import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M245.1,419.9c2.6-0.2,4.7-0.4,6.4-0.5c2.4-0.3,4.1-0.4,5.9-0.4h4.4c0.5,0,0.8,0.1,0.9,0.1
	c0.3,0.1,0.3,0.3,0.3,0.3l0.4,1.9c0.4,1.9,3,1.8,3,1.8l2.3,4.8c0,0-0.7,0.4-0.2,1.2c0.2,0.3,2.2,4.7,4.6,9.6c2.9,6,6.1,12.9,7,14.4
	c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.3c1.5,2.1,3.7,2.9,7.1,2.6c3.8-0.3,6.9-1.9,8.1-2.3c1.2-0.4,1.4-0.7,1.9-1.2
	c0.5-0.5,0.3-0.9-0.1-1.5s-13.9-19.6-14.5-20.6c-0.7-1-1.3-1.7-1.9-1.8c-0.5-0.1-1-0.5-1.2-0.7c-0.2-0.2-1.2-1.2-1.5-1.6
	c-0.3-0.4-1.1-0.3-1.1-0.3l-2-3.9c0,0,0.3-0.4,0.3-1c0.1-0.6,0.1-2,0.1-2.1c17.2,0.1,59.9,0.4,71.9,0.4c7.2,0,39.5,0,52.4,0.1h21.5
	c8.1,0,33.6-0.2,41.6-0.3h10.9c1.6,0,3.2-0.5,4.5-1.4h7.1c0.2,0.6,0.6,1.1,1.5,1.1c1.4,0,18.6-0.1,25.3-0.1
	c6.6-0.1,39.3,0.1,42.2,0.2c2.8,0.1,4.4,0.4,17.5,1c9.3,0.4,47.6,0.1,74.1-0.3c10.7-0.2,19.4-0.3,23.5-0.5c14-0.5,21.7-1.5,27.6-2.5
	s10.5-1.9,14.5-2c4-0.1,19.8-0.3,41.3-1c18.8-0.6,22.5-2.7,23-3.2l0.2-0.1c0.8-0.1,5.3-0.4,9.5-1.4c4.6-1,8.8-2.8,9.7-11
	c0.9-8.2,1.3-62.1,1.3-63.6c0-1.5-0.4-1.9-0.4-1.9c0-11.8,0.1-23.7,0.1-35.5v0.2c0-1.5-0.3-93-1.2-101.2c-0.6-5.6-2.7-7.5-5.5-8.6
	c-2.8-1.1-8.3-2.3-11.2-2.5l-0.4-0.3c-0.6-0.5-4.1-2.6-23.1-3.2c-21.4-0.7-37.3-0.9-41.3-1c-4-0.1-8.7-1-14.5-2
	c-5.8-1-14.4-2.5-28.5-2.9c-4-0.1-61.9-0.4-72.6-0.5c-26.4-0.4-16.9-0.6-26.2-0.2c-13,0.6-14.6,0.8-17.5,1
	c-2.8,0.1-35.5,0.3-42.2,0.2c-6.6-0.1-23.9-0.1-25.3-0.1c-0.9,0-1.3,0.5-1.5,1.1h-7.1c-1.3-0.9-2.9-1.4-4.5-1.4c-2.7-0.1-6,0-7.5,0
	h-3.4c-8-0.1-33.6-0.2-41.6-0.2H400c-12.9,0-45.1,0-52.4,0.1c-11.9,0-54.6,0.3-71.9,0.4c0,0-0.1-1.5-0.1-2.1c-0.1-0.6-0.3-1-0.3-1
	l2-3.9c0,0,0.9,0.1,1.1-0.3c0.3-0.4,1.3-1.4,1.5-1.6c0.2-0.2,0.7-0.6,1.2-0.7c0.5-0.1,1.2-0.9,1.9-1.8c0.7-1,14.1-19.9,14.5-20.6
	c0.4-0.6,0.6-1.1,0.1-1.5c-0.5-0.5-0.8-0.8-1.9-1.2c-1.1-0.4-4.3-2-8.1-2.3c-3.4-0.3-5.5,0.5-7.1,2.6c-0.1,0.1-0.1,0.2-0.2,0.3
	s-0.2,0.3-0.3,0.4c-0.9,1.5-4.2,8.3-7,14.4c-2.4,5-4.4,9.4-4.6,9.6c-0.4,0.8,0.2,1.2,0.2,1.2l-2.3,4.8c0,0-2.6-0.1-3,1.8l-0.4,1.9
	c0,0,0,0.2-0.3,0.3c-0.2,0.1-0.5,0.1-0.9,0.1h-4.4c-1.9,0-3.5-0.2-5.9-0.4c-1.7-0.2-3.8-0.4-6.4-0.5c-3.7-0.2-8.3-0.4-13.8-0.5
	c-9.6-0.1-41.7,0-57.9,0.4s-44.7,1.5-48.3,1.9s-6.4,0.6-6.4,0.6l-0.7-0.7c0,0-0.1-0.4-1.6-0.3c-1.5,0-13.7,1.1-16.3,1.7
	c-2.7,0.6-5.2,2.1-7.4,4.1c-4.9,3.8-13.3,11.5-19.2,18.4c-5.9,7-10.1,12.8-10.9,14.8s-3.1,10.8-4,15.2c-0.9,4.4-1.6,8.8-1.6,8.8
	l-1.2,6.9c0,0-2.7,21.4-3.2,29.7c-0.2,2.9-0.1,13.4,0.1,22.4c-0.2,8.8-0.3,16.4-0.1,19.3c0.5,8.2,3.2,29.7,3.2,29.7l1.2,6.9
	c0,0,0.7,4.4,1.6,8.8c0.9,4.3,3.2,13.2,4,15.2c0.8,2,5,7.8,10.9,14.8c5.9,7,14.3,14.6,19.1,18.9c2.2,1.9,4.8,3.5,7.4,4.1
	c2.7,0.6,14.9,1.6,16.3,1.7c1.5,0.1,1.6-0.3,1.6-0.3l0.7-0.7c0,0,2.8,0.2,6.4,0.6c3.6,0.4,32.1,1.5,48.3,1.9
	c16.2,0.4,48.3,0.5,57.9,0.4C236.7,420.3,241.3,420.1,245.1,419.9L245.1,419.9z"
          />
          <path
            fill="#FFFFFF"
            d="M263.2,175.7l0.3-1.3c0.4-1.9,3-1.8,3-1.8l2.3-4.8c0,0-0.7-0.4-0.2-1.2c0.2-0.3,2.2-4.7,4.6-9.6
	c2.9-6,6.1-12.9,7-14.4c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c1.5-2.1,3.7-2.9,7.1-2.6c3.8,0.3,6.9,1.9,8.1,2.3
	s1.4,0.7,1.9,1.2c0.5,0.5,0.3,0.9-0.1,1.5c-0.4,0.6-13.9,19.6-14.5,20.6c-0.7,1-1.3,1.7-1.9,1.8c-0.5,0.1-1,0.5-1.2,0.7
	c-0.2,0.2-1.2,1.2-1.5,1.6c-0.3,0.4-1.1,0.3-1.1,0.3l-2,3.9c0,0,0.3,0.4,0.3,1c0.1,0.6,0.1,2,0.1,2.1h0.1v0.5l0.3,0.7v2.2l-0.4,0.8
	l-0.2,2.7l19-0.4L385,183l0.1,16.7c0,0,0.3,1.2-0.2,2.3c-0.5,1-1.3,2.1-2.3,2.5s-2.9,1-4.7,1c-1.8,0.1-5.7,0.1-5.7,0.1l-28.7,0.2
	c0,0-12.1-0.4-14.7-0.6c-2.7-0.2-8.7-0.7-12.8-1.8c-4.2-1.1-10.3-2.9-10.3-2.9l-36.4-10.4l-8.7-2.4c0,0-3.8-1.3-5.3-2.5
	c-1.5-1.2-1.3-1-1.6-1.3c-0.2-0.2-0.9-0.7-0.9-1.7s0.7-1.4,2.4-1.5c1.7-0.2,5-0.1,5-0.1l3.2,0.1l0.2-4.4L263.2,175.7L263.2,175.7z"
          />
          <path
            fill="#5E5E5E"
            d="M289.8,393c5.6-1.7,15.3-4.5,17.4-5.3c2.1-0.8,5.3-3.5,3.8-8.4c-1.5-4.9-3.3-10-5-19.5s-2.8-16.3-3.8-25.9
	s-1.4-26.8-1.4-30.5v-11c0-3.7,0.5-20.9,1.4-30.5c1-9.5,2-16.4,3.8-25.9c1.7-9.5,3.5-14.6,5-19.5s-1.7-7.6-3.8-8.4
	s-11.8-3.6-17.4-5.3c-5.6-1.7-20.6-5.5-29.4-7.7s-17.2-2.8-17.2-2.8s-2.2,3.1-3.8,6.1c-1.6,3-6.9,12.7-9.7,20.7
	c-2.8,8-5.7,20.6-6.3,24.8c-0.7,4.1-1.4,9.1-1.4,9.1l-1.2,9.7c0,0-0.8,4.8-1,13.2c-0.2,8.3-0.4,22.5-0.4,22.5s0.2,12.9,0.4,21.3
	c0.3,8.3,1.6,17.2,1.6,17.2l1.3,10.5c0.2,1.4,0.4,2.9,0.7,4.3c0.7,4.1,3.6,16.8,6.3,24.9c2.8,8,8,17.7,9.7,20.7
	c1.6,3,3.8,6.1,3.8,6.1s8.6-0.6,17.2-2.8C269.1,398.5,284.1,394.7,289.8,393L289.8,393z"
          />
          <path
            fill="#FFFFFF"
            d="M263.2,419.9l0.3,1.3c0.4,1.9,3,1.8,3,1.8l2.3,4.8c0,0-0.7,0.4-0.2,1.2c0.2,0.3,2.2,4.7,4.6,9.6
	c2.9,6,6.1,12.9,7,14.4c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.3c1.5,2.1,3.7,2.9,7.1,2.6c3.8-0.3,6.9-1.9,8.1-2.3
	s1.4-0.7,1.9-1.2c0.5-0.5,0.3-0.9-0.1-1.5c-0.4-0.6-13.9-19.6-14.5-20.6c-0.7-1-1.3-1.7-1.9-1.8c-0.5-0.1-1-0.5-1.2-0.7
	c-0.2-0.2-1.2-1.2-1.5-1.6c-0.3-0.4-1.1-0.3-1.1-0.3l-2-3.9c0,0,0.3-0.4,0.3-1c0.1-0.6,0.1-2,0.1-2.1h0.1v-0.5l0.3-0.7v-2.2
	l-0.4-0.8l-0.2-2.7l19,0.4l90.2-0.2l0.1-16.7c0,0,0.3-1.2-0.2-2.3c-0.5-1-1.3-2.1-2.3-2.5c-1-0.4-2.9-1-4.7-1
	c-1.8-0.1-5.7-0.1-5.7-0.1l-28.7-0.2c0,0-12.1,0.4-14.7,0.6c-2.7,0.2-8.7,0.7-12.8,1.8c-4.2,1.1-10.3,2.9-10.3,2.9l-36.4,10.4
	l-8.7,2.4c0,0-3.8,1.3-5.3,2.5c-1.5,1.2-1.3,1-1.6,1.3c-0.2,0.2-0.9,0.7-0.9,1.7s0.7,1.4,2.4,1.5c1.7,0.2,5,0.1,5,0.1l3.2-0.1
	l0.2,4.4L263.2,419.9L263.2,419.9z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M471,311.5c0,2.7,1,3.7,1.7,3.8c0.6,0.1,1.6-0.3,2-0.5c0,4.5-0.1,8.5-0.1,10c-0.1,7.1,0,22.9,0.1,33.4v6.5
		c0,7.8,0.3,13.3,0.3,14.1v0.1c0,0,0.4,0.6,0.9,1.8c0.5,1.2,0.2,2,0.2,2s0.3,0.7,1,2c0.3,0.6,1,2.6,1.7,4.6v-45.2
		c0-17.5,0.2-45.1,0.2-45.1v-3.1c0,0-0.2-27.6-0.2-45.1V206c-0.7,2.1-1.4,4-1.7,4.6c-0.7,1.4-1,2-1,2s0.3,0.8-0.2,2
		s-0.9,1.8-0.9,1.8v0.1c0,0.7-0.3,5.5-0.3,12.5v1.6c0,8.8-0.2,31-0.1,39.9l0.1,10.1c-0.4-0.2-1.4-0.6-2-0.5
		c-0.7,0.1-1.7,1.1-1.7,3.8s-0.1,12.4-0.1,12.4v2.5C470.9,299,471,308.8,471,311.5L471,311.5z M376.6,391.8c-5.8,0-21-0.4-30.4-0.3
		c-9.4,0.1-22.2,0.6-30.2,2.5c-3.9,0.9-16.3,4.4-25.4,6.8c-3.2,0.9-6,1.6-7.8,2.1c-3.4,0.9-6.3,1.6-7.3,1.9v5.3
		c1.8,0,8.8,0,15.1,0.1c3.4,0,6.7,0.1,8.8,0.1c6.7,0.1,9.9-0.3,14.2-0.5c4.4-0.2,7.3-0.3,16.4-0.1c8.7,0.1,48.5,0.6,51.8,0.6
		c0-2.3,0.3-14.5,0.3-15.4C382.5,393.9,382.5,391.8,376.6,391.8L376.6,391.8z M445.4,390.5c-5.8,0-28-0.1-33,0.1
		c-5,0.1-7.8,0.8-7.6,4.3c0.2,3.6,1.3,16.3,1.3,16.3s-0.1,2.5,5.2,2.5c5.6,0.1,34.8,0.3,38.7,0.3c3.9,0,5.7-1.1,5.5-3
		c-0.2-1.8-1.9-11.6-2.7-15.1C452,392.5,451.2,390.6,445.4,390.5L445.4,390.5z M227.6,324.2c0.4,8.4,2.1,21.5,3.6,29.9
		c1.6,8.3,3.2,14.3,6,22.9c2.8,8.5,6,14.3,6,14.3c1.6,3.1,4.3,7,10.7,5.9c6.4-1.1,12.3-2.1,23.2-5c4.6-1.2,9.4-2.6,13.6-3.9
		c5.7-1.8,10.2-3.3,11.5-3.9c2.2-0.9,4.7-2.2,3.1-7.6c-1.6-5.4-3.9-14.6-5.6-24.8c-1.7-10.1-2.2-16.7-2.7-24.9
		c-0.6-9.3-0.7-22.4-0.8-24.3v-9.9c0.1-1.9,0.2-14.9,0.8-24.3c0.5-8,0.9-14.6,2.7-24.8c1.7-10.1,4-19.3,5.6-24.9
		c1.6-5.4-0.9-6.7-3.1-7.6c-1.3-0.5-5.8-2.1-11.5-3.9c-4.2-1.3-9-2.7-13.6-3.9c-10.8-2.9-16.7-3.8-23.2-5c-6.4-1.1-9,2.8-10.7,5.9
		c0,0-3.2,5.8-6,14.3s-4.5,14.5-6,22.9c-1.6,8.3-3.2,21.4-3.6,29.9s-0.8,24.1-0.8,25.4v2.2C226.8,300.2,227.2,315.9,227.6,324.2
		L227.6,324.2z M77.6,382.4c0.3,0.8,2.9,8.8,4.2,12.3c1,2.6,2.7,6.5,4,9.1c0.4,0.8,0.8,1.5,1.1,2.1c1.2,1.9,4.3,5.3,7.8,6.5
		c0.4,0.1,0.8,0.2,1.1,0.3c2.8,0.5,5.5,0.8,6.9,0.8c0.6,0,1.2,0.1,1.2,0.1c2.8,0.2,7.4,0.4,13.1,0.5h0.9c0.1,0,0.2-0.1,0.3-0.1
		c0.4-0.1,1-0.4,1.1-1c0.1-0.7,0.3-1.8,0.4-2.2c-1.6-0.1-3.1-0.2-4.4-0.3c-11.8-0.8-17.8-7.4-22.4-12.9c-4.6-5.4-11.1-17-11.1-17
		h-0.2l-1.5,0.1l-1.7,0.4C78.3,381.2,77.4,381.6,77.6,382.4L77.6,382.4z M290.8,194.7c8.9,2.6,21.4,6.4,25.4,7.3
		c7.9,1.9,20.7,2.3,30.2,2.5c9.3,0.1,24.6-0.3,30.4-0.3s5.8-2.1,5.8-3.1c0-0.9-0.3-13.1-0.3-15.4c-3.3,0-43.1,0.5-51.8,0.6
		c-9.1,0.1-12,0.1-16.4-0.1c-4.4-0.2-7.6-0.6-14.3-0.5c-2.1,0-5.4,0.1-8.9,0.1c-6.3,0-13.2,0.1-15,0.1v4.7c1,0.3,3.9,1.1,7.3,1.9
		C284.8,193,287.5,193.8,290.8,194.7L290.8,194.7z M412.3,205c5,0.2,27.3,0.1,33,0.1c5.8,0,6.6-1.9,7.4-5.4
		c0.8-3.5,2.5-13.2,2.7-15.1c0.2-1.8-1.6-3-5.5-3s-33.1,0.2-38.7,0.3c-5.3,0.1-5.2,2.5-5.2,2.5s-1.1,12.7-1.3,16.3
		C404.5,204.2,407.3,204.9,412.3,205L412.3,205z"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M118.4,177.6l-0.6-0.6c0,0-0.1-0.4-1.6-0.3c-1.5,0-13.7,1.1-16.3,1.7c-2.7,0.6-5.2,2.1-7.4,4.1
	c-4.9,4.2-13.3,11.9-19.2,18.8c-5.9,7-10.1,12.8-10.9,14.8s-3.1,10.8-4,15.2c-0.9,4.4-1.6,8.8-1.6,8.8l-1.2,6.9
	c0,0-2.7,21.4-3.2,29.7c-0.2,2.9-0.1,13.4,0.1,22.4c-0.2,8.8-0.3,16.4-0.1,19.3c0.5,7.9,3,28,3.2,29.6c-0.5,0.4-0.7,1.9-0.4,3.7
	c0.3,1.9,1,3.3,1.6,3.4c0.1,0.6,0.8,4.7,1.6,8.7c0.9,4.3,3.2,13.2,4,15.2c0.8,2,5,7.8,10.9,14.8s14.3,14.6,19.1,18.9
	c2.2,1.9,4.8,3.5,7.4,4.1c2.7,0.6,14.9,1.6,16.3,1.7c1.5,0.1,1.6-0.3,1.6-0.3l0.7-0.7l-2.4,0.2l-5.4-0.6l-4.2-0.9l-3-1.2l-2.7-1.7
	v-0.1c-1.4-0.1-3-0.3-4.8-0.7c-0.4-0.1-0.8-0.2-1.1-0.3c-3.5-1.2-6.6-4.5-7.8-6.5c-0.3-0.5-0.7-1.2-1.1-2.1c-1.3-2.7-3-6.5-4-9.1
	c-1.3-3.4-3.9-11.5-4.2-12.3c-0.3-0.8,0.7-1.2,0.7-1.2l1.7-0.4c-0.8,0.2-5.2-2.5-5.2-2.5l-4.7-3l-1.7-1.4l0.6-0.9l0.3-3.4l-0.1-3.7
	l-0.4-4.1l-0.8-6.8l-2.7-28.6l-0.7-17.6l-0.1-11l0.1-11l0.7-17.6l2.7-28.6l0.8-6.8l0.4-4.1l0.1-3.7l-0.3-3.4l-0.6-0.9l1.7-1.4l4.7-3
	c0,0,4.4-2.7,5.2-2.5c0.8,0.2,20.7-32.6,20.7-32.6l2.7-1.7l3-1.2l4.2-0.9l5.4-0.6l2.4,0.2L118.4,177.6L118.4,177.6z"
          />
          <path
            fill="#EAEBEB"
            d="M118.3,181.4c0.4,0.1,1,0.4,1.1,1c0.1,0.7,0.3,1.8,0.4,2.2c-1.6,0.1-3.1,0.2-4.4,0.3
	c-11.8,0.8-17.8,7.4-22.4,12.9c-4.6,5.4-11.1,17-11.1,17h-0.2l-1.5-0.1l-1.7-0.4c0,0-1-0.4-0.7-1.2s2.9-8.8,4.2-12.3
	c1-2.6,2.7-6.5,4-9.1c0.4-0.8,0.8-1.5,1.1-2.1c1.2-1.9,4.3-5.3,7.8-6.5c0.4-0.1,0.8-0.2,1.1-0.3c2.8-0.5,5.5-0.8,6.9-0.9
	c0.6,0,1.2-0.1,1.2-0.1c2.8-0.2,7.4-0.4,13.1-0.5h0.9C118.1,181.4,118.2,181.4,118.3,181.4L118.3,181.4z"
          />
          <path
            fill="#FFFFFF"
            d="M459.5,366.8H324.7c-0.6,0-1.6,0.8-0.7,1.8l1.7,2.4c0.5,1,1.4,1.1,2,1.1l131.8,0.1c0.7,0,1.2-0.5,1.2-1.2
	v-3.1C460.7,367.4,460.2,366.8,459.5,366.8L459.5,366.8z M324.7,228.6h134.8c0.7,0,1.2-0.5,1.2-1.2v-3.1c0-0.6-0.5-1.2-1.2-1.2
	l-131.8,0.2c-0.7,0-1.5,0.1-2,1.1l-1.7,2.4C323.1,227.9,324,228.6,324.7,228.6L324.7,228.6z M768.5,297.3l0.1,96.7
	c5.2-0.3,9.6,0.3,13.6,1.4l1.2-84.8c0.9-4.4,1.4-8.5,1.3-13L768.5,297.3L768.5,297.3z M784.8,297.5h-16.1l0.1-96.3
	c5.2,0.3,9.6-0.3,13.6-1.4l1.2,84.4C784.4,288.5,784.8,293,784.8,297.5L784.8,297.5z"
          />
          <path
            fill="#5E5E5E"
            d="M785.8,409.4c4.6-1,8.8-2.8,9.7-11s1.3-62.1,1.3-63.6c0-1.5-0.4-1.9-0.4-1.9l0.1-20.8v-14.7h-11.7
	c0.1,4.5-0.3,9-1.2,13.4v5.9c0,6.1-0.1,15.4-0.1,24.8c0,2.6-0.1,5.2-0.1,7.7c0,1.8-0.1,3.8-0.1,6c-0.1,4.2-0.2,9-0.3,13.7v10.4
	c0,1.3,0,2.4-0.1,3.6c0,1.2,0,2.3-0.1,3.3c0,1.3-0.1,2.5-0.1,3.5c0,1.4-0.1,2.5-0.1,3.3v2.5c-0.1,0.4-0.3,0.4-0.3,0.4l-1-0.1
	l0.3,0.2c0.4,0.2,0.9,0.5,1.2,0.7c0.2,0.1,0.3,0.3,0.4,0.5c0.2,0.2,0.5,0.6,0.4,1.2c-0.1,0.8-0.4,3.8-1.6,6.6
	c-0.4,1.1-0.9,2-1.5,2.7c-0.9,1.2-1.9,1.9-3.1,2.5c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0-0.1,0.1-0.2,0.1
	C776.9,410.7,781.6,410.3,785.8,409.4L785.8,409.4z"
          />
          <path
            fill="#5E5E5E"
            d="M784.8,297.5h9.6l2,0.1l0.1-35.3c0,0,0.3-1,0.2-3.6c-0.1-3.3-0.6-27.1-1-44.3c-0.2-8.2-0.4-14.9-0.5-17.3
	v-0.7c-0.2-2.1-0.7-3.7-1.3-4.9c-1.4-2.7-3.9-3.6-6.7-4.5c-0.3-0.1-0.5-0.2-0.8-0.2c-0.9-0.2-1.8-0.4-2.7-0.6
	c-1.9-0.4-3.8-0.6-5.2-0.8c-0.6-0.1-1.1-0.1-1.5-0.2c2.5,1.4,3.9,2.9,4.8,5.5c1,2.8,1.3,5.4,1.4,6.2s-0.2,1.4-0.7,1.8
	c-0.5,0.5-1.9,1-1.9,1l1.5,0.2v0.1h0.2v9.4l0.1,5.2c0,1.7,0.1,3.4,0.1,5.2c0.1,2.2,0.1,4.4,0.2,6.7c0.2,7.2,0.3,14.4,0.4,19.8
	c0,1.4,0,2.8,0.1,4.2v3.5c0.1,9,0.1,18,0.1,24.2v6.1c0.6,2.9,1,5.9,1.1,8.9c0.1,1,0.1,2.1,0.1,3.3L784.8,297.5L784.8,297.5z
	 M485.6,416V177.7l0.1-0.6h-7c1.4,1,2.8,2.8,3,6.3c0.4,6.4-0.4,180.2-0.4,180.2h0.4v49.1c0,2.6-1.2,4.5-3,5.6h7.1
	c-0.1-0.3-0.1-0.6-0.1-0.9C485.6,417.4,485.6,416.9,485.6,416L485.6,416z M450.8,406.5c0.8,0,1.5-0.4,1.5-0.9c0-0.5-0.7-0.9-1.5-0.9
	c-0.8,0-1.5,0.4-1.5,0.9S450,406.5,450.8,406.5z M409.5,411.3c0.8,0,1.5-0.4,1.5-0.8s-0.7-0.8-1.5-0.8s-1.5,0.4-1.5,0.8
	S408.7,411.3,409.5,411.3L409.5,411.3z M408.9,399.6c0.8,0,1.5-0.4,1.5-0.9c0-0.5-0.7-0.9-1.5-0.9c-0.9,0-1.5,0.4-1.5,0.9
	C407.3,399.2,408,399.6,408.9,399.6L408.9,399.6z"
          />
          <path
            fill="#EAEBEB"
            d="M297.7,452.9c0.5-0.5,0.3-0.9-0.1-1.5c-0.4-0.6-13.9-19.6-14.5-20.6c-0.7-1-1.3-1.7-1.9-1.8
	c-0.2,0-0.5-0.1-0.7-0.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.1,0.8,0.3,1.4c0.6,0.9,11.5,16.9,13,19
	C294.6,450.9,296.4,452.2,297.7,452.9L297.7,452.9z"
          />
          <path
            fill="#5E5E5E"
            d="M450.8,189.1c0.8,0,1.5,0.4,1.5,0.9s-0.7,0.9-1.5,0.9c-0.8,0-1.5-0.4-1.5-0.9S450,189.1,450.8,189.1
	L450.8,189.1z M409.5,184.3c0.8,0,1.5,0.4,1.5,0.8s-0.7,0.8-1.5,0.8s-1.5-0.4-1.5-0.8C408,184.6,408.7,184.3,409.5,184.3
	L409.5,184.3z M408.9,196c0.8,0,1.5,0.4,1.5,0.9s-0.7,0.9-1.5,0.9c-0.9,0-1.5-0.4-1.5-0.9C407.3,196.4,408,196,408.9,196L408.9,196z
	"
          />
          <path
            fill="#EAEBEB"
            d="M297.7,142.6c0.5,0.5,0.3,0.9-0.1,1.5c-0.4,0.6-13.9,19.6-14.5,20.6c-0.7,1-1.3,1.7-1.9,1.8
	c-0.2,0-0.5,0.1-0.7,0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.1-0.8,0.3-1.4c0.6-0.9,11.5-16.9,13-19
	C294.6,144.6,296.4,143.4,297.7,142.6L297.7,142.6z"
          />
          <path
            fill="#5E5E5E"
            d="M158,329.8v-1.1c0-0.3-0.3-0.6-0.6-0.6l-1.9,0.2c-0.3,0-0.6,0.3-0.6,0.6v0.8c0,0.3,0.3,0.6,0.6,0.6l1.9,0.2
	C157.8,330.4,158,330.2,158,329.8L158,329.8z M157.4,267.5c0.3,0,0.6-0.3,0.6-0.6v-1.1c0-0.3-0.3-0.6-0.6-0.6l-1.9,0.2
	c-0.3,0-0.6,0.3-0.6,0.6v0.8c0,0.3,0.3,0.6,0.6,0.6L157.4,267.5L157.4,267.5z M227.5,385.5c2.5,0.1,2.6-1.6,2.2-3.1c0,0-1-2-3.3-8.9
	c-1.5-4.5-3-8.7-4.3-14c-6.3-32.3-8-64.8-4.3-97.7c0,0,0.5-5.7,2.3-16c1.8-10.4,3.9-16.5,6.2-23.5s3.3-8.9,3.3-8.9
	c0.4-1.5,0.3-3.2-2.2-3.1c-2.5,0-9.1,0.2-12,0.8c-2.8,0.6-6,1.7-7,7.7c-0.9,5.9-2.3,15.6-2.9,27.1c-0.5,8.3-1.1,17.8-1.3,25.8
	c-0.3,7.8-0.6,24.6-0.6,24.6v3.7c0,0,0.4,16.7,0.6,24.7c0.3,7.8,0.9,17.3,1.3,25.8c0.6,11.4,2,21.1,2.9,27.1c0.9,5.9,4.1,7.1,7,7.7
	C218.3,385.3,225,385.4,227.5,385.5L227.5,385.5z M759.6,404h7.9c0.6,0,1.2-0.5,1.2-1.2v-4.4c0-0.6-0.5-1.2-1.2-1.2h-7.9
	c-0.7,0-1.2,0.5-1.2,1.2v4.4C758.4,403.5,758.9,404,759.6,404L759.6,404z M503.7,405.8h7.9c0.6,0,1.2-0.5,1.2-1.2v-4.4
	c0-0.7-0.5-1.2-1.2-1.2h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.4C502.5,405.3,503,405.8,503.7,405.8L503.7,405.8z M503.7,196.1h7.9
	c0.7,0,1.2-0.5,1.2-1.2v-4.4c0-0.7-0.5-1.2-1.2-1.2h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.4C502.5,195.6,503,196.1,503.7,196.1L503.7,196.1
	z M622.1,196.3h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.4c0-0.7-0.5-1.2-1.2-1.2h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.4
	C621,195.8,621.5,196.3,622.1,196.3z M759.6,198.1h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5c0-0.7-0.5-1.2-1.2-1.2h-7.9
	c-0.7,0-1.2,0.5-1.2,1.2v4.4C758.5,197.5,759,198.1,759.6,198.1L759.6,198.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M796.4,297.5l-0.1,35.3c0,0,0.4,0.4,0.4,1.9c0,1.5-0.4,55.4-1.3,63.6
	c-0.9,8.2-5.1,10-9.7,11c-4.6,1-9.6,1.4-9.6,1.4s-1.7,2.6-23.2,3.3c-21.5,0.7-37.3,0.9-41.3,1c-4,0.1-8.7,1-14.5,2
	c-5.8,1-13.6,2.1-27.6,2.5c-14,0.5-84.4,1.4-97.5,0.8c-13-0.6-14.6-0.8-17.5-1c-2.8-0.1-35.5-0.3-42.2-0.2
	c-6.6,0.1-23.9,0.1-25.3,0.1c-1.4,0-1.6-1.3-1.5-2c0.1-0.7,0-58.7,0-58.7h-4v53.9c0,0-3.8,7-7.5,7c-3.7,0.1-8.4,0-8.4,0
	s-34.5,0.3-44.1,0.3c-9.6,0-54.7,0-73.9-0.1s-71.9-0.4-71.9-0.4s-0.1,1.5-0.1,2.1c-0.1,0.6-0.3,1-0.3,1l2,3.9c0,0,0.9-0.1,1.1,0.3
	c0.3,0.4,1.3,1.4,1.5,1.6c0.2,0.2,0.7,0.6,1.2,0.7c0.5,0.1,1.2,0.9,1.9,1.8c0.7,1,14.1,19.9,14.5,20.6c0.4,0.6,0.6,1.1,0.1,1.5
	c-0.5,0.5-0.8,0.8-1.9,1.2c-1.1,0.4-4.3,2-8.1,2.3s-6-0.7-7.5-3.3c-1.6-2.7-11.2-23.5-11.6-24.1c-0.4-0.8,0.2-1.2,0.2-1.2l-2.3-4.8
	c0,0-2.6,0.1-3-1.8l-0.4-1.9c0,0,0-0.4-1.3-0.4c-4.4,0-6.2,0-10.3,0.5c-4,0.5-10.7,0.9-20.3,1s-41.7,0-57.9-0.4
	c-16.2-0.4-44.7-1.5-48.3-1.9s-6.4-0.6-6.4-0.6l-0.7,0.7c0,0-0.1,0.4-1.6,0.3c-1.5,0-13.7-1.1-16.3-1.7c-2.7-0.6-5.2-2.1-7.4-4.1
	c-4.9-4.3-13.2-11.9-19.1-18.9c-5.9-7-10.1-12.8-10.9-14.8c-0.8-2-3.1-10.8-4-15.2c-0.9-4.3-1.6-8.8-1.6-8.8s-1.5-1.2-1.7-3.5
	c-0.2-2.3,0.6-3.4,0.6-3.4s-2.7-21.4-3.2-29.7c-0.5-8.2,1-53.6,1.6-59c0.6-5.5,1.5-12.2,1.5-12.2s-0.7-1-0.5-3.5
	c0.2-2.5,1.9-3.6,1.9-3.6s3.3-16.2,3.9-19.4c0.7-3.2,2.1-5.6,4.4-9c8.3-12.1,23.2-26.5,28.7-30.2c5.3-3.7,7.7-3.1,12.5-3.7
	c4.3-0.6,9.6-0.9,10.4-0.9c0.9-0.1,0.8,0.2,1.1,0.4c0.3,0.2,0.7,0.5,0.7,0.5s4.6-0.3,19.9-1.2c15.3-0.9,50.8-2.5,68.3-1.8
	c17.4,0.6,35.9,0.4,42.2,0.9c6.2,0.5,7.3,0.2,8.4,0.6s5.7,0,5.7,0s0.3-1.3,0.6-2.3c0.5-1.5,2.6-1.5,2.6-1.5l2.6-5
	c0,0-0.7-0.1-0.2-1.2s7.9-16.9,9.4-19.7c1.5-2.7,2.8-5.5,3.3-6c0.9-0.8,3.4-2.4,6.3-1.7c2.9,0.6,7.6,2,8.7,2.4
	c1.1,0.4,1.5,0.9,1.8,1.1c0.4,0.2,0.4,0.6-0.1,1.4c-0.5,0.8-14,20-15.1,21.2s-1.5,1.4-1.8,1.5s-0.7,0.3-1.1,0.6c0,0-0.7,0.6-1,1.1
	c-0.3,0.5-0.8,0.8-1.1,0.8h-0.4l-2,3.8c0,0,0.3,0.2,0.3,0.8c0,0.6,0.1,2.2,0.1,2.2s137.1-0.5,144.9-0.4c7.8,0.1,45.3,0.1,45.3,0.1
	c5.5,0.1,7.4,0,9,0.3c1.7,0.3,5.9,1.1,6.3,7.5c0.4,6.4,0.2,53.1,0.2,53.1h3.8v-58.9c0-1.4,1.3-1.6,2.6-1.6c0,0,53.8,1,61.2,0.5
	c7.3-0.5,14.8-1.3,25.7-1.4c10.8-0.1,82.4,0.3,93.6,0.5c11.3,0.2,23,2,29.6,3.2c6.6,1.1,11.6,1.8,18.5,1.9
	c6.9,0.1,16.9,0.6,30.5,1.1c13.4,0.5,19.3,1,22.9,1.4s6.3,1.7,6.3,1.7s6.5,0.5,10.8,1.9c4.3,1.4,7.7,2.9,8,10.2
	c0.4,7.3,1.3,56.7,1.5,61.6c0.1,2.6-0.2,3.6-0.2,3.6L796.4,297.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M783.5,290.6c0,0,0.1-1.5,0-5.9c-0.1-4.4-0.1-22.9-0.3-38.6
	c-0.2-15.6-1-46.5-1-46.5l-1.5-0.2c0,0,1.4-0.6,1.9-1c0.5-0.5,0.7-1.1,0.7-1.8c-0.1-0.8-0.4-3.4-1.4-6.2s-2.5-4.2-5.4-5.8
	c-2.1-1.3-7.9-1.8-7.9-1.8l-1.6,1c0,0-21.4-1.5-36-2c-14.5-0.5-38.6-1.6-61-2.3c-22.5-0.6-58.8-2.1-77.5-2.1
	c-18.5-0.1-76.3-0.9-87.8-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M485.5,179.4c0,0-0.1-1.5,2.7-1.4c2.8,0.1,75.4,0.7,94.7,1.1
	c19.3,0.4,66.2,1.8,81.5,2.2c15.2,0.5,60.7,2,74.5,2.7c13.6,0.7,27.1,1.9,30.8,2.6c3.8,0.7,6.8,2.3,8.2,5.2c1.4,3,1.7,7.1,1.7,7.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M770.2,182.9c0,0,1.7,1.1,4.4,2.8c2.7,1.6,5.3,4.1,5.8,7.1
	c0.6,2.9,0.8,5.1,0.8,5.1l-1.7,1.2c0,0-0.1-10.6-10.6-11.2c-10.5-0.6-42.8-2.2-56.8-2.8c-14-0.6-58.2-2-79.1-2.6
	c-20.8-0.5-58.3-1.5-79.7-1.7c-21.2-0.3-62.3-0.4-64.9-0.5c-2.6-0.1-2.6,1-2.6,3.5c-0.1,2.5,0,13.6,0,13.6l2.5,0.1v0.3
	c0,0,0.1,0.4,0.9,0.4c0.8,0,94.6,0.7,119.5,1c25,0.3,99.2,0.4,118.3,0.8s37.5,0.5,42.8,0.2c5.2-0.3,7.2-0.3,9-0.5s2.6-0.5,2.6-0.5
	 M783.2,197.1c0,0-1.3,0.4-2.2,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M776.6,199.8c0,0,0.8,0.1,0.7-1.5c-0.1-1.6-0.6-5.3-2.4-7.1
	c-1.8-1.8-5-2.5-10.7-2.7c-5.7-0.2-132.3-4.6-146.7-4.8c-14.5-0.2-90.7-1.2-105.8-1.3c-15.1-0.1-21.2-0.2-22.4-0.2
	c0,0-2.6-0.3-2.6,1.7s-0.1,13.1-0.1,13.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M489.2,182.4c0,0-1-0.1-1,0.7v14.6 M768.7,196.9c0,0.6-0.5,1.2-1.2,1.2
	h-7.9c-0.7,0-1.2-0.5-1.2-1.2v-4.5c0-0.7,0.5-1.2,1.2-1.2h7.9c0.7,0,1.2,0.5,1.2,1.2V196.9L768.7,196.9z M512.8,195
	c0,0.6-0.5,1.2-1.2,1.2h-7.9c-0.7,0-1.2-0.5-1.2-1.2v-4.4c0-0.7,0.5-1.2,1.2-1.2h7.9c0.7,0,1.2,0.5,1.2,1.2L512.8,195L512.8,195z
	 M631.2,195.1c0,0.6-0.5,1.2-1.2,1.2h-7.9c-0.7,0-1.2-0.5-1.2-1.2v-4.4c0-0.7,0.5-1.2,1.2-1.2h7.9c0.7,0,1.2,0.5,1.2,1.2V195.1z
	 M758.4,200.4v3.5h0.8c0,0,0,0.6,2.1,0.5c2.1-0.1,7.1-0.1,7.1-0.1 M780.5,249.4h-11.6 M693.3,199.6c-4.1,0-6.5,5.5-12.8,12.6
	c-6.3,7.1-13.1,7.7-20.2,7.8c-7.1,0.1-45.9,0.1-51.8,0.1c0,0-10.6-0.2-15.5-4.6c-4.9-4.4-7.5-16.5-12.9-16.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M598.9,199c0,0,8.8,16,17.3,17.7c8.5,1.8,29,2.3,36.6-0.4
	c9.2-3.3,18.5-16.8,18.5-16.8 M759.6,204.3v18 M782.3,202.3l12.3-8.2 M782.3,205.7l12.8-8.5 M782.5,209.2l12.7-8.6 M782.6,212.6
	l12.7-8.5 M782.7,216.1l12.8-8.6 M782.7,219.7l12.7-8.5 M782.8,223.2l12.8-8.6 M782.9,226.4l12.7-8.5 M783,229.9l12.7-8.6
	 M783,233.3l12.8-8.4 M783.2,236.9l12.7-8.6 M783.2,240.2l12.7-8.5 M783.3,243.7l12.7-8.5 M783.4,246.7l12.7-8.5 M783.3,250.4
	l12.9-8.7 M783.3,253.9l12.7-8.5 M783.4,257.5l12.8-8.6 M783.5,260.8l12.7-8.5 M783.5,264.4l12.8-8.7 M783.5,283.1l12.8-8.6
	 M783.5,279.5l12.7-8.6 M783.6,275.8l12.7-8.6 M783.5,267.9l12.9-8.7 M796,262.7l-6.2,4.2c0,0,0.1,0.4-0.1,0.5
	c-0.2,0.2-0.5,0.5-0.6,1.2c-0.1,0.6-0.2,1.5-0.7,1.9c-0.5,0.4-1.8,0.3-2.2,0.2c-0.4-0.2-0.7-0.3-0.7-0.3l-2,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M785,270.6c0,0,0.2-0.6,1.7-1.8c2.2-1.7,3.5-2.1,3.5-2.1 M792.9,291.8
	c0,0.3-0.3,0.6-0.6,0.6h-3.1c-0.3,0-0.6-0.3-0.6-0.6v-8.2c0-0.3,0.3-0.6,0.6-0.6h3.1c0.3,0,0.6,0.3,0.6,0.6L792.9,291.8L792.9,291.8
	z M485.7,299.2V179.1 M493.1,302.2V198.3 M488.2,292.7v-95.3 M768.6,297.6v-1.7l0.1-94.7c0,0,1,0.1,4.6-0.1c3.7-0.2,8.8-1.4,8.8-1.4
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M780.9,297.6v-1.2c0,0-0.3-52.1-0.4-61.2c-0.1-9.1-1.7-16.6-2.3-20.3
	c-0.6-3.6-0.6-11.4-0.6-12.7c0-1.2-0.7-1.3-0.7-1.3 M764.8,297.6v-2c0,0,0.1-71.5,0.1-72.3s-1-1-1-1h-4.3l-0.1,0.2l-266.4-0.1
	 M784.7,297.6v-1.4c-0.1-6.4-1.2-11.7-1.2-11.7 M781.5,297.9v-2.3c0,0,0.1-7.1,0.1-8.1s0.2-1.5,0.4-1.8c0.3-0.3,0.9-0.1,0.9,0.9
	s0.1,2.5,0.1,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M781.6,288.7l0.8-0.4l0.6,1.2c0,0,0.6,1.1,0.7,2.5c0.1,1.4,0,5.3,0,5.3
	v0.4 M280.4,166.6c0,0-0.4-0.7,0.2-1.7c0.6-0.9,11.5-16.9,13-19c1.5-2,4.5-3.1,4.5-3.1 M278.3,166.1c0,0-0.2-0.4,0.7-1.8
	c0.9-1.4,11.5-17.5,13.1-19.3s4.2-3.4,4.2-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268.4,167.1c0,0,5.4,1.5,6.4,1.7c1,0.2,1.2,0.3,1.7-0.3
	c0.5-0.6,3.2-1.5,3.2-1.5s-4.4-2.7-5.8-4.2c-1.4-1.5-2.4-2.5-0.4-6.8 M265.9,172.8h5.9c0.7,0,1.6,0,1.6,1.5v2.5c0,0,0.4,0.2,0.4,0.7
	v2c0,0-0.2,0.8-0.5,1s-0.4,0.6-0.4,1.2v4.1l0.7,2.2 M263.4,175.8c0,0-0.1,2.3-0.2,5.2c-0.1,3-0.5,4.9-1.2,5.4 M275.8,175.7l0.1,1
	c0,0,0.3,0,0.3,0.7v2.2c0,0.4-0.3,0.5-0.4,0.8c-0.1,0.2-0.2,1.2-0.2,2v9.4 M280.3,142.2c0,0,0.8-1.8,3.4-1.4s7.1,1.5,8.6,2.2
	c1.5,0.7,2.2,1.8,2.2,1.8 M116.7,177.8c-4.6,0.3-4.9,0.4-9,1.2c-4.1,0.9-6.9,3.1-6.9,3.1s6.5-0.5,16.3-0.7c9.8-0.3,28.5-1,43.1-1.5
	c14.7-0.5,45.4-2.2,58-2.4c12.6-0.3,45-0.9,45-0.9 M276.3,178.6c0,0,29.1-0.5,50.7-0.6c21.5-0.1,70.5-0.6,89.9-0.6
	s47.4,0.1,54.4,0.1c7,0,9.6,2.5,9.9,6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,180.3c0,0,71-0.8,86.9-0.9c15.9-0.1,98.5-0.3,104.7-0.2
	c6.2,0.1,13.4-0.1,13.8,5 M119.6,183.5c0,0,8.9-1.2,33.2-2c24.3-0.9,110.3-2.8,110.3-2.8 M243.7,180.7l19.4-0.2 M378.6,175.6
	c0,0-0.3,0.6,0,1.1c0.3,0.5,0.5,0.9,1.1,1 M395.3,175.7c0,0,0.2,0.3,0.1,0.8c-0.1,0.5-0.3,1-0.7,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M301.3,180.1c4.3,0,8.3,0.5,13.7,0.8c5.4,0.3,10.4,0.5,17.6,0.5
	c7.3,0,45.7-0.2,49.1-0.2s3.1,0.7,3.1,1.5s0.3,17.1,0.1,18.3c-0.2,1.1,0,4.6-9,4.6s-25.5,0.5-36.3,0.1c-10.9-0.5-18.9-0.9-27.4-3.3
	c-8.4-2.4-39.8-11.5-46.6-13.2s-8.6-2.5-10.5-3.9c-1.7-1.2-2.3-1.9-2.5-2.6c-0.3-0.7-0.4-2.1,3.1-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,182.9c0,0,13.4-0.2,19.1-0.2s14.2,0.1,22.5,0.4
	c8.1,0.3,35.3,0,43.1-0.1c7.9-0.1,24.6-0.1,24.6-0.1 M382,185.4c0,0-42.6,0.5-51.6,0.6c-9.1,0.1-12,0.1-16.4-0.1
	c-4.4-0.2-7.6-0.6-14.2-0.5c-6.7,0.1-24,0.2-24,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M385,184.5c0,0-0.9-0.4-1.5-0.4c-0.6,0-1.3,0-1.3,0.9s0.3,15,0.3,16
	s0,3.1-5.7,3.1s-20.8,0.5-30.1,0.3c-9.2-0.2-22-0.6-29.8-2.5c-5.3-1.3-26.3-7.3-32.8-9.1c-4.2-1.1-8.6-2.4-8.6-2.4 M400.1,175.3
	l-0.3,2.2l-0.1,1.8c0,0-0.7,2.9-1.4,12.5s-1.2,19-1.2,19"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M466.4,175.6l-0.1,1.7v1.8c0,0,0.3,0.8-1.2,8.1s-4.1,18.4-4.4,19.9
	s-0.5,2.2-0.5,2.2s-0.5,1.2-1.2,1c-0.8-0.1-11.7,0.4-30.8,0.5s-45,0.2-65.6,0c-20.7-0.2-38.2-0.1-46.5-2.3c-9.9-2.5-21.3-6.5-33-9.7
	c-11.2-3.1-14.9-4-22.2-5.6c-7.3-1.6-11.7-2.4-11.7-2.4s-1.1-2.9-2.5-5.2c-1.4-2.3-2.1-3.3-2.6-4.2c-0.5-0.9-0.4-1.4-0.4-2.8
	c0-1.4,2.1-3.2,2.1-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M263.2,179.8c0,0,0.1,1.9-0.6,2.8c-0.9,1-1.6,1.4-1.6,1.4 M194.7,181.8
	c0,0,0-0.7,0.9-0.7s47.9-0.7,47.9-0.7 M249.1,191c0,0-3.6-1-10.7-1.1h-16l-0.9-0.1 M459.8,210c0,0-0.3,1.3,0.7,1.2
	c1-0.1,4.9-0.1,7.9,0.4c3,0.5,4.5,1.6,5.5,2.7c0.9,1,1.1,2.6,1.1,2.6 M460.7,207.1c0,0,5.1-0.1,8.1,0.4c3,0.5,4.8,1.5,5.9,2.8
	c0.9,1.1,1.3,2.1,1.4,2.4 M406,184.4c0,0-0.1-2.5,5.2-2.5c5.6,0,34.8-0.3,38.7-0.3s5.7,1.1,5.5,3c-0.2,1.8-1.9,11.6-2.7,15.1
	c-0.8,3.5-1.6,5.4-7.4,5.4c-5.8,0-28,0.1-33-0.1c-5-0.2-7.8-0.8-7.6-4.3C405,197.1,406,184.4,406,184.4L406,184.4z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="409.5"
            cy="185.2"
            rx="1.5"
            ry="0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M407.3,197c0-0.5,0.7-0.9,1.5-0.9c0.9,0,1.5,0.4,1.5,0.9
	c0,0.5-0.7,0.9-1.5,0.9S407.3,197.5,407.3,197z M449.2,190.1c0-0.5,0.7-0.9,1.5-0.9c0.8,0,1.5,0.4,1.5,0.9c0,0.5-0.7,0.9-1.5,0.9
	S449.2,190.6,449.2,190.1L449.2,190.1z M81.8,214.9c0,0,34.7-2.8,68.2-4.8s71.7-5,75.9-5s7.5-1.4,7.5-1.4 M154.8,266.7
	c0,0.3,0.3,0.6,0.6,0.6l1.9,0.2c0.3,0,0.6-0.3,0.6-0.6v-1.1c0-0.3-0.3-0.6-0.6-0.6l-1.9,0.2c-0.3,0-0.6,0.3-0.6,0.6L154.8,266.7
	L154.8,266.7z M69,222.6c-0.1,0.2-0.6,5.4-1.2,9.1c-0.6,3.8-1.4,10.4-1.4,10.4 M77.5,215.7c0,0-7.1,0.4-9.8,0.5s-4.8,0.8-5.7,4.6
	c-0.8,3.8-1.8,8.9-1.8,8.9 M56.7,239.4l-1.6,9.3 M77.8,212.2c0,0-2.3,0-4.5,0.1s-3.1-2-2-3.4c1.1-1.4,5.7-7.6,8.6-10.9
	c2.9-3.3,6-6.3,6-6.3 M94.7,183.3c0,0,1.2-1,4.2-2c3-1,6.3-1.5,6.3-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M80,214.8l-1.7-0.4c0,0-1-0.4-0.7-1.2s2.9-8.8,4.2-12.3
	c1.3-3.4,3.8-9.1,5.2-11.2c1.3-2.2,5-6,9-6.8c4-0.8,7.8-0.9,7.8-0.9 M118,181.4c0,0,1.2,0.3,1.4,1.1c0.2,0.8,0.3,2,0.3,2
	 M66.2,232.1h-6.5 M58.5,293.6h-3.8v-2.7h3.8 M58.3,299h-3.8v-2.7h3.8 M59.2,282.2h-4.3l0.1-2.7h5.1 M61,276.6h-5.8l0.2-2.6h5.9
	 M61.4,271h-5.8l0.2-2.7h5.9 M61.9,265.3h-5.6l0.2-2.7h5.6 M62.3,259.8h-5.6L57,257h5.6 M62.8,254h-5.3l0.4-2.7h5.2 M63.2,248.4
	h-4.9l0.3-2.7h5.1 M64.3,242.6H59l0.5-2.7h5.2 M65.2,237.1h-5.1l0.5-2.7h5.3 M58.7,287.9h-4v-2.7h4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M82.3,213.6c0,0-0.6-0.2-0.9-0.2s-0.5-0.1-0.5-0.5s0.1-1,0.3-1.6
	c0.2-0.7,3.6-9.7,5.1-12.7c1.5-3.1,3-6,5.8-8.9c2.8-2.9,4.7-4.3,4.7-4.3l2.6-0.3l0.1-0.5c0,0,1.1-0.3,4.3-0.6
	c3.3-0.3,7.6-0.6,9.8-0.6h3.8c0,0,0.5,0,0.6,0.3s0.2,1.2,0.2,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M94.9,186.8l0.9,0.4c0,0,0.2,0.2-0.2,0.6c-0.3,0.4-3.8,3.8-5.9,7.2
	c-2.1,3.4-4,7-5.1,10.3c-1.2,3.3-2.2,5.9-2.2,5.9l0.1,2.2 M280.4,428.9c0,0-0.4,0.7,0.2,1.7c0.6,0.9,11.5,16.9,13,19
	c1.5,2.1,4,3.3,4,3.3 M278.3,429.5c0,0-0.2,0.4,0.7,1.8c0.9,1.4,11.5,17.5,13.1,19.3c1.6,1.8,3.6,3.3,3.6,3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268.4,428.4c0,0,5.4-1.5,6.4-1.7c1-0.2,1.2-0.3,1.7,0.3
	c0.5,0.6,3.2,1.5,3.2,1.5s-4.4,2.7-5.8,4.2c-1.4,1.5-2.4,2.5-0.4,6.8 M266,422.9h5.9c0.7,0,1.6,0,1.6-1.5v-2.5c0,0,0.4-0.2,0.4-0.7
	v-2c0,0-0.2-0.8-0.5-1c-0.3-0.2-0.4-0.6-0.4-1.2v-4.1l0.7-2.2 M263.4,419.8c0,0-0.1-2.3-0.2-5.3c-0.1-3-0.5-4.9-1.2-5.4 M275.8,420
	l0.1-1c0,0,0.3,0,0.3-0.7v-2.2c0-0.4-0.3-0.5-0.4-0.8c-0.1-0.2-0.2-1.2-0.2-2v-9.4 M280.3,453.4c0,0,0.8,1.8,3.4,1.4
	s7.1-1.5,8.6-2.2c1.5-0.7,2.2-1.8,2.2-1.8 M116.7,417.8c-4.6-0.3-4.9-0.4-9.1-1.2c-4.1-0.9-6.8-3.1-6.8-3.1s6.5,0.5,16.3,0.7
	c9.8,0.3,28.5,1,43.1,1.5c14.7,0.5,45.4,2.2,58,2.5c12.6,0.3,45,0.9,45,0.9 M276.3,417.1c0,0,29.1,0.5,50.7,0.6
	c21.5,0.1,70.5,0.5,89.9,0.5s47.4-0.1,54.4-0.1c7,0,9.6-2.5,9.9-6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,415.3c0,0,71,0.7,86.9,0.9c15.9,0.1,98.5,0.3,104.7,0.2
	c6.2-0.1,13.4,0.1,13.8-5 M119.6,412.2c0,0,8.9,1.2,33.2,2c24.3,0.9,110.3,2.8,110.3,2.8 M245.2,412c0,0-21.1-0.2-22.9-0.2
	c-1.6,0-1.2-2.2-1.3-1.1c-0.1,1.1-1.2,1.2-1.2,1.2s-3.1-0.1-13.1,0.2s-13.4,2.4-13.4,2.4s-11-0.3-26.6-1c-15.5-0.6-39.6-2-51.4-2.8
	c-11.8-0.8-17.8-7.4-22.5-12.9c-4.6-5.4-11.1-17-11.1-17s-2.3-21.8-3.7-36.8c-1.3-14.9-1.9-39.8-1.8-45.7v-0.8
	c-0.1-5.9,0.5-30.8,1.8-45.7s3.6-36.8,3.6-36.8s6.5-11.5,11.1-17c4.6-5.5,10.6-12.1,22.4-12.9s35.9-2.2,51.4-2.8
	c15.5-0.6,26.6-0.9,26.6-0.9s3.4,2,13.4,2.4c10,0.3,13.1,0.2,13.1,0.2s1,0.1,1.2,1.2c0.1,1.1-0.3-1.1,1.3-1.1s22.9-0.2,22.9-0.2
	 M243.7,414.9l19.4,0.3 M68.8,374.2l0.3-0.8c0,0,0.3-2.9,0.1-7.4c-0.2-4.5-1.8-15-3.4-33.2c-1.2-14.2-1.3-34.6-1.3-34.6v-0.8
	c0,0,0.1-20.3,1.3-34.6c1.6-18.1,3.2-28.7,3.4-33.2c0.2-4.5-0.1-7.4-0.1-7.4l-0.3-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M64.1,350.6c0,0,0.5,2.9,0.9,5.6c0.4,2.7,1.1,5.4,1.4,7.2
	c0.3,1.8,0.5,3.7,0.5,3.7s0.1,3.3,0.4,4.8c0.3,1.4,0.9,2.2,1.9,3s5.9,4,7.6,4.8c1.7,0.8,3.1,1.3,3.1,1.3s1.3,0.1,1.2-1.4
	c-0.1-1.4-2.2-21.2-2.8-28.5c-0.6-7.2-1.4-19-1.8-25.2c-0.4-7.4-0.8-27.7-0.8-27.7v-0.7c0,0,0.3-20.2,0.7-27.7
	c0.3-6.1,1.2-17.9,1.8-25.2c0.6-7.2,2.7-27.1,2.8-28.5c0.1-1.4-1.2-1.4-1.2-1.4s-1.4,0.5-3.1,1.3c-1.7,0.8-6.6,4-7.6,4.8
	c-1,0.8-1.6,1.6-1.9,3s-0.4,4.8-0.4,4.8s-0.3,1.9-0.5,3.7c-0.3,1.8-1,4.4-1.4,7.2c-0.4,2.7-0.9,5.6-0.9,5.6 M378.6,420.1
	c0,0-0.3-0.6,0-1.1c0.3-0.5,0.5-0.9,1.1-1 M395.3,420c0,0,0.2-0.3,0.1-0.8c-0.1-0.5-0.3-1-0.7-1 M301.3,415.6
	c4.3,0,8.3-0.5,13.7-0.8c5.4-0.3,10.4-0.5,17.6-0.5c7.3,0.1,45.7,0.2,49.1,0.2s3.1-0.7,3.1-1.5c0-0.8,0.3-17.1,0.1-18.3
	c-0.2-1.1,0-4.6-9.1-4.6c-9,0-25.5-0.5-36.3-0.2c-10.8,0.4-18.8,0.8-27.4,3.2c-8.4,2.4-39.8,11.5-46.6,13.2
	c-6.8,1.7-8.6,2.5-10.5,3.9c-1.7,1.2-2.3,1.9-2.5,2.6c-0.3,0.7-0.4,2.1,3.1,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,412.7c0,0,13.4,0.2,19.1,0.2s14.3-0.1,22.5-0.4s35.3,0,43.1,0.1
	c7.9,0.1,24.6,0.1,24.6,0.1 M382,410.3c0,0-42.6-0.5-51.6-0.6c-9.1-0.1-12-0.1-16.4,0.1c-4.4,0.2-7.6,0.6-14.2,0.5
	c-6.7-0.1-24-0.2-24-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M385,411.1c0,0-0.9,0.4-1.5,0.4c-0.6,0-1.3,0-1.3-0.9s0.3-14.8,0.3-15.8
	c0-1,0-3.1-5.8-3.1s-21-0.4-30.4-0.3c-9.4,0.1-22.2,0.6-30.2,2.5c-5.4,1.3-26.6,7.2-33.2,8.9c-4.3,1.1-7.3,1.9-7.3,1.9 M400.1,420.3
	l-0.3-2.2l-0.1-1.8c0,0-0.7-2.9-1.4-12.5s-1.2-19-1.2-19"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M466.5,420.1l-0.1-1.8v-1.8c0,0,0.3-0.8-1.2-8.1
	c-1.5-7.3-4.1-18.4-4.4-19.9c-0.3-1.5-0.5-2.2-0.5-2.2s-0.5-1.2-1.2-1c-0.8,0.1-11.7-0.4-30.9-0.6c-19-0.1-45-0.2-65.6,0
	c-20.7,0.2-38.2,0.1-46.5,2.3c-9.9,2.5-21.3,6.5-33,9.7c-11.2,3.1-14.9,4-22.2,5.6s-11.7,2.4-11.7,2.4s-1.1,2.9-2.5,5.2
	c-1.4,2.2-2.1,3.3-2.6,4.2c-0.5,1-0.4,1.4-0.4,2.8s1.5,3.2,1.5,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M263.2,415.8c0,0,0.1-1.9-0.6-2.8c-0.9-1-1.6-1.4-1.6-1.4 M194.8,413.8
	c0,0,0,0.7,0.9,0.7c0.9,0,47.9,0.7,47.9,0.7 M249.2,404.7c0,0-3.6,1-10.7,1.1c-7.1,0.1-16,0.1-16,0.1h-0.9 M481.6,400.6
	c0-0.2-0.4-3-1-5.2c-0.5-2.2-2.8-9.1-3.5-10.4c-0.7-1.4-1-2-1-2s0.3-0.8-0.2-2s-0.9-1.8-0.9-1.8s-0.3-5.7-0.3-14.2
	c0-8.8-0.2-31.1-0.1-39.9c0-4,0.2-25.7,0.2-25.7v-2.8c0,0-0.2-21.6-0.2-25.7c-0.1-8.9,0.1-31.1,0.1-39.9c0-8.5,0.3-14.2,0.3-14.2
	s0.4-0.6,0.9-1.8c0.5-1.2,0.2-2,0.2-2s0.3-0.7,1-2c0.7-1.4,2.9-8.2,3.5-10.4c0.5-2.2,1-5,1-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M474.7,314.9c0,0-1.2,0.6-2,0.5c-0.7-0.1-1.7-1.1-1.7-3.8
	s-0.1-12.4-0.1-12.4v-2.5c0,0,0.1-9.7,0.1-12.4s1-3.7,1.7-3.8c0.8-0.1,2,0.5,2,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M474,314.3c0,0-1.1,0.4-1.1-0.9c0-1.4-0.1-14.3-0.1-14.3v-2.2
	c0,0,0.1-13,0.1-14.3c0-1.4,1.1-1,1.1-1 M478.8,389.8v-45.4l0.2-45.1v-3.1c0,0-0.2-27.6-0.2-45.1v-45.4 M481.2,397.9
	c0.1-0.5-0.2-29.1,0-48.1c0.2-18.9,0.2-49.3,0.2-49.3v-5.3c0,0,0-30.4-0.2-49.3c-0.2-18.9,0.1-47.6,0-48.1 M459.9,385.6
	c0,0-0.3-1.3,0.7-1.2s4.9,0.1,7.9-0.4c3-0.5,4.6-1.6,5.5-2.7c0.9-1,1.1-2.6,1.1-2.6 M460.7,388.4c0,0,5.1,0.1,8.1-0.4
	c3-0.5,4.8-1.5,5.9-2.8c0.9-1.1,1.3-2.1,1.4-2.4 M406.1,411.3c0,0-0.1,2.5,5.2,2.5c5.6,0.1,34.8,0.3,38.7,0.3c3.9,0,5.7-1.1,5.5-3
	c-0.2-1.8-1.9-11.6-2.7-15.1c-0.8-3.5-1.6-5.4-7.4-5.4s-28-0.1-33,0.1c-5,0.1-7.8,0.8-7.6,4.3C405,398.5,406.1,411.3,406.1,411.3
	L406.1,411.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M408,410.5c0,0.4,0.7,0.8,1.5,0.8s1.5-0.4,1.5-0.8s-0.7-0.8-1.5-0.8
	C408.7,409.6,408,410,408,410.5L408,410.5z M407.3,398.6c0,0.5,0.7,0.9,1.5,0.9s1.5-0.4,1.5-0.9c0-0.5-0.7-0.9-1.5-0.9
	S407.3,398.1,407.3,398.6z M449.3,405.6c0,0.5,0.7,0.9,1.5,0.9c0.8,0,1.5-0.4,1.5-0.9c0-0.5-0.7-0.9-1.5-0.9
	C450,404.6,449.3,405,449.3,405.6z M221.8,252.9c0,0,0.7-5,1.4-9.1c0.7-4.1,3.6-16.8,6.3-24.8c2.8-8,8-17.7,9.7-20.7
	c1.6-3,3.8-6.1,3.8-6.1s8.6,0.6,17.2,2.8c8.7,2.2,23.8,6,29.4,7.7c5.6,1.7,15.3,4.5,17.4,5.3c2.1,0.8,5.3,3.5,3.8,8.4
	s-3.3,10-5,19.5c-1.7,9.5-2.8,16.3-3.8,25.9c-1,9.5-1.4,26.8-1.4,30.5v11c0,3.8,0.5,20.9,1.4,30.5c1,9.5,2,16.4,3.8,25.9
	c1.7,9.5,3.5,14.6,5,19.5c1.5,4.9-1.7,7.6-3.8,8.4c-2.1,0.8-11.8,3.6-17.4,5.3c-5.6,1.7-20.6,5.5-29.4,7.7s-17.2,2.8-17.2,2.8
	s-2.2-3.1-3.8-6.1c-1.6-3-6.9-12.7-9.7-20.7c-2.8-8-5.7-20.6-6.3-24.9c-0.2-1.4-0.5-2.9-0.7-4.3l-1.3-10.5c0,0-1.4-8.8-1.6-17.2
	c-0.3-8.3-0.4-21.3-0.4-21.3s0.2-14.1,0.4-22.5c0.3-8.3,1-13.2,1-13.2L221.8,252.9L221.8,252.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M241.4,400.9c0,0,9.3-1,15.7-2.3s16.5-3.9,26.4-6.7
	c9.8-2.8,17.7-5.3,20.5-6.1c2.8-0.8,5.3-2.3,3.8-7c-1.4-4.7-4.4-16.4-5.9-24.4c-1.6-7.9-2.1-13.9-2.7-20.5
	c-0.6-6.6-1.4-27.1-1.4-30.2v-11.4c0-3.1,0.8-23.6,1.4-30.2c0.6-6.6,1.1-12.6,2.7-20.5c1.6-7.9,4.5-19.6,5.9-24.4s-1-6.2-3.8-7
	s-10.7-3.3-20.5-6.1s-19.9-5.4-26.4-6.7s-15.7-2.3-15.7-2.3 M217.9,261.8c0,0,0.5-5.7,2.3-16c1.8-10.4,3.9-16.5,6.2-23.5
	s3.3-8.9,3.3-8.9c0.4-1.5,0.3-3.2-2.2-3.1c-2.5,0-9.1,0.2-12,0.8c-2.8,0.6-6,1.7-7,7.7c-0.9,5.9-2.3,15.6-2.9,27.1
	c-0.5,8.3-1.1,17.8-1.3,25.8c-0.3,7.8-0.6,24.6-0.6,24.6v3.7c0,0,0.4,16.7,0.6,24.7c0.3,7.8,0.9,17.3,1.3,25.8
	c0.6,11.4,2,21.1,2.9,27.1c0.9,5.9,4.1,7.1,7,7.7c2.8,0.6,9.5,0.7,12,0.8c2.5,0.1,2.6-1.6,2.2-3.1c0,0-1-2-3.3-8.9
	c-1.5-4.5-3-8.7-4.3-14"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M221.1,410.9c0,0,0.6-4.5,0.4-5.9c-0.3-1.4-1.3-2.5-3.1-4.1
	s-3.7-2.7-6.3-5.7c-2.7-3-4.8-5.5-5.2-7.6c-0.4-2.1-2.8-19.4-3.3-26.7c-0.5-7.2-1.6-22-2-34.3c-0.4-12.1-0.5-27.2-0.5-27.2v-3.6
	c0,0,0.1-14.9,0.5-27.2c0.4-12.1,1.5-27.1,2-34.3c0.5-7.2,2.9-24.6,3.3-26.7c0.4-2.1,2.5-4.6,5.2-7.6s4.6-4.1,6.3-5.7
	c1.8-1.6,2.8-2.7,3.1-4.1c0.3-1.4-0.4-5.9-0.4-5.9 M296.3,302.8c0.1,1.9,0.2,14.9,0.8,24.3c0.5,8,0.9,14.6,2.7,24.9
	c1.7,10.1,4,19.3,5.6,24.9c1.6,5.4-0.9,6.7-3.1,7.6c-2.2,0.9-14.3,5-25.2,7.8c-10.8,2.9-16.7,3.8-23.2,5c-6.4,1.1-9.1-2.8-10.7-5.9
	c0,0-3.2-5.8-6-14.3c-2.8-8.5-4.5-14.5-6-22.9c-1.6-8.3-3.2-21.4-3.6-29.9c-0.4-8.4-0.8-24.1-0.8-25.4v-2.2c0-1.3,0.4-16.9,0.8-25.4
	c0.4-8.4,2-21.6,3.6-29.9c1.6-8.3,3.2-14.3,6-22.9s6-14.3,6-14.3c1.6-3.1,4.3-7,10.7-5.9c6.4,1.1,12.3,2.1,23.2,5
	c10.8,2.9,23,6.9,25.2,7.8c2.2,0.9,4.7,2.2,3.1,7.6c-1.6,5.4-3.9,14.6-5.6,24.9c-1.7,10.1-2.2,16.7-2.7,24.8
	c-0.6,9.3-0.7,22.4-0.8,24.3V302.8L296.3,302.8z M180.2,300.8c0,2.4,0.2,35.3-0.1,44.2s-4.9,12.4-12.9,12.6c-8,0.2-55-2.2-67.2-3.2
	C87.7,353.6,83,351,83,351s6.3-1.2,27.4-0.4c21,0.8,46.6,2.8,50.7,2.8s9.3,1.4,9.5-8.3c0.2-9.7,0.4-44.5,0.4-44.5V295
	c0,0-0.2-34.8-0.4-44.5c-0.2-9.7-5.4-8.3-9.5-8.3s-29.8,1.9-50.7,2.8c-21,0.8-27.4-0.4-27.4-0.4s4.7-2.6,16.9-3.5s59.2-3.4,67.2-3.2
	c8,0.2,12.6,3.7,12.9,12.6s0.1,41.7,0.1,44.2L180.2,300.8L180.2,300.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M81.8,380.7c0,0,34.7,2.8,68.2,4.8c33.5,2,71.7,5.1,75.9,5.1
	s7.5,1.4,7.5,1.4 M154.9,328.9c0-0.3,0.3-0.6,0.6-0.6l1.9-0.2c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6l-1.9-0.2
	c-0.3,0-0.6-0.3-0.6-0.6V328.9L154.9,328.9z M63.2,299.2c0,0-0.1,16.7,0.9,28.4c1,11.7,2.3,21.6,2.3,21.6l-0.3,0.7l0.5,3.7
	c0,0-2.8-2.5-3.1-5.1c-0.3-2.6-1.9-22.6-2.5-31.3c-0.5-8.6-0.4-17.2-0.4-17.2v-4.5c0,0-0.1-8.6,0.4-17.2c0.5-8.6,2.1-28.7,2.4-31.3
	c0.3-2.6,3.1-5.1,3.1-5.1l-0.5,3.7l0.3,0.7c0,0-1.2,9.9-2.2,21.6s-0.9,28.4-0.9,28.4L63.2,299.2L63.2,299.2z M69,373.1
	c-0.1-0.2-0.6-5.4-1.2-9.1c-0.6-3.8-1.4-10.4-1.4-10.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M61.1,317.3c0,0-1.5-1.2-1.7-4.4c-0.3-4.8-0.7-4.8-0.7-13.6v-2.9
	c0-8.8,0.4-8.8,0.7-13.6c0.2-3.2,1.7-4.4,1.7-4.4 M77.5,380c0,0-7.1-0.4-9.8-0.5c-2.7-0.1-4.8-0.8-5.7-4.6c-0.8-3.8-1.8-8.9-1.8-8.9
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M66.8,368.4c0,0-2.4-0.1-3.5-0.1c-1.2,0-2.8-0.1-3.5-3.8
	c-0.7-3.8-2.1-9.9-3.4-21.1c-1.3-11.2-2.4-20.6-2.7-28.6s-0.3-15.4-0.3-15.4v-3.2c0,0,0-7.5,0.3-15.4s1.4-17.3,2.7-28.6
	c1.3-11.2,2.7-17.3,3.4-21.1c0.7-3.8,2.3-3.8,3.5-3.8s3.5-0.1,3.5-0.1 M56.7,356.2l-1.5-9.3 M77.9,383.3c0,0-2.3,0-4.5-0.1
	s-3.1,2-2,3.4c1.1,1.4,5.7,7.6,8.6,10.9c2.9,3.3,6,6.3,6,6.3 M94.7,412.4c0,0,1.2,1,4.2,2s6.3,1.5,6.3,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M80,380.8l-1.7,0.4c0,0-1,0.4-0.7,1.2c0.3,0.8,2.9,8.8,4.2,12.3
	c1.3,3.4,3.8,9,5.2,11.2c1.3,2.2,5,6,9,6.8c4,0.8,7.8,0.9,7.8,0.9 M118,414.2c0,0,1.2-0.3,1.4-1.1c0.2-0.8,0.3-2,0.3-2 M66.3,363.5
	h-6.6 M58.5,302h-3.8v2.7h3.8 M59.2,313.5h-4.3l0.2,2.6h5 M61,319h-5.8l0.3,2.7h5.8 M61.4,324.7h-5.8l0.3,2.6h5.8 M61.9,330.3h-5.6
	l0.3,2.7h5.6 M62.3,335.9h-5.6l0.3,2.7h5.6 M62.8,341.5h-5.3l0.4,2.7h5.2 M63.2,347.2h-4.9l0.3,2.6h5.2 M64.3,353.1H59l0.5,2.7h5.3
	 M65.3,358.5h-5.2l0.5,2.7h5.3 M58.7,307.8h-4v2.7h4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M82.4,382.1c0,0-0.6,0.2-0.9,0.2s-0.5,0.1-0.5,0.5s0.1,0.9,0.3,1.6
	c0.2,0.7,3.6,9.7,5.1,12.7c1.5,3.1,3,6,5.8,8.9s4.7,4.3,4.7,4.3l2.6,0.3l0.1,0.5c0,0,1.1,0.3,4.3,0.7c3.3,0.3,7.6,0.6,9.8,0.6h3.8
	c0,0,0.5,0,0.6-0.3c0.1-0.3,0.2-1.2,0.2-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M94.9,408.8l0.9-0.4c0,0,0.2-0.2-0.2-0.7c-0.3-0.4-3.8-3.8-5.9-7.2
	c-2.1-3.4-3.9-7-5.1-10.3s-2.2-5.9-2.2-5.9l0.1-2.2 M460.7,227.5c0,0.7-0.5,1.2-1.2,1.2H324.7c-0.6,0-1.6-0.7-0.7-1.8l1.7-2.4
	c0.5-1,1.4-1.1,2-1.1l131.8-0.1c0.7,0,1.2,0.5,1.2,1.2L460.7,227.5L460.7,227.5z M460.7,368c0-0.6-0.5-1.2-1.2-1.2H324.7
	c-0.6,0-1.6,0.7-0.7,1.8l1.7,2.4c0.5,1,1.4,1.1,2,1.1l131.8,0.1c0.7,0,1.2-0.5,1.2-1.2L460.7,368L460.7,368z M783.2,305.6
	c0,0,0.2,11,0,23.8s-0.3,30.1-0.5,43.2c-0.1,13.1-0.4,21.5-0.4,22.5S782,396,782,396l-1-0.1l1.8,1.3c0,0,0.6,0.5,0.5,1.3
	c-0.1,0.8-0.4,4-1.8,6.9c-1.4,2.9-2.7,4-4.6,4.9c0,0-3.5,1.7-5.7,2c-2.8,0.4-4.3-0.8-4.3-0.8s-17.7,1.4-35.3,2s-30.6,1.3-53.2,2
	c-22.7,0.7-66.8,2.2-87.2,2.5c-20.3,0.2-104.9,1.1-104.9,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M783.5,304.7c0,0,0.1,1.5,0,5.9s-0.1,22.9-0.3,38.6
	c-0.2,15.6-1,46.5-1,46.5l-1.5,0.2c0,0,1.4,0.6,1.9,1c0.5,0.5,0.7,1.1,0.7,1.8c-0.1,0.8-0.4,3.4-1.4,6.2s-2.5,4.2-5.4,5.8
	c-2.1,1.3-7.9,1.8-7.9,1.8l-1.6-1c0,0-21.4,1.5-36,2c-14.5,0.5-38.1,1.6-60.5,2.2c-22.5,0.7-58.8,2.2-77.5,2.2
	c-18.5,0.1-76.7,0.7-88.2,0.9 M485.7,416V295.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M485.5,415.7c0,0-0.1,1.5,2.7,1.4c2.8-0.1,75.9-0.5,95.2-1
	c19.3-0.4,66.1-1.8,81.4-2.3c15.2-0.5,60.2-2,74-2.7c13.6-0.7,27.1-1.9,30.8-2.6c3.8-0.7,6.8-2.3,8.2-5.2c1.4-3,1.7-7.1,1.7-7.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M770.1,412.3c0,0,1.7-1.1,4.4-2.8c2.7-1.6,5.3-4.1,5.8-7.1
	c0.6-2.9,0.8-5.1,0.8-5.1l-1.7-1.2c0,0-0.1,10.6-10.6,11.2s-42.3,2.2-56.3,2.8s-58.3,2-79.1,2.5c-20.8,0.5-58.2,1.5-79.6,1.8
	c-21.2,0.3-62.7,0.3-65.3,0.3c-2.6,0-2.6-1-2.6-3.5c-0.1-2.5,0-13.6,0-13.6l2.4-0.1v-0.3c0,0,0.1-0.4,0.9-0.4
	c0.8,0,95-0.6,119.9-0.9c24.9-0.3,98.7-0.4,117.8-0.8c19-0.4,37.5-0.5,42.8-0.2c5.2,0.3,7.2,0.3,9,0.5c1.8,0.2,2.6,0.5,2.6,0.5
	 M783.2,398.2c0,0-1.3-0.4-2.2-0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M776.5,395.5c0,0,0.8-0.1,0.7,1.5c-0.1,1.6-0.6,5.3-2.4,7.1
	c-1.8,1.8-5,2.4-10.7,2.7c-5.7,0.2-131.8,4.6-146.3,4.8c-14.4,0.2-91.1,1.1-106.2,1.1c-15.1,0.1-21.2,0.2-22.4,0.2
	c0,0-2.6,0.3-2.6-1.7s-0.1-13.1-0.1-13.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M489.2,412.8c0,0-1,0.1-1-0.7v-14.6 M768.7,398.4c0-0.6-0.5-1.2-1.2-1.2
	h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.4c0,0.7,0.5,1.2,1.2,1.2h7.9c0.6,0,1.2-0.5,1.2-1.2V398.4z M512.8,400.2c0-0.7-0.5-1.2-1.2-1.2h-7.9
	c-0.7,0-1.2,0.5-1.2,1.2v4.4c0,0.7,0.5,1.2,1.2,1.2h7.9c0.6,0,1.2-0.5,1.2-1.2L512.8,400.2L512.8,400.2z M493.1,396.9V292.9
	 M488.2,397.7V292.5 M758.3,394.9v-3.5h0.8c0,0,0-0.6,2.1-0.5s7.1,0.1,7.1,0.1 M782.2,395.6c0,0-5.1-1.2-8.8-1.4
	c-3.7-0.2-4.6-0.1-4.6-0.1l-0.1-94.8v-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M776.7,394.5c0,0,0.7-0.1,0.7-1.3c0-1.2,0-9.1,0.6-12.7
	c0.6-3.6,2.3-11.1,2.3-20.3c0.1-9.1,0.5-61.3,0.5-61.3v-1.3 M780.3,345.9h-11.5 M493.1,372.7l266.4-0.1l0.1,0.3h4.3c0,0,1-0.2,1-1
	v-74.4 M693.7,395.6c-4.1,0-6.5-5.5-12.8-12.6c-6.3-7.1-13.1-7.7-20.2-7.8c-7.1-0.1-45.9-0.1-51.8-0.1c0,0-10.6,0.2-15.5,4.6
	c-4.9,4.4-7.4,16.6-12.8,16.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M599.3,396.3c0,0,8.8-16,17.3-17.7c8.5-1.8,29-2.3,36.6,0.4
	c9.2,3.3,18.5,16.8,18.5,16.8 M759.5,391v-18 M782.3,393l12.2,8.2 M782.3,389.5l12.7,8.5 M782.4,386l12.7,8.6 M782.5,382.7l12.7,8.5
	 M782.6,379.2l12.8,8.6 M782.6,375.6l12.7,8.5 M782.7,372.1l12.8,8.6 M782.8,368.9l12.7,8.5 M782.9,365.4l12.8,8.6 M783,362
	l12.7,8.5 M783.1,358.4l12.8,8.6 M783.1,355.1l12.7,8.5 M783.2,351.6l12.8,8.5 M783.3,348.6l12.7,8.5 M783.2,344.8l13,8.7
	 M783.2,341.3l12.8,8.5 M783.4,337.8l12.7,8.6 M783.4,334.5l12.7,8.5 M783.4,330.9l12.9,8.7 M783.5,312.2l12.7,8.6 M783.4,315.8
	l12.8,8.6 M783.5,319.5l12.8,8.6 M783.4,327.4l12.9,8.7 M796,332.6l-6.2-4.2c0,0,0.1-0.4-0.1-0.5c-0.2-0.2-0.5-0.5-0.6-1.2
	c-0.1-0.6-0.2-1.5-0.7-1.9c-0.5-0.4-1.8-0.3-2.2-0.2c-0.4,0.2-0.7,0.3-0.7,0.3l-2-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M784.9,324.7c0,0,0.2,0.6,1.7,1.8c2.2,1.7,3.5,2.1,3.5,2.1 M792.8,303.5
	c0-0.3-0.3-0.6-0.6-0.6h-3.1c-0.3,0-0.6,0.3-0.6,0.6v8.2c0,0.3,0.3,0.6,0.6,0.6h3.1c0.3,0,0.6-0.3,0.6-0.6L792.8,303.5L792.8,303.5z
	 M783.5,310.7c0,0,1.2-5.3,1.3-11.7v-1.5 M782.8,306.3c0,0,0,1.5-0.1,2.5c0,1-0.6,1.2-0.9,0.9s-0.4-0.8-0.4-1.8v-10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M781.6,306.6l0.8,0.4l0.6-1.2c0,0,0.6-1.1,0.7-2.5
	c0.1-1.4,0.1-5.1,0.1-5.1v-1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M220.5,411.8c0,0,1.4,0.3,1.7-1.4
		c0.3-1.7,0.5-4.8,0.5-4.8s0-1.7-1.6-3.2c-1.6-1.5-3.7-3.2-3.7-3.2s-2.5-1.9-4.2-3.9c0,0-4.7-4.8-5.1-7.3c0,0-2.8-15.9-4-36.4
		c0,0-1.9-24.7-1.8-53.6c0,0,0.1-31.4,1.6-50.4c0,0,1.1-21.4,4.1-39.4c0,0-0.3-2.5,7.1-9.7c0,0,1.8-1.6,4.3-3.6c0,0,3.6-2.7,3.4-5.4
		c-0.2-2.7-0.4-4.3-0.4-4.3s-0.3-1.5-1.3-1.5c0,0-22.1,0.5-26.6-2.6c0,0-55.3,2.1-73.7,3.4c0,0-7.9,0.5-10.1,1.2
		c-2.2,0.7-8.1,1.7-16.2,11.5c0,0-5,5.8-11.5,17.5c0,0-3.2,26.6-4.3,44.8c0,0-1.4,25.2-1.2,38.5c0,0-0.2,16.9,1.9,46
		c0,0,1.6,18.1,3.6,35.8v0.7c0,0,7,13.3,15.4,21.8c0,0,6.5,7.5,18.4,8.2c0,0,19.9,1.4,38.8,2.2c18.7,0.8,38.3,1.5,38.3,1.5h0.7
		c0,0,3.4-2.2,14.1-2.4C208.5,412,213.5,411.8,220.5,411.8z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M242.4,400.9
		c0,0-11-17.4-14.7-33.7c0,0-3.9-13.6-5.1-27.2l-0.2-1.9c0,0-2.1-10.3-2.1-30.9l-0.1-17.7l0.4-11.7c0,0-0.1-7,1.6-19.3
		c0,0,1.6-14.3,4-23.6c0,0,3.5-17.2,10.5-29.7c0,0,2.8-5.6,5.7-10.2c0,0,16.9,1.4,42.1,9l18.9,5.6c0,0,4.3,0.7,5.5,3.1
		c0,0,1.1,1.7-0.1,4.7c0,0-7.3,24.1-8.4,43.5c0,0-1.8,23.6-1.5,37.3v7.2c0,0,0.4,15.9,1.4,28.3c0,0,1,15.6,4.9,30.7l3.4,13.2
		c0,0,1.7,4.3,0.1,6c0,0-0.4,1.2-3.6,2.2l-25.9,7.6c0,0-20.5,5.9-32,6.9L242.4,400.9z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M314.9,387.5l-3.1-3.3
		c0,0,0.9-1.7,0.4-4.1c0,0-3.6-10.8-5.1-19.6c0,0-1.8-9.6-3.2-20.2c0,0-1.4-10-2-28.7c0,0-0.2-5.2-0.2-13.5c0,0-0.3-7,0.7-25.8
		c0,0,0.5-16.9,5.3-40.3c0,0,1.2-6.2,4.4-15.8c0,0,0.9-2.3-0.2-5l3-3c0,0,5,2.4,28.9,2.6c0,0,35.2,0.8,54.3,0.1c0,0,37.6,0.3,62-0.5
		c0,0,0.6,0.1,0.8-0.4c0,0-0.3,0.9,0.5,1.2h0.2c0,0,8.1-0.1,10.2,1c2,1.1,3.9,2.1,4.2,4.3c0,0-0.8,28.4-0.1,81.4
		c0,0-0.6,52.9,0.1,81c0,0-0.4,4.1-6.8,5c0,0-2.4,0.4-7.6,0.4c0,0-0.9-0.2-0.7,1.2c0,0-0.4-0.4-0.8-0.4h-0.8c0,0-40.9-1.2-61.2-0.5
		c0,0-34.8-0.7-68.2,0.6C329.8,385.3,322.2,385.4,314.9,387.5z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M479.8,389.6
		c0,0-1.2-4.1-2.7-6.7c0,0,0.5-1.3-1.1-3.9c0,0-0.8-35.8-0.1-81c0,0-0.4-47.4,0.1-81.1l-0.1-0.4c0,0,1.6-1.9,1.2-4
		c0,0,1.2-2,2.6-6.3l0.3,91.9L479.8,389.6z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M765.1,395.1
		c0,0-17.5-0.6-42.6,0.3l-232.8,1.5V198.2l275.4,1.3L765.1,395.1L765.1,395.1z"
              id="Truck Bed"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Truck Bed')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('truckBedColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M768.6,394.1
		l-0.1-96.1l0.1-96.8c0,0,7.6,0.4,12.6-1.5l0.9,0.1c0,0,1.4,57.9,1.2,90.6c0,0,0.3,0.7,0.3,2.6s0,5.1,0,5.1v4.8c0,0-0.1,1.7-0.5,2.7
		v3.1c0,0-0.1,36.9-1,86c0,0,0.2,1.3-0.6,1C781.6,395.6,775.4,393.7,768.6,394.1z"
              id="Liftgate and Tailgate"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Liftgate and Tailgate')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('liftgateTailgateColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup4DrTop);
