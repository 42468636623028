import React, { Component } from 'react';

class ColorLabel extends Component {
  constructor(props) {
    super(props);
  }

  getHexValue = color => {
    switch (color) {
      case 'Beige':
        return '#CAB77D';
      case 'Black':
        return '#1D1D1D';
      case 'Blue':
        return '#2769B0';
      case 'Brown':
        return '#4A3625';
      case 'Burgundy':
        return '#62231E';
      case 'Bronze':
        return '#CAB77D';
      case 'Cream':
        return '#EBD89F';
      case 'Gold':
        return '#FBC700';
      case 'Green':
        return '#31A343';
      case 'Grey':
      case 'Gray':
        return '#849495';
      case 'Maroon':
        return '#501C18';
      case 'Orange':
        return '#D96C00';
      case 'Pink':
        return '#E960BB';
      case 'Purple':
        return '#472F97';
      case 'Red':
        return '#D93829';
      case 'Rose':
        return '#AC281C';
      case 'Silver':
        return '#B0B6BB';
      case 'Tan':
        return '#90745B';
      case 'Teal':
        return '#305B70';
      case 'White':
        return '#E8ECEE';
      case 'Yellow':
        return '#FBC700';
      default:
        return '#e6e6e6';
    }
  };

  render() {
    const { color } = this.props;
    const hex = this.getHexValue(color);
    return (
      <div className="flex items-center">
        {hex && (
          <div
            style={{
              height: '24px',
              width: '24px',
              backgroundColor: hex,
              borderRadius: '50%',
              marginRight: '10px',
            }}
          />
        )}
        <div>{color}</div>
      </div>
    );
  }
}

export default ColorLabel;
