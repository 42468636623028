import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#5E5E5E"
            d="M291.3,429.8c0,0-0.4,1.2-1.2,1.6c0,0-3.4,1.9-20,1.8h-0.2c-16.6,0.2-20-1.8-20-1.8
	c-0.9-0.4-1.2-1.6-1.2-1.6l-0.5-1.4c-2.1-2.8-1.9-8.1-1.9-8.1V306.7l47.6,85.8v27.8c0,0,0.4,5.5-1.9,8.1L291.3,429.8L291.3,429.8z
	 M550.9,430.3c0,0,0.4,1.2,1.2,1.6c0,0,3.4,1.9,20,1.8h0.2c16.6,0.2,20-1.8,20-1.8c0.9-0.4,1.2-1.6,1.2-1.6l0.5-1.4
	c2.1-2.8,1.9-8.1,1.9-8.1V307.2L548.5,393v27.8c0,0-0.4,5.5,1.9,8.1L550.9,430.3L550.9,430.3z"
          />
          <path
            fill="#FFFFFF"
            d="M421.1,394.7c-0.2,0-89.1-0.7-116.6-1.8c0,0-34.5-1.1-42.4-1.6c0,0-3.7,1.6-6.2-4.2c0,0-2.7-4.2-4.8-22.1
	c0,0-5.7-38.6-6-52.7l1.4,41.2c-4.8-37.5-2.5-67-2.5-67c1.9-16.5,13.4-34.1,15.7-37.5c0.2-0.4,1.1-1.4,1.1-1.4l-2.5-0.4
	c-0.2,0-1.4,0.2-1.6,0.2c-7.6,0.4-16.8-3.4-16.8-3.4c-8.1-3-6.7-14.1-6.7-14.1c0.2-5.5,3.7-5.8,3.7-5.8c13.1-2.1,23.3-0.7,23.3-0.7
	c6.2,0.5,7.3,6.4,7.3,6.4c0.2,1.2,0.2,4.6,0.2,4.6l1.4,0.2c0,0,1.8,0.4,3.7,0.4c0-0.2,0.4-1.1,0.4-1.2c9-27.6,35.4-61.6,35.4-61.6
	s6.7-6.7,15.2-7.8c0,0,8.3-1.6,24.1-1.6l73.4-0.5l73.4,0.5c15.6,0,24.1,1.6,24.1,1.6c8.3,1.1,15.2,7.8,15.2,7.8s26.2,34,35.4,61.6
	c0,0.2,0.4,1.1,0.4,1.2c1.9,0,3.7-0.4,3.7-0.4l1.4-0.2c0,0,0-3.4,0.2-4.6c0,0,1.1-5.8,7.2-6.4c0,0,10.3-1.4,23.3,0.7
	c0,0,3.7,0.4,3.7,5.8c0,0,1.4,11.1-6.7,14.1c0,0-9.2,3.7-16.8,3.4c-0.2,0-1.4-0.2-1.6-0.2l-2.5,0.4c0,0,0.9,1.1,1.1,1.4
	c2.3,3.4,14,21,15.7,37.5c0,0,2.3,29.5-2.5,67l1.4-41.2c-0.2,14.1-6,52.7-6,52.7c-2.1,18-4.8,22.1-4.8,22.1
	c-2.7,5.7-6.2,4.2-6.2,4.2c-8.1,0.5-42.4,1.6-42.4,1.6C510.3,393.9,421.3,394.7,421.1,394.7L421.1,394.7z"
          />
          <path
            fill="#EAEBEB"
            d="M546.9,251.8v-29.5c4.6,15.4,8,29.4,8,29.4h-0.5C554.5,251.7,551.5,251.8,546.9,251.8L546.9,251.8z
	 M546.9,195.8c8,16.8,16.8,36.6,16.8,36.6c0.7,2.3,3.4,2.8,5.7,2.8c-0.4-1.1-0.7-2.1-0.9-2.7c-4.8-14-13.8-29.4-21.6-41.2V195.8
	L546.9,195.8z M295.4,309.3c11,1.2,23.5,2.6,32.7,3c0,0,4.8,0.4,2.3-3.4c0,0-3.5-6.4-7.3-11.7c-1.6-1.6-3-3.2-4.6-4.6l-0.5-0.2
	c-3.4-0.7-13.4-3-22.6-5.3V309.3L295.4,309.3z M546.9,191.3v4.4c-3-6.2-5.8-12-8-16.1C541,182.7,543.9,186.7,546.9,191.3z
	 M546.9,222.3c-3.4-11.1-7.3-23-11.1-30.8c0,0-2.1-8.3-14-8.7c0,0-54.7-5.1-100.8-4.2c-46.2-1.1-100.8,4.2-100.8,4.2
	c-11.9,0.4-14,8.7-14,8.7c-3.7,8-7.8,19.6-11.1,30.8v29.4c6,0,14.7-0.4,23-1.1c0,0,15.6-0.2,40.9-0.7l1.8,1.2l1.8-1.2
	c17.5-0.7,30.6-1.1,58.2-1.1s40.7,0.4,58.2,1.1l1.8,1.2l1.8-1.2c25.3,0.4,40.9,0.7,40.9,0.7c8.5,0.7,17.3,1.1,23.5,1.1L546.9,222.3
	L546.9,222.3z M295.4,195.9v-4.4c3-4.6,5.8-8.7,8-11.9C301,183.9,298.2,189.8,295.4,195.9L295.4,195.9z M264.4,280.8
	c0,0-8.7-1.1-9.4,5.3c0,0-2.3,8.7,2.8,15.4c0,0,2.3,3.2,14.1,5c0,0,10.6,1.4,23.3,3v-21.9c-3.7-0.9-7.4-1.8-10.3-2.7
	C284.9,284.7,276.6,282.1,264.4,280.8L264.4,280.8z M295.4,191.5v4.4c-8,16.6-16.6,36.3-16.6,36.3c-0.7,2.1-3.4,2.8-5.7,2.8
	c0.4-1.1,0.7-2.1,0.9-2.5C278.7,218.6,287.6,203.2,295.4,191.5L295.4,191.5z M295.4,222.5c-4.6,15.4-8,29.4-8,29.4h8V222.5z
	 M577.8,280.8c0,0,8.7-1.1,9.4,5.3c0,0,2.3,8.7-2.8,15.4c0,0-2.3,3.2-14.2,5c0,0-35.9,5-56.1,6c0,0-4.8,0.4-2.3-3.4
	c0,0,3.5-6.4,7.3-11.7c1.6-1.6,3-3.2,4.6-4.6l0.5-0.2c4.6-1.1,22.6-5.1,33.1-7.8C557.1,284.7,565.6,282.1,577.8,280.8L577.8,280.8z
	 M576.8,336.9l-30.4,1.2l6.5-11.3l25.6-3.5L576.8,336.9L576.8,336.9z M265.5,336.9l30.4,1.2l-6.5-11.3l-25.6-3.5L265.5,336.9
	L265.5,336.9z"
          />
          <g>
            <g>
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M420.8,321.7c33.4,0,83.3,0.4,87.9,0.4c4.8,0,6.2,0.7,4.4,5.1
			c-4.4,11-10.3,20.5-16.6,28.1c-4.2,5.1-9.2,7.6-17.2,9.4c-8.7,1.9-36.6,7.3-58.7,7.3s-49.9-5.5-58.7-7.3
			c-7.8-1.8-12.9-4.2-17.2-9.4c-6.2-7.8-12-17.2-16.6-28.1c-1.8-4.4-0.4-5.1,4.2-5.1C337.6,322,387.5,321.7,420.8,321.7L420.8,321.7
			z M582.1,324.7c-14.5,0.7-29.5,3-43.5,8c-4.2,1.6-8.3,4.8-9.7,6.9c-1.2,1.8-0.5,2.1,0.4,2.1c3-0.2,3-0.2,8.3-0.4
			c-3.7,5.3-6.7,12.6-8,18.4c-1.1,4.6-1.1,4.4,5,5.3c11.1,1.8,29,2.6,38.4,0.4c5-1.2,6-3.2,6.9-5.5c0.9-2.1,5.1-14,6.4-30.1
			c0.2-1.8-0.4-3.2-1.2-4.1C584,324.9,582.8,324.7,582.1,324.7L582.1,324.7z M519.1,297.3c20.9-21.8,32.7-30.6,45.6-37.5
			c1.9-1.1,0.4-3.5,0.4-3.5c-3.2-5-10.1-4.6-10.1-4.6s-10.1-41.4-19.1-60.1c0,0-2.1-8.3-14-8.7c0,0-54.7-5.3-100.8-4.2"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M519.1,297.3c0,0-1.1-1.4-15.6-2.1c0,0-46-1.4-82.4-1.8 M518.4,164.5
			c0,0,3.9,9.4,3.9,17.3l5.8,1.1c8.3,2.1,11.8,11.9,11.8,11.9c11.5,26,19.5,56.6,19.5,56.6 M513.3,163.8c3.2,1.8,3.9,17,3.9,17
			s-56.1-5-96-3.9"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M569,234.3c-8.8-27.6-35.5-62.1-35.5-62.1s-6.7-6.7-15-7.8
			c0,0-8.3-1.6-24.1-1.6l-73.4-0.5 M582.1,248.5c0,0,14.1,19.8,16.1,38c0,0,2.3,29.5-2.5,67"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M594.1,270.4l3,0.5l2.1,6.2l-2.7,2.1 M538.2,178.4
			c8,14.7,25.5,53.9,25.5,53.9c1.4,4.2,9.4,2.5,9.4,2.5l1.4-0.2"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M567.4,235c0,7.6,4.2,7.6,4.2,7.6h4.6l0.9,0.2 M533.6,172.2
			c0,0,21.8,43.7,31.5,79.4"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M537.5,177.4c0,0,23,45.3,29.5,73l0.7,1.8c1.1-3.2,3.5-3.9,3.5-3.9
			c8-0.4,12.6-1.1,12.6-1.1"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M574.6,229.9c0,0,1.1-5.8,7.3-6.4c0,0,10.3-1.4,23.3,0.7
			c0,0,3.7,0.4,3.7,5.8c0,0,1.4,11.1-6.7,14.1c0,0-9.7,3.9-17.5,3.2c0,0-10.3-2.7-10.3-12.9C574.5,234.5,574.5,231.1,574.6,229.9
			L574.6,229.9z M596,352.8V420c0,0,0.2,5.3-1.9,8.1l-0.5,1.4c0,0-0.4,1.2-1.2,1.6c0,0-3.4,1.9-20,1.8h-0.2c-16.6,0.2-20-1.8-20-1.8
			c-0.9-0.4-1.2-1.6-1.2-1.6l-0.5-1.4c-2.3-2.7-1.9-8.1-1.9-8.1v-27.8"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M421.3,394.7c0,0,89.1-0.7,116.4-1.8c0,0,34.5-1.1,42.5-1.6
			c0,0,3.7,1.6,6.2-4.2c0,0,2.7-4.2,4.8-22.1c0,0,5.7-38.6,6-52.7c0,0,1.1-3.4-2.1-8.1c0,0-1.2-3.2-7.8-8.8 M548.6,272.2
			c0,0,9.9-4.8,34.5-6.2c0,0,6.7-2.1,13.8,14.7 M567.7,252c0,0,6.4,4.2,13.3,13.6"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M523.7,292.7c3.5-0.9,22.8-5.1,33.6-8c0,0,8.3-2.7,20.5-3.9
			c0,0,8.7-1.1,9.4,5.1c0,0,2.3,8.7-2.8,15.4c0,0-2.3,3.2-14.2,5c0,0-35.9,5-56.1,6c0,0-4.8,0.2-2.3-3.4c0,0,3.5-6.4,7.3-11.7
			 M421.1,380.1c0,0,29-0.2,47.8-2.1c0,0,19.8-1.6,29,0c0,0,16.1,2.6,40.9,14.7 M323.1,297.3c-20.9-21.8-32.7-30.6-45.6-37.5
			c-1.9-1.1-0.4-3.5-0.4-3.5c3.2-5,10.1-4.6,10.1-4.6s10.1-41.4,19.1-60.1c0,0,2.1-8.3,14-8.7c0,0,54.7-5.1,100.8-4.2"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M276.1,251.7c11.5,6.4,50.9,32.4,69.5,70.2c-31.1,0.5-65.1-4.2-83.3-8.7
			c-7.6-1.8-8.7-5.7-10.6-14.9 M566,251.7c-11.5,6.4-50.9,32.4-69.5,70.2c31.1,0.5,65.1-4.2,83.3-8.7c7.6-1.8,8.7-5.7,10.6-14.9"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M323.1,297.3c0,0,1.1-1.4,15.6-2.1c0,0,46-1.4,82.4-1.8 M323.8,164.5
			c0,0-3.9,9.4-3.9,17.3l-5.8,1.1c-8.3,2.1-11.9,11.9-11.9,11.9c-11.5,26-19.5,56.6-19.5,56.6 M329,163.8c-3.2,1.8-3.9,17-3.9,17
			s56.1-5,96-3.9"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M273.3,234.3c8.8-27.6,35.5-62.1,35.5-62.1s6.7-6.7,15.2-7.8
			c0,0,8.3-1.6,24.1-1.6l73.4-0.5 M260.2,248.5c0,0-14.1,19.8-16.1,38c0,0-2.3,29.5,2.5,67"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M248.1,270.4l-3.2,0.5l-1.9,6.2l2.5,2.1 M304,178.4
			c-8,14.7-25.3,53.9-25.3,53.9c-1.4,4.2-9.4,2.5-9.4,2.5l-1.4-0.2"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M274.7,235c0,7.6-4.2,7.6-4.2,7.6h-4.6l-0.9,0.2 M308.6,172.2
			c0,0-21.8,43.7-31.5,79.4"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M304.7,177.4c0,0-23,45.3-29.5,73l-0.7,1.8c-1.1-3.2-3.5-3.9-3.5-3.9
			c-8-0.4-12.6-1.1-12.6-1.1"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M267.4,229.9c0,0-1.1-5.8-7.2-6.4c0,0-10.3-1.4-23.3,0.7
			c0,0-3.7,0.4-3.7,5.8c0,0-1.4,11.1,6.7,14.1c0,0,9.7,3.9,17.5,3.2c0,0,10.3-2.7,10.3-12.9C267.8,234.5,267.8,231.1,267.4,229.9
			L267.4,229.9z M246.2,352.8V420c0,0-0.2,5.3,1.9,8.1l0.5,1.4c0,0,0.4,1.2,1.2,1.6c0,0,3.4,1.9,20,1.8h0.2c16.6,0.2,20-1.8,20-1.8
			c0.9-0.4,1.2-1.6,1.2-1.6l0.5-1.4c2.3-2.7,1.9-8.1,1.9-8.1v-27.8"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M420.9,394.7c0,0-89.1-0.7-116.4-1.8c0,0-34.5-1.1-42.4-1.6
			c0,0-3.7,1.6-6.2-4.2c0,0-2.7-4.2-4.8-22.1c0,0-5.7-38.6-6-52.7c0,0-1.1-3.4,2.1-8.1c0,0,1.2-3.2,7.8-8.8 M293.6,272.2
			c0,0-9.9-4.8-34.5-6.2c0,0-6.7-2.1-13.8,14.7 M274.5,252c0,0-6.4,4.2-13.1,13.6"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M318.5,292.7c-3.5-0.9-22.8-5.1-33.6-8c0,0-8.3-2.7-20.5-3.9
			c0,0-8.7-1.1-9.4,5.3c0,0-2.3,8.7,2.8,15.4c0,0,2.3,3.2,14.1,5c0,0,35.9,5,56.1,6c0,0,4.8,0.4,2.3-3.4c0,0-3.5-6.4-7.2-11.7
			 M421.1,380.1c0,0-29-0.2-47.8-2.1c0,0-19.8-1.6-29,0c0,0-16.1,2.6-41,14.7"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M420.9,394.7c0,0-90-1.1-117.4-2.1c24.8-12,40.9-14.7,40.9-14.7
			c9.2-1.6,29,0,29,0c18.7,1.8,47.8,2.1,47.8,2.1s29-0.2,47.8-2.1c0,0,19.8-1.6,29,0c0,0,16.1,2.7,41,14.7
			c-27.4,1.1-117.4,2.1-117.4,2.1H420.9L420.9,394.7z M544.9,337.6l5.1-8.3 M572.9,335.7l2.7-10.4 M289,306c4.1,0,7.4-3.4,7.4-7.4
			c0-4.1-3.4-7.4-7.4-7.4s-7.4,3.4-7.4,7.4S284.9,306,289,306L289,306z"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M262.3,283.3c0,0,5.5,0.4,9.9,1.2c0,0,11.3,2.3,17,4.1
			c0,0,10.3,2.7,13.6,3.5c0,0,13.4,2.8,15.2,3.2c0,0,0.9,0.2,1.8,1.6c0,0,6,8.1,8.8,14.3c0,0-4.6-0.2-8.1-0.5c0,0-16.3-1.6-24.2-2.5
			l-12.6-1.6c0,0-1.8-0.2-4.4-0.5c0,0-10.3-0.9-14.9-3.2c0,0-1.2-7.6-1.8-11.5l-0.7-7.6C261.8,283.8,261.8,283.5,262.3,283.3
			L262.3,283.3z"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M276.6,292.9c0,0-9.7-1.6-12-1.8c0,0-1.1-0.2-0.9,0.9s-0.2,0-0.2,0
			s0.4,2.8,1.2,3.9c0,0,1.1,1.4,3,1.9c0,0,6.7,1.4,7.8,1.6 M295.7,308.1c0,0,1.6-0.2,2.8-0.7l0.4-0.2c0,0,0.9-1.1,1.4-3.2
			c0,0,0.5-1.1,0.7-3.7c0,0,0.9-3.5-1.8-7.1c0,0-0.9-0.9-2.3-1.6l-3-1.4"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M288.5,288.3c0,0-0.5-0.5-2.3-0.4c0,0-0.7-0.2-1.8-0.4
			c-1.8-0.2-3.9,0.7-3.9,0.7c-2.1,0.9-3.9,4.8-3.9,4.8c-0.7,1.2-1.2,5.7-1.2,5.7c0,2.7,1.1,5.1,1.1,5.1c1.8,1.8,6.5,2.8,6.5,2.8
			 M294.5,290.4l-2.1-1.1 M302.3,293.4l13.4,2.8c0,0,1.9-0.2,3.5,2.3c0,0,3.5,5.1,6,9.7c0,0,0.7,1.8-0.5,1.8c0,0-19.1-1.6-24.6-2.3
			c0,0,1.6-3.9,1.9-7.6C302.3,300.3,302.4,295.7,302.3,293.4L302.3,293.4z M553.2,306c-4.1,0-7.4-3.4-7.4-7.4c0-4.1,3.4-7.4,7.4-7.4
			s7.4,3.4,7.4,7.4S557.3,306,553.2,306z"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M580,283.3c0,0-5.5,0.4-9.9,1.2c0,0-11.3,2.3-17,4.1
			c0,0-10.4,2.7-13.6,3.5c0,0-13.4,2.8-15.2,3.2c0,0-0.9,0.2-1.8,1.6c0,0-6,8.1-8.8,14.3c0,0,4.6-0.2,8.1-0.5c0,0,16.3-1.6,24.1-2.5
			l12.6-1.6c0,0,1.8-0.2,4.4-0.5c0,0,10.3-0.9,14.9-3.2c0,0,1.2-7.6,1.8-11.5l0.7-7.6C580.3,283.8,580.5,283.5,580,283.3L580,283.3z
			"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M565.4,292.9c0,0,9.7-1.6,12-1.8c0,0,1.1-0.2,0.9,0.9s0.2,0,0.2,0
			s-0.4,2.8-1.2,3.9c0,0-1.1,1.4-3,1.9c0,0-6.7,1.4-7.8,1.6 M547.1,308.1c0,0-1.1-0.2-2.7-0.4l-1.8-1.2c-1.1-1.9-0.7-1.2-1.1-2.3
			c0,0-0.5-1.4-0.7-4.1c0,0-0.9-4.1,1.4-6.9l2.8-1.8l3.2-1.4"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M553.6,288.3c0,0,1.2-0.4,3-0.7l0.9-0.2c1.8-0.2,3.9,0.7,3.9,0.7
			c1.9,0.9,3.9,4.8,3.9,4.8c0.7,1.2,1.4,5.7,1.4,5.7c-0.2,2.1-1.2,5-1.2,5c-1.4,1.9-5.7,3-5.7,3 M547.9,290.2c0,0,0.9-0.5,2.1-1.1
			 M540,293.4l-13.3,2.7c0,0-2.5,0-3.7,2.5c0,0-3.5,5.1-6,9.7c0,0-0.9,1.8,0.5,1.8c0,0,19.1-1.6,24.6-2.3c0,0-1.6-3.9-1.9-7.6
			C540,300.3,539.8,295.7,540,293.4L540,293.4z M252,389.8h35.9c0,0,15.6,0.2,18.7-0.2c0,0,16.5-1.4,19.1-1.8c0,0,7.1,0.4,11,1.6
			c0,0,2.3,0,2.7,1.9c0,0,1.2,3-2.1,3c0,0-10.8,1.1-21.9,0.9l-48.8,0.4l-12.9,0.2c0,0-1.9,0.2-3.2-1.1c0,0-1.2-0.5-1.2-2.3v-1.6
			C249.2,391,249.4,389.8,252,389.8"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M252,389.8h35.9c0,0,15.6,0.2,18.7-0.2c0,0,16.5-1.4,19.1-1.8
			c0,0,7.1,0.4,11,1.6c0,0,2.3,0,2.7,1.9c0,0,1.2,3-2.1,3c0,0-10.8,1.1-21.9,0.9l-48.8,0.4l-12.9,0.2c0,0-1.9,0.2-3.2-1.1
			c0,0-1.2-0.5-1.2-2.3v-1.6C249.2,391,249.4,389.8,252,389.8L252,389.8z M590.2,389.8h-35.9c0,0-15.6,0.2-18.7-0.2
			c0,0-16.4-1.4-19.1-1.8c0,0-7.1,0.4-11,1.6c0,0-2.3,0-2.7,1.9c0,0-1.2,3,2.1,3c0,0,10.8,1.1,21.9,0.9l48.8,0.4l12.7,0.2
			c0,0,1.9,0.2,3.2-1.1c0,0,1.2-0.5,1.2-2.3v-1.6C592.9,391,592.9,389.8,590.2,389.8"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M590.2,389.8h-35.9c0,0-15.6,0.2-18.7-0.2c0,0-16.4-1.4-19.1-1.8
			c0,0-7.1,0.4-11,1.6c0,0-2.3,0-2.7,1.9c0,0-1.2,3,2.1,3c0,0,10.8,1.1,21.9,0.9l48.8,0.4l12.7,0.2c0,0,1.9,0.2,3.2-1.1
			c0,0,1.2-0.5,1.2-2.3v-1.6C592.9,391,592.9,389.8,590.2,389.8L590.2,389.8z M572.3,368.4c-8.8,2.3-40.3,2.5-63.5-1.2
			c-2.8-0.5-5-2.8-3-6.7c4.4-8.7,8.1-15.4,14.5-23.3c2.1-2.5,4.1-4.1,8.8-5.8c14.2-5,36.3-8.8,51.5-9.5c3.4-0.2,7.4,2.6,7.1,8.5
			c-1.2,15.7-5.3,27.8-6.5,31C580.1,364,578.5,366.8,572.3,368.4L572.3,368.4z"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M583.1,265.8c13.4,5.7,5.1,58.4,4.8,64.2c-1.2,15.7-5.3,27.8-6.5,31
			c-1.1,3-2.1,8.7-8.3,10.3c-5.1,1.4-11.7,2.1-24.9,3.2"
              />
              <path
                fill="none"
                stroke="#010101"
                strokeWidth="0.5"
                d="M590,373.3c-34.8,0-85.1,3.5-85.1,3.5c-18.7,0-33.2,1.2-33.2,1.2
			c-18.7,1.8-47.8,2.1-47.8,2.1"
              />
            </g>
          </g>
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M287.2,251.7
		c0,0,20.2,0.2,28.3-0.9c0,0,2.5-0.5,4.8-0.2l38.6-0.7l1.8,1.2l1.8-1.2c0,0,61-2.3,116.4,0l1.8,1.2l1.8-1.2l38.9,0.7
		c0,0,1.9-0.2,3.5,0.2c0,0,18.7,1.4,30.1,0.9c0,0-11.8-46.5-19.1-60.1c0,0-2.1-8.3-14-8.7c0,0-41-4.8-100.8-4.2
		c0,0-50.6-0.7-100.8,4.2c0,0-10.4-0.5-14,8.7C306.3,191.5,297.1,211.9,287.2,251.7z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M332.9,322c0,0,100.5-0.7,176,0
		c0,0,4.1-0.4,4.8,1.2c0.7,1.4-0.2,3.5-0.2,3.5s-4.2,12.9-15.4,26.9c0,0-4.2,6-8.7,8s-9.9,3.4-9.9,3.4s-30.1,6.9-58.2,7.3
		s-63.9-8.5-63.9-8.5s-5.7-1.2-11-6.5c0,0-7.6-8.8-11.7-16.8c0,0-4.4-8.3-6.5-13.6c0,0-1.1-2.7,0.2-3.7
		C329.7,321.7,332.9,322,332.9,322z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M252.2,373.3
		c0,0-6.9-47.8-7.1-61c0,0-1.1-3.9,3.2-9.7c0,0,2.5-3.9,6.9-7.3c0,0,1.2,4.4,3.2,6.4c0,0,3.4,2.8,12.6,4.4c0,0,33.1,4.4,45.1,5.3
		l12.7,0.9c0,0,1.2,0,2.3-0.7c0,0,9.6,5.1,14.9,10.4h-14.9c0,0-3.7,0-2.8,3.2c0,0,2.3,7.8,9.7,19.8c0,0,7.6,12.7,14.9,16.4
		c0,0,5,3.5,35.6,8c0,0,18.9,2.8,33.3,2.7c0,0,14.5,0.2,35.9-3.2c0,0,27.4-3.9,32.5-7.6c0,0,5.1-1.9,12.6-13.4c0,0,8-11.5,11.3-21.9
		c0,0,1.6-3.9-2.8-4.1l-14.1,0.2c0,0,8-6.5,14.9-10.4c0,0,1.1,0.9,3.2,0.7c2.1-0.2,12.7-0.9,23-1.9c0,0,23.2-2.8,23.7-2.8
		s17.2-1.2,22.5-5.7c0,0,2.3-2.7,3.2-6.4c0,0,6,5,7.8,8.8c0,0,3.2,4.1,2.1,8.1c0,0,0,10.6-2.5,26.2l-3.2,23.2c0,0-0.5,3.5-0.7,5.1
		s-2.1,18.7-6.2,23h5.5c0,0,2.7-0.2,2.7,1.2c0,1.4,0,2.5,0,2.5s-1.1,2.1-4.6,2.5l-55-0.5c0,0-29,0.2-30.6-1.9l-81.5,1.2l-81.9-1.2
		c0,0,0.7,0.9-4.1,1.2c-4.8,0.4-15.2,0.7-15.2,0.7l-26.4,0.2l-38.9,0.4c0,0-2.3,0.4-4.2-1.1c0,0-1.2-0.7-1.2-1.9c0-1.2,0-1.8,0-1.8
		s0.2-1.2,2.3-1.2s5.8,0,5.8,0S254,386.1,252.2,373.3z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M258.2,301.7
		c0,0,3.5,2.8,12.9,4.4s51.5,5.7,51.5,5.7s6.4,0.9,7.6,0.2s1.2-1.2,0.7-2.1l-7.4-12.2l-4.6-5l-32.7-7.6c0,0-6.7-1.9-11.5-2.7
		c-4.8-0.7-10.6-1.6-10.6-1.6s-7.3-0.7-8.7,4.4c0,0-1.4,5.3-0.2,10.3C255,295.3,256.5,300.1,258.2,301.7z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M514.7,312.3
		l14-1.1l23.5-2.7l15.7-1.8c0,0,11.1-1.2,15.7-5c0,0,4.1-4.1,3.7-11.7l-0.5-4.1c0,0-0.5-5.7-8.5-5.3c0,0-6.2,0.4-15.7,2.5l-8.1,2.1
		l-31.5,7.4l-4.4,4.6l-2.1,3.7l-5,8.1c0,0-1.1,1.6,0.4,2.3C511.9,311.8,512.9,312.5,514.7,312.3z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M518.6,297.5l-7.2,12
		c-0.9,1.5,0.4,2.2,0.4,2.2c-6.3,3.3-15.2,10.4-15.2,10.4C433,321.5,346,322,346,322c-4.9-5-15.1-10.6-15.1-10.6
		c0.8-0.6,0-1.6,0-1.6l-7.4-12.2l-0.5-0.5c0,0-24.2-26.5-45.1-37.3c0,0-4.2-1.6,1.8-6.2c0,0.2,2.6-1.9,7.6-1.9
		c0,0,15.6,0.2,28.3-0.9c0,0,1.9-0.5,4.2-0.4l39.1-0.5l1.8,1.2l1.8-1.2c0,0,71.1-2.3,116.4,0l1.8,1.2l1.8-1.2l37.7,0.7
		c0,0,3,0,5.7,0.2c0,0,18,1.2,29,0.9c0,0,7.6-0.5,10.4,5c0,0,1.2,2.3-0.9,3c0,0-17.2,7.4-45.3,37.5L518.6,297.5z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe4DrFront);
