import React, { Component } from 'react';

class CompleteStatusIcon extends Component {
  render() {
    const { size = 64, color = '#4D9BB0' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM8.02575 32C8.02575 45.2406 18.7594 55.9743 32 55.9743C45.2406 55.9743 55.9743 45.2406 55.9743 32C55.9743 18.7594 45.2406 8.02575 32 8.02575C18.7594 8.02575 8.02575 18.7594 8.02575 32Z"
          fill={color}
        />
        <path
          d="M29.1417 38.5043C28.9862 38.5043 28.8403 38.4798 28.7042 38.4308C28.5681 38.3826 28.4417 38.3001 28.3251 38.1835L23.3084 33.1668C23.0945 32.9529 22.9922 32.6756 23.0016 32.335C23.0117 31.9951 23.1237 31.7182 23.3376 31.5043C23.5515 31.2904 23.8237 31.1835 24.1542 31.1835C24.4848 31.1835 24.757 31.2904 24.9709 31.5043L29.1417 35.6751L39.0292 25.7876C39.2431 25.5737 39.5204 25.4668 39.8611 25.4668C40.201 25.4668 40.4778 25.5737 40.6917 25.7876C40.9056 26.0015 41.0126 26.2784 41.0126 26.6183C41.0126 26.959 40.9056 27.2362 40.6917 27.4501L29.9584 38.1835C29.8417 38.3001 29.7153 38.3826 29.5792 38.4308C29.4431 38.4798 29.2973 38.5043 29.1417 38.5043Z"
          fill={color}
        />
      </svg>
    );
  }
}

export default CompleteStatusIcon;
