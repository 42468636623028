import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import axios from 'axios';
import * as rax from 'retry-axios';
import { loadARITerms } from '../actions';
import { getConfig } from '../utils/helpers';
import { Button, ButtonGroup } from 'semantic-ui-react';
import Modal from 'react-modal';
rax.attach();


const styles = {
  header: {
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    width: '65%',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 40,
    paddingBottom: 40,
  },
  button: {
    color: 'white',
    margin: 5,
    width: 80,
    height: 40,
    fontSize: 14,
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  messageText: {
    margin: 20,
    textAlign: 'center',
  },
  customStyles: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      position: 'fixed',
      height: window.innerHeight,
      width: window.innerWidth,
      maxHeight: window.innerHeight < 670 ? '100%' : 660, //temp fix
      maxWidth: window.innerWidth < 850 ? '100%' : 840, //temp fix
      overflow: 'hidden',
      padding: 20,
      outline: 'none',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .8)',
    },
  },
};

/** Currently, this Component is specific to Holman (ARI)
 * @param {boolean | undefined} isOpen conditionally renders Modal component
 * @param {(v: boolean) => void} handleTermsClick callback that takes `accept` or `denied` truthy value. 
 * @param {function} onClose event handler callback when closing Modal. _unused_
 * @param {object} termsData `{termId, termHtml, ... }`
 */
class TermsAndConditionsModal extends Component {
  
  state = {
    loading: false,
    termsMarkup: null,
    customerName: getConfig('name'),
    showErrorMessage: false,
    showRetryButton: false
  };

  componentDidMount() {
    this.loadTerms();
  }

  loadTerms() {
    const {termsData} = this.props;
    if (termsData && termsData.termsHtml) {
      this.setState({termsMarkup: termsData.termsHtml, showErrorMessage: false});
    } else {
      this.setState({showErrorMessage: true});
    } 
  }

  renderErrorMessage() {
    let errorText = 'An error occured while fetching the terms and conditions'
    errorText += 
      this.state.customerName
        ? ` for ${this.state.customerName}.`
        : '.'
    return (
      <div style={styles.messageContainer}>
        <div style={styles.messageText} >
          <p>{errorText}</p>
          <p>Please exit the try again.</p>
        </div>
      </div>
    )
  }

  renderRetry() {
    return (
      <div style={styles.messageContainer}>
        <div style={styles.messageText}>
          <div>An error occured while fetching the terms and conditions.</div>
          <div>Please try again.</div>
        </div>
        <Button
          style={{ width: 100, margin: 20 }}
          onClick={() => this.loadTerms()}
        >
          RETRY
        </Button>
      </div>
    );
  }

  renderLoading() {
    return (
      <div style={styles.messageContainer}>
        <div style={styles.messageText}>Loading terms and conditions...</div>
      </div>
    );
  }

  renderMarkup() {
    return (
      <div>
        <h2 style={styles.header}>
          Scroll to the bottom to accept the Terms and Conditions
        </h2>
        <hr style={{ width: '100%' }} />
        <div
          className="terms-markup"
          ref={node => (this.termsRef = node)}
          dangerouslySetInnerHTML={{
            // __html: DOMPurify.sanitize(this.state.termsMarkup),
            __html: DOMPurify.sanitize(this.props.termsData.termHtml),
          }}
        />
        <ButtonGroup size="huge" style={styles.buttonGroup}>
          <Button
            style={{ ...styles.button, backgroundColor: '#5CBE7F' }}
            onClick={() => this.props.handleTermsClick(true)}
          >
            AGREE
          </Button>
          <Button
            style={{ ...styles.button, backgroundColor: 'red' }}
            onClick={() => this.props.handleTermsClick(false)}
          >
            DISAGREE
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        shouldCloseOnEsc
        style={styles.customStyles}
        contentLabel={'terms'}
        ariaHideApp={false}
      >
        <div style={{ minHeight: 260, width: 800 }}>
          {this.state.loading && this.renderLoading()}
          {(this.props.termsData && this.renderMarkup()) || 
            (!this.stateloading && this.renderErrorMessage())}
        </div>
      </Modal>
    );
  }
}


TermsAndConditionsModal.propTypes = {
  handleTermsClick: PropTypes.func,
  isOpen: PropTypes.bool,
  termsData: PropTypes.object,
};

const matchPropsToState = state => {
  return {termsData: state.entities.ariTermsAndConditions};
}

export default connect(matchPropsToState, { loadARITerms })(TermsAndConditionsModal);
