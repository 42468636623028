import React, { Component } from 'react';
import { connect } from 'react-redux';

class Wagon4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M793.3,368.5v6.1c0,1.6-1,2.8-2.8,3.7c-1.2,0.7-4.4,2.2-7.4,2.8c-1.4,0.3-3.2-0.3-4.1-0.3
	c-2.9,0.3-9.4-1-12.4-0.8c-25.5,1.1-57.4,2.2-59,2.2c-1.5,0-1.5-1.5-1.5-2.3c1.1-36.3-14.9-79-63.3-79.4
	c-33.8-0.3-58.3,23.8-60.1,65.9c0,0.4-0.4,0.7-0.4,1.1v1.8c0,0.3,0.4,0.7,0.4,1.1c0,3.2,0.1,6.5,0.3,9.9c0,1.6-1.2,1.9-2.3,1.9
	c-0.3,0-10.6-0.8-27.4-0.8c-46.9,0-143.9-0.1-209-0.1c-37.4,0-64.4,0.8-64.9,0.8c-2.1,0-2.1-0.7-2.2-2.5c-0.1-3.6-0.4-7.3-0.8-11
	c0-0.1,0.3-0.3,0.3-0.4c0-0.4-0.1-1-0.1-1.4c0-0.3-0.3-0.4-0.3-0.7c-2.3-28.3-17.2-64-60.4-64c-30.4,0-54.6,18.6-60.3,56
	c0,0.4-0.7,0.7-0.8,1.1c0,0.3,0.6,0.8,0.6,1.1c-0.6,4.7-1,9.8-1,15c0,1.8-0.6,2.6-2.3,2.3c-6.1-1-12.7-2.5-26.4-4.5
	c-16.1-2.5-34.9-5.6-42.6-5.6c-5.1,0-5.4,0.1-8.3,0.1c-6.1,0-20.6-1.8-21.5-2.3c-0.8-0.5-2.5-5-2.8-5.6c-0.3-0.7-0.4-1.4-0.4-3.4
	c0-0.8-0.1-4.7-0.3-8.4c0-0.8,0.7-1.4,0.7-2.3v-0.8c0-0.5-0.8-0.5-0.8-1.2c-0.1-2.9-0.1-6.1-0.1-7.3c0-2.1,0.4-3.4,0.4-6.3
	c0-1.8,0.4-1.9,3.6-2.5c3.3-0.5,3.9-0.7,4-1.5c1.8-9.6,4.8-16.6,5.2-18c0.4-1.5,2.1-1.9,2.1-2.9s-0.3-5.6-0.3-12.4
	c0-9.1,1.4-17.5,1.5-18.2c0.3-0.7-0.3-0.7-1-0.7c-2.2,0-2.5-0.4-2.5-1.6v-3.9c0-1.4,0.8-1.4,1.4-2.8c0.5-1.4,3.4-8.8,3.4-16
	c0-0.4,1.2-2.3,1.5-2.5c0.3-0.1,5.8-1.2,9.5-2.1c1.1-0.3,10.7-13.6,27-29c14.9-14,27.8-23.2,27.8-23.5c0-0.4-3.4-4.8-3.3-6.2
	c0-0.4,1.2-1.6,1.6-1.8c0.1,0,4.7-1.8,26.6-3.4c1-0.1,1.9-0.1,3-0.3c0.4,0,0.7,0.4,1.1,0.3c0.6,0,1.1-0.6,1.7-0.6
	c42.6-3.2,104.5-6.9,154.3-6.9c85,0,137,5.9,165.1,18.7c0.4,0.1,0.5,0.8,1.1,1c38.2,17.6,97,55,108.4,62c1.2,0.7,2.1,1.1,3.3,1.1
	c7.8,0,16.6-2.1,17.5-2.1c0.1,0,2.8,1.2,8.7,1.9c18.4,2.1,59.8,6.1,85.4,10.5c35.4,6.2,67.8,17.2,74.8,26c0.4,0.4-0.3,1,0,1.5
	c0.3,0.4,1.7,0.8,1.8,1.2c0.6,1,1,1.8,1,2.2c0,0.8-1.5,0.3-3,0.3s-2.6-1.7-5.9-1.7c-1,0-1,1.1-0.7,1.5c0.1,0.3,2.2,2.9,5.6,9.5
	s5.1,12.4,5.4,12.9c0.1,0.4,5.5,3.6,5.9,4.8c0.3,1.2,1.7,1.4,1.7,2.8c0,0.4,0,9.5-0.1,10.7c0,0.3-0.1,0.5-0.1,0.8
	c0,0.1,0.1,0.4,0.1,0.8c-0.3,2.9-0.1,6.7-0.1,7.4c0,0.4-0.1,1.2-0.1,1.9c0,0.3-0.4,0.5-0.4,0.8c0,0.4,0.3,0.8,0.3,1
	c0,1.8,0,5.6-0.1,6.9c-0.3,6.3-1.1,4.4-1.1,7.6C792.1,367.1,793.3,366.7,793.3,368.5L793.3,368.5z"
          />
          <path
            fill="#5E5E5E"
            d="M767.7,368.6c1.7,0.1,3.4-0.8,5-0.7c10,0.4,17.3,0.7,17.9,0.7c1,0,1.5-0.1,1.9-0.3c0.1-0.5,0.1-1,0.1-1.5
	c-0.3-0.3-0.6-0.5-0.6-1.2c0-3.2,0.8-1.4,1.1-7.6c0-1.2,0.1-5.1,0.1-6.9c0-0.3-0.4-0.5-0.3-1c0,0-1.1-0.3-3-0.5
	c-7.2-1.2-25.3-4.4-29.4-4.4c-5.1,0-9.8,1.8-28.3,19c4.4,0.3,8.9,0.6,13.6,0.6c4.7,0,8.9,2.2,12.5,2.5h0.7
	C762.1,367.5,765,368.5,767.7,368.6L767.7,368.6z"
          />
          <path
            fill="#EAEBEB"
            d="M780,312.6c-4.5-7.8-5.9-10.6-11-15c-0.3-0.3-0.5-0.4-0.8-0.7c-7.2-5.2-31.2-15-52.5-20.5
	c-5.8-1.5-12-2.9-18.4-4.1c-0.3,0.1-1.7,0.5-0.5,1.8c3,3.4,7.8,8,13.1,12.2c5.1,4.1,10.3,8.3,14.6,10.7c0.7,0.4,1.2,0.8,1.9,1.1
	c7.7,4.3,27.8,12.9,32.5,14c0.4,0.1,0.5,0.1,0.8,0.1c1.7,0,1-2.2,2.6-2.2c1.5,0,11.7,3,16.9,4.8c0.3,0.1,0.5,0.1,0.8,0.1
	C780.5,315.1,780.9,314.3,780,312.6L780,312.6z M763.3,349.9c-0.7,1.8-1.2,5-1.2,8.5c0,3.4,0.3,6.2,1.7,7.7c5,0.5,9.6-4.7,9.6-10.2
	c0-1.4-0.3-2.6-0.7-3.9c-0.1-0.4-0.5-1.1-1.9-1.4L763.3,349.9L763.3,349.9z"
          />
          <path
            fill="#5E5E5E"
            d="M775.4,297.5c0.1,0.7,1,1.6,1.8,3c0.4,0.7,0.8,1.4,1.4,2.3c0,0,0,0.1,0.1,0.1l4.3,8.4c1,2.2,1.8,4.1,2.3,5.6
	c0.1,0.3,0.3,0.6,0.3,0.8c0.4,1.2,0.7,2.1,0.7,2.2c0,0.1,0.1,0.3,0.4,0.4c1.1,0.8,4,2.6,5.1,3.7c0-2.1-0.3-2.9-1.7-3.9
	c0-0.3-0.3-0.7,0-0.7c1.7,0,2.1-1,1.7-1.8c-0.7-1.4-3.2-2.9-3.7-3.4h1.4c0.7,0,1.5-0.7,1.5-1.8c0-1.4-0.4-2.6-1-3.6
	c0-0.1-0.1-0.3-0.1-0.3c0.1,0.1,0.1,0.3,0.1,0.3c0-3-1.5-7.8-2.1-8.9c-0.4-0.8-1.1-0.8-2.1-1.1c-0.1,0-0.3-0.1-0.4-0.3
	c-0.3-0.3-0.5-0.5-1.1-0.5H782l-2.9-1.4C777.1,296.1,774.9,295.6,775.4,297.5L775.4,297.5z M589,370.8c0,6.1,1,11.8,2.8,17.3
	c7.3,21.7,27.8,37.4,52,37.4c23.4,0,43.2-14.6,51.2-35.1c2.3-6.1,3.6-12.7,3.6-19.7c0-30.3-24.5-54.8-54.8-54.8
	C613.7,316.2,589,340.7,589,370.8L589,370.8z"
          />
          <path
            fill="#EAEBEB"
            d="M643.9,407.7c-20.4,0-36.9-16.5-36.9-36.9s16.5-36.9,36.9-36.9s36.9,16.5,36.9,36.9
	C680.7,391.2,664.2,407.7,643.9,407.7L643.9,407.7z"
          />
          <path
            fill="#5E5E5E"
            d="M643.8,316.2c30.3,0,54.8,24.5,54.8,54.8c0,6.9-1.2,13.5-3.6,19.7h15c2.1,0,7.2-2.5,12-2.5
	c4.8,0,9.4-2.9,12.1-2.9h14.4c2.6,0,6.6-2.6,8.1-2.6c1.5,0,5.8-1,7.2-1s1.7,2.6,2.8,2.6c1.1,0,5.4-1.1,7.2-1.7
	c1.5-0.4,2.2-1.1,2.3-1.6v-0.3c-3.2-0.3-7.2-0.8-9.4-0.8c-25.4,1.1-57.4,2.2-59,2.2c-1.4,0-1.5-1.1-1.5-1.9v-0.4
	c0.1-4.4,0-8.8-0.4-13.3c0-0.4,0-0.7-0.1-1.1c0-0.3,0-0.5-0.1-0.8c-2.3-22.1-11.4-44-30.5-55.7c-8.5-5.2-19.3-8.4-32.2-8.5
	c-33.8-0.3-58.3,23.8-60.1,65.9c0,0.4-0.4,0.7-0.4,1.1v1.8c0,0.3,0.4,0.7,0.4,1.1c0,3.2,0.1,6.5,0.3,9.9c0,1.6-1.2,1.9-2.3,1.9
	c-0.1,0-2.2-0.1-5.8-0.3c-4-0.1-9.8-0.4-17.2-0.4h-4.4l-209-0.1c-27.8,0-49.8,0.4-59.6,0.7c-3.3,0.1-5.2,0.1-5.4,0.1
	c-2.1,0-2.1-0.7-2.2-2.5c-0.1-3.6-0.4-7.3-0.8-11c0-0.1,0.3-0.3,0.3-0.4l-0.1-1.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.1-0.3
	c-2.3-28.3-17.2-64-60.4-64c-10.3,0-19.8,2.1-28.2,6.3c-16.2,8.3-28.1,24.3-31.9,48.6c0,0.4-0.1,0.7-0.1,1.1s-0.7,0.7-0.8,1.1
	c0,0.3,0.6,0.8,0.6,1.1c0,0.4-0.1,0.8-0.1,1.2c-0.4,3.3-0.7,6.7-0.7,10.3c3.3,1.5,3.9,1.8,3.9,2.5c0,0.5,0.4,5.5,3.9,6.9
	c-0.4-2.8-0.7-5.5-0.7-8.4c0-28.3,21.6-51.7,49.1-54.5c1.8-0.1,3.7-0.3,5.6-0.3c30.3,0,54.8,24.5,54.8,54.8c0,6.3-1.1,12.5-3,18.2
	h7.4c2.2,0,33.4-4,35.5-4h27.5c1.4,0,1.4,0.8,3.3,0.8h2.2c2.1,0,1,1.7,4.4,1.7h60c2.2,0,2.1-1.1,4.1-1.1h5.4c0.3,0,0.1-0.4,0.7-0.4
	h22.6v1.9c0,0.4,0.1,1,0.7,0.7c1-0.4,0.3-1.6,1.4-1.6c0.7,0,0.1,1,0.8,1c1.4,0.3,0.4-1.6,1.4-1.6h6.7v1.4h2.5c2.9,0,7.3,1.1,11,1.1
	c3.9,0,3.9-1.5,5-1.5h4.7c1.1,0,1-1.1,1.8-1.1h28.3c1.1,0,1.6,2.2,2.6,2.2h2.3c1.1,0,1.7-2.2,2.9-2.2h20.4c3.3,0,7.8,2.2,9.5,2.2
	h2.3c0.5,0-0.1-1.6,0.8-1.6h3.2c0.8,0,2.5,0.8,3.7,0.8h8.4c0.7,0,1.8-0.1,2.9-0.3c7-0.8,19.7-4,20.6-4c1.1,0,4.1,3.3,5.8,3.3h1.5
	c-1.8-5.5-2.8-11.3-2.8-17.3C589.1,340.7,613.7,316.2,643.8,316.2L643.8,316.2z"
          />
          <path
            fill="#5E5E5E"
            d="M161.4,372.3c0,6.1,1,11.8,2.8,17.3c7.3,21.7,27.8,37.4,52,37.4c23.4,0,43.2-14.6,51.2-35.1
	c2.3-6.1,3.6-12.7,3.6-19.7c0-30.3-24.5-54.8-54.8-54.8S161.4,342.1,161.4,372.3L161.4,372.3z"
          />
          <path
            fill="#EAEBEB"
            d="M216.1,409.1c-20.4,0-36.9-16.5-36.9-36.9s16.5-36.9,36.9-36.9s36.9,16.5,36.9,36.9
	C252.8,392.7,236.3,409.1,216.1,409.1L216.1,409.1z M484.2,193.1c0,0-1.2,0.3-7.8,3.6c35.4,14.9,89,51.7,96.7,55.8l0.3,0.1
	c0.1,0.1,0.3,0.1,0.4,0.1l0.3,0.1l0.4,0.1c5.8,1.8,12.1,3.3,17.5,3.6h3.6l-1.7-1c-0.4-0.1-1-0.4-1.5-0.8
	c-9.1-5.4-50.2-31.5-85.8-50.5C498.7,200.1,491.1,196.4,484.2,193.1L484.2,193.1z"
          />
          <path
            fill="#5E5E5E"
            d="M546.5,249.2v0.4c0,1.9-0.8,3.6-1.9,5v3c0,0.8-0.5,1-4.3,1.4c0,1.1-1.2,2.6-3.3,3.3
	c10.7,0.5,19.9,1.1,23.4,1.4c-3.9-6.2-9.5-10.9-13.2-13.8C547.2,249.8,546.8,249.5,546.5,249.2L546.5,249.2z"
          />
          <path
            fill="#EAEBEB"
            d="M390,192.8l0.3,1.4l13.8,61.5c45.8,2.2,87.5,4.3,116,5.8h1.7c-2.8-1-3.2-2.1-3.2-10.5
	c0-7.2,0.3-10.7,1.8-12.5c0.8-1.1,3.6-1.4,7.2-1.4c-0.4-0.3-1-0.6-1.2-0.7c-21.7-13.9-50.8-30-68.2-34.4
	C447.4,199.1,428.2,194.5,390,192.8L390,192.8z M372.9,192.4c-3.9,0-8-0.1-12.1-0.1c-35.9,0-67.4,1-88.5,1.8l5.2,56
	c32.5,1.4,67.1,2.9,100.6,4.5l-5.1-60.8L372.9,192.4L372.9,192.4z M260,194.6c0,0-8.8,23-15.3,40c-0.1,0.7-0.4,1.2-0.7,1.7
	c-1.9,5-3.4,9.2-4.7,12.2c11.4,0.4,23.2,1,35.5,1.5l-5-55.7C266.3,194.3,263,194.5,260,194.6L260,194.6z M249.8,195
	c-18.2,1.1-37.4,2.6-53.5,4.7c-6.3,0.8-7.8,1.6-11.8,4.4c-9.5,6.5-25.4,18.3-33,26.3c-1.8,1.9-4,6.5,0.7,8.1
	c13.1,4.5,38.7,8,77.4,9.6C232.1,241.5,245.3,206.8,249.8,195z"
          />
          <path
            fill="#EAEBEB"
            d="M521.5,250v3c0,0.4,0.5,0.8,1,0.8h0.1c3.4,0.1,11.7,0.3,13.1,0.3c2.1,0,3.7-3.3,3.7-3.6
	c0-0.3-0.3-0.7-1.1-0.8c-0.7-0.1-11-0.6-15.4-0.8C522.1,248.9,521.5,249.4,521.5,250L521.5,250z M133.4,253.9
	c-1.1-0.6-2.6-1.1-4.3-1.5c-4.3-1.1-23.2-4.3-26.7-4.7c-3.3-0.4-8.4,0.1-10.6,0.8c-0.1,0-0.1,0-0.3,0.1c-0.7,0.3-1.4,0.4-2.1,0.7
	c-1.8,0.7-4.8,2.8-6.3,5.4c-3.6,5.8-6.2,14.9-6.3,16c-0.1,0.7,0.1,1.4,0,2.2c0,0.4-0.1,1-0.3,1.4c-0.3,0.7-1.2,4.5-2.1,8.8
	c-0.1,0.8,0.1,1.5,1.1,1.5s5.4-2.2,9.4-2.8c4-0.5,7.6-0.7,12-2.3c0.1,0,0.3-0.1,0.3-0.1c5.1-1.8,27.5-10.6,38.5-18.2
	c1.5-1.1,1.5-2.3,0.7-4.3C136,255.7,135.1,254.7,133.4,253.9L133.4,253.9z M132.5,200.2c-1.2-1.1-5.8-3.2-5.8-3.2
	c-5.2,4.1-13.1,10.6-21.5,18.6c-8.3,7.8-14.9,15.1-19.4,20.5c-0.4,0.4-0.7,0.8-1.1,1.2c-1.9,2.2-3.4,4-4.4,5.2
	c-0.4,0.4-0.7,0.8-1,1.1l5.9-0.3l0.6-0.1c3-0.7,7.7-2.3,11.6-5.1c7.7-5.6,34-33,35.5-34.7C134.4,201.8,133.7,201.3,132.5,200.2
	L132.5,200.2z"
          />
          <path
            fill="#5E5E5E"
            d="M79.1,233.9l-2.8,2.6l2.8,3.4c-0.1,0.8,1.1,2.6,1.2,2.8c1.1-1.2,2.6-3,4.4-5.2c0.3-0.4,0.7-0.8,1.1-1.2
	c-1.8-2.2-3.9-1.6-3.9-1.6S81,233.5,79.1,233.9L79.1,233.9z M70,319.4V319h-5.5v0.3c0,0.8-2.5,13.3-2.5,16.1v7.7h5
	c1.4,0,2.5-1,2.5-2.8v-9.4C69.5,323.8,70,320.2,70,319.4z M57.8,365h-2.2c-3-4.1-2.8-6.7-4.3-6.7c-0.4,0-0.7,0.4-0.8,1
	c0.4,1.1,1.9,5,2.8,5.5c0.1,0.1,0.5,0.1,1.2,0.3c3.8,0.7,15.1,1.9,20.2,1.9c2.9,0,3.2-0.1,8.3-0.1c2.3,0,5.8,0.3,9.9,0.8
	c-8.1-1.1-18.6-2.6-24.8-2.6h-6.3c-4.4-4.1-5-6.7-6.5-6.7C53.7,358.3,55.9,363.3,57.8,365L57.8,365z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M243.8,236.8
	c0,0,1.1-1.8,1.1-3.6 M356.3,174.1h7.4 M643.9,334.1c20.4,0,36.9,16.5,36.9,36.9s-16.5,36.9-36.9,36.9s-36.9-16.5-36.9-36.9
	S623.6,334.1,643.9,334.1L643.9,334.1z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="216.1"
            cy="372.3"
            r="37"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M777.2,300.3
	c-2.8-4.5-4-5.6-4-6.3s0.8-1.1,2.2-1.1c1.5,0,7.2,0.3,7.2,0.3 M592.1,256.8c3.4,0,7.4,3,12.9,3c4.3,0,15,1.4,28.9,2.9
	c23.1,2.6,56,6.9,81.6,13.6c22.3,5.8,47.6,16.1,53.4,21.2c5.1,4.4,6.6,7.3,11,15c1.2,2.2,0.3,2.8-1.1,2.3
	c-5.2-1.8-15.3-4.8-16.9-4.8s-1,2.2-2.6,2.2c-2.5,0-25-9.6-33.3-14.2c-8.3-4.5-23.1-16.9-29.6-24.1c-1.2-1.5,0.5-1.8,0.5-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M696.2,273
	c6.3,1.2,12.5,2.6,18.4,4.1c22.3,5.8,47.6,16.1,53.4,21.2c5.1,4.4,6.6,7.3,11,15c0.4,0.7,0.5,1.2,0.5,1.5 M763.1,310.3
	c-1.4-0.4-2.5-0.5-2.9-0.5c-1.7,0-1,2.2-2.6,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M159.7,187.5
	c35.2-3,120-7.8,172.9-7.8c64.4,0,111.3,5.5,136.7,14.4c34.8,12.4,95.3,54.2,103.7,58.6c0.1,0,0.3,0.1,0.3,0.1
	c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c6.3,2.1,13.2,3.6,19,3.6c5.9,0,6.3-0.3,11.3-0.3
	c2.6,0,55.2,3.7,96.6,11.1c37.7,6.7,70.7,16.8,75.8,25.6 M675,308.8c0,0,26.1,1.9,32.7,2.2c4.4,0.1,6.3-0.3,8.3-2.2s10-10.7,10-10.7
	 M769.7,298.2l6.2,0.3 M768,296.8h6.9 M706.1,380.2c0,0,63.3-2.6,82.4-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M574.7,381.8v-15.5
	c0-43.1,29.4-75,69.1-73.3c38.2,1.5,71.8,27.6,69.5,86.8 M277.2,366.7l305.1,1.6 M546.5,249.6c0,5.2-6.2,8.3-6.2,9.4
	c0,1.6-2.6,3.9-6.5,3.9c-5.2,0-9.4-0.4-11.4-1.1c-3.4-1.1-3.9-1.5-3.9-10.7c0-7.2,0.3-10.7,1.8-12.5c1-1.2,4.4-1.4,8.8-1.4
	c3.3,0,8.3,1,11.8,3.6C544.3,243.2,546.5,247,546.5,249.6L546.5,249.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M525.5,262.3
	c-3.4-1.1-4-2.1-4-11.3c0-7.2,0.3-10.7,1.8-12.5c0.8-1.1,3.3-1.2,6.5-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M522.7,248.8
	c2.3,0,14.6,0.7,15.4,0.8c0.8,0.1,1.1,0.6,1.1,0.8c0,0.3-1.7,3.6-3.7,3.6c-2.1,0-12.5-0.4-13.1-0.4s-1.1-0.4-1.1-0.8v-3
	C521.5,249.2,521.9,248.8,522.7,248.8L522.7,248.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M525.9,253.9
	c-0.5,0-1.1-0.4-1.1-0.8v-3c0-0.7,0.4-1.1,1.2-1.1 M544.6,254.6v3c0,0.8-0.5,1-4.3,1.4c-0.4,0-8.7-0.4-18.6-1.6 M568.3,368.2
	c-1.1,0-1.2-0.8-1.1-1.9c0.7-8,3.2-39.9,3.2-56.8c0-16.6,1-31.5-5.4-44.4c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.3-0.4
	c0-0.1-0.1-0.3-0.1-0.4c-4.7-8.8-11.7-13.5-16.1-16.6c-1.1-0.8-2.3-1.6-3.6-2.6 M535,237.8c-22.1-15-54.2-34.3-74.4-39.2
	c-13.8-3.4-39.2-9.9-100.3-9.9c-68,0-117.3,1.5-167,7.7c-7.4,1-9.2,1.8-14,4.8c-11.3,7.3-30.1,20.5-39.1,29.6
	c-2.1,2.2-4.7,7.3,0.8,9.1c15.5,5.1,45.1,8.9,87.8,10.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M251.3,191.2
	c0,0-21.2,55.4-22,57.8c-0.8,2.5-2.1,7-2.1,8.8c0,5.1,13.1,34.8,19.9,42.1 M260,194.6c0,0-15.5,40.6-20.5,53.8 M560.4,263.5
	c-4.1-6.6-10-11.3-13.8-14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M527.6,237.1
	c-21.9-14.2-51.6-30.7-69.5-35.2c-13.1-3.3-38.9-9.6-97.1-9.6c-53.5,0-97.4,2.1-111,2.9 M390.4,194.2c-5.4-0.3-11.3-0.4-17.5-0.6
	 M228.8,250.9c6.2,0,75.2,2.8,149.1,6.3c89.7,4.3,187.8,9.6,187.8,9.6 M560.4,263.5c-4.1-0.3-10.7-0.5-23.7-1.2 M521.8,261.5
	c-63.8-3.3-197.5-9.8-292.2-13.5 M277.6,249.9l-5.2-55.9 M275.1,249.9l-5.1-55.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M395.1,366.1
	c-0.8,0-0.8-0.7-0.5-2.1c0.5-3.9,2.2-14.4,2.2-47.5c0-37.4-5.4-57.1-6.1-61.6c-0.7-4.7-6.2-42.9-9.9-64.7 M474.9,196.1
	c6.6-3.3,8.5-3.9,8.5-3.9 M476.4,196.7c6.6-3.3,7.8-3.6,7.8-3.6 M484.2,200.2c0,0,2.8-1.5,3.9-1.9c1.1-0.4,1.6-0.4,2.6,0.1
	c1,0.4,11.7,5.5,13.2,6.2c1.5,0.7,1.9,0.3,2.6-0.3 M564.7,264.4l9.2-11.4 M378.2,254.4l-5.2-62 M404,255.5l-13.9-62.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M276.4,365.9l117.3,0.3
	c0.4,0,0.5-0.7,0.7-1.5 M395.1,366.1l170.9,0.1c0.8,0,1.1-0.7,1.2-1.5 M785.8,318c5.4,3,6.2,3,6.2,6.5 M787,316.6
	c2.8,1.5,3.3,1.8,3.3,4 M786.9,314.7h2.8c0.7,0,1.5-0.7,1.5-1.8c0-4.4-4-7.3-7.6-9.1c-2.9-1.4-5.1-1.5-6.3-3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M789.2,314.7
	c1,0,1-1.5,0.7-2.1c-1-2.2-2.9-3.3-5.1-4.4 M783.2,311.4c1.8,0.8,3.2,1.9,4,3.9c0.1,0.4,0.4,1.8-1.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M778.7,302.9
	c1.5,2.6,5.4,2.6,6.2,5.8c0.1,0.4,0,1.8-3,0.3 M779.7,298.3h4.8c0.7,0,1,0.7,1.4,0.8c1,0.3,1.7,0.3,2.1,1.1c0.7,1.2,2.1,6.1,2.1,8.9
	 M786.9,302.6c-0.5-1.8-1.4-3.7-1.4-3.7 M154.2,371.2c3.4,1.7,3.9,1.8,3.9,2.6c0,0.5,0.4,5.6,4,7 M97.3,279.3c0,0,7.7,16.2,9.6,20.1
	c1.9,3.9,2.9,3.4,8.4,3.9c5.4,0.3,72.9,4.8,72.9,4.8 M67.4,273.3c0.8-7.8,6.5-17.1,6.9-17.6c0.3-0.3,5.2-2.6,10.7-4.8
	c5.1-1.9,10.6-3.7,12.9-4.7c4.8-1.9,8.4-3.7,13.6-9.4c5.2-5.8,33.8-34.8,34.1-35.8c1-3,1.1-3.3,1.6-3.6c0.6-0.3,12-8.5,12.7-10.5
	c0.7-2.1,2.1-6.1,2.1-6.1 M76.9,272.7l-12,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M91.6,248.5
	c2.1-0.7,7.4-1.4,10.9-0.8s22.4,3.7,26.7,4.7c4.3,1.1,6.7,2.6,7.4,4.4c0.7,1.9,0.7,3.2-0.7,4.3c-11.1,7.8-34.4,16.8-38.8,18.3
	c-4.4,1.5-8,1.6-12,2.3c-4,0.5-8.4,2.8-9.4,2.8c-1,0-1.2-0.7-1.1-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M64.1,305.5
	c0,0,4.5-3.7,6.9-5.8c2.5-2.1,2.2-2.5,2.2-5.6c0-5.5,2.9-18.7,3.4-19.9c0.6-1.4,0.1-2.6,0.3-3.7c0.1-1.1,2.8-10,6.3-16
	c1.5-2.6,4.5-4.7,6.3-5.4 M131.1,184.4c0,0,9.4,4.4,19.3,10.9 M146.9,197.8c-5.9-5.1-17.2-11.8-17.2-11.8 M145.1,201.8
	c0.7-0.8,0.3-1.6-0.3-2.3c-0.7-0.7-6.5-5.1-11.7-7.3 M76.8,245c0,0,12.9-2.3,21.9-3.9c8.9-1.5,11.3-2.5,14.2-5.5 M85.3,243.4
	l-6.1,0.3 M69.6,246.5l26.7,0.4 M68.9,266.8l-5.2-1.4 M68.8,267.1h-6.2 M67.5,271.8h-5.2 M284.8,381.4v-13.9 M284.8,367.5v-2.1
	c-1.1-10.5-3.4-24.9-10.2-37.7c-5.9-11.3-14.9-21.5-27.5-27.9 M247.1,299.8c-8.5-4.4-18.8-7-31.1-7c-49.2,0-70.6,41.7-70.6,83.6
	 M758.7,312.1c8.7,3.2,22.8,5.4,28.2,8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M793.6,326.8
	c-1.1-1.6-18.2-6.1-24.1-7.4c-6.9-1.6-10.5-2.6-13.9-4.3c-8.5-4-22.6-11.4-31.5-18.2 M778.2,380.7c5,0,10.5-3.2,11-3.9
	s3.3-6.1,3.3-10 M788.9,379.1c2.2-1,4.4-5.6,4.4-8.3 M732.2,364.4c18.6-17.2,23.2-19,28.3-19s32.3,5,32.3,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M789.9,349.8
	c0,0,1.9-0.4,1.9-2.3v-7.2c0-5.2-2.2-14-3.4-16.1 M705.7,366.4c0,0,15.3-0.1,32.3,0.3c6.7,0.1,13.8,0.3,20.5,0.5
	c3.2,0.1,6.2,1.2,9.1,1.4c1.7,0,3.4-0.8,5-0.7c10,0.4,17.3,0.7,17.9,0.7c2.6,0,2.8-1,2.8-1 M746,364.9v-2.2c0-3,5.1-9.6,9.9-12.9
	c2.2-1.5,4.7-1.5,5.9-1.5c3.7,0,20.2,2.5,20.2,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M758.5,367.2
	c-3.6-0.1-8-2.5-12.5-2.5c-7.2,0-14.2-0.7-20.4-0.8c-5.6-0.1-9.8,1.2-13.3,1.4c-3.6,0.1-6.6,0-6.6,0 M771.3,365.5
	c14.7,1.5,17.6,2.5,19.1,2.5c1.4,0,0.6-0.8,0.6-1.9c0-2.5,0.5-1.1,0.7-5.9c0-1,0.1-4,0.1-5.4 M775.2,352.1H790 M759.3,367.4
	c-0.8-0.1-1.7-1.2-1.7-6.9c0-7.4,1.9-12.1,3.2-12.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M728,364.1
	c17.5-15.7,24.9-20.5,31-20.5c4.5,0,31,4.8,32.3,5.1c1.5,0.3,1.9,1.1,1.9,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M772.7,349.4
	c1.7,0.3,2.8,1.8,3,6.3c0.3,5.2-4.5,11.8-8.4,11.8c-4.4,0-5.2-3.7-5.2-9.1c0-5.5,1.2-10,2.6-9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M763.3,349.9l7.4,1
	c1.4,0.1,1.7,1,1.9,1.4c0.5,1.2,0.7,2.5,0.7,3.9c0,5.4-4.7,10.7-9.6,10.2 M695,390.5h15c2.1,0,7.2-2.5,12-2.5
	c4.8,0,9.4-2.9,12.1-2.9h14.4c2.6,0,6.6-2.6,8.1-2.6c1.5,0,5.8-1,7.2-1s1.7,2.6,2.8,2.6c1.1,0,5.4-1.1,7.2-1.6
	c1.8-0.5,2.3-1.2,2.3-1.9 M778.5,296.5c0.5,1,2.3,3,3.7,3.7c1.2,0.7,3,1,4,1.8c1.1,0.7,2.5,2.3,2.6,3.7c0.1,1.4-0.3,1.6-0.3,1.6
	 M787.6,314.7c0,0,4.4,2.3,4.4,4.1c0,1.1-1.8,1.1-1.8,1.1 M92.9,367.8c-8.1-1.1-18.7-2.6-24.9-2.6H54.3 M55.8,345.8
	c0-9.9,0.5-14.9,1.5-20.2 M58.2,344.5h8.1c1.7,0,3.2-0.5,3.2-3.2v-10.3c0-7,0.6-10.6,0.6-11.6c0-1.5-0.1-2.2-1.5-2.2h-4.4
	c-1.1,0-2.6,0.7-4.8,0.7 M64.5,318.8H70"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M62,343v-7.7
	c0-2.8,2.5-15.4,2.5-16.1c0-0.8-0.7-0.8-1.9-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M69.5,340.3
	c0,1.8-1,2.8-2.5,2.8h-4.4c-1.9,0-2.3,1.5-4,1.5 M57.8,365c-1.9-1.9-4.1-6.7-2.6-6.7s2.1,2.6,6.5,6.7 M50.5,359.3
	c0.1-0.6,0.4-1,0.8-1c1.5,0,1.2,2.6,4.3,6.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M50.3,343.6h5.2
	c0.4,0,2.5,3.6,2.9,3.6c3.4,0,19.5,1.1,41,3.2c27,2.6,56.1,6.1,56.1,6.1 M49.7,347.2h5.8c0.4,0,2.5,3.6,2.9,3.6
	c3.4,0,19.5,1.5,41.1,3.6c27,2.6,55.7,6.6,55.7,6.6 M62.5,345.1H49.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M58.3,350.9
	c0-0.5,1.5-1.6,1.5-2.1s-1.2-1.2-1.2-1.5 M59.8,348.8c3.4,0,19.5,1.4,41,3.4c27,2.6,53.9,6.5,53.9,6.5 M267.7,390.5h7.6
	c2.2,0,33.4-4,35.5-4h27.5c1.4,0,1.4,0.8,3.3,0.8h2.2c2.1,0,1,1.6,4.4,1.6h60c2.2,0,2.1-1.1,4.1-1.1h5.4c0.3,0,0.1-0.4,0.7-0.4h22.6
	v1.9c0,0.4,0.1,1,0.7,0.7c1-0.4,0.3-1.6,1.4-1.6c0.7,0,0.1,1,0.8,1.1c1.4,0.3,0.4-1.6,1.4-1.6h6.7v1.4h2.5c2.9,0,7.3,1.1,11,1.1
	c3.9,0,3.9-1.5,5-1.5h4.7c1.1,0,1-1.1,1.8-1.1h28.3c1.1,0,1.6,2.2,2.6,2.2h2.3c1.1,0,1.7-2.2,2.9-2.2h20.4c3.3,0,7.8,2.2,9.5,2.2
	h2.3c0.5,0-0.1-1.6,0.8-1.6h3.2c0.8,0,2.5,0.8,3.7,0.8h8.4c5.5,0,22.4-4.3,23.5-4.3c1.1,0,4.1,3.3,5.8,3.3h1.5 M249.8,195
	c-18.2,1.1-37.4,2.6-53.5,4.7c-6.3,0.8-7.8,1.6-11.8,4.3c-9.5,6.5-25.4,18.3-33,26.3c-1.8,1.9-4,6.5,0.7,8.1
	c13.1,4.5,38.7,8,77.4,9.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M643.9,316.2
	c30.3,0,54.8,24.5,54.8,54.8c0,30.3-24.5,54.8-54.8,54.8s-54.8-24.5-54.8-54.8C589,340.7,613.7,316.2,643.9,316.2L643.9,316.2z
	 M216.1,317.6c30.3,0,54.8,24.5,54.8,54.8s-24.5,54.8-54.8,54.8s-54.8-24.5-54.8-54.8S185.8,317.6,216.1,317.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M707.6,266.7
	c35.4,6.2,67.8,17.2,74.8,26c0.4,0.4-0.3,1,0,1.5c0.3,0.4,1.7,0.8,1.8,1.2c0.5,1,1,1.8,1,2.2c0,0.8-1.5,0.3-3,0.3s-2.6-1.6-5.9-1.6
	c-1,0-1,1.1-0.7,1.5c0.1,0.3,2.2,2.9,5.6,9.5c3.4,6.6,5.1,12.4,5.4,12.9c0.1,0.4,5.5,3.6,5.9,4.8c0.3,1.2,1.7,1.4,1.7,2.8
	c0,0.4,0,9.5-0.1,10.7c0,0.3-0.1,0.5-0.1,0.8c0,0.1,0.1,0.4,0.1,0.8c-0.3,2.9-0.1,6.7-0.1,7.4c0,0.4-0.1,1.2-0.1,1.9
	c0,0.3-0.4,0.5-0.4,0.8c0,0.4,0.3,0.8,0.3,1c0,1.8,0,5.6-0.1,6.9c-0.3,6.3-1.1,4.4-1.1,7.6c0,1.5,1.2,1.1,1.2,2.8v6.1
	c0,1.6-1,2.8-2.8,3.7c-1.2,0.7-4.4,2.2-7.4,2.8c-1.4,0.3-3.2-0.3-4.1-0.3c-2.9,0.3-9.4-1-12.4-0.8c-25.5,1.1-57.4,2.2-59,2.2
	c-1.5,0-1.5-1.5-1.5-2.3c1.1-36.3-14.9-79-63.3-79.4c-33.8-0.3-58.3,23.8-60.1,65.9c0,0.3-0.4,0.7-0.4,1.1v1.8
	c0,0.3,0.4,0.7,0.4,1.1c0,3.2,0.1,6.5,0.3,9.9c0,1.6-1.2,1.9-2.3,1.9c-0.3,0-10.6-0.8-27.4-0.8c-46.9,0-143.9-0.1-209-0.1
	c-37.4,0-64.4,0.8-64.9,0.8c-2.1,0-2.1-0.7-2.2-2.5c-0.1-3.6-0.4-7.3-0.8-11c0-0.1,0.3-0.3,0.3-0.4c0-0.4-0.1-1-0.1-1.4
	c0-0.3-0.3-0.4-0.3-0.7c-2.3-28.3-17.2-64-60.4-64c-30.5,0-54.6,18.6-60.3,56c0,0.4-0.7,0.7-0.8,1.1c0,0.3,0.6,0.8,0.6,1.1
	c-0.6,4.7-1,9.8-1,15c0,1.8-0.6,2.6-2.3,2.3c-6.1-1-12.7-2.5-26.4-4.5c-16.1-2.5-34.9-5.6-42.6-5.6c-5.1,0-5.4,0.1-8.3,0.1
	c-6.1,0-20.6-1.8-21.5-2.3c-0.8-0.5-2.5-5-2.8-5.6c-0.3-0.7-0.4-1.4-0.4-3.4c0-0.8-0.1-4.7-0.3-8.4c0-0.8,0.7-1.4,0.7-2.3v-0.8
	c0-0.5-0.8-0.5-0.8-1.2c-0.1-2.9-0.1-6.1-0.1-7.3c0-2.1,0.4-3.4,0.4-6.3c0-1.8,0.4-1.9,3.6-2.5c3.3-0.5,3.9-0.7,4-1.5
	c1.8-9.6,4.8-16.6,5.2-18c0.4-1.5,2.1-1.9,2.1-2.9s-0.3-5.6-0.3-12.4c0-9.1,1.4-17.5,1.5-18.2c0.3-0.7-0.3-0.7-1-0.7
	c-2.2,0-2.5-0.4-2.5-1.6v-3.9c0-1.4,0.8-1.4,1.4-2.8c0.6-1.4,3.4-8.8,3.4-16c0-0.4,1.2-2.3,1.5-2.5c0.3-0.1,5.8-1.2,9.5-2.1
	c1.1-0.3,10.7-13.6,27-29c14.9-14,27.8-23.2,27.8-23.5c0-0.4-3.4-4.8-3.3-6.2c0-0.4,1.2-1.6,1.7-1.8c0.1,0,4.7-1.8,26.6-3.4
	c1-0.1,1.9-0.1,3-0.3c0.4,0,0.7,0.4,1.1,0.3c0.6,0,1.1-0.6,1.7-0.6c42.6-3.2,104.5-6.9,154.3-6.9c85,0,137,5.9,165.1,18.7
	c0.4,0.1,0.5,0.8,1.1,1c38.2,17.6,97,55,108.4,62c1.2,0.7,2.1,1.1,3.3,1.1c7.8,0,16.6-2.1,17.5-2.1c0.1,0,2.8,1.2,8.7,1.9
	C640.6,258.2,681.9,262.2,707.6,266.7L707.6,266.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M85.3,243.4
	c3-0.6,8-2.2,12.1-5.2c7.7-5.6,34-33,35.5-34.7c1.5-1.8,0.8-2.2-0.4-3.3c-1.2-1.1-5.8-3.2-5.8-3.2 M80.2,242.6c0,0-1.7-2.3-1.1-2.9
	s2.6-2.8,2.8-3s2.9,0.7,2.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M79.1,240l-2.8-3.6
	l2.8-2.5l2.8,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M81.8,236.7v-2.2
	c0,0-1-1-2.9-0.7 M85.8,236.1c-1.8-2.2-3.9-1.7-3.9-1.7"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M675,308.8
		l32.1,2.2c0,0,4.3,0.3,5.8-0.3c0,0,1.7-0.4,2.6-1.2l10.6-11.3c0,0-9.8-6.3-16.4-12c0,0-10.3-8.8-12.9-12c0,0-1.5-1.7,0.1-2.1
		c0,0-16.9-3.7-52.5-8.1c0,0-32.6-3.9-36.6-4c0,0-1.4-0.1-2.6-0.1s-2.5,0-4.5-0.5c0,0-5.1-1.7-7.2-2.5c0,0-7.6,0.1-19.7-3.9
		l-9.2,11.4c0,0,5.2,8.4,5.6,26.4c0,0,0.1,17.1,0,22c0,0-0.3,13.3-1.1,24.8l-1.9,27.2l-0.1,0.8c0,0-0.4,2.3,0.6,2.6l14.6,0.1v-1
		c0,0,0-0.3,0.3-0.6s0.3-1,0.3-1s-0.1-19.9,10.6-38.7c0,0,13.5-26.8,49.5-26.8C642.8,300.4,659.9,299.6,675,308.8z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M544.6,254.6v3
		c0,0,0,0.5-0.7,0.8c-0.7,0.1-3.6,0.5-3.6,0.5s-0.3,2.5-4.1,3.6c0,0-1.1,0.3-2.2,0.3c0,0-5.2,0.1-9.6-0.7c0,0-4-0.5-5-2.2
		c0,0-0.8-1-0.8-5.4c0,0-0.3-7.3,0.4-12.5c0,0,0.3-2.3,1.5-3.9c0,0,0.7-1.1,4.8-1.1c0,0,3.9-0.1,5.1,0c1.2,0.1,6.3,0.6,10.6,3.7
		c0,0,3.6,2.3,5.2,7.3C546.3,248,547.6,250.9,544.6,254.6z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M521.6,261.5
		L404,255.7l-14-62.9c0,0,22,0.6,43.2,4c0,0,11.8,1.5,27.1,5.8c0,0,7.8,1.9,25.3,10.6c0,0,22.6,11.1,41.8,23.9c0,0-5.1-0.1-6.6,1
		c0,0-1.8,1-1.9,6.2c0,0-0.3,5.4-0.1,10.2c0,0,0.1,4.3,0.7,5.4C519.3,259.7,519.7,260.8,521.6,261.5z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M372.9,192.4
		c0,0-38-0.6-100.6,1.7l5.2,56l100.6,4.5L372.9,192.4z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="270,194.2 
		260,194.6 239.5,248.4 275.1,249.9 	"
              id="Right Qtr Glass 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M249.8,195
		c0,0-38.4,1.8-59,5.8c0,0-2.1,0.4-6.3,3.3c0,0-23.1,15.7-33.6,27c0,0-4.1,5,0.7,7.3c0,0,18.8,7.7,78,9.8L249.8,195z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M565.8,266.7
		l-174.6-8.9c0,0,2.9,12.8,4.4,30.3c0,0,1.1,12.9,1.1,23.8c0,0-0.1,33.2-0.7,35.2c0,0-1,14.9-1.7,17.6c0,0-0.3,1.4,0.8,1.4
		l170.3,0.1c0,0,1.5,0.3,1.8-1.9l2.3-34.3c0,0,1.4-20.6,0.4-43.7C570.1,286.4,570.1,277.4,565.8,266.7z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M391.2,257.7l-162.5-6.9
		c0,0-1.7,5.1-1.5,7.2c0,0-0.3,1.2,1.8,6.5c0,0,3.7,10.3,9.4,21.2c0,0,4.8,9.6,8.8,14.2c0,0,17.6,7.2,29,31.2c0,0,7.2,14.7,8.5,34.7
		l108.5,0.3c0,0,0.7,0.3,1-1.1c0.3-1.4,2.6-18.7,2.2-33c0,0,0.7-20.5-0.8-42.9C395.8,289.1,394.7,275.5,391.2,257.7z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M243.8,308
		l3.6-8.1c0,0-4-4.7-8.1-12.9c0,0-5.6-10.9-9.4-20.6c0,0-2.1-5.9-2.3-7.3c0,0-0.1-1.5,0.1-3.4l1.2-4.8c0,0-16.5-0.7-37.3-2.5
		c0,0-24.2-1.9-45.5-7l-4.8-1.4c0,0-3.4-0.7-3.3-3.9l-26.7,1.4c0,0-6.1,6.3-14.2,9.2l-5.9,2.1c0,0,2.8-1.5,9.5-1.2l3,0.3
		c0,0,14.4,2.3,24.1,4.3c0,0,4.5,1,6.7,2.3c2.2,1.4,3.2,4.3,2.5,5.8c0,0-0.6,0.8-1.9,1.6c0,0-9.1,5.9-25.3,12.7l-12.2,4.8l9.6,20.1
		c0,0,1.4,3.6,4.1,3.6l76.5,5.1c0,0,12.2-6.7,29.7-6.3C217.4,301.6,232.2,301.8,243.8,308z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="643.8"
              cy="370.8"
              r="54.8"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="216.1"
              cy="372.3"
              r="54.8"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Wagon4DrRight);
