import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv2DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 125 800 350">
          <path
            fill="#656565"
            d="M690.4,172.5h4.7c1.1,0.3,1.7,1,1.7,2.2v9.8h22.8l-2.5-15c-1.4-9.2-5.6-15.3-12.7-18.2
	c-14.3-4-28.1-7-41.5-8.9l3.3,1.2c0.3,0.1,0.5,0.3,0.7,0.4c1.5,1,2.8,3.3,3.7,6.9l4.1,14.9C684.7,165.9,689.7,168.1,690.4,172.5
	L690.4,172.5z"
          />
          <path
            fill="#656565"
            d="M695.1,172.5h-4.7c-2.1-0.3-3.3,0.4-4,1.8c2.5,3,3.9,7.4,4,13.3v8.4h4.7V172.5z"
          />
          <path
            fill="#656565"
            d="M721.9,184.6h-25v9.9c0,1-0.5,1.5-1.5,1.5h-3.9c1.7,0.3,2.3,1.8,2.3,4.5v6.7l-0.5,0.3v2.1h28.8
	c0.7,0,1-0.3,1-1.1v-23C723,184.8,722.6,184.6,721.9,184.6L721.9,184.6z"
          />
          <path
            fill="#656565"
            d="M693.7,200.5c0.1-2.8-0.7-4.3-2.3-4.5h-1v12.7l2.8-1.1l0.5-0.3L693.7,200.5L693.7,200.5z"
          />
          <path
            fill="#656565"
            d="M693.2,209.5v-2.1l-2.8,1.2v0.8 M693.2,209.5h-2.8V267l3.3-2.1l-0.1-10.3 M718.8,215c0-3.3,0.5-5.1,1.5-5.5
	h-27l0.4,45.1l6.6,0.3v-9.2l0.1-1c0.1-1.1,0.5-1.9,1.1-2.8l0.1-0.1c0.5-0.8,1.2-1.5,2.1-2.1c1-0.7,1.9-1.2,3.2-1.6
	c3.6-0.7,7.6-1.1,12.1-1.1v-22L718.8,215L718.8,215z"
          />
          <path
            fill="#656565"
            d="M723.4,237.1h-4.7c-4.5,0.1-8.5,0.4-12.1,1.1c-1.2,0.4-2.3,1-3.2,1.6c-0.8,0.6-1.5,1.2-2.1,2.1l-0.1,0.1
	c-0.5,0.8-0.8,1.6-1.1,2.8l-0.1,1.2v38.5c3.7-0.8,7.3-0.7,10.6,0.5V314v-0.4c-3.3,1.2-6.9,1.5-10.6,0.7v38.8
	c0.4,3.7,2.5,6.2,6.5,7.6c3.6,0.7,7.6,1.1,12.1,1.1h6.9c6.5,0.1,12-0.3,16.8-1.1c4-1.2,6.2-3.7,6.5-7.6V245.8
	c-0.4-3.7-2.5-6.2-6.5-7.6l-1.5-0.3c-4.4-0.7-9.5-1-15.3-0.8L723.4,237.1L723.4,237.1z"
          />
          <path
            fill="#656565"
            d="M700.2,254.9l-6.6-0.3l0.1,10.5l-3.3,2.1v22.1h5.4v-0.7h2.2v-3.2c0.7-0.3,1.5-0.4,2.2-0.5L700.2,254.9
	L700.2,254.9z M154.6,141.2c0.7,0,1.1,0.1,1.5,0.3h111.6c-0.8-2.9-3.9-4.3-9.2-4.3h-97.3c-6.3,0-10,1.5-11.3,4.3h2.5
	C153.2,141.4,153.9,141.2,154.6,141.2L154.6,141.2z"
          />
          <path
            fill="#FFFFFF"
            d="M149.9,141.6h-9.2c-6.7,2.1-11.1,4.7-13.3,7.8h3.3c1.9-0.1,2.6,1.4,1.8,4.3l-5.5,34.8l0.8,2.2v4.7l1.5,2.1
	l1.5,2.1l-0.1,3l7.7,2.9c1.8,1.2,4.1,0.6,7.3-2.3c0.6-0.4,1-1,1.6-1.4c-9.9-2.5-14.7-5.6-14.3-9.4c0.4-4.8,0.8-9.5,1.4-13.9
	c1.2-10.7,2.8-20.2,4.7-28.3C140.3,146.5,143.9,143.7,149.9,141.6L149.9,141.6z"
          />
          <path
            fill="#656565"
            d="M121.2,149.3c-2.9-0.1-4.8,1.1-5.8,3.4c-3.2,7.2-6.2,15.7-9.1,25.6l-2.5,8.9c-0.6,1.8-1.5,3.2-3,4.1
	l-2.6,1.9c-1.2,1-2.1,3.4-2.3,7.3l-2.6,28.8c0.1,1.2,1.1,2.8,3,4.3l1,0.7l5.9,0.1c-1.9-1.9-2.9-3.6-3-5l2.6-28.8
	c0.1-2.6,0.6-4.5,1.2-5.8l0.1-0.4l1.4-1.5l1.5-2.1l0.8-1.7l12.9-37.3c0.3-1.6,1.4-2.6,3.3-2.6h-2.9L121.2,149.3L121.2,149.3z"
          />
          <path
            fill="#656565"
            d="M127.4,149.3h-3.3c-1.9,0.1-3,1-3.3,2.6l-12.9,37.3l19-0.4l-19,0.4l-0.8,1.6l-1.5,2.1l-1.4,1.5l-0.1,0.4
	l23.9-0.4v-3.7l-0.8-2.2l5.5-34.8c0.7-2.9,0.1-4.4-1.8-4.3h-3.4V149.3L127.4,149.3z"
          />
          <path
            fill="#656565"
            d="M127.9,195.3v-1l-23.9,0.4c-0.7,1.2-1.1,3.2-1.2,5.8l-2.6,28.8c0.1,1.2,1.1,2.9,3,5h1.1v-0.1l3.4,0.1
	l16.2,0.1h2.2l2.3-4.4l1-32.7L127.9,195.3L127.9,195.3z"
          />
          <path
            fill="#656565"
            d="M131,199.4l-1.5-2.1l-1,32.7l-2.3,4.4l3.9-4.4l0.8-27.6"
          />
          <path
            fill="#656565"
            d="M130,230.1l-3.9,4.4v128.8l3.9,4.4l1,27.6l7.7-2.9c1.8-1.2,4.1-0.5,7.3,2.3c0.6,0.5,1,1,1.7,1.4
	c-1.2-1.4-2.3-3.7-3.4-7h-6.6c-0.8-4-1.5-9.4-2.5-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.6-6.6-0.8-10.2l-1-15.8
	c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.4,1.2-40.6l0.8-12.9c0.3-3.7,0.6-7,0.8-10.2l0.8-7.3c0.8-6.6,1.6-12,2.5-16.1h6.5
	c1-3.3,2.2-5.6,3.4-7c-0.6,0.4-1.1,0.8-1.6,1.4c-3.2,2.8-5.5,3.6-7.3,2.3l-7.7-2.9L130,230.1L130,230.1z M126,234.6l-2.1-0.1
	l-16.4-0.1h-4.4v129.2l1.1-0.1l3.3,0.1l15.5-0.3h2.9"
          />
          <path
            fill="#656565"
            d="M97.3,234.4l0.4,0.3L97.3,234.4c0.8,1.1,1.2,4,1.1,8.7v111.8c0.1,4.7-0.1,7.6-1.1,8.7l0.4-0.3l-0.4,0.3h5.9
	v-129L97.3,234.4z M179.7,192.1l-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3c-0.3,0.1-0.4,0.3-0.3,0.6l0.4,1.6h0.1l-0.1-0.6
	c-0.1-0.3,0-0.6,0.3-0.6l1.2-0.4L179.7,192.1L179.7,192.1z"
          />
          <path
            fill="#656565"
            d="M179.9,192.1h-0.4l-1.2,0.4c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.6l0.3,1l0.4,1.4c0.1,0.3,0.3,0.4,0.6,0.4
	l1.2-0.3l0.4-0.3v-0.4l-0.3-1.1l-0.6-1.9C180.1,192.3,179.9,192.3,179.9,192.1L179.9,192.1z M260,243.6c-0.8,0.8-1.1,1.6-1.1,2.8
	s0.4,1.9,1.1,2.8c0.8,0.7,1.6,1.1,2.8,1.1s1.9-0.4,2.8-1.1c0.8-0.8,1.1-1.6,1.1-2.8c0-1.1-0.4-1.9-1.1-2.8c-0.8-0.8-1.6-1.1-2.8-1.1
	S260.8,242.9,260,243.6z M260.8,248.3c-0.5-0.6-0.8-1.1-0.8-1.9c0-0.8,0.3-1.4,0.8-1.9s1.2-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8
	s0.8,1.1,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9s-1.1,0.8-1.9,0.8C262.1,248.9,261.4,248.7,260.8,248.3L260.8,248.3z"
          />
          <path
            fill="#656565"
            d="M260.1,246.3c0,0.7,0.3,1.4,0.8,1.9s1.2,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8s0.8-1.1,0.8-1.9
	c0-0.8-0.3-1.4-0.8-1.9s-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8S260.1,245.5,260.1,246.3L260.1,246.3z M261.8,246.3
	c0-0.3,0.1-0.6,0.3-0.7c0.3-0.3,0.4-0.3,0.7-0.3s0.5,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.7c0,0.3-0.1,0.6-0.3,0.7
	c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.3C261.8,246.9,261.8,246.6,261.8,246.3L261.8,246.3z"
          />
          <path
            fill="#656565"
            d="M262.1,245.6c-0.1,0.1-0.3,0.4-0.3,0.7c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3
	c0.1-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.3-0.7c-0.3-0.3-0.4-0.3-0.8-0.3C262.5,245.2,262.2,245.4,262.1,245.6L262.1,245.6z
	 M258.8,290.1c-0.1,0.1-0.1,0.3-0.1,0.4v5.6c-0.1,0.1-0.3,0.3-0.3,0.5v4.8c0,0.1,0.1,0.4,0.3,0.6v5.6l0.1,0.3
	c0.1,0.1,0.3,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1l0.1-0.3v-5.5c0.1-0.1,0.3-0.3,0.3-0.5v-5.1c0-0.3-0.1-0.4-0.3-0.5v-5.5l-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1C259,289.9,258.9,289.9,258.8,290.1L258.8,290.1z"
          />
          <path
            fill="#656565"
            d="M258.6,290.4c0-0.1,0-0.3,0.1-0.4s0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.1l0.1,0.4v1.2l0.5-0.4
	c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1
	s0.1,0.8,0.4,1.1l0.7,0.4L258.6,290.4L258.6,290.4z M261.8,297.5l-1.9-0.7v4.3l1.9-0.8 M257.9,306.4c-0.3,0.3-0.4,0.7-0.4,1.1
	s0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1l-0.5-0.3v1.2l-0.1,0.3
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.3-0.1l-0.1-0.3V306L257.9,306.4L257.9,306.4z M265.5,348.8c-0.8-0.8-1.6-1.1-2.8-1.1
	s-1.9,0.4-2.8,1.1c-0.8,0.8-1.1,1.6-1.1,2.8c0,1.1,0.4,1.9,1.1,2.8c0.8,0.8,1.6,1.1,2.8,1.1s1.9-0.4,2.8-1.1
	c0.8-0.8,1.1-1.6,1.1-2.8C266.6,350.5,266.2,349.6,265.5,348.8z M262.7,348.8c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.1,0.8,1.9
	c0,0.8-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.1-0.8-1.9c0-0.7,0.3-1.4,0.8-1.9
	C261.4,349.1,262.1,348.8,262.7,348.8L262.7,348.8z"
          />
          <path
            fill="#656565"
            d="M264.7,349.6c-0.5-0.5-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.1-0.8,1.9
	c0,0.8,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9
	C265.5,350.7,265.2,350.2,264.7,349.6z M262.7,350.5c0.3,0,0.5,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7
	c-0.3,0.3-0.4,0.4-0.8,0.4c-0.3,0-0.5-0.1-0.7-0.4c-0.1-0.1-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7C262.2,350.6,262.5,350.5,262.7,350.5
	L262.7,350.5z"
          />
          <path
            fill="#656565"
            d="M263.6,350.7c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
	c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3c0.1-0.1,0.3-0.4,0.3-0.7C263.8,351.3,263.7,351,263.6,350.7L263.6,350.7z M180.9,195.8
	l-0.4,0.3l-1.2,0.3c-0.3,0.1-0.6,0-0.6-0.4l-0.4-1.4H178l0.1,0.7l0.4,2.9l1.6-0.4c0.3-0.1,0.6,0,0.6,0.4l0.1,0.6l0.4-0.6
	L180.9,195.8L180.9,195.8z"
          />
          <path
            fill="#656565"
            d="M181,198.7l-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.4l-1.6,0.4l-2.1,0.6l-1.6,0.4c-0.3,0.1-0.4,0.3-0.4,0.7l0.3,0.8
	l0.1,0.8l0.3,1l0.1,0.1c0.1,0.1,0.3,0.3,0.6,0.1l5.4-1.5c0.3-0.1,0.4-0.3,0.4-0.4v-0.1l-0.3-1.1L181,198.7L181,198.7z"
          />
          <path
            fill="#656565"
            d="M175.1,196.3l-0.3-1.1h-0.3l0.6,1.9c0.1,0.3,0,0.6-0.4,0.7l-1.2,0.4h-0.4l0.8,2.2l1,0.4l-0.3-0.8
	c-0.1-0.3,0-0.6,0.4-0.7l1.7-0.4L175.1,196.3L175.1,196.3z M175,201.5l-0.3,0.1c-0.1,0-0.3,0.1-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
	l0.1,0.3l0.3,0.3h0.4l0.4-0.1l-0.1-0.1L175,201.5L175,201.5z M181.4,200.8v0.1l0.3-0.1l0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4l-0.1-0.3
	l-0.3-0.3h-0.4l-0.3,0.1L181.4,200.8L181.4,200.8z M150.9,252.2v-3.4c0.1-3-0.7-4.7-2.2-5h-4.4v11h6.1
	C150.6,254.3,150.9,253.3,150.9,252.2z M146.8,260.5h-1.1c-0.3,4.1-0.6,8.3-0.7,12.4h1.1C146.2,268.8,146.5,264.5,146.8,260.5z"
          />
          <path
            fill="#656565"
            d="M147.7,260.5h-1c-0.3,4.1-0.6,8.3-0.7,12.4h1C147.2,268.8,147.5,264.5,147.7,260.5z M145.5,260.5h-1
	c-0.3,4.1-0.6,8.3-0.7,12.4h1C145.1,268.8,145.3,264.5,145.5,260.5L145.5,260.5z M150.6,264.1c-0.1-1.2-0.4-2.2-1-2.9
	c-0.3,3.7-0.4,7.4-0.6,11.1c0.6-0.3,1-0.8,1.2-1.4L150.6,264.1L150.6,264.1z"
          />
          <path
            fill="#656565"
            d="M149.7,261.2c-0.3-0.3-0.6-0.5-1-0.7c-0.3,4-0.6,8.1-0.7,12.2l1-0.4C149.1,268.6,149.4,264.9,149.7,261.2
	L149.7,261.2z"
          />
          <path
            fill="#656565"
            d="M148.7,260.5l-0.3-0.1h-0.6c-0.3,4.1-0.6,8.3-0.7,12.4l0.8-0.1C148.2,268.6,148.4,264.6,148.7,260.5
	L148.7,260.5z M147.7,276.9h-1c-0.1,4.1-0.3,8.3-0.3,12.4h1C147.6,285.1,147.6,281,147.7,276.9z"
          />
          <path
            fill="#656565"
            d="M146.9,276.9h-1.1c-0.1,4.1-0.3,8.3-0.3,12.4h1L146.9,276.9L146.9,276.9z"
          />
          <path
            fill="#656565"
            d="M145.8,276.9h-1.1c-0.1,4.1-0.3,8.3-0.3,12.4h1.1C145.7,285.1,145.7,281,145.8,276.9L145.8,276.9z"
          />
          <path
            fill="#656565"
            d="M144.7,276.9h-1c-0.1,4.1-0.3,8.3-0.3,12.4h1C144.4,285.1,144.6,281,144.7,276.9L144.7,276.9z M148.8,277.3
	L148,277h-0.1c-0.1,4.1-0.3,8.1-0.3,12.2c0.4-0.1,0.7-0.1,1-0.4C148.6,285,148.7,281.1,148.8,277.3L148.8,277.3z"
          />
          <path
            fill="#656565"
            d="M148.6,288.8c0.6-0.3,0.8-0.7,1.1-1.2l0.3-8.8c-0.3-0.8-0.6-1.4-1.1-1.6C148.7,281.1,148.6,285,148.6,288.8
	L148.6,288.8z M148.4,293.8h-1v10.3h0.8l0.1-0.1 M149.5,294.5c-0.3-0.3-0.7-0.5-1.1-0.7V304c0.4-0.1,0.8-0.3,1.1-0.7V294.5
	L149.5,294.5z M144.4,293.8l-1.1-0.1v10.5h1.1 M145.5,293.8h-1.1v10.3h1.1C145.4,300.7,145.4,297.2,145.5,293.8z M146.5,293.8h-1
	v10.3h1V293.8z M147.5,293.8h-1v10.3h1 M150.6,299.8v-1.9c0-1.6-0.4-2.9-1-3.4v8.9C150.2,302.7,150.6,301.5,150.6,299.8L150.6,299.8
	z M144.4,308.5h-1.1c0,4.1,0.1,8.3,0.3,12.4h1C144.6,316.8,144.4,312.6,144.4,308.5L144.4,308.5z M146.6,308.5h-1
	c0,4.1,0.1,8.3,0.3,12.4h1.1C146.8,316.8,146.6,312.6,146.6,308.5z"
          />
          <path
            fill="#656565"
            d="M146.8,308.5c-0.1,4.1,0,8.3,0.1,12.4h1l-0.3-12.2L146.8,308.5L146.8,308.5z M145.5,308.5h-1.1
	c0,4.1,0.1,8.3,0.3,12.4h1.1C145.7,316.8,145.7,312.6,145.5,308.5z M149.7,310.2c-0.3-0.5-0.7-1-1.1-1.2c0,3.9,0.1,7.7,0.3,11.6
	c0.4-0.4,0.8-1,1.1-1.6L149.7,310.2L149.7,310.2z"
          />
          <path
            fill="#656565"
            d="M150.1,312.1c-0.1-0.7-0.3-1.4-0.4-1.8l0.3,8.7c0.3-0.7,0.3-1.6,0.3-2.9L150.1,312.1L150.1,312.1z
	 M148.6,308.9c-0.3-0.1-0.7-0.3-1.1-0.3l0.3,12.2h0.1c0.3-0.1,0.7-0.1,1-0.4C148.7,316.6,148.6,312.8,148.6,308.9L148.6,308.9z
	 M145,325h-1.1c0.1,4.1,0.4,8.3,0.7,12.4h1.1C145.3,333.3,145.1,329.1,145,325L145,325z M147.1,325l0.6,12.4h1
	c-0.3-4.1-0.4-8.3-0.7-12.2L147.1,325L147.1,325z"
          />
          <path fill="#656565" d="M147.1,325h-1l0.6,12.4h1.1" />
          <path
            fill="#656565"
            d="M146.1,325H145c0.1,4.1,0.4,8.3,0.7,12.4h1.1L146.1,325z M149,325.4c-0.3-0.1-0.7-0.3-1-0.4
	c0.1,4,0.4,8.1,0.7,12.2c0.4-0.1,0.7-0.3,1-0.7L149,325.4L149,325.4z"
          />
          <path
            fill="#656565"
            d="M149.5,336.7c0.6-0.5,0.8-1.5,1-2.9l-0.4-7c-0.3-0.7-0.7-1.1-1.2-1.4L149.5,336.7L149.5,336.7z M150.4,343.2
	h-6.2v10.7h4.4c1.5-0.3,2.3-1.9,2.2-5v-3.3C150.9,344.7,150.8,343.9,150.4,343.2L150.4,343.2z"
          />
          <path
            fill="#FFFFFF"
            d="M317.2,162.1c1,3.9,1.9,8.3,3,13.2l17.9-2.8l7.2-1.1l0.1-0.1c0.3-0.1,0.4-0.3,0.7-0.3h0.3l0.1,0.1l1.1-0.1
	h2.8l-0.3-0.3c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4h2.6c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1
	s-0.1,0.7-0.4,1l-0.3,0.3l4.7-0.1c0.3-1,1.4-1.8,3-2.5l3.3-7.6L317.2,162.1L317.2,162.1z M367.4,152.1c-0.5,1.1-0.5,2.2,0.1,3.2
	l3.2,4.3l12-32.5c-1.2,0.1-2.6,0.6-4.1,1.2h-0.1l-0.1,0.1l-1.9,1c-0.7,0.3-1.2,1-1.8,1.9L367.4,152.1L367.4,152.1z M370.9,159.5
	l0.4,0.6l8.3,3.9c0.8,0.3,1.4-0.1,1.5-1l0.8-2.6l9.9-29.6c0.4-1,0.1-1.5-0.7-1.8l-7.2-1.7h-1.2L370.9,159.5L370.9,159.5z"
          />
          <path
            fill="#FFFFFF"
            d="M371.3,160.1l-0.4-0.6l-3.2-4.3l-1.9,4.4l9.5,3.3l0.1-0.8l-0.1,0.8l-0.8,6.6c0.5,0.1,1,0.6,1.2,1l78.7-1.6
	c0.3-1.5,0.8-2.9,1.5-3.9c7.2-0.6,16.2-0.7,27.2-0.1c0.1-0.3,0.3-0.4,0.5-0.4h6.5c0.3,0,0.5,0.1,0.5,0.6v3.2h1.5
	c-0.7-0.3-0.5-2.3,0.6-5.9l1.9-5.1l-112.3,3.3l-0.8,2.6c-0.1,1-0.7,1.2-1.5,1L371.3,160.1L371.3,160.1z"
          />
          <path
            fill="#FFFFFF"
            d="M365.6,159.7l-0.4,1l-3.3,7.6l-0.7,1.5c1.5-0.8,3.4-1.2,5.6-1.4c3.2,0,5.5,0.7,6.9,2.1h0.3l0.1-1l0.8-6.6
	L365.6,159.7L365.6,159.7z"
          />
          <path
            fill="#FFFFFF"
            d="M361.2,169.8l-1.5,1c-0.4,0.3-0.7,0.7-1,1l-0.3,0.3l16.1-0.6l-0.5-0.8l-0.3-0.3c-1.4-1.4-3.7-2.1-6.9-2.1
	C364.5,168.6,362.8,169,361.2,169.8L361.2,169.8z"
          />
          <path
            fill="#656565"
            d="M455.7,164.9c-0.7,1.1-1.1,2.3-1.5,3.9l-0.1,0.6h7.3l0.3-0.7l0.1-0.1c6.6-0.6,13.6-0.7,21-0.4h1.4v-3.3h-1.4
	C471.8,164.2,462.8,164.3,455.7,164.9L455.7,164.9z M485,164.3v4.3h4.8c0.3,0,0.5-0.1,0.5-0.6v-3.2c0-0.3-0.1-0.6-0.5-0.6L485,164.3
	L485,164.3z"
          />
          <path
            fill="#FFFFFF"
            d="M529.6,140.1h-7.8c-4.4-0.6-8.3-0.3-11.4,0.8c-7.1,1.5-11.4,3.9-12.9,7.2l-3.3,8.8l-1.9,5.1
	c-1.1,3.7-1.2,5.6-0.5,5.9h5.2c10.9-0.4,21.7-0.7,32.9-0.7h92l34.5,0.7l15,0.8h0.3c2.5-0.6,3.6-1.6,3.4-3l-4.1-14.9
	c-1-3.6-2.2-5.8-3.7-6.9c-0.3-0.1-0.4-0.3-0.7-0.4l-3.3-1.2c-4.1-1.5-7.6-2.2-10-2.3h-8.5L529.6,140.1L529.6,140.1z M519.6,169
	c-7.6,0.1-15,0.3-22.4,0.6l0.7,11.7h2.3c56.1-1,111-0.7,164.9,0.8h1.4l9.8-10.7c-1.4-0.4-2.9-0.7-4.7-0.8h-0.3l-13.6-0.8l-35.9-0.7
	L519.6,169L519.6,169z M516.6,179.7c-2.2-0.3-3.3-1-3.3-2.1v-4.1c0-1.1,1.2-2.1,3.7-2.6l15.1-0.6h89.7l27.8,0.6
	c2.6,0.1,3.2,0.8,1.8,2.1l-3.7,4.7c-0.7,1.5-1.8,2.3-3.4,2.3C602.4,179.3,559.7,179.2,516.6,179.7L516.6,179.7z"
          />
          <path
            fill="#FFFFFF"
            d="M395.5,182.9c-1.6,0-3,0.6-3.7,1.5c-0.7,0.8-1.1,1.9-1.1,3.4c-0.4,11.6-0.5,24.1-0.5,37.6v147.1
	c-0.1,13.5,0.1,26,0.5,37.6c0,1.5,0.4,2.6,1.1,3.4c0.8,1,2.1,1.4,3.7,1.5l102.3,1.6h2.3V181.3h-2.3L395.5,182.9L395.5,182.9z"
          />
          <path
            fill="#FFFFFF"
            d="M500.2,206.6v6.6l165.1-1.2v-6.9 M500.2,181.3v25.3l165.1-1.6v-9.8c-0.1-7.8,0.3-12.1,1.2-13.1h-1.4
	C611.2,180.6,556.3,180.3,500.2,181.3L500.2,181.3z M500.2,241.8v6.5h165.1v-6.5 M500.2,248.3v29h165.1v-29"
          />
          <path fill="#FFFFFF" d="M500.2,213.2v28.6h165.1v-29.9" />
          <path
            fill="#656565"
            d="M644.5,140.1c-0.5-1.9-3-2.9-7.8-2.9h-94.9c-6.5-0.3-10.6,0.7-12.2,2.9H644.5L644.5,140.1z M674.9,165.9
	c0.3,1.5-1,2.5-3.4,3c6.1,0.7,10.3,1.8,12.8,3.3c0.8,0.6,1.5,1.2,2.2,2.1c0.6-1.5,1.9-2.1,4-1.8
	C689.7,168.1,684.7,165.9,674.9,165.9L674.9,165.9z M680.4,228.3c-0.4-0.3-1-0.4-1.7-0.4v8.9c0.7-0.1,1.2-0.1,1.7-0.4
	c0.7-0.3,1.1-0.7,1.1-1.4v-5.2C681.2,229.3,680.9,228.7,680.4,228.3L680.4,228.3z"
          />
          <path
            fill="#FFFFFF"
            d="M665.3,277.3H500.2v6.6h165.1 M665.3,320.5V314H500.2v6.5"
          />
          <path fill="#FFFFFF" d="M665.3,314v-30.1H500.2V314" />
          <path
            fill="#656565"
            d="M681.5,363.1c-0.1-0.8-0.5-1.4-1.1-1.6c-0.4-0.3-1-0.4-1.7-0.4v8.9c0.7-0.1,1.2-0.1,1.7-0.4
	c0.7-0.3,1.1-0.7,1.1-1.4V363.1L681.5,363.1z"
          />
          <path
            fill="#FFFFFF"
            d="M665.3,356.1v-6.6H500.2v6.6 M665.3,349.5v-29H500.2v29 M665.3,392.8v-7l-165.1-1.1v6.6"
          />
          <path
            fill="#FFFFFF"
            d="M666.5,415.8c-0.8-1-1.2-5.2-1.2-13.1v-9.9l-165.1-1.5v25.3c56.1,1.1,111,0.8,164.9-0.8L666.5,415.8
	L666.5,415.8L666.5,415.8z"
          />
          <path
            fill="#FFFFFF"
            d="M666.5,415.8h-1.4c-53.9,1.6-108.8,1.9-164.9,0.8h-2.3l-0.7,11.7c7.4,0.3,14.9,0.5,22.4,0.5h102.2l35.8-0.7
	l13.6-0.8h0.3c1.8-0.3,3.3-0.5,4.7-0.8L666.5,415.8L666.5,415.8z M644.3,417.5c1.7,0,2.8,0.8,3.4,2.3l3.7,4.7
	c1.4,1.4,0.7,2.1-1.8,2.2l-27.8,0.6h-92.4l-12.5-0.5c-2.5-0.6-3.7-1.5-3.7-2.6v-4.1c0-1.1,1.1-1.8,3.3-1.9
	C559.7,418.7,602.4,418.4,644.3,417.5L644.3,417.5z"
          />
          <path
            fill="#656565"
            d="M636.6,460.5c4.7,0,7.3-1,7.8-2.9H529.6c1.7,2.2,5.8,3.2,12.2,2.9H636.6L636.6,460.5z"
          />
          <path
            fill="#FFFFFF"
            d="M671.6,428.9l-0.3,0.1l-15,0.8l-34.5,0.7H530c-11.1,0-22.1-0.1-32.9-0.7h-5.2c-0.7,0.3-0.5,2.3,0.6,5.9
	l1.9,5.1l3.3,8.8c1.4,3.3,5.8,5.6,12.9,7.2c3.2,1.1,7,1.4,11.4,0.8h131.2c2.6-0.1,5.9-0.8,10-2.3l3.3-1.2l0.8-0.4
	c1.5-1,2.8-3.3,3.7-6.9l4.1-14.9C675.2,430.5,674.1,429.4,671.6,428.9L671.6,428.9z"
          />
          <path
            fill="#656565"
            d="M684.4,425.6c-2.5,1.5-6.7,2.6-12.8,3.3c2.5,0.5,3.6,1.6,3.4,3c9.6,0.1,14.9-2.2,15.5-6.6
	c-2.1,0.3-3.3-0.4-4-1.8C685.9,424.3,685.2,425,684.4,425.6L684.4,425.6z"
          />
          <path
            fill="#656565"
            d="M674.9,431.9l-4.1,14.9c-1,3.6-2.2,5.8-3.7,6.9l-0.8,0.4l-3.3,1.2c13.3-1.9,27.2-4.8,41.5-8.9
	c7-2.9,11.3-8.9,12.7-18.2l2.2-14.9h-22.7v9.8c0,1.2-0.5,2.1-1.7,2.2h-4.7C689.7,429.8,684.7,432,674.9,431.9L674.9,431.9z"
          />
          <path
            fill="#FFFFFF"
            d="M665.3,385.8v-29.7H500.2v28.6 M374.3,428.3l-0.1-1h-0.3c-1.4,1.4-3.7,2.1-6.9,2.1c-2.3-0.1-4.1-0.5-5.6-1.4
	l0.7,1.5l3.3,7.6l0.4,1.1l9.5-3.3L374.3,428.3L374.3,428.3z"
          />
          <path
            fill="#FFFFFF"
            d="M375.5,427.4c-0.3,0.4-0.7,0.8-1.2,1l0.8,6.6l0.1,0.8l-0.1-0.8l-9.5,3.3l1.9,4.4l0.7-0.8l2.5-3.4l0.4-0.6
	l8.3-3.7c0.8-0.3,1.4,0.1,1.5,1l0.8,2.6L494,441l-1.9-5.1c-1.1-3.7-1.2-5.6-0.5-5.9H490v3.2c0,0.3-0.1,0.5-0.5,0.5H483
	c-0.3,0-0.4-0.1-0.5-0.4c-11,0.5-20.1,0.4-27.2-0.1c-0.7-1.1-1.1-2.3-1.5-3.9L375.5,427.4L375.5,427.4z M374,427.1l0.5-0.8
	l-16.1-0.5l0.3,0.3c0.3,0.4,0.7,0.7,1,1l1.5,1c1.5,0.8,3.4,1.2,5.6,1.4c3.2,0,5.5-0.7,6.9-2.1L374,427.1L374,427.1z"
          />
          <path
            fill="#FFFFFF"
            d="M381,434.9c-0.1-1-0.7-1.2-1.5-1l-8.3,3.7l-0.4,0.5l12,32.5h1.2l7.2-1.6c0.8-0.3,1.1-0.8,0.7-1.8l-9.9-29.6
	L381,434.9L381,434.9z"
          />
          <path
            fill="#FFFFFF"
            d="M378.7,469.5c1.5,0.8,2.9,1.2,4.1,1.2l-12-32.5l-2.5,3.4l-0.7,0.8c-0.7,1-0.7,2.1-0.1,3.2l7.2,20.6
	c0.4,1,1.1,1.7,1.8,1.9l1.9,1L378.7,469.5L378.7,469.5z"
          />
          <path
            fill="#656565"
            d="M490.4,429.8v-0.1c0-0.3-0.1-0.5-0.5-0.5h-4.8v4.3h4.8c0.3,0,0.5-0.1,0.5-0.5L490.4,429.8L490.4,429.8z
	 M484.2,429.8h-1.4c-7.4,0.3-14.4,0.1-21-0.4l-0.1-0.1l-0.3-0.7h-7.3l0.1,0.5c0.3,1.5,0.8,2.9,1.5,3.9c7.2,0.5,16.2,0.7,27.2,0.1
	h1.4v-3.3H484.2L484.2,429.8z M258.5,460.5c5.4,0,8.4-1.4,9.2-4.3H156.1c-0.3,0.3-0.8,0.3-1.5,0.3s-1.5-0.1-2.3-0.3h-2.3
	c1.1,2.9,5,4.3,11.3,4.3L258.5,460.5L258.5,460.5z"
          />
          <path
            fill="#FFFFFF"
            d="M353,427h-2.6l-0.3,0.4c-0.3,0.3-0.4,0.5-0.4,1s0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4h2.6
	c-0.3,0-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1C352.7,427.1,352.8,427,353,427L353,427z"
          />
          <path
            fill="#FFFFFF"
            d="M353.5,429.4h-2.6c-0.3,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1s0.1-0.7,0.4-1l0.3-0.3h-2.8l-1.1-0.1
	l-0.1,0.1H346l-0.7-0.3l-0.1-0.1l-7.2-1.1l-17.9-2.8c-1,4.8-1.9,9.4-3,13.2l48,1.4l-3.3-7.6c-1.6-0.7-2.8-1.5-3-2.5l-4.7-0.1
	l0.3,0.3c0.3,0.3,0.4,0.5,0.4,1c0,0.4-0.1,0.7-0.4,1C354.2,429.3,354,429.4,353.5,429.4L353.5,429.4z"
          />
          <path
            fill="#656565"
            d="M97.3,363.5l-1,0.7c-1.9,1.5-3,2.9-3,4.1l2.6,28.8c0.3,3.9,1.1,6.3,2.3,7.3l2.6,1.9c1.5,1,2.5,2.2,3,4.1
	c3.7,13.8,7.6,25.3,11.7,34.5c1,2.3,2.9,3.6,5.8,3.4h2.9c-1.9-0.1-3-1-3.3-2.6L108,408.5l-0.8-1.6c-0.4-0.7-1-1.4-1.5-2.1l-1.4-1.5
	l-0.1-0.3c-0.7-1.2-1.1-3.2-1.2-5.8l-2.6-28.8c0.1-1.2,1.1-2.9,3-5L97.3,363.5L97.3,363.5z"
          />
          <path
            fill="#656565"
            d="M104.3,363.4h-1.1c-1.9,1.9-2.9,3.6-3,5l2.6,28.8c0.1,2.6,0.6,4.5,1.2,5.8l23.9,0.4v-1l1.5-2.1l-1-32.7
	l-2.3-4.4h-3l-15.5,0.1C107.6,363.3,104.3,363.4,104.3,363.4z"
          />
          <path
            fill="#656565"
            d="M130,367.8l-3.9-4.5l2.3,4.5l1,32.6l1.5-2.1l-0.1-3"
          />
          <path
            fill="#FFFFFF"
            d="M130.8,395.3l0.1,3l-1.5,2.1l-1.5,2.1v4.7l-0.8,2.2l5.5,34.8c0.7,2.9,0.1,4.4-1.8,4.3h-3.3
	c1.9,2.8,5.8,5.1,11.3,7.2l2.1,0.7h9.2l-1.8-0.7c-5.1-2.1-8-4.7-8.9-7.7c-2.8-11.6-4.8-25.7-6.2-42.4c-0.4-3.7,4.4-6.9,14.3-9.4
	c-0.6-0.4-1.1-0.8-1.6-1.4c-3.2-2.9-5.5-3.6-7.3-2.3L130.8,395.3L130.8,395.3z"
          />
          <path
            fill="#656565"
            d="M104,403l0.1,0.3l1.4,1.5c0.6,0.7,1.1,1.4,1.5,2.1l0.8,1.7l19,0.4l-19-0.4l12.9,37.3
	c0.3,1.6,1.4,2.6,3.3,2.6h6.6c1.9,0.1,2.6-1.4,1.8-4.3l-5.5-34.8l0.8-2.2v-3.7L104,403L104,403z M175.8,395c-0.3-0.1-0.4,0-0.6,0.1
	l-0.1,0.1l-0.3,1l-0.1,0.8l-0.3,0.8c-0.1,0.3,0,0.5,0.4,0.7l1.6,0.4l2.1,0.6l1.6,0.4c0.3,0.1,0.6,0,0.6-0.4l0.1-0.5l0.3-1l0.3-1.2
	c0-0.3-0.1-0.4-0.4-0.5L175.8,395L175.8,395z"
          />
          <path
            fill="#656565"
            d="M175.1,395.3l0.1-0.1l-0.4-0.1h-0.4c-0.1,0.1-0.3,0.1-0.3,0.3l-0.1,0.3c0,0.1,0,0.3,0.1,0.4l0.3,0.3l0.3,0.1
	L175.1,395.3L175.1,395.3z M174.7,397.1l-1,0.4l-0.8,2.2h0.4l1.2,0.4c0.3,0.1,0.4,0.3,0.4,0.7l-0.6,1.9h0.3l0.3-1l0.1-0.3l1.4-2.3
	l-1.6-0.4c-0.3-0.1-0.4-0.3-0.4-0.7L174.7,397.1L174.7,397.1z M181.7,397l-0.3-0.1l-0.3,1.2l0.3,0.1h0.4l0.3-0.3l0.1-0.3
	c0-0.1,0-0.3-0.1-0.4C182.1,397.1,182,397.1,181.7,397L181.7,397z M181,399l-0.1,0.5c-0.1,0.3-0.3,0.4-0.6,0.4l-1.6-0.4l-0.4,2.9
	l-0.1,0.7h0.3l0.4-1.4c0.1-0.3,0.3-0.4,0.6-0.4l1.2,0.4c0.1,0,0.3,0.1,0.4,0.3l0.6-2.3L181,399L181,399z"
          />
          <path
            fill="#656565"
            d="M178.6,399.6l-2.1-0.5l-1.4,2.3l-0.1,0.3l-0.3,1.1l-0.1,0.7v0.1c-0.1,0.3,0,0.4,0.4,0.5l2.1,0.6h0.4l0.3-0.3
	v-0.1l0.3-1l0.1-0.7L178.6,399.6L178.6,399.6z M180.9,402c-0.1-0.1-0.1-0.1-0.4-0.3l-1.2-0.4c-0.3-0.1-0.6,0-0.6,0.4l-0.4,1.4
	l-0.3,1l-0.1,0.5c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.4h0.4l0.3-0.3l0.6-1.9l0.3-1.1L180.9,402L180.9,402z"
          />
          <path
            fill="#656565"
            d="M178,404.1h-0.1l-0.4,1.6c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.4c0.3,0.1,0.6,0,0.6-0.4l0.1-0.5h-0.3l-1.2-0.4
	c-0.3-0.1-0.4-0.3-0.3-0.5L178,404.1L178,404.1z M175,404c-0.3-0.1-0.4-0.3-0.4-0.5l-0.3,1c-0.1,0.3,0,0.5,0.3,0.5l1.9,0.5
	c0.3,0.1,0.6,0,0.6-0.3l0.1-0.7h-0.4L175,404L175,404z M695.8,289.1h-5.4v20.6h5.4v-2.2h-5h5v-16.2h-5h5"
          />
          <path
            fill="#656565"
            d="M695.8,288.4v22h2.2v-22 M698,285.3v28.5l2.2,0.7c3.7,0.8,7.3,0.7,10.6-0.7v-28.5c-3.3-1.2-6.9-1.5-10.6-0.5
	C699.5,284.7,698.8,285,698,285.3L698,285.3z"
          />
          <path
            fill="#656565"
            d="M695.8,310.4v-0.7h-5.4v62.6h3v-28.5c0.3-0.5,1-0.8,2.1-0.7h4.7v-29l-2.2-0.7v-3.2L695.8,310.4L695.8,310.4z
	"
          />
          <path
            fill="#656565"
            d="M695.5,343.3c-1-0.1-1.7,0.1-2.1,0.7v28.5h1c0.3,0,0.6,0.1,0.6,0.5v5.2c0,0.3-0.1,0.5-0.5,0.5h-1v0.6
	c0.1,4.7-0.1,7.7-0.7,9.2H720c-1-0.4-1.5-2.2-1.5-5.5v-21.2c-4.5-0.1-8.5-0.4-12.1-1.1c-4-1.2-6.1-3.7-6.5-7.6v-9.8h-4.4
	L695.5,343.3L695.5,343.3z"
          />
          <path
            fill="#656565"
            d="M693.6,372.3h-3v6.2h4c0.3,0,0.5-0.1,0.5-0.5v-5.2c0-0.3-0.1-0.5-0.5-0.5h-1L693.6,372.3L693.6,372.3z
	 M721.9,388.4h-31.5v0.8l3.3,1.4v6.7c0.1,2.8-0.7,4.3-2.3,4.5h3.9c1,0,1.5,0.5,1.5,1.5v9.9h25.2c0.7,0,1-0.3,1-1.1v-22.8
	C723,388.7,722.6,388.4,721.9,388.4L721.9,388.4z M693.6,379.2v-0.5h-3v9.8h2.5C693.5,386.9,693.6,383.9,693.6,379.2L693.6,379.2z"
          />
          <path
            fill="#656565"
            d="M693.7,397.4v-6.7l-3.3-1.4v12.7h1C693,401.6,693.9,400.1,693.7,397.4L693.7,397.4z M690.4,401.9v8.4
	c-0.1,5.9-1.5,10.3-4,13.3c0.5,1.5,1.9,2.1,4,1.8h4.7v-23.5H690.4L690.4,401.9z M174,193.1c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3
	c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.7c0-0.1,0.1-0.1,0.3-0.3l1.2-0.3h0.6L174,193.1L174,193.1z"
          />
          <path
            fill="#656565"
            d="M174.3,193.9l-0.1-0.1h-0.4l-1.2,0.3c-0.1,0-0.3,0.1-0.3,0.3v0.4l0.3,0.8l0.6,2.2c0,0.1,0.1,0.3,0.3,0.4h0.4
	l1.2-0.4c0.3-0.1,0.4-0.3,0.4-0.7l-0.6-1.9l-0.1-0.7L174.3,193.9L174.3,193.9z"
          />
          <path
            fill="#FFFFFF"
            d="M156.1,141.6l0.3,0.3c0.1,0.3-0.3,0.7-1.1,1.2c-0.8,0.6-1.8,1-3,1.4c-1.2,0.4-2.3,0.7-3.3,0.8
	c-1,0.1-1.5,0-1.5-0.3c-0.1-0.4,0.1-1,1-1.5s1.8-1.1,3-1.5l1-0.3h-2.5c-6.2,2.2-9.6,5-10.6,8.4c-1.9,8.3-3.4,17.6-4.7,28.3
	c-0.6,4.4-1,9.1-1.4,13.9c-0.4,3.9,4.4,6.9,14.3,9.4c0.8-0.6,1.6-1.1,2.6-1.6c1.8-1,4-1.8,6.5-2.5l15.7-2.1l-0.3-0.8v-0.4l-0.1-0.7
	c-0.1-0.3,0-0.6,0.3-0.6l1.2-0.3c0.3-0.1,0.6,0,0.6,0.3l0.1,0.7v0.1l0.1,0.7h0.1l-0.3-1.1c-0.1-0.3,0-0.6,0.3-0.6l1.9-0.6
	c0.3-0.1,0.6,0,0.6,0.4l0.1,0.7l0.3,0.3v0.1h0.1l-0.4-1.6c-0.1-0.3,0-0.6,0.3-0.6l1.2-0.4c0.3-0.1,0.6,0,0.6,0.4l0.1,0.6h0.1
	c0.1,0,0.1,0.1,0.3,0.3l0.6,1.9l76.6-10l49.5-7.2l13.3-1.9c-1-5-1.9-9.4-3-13.2l-1-3.7c-0.8-2.9-1.7-5.5-2.5-7.7
	c-1.4-3.6-4.4-5.8-9.2-6.6c-4.8-1.6-9.1-2.5-12.7-2.5L156.1,141.6L156.1,141.6L156.1,141.6z"
          />
          <path
            fill="#656565"
            d="M177.5,193.4H177l-2.1,0.6c-0.3,0.1-0.4,0.3-0.4,0.7l0.1,0.7l0.3,1.1l1.4,2.6l2.1-0.6l-0.4-2.9l-0.1-0.7
	l-0.3-1v-0.1L177.5,193.4L177.5,193.4z"
          />
          <path
            fill="#656565"
            d="M177.5,193.2l-0.1-0.7c-0.1-0.3-0.3-0.4-0.6-0.4l-1.9,0.6c-0.3,0.1-0.4,0.3-0.3,0.6l0.3,1.1
	c-0.1-0.3,0-0.6,0.4-0.7l2.1-0.6h0.1L177.5,193.2L177.5,193.2z"
          />
          <path
            fill="#FFFFFF"
            d="M267.7,456.2h24.1c3.6,0,7.8-0.8,12.7-2.5c4.8-0.8,7.8-3,9.2-6.6c0.8-2.3,1.6-4.8,2.5-7.7l1-3.7
	c1-4,1.9-8.4,3-13.2l-13.3-1.9l-49.5-7.2l-76.6-10l-0.6,1.9l-0.3,0.3h-0.1l-0.1,0.5c-0.1,0.3-0.3,0.4-0.6,0.4l-1.2-0.4
	c-0.3-0.1-0.4-0.3-0.3-0.5l0.4-1.6h-0.1v0.1l-0.3,0.3l-0.1,0.7c-0.1,0.3-0.3,0.4-0.6,0.3l-1.9-0.5c-0.3-0.1-0.4-0.3-0.3-0.6l0.3-1
	v-0.1h-0.1l-0.1,0.7v0.1l-0.1,0.7c-0.1,0.3-0.3,0.4-0.6,0.3l-1.2-0.3c-0.3-0.1-0.4-0.3-0.3-0.5l0.1-0.7V403l0.3-0.8l-15.7-2.1
	c-2.5-0.7-4.7-1.5-6.5-2.5l-2.6-1.5c-9.9,2.5-14.7,5.6-14.3,9.4c1.4,16.6,3.4,30.8,6.2,42.4c0.8,3,3.9,5.6,8.9,7.7l1.8,0.7h2.5
	l-1.9-0.7c-0.8-0.3-1.5-0.7-2.1-1.2c-0.8-0.5-1.1-1.1-1-1.5c0.1-0.3,0.6-0.4,1.5-0.3c1,0.1,2.1,0.4,3.3,0.8c1.2,0.4,2.2,0.8,3,1.4
	c0.6,0.3,0.8,0.5,1,0.8l0.1,0.3l-0.3,0.3h110.9V456.2L267.7,456.2z"
          />
          <path
            fill="#656565"
            d="M174.6,402.6l0.6-1.9c0.1-0.4,0-0.5-0.4-0.7l-1.2-0.4h-0.4l-0.3,0.3l-0.6,2.2l-0.3,0.8v0.4
	c0,0.1,0.1,0.1,0.3,0.1l1.2,0.4h0.4l0.1-0.1l0.1-0.7L174.6,402.6L174.6,402.6z M174.2,404.1h-0.6l-1.2-0.4c-0.1,0-0.3-0.1-0.3-0.1
	l-0.1,0.7c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.3c0.3,0.1,0.6,0,0.6-0.3L174.2,404.1z"
          />
          <path
            fill="#FFFFFF"
            d="M690.4,196v-8.4c-0.1-5.9-1.5-10.3-4-13.3c-0.7-0.8-1.4-1.5-2.2-2.1c-2.5-1.5-6.7-2.6-12.8-3.3h-0.3l-15-0.8
	l-34.4-0.7H530c-11.1,0-22.1,0.1-32.9,0.7l0.1,1.6c7.4-0.3,14.9-0.6,22.4-0.6h102.2l35.8,0.7l13.6,0.8h0.3c1.8,0.3,3.3,0.6,4.7,0.8
	l-9.8,10.7c6.3,2.3,9.6,8.1,10,17.2v199.3c-0.4,9.1-3.7,14.7-10,17.2l9.8,10.6c-1.4,0.4-2.9,0.7-4.7,0.8h-0.3l-13.6,0.8l-35.8,0.7
	H519.7c-7.6-0.1-15-0.3-22.4-0.5l-0.1,1.6c10.9,0.4,21.7,0.7,32.9,0.7h91.8l34.5-0.7l15-1l0.3-0.1c6.1-0.7,10.3-1.8,12.8-3.3
	c0.8-0.5,1.5-1.2,2.2-2.1c2.5-3,3.9-7.4,4-13.3v-21l-1-0.4v8.9c0.1,2.3-0.4,3.3-1.8,3c-2.3-0.5-4-1.5-5.2-2.8
	c-0.6-0.5-1-1.1-1.2-1.6l-0.3-0.8c-0.1-1.1-0.3-2.1-0.4-3.2V371H679v-10.7h1.7V237.8H679v-10.7h1.7v-21.5c0.1-1.1,0.3-2.2,0.4-3.2
	l0.3-0.8c0.3-0.6,0.7-1.1,1.2-1.6c1.2-1.2,3-2.2,5.2-2.8c1.4-0.4,1.9,0.7,1.8,3v8.9l1-0.4V196H690.4L690.4,196z"
          />
          <path
            fill="#FFFFFF"
            d="M690.4,208.6l-1,0.4l-2.2,2.3v175.1l2.2,2.3l1,0.4V209.5"
          />
          <path
            fill="#FFFFFF"
            d="M689.5,209.1l-0.3,0.1L689.5,209.1v-8.9c0.1-2.3-0.4-3.3-1.8-3c-2.3,0.6-4,1.5-5.2,2.8
	c2.8-1.2,4.3-0.8,4.7,1.1l0.3,10.3L689.5,209.1L689.5,209.1z"
          />
          <path
            fill="#6B9BD2"
            d="M700.2,245.8v0.4l0.1-1.4 M126,234.6l0.1-0.1h-2.2 M104.1,234.4h3.4 M104.3,234.4h-0.1H104.3z"
          />
          <path
            fill="#FFFFFF"
            d="M180.6,194.5l0.3,1.1v0.4l0.6,2.3l-0.4,0.6l0.3,1l0.3-0.1h0.4l0.3,0.3l0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.4,0.3
	l-0.3,0.1c0,0.3-0.1,0.4-0.4,0.4l-5.4,1.5c-0.3,0.1-0.4,0-0.6-0.1l-0.4,0.1h-0.4l-0.3-0.3l-0.1-0.3c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.3,0.3-0.3l0.3-0.1v-1l-1-0.4l-0.8-2.2c-0.1-0.1-0.1-0.1-0.3-0.4l-0.6-2.2l-15.7,2.1c-2.5,0.7-4.7,1.5-6.5,2.5
	c2.5-1,4.3-0.8,5.4,0.4c1.2,3.6,1.9,12.1,2.1,25.6c0.4,16,0.6,31.2,0.6,45.9v67.4c0,10.5-0.3,21.2-0.6,32.2c0,13.5-0.7,22-2.1,25.6
	c-1.1,1.4-2.9,1.5-5.4,0.4c1.8,1,4,1.8,6.5,2.5l15.7,2.1l0.6-2.2c0-0.1,0.1-0.3,0.3-0.3l0.8-2.2l1-0.4l0.1-0.8l-0.3-0.1l-0.3-0.3
	c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.3c0-0.1,0.1-0.3,0.3-0.3h0.4l0.4,0.1c0.1-0.1,0.3-0.3,0.6-0.1l5.4,1.4c0.3,0.1,0.4,0.3,0.4,0.5
	l0.3,0.1c0.1,0,0.3,0.1,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4l-0.1,0.3l-0.3,0.3h-0.4l-0.3-0.1l-0.3,1l0.4,0.5l-0.6,2.3v0.4l-0.3,1.1
	l76.6,10l49.5,7.2l1.1-9.8c0.7-25.2,1.1-49,1.1-71.3v-69.9c0.1-25.4-0.1-53-1.1-82.5l-1.2-9.8l-49.5,7.2L180.6,194.5L180.6,194.5z
	 M258.9,246.3c0-1.1,0.4-1.9,1.1-2.8c0.8-0.8,1.6-1.1,2.8-1.1s1.9,0.4,2.8,1.1c0.8,0.8,1.1,1.6,1.1,2.8s-0.4,1.9-1.1,2.8
	c-0.8,0.7-1.6,1.1-2.8,1.1s-1.9-0.4-2.8-1.1C259.3,248.3,258.9,247.3,258.9,246.3z M260.1,289.1c0.3,0.3,0.4,0.7,0.4,1.1
	c0,0.4-0.1,0.8-0.4,1.1l-0.5,0.4v4.3c0.1,0.1,0.3,0.3,0.3,0.5v0.3l1.8,0.8v2.8l-1.8,0.8v0.3c0,0.1-0.1,0.4-0.3,0.5v4.3l0.5,0.3
	c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.1
	c0-0.4,0.1-0.8,0.4-1.1l0.7-0.4v-4.3c-0.1-0.1-0.3-0.3-0.3-0.5v-4.8c0-0.3,0.1-0.4,0.3-0.5v-4.3l-0.7-0.4c-0.3-0.3-0.4-0.7-0.4-1.1
	c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.7-0.4,1.1-0.4C259.4,288.6,259.9,288.8,260.1,289.1L260.1,289.1z M262.7,347.7
	c1.1,0,1.9,0.4,2.8,1.1c0.8,0.8,1.1,1.6,1.1,2.8c0,1.1-0.4,1.9-1.1,2.8c-0.8,0.8-1.6,1.1-2.8,1.1s-1.9-0.4-2.8-1.1
	c-0.8-0.8-1.1-1.6-1.1-2.8c0-1.1,0.4-1.9,1.1-2.8C260.8,348.1,261.6,347.7,262.7,347.7L262.7,347.7z"
          />
          <path fill="#6B9BD2" d="M178.3,194.7l-0.3-1h-0.3l0.3,1" />
          <path
            fill="#FFFFFF"
            d="M157.4,226.1c0-13.5-0.7-22-2.1-25.6c-1.1-1.4-2.9-1.5-5.4-0.4c-1,0.4-1.8,1-2.6,1.6c-1.2,1.4-2.3,3.7-3.4,7
	c-1.2,4-2.3,9.4-3.2,16.1c-0.3,2.3-0.6,4.7-0.8,7.3c-0.3,3.2-0.7,6.6-1,10.2c-0.3,4.3-0.6,8.5-0.8,12.9c-0.1,3.4-0.3,7-0.6,10.5h2.2
	l0.1-3.6c0.1-1,0.7-1.5,1.6-1.6h6.2l0.3,0.1c0.4,0.1,0.7,0.3,1,0.7c0.6,0.5,0.8,1.5,1,2.9v1.8h7.3
	C157.9,253.1,157.8,239.9,157.4,226.1L157.4,226.1z M154.2,207.3l0.1,0.7c0.4,3.4,0.4,7.6,0,12.5c-0.3,3-0.7,5.8-1.2,8.4l-0.8,3.3
	L152,233c-1.1,3.4-2.2,5.2-3.3,5.2c-0.6,0-1-0.4-1.4-1.2s-0.7-2.2-1-3.9c-0.4-3.4-0.4-7.6,0-12.4c0.4-4.8,1.2-8.9,2.3-12.5l0.1-0.4
	c1.1-3.2,2.1-4.8,3.2-4.8C153.1,202.9,153.8,204.4,154.2,207.3L154.2,207.3z M150.9,248.8v3.4c0,1.2-0.3,2.1-0.7,2.8
	c-0.6,0.8-1.5,1.4-2.8,1.4h-5.4c-0.8-0.1-1.4-0.6-1.5-1.5l0.6-9.8c0.3-1,1.1-1.4,2.2-1.2h5.4C150.2,244.1,150.9,245.8,150.9,248.8
	L150.9,248.8z M145,208.9c0.3,0,0.4,0.3,0.6,0.7l0.3,0.8v0.1l-0.3,1c-0.3,1.2-0.6,2.6-0.8,4.1c0.4-1.9,0.8-3.6,1.2-5.1l0.1,1
	c0.3,1.6,0.3,3.7,0,6.2c-0.1,2.5-0.6,4.5-1,6.2l-0.1,0.3l-0.3,0.8c-0.4,1-0.7,1.5-1.1,1.5c-0.1,0-0.3-0.1-0.6-0.4
	c-0.3-0.4-0.4-1.1-0.6-2.2c-0.3-1.7-0.3-3.7,0-6.2c0.1-2.5,0.6-4.5,1-6.2C144,209.9,144.4,208.9,145,208.9L145,208.9z"
          />
          <path
            fill="#FFFFFF"
            d="M149,272.3l-1,0.4l-0.8,0.1h-5.4c-1,0-1.6-0.5-1.8-1.5l0.3-5.5h-2.2c-0.4,9.9-0.7,19.8-0.8,30v6.3
	c0.1,10.2,0.3,20.2,0.8,30h2.2l-0.3-5.5c0.3-1,0.8-1.5,1.8-1.5h5.4l0.8,0.1c0.4,0.1,0.7,0.1,1,0.4c0.6,0.3,1,0.8,1.2,1.4
	c0.3,0.5,0.4,1.4,0.4,2.2v3h7.3v-60V266h-7.3v3c0,1-0.1,1.6-0.4,2.2C149.9,271.6,149.5,272.1,149,272.3L149,272.3z M150.2,281.8v4
	c0,0.8-0.3,1.4-0.6,1.9s-0.7,1-1.1,1.2c-0.3,0.1-0.7,0.3-1,0.4h-6.2c-1-0.1-1.5-0.5-1.8-1.4l0.1-9.5c0.4-1,1-1.5,1.9-1.5h6.3
	l0.8,0.3c0.4,0.3,0.8,1,1.1,1.7C150.1,279.8,150.2,280.7,150.2,281.8L150.2,281.8z M148.3,293.8c0.6,0.1,1,0.4,1.2,0.7
	c0.7,0.7,1,1.9,1,3.6v1.9c0,1.7-0.4,2.8-1,3.4c-0.3,0.3-0.7,0.5-1.1,0.7h-7c-1.1,0-1.8-0.7-2.1-1.8v-6.9c0.3-1.1,1-1.8,2.1-1.8h5.9
	L148.3,293.8L148.3,293.8z M151.9,289.7v0.8c0.1,0.3,0.3,0.5,0.4,0.5h2.9v1.9h-2.8c-0.3,0-0.4-0.1-0.7-0.3c-0.3-0.3-0.6-0.8-0.7-1.5
	l-0.1-2.1c0-0.4,0-0.7,0.1-1c0.1-0.5,0.4-1.1,0.7-1.2c0.1-0.1,0.3-0.1,0.4-0.1h0.6v1.9h-0.6l-0.3,0.1
	C152,289,151.9,289.2,151.9,289.7L151.9,289.7z M151,297.5c-0.1-0.4-0.1-1-0.1-1.5l0.1-1c0.1-0.7,0.4-1.2,0.8-1.5
	c0.1-0.1,0.4-0.1,0.7-0.1s0.4,0.1,0.7,0.3c0.3,0.3,0.4,0.5,0.6,0.8l0.1,1.2v1.2c-0.1,0.8-0.3,1.4-0.7,1.7l-0.4,0.3l-0.4,0.1v-3.7
	h-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.3-0.3,0.5v0.5c0,0.3,0.1,0.4,0.1,0.5v1.8h-0.1l-0.3-0.4
	C151.3,298.3,151.2,297.9,151,297.5L151,297.5z M146.8,308.5l0.8,0.1c0.4,0,0.7,0.1,1.1,0.4c0.6,0.3,0.8,0.7,1.1,1.2
	c0.3,0.5,0.4,1.1,0.4,1.8v4c0,1.1,0,2.1-0.3,2.8c-0.3,0.8-0.6,1.4-1.1,1.6c-0.3,0.1-0.6,0.3-1,0.4h-6.3c-0.8,0-1.5-0.5-1.9-1.5
	l-0.1-9.5c0.3-0.8,0.8-1.2,1.8-1.4H146.8L146.8,308.5z M153.2,299.7c0.3,0.3,0.4,0.5,0.6,1c0.1,0.3,0.1,0.7,0.1,1.1s0,0.8-0.1,1.2
	c-0.1,0.8-0.3,1.2-0.7,1.5l-0.4,0.3h-0.4v-3.7c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.3,0.4-0.3,0.7v0.4c0,0.3,0.1,0.4,0.1,0.5v1.9h-0.1
	l-0.3-0.3c-0.1-0.3-0.3-0.7-0.4-1.1s-0.1-1-0.1-1.5c0-0.4,0-0.7,0.1-1c0.1-0.7,0.4-1.2,0.8-1.5c0.1-0.1,0.3-0.1,0.4-0.1
	C152.7,299.3,153,299.4,153.2,299.7L153.2,299.7z M153.9,307.1h-0.3v0.1l0.1,0.3l0.1,0.7c0.1,0.5,0.1,1,0,1.5
	c-0.1,0.4-0.3,0.8-0.6,1.1l-0.6,0.3l-1-0.1c-0.3-0.1-0.4-0.3-0.6-0.5c-0.3-0.4-0.4-0.8-0.4-1.4c0-0.4,0-0.8,0.1-1.2l0.1-0.4v-0.3
	h-1.1v-1.9h3.9L153.9,307.1L153.9,307.1z"
          />
          <path
            fill="#FFFFFF"
            d="M145.5,337.4h-3.4c-1.1-0.3-1.6-0.8-1.6-1.6l-0.1-3.6h-2.2c0.1,2.5,0.3,5,0.3,7.4l0.1,3
	c0.3,4.3,0.4,8.7,0.8,12.9c0.3,3.7,0.6,7,1,10.2c0.3,2.6,0.6,5.1,0.8,7.4c0.8,6.6,1.9,12,3.2,16.1c1,3.3,2.2,5.6,3.4,7l2.6,1.5
	c2.5,1.1,4.3,0.8,5.4-0.4c1.2-3.4,1.9-12.1,2.1-25.6c0.3-11,0.4-21.7,0.6-32.2v-7.4H151v1.8c-0.1,1.2-0.4,2.2-1,2.9
	c-0.3,0.3-0.6,0.5-1,0.7h-3.6L145.5,337.4L145.5,337.4z M142.1,341.5h5.4c1.4,0,2.3,0.5,3,1.5c0.3,0.7,0.6,1.5,0.6,2.5v3.3
	c0.1,3-0.7,4.7-2.2,5h-5.4c-1.1,0.3-1.8-0.1-2.1-1.2l-0.6-9.8C140.7,342.1,141.3,341.7,142.1,341.5L142.1,341.5z M144.4,372.3
	l0.4,1.4l0.1,0.1c0.4,1.6,0.8,3.7,1,6.2c0.3,2.5,0.3,4.5,0,6.2l-0.3,1.6l-0.1,0.3c-0.1,0.4-0.4,0.7-0.6,0.7c-0.6,0-1-0.8-1.5-2.6
	c-0.4-1.6-0.8-3.7-1-6.2c-0.3-2.5-0.3-4.5,0-6.2c0.1-1.1,0.3-1.8,0.6-2.2c0.1-0.3,0.3-0.4,0.6-0.4
	C143.8,371.4,144,371.7,144.4,372.3L144.4,372.3z M146.2,372.8c-0.1-2.9,0-5.5,0.3-7.8c0.3-1.8,0.6-3,1-3.9c0.4-0.8,0.8-1.2,1.4-1.2
	c1.1,0,2.2,1.6,3.3,5.1l0.3,0.8l0.8,3.3c0.6,2.5,1,5.2,1.2,8.4c0.4,4.8,0.4,8.9,0,12.4l-0.1,0.7c-0.4,2.9-1.2,4.4-2.2,4.4
	s-2.1-1.6-3.2-4.8l-0.1-0.4c-0.4-1.4-0.8-2.9-1.1-4.4c-0.6-2.5-1-5.1-1.2-8C146.4,375.8,146.2,374.3,146.2,372.8L146.2,372.8z
	 M149,207.7l-0.1,0.4c-1.1,3.4-1.9,7.6-2.3,12.5c-0.4,4.8-0.4,8.9,0,12.4c0.3,1.8,0.6,3,1,3.9s0.8,1.2,1.4,1.2
	c1.1,0,2.2-1.6,3.3-5.2l0.3-0.8l0.8-3.3l-0.4-0.6c-0.3-0.6-0.4-1.2-0.6-2.3v-0.3c-0.3-2.3-0.3-5.1,0-8.4c0.3-3,0.8-5.6,1.4-7.8
	l0.3-0.8c0.1-0.4,0.3-0.8,0.6-1.2c-0.4-2.9-1.2-4.4-2.2-4.4C151,202.9,149.9,204.5,149,207.7L149,207.7z M145.4,211.5l0.3-1v-0.1
	l-0.3-0.8c-0.1-0.4-0.4-0.7-0.6-0.7c-0.6,0-1,0.8-1.5,2.6c-0.4,1.6-0.8,3.7-1,6.2c-0.3,2.5-0.3,4.5,0,6.2c0.1,1.1,0.3,1.8,0.6,2.2
	c0.1,0.3,0.3,0.4,0.6,0.4c0.4,0,0.7-0.6,1.1-1.5l-0.1-1.1c-0.3-1.6-0.3-3.7,0-6.2l0.3-2.1C144.9,214.1,145.1,212.8,145.4,211.5
	L145.4,211.5z M144.3,243.9h-1c-1.1-0.1-1.8,0.3-2.1,1.2l-0.6,9.8c0.1,0.8,0.6,1.4,1.5,1.5h5.4c1.2,0,2.2-0.4,2.8-1.4h-5.9v-11.1
	L144.3,243.9L144.3,243.9z M142,260.5c-1.1,0.3-1.6,0.8-1.6,1.6l-0.4,9.1c0.3,1,0.8,1.5,1.8,1.5h2.2c0.1-4.1,0.4-8.3,0.7-12.4H142
	L142,260.5L142,260.5z"
          />
          <path
            fill="#6B9BD2"
            d="M150.2,271c0.3-0.5,0.4-1.4,0.4-2.2v-4.7L150.2,271L150.2,271z"
          />
          <path
            fill="#FFFFFF"
            d="M143.8,276.9h-2.2c-0.8,0-1.5,0.5-1.9,1.5l-0.1,9.5c0.3,0.8,0.8,1.2,1.8,1.4h2.1
	C143.5,285.1,143.6,281,143.8,276.9L143.8,276.9z"
          />
          <path
            fill="#6B9BD2"
            d="M150.2,281.8c0-1.2,0-2.2-0.3-2.9l-0.3,8.8c0.3-0.5,0.4-1.1,0.6-1.9L150.2,281.8L150.2,281.8z"
          />
          <path
            fill="#FFFFFF"
            d="M153,288.8v-1.9h-0.6c-0.1,0-0.3,0-0.4,0.1c-0.3,0.3-0.6,0.7-0.7,1.2c-0.1,0.3-0.1,0.5-0.1,1l0.1,2.1
	c0.1,0.7,0.3,1.2,0.7,1.5c0.1,0.1,0.4,0.3,0.7,0.3h2.8v-1.9h-2.9c-0.1-0.1-0.3-0.3-0.4-0.5v-0.8c0-0.4,0.1-0.7,0.3-0.8l0.3-0.1
	L153,288.8L153,288.8z M153.9,295.9l-0.1-1.2c-0.1-0.4-0.3-0.7-0.6-0.8c-0.1-0.1-0.4-0.3-0.7-0.3s-0.6,0-0.7,0.1
	c-0.4,0.3-0.7,0.8-0.8,1.5l-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1l0.3,0.4h0.1v-1.8c-0.1-0.1-0.1-0.3-0.1-0.5v-0.5
	c0-0.3,0.1-0.4,0.3-0.5l0.1-0.1h0.1v3.7l0.4-0.1l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.6L153.9,295.9L153.9,295.9z M152.8,295.3l0.3,0.1
	c0.1,0.1,0.1,0.3,0.1,0.5v0.5c0,0.3-0.1,0.5-0.3,0.7h-0.1L152.8,295.3L152.8,295.3z"
          />
          <path
            fill="#6B9BD2"
            d="M153,295.3l-0.1,1.9h0.1c0.1-0.1,0.3-0.3,0.3-0.7V296c0-0.3-0.1-0.4-0.1-0.5L153,295.3L153,295.3z"
          />
          <path
            fill="#FFFFFF"
            d="M153.9,301.6c0-0.4,0-0.7-0.1-1.1c-0.1-0.4-0.3-0.7-0.6-1s-0.6-0.4-1-0.4c-0.1,0-0.3,0-0.4,0.1
	c-0.4,0.3-0.7,0.7-0.8,1.5c-0.1,0.3-0.1,0.7-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1.1l0.3,0.3h0.1v-1.9
	c-0.1-0.1-0.1-0.3-0.1-0.5v-0.4c0-0.4,0.1-0.5,0.3-0.7c0.1-0.1,0.1-0.1,0.3-0.1v3.7h0.4l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.5
	C153.9,302.6,153.9,302.2,153.9,301.6L153.9,301.6z M153.1,301.4c0.1,0.1,0.1,0.3,0.1,0.5v0.5c0,0.3-0.1,0.5-0.3,0.7l-0.1,0.1v-1.9
	L153.1,301.4L153.1,301.4z"
          />
          <path
            fill="#6B9BD2"
            d="M153.4,301.9c0-0.3-0.1-0.4-0.1-0.5l-0.1-0.1H153v1.9l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.7V301.9L153.4,301.9z"
          />
          <path
            fill="#FFFFFF"
            d="M139.4,295.4v6.9c0.3,1.1,1,1.8,2.1,1.8h1.9v-10.5h-1.9C140.3,293.8,139.6,294.3,139.4,295.4L139.4,295.4z
	 M151.2,307.3l-0.1,0.4c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.5,0.1,1,0.4,1.4c0.1,0.3,0.3,0.4,0.6,0.5l1,0.1l0.6-0.3
	c0.3-0.3,0.4-0.6,0.6-1.1c0.1-0.4,0.1-1,0-1.5l-0.1-0.7l-0.1-0.3V307h0.3v-1.8h-3.9v1.9h1.1V307.3L151.2,307.3z M152.1,307.1h0.3
	l0.4,0.1l0.1,0.1c0.1,0.3,0.3,0.5,0.3,1v0.3l-0.3,0.5l-0.3,0.1l-0.7-0.1l-0.3-0.1c-0.1-0.1-0.3-0.4-0.3-0.8v-0.4l0.3-0.5
	L152.1,307.1L152.1,307.1z"
          />
          <path
            fill="#FFFFFF"
            d="M152.4,307.1h-0.3l-0.3,0.1l-0.3,0.5v0.4c0,0.3,0.1,0.5,0.3,0.8l0.3,0.1l0.7,0.1l0.3-0.1l0.3-0.5v-0.3
	c0-0.4-0.1-0.7-0.3-1l-0.1-0.1L152.4,307.1L152.4,307.1z M141.3,308.5c-1,0.1-1.5,0.5-1.8,1.4l0.1,9.5c0.4,1,1,1.5,1.9,1.5h2.2
	c-0.1-4.1-0.3-8.3-0.3-12.4L141.3,308.5L141.3,308.5z M141.8,325c-1,0-1.6,0.5-1.8,1.5l0.4,9.1c0.1,1,0.7,1.5,1.6,1.7h2.5
	c-0.3-4.1-0.4-8.3-0.7-12.4h-2.1L141.8,325L141.8,325z"
          />
          <path
            fill="#6B9BD2"
            d="M150.6,329.1c0-0.8-0.1-1.6-0.4-2.2l0.4,7L150.6,329.1L150.6,329.1z"
          />
          <path
            fill="#FFFFFF"
            d="M142.1,341.5c-0.8,0.1-1.4,0.5-1.5,1.4l0.6,9.8c0.3,1,1.1,1.5,2.2,1.2h1v-10.7h6.2c-0.6-1.1-1.5-1.5-3-1.5
	h-5.4L142.1,341.5L142.1,341.5z M148.8,359.8c-0.6,0-1,0.4-1.4,1.2c-0.4,0.8-0.7,2.1-1,3.9c-0.3,2.3-0.4,5-0.3,7.8
	c0,1.5,0.1,3,0.3,4.5c0.3,2.9,0.7,5.6,1.2,8c0.3,1.5,0.7,3,1.1,4.4l0.1,0.4c1.1,3.2,2.1,4.8,3.2,4.8c1,0,1.6-1.5,2.2-4.4l-0.6-1.2
	l-0.3-0.8c-0.7-2.2-1.1-4.8-1.4-7.8c-0.3-3.3-0.3-6.1,0-8.4v-0.3c0.1-1,0.3-1.8,0.6-2.3l0.4-0.5l-0.8-3.3l-0.3-0.8
	C150.9,361.5,149.9,359.8,148.8,359.8L148.8,359.8z M144,373.9c0.1-0.7,0.1-1.2,0.3-1.5c-0.3-0.7-0.6-1-1-1c-0.1,0-0.3,0.1-0.6,0.4
	c-0.3,0.4-0.4,1.1-0.6,2.2c-0.3,1.6-0.3,3.7,0,6.2c0.1,2.5,0.6,4.5,1,6.2c0.6,1.6,1,2.6,1.5,2.6c0.3,0,0.4-0.3,0.6-0.7l0.1-0.3
	c-0.1-0.4-0.4-1-0.6-1.6c-0.4-1.6-0.8-3.7-1-6.2C143.9,377.7,143.9,375.6,144,373.9L144,373.9z"
          />
          <path
            fill="#FFFFFF"
            d="M142.2,208.8h-4.7c-0.8,4-1.5,9.4-2.5,16.1l-0.8,7.3c-0.3,3.2-0.6,6.6-0.8,10.2l-0.8,12.9
	c-0.7,13.2-1.1,26.7-1.2,40.6v6.3c0.1,12.8,0.6,25.2,1.1,37.4l1,16c0.3,3.7,0.6,7,0.8,10.2c0.3,2.6,0.6,5.1,0.8,7.4
	c0.8,6.6,1.6,12,2.5,16.1h4.7c-1-4-1.9-9.4-2.9-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.6-6.6-0.8-10.2l-1-16
	c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.4,1.2-40.6l0.8-12.9c0.3-3.7,0.6-7,0.8-10.2c0.3-2.6,0.6-5.1,0.8-7.3
	C140.3,218.1,141.3,212.8,142.2,208.8L142.2,208.8z"
          />
          <path
            fill="#FFFFFF"
            d="M140.9,224.7c0.8-6.6,1.9-12,3.2-16.1h-1.8c-1,4-1.9,9.4-2.8,16.1c-0.3,2.3-0.6,4.7-0.8,7.3
	c-0.3,3.2-0.6,6.6-0.8,10.2l-0.8,12.9c-0.7,13.2-1.1,26.7-1.2,40.6v6.3c0.1,12.8,0.6,25.2,1.1,37.4l1,16c0.3,3.7,0.6,7,0.8,10.2
	c0.3,2.6,0.6,5.1,0.8,7.4c0.8,6.6,1.8,12,2.8,16.1h1.8c-1.2-4-2.3-9.4-3.2-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.7-6.6-1-10.2
	c-0.3-4.3-0.6-8.5-0.8-12.9l-0.1-3c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.2,1.2-40.6c0.3-4.4,0.4-8.7,0.8-12.9
	c0.3-3.7,0.6-7,1-10.2C140.3,229.5,140.6,227.1,140.9,224.7L140.9,224.7z M320.1,175.3l-13.3,1.9l1.1,9.8c0.8,29.6,1.2,57.1,1,82.5
	v69.9c0,22.3-0.4,46.1-1.1,71.3l-1.1,9.8l13.3,1.9l17.9,2.8c1.5-1.6,2.3-6.2,2.5-13.6l0.1-12.9l0.7-59.3l-0.7-140.5l-0.1-12.9
	c-0.1-7.4-1-11.8-2.5-13.6L320.1,175.3L320.1,175.3z M350.9,168.5c-0.3,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1
	l0.3,0.3h2.6l-0.3-0.3c-0.3-0.3-0.4-0.6-0.4-1c0-0.3,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,1-0.4L350.9,168.5L350.9,168.5z"
          />
          <path
            fill="#FFFFFF"
            d="M347.6,171.1l-1.1,0.1h0.1l0.1,0.1l0.3,0.4v0.1l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.3-0.1-0.3-0.1-0.4-0.3
	c0-0.1,0.1-0.3,0.3-0.4l-7.2,1.1c1.5,1.7,2.3,6.2,2.5,13.6l0.1,12.9h5.6v-2.1c-0.3-8.7,0.1-13.3,1.2-14l5.4,1.1c1.4-5,3.9-8,7.3-8.9
	l-1.4-0.3v0.1l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.3,0.1-0.4,0.3-0.6l-3-0.8h0.1c0.1,0.1,0.3,0.3,0.3,0.6
	l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.3,0.1-0.4,0.3-0.6c0.3-0.1,0.5-0.3,0.8-0.3h0.3c-2.2-0.8-2.9-1.6-2.1-2.5
	h-1.4L347.6,171.1L347.6,171.1z M346.5,173.4c0-0.3,0.1-0.4,0.3-0.6c0.3-0.1,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3
	c0.3,0.1,0.3,0.4,0.3,0.6c0,0.1-0.1,0.3-0.3,0.3l-0.8,0.1l-0.8-0.1C346.7,173.7,346.7,173.6,346.5,173.4L346.5,173.4z M343.5,183.9
	c-0.1,0.3-0.3,0.3-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.3-0.8c0.3-0.1,0.5-0.3,0.8-0.3
	c0.1,0,0.3,0.1,0.4,0.3l0.1,0.8L343.5,183.9z M343.5,193c-0.1-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3
	l0.4,0.3l0.1,0.8l-0.1,0.7c-0.1,0.3-0.3,0.3-0.4,0.3C343.9,193.4,343.6,193.2,343.5,193L343.5,193z"
          />
          <path
            fill="#FFFFFF"
            d="M346.9,172.9c-0.3,0.1-0.3,0.3-0.3,0.6c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1c0.3-0.1,0.3-0.1,0.3-0.3
	c0-0.3-0.1-0.4-0.3-0.6c-0.3-0.1-0.5-0.3-0.8-0.3C347.4,172.6,347.1,172.7,346.9,172.9L346.9,172.9z M343.1,184.1
	c0.1,0,0.3-0.1,0.4-0.3l0.1-0.7l-0.1-0.8c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.6-0.3,0.8
	s0.1,0.6,0.3,0.7C342.5,184.1,342.8,184.1,343.1,184.1z M343.2,192.3c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,0.5,0.3,0.8,0.3
	c0.1,0,0.3-0.1,0.4-0.3l0.1-0.7l-0.1-0.8l-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3C343.2,191.7,343.2,192,343.2,192.3L343.2,192.3z
	 M346.7,171.2h-0.1l-0.1-0.1h-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4s0.1,0.3,0.4,0.3l0.8,0.1l0.8-0.1
	l0.3-0.3v-0.1l-0.3-0.4L346.7,171.2L346.7,171.2z M353.5,168.5c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,0.8c0,0.4,0.1,0.7,0.4,1
	l0.3,0.3h1.2l0.3-0.3c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1C354.2,168.6,354,168.5,353.5,168.5L353.5,168.5z M359.7,170.8l1.5-1
	l0.7-1.5c-1.6,0.7-2.8,1.5-3,2.5c-0.1,0.3-0.1,0.7-0.1,1C359,171.4,359.3,171.1,359.7,170.8L359.7,170.8z"
          />
          <path
            fill="#FFFFFF"
            d="M358.5,172l0.3-0.3c0-0.3,0-0.7,0.1-1l-4.7,0.1l-0.7,0.1l-0.5-0.1h-1.2c-1,1-0.3,1.8,2.1,2.5l0.4,0.1l3,0.8
	c0.3-0.1,0.4-0.3,0.7-0.3h0.3l0.4,0.1l0.1,0.1l0.3,0.4l1.4,0.3l28.9,6.9c1.5,0.3,2.3,1.1,2.6,2.3c0.8-1,2.1-1.4,3.7-1.5l102.3-1.6
	l-0.7-11.7l-0.1-1.6h-6.7v0.1c0,0.3-0.1,0.6-0.6,0.6h-6.5c-0.3,0-0.5-0.1-0.5-0.6l-21.2,0.4l-0.3,0.7h-7.3l0.1-0.6l-78.7,1.9
	c0.1,0.3,0.3,0.6,0.3,1l-1.2,0.1L358.5,172L358.5,172z M485.2,176.7c0.7,1.6,0.1,2.6-1.5,3l-86.5,1.5h-0.3c-1.9-0.1-4-0.6-5.9-1.4
	l-7.3-2.1l-8.4-2.8l-5.9-1.9l109.4-1.8c3.4,0.1,5.5,1.1,6.1,3L485.2,176.7L485.2,176.7z"
          />
          <path
            fill="#FFFFFF"
            d="M483.7,179.7c1.6-0.3,2.2-1.4,1.5-3l-0.5-2.5c-0.5-1.9-2.5-2.9-5.9-3l-109.4,1.9l5.9,1.9h105.6
	c1.9,0,2.6,0.7,2.2,2.1L483.7,179.7L483.7,179.7z M357.8,174.2c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.1-0.3,0.3-0.3,0.6
	c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1l0.3-0.3v-0.1l-0.3-0.4l-0.1-0.1l-0.4-0.1L357.8,174.2L357.8,174.2z"
          />
          <path fill="#6B9BD2" d="M353,170.9l0.5,0.1l0.7-0.1" />
          <path
            fill="#FFFFFF"
            d="M353.4,173.4c-0.3,0-0.5,0.1-0.8,0.3s-0.3,0.3-0.3,0.6c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1l0.3-0.3
	c0-0.3-0.1-0.4-0.3-0.6h-0.1l-0.4-0.1L353.4,173.4L353.4,173.4z M346.4,199h-2.6l0.7,61.6l-0.7,138.1h2.6l0.7-59.2"
          />
          <path
            fill="#FFFFFF"
            d="M343.8,199h-3.2l0.7,140.6l-0.7,59.2h3.2l0.7-138.1 M360.3,175.1c-3.6,1.1-5.9,4-7.3,8.9l24.5,5.8
	c2.8,0.6,4.1,3.3,4.1,8.4v201.4c0,5.1-1.4,7.8-4.1,8.4l-24.5,5.8c1.4,5,3.9,8,7.3,8.9l28.9-6.9c1.5-0.3,2.3-1.1,2.6-2.3
	c-0.7-0.8-1.1-1.9-1.1-3.4c-0.4-11.6-0.5-24.1-0.5-37.6V225.4c-0.1-13.5,0.1-26,0.5-37.6c0-1.5,0.4-2.6,1.1-3.4
	c-0.1-1.2-1.1-2.1-2.6-2.3L360.3,175.1L360.3,175.1z"
          />
          <path
            fill="#6B9BD2"
            d="M461.8,168.5l-0.1,0.1l21.2-0.4v-0.1C475.4,167.8,468.4,167.9,461.8,168.5L461.8,168.5z"
          />
          <path
            fill="#6B9BD2"
            d="M485,164.3h-1.7c-0.3,0-0.4,0.1-0.5,0.4h1.4v3.3h-1.4v0.1c0,0.3,0.1,0.6,0.5,0.6h1.7L485,164.3L485,164.3z
	 M374.2,170.4l-0.4,0.1l0.3,0.3"
          />
          <path
            fill="#FFFFFF"
            d="M374.3,169.4l-0.1,1l-0.1,0.3l0.5,0.8l1.2-0.1c0-0.4-0.1-0.7-0.3-1C375.3,170,374.9,169.7,374.3,169.4
	L374.3,169.4z M681.1,201.5l-0.3,0.8c-0.1,1.1-0.3,2.1-0.4,3.2v5.8h7l-0.3-10.3c-0.4-2.1-1.9-2.5-4.7-1.1
	C681.8,200.4,681.4,200.8,681.1,201.5L681.1,201.5z M666.5,182.1c-0.8,1-1.2,5.2-1.2,13.1v207.7c-0.1,7.8,0.3,12.1,1.2,13.1
	c6.3-2.3,9.6-8.1,10-17.2V199.3C676.1,190.2,672.8,184.4,666.5,182.1L666.5,182.1z M678.7,227.1v1c0.7,0,1.2,0.1,1.7,0.4v-1.4
	L678.7,227.1L678.7,227.1z M680.4,211.4v17.1c0.5,0.3,1,0.8,1.1,1.6v5.2c-0.1,0.6-0.4,1.1-1.1,1.4v125c0.5,0.3,1,0.8,1.1,1.6v5.2
	c-0.1,0.5-0.4,1.1-1.1,1.4v16.8h7V211.4H680.4z M678.7,237.8h1.7v-1.2c-0.4,0.1-1,0.3-1.7,0.4V237.8L678.7,237.8z M680.4,361.5v-1.4
	h-1.7v1C679.4,361.2,680,361.2,680.4,361.5z M678.7,370.1v0.8h1.7v-1.2C680,370,679.4,370,678.7,370.1L678.7,370.1z"
          />
          <path
            fill="#FFFFFF"
            d="M681.1,396.4c0.3,0.5,0.7,1.1,1.2,1.6c2.8,1.2,4.3,0.8,4.7-1.1l0.3-10.3h-7v5.8c0.1,1.1,0.3,2.2,0.4,3.2
	L681.1,396.4L681.1,396.4z"
          />
          <path
            fill="#FFFFFF"
            d="M687.3,386.5l-0.3,10.3c-0.4,2.1-1.9,2.5-4.7,1.1c1.2,1.2,3,2.2,5.4,2.8c1.4,0.3,1.9-0.7,1.8-3v-8.9
	L687.3,386.5L687.3,386.5z M395.5,415c-1.6-0.1-3-0.5-3.7-1.5c-0.1,1.2-1,2.1-2.6,2.3l-28.9,6.9l-1.4,0.3l-0.3,0.4l-0.1,0.1
	l-0.4,0.1h-0.3c-0.3,0-0.5-0.1-0.7-0.3l-1.6,0.4c-0.4,0.1-1,0.3-1.2,0.4l-0.4,0.1c-2.2,0.8-2.9,1.7-2.1,2.5h1.2l0.5-0.1l0.7,0.1
	l4.7,0.1c-0.1-0.3-0.1-0.6-0.1-1l-0.3-0.3l16.1,0.5l1.2,0.1c0,0.4-0.1,0.7-0.3,1l78.7,1.6l-0.1-0.5h7.3l0.3,0.7l21.2,0.4
	c0-0.3,0.1-0.5,0.5-0.5h6.5c0.3,0,0.5,0.1,0.5,0.5v0.1h6.7l0.1-1.6l0.7-11.7L395.5,415L395.5,415z M375.3,422.8l8.4-2.8l7.3-2.1
	c1.9-0.7,4-1.1,5.9-1.4h0.3l86.5,1.5c1.6,0.3,2.2,1.4,1.5,3l-0.5,2.5c-0.5,1.9-2.5,2.9-5.9,3l-109.4-1.8L375.3,422.8L375.3,422.8z"
          />
          <path
            fill="#FFFFFF"
            d="M485.2,421c0.7-1.6,0.1-2.8-1.5-3l-0.5,2.6c0.4,1.4-0.4,2.1-2.2,2.2H375.3l-5.9,1.9l109.4,1.8
	c3.4-0.1,5.5-1.1,6.1-3L485.2,421L485.2,421z M374,427.1l0.1,0.3l0.1,1c0.5-0.1,1-0.5,1.2-1c0.1-0.3,0.3-0.5,0.3-1l-1.2-0.1
	L374,427.1L374,427.1z"
          />
          <path
            fill="#6B9BD2"
            d="M374.2,427.4l-0.1-0.3l-0.3,0.3 M482.9,429.8h1.4v3.3h-1.4c0.1,0.3,0.3,0.4,0.5,0.4h1.7v-4.3h-1.7
	C483.1,429.3,482.8,429.4,482.9,429.8L482.9,429.8z"
          />
          <path
            fill="#6B9BD2"
            d="M482.9,429.8v-0.1l-21.2-0.4l0.1,0.1C468.4,430,475.4,430.1,482.9,429.8L482.9,429.8z"
          />
          <path
            fill="#FFFFFF"
            d="M343.8,398.8h-3l-0.1,12.9c-0.1,7.4-1.1,11.8-2.5,13.6l7.2,1.1c-0.1-0.1-0.3-0.3-0.3-0.4
	c0-0.1,0.1-0.3,0.4-0.3l0.8-0.1l0.8,0.1c0.1,0.1,0.3,0.1,0.3,0.3v0.1l-0.3,0.4l-0.1,0.1h-0.1l1.1,0.1h4.1c-1-1-0.3-1.8,2.1-2.5h-0.3
	c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1l0.8,0.1c0.1,0.1,0.3,0.1,0.3,0.3
	c0,0.3-0.1,0.4-0.3,0.5l-0.1,0.1l1.2-0.4l1.6-0.4c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1l0.8,0.1
	c0.1,0.1,0.3,0.1,0.3,0.3v0.1l1.4-0.3c-3.6-1.1-5.9-4-7.3-8.9l-5.4,1.1c-1.1-0.7-1.5-5.4-1.2-14v-2.1L343.8,398.8L343.8,398.8
	L343.8,398.8z M343.5,406.3c-0.1-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.3,0.8-0.3c0.1,0,0.3,0.1,0.4,0.3l0.1,0.7
	l-0.1,0.8l-0.4,0.3C343.9,406.7,343.6,406.6,343.5,406.3L343.5,406.3z M343.6,414.7l-0.1,0.8c-0.1,0.1-0.3,0.3-0.4,0.3
	c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.4,0.8-0.4c0.1,0,0.3,0.1,0.4,0.4
	L343.6,414.7L343.6,414.7z M348.9,424.5c0,0.3-0.1,0.4-0.3,0.5c-0.3,0.1-0.5,0.3-0.8,0.3l-0.8-0.3c-0.3-0.1-0.3-0.4-0.3-0.5
	c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1l0.8,0.1C348.7,424.2,348.9,424.3,348.9,424.5L348.9,424.5z"
          />
          <path
            fill="#FFFFFF"
            d="M343.2,405.6c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3l0.4-0.3l0.1-0.8l-0.1-0.7
	c-0.1-0.3-0.3-0.3-0.4-0.4c-0.3,0-0.5,0.1-0.8,0.4C343.2,405.1,343.2,405.4,343.2,405.6L343.2,405.6z M343.5,415.4l0.1-0.8l-0.1-0.7
	c-0.1-0.3-0.3-0.3-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3
	C343.2,415.8,343.4,415.7,343.5,415.4L343.5,415.4z M348.5,425c0.3-0.1,0.3-0.4,0.3-0.5c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1
	c-0.3,0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.4,0.3,0.5l0.8,0.3C348,425.2,348.3,425.2,348.5,425L348.5,425z M352.6,423.2
	c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.4,0.3,0.6c0.3,0.1,0.5,0.3,0.8,0.3h0.3l0.4-0.1l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5
	c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1L352.6,423.2L352.6,423.2z M357.1,423.4c0.3,0.1,0.5,0.3,0.8,0.3h0.3l0.4-0.1l0.1-0.1l0.3-0.4v-0.1
	c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.1,0.1-0.3,0.1-0.3,0.3C356.7,423.1,356.8,423.2,357.1,423.4L357.1,423.4z M358.8,426.1
	c0,0.3,0,0.7,0.1,1c0.3,1,1.4,1.8,3,2.5l-0.7-1.5l-1.5-1C359.3,426.8,359,426.4,358.8,426.1L358.8,426.1z"
          />
          <path fill="#6B9BD2" d="M353.5,426.8L353,427h1.2" />
          <path
            fill="#FFFFFF"
            d="M353.5,429.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1l-0.3-0.3H353c-0.1,0.1-0.3,0.1-0.3,0.3
	c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,1C352.9,429.3,353.1,429.4,353.5,429.4L353.5,429.4z M346.5,426.7h0.1l0.1-0.1l0.3-0.4
	V426c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.3,0.1-0.3,0.1-0.4,0.3c0,0.1,0.1,0.3,0.3,0.4l0.1,0.1l0.7,0.3h0.3L346.5,426.7
	L346.5,426.7z"
          />
          <path
            fill="#6B9BD2"
            d="M104.1,363.5l0.1-0.1 M104.3,363.4l-0.1,0.1h3.4 M126.1,363.3h-3 M178,403l-0.3,1.1h0.3l0.3-1 M174.8,402.6
	h-0.3l-0.1,0.7h0.1 M693.6,378.7v0.5 M174,193.8l0.3,0.1l-0.1-0.1H174z M174.6,194.5l-0.1,0.1l0.1,0.6h0.3 M174,404.1h0.1l0.1-0.3"
          />
          <path
            fill="#EBEBEB"
            d="M152.1,225.7v0.3c0.1,1,0.3,1.8,0.6,2.3l0.4,0.6c0.6-2.5,1-5.2,1.2-8.4c0.4-4.8,0.4-8.9,0-12.5l-0.1-0.7
	c-0.1,0.4-0.3,0.8-0.6,1.2l-0.3,0.8c-0.7,2.2-1.1,4.8-1.4,7.8C151.9,220.5,151.9,223.4,152.1,225.7L152.1,225.7z M152.1,371.9v0.3
	c-0.3,2.3-0.3,5.1,0,8.4c0.3,3,0.8,5.6,1.4,7.8l0.3,0.8l0.6,1.2l0.1-0.7c0.4-3.4,0.4-7.6,0-12.4c-0.3-3-0.7-5.9-1.2-8.4l-0.4,0.5
	C152.6,370.1,152.3,370.8,152.1,371.9L152.1,371.9z M483.1,177.1c0.4-1.4-0.4-2.1-2.2-2.1H375.3l8.4,2.8l7.3,2.1
	c1.9,0.7,4,1.2,5.9,1.4h0.3l86.5-1.5L483.1,177.1L483.1,177.1z M346.4,196.9v2.1l0.7,140.5l-0.7,59.3v2.1c-0.3,8.7,0.1,13.3,1.2,14
	l5.4-1.1l24.5-5.8c2.8-0.5,4.1-3.3,4.1-8.4V198.2c0-5.1-1.4-7.8-4.1-8.4l-24.5-5.6l-5.4-1.1C346.5,183.7,346.1,188.4,346.4,196.9
	L346.4,196.9z M513.2,177.8c0,1.1,1.1,1.8,3.3,2.1c43.2-0.6,85.8-0.4,127.8,0.6c1.7,0,2.8-0.8,3.4-2.3l3.7-4.7
	c1.4-1.4,0.7-2.1-1.8-2.1l-27.9-0.7h-89.7l-15.1,0.6c-2.5,0.6-3.7,1.5-3.7,2.6L513.2,177.8L513.2,177.8z M647.8,419.9
	c-0.7-1.5-1.8-2.3-3.4-2.3c-42,1-84.6,1.1-127.8,0.5c-2.2,0.3-3.3,0.8-3.3,1.9v4.1c0,1.1,1.2,1.9,3.7,2.6l12.5,0.5h92.3l27.8-0.5
	c2.6-0.1,3.2-0.8,1.8-2.2L647.8,419.9L647.8,419.9z M383.7,420.1l-8.4,2.8h105.6c1.9,0,2.6-0.7,2.2-2.2l0.5-2.6l-86.5-1.5h-0.3
	c-1.9,0.1-4,0.5-5.9,1.4L383.7,420.1L383.7,420.1z"
          />
          <path
            fill="#656565"
            d="M696.8,174.7c0-1.2-0.5-2.1-1.7-2.2V196h0.1c1,0,1.5-0.6,1.5-1.5V174.7z M696.8,403.4c0-1-0.5-1.5-1.5-1.5
	h-0.1v23.5c1.1-0.3,1.7-1,1.7-2.2L696.8,403.4L696.8,403.4L696.8,403.4z"
          />
          <path
            fill="#D2D3D4"
            d="M156.4,141.9l-0.3-0.3c-0.3-0.3-0.8-0.3-1.5-0.3s-1.5,0.1-2.3,0.3l-1,0.3c-1.2,0.4-2.2,1-3,1.5
	s-1.1,1.1-1,1.5c0.1,0.3,0.6,0.3,1.5,0.3c1-0.1,2.1-0.3,3.3-0.8c1.2-0.4,2.2-0.8,3-1.4C156.1,142.6,156.4,142.2,156.4,141.9
	L156.4,141.9z M146,211.5l-0.1-1c-0.4,1.5-0.8,3.3-1.2,5.1l-0.3,2.1c-0.3,2.5-0.3,4.5,0,6.2l0.1,1.1l0.3-0.8l0.1-0.3
	c0.4-1.6,0.8-3.7,1-6.2C146.1,215.2,146.1,213.2,146,211.5L146,211.5z M145,373.9l-0.1-0.1l-0.4-1.4c-0.1,0.4-0.3,1-0.3,1.5
	c-0.1,1.6-0.1,3.7,0,6.2c0.3,2.5,0.6,4.5,1.1,6.2c0.1,0.7,0.4,1.2,0.6,1.6l0.3-1.6c0.3-1.6,0.3-3.7,0-6.2
	C145.8,377.7,145.4,375.6,145,373.9L145,373.9z M150.5,455.6l1.9,0.7c0.8,0.3,1.5,0.3,2.3,0.3c0.7,0,1.1-0.1,1.5-0.3l0.3-0.3
	l-0.1-0.3c-0.1-0.3-0.4-0.5-1-0.8c-0.8-0.5-1.8-1-3-1.4c-1.2-0.4-2.3-0.7-3.3-0.8c-1-0.1-1.5,0-1.5,0.3c-0.1,0.4,0.1,1,1,1.5
	C149,454.9,149.7,455.3,150.5,455.6L150.5,455.6z"
          />
          <path
            fill="none"
            d="M147.5,289.2c0-4.1,0.1-8.3,0.3-12.2 M145.5,289.2c0-4.1,0.1-8.3,0.3-12.4 M146.5,289.2c0-4.1,0.1-8.3,0.3-12.4
	 M144.4,289.2c0-4.1,0.1-8.3,0.3-12.4 M146.6,337.4l-0.6-12.4 M147.7,337.4l-0.7-12.4 M145.5,337.4c-0.3-4.1-0.4-8.3-0.7-12.4
	 M148.6,337.4c-0.3-4.1-0.4-8.3-0.7-12.2 M149.5,336.7l-0.6-11.3 M150.6,333.8l-0.4-6.9 M149.5,303.4v-8.9 M148.4,304v-10.2
	 M145.5,304.1v-10.3 M146.5,304.1v-10.3 M147.5,304.1v-10.3 M144.4,304.1v-10.3 M149.9,318.8l-0.3-8.7 M148.6,308.9
	c0,3.9,0.1,7.7,0.3,11.6 M145.5,308.5c0,4.1,0.1,8.3,0.3,12.4 M146.9,320.9c-0.1-4.1-0.3-8.3-0.3-12.4 M147.5,308.6l0.4,12.2
	 M144.4,308.5c0,4.1,0.1,8.3,0.3,12.4 M152.4,307.1l0.4,0.1l0.1,0.1c0.1,0.3,0.3,0.5,0.3,1v0.3l-0.3,0.5l-0.3,0.1l-0.7-0.1l-0.3-0.1
	c-0.1-0.1-0.3-0.4-0.3-0.8v-0.4l0.3-0.5l0.3-0.1L152.4,307.1L152.4,307.1z"
          />
          <path
            fill="none"
            d="M151,307.8c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.5,0.1,1,0.4,1.4c0.1,0.3,0.3,0.4,0.6,0.5l1,0.1l0.6-0.3
	c0.3-0.3,0.4-0.5,0.6-1.1c0.1-0.4,0.1-1,0-1.5l-0.1-0.7l-0.1-0.3v-0.1h0.3v-1.9h-3.9v1.9h1.1v0.3L151,307.8L151,307.8z M149.7,287.7
	l0.3-8.8 M153.4,301.9v0.5c0,0.3-0.1,0.6-0.3,0.7l-0.1,0.1v-1.9h0.1l0.1,0.1C153.2,301.5,153.2,301.6,153.4,301.9L153.4,301.9z"
          />
          <path
            fill="none"
            d="M153.8,300.7c-0.1-0.4-0.3-0.7-0.6-1c-0.3-0.3-0.6-0.4-1-0.4c-0.1,0-0.3,0-0.4,0.1c-0.4,0.3-0.7,0.7-0.8,1.5
	c-0.1,0.3-0.1,0.7-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1.1l0.3,0.3h0.1v-1.9c-0.1-0.1-0.1-0.3-0.1-0.5v-0.4
	c0-0.4,0.1-0.5,0.3-0.7c0.1-0.1,0.1-0.1,0.3-0.1v3.7h0.4l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.5c0.1-0.4,0.1-0.8,0.1-1.2
	S153.9,300.9,153.8,300.7L153.8,300.7z M153.8,294.6c-0.1-0.4-0.3-0.7-0.6-0.8c-0.1-0.1-0.4-0.3-0.7-0.3s-0.6,0-0.7,0.1
	c-0.4,0.3-0.7,0.8-0.8,1.5l-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1l0.3,0.4h0.1v-1.8c-0.1-0.1-0.1-0.3-0.1-0.5v-0.5
	c0-0.3,0.1-0.4,0.3-0.5l0.1-0.1h0.1v3.7l0.4-0.1l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.6L153.8,294.6L153.8,294.6L153.8,294.6z"
          />
          <path
            fill="none"
            d="M153,295.3l0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.5v0.5c0,0.3-0.1,0.5-0.3,0.7H153V295.3L153,295.3z M153,286.9h-0.6
	c-0.1,0-0.3,0-0.4,0.1c-0.3,0.3-0.6,0.7-0.7,1.2c-0.1,0.3-0.1,0.5-0.1,1l0.1,2.1c0.1,0.7,0.3,1.2,0.7,1.5c0.1,0.1,0.4,0.3,0.7,0.3
	h2.8v-1.9h-2.9c-0.1-0.1-0.3-0.3-0.4-0.5v-0.8c0-0.4,0.1-0.7,0.3-0.8l0.3-0.1h0.6L153,286.9L153,286.9z M150.6,264.1l-0.4,6.9
	 M149,272.3c0.1-3.7,0.4-7.6,0.6-11.1 M147.1,272.9c0.1-4.1,0.4-8.3,0.7-12.4 M148.7,260.5c-0.3,4-0.6,8.1-0.7,12.2 M145,272.9
	c0.1-4.1,0.4-8.3,0.7-12.4 M146.8,260.5c-0.3,4.1-0.6,8.3-0.7,12.4 M148.6,288.8c0.1-3.9,0.1-7.7,0.3-11.6 M695.1,172.5h-4.7
	 M696.8,184.6v-9.8c0-1.2-0.5-2.1-1.7-2.2 M663.1,142.5c13.3,1.9,27.2,4.8,41.5,8.9c7,2.9,11.3,8.9,12.7,18.2l2.5,15 M720.3,209.5
	h1.8c0.7,0,1-0.3,1-1.1v-22.8c0-0.7-0.3-1-1-1h-2.2 M691.4,196h3.7 M693.2,207.4l0.5-0.3v-6.7c0.1-2.8-0.7-4.3-2.3-4.5 M691.4,196
	h-1 M686.6,174.2c2.5,3,3.9,7.4,4,13.3v8.4 M695.1,196c1.1,0,1.7-0.6,1.7-1.5v-9.9 M693.2,209.5v-2.1 M690.4,208.6l-1,0.4
	 M693.2,207.4l-2.8,1.2 M690.4,209.5v-0.8 M693.2,209.5h-2.8 M689.2,209.2l0.3-0.1 M718.8,237.1V215c0-3.3,0.5-5.1,1.5-5.5
	 M719.7,184.6h-23 M720.3,209.5h-27.1 M690.4,196v12.7 M689.5,209.1v-8.9c0.1-2.3-0.4-3.3-1.8-3c-2.3,0.6-4,1.5-5.2,2.8
	 M718.8,361.7h6.9c6.5,0.1,12-0.3,16.8-1.1c4-1.2,6.2-3.7,6.5-7.6V245.8c-0.4-3.7-2.5-6.2-6.5-7.6l-1.5-0.3c-4.4-0.7-9.5-1-15.3-0.8
	h-6.9 M700.3,244.8l-0.1,1v0.4 M718.8,237.1c-4.5,0.1-8.5,0.4-12.1,1.1c-1.2,0.4-2.3,1-3.2,1.6c-0.8,0.6-1.5,1.2-2.1,2.1l-0.1,0.1
	c-0.5,0.8-0.8,1.6-1.1,2.8 M700.2,246.2l0.1-1.4 M700.2,254.9l-6.6-0.3 M690.4,267l3.3-2.1l-0.1-10.3 M700.2,246.2v8.7 M693.6,254.6
	l-0.4-45.1 M690.4,267v-57.5 M695.1,172.5V196 M152.4,141.6l-1,0.3c-1.2,0.4-2.2,1-3,1.5c-0.8,0.6-1.1,1.1-1,1.5
	c0.1,0.3,0.6,0.3,1.5,0.3c1-0.1,2.1-0.3,3.3-0.8c1.2-0.4,2.2-0.8,3-1.4c0.8-0.6,1.1-1,1.1-1.2l-0.3-0.3"
          />
          <path
            fill="none"
            d="M156.1,141.6c-0.3-0.3-0.8-0.3-1.5-0.3s-1.5,0.1-2.3,0.3 M149.9,141.6h-9.2c-6.7,2.1-11.1,4.7-13.3,7.8
	 M149.9,141.6h2.5 M147.5,201.8c-9.9-2.5-14.7-5.6-14.3-9.4c0.4-4.8,0.8-9.5,1.4-13.9c1.2-10.7,2.8-20.2,4.7-28.3
	c1-3.4,4.5-6.2,10.6-8.4"
          />
          <path
            fill="none"
            d="M267.7,141.6c-0.8-2.9-3.9-4.3-9.2-4.3h-97.3c-6.3,0-10,1.5-11.3,4.3 M124.1,149.3h-2.9
	c-2.9-0.1-4.8,1.1-5.8,3.4c-3.2,7.2-6.2,15.7-9.1,25.6l-2.5,8.9c-0.6,1.8-1.5,3.2-3,4.1l-2.6,1.9c-1.2,1-2.1,3.4-2.3,7.3l-2.6,28.8
	c0.1,1.2,1.1,2.8,3,4.3l1,0.7 M127.4,149.3h-3.3"
          />
          <path
            fill="none"
            d="M124.1,149.3c-1.9,0.1-3,1-3.3,2.6l-12.9,37.3 M127.9,194.5v-3.7l-0.8-2.2l5.5-34.8c0.7-2.9,0.1-4.4-1.8-4.3
	h-3.3 M129.4,197.4l-1.5-2.1v-0.8 M130.8,202.4l0.1-3l-1.5-2.1 M107.8,189.4l-0.8,1.5l-1.5,2.1l-1.4,1.5l-0.1,0.4 M104,194.9
	c-0.7,1.2-1.1,3.2-1.2,5.8l-2.6,28.8c0.1,1.2,1.1,2.9,3,5 M107.8,189.4l19-0.4 M104,194.9l23.9-0.4 M126.1,234.5l3.9-4.4l0.8-27.6
	 M126,234.6l0.1-0.1"
          />
          <path
            fill="none"
            d="M129.4,197.4l-1,32.7l-2.3,4.4 M123.9,234.5l2.1,0.1 M126.1,234.5h-2.2 M104.3,234.4h-0.1 M107.6,234.4h-3.4
	 M104.3,234.4h3.3 M103.2,234.4h1.1 M104.1,234.4h0.1 M97.7,234.6l-0.4-0.3 M103.2,234.4h-5.9 M97.3,234.4c0.8,1.1,1.2,4,1.1,8.7
	v111.8c0.1,4.7-0.1,7.6-1.1,8.7 M107.6,234.4l16.4,0.1 M177.7,193.8l-0.1-0.1l-0.1-0.3v-0.1 M177.5,193.2l-0.1-0.7
	c-0.1-0.3-0.3-0.4-0.6-0.4l-1.9,0.6c-0.3,0.1-0.4,0.3-0.3,0.6l0.3,1.1 M174.3,193.9v-0.1l-0.1-0.7c-0.1-0.3-0.3-0.4-0.6-0.3
	l-1.2,0.3c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.7"
          />
          <path
            fill="none"
            d="M177.5,193.2H177l-2.1,0.6c-0.3,0.1-0.4,0.3-0.4,0.7 M179.7,192.1l-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3
	c-0.3,0.1-0.4,0.3-0.3,0.6l0.4,1.6 M180.6,194.5l-0.6-1.9c0-0.1-0.1-0.3-0.3-0.3h-0.1"
          />
          <path
            fill="none"
            d="M179.7,192.1h-0.3l-1.2,0.4c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.6 M180.6,194.5l0.3,1v0.4 M180.9,195.8l-0.4,0.3
	l-1.2,0.3c-0.3,0.1-0.6,0-0.6-0.4l-0.4-1.4 M178.3,194.7l-0.3-1 M177.9,193.8h-0.1 M178,193.8h-0.1 M178,194.7l0.1,0.7l0.4,2.9
	 M177.7,193.8l0.3,1 M178.3,194.7H178"
          />
          <path
            fill="none"
            d="M178.6,198.3l1.6-0.4c0.3-0.1,0.6,0,0.6,0.4l0.1,0.6 M181,198.7l0.4-0.6l-0.6-2.3 M174.3,193.9l-0.1-0.1h-0.4
	l-1.2,0.3c-0.1,0-0.3,0.1-0.3,0.3 M174.4,194.6l-0.1-0.7 M174.6,194.5l-0.1,0.1 M176.5,198.9l-1.4-2.6l-0.3-1.1 M174.8,195.2h-0.3
	 M174.8,195.2l-0.3-0.7 M174.6,195.2l-0.1-0.6 M173.1,198c-0.1-0.1-0.1-0.1-0.3-0.4l-0.6-2.2 M174.6,195.2l0.6,1.9
	c0.1,0.3,0,0.6-0.4,0.7l-1.2,0.4h-0.4"
          />
          <path
            fill="none"
            d="M174.7,200.7l-0.3-0.8c-0.1-0.4,0-0.6,0.4-0.7l1.6-0.4 M174.7,200.7l-1-0.4l-0.7-2.2 M175,201.5l-0.3-0.8
	 M175.3,202.7l-0.4,0.1h-0.4l-0.3-0.3l-0.1-0.3c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.3,0.3-0.3l0.3-0.1 M181.6,200.9
	c0,0.3-0.1,0.4-0.4,0.4l-5.4,1.5c-0.3,0.1-0.4,0-0.6-0.1"
          />
          <path
            fill="none"
            d="M175.3,202.7l-0.1-0.3l-0.1-1 M181.2,199.7l0.3,1.1l0.1,0.1"
          />
          <path
            fill="none"
            d="M181.2,199.7l0.3-0.1h0.4l0.3,0.3l0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.4,0.3l-0.3,0.1 M178.6,198.3l-2.1,0.6
	 M181,198.7l0.1,1 M172.2,195.6l-15.7,2.1c-2.5,0.7-4.7,1.5-6.5,2.5 M172.1,194.3l-0.1,0.1v0.3l0.3,0.8 M150.1,200.1
	c-1,0.4-1.8,1-2.6,1.6 M147.5,201.8c-0.6,0.4-1.1,0.8-1.6,1.4c-3.2,2.9-5.5,3.6-7.3,2.3l-7.7-2.9 M154.2,207.3
	c-0.4-2.9-1.2-4.4-2.2-4.4s-2.1,1.6-3.2,4.8l-0.1,0.4c-1.1,3.4-1.9,7.6-2.3,12.5c-0.4,4.8-0.4,8.9,0,12.4c0.3,1.8,0.6,3,1,3.9
	s0.8,1.2,1.4,1.2c1.1,0,2.2-1.6,3.3-5.2l0.3-0.8l0.8-3.3 M144.6,225l-0.1-1.1c-0.3-1.6-0.3-3.7,0-6.2l0.3-2.1
	c0.1-1.5,0.4-2.9,0.8-4.1l0.3-1v-0.1l-0.3-0.8c-0.1-0.4-0.4-0.7-0.6-0.7c-0.6,0-1,0.8-1.5,2.6c-0.4,1.6-0.8,3.7-1,6.2
	c-0.3,2.5-0.3,4.5,0,6.2c0.1,1.1,0.3,1.8,0.6,2.2c0.1,0.3,0.3,0.4,0.6,0.4S144.2,226,144.6,225L144.6,225z M144,208.8
	c1-3.3,2.2-5.6,3.4-7 M144.6,225l0.3-0.8l0.1-0.3c0.4-1.6,0.8-3.7,1-6.2c0.3-2.5,0.3-4.5,0-6.2l-0.1-1 M142.2,208.8h-4.7
	c-0.8,4-1.5,9.4-2.5,16.1l-0.8,7.3c-0.3,3.2-0.6,6.6-0.8,10.2l-0.8,12.9c-0.7,13.2-1.1,26.7-1.2,40.6v6.3
	c0.1,12.8,0.6,25.2,1.1,37.4l1,16c0.3,3.7,0.6,7,0.8,10.2c0.3,2.6,0.6,5.1,0.8,7.4c0.8,6.6,1.6,12,2.5,16.1h4.7"
          />
          <path
            fill="none"
            d="M154.2,207.3c-0.1,0.4-0.3,0.8-0.6,1.2l-0.3,0.8c-0.7,2.2-1.1,4.8-1.4,7.8c-0.3,3.3-0.3,6.1,0,8.4v0.3
	c0.1,1,0.3,1.8,0.6,2.3l0.4,0.6 M144,389.1c-1.2-4-2.3-9.4-3.2-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.7-6.6-1-10.2
	c-0.3-4.3-0.6-8.5-0.8-12.9l-0.1-3c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.2,1.2-40.6c0.3-4.4,0.4-8.7,0.8-12.9
	c0.3-3.7,0.6-7,1-10.2c0.3-2.6,0.6-5.1,0.8-7.3c0.8-6.6,1.9-12,3.2-16.1 M144,208.8h-1.8"
          />
          <path
            fill="none"
            d="M142.2,389.1c-1-4-1.9-9.4-2.8-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.6-6.6-0.8-10.2l-1.1-15.8
	c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.4,1.2-40.6l0.8-12.9c0.3-3.7,0.6-7,0.8-10.2c0.3-2.6,0.6-5.1,0.8-7.3
	c0.8-6.6,1.8-12,2.8-16.1 M153.1,228.9c0.6-2.5,1-5.2,1.2-8.4c0.4-4.8,0.4-8.9,0-12.5l-0.1-0.7"
          />
          <path
            fill="none"
            d="M150.1,200.1c2.5-1,4.3-0.8,5.4,0.4c1.2,3.6,1.9,12.1,2.1,25.6c0.4,16,0.6,31.2,0.6,45.9v67.4
	c0,10.5-0.3,21.2-0.6,32.2c0,13.5-0.7,22-2.1,25.6c-1.1,1.4-2.9,1.5-5.4,0.4"
          />
          <path
            fill="none"
            d="M150.2,255c0.4-0.7,0.7-1.5,0.7-2.8v-3.4c0.1-3-0.7-4.7-2.2-5h-4.4 M150.2,255c-0.6,0.8-1.5,1.4-2.8,1.4h-5.4
	c-0.8-0.1-1.4-0.6-1.5-1.5l0.6-9.8c0.3-1,1.1-1.4,2.2-1.2h1 M146.8,260.5h1.5l0.3,0.1c0.4,0.1,0.7,0.3,1,0.7c0.6,0.5,0.8,1.5,1,2.9
	v4.7c0,1-0.1,1.6-0.4,2.2c-0.3,0.5-0.7,1.1-1.2,1.4l-1,0.4l-0.8,0.1h-5.2c-1,0-1.7-0.5-1.8-1.5l0.4-9.1c0.1-1,0.7-1.5,1.6-1.6h3.6
	L146.8,260.5L146.8,260.5z"
          />
          <path
            fill="none"
            d="M144.3,243.9V255h5.9 M146.9,276.9h-5.4c-0.8,0-1.5,0.5-1.9,1.5l-0.1,9.5c0.3,0.8,0.8,1.2,1.8,1.4h6.1
	c0.4-0.1,0.7-0.1,1-0.4c0.6-0.3,0.8-0.7,1.1-1.2c0.3-0.5,0.4-1.1,0.6-1.9v-4c0-1.2,0-2.2-0.3-2.9c-0.3-0.8-0.6-1.4-1.1-1.6l-0.8-0.3
	L146.9,276.9L146.9,276.9z M143.9,272.9c0.1-4.1,0.4-8.3,0.7-12.4 M317.2,162.1l-1-3.7c-0.8-2.9-1.7-5.5-2.5-7.7
	c-1.4-3.6-4.4-5.8-9.2-6.6c-4.8-1.7-9.1-2.5-12.7-2.5h-24.1 M317.2,162.1c1,3.9,1.9,8.3,3,13.2 M320.1,175.3l-13.3,1.9 M382.8,127.1
	c-1.2,0.1-2.6,0.6-4.1,1.2h-0.1l-0.1,0.1l-1.9,1c-0.7,0.3-1.2,1-1.8,1.9l-7.2,20.6c-0.5,1.1-0.5,2.2,0.1,3.2 M353.5,168.5h-2.6
	c-0.3,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1l0.3,0.3"
          />
          <path
            fill="none"
            d="M350.4,170.9l-2.8,0.1l-1.1,0.1 M345.1,171.5c-0.1,0.1-0.3,0.3-0.3,0.4s0.1,0.3,0.4,0.3l0.8,0.1l0.8-0.1
	l0.3-0.3v-0.1l-0.3-0.4l-0.1-0.1h-0.1 M346.5,173.4c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1c0.3-0.1,0.3-0.1,0.3-0.3
	c0-0.3-0.1-0.4-0.3-0.6c-0.3-0.1-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3C346.7,173,346.5,173.1,346.5,173.4L346.5,173.4z"
          />
          <path
            fill="none"
            d="M346.5,171.2l-0.1-0.1h-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1 M345.1,171.5l-7.2,1.1 M382,160.2l-0.8,2.6
	c-0.1,1-0.7,1.2-1.5,1l-8.3-3.9l-0.4-0.6 M365.6,159.7l-0.4,1.1 M365.2,160.8l-3.3,7.6 M353.5,168.5c0.4,0,0.7,0.1,1,0.4
	c0.3,0.3,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1l-0.3,0.3 M353.5,168.5c-0.3,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,0.8
	c0,0.4,0.1,0.7,0.4,1l0.3,0.3 M361.2,169.8l-1.5,1c-0.4,0.3-0.7,0.7-1,1 M358.8,171.8l-0.3,0.3l16.1-0.6 M358.9,170.8
	c-0.1,0.3-0.1,0.7-0.1,1 M359,174.8l-0.3-0.4l-0.1-0.1l-0.4-0.1h-0.3c-0.3,0-0.5,0.1-0.7,0.3 M357.1,174.4c-0.3,0.1-0.4,0.3-0.4,0.6
	c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1l0.3-0.3v-0.1 M359,174.8l1.2,0.3"
          />
          <path
            fill="none"
            d="M353,170.9l0.5,0.1l0.7-0.1 M353,170.9h-1.4 M354.1,173.7c0.3,0.1,0.4,0.4,0.4,0.6l-0.3,0.3l-0.8,0.1l-0.8-0.1
	c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.3,0.1-0.4,0.3-0.6c0.3-0.1,0.5-0.3,0.8-0.3h0.3"
          />
          <path
            fill="none"
            d="M353.7,173.4l0.4,0.1v0.1 M354.2,170.9H353 M358.9,170.8l-4.7,0.1 M354.1,173.7l3,0.7 M361.9,168.3
	c-1.6,0.7-2.8,1.5-3,2.5 M361.9,168.3l-0.7,1.5 M373.8,170.5c-1.4-1.4-3.7-2.1-6.9-2.1c-2.3,0.1-4.1,0.6-5.6,1.4 M367.7,155.3
	l-2.1,4.4 M351.6,170.9h-1.2 M351.6,170.9c-1,1-0.3,1.8,2.1,2.5 M370.9,159.5l-3.2-4.3 M365.2,160.8l-48,1.4 M338,172.6l-17.9,2.8
	 M261.8,246.3c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3c0.1-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.3-0.7
	c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3C261.8,245.8,261.8,246.1,261.8,246.3L261.8,246.3z"
          />
          <path
            fill="none"
            d="M258.9,246.3c0,1.1,0.4,1.9,1.1,2.8c0.8,0.7,1.6,1.1,2.8,1.1s1.9-0.4,2.8-1.1c0.8-0.8,1.1-1.6,1.1-2.8
	c0-1.1-0.4-1.9-1.1-2.8c-0.8-0.8-1.6-1.1-2.8-1.1s-1.9,0.4-2.8,1.1C259.3,244.4,258.9,245.2,258.9,246.3L258.9,246.3z"
          />
          <path
            fill="none"
            d="M260.8,248.3c0.5,0.6,1.2,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8s0.8-1.1,0.8-1.9c0-0.8-0.3-1.4-0.8-1.9
	s-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8s-0.8,1.1-0.8,1.9C260.1,247,260.4,247.7,260.8,248.3L260.8,248.3z M343.5,183.9l0.1-0.7
	l-0.1-0.8c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.7c0.3,0.3,0.5,0.3,0.8,0.3
	C343.2,184.1,343.4,184.1,343.5,183.9z M343.5,193c0.3,0.3,0.5,0.3,0.8,0.3c0.1,0,0.3-0.1,0.4-0.3l0.1-0.7l-0.1-0.8l-0.4-0.3
	c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.6-0.3,0.8S343.2,192.8,343.5,193L343.5,193z M346.4,199h-2.6 M353,184.1l-5.4-1.1
	c-1.1,0.7-1.5,5.4-1.2,14v2.1 M340.6,199l-0.1-12.9c-0.1-7.4-1-11.8-2.5-13.6 M343.8,199h-3.2 M306.8,177.3l1.1,9.8
	c0.8,29.6,1.2,57.1,1.1,82.5v69.9c0,22.3-0.4,46.1-1.1,71.3l-1.1,9.8 M343.8,199l0.7,61.6l-0.7,138.1 M306.8,177.3l-49.5,7
	l-76.6,10.2 M360.3,175.1c-3.6,1.1-5.9,4-7.3,8.9 M267.7,141.6H156.1 M454.2,168.7c0.3-1.5,0.8-2.9,1.5-3.9
	c7.2-0.6,16.2-0.7,27.2-0.1"
          />
          <path fill="none" d="M461.7,168.6l-0.3,0.7h-7.3l0.1-0.6" />
          <path
            fill="none"
            d="M482.9,168.1c-7.4-0.3-14.4-0.1-21,0.4l-0.1,0.1 M485,164.3h-1.7c-0.3,0-0.4,0.1-0.5,0.4 M482.9,168.2v-0.1"
          />
          <path
            fill="none"
            d="M485,168.6h-1.7c-0.3,0-0.5-0.1-0.5-0.6 M482.9,164.8h1.4v3.3h-1.4 M485,164.3v4.3 M490.4,168.1v-3.2
	c0-0.3-0.1-0.6-0.5-0.6h-4.8"
          />
          <path
            fill="none"
            d="M485,168.6h4.8c0.3,0,0.5-0.1,0.5-0.6v-0.1 M375.3,175.1l-5.9-1.9l109.4-1.8c3.4,0.1,5.5,1.1,5.9,3l0.5,2.5
	c0.7,1.6,0.1,2.6-1.5,3"
          />
          <path
            fill="none"
            d="M483.7,179.7l-0.5-2.6c0.4-1.4-0.4-2.1-2.2-2.1H375.3 M461.7,168.6l21.2-0.4 M494.3,156.9l3.3-8.8
	c1.4-3.3,5.8-5.6,12.9-7.2c3.2-1.1,7-1.4,11.4-0.8h7.8"
          />
          <path
            fill="none"
            d="M491.8,167.9c-0.7-0.3-0.5-2.3,0.6-5.9l1.9-5.1 M497.2,167.9h-5.4 M497.2,167.9v1.6 M516.6,179.7
	c43.2-0.6,85.8-0.4,127.8,0.6c1.7,0,2.8-0.8,3.4-2.3l3.7-4.7c1.4-1.4,0.7-2.1-1.8-2.1l-27.9-0.6h-89.7l-15.1,0.6
	c-2.5,0.6-3.7,1.5-3.7,2.6v4.1C513.2,178.9,514.3,179.5,516.6,179.7L516.6,179.7z M671.6,168.9h-0.3l-15-0.8l-34.5-0.7H530
	c-11.1,0-22.1,0.1-32.9,0.7"
          />
          <path
            fill="none"
            d="M497.2,169.6c7.4-0.3,14.9-0.6,22.4-0.6h102.2l35.8,0.7l13.6,0.8h0.3c1.8,0.3,3.3,0.6,4.7,0.8l-9.8,10.7
	 M491.8,167.9l-1.4,0.1 M382,160.2l9.9-29.6c0.4-1,0.1-1.5-0.7-1.8l-7.2-1.7h-1.2 M375.1,163l0.1-0.8 M374.2,170.4l-0.4,0.1
	 M374.3,169.4l-0.1,1 M374.6,171.5l1.2-0.1c0-0.4-0.1-0.7-0.3-1 M375.5,170.4c-0.3-0.4-0.7-0.8-1.2-1 M374.2,170.4l-0.1,0.4
	 M373.8,170.5l0.3,0.3 M374,170.8l0.5,0.7 M375.3,175.1l8.4,2.8l7.3,2.1c1.9,0.7,4,1.2,5.9,1.4h0.3l86.5-1.5 M375.1,163l-0.8,6.5
	 M391.8,184.3c0.8-1,2.1-1.4,3.7-1.5l102.3-1.6 M353,413.7l24.5-5.8c2.8-0.5,4.1-3.3,4.1-8.4V198.2c0-5.1-1.4-7.8-4.1-8.4l-24.5-5.6
	 M391.8,184.3c-0.7,0.8-1.1,1.9-1.1,3.4c-0.4,11.6-0.5,24.1-0.5,37.6v147.1c-0.1,13.5,0.1,26,0.5,37.6c0,1.5,0.4,2.6,1.1,3.4"
          />
          <path
            fill="none"
            d="M360.3,175.1l28.9,6.9c1.5,0.3,2.3,1.1,2.6,2.3 M500.2,181.3h-2.3 M500.2,206.6v6.6 M500.2,181.3v25.3
	 M500.2,241.8v6.5 M500.2,248.3v29 M500.2,213.2v28.6 M494.3,156.9L382,160.2 M497.8,181.3l-0.7-11.7 M454.2,168.7l-78.7,1.6
	 M644.5,140.1c-0.5-1.9-3-2.9-7.8-2.9h-94.9c-6.5-0.3-10.6,0.7-12.2,2.9 M674.9,165.9l-4.1-14.9c-1-3.6-2.2-5.8-3.7-6.9
	c-0.3-0.1-0.4-0.3-0.7-0.4l-3.3-1.2 M644.5,140.1h8.5c2.6,0.1,5.9,0.8,10,2.3 M674.9,165.9c0.3,1.5-1,2.5-3.4,3 M671.6,168.9
	c6.1,0.7,10.3,1.8,12.8,3.3c0.8,0.6,1.5,1.2,2.2,2.1 M682.3,199.8c-0.5,0.6-1,1.1-1.2,1.6l-0.3,0.8c-0.1,1.1-0.3,2.1-0.4,3.2v5.8
	 M687.3,211.4l-0.3-10.3c-0.4-2.1-1.9-2.5-4.7-1.1 M666.5,182.1h-1.4c-53.9-1.6-108.8-1.9-164.9-0.8 M665.3,205.1v-9.9
	c-0.1-7.8,0.3-12.1,1.2-13.1 M680.4,227.1h-1.7v0.8 M680.4,211.4v15.7 M680.4,228.3c-0.4-0.3-1-0.4-1.7-0.4 M680.4,227.1v1.2
	 M680.4,211.4h6.9 M666.5,415.8c6.3-2.3,9.6-8.1,10-17.2V199.3c-0.4-9.1-3.7-14.7-10-17.2 M665.3,211.9v-6.9 M678.7,237v0.8h1.7
	 M680.4,236.6c0.7-0.3,1.1-0.7,1.1-1.4V230c-0.1-0.8-0.5-1.2-1.1-1.7 M680.4,237.8v-1.2 M678.7,237c0.7-0.1,1.2-0.1,1.7-0.4
	 M665.3,248.3v-6.5 M665.3,277.3v-29 M678.7,227.9v9.1 M665.3,241.8v-29.9 M529.6,140.1h114.9 M665.3,277.3H500.2 M665.3,248.3
	H500.2 M665.3,241.8H500.2 M665.3,211.9l-165.1,1.2 M665.3,205.1l-165.1,1.5 M382.8,127.1l-12,32.5 M365.6,159.7l9.5,3.3
	 M665.3,320.5V314 M665.3,314v-30.1 M680.4,361.5v-1.2 M680.4,369.7c0.7-0.3,1.1-0.7,1.1-1.4v-5.2c-0.1-0.8-0.5-1.4-1.1-1.6
	 M680.4,360.2h-1.7v1 M678.7,370.1v0.8h1.7 M680.4,371v-1.2 M678.7,361.2v8.9 M678.7,361.2c0.7,0,1.2,0.1,1.7,0.4 M678.7,370.1
	c0.7-0.1,1.2-0.1,1.7-0.4 M665.3,356.1v-6.6 M665.3,349.5v-29 M680.4,386.5v5.8c0.1,1.1,0.3,2.2,0.4,3.2l0.3,0.8
	c0.3,0.5,0.7,1.1,1.2,1.6 M687.3,386.5h-6.9 M682.3,398.1c2.8,1.2,4.3,0.8,4.7-1.1l0.3-10.3 M665.3,392.8v-7 M666.5,415.8
	c-0.8-1-1.2-5.2-1.2-13.1v-9.9 M500.2,416.6c56.1,1.1,111,0.8,164.9-0.8h1.4"
          />
          <path
            fill="none"
            d="M682.3,398.1c1.2,1.2,3,2.2,5.2,2.8c1.4,0.3,1.9-0.7,1.8-3v-8.9 M647.8,419.9l3.7,4.7c1.4,1.4,0.7,2.1-1.8,2.2
	l-27.8,0.5h-92.4l-12.5-0.5c-2.5-0.5-3.7-1.5-3.7-2.6v-4.1c0-1.1,1.1-1.8,3.3-1.9c43.2,0.5,85.8,0.4,127.8-0.5
	C646,417.6,647.1,418.3,647.8,419.9L647.8,419.9z M529.6,457.6c1.7,2.2,5.8,3.2,12.2,2.9h94.9c4.7,0,7.3-1,7.8-2.9"
          />
          <path
            fill="none"
            d="M497.2,428.2c7.4,0.3,14.9,0.5,22.4,0.5h102.2l35.8-0.7l13.6-0.8h0.3c1.8-0.3,3.3-0.5,4.7-0.8l-9.8-10.6
	 M671.6,428.9l-0.3,0.1l-15,0.8l-34.5,0.7H530c-11.1,0-22.1-0.1-32.9-0.7 M686.6,423.5c-0.7,0.8-1.4,1.5-2.2,2.1
	c-2.5,1.5-6.7,2.6-12.8,3.3 M671.6,428.9c2.5,0.5,3.6,1.6,3.4,3 M674.9,431.9l-4.1,14.9c-1,3.6-2.2,5.8-3.7,6.9l-0.8,0.4l-3.3,1.2
	 M644.5,457.6h8.5c2.6-0.1,5.9-0.8,10-2.3 M680.4,371v15.5 M665.3,385.8v-29.7 M500.2,314v6.5 M500.2,283.9V314 M500.2,349.5v6.6
	 M500.2,320.5v29 M483.7,418.1l-86.5-1.5h-0.3c-1.9,0.1-4,0.5-5.9,1.4l-7.3,2.1l-8.4,2.8 M497.8,416.5l-102.3-1.6
	c-1.6-0.1-3-0.5-3.7-1.5 M391.8,413.5c-0.1,1.2-1,2.1-2.6,2.3l-28.9,6.9 M374.2,427.4l-0.1-0.3 M374.3,428.3l-0.1-1 M375.5,427.4
	c-0.3,0.4-0.7,0.8-1.2,1 M374.6,426.3l1.2,0.1c0,0.4-0.1,0.7-0.3,1 M374,427.1l0.5-0.8 M373.8,427.4l0.3-0.3 M374.2,427.4h-0.4
	 M375.1,434.9l0.1,0.8 M382,437.5l-0.8-2.6c-0.1-1-0.7-1.2-1.5-1l-8.3,3.7l-0.4,0.5 M375.1,434.9l-0.8-6.6 M382.8,470.7h1.2l7.2-1.6
	c0.8-0.3,1.1-0.8,0.7-1.8l-9.9-29.6"
          />
          <path
            fill="none"
            d="M367.7,442.6c-0.7,1-0.7,2.1-0.1,3.2l7.2,20.6c0.4,1,1.1,1.6,1.8,1.9l1.9,1l0.1,0.1h0.1
	c1.5,0.8,2.9,1.2,4.1,1.2 M500.2,384.7v6.6 M500.2,416.6l-2.3-0.1 M500.2,391.3v25.3 M375.3,422.8l-5.9,1.9l109.4,1.8
	c3.4-0.1,5.5-1.1,5.9-3l0.5-2.5c0.7-1.6,0.1-2.8-1.5-3"
          />
          <path
            fill="none"
            d="M483.7,418.1l-0.5,2.6c0.4,1.4-0.4,2.1-2.2,2.2H375.3 M485,429.2h4.8c0.3,0,0.5,0.1,0.5,0.5v0.1 M482.9,429.8
	h1.4v3.2l-1.4,0.1 M482.9,429.7c0-0.3,0.1-0.5,0.5-0.5h1.7 M482.9,429.8v-0.1"
          />
          <path
            fill="none"
            d="M485,433.4h-1.7c-0.3,0-0.4-0.1-0.5-0.4 M490.4,429.8v3.2c0,0.3-0.1,0.5-0.5,0.5h-4.8 M485,433.4v-4.3
	 M461.7,429.2l0.1,0.1c6.6,0.5,13.6,0.7,21,0.4 M454.2,429l-0.1-0.4h7.3l0.3,0.6 M482.9,433.1c-11,0.5-20.1,0.4-27.2-0.1
	c-0.7-1.1-1.1-2.3-1.5-3.9 M461.7,429.2l21.2,0.5 M497.2,429.8v-1.6 M497.2,429.8h-5.4 M491.8,429.8c-0.7,0.3-0.5,2.3,0.6,5.9
	l1.9,5.1 M494.3,440.8l3.3,8.8c1.4,3.3,5.8,5.6,12.9,7.2c3.2,1.1,7,1.4,11.4,0.8h7.8 M497.2,428.2l0.7-11.7 M491.8,429.8h-1.4
	 M382,437.5l112.3,3.3 M500.2,356.1v28.6 M375.5,427.4l78.7,1.6 M529.6,457.6h114.9 M665.3,392.8l-165.1-1.5 M665.3,385.8
	l-165.1-1.1 M665.3,356.1H500.2 M665.3,349.5H500.2 M665.3,314H500.2 M665.3,320.5H500.2 M665.3,283.9H500.2 M258.6,291.7v-1.4
	c0-0.1,0-0.3,0.1-0.4s0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.1l0.1,0.4v1.2"
          />
          <path
            fill="none"
            d="M259.6,291.7l0.5-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4
	c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1l0.7,0.4 M258.6,291.7v4.3c-0.1,0.1-0.3,0.3-0.3,0.5v4.8
	c0,0.1,0.1,0.4,0.3,0.5v4.3 M259.9,296.8v-0.3c0-0.3-0.1-0.4-0.3-0.5v-4.3 M259.9,301.1l1.9-0.8v-2.8l-1.9-0.7 M259.6,306.2v-4.3
	c0.1-0.1,0.3-0.3,0.3-0.5v-0.3 M259.9,296.8v4.3"
          />
          <path
            fill="none"
            d="M258.6,306l-0.7,0.4c-0.3,0.3-0.4,0.7-0.4,1.1s0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4
	c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1l-0.5-0.3"
          />
          <path
            fill="none"
            d="M259.6,306.2v1.2l-0.1,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.3-0.1l-0.1-0.3V306 M265.5,348.8
	c0.8,0.8,1.1,1.6,1.1,2.8c0,1.1-0.4,1.9-1.1,2.8c-0.8,0.8-1.6,1.1-2.8,1.1s-1.9-0.4-2.8-1.1c-0.8-0.8-1.1-1.6-1.1-2.8
	c0-1.1,0.4-1.9,1.1-2.8c0.8-0.8,1.6-1.1,2.8-1.1S264.7,348.1,265.5,348.8z"
          />
          <path
            fill="none"
            d="M264.7,349.6c0.5,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8
	c-0.5-0.5-0.8-1.1-0.8-1.9c0-0.7,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8C263.6,348.8,264.1,349.1,264.7,349.6z"
          />
          <path
            fill="none"
            d="M263.6,350.7c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.3
	c-0.1-0.1-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7c0.3-0.3,0.4-0.3,0.7-0.3S263.3,350.6,263.6,350.7L263.6,350.7z M149.9,456.2
	c1.1,2.9,5,4.3,11.3,4.3h97.3c5.4,0,8.4-1.4,9.2-4.3 M267.7,456.2h24.1c3.6,0,7.8-0.8,12.7-2.5c4.8-0.8,7.8-3,9.2-6.6
	c0.8-2.3,1.6-4.8,2.5-7.7l1-3.7 M343.8,398.8h-3.2 M346.4,398.8h-2.6 M343.2,405.6c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.4,0.8-0.4
	c0.1,0,0.3,0.1,0.4,0.4l0.1,0.7l-0.1,0.8l-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3C343.2,406.2,343.2,405.9,343.2,405.6L343.2,405.6z
	 M343.5,415.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.7
	c0.3-0.3,0.5-0.3,0.8-0.3c0.1,0,0.3,0.1,0.4,0.3l0.1,0.7L343.5,415.4L343.5,415.4z M353,413.7l-5.4,1.1c-1.1-0.7-1.5-5.4-1.2-14
	v-2.1 M354.1,424.2c0.3-0.3,0.4-0.4,0.4-0.7c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.1,0.1-0.3,0.1-0.3,0.3
	c0,0.3,0.1,0.4,0.3,0.6c0.3,0.1,0.5,0.3,0.8,0.3h0.3 M359,423c0-0.3-0.1-0.4-0.3-0.4l-0.8-0.1l-0.8,0.1c-0.1,0.1-0.3,0.1-0.3,0.3
	c0,0.3,0.1,0.4,0.3,0.5 M359,423l-0.3,0.4l-0.1,0.1l-0.4,0.1h-0.3c-0.3,0-0.5-0.1-0.7-0.3 M360.3,422.7L359,423 M360.3,422.7
	c-3.6-1.1-5.9-4-7.3-8.9 M357.1,423.4l-1.6,0.4c-0.4,0.1-1,0.3-1.2,0.4 M338,425.3c1.5-1.6,2.3-6.2,2.5-13.6l0.1-12.9 M320.1,422.5
	l-13.3-1.9 M374.6,426.3l-16.1-0.4l0.3,0.3 M358.8,426.1c0,0.3,0,0.7,0.1,1"
          />
          <path
            fill="none"
            d="M361.2,428l-1.5-1c-0.4-0.3-0.7-0.5-1-1 M354.1,424.2l-0.4,0.1 M354.2,427l-0.7-0.1L353,427 M353,427h-1.4"
          />
          <path
            fill="none"
            d="M353.5,429.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1l-0.3-0.3 M353.5,429.4c-0.3,0-0.5-0.1-0.8-0.4
	c-0.3-0.3-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-1c0.1-0.1,0.3-0.1,0.3-0.3 M353,427h1.2 M358.9,427.1l-4.7-0.1 M370.9,438.2l-2.5,3.6
	l-0.7,0.8 M365.2,437.1l0.4,1.1 M365.6,438.2l2.1,4.4 M373.8,427.4c-1.4,1.4-3.7,2.1-6.9,2.1c-2.3-0.1-4.1-0.5-5.6-1.4 M361.9,429.6
	l-0.7-1.5 M365.2,437.1l-3.3-7.6 M361.9,429.6c-1.6-0.7-2.8-1.5-3-2.5 M348.5,425c-0.3,0.1-0.5,0.3-0.8,0.3l-0.8-0.3
	c-0.3-0.1-0.3-0.4-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1l0.8,0.1c0.3,0.1,0.3,0.1,0.3,0.3C348.9,424.6,348.7,424.9,348.5,425
	L348.5,425z M346.5,426.7h0.1l0.1-0.1l0.3-0.4V426c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.3,0.1-0.3,0.1-0.4,0.3
	c0,0.1,0.1,0.3,0.3,0.4 M350.4,426.8h-2.8l-1.1-0.1"
          />
          <path
            fill="none"
            d="M353.5,429.4h-2.6c-0.3,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1s0.1-0.7,0.4-1l0.3-0.3 M346.5,426.7H346
	l-0.7-0.1l-0.1-0.1 M345.1,426.4l-7.2-1.1 M353.7,424.3c-2.2,0.8-2.9,1.7-2.1,2.5 M351.6,427l-1.2-0.1 M317.2,435.8l48,1.4
	 M338,425.3l-17.9-2.8 M317.2,435.8c1-4,1.9-8.4,3-13.2 M340.6,398.8l0.7-59.2L340.6,199 M346.4,199l0.7,140.6l-0.7,59.2
	 M306.8,420.6l-49.5-7.2l-76.6-10 M139.4,302.3c0.3,1.1,1,1.8,2.1,1.8h7c0.4-0.1,0.8-0.3,1.1-0.7c0.7-0.7,1-1.9,1-3.4v-1.9
	c0-1.6-0.4-2.9-1-3.4c-0.3-0.3-0.7-0.5-1.1-0.7h-6.9c-1.1,0-1.8,0.7-2.1,1.8v6.6L139.4,302.3L139.4,302.3z M144.4,308.5h2.2l0.8,0.1
	c0.4,0,0.7,0.1,1.1,0.4c0.6,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.4,1.1,0.4,1.8v4c0,1.1,0,2.1-0.3,2.8c-0.3,0.8-0.6,1.4-1.1,1.6
	c-0.3,0.1-0.6,0.3-1,0.4h-6.2c-0.8,0-1.5-0.5-1.9-1.5l-0.1-9.5c0.3-0.8,0.8-1.2,1.8-1.4H144.4L144.4,308.5z M139.9,326.5l0.4,9.1
	c0.1,1,0.7,1.5,1.6,1.7h6.5c0.4-0.1,0.7-0.3,1-0.7c0.6-0.5,0.8-1.5,1-2.9v-4.8c0-0.8-0.1-1.6-0.4-2.2c-0.3-0.7-0.7-1.1-1.2-1.4
	c-0.3-0.1-0.7-0.3-1-0.4l-0.8-0.1h-5.2C140.7,325,140.2,325.6,139.9,326.5L139.9,326.5z M143.8,320.9c-0.1-4.1-0.3-8.3-0.3-12.4
	 M143.3,293.6v10.5 M144.3,353.9h-1c-1.1,0.3-1.8-0.1-2.1-1.2l-0.6-9.8c0.1-0.8,0.6-1.4,1.5-1.4h5.4c1.4,0,2.3,0.5,3,1.5"
          />
          <path fill="none" d="M150.4,343.2h-6.1v10.7" />
          <path
            fill="none"
            d="M144.3,353.9h4.4c1.5-0.3,2.3-1.9,2.2-5v-3.3c0-1.1-0.1-1.9-0.6-2.5 M154.2,390.5c-0.4,2.9-1.2,4.4-2.2,4.4
	s-2.1-1.6-3.2-4.8l-0.1-0.4c-0.4-1.4-0.8-2.8-1.1-4.4c-0.6-2.5-1-5.1-1.2-8c-0.1-1.6-0.3-3.2-0.3-4.5c-0.1-2.9,0-5.5,0.3-7.8
	c0.3-1.8,0.6-3,1-3.9c0.4-0.8,0.8-1.2,1.4-1.2c1.1,0,2.2,1.6,3.3,5.1l0.3,0.8l0.8,3.3 M144.4,372.3l0.4,1.4l0.1,0.1
	c0.4,1.6,0.8,3.7,1,6.2c0.3,2.5,0.3,4.5,0,6.2l-0.3,1.6 M145.7,387.9c-0.1-0.4-0.4-1-0.6-1.6c-0.4-1.7-0.8-3.7-1.1-6.2
	c-0.1-2.5-0.1-4.5,0-6.2c0.1-0.7,0.1-1.2,0.3-1.5"
          />
          <path
            fill="none"
            d="M145.7,387.9l-0.1,0.3c-0.1,0.4-0.4,0.7-0.6,0.7c-0.6,0-1-0.8-1.5-2.6c-0.4-1.7-0.8-3.7-1-6.2
	c-0.3-2.5-0.3-4.5,0-6.2c0.1-1.1,0.3-1.8,0.6-2.2c0.1-0.3,0.3-0.4,0.6-0.4s0.6,0.3,1,1 M153.1,369l-0.4,0.5
	c-0.3,0.5-0.4,1.2-0.6,2.3v0.3c-0.3,2.3-0.3,5.1,0,8.4c0.3,3,0.8,5.6,1.4,7.8l0.3,0.8l0.6,1.2 M143.9,325c0.1,4.1,0.4,8.3,0.7,12.4
	 M97.3,363.5l-1,0.7c-1.9,1.5-3,2.9-3,4.1l2.6,28.8c0.3,3.9,1.1,6.3,2.3,7.3l2.6,1.9c1.5,1,2.5,2.2,3,4.1
	c3.7,13.8,7.6,25.3,11.7,34.5c1,2.3,2.9,3.6,5.8,3.4h2.9 M104.3,363.4l-0.1,0.1 M104.3,363.4l-1.1,0.1 M107.6,363.5l-3.3-0.1
	 M104.1,363.5h3.4 M104.3,363.4l-0.1,0.1"
          />
          <path
            fill="none"
            d="M104,403c-0.7-1.2-1.1-3.2-1.2-5.8l-2.6-28.8c0.1-1.2,1.1-2.9,3-5 M97.3,363.5l0.4-0.3 M103.2,363.5h-5.9
	 M126.1,363.3H126 M130.8,395.3l-0.8-27.5l-3.9-4.5 M126,363.3h-2.9"
          />
          <path
            fill="none"
            d="M126.1,363.3l2.3,4.5l1,32.6 M123.1,363.3h3 M123.1,363.3l-15.5,0.3 M130.8,395.3l0.1,3l-1.5,2.1 M107.8,408.5
	l-0.8-1.7c-0.4-0.7-1-1.4-1.5-2.1l-1.4-1.5L104,403"
          />
          <path
            fill="none"
            d="M129.4,400.4l-1.5,2.1v1 M127.9,403.4v3.7l-0.8,2.2l5.5,34.8c0.7,2.9,0.1,4.4-1.8,4.3h-3.3 M127.9,403.4
	L104,403 M107.8,408.5l19,0.4 M124.1,448.5h3.3 M124.1,448.5c-1.9-0.1-3-1-3.3-2.6l-12.9-37.3 M181.6,397c0-0.3-0.1-0.4-0.4-0.5
	l-5.4-1.4c-0.3-0.1-0.4,0-0.6,0.1 M175.3,395.2l-0.1,0.1l-0.1,1.1"
          />
          <path
            fill="none"
            d="M175,396.4l-0.3-0.1l-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.3c0-0.1,0.1-0.3,0.3-0.3h0.4l0.4,0.1
	 M174.7,397.1l-1,0.5l-0.7,2.2 M174.7,397.1l-0.3,0.8c-0.1,0.3,0,0.5,0.4,0.7l1.6,0.4 M175,396.4l-0.3,0.7 M181.2,398.2l0.3,0.1h0.4
	l0.3-0.3l0.1-0.3c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.1-0.4-0.3l-0.3-0.1 M178.6,399.6l1.6,0.4c0.3,0.1,0.6,0,0.6-0.4l0.1-0.5
	 M180.9,402l0.6-2.5L181,399 M181,399l0.1-0.8 M181.2,398.2l0.4-1.2 M178.6,399.6l-2.1-0.5 M180.9,402c-0.1-0.1-0.1-0.1-0.4-0.3
	l-1.2-0.4c-0.3-0.1-0.6,0-0.6,0.4l-0.4,1.4 M180.6,403.4l0.3-1.1V402 M178,403l-0.3,1.1 M178.3,403.2l-0.3,1 M178,404.1h-0.1
	 M177.7,404.1h0.1 M178.3,403.2L178,403 M179.7,405.8h-0.3l-1.2-0.3c-0.3-0.1-0.4-0.3-0.3-0.5l0.1-0.5 M179.7,405.8l0.3-0.1l0.1-0.3
	l0.6-1.9"
          />
          <path
            fill="none"
            d="M178,403l0.1-0.5l0.4-2.9 M176.5,399l-1.4,2.3v0.3l-0.3,1 M174.8,402.6h-0.3 M174.6,402.6l0.6-1.9
	c0.1-0.4,0-0.5-0.4-0.7l-1.2-0.4h-0.4 M173.1,399.9l-0.3,0.3l-0.6,2.2 M172.1,403.6l-0.1,0.7c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.3
	c0.3,0.1,0.6,0,0.6-0.3l0.1-0.7V404 M174.6,403.3v0.1 M174.3,403.8l0.1-0.5"
          />
          <path
            fill="none"
            d="M172.1,403.6c0,0.1,0.1,0.1,0.3,0.1l1.2,0.4h0.4l0.1-0.1 M177.5,404.5H177L175,404c-0.3-0.1-0.4-0.3-0.4-0.5
	 M174.4,403.3h0.1"
          />
          <path
            fill="none"
            d="M174.6,403.4l-0.3,1c-0.1,0.3,0,0.5,0.3,0.5l1.9,0.5c0.3,0.1,0.6,0,0.6-0.3l0.1-0.7 M177.5,404.5l0.1-0.3
	l0.1-0.1 M174.6,402.6l-0.1,0.7 M174.8,402.6l-0.3,0.7 M177.9,404.1l-0.4,1.6c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.4c0.3,0.1,0.6,0,0.6-0.4
	l0.1-0.5 M154.2,390.5l0.1-0.7c0.4-3.4,0.4-7.6,0-12.4c-0.3-3-0.7-5.9-1.2-8.4 M147.5,396.1c-1.2-1.4-2.3-3.7-3.4-7 M147.5,396.1
	c-0.6-0.4-1.1-0.8-1.6-1.4c-3.2-2.9-5.5-3.6-7.3-2.3l-7.7,2.9 M150.1,397.8l-2.6-1.6 M142.2,389.1h1.8 M172.2,402.3l-0.3,0.8v0.3
	l0.1,0.1 M150.1,397.8c1.8,1,4,1.8,6.5,2.5l15.7,2.1 M149.9,456.2l-1.8-0.7c-5.1-2.1-8-4.7-8.9-7.7c-2.8-11.6-4.8-25.7-6.2-42.4
	c-0.4-3.7,4.4-6.9,14.3-9.4"
          />
          <path
            fill="none"
            d="M156.1,456.2l0.3-0.3l-0.1-0.3c-0.1-0.3-0.4-0.5-1-0.8c-0.8-0.5-1.8-1-3-1.4c-1.2-0.4-2.3-0.7-3.3-0.8
	c-1-0.1-1.5,0-1.5,0.3c-0.1,0.4,0.1,1,1,1.5c0.6,0.4,1.2,0.8,2.1,1.2l1.9,0.7 M152.4,456.2h-2.5 M127.4,448.5
	c1.9,2.8,5.8,5.1,11.3,7.2l2.1,0.7h9.2"
          />
          <path
            fill="none"
            d="M156.1,456.2c-0.3,0.3-0.8,0.3-1.5,0.3s-1.5-0.1-2.3-0.3 M267.7,456.2H156.1 M365.6,438.2l9.5-3.3 M382.8,470.7
	l-12-32.5 M695.8,289.1h-5.4 M698,288.4h-2.2v0.7 M700.2,284.6c-0.7,0.1-1.5,0.3-2.2,0.5v3.2 M695.8,291.3h-5 M695.8,289.1v2.2
	 M695.8,309.7v-2.2 M698,310.4h-2.2v-0.7 M700.2,314.3l-2.2-0.7v-3.2 M695.8,309.7h-5.4 M695.8,307.5h-5 M710.8,314.1v-0.5
	 M695.8,307.5v-16.2 M698,288.4v22 M710.8,313.6c-3.3,1.2-6.9,1.5-10.6,0.7 M700.2,284.6c3.7-0.8,7.3-0.7,10.6,0.5v28.5
	 M690.4,309.7v-20.6 M700.2,343.3h-4.7c-1.1-0.1-1.7,0.1-2.1,0.7v28.5 M693.6,372.3h-3.2"
          />
          <path
            fill="none"
            d="M718.8,361.7c-4.5-0.1-8.5-0.4-12.1-1.1c-4-1.2-6.1-3.7-6.5-7.6v-9.8 M693.6,372.3h1c0.3,0,0.5,0.1,0.5,0.5v5.2
	c0,0.3-0.1,0.5-0.5,0.5h-1 M690.4,372.3v-62.6 M700.2,314.3v29 M720.3,388.4c-1-0.4-1.5-2.2-1.5-5.5v-21.2 M719.5,413.3h2.5
	c0.7,0,1-0.3,1-1.1v-22.8c0-0.7-0.3-1-1-1h-1.8 M693.6,379.2v-0.5 M693.6,378.7v0.5 M693.6,378.7h-3.2 M689.5,388.8l1,0.4
	 M690.4,389.3l3.3,1.4v6.7c0.1,2.8-0.7,4.3-2.3,4.5 M690.4,388.4v0.8 M692.9,388.4h-2.5 M690.4,378.7v9.8 M693.6,379.2
	c0.1,4.7-0.1,7.7-0.7,9.2 M690.4,401.9v8.4c-0.1,5.9-1.5,10.3-4,13.3 M691.4,401.9h-1 M695.1,401.9c1.1,0,1.7,0.5,1.7,1.5v9.9
	 M696.8,413.3v9.8c0,1.2-0.5,2.1-1.7,2.2 M691.4,401.9h3.7 M690.4,389.3v12.7 M720.3,388.4h-27.4 M696.8,413.3h22.7 M695.1,425.4
	h-4.7 M663.1,455.3c13.3-1.9,27.2-4.8,41.5-8.9c7-2.9,11.3-8.9,12.7-18.2l2.2-14.9 M695.1,425.4v-23.5 M690.4,372.3v6.3
	 M690.4,172.5c-0.7-4.4-5.9-6.6-15.5-6.6 M689.5,209.1l-2.2,2.3 M690.4,289.1V267 M700.2,254.9v29.7 M126,363.3V234.6 M103.2,363.5
	V234.4 M143.3,289.2c0-4.1,0.1-8.3,0.3-12.4 M680.4,237.8v122.4 M500.2,277.3v6.6 M665.3,283.9v-6.6 M687.3,211.4v175.1
	 M687.3,386.5l2.2,2.3 M690.4,425.4c-2.1,0.3-3.3-0.4-4-1.8 M674.9,431.9c9.6,0.1,14.9-2.2,15.5-6.6 M690.4,172.5
	c-2.1-0.3-3.3,0.4-4,1.8"
          />
          <path
            fill="none"
            d="M690.4,172.5h4.7c1.1,0.3,1.7,1,1.7,2.2v9.8h22.8l-2.5-15c-1.4-9.2-5.6-15.3-12.7-18.2c-14.3-4-28.1-7-41.5-8.9
	l3.3,1.2c0.3,0.1,0.5,0.3,0.7,0.4c1.5,1,2.8,3.3,3.7,6.9l4.1,14.9C684.7,165.9,689.7,168.1,690.4,172.5L690.4,172.5z"
          />
          <path
            fill="none"
            d="M695.1,172.5h-4.7c-2.1-0.3-3.3,0.4-4,1.8c2.5,3,3.9,7.4,4,13.3v8.4h4.7V172.5z"
          />
          <path
            fill="none"
            d="M696.8,174.7c0-1.2-0.5-2.1-1.7-2.2V196h0.1c1,0,1.5-0.6,1.5-1.5V174.7z"
          />
          <path
            fill="none"
            d="M721.9,184.6h-25v9.9c0,1-0.5,1.5-1.5,1.5h-3.9c1.7,0.3,2.3,1.8,2.3,4.5v6.7l-0.5,0.3v2.1h28.8
	c0.7,0,1-0.3,1-1.1v-23C723,184.8,722.6,184.6,721.9,184.6L721.9,184.6z"
          />
          <path
            fill="none"
            d="M693.7,200.5c0.1-2.8-0.7-4.3-2.3-4.5h-1v12.7l2.8-1.1l0.5-0.3L693.7,200.5L693.7,200.5z"
          />
          <path
            fill="none"
            d="M690.4,196v-8.4c-0.1-5.9-1.5-10.3-4-13.3c-0.7-0.8-1.4-1.5-2.2-2.1c-2.5-1.5-6.7-2.6-12.8-3.3h-0.3l-15-0.8
	l-34.4-0.7H530c-11.1,0-22.1,0.1-32.9,0.7l0.1,1.6c7.4-0.3,14.9-0.6,22.4-0.6h102.2l35.8,0.7l13.6,0.8h0.3c1.8,0.3,3.3,0.6,4.7,0.8
	l-9.8,10.7c6.3,2.3,9.6,8.1,10,17.2v199.3c-0.4,9.1-3.7,14.7-10,17.2l9.8,10.6c-1.4,0.4-2.9,0.7-4.7,0.8h-0.3l-13.6,0.8l-35.8,0.7
	H519.7c-7.6-0.1-15-0.3-22.4-0.5l-0.1,1.6c10.9,0.4,21.7,0.7,32.9,0.7h91.8l34.5-0.7l15-1l0.3-0.1c6.1-0.7,10.3-1.8,12.8-3.3
	c0.8-0.5,1.5-1.2,2.2-2.1c2.5-3,3.9-7.4,4-13.3v-21l-1-0.4v8.9c0.1,2.3-0.4,3.3-1.8,3c-2.3-0.5-4-1.5-5.2-2.8
	c-0.6-0.5-1-1.1-1.2-1.6l-0.3-0.8c-0.1-1.1-0.3-2.1-0.4-3.2V371H679v-10.7h1.7V237.8H679v-10.7h1.7v-21.5c0.1-1.1,0.3-2.2,0.4-3.2
	l0.3-0.8c0.3-0.6,0.7-1.1,1.2-1.6c1.2-1.2,3-2.2,5.2-2.8c1.4-0.4,1.9,0.7,1.8,3v8.9l1-0.4V196H690.4L690.4,196z"
          />
          <path
            fill="none"
            d="M693.2,209.5v-2.1l-2.8,1.2v0.8 M690.4,208.6l-1,0.4l-2.2,2.3v175.1l2.2,2.3l1,0.4V209.5 M693.2,209.5h-2.8V267
	l3.3-2.1l-0.1-10.3"
          />
          <path
            fill="none"
            d="M689.5,209.1l-0.3,0.1L689.5,209.1v-8.9c0.1-2.3-0.4-3.3-1.8-3c-2.3,0.6-4,1.5-5.2,2.8c2.8-1.2,4.3-0.8,4.7,1.1
	l0.3,10.3L689.5,209.1L689.5,209.1z M718.8,215c0-3.3,0.5-5.1,1.5-5.5h-27l0.4,45.1l6.6,0.3v-9.2l0.1-1c0.1-1.1,0.5-1.9,1.1-2.8
	l0.1-0.1c0.5-0.8,1.2-1.5,2.1-2.1c1-0.7,1.9-1.2,3.2-1.6c3.6-0.7,7.6-1.1,12.1-1.1v-22L718.8,215L718.8,215z"
          />
          <path
            fill="none"
            d="M723.4,237.1h-4.7c-4.5,0.1-8.5,0.4-12.1,1.1c-1.2,0.4-2.3,1-3.2,1.6c-0.8,0.6-1.5,1.2-2.1,2.1l-0.1,0.1
	c-0.5,0.8-0.8,1.6-1.1,2.8l-0.1,1.2v38.5c3.7-0.8,7.3-0.7,10.6,0.5V314v-0.4c-3.3,1.2-6.9,1.5-10.6,0.7v38.8
	c0.4,3.7,2.5,6.2,6.5,7.6c3.6,0.7,7.6,1.1,12.1,1.1h6.9c6.5,0.1,12-0.3,16.8-1.1c4-1.2,6.2-3.7,6.5-7.6V245.8
	c-0.4-3.7-2.5-6.2-6.5-7.6l-1.5-0.3c-4.4-0.7-9.5-1-15.3-0.8L723.4,237.1L723.4,237.1z"
          />
          <path
            fill="none"
            d="M700.2,245.8v0.4l0.1-1.4 M700.2,254.9l-6.6-0.3l0.1,10.5l-3.3,2.1v22.1h5.4v-0.7h2.2v-3.2
	c0.7-0.3,1.5-0.4,2.2-0.5L700.2,254.9L700.2,254.9z M156.4,141.9l-0.3-0.3c-0.3-0.3-0.8-0.3-1.5-0.3s-1.5,0.1-2.3,0.3l-1,0.3
	c-1.2,0.4-2.2,1-3,1.5s-1.1,1.1-1,1.5c0.1,0.3,0.6,0.3,1.5,0.3c1-0.1,2.1-0.3,3.3-0.8c1.2-0.4,2.2-0.8,3-1.4
	C156.1,142.6,156.4,142.2,156.4,141.9L156.4,141.9z"
          />
          <path
            fill="none"
            d="M154.6,141.2c0.7,0,1.1,0.1,1.5,0.3h111.6c-0.8-2.9-3.9-4.3-9.2-4.3h-97.3c-6.3,0-10,1.5-11.3,4.3h2.5
	C153.2,141.4,153.9,141.2,154.6,141.2L154.6,141.2z M149.9,141.6h-9.2c-6.7,2.1-11.1,4.7-13.3,7.8h3.3c1.9-0.1,2.6,1.4,1.8,4.3
	l-5.5,34.8l0.8,2.2v4.7l1.5,2.1l1.5,2.1l-0.1,3l7.7,2.9c1.8,1.2,4.1,0.6,7.3-2.3c0.6-0.4,1-1,1.6-1.4c-9.9-2.5-14.7-5.6-14.3-9.4
	c0.4-4.8,0.8-9.5,1.4-13.9c1.2-10.7,2.8-20.2,4.7-28.3C140.3,146.5,143.9,143.7,149.9,141.6L149.9,141.6z M121.2,149.3
	c-2.9-0.1-4.8,1.1-5.8,3.4c-3.2,7.2-6.2,15.7-9.1,25.6l-2.5,8.9c-0.6,1.8-1.5,3.2-3,4.1l-2.6,1.9c-1.2,1-2.1,3.4-2.3,7.3l-2.6,28.8
	c0.1,1.2,1.1,2.8,3,4.3l1,0.7l5.9,0.1c-1.9-1.9-2.9-3.6-3-5l2.6-28.8c0.1-2.6,0.6-4.5,1.2-5.8l0.1-0.4l1.4-1.5l1.5-2.1l0.8-1.7
	l12.9-37.3c0.3-1.6,1.4-2.6,3.3-2.6h-2.9L121.2,149.3L121.2,149.3z"
          />
          <path
            fill="none"
            d="M127.4,149.3h-3.3c-1.9,0.1-3,1-3.3,2.6l-12.9,37.3l19-0.4l-19,0.4l-0.8,1.6l-1.5,2.1l-1.4,1.5l-0.1,0.4
	l23.9-0.4v-3.7l-0.8-2.2l5.5-34.8c0.7-2.9,0.1-4.4-1.8-4.3h-3.4V149.3L127.4,149.3z"
          />
          <path
            fill="none"
            d="M127.9,195.3v-1l-23.9,0.4c-0.7,1.2-1.1,3.2-1.2,5.8l-2.6,28.8c0.1,1.2,1.1,2.9,3,5h1.1v-0.1l3.4,0.1l16.2,0.1
	h2.2l2.3-4.4l1-32.7L127.9,195.3L127.9,195.3z"
          />
          <path
            fill="none"
            d="M131,199.4l-1.5-2.1l-1,32.7l-2.3,4.4l3.9-4.4l0.8-27.6"
          />
          <path
            fill="none"
            d="M130,230.1l-3.9,4.4v128.8l3.9,4.4l1,27.6l7.7-2.9c1.8-1.2,4.1-0.5,7.3,2.3c0.6,0.5,1,1,1.7,1.4
	c-1.2-1.4-2.3-3.7-3.4-7h-6.6c-0.8-4-1.5-9.4-2.5-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.6-6.6-0.8-10.2l-1-15.8
	c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.4,1.2-40.6l0.8-12.9c0.3-3.7,0.6-7,0.8-10.2l0.8-7.3c0.8-6.6,1.6-12,2.5-16.1h6.5
	c1-3.3,2.2-5.6,3.4-7c-0.6,0.4-1.1,0.8-1.6,1.4c-3.2,2.8-5.5,3.6-7.3,2.3l-7.7-2.9L130,230.1L130,230.1z"
          />
          <path
            fill="none"
            d="M126,234.6l0.1-0.1h-2.2 M126,234.6l-2.1-0.1l-16.4-0.1h-4.4v129.2l1.1-0.1l3.3,0.1l15.5-0.3h2.9 M104.1,234.4
	h3.4 M104.3,234.4h-0.1H104.3z"
          />
          <path
            fill="none"
            d="M97.3,234.4l0.4,0.3L97.3,234.4c0.8,1.1,1.2,4,1.1,8.7v111.8c0.1,4.7-0.1,7.6-1.1,8.7l0.4-0.3l-0.4,0.3h5.9
	v-129L97.3,234.4z M179.7,192.1l-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3c-0.3,0.1-0.4,0.3-0.3,0.6l0.4,1.6h0.1l-0.1-0.6
	c-0.1-0.3,0-0.6,0.3-0.6l1.2-0.4L179.7,192.1L179.7,192.1z"
          />
          <path
            fill="none"
            d="M179.9,192.1h-0.4l-1.2,0.4c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.6l0.3,1l0.4,1.4c0.1,0.3,0.3,0.4,0.6,0.4l1.2-0.3
	l0.4-0.3v-0.4l-0.3-1.1l-0.6-1.9C180.1,192.3,179.9,192.3,179.9,192.1L179.9,192.1z"
          />
          <path
            fill="none"
            d="M180.6,194.5l0.3,1.1v0.4l0.6,2.3l-0.4,0.6l0.3,1l0.3-0.1h0.4l0.3,0.3l0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.4,0.3
	l-0.3,0.1c0,0.3-0.1,0.4-0.4,0.4l-5.4,1.5c-0.3,0.1-0.4,0-0.6-0.1l-0.4,0.1h-0.4l-0.3-0.3l-0.1-0.3c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.3,0.3-0.3l0.3-0.1v-1l-1-0.4l-0.8-2.2c-0.1-0.1-0.1-0.1-0.3-0.4l-0.6-2.2l-15.7,2.1c-2.5,0.7-4.7,1.5-6.5,2.5
	c2.5-1,4.3-0.8,5.4,0.4c1.2,3.6,1.9,12.1,2.1,25.6c0.4,16,0.6,31.2,0.6,45.9v67.4c0,10.5-0.3,21.2-0.6,32.2c0,13.5-0.7,22-2.1,25.6
	c-1.1,1.4-2.9,1.5-5.4,0.4c1.8,1,4,1.8,6.5,2.5l15.7,2.1l0.6-2.2c0-0.1,0.1-0.3,0.3-0.3l0.8-2.2l1-0.4l0.1-0.8l-0.3-0.1l-0.3-0.3
	c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.3c0-0.1,0.1-0.3,0.3-0.3h0.4l0.4,0.1c0.1-0.1,0.3-0.3,0.6-0.1l5.4,1.4c0.3,0.1,0.4,0.3,0.4,0.5
	l0.3,0.1c0.1,0,0.3,0.1,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4l-0.1,0.3l-0.3,0.3h-0.4l-0.3-0.1l-0.3,1l0.4,0.5l-0.6,2.3v0.4l-0.3,1.1
	l76.6,10l49.5,7.2l1.1-9.8c0.7-25.2,1.1-49,1.1-71.3v-69.9c0.1-25.4-0.1-53-1.1-82.5l-1.2-9.8l-49.5,7.2L180.6,194.5L180.6,194.5z
	 M258.9,246.3c0-1.1,0.4-1.9,1.1-2.8c0.8-0.8,1.6-1.1,2.8-1.1s1.9,0.4,2.8,1.1c0.8,0.8,1.1,1.6,1.1,2.8s-0.4,1.9-1.1,2.8
	c-0.8,0.7-1.6,1.1-2.8,1.1s-1.9-0.4-2.8-1.1C259.3,248.3,258.9,247.3,258.9,246.3z M260.1,289.1c0.3,0.3,0.4,0.7,0.4,1.1
	c0,0.4-0.1,0.8-0.4,1.1l-0.5,0.4v4.3c0.1,0.1,0.3,0.3,0.3,0.5v0.3l1.8,0.8v2.8l-1.8,0.8v0.3c0,0.1-0.1,0.4-0.3,0.5v4.3l0.5,0.3
	c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.1
	c0-0.4,0.1-0.8,0.4-1.1l0.7-0.4v-4.3c-0.1-0.1-0.3-0.3-0.3-0.5v-4.8c0-0.3,0.1-0.4,0.3-0.5v-4.3l-0.7-0.4c-0.3-0.3-0.4-0.7-0.4-1.1
	c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.7-0.4,1.1-0.4C259.4,288.6,259.9,288.8,260.1,289.1L260.1,289.1z M262.7,347.7
	c1.1,0,1.9,0.4,2.8,1.1c0.8,0.8,1.1,1.6,1.1,2.8c0,1.1-0.4,1.9-1.1,2.8c-0.8,0.8-1.6,1.1-2.8,1.1s-1.9-0.4-2.8-1.1
	c-0.8-0.8-1.1-1.6-1.1-2.8c0-1.1,0.4-1.9,1.1-2.8C260.8,348.1,261.6,347.7,262.7,347.7L262.7,347.7z"
          />
          <path
            fill="none"
            d="M260,243.6c-0.8,0.8-1.1,1.6-1.1,2.8s0.4,1.9,1.1,2.8c0.8,0.7,1.6,1.1,2.8,1.1s1.9-0.4,2.8-1.1
	c0.8-0.8,1.1-1.6,1.1-2.8c0-1.1-0.4-1.9-1.1-2.8c-0.8-0.8-1.6-1.1-2.8-1.1S260.8,242.9,260,243.6z M260.8,248.3
	c-0.5-0.6-0.8-1.1-0.8-1.9c0-0.8,0.3-1.4,0.8-1.9s1.2-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8s0.8,1.1,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9
	s-1.1,0.8-1.9,0.8C262.1,248.9,261.4,248.7,260.8,248.3L260.8,248.3z"
          />
          <path
            fill="none"
            d="M260.1,246.3c0,0.7,0.3,1.4,0.8,1.9s1.2,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8s0.8-1.1,0.8-1.9
	c0-0.8-0.3-1.4-0.8-1.9s-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8S260.1,245.5,260.1,246.3L260.1,246.3z M261.8,246.3
	c0-0.3,0.1-0.6,0.3-0.7c0.3-0.3,0.4-0.3,0.7-0.3s0.5,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.7c0,0.3-0.1,0.6-0.3,0.7
	c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.3C261.8,246.9,261.8,246.6,261.8,246.3L261.8,246.3z"
          />
          <path
            fill="none"
            d="M262.1,245.6c-0.1,0.1-0.3,0.4-0.3,0.7c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3
	c0.1-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.3-0.7c-0.3-0.3-0.4-0.3-0.8-0.3C262.5,245.2,262.2,245.4,262.1,245.6L262.1,245.6z
	 M258.8,290.1c-0.1,0.1-0.1,0.3-0.1,0.4v5.6c-0.1,0.1-0.3,0.3-0.3,0.5v4.8c0,0.1,0.1,0.4,0.3,0.6v5.6l0.1,0.3
	c0.1,0.1,0.3,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1l0.1-0.3v-5.5c0.1-0.1,0.3-0.3,0.3-0.5v-5.1c0-0.3-0.1-0.4-0.3-0.5v-5.5l-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1C259,289.9,258.9,289.9,258.8,290.1L258.8,290.1z"
          />
          <path
            fill="none"
            d="M258.6,290.4c0-0.1,0-0.3,0.1-0.4s0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.1l0.1,0.4v1.2l0.5-0.4
	c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1
	s0.1,0.8,0.4,1.1l0.7,0.4L258.6,290.4L258.6,290.4z M261.8,297.5l-1.9-0.7v4.3l1.9-0.8 M257.9,306.4c-0.3,0.3-0.4,0.7-0.4,1.1
	s0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1l-0.5-0.3v1.2l-0.1,0.3
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.3-0.1l-0.1-0.3V306L257.9,306.4L257.9,306.4z M265.5,348.8c-0.8-0.8-1.6-1.1-2.8-1.1
	s-1.9,0.4-2.8,1.1c-0.8,0.8-1.1,1.6-1.1,2.8c0,1.1,0.4,1.9,1.1,2.8c0.8,0.8,1.6,1.1,2.8,1.1s1.9-0.4,2.8-1.1
	c0.8-0.8,1.1-1.6,1.1-2.8C266.6,350.5,266.2,349.6,265.5,348.8z M262.7,348.8c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.1,0.8,1.9
	c0,0.8-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.1-0.8-1.9c0-0.7,0.3-1.4,0.8-1.9
	C261.4,349.1,262.1,348.8,262.7,348.8L262.7,348.8z"
          />
          <path
            fill="none"
            d="M264.7,349.6c-0.5-0.5-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.1-0.8,1.9c0,0.8,0.3,1.4,0.8,1.9
	c0.5,0.5,1.2,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9C265.5,350.7,265.2,350.2,264.7,349.6z M262.7,350.5
	c0.3,0,0.5,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7c-0.3,0.3-0.4,0.4-0.8,0.4c-0.3,0-0.5-0.1-0.7-0.4
	c-0.1-0.1-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7C262.2,350.6,262.5,350.5,262.7,350.5L262.7,350.5z"
          />
          <path
            fill="none"
            d="M263.6,350.7c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
	c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3c0.1-0.1,0.3-0.4,0.3-0.7C263.8,351.3,263.7,351,263.6,350.7L263.6,350.7z M180.9,195.8
	l-0.4,0.3l-1.2,0.3c-0.3,0.1-0.6,0-0.6-0.4l-0.4-1.4H178l0.1,0.7l0.4,2.9l1.6-0.4c0.3-0.1,0.6,0,0.6,0.4l0.1,0.6l0.4-0.6
	L180.9,195.8L180.9,195.8z"
          />
          <path
            fill="none"
            d="M178.3,194.7l-0.3-1h-0.3l0.3,1 M181,198.7l-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.4l-1.6,0.4l-2.1,0.6l-1.6,0.4
	c-0.3,0.1-0.4,0.3-0.4,0.7l0.3,0.8l0.1,0.8l0.3,1l0.1,0.1c0.1,0.1,0.3,0.3,0.6,0.1l5.4-1.5c0.3-0.1,0.4-0.3,0.4-0.4v-0.1l-0.3-1.1
	L181,198.7L181,198.7z"
          />
          <path
            fill="none"
            d="M175.1,196.3l-0.3-1.1h-0.3l0.6,1.9c0.1,0.3,0,0.6-0.4,0.7l-1.2,0.4h-0.4l0.8,2.2l1,0.4l-0.3-0.8
	c-0.1-0.3,0-0.6,0.4-0.7l1.7-0.4L175.1,196.3L175.1,196.3z M175,201.5l-0.3,0.1c-0.1,0-0.3,0.1-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
	l0.1,0.3l0.3,0.3h0.4l0.4-0.1l-0.1-0.1L175,201.5L175,201.5z M181.4,200.8v0.1l0.3-0.1l0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4l-0.1-0.3
	l-0.3-0.3h-0.4l-0.3,0.1L181.4,200.8L181.4,200.8z M157.4,226.1c0-13.5-0.7-22-2.1-25.6c-1.1-1.4-2.9-1.5-5.4-0.4
	c-1,0.4-1.8,1-2.6,1.6c-1.2,1.4-2.3,3.7-3.4,7c-1.2,4-2.3,9.4-3.2,16.1c-0.3,2.3-0.6,4.7-0.8,7.3c-0.3,3.2-0.7,6.6-1,10.2
	c-0.3,4.3-0.6,8.5-0.8,12.9c-0.1,3.4-0.3,7-0.6,10.5h2.2l0.1-3.6c0.1-1,0.7-1.5,1.6-1.6h6.2l0.3,0.1c0.4,0.1,0.7,0.3,1,0.7
	c0.6,0.5,0.8,1.5,1,2.9v1.8h7.3C157.9,253.1,157.8,239.9,157.4,226.1L157.4,226.1z M154.2,207.3l0.1,0.7c0.4,3.4,0.4,7.6,0,12.5
	c-0.3,3-0.7,5.8-1.2,8.4l-0.8,3.3L152,233c-1.1,3.4-2.2,5.2-3.3,5.2c-0.6,0-1-0.4-1.4-1.2s-0.7-2.2-1-3.9c-0.4-3.4-0.4-7.6,0-12.4
	c0.4-4.8,1.2-8.9,2.3-12.5l0.1-0.4c1.1-3.2,2.1-4.8,3.2-4.8C153.1,202.9,153.8,204.4,154.2,207.3L154.2,207.3z M150.9,248.8v3.4
	c0,1.2-0.3,2.1-0.7,2.8c-0.6,0.8-1.5,1.4-2.8,1.4h-5.4c-0.8-0.1-1.4-0.6-1.5-1.5l0.6-9.8c0.3-1,1.1-1.4,2.2-1.2h5.4
	C150.2,244.1,150.9,245.8,150.9,248.8L150.9,248.8z M145,208.9c0.3,0,0.4,0.3,0.6,0.7l0.3,0.8v0.1l-0.3,1c-0.3,1.2-0.6,2.6-0.8,4.1
	c0.4-1.9,0.8-3.6,1.2-5.1l0.1,1c0.3,1.6,0.3,3.7,0,6.2c-0.1,2.5-0.6,4.5-1,6.2l-0.1,0.3l-0.3,0.8c-0.4,1-0.7,1.5-1.1,1.5
	c-0.1,0-0.3-0.1-0.6-0.4c-0.3-0.4-0.4-1.1-0.6-2.2c-0.3-1.7-0.3-3.7,0-6.2c0.1-2.5,0.6-4.5,1-6.2C144,209.9,144.4,208.9,145,208.9
	L145,208.9z"
          />
          <path
            fill="none"
            d="M149,272.3l-1,0.4l-0.8,0.1h-5.4c-1,0-1.6-0.5-1.8-1.5l0.3-5.5h-2.2c-0.4,9.9-0.7,19.8-0.8,30v6.3
	c0.1,10.2,0.3,20.2,0.8,30h2.2l-0.3-5.5c0.3-1,0.8-1.5,1.8-1.5h5.4l0.8,0.1c0.4,0.1,0.7,0.1,1,0.4c0.6,0.3,1,0.8,1.2,1.4
	c0.3,0.5,0.4,1.4,0.4,2.2v3h7.3v-60V266h-7.3v3c0,1-0.1,1.6-0.4,2.2C149.9,271.6,149.5,272.1,149,272.3L149,272.3z M150.2,281.8v4
	c0,0.8-0.3,1.4-0.6,1.9s-0.7,1-1.1,1.2c-0.3,0.1-0.7,0.3-1,0.4h-6.2c-1-0.1-1.5-0.5-1.8-1.4l0.1-9.5c0.4-1,1-1.5,1.9-1.5h6.3
	l0.8,0.3c0.4,0.3,0.8,1,1.1,1.7C150.1,279.8,150.2,280.7,150.2,281.8L150.2,281.8z M148.3,293.8c0.6,0.1,1,0.4,1.2,0.7
	c0.7,0.7,1,1.9,1,3.6v1.9c0,1.7-0.4,2.8-1,3.4c-0.3,0.3-0.7,0.5-1.1,0.7h-7c-1.1,0-1.8-0.7-2.1-1.8v-6.9c0.3-1.1,1-1.8,2.1-1.8h5.9
	L148.3,293.8L148.3,293.8z M151.9,289.7v0.8c0.1,0.3,0.3,0.5,0.4,0.5h2.9v1.9h-2.8c-0.3,0-0.4-0.1-0.7-0.3c-0.3-0.3-0.6-0.8-0.7-1.5
	l-0.1-2.1c0-0.4,0-0.7,0.1-1c0.1-0.5,0.4-1.1,0.7-1.2c0.1-0.1,0.3-0.1,0.4-0.1h0.6v1.9h-0.6l-0.3,0.1
	C152,289,151.9,289.2,151.9,289.7L151.9,289.7z M151,297.5c-0.1-0.4-0.1-1-0.1-1.5l0.1-1c0.1-0.7,0.4-1.2,0.8-1.5
	c0.1-0.1,0.4-0.1,0.7-0.1s0.4,0.1,0.7,0.3c0.3,0.3,0.4,0.5,0.6,0.8l0.1,1.2v1.2c-0.1,0.8-0.3,1.4-0.7,1.7l-0.4,0.3l-0.4,0.1v-3.7
	h-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.3-0.3,0.5v0.5c0,0.3,0.1,0.4,0.1,0.5v1.8h-0.1l-0.3-0.4
	C151.3,298.3,151.2,297.9,151,297.5L151,297.5z M146.8,308.5l0.8,0.1c0.4,0,0.7,0.1,1.1,0.4c0.6,0.3,0.8,0.7,1.1,1.2
	c0.3,0.5,0.4,1.1,0.4,1.8v4c0,1.1,0,2.1-0.3,2.8c-0.3,0.8-0.6,1.4-1.1,1.6c-0.3,0.1-0.6,0.3-1,0.4h-6.3c-0.8,0-1.5-0.5-1.9-1.5
	l-0.1-9.5c0.3-0.8,0.8-1.2,1.8-1.4H146.8L146.8,308.5z M153.2,299.7c0.3,0.3,0.4,0.5,0.6,1c0.1,0.3,0.1,0.7,0.1,1.1s0,0.8-0.1,1.2
	c-0.1,0.8-0.3,1.2-0.7,1.5l-0.4,0.3h-0.4v-3.7c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.3,0.4-0.3,0.7v0.4c0,0.3,0.1,0.4,0.1,0.5v1.9h-0.1
	l-0.3-0.3c-0.1-0.3-0.3-0.7-0.4-1.1s-0.1-1-0.1-1.5c0-0.4,0-0.7,0.1-1c0.1-0.7,0.4-1.2,0.8-1.5c0.1-0.1,0.3-0.1,0.4-0.1
	C152.7,299.3,153,299.4,153.2,299.7L153.2,299.7z M153.9,307.1h-0.3v0.1l0.1,0.3l0.1,0.7c0.1,0.5,0.1,1,0,1.5
	c-0.1,0.4-0.3,0.8-0.6,1.1l-0.6,0.3l-1-0.1c-0.3-0.1-0.4-0.3-0.6-0.5c-0.3-0.4-0.4-0.8-0.4-1.4c0-0.4,0-0.8,0.1-1.2l0.1-0.4v-0.3
	h-1.1v-1.9h3.9L153.9,307.1L153.9,307.1z"
          />
          <path
            fill="none"
            d="M145.5,337.4h-3.4c-1.1-0.3-1.6-0.8-1.6-1.6l-0.1-3.6h-2.2c0.1,2.5,0.3,5,0.3,7.4l0.1,3
	c0.3,4.3,0.4,8.7,0.8,12.9c0.3,3.7,0.6,7,1,10.2c0.3,2.6,0.6,5.1,0.8,7.4c0.8,6.6,1.9,12,3.2,16.1c1,3.3,2.2,5.6,3.4,7l2.6,1.5
	c2.5,1.1,4.3,0.8,5.4-0.4c1.2-3.4,1.9-12.1,2.1-25.6c0.3-11,0.4-21.7,0.6-32.2v-7.4H151v1.8c-0.1,1.2-0.4,2.2-1,2.9
	c-0.3,0.3-0.6,0.5-1,0.7h-3.6L145.5,337.4L145.5,337.4z M142.1,341.5h5.4c1.4,0,2.3,0.5,3,1.5c0.3,0.7,0.6,1.5,0.6,2.5v3.3
	c0.1,3-0.7,4.7-2.2,5h-5.4c-1.1,0.3-1.8-0.1-2.1-1.2l-0.6-9.8C140.7,342.1,141.3,341.7,142.1,341.5L142.1,341.5z M144.4,372.3
	l0.4,1.4l0.1,0.1c0.4,1.6,0.8,3.7,1,6.2c0.3,2.5,0.3,4.5,0,6.2l-0.3,1.6l-0.1,0.3c-0.1,0.4-0.4,0.7-0.6,0.7c-0.6,0-1-0.8-1.5-2.6
	c-0.4-1.6-0.8-3.7-1-6.2c-0.3-2.5-0.3-4.5,0-6.2c0.1-1.1,0.3-1.8,0.6-2.2c0.1-0.3,0.3-0.4,0.6-0.4
	C143.8,371.4,144,371.7,144.4,372.3L144.4,372.3z M146.2,372.8c-0.1-2.9,0-5.5,0.3-7.8c0.3-1.8,0.6-3,1-3.9c0.4-0.8,0.8-1.2,1.4-1.2
	c1.1,0,2.2,1.6,3.3,5.1l0.3,0.8l0.8,3.3c0.6,2.5,1,5.2,1.2,8.4c0.4,4.8,0.4,8.9,0,12.4l-0.1,0.7c-0.4,2.9-1.2,4.4-2.2,4.4
	s-2.1-1.6-3.2-4.8l-0.1-0.4c-0.4-1.4-0.8-2.9-1.1-4.4c-0.6-2.5-1-5.1-1.2-8C146.4,375.8,146.2,374.3,146.2,372.8L146.2,372.8z
	 M149,207.7l-0.1,0.4c-1.1,3.4-1.9,7.6-2.3,12.5c-0.4,4.8-0.4,8.9,0,12.4c0.3,1.8,0.6,3,1,3.9s0.8,1.2,1.4,1.2
	c1.1,0,2.2-1.6,3.3-5.2l0.3-0.8l0.8-3.3l-0.4-0.6c-0.3-0.6-0.4-1.2-0.6-2.3v-0.3c-0.3-2.3-0.3-5.1,0-8.4c0.3-3,0.8-5.6,1.4-7.8
	l0.3-0.8c0.1-0.4,0.3-0.8,0.6-1.2c-0.4-2.9-1.2-4.4-2.2-4.4C151,202.9,149.9,204.5,149,207.7L149,207.7z"
          />
          <path
            fill="none"
            d="M152.1,225.7v0.3c0.1,1,0.3,1.8,0.6,2.3l0.4,0.6c0.6-2.5,1-5.2,1.2-8.4c0.4-4.8,0.4-8.9,0-12.5l-0.1-0.7
	c-0.1,0.4-0.3,0.8-0.6,1.2l-0.3,0.8c-0.7,2.2-1.1,4.8-1.4,7.8C151.9,220.5,151.9,223.4,152.1,225.7L152.1,225.7z M145.4,211.5l0.3-1
	v-0.1l-0.3-0.8c-0.1-0.4-0.4-0.7-0.6-0.7c-0.6,0-1,0.8-1.5,2.6c-0.4,1.6-0.8,3.7-1,6.2c-0.3,2.5-0.3,4.5,0,6.2
	c0.1,1.1,0.3,1.8,0.6,2.2c0.1,0.3,0.3,0.4,0.6,0.4c0.4,0,0.7-0.6,1.1-1.5l-0.1-1.1c-0.3-1.6-0.3-3.7,0-6.2l0.3-2.1
	C144.9,214.1,145.1,212.8,145.4,211.5L145.4,211.5z"
          />
          <path
            fill="none"
            d="M146,211.5l-0.1-1c-0.4,1.5-0.8,3.3-1.2,5.1l-0.3,2.1c-0.3,2.5-0.3,4.5,0,6.2l0.1,1.1l0.3-0.8l0.1-0.3
	c0.4-1.6,0.8-3.7,1-6.2C146.1,215.2,146.1,213.2,146,211.5L146,211.5z M150.9,252.2v-3.4c0.1-3-0.7-4.7-2.2-5h-4.4v11h6.1
	C150.6,254.3,150.9,253.3,150.9,252.2z"
          />
          <path
            fill="none"
            d="M144.3,243.9h-1c-1.1-0.1-1.8,0.3-2.1,1.2l-0.6,9.8c0.1,0.8,0.6,1.4,1.5,1.5h5.4c1.2,0,2.2-0.4,2.8-1.4h-5.9
	v-11.1L144.3,243.9L144.3,243.9z M146.8,260.5h-1.1c-0.3,4.1-0.6,8.3-0.7,12.4h1.1C146.2,268.8,146.5,264.5,146.8,260.5z"
          />
          <path
            fill="none"
            d="M147.7,260.5h-1c-0.3,4.1-0.6,8.3-0.7,12.4h1C147.2,268.8,147.5,264.5,147.7,260.5z M145.5,260.5h-1
	c-0.3,4.1-0.6,8.3-0.7,12.4h1C145.1,268.8,145.3,264.5,145.5,260.5L145.5,260.5z M150.6,264.1c-0.1-1.2-0.4-2.2-1-2.9
	c-0.3,3.7-0.4,7.4-0.6,11.1c0.6-0.3,1-0.8,1.2-1.4L150.6,264.1L150.6,264.1z"
          />
          <path
            fill="none"
            d="M149.7,261.2c-0.3-0.3-0.6-0.5-1-0.7c-0.3,4-0.6,8.1-0.7,12.2l1-0.4C149.1,268.6,149.4,264.9,149.7,261.2
	L149.7,261.2z"
          />
          <path
            fill="none"
            d="M148.7,260.5l-0.3-0.1h-0.6c-0.3,4.1-0.6,8.3-0.7,12.4l0.8-0.1C148.2,268.6,148.4,264.6,148.7,260.5
	L148.7,260.5z M142,260.5c-1.1,0.3-1.6,0.8-1.6,1.6l-0.4,9.1c0.3,1,0.8,1.5,1.8,1.5h2.2c0.1-4.1,0.4-8.3,0.7-12.4H142L142,260.5
	L142,260.5z M150.2,271c0.3-0.5,0.4-1.4,0.4-2.2v-4.7L150.2,271L150.2,271z M147.7,276.9h-1c-0.1,4.1-0.3,8.3-0.3,12.4h1
	C147.6,285.1,147.6,281,147.7,276.9z"
          />
          <path
            fill="none"
            d="M146.9,276.9h-1.1c-0.1,4.1-0.3,8.3-0.3,12.4h1L146.9,276.9L146.9,276.9z"
          />
          <path
            fill="none"
            d="M145.8,276.9h-1.1c-0.1,4.1-0.3,8.3-0.3,12.4h1.1C145.7,285.1,145.7,281,145.8,276.9L145.8,276.9z"
          />
          <path
            fill="none"
            d="M144.7,276.9h-1c-0.1,4.1-0.3,8.3-0.3,12.4h1C144.4,285.1,144.6,281,144.7,276.9L144.7,276.9z M148.8,277.3
	L148,277h-0.1c-0.1,4.1-0.3,8.1-0.3,12.2c0.4-0.1,0.7-0.1,1-0.4C148.6,285,148.7,281.1,148.8,277.3L148.8,277.3z"
          />
          <path
            fill="none"
            d="M143.8,276.9h-2.2c-0.8,0-1.5,0.5-1.9,1.5l-0.1,9.5c0.3,0.8,0.8,1.2,1.8,1.4h2.1
	C143.5,285.1,143.6,281,143.8,276.9L143.8,276.9z M150.2,281.8c0-1.2,0-2.2-0.3-2.9l-0.3,8.8c0.3-0.5,0.4-1.1,0.6-1.9L150.2,281.8
	L150.2,281.8z M148.6,288.8c0.6-0.3,0.8-0.7,1.1-1.2l0.3-8.8c-0.3-0.8-0.6-1.4-1.1-1.6C148.7,281.1,148.6,285,148.6,288.8
	L148.6,288.8z M153,288.8v-1.9h-0.6c-0.1,0-0.3,0-0.4,0.1c-0.3,0.3-0.6,0.7-0.7,1.2c-0.1,0.3-0.1,0.5-0.1,1l0.1,2.1
	c0.1,0.7,0.3,1.2,0.7,1.5c0.1,0.1,0.4,0.3,0.7,0.3h2.8v-1.9h-2.9c-0.1-0.1-0.3-0.3-0.4-0.5v-0.8c0-0.4,0.1-0.7,0.3-0.8l0.3-0.1
	L153,288.8L153,288.8z M153.9,295.9l-0.1-1.2c-0.1-0.4-0.3-0.7-0.6-0.8c-0.1-0.1-0.4-0.3-0.7-0.3s-0.6,0-0.7,0.1
	c-0.4,0.3-0.7,0.8-0.8,1.5l-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1l0.3,0.4h0.1v-1.8c-0.1-0.1-0.1-0.3-0.1-0.5v-0.5
	c0-0.3,0.1-0.4,0.3-0.5l0.1-0.1h0.1v3.7l0.4-0.1l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.6L153.9,295.9L153.9,295.9z M152.8,295.3l0.3,0.1
	c0.1,0.1,0.1,0.3,0.1,0.5v0.5c0,0.3-0.1,0.5-0.3,0.7h-0.1L152.8,295.3L152.8,295.3z"
          />
          <path
            fill="none"
            d="M153,295.3l-0.1,1.9h0.1c0.1-0.1,0.3-0.3,0.3-0.7V296c0-0.3-0.1-0.4-0.1-0.5L153,295.3L153,295.3z M153.9,301.6
	c0-0.4,0-0.7-0.1-1.1c-0.1-0.4-0.3-0.7-0.6-1s-0.6-0.4-1-0.4c-0.1,0-0.3,0-0.4,0.1c-0.4,0.3-0.7,0.7-0.8,1.5c-0.1,0.3-0.1,0.7-0.1,1
	c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1.1l0.3,0.3h0.1v-1.9c-0.1-0.1-0.1-0.3-0.1-0.5v-0.4c0-0.4,0.1-0.5,0.3-0.7
	c0.1-0.1,0.1-0.1,0.3-0.1v3.7h0.4l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.5C153.9,302.6,153.9,302.2,153.9,301.6L153.9,301.6z M153.1,301.4
	c0.1,0.1,0.1,0.3,0.1,0.5v0.5c0,0.3-0.1,0.5-0.3,0.7l-0.1,0.1v-1.9L153.1,301.4L153.1,301.4z"
          />
          <path
            fill="none"
            d="M153.4,301.9c0-0.3-0.1-0.4-0.1-0.5l-0.1-0.1H153v1.9l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.7V301.9L153.4,301.9z
	 M139.4,295.4v6.9c0.3,1.1,1,1.8,2.1,1.8h1.9v-10.5h-1.9C140.3,293.8,139.6,294.3,139.4,295.4L139.4,295.4z M148.4,293.8h-1v10.3
	h0.8l0.1-0.1 M149.5,294.5c-0.3-0.3-0.7-0.5-1.1-0.7V304c0.4-0.1,0.8-0.3,1.1-0.7V294.5L149.5,294.5z M144.4,293.8l-1.1-0.1v10.5
	h1.1 M145.5,293.8h-1.1v10.3h1.1C145.4,300.7,145.4,297.2,145.5,293.8z M146.5,293.8h-1v10.3h1V293.8z M147.5,293.8h-1v10.3h1
	 M150.6,299.8v-1.9c0-1.6-0.4-2.9-1-3.4v8.9C150.2,302.7,150.6,301.5,150.6,299.8L150.6,299.8z M151.2,307.3l-0.1,0.4
	c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.5,0.1,1,0.4,1.4c0.1,0.3,0.3,0.4,0.6,0.5l1,0.1l0.6-0.3c0.3-0.3,0.4-0.6,0.6-1.1c0.1-0.4,0.1-1,0-1.5
	l-0.1-0.7l-0.1-0.3V307h0.3v-1.8h-3.9v1.9h1.1V307.3L151.2,307.3z M152.1,307.1h0.3l0.4,0.1l0.1,0.1c0.1,0.3,0.3,0.5,0.3,1v0.3
	l-0.3,0.5l-0.3,0.1l-0.7-0.1l-0.3-0.1c-0.1-0.1-0.3-0.4-0.3-0.8v-0.4l0.3-0.5L152.1,307.1L152.1,307.1z"
          />
          <path
            fill="none"
            d="M152.4,307.1h-0.3l-0.3,0.1l-0.3,0.5v0.4c0,0.3,0.1,0.5,0.3,0.8l0.3,0.1l0.7,0.1l0.3-0.1l0.3-0.5v-0.3
	c0-0.4-0.1-0.7-0.3-1l-0.1-0.1L152.4,307.1L152.4,307.1z M144.4,308.5h-1.1c0,4.1,0.1,8.3,0.3,12.4h1
	C144.6,316.8,144.4,312.6,144.4,308.5L144.4,308.5z M146.6,308.5h-1c0,4.1,0.1,8.3,0.3,12.4h1.1
	C146.8,316.8,146.6,312.6,146.6,308.5z"
          />
          <path
            fill="none"
            d="M146.8,308.5c-0.1,4.1,0,8.3,0.1,12.4h1l-0.3-12.2L146.8,308.5L146.8,308.5z M145.5,308.5h-1.1
	c0,4.1,0.1,8.3,0.3,12.4h1.1C145.7,316.8,145.7,312.6,145.5,308.5z M149.7,310.2c-0.3-0.5-0.7-1-1.1-1.2c0,3.9,0.1,7.7,0.3,11.6
	c0.4-0.4,0.8-1,1.1-1.6L149.7,310.2L149.7,310.2z"
          />
          <path
            fill="none"
            d="M150.1,312.1c-0.1-0.7-0.3-1.4-0.4-1.8l0.3,8.7c0.3-0.7,0.3-1.6,0.3-2.9L150.1,312.1L150.1,312.1z M148.6,308.9
	c-0.3-0.1-0.7-0.3-1.1-0.3l0.3,12.2h0.1c0.3-0.1,0.7-0.1,1-0.4C148.7,316.6,148.6,312.8,148.6,308.9L148.6,308.9z M141.3,308.5
	c-1,0.1-1.5,0.5-1.8,1.4l0.1,9.5c0.4,1,1,1.5,1.9,1.5h2.2c-0.1-4.1-0.3-8.3-0.3-12.4L141.3,308.5L141.3,308.5z M141.8,325
	c-1,0-1.6,0.5-1.8,1.5l0.4,9.1c0.1,1,0.7,1.5,1.6,1.7h2.5c-0.3-4.1-0.4-8.3-0.7-12.4h-2.1L141.8,325L141.8,325z"
          />
          <path
            fill="none"
            d="M145,325h-1.1c0.1,4.1,0.4,8.3,0.7,12.4h1.1C145.3,333.3,145.1,329.1,145,325L145,325z M147.1,325l0.6,12.4h1
	c-0.3-4.1-0.4-8.3-0.7-12.2L147.1,325L147.1,325z"
          />
          <path fill="none" d="M147.1,325h-1l0.6,12.4h1.1" />
          <path
            fill="none"
            d="M146.1,325H145c0.1,4.1,0.4,8.3,0.7,12.4h1.1L146.1,325z M149,325.4c-0.3-0.1-0.7-0.3-1-0.4
	c0.1,4,0.4,8.1,0.7,12.2c0.4-0.1,0.7-0.3,1-0.7L149,325.4L149,325.4z M150.6,329.1c0-0.8-0.1-1.6-0.4-2.2l0.4,7L150.6,329.1
	L150.6,329.1z"
          />
          <path
            fill="none"
            d="M149.5,336.7c0.6-0.5,0.8-1.5,1-2.9l-0.4-7c-0.3-0.7-0.7-1.1-1.2-1.4L149.5,336.7L149.5,336.7z M142.1,341.5
	c-0.8,0.1-1.4,0.5-1.5,1.4l0.6,9.8c0.3,1,1.1,1.5,2.2,1.2h1v-10.7h6.2c-0.6-1.1-1.5-1.5-3-1.5h-5.4L142.1,341.5L142.1,341.5z"
          />
          <path
            fill="none"
            d="M150.4,343.2h-6.2v10.7h4.4c1.5-0.3,2.3-1.9,2.2-5v-3.3C150.9,344.7,150.8,343.9,150.4,343.2L150.4,343.2z
	 M148.8,359.8c-0.6,0-1,0.4-1.4,1.2c-0.4,0.8-0.7,2.1-1,3.9c-0.3,2.3-0.4,5-0.3,7.8c0,1.5,0.1,3,0.3,4.5c0.3,2.9,0.7,5.6,1.2,8
	c0.3,1.5,0.7,3,1.1,4.4l0.1,0.4c1.1,3.2,2.1,4.8,3.2,4.8c1,0,1.6-1.5,2.2-4.4l-0.6-1.2l-0.3-0.8c-0.7-2.2-1.1-4.8-1.4-7.8
	c-0.3-3.3-0.3-6.1,0-8.4v-0.3c0.1-1,0.3-1.8,0.6-2.3l0.4-0.5l-0.8-3.3l-0.3-0.8C150.9,361.5,149.9,359.8,148.8,359.8L148.8,359.8z"
          />
          <path
            fill="none"
            d="M152.1,371.9v0.3c-0.3,2.3-0.3,5.1,0,8.4c0.3,3,0.8,5.6,1.4,7.8l0.3,0.8l0.6,1.2l0.1-0.7
	c0.4-3.4,0.4-7.6,0-12.4c-0.3-3-0.7-5.9-1.2-8.4l-0.4,0.5C152.6,370.1,152.3,370.8,152.1,371.9L152.1,371.9z M145,373.9l-0.1-0.1
	l-0.4-1.4c-0.1,0.4-0.3,1-0.3,1.5c-0.1,1.6-0.1,3.7,0,6.2c0.3,2.5,0.6,4.5,1.1,6.2c0.1,0.7,0.4,1.2,0.6,1.6l0.3-1.6
	c0.3-1.6,0.3-3.7,0-6.2C145.8,377.7,145.4,375.6,145,373.9L145,373.9z"
          />
          <path
            fill="none"
            d="M144,373.9c0.1-0.7,0.1-1.2,0.3-1.5c-0.3-0.7-0.6-1-1-1c-0.1,0-0.3,0.1-0.6,0.4c-0.3,0.4-0.4,1.1-0.6,2.2
	c-0.3,1.6-0.3,3.7,0,6.2c0.1,2.5,0.6,4.5,1,6.2c0.6,1.6,1,2.6,1.5,2.6c0.3,0,0.4-0.3,0.6-0.7l0.1-0.3c-0.1-0.4-0.4-1-0.6-1.6
	c-0.4-1.6-0.8-3.7-1-6.2C143.9,377.7,143.9,375.6,144,373.9L144,373.9z"
          />
          <path
            fill="none"
            d="M142.2,208.8h-4.7c-0.8,4-1.5,9.4-2.5,16.1l-0.8,7.3c-0.3,3.2-0.6,6.6-0.8,10.2l-0.8,12.9
	c-0.7,13.2-1.1,26.7-1.2,40.6v6.3c0.1,12.8,0.6,25.2,1.1,37.4l1,16c0.3,3.7,0.6,7,0.8,10.2c0.3,2.6,0.6,5.1,0.8,7.4
	c0.8,6.6,1.6,12,2.5,16.1h4.7c-1-4-1.9-9.4-2.9-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.6-6.6-0.8-10.2l-1-16
	c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.4,1.2-40.6l0.8-12.9c0.3-3.7,0.6-7,0.8-10.2c0.3-2.6,0.6-5.1,0.8-7.3
	C140.3,218.1,141.3,212.8,142.2,208.8L142.2,208.8z"
          />
          <path
            fill="none"
            d="M140.9,224.7c0.8-6.6,1.9-12,3.2-16.1h-1.8c-1,4-1.9,9.4-2.8,16.1c-0.3,2.3-0.6,4.7-0.8,7.3
	c-0.3,3.2-0.6,6.6-0.8,10.2l-0.8,12.9c-0.7,13.2-1.1,26.7-1.2,40.6v6.3c0.1,12.8,0.6,25.2,1.1,37.4l1,16c0.3,3.7,0.6,7,0.8,10.2
	c0.3,2.6,0.6,5.1,0.8,7.4c0.8,6.6,1.8,12,2.8,16.1h1.8c-1.2-4-2.3-9.4-3.2-16.1c-0.3-2.3-0.6-4.8-0.8-7.4c-0.3-3.2-0.7-6.6-1-10.2
	c-0.3-4.3-0.6-8.5-0.8-12.9l-0.1-3c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.2,1.2-40.6c0.3-4.4,0.4-8.7,0.8-12.9
	c0.3-3.7,0.6-7,1-10.2C140.3,229.5,140.6,227.1,140.9,224.7L140.9,224.7z M317.2,162.1c1,3.9,1.9,8.3,3,13.2l17.9-2.8l7.2-1.1
	l0.1-0.1c0.3-0.1,0.4-0.3,0.7-0.3h0.3l0.1,0.1l1.1-0.1h2.8l-0.3-0.3c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4
	h2.6c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1s-0.1,0.7-0.4,1l-0.3,0.3l4.7-0.1c0.3-1,1.4-1.8,3-2.5l3.3-7.6L317.2,162.1
	L317.2,162.1z"
          />
          <path
            fill="none"
            d="M320.1,175.3l-13.3,1.9l1.1,9.8c0.8,29.6,1.2,57.1,1,82.5v69.9c0,22.3-0.4,46.1-1.1,71.3l-1.1,9.8l13.3,1.9
	l17.9,2.8c1.5-1.6,2.3-6.2,2.5-13.6l0.1-12.9l0.7-59.3l-0.7-140.5l-0.1-12.9c-0.1-7.4-1-11.8-2.5-13.6L320.1,175.3L320.1,175.3z
	 M367.4,152.1c-0.5,1.1-0.5,2.2,0.1,3.2l3.2,4.3l12-32.5c-1.2,0.1-2.6,0.6-4.1,1.2h-0.1l-0.1,0.1l-1.9,1c-0.7,0.3-1.2,1-1.8,1.9
	L367.4,152.1L367.4,152.1z M350.9,168.5c-0.3,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1l0.3,0.3h2.6l-0.3-0.3
	c-0.3-0.3-0.4-0.6-0.4-1c0-0.3,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,1-0.4L350.9,168.5L350.9,168.5z"
          />
          <path
            fill="none"
            d="M347.6,171.1l-1.1,0.1h0.1l0.1,0.1l0.3,0.4v0.1l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.3-0.1-0.3-0.1-0.4-0.3
	c0-0.1,0.1-0.3,0.3-0.4l-7.2,1.1c1.5,1.7,2.3,6.2,2.5,13.6l0.1,12.9h5.6v-2.1c-0.3-8.7,0.1-13.3,1.2-14l5.4,1.1c1.4-5,3.9-8,7.3-8.9
	l-1.4-0.3v0.1l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.3,0.1-0.4,0.3-0.6l-3-0.8h0.1c0.1,0.1,0.3,0.3,0.3,0.6
	l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.3,0.1-0.4,0.3-0.6c0.3-0.1,0.5-0.3,0.8-0.3h0.3c-2.2-0.8-2.9-1.6-2.1-2.5
	h-1.4L347.6,171.1L347.6,171.1z M346.5,173.4c0-0.3,0.1-0.4,0.3-0.6c0.3-0.1,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3
	c0.3,0.1,0.3,0.4,0.3,0.6c0,0.1-0.1,0.3-0.3,0.3l-0.8,0.1l-0.8-0.1C346.7,173.7,346.7,173.6,346.5,173.4L346.5,173.4z M343.5,183.9
	c-0.1,0.3-0.3,0.3-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.3-0.8c0.3-0.1,0.5-0.3,0.8-0.3
	c0.1,0,0.3,0.1,0.4,0.3l0.1,0.8L343.5,183.9z M343.5,193c-0.1-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3
	l0.4,0.3l0.1,0.8l-0.1,0.7c-0.1,0.3-0.3,0.3-0.4,0.3C343.9,193.4,343.6,193.2,343.5,193L343.5,193z"
          />
          <path
            fill="none"
            d="M346.9,172.9c-0.3,0.1-0.3,0.3-0.3,0.6c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1c0.3-0.1,0.3-0.1,0.3-0.3
	c0-0.3-0.1-0.4-0.3-0.6c-0.3-0.1-0.5-0.3-0.8-0.3C347.4,172.6,347.1,172.7,346.9,172.9L346.9,172.9z M343.1,184.1
	c0.1,0,0.3-0.1,0.4-0.3l0.1-0.7l-0.1-0.8c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.6-0.3,0.8
	s0.1,0.6,0.3,0.7C342.5,184.1,342.8,184.1,343.1,184.1z M343.2,192.3c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,0.5,0.3,0.8,0.3
	c0.1,0,0.3-0.1,0.4-0.3l0.1-0.7l-0.1-0.8l-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3C343.2,191.7,343.2,192,343.2,192.3L343.2,192.3z
	 M346.7,171.2h-0.1l-0.1-0.1h-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.4s0.1,0.3,0.4,0.3l0.8,0.1l0.8-0.1
	l0.3-0.3v-0.1l-0.3-0.4L346.7,171.2L346.7,171.2z M370.9,159.5l0.4,0.6l8.3,3.9c0.8,0.3,1.4-0.1,1.5-1l0.8-2.6l9.9-29.6
	c0.4-1,0.1-1.5-0.7-1.8l-7.2-1.7h-1.2L370.9,159.5L370.9,159.5z"
          />
          <path
            fill="none"
            d="M371.3,160.1l-0.4-0.6l-3.2-4.3l-1.9,4.4l9.5,3.3l0.1-0.8l-0.1,0.8l-0.8,6.6c0.5,0.1,1,0.6,1.2,1l78.7-1.6
	c0.3-1.5,0.8-2.9,1.5-3.9c7.2-0.6,16.2-0.7,27.2-0.1c0.1-0.3,0.3-0.4,0.5-0.4h6.5c0.3,0,0.5,0.1,0.5,0.6v3.2h1.5
	c-0.7-0.3-0.5-2.3,0.6-5.9l1.9-5.1l-112.3,3.3l-0.8,2.6c-0.1,1-0.7,1.2-1.5,1L371.3,160.1L371.3,160.1z"
          />
          <path
            fill="none"
            d="M365.6,159.7l-0.4,1l-3.3,7.6l-0.7,1.5c1.5-0.8,3.4-1.2,5.6-1.4c3.2,0,5.5,0.7,6.9,2.1h0.3l0.1-1l0.8-6.6
	L365.6,159.7L365.6,159.7z M353.5,168.5c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,0.8c0,0.4,0.1,0.7,0.4,1l0.3,0.3h1.2l0.3-0.3
	c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1C354.2,168.6,354,168.5,353.5,168.5L353.5,168.5z"
          />
          <path
            fill="none"
            d="M359.7,170.8l1.5-1l0.7-1.5c-1.6,0.7-2.8,1.5-3,2.5c-0.1,0.3-0.1,0.7-0.1,1C359,171.4,359.3,171.1,359.7,170.8
	L359.7,170.8z"
          />
          <path
            fill="none"
            d="M361.2,169.8l-1.5,1c-0.4,0.3-0.7,0.7-1,1l-0.3,0.3l16.1-0.6l-0.5-0.8l-0.3-0.3c-1.4-1.4-3.7-2.1-6.9-2.1
	C364.5,168.6,362.8,169,361.2,169.8L361.2,169.8z"
          />
          <path
            fill="none"
            d="M358.5,172l0.3-0.3c0-0.3,0-0.7,0.1-1l-4.7,0.1l-0.7,0.1l-0.5-0.1h-1.2c-1,1-0.3,1.8,2.1,2.5l0.4,0.1l3,0.8
	c0.3-0.1,0.4-0.3,0.7-0.3h0.3l0.4,0.1l0.1,0.1l0.3,0.4l1.4,0.3l28.9,6.9c1.5,0.3,2.3,1.1,2.6,2.3c0.8-1,2.1-1.4,3.7-1.5l102.3-1.6
	l-0.7-11.7l-0.1-1.6h-6.7v0.1c0,0.3-0.1,0.6-0.6,0.6h-6.5c-0.3,0-0.5-0.1-0.5-0.6l-21.2,0.4l-0.3,0.7h-7.3l0.1-0.6l-78.7,1.9
	c0.1,0.3,0.3,0.6,0.3,1l-1.2,0.1L358.5,172L358.5,172z M485.2,176.7c0.7,1.6,0.1,2.6-1.5,3l-86.5,1.5h-0.3c-1.9-0.1-4-0.6-5.9-1.4
	l-7.3-2.1l-8.4-2.8l-5.9-1.9l109.4-1.8c3.4,0.1,5.5,1.1,6.1,3L485.2,176.7L485.2,176.7z"
          />
          <path
            fill="none"
            d="M483.7,179.7c1.6-0.3,2.2-1.4,1.5-3l-0.5-2.5c-0.5-1.9-2.5-2.9-5.9-3l-109.4,1.9l5.9,1.9h105.6
	c1.9,0,2.6,0.7,2.2,2.1L483.7,179.7L483.7,179.7z"
          />
          <path
            fill="none"
            d="M483.1,177.1c0.4-1.4-0.4-2.1-2.2-2.1H375.3l8.4,2.8l7.3,2.1c1.9,0.7,4,1.2,5.9,1.4h0.3l86.5-1.5L483.1,177.1
	L483.1,177.1z M357.8,174.2c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.1-0.3,0.3-0.3,0.6c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1l0.3-0.3v-0.1
	l-0.3-0.4l-0.1-0.1l-0.4-0.1L357.8,174.2L357.8,174.2z M353,170.9l0.5,0.1l0.7-0.1 M353.4,173.4c-0.3,0-0.5,0.1-0.8,0.3
	s-0.3,0.3-0.3,0.6c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1l0.3-0.3c0-0.3-0.1-0.4-0.3-0.6h-0.1l-0.4-0.1L353.4,173.4L353.4,173.4z
	 M346.4,199h-2.6l0.7,61.6l-0.7,138.1h2.6l0.7-59.2"
          />
          <path
            fill="none"
            d="M346.4,196.9v2.1l0.7,140.5l-0.7,59.3v2.1c-0.3,8.7,0.1,13.3,1.2,14l5.4-1.1l24.5-5.8c2.8-0.5,4.1-3.3,4.1-8.4
	V198.2c0-5.1-1.4-7.8-4.1-8.4l-24.5-5.6l-5.4-1.1C346.5,183.7,346.1,188.4,346.4,196.9L346.4,196.9z M343.8,199h-3.2l0.7,140.6
	l-0.7,59.2h3.2l0.7-138.1"
          />
          <path
            fill="none"
            d="M360.3,175.1c-3.6,1.1-5.9,4-7.3,8.9l24.5,5.8c2.8,0.6,4.1,3.3,4.1,8.4v201.4c0,5.1-1.4,7.8-4.1,8.4l-24.5,5.8
	c1.4,5,3.9,8,7.3,8.9l28.9-6.9c1.5-0.3,2.3-1.1,2.6-2.3c-0.7-0.8-1.1-1.9-1.1-3.4c-0.4-11.6-0.5-24.1-0.5-37.6V225.4
	c-0.1-13.5,0.1-26,0.5-37.6c0-1.5,0.4-2.6,1.1-3.4c-0.1-1.2-1.1-2.1-2.6-2.3L360.3,175.1L360.3,175.1z M455.7,164.9
	c-0.7,1.1-1.1,2.3-1.5,3.9l-0.1,0.6h7.3l0.3-0.7l0.1-0.1c6.6-0.6,13.6-0.7,21-0.4h1.4v-3.3h-1.4
	C471.8,164.2,462.8,164.3,455.7,164.9L455.7,164.9z"
          />
          <path
            fill="none"
            d="M461.8,168.5l-0.1,0.1l21.2-0.4v-0.1C475.4,167.8,468.4,167.9,461.8,168.5L461.8,168.5z"
          />
          <path
            fill="none"
            d="M485,164.3h-1.7c-0.3,0-0.4,0.1-0.5,0.4h1.4v3.3h-1.4v0.1c0,0.3,0.1,0.6,0.5,0.6h1.7L485,164.3L485,164.3z
	 M485,164.3v4.3h4.8c0.3,0,0.5-0.1,0.5-0.6v-3.2c0-0.3-0.1-0.6-0.5-0.6L485,164.3L485,164.3z M529.6,140.1h-7.8
	c-4.4-0.6-8.3-0.3-11.4,0.8c-7.1,1.5-11.4,3.9-12.9,7.2l-3.3,8.8l-1.9,5.1c-1.1,3.7-1.2,5.6-0.5,5.9h5.2c10.9-0.4,21.7-0.7,32.9-0.7
	h92l34.5,0.7l15,0.8h0.3c2.5-0.6,3.6-1.6,3.4-3l-4.1-14.9c-1-3.6-2.2-5.8-3.7-6.9c-0.3-0.1-0.4-0.3-0.7-0.4l-3.3-1.2
	c-4.1-1.5-7.6-2.2-10-2.3h-8.5L529.6,140.1L529.6,140.1z M519.6,169c-7.6,0.1-15,0.3-22.4,0.6l0.7,11.7h2.3
	c56.1-1,111-0.7,164.9,0.8h1.4l9.8-10.7c-1.4-0.4-2.9-0.7-4.7-0.8h-0.3l-13.6-0.8l-35.9-0.7L519.6,169L519.6,169z M516.6,179.7
	c-2.2-0.3-3.3-1-3.3-2.1v-4.1c0-1.1,1.2-2.1,3.7-2.6l15.1-0.6h89.7l27.8,0.6c2.6,0.1,3.2,0.8,1.8,2.1l-3.7,4.7
	c-0.7,1.5-1.8,2.3-3.4,2.3C602.4,179.3,559.7,179.2,516.6,179.7L516.6,179.7z"
          />
          <path
            fill="none"
            d="M513.2,177.8c0,1.1,1.1,1.8,3.3,2.1c43.2-0.6,85.8-0.4,127.8,0.6c1.7,0,2.8-0.8,3.4-2.3l3.7-4.7
	c1.4-1.4,0.7-2.1-1.8-2.1l-27.9-0.7h-89.7l-15.1,0.6c-2.5,0.6-3.7,1.5-3.7,2.6L513.2,177.8L513.2,177.8z M374.2,170.4l-0.4,0.1
	l0.3,0.3"
          />
          <path
            fill="none"
            d="M374.3,169.4l-0.1,1l-0.1,0.3l0.5,0.8l1.2-0.1c0-0.4-0.1-0.7-0.3-1C375.3,170,374.9,169.7,374.3,169.4
	L374.3,169.4z M395.5,182.9c-1.6,0-3,0.6-3.7,1.5c-0.7,0.8-1.1,1.9-1.1,3.4c-0.4,11.6-0.5,24.1-0.5,37.6v147.1
	c-0.1,13.5,0.1,26,0.5,37.6c0,1.5,0.4,2.6,1.1,3.4c0.8,1,2.1,1.4,3.7,1.5l102.3,1.6h2.3V181.3h-2.3L395.5,182.9L395.5,182.9z"
          />
          <path
            fill="none"
            d="M500.2,206.6v6.6l165.1-1.2v-6.9 M500.2,181.3v25.3l165.1-1.6v-9.8c-0.1-7.8,0.3-12.1,1.2-13.1h-1.4
	C611.2,180.6,556.3,180.3,500.2,181.3L500.2,181.3z M500.2,241.8v6.5h165.1v-6.5 M500.2,248.3v29h165.1v-29"
          />
          <path
            fill="none"
            d="M500.2,213.2v28.6h165.1v-29.9 M644.5,140.1c-0.5-1.9-3-2.9-7.8-2.9h-94.9c-6.5-0.3-10.6,0.7-12.2,2.9H644.5
	L644.5,140.1z M674.9,165.9c0.3,1.5-1,2.5-3.4,3c6.1,0.7,10.3,1.8,12.8,3.3c0.8,0.6,1.5,1.2,2.2,2.1c0.6-1.5,1.9-2.1,4-1.8
	C689.7,168.1,684.7,165.9,674.9,165.9L674.9,165.9z M681.1,201.5l-0.3,0.8c-0.1,1.1-0.3,2.1-0.4,3.2v5.8h7l-0.3-10.3
	c-0.4-2.1-1.9-2.5-4.7-1.1C681.8,200.4,681.4,200.8,681.1,201.5L681.1,201.5z"
          />
          <path
            fill="none"
            d="M666.5,182.1c-0.8,1-1.2,5.2-1.2,13.1v207.7c-0.1,7.8,0.3,12.1,1.2,13.1c6.3-2.3,9.6-8.1,10-17.2V199.3
	C676.1,190.2,672.8,184.4,666.5,182.1L666.5,182.1z M678.7,227.1v1c0.7,0,1.2,0.1,1.7,0.4v-1.4L678.7,227.1L678.7,227.1z
	 M680.4,211.4v17.1c0.5,0.3,1,0.8,1.1,1.6v5.2c-0.1,0.6-0.4,1.1-1.1,1.4v125c0.5,0.3,1,0.8,1.1,1.6v5.2c-0.1,0.5-0.4,1.1-1.1,1.4
	v16.8h7V211.4H680.4z"
          />
          <path
            fill="none"
            d="M680.4,228.3c-0.4-0.3-1-0.4-1.7-0.4v8.9c0.7-0.1,1.2-0.1,1.7-0.4c0.7-0.3,1.1-0.7,1.1-1.4v-5.2
	C681.2,229.3,680.9,228.7,680.4,228.3L680.4,228.3z M678.7,237.8h1.7v-1.2c-0.4,0.1-1,0.3-1.7,0.4V237.8L678.7,237.8z M665.3,277.3
	H500.2v6.6h165.1 M665.3,320.5V314H500.2v6.5"
          />
          <path
            fill="none"
            d="M665.3,314v-30.1H500.2V314 M680.4,361.5v-1.4h-1.7v1C679.4,361.2,680,361.2,680.4,361.5z"
          />
          <path
            fill="none"
            d="M681.5,363.1c-0.1-0.8-0.5-1.4-1.1-1.6c-0.4-0.3-1-0.4-1.7-0.4v8.9c0.7-0.1,1.2-0.1,1.7-0.4
	c0.7-0.3,1.1-0.7,1.1-1.4V363.1L681.5,363.1z M678.7,370.1v0.8h1.7v-1.2C680,370,679.4,370,678.7,370.1L678.7,370.1z M665.3,356.1
	v-6.6H500.2v6.6 M665.3,349.5v-29H500.2v29 M681.1,396.4c0.3,0.5,0.7,1.1,1.2,1.6c2.8,1.2,4.3,0.8,4.7-1.1l0.3-10.3h-7v5.8
	c0.1,1.1,0.3,2.2,0.4,3.2L681.1,396.4L681.1,396.4z"
          />
          <path
            fill="none"
            d="M687.3,386.5l-0.3,10.3c-0.4,2.1-1.9,2.5-4.7,1.1c1.2,1.2,3,2.2,5.4,2.8c1.4,0.3,1.9-0.7,1.8-3v-8.9
	L687.3,386.5L687.3,386.5z M665.3,392.8v-7l-165.1-1.1v6.6"
          />
          <path
            fill="none"
            d="M666.5,415.8c-0.8-1-1.2-5.2-1.2-13.1v-9.9l-165.1-1.5v25.3c56.1,1.1,111,0.8,164.9-0.8L666.5,415.8
	L666.5,415.8L666.5,415.8z"
          />
          <path
            fill="none"
            d="M666.5,415.8h-1.4c-53.9,1.6-108.8,1.9-164.9,0.8h-2.3l-0.7,11.7c7.4,0.3,14.9,0.5,22.4,0.5h102.2l35.8-0.7
	l13.6-0.8h0.3c1.8-0.3,3.3-0.5,4.7-0.8L666.5,415.8L666.5,415.8z M644.3,417.5c1.7,0,2.8,0.8,3.4,2.3l3.7,4.7
	c1.4,1.4,0.7,2.1-1.8,2.2l-27.8,0.6h-92.4l-12.5-0.5c-2.5-0.6-3.7-1.5-3.7-2.6v-4.1c0-1.1,1.1-1.8,3.3-1.9
	C559.7,418.7,602.4,418.4,644.3,417.5L644.3,417.5z"
          />
          <path
            fill="none"
            d="M647.8,419.9c-0.7-1.5-1.8-2.3-3.4-2.3c-42,1-84.6,1.1-127.8,0.5c-2.2,0.3-3.3,0.8-3.3,1.9v4.1
	c0,1.1,1.2,1.9,3.7,2.6l12.5,0.5h92.3l27.8-0.5c2.6-0.1,3.2-0.8,1.8-2.2L647.8,419.9L647.8,419.9z M636.6,460.5c4.7,0,7.3-1,7.8-2.9
	H529.6c1.7,2.2,5.8,3.2,12.2,2.9H636.6L636.6,460.5z"
          />
          <path
            fill="none"
            d="M671.6,428.9l-0.3,0.1l-15,0.8l-34.5,0.7H530c-11.1,0-22.1-0.1-32.9-0.7h-5.2c-0.7,0.3-0.5,2.3,0.6,5.9l1.9,5.1
	l3.3,8.8c1.4,3.3,5.8,5.6,12.9,7.2c3.2,1.1,7,1.4,11.4,0.8h131.2c2.6-0.1,5.9-0.8,10-2.3l3.3-1.2l0.8-0.4c1.5-1,2.8-3.3,3.7-6.9
	l4.1-14.9C675.2,430.5,674.1,429.4,671.6,428.9L671.6,428.9z"
          />
          <path
            fill="none"
            d="M684.4,425.6c-2.5,1.5-6.7,2.6-12.8,3.3c2.5,0.5,3.6,1.6,3.4,3c9.6,0.1,14.9-2.2,15.5-6.6
	c-2.1,0.3-3.3-0.4-4-1.8C685.9,424.3,685.2,425,684.4,425.6L684.4,425.6z"
          />
          <path
            fill="none"
            d="M674.9,431.9l-4.1,14.9c-1,3.6-2.2,5.8-3.7,6.9l-0.8,0.4l-3.3,1.2c13.3-1.9,27.2-4.8,41.5-8.9
	c7-2.9,11.3-8.9,12.7-18.2l2.2-14.9h-22.7v9.8c0,1.2-0.5,2.1-1.7,2.2h-4.7C689.7,429.8,684.7,432,674.9,431.9L674.9,431.9z
	 M665.3,385.8v-29.7H500.2v28.6 M395.5,415c-1.6-0.1-3-0.5-3.7-1.5c-0.1,1.2-1,2.1-2.6,2.3l-28.9,6.9l-1.4,0.3l-0.3,0.4l-0.1,0.1
	l-0.4,0.1h-0.3c-0.3,0-0.5-0.1-0.7-0.3l-1.6,0.4c-0.4,0.1-1,0.3-1.2,0.4l-0.4,0.1c-2.2,0.8-2.9,1.7-2.1,2.5h1.2l0.5-0.1l0.7,0.1
	l4.7,0.1c-0.1-0.3-0.1-0.6-0.1-1l-0.3-0.3l16.1,0.5l1.2,0.1c0,0.4-0.1,0.7-0.3,1l78.7,1.6l-0.1-0.5h7.3l0.3,0.7l21.2,0.4
	c0-0.3,0.1-0.5,0.5-0.5h6.5c0.3,0,0.5,0.1,0.5,0.5v0.1h6.7l0.1-1.6l0.7-11.7L395.5,415L395.5,415z M375.3,422.8l8.4-2.8l7.3-2.1
	c1.9-0.7,4-1.1,5.9-1.4h0.3l86.5,1.5c1.6,0.3,2.2,1.4,1.5,3l-0.5,2.5c-0.5,1.9-2.5,2.9-5.9,3l-109.4-1.8L375.3,422.8L375.3,422.8z"
          />
          <path
            fill="none"
            d="M383.7,420.1l-8.4,2.8h105.6c1.9,0,2.6-0.7,2.2-2.2l0.5-2.6l-86.5-1.5h-0.3c-1.9,0.1-4,0.5-5.9,1.4L383.7,420.1
	L383.7,420.1z"
          />
          <path
            fill="none"
            d="M485.2,421c0.7-1.6,0.1-2.8-1.5-3l-0.5,2.6c0.4,1.4-0.4,2.1-2.2,2.2H375.3l-5.9,1.9l109.4,1.8
	c3.4-0.1,5.5-1.1,6.1-3L485.2,421L485.2,421z M374,427.1l0.1,0.3l0.1,1c0.5-0.1,1-0.5,1.2-1c0.1-0.3,0.3-0.5,0.3-1l-1.2-0.1
	L374,427.1L374,427.1z"
          />
          <path
            fill="none"
            d="M374.2,427.4l-0.1-0.3l-0.3,0.3 M374.3,428.3l-0.1-1h-0.3c-1.4,1.4-3.7,2.1-6.9,2.1c-2.3-0.1-4.1-0.5-5.6-1.4
	l0.7,1.5l3.3,7.6l0.4,1.1l9.5-3.3L374.3,428.3L374.3,428.3z"
          />
          <path
            fill="none"
            d="M375.5,427.4c-0.3,0.4-0.7,0.8-1.2,1l0.8,6.6l0.1,0.8l-0.1-0.8l-9.5,3.3l1.9,4.4l0.7-0.8l2.5-3.4l0.4-0.6
	l8.3-3.7c0.8-0.3,1.4,0.1,1.5,1l0.8,2.6L494,441l-1.9-5.1c-1.1-3.7-1.2-5.6-0.5-5.9H490v3.2c0,0.3-0.1,0.5-0.5,0.5H483
	c-0.3,0-0.4-0.1-0.5-0.4c-11,0.5-20.1,0.4-27.2-0.1c-0.7-1.1-1.1-2.3-1.5-3.9L375.5,427.4L375.5,427.4z M374,427.1l0.5-0.8
	l-16.1-0.5l0.3,0.3c0.3,0.4,0.7,0.7,1,1l1.5,1c1.5,0.8,3.4,1.2,5.6,1.4c3.2,0,5.5-0.7,6.9-2.1L374,427.1L374,427.1z"
          />
          <path
            fill="none"
            d="M381,434.9c-0.1-1-0.7-1.2-1.5-1l-8.3,3.7l-0.4,0.5l12,32.5h1.2l7.2-1.6c0.8-0.3,1.1-0.8,0.7-1.8l-9.9-29.6
	L381,434.9L381,434.9z"
          />
          <path
            fill="none"
            d="M378.7,469.5c1.5,0.8,2.9,1.2,4.1,1.2l-12-32.5l-2.5,3.4l-0.7,0.8c-0.7,1-0.7,2.1-0.1,3.2l7.2,20.6
	c0.4,1,1.1,1.7,1.8,1.9l1.9,1L378.7,469.5L378.7,469.5z M490.4,429.8v-0.1c0-0.3-0.1-0.5-0.5-0.5h-4.8v4.3h4.8
	c0.3,0,0.5-0.1,0.5-0.5L490.4,429.8L490.4,429.8z M484.2,429.8h-1.4c-7.4,0.3-14.4,0.1-21-0.4l-0.1-0.1l-0.3-0.7h-7.3l0.1,0.5
	c0.3,1.5,0.8,2.9,1.5,3.9c7.2,0.5,16.2,0.7,27.2,0.1h1.4v-3.3H484.2L484.2,429.8z"
          />
          <path
            fill="none"
            d="M482.9,429.8h1.4v3.3h-1.4c0.1,0.3,0.3,0.4,0.5,0.4h1.7v-4.3h-1.7C483.1,429.3,482.8,429.4,482.9,429.8
	L482.9,429.8z"
          />
          <path
            fill="none"
            d="M482.9,429.8v-0.1l-21.2-0.4l0.1,0.1C468.4,430,475.4,430.1,482.9,429.8L482.9,429.8z M258.5,460.5
	c5.4,0,8.4-1.4,9.2-4.3H156.1c-0.3,0.3-0.8,0.3-1.5,0.3s-1.5-0.1-2.3-0.3h-2.3c1.1,2.9,5,4.3,11.3,4.3L258.5,460.5L258.5,460.5z
	 M343.8,398.8h-3l-0.1,12.9c-0.1,7.4-1.1,11.8-2.5,13.6l7.2,1.1c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.1,0.1-0.3,0.4-0.3l0.8-0.1l0.8,0.1
	c0.1,0.1,0.3,0.1,0.3,0.3v0.1l-0.3,0.4l-0.1,0.1h-0.1l1.1,0.1h4.1c-1-1-0.3-1.8,2.1-2.5h-0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1l0.8,0.1c0.1,0.1,0.3,0.1,0.3,0.3c0,0.3-0.1,0.4-0.3,0.5l-0.1,0.1l1.2-0.4
	l1.6-0.4c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1l0.8,0.1c0.1,0.1,0.3,0.1,0.3,0.3v0.1l1.4-0.3
	c-3.6-1.1-5.9-4-7.3-8.9l-5.4,1.1c-1.1-0.7-1.5-5.4-1.2-14v-2.1L343.8,398.8L343.8,398.8L343.8,398.8z M343.5,406.3
	c-0.1-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.3,0.8-0.3c0.1,0,0.3,0.1,0.4,0.3l0.1,0.7l-0.1,0.8l-0.4,0.3
	C343.9,406.7,343.6,406.6,343.5,406.3L343.5,406.3z M343.6,414.7l-0.1,0.8c-0.1,0.1-0.3,0.3-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.1-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.4,0.8-0.4c0.1,0,0.3,0.1,0.4,0.4L343.6,414.7L343.6,414.7z
	 M348.9,424.5c0,0.3-0.1,0.4-0.3,0.5c-0.3,0.1-0.5,0.3-0.8,0.3l-0.8-0.3c-0.3-0.1-0.3-0.4-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1
	l0.8,0.1C348.7,424.2,348.9,424.3,348.9,424.5L348.9,424.5z"
          />
          <path
            fill="none"
            d="M343.2,405.6c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3l0.4-0.3l0.1-0.8l-0.1-0.7
	c-0.1-0.3-0.3-0.3-0.4-0.4c-0.3,0-0.5,0.1-0.8,0.4C343.2,405.1,343.2,405.4,343.2,405.6L343.2,405.6z M343.5,415.4l0.1-0.8l-0.1-0.7
	c-0.1-0.3-0.3-0.3-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3
	C343.2,415.8,343.4,415.7,343.5,415.4L343.5,415.4z M348.5,425c0.3-0.1,0.3-0.4,0.3-0.5c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1
	c-0.3,0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.4,0.3,0.5l0.8,0.3C348,425.2,348.3,425.2,348.5,425L348.5,425z M352.6,423.2
	c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.4,0.3,0.6c0.3,0.1,0.5,0.3,0.8,0.3h0.3l0.4-0.1l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5
	c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1L352.6,423.2L352.6,423.2z M357.1,423.4c0.3,0.1,0.5,0.3,0.8,0.3h0.3l0.4-0.1l0.1-0.1l0.3-0.4v-0.1
	c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.1,0.1-0.3,0.1-0.3,0.3C356.7,423.1,356.8,423.2,357.1,423.4L357.1,423.4z M358.8,426.1
	c0,0.3,0,0.7,0.1,1c0.3,1,1.4,1.8,3,2.5l-0.7-1.5l-1.5-1C359.3,426.8,359,426.4,358.8,426.1L358.8,426.1z M353.5,426.8L353,427h1.2"
          />
          <path
            fill="none"
            d="M353,427h-2.6l-0.3,0.4c-0.3,0.3-0.4,0.5-0.4,1s0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4h2.6
	c-0.3,0-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1C352.7,427.1,352.8,427,353,427L353,427z"
          />
          <path
            fill="none"
            d="M353.5,429.4h-2.6c-0.3,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1s0.1-0.7,0.4-1l0.3-0.3h-2.8l-1.1-0.1l-0.1,0.1
	H346l-0.7-0.3l-0.1-0.1l-7.2-1.1l-17.9-2.8c-1,4.8-1.9,9.4-3,13.2l48,1.4l-3.3-7.6c-1.6-0.7-2.8-1.5-3-2.5l-4.7-0.1l0.3,0.3
	c0.3,0.3,0.4,0.5,0.4,1c0,0.4-0.1,0.7-0.4,1C354.2,429.3,354,429.4,353.5,429.4L353.5,429.4z"
          />
          <path
            fill="none"
            d="M353.5,429.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1l-0.3-0.3H353c-0.1,0.1-0.3,0.1-0.3,0.3
	c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,1C352.9,429.3,353.1,429.4,353.5,429.4L353.5,429.4z M346.5,426.7h0.1l0.1-0.1l0.3-0.4
	V426c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.3,0.1-0.3,0.1-0.4,0.3c0,0.1,0.1,0.3,0.3,0.4l0.1,0.1l0.7,0.3h0.3L346.5,426.7
	L346.5,426.7z M97.3,363.5l-1,0.7c-1.9,1.5-3,2.9-3,4.1l2.6,28.8c0.3,3.9,1.1,6.3,2.3,7.3l2.6,1.9c1.5,1,2.5,2.2,3,4.1
	c3.7,13.8,7.6,25.3,11.7,34.5c1,2.3,2.9,3.6,5.8,3.4h2.9c-1.9-0.1-3-1-3.3-2.6L108,408.5l-0.8-1.6c-0.4-0.7-1-1.4-1.5-2.1l-1.4-1.5
	l-0.1-0.3c-0.7-1.2-1.1-3.2-1.2-5.8l-2.6-28.8c0.1-1.2,1.1-2.9,3-5L97.3,363.5L97.3,363.5z M104.1,363.5l0.1-0.1 M104.3,363.4
	l-0.1,0.1h3.4"
          />
          <path
            fill="none"
            d="M104.3,363.4h-1.1c-1.9,1.9-2.9,3.6-3,5l2.6,28.8c0.1,2.6,0.6,4.5,1.2,5.8l23.9,0.4v-1l1.5-2.1l-1-32.7
	l-2.3-4.4h-3l-15.5,0.1C107.6,363.3,104.3,363.4,104.3,363.4z M126.1,363.3h-3"
          />
          <path
            fill="none"
            d="M130,367.8l-3.9-4.5l2.3,4.5l1,32.6l1.5-2.1l-0.1-3"
          />
          <path
            fill="none"
            d="M130.8,395.3l0.1,3l-1.5,2.1l-1.5,2.1v4.7l-0.8,2.2l5.5,34.8c0.7,2.9,0.1,4.4-1.8,4.3h-3.3
	c1.9,2.8,5.8,5.1,11.3,7.2l2.1,0.7h9.2l-1.8-0.7c-5.1-2.1-8-4.7-8.9-7.7c-2.8-11.6-4.8-25.7-6.2-42.4c-0.4-3.7,4.4-6.9,14.3-9.4
	c-0.6-0.4-1.1-0.8-1.6-1.4c-3.2-2.9-5.5-3.6-7.3-2.3L130.8,395.3L130.8,395.3z"
          />
          <path
            fill="none"
            d="M104,403l0.1,0.3l1.4,1.5c0.6,0.7,1.1,1.4,1.5,2.1l0.8,1.7l19,0.4l-19-0.4l12.9,37.3c0.3,1.6,1.4,2.6,3.3,2.6
	h6.6c1.9,0.1,2.6-1.4,1.8-4.3l-5.5-34.8l0.8-2.2v-3.7L104,403L104,403z M175.8,395c-0.3-0.1-0.4,0-0.6,0.1l-0.1,0.1l-0.3,1l-0.1,0.8
	l-0.3,0.8c-0.1,0.3,0,0.5,0.4,0.7l1.6,0.4l2.1,0.6l1.6,0.4c0.3,0.1,0.6,0,0.6-0.4l0.1-0.5l0.3-1l0.3-1.2c0-0.3-0.1-0.4-0.4-0.5
	L175.8,395L175.8,395z"
          />
          <path
            fill="none"
            d="M175.1,395.3l0.1-0.1l-0.4-0.1h-0.4c-0.1,0.1-0.3,0.1-0.3,0.3l-0.1,0.3c0,0.1,0,0.3,0.1,0.4l0.3,0.3l0.3,0.1
	L175.1,395.3L175.1,395.3z M174.7,397.1l-1,0.4l-0.8,2.2h0.4l1.2,0.4c0.3,0.1,0.4,0.3,0.4,0.7l-0.6,1.9h0.3l0.3-1l0.1-0.3l1.4-2.3
	l-1.6-0.4c-0.3-0.1-0.4-0.3-0.4-0.7L174.7,397.1L174.7,397.1z M181.7,397l-0.3-0.1l-0.3,1.2l0.3,0.1h0.4l0.3-0.3l0.1-0.3
	c0-0.1,0-0.3-0.1-0.4C182.1,397.1,182,397.1,181.7,397L181.7,397z M181,399l-0.1,0.5c-0.1,0.3-0.3,0.4-0.6,0.4l-1.6-0.4l-0.4,2.9
	l-0.1,0.7h0.3l0.4-1.4c0.1-0.3,0.3-0.4,0.6-0.4l1.2,0.4c0.1,0,0.3,0.1,0.4,0.3l0.6-2.3L181,399L181,399z"
          />
          <path
            fill="none"
            d="M178.6,399.6l-2.1-0.5l-1.4,2.3l-0.1,0.3l-0.3,1.1l-0.1,0.7v0.1c-0.1,0.3,0,0.4,0.4,0.5l2.1,0.6h0.4l0.3-0.3
	v-0.1l0.3-1l0.1-0.7L178.6,399.6L178.6,399.6z M180.9,402c-0.1-0.1-0.1-0.1-0.4-0.3l-1.2-0.4c-0.3-0.1-0.6,0-0.6,0.4l-0.4,1.4
	l-0.3,1l-0.1,0.5c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.4h0.4l0.3-0.3l0.6-1.9l0.3-1.1L180.9,402L180.9,402z"
          />
          <path
            fill="none"
            d="M178,403l-0.3,1.1h0.3l0.3-1 M178,404.1h-0.1l-0.4,1.6c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.4c0.3,0.1,0.6,0,0.6-0.4
	l0.1-0.5h-0.3l-1.2-0.4c-0.3-0.1-0.4-0.3-0.3-0.5L178,404.1L178,404.1z M174.8,402.6h-0.3l-0.1,0.7h0.1 M175,404
	c-0.3-0.1-0.4-0.3-0.4-0.5l-0.3,1c-0.1,0.3,0,0.5,0.3,0.5l1.9,0.5c0.3,0.1,0.6,0,0.6-0.3l0.1-0.7h-0.4L175,404L175,404z
	 M150.5,455.6l1.9,0.7c0.8,0.3,1.5,0.3,2.3,0.3c0.7,0,1.1-0.1,1.5-0.3l0.3-0.3l-0.1-0.3c-0.1-0.3-0.4-0.5-1-0.8
	c-0.8-0.5-1.8-1-3-1.4c-1.2-0.4-2.3-0.7-3.3-0.8c-1-0.1-1.5,0-1.5,0.3c-0.1,0.4,0.1,1,1,1.5C149,454.9,149.7,455.3,150.5,455.6
	L150.5,455.6z M695.8,289.1h-5.4v20.6h5.4v-2.2h-5h5v-16.2h-5h5"
          />
          <path
            fill="none"
            d="M695.8,288.4v22h2.2v-22 M698,285.3v28.5l2.2,0.7c3.7,0.8,7.3,0.7,10.6-0.7v-28.5c-3.3-1.2-6.9-1.5-10.6-0.5
	C699.5,284.7,698.8,285,698,285.3L698,285.3z"
          />
          <path
            fill="none"
            d="M695.8,310.4v-0.7h-5.4v62.6h3v-28.5c0.3-0.5,1-0.8,2.1-0.7h4.7v-29l-2.2-0.7v-3.2L695.8,310.4L695.8,310.4z"
          />
          <path
            fill="none"
            d="M695.5,343.3c-1-0.1-1.7,0.1-2.1,0.7v28.5h1c0.3,0,0.6,0.1,0.6,0.5v5.2c0,0.3-0.1,0.5-0.5,0.5h-1v0.6
	c0.1,4.7-0.1,7.7-0.7,9.2H720c-1-0.4-1.5-2.2-1.5-5.5v-21.2c-4.5-0.1-8.5-0.4-12.1-1.1c-4-1.2-6.1-3.7-6.5-7.6v-9.8h-4.4
	L695.5,343.3L695.5,343.3z"
          />
          <path
            fill="none"
            d="M693.6,372.3h-3v6.2h4c0.3,0,0.5-0.1,0.5-0.5v-5.2c0-0.3-0.1-0.5-0.5-0.5h-1L693.6,372.3L693.6,372.3z
	 M721.9,388.4h-31.5v0.8l3.3,1.4v6.7c0.1,2.8-0.7,4.3-2.3,4.5h3.9c1,0,1.5,0.5,1.5,1.5v9.9h25.2c0.7,0,1-0.3,1-1.1v-22.8
	C723,388.7,722.6,388.4,721.9,388.4L721.9,388.4z M693.6,378.7v0.5 M693.6,379.2v-0.5h-3v9.8h2.5
	C693.5,386.9,693.6,383.9,693.6,379.2L693.6,379.2z"
          />
          <path
            fill="none"
            d="M693.7,397.4v-6.7l-3.3-1.4v12.7h1C693,401.6,693.9,400.1,693.7,397.4L693.7,397.4z M690.4,401.9v8.4
	c-0.1,5.9-1.5,10.3-4,13.3c0.5,1.5,1.9,2.1,4,1.8h4.7v-23.5H690.4L690.4,401.9z"
          />
          <path
            fill="none"
            d="M696.8,403.4c0-1-0.5-1.5-1.5-1.5h-0.1v23.5c1.1-0.3,1.7-1,1.7-2.2L696.8,403.4L696.8,403.4L696.8,403.4z
	 M174,193.1c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.7c0-0.1,0.1-0.1,0.3-0.3l1.2-0.3h0.6L174,193.1
	L174,193.1z"
          />
          <path fill="none" d="M174,193.8l0.3,0.1l-0.1-0.1" />
          <path
            fill="none"
            d="M174.3,193.9l-0.1-0.1h-0.4l-1.2,0.3c-0.1,0-0.3,0.1-0.3,0.3v0.4l0.3,0.8l0.6,2.2c0,0.1,0.1,0.3,0.3,0.4h0.4
	l1.2-0.4c0.3-0.1,0.4-0.3,0.4-0.7l-0.6-1.9l-0.1-0.7L174.3,193.9L174.3,193.9z"
          />
          <path
            fill="none"
            d="M156.1,141.6l0.3,0.3c0.1,0.3-0.3,0.7-1.1,1.2c-0.8,0.6-1.8,1-3,1.4c-1.2,0.4-2.3,0.7-3.3,0.8
	c-1,0.1-1.5,0-1.5-0.3c-0.1-0.4,0.1-1,1-1.5s1.8-1.1,3-1.5l1-0.3h-2.5c-6.2,2.2-9.6,5-10.6,8.4c-1.9,8.3-3.4,17.6-4.7,28.3
	c-0.6,4.4-1,9.1-1.4,13.9c-0.4,3.9,4.4,6.9,14.3,9.4c0.8-0.6,1.6-1.1,2.6-1.6c1.8-1,4-1.8,6.5-2.5l15.7-2.1l-0.3-0.8v-0.4l-0.1-0.7
	c-0.1-0.3,0-0.6,0.3-0.6l1.2-0.3c0.3-0.1,0.6,0,0.6,0.3l0.1,0.7v0.1l0.1,0.7h0.1l-0.3-1.1c-0.1-0.3,0-0.6,0.3-0.6l1.9-0.6
	c0.3-0.1,0.6,0,0.6,0.4l0.1,0.7l0.3,0.3v0.1h0.1l-0.4-1.6c-0.1-0.3,0-0.6,0.3-0.6l1.2-0.4c0.3-0.1,0.6,0,0.6,0.4l0.1,0.6h0.1
	c0.1,0,0.1,0.1,0.3,0.3l0.6,1.9l76.6-10l49.5-7.2l13.3-1.9c-1-5-1.9-9.4-3-13.2l-1-3.7c-0.8-2.9-1.7-5.5-2.5-7.7
	c-1.4-3.6-4.4-5.8-9.2-6.6c-4.8-1.6-9.1-2.5-12.7-2.5L156.1,141.6L156.1,141.6L156.1,141.6z"
          />
          <path
            fill="none"
            d="M177.5,193.4H177l-2.1,0.6c-0.3,0.1-0.4,0.3-0.4,0.7l0.1,0.7l0.3,1.1l1.4,2.6l2.1-0.6l-0.4-2.9l-0.1-0.7l-0.3-1
	v-0.1L177.5,193.4L177.5,193.4z"
          />
          <path
            fill="none"
            d="M177.5,193.2l-0.1-0.7c-0.1-0.3-0.3-0.4-0.6-0.4l-1.9,0.6c-0.3,0.1-0.4,0.3-0.3,0.6l0.3,1.1
	c-0.1-0.3,0-0.6,0.4-0.7l2.1-0.6h0.1L177.5,193.2L177.5,193.2z M174.6,194.5l-0.1,0.1l0.1,0.6h0.3 M267.7,456.2h24.1
	c3.6,0,7.8-0.8,12.7-2.5c4.8-0.8,7.8-3,9.2-6.6c0.8-2.3,1.6-4.8,2.5-7.7l1-3.7c1-4,1.9-8.4,3-13.2l-13.3-1.9l-49.5-7.2l-76.6-10
	l-0.6,1.9l-0.3,0.3h-0.1l-0.1,0.5c-0.1,0.3-0.3,0.4-0.6,0.4l-1.2-0.4c-0.3-0.1-0.4-0.3-0.3-0.5l0.4-1.6h-0.1v0.1l-0.3,0.3l-0.1,0.7
	c-0.1,0.3-0.3,0.4-0.6,0.3l-1.9-0.5c-0.3-0.1-0.4-0.3-0.3-0.6l0.3-1v-0.1h-0.1l-0.1,0.7v0.1l-0.1,0.7c-0.1,0.3-0.3,0.4-0.6,0.3
	l-1.2-0.3c-0.3-0.1-0.4-0.3-0.3-0.5l0.1-0.7V403l0.3-0.8l-15.7-2.1c-2.5-0.7-4.7-1.5-6.5-2.5l-2.6-1.5c-9.9,2.5-14.7,5.6-14.3,9.4
	c1.4,16.6,3.4,30.8,6.2,42.4c0.8,3,3.9,5.6,8.9,7.7l1.8,0.7h2.5l-1.9-0.7c-0.8-0.3-1.5-0.7-2.1-1.2c-0.8-0.5-1.1-1.1-1-1.5
	c0.1-0.3,0.6-0.4,1.5-0.3c1,0.1,2.1,0.4,3.3,0.8c1.2,0.4,2.2,0.8,3,1.4c0.6,0.3,0.8,0.5,1,0.8l0.1,0.3l-0.3,0.3h110.9V456.2
	L267.7,456.2z"
          />
          <path
            fill="none"
            d="M174.6,402.6l0.6-1.9c0.1-0.4,0-0.5-0.4-0.7l-1.2-0.4h-0.4l-0.3,0.3l-0.6,2.2l-0.3,0.8v0.4
	c0,0.1,0.1,0.1,0.3,0.1l1.2,0.4h0.4l0.1-0.1l0.1-0.7L174.6,402.6L174.6,402.6z M174,404.1h0.1l0.1-0.3"
          />
          <path
            fill="none"
            d="M174.2,404.1h-0.6l-1.2-0.4c-0.1,0-0.3-0.1-0.3-0.1l-0.1,0.7c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.3
	c0.3,0.1,0.6,0,0.6-0.3L174.2,404.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M147.5,289.2c0-4.1,0.1-8.3,0.3-12.2 M145.5,289.2c0-4.1,0.1-8.3,0.3-12.4
	 M146.5,289.2c0-4.1,0.1-8.3,0.3-12.4 M144.4,289.2c0-4.1,0.1-8.3,0.3-12.4 M146.6,337.4l-0.6-12.4 M147.7,337.4l-0.7-12.4
	 M145.5,337.4c-0.3-4.1-0.4-8.3-0.7-12.4 M148.6,337.4c-0.3-4.1-0.4-8.3-0.7-12.2 M149.5,336.7l-0.6-11.3 M150.6,333.8l-0.4-6.9
	 M149.5,303.4v-8.9 M148.4,304v-10.2 M145.5,304.1v-10.3 M146.5,304.1v-10.3 M147.5,304.1v-10.3 M144.4,304.1v-10.3 M149.9,318.8
	l-0.3-8.7 M148.6,308.9c0,3.9,0.1,7.7,0.3,11.6 M145.5,308.5c0,4.1,0.1,8.3,0.3,12.4 M146.9,320.9c-0.1-4.1-0.3-8.3-0.3-12.4
	 M147.5,308.6l0.4,12.2 M144.4,308.5c0,4.1,0.1,8.3,0.3,12.4 M152.4,307.1l0.4,0.1l0.1,0.1c0.1,0.3,0.3,0.5,0.3,1v0.3l-0.3,0.5
	l-0.3,0.1l-0.7-0.1l-0.3-0.1c-0.1-0.1-0.3-0.4-0.3-0.8v-0.4l0.3-0.5l0.3-0.1L152.4,307.1L152.4,307.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151,307.8c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.5,0.1,1,0.4,1.4
	c0.1,0.3,0.3,0.4,0.6,0.5l1,0.1l0.6-0.3c0.3-0.3,0.4-0.5,0.6-1.1c0.1-0.4,0.1-1,0-1.5l-0.1-0.7l-0.1-0.3v-0.1h0.3v-1.9h-3.9v1.9h1.1
	v0.3L151,307.8L151,307.8z M149.7,287.7l0.3-8.8 M153.4,301.9v0.5c0,0.3-0.1,0.6-0.3,0.7l-0.1,0.1v-1.9h0.1l0.1,0.1
	C153.2,301.5,153.2,301.6,153.4,301.9L153.4,301.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M153.8,300.7c-0.1-0.4-0.3-0.7-0.6-1c-0.3-0.3-0.6-0.4-1-0.4
	c-0.1,0-0.3,0-0.4,0.1c-0.4,0.3-0.7,0.7-0.8,1.5c-0.1,0.3-0.1,0.7-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1.1l0.3,0.3h0.1
	v-1.9c-0.1-0.1-0.1-0.3-0.1-0.5v-0.4c0-0.4,0.1-0.5,0.3-0.7c0.1-0.1,0.1-0.1,0.3-0.1v3.7h0.4l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.5
	c0.1-0.4,0.1-0.8,0.1-1.2S153.9,300.9,153.8,300.7L153.8,300.7z M153.8,294.6c-0.1-0.4-0.3-0.7-0.6-0.8c-0.1-0.1-0.4-0.3-0.7-0.3
	s-0.6,0-0.7,0.1c-0.4,0.3-0.7,0.8-0.8,1.5l-0.1,1c0,0.5,0,1,0.1,1.5c0.1,0.4,0.1,0.8,0.4,1l0.3,0.4h0.1v-1.8
	c-0.1-0.1-0.1-0.3-0.1-0.5v-0.5c0-0.3,0.1-0.4,0.3-0.5l0.1-0.1h0.1v3.7l0.4-0.1l0.4-0.3c0.4-0.3,0.7-0.8,0.7-1.6L153.8,294.6
	L153.8,294.6L153.8,294.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M153,295.3l0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.5v0.5c0,0.3-0.1,0.5-0.3,0.7
	H153V295.3L153,295.3z M153,286.9h-0.6c-0.1,0-0.3,0-0.4,0.1c-0.3,0.3-0.6,0.7-0.7,1.2c-0.1,0.3-0.1,0.5-0.1,1l0.1,2.1
	c0.1,0.7,0.3,1.2,0.7,1.5c0.1,0.1,0.4,0.3,0.7,0.3h2.8v-1.9h-2.9c-0.1-0.1-0.3-0.3-0.4-0.5v-0.8c0-0.4,0.1-0.7,0.3-0.8l0.3-0.1h0.6
	L153,286.9L153,286.9z M150.6,264.1l-0.4,6.9 M149,272.3c0.1-3.7,0.4-7.6,0.6-11.1 M147.1,272.9c0.1-4.1,0.4-8.3,0.7-12.4
	 M148.7,260.5c-0.3,4-0.6,8.1-0.7,12.2 M145,272.9c0.1-4.1,0.4-8.3,0.7-12.4 M146.8,260.5c-0.3,4.1-0.6,8.3-0.7,12.4 M148.6,288.8
	c0.1-3.9,0.1-7.7,0.3-11.6 M695.1,172.5h-4.7 M696.8,184.6v-9.8c0-1.2-0.5-2.1-1.7-2.2 M663.1,142.5c13.3,1.9,27.2,4.8,41.5,8.9
	c7,2.9,11.3,8.9,12.7,18.2l2.5,15 M720.3,209.5h1.8c0.7,0,1-0.3,1-1.1v-22.8c0-0.7-0.3-1-1-1h-2.2 M691.4,196h3.7 M693.2,207.4
	l0.5-0.3v-6.7c0.1-2.8-0.7-4.3-2.3-4.5 M691.4,196h-1 M686.6,174.2c2.5,3,3.9,7.4,4,13.3v8.4 M695.1,196c1.1,0,1.7-0.6,1.7-1.5v-9.9
	 M693.2,209.5v-2.1 M690.4,208.6l-1,0.4 M693.2,207.4l-2.8,1.2 M690.4,209.5v-0.8 M693.2,209.5h-2.8 M689.2,209.2l0.3-0.1
	 M718.8,237.1V215c0-3.3,0.5-5.1,1.5-5.5 M719.7,184.6h-23 M720.3,209.5h-27.1 M690.4,196v12.7 M689.5,209.1v-8.9
	c0.1-2.3-0.4-3.3-1.8-3c-2.3,0.6-4,1.5-5.2,2.8 M718.8,361.7h6.9c6.5,0.1,12-0.3,16.8-1.1c4-1.2,6.2-3.7,6.5-7.6V245.8
	c-0.4-3.7-2.5-6.2-6.5-7.6l-1.5-0.3c-4.4-0.7-9.5-1-15.3-0.8h-6.9 M700.3,244.8l-0.1,1v0.4 M718.8,237.1c-4.5,0.1-8.5,0.4-12.1,1.1
	c-1.2,0.4-2.3,1-3.2,1.6c-0.8,0.6-1.5,1.2-2.1,2.1l-0.1,0.1c-0.5,0.8-0.8,1.6-1.1,2.8 M700.2,246.2l0.1-1.4 M700.2,254.9l-6.6-0.3
	 M690.4,267l3.3-2.1l-0.1-10.3 M700.2,246.2v8.7 M693.6,254.6l-0.4-45.1 M690.4,267v-57.5 M695.1,172.5V196 M152.4,141.6l-1,0.3
	c-1.2,0.4-2.2,1-3,1.5c-0.8,0.6-1.1,1.1-1,1.5c0.1,0.3,0.6,0.3,1.5,0.3c1-0.1,2.1-0.3,3.3-0.8c1.2-0.4,2.2-0.8,3-1.4
	c0.8-0.6,1.1-1,1.1-1.2l-0.3-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.1,141.6c-0.3-0.3-0.8-0.3-1.5-0.3s-1.5,0.1-2.3,0.3 M149.9,141.6
	h-9.2c-6.7,2.1-11.1,4.7-13.3,7.8 M149.9,141.6h2.5 M147.5,201.8c-9.9-2.5-14.7-5.6-14.3-9.4c0.4-4.8,0.8-9.5,1.4-13.9
	c1.2-10.7,2.8-20.2,4.7-28.3c1-3.4,4.5-6.2,10.6-8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M267.7,141.6c-0.8-2.9-3.9-4.3-9.2-4.3h-97.3c-6.3,0-10,1.5-11.3,4.3
	 M124.1,149.3h-2.9c-2.9-0.1-4.8,1.1-5.8,3.4c-3.2,7.2-6.2,15.7-9.1,25.6l-2.5,8.9c-0.6,1.8-1.5,3.2-3,4.1l-2.6,1.9
	c-1.2,1-2.1,3.4-2.3,7.3l-2.6,28.8c0.1,1.2,1.1,2.8,3,4.3l1,0.7 M127.4,149.3h-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M124.1,149.3c-1.9,0.1-3,1-3.3,2.6l-12.9,37.3 M127.9,194.5v-3.7l-0.8-2.2
	l5.5-34.8c0.7-2.9,0.1-4.4-1.8-4.3h-3.3 M129.4,197.4l-1.5-2.1v-0.8 M130.8,202.4l0.1-3l-1.5-2.1 M107.8,189.4l-0.8,1.5l-1.5,2.1
	l-1.4,1.5l-0.1,0.4 M104,194.9c-0.7,1.2-1.1,3.2-1.2,5.8l-2.6,28.8c0.1,1.2,1.1,2.9,3,5 M107.8,189.4l19-0.4 M104,194.9l23.9-0.4
	 M126.1,234.5l3.9-4.4l0.8-27.6 M126,234.6l0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M129.4,197.4l-1,32.7l-2.3,4.4 M123.9,234.5l2.1,0.1 M126.1,234.5h-2.2
	 M104.3,234.4h-0.1 M107.6,234.4h-3.4 M104.3,234.4h3.3 M103.2,234.4h1.1 M104.1,234.4h0.1 M97.7,234.6l-0.4-0.3 M103.2,234.4h-5.9
	 M97.3,234.4c0.8,1.1,1.2,4,1.1,8.7v111.8c0.1,4.7-0.1,7.6-1.1,8.7 M107.6,234.4l16.4,0.1 M177.7,193.8l-0.1-0.1l-0.1-0.3v-0.1
	 M177.5,193.2l-0.1-0.7c-0.1-0.3-0.3-0.4-0.6-0.4l-1.9,0.6c-0.3,0.1-0.4,0.3-0.3,0.6l0.3,1.1 M174.3,193.9v-0.1l-0.1-0.7
	c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M177.5,193.2H177l-2.1,0.6c-0.3,0.1-0.4,0.3-0.4,0.7 M179.7,192.1
	l-0.1-0.6c-0.1-0.3-0.3-0.4-0.6-0.3l-1.2,0.3c-0.3,0.1-0.4,0.3-0.3,0.6l0.4,1.6 M180.6,194.5l-0.6-1.9c0-0.1-0.1-0.3-0.3-0.3h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M179.7,192.1h-0.3l-1.2,0.4c-0.3,0.1-0.4,0.3-0.3,0.6l0.1,0.6
	 M180.6,194.5l0.3,1v0.4 M180.9,195.8l-0.4,0.3l-1.2,0.3c-0.3,0.1-0.6,0-0.6-0.4l-0.4-1.4 M178.3,194.7l-0.3-1 M177.9,193.8h-0.1
	 M178,193.8h-0.1 M178,194.7l0.1,0.7l0.4,2.9 M177.7,193.8l0.3,1 M178.3,194.7H178"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.6,198.3l1.6-0.4c0.3-0.1,0.6,0,0.6,0.4l0.1,0.6 M181,198.7l0.4-0.6
	l-0.6-2.3 M174.3,193.9l-0.1-0.1h-0.4l-1.2,0.3c-0.1,0-0.3,0.1-0.3,0.3 M174.4,194.6l-0.1-0.7 M174.6,194.5l-0.1,0.1 M176.5,198.9
	l-1.4-2.6l-0.3-1.1 M174.8,195.2h-0.3 M174.8,195.2l-0.3-0.7 M174.6,195.2l-0.1-0.6 M173.1,198c-0.1-0.1-0.1-0.1-0.3-0.4l-0.6-2.2
	 M174.6,195.2l0.6,1.9c0.1,0.3,0,0.6-0.4,0.7l-1.2,0.4h-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M174.7,200.7l-0.3-0.8c-0.1-0.4,0-0.6,0.4-0.7l1.6-0.4 M174.7,200.7
	l-1-0.4l-0.7-2.2 M175,201.5l-0.3-0.8 M175.3,202.7l-0.4,0.1h-0.4l-0.3-0.3l-0.1-0.3c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.3,0.3-0.3
	l0.3-0.1 M181.6,200.9c0,0.3-0.1,0.4-0.4,0.4l-5.4,1.5c-0.3,0.1-0.4,0-0.6-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M175.3,202.7l-0.1-0.3l-0.1-1 M181.2,199.7l0.3,1.1l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M181.2,199.7l0.3-0.1h0.4l0.3,0.3l0.1,0.3c0,0.1,0,0.3-0.1,0.4l-0.4,0.3
	l-0.3,0.1 M178.6,198.3l-2.1,0.6 M181,198.7l0.1,1 M172.2,195.6l-15.7,2.1c-2.5,0.7-4.7,1.5-6.5,2.5 M172.1,194.3l-0.1,0.1v0.3
	l0.3,0.8 M150.1,200.1c-1,0.4-1.8,1-2.6,1.6 M147.5,201.8c-0.6,0.4-1.1,0.8-1.6,1.4c-3.2,2.9-5.5,3.6-7.3,2.3l-7.7-2.9 M154.2,207.3
	c-0.4-2.9-1.2-4.4-2.2-4.4s-2.1,1.6-3.2,4.8l-0.1,0.4c-1.1,3.4-1.9,7.6-2.3,12.5c-0.4,4.8-0.4,8.9,0,12.4c0.3,1.8,0.6,3,1,3.9
	s0.8,1.2,1.4,1.2c1.1,0,2.2-1.6,3.3-5.2l0.3-0.8l0.8-3.3 M144.6,225l-0.1-1.1c-0.3-1.6-0.3-3.7,0-6.2l0.3-2.1
	c0.1-1.5,0.4-2.9,0.8-4.1l0.3-1v-0.1l-0.3-0.8c-0.1-0.4-0.4-0.7-0.6-0.7c-0.6,0-1,0.8-1.5,2.6c-0.4,1.6-0.8,3.7-1,6.2
	c-0.3,2.5-0.3,4.5,0,6.2c0.1,1.1,0.3,1.8,0.6,2.2c0.1,0.3,0.3,0.4,0.6,0.4S144.2,226,144.6,225L144.6,225z M144,208.8
	c1-3.3,2.2-5.6,3.4-7 M144.6,225l0.3-0.8l0.1-0.3c0.4-1.6,0.8-3.7,1-6.2c0.3-2.5,0.3-4.5,0-6.2l-0.1-1 M142.2,208.8h-4.7
	c-0.8,4-1.5,9.4-2.5,16.1l-0.8,7.3c-0.3,3.2-0.6,6.6-0.8,10.2l-0.8,12.9c-0.7,13.2-1.1,26.7-1.2,40.6v6.3
	c0.1,12.8,0.6,25.2,1.1,37.4l1,16c0.3,3.7,0.6,7,0.8,10.2c0.3,2.6,0.6,5.1,0.8,7.4c0.8,6.6,1.6,12,2.5,16.1h4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M154.2,207.3c-0.1,0.4-0.3,0.8-0.6,1.2l-0.3,0.8c-0.7,2.2-1.1,4.8-1.4,7.8
	c-0.3,3.3-0.3,6.1,0,8.4v0.3c0.1,1,0.3,1.8,0.6,2.3l0.4,0.6 M144,389.1c-1.2-4-2.3-9.4-3.2-16.1c-0.3-2.3-0.6-4.8-0.8-7.4
	c-0.3-3.2-0.7-6.6-1-10.2c-0.3-4.3-0.6-8.5-0.8-12.9l-0.1-3c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.2,1.2-40.6
	c0.3-4.4,0.4-8.7,0.8-12.9c0.3-3.7,0.6-7,1-10.2c0.3-2.6,0.6-5.1,0.8-7.3c0.8-6.6,1.9-12,3.2-16.1 M144,208.8h-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M142.2,389.1c-1-4-1.9-9.4-2.8-16.1c-0.3-2.3-0.6-4.8-0.8-7.4
	c-0.3-3.2-0.6-6.6-0.8-10.2l-1.1-15.8c-0.6-12.2-1-24.8-1.1-37.4v-6.3c0.1-13.8,0.6-27.4,1.2-40.6l0.8-12.9c0.3-3.7,0.6-7,0.8-10.2
	c0.3-2.6,0.6-5.1,0.8-7.3c0.8-6.6,1.8-12,2.8-16.1 M153.1,228.9c0.6-2.5,1-5.2,1.2-8.4c0.4-4.8,0.4-8.9,0-12.5l-0.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M150.1,200.1c2.5-1,4.3-0.8,5.4,0.4c1.2,3.6,1.9,12.1,2.1,25.6
	c0.4,16,0.6,31.2,0.6,45.9v67.4c0,10.5-0.3,21.2-0.6,32.2c0,13.5-0.7,22-2.1,25.6c-1.1,1.4-2.9,1.5-5.4,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M150.2,255c0.4-0.7,0.7-1.5,0.7-2.8v-3.4c0.1-3-0.7-4.7-2.2-5h-4.4
	 M150.2,255c-0.6,0.8-1.5,1.4-2.8,1.4h-5.4c-0.8-0.1-1.4-0.6-1.5-1.5l0.6-9.8c0.3-1,1.1-1.4,2.2-1.2h1 M146.8,260.5h1.5l0.3,0.1
	c0.4,0.1,0.7,0.3,1,0.7c0.6,0.5,0.8,1.5,1,2.9v4.7c0,1-0.1,1.6-0.4,2.2c-0.3,0.5-0.7,1.1-1.2,1.4l-1,0.4l-0.8,0.1h-5.2
	c-1,0-1.7-0.5-1.8-1.5l0.4-9.1c0.1-1,0.7-1.5,1.6-1.6h3.6L146.8,260.5L146.8,260.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M144.3,243.9V255h5.9 M146.9,276.9h-5.4c-0.8,0-1.5,0.5-1.9,1.5l-0.1,9.5
	c0.3,0.8,0.8,1.2,1.8,1.4h6.1c0.4-0.1,0.7-0.1,1-0.4c0.6-0.3,0.8-0.7,1.1-1.2c0.3-0.5,0.4-1.1,0.6-1.9v-4c0-1.2,0-2.2-0.3-2.9
	c-0.3-0.8-0.6-1.4-1.1-1.6l-0.8-0.3L146.9,276.9L146.9,276.9z M143.9,272.9c0.1-4.1,0.4-8.3,0.7-12.4 M317.2,162.1l-1-3.7
	c-0.8-2.9-1.7-5.5-2.5-7.7c-1.4-3.6-4.4-5.8-9.2-6.6c-4.8-1.7-9.1-2.5-12.7-2.5h-24.1 M317.2,162.1c1,3.9,1.9,8.3,3,13.2
	 M320.1,175.3l-13.3,1.9 M382.8,127.1c-1.2,0.1-2.6,0.6-4.1,1.2h-0.1l-0.1,0.1l-1.9,1c-0.7,0.3-1.2,1-1.8,1.9l-7.2,20.6
	c-0.5,1.1-0.5,2.2,0.1,3.2 M353.5,168.5h-2.6c-0.3,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1l0.3,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M350.4,170.9l-2.8,0.1l-1.1,0.1 M345.1,171.5c-0.1,0.1-0.3,0.3-0.3,0.4
	s0.1,0.3,0.4,0.3l0.8,0.1l0.8-0.1l0.3-0.3v-0.1l-0.3-0.4l-0.1-0.1h-0.1 M346.5,173.4c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1
	c0.3-0.1,0.3-0.1,0.3-0.3c0-0.3-0.1-0.4-0.3-0.6c-0.3-0.1-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	C346.7,173,346.5,173.1,346.5,173.4L346.5,173.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M346.5,171.2l-0.1-0.1h-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1 M345.1,171.5
	l-7.2,1.1 M382,160.2l-0.8,2.6c-0.1,1-0.7,1.2-1.5,1l-8.3-3.9l-0.4-0.6 M365.6,159.7l-0.4,1.1 M365.2,160.8l-3.3,7.6 M353.5,168.5
	c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1l-0.3,0.3 M353.5,168.5c-0.3,0-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.6-0.4,0.8c0,0.4,0.1,0.7,0.4,1l0.3,0.3 M361.2,169.8l-1.5,1c-0.4,0.3-0.7,0.7-1,1 M358.8,171.8l-0.3,0.3l16.1-0.6
	 M358.9,170.8c-0.1,0.3-0.1,0.7-0.1,1 M359,174.8l-0.3-0.4l-0.1-0.1l-0.4-0.1h-0.3c-0.3,0-0.5,0.1-0.7,0.3 M357.1,174.4
	c-0.3,0.1-0.4,0.3-0.4,0.6c0,0.1,0.1,0.3,0.3,0.3l0.8,0.1l0.8-0.1l0.3-0.3v-0.1 M359,174.8l1.2,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353,170.9l0.5,0.1l0.7-0.1 M353,170.9h-1.4 M354.1,173.7
	c0.3,0.1,0.4,0.4,0.4,0.6l-0.3,0.3l-0.8,0.1l-0.8-0.1c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.3,0.1-0.4,0.3-0.6c0.3-0.1,0.5-0.3,0.8-0.3h0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.7,173.4l0.4,0.1v0.1 M354.2,170.9H353 M358.9,170.8l-4.7,0.1
	 M354.1,173.7l3,0.7 M361.9,168.3c-1.6,0.7-2.8,1.5-3,2.5 M361.9,168.3l-0.7,1.5 M373.8,170.5c-1.4-1.4-3.7-2.1-6.9-2.1
	c-2.3,0.1-4.1,0.6-5.6,1.4 M367.7,155.3l-2.1,4.4 M351.6,170.9h-1.2 M351.6,170.9c-1,1-0.3,1.8,2.1,2.5 M370.9,159.5l-3.2-4.3
	 M365.2,160.8l-48,1.4 M338,172.6l-17.9,2.8 M261.8,246.3c0,0.3,0.1,0.6,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3
	c0.1-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.3-0.7c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3
	C261.8,245.8,261.8,246.1,261.8,246.3L261.8,246.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M258.9,246.3c0,1.1,0.4,1.9,1.1,2.8c0.8,0.7,1.6,1.1,2.8,1.1
	s1.9-0.4,2.8-1.1c0.8-0.8,1.1-1.6,1.1-2.8c0-1.1-0.4-1.9-1.1-2.8c-0.8-0.8-1.6-1.1-2.8-1.1s-1.9,0.4-2.8,1.1
	C259.3,244.4,258.9,245.2,258.9,246.3L258.9,246.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M260.8,248.3c0.5,0.6,1.2,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8
	s0.8-1.1,0.8-1.9c0-0.8-0.3-1.4-0.8-1.9s-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8s-0.8,1.1-0.8,1.9
	C260.1,247,260.4,247.7,260.8,248.3L260.8,248.3z M343.5,183.9l0.1-0.7l-0.1-0.8c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3
	c-0.1,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.7c0.3,0.3,0.5,0.3,0.8,0.3C343.2,184.1,343.4,184.1,343.5,183.9z M343.5,193
	c0.3,0.3,0.5,0.3,0.8,0.3c0.1,0,0.3-0.1,0.4-0.3l0.1-0.7l-0.1-0.8l-0.4-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.6-0.3,0.8
	S343.2,192.8,343.5,193L343.5,193z M346.4,199h-2.6 M353,184.1l-5.4-1.1c-1.1,0.7-1.5,5.4-1.2,14v2.1 M340.6,199l-0.1-12.9
	c-0.1-7.4-1-11.8-2.5-13.6 M343.8,199h-3.2 M306.8,177.3l1.1,9.8c0.8,29.6,1.2,57.1,1.1,82.5v69.9c0,22.3-0.4,46.1-1.1,71.3
	l-1.1,9.8 M343.8,199l0.7,61.6l-0.7,138.1 M306.8,177.3l-49.5,7l-76.6,10.2 M360.3,175.1c-3.6,1.1-5.9,4-7.3,8.9 M267.7,141.6H156.1
	 M454.2,168.7c0.3-1.5,0.8-2.9,1.5-3.9c7.2-0.6,16.2-0.7,27.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M461.7,168.6l-0.3,0.7h-7.3l0.1-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M482.9,168.1c-7.4-0.3-14.4-0.1-21,0.4l-0.1,0.1 M485,164.3h-1.7
	c-0.3,0-0.4,0.1-0.5,0.4 M482.9,168.2v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M485,168.6h-1.7c-0.3,0-0.5-0.1-0.5-0.6 M482.9,164.8h1.4v3.3h-1.4
	 M485,164.3v4.3 M490.4,168.1v-3.2c0-0.3-0.1-0.6-0.5-0.6h-4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M485,168.6h4.8c0.3,0,0.5-0.1,0.5-0.6v-0.1 M375.3,175.1l-5.9-1.9
	l109.4-1.8c3.4,0.1,5.5,1.1,5.9,3l0.5,2.5c0.7,1.6,0.1,2.6-1.5,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M483.7,179.7l-0.5-2.6c0.4-1.4-0.4-2.1-2.2-2.1H375.3 M461.7,168.6
	l21.2-0.4 M494.3,156.9l3.3-8.8c1.4-3.3,5.8-5.6,12.9-7.2c3.2-1.1,7-1.4,11.4-0.8h7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M491.8,167.9c-0.7-0.3-0.5-2.3,0.6-5.9l1.9-5.1 M497.2,167.9h-5.4
	 M497.2,167.9v1.6 M516.6,179.7c43.2-0.6,85.8-0.4,127.8,0.6c1.7,0,2.8-0.8,3.4-2.3l3.7-4.7c1.4-1.4,0.7-2.1-1.8-2.1l-27.9-0.6
	h-89.7l-15.1,0.6c-2.5,0.6-3.7,1.5-3.7,2.6v4.1C513.2,178.9,514.3,179.5,516.6,179.7L516.6,179.7z M671.6,168.9h-0.3l-15-0.8
	l-34.5-0.7H530c-11.1,0-22.1,0.1-32.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M497.2,169.6c7.4-0.3,14.9-0.6,22.4-0.6h102.2l35.8,0.7l13.6,0.8h0.3
	c1.8,0.3,3.3,0.6,4.7,0.8l-9.8,10.7 M491.8,167.9l-1.4,0.1 M382,160.2l9.9-29.6c0.4-1,0.1-1.5-0.7-1.8l-7.2-1.7h-1.2 M375.1,163
	l0.1-0.8 M374.2,170.4l-0.4,0.1 M374.3,169.4l-0.1,1 M374.6,171.5l1.2-0.1c0-0.4-0.1-0.7-0.3-1 M375.5,170.4c-0.3-0.4-0.7-0.8-1.2-1
	 M374.2,170.4l-0.1,0.4 M373.8,170.5l0.3,0.3 M374,170.8l0.5,0.7 M375.3,175.1l8.4,2.8l7.3,2.1c1.9,0.7,4,1.2,5.9,1.4h0.3l86.5-1.5
	 M375.1,163l-0.8,6.5 M391.8,184.3c0.8-1,2.1-1.4,3.7-1.5l102.3-1.6 M353,413.7l24.5-5.8c2.8-0.5,4.1-3.3,4.1-8.4V198.2
	c0-5.1-1.4-7.8-4.1-8.4l-24.5-5.6 M391.8,184.3c-0.7,0.8-1.1,1.9-1.1,3.4c-0.4,11.6-0.5,24.1-0.5,37.6v147.1
	c-0.1,13.5,0.1,26,0.5,37.6c0,1.5,0.4,2.6,1.1,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M360.3,175.1l28.9,6.9c1.5,0.3,2.3,1.1,2.6,2.3 M500.2,181.3h-2.3
	 M500.2,206.6v6.6 M500.2,181.3v25.3 M500.2,241.8v6.5 M500.2,248.3v29 M500.2,213.2v28.6 M494.3,156.9L382,160.2 M497.8,181.3
	l-0.7-11.7 M454.2,168.7l-78.7,1.6 M644.5,140.1c-0.5-1.9-3-2.9-7.8-2.9h-94.9c-6.5-0.3-10.6,0.7-12.2,2.9 M674.9,165.9l-4.1-14.9
	c-1-3.6-2.2-5.8-3.7-6.9c-0.3-0.1-0.4-0.3-0.7-0.4l-3.3-1.2 M644.5,140.1h8.5c2.6,0.1,5.9,0.8,10,2.3 M674.9,165.9
	c0.3,1.5-1,2.5-3.4,3 M671.6,168.9c6.1,0.7,10.3,1.8,12.8,3.3c0.8,0.6,1.5,1.2,2.2,2.1 M682.3,199.8c-0.5,0.6-1,1.1-1.2,1.6
	l-0.3,0.8c-0.1,1.1-0.3,2.1-0.4,3.2v5.8 M687.3,211.4l-0.3-10.3c-0.4-2.1-1.9-2.5-4.7-1.1 M666.5,182.1h-1.4
	c-53.9-1.6-108.8-1.9-164.9-0.8 M665.3,205.1v-9.9c-0.1-7.8,0.3-12.1,1.2-13.1 M680.4,227.1h-1.7v0.8 M680.4,211.4v15.7
	 M680.4,228.3c-0.4-0.3-1-0.4-1.7-0.4 M680.4,227.1v1.2 M680.4,211.4h6.9 M666.5,415.8c6.3-2.3,9.6-8.1,10-17.2V199.3
	c-0.4-9.1-3.7-14.7-10-17.2 M665.3,211.9v-6.9 M678.7,237v0.8h1.7 M680.4,236.6c0.7-0.3,1.1-0.7,1.1-1.4V230
	c-0.1-0.8-0.5-1.2-1.1-1.7 M680.4,237.8v-1.2 M678.7,237c0.7-0.1,1.2-0.1,1.7-0.4 M665.3,248.3v-6.5 M665.3,277.3v-29 M678.7,227.9
	v9.1 M665.3,241.8v-29.9 M529.6,140.1h114.9 M665.3,277.3H500.2 M665.3,248.3H500.2 M665.3,241.8H500.2 M665.3,211.9l-165.1,1.2
	 M665.3,205.1l-165.1,1.5 M382.8,127.1l-12,32.5 M365.6,159.7l9.5,3.3 M665.3,320.5V314 M665.3,314v-30.1 M680.4,361.5v-1.2
	 M680.4,369.7c0.7-0.3,1.1-0.7,1.1-1.4v-5.2c-0.1-0.8-0.5-1.4-1.1-1.6 M680.4,360.2h-1.7v1 M678.7,370.1v0.8h1.7 M680.4,371v-1.2
	 M678.7,361.2v8.9 M678.7,361.2c0.7,0,1.2,0.1,1.7,0.4 M678.7,370.1c0.7-0.1,1.2-0.1,1.7-0.4 M665.3,356.1v-6.6 M665.3,349.5v-29
	 M680.4,386.5v5.8c0.1,1.1,0.3,2.2,0.4,3.2l0.3,0.8c0.3,0.5,0.7,1.1,1.2,1.6 M687.3,386.5h-6.9 M682.3,398.1
	c2.8,1.2,4.3,0.8,4.7-1.1l0.3-10.3 M665.3,392.8v-7 M666.5,415.8c-0.8-1-1.2-5.2-1.2-13.1v-9.9 M500.2,416.6
	c56.1,1.1,111,0.8,164.9-0.8h1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M682.3,398.1c1.2,1.2,3,2.2,5.2,2.8c1.4,0.3,1.9-0.7,1.8-3v-8.9
	 M647.8,419.9l3.7,4.7c1.4,1.4,0.7,2.1-1.8,2.2l-27.8,0.5h-92.4l-12.5-0.5c-2.5-0.5-3.7-1.5-3.7-2.6v-4.1c0-1.1,1.1-1.8,3.3-1.9
	c43.2,0.5,85.8,0.4,127.8-0.5C646,417.6,647.1,418.3,647.8,419.9L647.8,419.9z M529.6,457.6c1.7,2.2,5.8,3.2,12.2,2.9h94.9
	c4.7,0,7.3-1,7.8-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M497.2,428.2c7.4,0.3,14.9,0.5,22.4,0.5h102.2l35.8-0.7l13.6-0.8h0.3
	c1.8-0.3,3.3-0.5,4.7-0.8l-9.8-10.6 M671.6,428.9l-0.3,0.1l-15,0.8l-34.5,0.7H530c-11.1,0-22.1-0.1-32.9-0.7 M686.6,423.5
	c-0.7,0.8-1.4,1.5-2.2,2.1c-2.5,1.5-6.7,2.6-12.8,3.3 M671.6,428.9c2.5,0.5,3.6,1.6,3.4,3 M674.9,431.9l-4.1,14.9
	c-1,3.6-2.2,5.8-3.7,6.9l-0.8,0.4l-3.3,1.2 M644.5,457.6h8.5c2.6-0.1,5.9-0.8,10-2.3 M680.4,371v15.5 M665.3,385.8v-29.7 M500.2,314
	v6.5 M500.2,283.9V314 M500.2,349.5v6.6 M500.2,320.5v29 M483.7,418.1l-86.5-1.5h-0.3c-1.9,0.1-4,0.5-5.9,1.4l-7.3,2.1l-8.4,2.8
	 M497.8,416.5l-102.3-1.6c-1.6-0.1-3-0.5-3.7-1.5 M391.8,413.5c-0.1,1.2-1,2.1-2.6,2.3l-28.9,6.9 M374.2,427.4l-0.1-0.3
	 M374.3,428.3l-0.1-1 M375.5,427.4c-0.3,0.4-0.7,0.8-1.2,1 M374.6,426.3l1.2,0.1c0,0.4-0.1,0.7-0.3,1 M374,427.1l0.5-0.8
	 M373.8,427.4l0.3-0.3 M374.2,427.4h-0.4 M375.1,434.9l0.1,0.8 M382,437.5l-0.8-2.6c-0.1-1-0.7-1.2-1.5-1l-8.3,3.7l-0.4,0.5
	 M375.1,434.9l-0.8-6.6 M382.8,470.7h1.2l7.2-1.6c0.8-0.3,1.1-0.8,0.7-1.8l-9.9-29.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367.7,442.6c-0.7,1-0.7,2.1-0.1,3.2l7.2,20.6c0.4,1,1.1,1.6,1.8,1.9
	l1.9,1l0.1,0.1h0.1c1.5,0.8,2.9,1.2,4.1,1.2 M500.2,384.7v6.6 M500.2,416.6l-2.3-0.1 M500.2,391.3v25.3 M375.3,422.8l-5.9,1.9
	l109.4,1.8c3.4-0.1,5.5-1.1,5.9-3l0.5-2.5c0.7-1.6,0.1-2.8-1.5-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M483.7,418.1l-0.5,2.6c0.4,1.4-0.4,2.1-2.2,2.2H375.3 M485,429.2h4.8
	c0.3,0,0.5,0.1,0.5,0.5v0.1 M482.9,429.8h1.4v3.2l-1.4,0.1 M482.9,429.7c0-0.3,0.1-0.5,0.5-0.5h1.7 M482.9,429.8v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M485,433.4h-1.7c-0.3,0-0.4-0.1-0.5-0.4 M490.4,429.8v3.2
	c0,0.3-0.1,0.5-0.5,0.5h-4.8 M485,433.4v-4.3 M461.7,429.2l0.1,0.1c6.6,0.5,13.6,0.7,21,0.4 M454.2,429l-0.1-0.4h7.3l0.3,0.6
	 M482.9,433.1c-11,0.5-20.1,0.4-27.2-0.1c-0.7-1.1-1.1-2.3-1.5-3.9 M461.7,429.2l21.2,0.5 M497.2,429.8v-1.6 M497.2,429.8h-5.4
	 M491.8,429.8c-0.7,0.3-0.5,2.3,0.6,5.9l1.9,5.1 M494.3,440.8l3.3,8.8c1.4,3.3,5.8,5.6,12.9,7.2c3.2,1.1,7,1.4,11.4,0.8h7.8
	 M497.2,428.2l0.7-11.7 M491.8,429.8h-1.4 M382,437.5l112.3,3.3 M500.2,356.1v28.6 M375.5,427.4l78.7,1.6 M529.6,457.6h114.9
	 M665.3,392.8l-165.1-1.5 M665.3,385.8l-165.1-1.1 M665.3,356.1H500.2 M665.3,349.5H500.2 M665.3,314H500.2 M665.3,320.5H500.2
	 M665.3,283.9H500.2 M258.6,291.7v-1.4c0-0.1,0-0.3,0.1-0.4s0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.1l0.1,0.4v1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M259.6,291.7l0.5-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1
	c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1l0.7,0.4 M258.6,291.7v4.3
	c-0.1,0.1-0.3,0.3-0.3,0.5v4.8c0,0.1,0.1,0.4,0.3,0.5v4.3 M259.9,296.8v-0.3c0-0.3-0.1-0.4-0.3-0.5v-4.3 M259.9,301.1l1.9-0.8v-2.8
	l-1.9-0.7 M259.6,306.2v-4.3c0.1-0.1,0.3-0.3,0.3-0.5v-0.3 M259.9,296.8v4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M258.6,306l-0.7,0.4c-0.3,0.3-0.4,0.7-0.4,1.1s0.1,0.8,0.4,1.1
	c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1l-0.5-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M259.6,306.2v1.2l-0.1,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.3-0.1
	l-0.1-0.3V306 M265.5,348.8c0.8,0.8,1.1,1.6,1.1,2.8c0,1.1-0.4,1.9-1.1,2.8c-0.8,0.8-1.6,1.1-2.8,1.1s-1.9-0.4-2.8-1.1
	c-0.8-0.8-1.1-1.6-1.1-2.8c0-1.1,0.4-1.9,1.1-2.8c0.8-0.8,1.6-1.1,2.8-1.1S264.7,348.1,265.5,348.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M264.7,349.6c0.5,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.9
	c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.1-0.8-1.9c0-0.7,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8
	C263.6,348.8,264.1,349.1,264.7,349.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M263.6,350.7c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7
	c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7c0.3-0.3,0.4-0.3,0.7-0.3
	S263.3,350.6,263.6,350.7L263.6,350.7z M149.9,456.2c1.1,2.9,5,4.3,11.3,4.3h97.3c5.4,0,8.4-1.4,9.2-4.3 M267.7,456.2h24.1
	c3.6,0,7.8-0.8,12.7-2.5c4.8-0.8,7.8-3,9.2-6.6c0.8-2.3,1.6-4.8,2.5-7.7l1-3.7 M343.8,398.8h-3.2 M346.4,398.8h-2.6 M343.2,405.6
	c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.4,0.8-0.4c0.1,0,0.3,0.1,0.4,0.4l0.1,0.7l-0.1,0.8l-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3
	C343.2,406.2,343.2,405.9,343.2,405.6L343.2,405.6z M343.5,415.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.1-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.3,0.8-0.3c0.1,0,0.3,0.1,0.4,0.3l0.1,0.7L343.5,415.4L343.5,415.4z
	 M353,413.7l-5.4,1.1c-1.1-0.7-1.5-5.4-1.2-14v-2.1 M354.1,424.2c0.3-0.3,0.4-0.4,0.4-0.7c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1
	c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.4,0.3,0.6c0.3,0.1,0.5,0.3,0.8,0.3h0.3 M359,423c0-0.3-0.1-0.4-0.3-0.4l-0.8-0.1l-0.8,0.1
	c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.4,0.3,0.5 M359,423l-0.3,0.4l-0.1,0.1l-0.4,0.1h-0.3c-0.3,0-0.5-0.1-0.7-0.3 M360.3,422.7
	L359,423 M360.3,422.7c-3.6-1.1-5.9-4-7.3-8.9 M357.1,423.4l-1.6,0.4c-0.4,0.1-1,0.3-1.2,0.4 M338,425.3c1.5-1.6,2.3-6.2,2.5-13.6
	l0.1-12.9 M320.1,422.5l-13.3-1.9 M374.6,426.3l-16.1-0.4l0.3,0.3 M358.8,426.1c0,0.3,0,0.7,0.1,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M361.2,428l-1.5-1c-0.4-0.3-0.7-0.5-1-1 M354.1,424.2l-0.4,0.1 M354.2,427
	l-0.7-0.1L353,427 M353,427h-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.5,429.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1l-0.3-0.3
	 M353.5,429.4c-0.3,0-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-1c0.1-0.1,0.3-0.1,0.3-0.3 M353,427h1.2
	 M358.9,427.1l-4.7-0.1 M370.9,438.2l-2.5,3.6l-0.7,0.8 M365.2,437.1l0.4,1.1 M365.6,438.2l2.1,4.4 M373.8,427.4
	c-1.4,1.4-3.7,2.1-6.9,2.1c-2.3-0.1-4.1-0.5-5.6-1.4 M361.9,429.6l-0.7-1.5 M365.2,437.1l-3.3-7.6 M361.9,429.6
	c-1.6-0.7-2.8-1.5-3-2.5 M348.5,425c-0.3,0.1-0.5,0.3-0.8,0.3l-0.8-0.3c-0.3-0.1-0.3-0.4-0.3-0.5c0-0.1,0.1-0.3,0.3-0.3l0.8-0.1
	l0.8,0.1c0.3,0.1,0.3,0.1,0.3,0.3C348.9,424.6,348.7,424.9,348.5,425L348.5,425z M346.5,426.7h0.1l0.1-0.1l0.3-0.4V426
	c0-0.1-0.1-0.3-0.3-0.3l-0.8-0.1l-0.8,0.1c-0.3,0.1-0.3,0.1-0.4,0.3c0,0.1,0.1,0.3,0.3,0.4 M350.4,426.8h-2.8l-1.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.5,429.4h-2.6c-0.3,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1
	s0.1-0.7,0.4-1l0.3-0.3 M346.5,426.7H346l-0.7-0.1l-0.1-0.1 M345.1,426.4l-7.2-1.1 M353.7,424.3c-2.2,0.8-2.9,1.7-2.1,2.5
	 M351.6,427l-1.2-0.1 M317.2,435.8l48,1.4 M338,425.3l-17.9-2.8 M317.2,435.8c1-4,1.9-8.4,3-13.2 M340.6,398.8l0.7-59.2L340.6,199
	 M346.4,199l0.7,140.6l-0.7,59.2 M306.8,420.6l-49.5-7.2l-76.6-10 M139.4,302.3c0.3,1.1,1,1.8,2.1,1.8h7c0.4-0.1,0.8-0.3,1.1-0.7
	c0.7-0.7,1-1.9,1-3.4v-1.9c0-1.6-0.4-2.9-1-3.4c-0.3-0.3-0.7-0.5-1.1-0.7h-6.9c-1.1,0-1.8,0.7-2.1,1.8v6.6L139.4,302.3L139.4,302.3z
	 M144.4,308.5h2.2l0.8,0.1c0.4,0,0.7,0.1,1.1,0.4c0.6,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.4,1.1,0.4,1.8v4c0,1.1,0,2.1-0.3,2.8
	c-0.3,0.8-0.6,1.4-1.1,1.6c-0.3,0.1-0.6,0.3-1,0.4h-6.2c-0.8,0-1.5-0.5-1.9-1.5l-0.1-9.5c0.3-0.8,0.8-1.2,1.8-1.4H144.4L144.4,308.5
	z M139.9,326.5l0.4,9.1c0.1,1,0.7,1.5,1.6,1.7h6.5c0.4-0.1,0.7-0.3,1-0.7c0.6-0.5,0.8-1.5,1-2.9v-4.8c0-0.8-0.1-1.6-0.4-2.2
	c-0.3-0.7-0.7-1.1-1.2-1.4c-0.3-0.1-0.7-0.3-1-0.4l-0.8-0.1h-5.2C140.7,325,140.2,325.6,139.9,326.5L139.9,326.5z M143.8,320.9
	c-0.1-4.1-0.3-8.3-0.3-12.4 M143.3,293.6v10.5 M144.3,353.9h-1c-1.1,0.3-1.8-0.1-2.1-1.2l-0.6-9.8c0.1-0.8,0.6-1.4,1.5-1.4h5.4
	c1.4,0,2.3,0.5,3,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M150.4,343.2h-6.1v10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M144.3,353.9h4.4c1.5-0.3,2.3-1.9,2.2-5v-3.3c0-1.1-0.1-1.9-0.6-2.5
	 M154.2,390.5c-0.4,2.9-1.2,4.4-2.2,4.4s-2.1-1.6-3.2-4.8l-0.1-0.4c-0.4-1.4-0.8-2.8-1.1-4.4c-0.6-2.5-1-5.1-1.2-8
	c-0.1-1.6-0.3-3.2-0.3-4.5c-0.1-2.9,0-5.5,0.3-7.8c0.3-1.8,0.6-3,1-3.9c0.4-0.8,0.8-1.2,1.4-1.2c1.1,0,2.2,1.6,3.3,5.1l0.3,0.8
	l0.8,3.3 M144.4,372.3l0.4,1.4l0.1,0.1c0.4,1.6,0.8,3.7,1,6.2c0.3,2.5,0.3,4.5,0,6.2l-0.3,1.6 M145.7,387.9c-0.1-0.4-0.4-1-0.6-1.6
	c-0.4-1.7-0.8-3.7-1.1-6.2c-0.1-2.5-0.1-4.5,0-6.2c0.1-0.7,0.1-1.2,0.3-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M145.7,387.9l-0.1,0.3c-0.1,0.4-0.4,0.7-0.6,0.7c-0.6,0-1-0.8-1.5-2.6
	c-0.4-1.7-0.8-3.7-1-6.2c-0.3-2.5-0.3-4.5,0-6.2c0.1-1.1,0.3-1.8,0.6-2.2c0.1-0.3,0.3-0.4,0.6-0.4s0.6,0.3,1,1 M153.1,369l-0.4,0.5
	c-0.3,0.5-0.4,1.2-0.6,2.3v0.3c-0.3,2.3-0.3,5.1,0,8.4c0.3,3,0.8,5.6,1.4,7.8l0.3,0.8l0.6,1.2 M143.9,325c0.1,4.1,0.4,8.3,0.7,12.4
	 M97.3,363.5l-1,0.7c-1.9,1.5-3,2.9-3,4.1l2.6,28.8c0.3,3.9,1.1,6.3,2.3,7.3l2.6,1.9c1.5,1,2.5,2.2,3,4.1
	c3.7,13.8,7.6,25.3,11.7,34.5c1,2.3,2.9,3.6,5.8,3.4h2.9 M104.3,363.4l-0.1,0.1 M104.3,363.4l-1.1,0.1 M107.6,363.5l-3.3-0.1
	 M104.1,363.5h3.4 M104.3,363.4l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M104,403c-0.7-1.2-1.1-3.2-1.2-5.8l-2.6-28.8c0.1-1.2,1.1-2.9,3-5
	 M97.3,363.5l0.4-0.3 M103.2,363.5h-5.9 M126.1,363.3H126 M130.8,395.3l-0.8-27.5l-3.9-4.5 M126,363.3h-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M126.1,363.3l2.3,4.5l1,32.6 M123.1,363.3h3 M123.1,363.3l-15.5,0.3
	 M130.8,395.3l0.1,3l-1.5,2.1 M107.8,408.5l-0.8-1.7c-0.4-0.7-1-1.4-1.5-2.1l-1.4-1.5L104,403"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M129.4,400.4l-1.5,2.1v1 M127.9,403.4v3.7l-0.8,2.2l5.5,34.8
	c0.7,2.9,0.1,4.4-1.8,4.3h-3.3 M127.9,403.4L104,403 M107.8,408.5l19,0.4 M124.1,448.5h3.3 M124.1,448.5c-1.9-0.1-3-1-3.3-2.6
	l-12.9-37.3 M181.6,397c0-0.3-0.1-0.4-0.4-0.5l-5.4-1.4c-0.3-0.1-0.4,0-0.6,0.1 M175.3,395.2l-0.1,0.1l-0.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M175,396.4l-0.3-0.1l-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.3
	c0-0.1,0.1-0.3,0.3-0.3h0.4l0.4,0.1 M174.7,397.1l-1,0.5l-0.7,2.2 M174.7,397.1l-0.3,0.8c-0.1,0.3,0,0.5,0.4,0.7l1.6,0.4 M175,396.4
	l-0.3,0.7 M181.2,398.2l0.3,0.1h0.4l0.3-0.3l0.1-0.3c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.1-0.4-0.3l-0.3-0.1 M178.6,399.6l1.6,0.4
	c0.3,0.1,0.6,0,0.6-0.4l0.1-0.5 M180.9,402l0.6-2.5L181,399 M181,399l0.1-0.8 M181.2,398.2l0.4-1.2 M178.6,399.6l-2.1-0.5
	 M180.9,402c-0.1-0.1-0.1-0.1-0.4-0.3l-1.2-0.4c-0.3-0.1-0.6,0-0.6,0.4l-0.4,1.4 M180.6,403.4l0.3-1.1V402 M178,403l-0.3,1.1
	 M178.3,403.2l-0.3,1 M178,404.1h-0.1 M177.7,404.1h0.1 M178.3,403.2L178,403 M179.7,405.8h-0.3l-1.2-0.3c-0.3-0.1-0.4-0.3-0.3-0.5
	l0.1-0.5 M179.7,405.8l0.3-0.1l0.1-0.3l0.6-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178,403l0.1-0.5l0.4-2.9 M176.5,399l-1.4,2.3v0.3l-0.3,1 M174.8,402.6
	h-0.3 M174.6,402.6l0.6-1.9c0.1-0.4,0-0.5-0.4-0.7l-1.2-0.4h-0.4 M173.1,399.9l-0.3,0.3l-0.6,2.2 M172.1,403.6l-0.1,0.7
	c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.3c0.3,0.1,0.6,0,0.6-0.3l0.1-0.7V404 M174.6,403.3v0.1 M174.3,403.8l0.1-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M172.1,403.6c0,0.1,0.1,0.1,0.3,0.1l1.2,0.4h0.4l0.1-0.1 M177.5,404.5H177
	L175,404c-0.3-0.1-0.4-0.3-0.4-0.5 M174.4,403.3h0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M174.6,403.4l-0.3,1c-0.1,0.3,0,0.5,0.3,0.5l1.9,0.5
	c0.3,0.1,0.6,0,0.6-0.3l0.1-0.7 M177.5,404.5l0.1-0.3l0.1-0.1 M174.6,402.6l-0.1,0.7 M174.8,402.6l-0.3,0.7 M177.9,404.1l-0.4,1.6
	c-0.1,0.3,0,0.5,0.3,0.5l1.2,0.4c0.3,0.1,0.6,0,0.6-0.4l0.1-0.5 M154.2,390.5l0.1-0.7c0.4-3.4,0.4-7.6,0-12.4
	c-0.3-3-0.7-5.9-1.2-8.4 M147.5,396.1c-1.2-1.4-2.3-3.7-3.4-7 M147.5,396.1c-0.6-0.4-1.1-0.8-1.6-1.4c-3.2-2.9-5.5-3.6-7.3-2.3
	l-7.7,2.9 M150.1,397.8l-2.6-1.6 M142.2,389.1h1.8 M172.2,402.3l-0.3,0.8v0.3l0.1,0.1 M150.1,397.8c1.8,1,4,1.8,6.5,2.5l15.7,2.1
	 M149.9,456.2l-1.8-0.7c-5.1-2.1-8-4.7-8.9-7.7c-2.8-11.6-4.8-25.7-6.2-42.4c-0.4-3.7,4.4-6.9,14.3-9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.1,456.2l0.3-0.3l-0.1-0.3c-0.1-0.3-0.4-0.5-1-0.8
	c-0.8-0.5-1.8-1-3-1.4c-1.2-0.4-2.3-0.7-3.3-0.8c-1-0.1-1.5,0-1.5,0.3c-0.1,0.4,0.1,1,1,1.5c0.6,0.4,1.2,0.8,2.1,1.2l1.9,0.7
	 M152.4,456.2h-2.5 M127.4,448.5c1.9,2.8,5.8,5.1,11.3,7.2l2.1,0.7h9.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.1,456.2c-0.3,0.3-0.8,0.3-1.5,0.3s-1.5-0.1-2.3-0.3 M267.7,456.2
	H156.1 M365.6,438.2l9.5-3.3 M382.8,470.7l-12-32.5 M695.8,289.1h-5.4 M698,288.4h-2.2v0.7 M700.2,284.6c-0.7,0.1-1.5,0.3-2.2,0.5
	v3.2 M695.8,291.3h-5 M695.8,289.1v2.2 M695.8,309.7v-2.2 M698,310.4h-2.2v-0.7 M700.2,314.3l-2.2-0.7v-3.2 M695.8,309.7h-5.4
	 M695.8,307.5h-5 M710.8,314.1v-0.5 M695.8,307.5v-16.2 M698,288.4v22 M710.8,313.6c-3.3,1.2-6.9,1.5-10.6,0.7 M700.2,284.6
	c3.7-0.8,7.3-0.7,10.6,0.5v28.5 M690.4,309.7v-20.6 M700.2,343.3h-4.7c-1.1-0.1-1.7,0.1-2.1,0.7v28.5 M693.6,372.3h-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M718.8,361.7c-4.5-0.1-8.5-0.4-12.1-1.1c-4-1.2-6.1-3.7-6.5-7.6v-9.8
	 M693.6,372.3h1c0.3,0,0.5,0.1,0.5,0.5v5.2c0,0.3-0.1,0.5-0.5,0.5h-1 M690.4,372.3v-62.6 M700.2,314.3v29 M720.3,388.4
	c-1-0.4-1.5-2.2-1.5-5.5v-21.2 M719.5,413.3h2.5c0.7,0,1-0.3,1-1.1v-22.8c0-0.7-0.3-1-1-1h-1.8 M693.6,379.2v-0.5 M693.6,378.7v0.5
	 M693.6,378.7h-3.2 M689.5,388.8l1,0.4 M690.4,389.3l3.3,1.4v6.7c0.1,2.8-0.7,4.3-2.3,4.5 M690.4,388.4v0.8 M692.9,388.4h-2.5
	 M690.4,378.7v9.8 M693.6,379.2c0.1,4.7-0.1,7.7-0.7,9.2 M690.4,401.9v8.4c-0.1,5.9-1.5,10.3-4,13.3 M691.4,401.9h-1 M695.1,401.9
	c1.1,0,1.7,0.5,1.7,1.5v9.9 M696.8,413.3v9.8c0,1.2-0.5,2.1-1.7,2.2 M691.4,401.9h3.7 M690.4,389.3v12.7 M720.3,388.4h-27.4
	 M696.8,413.3h22.7 M695.1,425.4h-4.7 M663.1,455.3c13.3-1.9,27.2-4.8,41.5-8.9c7-2.9,11.3-8.9,12.7-18.2l2.2-14.9 M695.1,425.4
	v-23.5 M690.4,372.3v6.3 M690.4,172.5c-0.7-4.4-5.9-6.6-15.5-6.6 M689.5,209.1l-2.2,2.3 M690.4,289.1V267 M700.2,254.9v29.7
	 M126,363.3V234.6 M103.2,363.5V234.4 M143.3,289.2c0-4.1,0.1-8.3,0.3-12.4 M680.4,237.8v122.4 M500.2,277.3v6.6 M665.3,283.9v-6.6
	 M687.3,211.4v175.1 M687.3,386.5l2.2,2.3 M690.4,425.4c-2.1,0.3-3.3-0.4-4-1.8 M674.9,431.9c9.6,0.1,14.9-2.2,15.5-6.6
	 M690.4,172.5c-2.1-0.3-3.3,0.4-4,1.8"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M306.8,420.6
		c0,0-127.8-17.5-148.8-20.2c0,0-2.8-0.1-6.7-2.3c0,0,2.2,1,4.3-0.8c0,0,2.1-3,2.1-27.2c0,0,0.4-22,0.4-28.5v-42.6v-36.5
		c0,0-0.1-21.5-0.6-35.5c0,0,0-19.8-2.1-26.3c0,0-1.2-1.8-4.5-1c0,0,4-1.8,5.6-2.1l68.6-9.1c0,0,44.7-5.6,81.7-11.3l1.1,9.8
		c0,0,1.1,29.3,1.1,93.4v18.4v17.1c0,0,0,55.3-1.1,94.8L306.8,420.6z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M347.6,414.8
		c0,0-1.5-0.7-1.4-9.2c0,0,1.1-86.1,0.5-106.8c0,0-0.1-73.9-0.4-99.9c0,0-0.7-14.2,1.2-16.1l29.9,6.9c0,0,4.1,0.4,4.1,7.7v101.4
		v97.4v3.7c0,0,0.3,6.3-3.3,7.8C378.3,407.8,348.9,414.8,347.6,414.8z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M396.3,415c0,0-5.8,0.6-5.8-5
		c0,0-0.5-17.2-0.5-35.9v-75.2v-75.7c0,0-0.3-17.7,0.5-34.8c0,0-0.7-5.5,5-5.6l104.7-1.7c0,0,102.9-1.2,166.3,0.8l-0.4,0.7
		c0,0-1.2,0.4-0.8,15.8v100.4v101.8c0,0-0.5,13.1,1.2,15c0,0-64.2,1.4-78.4,1.4c0,0-55.9,0-88-0.5L396.3,415z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M687.3,386.5h-7v-16.8
		c0,0,1-0.3,1.1-1.4v-5.2c0,0-0.3-1.1-1.1-1.6v-20.4V319V299v-17.3v-22.6v-22.4c0,0,1.1-0.3,1.1-1.4v-5.2c0,0-0.3-1.1-1.1-1.6v-17.1
		h7v17.1v32.5V284v15v26.8v19.9v21.7V386.5z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="690.4,389.3 
		689.5,388.8 687.3,386.5 687.3,334.5 687.3,281.1 687.3,211.4 689.5,209 690.4,208.6 690.4,267 690.4,352.4 	"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv2DrTop);
