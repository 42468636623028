import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-modal';
import { find, get } from 'lodash';
import {
  commafy,
  commafyCurrency,
  findDistanceUnits,
  getConfig,
  isValidNumber,
} from '../utils/helpers';
import { localization } from '../utils/localization';
import { loadItemBid, resetBid } from '../actions';
import Fees from './common/Fees';

const styles = {
  customStyles: {
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 300,
      maxHeight: 800,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  proxyHardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    marginTop: 10,
  },
  proxyHardShared: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 100,
    border: '1px solid gray',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '0.9em',
  },
  proxyButtonOnly: {
    height: 40,
    width: '18%',
    margin: 'auto',
    marginBottom: 10,
    fontWeight: '700',
    display: 'block',
    fontSize: '0.9em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: '#787878',
    color: '#ECF0F1',
    border: 'none',
    borderRadius: 3,
    outline: 'none',
  },
  pseudoDropdown: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: '6px 16px',
    cursor: 'default',
    color: '#555',
  },
  link: {
    color: '#4183c4',
  },
};

const defaultState = {
  tabView: '1',
  accountList: [],
  selectedAccount: {},
  paymentTypesArray: [],
  selectedPayment: '',
  isSubmitting: false,
  bidAmount: '',
  bidType: '1',
  bidButton: 'Bid',
  agreed: !!getConfig('agreeToTermsAtLogin'),
};

class BulkLotBidModal extends Component {
  state = defaultState;

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.loadAccounts();
    }
    this.loadAgreed();
  }

  onOpen = () => {
    this.loadMarketplaceFeatures();
    this.loadAccounts();
    this.loadAgreed();
  };

  onClose = () => {
    this.setState(defaultState, () => {
      this.props.onClose();
    });
  };

  loadAccounts() {
    const accountList = this.props.userProfile.user.accountList.filter(
      account => {
        return account.accountNumber.includes('@') ||
          (getConfig('enableBidderBadges') && account.badgeNumber === null)
          ? false
          : true;
      }
    );

    this.setState({ accountList }, () => {
      this.handleAccountSelection(null);
    });
  }

  loadAgreed() {
    const agreedToTerms = sessionStorage.getItem('agreedToTerms');
    if (
      this.state.agreed === undefined && 
        !getConfig('agreeToTermsAtLogin') && 
          ( agreedToTerms && agreedToTerms !== 'false')
    ) {
      this.setState({ agreed: true });
    }
  }

  loadMarketplaceFeatures() {
    // per item
    const marketplaceFeaturesArr = (
      this.props.item.marketplaceFeatures ||
      this.props.item.mpFeatures ||
      ''
    ).split(',');

    // this.setState({});
  }

  onBidSuccess(response) {
    toastr.success(response.wsStatus, response.wsMessage);
    this.onClose();
    setTimeout(() => {
      this.props.loadData();
    }, 1000);
  }

  onBidWarning(response) {
    toastr.warning('Attention', response.wsMessage, { icon: 'warning' });
    this.setState({
      bidAmount: '',
      bidButton: 'Bid',
      isSubmitting: false,
    });

    setTimeout(() => {
      this.props.loadData();
    }, 1000);
  }

  onBidError(error) {
    toastr.error('Error', error.message || 'An unknown error occured');
    console.error(error);
    this.setState({
      bidAmount: '',
      bidButton: 'Bid',
      isSubmitting: false,
    });

    this.props.loadData();
  }

  handleAmountChange = e => {
    const bidAmount = String(e.target.value).replace(/\D/g, '');
    if (bidAmount === '' || isValidNumber(bidAmount)) {
      this.setState({ bidAmount });
    }
  };

  handleAccountSelection = event => {
    // also called on initial load (event will be null)
    const selectedAccount = event
      ? find(this.state.accountList, {
          userId: Number(event.target.value),
        })
      : this.state.accountList[0];

    if (selectedAccount) {
      const paymentTypesArray = selectedAccount.paymentTypesArray || [];
      if (!paymentTypesArray.includes('Pay Later')) {
        paymentTypesArray.unshift('Pay Later');
      }
      const selectedPayment =
        selectedAccount.defaultPaymentType || paymentTypesArray[0];

      this.setState({
        selectedAccount,
        paymentTypesArray,
        selectedPayment,
      });
    }
  };

  handlePaymentSelection = e => {
    this.setState({
      selectedPayment: e.target.value,
    });
  };

  handleBid = e => {
    this.setState(
      {
        isSubmitting: true,
        bidButton: 'Submitting Bid...',
      },
      () => {
        const mpId = getConfig('marketplaceId');
        const itemId = this.props.item.itemId || this.props.item.id;
        const bidType = this.state.bidType;
        const bidAmount = this.state.bidAmount;
        const { userId } = this.state.selectedAccount;
        const paymentType =
          this.state.selectedPayment === 'Pay Later'
            ? ''
            : this.state.selectedPayment;
        const transportationFeeId = 0;
        const transportFee = 0;

        this.props
          .loadItemBid(
            mpId,
            itemId,
            bidType,
            bidAmount,
            userId,
            paymentType,
            transportationFeeId,
            transportFee
          )
          .then(({ response }) => {
            if (response) {
              if (response.responseCode > 1000) {
                this.onBidSuccess(response);
              } else {
                this.onBidWarning(response);
              }
            } else {
              throw new Error('Bid Error');
            }
          })
          .catch(error => this.onBidError(error));
      }
    );
  };

  handleClose = () => {
    this.setState({
      bidAmount: '',
      bidType: '1',
      tabView: '1',
    });
  };

  renderProxyHardTabs() {
    const shared = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      width: 100,
      border: '1px solid gray',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: '0.9em',
    };

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 100,
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            ...shared,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            color: this.state.bidType === '1' ? '#FFF' : '#404040',
            backgroundColor: this.state.bidType === '1' ? '#787878' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.bidType === '2') this.setState({ bidType: '1' });
          }}
        >
          Proxy
        </div>
        <div
          style={{
            ...shared,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 'none',
            color: this.state.bidType === '2' ? '#FFF' : '#404040',
            backgroundColor: this.state.bidType === '2' ? '#787878' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.bidType === '1') this.setState({ bidType: '2' });
          }}
        >
          Hard
        </div>
      </div>
    );
  }

  renderViewTabs() {
    const shared = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 30,
      width: '100%',
      border: '1px solid #83be97',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: '0.9em',
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            ...shared,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            color: this.state.tabView === '1' ? '#FFF' : '#83be97',
            backgroundColor: this.state.tabView === '1' ? '#83be97' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.tabView === '2') this.setState({ tabView: '1' });
          }}
        >
          Bid
        </div>
        <div
          style={{
            ...shared,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 'none',
            color: this.state.tabView === '2' ? '#FFF' : '#83be97',
            backgroundColor: this.state.tabView === '2' ? '#83be97' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.tabView === '1') this.setState({ tabView: '2' });
          }}
        >
          Items
        </div>
      </div>
    );
  }

  renderAccountSelect() {
    if (this.state.accountList.length === 1) {
      const { accountName, city, state } = this.state.selectedAccount;

      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div
            style={{
              border: '1px solid lightgray',
              borderRadius: 5,
              padding: 8,
              cursor: 'default',
            }}
          >
            {`${accountName} – ${city}, ${state}`}
          </div>
        </div>
      );
    }

    const options = this.state.accountList.map((account, index) => {
      const text = `${account.accountName} – ${account.accountNumber} – ${account.city}, ${account.state}`;

      return (
        <option key={index} value={account.userId}>
          {text}
        </option>
      );
    });

    return (
      <div className="form-group">
        <label htmlFor="buyer-account">Buyer Account</label>
        <select
          id="buyer-account"
          className="form-control"
          onChange={event => this.handleAccountSelection(event)}
          value={this.state.userId}
        >
          {options}
        </select>
      </div>
    );
  }

  renderPaymentSelect() {
    if (
      !this.state.selectedAccount ||
      this.state.paymentTypesArray.length < 1 ||
      (getConfig('hideSinglePaymentOption') &&
        this.state.paymentTypesArray.length <= 2)
    ) {
      return null;
    }

    const options = this.state.paymentTypesArray.map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });

    return (
      <div className="form-group">
        <label htmlFor="payment-types">Payment Type</label>
        <select
          id="payment"
          className="form-control"
          onChange={this.handlePaymentSelection}
        >
          {options}
        </select>
      </div>
    );
  }

  renderTermsAndConditions() {
    const { customerTermsLink } = this.props;

    return (
      <div className="form-group">
        <input
          type="checkbox"
          id="agree"
          checked={this.state.agreed}
          disabled={getConfig('agreeToTermsAtLogin')}
          onChange={e => {
            this.setState(prevState => ({
              agreed: !prevState.agreed,
            }));
          }}
        />
        {customerTermsLink ? (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the{' '}
            <a
              href={customerTermsLink}
              target="_blank"
              rel="noreferrer"
              style={styles.link}
            >
              {`${getConfig('shortName')} Terms and Conditions`}
            </a>
          </label>
        ) : (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the Terms and Conditions
          </label>
        )}
      </div>
    );
  }

  renderBidPlaceholder() {
    const { highBid, bidIncrement, startingBid } = this.props.item;

    const nextBid = highBid
      ? Math.max(
          Number(highBid || 0) + Number(bidIncrement || 0),
          Number(startingBid || 0)
        )
      : Number(startingBid || 0);

    const placeholder = nextBid
      ? `Next Minimum Bid: ${commafyCurrency(nextBid)}`
      : 'Enter your bid';

    return placeholder;
  }

  renderBidView() {
    return (
      <form autoComplete="off">
        <div style={{ paddingTop: 20, paddingLeft: 3, paddingBottom: 0 }}>
          {this.renderBidAmountInfo()}
          {this.renderBidIncrement()}
        </div>

        {getConfig('diableHardBid') ? (
          <button disabled style={styles.proxyHardContainer}>
            Proxy Bid
          </button>
        ) : (
          this.renderProxyHardTabs()
        )}
        <div className="form-group">
          <label className="sr-only" htmlFor="bid-amount">
            Amount
          </label>
          <div className="input-group">
            <div className="input-group-addon">
              {localization[getConfig('localization')].currency}
            </div>
            <input
              type="text"
              id="bid-amount"
              className="form-control"
              placeholder={this.renderBidPlaceholder()}
              value={commafy(this.state.bidAmount)}
              onChange={this.handleAmountChange}
            />
          </div>
          <p>* Normal fees apply for simulcast auctions.</p>
        </div>
        <Fees
          bidAmount={this.state.bidAmount}
          bidType={this.state.bidType}
          itemId={this.props.item.itemId || this.props.item.id}
          userId={this.state.selectedAccount?.userId || ''}
        />
        {this.renderAccountSelect()}
        {this.renderPaymentSelect()}
        {this.renderTermsAndConditions()}
      </form>
    );
  }

  renderItemsView() {
    return (
      <div style={{ marginTop: 40, overflowY: 'auto', maxHeight: '50vh' }}>
        {(this.props.bulkLotItems || []).map(item => {
          return (
            <div key={item.listingId}>
              <hr />
              <h4>{item.cardTitle}</h4>
              <p>
                <strong style={{ marginRight: 4 }}>VIN:</strong>
                <span>{item.vin || item.vIN}</span>
              </p>
              <p>
                <strong style={{ marginRight: 4 }}>Location:</strong>
                <span>{item.location}</span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>Bulk Lot Bid</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderViewTabs()}
        {this.state.tabView === '1'
          ? this.renderBidView()
          : this.renderItemsView()}
      </>
    );
  }

  renderFooter() {
    const isDisabled = !!(
      this.state.isSubmitting ||
      !this.state.agreed ||
      !this.state.bidAmount ||
      this.state.bidAmount === '0' ||
      this.state.bidButton !== 'Bid' ||
      !this.state.selectedAccount ||
      !this.state.selectedPayment
    );

    return (
      <div style={styles.footer}>
        <div />
        <div style={styles.buttons}>
          <button
            className="btn btn-primary button-cancel"
            style={{
              opacity: this.state.isSubmitting ? 0.5 : 1,
              minWidth: 100,
              marginRight: 10,
            }}
            disabled={this.state.isSubmitting}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>
          {this.state.tabView === '1' && (
            <button
              className="btn btn-primary button-action"
              style={{
                opacity: isDisabled ? 0.5 : 1,
                minWidth: 100,
              }}
              type="button"
              disabled={isDisabled}
              onClick={this.handleBid}
            >
              {this.state.bidButton}
            </button>
          )}
        </div>
      </div>
    );
  }

  renderBidAmountInfo() {
    let label = '';
    let value = '';

    let { highBid, startingBid } = this.props.item;

    if (highBid > 0) {
      label = 'Current Bid:';
      value = commafyCurrency(highBid);
    } else if (highBid === 0 && startingBid > 0) {
      label = 'Minimum Bid:';
      value = commafyCurrency(startingBid);
    }

    if (value) {
      return (
        <div style={{ margin: '5px 0' }}>
          <strong style={{ marginRight: 4 }}>{label}</strong>
          <span>{value}</span>
        </div>
      );
    }
  }

  renderBidIncrement() {
    const value = commafyCurrency(this.props.item.bidIncrement);
    if (value) {
      return (
        <div style={{ margin: '5px 0' }}>
          <strong style={{ marginRight: 4 }}>Bid Increment:</strong>
          <span>{value}</span>
        </div>
      );
    }
  }

  render() {
    if (
      !this.props.item ||
      !this.props.userProfile.user ||
      !this.props.bulkLotItems.length
    ) {
      return null;
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Bulk Bid Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div style={styles.container}>
          {this.renderHeader()}
          {this.renderDivider()}
          <div style={styles.body}>
            {this.renderContent()}
            {this.renderDivider()}
            {this.renderFooter()}
          </div>
        </div>
      </Modal>
    );
  }
}

BulkLotBidModal.defaultProps = {
  loadData: () => null,
};

BulkLotBidModal.propTypes = {
  item: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  updateHighBid: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { customerTermsLink, marketplaceFeatures, userProfile } =
    state.entities;
  return { customerTermsLink, marketplaceFeatures, userProfile };
};

export default connect(mapStateToProps, {
  loadItemBid,
  resetBid,
})(BulkLotBidModal);
