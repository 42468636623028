import React, { Component } from 'react';

import Icon from '../../ui/icons';
import { CYAN_01 } from '../../../constants/colors';

export default class BackButton extends Component {
  render() {
    const { style, className } = this.props;
    return (
      <div
        className={`core-back-button ${className}`}
        style={style && style}
        onClick={() => window.history.back()}
      >
        <Icon name="LeftArrow" size={17} color={CYAN_01} marginRight={12} />
        <span style={{ color: CYAN_01 }}>Back</span>
      </div>
    );
  }
}
