import React, { Component } from 'react';
import { connect } from 'react-redux';

class Sedan4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 115 800 360">
          <path
            fill="#5F605F"
            d="M585.2,466c3.3,0,6.6-2.7,6.6-9.3c-0.2-6.4-1.9-121.3-1.9-121.3l-57,96.4v26c0,1.6,0.8,2.1,1.2,2.7
	c0.4,0.6,1,5.4,4.5,5.4L585.2,466L585.2,466z M164.5,466c-3.3,0-6.6-2.7-6.6-9.3c0.2-6.4,1.9-121.3,1.9-121.3l56.9,96.4v26
	c0,1.6-0.8,2.1-1.2,2.7c-0.4,0.6-1,5.4-4.5,5.4L164.5,466L164.5,466z"
          />
          <path
            fill="#FFFFFF"
            d="M502.9,137.9l-0.4-0.8c0,0,0.6-0.8,0.8-0.8c0.2-0.2,0.4-0.2,1,0c0.6,0.2,2.1,0.2,2.9,0.6
	c0.4,0.2,1.4,0.8,2.7,1.4c1,0.6,2.3,1.7,2.9,2.1c0.8,0.8,1.4,1.4,1.9,2.1l0.4,0.6c0,0,1,0.2,1.2,0.6c0.2,0.4,0.8,1.4,1.7,2.9
	l0.2,0.2c0,0,0.8,0.6,1,1.2c0.4,0.6,1,1.9,1,1.9s1.2,0.6,2.3,2.1c1,1.6,7,10.1,14.9,24.3c6.4,11.6,11.6,22.9,17.5,38.2
	c1.4,3.7,3.1,7.6,4.5,12h0.6c1.2,0,1.7-0.2,2.3-0.2c0.8,0,2.1-0.6,2.7-1.9c0.6-1.4,3.9-7.2,5.8-10.5c1.9-3.3,3.5-4.7,6.2-4.7
	c9.7,0,30.7,2.1,36.7,5.6c5.8,3.3,7.6,8.7,8,10.1c0.4,1.4,0.4,2.3,0.2,3.5c-1.2,4.7-2.9,6.6-3.7,7.8c-1.4,1.9-3.5,3.3-5.6,4.1
	c-2.5,1-5.8,1.6-9.5,2.1c-6.8,0.8-20.4,1.2-21.9,1.2h-6.2c2.3,1.4,2.9,2.3,4.3,4.7c1.2,2.1,1.2,3.3,1,4.1c-0.2,0.8,0,1.9,0.4,2.7
	c0.4,0.6,0.6,1,1.9,3.9c0,0.4,5.8,16.5,6,16.9c1.7,6,2.7,12.2,3.1,15.1l0.2,0.2c0.4,0.4,0.6,1,1,1.4c0.6,1,1,3.1,1.2,8.9
	c0.2,5.8-0.2,20.8-1.4,42.9c-0.4,7.4-1,14.9-2.1,22.1v3.1c0.2,1.4,1.2,3.9,1.2,6.6c0,2.9-1,7-1,7c-0.2,2.1-0.4-47.5-0.6-45.2
	c-1.2,14-3.1,33.2-4.5,44.6c-1.6,13.2-3.3,25-3.5,26.2c-0.2,1.2,0.8,4.7,1.2,5.2c0.2,0.4,0.2,0.8,0,1.2c-1.2,3.3-0.8,6-3.3,8.9
	c-0.4,0.6-1,0.8-1,0.8s-0.2,0-0.8,0.2v0.2c0,0-8.7,1.6-15.3,2.1c-8.7,0.6-16.1-0.2-16.3,0.6c-0.6,1.2-3.1,1.9-4.5,1.9l-64.4,1.2
	H272.8l-64.6-1.2c-1.6,0-3.9-0.6-4.5-1.9c-16.1,0.4-31.6-2.7-31.6-2.7v-0.2c-0.6-0.2-0.8-0.2-0.8-0.2s-0.6-0.2-1-0.8
	c-2.5-2.9-2.1-4.1-3.3-7.4c-0.2-0.6-0.2-0.8,0-1.2c0.2-0.4,1.2-5.2,1-6.4c-0.2-1.2-1.9-13-3.5-26.2c-1.4-11.4-3.3-30.3-4.5-44.6
	c-0.2-2.5-0.4,47.3-0.6,45.2c0,0-1-4.1-1-7c-0.2-2.7,1-5.2,1.2-6.6v-3.1c-0.8-7.2-1.6-14.6-2.1-22.1c-1.2-21.9-1.4-37.1-1.4-42.9
	c0.2-5.8,0.6-7.8,1.2-8.9c0.4-0.4,0.6-1,1-1.4l0.2-0.2c0.4-2.9,1.4-9.1,3.1-15.1c0-0.4,5.8-16.5,6-16.9c1.2-3.1,1.4-3.3,1.9-3.9
	c0.4-0.8,0.6-1.9,0.4-2.7c-0.2-0.8-0.2-2.1,1-4.1c1.4-2.3,2.1-3.1,4.3-4.7c0,0-1.2,0.2-6.2,0c-1.6,0-8.3-0.4-15.1-1.2
	c-3.7-0.4-7-1-9.5-2.1c-2.1-0.8-4.1-2.3-5.6-4.1c-0.8-1-2.5-3.1-3.7-7.8c-0.2-1-0.2-2.1,0.2-3.5c0.4-1.4,1.6-7.4,7.6-10.7
	c6-3.5,20.2-5.2,29.9-5.2c2.7,0,4.3,1.6,6.2,4.7c1.9,3.3,3.7,9.3,4.3,10.5c0.6,1.4,1,1.9,1.9,1.9c0.6,0,3.7,0.2,4.9,0.4h0.6
	c1.6-4.3,3.1-8.3,4.5-12c6-15.5,11.1-26.8,17.5-38.2c8-14.2,13.8-22.9,14.9-24.4c1-1.6,2.3-2.1,2.3-2.1s0.6-1.4,1-1.9
	c0.4-0.6,1-1.2,1-1.2l0.2-0.2c0.8-1.4,1.4-2.5,1.6-2.9c0.4-0.6,1.2-0.6,1.2-0.6l0.4-0.6c0.4-0.6,1.2-1.4,1.9-2.1
	c0.6-0.4,1.9-1.4,2.9-2.1c1.2-0.8,2.3-1.2,2.7-1.4c0.8-0.4,2.3-0.6,2.9-0.6c0.6-0.2,0.8,0,1,0l0.8,0.8l-0.4,0.8c0.6-1,1-1.2,1.2-1.4
	c15.5-1.9,32.2-3.3,49.3-4.3c29.5-1.6,59-2.1,77.2-2.1s47.7,0.4,77.2,2.1c17.1,1,33.8,2.5,49.3,4.3
	C501.8,136.7,502.2,136.9,502.9,137.9L502.9,137.9z M157.4,345.5c0.4,8,1.2,15.5,2.1,21.9L157.4,345.5L157.4,345.5z M592.8,345.5
	l-2.1,21.9C591.6,361,592.2,353.6,592.8,345.5z"
          />
          <path
            fill="#5F605F"
            d="M161.8,275.4c1.2-3.3,5-13.8,5.6-15.7c-3.1,0-7,0.6-7.2,1c-0.2,0.6-0.8,3.3-0.8,4.5
	c-1.2,0.2-2.5,0.6-2.7,1.2c-0.2,1.4-0.4,3.1-0.4,4.3s0.6,1.9,1.9,2.7C158.7,274.1,160.5,274.9,161.8,275.4L161.8,275.4z
	 M250.9,427.2c0.2,0.4,0.4,1.2,1.2,1.2c1.9,0.2,101.7-0.4,122.4-0.4c20.6,0,121.3,0.6,123.2,0.4c1,0,1.2-1,1.4-1.2
	c0-0.2,0.6-2.3,1-2.5l-1.2-0.2c-3.7-0.2-18.8-1.4-36.3-1.9c-27.4-0.8-64-0.8-87.9-0.8s-60.5,0-87.9,0.8c-17.5,0.4-32.2,1.4-35.9,1.9
	l-1.2,0.2C250.1,424.7,250.7,426.8,250.9,427.2L250.9,427.2z"
          />
          <path
            fill="#E9EAE9"
            d="M212.7,176.7c-6.4,11.6-11.6,22.9-17.5,38.2c-1.4,3.7-3.1,7.6-4.5,12c2.1,0,2.7-0.4,3.1-1.2
	c0.4-1,6.6-16.3,7.4-18.2c0.4-1,1-1.4,1.6-1.9c2.5-6.2,5.2-12.4,7.8-17.7c7.6-16.1,17.5-34,21.7-41.3l-0.2,0.2c0,0-0.8,0.6-1,1.2
	c-0.4,0.6-1,1.9-1,1.9s-1.2,0.6-2.3,2.1C226.6,154,220.8,162.5,212.7,176.7L212.7,176.7z M136.4,233.1c3.9,0.2,12.6,1,19.2,1.6
	c0.2,0,0.6,0,1-0.2c0.4-0.2,1-0.6,1.4-0.8c0.2-0.2,0.2-0.4,0.2-0.6h-0.2c-7.2-0.6-19.2-1.6-22.3-1.9
	C135.8,231.8,136,232.4,136.4,233.1L136.4,233.1z"
          />
          <path
            fill="#5F605F"
            d="M187.2,227.7c-1.4,0.4-5.8,2.1-6.4,2.5c-0.8,0.4-1.2,0.6-2.3,1.4c-0.6,0.4-3.1,2.1-5.4,4.1
	c-2.1,2.1-2.9,3.9-2.9,5.2s0.6,1.6,1.4,1.9c0.8,0.2,10.5,0,10.7,0l4.5,0.2c0,0.2,0.2,0.2,0.2,0.4c0.2,0.4,0.4,0.6,0.6,1
	c0.2-0.4,0.8-1.2,1-1.4c1.2-3.7,7.2-20.4,14-37.1c-0.6,0.2-1.2,0.8-1.6,1.9c-0.8,1.9-7.2,17.1-7.4,18.2c-0.4,0.8-1,1.2-3.1,1.2h-0.4
	h-0.8C188.8,227.3,188,227.5,187.2,227.7L187.2,227.7z M376.4,129.9c-0.2-1-0.4-2.5-0.4-2.7c-0.2-0.6-0.6-0.8-0.8-0.8
	c-0.2,0-0.8,0.2-0.8,0.8l-0.4,2.7L376.4,129.9L376.4,129.9z"
          />
          <path
            fill="#E9EAE9"
            d="M278.6,304.9c-2.9,2.5-15.7,13.8-17.1,15.1c-1.6,1.4-3.1,2.1-6.4,2.1c-3.3,0-23.5-1.4-26-1.7
	c-2.5-0.2-3.7,0-5.4,0.4c-1.6,0.4-16.7,5.2-18.4,5.6s-2.1,0.6-4.3,0.6s-16.1-1.6-17.7-1.9c-1.6-0.2-2.3-0.6-2.5-0.8
	c-1-1-1.4-2.3-2.1-3.9c-1.2-3.5-1.6-16.7-1.4-19.2c0.2-2.5,0.4-3.3,1-4.7c0.6-1.4,2.3-3.3,5.2-3.3c9.9-0.4,20.8,0.8,24.4,1.4
	c0.6,0,1.2,0.2,1.2,0.2c0.6,0,1.2-0.2,1.2-0.4c0.2,0.2,0.6,0.4,1.6,0.4c1.4,0.2,25,3.5,43.3,6.4
	C264.3,302.4,272.4,303.8,278.6,304.9L278.6,304.9z"
          />
          <path
            fill="#5F605F"
            d="M296.1,307.1c1.6-0.6,3.1-0.8,6.6-1.2c11.6-0.8,44-1.9,72.4-1.9c28.5,0,60.9,1,72.4,1.9
	c3.5,0.2,4.7,0.4,6.6,1.2c0.6,0.2,2.1,0.8,4.1,2.7l0.2,0.2c2.7,2.1,9.7,8.7,11.1,10.5c1.2,1.6,1.2,2.5,1.2,3.7
	c-0.2,2.9-1.9,17.7-6.2,35.1c-5,19.6-9.1,31.2-9.1,31.2c-1.2,3.1-1.9,5.8-6.8,6.2c-6.6,0.6-43.1,1-73.5,1s-66.9-0.4-73.5-1
	c-5-0.4-5.6-3.1-6.8-6.2c0,0-4.1-11.6-9.1-31.2c-4.3-17.3-6.2-32.4-6.2-35.1c0-1.2,0-2.1,1.2-3.7c1.4-1.9,8.5-8.5,11.1-10.5l0.2-0.2
	C294,308,295.5,307.3,296.1,307.1L296.1,307.1z"
          />
          <path
            fill="#E9EAE9"
            d="M214.4,203.3c-8,21.5-13.6,37.8-14.2,39.8c48.3-5,127.7-5.2,175-5.2c47.3,0,126.5,0,174.8,5.2
	c-0.8-2.5-6.2-18.8-14.2-39.8c-8.7-22.9-16.5-37.8-20.6-45c-35.9-2.9-103.4-5.2-140.1-5.2s-104,2.3-140.1,5.2
	C230.9,165.8,223.1,180.4,214.4,203.3L214.4,203.3z"
          />
          <path
            fill="#5F605F"
            d="M288.3,412.2C288.3,412.2,288.5,412.2,288.3,412.2c29.1,0.2,66,0,86.7,0s57.8,0.2,86.7,0h0.2
	c24.4-0.2,92-3.5,96.2-3.7c4.1-0.2,7-2.9,8-6c2.7-8.9,3.3-21,3.1-25.4c0-4.1-2.3-5.2-5.2-5.2c-2.1,0-44.2,2.1-71,3.5l-7,10.7
	c0,0-0.2,0.2-0.8,0.2s-8.7,0.8-9.7,1c-1,0.2-1.9,0.6-1.9,1.2c0,0.2,0,0.8,0.6,0.8l9.3-0.2c0.4,0,0.2,0.2,0.2,0.2
	c-2.3,3.5-4.3,6.6-5,7.4c0,0-0.2,0.2-0.4,0.2c-0.2,0-5.4,0.4-6.2,0.6c-1,0.2-2.1,0.6-2.1,1.4v0.4c0,0.2,0.2,0.4,0.4,0.4h5.8
	c0.4,0,0.2,0.2,0.2,0.2c-1.4,1.4-3.5,2.7-6.6,2.9c-2.3,0.2-4.7,0.4-7.6,0.6c-23.1,1.2-64.6,1.2-87.5,1.2s-64.4,0-87.5-1.2
	c-2.9-0.2-5.4-0.4-7.6-0.6c-3.1-0.2-5.2-1.7-6.6-2.9c0,0-0.2-0.2,0.2-0.2h5.8c0.2,0,0.4-0.2,0.4-0.4v-0.4c0-0.8-1-1.2-2.1-1.4
	c-1-0.2-6-0.6-6.2-0.6c-0.2,0-0.4-0.2-0.4-0.2c-0.6-0.8-2.7-3.7-5-7.4c0,0-0.2-0.2,0.2-0.2l9.3,0.2c0.6,0,0.6-0.4,0.6-0.8
	c0-0.8-0.8-1.2-1.9-1.2c-1-0.2-9.1-0.8-9.7-1c-0.6,0-0.8-0.2-0.8-0.2l-6.2-9.3l-1-1.4c-26.8-1.2-68.9-3.5-71-3.5
	c-2.3,0-3.9,0.4-4.7,2.5c-0.2,0.6-0.4,1.6-0.4,2.7c0,4.1,0.4,16.5,3.1,25.4c1,3.1,3.9,5.8,8,6c0.8,0,4.3,0.2,9.7,0.4
	C222,409.9,268.9,412.2,288.3,412.2L288.3,412.2z"
          />
          <path
            fill="#E9EAE9"
            d="M201.4,390.9c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3c0-4.5-3.7-8.3-8.3-8.3S201.4,386.4,201.4,390.9
	L201.4,390.9z"
          />
          <path
            fill="#5F605F"
            d="M587.9,275.4c-1.2-3.3-5-13.8-5.6-15.7c3.1,0,7,0.6,7.2,1c0.2,0.6,0.8,3.3,0.8,4.5c1.2,0.2,2.5,0.6,2.7,1.2
	c0.2,1.4,0.4,3.1,0.4,4.3s-0.6,1.9-1.9,2.7C591.2,274.1,589.1,274.9,587.9,275.4L587.9,275.4z"
          />
          <path
            fill="#E9EAE9"
            d="M536.9,176.7c6.4,11.6,11.6,22.9,17.5,38.2c1.4,3.7,3.1,7.6,4.5,12c-2.1,0-2.7-0.4-3.1-1.2
	c-0.4-1-6.6-16.3-7.4-18.2c-0.4-1-1-1.4-1.7-1.9c-2.5-6.2-5.2-12.4-7.8-17.7c-7.6-16.1-17.5-34-21.7-41.3l0.2,0.2c0,0,0.8,0.6,1,1.2
	c0.4,0.6,1,1.9,1,1.9s1.2,0.6,2.3,2.1C523.1,154,528.9,162.5,536.9,176.7L536.9,176.7z M620.5,233.1c-3.9,0.2-19.6,1-26.2,1.6
	c-0.2,0-0.6,0-1-0.2c-0.4-0.2-1-0.6-1.4-0.8c-0.2-0.2-0.2-0.4-0.2-0.6h0.2c7.2-0.6,26.2-1.6,29.3-1.9L620.5,233.1L620.5,233.1z"
          />
          <path
            fill="#5F605F"
            d="M561.7,228.5c1.4,0.4,5,2.5,5.6,2.9c0.8,0.4,1.2,0.6,2.3,1.4c0.6,0.4,3.5,1.6,5.6,3.9
	c2.1,2.1,3.7,3.1,3.7,4.3c0,1.2,0,1.9-0.8,2.1c-0.8,0.2-10.1,0.4-10.5,0.4l-4.5,0.2c0,0.2-0.2,0.2-0.2,0.4c-0.2,0.4-0.4,0.6-0.6,1
	c-0.2-0.4-0.8-1.2-1-1.4c-1.2-3.7-7.6-20.8-14.4-37.6c0.6,0.2,1.2,0.8,1.6,1.9c0.8,1.9,7.2,17.1,7.4,18.2c0.4,0.8,0.6,1.6,2.7,1.6
	h1.2C560.4,227.5,560.8,228.1,561.7,228.5L561.7,228.5z"
          />
          <path
            fill="#E9EAE9"
            d="M471.1,304.9c2.9,2.5,15.7,13.8,17.1,15.1c1.6,1.4,3.1,2.1,6.4,2.1c3.3,0,23.5-1.4,26-1.7
	c2.5-0.2,3.7,0,5.4,0.4c1.7,0.4,16.7,5.2,18.4,5.6c1.7,0.4,2.1,0.6,4.3,0.6c2.3,0,16.1-1.6,17.7-1.9c1.7-0.2,2.3-0.6,2.5-0.8
	c1-1,1.4-2.3,2.1-3.9c1.2-3.5,1.7-16.7,1.4-19.2c-0.2-2.5-0.4-3.3-1-4.7c-0.6-1.4-2.3-3.3-5.2-3.3c-9.9-0.4-20.8,0.8-24.3,1.4
	c-0.6,0-1.2,0.2-1.2,0.2c-0.6,0-1.2-0.2-1.2-0.4c-0.2,0.2-0.6,0.4-1.6,0.4c-1.4,0.2-25,3.5-43.3,6.4
	C485.3,302.4,477.3,303.8,471.1,304.9L471.1,304.9z M548.3,390.9c0,4.5-3.7,8.3-8.3,8.3c-4.5,0-8.3-3.7-8.3-8.3
	c0-4.5,3.7-8.3,8.3-8.3C544.5,382.7,548.3,386.4,548.3,390.9z"
          />
          <path
            fill="#FFFFFF"
            d="M316.5,347.4c-1.6,0-4.3,0.2-4.5,3.9v25.2c0,0.4,0.2,0.6,0.2,0.6h125.5c0,0,0.2-0.2,0.2-0.6v-0.4v-10.5
	v-14.2c-0.2-3.5-2.9-3.9-4.5-3.9L316.5,347.4L316.5,347.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M573.4,243.2c0.4,0.2,3.1,1.2,5.2,1.2
	c1.9,0,10.7-0.4,19.2-1.2c4.5-0.4,8.7-1,11.6-2.1c2.5-0.8,3.9-1.9,5.8-3.7c1-1,2.9-3.5,4.5-8.3c0.4-1,0.2-2.1-0.2-3.5
	s-2.1-7.4-9.3-10.7c-7.4-3.5-19.4-5.2-31.2-5.2c-3.1,0-5.4,1.7-7.6,4.7c-2.3,3.3-5.2,9.3-6,10.5c-0.6,1.4-1.4,1.9-2.3,1.9
	c-1,0-5,0.4-5.4,0.4s-0.4,0.2-0.4,0.2 M512.6,144.1c-0.2-0.2-0.2-0.4-0.2-0.4c-0.6-1-1.4-2.1-2.5-3.1c-0.6-0.4-1.9-1.4-2.9-2.1
	c-1.2-0.8-2.3-1.2-2.7-1.4c-0.8-0.4-2.3-0.6-2.9-0.6c-0.6-0.2-0.8,0-1,0l-0.8,0.8 M515.2,147c-0.8-1.4-1.4-2.5-1.7-3.1
	c-0.4-0.6-1.2-0.6-1.2-0.6 M515.2,147c0,0,0.8,0.6,1,1.2c0.4,0.6,1,1.9,1,1.9s1.2,0.6,2.3,2.3c1,1.6,7,10.3,14.9,24.6
	c6.4,11.6,11.6,22.9,17.5,38.4c1.4,3.7,3.1,7.6,4.5,12 M213.4,430.7h7.8c16.3,0,43.1,0.8,43.1,0.8h107.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M244.5,137.9c0.6-1,1-1.2,1.2-1.4
	c15.5-1.9,32.2-3.3,49.3-4.3c29.5-1.6,59-2.1,77.2-2.1c18.2,0,47.7,0.4,77.2,2.1c17.1,1,33.8,2.7,49.3,4.3c0.2,0,0.6,0.2,1,0.8
	 M531.1,430.7h-7.8c-16.3,0-43.1,0.8-43.1,0.8H372.2 M588.5,291.2c0.6,0.8,1.2,1.6,1.7,2.3c0.6,1,1,3.1,1.2,8.9
	c0.2,6-0.2,21.3-1.4,43.3c-0.4,8-1.2,15.5-2.1,21.9 M579.8,259.5c-1.2-2.9-1.4-3.1-1.9-3.7c-0.4-0.8-0.6-1.9-0.4-2.7
	c0.2-0.8,0.2-2.1-1-4.1c-1.2-2.3-3.5-4.1-5.8-5.6 M588.9,291.7c-0.4-2.9-1.4-9.1-3.1-15.3c0-0.4-0.2-0.6-0.2-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M585.6,275.6c1-0.4,2.9-1,3.9-1.6
	c1.2-0.8,1.7-1.2,1.7-2.7c0-1.2-0.2-3.1-0.4-4.3c-0.2-0.6-1.4-1-3.3-1.2 M580.2,259.9c3.1,0,7,0.6,7.2,1c0.2,0.6,0.8,3.3,0.8,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M572.6,234.5c0,0,0.2-0.2,0.8-0.2c0.6,0,10.9-0.8,20.6-1.6
	c9.7-0.6,25.2-1.9,27.2-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M576.1,243.2c0.4,0.2,3.1,1.2,5.2,1.2
	c1.9,0,10.7-0.4,19.2-1.2c4.5-0.4,8.7-1,11.6-2.1c2.5-0.8,3.9-1.9,5.8-3.7c1-1,2.9-3.5,4.5-8.3c0.4-1,0.2-2.1-0.2-3.5
	c-0.4-1.4-2.1-7.4-9.3-10.7c-7.4-3.5-19.4-5.2-31.2-5.2c-3.1,0-5.4,1.7-7.6,4.7c-2.3,3.3-5.2,9.3-6,10.5c-0.6,1.4-1.4,1.9-2.3,1.9
	c-1,0-5,0.4-5.4,0.4c-0.4,0-0.4,0.2-0.4,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M568.7,231.2c0,0,36.5-2.9,53.4-3.7 M591.2,233.1c-0.2,0-0.2,0.4,0,0.6
	l1.7,0.8c0.4,0.2,0.8,0.2,1.2,0.2c8.5-0.6,21.7-1.4,26-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M561.5,245.2c0.2-0.2,0.2-0.6,0-1
	c-0.2-0.4-12.6-33.4-23.1-55.7c-9.5-20.2-22.9-43.7-23.3-44.8c-0.6-1-1.4-2.1-2.5-3.1c-0.6-0.4-1.9-1.4-2.9-2.1
	c-1.2-0.8-2.3-1.2-2.7-1.4c-0.8-0.4-2.3-0.6-2.9-0.6c-0.6-0.2-0.8,0-1,0l-0.8,0.8 M517.9,147c-0.8-1.4-1.4-2.7-1.7-3.1
	c-0.4-0.6-1.2-0.6-1.2-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M561.7,244c-0.2-1-11.6-33.6-22.1-55.9
	c-7.6-15.9-17.5-33.8-21.7-41.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M503.7,136.3c2.5,4.3,11.3,20.2,11.3,20.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M518.1,147.2c0,0,0.8,0.6,1,1.2
	c0.4,0.6,1,1.9,1,1.9s1.2,0.6,2.3,2.1c1,1.6,7,10.3,14.9,24.6c6.4,11.6,11.6,22.9,17.5,38.4c1.4,3.7,3.1,7.6,4.5,12"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M514.8,157.1c-0.2-0.4,0-0.8,0.6-0.6c0.4,0.2,3.1,0.8,4.1,2.7
	c6.8,12.2,21.5,41.1,35.5,83.4c0.2,0.4,0.2,0.6-0.2,1 M567.5,243.4c-0.6,1.9-1.7,4.3-2.5,5.6 M566.8,231c-2.9,0.2-6.4,0.4-7.6,0.4
	s-1.2-0.8-1.2-0.8l-0.4-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M561.9,245.4c0,0,0.6-1,1-1.9c0.4-0.6,0.6-1,0.8-1
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M571.2,234.3c0,0-8.3,0.8-9.3,0.8s-1,0.4-0.6,1.2c0.4,0.8,2.5,6,2.5,6
	 M375.1,300.9c-28.7,0-61.3,1-73.3,1.9c-3.5,0.2-5.2,0.4-7.2,1.2c-2.1,0.8-4.7,2.9-5,3.3c-2.7,2.1-10.1,9.1-11.8,11.4
	c-1.6,2.1-1.9,3.5-1.9,5.4c0,1.9,1.4,17.1,6.2,35.7c4.7,18.6,8,29.7,9.5,33c1.4,3.3,2.5,6.4,9.1,7s43.5,1,74.3,1 M198.1,243.8
	c0,0,0-0.4-0.4-0.2c-3.1,0.2-7.8,1.4-9.1,1.9c-1.2,0.4-1.6,1-1.6,1c0,0.2-0.8,1.2-2.3,2.9c-1.4,1.4-7.4,5.8-11.1,9.9
	c-0.8,1-0.6,1.9-0.6,2.7v1.6c0,0.6-0.2,1.4-0.4,2.1c-2.1,4.1-5.8,12-7.4,19 M180.8,324.3c0,0-0.4,0.2-0.4,0.8s-0.6,7-0.6,8
	c0,1-0.4,2.1-1.6,2.1c-1.2-0.2-16.7-2.3-17.5-2.5c-0.8-0.2-1.2-0.2-1.2-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M156.2,301.8c0.4-0.2,1.6,16.9,2.9,30.5
	c1,14.2,3.5,38,5.2,51.4c1.6,13.2,3.3,24.8,3.3,26c0,0.6,0,1.2-0.2,2.5c-0.2,1-0.8,3.3-0.8,3.7s0,0.6,0.2,1.2
	c0.2,0.6,2.1,5.6,3.3,7.4c0.2,0.4,0.6,0.8,1.2,1c1.9,0.4,6.6,1.4,18.2,2.5c4.5,0.4,8.7,0.4,14.2,0.4c0,0,0.8,1.2,3.1,1.7
	c2.3,0.2,1,0.6,17.5,0.6c16.3,0,43.1,0.8,43.1,0.8h107.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M170.4,277.2c0,0.6-0.8,2.5-2.3,3.9
	c-1.4,1.4-7.4,7-10.9,12.6c-0.6,1-1,3.1-1.2,8.9c-0.2,6,0.2,21.3,1.4,43.3c0.4,8,1.2,15.5,2.1,21.9 M159.3,370.9
	c0,1.4-1.2,3.9-1.2,6.6c0,2.9,1,7,1,7 M165.1,265.2c0.4-1.2,1-2.5,1.4-3.7c2.1-4.7,2.1-5,2.7-5.8c0.4-0.8,0.6-1.9,0.4-2.7
	s-0.2-2.3,1-4.1c1.2-2.3,3.5-4.1,5.8-5.6 M158.3,291.9c0.4-2.9,1.4-9.1,3.1-15.3c0-0.4,0.2-0.6,0.2-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M169.6,252.4c0.4,3.9,2.7,7,9.1,17.1
	c6.4,10.1,15.3,21.3,15.3,21.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M167.6,260.7c0.2,0-1.2,3.1-3.3,9.1c-2.1,5.8-3.3,10.7-3.7,10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M161.6,275.6c-1-0.4-2.9-1-3.9-1.6
	c-1.2-0.8-1.6-1.2-1.6-2.7c0-1.2,0.2-3.1,0.4-4.3c0.2-1.4,6-1.6,8.9-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M163.4,269.4c-2.9-0.8-6.2-2.1-6.2-3.1 M164.5,267.1
	c-2.1-0.2-4.3-0.6-4.3-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M167.1,260.1c-3.1,0-7,0.6-7.2,1
	c-0.2,0.6-0.8,3.3-0.8,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M160.1,260.9c0,0.2,2.5,1.7,5.6,2.3 M175,234.3c0,0-0.4-0.2-1-0.2
	c-0.4,0-8.3-0.6-16.1-1.2c-7.8-0.6-20.6-1.9-22.3-1.9 M171.7,243.2c-0.8-0.2-1.4-0.6-1.4-1.9c0-1.2,0.8-3.1,2.9-5.2s4.7-3.9,5.4-4.1
	c1-0.8,1.6-1,2.3-1.4c0.6-0.4,4.9-1.9,6.6-2.5c1.4-0.4,2.9-0.6,2.9-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M186.9,243.2c0,0-4.1-0.2-4.3-0.2
	c-0.4,0-9.9,0.2-10.7,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M175,244.2c0,0-1.2,0.2-6.2,0
	c-1.6,0-8.3-0.4-15.1-1.2c-3.7-0.4-7-1-9.5-2.1c-2.1-0.8-4.1-2.3-5.6-4.1c-0.8-1-2.5-3.1-3.7-7.8c-0.4-1-0.2-2.1,0.2-3.5
	c0.4-1.4,1.6-7.4,7.6-10.7c6-3.5,20.2-5.2,29.9-5.2c2.7,0,4.3,1.6,6.2,4.7c1.9,3.3,3.7,9.3,4.3,10.7c0.6,1.4,1,1.9,1.9,1.9
	c0.8,0,4.7,0.4,5,0.4c2.3,0,3.3-0.2,3.7-1.2c0.2-0.8,6.4-16.1,7.2-18.2c0.8-1.9,1.7-2.3,1.7-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.5,231.2c0,0-29.9-2.9-43.7-3.7 M157.9,232.8c0.2,0,0.2,0.4,0,0.6
	c-0.2,0.2-1,0.6-1.4,0.8c-0.4,0.4-0.6,0.4-1,0.4c-7-0.6-15.7-1.4-19.2-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M204.5,429.5c0,0,16.7-2.7,20.8-2.7
	c5.6,0,15.5-0.2,17.5-0.2c2.1-0.2,3.7-1.7,8-2.1c1.9-0.2,16.9-1.6,36.5-2.3c27.6-0.8,63.8-0.4,87.7-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M249.5,425.6c1.2,0.2,1.2,1.2,1.2,1.6c0.2,0.4,0.4,1.2,1.2,1.2
	c1.9,0.2,102.3-0.4,122.8-0.4"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            cx="209.6"
            cy="389.9"
            rx="6.6"
            ry="6.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M201.4,390.9c0,4.5,3.7,8.3,8.3,8.3
	s8.3-3.7,8.3-8.3c0-4.5-3.7-8.3-8.3-8.3S201.4,386.2,201.4,390.9L201.4,390.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M201.4,390.9c0,4.5,3.7,8.3,8.3,8.3
	s8.3-3.7,8.3-8.3c0-4.5-3.7-8.3-8.3-8.3S201.4,386.2,201.4,390.9L201.4,390.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M200.8,390.7c0,5,3.9,8.9,8.9,8.9
	c6.6,0,11.1-3.9,11.1-8.9c0-5-4.7-8.9-11.1-8.9C204.7,381.8,200.8,385.8,200.8,390.7z M278.2,302.2c-5.8-8.9-22.1-27.2-38-38.6
	c-16.1-11.4-33.4-18.2-39.6-20 M188.6,245.2c-0.2-0.2-0.2-0.6,0-1c0.2-0.4,12.6-33.4,23.1-55.7c9.5-20.2,22.9-43.7,23.3-44.8
	c0.6-1,1.4-2.1,2.5-3.1c0.6-0.4,1.9-1.4,2.9-2.1c1.2-0.8,2.3-1.2,2.7-1.4c0.8-0.4,2.3-0.6,2.9-0.6c0.6-0.2,0.8,0,1,0l0.8,0.8
	 M232.1,147c0.8-1.4,1.4-2.5,1.6-2.9c0.4-0.6,1.2-0.6,1.2-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M188.4,244c0.2-1,11.6-33.6,22.1-55.9
	c7.6-16.1,17.5-34,21.7-41.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M246.4,136.3c-2.5,4.3-11.4,20.2-11.4,20.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M231.9,147.2c0,0-0.8,0.6-1,1.2
	c-0.4,0.6-1,1.9-1,1.9s-1.2,0.6-2.3,2.1c-1,1.6-7,10.3-14.9,24.6c-6.4,11.6-11.6,22.9-17.5,38.4c-1.4,3.7-3.1,7.6-4.5,12"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M226.6,157.1c-2.7,4.1-6.8,10.9-12,20
	c-6.4,11.6-11.6,22.9-17.5,38.4c-1.4,3.7-3.1,7.6-4.5,12"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M578.4,242.8c0.4-0.2,0.6-0.8,0.6-1.4c0-1.2-1.7-3.1-4.3-5.2
	c-2.5-2.1-5.8-3.7-6.4-4.1s-0.8-0.8-1.7-1.2c-0.8-0.4-6.6-3.1-6.6-3.1c-0.4-0.2-1.7-0.4-2.5-0.6c-0.8-0.2-1.2-2.1-1.2-2.1
	c-1.7-3.9-6.4-15.3-7-16.9c-0.8-1.9-1.7-2.3-1.7-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M563.3,243.2c0,0,0.2-0.2,0.6-0.2
	c0.4,0,12.2,0.2,13.2,0c0.4,0,0.8-0.2,1.2-0.4 M523.7,157.1c2.7,4.1,6.8,10.9,12,20c6.4,11.6,11.6,22.9,17.5,38.4
	c1.4,3.7,2.9,7.4,4.5,11.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M502.5,137.3c0.2,0.2,0.2,0.4,0.4,0.6c0.6,1,10.1,18.6,10.3,19
	s0.2,0.8,0.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M247.2,137.9c0.6-1,1-1.2,1.2-1.4
	c15.5-1.9,32.2-3.3,49.3-4.3c29.5-1.6,59-2.1,77.2-2.1s47.7,0.4,77.2,2.1c17.1,1,33.8,2.7,49.3,4.3c0.2,0,0.6,0.2,0.8,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.9,157.7c0,0,0-0.2,0.2-0.8c0.2-0.4,9.7-18,10.3-19 M200.2,243
	c0,0,5.6-16.7,14.2-39.6c8.7-22.9,16.5-37.8,20.6-45c35.9-2.9,103.4-5.2,140.1-5.2s104,2.3,140.1,5.2c4.1,7.2,12,22.1,20.6,45
	c8.7,22.9,14.2,39.6,14.2,39.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235.2,157.1c0.2-0.4,0-0.8-0.6-0.6c-0.4,0.2-3.1,0.8-4.1,2.7
	c-6.8,12.2-21.5,41.1-35.5,83.4c-0.2,0.4-0.2,0.6,0.2,1 M182.6,243.4c0.6,1.9,1.6,4.3,2.5,5.6 M183.2,231c2.9,0.2,6.4,0.4,7.6,0.4
	c1.2,0,1.2-0.8,1.2-0.8l0.4-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M188.2,245.4c0,0-0.6-1-1-1.9
	c-0.4-0.6-0.6-1-0.8-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.9,234.3c0,0,8.3,0.8,9.3,0.8s1,0.4,0.6,1.2c-0.4,0.8-2.5,6-2.5,6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M312.2,377.1c0,0-0.2-0.2-0.2-0.6
	s-0.2-21.5,0-25.2c0.2-3.5,2.9-3.9,4.5-3.9h58.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M313.4,377.1c0,0-0.2-0.2-0.2-0.6v-25
	c0-1.9,1.9-2.7,3.1-2.7h58.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            cx="307.2"
            cy="372.1"
            r="2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M202.6,367.6c0,1.2,0.8,2.1,1.7,2.1
	c0.8,0,1.4-1,1.4-2.3s-0.8-2.1-1.6-2.1C203.2,365.5,202.4,366.4,202.6,367.6L202.6,367.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M209.2,430.5c0,0,9.3-1.6,14-1.9c4.7-0.4,28.3-0.2,28.3-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M216.7,430.9v26.8c0,1.6-0.8,2.1-1.2,2.7
	c-0.4,0.6-1,5.4-4.5,5.4h-46.4c-3.3,0-6.6-2.7-6.6-9.3c0.2-6.4,1.9-116,1.9-116 M373.9,129.7c0.2-1,0.2-2.1,0.4-2.5
	c0.2-0.6,0.6-0.8,0.8-0.8c0.2,0,0.8,0.2,0.8,0.8c0,0.2,0.2,1.2,0.4,2.5 M389.4,324.9c1-1.4,1.6-3.1,1.6-5.2c0-1.9-0.6-3.7-1.6-5
	c-1,1.4-1.9,3.1-1.9,5C387.5,321.6,388.1,323.4,389.4,324.9v5.8 M391.2,326.7c1.4,1,3.3,1.6,5.2,1.6c5,0,8.9-3.9,8.9-8.5
	c0-4.7-3.9-8.5-8.9-8.5c-1.9,0-3.7,0.6-5.2,1.7c1.7,1.9,2.5,4.3,2.5,7C393.7,322.2,392.9,324.7,391.2,326.7L391.2,326.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M396.4,327.4c-1.2,0-2.7-0.4-3.7-1
	c1.4-2.1,2.1-4.3,2.1-6.8c0-2.3-0.6-4.7-2.1-6.6c1.2-0.6,2.5-0.8,3.7-0.8c4.3,0,8,3.5,8,7.6c0,2.1-0.8,3.9-2.3,5.4
	C400.5,326.5,398.7,327.4,396.4,327.4L396.4,327.4z M389.4,308.6v6 M387.3,312.7c0.6-0.2,1.4-0.4,2.1-0.4s1.9,0.4,1.9,0.4
	 M387.3,326.5h3.9 M372.9,312.7c-1.4-1-3.3-1.6-5.2-1.6c-1.9,0-3.7,0.6-5.2,1.6c1.4,1.9,2.5,4.3,2.5,6.8c0,2.7-1,5.2-2.5,7
	c1.4,1,3.3,1.6,5.2,1.6c2.1,0,3.7-0.6,5.2-1.6c-1.6-1.9-2.5-4.3-2.5-7C370.6,317,371.4,314.8,372.9,312.7L372.9,312.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M367.7,327.4c-1.4,0-2.7-0.4-3.9-1
	c1.4-2.1,2.1-4.3,2.1-6.8c0-2.3-0.8-4.5-2.1-6.6c1.2-0.6,2.5-1,3.7-1c1.4,0,2.7,0.4,3.9,1c-1.2,2.1-2.1,4.1-2.1,6.6
	c0,2.5,0.6,4.7,2.1,6.6C370.4,326.9,369.2,327.4,367.7,327.4L367.7,327.4z M374.9,324.7c1-1.4,1.6-3.1,1.6-5c0-1.9-0.6-3.5-1.6-5
	c-1,1.4-1.6,3.1-1.6,5C373.3,321.6,373.9,323.2,374.9,324.7L374.9,324.7z M377,312.7c1.4,1.9,2.5,4.3,2.5,6.8c0,2.7-1,5-2.5,7
	c1.4,1,3.3,1.6,5.4,1.6s3.7-0.6,5.2-1.6c-1.6-1.9-2.5-4.3-2.5-7c0-2.5,1-5,2.5-6.8c-1.4-1-3.3-1.6-5.2-1.6
	C380.1,311.1,378.4,311.7,377,312.7L377,312.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M382.2,327.4c-1.4,0-2.7-0.4-3.9-1
	c1.4-2.1,2.1-4.3,2.1-6.6c0-2.3-0.6-4.5-2.1-6.6c1.2-0.6,2.5-1,3.9-1s2.7,0.4,3.7,0.8c-1.2,2.1-2.1,4.3-2.1,6.6
	c0,2.5,0.8,4.7,2.1,6.8C384.8,326.9,383.6,327.4,382.2,327.4L382.2,327.4z M360.5,324.9c-1-1.4-1.6-3.1-1.6-5.2c0-1.9,0.6-3.7,1.6-5
	c1,1.4,1.9,3.1,1.9,5C362.3,321.6,361.7,323.4,360.5,324.9v5.8 M358.6,326.7c-1.4,1-3.3,1.6-5.2,1.6c-5,0-8.9-3.9-8.9-8.5
	c0-4.7,3.9-8.5,8.9-8.5c1.9,0,3.7,0.6,5.2,1.7c-1.4,1.9-2.5,4.3-2.5,7C356.2,322.2,357,324.7,358.6,326.7L358.6,326.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M353.5,327.4c-4.3,0-8-3.5-8-7.6s3.5-7.6,8-7.6
	c1.2,0,2.7,0.4,3.7,0.8c-1.2,2.1-2.1,4.3-2.1,6.6c0,2.5,0.8,4.7,2.1,6.8C355.9,326.9,354.7,327.4,353.5,327.4L353.5,327.4z
	 M360.5,310.8v3.7 M362.5,312.7c-0.6-0.2-1.4-0.4-2.1-0.4c-0.6,0-1.9,0.4-1.9,0.4 M362.5,326.5h-3.9 M372.9,312.7
	c0.6-0.2,1.4-0.4,2.1-0.4c0.6,0,1.9,0.4,1.9,0.4 M373.1,326.5c0.8-0.2,1.9-0.2,1.9-0.2s1.6,0.2,1.9,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M390.4,312.1c1.9-1.2,3.9-2.1,6-2.1c5.4,0,9.9,4.1,9.9,9.5
	c0,5.4-4.3,9.5-9.9,9.5c-2.3,0-4.3-0.6-6-2.1l-1-0.8l-1,0.8c-1.6,1.2-3.9,2.1-6,2.1c-2.3,0-4.3-0.6-6.2-2.1c0,0-0.6,0.8-1,1.4
	c-0.6-0.8-1-1.4-1-1.4c-1.6,1.2-3.9,2.1-6.2,2.1s-4.3-0.6-6-2.1l-1-0.8l-1,0.8c-1.6,1.2-3.9,2.1-6,2.1c-5.4,0-9.9-4.1-9.9-9.5
	c0-5.4,4.3-9.5,9.9-9.5c2.3,0,4.3,0.6,6,2.1l1,0.8l1-0.8c1.6-1.2,3.9-2.1,6.2-2.1s4.3,0.6,6.2,2.1l1,0.8l1-0.8
	c1.9-1.2,3.9-2.1,6.2-2.1s4.3,0.6,6.2,2.1l1,0.8L390.4,312.1L390.4,312.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M374.9,328.4c-2.1,1.4-4.5,2.5-7.2,2.5
	s-5.2-0.8-7.2-2.3c-2.1,1.4-4.5,2.3-7.2,2.3c-6.4,0-11.6-5-11.6-11.1c0-6.2,5.2-11.1,11.6-11.1c2.7,0,5.2,0.8,7.2,2.5
	c2.1-1.4,4.5-2.3,7.2-2.5c2.7,0,5.2,0.8,7.2,2.5c2.1-1.4,4.5-2.5,7.2-2.5c2.7,0,5.2,0.8,7.2,2.5c2.1-1.4,4.5-2.5,7.2-2.5
	c6.4,0,11.6,5,11.6,11.1s-5.2,11.1-11.6,11.1c-2.7,0-5.2-0.8-7.2-2.3c-2.1,1.4-4.5,2.3-7.2,2.3C379.5,330.9,377,330,374.9,328.4
	 M374.9,324.7v5.8 M374.9,308.6v6 M360.5,308.6v2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M166.5,416.5c29.1-1.2,171.3-1.4,208.6-1.4 M170.2,425
	c10.3-1.6,21-2.3,39.6-2.9c18.6-0.6,37.6-1,39.2-1s3.1-0.4,4.1-0.6c1-0.4,9.3-2.5,12-3.3c2.7-0.6,8.5-1.9,12-1.9 M375.1,397.5
	c-30.3,0-66.9-0.4-73.5-1c-5-0.4-5.6-3.1-6.8-6.2c0,0-4.1-11.6-9.1-31.2c-4.3-17.3-6.2-32.4-6.2-35.1c0-1.2,0-2.1,1.2-3.7
	c1.4-1.9,8.5-8.5,11.1-10.5l0.2-0.2c2.3-1.9,3.7-2.5,4.1-2.7c1.6-0.6,3.1-0.8,6.6-1.2c11.6-0.8,44-1.9,72.4-1.9 M210.7,294.1
	c0,0,0.2,0.2,1.6,0.4c1.4,0.2,25,3.5,43.3,6.4c18.4,2.9,34,5.8,34,5.8c0.6,0,1-0.2,1.4-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375.1,238c-47.5,0-127.1,0-175.4,5.2c-0.6,0-1.6,0.6-1.6,0.6
	c-3.1,2.5-7.4,5.8-10.1,7.8c-2.7,2.1-2.5,3.3-0.6,6c9.7,13.4,20.2,31.2,23.1,36.3c0.2,0.4-0.6,0.6-1.2,0.6s-14-2.1-25.8-1.7
	c-2.9,0.2-4.5,1.9-5.2,3.3c-0.6,1.4-0.8,2.3-1,4.7c-0.2,2.5,0.2,15.7,1.4,19.2c0.6,1.6,1.2,3.1,2.1,3.9c0.2,0.2,0.8,0.6,2.5,0.8
	c1.6,0.2,15.5,1.9,17.7,1.9s2.7-0.2,4.3-0.6c1.6-0.4,16.7-5.2,18.4-5.6c1.6-0.4,2.9-0.6,5.4-0.4c2.5,0.2,22.7,1.6,26,1.6
	s4.7-0.8,6.4-2.1c1.6-1.4,17.1-15.1,17.1-15.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M375.1,413.4c-17.1,0-59.4,0-86.5-0.2
	c-27-0.2-91.8-0.8-98-1.4s-8.9-3.9-9.7-6.6c-3.5-11.1-3.7-27.4-3.3-29.9c0.2-2.3,1-5.2,7.2-4.7c6.2,0.4,80.5,4.1,99.3,4.7
	c0.2,0,0.4,0.2-0.2,0.4c-0.6,0.2-1.4,0.4-2.1,0.6c-0.6,0.2-1.6,0.4-3.1,0.2c-1.4,0-90-4.5-92.9-4.5c-2.9,0-5.2,1-5.2,5.2
	s0.4,16.5,3.1,25.4c1,3.1,3.9,5.8,8,6c4.1,0.2,72.2,3.7,96.4,3.7c28.9,0.2,65.8,0,86.7,0 M283.3,368.6c-18.8-0.6-74.3-3.7-92.2-5
	c-9.9-0.8-20.4-1-29.3-4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M278.8,376.5c-1.4,0-90-4.5-92.9-4.5
	c-2.9,0-5.2,1-5.2,5.2s0.4,16.5,3.1,25.4c1,3.1,3.9,5.8,8,6c4.1,0.2,72.2,3.7,96.4,3.7c28.9,0.2,65.8,0,86.7,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M375.1,404.3c-25.8,0-74.9,0-95.1-1.9
	c-3.1-0.2-5.2-1.7-6.6-2.9c0,0-0.2-0.2,0.2-0.2h5.8c0.2,0,0.4-0.2,0.4-0.4v-0.4c0-0.8-1-1.2-2.1-1.4c-1-0.2-6-0.6-6.2-0.6
	c-0.2,0-0.4-0.2-0.4-0.2c-0.6-0.8-2.7-3.7-5-7.4c0,0-0.2-0.2,0.2-0.2c0,0,8.7,0.2,9.3,0.2c0.6,0,0.6-0.4,0.6-0.8
	c0-0.8-0.8-1.2-1.9-1.2c-1-0.2-9.1-0.8-9.7-1c-0.6,0-0.8-0.2-0.8-0.2c-3.5-5.4-7-10.7-7-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M375.1,410.1c0,0-66.4,0.2-87.1,0
	c-24.4-0.2-91-3.3-94.9-3.5c-3.1-0.2-6.2-2.3-7-5.2c-2.7-8.7-3.3-18.8-3.3-22.7c0-1.4,0-3.1,1-3.9c0.8-0.8,2.1-1,3.3-1
	c1.6,0,32.8,1.4,58.4,2.7c4.5,0.2,8.7,0.4,12.4,0.6c0,0,0.2-0.2,0.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M267.6,395l-0.2-0.2c-0.4-0.6-1.6-2.5-3.1-4.7
	c-0.2-0.4-0.6-0.4-1-0.4c-0.2,0-18.2-0.6-18.8-0.6c-0.4,0-0.6,0.4-0.4,0.6c0.6,1.7,1.2,3.1,1.9,4.7c0,0.2,0.2,0.4,0.6,0.4
	c0.2,0,20.4,1.2,20.6,1.2C267.4,395.9,268,395.7,267.6,395L267.6,395z M271.3,400.2c0.4,0.6,1,1.2,1.6,1.6c0.4,0.2,0.6,0.4-0.2,0.8
	c-0.8,0.4-7.2,3.9-8,4.3c-0.8,0.4-1.2,0.8-2.9,0.8c-1.4,0-9.9-0.4-9.9-0.4s-0.6,0-0.8-0.6c-0.8-2.1-1.9-4.3-3.1-7
	c-0.2-0.4,0-0.8,0.4-0.8c0.6,0,21.3,0.6,21.7,0.6C270.7,399.8,271.1,399.8,271.3,400.2L271.3,400.2z M260.6,384.5
	c-1.9-2.9-3.5-5.4-3.7-5.6c-0.4-0.4-0.8-0.4-0.8-0.4c-0.2,0-14.6-0.6-15.3-0.6c-0.6,0-0.8,0.4-0.6,0.8c0.6,1.9,1,3.5,1.9,5.4
	c0,0.2,0.2,0.4,0.6,0.4s17.1,1,17.5,1C260.4,385.1,261.2,385.1,260.6,384.5L260.6,384.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M202.8,407c8.5,0,16.3,0.6,25-2.1
	c8.9-2.7,15.9-5.2,17.3-5.6c1.4-0.4,2.5-0.4,3.1,0 M222.6,407.4c7.8,0,26.8,0.2,27.6,0.2c0.8,0,1-0.2,1-0.2 M263.5,408
	c0,0,5.2,0.6,5.8,0.8c1.9,0.2,4.7,0.2,5.4,0.2c20.4,0.6,90,0.4,100.5,0.4 M273.4,402c0.4,0.4,1.2,0.8,2.1,0.8c0.8,0,2.5,0,3.9,0.6
	c1.4,0.6,1.2,0.8,3.1,1c21.3,1.6,67.7,1.6,92.9,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M281.9,404.5c0,0-4.7,3.1-5.4,3.3
	c-0.6,0.2-0.4,1,0.4,1 M278.6,403.5c0,0-7.4,5-7.8,5.2 M219.8,375.6c3.9,0.4,17.5,1.6,18.6,1.9c0.8,0,1.9,0.2,2.1,0.8 M213.4,375
	c2.9,0.2,7.8,1,11.8,2.5c3.9,1.6,14.2,6,15.3,6.4c1.2,0.4,1.4,0.2,1.6,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M279.6,399.4c-0.4,0-7.6-0.2-18-0.6
	c-8.3-0.4-18.6-0.6-19.2-0.8c-0.6,0-1,0.2-1.4,0.4c-0.8,0.4-15.9,5.6-17.3,6.2c-1.9,0.6-3.7,0.8-5.6,1c-3.9,0.2-20.4-0.8-22.5-1
	c-2.5-0.4-3.5-2.3-4.1-3.5c-2.7-7-3.3-18.2-3.3-20.8c0.2-2.9,1.9-4.3,4.7-4.1c1.6,0,20,1,23.7,1.2c3.9,0.2,5.8,0.8,7.6,1.7l13.8,6.4
	c1.2,0.6,1.9,0.8,2.5,0.8c0.8,0,20.4,1,32.8,1.4c0.8,0,2.1,0.2,2.5,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M243.9,389c-0.4-0.4-1.2-0.4-2.1-0.4s-6-0.4-7-0.4
	c-0.8,0-1.4,0-2.3-0.6c-0.8-0.4-10.5-5.2-12.4-6.2c-1.9-0.8-3.9-1.2-5-1.2c-9.7-0.8-20.6-1.4-22.3-1.6c-1.2,0-1.2,0-1.4,1v0.8
	c0,3.3,0.8,13,3.1,19c0.4,1,0.8,1.2,1.6,1.4c1.4,0.2,12.2,1,19.2,0.8c1.2,0,3.5-0.4,5.4-1c1.9-0.8,13.2-5.4,14-5.6
	c0.8-0.2,1.7-0.4,2.3-0.4c0.6,0,7.2,0.2,7.6,0.2s0.8,0,1-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M276.1,389c0,0-34.7-1.4-35.7-1.6
	c-1,0-1.4-0.4-3.1-1c-1.4-0.6-12.2-5.6-13.6-6.4c-1.4-0.6-3.3-1.2-7-1.4c-3.7-0.2-21.9-1.2-23.7-1.2c-1.9,0-2.9,0.4-3.1,2.7
	c-0.2,2.3,0.6,13.4,3.1,20.2c0.4,1,1,2.3,2.9,2.5c1.6,0.2,18.4,1.2,22.1,1c1.9,0,3.5-0.4,5.2-0.8s16.7-5.8,17.3-6s1-0.4,2.1-0.4
	c1,0,33.8,1.2,34.5,1.2c0.8,0,1.9,0.2,2.3,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M215.8,380.8c-7.2-0.4-13.6-0.6-14.9-0.6
	c-1.4,0-2.7,0.4-2.7,2.1c0,1.6,0.4,9.3,2.5,16.5c0.4,1,0.4,1.9,2.5,2.1c2.1,0.2,9.1,0.6,14.9,0.6 M287.6,403.1c0,0.2,0,1-0.6,1.6
	 M286.8,404.7v4.3 M287.2,409.3c0.2,0.2,1,1,1,3.1 M260.6,385.3c0.2,0.2,0.8,0.4,1.2,0.6c0.4,0.2,1.2,0.6,2.3,0.4 M264.1,389.5
	c0.2-0.2,0.8-0.4,1-0.4h0.8 M267.6,395.9c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,1.6,0.6,2.5,0.4 M271.3,399.8c0.2-0.2,0.8-0.6,1.9-0.4
	 M306.6,405.6v3.7 M308.5,405.6v3.7 M331.2,406v3.3 M333,406v3.3 M359.5,406.2v3.1 M361.1,406.2v3.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M341.1,315c-3.3,0-42.3-0.2-52.8,0.4c-0.6,0-0.2-0.4-0.2-0.4
	s0.8-1.2,1-1.4c0.2-0.2,0.6-0.2,0.6-0.2c8.7,0.8,48.9,0.6,52,0.6c0,0,0.2,0,0.2,0.2c-0.2,0.2-0.4,0.6-0.4,0.6S341.3,315,341.1,315
	L341.1,315z M341.7,325.7c-3.3,0-50.1-0.2-60.7,0.4c-0.4,0-0.4-0.4-0.4-0.4v-2.1c0-0.2,0.2-0.2,0.4-0.2c9.5,1.6,57.2,1.2,60.5,1.2
	c0.2,0,0.4,0,0.4,0.2c0,0.2,0.2,0.6,0.2,0.6S342.1,325.7,341.7,325.7L341.7,325.7z M359,331.5c-0.6,0.2-0.4,1.2,0.6,1.2h2.1
	c0.8,0,1.2-0.8,0.6-1.2 M375.1,335c-3.3,0-69.5,0.6-80.5-0.2c-1.9,0-10.3-0.8-13-1c-0.2,0-0.4,0-0.4,0.2c0,0.2,0.4,2.3,0.4,2.3
	s0,0.4,0.4,0.4c10.7-0.4,89.8-0.2,93.1-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M293.4,310.8c-1,0.2-2.7,0.6-3.5,1.2c-0.6,0.6-2.1,2.5-2.3,2.9
	c-0.2,0.4,0,0.6,0.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M343.4,310.8c0,0.2-0.2,0.6,0,0.8
	c0,0.2,0,0.4-0.2,0.8c-0.2,0.4-1,1.6-1.2,1.9c-0.2,0.2-0.6,0.8-0.8,1c-0.4,0.2-0.6,0.6-0.8,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M374.9,308.6c-2.1-1.2-4.5-2.1-7.2-2.1s-5.2,0.6-7.2,2.1
	c-2.1-1.2-4.5-2.1-7.2-2.1c-3.9,0-7.6,1.6-10.1,4.3 M342.5,327.1c2.5,3.3,6.4,5.4,10.9,5.4c2.5,0,5-0.6,7.2-2.1
	c2.1,1.2,4.5,2.1,7.2,2.1c2.5,0,5.2-0.8,7.2-2.1 M340.3,316.2c-0.2,1-0.4,2.1-0.4,3.3c0,0.6,0,1.4,0.2,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M340.1,321.6c0,0.2,0.4,0.6,0.6,0.8
	c0.2,0.2,0.6,0.4,0.8,0.8c0.2,0.4,0.6,1.4,0.8,1.9c0.2,0.4,0.2,0.4,0,0.6c0,0.2-0.2,1,0,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M282.7,321c1.2-1.2,3.3-3.3,5.4-5.2 M282.7,321c0,0-1.4,0.6-1.6,0.8
	c-0.4,0.2-0.8,0.8-0.8,1.4c0,0.6,0.2,2.3,0.2,2.3s0,0.4,0.2,0.6c0.2,0.2,0.6,0.4,0.6,0.4 M375.1,345.7c-3.3,0-68.7,0.2-78.8-0.4
	c-1.9,0-10.3-0.6-13-0.8c-0.2,0-0.4,0-0.4,0.2s0.4,2.3,0.4,2.3s0,0.4,0.4,0.4c14.6-0.8,87.9-0.2,91.2-0.2 M375.1,377.1
	c-18,0-62.5,0.2-71.4,0c-1.6,0-9.1-0.6-12.2-0.8c-0.2,0-0.4,0-0.4,0.4c0,0.2,0.4,1.9,0.4,1.9s0.2,0.4,0.4,0.4
	c13.8-0.4,64-0.4,82.9-0.2 M281.7,331.5c-0.2-2.1-0.4-3.7-0.6-5 M281.7,331.5l-0.6,0.8c0,0-0.2,0.2-0.2,0.8s0.4,2.9,0.4,2.9
	s0,0.6,0.4,0.8c0.2,0.2,0.8,0.4,0.8,0.4 M373.1,331.5c-0.6,0.2-0.4,1.2,0.6,1.2h1.2 M312,356.2c-6.2,0-10.9-0.2-13.2-0.2
	c-1.9,0-10.3-0.6-13-0.8c-0.2,0-0.4,0-0.4,0.2c0,0.2,0.4,2.3,0.4,2.3s0,0.4,0.4,0.4c4.3-0.2,14-0.4,25.6-0.4 M312,366.8
	c-5,0-8.7-0.2-10.5-0.2c-1.9,0-10.3-0.6-13-0.8c-0.2,0-0.4,0-0.4,0.2c0,0.2,0.4,1.9,0.4,1.9s0,0.4,0.4,0.4
	c4.1-0.2,12.6-0.4,22.9-0.4 M283.1,342.2c-0.2-1.9-0.6-3.5-0.8-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292.4,379.6c0,0-0.6-0.4-0.8-0.6l-0.4-0.4c-1.4-5.2-3.3-11.6-5.4-19.4
	c-1.4-5.6-2.7-10.7-3.5-15.5V343c0-0.2,0.6-0.6,0.6-0.6 M284.8,354.2c4.7,0.2,15.1,0.6,27,0.6 M287.4,364.7
	c4.5,0.2,13.6,0.6,24.3,0.8 M290.3,375.2c4.5,0.2,12.4,0.6,21.5,0.8 M375.1,388h-67.1c-3.1,0-10.5-0.4-13.2-0.8
	c-0.2,0-0.4,0-0.2,0.2c0,0.2,0.4,1.6,0.4,1.6s0,0.4,0.4,0.4c10.7-0.4,76.3-0.2,79.6-0.2 M294,384.5c-0.4-1.4-1-3.1-1.6-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M294,384.5c0,0-0.2,0.8-0.2,1c0,0.4,0.2,1,0.2,1.7
	c0.2,0.6,0.6,2.1,0.6,2.1s0,0.2,0.4,0.4c0.2,0.2,0.8,0.6,0.8,0.6 M374.9,336.4c-1.6,0-7,0.4-11.8,1.4v5c1.4,0.4,6,0.8,11.8,0.8"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="374.7"
            cy="340.1"
            r="1.7"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="374.7"
            cy="340.1"
            r="2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M403.2,319.3c0,2.1-1.7,3.7-3.7,3.7s-3.7-1.6-3.7-3.7
	c0-2.1,1.7-3.7,3.7-3.7S403.2,317.2,403.2,319.3L403.2,319.3z M356.8,306.9v-1.2 M357.2,307.1v-2.3 M357.6,307.1v-1.4 M356.8,305.7
	c0-0.2,0.4-0.8,0.4-0.8c0.2,0,0.4,0.4,0.4,0.8 M325.8,310.8l-0.2-4.5 M326.2,311.7l-0.2-6.2 M326.6,310.8l-0.2-4.5 M325.6,306.3
	c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M326.6,310.8c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M326.2,321.8l-0.2-5.4
	 M326.6,322.4l-0.2-6.8 M327.1,321.8l-0.2-5.4 M326,316.4c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M327.1,321.8
	c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M298.2,321.6l-0.2-5.4 M298.6,322.2l-0.2-6.8 M299,321.6l-0.2-5.4 M298,316.2
	c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M299,321.4c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M299.2,331.9l-0.4-4.7
	 M299.6,332.7l-0.4-6.4 M300,332.1l-0.4-5 M298.8,327.1c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.6,0.8 M300,331.9
	c0,0.2-0.2,0.6-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M300.2,342.6l-0.4-5 M300.6,343.5l-0.4-6.4 M301.1,342.8l-0.4-5.2 M299.8,337.7
	c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.6,0.8 M301.1,342.6c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M305.2,384.9l-0.6-5.2
	 M305.6,385.8l-0.6-6.6 M306,384.9l-0.4-5.2 M304.6,379.8c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.6,0.8 M306,384.9
	c0,0.2-0.2,0.6-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M306.4,395.7l-0.4-5 M307,396.5l-0.6-6.6 M307.5,395.7l-0.6-5 M306,390.7
	c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.6,0.8 M307.5,395.7c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M326.6,332.3v-5.2
	 M327.1,333.1l-0.2-6.6 M327.5,332.3v-5.2 M326.6,327.1c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M327.5,332.3
	c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M327.3,342.8l-0.2-5 M327.9,343.7l-0.4-6.4 M328.3,342.8l-0.4-5 M327.1,337.9
	c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M328.3,342.8c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M357.6,342.8l-0.2-5
	 M358,343.5l-0.2-6.2 M358.4,342.8v-5 M357.4,337.9c0-0.2,0.2-0.6,0.4-0.6c0,0,0.4,0.4,0.4,0.6 M358.4,342.8c0,0.2-0.2,0.6-0.4,0.6
	c0,0-0.4-0.2-0.4-0.6 M359,385.1l-0.2-5.2 M359.5,385.8l-0.2-6.4 M359.9,385.1l-0.2-5.2 M358.8,380c0-0.2,0.2-0.6,0.4-0.6
	s0.4,0.4,0.4,0.6 M359.9,385.1c0,0.2-0.4,0.6-0.4,0.6s-0.4-0.2-0.4-0.6 M359.2,396.3l-0.2-5.4 M359.7,396.9l-0.2-6.6 M360.1,396.5
	v-5.6 M359,390.9c0-0.2,0.4-0.6,0.4-0.6s0.4,0.4,0.4,0.6 M360.1,396.3c0,0.2-0.2,0.4-0.4,0.4c0,0-0.4-0.2-0.4-0.4 M330.4,385.1
	l-0.4-5.2 M330.8,385.8l-0.4-6.4 M331.2,385.1l-0.4-5.2 M329.9,380c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M331.2,385.1
	c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M331.2,396.1l-0.4-5.2 M331.6,396.9l-0.4-6.8 M332,396.3l-0.4-5.4 M330.8,390.9
	c0-0.2,0.2-0.8,0.4-0.8c0,0,0.4,0.4,0.4,0.8 M332,396.1c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M297.5,310.8l-0.2-2.5
	 M298,311.7l-0.2-4.3 M298.4,310.8l-0.2-2.9 M297.3,308.4c0-0.6,0.2-1,0.4-1c0,0,0.4,0.4,0.4,0.6 M298.4,310.8
	c0,0.2-0.2,0.8-0.4,0.8c0,0-0.4-0.4-0.4-0.8 M357.6,305.5c5.8,0,11.8-0.2,17.3-0.2 M326.4,306.3c9.1-0.2,19.6-0.6,30.3-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298.2,308c1.2-0.4,2.5-0.4,5.2-0.6c4.5-0.4,12.6-0.6,22.3-1 M293.4,310.8
	c2.1-1.6,3.3-2.3,3.7-2.5c0,0,0.2,0,0.2-0.2 M326.6,311.1h16.7 M298.4,310.8h27.4 M293.4,310.8h4.1 M326.9,316.4
	c7,0,12.4,0,13.4-0.2 M298.8,316.2c8,0.2,18.8,0.2,27.2,0.2 M287.9,315.8c1.9,0.2,5.6,0.4,10.1,0.4 M327.1,321.8h13 M299,321.6
	c9.1,0.2,19.2,0.2,27,0.2 M282.7,321c3.9,0.2,9.5,0.4,15.5,0.4 M327.5,327.4h15.1 M281,326.5c2.7,0.2,9.5,0.4,17.7,0.6 M327.5,332.3
	c6.2,0,12.2,0.2,18,0.2c1.4,0,1.9-1.4,0.4-2.1 M300,331.9c7.8,0.2,17.3,0.2,26.6,0.4 M281.7,331.5c3.3,0.2,9.7,0.4,17.5,0.4
	 M358.4,337.9h4.7 M327.9,337.9h29.5 M300.6,337.7c7.6,0,17.1,0.2,26.4,0.2 M282.3,337.3c2.1,0.2,8.7,0.4,17.5,0.4 M358.4,342.8h4.7
	 M328.3,342.8h29.5 M301.1,342.8c7.8,0,16.9,0.2,26.2,0.2 M283.3,342.4c3.3,0.2,9.5,0.4,16.9,0.4 M359.7,380.2h15.3 M330.8,380h27.9
	 M305.6,379.8c7,0.2,15.5,0.2,24.4,0.2 M292.4,379.6c2.7,0.2,7,0.2,12.2,0.4 M359.9,385.1h15.3 M331.2,385.1H359 M306,384.9
	c6.8,0.2,15.3,0.2,24.4,0.2 M294,384.5c2.5,0.2,6.4,0.2,11.1,0.4 M296.1,390.3c1.6,0.2,5.2,0.2,10.1,0.4 M360.1,390.9h15.1
	 M331.8,390.9h27.4 M306.8,390.7c6.6,0.2,15.1,0.2,23.9,0.2 M306.4,395.7c-2.3,0-3.9-0.2-5-0.2c-3.7-0.4-4.3-2.1-5.4-4.7
	c0,0-0.2-0.2-0.2-0.4 M331.2,396.3c-9.7-0.2-18.2-0.2-23.7-0.4 M359.2,396.5c-9.3,0-18.8-0.2-27.2-0.2 M375.1,396.5h-14.9
	 M375.1,300.9c28.7,0,61.3,1,73.3,1.9c3.5,0.2,5.2,0.4,7.2,1.2c2.1,0.8,4.7,2.9,5,3.3c2.7,2.1,10.1,9.1,11.8,11.4
	c1.6,2.1,1.9,3.5,1.9,5.4c0,1.9-1.4,17.1-6.2,35.7c-4.7,18.6-8,29.7-9.5,33c-1.4,3.3-2.5,6.4-9.1,7c-6.6,0.6-43.5,1-74.3,1
	 M552,243.8c0,0,0-0.4,0.4-0.2c3.1,0.2,7.8,1.4,9.1,1.9s1.7,1,1.7,1c0,0.2,0.8,1.2,2.3,2.9c1.4,1.4,7.4,5.8,11.1,9.9
	c0.8,1,0.8,1.9,0.8,2.7v1.6c0,0.6,0.2,1.4,0.4,2.1c2.1,4.1,5.8,12,7.4,19 M569.3,324.3c0,0,0.4,0.2,0.4,0.8s0.6,7,0.6,8
	c0,1,0.4,2.1,1.7,2.1c1.2-0.2,16.7-2.3,17.5-2.5c0.8-0.2,1.2-0.2,1.2-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M593.9,301.8c-0.4-0.2-1.7,16.9-2.9,30.5
	c-1,14.2-3.5,38-5.2,51.4c-1.7,13.2-3.3,24.8-3.3,26c0,0.6,0,1.2,0.2,2.5c0.2,1,0.8,3.3,0.8,3.7s0,0.6-0.2,1.2
	c-0.2,0.6-2.1,5.6-3.3,7.4c-0.2,0.4-0.6,0.8-1.2,1c-1.9,0.4-6.6,1.4-18.2,2.5c-4.5,0.4-8.7,0.4-14.2,0.4c0,0-0.8,1.2-3.1,1.7
	c-2.3,0.2-1,0.6-17.5,0.6c-16.3,0-43.1,0.8-43.1,0.8H374.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M579.6,277.2c0,0.6,0.8,2.5,2.3,3.9
	c1.4,1.4,7.4,7,10.9,12.6c0.6,1,1,3.1,1.2,8.9c0.2,6-0.2,21.3-1.4,43.3c-0.4,8-1.2,15.5-2.1,21.9 M590.8,370.9
	c0,1.4,1.2,3.9,1.2,6.6c0,2.9-1,7-1,7 M585,265.2c-0.4-1.2-1-2.5-1.4-3.7c-2.1-4.7-2.3-5-2.7-5.8s-0.6-1.9-0.4-2.7
	c0.2-0.8,0.2-2.3-1-4.1c-1.2-2.3-3.5-4.1-5.8-5.6 M591.8,291.9c-0.4-2.9-1.4-9.1-3.1-15.3c0-0.4-0.2-0.6-0.2-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M580.5,252.4c-0.4,3.9-2.7,7-9.1,17.1
	c-6.4,10.1-15.3,21.3-15.3,21.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M582.7,260.7c-0.2,0,1.2,3.1,3.3,9.1c2.1,5.8,3.3,10.7,3.7,10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M588.5,275.6c1-0.4,2.9-1,3.9-1.6
	c1.2-0.8,1.7-1.2,1.7-2.7c0-1.2-0.2-3.1-0.4-4.3c-0.2-1.4-6-1.6-8.9-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M586.6,269.4c2.9-0.8,6.2-2.1,6.2-3.1 M585.6,267.1c1.9-0.2,4.3-0.6,4.3-1
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M582.9,260.1c3.1,0,7,0.6,7.2,1
	c0.2,0.6,0.8,3.3,0.8,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M589.9,260.9c0,0.2-2.5,1.7-5.6,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M545.6,429.5c0,0-16.7-2.7-20.8-2.7
	c-5.6,0-15.7-0.2-17.5-0.2c-2.1-0.2-3.5-1.7-7.8-2.1c-1.9-0.2-16.9-1.6-36.5-2.3c-27.6-0.8-63.8-0.4-87.7-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.6,425.6c-1.2,0.2-1.2,1.2-1.4,1.6s-0.4,1.2-1.2,1.2
	c-1.9,0.2-102.3-0.4-122.8-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M547,389.9c0,3.5-2.9,6.4-6.6,6.4
	c-3.7,0-6.6-2.9-6.6-6.4s2.9-6.4,6.6-6.4C544.1,383.5,547,386.4,547,389.9L547,389.9z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            cx="540.4"
            cy="390.9"
            rx="8.3"
            ry="8.3"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            cx="540.4"
            cy="390.9"
            rx="8.3"
            ry="8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M549.3,390.7c0,5-3.9,8.9-8.9,8.9
	c-6.6,0-11.1-3.9-11.1-8.9c0-5,4.7-8.9,11.1-8.9C545.4,381.8,549.3,385.8,549.3,390.7L549.3,390.7z M471.9,302.2
	c5.8-8.9,22.1-27.2,38-38.6c16.1-11.4,33.4-18.2,39.6-20 M437.9,377.1c0,0,0.2-0.2,0.2-0.6s0.2-21.5,0-25.2
	c-0.2-3.5-2.9-3.9-4.5-3.9h-58.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M436.6,377.1c0,0,0.2-0.2,0.2-0.6v-25
	c0-1.9-1.9-2.7-3.1-2.7h-58.6 M445.1,372.1c0,1.2-1,2.1-2.1,2.1c-1,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1
	C444.1,369.9,445.1,370.9,445.1,372.1z M547.4,367.6c0,1.2-0.8,2.1-1.7,2.1c-0.8,0-1.4-1-1.4-2.3s0.8-2.1,1.7-2.1
	C546.8,365.5,547.6,366.4,547.4,367.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M540.8,430.5c0,0-9.3-1.6-14-1.9c-4.7-0.4-28.3-0.2-28.3-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M533.4,430.9v26.8c0,1.6,0.8,2.1,1.2,2.7
	c0.4,0.6,1,5.4,4.5,5.4h46.4c3.3,0,6.6-2.7,6.6-9.3c-0.2-6.4-1.9-116-1.9-116"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M583.5,416.5c-29.1-1.2-171.3-1.4-208.6-1.4 M579.8,425
	c-10.3-1.6-21-2.3-39.6-2.9c-18.6-0.6-37.6-1-39.2-1s-3.1-0.4-4.1-0.6c-1-0.4-9.3-2.5-12-3.3c-2.5-0.6-8.5-1.9-12-1.9 M375.1,397.5
	c30.3,0,66.9-0.4,73.5-1c5-0.4,5.6-3.1,6.8-6.2c0,0,4.1-11.6,9.3-31.2c4.5-17.3,6.2-32.4,6.2-35.1c0-1.2,0-2.1-1.2-3.7
	c-1.4-1.9-8.5-8.5-11.1-10.5l-0.2-0.2c-2.3-1.9-3.7-2.5-4.1-2.7c-1.6-0.6-3.1-0.8-6.6-1.2c-11.6-0.8-44-1.9-72.4-1.9 M539.4,294.1
	c0,0-0.2,0.2-1.7,0.4c-1.4,0.2-25,3.5-43.3,6.4c-18.4,2.9-34,5.8-34,5.8c-0.6,0-1-0.2-1.4-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375.1,238c47.5,0,127.1,0,175.4,5.2c0.6,0,1.7,0.6,1.7,0.6
	c3.1,2.5,7.4,5.8,10.1,7.8c2.7,2.1,2.5,3.3,0.6,6c-9.7,13.4-20.2,31.2-23.1,36.3c-0.2,0.4,0.6,0.6,1.2,0.6s14-2.1,25.8-1.7
	c2.9,0.2,4.5,1.9,5.2,3.3c0.6,1.4,0.8,2.3,1,4.7c0.2,2.5-0.2,15.7-1.4,19.2c-0.6,1.6-1.2,3.1-2.1,3.9c-0.2,0.2-0.8,0.6-2.5,0.8
	c-1.7,0.2-15.5,1.9-17.7,1.9c-2.3,0-2.7-0.2-4.3-0.6c-1.7-0.4-16.7-5.2-18.4-5.6s-2.9-0.6-5.4-0.4c-2.5,0.2-22.7,1.6-26,1.6
	c-3.3,0-4.7-0.8-6.4-2.1c-1.6-1.4-17.1-15.1-17.1-15.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M375.1,413.4c17.1,0,59.4,0,86.5-0.2
	c27-0.2,91.8-0.8,98-1.4c6.2-0.6,8.9-3.9,9.7-6.6c3.5-11.1,3.7-27.4,3.3-29.9c-0.2-2.3-1-5.2-7.2-4.7c-6.2,0.4-80.5,4.1-99.3,4.7
	c-0.2,0-0.4,0.2,0.2,0.4c0.6,0.2,1.4,0.4,2.1,0.6s1.6,0.4,3.1,0.2c1.4,0,90-4.5,92.9-4.5s5.2,1,5.2,5.2s-0.4,16.5-3.1,25.4
	c-1,3.1-3.9,5.8-8,6c-4.1,0.2-72.2,3.7-96.4,3.7c-28.9,0.2-65.8,0-86.7,0 M466.8,368.6c18.8-0.6,74.3-3.7,92.2-5
	c9.9-0.8,20.4-1,29.3-4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M471.3,376.5c1.4,0,90-4.5,92.9-4.5
	c2.9,0,5.2,1,5.2,5.2s-0.4,16.5-3.1,25.4c-1,3.1-3.9,5.8-8,6c-4.1,0.2-72.2,3.7-96.4,3.7c-28.9,0.2-65.8,0-86.7,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M375.1,404.3c25.8,0,74.9,0,95.1-1.9
	c3.1-0.2,5.2-1.7,6.6-2.9c0,0,0.2-0.2-0.2-0.2h-5.8c0,0-0.4-0.2-0.4-0.4v-0.4c0-0.8,1-1.2,2.1-1.4c1-0.2,6-0.6,6.2-0.6
	c0.2,0,0.4-0.2,0.4-0.2c0.6-0.8,2.5-3.7,5-7.4c0,0,0.2-0.2-0.2-0.2c0,0-8.7,0.2-9.3,0.2s-0.6-0.4-0.6-0.8c0-0.8,0.8-1.2,1.9-1.2
	c1-0.2,9.1-0.8,9.7-1c0.6,0,0.8-0.2,0.8-0.2c3.5-5.4,7-10.7,7-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M375.1,410.1c0,0,66.4,0.2,87.1,0
	c24.3-0.2,91-3.3,94.9-3.5c3.1-0.2,6.2-2.3,7-5.2c2.7-8.7,3.3-18.8,3.3-22.7c0-1.4,0-3.1-1-3.9c-0.8-0.8-2.1-1-3.3-1
	c-1.7,0-32.8,1.4-58.4,2.7c-4.5,0.2-8.7,0.4-12.4,0.6c0,0-0.2-0.2-0.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M482.4,395l0.2-0.2c0.4-0.6,1.6-2.5,3.1-4.7
	c0.2-0.4,0.6-0.4,1-0.4c0.2,0,18.2-0.6,18.8-0.6c0.4,0,0.6,0.4,0.4,0.6c-0.6,1.7-1.2,3.1-1.9,4.7c0,0.2-0.2,0.4-0.6,0.4
	c-0.2,0-20.4,1.2-20.6,1.2C482.6,395.9,482,395.7,482.4,395L482.4,395z M478.7,400.2c-0.4,0.6-1,1.2-1.7,1.6
	c-0.4,0.2-0.6,0.4,0.2,0.8c0.8,0.4,7.2,3.9,8,4.3c0.8,0.4,1.2,0.8,2.9,0.8c1.4,0,9.9-0.4,9.9-0.4s0.6,0,0.8-0.6
	c0.8-2.1,1.9-4.3,3.1-7c0.2-0.4,0-0.8-0.6-0.8s-21.3,0.6-21.7,0.6C479.3,399.8,478.9,399.8,478.7,400.2L478.7,400.2z M489.5,384.5
	c1.9-2.9,3.5-5.4,3.7-5.6c0.4-0.4,0.8-0.4,0.8-0.4c0.2,0,14.6-0.6,15.3-0.6s0.8,0.4,0.6,0.8c-0.6,1.9-1,3.5-1.9,5.4
	c0,0.2-0.2,0.4-0.6,0.4s-17.1,1-17.5,1C489.7,385.1,489,385.1,489.5,384.5L489.5,384.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M547.2,407c-8.5,0-16.3,0.6-25-2.1
	c-8.9-2.7-15.9-5.2-17.3-5.6c-1.4-0.4-2.5-0.4-3.1,0 M527.4,407.4c-7.8,0-26.8,0.2-27.6,0.2c-0.8,0-1-0.2-1-0.2 M486.6,408
	c0,0-5.2,0.6-5.8,0.8c-1.9,0.2-4.7,0.2-5.4,0.2c-20.4,0.6-90,0.4-100.5,0.4 M476.7,402c-0.4,0.4-1.2,0.8-2.1,0.8
	c-0.8,0-2.5,0-3.9,0.6c-1.4,0.6-1.2,0.8-3.1,1c-21.3,1.6-67.7,1.6-92.9,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M468.2,404.5c0,0,4.7,3.1,5.4,3.3
	c0.6,0.2,0.4,1-0.4,1 M471.5,403.5c0,0,7.4,5,7.8,5.2 M530.3,375.6c-3.9,0.4-17.5,1.6-18.6,1.9c-0.8,0-1.9,0.2-2.1,0.8 M536.7,375
	c-2.9,0.2-7.8,1-11.8,2.5c-3.9,1.6-14,6-15.3,6.4c-1.2,0.4-1.4,0.2-1.6,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M470.5,399.4c0.4,0,7.6-0.2,18-0.6
	c8.3-0.4,18.6-0.6,19.2-0.8c0.6,0,1,0.2,1.4,0.4c0.8,0.4,15.9,5.6,17.3,6.2c1.9,0.6,3.7,0.8,5.6,1c3.9,0.2,20.4-0.8,22.5-1
	c2.5-0.4,3.5-2.3,4.1-3.5c2.7-7,3.3-18.2,3.1-20.8c-0.2-2.9-1.7-4.3-4.7-4.1c-1.6,0-20,1-23.7,1.2c-3.9,0.2-5.8,0.8-7.6,1.7
	l-13.8,6.4c-1.2,0.6-1.9,0.8-2.5,0.8c-0.8,0-20.4,1-32.8,1.4c-0.8,0-2.1,0.2-2.5,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M506.2,389c0.4-0.4,1.2-0.4,2.1-0.4
	c0.8,0,6.2-0.4,7-0.4c0.8,0,1.4,0,2.3-0.6c0.8-0.4,10.5-5.2,12.4-6.2c1.9-0.8,3.9-1.2,5-1.2c9.7-0.8,20.6-1.4,22.3-1.6
	c1.2,0,1.2,0,1.4,1v0.8c0,3.3-0.8,13-3.1,19c-0.4,1-0.8,1.2-1.6,1.4c-1.4,0.2-12.2,1-19.2,0.8c-1.2,0-3.5-0.4-5.4-1
	c-1.9-0.8-13.2-5.4-14-5.6c-0.8-0.2-1.7-0.4-2.3-0.4s-7.2,0.2-7.6,0.2c-0.4,0-0.8,0-1-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M474,389c0,0,34.7-1.4,35.7-1.6c1,0,1.4-0.4,3.1-1
	c1.4-0.6,12.2-5.6,13.6-6.4c1.4-0.6,3.3-1.2,7-1.4c3.7-0.2,21.9-1.2,23.7-1.2c1.9,0,2.9,0.4,3.1,2.7c0.2,2.3-0.6,13.4-3.1,20.2
	c-0.4,1-1,2.3-2.9,2.5c-1.7,0.2-18.4,1.2-22.1,1c-1.9,0-3.5-0.4-5.2-0.8c-1.6-0.4-16.7-5.8-17.3-6s-1-0.4-2.1-0.4
	c-1,0-33.8,1.2-34.5,1.2c-0.8,0-1.9,0.2-2.3,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M534.2,380.8c7.2-0.4,13.6-0.6,14.9-0.6
	c1.4,0,2.7,0.4,2.7,2.1c0,1.6-0.4,9.3-2.5,16.5c-0.4,1-0.4,1.9-2.5,2.1c-2.1,0.2-9.1,0.6-14.9,0.6 M462.4,403.1c0,0.2,0,1,0.6,1.6
	 M463.2,404.7v4.3 M462.8,409.3c-0.2,0.2-1,1-1,3.1 M489.5,385.3c-0.2,0.2-0.8,0.4-1.2,0.6c-0.4,0.2-1.2,0.6-2.3,0.4 M485.9,389.5
	c-0.2-0.2-0.8-0.4-1-0.4h-0.8 M482.4,395.9c-0.2,0.2-0.6,0.4-1,0.6c-0.4,0.2-1.6,0.6-2.5,0.4 M478.7,399.8c-0.2-0.2-0.8-0.6-1.9-0.4
	 M442.8,405.6v3.7 M441.2,405.6v3.7 M418.3,406v3.3 M416.6,406v3.3 M390.2,406.2v3.1 M388.5,406.2v3.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M409,315c3.3,0,42.3-0.2,52.8,0.4c0.6,0,0.2-0.4,0.2-0.4s-0.8-1.2-1-1.4
	s-0.6-0.2-0.6-0.2c-8.7,0.8-48.7,0.6-52.2,0.6c0,0-0.2,0-0.2,0.2c0.2,0.2,0.4,0.6,0.4,0.6C408.6,314.8,408.8,315,409,315L409,315z
	 M408.4,325.7c3.3,0,50.1-0.2,60.7,0.4c0.4,0,0.4-0.4,0.4-0.4v-2.1c0-0.2-0.2-0.2-0.4-0.2c-9.5,1.6-57.2,1.2-60.5,1.2
	c-0.2,0-0.4,0-0.4,0.2c0,0.2-0.2,0.6-0.2,0.6S407.9,325.7,408.4,325.7L408.4,325.7z M391,331.5c0.6,0.2,0.4,1.2-0.6,1.2h-2.1
	c-0.8,0-1.2-0.8-0.6-1.2 M375.1,335c3.3,0,69.5,0.6,80.5-0.2c1.9,0,10.3-0.8,13-1c0.2,0,0.4,0,0.4,0.2c0,0.2-0.4,2.3-0.4,2.3
	s0,0.4-0.4,0.4c-10.7-0.4-89.6-0.2-92.9-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M456.6,310.8c1,0.2,2.7,0.6,3.5,1.2c0.6,0.6,2.1,2.5,2.3,2.9
	c0.2,0.4,0,0.6-0.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M406.7,310.8c0,0.2,0.2,0.6,0,0.8
	c0,0.2,0,0.4,0.2,0.8c0.2,0.4,1,1.6,1.2,1.9c0.2,0.2,0.6,0.8,0.8,1c0.4,0.2,0.6,0.6,0.8,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M406.7,310.8c-2.5-2.7-6-4.3-10.1-4.3c-2.5,0-5,0.6-7.2,2.1
	c-2.1-1.2-4.5-2.1-7.2-2.1c-2.7,0-5.4,0.6-7.4,2.1 M374.9,330.7c2.1,1.2,4.7,2.1,7.4,2.1c2.5,0,5-0.6,7.2-2.1
	c2.1,1.2,4.5,2.1,7.2,2.1c4.5,0,8.5-2.1,10.9-5.4 M410,321.6c0.2-0.6,0.2-1.4,0.2-2.1c0-1.2-0.2-2.3-0.4-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M410,321.6c0,0.2-0.4,0.6-0.6,0.8
	c-0.2,0.2-0.6,0.4-0.8,0.8c-0.2,0.4-0.6,1.4-0.8,1.9c-0.2,0.4-0.2,0.4,0,0.6c0,0.2,0.2,1,0,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M467.4,321c-1.2-1.2-3.3-3.3-5.4-5.2 M467.4,321c0,0,1.4,0.6,1.6,0.8
	c0.4,0.2,0.8,0.8,0.8,1.4c0,0.6-0.2,2.3-0.2,2.3s0,0.4-0.2,0.6l-0.4,0.4 M375.1,345.7c3.3,0,68.7,0.2,78.8-0.4
	c1.9,0,10.3-0.6,13-0.8c0.2,0,0.4,0,0.4,0.2S467,347,467,347s0,0.4-0.4,0.4c-14.6-0.8-87.9-0.2-91.2-0.2 M375.1,377.1
	c18,0,62.5,0.2,71.4,0c1.7,0,9.1-0.6,12.2-0.8c0.2,0,0.4,0,0.2,0.4c0,0.2-0.4,1.9-0.4,1.9s-0.2,0.4-0.4,0.4c-13.8-0.4-64-0.4-83-0.2
	 M468.4,331.5c0.2-2.1,0.4-3.7,0.6-5 M468.4,331.5l0.6,0.8c0,0,0.2,0.2,0.2,0.8s-0.4,2.9-0.4,2.9s-0.2,0.6-0.4,0.8
	c-0.2,0.2-0.8,0.4-0.8,0.4 M376.8,331.5c0.6,0.2,0.4,1.2-0.6,1.2h-1.2 M438.3,356.2c6.2,0,10.9-0.2,13.2-0.2c1.9,0,10.3-0.6,13-0.8
	c0.2,0,0.4,0,0.4,0.2c0,0.2-0.4,2.3-0.4,2.3s0,0.4-0.4,0.4c-4.3-0.2-14-0.4-25.6-0.4 M438.3,366.8c5,0,8.7-0.2,10.5-0.2
	c1.9,0,10.3-0.6,13-0.8c0.2,0,0.4,0,0.4,0.2c0,0.2-0.4,1.9-0.4,1.9s0,0.4-0.4,0.4c-4.1-0.2-12.6-0.4-22.9-0.4 M467,342.2
	c0.2-1.9,0.6-3.5,0.8-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M457.7,379.6c0,0,0.6-0.4,0.8-0.6l0.4-0.4c1.4-5.2,3.3-11.6,5.4-19.4
	c1.4-5.6,2.7-10.7,3.5-15.5c0-0.4,0-0.4-0.2-0.6c0-0.2-0.6-0.6-0.6-0.6 M465.3,354.2c-4.7,0.2-15.1,0.6-27,0.6 M462.6,364.7
	c-4.5,0.2-13.6,0.6-24.4,0.8 M459.7,375.2c-4.5,0.2-12.4,0.6-21.5,0.8 M375.1,388h67.1c3.1,0,10.5-0.4,13.2-0.8c0.2,0,0.4,0,0.2,0.2
	c0,0.2-0.4,1.6-0.4,1.6s0,0.4-0.4,0.4c-10.7-0.4-76.3-0.2-79.6-0.2 M456,384.5c0.4-1.4,1-3.1,1.6-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M456,384.5c0,0,0.2,0.8,0.2,1c0,0.4-0.2,1-0.2,1.7
	c-0.2,0.6-0.6,2.1-0.6,2.1s0,0.2-0.4,0.4c-0.2,0.2-0.8,0.6-0.8,0.6 M374.9,336.4c1.6,0,7.2,0.4,12,1.4v5c-1.4,0.4-6,0.8-11.8,0.8
	 M393.3,306.9v-1.2 M392.9,307.1v-2.3 M392.5,307.1v-1.4 M393.3,305.7c0-0.2-0.2-0.8-0.4-0.8s-0.4,0.4-0.4,0.8 M424.2,310.8l0.2-4.5
	 M423.8,311.7l0.2-6.2 M423.4,310.8l0.2-4.5 M424.5,306.3c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.4,0.8 M423.4,310.8
	c0,0.2,0.2,0.8,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M423.8,321.8l0.2-5.4 M423.4,322.4l0.2-6.8 M423,321.8l0.2-5.4 M424,316.4
	c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.4,0.8 M423,321.8c0,0.2,0.2,0.8,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M451.9,321.6l0.2-5.4
	 M451.5,322.2l0.2-6.8 M451.1,321.6l0.2-5.4 M452.1,316.2c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.4,0.8 M451.1,321.4
	c0,0.2,0.2,0.8,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M450.9,331.9l0.4-4.7 M450.5,332.7l0.4-6.4 M450,332.1l0.4-5 M451.3,327.1
	c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.6,0.8 M450,331.9c0,0.2,0.2,0.6,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M449.8,342.6l0.4-5 M449.4,343.5
	l0.4-6.4 M449,342.8l0.4-5.2 M450.2,337.7c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.6,0.8 M449,342.6c0,0.2,0.2,0.8,0.4,0.8
	c0,0,0.4-0.4,0.4-0.8 M444.9,384.9l0.6-5.2 M444.5,385.8l0.6-6.6 M444.1,384.9l0.4-5.2 M445.5,379.8c0-0.2-0.2-0.8-0.4-0.8
	c0,0-0.4,0.4-0.6,0.8 M444.1,384.9c0,0.2,0.2,0.6,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M443.6,395.7l0.4-5 M443,396.5l0.6-6.6 M442.6,395.7
	l0.6-5 M444.1,390.7c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.6,0.8 M442.6,395.7c0,0.2,0.2,0.8,0.4,0.8c0,0,0.4-0.4,0.4-0.8
	 M423.4,332.3l0.2-5.2 M423,333.1l0.2-6.6 M422.6,332.3v-5.2 M423.6,327.1c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.4,0.8 M422.6,332.3
	c0,0.2,0.2,0.8,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M422.8,342.8l0.2-5 M422.2,343.7l0.4-6.4 M421.8,342.8l0.4-5 M423,337.9
	c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.4,0.8 M421.8,342.8c0,0.2,0.2,0.8,0.4,0.8c0,0,0.4-0.4,0.4-0.8 M392.5,342.8l0.2-5
	 M392.1,343.5l0.2-6.2 M391.6,342.8l0.2-5 M392.7,337.9c0-0.2-0.2-0.6-0.4-0.6c0,0-0.4,0.4-0.4,0.6 M391.6,342.8
	c0,0.2,0.2,0.6,0.4,0.6c0,0,0.4-0.2,0.4-0.6 M391,385.1l0.2-5.2 M390.6,385.8l0.2-6.4 M390.2,385.1l0.2-5.2 M391.2,380
	c0-0.2-0.2-0.6-0.4-0.6c0,0-0.4,0.4-0.4,0.6 M390.2,385.1c0,0.2,0.2,0.6,0.4,0.6c0,0,0.4-0.2,0.4-0.6 M390.8,396.3l0.2-5.4
	 M390.4,396.9l0.2-6.6 M390,396.5v-5.6 M391,390.9c0-0.2-0.2-0.6-0.4-0.6s-0.4,0.4-0.4,0.6 M390,396.3c0,0.2,0.2,0.4,0.4,0.4
	c0,0,0.4-0.2,0.4-0.4 M419.7,385.1l0.4-5.2 M419.3,385.8l0.4-6.4 M418.9,385.1l0.4-5.2 M420.1,380c0-0.2-0.2-0.8-0.4-0.8
	c0,0-0.4,0.4-0.4,0.8 M418.9,385.1c0,0.2,0.2,0.8,0.4,0.8c0.2,0,0.4-0.4,0.4-0.8 M418.9,396.1l0.4-5.2 M418.5,396.9l0.4-6.8
	 M418.1,396.3l0.4-5.4 M419.3,390.9c0-0.2-0.2-0.8-0.4-0.8c0,0-0.4,0.4-0.4,0.8 M418.1,396.1c0,0.2,0.2,0.8,0.4,0.8
	c0,0,0.4-0.4,0.4-0.8 M452.5,310.8l0.2-2.5 M452.1,311.7l0.2-4.3 M451.7,310.8l0.2-2.9 M452.7,308.4c0-0.6-0.2-1-0.4-1
	c0,0-0.4,0.4-0.4,0.6 M451.7,310.8c0,0.2,0.2,0.8,0.4,0.8s0.4-0.4,0.4-0.8 M392.5,305.5c-5.8,0-11.8-0.2-17.3-0.2 M423.6,306.3
	c-9.1-0.2-19.6-0.6-30.3-0.6 M451.9,308c-1.2-0.4-2.5-0.4-5.2-0.6c-4.5-0.4-12.6-0.6-22.3-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M456.6,310.8c-2.1-1.6-3.3-2.3-3.7-2.5c0,0-0.2,0-0.2-0.2 M423.4,311.1
	h-16.7 M451.7,310.8h-27.4 M456.6,310.8h-4.1 M423.2,316.4c-7,0-12.4,0-13.4-0.2 M451.3,316.2c-8,0.2-18.8,0.2-27.2,0.2
	 M462.2,315.8c-1.9,0.2-5.6,0.4-10.1,0.4 M423,321.8h-13 M451.1,321.6c-9.1,0.2-19.2,0.2-27,0.2 M467.4,321
	c-3.9,0.2-9.5,0.4-15.5,0.4 M422.6,327.4h-15.1 M450.5,327.1c-8.7,0.2-18.6,0.2-26.8,0.2 M469,326.5c-2.7,0.2-9.5,0.4-17.7,0.6
	 M422.6,332.3c-6.2,0-12.2,0.2-18,0.2c-1.4,0-1.9-1.4-0.4-2.1 M450,331.9c-7.8,0.2-17.3,0.2-26.6,0.4 M468.4,331.5
	c-3.3,0.2-9.7,0.4-17.5,0.4 M391.9,337.9h-4.7 M422.2,337.9h-29.5 M449.4,337.7c-7.6,0-17.1,0.2-26.4,0.2 M467.8,337.3
	c-2.1,0.2-8.7,0.4-17.3,0.4 M391.6,342.8h-4.7 M421.8,342.8h-29.5 M449,342.8c-7.8,0-16.9,0.2-26.2,0.2 M466.8,342.4
	c-3.3,0.2-9.5,0.4-16.9,0.4 M390.4,380.2h-15.3 M419.3,380h-27.9 M444.5,379.8c-7,0.2-15.5,0.2-24.4,0.2 M457.7,379.6
	c-2.7,0.2-7,0.2-12.2,0.4 M390.2,385.1h-15.3 M418.9,385.1H391 M444.1,384.9c-6.8,0.2-15.3,0.2-24.4,0.2 M456,384.5
	c-2.5,0.2-6.2,0.2-11.1,0.4 M454.2,390.3c-1.6,0.2-5.2,0.2-10.1,0.4 M390,390.9h-15.1 M418.5,390.9H391 M443.2,390.7
	c-6.6,0.2-15.1,0.2-23.9,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M443.6,395.7c2.3,0,3.9-0.2,5-0.2c3.7-0.4,4.3-2.1,5.4-4.7
	c0,0,0.2-0.2,0.2-0.4 M418.9,396.3c9.7-0.2,18.2-0.2,23.7-0.4 M390.8,396.5c9.3,0,18.8-0.2,27.2-0.2 M375.1,396.5H390 M302.9,329.6
	h20.2c0.4,0,0.8-0.4,0.8-0.8v-1.4c0-0.4-0.4-0.8-0.8-0.8h-20.2c-0.4,0-0.8,0.4-0.8,0.8v1.4C302.1,329.2,302.5,329.6,302.9,329.6
	 M324,327.4h2.5 M299.6,327.1h2.5 M208.6,294.3c-0.6,0-15.7-1.7-23.9-1.2c-2.9,0.2-4.1,0.6-5.4,2.3c-1,1.2-1.4,3.1-1.6,5.6
	c-0.2,2.5,0.2,14.2,1.4,17.7c0.6,1.6,1.2,3.1,2.1,3.9c0.2,0.2,0.8,0.6,2.5,0.8s14.2,1.9,16.7,1.9c2.3,0,2.7-0.2,4.3-0.6
	s16.7-5.2,18.4-5.6c1.6-0.4,2.9-0.6,5.4-0.4c2.5,0.2,22.7,1.6,26,1.6s4.7-0.8,6.4-2.1c1.4-1.2,12.4-10.7,16.1-14"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M272.6,304.7c-11.6-1.9-39.8-6.6-51.6-8c-11.8-1.4-25.2-2.9-31.4-3.1
	c-1.6,0-4.3,0.2-5.6,0.6c-1.2,0.4-3.7,1-4.5,2.9c-0.4,1-0.8,2.3-0.8,4.5c0,2.7-0.4,8.3,1,14.6c1.2,6.4,3.1,6,4.7,6.4
	c1.6,0.2,8,1.2,14.9,1.9c1.2,0.2,2.9,0.2,6-0.6c3.1-0.8,16.5-5.2,17.7-5.4c1.2-0.4,3.5-0.6,5.2-0.6c1.6,0.2,22.5,1.6,25.2,1.6
	c2.7,0,4.3-0.2,6.4-2.1c2.1-1.9,12-10.5,13.2-11.6C273.6,305.1,273,304.9,272.6,304.7L272.6,304.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M271.8,306.7c-12.4-2.3-39.6-6.2-51.4-7.6c-11.8-1.4-26.6-3.1-40.2-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M228.2,317.5c-9.3-0.4-32.4-2.1-33.4-2.1c-1.2,0-3.1,0.2-4.1,1.6
	c-1.2,1.4-2.7,3.7-2.7,3.7c-1.4-0.2-1.9-0.2-2.3-0.2c-0.4-0.2-2.5-0.8-3.3-3.7c-0.8-2.9-2.1-11.1-1.4-20.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M189.4,320.3l1.9-2.1c1.2-1.4,2.9-1.6,4.1-1.6c0.8,0,11.8,0.8,21.7,1.4
	c0,0-9.7,2.9-11.1,3.3c-1.2,0.2-2.5,0.4-4.3,0.4C199.9,321.6,192.7,321,189.4,320.3L189.4,320.3z M189.4,320.3l-1.4,0.4
	 M217.1,318.1l5-0.6c0,0,2.1-0.2,3.5-0.2 M262.3,305.1c-2.7,2.3-5.4,4.3-6.2,5c-2.3,1.6-3.1,1.9-6,1.6c-2.7,0-24.3-2.3-26-2.5
	c-6.6-0.4-27.4-2.7-28.7-2.7c-1.7-0.2-3.1-0.6-4.5-3.5c-1-2.1-1.9-4.1-2.9-6.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M269.9,306.9c-2.3-0.4-5-0.8-8-1.2c0,0-0.2,0-0.2-0.2 M268,306.7
	c-3.3,2.9-8,7-9.5,8.3c-2.1,1.9-3.5,1.9-6.4,1.9c-2.7,0-24.1-1.6-25.8-1.6c-6.6-0.4-31.8-2.3-33-2.5c-1.2,0-3.1,0.2-4.1,1.7
	c-1.2,1.4-3.5,5.2-3.5,5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.2,306.1c-2.9,2.7-6.2,5.4-7.2,6.4c-2.1,1.9-3.1,1.6-6,1.6
	c-2.7,0-24.4-1.9-26-1.9c-6.6-0.4-30.1-2.5-31.4-2.7c-1.6-0.2-3.5-0.4-5-3.3c-1.4-2.9-2.9-6.4-3.9-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M188.6,297.6c-0.4-0.2-1-0.4-1.9-0.4s-2.9-0.2-4.7,0c-0.2,0-0.2,0-0.4,0.2
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M183.4,297c0.6,2.5,2.3,8,5.6,13.8c0.2,0.4,0.4,0.8,0,1.2
	c-0.2,0.4-2.9,5.4-3.9,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M183,311.9c1.9,0.2,3.7,0.4,5.2,0.4h0.2l0.2-0.6c0.2-0.4,0-0.6,0-0.6
	l-0.4-0.8L188,310c-1.4-0.2-3.9-0.4-5.4-0.6v1.4C182.6,311.3,182.8,311.7,183,311.9L183,311.9z M183,311.9c0.4,2.7,0.8,4.3,1.2,5.4
	c0.4,0.8,0.6,1.4,1,1.6 M182.8,297c-0.2,0-0.6,0.2-0.6,0.6s-0.2,6.6,0.4,11.1v0.6 M205.3,321.4c-0.6-0.4-2.5-1-2.9-1
	c-0.4-0.2-0.8-0.2-1-0.2h-3.3c-0.2,0-0.4,0-0.6,0.2c-0.2,0.2-0.4,0.4-0.4,0.6v0.4 M194.4,316.8c0.2,0.6,0.6,2.9,0.6,4.3
	 M205.5,317.5c0.6,0.6,1.6,2.7,1.6,3.7 M191.5,296.4c-0.2,0.2-0.6,1-0.6,2.1v3.9 M195,296.6c-1.6-0.2-2.9,1.4-2.9,2.3v3.5
	c0,1,0.2,2.1,1,3.1 M199.9,297v9.5c0,0,0,0.4-0.4,0.4h-1.9 M210.7,297.8c-0.2,0.6-0.6,3.1-0.6,6.2c0,0.4,0,0.6,0.2,0.8
	c0.2,0.2,0.2,0.4,0.4,0.6c0,0.2,0.2,2.5,0.2,2.5 M219.8,299.1v9.1c0,0,0,0.2-0.2,0.2h-6.6 M212.5,298.1v10.3 M222.9,299.3v9.9
	 M201.8,297v9.1c0,0.4,0.2,0.8,0.8,0.8c0.6,0,8,0.2,8,0.2 M222.2,299.3c0.2,1.6,0,7.4-0.4,9.9 M224.7,309.4v-9.3
	c0-0.2,0-0.4,0.4-0.4 M232.3,300.5v9.5 M233.6,310.2v-8.9c0-0.2,0-0.4,0.4-0.4 M240.8,301.8v9.1 M241.8,311.1v-8.7
	c0-0.2,0-0.4,0.4-0.4 M249.1,303v8.7 M250.7,303.2c-0.4,0-0.4,0.2-0.4,0.4v7.4c0,0.2,0,0.6,0.6,0.6 M541.5,294.3
	c0.6,0,15.7-1.7,23.9-1.2c2.9,0.2,4.1,0.6,5.4,2.3c1,1.2,1.4,3.1,1.7,5.6c0.2,2.5-0.2,14.2-1.4,17.7c-0.6,1.6-1.2,3.1-2.1,3.9
	c-0.2,0.2-0.8,0.6-2.5,0.8c-1.7,0.2-14.2,1.9-16.7,1.9c-2.3,0-2.7-0.2-4.3-0.6s-16.7-5.2-18.4-5.6c-1.7-0.4-2.9-0.6-5.4-0.4
	c-2.5,0.2-22.7,1.6-26,1.6c-3.3,0-4.7-0.8-6.4-2.1c-1.4-1.2-12.4-10.7-16.1-14"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M477.5,304.7c11.6-1.9,39.8-6.6,51.6-8c11.8-1.4,25.2-2.9,31.4-3.1
	c1.7,0,4.3,0.2,5.6,0.6c1.2,0.4,3.7,1,4.5,2.9c0.4,1,0.8,2.3,0.8,4.5c0,2.7,0.4,8.3-1,14.6c-1.2,6.4-3.1,6-4.7,6.4
	c-1.7,0.2-8,1.2-14.9,1.9c-1.2,0.2-2.9,0.2-6-0.6c-3.1-0.8-16.5-5.2-17.7-5.4c-1.2-0.4-3.5-0.6-5.2-0.6c-1.6,0.2-22.5,1.6-25.2,1.6
	c-2.7,0-4.1-0.2-6.4-2.1c-2.1-1.9-12-10.5-13.2-11.6C476.5,305.1,477.1,304.9,477.5,304.7L477.5,304.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M478.3,306.7c12.4-2.3,39.6-6.2,51.4-7.6c11.8-1.4,26.6-3.1,40.2-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M521.8,317.5c9.3-0.4,32.4-2.1,33.4-2.1c1.2,0,3.1,0.2,4.1,1.6
	c1.2,1.4,2.7,3.7,2.7,3.7c1.4-0.2,1.9-0.2,2.3-0.2c0.4-0.2,2.5-0.8,3.3-3.7c0.8-2.9,2.1-11.1,1.4-20.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M560.6,320.3l-1.9-2.1c-1.2-1.4-2.9-1.6-4.1-1.6c-0.8,0-11.8,0.8-21.7,1.4
	c0,0,9.7,2.9,11.1,3.3c1.2,0.2,2.5,0.4,4.1,0.4C550.1,321.6,557.3,321,560.6,320.3z M560.6,320.3l1.7,0.4 M533,318.1l-5-0.6
	c0,0-2.1-0.2-3.5-0.2 M487.8,305.1c2.7,2.3,5.4,4.3,6.2,5c2.3,1.6,3.1,1.9,6,1.6c2.7,0,24.4-2.3,26-2.5c6.6-0.4,27.4-2.7,28.7-2.7
	c1.6-0.2,3.1-0.6,4.5-3.5c1-2.1,1.9-4.1,2.9-6.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M480.2,306.9c2.3-0.4,5-0.8,8-1.2c0,0,0.2,0,0.2-0.2 M482.2,306.7
	c3.3,2.9,8,7,9.5,8.3c2.1,1.9,3.5,1.9,6.4,1.9c2.7,0,24.1-1.6,25.8-1.6c6.6-0.4,31.8-2.3,33-2.5c1.2,0,3.1,0.2,4.1,1.7
	c1.2,1.4,3.5,5.2,3.5,5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M484.9,306.1c2.9,2.7,6.2,5.4,7.2,6.4c2.1,1.9,3.1,1.6,6,1.6
	c2.7,0,24.3-1.9,26-1.9c6.6-0.4,30.1-2.5,31.4-2.7c1.7-0.2,3.5-0.4,5-3.3c1.4-2.9,2.9-6.4,3.9-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M561.5,297.6c0.4-0.2,1-0.4,1.9-0.4c0.8,0,2.9-0.2,4.7,0
	c0.2,0,0.2,0,0.4,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M566.6,297c-0.6,2.5-2.3,8-5.6,13.8c-0.2,0.4-0.4,0.8,0,1.2
	c0.2,0.4,2.9,5.4,3.9,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M567,311.9c-1.9,0.2-3.7,0.4-5.2,0.4h-0.2l-0.2-0.6c-0.2-0.4,0-0.6,0-0.6
	l0.4-0.8l0.2-0.2c1.4-0.2,3.9-0.4,5.4-0.6v1.4C567.4,311.3,567.2,311.7,567,311.9L567,311.9z M567,311.9c-0.4,2.7-0.8,4.3-1.2,5.4
	c-0.4,0.8-0.6,1.4-1,1.6 M567.5,297c0.2,0,0.6,0.2,0.6,0.6s0.2,6.6-0.4,11.1v0.6 M544.8,321.4c0.6-0.4,2.5-1,2.9-1
	c0.4-0.2,0.8-0.2,1-0.2h3.3c0,0,0.4,0,0.6,0.2c0.2,0.2,0.4,0.4,0.4,0.6v0.4 M555.7,316.8c-0.2,0.6-0.6,2.9-0.6,4.3 M544.5,317.5
	c-0.6,0.6-1.7,2.7-1.7,3.7 M558.6,296.4c0.2,0.2,0.6,1,0.6,2.1v3.9 M555.3,296.6c1.7-0.2,2.9,1.4,2.9,2.3v3.5c0,1-0.2,2.1-1,3.1
	 M550.3,297v9.5c0,0,0,0.4,0.4,0.4h1.9 M539.6,297.8c0.2,0.6,0.6,3.1,0.6,6.2c0,0.4,0,0.6-0.2,0.8c-0.2,0.2-0.2,0.4-0.4,0.6
	c0,0.2-0.2,2.5-0.2,2.5 M530.3,299.1v9.1c0,0,0,0.2,0.2,0.2h6.6 M537.5,298.1v10.3 M527.2,299.3v9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.3,297v9.1c0,0.4-0.2,0.8-0.8,0.8s-8,0.2-8,0.2 M527.8,299.3
	c-0.2,1.6,0,7.4,0.4,9.9 M525.4,309.4v-9.3c0-0.2,0-0.4-0.4-0.4 M517.9,300.5v9.5 M516.5,310.2v-8.9c0-0.2,0-0.4-0.4-0.4
	 M509.5,301.8v9.1 M508.2,311.1v-8.7c0-0.2,0-0.4-0.4-0.4 M501,303v8.7 M499.4,303.2c0.4,0,0.4,0.2,0.4,0.4v7.4c0,0.2,0,0.6-0.6,0.6
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M576.1,243.2c0.4,0.2,3.1,1.2,5.2,1.2
	c1.9,0,10.7-0.4,19.2-1.2c4.5-0.4,8.7-1,11.6-2.1c2.5-0.8,3.9-1.9,5.8-3.7c1-1,2.9-3.5,4.5-8.3c0.4-1,0.2-2.1-0.2-3.5
	c-0.4-1.4-2.1-7.4-9.3-10.7c-7.4-3.5-19.4-5.2-31.2-5.2c-3.1,0-5.4,1.7-7.6,4.7c-2.3,3.3-5.2,9.3-6,10.5c-0.6,1.4-1.4,1.9-2.3,1.9
	c-1,0-5,0.4-5.4,0.4c-0.4,0-0.4,0.2-0.4,0.2 M515.2,144.1c-0.2-0.2-0.2-0.4-0.2-0.4c-0.6-1-1.4-2.1-2.5-3.1
	c-0.6-0.4-1.9-1.4-2.9-2.1c-1.2-0.8-2.3-1.2-2.7-1.4c-0.8-0.4-2.3-0.6-2.9-0.6c-0.6-0.2-0.8,0-1,0l-0.8,0.8 M517.9,147
	c-0.8-1.4-1.4-2.7-1.7-3.1c-0.4-0.6-1.2-0.6-1.2-0.6 M518.1,147.2c0,0,0.8,0.6,1,1.2c0.4,0.6,1,1.9,1,1.9s1.2,0.6,2.3,2.3
	c1,1.6,7,10.3,14.9,24.6c6.4,11.6,11.6,22.9,17.5,38.4c1.4,3.7,3.1,7.6,4.5,12 M216.2,430.7h7.8c16.3,0,43.1,0.8,43.1,0.8h107.9
	 M158.9,291.2c-0.6,0.8-1.2,1.6-1.6,2.5c-0.6,1-1,3.1-1.2,8.9c-0.2,6,0.2,21.3,1.4,43.3c0.4,8,1.2,15.5,2.1,21.9 M159.3,370.9
	c0,1.4-1.2,3.9-1.2,6.6c0,2.9,1,7,1,7 M166.9,260.5c1.6-3.7,1.9-3.9,2.3-4.7s0.6-1.9,0.4-2.7s-0.2-2.3,1-4.1c1.2-2.1,3.1-3.7,5-5.2
	 M158.3,291.9c0.4-2.9,1.4-9.1,3.1-15.3c0-0.4,0.2-0.6,0.2-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M167.1,260.1c-3.1,0-7,0.6-7.2,1
	c-0.2,0.6-0.8,3.3-0.8,4.5 M175,244.2c0,0-1.2,0.2-6.2,0c-1.6,0-8.3-0.4-15.1-1.2c-3.7-0.4-7-1-9.5-2.1c-2.1-0.8-4.1-2.3-5.6-4.1
	c-0.8-1-2.5-3.1-3.7-7.8c-0.4-1-0.2-2.1,0.2-3.5c0.4-1.4,1.6-7.4,7.6-10.7c6-3.5,20.2-5.2,29.9-5.2c2.7,0,4.3,1.6,6.2,4.7
	c1.9,3.3,3.7,9.3,4.3,10.5c0.6,1.4,1,1.9,1.9,1.9c0.8,0,4.7,0.4,5,0.4h1 M233.2,147c1-1.9,1.6-3.1,1.9-3.3c0.6-1,1.4-2.1,2.5-3.1
	c0.6-0.4,1.9-1.4,2.9-2.1c1.2-0.8,2.3-1.2,2.7-1.4c0.8-0.4,2.3-0.6,2.9-0.6c0.6-0.2,0.8,0,1,0l0.8,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M231.9,147.2c0,0-0.8,0.6-1,1.2
	c-0.4,0.6-1,1.9-1,1.9s-1.2,0.6-2.3,2.3c-1,1.6-7,10.3-14.9,24.6c-6.4,11.6-11.6,22.9-17.5,38.4c-1.4,3.7-3.1,7.6-4.5,12
	 M247.2,137.9c0.6-1,1-1.2,1.2-1.4c15.5-1.9,32.2-3.3,49.3-4.3c29.5-1.6,59-2.1,77.2-2.1s47.7,0.4,77.2,2.1
	c17.1,1,33.8,2.7,49.3,4.3c0.2,0,0.6,0.2,1,0.8 M159.3,373.8c0-2.3,0-4.5,0.2-6.8 M216.7,430.9v26.8c0,1.6-0.8,2.1-1.2,2.7
	c-0.4,0.6-1,5.4-4.5,5.4h-46.4c-3.3,0-6.6-2.7-6.6-9.3c0-3.7,0.6-43.3,1.2-75.3 M373.9,129.7c0.2-1,0.2-2.1,0.4-2.5
	c0.2-0.6,0.6-0.8,0.8-0.8c0.2,0,0.8,0.2,0.8,0.8c0,0.2,0.2,1.2,0.4,2.5 M533.8,430.7h-7.6c-16.3,0-43.1,0.8-43.1,0.8H375.1
	 M591.4,291.5c0.6,0.8,1.2,1.6,1.7,2.3c0.6,1,1,3.1,1.2,8.9c0.2,6-0.2,21.3-1.4,43.3c-0.4,8-1.2,15.5-2.1,21.9 M582.7,259.5
	c-1.2-2.9-1.4-3.1-1.9-3.7c-0.4-0.8-0.6-1.9-0.4-2.7c0.2-0.8,0.2-2.3-1-4.1c-1.2-2.3-3.5-4.1-5.8-5.6 M591.8,291.9
	c-0.4-2.9-1.4-9.1-3.1-15.3c0-0.4-0.2-0.6-0.2-1 M588.5,275.6c1-0.4,2.9-1,3.9-1.6c1.2-0.8,1.7-1.2,1.7-2.7c0-1.2-0.2-3.1-0.4-4.3
	c-0.2-0.6-1.4-1-3.3-1.2 M582.9,260.1c3.1,0,7,0.6,7.2,1c0.2,0.6,0.8,3.3,0.8,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M533.4,430.9v26.8c0,1.6,0.8,2.1,1.2,2.7
	c0.4,0.6,1,5.4,4.5,5.4h46.4c3.3,0,6.6-2.7,6.6-9.3c0-4.3-0.8-56.1-1.4-88.9 M161.6,275.6c-1-0.4-2.9-1-3.9-1.6
	c-1.2-0.8-1.6-1.2-1.6-2.7c0-1.2,0.2-3.1,0.4-4.3c0.2-0.6,1.2-1,2.7-1.2"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M200.2,243
		c0,0,165.1-12.4,349.8,0.2c0,0-15.5-49.3-34.7-84.6c0,0-129.2-10.5-280.2,0C235,158.6,224.9,172,200.2,243z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M471.5,304.9
		l17.1,15.1c0,0,1.9,2.7,7.8,2.3l24.4-1.6c0,0,2.7-0.4,4.5,0.2c1.6,0.6,20.2,6.2,20.2,6.2s1,0.4,2.7,0.4s18.8-2.1,18.8-2.1
		s2.3,0,3.3-2.5c1-2.5,1.7-6.6,1.7-6.6s1.2-13.4,0.4-16.5c0,0-0.6-6.2-6.8-6.2C565.6,293.1,541,293.1,471.5,304.9z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M278.6,304.9
		l-18.4,15.9c0,0-2.3,1.2-5.2,1.2s-27.4-1.7-27.4-1.7s-1.4-0.2-2.7,0.2c-1.2,0.4-20.4,6.4-20.4,6.4s-1,0.4-2.9,0.4
		c-1.9-0.2-18.6-2.1-18.6-2.1s-3.1-0.4-4.3-5c0,0-1.4-7.4-1-20.8c0,0,0.4-5.8,5.8-6.2C188.6,292.9,205.9,293.1,278.6,304.9z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M275.9,325.1
		c0,0,3.1,31.8,16.5,69.7c0,0,1.2,5,9.3,5.2s110.2,1.2,144.9,0c0,0,8.5,0.4,10.5-4.5c0,0,14.2-37.6,16.7-70.6c0,0,0.8-3.7-2.1-6.8
		c0,0-12.8-14-18.6-14.6c-5.6-0.6-67.9-5.2-155.4-0.2c0,0-3.9,0-8.7,4.1c-4.7,4.1-10.7,9.7-11.8,11.4
		C277.7,318.7,275.3,321.6,275.9,325.1z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M161.8,359.3
		c0,0,7,2.7,27.9,4.1s94.9,5.2,94.9,5.2s5.6,21.5,7.6,25.2c2.1,3.7,2.5,6.2,9.7,6.6s102.6,1.6,148.2,0c0,0,6.4-0.4,8.5-7.2
		c2.3-6.8,7.2-24.3,7.2-24.3s114.3-4.1,122.8-9.3c0,0-2.5,29.9-6,49.3c0,0-0.4,1,0.2,3.1c0.6,2.1,1,4.3,1,4.3s0.2,0.2-0.2,1.2
		c-0.4,1-2.7,7.2-3.7,8c-1,0.8-16.5,3.7-33.2,3.3c0,0,0,2.3-13.2,2.3c-13.2,0-132.9,1.6-316.7,0c0,0-11.8,0.2-13.2-2.3
		c0,0-16.5,0.4-32.8-3.1c0,0-1.4-1.4-3.9-7.8c0,0-0.6-1,0-2.3c0,0,1-4.1,1-5.2C167.6,409.3,163.4,377.3,161.8,359.3z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M211.1,295c0,0-7-14.2-24.6-38.8
		c0,0-1.2-2.1,0.4-3.5c1.6-1.4,10.9-8.7,10.9-8.7s0.8-1,3.1-1c0,0,177-12,348.9,0.2c0,0,1.2,0,2.9,1.4c1.7,1.4,10.3,8,10.3,8
		s1.9,1.7,0.4,3.7c-1.4,2.1-12.2,16.9-24.6,39c0,0-52.6,6.6-79,11.8c0,0-3.7-3.5-8.5-3.7c-5-0.2-79.6-5.2-153.1,0c0,0-3.5-0.2-8,3.7
		C290.1,306.9,244.3,298.5,211.1,295z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Sedan4DrFront);
