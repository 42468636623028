import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, some } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { loadBuyerTitles, resetBuyerBids } from '../../actions';
import { compose, getConfig } from '../../utils/helpers';

class BuyerTitles extends Component {
  state = {
    title: 'buyer titles',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetBuyerBids();
    this.props.loadBuyerTitles(getConfig('marketplaceId'));
  };

  render() {
    const loading = isEmpty(this.props.buyerTitles);
    const data = (this.props.buyerTitles.data || []).filter(item => {
      const { bCompNo, sCompNo, type } = item;
      const accountList = get(this.props.userProfile, 'user.accountList');
      let isBuyer = some(accountList, { accountNumber: bCompNo });
      let isSeller = some(accountList, { accountNumber: sCompNo });
      if (isBuyer && isSeller) {
        if (type === 'Title Not In') isSeller = false;
        if (type === 'Title Due From You') isBuyer = false;
      }
      return isBuyer;
    });
    const columns = this.props.makeColumns([
      'checkbox',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'seller',
      'sDate',
      'title',
    ]);

    /*
    SAMPLE
      aucAccId: "5003027"
      bCompNo: "M7298"
      bName: "HOCKETT MOTORS INC"
      body: "SEDAN 4-DR"
      buyOrSellDealer: "B"
      controlNo: "362791"
      gatepass: "1"
      gross: "6100.00"
      lotNo: "B207"
      make: "Chrysler"
      model: "300"
      sCompNo: "M8922"
      sDate: "2021-08-06T00:00:00"
      sName: "FORUM CREDIT UNION"
      series: ""
      titleDate: "2021-08-02T00:00:00"
      type: "Title given out Wednesday, 11 August 2021 AM to ROBERT D HOCKETT SR"
      vin: "2C3CCARG0FH892267"
    */

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Titles</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { buyerTitles, userProfile } = state.entities;
  return { buyerTitles, userProfile };
};

export default compose(
  withTable,
  connect(mapStateToProps, { loadBuyerTitles, resetBuyerBids })
)(BuyerTitles);
