import React, { Component } from 'react';
import { connect } from 'react-redux';

class CrewCabPickup4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 120 800 350">
          <path
            fill="#FFFFFF"
            d="M199.6,230.7c3.8,1.8,9.4,3,17.1,3.8c3.6,0.3,7.7,0.5,12.2,0.7l18.1,2.5c1,0.3,1.5,0.8,1.8,1.3
	c0.2,0.2,0.2,0.3,0.2,0.5c-0.2,0.3-0.2,0.5-0.2,0.8c-0.2,0.3-0.2,0.7-0.3,1.1c-0.5,1.8-1,3.8-1.6,5.6c-1.6,1.3-3,2.6-4.3,4.1
	c-5.9,6.6-9.4,14.5-10,23.7v0.7l-1.6,19.9l0.3,1.8l0.7,14.5l0.7,14.8c-0.8,1.6-1.5,3.8-2,6.4v3.3c0,2.3,0.2,4.8,0.3,7.1
	c0,1.1,0,2.3,0.2,3.3c0,0.5,0,1.1,0.2,1.6c1.1,16.9,3.5,34.5,7.2,52.6L245,414c7.1,1,14.5,1.8,21.9,2.6c4.6,0.5,9.2,1,13.8,1.3
	c0.5,0,1,0.2,1.5,0.2c3,0.3,5.9,0.5,8.9,0.7c2.6,0.2,5.4,0.3,8.1,0.7c1.1,0,2.3,0.2,3.5,0.2c8.5,0.5,17.1,1,26,1.3
	c5.6,0.2,11.3,0.5,17.3,0.7c1.8,0,3.8,0,5.6,0.2h3.3v-0.5c0.2,0,0.5,0,0.7-0.2c5.4-1,9.2-2.1,11.5-3.5h108.7c1.2,0.7,2.6,1.3,4.6,2
	c2.8,0.8,6.4,1.5,10.9,2.1c7.7-0.2,15.3-0.3,22.9-0.7c8.7-0.3,17.4-0.8,25.8-1.3h0.8c1,0,2-0.2,3-0.2c5.8-0.3,11.3-0.8,16.9-1.3
	c0.5,0,1-0.2,1.5-0.2c12.3-1.1,24.2-2.5,35.7-3.9l6.4-13.2c3.8-18.3,6.2-35.8,7.2-52.6v-1.6c0.2-3.5,0.3-7.1,0.5-10.5V333
	c-0.5-2.6-1.2-4.8-2-6.4l0.7-14.8l0.7-14.5l0.3-1.8l-1.6-19.9v-0.7c-0.2-2.1-0.5-4.1-1-6.2c-0.7-2.8-1.5-5.3-2.6-7.7
	c-0.8-1.8-1.8-3.6-3-5.3c-0.2-0.3-0.3-0.5-0.5-0.8s-0.5-0.5-0.7-0.8c-0.5-0.8-1.2-1.5-1.6-2.1c-0.2-0.3-0.5-0.5-0.7-0.8
	c-1.3-1.5-2.8-2.8-4.3-4.1c-0.5-2-1-3.8-1.6-5.6c-0.2-0.3-0.2-0.8-0.3-1.1c0-0.3-0.2-0.5-0.2-0.8c0.2-0.8,0.8-1.3,2.1-1.8l18.1-2.5
	c14.1-0.2,23.8-1.6,29.3-4.3c0.3-0.2,0.7-0.3,1-0.5c4.3-4.3,4.8-10.5,1.5-18.9c-2.8-5.1-7.7-8.4-14.6-10.2c-5.8-1.5-12.8-1.8-21.4-1
	c-0.8,0.2-1.6,0.3-2.5,0.5c-0.3,0.2-0.8,0.2-1.2,0.3c-0.2,0-0.3,0.2-0.5,0.2c-1.3,0.5-2.3,1.1-3.3,1.8c-3.8,3-5.4,8.2-4.6,15.6
	h-10.2c-1-3.5-2.1-6.7-3.1-10c-0.8-2.6-1.6-5.1-2.5-7.4c-9.4-28.3-17.9-47.9-25.8-58.7c-1.8-2.6-3.9-4.9-6.4-6.6
	c-0.7-0.3-1.3-0.8-2-1c-0.3-0.2-0.7-0.2-1-0.3c-1-0.3-2-0.5-2.8-0.7c-1.3-0.3-2.8-0.7-4.1-0.8c-1.2-0.2-2.1-0.3-3.5-0.5
	c-3-0.3-6.4-0.8-10.2-1.1c-0.2-0.3-0.3-0.5-0.5-0.8c-0.5-0.7-1.3-1-2.1-1l-1.8-0.2c-0.8,0-1.5,0.2-2.1,0.8l-0.5,0.5h-0.3
	c-20.6-1.6-50.5-2.6-89.9-3.1h-15c-4.8,0-9.5,0.2-14.1,0.2c-15,0.2-28.6,0.5-40.5,1c-13.8,0.5-25.7,1.1-35.4,1.8h-0.3l-0.5-0.5
	c-0.7-0.5-1.3-0.8-2.1-0.8l-1.8,0.2c-0.8,0-1.5,0.3-2.1,1c-0.2,0.2-0.3,0.5-0.5,0.8h-0.3c-5.3,0.5-9.7,1-13.3,1.6
	c-1.3,0.2-2.6,0.5-4.1,0.8c-1,0.2-2,0.5-3,0.7c-0.3,0-0.7,0.2-1,0.3l-2,1c-1,0.7-1.8,1.3-2.6,2.1c-1.5,1.3-2.8,2.8-3.8,4.6
	c-7.9,10.9-16.4,30.4-25.8,58.7c-1.1,3.6-2.5,7.4-3.6,11.2c-0.7,2.1-1.3,4.1-2,6.2H245c0.2-1.3,0.2-2.5,0.2-3.5c0-7.2-2.8-12-8.1-14
	c-0.5-0.2-1.1-0.3-1.6-0.5c-0.7-0.2-1.5-0.3-2.3-0.5H233c-6.2-0.5-11.7-0.5-16.3,0c-1.8,0.2-3.5,0.5-4.9,1
	c-6.9,1.6-11.7,4.9-14.5,9.9c0,0.2-0.2,0.2-0.2,0.3c-3.3,8.4-2.8,14.6,1.5,18.9C198.8,230.4,199.1,230.6,199.6,230.7L199.6,230.7z"
          />
          <path
            fill="#FFFFFF"
            d="M199.6,230.7c3.8,1.8,9.4,3,17.1,3.8c3.6,0.3,7.7,0.5,12.2,0.7l18.1,2.5c1,0.3,1.5,0.8,1.8,1.3
	c0,0.2,0.2,0.2,0.2,0.3l2-6.4l2-7.1l2.1-7.1h-10c0.2-1.3,0.2-2.5,0.2-3.5c0-7.2-2.8-12-8.1-14c-0.5-0.2-1.1-0.3-1.6-0.5
	c-0.7-0.2-1.5-0.3-2.3-0.5H233c-6.2-0.5-11.7-0.5-16.3,0c-1.8,0.2-3.5,0.5-4.9,1c-6.9,1.6-11.7,4.9-14.5,9.9c0,0.2-0.2,0.2-0.2,0.3
	c-3.3,8.4-2.8,14.6,1.5,18.9C198.8,230.4,199.1,230.6,199.6,230.7L199.6,230.7z"
          />
          <path
            fill="#EAEAEA"
            d="M552,153.9c-12-4.4-55.7-7.1-131.1-7.9c-75.1,0.8-118.9,3.5-130.9,7.9c-8.9,21-17.9,46.7-27.1,76.8l0.2,1.3
	l6.6-0.8c0.7-0.2,1.3-0.2,2-0.3c3.1-0.8,5.4-2.1,6.9-4.1c0.7-0.7,1.8-1.3,3.3-1.8c3.3-1.1,8.4-2,15.1-2.1l49.2-1.8
	c4.3,0.3,7.2,1.8,9,4.4c0.8-0.2,1.6-0.3,2.5-0.5c3.5-0.5,7.7-0.5,12.7-0.3c5.4-0.2,10.7-0.5,16.1-0.7c3-0.2,5.8-0.2,8.5-0.3
	l25.8-0.3l25.8,0.3c3,0,5.8,0.2,8.5,0.3c5.4,0.2,10.9,0.3,16.1,0.7c4.9-0.2,9.2-0.2,12.7,0.3c0.8,0.2,1.8,0.3,2.5,0.5
	c1.8-2.8,4.8-4.3,9-4.4l49.2,1.8c6.9,0.3,11.8,1,15.1,2.1c1.5,0.5,2.5,1.2,3.3,1.8c1.5,2,3.8,3.3,7.1,4.1c0.7,0.2,1.3,0.3,2,0.3
	l6.6,0.8l0.2-1.3C569.9,200.6,560.9,175,552,153.9L552,153.9z"
          />
          <path
            fill="#5E5E5E"
            d="M541.1,274.6l-0.3,5.1c0,0.5,0,1-0.2,1.3c-0.2,0.2-0.2,0.3-0.3,0.5c-0.3,0.3-0.8,0.3-1.5,0.3h-0.7
	l-100.5,1.6c0-0.5,0-1-0.2-1.5c0-0.2,0-0.3-0.2-0.3c-0.3-1.3-1.1-2.1-2.1-2.6c-0.8-0.3-1.6-0.5-2.8-0.5h-22.2
	c-1.1,0-2.1,0.2-2.8,0.5h-0.2c0,0-0.2,0-0.2,0.2h0.1c0,0-0.2,0-0.2,0.2l-0.2,0.2l-0.2,0.2l-0.2,0.2c0,0.2,0,0.3-0.2,0.5
	c-0.2,0.5-0.2,1-0.2,1.5l-100.5-1.6h-0.7c-0.7,0-1.1,0-1.5-0.3c-0.2-0.2-0.3-0.3-0.3-0.5c-0.2-0.3-0.2-0.8-0.2-1.3l-0.5-7.6
	c0-0.3,0-0.5,0.2-0.7c0-0.2,0.2-0.3,0.3-0.5c0.5-0.3,1.3-0.5,2.3-0.5l46,0.3l123.2,0.2L540,269c1,0,1.8,0.2,2.3,0.5
	c0.2,0.2,0.3,0.3,0.3,0.5C541.3,272.3,541.1,273.6,541.1,274.6L541.1,274.6z M549,274.6c0,0.2-0.2,0.3-0.3,0.5
	c-0.2,0.5-0.5,0.8-0.8,1.1l0.8-1.8l0.8-2c0,0.5-0.2,1-0.3,1.3C549,274.3,549,274.5,549,274.6L549,274.6z M537.9,311.3l-0.7,8.2v0.5
	c0,0.2,0,0.3-0.2,0.3c-0.2,0.3-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1,0.7c-0.5,0.2-1.2,0.3-1.6,0.3h-12.3c-3.3,0-6.7,0.2-10,0.2h-1.8
	c-3,0-5.9,0.2-8.9,0.2c-2.6,0-5.3,0-7.9,0.2h-97.9h1.1h-2.6h1h-10.4h2.1h-2.6h1h-2.6h1h-2.6h1h-37.7c-2.8,0-5.6-0.2-8.4-0.2h-2.3
	c-3.1,0-6.2-0.2-9.4-0.2h-1.2c-3.5,0-7.1-0.2-10.5-0.2h-1.6c-1.6,0.2-3-0.2-3.8-0.8c-0.3-0.2-0.5-0.5-0.7-0.8
	c-0.2-0.2-0.2-0.3-0.3-0.5c-0.3-0.7-0.5-1.6-0.7-2.6l-0.7-8.2l-0.5-5.6c0.2-1.1,1-1.6,2.6-1.8l98.3,1.3c0,0.5,0,1,0.2,1.5
	c0,0.5,0.2,0.8,0.3,1.3c0.7,1.5,2,2.3,4.1,2.5h22.9c1.5,0,2.5-0.3,3.5-0.8c0.5-0.7,0.8-1.5,1-2.5v-1l98.3-1.3c1,0,1.6,0.3,2,0.7
	c0.3,0.3,0.5,0.7,0.5,1.1L537.9,311.3L537.9,311.3z"
          />
          <path
            fill="#646464"
            d="M266.7,416.5c-7.4-0.8-14.8-1.6-21.9-2.6l-6.4-13.2c-3.8-18.7-6.2-37-7.4-54.4v112c0,7.4,3.8,11.2,11.2,11.2
	h28.4c7.4,0,11.2-3.8,11.2-11.2v-40.5c-0.5,0-1-0.2-1.5-0.2C275.8,417.3,271.1,417,266.7,416.5L266.7,416.5z M561.5,417.8
	c-0.5,0-1,0.2-1.5,0.2v40.5c0,7.4,3.8,11.2,11.2,11.2h28.4c7.4,0,11.2-3.8,11.2-11.2v-112c-1,17.4-3.5,35.5-7.4,54.4l-6.4,13.2
	C585.7,415.4,573.9,416.7,561.5,417.8L561.5,417.8z"
          />
          <path
            fill="#FFFFFF"
            d="M290.1,153.9c12-4.4,55.7-7.1,130.9-7.9c75.1,0.8,118.9,3.5,131.1,7.9c5.8,13.8,11.7,29.4,17.6,47.2
	c3.1,9.4,6.2,19.2,9.4,29.8l-0.2,1.3l-6.6-0.8c-0.7-0.2-1.3-0.2-2-0.3c-3.1-0.8-5.4-2.1-7.1-4.1c-0.7-0.7-1.8-1.3-3.3-1.8
	c-3.3-1.1-8.4-2-15.1-2.1l-49.2-1.8c-4.3,0.3-7.2,1.8-9,4.4l-2.5-0.5c-3.5-0.5-7.7-0.5-12.7-0.3c-5.4-0.2-10.9-0.5-16.1-0.7
	c-3-0.2-5.8-0.2-8.5-0.3l-25.8-0.5l-25.8,0.3c-3,0-5.8,0.2-8.5,0.3c-5.4,0.2-10.9,0.3-16.1,0.7c-4.9-0.2-9.2-0.2-12.7,0.3
	c-0.8,0.2-1.8,0.3-2.5,0.5c-1.8-2.8-4.8-4.3-9-4.4l-49.2,1.8c-6.9,0.3-11.8,1-15.1,2.1c-1.5,0.5-2.5,1.2-3.3,1.8
	c-1.5,2-3.8,3.3-6.9,4.1c-0.7,0.2-1.3,0.3-2,0.3l-6.6,0.8l-0.2-1.3c3.1-10.4,6.2-20.4,9.5-29.8C278.4,183.4,284.1,167.7,290.1,153.9
	L290.1,153.9z M642.6,230.7c-3.8,1.8-9.4,3-17.1,3.8c-3.6,0.3-7.7,0.5-12.2,0.7l-18.1,2.5c-1,0.3-1.5,0.8-1.8,1.3
	c0,0.2-0.2,0.2-0.2,0.3l-2-6.4l-2-7.1l-2.1-7.1h10.2c-0.2-1.3-0.2-2.5-0.2-3.5c0-7.2,2.8-12,8.1-14c0.5-0.2,1.2-0.3,1.6-0.5
	c0.7-0.2,1.5-0.3,2.3-0.5h0.2c6.2-0.5,11.7-0.5,16.3,0c1.8,0.2,3.5,0.5,4.9,1c6.9,1.6,11.7,4.9,14.5,9.9c0,0.2,0.2,0.2,0.2,0.3
	c3.3,8.4,2.8,14.6-1.5,18.9C643.3,230.4,642.9,230.6,642.6,230.7L642.6,230.7z"
          />
          <path
            fill="#5E5E5E"
            d="M611.4,336v-3.3c-0.5-2.6-1.2-4.8-2-6.4c-0.3-0.7-0.7-1.3-1-1.8c-0.5-0.7-1.2-1.3-1.6-1.8
	c-0.8-0.7-1.8-1-3-1.1c-16,2-32.2,3-49,3c-1.5,0.2-3,0.8-4.1,2.3c-1,1-1.8,2.5-2.5,4.1c-1.8,1.8-4.3,3.1-7.7,3.8
	c-2.1,0.5-4.8,0.7-7.7,0.5h-1.3v0.2v-0.2c-32.2,0.8-64.1,1.1-95.5,1.1h-29.6c-18.2,0-36.5,0-55.1-0.3c-13.8-0.2-27.8-0.5-41.8-0.8
	c-7.4,0.2-12.7-1.3-15.5-4.3c-0.8-1.8-1.6-3.1-2.5-4.1c-1.3-1.5-2.6-2.1-4.1-2.3c-16.8,0-33.1-1-49-3c-1.2,0-2,0.5-3,1.1
	c-0.8,0.5-1.5,1.3-2.1,2.3c-0.2,0.3-0.5,0.8-0.7,1.2c-0.8,1.6-1.5,3.8-2,6.4v3.3c0,2.3,0.2,4.8,0.3,7.1c0,1.1,0,2.3,0.2,3.3l0.2,1.6
	c0.7,10.5,1.8,21.4,3.6,32.6v0.3c1,6.6,2.3,13.2,3.6,19.9l6.4,13.2c7.1,1,14.5,1.8,21.9,2.6c4.6,0.5,9.2,1,13.8,1.3
	c0.5,0,1,0.2,1.5,0.2c3,0.3,5.9,0.5,8.9,0.7c2.6,0.2,5.4,0.3,8.1,0.7l3.5,0.2c8.5,0.5,17.1,1,26,1.3c5.6,0.2,11.3,0.3,17.3,0.7
	l5.6,0.2l3.3-0.5v0.5h7.4c20.4,1,40,1.2,59,0.7c8.4,0.2,16.9,0.3,25.7,0.2c3.5,0,7.1,0,10.5-0.2c7.6-0.2,15.1-0.5,22.9-0.8
	c3.6,0,7.2-0.2,10.9-0.2c7.7-0.2,15.3-0.3,22.9-0.7c8.7-0.3,17.4-0.8,25.8-1.3h0.8c1,0,2-0.2,3-0.2c5.8-0.3,11.3-0.8,16.9-1.3v40.5
	c0,7.4,3.8,11.2,11.2,11.2H600c7.4,0,11.2-3.8,11.2-11.2v-112C611.2,342.9,611.4,339.4,611.4,336L611.4,336z"
          />
          <path
            fill="#FFFFFF"
            d="M316.2,132.9c0,0.8,0.5,1.5,1,2c0.7,0.5,1.3,0.8,2.1,0.8l1.8-0.2c0.8,0,1.5-0.3,2.1-1c0.5-0.5,0.7-1,0.8-1.6
	l-0.2-0.8v-0.3v-0.3c0-0.3-0.2-0.7-0.5-1l-0.5-0.5c-0.7-0.5-1.3-0.8-2.1-0.8l-1.8,0.2c-0.8,0-1.5,0.3-2.1,1
	c-0.2,0.2-0.3,0.5-0.5,0.8c0,0.2-0.2,0.2-0.2,0.3L316.2,132.9L316.2,132.9z M518.1,131.9v0.3L518,133c0,0.7,0.3,1.2,0.8,1.6
	c0.5,0.7,1.3,1,2,1l1.8,0.2c0.8,0,1.5-0.2,2.1-0.8c0.5-0.5,0.8-1.1,1-2v-1v-0.3c0-0.2-0.2-0.2-0.2-0.3c-0.2-0.3-0.3-0.5-0.5-0.8
	c-0.5-0.7-1.3-1-2.1-1l-1.8-0.2c-0.8,0-1.5,0.2-2.1,0.8l-0.5,0.5l-0.5,1C518.3,131.6,518.3,131.7,518.1,131.9L518.1,131.9z"
          />
          <path
            fill="#5E5E5E"
            d="M420.9,143.7c-75.3,0.8-118.6,3.3-129.9,7.6c-0.3,0.8-0.8,1.6-1.2,2.6c12-4.4,55.7-7.1,130.9-7.9
	c75.1,0.8,118.9,3.5,131.1,7.9l-1.2-2.6C539.7,147,496.3,144.6,420.9,143.7z"
          />
          <path
            fill="#5E5E5E"
            d="M262.9,230.7c0-0.2,0.2-0.5,0.2-0.7C263.1,230.2,263.1,230.4,262.9,230.7z M263.9,218.7
	c-1.3,3.9-2.5,7.9-3.8,12c-0.2,0.3-0.2,0.5-0.3,0.8l0.3,0.8l2.8-0.3v-0.7c0-0.7,0.2-1.3,0.2-1.3c0-0.3,0.2-0.5,0.2-0.8
	c3-9.9,6.1-19.2,9-28.1c1.5-4.6,3.1-9,4.6-13.5c0.3-1,0.7-2,1-3c3.9-11,7.9-21.4,12-30.7c0.3-0.8,0.7-1.6,1.1-2.6
	c2.1-4.8,4.1-9.2,6.1-13.5l1.3-2.6c0-0.2,0.5-0.8,0.8-1.6c-0.2,0-0.3,0.2-0.7,0.2c-1,0.2-2,0.5-3,0.7c-0.5,1.1-1.1,2.3-1.6,3.5
	C284.3,158.7,274.1,185.7,263.9,218.7L263.9,218.7z"
          />
          <path
            fill="#FFFFFF"
            d="M282,234.3l4.8,7.7c2.8,3,6.9,4.1,12,3.6l65.4-1.1l1-4.9c0.2-3.6-0.2-6.6-1-8.7c-1.1-3.1-3.3-5.1-6.4-5.8
	c-0.8,0.2-1.8,0.3-2.5,0.5c-1.8-2.8-4.8-4.3-9-4.4l-49.2,1.8c-6.9,0.3-11.8,1-15.1,2.1c-2.1,1.3-2.6,3.3-1.8,5.9
	C280.4,231.7,281,233,282,234.3L282,234.3z"
          />
          <path
            fill="#5E5E5E"
            d="M579.1,230.7c0-0.2-0.2-0.5-0.2-0.7C579,230.2,579.1,230.4,579.1,230.7z M578.3,218.7
	c1.3,3.9,2.5,7.9,3.8,12c0.2,0.3,0.2,0.5,0.3,0.8l-0.3,0.8l-2.8-0.3v-0.7c0-0.7-0.2-1.3-0.2-1.3c0-0.3-0.2-0.5-0.2-0.8
	c-3-9.9-6.1-19.2-9-28.1c-1.5-4.6-3.1-9-4.6-13.5c-0.3-1-0.7-2-1-3c-3.9-11-7.9-21.4-12-30.7c-0.3-0.8-0.7-1.6-1.2-2.6
	c-2.1-4.8-4.1-9.2-6.1-13.5l-1.3-2.6c0-0.2-0.5-0.8-0.8-1.6c0.2,0,0.3,0.2,0.7,0.2c1,0.2,2,0.5,3,0.7c0.5,1.1,1.2,2.3,1.6,3.5
	C557.9,158.7,567.9,185.7,578.3,218.7L578.3,218.7z"
          />
          <path
            fill="#FFFFFF"
            d="M560.2,234.3l-4.8,7.7c-2.8,3-6.9,4.1-12,3.6l-65.4-1.1l-1-4.9c-0.2-3.6,0.2-6.6,1-8.7
	c1.1-3.1,3.3-5.1,6.4-5.8c0.8,0.2,1.8,0.3,2.5,0.5c1.8-2.8,4.8-4.3,9-4.4l49.2,1.8c6.9,0.3,11.8,1,15.1,2.1c2.1,1.3,2.6,3.3,1.8,5.9
	C561.7,231.7,561,233,560.2,234.3L560.2,234.3z"
          />
          <path
            fill="#EAEBEB"
            d="M249.8,266.2c-5.4-1.5-8.7,0-9.4,4.9c-0.5,2.1-0.8,4.3-1.1,6.7c-0.5,3.6-0.8,7.4-1.2,11.5l-0.2,3.8l-0.2,1.8
	c-0.3,7.4-0.2,15.6,0.3,24.7c16.9,2.1,34.2,3.1,52,3l-2.5-27.8l-0.3-3.1l-2-21.9V269l-13.6-0.2c-5.3-0.2-10.4-0.7-15.3-1.3
	C254.2,267.1,251.9,266.7,249.8,266.2L249.8,266.2z"
          />
          <path
            fill="#5E5E5E"
            d="M288.4,261c-2.5,1.5-3.5,4.1-3.1,7.7v0.8l2,21.9l108.4,1.1c0.3-0.5,0.5-1,0.8-1.5c0.3-0.7,0.8-1.5,1.1-2.1
	c0.3-0.5,0.8-0.8,1.5-0.8h10.7c0.5,0,0.7-0.2,0.8-0.5v-0.5v-2c0-0.3,0.2-0.7,0.3-0.8c0-0.2,0.2-0.2,0.3-0.3l0.5-0.2h18.4
	c0.3,0,0.5,0,0.7,0.2c0.2,0,0.3,0.2,0.3,0.3c0.2,0.3,0.2,0.7,0.3,1v2.1c0,0.2,0,0.3,0.2,0.5c0.2,0,0.3,0.2,0.7,0.2h16.4
	c0.5,0,0.8,0.2,1,0.5c0,0.2,0.2,0.3,0.2,0.3c0,0.3,0,0.5-0.2,0.8c-0.3,0.3-0.5,0.8-0.7,1.3c-0.3,0.5-0.5,1-0.8,1.5l106.7-1.1l2-21.9
	v-0.7c0.3-3.8-0.7-6.2-3.1-7.7c-1.3-0.8-3.1-1.3-5.4-1.3H293.8C291.5,259.8,289.7,260.2,288.4,261L288.4,261z M297.8,272.2
	c0-1.5,0.3-2.5,1.3-3.3l0.3-0.3c0.8-0.8,2.1-1.1,4.1-1.1L352,268h123.2l63.6-0.5c1.8,0,3.1,0.3,4.1,1.1l0.3,0.3
	c0.8,0.8,1.3,1.8,1.3,3.3l-0.3,3.8l-0.3,3.6c0,1.5-0.3,2.5-1,3.3c-0.2,0.3-0.5,0.5-0.7,0.7c-1,0.8-2.3,1.1-4.3,0.8l-102,1.6
	c-0.2,0-0.5,0-0.7-0.2c-0.2,0-0.3-0.2-0.5-0.3c-0.3-0.3-0.5-0.7-0.5-1.1v-0.7v-0.8c-0.2-1-1-1.5-2.1-1.5h-22.2c-1.3,0-2,0.5-2.1,1.5
	v2c0,0.5-0.2,0.8-0.5,1.1c-0.2,0.2-0.3,0.2-0.5,0.3c-0.2,0-0.3,0.2-0.5,0.2l-101.9-1.6c-1.8,0.3-3.3,0-4.3-0.8
	c-0.3-0.2-0.5-0.3-0.7-0.7c-0.7-0.8-1-2-1-3.3l-0.3-3.6L297.8,272.2L297.8,272.2z M547.9,276.4l1.6-3.8l-0.5,2L547.9,276.4
	L547.9,276.4z M287.8,294.7l2.5,27.8c-17.8,0.2-35-0.8-52-3c-1-0.2-2.1-0.3-3.1-0.3l-3.8-22.2l1.3,27.5c0.2-0.2,0.5-0.3,0.7-0.7
	c0.7-0.5,1.3-0.8,2.1-1.1c0.8-0.7,1.8-1,3-1.2c15.9,2,32.2,3,49,3c1.5,0.2,3,0.8,4.1,2.3c1,1,1.8,2.5,2.5,4.1c3,3,8.1,4.4,15.5,4.3
	c14,0.3,28,0.7,41.8,0.8c18.4,0.3,36.8,0.3,55.1,0.3h29.6c31.4,0,63.1-0.3,95.5-1.1h1.3c3,0,5.6-0.2,7.7-0.5c3.5-0.7,6.1-2,7.7-3.8
	c0.8-1.8,1.6-3.1,2.5-4.1c1.3-1.5,2.6-2.1,4.1-2.3c16.8,0,33-1,49-3c1.2,0,2.1,0.5,3,1.2c0.7,0.3,1.2,0.5,1.6,0.8
	c0.3,0.3,0.8,0.5,1.2,0.8l1.2-27.5v-0.2l-3.8,22.2c-1,0.2-2.1,0.3-3.1,0.3c-16.9,2.1-34.2,3.1-52,3l2.5-27.8l-108,1.1l-0.2,0.2
	c-0.3,0.7-0.8,1.5-1.1,2.1l-1,2.3c-0.2,0.3-0.5,0.5-0.8,0.5c-0.5,0.2-1,0.2-1.5,0.2h-9.4c-0.5,0-0.7,0.2-0.7,0.7v2.1
	c0,0.3,0,0.5-0.2,0.7l-0.5,0.5c-0.3,0.2-0.7,0.2-1,0.2h-17.8c-0.3,0-0.7,0-0.8-0.2c-0.3-0.2-0.5-0.3-0.5-0.5c0-0.3-0.2-0.5-0.2-0.8
	v-2c0-0.3,0-0.7-0.2-0.8c-0.2,0-0.3-0.2-0.8-0.2h-16c-0.3,0-0.7,0-1-0.2s-0.3-0.3-0.5-0.7c-0.2-0.3,0-0.5,0.2-0.8l1.3-2.6
	c0.2-0.3,0.3-0.5,0.3-0.8L287.8,294.7L287.8,294.7z M300.7,305.5c0-2.6,1.8-4.1,5.3-4.6h0.2l100,1.3c0.3,0,0.8,0.2,1,0.5
	c0.2,0.2,0.3,0.3,0.3,0.7v1.6c0,0.8,0.2,1.5,0.7,1.8c0.3,0.3,0.8,0.5,1.6,0.5H432c1.3,0,2-0.5,2.1-1.6v-2c0-0.3,0.2-0.8,0.3-1
	c0.3-0.3,0.7-0.5,1-0.5l100-1.3h0.2c2.6,0.3,4.1,1.1,4.9,2.6c0.3,0.7,0.3,1.3,0.3,2.1v0.2l-0.3,5.3l-0.7,8.5c0,0.8-0.2,1.5-0.3,2.1
	c0,0.3-0.2,0.7-0.3,0.8c-0.3,0.8-0.8,1.6-1.3,2.3c-0.2,0.2-0.3,0.3-0.7,0.5c-1.2,1-2.6,1.5-4.4,1.5h-1.3l-5.3,0.2l-3.9,0.2
	c-2.6,0-5.3,0-7.9,0.2h-2.6c-3,0-6.1,0.2-9,0.2H501c-3.3,0-6.7,0.2-10,0.2h-0.7l-10.9,0.2l-10.9,0.2H367.8l-10.9-0.2h-0.3l-10.5-0.2
	H345c-3.1,0-6.4-0.2-9.5-0.2h-2.1c-2.8,0-5.6-0.2-8.4-0.2h-3.3c-2.5,0-4.8-0.2-7.2-0.2h-4.6c-1.6,0-3-0.2-4.1-0.8
	c-0.3-0.2-0.7-0.5-1-0.7c-0.2-0.2-0.3-0.2-0.3-0.3c-0.5-0.5-1-1.1-1.3-1.8c-0.3-0.8-0.7-1.6-0.8-2.6c0-0.3-0.2-0.5-0.2-0.8v-0.3
	l-1.1-13.8v-0.2L300.7,305.5L300.7,305.5z"
          />
          <path
            fill="#EAEBEB"
            d="M400.9,291.6c-0.5,0-0.8,0.2-1.1,0.7c-0.2,0.2-0.2,0.3-0.3,0.5l-0.5,1c-0.3,0.7-0.7,1.5-1.1,2.1
	c-0.2,0.3-0.3,0.5-0.3,0.8v0.3c0,0.2,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.2,0.5,0.2h15.3c0.3,0,0.7,0.3,0.7,0.7v2.6c0,0.3,0,0.7,0.2,0.7
	l0.7,0.2h13.2c0,0,0.2,0,0.2-0.2c0-0.3,0.2-0.7,0.2-0.8v-2.3l0.5-0.7h12.5c0.3,0,0.5-0.2,0.8-0.3c0.2-0.3,0.5-0.8,0.7-1.1
	c0.5-1,1-1.8,1.5-2.8c0.2-0.2,0.2-0.3,0.3-0.5v-1.2h-15.5c-0.2,0-0.2-0.2-0.2-0.2v-3.1l-0.2-0.5v-0.2c-0.2-0.2-0.3-0.2-0.7-0.2
	h-12.7c-0.3,0-0.5,0-0.7,0.2v3.8l-0.2,0.2c0,0-0.2,0-0.3,0.2h-1.3L400.9,291.6L400.9,291.6z M297.8,272.3l0.3,3.8l0.3,3.6
	c0,1.5,0.3,2.5,1,3.3c0.2,0.3,0.3,0.5,0.7,0.7c1,0.8,2.3,1.1,4.3,0.8l101.9,1.6c0.2,0,0.3,0,0.5-0.2c0.2,0,0.3-0.2,0.5-0.3
	c0.3-0.3,0.5-0.7,0.5-1.1v-0.8V283c0.2-1,1-1.5,2.1-1.5h22.2c1.3,0,2,0.5,2.1,1.5v2.1c0,0.5,0.2,0.8,0.5,1.1
	c0.2,0.2,0.3,0.2,0.5,0.3c0.2,0,0.3,0.2,0.7,0.2l101.9-1.6c1.8,0.3,3.3,0,4.3-0.8c0.3-0.2,0.5-0.3,0.7-0.7c0.7-0.8,1-2,1-3.3
	l0.3-3.6l0.3-3.8c0-1.5-0.3-2.5-1.3-3.3l-0.3-0.3c-0.8-0.8-2.1-1.1-4.1-1.1l-63.6,0.5H351.9l-48.5-0.5c-1.8,0-3.1,0.3-4.1,1.1
	l-0.3,0.3C298.1,269.7,297.8,270.8,297.8,272.3L297.8,272.3z M301.4,279.7l-0.5-7.6c0-0.3,0-0.5,0.2-0.7c0-0.2,0.2-0.3,0.3-0.5
	c0.5-0.3,1.3-0.5,2.3-0.5l46,0.3l123.2,0.2l65.9-0.5c1,0,1.8,0.2,2.3,0.5c0.2,0.2,0.3,0.3,0.3,0.5c0.2,0.8,0,2.1,0,3.1l-0.3,5.1
	c0,0.5,0,1-0.2,1.3c-0.2,0.2-0.2,0.3-0.3,0.5c-0.3,0.3-0.8,0.3-1.5,0.3h-0.7L438,283.5c0-0.5,0-1-0.2-1.5l-0.2-0.3
	c-0.3-1.3-1.1-2.1-2.1-2.6c-0.8-0.3-1.6-0.5-2.8-0.5h-22.2c-1.1,0-2.1,0.2-2.8,0.5h-0.2l-0.2,0.2h-0.2l-0.2,0.2l-0.2,0.2l-0.2,0.2
	l-0.2,0.2l-0.2,0.5c-0.2,0.5-0.2,1-0.2,1.5l-100.5-1.6h-0.7c-0.7,0-1.1,0-1.5-0.3c-0.2-0.2-0.3-0.3-0.3-0.5
	C301.4,280.7,301.2,280.2,301.4,279.7L301.4,279.7z"
          />
          <path
            fill="#EAEBEB"
            d="M306,300.9c-3.5,0.3-5.3,1.8-5.3,4.6v0.5l1.1,13.8v0.3c0,0.3,0.2,0.5,0.2,0.8c0.2,1,0.5,1.8,0.8,2.6
	c0.3,0.7,0.8,1.3,1.3,1.8c0.2,0.2,0.3,0.2,0.3,0.3c0.3,0.3,0.7,0.5,1,0.7c1.1,0.7,2.5,0.8,4.1,0.8h4.6c2.5,0,4.8,0.2,7.2,0.2h3.3
	c2.8,0,5.6,0.2,8.4,0.2h2.1c3.1,0,6.4,0.2,9.5,0.2h1.1l10.5,0.2h0.3l10.9,0.2h100.8l10.9-0.2l10.9-0.2h0.7c3.3,0,6.7-0.2,10-0.2h1.6
	c3,0,6.1-0.2,9-0.2h2.6c2.6,0,5.3-0.2,7.9-0.2l3.9-0.2l5.3-0.2h1.6c1.8,0,3.3-0.5,4.4-1.5c0.2-0.2,0.3-0.3,0.7-0.5
	c0.5-0.7,1-1.3,1.3-2.3c0.2-0.3,0.2-0.7,0.3-0.8c0.2-0.7,0.3-1.5,0.3-2.1l0.7-8.5l0.3-5.3v-0.2c0-0.8,0-1.5-0.3-2.1
	c-0.7-1.5-2.3-2.5-4.9-2.6h-0.2l-100,1.3c-0.3,0-0.8,0.2-1,0.5c-0.3,0.3-0.3,0.7-0.3,1v2c-0.2,1.2-1,1.6-2.1,1.6h-22.2
	c-0.7,0-1.1-0.2-1.6-0.5s-0.7-1-0.7-1.8v-1.3v-0.3c0-0.2-0.2-0.5-0.3-0.7c-0.3-0.3-0.7-0.5-1-0.5l-100-1.3V300.9L306,300.9z
	 M306.3,303.9l98.3,1.3c0,0.5,0,1,0.2,1.5c0,0.5,0.2,0.8,0.3,1.3c0.7,1.5,2,2.3,4.1,2.5h22.9c1.5,0,2.5-0.3,3.5-0.8
	c0.5-0.7,0.8-1.5,1-2.5v-1l98.3-1.3c1,0,1.6,0.3,2,0.7c0.3,0.3,0.5,0.7,0.5,1.1l-0.5,5.6l-0.7,8.2v0.5l-0.2,0.3
	c-0.2,0.3-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1,0.7c-0.5,0.2-1.2,0.3-1.6,0.3h-12.3c-3.3,0-6.7,0.2-10,0.2h-1.8c-3,0-5.9,0.2-8.9,0.2
	h-0.2l-7.9,0.2h-78.6h1.1h-17.1h0.7h-2.8h1.1h-2.6h1h-10.4h2.1h-2.6h1h-2.6h1h-2.6h1h-37.7c-2.8,0-5.6-0.2-8.4-0.2h-2.3
	c-3.1,0-6.2-0.2-9.4-0.2h-1.2l-10.5-0.2h-1.6c-1.6,0.2-3-0.2-3.8-0.8c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.2-0.2-0.3-0.3-0.5
	c-0.3-0.7-0.5-1.6-0.7-2.6l-0.7-8.2l-0.5-5.6C303.9,304.7,304.7,304.1,306.3,303.9L306.3,303.9z"
          />
          <path
            fill="#EAEBEB"
            d="M554.6,291.4l-106.7,1.1c0.3-0.5,0.5-1,0.8-1.5c0.2-0.5,0.3-0.8,0.7-1.3c0.2-0.3,0.3-0.7,0.2-0.8
	c0-0.2,0-0.3-0.2-0.3c-0.2-0.3-0.5-0.5-1-0.5h-16.3c-0.3,0-0.5,0-0.7-0.2c0,0-0.2-0.2-0.2-0.5v-0.3v-1.8c0-0.3-0.2-0.7-0.3-1
	l-0.3-0.3c-0.2-0.2-0.5-0.2-0.7-0.2h-18.4L411,284c-0.2,0-0.3,0.2-0.3,0.3c-0.2,0.3-0.3,0.7-0.3,0.8v2.5c0,0.3-0.3,0.5-0.8,0.5
	h-10.7c-0.7,0-1.1,0.3-1.5,0.8c-0.3,0.7-0.7,1.5-1.1,2.1c-0.3,0.5-0.5,1-0.8,1.5l-108.4-1.2l0.3,3.1l106.4,1.1
	c-0.2,0.3-0.3,0.5-0.3,0.8l-1.3,2.6c-0.2,0.3-0.2,0.5-0.2,0.8s0.2,0.5,0.5,0.7s0.7,0.2,1,0.2h16c0.3,0,0.7,0.2,0.8,0.2
	s0.2,0.3,0.2,0.8v2c0,0.3,0,0.5,0.2,0.8c0,0.2,0.3,0.3,0.5,0.5c0.3,0.2,0.7,0.2,0.8,0.2h17.8c0.3,0,0.7,0,1-0.2l0.5-0.5
	c0-0.2,0.2-0.5,0.2-0.7v-2.1c0-0.5,0.2-0.7,0.7-0.7h9.5c0.5,0,1,0,1.5-0.2c0.3,0,0.7-0.2,0.8-0.5l1-1.8c0.3-0.8,0.8-1.5,1.2-2.1
	l0.2-0.2l108-1.1L554.6,291.4L554.6,291.4z M444.3,292v0.7c0,0.2-0.2,0.3-0.3,0.5c-0.5,0.8-1,1.8-1.5,2.8c-0.2,0.3-0.5,0.8-0.7,1.1
	c-0.2,0.3-0.3,0.5-0.8,0.3h-12.5l-0.5,0.7v2.3c0,0.3,0,0.7-0.2,0.8c0,0-0.2,0-0.2,0.2h-13.2l-0.7-0.2c-0.2-0.2-0.2-0.3-0.2-0.7V299
	v-1c0-0.3-0.3-0.7-0.7-0.7h-15.3c-0.2,0-0.3,0-0.5-0.2c-0.2,0-0.2-0.2-0.3-0.3v-0.3c0.2-0.3,0.3-0.5,0.3-0.8
	c0.3-0.7,0.8-1.5,1.1-2.1l0.5-1c0.2-0.2,0.2-0.3,0.3-0.5c0.3-0.5,0.7-0.7,1.1-0.7h12.5c0.2,0,0.2,0,0.3-0.2l0.2-0.2v-0.3v-2.1v-1.3
	c0.2-0.2,0.3-0.2,0.7-0.2h12.7c0.3,0,0.5,0,0.7,0.2v0.2l0.2,0.5v3.1l0.2,0.2H443v0.2L444.3,292L444.3,292z M251.2,371.6l0.5,8.9
	c0.2,3.8,2.3,5.8,6.1,5.8l22.5,0.2c3.9,0,5.8-2,5.8-5.8l-0.3-9c-0.2-4.1-2.1-6.1-6.1-6.1h-23C252.9,365.7,251.1,367.7,251.2,371.6
	L251.2,371.6z M327.5,394.8v7.1c0,1.3,0.3,2.1,1.3,2.6c0.5,0.3,1,0.3,1.6,0.3h24.7c0.7,0,1.3-0.2,1.8-0.3c0.8-0.5,1.3-1.3,1.3-2.6
	v-7.1c0-2-1-3-3-3h-24.7C328.5,391.7,327.5,392.7,327.5,394.8z M486.7,391.7c-2,0-3,1-3,3v7.1c0,1.3,0.3,2.1,1.3,2.6
	c0.5,0.3,1,0.3,1.6,0.3h24.7c0.7,0,1.3-0.2,1.8-0.3c0.8-0.5,1.3-1.3,1.3-2.6v-7.1c0-2-1-3-3-3H486.7L486.7,391.7z"
          />
          <path
            fill="#5E5E5E"
            d="M562.4,365.7c-3.9,0-6.1,2-6.1,6.1l-0.3,9c-0.2,3.9,1.8,5.9,5.8,5.8l22.7-0.2c3.8,0,5.8-2,6.1-5.8l0.5-8.9
	c0.2-3.9-1.6-5.9-5.4-5.9L562.4,365.7L562.4,365.7z"
          />
          <path
            fill="#EAEBEB"
            d="M591.6,266.2c5.4-1.5,8.7,0,9.4,4.9c0.5,2.1,0.8,4.3,1.2,6.7c0.5,3.6,0.8,7.4,1.2,11.5l0.2,3.8l0.2,1.8
	c0.3,7.4,0.2,15.6-0.3,24.7c-16.9,2.1-34.2,3.1-52,3l2.5-27.8l0.3-3.1l2-21.9V269l13.6-0.2c5.3-0.2,10.4-0.7,15.3-1.3
	C587.3,267.1,589.5,266.7,591.6,266.2L591.6,266.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.1"
            strokeMiterlimit="10"
            d="M337.2,274.3l-5.6,1.8 M303.7,274.3l-3-1
	 M303.9,278.1l-2.6-0.8 M301.2,278.9l2.5-0.8 M303.7,270.5l-2.8,1 M418,322.3l5.6-1.8 M418,322.3l5.6,1.8 M423.6,324.3l-2.5,0.8
	 M423.7,320.5l-5.6-2 M418,318.5l5.6-1.8 M423.7,312.9l-5.6-2 M423.7,316.7l-5.6-2 M418,314.7l5.6-1.8 M426.7,278.1l-5.6-2
	 M420.9,276.1l5.6-1.8 M426.7,274.3l-5.6-2 M420.9,272.3l4.1-1.3 M359.6,274.3l5.6-2 M356.7,312.9l5.6-2 M365.2,272.3l-4.3-1.3
	 M386.1,283.2l-4.3-1.5 M388.7,283.2l4.3-1.5 M393.2,281.9l4.8,1.6 M382,281.9l-3.8,1.3 M362.2,311.1l-5.6-1.8 M356.7,309.3l5.6-2
	 M356.7,320.5l5.6,1.8 M362.2,322.3l-5.6,1.8 M356.7,316.7l5.6,1.8 M362.2,318.5l-5.6,2 M356.7,312.9l5.6,1.8 M362.2,314.9l-5.6,2
	 M362.2,307.3l-5.6-1.8 M359.6,278.1l5.6,1.8 M365.2,279.9l-5.6,2 M359.6,274.3l5.6,1.8 M365.2,276.1l-5.6,2 M359.6,281.8l3,1
	 M399.4,283.5l4.8-1.6 M370.8,281.9l3.6,1.1 M390.2,320.5l5.6,1.8 M390.2,305.5l5.6,1.8 M390.2,312.9l5.6-2 M395.8,311.1l-5.6-1.8
	 M390.2,309.1l5.6-1.8 M392.8,325.1l-2.8-1 M390.2,324.3l5.6-1.8 M390.2,312.9l5.6,1.8 M395.8,314.9l-5.6,2 M390.2,316.7l5.6,1.8
	 M395.8,318.7l-5.6,1.8 M390.2,320.5l-5.6-1.8 M384.6,318.7l-5.6,1.8 M379,320.5l-5.6-2 M373.4,318.5l-5.6,2 M367.8,320.5l-5.6-2
	 M418,318.5l-5.6,2 M412.4,320.5l-5.6-2 M406.8,318.5l-5.6,2 M401.2,320.5l-5.6-1.8 M412.4,324.3l-5.6-1.8 M406.8,322.3l5.6-1.8
	 M395.8,322.3l5.6-1.8 M401.2,320.5l5.6,1.8 M406.8,322.3l-5.6,1.8 M401.2,324.3l2.6,1 M395.8,322.3l5.6,1.8 M401.2,324.3l-2.6,1
	 M412.4,320.5l5.6,1.8 M415,325.1l-2.6-0.8 M412.4,324.3l5.6-1.8 M409.8,325.1l2.6-0.8 M379,320.5l-5.6,1.8 M373.4,322.3l5.6,1.8
	 M379,324.3l-2.5,0.8 M367.8,320.5l5.6,1.8 M370.3,325.1l-2.5-0.8 M367.8,324.3l5.6-1.8 M367.8,324.3l-2.3,0.8 M367.8,324.3
	l-5.6-1.8 M362.2,322.3l5.6-1.8 M384.6,322.3l-5.6-1.8 M390.2,320.5l-5.6,1.8 M390.2,324.3l-2.6,0.8 M384.6,322.3l5.6,1.8
	 M379,324.3l5.6-1.8 M381.6,325.1l-2.6-0.8 M379,312.9l-5.6-1.8 M373.4,318.5l5.6-1.8 M379,316.7l-5.6-2 M373.4,314.9l5.6-1.8
	 M373.4,307.3l5.6,2 M379,309.3l-5.6,1.8 M379,305.5l-5.6,1.8 M390.2,316.7l-5.6-2 M384.6,318.7l5.6-1.8 M384.6,311.1l5.6,2
	 M390.2,312.9l-5.6,1.8 M384.6,307.3l5.6,1.8 M390.2,305.5l-5.6,1.8 M384.6,311.1l-5.6-1.8 M390.2,309.2l-5.6,1.8 M379,316.7
	l5.6,1.8 M379,312.9l5.6-2 M384.6,314.9l-5.6,2 M379,312.9l5.6,1.8 M384.6,307.3l-5.6-1.8 M379,305.5l1.6-0.5 M377.2,304.9l1.6,0.7
	 M379,309.3l5.6-2 M391.3,305l-1.1,0.3 M390.2,305.5l-1.3-0.3 M367.8,309.3l5.6-2 M373.4,307.3l-5.6-1.8 M367.8,305.5l2.1-0.7
	 M367.8,309.3l5.6,1.8 M373.4,311.1l-5.6,1.8 M367.8,309.3l-5.6,1.8 M367.8,316.7l5.6,1.8 M373.4,314.9l-5.6,1.8 M367.8,312.9
	l5.6,1.8 M367.8,312.9l-5.6-2 M367.8,316.7l-5.6-1.8 M362.2,318.5l5.6-1.8 M362.2,314.9l5.6-1.8 M367.8,305.5l-5.6,1.8 M365.7,304.7
	l2.1,0.8 M362.2,307.3l5.6,2 M406.8,318.5l5.6-1.8 M412.6,316.7l-5.6-1.8 M406.8,311.1l2.5-0.8 M406.8,311.1l5.6,2 M412.6,312.9
	l-5.6,1.8 M412.6,316.7l5.6,1.8 M412.6,312.9l5.6-2 M412.6,312.9l5.6,1.8 M418,314.7l-5.6,2 M418,311.1l-2.3-0.8 M418,311.1l2.3-0.8
	 M401.4,312.9l-5.6-2 M395.8,311.1l5.6-1.8 M401.4,309.2l5.6,1.8 M395.8,318.7l5.6-1.8 M401.2,316.7l-5.6-2 M395.8,314.9l5.6-1.8
	 M395.8,307.3l5.6-1.8 M401.4,309.2l-5.6-1.8 M401.4,312.9l5.6-2 M401.2,316.7l5.6,1.8 M406.8,314.9l-5.6,2 M401.4,312.9l5.6,1.8
	 M405.3,307.8l-3.9,1.3 M401.4,305.5l3.5,1.1 M402.2,305.2l-0.8,0.3 M401.4,305.5l-0.8-0.3 M334.3,320.5l-5.6,1.8 M328.7,322.3
	l5.6,1.8 M317.5,318.5l-5.6,2 M328.7,318.5l-5.6,2 M323.1,320.5l-5.6-2 M306.3,318.5l5.6,2 M356.7,320.5l-5.6-2 M334.3,309.3l-5.6-2
	 M328.7,307.3l5.6-1.8 M334.3,316.7l-5.6-1.8 M328.7,314.9l5.6-1.8 M334.3,312.9l-5.6-1.8 M328.7,311.1l5.6-1.8 M339.9,318.5l-5.6,2
	 M334.3,320.5l-5.6-2 M328.7,318.5l5.6-1.8 M351.1,318.5l-5.6,2 M345.5,320.5l-5.6-2 M334.3,305.5l-3.6-1.1 M345.5,320.5l5.6,1.8
	 M347.3,324.8l-1.8-0.7 M345.5,324.3l5.6-1.8 M339.9,322.3l-5.6-1.8 M334.3,324.3l5.6-2 M334.3,324.3l1.3,0.3 M334.3,324.3l-1.1,0.3
	 M343.8,324.8l1.6-0.5 M345.5,324.3l-5.6-2 M339.9,322.3l5.6-1.8 M356.7,324.3l-2,0.7 M351.1,322.3l5.6,1.8 M351.1,322.3l5.6-1.8
	 M356.7,324.3l2.1,0.7 M311.9,320.5l5.6,1.8 M317.5,322.3l-5.4,1.8 M306.3,322.3l5.4,1.8 M311.9,320.5l-5.6,1.8 M306.3,322.3
	l-0.8-0.3 M305.8,322.6l0.7-0.2 M323.1,324.3l-5.6-2 M328.7,322.3l-5.6,1.8 M323.1,320.5l5.6,1.8 M317.5,322.3l5.6-1.8 M323.1,324.3
	l0.7,0.2 M322.6,324.4l0.5-0.2 M317.7,314.9l-5.6,2 M311.9,316.7l5.6,1.8 M317.7,314.9l-5.6-1.8 M312.1,312.9l5.6-2 M317.7,311.1
	l-5.6-1.8 M312.1,309.3l5.6-2 M317.7,307.3l-5.6-1.8 M328.7,311.1l-5.6-1.8 M323.1,309.3l5.6-2 M323.3,312.9l5.6-1.8 M323.3,312.9
	l5.6,1.8 M328.7,314.9l-5.6,1.8 M323.3,305.5l3.6-1.3 M328.7,307.3l-5.6-1.8 M323.1,309.3l-5.6,1.8 M323.1,316.7l5.6,1.8
	 M317.5,318.5l5.6-1.8 M323.3,312.9l-5.6,1.8 M317.7,311.1l5.6,2 M323.1,316.7l-5.6-1.8 M317.7,307.3l5.6,2 M319.2,304.1l4.1,1.3
	 M323.3,305.5l-5.6,1.8 M311.9,305.5l-5.6,1.8 M311.9,305.5l-4.4-1.5 M306.3,314.9l5.6,2 M311.9,316.7l-5.6,1.8 M306.3,311.1l5.6,2
	 M312.1,312.9l-5.6,1.8 M306.3,311.1l-2.3-0.8 M312.1,309.3l-5.6,1.8 M306.3,307.3l5.6,2 M304.4,311.8l2.1-0.8 M306.3,314.9l-2-0.7
	 M304.5,315.6l1.8-0.7 M306.3,318.5l-1.6,0.5 M304.7,318l1.6,0.5 M306.3,307.3l-2.5,0.8 M303.9,306.5l2.6,0.8 M311.9,305.5l4.1-1.3
	 M345.5,312.9l5.6,1.8 M351.1,314.9l-5.6,1.8 M345.5,309.3l5.6,1.8 M351.1,311.1l-5.6,2 M345.5,316.7l5.6,1.8 M348.4,304.5l-3,1
	 M345.5,305.5l5.6,1.8 M351.1,307.3l-5.6,2 M351.1,311.1l5.6,2 M356.7,316.7l-5.6-2 M351.1,314.9l5.6-1.8 M354,304.5l2.6,0.8
	 M356.7,305.5l-5.6,1.8 M351.1,307.3l5.6,2 M488,272.3l-4.3-1.3 M491.2,282.7l2.5-0.8 M493.6,281.9l2.3,0.8 M488,276.1l5.6-1.8
	 M492.5,270.8l-4.4,1.5 M493.6,278.1l-5.6-2 M493.6,274.3l-5.6-2 M493.6,281.9l-5.6-2 M488,279.9l5.6-1.8 M499.2,272.3l4.8-1.6
	 M502.7,282.4l2-0.7 M499.2,276.1l5.6-1.8 M504.8,278.1l-5.6-1.8 M499.2,279.9l5.6-1.8 M504.8,281.7l-5.6-1.8 M504.8,278.1l5.6,1.8
	 M510.4,279.9l-5.6,1.8 M505.6,270.7l4.8,1.6 M510.4,272.3l-5.6,2 M504.8,274.3l-5.6-2 M510.4,276.1l-5.6,2 M504.8,281.7l1.8,0.7
	 M504.8,274.3l5.6,1.8 M499.2,276.1l-5.6,1.8 M493.6,274.3l5.6,1.8 M499.2,272.3l-5.6,2 M494.6,270.8l4.4,1.5 M499.2,279.9l-5.6,2
	 M493.6,278.1l5.6,1.8 M526.8,270.5l-5.4,1.8 M517.1,282.2l-1.2-0.3 M516,281.9l-1.3,0.5 M527.2,281.9l-0.7,0.2 M521.6,279.9l5.6,2
	 M527.7,282l-0.7-0.2 M521.6,279.9l5.6-1.8 M527.2,278.1l-5.6-2 M521.4,276.1l5.6-1.8 M521.4,272.3l-5.1-1.6 M516,281.9l5.6-2
	 M521.4,272.3l5.6,1.8 M515.8,274.3l5.6-2 M521.4,276.1l-5.6-1.8 M515.8,278.1l5.8,1.8 M521.4,276.1l-5.6,2 M541.1,271.5l-3-1
	 M538.7,281.9l-0.3-0.2 M538.3,281.9h-0.2 M538.3,281.9l2.3-0.8 M538.3,278.1l2.6-0.8 M540.8,278.9l-2.5-0.8 M538.3,274.3l2.8,1
	 M532.6,279.9l5.6,2 M538.2,270.5l-5.6,1.8 M532.8,276.1l5.6-1.8 M538.3,278.1l-5.6-1.8 M538.3,274.3l3-1 M532.6,272.3l5.6,2
	 M532.6,279.9l5.6-1.8 M527.2,281.9l5.6-2 M527.2,278.1l5.6,1.8 M532.8,276.1l-5.6,2 M527,274.3l5.6,2 M532.6,272.3l-5.4-1.8
	 M527,274.3l5.6-1.8 M510.4,279.9l5.6,2 M510.4,279.9l5.6-1.8 M510.4,276.1l5.6-1.8 M515.8,278.1l-5.6-2 M515.5,270.7l-5.1,1.6
	 M510.4,272.3l5.6,2 M351.1,318.5l5.6-1.8 M432.1,272.3l4.1-1.3 M426.7,278.1l1.3,0.5 M425.2,278.4l1.3-0.5 M437.7,281.9l-0.5,0.2
	 M437.1,281.5l0.7,0.2 M432.1,272.3l-5.6,2 M428,271l4.1,1.3 M432.1,276.1l-5.6,2 M426.7,274.3l5.6,1.8 M439.2,271l4.1,1.3
	 M437.9,274.3l5.6,1.8 M443.5,276.1l-5.6,2 M437.9,278.1l5.6,1.8 M443.3,272.3l5.6,2 M448.9,274.3l-5.6,1.8 M443.3,272.3l-5.6,1.8
	 M443.3,279.9l5.6-1.8 M448.9,281.7l-5.6-1.8 M447.6,271l-4.1,1.3 M448.9,278.1l-5.6-2 M437.9,274.3l-5.6-1.8 M432.1,276.1l5.6-1.8
	 M437.9,278.1l-5.6-2 M443.3,279.9l-5.6,2 M434.9,279.1l3-1 M465.7,272.3l-4.1-1.3 M454.5,272.3l4.3-1.3 M454.5,279.9l5.6-1.8
	 M460.1,281.7l-5.6-1.8 M460.1,278.1l-5.6-2 M454.5,276.1l5.6-1.8 M460.1,278.1l5.6,1.8 M465.7,279.9l-5.6,1.8 M465.7,272.3l-5.6,2
	 M460.1,274.3l-5.6-2 M465.7,276.1l-5.6,2 M460.1,274.3l5.6,1.8 M481,271l-4.1,1.3 M474.7,282.9l-3.5-1.1 M482.4,278.1l-5.6-1.8
	 M476.8,276.1l5.6-1.8 M476.9,279.9l5.6-1.8 M482.4,281.9l-3.1,1 M476.9,279.9l5.6,2 M471.3,281.7l5.6-1.8 M476.9,272.3l-4.1-1.3
	 M471.3,278.1l5.6,1.8 M471.3,274.3l5.6,1.8 M476.9,276.1l-5.6,1.8 M476.9,272.3l5.6,2 M471.3,274.3l5.6-2 M469.8,271l-4.1,1.3
	 M465.7,279.9l5.6-1.8 M471.3,281.7l-5.6-1.8 M465.7,276.1l5.6-1.8 M471.3,278.1l-5.6-2 M465.7,272.3l5.6,2 M454.5,276.1l-5.6,2
	 M448.9,274.3l5.6,1.8 M454.5,279.9l-5.6,1.8 M448.9,278.1l5.6,1.8 M454.5,272.3l-4.1-1.3 M448.9,274.3l5.6-2 M356.7,309.3l-5.6,1.8
	 M457.1,305.4l-1.3-0.5 M457.1,305.4l1.5-0.5 M457.1,309.3l5.6-2 M462.7,314.8l-5.6,2 M462.7,307.4l-5.6-2 M462.7,311.2l-5.6-1.8
	 M457.1,316.7l5.6,1.8 M457.1,313l5.6-2 M462.7,314.7l-5.6-1.8 M479.5,305.5l-2.1-0.8 M481.9,304.7l-2.5,0.8 M468.3,309.1l5.6-1.8
	 M468.3,305.5l2-0.7 M473.9,307.3l-5.6-1.8 M468.3,312.9l5.6-2 M473.9,314.9l-5.6,1.8 M468.3,316.7l5.6,1.8 M473.9,314.9l-5.6-1.8
	 M473.9,307.3l5.6,1.8 M479.5,309.2l-5.6,1.8 M473.9,311.1l-5.6-1.8 M479.5,305.5l-5.6,1.8 M479.5,312.9l-5.6,1.8 M473.9,311.1
	l5.6,2 M473.9,318.5l5.6-1.8 M479.5,316.7l-5.6-1.8 M468.3,305.5l-5.6,1.8 M466.5,304.9l1.8,0.7 M468.3,312.9l-5.6,1.8 M462.7,311.1
	l5.6,2 M468.3,309.2l-5.6,1.8 M462.7,307.3l5.6,1.8 M462.7,318.5l5.6-1.8 M468.3,316.7l-5.6-2 M356.7,305.5l2.5-0.8 M334.3,305.5
	l5.6,1.8 M339.9,307.3l-5.6,2 M334.3,312.9l5.6,1.8 M423.7,312.9l5.6,1.8 M429.3,314.7l-5.6,2 M429.3,311.1l-5.6,2 M423.7,316.7
	l5.6,1.8 M429.3,311.1l2.3-0.8 M434.9,312.9l-5.6-1.8 M426.9,310.3l2.3,0.8 M434.9,316.7l-5.6-2 M429.3,314.7l5.6-1.8 M429.3,318.5
	l5.6-1.8 M447.1,305l-1,0.3 M445.9,305.5l5.4,1.8 M451.5,307.3l-5.6,1.8 M445.9,312.9l5.6-2 M451.5,314.7l-5.6,2 M445.9,312.9
	l5.6,1.8 M445.9,316.7l5.6,1.8 M440.3,318.5l5.6-1.8 M445.9,309.2l-5.6-1.8 M440.3,307.3l5.6-1.8 M445.9,305.5l-1-0.3 M440.5,314.7
	l5.6-1.8 M445.9,316.7l-5.6-2 M445.9,309.2l-5.6,1.8 M440.3,311.1l5.6,2 M451.5,311.1l-5.6-1.8 M440.3,311.1l-5.6,1.8 M435.4,309.5
	l4.9,1.6 M440.3,307.3l-4.1,1.5 M437.2,306.4l3.1,1 M440.5,314.8l-5.6,2 M434.9,312.9l5.6,1.8 M434.9,316.7l5.6,1.8 M445.9,324.3
	l5.6-2 M451.5,322.3l-5.6-1.8 M445.9,324.3l2.8,1 M440.3,322.3l5.6,2 M445.9,324.3l-2.8,1 M445.9,320.5l-5.6,1.8 M437.5,325.1
	l-2.8-1 M434.7,324.3l-2.6,0.8 M429.3,322.3l-5.6-1.8 M423.6,324.3l5.6-2 M426.2,325.1l-2.6-0.8 M429.3,322.3l5.6,2 M434.7,320.5
	l-5.6,1.8 M440.3,322.3l-5.6-1.8 M434.7,324.3l5.6-2 M477.2,325.1l2.3-0.8 M479.5,324.3l2.1,0.8 M468.3,324.3l2.5,0.8 M473.9,322.3
	l-5.6,2 M473.9,322.3l-5.6-1.8 M479.5,320.5l-5.6,1.8 M473.9,322.3l5.6,2 M457.1,324.3l-2.6,0.8 M457.1,324.3l2.6,0.8 M462.7,322.3
	l-5.6,2 M457.1,320.5l5.6,1.8 M462.7,322.3l5.6-1.8 M468.3,324.3l-5.6-2 M465.8,325.1l2.5-0.8 M451.5,314.7l5.6-1.8 M457.1,316.7
	l-5.6-2 M451.5,318.5l5.6-1.8 M457.1,320.5l-5.6-1.8 M462.7,318.5l-5.6,1.8 M468.3,320.5l-5.6-1.8 M473.9,318.5l-5.6,1.8
	 M479.5,320.5l-5.6-2 M429.3,318.5l-5.6,2 M434.8,320.5l-5.6-2 M440.3,318.5l-5.6,2 M445.9,320.5l-5.6-1.8 M451.5,318.5l-5.6,1.8
	 M451.5,311.1l5.6,2 M457.1,309.3l-5.6,1.8 M451.5,307.3l5.8,2 M457.1,320.5l-5.6,1.8 M451.5,322.3l5.6,2 M451.5,307.3l5.6-2
	 M528.5,304.1l-4.3,1.5 M518.4,314.7l5.6-1.8 M518.6,318.5l5.6-1.8 M524.2,305.5l-3.9-1.3 M524.2,316.7l-5.6-2 M518.4,311.1l5.6,1.8
	 M524.2,305.5l-5.6,1.8 M524,309.2l-5.6,1.8 M518.4,307.3l5.6,1.8 M529.6,307.3l5.6-1.8 M535.2,309.2l-5.6-1.8 M535.2,305.5
	l-4.4-1.5 M529.8,314.9l5.6-1.8 M535.4,316.7l-5.6-1.8 M529.6,311.1l5.6,2 M529.6,318.5l5.6-1.8 M535.2,305.5l3,1 M538,308.3l-2.8,1
	 M535.2,305.5l2.5-0.8 M535.4,312.9l2.3,0.8 M537.5,316.1l-2.1,0.7 M535.4,316.7l2,0.7 M535.4,312.9l2.5-0.8 M535.2,309.2l2.6,0.8
	 M529.6,311.1l5.6-1.8 M524.2,316.7l5.6,1.8 M529.8,314.9l-5.6,2 M524,312.9l5.6,2 M524,312.9l5.6-1.8 M529.6,307.3l-5.6-1.8
	 M524,309.2l5.6-1.8 M529.6,311.1l-5.6-1.8 M490.7,305.5l-2.6-0.8 M490.7,309.2l-5.6-1.8 M485.1,307.3l5.6-1.8 M485.1,318.5l5.6-1.8
	 M490.7,312.9l-5.6-2 M490.7,316.7l-5.6-2 M485.1,314.7l5.6-1.8 M490.7,316.7l5.6,1.8 M493.5,304.6l-3,1 M490.7,305.5l5.6,1.8
	 M496.2,307.3l-5.6,1.8 M496.2,311.1l-5.6,2 M490.7,309.2l5.6,1.8 M485.1,311.1l5.6-1.8 M496.2,314.9l-5.6,1.8 M490.7,312.9l5.6,1.8
	 M512.9,305.5l-3.5-1.1 M507.4,307.3l5.6-1.8 M513,309.2l-5.6-1.8 M501.8,305.5l-3.1-1 M505.1,304.4l-3.3,1.1 M507.4,311.1l5.6,2
	 M512.9,316.7l-5.6-2 M507.4,318.5l5.6-1.8 M512.9,312.9l-5.6,1.8 M507.4,314.7l-5.6-1.8 M501.8,312.9l5.6-2 M501.8,309.2l5.6-1.8
	 M507.4,311.1l-5.6-1.8 M513,309.2l-5.6,1.8 M507.4,314.7l-5.6,2 M501.8,316.7l5.6,1.8 M501.8,305.5l5.6,1.8 M496.3,314.9l5.6-1.8
	 M501.8,316.7l-5.6-1.8 M496.3,318.5l5.6-1.8 M496.3,307.3l5.6-1.8 M501.8,309.2l-5.6-1.8 M496.3,311.1l5.6-1.8 M501.8,312.9l-5.6-2
	 M513,320.5l-5.6,1.8 M507.4,322.3l5.6,1.8 M513,324.3l-1,0.3 M503.2,324.8l-1.3-0.5 M501.8,324.3l-1.5,0.5 M507.4,322.3l-5.6-2
	 M501.8,324.3l5.6-1.8 M490.7,324.3l5.6-2 M492.5,324.9l-1.8-0.7 M488.7,324.9l2-0.7 M490.7,320.5l5.6,1.8 M485.1,322.3l5.6-1.8
	 M490.7,324.3l-5.6-2 M496.2,322.3l5.6,2 M496.2,322.3l5.6-1.8 M529.8,322.3l5.6-1.8 M534.6,324l-4.8-1.6 M535.4,320.5l1.6,0.5
	 M524.2,324.3h0.3 M523.9,324.3l0.3-0.2 M524.2,320.5l-5.6,1.8 M518.6,322.3l5.6,1.8 M529.8,322.3l-5.6-1.8 M524.2,324.3l5.6-2
	 M512.9,305.5l3.8-1.3 M518.5,307.3l-5.6-1.8 M513,309.1l5.6-1.8 M518.5,311.1l-5.6-1.8 M524.2,320.5l-5.6-2 M529.6,318.5l-5.6,2
	 M512.9,312.9l5.6-2 M518.5,314.7l-5.6-1.8 M535.4,320.5l-5.6-2 M537.2,319.8l-1.8,0.7 M490.7,320.5l-5.6-2 M496.3,318.5l-5.6,2
	 M513,320.5l-5.6-2 M518.6,318.5l-5.6,2 M512.9,316.7l5.8,1.8 M518.5,314.7l-5.6,2 M501.8,320.5l-5.6-1.8 M507.4,318.5l-5.6,1.8
	 M518.6,322.3l-5.6-1.8 M513,324.3l5.6-1.8 M513.8,324.6l-0.8-0.3 M488,279.9l-5.6,2 M482.4,278.1l5.6,1.8 M488,276.1l-5.6,2
	 M482.4,274.3l5.6,1.8 M485.1,318.5l-5.6,2 M479.5,316.7l5.6,1.8 M485.1,314.7l-5.6,2 M479.5,312.9l5.6,1.8 M448.9,281.7l-4.9,1.6
	 M442.7,283.5l-4.9-1.6 M453.3,283.2l-4.4-1.5 M467.5,283l3.6-1.3 M485.2,282.7l-2.8-1 M460.1,281.7l3.9,1.3 M455.8,283.2l4.3-1.5
	 M485.1,307.3l-5.6-1.8 M479.5,312.9l5.6-2 M479.5,309.2l5.6-1.8 M485.1,311.1l-5.6-1.8 M479.5,324.3l5.6-2 M485.1,322.3l-5.6-1.8
	 M482.4,274.3l5.6-2 M372.1,271l4.3,1.5 M365.2,272.3l4.1-1.3 M376.4,279.9l-5.6,2 M370.8,278.1l5.6,1.8 M376.4,276.1l-5.6,1.8
	 M370.8,274.3l5.6,1.8 M370.8,281.9l-5.6-2 M365.2,279.9l5.6-1.8 M376.4,272.3l-5.6,1.8 M370.8,274.3l-5.6-2 M365.2,276.1l5.6-1.8
	 M370.8,278.1l-5.6-1.8 M367.5,282.8l3.3-1.1 M391.7,271l-4.1,1.3 M387.6,272.3l-4.1-1.3 M387.6,279.9l-5.6,1.8 M382,278.1l5.6,1.8
	 M387.6,276.1l-5.6,2 M382,274.3l5.6,1.8 M382,274.3l5.6-2 M376.4,279.9l5.6-1.8 M382,281.9l-5.6-2 M376.4,276.1l5.6-1.8 M382,278.1
	l-5.6-2 M376.4,272.3l4.3-1.5 M382,274.3l-5.6-1.8 M398.7,272.3l-4.1-1.3 M398.7,279.9l-5.6,1.8 M393.2,274.3l5.6,1.8 M398.7,276.1
	l-5.6,2 M393.2,278.1l5.6,1.8 M404.2,281.9l0.7,0.2 M404.2,278.1l3,1 M405,281.5l-0.8,0.3 M402.9,271l-4.1,1.3 M398.7,279.9l5.6-1.8
	 M404.2,281.9l-5.6-1.8 M404.3,274.3l-5.6,1.8 M398.7,272.3l5.6,2 M393.2,274.3l5.6-2 M404.2,278.1l-5.6-2 M420.9,272.3l-4.1-1.3
	 M414,271l-4.1,1.3 M415.5,278.1l1.3,0.5 M420.9,276.1l-5.6,2 M415.5,274.3l5.6,1.8 M415.5,278.1l-5.6-1.8 M409.8,276.1l5.6-1.8
	 M415.5,274.3l5.6-2 M409.8,272.3l5.6,2 M414,278.4l1.3-0.5 M409.8,276.1l-5.6,2 M404.3,274.3l5.6,1.8 M409.8,272.3l-4.1-1.3
	 M404.3,274.3l5.6-2 M393.2,274.3l-5.6,1.8 M387.6,272.3l5.6,2 M393.2,278.1l-5.6-2 M387.6,279.9l5.6-1.8 M393.2,281.9l-5.6-1.8
	 M314.9,270.5l-5.6,1.8 M304,281.9h-0.2 M303.9,281.9l-0.3,0.2 M301.6,281l2.3,0.8 M314.9,281.9l-0.7,0.2 M309.3,279.9l5.6,2
	 M314.9,281.9l0.7,0.2 M309.3,279.9l5.6-1.8 M314.9,278.1l-5.6-2 M309.3,276.1l5.6-1.8 M309.3,272.3l-5.6-1.8 M303.9,281.9l5.6-2
	 M309.3,272.3l5.6,2 M303.7,274.3l5.6-2 M309.3,276.1l-5.6-1.8 M303.9,278.1l5.6,1.8 M309.3,276.1l-5.6,2 M331.7,272.3l-5.3-1.6
	 M326.1,278.1l5.6,1.8 M326.1,281.9l5.6-2 M326.2,274.3l5.6,1.8 M331.7,276.1l-5.6,1.8 M326.1,281.9l1.3,0.5 M324.9,282.2l1.1-0.3
	 M326.1,278.1l-5.6-1.8 M320.5,276.1l5.6-1.8 M320.5,279.9l5.6-1.8 M326.1,281.9l-5.6-2 M325.7,270.7l-5.1,1.6 M320.5,272.3l5.6,2
	 M326.2,274.3l5.6-1.8 M320.5,279.9l-5.6,2 M314.9,278.1l5.6,1.8 M320.5,276.1l-5.6,2 M315,274.3l5.6,1.8 M320.5,272.3l-5.4-1.8
	 M315,274.3l5.6-2 M339.2,282.4l-2-0.7 M337.2,281.9l-1.6,0.7 M337.2,278.1l5.6,1.8 M337.2,274.3l5.6,1.8 M342.8,276.1l-5.6,1.8
	 M337.2,281.9l5.6-2 M342.8,272.3l-5.6,2 M337.9,270.8l4.8,1.6 M349.4,270.8l4.6,1.5 M348.4,274.3l5.6,1.8 M354,276.1l-5.6,1.8
	 M354,279.9l-5.6,2 M348.4,278.1l5.6,1.8 M348.4,281.9l2.5,0.8 M356.8,282.7l2.8-1 M354,272.3l-5.6,2 M358.3,271l-4.3,1.5
	 M354,276.1l5.6-1.8 M359.6,278.1l-5.6-2 M354,279.9l5.6-1.8 M359.6,281.9l-5.6-2 M354,272.3l5.6,2 M348.4,281.9l-2.3,0.8
	 M342.8,279.9l5.6,2 M342.8,272.3l4.6-1.5 M348.4,274.3l-5.6-2 M342.8,279.9l5.6-1.8 M342.8,276.1l5.6-1.8 M348.4,278.1l-5.6-1.8
	 M336.6,270.7l-4.9,1.6 M331.7,279.9l5.6,2 M339.9,314.9l-5.6,1.8 M331.7,272.3l5.6,1.8 M337.2,278.1l-5.6-1.8 M331.7,279.9l5.6-1.8
	 M345.5,305.6l-3.1-1 M339.9,307.4l5.6-1.8 M345.5,309.3l-5.6-2 M337.6,304.4l-3.3,1.1 M339.9,314.9l5.6-1.8 M345.5,316.7l-5.6-1.8
	 M345.5,313l-5.6-2 M339.9,318.5l5.6-1.8 M339.9,311.1l5.6-1.8 M334.3,316.7l5.6,1.8 M334.3,309.3l5.6,1.8 M339.9,311.1l-5.6,2
	 M301.1,275.3l2.8-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M446.3,295.8c0.5-1,1-1.8,1.5-2.8c0-0.2,0.2-0.3,0.2-0.5 M394.1,295.8
	c-0.2,0.3-0.3,0.5-0.3,0.8c-0.5,0.8-0.8,1.6-1.3,2.6c-0.2,0.3-0.2,0.5-0.2,0.8s0.2,0.5,0.5,0.7s0.7,0.2,1,0.2h16
	c0.3,0,0.7,0.2,0.8,0.2c0.2,0,0.2,0.3,0.2,0.8v2c0,0.3,0,0.5,0.2,0.8c0,0.2,0.3,0.3,0.5,0.5c0.3,0.2,0.7,0.2,0.8,0.2H430
	c0.3,0,0.7,0,1-0.2l0.5-0.5c0-0.2,0.2-0.5,0.2-0.7v-0.8v-1.3c0-0.5,0.2-0.7,0.7-0.7h9.5c0.5,0,1,0,1.5-0.2c0.3,0,0.7-0.2,0.8-0.5
	c0.3-0.7,0.7-1.3,1-1.8c0.3-0.8,0.8-1.5,1.1-2.1l0.2-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M443.1,291.6h-14.5c-0.2,0-0.2-0.2-0.2-0.2v-0.7v-2v-0.5l-0.2-0.5v-0.2
	c-0.2-0.2-0.3-0.2-0.7-0.2H415c-0.3,0-0.5,0-0.7,0.2v3.8l-0.2,0.2c0,0-0.2,0-0.3,0.2h-12.5c-0.5,0-0.8,0.2-1.2,0.7
	c-0.2,0.2-0.2,0.3-0.3,0.5l-0.5,1c-0.3,0.7-0.7,1.5-1.1,2.1c-0.2,0.3-0.3,0.5-0.3,0.8v0.3c0,0.2,0.2,0.2,0.3,0.3
	c0.2,0.2,0.3,0.2,0.5,0.2H414c0.3,0,0.7,0.3,0.7,0.7v2.6c0,0.3,0,0.7,0.2,0.7l0.7,0.2h13.1c0,0,0.2,0,0.2-0.2c0-0.3,0.2-0.7,0.2-0.8
	v-2.3l0.5-0.7H442c0.3,0,0.5-0.2,0.8-0.5c0.2-0.3,0.5-0.8,0.7-1.1c0.5-1,1-1.8,1.5-2.8c0.2-0.2,0.2-0.3,0.3-0.5v-1.2
	C443.8,291.6,443.5,291.6,443.1,291.6L443.1,291.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M447.9,292.7c0.3-0.5,0.5-1,0.8-1.5c0.2-0.5,0.3-0.8,0.7-1.3
	c0.2-0.3,0.3-0.7,0.2-0.8c0-0.2,0-0.3-0.2-0.3c-0.2-0.3-0.5-0.5-1-0.5H432c-0.3,0-0.5,0-0.7-0.2c0,0-0.2-0.2-0.2-0.5v-0.3v-1.8
	c0-0.3-0.2-0.7-0.3-1l-0.3-0.3c-0.2-0.2-0.5-0.2-0.7-0.2h-18.4c0,0-0.3,0-0.5,0.2c-0.2,0-0.3,0.2-0.3,0.3c-0.2,0.3-0.3,0.7-0.3,0.8
	v2.5c0,0.3-0.3,0.5-0.8,0.5h-10.7c0,0-1.1,0.3-1.5,0.8c-0.3,0.7-0.7,1.5-1.1,2.1c-0.3,0.5-0.5,1-0.8,1.5 M458.6,305l8.1-0.2h3.8
	l6.9-0.2h4.6l6.1-0.2h21.5l7.4-0.2h3.5l8.2-0.2h7.2c1,0,1.6,0.3,2,0.7c0.3,0.3,0.5,0.7,0.5,1.2v0.7l-0.2,1.8l-0.2,1.8l-0.2,2.1
	l-0.2,1.6l-0.2,2.3l-0.2,1.3l-0.2,2.1v0.5c0,0.3-0.2,0.8-0.2,1.1c-0.3,1-0.7,1.8-1.3,2.3c-0.3,0.3-0.7,0.5-1,0.7
	c-0.5,0.2-1.2,0.3-1.6,0.3h-1.3c-2.3,0-4.8,0.2-7.1,0.2H524c-3.3,0-6.7,0.2-10,0.2h-1.8c-3,0-5.9,0.2-8.9,0.2h-3
	c-2.6,0-5.3,0-7.9,0.2H343.5c-2.8,0-5.6-0.2-8.4-0.2h-2.3c-3.1,0-6.2-0.2-9.4-0.2h-1.1c-3.5,0-7.1-0.2-10.5-0.2h-1.6
	c-1.6,0.2-3-0.2-3.8-0.8c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.2-0.2-0.3-0.3-0.5c-0.3-0.7-0.5-1.6-0.7-2.6v-1.5l-0.2-2.6v-1.3l-0.2-2.5
	l-0.2-1.5l-0.2-2.1l-0.2-1.6v-0.7c0.2-1.1,1-1.6,2.6-1.8h1.2l8.7,0.2h3l7.7,0.2h3.8l6.9,0.2h43.1l8.2,0.2h2.5l9.2,0.2h4.3
	c0,0.5,0,1,0.2,1.5c0,0.5,0.2,0.8,0.3,1.3c0.7,1.5,2,2.3,4.1,2.5h22.9c1.5,0,2.5-0.3,3.5-0.8c0.3-0.2,0.5-0.3,0.8-0.7
	c0.5-0.7,0.8-1.5,1-2.5v-1l7.7-0.2h2l8.7-0.2L458.6,305L458.6,305z M357.8,224.8c3.5-0.5,7.7-0.5,12.7-0.3
	c5.4-0.2,10.9-0.5,16.1-0.7c3-0.2,5.8-0.2,8.5-0.3l25.8-0.3l25.8,0.3c3,0,5.8,0.2,8.5,0.3c5.4,0.2,10.9,0.3,16.1,0.7
	c4.9-0.2,9.2-0.2,12.7,0.3 M611,346.5c-1,17.4-3.5,35.5-7.4,54.4 M545.9,400.7l3.8-58"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M560.1,418v40.5c0,7.4,3.8,11.2,11.2,11.2h28.4c7.4,0,11.2-3.8,11.2-11.2
	v-112 M603.6,400.7h-57.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M603.6,400.7l-6.4,13.2c-11.5,1.5-23.3,3-35.7,3.9c-0.5,0-1,0.2-1.5,0.2
	 M585.4,365.7h-23c-3.9,0-6.1,2-6.1,6.1l-0.3,9c-0.2,3.9,1.8,5.9,5.8,5.8l22.7-0.2c3.8,0,5.8-2,6.1-5.8l0.5-8.9
	C591.1,367.7,589.2,365.7,585.4,365.7L585.4,365.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M588.3,363.1h-28.8c-4.9,0-7.6,2.5-7.7,7.6l-0.3,11.2
	c-0.2,4.9,2.3,7.4,7.1,7.2l28.3-0.2c4.8,0,7.2-2.5,7.6-7.4l0.7-11.2C595.4,365.5,593.1,363.1,588.3,363.1L588.3,363.1z M554.6,291.4
	l2-21.9 M595.1,237.5l18.1-2.5 M609.5,324.4l1.3-27.5 M611,346.5c0.2-3.5,0.3-7.1,0.5-10.5 M609.5,326.1v-1.6 M611.4,336v-3.3
	c-0.5-2.6-1.2-4.8-2-6.4 M609.5,324.4c-0.3-0.3-0.7-0.7-1.2-0.8c-0.5-0.3-1-0.7-1.6-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M606.8,322.6c0.7,0.5,1.2,1,1.6,1.8c0.3,0.5,0.7,1.1,1,1.8 M549.7,342.7
	c30.6,0.2,51-2,61.3-6.4c0.2,0,0.3-0.2,0.5-0.2 M610.9,297l-3.8,22.2c-1,0.2-2.1,0.3-3.1,0.3 M604,319.5c-16.9,2.1-34.2,3.1-52,3
	l2.5-27.8 M606.8,322.6c-0.8-0.7-1.8-1-3-1.2c-16,2-32.2,3-49,3c-1.5,0.2-3,0.8-4.1,2.3 M550.7,326.9l-1,15.8 M554.3,294.7l0.3-3.3
	 M601.3,289.6c-13.6,1.3-27.8,2-42.1,1.8c-0.5,0-1,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5
	c14.5,0.2,28.4-0.5,42.3-1.8 M604.1,294.8c0.3,7.4,0.2,15.6-0.3,24.7 M604,293v-3.8 M604,293l0.2,1.8 M601.5,293.2
	c0.8,0,1.6-0.2,2.6-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M558.7,272l-4.1,47.7c16,0.2,31.6-0.8,46.7-2.6c0.3-8.1,0.5-15.5,0.2-22.2
	v-1.5 M604.1,294.8V293 M604,289.3C603.8,289.3,603.8,289.3,604,289.3c-1,0-1.8,0.2-2.6,0.3 M572.4,231l9.4,1.3 M572.4,231l-18.8,30
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M570.2,268.7c5.3-0.2,10.4-0.7,15.3-1.3c2.1-0.3,4.4-0.7,6.6-1.1
	c5.4-1.5,8.7,0,9.4,4.9c0.5,2.1,0.8,4.3,1.2,6.7c0.5,3.6,0.8,7.4,1.2,11.5 M570.2,268.7c1.2-4.8,2.3-9.2,3.5-13.3
	c2.6-9.5,5.3-17.1,7.9-23.2 M553.6,261c2.5,1.5,3.5,4.1,3.1,7.7 M556.6,268.9l13.7-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601.3,289.6c-0.2-3.6-0.5-7.1-1-10c-0.3-2.1-0.7-4.1-1-6.1
	c-0.7-4.3-3.5-5.8-8.4-4.4l-5.9,1c-4.4,0.7-9,1-13.8,1.1l-12.3,0.2 M556.6,269.5v-0.8 M556.6,268.9v0.7 M560.1,224.8
	c-3.3-1.1-8.2-2-15.1-2.1l-49.2-1.8c-4.3,0.3-7.2,1.8-9,4.4l-2.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M484.2,224.8c-3.1,0.7-5.3,2.6-6.4,5.8c-0.8,2.3-1.1,5.3-1,8.7l1,4.9
	l65.4,1.1c5.3,0.5,9.2-0.8,12-3.6l4.8-7.7c0.8-1.3,1.5-2.5,1.8-3.6c0.8-2.6,0.2-4.6-1.8-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M560.1,224.8c1.5,0.5,2.5,1.1,3.3,1.8c1.5,2,3.8,3.3,7.1,4.1
	c0.7,0.2,1.3,0.3,2,0.3 M595.1,237.5l-7.9-17.9 M589.5,218.5h-2.6 M592.9,239.3c-0.2-0.7-0.3-1.3-0.5-1.8c-0.7-2.3-1.3-4.6-2-6.7
	c-1.2-3.8-2.3-7.6-3.3-11.2 M593.1,240.1c0-0.2-0.2-0.3-0.2-0.5 M592.9,239.6c0,0.5,0.2,1,0.5,1.6 M593.4,241.2
	c-0.2-0.3-0.2-0.8-0.3-1.1 M593.1,240.1c0-0.3-0.2-0.5-0.2-0.8 M592.9,239.3c0.2-0.8,0.8-1.3,2.1-1.8 M578.1,218.7
	c1.3,3.9,2.5,7.9,3.8,12c0,0.3,0.2,0.5,0.2,0.8c-0.2,0.3-0.3,0.5-0.3,0.8 M587.2,219.5c-0.2-0.3-0.2-0.7-0.3-0.8v-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M578.1,218.7l4.8,2.6c1.6,2,3.6,5.1,5.9,9.4c1,2,2.1,4.3,3.3,6.7
	c0.3,0.7,0.7,1.3,1,2.1 M610.9,297l0.3-1.8l-1.6-19.9c-0.2-2.5-0.5-4.6-1-6.9c-0.7-2.8-1.5-5.3-2.6-7.7c-0.8-1.8-1.8-3.6-3-5.3
	c-0.2-0.3-0.3-0.5-0.5-0.8s-0.5-0.5-0.7-0.8c-0.5-0.8-1.2-1.5-1.6-2.1c-0.2-0.3-0.5-0.5-0.7-0.8c-1.3-1.5-2.8-2.8-4.3-4.1
	c-0.5-2-1-3.8-1.6-5.6 M395.6,292.7c-0.2,0.3-0.5,0.8-0.7,1.3c-0.3,0.7-0.7,1.3-1,1.8 M292.4,342.7l3.8,58 M231,346.5
	c1,17.4,3.5,35.5,7.4,54.4 M296.1,400.7h-57.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231,346.5v112c0,7.4,3.8,11.2,11.2,11.2h28.4c7.6,0,11.2-3.8,11.2-11.2
	V418 M282,418c3,0.3,5.9,0.5,8.9,0.7c2.6,0.2,5.4,0.3,8.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M238.4,400.7l6.4,13.2c7.1,1,14.5,1.8,21.9,2.6c4.6,0.5,9.2,1,13.8,1.3
	c0.5,0,1,0.2,1.5,0.2 M286.1,380.8c0.2,3.9-1.8,5.9-5.8,5.8l-22.5-0.2c-3.8,0-5.9-2-6.1-5.8l-0.5-8.9c-0.2-3.9,1.6-5.9,5.6-5.9h23
	c3.9,0,6.1,2,6.1,6.1L286.1,380.8L286.1,380.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M290.2,370.6l0.3,11.2c0.2,4.9-2.3,7.4-7.1,7.2l-28.3-0.2
	c-4.8,0-7.2-2.5-7.6-7.4l-0.7-11.2c-0.3-4.9,2-7.4,6.9-7.4h28.8C287.4,363.1,290.1,365.7,290.2,370.6z M285.5,269.5l2,21.9
	 M228.9,235l18.1,2.5 M287.8,294.7l2.5,27.8c-17.8,0.2-35-0.8-52-3 M550.7,326.9c-1,1-1.8,2.5-2.5,4.1c-1.8,1.8-4.3,3.1-7.7,3.8
	c-2.1,0.5-4.8,0.7-7.7,0.5h-1.3c-32.2,0.8-64.1,1.2-95.5,1.2h-29.6c-18.2,0-36.5,0-55.1-0.3c-13.8-0.2-27.8-0.5-41.8-0.8
	c-7.4,0.2-12.7-1.3-15.5-4.3c-0.8-1.8-1.6-3.1-2.5-4.1 M291.4,326.9l1,15.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.7,305.9v-0.3c0-2.6,1.8-4.1,5.3-4.6h0.2l100,1.3c0.3,0,0.8,0.2,1,0.5
	c0.2,0.2,0.3,0.3,0.3,0.7v1.6c0,0.8,0.2,1.5,0.7,1.8c0.3,0.3,0.8,0.5,1.6,0.5H432c1.3,0,2-0.5,2.1-1.6v-0.3v-1.6
	c0-0.3,0.2-0.8,0.3-1c0.3-0.3,0.7-0.5,1-0.5l100-1.3h0.2c2.6,0.3,4.1,1.1,4.9,2.6c0.3,0.7,0.3,1.3,0.3,2.1v0.2l-0.3,5.3l-0.7,8.5
	c0,0.8-0.2,1.5-0.3,2.1c0,0.3-0.2,0.7-0.3,0.8c-0.3,0.8-0.8,1.6-1.3,2.3c-0.2,0.2-0.3,0.3-0.7,0.5c-1.2,1-2.6,1.5-4.4,1.5h-1.6
	c-1.8,0-3.6,0-5.3,0.2c-1.3,0-2.6,0-3.9,0.2c-2.6,0-5.3,0-7.9,0.2h-2.6c-3,0-6.1,0.2-9,0.2h-1.6c-3.3,0-6.7,0.2-10,0.2H490
	c-3.6,0-7.2,0-10.9,0.2c-3.6,0-7.2,0-10.9,0.2h-44.6h-56.2c-3.6,0-7.2,0-10.9-0.2h-0.3c-3.5,0-7.1,0-10.5-0.2h-1.1
	c-3.1,0-6.4-0.2-9.5-0.2H333c-2.8,0-5.6-0.2-8.4-0.2h-3.3c-2.5,0-4.8-0.2-7.2-0.2h-4.6c-1.6,0-3-0.2-4.1-0.8c-0.3-0.2-0.7-0.5-1-0.7
	c-0.2-0.2-0.3-0.2-0.3-0.3c-0.5-0.5-1-1.1-1.3-1.8c-0.3-0.8-0.7-1.6-0.8-2.6c0-0.3-0.2-0.5-0.2-0.8v-0.3L300.7,305.9L300.7,305.9z
	 M240.7,289.6c13.6,1.3,27.8,2,42.1,1.8c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	c-14.5,0.2-28.4-0.5-42.3-1.8 M287.4,291.4l0.3,3.3 M231.2,297l1.3,27.5 M230.5,336c0,2.3,0.2,4.8,0.3,7.1c0,1.1,0,2.3,0.2,3.3
	 M232.5,326.1c-0.8,1.6-1.5,3.8-2,6.4v3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292.4,342.7c-28.6,0.2-48.3-1.6-59.2-5.6c-0.8-0.3-1.5-0.5-2.1-0.8
	c-0.2,0-0.3-0.2-0.5-0.2 M232.5,324.4v1.6 M235.3,322.6c-0.8,0.3-1.5,0.7-2.1,1.1c-0.3,0.2-0.5,0.3-0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M232.5,326.1c0.2-0.3,0.3-0.8,0.7-1.2c0.7-1,1.3-1.8,2.1-2.3 M291.4,326.9
	c-1.3-1.5-2.6-2.1-4.1-2.3c-16.8,0-33.1-1-49-3c-1.2,0-2,0.5-3,1.1 M238.1,319.5c-0.5-9-0.5-17.3-0.3-24.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231.2,297l3.8,22.2c1,0.2,2.1,0.3,3.1,0.3 M238.1,289.3l-0.2,3.8
	 M240.6,293.2v1.5c-0.3,6.6-0.2,14,0.2,22.2c15.1,2,30.8,2.8,46.7,2.6l-4.1-47.7 M237.9,293c0.8,0.2,1.6,0.2,2.6,0.3 M237.9,294.8
	V293 M237.9,293c0,0.7-0.2,1.3-0.2,1.8 M240.7,289.6c-0.8-0.2-1.6-0.2-2.5-0.3H238 M260.3,232.4c3.8,8.6,7.6,20.7,11.5,36.3
	 M288.4,261l-18.7-30 M285.5,268.9c-0.3-3.8,0.7-6.2,3.1-7.7 M297.8,272.2c0-1.5,0.3-2.5,1.3-3.3l0.3-0.3c0.8-0.8,2.1-1.1,4.1-1.1
	L352,268h123.2l63.6-0.5c1.8,0,3.1,0.3,4.1,1.1l0.3,0.3c0.8,0.8,1.3,1.8,1.3,3.3l-0.3,3.8l-0.3,3.6c0,1.5-0.3,2.5-1,3.3
	c-0.2,0.3-0.5,0.5-0.7,0.7c-1,0.8-2.3,1.1-4.3,0.8l-102,1.6c-0.2,0-0.5,0-0.7-0.2c-0.2,0-0.3-0.2-0.5-0.3c-0.3-0.3-0.5-0.7-0.5-1.1
	v-0.7v-0.8c-0.2-1-1-1.5-2.1-1.5h-22.2c-1.3,0-2,0.5-2.1,1.5v2c0,0.5-0.2,0.8-0.5,1.1c-0.2,0.2-0.3,0.2-0.5,0.3
	c-0.2,0-0.3,0.2-0.5,0.2l-101.9-1.6c-1.8,0.3-3.3,0-4.3-0.8c-0.3-0.2-0.5-0.3-0.7-0.7c-0.7-0.8-1-2-1-3.3l-0.3-3.6L297.8,272.2
	L297.8,272.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.9,271.5c0,0.2-0.2,0.3-0.2,0.7v1.1l0.2,2l0.2,2l0.2,1.6v0.8
	c0,0.5,0,1,0.2,1.3c0.2,0.2,0.2,0.3,0.3,0.5c0.3,0.3,0.8,0.3,1.5,0.3h0.7l10.2,0.2h1.3l9.4,0.2h2.5l8.2,0.2h3.6l7.1,0.2h4.8l5.9,0.2
	h10.7l6.9,0.2h3.8l8.1,0.2h2.6l9.2,0.2h6.7c0-0.5,0-1,0.2-1.5c0-0.2,0-0.3,0.2-0.5c0.3-1.1,1.1-2,2.1-2.5c0.8-0.3,1.6-0.5,2.8-0.5
	h22c1.1,0,2,0.2,2.8,0.5c1.1,0.5,1.8,1.3,2.1,2.6c0,0.2,0,0.3,0.2,0.3c0.2,0.5,0.2,1,0.2,1.5h6.7l9.4-0.2h2.5l8.2-0.2h3.5l7.1-0.2
	h4.6l5.9-0.2l5.8-0.2h4.8l6.9-0.2h3.8l8.2-0.2h2.5l9.4-0.2h1.3l10.2-0.2h0.7c0.7,0,1.2,0,1.5-0.3c0.2-0.2,0.3-0.3,0.3-0.5
	c0.2-0.3,0.2-0.8,0.2-1.3v-0.8l0.2-1.6l0.2-2l0.2-2v-1v-0.7c0-0.2-0.2-0.3-0.3-0.5c-0.5-0.3-1.3-0.5-2.3-0.5h-0.3l-10.9,0.2H527
	l-10.4,0.2h-23.8l-8.7,0.2H325.6l-10.7-0.2h-0.2l-11-0.2h-0.3c-1,0-1.8,0.2-2.3,0.5C301.1,271.2,301.1,271.3,300.9,271.5
	L300.9,271.5z M285.5,268.9v0.8 M271.8,268.7l13.6,0.2 M285.5,269.5v-0.7 M553.6,261c-1.3-0.8-3.1-1.3-5.4-1.3H293.8
	c-2.1,0-3.9,0.5-5.4,1.3 M248.6,241.2c-0.5,1.8-1,3.8-1.6,5.6c-1.6,1.3-3,2.6-4.3,4.1c-6.1,6.7-9.5,15-10,24.3l-1.6,19.9l0.3,1.8
	 M238.1,289.3c0.3-4.1,0.7-7.9,1.1-11.5c0.3-2.3,0.7-4.6,1.2-6.7c0.8-4.8,3.9-6.4,9.4-4.9c2.1,0.5,4.3,0.8,6.6,1.1
	c4.9,0.7,10,1.1,15.3,1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.3,271.3l-12.3-0.2c-4.8-0.2-9.4-0.5-13.8-1.1c-2-0.3-3.9-0.5-5.9-1
	c-4.9-1.3-7.7,0-8.4,4.4c-0.5,2-0.8,3.9-1,6.1c-0.5,3.1-0.8,6.4-1,10 M254.5,220.4l-7.6,17.1 M269.7,231l-9.4,1.3 M260.3,232.4
	c-0.2-0.3-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8c1.3-4.1,2.5-8.1,3.8-12 M247,237.5c1,0.3,1.5,0.8,1.8,1.3c0.2,0.2,0.2,0.3,0.2,0.5
	 M249.1,239.3c-0.2,0.3-0.2,0.5-0.2,0.8 M248.9,240.1c-0.2,0.3-0.2,0.7-0.3,1.1 M249.1,239.6c0,0.5-0.2,1-0.5,1.6 M248.9,240.1
	c0-0.2,0.2-0.3,0.2-0.5 M249.1,239.3c0.5-1.6,0.8-3.3,1.3-4.8c0.3-1.3,0.7-2.5,1.1-3.8c1-3.6,2-7.1,3-10.4 M255.2,218.5h-2.8
	 M254.7,220.4l0.5-1.6v-0.2 M254.5,220.4h0.2 M263.9,218.7l-4.8,2.6c-1.6,2-3.6,5.1-5.9,9.4c-0.7,1.1-1.3,2.5-1.8,3.8
	c-0.8,1.6-1.5,3.3-2.3,5.1 M282,224.8c3.3-1.1,8.2-2,15.1-2.1l49.2-1.8c4.3,0.3,7.2,1.8,9,4.4l2.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M282,224.8c-2.1,1.3-2.6,3.3-1.8,5.9c0.3,1.1,1,2.3,1.8,3.6l4.8,7.7
	c2.8,3,6.9,4.1,12,3.6l65.4-1.1l1-4.9c0.2-3.6-0.2-6.6-1-8.7c-1.1-3.1-3.3-5.1-6.4-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M269.7,231c0.7-0.2,1.3-0.2,2.1-0.3c3.1-0.8,5.4-2.1,6.9-4.1
	c0.7-0.7,1.8-1.3,3.3-1.8 M296,134.4c-0.5,1.1-1.1,2.3-1.6,3.5c-10,21-20.1,48-30.4,80.9 M291.2,151.3c2.1-4.8,4.1-9.2,6.1-13.5
	c0.5-1.1,1.1-2.3,1.6-3.5 M290.1,153.9c0.3-0.8,0.7-1.6,1.1-2.6 M252.4,218.5H245c0.2-1.3,0.2-2.5,0.2-3.5c0-7.9-3.3-12.8-9.7-14.5
	c-0.7-0.2-1.5-0.3-2.3-0.5H233c-6.2-0.5-11.7-0.5-16.3,0c-9.5,1.3-15.9,4.8-19.6,10.9c0,0.2-0.2,0.2-0.2,0.3
	c-3.3,8.4-2.8,14.6,1.5,18.9c0.3,0.2,0.7,0.3,1.1,0.5c3.8,1.8,9.4,3,17.1,3.8c3.6,0.3,7.7,0.5,12.2,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M296,134.4c-0.3,0-0.7,0.2-1,0.3c-0.7,0.3-1.3,0.7-2,1
	c-1,0.7-1.8,1.3-2.6,2.1c-1.5,1.3-2.8,2.8-3.8,4.6c-8.9,12.2-18.6,35.5-29.3,69.9c-0.7,2.1-1.3,4.1-2,6.2 M291.2,151.3
	c11.3-4.3,54.6-6.7,129.9-7.6c75.3,0.8,118.6,3.3,129.9,7.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M552,153.9c-12-4.4-55.7-7.1-131.1-7.9c-75.1,0.8-118.9,3.5-130.9,7.9
	 M550.8,151.3c-2.6-6.1-5.1-11.7-7.7-16.9 M613.2,235c14.1-0.2,23.8-1.6,29.3-4.3c0.3-0.2,0.7-0.3,1-0.5c4.3-4.3,4.8-10.5,1.5-18.9
	c-5.1-9.2-17.1-12.8-36-11.2c-0.8,0.2-1.6,0.3-2.5,0.5c-0.3,0.2-0.8,0.2-1.2,0.3c-1.5,0.5-2.6,1.1-3.8,2c-3.8,3-5.4,8.2-4.6,15.6
	h-7.6 M552,153.9c-0.3-0.8-0.8-1.6-1.2-2.6 M586.9,218.5c-1-3.5-2.1-6.7-3.1-10c-10.2-32.4-19.6-54.4-28.1-66.1
	c-1.8-2.6-3.9-4.9-6.4-6.6c-0.7-0.3-1.3-0.8-2-1c-0.3-0.2-0.7-0.2-1-0.3 M546.1,134.4c-1-0.3-2-0.5-2.8-0.7
	c-1.3-0.3-2.8-0.7-4.1-0.8c-1.2-0.2-2.1-0.3-3.5-0.5c-3-0.3-6.4-0.8-10.2-1.1 M525.9,132.9v-1.1 M366.7,417.5c0.3-0.2,0.7-0.5,1-0.7
	v-22.7v-0.8c-0.3-5.3-2.6-8.1-7.1-8.2h-34.4c-6.7-1-10.2,2.1-10.5,9.4v20.9 M355.5,366.4l-1-8.9c-0.5-5.1,1.6-7.7,6.6-7.6h119.7
	c4.9,0,7.1,2.5,6.6,7.6l-1,8.9c0,0.8-0.2,1.5-0.5,2.1c-0.5,1.6-1.3,3-2.6,3.8c-1.3,0.8-2.8,1.3-4.8,1.3H363.1c-2,0-3.5-0.5-4.8-1.3
	s-2.1-2.1-2.6-3.8C355.8,367.9,355.7,367.2,355.5,366.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M538.2,397.1c-0.2-0.3-0.2-0.5-0.2-0.8v-0.2l-1.3-11.3
	c0-3.8-5.9-5.6-17.9-5.3H323.4c-12-0.3-18.1,1.5-17.9,5.3l-1.3,11.3v0.2c0,0.3,0,0.7-0.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M328.9,404.5c-0.8-0.5-1.3-1.3-1.3-2.6v-7.1c0-2,1-3,3-3h24.7c2,0,3,1,3,3
	v7.1c0,1.3-0.3,2.1-1.3,2.6c-0.5,0.3-1,0.3-1.8,0.3h-24.7C329.8,404.8,329.2,404.7,328.9,404.5L328.9,404.5z M315.5,415.4l-13.2,4.1
	 M328.4,420.8c-4.3-0.5-7.6-1.5-9.9-3c-1.3-0.8-2.3-1.6-3-2.6 M302.4,419.5c8.5,0.5,17.1,1,26,1.3 M351.2,421.6
	c1.1-0.2,2.3-0.3,3.3-0.5c0.2,0,0.5,0,0.7-0.2c5.4-1,9.2-2.1,11.5-3.5 M351.2,421.6h10.9c20.4,1,40,1.2,59,0.7
	c8.4,0.2,16.9,0.3,25.7,0.2c3.5,0,7.1,0,10.5-0.2c7.6-0.2,15.1-0.5,22.9-0.8c3.6,0,7.2-0.2,10.9-0.2 M328.4,420.8
	c5.6,0.2,11.3,0.5,17.3,0.7c1.8,0,3.8,0,5.6,0.2 M486.7,391.7c-2,0-3,1-3,3v7.1c0,1.3,0.3,2.1,1.3,2.6c0.5,0.3,1,0.3,1.6,0.3h24.7
	c0.7,0,1.3-0.2,1.8-0.3c0.8-0.5,1.3-1.3,1.3-2.6v-7.1c0-2-1-3-3-3H486.7L486.7,391.7z M513.7,420.8c2.5-0.3,4.6-0.8,6.6-1.3
	c3-1,5.1-2.3,6.2-4.1 M539.5,419.5C539.7,419.5,539.7,419.5,539.5,419.5h0.7c1,0,2-0.2,3-0.2 M513.7,420.8
	c8.7-0.3,17.4-0.8,25.8-1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M526.5,415.4v-20.9c-0.3-7.2-3.8-10.4-10.5-9.4h-34.5
	c-4.4,0.2-6.7,3-7.1,8.2v23.5c0.3,0.3,0.7,0.5,1,0.7 M539.5,419.5l-13-4.1 M543.1,419.3l-4.9-22.2 M490.8,421.6
	c7.7-0.2,15.3-0.3,22.9-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M490.8,421.6c-4.4-0.5-8.1-1.3-10.9-2.1c-2-0.5-3.5-1.1-4.6-2
	 M525.9,131.9v-0.3c0-0.2-0.2-0.2-0.2-0.3 M525.9,132.9l0.2-0.3v-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M525.7,131.2c-0.2-0.3-0.3-0.5-0.5-0.8c-0.5-0.7-1.3-1-2.1-1l-1.8-0.2
	c-0.8,0-1.5,0.2-2.1,0.8l-0.5,0.5 M518.1,132.9c0,0.7,0.3,1.1,0.8,1.6c0.5,0.7,1.3,1,2,1l1.8,0.2c0.8,0,1.5-0.2,2.1-0.8
	c0.5-0.5,0.8-1.1,1-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M518.8,130.6l-0.5,1v0.3 M518.1,131.9l-0.2,0.5v0.5 M518.1,131.9v0.3
	L518,133 M323.9,132.9c0,0.7-0.3,1.1-0.8,1.6c-0.5,0.7-1.3,1-2.1,1l-1.8,0.2c-0.8,0-1.5-0.2-2.1-0.8c-0.5-0.5-0.8-1.1-1-2
	 M323.9,131.9v1 M323.3,130.6c0.2,0.3,0.3,0.7,0.5,1v0.3 M323.9,131.9v0.3l0.2,0.8 M475.4,417.5H366.7 M323.3,130.6l-0.5-0.5
	c-0.7-0.5-1.3-0.8-2.1-0.8l-1.8,0.2c-0.8,0-1.5,0.3-2.1,1c-0.2,0.2-0.3,0.5-0.5,0.8 M543.1,419.3c5.8-0.3,11.3-0.8,16.9-1.3
	 M554.6,291.4l-106.7,1.3 M446.3,295.8l108-1.2 M545.9,400.7c-4.4-0.2-7.1-1.3-7.7-3.6 M298.9,419.3c1.1,0,2.3,0.2,3.5,0.2
	 M579.1,230.7c-9-30.1-18.1-55.7-27-76.8 M546.1,134.4c10.5,21.4,21.2,49.5,32.1,84.2 M290.1,153.9c-8.9,21-17.9,46.7-27.1,76.8
	 M549.7,342.7H292.4 M395.6,292.7l-108.2-1.3 M287.8,294.7l106.4,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.4,131.2H316c-5.3,0.5-9.7,1-13.3,1.6c-1.3,0.2-2.6,0.5-4.1,0.8
	c-1,0.2-2,0.5-3,0.7 M316.2,132.9l-0.2-0.3v-0.8 M316.2,131.9v1 M303.9,397.1c-0.8,2.3-3.3,3.5-7.7,3.6 M298.9,419.3l4.9-22.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.2,131.9v-0.3c0-0.2,0.2-0.2,0.2-0.3 M518.8,130.6h-0.3
	c-20.6-1.6-50.5-2.6-89.9-3.1h-15c-4.8,0-9.5,0.2-14.1,0.2c-15,0.2-28.6,0.5-40.5,1c-13.8,0.5-25.7,1.1-35.4,1.8h-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M285.5,268.9l5.3,57.4c0,0,2,1.6,3.3,4.9
	c0,0,3,4.1,13,4.3c0,0,41.9,1.1,76.6,1.1h81.1c0,0,40.1-0.3,70.5-1.1c0,0,9,0.2,13.2-4.3c0,0,1.6-3.8,3.5-5.1l0.3-4.8l4.6-51.5
	c0,0,0.8-4.6-1.5-7.2c0,0-2.3-3.5-9.5-2.6H296.8c0,0-5.3-0.7-8.2,1.3C288.4,261,285,262.6,285.5,268.9L285.5,268.9z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M260.3,232.4l9.4-1.3
		c0,0,6.2-0.5,9-4.4c0,0,2.5-3.8,19.7-3.9l47.9-1.8c0,0,6.2,0,9,4.4c0,0,5.1-1.5,15.1-0.8c0,0,28-1.2,50.6-1.2
		c0,0,16.3-0.2,50.6,1.2c0,0,8.7-0.7,15.1,0.8c0,0,2.3-4.4,9-4.4l50,2c0,0,14,0.3,17.6,3.9c0,0,2.5,3.6,9,4.4l9.4,1.3
		c0,0-5.6,12.5-11.5,36.3l-13.6,0.2c0,0,1-5.6-3.1-7.9c0,0-2.5-1.6-8.1-1.2h-69.2h-55.1H355h-58c0,0-6.1-0.8-9,1.8
		c0,0-2.8,2-2.3,7.2l-13.6-0.2C271.8,268.7,266.9,247.3,260.3,232.4z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M262.4,232l7.2-1
		c0,0,6.1-0.7,9-4.4c0,0,2.1-3.1,15.3-3.8l51.5-2c0,0,6.9-0.5,9.7,4.4c0,0,5.4-1.3,15.1-0.8c0,0,29.9-1.2,47.7-1.2h5.1
		c0,0,31.6,0,48,1.2c0,0,8.9-0.7,15.5,0.8c0,0,2.5-4.4,9.5-4.4l50.3,2c0,0,12.7,0.3,16.6,3.9c0,0,2.5,3.6,9,4.4l7.2,1l-0.5-1.3
		c0,0-10.5-37.3-27-76.8c0,0-6.4-3.5-49.3-5.8c0,0-28.1-1.6-81.6-2.1c0,0-21,0.2-53.9,1c0,0-15.6,0.5-43.1,2c0,0-25,1.5-33.9,4.8
		c0,0-5.4,12.8-13.5,35.2C276.6,189.1,269,210.3,262.4,232z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M290.2,322.5
		l-4.9-53.8c0,0-10.9-0.2-13.6-0.2c0,0-11.2-0.2-21.9-2.5c0,0-4.9-1.6-7.2,0.7c0,0-1.5,1.2-2,3.5c0,0-1.8,8.2-2.3,18.9l-0.3,3.8
		c0,0-0.3,8.4-0.3,13s0,7.2,0.3,13.5C238.1,319.5,259.1,322.5,290.2,322.5z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M552,322.5
		c0,0,12.2,0.2,26.1-0.7c0,0,10.9-0.7,25.8-2.3c0,0,1-12,0.2-26.5c0,0-0.5-13.3-2.6-22.7c0,0-0.5-2.8-2.8-3.9c0,0-1.8-1.3-6.6,0
		c0,0-9.2,2-21.9,2.5l-13.6,0.2l-2,24.2L552,322.5z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M244.8,413.9l-6.4-13.2
		c0,0-6.9-31.1-7.7-64.8v-3.3c0,0,1.3-10.4,7.6-11c0,0,22.7,3.1,49.3,3.1c0,0,3.9,0,6.4,6.4c0,0,3.3,4.3,13,4.3l42.1,0.8l37,0.3
		h35.2h39l35.4-0.5l37.2-0.7c0,0,11.2,0.5,15.8-4.3c0,0,2.1-5.9,6.7-6.6c0,0,15.1,0,29.6-1.1c0,0,9.2-0.7,19.4-2
		c0,0,3.1-0.3,5.8,4.8c0,0,1.5,2.8,2,6.2c0,0-0.2,12-1.5,26.8c0,0-2.3,24-6.2,41.4l-6.4,13.2c0,0-13.8,2-37.2,4.1
		c0,0-23.2,2.3-54.1,3.1c0,0-62.3,2.3-84.8,1.3c0,0-21.4,0.8-60.2-0.7c0,0-23.7,0-62-2.5C298.9,419.3,261.6,416.5,244.8,413.9z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M285,269.5l5.3,57.4
		c0,0,2,1.6,3.3,4.9c0,0,3,4.1,13,4.3c0,0,41.9,1.2,76.6,1.2h37.7h43.4c0,0,40.1-0.3,70.5-1.2c0,0,9,0.2,13.2-4.3
		c0,0,1.6-3.8,3.5-5.1l0.3-4.8l4.6-51.5c0,0,0.8-4.6-1.5-7.2c0,0-2.3-3.5-9.5-2.6h-35.7h-51.8h-37h-35h-47.7h-41.8
		c0,0-5.3-0.7-8.2,1.3C287.9,261.6,284.5,263.3,285,269.5z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(CrewCabPickup4DrFront);
