import React, { Component } from 'react';
import { Table, Card } from 'semantic-ui-react';
import { getConfig } from '../../utils/helpers';
import DeviceTime from '../common/DeviceTimeComponent';

export default class HyperlaunchUnlisted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      sellerInventory: [],
    };
  }

  fetchSellerInventory() {
    const url =
      `${getConfig('apiRoot')}myaccount?list=Inventory` +
      `&apiKey=${this.props.cookies.get('apiKey')}` +
      `&mpId=${getConfig('marketplaceId')}`;

    fetch(url, { mode: 'cors' })
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (response.ok) {
          this.setState({ sellerInventory: json.data, isLoading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.fetchSellerInventory();
  }

  renderIDColumnHeader() {
    if (getConfig('localization') === 'en-uk') {
      return 'REG';
    } else {
      return 'VIN';
    }
  }

  renderIDColumn(vehicle) {
    if (getConfig('localization') === 'en-uk') {
      return <Table.Cell>{vehicle.stockNumber}</Table.Cell>;
    } else {
      return <Table.Cell>{vehicle.vin}</Table.Cell>;
    }
  }

  render() {
    if (this.state.sellerInventory.length < 1) {
      return (
        <Card fluid>
          <Card.Content>
            <Card.Header>Hyperlaunch Unlisted</Card.Header>
            <Card.Description>
              No Unlisted Hyperlaunch Vehicles Available
            </Card.Description>
          </Card.Content>
        </Card>
      );
    } else {
      return (
        <Card fluid>
          <Card.Content>
            <Card.Header>Hyperlaunch Unlisted</Card.Header>
            <Card.Description>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Image</Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.renderIDColumnHeader()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Year</Table.HeaderCell>
                    <Table.HeaderCell>Make</Table.HeaderCell>
                    <Table.HeaderCell>Model</Table.HeaderCell>
                    <Table.HeaderCell>Mileage</Table.HeaderCell>
                    <Table.HeaderCell>Seller Name</Table.HeaderCell>
                    <Table.HeaderCell>Reserve</Table.HeaderCell>
                    <Table.HeaderCell>Buy Now Price</Table.HeaderCell>
                    <Table.HeaderCell>Date Added</Table.HeaderCell>
                    <Table.HeaderCell>Days In Marketplace</Table.HeaderCell>
                    <Table.HeaderCell>Times to Rerun</Table.HeaderCell>
                    <Table.HeaderCell>Event</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {this.state.isLoading === true && 'Loading Data'}
                {this.state.sellerInventory.length > 0 &&
                  this.state.sellerInventory
                    .sort((a, b) => {
                      return new Date(a.dateCreated) - new Date(b.dateCreated);
                    })
                    .map((vehicle, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <img
                            className="table-image"
                            src={vehicle.mainImage}
                            onError={e => {
                              e.target.src =
                                '..images/image_placeholder_small.png';
                            }}
                            role="presentation"
                            alt="hyper"
                          />
                        </Table.Cell>
                        <Table.Cell>{this.renderIDColumn(vehicle)}</Table.Cell>
                        <Table.Cell>{vehicle.vehicleYear}</Table.Cell>
                        <Table.Cell>{vehicle.make}</Table.Cell>
                        <Table.Cell>{vehicle.model}</Table.Cell>
                        <Table.Cell>{vehicle.mileage}</Table.Cell>
                        <Table.Cell>{vehicle.sellerName}</Table.Cell>
                        <Table.Cell>{vehicle.reservePrice}</Table.Cell>
                        <Table.Cell>{vehicle.outrightPrice}</Table.Cell>
                        <Table.Cell>
                          <DeviceTime
                            sourceTime={vehicle.dateCreated}
                            format="LLL z"
                          />
                        </Table.Cell>
                        <Table.Cell>daysInMarketplace</Table.Cell>
                        <Table.Cell>{vehicle.rerunRequest}</Table.Cell>
                        <Table.Cell>{vehicle.eventName}</Table.Cell>
                      </Table.Row>
                    ))}
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      );
    }
  }
}
