import { FETCH_API } from '../../middleware';

export const AUTH_SELLER_EVENTS_REQUEST = 'AUTH_SELLER_EVENTS_REQUEST';
export const AUTH_SELLER_EVENTS_SUCCESS = 'AUTH_SELLER_EVENTS_SUCCESS';
export const AUTH_SELLER_EVENTS_FAILURE = 'AUTH_SELLER_EVENTS_FAILURE';

const fetchAuthorizedSellerEvents = ({accountId}) => {
  return {
    [FETCH_API]: {
      types: [AUTH_SELLER_EVENTS_REQUEST, AUTH_SELLER_EVENTS_SUCCESS, AUTH_SELLER_EVENTS_FAILURE],
      endpoint: `seller/${accountId}/events?apiKey=`,
      method: 'GET',
    },
  };
};

export const loadAuthorizedSellerEvents = (accountId) => {
  return dispatch => {
    return dispatch(fetchAuthorizedSellerEvents({accountId}));
  };
};