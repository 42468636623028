import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { find, isEmpty } from 'lodash';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import Accounts from './Accounts';
import Alerts from './Alerts';
import Impersonate from './Impersonate';
import IASConnectModal from '../IASConnectModal';
import DeviceTime from '../common/DeviceTimeComponent';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadUpdateUserProfile,
  loadARIAddressValidation,
  loadMarketplaces,
  loadCarfaxLoginCheck,
  resetCarfaxCode,
} from '../../actions';
import { getFips, formatPhoneNumber, getConfig } from '../../utils/helpers';
import { mp3rdPartyIntegratorButtonsMap } from '../../utils/constants';
import carfaxImg from '../../assets/images/carfax.svg';

const mp3rdPartyIntegratorButtonsFallback = '2,3';
const fields = [
  {
    field: 'firstName',
    label: 'First Name',
    required: true,
    edit: true,
  },
  {
    field: 'lastName',
    label: 'Last Name',
    required: true,
    edit: true,
  },
  {
    field: 'address1',
    label: 'Address Line 1',
    required: true,
    edit: true,
    upperCase: true,
  },
  {
    field: 'address2',
    label: 'Address Line 2',
    required: false,
    edit: true,
    upperCase: true,
  },
  {
    field: 'city',
    label: 'City',
    required: true,
    edit: true,
    upperCase: true,
    hide: getConfig('enableAddressValidation'),
  },
  {
    field: 'state',
    label: 'State',
    required: true,
    edit: true,
    upperCase: true,
    hide: getConfig('enableAddressValidation'),
  },
  {
    field: 'county',
    label: 'County',
    required: true,
    edit: true,
    upperCase: true,
    hide: getConfig('enableAddressValidation'),
  },
  {
    field: 'zipcode',
    label: 'ZIP Code',
    required: true,
    edit: true,
  },
  {
    field: 'country',
    label: 'Country',
    required: true,
    edit: !getConfig('enableAddressValidation'),
    upperCase: true,
  },
  {
    field: 'phone',
    label: 'Phone',
    required: false,
    edit: true,
  },
  {
    field: 'aaRepId',
    label: 'Auction ACCESS Rep #',
    required: false,
    edit: true,
    hide: getConfig('hideAACredsUpdate'),
  },
  {
    field: 'aaGovId',
    label: 'Last 4 of Government ID',
    required: false,
    edit: true,
    hide: getConfig('hideAACredsUpdate'),
  },
].filter(field => !field.hide);

class Profile extends Component {
  state = {
    tabTitle: '',
    tabAlerts: '',
    tabAccounts: '',
    tabProfile: '',
    tabThirdParty: '',
    tabImpersonate: '',

    editMode: false,
    hasChanged: false,
    validating: false,
    locations: [],
    locationIndex: 0,
    mp3rdParties: null,
    iasConnectModalOpen: false,
    iasConnectUrl: '',

    // also the profile fields will be populated here
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadTabs();
    this.loadsProfileData();
    this.loadThirdPartyData();
    if (this.props.carfaxCode) {
      this.handleCarfaxLoginReturn(); // when returning from carfax
      this.props.resetCarfaxCode();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.loadsProfileData();
    }

    if (this.props.marketplaces && !this.state.mp3rdParties) {
      this.loadThirdPartyData();
    }

    if (
      (!prevState.tabThirdParty && this.state.tabThirdParty) ||
      (!isEmpty(this.props.carfaxLoginCheck) &&
        this.props.carfaxLoginCheck.isLoggedIn !==
          prevProps.carfaxLoginCheck.isLoggedIn)
    ) {
      this.props.loadCarfaxLoginCheck(getConfig('marketplaceId'));
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadTabs();
    }
  }

  loadTabs() {
    const tabId = (this.props.location.pathname || '').split('/').reverse()[0];
    let tabTitle = '';
    let tabProfile = '';
    let tabAccounts = '';
    let tabAlerts = '';
    let tabThirdParty = '';
    let tabImpersonate = '';
    switch (tabId) {
      case 'profile': {
        tabTitle = 'Profile';
        tabProfile = 'active';
        break;
      }
      case 'accounts': {
        tabTitle = 'Accounts';
        tabAccounts = 'active';
        break;
      }
      case 'alerts': {
        tabTitle = 'Alerts';
        tabAlerts = 'active';
        break;
      }
      case 'thirdparty': {
        tabTitle = 'Third Party';
        tabThirdParty = 'active';
        break;
      }
      case 'impersonate': {
        tabTitle = 'Impersonate';
        tabImpersonate = 'active';
        break;
      }
      default: {
        tabTitle = 'Profile';
        tabProfile = 'active';
      }
    }

    this.setState({
      tabTitle,
      tabProfile,
      tabAccounts,
      tabAlerts,
      tabThirdParty,
      tabImpersonate,
    });
  }

  loadsProfileData() {
    const { user } = this.props.userProfile;
    if (!user) return null;

    // gathers values from user profile, puts in state
    const stateFields = fields.reduce((obj, field) => {
      const key = field.field;
      const value = user[key] || '';
      const label = field.upperCase
        ? value.toUpperCase()
        : field.field === 'phone'
        ? formatPhoneNumber(value)
        : user[field.field];

      return { ...obj, [key]: label };
    }, {});

    const currentLocation = {
      postalCode: user.zipcode,
      state: user.state,
      city: user.city,
      county: user.county,
      fipsCountyCode: user.countyCode,
    };

    this.setState({
      ...stateFields,
      locations: [currentLocation],
      locationIndex: 0,
      editMode: false,
      hasChanged: false,
      validating: false,
    });
  }

  loadThirdPartyData = () => {
    const mpId = getConfig('marketplaceId');

    if (this.props.marketplaces.marketplaceList) {
      const marketplace = find(
        this.props.marketplaces.marketplaceList,
        mp => mp.marketplaceId == mpId
      );

      if (marketplace) {
        const mp3rdParties = (
          marketplace.mp3rdPartyIntegratorButtons &&
          marketplace.mp3rdPartyIntegratorButtons !== '0'
            ? marketplace.mp3rdPartyIntegratorButtons
            : mp3rdPartyIntegratorButtonsFallback
        )
          .split(',')
          .map(key => {
            const val = mp3rdPartyIntegratorButtonsMap.get(key.trim());
            if (!val && key !== '0') {
              console.error(
                `mp3rdPartyIntegratorButtons does not contain key ${key}`
              );
            }
            return val;
          });

        this.setState({
          mp3rdParties,
        });
      }
    }
  };

  loadValidLocations(zipcode) {
    this.props
      .loadARIAddressValidation(zipcode)
      .then(({ response }) => {
        // ignore the response if changes have been cancelled (validating reset to false)
        if (this.state.validating) {
          if (response) {
            this.setState({
              locations: ['Select from the list...', ...response],
              validating: false,
            });
          } else {
            // restore
            toastr.error('Error validating location');
            this.setState(prevState => ({
              locations: prevState.locations,
              zipcode: this.props.userProfile.user.zipcode,
              validating: false,
            }));
          }
        }
      })
      .catch(error => {
        if (this.state.validating) {
          // restore
          toastr.error('Error validating location');
          this.setState(prevState => ({
            locations: prevState.locations,
            zipcode: this.props.userProfile.user.zipcode,
            validating: false,
          }));
        }
        console.error(error);
      });
  }

  handleProfileUpdate = () => {
    const { userProfile } = this.props;

    // gather all the potentially changed fields from state
    const stateFields = fields.reduce(
      (obj, field) => ({ ...obj, [field.field]: this.state[field.field] }),
      {}
    );

    // check for required fields
    const missing = fields
      .filter(field => field.required && stateFields[field.field] === '')
      .map(field => field.label);

    if (missing.length) {
      confirm(`Please complete the following: ${missing.join(', ')}`, {
        hideCancel: true,
      });
      return;
    }

    // the body that gets sent with the api POST
    const updatedUserProfile = {
      user: { ...userProfile.user, ...stateFields },
    };

    this.handleUpdateUserProfile(userProfile, updatedUserProfile);
  };

  handleUpdateUserProfile = (userProfile, updatedUserProfile) => {
    if (getConfig('enableAddressValidation')) {
      const location = this.state.locations[this.state.locationIndex];

      // check that the user has selected a valid location
      const isPlaceholer = typeof location === 'string';
      if (isPlaceholer) {
        confirm(`Please select a Location from the list`, { hideCancel: true });
        return;
      }

      // pull the address components off of the location object that came from the validate-address api
      updatedUserProfile.user.zipcode = location.postalCode;
      updatedUserProfile.user.city = location.city;
      updatedUserProfile.user.state = location.state;
      updatedUserProfile.user.county = location.county;
      updatedUserProfile.user.countyCode = location.fipsCountyCode;
    } else {
      // looks up county FIPS code or else uses '00000'
      if (
        updatedUserProfile.user.county !== userProfile.user.county ||
        updatedUserProfile.user.state !== userProfile.user.state
      ) {
        const { statefp, countyfp } = getFips({
          county: updatedUserProfile.user.county,
          state: updatedUserProfile.user.state,
        });

        updatedUserProfile.user.countyCode =
          statefp && countyfp ? statefp + countyfp : '00000';
      }
    }

    updatedUserProfile.keyCloakUrl = getConfig('keycloakUrl');

    confirm('Confirm changes?').then(
      () => {
        this.props
          .loadUpdateUserProfile(getConfig('marketplaceId'), updatedUserProfile)
          .then(({ response }) => {
            if (
              response.wsStatus === 'Success' &&
              response.user.marketplaceId > 0
            ) {
              toastr.success(response.wsStatus, 'Profile updated');
            } else {
              toastr.error('Error', response.wsMessage);
            }
          })
          .catch(error => {
            toastr.error('Error', error.message);
            console.error(error);
          });
      },
      () => null
    );
  };

  handleInputChange = (event, field, user) => {
    if (!this.state.editMode || !field.edit) {
      return null;
    }

    let value = event.target.value;
    if (field.upperCase) value = value.toUpperCase();
    if (field.field === 'phone') value = formatPhoneNumber(value);
    if (field.field === 'zipcode') {
      value = value.replace(/[^\d-]/g, '').slice(0, 5); // 5 numeric digits
    }

    // when zipcode has been fully typed call ari validation api to populate the location dropdown
    if (
      getConfig('enableAddressValidation') &&
      field.field === 'zipcode' &&
      value.length === 5
    ) {
      this.setState(
        {
          [field.field]: value,
          hasChanged: true,
          validating: true,
        },
        () => this.loadValidLocations(value)
      );
    } else {
      this.setState({
        [field.field]: value,
        hasChanged: true,
      });
    }
  };

  handleCarfaxClick = () => {
    const { isLoggedIn, loginUrl } = this.props.carfaxLoginCheck;

    if (isLoggedIn) {
      this.handleCarfaxLogout();
    } else {
      this.handleCarfaxLogin(loginUrl);
    }
  };

  handleCarfaxLogin = loginUrl => {
    const carfaxRedirectPath = window.location.pathname;
    const carfaxLoginUrl = loginUrl + window.location.origin;
    localStorage.setItem('carfaxRedirectPath', carfaxRedirectPath);
    window.open(carfaxLoginUrl, '_self');
  };

  handleCarfaxLogout = () => {
    const apiRoot = getConfig('apiRoot');
    const apiKey = this.props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');

    const url =
      `${apiRoot}carfax/v2/logout` +
      `?apiKey=${apiKey}` +
      `&mpId=${mpId}` +
      `&deviceId=54321`;

    axios({
      url,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 204) {
          window.open(`https://auth.carfax.com/v2/logout`, '_blank');
          this.props.loadCarfaxLoginCheck(mpId);
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'logout'));
  };

  handleCarfaxLoginReturn = () => {
    const apiRoot = getConfig('apiRoot');
    const apiKey = this.props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');
    const { carfaxCode } = this.props;
    const redirectUri = window.location.origin;

    const url =
      `${apiRoot}carfax/v2/login` +
      `?apiKey=${apiKey}` +
      `&mpId=${mpId}` +
      `&code=${carfaxCode}` +
      `&redirect_uri=${redirectUri}` +
      `&deviceId=54321`;

    axios({
      url,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // no response on success
        if (response.status === 204) {
          this.props.loadCarfaxLoginCheck(mpId);
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'login'));
  };

  handleCarfaxError = (err, message) => {
    // the errs from awg are not very helpful
    toastr.error(
      `There was a problem with CARFAX \n ${message && '(' + message + ')'}`
    );
    console.error({ err, message });
  };

  renderSubheader(user) {
    return (
      <div className="col-xs-12 no-padding">
        <div
          className="list-group profile-details"
          style={{ cursor: 'default' }}
        >
          <div className="list-group-item clearfix">
            <span className="list-title">Member Since</span>
            <span className="list-content pull-right">
              <DeviceTime sourceTime={user.createdOn} format="MMM DD, YYYY" />
            </span>
          </div>
          <div className="list-group-item clearfix">
            <span className="list-title">Username / Email</span>
            <span className="list-content pull-right">{user.username}</span>
          </div>

          <div className="list-group-item clearfix">
            <span className="list-title">Mobile</span>
            <span className="list-content pull-right">{user.mobile}</span>
          </div>
        </div>
      </div>
    );
  }

  renderTabs() {
    const handleTabClick = () => {
      if (this.state.hasChanged)
        confirm('Please save or cancel your changes', { hideCancel: true });
    };
    const linkStyle = {
      pointerEvents: this.state.hasChanged ? 'none' : 'auto',
    };

    // NOTE - CARFAX is the only thing we have content for atm, but this could change.
    const showThirdPartyTab =
      this.state.mp3rdParties && this.state.mp3rdParties.includes('CARFAX');

    const features = this.props.userProfile.user.userFeatures || [];

    return (
      <ul
        id="profileTabs"
        className="nav nav-tabs profile-tabs"
        data-tabs="tabs"
      >
        <li className={this.state.tabProfile} onClick={handleTabClick}>
          <Link to="/profile" style={linkStyle}>
            Profile Information
          </Link>
        </li>
        <li className={this.state.tabAccounts} onClick={handleTabClick}>
          <Link to="/profile/accounts" style={linkStyle}>
            My Accounts
          </Link>
        </li>
        <li className={this.state.tabAlerts} onClick={handleTabClick}>
          <Link to="/profile/alerts" style={linkStyle}>
            My Alerts
          </Link>
        </li>
        {showThirdPartyTab && (
          <li className={this.state.tabThirdParty} onClick={handleTabClick}>
            <Link to="/profile/thirdparty" style={linkStyle}>
              Third Party
            </Link>
          </li>
        )}
        {features.includes('546') && (
          <li className={this.state.tabImpersonate} onClick={handleTabClick}>
            <Link to="/profile/impersonate" style={linkStyle}>
              Impersonate
            </Link>
          </li>
        )}
      </ul>
    );
  }

  renderProfile() {
    return (
      <div
        id="profileInfo"
        className={'tab-pane ' + this.state.tabProfile}
        style={{
          display: this.state.tabTitle === 'Profile' ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {this.renderForm()}
        {this.renderFormButtons()}
      </div>
    );
  }

  renderForm() {
    const { user } = this.props.userProfile;
    const formFields = fields.map((field, index) => {
      return (
        <div key={index} className="form-group col-md-6 col-sm-6" style={{}}>
          <label className="control-label" htmlFor="name">
            {field.label}
          </label>

          <input
            id={field.field}
            className="form-control input-sm"
            style={{
              backgroundColor: '#EFEFEF',
              border:
                this.state.editMode && field.edit
                  ? '1px solid blue'
                  : 'initial',
              cursor: this.state.editMode ? 'text' : 'default',
            }}
            type="text"
            value={this.state[field.field] || ''}
            onChange={event => this.handleInputChange(event, field, user)}
            disabled={!this.state.editMode}
          />
        </div>
      );
    });

    if (getConfig('enableAddressValidation')) {
      const hasNewLocations =
        user.zipcode !== this.state.zipcode &&
        this.state.zipcode &&
        this.state.zipcode.length >= 5 &&
        this.state.editMode &&
        this.state.locations.length &&
        !this.state.validating;

      const location = (
        <div key="location" className="form-group col-md-6 col-sm-6">
          <label className="control-label" htmlFor="name">
            Location
          </label>
          <select
            id="location"
            className={`form-control input-sm ${
              hasNewLocations ? '' : 'profile-select' // removes arrow until there is a legit list to select from
            }`}
            style={{
              backgroundColor: '#EFEFEF',
              border: hasNewLocations ? '1px solid blue' : 'initial',
              cursor: 'default',
            }}
            value={this.state.locationIndex}
            disabled={!this.state.editMode || this.state.validating}
            onChange={event => {
              this.setState({ locationIndex: event.target.value });
            }}
          >
            {this.state.validating
              ? [
                  <option key="loading" disabled>
                    Loading...
                  </option>,
                ]
              : this.state.locations.map((location, index) => {
                  const isPlaceholer = typeof location === 'string';
                  const text = isPlaceholer
                    ? location
                    : `${location.city}, ${location.state}, ${location.postalCode}, ${location.county}`;

                  return (
                    <option key={index} value={index} disabled={isPlaceholer}>
                      {text}
                    </option>
                  );
                })}
          </select>
        </div>
      );

      // not a true user field which is why it's just tacked on to the UI
      formFields.push(location);
    }

    return <form style={{ marginBottom: 40 }}>{formFields}</form>;
  }

  renderFormButtons() {
    if (this.state.editMode) {
      return (
        <div>
          <button
            style={{ marginLeft: 5, marginRight: 5, width: 80 }}
            onClick={() => {
              if (this.state.hasChanged) {
                confirm('Are you sure you want to cancel you changes?').then(
                  () => this.loadsProfileData(),
                  () => null
                );
              } else {
                this.loadsProfileData();
              }
            }}
          >
            Cancel
          </button>

          <button
            disabled={!this.state.hasChanged || this.state.validating}
            style={{ marginLeft: 5, marginRight: 5, width: 80 }}
            onClick={() => {
              this.handleProfileUpdate();
            }}
          >
            Save
          </button>
        </div>
      );
    } else {
      return (
        <button
          style={{ marginLeft: 5, marginRight: 5, width: 80 }}
          onClick={() => {
            this.setState({ editMode: true });
          }}
        >
          Edit
        </button>
      );
    }
  }

  renderAccounts() {
    return (
      <div className={'tab-pane ' + this.state.tabAccounts} id="myAccounts">
        {/* <h3>My Accounts</h3> */}
        <Accounts accountList={this.props.userProfile.user.accountList} />
      </div>
    );
  }

  renderAlerts() {
    return (
      <div className={'tab-pane ' + this.state.tabAlerts} id="myAlerts">
        <Alerts />
      </div>
    );
  }

  renderThirdParty(user) {
    const { mp3rdParties } = this.state;

    if (mp3rdParties) {
      const content = mp3rdParties.map(item => {
        switch (item) {
          case 'CARFAX': {
            const linkStyle = {
              textDecoration: 'underline',
              cursor: 'pointer',
              marginLeft: 4,
              marginRight: 4,
            };

            const carfaxContent = (
              <div key={item}>
                <img
                  alt="carfax"
                  src={carfaxImg}
                  style={{
                    height: 40,
                    cursor: 'pointer',
                    margin: 5,
                    marginRight: 15,
                    border: 'none',
                  }}
                  onClick={this.handleCarfaxClick}
                />
                {this.props.carfaxLoginCheck.isLoggedIn ? (
                  <span>
                    You are logged in to CAFAX. Click
                    <span style={linkStyle} onClick={this.handleCarfaxClick}>
                      here
                    </span>
                    to logout.
                  </span>
                ) : (
                  <span>
                    Click
                    <span style={linkStyle} onClick={this.handleCarfaxClick}>
                      here
                    </span>
                    to login.
                  </span>
                )}
              </div>
            );

            return carfaxContent;
          }

          default:
            return null;
        }
      });

      return (
        <div
          className={'tab-pane ' + this.state.tabThirdParty}
          id="myThirdParty"
          style={{ minHeight: 200 }}
        >
          {content}
        </div>
      );
    }
  }

  renderImpersonate() {
    return (
      <div className={'tab-pane ' + this.state.tabImpersonate} id="impersonate">
        <h3>Impersonate a user</h3>
        <Impersonate />
      </div>
    );
  }

  render() {
    const { user } = this.props.userProfile;
    const { marketplaceList } = this.props.marketplaces;
    if (!user || !marketplaceList) return null;

    return (
      <DocumentTitle title={this.state.tabTitle}>
        <div
          className="container profile-container"
          style={{
            backgroundColor: '#f9f9f9',
            marginTop: 20,
            padding: 20,
            borderRadius: 4,
          }}
        >
          <IASConnectModal
            isOpen={this.state.iasConnectModalOpen}
            onClose={() => this.setState({ iasConnectModalOpen: false })}
            url={this.state.iasConnectUrl}
          />
          <h2>Profile</h2>
          {this.renderSubheader(user)}
          <div className="col-xs-12 no-padding">
            {this.renderTabs(user)}
            <div
              id="profileTabContent"
              className="tab-content profile-tab-content clearfix"
            >
              {this.renderProfile(user)}
              {this.renderAccounts(user)}
              {this.renderAlerts(user)}
              {this.renderThirdParty(user)}
              {this.renderImpersonate(user)}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    userProfile,
    marketplaces,
    carfaxLoginCheck,
    carfaxCode,
    marketplaceFeatures,
  } = state.entities;
  return {
    userProfile,
    marketplaces,
    carfaxLoginCheck,
    carfaxCode,
    marketplaceFeatures,
  };
};

export default connect(mapStateToProps, {
  loadUpdateUserProfile,
  loadARIAddressValidation,
  loadMarketplaces,
  loadCarfaxLoginCheck,
  resetCarfaxCode,
})(Profile);
