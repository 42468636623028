import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback3DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M705.4,197.8l-0.1-0.1H705l-0.1,0.1l-2.1,4.3v0.3l-17.7,36.6l0.4,0.3l0.1-0.1l17.7-36.6l0.1-0.1l2.1-4.3
	L705.4,197.8L705.4,197.8z M685.2,239.3l-0.4-0.3h-0.5l-2.6,5l-1.8,3.4l1.9,0.6l1.5-3.4l2.2-4.8L685.2,239.3L685.2,239.3z"
          />
          <path
            fill="#EBEBEB"
            d="M683.4,244.7l-1.5,3.4l2.5,0.7c3.4,0.6,6.5,0.6,8.9,0L683.4,244.7L683.4,244.7z M679,247l1,0.4l1.8-3.4
	l-26.5-11.1c-23.4-10.5-48.8-19-76.3-25.7l-1.9,2.9C602.5,216.9,636.6,229.3,679,247L679,247z"
          />
          <path
            fill="#FFFFFF"
            d="M680,247.4l-1-0.4c-42.4-17.9-76.3-30.3-102.1-37.1c-8.5-2.3-16.1-4-22.7-5.2c-22.6-2.5-46.5-3.2-71.9-2.1
	c-55.2,1-91.8,4.3-110.1,10.2l1,0.3c4,1.2,9.9,1.4,17.7,0.6l31.2-2.1c21.5-0.6,42.9-0.7,64.4-0.6H500c10.3,0.1,20.8,0.3,31.1,0.6
	c4.7,0.3,9.4,0.8,14,1.5c5.5,1,11,2.2,16.2,3.9l19.7,7c6.9,3,9.1,5.9,6.6,8.7c-11,9.6-20.6,16.6-28.6,21c-1.4,0.8-2.9,1.6-4.5,2.2
	c-3.7,1.5-8.3,2.5-13.5,2.9l-42.1,1.6l-2.6,0.1l3.6,10.3l146.6-5.5l47.3-1.2h0.4c7-0.4,14.2-1.8,21.5-3.9h0.1l18.4-4.5
	c-0.5-0.8-1.2-1.1-1.9-1.1l-31.1-2.9l-7.7-1.1c-3.3-0.6-6.3-1.1-9.1-1.9L682,248L680,247.4L680,247.4z"
          />
          <path
            fill="#656565"
            d="M697.5,250.5l-4.1-1.8c-2.5,0.6-5.5,0.6-8.9,0c2.8,0.7,5.8,1.4,9.1,1.9L697.5,250.5L697.5,250.5z"
          />
          <path
            fill="#FFFFFF"
            d="M703.9,248.5c-2.3,1.1-4.5,1.6-6.6,1.9l-4.1,0.1l7.7,1.1l31.1,2.9c0.7,0,1.4,0.4,1.9,1.1l16.4-4
	c-0.5-0.6-1.4-0.7-2.2-0.7L703.9,248.5L703.9,248.5z"
          />
          <path
            fill="#FFFFFF"
            d="M751.4,253.1c-0.3-0.7-0.5-1.1-1-1.5l-16.4,4.1c1.4,1.7,2.5,5.2,3.4,10.7l4.1,16.4c0.5,2.5,2.5,4.8,5.6,6.7
	c1.9,1.7,3.3,6.1,4.4,13.3l5,1.2l1.8,6.2l2.5,0.3l-0.5-8.4c-1.5-1-2.8-2.3-3.6-3.9l-4.8-24.8l-2.6-14.3
	C748.3,254.6,749.2,252.5,751.4,253.1L751.4,253.1z"
          />
          <path
            fill="#FFFFFF"
            d="M755.1,270.3l-3.4-15c-0.1-0.8-0.3-1.5-0.4-2.2c-2.2-0.6-2.9,1.5-2.2,6.1l2.6,14.3
	C754.1,273,755.2,272.1,755.1,270.3L755.1,270.3z"
          />
          <path
            fill="#979797"
            d="M747.4,293c-0.5-1.4-1.2-2.5-1.8-3.2c-2.5-2.1-4.3-4.1-5.4-5.9c-1.9-4.4-3.3-9.9-4.1-16.1l-4,0.7
	c1.2,0.4,2.2,1.1,3,2.3c0.3,0,0.5,0.7,0.8,2.1c0.4,1.5,0.5,3.2,0.7,5.2c0.1,2.1,0.1,3.9,0.1,5.2c0,1.2-0.1,1.9-0.4,2.2
	c-0.6,0.8-1.5,1.4-2.8,1.7c1.8,0.8,3.9,1.4,6.2,1.9C743.5,290.1,746.1,291.3,747.4,293L747.4,293z"
          />
          <path
            fill="#FFFFFF"
            d="M745.5,289.8c0.7,0.7,1.2,1.8,1.8,3.2c1,2.2,1.8,5.4,2.8,9.4l1.5,0.4c-1.1-7.3-2.6-11.7-4.4-13.3
	c-3.2-2.1-5-4.3-5.6-6.7l-4.1-16.4l-1.7,0.1l0.1,1.2c1,6.3,2.3,11.7,4.1,16.1C741.3,285.8,743.1,287.7,745.5,289.8L745.5,289.8z"
          />
          <path
            fill="#979797"
            d="M731.4,268.5l0.5-0.1l4-0.7l-0.1-1.2l-14.7,1c-4.1,0.3-5.9,1.6-5.6,4.3l10.2,0.1c0-1.5,1.4-2.6,4.3-3
	L731.4,268.5L731.4,268.5z"
          />
          <path
            fill="#D2D3D4"
            d="M732.1,268.5l-0.5,0.1c-0.4,1.4-0.6,3.2-0.6,5.5c0,1.5,0.1,3.3,0.3,5.4l0.3,2.9c0.4,2.2,1.1,3.9,1.9,5.1h0.1
	c1.2-0.3,2.2-0.8,2.8-1.7h-0.1c-0.3,0-0.5-0.7-1-2.1c-0.3-1.4-0.5-3.2-0.7-5.2c-0.1-2.1-0.3-3.9-0.1-5.4s0.3-2.2,0.6-2.2
	C734.3,269.6,733.4,268.8,732.1,268.5L732.1,268.5z"
          />
          <path
            fill="#979797"
            d="M725.8,272.6l0.4,1.4h4.8c0-2.3,0.1-4.1,0.5-5.5l-1.5,0.3c-2.9,0.5-4.3,1.5-4.3,3
	C725.6,272.1,725.6,272.3,725.8,272.6L725.8,272.6z"
          />
          <path
            fill="#BDBEC0"
            d="M726.2,274l-0.4-1.4c0-0.3-0.1-0.5-0.1-0.8l-10.2-0.1c0,0.4,0.1,1,0.3,1.4l5,11.7h8.3l-0.7-2.5L726.2,274
	L726.2,274z"
          />
          <path
            fill="#979797"
            d="M731,274h-4.8l2.3,8.1h3.2l-0.3-2.9C731,277.3,731,275.5,731,274L731,274z"
          />
          <path
            fill="#FFFFFF"
            d="M735.8,266.5l1.7-0.1c-1-5.5-2.1-9.1-3.4-10.7l-18.4,4.5h-0.1c-7.3,2.1-14.4,3.3-21.5,3.9
	c7.4,0.5,12.4,3.9,14.7,9.9l10,24.1l8.1,0.5l-3.9-9.1l-0.3-0.7l-1.8-4.3l-5-11.7c-0.1-0.5-0.3-1-0.3-1.4c-0.3-2.6,1.5-4,5.6-4.3
	L735.8,266.5L735.8,266.5z"
          />
          <path
            fill="#FFFFFF"
            d="M736,272.9c-0.3-1.4-0.5-2.1-0.8-2.1c-0.3,0-0.4,0.8-0.5,2.2c-0.1,1.5,0,3.3,0.1,5.4
	c0.1,2.1,0.4,3.9,0.7,5.2c0.3,1.4,0.5,2.2,1,2.1h0.1c0.1-0.3,0.3-1,0.4-2.2c0.1-1.5,0.1-3.2-0.1-5.2S736.2,274.4,736,272.9
	L736,272.9z"
          />
          <path
            fill="#EBEBEB"
            d="M722.6,289l0.3,0.7h8c3.4,0.5,8.1,2.1,13.8,4.3c0.1,0.7-2.6,0.7-8.1,0c5.5,0.7,8.3,0.7,8.1,0
	c-5.8-2.3-10.3-3.7-13.8-4.3h-7.3c4.4,1.4,8.5,2.1,12.2,2.1h0.1l1.1,8.3c3.3,0.5,6.7,1.1,10.2,1.9c-0.4-4-1-6.7-1.8-8.1
	c-5.2-2.5-9.8-4-13.6-4.7h-9.2L722.6,289L722.6,289z"
          />
          <path
            fill="#979797"
            d="M720.8,284.7l1.8,4.3h9.2c3.8,0.7,8.4,2.2,13.6,4.7c0.8,1.5,1.4,4.3,1.8,8.1l2.9,0.7c-1-4-1.8-7.2-2.8-9.4
	c-1.2-1.6-3.7-2.9-7.4-3.7c-2.3-0.5-4.4-1.2-6.2-1.9h-0.1c-1.8-0.8-3.2-1.6-4.4-2.6L720.8,284.7L720.8,284.7z"
          />
          <path
            fill="#EBEBEB"
            d="M723.6,289.5h-0.7l3.9,9.1l7,0.8c-0.8-5.1-0.1-7.7,1.9-7.7C732.1,291.7,728,291,723.6,289.5L723.6,289.5z"
          />
          <path
            fill="#979797"
            d="M731.6,282.2h-3.2l0.7,2.5c1.2,1,2.6,1.9,4.4,2.6C732.7,286.1,732,284.4,731.6,282.2L731.6,282.2z"
          />
          <path
            fill="#FFFFFF"
            d="M733.9,299.4l-7-0.8l-8.1-0.5c-8.4-0.4-16.5,0-24.5,1c-5.5,1.9-10.9,5.1-16.4,9.4l-1,0.7l1.5,1.1
	c2.1,1.5,4,3.2,5.9,5l0.1,0.1c1.4,1.4,2.8,2.6,4,4c6.6,7.3,11,15.4,13.3,24.2c0.8,3,1.4,6.2,1.7,9.5l0.7,9.8l12.2-1.2
	c8.9-1.5,15.5-3.7,20.1-6.6c-0.5-0.7-1-1.6-1.1-2.9l-0.1-1v-22.7c0-5.5,1-7.8,2.9-7.1c1.5-0.3,2.5,1.4,2.8,4.8c-1.1-4-3-3.2-5.6,2.3
	V351l0.1,1c1.5,1.8,3.9,3.2,7.2,4.3h-2.8c-1.5,0-2.6-0.4-3.3-1.4c-4.5,2.9-11.3,5.1-20.1,6.6c9.2,0,21.5-1.5,36.7-4.1l1.1-6.7v-0.3
	l0.1-0.8l1.1-6.2l0.1-0.8l1.1-6.5l0.1-0.8l0.8-4.8c0.1-0.5,0.4-1.1,0.7-1.5c1-1.6,2.8-2.5,5.4-2.3c-2.6,0-4.4,0.8-5.4,2.3h5.5
	c1.8-0.1,2.8-1.2,2.8-3.2v0.1l-1-12.5c-0.1-1.5-0.5-2.3-1.5-2.5l-3.3-0.4l-2.5-0.3l-1.8-6.2l-5-1.2l-1.5-0.4l-2.9-0.7
	c-3.4-0.8-6.7-1.4-10.2-1.9L733.9,299.4L733.9,299.4z"
          />
          <path
            fill="#EBEBEB"
            d="M736,291.7c-2.2,0-2.9,2.6-2.1,7.7l3.2,0.5L736,291.7L736,291.7z"
          />
          <path
            fill="#FFFFFF"
            d="M718.8,298.1l-10-24.1c-2.5-6.1-7.3-9.4-14.7-9.9h-0.3l-47.3,1.2l-146.6,5.5v0.3c2.6,13.5,3.7,30,3,49.5
	c-1,13.8-2.9,25.9-5.9,36.6l74.8-0.1c0-1.5,0.1-3,0.1-4.5c0.1-1.8,0.3-3.6,0.5-5.2c1-5,2.6-9.8,4.8-14.3c3.2-6.5,7.7-12.4,13.3-17.7
	c1.9-1.8,3.9-3.4,5.9-5c11.7-8.8,25.3-13.2,41-13.2c15,0,28.1,4.1,39.5,12.2l1-0.7c5.5-4.3,10.9-7.4,16.4-9.4
	C702.3,298.1,710.4,297.6,718.8,298.1L718.8,298.1z M674.6,269.2c2.1,0,3.7,0.7,5.2,2.1c1.4,1.5,2.1,3.2,2.1,5.1v6.9
	c0,2.1-0.7,3.7-2.1,5.2c-1.4,1.4-3.2,2.1-5.2,2.1c-6.3,0.8-12.7,0.8-18.7,0c-2.1,0-3.7-0.7-5.1-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9
	c0-2.1,0.7-3.7,2.1-5.1c1.5-1.4,3.2-2.1,5.1-2.1C662.2,268.3,668.6,268.3,674.6,269.2L674.6,269.2z"
          />
          <path
            fill="#FFFFFF"
            d="M679.7,271.2c-1.4-1.4-3.2-2.1-5.2-2.1c-6.1-0.8-12.2-0.8-18.7,0c-2.1,0-3.7,0.7-5.1,2.1
	c-1.4,1.5-2.1,3.2-2.1,5.1v6.9c0,2.1,0.7,3.7,2.1,5.2c1.5,1.4,3.2,2.1,5.1,2.1c6.1,0.8,12.4,0.8,18.7,0c2.1,0,3.7-0.7,5.2-2.1
	c1.4-1.5,2.1-3.2,2.1-5.2v-6.9C681.9,274.4,681.2,272.7,679.7,271.2L679.7,271.2z"
          />
          <path
            fill="#656565"
            d="M758.2,329.1c-0.3,0.4-0.4,1-0.7,1.5l-0.8,4.8h2.9c0.3-0.8,0.8-1.2,1.5-1.4h4.3v-2.8l-1.8-2.3L758.2,329.1
	L758.2,329.1z"
          />
          <path
            fill="#656565"
            d="M759.8,336.3h1.4l0.1-2.1c-0.7,0-1.2,0.5-1.5,1.4L759.8,336.3L759.8,336.3z"
          />
          <path
            fill="#656565"
            d="M761.1,336.3h-4.4l-1.1,6.5h2.9c0.3-0.5,0.5-1,1-1.1c0.3-0.1,0.4-0.1,0.7-0.3h5l0.3-3.7c0-1-0.5-1.5-1.5-1.4
	L761.1,336.3L761.1,336.3z"
          />
          <path
            fill="#656565"
            d="M759.8,336.3v-0.8h-2.9l-0.1,0.8 M755.6,342.8l-0.1,0.7h2.8l0.3-0.7 M760.9,341.4h-0.7
	c-0.3,0-0.5,0.1-0.7,0.3l-0.1,1.9h1.4L760.9,341.4L760.9,341.4z"
          />
          <path
            fill="#656565"
            d="M759.3,343.5h-3.7l-1.1,6.2h2.5c0.3-0.8,0.8-1.4,1.7-1.4h5.8l0.5-3.6c-0.1-1-0.5-1.4-1.4-1.4h-4.3V343.5
	L759.3,343.5z"
          />
          <path
            fill="#656565"
            d="M761.8,341.4l-0.1,2.1h1.8l0.3-2.1 M764,334.1h-1.8l-0.1,2.2h1.9"
          />
          <path fill="#FFFFFF" d="M693.7,264.1h0.3" />
          <path
            fill="#656565"
            d="M636.9,311.4c12,0,22.3,3.6,31.4,10.6l4.8,4.3c2.3,2.3,4.4,4.8,6.2,7.3c5.9,8.4,8.8,18,8.8,28.8v2.1l9.1-1
	l-0.4-5.5c-1.1-12.4-5.6-23.4-13.6-32.9l-3.6-4c-1.4-1.5-2.9-2.8-4.3-4c-1.4-1.1-2.6-2.2-4-3.2c-2.1-1.5-4-2.8-6.2-3.9
	c-8.3-4.4-17.5-6.7-27.5-6.7c-10.2,0-19.3,2.2-27.7,6.7c-5.2,2.9-10,6.5-14.6,11.1c-8.8,9.1-14.3,19.4-16.4,31.2
	c-0.3,1.5-0.4,3-0.4,4.5v0.5c-0.1,3-0.3,5.9-0.3,8.9v10l9.2-1c-1.1-4.1-1.7-8.5-1.7-13.2c0-11.4,3.3-21.5,9.9-30.3
	c1.5-2.1,3.2-4,5.1-5.8c1.5-1.5,3.2-3,4.8-4.3C614.6,315,625.1,311.4,636.9,311.4L636.9,311.4z"
          />
          <path
            fill="#656565"
            d="M668.3,322c-8.9-7.2-19.4-10.6-31.4-10.6c-11.8,0-22.3,3.6-31.2,10.6c-1.7,1.4-3.3,2.8-4.8,4.3
	c-1.9,1.9-3.6,3.9-5.1,5.8c-6.6,8.7-9.9,18.8-9.9,30.3c0,4.7,0.5,9.1,1.7,13.2c1.7,6.2,4.4,11.8,8.3,17.1l1.9,2.5l3.2,3.4
	c8.4,8.4,18.3,13.2,29.6,14.6l5.2,0.4l7.4-0.3c11.6-1.2,21.5-6.2,30-14.7c2.3-2.3,4.4-4.8,6.2-7.3c5.5-7.8,8.4-16.8,8.8-26.7v-2.1
	c0-10.7-2.9-20.4-8.8-28.8c-1.8-2.6-3.9-5-6.2-7.3L668.3,322L668.3,322z M642.6,323.9l1.9,0.3c7.6,1.4,14.2,5,19.9,10.7
	c2.8,2.8,5,5.6,6.7,8.8l1,1.8c1.7,3.3,2.8,6.7,3.3,10.5l0.3,1.9c0.1,1.5,0.3,3,0.3,4.7c0,10.3-3.6,19.3-10.6,26.7l-1.4,1.4
	c-2.8,2.6-5.6,4.8-8.8,6.5l-1.8,0.8c-5,2.3-10.5,3.4-16.4,3.4c-6.1,0-11.6-1.2-16.6-3.6l-1.8-1c-3.2-1.6-6.1-3.9-8.8-6.6h-0.1
	l-1.4-1.4c-6.9-7.4-10.3-16.2-10.3-26.4c0-1.6,0.1-3.3,0.3-5l0.3-1.8v-0.1c0.7-3.7,1.8-7.1,3.3-10.5l1-1.8c1.7-3,3.9-5.8,6.5-8.5
	c5.8-5.8,12.5-9.4,20.2-10.7l1.9-0.3l5.4-0.3L642.6,323.9L642.6,323.9z"
          />
          <path
            fill="#EBEBEB"
            d="M644.5,324.2l-1.9-0.3v0.5l0.5,13.5c0.1,0.8,0,1.8-0.1,2.8l-0.4,1.7h2.3l0.3-1.4c0.1-1.1,0.1-2.1,0.1-3.2
	L644.5,324.2L644.5,324.2z"
          />
          <path
            fill="#EBEBEB"
            d="M646.5,327.9v-0.7l-2.1-2.9l0.8,13.6c0.1,1.1,0,2.1-0.1,3.2l-0.3,1.4h2.1v-0.1l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6L646.5,327.9L646.5,327.9z"
          />
          <path
            fill="#EBEBEB"
            d="M653.7,346.5l-0.7,0.3c-0.4,0.3-1,0.5-1.4,0.8l-5,3.7c-0.8,0.8-1.5,1.1-1.9,0.5c-0.8-0.5-1-1.4-0.4-2.5
	l2.1-5.8l0.3-1.4h-2.1l-0.3,1.4l-2.8,7.8l2.8-7.8l0.3-1.4h-2.3c-0.1,0.4-0.4,1-0.5,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.5
	c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4c-0.3-0.4-0.4-1-0.5-1.4h-2.3c0.1,0.4,0.3,1,0.4,1.4l2.9,8h0.1c1,0.3,1.5,0.8,1.9,1.6
	c0.4,0.8,0.5,1.6,0.3,2.6s-0.8,1.5-1.7,1.9c-0.8,0.4-1.7,0.4-2.6,0.1c-1-0.3-1.5-0.8-1.9-1.6c-0.3-0.5-0.4-1.1-0.3-1.8l0.1-0.8
	c0.3-0.8,0.8-1.5,1.7-1.9s1.7-0.5,2.3-0.3l-2.9-8c-0.1-0.4-0.3-1-0.4-1.4h-2.1c0.1,0.4,0.1,1,0.3,1.4l2.1,5.8c0.5,1.1,0.5,1.6,0,1.9
	c-0.8,0.7-1.7,0.6-2.5-0.4l-5-3.7c-0.4-0.3-0.8-0.5-1.2-0.7l-0.8-0.4c-1.1-0.6-2.2-0.8-3.4-1.2l-9.6-2.5l-0.8-0.1l-3.4,1.1l13.2,3.4
	c1,0.3,1.9,0.7,2.9,1.1l0.4,0.3c0.7,0.3,1.4,0.8,1.9,1.2l6.6,5l-6.6-5c-0.7-0.5-1.2-1-1.9-1.2l-0.1-0.1c-1-0.4-1.9-0.8-2.9-1.1
	l-13.2-3.4l-1,1.8l0.5,0.1h0.1l12.8,3.6c1,0.3,1.8,0.5,2.6,1l0.1,0.1c0.8,0.4,1.7,1.1,2.5,1.9l6.1,5.4c0.4,0.5,0.5,1.1,0.4,1.5v0.3
	l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-4.7,0.4h11.3c0.3-0.7,0.7-1.4,1.2-2.1c1-1,2.2-1.5,3.6-1.5c1.2,0,2.3,0.4,3.2,1.2l0.4,0.3
	c0.5,0.5,1,1.2,1.2,2.1h8.4l-1.8-0.1c-0.5-0.3-1.1-0.5-1.4-1l-0.1-0.3V359c-0.1-0.5,0.1-1.1,0.4-1.6l6.1-5.4
	c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9h0.1l-1-1.8h-0.3l-13.2,3.4c-1.1,0.3-2.1,0.7-2.9,1.1l-0.3,0.1
	c-0.8,0.4-1.5,0.8-2.2,1.4l-6.7,5.1v0.3c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.3,1c-1,0-1.8-0.3-2.3-1c-0.7-0.7-1-1.4-1-2.3
	s0.3-1.8,1-2.5c0.4-0.4,1-0.7,1.7-0.8l0.8-0.1c1,0,1.8,0.3,2.3,1c0.5,0.7,1,1.4,1,2.2l6.7-5.1c0.7-0.5,1.4-1,2.2-1.4l0.3-0.1
	c0.8-0.4,1.8-0.7,2.9-1.1l13.2-3.4l-3.4-1.1l-0.7,0.1l-9.8,2.5C655.9,345.7,654.8,346.1,653.7,346.5L653.7,346.5z M675.3,355.8
	l-6.5,2.5l-6.6,2.5c-0.1,0-0.1,0.1-0.3,0.1h5.9l1.1-0.4l6.1-2.3l0.1-0.1l0.4-0.1L675.3,355.8L675.3,355.8z"
          />
          <path
            fill="#EBEBEB"
            d="M669,360.4l-1.1,0.5h5.1l0.4-0.3l2.2-2.9l-0.3,0.1L675,358 M629.6,324.2v0.5l-0.8,13.2c0,1.1,0,2.2,0.1,3.2
	v0.3c0,0.4,0.1,0.7,0.3,1.1h2.3c-0.3-0.5-0.4-1.1-0.4-1.6c-0.1-1-0.3-1.9-0.1-2.8l0.5-14L629.6,324.2L629.6,324.2z"
          />
          <path
            fill="#EBEBEB"
            d="M629.6,324.6v-0.4l-2.1,2.9v0.7l-0.5,10c-0.1,1.2-0.1,2.3,0,3.6l0.1,0.7v0.3h2.1c-0.1-0.4-0.1-0.7-0.3-1.1
	V341c-0.1-1-0.1-1.9-0.1-3.2L629.6,324.6L629.6,324.6z M599.1,355.7h-0.4l-0.3,1.8h0.1l8.4,3.3h5.9c-0.4-0.1-0.7-0.3-1-0.4
	L599.1,355.7L599.1,355.7z M598.4,357.5l2.1,2.9l0.8,0.3l0.4,0.3h5.1"
          />
          <path
            fill="#EBEBEB"
            d="M632,362.4c0-0.5,0.1-1.1,0.3-1.5H621l-3.3,0.3c-1.2,0.1-2.2,0.1-3.2,0c-0.7,0-1.2-0.3-1.7-0.4h-5.9l4.3,1.6
	c1.1,0.4,2.1,0.7,3,0.8l0.4,0.1l2.3,0.1l8.4-0.3l0.1-0.1c0.5-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2
	c0.1,1,0,1.8-0.7,2.5c-0.5,0.7-1.2,1.2-2.2,1.4c-0.7,0.1-1.2,0-1.8-0.3l-0.7-0.4c-0.8-0.5-1.2-1.2-1.4-2.2c-0.1-0.8,0-1.6,0.4-2.3
	l-8.4,0.3l-2.3-0.1l-0.4-0.1c-1-0.1-1.9-0.4-3-0.8l-4.3-1.6h-5.2l8.8,3.4c1.2,0.4,2.3,0.8,3.4,1.1l0.7,0.1h7.7
	c1.2-0.3,1.8,0.3,1.9,1.2c0.3,0.5,0,1-1.1,1.5l-5.1,3.6c-0.3,0.1-0.4,0.3-0.5,0.4l-0.5,0.5l-0.5,0.5c-0.7,0.8-1.4,1.8-2.2,2.9
	l-2.1,3.2h2.3l1.2-2.1c0.5-1,1.2-1.8,1.9-2.5l0.3-0.3c0.5-0.5,1.1-1,1.8-1.5l6.9-4.7l-6.9,4.7c-0.7,0.5-1.2,1-1.8,1.5l-0.3,0.3
	c-0.7,0.7-1.4,1.5-1.9,2.5l-1.2,2.1h2.5l0.5-0.8c0.4-0.8,1.1-1.5,1.8-2.2v-0.1c0.8-0.7,1.7-1.2,2.6-1.8l6.9-4.1l1.7,0.1l0.3,0.1
	l0.3,0.3c0.3,0.4,0.5,0.8,0.5,1.5l-1.5,6.9h2.6l1.7-5.6l-0.1-0.1c-0.5-0.8-0.8-1.6-0.5-2.5c0.1-1,0.5-1.6,1.4-2.2
	c0.8-0.6,1.7-0.7,2.5-0.6c1,0.1,1.7,0.5,2.2,1.4c0.5,0.7,0.8,1.6,0.7,2.5c-0.1,0.7-0.4,1.2-0.8,1.6l-0.5,0.5
	c-0.7,0.5-1.5,0.8-2.5,0.7c-0.8-0.1-1.5-0.5-2.1-1.2l-1.7,5.6h2.1l0.7-2.2c0.1-1.2,0.8-1.7,1.8-1.4c0.5-0.1,1,0.4,1.1,1.6l0.5,2.1
	h2.1l-1.5-5.2l1.5,5.2h2.6l-1.5-6.7c0-0.7,0.1-1.1,0.5-1.5l0.3-0.3l0.3-0.1h1.7l6.9,4.1c1.1,0.5,1.9,1.1,2.6,1.8
	c0.7,0.7,1.2,1.4,1.7,2.2l0.4,0.5h2.5l-1.2-1.9c-0.5-0.8-1.1-1.6-1.9-2.5l-0.1-0.1c-0.5-0.7-1.2-1.2-1.9-1.7l-7-4.7h-0.1
	c-1,0.3-1.8,0.3-2.6-0.3c-0.8-0.4-1.4-1.1-1.7-1.9c-0.3-1-0.3-1.8,0.3-2.6c0.4-0.8,1.1-1.4,1.9-1.6c1-0.3,1.8-0.3,2.6,0.1
	c0.5,0.3,1.1,0.7,1.4,1.2l0.3,0.7c0.3,1,0.3,1.8-0.3,2.6c-0.4,0.8-1,1.4-1.8,1.6l7,4.7c0.7,0.5,1.4,1.1,1.9,1.6l0.1,0.1
	c0.8,0.8,1.4,1.6,1.9,2.5l1.2,1.9h2.2l-1.9-3c-0.7-1.1-1.4-2.1-2.2-2.9l-0.4-0.4l-3.2-2.3l-3.2-2.2c-1.1-0.5-1.4-1.2-0.8-2.2
	c0-0.5,0.7-0.8,1.9-0.5h8.7c1.2-0.3,2.5-0.7,3.4-1.1l5.6-2.2l3.6-1.4l0.3-0.1h-5.2l-5,1.9c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3
	l8.3,0.3l2.8-0.3c1-0.3,2.1-0.5,3-1l5-1.9h-5.9c-0.8,0.3-1.7,0.5-2.5,0.7c-1,0.1-2.1,0.1-3.2,0l-6.2-0.5h-8.4c0.1,0.5,0.3,1,0.3,1.5
	v0.1c0,1.2-0.5,2.3-1.5,3.3c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2c-1.1-0.1-2.1-0.6-2.9-1.4l-0.3-0.4c-0.5-0.5-0.8-1.2-1-1.9
	C632.1,363.3,632,362.8,632,362.4L632,362.4z M630,379.4l-0.3,1c-0.3,1.1-0.5,2.2-1,3v0.1c-0.4,0.8-1,1.6-1.5,2.2l-8.8,11l1.8,1
	l0.1-0.1l0.3-0.3l8.4-10.2c0.7-0.8,1.2-1.8,1.8-2.6l0.1-0.3c0.4-0.8,0.7-1.5,1-2.3l0.8-2.5L630,379.4L630,379.4z"
          />
          <path
            fill="#EBEBEB"
            d="M634.7,379.4h-2.1l-0.8,2.5c-0.3,0.8-0.6,1.5-1,2.3l-0.1,0.3c-0.4,0.8-1,1.6-1.8,2.6l-8.4,10.2l-0.3,0.3
	l3.3-1.1l0.4-0.5l6.3-7.8c0.8-1,1.5-1.9,2.1-2.9l0.3-0.5l0.7-1.5L634.7,379.4L634.7,379.4z M614.5,379.4h-2.3l-3.3,5.2l-0.4,0.5
	l-0.1,3.7"
          />
          <path
            fill="#EBEBEB"
            d="M608.3,388.8l1.4,1.4l7.3-10.9h-2.5 M661.3,379.4h-2.2l6.1,9.5l0.1,0.1l-0.1-3.6l-0.3-0.5l-3.3-5.1
	 M640.9,379.4h-2.1l1.2,3.9c0.1,0.5,0.3,1,0.5,1.4l0.4,0.7c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.7l0.4,0.6l3.4,1.1l-8.7-10.6
	c-0.7-0.8-1.2-1.6-1.8-2.6l-0.1-0.3c-0.3-0.7-0.7-1.5-1-2.2L640.9,379.4L640.9,379.4z"
          />
          <path
            fill="#EBEBEB"
            d="M643.8,380.5l-0.3-1.1h-2.6l0.8,2.6c0.3,0.8,0.5,1.5,1,2.2l0.1,0.3c0.5,1,1.1,1.8,1.8,2.6l8.7,10.6l0.1,0.1
	l1.8-0.8l-0.1-0.1l-8.8-11c-0.5-0.7-1.1-1.5-1.5-2.3C644.4,382.8,644.1,381.7,643.8,380.5L643.8,380.5z M665.3,389l-0.1-0.1
	l-6.1-9.5h-2.6l7.4,11.1l1.4-1.4 M640.5,358.9l-0.3-0.3c-1-0.8-1.9-1.2-3.2-1.2c-1.4,0-2.5,0.5-3.6,1.5c-1,1-1.4,2.2-1.4,3.6
	c0,0.4,0,0.8,0.1,1.2c0.1,0.7,0.5,1.4,1,1.9l0.3,0.4c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.5,2.9-1.4c1-1,1.4-2.1,1.5-3.3v-0.3
	C641.9,361.1,641.5,359.8,640.5,358.9L640.5,358.9z M640.4,360.5l0.4,0.4l0.4,0.5c0.1,0.3,0.1,0.4,0.1,0.7v0.8l-0.3,0.5l-0.4,0.6
	l-0.5,0.4l-1.2,0.5l-1.5,0.3l-1.7-0.1c-0.4-0.1-0.8-0.3-1.2-0.5l-1-0.8l-0.4-0.5l-0.3-0.7v-0.8c0-0.3,0.1-0.4,0.3-0.7l0.4-0.5
	l0.6-0.4c0.5-0.3,1.1-0.5,1.8-0.7l0.8-0.1c1.1-0.1,2.1,0.1,2.9,0.5L640.4,360.5L640.4,360.5z"
          />
          <path
            fill="#EBEBEB"
            d="M640.9,360.9l-0.4-0.4l-0.5-0.4c-0.8-0.4-1.8-0.7-2.9-0.5l-0.8,0.1c-0.7,0.1-1.4,0.4-1.8,0.7l-0.5,0.4
	l-0.4,0.5c-0.1,0.1-0.3,0.4-0.3,0.7c-0.1,0.3-0.1,0.5-0.1,0.8l0.3,0.7l0.4,0.5l1,0.8c0.4,0.3,0.8,0.4,1.2,0.5l1.7,0.1l1.5-0.3
	l1.2-0.5l0.5-0.4l0.4-0.5l0.3-0.5v-0.8c0-0.3,0-0.4-0.1-0.7L640.9,360.9L640.9,360.9z M637.3,359.8l1.2,0.1l1,0.4
	c0.7,0.4,1.1,0.8,1.2,1.5h-3c-0.1,0-0.1,0-0.1-0.1l-0.1-0.3c-0.1-0.3-0.1-0.5-0.1-0.8C637.2,360.4,637.2,360.1,637.3,359.8
	L637.3,359.8z M637.2,359.8c-0.3,0.3-0.5,0.5-0.7,1l-0.4,1.1h-2.3l0.4-0.8c0.1-0.3,0.4-0.4,0.7-0.5
	C635.7,360,636.5,359.8,637.2,359.8L637.2,359.8z M637.2,361.9h0.1v0.7h-0.1V361.9z M636.9,361.9l-1,0.1h-0.3v0.4h1.2v0.1h-1.4
	c-0.1,0-0.1,0-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.3-0.1l0.4,0.1h0.7L636.9,361.9L636.9,361.9z M636.6,362.8l0.3,0.1
	l0.1,0.1c0.1,0.3,0.1,0.5,0.3,0.8v1c-0.8,0-1.5-0.1-2.1-0.5s-1-1-1.2-1.5L636.6,362.8L636.6,362.8z M637.5,362.6V362h0.3l0.3-0.1
	l0.3,0.1l0.4-0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.1v0.4l-0.1,0.1h-1.2C637.6,362.6,637.5,362.6,637.5,362.6L637.5,362.6z M639.1,362.6
	v-0.7h1.4c0.1,0,0.1,0,0.1,0.1v0.5c0,0.1-0.1,0.1-0.3,0.1v-0.6h-1.1v0.5h-0.1V362.6L639.1,362.6z M638.2,362.8h2.5
	c-0.1,0.7-0.5,1.2-1.2,1.5l-1.1,0.4l-1.2,0.1l0.5-0.8l0.3-0.5L638.2,362.8L638.2,362.8z M633.9,362c0-0.1,0.1-0.1,0.1-0.1l0.3-0.1
	l0.3,0.1h0.7v0.1H634v0.1h1.2v0.3h-1.4l-0.1-0.1h1.2l0.1-0.1h-1.2L633.9,362L633.9,362z"
          />
          <path
            fill="#EBEBEB"
            d="M638.4,360l-1.2-0.1c-0.1,0.3-0.1,0.5,0,0.8c0,0.3,0,0.5,0.1,0.8l0.1,0.3c0,0.1,0.1,0.1,0.1,0.1h3
	c-0.1-0.7-0.5-1.1-1.2-1.5L638.4,360L638.4,360z"
          />
          <path
            fill="#EBEBEB"
            d="M637.2,359.8c-0.7-0.1-1.4,0.1-2.1,0.4c-0.3,0.1-0.5,0.3-0.7,0.5l-0.4,0.8h2.3l0.4-1.1
	C636.8,360.4,636.9,360.1,637.2,359.8L637.2,359.8z M637.3,361.9h-0.1v0.7h0.1 M636.9,362l-0.7-0.1l-0.4-0.1c-0.1,0-0.3,0-0.3,0.1
	c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.1,0.1h1.4v-0.1h-1.2v-0.4h1.2L636.9,362L636.9,362z M636.9,363l-0.3-0.1h-2.9
	c0.3,0.5,0.7,1.1,1.2,1.5c0.7,0.4,1.4,0.5,2.1,0.5v-1c0-0.3-0.1-0.5-0.3-0.8L636.9,363L636.9,363z M637.5,362v0.5
	c0,0,0.1,0.1,0.3,0.1h1.2l0.1-0.1v-0.4L639,362c0,0-0.1-0.1-0.3-0.1h-1L637.5,362L637.5,362z M638.3,362h0.5v0.4h-1.1V362L638.3,362
	L638.3,362z"
          />
          <path
            fill="#EBEBEB"
            d="M638.6,362h-0.8v0.4h1l0.1-0.1l-0.1-0.3 M639.1,361.9v0.7h0.1V362h1.1v0.5c0.1,0,0.1,0,0.3-0.1v-0.5
	l-0.1-0.1h-1.4L639.1,361.9L639.1,361.9z M640.6,362.8h-2.5l-0.1,0.7l-0.3,0.5l-0.5,0.8l1.2-0.1l1.1-0.4
	C640.1,363.9,640.5,363.4,640.6,362.8L640.6,362.8z M634,361.9c-0.1,0-0.1,0-0.1,0.1v0.3h1.2l-0.1,0.1h-1.2l0.1,0.1h1.4v-0.3H634
	v-0.1h1.2V362h-1L634,361.9L634,361.9z M646.5,327.2l0.1,0.8c5.8,1.6,11,4.7,15.5,9.2c1.8,1.8,3.4,3.7,4.7,5.6l0.7-0.1l3.4,1.1h0.3
	c-1.8-3.2-4-6.1-6.7-8.8c-5.8-5.8-12.4-9.4-19.9-10.7v0.1L646.5,327.2L646.5,327.2z M642.6,324.5v-0.5 M642.6,324.5v-0.5l-1.5,2.2
	v0.8l0.4,12.2l-0.1,1.2c-0.1,0.5-0.3,1.1-0.7,1.6l-1.8,3.2c-0.4,0.7-1,1.1-1.7,1.1h-0.7c-0.7,0-1.2-0.4-1.7-1.1l-1.8-3.2
	c-0.3-0.5-0.5-1.1-0.7-1.6l-0.1-1.1l0.4-12.2v-0.8l-1.5-2.2l-0.5,14c-0.1,0.8-0.1,1.8,0.1,2.8c0.1,1,0.5,1.9,1.1,3l3.2,7.4
	c0.4,0.5,0.8,0.8,1.4,1h0.5c0.5-0.1,1-0.4,1.4-1l3.2-7.4c0.5-1.1,1-2.1,1.1-3c0.1-1,0.3-1.9,0.1-2.8L642.6,324.5L642.6,324.5z"
          />
          <path
            fill="#EBEBEB"
            d="M641,326.1l1.5-2.2l-5.6-0.4l-5.4,0.3l1.5,2.3v0.8l3.8-0.3l4.1,0.3"
          />
          <path
            fill="#656565"
            d="M636.9,326.7l-3.9,0.3l-0.5,12.2l0.1,1.1l4.3-0.4l4.4,0.4l0.1-1.1l-0.4-12.2"
          />
          <path
            fill="#EBEBEB"
            d="M639,345.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6l-4.4-0.4l-4.3,0.4c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2
	c0.4,0.7,1,1.1,1.7,1.1h0.7C638.2,346.2,638.6,345.9,639,345.1L639,345.1z"
          />
          <path
            fill="#656565"
            d="M647.2,337.9v3.6l-0.1,0.7c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5
	c-1.4-1.9-2.9-3.9-4.7-5.6c-4.5-4.5-9.8-7.7-15.5-9.2L647.2,337.9L647.2,337.9z"
          />
          <path
            fill="#EBEBEB"
            d="M647,342.2l-0.4,1.5l-2.1,5.8c-0.5,1.1-0.4,1.9,0.4,2.5c0.4,0.4,1,0.3,1.9-0.5l5-3.7
	c0.4-0.4,0.8-0.7,1.4-0.8l-0.3-0.3C651.1,344.7,649.2,343.3,647,342.2L647,342.2z M672,345.4l-13.6,3.9c-1,0.1-1.8,0.5-2.5,1h-0.1
	c-1,0.4-1.8,1.1-2.5,1.9l-6.1,5.4c-0.3,0.6-0.5,1.1-0.4,1.6v0.3l0.1,0.3c0.3,0.6,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.3,3.2,0
	c1-0.1,1.9-0.4,2.8-0.7l6.6-2.5l6.5-2.5l-2.6-0.7l-0.7,0.3l-3.3,1.2l-8.3,3l-0.3,0.1l-0.8,0.1h-1.8l-1.2-0.3l-2.3-0.4l-0.7-0.1
	c-0.4-0.3-0.7-0.5-0.8-1l-0.3-0.7c-0.3-0.7,0-1.4,0.5-1.9l0.8-0.8l1.7-1.8c0.5-0.5,1-0.8,1.5-1.1c0.3-0.3,0.5-0.3,1-0.4l8.1-2.3
	l3.8-1.1l0.7-0.3L672,345.4L672,345.4z"
          />
          <path
            fill="#EBEBEB"
            d="M672,345.4l-1.8,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C674.8,352.1,673.7,348.7,672,345.4
	L672,345.4z"
          />
          <path
            fill="#656565"
            d="M672,355.4c-0.5-2.8-1.4-5.2-2.5-7.7l-3.9,1.1v1.2h-1.9c-2.1,0.1-3.2,0.7-3.3,1.8c-0.3,1-1,1.4-1.9,1.2
	c-0.4,0-0.7,0.1-1.1,0.3c0.5,1.1,1,2.2,1.2,3.4h0.5c0.7-1.4,1.4-2.2,2.2-2.3c1.7-0.5,2.3-1.5,2.3-3c0-0.8,0.5-1,1.7-0.5
	c1.4,0.7,2.3,1.5,2.8,2.6l0.3,1.8l0.1,1.4L672,355.4L672,355.4z"
          />
          <path
            fill="#EBEBEB"
            d="M675.6,357.8l-0.3,0.1 M675,358l0.3-0.1 M675.6,357.8l-0.3,0.1L675.6,357.8l-2.2,2.9l-0.8,0.3v1.4
	c0,1.4-0.1,2.6-0.1,3.9c-0.7,7-3.3,13.2-7.7,18.6l0.4,0.5v3.6l0.1,0.1c7-7.4,10.6-16.4,10.6-26.7
	C675.9,360.8,675.7,359.3,675.6,357.8L675.6,357.8z"
          />
          <path
            fill="#656565"
            d="M672.7,362.4v-1.4l-3.6,1.4c0,1.5,0,2.9-0.1,4.3l3.7-0.4C672.5,364.9,672.7,363.7,672.7,362.4L672.7,362.4z
	 M665.8,350.1v-1.2l-8.1,2.3c-0.3,0.1-0.7,0.3-1,0.4c-0.5,0.3-1.1,0.7-1.5,1.1l-1.7,1.8c-0.1,1.1-0.3,2.6-0.3,4.4l0.7,0.1l2.3,0.4
	v-3c0.1-1.6,0.5-2.8,1.4-3.2c0.3-0.1,0.7-0.3,1.1-0.3c1.1,0.1,1.7-0.3,1.9-1.2c0.1-1.1,1.2-1.6,3.3-1.8h1.8L665.8,350.1L665.8,350.1
	z"
          />
          <path
            fill="#EBEBEB"
            d="M668.7,356.7l-0.1-1.4L668.7,356.7L668.7,356.7z"
          />
          <path
            fill="#656565"
            d="M668.6,355.3l-0.3-1.8c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5c0,1.5-0.8,2.5-2.3,3
	c-0.8,0.1-1.5,1-2.2,2.3h-3v0.8c1.2-0.1,2.2,0,2.8,0.4s1,1,1.1,1.8l0.3-0.1l8.3-3L668.6,355.3L668.6,355.3z"
          />
          <path
            fill="#EBEBEB"
            d="M653.6,354.5l-0.8,0.8c-0.5,0.5-0.8,1.2-0.5,1.9l0.3,0.7c0.1,0.4,0.4,0.8,0.8,1
	C653.3,357.1,653.4,355.7,653.6,354.5z M658.8,356.7c-0.3-1.2-0.7-2.3-1.2-3.4c-0.7,0.4-1.2,1.5-1.4,3.2v0.3L658.8,356.7
	L658.8,356.7z"
          />
          <path
            fill="#656565"
            d="M656.3,357.6v1.9l1.2,0.3h1.8l0.8-0.1c-0.1-0.8-0.5-1.4-1.1-1.8C658.4,357.5,657.4,357.3,656.3,357.6
	L656.3,357.6z"
          />
          <path
            fill="#EBEBEB"
            d="M653.3,365.7h-1.8c-1.2-0.3-1.8,0-1.9,0.5c-0.5,1-0.3,1.6,0.8,2.2l3.2,2.2
	C653.4,369.4,653.3,367.8,653.3,365.7L653.3,365.7z M659.6,365.7h-0.4v0.4"
          />
          <path
            fill="#656565"
            d="M656.3,367.7v-1.8l-3-0.1c0.1,2.1,0.3,3.7,0.4,5l3.2,2.3l0.4,0.4c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3l3,0.3
	l1.2-5.9H664c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1C657,370.5,656.5,369.6,656.3,367.7z"
          />
          <path
            fill="#EBEBEB"
            d="M656.3,367.4v0.3c0.1,1.8,0.7,2.9,1.7,3.3c0.4-1.1,0.8-2.2,1-3.3v-0.3L656.3,367.4L656.3,367.4z"
          />
          <path
            fill="#656565"
            d="M656.3,366.6v0.8h3l0.1,0.3c0.7,1.2,1.2,2.1,2.1,2.2c1.7,0.5,2.3,1.5,2.3,3c0,0.8,0.5,1,1.7,0.4
	c1.4-0.7,2.3-1.5,2.8-2.6l0.3-1.8l0.3-2.2l0.3-4.3l-5.6,2.2c-1.1,0.4-2.2,0.8-3.4,1.1l-0.3,0.1l-0.4,0.4
	C658.5,366.6,657.6,366.7,656.3,366.6L656.3,366.6z"
          />
          <path fill="#EBEBEB" d="M658.9,367.4v0.3h0.4v-0.3" />
          <path
            fill="#656565"
            d="M656.3,366.6c1.4,0.1,2.3,0,2.9-0.4v-0.3l-3,0.1v0.5L656.3,366.6L656.3,366.6z"
          />
          <path
            fill="#EBEBEB"
            d="M668.8,366.6c0.1-1.4,0.3-2.8,0.3-4.3L668.8,366.6z M668.8,366.6l-0.3,2.3 M645.7,355v-0.3
	c0-0.8-0.4-1.7-1-2.2c-0.7-0.7-1.5-1-2.3-1l-0.8,0.1c-0.7,0.1-1.1,0.4-1.7,0.8c-0.7,0.7-1,1.5-1,2.5s0.3,1.8,1,2.3
	c0.7,0.7,1.5,1,2.3,1c1,0,1.8-0.4,2.3-1C645.4,356.8,645.7,356,645.7,355L645.7,355z M643.5,353.8c0.3,0.3,0.5,0.7,0.5,1.2
	c0,0.4-0.1,0.8-0.5,1.2c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.5c-0.4-0.3-0.5-0.7-0.5-1.2c0-0.5,0.1-1,0.5-1.2
	c0.3-0.3,0.5-0.4,0.8-0.4h0.4C642.8,353.4,643.2,353.5,643.5,353.8L643.5,353.8z"
          />
          <path
            fill="#EBEBEB"
            d="M644.1,355c0-0.5-0.1-1-0.5-1.2c-0.3-0.3-0.7-0.4-1.2-0.4h-0.4c-0.3,0.1-0.6,0.3-0.8,0.4
	c-0.4,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.6,1.2,0.6c0.4,0,0.8-0.1,1.2-0.5C643.9,355.8,644.1,355.4,644.1,355
	L644.1,355z M634.7,356c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6h-0.1c-0.8-0.3-1.7-0.1-2.3,0.3c-0.8,0.4-1.4,1.1-1.7,1.9
	l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,1.9,1.6c1,0.3,1.8,0.3,2.6-0.1C633.9,357.5,634.4,356.8,634.7,356L634.7,356z
	 M633.2,355.4c-0.1,0.4-0.4,0.8-0.8,1l-1.4,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.3-0.3-0.7-0.1-1v-0.4c0.1-0.4,0.4-0.8,0.8-1l1.2-0.1
	c0.4,0.1,0.8,0.4,1,0.8C633.2,354.6,633.3,355,633.2,355.4L633.2,355.4z"
          />
          <path
            fill="#EBEBEB"
            d="M632.4,356.4c0.4-0.3,0.7-0.5,0.8-1c0.1-0.4,0.1-0.8-0.1-1.2c-0.3-0.4-0.5-0.7-1-0.8l-1.2,0.1
	c-0.4,0.1-0.7,0.5-0.8,1v0.4c-0.1,0.3,0,0.5,0.1,1c0.3,0.4,0.5,0.7,1,0.8L632.4,356.4L632.4,356.4z M642.4,366.4
	c0.3,1,0.8,1.5,1.7,1.9c0.8,0.4,1.7,0.5,2.6,0.3h0.1c0.8-0.3,1.4-1,1.8-1.6c0.4-0.8,0.5-1.6,0.3-2.6l-0.3-0.7
	c-0.3-0.5-0.7-1-1.4-1.2c-0.8-0.4-1.7-0.5-2.6-0.1c-1,0.3-1.5,0.8-1.9,1.7C642.1,364.6,642.1,365.5,642.4,366.4L642.4,366.4z
	 M644.2,364.5c0.1-0.4,0.5-0.7,1-0.8l1.2,0.1c0.3,0.1,0.6,0.4,0.7,0.7l0.1,0.4c0.1,0.4,0.1,1-0.1,1.2c-0.1,0.4-0.5,0.7-1,0.8
	l-1.2-0.1c-0.4-0.3-0.7-0.5-0.8-1C643.9,365.5,643.9,364.9,644.2,364.5L644.2,364.5z"
          />
          <path
            fill="#EBEBEB"
            d="M645.2,363.7c-0.4,0.1-0.8,0.4-1,0.8c-0.3,0.4-0.3,0.8-0.1,1.4c0.1,0.4,0.4,0.7,0.8,1h1.2
	c0.4-0.1,0.8-0.4,1-0.8c0.3-0.4,0.3-0.8,0.1-1.2l-0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.7L645.2,363.7L645.2,363.7z M642.6,371.1
	c-0.4,0.4-0.5,1-0.5,1.5l1.8,7.8c0.3,1.2,0.5,2.3,1,3c0.4,0.8,1,1.6,1.5,2.3l8.7,11l-0.1-2.6l-0.4-0.5l-7.6-9.8l-0.5-1
	c-0.3-0.6-0.4-1.1-0.5-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.1l0.3-0.3l1.9-0.1l3.3,1.5c0.6,0.3,1.1,0.7,1.5,1.1l0.7,0.8
	l6.9,10.2l0.4,0.7l2.5,1l-7.7-11.7c-0.4-0.8-1-1.5-1.7-2.2c-0.7-0.7-1.7-1.2-2.6-1.8l-6.9-4.1h-1.7l-0.3,0.1L642.6,371.1
	L642.6,371.1z"
          />
          <path
            fill="#EBEBEB"
            d="M646,375.8c-0.5,0.4-0.8,1.1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.5,1.8c2.3-1.1,4.5-2.6,6.6-4.7l0.4-0.4
	c-0.4-0.4-1-0.7-1.5-1.1l-3.3-1.5l-1.9,0.1l-0.3,0.3L646,375.8L646,375.8z M640.2,372.2c0.1-1-0.1-1.8-0.7-2.5
	c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0.1-2.5,0.5c-0.8,0.6-1.2,1.4-1.4,2.2c-0.1,1,0,1.8,0.5,2.5l0.1,0.1c0.5,0.7,1.2,1.1,2.1,1.2
	c1,0.1,1.8-0.1,2.5-0.7l0.5-0.5C639.8,373.3,640.1,372.7,640.2,372.2L640.2,372.2z M637.1,370c0.4,0.1,0.8,0.3,1.1,0.7
	c0.3,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.7-0.4,0.8l-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.3c-0.5-0.1-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.4-0.8-0.3-1.2c0-0.4,0.3-0.8,0.7-1.1C636.2,370,636.6,369.9,637.1,370L637.1,370z"
          />
          <path
            fill="#EBEBEB"
            d="M638.2,370.5c-0.3-0.4-0.7-0.5-1.1-0.7c-0.5-0.1-1,0-1.2,0.3c-0.4,0.3-0.5,0.7-0.7,1.1c0,0.4,0,0.8,0.3,1.2
	c0.3,0.4,0.7,0.5,1.1,0.7c0.4,0.1,0.8,0,1.2-0.3l0.3-0.3c0.3-0.3,0.4-0.5,0.4-0.8C638.6,371.4,638.4,371,638.2,370.5L638.2,370.5z
	 M638.3,377.4c-0.1-1.2-0.5-1.8-1.1-1.6c-1.1-0.3-1.7,0.3-1.8,1.4l-1.8,5.9l-0.7,1.5l4,0.3l3.6-0.3c-0.3-0.4-0.4-1-0.5-1.4
	L638.3,377.4z M663.9,390.5l-2.5-1l-0.4-0.7c-2.1,1.9-4.3,3.4-6.5,4.8l0.4,0.5l0.1,2.6l0.1,0.1C658.2,395.3,661.1,393.1,663.9,390.5
	L663.9,390.5z M653.3,397.8l-0.1-0.1l-3.4-1.1l-0.4-0.5c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2l-0.4,0.5l-3.3,1.1
	l-0.1,0.1c5.1,2.5,10.6,3.6,16.6,3.6C642.8,401.4,648.3,400.1,653.3,397.8L653.3,397.8z M602.9,343.3l3.4-1l0.8,0.1
	c1.4-1.9,2.8-3.7,4.5-5.4c4.7-4.7,9.9-7.7,15.8-9.2v-0.7l2.1-2.9v0.4l0.1-0.6c-7.7,1.4-14.4,5-20.2,10.7
	C606.8,337.5,604.6,340.3,602.9,343.3L602.9,343.3z M602,345.1l0.5,0.1 M602,345.1c-1.7,3.3-2.8,6.7-3.4,10.5l2.6-0.7l0.7,0.3
	c0.5-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L602,345.1L602,345.1z"
          />
          <path
            fill="#EBEBEB"
            d="M602,345.1l1.7,2.1l0.7,0.3l11.8,3.4c0.4,0.1,0.7,0.3,1.1,0.5c0.5,0.3,1,0.7,1.4,1.1l2.5,2.6
	c0.5,0.5,0.8,1.2,0.5,1.9l-0.3,0.7c-0.1,0.7-0.7,1.1-1.5,1.2l-3.6,0.7l-1.8,0.1l-1.1-0.3l-11.6-4.3l-0.7-0.3l-2.6,0.7l0.4,0.1
	l12.7,4.7c0.8,0.4,1.7,0.7,2.8,0.8c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.3,1.1-0.6,1.4-1l0.1-0.3V359c0-0.5-0.1-1.1-0.4-1.5l-6.1-5.4
	c-0.8-0.8-1.7-1.5-2.5-1.9l-0.1-0.1c-0.8-0.4-1.7-0.8-2.6-1l-12.8-3.6h-0.1L602,345.1L602,345.1z"
          />
          <path
            fill="#656565"
            d="M611.6,337.1c-1.8,1.6-3.2,3.4-4.5,5.4l9.6,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.8,0.4l0.1-0.1
	c1.9-1.8,3.9-3.3,6.1-4.4l-0.1-0.7c-0.1-1.2-0.1-2.5,0-3.6l0.5-10C621.6,329.4,616.3,332.4,611.6,337.1L611.6,337.1z"
          />
          <path
            fill="#EBEBEB"
            d="M627.4,343.7c-0.1-0.5-0.3-1.1-0.4-1.5c-2.2,1.1-4.1,2.5-6.1,4.4l-0.1,0.1c0.4,0.3,0.8,0.5,1.2,0.7l5,3.7
	c0.8,0.8,1.7,1,2.5,0.4c0.6-0.3,0.6-1,0-1.9L627.4,343.7L627.4,343.7z"
          />
          <path
            fill="#656565"
            d="M617.4,351.3c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4c-1.1,2.5-1.9,5-2.5,7.7l11.6,4.3l1.1,0.3
	C614.9,356.7,615.9,353.9,617.4,351.3L617.4,351.3z"
          />
          <path
            fill="#EBEBEB"
            d="M621.7,356.9c0.3-0.7,0-1.4-0.5-1.9l-2.5-2.6c-0.4-0.4-1-0.8-1.4-1.1c-1.5,2.5-2.3,5.2-2.8,8.3l1.8-0.1
	l3.6-0.7c0.8-0.1,1.4-0.5,1.5-1.2L621.7,356.9L621.7,356.9z"
          />
          <path
            fill="#656565"
            d="M614.6,365.5l-0.7-0.1c-1.1-0.3-2.2-0.5-3.4-1.1l-9.2-3.6v1.6c0,4.1,0.5,7.8,1.8,11.4l13.8-1.5
	C615.6,370.3,614.9,367.9,614.6,365.5L614.6,365.5z"
          />
          <path
            fill="#EBEBEB"
            d="M622.3,365.5h-7.7c0.3,2.5,1,4.7,2.1,6.9l0.8-0.1c0.1-0.1,0.4-0.3,0.5-0.4l5.1-3.6c1.1-0.5,1.5-1.1,1.1-1.5
	C624.1,365.7,623.6,365.3,622.3,365.5z M630,362.4c-0.8-0.5-1.7-0.8-2.5-0.5c-1,0.1-1.7,0.5-2.2,1.4l-0.1,0.1
	c-0.4,0.7-0.7,1.5-0.4,2.3c0.1,1,0.5,1.6,1.4,2.2l0.7,0.4c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.6-0.8,0.8-1.6,0.7-2.5
	C631.3,363.8,630.9,363,630,362.4L630,362.4z M626.5,365.5c0-0.4,0-0.8,0.3-1.2c0.3-0.4,0.7-0.5,1.1-0.7c0.4-0.1,0.8,0,1.2,0.3
	c0.4,0.3,0.5,0.7,0.7,1.1c0.1,0.4,0,0.8-0.3,1.2c-0.3,0.4-0.7,0.7-1.1,0.7l-1-0.1l-0.4-0.1C626.7,366.3,626.6,365.9,626.5,365.5
	L626.5,365.5z"
          />
          <path
            fill="#EBEBEB"
            d="M626.7,364.2c-0.3,0.4-0.4,0.8-0.3,1.2c0.1,0.4,0.3,0.8,0.7,1.1l0.4,0.1l1,0.1c0.4,0,0.8-0.3,1.1-0.7
	c0.3-0.4,0.4-0.8,0.3-1.2c-0.1-0.5-0.3-0.8-0.7-1.1s-0.8-0.4-1.2-0.3C627.4,363.5,627,363.8,626.7,364.2L626.7,364.2z M598.5,355.7
	h0.5l-0.5-0.1 M598.3,357.5c-0.1,1.6-0.3,3.2-0.3,5c0,10.2,3.4,19.1,10.3,26.4v-3.6l0.4-0.7c-2.6-3.3-4.5-6.9-5.9-10.7
	c-1.2-3.6-1.8-7.4-1.8-11.4v-1.6l-0.7-0.3L598.3,357.5L598.3,357.5z M609.7,390.2h0.1H609.7z M609.7,390.4v-0.1 M609.7,390.2l2.5-1
	l0.4-0.7l6.9-10.2l0.8-1c0.4-0.4,0.8-0.7,1.4-1l3.3-1.5h1.9l0.3,0.3l0.3,0.3c0.5,0.4,0.8,1,0.7,1.8l-0.4,3.6
	c-0.1,0.7-0.3,1.2-0.5,1.8l-0.5,1l-7.6,9.8l-0.4,0.5l-0.1,2.8l8.7-11c0.5-0.7,1.1-1.4,1.5-2.2v-0.1c0.4-1,0.7-1.9,1-3l1.8-7.8
	c0-0.7-0.3-1.2-0.5-1.5l-0.3-0.3l-0.3-0.1l-1.7-0.1l-6.9,4.1c-1,0.5-1.8,1.1-2.6,1.8v0.1c-0.7,0.7-1.4,1.4-1.8,2.2L609.7,390.2
	L609.7,390.2z"
          />
          <path
            fill="#EBEBEB"
            d="M612.7,388.7l-0.4,0.7l-2.5,1c2.8,2.6,5.6,4.8,8.8,6.6l0.1-2.8l0.4-0.5C616.8,392.1,614.6,390.5,612.7,388.7
	L612.7,388.7z M628.3,377.4c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.3l-0.3-0.3h-1.9l-3.3,1.5c-0.5,0.3-1.1,0.5-1.4,1l0.7,0.7
	c1.9,1.9,4,3.4,6.2,4.4c0.3-0.5,0.4-1.1,0.5-1.8L628.3,377.4L628.3,377.4z M617,372.8l0.5-0.5l-0.8,0.1 M620.4,397.5l0.3-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M637.5,303.4c10,0,19.3,2.2,27.5,6.7c2.1,1.1,4.1,2.5,6.2,3.9l5.8-5c-11.4-8.1-24.5-12.2-39.5-12.2
	c-15.5,0-29.3,4.4-41,13.2c-2.1,1.5-4,3.2-5.9,5c-5.8,5.4-10.2,11.4-13.3,17.7c-2.2,4.5-3.9,9.2-4.8,14.3c-0.3,1.8-0.4,3.6-0.5,5.2
	c-0.1,1.5-0.1,3-0.1,4.5h6.7c0-1.5,0.3-3,0.4-4.5c2.1-11.8,7.6-22.3,16.4-31.2c4.5-4.5,9.4-8.3,14.6-11.1
	C618.2,305.6,627.4,303.4,637.5,303.4L637.5,303.4z M677.1,309.1l-5.8,5c1.4,1,2.8,2.1,4,3.2c1.5,1.2,2.9,2.6,4.3,4l3.6,4
	c8,9.5,12.4,20.5,13.6,32.9l0.4,5.5l0.7,10l7.2-1l-0.7-9.9l-0.7-9.8c-0.4-3.3-1-6.5-1.7-9.5c-2.3-8.9-6.9-16.9-13.3-24.2
	c-1.2-1.4-2.6-2.8-4-4l-0.1-0.1c-1.9-1.8-3.9-3.4-5.9-5L677.1,309.1L677.1,309.1z"
          />
          <path
            fill="#FFFFFF"
            d="M704.2,362.7l0.7,9.9l1.9-0.3l8.7-1.1h0.1l21.9-2.8l4.4-0.5c3.4-0.7,6.1-1.8,8-3.6l0.8-0.8
	c1.4-1.6,2.2-3.6,2.6-5.9c-15.4,2.6-27.7,4-36.7,4.1L704.2,362.7L704.2,362.7z"
          />
          <path
            fill="#656565"
            d="M762.7,350.5l0.3-2.1h-1.8l-0.3,2.1 M764,352c0-1-0.4-1.4-1.2-1.4h-8.4v0.3l-1.1,6.7
	c-0.4,2.3-1.2,4.3-2.6,5.9c1.2-0.7,2.5-1.6,3.7-3.2l1.5-2.2c2.2-3.9,3.2-4.1,2.9-0.7c-0.3,2.3-1.8,4.8-4.3,7.7
	c2.5-2.8,3.9-5.4,4.3-7.7c0.3-3.4-0.7-3.3-2.9,0.7l-1.5,2.2h2.2l-3.3,6.7c1.7,0,4,1.4,7,4.4c1.7-0.8,2.8-1.8,3.2-2.6
	c1.4-5.1,1.9-9.2,1.7-12.1C763.9,355.1,763.6,353.6,764,352L764,352z M758.5,350.5h1.4l0.3-2.1h-1.4 M754.4,349.8l-0.1,0.7h2.5
	l0.3-0.7"
          />
          <path
            fill="#656565"
            d="M750.5,363.4l-0.8,0.8c-1.9,1.8-4.5,3-8,3.6l-4.4,0.7v4.1h13.1l2.8-5.6l3.3-6.7h-2.2
	C753,361.6,751.8,362.7,750.5,363.4L750.5,363.4z M715.6,371.2c-0.1,0.8,0.7,1.4,2.6,1.4h19.1v-4.1L715.6,371.2z"
          />
          <path
            fill="#FFFFFF"
            d="M367.4,210.1L361,213l5.8,3.2c-23.2,11.7-45.7,24.9-67.3,39.8c-6.7,5-13.5,7.3-20.1,7.3l-14.3-1.2l-2.9,1.7
	l1.4,0.1c12.1,1.2,25.2,2.3,39.2,3.2c0.3-0.5,0.8-1.1,1.2-1.8c2.2-2.9,5.2-6.1,9.5-9.5c0.3-1.9,1.6-4.1,4.1-6.3
	c2.1-1.9,5.2-3.6,9.4-5l2.8-0.4c10.6-6.9,24.1-14.6,40.4-23.1c5.5-2.9,12.5-5.4,20.9-7.4c-7.8,0.8-13.8,0.7-17.7-0.6l-1-0.3
	L367.4,210.1L367.4,210.1z"
          />
          <path
            fill="#FFFFFF"
            d="M366.2,209.6l1.2,0.7l4.8,2.5c18.3-5.8,54.9-9.2,110.1-10.2c25.3-1.1,49.4-0.4,71.9,2.1l0.8-3h-0.3h0.3
	l0.3-0.7c-3.7-0.7-7.6-1.1-11.8-1.5c-33.7-1.1-69.9-0.7-108.4,1C400.3,201.9,377.5,204.9,366.2,209.6L366.2,209.6z"
          />
          <path
            fill="#EBEBEB"
            d="M366.7,216.2L361,213c-31.4,14-63.4,30.4-95.9,49l14.3,1.2c6.7,0,13.5-2.3,20.1-7.3
	C321.1,241.2,343.5,227.9,366.7,216.2L366.7,216.2z"
          />
          <path
            fill="#FFFFFF"
            d="M335.5,251.3l-0.1-0.8c-0.5-2.5-1.2-4.1-2.1-5.1c-0.4-0.3-0.8-0.6-1.2-0.7c1.2,1.1,1.9,3.2,2.1,6.3
	c0,1,0.1,2.1,0,3.3L334,255c-0.7,2.6-2.5,4-5.6,3.9c-7.8,0.7-12.9,1.6-15,2.6c0.3,1.4,0.8,2.3,1.5,2.8c1.2,0.6,3.4,0.7,6.6,0.1
	l4.7-1.1l5.8-0.4c0.8-0.1,1.4-0.4,1.9-0.7C335.9,260.9,336.5,257.3,335.5,251.3L335.5,251.3z"
          />
          <path
            fill="#EBEBEB"
            d="M335.4,250.5l0.1,0.8c1,6.1,0.4,9.6-1.6,10.9c-0.5,0.3-1.1,0.4-1.9,0.7l-0.4,1.6l151.3-5.2l3.4-44h-35.1
	c-25.7,0.1-46.1,1.2-60.9,3.4c-16.6,4.5-34.7,13.5-53.9,27.1C336.3,246.7,335.9,248.3,335.4,250.5L335.4,250.5z"
          />
          <path
            fill="#EBEBEB"
            d="M334.1,254.2v-3.3l-11.6,0.8c-1.9,0-3.2,0.6-3.6,1.6c-0.7,1.9-0.3,2.6,1.2,2.2l13.9-0.7L334.1,254.2
	L334.1,254.2z"
          />
          <path
            fill="#FFFFFF"
            d="M335.4,250.5c0.7-2.2,1-3.7,1-4.7c0-0.7-0.1-1.1-0.4-1.1h-3.9c0.4,0.1,1,0.4,1.2,0.7
	C334.1,246.2,334.8,248,335.4,250.5L335.4,250.5z"
          />
          <path
            fill="#FFFFFF"
            d="M335.9,244.7c0.3,0.1,0.4,0.6,0.4,1.1c19.3-13.6,37.3-22.6,53.9-27.1c14.9-2.1,35.2-3.3,60.9-3.4h35.1
	l0.3-4.3c-21.5-0.1-42.9,0.1-64.4,0.6l-31.2,2.1c-8.4,1.9-15.4,4.4-20.9,7.4c-16.4,8.5-29.9,16.2-40.4,23.1c0.8,0,1.6,0.3,2.5,0.6
	L335.9,244.7L335.9,244.7z"
          />
          <path
            fill="#656565"
            d="M554.9,201.6l-0.8,3c6.6,1.1,14.3,2.9,22.7,5.2l1.9-2.9C571,205.1,563,203.3,554.9,201.6L554.9,201.6z"
          />
          <path fill="#FFFFFF" d="M497.4,211h-10.9l-0.3,4.3l-3.4,44l13.5-0.4" />
          <path fill="#656565" d="M500,211h-2.6l-1.1,47.9l-0.1,1.6l2.6-0.1" />
          <path
            fill="#FFFFFF"
            d="M545,213c-4.7-0.8-9.4-1.2-14-1.5c-10.3-0.3-20.6-0.4-31.1-0.6l-1.1,49.4l42.1-1.6c5.2-0.4,9.8-1.4,13.5-2.9
	l4.5-2.2c8.1-4.4,17.6-11.4,28.6-21c2.5-2.8,0.3-5.6-6.6-8.7l-19.7-7C556,215.2,550.7,214,545,213L545,213z M509.4,222.1
	c0.6-4,3.4-5.9,8.7-5.8c18.8,0.6,34.7,3,47.6,7.6c13.3,4,19,7.7,17.1,11.1c-4,5.2-12.9,11.4-27,18.4c-7.6,3.3-19.8,4.8-36.6,4.8
	c-6.9,0.1-10.5-2.3-10.7-7.6L509.4,222.1L509.4,222.1z"
          />
          <path
            fill="#EBEBEB"
            d="M518.1,216.2c-5.2-0.1-8.1,1.8-8.7,5.8l-1,28.6c0.1,5.2,3.7,7.7,10.7,7.6c16.8,0,29-1.6,36.6-4.8
	c13.9-7,22.8-13.2,27-18.4c2.1-3.4-3.6-7.2-17.1-11.1C552.7,219.2,536.9,216.8,518.1,216.2L518.1,216.2z"
          />
          <path
            fill="#FFFFFF"
            d="M332.1,244.7c-0.8-0.3-1.5-0.6-2.5-0.6l-2.8,0.4c-4.1,1.4-7.3,3-9.4,5c-2.5,2.2-3.9,4.4-4.1,6.3v0.4
	c-0.1,2.2-0.1,4,0,5.4c2.1-1.1,7-1.9,15-2.6c3,0,5-1.2,5.6-3.9l-13.9,0.7c-1.5,0.4-1.9-0.3-1.2-2.2c0.4-1.1,1.5-1.6,3.6-1.6
	l11.6-0.8C333.9,247.8,333.2,245.8,332.1,244.7L332.1,244.7z"
          />
          <path
            fill="#FFFFFF"
            d="M326.3,263.1l-4.7,1.1c-3.2,0.5-5.4,0.4-6.6-0.1c-0.8-0.4-1.4-1.2-1.5-2.8c-0.3-1.4-0.3-3,0-5.4v-0.4
	c-4.1,3.4-7.3,6.6-9.5,9.5l27.6-1l0.4-1.6L326.3,263.1L326.3,263.1z M304,265.3c-0.4,0.5-0.8,1.1-1.2,1.8l193.4-6.5V259l-13.5,0.5
	l-151.2,5.1L304,265.3L304,265.3z"
          />
          <path
            fill="#FFFFFF"
            d="M302.8,267.1c-1.6,3-2.8,6.9-3.3,11.7l200.3-8l-3.6-10.3L302.8,267.1L302.8,267.1z"
          />
          <path
            fill="#FFFFFF"
            d="M299.5,278.8c0.5-4.8,1.6-8.7,3.3-11.7c-14-0.8-27.2-1.9-39.2-3.2c-7.4,4.3-18.7,7.3-33.7,8.9
	c-22.7,2.3-43.3,5.2-61.9,8.9c-9.6,2.2-16.6,5.2-21.3,9.1l17.9-1.8l0.8-0.1l2.9-0.3c28.3-3.9,62-6.7,100.8-8.7L299.5,278.8
	L299.5,278.8z"
          />
          <path
            fill="#FFFFFF"
            d="M262.2,263.8l-1.8-0.1c-32.1,2.5-60.8,5.8-86.4,10c-24.5,4.7-42,10.3-52.1,17.2
	c10.3-6.9,27.7-12.7,52.1-17.2c25.6-4.3,54.3-7.6,86.4-10c-8.1-1-15.7-1.9-22.8-3c-45.5,5.2-81,12.1-106.5,20.9
	C118,286.1,107,293,98.2,302c3.3,0.4,7,0.3,11.3-0.3l7.6-1.2c3.9-0.8,8-1.9,12.4-3.3c4.5-1.1,8.8-2.5,12.9-4.3l4.3-1.9
	c4.7-3.9,11.7-6.9,21.3-9.1c18.6-3.7,39.2-6.6,61.9-8.9c15.1-1.7,26.3-4.5,33.7-8.9L262.2,263.8L262.2,263.8z M266.7,318.1
	c1.5,1.2,2.9,2.6,4.3,4c1.2,1.4,2.5,2.6,3.6,4c7.8,9.5,12.4,20.5,13.5,32.9v1.6h7.2l-0.5-6.9c-1.2-12.7-6.2-23.9-15-33.7l-4-4.1
	c-12.8-12.1-28.3-18.3-46.5-18.3c-18.2,0-33.7,6.1-46.6,18.3c-9.6,9.1-15.5,19.7-18,31.5c-1.5,5.9-2.3,13.2-2.6,21.9
	c-1.1,3.6-3.4,5.4-7,5.2H77.7c0.3,0.4,0.8,0.7,1.7,0.8l3.3,0.4c7.2,1,14.9,1.5,23.1,1.6l13.9,2.2l49.7,2.2v-6.2
	c0.1-8.1,0.6-15,1.4-20.5l0.1-1.9c2.2-11.8,7.6-22.3,16.4-31.2c11.6-11.8,25.6-17.9,42-17.9C243.6,304.4,256.1,308.9,266.7,318.1
	L266.7,318.1z"
          />
          <path
            fill="#656565"
            d="M271.1,322.1c-1.4-1.5-2.9-2.8-4.3-4c-10.6-9.2-23.1-13.8-37.6-13.8c-16.4,0-30.3,5.9-42,17.9
	c-8.8,8.9-14.2,19.4-16.4,31.2l-0.1,1.9c-0.8,5.6-1.2,12.5-1.4,20.5l11.6,0.3c-1-3.7-1.6-7.6-1.8-11.7v-2.1
	c0-10.7,2.9-20.4,8.8-28.8c1.8-2.6,3.9-5,6.2-7.3l4.8-4.3c8.9-7.2,19.4-10.6,31.4-10.6c11.8,0,22.3,3.6,31.2,10.6
	c1.6,1.4,3.3,2.8,4.8,4.3c1.9,1.9,3.6,3.9,5.1,5.8c6.6,8.7,9.9,18.8,9.9,30.3c0,4.7-0.5,9.1-1.6,13.2l-1,3.2l9.4,0.3v-19.8
	c-1.1-12.4-5.6-23.4-13.5-32.9C273.6,324.9,272.4,323.5,271.1,322.1L271.1,322.1z"
          />
          <path
            fill="#656565"
            d="M271.5,332.2c-1.5-2.1-3.2-4-5.1-5.8c-1.5-1.5-3.2-3-4.8-4.3c-8.9-7.2-19.4-10.6-31.2-10.6
	c-12,0-22.3,3.6-31.4,10.6l-4.8,4.3c-2.3,2.3-4.4,4.8-6.2,7.3c-5.9,8.4-8.8,18-8.8,28.8v2.1c0.1,4.1,0.7,8,1.8,11.7
	c1.5,5.4,3.9,10.3,7,15c1.8,2.5,3.9,5,6.2,7.3c8.5,8.5,18.4,13.3,30,14.7l7.4,0.3l5.2-0.4c11.3-1.4,21.2-6.2,29.6-14.6l3.2-3.4
	l1.9-2.5c3.3-4.3,5.8-8.9,7.3-13.9l1-3.2c1.1-4.1,1.6-8.5,1.6-13.2C281.3,351,278,340.8,271.5,332.2L271.5,332.2z M265.2,345.1
	c1.6,3.3,2.8,6.7,3.4,10.5v0.1l0.3,1.8c0.3,1.6,0.3,3.2,0.3,5c0,10.2-3.4,19.1-10.3,26.4l-1.4,1.4l0.1,0.1h-0.1
	c-2.8,2.6-5.6,4.8-8.8,6.6l-1.8,1c-5.1,2.5-10.6,3.6-16.6,3.6c-5.9,0-11.4-1.1-16.4-3.4l-1.8-0.8c-3.2-1.6-6.1-3.9-8.8-6.5l-1.4-1.4
	c-7-7.4-10.6-16.4-10.6-26.7c0-1.7,0-3.2,0.3-4.7l0.3-1.9c0.6-3.7,1.6-7.2,3.3-10.5l1-1.8c1.8-3.2,4-6.1,6.7-8.8
	c5.8-5.8,12.4-9.4,19.9-10.7l1.9-0.3l5.6-0.4l5.4,0.3l1.9,0.3c7.7,1.4,14.4,5,20.2,10.7c2.6,2.6,4.8,5.5,6.5,8.5L265.2,345.1
	L265.2,345.1z"
          />
          <path
            fill="#EBEBEB"
            d="M237.6,324.2l-1.9-0.3l0.6,14c0.1,0.8,0.1,1.8-0.1,2.8l-0.4,1.7h2.3c0-0.4,0.1-0.7,0.1-1.1V341
	c0.1-1,0.1-1.9,0.1-3.2l-0.7-13.2L237.6,324.2L237.6,324.2L237.6,324.2z"
          />
          <path
            fill="#EBEBEB"
            d="M237.7,324.5v0.1l0.7,13.2c0,1.1,0,2.2-0.1,3.2v0.3c0,0.4-0.1,0.7-0.1,1.1h2.1v-0.3l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6l-0.6-10v-0.7l-2.1-2.9V324.5L237.7,324.5z M247.1,346.2l-0.8,0.4l-1.2,0.7l-5,3.7c-0.8,0.8-1.6,1-2.5,0.4
	c-0.6-0.3-0.6-1,0-1.9l2.1-5.8l0.4-1.4H238c-0.1,0.4-0.3,1-0.4,1.4l-2.8,8c0.8-0.3,1.6-0.1,2.3,0.3c0.8,0.4,1.4,1.1,1.6,1.9l0.1,0.8
	c0.1,0.7,0,1.2-0.3,1.8c-0.4,0.8-1.1,1.4-2.1,1.6c-0.8,0.3-1.8,0.3-2.5-0.1c-0.8-0.4-1.4-1.1-1.6-1.9c-0.3-1-0.3-1.8,0.3-2.6
	c0.4-0.8,1.1-1.4,1.9-1.7h0.1l2.8-8c0.1-0.4,0.3-1,0.4-1.4h-2.3c-0.1,0.4-0.4,1-0.6,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7
	c-0.6-0.1-1.1-0.4-1.4-1l-3.2-7.4c-0.3-0.4-0.4-1-0.6-1.4h-2.3c0.1,0.4,0.3,1,0.4,1.4l2.8,7.8l-2.8-7.8c-0.1-0.4-0.3-1-0.4-1.4h-2.1
	l0.3,1.4l2.1,5.8c0.6,1.1,0.4,1.9-0.4,2.5c-0.4,0.4-1,0.3-1.9-0.5l-5-3.7c-0.4-0.3-1-0.7-1.4-0.8l-0.7-0.3c-1.1-0.5-2.2-0.8-3.4-1.2
	l-9.8-2.5l-0.7-0.1L196,344l13.2,3.4c1.1,0.3,2.1,0.7,2.9,1.1l0.3,0.1c0.8,0.4,1.5,0.8,2.1,1.4l6.7,5.1c0-0.8,0.4-1.6,1-2.2
	c0.7-0.7,1.5-1,2.3-1l0.8,0.1c0.7,0.1,1.1,0.4,1.6,0.8c0.7,0.7,1,1.5,1,2.5s-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.5,1s-1.8-0.3-2.3-1
	c-0.7-0.7-1-1.4-1-2.3v-0.3l-6.7-5.1c-0.7-0.5-1.4-1-2.1-1.4l-0.3-0.1c-0.8-0.4-1.8-0.7-2.9-1.1L196,344h-0.3l-1,1.8h0.1l13.5,3.9
	c1,0.1,1.8,0.5,2.5,1h0.1c1,0.5,1.8,1.1,2.5,1.9l6.1,5.4c0.3,0.5,0.6,1.1,0.4,1.7v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-1.8,0.1h8.4
	c0.3-0.7,0.7-1.4,1.2-2.1l0.3-0.3c1-0.8,1.9-1.2,3.2-1.2c1.4,0,2.5,0.5,3.6,1.5c0.6,0.5,1,1.2,1.2,2.1h11.3l-4.7-0.4
	c-0.7-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c0-0.5,0.1-1.1,0.4-1.5l6.1-5.4c0.8-0.8,1.6-1.5,2.5-1.9l0.1-0.1c0.8-0.4,1.6-0.8,2.6-1
	l12.8-3.6h0.1l0.5-0.1l-1-1.8l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5l6.6-5
	c0.7-0.5,1.2-1,1.9-1.2l0.4-0.3c1-0.4,1.9-0.8,2.9-1.1l13.2-3.4l-3.4-1.1l-0.7,0.1l-9.6,2.5C249.3,345.4,248.2,345.8,247.1,346.2
	L247.1,346.2z M268.7,355.7h-0.4l-12.7,4.7c-0.3,0.1-0.7,0.3-1,0.4h5.9l8.1-3.2l0.3-0.1L268.7,355.7L268.7,355.7z"
          />
          <path
            fill="#EBEBEB"
            d="M266.7,360.4l2.1-2.9l-0.3,0.1l-8.1,3.3h5.2l0.4-0.3 M224.6,324.5v-0.5l-1.9,0.3v0.1l-0.8,13.6
	c0,1.1,0,2.1,0.1,3.2v0.4c0,0.3,0.1,0.7,0.3,1h2.3c-0.3-0.5-0.4-1.1-0.6-1.6c-0.1-1-0.1-1.9-0.1-2.8L224.6,324.5L224.6,324.5z"
          />
          <path
            fill="#EBEBEB"
            d="M220.7,327.2v0.8l-0.6,10c-0.1,1.1,0,2.3,0,3.6l0.1,0.7v0.1h2.1c0-0.3-0.1-0.7-0.3-1v-0.4
	c-0.1-1.1-0.1-2.1-0.1-3.2l0.8-13.6L220.7,327.2L220.7,327.2z M191.9,357.9l-0.1-0.1l2.1,2.9l0.4,0.3h5.2l-1.2-0.5l-6.1-2.3"
          />
          <path
            fill="#EBEBEB"
            d="M191.6,357.8l0.3,0.1l0.3,0.1l6.1,2.3l1.2,0.4h5.9c-0.1,0-0.1-0.1-0.3-0.1l-6.6-2.5l-6.5-2.5L191.6,357.8
	L191.6,357.8z"
          />
          <path
            fill="#EBEBEB"
            d="M225.3,362.4c0-0.5,0.1-1.1,0.3-1.5h-8.4l-6.2,0.5c-1.2,0.1-2.2,0.3-3.2,0c-0.8-0.1-1.6-0.3-2.5-0.7h-5.9
	l5,1.9c1,0.4,1.9,0.7,3,1l2.8,0.3l8.3-0.3l-8.3,0.3l-2.8-0.3c-1-0.1-2.1-0.5-3-1l-5-1.9h-5.2l0.3,0.1l3.6,1.4l5.6,2.2
	c1.1,0.4,2.2,0.8,3.4,1.1l0.4,0.1h0.4l3,0.1l3-0.1h1.8c1.2-0.3,1.8,0,1.8,0.5c0.6,1,0.4,1.6-0.8,2.2l-3.2,2.2l-3.2,2.3l-0.4,0.4
	c-0.8,0.8-1.5,1.8-2.2,2.9l-1.9,3h2.2l1.2-1.9c0.6-0.8,1.1-1.6,1.9-2.5l0.1-0.1c0.6-0.7,1.2-1.2,1.9-1.6l7-4.7
	c-0.8-0.3-1.4-1-1.8-1.6c-0.4-0.8-0.6-1.7-0.1-2.6l0.3-0.7c0.3-0.5,0.7-1,1.4-1.2c0.8-0.4,1.6-0.5,2.6-0.1c1,0.3,1.5,0.8,1.9,1.6
	c0.4,0.8,0.6,1.6,0.3,2.6c-0.3,1-0.8,1.5-1.6,1.9c-0.8,0.4-1.6,0.5-2.6,0.3h-0.1l-7,4.7c-0.7,0.5-1.4,1.1-1.9,1.6l-0.1,0.1
	c-0.8,0.8-1.4,1.6-1.9,2.5l-1.2,1.9h2.5l0.4-0.5c0.4-0.8,1-1.5,1.6-2.2c0.7-0.7,1.6-1.2,2.6-1.8l6.9-4.1h1.6l0.3,0.1l0.3,0.3
	c0.4,0.4,0.6,1,0.6,1.5l-1.5,6.7h2.6l1.5-5.2l-1.5,5.2h2.1l0.7-2.1c0.1-1.2,0.6-1.8,1.1-1.6c1.1-0.3,1.7,0.3,1.8,1.4l0.7,2.2h2.1
	l-1.6-5.6c-0.6,0.7-1.2,1.1-2.1,1.2c-1,0.1-1.8-0.1-2.5-0.7l-0.6-0.5c-0.4-0.4-0.7-1-0.8-1.6c-0.1-1,0.1-1.8,0.7-2.5
	c0.6-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2c0.1,1,0,1.8-0.6,2.5l-0.1,0.1l1.6,5.6h2.6l-1.5-6.9
	c0-0.7,0.1-1.2,0.6-1.5l0.3-0.3c0.1-0.1,0.1-0.1,0.3-0.1l1.6-0.1l6.9,4.1c1,0.5,1.8,1.1,2.6,1.8v0.1c0.6,0.5,1,1,1.4,1.5l1,1.4h2.5
	l-1.2-2.1c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7l6.9,4.7c0.7,0.5,1.2,1,1.8,1.5l0.3,0.3
	c0.7,0.7,1.4,1.5,1.9,2.5l1.2,2.1h2.3l-0.8-1.2l-1.2-1.9c-0.7-1.1-1.5-2.1-2.2-2.9l-0.6-0.5l-0.6-0.6l-5.6-4
	c-1.1-0.5-1.5-1.1-1.1-1.5c0.1-1.1,0.7-1.5,1.9-1.2h7.7l0.7-0.1c1.1-0.3,2.2-0.5,3.4-1.1l8.8-3.4h-5.2l-4.3,1.6
	c-1.1,0.4-2.1,0.7-3,0.8l-0.4,0.1l-2.3,0.1l-8.4-0.3c0.6,0.7,0.7,1.5,0.6,2.3c-0.1,1-0.6,1.6-1.4,2.2l-0.7,0.4
	c-0.6,0.3-1.2,0.4-1.8,0.3c-1-0.1-1.6-0.7-2.2-1.4c-0.6-0.8-0.8-1.6-0.7-2.5c0.1-1,0.6-1.6,1.4-2.2c0.8-0.5,1.6-0.8,2.5-0.5
	c1,0.1,1.6,0.5,2.2,1.4l0.1,0.1l8.4,0.3l2.3-0.1l0.4-0.1c1-0.1,1.9-0.4,3-0.8l4.3-1.6h-5.9c-0.6,0.1-1.1,0.3-1.6,0.4
	c-1,0.1-2.1,0.1-3.2,0l-3.3-0.3h-11.3c0.1,0.5,0.1,1,0.1,1.5l-0.1,1.2c-0.1,0.7-0.6,1.4-1,1.9l-0.3,0.4c-0.8,0.8-1.8,1.2-2.9,1.4
	h-1.2c-1.1-0.1-2.1-0.5-2.9-1.4c-1-1-1.4-2.1-1.5-3.3L225.3,362.4L225.3,362.4z"
          />
          <path
            fill="#EBEBEB"
            d="M223.7,379.4l-0.3,1.1c-0.3,1.2-0.6,2.3-1,3c-0.4,0.8-1,1.6-1.5,2.3l-8.8,11l1.8,0.8l0.1-0.1l8.7-10.6
	c0.7-0.8,1.2-1.6,1.8-2.6l0.1-0.3c0.4-0.7,0.7-1.5,1-2.2l0.8-2.6h-2.8L223.7,379.4L223.7,379.4z"
          />
          <path
            fill="#EBEBEB"
            d="M228.4,379.4h-2.1l-0.8,2.6c-0.3,0.8-0.6,1.5-1,2.2l-0.1,0.3c-0.6,1-1.1,1.8-1.8,2.6l-8.7,10.6l3.3-1.1
	l0.4-0.5l6.3-7.7l2.1-2.9l0.4-0.7L228.4,379.4L228.4,379.4z M208.1,379.4h-2.2l-0.3,0.4l-3.2,5.1l-0.4,0.5v3.6"
          />
          <path
            fill="#EBEBEB"
            d="M202.1,389l-0.1,0.1l1.4,1.4l7.4-11.1h-2.6 M255,379.4h-2.2l6.1,9.5l-0.1-3.7l-0.4-0.5 M234.7,379.4h-2.1
	l1.1,3.7c0.1,0.5,0.4,1,0.7,1.5l0.3,0.5c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.8l0.4,0.5l3.3,1.1l-0.3-0.3l-8.4-10.2
	c-0.7-0.8-1.2-1.8-1.8-2.6l-0.1-0.3c-0.4-0.8-0.7-1.5-1-2.3L234.7,379.4L234.7,379.4z"
          />
          <path
            fill="#EBEBEB"
            d="M237.4,380.3l-0.3-1h-2.6l0.7,2.5c0.3,0.8,0.6,1.5,1,2.3l0.1,0.3c0.4,0.8,1,1.6,1.8,2.6l8.4,10.2l0.3,0.3
	l0.1,0.1l1.8-1l-8.8-11c-0.6-0.7-1.1-1.4-1.5-2.2v-0.1C238,382.5,237.7,381.4,237.4,380.3L237.4,380.3z M258.9,388.8l-6.1-9.5h-2.6
	l7.3,10.9 M227.1,358.6l-0.4,0.3c-1,1-1.5,2.2-1.5,3.6v0.3c0.1,1.2,0.6,2.3,1.5,3.3c0.8,0.8,1.8,1.2,2.9,1.4h1.2
	c1.1-0.1,2.1-0.5,2.9-1.4l0.3-0.4c0.6-0.5,0.8-1.2,1-1.9l0.1-1.2c0-1.4-0.6-2.5-1.4-3.6c-1-1-2.2-1.5-3.6-1.5
	C229,357.3,228.1,357.8,227.1,358.6L227.1,358.6z M230.3,359.5l0.8,0.1c0.7,0.1,1.4,0.4,1.8,0.7l0.6,0.4l0.4,0.5l0.3,0.7
	c0.1,0.3,0.1,0.5,0,0.8l-0.1,0.7l-0.4,0.5l-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.5l-1.6,0.1l-1.5-0.3c-0.4-0.1-0.8-0.3-1.2-0.5l-0.6-0.4
	l-0.4-0.5c-0.1-0.3-0.3-0.4-0.4-0.5c-0.1-0.3-0.1-0.5,0-0.8c0-0.3,0-0.4,0.1-0.7l0.4-0.5l0.6-0.4l0.6-0.3
	C228.2,359.7,229.2,359.4,230.3,359.5L230.3,359.5z"
          />
          <path
            fill="#EBEBEB"
            d="M231.1,359.5l-0.8-0.1c-1.1-0.1-2.1,0.1-2.9,0.6l-0.6,0.4l-0.6,0.4l-0.4,0.5c-0.1,0.3-0.1,0.4-0.1,0.7v0.8
	c0,0,0.1,0.4,0.4,0.5l0.4,0.5l0.6,0.4c0.4,0.3,0.7,0.4,1.2,0.5l1.5,0.3l1.6-0.1c0.4-0.1,0.8-0.3,1.2-0.5l1-0.8l0.4-0.5l0.1-0.7v-0.8
	l-0.3-0.5l-0.4-0.5l-0.6-0.4C232.3,360,231.8,359.7,231.1,359.5L231.1,359.5z M230,359.8c0.8,0,1.5,0.1,2.1,0.5
	c0.3,0.1,0.6,0.3,0.7,0.5l0.6,0.8H231l-0.4-1.1C230.6,360.4,230.3,360.1,230,359.8L230,359.8z M226.8,362v0.5c-0.1,0-0.1,0-0.1-0.1
	v-0.5C226.6,362,226.7,361.9,226.8,362l1.2-0.1v0.7h-0.1V362L226.8,362L226.8,362z M228.6,361.9l0.4,0.1l0.3-0.1
	c0.1,0,0.3,0,0.3,0.1h0.3v0.5l-0.1,0.1h-1.2l-0.1-0.1v-0.4l0.1-0.1C228.4,361.9,228.4,361.9,228.6,361.9L228.6,361.9z M230,360.6
	l-0.1,0.8l-0.1,0.1l-0.1,0.1h-3c0.1-0.7,0.6-1.1,1.2-1.5l1-0.4l1.2-0.1L230,360.6L230,360.6z M229.9,361.9h0.1v0.7h-0.1L229.9,361.9
	L229.9,361.9z M231.2,362h-1v-0.1h0.7l0.4-0.1l0.3,0.1c0.1,0,0.1,0,0.1,0.1v0.5l-0.1,0.1h-1.4v-0.1h1.2v-0.4h-0.1L231.2,362
	L231.2,362z M230.3,363l0.3-0.1h2.9c-0.3,0.5-0.7,1.1-1.2,1.5c-0.7,0.4-1.4,0.5-2.1,0.5c-0.1-0.3-0.1-0.5,0-1c0-0.3,0.1-0.5,0.1-0.8
	L230.3,363L230.3,363z M229.2,363.4l0.1,0.5l0.6,0.8l-1.2-0.1c-0.4-0.1-0.7-0.3-1-0.4c-0.7-0.4-1.1-0.8-1.2-1.5h2.5
	C229,363,229,363.3,229.2,363.4L229.2,363.4z M231.9,362l0.7-0.1l0.3-0.1l0.3,0.1l0.1,0.1v0.3h-1.2l0.1,0.1h1.2l-0.1,0.1h-1.4
	l-0.1-0.1L231.9,362l1.2,0.1V362L231.9,362L231.9,362z"
          />
          <path
            fill="#EBEBEB"
            d="M230,359.8c0.3,0.3,0.6,0.5,0.7,1l0.4,1.1h2.3l-0.6-0.8c-0.1-0.3-0.4-0.4-0.7-0.5
	C231.5,360,230.8,359.8,230,359.8L230,359.8z M226.8,362.6V362h1.1v0.5h0.1v-0.7h-1.4c-0.1,0-0.1,0-0.1,0.1v0.5L226.8,362.6
	L226.8,362.6z"
          />
          <path
            fill="#EBEBEB"
            d="M228.9,361.9l-0.4-0.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.1v0.4l0.1,0.1h1.2l0.1-0.1v-0.5h-0.3
	c0,0-0.1-0.1-0.3-0.1L228.9,361.9L228.9,361.9z M228.8,362h0.8v0.4h-1.1V362L228.8,362L228.8,362z"
          />
          <path
            fill="#EBEBEB"
            d="M228.9,362h-0.4v0.4h1V362 M229.9,361.5l0.1-0.8c0-0.3,0-0.5-0.1-0.8l-1.2,0.1l-1,0.4
	c-0.7,0.4-1.1,0.8-1.2,1.5h3l0.1-0.1L229.9,361.5L229.9,361.5z M230,361.9h-0.1v0.7h0.3v-0.3 M230.3,362h1.2v0.4h-1.2v0.1h1.4
	l0.1-0.1v-0.5c0,0,0-0.1-0.1-0.1l-0.3-0.1l-0.4,0.1L230.3,362L230.3,362z M230.6,362.8l-0.3,0.1l-0.1,0.1c-0.1,0.3-0.1,0.5-0.1,0.8
	v1c0.8,0,1.5-0.1,2.1-0.5c0.7-0.4,1.1-1,1.2-1.5L230.6,362.8L230.6,362.8z"
          />
          <path
            fill="#EBEBEB"
            d="M229.5,363.9l-0.1-0.5c-0.1-0.3-0.1-0.4-0.1-0.7h-2.5c0.1,0.7,0.6,1.2,1.2,1.5c0.3,0.1,0.7,0.3,1,0.4
	l1.2,0.1L229.5,363.9L229.5,363.9z M231.9,361.9l0.6,0.1h0.7v0.1h-1.2v0.1l0.1,0.1h1.4l0.1-0.1h-1.2l-0.1-0.1h1.2v-0.3l-0.1-0.1
	h-0.6L231.9,361.9L231.9,361.9z M260.1,342.5l0.7-0.1l3.4,1.1h0.1c-1.8-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7v0.3v-0.3
	l2.1,2.9v0.7c5.9,1.5,11.1,4.5,15.8,9.2C257.2,338.9,258.8,340.7,260.1,342.5L260.1,342.5z M265.2,345.1l-0.5,0.1L265.2,345.1"
          />
          <path
            fill="#EBEBEB"
            d="M265.2,345.1l-0.5,0.1h-0.1l-12.8,3.6c-1,0.3-1.8,0.5-2.6,1l-0.1,0.1c-0.8,0.4-1.6,1.1-2.5,1.9l-6.1,5.4
	c-0.4,0.5-0.6,1.1-0.4,1.5v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.1,3.2,0c1-0.1,1.9-0.4,2.8-0.8l12.7-4.7l0.4-0.1
	l-2.6-0.7l-0.7,0.3l-11.6,4.3l-1.2,0.3l-1.8-0.1l-3.6-0.7c-0.8-0.1-1.2-0.5-1.5-1.2l-0.3-0.7c-0.1-0.7,0-1.4,0.6-1.9l2.5-2.6
	c0.4-0.4,1-0.8,1.4-1.1s0.8-0.4,1.1-0.5l11.8-3.4l0.7-0.3L265.2,345.1L265.2,345.1z"
          />
          <path
            fill="#EBEBEB"
            d="M265.2,345.1l-1.6,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C268,351.8,266.9,348.4,265.2,345.1
	L265.2,345.1z M195.3,345.4c-1.8,3.3-2.9,6.7-3.4,10.5l2.6-0.7l0.7,0.3c0.6-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L195.3,345.4L195.3,345.4z"
          />
          <path
            fill="#EBEBEB"
            d="M195.3,345.4l1.8,2.1l0.7,0.3l3.9,1.1l8.1,2.3c0.4,0.1,0.7,0.3,1,0.4c0.6,0.3,1.1,0.7,1.5,1.1l1.7,1.8
	l0.8,0.8c0.6,0.5,0.8,1.2,0.6,1.9l-0.3,0.7c-0.1,0.4-0.4,0.8-0.8,1l-0.7,0.3l-2.3,0.4l-1.2,0.3h-1.8l-0.8-0.1l-0.3-0.1l-8.3-3h-0.1
	l-3.3-1.2l-0.7-0.3l-2.6,0.7l6.5,2.5l6.6,2.5c0.8,0.4,1.8,0.7,2.8,0.7c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.1,1.1-0.6,1.4-1l0.1-0.3V359
	c0-0.5-0.1-1.1-0.4-1.6l-6.1-5.4c-0.8-0.8-1.6-1.5-2.5-1.9h-0.1c-0.8-0.4-1.6-0.8-2.5-1L195.3,345.4L195.3,345.4z M195.3,345.4h-0.1
	H195.3z M196,343.6h0.3l3.4-1.1l0.7,0.1c1.4-1.9,2.9-3.9,4.7-5.6c4.5-4.5,9.8-7.7,15.5-9.2v-0.8l2.1-2.9v-0.1
	c-7.6,1.4-14.2,5-19.9,10.7C200,337.7,197.8,340.6,196,343.6L196,343.6z"
          />
          <path
            fill="#656565"
            d="M220.7,327.9c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3
	l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3l-0.1-0.7c-0.1-1.2-0.1-2.5,0-3.6L220.7,327.9L220.7,327.9z"
          />
          <path
            fill="#EBEBEB"
            d="M220.7,343.7l-0.4-1.5c-2.1,1.1-4,2.5-5.9,4.3l-0.3,0.3c0.4,0.3,1,0.5,1.4,0.8l5,3.7
	c0.8,0.8,1.5,1.1,1.9,0.5c0.8-0.5,1-1.4,0.4-2.5L220.7,343.7L220.7,343.7z M235.6,323.8l-1.5,2.2v0.8l0.4,12.2l-0.1,1.1
	c-0.1,0.5-0.3,1.1-0.7,1.6l-1.8,3.2c-0.4,0.7-1,1.1-1.6,1.1h-0.7c-0.7,0-1.2-0.3-1.6-1.1l-1.8-3.2c-0.3-0.5-0.6-1.1-0.7-1.6
	l-0.1-1.2l0.4-12.2v-0.8l-1.5-2.2v0.5l-0.6,13.5c-0.1,0.8-0.1,1.8,0.1,2.8c0.1,1,0.6,1.9,1.1,3l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.7
	c0.6-0.1,1-0.4,1.4-1l3.2-7.4c0.6-1.1,1-2.1,1.1-3v-0.1c0.1-1,0.3-1.9,0.1-2.8L235.6,323.8L235.6,323.8z"
          />
          <path
            fill="#EBEBEB"
            d="M234.1,326.1l1.5-2.3l-5.4-0.3l-5.6,0.4l1.5,2.2v0.8l4.1-0.3l3.9,0.3 M224.6,323.9v0.5"
          />
          <path
            fill="#656565"
            d="M230.3,326.7l-4.1,0.3l-0.4,12.2l0.1,1.1l4.4-0.4l4.3,0.4l0.1-1.1l-0.6-12.2"
          />
          <path
            fill="#EBEBEB"
            d="M226.4,341.9l1.8,3.2c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.6-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6
	l-4.3-0.4l-4.4,0.4C226,340.8,226.2,341.4,226.4,341.9L226.4,341.9z"
          />
          <path
            fill="#656565"
            d="M240.2,341.5l-0.1,0.7c2.2,1.1,4.1,2.5,6.1,4.4l0.1,0.1l0.8-0.4c1.1-0.4,2.2-0.8,3.4-1.2l9.6-2.5
	c-1.4-1.9-2.8-3.7-4.5-5.4c-4.7-4.7-9.9-7.7-15.8-9.2l0.6,10C240.5,339,240.3,340.3,240.2,341.5L240.2,341.5z"
          />
          <path
            fill="#EBEBEB"
            d="M240.2,342.1l-0.4,1.5l-2.1,5.8c-0.6,1.1-0.6,1.6,0,1.9c0.8,0.7,1.6,0.4,2.5-0.4l5-3.7l1.2-0.7h-0.1
	C244.3,344.7,242.4,343.2,240.2,342.1L240.2,342.1z M248.6,352.4l-2.5,2.6c-0.6,0.5-0.7,1.2-0.6,1.9l0.3,0.7
	c0.1,0.7,0.7,1.1,1.5,1.2l3.6,0.7l1.8,0.1c-0.3-3-1.2-5.6-2.8-8.3C249.4,351.7,249,352,248.6,352.4L248.6,352.4z M242.4,365.7
	c0.1-0.8,0-1.6-0.6-2.3l-0.1-0.1c-0.6-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5
	c0.6,0.7,1.2,1.2,2.2,1.4c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4C241.8,367.2,242.2,366.6,242.4,365.7L242.4,365.7z M239.4,363.5
	c0.4,0.1,0.8,0.3,1.1,0.7c0.3,0.4,0.4,0.8,0.3,1.2s-0.3,0.8-0.7,1.1l-0.4,0.1c-0.3,0.1-0.6,0.3-1,0.1c-0.4,0-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.3-0.8-0.3-1.2c0-0.4,0.3-0.8,0.7-1.1C238.5,363.5,238.9,363.4,239.4,363.5L239.4,363.5z"
          />
          <path
            fill="#EBEBEB"
            d="M240.5,364.2c-0.3-0.4-0.7-0.5-1.1-0.7c-0.6-0.1-1,0-1.2,0.3c-0.4,0.3-0.6,0.7-0.7,1.1c0,0.4,0,0.8,0.3,1.2
	c0.3,0.4,0.7,0.7,1.1,0.7c0.3,0.1,0.7,0,1-0.1l0.4-0.1c0.4-0.3,0.6-0.7,0.7-1.1C240.9,365,240.7,364.6,240.5,364.2L240.5,364.2z
	 M242.9,366.7c-0.3,0.5,0.1,1,1.1,1.5l5.6,4l0.7,0.1c1.1-2.2,1.8-4.4,2.1-6.9h-7.7C243.6,365.3,243.1,365.7,242.9,366.7L242.9,366.7
	z"
          />
          <path
            fill="#656565"
            d="M252.6,359.5l1.2-0.3l11.6-4.3c-0.5-2.8-1.4-5.2-2.5-7.7l-11.8,3.4c-0.4,0.1-0.7,0.3-1.1,0.5
	C251.3,353.9,252.3,356.7,252.6,359.5L252.6,359.5z M253.3,365.3l-0.7,0.1c-0.3,2.5-1,4.7-2.1,6.9l-0.1,0.4l0.6,0.5
	c0.7,0.8,1.4,1.8,2.2,2.9l1.2,1.9l8.3,0.1c0.7-1.4,1.2-2.9,1.8-4.4c1.2-3.6,1.8-7.4,1.8-11.4v-1.6l-9.2,3.6
	C255.6,364.8,254.4,365.2,253.3,365.3L253.3,365.3z"
          />
          <path
            fill="#EBEBEB"
            d="M234.7,351.7c-1,0.3-1.5,0.8-1.9,1.6c-0.4,0.8-0.6,1.6-0.3,2.6c0.3,1,0.8,1.5,1.6,1.9
	c0.8,0.4,1.7,0.4,2.5,0.1c1-0.3,1.5-0.8,2.1-1.6c0.3-0.6,0.4-1.1,0.3-1.8l-0.1-0.8c-0.3-0.8-0.8-1.5-1.6-1.9
	c-0.8-0.4-1.7-0.5-2.3-0.3L234.7,351.7L234.7,351.7z M235,356.4c-0.4-0.3-0.7-0.5-0.8-1c-0.1-0.4-0.1-0.8,0.1-1.2
	c0.1-0.4,0.6-0.7,1-0.8l1.2,0.1c0.4,0.1,0.7,0.5,0.8,1v0.4c0,0.3,0,0.5-0.1,1s-0.6,0.7-1,0.8L235,356.4L235,356.4z"
          />
          <path
            fill="#EBEBEB"
            d="M234,355.4c0.1,0.4,0.4,0.8,0.8,1l1.2,0.1c0.4-0.1,0.8-0.4,1-0.8c0.1-0.3,0.1-0.7,0.1-1v-0.4
	c-0.1-0.4-0.4-0.8-0.8-1l-1.2-0.1c-0.4,0.1-0.8,0.4-1,0.8C234,354.6,233.9,355,234,355.4z M228.2,355c0-1-0.3-1.8-1-2.5
	c-0.6-0.4-1-0.7-1.6-0.8l-0.8-0.1c-1,0-1.6,0.3-2.3,1c-0.6,0.7-1,1.4-1,2.2v0.3c0,1,0.3,1.8,1,2.3c0.7,0.7,1.4,1,2.3,1
	s1.8-0.4,2.5-1C227.9,356.8,228.2,356,228.2,355L228.2,355z M225.2,353.4c0.3,0,0.6,0.3,0.8,0.4c0.3,0.3,0.6,0.7,0.6,1.2
	c0,0.4-0.1,0.8-0.6,1.2c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.1-1,0.4-1.2
	c0.3-0.3,0.7-0.5,1.2-0.5L225.2,353.4L225.2,353.4z"
          />
          <path
            fill="#EBEBEB"
            d="M226,353.8c-0.1-0.3-0.6-0.4-0.8-0.4h-0.4c-0.4,0-0.8,0.1-1.2,0.5c-0.3,0.3-0.4,0.7-0.4,1.2
	c0,0.4,0.1,0.8,0.4,1.2c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.3-0.3,0.6-0.7,0.6-1.2S226.4,354.2,226,353.8L226,353.8z
	 M223.1,368.3c0.8-0.4,1.4-1.1,1.6-1.9c0.3-1,0.1-1.8-0.3-2.6s-1.1-1.4-1.9-1.6c-1-0.3-1.8-0.3-2.6,0.1c-0.7,0.3-1.1,0.7-1.4,1.2
	l-0.3,0.7c-0.3,1-0.3,1.8,0.1,2.6c0.4,0.8,1,1.4,1.8,1.6h0.1C221.5,368.8,222.3,368.8,223.1,368.3L223.1,368.3z M222.2,363.7
	c0.4,0.1,0.8,0.4,1,0.8c0.3,0.4,0.3,0.8,0.1,1.4c-0.1,0.4-0.4,0.7-0.8,1h-1.2c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.4-0.3-0.8-0.1-1.2
	l0.1-0.4c0.1-0.3,0.3-0.4,0.7-0.7L222.2,363.7L222.2,363.7z"
          />
          <path
            fill="#EBEBEB"
            d="M223.1,364.5c-0.1-0.4-0.6-0.7-1-0.8l-1.2,0.1c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.4c-0.1,0.4-0.1,1,0.1,1.2
	c0.3,0.4,0.6,0.7,1,0.8l1.2-0.1c0.4-0.3,0.7-0.5,0.8-1C223.4,365.5,223.3,364.9,223.1,364.5L223.1,364.5z M236.5,370.7l-0.3,0.3
	c-0.4,0.4-0.6,0.8-0.6,1.5l1.8,7.8c0.1,1.1,0.6,2.2,1,3v0.1c0.4,0.8,1,1.6,1.5,2.2l8.8,11l-0.1-2.8l-0.4-0.5l-7.6-9.8l-0.6-1
	c-0.3-0.5-0.4-1.1-0.6-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.6-0.4h1.9l3.3,1.5c0.6,0.3,1.1,0.6,1.4,1l0.3,0.3l0.6,0.7l6.9,10.2
	l0.6,0.7l2.3,1l-8.1-12.2c-0.4-0.5-0.8-1.1-1.4-1.5v-0.1c-0.8-0.7-1.6-1.2-2.6-1.8l-6.9-4.1l-1.6,0.1
	C236.7,370.5,236.6,370.5,236.5,370.7L236.5,370.7z M233.2,373.6c0.6-0.8,0.7-1.6,0.6-2.5c-0.1-1-0.6-1.6-1.4-2.2
	c-0.8-0.5-1.6-0.7-2.5-0.5c-1,0.1-1.6,0.5-2.2,1.4c-0.6,0.7-0.8,1.6-0.7,2.5c0.1,0.7,0.4,1.2,0.8,1.6l0.6,0.5
	c0.7,0.5,1.5,0.8,2.5,0.7c0.8-0.1,1.5-0.6,2.1-1.2L233.2,373.6L233.2,373.6z M231.4,370.3c0.4,0.3,0.6,0.7,0.7,1.1s0,0.8-0.3,1.2
	c-0.3,0.4-0.7,0.5-1.1,0.7c-0.4,0-0.8,0-1.2-0.3l-0.3-0.3c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.5,0-1,0.3-1.2c0.3-0.4,0.7-0.6,1.1-0.7
	C230.6,369.9,231,370,231.4,370.3L231.4,370.3z"
          />
          <path
            fill="#EBEBEB"
            d="M232.1,371.4c0-0.4-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.2-0.3c-0.6,0.1-0.8,0.3-1.1,0.7
	c-0.3,0.4-0.4,0.8-0.3,1.2c0,0.3,0.1,0.7,0.4,0.8l0.3,0.3c0.4,0.3,0.8,0.4,1.2,0.3c0.6-0.1,0.8-0.3,1.1-0.7
	C232.1,372.2,232.2,371.8,232.1,371.4L232.1,371.4z M240.2,375.2l-0.6,0.4c-0.6,0.4-0.8,1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.6,1.8
	c2.2-1.1,4.4-2.6,6.2-4.4l0.4-0.4l0.3-0.3c-0.4-0.4-0.8-0.7-1.4-1l-3.3-1.5H240.2L240.2,375.2z M224.1,370.7h-1.6l-6.9,4.1
	c-1.1,0.5-1.9,1.1-2.6,1.8c-0.7,0.7-1.2,1.4-1.6,2.2l-7.8,11.7l2.5-1l0.4-0.7l6.9-10.2l0.7-0.8c0.4-0.4,1-0.7,1.5-1.1l3.3-1.5
	l1.9,0.1l0.3,0.3l0.3,0.1c0.6,0.4,0.8,1.1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8l-0.6,1l-7.6,9.8l-0.4,0.5l-0.1,2.6l8.8-11
	c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.7-1.8,1-3l1.8-7.8c0-0.7-0.1-1.1-0.6-1.5l-0.3-0.3L224.1,370.7L224.1,370.7z M230.1,375.8
	c-0.6-0.1-1,0.4-1.1,1.6l-2.3,7.3l3.6,0.3l4-0.3c-0.3-0.5-0.4-1.1-0.7-1.5l-1.8-5.9C231.8,375.9,231.1,375.5,230.1,375.8
	L230.1,375.8z M250.4,372.8l0.1-0.4l-0.7-0.1"
          />
          <path
            fill="#EBEBEB"
            d="M247.2,377.8l-0.3-0.3l-0.3,0.3 M258.3,384.6l0.4,0.7v3.6c6.9-7.4,10.3-16.2,10.3-26.4c0-1.6-0.1-3.3-0.3-5
	l-0.3,0.1l0.3-0.1l-2.2,2.9l-0.7,0.3v1.6c0,4.1-0.5,7.8-1.8,11.4c-0.5,1.5-1.1,3-1.8,4.4C261.4,380.5,260,382.5,258.3,384.6
	L258.3,384.6z M257.5,390.2v0.1 M257.4,390.2h0.1 M257.4,390.2l-2.3-1l-0.6-0.7c-2.1,1.9-4.1,3.4-6.5,4.8l0.4,0.5l0.1,2.8
	C251.7,395.2,254.8,393,257.4,390.2L257.4,390.2z M214.5,355.3l-0.8-0.8c0.1,1.1,0.3,2.6,0.3,4.4c0.4-0.3,0.7-0.5,0.8-1l0.3-0.7
	C215.3,356.5,215,356,214.5,355.3L214.5,355.3z"
          />
          <path
            fill="#656565"
            d="M210.5,351.6c-0.3-0.3-0.6-0.3-1-0.4l-8.1-2.3v1.2h1.9c2.1,0.1,3.2,0.7,3.3,1.8c0.3,1,1,1.4,1.9,1.2
	c0.4,0,0.7,0.1,1.1,0.3c0.7,0.4,1.2,1.5,1.4,3.2v3l2.3-0.4l0.7-0.1c-0.1-1.8-0.1-3.2-0.3-4.4l-1.6-1.8
	C211.6,352.3,211,351.8,210.5,351.6L210.5,351.6z"
          />
          <path
            fill="#656565"
            d="M205.8,354.3c0.8,0.1,1.5,1,2.2,2.3h0.6c0.3-1.2,0.7-2.3,1.2-3.4c-0.3-0.1-0.7-0.3-1.1-0.3
	c-1.1,0.1-1.6-0.3-1.9-1.2c-0.1-1.1-1.2-1.6-3.3-1.8h-1.9v-1.2l-3.9-1.1c-1.1,2.5-1.9,5-2.5,7.7l3.3,1.2l0.1-1.4l0.3-1.8
	c0.4-1.1,1.4-1.9,2.8-2.6c1.1-0.4,1.7-0.3,1.7,0.6C203.5,352.8,204.3,353.9,205.8,354.3L205.8,354.3z"
          />
          <path
            fill="#656565"
            d="M208,356.7c-0.7-1.4-1.4-2.2-2.2-2.3c-1.7-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6
	l-0.3,1.8l-0.1,1.4l8.3,3l0.3,0.1c0.1-0.8,0.6-1.4,1.1-1.8c0.7-0.4,1.5-0.5,2.8-0.4v-0.8L208,356.7L208,356.7z"
          />
          <path
            fill="#EBEBEB"
            d="M211,356.4c-0.1-1.6-0.6-2.8-1.4-3.2c-0.6,1.1-1,2.2-1.2,3.4h2.5L211,356.4L211,356.4z"
          />
          <path
            fill="#656565"
            d="M209.8,359.8l1.2-0.3v-1.9c-1.2-0.1-2.2,0-2.8,0.4s-1,1-1.1,1.8l0.8,0.1L209.8,359.8L209.8,359.8z"
          />
          <path fill="#EBEBEB" d="M208,365.7h-0.4l0.4,0.4" />
          <path
            fill="#656565"
            d="M208,366.1l-0.4-0.4l-0.4-0.1c-1.2-0.3-2.3-0.7-3.4-1.1l-5.6-2.2l0.3,4.3l0.3,2.2l0.3,1.8
	c0.4,1.1,1.4,1.9,2.8,2.6c1.1,0.6,1.6,0.3,1.6-0.4c0-1.5,0.8-2.5,2.3-3c0.8-0.1,1.4-1,2.1-2.2l0.1-0.1h3v-0.8
	C209.6,366.7,208.7,366.6,208,366.1L208,366.1z"
          />
          <path
            fill="#656565"
            d="M208,365.7v0.3c0.7,0.4,1.6,0.5,2.9,0.4v-0.7L208,365.7L208,365.7z"
          />
          <path fill="#EBEBEB" d="M208,367.4l-0.1,0.3h0.6l-0.1-0.3" />
          <path
            fill="#EBEBEB"
            d="M211,367.4h-2.8l0.1,0.3c0.3,1.1,0.6,2.2,1,3.3c1-0.4,1.4-1.5,1.6-3.3L211,367.4L211,367.4z"
          />
          <path
            fill="#656565"
            d="M211,366.6v1.1c-0.1,1.8-0.7,2.9-1.6,3.3l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9
	l0.6,2.8l0.7,3.3l3-0.3l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3c0.1-1.2,0.3-2.9,0.4-5l-3,0.1L211,366.6L211,366.6z"
          />
          <path
            fill="#656565"
            d="M208.4,367.7l-0.6-0.1c-0.7,1.2-1.2,2.1-2.1,2.2c-1.6,0.5-2.3,1.5-2.3,3c0,0.8-0.6,1-1.6,0.4
	c-1.4-0.7-2.3-1.5-2.8-2.6l-0.3-1.8c-0.1-0.7-0.3-1.5-0.3-2.2l-3.7-0.4c0.4,3.7,1.2,7.2,2.8,10.5l4.5,0.1l-0.6-2.8h1.9
	c2.1-0.1,3.2-0.7,3.3-1.8c0.3-1,1-1.4,1.9-1.2l0.8-0.1C209,369.9,208.5,368.9,208.4,367.7L208.4,367.7z"
          />
          <path
            fill="#EBEBEB"
            d="M215.7,365.7h-1.8c0,2.1-0.1,3.7-0.4,5l3.2-2.2c1.1-0.6,1.4-1.2,0.8-2.2
	C217.5,365.7,216.9,365.6,215.7,365.7L215.7,365.7z M198.5,356.7l0.1-1.4 M192.2,358l-0.3-0.1 M191.9,357.9l-0.3-0.1 M191.6,357.8
	c-0.3,1.5-0.3,3-0.3,4.5c0,10.3,3.6,19.3,10.6,26.7v-3.7l0.3-0.6c-2.1-2.6-3.7-5.2-5-8.1c-1.4-3.3-2.3-6.7-2.8-10.5
	c-0.1-1.2-0.1-2.5-0.1-3.9v-1.4l-0.7-0.3L191.6,357.8l0.3,0.1L191.6,357.8L191.6,357.8z"
          />
          <path
            fill="#656565"
            d="M194.7,360.9v1.4c0,1.4,0,2.6,0.1,3.9l3.7,0.4c-0.1-1.4-0.1-2.8-0.1-4.3L194.7,360.9L194.7,360.9z"
          />
          <path
            fill="#EBEBEB"
            d="M198.4,366.6l-0.1-4.3C198.2,363.8,198.2,365.3,198.4,366.6L198.4,366.6z M198.5,366.6
	c0,0.8,0.1,1.5,0.3,2.2L198.5,366.6L198.5,366.6z M213.9,377.8l0.4,0.4c2.1,2.1,4.1,3.6,6.6,4.7c0.3-0.6,0.4-1.1,0.6-1.8l0.4-3.6
	c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.1l-0.3-0.3l-1.9-0.1l-3.3,1.5C214.9,377.2,214.3,377.4,213.9,377.8L213.9,377.8z M206.3,388.8
	l-0.4,0.7l-2.5,1c2.8,2.6,5.6,4.8,8.8,6.5v-0.1l0.1-2.6l0.4-0.5C210.5,392.3,208.3,390.8,206.3,388.8L206.3,388.8z M214.1,397.6
	l-0.1,0.1c5,2.3,10.5,3.4,16.4,3.4c6.1,0,11.6-1.2,16.6-3.6l-0.1-0.1l-3.3-1.1l-0.4-0.5c-4,1.5-8.3,2.2-12.8,2.2
	c-4.4,0-8.5-0.7-12.4-2.1l-0.4,0.5L214.1,397.6L214.1,397.6z M246.7,397.2l0.1,0.3 M268.7,355.7v-0.1l-0.4,0.1"
          />
          <path
            fill="#FFFFFF"
            d="M275.8,316.1l4,4.1c8.8,9.8,13.8,21,15,33.7l0.5,6.9h9.2c-4.8-30.1-6.5-57.5-5.1-82l-30.4,1.4
	c-38.8,1.9-72.4,4.8-100.8,8.7c1.4,0,2.3,0.3,2.9,0.5l0.6,0.5c0.1,0.3,0,0.8-0.4,1.4c-2.9,2.9-5.9,5.8-9.1,8.5
	c-3.7,3.2-7.7,6.2-12,9.1c-2.1,1.4-5.4,2.3-9.9,3l-27.4,4.5l-2.5,0.4l-8.7,1.4c-2.5,0.5-3.3,0.3-2.6-0.5c3.4-3.2,7-5.6,10.5-7.7
	c1.2-0.7,2.6-1.4,3.9-1.9l-0.4,0.1l-13.3,2.1l-0.3,0.3l-12.8,8.3H88c1.2,0.1,2.1,1.9,2.5,5.1l0.6,2.1l0.6,1.8l0.4,1.5l0.6,1.8
	l1.4,4.8l0.6,1.8l0.4,1.5l0.6,1.8l1,3.6l0.1,1.1l0.3,1.8l0.1,1.5v1.8l-0.3,2.2l-0.8,2.3l-0.8,1.8l-0.8,1.5l-1.1,1.8l-0.1,0.1
	l-1.1,1.5l-2.8,3.3c-1.5,1.1-3.7,1.5-6.6,1.2c-1.9,0.5-3.6,2.6-5,6.2c-0.4,1-0.4,1.8,0,2.3h77.2c3.6,0.1,5.9-1.7,7-5.2
	c0.3-8.7,1.2-16,2.6-21.9c2.5-11.8,8.4-22.4,18-31.5c12.9-12.1,28.5-18.3,46.6-18.3C247.5,297.8,263,304,275.8,316.1L275.8,316.1z
	 M141,324.2c0.1,0.7,0.1,1.5,0,2.5l-2.6,21.9l-0.4,3.6l-0.4,3.7l-0.1,1.8v0.3c-0.6,3.6-2.8,5.8-6.5,6.5c-4.3,0.7-7,0-8.4-2.2
	l-0.4-0.7v-0.4c-0.1-0.8-0.3-1.6-0.4-2.3c-0.1-0.7-0.1-1.2-0.1-1.8c0-0.7,0-1.2,0.1-1.8c0-0.5,0.1-1.1,0.3-1.6
	c0.1-0.4,0.3-1,0.4-1.4l0.4-0.8l0.8-1.6c1.2-2.1,2.5-3.2,3.9-3.2l6.1-1.7l3-15.8c0.3-1.2,0.6-2.1,1-2.6l0.4-0.5L141,324.2
	c-0.1-1-0.7-1.4-1.6-1.4C140.2,322.8,140.7,323.2,141,324.2L141,324.2z"
          />
          <path
            fill="#656565"
            d="M140.9,326.7c0.1-1.1,0.1-1.9,0-2.5l-3,1.8l-0.4,0.5l-2.1,15.8c-0.3,1.8-1,2.6-1.8,2.6l-6.1,1.6
	c-1.2,0-2.5,1.1-3.8,3.2l2.9-0.5c3.7-0.6,7.6-0.8,11.8-0.7L140.9,326.7L140.9,326.7z"
          />
          <path
            fill="#EBEBEB"
            d="M135.4,342.4l2.2-15.8c-0.4,0.5-0.8,1.5-1,2.6l-3,15.8C134.4,345,135,344.1,135.4,342.4L135.4,342.4z"
          />
          <path
            fill="#656565"
            d="M138,352.3l-4.3,0.1c-3.2,0.1-6.2,0.3-9.1,0.8c-0.1,0.5-0.3,1-0.4,1.5c2.8-0.4,5.8-0.7,8.8-0.7h4.1H133
	c-3.2,0-6.1,0.3-8.8,0.7l-2.5,0.4c0,0.5-0.1,1.2-0.1,1.8l2.3-0.4c2.9-0.4,5.9-0.7,9.1-0.8l4.5,0.1L138,352.3L138,352.3z"
          />
          <path
            fill="#656565"
            d="M133.6,352.4l4.3-0.1l0.4-3.6c-4.3-0.1-8.3,0.1-11.8,0.7c-0.4,0.5-0.7,1.1-1.1,1.6c3.7-0.6,7.8-0.8,12.1-0.7
	c-4.4-0.1-8.4,0.1-12.1,0.7l-2.8,0.5l-0.4,0.8c-0.1,0.4-0.3,0.8-0.4,1.4l2.6-0.4C127.4,352.7,130.4,352.4,133.6,352.4L133.6,352.4z
	 M137.4,355.8l-4.5-0.1c-3.2,0.1-6.2,0.3-9.1,0.8v1.8c2.9-0.4,5.9-0.7,9.1-0.8h4.3L137.4,355.8L137.4,355.8z"
          />
          <path
            fill="#656565"
            d="M137.3,357.6l-4.3-0.1c-3.2,0.1-6.2,0.3-9.1,0.8l-2.2,0.4c0,0.8,0.1,1.5,0.4,2.3l2.1-0.4l8.9-0.7h3.3h-3.3
	l-8.9,0.7l0.1,1.2l8.3-0.7h3.3h-3.3l-8.3,0.7l-1.8,0.3c1.4,2.2,4.1,2.9,8.4,2.2c3.7-0.7,5.9-2.9,6.5-6.5L137.3,357.6L137.3,357.6z"
          />
          <path
            fill="#C8C8C8"
            d="M125.5,350.9c0.3-0.5,0.7-1.1,1.1-1.6l-2.9,0.5l-0.8,1.6L125.5,350.9z M121.9,353.5c-0.1,0.4-0.3,1-0.3,1.6
	l2.5-0.4c0.1-0.5,0.3-1.1,0.4-1.5L121.9,353.5L121.9,353.5z M123.9,356.5l-2.3,0.4c0,0.5,0,1.2,0.1,1.8l2.2-0.4L123.9,356.5
	L123.9,356.5z M122,361.1v0.5l0.4,0.7l1.8-0.3l-0.1-1.2"
          />
          <path
            fill="#FFFFFF"
            d="M304.6,360.8c0.5,2.3,1.4,4.3,2.5,5.6c1.9,2.3,4.5,3.3,7.8,2.9l167.8-1.2c6.6,0.1,10.6-1.5,12.1-4.8l1.9-6.2
	c3-10.6,5-22.8,5.9-36.6c0.7-19.4-0.4-35.9-3-49.5v-0.3l-200.3,8C298.1,303.3,299.9,330.5,304.6,360.8L304.6,360.8z"
          />
          <path
            fill="#FFFFFF"
            d="M307.2,366.4c-1.1-1.4-1.9-3.3-2.5-5.6h-16.4v22.4h283.5v-13.6l-270.5,1.6
	C296.6,371.5,298.5,369.9,307.2,366.4L307.2,366.4z"
          />
          <path
            fill="#FFFFFF"
            d="M315,369.3c-3.4,0.4-6.1-0.5-7.8-2.9c-8.7,3.4-10.5,5.1-5.6,4.8l270.2-1.6c-5.9-4.4-14.2-6.9-24.6-7.6
	l-52,1.2c-1.5,3.3-5.5,5-12.1,4.8L315,369.3L315,369.3z"
          />
          <path
            fill="#EBEBEB"
            d="M165.4,289l-0.8,0.1l-21.3,3.6l-3.4,2.5l31.4-5.8c-0.6-0.3-1.5-0.5-2.9-0.5L165.4,289L165.4,289z"
          />
          <path
            fill="#EBEBEB"
            d="M171.7,289.8l-0.6-0.5l-31.4,5.8l-0.4,0.3l0.4-0.3l3.4-2.5l-1,0.1c-4.1,1.8-8.5,3.2-12.9,4.3
	c-4.4,1.4-8.5,2.5-12.4,3.3l20.4-4l2.3-0.4l3.6-0.7l1.8-0.3L171.7,289.8L171.7,289.8z"
          />
          <path
            fill="#EBEBEB"
            d="M171.7,289.8l-26.8,5.2c7.3,0.3,14.6-0.4,21.9-2.2l0.7-0.1C169.6,292.3,171,291.3,171.7,289.8L171.7,289.8z"
          />
          <path
            fill="#EBEBEB"
            d="M171.4,291.2c0.3-0.5,0.4-1,0.3-1.4c-0.8,1.5-2.2,2.5-4.1,2.9l-0.7,0.1c-7.2,1.8-14.4,2.5-21.9,2.2l-1.8,0.3
	c1.1,3,0.8,5.8-0.6,8.5l19.7-4.1C165.5,296.8,168.5,294.1,171.4,291.2L171.4,291.2z"
          />
          <path fill="#FFFFFF" d="M146.8,290.9l-4.4,1.9l1-0.1l21.3-3.6" />
          <path
            fill="#EBEBEB"
            d="M139.6,296l-2.3,0.4c0.7,2.5,1,6.3,0.8,11.7c1-0.5,1.8-1.2,2.5-1.9C140.7,301.5,140.5,298.1,139.6,296
	L139.6,296z"
          />
          <path
            fill="#EBEBEB"
            d="M143.2,295.3l-3.6,0.7c0.8,2.1,1.1,5.4,1,10.2c0.7-0.7,1.4-1.5,1.9-2.3C144,301.1,144.3,298.3,143.2,295.3z
	 M137.3,296.5l-20.4,4l-7.6,1.2l-1.2,1.1l-8.5,7.4l13.3-2.1l3.7-5.5l7.7-1.8l5.8-1.4v8.5l-5.6,0.1l-9.5,0.1h-1.1
	c0.1,0.1,0.3,0.3,0.3,0.5l21.9,0.1l0.1-3.4C136,300.8,136.5,297.9,137.3,296.5L137.3,296.5z"
          />
          <path
            fill="#EBEBEB"
            d="M142.7,303.8c-0.6,0.8-1.2,1.7-1.9,2.3c-0.8,0.7-1.6,1.4-2.5,1.9c-0.7,0.4-1.4,0.7-2.1,1.1
	c-1.4,0.7-3,1.1-4.7,1.5l-18.6,5.5l27.4-4.5c4.5-0.7,7.8-1.8,9.9-3c4.3-2.9,8.3-5.9,12-9.1L142.7,303.8L142.7,303.8z"
          />
          <path
            fill="#EBEBEB"
            d="M136.2,309.3c0.7-0.4,1.4-0.7,2.1-1.1c0.1-5.4-0.1-9.2-0.8-11.7c-1,1.4-1.2,4.4-1.1,8.8l-0.1,3.4
	L136.2,309.3L136.2,309.3L136.2,309.3z"
          />
          <path
            fill="#EBEBEB"
            d="M131.5,310.8c1.8-0.4,3.3-1,4.7-1.5v-0.5l-21.9-0.1c0,0.5-0.3,1.2-0.8,2.3l-3,5.8l2.5-0.4L131.5,310.8
	L131.5,310.8z M130.3,306.7v-5l-5.5-0.5l0.4,0.8c0.3,0.5,0.4,1.2,0.4,1.8c0.1,0.8,0,1.6-0.3,2.5l-0.4,1L130.3,306.7L130.3,306.7z"
          />
          <path
            fill="#EBEBEB"
            d="M130.3,308v-1.2l-5.4,0.5l-0.3,0.7 M124.5,300.8l0.3,0.4l5.5,0.5v-2.3"
          />
          <path
            fill="#EBEBEB"
            d="M124.8,301.2l-0.3-0.4l-7.7,1.8l-3.7,5.5l0.4-0.1l0.7,0.1h1.1c2.9-1.1,4.8-2.8,5.6-5l4.3-1.1L124.8,301.2
	L124.8,301.2z"
          />
          <path
            fill="#EBEBEB"
            d="M125.6,303.8c0-0.7-0.1-1.2-0.4-1.8l-4.3,1.1c-1,2.2-2.9,3.9-5.6,5l9.5-0.1l0.3-0.7l0.4-1h-2.1
	c-0.3,0-0.6-0.1-0.7-0.3c-0.1-0.3-0.3-0.4-0.3-0.7v-0.7c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3L125.6,303.8L125.6,303.8z"
          />
          <path
            fill="#EBEBEB"
            d="M122.3,304.8v0.7c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3h2.1c0.3-0.8,0.4-1.7,0.3-2.5h-2.3
	c-0.3,0-0.6,0.1-0.7,0.3C122.4,304.4,122.3,304.7,122.3,304.8L122.3,304.8z"
          />
          <path
            fill="#FFFFFF"
            d="M114.3,308.6c0-0.3-0.1-0.4-0.3-0.5l-0.7-0.1c-1.2,0.5-2.6,1.2-3.9,1.9c-3.6,1.9-7,4.5-10.5,7.7
	c-0.8,0.8,0,1.1,2.6,0.5l8.7-1.4l3-5.8C114,309.9,114.3,309.1,114.3,308.6L114.3,308.6z M108.3,302.7l1.2-1.1
	c-4.3,0.5-8,0.5-11.3,0.3v1.7h1.9v0.3h3h-3l-0.4,2.9l4.5-3.4l2.1-0.3L108.3,302.7L108.3,302.7z"
          />
          <path
            fill="#FFFFFF"
            d="M108.3,302.7l-2.1,0.3l-19.5,15.7l12.7-8.3l0.3-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M106.2,303l-2.1,0.3l-4.5,3.4l-4.7,3.7h-3.3l-8.3,8.3h3.3"
          />
          <path
            fill="#FFFFFF"
            d="M99.6,306.7l0.4-2.9l0.1-0.3h-5.1l-3.4,6.9h3.3"
          />
          <path
            fill="#656565"
            d="M86,321.9h3.7l-0.1-0.7c-0.4-0.7-1-1.1-1.9-1.1h-3.7C83.8,321.2,84.5,321.9,86,321.9L86,321.9z"
          />
          <path
            fill="#FFFFFF"
            d="M83.4,320.1h4.5c1,0,1.5,0.4,1.9,1.1l0.1,0.7l0.7,2.1c-0.4-3.3-1.2-5-2.5-5.1h-4.8L83.4,320.1L83.4,320.1
	L83.4,320.1z"
          />
          <path
            fill="#C8C8C8"
            d="M89.3,321.7l-1,0.1l-0.1,4h1.1 M88.3,321.9h-1v4h0.8 M89.8,321.7h-0.6v4.1h1.8l-0.6-2.1 M87.4,321.9h-1
	l-0.1,4h1.1"
          />
          <path
            fill="#656565"
            d="M89.3,325.8h-5.6v0.8h4.8l0.7,0.8l0.1,0.1h2.3l-0.6-1.8"
          />
          <path fill="#656565" d="M88.2,326.7h-1v3.4H88" />
          <path fill="#C8C8C8" d="M88.5,326.7h-0.3l-0.1,3.4h0.4l0.7-0.8v-1.8" />
          <path
            fill="#656565"
            d="M89.3,327.6l-0.1-0.1v1.8l0.1-0.1h1.7l-0.1-1.5"
          />
          <path fill="#FFFFFF" d="M91.6,327.6h-0.1l0.1,1.5H92" />
          <path fill="#C8C8C8" d="M91.5,327.6h-0.7l0.1,1.5h0.7" />
          <path fill="#656565" d="M83.9,326.7v1H85v-1" />
          <path fill="#C8C8C8" d="M86.9,327.6h-0.8v1.5h0.8v1h0.3v-3.4h-0.3" />
          <path
            fill="#656565"
            d="M86.1,327.6h0.8v-1h-1.1v1 M86.1,329.1v1h0.8v-1"
          />
          <path fill="#FFFFFF" d="M86.1,330.1v-1h-0.3v1" />
          <path
            fill="#656565"
            d="M86.9,330.1h-3.3v0.7h8.9l-0.6-1.6h-2.8l-0.1,0.1l-0.7,0.8h-1.2"
          />
          <path fill="#656565" d="M85,330.1v-1h-1.1v1" />
          <path fill="#C8C8C8" d="M91.6,330.8h-0.7l0.1,5h0.7" />
          <path fill="#656565" d="M88,330.8h-0.8l-0.3,5h1" />
          <path fill="#C8C8C8" d="M89.1,330.8H88l-0.1,5H89" />
          <path fill="#656565" d="M90.9,330.8h-1.8l-0.1,5h2.1" />
          <path fill="#C8C8C8" d="M87.2,330.8h-1.1l-0.1,5h1" />
          <path
            fill="#656565"
            d="M91.8,335.7h-8.1v0.7h4.8l0.6,0.7l0.3,0.3h5.2l-0.6-1.6"
          />
          <path
            fill="#C8C8C8"
            d="M89,337.1l-0.6-0.7h-0.6l-0.1,3.6h0.7l0.6-0.7"
          />
          <path fill="#656565" d="M89,339.3l0.3-0.4h1.8v-1.5h-1.8l-0.3-0.3" />
          <path
            fill="#656565"
            d="M89.3,338.9l-0.3,0.4l-0.6,0.7h-4.8v0.7h11.8l-0.6-1.8h-3.9"
          />
          <path fill="#C8C8C8" d="M91.1,337.4v1.5h0.7v-1.5" />
          <path
            fill="#656565"
            d="M87.8,340l0.1-3.6h-1v3.6 M86.9,336.4h-1.1v1h1.1 M83.9,338.9v1.1H85v-1.1 M85.8,338.9v1.1h1.1v-1.1"
          />
          <path fill="#FFFFFF" d="M85.8,339.7v-0.8 M86.9,338.9v1.1" />
          <path fill="#C8C8C8" d="M86.9,338.9v-1.5h-1.1v1.5" />
          <path
            fill="#656565"
            d="M85,337.4v-1h-1.1v1 M85.7,345.4h-2.1v0.7h4.8l0.8,1.1h7.6l-0.1-1.8h-10"
          />
          <path fill="#C8C8C8" d="M85.8,340.7l-0.1,4.7h1l0.1-4.7" />
          <path fill="#656565" d="M86.8,340.7l-0.1,4.7h1l0.1-4.7" />
          <path fill="#C8C8C8" d="M88.9,345.4l0.1-4.7h-1.2l-0.1,4.7" />
          <path fill="#656565" d="M89,340.7l-0.1,4.7h2.3l-0.1-4.7" />
          <path fill="#C8C8C8" d="M91.1,340.7l0.1,4.7h0.7v-4.7" />
          <path
            fill="#656565"
            d="M91.6,330.8l0.1,5H94l-1.4-5 M91.8,337.4v1.5h3.2l-0.4-1.5 M95.5,340.7h-3.6v4.7h4.8l-0.3-1.1 M97,350.3
	v-1.8h-7.6v0.1l-0.8,1h-4.8v0.8H97L97,350.3L97,350.3z"
          />
          <path fill="#C8C8C8" d="M93.1,348.5v-1.4h-1.7v1.4" />
          <path fill="#656565" d="M95.9,355H83.6v0.7h6.2l0.8,1h4.4" />
          <path fill="#C8C8C8" d="M93.1,355v-4.7h-1.7v4.7" />
          <path
            fill="#656565"
            d="M93.1,360l1.1-1.8h-3.6l-0.8,1.1h-6.2v0.7h7.8"
          />
          <path fill="#FFFFFF" d="M93.1,360.1V360" />
          <path
            fill="#C8C8C8"
            d="M92,361.6l1.1-1.5V360h-1.7v2.2 M93.1,358.2v-1.5h-1.7v1.5 M88.5,346.1h-1.1v3.6h1.1l0.8-1v-1.5 M89.3,350.3
	h-2.1v4.7h1.9 M87.4,349.6v-3.6h-0.4v1.1h-1.1v-1.1h-0.1l-0.1,3.6h0.3v-1.1h1.1v1.1"
          />
          <path
            fill="#656565"
            d="M85.8,346.1v1.1h1.1v-1.1 M86.9,348.5h-1.1v1.1h1.1 M85,347.2v-1.1h-1.1v1.1 M85,349.6v-1.1h-1.1v1.1
	 M83.9,355.7v1H85v-1"
          />
          <path
            fill="#C8C8C8"
            d="M86.9,355.7v1h-1.1v-1h-0.6v3.6h0.6v-1.1h1.1v1.1h0.1l0.1-3.6"
          />
          <path fill="#656565" d="M86.9,356.7v-1h-1.1v1" />
          <path
            fill="#C8C8C8"
            d="M89.1,355.7h-1.9l-0.1,3.6h2.1 M85.6,350.3l-0.1,4.7h1.8v-4.7 M87.1,360h-1.9l-0.1,4.1h1.9"
          />
          <path
            fill="#656565"
            d="M86.9,359.3v-1.1h-1.1v1.1 M85,359.3v-1.1h-1.1v1.1"
          />
          <path
            fill="#FFFFFF"
            d="M85,364.1h-1.2c-1,0-1.7,0.4-2.1,1.1c-0.3,0.7,0,1,1,1c2.9,0.3,5.1-0.1,6.6-1.2l2.8-3.3l-0.6,0.5
	c-0.8,0.7-1.7,1.1-2.3,1.5c-0.4,0.1-0.8,0.3-1.4,0.4L85,364.1L85,364.1z"
          />
          <path
            fill="#C8C8C8"
            d="M87.8,364.1c0.4,0,0.8-0.1,1.4-0.4V360h-2.1l-0.1,4.1L87.8,364.1L87.8,364.1z"
          />
          <path
            fill="#FFFFFF"
            d="M497,357.1l-1.9,6.2l52-1.2c10.6,0.6,18.8,3,24.6,7.6v-8c0-1.5,0-3.2,0.1-4.7L497,357.1L497,357.1z"
          />
          <path
            fill="#FFFFFF"
            d="M578.2,366.6c0-3,0-5.9,0.3-8.9v-0.6h-6.7c0,1.5-0.1,3-0.1,4.7v21.6l6.6,0.4L578.2,366.6L578.2,366.6z
	 M554.4,256c1.7-0.7,3.2-1.4,4.5-2.2L554.4,256z"
          />
          <path
            fill="none"
            d="M230,359.8c0.8,0,1.5,0.1,2.1,0.5c0.3,0.1,0.6,0.3,0.7,0.5l0.6,0.8H231l-0.4-1.1
	C230.6,360.4,230.3,360.1,230,359.8L230,359.8z"
          />
          <path
            fill="none"
            d="M231.1,359.5c0.7,0.1,1.4,0.4,1.8,0.7l0.6,0.4l0.4,0.5l0.3,0.7c0.1,0.3,0.1,0.5,0,0.8l-0.1,0.7l-0.4,0.5l-1,0.8
	c-0.4,0.3-0.8,0.4-1.2,0.5l-1.6,0.1l-1.5-0.3c-0.4-0.1-0.8-0.3-1.2-0.5l-0.6-0.4l-0.4-0.5c-0.1-0.3-0.3-0.4-0.4-0.5
	c-0.1-0.3-0.1-0.5,0-0.8c0-0.3,0-0.4,0.1-0.7l0.4-0.5l0.6-0.4l0.6-0.3c0.8-0.4,1.8-0.7,2.9-0.5L231.1,359.5L231.1,359.5z"
          />
          <path
            fill="none"
            d="M226.8,362.6c-0.1,0-0.1,0-0.1-0.1v-0.5l0.1-0.1h1.4v0.7h-0.1v-0.5H227L226.8,362.6L226.8,362.6z M228.8,362
	h-0.3v0.4h1V362h-0.6"
          />
          <path
            fill="none"
            d="M228.9,361.9l0.3-0.1c0.1,0,0.3,0,0.3,0.1h0.3v0.5l-0.1,0.1h-1.2l-0.1-0.1V362l0.1-0.1c0-0.1,0.1-0.1,0.3-0.1
	L228.9,361.9L228.9,361.9z"
          />
          <path
            fill="none"
            d="M229.9,361.5l-0.1,0.1l-0.1,0.1h-3c0.1-0.7,0.6-1.1,1.2-1.5l1-0.4l1.2-0.1c0,0.3,0.1,0.6,0,0.8L229.9,361.5
	L229.9,361.5z M230,361.9l0.1,0.4v0.3h-0.3v-0.7 M230.3,362l0.7-0.1l0.4-0.1l0.3,0.1c0.1,0,0.1,0,0.1,0.1v0.5l-0.1,0.1h-1.4v-0.1
	h1.2v-0.4h-1.2L230.3,362L230.3,362z M230.6,362.8h2.9c-0.3,0.5-0.7,1.1-1.2,1.5c-0.7,0.4-1.4,0.5-2.1,0.5c-0.1-0.3-0.1-0.5,0-1
	c0-0.3,0.1-0.5,0.1-0.8l0.1-0.1L230.6,362.8L230.6,362.8z"
          />
          <path
            fill="none"
            d="M229.5,363.9l0.6,0.8h0.1l-1.2-0.1c-0.4-0.1-0.7-0.3-1-0.4c-0.7-0.4-1.1-0.8-1.2-1.5h2.5c0,0.3,0,0.4,0.1,0.7
	L229.5,363.9L229.5,363.9z M231.9,361.9h0.7l0.3-0.1l0.3,0.1l0.1,0.1v0.3h-1.2l0.1,0.1h1.2l-0.1,0.1h-1.4l-0.1-0.1v-0.1h1.2v-0.1
	h-1.2L231.9,361.9L231.9,361.9z M633.9,362v0.3h1.2l-0.1,0.1h-1.2l0.1,0.1h1.4v-0.3H634v-0.1h1.2V362H634
	C633.9,361.9,633.9,362,633.9,362L633.9,362z M638.2,362.8l-0.1,0.7l-0.3,0.5l-0.5,0.8l1.2-0.1l1.1-0.4c0.7-0.4,1.1-0.8,1.2-1.5
	L638.2,362.8L638.2,362.8z M639.1,362.6h0.1V362h1.1v0.5c0.1,0,0.1,0,0.3-0.1v-0.5l-0.1-0.1h-1.4L639.1,362.6L639.1,362.6z"
          />
          <path
            fill="none"
            d="M640.4,360.5l-0.6-0.4c-0.8-0.4-1.8-0.7-2.9-0.5l-0.8,0.1c-0.7,0.1-1.4,0.4-1.8,0.7l-0.5,0.4l-0.4,0.5
	c-0.1,0.1-0.3,0.4-0.3,0.7c-0.1,0.3-0.1,0.5-0.1,0.8l0.3,0.7l0.4,0.5l1,0.8c0.4,0.3,0.8,0.4,1.2,0.5l1.7,0.1l1.5-0.3l1.2-0.5
	l0.5-0.4l0.4-0.5l0.3-0.5v-0.8c0-0.3,0-0.4-0.1-0.7l-0.4-0.5L640.4,360.5L640.4,360.5z"
          />
          <path fill="none" d="M638.6,362h0.1l0.1,0.3l-0.1,0.1h-1V362h0.5" />
          <path
            fill="none"
            d="M637.5,362.6c0,0,0.1,0.1,0.3,0.1h1.2l0.1-0.1v-0.4L639,362c0,0-0.1-0.1-0.3-0.1h-1.2L637.5,362.6L637.5,362.6z
	 M636.6,362.8h-2.9c0.3,0.5,0.7,1.1,1.2,1.5c0.7,0.4,1.4,0.5,2.1,0.5v-1c0-0.3-0.1-0.5-0.3-0.8l0.1-0.1L636.6,362.8L636.6,362.8z
	 M636.9,361.9h-0.7l-0.4-0.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.1,0.1h1.4v-0.1h-1.2v-0.4h0.3
	L636.9,361.9L636.9,361.9z M637.2,361.9v0.7h0.1v-0.7"
          />
          <path
            fill="none"
            d="M637.2,359.8c-0.8,0-1.5,0.1-2.1,0.5c-0.3,0.1-0.5,0.3-0.7,0.5l-0.4,0.8h2.3l0.4-1.1
	C636.8,360.4,636.9,360.1,637.2,359.8L637.2,359.8z M637.3,359.8c-0.1,0.3-0.1,0.5,0,0.8c0,0.3,0,0.5,0.1,0.8l0.1,0.3l0.1,0.1h3
	c-0.1-0.7-0.5-1.1-1.2-1.5l-1-0.4L637.3,359.8L637.3,359.8z M736,267.8c1,6.3,2.3,11.7,4.1,16.1c1.1,1.8,2.9,3.9,5.4,5.9
	c0.7,0.7,1.2,1.8,1.8,3.2 M732.1,268.5h-0.7 M726.2,274l-0.4-1.4c0-0.3-0.1-0.5-0.1-0.8 M726.2,274h4.8 M731.4,268.5
	c-0.4,1.4-0.5,3.2-0.5,5.5"
          />
          <path
            fill="none"
            d="M725.6,271.8c0-1.5,1.4-2.6,4.3-3l1.5-0.3 M736,267.8l-0.1-1.2v-0.1 M736.4,285.7c0.1-0.3,0.3-1,0.4-2.2
	c0.1-1.5,0.1-3.2-0.1-5.2c-0.1-2.1-0.4-3.9-0.7-5.2s-0.5-2.1-0.8-2.1"
          />
          <path
            fill="none"
            d="M735.1,270.8c-0.3,0-0.5,0.8-0.5,2.2c-0.1,1.5,0,3.3,0.1,5.4c0.1,2.1,0.4,3.9,0.7,5.2c0.3,1.4,0.5,2.2,1,2.1
	h0.1 M735.1,270.8c-0.8-1.2-1.8-2.1-3-2.3 M732.1,268.5l3.8-0.7 M723.6,289.5h-0.7 M731.6,282.2h-3.2 M733.5,287.3
	c-1.8-0.8-3.2-1.6-4.4-2.6 M731,274c0,1.5,0.1,3.3,0.3,5.4l0.3,2.9 M728.4,282.2l0.8,2.5 M733.6,287.3h-0.1 M731.6,282.2
	c0.4,2.2,1.1,3.9,1.9,5.1 M737.1,299.8l-1.1-8.1h-0.1 M747.4,293c-1.2-1.6-3.7-2.9-7.4-3.7c-2.3-0.5-4.4-1.2-6.2-1.9 M733.6,287.3
	c1.2-0.3,2.2-0.8,2.8-1.6 M733.9,299.4c-0.8-5.1-0.1-7.7,1.9-7.7 M728.4,282.2l-2.2-8.3"
          />
          <path
            fill="none"
            d="M736.5,293.9c5.5,0.7,8.3,0.7,8.1,0c-5.8-2.3-10.3-3.7-13.8-4.3h-7.3 M729.2,284.7h-8.4 M735.8,291.7
	c-3.9,0-7.8-0.7-12.2-2.1"
          />
          <path
            fill="none"
            d="M722.6,289h9.2c3.9,0.7,8.4,2.2,13.6,4.7c0.8,1.5,1.4,4.3,1.8,8.1 M715.5,271.6l10.2,0.1 M93.1,360h-1.7
	 M93.1,356.7h-1.7 M93.1,347.2h-1.7 M93.1,348.5h-1.7 M91.5,358.2h1.7 M91.5,350.3h1.7 M91.5,355h1.7 M91.5,356.7v1.5 M87.2,355.7
	l-0.1,3.6 M89.1,355.7v3.6 M85.3,355.7v3.6 M750,302.5c-1-4-1.8-7.2-2.8-9.4 M89.1,360h-2.1 M87.1,360l-0.1,4.1 M91.5,362.2
	c-0.8,0.7-1.7,1.1-2.3,1.5 M89,363.7l0.1-3.7 M87.1,359.3h2.1 M85.2,360l-0.1,4.1 M89,363.7c-0.4,0.1-0.8,0.3-1.4,0.4h-0.8
	 M86.9,364.1H85 M85,364.1h-1.2c-1,0-1.7,0.4-2.1,1.1c-0.3,0.7,0,1,1,1 M85,359.3h-1.1 M83.9,359.3h-0.3v0.7h1.5 M85.8,359.3h1.1
	 M85,359.3v-1.1h-1.1v1.1 M86.9,359.3v-1.1h-1.1v1.1 M85.2,360h1.9 M86.9,359.3h0.1 M85.3,359.3h0.6 M85.3,359.3H85 M89.1,359.3h0.7
	l0.8-1.1h0.8 M89.1,360h2.3 M91.5,360v2.2 M85.6,350.3h1.7 M87.2,355.7h-0.3 M89.1,355l0.1-4.7 M86.9,349.6h0.4 M87.2,355v-4.7
	 M87.4,346.1h-0.4 M91.5,355v-4.7 M85.4,355h1.8 M85.6,350.3l-0.1,4.7 M91.5,356.7h-0.8l-0.8-1h-0.7 M89.1,355.7h-1.9 M87.2,355h1.9
	 M89.1,355h2.3 M85,355.7h-1.1 M86.9,355.7h-1.1 M85,355.7h0.3 M85.3,355.7h0.6 M85.8,355.7v1h1.1v-1 M83.9,355.7v1H85v-1 M85.4,355
	h-1.8v0.7h0.3 M85,346.1h-1.1 M85,349.6h-1.1 M85,349.6v-1.1h-1.1v1.1 M85.7,346.1l-0.1,3.6 M85.6,349.6h0.3 M85.8,349.6h1.1
	 M85.6,349.6H85 M83.9,349.6h-0.3v0.7h1.9 M85,346.1h0.7 M83.9,346.1v1.1H85v-1.1 M86.9,349.6v-1.1h-1.1v1.1 M85.8,346.1h-0.1
	 M86.9,346.1h-1.1 M85.8,346.1v1.1h1.1v-1.1 M87.4,349.6v-3.6 M89.3,350.3h2.2 M91.5,347.2h-2.2 M87.2,350.3h2.1 M87.4,349.6h1.1
	l0.8-1 M91.5,348.5h-2.2v0.1 M89.3,348.7v-1.5 M89.3,347.2l-0.8-1.1h-1.1 M91.5,348.5v-1.4 M93.1,358.2v-1.5 M93.1,360.1V360
	 M94.2,358.2h-1.1 M91.5,362.2l0.6-0.5 M93.1,350.3H97 M93.1,355v-4.7 M96.8,347.2h-3.7 M97,348.5h-3.9 M95.1,356.7h-1.9 M93.1,355
	h2.8 M93.1,348.5v-1.4 M91.9,345.4h-0.7 M91.1,340.7h0.8 M94,335.7h-2.2 M91.6,330.8h1 M87.4,325.8v-4 M88.2,325.8l0.1-4
	 M89.3,325.8v-4.1 M92,329.1h-0.4 M91.8,338.9h-0.7 M91.8,337.4h-0.7 M86.4,321.9l-0.1,4 M96.7,345.4h-4.8 M91.9,345.4v-4.7
	 M91.9,340.7h3.6 M94.5,337.4h-2.8 M94.9,338.9h-3.2 M91.8,337.4v1.5 M86.9,335.7l0.3-5 M87.9,335.7l0.1-5 M89,335.7l0.1-5
	 M91.6,330.8l0.1,5 M91.1,335.7l-0.1-5 M86.1,330.8l-0.1,5 M87.6,345.4h-1 M86.8,340.7h1 M87.8,340h-0.8 M87.9,336.4h-1 M87.9,335.7
	h-1 M89,340.7h2.1 M91.1,340.7l0.1,4.7 M91.2,345.4h-2.3 M88.9,345.4h-1.2 M87.6,345.4l0.1-4.7 M87.8,340.7H89 M89,340.7l-0.1,4.7
	 M85.8,340.7h1 M86.8,340.7l-0.1,4.7 M86.7,345.4h-1 M85.8,340.7l-0.1,4.7 M85.7,345.4h-2.1v0.7h0.3 M85,340h-1.1 M85,336.4h-1.1
	 M83.9,336.4v1H85v-1 M85.8,340h1.1 M86.9,338.9h-1.1 M85.8,337.4h1.1 M85.8,337.4v1.5 M86.9,338.9v-1.5 M86.9,340v-1.1 M86.9,340
	v-1.1 M85.8,340H85 M85.8,339.7v0.3 M85.8,338.9v0.8 M85.8,339.7v-0.8 M85.8,336.4v1 M86.9,337.4v-1 M85,340v-1.1h-1.1v1.1
	 M83.9,340h-0.3v0.7h2.2 M86,335.7h-2.3v0.7h0.3 M85,336.4h0.8 M86.9,336.4h-1.1 M86.9,335.7h-1 M89,337.1v2.2 M87.8,340l0.1-3.6
	 M91.1,335.7H89 M91.1,337.4h-1.8l-0.3-0.3 M91.1,337.4v1.5 M89,339.3l-0.6,0.7h-0.7 M91.1,338.9h-1.8l-0.3,0.4 M89,335.7h-1.1
	 M89,337.1l-0.6-0.7h-0.6 M91.8,335.7h-0.7 M86.1,330.8h1.1 M89.1,327.5v1.8 M91.5,327.6l0.1,1.5 M90.9,329.1l-0.1-1.5 M87.2,330.1
	h-0.3 M88.2,326.7l-0.1,3.4 M87.2,330.1v-3.4 M87.2,326.7h-0.3 M86.3,325.8h1.1 M86.1,327.6v1.5 M89.1,330.8h1.8 M89.1,329.3
	l-0.7,0.8H88 M88,330.8h1.1 M90.9,329.1h-1.7l-0.1,0.1 M88,330.1h-0.8 M87.2,330.8H88 M91.6,329.1h-0.7 M90.9,330.8h0.7 M85,330.1
	h-1.1 M85,330.1v-1h-1.1v1 M85.8,330.1H85 M86.1,329.1h-0.3v1 M86.9,330.1v-1h-0.8 M85.8,330.1h1.1 M85,326.7h-1.1 M85,326.7h0.8
	 M85.8,326.7v1h0.3 M86.9,326.7h-1.1"
          />
          <path
            fill="none"
            d="M86.1,327.6h0.8v-1 M86.3,325.8h-2.6v0.8h0.3 M83.9,326.7v1H85v-1 M89.3,325.8h1.8 M91.5,327.6h-0.7
	 M91.6,327.6h-0.1 M90.8,327.6h-1.5l-0.1-0.1 M89.1,327.5l-0.7-0.8h-0.3 M88.2,326.7h-1 M87.4,325.8h0.8 M88.2,325.8h1.1
	 M87.4,321.9h-1 M89.8,321.7h-0.6 M88.3,321.9h-0.8 M89.3,321.7l-1,0.1 M83.9,320.1c0,1.2,0.7,1.8,2.2,1.8h0.3 M142.7,303.8
	c1.4-2.8,1.6-5.5,0.6-8.5 M140.7,306.2c0-4.8-0.3-8.1-1-10.2 M138.2,308.2c0.1-5.4-0.1-9.2-0.8-11.7 M130.3,301.8v-2.3l-5.8,1.4
	 M142.7,303.8l19.5-4.1 M139.9,295l31.4-5.8 M145,295l26.7-5.2 M130.3,306.7l-5.4,0.5 M124.8,301.2l5.5,0.5 M136.2,308.8l-21.9-0.1
	 M115.1,308.1l9.5-0.1 M124.5,300.8l-7.7,1.8l-3.7,5.5 M114,308.1h1.1"
          />
          <path
            fill="none"
            d="M115.1,308.1c2.9-1.1,4.8-2.8,5.6-5l4.3-1.1 M125.3,306.4c0.3-0.8,0.4-1.7,0.3-2.5 M124.9,307.3l-0.3,0.7
	 M124.9,307.3l0.4-0.8 M125.6,303.8h-2.3c-0.3,0-0.6,0.1-0.7,0.3c-0.1,0.3-0.3,0.4-0.3,0.7v0.5c0,0.3,0.1,0.4,0.3,0.7
	c0.1,0.1,0.4,0.3,0.7,0.3h2.1 M125.6,303.8c0-0.7-0.1-1.2-0.4-1.8 M125.2,302l-0.4-0.8 M124.8,301.2l-0.3-0.4 M136.2,309.3v-0.5
	 M137.3,296.5c-1,1.4-1.2,4.4-1.1,8.8l-0.1,3.4 M124.6,308h5.6v-1.2 M130.3,306.7v-5 M112.9,316.4l18.6-5.5c1.8-0.4,3.3-1,4.7-1.5
	 M136.2,309.3c0.7-0.4,1.4-0.7,2.1-1.1 M138.2,308.2c1-0.5,1.8-1.2,2.5-1.9 M140.7,306.2c0.7-0.7,1.4-1.5,1.9-2.3 M137.3,296.5
	l-20.2,3.9 M145,295l-1.8,0.3 M143.2,295.3l-3.6,0.7 M139.6,296l-2.3,0.5 M143.3,292.7l-1,0.1 M139.9,295l3.4-2.3 M139.5,295.4
	l0.4-0.4 M171.7,289.8c-0.8,1.5-2.2,2.5-4.1,2.9l-0.7,0.1c-7.2,1.8-14.4,2.5-21.9,2.2 M165.4,289l-0.7,0.1l-21.3,3.6 M693.2,248.8
	c-2.5,0.6-5.5,0.6-8.9,0 M578.7,207l-1.9,2.9 M509.4,222.1l-1,28.6c0.1,5.2,3.7,7.7,10.7,7.6c16.8,0,29-1.6,36.6-4.8
	c13.9-7,22.8-13.2,27-18.4c2.1-3.4-3.6-7.2-17.1-11.1c-12.8-4.4-28.8-6.9-47.6-7.6C512.8,216.1,509.9,218,509.4,222.1L509.4,222.1z
	 M336.3,245.8c19.3-13.6,37.3-22.6,53.9-27.1c14.9-2.1,35.2-3.3,60.9-3.4h35.1 M265.1,262.1l14.3,1.2c6.7,0,13.5-2.3,20.1-7.3
	c21.6-14.9,44-28.1,67.3-39.8l-5.8-3.2 M684.8,239l17.9-36.7V202l2.1-4.3l0.1-0.1h0.3l0.1,0.1v0.3l-2.1,4.3l-0.1,0.1l-17.7,36.7
	l-0.1,0.1 M83.9,330.1h-0.3v0.7h2.5 M750.4,251.7l-16.4,4"
          />
          <path
            fill="none"
            d="M367.4,210.1L361,213c-31.4,14-63.4,30.4-95.9,49l-2.9,1.7 M366.2,209.6l1.2,0.6 M335.4,250.5l0.1,0.8
	c1,6.1,0.4,9.6-1.6,10.9c-0.5,0.3-1.1,0.4-1.9,0.7 M334,254.9l0.1-0.7v-3.3 M335.4,250.5c0.7-2.2,1-3.7,1-4.7c0-0.7-0.1-1.1-0.4-1.1
	h-3.9 M390.8,213.6c-7.8,0.8-13.8,0.7-17.7-0.6l-1-0.3l-4.8-2.5 M329.6,244.1c10.6-6.9,24.1-14.6,40.4-23.1
	c5.5-2.9,12.5-5.4,20.9-7.4"
          />
          <path
            fill="none"
            d="M554.1,204.6l0.8-3l0.3-0.7c-3.7-0.7-7.6-1.1-11.8-1.5c-33.7-1.1-69.9-0.7-108.4,1c-34.5,1.5-57.5,4.5-68.8,9.1
	 M554.1,204.6c-22.6-2.5-46.5-3.2-71.9-2.1c-55.2,1-91.8,4.3-110.1,10.2 M497.4,211h-10.9c-21.5-0.1-42.9,0.1-64.4,0.6l-31.2,2.1
	 M500,211h-2.6"
          />
          <path
            fill="none"
            d="M486.6,211l-0.3,4.3l-3.4,44 M558.9,253.6c8.1-4.4,17.6-11.4,28.6-21c2.5-2.8,0.3-5.6-6.6-8.7l-19.7-7
	c-5.4-1.6-10.7-2.9-16.2-3.9c-4.7-0.8-9.4-1.2-14-1.5c-10.3-0.3-20.6-0.4-31.1-0.6 M679,247c-42.4-17.9-76.3-30.3-102.1-37.1
	c-8.5-2.3-16.1-4-22.7-5.2 M332.1,244.7c0.4,0.1,1,0.4,1.2,0.7c0.8,1,1.5,2.6,2.1,5.1 M329.6,244.1c0.8,0,1.6,0.3,2.5,0.6"
          />
          <path
            fill="none"
            d="M313.5,255.8c0.3-1.9,1.6-4.1,4.1-6.3c2.1-1.9,5.2-3.6,9.4-5l2.8-0.4 M334.1,251l-11.6,0.8
	c-1.9,0-3.2,0.6-3.6,1.6c-0.7,1.9-0.3,2.6,1.2,2.2l13.9-0.7 M332.1,262.7l-5.8,0.4l-4.7,1.1c-3.2,0.5-5.4,0.4-6.6-0.1
	c-0.8-0.4-1.4-1.2-1.5-2.8 M331.7,264.4l0.4-1.6 M313.5,261.5c-0.3-1.4-0.3-3,0-5.4v-0.4 M304,265.3c-0.4,0.5-0.8,1.1-1.2,1.8
	 M313.5,255.8c-4.1,3.4-7.3,6.6-9.5,9.5"
          />
          <path
            fill="none"
            d="M313.5,261.5c2.1-1.1,7-1.9,15-2.6c3,0,5-1.2,5.6-3.9 M299.5,278.8c0.5-4.8,1.6-8.7,3.3-11.7 M304,265.3l27.6-1
	 M262.2,263.8l-1.8-0.3 M263.6,263.9l-1.4-0.1 M288.2,360.8v-1.7c-1.1-12.4-5.6-23.4-13.5-32.9c-1.1-1.4-2.3-2.8-3.6-4
	c-1.4-1.5-2.9-2.8-4.3-4c-10.6-9.2-23.1-13.8-37.6-13.8c-16.4,0-30.3,5.9-42,17.9c-8.8,9.1-14.2,19.4-16.4,31.2l-0.1,1.9
	c-0.8,5.6-1.2,12.5-1.4,20.5v6.2l-49.7-2.2l-13.9-2.3c-8.4-0.1-16.1-0.7-23.1-1.6l-3.3-0.5c-0.8-0.1-1.4-0.4-1.7-0.8"
          />
          <path
            fill="none"
            d="M260.1,342.5l0.7-0.1l3.4,1.1 M264.7,345.2l0.5-0.1 M265.2,345.1l-0.5,0.1 M264.4,343.3l0.8,1.8 M264.3,343.5
	l0.1-0.1 M264.7,345.2h-0.1l-12.8,3.6c-1,0.3-1.8,0.5-2.6,1l-0.1,0.1c-0.8,0.4-1.6,1.1-2.5,1.9l-6.1,5.4c-0.4,0.5-0.6,1.1-0.4,1.5
	v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.1,3.2,0c1-0.1,1.9-0.4,2.8-0.8l12.7-4.7"
          />
          <path
            fill="none"
            d="M271.5,332.2c6.6,8.7,9.9,18.8,9.9,30.3c0,4.7-0.5,9.1-1.6,13.2l-1,3.2c-1.6,5-4.1,9.6-7.3,13.9l-1.9,2.5
	l-3.2,3.4c-8.4,8.4-18.3,13.2-29.6,14.6l-5.2,0.4l-7.4-0.3c-11.6-1.2-21.5-6.2-30-14.7c-2.3-2.3-4.4-4.8-6.2-7.3
	c-3.3-4.7-5.6-9.6-7-15c-1-3.7-1.6-7.6-1.8-11.7v-2.1c0-10.7,2.9-20.4,8.8-28.8c1.8-2.6,3.9-5,6.2-7.3l4.8-4.3
	c8.9-7.2,19.4-10.6,31.4-10.6c11.8,0,22.3,3.6,31.2,10.6c1.6,1.4,3.3,2.8,4.8,4.3C268.2,328.2,269.9,330.1,271.5,332.2L271.5,332.2z
	"
          />
          <path
            fill="none"
            d="M77.7,374.7h77.2c3.6,0.1,5.9-1.6,7-5.2c0.3-8.7,1.2-16,2.6-21.9c2.5-11.8,8.4-22.4,18-31.5
	c12.9-12.1,28.5-18.3,46.6-18.3s33.7,6.1,46.5,18.3l4,4.1c8.8,9.8,13.8,21,15,33.7l0.5,6.9 M168.4,288.7c28.3-3.9,62-6.7,100.8-8.7
	l30.4-1.4 M302.8,267.1c-14-0.8-27.2-1.9-39.2-3.2"
          />
          <path
            fill="none"
            d="M195.3,345.4l1.6,2.1l0.7,0.3 M195.2,345.4h0.1 M196,343.6l-0.8,1.8 M196.3,343.7l-0.3-0.1 M195.3,345.4h-0.1
	 M200.4,342.8l-0.7-0.1l-3.4,1.1 M222.7,324.2c-7.6,1.4-14.2,5-19.9,10.7c-2.8,2.8-5,5.6-6.7,8.8"
          />
          <path
            fill="none"
            d="M220.7,327.9c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6 M222.7,324.2l-2.1,3v0.7 M220.2,342.2l0.4,1.5
	l2.1,5.8c0.6,1.1,0.4,1.9-0.4,2.5c-0.4,0.4-1,0.3-1.9-0.5l-5-3.7c-0.4-0.4-1-0.7-1.4-0.8 M222.7,324.2l-0.8,13.6
	c0,1.1,0,2.1,0.1,3.2v0.4c0.1,0.8,0.4,1.5,0.6,2.3l2.8,7.8"
          />
          <path
            fill="none"
            d="M220.7,327.9l-0.6,10c-0.1,1.1,0,2.3,0,3.6l0.1,0.7 M200.4,342.8l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3
	 M237.6,324.2l0.1,0.3 M237.7,324.5l-0.1-0.3l2.1,3l0.1,0.7 M234.8,351.7l2.8-8c0.3-0.8,0.6-1.6,0.7-2.3v-0.3c0.1-1,0.1-1.9,0.1-3.2
	l-0.7-13.2v-0.1 M234.1,326.9v-0.8l1.5-2.3 M237.6,324.2l-1.9-0.4 M224.6,323.9l-1.9,0.3 M224.6,323.9l1.5,2.2v0.8 M224.6,324.5
	v-0.5 M224.6,323.9v0.5"
          />
          <path
            fill="none"
            d="M235.6,323.8l-5.4-0.3l-5.6,0.4 M234.1,326.9l-3.9-0.3l-4.1,0.3"
          />
          <path
            fill="none"
            d="M235.6,323.8l0.6,14c0.1,0.8,0.1,1.8-0.1,2.8v0.1c-0.1,1-0.6,1.9-1.1,3l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7
	c-0.6-0.1-1.1-0.4-1.4-1l-3.2-7.4c-0.6-1.1-1-2.1-1.1-3s-0.1-1.9-0.1-2.8l0.6-13.5"
          />
          <path
            fill="none"
            d="M225.9,340.3c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.6-1.1l1.8-3.2
	c0.3-0.5,0.6-1.1,0.7-1.6 M226.2,326.9l-0.4,12.2l0.1,1.1"
          />
          <path
            fill="none"
            d="M225.9,340.3l4.4-0.4l4.3,0.4 M239.8,327.9l0.6,10c0.1,1.2,0.1,2.3,0,3.6l-0.1,0.7"
          />
          <path
            fill="none"
            d="M240.2,342.1l-0.4,1.5l-2.1,5.8c-0.6,1.1-0.6,1.6,0,1.9c0.8,0.7,1.6,0.4,2.5-0.4l5-3.7l1.2-0.7 M234.5,340.3
	l0.1-1.1l-0.6-12.2 M264.4,343.3c-1.8-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7"
          />
          <path
            fill="none"
            d="M239.8,327.9c5.9,1.5,11.1,4.5,15.8,9.2c1.8,1.6,3.2,3.4,4.5,5.4 M240.2,342.1c2.2,1.1,4.1,2.5,6.1,4.4l0.1,0.1
	 M264.3,343.5l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5"
          />
          <path
            fill="none"
            d="M246.2,346.6l0.8-0.4c1.1-0.5,2.2-0.8,3.4-1.2l9.6-2.5 M252.6,359.5l-1.8-0.1l-3.6-0.7
	c-0.8-0.1-1.2-0.5-1.5-1.2l-0.3-0.7c-0.1-0.7,0-1.4,0.6-1.9l2.5-2.6c0.4-0.4,1-0.8,1.4-1.1 M242,363.4l-0.1-0.1
	c-0.6-0.8-1.2-1.2-2.2-1.4s-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5c0.6,0.7,1.2,1.2,2.2,1.4
	c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4c0.8-0.6,1.2-1.2,1.4-2.2C242.5,364.9,242.4,364.1,242,363.4L242,363.4z M252.6,365.5h-7.7
	c-1.2-0.3-1.8,0.3-1.9,1.2c-0.3,0.5,0.1,1,1.1,1.5l5.6,4 M265.4,355.1l-11.6,4.1l-1.2,0.3"
          />
          <path
            fill="none"
            d="M242,363.4l8.4,0.3l2.3-0.1l0.4-0.1c1-0.1,1.9-0.4,3-0.8l12.4-4.8 M266,360.6l-9.2,3.6
	c-1.2,0.4-2.3,0.8-3.4,1.1l-0.7,0.1 M250,351.3c1.5,2.5,2.3,5.2,2.8,8.3"
          />
          <path
            fill="none"
            d="M262.9,347.4l-11.8,3.4c-0.4,0.1-0.7,0.3-1.1,0.5 M234.8,351.7h-0.1c-1,0.3-1.5,0.8-1.9,1.6
	c-0.4,0.8-0.6,1.6-0.3,2.6c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.7,0.4,2.5,0.1c1-0.3,1.5-0.8,2.1-1.6c0.3-0.6,0.4-1.1,0.3-1.8l-0.1-0.8
	c-0.3-0.8-0.8-1.5-1.7-1.9C236.5,351.6,235.6,351.4,234.8,351.7L234.8,351.7z"
          />
          <path
            fill="none"
            d="M234,355.4c-0.1-0.4-0.1-0.8,0.1-1.2c0.1-0.4,0.6-0.7,1-0.8l1.2,0.1c0.4,0.1,0.7,0.5,0.8,1v0.4
	c0,0.3,0,0.5-0.1,1c-0.1,0.4-0.6,0.7-1,0.8l-1.2-0.1C234.4,356.2,234.1,355.8,234,355.4L234,355.4z M226,353.8
	c0.3,0.3,0.6,0.7,0.6,1.2c0,0.4-0.1,0.8-0.6,1.2c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.2
	c0-0.5,0.1-1,0.4-1.2c0.3-0.3,0.7-0.5,1.2-0.5h0.4C225.6,353.5,225.9,353.6,226,353.8L226,353.8z"
          />
          <path
            fill="none"
            d="M221.5,354.9c0-0.8,0.4-1.6,1-2.2c0.7-0.7,1.5-1,2.3-1l0.8,0.1c0.7,0.1,1.1,0.4,1.6,0.8c0.7,0.7,1,1.5,1,2.5
	s-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.5,1s-1.8-0.4-2.3-1c-0.7-0.7-1-1.4-1-2.3L221.5,354.9L221.5,354.9z M227.1,358.6
	c1-0.8,1.9-1.2,3.2-1.2c1.4,0,2.5,0.5,3.6,1.5c1,1,1.4,2.2,1.4,3.6l-0.1,1.2c-0.1,0.7-0.6,1.4-1,1.9l-0.3,0.4
	c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2c-1.1-0.1-2.1-0.5-2.9-1.4c-1-1-1.4-2.1-1.5-3.3v-0.3c0-1.4,0.6-2.5,1.5-3.6L227.1,358.6L227.1,358.6
	z M223.1,364.5c0.3,0.4,0.3,0.8,0.1,1.4c-0.1,0.4-0.4,0.7-0.8,1h-1.2c-0.4-0.1-0.8-0.4-1-0.8s-0.3-0.8-0.1-1.2l0.1-0.4
	c0.1-0.3,0.3-0.4,0.7-0.7l1.2-0.1C222.6,363.8,222.9,364.1,223.1,364.5L223.1,364.5z"
          />
          <path
            fill="none"
            d="M220.4,368.5c-0.8-0.4-1.4-1-1.8-1.6c-0.4-0.8-0.6-1.7-0.1-2.6l0.3-0.7c0.3-0.5,0.7-1,1.4-1.2
	c0.8-0.4,1.6-0.5,2.6-0.1c1,0.3,1.5,0.8,1.9,1.6c0.4,0.8,0.6,1.6,0.3,2.6c-0.3,1-0.8,1.5-1.6,1.9c-0.8,0.4-1.6,0.5-2.6,0.3
	L220.4,368.5L220.4,368.5z M240.5,364.2c0.3,0.4,0.4,0.8,0.3,1.2c-0.1,0.4-0.3,0.8-0.7,1.1l-0.4,0.1c-0.3,0.1-0.6,0.3-1,0.1
	c-0.4,0-0.8-0.3-1.1-0.7c-0.3-0.4-0.3-0.8-0.3-1.2c0-0.5,0.3-0.8,0.7-1.1c0.4-0.3,0.8-0.4,1.2-0.3
	C239.8,363.5,240.2,363.8,240.5,364.2L240.5,364.2z M257.5,390.2l-8.1-12.2c-0.4-0.5-0.8-1.1-1.4-1.5v-0.1c-0.8-0.7-1.6-1.2-2.6-1.8
	l-6.9-4.1l-1.6,0.1c-0.1,0-0.1,0-0.3,0.1l-0.3,0.3c-0.4,0.4-0.6,0.8-0.6,1.5l1.8,7.8c0.1,1.1,0.6,2.2,1,3v0.1c0.4,0.8,1,1.6,1.5,2.2
	l8.8,11 M232.1,371.4c0,0.4,0,0.8-0.3,1.2c-0.3,0.4-0.7,0.5-1.1,0.7c-0.4,0-0.8,0-1.2-0.3l-0.3-0.3c-0.3-0.3-0.4-0.5-0.4-0.8
	c0-0.5,0-1,0.3-1.2c0.3-0.4,0.7-0.6,1.1-0.7c0.6-0.1,1,0,1.2,0.3C231.8,370.5,232.1,371,232.1,371.4L232.1,371.4z"
          />
          <path
            fill="none"
            d="M233,373.7c-0.6,0.7-1.2,1.1-2.1,1.2c-1,0.1-1.8-0.1-2.5-0.7l-0.6-0.5c-0.4-0.4-0.7-1-0.8-1.6
	c-0.1-1,0.1-1.8,0.7-2.5c0.6-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0.1,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2c0.1,1,0,1.8-0.6,2.5L233,373.7
	L233,373.7z M239.9,382.8c-0.3-0.5-0.4-1.1-0.6-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.6-0.4h1.9l3.3,1.5c0.6,0.3,1.1,0.5,1.4,1
	 M212.3,397l8.8-11c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.7-1.8,1-3l1.8-7.8c0-0.7-0.1-1.1-0.6-1.5l-0.3-0.3l-0.3-0.1h-1.7l-6.9,4.1
	c-1.1,0.5-1.9,1.1-2.6,1.8c-0.7,0.7-1.2,1.4-1.6,2.2l-7.8,11.7 M226.7,384.6l2.3-7.3c0.1-1.2,0.6-1.8,1.1-1.6
	c1.1-0.3,1.7,0.3,1.8,1.4l1.8,5.9c0.1,0.5,0.4,1,0.7,1.5 M217.9,396l6.3-7.7l2.1-2.9l0.4-0.7"
          />
          <path
            fill="none"
            d="M227.8,374.1l-2.3,8c-0.3,0.8-0.6,1.5-1,2.2l-0.1,0.3c-0.6,1-1.1,1.8-1.8,2.6l-8.7,10.6 M234.3,384.6l-4,0.3
	l-3.6-0.3 M248.2,393.5l-7.7-9.8l-0.6-1 M233,373.7l2.3,8c0.3,0.8,0.6,1.5,1,2.3l0.1,0.3c0.4,0.8,1,1.6,1.8,2.6l8.4,10.2
	 M243.1,395.9l-6.3-7.8c-0.8-1-1.5-1.9-2.1-2.9l-0.3-0.5 M250.4,372.8l0.6,0.5c0.7,0.8,1.4,1.8,2.2,2.9l1.2,1.9l4.1,6.5
	 M250.5,372.3l-0.1,0.4 M258.9,388.8l-7.3-11.4c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7 M246.9,377.6
	l0.3,0.3l0.6,0.7l6.9,10.2 M246.9,377.6l-0.3,0.3l-0.4,0.4c-1.9,1.9-4,3.4-6.2,4.4 M247.2,377.8h-0.6 M258.9,388.8v-3.6l-0.4-0.7
	 M257.5,390.2l1.4-1.4 M254.6,388.7l0.6,0.7l2.3,1 M249.8,372.2l0.6,0.5 M249.8,372.2l0.7,0.1 M252.6,365.5c-0.3,2.5-1,4.7-2.1,6.9
	 M213.6,354.5l0.8,0.8c0.6,0.5,0.8,1.2,0.6,1.9l-0.3,0.7c-0.1,0.4-0.4,0.8-0.8,1 M196.3,343.7l13.2,3.4c1.1,0.3,2.1,0.7,2.9,1.1
	l0.3,0.1c0.8,0.4,1.5,0.8,2.1,1.4l6.7,5.1 M214,346.7l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3 M195.3,345.4l13.5,3.9c1,0.1,1.8,0.5,2.5,1
	h0.1c1,0.4,1.8,1.1,2.5,1.9l6.1,5.4c0.3,0.6,0.6,1.1,0.4,1.6v0.3l-0.1,0.3c-0.3,0.6-0.7,0.8-1.4,1l-8,0.8c-1.2,0.1-2.2,0.3-3.2,0
	c-1-0.1-1.9-0.4-2.8-0.7l-6.6-2.5l-6.5-2.5 M201.4,348.8l8.1,2.3c0.4,0.1,0.7,0.3,1,0.4c0.6,0.3,1.1,0.7,1.5,1.1l1.6,1.8
	 M208.4,356.7h-0.6c-0.7-1.4-1.4-2.2-2.2-2.3c-1.6-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6l-0.3,1.8
	 M211,356.7v-0.3c-0.1-1.6-0.6-2.8-1.4-3.2 M209.6,353.4c-0.3-0.1-0.7-0.3-1.1-0.3c-1.1,0.1-1.7-0.3-1.9-1.2
	c-0.1-1.1-1.2-1.6-3.3-1.8h-1.9v-1.2 M208.4,356.7h2.5 M209.6,353.4c-0.6,1.1-1,2.2-1.2,3.4 M207,359.7l0.8,0.1h1.8l1.2-0.3
	 M211,359.5v-1.9 M207,359.7c0.1-0.8,0.6-1.4,1.1-1.8c0.7-0.4,1.5-0.5,2.8-0.4 M198.5,356.7l8.3,3l0.3,0.1 M207.6,365.7h0.4
	 M208,366.2l-0.4-0.4 M208,365.7v0.3 M198.2,362.4l5.6,2.2c1.1,0.4,2.2,0.8,3.4,1.1l0.4,0.1 M208.3,367.4H208l-0.1,0.1 M211,367.4
	h-2.8 M211,366.6v0.8 M211,367.4v0.3c-0.1,1.8-0.7,2.9-1.6,3.3 M208.4,367.7l-0.1-0.3 M211,365.9v0.7 M218.4,363.7l-8.3,0.3
	l-2.8-0.3c-1-0.3-2.1-0.5-3-1l-6.2-2.3l-6.1-2.3 M208,366.2c0.7,0.4,1.6,0.5,2.9,0.4 M207.8,367.7l0.6,0.1 M211,365.9l-3-0.1
	 M211,359.5l2.3-0.4l0.7-0.3 M213.6,370.7l3.2-2.2c1.1-0.6,1.4-1.2,0.8-2.2c0-0.5-0.7-0.8-1.8-0.5H214 M214,358.9
	c-0.1-1.8-0.1-3.2-0.3-4.4 M211,357.6v-0.8 M213.9,365.7l-3,0.1 M195.2,355.4l3.3,1.2 M191.9,355.8l2.6-0.7l0.7,0.3 M198.5,356.7
	l0.1-1.4 M198.6,355.3l-0.1,1.4 M197.7,347.7l3.9,1.1 M195.2,355.4c0.6-2.8,1.4-5.2,2.5-7.7 M192.2,358l-0.3-0.1 M192.2,358
	l-0.3-0.1 M191.6,357.8c-0.1,1.5-0.3,3-0.3,4.7c0,10.3,3.6,19.3,10.6,26.7 M191.9,357.9l-0.3-0.1 M191.9,357.9l-0.1-0.1l2.2,2.9
	l0.7,0.3 M191.6,357.8l0.3,0.1 M194.7,360.9v1.4c0,1.4,0,2.6,0.1,3.9 M198.2,362.4l-3.6-1.4 M198.4,366.6l-3.7-0.4 M198.5,366.6
	l-0.3-4.3 M198.4,366.6c-0.1-1.4-0.1-2.8-0.1-4.3 M191.9,355.8l-0.3,1.9 M209.4,371l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2
	c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9l0.6,2.8l0.7,3.3l3-0.3 M197.4,376.6l4.5,0.1"
          />
          <path
            fill="none"
            d="M207.9,367.7c-0.7,1.2-1.2,2.1-2.1,2.2c-1.6,0.6-2.3,1.5-2.3,3c0,0.8-0.6,1-1.6,0.4c-1.4-0.7-2.3-1.5-2.8-2.6
	l-0.3-1.8 M202.1,389v-3.6l0.3-0.5 M201.9,389.1l1.4,1.4 M202.5,384.9c-2.1-2.6-3.7-5.2-5-8.1c-1.4-3.3-2.3-6.7-2.8-10.5
	 M206.3,388.8l6.9-10.2l0.7-0.8 M213.9,377.8l0.4,0.4c2.1,2.1,4.1,3.6,6.6,4.7 M213.9,377.8c0.4-0.4,1-0.7,1.5-1.1l3.3-1.5l1.9,0.1
	l0.3,0.3l0.3,0.1c0.6,0.4,0.8,1.1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8 M220.4,368.5l-7,4.7c-0.7,0.5-1.4,1.1-1.9,1.7
	l-0.1,0.1c-0.8,0.8-1.4,1.6-1.9,2.5l-7.4,11.4 M205.7,379.8l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3 M203.3,390.5l2.5-1
	l0.4-0.7 M220.9,382.9l-0.6,1l-7.6,9.8 M202.5,384.9l3.3-5.1 M198.6,368.9l-0.3-2.2 M209.4,371c-0.4-1.1-0.8-2.2-1-3.3 M198.6,368.9
	c-0.1-0.7-0.3-1.5-0.3-2.2 M213.6,370.7c0.1-1.2,0.3-2.9,0.4-5 M214,397.6l-0.1,0.1 M214,397.6l3.3-1.1l0.4-0.5 M212.8,393.7
	l-0.4,0.5l-0.1,2.6 M212.1,397l1.8,0.8 M248.7,396.8l-0.1-2.8l-0.4-0.5 M246.8,397.5l-3.3-1.1l-0.4-0.6 M246.7,397.2l0.3,0.3
	 M246.8,397.5l-0.3-0.3 M246.9,397.6l-0.1-0.1 M246.9,397.6l1.8-1 M213.9,397.8c5,2.3,10.5,3.4,16.4,3.4c6.1,0,11.6-1.2,16.6-3.6
	 M243.1,395.9c-4,1.5-8.3,2.2-12.8,2.2c-4.4,0-8.5-0.7-12.4-2.1 M248.7,396.8c3.2-1.6,6.1-3.9,8.8-6.6 M203.3,390.5
	c2.8,2.6,5.6,4.8,8.8,6.5 M254.6,388.7c-2.1,1.9-4.1,3.4-6.5,4.8 M212.8,393.7c-2.3-1.4-4.4-2.9-6.5-4.8 M307.2,366.4
	c-1.1-1.4-1.9-3.3-2.5-5.6 M495,363.3c-1.5,3.3-5.5,5-12.1,4.8L315,369.3c-3.4,0.4-6.1-0.5-7.8-2.9 M571.7,369.6l-270.3,1.6
	c-4.8,0.3-2.9-1.4,5.6-4.8 M295.4,360.8h-7.2 M268.7,355.6l-2.6-0.7l-0.7,0.3 M262.9,347.4c1.1,2.5,1.9,5,2.5,7.7 M265.2,345.1
	l-1.6,2.1l-0.7,0.3 M258.3,384.6c1.6-2.1,3-4.1,4.1-6.3c0.7-1.4,1.2-2.9,1.8-4.4c1.2-3.6,1.8-7.4,1.8-11.4v-1.6 M268.5,357.6
	l0.3-0.1l-2.2,2.9l-0.7,0.3 M258.9,388.8c6.9-7.4,10.3-16.2,10.3-26.4c0-1.6-0.1-3.3-0.3-5 M268.9,357.5l-0.3-1.8 M268.2,355.7h0.4
	 M268.2,355.7l0.4-0.1 M268.5,357.6l0.3-0.1 M288.2,360.8v22.4h283.5 M278.8,378.7l9.4,0.3"
          />
          <path
            fill="none"
            d="M304.6,360.8h-9.2 M260.4,263.5c-8.1-1-15.7-1.9-22.8-3c-45.5,5.2-81,12.1-106.5,20.9
	c-13.1,4.5-24.1,11.4-32.9,20.5 M304.6,360.8c-4.8-30.1-6.5-57.5-5.1-82 M268.7,355.6c-0.7-3.7-1.8-7.2-3.4-10.5 M195.2,345.4
	c-1.6,3.3-2.8,6.7-3.3,10.5 M262.5,378.2l-8.3-0.1"
          />
          <path
            fill="none"
            d="M263.6,263.9c-7.4,4.3-18.7,7.3-33.7,8.9c-22.7,2.3-43.3,5.2-61.9,8.9c-9.6,2.2-16.6,5.2-21.3,9.1 M110.3,316.8
	l2.5-0.4l27.4-4.5c4.5-0.7,7.8-1.8,9.9-3c4.3-2.9,8.3-5.9,12-9.1c3.3-2.8,6.3-5.6,9.1-8.5c0.4-0.5,0.4-1,0.4-1.4l-0.6-0.5
	c-0.6-0.3-1.5-0.5-2.9-0.5 M168.4,288.7l-3,0.3 M146.8,290.9l-4.3,1.9c-4.1,1.8-8.5,3.2-12.9,4.3c-4.4,1.4-8.5,2.5-12.4,3.3
	l-7.6,1.2 M110.3,316.8l3-5.8c0.7-1.1,1-1.8,0.8-2.3c0-0.3-0.1-0.4-0.3-0.5l-0.7-0.1"
          />
          <path
            fill="none"
            d="M99.6,310.2l13.5-2.1l0.4-0.1 M141,324.2c0.1,0.7,0.1,1.5,0,2.5l-2.6,21.9 M137.6,326.5l0.4-0.6l3-1.8"
          />
          <path
            fill="none"
            d="M133.6,345c1,0.1,1.5-0.8,1.8-2.6l2.2-15.8 M133.6,345l3-15.8c0.3-1.2,0.6-2.1,1-2.6 M141,324.2
	c-0.1-1-0.7-1.4-1.6-1.4 M146.8,290.9l17.9-1.8 M260.4,263.5c-32.1,2.5-60.8,5.8-86.4,10c-24.5,4.7-42,10.3-52.1,17.2 M109.4,301.8
	l-1.1,1 M108.3,302.7l-2.1,0.3 M106.2,303l-2.1,0.3l-4.5,3.4 M99.6,306.7l0.4-2.9 M100,303.8l0.1-0.3h-2.1 M99.6,306.7l-4.7,3.7
	h-3.3"
          />
          <path
            fill="none"
            d="M98.1,303.6h-3l-3.4,6.9 M98.1,301.9v1.7 M86.7,318.7l12.7-8.3l0.3-0.3 M100,303.8h3.2 M113.5,308
	c-1.2,0.5-2.6,1.2-3.9,1.9c-3.6,1.9-7,4.5-10.5,7.7c-0.8,0.8,0,1.1,2.6,0.5l8.7-1.4 M98.1,301.9c3.3,0.4,7,0.3,11.3-0.3 M99.6,310.2
	l8.7-7.4 M83.4,318.7v1.4h4.5c1,0,1.5,0.4,1.9,1.1l0.1,0.7l0.7,2.1 M86.7,318.7h-3.3"
          />
          <path
            fill="none"
            d="M90.5,323.8c-0.4-3.3-1.2-5-2.5-5.1h-1.4 M91.6,310.4l-8.3,8.3 M92,361.6l1.1-1.5l0.1-0.1l1.1-1.8l0.8-1.5
	l0.8-1.8l0.8-2.3l0.3-2.2v-1.8L97,347l-0.3-1.8l-0.1-1.1l-1-3.6l-0.6-1.8l-0.4-1.5l-0.6-1.8l-1.4-4.8l-0.6-1.8l-0.4-1.5l-0.6-1.8
	l-0.6-2.1 M716.4,361.5l-12.2,1.2 M675.3,355.8c-0.5-3.7-1.7-7.2-3.3-10.5 M755.5,343.5l-1.1,6.2 M704.9,372.6l1.9-0.3 M753.2,357.5
	c-15.4,2.6-27.7,4-36.7,4.1 M737.3,372.6v-4.1l-21.7,2.8 M716.4,361.5c8.9-1.5,15.5-3.7,20.1-6.6 M706.8,372.3l8.7-1.1"
          />
          <path fill="none" d="M715.6,371.2c-0.1,0.8,0.7,1.4,2.6,1.4h19.1" />
          <path
            fill="none"
            d="M706.8,372.3c0.5,1.2,1.5,2.3,2.6,3.3c2.5,2.2,5.5,3.3,9.1,3.3c3.6,0,6.6-1.1,9.1-3.3c1.1-1,1.9-1.9,2.6-3
	 M749.7,364.2c-1.9,1.8-4.5,3-8,3.6l-4.4,0.7 M736.5,355c-0.5-0.7-1-1.6-1.1-2.9 M736.5,355c0.8,1,1.9,1.4,3.3,1.4h2.8
	c-3.3-1.1-5.6-2.6-7.2-4.3 M735.4,352l-0.1-1v-22.7 M762.7,350.5h-1.8 M761.1,348.4h1.8 M737.3,372.6h13.1l2.8-5.6 M754.3,360.2
	c-1.2,1.5-2.3,2.5-3.7,3.2 M753.2,357.5c-0.4,2.3-1.2,4.3-2.6,5.9 M754.3,350.7l-1.1,6.7 M753.2,367l3.3-6.7h-2.2 M754.3,360.2
	l1.5-2.2c2.2-3.9,3.2-4.1,2.9-0.7c-0.4,2.3-1.8,4.8-4.3,7.7 M750.5,363.4l-0.8,0.8 M754.3,350.5h4.3 M756.7,350.5l0.3-0.7"
          />
          <path
            fill="none"
            d="M754.4,349.8l-0.1,0.8 M754.3,350.5v0.3 M760.2,348.4h1 M759.9,350.5h1 M760.9,350.5l0.3-2.1 M758.8,348.4h1.4
	 M760.2,348.4l-0.3,2.1 M754.4,349.8h2.5c0.3-0.8,0.8-1.4,1.7-1.4h0.3 M758.8,348.4l-0.3,2.1 M759.9,350.5h-1.4 M753.2,367
	c1.7,0,4,1.4,7,4.4c1.7-0.8,2.8-1.8,3.2-2.6c1.4-5.1,1.9-9.2,1.7-12.1c-1.1-1.5-1.5-3-1.1-4.7c0-1-0.4-1.4-1.2-1.4 M762.7,350.5
	l0.3-2.1 M654.5,393.7c2.3-1.4,4.4-2.9,6.5-4.8 M672.5,366.1l-3.7,0.4 M668.8,366.6c0.1-1.4,0.1-2.8,0.1-4.3 M669,362.4l-0.3,4.3
	 M664.8,384.9c4.4-5.4,7-11.7,7.7-18.6 M669.7,347.7l-3.9,1.1 M668.7,356.7l3.3-1.2 M655.1,397c3.2-1.6,6.1-3.9,8.8-6.5
	 M620.3,397.6c5.1,2.5,10.6,3.6,16.6,3.6c5.9,0,11.4-1.1,16.4-3.4 M649.4,396c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2
	 M653.2,397.6l-3.4-1.1l-0.4-0.5 M653.2,397.6l0.1,0.1 M654.5,393.7l0.4,0.5l0.1,2.6 M653.3,397.8l1.8-0.8 M653.3,365.7
	c0.1,2.1,0.3,3.7,0.4,5 M668.6,368.9l0.3-2.2 M657.8,371c0.4-1.1,0.8-2.2,1-3.3 M668.8,366.6l-0.3,2.2 M664.8,384.9l-3.3-5.1
	 M663.9,390.5l1.4-1.4 M663.9,390.5l-2.5-1l-0.4-0.7 M665.3,389v-3.6l-0.4-0.5 M646.8,368.5l7,4.7c0.7,0.5,1.4,1.1,1.9,1.7l0.1,0.1
	c0.8,0.8,1.4,1.6,1.9,2.5l7.3,11.3l0.1,0.1 M661,388.8l-6.9-10.2l-0.7-0.8 M653.3,377.8l-0.4,0.4c-2.1,2.1-4.1,3.6-6.6,4.7
	 M653.7,370.7l3.2,2.3l0.4,0.4c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3 M668.6,368.9l-0.3,1.8c-0.4,1.1-1.4,1.9-2.8,2.6
	c-1.1,0.5-1.7,0.3-1.7-0.4c0-1.5-0.8-2.5-2.3-3c-0.8-0.1-1.4-1-2.1-2.2 M661.5,379.8l3,0.3l1.2-5.9h-1.9c-2.1-0.1-3.2-0.7-3.3-1.8
	c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1 M634.2,373.7l-2.5,8c-0.3,0.8-0.5,1.5-1,2.3l-0.1,0.3c-0.4,0.8-1,1.6-1.8,2.6l-8.4,10.2
	 M639.4,374.1l2.3,8c0.3,0.8,0.5,1.5,1,2.2l0.1,0.3c0.5,1,1.1,1.8,1.8,2.6l8.7,10.6 M646.3,382.9l0.5,1l7.6,9.8 M624.1,395.9
	l6.3-7.8c0.8-1,1.5-1.9,2.1-2.9l0.3-0.5 M632.9,384.6l4,0.3l3.6-0.3 M649.4,396l-6.3-7.7l-2.1-2.9l-0.4-0.7 M640.5,384.6
	c-0.3-0.4-0.4-1-0.5-1.4l-1.8-5.9c-0.1-1.2-0.5-1.8-1.1-1.6c-1.1-0.3-1.7,0.3-1.8,1.4l-1.8,5.9l-0.7,1.5 M634.2,373.7
	c0.5,0.7,1.2,1.1,2.1,1.2c1,0.1,1.8-0.1,2.5-0.7l0.5-0.5c0.4-0.4,0.7-1,0.8-1.6c0.1-1-0.1-1.8-0.7-2.5c-0.5-0.8-1.2-1.2-2.2-1.4
	c-1-0.1-1.8,0.1-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0,1.8,0.5,2.5L634.2,373.7L634.2,373.7z M637.1,370c-0.5-0.1-1,0-1.2,0.3
	c-0.4,0.3-0.5,0.7-0.7,1.1c0,0.4,0,0.8,0.3,1.2c0.3,0.4,0.7,0.5,1.1,0.7c0.4,0,0.8,0,1.2-0.3l0.3-0.3c0.3-0.3,0.4-0.5,0.4-0.8
	c0.1-0.5,0-1-0.3-1.2C637.9,370.3,637.6,370,637.1,370L637.1,370z M653.3,377.8c-0.4-0.4-1-0.7-1.5-1.1l-3.3-1.5l-1.9,0.1l-0.3,0.3
	l-0.3,0.1c-0.5,0.4-0.8,1.1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.5,1.8 M654.9,396.8l-8.8-11c-0.5-0.7-1.1-1.5-1.5-2.3
	c-0.4-0.8-0.7-1.8-1-3l-1.8-7.8c0-0.7,0.1-1.1,0.5-1.5l0.3-0.3l0.3-0.1h1.7l6.9,4.1c1.1,0.5,1.9,1.1,2.6,1.8
	c0.7,0.7,1.2,1.4,1.7,2.2l7.7,11.7 M646.8,368.5c0.8-0.4,1.4-1,1.8-1.6c0.4-0.8,0.5-1.6,0.3-2.6l-0.4-0.7c-0.3-0.5-0.7-1-1.4-1.2
	c-0.8-0.4-1.7-0.5-2.6-0.1c-1,0.3-1.5,0.8-1.9,1.6s-0.5,1.6-0.3,2.6s0.8,1.5,1.7,1.9c0.8,0.4,1.7,0.5,2.6,0.3L646.8,368.5
	L646.8,368.5z M644.2,364.5c-0.3,0.4-0.3,0.8-0.1,1.4c0.1,0.4,0.4,0.7,0.8,1h1.2c0.4-0.1,0.8-0.4,1-0.8c0.3-0.4,0.3-0.8,0.1-1.2
	l-0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.7l-1.2-0.1C644.6,363.8,644.4,364.1,644.2,364.5L644.2,364.5z M640.1,358.6
	c-1-0.8-1.9-1.2-3.2-1.2c-1.4,0-2.5,0.5-3.6,1.5c-1,1-1.4,2.2-1.4,3.6c0,0.4,0,0.8,0.1,1.2c0.1,0.7,0.5,1.4,1,1.9l0.3,0.4
	c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.5,2.9-1.4c1-1,1.4-2.1,1.5-3.3v-0.3c0-1.4-0.5-2.5-1.5-3.6L640.1,358.6L640.1,358.6z
	 M633.2,355.4c0.1-0.4,0.1-0.8-0.1-1.2c-0.3-0.4-0.5-0.7-1-0.8l-1.2,0.1c-0.4,0.1-0.7,0.5-0.8,1v0.4c-0.1,0.3,0,0.5,0.1,1
	c0.3,0.4,0.5,0.7,1,0.8l1.4-0.1C632.8,356.2,633.1,355.8,633.2,355.4L633.2,355.4z M632.4,351.7c-0.8-0.3-1.7-0.1-2.3,0.3
	c-0.8,0.4-1.4,1.1-1.7,1.9l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,1.9,1.6c1,0.3,1.8,0.3,2.6-0.1s1.4-1.1,1.7-1.9
	c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6L632.4,351.7L632.4,351.7z M645.7,354.9c0-0.8-0.4-1.6-1-2.2c-0.7-0.7-1.5-1-2.3-1
	l-0.8,0.1c-0.7,0.1-1.1,0.4-1.7,0.8c-0.7,0.7-1,1.5-1,2.5s0.3,1.8,1,2.3c0.7,0.7,1.5,1,2.3,1c1,0,1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3
	v-0.3L645.7,354.9L645.7,354.9z M643.5,353.8c-0.3-0.3-0.7-0.5-1.2-0.5h-0.4c-0.3,0-0.6,0.3-0.8,0.4c-0.4,0.3-0.5,0.7-0.5,1.2
	c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2
	C644.1,354.6,643.9,354.2,643.5,353.8L643.5,353.8z M653.3,358.8c0-1.8,0.1-3.2,0.3-4.4 M656.3,357.6v-0.8 M106.2,303l-19.5,15.7
	 M669,362.4l-5.6,2.2c-1.1,0.4-2.2,0.8-3.4,1.1l-0.4,0.1 M660.2,359.7l0.3-0.1l8.3-3 M656.3,365.9l-3-0.1 M656.3,365.9v0.7
	 M659.2,365.7l-3,0.1 M659.2,366.1v-0.3 M659.2,366.1l0.4-0.4 M656.3,366.6c1.4,0.1,2.3,0,2.9-0.4 M658.9,367.4h-2.8 M659.3,367.7
	l-0.1-0.1h-0.3 M659.3,367.7l-0.5,0.1 M658.9,367.7v-0.3 M656.3,367.4v-0.8 M656.3,367.4v0.3c0.1,1.8,0.7,2.9,1.7,3.3 M659.6,365.7
	h-0.4 M653.3,365.7h-1.8c-1.2-0.3-1.8,0-1.9,0.5c-0.5,1-0.3,1.6,0.8,2.2l3.2,2.2 M653.3,358.9l0.7,0.1l2.3,0.4 M660.2,359.7
	c-0.1-0.8-0.5-1.4-1.1-1.8c-0.7-0.4-1.5-0.5-2.8-0.4 M656.3,359.5l1.2,0.3h1.8l0.8-0.1 M656.3,357.6v1.9 M658.8,356.7h-2.5
	 M656.3,356.7v-0.3c0.1-1.6,0.6-2.8,1.4-3.2 M658.8,356.7c-0.3-1.2-0.7-2.3-1.2-3.4 M653.6,354.5l-0.8,0.8c-0.5,0.5-0.8,1.2-0.5,1.9
	l0.3,0.7c0.1,0.4,0.4,0.8,0.8,1 M647,342.2c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3 M670.9,343.7l-13.2,3.4c-1.1,0.3-2.1,0.7-2.9,1.1
	l-0.3,0.1c-0.8,0.4-1.5,0.8-2.2,1.4l-6.7,5.1 M665.8,348.8l-8.1,2.3c-0.3,0.1-0.7,0.3-1,0.4c-0.5,0.3-1.1,0.7-1.5,1.1l-1.7,1.8
	 M668.6,355.3l0.1,1.4 M668.6,355.3l-0.3-1.8c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5c0,1.5-0.8,2.5-2.3,3
	c-0.8,0.1-1.5,1-2.2,2.3h-0.6 M668.7,356.7l-0.1-1.4 M657.6,353.4c0.3-0.1,0.7-0.3,1.1-0.3c1.1,0.1,1.7-0.3,1.9-1.2
	c0.1-1.1,1.2-1.6,3.3-1.8h1.9v-1.2 M704.9,372.6l-0.7-9.9 M587.5,375.5c1.7,6.2,4.4,11.8,8.3,17.1l1.9,2.5l3.2,3.4
	c8.4,8.4,18.3,13.2,29.6,14.6l5.2,0.4l7.4-0.3c11.6-1.2,21.5-6.2,30-14.7c2.3-2.3,4.4-4.8,6.2-7.3c5.5-7.8,8.4-16.8,8.8-26.7
	 M697,363.5l0.7,10 M697.7,373.4l7.2-0.8"
          />
          <path
            fill="none"
            d="M675.6,357.8l-0.3-1.9 M675,358l-6.1,2.3l-6.2,2.3c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3 M672.7,360.9l-3.6,1.4
	 M672.5,366.1c0.1-1.2,0.1-2.5,0.1-3.9v-1.4 M675.3,357.9l-0.1,0.1 M672.7,360.9l0.8-0.3l2.2-2.9l-0.1,0.1 M665.3,389.1
	c7-7.4,10.6-16.4,10.6-26.7c0-1.6-0.1-3.2-0.3-4.7 M675.3,357.9l0.3-0.1 M675.3,357.9L675,358 M675.6,357.8l-0.4,0.1 M672,355.4
	c-0.5-2.8-1.4-5.2-2.5-7.7 M672,355.4l0.7-0.3l2.6,0.7 M675.3,355.8l-6.5,2.5l-6.6,2.5c-0.8,0.4-1.8,0.7-2.8,0.7
	c-1,0.1-2.1,0.1-3.2,0l-8-0.8c-0.5-0.1-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.6l6.1-5.4c0.8-0.8,1.7-1.5,2.5-1.9h0.1
	c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9 M688,364.5l9.1-1 M499.8,270.8l146.6-5.5l47.3-1.2 M672,345.4l-1.8,2.1l-0.7,0.3 M671.2,343.6h-0.3
	 M672,345.4l-1-1.8 M704.2,362.7l-0.7-9.8c-0.4-3.3-1-6.5-1.7-9.5c-2.3-8.9-6.9-16.9-13.3-24.2c-1.2-1.4-2.6-2.8-4-4l-0.1-0.1
	c-1.9-1.8-3.8-3.4-5.9-5l-1.5-1.1c-11.4-8.1-24.5-12.2-39.5-12.2c-15.5,0-29.3,4.4-41,13.2c-2.1,1.5-4,3.2-5.9,5
	c-5.8,5.4-10.2,11.4-13.3,17.7c-2.2,4.5-3.9,9.2-4.8,14.3c-0.3,1.8-0.4,3.6-0.5,5.2c-0.1,1.5-0.1,3-0.1,4.5 M697,363.5l-0.4-5.5
	c-1.1-12.4-5.6-23.4-13.6-32.9l-3.6-4c-1.4-1.5-2.9-2.8-4.3-4c-1.4-1.1-2.6-2.2-4-3.2c-2.1-1.5-4-2.8-6.2-3.9
	c-8.3-4.4-17.5-6.7-27.5-6.7c-10.2,0-19.3,2.2-27.7,6.7c-5.2,2.9-10,6.5-14.6,11.1c-8.8,9.1-14.3,19.4-16.4,31.2
	c-0.3,1.5-0.4,3-0.4,4.5 M688,364.5v-2.1c0-10.7-2.9-20.4-8.8-28.8c-1.8-2.6-3.9-5-6.2-7.3l-4.8-4.3c-8.9-7.2-19.4-10.6-31.4-10.6
	c-11.8,0-22.3,3.6-31.2,10.6c-1.7,1.4-3.3,2.8-4.8,4.3c-1.9,1.9-3.6,3.9-5.1,5.8c-6.6,8.7-9.9,18.8-9.9,30.3c0,4.7,0.5,9.1,1.7,13.2
	 M671.2,343.6c-1.8-3.2-4-6.1-6.7-8.8c-5.8-5.8-12.4-9.4-19.9-10.7 M653.2,346.8l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5
	 M670.9,343.7l-3.4-1.1l-0.7,0.1 M666.9,342.8c-1.4-1.9-2.9-3.9-4.7-5.6c-4.5-4.5-9.8-7.7-15.5-9.2 M642.6,323.9l-5.6-0.4l-5.4,0.4
	 M647,342.2l-0.4,1.5l-2.1,5.8c-0.5,1.1-0.4,1.9,0.4,2.5c0.4,0.4,1,0.3,1.9-0.5l5-3.7c0.4-0.4,0.8-0.7,1.4-0.8 M641.7,351.6l2.8-7.8
	l0.7-2.8c0.1-1.1,0.1-2.1,0.1-3.2l-0.8-13.6 M641.3,340.3l0.1-1.2l-0.4-12.2 M642.6,324.5l0.5,13.5c0.1,0.8,0,1.8-0.1,2.8
	c-0.1,1-0.5,1.9-1.1,3l-3.2,7.4c-0.4,0.6-0.8,0.8-1.4,1h-0.5c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4c-0.5-1.1-1-2.1-1.1-3
	c-0.1-1-0.3-1.9-0.1-2.8l0.5-14 M646.5,327.9l0.5,10v3.6l-0.1,0.7 M641.3,340.3l-4.4-0.4l-4.3,0.4 M633.1,326.9l-0.4,12.2l0.1,1.1
	 M632.7,340.3c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.7,1.1h0.7c0.7,0,1.2-0.3,1.7-1.1l1.8-3.2c0.3-0.5,0.5-1.1,0.7-1.6
	 M631.6,323.8l1.5,2.2v0.8 M641,326.9l-4.1-0.3l-3.9,0.3 M644.5,324.2l-1.9-0.3 M77.7,374.7c-0.4-0.5-0.4-1.4,0-2.3
	c1.5-3.6,3.2-5.6,5-6.2c2.9,0.3,5.1-0.1,6.6-1.2l2.8-3.3 M642.6,323.9l-1.5,2.2v0.8 M642.6,324.5v-0.5 M642.6,323.9v0.5
	 M644.5,324.2l2.1,2.9l0.1,0.8 M679.7,271.2c1.4,1.5,2.1,3.2,2.1,5.1v6.9c0,2.1-0.7,3.7-2.1,5.2c-1.4,1.4-3.2,2.1-5.2,2.1
	c-6.3,0.8-12.7,0.8-18.7,0c-2.1,0-3.7-0.7-5.1-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9c0-2.1,0.7-3.7,2.1-5.1c1.5-1.4,3.2-2.1,5.1-2.1
	c6.5-0.8,12.7-0.8,18.7,0C676.7,269.2,678.3,269.9,679.7,271.2L679.7,271.2z M694,264.1h-0.3 M693.7,264.1h0.3 M763.5,343.5h-1.8
	 M761.8,341.4h1.8 M763.8,329.1h-5.5 M762.1,336.3h-1 M763.6,326.8c-2.6,0-4.4,0.8-5.4,2.3 M762.2,334.1l-0.1,2.1 M764,336.3
	l0.1-2.1 M763.5,343.4l0.1-2.1 M762.9,348.4h1.4l0.4-3.6c0-1-0.5-1.4-1.4-1.4 M764,336.3h-1.9 M763.8,341.4h1.5l0.3-3.7
	c0-1-0.5-1.5-1.5-1.4 M169.5,375.9l11.6,0.3 M124.5,353.1c2.9-0.4,5.9-0.7,9.1-0.8l4.3-0.1 M137.4,355.8l-4.5-0.1
	c-3.2,0.1-6.2,0.3-9.1,0.8 M137.1,353.9H133c-3.2,0-6.1,0.3-8.8,0.7 M137.4,355.8l0.4-3.7 M137.3,357.6l-4.3-0.1
	c-3.2,0.1-6.2,0.3-9.1,0.8 M135.8,361.3h-3.3l-8.3,0.7 M136.3,360H133l-8.9,0.7 M122.4,362.3c1.4,2.2,4.1,2.9,8.4,2.2
	c3.7-0.7,5.9-2.9,6.5-6.5v-0.3 M137.3,357.6l0.1-1.8 M138,352.3l0.4-3.6 M126.4,349.2c-0.4,0.6-0.7,1.1-1.1,1.6 M123.7,349.8
	l-0.8,1.6 M121.6,355.1c0,0.5-0.1,1.2-0.1,1.8 M121.9,353.5c-0.1,0.4-0.3,1-0.3,1.6 M122.7,351.3l-0.4,0.8c-0.1,0.4-0.3,0.8-0.4,1.4
	 M123.9,356.5l-2.3,0.4 M121.6,355.1l2.5-0.4 M125.5,350.9l-2.8,0.5 M124.2,354.7c0.1-0.5,0.3-1.1,0.4-1.5 M123.7,349.8
	c1.2-2.1,2.5-3.2,3.9-3.2l6.1-1.6 M126.4,349.2l-2.9,0.5 M124.5,353.1l-2.6,0.4 M121.6,358.7c0,0.8,0.1,1.5,0.4,2.3 M122,361.1v0.4
	l0.4,0.7 M121.6,358.7l2.2-0.4 M124.2,362l-1.8,0.3 M124.1,360.8l0.1,1.2 M122,361l2.1-0.3 M121.5,356.9c0,0.5,0,1.2,0.1,1.8
	 M123.9,358.3v-1.8 M138.4,348.5c-4.3-0.1-8.3,0.1-11.8,0.7 M137.6,350.2c-4.4,0-8.4,0.1-12.1,0.7 M764,334.1h1.5v-2.8l-1.8-2.3
	 M763.8,329.1c1.8-0.1,2.8-1.2,2.8-3.2 M762.2,334.1h1.8 M761.1,336.3l0.1-2.1 M759.8,335.5c0.3-0.8,0.8-1.2,1.5-1.4 M759.8,336.3
	h-3 M756.9,335.5h2.9 M756.7,336.3l-1.1,6.5 M760.7,343.5l0.1-2.1 M761.8,341.4l-0.1,2.1 M758.2,343.5l0.3-0.7 M759.3,343.5l0.1-1.9
	 M760.7,343.4h-1.4 M761.7,343.4h-1 M759.3,343.5h-3.7 M755.6,342.8h2.9c0.3-0.5,0.5-1,1-1.1 M759.5,341.5c0.3-0.1,0.4-0.1,0.7-0.3
	h0.7 M760.9,341.4h1 M755.6,342.8l-0.1,0.8 M756.9,335.5l-0.1,0.8 M759.8,336.3v-0.8 M761.1,336.3h-1.4 M761.3,334.2h1 M758.2,329.1
	c-0.3,0.4-0.4,1-0.7,1.5l-0.8,4.8 M758.4,310.3l-1.8-6.2l-5-1.2 M766.5,326l-1-12.5c-0.1-1.5-0.5-2.3-1.5-2.5l-3.3-0.4l-2.5-0.3
	 M735.3,328.3c2.6-5.5,4.5-6.3,5.6-2.3c-0.3-3.4-1.1-5.1-2.8-4.8C736.2,320.5,735.3,322.8,735.3,328.3z"
          />
          <path
            fill="none"
            d="M726.9,298.6l-8.1-0.5c-8.3-0.4-16.5,0-24.5,1c-5.5,1.9-10.9,5.1-16.4,9.4l-1,0.7l-5.8,5"
          />
          <path
            fill="none"
            d="M718.8,298.1l-10-24.1c-2.5-6.1-7.3-9.4-14.7-9.9 M726.9,298.6l7,0.8l3.2,0.5c3.3,0.5,6.7,1.1,10.2,1.9l2.9,0.7
	l1.5,0.4 M694,264.1c7-0.4,14.2-1.8,21.5-3.9h0.1l18.4-4.5 M734,255.7c1.4,1.7,2.5,5.2,3.4,10.7"
          />
          <path
            fill="none"
            d="M726.9,298.6l-3.9-9.1l-0.3-0.7l-1.8-4.3l-5-11.7c-0.1-0.5-0.3-1-0.3-1.4c-0.3-2.6,1.5-4,5.6-4.3l14.7-1
	l1.7-0.1l4.1,16.4c0.6,2.5,2.5,4.8,5.6,6.7c1.9,1.6,3.3,6.1,4.4,13.3 M760.9,310.6l-0.5-8.4c-1.5-1-2.8-2.3-3.6-3.9l-4.8-24.8
	 M751.4,253.1c-2.2-0.6-2.9,1.5-2.2,6.1l2.6,14.3"
          />
          <path
            fill="none"
            d="M751.8,273.6c2.5-0.4,3.6-1.5,3.4-3.2l-3.4-15c-0.1-0.8-0.3-1.5-0.4-2.2 M602.9,343.5v-0.1 M602.9,343.3l-1,1.8
	 M602.5,345.2l-0.6-0.1 M602,345.1l0.5,0.1 M602.5,345.2h0.1l12.8,3.6c1,0.3,1.8,0.5,2.6,1l0.1,0.1c0.8,0.4,1.7,1.1,2.5,1.9l6.1,5.4
	c0.4,0.5,0.6,1.1,0.4,1.5v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-8,0.8c-1.2,0.1-2.2,0.1-3.2,0c-1.1-0.1-1.9-0.4-2.8-0.8l-12.7-4.7
	 M607.1,342.5l-0.7-0.1l-3.4,1.1 M627.6,327.9c-5.9,1.5-11.1,4.5-15.8,9.2c-1.8,1.6-3.2,3.4-4.5,5.4"
          />
          <path
            fill="none"
            d="M629.6,324.2c-7.7,1.4-14.4,5-20.2,10.7c-2.6,2.6-4.8,5.5-6.5,8.5"
          />
          <path
            fill="none"
            d="M629.6,324.6v-0.4l-2.1,3v0.7 M631.6,323.8l-1.9,0.4 M629.6,324.6v-0.4 M632.4,351.7l-2.9-8
	c-0.3-0.8-0.5-1.6-0.5-2.3v-0.3c-0.1-1-0.1-1.9-0.1-3.2l0.8-13.2 M627.6,327.9l-0.5,10c-0.1,1.2-0.1,2.3,0,3.6l0.1,0.7"
          />
          <path
            fill="none"
            d="M627.2,342.1c0.1,0.5,0.3,1.1,0.4,1.5l2.1,5.8c0.5,1.1,0.5,1.6,0,1.9c-0.8,0.7-1.7,0.4-2.5-0.4l-5-3.7
	c-0.4-0.3-0.8-0.5-1.2-0.7 M621,346.6l-0.8-0.4c-1.1-0.5-2.2-0.8-3.4-1.2l-9.6-2.5 M498.8,260.4l42.1-1.6c5.2-0.4,9.8-1.4,13.5-2.9
	 M497,357.1c3-10.6,5-22.8,5.9-36.6c0.7-19.4-0.4-35.9-3-49.5v-0.3 M496.2,260.5l2.6-0.1 M499.8,270.8l-3.6-10.3 M496.3,258.8
	l-0.1,1.6 M331.7,264.4l151.2-5.1l13.5-0.4 M497,357.1l-2.1,6.2 M495,363.3l52-1.2c10.6,0.5,18.8,3,24.6,7.6"
          />
          <path
            fill="none"
            d="M627.2,342.1c-2.2,1.1-4.1,2.5-6.1,4.4l-0.1,0.1 M628,354.5l-6.6-5c-0.7-0.5-1.2-1-1.9-1.2l-0.4-0.4
	c-1-0.4-1.9-0.8-2.9-1.1l-13.2-3.4 M617.4,351.3c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4"
          />
          <path
            fill="none"
            d="M614.6,359.5l1.8-0.1l3.6-0.7c0.8-0.1,1.4-0.5,1.5-1.2l0.3-0.7c0.3-0.7,0-1.4-0.5-1.9l-2.5-2.6
	c-0.4-0.4-1-0.8-1.4-1.1 M602,355.1l11.4,4.1l1.2,0.3 M625.2,363.4l-8.4,0.3l-2.3-0.1l-0.4-0.1c-1-0.1-1.9-0.4-3-0.8l-12.7-5.1
	 M601.3,360.6l9.2,3.6c1.2,0.4,2.3,0.8,3.4,1.1l0.7,0.1"
          />
          <path
            fill="none"
            d="M614.6,365.5h7.7c1.2-0.3,1.8,0.3,1.9,1.2c0.3,0.5,0,1-1.1,1.5l-5.1,3.6c-0.3,0.1-0.4,0.3-0.5,0.4 M625.2,363.4
	c-0.4,0.7-0.7,1.5-0.4,2.3c0.1,1,0.5,1.6,1.4,2.2l0.7,0.4c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.6-0.8,0.8-1.6,0.7-2.5
	c-0.1-1-0.7-1.6-1.4-2.2c-0.8-0.6-1.7-0.8-2.5-0.6c-1,0.1-1.7,0.5-2.2,1.4L625.2,363.4L625.2,363.4z"
          />
          <path
            fill="none"
            d="M626.5,365.5c0.1,0.4,0.3,0.8,0.7,1.1l0.4,0.1l1,0.1c0.4,0,0.8-0.3,1.1-0.7c0.3-0.4,0.4-0.8,0.3-1.2
	c-0.1-0.5-0.3-0.8-0.7-1.1s-0.8-0.4-1.2-0.3c-0.4,0.1-0.8,0.3-1.1,0.7C626.5,364.6,626.5,365,626.5,365.5L626.5,365.5z M617.4,351.3
	c-1.5,2.5-2.3,5.2-2.8,8.3 M598.5,355.7h0.5 M599.1,355.7l-0.5-0.1 M598.5,355.6v0.1 M598.5,355.6l2.6-0.7l0.8,0.3 M598.3,357.5
	c-0.1,1.6-0.3,3.2-0.3,5c0,10.2,3.4,19.1,10.3,26.4"
          />
          <path
            fill="none"
            d="M602.9,373.9c-1.2-3.6-1.8-7.4-1.8-11.4v-1.6 M598.4,357.5h-0.1 M598.4,357.5l2.1,2.9l0.8,0.3 M598.5,355.7
	l-0.3,1.8 M604.3,347.4c-1.1,2.5-1.9,5-2.5,7.7 M602,345.1l1.7,2.1l0.7,0.3 M609.7,390.2h0.1 M609.7,390.2v0.1V390.2 M609.7,390.2
	h0.1 M612.7,388.7l-0.5,0.7l-2.3,0.8 M608.9,384.6l-0.4,0.5l-0.1,3.7 M608.3,388.8l1.4,1.4 M602.9,373.9c1.2,3.9,3.2,7.4,5.9,10.7
	 M627.3,382.8c0.3-0.5,0.4-1.1,0.6-1.8l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.3l-0.3-0.3h-1.9l-3.3,1.5c-0.6,0.3-1.1,0.5-1.4,1"
          />
          <path
            fill="none"
            d="M609.7,390.2l7.7-11.6c0.4-0.8,1.1-1.5,1.8-2.2v-0.1c0.8-0.7,1.7-1.2,2.6-1.8l6.9-4.1l1.7,0.1l0.3,0.1l0.3,0.3
	c0.4,0.4,0.5,0.8,0.5,1.5l-1.8,7.8c-0.3,1.1-0.5,2.2-1,3v0.1c-0.4,0.8-1,1.6-1.5,2.2l-8.8,11 M617.5,372.2l-0.5,0.5 M617,372.8
	l-0.3-0.4 M617.5,372.2l-0.8,0.1 M608.9,384.6l5.4-8.4c0.7-1.1,1.4-2.1,2.2-2.9l0.5-0.5"
          />
          <path
            fill="none"
            d="M626.6,368.3l-6.9,4.7c-0.7,0.5-1.2,1-1.8,1.5l-0.3,0.3c-0.7,0.7-1.4,1.5-1.9,2.5l-7.4,11.4 M620.4,377.6
	l0.7,0.7c1.9,1.9,4,3.4,6.2,4.4 M620.4,377.6l-0.8,1l-6.9,10.2 M619.2,393.5l7.6-9.8l0.5-1 M614.6,365.5c0.3,2.5,1,4.7,2.1,6.9
	 M616.7,372.3l-13.8,1.5 M578.5,356.9v0.5c-0.1,3-0.3,5.9-0.3,8.9v10 M571.7,369.6v-8c0-1.5,0-3.2,0.1-4.7 M571.7,383.2v-13.6
	 M578.2,376.6l9.4-1.1 M571.7,383.2l6.5,0.4v-7 M571.7,356.9h6.7 M624.1,395.9l-0.4,0.6l-3.3,1.1 M618.5,396.8h0.1 M618.5,396.8
	l1.8,0.8 M620.4,397.5l-0.1,0.1 M620.7,397.2l-0.3,0.3 M618.6,396.8l0.1-2.8l0.4-0.5 M620.4,397.5l0.3-0.3 M609.8,390.2
	c2.8,2.6,5.6,4.8,8.8,6.6 M612.7,388.7c2.1,1.9,4.3,3.4,6.5,4.8 M571.7,356.9l-74.7,0.1 M602,345.1c-1.7,3.3-2.8,6.7-3.3,10.5
	 M334.1,251c-0.3-3.2-1-5.2-2.1-6.3 M554.4,256c1.7-0.7,3.2-1.4,4.5-2.2 M299.5,278.8l200.3-8 M496.2,260.5l-193.4,6.6 M554.4,256
	l4.5-2.3 M498.8,260.4L500,211 M496.3,258.8l1.1-47.9 M751.4,253.1c-0.3-0.7-0.5-1.1-1-1.5 M681.8,244l-26.5-11.1
	c-23.4-10.5-48.8-19-76.3-25.7c-7.7-1.9-15.7-3.7-23.8-5.2h-0.3 M734,255.7c-0.5-0.8-1.2-1.1-1.9-1.1l-31.1-2.9l-7.7-1.1
	 M693.3,250.6l4.1-0.1 M750.4,251.7c-0.5-0.6-1.4-0.7-2.2-0.7l-44.2-2.5c-2.3,1.1-4.5,1.6-6.6,1.9"
          />
          <path
            fill="none"
            d="M697.5,250.5l-4.3-1.6l-9.8-4.1 M681.9,248l-1.9-0.6 M693.3,250.6c-3.3-0.6-6.3-1.1-9.1-1.9l-2.5-0.7
	 M681.8,244l-1.8,3.4 M680,247.4l-1-0.4 M683.4,244.7l-1.5,3.3 M683.4,244.7l2.2-4.8l-0.4-0.6l-0.4-0.3h-0.5l-2.6,5 M705.4,197.8
	l-0.1-0.1H705l-0.1,0.1l-2.1,4.3v0.3l-17.7,36.6l0.4,0.3l0.1-0.1l17.7-36.6l0.1-0.1l2.1-4.3L705.4,197.8L705.4,197.8z"
          />
          <path
            fill="none"
            d="M685.2,239.3l-0.4-0.3h-0.5l-2.6,5l-1.8,3.4l1.9,0.6l1.5-3.4l2.2-4.8L685.2,239.3L685.2,239.3z M683.4,244.7
	l-1.5,3.4l2.5,0.7c3.4,0.6,6.5,0.6,8.9,0L683.4,244.7L683.4,244.7z"
          />
          <path
            fill="none"
            d="M679,247l1,0.4l1.8-3.4l-26.5-11.1c-23.4-10.5-48.8-19-76.3-25.7l-1.9,2.9C602.5,216.9,636.6,229.3,679,247
	L679,247z"
          />
          <path
            fill="none"
            d="M680,247.4l-1-0.4c-42.4-17.9-76.3-30.3-102.1-37.1c-8.5-2.3-16.1-4-22.7-5.2c-22.6-2.5-46.5-3.2-71.9-2.1
	c-55.2,1-91.8,4.3-110.1,10.2l1,0.3c4,1.2,9.9,1.4,17.7,0.6l31.2-2.1c21.5-0.6,42.9-0.7,64.4-0.6H500c10.3,0.1,20.8,0.3,31.1,0.6
	c4.7,0.3,9.4,0.8,14,1.5c5.5,1,11,2.2,16.2,3.9l19.7,7c6.9,3,9.1,5.9,6.6,8.7c-11,9.6-20.6,16.6-28.6,21c-1.4,0.8-2.9,1.6-4.5,2.2
	c-3.7,1.5-8.3,2.5-13.5,2.9l-42.1,1.6l-2.6,0.1l3.6,10.3l146.6-5.5l47.3-1.2h0.4c7-0.4,14.2-1.8,21.5-3.9h0.1l18.4-4.5
	c-0.5-0.8-1.2-1.1-1.9-1.1l-31.1-2.9l-7.7-1.1c-3.3-0.6-6.3-1.1-9.1-1.9L682,248L680,247.4L680,247.4z"
          />
          <path
            fill="none"
            d="M697.5,250.5l-4.1-1.8c-2.5,0.6-5.5,0.6-8.9,0c2.8,0.7,5.8,1.4,9.1,1.9L697.5,250.5L697.5,250.5z"
          />
          <path
            fill="none"
            d="M703.9,248.5c-2.3,1.1-4.5,1.6-6.6,1.9l-4.1,0.1l7.7,1.1l31.1,2.9c0.7,0,1.4,0.4,1.9,1.1l16.4-4
	c-0.5-0.6-1.4-0.7-2.2-0.7L703.9,248.5L703.9,248.5z"
          />
          <path
            fill="none"
            d="M751.4,253.1c-0.3-0.7-0.5-1.1-1-1.5l-16.4,4.1c1.4,1.7,2.5,5.2,3.4,10.7l4.1,16.4c0.5,2.5,2.5,4.8,5.6,6.7
	c1.9,1.7,3.3,6.1,4.4,13.3l5,1.2l1.8,6.2l2.5,0.3l-0.5-8.4c-1.5-1-2.8-2.3-3.6-3.9l-4.8-24.8l-2.6-14.3
	C748.3,254.6,749.2,252.5,751.4,253.1L751.4,253.1z"
          />
          <path
            fill="none"
            d="M755.1,270.3l-3.4-15c-0.1-0.8-0.3-1.5-0.4-2.2c-2.2-0.6-2.9,1.5-2.2,6.1l2.6,14.3
	C754.1,273,755.2,272.1,755.1,270.3L755.1,270.3z M747.4,293c-0.5-1.4-1.2-2.5-1.8-3.2c-2.5-2.1-4.3-4.1-5.4-5.9
	c-1.9-4.4-3.3-9.9-4.1-16.1l-4,0.7c1.2,0.4,2.2,1.1,3,2.3c0.3,0,0.5,0.7,0.8,2.1c0.4,1.5,0.5,3.2,0.7,5.2c0.1,2.1,0.1,3.9,0.1,5.2
	c0,1.2-0.1,1.9-0.4,2.2c-0.6,0.8-1.5,1.4-2.8,1.7c1.8,0.8,3.9,1.4,6.2,1.9C743.5,290.1,746.1,291.3,747.4,293L747.4,293z"
          />
          <path
            fill="none"
            d="M745.5,289.8c0.7,0.7,1.2,1.8,1.8,3.2c1,2.2,1.8,5.4,2.8,9.4l1.5,0.4c-1.1-7.3-2.6-11.7-4.4-13.3
	c-3.2-2.1-5-4.3-5.6-6.7l-4.1-16.4l-1.7,0.1l0.1,1.2c1,6.3,2.3,11.7,4.1,16.1C741.3,285.8,743.1,287.7,745.5,289.8L745.5,289.8z"
          />
          <path
            fill="none"
            d="M731.4,268.5l0.5-0.1l4-0.7l-0.1-1.2l-14.7,1c-4.1,0.3-5.9,1.6-5.6,4.3l10.2,0.1c0-1.5,1.4-2.6,4.3-3
	L731.4,268.5L731.4,268.5z"
          />
          <path
            fill="none"
            d="M732.1,268.5l-0.5,0.1c-0.4,1.4-0.6,3.2-0.6,5.5c0,1.5,0.1,3.3,0.3,5.4l0.3,2.9c0.4,2.2,1.1,3.9,1.9,5.1h0.1
	c1.2-0.3,2.2-0.8,2.8-1.7h-0.1c-0.3,0-0.5-0.7-1-2.1c-0.3-1.4-0.5-3.2-0.7-5.2c-0.1-2.1-0.3-3.9-0.1-5.4s0.3-2.2,0.6-2.2
	C734.3,269.6,733.4,268.8,732.1,268.5L732.1,268.5z"
          />
          <path
            fill="none"
            d="M725.8,272.6l0.4,1.4h4.8c0-2.3,0.1-4.1,0.5-5.5l-1.5,0.3c-2.9,0.5-4.3,1.5-4.3,3
	C725.6,272.1,725.6,272.3,725.8,272.6L725.8,272.6z"
          />
          <path
            fill="none"
            d="M726.2,274l-0.4-1.4c0-0.3-0.1-0.5-0.1-0.8l-10.2-0.1c0,0.4,0.1,1,0.3,1.4l5,11.7h8.3l-0.7-2.5L726.2,274
	L726.2,274z"
          />
          <path
            fill="none"
            d="M731,274h-4.8l2.3,8.1h3.2l-0.3-2.9C731,277.3,731,275.5,731,274L731,274z"
          />
          <path
            fill="none"
            d="M735.8,266.5l1.7-0.1c-1-5.5-2.1-9.1-3.4-10.7l-18.4,4.5h-0.1c-7.3,2.1-14.4,3.3-21.5,3.9
	c7.4,0.5,12.4,3.9,14.7,9.9l10,24.1l8.1,0.5l-3.9-9.1l-0.3-0.7l-1.8-4.3l-5-11.7c-0.1-0.5-0.3-1-0.3-1.4c-0.3-2.6,1.5-4,5.6-4.3
	L735.8,266.5L735.8,266.5z"
          />
          <path
            fill="none"
            d="M736,272.9c-0.3-1.4-0.5-2.1-0.8-2.1c-0.3,0-0.4,0.8-0.5,2.2c-0.1,1.5,0,3.3,0.1,5.4c0.1,2.1,0.4,3.9,0.7,5.2
	c0.3,1.4,0.5,2.2,1,2.1h0.1c0.1-0.3,0.3-1,0.4-2.2c0.1-1.5,0.1-3.2-0.1-5.2S736.2,274.4,736,272.9L736,272.9z M722.6,289l0.3,0.7h8
	c3.4,0.5,8.1,2.1,13.8,4.3c0.1,0.7-2.6,0.7-8.1,0c5.5,0.7,8.3,0.7,8.1,0c-5.8-2.3-10.3-3.7-13.8-4.3h-7.3c4.4,1.4,8.5,2.1,12.2,2.1
	h0.1l1.1,8.3c3.3,0.5,6.7,1.1,10.2,1.9c-0.4-4-1-6.7-1.8-8.1c-5.2-2.5-9.8-4-13.6-4.7h-9.2L722.6,289L722.6,289z"
          />
          <path
            fill="none"
            d="M720.8,284.7l1.8,4.3h9.2c3.8,0.7,8.4,2.2,13.6,4.7c0.8,1.5,1.4,4.3,1.8,8.1l2.9,0.7c-1-4-1.8-7.2-2.8-9.4
	c-1.2-1.6-3.7-2.9-7.4-3.7c-2.3-0.5-4.4-1.2-6.2-1.9h-0.1c-1.8-0.8-3.2-1.6-4.4-2.6L720.8,284.7L720.8,284.7z"
          />
          <path
            fill="none"
            d="M723.6,289.5h-0.7l3.9,9.1l7,0.8c-0.8-5.1-0.1-7.7,1.9-7.7C732.1,291.7,728,291,723.6,289.5L723.6,289.5z
	 M731.6,282.2h-3.2l0.7,2.5c1.2,1,2.6,1.9,4.4,2.6C732.7,286.1,732,284.4,731.6,282.2L731.6,282.2z"
          />
          <path
            fill="none"
            d="M733.9,299.4l-7-0.8l-8.1-0.5c-8.4-0.4-16.5,0-24.5,1c-5.5,1.9-10.9,5.1-16.4,9.4l-1,0.7l1.5,1.1
	c2.1,1.5,4,3.2,5.9,5l0.1,0.1c1.4,1.4,2.8,2.6,4,4c6.6,7.3,11,15.4,13.3,24.2c0.8,3,1.4,6.2,1.7,9.5l0.7,9.8l12.2-1.2
	c8.9-1.5,15.5-3.7,20.1-6.6c-0.5-0.7-1-1.6-1.1-2.9l-0.1-1v-22.7c0-5.5,1-7.8,2.9-7.1c1.5-0.3,2.5,1.4,2.8,4.8c-1.1-4-3-3.2-5.6,2.3
	V351l0.1,1c1.5,1.8,3.9,3.2,7.2,4.3h-2.8c-1.5,0-2.6-0.4-3.3-1.4c-4.5,2.9-11.3,5.1-20.1,6.6c9.2,0,21.5-1.5,36.7-4.1l1.1-6.7v-0.3
	l0.1-0.8l1.1-6.2l0.1-0.8l1.1-6.5l0.1-0.8l0.8-4.8c0.1-0.5,0.4-1.1,0.7-1.5c1-1.6,2.8-2.5,5.4-2.3c-2.6,0-4.4,0.8-5.4,2.3h5.5
	c1.8-0.1,2.8-1.2,2.8-3.2v0.1l-1-12.5c-0.1-1.5-0.5-2.3-1.5-2.5l-3.3-0.4l-2.5-0.3l-1.8-6.2l-5-1.2l-1.5-0.4l-2.9-0.7
	c-3.4-0.8-6.7-1.4-10.2-1.9L733.9,299.4L733.9,299.4z"
          />
          <path
            fill="none"
            d="M736,291.7c-2.2,0-2.9,2.6-2.1,7.7l3.2,0.5L736,291.7L736,291.7z M718.8,298.1l-10-24.1
	c-2.5-6.1-7.3-9.4-14.7-9.9h-0.3l-47.3,1.2l-146.6,5.5v0.3c2.6,13.5,3.7,30,3,49.5c-1,13.8-2.9,25.9-5.9,36.6l74.8-0.1
	c0-1.5,0.1-3,0.1-4.5c0.1-1.8,0.3-3.6,0.5-5.2c1-5,2.6-9.8,4.8-14.3c3.2-6.5,7.7-12.4,13.3-17.7c1.9-1.8,3.9-3.4,5.9-5
	c11.7-8.8,25.3-13.2,41-13.2c15,0,28.1,4.1,39.5,12.2l1-0.7c5.5-4.3,10.9-7.4,16.4-9.4C702.3,298.1,710.4,297.6,718.8,298.1
	L718.8,298.1z M674.6,269.2c2.1,0,3.7,0.7,5.2,2.1c1.4,1.5,2.1,3.2,2.1,5.1v6.9c0,2.1-0.7,3.7-2.1,5.2c-1.4,1.4-3.2,2.1-5.2,2.1
	c-6.3,0.8-12.7,0.8-18.7,0c-2.1,0-3.7-0.7-5.1-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9c0-2.1,0.7-3.7,2.1-5.1c1.5-1.4,3.2-2.1,5.1-2.1
	C662.2,268.3,668.6,268.3,674.6,269.2L674.6,269.2z"
          />
          <path
            fill="none"
            d="M679.7,271.2c-1.4-1.4-3.2-2.1-5.2-2.1c-6.1-0.8-12.2-0.8-18.7,0c-2.1,0-3.7,0.7-5.1,2.1
	c-1.4,1.5-2.1,3.2-2.1,5.1v6.9c0,2.1,0.7,3.7,2.1,5.2c1.5,1.4,3.2,2.1,5.1,2.1c6.1,0.8,12.4,0.8,18.7,0c2.1,0,3.7-0.7,5.2-2.1
	c1.4-1.5,2.1-3.2,2.1-5.2v-6.9C681.9,274.4,681.2,272.7,679.7,271.2L679.7,271.2z M735.3,328.3c2.6-5.5,4.5-6.3,5.6-2.3
	 M758.2,329.1c-0.3,0.4-0.4,1-0.7,1.5l-0.8,4.8h2.9c0.3-0.8,0.8-1.2,1.5-1.4h4.3v-2.8l-1.8-2.3L758.2,329.1L758.2,329.1z"
          />
          <path
            fill="none"
            d="M759.8,336.3h1.4l0.1-2.1c-0.7,0-1.2,0.5-1.5,1.4L759.8,336.3L759.8,336.3z"
          />
          <path
            fill="none"
            d="M761.1,336.3h-4.4l-1.1,6.5h2.9c0.3-0.5,0.5-1,1-1.1c0.3-0.1,0.4-0.1,0.7-0.3h5l0.3-3.7c0-1-0.5-1.5-1.5-1.4
	L761.1,336.3L761.1,336.3z"
          />
          <path
            fill="none"
            d="M759.8,336.3v-0.8h-2.9l-0.1,0.8 M755.6,342.8l-0.1,0.7h2.8l0.3-0.7 M760.9,341.4h-0.7c-0.3,0-0.5,0.1-0.7,0.3
	l-0.1,1.9h1.4L760.9,341.4L760.9,341.4z"
          />
          <path
            fill="none"
            d="M759.3,343.5h-3.7l-1.1,6.2h2.5c0.3-0.8,0.8-1.4,1.7-1.4h5.8l0.5-3.6c-0.1-1-0.5-1.4-1.4-1.4h-4.3V343.5
	L759.3,343.5z"
          />
          <path
            fill="none"
            d="M761.8,341.4l-0.1,2.1h1.8l0.3-2.1 M764,334.1h-1.8l-0.1,2.2h1.9 M693.7,264.1h0.3 M636.9,311.4
	c12,0,22.3,3.6,31.4,10.6l4.8,4.3c2.3,2.3,4.4,4.8,6.2,7.3c5.9,8.4,8.8,18,8.8,28.8v2.1l9.1-1l-0.4-5.5
	c-1.1-12.4-5.6-23.4-13.6-32.9l-3.6-4c-1.4-1.5-2.9-2.8-4.3-4c-1.4-1.1-2.6-2.2-4-3.2c-2.1-1.5-4-2.8-6.2-3.9
	c-8.3-4.4-17.5-6.7-27.5-6.7c-10.2,0-19.3,2.2-27.7,6.7c-5.2,2.9-10,6.5-14.6,11.1c-8.8,9.1-14.3,19.4-16.4,31.2
	c-0.3,1.5-0.4,3-0.4,4.5v0.5c-0.1,3-0.3,5.9-0.3,8.9v10l9.2-1c-1.1-4.1-1.7-8.5-1.7-13.2c0-11.4,3.3-21.5,9.9-30.3
	c1.5-2.1,3.2-4,5.1-5.8c1.5-1.5,3.2-3,4.8-4.3C614.6,315,625.1,311.4,636.9,311.4L636.9,311.4z"
          />
          <path
            fill="none"
            d="M668.3,322c-8.9-7.2-19.4-10.6-31.4-10.6c-11.8,0-22.3,3.6-31.2,10.6c-1.7,1.4-3.3,2.8-4.8,4.3
	c-1.9,1.9-3.6,3.9-5.1,5.8c-6.6,8.7-9.9,18.8-9.9,30.3c0,4.7,0.5,9.1,1.7,13.2c1.7,6.2,4.4,11.8,8.3,17.1l1.9,2.5l3.2,3.4
	c8.4,8.4,18.3,13.2,29.6,14.6l5.2,0.4l7.4-0.3c11.6-1.2,21.5-6.2,30-14.7c2.3-2.3,4.4-4.8,6.2-7.3c5.5-7.8,8.4-16.8,8.8-26.7v-2.1
	c0-10.7-2.9-20.4-8.8-28.8c-1.8-2.6-3.9-5-6.2-7.3L668.3,322L668.3,322z M642.6,323.9l1.9,0.3c7.6,1.4,14.2,5,19.9,10.7
	c2.8,2.8,5,5.6,6.7,8.8l1,1.8c1.7,3.3,2.8,6.7,3.3,10.5l0.3,1.9c0.1,1.5,0.3,3,0.3,4.7c0,10.3-3.6,19.3-10.6,26.7l-1.4,1.4
	c-2.8,2.6-5.6,4.8-8.8,6.5l-1.8,0.8c-5,2.3-10.5,3.4-16.4,3.4c-6.1,0-11.6-1.2-16.6-3.6l-1.8-1c-3.2-1.6-6.1-3.9-8.8-6.6h-0.1
	l-1.4-1.4c-6.9-7.4-10.3-16.2-10.3-26.4c0-1.6,0.1-3.3,0.3-5l0.3-1.8v-0.1c0.7-3.7,1.8-7.1,3.3-10.5l1-1.8c1.7-3,3.9-5.8,6.5-8.5
	c5.8-5.8,12.5-9.4,20.2-10.7l1.9-0.3l5.4-0.3L642.6,323.9L642.6,323.9z"
          />
          <path
            fill="none"
            d="M644.5,324.2l-1.9-0.3v0.5l0.5,13.5c0.1,0.8,0,1.8-0.1,2.8l-0.4,1.7h2.3l0.3-1.4c0.1-1.1,0.1-2.1,0.1-3.2
	L644.5,324.2L644.5,324.2z"
          />
          <path
            fill="none"
            d="M646.5,327.9v-0.7l-2.1-2.9l0.8,13.6c0.1,1.1,0,2.1-0.1,3.2l-0.3,1.4h2.1v-0.1l0.1-0.7c0.1-1.2,0.1-2.5,0-3.6
	L646.5,327.9L646.5,327.9z"
          />
          <path
            fill="none"
            d="M653.7,346.5l-0.7,0.3c-0.4,0.3-1,0.5-1.4,0.8l-5,3.7c-0.8,0.8-1.5,1.1-1.9,0.5c-0.8-0.5-1-1.4-0.4-2.5l2.1-5.8
	l0.3-1.4h-2.1l-0.3,1.4l-2.8,7.8l2.8-7.8l0.3-1.4h-2.3c-0.1,0.4-0.4,1-0.5,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.5
	c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4c-0.3-0.4-0.4-1-0.5-1.4h-2.3c0.1,0.4,0.3,1,0.4,1.4l2.9,8h0.1c1,0.3,1.5,0.8,1.9,1.6
	c0.4,0.8,0.5,1.6,0.3,2.6s-0.8,1.5-1.7,1.9c-0.8,0.4-1.7,0.4-2.6,0.1c-1-0.3-1.5-0.8-1.9-1.6c-0.3-0.5-0.4-1.1-0.3-1.8l0.1-0.8
	c0.3-0.8,0.8-1.5,1.7-1.9s1.7-0.5,2.3-0.3l-2.9-8c-0.1-0.4-0.3-1-0.4-1.4h-2.1c0.1,0.4,0.1,1,0.3,1.4l2.1,5.8c0.5,1.1,0.5,1.6,0,1.9
	c-0.8,0.7-1.7,0.6-2.5-0.4l-5-3.7c-0.4-0.3-0.8-0.5-1.2-0.7l-0.8-0.4c-1.1-0.6-2.2-0.8-3.4-1.2l-9.6-2.5l-0.8-0.1l-3.4,1.1l13.2,3.4
	c1,0.3,1.9,0.7,2.9,1.1l0.4,0.3c0.7,0.3,1.4,0.8,1.9,1.2l6.6,5l-6.6-5c-0.7-0.5-1.2-1-1.9-1.2l-0.1-0.1c-1-0.4-1.9-0.8-2.9-1.1
	l-13.2-3.4l-1,1.8l0.5,0.1h0.1l12.8,3.6c1,0.3,1.8,0.5,2.6,1l0.1,0.1c0.8,0.4,1.7,1.1,2.5,1.9l6.1,5.4c0.4,0.5,0.5,1.1,0.4,1.5v0.3
	l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-4.7,0.4h11.3c0.3-0.7,0.7-1.4,1.2-2.1c1-1,2.2-1.5,3.6-1.5c1.2,0,2.3,0.4,3.2,1.2l0.4,0.3
	c0.5,0.5,1,1.2,1.2,2.1h8.4l-1.8-0.1c-0.5-0.3-1.1-0.5-1.4-1l-0.1-0.3V359c-0.1-0.5,0.1-1.1,0.4-1.6l6.1-5.4
	c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9h0.1l-1-1.8h-0.3l-13.2,3.4c-1.1,0.3-2.1,0.7-2.9,1.1l-0.3,0.1
	c-0.8,0.4-1.5,0.8-2.2,1.4l-6.7,5.1v0.3c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.3,1c-1,0-1.8-0.3-2.3-1c-0.7-0.7-1-1.4-1-2.3
	s0.3-1.8,1-2.5c0.4-0.4,1-0.7,1.7-0.8l0.8-0.1c1,0,1.8,0.3,2.3,1c0.5,0.7,1,1.4,1,2.2l6.7-5.1c0.7-0.5,1.4-1,2.2-1.4l0.3-0.1
	c0.8-0.4,1.8-0.7,2.9-1.1l13.2-3.4l-3.4-1.1l-0.7,0.1l-9.8,2.5C655.9,345.7,654.8,346.1,653.7,346.5L653.7,346.5z M675.3,355.8
	l-6.5,2.5l-6.6,2.5c-0.1,0-0.1,0.1-0.3,0.1h5.9l1.1-0.4l6.1-2.3l0.1-0.1l0.4-0.1L675.3,355.8L675.3,355.8z"
          />
          <path
            fill="none"
            d="M669,360.4l-1.1,0.5h5.1l0.4-0.3l2.2-2.9l-0.3,0.1L675,358 M629.6,324.2v0.5l-0.8,13.2c0,1.1,0,2.2,0.1,3.2v0.3
	c0,0.4,0.1,0.7,0.3,1.1h2.3c-0.3-0.5-0.4-1.1-0.4-1.6c-0.1-1-0.3-1.9-0.1-2.8l0.5-14L629.6,324.2L629.6,324.2z"
          />
          <path
            fill="none"
            d="M629.6,324.6v-0.4l-2.1,2.9v0.7l-0.5,10c-0.1,1.2-0.1,2.3,0,3.6l0.1,0.7v0.3h2.1c-0.1-0.4-0.1-0.7-0.3-1.1V341
	c-0.1-1-0.1-1.9-0.1-3.2L629.6,324.6L629.6,324.6z M599.1,355.7h-0.4l-0.3,1.8h0.1l8.4,3.3h5.9c-0.4-0.1-0.7-0.3-1-0.4L599.1,355.7
	L599.1,355.7z M598.4,357.5l2.1,2.9l0.8,0.3l0.4,0.3h5.1"
          />
          <path
            fill="none"
            d="M632,362.4c0-0.5,0.1-1.1,0.3-1.5H621l-3.3,0.3c-1.2,0.1-2.2,0.1-3.2,0c-0.7,0-1.2-0.3-1.7-0.4h-5.9l4.3,1.6
	c1.1,0.4,2.1,0.7,3,0.8l0.4,0.1l2.3,0.1l8.4-0.3l0.1-0.1c0.5-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2
	c0.1,1,0,1.8-0.7,2.5c-0.5,0.7-1.2,1.2-2.2,1.4c-0.7,0.1-1.2,0-1.8-0.3l-0.7-0.4c-0.8-0.5-1.2-1.2-1.4-2.2c-0.1-0.8,0-1.6,0.4-2.3
	l-8.4,0.3l-2.3-0.1l-0.4-0.1c-1-0.1-1.9-0.4-3-0.8l-4.3-1.6h-5.2l8.8,3.4c1.2,0.4,2.3,0.8,3.4,1.1l0.7,0.1h7.7
	c1.2-0.3,1.8,0.3,1.9,1.2c0.3,0.5,0,1-1.1,1.5l-5.1,3.6c-0.3,0.1-0.4,0.3-0.5,0.4l-0.5,0.5l-0.5,0.5c-0.7,0.8-1.4,1.8-2.2,2.9
	l-2.1,3.2h2.3l1.2-2.1c0.5-1,1.2-1.8,1.9-2.5l0.3-0.3c0.5-0.5,1.1-1,1.8-1.5l6.9-4.7l-6.9,4.7c-0.7,0.5-1.2,1-1.8,1.5l-0.3,0.3
	c-0.7,0.7-1.4,1.5-1.9,2.5l-1.2,2.1h2.5l0.5-0.8c0.4-0.8,1.1-1.5,1.8-2.2v-0.1c0.8-0.7,1.7-1.2,2.6-1.8l6.9-4.1l1.7,0.1l0.3,0.1
	l0.3,0.3c0.3,0.4,0.5,0.8,0.5,1.5l-1.5,6.9h2.6l1.7-5.6l-0.1-0.1c-0.5-0.8-0.8-1.6-0.5-2.5c0.1-1,0.5-1.6,1.4-2.2
	c0.8-0.6,1.7-0.7,2.5-0.6c1,0.1,1.7,0.5,2.2,1.4c0.5,0.7,0.8,1.6,0.7,2.5c-0.1,0.7-0.4,1.2-0.8,1.6l-0.5,0.5
	c-0.7,0.5-1.5,0.8-2.5,0.7c-0.8-0.1-1.5-0.5-2.1-1.2l-1.7,5.6h2.1l0.7-2.2c0.1-1.2,0.8-1.7,1.8-1.4c0.5-0.1,1,0.4,1.1,1.6l0.5,2.1
	h2.1l-1.5-5.2l1.5,5.2h2.6l-1.5-6.7c0-0.7,0.1-1.1,0.5-1.5l0.3-0.3l0.3-0.1h1.7l6.9,4.1c1.1,0.5,1.9,1.1,2.6,1.8
	c0.7,0.7,1.2,1.4,1.7,2.2l0.4,0.5h2.5l-1.2-1.9c-0.5-0.8-1.1-1.6-1.9-2.5l-0.1-0.1c-0.5-0.7-1.2-1.2-1.9-1.7l-7-4.7h-0.1
	c-1,0.3-1.8,0.3-2.6-0.3c-0.8-0.4-1.4-1.1-1.7-1.9c-0.3-1-0.3-1.8,0.3-2.6c0.4-0.8,1.1-1.4,1.9-1.6c1-0.3,1.8-0.3,2.6,0.1
	c0.5,0.3,1.1,0.7,1.4,1.2l0.3,0.7c0.3,1,0.3,1.8-0.3,2.6c-0.4,0.8-1,1.4-1.8,1.6l7,4.7c0.7,0.5,1.4,1.1,1.9,1.6l0.1,0.1
	c0.8,0.8,1.4,1.6,1.9,2.5l1.2,1.9h2.2l-1.9-3c-0.7-1.1-1.4-2.1-2.2-2.9l-0.4-0.4l-3.2-2.3l-3.2-2.2c-1.1-0.5-1.4-1.2-0.8-2.2
	c0-0.5,0.7-0.8,1.9-0.5h8.7c1.2-0.3,2.5-0.7,3.4-1.1l5.6-2.2l3.6-1.4l0.3-0.1h-5.2l-5,1.9c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3
	l8.3,0.3l2.8-0.3c1-0.3,2.1-0.5,3-1l5-1.9h-5.9c-0.8,0.3-1.7,0.5-2.5,0.7c-1,0.1-2.1,0.1-3.2,0l-6.2-0.5h-8.4c0.1,0.5,0.3,1,0.3,1.5
	v0.1c0,1.2-0.5,2.3-1.5,3.3c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2c-1.1-0.1-2.1-0.6-2.9-1.4l-0.3-0.4c-0.5-0.5-0.8-1.2-1-1.9
	C632.1,363.3,632,362.8,632,362.4L632,362.4z M630,379.4l-0.3,1c-0.3,1.1-0.5,2.2-1,3v0.1c-0.4,0.8-1,1.6-1.5,2.2l-8.8,11l1.8,1
	l0.1-0.1l0.3-0.3l8.4-10.2c0.7-0.8,1.2-1.8,1.8-2.6l0.1-0.3c0.4-0.8,0.7-1.5,1-2.3l0.8-2.5L630,379.4L630,379.4z"
          />
          <path
            fill="none"
            d="M634.7,379.4h-2.1l-0.8,2.5c-0.3,0.8-0.6,1.5-1,2.3l-0.1,0.3c-0.4,0.8-1,1.6-1.8,2.6l-8.4,10.2l-0.3,0.3
	l3.3-1.1l0.4-0.5l6.3-7.8c0.8-1,1.5-1.9,2.1-2.9l0.3-0.5l0.7-1.5L634.7,379.4L634.7,379.4z M614.5,379.4h-2.3l-3.3,5.2l-0.4,0.5
	l-0.1,3.7"
          />
          <path
            fill="none"
            d="M608.3,388.8l1.4,1.4l7.3-10.9h-2.5 M661.3,379.4h-2.2l6.1,9.5l0.1,0.1l-0.1-3.6l-0.3-0.5l-3.3-5.1
	 M640.9,379.4h-2.1l1.2,3.9c0.1,0.5,0.3,1,0.5,1.4l0.4,0.7c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.7l0.4,0.6l3.4,1.1l-8.7-10.6
	c-0.7-0.8-1.2-1.6-1.8-2.6l-0.1-0.3c-0.3-0.7-0.7-1.5-1-2.2L640.9,379.4L640.9,379.4z"
          />
          <path
            fill="none"
            d="M643.8,380.5l-0.3-1.1h-2.6l0.8,2.6c0.3,0.8,0.5,1.5,1,2.2l0.1,0.3c0.5,1,1.1,1.8,1.8,2.6l8.7,10.6l0.1,0.1
	l1.8-0.8l-0.1-0.1l-8.8-11c-0.5-0.7-1.1-1.5-1.5-2.3C644.4,382.8,644.1,381.7,643.8,380.5L643.8,380.5z M665.3,389l-0.1-0.1
	l-6.1-9.5h-2.6l7.4,11.1l1.4-1.4 M640.5,358.9l-0.3-0.3c-1-0.8-1.9-1.2-3.2-1.2c-1.4,0-2.5,0.5-3.6,1.5c-1,1-1.4,2.2-1.4,3.6
	c0,0.4,0,0.8,0.1,1.2c0.1,0.7,0.5,1.4,1,1.9l0.3,0.4c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.5,2.9-1.4c1-1,1.4-2.1,1.5-3.3v-0.3
	C641.9,361.1,641.5,359.8,640.5,358.9L640.5,358.9z M640.4,360.5l0.4,0.4l0.4,0.5c0.1,0.3,0.1,0.4,0.1,0.7v0.8l-0.3,0.5l-0.4,0.6
	l-0.5,0.4l-1.2,0.5l-1.5,0.3l-1.7-0.1c-0.4-0.1-0.8-0.3-1.2-0.5l-1-0.8l-0.4-0.5l-0.3-0.7v-0.8c0-0.3,0.1-0.4,0.3-0.7l0.4-0.5
	l0.6-0.4c0.5-0.3,1.1-0.5,1.8-0.7l0.8-0.1c1.1-0.1,2.1,0.1,2.9,0.5L640.4,360.5L640.4,360.5z"
          />
          <path
            fill="none"
            d="M640.9,360.9l-0.4-0.4l-0.5-0.4c-0.8-0.4-1.8-0.7-2.9-0.5l-0.8,0.1c-0.7,0.1-1.4,0.4-1.8,0.7l-0.5,0.4l-0.4,0.5
	c-0.1,0.1-0.3,0.4-0.3,0.7c-0.1,0.3-0.1,0.5-0.1,0.8l0.3,0.7l0.4,0.5l1,0.8c0.4,0.3,0.8,0.4,1.2,0.5l1.7,0.1l1.5-0.3l1.2-0.5
	l0.5-0.4l0.4-0.5l0.3-0.5v-0.8c0-0.3,0-0.4-0.1-0.7L640.9,360.9L640.9,360.9z M637.3,359.8l1.2,0.1l1,0.4c0.7,0.4,1.1,0.8,1.2,1.5
	h-3c-0.1,0-0.1,0-0.1-0.1l-0.1-0.3c-0.1-0.3-0.1-0.5-0.1-0.8C637.2,360.4,637.2,360.1,637.3,359.8L637.3,359.8z M637.2,359.8
	c-0.3,0.3-0.5,0.5-0.7,1l-0.4,1.1h-2.3l0.4-0.8c0.1-0.3,0.4-0.4,0.7-0.5C635.7,360,636.5,359.8,637.2,359.8L637.2,359.8z
	 M637.2,361.9h0.1v0.7h-0.1V361.9z M636.9,361.9l-1,0.1h-0.3v0.4h1.2v0.1h-1.4c-0.1,0-0.1,0-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1
	c0,0,0.1-0.1,0.3-0.1l0.4,0.1h0.7L636.9,361.9L636.9,361.9z M636.6,362.8l0.3,0.1l0.1,0.1c0.1,0.3,0.1,0.5,0.3,0.8v1
	c-0.8,0-1.5-0.1-2.1-0.5s-1-1-1.2-1.5L636.6,362.8L636.6,362.8z M637.5,362.6V362h0.3l0.3-0.1l0.3,0.1l0.4-0.1c0.1,0,0.3,0,0.3,0.1
	l0.1,0.1v0.4l-0.1,0.1h-1.2C637.6,362.6,637.5,362.6,637.5,362.6L637.5,362.6z M639.1,362.6v-0.7h1.4c0.1,0,0.1,0,0.1,0.1v0.5
	c0,0.1-0.1,0.1-0.3,0.1v-0.6h-1.1v0.5h-0.1V362.6L639.1,362.6z M638.2,362.8h2.5c-0.1,0.7-0.5,1.2-1.2,1.5l-1.1,0.4l-1.2,0.1
	l0.5-0.8l0.3-0.5L638.2,362.8L638.2,362.8z M633.9,362c0-0.1,0.1-0.1,0.1-0.1l0.3-0.1l0.3,0.1h0.7v0.1H634v0.1h1.2v0.3h-1.4
	l-0.1-0.1h1.2l0.1-0.1h-1.2L633.9,362L633.9,362z"
          />
          <path
            fill="none"
            d="M638.4,360l-1.2-0.1c-0.1,0.3-0.1,0.5,0,0.8c0,0.3,0,0.5,0.1,0.8l0.1,0.3c0,0.1,0.1,0.1,0.1,0.1h3
	c-0.1-0.7-0.5-1.1-1.2-1.5L638.4,360L638.4,360z"
          />
          <path
            fill="none"
            d="M637.2,359.8c-0.7-0.1-1.4,0.1-2.1,0.4c-0.3,0.1-0.5,0.3-0.7,0.5l-0.4,0.8h2.3l0.4-1.1
	C636.8,360.4,636.9,360.1,637.2,359.8L637.2,359.8z M637.3,361.9h-0.1v0.7h0.1 M636.9,362l-0.7-0.1l-0.4-0.1c-0.1,0-0.3,0-0.3,0.1
	c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.1,0.1h1.4v-0.1h-1.2v-0.4h1.2L636.9,362L636.9,362z M636.9,363l-0.3-0.1h-2.9
	c0.3,0.5,0.7,1.1,1.2,1.5c0.7,0.4,1.4,0.5,2.1,0.5v-1c0-0.3-0.1-0.5-0.3-0.8L636.9,363L636.9,363z M637.5,362v0.5
	c0,0,0.1,0.1,0.3,0.1h1.2l0.1-0.1v-0.4L639,362c0,0-0.1-0.1-0.3-0.1h-1L637.5,362L637.5,362z M638.3,362h0.5v0.4h-1.1V362L638.3,362
	L638.3,362z"
          />
          <path
            fill="none"
            d="M638.6,362h-0.8v0.4h1l0.1-0.1l-0.1-0.3 M639.1,361.9v0.7h0.1V362h1.1v0.5c0.1,0,0.1,0,0.3-0.1v-0.5l-0.1-0.1
	h-1.4L639.1,361.9L639.1,361.9z M640.6,362.8h-2.5l-0.1,0.7l-0.3,0.5l-0.5,0.8l1.2-0.1l1.1-0.4C640.1,363.9,640.5,363.4,640.6,362.8
	L640.6,362.8z M634,361.9c-0.1,0-0.1,0-0.1,0.1v0.3h1.2l-0.1,0.1h-1.2l0.1,0.1h1.4v-0.3H634v-0.1h1.2V362h-1L634,361.9L634,361.9z
	 M646.5,327.2l0.1,0.8c5.8,1.6,11,4.7,15.5,9.2c1.8,1.8,3.4,3.7,4.7,5.6l0.7-0.1l3.4,1.1h0.3c-1.8-3.2-4-6.1-6.7-8.8
	c-5.8-5.8-12.4-9.4-19.9-10.7v0.1L646.5,327.2L646.5,327.2z M642.6,324.5v-0.5 M642.6,324.5v-0.5l-1.5,2.2v0.8l0.4,12.2l-0.1,1.2
	c-0.1,0.5-0.3,1.1-0.7,1.6l-1.8,3.2c-0.4,0.7-1,1.1-1.7,1.1h-0.7c-0.7,0-1.2-0.4-1.7-1.1l-1.8-3.2c-0.3-0.5-0.5-1.1-0.7-1.6
	l-0.1-1.1l0.4-12.2v-0.8l-1.5-2.2l-0.5,14c-0.1,0.8-0.1,1.8,0.1,2.8c0.1,1,0.5,1.9,1.1,3l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.5
	c0.5-0.1,1-0.4,1.4-1l3.2-7.4c0.5-1.1,1-2.1,1.1-3c0.1-1,0.3-1.9,0.1-2.8L642.6,324.5L642.6,324.5z"
          />
          <path
            fill="none"
            d="M641,326.1l1.5-2.2l-5.6-0.4l-5.4,0.3l1.5,2.3v0.8l3.8-0.3l4.1,0.3"
          />
          <path
            fill="none"
            d="M636.9,326.7l-3.9,0.3l-0.5,12.2l0.1,1.1l4.3-0.4l4.4,0.4l0.1-1.1l-0.4-12.2"
          />
          <path
            fill="none"
            d="M639,345.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6l-4.4-0.4l-4.3,0.4c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2
	c0.4,0.7,1,1.1,1.7,1.1h0.7C638.2,346.2,638.6,345.9,639,345.1L639,345.1z M647.2,337.9v3.6l-0.1,0.7c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3
	l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5c-1.4-1.9-2.9-3.9-4.7-5.6c-4.5-4.5-9.8-7.7-15.5-9.2L647.2,337.9L647.2,337.9z"
          />
          <path
            fill="none"
            d="M647,342.2l-0.4,1.5l-2.1,5.8c-0.5,1.1-0.4,1.9,0.4,2.5c0.4,0.4,1,0.3,1.9-0.5l5-3.7c0.4-0.4,0.8-0.7,1.4-0.8
	l-0.3-0.3C651.1,344.7,649.2,343.3,647,342.2L647,342.2z M672,345.4l-13.6,3.9c-1,0.1-1.8,0.5-2.5,1h-0.1c-1,0.4-1.8,1.1-2.5,1.9
	l-6.1,5.4c-0.3,0.6-0.5,1.1-0.4,1.6v0.3l0.1,0.3c0.3,0.6,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.3,3.2,0c1-0.1,1.9-0.4,2.8-0.7l6.6-2.5
	l6.5-2.5l-2.6-0.7l-0.7,0.3l-3.3,1.2l-8.3,3l-0.3,0.1l-0.8,0.1h-1.8l-1.2-0.3l-2.3-0.4l-0.7-0.1c-0.4-0.3-0.7-0.5-0.8-1l-0.3-0.7
	c-0.3-0.7,0-1.4,0.5-1.9l0.8-0.8l1.7-1.8c0.5-0.5,1-0.8,1.5-1.1c0.3-0.3,0.5-0.3,1-0.4l8.1-2.3l3.8-1.1l0.7-0.3L672,345.4L672,345.4
	z"
          />
          <path
            fill="none"
            d="M672,345.4l-1.8,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C674.8,352.1,673.7,348.7,672,345.4
	L672,345.4z"
          />
          <path
            fill="none"
            d="M672,355.4c-0.5-2.8-1.4-5.2-2.5-7.7l-3.9,1.1v1.2h-1.9c-2.1,0.1-3.2,0.7-3.3,1.8c-0.3,1-1,1.4-1.9,1.2
	c-0.4,0-0.7,0.1-1.1,0.3c0.5,1.1,1,2.2,1.2,3.4h0.5c0.7-1.4,1.4-2.2,2.2-2.3c1.7-0.5,2.3-1.5,2.3-3c0-0.8,0.5-1,1.7-0.5
	c1.4,0.7,2.3,1.5,2.8,2.6l0.3,1.8l0.1,1.4L672,355.4L672,355.4z M675.6,357.8l-0.3,0.1 M675,358l0.3-0.1 M675.6,357.8l-0.3,0.1
	L675.6,357.8l-2.2,2.9l-0.8,0.3v1.4c0,1.4-0.1,2.6-0.1,3.9c-0.7,7-3.3,13.2-7.7,18.6l0.4,0.5v3.6l0.1,0.1c7-7.4,10.6-16.4,10.6-26.7
	C675.9,360.8,675.7,359.3,675.6,357.8L675.6,357.8z"
          />
          <path
            fill="none"
            d="M672.7,362.4v-1.4l-3.6,1.4c0,1.5,0,2.9-0.1,4.3l3.7-0.4C672.5,364.9,672.7,363.7,672.7,362.4L672.7,362.4z
	 M665.8,350.1v-1.2l-8.1,2.3c-0.3,0.1-0.7,0.3-1,0.4c-0.5,0.3-1.1,0.7-1.5,1.1l-1.7,1.8c-0.1,1.1-0.3,2.6-0.3,4.4l0.7,0.1l2.3,0.4
	v-3c0.1-1.6,0.5-2.8,1.4-3.2c0.3-0.1,0.7-0.3,1.1-0.3c1.1,0.1,1.7-0.3,1.9-1.2c0.1-1.1,1.2-1.6,3.3-1.8h1.8L665.8,350.1L665.8,350.1
	z M668.7,356.7l-0.1-1.4L668.7,356.7L668.7,356.7z"
          />
          <path
            fill="none"
            d="M668.6,355.3l-0.3-1.8c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5c0,1.5-0.8,2.5-2.3,3
	c-0.8,0.1-1.5,1-2.2,2.3h-3v0.8c1.2-0.1,2.2,0,2.8,0.4s1,1,1.1,1.8l0.3-0.1l8.3-3L668.6,355.3L668.6,355.3z M653.6,354.5l-0.8,0.8
	c-0.5,0.5-0.8,1.2-0.5,1.9l0.3,0.7c0.1,0.4,0.4,0.8,0.8,1C653.3,357.1,653.4,355.7,653.6,354.5z"
          />
          <path
            fill="none"
            d="M658.8,356.7c-0.3-1.2-0.7-2.3-1.2-3.4c-0.7,0.4-1.2,1.5-1.4,3.2v0.3L658.8,356.7L658.8,356.7z M656.3,357.6
	v1.9l1.2,0.3h1.8l0.8-0.1c-0.1-0.8-0.5-1.4-1.1-1.8C658.4,357.5,657.4,357.3,656.3,357.6L656.3,357.6z M653.3,365.7h-1.8
	c-1.2-0.3-1.8,0-1.9,0.5c-0.5,1-0.3,1.6,0.8,2.2l3.2,2.2C653.4,369.4,653.3,367.8,653.3,365.7L653.3,365.7z M659.6,365.7h-0.4v0.4
	 M656.3,367.7v-1.8l-3-0.1c0.1,2.1,0.3,3.7,0.4,5l3.2,2.3l0.4,0.4c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3l3,0.3l1.2-5.9H664
	c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1C657,370.5,656.5,369.6,656.3,367.7z"
          />
          <path
            fill="none"
            d="M656.3,367.4v0.3c0.1,1.8,0.7,2.9,1.7,3.3c0.4-1.1,0.8-2.2,1-3.3v-0.3L656.3,367.4L656.3,367.4z"
          />
          <path
            fill="none"
            d="M656.3,366.6v0.8h3l0.1,0.3c0.7,1.2,1.2,2.1,2.1,2.2c1.7,0.5,2.3,1.5,2.3,3c0,0.8,0.5,1,1.7,0.4
	c1.4-0.7,2.3-1.5,2.8-2.6l0.3-1.8l0.3-2.2l0.3-4.3l-5.6,2.2c-1.1,0.4-2.2,0.8-3.4,1.1l-0.3,0.1l-0.4,0.4
	C658.5,366.6,657.6,366.7,656.3,366.6L656.3,366.6z"
          />
          <path
            fill="none"
            d="M658.9,367.4v0.3h0.4v-0.3 M656.3,366.6c1.4,0.1,2.3,0,2.9-0.4v-0.3l-3,0.1v0.5L656.3,366.6L656.3,366.6z
	 M668.8,366.6c0.1-1.4,0.3-2.8,0.3-4.3L668.8,366.6z M668.8,366.6l-0.3,2.3 M645.7,355v-0.3c0-0.8-0.4-1.7-1-2.2
	c-0.7-0.7-1.5-1-2.3-1l-0.8,0.1c-0.7,0.1-1.1,0.4-1.7,0.8c-0.7,0.7-1,1.5-1,2.5s0.3,1.8,1,2.3c0.7,0.7,1.5,1,2.3,1
	c1,0,1.8-0.4,2.3-1C645.4,356.8,645.7,356,645.7,355L645.7,355z M643.5,353.8c0.3,0.3,0.5,0.7,0.5,1.2c0,0.4-0.1,0.8-0.5,1.2
	c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.5c-0.4-0.3-0.5-0.7-0.5-1.2c0-0.5,0.1-1,0.5-1.2c0.3-0.3,0.5-0.4,0.8-0.4h0.4
	C642.8,353.4,643.2,353.5,643.5,353.8L643.5,353.8z"
          />
          <path
            fill="none"
            d="M644.1,355c0-0.5-0.1-1-0.5-1.2c-0.3-0.3-0.7-0.4-1.2-0.4h-0.4c-0.3,0.1-0.6,0.3-0.8,0.4
	c-0.4,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.6,1.2,0.6c0.4,0,0.8-0.1,1.2-0.5C643.9,355.8,644.1,355.4,644.1,355
	L644.1,355z M634.7,356c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6h-0.1c-0.8-0.3-1.7-0.1-2.3,0.3c-0.8,0.4-1.4,1.1-1.7,1.9
	l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,1.9,1.6c1,0.3,1.8,0.3,2.6-0.1C633.9,357.5,634.4,356.8,634.7,356L634.7,356z
	 M633.2,355.4c-0.1,0.4-0.4,0.8-0.8,1l-1.4,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.3-0.3-0.7-0.1-1v-0.4c0.1-0.4,0.4-0.8,0.8-1l1.2-0.1
	c0.4,0.1,0.8,0.4,1,0.8C633.2,354.6,633.3,355,633.2,355.4L633.2,355.4z"
          />
          <path
            fill="none"
            d="M632.4,356.4c0.4-0.3,0.7-0.5,0.8-1c0.1-0.4,0.1-0.8-0.1-1.2c-0.3-0.4-0.5-0.7-1-0.8l-1.2,0.1
	c-0.4,0.1-0.7,0.5-0.8,1v0.4c-0.1,0.3,0,0.5,0.1,1c0.3,0.4,0.5,0.7,1,0.8L632.4,356.4L632.4,356.4z M642.4,366.4
	c0.3,1,0.8,1.5,1.7,1.9c0.8,0.4,1.7,0.5,2.6,0.3h0.1c0.8-0.3,1.4-1,1.8-1.6c0.4-0.8,0.5-1.6,0.3-2.6l-0.3-0.7
	c-0.3-0.5-0.7-1-1.4-1.2c-0.8-0.4-1.7-0.5-2.6-0.1c-1,0.3-1.5,0.8-1.9,1.7C642.1,364.6,642.1,365.5,642.4,366.4L642.4,366.4z
	 M644.2,364.5c0.1-0.4,0.5-0.7,1-0.8l1.2,0.1c0.3,0.1,0.6,0.4,0.7,0.7l0.1,0.4c0.1,0.4,0.1,1-0.1,1.2c-0.1,0.4-0.5,0.7-1,0.8
	l-1.2-0.1c-0.4-0.3-0.7-0.5-0.8-1C643.9,365.5,643.9,364.9,644.2,364.5L644.2,364.5z"
          />
          <path
            fill="none"
            d="M645.2,363.7c-0.4,0.1-0.8,0.4-1,0.8c-0.3,0.4-0.3,0.8-0.1,1.4c0.1,0.4,0.4,0.7,0.8,1h1.2
	c0.4-0.1,0.8-0.4,1-0.8c0.3-0.4,0.3-0.8,0.1-1.2l-0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.7L645.2,363.7L645.2,363.7z M642.6,371.1
	c-0.4,0.4-0.5,1-0.5,1.5l1.8,7.8c0.3,1.2,0.5,2.3,1,3c0.4,0.8,1,1.6,1.5,2.3l8.7,11l-0.1-2.6l-0.4-0.5l-7.6-9.8l-0.5-1
	c-0.3-0.6-0.4-1.1-0.5-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.1l0.3-0.3l1.9-0.1l3.3,1.5c0.6,0.3,1.1,0.7,1.5,1.1l0.7,0.8
	l6.9,10.2l0.4,0.7l2.5,1l-7.7-11.7c-0.4-0.8-1-1.5-1.7-2.2c-0.7-0.7-1.7-1.2-2.6-1.8l-6.9-4.1h-1.7l-0.3,0.1L642.6,371.1
	L642.6,371.1z"
          />
          <path
            fill="none"
            d="M646,375.8c-0.5,0.4-0.8,1.1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.5,1.8c2.3-1.1,4.5-2.6,6.6-4.7l0.4-0.4
	c-0.4-0.4-1-0.7-1.5-1.1l-3.3-1.5l-1.9,0.1l-0.3,0.3L646,375.8L646,375.8z M640.2,372.2c0.1-1-0.1-1.8-0.7-2.5
	c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0.1-2.5,0.5c-0.8,0.6-1.2,1.4-1.4,2.2c-0.1,1,0,1.8,0.5,2.5l0.1,0.1c0.5,0.7,1.2,1.1,2.1,1.2
	c1,0.1,1.8-0.1,2.5-0.7l0.5-0.5C639.8,373.3,640.1,372.7,640.2,372.2L640.2,372.2z M637.1,370c0.4,0.1,0.8,0.3,1.1,0.7
	c0.3,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.7-0.4,0.8l-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.3c-0.5-0.1-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.4-0.8-0.3-1.2c0-0.4,0.3-0.8,0.7-1.1C636.2,370,636.6,369.9,637.1,370L637.1,370z"
          />
          <path
            fill="none"
            d="M638.2,370.5c-0.3-0.4-0.7-0.5-1.1-0.7c-0.5-0.1-1,0-1.2,0.3c-0.4,0.3-0.5,0.7-0.7,1.1c0,0.4,0,0.8,0.3,1.2
	c0.3,0.4,0.7,0.5,1.1,0.7c0.4,0.1,0.8,0,1.2-0.3l0.3-0.3c0.3-0.3,0.4-0.5,0.4-0.8C638.6,371.4,638.4,371,638.2,370.5L638.2,370.5z
	 M638.3,377.4c-0.1-1.2-0.5-1.8-1.1-1.6c-1.1-0.3-1.7,0.3-1.8,1.4l-1.8,5.9l-0.7,1.5l4,0.3l3.6-0.3c-0.3-0.4-0.4-1-0.5-1.4
	L638.3,377.4z M663.9,390.5l-2.5-1l-0.4-0.7c-2.1,1.9-4.3,3.4-6.5,4.8l0.4,0.5l0.1,2.6l0.1,0.1C658.2,395.3,661.1,393.1,663.9,390.5
	L663.9,390.5z M653.3,397.8l-0.1-0.1l-3.4-1.1l-0.4-0.5c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2l-0.4,0.5l-3.3,1.1
	l-0.1,0.1c5.1,2.5,10.6,3.6,16.6,3.6C642.8,401.4,648.3,400.1,653.3,397.8L653.3,397.8z M602.9,343.3l3.4-1l0.8,0.1
	c1.4-1.9,2.8-3.7,4.5-5.4c4.7-4.7,9.9-7.7,15.8-9.2v-0.7l2.1-2.9v0.4l0.1-0.6c-7.7,1.4-14.4,5-20.2,10.7
	C606.8,337.5,604.6,340.3,602.9,343.3L602.9,343.3z M602,345.1l0.5,0.1 M602,345.1c-1.7,3.3-2.8,6.7-3.4,10.5l2.6-0.7l0.7,0.3
	c0.5-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L602,345.1L602,345.1z"
          />
          <path
            fill="none"
            d="M602,345.1l1.7,2.1l0.7,0.3l11.8,3.4c0.4,0.1,0.7,0.3,1.1,0.5c0.5,0.3,1,0.7,1.4,1.1l2.5,2.6
	c0.5,0.5,0.8,1.2,0.5,1.9l-0.3,0.7c-0.1,0.7-0.7,1.1-1.5,1.2l-3.6,0.7l-1.8,0.1l-1.1-0.3l-11.6-4.3l-0.7-0.3l-2.6,0.7l0.4,0.1
	l12.7,4.7c0.8,0.4,1.7,0.7,2.8,0.8c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.3,1.1-0.6,1.4-1l0.1-0.3V359c0-0.5-0.1-1.1-0.4-1.5l-6.1-5.4
	c-0.8-0.8-1.7-1.5-2.5-1.9l-0.1-0.1c-0.8-0.4-1.7-0.8-2.6-1l-12.8-3.6h-0.1L602,345.1L602,345.1z M611.6,337.1
	c-1.8,1.6-3.2,3.4-4.5,5.4l9.6,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.8,0.4l0.1-0.1c1.9-1.8,3.9-3.3,6.1-4.4l-0.1-0.7
	c-0.1-1.2-0.1-2.5,0-3.6l0.5-10C621.6,329.4,616.3,332.4,611.6,337.1L611.6,337.1z"
          />
          <path
            fill="none"
            d="M627.4,343.7c-0.1-0.5-0.3-1.1-0.4-1.5c-2.2,1.1-4.1,2.5-6.1,4.4l-0.1,0.1c0.4,0.3,0.8,0.5,1.2,0.7l5,3.7
	c0.8,0.8,1.7,1,2.5,0.4c0.6-0.3,0.6-1,0-1.9L627.4,343.7L627.4,343.7z M617.4,351.3c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4
	c-1.1,2.5-1.9,5-2.5,7.7l11.6,4.3l1.1,0.3C614.9,356.7,615.9,353.9,617.4,351.3L617.4,351.3z"
          />
          <path
            fill="none"
            d="M621.7,356.9c0.3-0.7,0-1.4-0.5-1.9l-2.5-2.6c-0.4-0.4-1-0.8-1.4-1.1c-1.5,2.5-2.3,5.2-2.8,8.3l1.8-0.1l3.6-0.7
	c0.8-0.1,1.4-0.5,1.5-1.2L621.7,356.9L621.7,356.9z M614.6,365.5l-0.7-0.1c-1.1-0.3-2.2-0.5-3.4-1.1l-9.2-3.6v1.6
	c0,4.1,0.5,7.8,1.8,11.4l13.8-1.5C615.6,370.3,614.9,367.9,614.6,365.5L614.6,365.5z"
          />
          <path
            fill="none"
            d="M622.3,365.5h-7.7c0.3,2.5,1,4.7,2.1,6.9l0.8-0.1c0.1-0.1,0.4-0.3,0.5-0.4l5.1-3.6c1.1-0.5,1.5-1.1,1.1-1.5
	C624.1,365.7,623.6,365.3,622.3,365.5z M630,362.4c-0.8-0.5-1.7-0.8-2.5-0.5c-1,0.1-1.7,0.5-2.2,1.4l-0.1,0.1
	c-0.4,0.7-0.7,1.5-0.4,2.3c0.1,1,0.5,1.6,1.4,2.2l0.7,0.4c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.6-0.8,0.8-1.6,0.7-2.5
	C631.3,363.8,630.9,363,630,362.4L630,362.4z M626.5,365.5c0-0.4,0-0.8,0.3-1.2c0.3-0.4,0.7-0.5,1.1-0.7c0.4-0.1,0.8,0,1.2,0.3
	c0.4,0.3,0.5,0.7,0.7,1.1c0.1,0.4,0,0.8-0.3,1.2c-0.3,0.4-0.7,0.7-1.1,0.7l-1-0.1l-0.4-0.1C626.7,366.3,626.6,365.9,626.5,365.5
	L626.5,365.5z"
          />
          <path
            fill="none"
            d="M626.7,364.2c-0.3,0.4-0.4,0.8-0.3,1.2c0.1,0.4,0.3,0.8,0.7,1.1l0.4,0.1l1,0.1c0.4,0,0.8-0.3,1.1-0.7
	c0.3-0.4,0.4-0.8,0.3-1.2c-0.1-0.5-0.3-0.8-0.7-1.1s-0.8-0.4-1.2-0.3C627.4,363.5,627,363.8,626.7,364.2L626.7,364.2z M598.5,355.7
	h0.5l-0.5-0.1 M598.3,357.5c-0.1,1.6-0.3,3.2-0.3,5c0,10.2,3.4,19.1,10.3,26.4v-3.6l0.4-0.7c-2.6-3.3-4.5-6.9-5.9-10.7
	c-1.2-3.6-1.8-7.4-1.8-11.4v-1.6l-0.7-0.3L598.3,357.5L598.3,357.5z M609.7,390.2h0.1H609.7z M609.7,390.4v-0.1 M609.7,390.2l2.5-1
	l0.4-0.7l6.9-10.2l0.8-1c0.4-0.4,0.8-0.7,1.4-1l3.3-1.5h1.9l0.3,0.3l0.3,0.3c0.5,0.4,0.8,1,0.7,1.8l-0.4,3.6
	c-0.1,0.7-0.3,1.2-0.5,1.8l-0.5,1l-7.6,9.8l-0.4,0.5l-0.1,2.8l8.7-11c0.5-0.7,1.1-1.4,1.5-2.2v-0.1c0.4-1,0.7-1.9,1-3l1.8-7.8
	c0-0.7-0.3-1.2-0.5-1.5l-0.3-0.3l-0.3-0.1l-1.7-0.1l-6.9,4.1c-1,0.5-1.8,1.1-2.6,1.8v0.1c-0.7,0.7-1.4,1.4-1.8,2.2L609.7,390.2
	L609.7,390.2z"
          />
          <path
            fill="none"
            d="M612.7,388.7l-0.4,0.7l-2.5,1c2.8,2.6,5.6,4.8,8.8,6.6l0.1-2.8l0.4-0.5C616.8,392.1,614.6,390.5,612.7,388.7
	L612.7,388.7z M628.3,377.4c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.3l-0.3-0.3h-1.9l-3.3,1.5c-0.5,0.3-1.1,0.5-1.4,1l0.7,0.7
	c1.9,1.9,4,3.4,6.2,4.4c0.3-0.5,0.4-1.1,0.5-1.8L628.3,377.4L628.3,377.4z M617,372.8l0.5-0.5l-0.8,0.1 M620.4,397.5l0.3-0.3
	 M637.5,303.4c10,0,19.3,2.2,27.5,6.7c2.1,1.1,4.1,2.5,6.2,3.9l5.8-5c-11.4-8.1-24.5-12.2-39.5-12.2c-15.5,0-29.3,4.4-41,13.2
	c-2.1,1.5-4,3.2-5.9,5c-5.8,5.4-10.2,11.4-13.3,17.7c-2.2,4.5-3.9,9.2-4.8,14.3c-0.3,1.8-0.4,3.6-0.5,5.2c-0.1,1.5-0.1,3-0.1,4.5
	h6.7c0-1.5,0.3-3,0.4-4.5c2.1-11.8,7.6-22.3,16.4-31.2c4.5-4.5,9.4-8.3,14.6-11.1C618.2,305.6,627.4,303.4,637.5,303.4L637.5,303.4z
	 M677.1,309.1l-5.8,5c1.4,1,2.8,2.1,4,3.2c1.5,1.2,2.9,2.6,4.3,4l3.6,4c8,9.5,12.4,20.5,13.6,32.9l0.4,5.5l0.7,10l7.2-1l-0.7-9.9
	l-0.7-9.8c-0.4-3.3-1-6.5-1.7-9.5c-2.3-8.9-6.9-16.9-13.3-24.2c-1.2-1.4-2.6-2.8-4-4l-0.1-0.1c-1.9-1.8-3.9-3.4-5.9-5L677.1,309.1
	L677.1,309.1z"
          />
          <path
            fill="none"
            d="M704.2,362.7l0.7,9.9l1.9-0.3l8.7-1.1h0.1l21.9-2.8l4.4-0.5c3.4-0.7,6.1-1.8,8-3.6l0.8-0.8
	c1.4-1.6,2.2-3.6,2.6-5.9c-15.4,2.6-27.7,4-36.7,4.1L704.2,362.7L704.2,362.7z M762.7,350.5l0.3-2.1h-1.8l-0.3,2.1"
          />
          <path
            fill="none"
            d="M764,352c0-1-0.4-1.4-1.2-1.4h-8.4v0.3l-1.1,6.7c-0.4,2.3-1.2,4.3-2.6,5.9c1.2-0.7,2.5-1.6,3.7-3.2l1.5-2.2
	c2.2-3.9,3.2-4.1,2.9-0.7c-0.3,2.3-1.8,4.8-4.3,7.7c2.5-2.8,3.9-5.4,4.3-7.7c0.3-3.4-0.7-3.3-2.9,0.7l-1.5,2.2h2.2l-3.3,6.7
	c1.7,0,4,1.4,7,4.4c1.7-0.8,2.8-1.8,3.2-2.6c1.4-5.1,1.9-9.2,1.7-12.1C763.9,355.1,763.6,353.6,764,352L764,352z M758.5,350.5h1.4
	l0.3-2.1h-1.4 M754.4,349.8l-0.1,0.7h2.5l0.3-0.7"
          />
          <path
            fill="none"
            d="M750.5,363.4l-0.8,0.8c-1.9,1.8-4.5,3-8,3.6l-4.4,0.7v4.1h13.1l2.8-5.6l3.3-6.7h-2.2
	C753,361.6,751.8,362.7,750.5,363.4L750.5,363.4z M709.4,375.6c2.5,2.2,5.5,3.3,9.1,3.3c3.6,0,6.6-1.1,9.1-3.3c1.1-1,1.9-1.9,2.6-3
	h-12c-1.9,0-2.9-0.5-2.6-1.4h-0.1l-8.7,1.1C707.4,373.6,708.3,374.7,709.4,375.6L709.4,375.6z"
          />
          <path
            fill="none"
            d="M715.6,371.2c-0.1,0.8,0.7,1.4,2.6,1.4h19.1v-4.1L715.6,371.2z M367.4,210.1L361,213l5.8,3.2
	c-23.2,11.7-45.7,24.9-67.3,39.8c-6.7,5-13.5,7.3-20.1,7.3l-14.3-1.2l-2.9,1.7l1.4,0.1c12.1,1.2,25.2,2.3,39.2,3.2
	c0.3-0.5,0.8-1.1,1.2-1.8c2.2-2.9,5.2-6.1,9.5-9.5c0.3-1.9,1.6-4.1,4.1-6.3c2.1-1.9,5.2-3.6,9.4-5l2.8-0.4
	c10.6-6.9,24.1-14.6,40.4-23.1c5.5-2.9,12.5-5.4,20.9-7.4c-7.8,0.8-13.8,0.7-17.7-0.6l-1-0.3L367.4,210.1L367.4,210.1z"
          />
          <path
            fill="none"
            d="M366.2,209.6l1.2,0.7l4.8,2.5c18.3-5.8,54.9-9.2,110.1-10.2c25.3-1.1,49.4-0.4,71.9,2.1l0.8-3h-0.3h0.3l0.3-0.7
	c-3.7-0.7-7.6-1.1-11.8-1.5c-33.7-1.1-69.9-0.7-108.4,1C400.3,201.9,377.5,204.9,366.2,209.6L366.2,209.6z M366.7,216.2L361,213
	c-31.4,14-63.4,30.4-95.9,49l14.3,1.2c6.7,0,13.5-2.3,20.1-7.3C321.1,241.2,343.5,227.9,366.7,216.2L366.7,216.2z M335.5,251.3
	l-0.1-0.8c-0.5-2.5-1.2-4.1-2.1-5.1c-0.4-0.3-0.8-0.6-1.2-0.7c1.2,1.1,1.9,3.2,2.1,6.3c0,1,0.1,2.1,0,3.3L334,255
	c-0.7,2.6-2.5,4-5.6,3.9c-7.8,0.7-12.9,1.6-15,2.6c0.3,1.4,0.8,2.3,1.5,2.8c1.2,0.6,3.4,0.7,6.6,0.1l4.7-1.1l5.8-0.4
	c0.8-0.1,1.4-0.4,1.9-0.7C335.9,260.9,336.5,257.3,335.5,251.3L335.5,251.3z"
          />
          <path
            fill="none"
            d="M335.4,250.5l0.1,0.8c1,6.1,0.4,9.6-1.6,10.9c-0.5,0.3-1.1,0.4-1.9,0.7l-0.4,1.6l151.3-5.2l3.4-44h-35.1
	c-25.7,0.1-46.1,1.2-60.9,3.4c-16.6,4.5-34.7,13.5-53.9,27.1C336.3,246.7,335.9,248.3,335.4,250.5L335.4,250.5z"
          />
          <path
            fill="none"
            d="M334.1,254.2v-3.3l-11.6,0.8c-1.9,0-3.2,0.6-3.6,1.6c-0.7,1.9-0.3,2.6,1.2,2.2l13.9-0.7L334.1,254.2
	L334.1,254.2z M335.4,250.5c0.7-2.2,1-3.7,1-4.7c0-0.7-0.1-1.1-0.4-1.1h-3.9c0.4,0.1,1,0.4,1.2,0.7
	C334.1,246.2,334.8,248,335.4,250.5L335.4,250.5z"
          />
          <path
            fill="none"
            d="M335.9,244.7c0.3,0.1,0.4,0.6,0.4,1.1c19.3-13.6,37.3-22.6,53.9-27.1c14.9-2.1,35.2-3.3,60.9-3.4h35.1l0.3-4.3
	c-21.5-0.1-42.9,0.1-64.4,0.6l-31.2,2.1c-8.4,1.9-15.4,4.4-20.9,7.4c-16.4,8.5-29.9,16.2-40.4,23.1c0.8,0,1.6,0.3,2.5,0.6
	L335.9,244.7L335.9,244.7z M554.9,201.6l-0.8,3c6.6,1.1,14.3,2.9,22.7,5.2l1.9-2.9C571,205.1,563,203.3,554.9,201.6L554.9,201.6z"
          />
          <path fill="none" d="M497.4,211h-10.9l-0.3,4.3l-3.4,44l13.5-0.4" />
          <path fill="none" d="M500,211h-2.6l-1.1,47.9l-0.1,1.6l2.6-0.1" />
          <path
            fill="none"
            d="M545,213c-4.7-0.8-9.4-1.2-14-1.5c-10.3-0.3-20.6-0.4-31.1-0.6l-1.1,49.4l42.1-1.6c5.2-0.4,9.8-1.4,13.5-2.9
	l4.5-2.2c8.1-4.4,17.6-11.4,28.6-21c2.5-2.8,0.3-5.6-6.6-8.7l-19.7-7C556,215.2,550.7,214,545,213L545,213z M509.4,222.1
	c0.6-4,3.4-5.9,8.7-5.8c18.8,0.6,34.7,3,47.6,7.6c13.3,4,19,7.7,17.1,11.1c-4,5.2-12.9,11.4-27,18.4c-7.6,3.3-19.8,4.8-36.6,4.8
	c-6.9,0.1-10.5-2.3-10.7-7.6L509.4,222.1L509.4,222.1z"
          />
          <path
            fill="none"
            d="M518.1,216.2c-5.2-0.1-8.1,1.8-8.7,5.8l-1,28.6c0.1,5.2,3.7,7.7,10.7,7.6c16.8,0,29-1.6,36.6-4.8
	c13.9-7,22.8-13.2,27-18.4c2.1-3.4-3.6-7.2-17.1-11.1C552.7,219.2,536.9,216.8,518.1,216.2L518.1,216.2z M332.1,244.7
	c-0.8-0.3-1.5-0.6-2.5-0.6l-2.8,0.4c-4.1,1.4-7.3,3-9.4,5c-2.5,2.2-3.9,4.4-4.1,6.3v0.4c-0.1,2.2-0.1,4,0,5.4c2.1-1.1,7-1.9,15-2.6
	c3,0,5-1.2,5.6-3.9l-13.9,0.7c-1.5,0.4-1.9-0.3-1.2-2.2c0.4-1.1,1.5-1.6,3.6-1.6l11.6-0.8C333.9,247.8,333.2,245.8,332.1,244.7
	L332.1,244.7z"
          />
          <path
            fill="none"
            d="M326.3,263.1l-4.7,1.1c-3.2,0.5-5.4,0.4-6.6-0.1c-0.8-0.4-1.4-1.2-1.5-2.8c-0.3-1.4-0.3-3,0-5.4v-0.4
	c-4.1,3.4-7.3,6.6-9.5,9.5l27.6-1l0.4-1.6L326.3,263.1L326.3,263.1z M304,265.3c-0.4,0.5-0.8,1.1-1.2,1.8l193.4-6.5V259l-13.5,0.5
	l-151.2,5.1L304,265.3L304,265.3z"
          />
          <path
            fill="none"
            d="M302.8,267.1c-1.6,3-2.8,6.9-3.3,11.7l200.3-8l-3.6-10.3L302.8,267.1L302.8,267.1z"
          />
          <path
            fill="none"
            d="M299.5,278.8c0.5-4.8,1.6-8.7,3.3-11.7c-14-0.8-27.2-1.9-39.2-3.2c-7.4,4.3-18.7,7.3-33.7,8.9
	c-22.7,2.3-43.3,5.2-61.9,8.9c-9.6,2.2-16.6,5.2-21.3,9.1l17.9-1.8l0.8-0.1l2.9-0.3c28.3-3.9,62-6.7,100.8-8.7L299.5,278.8
	L299.5,278.8z"
          />
          <path
            fill="none"
            d="M262.2,263.8l-1.8-0.1c-32.1,2.5-60.8,5.8-86.4,10c-24.5,4.7-42,10.3-52.1,17.2c10.3-6.9,27.7-12.7,52.1-17.2
	c25.6-4.3,54.3-7.6,86.4-10c-8.1-1-15.7-1.9-22.8-3c-45.5,5.2-81,12.1-106.5,20.9C118,286.1,107,293,98.2,302
	c3.3,0.4,7,0.3,11.3-0.3l7.6-1.2c3.9-0.8,8-1.9,12.4-3.3c4.5-1.1,8.8-2.5,12.9-4.3l4.3-1.9c4.7-3.9,11.7-6.9,21.3-9.1
	c18.6-3.7,39.2-6.6,61.9-8.9c15.1-1.7,26.3-4.5,33.7-8.9L262.2,263.8L262.2,263.8z M266.7,318.1c1.5,1.2,2.9,2.6,4.3,4
	c1.2,1.4,2.5,2.6,3.6,4c7.8,9.5,12.4,20.5,13.5,32.9v1.6h7.2l-0.5-6.9c-1.2-12.7-6.2-23.9-15-33.7l-4-4.1
	c-12.8-12.1-28.3-18.3-46.5-18.3c-18.2,0-33.7,6.1-46.6,18.3c-9.6,9.1-15.5,19.7-18,31.5c-1.5,5.9-2.3,13.2-2.6,21.9
	c-1.1,3.6-3.4,5.4-7,5.2H77.7c0.3,0.4,0.8,0.7,1.7,0.8l3.3,0.4c7.2,1,14.9,1.5,23.1,1.6l13.9,2.2l49.7,2.2v-6.2
	c0.1-8.1,0.6-15,1.4-20.5l0.1-1.9c2.2-11.8,7.6-22.3,16.4-31.2c11.6-11.8,25.6-17.9,42-17.9C243.6,304.4,256.1,308.9,266.7,318.1
	L266.7,318.1z"
          />
          <path
            fill="none"
            d="M271.1,322.1c-1.4-1.5-2.9-2.8-4.3-4c-10.6-9.2-23.1-13.8-37.6-13.8c-16.4,0-30.3,5.9-42,17.9
	c-8.8,8.9-14.2,19.4-16.4,31.2l-0.1,1.9c-0.8,5.6-1.2,12.5-1.4,20.5l11.6,0.3c-1-3.7-1.6-7.6-1.8-11.7v-2.1
	c0-10.7,2.9-20.4,8.8-28.8c1.8-2.6,3.9-5,6.2-7.3l4.8-4.3c8.9-7.2,19.4-10.6,31.4-10.6c11.8,0,22.3,3.6,31.2,10.6
	c1.6,1.4,3.3,2.8,4.8,4.3c1.9,1.9,3.6,3.9,5.1,5.8c6.6,8.7,9.9,18.8,9.9,30.3c0,4.7-0.5,9.1-1.6,13.2l-1,3.2l9.4,0.3v-19.8
	c-1.1-12.4-5.6-23.4-13.5-32.9C273.6,324.9,272.4,323.5,271.1,322.1L271.1,322.1z"
          />
          <path
            fill="none"
            d="M271.5,332.2c-1.5-2.1-3.2-4-5.1-5.8c-1.5-1.5-3.2-3-4.8-4.3c-8.9-7.2-19.4-10.6-31.2-10.6
	c-12,0-22.3,3.6-31.4,10.6l-4.8,4.3c-2.3,2.3-4.4,4.8-6.2,7.3c-5.9,8.4-8.8,18-8.8,28.8v2.1c0.1,4.1,0.7,8,1.8,11.7
	c1.5,5.4,3.9,10.3,7,15c1.8,2.5,3.9,5,6.2,7.3c8.5,8.5,18.4,13.3,30,14.7l7.4,0.3l5.2-0.4c11.3-1.4,21.2-6.2,29.6-14.6l3.2-3.4
	l1.9-2.5c3.3-4.3,5.8-8.9,7.3-13.9l1-3.2c1.1-4.1,1.6-8.5,1.6-13.2C281.3,351,278,340.8,271.5,332.2L271.5,332.2z M265.2,345.1
	c1.6,3.3,2.8,6.7,3.4,10.5v0.1l0.3,1.8c0.3,1.6,0.3,3.2,0.3,5c0,10.2-3.4,19.1-10.3,26.4l-1.4,1.4l0.1,0.1h-0.1
	c-2.8,2.6-5.6,4.8-8.8,6.6l-1.8,1c-5.1,2.5-10.6,3.6-16.6,3.6c-5.9,0-11.4-1.1-16.4-3.4l-1.8-0.8c-3.2-1.6-6.1-3.9-8.8-6.5l-1.4-1.4
	c-7-7.4-10.6-16.4-10.6-26.7c0-1.7,0-3.2,0.3-4.7l0.3-1.9c0.6-3.7,1.6-7.2,3.3-10.5l1-1.8c1.8-3.2,4-6.1,6.7-8.8
	c5.8-5.8,12.4-9.4,19.9-10.7l1.9-0.3l5.6-0.4l5.4,0.3l1.9,0.3c7.7,1.4,14.4,5,20.2,10.7c2.6,2.6,4.8,5.5,6.5,8.5L265.2,345.1
	L265.2,345.1z"
          />
          <path
            fill="none"
            d="M237.6,324.2l-1.9-0.3l0.6,14c0.1,0.8,0.1,1.8-0.1,2.8l-0.4,1.7h2.3c0-0.4,0.1-0.7,0.1-1.1V341
	c0.1-1,0.1-1.9,0.1-3.2l-0.7-13.2L237.6,324.2L237.6,324.2L237.6,324.2z"
          />
          <path
            fill="none"
            d="M237.7,324.5v0.1l0.7,13.2c0,1.1,0,2.2-0.1,3.2v0.3c0,0.4-0.1,0.7-0.1,1.1h2.1v-0.3l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6l-0.6-10v-0.7l-2.1-2.9V324.5L237.7,324.5z M247.1,346.2l-0.8,0.4l-1.2,0.7l-5,3.7c-0.8,0.8-1.6,1-2.5,0.4
	c-0.6-0.3-0.6-1,0-1.9l2.1-5.8l0.4-1.4H238c-0.1,0.4-0.3,1-0.4,1.4l-2.8,8c0.8-0.3,1.6-0.1,2.3,0.3c0.8,0.4,1.4,1.1,1.6,1.9l0.1,0.8
	c0.1,0.7,0,1.2-0.3,1.8c-0.4,0.8-1.1,1.4-2.1,1.6c-0.8,0.3-1.8,0.3-2.5-0.1c-0.8-0.4-1.4-1.1-1.6-1.9c-0.3-1-0.3-1.8,0.3-2.6
	c0.4-0.8,1.1-1.4,1.9-1.7h0.1l2.8-8c0.1-0.4,0.3-1,0.4-1.4h-2.3c-0.1,0.4-0.4,1-0.6,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7
	c-0.6-0.1-1.1-0.4-1.4-1l-3.2-7.4c-0.3-0.4-0.4-1-0.6-1.4h-2.3c0.1,0.4,0.3,1,0.4,1.4l2.8,7.8l-2.8-7.8c-0.1-0.4-0.3-1-0.4-1.4h-2.1
	l0.3,1.4l2.1,5.8c0.6,1.1,0.4,1.9-0.4,2.5c-0.4,0.4-1,0.3-1.9-0.5l-5-3.7c-0.4-0.3-1-0.7-1.4-0.8l-0.7-0.3c-1.1-0.5-2.2-0.8-3.4-1.2
	l-9.8-2.5l-0.7-0.1L196,344l13.2,3.4c1.1,0.3,2.1,0.7,2.9,1.1l0.3,0.1c0.8,0.4,1.5,0.8,2.1,1.4l6.7,5.1c0-0.8,0.4-1.6,1-2.2
	c0.7-0.7,1.5-1,2.3-1l0.8,0.1c0.7,0.1,1.1,0.4,1.6,0.8c0.7,0.7,1,1.5,1,2.5s-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.5,1s-1.8-0.3-2.3-1
	c-0.7-0.7-1-1.4-1-2.3v-0.3l-6.7-5.1c-0.7-0.5-1.4-1-2.1-1.4l-0.3-0.1c-0.8-0.4-1.8-0.7-2.9-1.1L196,344h-0.3l-1,1.8h0.1l13.5,3.9
	c1,0.1,1.8,0.5,2.5,1h0.1c1,0.5,1.8,1.1,2.5,1.9l6.1,5.4c0.3,0.5,0.6,1.1,0.4,1.7v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-1.8,0.1h8.4
	c0.3-0.7,0.7-1.4,1.2-2.1l0.3-0.3c1-0.8,1.9-1.2,3.2-1.2c1.4,0,2.5,0.5,3.6,1.5c0.6,0.5,1,1.2,1.2,2.1h11.3l-4.7-0.4
	c-0.7-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c0-0.5,0.1-1.1,0.4-1.5l6.1-5.4c0.8-0.8,1.6-1.5,2.5-1.9l0.1-0.1c0.8-0.4,1.6-0.8,2.6-1
	l12.8-3.6h0.1l0.5-0.1l-1-1.8l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5l6.6-5
	c0.7-0.5,1.2-1,1.9-1.2l0.4-0.3c1-0.4,1.9-0.8,2.9-1.1l13.2-3.4l-3.4-1.1l-0.7,0.1l-9.6,2.5C249.3,345.4,248.2,345.8,247.1,346.2
	L247.1,346.2z M268.7,355.7h-0.4l-12.7,4.7c-0.3,0.1-0.7,0.3-1,0.4h5.9l8.1-3.2l0.3-0.1L268.7,355.7L268.7,355.7z"
          />
          <path
            fill="none"
            d="M266.7,360.4l2.1-2.9l-0.3,0.1l-8.1,3.3h5.2l0.4-0.3 M224.6,324.5v-0.5l-1.9,0.3v0.1l-0.8,13.6
	c0,1.1,0,2.1,0.1,3.2v0.4c0,0.3,0.1,0.7,0.3,1h2.3c-0.3-0.5-0.4-1.1-0.6-1.6c-0.1-1-0.1-1.9-0.1-2.8L224.6,324.5L224.6,324.5z"
          />
          <path
            fill="none"
            d="M220.7,327.2v0.8l-0.6,10c-0.1,1.1,0,2.3,0,3.6l0.1,0.7v0.1h2.1c0-0.3-0.1-0.7-0.3-1v-0.4
	c-0.1-1.1-0.1-2.1-0.1-3.2l0.8-13.6L220.7,327.2L220.7,327.2z M191.9,357.9l-0.1-0.1l2.1,2.9l0.4,0.3h5.2l-1.2-0.5l-6.1-2.3"
          />
          <path
            fill="none"
            d="M191.6,357.8l0.3,0.1l0.3,0.1l6.1,2.3l1.2,0.4h5.9c-0.1,0-0.1-0.1-0.3-0.1l-6.6-2.5l-6.5-2.5L191.6,357.8
	L191.6,357.8z"
          />
          <path
            fill="none"
            d="M225.3,362.4c0-0.5,0.1-1.1,0.3-1.5h-8.4l-6.2,0.5c-1.2,0.1-2.2,0.3-3.2,0c-0.8-0.1-1.6-0.3-2.5-0.7h-5.9l5,1.9
	c1,0.4,1.9,0.7,3,1l2.8,0.3l8.3-0.3l-8.3,0.3l-2.8-0.3c-1-0.1-2.1-0.5-3-1l-5-1.9h-5.2l0.3,0.1l3.6,1.4l5.6,2.2
	c1.1,0.4,2.2,0.8,3.4,1.1l0.4,0.1h0.4l3,0.1l3-0.1h1.8c1.2-0.3,1.8,0,1.8,0.5c0.6,1,0.4,1.6-0.8,2.2l-3.2,2.2l-3.2,2.3l-0.4,0.4
	c-0.8,0.8-1.5,1.8-2.2,2.9l-1.9,3h2.2l1.2-1.9c0.6-0.8,1.1-1.6,1.9-2.5l0.1-0.1c0.6-0.7,1.2-1.2,1.9-1.6l7-4.7
	c-0.8-0.3-1.4-1-1.8-1.6c-0.4-0.8-0.6-1.7-0.1-2.6l0.3-0.7c0.3-0.5,0.7-1,1.4-1.2c0.8-0.4,1.6-0.5,2.6-0.1c1,0.3,1.5,0.8,1.9,1.6
	c0.4,0.8,0.6,1.6,0.3,2.6c-0.3,1-0.8,1.5-1.6,1.9c-0.8,0.4-1.6,0.5-2.6,0.3h-0.1l-7,4.7c-0.7,0.5-1.4,1.1-1.9,1.6l-0.1,0.1
	c-0.8,0.8-1.4,1.6-1.9,2.5l-1.2,1.9h2.5l0.4-0.5c0.4-0.8,1-1.5,1.6-2.2c0.7-0.7,1.6-1.2,2.6-1.8l6.9-4.1h1.6l0.3,0.1l0.3,0.3
	c0.4,0.4,0.6,1,0.6,1.5l-1.5,6.7h2.6l1.5-5.2l-1.5,5.2h2.1l0.7-2.1c0.1-1.2,0.6-1.8,1.1-1.6c1.1-0.3,1.7,0.3,1.8,1.4l0.7,2.2h2.1
	l-1.6-5.6c-0.6,0.7-1.2,1.1-2.1,1.2c-1,0.1-1.8-0.1-2.5-0.7l-0.6-0.5c-0.4-0.4-0.7-1-0.8-1.6c-0.1-1,0.1-1.8,0.7-2.5
	c0.6-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2c0.1,1,0,1.8-0.6,2.5l-0.1,0.1l1.6,5.6h2.6l-1.5-6.9
	c0-0.7,0.1-1.2,0.6-1.5l0.3-0.3c0.1-0.1,0.1-0.1,0.3-0.1l1.6-0.1l6.9,4.1c1,0.5,1.8,1.1,2.6,1.8v0.1c0.6,0.5,1,1,1.4,1.5l1,1.4h2.5
	l-1.2-2.1c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7l6.9,4.7c0.7,0.5,1.2,1,1.8,1.5l0.3,0.3
	c0.7,0.7,1.4,1.5,1.9,2.5l1.2,2.1h2.3l-0.8-1.2l-1.2-1.9c-0.7-1.1-1.5-2.1-2.2-2.9l-0.6-0.5l-0.6-0.6l-5.6-4
	c-1.1-0.5-1.5-1.1-1.1-1.5c0.1-1.1,0.7-1.5,1.9-1.2h7.7l0.7-0.1c1.1-0.3,2.2-0.5,3.4-1.1l8.8-3.4h-5.2l-4.3,1.6
	c-1.1,0.4-2.1,0.7-3,0.8l-0.4,0.1l-2.3,0.1l-8.4-0.3c0.6,0.7,0.7,1.5,0.6,2.3c-0.1,1-0.6,1.6-1.4,2.2l-0.7,0.4
	c-0.6,0.3-1.2,0.4-1.8,0.3c-1-0.1-1.6-0.7-2.2-1.4c-0.6-0.8-0.8-1.6-0.7-2.5c0.1-1,0.6-1.6,1.4-2.2c0.8-0.5,1.6-0.8,2.5-0.5
	c1,0.1,1.6,0.5,2.2,1.4l0.1,0.1l8.4,0.3l2.3-0.1l0.4-0.1c1-0.1,1.9-0.4,3-0.8l4.3-1.6h-5.9c-0.6,0.1-1.1,0.3-1.6,0.4
	c-1,0.1-2.1,0.1-3.2,0l-3.3-0.3h-11.3c0.1,0.5,0.1,1,0.1,1.5l-0.1,1.2c-0.1,0.7-0.6,1.4-1,1.9l-0.3,0.4c-0.8,0.8-1.8,1.2-2.9,1.4
	h-1.2c-1.1-0.1-2.1-0.5-2.9-1.4c-1-1-1.4-2.1-1.5-3.3L225.3,362.4L225.3,362.4z"
          />
          <path
            fill="none"
            d="M223.7,379.4l-0.3,1.1c-0.3,1.2-0.6,2.3-1,3c-0.4,0.8-1,1.6-1.5,2.3l-8.8,11l1.8,0.8l0.1-0.1l8.7-10.6
	c0.7-0.8,1.2-1.6,1.8-2.6l0.1-0.3c0.4-0.7,0.7-1.5,1-2.2l0.8-2.6h-2.8L223.7,379.4L223.7,379.4z"
          />
          <path
            fill="none"
            d="M228.4,379.4h-2.1l-0.8,2.6c-0.3,0.8-0.6,1.5-1,2.2l-0.1,0.3c-0.6,1-1.1,1.8-1.8,2.6l-8.7,10.6l3.3-1.1l0.4-0.5
	l6.3-7.7l2.1-2.9l0.4-0.7L228.4,379.4L228.4,379.4z M208.1,379.4h-2.2l-0.3,0.4l-3.2,5.1l-0.4,0.5v3.6"
          />
          <path
            fill="none"
            d="M202.1,389l-0.1,0.1l1.4,1.4l7.4-11.1h-2.6 M255,379.4h-2.2l6.1,9.5l-0.1-3.7l-0.4-0.5 M234.7,379.4h-2.1
	l1.1,3.7c0.1,0.5,0.4,1,0.7,1.5l0.3,0.5c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.8l0.4,0.5l3.3,1.1l-0.3-0.3l-8.4-10.2
	c-0.7-0.8-1.2-1.8-1.8-2.6l-0.1-0.3c-0.4-0.8-0.7-1.5-1-2.3L234.7,379.4L234.7,379.4z"
          />
          <path
            fill="none"
            d="M237.4,380.3l-0.3-1h-2.6l0.7,2.5c0.3,0.8,0.6,1.5,1,2.3l0.1,0.3c0.4,0.8,1,1.6,1.8,2.6l8.4,10.2l0.3,0.3
	l0.1,0.1l1.8-1l-8.8-11c-0.6-0.7-1.1-1.4-1.5-2.2v-0.1C238,382.5,237.7,381.4,237.4,380.3L237.4,380.3z M258.9,388.8l-6.1-9.5h-2.6
	l7.3,10.9 M227.1,358.6l-0.4,0.3c-1,1-1.5,2.2-1.5,3.6v0.3c0.1,1.2,0.6,2.3,1.5,3.3c0.8,0.8,1.8,1.2,2.9,1.4h1.2
	c1.1-0.1,2.1-0.5,2.9-1.4l0.3-0.4c0.6-0.5,0.8-1.2,1-1.9l0.1-1.2c0-1.4-0.6-2.5-1.4-3.6c-1-1-2.2-1.5-3.6-1.5
	C229,357.3,228.1,357.8,227.1,358.6L227.1,358.6z M230.3,359.5l0.8,0.1c0.7,0.1,1.4,0.4,1.8,0.7l0.6,0.4l0.4,0.5l0.3,0.7
	c0.1,0.3,0.1,0.5,0,0.8l-0.1,0.7l-0.4,0.5l-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.5l-1.6,0.1l-1.5-0.3c-0.4-0.1-0.8-0.3-1.2-0.5l-0.6-0.4
	l-0.4-0.5c-0.1-0.3-0.3-0.4-0.4-0.5c-0.1-0.3-0.1-0.5,0-0.8c0-0.3,0-0.4,0.1-0.7l0.4-0.5l0.6-0.4l0.6-0.3
	C228.2,359.7,229.2,359.4,230.3,359.5L230.3,359.5z"
          />
          <path
            fill="none"
            d="M231.1,359.5l-0.8-0.1c-1.1-0.1-2.1,0.1-2.9,0.6l-0.6,0.4l-0.6,0.4l-0.4,0.5c-0.1,0.3-0.1,0.4-0.1,0.7v0.8
	c0,0,0.1,0.4,0.4,0.5l0.4,0.5l0.6,0.4c0.4,0.3,0.7,0.4,1.2,0.5l1.5,0.3l1.6-0.1c0.4-0.1,0.8-0.3,1.2-0.5l1-0.8l0.4-0.5l0.1-0.7v-0.8
	l-0.3-0.5l-0.4-0.5l-0.6-0.4C232.3,360,231.8,359.7,231.1,359.5L231.1,359.5z M230,359.8c0.8,0,1.5,0.1,2.1,0.5
	c0.3,0.1,0.6,0.3,0.7,0.5l0.6,0.8H231l-0.4-1.1C230.6,360.4,230.3,360.1,230,359.8L230,359.8z M226.8,362v0.5c-0.1,0-0.1,0-0.1-0.1
	v-0.5C226.6,362,226.7,361.9,226.8,362l1.2-0.1v0.7h-0.1V362L226.8,362L226.8,362z M228.6,361.9l0.4,0.1l0.3-0.1
	c0.1,0,0.3,0,0.3,0.1h0.3v0.5l-0.1,0.1h-1.2l-0.1-0.1v-0.4l0.1-0.1C228.4,361.9,228.4,361.9,228.6,361.9L228.6,361.9z M230,360.6
	l-0.1,0.8l-0.1,0.1l-0.1,0.1h-3c0.1-0.7,0.6-1.1,1.2-1.5l1-0.4l1.2-0.1L230,360.6L230,360.6z M229.9,361.9h0.1v0.7h-0.1L229.9,361.9
	L229.9,361.9z M231.2,362h-1v-0.1h0.7l0.4-0.1l0.3,0.1c0.1,0,0.1,0,0.1,0.1v0.5l-0.1,0.1h-1.4v-0.1h1.2v-0.4h-0.1L231.2,362
	L231.2,362z M230.3,363l0.3-0.1h2.9c-0.3,0.5-0.7,1.1-1.2,1.5c-0.7,0.4-1.4,0.5-2.1,0.5c-0.1-0.3-0.1-0.5,0-1c0-0.3,0.1-0.5,0.1-0.8
	L230.3,363L230.3,363z M229.2,363.4l0.1,0.5l0.6,0.8l-1.2-0.1c-0.4-0.1-0.7-0.3-1-0.4c-0.7-0.4-1.1-0.8-1.2-1.5h2.5
	C229,363,229,363.3,229.2,363.4L229.2,363.4z M231.9,362l0.7-0.1l0.3-0.1l0.3,0.1l0.1,0.1v0.3h-1.2l0.1,0.1h1.2l-0.1,0.1h-1.4
	l-0.1-0.1L231.9,362l1.2,0.1V362L231.9,362L231.9,362z"
          />
          <path
            fill="none"
            d="M230,359.8c0.3,0.3,0.6,0.5,0.7,1l0.4,1.1h2.3l-0.6-0.8c-0.1-0.3-0.4-0.4-0.7-0.5
	C231.5,360,230.8,359.8,230,359.8L230,359.8z M226.8,362.6V362h1.1v0.5h0.1v-0.7h-1.4c-0.1,0-0.1,0-0.1,0.1v0.5L226.8,362.6
	L226.8,362.6z"
          />
          <path
            fill="none"
            d="M228.9,361.9l-0.4-0.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.1v0.4l0.1,0.1h1.2l0.1-0.1v-0.5h-0.3c0,0-0.1-0.1-0.3-0.1
	L228.9,361.9L228.9,361.9z M228.8,362h0.8v0.4h-1.1V362L228.8,362L228.8,362z"
          />
          <path
            fill="none"
            d="M228.9,362h-0.4v0.4h1V362 M229.9,361.5l0.1-0.8c0-0.3,0-0.5-0.1-0.8l-1.2,0.1l-1,0.4c-0.7,0.4-1.1,0.8-1.2,1.5
	h3l0.1-0.1L229.9,361.5L229.9,361.5z M230,361.9h-0.1v0.7h0.3v-0.3 M230.3,362h1.2v0.4h-1.2v0.1h1.4l0.1-0.1v-0.5c0,0,0-0.1-0.1-0.1
	l-0.3-0.1l-0.4,0.1L230.3,362L230.3,362z M230.6,362.8l-0.3,0.1l-0.1,0.1c-0.1,0.3-0.1,0.5-0.1,0.8v1c0.8,0,1.5-0.1,2.1-0.5
	c0.7-0.4,1.1-1,1.2-1.5L230.6,362.8L230.6,362.8z"
          />
          <path
            fill="none"
            d="M229.5,363.9l-0.1-0.5c-0.1-0.3-0.1-0.4-0.1-0.7h-2.5c0.1,0.7,0.6,1.2,1.2,1.5c0.3,0.1,0.7,0.3,1,0.4l1.2,0.1
	L229.5,363.9L229.5,363.9z M231.9,361.9l0.6,0.1h0.7v0.1h-1.2v0.1l0.1,0.1h1.4l0.1-0.1h-1.2l-0.1-0.1h1.2v-0.3l-0.1-0.1h-0.6
	L231.9,361.9L231.9,361.9z M260.1,342.5l0.7-0.1l3.4,1.1h0.1c-1.8-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7v0.3v-0.3l2.1,2.9
	v0.7c5.9,1.5,11.1,4.5,15.8,9.2C257.2,338.9,258.8,340.7,260.1,342.5L260.1,342.5z M265.2,345.1l-0.5,0.1L265.2,345.1"
          />
          <path
            fill="none"
            d="M265.2,345.1l-0.5,0.1h-0.1l-12.8,3.6c-1,0.3-1.8,0.5-2.6,1l-0.1,0.1c-0.8,0.4-1.6,1.1-2.5,1.9l-6.1,5.4
	c-0.4,0.5-0.6,1.1-0.4,1.5v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.1,3.2,0c1-0.1,1.9-0.4,2.8-0.8l12.7-4.7l0.4-0.1
	l-2.6-0.7l-0.7,0.3l-11.6,4.3l-1.2,0.3l-1.8-0.1l-3.6-0.7c-0.8-0.1-1.2-0.5-1.5-1.2l-0.3-0.7c-0.1-0.7,0-1.4,0.6-1.9l2.5-2.6
	c0.4-0.4,1-0.8,1.4-1.1s0.8-0.4,1.1-0.5l11.8-3.4l0.7-0.3L265.2,345.1L265.2,345.1z"
          />
          <path
            fill="none"
            d="M265.2,345.1l-1.6,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C268,351.8,266.9,348.4,265.2,345.1
	L265.2,345.1z M195.3,345.4c-1.8,3.3-2.9,6.7-3.4,10.5l2.6-0.7l0.7,0.3c0.6-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L195.3,345.4L195.3,345.4z"
          />
          <path
            fill="none"
            d="M195.3,345.4l1.8,2.1l0.7,0.3l3.9,1.1l8.1,2.3c0.4,0.1,0.7,0.3,1,0.4c0.6,0.3,1.1,0.7,1.5,1.1l1.7,1.8l0.8,0.8
	c0.6,0.5,0.8,1.2,0.6,1.9l-0.3,0.7c-0.1,0.4-0.4,0.8-0.8,1l-0.7,0.3l-2.3,0.4l-1.2,0.3h-1.8l-0.8-0.1l-0.3-0.1l-8.3-3h-0.1l-3.3-1.2
	l-0.7-0.3l-2.6,0.7l6.5,2.5l6.6,2.5c0.8,0.4,1.8,0.7,2.8,0.7c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.1,1.1-0.6,1.4-1l0.1-0.3V359
	c0-0.5-0.1-1.1-0.4-1.6l-6.1-5.4c-0.8-0.8-1.6-1.5-2.5-1.9h-0.1c-0.8-0.4-1.6-0.8-2.5-1L195.3,345.4L195.3,345.4z M195.3,345.4h-0.1
	H195.3z M196,343.6h0.3l3.4-1.1l0.7,0.1c1.4-1.9,2.9-3.9,4.7-5.6c4.5-4.5,9.8-7.7,15.5-9.2v-0.8l2.1-2.9v-0.1
	c-7.6,1.4-14.2,5-19.9,10.7C200,337.7,197.8,340.6,196,343.6L196,343.6z"
          />
          <path
            fill="none"
            d="M220.7,327.9c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3
	l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3l-0.1-0.7c-0.1-1.2-0.1-2.5,0-3.6L220.7,327.9L220.7,327.9z"
          />
          <path
            fill="none"
            d="M220.7,343.7l-0.4-1.5c-2.1,1.1-4,2.5-5.9,4.3l-0.3,0.3c0.4,0.3,1,0.5,1.4,0.8l5,3.7c0.8,0.8,1.5,1.1,1.9,0.5
	c0.8-0.5,1-1.4,0.4-2.5L220.7,343.7L220.7,343.7z M235.6,323.8l-1.5,2.2v0.8l0.4,12.2l-0.1,1.1c-0.1,0.5-0.3,1.1-0.7,1.6l-1.8,3.2
	c-0.4,0.7-1,1.1-1.6,1.1h-0.7c-0.7,0-1.2-0.3-1.6-1.1l-1.8-3.2c-0.3-0.5-0.6-1.1-0.7-1.6l-0.1-1.2l0.4-12.2v-0.8l-1.5-2.2v0.5
	l-0.6,13.5c-0.1,0.8-0.1,1.8,0.1,2.8c0.1,1,0.6,1.9,1.1,3l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.7c0.6-0.1,1-0.4,1.4-1l3.2-7.4
	c0.6-1.1,1-2.1,1.1-3v-0.1c0.1-1,0.3-1.9,0.1-2.8L235.6,323.8L235.6,323.8z"
          />
          <path
            fill="none"
            d="M234.1,326.1l1.5-2.3l-5.4-0.3l-5.6,0.4l1.5,2.2v0.8l4.1-0.3l3.9,0.3 M224.6,323.9v0.5"
          />
          <path
            fill="none"
            d="M230.3,326.7l-4.1,0.3l-0.4,12.2l0.1,1.1l4.4-0.4l4.3,0.4l0.1-1.1l-0.6-12.2"
          />
          <path
            fill="none"
            d="M226.4,341.9l1.8,3.2c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.6-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6
	l-4.3-0.4l-4.4,0.4C226,340.8,226.2,341.4,226.4,341.9L226.4,341.9z M240.2,341.5l-0.1,0.7c2.2,1.1,4.1,2.5,6.1,4.4l0.1,0.1l0.8-0.4
	c1.1-0.4,2.2-0.8,3.4-1.2l9.6-2.5c-1.4-1.9-2.8-3.7-4.5-5.4c-4.7-4.7-9.9-7.7-15.8-9.2l0.6,10C240.5,339,240.3,340.3,240.2,341.5
	L240.2,341.5z"
          />
          <path
            fill="none"
            d="M240.2,342.1l-0.4,1.5l-2.1,5.8c-0.6,1.1-0.6,1.6,0,1.9c0.8,0.7,1.6,0.4,2.5-0.4l5-3.7l1.2-0.7h-0.1
	C244.3,344.7,242.4,343.2,240.2,342.1L240.2,342.1z M248.6,352.4l-2.5,2.6c-0.6,0.5-0.7,1.2-0.6,1.9l0.3,0.7
	c0.1,0.7,0.7,1.1,1.5,1.2l3.6,0.7l1.8,0.1c-0.3-3-1.2-5.6-2.8-8.3C249.4,351.7,249,352,248.6,352.4L248.6,352.4z M242.4,365.7
	c0.1-0.8,0-1.6-0.6-2.3l-0.1-0.1c-0.6-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5
	c0.6,0.7,1.2,1.2,2.2,1.4c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4C241.8,367.2,242.2,366.6,242.4,365.7L242.4,365.7z M239.4,363.5
	c0.4,0.1,0.8,0.3,1.1,0.7c0.3,0.4,0.4,0.8,0.3,1.2s-0.3,0.8-0.7,1.1l-0.4,0.1c-0.3,0.1-0.6,0.3-1,0.1c-0.4,0-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.3-0.8-0.3-1.2c0-0.4,0.3-0.8,0.7-1.1C238.5,363.5,238.9,363.4,239.4,363.5L239.4,363.5z"
          />
          <path
            fill="none"
            d="M240.5,364.2c-0.3-0.4-0.7-0.5-1.1-0.7c-0.6-0.1-1,0-1.2,0.3c-0.4,0.3-0.6,0.7-0.7,1.1c0,0.4,0,0.8,0.3,1.2
	c0.3,0.4,0.7,0.7,1.1,0.7c0.3,0.1,0.7,0,1-0.1l0.4-0.1c0.4-0.3,0.6-0.7,0.7-1.1C240.9,365,240.7,364.6,240.5,364.2L240.5,364.2z
	 M242.9,366.7c-0.3,0.5,0.1,1,1.1,1.5l5.6,4l0.7,0.1c1.1-2.2,1.8-4.4,2.1-6.9h-7.7C243.6,365.3,243.1,365.7,242.9,366.7L242.9,366.7
	z M252.6,359.5l1.2-0.3l11.6-4.3c-0.5-2.8-1.4-5.2-2.5-7.7l-11.8,3.4c-0.4,0.1-0.7,0.3-1.1,0.5C251.3,353.9,252.3,356.7,252.6,359.5
	L252.6,359.5z"
          />
          <path
            fill="none"
            d="M253.3,365.3l-0.7,0.1c-0.3,2.5-1,4.7-2.1,6.9l-0.1,0.4l0.6,0.5c0.7,0.8,1.4,1.8,2.2,2.9l1.2,1.9l8.3,0.1
	c0.7-1.4,1.2-2.9,1.8-4.4c1.2-3.6,1.8-7.4,1.8-11.4v-1.6l-9.2,3.6C255.6,364.8,254.4,365.2,253.3,365.3L253.3,365.3z M234.7,351.7
	c-1,0.3-1.5,0.8-1.9,1.6c-0.4,0.8-0.6,1.6-0.3,2.6c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.7,0.4,2.5,0.1c1-0.3,1.5-0.8,2.1-1.6
	c0.3-0.6,0.4-1.1,0.3-1.8l-0.1-0.8c-0.3-0.8-0.8-1.5-1.6-1.9c-0.8-0.4-1.7-0.5-2.3-0.3L234.7,351.7L234.7,351.7z M235,356.4
	c-0.4-0.3-0.7-0.5-0.8-1c-0.1-0.4-0.1-0.8,0.1-1.2c0.1-0.4,0.6-0.7,1-0.8l1.2,0.1c0.4,0.1,0.7,0.5,0.8,1v0.4c0,0.3,0,0.5-0.1,1
	s-0.6,0.7-1,0.8L235,356.4L235,356.4z"
          />
          <path
            fill="none"
            d="M234,355.4c0.1,0.4,0.4,0.8,0.8,1l1.2,0.1c0.4-0.1,0.8-0.4,1-0.8c0.1-0.3,0.1-0.7,0.1-1v-0.4
	c-0.1-0.4-0.4-0.8-0.8-1l-1.2-0.1c-0.4,0.1-0.8,0.4-1,0.8C234,354.6,233.9,355,234,355.4z M228.2,355c0-1-0.3-1.8-1-2.5
	c-0.6-0.4-1-0.7-1.6-0.8l-0.8-0.1c-1,0-1.6,0.3-2.3,1c-0.6,0.7-1,1.4-1,2.2v0.3c0,1,0.3,1.8,1,2.3c0.7,0.7,1.4,1,2.3,1
	s1.8-0.4,2.5-1C227.9,356.8,228.2,356,228.2,355L228.2,355z M225.2,353.4c0.3,0,0.6,0.3,0.8,0.4c0.3,0.3,0.6,0.7,0.6,1.2
	c0,0.4-0.1,0.8-0.6,1.2c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.1-1,0.4-1.2
	c0.3-0.3,0.7-0.5,1.2-0.5L225.2,353.4L225.2,353.4z"
          />
          <path
            fill="none"
            d="M226,353.8c-0.1-0.3-0.6-0.4-0.8-0.4h-0.4c-0.4,0-0.8,0.1-1.2,0.5c-0.3,0.3-0.4,0.7-0.4,1.2
	c0,0.4,0.1,0.8,0.4,1.2c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.3-0.3,0.6-0.7,0.6-1.2S226.4,354.2,226,353.8L226,353.8z
	 M223.1,368.3c0.8-0.4,1.4-1.1,1.6-1.9c0.3-1,0.1-1.8-0.3-2.6s-1.1-1.4-1.9-1.6c-1-0.3-1.8-0.3-2.6,0.1c-0.7,0.3-1.1,0.7-1.4,1.2
	l-0.3,0.7c-0.3,1-0.3,1.8,0.1,2.6c0.4,0.8,1,1.4,1.8,1.6h0.1C221.5,368.8,222.3,368.8,223.1,368.3L223.1,368.3z M222.2,363.7
	c0.4,0.1,0.8,0.4,1,0.8c0.3,0.4,0.3,0.8,0.1,1.4c-0.1,0.4-0.4,0.7-0.8,1h-1.2c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.4-0.3-0.8-0.1-1.2
	l0.1-0.4c0.1-0.3,0.3-0.4,0.7-0.7L222.2,363.7L222.2,363.7z"
          />
          <path
            fill="none"
            d="M223.1,364.5c-0.1-0.4-0.6-0.7-1-0.8l-1.2,0.1c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.4c-0.1,0.4-0.1,1,0.1,1.2
	c0.3,0.4,0.6,0.7,1,0.8l1.2-0.1c0.4-0.3,0.7-0.5,0.8-1C223.4,365.5,223.3,364.9,223.1,364.5L223.1,364.5z M236.5,370.7l-0.3,0.3
	c-0.4,0.4-0.6,0.8-0.6,1.5l1.8,7.8c0.1,1.1,0.6,2.2,1,3v0.1c0.4,0.8,1,1.6,1.5,2.2l8.8,11l-0.1-2.8l-0.4-0.5l-7.6-9.8l-0.6-1
	c-0.3-0.5-0.4-1.1-0.6-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.6-0.4h1.9l3.3,1.5c0.6,0.3,1.1,0.6,1.4,1l0.3,0.3l0.6,0.7l6.9,10.2
	l0.6,0.7l2.3,1l-8.1-12.2c-0.4-0.5-0.8-1.1-1.4-1.5v-0.1c-0.8-0.7-1.6-1.2-2.6-1.8l-6.9-4.1l-1.6,0.1
	C236.7,370.5,236.6,370.5,236.5,370.7L236.5,370.7z M233.2,373.6c0.6-0.8,0.7-1.6,0.6-2.5c-0.1-1-0.6-1.6-1.4-2.2
	c-0.8-0.5-1.6-0.7-2.5-0.5c-1,0.1-1.6,0.5-2.2,1.4c-0.6,0.7-0.8,1.6-0.7,2.5c0.1,0.7,0.4,1.2,0.8,1.6l0.6,0.5
	c0.7,0.5,1.5,0.8,2.5,0.7c0.8-0.1,1.5-0.6,2.1-1.2L233.2,373.6L233.2,373.6z M231.4,370.3c0.4,0.3,0.6,0.7,0.7,1.1s0,0.8-0.3,1.2
	c-0.3,0.4-0.7,0.5-1.1,0.7c-0.4,0-0.8,0-1.2-0.3l-0.3-0.3c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.5,0-1,0.3-1.2c0.3-0.4,0.7-0.6,1.1-0.7
	C230.6,369.9,231,370,231.4,370.3L231.4,370.3z"
          />
          <path
            fill="none"
            d="M232.1,371.4c0-0.4-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.2-0.3c-0.6,0.1-0.8,0.3-1.1,0.7
	c-0.3,0.4-0.4,0.8-0.3,1.2c0,0.3,0.1,0.7,0.4,0.8l0.3,0.3c0.4,0.3,0.8,0.4,1.2,0.3c0.6-0.1,0.8-0.3,1.1-0.7
	C232.1,372.2,232.2,371.8,232.1,371.4L232.1,371.4z M240.2,375.2l-0.6,0.4c-0.6,0.4-0.8,1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.6,1.8
	c2.2-1.1,4.4-2.6,6.2-4.4l0.4-0.4l0.3-0.3c-0.4-0.4-0.8-0.7-1.4-1l-3.3-1.5H240.2L240.2,375.2z M224.1,370.7h-1.6l-6.9,4.1
	c-1.1,0.5-1.9,1.1-2.6,1.8c-0.7,0.7-1.2,1.4-1.6,2.2l-7.8,11.7l2.5-1l0.4-0.7l6.9-10.2l0.7-0.8c0.4-0.4,1-0.7,1.5-1.1l3.3-1.5
	l1.9,0.1l0.3,0.3l0.3,0.1c0.6,0.4,0.8,1.1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8l-0.6,1l-7.6,9.8l-0.4,0.5l-0.1,2.6l8.8-11
	c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.7-1.8,1-3l1.8-7.8c0-0.7-0.1-1.1-0.6-1.5l-0.3-0.3L224.1,370.7L224.1,370.7z M230.1,375.8
	c-0.6-0.1-1,0.4-1.1,1.6l-2.3,7.3l3.6,0.3l4-0.3c-0.3-0.5-0.4-1.1-0.7-1.5l-1.8-5.9C231.8,375.9,231.1,375.5,230.1,375.8
	L230.1,375.8z M250.4,372.8l0.1-0.4l-0.7-0.1"
          />
          <path
            fill="none"
            d="M247.2,377.8l-0.3-0.3l-0.3,0.3 M258.3,384.6l0.4,0.7v3.6c6.9-7.4,10.3-16.2,10.3-26.4c0-1.6-0.1-3.3-0.3-5
	l-0.3,0.1l0.3-0.1l-2.2,2.9l-0.7,0.3v1.6c0,4.1-0.5,7.8-1.8,11.4c-0.5,1.5-1.1,3-1.8,4.4C261.4,380.5,260,382.5,258.3,384.6
	L258.3,384.6z M257.5,390.2v0.1 M257.4,390.2h0.1 M257.4,390.2l-2.3-1l-0.6-0.7c-2.1,1.9-4.1,3.4-6.5,4.8l0.4,0.5l0.1,2.8
	C251.7,395.2,254.8,393,257.4,390.2L257.4,390.2z M214.5,355.3l-0.8-0.8c0.1,1.1,0.3,2.6,0.3,4.4c0.4-0.3,0.7-0.5,0.8-1l0.3-0.7
	C215.3,356.5,215,356,214.5,355.3L214.5,355.3z"
          />
          <path
            fill="none"
            d="M210.5,351.6c-0.3-0.3-0.6-0.3-1-0.4l-8.1-2.3v1.2h1.9c2.1,0.1,3.2,0.7,3.3,1.8c0.3,1,1,1.4,1.9,1.2
	c0.4,0,0.7,0.1,1.1,0.3c0.7,0.4,1.2,1.5,1.4,3.2v3l2.3-0.4l0.7-0.1c-0.1-1.8-0.1-3.2-0.3-4.4l-1.6-1.8
	C211.6,352.3,211,351.8,210.5,351.6L210.5,351.6z"
          />
          <path
            fill="none"
            d="M205.8,354.3c0.8,0.1,1.5,1,2.2,2.3h0.6c0.3-1.2,0.7-2.3,1.2-3.4c-0.3-0.1-0.7-0.3-1.1-0.3
	c-1.1,0.1-1.6-0.3-1.9-1.2c-0.1-1.1-1.2-1.6-3.3-1.8h-1.9v-1.2l-3.9-1.1c-1.1,2.5-1.9,5-2.5,7.7l3.3,1.2l0.1-1.4l0.3-1.8
	c0.4-1.1,1.4-1.9,2.8-2.6c1.1-0.4,1.7-0.3,1.7,0.6C203.5,352.8,204.3,353.9,205.8,354.3L205.8,354.3z"
          />
          <path
            fill="none"
            d="M208,356.7c-0.7-1.4-1.4-2.2-2.2-2.3c-1.7-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6
	l-0.3,1.8l-0.1,1.4l8.3,3l0.3,0.1c0.1-0.8,0.6-1.4,1.1-1.8c0.7-0.4,1.5-0.5,2.8-0.4v-0.8L208,356.7L208,356.7z"
          />
          <path
            fill="none"
            d="M211,356.4c-0.1-1.6-0.6-2.8-1.4-3.2c-0.6,1.1-1,2.2-1.2,3.4h2.5L211,356.4L211,356.4z M209.8,359.8l1.2-0.3
	v-1.9c-1.2-0.1-2.2,0-2.8,0.4s-1,1-1.1,1.8l0.8,0.1L209.8,359.8L209.8,359.8z M208,365.7h-0.4l0.4,0.4"
          />
          <path
            fill="none"
            d="M208,366.1l-0.4-0.4l-0.4-0.1c-1.2-0.3-2.3-0.7-3.4-1.1l-5.6-2.2l0.3,4.3l0.3,2.2l0.3,1.8
	c0.4,1.1,1.4,1.9,2.8,2.6c1.1,0.6,1.6,0.3,1.6-0.4c0-1.5,0.8-2.5,2.3-3c0.8-0.1,1.4-1,2.1-2.2l0.1-0.1h3v-0.8
	C209.6,366.7,208.7,366.6,208,366.1L208,366.1z"
          />
          <path
            fill="none"
            d="M208,365.7v0.3c0.7,0.4,1.6,0.5,2.9,0.4v-0.7L208,365.7L208,365.7z M208,367.4l-0.1,0.3h0.6l-0.1-0.3"
          />
          <path
            fill="none"
            d="M211,367.4h-2.8l0.1,0.3c0.3,1.1,0.6,2.2,1,3.3c1-0.4,1.4-1.5,1.6-3.3L211,367.4L211,367.4z"
          />
          <path
            fill="none"
            d="M211,366.6v1.1c-0.1,1.8-0.7,2.9-1.6,3.3l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9
	l0.6,2.8l0.7,3.3l3-0.3l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3c0.1-1.2,0.3-2.9,0.4-5l-3,0.1L211,366.6L211,366.6z"
          />
          <path
            fill="none"
            d="M208.4,367.7l-0.6-0.1c-0.7,1.2-1.2,2.1-2.1,2.2c-1.6,0.5-2.3,1.5-2.3,3c0,0.8-0.6,1-1.6,0.4
	c-1.4-0.7-2.3-1.5-2.8-2.6l-0.3-1.8c-0.1-0.7-0.3-1.5-0.3-2.2l-3.7-0.4c0.4,3.7,1.2,7.2,2.8,10.5l4.5,0.1l-0.6-2.8h1.9
	c2.1-0.1,3.2-0.7,3.3-1.8c0.3-1,1-1.4,1.9-1.2l0.8-0.1C209,369.9,208.5,368.9,208.4,367.7L208.4,367.7z M215.7,365.7h-1.8
	c0,2.1-0.1,3.7-0.4,5l3.2-2.2c1.1-0.6,1.4-1.2,0.8-2.2C217.5,365.7,216.9,365.6,215.7,365.7L215.7,365.7z M198.5,356.7l0.1-1.4
	 M192.2,358l-0.3-0.1 M191.9,357.9l-0.3-0.1"
          />
          <path
            fill="none"
            d="M191.6,357.8c-0.3,1.5-0.3,3-0.3,4.5c0,10.3,3.6,19.3,10.6,26.7v-3.7l0.3-0.6c-2.1-2.6-3.7-5.2-5-8.1
	c-1.4-3.3-2.3-6.7-2.8-10.5c-0.1-1.2-0.1-2.5-0.1-3.9v-1.4l-0.7-0.3L191.6,357.8l0.3,0.1L191.6,357.8L191.6,357.8z"
          />
          <path
            fill="none"
            d="M194.7,360.9v1.4c0,1.4,0,2.6,0.1,3.9l3.7,0.4c-0.1-1.4-0.1-2.8-0.1-4.3L194.7,360.9L194.7,360.9z"
          />
          <path
            fill="none"
            d="M198.4,366.6l-0.1-4.3C198.2,363.8,198.2,365.3,198.4,366.6L198.4,366.6z M198.5,366.6c0,0.8,0.1,1.5,0.3,2.2
	L198.5,366.6L198.5,366.6z M213.9,377.8l0.4,0.4c2.1,2.1,4.1,3.6,6.6,4.7c0.3-0.6,0.4-1.1,0.6-1.8l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8
	l-0.3-0.1l-0.3-0.3l-1.9-0.1l-3.3,1.5C214.9,377.2,214.3,377.4,213.9,377.8L213.9,377.8z M206.3,388.8l-0.4,0.7l-2.5,1
	c2.8,2.6,5.6,4.8,8.8,6.5v-0.1l0.1-2.6l0.4-0.5C210.5,392.3,208.3,390.8,206.3,388.8L206.3,388.8z M214.1,397.6l-0.1,0.1
	c5,2.3,10.5,3.4,16.4,3.4c6.1,0,11.6-1.2,16.6-3.6l-0.1-0.1l-3.3-1.1l-0.4-0.5c-4,1.5-8.3,2.2-12.8,2.2c-4.4,0-8.5-0.7-12.4-2.1
	l-0.4,0.5L214.1,397.6L214.1,397.6z M246.7,397.2l0.1,0.3 M268.7,355.7v-0.1l-0.4,0.1"
          />
          <path
            fill="none"
            d="M275.8,316.1l4,4.1c8.8,9.8,13.8,21,15,33.7l0.5,6.9h9.2c-4.8-30.1-6.5-57.5-5.1-82l-30.4,1.4
	c-38.8,1.9-72.4,4.8-100.8,8.7c1.4,0,2.3,0.3,2.9,0.5l0.6,0.5c0.1,0.3,0,0.8-0.4,1.4c-2.9,2.9-5.9,5.8-9.1,8.5
	c-3.7,3.2-7.7,6.2-12,9.1c-2.1,1.4-5.4,2.3-9.9,3l-27.4,4.5l-2.5,0.4l-8.7,1.4c-2.5,0.5-3.3,0.3-2.6-0.5c3.4-3.2,7-5.6,10.5-7.7
	c1.2-0.7,2.6-1.4,3.9-1.9l-0.4,0.1l-13.3,2.1l-0.3,0.3l-12.8,8.3H88c1.2,0.1,2.1,1.9,2.5,5.1l0.6,2.1l0.6,1.8l0.4,1.5l0.6,1.8
	l1.4,4.8l0.6,1.8l0.4,1.5l0.6,1.8l1,3.6l0.1,1.1l0.3,1.8l0.1,1.5v1.8l-0.3,2.2l-0.8,2.3l-0.8,1.8l-0.8,1.5l-1.1,1.8l-0.1,0.1
	l-1.1,1.5l-2.8,3.3c-1.5,1.1-3.7,1.5-6.6,1.2c-1.9,0.5-3.6,2.6-5,6.2c-0.4,1-0.4,1.8,0,2.3h77.2c3.6,0.1,5.9-1.7,7-5.2
	c0.3-8.7,1.2-16,2.6-21.9c2.5-11.8,8.4-22.4,18-31.5c12.9-12.1,28.5-18.3,46.6-18.3C247.5,297.8,263,304,275.8,316.1L275.8,316.1z
	 M141,324.2c0.1,0.7,0.1,1.5,0,2.5l-2.6,21.9l-0.4,3.6l-0.4,3.7l-0.1,1.8v0.3c-0.6,3.6-2.8,5.8-6.5,6.5c-4.3,0.7-7,0-8.4-2.2
	l-0.4-0.7v-0.4c-0.1-0.8-0.3-1.6-0.4-2.3c-0.1-0.7-0.1-1.2-0.1-1.8c0-0.7,0-1.2,0.1-1.8c0-0.5,0.1-1.1,0.3-1.6
	c0.1-0.4,0.3-1,0.4-1.4l0.4-0.8l0.8-1.6c1.2-2.1,2.5-3.2,3.9-3.2l6.1-1.7l3-15.8c0.3-1.2,0.6-2.1,1-2.6l0.4-0.5L141,324.2
	c-0.1-1-0.7-1.4-1.6-1.4C140.2,322.8,140.7,323.2,141,324.2L141,324.2z"
          />
          <path
            fill="none"
            d="M140.9,326.7c0.1-1.1,0.1-1.9,0-2.5l-3,1.8l-0.4,0.5l-2.1,15.8c-0.3,1.8-1,2.6-1.8,2.6l-6.1,1.6
	c-1.2,0-2.5,1.1-3.8,3.2l2.9-0.5c3.7-0.6,7.6-0.8,11.8-0.7L140.9,326.7L140.9,326.7z"
          />
          <path
            fill="none"
            d="M135.4,342.4l2.2-15.8c-0.4,0.5-0.8,1.5-1,2.6l-3,15.8C134.4,345,135,344.1,135.4,342.4L135.4,342.4z
	 M138,352.3l-4.3,0.1c-3.2,0.1-6.2,0.3-9.1,0.8c-0.1,0.5-0.3,1-0.4,1.5c2.8-0.4,5.8-0.7,8.8-0.7h4.1H133c-3.2,0-6.1,0.3-8.8,0.7
	l-2.5,0.4c0,0.5-0.1,1.2-0.1,1.8l2.3-0.4c2.9-0.4,5.9-0.7,9.1-0.8l4.5,0.1L138,352.3L138,352.3z"
          />
          <path
            fill="none"
            d="M133.6,352.4l4.3-0.1l0.4-3.6c-4.3-0.1-8.3,0.1-11.8,0.7c-0.4,0.5-0.7,1.1-1.1,1.6c3.7-0.6,7.8-0.8,12.1-0.7
	c-4.4-0.1-8.4,0.1-12.1,0.7l-2.8,0.5l-0.4,0.8c-0.1,0.4-0.3,0.8-0.4,1.4l2.6-0.4C127.4,352.7,130.4,352.4,133.6,352.4L133.6,352.4z
	 M137.4,355.8l-4.5-0.1c-3.2,0.1-6.2,0.3-9.1,0.8v1.8c2.9-0.4,5.9-0.7,9.1-0.8h4.3L137.4,355.8L137.4,355.8z"
          />
          <path
            fill="none"
            d="M137.3,357.6l-4.3-0.1c-3.2,0.1-6.2,0.3-9.1,0.8l-2.2,0.4c0,0.8,0.1,1.5,0.4,2.3l2.1-0.4l8.9-0.7h3.3h-3.3
	l-8.9,0.7l0.1,1.2l8.3-0.7h3.3h-3.3l-8.3,0.7l-1.8,0.3c1.4,2.2,4.1,2.9,8.4,2.2c3.7-0.7,5.9-2.9,6.5-6.5L137.3,357.6L137.3,357.6z
	 M125.5,350.9c0.3-0.5,0.7-1.1,1.1-1.6l-2.9,0.5l-0.8,1.6L125.5,350.9z M121.9,353.5c-0.1,0.4-0.3,1-0.3,1.6l2.5-0.4
	c0.1-0.5,0.3-1.1,0.4-1.5L121.9,353.5L121.9,353.5z"
          />
          <path
            fill="none"
            d="M123.9,356.5l-2.3,0.4c0,0.5,0,1.2,0.1,1.8l2.2-0.4L123.9,356.5L123.9,356.5z M122,361.1v0.5l0.4,0.7l1.8-0.3
	l-0.1-1.2 M304.6,360.8c0.5,2.3,1.4,4.3,2.5,5.6c1.9,2.3,4.5,3.3,7.8,2.9l167.8-1.2c6.6,0.1,10.6-1.5,12.1-4.8l1.9-6.2
	c3-10.6,5-22.8,5.9-36.6c0.7-19.4-0.4-35.9-3-49.5v-0.3l-200.3,8C298.1,303.3,299.9,330.5,304.6,360.8L304.6,360.8z"
          />
          <path
            fill="none"
            d="M307.2,366.4c-1.1-1.4-1.9-3.3-2.5-5.6h-16.4v22.4h283.5v-13.6l-270.5,1.6C296.6,371.5,298.5,369.9,307.2,366.4
	L307.2,366.4z"
          />
          <path
            fill="none"
            d="M315,369.3c-3.4,0.4-6.1-0.5-7.8-2.9c-8.7,3.4-10.5,5.1-5.6,4.8l270.2-1.6c-5.9-4.4-14.2-6.9-24.6-7.6l-52,1.2
	c-1.5,3.3-5.5,5-12.1,4.8L315,369.3L315,369.3z M165.4,289l-0.8,0.1l-21.3,3.6l-3.4,2.5l31.4-5.8c-0.6-0.3-1.5-0.5-2.9-0.5
	L165.4,289L165.4,289z"
          />
          <path
            fill="none"
            d="M171.7,289.8l-0.6-0.5l-31.4,5.8l-0.4,0.3l0.4-0.3l3.4-2.5l-1,0.1c-4.1,1.8-8.5,3.2-12.9,4.3
	c-4.4,1.4-8.5,2.5-12.4,3.3l20.4-4l2.3-0.4l3.6-0.7l1.8-0.3L171.7,289.8L171.7,289.8z"
          />
          <path
            fill="none"
            d="M171.7,289.8l-26.8,5.2c7.3,0.3,14.6-0.4,21.9-2.2l0.7-0.1C169.6,292.3,171,291.3,171.7,289.8L171.7,289.8z"
          />
          <path
            fill="none"
            d="M171.4,291.2c0.3-0.5,0.4-1,0.3-1.4c-0.8,1.5-2.2,2.5-4.1,2.9l-0.7,0.1c-7.2,1.8-14.4,2.5-21.9,2.2l-1.8,0.3
	c1.1,3,0.8,5.8-0.6,8.5l19.7-4.1C165.5,296.8,168.5,294.1,171.4,291.2L171.4,291.2z M146.8,290.9l-4.4,1.9l1-0.1l21.3-3.6
	 M139.6,296l-2.3,0.4c0.7,2.5,1,6.3,0.8,11.7c1-0.5,1.8-1.2,2.5-1.9C140.7,301.5,140.5,298.1,139.6,296L139.6,296z"
          />
          <path
            fill="none"
            d="M143.2,295.3l-3.6,0.7c0.8,2.1,1.1,5.4,1,10.2c0.7-0.7,1.4-1.5,1.9-2.3C144,301.1,144.3,298.3,143.2,295.3z
	 M137.3,296.5l-20.4,4l-7.6,1.2l-1.2,1.1l-8.5,7.4l13.3-2.1l3.7-5.5l7.7-1.8l5.8-1.4v8.5l-5.6,0.1l-9.5,0.1h-1.1
	c0.1,0.1,0.3,0.3,0.3,0.5l21.9,0.1l0.1-3.4C136,300.8,136.5,297.9,137.3,296.5L137.3,296.5z"
          />
          <path
            fill="none"
            d="M142.7,303.8c-0.6,0.8-1.2,1.7-1.9,2.3c-0.8,0.7-1.6,1.4-2.5,1.9c-0.7,0.4-1.4,0.7-2.1,1.1
	c-1.4,0.7-3,1.1-4.7,1.5l-18.6,5.5l27.4-4.5c4.5-0.7,7.8-1.8,9.9-3c4.3-2.9,8.3-5.9,12-9.1L142.7,303.8L142.7,303.8z"
          />
          <path
            fill="none"
            d="M136.2,309.3c0.7-0.4,1.4-0.7,2.1-1.1c0.1-5.4-0.1-9.2-0.8-11.7c-1,1.4-1.2,4.4-1.1,8.8l-0.1,3.4L136.2,309.3
	L136.2,309.3L136.2,309.3z"
          />
          <path
            fill="none"
            d="M131.5,310.8c1.8-0.4,3.3-1,4.7-1.5v-0.5l-21.9-0.1c0,0.5-0.3,1.2-0.8,2.3l-3,5.8l2.5-0.4L131.5,310.8
	L131.5,310.8z M130.3,306.7v-5l-5.5-0.5l0.4,0.8c0.3,0.5,0.4,1.2,0.4,1.8c0.1,0.8,0,1.6-0.3,2.5l-0.4,1L130.3,306.7L130.3,306.7z"
          />
          <path
            fill="none"
            d="M130.3,308v-1.2l-5.4,0.5l-0.3,0.7 M124.5,300.8l0.3,0.4l5.5,0.5v-2.3"
          />
          <path
            fill="none"
            d="M124.8,301.2l-0.3-0.4l-7.7,1.8l-3.7,5.5l0.4-0.1l0.7,0.1h1.1c2.9-1.1,4.8-2.8,5.6-5l4.3-1.1L124.8,301.2
	L124.8,301.2z"
          />
          <path
            fill="none"
            d="M125.6,303.8c0-0.7-0.1-1.2-0.4-1.8l-4.3,1.1c-1,2.2-2.9,3.9-5.6,5l9.5-0.1l0.3-0.7l0.4-1h-2.1
	c-0.3,0-0.6-0.1-0.7-0.3c-0.1-0.3-0.3-0.4-0.3-0.7v-0.7c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3L125.6,303.8L125.6,303.8z"
          />
          <path
            fill="none"
            d="M122.3,304.8v0.7c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3h2.1c0.3-0.8,0.4-1.7,0.3-2.5h-2.3
	c-0.3,0-0.6,0.1-0.7,0.3C122.4,304.4,122.3,304.7,122.3,304.8L122.3,304.8z M114.3,308.6c0-0.3-0.1-0.4-0.3-0.5l-0.7-0.1
	c-1.2,0.5-2.6,1.2-3.9,1.9c-3.6,1.9-7,4.5-10.5,7.7c-0.8,0.8,0,1.1,2.6,0.5l8.7-1.4l3-5.8C114,309.9,114.3,309.1,114.3,308.6
	L114.3,308.6z M108.3,302.7l1.2-1.1c-4.3,0.5-8,0.5-11.3,0.3v1.7h1.9v0.3h3h-3l-0.4,2.9l4.5-3.4l2.1-0.3L108.3,302.7L108.3,302.7z"
          />
          <path
            fill="none"
            d="M108.3,302.7l-2.1,0.3l-19.5,15.7l12.7-8.3l0.3-0.3"
          />
          <path
            fill="none"
            d="M106.2,303l-2.1,0.3l-4.5,3.4l-4.7,3.7h-3.3l-8.3,8.3h3.3"
          />
          <path
            fill="none"
            d="M99.6,306.7l0.4-2.9l0.1-0.3h-5.1l-3.4,6.9h3.3 M86,321.9h3.7l-0.1-0.7c-0.4-0.7-1-1.1-1.9-1.1h-3.7
	C83.8,321.2,84.5,321.9,86,321.9L86,321.9z"
          />
          <path
            fill="none"
            d="M83.4,320.1h4.5c1,0,1.5,0.4,1.9,1.1l0.1,0.7l0.7,2.1c-0.4-3.3-1.2-5-2.5-5.1h-4.8L83.4,320.1L83.4,320.1
	L83.4,320.1z"
          />
          <path
            fill="none"
            d="M89.3,321.7l-1,0.1l-0.1,4h1.1 M88.3,321.9h-1v4h0.8 M89.8,321.7h-0.6v4.1h1.8l-0.6-2.1 M87.4,321.9h-1l-0.1,4
	h1.1"
          />
          <path
            fill="none"
            d="M89.3,325.8h-5.6v0.8h4.8l0.7,0.8l0.1,0.1h2.3l-0.6-1.8"
          />
          <path fill="none" d="M88.2,326.7h-1v3.4H88" />
          <path fill="none" d="M88.5,326.7h-0.3l-0.1,3.4h0.4l0.7-0.8v-1.8" />
          <path
            fill="none"
            d="M89.3,327.6l-0.1-0.1v1.8l0.1-0.1h1.7l-0.1-1.5 M91.6,327.6h-0.1l0.1,1.5H92"
          />
          <path
            fill="none"
            d="M91.5,327.6h-0.7l0.1,1.5h0.7 M83.9,326.7v1H85v-1 M86.9,327.6h-0.8v1.5h0.8v1h0.3v-3.4h-0.3"
          />
          <path
            fill="none"
            d="M86.1,327.6h0.8v-1h-1.1v1 M86.1,329.1v1h0.8v-1 M86.1,330.1v-1h-0.3v1"
          />
          <path
            fill="none"
            d="M86.9,330.1h-3.3v0.7h8.9l-0.6-1.6h-2.8l-0.1,0.1l-0.7,0.8h-1.2"
          />
          <path
            fill="none"
            d="M85,330.1v-1h-1.1v1 M91.6,330.8h-0.7l0.1,5h0.7 M88,330.8h-0.8l-0.3,5h1 M89.1,330.8H88l-0.1,5H89"
          />
          <path
            fill="none"
            d="M90.9,330.8h-1.8l-0.1,5h2.1 M87.2,330.8h-1.1l-0.1,5h1 M91.8,335.7h-8.1v0.7h4.8l0.6,0.7l0.3,0.3h5.2l-0.6-1.6
	"
          />
          <path
            fill="none"
            d="M89,337.1l-0.6-0.7h-0.6l-0.1,3.6h0.7l0.6-0.7 M89,339.3l0.3-0.4h1.8v-1.5h-1.8l-0.3-0.3"
          />
          <path
            fill="none"
            d="M89.3,338.9l-0.3,0.4l-0.6,0.7h-4.8v0.7h11.8l-0.6-1.8h-3.9"
          />
          <path
            fill="none"
            d="M91.1,337.4v1.5h0.7v-1.5 M87.8,340l0.1-3.6h-1v3.6 M86.9,336.4h-1.1v1h1.1 M83.9,338.9v1.1H85v-1.1
	 M85.8,338.9v1.1h1.1v-1.1 M85.8,339.7v-0.8 M86.9,338.9v1.1"
          />
          <path
            fill="none"
            d="M86.9,338.9v-1.5h-1.1v1.5 M85,337.4v-1h-1.1v1 M85.7,345.4h-2.1v0.7h4.8l0.8,1.1h7.6l-0.1-1.8h-10"
          />
          <path fill="none" d="M85.8,340.7l-0.1,4.7h1l0.1-4.7" />
          <path
            fill="none"
            d="M86.8,340.7l-0.1,4.7h1l0.1-4.7 M88.9,345.4l0.1-4.7h-1.2l-0.1,4.7"
          />
          <path fill="none" d="M89,340.7l-0.1,4.7h2.3l-0.1-4.7" />
          <path
            fill="none"
            d="M91.1,340.7l0.1,4.7h0.7v-4.7 M91.6,330.8l0.1,5H94l-1.4-5 M91.8,337.4v1.5h3.2l-0.4-1.5 M95.5,340.7h-3.6v4.7
	h4.8l-0.3-1.1 M97,350.3v-1.8h-7.6v0.1l-0.8,1h-4.8v0.8H97L97,350.3L97,350.3z"
          />
          <path
            fill="none"
            d="M93.1,348.5v-1.4h-1.7v1.4 M95.9,355H83.6v0.7h6.2l0.8,1h4.4 M93.1,355v-4.7h-1.7v4.7 M93.1,360l1.1-1.8h-3.6
	l-0.8,1.1h-6.2v0.7h7.8 M93.1,360.1V360 M92,361.6l1.1-1.5V360h-1.7v2.2 M93.1,358.2v-1.5h-1.7v1.5 M88.5,346.1h-1.1v3.6h1.1l0.8-1
	v-1.5 M89.3,350.3h-2.1v4.7h1.9 M87.4,349.6v-3.6h-0.4v1.1h-1.1v-1.1h-0.1l-0.1,3.6h0.3v-1.1h1.1v1.1"
          />
          <path
            fill="none"
            d="M85.8,346.1v1.1h1.1v-1.1 M86.9,348.5h-1.1v1.1h1.1 M85,347.2v-1.1h-1.1v1.1 M85,349.6v-1.1h-1.1v1.1
	 M83.9,355.7v1H85v-1 M86.9,355.7v1h-1.1v-1h-0.6v3.6h0.6v-1.1h1.1v1.1h0.1l0.1-3.6"
          />
          <path
            fill="none"
            d="M86.9,356.7v-1h-1.1v1 M89.1,355.7h-1.9l-0.1,3.6h2.1 M85.6,350.3l-0.1,4.7h1.8v-4.7 M87.1,360h-1.9l-0.1,4.1
	h1.9 M86.9,359.3v-1.1h-1.1v1.1 M85,359.3v-1.1h-1.1v1.1"
          />
          <path
            fill="none"
            d="M85,364.1h-1.2c-1,0-1.7,0.4-2.1,1.1c-0.3,0.7,0,1,1,1c2.9,0.3,5.1-0.1,6.6-1.2l2.8-3.3l-0.6,0.5
	c-0.8,0.7-1.7,1.1-2.3,1.5c-0.4,0.1-0.8,0.3-1.4,0.4L85,364.1L85,364.1z"
          />
          <path
            fill="none"
            d="M87.8,364.1c0.4,0,0.8-0.1,1.4-0.4V360h-2.1l-0.1,4.1L87.8,364.1L87.8,364.1z M497,357.1l-1.9,6.2l52-1.2
	c10.6,0.6,18.8,3,24.6,7.6v-8c0-1.5,0-3.2,0.1-4.7L497,357.1L497,357.1z"
          />
          <path
            fill="none"
            d="M578.2,366.6c0-3,0-5.9,0.3-8.9v-0.6h-6.7c0,1.5-0.1,3-0.1,4.7v21.6l6.6,0.4L578.2,366.6L578.2,366.6z
	 M554.4,256c1.7-0.7,3.2-1.4,4.5-2.2L554.4,256z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230,359.8c0.8,0,1.5,0.1,2.1,0.5c0.3,0.1,0.6,0.3,0.7,0.5l0.6,0.8H231
	l-0.4-1.1C230.6,360.4,230.3,360.1,230,359.8L230,359.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231.1,359.5c0.7,0.1,1.4,0.4,1.8,0.7l0.6,0.4l0.4,0.5l0.3,0.7
	c0.1,0.3,0.1,0.5,0,0.8l-0.1,0.7l-0.4,0.5l-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.5l-1.6,0.1l-1.5-0.3c-0.4-0.1-0.8-0.3-1.2-0.5l-0.6-0.4
	l-0.4-0.5c-0.1-0.3-0.3-0.4-0.4-0.5c-0.1-0.3-0.1-0.5,0-0.8c0-0.3,0-0.4,0.1-0.7l0.4-0.5l0.6-0.4l0.6-0.3c0.8-0.4,1.8-0.7,2.9-0.5
	L231.1,359.5L231.1,359.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226.8,362.6c-0.1,0-0.1,0-0.1-0.1v-0.5l0.1-0.1h1.4v0.7h-0.1v-0.5H227
	L226.8,362.6L226.8,362.6z M228.8,362h-0.3v0.4h1V362h-0.6L228.8,362z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M228.9,361.9l0.3-0.1c0.1,0,0.3,0,0.3,0.1h0.3v0.5l-0.1,0.1h-1.2l-0.1-0.1
	V362l0.1-0.1c0-0.1,0.1-0.1,0.3-0.1L228.9,361.9L228.9,361.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M229.9,361.5l-0.1,0.1l-0.1,0.1h-3c0.1-0.7,0.6-1.1,1.2-1.5l1-0.4l1.2-0.1
	c0,0.3,0.1,0.6,0,0.8L229.9,361.5L229.9,361.5z M230,361.9l0.1,0.4v0.3h-0.3v-0.7H230z M230.3,362l0.7-0.1l0.4-0.1l0.3,0.1
	c0.1,0,0.1,0,0.1,0.1v0.5l-0.1,0.1h-1.4v-0.1h1.2v-0.4h-1.2L230.3,362L230.3,362z M230.6,362.8h2.9c-0.3,0.5-0.7,1.1-1.2,1.5
	c-0.7,0.4-1.4,0.5-2.1,0.5c-0.1-0.3-0.1-0.5,0-1c0-0.3,0.1-0.5,0.1-0.8l0.1-0.1L230.6,362.8L230.6,362.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M229.5,363.9l0.6,0.8h0.1l-1.2-0.1c-0.4-0.1-0.7-0.3-1-0.4
	c-0.7-0.4-1.1-0.8-1.2-1.5h2.5c0,0.3,0,0.4,0.1,0.7L229.5,363.9L229.5,363.9z M231.9,361.9h0.7l0.3-0.1l0.3,0.1l0.1,0.1v0.3h-1.2
	l0.1,0.1h1.2l-0.1,0.1h-1.4l-0.1-0.1v-0.1h1.2v-0.1h-1.2L231.9,361.9L231.9,361.9z M633.9,362v0.3h1.2l-0.1,0.1h-1.2l0.1,0.1h1.4
	v-0.3H634v-0.1h1.2V362H634C633.9,361.9,633.9,362,633.9,362L633.9,362z M638.2,362.8l-0.1,0.7l-0.3,0.5l-0.5,0.8l1.2-0.1l1.1-0.4
	c0.7-0.4,1.1-0.8,1.2-1.5L638.2,362.8L638.2,362.8z M639.1,362.6h0.1V362h1.1v0.5c0.1,0,0.1,0,0.3-0.1v-0.5l-0.1-0.1h-1.4
	L639.1,362.6L639.1,362.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M640.4,360.5l-0.6-0.4c-0.8-0.4-1.8-0.7-2.9-0.5l-0.8,0.1
	c-0.7,0.1-1.4,0.4-1.8,0.7l-0.5,0.4l-0.4,0.5c-0.1,0.1-0.3,0.4-0.3,0.7c-0.1,0.3-0.1,0.5-0.1,0.8l0.3,0.7l0.4,0.5l1,0.8
	c0.4,0.3,0.8,0.4,1.2,0.5l1.7,0.1l1.5-0.3l1.2-0.5l0.5-0.4l0.4-0.5l0.3-0.5v-0.8c0-0.3,0-0.4-0.1-0.7l-0.4-0.5L640.4,360.5
	L640.4,360.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M638.6,362h0.1l0.1,0.3l-0.1,0.1h-1V362h0.5L638.6,362z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M637.5,362.6c0,0,0.1,0.1,0.3,0.1h1.2l0.1-0.1v-0.4L639,362
	c0,0-0.1-0.1-0.3-0.1h-1.2L637.5,362.6L637.5,362.6z M636.6,362.8h-2.9c0.3,0.5,0.7,1.1,1.2,1.5c0.7,0.4,1.4,0.5,2.1,0.5v-1
	c0-0.3-0.1-0.5-0.3-0.8l0.1-0.1L636.6,362.8L636.6,362.8z M636.9,361.9h-0.7l-0.4-0.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0-0.1,0-0.1,0.1
	v0.5c0,0.1,0.1,0.1,0.1,0.1h1.4v-0.1h-1.2v-0.4h0.3L636.9,361.9L636.9,361.9z M637.2,361.9v0.7h0.1v-0.7H637.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M637.2,359.8c-0.8,0-1.5,0.1-2.1,0.5c-0.3,0.1-0.5,0.3-0.7,0.5l-0.4,0.8
	h2.3l0.4-1.1C636.8,360.4,636.9,360.1,637.2,359.8L637.2,359.8z M637.3,359.8c-0.1,0.3-0.1,0.5,0,0.8c0,0.3,0,0.5,0.1,0.8l0.1,0.3
	l0.1,0.1h3c-0.1-0.7-0.5-1.1-1.2-1.5l-1-0.4L637.3,359.8L637.3,359.8z M736,267.8c1,6.3,2.3,11.7,4.1,16.1c1.1,1.8,2.9,3.9,5.4,5.9
	c0.7,0.7,1.2,1.8,1.8,3.2 M732.1,268.5h-0.7 M726.2,274l-0.4-1.4c0-0.3-0.1-0.5-0.1-0.8 M726.2,274h4.8 M731.4,268.5
	c-0.4,1.4-0.5,3.2-0.5,5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M725.6,271.8c0-1.5,1.4-2.6,4.3-3l1.5-0.3 M736,267.8l-0.1-1.2v-0.1
	 M736.4,285.7c0.1-0.3,0.3-1,0.4-2.2c0.1-1.5,0.1-3.2-0.1-5.2c-0.1-2.1-0.4-3.9-0.7-5.2s-0.5-2.1-0.8-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M735.1,270.8c-0.3,0-0.5,0.8-0.5,2.2c-0.1,1.5,0,3.3,0.1,5.4
	c0.1,2.1,0.4,3.9,0.7,5.2c0.3,1.4,0.5,2.2,1,2.1h0.1 M735.1,270.8c-0.8-1.2-1.8-2.1-3-2.3 M732.1,268.5l3.8-0.7 M723.6,289.5h-0.7
	 M731.6,282.2h-3.2 M733.5,287.3c-1.8-0.8-3.2-1.6-4.4-2.6 M731,274c0,1.5,0.1,3.3,0.3,5.4l0.3,2.9 M728.4,282.2l0.8,2.5
	 M733.6,287.3h-0.1 M731.6,282.2c0.4,2.2,1.1,3.9,1.9,5.1 M737.1,299.8l-1.1-8.1h-0.1 M747.4,293c-1.2-1.6-3.7-2.9-7.4-3.7
	c-2.3-0.5-4.4-1.2-6.2-1.9 M733.6,287.3c1.2-0.3,2.2-0.8,2.8-1.6 M733.9,299.4c-0.8-5.1-0.1-7.7,1.9-7.7 M728.4,282.2l-2.2-8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M736.5,293.9c5.5,0.7,8.3,0.7,8.1,0c-5.8-2.3-10.3-3.7-13.8-4.3h-7.3
	 M729.2,284.7h-8.4 M735.8,291.7c-3.9,0-7.8-0.7-12.2-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M722.6,289h9.2c3.9,0.7,8.4,2.2,13.6,4.7c0.8,1.5,1.4,4.3,1.8,8.1
	 M715.5,271.6l10.2,0.1 M93.1,360h-1.7 M93.1,356.7h-1.7 M93.1,347.2h-1.7 M93.1,348.5h-1.7 M91.5,358.2h1.7 M91.5,350.3h1.7
	 M91.5,355h1.7 M91.5,356.7v1.5 M87.2,355.7l-0.1,3.6 M89.1,355.7v3.6 M85.3,355.7v3.6 M750,302.5c-1-4-1.8-7.2-2.8-9.4 M89.1,360
	h-2.1 M87.1,360l-0.1,4.1 M91.5,362.2c-0.8,0.7-1.7,1.1-2.3,1.5 M89,363.7l0.1-3.7 M87.1,359.3h2.1 M85.2,360l-0.1,4.1 M89,363.7
	c-0.4,0.1-0.8,0.3-1.4,0.4h-0.8 M86.9,364.1H85 M85,364.1h-1.2c-1,0-1.7,0.4-2.1,1.1c-0.3,0.7,0,1,1,1 M85,359.3h-1.1 M83.9,359.3
	h-0.3v0.7h1.5 M85.8,359.3h1.1 M85,359.3v-1.1h-1.1v1.1 M86.9,359.3v-1.1h-1.1v1.1 M85.2,360h1.9 M86.9,359.3h0.1 M85.3,359.3h0.6
	 M85.3,359.3H85 M89.1,359.3h0.7l0.8-1.1h0.8 M89.1,360h2.3 M91.5,360v2.2 M85.6,350.3h1.7 M87.2,355.7h-0.3 M89.1,355l0.1-4.7
	 M86.9,349.6h0.4 M87.2,355v-4.7 M87.4,346.1h-0.4 M91.5,355v-4.7 M85.4,355h1.8 M85.6,350.3l-0.1,4.7 M91.5,356.7h-0.8l-0.8-1h-0.7
	 M89.1,355.7h-1.9 M87.2,355h1.9 M89.1,355h2.3 M85,355.7h-1.1 M86.9,355.7h-1.1 M85,355.7h0.3 M85.3,355.7h0.6 M85.8,355.7v1h1.1
	v-1 M83.9,355.7v1H85v-1 M85.4,355h-1.8v0.7h0.3 M85,346.1h-1.1 M85,349.6h-1.1 M85,349.6v-1.1h-1.1v1.1 M85.7,346.1l-0.1,3.6
	 M85.6,349.6h0.3 M85.8,349.6h1.1 M85.6,349.6H85 M83.9,349.6h-0.3v0.7h1.9 M85,346.1h0.7 M83.9,346.1v1.1H85v-1.1 M86.9,349.6v-1.1
	h-1.1v1.1 M85.8,346.1h-0.1 M86.9,346.1h-1.1 M85.8,346.1v1.1h1.1v-1.1 M87.4,349.6v-3.6 M89.3,350.3h2.2 M91.5,347.2h-2.2
	 M87.2,350.3h2.1 M87.4,349.6h1.1l0.8-1 M91.5,348.5h-2.2v0.1 M89.3,348.7v-1.5 M89.3,347.2l-0.8-1.1h-1.1 M91.5,348.5v-1.4
	 M93.1,358.2v-1.5 M93.1,360.1V360 M94.2,358.2h-1.1 M91.5,362.2l0.6-0.5 M93.1,350.3H97 M93.1,355v-4.7 M96.8,347.2h-3.7 M97,348.5
	h-3.9 M95.1,356.7h-1.9 M93.1,355h2.8 M93.1,348.5v-1.4 M91.9,345.4h-0.7 M91.1,340.7h0.8 M94,335.7h-2.2 M91.6,330.8h1 M87.4,325.8
	v-4 M88.2,325.8l0.1-4 M89.3,325.8v-4.1 M92,329.1h-0.4 M91.8,338.9h-0.7 M91.8,337.4h-0.7 M86.4,321.9l-0.1,4 M96.7,345.4h-4.8
	 M91.9,345.4v-4.7 M91.9,340.7h3.6 M94.5,337.4h-2.8 M94.9,338.9h-3.2 M91.8,337.4v1.5 M86.9,335.7l0.3-5 M87.9,335.7l0.1-5
	 M89,335.7l0.1-5 M91.6,330.8l0.1,5 M91.1,335.7l-0.1-5 M86.1,330.8l-0.1,5 M87.6,345.4h-1 M86.8,340.7h1 M87.8,340h-0.8
	 M87.9,336.4h-1 M87.9,335.7h-1 M89,340.7h2.1 M91.1,340.7l0.1,4.7 M91.2,345.4h-2.3 M88.9,345.4h-1.2 M87.6,345.4l0.1-4.7
	 M87.8,340.7H89 M89,340.7l-0.1,4.7 M85.8,340.7h1 M86.8,340.7l-0.1,4.7 M86.7,345.4h-1 M85.8,340.7l-0.1,4.7 M85.7,345.4h-2.1v0.7
	h0.3 M85,340h-1.1 M85,336.4h-1.1 M83.9,336.4v1H85v-1 M85.8,340h1.1 M86.9,338.9h-1.1 M85.8,337.4h1.1 M85.8,337.4v1.5 M86.9,338.9
	v-1.5 M86.9,340v-1.1 M86.9,340v-1.1 M85.8,340H85 M85.8,339.7v0.3 M85.8,338.9v0.8 M85.8,339.7v-0.8 M85.8,336.4v1 M86.9,337.4v-1
	 M85,340v-1.1h-1.1v1.1 M83.9,340h-0.3v0.7h2.2 M86,335.7h-2.3v0.7h0.3 M85,336.4h0.8 M86.9,336.4h-1.1 M86.9,335.7h-1 M89,337.1
	v2.2 M87.8,340l0.1-3.6 M91.1,335.7H89 M91.1,337.4h-1.8l-0.3-0.3 M91.1,337.4v1.5 M89,339.3l-0.6,0.7h-0.7 M91.1,338.9h-1.8
	l-0.3,0.4 M89,335.7h-1.1 M89,337.1l-0.6-0.7h-0.6 M91.8,335.7h-0.7 M86.1,330.8h1.1 M89.1,327.5v1.8 M91.5,327.6l0.1,1.5
	 M90.9,329.1l-0.1-1.5 M87.2,330.1h-0.3 M88.2,326.7l-0.1,3.4 M87.2,330.1v-3.4 M87.2,326.7h-0.3 M86.3,325.8h1.1 M86.1,327.6v1.5
	 M89.1,330.8h1.8 M89.1,329.3l-0.7,0.8H88 M88,330.8h1.1 M90.9,329.1h-1.7l-0.1,0.1 M88,330.1h-0.8 M87.2,330.8H88 M91.6,329.1h-0.7
	 M90.9,330.8h0.7 M85,330.1h-1.1 M85,330.1v-1h-1.1v1 M85.8,330.1H85 M86.1,329.1h-0.3v1 M86.9,330.1v-1h-0.8 M85.8,330.1h1.1
	 M85,326.7h-1.1 M85,326.7h0.8 M85.8,326.7v1h0.3 M86.9,326.7h-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M86.1,327.6h0.8v-1 M86.3,325.8h-2.6v0.8h0.3 M83.9,326.7v1H85v-1
	 M89.3,325.8h1.8 M91.5,327.6h-0.7 M91.6,327.6h-0.1 M90.8,327.6h-1.5l-0.1-0.1 M89.1,327.5l-0.7-0.8h-0.3 M88.2,326.7h-1
	 M87.4,325.8h0.8 M88.2,325.8h1.1 M87.4,321.9h-1 M89.8,321.7h-0.6 M88.3,321.9h-0.8 M89.3,321.7l-1,0.1 M83.9,320.1
	c0,1.2,0.7,1.8,2.2,1.8h0.3 M142.7,303.8c1.4-2.8,1.6-5.5,0.6-8.5 M140.7,306.2c0-4.8-0.3-8.1-1-10.2 M138.2,308.2
	c0.1-5.4-0.1-9.2-0.8-11.7 M130.3,301.8v-2.3l-5.8,1.4 M142.7,303.8l19.5-4.1 M139.9,295l31.4-5.8 M145,295l26.7-5.2 M130.3,306.7
	l-5.4,0.5 M124.8,301.2l5.5,0.5 M136.2,308.8l-21.9-0.1 M115.1,308.1l9.5-0.1 M124.5,300.8l-7.7,1.8l-3.7,5.5 M114,308.1h1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M115.1,308.1c2.9-1.1,4.8-2.8,5.6-5l4.3-1.1 M125.3,306.4
	c0.3-0.8,0.4-1.7,0.3-2.5 M124.9,307.3l-0.3,0.7 M124.9,307.3l0.4-0.8 M125.6,303.8h-2.3c-0.3,0-0.6,0.1-0.7,0.3
	c-0.1,0.3-0.3,0.4-0.3,0.7v0.5c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3h2.1 M125.6,303.8c0-0.7-0.1-1.2-0.4-1.8 M125.2,302
	l-0.4-0.8 M124.8,301.2l-0.3-0.4 M136.2,309.3v-0.5 M137.3,296.5c-1,1.4-1.2,4.4-1.1,8.8l-0.1,3.4 M124.6,308h5.6v-1.2 M130.3,306.7
	v-5 M112.9,316.4l18.6-5.5c1.8-0.4,3.3-1,4.7-1.5 M136.2,309.3c0.7-0.4,1.4-0.7,2.1-1.1 M138.2,308.2c1-0.5,1.8-1.2,2.5-1.9
	 M140.7,306.2c0.7-0.7,1.4-1.5,1.9-2.3 M137.3,296.5l-20.2,3.9 M145,295l-1.8,0.3 M143.2,295.3l-3.6,0.7 M139.6,296l-2.3,0.5
	 M143.3,292.7l-1,0.1 M139.9,295l3.4-2.3 M139.5,295.4l0.4-0.4 M171.7,289.8c-0.8,1.5-2.2,2.5-4.1,2.9l-0.7,0.1
	c-7.2,1.8-14.4,2.5-21.9,2.2 M165.4,289l-0.7,0.1l-21.3,3.6 M693.2,248.8c-2.5,0.6-5.5,0.6-8.9,0 M578.7,207l-1.9,2.9 M509.4,222.1
	l-1,28.6c0.1,5.2,3.7,7.7,10.7,7.6c16.8,0,29-1.6,36.6-4.8c13.9-7,22.8-13.2,27-18.4c2.1-3.4-3.6-7.2-17.1-11.1
	c-12.8-4.4-28.8-6.9-47.6-7.6C512.8,216.1,509.9,218,509.4,222.1L509.4,222.1z M336.3,245.8c19.3-13.6,37.3-22.6,53.9-27.1
	c14.9-2.1,35.2-3.3,60.9-3.4h35.1 M265.1,262.1l14.3,1.2c6.7,0,13.5-2.3,20.1-7.3c21.6-14.9,44-28.1,67.3-39.8l-5.8-3.2 M684.8,239
	l17.9-36.7V202l2.1-4.3l0.1-0.1h0.3l0.1,0.1v0.3l-2.1,4.3l-0.1,0.1l-17.7,36.7l-0.1,0.1 M83.9,330.1h-0.3v0.7h2.5 M750.4,251.7
	l-16.4,4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367.4,210.1L361,213c-31.4,14-63.4,30.4-95.9,49l-2.9,1.7 M366.2,209.6
	l1.2,0.6 M335.4,250.5l0.1,0.8c1,6.1,0.4,9.6-1.6,10.9c-0.5,0.3-1.1,0.4-1.9,0.7 M334,254.9l0.1-0.7v-3.3 M335.4,250.5
	c0.7-2.2,1-3.7,1-4.7c0-0.7-0.1-1.1-0.4-1.1h-3.9 M390.8,213.6c-7.8,0.8-13.8,0.7-17.7-0.6l-1-0.3l-4.8-2.5 M329.6,244.1
	c10.6-6.9,24.1-14.6,40.4-23.1c5.5-2.9,12.5-5.4,20.9-7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M554.1,204.6l0.8-3l0.3-0.7c-3.7-0.7-7.6-1.1-11.8-1.5
	c-33.7-1.1-69.9-0.7-108.4,1c-34.5,1.5-57.5,4.5-68.8,9.1 M554.1,204.6c-22.6-2.5-46.5-3.2-71.9-2.1c-55.2,1-91.8,4.3-110.1,10.2
	 M497.4,211h-10.9c-21.5-0.1-42.9,0.1-64.4,0.6l-31.2,2.1 M500,211h-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M486.6,211l-0.3,4.3l-3.4,44 M558.9,253.6c8.1-4.4,17.6-11.4,28.6-21
	c2.5-2.8,0.3-5.6-6.6-8.7l-19.7-7c-5.4-1.6-10.7-2.9-16.2-3.9c-4.7-0.8-9.4-1.2-14-1.5c-10.3-0.3-20.6-0.4-31.1-0.6 M679,247
	c-42.4-17.9-76.3-30.3-102.1-37.1c-8.5-2.3-16.1-4-22.7-5.2 M332.1,244.7c0.4,0.1,1,0.4,1.2,0.7c0.8,1,1.5,2.6,2.1,5.1 M329.6,244.1
	c0.8,0,1.6,0.3,2.5,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M313.5,255.8c0.3-1.9,1.6-4.1,4.1-6.3c2.1-1.9,5.2-3.6,9.4-5l2.8-0.4
	 M334.1,251l-11.6,0.8c-1.9,0-3.2,0.6-3.6,1.6c-0.7,1.9-0.3,2.6,1.2,2.2l13.9-0.7 M332.1,262.7l-5.8,0.4l-4.7,1.1
	c-3.2,0.5-5.4,0.4-6.6-0.1c-0.8-0.4-1.4-1.2-1.5-2.8 M331.7,264.4l0.4-1.6 M313.5,261.5c-0.3-1.4-0.3-3,0-5.4v-0.4 M304,265.3
	c-0.4,0.5-0.8,1.1-1.2,1.8 M313.5,255.8c-4.1,3.4-7.3,6.6-9.5,9.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M313.5,261.5c2.1-1.1,7-1.9,15-2.6c3,0,5-1.2,5.6-3.9 M299.5,278.8
	c0.5-4.8,1.6-8.7,3.3-11.7 M304,265.3l27.6-1 M262.2,263.8l-1.8-0.3 M263.6,263.9l-1.4-0.1 M288.2,360.8v-1.7
	c-1.1-12.4-5.6-23.4-13.5-32.9c-1.1-1.4-2.3-2.8-3.6-4c-1.4-1.5-2.9-2.8-4.3-4c-10.6-9.2-23.1-13.8-37.6-13.8
	c-16.4,0-30.3,5.9-42,17.9c-8.8,9.1-14.2,19.4-16.4,31.2l-0.1,1.9c-0.8,5.6-1.2,12.5-1.4,20.5v6.2l-49.7-2.2l-13.9-2.3
	c-8.4-0.1-16.1-0.7-23.1-1.6l-3.3-0.5c-0.8-0.1-1.4-0.4-1.7-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M260.1,342.5l0.7-0.1l3.4,1.1 M264.7,345.2l0.5-0.1 M265.2,345.1l-0.5,0.1
	 M264.4,343.3l0.8,1.8 M264.3,343.5l0.1-0.1 M264.7,345.2h-0.1l-12.8,3.6c-1,0.3-1.8,0.5-2.6,1l-0.1,0.1c-0.8,0.4-1.6,1.1-2.5,1.9
	l-6.1,5.4c-0.4,0.5-0.6,1.1-0.4,1.5v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.1,3.2,0c1-0.1,1.9-0.4,2.8-0.8l12.7-4.7
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M271.5,332.2c6.6,8.7,9.9,18.8,9.9,30.3c0,4.7-0.5,9.1-1.6,13.2l-1,3.2
	c-1.6,5-4.1,9.6-7.3,13.9l-1.9,2.5l-3.2,3.4c-8.4,8.4-18.3,13.2-29.6,14.6l-5.2,0.4l-7.4-0.3c-11.6-1.2-21.5-6.2-30-14.7
	c-2.3-2.3-4.4-4.8-6.2-7.3c-3.3-4.7-5.6-9.6-7-15c-1-3.7-1.6-7.6-1.8-11.7v-2.1c0-10.7,2.9-20.4,8.8-28.8c1.8-2.6,3.9-5,6.2-7.3
	l4.8-4.3c8.9-7.2,19.4-10.6,31.4-10.6c11.8,0,22.3,3.6,31.2,10.6c1.6,1.4,3.3,2.8,4.8,4.3C268.2,328.2,269.9,330.1,271.5,332.2
	L271.5,332.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M77.7,374.7h77.2c3.6,0.1,5.9-1.6,7-5.2c0.3-8.7,1.2-16,2.6-21.9
	c2.5-11.8,8.4-22.4,18-31.5c12.9-12.1,28.5-18.3,46.6-18.3s33.7,6.1,46.5,18.3l4,4.1c8.8,9.8,13.8,21,15,33.7l0.5,6.9 M168.4,288.7
	c28.3-3.9,62-6.7,100.8-8.7l30.4-1.4 M302.8,267.1c-14-0.8-27.2-1.9-39.2-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.3,345.4l1.6,2.1l0.7,0.3 M195.2,345.4h0.1 M196,343.6l-0.8,1.8
	 M196.3,343.7l-0.3-0.1 M195.3,345.4h-0.1 M200.4,342.8l-0.7-0.1l-3.4,1.1 M222.7,324.2c-7.6,1.4-14.2,5-19.9,10.7
	c-2.8,2.8-5,5.6-6.7,8.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M220.7,327.9c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6
	 M222.7,324.2l-2.1,3v0.7 M220.2,342.2l0.4,1.5l2.1,5.8c0.6,1.1,0.4,1.9-0.4,2.5c-0.4,0.4-1,0.3-1.9-0.5l-5-3.7
	c-0.4-0.4-1-0.7-1.4-0.8 M222.7,324.2l-0.8,13.6c0,1.1,0,2.1,0.1,3.2v0.4c0.1,0.8,0.4,1.5,0.6,2.3l2.8,7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M220.7,327.9l-0.6,10c-0.1,1.1,0,2.3,0,3.6l0.1,0.7 M200.4,342.8l9.8,2.5
	c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3 M237.6,324.2l0.1,0.3 M237.7,324.5l-0.1-0.3l2.1,3l0.1,0.7 M234.8,351.7l2.8-8
	c0.3-0.8,0.6-1.6,0.7-2.3v-0.3c0.1-1,0.1-1.9,0.1-3.2l-0.7-13.2v-0.1 M234.1,326.9v-0.8l1.5-2.3 M237.6,324.2l-1.9-0.4 M224.6,323.9
	l-1.9,0.3 M224.6,323.9l1.5,2.2v0.8 M224.6,324.5v-0.5 M224.6,323.9v0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235.6,323.8l-5.4-0.3l-5.6,0.4 M234.1,326.9l-3.9-0.3l-4.1,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235.6,323.8l0.6,14c0.1,0.8,0.1,1.8-0.1,2.8v0.1c-0.1,1-0.6,1.9-1.1,3
	l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7c-0.6-0.1-1.1-0.4-1.4-1l-3.2-7.4c-0.6-1.1-1-2.1-1.1-3s-0.1-1.9-0.1-2.8l0.6-13.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.9,340.3c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.6,1.1h0.7
	c0.7,0,1.2-0.4,1.6-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6 M226.2,326.9l-0.4,12.2l0.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.9,340.3l4.4-0.4l4.3,0.4 M239.8,327.9l0.6,10c0.1,1.2,0.1,2.3,0,3.6
	l-0.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M240.2,342.1l-0.4,1.5l-2.1,5.8c-0.6,1.1-0.6,1.6,0,1.9
	c0.8,0.7,1.6,0.4,2.5-0.4l5-3.7l1.2-0.7 M234.5,340.3l0.1-1.1l-0.6-12.2 M264.4,343.3c-1.8-3-3.9-5.8-6.5-8.5
	c-5.8-5.8-12.5-9.4-20.2-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M239.8,327.9c5.9,1.5,11.1,4.5,15.8,9.2c1.8,1.6,3.2,3.4,4.5,5.4
	 M240.2,342.1c2.2,1.1,4.1,2.5,6.1,4.4l0.1,0.1 M264.3,343.5l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3c-0.7,0.3-1.4,0.8-1.9,1.2
	l-6.6,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M246.2,346.6l0.8-0.4c1.1-0.5,2.2-0.8,3.4-1.2l9.6-2.5 M252.6,359.5
	l-1.8-0.1l-3.6-0.7c-0.8-0.1-1.2-0.5-1.5-1.2l-0.3-0.7c-0.1-0.7,0-1.4,0.6-1.9l2.5-2.6c0.4-0.4,1-0.8,1.4-1.1 M242,363.4l-0.1-0.1
	c-0.6-0.8-1.2-1.2-2.2-1.4s-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5c0.6,0.7,1.2,1.2,2.2,1.4
	c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4c0.8-0.6,1.2-1.2,1.4-2.2C242.5,364.9,242.4,364.1,242,363.4L242,363.4z M252.6,365.5h-7.7
	c-1.2-0.3-1.8,0.3-1.9,1.2c-0.3,0.5,0.1,1,1.1,1.5l5.6,4 M265.4,355.1l-11.6,4.1l-1.2,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242,363.4l8.4,0.3l2.3-0.1l0.4-0.1c1-0.1,1.9-0.4,3-0.8l12.4-4.8
	 M266,360.6l-9.2,3.6c-1.2,0.4-2.3,0.8-3.4,1.1l-0.7,0.1 M250,351.3c1.5,2.5,2.3,5.2,2.8,8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.9,347.4l-11.8,3.4c-0.4,0.1-0.7,0.3-1.1,0.5 M234.8,351.7h-0.1
	c-1,0.3-1.5,0.8-1.9,1.6c-0.4,0.8-0.6,1.6-0.3,2.6c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.7,0.4,2.5,0.1c1-0.3,1.5-0.8,2.1-1.6
	c0.3-0.6,0.4-1.1,0.3-1.8l-0.1-0.8c-0.3-0.8-0.8-1.5-1.7-1.9C236.5,351.6,235.6,351.4,234.8,351.7L234.8,351.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M234,355.4c-0.1-0.4-0.1-0.8,0.1-1.2c0.1-0.4,0.6-0.7,1-0.8l1.2,0.1
	c0.4,0.1,0.7,0.5,0.8,1v0.4c0,0.3,0,0.5-0.1,1c-0.1,0.4-0.6,0.7-1,0.8l-1.2-0.1C234.4,356.2,234.1,355.8,234,355.4L234,355.4z
	 M226,353.8c0.3,0.3,0.6,0.7,0.6,1.2c0,0.4-0.1,0.8-0.6,1.2c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.5
	c-0.3-0.3-0.4-0.7-0.4-1.2c0-0.5,0.1-1,0.4-1.2c0.3-0.3,0.7-0.5,1.2-0.5h0.4C225.6,353.5,225.9,353.6,226,353.8L226,353.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M221.5,354.9c0-0.8,0.4-1.6,1-2.2c0.7-0.7,1.5-1,2.3-1l0.8,0.1
	c0.7,0.1,1.1,0.4,1.6,0.8c0.7,0.7,1,1.5,1,2.5s-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.5,1s-1.8-0.4-2.3-1c-0.7-0.7-1-1.4-1-2.3
	L221.5,354.9L221.5,354.9z M227.1,358.6c1-0.8,1.9-1.2,3.2-1.2c1.4,0,2.5,0.5,3.6,1.5c1,1,1.4,2.2,1.4,3.6l-0.1,1.2
	c-0.1,0.7-0.6,1.4-1,1.9l-0.3,0.4c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2c-1.1-0.1-2.1-0.5-2.9-1.4c-1-1-1.4-2.1-1.5-3.3v-0.3
	c0-1.4,0.6-2.5,1.5-3.6L227.1,358.6L227.1,358.6z M223.1,364.5c0.3,0.4,0.3,0.8,0.1,1.4c-0.1,0.4-0.4,0.7-0.8,1h-1.2
	c-0.4-0.1-0.8-0.4-1-0.8s-0.3-0.8-0.1-1.2l0.1-0.4c0.1-0.3,0.3-0.4,0.7-0.7l1.2-0.1C222.6,363.8,222.9,364.1,223.1,364.5
	L223.1,364.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M220.4,368.5c-0.8-0.4-1.4-1-1.8-1.6c-0.4-0.8-0.6-1.7-0.1-2.6l0.3-0.7
	c0.3-0.5,0.7-1,1.4-1.2c0.8-0.4,1.6-0.5,2.6-0.1c1,0.3,1.5,0.8,1.9,1.6c0.4,0.8,0.6,1.6,0.3,2.6c-0.3,1-0.8,1.5-1.6,1.9
	c-0.8,0.4-1.6,0.5-2.6,0.3L220.4,368.5L220.4,368.5z M240.5,364.2c0.3,0.4,0.4,0.8,0.3,1.2c-0.1,0.4-0.3,0.8-0.7,1.1l-0.4,0.1
	c-0.3,0.1-0.6,0.3-1,0.1c-0.4,0-0.8-0.3-1.1-0.7c-0.3-0.4-0.3-0.8-0.3-1.2c0-0.5,0.3-0.8,0.7-1.1c0.4-0.3,0.8-0.4,1.2-0.3
	C239.8,363.5,240.2,363.8,240.5,364.2L240.5,364.2z M257.5,390.2l-8.1-12.2c-0.4-0.5-0.8-1.1-1.4-1.5v-0.1c-0.8-0.7-1.6-1.2-2.6-1.8
	l-6.9-4.1l-1.6,0.1c-0.1,0-0.1,0-0.3,0.1l-0.3,0.3c-0.4,0.4-0.6,0.8-0.6,1.5l1.8,7.8c0.1,1.1,0.6,2.2,1,3v0.1c0.4,0.8,1,1.6,1.5,2.2
	l8.8,11 M232.1,371.4c0,0.4,0,0.8-0.3,1.2c-0.3,0.4-0.7,0.5-1.1,0.7c-0.4,0-0.8,0-1.2-0.3l-0.3-0.3c-0.3-0.3-0.4-0.5-0.4-0.8
	c0-0.5,0-1,0.3-1.2c0.3-0.4,0.7-0.6,1.1-0.7c0.6-0.1,1,0,1.2,0.3C231.8,370.5,232.1,371,232.1,371.4L232.1,371.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M233,373.7c-0.6,0.7-1.2,1.1-2.1,1.2c-1,0.1-1.8-0.1-2.5-0.7l-0.6-0.5
	c-0.4-0.4-0.7-1-0.8-1.6c-0.1-1,0.1-1.8,0.7-2.5c0.6-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0.1,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2
	c0.1,1,0,1.8-0.6,2.5L233,373.7L233,373.7z M239.9,382.8c-0.3-0.5-0.4-1.1-0.6-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.6-0.4h1.9
	l3.3,1.5c0.6,0.3,1.1,0.5,1.4,1 M212.3,397l8.8-11c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.7-1.8,1-3l1.8-7.8c0-0.7-0.1-1.1-0.6-1.5
	l-0.3-0.3l-0.3-0.1h-1.7l-6.9,4.1c-1.1,0.5-1.9,1.1-2.6,1.8c-0.7,0.7-1.2,1.4-1.6,2.2l-7.8,11.7 M226.7,384.6l2.3-7.3
	c0.1-1.2,0.6-1.8,1.1-1.6c1.1-0.3,1.7,0.3,1.8,1.4l1.8,5.9c0.1,0.5,0.4,1,0.7,1.5 M217.9,396l6.3-7.7l2.1-2.9l0.4-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M227.8,374.1l-2.3,8c-0.3,0.8-0.6,1.5-1,2.2l-0.1,0.3
	c-0.6,1-1.1,1.8-1.8,2.6l-8.7,10.6 M234.3,384.6l-4,0.3l-3.6-0.3 M248.2,393.5l-7.7-9.8l-0.6-1 M233,373.7l2.3,8
	c0.3,0.8,0.6,1.5,1,2.3l0.1,0.3c0.4,0.8,1,1.6,1.8,2.6l8.4,10.2 M243.1,395.9l-6.3-7.8c-0.8-1-1.5-1.9-2.1-2.9l-0.3-0.5
	 M250.4,372.8l0.6,0.5c0.7,0.8,1.4,1.8,2.2,2.9l1.2,1.9l4.1,6.5 M250.5,372.3l-0.1,0.4 M258.9,388.8l-7.3-11.4
	c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7 M246.9,377.6l0.3,0.3l0.6,0.7l6.9,10.2 M246.9,377.6l-0.3,0.3
	l-0.4,0.4c-1.9,1.9-4,3.4-6.2,4.4 M247.2,377.8h-0.6 M258.9,388.8v-3.6l-0.4-0.7 M257.5,390.2l1.4-1.4 M254.6,388.7l0.6,0.7l2.3,1
	 M249.8,372.2l0.6,0.5 M249.8,372.2l0.7,0.1 M252.6,365.5c-0.3,2.5-1,4.7-2.1,6.9 M213.6,354.5l0.8,0.8c0.6,0.5,0.8,1.2,0.6,1.9
	l-0.3,0.7c-0.1,0.4-0.4,0.8-0.8,1 M196.3,343.7l13.2,3.4c1.1,0.3,2.1,0.7,2.9,1.1l0.3,0.1c0.8,0.4,1.5,0.8,2.1,1.4l6.7,5.1
	 M214,346.7l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3 M195.3,345.4l13.5,3.9c1,0.1,1.8,0.5,2.5,1h0.1c1,0.4,1.8,1.1,2.5,1.9l6.1,5.4
	c0.3,0.6,0.6,1.1,0.4,1.6v0.3l-0.1,0.3c-0.3,0.6-0.7,0.8-1.4,1l-8,0.8c-1.2,0.1-2.2,0.3-3.2,0c-1-0.1-1.9-0.4-2.8-0.7l-6.6-2.5
	l-6.5-2.5 M201.4,348.8l8.1,2.3c0.4,0.1,0.7,0.3,1,0.4c0.6,0.3,1.1,0.7,1.5,1.1l1.6,1.8 M208.4,356.7h-0.6c-0.7-1.4-1.4-2.2-2.2-2.3
	c-1.6-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6l-0.3,1.8 M211,356.7v-0.3c-0.1-1.6-0.6-2.8-1.4-3.2
	 M209.6,353.4c-0.3-0.1-0.7-0.3-1.1-0.3c-1.1,0.1-1.7-0.3-1.9-1.2c-0.1-1.1-1.2-1.6-3.3-1.8h-1.9v-1.2 M208.4,356.7h2.5
	 M209.6,353.4c-0.6,1.1-1,2.2-1.2,3.4 M207,359.7l0.8,0.1h1.8l1.2-0.3 M211,359.5v-1.9 M207,359.7c0.1-0.8,0.6-1.4,1.1-1.8
	c0.7-0.4,1.5-0.5,2.8-0.4 M198.5,356.7l8.3,3l0.3,0.1 M207.6,365.7h0.4 M208,366.2l-0.4-0.4 M208,365.7v0.3 M198.2,362.4l5.6,2.2
	c1.1,0.4,2.2,0.8,3.4,1.1l0.4,0.1 M208.3,367.4H208l-0.1,0.1 M211,367.4h-2.8 M211,366.6v0.8 M211,367.4v0.3
	c-0.1,1.8-0.7,2.9-1.6,3.3 M208.4,367.7l-0.1-0.3 M211,365.9v0.7 M218.4,363.7l-8.3,0.3l-2.8-0.3c-1-0.3-2.1-0.5-3-1l-6.2-2.3
	l-6.1-2.3 M208,366.2c0.7,0.4,1.6,0.5,2.9,0.4 M207.8,367.7l0.6,0.1 M211,365.9l-3-0.1 M211,359.5l2.3-0.4l0.7-0.3 M213.6,370.7
	l3.2-2.2c1.1-0.6,1.4-1.2,0.8-2.2c0-0.5-0.7-0.8-1.8-0.5H214 M214,358.9c-0.1-1.8-0.1-3.2-0.3-4.4 M211,357.6v-0.8 M213.9,365.7
	l-3,0.1 M195.2,355.4l3.3,1.2 M191.9,355.8l2.6-0.7l0.7,0.3 M198.5,356.7l0.1-1.4 M198.6,355.3l-0.1,1.4 M197.7,347.7l3.9,1.1
	 M195.2,355.4c0.6-2.8,1.4-5.2,2.5-7.7 M192.2,358l-0.3-0.1 M192.2,358l-0.3-0.1 M191.6,357.8c-0.1,1.5-0.3,3-0.3,4.7
	c0,10.3,3.6,19.3,10.6,26.7 M191.9,357.9l-0.3-0.1 M191.9,357.9l-0.1-0.1l2.2,2.9l0.7,0.3 M191.6,357.8l0.3,0.1 M194.7,360.9v1.4
	c0,1.4,0,2.6,0.1,3.9 M198.2,362.4l-3.6-1.4 M198.4,366.6l-3.7-0.4 M198.5,366.6l-0.3-4.3 M198.4,366.6c-0.1-1.4-0.1-2.8-0.1-4.3
	 M191.9,355.8l-0.3,1.9 M209.4,371l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9l0.6,2.8l0.7,3.3l3-0.3
	 M197.4,376.6l4.5,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M207.9,367.7c-0.7,1.2-1.2,2.1-2.1,2.2c-1.6,0.6-2.3,1.5-2.3,3
	c0,0.8-0.6,1-1.6,0.4c-1.4-0.7-2.3-1.5-2.8-2.6l-0.3-1.8 M202.1,389v-3.6l0.3-0.5 M201.9,389.1l1.4,1.4 M202.5,384.9
	c-2.1-2.6-3.7-5.2-5-8.1c-1.4-3.3-2.3-6.7-2.8-10.5 M206.3,388.8l6.9-10.2l0.7-0.8 M213.9,377.8l0.4,0.4c2.1,2.1,4.1,3.6,6.6,4.7
	 M213.9,377.8c0.4-0.4,1-0.7,1.5-1.1l3.3-1.5l1.9,0.1l0.3,0.3l0.3,0.1c0.6,0.4,0.8,1.1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8
	 M220.4,368.5l-7,4.7c-0.7,0.5-1.4,1.1-1.9,1.7l-0.1,0.1c-0.8,0.8-1.4,1.6-1.9,2.5l-7.4,11.4 M205.7,379.8l2.2-3.3
	c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3 M203.3,390.5l2.5-1l0.4-0.7 M220.9,382.9l-0.6,1l-7.6,9.8 M202.5,384.9l3.3-5.1
	 M198.6,368.9l-0.3-2.2 M209.4,371c-0.4-1.1-0.8-2.2-1-3.3 M198.6,368.9c-0.1-0.7-0.3-1.5-0.3-2.2 M213.6,370.7
	c0.1-1.2,0.3-2.9,0.4-5 M214,397.6l-0.1,0.1 M214,397.6l3.3-1.1l0.4-0.5 M212.8,393.7l-0.4,0.5l-0.1,2.6 M212.1,397l1.8,0.8
	 M248.7,396.8l-0.1-2.8l-0.4-0.5 M246.8,397.5l-3.3-1.1l-0.4-0.6 M246.7,397.2l0.3,0.3 M246.8,397.5l-0.3-0.3 M246.9,397.6l-0.1-0.1
	 M246.9,397.6l1.8-1 M213.9,397.8c5,2.3,10.5,3.4,16.4,3.4c6.1,0,11.6-1.2,16.6-3.6 M243.1,395.9c-4,1.5-8.3,2.2-12.8,2.2
	c-4.4,0-8.5-0.7-12.4-2.1 M248.7,396.8c3.2-1.6,6.1-3.9,8.8-6.6 M203.3,390.5c2.8,2.6,5.6,4.8,8.8,6.5 M254.6,388.7
	c-2.1,1.9-4.1,3.4-6.5,4.8 M212.8,393.7c-2.3-1.4-4.4-2.9-6.5-4.8 M307.2,366.4c-1.1-1.4-1.9-3.3-2.5-5.6 M495,363.3
	c-1.5,3.3-5.5,5-12.1,4.8L315,369.3c-3.4,0.4-6.1-0.5-7.8-2.9 M571.7,369.6l-270.3,1.6c-4.8,0.3-2.9-1.4,5.6-4.8 M295.4,360.8h-7.2
	 M268.7,355.6l-2.6-0.7l-0.7,0.3 M262.9,347.4c1.1,2.5,1.9,5,2.5,7.7 M265.2,345.1l-1.6,2.1l-0.7,0.3 M258.3,384.6
	c1.6-2.1,3-4.1,4.1-6.3c0.7-1.4,1.2-2.9,1.8-4.4c1.2-3.6,1.8-7.4,1.8-11.4v-1.6 M268.5,357.6l0.3-0.1l-2.2,2.9l-0.7,0.3
	 M258.9,388.8c6.9-7.4,10.3-16.2,10.3-26.4c0-1.6-0.1-3.3-0.3-5 M268.9,357.5l-0.3-1.8 M268.2,355.7h0.4 M268.2,355.7l0.4-0.1
	 M268.5,357.6l0.3-0.1 M288.2,360.8v22.4h283.5 M278.8,378.7l9.4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M304.6,360.8h-9.2 M260.4,263.5c-8.1-1-15.7-1.9-22.8-3
	c-45.5,5.2-81,12.1-106.5,20.9c-13.1,4.5-24.1,11.4-32.9,20.5 M304.6,360.8c-4.8-30.1-6.5-57.5-5.1-82 M268.7,355.6
	c-0.7-3.7-1.8-7.2-3.4-10.5 M195.2,345.4c-1.6,3.3-2.8,6.7-3.3,10.5 M262.5,378.2l-8.3-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M263.6,263.9c-7.4,4.3-18.7,7.3-33.7,8.9c-22.7,2.3-43.3,5.2-61.9,8.9
	c-9.6,2.2-16.6,5.2-21.3,9.1 M110.3,316.8l2.5-0.4l27.4-4.5c4.5-0.7,7.8-1.8,9.9-3c4.3-2.9,8.3-5.9,12-9.1c3.3-2.8,6.3-5.6,9.1-8.5
	c0.4-0.5,0.4-1,0.4-1.4l-0.6-0.5c-0.6-0.3-1.5-0.5-2.9-0.5 M168.4,288.7l-3,0.3 M146.8,290.9l-4.3,1.9c-4.1,1.8-8.5,3.2-12.9,4.3
	c-4.4,1.4-8.5,2.5-12.4,3.3l-7.6,1.2 M110.3,316.8l3-5.8c0.7-1.1,1-1.8,0.8-2.3c0-0.3-0.1-0.4-0.3-0.5l-0.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M99.6,310.2l13.5-2.1l0.4-0.1 M141,324.2c0.1,0.7,0.1,1.5,0,2.5l-2.6,21.9
	 M137.6,326.5l0.4-0.6l3-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M133.6,345c1,0.1,1.5-0.8,1.8-2.6l2.2-15.8 M133.6,345l3-15.8
	c0.3-1.2,0.6-2.1,1-2.6 M141,324.2c-0.1-1-0.7-1.4-1.6-1.4 M146.8,290.9l17.9-1.8 M260.4,263.5c-32.1,2.5-60.8,5.8-86.4,10
	c-24.5,4.7-42,10.3-52.1,17.2 M109.4,301.8l-1.1,1 M108.3,302.7l-2.1,0.3 M106.2,303l-2.1,0.3l-4.5,3.4 M99.6,306.7l0.4-2.9
	 M100,303.8l0.1-0.3h-2.1 M99.6,306.7l-4.7,3.7h-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M98.1,303.6h-3l-3.4,6.9 M98.1,301.9v1.7 M86.7,318.7l12.7-8.3l0.3-0.3
	 M100,303.8h3.2 M113.5,308c-1.2,0.5-2.6,1.2-3.9,1.9c-3.6,1.9-7,4.5-10.5,7.7c-0.8,0.8,0,1.1,2.6,0.5l8.7-1.4 M98.1,301.9
	c3.3,0.4,7,0.3,11.3-0.3 M99.6,310.2l8.7-7.4 M83.4,318.7v1.4h4.5c1,0,1.5,0.4,1.9,1.1l0.1,0.7l0.7,2.1 M86.7,318.7h-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M90.5,323.8c-0.4-3.3-1.2-5-2.5-5.1h-1.4 M91.6,310.4l-8.3,8.3 M92,361.6
	l1.1-1.5l0.1-0.1l1.1-1.8l0.8-1.5l0.8-1.8l0.8-2.3l0.3-2.2v-1.8L97,347l-0.3-1.8l-0.1-1.1l-1-3.6l-0.6-1.8l-0.4-1.5l-0.6-1.8
	l-1.4-4.8l-0.6-1.8l-0.4-1.5l-0.6-1.8l-0.6-2.1 M716.4,361.5l-12.2,1.2 M675.3,355.8c-0.5-3.7-1.7-7.2-3.3-10.5 M755.5,343.5
	l-1.1,6.2 M704.9,372.6l1.9-0.3 M753.2,357.5c-15.4,2.6-27.7,4-36.7,4.1 M737.3,372.6v-4.1l-21.7,2.8 M706.8,372.3l8.7-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M715.6,371.2c-0.1,0.8,0.7,1.4,2.6,1.4h19.1 M749.7,364.2
	c-1.9,1.8-4.5,3-8,3.6l-4.4,0.7 M762.7,350.5h-1.8 M761.1,348.4h1.8 M737.3,372.6h13.1l2.8-5.6 M754.3,360.2
	c-1.2,1.5-2.3,2.5-3.7,3.2 M753.2,357.5c-0.4,2.3-1.2,4.3-2.6,5.9 M754.3,350.7l-1.1,6.7 M753.2,367l3.3-6.7h-2.2 M754.3,360.2
	l1.5-2.2c2.2-3.9,3.2-4.1,2.9-0.7c-0.4,2.3-1.8,4.8-4.3,7.7 M750.5,363.4l-0.8,0.8 M754.3,350.5h4.3 M756.7,350.5l0.3-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M754.4,349.8l-0.1,0.8 M754.3,350.5v0.3 M760.2,348.4h1 M759.9,350.5h1
	 M760.9,350.5l0.3-2.1 M758.8,348.4h1.4 M760.2,348.4l-0.3,2.1 M754.4,349.8h2.5c0.3-0.8,0.8-1.4,1.7-1.4h0.3 M758.8,348.4l-0.3,2.1
	 M759.9,350.5h-1.4 M753.2,367c1.7,0,4,1.4,7,4.4c1.7-0.8,2.8-1.8,3.2-2.6c1.4-5.1,1.9-9.2,1.7-12.1c-1.1-1.5-1.5-3-1.1-4.7
	c0-1-0.4-1.4-1.2-1.4 M762.7,350.5l0.3-2.1 M654.5,393.7c2.3-1.4,4.4-2.9,6.5-4.8 M672.5,366.1l-3.7,0.4 M668.8,366.6
	c0.1-1.4,0.1-2.8,0.1-4.3 M669,362.4l-0.3,4.3 M664.8,384.9c4.4-5.4,7-11.7,7.7-18.6 M669.7,347.7l-3.9,1.1 M668.7,356.7l3.3-1.2
	 M655.1,397c3.2-1.6,6.1-3.9,8.8-6.5 M620.3,397.6c5.1,2.5,10.6,3.6,16.6,3.6c5.9,0,11.4-1.1,16.4-3.4 M649.4,396
	c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2 M653.2,397.6l-3.4-1.1l-0.4-0.5 M653.2,397.6l0.1,0.1 M654.5,393.7l0.4,0.5
	l0.1,2.6 M653.3,397.8l1.8-0.8 M653.3,365.7c0.1,2.1,0.3,3.7,0.4,5 M668.6,368.9l0.3-2.2 M657.8,371c0.4-1.1,0.8-2.2,1-3.3
	 M668.8,366.6l-0.3,2.2 M664.8,384.9l-3.3-5.1 M663.9,390.5l1.4-1.4 M663.9,390.5l-2.5-1l-0.4-0.7 M665.3,389v-3.6l-0.4-0.5
	 M646.8,368.5l7,4.7c0.7,0.5,1.4,1.1,1.9,1.7l0.1,0.1c0.8,0.8,1.4,1.6,1.9,2.5l7.3,11.3l0.1,0.1 M661,388.8l-6.9-10.2l-0.7-0.8
	 M653.3,377.8l-0.4,0.4c-2.1,2.1-4.1,3.6-6.6,4.7 M653.7,370.7l3.2,2.3l0.4,0.4c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3 M668.6,368.9
	l-0.3,1.8c-0.4,1.1-1.4,1.9-2.8,2.6c-1.1,0.5-1.7,0.3-1.7-0.4c0-1.5-0.8-2.5-2.3-3c-0.8-0.1-1.4-1-2.1-2.2 M661.5,379.8l3,0.3
	l1.2-5.9h-1.9c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1 M634.2,373.7l-2.5,8c-0.3,0.8-0.5,1.5-1,2.3l-0.1,0.3
	c-0.4,0.8-1,1.6-1.8,2.6l-8.4,10.2 M639.4,374.1l2.3,8c0.3,0.8,0.5,1.5,1,2.2l0.1,0.3c0.5,1,1.1,1.8,1.8,2.6l8.7,10.6 M646.3,382.9
	l0.5,1l7.6,9.8 M624.1,395.9l6.3-7.8c0.8-1,1.5-1.9,2.1-2.9l0.3-0.5 M632.9,384.6l4,0.3l3.6-0.3 M649.4,396l-6.3-7.7l-2.1-2.9
	l-0.4-0.7 M640.5,384.6c-0.3-0.4-0.4-1-0.5-1.4l-1.8-5.9c-0.1-1.2-0.5-1.8-1.1-1.6c-1.1-0.3-1.7,0.3-1.8,1.4l-1.8,5.9l-0.7,1.5
	 M634.2,373.7c0.5,0.7,1.2,1.1,2.1,1.2c1,0.1,1.8-0.1,2.5-0.7l0.5-0.5c0.4-0.4,0.7-1,0.8-1.6c0.1-1-0.1-1.8-0.7-2.5
	c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0.1-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0,1.8,0.5,2.5L634.2,373.7L634.2,373.7z
	 M637.1,370c-0.5-0.1-1,0-1.2,0.3c-0.4,0.3-0.5,0.7-0.7,1.1c0,0.4,0,0.8,0.3,1.2c0.3,0.4,0.7,0.5,1.1,0.7c0.4,0,0.8,0,1.2-0.3
	l0.3-0.3c0.3-0.3,0.4-0.5,0.4-0.8c0.1-0.5,0-1-0.3-1.2C637.9,370.3,637.6,370,637.1,370L637.1,370z M653.3,377.8
	c-0.4-0.4-1-0.7-1.5-1.1l-3.3-1.5l-1.9,0.1l-0.3,0.3l-0.3,0.1c-0.5,0.4-0.8,1.1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.5,1.8
	 M654.9,396.8l-8.8-11c-0.5-0.7-1.1-1.5-1.5-2.3c-0.4-0.8-0.7-1.8-1-3l-1.8-7.8c0-0.7,0.1-1.1,0.5-1.5l0.3-0.3l0.3-0.1h1.7l6.9,4.1
	c1.1,0.5,1.9,1.1,2.6,1.8c0.7,0.7,1.2,1.4,1.7,2.2l7.7,11.7 M646.8,368.5c0.8-0.4,1.4-1,1.8-1.6c0.4-0.8,0.5-1.6,0.3-2.6l-0.4-0.7
	c-0.3-0.5-0.7-1-1.4-1.2c-0.8-0.4-1.7-0.5-2.6-0.1c-1,0.3-1.5,0.8-1.9,1.6s-0.5,1.6-0.3,2.6s0.8,1.5,1.7,1.9
	c0.8,0.4,1.7,0.5,2.6,0.3L646.8,368.5L646.8,368.5z M644.2,364.5c-0.3,0.4-0.3,0.8-0.1,1.4c0.1,0.4,0.4,0.7,0.8,1h1.2
	c0.4-0.1,0.8-0.4,1-0.8c0.3-0.4,0.3-0.8,0.1-1.2l-0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.7l-1.2-0.1C644.6,363.8,644.4,364.1,644.2,364.5
	L644.2,364.5z M640.1,358.6c-1-0.8-1.9-1.2-3.2-1.2c-1.4,0-2.5,0.5-3.6,1.5c-1,1-1.4,2.2-1.4,3.6c0,0.4,0,0.8,0.1,1.2
	c0.1,0.7,0.5,1.4,1,1.9l0.3,0.4c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.5,2.9-1.4c1-1,1.4-2.1,1.5-3.3v-0.3
	c0-1.4-0.5-2.5-1.5-3.6L640.1,358.6L640.1,358.6z M633.2,355.4c0.1-0.4,0.1-0.8-0.1-1.2c-0.3-0.4-0.5-0.7-1-0.8l-1.2,0.1
	c-0.4,0.1-0.7,0.5-0.8,1v0.4c-0.1,0.3,0,0.5,0.1,1c0.3,0.4,0.5,0.7,1,0.8l1.4-0.1C632.8,356.2,633.1,355.8,633.2,355.4L633.2,355.4z
	 M632.4,351.7c-0.8-0.3-1.7-0.1-2.3,0.3c-0.8,0.4-1.4,1.1-1.7,1.9l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,1.9,1.6
	c1,0.3,1.8,0.3,2.6-0.1s1.4-1.1,1.7-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6L632.4,351.7L632.4,351.7z M645.7,354.9
	c0-0.8-0.4-1.6-1-2.2c-0.7-0.7-1.5-1-2.3-1l-0.8,0.1c-0.7,0.1-1.1,0.4-1.7,0.8c-0.7,0.7-1,1.5-1,2.5s0.3,1.8,1,2.3
	c0.7,0.7,1.5,1,2.3,1c1,0,1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3v-0.3L645.7,354.9L645.7,354.9z M643.5,353.8c-0.3-0.3-0.7-0.5-1.2-0.5
	h-0.4c-0.3,0-0.6,0.3-0.8,0.4c-0.4,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5
	c0.3-0.3,0.5-0.7,0.5-1.2C644.1,354.6,643.9,354.2,643.5,353.8L643.5,353.8z M653.3,358.8c0-1.8,0.1-3.2,0.3-4.4 M656.3,357.6v-0.8
	 M106.2,303l-19.5,15.7 M669,362.4l-5.6,2.2c-1.1,0.4-2.2,0.8-3.4,1.1l-0.4,0.1 M660.2,359.7l0.3-0.1l8.3-3 M656.3,365.9l-3-0.1
	 M656.3,365.9v0.7 M659.2,365.7l-3,0.1 M659.2,366.1v-0.3 M659.2,366.1l0.4-0.4 M656.3,366.6c1.4,0.1,2.3,0,2.9-0.4 M658.9,367.4
	h-2.8 M659.3,367.7l-0.1-0.1h-0.3 M659.3,367.7l-0.5,0.1 M658.9,367.7v-0.3 M656.3,367.4v-0.8 M656.3,367.4v0.3
	c0.1,1.8,0.7,2.9,1.7,3.3 M659.6,365.7h-0.4 M653.3,365.7h-1.8c-1.2-0.3-1.8,0-1.9,0.5c-0.5,1-0.3,1.6,0.8,2.2l3.2,2.2 M653.3,358.9
	l0.7,0.1l2.3,0.4 M660.2,359.7c-0.1-0.8-0.5-1.4-1.1-1.8c-0.7-0.4-1.5-0.5-2.8-0.4 M656.3,359.5l1.2,0.3h1.8l0.8-0.1 M656.3,357.6
	v1.9 M658.8,356.7h-2.5 M656.3,356.7v-0.3c0.1-1.6,0.6-2.8,1.4-3.2 M658.8,356.7c-0.3-1.2-0.7-2.3-1.2-3.4 M653.6,354.5l-0.8,0.8
	c-0.5,0.5-0.8,1.2-0.5,1.9l0.3,0.7c0.1,0.4,0.4,0.8,0.8,1 M647,342.2c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3 M670.9,343.7l-13.2,3.4
	c-1.1,0.3-2.1,0.7-2.9,1.1l-0.3,0.1c-0.8,0.4-1.5,0.8-2.2,1.4l-6.7,5.1 M665.8,348.8l-8.1,2.3c-0.3,0.1-0.7,0.3-1,0.4
	c-0.5,0.3-1.1,0.7-1.5,1.1l-1.7,1.8 M668.6,355.3l0.1,1.4 M668.6,355.3l-0.3-1.8c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5
	c0,1.5-0.8,2.5-2.3,3c-0.8,0.1-1.5,1-2.2,2.3h-0.6 M668.7,356.7l-0.1-1.4 M657.6,353.4c0.3-0.1,0.7-0.3,1.1-0.3
	c1.1,0.1,1.7-0.3,1.9-1.2c0.1-1.1,1.2-1.6,3.3-1.8h1.9v-1.2 M704.9,372.6l-0.7-9.9 M587.5,375.5c1.7,6.2,4.4,11.8,8.3,17.1l1.9,2.5
	l3.2,3.4c8.4,8.4,18.3,13.2,29.6,14.6l5.2,0.4l7.4-0.3c11.6-1.2,21.5-6.2,30-14.7c2.3-2.3,4.4-4.8,6.2-7.3
	c5.5-7.8,8.4-16.8,8.8-26.7 M697,363.5l0.7,10 M697.7,373.4l7.2-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M675.6,357.8l-0.3-1.9 M675,358l-6.1,2.3l-6.2,2.3c-1,0.4-1.9,0.7-3,1
	l-2.8,0.3l-8.3-0.3 M672.7,360.9l-3.6,1.4 M672.5,366.1c0.1-1.2,0.1-2.5,0.1-3.9v-1.4 M675.3,357.9l-0.1,0.1 M672.7,360.9l0.8-0.3
	l2.2-2.9l-0.1,0.1 M665.3,389.1c7-7.4,10.6-16.4,10.6-26.7c0-1.6-0.1-3.2-0.3-4.7 M675.3,357.9l0.3-0.1 M675.3,357.9L675,358
	 M675.6,357.8l-0.4,0.1 M672,355.4c-0.5-2.8-1.4-5.2-2.5-7.7 M672,355.4l0.7-0.3l2.6,0.7 M675.3,355.8l-6.5,2.5l-6.6,2.5
	c-0.8,0.4-1.8,0.7-2.8,0.7c-1,0.1-2.1,0.1-3.2,0l-8-0.8c-0.5-0.1-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.6l6.1-5.4
	c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9 M688,364.5l9.1-1 M499.8,270.8l146.6-5.5l47.3-1.2 M672,345.4
	l-1.8,2.1l-0.7,0.3 M671.2,343.6h-0.3 M672,345.4l-1-1.8 M704.2,362.7l-0.7-9.8c-0.4-3.3-1-6.5-1.7-9.5c-2.3-8.9-6.9-16.9-13.3-24.2
	c-1.2-1.4-2.6-2.8-4-4l-0.1-0.1c-1.9-1.8-3.8-3.4-5.9-5l-1.5-1.1c-11.4-8.1-24.5-12.2-39.5-12.2c-15.5,0-29.3,4.4-41,13.2
	c-2.1,1.5-4,3.2-5.9,5c-5.8,5.4-10.2,11.4-13.3,17.7c-2.2,4.5-3.9,9.2-4.8,14.3c-0.3,1.8-0.4,3.6-0.5,5.2c-0.1,1.5-0.1,3-0.1,4.5
	 M697,363.5l-0.4-5.5c-1.1-12.4-5.6-23.4-13.6-32.9l-3.6-4c-1.4-1.5-2.9-2.8-4.3-4c-1.4-1.1-2.6-2.2-4-3.2c-2.1-1.5-4-2.8-6.2-3.9
	c-8.3-4.4-17.5-6.7-27.5-6.7c-10.2,0-19.3,2.2-27.7,6.7c-5.2,2.9-10,6.5-14.6,11.1c-8.8,9.1-14.3,19.4-16.4,31.2
	c-0.3,1.5-0.4,3-0.4,4.5 M688,364.5v-2.1c0-10.7-2.9-20.4-8.8-28.8c-1.8-2.6-3.9-5-6.2-7.3l-4.8-4.3c-8.9-7.2-19.4-10.6-31.4-10.6
	c-11.8,0-22.3,3.6-31.2,10.6c-1.7,1.4-3.3,2.8-4.8,4.3c-1.9,1.9-3.6,3.9-5.1,5.8c-6.6,8.7-9.9,18.8-9.9,30.3c0,4.7,0.5,9.1,1.7,13.2
	 M671.2,343.6c-1.8-3.2-4-6.1-6.7-8.8c-5.8-5.8-12.4-9.4-19.9-10.7 M653.2,346.8l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5
	 M670.9,343.7l-3.4-1.1l-0.7,0.1 M666.9,342.8c-1.4-1.9-2.9-3.9-4.7-5.6c-4.5-4.5-9.8-7.7-15.5-9.2 M642.6,323.9l-5.6-0.4l-5.4,0.4
	 M647,342.2l-0.4,1.5l-2.1,5.8c-0.5,1.1-0.4,1.9,0.4,2.5c0.4,0.4,1,0.3,1.9-0.5l5-3.7c0.4-0.4,0.8-0.7,1.4-0.8 M641.7,351.6l2.8-7.8
	l0.7-2.8c0.1-1.1,0.1-2.1,0.1-3.2l-0.8-13.6 M641.3,340.3l0.1-1.2l-0.4-12.2 M642.6,324.5l0.5,13.5c0.1,0.8,0,1.8-0.1,2.8
	c-0.1,1-0.5,1.9-1.1,3l-3.2,7.4c-0.4,0.6-0.8,0.8-1.4,1h-0.5c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4c-0.5-1.1-1-2.1-1.1-3
	c-0.1-1-0.3-1.9-0.1-2.8l0.5-14 M646.5,327.9l0.5,10v3.6l-0.1,0.7 M641.3,340.3l-4.4-0.4l-4.3,0.4 M633.1,326.9l-0.4,12.2l0.1,1.1
	 M632.7,340.3c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.7,1.1h0.7c0.7,0,1.2-0.3,1.7-1.1l1.8-3.2c0.3-0.5,0.5-1.1,0.7-1.6
	 M631.6,323.8l1.5,2.2v0.8 M641,326.9l-4.1-0.3l-3.9,0.3 M644.5,324.2l-1.9-0.3 M77.7,374.7c-0.4-0.5-0.4-1.4,0-2.3
	c1.5-3.6,3.2-5.6,5-6.2c2.9,0.3,5.1-0.1,6.6-1.2l2.8-3.3 M642.6,323.9l-1.5,2.2v0.8 M642.6,324.5v-0.5 M642.6,323.9v0.5
	 M644.5,324.2l2.1,2.9l0.1,0.8 M679.7,271.2c1.4,1.5,2.1,3.2,2.1,5.1v6.9c0,2.1-0.7,3.7-2.1,5.2c-1.4,1.4-3.2,2.1-5.2,2.1
	c-6.3,0.8-12.7,0.8-18.7,0c-2.1,0-3.7-0.7-5.1-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9c0-2.1,0.7-3.7,2.1-5.1c1.5-1.4,3.2-2.1,5.1-2.1
	c6.5-0.8,12.7-0.8,18.7,0C676.7,269.2,678.3,269.9,679.7,271.2L679.7,271.2z M694,264.1h-0.3 M693.7,264.1h0.3 M763.5,343.5h-1.8
	 M761.8,341.4h1.8 M763.8,329.1h-5.5 M762.1,336.3h-1 M763.6,326.8c-2.6,0-4.4,0.8-5.4,2.3 M762.2,334.1l-0.1,2.1 M764,336.3
	l0.1-2.1 M763.5,343.4l0.1-2.1 M762.9,348.4h1.4l0.4-3.6c0-1-0.5-1.4-1.4-1.4 M764,336.3h-1.9 M763.8,341.4h1.5l0.3-3.7
	c0-1-0.5-1.5-1.5-1.4 M169.5,375.9l11.6,0.3 M124.5,353.1c2.9-0.4,5.9-0.7,9.1-0.8l4.3-0.1 M137.4,355.8l-4.5-0.1
	c-3.2,0.1-6.2,0.3-9.1,0.8 M137.1,353.9H133c-3.2,0-6.1,0.3-8.8,0.7 M137.4,355.8l0.4-3.7 M137.3,357.6l-4.3-0.1
	c-3.2,0.1-6.2,0.3-9.1,0.8 M135.8,361.3h-3.3l-8.3,0.7 M136.3,360H133l-8.9,0.7 M122.4,362.3c1.4,2.2,4.1,2.9,8.4,2.2
	c3.7-0.7,5.9-2.9,6.5-6.5v-0.3 M137.3,357.6l0.1-1.8 M138,352.3l0.4-3.6 M126.4,349.2c-0.4,0.6-0.7,1.1-1.1,1.6 M123.7,349.8
	l-0.8,1.6 M121.6,355.1c0,0.5-0.1,1.2-0.1,1.8 M121.9,353.5c-0.1,0.4-0.3,1-0.3,1.6 M122.7,351.3l-0.4,0.8c-0.1,0.4-0.3,0.8-0.4,1.4
	 M123.9,356.5l-2.3,0.4 M121.6,355.1l2.5-0.4 M125.5,350.9l-2.8,0.5 M124.2,354.7c0.1-0.5,0.3-1.1,0.4-1.5 M123.7,349.8
	c1.2-2.1,2.5-3.2,3.9-3.2l6.1-1.6 M126.4,349.2l-2.9,0.5 M124.5,353.1l-2.6,0.4 M121.6,358.7c0,0.8,0.1,1.5,0.4,2.3 M122,361.1v0.4
	l0.4,0.7 M121.6,358.7l2.2-0.4 M124.2,362l-1.8,0.3 M124.1,360.8l0.1,1.2 M122,361l2.1-0.3 M121.5,356.9c0,0.5,0,1.2,0.1,1.8
	 M123.9,358.3v-1.8 M138.4,348.5c-4.3-0.1-8.3,0.1-11.8,0.7 M137.6,350.2c-4.4,0-8.4,0.1-12.1,0.7 M764,334.1h1.5v-2.8l-1.8-2.3
	 M763.8,329.1c1.8-0.1,2.8-1.2,2.8-3.2 M762.2,334.1h1.8 M761.1,336.3l0.1-2.1 M759.8,335.5c0.3-0.8,0.8-1.2,1.5-1.4 M759.8,336.3
	h-3 M756.9,335.5h2.9 M756.7,336.3l-1.1,6.5 M760.7,343.5l0.1-2.1 M761.8,341.4l-0.1,2.1 M758.2,343.5l0.3-0.7 M759.3,343.5l0.1-1.9
	 M760.7,343.4h-1.4 M761.7,343.4h-1 M759.3,343.5h-3.7 M755.6,342.8h2.9c0.3-0.5,0.5-1,1-1.1 M759.5,341.5c0.3-0.1,0.4-0.1,0.7-0.3
	h0.7 M760.9,341.4h1 M755.6,342.8l-0.1,0.8 M756.9,335.5l-0.1,0.8 M759.8,336.3v-0.8 M761.1,336.3h-1.4 M761.3,334.2h1 M758.2,329.1
	c-0.3,0.4-0.4,1-0.7,1.5l-0.8,4.8 M758.4,310.3l-1.8-6.2l-5-1.2 M766.5,326l-1-12.5c-0.1-1.5-0.5-2.3-1.5-2.5l-3.3-0.4l-2.5-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M726.9,298.6l-8.1-0.5c-8.3-0.4-16.5,0-24.5,1c-5.5,1.9-10.9,5.1-16.4,9.4
	l-1,0.7l-5.8,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M718.8,298.1l-10-24.1c-2.5-6.1-7.3-9.4-14.7-9.9 M726.9,298.6l7,0.8
	l3.2,0.5c3.3,0.5,6.7,1.1,10.2,1.9l2.9,0.7l1.5,0.4 M694,264.1c7-0.4,14.2-1.8,21.5-3.9h0.1l18.4-4.5 M734,255.7
	c1.4,1.7,2.5,5.2,3.4,10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M726.9,298.6l-3.9-9.1l-0.3-0.7l-1.8-4.3l-5-11.7c-0.1-0.5-0.3-1-0.3-1.4
	c-0.3-2.6,1.5-4,5.6-4.3l14.7-1l1.7-0.1l4.1,16.4c0.6,2.5,2.5,4.8,5.6,6.7c1.9,1.6,3.3,6.1,4.4,13.3 M760.9,310.6l-0.5-8.4
	c-1.5-1-2.8-2.3-3.6-3.9l-4.8-24.8 M751.4,253.1c-2.2-0.6-2.9,1.5-2.2,6.1l2.6,14.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M751.8,273.6c2.5-0.4,3.6-1.5,3.4-3.2l-3.4-15c-0.1-0.8-0.3-1.5-0.4-2.2
	 M602.9,343.5v-0.1 M602.9,343.3l-1,1.8 M602.5,345.2l-0.6-0.1 M602,345.1l0.5,0.1 M602.5,345.2h0.1l12.8,3.6c1,0.3,1.8,0.5,2.6,1
	l0.1,0.1c0.8,0.4,1.7,1.1,2.5,1.9l6.1,5.4c0.4,0.5,0.6,1.1,0.4,1.5v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-8,0.8
	c-1.2,0.1-2.2,0.1-3.2,0c-1.1-0.1-1.9-0.4-2.8-0.8l-12.7-4.7 M607.1,342.5l-0.7-0.1l-3.4,1.1 M627.6,327.9
	c-5.9,1.5-11.1,4.5-15.8,9.2c-1.8,1.6-3.2,3.4-4.5,5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.6,324.2c-7.7,1.4-14.4,5-20.2,10.7c-2.6,2.6-4.8,5.5-6.5,8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.6,324.6v-0.4l-2.1,3v0.7 M631.6,323.8l-1.9,0.4 M629.6,324.6v-0.4
	 M632.4,351.7l-2.9-8c-0.3-0.8-0.5-1.6-0.5-2.3v-0.3c-0.1-1-0.1-1.9-0.1-3.2l0.8-13.2 M627.6,327.9l-0.5,10c-0.1,1.2-0.1,2.3,0,3.6
	l0.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.2,342.1c0.1,0.5,0.3,1.1,0.4,1.5l2.1,5.8c0.5,1.1,0.5,1.6,0,1.9
	c-0.8,0.7-1.7,0.4-2.5-0.4l-5-3.7c-0.4-0.3-0.8-0.5-1.2-0.7 M621,346.6l-0.8-0.4c-1.1-0.5-2.2-0.8-3.4-1.2l-9.6-2.5 M498.8,260.4
	l42.1-1.6c5.2-0.4,9.8-1.4,13.5-2.9 M497,357.1c3-10.6,5-22.8,5.9-36.6c0.7-19.4-0.4-35.9-3-49.5v-0.3 M496.2,260.5l2.6-0.1
	 M499.8,270.8l-3.6-10.3 M496.3,258.8l-0.1,1.6 M331.7,264.4l151.2-5.1l13.5-0.4 M497,357.1l-2.1,6.2 M495,363.3l52-1.2
	c10.6,0.5,18.8,3,24.6,7.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.2,342.1c-2.2,1.1-4.1,2.5-6.1,4.4l-0.1,0.1 M628,354.5l-6.6-5
	c-0.7-0.5-1.2-1-1.9-1.2l-0.4-0.4c-1-0.4-1.9-0.8-2.9-1.1l-13.2-3.4 M617.4,351.3c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.6,359.5l1.8-0.1l3.6-0.7c0.8-0.1,1.4-0.5,1.5-1.2l0.3-0.7
	c0.3-0.7,0-1.4-0.5-1.9l-2.5-2.6c-0.4-0.4-1-0.8-1.4-1.1 M602,355.1l11.4,4.1l1.2,0.3 M625.2,363.4l-8.4,0.3l-2.3-0.1l-0.4-0.1
	c-1-0.1-1.9-0.4-3-0.8l-12.7-5.1 M601.3,360.6l9.2,3.6c1.2,0.4,2.3,0.8,3.4,1.1l0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.6,365.5h7.7c1.2-0.3,1.8,0.3,1.9,1.2c0.3,0.5,0,1-1.1,1.5l-5.1,3.6
	c-0.3,0.1-0.4,0.3-0.5,0.4 M625.2,363.4c-0.4,0.7-0.7,1.5-0.4,2.3c0.1,1,0.5,1.6,1.4,2.2l0.7,0.4c0.5,0.3,1.2,0.3,1.8,0.3
	c1-0.1,1.7-0.7,2.2-1.4c0.6-0.8,0.8-1.6,0.7-2.5c-0.1-1-0.7-1.6-1.4-2.2c-0.8-0.6-1.7-0.8-2.5-0.6c-1,0.1-1.7,0.5-2.2,1.4
	L625.2,363.4L625.2,363.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626.5,365.5c0.1,0.4,0.3,0.8,0.7,1.1l0.4,0.1l1,0.1
	c0.4,0,0.8-0.3,1.1-0.7c0.3-0.4,0.4-0.8,0.3-1.2c-0.1-0.5-0.3-0.8-0.7-1.1s-0.8-0.4-1.2-0.3c-0.4,0.1-0.8,0.3-1.1,0.7
	C626.5,364.6,626.5,365,626.5,365.5L626.5,365.5z M617.4,351.3c-1.5,2.5-2.3,5.2-2.8,8.3 M598.5,355.7h0.5 M599.1,355.7l-0.5-0.1
	 M598.5,355.6v0.1 M598.5,355.6l2.6-0.7l0.8,0.3 M598.3,357.5c-0.1,1.6-0.3,3.2-0.3,5c0,10.2,3.4,19.1,10.3,26.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M602.9,373.9c-1.2-3.6-1.8-7.4-1.8-11.4v-1.6 M598.4,357.5h-0.1
	 M598.4,357.5l2.1,2.9l0.8,0.3 M598.5,355.7l-0.3,1.8 M604.3,347.4c-1.1,2.5-1.9,5-2.5,7.7 M602,345.1l1.7,2.1l0.7,0.3 M609.7,390.2
	h0.1 M609.7,390.2v0.1V390.2 M609.7,390.2h0.1 M612.7,388.7l-0.5,0.7l-2.3,0.8 M608.9,384.6l-0.4,0.5l-0.1,3.7 M608.3,388.8l1.4,1.4
	 M602.9,373.9c1.2,3.9,3.2,7.4,5.9,10.7 M627.3,382.8c0.3-0.5,0.4-1.1,0.6-1.8l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.3l-0.3-0.3
	h-1.9l-3.3,1.5c-0.6,0.3-1.1,0.5-1.4,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.7,390.2l7.7-11.6c0.4-0.8,1.1-1.5,1.8-2.2v-0.1
	c0.8-0.7,1.7-1.2,2.6-1.8l6.9-4.1l1.7,0.1l0.3,0.1l0.3,0.3c0.4,0.4,0.5,0.8,0.5,1.5l-1.8,7.8c-0.3,1.1-0.5,2.2-1,3v0.1
	c-0.4,0.8-1,1.6-1.5,2.2l-8.8,11 M617.5,372.2l-0.5,0.5 M617,372.8l-0.3-0.4 M617.5,372.2l-0.8,0.1 M608.9,384.6l5.4-8.4
	c0.7-1.1,1.4-2.1,2.2-2.9l0.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626.6,368.3l-6.9,4.7c-0.7,0.5-1.2,1-1.8,1.5l-0.3,0.3
	c-0.7,0.7-1.4,1.5-1.9,2.5l-7.4,11.4 M620.4,377.6l0.7,0.7c1.9,1.9,4,3.4,6.2,4.4 M620.4,377.6l-0.8,1l-6.9,10.2 M619.2,393.5
	l7.6-9.8l0.5-1 M614.6,365.5c0.3,2.5,1,4.7,2.1,6.9 M616.7,372.3l-13.8,1.5 M578.5,356.9v0.5c-0.1,3-0.3,5.9-0.3,8.9v10
	 M571.7,369.6v-8c0-1.5,0-3.2,0.1-4.7 M571.7,383.2v-13.6 M578.2,376.6l9.4-1.1 M571.7,383.2l6.5,0.4v-7 M571.7,356.9h6.7
	 M624.1,395.9l-0.4,0.6l-3.3,1.1 M618.5,396.8h0.1 M618.5,396.8l1.8,0.8 M620.4,397.5l-0.1,0.1 M620.7,397.2l-0.3,0.3 M618.6,396.8
	l0.1-2.8l0.4-0.5 M620.4,397.5l0.3-0.3 M609.8,390.2c2.8,2.6,5.6,4.8,8.8,6.6 M612.7,388.7c2.1,1.9,4.3,3.4,6.5,4.8 M571.7,356.9
	l-74.7,0.1 M602,345.1c-1.7,3.3-2.8,6.7-3.3,10.5 M334.1,251c-0.3-3.2-1-5.2-2.1-6.3 M554.4,256c1.7-0.7,3.2-1.4,4.5-2.2
	 M299.5,278.8l200.3-8 M496.2,260.5l-193.4,6.6 M554.4,256l4.5-2.3 M498.8,260.4L500,211 M496.3,258.8l1.1-47.9 M751.4,253.1
	c-0.3-0.7-0.5-1.1-1-1.5 M681.8,244l-26.5-11.1c-23.4-10.5-48.8-19-76.3-25.7c-7.7-1.9-15.7-3.7-23.8-5.2h-0.3 M734,255.7
	c-0.5-0.8-1.2-1.1-1.9-1.1l-31.1-2.9l-7.7-1.1 M693.3,250.6l4.1-0.1 M750.4,251.7c-0.5-0.6-1.4-0.7-2.2-0.7l-44.2-2.5
	c-2.3,1.1-4.5,1.6-6.6,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M697.5,250.5l-4.3-1.6l-9.8-4.1 M681.9,248l-1.9-0.6 M693.3,250.6
	c-3.3-0.6-6.3-1.1-9.1-1.9l-2.5-0.7 M681.8,244l-1.8,3.4 M680,247.4l-1-0.4 M683.4,244.7l-1.5,3.3 M683.4,244.7l2.2-4.8l-0.4-0.6
	l-0.4-0.3h-0.5l-2.6,5"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M304,265.3l27.6-1
		l0.4-1.6c0,0,3.2-0.1,3.7-4.1c0,0,0.5-2.3-0.4-8.1c0,0-0.7-6.3-5.4-6.3l-3,0.4c0,0-10.3,2.8-13.2,10.3l-0.4,1.1
		C313.2,256,307.5,260.5,304,265.3z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M302.8,267.1
		c0,0-2.5,3.9-3.3,11.7c0,0-1.1,17.3,0.4,40.6c0,0,1.7,24.2,4.7,41.4c0,0,1.4,9.8,10.5,8.5l166.6-1.2c0,0,5.8,0.3,9.6-1.4
		c0,0,2.6-1.1,3.7-3.6l1.9-6.2c0,0,6.2-21,6.1-46.5c0,0,0.3-22.7-3.2-39.8l-3.6-10.3L302.8,267.1z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M194.5,315.8
		l-4.7-5.6l-26.6-3.7h-9.8c0,0,8.3-6.1,13.5-11.1c0,0,5-4.3,4.5-5.5c0,0-0.4-1-3-1.1l-21.9,2.2c0,0,6.3-6.1,21.5-9.2
		c0,0,34.4-6.7,61.1-8.8c0,0,21.5-1.8,34-8.9l39.5,3c0,0-2.5,3.2-3.3,11.7c0,0-1.2,15.3,0.4,40.6c0,0,1.6,24.1,4.7,41.4h-16.5V359
		c0,0-0.7-27.6-28.1-45.9C260.1,313.2,228.5,291.5,194.5,315.8z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="230.3"
              cy="362.8"
              r="51.2"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M497,357.1
		l81.6-0.1c0,0,0.3-23.4,22.7-41.1c0,0,14.9-13.5,39.5-12.5c0,0,16.4,0.1,30.5,10.6c0,0,12.5-12,23.1-15c0,0,13.2-1.7,24.5-1
		l-10-24.1c0,0-2.6-9.4-14.7-9.9l-194.4,6.9c0,0,2.6,13.3,3,29.2c0,0,1.2,17.5-1.6,36.5C501.1,336.6,500.3,344.4,497,357.1z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M336.3,245.8
		c0,0,27.5-20.4,53.9-27.1c0,0,17.7-3,61.8-3.4h34.3l-3.4,44l-151.2,5.1l0.4-1.6c0,0,2.6-0.3,3.6-3c0,0,1-2.3-0.3-9.4
		C335.4,250.2,336.3,247.7,336.3,245.8z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M508.4,250.6
		c0,0,0,7.7,9.1,7.6c0,0,23.7,0.8,37.7-4.5c0,0,16.9-8.3,25.7-16.8c0,0,1.8-1.8,1.9-2.5c0.1-0.6,0.8-1.4-1-3.4
		c-1.8-2.1-5.9-4-16.2-7.3c0,0-6.2-2.3-12.9-3.6c0,0-7.6-1.7-17.9-2.9c0,0-12.4-1.2-18.6-1.1c0,0-6.1-0.3-7,5.8L508.4,250.6z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="636.8"
              cy="362"
              r="51.2"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback3DrLeft);
