import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { confirmable } from 'react-confirm';

class AmountConfirmDialog extends Component {
  render() {
    const {
      okLabel = 'OK',
      cancelLabel = 'Cancel',
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      enableEscape = false,
      message = '',
      hideCancel = false,
      hideOk = false,
      paddingTop = 'initial',
    } = this.props;

    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={dismiss}
          backdrop={enableEscape ? true : 'static'}
          keyboard={enableEscape}
          style={{
            paddingTop,
            backgroundColor: `rgba(0, 0, 0, .5)`,
          }}
        >
          <Modal.Body
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
              backgroundColor: '#FFCC00',
              color: '#363838',
            }}
          >
            {confirmation}
            {message && <div>{message}</div>}
            <div>
              {!hideCancel && (
                <Button
                  className="button-l"
                  style={{ marginRight: 5 }}
                  bsStyle="primary"
                  onClick={cancel}
                >
                  {cancelLabel}
                </Button>
              )}
              {!hideOk && (
                <Button
                  className="button-l"
                  bsStyle="default"
                  onClick={proceed}
                >
                  {okLabel}
                </Button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

AmountConfirmDialog.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  cancel: PropTypes.func, // called when cancel button is clicked.
  dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
  enableEscape: PropTypes.bool,
};

export default confirmable(AmountConfirmDialog);
