import React, { Component } from 'react';
import Icon from '../icons';
import { CYAN_01 } from '../../../constants/colors';
import Loader from '../loader';
import PropTypes from 'prop-types'

class Button extends Component {
  render() {
    const {
      btnText,
      disabled = false,
      onClickHandler,
      width = '100%',
      height = '50px',
      bgColor = CYAN_01,
      borderColor = CYAN_01,
      borderRadius = '4px',
      textColor = '#fff',
      outlined = false,
      outlinedBgColor = '#FAFAFA',
      outlinedBorderColor = CYAN_01,
      outlinedTextColor = CYAN_01,
      outlinedIconColor = CYAN_01,
      iconName = null,
      marginRight = '0px',
      marginLeft = '0px',
      iconSize = 20,
      iconColor = '#fff',
      fontSize = '14px',
      fontWeight = 400,
      justifyContent = 'center',
      loading = false,
      iconOnRight = false,
    } = this.props;

    const buttonStyle = {
      height: height,
      width: width,
      backgroundColor: outlined ? outlinedBgColor : bgColor,
      borderWidth: '1px',
      borderColor: outlined ? outlinedBorderColor : borderColor,
      borderStyle: 'solid',
      borderRadius: borderRadius,
      verticalAlign: 'middle',
      color: outlined ? outlinedTextColor : textColor,
      fontSize: fontSize,
      lineHeight: '20px',
      fontWeight: fontWeight,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: justifyContent,
      padding: '10px 15px',
      whiteSpace: 'normal',
    };

    return (
      <div>
        <button
          style={buttonStyle}
          disabled={disabled}
          onClick={onClickHandler}
        >
          {!iconOnRight &&
            iconName &&
            loading !== true && (
              <Icon
                name={iconName}
                size={iconSize}
                color={outlined ? outlinedIconColor : iconColor}
                marginLeft={marginLeft}
                marginRight={marginRight}
              />
            )}
          {loading === true && (
            <Loader
              className="conditions-loader flex justify-center pt-1 "
              textVisibility={false}
              size={18}
              color={'white'}
            />
          )}
          {loading === false && btnText}
          {iconOnRight &&
            iconName &&
            loading !== true && (
              <Icon
                name={iconName}
                size={iconSize}
                color={outlined ? outlinedIconColor : iconColor}
                marginLeft={marginLeft}
                marginRight={marginRight}
              />
            )}
        </button>
      </div>
    );
  }
}

Button.propTypes = {                    // defaults 
  btnText: PropTypes.string,            // --------
  disabled: PropTypes.bool,             // false,
  onClickHandler: PropTypes.func,
  width: PropTypes.string,              // '100%',
  height: PropTypes.string,             // '50px',
  bgColor: PropTypes.string,            // CYAN_01,
  borderColor: PropTypes.string,        // CYAN_01,
  borderRadius: PropTypes.string,       // '4px',
  textColor: PropTypes.string,          // '#fff',
  outlined: PropTypes.bool,             // false,
  outlinedBgColor: PropTypes.string,    // '#FAFAFA',
  outlinedBorderColor: PropTypes.string,// CYAN_01,
  outlinedTextColor: PropTypes.string,  // CYAN_01,
  outlinedIconColor: PropTypes.string,  // CYAN_01,
  iconName: PropTypes.string,           // null,
  marginRight: PropTypes.string,        // '0px',
  marginLeft: PropTypes.string,         //'0px',
  iconSize: PropTypes.number,           // 20,
  iconColor: PropTypes.string,          // '#fff',
  fontSize: PropTypes.string,           // '14px',
  fontWeight: PropTypes.number,         // 400,
  justifyContent: PropTypes.string,     // 'center
  loading: PropTypes.bool,              // false,
  iconOnRight: PropTypes.bool,          // false,
}

export default Button;
