import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, some } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { loadAuctionArbitration, resetAuctionArbitration } from '../../actions';
import { getConfig, compose } from '../../utils/helpers';

class BuyerArbs extends Component {
  state = {
    title: 'buyer arbs',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetAuctionArbitration();
    this.props.loadAuctionArbitration(getConfig('marketplaceId'));
  };

  render() {
    const loading = isEmpty(this.props.auctionArbitration);
    const data =
      this.props.auctionArbitration.data ||
      [].filter(item => {
        const { bCompNo, sCompNo, type } = item;
        const accountList = get(this.props.userProfile, 'user.accountList');
        let isBuyer = some(accountList, { accountNumber: bCompNo });
        let isSeller = some(accountList, { accountNumber: sCompNo });
        if (isBuyer && isSeller) {
          if (type === 'Title Not In') isSeller = false;
          if (type === 'Title Due From You') isBuyer = false;
        }
        return isBuyer;
      });
    const columns = this.props.makeColumns([
      'checkbox',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'saleDate',
      'status',
      'details',
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Arbitration</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auctionArbitration, userProfile } = state.entities;
  return { auctionArbitration, userProfile };
};

export default compose(
  withTable,
  connect(
    mapStateToProps,
    { loadAuctionArbitration, resetAuctionArbitration }
  )
)(BuyerArbs);
