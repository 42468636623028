import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe4DrLeft extends Component {
  constructor(props) {
    super(props);
  }
  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#5E5E5E"
            d="M206.3,301.9c-28.6,0-51.7,23.1-51.7,51.7c0,8.3,1.9,16,5.4,22.8c8.4,17.1,26,28.9,46.4,28.9
	c19.1,0,35.8-10.3,44.7-25.7c4.4-7.6,7-16.5,7-25.9C257.9,325,234.8,301.9,206.3,301.9L206.3,301.9z"
          />
          <path
            fill="#FFFFFF"
            d="M80.9,357.6l-1.1,0.8c-1.2,0.7-0.6,2.5-0.6,2.5l2.3,3.6c0.4,0.5,0.7,0.7,1.1,0.8h2.8c0,0,4.3,3.2,6.7,6.2
	c0,0-2.1,2.5,0.6,3l1.4-0.1c0.6,0,1.1-0.1,1.8-0.1c1.1,0.1,2.5,0.3,4,0.4l25.9,0.1l17.3,0.1c0,0,0.8,0.1,0.8-1V372
	c1.2,0,1.5-0.5,1.5-0.8V371c0,0,0.1-7.2,0.1-19.5c0-12.8,4.5-24.8,12-34.3c2.2-2.9,4.8-5.5,7.6-8c10.7-9.2,24.9-15,40.4-15
	c33,0,60,25.6,60,57.2c0,5.1-0.1,7.8-0.1,11.7l-0.8,0.4c0,4.1-0.1,9.4-0.7,13.3l26.8,0.1h2.5l268.8,0.7l0.5-12.9l0.5-11.3l-1.2-2.2
	c0-31.5,27-57.2,60-57.2c7.7,0,15.1,1.4,21.9,4c22.3,8.4,38.1,29,38.1,53.2c0,6.5-0.1,9.2-0.1,15c0,0-0.1,2.1,1.5,2.8h0.1
	c0.5,0.1,1.5,0.1,2.6-0.1c0,0,11.6-3.2,14.6-4.5c0,0,11.6-3,19.5-6.2c1.7-0.7,3.2-1.4,4.4-2.1c0.7-0.4,9.8-6.3,14.7-11.3l0.3-0.3
	c1.2-1.1,4.8-2.5,5.4-0.1v0.1h1.2c1,0,1.7,3,1.7,6.9c0,2.2-0.3,4.3-0.7,5.5l3.6,0.5c0,0,5.6-5.1,10.7-16.4c0,0,3.2-2.2,3.4-8.7
	c0,0,1.4-3.4-2.2-3.7c0,0-2.8,0.3-2.8-3.2c0,0,1.9-19.7,1.1-24.8c0,0-0.8-1.9,1.1-1.9c0,0,3.2,0.5,2.8-2.2c0,0,0.7-2.1-1.8-4.8
	c-1-1-2.2-2.1-4.1-3.3c0,0-3.2-2.9-8.8-5.4v-0.1l-3.3-1.8c3.3-15.8-1.7-20.9-1.7-20.9c-1.7-2.8-0.8-6.5-0.8-6.5l1.1-4.5l-0.4-3.2
	c-2.9-0.6-5.9-1.1-9.1-1.5c0.3-0.1,0.4-0.3,0.7-0.4c9.4-6.6,13.2-7.4,13.2-7.4c2.3-1.5,0.3-2.8,0.3-2.8l-11.6,0.3
	c-3.2,0-6.7,1-6.7,1c-2.8,1-11.7,3.9-11.7,3.9c-2.2,0.8-5.6,2.2-9.5,3.6c-0.1,0-0.3,0.1-0.4,0.1c-12.8-0.3-22.8,0.3-22.8,0.3
	c-51.9-26.7-85.8-36-85.8-36c-0.3-1.4-2.5-1.9-2.5-1.9c-23.7-7.6-74-10.2-74-10.2c-89.8-3.7-131.8,9.9-131.8,9.9
	c-2.2,0.8-2.8,2.2-2.8,2.2c-34.1,13.5-86.9,44-106.9,55.7c-6.1-0.5-12-0.8-17.6-1.1c-42.9-1.4-73.3,5.9-73.3,5.9
	c-7.3,2.5-17.7,4.4-17.7,4.4c-9.9,1.9-18.7,4.1-26.4,6.3c-21.7,6.1-33.7,11.8-33.7,11.8s-7.3,3.9-9.9,5.4l-0.1-0.1c-2.8,1.9-5,3-5,3
	c-11.4,6.6-20.2,16.8-20.2,16.8c-1.4,1.8-1,2.3-1,2.3s-4,4-4.5,7.7l-0.1-5l-1.9,0.1c-1.2,0.3,0.1,1,0.1,1l0.7,23.4
	c0,0.3,0.3,0.4,0.3,0.4h1.7l-0.6-16l0.6,0.6C77.3,332.2,77.6,344.8,80.9,357.6L80.9,357.6z"
          />
          <path
            fill="#5E5E5E"
            d="M91.2,330l-14,2.2c0,0,0.3,12.7,3.7,25.5l0.3-0.1l4.4-3.3c0,0,1.4-1.2,1.9-3c0,0,2.6-12,5-21.2h-1.3V330z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M292.3,295.9c0,0-9.9,0.4-21.6,1.8c0,0-3,0.4-1.6,1.8l4.4,3.2c0,0,2.2,1.6,7.8,0c0,0,7-2.1,10.9-3.2
		C292.3,299.6,296.7,295.7,292.3,295.9L292.3,295.9z M398.7,214.4c0.4-0.3,1-0.6,1.4-0.8l-0.4-1c-1.9-1.6-6.7-3.2-6.7-3.2
		c-6.9,2.8-14.6,6.2-22.7,10c-31.5,15.1-68.4,36.3-84.2,45.8c2.5,0.3,5,0.4,7.4,0.7l1.8,0.3l0.3-0.1l13.3-0.1c0.4-0.3,0.8-0.5,1-0.7
		c20.2-13.5,42.8-26.4,60.3-35.9C384.2,221.8,394.9,216.3,398.7,214.4L398.7,214.4z M124.6,300c-0.6,0.3-1.2,0.5-1.9,0.8
		c-1.2,0.5-2.5,1.2-3.7,1.9l-3.7-1.5c-10.9,5.8-19.1,12.1-24.3,18.3c16.1-2.9,30.3-2.9,43.9-8c0.3-0.8,0.8-2.2,1.2-3
		c1.2-2.5,3.6-6.9,7.7-10.6H129C127.7,298.6,126.1,299.3,124.6,300L124.6,300z M110.5,327.1c-1.1,1.8-2.1,4.1-2.3,6.9v0.5l3,0.4
		c0,0,0.6-0.4,1.2-1.4c1.1-1.4,2.6-3.9,4-8.1c0.3-1,0.6-2.1,0.8-3.2l-2.6,0.3C114.6,322.7,112.4,324.2,110.5,327.1L110.5,327.1z
		 M604.9,218l5.4,27c0,0,2.2,5.6,7,4.8l44.4-1.6c9.1-0.8,17.5-1.8,24.3-2.8c0.4,0,1.8-0.3,2.9-0.4c-51.5-26.3-85-35.6-85-35.6
		S604,213.7,604.9,218L604.9,218z M745.7,285.1c-0.5-5.9-1.4-10.9-2.6-15.1c-2.2-8.1-6.5-9.2-10.5-9.4c-0.1,0-1.1,0.1-2.6,0.5
		c-6.2,1.2-20.8,4.5-20.8,7.6c0,8.1,27.1,16.8,27.1,16.8c4,0.7,7.2,0.8,9.5,1C745.9,285.8,745.7,285.3,745.7,285.1z M370.9,238.9
		l-0.5,3.9l-0.1,1h0.1c2.3-0.7,4.4-1.1,5.4-1.1c3-0.3,5.8,0.1,5.9,2.9c0.3,4.7-0.5,10.2-3.2,12.5c-1.1,1-2.1,1.4-4.1,1.9l99.6-4.7
		l0.5-2.1l11.4-43.3l0.3-1c-30.4,0.3-53.6,4.1-53.6,4.1C413.2,216.8,390.1,227.9,370.9,238.9L370.9,238.9z M563.5,221
		c-25.3-8.7-63.1-9.4-63.1-9.4s-3.9,16-9.6,43.1l40-1.9c0,0,12.7,1,19.8-7c0.3-0.3,0.4-0.6,0.5-0.7c7-8.9,8.1-10.5,13.8-20.8
		C564.8,224.2,565.8,221.7,563.5,221L563.5,221z"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M370.9,238.9c-16.6,9.5-30.5,19-36.7,23.2c-1.4,1-3,2.2-4,2.9c8.8-0.5,21.9-1.2,37.8-2.2l0.1-1.5
	c-0.3,0-0.4,0-0.7,0.1c-0.1,0-0.3-0.1-0.4-0.1c-1.8-0.5-4.1-2.9-4.1-2.9s-3,0.1-6.7-0.1c-1.1-0.1-1.7-0.8-1.9-1.8
	c-0.1-0.4-0.3-1-0.3-1.4c0-0.8,1.1-1.2,1.6-1.5c0.1,0,0.3,0,0.4-0.1s0.1-0.4,0.3-0.7c0.7-1.4,2.9-5.4,5.9-6.6
	c2.1-0.8,5.2-1.8,8.1-2.6l0.1-1.1L370.9,238.9L370.9,238.9z M462.8,287.2c3.3-0.3,5.2-0.8,5.2-0.8c2.3-0.1,3.2-1.1,3.6-1.9
	c0.4-1,0-1.9,0-1.9c0.5-3.2-5.4-3.4-8.9-3.3v8h0.1V287.2z M460.4,287.3c0.8,0,1.6-0.1,2.5-0.1v-8c-0.8,0-1.6,0.1-2.1,0.1h-0.4v7.8
	h0.1h-0.1V287.3L460.4,287.3z M460.2,287.3c0.1,0,0.3,0.1,0.3,0.1v-0.3h-0.1h0.1v-7.8h-0.3V287.3L460.2,287.3z M441,281.8
	c-4.4,0.4-7.3,1.2-7.3,1.2s-0.8,0.4-0.8,1.4v1.2l1.1,0.8l0.4,0.3c0,0,5.9,0.1,6.5,0.1c0,0,0.7-1.1,4.5-1.4c3.2,0,7.3,0,10.9-0.1
	c0.7,0.4,2.2,1.4,2.6,1.6c0.3,0,0.5,0.1,0.7,0.3c0.1,0,0.4,0.1,0.5,0.1v-0.1H460h0.1v-7.8c-1.4,0-2.1,0.5-2.5,0.8
	c-0.1,0.1-0.3,0.4-0.4,0.5L441,281.8L441,281.8z M748.3,346.8c-1,0-1.7,3-1.7,6.9c0,2.1,0.3,3.9,0.5,5.1l1.1,0.1L748.3,346.8
	L748.3,346.8z M625,301.9c-28.6,0-51.7,23.1-51.7,51.7c0,10.2,3,19.8,8.1,27.8c9.2,14.3,25.3,23.9,43.6,23.9
	c22.1,0,41-13.9,48.4-33.6c2.1-5.6,3.3-11.8,3.3-18.2C676.7,325,653.6,301.9,625,301.9L625,301.9z"
          />
          <path
            fill="#EAEBEB"
            d="M173.7,376.9c-4.7-6.6-7.4-14.6-7.4-23.2c0-22.1,17.9-40,40-40s40,17.9,40,40c0,9.6-3.4,18.6-9.1,25.5
	c-7.3,8.9-18.4,14.6-31,14.6C192.9,393.7,181,387.1,173.7,376.9z M595,380.2c-6.3-7-10-16.4-10-26.6c0-22.1,17.9-40,40-40
	s40,17.9,40,40c0,7.2-1.9,13.9-5.1,19.7c-6.9,12.1-19.9,20.4-34.9,20.4C613.1,393.7,602.4,388.4,595,380.2L595,380.2z"
          />
          <path
            fill="#5E5E5E"
            d="M147.3,373.7c0,0.1-0.1,0.3-0.4,0.4v1l12.8,1.2h0.1c-3.4-6.9-5.4-14.6-5.4-22.8c0-28.6,23.1-51.7,51.7-51.7
	s51.7,23.1,51.7,51.7c0,9.5-2.5,18.3-7,25.9h2.2v1.1h3.7v2.2h30.7l5.2-1v-2.6l-26.8-0.1c0.5-4.1,1.2-8.5,1.2-12.7l0.3-1.2
	c0-3.9,0.1-6.6,0.1-11.7c0-31.5-27-57.2-60-57.2c-16.8,0-32.3,6.7-43.3,17.7c-10.3,10.3-16.5,24.1-16.5,39.3
	c0,12.2-0.1,19.5-0.1,19.5v0.3C147.5,373.6,147.5,373.6,147.3,373.7c0,0.1-0.1,0.3-0.4,0.4C147.2,374,147.3,373.9,147.3,373.7
	L147.3,373.7z M146.9,374.1c-0.1,0-0.3,0.1-0.4,0.1C146.8,374.3,146.9,374.1,146.9,374.1c-0.1,0-0.4,0.1-0.7,0.1h0.3h-0.3v0.8h0.7
	V374.1L146.9,374.1z M146.4,374.3h-0.1H146.4v0.8h-0.3v-0.7h0.1C146.1,374.3,146.2,374.3,146.4,374.3L146.4,374.3z M665.1,312.1
	L665,312L665.1,312.1c11.6,10.5,18.8,25.2,18.8,41.5c0,6.5-0.1,9.2-0.1,15l-4,0.3l-6.3,2.9c2.1-5.6,3.3-11.8,3.3-18.2
	c0-12.4-4.4-23.8-11.7-32.7V312V312.1L665.1,312.1z M665,311.9l-0.1-0.1L665,311.9v8.9c-9.5-11.6-23.9-19-40-19
	c-28.6,0-51.7,23.1-51.7,51.7c0,10.2,3,19.8,8.1,27.8h-64.9c-10.5-0.4-20.9,0.4-20.9,0.4H452l-19-2.2l131,0.5l0.5-12.9l-0.7-10.7
	v-2.6c0-31.5,27-57.2,60-57.2c15.5,0,30,5.8,40.9,15.4L665,311.9L665,311.9z M747.1,360.5v-13.8h1.2c1,0,1.8,3,1.8,6.9
	s-0.8,6.9-1.8,6.9H747.1L747.1,360.5z M726.5,358.4c5.8-3.3,9.1-5.6,14.7-11.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1c0.7-0.4,4.8-2.2,5.4-0.1
	v13.9h-20.6L726.5,358.4L726.5,358.4L726.5,358.4z M726.5,358.4v2.1h-1.9c-0.5,1.8-1.5,3.4-2.8,5.2c-1.4,2.2-1.7,4-5.5,5
	c-2.2,0.5-4.3,1.5-6.6,1.8c-10.5,1.1-20.8,1-24.3-1h1.8c5.5-1.4,11-3,16.5-5c6.5-1.8,12.8-3.7,19-6.2l1.9-0.1l0.1-0.4
	c0-0.1,0.1-0.4,0.1-0.5C725.5,359,726.1,358.7,726.5,358.4l-0.3-0.5L726.5,358.4L726.5,358.4z"
          />
          <path
            fill="#5E5E5E"
            d="M727.7,360.5l1.5,2.8l3.7,1.7l6.2,1.4c0,0,1.9,2.8,5.1,1.6c0,0,5.1-1.6,8.4-5.6c0,0,1.7-1.4,0.3-2.5
	l-3.4-0.5c-0.3,0.8-0.7,1.4-1.1,1.4L727.7,360.5L727.7,360.5z M725,359.1c-0.8,0.4-1.9,0.8-2.9,1.4h2.5c0.1-0.3,0.1-0.5,0.3-0.7
	C725,359.7,725,359.4,725,359.1L725,359.1z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M80.9,357.6l4.7-3.6c0,0,1.4-1.2,1.9-3c0,0,4.5-21.2,7.3-29
	c0,0,1-2.1-1.7-2.1h-12c0,0-4.3,4.4-4.7,8.1l-0.1,3.3l1,0.8C77.3,332.2,77.6,344.8,80.9,357.6L80.9,357.6z M673.4,371.8l6.5-2.9
	l4-0.3c0-5.8,0.1-8.7,0.1-15c0-31.5-27-57.2-60-57.2s-60,25.6-60,57.2l-5.8,18.7l-290.7-3.6c0-5.8,0.1-8.7,0.1-15
	c0-31.5-27-57.2-60-57.2c-33.2,0-60,25.6-60,57.2c0,12.2-0.1,19.5-0.1,19.5s0.6,1.4-1.6,1.2c0,0-3.6-0.3-9.1-0.1l23.1,2.3
	 M251.6,379.5h1.6v1.1h3.7v2.2h30.7l5.2-1v-2.6l137.7,0.5l21.3,2.1h43.6c0,0,10.5-0.8,20.9-0.4h64.9 M685.3,371.4
	c0.7,0.3,1.5,0.3,2.8-0.1c0,0,11.6-3.2,14.6-4.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M725.1,359.3c-1.2,4-3.3,6.5-3.3,6.5c-2.8,7.7-19,7.3-19,7.3
	c-15.3,0.8-17.6-1.5-17.6-1.5 M152.1,299.7l15.1,11.7 M107.6,298.1l11.4,4.7 M81.2,319.9c0,0-0.4-0.5,1-2.3c0,0,8.8-10.2,20.2-16.8
	c0,0,2.2-1.1,5-3l0.4,0.1 M81.2,357.3l4.4-3.3c0,0,1.4-1.2,1.9-3c0,0,4.5-21.2,7.3-29c0,0,1-2.1-1.7-2.1h-12 M83.6,365.2
	c0,0-1.1,0.7-2.1-0.7l-2.3-3.6c0,0-0.7-1.8,0.6-2.5l1.2-1 M145.8,374.3c0,0-12.7-0.8-26.7,0.8c0,0-3.4,0.4-8.9-2.1
	c0,0-8.8-5.6-13.5-8.8c0,0-5.1-2.9-13.1,0.8 M267.4,368.6c0-5.8,0.1-8.7,0.1-15c0-31.5-27-57.2-60-57.2s-60,25.6-60,57.2
	c0,12.2-0.1,19.5-0.1,19.5s0.6,1.4-1.6,1.2"
          />
          <g>
            <circle
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              cx="206.3"
              cy="353.6"
              r="51.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M206.3,313.6c22.1,0,40,17.9,40,40s-17.9,40-40,40s-40-17.9-40-40
		S184.2,313.6,206.3,313.6z"
            />
            <circle
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              cx="206.3"
              cy="353.6"
              r="4.8"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M206.3,333.1c1.9,0,6.3-5.1,7.7-8.5c2.1-5.1,2.2-7,2.3-7.4
		c0-0.4-0.3-0.8-0.7-1c-3-0.7-6.1-1.1-9.4-1.1c-3.2,0-6.3,0.4-9.4,1.1c-0.4,0.1-0.7,0.4-0.7,1c0,0.4,0.1,2.5,2.3,7.4
		C200,328,204.4,333.1,206.3,333.1L206.3,333.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M206.3,331.2c1.9,0,6.2-5,7.6-9.9c-2.5-0.5-5-0.8-7.6-0.8
		s-5.1,0.3-7.6,0.8C200.2,326.3,204.4,331.2,206.3,331.2L206.3,331.2z M189.8,348.3c0.6-1.6-3.4-8.8-8.9-13.3
		c-3.6-2.9-5.2-3.7-5.5-4c-0.4-0.3-0.4-0.8-0.1-1.1c2.3-3,5.1-5.6,8.1-7.8c3-2.2,6.3-4,9.9-5.2c0.4-0.1,0.8,0,1,0.5
		c0.1,0.4,0.4,2.3,2.2,6.5c2.6,6.6,8.1,12.7,9.9,12.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M198.9,343.5c4.3-3,0.3-11.3-1.9-14.9c-2.1-3.6-3-5.8-3-5.8
		c-2.5,1-5,2.3-7.2,4c-2.3,1.6-4.3,3.6-6.1,5.6c0,0,1.8,1.6,4.5,4.7C188.2,340.1,194.7,346.5,198.9,343.5L198.9,343.5z M194,322.7
		c0,0-0.3-0.5-0.8-1.9c-0.6-1.8,0.6-3.9,0.6-3.9 M180.8,332.3c0,0-0.4-0.4-1.5-1.2c-1.5-1.1-3.9-0.7-3.9-0.7 M196.7,316.8
		c0,0,1.4,2.2,2.1,4.4 M216,316.8c0,0-1.4,2.2-2.1,4.4 M209.9,344.4c0,2.1-1.6,3.7-3.7,3.7s-3.7-1.6-3.7-3.7s1.6-3.7,3.7-3.7
		C208.4,340.7,209.9,342.3,209.9,344.4z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M205,346.9l-1.5-2.5l1.5-2.5h2.8l1.4,2.5l-1.4,2.5H205z M186.9,347.3
		c0.6-1.8-2.9-7.6-5.8-9.9c-4.1-3.6-6.1-4.3-6.5-4.5c-0.4-0.1-0.8,0-1.1,0.4c-1.7,2.6-3,5.5-4,8.5s-1.5,6.2-1.8,9.2
		c0,0.4,0.3,0.8,0.7,1c0.4,0.1,2.3,0.5,7.8,0.1C180.1,351.7,186.3,349.1,186.9,347.3L186.9,347.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M185,346.6c0.6-1.8-2.8-7.4-7.2-10.3c-1.2,2.2-2.3,4.4-3.2,6.9
		c-0.8,2.5-1.2,5-1.5,7.4C178.4,351,184.5,348.4,185,346.6L185,346.6z M196.2,367.5c-1.5-1.1-9.5,0.5-15.4,4.4
		c-3.9,2.5-5.2,3.7-5.5,4c-0.4,0.3-0.8,0.1-1.1-0.3c-2.2-3.2-3.9-6.6-5-10s-1.8-7.3-1.9-11.1c0-0.4,0.3-0.8,0.8-0.8
		c0.4,0,2.3,0.3,6.9,0c7.2-0.4,14.6-3.9,15.1-5.5"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M194.4,357.5c-1.6-5-10.6-3.7-14.7-2.8c-4.1,0.8-6.5,1.1-6.5,1.1
		c0.1,2.6,0.7,5.4,1.5,8.1c0.8,2.6,2.1,5.2,3.4,7.4c0,0,2.1-1.2,5.9-2.9C187.9,366.7,196,362.4,194.4,357.5z M173.2,355.8
		c0,0-0.6,0.1-2.1,0.1c-1.9,0-3.4-1.6-3.4-1.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M178.1,371.2c0,0-0.6,0.3-1.6,1.1c-1.5,1.1-1.8,3.4-1.8,3.4 M168.4,351.3
		c0,0,2.6-0.7,4.8-0.5 M174.3,333.1c0,0,1.6,2.1,3.6,3.3 M198.8,347.3c1.9,0.7,3,2.8,2.3,4.7c-0.7,1.9-2.8,3-4.7,2.3s-3-2.8-2.3-4.7
		C194.8,347.7,196.9,346.6,198.8,347.3L198.8,347.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M199.5,352.8l-2.8,0.5l-1.9-2.1l1-2.6l2.8-0.5l1.8,2.1L199.5,352.8z
		 M194.4,370.1c-1.5-1.1-8.1,0.4-11.3,2.3c-4.7,2.9-5.9,4.4-6.2,4.7c-0.3,0.3-0.3,0.8,0,1.1c1.9,2.3,4.3,4.5,6.9,6.3
		c2.6,1.9,5.4,3.4,8.3,4.5c0.4,0.1,0.8,0,1.1-0.3c0.3-0.4,1.2-2.1,2.6-7.4C196.4,378,195.9,371.2,194.4,370.1L194.4,370.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M193.1,371.6c-1.5-1.1-7.8,0.3-12,3.6c1.7,1.9,3.6,3.6,5.6,5.1
		s4.3,2.8,6.6,3.7C195.2,379.4,194.7,372.9,193.1,371.6L193.1,371.6z M216.5,367.5c-1.5,1.1-2.3,9.2-0.6,16.1
		c1.1,4.4,1.9,6.1,2.1,6.6c0.1,0.4-0.1,0.8-0.6,1c-3.7,1.1-7.4,1.6-11.1,1.6c-3.7,0-7.4-0.5-11.1-1.6c-0.4-0.1-0.7-0.5-0.6-1
		c0.1-0.4,1-2.1,2.1-6.6c1.8-6.9,0.8-15-0.6-16.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M206.3,366.1c-5.2,0-6.7,8.9-7.2,13.1s-1,6.5-1,6.5c2.6,0.7,5.4,1,8.1,1
		c2.8,0,5.5-0.4,8.1-1c0,0-0.6-2.3-1-6.5C213.1,375.1,211.6,366.1,206.3,366.1L206.3,366.1z M198.2,385.8c0,0,0,0.5-0.6,1.9
		c-0.6,1.8-2.8,2.8-2.8,2.8 M214.5,385.8c0,0,0,0.5,0.6,1.9c0.6,1.8,2.8,2.8,2.8,2.8 M192.5,389c0,0,0.1-2.6,1-4.8 M176.9,377.7
		c0,0,2.5-1,4.3-2.3 M198,358.9c1.2-1.7,3.4-2.1,5.1-0.8s2.1,3.4,0.8,5.1c-1.2,1.6-3.4,2.1-5.1,0.8
		C197.1,362.7,196.7,360.5,198,358.9L198,358.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M203.5,359.8l-0.3,2.9l-2.6,1.1l-2.2-1.6l0.3-2.8l2.6-1.2L203.5,359.8z
		 M218.3,370.1c-1.5,1.1-2.1,7.8-1.2,11.4c1.2,5.4,2.3,7,2.6,7.4s0.7,0.5,1.1,0.3c2.9-1.1,5.6-2.6,8.3-4.5s4.8-4,6.9-6.3
		c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-1.5-1.8-6.2-4.7C226.4,370.5,219.8,369,218.3,370.1L218.3,370.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M219.6,371.6c-1.5,1.1-2.1,7.6-0.3,12.5c2.3-1,4.5-2.2,6.6-3.7
		s4-3.3,5.6-5.1C227.4,372.1,221.1,370.5,219.6,371.6z M222.7,348.3c0.6,1.6,8,5.1,15.1,5.5c4.5,0.3,6.5,0,6.9,0s0.8,0.4,0.8,0.8
		c-0.1,3.9-0.8,7.6-1.9,11.1c-1.1,3.4-2.8,6.9-5,10c-0.3,0.4-0.7,0.4-1.1,0.3c-0.4-0.3-1.6-1.5-5.5-4c-6.1-3.9-14-5.5-15.4-4.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M218.3,357.5c-1.6,5,6.5,9.2,10.3,10.9c3.9,1.7,5.9,2.9,5.9,2.9
		c1.4-2.3,2.6-4.8,3.4-7.4c0.8-2.6,1.4-5.4,1.5-8.1c0,0-2.5-0.3-6.5-1.1C228.9,353.8,219.8,352.5,218.3,357.5L218.3,357.5z
		 M234.4,371.2c0,0,0.6,0.3,1.6,1.1c1.5,1.1,1.8,3.4,1.8,3.4 M239.5,355.8c0,0,0.6,0.1,2.1,0.1c1.9,0,3.4-1.6,3.4-1.6 M235.6,377.7
		c0,0-2.5-1-4.3-2.3 M220.2,389c0,0-0.1-2.6-1-4.8 M208.7,363.1c-1.2-1.6-0.8-4,0.8-5.1c1.6-1.2,4-0.8,5.1,0.8
		c1.2,1.6,0.8,4-0.8,5.1C212.3,365.2,209.9,364.8,208.7,363.1L208.7,363.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M211.4,358.2l2.5,1.2l0.4,2.8l-2.3,1.6l-2.6-1.1l-0.3-2.9L211.4,358.2z
		 M225.7,347.3c0.6,1.8,6.7,4.4,10.5,4.7c5.5,0.4,7.4,0,7.8-0.1c0.4-0.1,0.7-0.4,0.7-1c-0.3-3-0.8-6.2-1.8-9.2s-2.3-5.9-4-8.5
		c-0.3-0.4-0.7-0.5-1.1-0.4c-0.4,0.1-2.2,1-6.5,4.5C228.6,339.7,225.2,345.5,225.7,347.3L225.7,347.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M227.7,346.6c0.6,1.8,6.6,4.4,11.8,4.1c-0.3-2.5-0.7-5-1.5-7.4
		c-0.8-2.5-1.9-4.8-3.2-6.9C230.4,339.3,227,344.8,227.7,346.6L227.7,346.6z M206.3,336.3c1.8,0,7.3-6.1,9.9-12.7
		c1.6-4.3,1.9-6.1,2.2-6.5c0.1-0.4,0.6-0.7,1-0.5c3.7,1.2,7,3,9.9,5.2c3,2.2,5.6,4.8,8.1,7.8c0.3,0.4,0.1,0.8-0.1,1.1
		c-0.4,0.3-2.1,1.1-5.5,4c-5.5,4.5-9.5,11.7-8.9,13.3"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M213.6,343.5c4.3,3,10.7-3.3,13.6-6.3c2.8-3.2,4.5-4.7,4.5-4.7
		c-1.8-2.1-3.7-4-6.1-5.6c-2.3-1.6-4.7-3-7.2-4c0,0-1,2.2-3,5.8C213.5,332.2,209.5,340.3,213.6,343.5L213.6,343.5z M231.8,332.3
		c0,0,0.4-0.4,1.5-1.2c1.5-1.1,3.9-0.7,3.9-0.7 M218.7,322.7c0,0,0.3-0.5,0.7-1.9c0.6-1.8-0.6-3.9-0.6-3.9 M238.4,333.1
		c0,0-1.6,2.1-3.6,3.3 M244.3,351.3c0,0-2.6-0.7-4.8-0.5 M216.1,354.2c-1.9,0.7-4-0.4-4.7-2.3s0.4-4,2.3-4.7s4,0.4,4.7,2.3
		C219.1,351.6,218,353.6,216.1,354.2L216.1,354.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M212.3,350.2l1.9-2.1l2.8,0.5l0.8,2.6l-1.9,2.1l-2.6-0.5L212.3,350.2z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M644.5,263.9c0.1,5.9-6.3,9.5-14.3,9.5c-8,0.1-14.4-3.2-14.6-9.1
	c-0.1-5.9,6.3-10.7,14.2-10.9C637.9,253.3,644.3,258,644.5,263.9L644.5,263.9z M170,276c0,0,1.2,1.6,27-3.2c0,0,31.1-5.6,66.2-6.1
	c0,0,2.5,0,5.2-2.9 M90.7,319.9c21.5-26.4,96.7-51.9,197.7-51.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M117.3,302c46.2-23.5,124.1-32.6,164.1-31.6c0,0,2.1,0,8.5-2.5l5.6-2.1
	l22.3-0.1c0,0,118.6-7.3,219.3-10.5c0,0,107-3.7,150.6-10.2 M434,286.4c-80.6,4-175.3,8.4-175.3,8.4 M596.3,280.3
	c0,0-4.1-1.8-36.3-0.1c-10.6,0.5-45.8,2.3-88.5,4.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M316.5,265.9c0,0-12.1,9.1-14.3,30.4c0,0-1.9,12.7-0.8,32.3l1.4,24.8
	l0.8,4.5c0,0,0.5,5.4,5.9,5.1 M294.4,266c-56.1-6.5-99.2,4-99.2,4c-7.3,2.5-17.7,4.4-17.7,4.4c-38.4,7.7-60.1,18.2-60.1,18.2
	s-7.7,4-10,5.5 M392.9,209.5c0,0,4.8,1.6,6.7,3.2l0.5,1.1c2.5-1.4,1.1-2.8,1.1-2.8c-1.9-1.9-6.5-3.2-6.5-3.2 M400.2,213.7
	c0,0-50.6,25-91.2,52.1 M703.4,264.8c0,0,9.4-4.3,22.8-8.7 M702.8,266.1c0,0,13.6-5.9,28.8-5.6c4.3,0.1,9.2,0.5,11.6,9.5
	c1.2,4.5,2.1,9.8,2.8,16.2 M370.9,239.3l-0.8,4.8 M330.6,264.8c0,0,60-43.9,101.9-51.6c0,0,75.8-12.4,132.1,5.6c0,0,4.8,1.4,3.2,5.2
	c0,0-7.3,15.7-15.8,23.8c0,0-5.6,6.9-14.9,6.6l-74,3.7 M486,209.5l-12.1,45.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M499.2,204.2l-11.3,50.3c0,0-3.4,12.4-8.9,38l-13.5,70.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M490.6,254.6c5.9-27.6,9.8-43.1,9.8-43.1s37.8,0.8,63.1,9.4
	c2.3,0.8,1.4,3.2,1.4,3.2c-5.6,10.2-6.7,11.8-13.8,20.8c-0.1,0.3-0.4,0.6-0.5,0.7c-7.2,8.1-19.8,7-19.8,7l-156.5,7.6 M313.6,265.3
	c0,0,40.6-26.1,87.4-52.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M401.3,211c0,0,38.8-15.5,125-11.6c0,0,60,5.6,75.1,12.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M318.7,265.6c0,0,65.9-44.2,99.2-53.5c0,0,29.6-9.1,78.3-8.1
	c0,0,52.4,1.4,76.9,9.9c0,0,5.9,1.9,4.8,7.6l-9.9,50.9c0,0-2.8,12.9-4.5,19.9c-5.4,19.8-16.9,46.8-16.9,46.8
	c-7.8,16.6-13.2,18.6-13.2,18.6c-5.9,5.6-18.3,5.1-18.3,5.1l-206.3,0.5 M603.8,209.5c0,0,0.3,4.3,1.1,8.7l5.4,27c0,0,2.2,5.6,7,4.8
	l45-1.6c0,0,7.6,2.8,21.5,4c0,0,27.7,1.1,41.1,2.8c0,0,1.4,0.3,1.9,2.2l0.8,3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M601.4,207.5c0,0,3.7,23.9,6.7,38.2c0,0,2.5,6.2,7.8,5.6 M649.7,280
	c0,0,4.8-0.8,18.7-1.2c14-0.4,44.6-2.5,44.6-2.5 M458,286.8c-1.4,2.6-4.5,4.5-8.3,4.5c-3.6,0-6.7-1.8-8.1-4.3 M441.3,281.4
	c1.2-2.8,4.5-4.5,8.4-4.5c3.3,0,6.2,1.5,7.8,3.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M460.4,287.3c4.8-0.1,7.7-1,7.7-1c4.8-0.4,3.6-3.9,3.6-3.9
	c-0.1-1.8-2.1-2.1-2.1-2.1l-6.2,0.1c-0.8-0.1-5.5,0.1-5.5,0.1l-16.9,1c-4.4,0.4-7.3,1.2-7.3,1.2s-0.8,0.4-0.8,1.4v1.2l1.4,1
	c0,0,5.9,0.1,6.5,0.1c0,0,0.8-1.2,5.1-1.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M462.8,284.8c0.5-1.8-0.1-4.3-0.1-4.3s-1.8-2.2-4-1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M432.9,284.4c0,0,1.2,0.4,2.1,0.8l5.6,0.1c0,0,15.1,0.1,21.3-0.4l7.8-0.5
	c0,0,1.8-0.5,1.8-1.2 M457.4,280.6c0,0,0.8-1.2,3-1.2c0,0,12.1-1.2,11.3,3.2 M462.8,284.8c0,0-0.8,3.4-4.3,2.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M460.4,287.5c0,0-1.5-0.7-2.5-0.7c0,0-0.3-1.4-3.3-1.4 M266,299.2
	c0,0,4.3,6.5,6.6,8.9c0,0,2.6,1.6,5.9,0.8c0,0,7.8-2.5,11.6-4.3c0,0,4.7-2.2,6.2-7c0,0,1.8-2.9-3.4-2.9c0,0-16.8,1-26,2.6
	C266.9,297.2,265.5,297.5,266,299.2L266,299.2z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M269.1,299.6l4.4,3.2c0,0,2.2,1.6,7.8,0c0,0,7-2.1,10.9-3.2
	c0,0,4.4-3.9,0-3.6c0,0-9.9,0.4-21.6,1.8C270.7,297.6,267.7,298.1,269.1,299.6L269.1,299.6z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M272,300.5l2.9,1.8c0,0,2.2,0.1,4.3-0.4l7.4-2.5c0,0,3.2-2.2-1-2.1
	c0,0-6.6,0.8-12.5,1.6C272.9,299.2,270.3,299.3,272,300.5L272,300.5z M286,265.2c19.9-11.7,72.8-42.2,106.9-55.7
	c0,0,0.5-1.4,2.8-2.2c0,0,42-13.8,131.8-9.9c0,0,50.3,2.8,74,10.2c0,0,2.2,0.6,2.5,1.9c0,0,33.8,9.4,85.8,36c0,0,34.3-1.9,57.6,3
	l0.4,3.2l-1.1,4.5c0,0-0.8,3.7,0.8,6.5c0,0,5,5.1,1.7,20.9l3.4,1.9 M159.2,289c-8.9,1.2-20.5,4.4-36.5,12c-5.8,2.8-11.4,6.6-18,12.1
	 M266,379.1l298.1,0.8 M564.6,367l-0.5-13.3 M564.1,379.9l0.4-12.9 M267.6,360.8c0,0-0.4,10.5-1.5,18.3 M96.2,376.6
	c4-0.3,15.4-1.1,17.9-1l5.1-0.5c0,0-3.4,0.4-8.9-2.1c0,0-8.8-5.6-13.5-8.8c0,0-5.1-2.9-13.1,0.8c0,0-0.4,0.3-1,0.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M82.8,365.3c0,0,2.9,0.8,4.7,2.2c0,0,4.3,3.2,6.7,6.2c0,0-2.1,2.5,0.6,3
	c0,0,0.6,0,1.4-0.1 M77.3,332.2l14.7-2.3 M717.9,278.9c0,0-24.3,15-27.4,16.8c-3,1.6-8.1,3.3-13.3,3.6s-31.4,1.1-31.4,1.1
	 M752.3,285.5c0,0-5.1,2.2-21.7-1.4c0,0-5.9-1.9-16.1-7c-7.6-3.7-11.4-8.7-11.4-8.7c-2.5-2.8,1.9-5.6,1.9-5.6s26.7-11.8,40.6-14.3
	 M752.9,359.7c0,0,5.6-5.1,10.7-16.4c0,0,3.2-2.2,3.4-8.7c0,0,1.4-3.4-2.2-3.7c0,0-2.8,0.3-2.8-3.2c0,0,1.9-19.7,1.1-24.8
	c0,0-0.8-1.9,1.1-1.9c0,0,3.2,0.5,2.8-2.2c0,0,1.4-3.7-5.9-8.1c0,0-3.2-2.9-8.8-5.4 M685.5,371.5c0.5,0.1,1.5,0.1,2.6-0.1
	c0,0,11.6-3.2,14.6-4.5 M683.7,368.6c0,0-0.1,2.2,1.7,2.9 M727.7,360.5l1.5,2.8l3.7,1.7l6.2,1.4c0,0,1.9,2.8,5.1,1.6
	c0,0,5.1-1.6,8.4-5.6c0,0,1.7-1.4,0.3-2.5 M752.9,359.7l-10.6-1.8l-2.1-0.4c5.4-4.3,6.2-8.1,6.2-8.1c3-6.7-4.8-2.8-4.8-2.8
	c-5.1,5.1-15,11.6-15,11.6l1.2,2.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M767.2,334c-6.7,0-10,0.1-15.5,0.1c-8.4,0-10.3-0.8-16.2,6.1
	c-5.4,6.3-17.9,19.4-32.7,26.7 M723.4,281.4c0,0,13.2,6.5,16.4,7.7c10,4.3,15.8,6.1,25.7,5 M738.9,246.5c-1.8,1.2-3.9,2.8-6.1,4.5
	c0,0-2.5,3.6-10.9,3.2l-25.9-1.9c0,0-1.7-0.7,0-1.4c0,0,8.9-3.2,16.8-5.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M712.9,245c3.9-1.4,7.3-2.8,9.5-3.6c0,0,8.9-2.9,11.7-3.9c0,0,3.4-1,6.7-1
	l11.6-0.3c0,0,2.1,1.2-0.3,2.8c0,0-3.9,1-13.2,7.4 M77.2,347.7h-1.7c0,0-0.3-0.1-0.3-0.4l-0.7-23.4c0,0-1.4-0.7-0.1-1l1.9-0.1
	L77.2,347.7L77.2,347.7z M683.7,368.6c0-5.8,0.1-8.7,0.1-15c0-31.5-27-57.2-60-57.2s-60,25.6-60,57.2 M701.4,340.6l-12.9,13.1
	 M550.4,341.1l9.1,9.1 M286.5,350.2l-12.5,7.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M90.9,319.6c23-4,41.7-2.5,60.9-17.6c17.3-13.6,33.8-24.4,55.9-24.4
	c51.6,0,73.5,36,78.8,72.6c0,0,139.9-3.3,190.4-5.2c54.3-2.1,73.5-3.9,73.5-3.9c6.1-39.5,32.1-65.3,73.6-65.3
	c39.5,0,72.1,28.2,77.4,64.8c8.5,0,18-1.6,24.9-3.2c4.4-1,4.7-0.3,0.7,2.1c-18,10.5-38.5,14-38.5,14c0-34.1-29-61.8-64.5-61.8
	c-34.4,0-62.6,25.9-64.5,58.3L274,357.3c0-34.1-24.8-65.5-66.4-65.5c-34.4,0-61.6,24.8-64.5,58.3c-0.6,7.2,0,9.6,0,9.6l-63.8,1.1
	 M350.5,253.6c0,0,0.7,1,3.3,0.6c3.2-0.4,17.1-3.2,19.5-3.6c2.3-0.4,6.3-0.1,6.3-0.1c0.8,0,0.8-1.9,0.8-3.7c0-1.9-0.7-4.3-4.8-3.7
	s-6.3,1.4-6.3,1.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M359.5,262.3c0,0,6.7-0.1,10.9-1.2c5.1-1.2,6.3-1.4,8-2.9
	c2.8-2.5,3.4-8,3.2-12.5c-0.1-2.8-2.9-3.2-5.9-2.9c-1.9,0.1-9.8,2.2-13.6,3.7c-3.7,1.5-6.2,7.2-6.2,7.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M379,251.7c0,0-5-0.1-8.8,0.8c-7.3,1.9-26.7,6.9-26.7,6.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M355.7,253.8c0,0-1.8,0.6-1.8,1.5c0,0.9,0.5,2.9,2.2,3
	c3.7,0.3,6.7,0.1,6.7,0.1s2.8,2.8,4.5,3 M125.7,346.6c0,0-3.3,13.2-26.6,13.9"
          />
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M119,301.2c-5.8,2.8-11.4,6.6-18,12.1c0,0,23.1-2.1,36.5-6.5c11.7-3.9,21.5-12.1,22.7-13.3
				c1.2-1.2,5.9-5.6-3.2-4.4C147.7,290.4,135.9,293.4,119,301.2L119,301.2z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_2_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M165.8,290.6c0,0-11.6-0.4-19.7,5.5
			c-8.1,5.8-11,14.7-11,14.7"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_3_"
                  d="M119,301.2c-5.8,2.8-11.4,6.6-18,12.1c0,0,23.1-2.1,36.5-6.5c11.7-3.9,21.5-12.1,22.7-13.3
				c1.2-1.2,5.9-5.6-3.2-4.4C147.7,290.4,135.9,293.4,119,301.2L119,301.2z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_4_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M124.6,300c-3.3,3.9-6.6,8.9-8.8,15.1"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_5_"
                  d="M119,301.2c-5.8,2.8-11.4,6.6-18,12.1c0,0,23.1-2.1,36.5-6.5c11.7-3.9,21.5-12.1,22.7-13.3
				c1.2-1.2,5.9-5.6-3.2-4.4C147.7,290.4,135.9,293.4,119,301.2L119,301.2z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_6_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M127.4,306.7l2.3,2.3v-11l-2.3,2.2
			C127.4,300.2,127.4,306.7,127.4,306.7z"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_7_"
                  d="M119,301.2c-5.8,2.8-11.4,6.6-18,12.1c0,0,23.1-2.1,36.5-6.5c11.7-3.9,21.5-12.1,22.7-13.3
				c1.2-1.2,5.9-5.6-3.2-4.4C147.7,290.4,135.9,293.4,119,301.2L119,301.2z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_8_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M129.7,298.1h13.9"
              />
            </g>
          </g>
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M119,301.2c-5.8,2.8-11.4,6.6-18,12.1c0,0,23.1-2.1,36.5-6.5
		c11.7-3.9,21.5-12.1,22.7-13.3c1.2-1.2,5.9-5.6-3.2-4.4C147.7,290.4,135.9,293.4,119,301.2L119,301.2z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M747.1,346.8c-0.3-2.6-4.4-1.1-5.5,0c-5.9,5.9-9.2,8.3-15,11.6
	c-7,4-23.9,8.4-23.9,8.4s21.5-9.6,33.8-23.9c3.3-3.9,9.2-10.5,12.4-2.8c0.8,2.1,1.4,4.4,0.3,6.6"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M724.5,353.6c-4.1,3-10.3,6.9-10.3,6.9h34.1v-13.8h-15.5
		C732.7,346.8,728.7,350.6,724.5,353.6z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M750,353.6c0,3.9-0.8,6.9-1.7,6.9c-1,0-1.7-3-1.7-6.9s0.8-6.9,1.7-6.9
		C749.2,346.8,750,349.9,750,353.6z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M736.5,285.3c-1.1-13.5-3.7-24.8-3.7-24.8s-8,0.7-8,5.8
	C724.7,276.6,736.5,285.3,736.5,285.3L736.5,285.3z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M732.7,260.5c0,0-23.4,4.3-23.4,8c0,8.1,27.1,16.8,27.1,16.8 M101,313.3
	l-10,6.3"
          />
          <g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_9_"
                    d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5c1-2.6,2.8-3.9,5.8-4.8
					c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
					C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_10_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M118,355.6c0,0-8.7,1.2-8.7-5
				s2.6-13.8,2.6-13.8s-8.9,0.1-11-0.1s-2.5-0.5-2.3-2.5c0.1-1.1,1.1-3.4,5.4-5.1c6.1-2.3,17.1-5,25.6-6.2c1.9-0.3,3.6,1,3.4,2.5
				l-7.3,26.8L118,355.6L118,355.6z"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <defs>
                    <path
                      id="SVGID_11_"
                      d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5c1-2.6,2.8-3.9,5.8-4.8
						c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
						C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
                    />
                  </defs>
                  <path
                    clipPath="url(#SVGID_12_)"
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.5"
                    d="M111.3,343.6l19.4-1.4"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5
			c1-2.6,2.8-3.9,5.8-4.8c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
			C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_13_"
                    d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5c1-2.6,2.8-3.9,5.8-4.8
					c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
					C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_14_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M114.6,322.7c0,0-6.5,4.1-6.5,12.2h10.5
				c0,0,4-3.2,6.2-13.5L114.6,322.7L114.6,322.7z"
                />
              </g>
            </g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_15_"
                    d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5c1-2.6,2.8-3.9,5.8-4.8
					c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
					C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_16_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M111.3,335.1c0,0,4-3.2,6.2-13.5"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <defs>
                    <path
                      id="SVGID_17_"
                      d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5c1-2.6,2.8-3.9,5.8-4.8
						c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
						C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
                    />
                  </defs>
                  <path
                    clipPath="url(#SVGID_18_)"
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.5"
                    d="M112,336.7c-6.2,0.4-8.9,0.7-11,0.5
					c-2.1,0-2.6-0.4-2.5-1.1c0.1-0.8,1.1-1.2,5.2-1.8c6.5-0.8,29.2-2.2,29.2,0C133,334.5,131.9,335.5,112,336.7L112,336.7z"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M94.2,354.5c-2.3-1.4-3.4-3.3-3.2-7c0.3-3.3,1.5-10.5,3.4-15.5
			c1-2.6,2.8-3.9,5.8-4.8c3-1.1,26.1-5.6,29.2-5.6c3,0,3.3,1.9,2.8,3.6c-0.6,1.8-4.9,18.2-6.3,21.7c-1.5,3.6-4.8,8.3-9.1,8.9
			C112.3,356.4,100.1,357.8,94.2,354.5L94.2,354.5z"
              />
            </g>
          </g>
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M676.7,353.6c0,28.6-23.1,51.7-51.7,51.7s-51.7-23.1-51.7-51.7
		s23.1-51.7,51.7-51.7S676.7,325,676.7,353.6z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M625,313.6c22.1,0,40,17.9,40,40s-17.9,40-40,40s-40-17.9-40-40
		S602.9,313.6,625,313.6z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M625,348.8c2.6,0,4.8,2.2,4.8,4.8s-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8
		C620.1,350.9,622.3,348.8,625,348.8z M625,333.1c1.9,0,6.3-5.1,7.7-8.5c2.1-5.1,2.2-7,2.3-7.4c0-0.4-0.3-0.8-0.7-1
		c-3-0.7-6.1-1.1-9.4-1.1c-3.2,0-6.3,0.4-9.4,1.1c-0.4,0.1-0.7,0.4-0.7,1c0,0.4,0.1,2.5,2.3,7.4C618.8,328,623,333.1,625,333.1
		L625,333.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M625,331.2c1.9,0,6.2-5,7.6-9.9c-2.5-0.5-5-0.8-7.6-0.8s-5.1,0.3-7.6,0.8
		C618.9,326.3,623,331.2,625,331.2L625,331.2z M608.6,348.3c0.5-1.6-3.4-8.8-8.9-13.3c-3.6-2.9-5.2-3.7-5.5-4
		c-0.4-0.3-0.4-0.8-0.1-1.1c2.3-3,5.1-5.6,8.1-7.8c3-2.2,6.3-4,9.9-5.2c0.4-0.1,0.8,0,1,0.5c0.1,0.4,0.4,2.3,2.2,6.5
		c2.6,6.6,8.1,12.7,9.9,12.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M617.7,343.5c4.3-3,0.3-11.3-1.9-14.9c-2.1-3.6-3-5.8-3-5.8
		c-2.5,1-5,2.3-7.2,4c-2.3,1.6-4.3,3.6-6.1,5.6c0,0,1.8,1.6,4.5,4.7C606.8,340.1,613.4,346.5,617.7,343.5L617.7,343.5z M612.7,322.7
		c0,0-0.3-0.5-0.8-1.9c-0.5-1.8,0.5-3.9,0.5-3.9 M599.5,332.3c0,0-0.4-0.4-1.5-1.2c-1.5-1.1-3.9-0.7-3.9-0.7 M615.5,316.8
		c0,0,1.4,2.2,2.1,4.4 M634.6,316.8c0,0-1.4,2.2-2.1,4.4 M628.7,344.4c0,2.1-1.7,3.7-3.7,3.7c-2.1,0-3.7-1.6-3.7-3.7
		s1.7-3.7,3.7-3.7C627,340.7,628.7,342.3,628.7,344.4z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M623.6,346.9l-1.4-2.5l1.4-2.5h2.8l1.5,2.5l-1.5,2.5H623.6z M605.6,347.3
		c0.5-1.8-2.9-7.6-5.8-9.9c-4.1-3.6-6.1-4.3-6.5-4.5c-0.4-0.1-0.8,0-1.1,0.4c-1.7,2.6-3,5.5-4,8.5s-1.5,6.2-1.8,9.2
		c0,0.4,0.3,0.8,0.7,1c0.4,0.1,2.3,0.5,7.8,0.1C598.8,351.7,605,349.1,605.6,347.3L605.6,347.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M603.6,346.6c0.5-1.8-2.8-7.4-7.2-10.3c-1.2,2.2-2.3,4.4-3.2,6.9
		s-1.2,5-1.5,7.4C597.2,351,603.1,348.4,603.6,346.6L603.6,346.6z M614.8,367.5c-1.5-1.1-9.5,0.5-15.4,4.4c-3.9,2.5-5.2,3.7-5.5,4
		c-0.4,0.3-0.8,0.1-1.1-0.3c-2.2-3.2-3.8-6.6-5-10c-1.1-3.4-1.8-7.3-1.9-11.1c0-0.4,0.3-0.8,0.8-0.8c0.4,0,2.3,0.3,6.9,0
		c7.2-0.4,14.6-3.9,15.1-5.5"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M613,357.5c-1.7-5-10.6-3.7-14.7-2.8c-4.1,0.8-6.5,1.1-6.5,1.1
		c0.1,2.6,0.7,5.4,1.5,8.1c0.8,2.6,2.1,5.2,3.4,7.4c0,0,2.1-1.2,5.9-2.9C606.7,366.7,614.6,362.4,613,357.5L613,357.5z M591.8,355.8
		c0,0-0.5,0.1-2.1,0.1c-1.9,0-3.6-1.6-3.6-1.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M596.9,371.2c0,0-0.5,0.3-1.7,1.1c-1.5,1.1-1.8,3.4-1.8,3.4 M587,351.3
		c0,0,2.6-0.7,4.8-0.5 M592.9,333.1c0,0,1.7,2.1,3.6,3.3 M617.4,347.3c1.9,0.7,3,2.8,2.3,4.7s-2.8,3-4.7,2.3s-3-2.8-2.3-4.7
		C613.4,347.7,615.5,346.6,617.4,347.3L617.4,347.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M618.2,352.8l-2.8,0.5l-1.9-2.1l0.8-2.6l2.8-0.5l1.9,2.1L618.2,352.8z
		 M613,370.1c-1.5-1.1-8.1,0.4-11.3,2.3c-4.7,2.9-5.9,4.4-6.2,4.7s-0.3,0.8,0,1.1c1.9,2.3,4.3,4.5,6.9,6.3c2.6,1.9,5.4,3.4,8.3,4.5
		c0.4,0.1,0.8,0,1.1-0.3c0.3-0.4,1.2-2.1,2.6-7.4C615,378,614.5,371.2,613,370.1L613,370.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M611.7,371.6c-1.5-1.1-7.8,0.3-12,3.6c1.7,1.9,3.4,3.6,5.6,5.1
		c2.1,1.5,4.3,2.8,6.6,3.7C613.9,379.4,613.4,372.9,611.7,371.6L611.7,371.6z M635.1,367.5c-1.5,1.1-2.3,9.2-0.5,16.1
		c1.1,4.4,1.9,6.1,2.1,6.6c0.1,0.4-0.1,0.8-0.5,1c-3.7,1.1-7.4,1.6-11.1,1.6c-3.7,0-7.4-0.5-11.1-1.6c-0.4-0.1-0.7-0.5-0.5-1
		c0.1-0.4,1-2.1,2.1-6.6c1.8-6.9,0.8-15-0.5-16.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M625,366.1c-5.2,0-6.7,8.9-7.2,13.1s-1,6.5-1,6.5c2.6,0.7,5.4,1,8.1,1
		c2.8,0,5.5-0.4,8.1-1c0,0-0.5-2.3-1-6.5C631.8,375.1,630.2,366.1,625,366.1L625,366.1z M616.8,385.8c0,0,0,0.5-0.5,1.9
		c-0.5,1.8-2.8,2.8-2.8,2.8 M633.2,385.8c0,0,0,0.5,0.5,1.9c0.6,1.8,2.8,2.8,2.8,2.8 M611.1,389c0,0,0.1-2.6,1-4.8 M595.7,377.7
		c0,0,2.5-1,4.3-2.3 M616.7,358.9c1.2-1.7,3.4-2.1,5.1-0.8c1.7,1.2,2.1,3.4,0.8,5.1s-3.4,2.1-5.1,0.8
		C615.9,362.7,615.5,360.5,616.7,358.9L616.7,358.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M622.2,359.8l-0.3,2.9l-2.6,1.1l-2.2-1.6l0.3-2.8l2.5-1.2L622.2,359.8z
		 M637.1,370.1c-1.5,1.1-2.1,7.8-1.2,11.4c1.2,5.4,2.3,7,2.6,7.4s0.7,0.5,1.1,0.3c2.9-1.1,5.6-2.6,8.3-4.5c2.6-1.9,4.8-4,6.9-6.3
		c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-1.5-1.8-6.2-4.7C645,370.5,638.6,369,637.1,370.1L637.1,370.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M638.2,371.6c-1.5,1.1-2.1,7.6-0.3,12.5c2.3-1,4.5-2.2,6.6-3.7
		s4-3.3,5.6-5.1C646,372.1,639.7,370.5,638.2,371.6L638.2,371.6z M641.5,348.3c0.5,1.6,8,5.1,15.1,5.5c4.5,0.3,6.5,0,6.9,0
		s0.8,0.4,0.8,0.8c-0.1,3.9-0.8,7.6-1.9,11.1c-1.1,3.4-2.8,6.9-5,10c-0.3,0.4-0.7,0.4-1.1,0.3c-0.4-0.3-1.7-1.5-5.5-4
		c-6.1-3.9-14-5.5-15.4-4.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M653.2,371.2c0,0,0.5,0.3,1.7,1.1c1.5,1.1,1.8,3.4,1.8,3.4 M658.1,355.8
		c0,0,0.5,0.1,2.1,0.1c1.9,0,3.4-1.6,3.4-1.6 M654.4,377.7c0,0-2.5-1-4.3-2.3 M638.8,389c0,0-0.1-2.6-1-4.8 M627.4,363.1
		c-1.2-1.6-0.8-4,0.8-5.1c1.7-1.2,4-0.8,5.1,0.8c1.2,1.6,0.8,4-0.8,5.1C630.9,365.2,628.7,364.8,627.4,363.1L627.4,363.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M630,358.2l2.6,1.2l0.3,2.8l-2.2,1.6l-2.6-1.1l-0.3-2.9L630,358.2z
		 M644.3,347.3c0.5,1.8,6.7,4.4,10.5,4.7c5.5,0.4,7.4,0,7.8-0.1c0.4-0.1,0.7-0.4,0.7-1c-0.3-3-0.8-6.2-1.8-9.2s-2.3-5.9-4-8.5
		c-0.3-0.4-0.7-0.5-1.1-0.4c-0.4,0.1-2.2,1-6.5,4.5C647.4,339.7,643.8,345.5,644.3,347.3L644.3,347.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M646.3,346.6c0.5,1.8,6.6,4.4,11.8,4.1c-0.3-2.5-0.7-5-1.5-7.4
		c-0.8-2.5-1.9-4.8-3.2-6.9C649,339.3,645.7,344.8,646.3,346.6L646.3,346.6z M625,336.3c1.8,0,7.3-6.1,9.9-12.7
		c1.7-4.3,1.9-6.1,2.2-6.5c0.1-0.4,0.5-0.7,1-0.5c3.7,1.2,7,3,9.9,5.2c3,2.2,5.6,4.8,8.1,7.8c0.3,0.4,0.1,0.8-0.1,1.1
		c-0.4,0.3-2.1,1.1-5.5,4c-5.5,4.5-9.5,11.7-8.9,13.3"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M632.4,343.5c4.3,3,10.7-3.3,13.6-6.3c2.8-3.2,4.5-4.7,4.5-4.7
		c-1.8-2.1-3.7-4-6.1-5.6s-4.7-3-7.2-4c0,0-1,2.2-3,5.8C632.1,332.2,628.1,340.3,632.4,343.5L632.4,343.5z M650.5,332.3
		c0,0,0.4-0.4,1.5-1.2c1.5-1.1,3.9-0.7,3.9-0.7 M637.3,322.7c0,0,0.3-0.5,0.7-1.9c0.5-1.8-0.6-3.9-0.6-3.9 M657,333.1
		c0,0-1.7,2.1-3.6,3.3 M662.9,351.3c0,0-2.6-0.7-4.8-0.5 M634.9,354.2c-1.9,0.7-4-0.4-4.7-2.3s0.4-4,2.3-4.7s4,0.4,4.7,2.3
		C637.9,351.6,636.8,353.6,634.9,354.2L634.9,354.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M631,350.2l1.8-2.1l2.8,0.5l1,2.6l-1.9,2.1l-2.8-0.5L631,350.2z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M96.8,376.3c1,0.1,3,0.4,5.1,0.5l25.9,0.1l17.3,0.1c0,0,0.8,0.1,0.8-1
	v-0.7 M146,375.5v-3l-24.3-0.1c0,0-8.5,0-11.3-0.1c0,0-2.5-0.7-3.4-1c0,0-0.8-0.3-1.9-0.1l-4.3,0.4c0,0-1.2,0.3-2.2,0.8l-2.8,1.4
	c0,0-1.8,1.6,0.4,2.5c0,0,0.1,0,0.6,0.1 M637.1,357.6c-1.5,5,6.5,9.2,10.3,10.9s5.9,2.9,5.9,2.9c1.4-2.3,2.6-4.8,3.4-7.4
	c0.8-2.6,1.4-5.4,1.5-8.1c0,0-2.5-0.3-6.5-1.1C647.8,353.9,638.7,352.7,637.1,357.6L637.1,357.6z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M267.4,368.6l31,0.4
		l6.9-7.6c0,0-1.5-1.4-1.8-4.5l-0.7-3.6L302,339l-0.7-13.6c0,0-1-17.9,1.4-32.7c0,0,2.2-17.1,13.8-26.8l-21,0.1
		c0,0-11.4,4.5-14.2,4.4c0,0-36.3-1.5-85.3,7.6c0,0-33.3,5.6-58.7,15.4c0,0,22-6.5,24.9-3.9c0,0,3.3,1.2-10,10.2l15.1,11.7
		c0,0,15.1-15,41.3-15c0,0,27.2-1.2,46.6,22.3c0,0,11.7,13.8,12.5,32.1L267.4,368.6z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M316.5,265.9l3.3-0.1
		l167.4-8.9c0,0-4.5,16.6-7.8,33.7s-13.9,72.1-13.9,72.1l-156.3,0.6c0,0-3.6,0.1-5.1-3.3l-0.4-1.2l-1-5.2c0,0-1.7-29.4-1.7-31.1
		c0-1.6-0.5-18.8,1.8-31.2C303.1,291.3,304.8,276.5,316.5,265.9z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M487.3,256.9
		c0,0,24.1-0.4,84.3-2.9c0,0-2.3,14.3-7.6,35.6c0,0-5.5,19.9-13.6,39.8c0,0-6.9,22-18.7,29.4c0,0-5.4,4.1-16.8,3.9h-49.5l12.5-65.5
		c0,0,1.2-6.5,2.8-13.2C480.9,283.9,484.5,266.7,487.3,256.9z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M645.9,300.4
		l28.3-1c0,0,8.8,0.3,16-3.6l27.7-16.9c0,0-5.4-2.5-7.8-4.1c-2.5-1.8-6.1-4.5-7.7-7.2c0,0-1.1-1.9,2.5-4.7l19.9-8.1
		c0,0-11.3-1.4-28.9-2.1l-12.2-0.6c0,0-12.8-1.1-21.3-4.1c0,0-51,4.3-90.8,5.9l-5.2,26l-2.5,10.3c0,0-3.6,11.8-5.5,17.7
		c0,0-5.2,16-11.3,30.1l17.1,16.9c0,0-1.1-9.9,3.7-21.6c0,0,6.9-20.6,29.4-31.1C597.2,302.5,619.9,290.2,645.9,300.4z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M330,265.2
		c0,0,19.5-14.3,40.7-26.1l-0.7,5c0,0,4.4-1.2,7.2-1.2s4.1,1,4.3,2.5c0.1,1.6,0.7,7.8-2.6,12.5c0,0-0.8,2.1-10.7,3.9l-0.3,1.6
		L330,265.2z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M374.2,260.1
		l7-0.3l92.4-4.4L486,209c0,0-31.9,0-58.9,5.2c0,0-19.3,3.6-56.4,24.6L370,244c0,0,4.8-1.5,8-1.2c3,0.3,3.4,1.8,3.6,2.8
		s0,5.8-0.7,7.7C380.2,255.1,379.7,258.7,374.2,260.1z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M490.6,254.6
		l40-1.9c0,0,6.5,0.6,13.1-2.2c0,0,3.6-1.4,6.5-4.5c0,0,9.1-10.7,14.6-21.6c0,0,1.1-2.2-0.8-3.3c0,0-6.7-3.3-32.2-7
		c0,0-12.2-1.9-31.4-2.6L490.6,254.6z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="206.3"
              cy="353.6"
              r="51.7"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="625"
              cy="353.6"
              r="51.7"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe4DrLeft);
