import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback2DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M85.4,336.2c0,1.4-1.2,3.7-1,5.4c0,0,0.3,2.8,0.7,4.5c1,5.9,3,12.2,4.7,14.2c1.5,1.8,2.1,2.5,3.8,2.9
	c2.1,0.1,18.6,3,27,5.2c12.7,3.2,27.4,8.9,27.4,8.9l7,0.8c0-2.3,0.1-4.7,0.3-7.4c0.8-8.8,117.2-10.3,118.6-0.7c0.5,5,1,10.3,1,16.2
	H565c0-5.8,0.3-11,1-15.8c1.2-9.8,117.8-2.9,118.3,3.7c0.1,2.5,0.3,5,0.3,7c0,0,65.1-5.4,67.5-5.5c2.8-0.1,2.9-0.5,2.9-1.5
	c0-1.2-0.8-1.2-1.4-1.2c-0.7,0-4,0.1-6.5,0.1c-2.5,0-1.7-1.4-0.8-2.8c1.4-2.3,2.5-4.3,3.4-5.2c1-1.2,2.8-1.2,3.7-1.2h5.4
	c1.8,0,1.8-1.8,2.1-2.9c0-1.2,0.1-1.2,0.1-1.8c0-0.5-0.3-1.6-1.4-1.6h-5.4c-1.4,0-1.8-0.5-1.8-1.8c0-1.4,1.5-6.6,1.8-8.5
	c0.7-2.9,5.8-2.1,5.8-2.1l0.1-24.1c0,0-3.9-3.6-5-4c-1-0.5-1.2-1.8-1.2-1.8c-0.5-5-2.9-10.5-6.9-14.3H747l2.2-1.5
	c0,0-9.9-13.6-42.2-22.6c-31.6-8.5-73.6-14.9-87.5-16.8c0,0-7.6,1.5-11,2.1c-0.8,0.1-2.1,0.3-3.4,0.3l-0.7-0.3
	c-55.3-32.1-117.9-63.6-151.9-72.8c-40.2-11-123.5-8.9-138.1-8.1c-26.7,1.2-51.9,3.7-100.3,13.8l-31.6,1.4l1,6.6l14.2,2.3l-0.1,0.7
	c-20.1,5.5-59.7,22.3-93.8,45.1c0,0-1.5,1.4-2.3,3.7c-1,2.5-3.6,12.2-3.6,12.2l3.6,0.3c0,0,3-0.7,2.2,4.7c-1,5.4-6.5,27.2-6.5,27.2
	s-0.3,10.2,0.8,16c0,0-1.7,0-7,0.5c-5.4,0.6-6.6,3.3-6.6,4.5v4.7c0,0,0.7,2.5,0.8,4.3C85.6,330.8,85.4,334.6,85.4,336.2L85.4,336.2z
	"
          />
          <path
            fill="#656565"
            d="M163.6,363.4c0,27.8,22.7,50.5,50.8,50.5c27.9,0,50.5-22.7,50.5-50.5c0-27.9-22.6-50.8-50.5-50.8
	C186.4,312.8,163.6,335.5,163.6,363.4L163.6,363.4z M179.2,363.4c0-19.4,15.7-35.1,35.1-35.1s35.1,15.7,35.1,35.1
	c0,19.3-15.7,35.1-35.1,35.1S179.2,382.7,179.2,363.4z M575.3,363.4c0,27.8,22.7,50.5,50.8,50.5c27.9,0,50.5-22.7,50.5-50.5
	c0-27.9-22.6-50.8-50.5-50.8C598,312.8,575.3,335.5,575.3,363.4z M590.4,363.3c0-19.3,15.7-34.9,35.1-34.9s35.1,15.7,35.1,34.9
	c0,19.4-15.7,35.1-35.1,35.1S590.4,382.7,590.4,363.3L590.4,363.3z"
          />
          <path
            fill="#EBEBEB"
            d="M576,256.8c2.2,0.7,4.3,1,6.1,1.2c6.5,0.5,12.7,0.1,16-0.1l-0.5-0.3c-41-23.4-84.9-45.9-117.8-59l-1-0.1
	c-4.3,0.6-8.4,1.8-11.7,3h0.3c30,11.1,63.7,29.7,106.3,54.2 M274.3,207.4c-22.3,6.1-47.9,17.2-64.9,25.3c0,0-11.8,4.4,0.6,8.9
	c1.6,0.7,3.2,1.4,4.4,1.6c2.3,1,9.5,3.6,23,3.9H237l94.9,4.7c4.3,0.1,4.4-4.7,4.4-4.7l-2.9-40.7c0-5.9-5.9-6.5-5.9-6.5
	c-4.7,0.1-8.3,0-11.3,0.1c-17.2,1.2-29.7,4.3-39.6,6.6L274.3,207.4 M684.4,289.7c21.5,5.9,26,17.9,29,24.2c1.5,3,3,3,4.5,3
	c1.7,0,25,0.1,25,0.1c1.2-0.1,2.3-1.8,1.5-5c-1.5-5.4-4.5-9.9-12.1-14c-7.7-4.3-34.4-7.6-44.7-7.7 M365,258.2
	c23.1,1.2,116,6.7,162.7,9.6h-0.5l-6.2-29.7l-1.8-0.7c-11.7-7.3-39.1-24.1-72.1-33.2c-22.6-6.2-61.6-7.6-92-7.4l-0.1,0.7L365,258.2
	 M121.1,251.7c5.5-6.9,12.9-11.1,15.7-12.8c3.7-2.5,32.9-16.4,66.9-29.4l-0.7,0.1c-1.6-1.5-3.2-3-5-4.3l-0.6,0.6
	c-20.1,5.8-59.7,22.4-93.8,45.3c0,0-0.3,0.1-0.8,0.8l1,1c0,0,22.3-1.5,17.2-1.6"
          />
          <path
            fill="#EBEBEB"
            d="M121.5,251.3c13.3,0.1,33.7-10.7,33.7-10.7c13.2-6.5,36.5-17.5,61.5-27.8c2.2-1,4.4-1.8,6.6-2.8
	c6.2-2.9,0.3-8,0.3-8c-2.9,0.8-5.8,1.8-8.4,2.9c-38.8,14.3-74.3,31.4-78.6,34c-2.8,1.6-10,5.9-15.7,12.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M198,205.1l-14.2-2.3l-1-6.6l31.6-1.4c48.4-10,73.6-12.7,100.3-13.8
	c14.7-0.8,98.1-2.9,138.1,8.1c33.8,9.2,96.6,40.7,151.9,72.8l0.7,0.3c1.4,0,2.5-0.1,3.4-0.3c3.3-0.5,11-2.1,11-2.1
	c13.8,2.1,55.7,8.3,87.5,16.8c32.3,8.9,42.2,22.6,42.2,22.6l-2.2,1.5h0.1c4,3.9,6.5,9.5,6.9,14.3c0,0,0.1,1.4,1.2,1.8c1,0.5,5,4,5,4
	l-0.1,24.1c0,0-5.1-0.8-5.8,2.1c-0.3,2.1-1.8,7.3-1.8,8.5s0.5,1.8,1.8,1.8h5.4c0,0,1.4,1.2,1.4,1.7c0,0.7-0.1,0.7-0.1,1.8
	c-0.1,1-0.1,2.9-2.1,2.9h-5.4c-1,0-2.8,0-3.7,1.2c-0.8,1-2.1,2.9-3.4,5.2c-0.8,1.4-1.7,2.8,0.8,2.8c2.3,0,5.8-0.1,6.5-0.1
	c0.5,0,1.4,0,1.4,1.2c0,1-0.1,1.4-2.9,1.5c-2.5,0.1-67.5,5.5-67.5,5.5c0-2.2-0.1-4.5-0.3-7c-0.5-6.6-117.1-13.5-118.3-3.7
	c-0.7,4.8-1,10-1,15.8H275c0-5.9-0.5-11.3-1-16.2c-1.4-9.6-117.8-8.1-118.6,0.7c-0.1,2.8-0.3,5.1-0.3,7.4l-7-0.8
	c0,0-14.7-5.8-27.4-8.9c-8.4-2.2-24.9-5.1-27-5.2c-1.8-0.3-2.3-1-3.9-2.9c-1.7-1.8-3.7-8.3-4.7-14.2c-0.3-1.8-0.7-4.5-0.7-4.5
	c-0.1-1.6,1-4,1-5.4c0-1.5,0.1-5.4,0-6.9c-0.1-1.7-0.8-4.3-0.8-4.3v-4.7c0-1.2,1.2-4,6.6-4.5s7-0.5,7-0.5c-1.1-5.9-0.8-16-0.8-16
	s5.4-21.9,6.5-27.2c0.8-5.4-2.2-4.7-2.2-4.7l-3.6-0.3c0,0,2.5-9.8,3.6-12.2c0.8-2.3,2.3-3.7,2.3-3.7c34.3-23,73.7-39.6,93.8-45.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M365,258.2c23.1,1.2,116,6.7,162.7,9.6h-0.5l-6.2-29.7l-1.8-0.7
	c-11.7-7.3-39.1-24.1-72.1-33.2c-22.6-6.2-61.6-7.6-92-7.4l-0.1,0.7L365,258.2 M684.4,289.7c21.5,5.9,26,17.9,29,24.2
	c1.5,3,3,3,4.5,3c1.7,0,25,0.1,25,0.1c1.2-0.1,2.3-1.8,1.5-5c-1.5-5.4-4.5-9.9-12.1-14c-7.7-4.3-34.4-7.6-44.7-7.7 M121.5,251.3
	c13.3,0.1,33.7-10.7,33.7-10.7c13.2-6.5,36.5-17.5,61.5-27.8c2.2-1,4.4-1.8,6.6-2.8c6.2-2.9,0.3-8,0.3-8c-2.9,0.8-5.8,1.8-8.4,2.9
	c-38.8,14.3-74.3,31.4-78.6,34c-2.8,1.6-10,5.9-15.7,12.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M121.1,251.7c5.5-6.9,12.9-11.1,15.7-12.8c3.7-2.5,32.9-16.4,66.9-29.4
	l-0.7,0.1c-1.6-1.5-3.2-3-5-4.3l-0.6,0.6c-20.1,5.8-59.7,22.4-93.8,45.3c0,0-0.3,0.1-0.8,0.8l1,1c0,0,22.3-1.5,17.2-1.6 M576,256.8
	c2.2,0.7,4.3,1,6.1,1.2c6.5,0.5,12.7,0.1,16-0.1l-0.5-0.3c-41-23.4-84.9-45.9-117.8-59l-1-0.1c-4.3,0.6-8.4,1.8-11.7,3h0.3
	c30,11.1,63.7,29.7,106.3,54.2 M102.1,267.2l1.7,1.6l-0.8,6.7h-8.8c0.7-7.6,3.7-8.5,3.7-8.5 M625.9,414c27.9,0,50.5-22.7,50.5-50.5
	c0-27.9-22.6-50.8-50.5-50.8c-27.9,0-50.8,22.7-50.8,50.8C575.3,391.2,598,414,625.9,414"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M676.5,363.4c0-27.9-22.6-50.8-50.5-50.8s-50.8,22.7-50.8,50.8
	c0,27.8,22.7,50.5,50.8,50.5C654,414,676.5,391.2,676.5,363.4z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M625.9,398.5c19.4,0,35.1-15.8,35.1-35.1c0-19.4-15.7-35.1-35.1-35.1
	s-35.1,15.7-35.1,35.1C591,382.7,606.7,398.5,625.9,398.5 M702,357.3c-3.2-39.2-36-70.3-75.9-70.3c-35.8,0-65.8,24.6-74,57.8
	 M743.8,356.5c1.8,0,3.9-1.5,5.1-4.5c1.4-3,4.4-13.3,5.1-15.7c0.7-2.9-3.2-3.2-3.2-3.2h-24.8c0,0-3.4-0.1-3.4,2.5
	c0,2.5,0.7,15.7,1,17.5c0.3,2.3,2.5,3.3,3.7,3.3h16.5 M683.6,360.9l77.7,0.1 M753.8,351.8h5.8 M756,357.3l4.4-12.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M684.9,381.3c-0.3-10.5-1.7-26.3-5.2-36.7c-7.7-22.3-29-38.1-53.8-38.1
	c-25.6,0-47.2,16.8-54.5,39.9c-2.9,9.2-6.5,23-6.2,39.9H275 M687.4,290.2c10.3,0.1,37,3.6,44.7,7.7c7.6,4,10.7,8.5,12.1,14
	c0.8,3-0.3,5-1.4,5.1 M660.9,318.3l19.3-8.9h31 M594.4,262.6c2.8,1.5,5.8,4.7,9.8,9.8c2.9,3.6,10.5,4,10.5,4
	c58.3,7,69.5,13.2,69.5,13.2c21.5,5.8,26,17.7,29.2,24.1c1.5,3,2.9,3.2,4.5,3.2c1.5,0,25,0.1,25,0.1 M684.9,381.3
	c0,0,65.1-5.4,67.7-5.5c2.5-0.1,2.8-0.5,2.8-1.5c0-1.2-0.7-1.2-1.4-1.2s-3.9,0.1-6.5,0.1c-2.5,0-1.7-1.4-0.8-2.9
	c1.4-2.3,2.5-4,3.6-5.1c0.8-1.2,2.5-1.2,3.6-1.2h5.5c1.7,0,1.7-1.9,1.8-3c0.1-1,0.3-1,0.3-1.6s-0.5-1.6-1.5-1.6h-5.2
	c-1.5,0-2.1-0.5-2.1-1.8c0-1.5,1.5-6.6,2.1-8.5c0.5-2.9,5.5-2.2,5.5-2.2l0.1-23.9c0,0-3.9-3.6-5-4c-0.8-0.5-1-1.8-1-1.8
	c-0.7-5-3-10.6-7-14.3 M619.9,259.8c13.8,2.2,55.7,8.3,87.5,17.1c32.3,8.8,42.2,22.4,42.2,22.4l-8.1,5.8 M754,373l4.4-8.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M539.4,386.5v-15.7 M282,385.1c-0.5-9.9-3.3-31.6-6.5-40.7
	c-2.5-7.7-7.6-16.2-12.7-22c-11.7-13.8-29.2-22.6-48.7-22.6v-0.1 M558.2,385.1c0.5-9.9,3.3-31.6,6.5-40.7c2.5-7.7,7.6-16.2,12.7-22
	c11.7-13.8,29.2-22.6,48.7-22.6v-0.1c31.6,0,57.9,23.1,62.9,53.4c0,0,0.5,4.7,0.8,7.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M214.3,414c-27.9,0-50.8-22.7-50.8-50.5c0-27.9,22.7-50.8,50.8-50.8
	c27.9,0,50.5,22.7,50.5,50.8C264.9,391.2,242.2,414,214.3,414"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M163.6,363.4c0-27.9,22.7-50.8,50.8-50.8c27.9,0,50.5,22.7,50.5,50.8
	c0,27.8-22.6,50.5-50.5,50.5C186.4,414,163.6,391.2,163.6,363.4L163.6,363.4z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M546.1,272.7c-42.9-2.5-134.7-8.1-162-9.5c-36.5-1.8-147.5-7-147.5-7
	 M214.3,299.7c-19.5,0-37,8.8-48.7,22.6c-5.1,5.9-10.2,14.3-12.7,22c-3,9.1-5.1,22.7-5.5,32.7 M214.3,398.5
	c-19.4,0-35.1-15.8-35.1-35.1c0-19.4,15.7-35.1,35.1-35.1s35.1,15.7,35.1,35.1C249.4,382.7,233.7,398.5,214.3,398.5 M137.8,355.1
	c4-38.7,36.7-68.8,76.5-68.8c21.9,0,41.8,9.2,56,24.1c12.9,13.8,20.9,32.3,20.9,53c0,2.3,0,4.7-0.3,7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M236.6,252.1c-13.8-0.3-21.6-2.9-25.4-4.3c-3.7-1.5-12.7-5.2-17.9-8.9
	c-5.2-3.9,1-6.9,4.7-8.8c23.5-11.7,51.7-23.5,74.4-29.9 M519.4,232.3l8,36.7 M538.8,244.5l7.3,28.3 M551.8,273.3
	c1.7,8.4,2.5,16,2.9,27.9c0.1,8.4-1.5,34.3-2.9,44.4c-1.4,11.3-3,25-12.5,25H368.7 M527.4,267.4c-46.6-2.8-118.9-6.9-141.8-8.1
	l-149-7.2 M521.5,233c6.6,3.9,42.9,27.2,49.9,33.4c10.7,9.2-2.9,7.7-2.9,7.7s-6.1-0.3-16.2-0.8c-2.1-0.1-4-0.3-6.2-0.5 M344.7,196.7
	c31.2-0.3,76.9,0.6,101.9,7.4c33.1,9.1,60.5,26,72.2,33.2 M211.2,252.1c-3.7-1.4-12.7-5.2-17.9-8.9 M236.6,256.2
	c-13.8-0.1-21.6-2.8-25.4-4.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M274.3,207.4c-22.3,6.1-47.9,17.2-64.9,25.3c0,0-11.8,4.4,0.6,8.9
	c1.6,0.7,3.2,1.4,4.4,1.6c2.3,1,9.5,3.6,23,3.9H237l94.9,4.7c4.3,0.1,4.4-4.7,4.4-4.7l-2.9-40.7c0-5.9-5.9-6.5-5.9-6.5
	c-4.7,0.1-8.3,0-11.3,0.1c-17.2,1.2-29.7,4.3-39.6,6.6L274.3,207.4"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M274.3,207.4c-22.3,6.1-47.9,17.2-64.9,25.3c0,0-11.8,4.4,0.6,8.9
		c1.6,0.7,3.2,1.4,4.4,1.6c2.3,1,9.5,3.6,23,3.9H237l94.9,4.7c4.3,0.1,4.4-4.7,4.4-4.7l-2.9-40.7c0-5.9-5.9-6.5-5.9-6.5
		c-4.7,0.1-8.3,0-11.3,0.1c-17.2,1.2-29.7,4.3-39.6,6.6L274.3,207.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M98.2,315.4c-1.4-5.9-0.8-16.2-0.8-16.2s5.4-21.9,6.2-27.2
		c1-5.2-2.2-4.7-2.2-4.7l-3.3-0.1c0,0,2.5-9.8,3.3-12.2c1-2.5,2.5-3.7,2.5-3.7c34.3-23,73.7-39.6,93.8-45.3 M198,205.1l-14.2-2.3
		l-1-6.6l31.6-1.4c48.3-9.9,73.7-12.5,100.3-13.8c14.7-0.7,98.1-2.9,138.4,8.3c33.7,9.2,96.6,40.6,151.9,72.8 M521.5,233
		c-12.2-7.4-39.8-24.5-73.5-33.7c-35.9-9.8-113.4-7.6-132.5-6.5c-19.3,1.2-32.9,5-43.2,7.4 M223.5,209.7
		c36-13.8,71.8-19.4,91.6-20.9"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M155.6,240.3c13.2-6.5,36.5-17.5,61.5-27.8c2.2-1,4.4-1.8,6.6-2.8
		 M449,195.4c-38.9-9.5-115.3-8.8-134-8c-29.4,1.7-68.2,6.5-91.5,14.7 M449,195.4c34.4,9.6,72.6,30.3,123.7,60 M131,304
		c-0.6-6.2-0.6-24.5,0.7-37.6c1.4-13.2,13.2-20.8,13.2-20.8s4-2.1,10.7-5.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M85.8,350.3c0,0,6.5,0.5,14.2,1.2 M115.4,352.7c4.7,0.3,8.5,0.7,10.2,0.8
		c3.6,0.3,14.2,2.2,16.5,2.8c2.3,0.3,7.4,1.4,7.4,1.4l7.4,1.2 M115.3,304H131l30.8,3.2l17.1,11.3 M574.9,256.6c2.2,0.5,4.3,1,6.1,1
		c6.5,0.5,12.8,0.3,16-0.1 M572.7,255.4c5.1,2.7,14,6.2,20.2,6.7c6.5,0.5,12.8,0.3,16-0.1c3.3-0.5,11-2.2,11-2.2 M364.3,257.6
		l-9.6-60.3 M349,257.1l-4.5-65.1 M368.8,370.8c-16-0.8-15.7-7.4-19.4-23c-5.2-22.4-5.2-65.9-5.2-73.5c0-7.4,4.7-17.3,4.7-17.3
		l-4.5-65.1 M85.8,321l26.7,0.1 M85.2,340.4l34,0.5"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M290.8,325.8c-3.6-0.3-4.5-3.2-5.1-4.5c-0.5-1.4,1.2-1.4,1.2-1.4
	l258.8,5.4c0,0,1.5,0,1.5,1v3.9c0,0.5-0.5,1-1.5,1L290.8,325.8 M373.6,289.5h6.9c3.2,0.1,5.8-2.3,5.9-5.4v-3.7c0-3-2.5-5.5-5.8-5.5
	l-6.9-0.1c-3.2,0-5.8,2.3-5.9,5.5v3.6C367.8,286.8,370.3,289.2,373.6,289.5 M723,339.9h29.8 M737.5,344.3h13.6 M735.7,353.2h12.2
	 M736.7,348.8h12.9 M733.8,355.7c3.4-3.7,3.9-12.1,3.9-14.9 M729.4,340.1c2.8,0,5,3.6,5,7.7c0,4.3-2.2,7.7-5,7.7s-4.8-3.6-4.8-7.7
	S726.7,340.2,729.4,340.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M754.5,314h1.2c0.5-0.1,0.7-1.4,0.7-2.3s0.1-0.8,0-3.4
	c-0.1-2.3-1.9-3.3-1.9-3.3h-3.4 M734.3,348c0,4.3-2.2,7.7-5,7.7s-4.8-3.6-4.8-7.7s2.2-7.7,4.8-7.7
	C732.1,340.1,734.3,343.7,734.3,348L734.3,348z M537.9,262.1c-0.7,0-3.9,0-5.1,0.5c-1,0.5-5,1.4-5.9,1.4c-0.8,0-7.3-0.1-10.2-0.5
	c-2.9-0.3-5-2.8-5-3.6c-0.1-1,0-5.5,0-10c0.1-4.4,4.9-5.2,5.8-5.2h8c1.8,0.1,4.5,1.2,5.9,2.3c1.7,1.2,2.9,3,3.2,4.3
	c0.3,1.2,0.3,1.4,0.8,2.3s2.3,2.2,2.3,2.2"
          />
          <path
            fill="#FFFFFF"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M525.4,264.1c1,0,1.7,2.5,2.1,3.3c0.5,1,0.8,1.9,2.2,1.9
	c1.4,0,8.3,0.3,10,0.3c2.5-0.1,1.4-2.9,1.2-4.7c0-1.8-1.8-7-3-9.1 M537.9,262.1c-0.7,0-3.8,0-5.1,0.5c-1,0.5-5,1.4-5.9,1.4
	c-0.8,0-7.3-0.1-10.2-0.5c-2.9-0.3-5-2.8-5-3.6c-0.1-1,0-5.5,0-10c0.1-4.4,4.9-5.2,5.8-5.2h8c1.8,0.1,4.5,1.2,5.9,2.3
	c1.7,1.2,2.9,3,3.2,4.3c0.3,1.2,0.3,1.4,0.8,2.3s2.3,2.2,2.3,2.2v6.3L537.9,262.1L537.9,262.1z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M513.9,259.1h8.8c2.9,0,6.5,1.6,8.5,2.5 M121.9,251
	c13.3,0.1,33.7-10.7,33.7-10.7 M572,256l-5.1,6.7 M235.5,198.6c-1.6-1.6-6.1-5.1-8.3-6.5 M224,201.9c-1.5-1.6-6.7-5.8-8.9-6.9
	 M198.5,205.2c-4.3-3.3-8.3-5.9-15.7-9.1 M203.5,209.5c-1.8-1.5-3.3-3-5-4.3 M114.9,347.3c1.4,0.3,1,1.5,0.8,2.9
	c-0.1,1.2-1.7,6.7-2.1,8.4c-0.6,1.6-1.2,2.1-1.8,2.1c-0.7,0-4.4-0.3-5.5-0.3c-1.4-0.1-2.2-1.8-2.9-2.8c-1.2-1.7-4.3-8-5.2-10
	c-0.6-1.4,1-1.4,1-1.4L114.9,347.3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M115.8,350.2c-0.1,1.2-1.7,6.7-2.1,8.4c-0.6,1.6-1.2,2.1-1.8,2.1
		c-0.7,0-4.4-0.3-5.5-0.3c-1.4-0.1-2.2-1.8-2.9-2.8c-1.2-1.7-4.3-8-5.2-10c-0.6-1.4,1-1.4,1-1.4l15.7,1.2
		C116.2,347.6,116,348.8,115.8,350.2z M86.3,334.9c6.5,0,22,2.2,30.8,5.2"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M573.1,293.8c1.4,0,2.5-1.2,2.8-2.5c0-1.5-1.2-2.8-2.8-2.8l-7-0.1
	c-1.4,0-2.5,1.2-2.5,2.5c0,1.5,1,2.8,2.5,2.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M121.1,317.6c-2.2,2.2-4,4-4.7,4.5c-1.5,1.6-3.6,3.2-5.1,3.2L84.7,325
	 M84.6,325c0,0,0.6,2.8,0.7,4.3c0.1,1.6,0,5.5,0,6.9c0,1.5-1,3.9-0.8,5.5c0,0,0.3,2.8,0.7,4.5c0.8,5.9,3,12.2,4.7,14.2
	c1.5,1.8,2.1,2.5,3.8,2.9c1.8,0.1,18.6,2.9,27,5.1c12.7,3.3,27.4,8.9,27.4,8.9l6.9,1c0.6-10.2,2.1-24.1,5.5-33.6
	c8.5-21.9,29-38.2,53.9-38.2c25.4,0,47,16.8,54.2,40.2c3,9.1,6.5,22.7,6.5,39.8 M103.2,275.5h-8.8c0.6-7.6,3.7-8.4,3.7-8.4
	 M573.1,293.8c1.4,0,2.5-1.2,2.8-2.5c0-1.5-1.2-2.8-2.8-2.8l-7-0.1c-1.4,0-2.5,1.2-2.5,2.5c0,1.5,1,2.8,2.5,2.8L573.1,293.8
	L573.1,293.8z M131,304c0,0,0.1,3.3-0.7,4.4l-9.2,9.2 M738.7,325c1.2,0,1.2-0.8,0.8-1.4c-0.3-0.7-1.7-4-2.1-4.7
	c-0.3-0.7-0.7-1.2-1.7-1.2h-2.8c-1.4,0-1.7,0.5-1.4,1.4c0.1,0.8,1.2,3,1.8,4.7c0.7,1.6,2.1,1.2,2.1,1.2L738.7,325 M719.9,356.2
	c0.3-3.6-0.5-12.8-0.7-17.5c-1-13.3-2.5-17.7-5.4-24.1 M598.7,264.3c13.8,2.1,70.6,9.8,105,19c24.9,6.7,33.8,13.3,40.6,19.7
	 M460.6,198.6c3-1.2,7.3-2.5,11.4-3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M466.1,201.2c3.2-1.2,7.4-2.8,11.7-3 M105,315.2c0,0,6.7-6.2,8.3-7.7
		c1.5-1.4,1.4-2.8,1.4-2.8c-0.8-7.4-0.8-26.1,0.7-39.2c0.6-5.5,3-10.3,6.1-14c5.4-6.9,12.9-11.1,15.7-12.8
		c4.3-2.8,39.8-19.7,78.6-34c2.9-1,5.5-2.1,8.4-2.9c0,0,5.9,5.1-0.3,8 M120.4,318.1c2.2-1.6-4.4-2.8-8.3-2.9h-8.5
		c-2.9,0.1-5.2,0.1-5.2,0.1s-1.7,0-7,0.5c-5.4,0.5-6.6,3.2-6.6,4.4v4.7 M104.1,252.9c0,0,22.3-1.5,17.2-1.6"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M390.8,285.3c-3.9-0.5-12.2-0.8-20.1-0.8h-12c-1.6,0-2.9-1.4-2.9-2.9
	s1.2-2.9,2.9-2.9h12c7.7,0,16.4,0.5,20.1,1c1.4,0.1,2.8,1.5,2.8,2.9C393.4,283.9,392.1,285.3,390.8,285.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M370.7,284.4h-12c-1.6,0-2.9-1.4-2.9-2.9s1.2-2.9,2.9-2.9h12
	c7.7,0,16.4,0.5,20.1,1c1.4,0.1,2.8,1.5,2.8,2.9c0,1.4-1.4,2.8-2.8,2.8C386.8,284.7,378.4,284.4,370.7,284.4L370.7,284.4z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M365,284.4l0.1-5.5 M361.9,283.7c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2
	s-2.2,1-2.2,2.2C359.7,282.8,360.8,283.7,361.9,283.7 M137.7,355.4c-0.7,3.3-1.2,6.9-1.5,10.3 M159.6,276.9
	c0,7.4,5.9,13.3,13.2,13.3 M172.8,263.7c-7.3,0-13.2,5.9-13.2,13.2 M186,276.9c0-7.3-5.9-13.2-13.3-13.2 M172.8,290.2
	c7.4,0,13.3-5.9,13.3-13.3 M575.4,366.3l-8.8,3.6 M677.5,372.3l7,2.2 M601.2,275.4c-8.1-0.5-16-1-20.9-1.4 M164.7,367.7l-8.8,3.3
	 M266.6,367.7l7,2.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M625.5,398.3c19.4,0,35.1-15.7,35.1-35.1c0-19.3-15.7-34.9-35.1-34.9
	s-35.1,15.7-35.1,34.9C590.4,382.7,606.1,398.3,625.5,398.3 M135.6,271.5c48.6-0.3,132.8,2.1,216.7,5.2 M396.6,278.5
	c64,2.9,124.2,5.9,163.6,8.4 M579,288.1c9.8,0.8,17.2,1.4,22,2.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M660.9,318.3
		l19.3-8.9h31.2c0,0-5.9-14.7-26.8-19.7c0,0-4.4-2.9-32.2-7.8c0,0-26.3-4.3-38.2-5.5c0,0-6.5-0.5-9.9-4c0,0-6.5-8.7-10.3-10
		c0,0-7.4,0.1-21.5-7l-5.6,7.6c0,0,8.5,5.6,8.7,9.4c0,0-0.3,2.3-6.5,1.9l-17.3-0.8c0,0,2.6,11.3,2.9,28.8c0,0,0,4-0.3,9.9
		c0,0-0.3,5.9-0.4,8c-0.1,2.1-1.2,18.6-2.1,24.5c0,0-1,10.9-3.2,17.5c0,0-1.8,5.4-4.4,7.3c0,0-2.3,1.9-5.1,1.6v15.4h25.7
		c0,0,0.4-14.2,2.1-22.3c0,0,3.2-16.4,6.2-22.6c0,0,7.6-21.5,31.5-30.8C604.9,310.6,632.5,297.6,660.9,318.3z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M551.8,273.3
		c0,0,3,12.1,2.9,29.6c0,0-0.5,12.1-0.7,16c0,0-1.5,21.6-1.9,24.2c0,0-1.5,15.7-4.1,20.9c0,0-2.8,6.9-8.7,6.9H368.7
		c0,0-10.6,0-14.2-5.6c0,0-1.8-2.3-3-7.7l-2.5-10.7c0,0-1.5-7.8-1.8-10.3l-1.1-9.4c0,0-1.2-14.6-1.5-24.1l-0.5-26.5
		c0,0-0.3-2.8,0.7-7.7c0,0,1-4.1,2.2-7.2L551.8,273.3z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M541.2,266.8
		c0,0,0.8,2.3-1.4,2.8l-10.3-0.3c0,0-1.2,0.3-2.1-1.5c0,0-1.2-3.6-2.2-3.7c0,0-6.3-0.1-9.1-0.5c0,0-2.9-0.7-4.3-3l-0.1-0.5l0.1-9.9
		c0,0-0.3-4.1,5.4-5.2h8.3c0,0,3.7,0.1,7,3.2c0,0,1.8,1.8,2.2,3.6c0,0,0.4,2.8,3.2,4.3c0,0,1.9,3.7,2.6,6.9c0,0,0.4,1.4,0.4,2.1
		C540.8,265.5,541.2,266.8,541.2,266.8z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M521,238.1
		l-1.8-0.7c0,0-34-22.4-69.7-32.5c0,0-18.8-6.5-59.4-7.7l-34.3-0.6c0,0-1.5-0.1-1,1.8l10.2,59.7l162.6,9.6c0,0-1.1-3-1.7-3.3
		c0,0,0-0.3-1.8-0.4c-1.8,0-6.7-0.4-7.8-0.5c-1.1-0.1-3.7-1.5-4.4-3.4v-10c0,0-0.3-4.1,5.4-5.2h5.1L521,238.1z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M330.8,251.7
		c0,0,5.2,1.1,5.2-5.2l-2.9-41.3c0,0-0.4-4.4-5.8-5.2c0,0-5.5,0.3-8.4,0.1c0,0-1.8,0-4.1,0.1c0,0-20.1,1-42.8,7.7
		c0,0-23.8,6.3-62.7,24.6c0,0-4.3,1.5-5.1,4.1c0,0-0.6,1.7,2.3,3.3l7.6,3.2c0,0,4,1.9,12.8,3.2c0,0,3.7,0.7,13.3,1l14.6,0.7
		L330.8,251.7z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M179.4,318.8
		l-17.6-11.7L131,304c0,0-0.3-4.1-0.3-9.5s0.1-19.1,1.1-28.9c0,0,0.7-11.6,13.3-20.2l28.5-13.8l19.7,11.6c0,0,18,11.8,34.8,12.7
		l119,5.6c0,0-2.8,7-2.9,12.2c0,0-0.4,17.6,1.7,47.5c0,0,0.8,13.1,2.6,21.3c0,0,2.1,10.7,3.2,14.6l-15.8,12.1l-45,1.2L275,386.1
		c0,0,0-20.2-5-34.9c0,0-4.1-22.3-24.8-35.6c0,0-12.7-9.4-31-9.2C214.3,306.4,196.6,305.2,179.4,318.8z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="625.9"
              cy="363.4"
              r="50.6"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="214.2"
              cy="363.4"
              r="50.6"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback2DrRight);
