import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateItem } from '../../../../../../../actions';
import Button from '../../../../ui/buttons';
import InputField from '../../../../ui/inputField';
import TextArea from '../../../../ui/textArea';
import Icon from '../../../../ui/icons';
import { CYAN_01 } from '../../../../../constants/colors';
import { AxleProperties } from '../../../../../constants/common';

const propertyList = Object.keys(AxleProperties);
class Axle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      data: {},
      hasChanged: false,
    };
  }

  componentDidMount() {
    const { selectedAxle } = this.props;

    if (selectedAxle) {
      this.setState({
        title: selectedAxle.title,
        data: selectedAxle.data,
      });
    }
  }

  handleChange = (text, item) => {
    this.setState(prevState => ({
      hasChanged: true,
      data: {
        ...prevState.data,
        [item]: text,
      },
    }));
  };

  updateAxle = () => {
    const { title, data } = this.state;
    const axles = this.props.item.axles.map(axle => {
      return axle.title === title ? { title, data } : axle;
    });

    this.props.updateItem({ axles });
    this.props.goBack();
  };

  render() {
    const { selectedAxle, goBack } = this.props;

    return (
      <div>
        <div className="form-header form-sub-section">
          <div onClick={() => goBack()}>
            <Icon name="LeftArrow" size={20} color={CYAN_01} />
            Select Axle
          </div>
        </div>
        <div className="form-body-container w-100">
          <div className="form-body form-body-sub">
            <div className="form-sub-section-title">
              <div>Add Axle</div>
            </div>
            <div className="form-body-sub-item">
              <span>{selectedAxle.title}</span>
              <div>
                {propertyList.map((item, index) => {
                  return (
                    <div key={index}>
                      {item !== 'notes' && (
                        <InputField
                          title={AxleProperties[item]}
                          placeholder={AxleProperties[item]}
                          onChangeHandler={e =>
                            this.handleChange(e.target.value, item)
                          }
                          value={this.state.data[item]}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <TextArea
              title="Note"
              placeholder="Enter note"
              onChangeHandler={e => this.handleChange(e.target.value, 'notes')}
              value={this.state.data['notes']}
            />
            <div className="form-sub-section-save-button">
              <Button
                btnText="Save"
                height="42px"
                outlined={!this.state.hasChanged}
                iconName="Save"
                iconSize={22}
                iconOnRight
                marginLeft="18px"
                onClickHandler={() => this.updateAxle()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Axle.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { item } = state.core;
  return { item };
};

export default connect(
  mapStateToProps,
  { updateItem }
)(Axle);
