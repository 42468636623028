import React, { Component } from 'react';
import { connect } from 'react-redux';

class Wagon4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M420.4,168.5h-2.2c-35.5,0.1-66,3.7-76.6,6.1c-2.2,0.6-2.5,1.1-3,1.2c-0.4,0-0.8-0.4-1.5-0.4
	c-1.2,0-1.8,0.4-1.9,0.4c-0.5,0-0.7-0.1-0.8-0.4c-0.1-0.1-0.4-0.3-1.1-0.1c-1.9,0.4-7,3.4-9.6,7.3c-9.5,14.2-17.9,37.1-22.4,53.8
	c-0.3,0.1-0.4,0.3-0.5,0.6c-0.3,0.7-1.8,6.1-2.5,9.4c-0.1,0.4-0.4,1-1,1s-1.1-0.6-2.2-0.6c-0.5,0-1-0.1-1.4-0.4
	c-0.1-2.6-0.4-5.9-0.7-8.9c-0.4-5.8-3.6-5.6-13.2-5.6c-5.2,0-12.2,0-13.9,1.5c-1.5,1.5-3,6.2-3.3,10.9c-1.5,0.8-0.5,4.3,0,4.4
	c0,1.2,0.1,2.3,0.4,3.2c0.1,0.4,0.5,0.7,0.7,1.1c0,0,3.4,0.3,7.4,0.4c-3.9-0.3-7.4-0.4-7.4-0.4c0.1,0.4-0.1,0.7,0,1
	c1,1.9,2.6,2.3,5.1,2.9c4.4,0.7,14.6,1.2,17.2,1.4c1.2,0,1.9-0.4,2.5-0.8h0.3v2.5c-0.1,0.1-0.1,0.1-0.1,0.3c-0.5,1-1,2.3-1.4,4
	c-0.4,1.9-0.7,4.1-1,6.2c-0.3,2.1-0.3,4.1-0.4,5.6v0.7c0-0.1,0.1-0.1,0.1-0.3c0,0.1-0.1,0.1-0.1,0.3c0,0.8-0.1,1.4-0.1,1.5
	c-0.3,1-3.4,5.4-4.5,9.5c-1.2,4.4-1,10.9-1,14.7v0.6c0,0.3,0.3,0.8,0.3,1.2c0,0.3-0.3,0.4-0.3,0.8c0,19.1,2.1,35.5,4.1,51
	c0.1,1.4,0.4,2.9,0.5,4.3l1.4-0.8l-1.4,0.8c0,0.3,0,0.4,0.1,0.7c0,0.3,0.4,0.5,0.4,0.7c0,0.3-0.4,0.4-0.4,0.7
	c0.1,3.2,2.8,13.6,3.4,13.8c0.4,0.1,2.6,0.5,4.7,0.8c0.8,0.1,1.6,0.3,2.3,0.3c2.5,0.3,3.2-1.4,6.5-1.5c2.5-0.1,10.3-0.4,17.9-0.5
	c2.9,0,5.8-0.1,8.1-0.1c3.3,0,7.6,0.1,12.8,0.4c5.1,0.3,10.9,0.4,17.2,0.7c1,0,1.2-0.8,2.3-1.2c1.9-0.5,4.4-0.7,6.3-0.7
	c0.1,0,0.3,0.1,0.5,0.3c0.5,0.5,1.1,1.8,1.6,1.8h17.1c0.1,0,0.1,0,0.3-0.1c0.4-0.3,0.8-0.8,1.2-1.1c0.1,0,0.3-0.1,0.3-0.1h8.4
	c0.1,0,0.3,0,0.3,0.1c0.4,0.3,0.8,0.8,1.2,1.1c0.1,0,0.1,0.1,0.3,0.1h17.2c0.1,0,0.3,0,0.3-0.1c0.4-0.3,0.8-1.1,1.2-1.5
	c0.1-0.1,0.3-0.3,0.4-0.3h8.5c0.1,0,0.3,0.1,0.4,0.3c0.4,0.4,0.8,1.2,1.2,1.5c0.1,0.1,0.3,0.1,0.3,0.1h17.2c0.1,0,0.1,0,0.3-0.1
	c0.4-0.3,0.8-0.8,1.2-1.1c0.1,0,0.3-0.1,0.3-0.1h8.4c0.1,0,0.3,0,0.3,0.1c0.4,0.3,0.8,0.8,1.2,1.1c0.1,0,0.1,0.1,0.3,0.1h17.1
	c0.5,0,1.1-1.2,1.6-1.8c0.1-0.1,0.3-0.3,0.5-0.3c1.9,0,4.5,0.1,6.3,0.7c1,0.3,1.4,1.2,2.3,1.2c6.3-0.1,12.1-0.4,17.2-0.7
	c5.2-0.3,9.5-0.4,12.8-0.4c2.5,0,5.2,0,8.1,0.1c7.6,0.1,15.4,0.4,17.9,0.5c3.4,0.1,4,1.8,6.6,1.5c0.7,0,1.5-0.1,2.3-0.3
	c2.1-0.3,4.3-0.7,4.7-0.8c0.7-0.1,3.2-10.6,3.4-13.8c0-0.1-0.4-0.4-0.4-0.7c0-0.3,0.4-0.5,0.4-0.7c0-0.3,0-0.4,0.1-0.7l-1.4-0.8
	l1.4,0.8c0.1-1.4,0.4-2.9,0.5-4.3c2.1-15.5,4.1-32.1,4.1-51c0-0.3-0.3-0.5-0.3-0.8c0-0.4,0.3-0.8,0.3-1.2V302c0-3.9,0.3-10.5-1-14.7
	c-1.1-4.1-4.3-8.5-4.5-9.5c0-0.1-0.1-0.7-0.1-1.5c0-0.1-0.1-0.1-0.1-0.3c0,0.1,0.1,0.1,0.1,0.3v-0.7c-0.1-1.5-0.3-3.4-0.4-5.6
	c-0.3-2.1-0.5-4.3-1-6.2c-0.4-1.6-0.8-3-1.4-4c0-0.1-0.1-0.1-0.1-0.3v-2.5h0.3c0.5,0.5,1.2,1,2.5,0.8c2.5-0.1,12.8-0.7,17.2-1.4
	c2.5-0.4,4.1-1,5.1-2.9c0.1-0.3-0.1-0.6,0-1c0,0-3.4,0.3-7.4,0.4c3.9-0.3,7.4-0.4,7.4-0.4c0.1-0.3,0.5-0.7,0.7-1.1
	c0.1-0.8,0.3-1.9,0.4-3.2c0.5-0.1,1.5-3.6,0-4.4c-0.3-4.7-1.7-9.4-3.3-10.9c-1.8-1.6-8.7-1.5-13.9-1.5c-9.6,0-12.8-0.1-13.2,5.6
	c-0.3,3-0.5,6.3-0.7,8.9c-0.4,0.3-0.8,0.4-1.4,0.4c-1.1,0-1.7,0.6-2.2,0.6c-0.6,0-1-0.6-1-1c-0.7-3.3-2.2-8.7-2.5-9.4
	c-0.1-0.3-0.3-0.4-0.6-0.6c-4.5-16.5-12.9-39.5-22.4-53.8c-2.6-3.9-7.7-7-9.6-7.3c-0.7-0.1-0.8,0-1.1,0.1c-0.1,0.1-0.4,0.4-0.8,0.4
	c-0.1,0-0.7-0.4-1.9-0.4c-0.7,0-1.1,0.4-1.5,0.4c-0.5,0-0.8-0.7-3-1.2C490.3,172.2,456,168.7,420.4,168.5L420.4,168.5z"
          />
          <path
            fill="#5E5E5E"
            d="M289.3,359l-4.3,2.3c0,0,5.2,0.3,11,0.7c0.8,0,1.6,0.1,2.6,0.1h0.4c0.7,0,1.4,0.1,2.1,0.1
	c3.2,0.3,3.9,1.7,8,1.5c4.1-0.1,4.1-1,8.1-1c13.1,0.1,37.7,0.4,38.8,0.4c0.3,0,0.4,0,0.7-0.1v-0.4l-1.9-16.6c0-0.4-0.1-1-0.1-1.4
	h-0.1c-1,0-47-4.4-52.7-4.8c-5.8-0.3-7.4,2.5-8.3,5c-1.5,4.8-3,14.2-3,14.2c-0.3-0.1-0.5-0.1-0.7-0.3
	C289.6,358.9,289.4,358.9,289.3,359L289.3,359z"
          />
          <path
            fill="#EAEBEB"
            d="M329.7,197.2c-4.7,4.8-15.1,40-15.1,46.8c0,2.9,0.7,5.2,6.9,5.2s34-1.5,99.6-1.5c65.6,0,93.3,1.5,99.6,1.5
	s6.9-2.3,6.9-5.2c0-6.7-10.5-42-15.1-46.8c-2.3-2.5-16-4.4-49.5-4.4c-9.2,0-7.6,7.2-19.1,7.2c-7.6,0-14.4-0.3-22.8-0.3
	c-8.4,0-15.3,0.3-22.8,0.3c-11.6,0-9.9-7.2-19.1-7.2C345.7,192.8,332.2,194.7,329.7,197.2L329.7,197.2z M261.6,246.1
	c0,0.6,0,1.1,0.1,1.4c0.1,0.4,0.3,0.7,0.4,0.8c0,0,0.1,0,0.1,0.1c0.3,0.1,0.4,0.1,0.8,0.3c1.4,0,5.4,0.1,7,0.1
	c1.6,0,4.5-1.9,5.2-2.6c0.7-0.7,1-1.2,0-1.4c-1.2-0.3-10-0.6-11.8-0.7h-1.2c-0.3,0.1-0.4,0.4-0.5,0.7
	C261.6,245.1,261.6,245.5,261.6,246.1L261.6,246.1z M314.2,199.8c-5.1,11-9.4,23.4-12.4,33.7c-0.3,1-0.5,1.8-0.8,2.8
	c0.1,0,0.3,0,0.5-0.1h1.2c4.7-16.2,12-38,20.8-53.9c0,0.1-0.1,0.1-0.1,0.3C320.2,187.6,317.1,193.5,314.2,199.8L314.2,199.8z"
          />
          <path
            fill="#5E5E5E"
            d="M294.8,246.7c-0.3,1.1-0.8,4.1-1,4.8c-0.1,1-0.4,1.2-1.6,1.5c-1.2,0.3-4.7,0.4-6.2,0.7
	c-1.7,0.1-1.5,0.6-1.5,1.8c0,1.1,0.3,1.5,1,1.5h9.5c0.7,0,1.1,0.3,1.8,1.6c0.3-0.7,0.5-2.1,1.1-4c1.1-4.1,2.8-10.7,5.1-18.4
	l-1.8,0.1c-0.3,0.1-0.4,0.3-0.5,0.6c-0.3,0.7-1.8,6.1-2.5,9.4c-0.1,0.4-0.4,1-1,1C296.5,247.2,295.9,246.7,294.8,246.7L294.8,246.7z
	 M284.1,270.4c-0.8,0.8-1.2,4-1,4.4c0.3,0.3,1.6,0.8,2.2,0.7c0.1-1.5,0.3-3.4,0.4-5.6C285.6,269.7,284.9,269.7,284.1,270.4
	L284.1,270.4z M284.9,265.9c-0.4,1.2-0.1,2.1,0.4,2.6c0.1,0.1,0.4,0.3,0.7,0.5c0.1-1.6,0.4-3.3,0.8-5v-0.3
	C286.3,263.8,285.4,264.2,284.9,265.9L284.9,265.9z"
          />
          <path
            fill="#EAEBEB"
            d="M296.3,280.4c-1.2,4-2.1,8.3-2.2,12.1v0.7c0,5.4,5.6,11.7,19.5,14c0.3,0,0.4,0,0.7-0.1
	c1.2-0.4,3-2.1,4.4-2.1c1.6,0,3.9,0.5,10.5,1.7c9.6,1.5,20.8,3.3,22.7,3.3s2.5-1.2,1.6-3.2s-3.2-8.1-5.2-11.3
	c-2.1-3.2-2.9-5.8-9.6-8.4c-16-6.2-23.2-20.4-32.3-21h-0.5C302.6,266.3,298.8,272.9,296.3,280.4L296.3,280.4z"
          />
          <path
            fill="#5E5E5E"
            d="M364,290.1c-4.8,0-5.1,1-4.3,2.9c0.3,0.5,1,2.1,1.9,4c0.4,0.8,0.8,1.6,1.2,2.5c0.7,1.5,1.5,3,2.3,4.7
	c0.4,0.7,0.7,1.4,1.1,2.1c0.8,1.5,1.6,2.9,2.2,4c0.4,0.8,0.8,1.4,1.1,1.7c1.6,1.9,1.8,2.2,4.7,2.6c2.9,0.4,32.1,3.2,38.8,3.2h16.2
	c6.7,0,35.8-2.6,38.8-3.2c2.9-0.4,3-0.8,4.7-2.6c0.3-0.3,0.7-0.8,1.1-1.7c2.6-4.4,8-15.1,8.8-17.1c0.8-1.9,0.5-2.9-4.3-2.9
	s-34.3,1.8-57.2,1.8C398.2,291.9,368.8,290.1,364,290.1z M283.8,351.4v58.7c0,5.1,4.3,9.4,9.4,9.4h16.9c5.1,0,9.4-4.3,9.4-9.4V375
	l-17.9,0.4c-3.4,0.1-4,1.8-6.5,1.5c-0.7,0-1.5-0.1-2.3-0.3c-2.1-0.3-4.3-0.7-4.7-0.8c-0.7-0.1-3.2-10.6-3.4-13.8
	c0-0.1,0.4-0.4,0.4-0.7c0-0.1,0-0.1-0.1-0.3l-0.3-0.3c0-0.1-0.1-0.1-0.1-0.3v-0.4c-0.1-0.5,1.1-1,1.5-1.2l-1-8.9L283.8,351.4
	L283.8,351.4z"
          />
          <path
            fill="#EAEBEB"
            d="M302.6,343c-2.8,2.1-4.4,5.2-4.4,8.8c0,6.2,5,11.1,11.1,11.1s11.1-5,11.1-11.1c0-3-1.2-5.8-3.2-7.8
	C311.7,343.7,305.8,343.3,302.6,343L302.6,343z"
          />
          <path
            fill="#5E5E5E"
            d="M413.1,344.7c-26.4-0.1-50.6-0.5-51.2-0.5c-0.7,0-0.8,0.4-0.8,1l0.5,6.5c0.4,3.9,2.5,5.8,8.3,6.1
	c5.8,0.3,21.3,0.1,43.7,0.1C413.8,355.7,413.1,344.7,413.1,344.7L413.1,344.7z M480.5,344.1c-0.5,0-24.8,0.4-51.2,0.5
	c0,0-0.1,1.4-0.1,3.3c-0.1,3.4-0.4,8.4-0.5,9.8c22.6,0,38,0.1,43.7-0.1c5.8-0.3,7.8-2.2,8.3-6.1l0.5-6.5
	C481.2,344.5,481.1,344.1,480.5,344.1L480.5,344.1z M421.2,374.1H417c-0.1,0-0.3,0.1-0.4,0.3c-0.4,0.4-0.8,1.2-1.2,1.5
	c-0.1,0.1-0.3,0.1-0.3,0.1h-17.2c-0.1,0-0.1,0-0.3-0.1c-0.4-0.3-0.8-0.8-1.2-1.1c-0.1,0-0.3-0.1-0.3-0.1h-8.4c-0.1,0-0.3,0-0.3,0.1
	c-0.4,0.3-0.8,0.8-1.2,1.1c-0.1,0-0.1,0.1-0.3,0.1h-17.1c-0.5,0-1.1-1.2-1.6-1.8c-0.1-0.1-0.3-0.3-0.5-0.3c-1.9,0-4.5,0.1-6.3,0.7
	c-1,0.3-1.4,1.2-2.3,1.2c-6.3-0.1-12.1-0.4-17.2-0.7c0,5.2,2.3,4.1,2.3,6.7c0,1.8,0.1,2.2,2.1,2.2h2.6c0.8,0,1-1.5,2.6-1.5
	s2.9,0.1,5.4,0.1s6.1,2.5,8.1,2.5h7.2c2.5,0,4.8-0.6,8.9-0.6l1.5-0.3c0,0,5.5,0.1,7.3,0.1c2.6,0,1.2,1.5,2.6,1.5h60
	c1.5,0,0.1-1.5,2.8-1.5c1.6,0,7.3-0.1,7.3-0.1l1.5,0.3c4.1,0,6.5,0.5,8.9,0.5h7.2c2.1,0,5.6-2.5,8.1-2.5s3.9-0.1,5.4-0.1
	c1.6,0,1.8,1.5,2.6,1.5h2.6c2.1,0,2.1-0.4,2.1-2.2c0-2.5,2.2-1.9,2.3-6.7c-5.1,0.3-10.9,0.4-17.2,0.7c-1,0-1.2-0.8-2.3-1.2
	c-1.9-0.5-4.4-0.7-6.3-0.7c-0.1,0-0.3,0.1-0.5,0.3c-0.6,0.5-1.1,1.8-1.6,1.8H457c-0.5,0-1.1-0.8-1.5-1.1c-0.1,0-0.3-0.1-0.3-0.1
	h-8.4c-0.1,0-0.3,0-0.3,0.1c-0.4,0.3-0.8,0.8-1.2,1.1c-0.1,0-0.1,0.1-0.3,0.1h-17.2c-0.4,0-1.1-1.1-1.5-1.6
	c-0.1-0.1-0.3-0.3-0.4-0.3L421.2,374.1L421.2,374.1z M553.1,359l4.3,2.3c0,0-5.2,0.3-11,0.7c-0.8,0-1.7,0.1-2.5,0.1h-0.4
	c-0.7,0-1.4,0.1-2.1,0.1c-3.2,0.3-3.9,1.7-8,1.5c-4.1-0.1-4.1-1-8.1-1c-13.1,0.1-37.7,0.4-38.8,0.4c-0.3,0-0.4,0-0.7-0.1v-0.4
	l1.9-16.6c0-0.4,0.1-1,0.1-1.4h0.1c1,0,47-4.4,52.7-4.8c5.8-0.3,7.4,2.5,8.3,5c1.5,4.8,3,14.2,3,14.2c0.3-0.1,0.5-0.1,0.7-0.3
	C552.9,358.9,553,358.9,553.1,359L553.1,359z"
          />
          <path
            fill="#EAEBEB"
            d="M580.8,246.1c0,0.6,0,1.1-0.1,1.4c-0.1,0.4-0.3,0.7-0.4,0.8c0,0-0.1,0-0.1,0.1c-0.3,0.1-0.4,0.1-0.8,0.3
	c-1.4,0-5.4,0.1-7,0.1s-4.5-1.9-5.2-2.6c-0.7-0.7-1-1.2,0-1.4c1.2-0.3,10-0.6,11.8-0.7h1.2c0.3,0.1,0.4,0.4,0.5,0.7
	C580.8,245.1,580.8,245.5,580.8,246.1L580.8,246.1z M528.2,199.8c5.1,11,9.4,23.4,12.4,33.7c0.3,1,0.6,1.8,0.8,2.8
	c-0.1,0-0.3,0-0.5-0.1h-1.2c-4.7-16.2-12-38-20.8-53.9c0,0.1,0.1,0.1,0.1,0.3C522.2,187.6,525.4,193.5,528.2,199.8L528.2,199.8z"
          />
          <path
            fill="#5E5E5E"
            d="M547.6,246.7c0.3,1.1,0.8,4.1,1,4.8c0.1,1,0.5,1.2,1.7,1.5c1.2,0.3,4.7,0.4,6.2,0.7c1.7,0.1,1.5,0.6,1.5,1.8
	c0,1.1-0.3,1.5-1,1.5h-9.5h-0.1c-0.7,0-1.1,0.3-1.8,1.6c-0.3-0.7-0.5-2.1-1.1-4c-1.1-4.1-2.8-10.7-5.1-18.4l1.8,0.1
	c0.3,0.1,0.4,0.3,0.5,0.6c0.3,0.7,1.8,6.1,2.5,9.4c0.1,0.4,0.4,1,1,1C546,247.2,546.5,246.7,547.6,246.7L547.6,246.7z M558.4,270.4
	c0.8,0.8,1.2,4,1,4.4c-0.3,0.3-1.7,0.8-2.2,0.7c-0.1-1.5-0.3-3.4-0.4-5.6C556.9,269.7,557.5,269.7,558.4,270.4L558.4,270.4z
	 M557.5,265.9c0.4,1.2,0.1,2.1-0.4,2.6c-0.1,0.1-0.4,0.3-0.7,0.5c-0.1-1.6-0.4-3.3-0.8-5v-0.3C556.2,263.8,557,264.2,557.5,265.9z"
          />
          <path
            fill="#EAEBEB"
            d="M546,280.4c1.2,4,2.1,8.3,2.2,12.1v0.7c0,5.4-5.6,11.7-19.5,14c-0.3,0-0.4,0-0.7-0.1c-1.2-0.4-3-2.1-4.4-2.1
	c-1.7,0-3.9,0.5-10.5,1.7c-9.6,1.5-20.8,3.3-22.7,3.3s-2.5-1.2-1.6-3.2c0.8-1.9,3.2-8.1,5.2-11.3c2.1-3.2,2.9-5.8,9.6-8.4
	c16-6.2,23.2-20.4,32.3-21h0.5C539.8,266.3,543.7,272.9,546,280.4L546,280.4z"
          />
          <path
            fill="#5E5E5E"
            d="M558.5,351.4v58.7c0,5.1-4.3,9.4-9.4,9.4h-16.8c-5.1,0-9.4-4.3-9.4-9.4V375l17.9,0.4c3.4,0.1,4,1.8,6.6,1.5
	c0.7,0,1.5-0.1,2.3-0.3c2.1-0.3,4.3-0.7,4.7-0.8c0.7-0.1,3.2-10.6,3.4-13.8c0-0.1-0.4-0.4-0.4-0.7c0-0.1,0-0.1,0.1-0.3l0.3-0.3
	c0-0.1,0.1-0.1,0.1-0.3v-0.4c0-0.5-1.1-1-1.5-1.2l1-8.9L558.5,351.4L558.5,351.4z"
          />
          <path
            fill="#EAEBEB"
            d="M539.8,343c2.8,2.1,4.4,5.2,4.4,8.8c0,6.2-5,11.1-11.1,11.1c-6.2,0-11.1-5-11.1-11.1c0-3,1.2-5.8,3.2-7.8
	C530.7,343.7,536.5,343.3,539.8,343L539.8,343z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M508.2,175.5
	c2.5,2.3,6.7,8.9,9.9,15.7c8.7,18.6,17.6,48.1,17.6,56.9c0,2.5-1.1,3.7-5.6,3.7c-25.2,0-70.8-1.2-108.8-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M503.9,175.8
	c2.2,2.1,7.7,12.7,8.9,15.3c0.3,0.7,1.4,0.8,2.3,1c1,0,1.7-0.1,1.4-0.8c-2.3-5.5-6.9-13.1-9.2-15.3 M421.2,199.7
	c8.4,0,15.3,0.3,22.8,0.3c11.6,0,9.9-7.2,19.1-7.2c33.6,0,47.2,1.9,49.5,4.4c4.7,4.8,15.1,40,15.1,46.8c0,2.9-0.7,5.2-6.9,5.2
	c-6.3,0-34-1.5-99.6-1.5 M518.9,182.6c15.4,27,25,70.2,26.7,76.1c2.9,10,7.7,6.2,8.8,13.9c0,0.3,0,0.4,0.1,0.7 M536.4,266.3
	c5.2,0,11.8,15.8,11.8,27c0,5.4-5.6,11.7-19.5,14c-1.2,0.3-3.4-2.2-5.2-2.2c-1.7,0-3.8,0.5-10.5,1.7c-9.6,1.5-20.8,3.3-22.7,3.3
	s-2.5-1.2-1.6-3.2c0.8-1.9,3.2-8.1,5.2-11.3c2.1-3.2,2.9-5.8,9.6-8.4C519.7,281,527,266.3,536.4,266.3L536.4,266.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M513.2,296.7
	c-0.5-3.7-2.2-6.1-3.4-6.9c-1.4-0.8-9.1,3-10.6,4.8c-1.8,2.2-5.4,9.9-4.8,10.7c0.5,1,16.5-0.1,17.7-1.1
	C513.3,303.8,513.8,300.3,513.2,296.7L513.2,296.7z M538.6,278.9c0,2.5-2.1,4.5-4.5,4.5s-4.5-2.1-4.5-4.5s2.1-4.5,4.5-4.5
	S538.6,276.5,538.6,278.9z M540.6,293.6c0,5.5-4.5,10-10,10c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10
	C536.1,283.6,540.6,288,540.6,293.6L540.6,293.6z M421.2,288.4c23,0,52.4-0.8,57.2-0.8s6.5,1.6,6.5,4.1c0,2.1-8.3,19.3-9.9,21.5
	s-1.8,2.5-4.7,3c-2.9,0.5-32,3.6-38.8,3.6h-10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,291.9
	c23,0,52.4-1.8,57.2-1.8s5.1,1,4.3,2.9c-1,2.2-8.3,16.8-9.9,18.7s-1.8,2.2-4.7,2.6c-2.9,0.4-32.1,3.2-38.8,3.2h-8.1 M547.9,292.1
	c3.2,5.1,5,9.8,5.6,11.7c0.6,1.4,1.2,2.3,2.2,1.9c1-0.4,6.6-1.9,6.6-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,321.7
	c34.1,0,68.1-1.9,69.7-2.2c1.5-0.3,0.8-0.3,2.2-0.4c1.4-0.3,37.6-4.8,46.5-8.7c6.2-2.6,7.8-10.3,8.9-17.1 M484.6,372.5
	c40.3,0.1,57.9,0.4,63,1.1c4,0.5,5.6,1.2,6.7,2.2 M550,376.6c-2.5,0.3-2.2-1-5.6-1.1c-3.3-0.1-17.3-1.1-26-1.1 M488.4,341.5
	c0.7-5.6,1.6-12.9,3.3-22.3 M476,362.3c5.1,0,8.4-1.5,10.2-6.9c0.7-2.1,1.2-5.5,1.9-10.7 M429.3,344.7c0,0-0.7,12.7-0.7,13.3
	s-5.1,1.1-7.3,1.1 M535.7,248c3-1.2,8,2.9,9.1,7.2 M546,280.4c0,0,3-2.6,5.4-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M535.8,266.4
	c1.7-6.7,2.9-8.5,3.7-10.3c0.7-1.6,0.4-1.8-0.7-2.3c-1.8-0.8-5.4-1.5-5.4-2.3c-1.7-20.5-10.5-43.5-17.1-58.3
	c-0.1-0.4-0.8-1.1-1.2-1.1 M421.2,188.6c43.5,0,84.2,2.8,94,3.4 M511,251.6c0.4,4.7-13.3,8.4-38.4,36 M484.4,293.5
	c0,0,0.4-0.4,1-0.4c0.5,0,8.7-0.3,9.5-0.3c0.8,0,0.5,0.4,0.5,0.4 M580.1,244.1c-0.3,0-0.7-0.1-1.1,0c-1.8,0-10.6,0.3-11.8,0.7
	c-1,0.3-0.7,0.7,0,1.4s3.4,2.6,5.2,2.6c1.7,0,5.6-0.1,7-0.1c0.3,0,0.5-0.1,0.8-0.1 M549,246.2c-0.1,1.2-0.1,2.2-0.1,2.9
	c-0.1,2.9,0.6,3.7,1.4,3.9 M539.7,236.3c0.8,0,1.4,0,1.7,0.1 M554.1,259.4v-2.5 M547.4,262.8v-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M554.4,256.9h2.6
	c0.7,0,1-0.3,1-1.5c0-1.1,0.1-1.5-1.5-1.8c-1.5-0.3-5.1-0.4-6.2-0.7c-1.2-0.3-1.5-0.6-1.7-1.5c-0.1-1-0.8-4.8-0.8-4.8 M545.7,258.7
	c0.7-1.4,1-1.7,1.8-1.7h3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M549,246.9
	c0.5,1.6,6.2,6.6,12.9,6.6c3.2,0,17.2-0.8,17.2-0.8 M556.7,275.5c0.4,0.4,2.2-0.3,2.5-0.7s0-3.6-1-4.4c-0.8-0.8-1.5-0.7-1.8-0.4
	 M555.6,263.8c0.5,0,1.4,0.4,1.9,2.1c0.5,1.6-0.3,2.8-1,3"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="533.1"
            cy="352"
            r="7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M539.8,343
	c2.8,2.1,4.4,5.2,4.4,8.8c0,6.2-5,11.1-11.1,11.1c-6.2,0-11.1-5-11.1-11.1c0-3,1.2-5.8,3.2-7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M542.1,342.9
	c2.8,2.2,4.5,5.4,4.5,8.9c0,6.6-5.9,12-13.3,12c-7.3,0-13.3-5.4-13.3-12c0-2.9,1.1-5.5,3-7.6 M409.1,298.9c0,3.3,5.4,5.9,12.1,5.9
	c6.6,0,12.1-2.6,12.1-5.9c0-3.2-5.4-5.9-12.1-5.9C414.6,293,409.1,295.6,409.1,298.9L409.1,298.9z M502,375.1c0,5.2-2.3,4.1-2.3,6.7
	c0,1.8-0.1,2.2-2.1,2.2H495c-0.8,0-1-1.5-2.6-1.5s-2.9,0.1-5.4,0.1c-2.5,0-6.1,2.5-8.1,2.5h-7.2c-2.5,0-4.8-0.5-8.9-0.5l-1.5-0.3
	c0,0-5.5,0.1-7.3,0.1c-2.6,0-1.2,1.5-2.6,1.5h-30"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,362.3h4.3
	c0.5,0,1.2,1.1,1.9,1.1h17.3c0.7,0,1.2-1.1,1.9-1.1h8.4c0.7,0,1.2,1.1,1.9,1.1h17.2c0.7,0,1.2-1.1,1.9-1.1h8c1.2,0,1.1,1.1,2.5,1.1
	c1.1,0,25.7-0.3,38.8-0.4c4,0,3.9,1,8.1,1c4.1,0,4.8-1.2,8-1.5c1.7-0.1,3.4-0.3,5.1-0.4c5.9-0.4,11-0.7,11-0.7 M557,276.3
	c-3.2-3.9-4-5-4.8-5.4c-0.8-0.4-1.1,0.7-1.1,1.6s3.2,21,4,33.3 M556,275.1l1,30.3 M561.8,287.3c0.4,32.3-5.2,71.8-5.2,71.8
	 M487.2,343.5c0,0,48.6-4.7,54.3-5c5.8-0.4,7.4,2.5,8.1,5.1c1.5,5,3,15.4,3,15.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M552,359.1
	c0,0-1.5-9.4-3-14.2c-0.8-2.6-2.5-5.4-8.3-5c-5.8,0.4-51.7,5-52.7,4.8c-0.4,0-0.8-0.7-0.8-1.4c0-0.8,0.5-1.8,1.1-1.9
	c1.1-0.1,48.3-4.5,53.9-5c5.6-0.4,7.3,2.6,8.1,5.2c0.8,2.9,1.8,8.7,2.6,13.5c0.1,1,0.4,1.9,1.5,2.5c1.1,0.7,3.6,2.2,3.6,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M543.8,362.2
	c0,0,3.2-1.8,4.7-2.5c0.8-0.4,1.7-0.1,2.6-0.3c0.7-0.1,1.7-0.7,2.1-0.4c1.1,0.5,4.3,2.3,4.3,2.3 M511.3,345.2c0,0,28.1-2.3,31-2.3
	c5.6,0,6.9,4.4,6.9,12.2c0,2.2-0.1,4.3-0.1,4.3 M496.1,347h25 M425.9,362.4v12 M427.1,363.4v12.5 M445,363.4v12.5 M446.3,362.4v12.4
	 M455.3,362.4v12.4 M456.6,363.4v12.5 M474.5,363.4v12.2 M475.7,362.4v11.7 M487.8,346.2l-3.4,29.4 M542.8,343c0,0,6.1,4.1,6.3,12.2
	 M546.5,352c0,0,0.6,5.5-3.2,10.3 M485.9,362.8l37.8-2.6 M428.5,357.8c22.6,0,38,0.1,43.7-0.1c5.8-0.3,7.8-2.2,8.3-6.1
	c0.3-2.6,0.4-5.1,0.5-6.5c0-0.5-0.1-1-0.8-1c-0.7,0-30,0.5-59.3,0.5 M421.2,348h7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,360
	c28.5,0,48.4-0.5,54.9-0.8c6.3-0.3,7.2-2.2,8.3-9.6 M472.9,315.4c0,0,44.6-4.5,55-8 M334.3,175.5c-2.5,2.3-6.7,8.9-9.9,15.7
	c-8.8,18.4-17.6,48-17.6,56.8c0,2.5,1.1,3.7,5.6,3.7c25.2,0,70.8-1.2,108.8-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M338.5,175.8
	c-2.2,2.1-7.7,12.7-8.9,15.3c-0.3,0.7-1.4,0.8-2.3,1c-1,0-1.6-0.1-1.4-0.8c2.3-5.5,6.9-13.1,9.2-15.3 M421.2,199.7
	c-8.4,0-15.3,0.3-22.8,0.3c-11.6,0-9.9-7.2-19.1-7.2c-33.6,0-47,1.9-49.5,4.4c-4.7,4.8-15.1,40-15.1,46.8c0,2.9,0.7,5.2,6.9,5.2
	s34-1.5,99.6-1.5 M323.5,182.6c-15.4,27-25,70-26.8,76.1c-2.9,10-7.7,6.2-8.8,13.9c0,0.3,0,0.4-0.1,0.7 M305.9,266.3
	c-5.2,0-11.8,15.8-11.8,27c0,5.4,5.6,11.7,19.5,14c1.2,0.3,3.4-2.2,5.2-2.2c1.6,0,3.9,0.5,10.5,1.7c9.6,1.5,20.8,3.3,22.7,3.3
	s2.5-1.2,1.6-3.2c-0.8-1.9-3.2-8.1-5.2-11.3c-2.1-3.2-2.9-5.8-9.6-8.4C322.7,281,315.4,266.3,305.9,266.3L305.9,266.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M329.1,296.7
	c0.5-3.7,2.2-6.1,3.4-6.9c1.4-0.8,9.1,3,10.6,4.8c1.8,2.2,5.4,9.9,4.8,10.7c-0.5,1-16.5-0.1-17.7-1.1
	C329.2,303.8,328.6,300.3,329.1,296.7L329.1,296.7z M303.9,278.9c0,2.5,2.1,4.5,4.5,4.5c2.5,0,4.5-2.1,4.5-4.5s-2.1-4.5-4.5-4.5
	S303.9,276.5,303.9,278.9z M301.8,293.6c0,5.5,4.5,10,10,10s10-4.5,10-10c0-5.5-4.5-10-10-10S301.8,288,301.8,293.6L301.8,293.6z
	 M421.2,288.4c-23,0-52.4-0.8-57.2-0.8c-4.8,0-6.5,1.6-6.5,4.1c0,2.1,8.3,19.3,9.9,21.5s1.8,2.5,4.7,3c2.9,0.5,32.1,3.6,38.8,3.6
	h10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,291.9
	c-23,0-52.4-1.8-57.2-1.8c-4.8,0-5.1,1-4.3,2.9c1,2.2,8.3,16.8,9.9,18.7c1.6,1.9,1.8,2.2,4.7,2.6c2.9,0.4,32.1,3.2,38.8,3.2h8.1
	 M294.5,292.1c-3.2,5.1-5,9.8-5.6,11.7c-0.5,1.4-1.2,2.3-2.2,1.9c-1-0.4-6.6-1.9-6.6-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,321.7
	c-34.1,0-68.1-1.9-69.7-2.2c-1.5-0.3-0.8-0.3-2.2-0.4c-1.4-0.3-37.6-4.8-46.5-8.7c-6.2-2.6-7.8-10.3-8.9-17.1 M357.7,372.5
	c-40.3,0.1-57.9,0.4-63,1.1c-4,0.5-5.6,1.2-6.7,2.2 M292.5,376.6c2.5,0.3,2.2-1,5.6-1.1c3.3-0.1,17.3-1.1,26-1.1 M354,341.5
	c-0.7-5.6-1.6-12.9-3.3-22.3 M366.5,362.3c-5.1,0-8.4-1.5-10.2-6.9c-0.7-2.1-1.2-5.5-1.9-10.7 M413.1,344.7c0,0,0.7,12.7,0.7,13.3
	s5.1,1.1,7.4,1.1 M306.8,248c-3-1.2-8,2.9-9.1,7.2 M296.3,280.4c0,0-2.9-2.6-5.4-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M306.5,266.4
	c-1.6-6.7-2.9-8.5-3.7-10.3c-0.7-1.6-0.4-1.8,0.7-2.3c1.8-0.8,5.4-1.5,5.4-2.3c1.7-20.5,10.5-43.5,16.9-58.3
	c0.1-0.4,0.8-1.1,1.2-1.1 M421.2,188.6c-43.5,0-84.2,2.8-94,3.4 M331.3,251.6c-0.4,4.7,13.3,8.4,38.4,36 M358.1,293.5
	c0,0-0.4-0.4-1-0.4s-8.7-0.3-9.5-0.3c-0.8,0-0.5,0.4-0.5,0.4 M262.3,244.1h1.1c1.8,0,10.6,0.3,11.8,0.7c1,0.3,0.7,0.7,0,1.4
	s-3.4,2.6-5.2,2.6c-1.6,0-5.6-0.1-7-0.1c-0.4,0-0.7-0.1-1-0.3 M293.4,246.2c0.1,1.2,0.1,2.2,0.1,3c0.1,2.9-0.5,3.7-1.4,3.9
	 M302.8,236.3h-1.1 M288.3,259.4v-2.5 M295.1,262.8v-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M288.1,256.9h-2.6
	c-0.7,0-1-0.3-1-1.5c0-1.1-0.1-1.5,1.5-1.8c1.5-0.3,5.1-0.4,6.2-0.7c1.1-0.3,1.5-0.6,1.6-1.5s0.8-4.8,0.8-4.8 M296.7,258.7
	c-0.7-1.4-1-1.7-1.8-1.7h-3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M293.4,246.9
	c-0.5,1.6-6.2,6.6-12.9,6.6c-3.2,0-17.2-0.8-17.2-0.8 M285.7,275.5c-0.4,0.4-2.2-0.3-2.5-0.7c-0.3-0.4,0-3.6,1-4.4
	c0.8-0.8,1.5-0.7,1.8-0.4 M286.8,263.8c-0.5,0-1.4,0.4-1.9,2.1s0.3,2.8,1,3 M302.2,352c0,4,3.2,7.2,7.2,7.2c3.9,0,7.2-3.2,7.2-7.2
	c0-3.9-3.2-7.2-7.2-7.2S302.2,348,302.2,352L302.2,352z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M302.6,343
	c-2.8,2.1-4.4,5.2-4.4,8.8c0,6.2,5,11.1,11.1,11.1s11.1-5,11.1-11.1c0-3-1.2-5.8-3.2-7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M300.3,342.9
	c-2.8,2.2-4.5,5.4-4.5,8.9c0,6.6,5.9,12,13.3,12c7.3,0,13.3-5.4,13.3-12c0-2.9-1.1-5.5-3-7.6 M340.5,375.1c0,5.2,2.3,4.1,2.3,6.7
	c0,1.8,0.1,2.2,2.1,2.2h2.6c0.8,0,1-1.5,2.6-1.5s2.9,0.1,5.4,0.1s6.1,2.5,8.1,2.5h7.2c2.5,0,4.8-0.5,8.9-0.5l1.5-0.3
	c0,0,5.5,0.1,7.3,0.1c2.6,0,1.2,1.5,2.6,1.5h30"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,362.3H417
	c-0.5,0-1.2,1.1-1.9,1.1h-17.3c-0.7,0-1.2-1.1-1.9-1.1h-8.4c-0.7,0-1.2,1.1-1.9,1.1h-17.2c-0.7,0-1.2-1.1-1.9-1.1h-8
	c-1.2,0-1.1,1.1-2.5,1.1c-1.1,0-25.7-0.3-38.8-0.4c-4,0-4,1-8.1,1c-4.1,0-4.8-1.2-8-1.5c-1.6-0.1-3.4-0.3-5.1-0.4
	c-5.9-0.4-11-0.7-11-0.7 M285.3,276.3c3-3.9,4-5,4.8-5.4c0.8-0.4,1.1,0.7,1.1,1.6s-3.2,21-4,33.3 M286.3,275.1l-0.8,30.3
	 M280.6,287.3c-0.4,32.3,5.2,71.8,5.2,71.8 M355.2,343.5c0,0-48.6-4.7-54.3-5c-5.8-0.4-7.4,2.5-8.1,5.1c-1.5,5-3,15.4-3,15.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M290.4,359.1
	c0,0,1.5-9.4,3-14.2c0.8-2.6,2.5-5.4,8.3-5c5.6,0.4,51.7,5,52.7,4.8c0.4,0,0.8-0.7,0.8-1.4c0-0.8-0.5-1.8-1.1-1.9
	c-1.1-0.1-48.3-4.5-53.9-5c-5.6-0.4-7.3,2.6-8.1,5.2c-0.8,2.9-1.8,8.7-2.6,13.5c-0.1,1-0.4,1.9-1.5,2.5c-1.1,0.7-3.6,2.2-3.6,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M298.6,362.2
	c0,0-3.2-1.8-4.7-2.5c-0.8-0.4-1.6-0.1-2.6-0.3c-0.7-0.1-1.6-0.7-2.1-0.4c-1.1,0.5-4.3,2.3-4.3,2.3 M331.1,345.2
	c0,0-28.1-2.3-31-2.3c-5.6,0-6.9,4.4-6.9,12.2c0,2.2,0.1,4.3,0.1,4.3 M346.4,347h-25.2 M416.5,362.4v12 M415.3,363.4v12.5
	 M397.4,363.4v12.5 M396.2,362.4v12.4 M387.1,362.4v12.4 M385.9,363.4v12.5 M368,363.4v12.2 M366.7,362.4v11.7 M354.6,346.2
	l3.4,29.4 M299.6,343c0,0-6.1,4.1-6.3,12.2 M295.8,352c0,0-0.5,5.5,3.2,10.3 M356.6,362.8l-37.8-2.6 M413.8,357.8
	c-22.6,0-38,0.1-43.7-0.1c-5.8-0.3-7.8-2.2-8.3-6.1c-0.3-2.6-0.4-5.1-0.5-6.5c0-0.5,0.1-1,0.8-1c0.7,0,30,0.5,59.3,0.5 M421.2,348
	h-7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,360
	c-28.5,0-48.4-0.5-54.9-0.8c-6.3-0.3-7.2-2.2-8.3-9.6 M369.5,315.4c0,0-44.6-4.5-55-8 M362.9,299.3c0,0,0.7-0.3,2.1,0
	c1.2,0.3,8.7,1.2,10.2,1.4c1.6,0.1-0.1,2.5,5.9,3c5.9,0.7,30.8,3.6,40.2,3.6 M366.3,306h1.9c0,0,7.3,1.1,9.5,1.5
	c2.2,0.4,1,2.2,5,2.9s31.1,2.3,38.5,2.3 M361.7,296.8c0,0,1.5,0.1,2.3,0.3c1.9,0.3,35.9,4.7,39.3,5.1c5.1,0.7,6.6,3,8.3,3.6
	c1.6,0.4,6.3,0.7,9.8,0.7 M365.2,304c0,0,1,0.1,2.2,0.3c1.2,0.1,37.4,5.2,53.8,5.2 M368.5,309.9c0,0,0.8,0.3,1.8,0.4
	c1.1,0.1,26.4,4.7,50.8,4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M479.5,299.3
	c0,0-0.7-0.3-2.1,0c-1.2,0.3-8.7,1.2-10.2,1.4c-1.6,0.1,0.1,2.5-5.9,3c-5.9,0.7-30.8,3.6-40.2,3.6 M476.1,306h-1.9
	c0,0-7.3,1.1-9.5,1.5c-2.2,0.4-1,2.2-5,2.9s-31.1,2.3-38.5,2.3 M480.8,296.8c0,0-1.5,0.1-2.3,0.3c-1.9,0.3-35.9,4.7-39.3,5.1
	c-5.1,0.7-6.6,3-8.3,3.6c-1.6,0.4-6.3,0.7-9.8,0.7 M477.2,304c0,0-1,0.1-2.2,0.3c-1.2,0.1-37.4,5.2-53.8,5.2 M473.9,309.9
	c0,0-0.8,0.3-1.8,0.4c-1.1,0.1-26.4,4.7-50.8,4.7 M551.1,256.9c1.5,1,2.8,1.8,3.3,2.8c2.5,4.3,2.6,17.1,2.9,18s3.4,5.4,4.5,9.5
	c1.2,4.4,1,10.9,1,14.7v0.5c0,0.3-0.3,0.8-0.3,1.2c0,0.3,0.3,0.4,0.3,0.8c0,21.2-2.5,39.1-4.7,56c0,0.3-0.4,0.5-0.5,0.7
	c0,0.3,0.4,0.4,0.4,0.7c-0.3,3.2-2.8,13.6-3.4,13.8c-0.7,0.1-4.5,0.8-7,1.1s-3.2-1.4-6.5-1.5c-3.3-0.1-17.3-0.7-26-0.7
	c-6.3,0-17.1,0.7-30,1c-1,0-1.2-0.8-2.3-1.2c-1.9-0.5-4.4-0.7-6.3-0.7c-0.7,0-1.5,2.1-2.2,2.1h-17.1c-0.7,0-1.2-1.2-1.9-1.2h-8.4
	c-0.7,0-1.2,1.2-1.9,1.2h-17.2c-0.5,0-1.4-1.9-2.1-1.9h-4.3 M421.2,168.5c36.7,0,68.8,3.6,79.6,6.1c2.2,0.6,2.5,1.1,3,1.2
	c0.4,0,0.8-0.4,1.5-0.4c1.2,0,1.8,0.4,1.9,0.4c1,0,0.7-0.8,1.9-0.6c1.9,0.4,7,3.4,9.6,7.3c9.5,14.2,17.9,37.1,22.4,53.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M558.5,355.7v54.5
	c0,5.1-4.3,9.4-9.4,9.4h-16.8c-5.1,0-9.4-4.3-9.4-9.4v-35.4 M580.1,248.5c0.5-0.3,0.7-1.1,0.7-2.3s0-1.8-0.7-1.9 M580.1,244.1
	c-0.3-4.7-1.7-9.4-3.3-10.9c-1.8-1.6-8.7-1.5-13.9-1.5c-9.6,0-12.8-0.1-13.2,5.6c-0.3,3-0.5,6.3-0.7,8.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M554.2,256.9
	c0.5,0.5,1.2,1,2.6,1c2.5-0.1,12.8-0.7,17.2-1.4c2.5-0.4,4.1-1,5.1-2.9c0.1-0.3-0.1-0.6,0-1c0.1-0.3,0.5-0.7,0.7-1.1
	c0.1-0.8,0.3-1.9,0.4-3.2 M541.3,236.4c0.3,0.1,0.4,0.3,0.5,0.6c0.3,0.7,1.8,6.1,2.5,9.4c0.1,0.4,0.4,1,1.1,1c0.5,0,1.1-0.4,2.2-0.4
	c0.6,0,1-0.3,1.4-0.4 M551.1,256.9h3.3 M291.4,256.9c-1.5,1-2.8,1.8-3.3,2.8c-2.5,4.3-2.6,17.1-2.9,18c-0.3,1-3.4,5.4-4.5,9.5
	c-1.2,4.4-1,10.9-1,14.7v0.5c0,0.3,0.3,0.8,0.3,1.2c0,0.3-0.3,0.4-0.3,0.8c0,21.2,2.5,39.1,4.7,56c0,0.3,0.4,0.5,0.4,0.7
	c0,0.3-0.4,0.4-0.4,0.7c0.1,3.2,2.8,13.6,3.4,13.8c0.7,0.1,4.5,0.8,7,1.1s3.2-1.4,6.5-1.5c3.3-0.1,17.3-0.7,26-0.7
	c6.3,0,17.1,0.7,30,1c1,0,1.2-0.8,2.3-1.2c1.9-0.5,4.4-0.7,6.3-0.7c0.7,0,1.5,2.1,2.2,2.1h17.1c0.7,0,1.2-1.2,1.9-1.2h8.4
	c0.7,0,1.2,1.2,1.9,1.2h17.2c0.5,0,1.4-1.9,2.1-1.9h4.3 M421.2,168.5c-36.7,0-68.8,3.6-79.6,6.1c-2.2,0.6-2.5,1.1-3,1.2
	c-0.4,0-0.8-0.4-1.5-0.4c-1.2,0-1.8,0.4-1.9,0.4c-1,0-0.7-0.8-1.9-0.6c-1.9,0.4-7,3.4-9.6,7.3c-9.5,14.2-17.9,37.1-22.4,53.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M283.8,355.7v54.5
	c0,5.1,4.3,9.4,9.4,9.4h16.9c5.1,0,9.4-4.3,9.4-9.4v-35.4 M262.1,248.4c-0.4-0.4-0.5-1.1-0.5-2.3s0-1.8,0.8-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M262.2,244.1
	c0.3-4.7,1.6-9.4,3.3-10.9c1.8-1.6,8.7-1.5,13.9-1.5c9.6,0,12.8-0.1,13.2,5.6c0.3,3,0.5,6.3,0.7,8.9 M288.1,256.9
	c-0.5,0.5-1.2,1-2.6,1c-2.5-0.1-12.8-0.7-17.2-1.4c-2.5-0.4-4.1-1-5.1-2.9c-0.1-0.3,0.1-0.6,0-1c-0.1-0.3-0.5-0.7-0.7-1.1
	c-0.1-0.8-0.3-1.9-0.4-3.2 M301.7,236.3c-0.5,0-1,0.1-1.1,0.6c-0.3,0.7-1.8,6.1-2.5,9.4c-0.1,0.4-0.4,1-1,1s-1.1-0.6-2.3-0.6
	c-0.5,0-1-0.3-1.4-0.4 M291.4,256.9h-3.3 M359.7,292.8c0,0,0.3-0.8,1.8-0.7c1.6,0.1,7.7,0.7,9.5,0.8c1.6,0.3,1,1.4,3.3,1.6
	c2.1,0.3,23.4,1.4,30.8,1.7c1.5,0.1,3.7,4.8,6.9,6.2 M482.7,292.8c0,0-0.3-0.8-1.8-0.7c-1.7,0.1-7.7,0.7-9.5,0.8
	c-1.6,0.3-1,1.4-3.3,1.6c-2.1,0.3-23.4,1.4-30.8,1.7c-1.5,0.1-3.7,4.8-6.9,6.2"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M309,251.4
		c0,0,0.8-14.2,8-35.5c0,0,5.9-16.5,8.9-23c0,0,0.4-1.1,1.5-1.1c0,0,42.2-3.4,95.9-3.4c0,0,48.7,0.3,90.8,3.4h1.1c0,0,0.7,0,1.2,1.1
		c0,0,14.6,31.2,17.1,58.3c0,0-1.9,0.4-2.8,0.4l-107.4-1.2l-111.4,1.4C312,251.8,309.9,251.8,309,251.4z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M309,251.4c0,0,1,0.6,3.7,0.4
		l110.7-1.4l107.2,1.2c0,0,1.4,0.1,3-0.4s0,0.1,0,0.1s0.3,0.4,1.2,0.7c1,0.3,3,1,3,1l1.5,0.7c0,0,1,0.3,0.3,1.5
		c0,0-2.9,6.2-3.9,10.9c0,0-3.7-0.1-8.8,4.3l-7.8,6.7c0,0-4.3,3.7-8.9,6.6c0,0-4,2.3-7.6,3.7c0,0-5,1.7-7.7,6.2c0,0,0.5-1-0.3-1
		l-9.4,0.3c0,0-0.8-0.1-1.1,0.7c0,0,1.1-1.9,0.3-3.7c0,0-1-2.6-5.9-2.5c0,0-71.7,1.7-109.1,0.1l-5.6-0.1c0,0-4-0.3-5.8,1.8
		c0,0-1.5,1.5,0,4.4c0,0,0-0.7-1.1-0.7l-9.4-0.3c0,0-1,0-0.4,0.8c0,0-1.8-3.9-7.6-6.1c-5.8-2.2-10.3-5.4-11-5.9s-4.5-3.4-10.6-8.8
		c0,0-6.7-6.5-11.6-6.6c0,0-1.2-5.6-3.2-9.4c0,0-0.5-1.1-0.7-1.8c-0.1-0.7,0.1-1.1,0.7-1.4c0,0,1.9-0.8,3.9-1.4
		C307,252.4,308.7,252,309,251.4z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M369.2,311c0,0-2.9-4.5-9.4-18
		c0,0-0.8-1.4,0-2.1c0.8-0.7,2.8-0.7,5.1-0.7c0,0,34.7,1.8,56.8,1.8c0,0,10.7,0.3,54.5-1.8c0,0,1.8-0.1,3.3,0
		c1.5,0.1,3.4,0.1,3.6,1.4c0,0,0.3,0.5-0.8,2.5c0,0-4.5,9.9-8.9,17.2c0,0-1.5,2.2-2.8,2.6c-1.4,0.6-6.1,1-6.1,1s-26.8,2.5-34,2.8
		h-18c0,0-12.4-0.4-37.1-3c0,0-2.8-0.3-3.6-0.8C370.9,312.8,369.2,311,369.2,311z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M312.8,307.3
		c0,0-13.9-1.8-18-10.7c0,0-2.2-3.3,1.5-16.1c0,0,3.6-12.1,8.8-14c0,0,1.8-0.8,5.8,1.2c0,0,3.4,1.5,8.8,6.6c0,0,10.7,10,18.4,12.7
		c0,0,6.2,1.8,8.9,6.2c0,0,2.6,3.7,4.7,8.7l2.1,5.1c0,0,1,1.8,0,2.6c0,0-0.5,0.8-2.5,0.4c0,0-22.1-3.2-30.3-4.7c0,0-1.4-0.3-2.3-0.1
		c-0.8,0.1-3.7,1.9-3.7,1.9S314.2,307.7,312.8,307.3z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M522.3,305.2
		c0,0-20.5,3.3-31.2,4.8c0,0-1.9,0.4-2.8-0.7c0,0-0.7-0.6,0.1-2.3c0,0,2.8-7,4-9.1c0,0,1.1-2.2,2.3-3.9c0,0,2.1-3.9,6.9-5.9
		c0,0,4.7-1.7,8.9-4.3c0,0,5.6-3.4,11.8-9.4c0,0,5.8-5,6.2-5.2c0,0,3.6-2.8,7.2-3c0,0,1.7-0.3,3.2,1.2c0,0,3.7,2.5,6.9,12.7
		c0,0,2.2,6.2,2.2,12.5c0,0,0.7,3.7-2.8,7.3c0,0-2.1,3.3-10.6,6.1c0,0-2.6,0.8-5.6,1.4c0,0-1,0.3-1.8-0.1c-0.8-0.4-2.5-1.5-3-1.6
		C524.1,305.3,523.7,305.1,522.3,305.2z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M279.8,304.5
		c0,0,0.1-0.8,0.8-0.6s6.3,1.9,6.3,1.9s1,0.1,1.4-0.8c0.5-1,0.5-1.1,0.5-1.1s2.1-6.2,5.4-11.3c0,0,0,1.8,0.3,3c0,0,0.8,3.6,5.2,6.7
		c0,0,4,3.3,12.1,4.8l1.8,0.3c0,0,0.3,0.1,0.8-0.1c0,0,3.3,1.1,9.6,2.2c0,0,11.1,2.2,45.3,5.9c0,0,0.5,0.6,3,0.8
		c0,0,17.7,2.3,36.9,3.4h21.9c0,0,1.6,0,5.2-0.3c0,0,9.4-0.6,32.3-3.2c0,0,2.9-0.3,4.1-1c0,0,14.4-1.4,26.8-3.2c0,0,23.7-3,27.9-5
		c0,0,0.8,0.3,1.5,0c0,0,7.7-1,14-5.4c0,0,5.8-4.3,5.1-9.4c0,0,1.4,2.2,2.2,4c0,0,1.8,3.6,3,7c0,0,0.7,2.1,1.7,2.2
		c0,0,0.4,0,0.8-0.1l6.5-1.9c0,0,0.3,0.3,0.3,1.2c0,0,0.1,11.4-1,23.2c0,0-2.5,24.8-3.6,30.8c0,0-0.3,0.4,0,0.8
		c0.3,0.5-0.3,1.1-0.5,1.4c0,0,0.5,0.4,0.4,0.8c0,0-1.4,8.8-3.4,13.6l-6.7,1.1c0,0-1.7,0.3-2.9-0.4c-1.1-0.7-2.3-1-3.4-1.1
		c0,0-23.4-0.7-23.7-0.7c0,0-14.6,0.1-32.6,1.1c0,0-0.7,0.1-1.4-0.4s-1-0.8-2.2-1.1c-1.4-0.3-2.9-0.4-5.5-0.4c0,0-0.5,0-0.8,0.8
		c-0.3,0.8-0.8,1.2-1.1,1.2l-17.2,0.1c0,0-0.5-0.3-0.7-0.4c-0.3-0.1-0.8-0.8-1.1-0.8c-0.4,0-8.4,0-8.4,0s-0.4,0-0.7,0.4
		c-0.4,0.4-0.7,0.8-1.1,0.8h-17.2c0,0-0.4-0.1-0.7-0.4c-0.3-0.4-1-1.5-1.4-1.5h-8.5c0,0-0.3,0-0.5,0.4c-0.3,0.3-1,1.2-1,1.2
		l-0.4,0.3h-17.2c0,0-0.4-0.1-0.7-0.4s-0.8-1-1.2-1s-8.4,0-8.4,0s-0.3,0-0.5,0.3s-1,1-1.4,1h-17.1l-0.4-0.3l-1.2-1.6l-0.5-0.1
		c0,0-4.3-0.1-6.7,0.8c0,0-1.2,1-1.8,1c0,0-24.3-0.8-30.8-1c0,0-20.6,0.3-26.5,0.8c0,0-0.5,0.1-1.2,0.3c-0.5,0.3-2.3,1.1-3,1.1
		s-2.1,0-7.8-1.1c0,0-0.4,0-0.5-0.7c0,0-2.5-7.8-3-12.9c0,0-0.1-0.4,0.5-0.8c0,0-0.5-0.6-0.5-0.8c0,0-1.9-14.9-3.4-29.2
		C281,331.3,279.9,319.5,279.8,304.5z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Wagon4DrFront);
