import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsVerified, updateItem } from '../../../../../../actions';
import InputField from '../../../ui/inputField';

class CustomInputs extends Component {
  render() {
    const { handleSectionVerified, handleValue, item, isVerified } = this.props;
    return (
      <div className="form-body">
        <div onClick={handleSectionVerified}>
          <InputField
            title="Body"
            placeholder="Enter body style"
            onChangeHandler={e => handleValue('bodyStyle', e.target.value)}
            value={(item && item.bodyStyle) || ''}
            isDisabled={isVerified}
          />
        </div>
        <div onClick={handleSectionVerified}>
          <InputField
            title="Transmission"
            placeholder="Enter transmission"
            onChangeHandler={e => handleValue('transmission', e.target.value)}
            value={(item && item.transmission) || ''}
            isDisabled={isVerified}
          />
        </div>

        <div onClick={handleSectionVerified}>
          <InputField
            title="Engine"
            placeholder="Enter engine"
            onChangeHandler={e => handleValue('engine', e.target.value)}
            value={(item && item.engine) || ''}
            isDisabled={isVerified}
          />
        </div>
        <div onClick={handleSectionVerified}>
          <InputField
            title="Driveline"
            placeholder="Enter driveline"
            onChangeHandler={e => handleValue('driveline', e.target.value)}
            value={(item && item.driveline) || ''}
            isDisabled={isVerified}
          />
        </div>
        <div onClick={handleSectionVerified}>
          <InputField
            title="Cylinder"
            placeholder="Enter cylinder"
            onChangeHandler={e => handleValue('cylinder', e.target.value)}
            value={(item && item.cylinder) || ''}
            isDisabled={isVerified}
          />
        </div>
      </div>
    );
  }
}

CustomInputs.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { variant, vin, item, isVerified } = state.core;
  return { variant, vin, item, isVerified };
};

export default connect(
  mapStateToProps,
  {
    updateItem,
    setIsVerified,
  }
)(CustomInputs);
