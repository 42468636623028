import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, some } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { loadSellerTitles, resetSellerTitles } from '../../actions';
import { compose, getConfig } from '../../utils/helpers';

class SellerTitles extends Component {
  state = {
    title: 'seller titles',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerTitles();
    this.props.loadSellerTitles(getConfig('marketplaceId'));
  };

  render() {
    const loading = isEmpty(this.props.sellerTitles);
    const data = (this.props.sellerTitles.data || []).filter(item => {
      const { bCompNo, sCompNo, type } = item;
      const accountList = get(this.props.userProfile, 'user.accountList');
      let isBuyer = some(accountList, { accountNumber: bCompNo });
      let isSeller = some(accountList, { accountNumber: sCompNo });
      if (isBuyer && isSeller) {
        if (type === 'Title Not In') isSeller = false;
        if (type === 'Title Due From You') isBuyer = false;
      }
      return isSeller;
    });
    const columns = this.props.makeColumns([
      'checkbox',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'buyer',
      'sDate',
      'title',
    ]);

    /*
    SAMPLE
      aucAccId: "5003027"
      bCompNo: "Q9431"
      bName: "INDIANAPOLIS TRUCKS"
      body: "CARGO VAN"
      buyOrSellDealer: "S"
      controlNo: "362853"
      gatepass: "1"
      gross: "6500.00"
      lotNo: "C416"
      make: "Chevrolet"
      model: "Express"
      sCompNo: "M7298"
      sDate: "2021-08-06T00:00:00"
      sName: "HOCKETT MOTORS INC"
      series: "2500 Cargo"
      titleDate: "1900-01-01T00:00:00"
      type: "Title Due From You"
      vin: "1GCWGFBA8C1201433"
      yr: "2012"
    */

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Titles</h4>

        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sellerTitles, userProfile } = state.entities;
  return { sellerTitles, userProfile };
};

export default compose(
  withTable,
  connect(mapStateToProps, { loadSellerTitles, resetSellerTitles })
)(SellerTitles);
