import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import {
  updateItem,
  loadPostItem,
  loadGetAutoGrade,
} from '../../../../../actions';
import Layout from '../../templates/forms/Layout';
import ColorLabel from '../../templates/forms/ColorLabel';
import FormFooter from '../../templates/forms/formFooter';
import SelectMenu from '../../ui/selectMenu';
import InputField from '../../ui/inputField';
import TextArea from '../../ui/textArea';
import { Platestates, topTypes } from '../../../constants/common';
import AutogradeModal from '../../modals/AutogradeModal';
import { Item } from '../../../utils/Item';
import SuccessErrorMessageModal from '../../modals/SuccessErrorMessageModal';
import Loader from '../../ui/loader';
import '../../../styles/form/additional.css';
import { showRow } from '../../../utils/helpers';
class Additional extends Component {
  constructor(props) {
    super(props);
    const decodedItem =
      props.vin.decodedItems &&
      props.vin.decodedItems[props.variant && props.variant.index];

    const announcementValues = [];
    if (decodedItem && decodedItem.announcements) {
      for (
        let i = 0;
        i < decodedItem && decodedItem.announcements.length;
        i++
      ) {
        announcementValues.push(
          decodedItem && decodedItem.announcements[i].value
        );
      }
    }

    const doors =
      decodedItem === undefined ||
      decodedItem.doors === undefined ||
      decodedItem.doors[0] == null
        ? ['1', '2', '3', '4', '5', '6', '7', '8']
        : decodedItem['doors'];

    const seats =
      decodedItem === undefined ||
      decodedItem.seats === undefined ||
      decodedItem.seats[0] == null
        ? ['1', '2', '3', '4', '5', '6', '7', '8']
        : decodedItem['seats'];

    this.state = {
      loading: false,
      message: null,
      openAutogradeModal: false,
      openErrorSuccessModal: false,
      vehicleGrade: props.item.vehicleGrade || '',
      blockAnnouncements: props.item.blockAnnouncements || '',
      privateBlockNotes: props.item.privateBlockNotes || '',
      plates: props.item.plates || '',
      state: props.item.plateState ? props.item.plateState : '',
      plateCount: props.item.plateCount ? props.item.plateCount : 1,
      doors: props.item.doors,
      seats: props.item.seats,
      top: props.item.top,
      optionItems: {
        lights:
          decodedItem &&
          decodedItem.lights.map(light => ({
            value: light.key,
            label: <ColorLabel color={light.color} />,
            color: light.color,
            exclusive: light.exclusive,
          })),
        announcements: announcementValues.map(value => ({
          value: value,
          label: value,
        })),
        doors: doors.map(value => ({
          value: value,
          label: value,
        })),
        seats: seats.map(value => ({
          value: value,
          label: value,
        })),
        top: topTypes.map(value => ({
          value: value,
          label: value,
        })),
        plateState: Platestates.map(value => ({
          value: value,
          label: value,
        })),
      },
      lights: decodedItem && decodedItem['lights'],
      announcements: decodedItem && decodedItem.announcements,
      announcementValues,
    };
  }

  handleLights = selectedLights => {
    // translate react-select array with multi values to string for store
    let updatedLights = '';
    const addedLight = selectedLights.at(-1);
    if (addedLight) {
      updatedLights = addedLight.exclusive
        ? addedLight.color
        : selectedLights
            .filter(lightObj => !lightObj.exclusive)
            .sort((a, b) => a.value - b.value)
            .map(lightObj => lightObj.color)
            .join(',');
    }

    this.props.updateItem({ light: updatedLights });
  };

  handleMutiSelectValues(entity, value) {
    const result = value.map(a => a.value);
    this.props.updateItem({ [entity]: result });
  }

  getDefaultValue(property) {
    const { item } = this.props;
    let existingValue = item && item[property];
    if (existingValue && existingValue.length > 0) {
      return {
        value: existingValue,
        label: existingValue,
      };
    } else {
      return null;
    }
  }

  handleValue(entity, value) {
    const regDecimal = /^\d*(\.\d{0,2})?$/;
    const regNumbers = /^[0-9]*$/;
    if (entity === 'vehicleGrade') {
      if (regDecimal.test(value)) {
        this.props.updateItem({ [entity]: value });
        this.setState({ [entity]: value });
      }
    } else if (entity === 'plateCount') {
      if (regNumbers.test(value)) {
        this.props.updateItem({ [entity]: value });
        this.setState({ [entity]: value });
      }
    } else {
      this.props.updateItem({ [entity]: value });
    }
  }

  handleGetAutoGrade = crWasDone => {
    const { item, loadGetAutoGrade, loadPostItem, lot, updateItem } =
      this.props;
    this.setState({ loading: true });
    const vehicle = Item({ ...item, crDone: true }, lot);
    loadPostItem(vehicle)
      .then(({ response, error }) => {
        this.setState({ loading: false });
        if (error) {
          this.setState({
            message: 'Something went wrong!',
            openErrorSuccessModal: true,
          });
          return;
        }
        if (response.amStatus === 'Success') {
          const { pk } = response.items;
          loadGetAutoGrade(pk)
            .then(({ response, error }) => {
              if (error) {
                this.setState({
                  message: 'Something went wrong!',
                  openErrorSuccessModal: true,
                });
              }

              if (response.amStatus === 'Success') {
                let { AutoGrade } = response;
                AutoGrade = Number(AutoGrade);

                this.setState({ vehicleGrade: response.AutoGrade }, () => {
                  if (AutoGrade > -1) {
                    updateItem({
                      pk: String(pk),
                      AutoGrade,
                      crDone: true,
                    });
                    this.setState({
                      message: 'AutoGrade Successfully added !',
                      openErrorSuccessModal: true,
                    });
                  } else {
                    updateItem({ pk: String(pk), crDone: true });
                    this.setState({
                      message: 'No AutoGrade at this time',
                      openErrorSuccessModal: true,
                    });
                  }
                });
              } else if (response.amStatus === 'Error') {
                console.error('error');
                this.setState({
                  message: 'Something went wrong!',
                  openErrorSuccessModal: true,
                });
              }
            })
            .catch(error => {
              console.error(error);
              this.setState({
                message: 'Something went wrong!',
                openErrorSuccessModal: true,
              });
            });
        } else if (response.amStatus === 'Error') {
          console.error(response.amMessage);
          this.setState({
            message: 'Something went wrong!',
            openErrorSuccessModal: true,
          });
        }
      })
      .catch(error => console.error(error));
  };

  handleAutoGradeButtonClick = () => {
    const { item } = this.props;
    if (item.crDone === true || item.crDone === 'true') {
      this.handleGetAutoGrade(true);
    } else {
      this.setState({ openAutogradeModal: true });
    }
  };

  closeModal = () => {
    this.setState({ openAutogradeModal: false });
  };

  closeErrorSuccessModal = () => {
    this.setState({ openErrorSuccessModal: false });
  };

  makeLightsValue() {
    // translate store string (e.g. "Green") into array with react-select multi values
    return (this.props.item.light || '')
      .split(',')
      .filter(str => str)
      .map(str => {
        const option =
          find(
            this.state.optionItems.lights || [],
            option => str.toUpperCase() === option.color.toUpperCase()
          ) || {};

        return {
          value: option.value,
          label: <ColorLabel color={option.color} />,
          color: option.color,
          exclusive: option.exclusive,
        };
      })
      .sort((a, b) => a.value - b.value);
  }

  render() {
    const { item, coreSections } = this.props;
    const {
      optionItems,
      openAutogradeModal,
      plates,
      plateCount,
      blockAnnouncements,
      privateBlockNotes,
      vehicleGrade,
      openErrorSuccessModal,
      message,
      loading,
    } = this.state;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {loading && <Loader />}
        <Layout>
          <AutogradeModal
            open={openAutogradeModal}
            close={this.closeModal}
            handleGetAutoGrade={this.handleGetAutoGrade}
            navigate={this.props.naviate}
          />
          <SuccessErrorMessageModal
            open={openErrorSuccessModal}
            close={this.closeErrorSuccessModal}
            message={message}
            navigate={this.props.naviate}
          />
          <div className="flex justify-between h-100">
            <div className="form-container w-100">
              <div className="form-header">
                <div>Additional</div>
              </div>
              <div className="form-body-container">
                <div className="form-body">
                  {showRow('s7:r1', coreSections) && (
                    <div>
                      {item && item.nonStandardVin ? (
                        <InputField
                          title="Lights"
                          placeholder="Enter lights"
                          onChangeHandler={e =>
                            this.handleValue('light', e.target.value)
                          }
                          value={item && item.light}
                        />
                      ) : (
                        <SelectMenu
                          title="Lights"
                          options={optionItems.lights}
                          value={this.makeLightsValue()}
                          onChangeHandler={this.handleLights}
                          hideIndicatorSeparator={true}
                          isMulti={true}
                          closeMenuOnSelect={false}
                        />
                      )}
                    </div>
                  )}

                  {showRow('s7:r2', coreSections) && (
                    <div>
                      {item && item.nonStandardVin ? (
                        <InputField
                          title="Announcements"
                          placeholder="Enter announcements"
                          onChangeHandler={e =>
                            this.handleValue('announcements', e.target.value)
                          }
                          value={item && item.announcements}
                        />
                      ) : (
                        <SelectMenu
                          title="Announcements"
                          options={optionItems.announcements}
                          onChangeHandler={e =>
                            this.handleValue('announcements', e.value)
                          }
                          hideIndicatorSeparator={true}
                          defaultValue={this.getDefaultValue('announcements')}
                          isMulti={true}
                        />
                      )}
                    </div>
                  )}
                </div>
                {showRow('s7:r4', coreSections) && (
                  <TextArea
                    title="Block Announcement Notes"
                    placeholder="Enter block announcement notes"
                    onChangeHandler={e =>
                      this.handleValue('blockAnnouncements', e.target.value)
                    }
                    defaultValue={blockAnnouncements}
                  />
                )}

                <div className="form-body">
                  {showRow('s7:r5', coreSections) && (
                    <InputField
                      title="Private Block Notes"
                      placeholder="Enter private block notes"
                      onChangeHandler={e =>
                        this.handleValue('privateBlockNotes', e.target.value)
                      }
                      defaultValue={privateBlockNotes || ''}
                    />
                  )}
                  {showRow('s7:r6', coreSections) && (
                    <InputField
                      title="Vehicle Grade"
                      placeholder="Enter vehicle grade"
                      onChangeHandler={e =>
                        this.handleValue('vehicleGrade', e.target.value)
                      }
                      value={vehicleGrade}
                      infoText="Get autograde"
                      infoTextClickHandler={() =>
                        this.handleAutoGradeButtonClick()
                      }
                      infoTextStyles="get-autograde-text"
                    />
                  )}
                  {showRow('s7:r7', coreSections) && (
                    <InputField
                      title="License Plate"
                      placeholder="Enter license plate"
                      onChangeHandler={e =>
                        this.handleValue('plates', e.target.value)
                      }
                      defaultValue={plates}
                    />
                  )}

                  {showRow('s7:r7', coreSections) && (
                    <SelectMenu
                      title="Plate State"
                      options={optionItems.plateState}
                      onChangeHandler={e =>
                        this.handleValue('plateState', e.value)
                      }
                      hideIndicatorSeparator={true}
                      defaultValue={this.getDefaultValue('plateState')}
                    />
                  )}

                  {showRow('s7:r7', coreSections) && (
                    <InputField
                      title="Enter Plate Count"
                      placeholder="Enter plate count"
                      onChangeHandler={e =>
                        this.handleValue('plateCount', e.target.value)
                      }
                      value={plateCount}
                    />
                  )}

                  {showRow('s7:r8', coreSections) && (
                    <SelectMenu
                      title="Doors"
                      options={optionItems && optionItems.doors}
                      defaultValue={this.getDefaultValue('doors')}
                      onChangeHandler={e => this.handleValue('doors', e.value)}
                      hideIndicatorSeparator={true}
                    />
                  )}
                  {showRow('s7:r9', coreSections) && (
                    <SelectMenu
                      title="Seats"
                      options={optionItems && optionItems.seats}
                      defaultValue={this.getDefaultValue('seats')}
                      onChangeHandler={e => this.handleValue('seats', e.value)}
                      hideIndicatorSeparator={true}
                    />
                  )}
                  {showRow('s7:r10', coreSections) && (
                    <SelectMenu
                      title="Top"
                      options={optionItems && optionItems.top}
                      defaultValue={this.getDefaultValue('top')}
                      onChangeHandler={e => this.handleValue('top', e.value)}
                      hideIndicatorSeparator={true}
                    />
                  )}
                </div>
              </div>
              <FormFooter currentSection="s7" />
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

Additional.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { vin, item, variant, coreSections } = state.core;
  return { vin, item, variant, coreSections };
};

export default connect(mapStateToProps, {
  updateItem,
  loadPostItem,
  loadGetAutoGrade,
})(Additional);
