import 'isomorphic-fetch';
import { toastr } from 'react-redux-toastr';
import {
  getConfig,
  getParamFromUrl,
  parseValue,
  refreshApiKey,
  startOver,
} from '../utils/helpers';
import { getUniversalCookies } from '../utils/helpers/cookies';

const fetchApi = async (
  types,
  endpoint,
  method,
  body,
  search,
  core,
  recalls,
  otherApiRoot,
  headers
) => {
  const cookies = getUniversalCookies();
  const apiRoot = getConfig('apiRoot');
  const searchRoot = getConfig('searchRoot');
  const recallsRoot = getConfig('recallsRoot');
  const coreRoot = getConfig('coreRoot');
  const refreshToken = cookies.get('refreshToken');

  // figure out the url root
  const urlRoot = otherApiRoot
    ? otherApiRoot
    : core
    ? coreRoot
    : search
    ? searchRoot
    : recalls
    ? recallsRoot
    : apiRoot;

  // add url root if not provided in endpoint
  let url = endpoint.includes(urlRoot) ? endpoint : urlRoot + endpoint;

  if (url && !otherApiRoot && !search && !recalls) {
    const queryString = new URL(url).search;

    // handle missing mpId parameter
    if (!queryString.includes('mpId=')) {
      const mpId = getConfig('marketplaceId');
      url = queryString.includes('?')
        ? `${url}&mpId=${mpId}`
        : `${url}?mpId=${mpId}`;
    }

    // handle urls with missing apiKeys
    if (queryString.includes('apiKey')) {
      const apiKeyParam = parseValue(getParamFromUrl('apiKey', queryString));

      // if apiKey available, or available to fetch, add it to the url.
      if (!apiKeyParam) {
        const apiKey =
          cookies.get('apiKey') || (await refreshApiKey(refreshToken));

        if (apiKey) {
          url = url.replace(`apiKey=${apiKeyParam}`, `apiKey=${apiKey}`);
        } else {
          // if we can't get an apiKey send them to Login screen
          startOver();
          return;
        }
      }
    }
  }

  const options = {
    method: method || 'GET',
    body: body || undefined,
    headers: headers || undefined,
    mode: 'cors',
  };

  return fetch(url, options).then(async response => {
    if (response.status === 401 || response.status === 403) {
      const apiKey = await refreshApiKey(refreshToken, () => {
        toastr.error('Error. Please try your request again');
      });

      if (!apiKey) {
        startOver();
        return Promise.reject();
      }
    }

    return response.json().then(json => {
      if (!response.ok) return Promise.reject(json);
      return json;
    });
  });
};

export const FETCH_API = Symbol('Fetch API');

const middleware = store => next => action => {
  const fetchAPI = action[FETCH_API];

  if (typeof fetchAPI === 'undefined') {
    return next(action);
  }

  let {
    endpoint,
    types,
    method,
    body,
    search,
    core,
    recalls,
    otherApiRoot,
    headers,
  } = fetchAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Error: Specify endpoint URL.');
  }

  const actionWithData = data => {
    const finalAction = Object.assign({}, action, data);

    delete finalAction[FETCH_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWithData({ type: requestType }));

  return fetchApi(
    types,
    endpoint,
    method,
    body,
    search,
    core,
    recalls,
    otherApiRoot,
    headers
  ).then(
    response => next(actionWithData({ response, type: successType })),
    error => {
      return next(
        actionWithData({
          type: failureType,
          error: error ? error.message : 'Error middleware',
        })
      );
    }
  );
};

export default middleware;
