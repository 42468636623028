import React, { Component } from 'react';
import { connect } from 'react-redux';

class Convertible2DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M226.4,272.7c-3.9,12.1-3.9,27.9-2.3,40.7c0.2,1.7,0.4,3.1,0.8,4.8c2.5,13.7,8.3,30.4,9.1,34.7
	s-2.3,8.3-1.2,12.1s6.9,15.6,9.8,19.7c2.9,4.2,13.3,11.2,13.3,11.2s8.7-2.9,13.7-5c5-1.9,12.9-6.7,16.6-8.3c2.3-1,5-2.5,6.7-2.5
	s20.8,0.2,20.8,0.2h215.6c0,0,5-8.7,14.1-8.7c9.4,0,10,8.5,13.3,11c3.1,2.5,11.8,6.4,16.6,8.3c5,1.9,13.7,5,13.7,5
	s10.2-7.1,13.3-11.2c2.9-4.2,8.7-16.2,9.8-19.7c1-3.7-1.9-7.5-1.2-12.1c0.8-4.4,6.7-21,9.1-34.7c0.2-1.5,0.4-3.1,0.8-4.8
	c1.7-13.1,1.7-28.9-2.3-40.7c-2.3-7.1-5.4-13.7-8.5-19.1h1.5c3.1-0.2,11.6-0.6,17-1.7c6.9-1.2,9.4-11.4,4.4-18.1
	c-5-6.7-19.5-8.5-27.6-8.5c-6.4,0-10,7.3-11,11c-0.2,0.6-0.2,1.9-0.2,2.7c-1.5-0.2-3.1-0.2-5-0.2h-2.9c-0.4-0.6-0.8-1.2-0.8-1.5
	c-1.9-2.7-3.5-4.6-5.2-5.8c-0.4-1.2-1-2.7-1.2-3.1c-7.3-18.3-26-53.6-27-55.5c-1.2-1.9-4.4-4.2-7.5-5.2c-3.1-1-15.2-4.2-35.5-7.1
	s-54-5.2-84.8-5.2c-30.8,0-64.6,2.3-84.8,5.2c-20.4,2.9-32.2,6-35.5,7.1c-3.1,1-6.4,3.1-7.5,5.2c-1,1.9-18.7,35.3-26.4,53.8
	c-0.4,1.2-1.2,3.1-1.9,4.8c-1,0.8-2.3,1.9-3.5,3.5c-0.6,0.8-1.7,2.5-2.5,3.7h-2.9c-1.9,0-3.3,0-4.8,0.2c0-0.6-0.2-1.5-0.2-1.9
	c-0.8-3.3-4.2-11.9-11.4-11.9c-8.1,0-22.7,1.9-27.6,8.5c-5,6.7-2.5,16.8,4.4,18.1c5,0.8,12.7,1.5,16.2,1.7l2.3,0.2l0.2-0.4
	C232,258.6,228.7,265.4,226.4,272.7L226.4,272.7z"
          />
          <path
            fill="#EBEBEB"
            d="M420.7,224.7h1c83.8,0,75.5,2.5,129.9,3.9c9.4,0.2,16-0.2,20.2,0.4c0.6,0,1,0.2,1.7,0.4
	c-0.6-1.5-1.7-3.9-2.1-5c-7.5-17.9-23.1-46.8-24.3-48.6c-1.2-1.9-4.4-4.2-7.7-5.2c-3.1-1-12.9-4.2-33.3-7.1s-54-5.2-84.8-5.2
	c-30.8,0-64.6,2.3-84.8,5.2c-20.4,2.9-30.1,6-33.3,7.1c-3.1,1-6.4,3.1-7.5,5.2c-1,1.7-15,27-22.7,44.9c-1.2,2.7-3.5,8.3-4,8.7
	c0.6-0.2,1.2-0.2,1.9-0.4c4.2-0.8,10.8-0.2,20.2-0.4C345.1,227.2,337,224.7,420.7,224.7L420.7,224.7z"
          />
          <path
            fill="#656565"
            d="M355,197.2h-26.4c-4,0-10.6,5.2-10.6,10.6v19.3c9.8-0.6,20-1.2,26.6-1.7c6-0.4,13.5-0.6,23.1-0.6v-17.3
	C367.7,204.3,360.7,197.2,355,197.2L355,197.2z M487.3,197.2c-5.6,0-12.7,7.1-12.7,10.2v17.3c9.4,0,16.8,0.2,23.1,0.6
	c6.9,0.4,16.8,1,26.6,1.7v-19.3c0-5.4-6.7-10.6-10.6-10.6L487.3,197.2L487.3,197.2z M255.7,381.8c0,0-10.2-5.8-13.3-9.4
	c-2.9-3.5-8.7-13.3-9.8-16.4c-1-3.1,1.9-6.2,1.2-10c-0.8-3.7-6.7-17.5-9.1-28.7c-0.2-1.2-0.4-2.5-0.8-4V424c0,2.5-0.2,5,2.9,5h47.4
	c3.3,0,2.9-1,2.9-3.5v-51.1c-2.9,1.2-5.8,2.5-8.1,3.3C264.6,379.3,255.7,381.8,255.7,381.8L255.7,381.8z M586.6,381.8
	c0,0,10.2-5.8,13.3-9.4c2.9-3.5,8.7-13.3,9.8-16.4c1-3.1-1.9-6.2-1.2-10c0.8-3.7,6.7-17.5,9.1-28.7c0.2-1.2,0.4-2.5,0.8-4V424
	c0,2.5,0.2,5-2.9,5h-47.4c-3.3,0-2.9-1-2.9-3.5v-51.1c2.9,1.2,5.8,2.5,8.1,3.3C577.7,379.3,586.6,381.8,586.6,381.8L586.6,381.8z"
          />
          <circle fill="#EBEBEB" cx="541.5" cy="386.6" r="10" />
          <path
            fill="#EBEBEB"
            d="M331.6,290.6c0-11.6-8.9-17.9-18.1-19.5c-3.7-0.8-9.4-1.5-15.2-2.1c-7.9-0.6-16.2-1-21.2-1
	c-8.7,0-29.3,2.7-29.3,19.5c0,16.8,12.1,22.2,17.3,22.2h51.1C321.6,309.7,331.6,305.3,331.6,290.6L331.6,290.6z M528.8,271
	c-8.9,1.9-18.1,8.1-18.1,19.5c0,14.8,10,19.1,15.4,19.1h51.1c5.2,0,17.3-5.4,17.3-22.2c0-16.8-20.6-19.5-29.3-19.5
	c-5,0-13.3,0.4-21.2,1C538.2,269.6,532.6,270.2,528.8,271L528.8,271z M408.3,260.8H434c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2
	h-25.8c-2.3,0-4.2,1.9-4.2,4.2C404.1,259,406,260.8,408.3,260.8L408.3,260.8z M245.1,338.6c2.5,0,4.8-2.1,4.8-4.8
	c0-2.5-2.1-4.6-4.8-4.6h-3.5c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.8,4.6,4.8H245.1L245.1,338.6z M597,329c-2.5,0-4.6,2.1-4.6,4.6
	c0,2.5,2.1,4.8,4.6,4.8h3.5c2.5,0,4.6-2.1,4.6-4.8c0-2.5-2.1-4.6-4.6-4.6L597,329L597,329z M249.4,237.6v-0.2
	c-1-4.6-4.6-10.6-10-10.6c-7.3,0-21.8,1.7-26.4,7.9c-3.1,4.2-3.3,11.6,1.2,14.8c0.6,0.4,1.5,0.8,2.3,0.8c5.2,0.8,10.8,1.2,16,1.7
	h1.5c1.9-3.3,4.6-7.5,7.1-10C243.6,239.4,246.3,238.2,249.4,237.6L249.4,237.6z M608.2,252.1h0.6c5.6-0.4,11.4-0.6,17-1.7
	c0.8-0.2,1.7-0.4,2.3-0.8c4.6-3.1,4.2-10.8,1.2-14.8c-4.6-6.2-19.1-7.9-26.4-7.9c-5.2,0-8.5,5.6-9.8,10c0,0.2-0.2,0.6-0.2,1
	c3.1,0.6,5.8,1.9,8.1,4.4C603.5,244.4,606.2,248.8,608.2,252.1L608.2,252.1z"
          />
          <path
            fill="#656565"
            d="M420.7,341.5c-27.4,0-42.8,1.2-66.1,2.7c-23.3,1.5-30.6,2.7-36.2,3.7c-5.6,1-5.2,5.8,0.2,6
	c5.4,0.2,102.1,0,102.1,0h1c0,0,96.7,0.2,102.1,0c5.4-0.2,5.8-5.2,0.2-6c-5.6-1-12.9-2.3-36.2-3.7s-38.7-2.7-66.1-2.7L420.7,341.5
	L420.7,341.5z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="553.8"
            cy="287.9"
            r="16.8"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="541.5"
            cy="386.6"
            r="10"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M605.3,333.8c0,2.5-2.1,4.8-4.6,4.8h-3.5c-2.5,0-4.6-2.1-4.6-4.8
	c0-2.5,2.1-4.6,4.6-4.6h3.5C603.2,329,605.3,331.3,605.3,333.8L605.3,333.8z M549.6,238.8c0,0-4.2,7.1-5,12.9
	c-0.8,5.8-0.8,13.1-0.8,17.3 M485,273.9v34.3c0,0-3.7,6.9-7.1,7.9 M421.2,155.5c30.8,0,64.6,2.3,84.8,5.2c20.4,2.9,32.2,6,35.5,7.1
	c3.1,1,6.4,3.1,7.5,5.2c1.2,1.9,21.8,41.2,28.1,58.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,158.4c30.8,0,64.6,2.3,84.8,5.2c20.4,2.9,30.1,6,33.3,7.1
	c3.1,1,6.4,3.1,7.5,5.2c1.2,1.9,19.5,35.8,26,52.8 M591.2,238.2c0-1.9,3.3-12.9,11.4-12.9s22.7,1.9,27.6,8.5
	c5,6.7,2.5,16.8-4.4,18.1c-6.9,1.2-18.3,1.7-18.3,1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M474.6,224.9v-17.5c0-3.1,7.1-10.2,12.7-10.2h26.4c4,0,10.6,5.2,10.6,10.6
	v18.9 M608.2,352.9h-40.1c-3.7,0-10,3.7-0.4,6c9.6,2.5,23.1,4.4,28.9,5.2c5.2,0.8,12.9,0.8,12.9,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M564.8,375.2v50.5c0,0-0.4,3.5,2.9,3.5h47.4c0,0,2.9-2.7,2.9-5V313.6
	 M618.8,300.8h-27.4 M510.7,290.6c0-11.6,8.9-17.9,18.1-19.5c8.9-1.9,27.6-3.1,36.6-3.1c8.7,0,29.3,2.7,29.3,19.5
	c0,16.8-12.1,22.2-17.3,22.2h-51.1C520.7,309.7,510.7,305.3,510.7,290.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M514.7,290.4c0-6.7,9.1-15,14.3-15s7.3,4,7.3,5.2c0,1.2-2.7,4-2.7,7.5
	c0,3.5,2.9,7.7,2.9,10.4c0,2.7-1.2,6.4-8.1,6.4S514.7,301,514.7,290.4L514.7,290.4z M571.4,277.5c0-2.7,4-5,6.7-5
	c2.7,0,13.3,6.9,13.3,15c0,8.1-7.5,18.1-12.5,18.1s-7.1-4-7.1-6.9c0-2.7,2.3-7.1,2.3-11C574.1,283.5,571.4,277.5,571.4,277.5
	L571.4,277.5z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="288.5"
            cy="287.9"
            r="16.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237,333.8c0,2.5,2.1,4.8,4.8,4.8h3.5c2.5,0,4.6-2.1,4.6-4.8
	c0-2.5-2.1-4.6-4.6-4.6h-3.5C239.1,329,237,331.3,237,333.8L237,333.8z M252.8,274.8c0.4-7.3,2.7-10.2,10.6-11.2
	c7.9-1,34.3-1.2,50.7-2.9s47.2-5,61.9-7.1c14.8-2.3,17.5-3.1,45.1-3.1h0.4c27.6,0,30.4,1,45.1,3.1c14.8,2.3,45.5,5.4,61.9,7.1
	c16.4,1.7,42.8,1.9,50.7,2.9c7.9,1,10,4,10.6,11.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292.7,238.8c0,0,4.2,7.1,5,12.9c0.8,5.8,0.8,13.1,0.8,17.3 M326.2,277.1
	c0,0,26.4-2.9,40.5-4.4c14.1-1.5,27.9-1.2,53.6-1.2h1.5c25.8,0,39.5-0.2,53.6,1.2s40.5,4.4,40.5,4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M357.3,273.9v34.3c0,0,3.7,6.9,7.1,7.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M347.8,275.2c0.8,6.9,3.7,27.6,5,33.7c1.2,6,5.2,7.5,13.9,7.5h108.9
	c8.7,0,12.7-1.5,13.9-7.5c1.2-6,4.2-26.8,5-33.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.4,311.6c-9.6,0.8-41.4,0.2-60.9,1.7s-43,1.9-43,5.8s1,3.1,6.6,3.1
	h330.1c0,0,6.7,0.8,6.7-3.1s-23.5-4.4-43-5.8c-19.5-1.5-51.3-1-60.9-1.7 M420.7,354c0,0-96.7,0.2-102.1,0c-5.4-0.2-5.8-5.2-0.2-6
	c5.6-1,12.9-2.3,36.2-3.7c23.3-1.5,38.7-2.7,66.1-2.7h1c27.4,0,42.8,1.2,66.1,2.7s30.6,2.7,36.2,3.7c5.6,1,5.2,5.8-0.2,6
	c-5.4,0.2-102.1,0-102.1,0L420.7,354L420.7,354z M246.3,388.7c0,0,12.5-2.9,24.9-7.9c12.5-5,17.9-11,17.9-11h263.8
	c0,0,5.4,6,17.9,11c12.5,5,24.9,7.9,24.9,7.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.3,232c-28.9,0-55.1,1.7-71.7,1.9s-53.6,4.6-58.2,4.6h-34.5
	c-6.4,0-10,0.8-13.7,4.4c-3.7,3.7-11.4,16.2-16,29.5c-4.4,13.5-3.9,31.8-1.5,45.5c2.5,13.7,8.3,30.4,9.1,34.7s-2.3,8.3-1.2,12.1
	c1,3.7,6.9,15.6,9.8,19.7s13.3,11.2,13.3,11.2s8.7-2.9,13.7-5c5-1.9,12.9-6.6,16.6-8.3c2.3-1,5-2.5,6.7-2.5s20.8,0.2,20.8,0.2H529
	c0,0,5-8.7,14.1-8.7c9.4,0,10,8.5,13.3,11c3.1,2.5,11.8,6.4,16.6,8.3c5,1.9,13.7,5,13.7,5s10.2-7.1,13.3-11.2
	c2.9-4.2,8.7-16.2,9.8-19.7c1-3.7-1.9-7.5-1.2-12.1c0.8-4.4,6.7-21,9.1-34.7c2.5-13.7,2.9-32-1.5-45.5c-4.4-13.5-12.3-26-16-29.5
	c-3.7-3.7-7.3-4.4-13.7-4.4h-34.5c-4.6,0-41.6-4.4-58.2-4.6c-16.6-0.2-42.8-1.9-71.7-1.9L420.3,232L420.3,232L420.3,232z
	 M421.2,155.5c-30.8,0-64.6,2.3-84.8,5.2c-20.4,2.9-32.2,6-35.5,7.1c-3.1,1-6.4,3.1-7.7,5.2c-1.2,1.9-21.8,41.2-28.1,58.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,158.4c-30.8,0-64.6,2.3-84.8,5.2c-20.4,2.9-30.1,6-33.3,7.1
	c-3.1,1-6.4,3.1-7.5,5.2c-1.2,1.9-19.5,35.8-26,52.8 M258.8,238.4c4.6-7.1,7.5-8.5,11.9-9.4c4.2-0.8,10.8-0.2,20.2-0.4
	c9.4-0.2,39.5-2.3,54-3.1c14.8-1,37-0.8,75.9-0.8h1c38.9,0,61.1-0.2,75.9,0.8c14.8,1,44.7,2.9,54,3.1c9.4,0.2,16-0.2,20.2,0.4
	c4.2,0.8,7.1,2.3,11.8,9.4 M251.1,238.2c0-1.9-3.3-12.9-11.4-12.9s-22.7,1.9-27.6,8.5c-5,6.7-2.5,16.8,4.4,18.1
	c6.9,1.2,18.3,1.7,18.3,1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367.7,224.9v-17.5c0-3.1-7.1-10.2-12.7-10.2h-26.4
	c-4,0-10.6,5.2-10.6,10.6v18.9 M233.9,352.9H274c0,0,10,3.7,0.4,6c-9.6,2.5-23.1,4.4-28.9,5.2c-5.2,0.8-12.9,0.8-12.9,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M277.5,375.2v50.5c0,0,0.4,3.5-2.9,3.5h-47.4c-3.1,0-2.9-2.7-2.9-5V313
	 M223.5,300.8h27.4 M331.6,290.6c0-11.6-8.9-17.9-18.1-19.5c-8.9-1.9-27.6-3.1-36.6-3.1c-8.7,0-29.3,2.7-29.3,19.5
	c0,16.8,12.1,22.2,17.3,22.2H316C321.6,309.7,331.6,305.3,331.6,290.6L331.6,290.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M327.6,290.4c0-6.7-9.1-15-14.3-15s-7.3,4-7.3,5.2c0,1.2,2.7,4,2.7,7.5
	c0,3.5-2.9,7.7-2.9,10.4c0,2.7,1.2,6.4,8.1,6.4S327.6,301,327.6,290.4L327.6,290.4z M270.9,277.5c0-2.7-4-5-6.7-5
	c-2.5,0-13.3,6.9-13.3,15c0,8.1,7.5,18.1,12.5,18.1c5,0,7.1-4,7.1-6.9c0-2.7-2.3-7.1-2.3-11C268.2,283.5,270.9,277.5,270.9,277.5
	L270.9,277.5z M374.2,315.9v-41.2h92.1v41.8 M438,256.7c0,2.3-1.9,4.2-4.2,4.2h-25.8c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2h25.8
	C436.1,252.5,438,254.4,438,256.7L438,256.7z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M298.3,269
		c0,0-0.2-14.3-0.8-17.5c-0.8-3.3-0.8-4.6-4.8-13.1c0,0,4,0,22.7-2.1c18.7-2.1,31.2-2.7,38.2-2.5c7.1,0.2,12.5-0.4,13.7-0.4
		c1.2,0,42-1.5,59.2-1.5c17.3,0,48.9,1.5,48.9,1.5s13.3,0.4,17,0.6c3.5,0,12.1,0,55.1,4.4l2.1,0.2c0,0-4.8,8.5-5.2,15.4
		c-0.4,7.1-0.4,15-0.4,15s-19.1,0.2-27.9,8.1l-40.1-4.4c0,0-12.5-1.2-23.7-1.2s-61.3,0-61.3,0s-12.3,0.2-18.9,0.6
		c-6.7,0.6-46.1,4.8-46.1,4.8s-5.2-5.4-15.2-6.7L298.3,269z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M223,300.8h27.9
		c0,0,5,7.9,13.3,8.9H317c0,0,12.1-0.2,14.3-15.4c0,0,2.3-10-5.4-17l21.8-2.3c0,0,3.7,33.7,5.6,36.8c1.9,3.1,3.7,4.8,10.4,4.8
		s113.5,0,113.5,0s11,1,12.5-7.5c0,0,4.2-26.4,5-34.1l21.6,2.3c0,0-7.9,8.1-4.6,20c0,0,2.5,11.6,14.1,12.7h52.4
		c0,0,7.9-0.2,13.5-8.9h27.9c0,0-0.2,14.8-5,31c-4.8,16.4-5,17.5-5,17.5s-1.7,4.4-0.6,8.1l0.8,2.9c0,0,1.2,3.3-0.6,6.7
		c0,0-4.8,11.6-9.4,18.1c0,0-2.1,3.1-12.9,10.8c0,0-14.8-4.2-29.1-12.3c0,0-2.3-1-3.1-3.3c-1-2.3-4.2-8.9-11.4-8.7
		c-7.3,0.2-11.4,4.8-14.1,8.7l-236.1-0.2c0,0-0.8,0-2.5,0.6c-1.7,0.6-18.3,9.1-19.3,9.6c-1,0.4-15,5.4-15,5.4s-9.8-6.9-13.1-11
		c-3.3-4.2-9.6-18.9-9.6-18.9s-1.5-2.1-0.2-5.4c1.2-3.3,1.7-6.2,0.4-10C232.4,346.9,223.3,320.3,223,300.8z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M250.9,300.8
		c0,0-7.9-14.5,0.8-24.3c0,0,6.7-9.4,28.9-8.5c0,0,18.5,0.6,28.1,2.3c0,0,22.2,0.6,23.1,21c0,0,0.8,16-14.6,18.5h-52.8
		C264.2,309.7,257.3,309.7,250.9,300.8z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M525.1,309.7
		c0,0-14.8-0.8-14.3-20.6c0,0,0.4-13.9,16.6-17.7c0,0,4.8-1.7,16.6-2.5c0,0,27-3.1,36.2,1.2c0,0,12.9,3.3,14.3,15
		c1.5,11.6-6.2,23.3-16.4,24.5L525.1,309.7L525.1,309.7z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Convertible2DrRear);
