import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, uniqBy, isEmpty } from 'lodash';
import Icon from '../../../ui/icons';
import { GREY_07 } from '../../../../constants/colors';
import { loadConditionAreas } from '../../../../../../actions';
import Loader from '../../../ui/loader';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import '../../../../styles/form/condition.css';

class All extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loadingDataSource: [{ description: 'Loading...' }],
      searchText: '',
      openErrorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.condition.areas)) {
      this.loadItems();
    } else {
      this.setState({ loaded: true });
    }
  }

  loadItems() {
    this.props
      .loadConditionAreas()
      .then(({ response }) => {
        if (response.amStatus === 'Success') {
          this.setState({ loaded: true });
        } else if (response.amStatus === 'Error') {
          this.setState({
            message: `Unable to load data. Error: ${response.amMessage}`,
            openErrorModal: true,
          });
        }
      })
      .catch(error => console.error(error));
  }

  handleClick = value => {
    this.props.navigate({
      pathname: '/core/condition/parts',
      state: {
        condition: {
          area: value,
          selectedRow: this.props.selectedRow,
        },
      },
    });
  };

  filterList(searchText, list) {
    const text = searchText.toLowerCase();
    return filter(list, v => {
      const val = v.description.toLowerCase();
      return val.search(text) !== -1;
    });
  }

  render() {
    const { searchText, loaded } = this.state;
    let areas = null;
    if (areas == null || !this.state.loaded) {
      areas = this.state.loadingDataSource;
    }

    if (this.props.condition.areas != null) {
      areas = uniqBy(this.props.condition.areas.values, e => e.description);
    }
    if (searchText !== '') {
      areas = this.filterList(searchText, areas);
    }

    return (
      <div className="flex justify-between h-100">
        <div className="form-body-container form-sub-section w-100">
          <div className="form-body form-sub-section">
            <div className="search-container" style={{ borderRadius: '10px' }}>
              <input
                type="text"
                value={searchText}
                onChange={e => this.setState({ searchText: e.target.value })}
                className="search-box"
                placeholder="Search"
              />
            </div>
            {!loaded ? (
              <Loader className="conditions-loader flex justify-center pt-4" />
            ) : null}
            {loaded &&
              areas.map((item, index) => {
                return (
                  <div
                    key={'areas-' + index}
                    className="form-section-navigation-card"
                    onClick={() => this.handleClick(item.description)}
                  >
                    <span>{item.description}</span>
                    <Icon name="ChevronRight" size={20} color={GREY_07} />
                  </div>
                );
              })}
          </div>
        </div>
        <ConfirmationModal
          open={this.state.openErrorModal}
          close={() => this.setState({ openErrorModal: false })}
          action={() => this.loadItems()}
          title="Error"
          body={this.state.message}
          confirmBtnTxt="Try Again"
          iconName="CircledExclamationmark"
        />
      </div>
    );
  }
}

All.propTypes = {
  loadConditionAreas: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { condition, crTypes } = state.core;
  const { loadingBar } = state;
  return { condition, crTypes, loadingBar };
};

export default connect(
  mapStateToProps,
  {
    loadConditionAreas,
  }
)(All);
