import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

export default class AutoniqModal extends Component {
  render() {
    return (
      <div className="modal fade" id="autoniqModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <iframe
              title="autoniq"
              src={this.props.url}
              height="590"
              width="600"
            />
          </div>
        </div>
      </div>
    );
  }
}

AutoniqModal.propTypes = {
  url: PropTypes.string.isRequired,
};
