import React, { Component } from 'react';
import Layout from '../../../templates/forms/Layout';
import Button from '../../../ui/buttons';
import Icon from '../../../ui/icons';
import { CYAN_01, WHITE_01 } from '../../../../constants/colors';
import { commafyCurrency } from '../../../../../../utils/helpers';

class QuickReview extends Component {
  render() {
    const item = this.props.location.state.item;

    if (!item) {
      return null;
    }

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header form-sub-section">
              <div onClick={() => this.props.navigate('/core/conditionReview')}>
                <Icon name="LeftArrow" size={20} color={CYAN_01} />
                Condition Review
              </div>
            </div>
            <div className="form-sub-section-title">
              <div>Quick Review</div>
            </div>
            <div className="quick-review-card">
              <div>
                <span className="item-label">Area</span>
                <span className="item-value">{item.area || '-'}</span>
              </div>
              <div>
                <span className="item-label">Area Code</span>
                <span className="item-value">{item.areaCode || '-'}</span>
              </div>
              <div>
                <span className="item-label">Part</span>
                <span className="item-value">{item.part || '-'}</span>
              </div>
              <div>
                <span className="item-label">Part Code</span>
                <span className="item-value">{item.partCode || '-'}</span>
              </div>
              <div>
                <span className="item-label">Damage</span>
                <span className="item-value">{item.damage || '-'}</span>
              </div>
              <div>
                <span className="item-label">Damage Code</span>
                <span className="item-value">{item.damageCode || '-'}</span>
              </div>
              <div>
                <span className="item-label">Severity</span>
                <span className="item-value">{item.severity || '-'}</span>
              </div>
              <div>
                <span className="item-label">Severity Code</span>
                <span className="item-value">{item.severityCode || '-'}</span>
              </div>
              <div>
                <span className="item-label">Material Estimate</span>
                <span className="item-value">
                  {commafyCurrency(item.materialEstimate) || '-'}
                </span>
              </div>
              <div>
                <span className="item-label">Labor Hours</span>
                <span className="item-value">{item.laborHours || '-'}</span>
              </div>
              <div>
                <span className="item-label">Labor Code</span>
                <span className="item-value">{item.laborCode || '-'}</span>
              </div>
              <div>
                <span className="item-label">Paint Hours</span>
                <span className="item-value">{item.paintHours || '-'}</span>
              </div>
              <div>
                <span className="item-label">Paint Code</span>
                <span className="item-value">{item.paintCode || '-'}</span>
              </div>
              <div>
                <span className="item-label">Sublet</span>
                <span className="item-value">
                  {commafyCurrency(item.sublet) || '-'}
                </span>
              </div>
              <div>
                <span className="item-label">Notes</span>
                <span className="item-value">{item.notes || '-'}</span>
              </div>
              <div>
                <span className="item-label">Needs Review</span>
                <span className="item-value">
                  {item.needReview === '1' ? 'Yes' : 'No'}
                </span>
              </div>
            </div>
            <div className="form-footer">
              <Button
                btnText="Close"
                height="42px"
                textColor={CYAN_01}
                bgColor={WHITE_01}
                onClickHandler={() =>
                  this.props.navigate('/core/conditionReview')
                }
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default QuickReview;
