import React, { Component } from 'react';
import { connect } from 'react-redux';

class Convertible2DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }
    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M521.9,264.1v-25.7c0-3.7-2.6-3.9-6.7-3.9c-4.3,0-8.1,10-8.1,17.6c0,5.1,0.8,10.3,1.4,13.2l0.5,2.6l-5.1,0.3
	c-18.6,1.1-48.1,2.2-69.6,2.9c-5.6,0.1-10.6,0.3-14.7,0.4h-2.8c0.5-0.7,0.8-1.2,0.8-1.2h7.3c6.1,0,6.2-8.1,3.2-12
	c-1.1-1.5-2.5-2.6-4.1-3.3c1.4-5,8-28.3,8.4-30.3c0.5-2.2,0.3-3.4-0.8-5.5c-1.1-2.1-8.3-10.7-9.2-11.7c-1-1-4.4-1-7.6,0.8
	c-3.3,1.8-37.4,21.6-51,30.1c-3,1.9-6.6,4.3-10.6,6.7c-13.3,8.5-29.7,19.3-31.5,20.5c-1.8-0.5-3.4-1.1-6.2-2.2c0,0-6.2,1.9-9.9,1.9
	h-3.3c-8.9,0-31.6-0.1-40-0.1c-10,0-26,1.8-36.3,3.9c-8.8,1.8-22.4,5.6-26.4,6.7c-0.1,0-0.6,0.1-0.8,0.3c-7.7,1.7-16.2,3.6-24.4,6.3
	c-1.2,0.4-2.6,0.8-3.9,1.4c-11.4,4.1-25.3,10.6-35.1,17.5c-11,7.6-10.3,7.7-13.3,10.7c-1.4,1.4-2.3,2.9-2.9,4.5h-7.4v27.8h5.8v-4
	c0.4,0.4,1,0.5,1.5,0.5h6.5c1.9,0,4.4,1.6,6.2,6.3c1.8,4.7,2.1,9.9,1.6,12.8c-0.6,2.9-5.4,5.1-5.4,7.4c0,2.5,2.8,2.9,7.3,5
	c4.5,2.1,9.2,1.1,13.3,1.1h0.7h-0.1c0.1,0.5,0.1,1,0.3,1.1c0.4,1.2,1.2,3,3.4,3c3,0,10.7-2.6,14.3-8.9c0.1-0.1,0.3-0.7,0.6-1.2H187
	c0,0-4.4-24.5,5.6-40c4.4-6.9,9.9-12.9,16.6-17.6c8.7-5.9,19.5-9.6,32.9-9.6c18.8,0,32.3,7.7,41.8,18.6c2.5,2.9,4.7,5.9,6.7,9.1
	c9.8,15.5,8.3,36.7,8.3,46.4c0,0,7.4-0.3,18-0.6c18-0.5,45.4-1.2,62.6-1.1c27.5,0.3,113.5-0.7,125.3,0c2.1,0.1,4.1,0.3,6.3,0.4
	c10.7,0.7,22.7,1.4,25.7,1.4c0,0,4.3-6.3,4.8-13.6v-1.9c-0.3-8-1.5-20.4,4.7-33c4.4-9.2,14-20.8,29.7-26.4
	c5.8-2.1,12.4-3.3,19.8-3.3c27.9,0,42,13.6,48.1,24.5c5.8,10.3,6.7,14.7,6.7,40.4v5.1c0,0,25.9-5.1,38.7-7.7
	c3.2-0.7,5.5-1.2,6.5-1.5c5.4-1.5,7.3-1.6,10.7-1.6h10.2c2.8,0,8.9-0.3,13.9-8.5v-8.5c0-1-0.4-1.2-1.2-1.2v-5.1l1.5-0.3v-13.8
	l-12.1-7.2c0,0-0.1-19.9-0.1-24.1c0-4.3-2.2-9.8-2.8-12.9c-0.5-3.3,0.5-8.1-6.2-7.7c-6.6,0.3-23.5,0.7-33.6-0.7
	c-10-1.2-37.1-5.6-56.1-6.7c-14-0.8-20.1-0.7-20.1-0.7s-0.3,0-0.8,0.1c-8.5-1.6-39.5-5.2-39.5-5.2c-4.7,5.1-16.1,8.8-22.1,9.6
	C533.9,262.8,526.9,263.7,521.9,264.1L521.9,264.1z"
          />
          <circle fill="#656565" cx="241.6" cy="355.4" r="48.8" />
          <circle fill="#EBEBEB" cx="241.6" cy="355.4" r="36.5" />
          <circle fill="#656565" cx="596.3" cy="355.4" r="48.8" />
          <circle fill="#EBEBEB" cx="596.3" cy="355.4" r="36.5" />
          <path
            fill="#656565"
            d="M689.9,358.6c-12.8,2.8-38.7,7.7-38.7,7.7v-5.1c-0.1-25.6-1.1-30.1-6.7-40.3c-6.1-10.9-20.2-24.5-48.1-24.5
	c-7.4,0-14,1.2-19.8,3.3c-15.7,5.5-25.3,17.2-29.7,26.4c-6.1,12.7-4.8,25-4.7,33v1.9c-0.3,3.6-1.4,7-2.6,9.5L550,371
	c-1.7-4.8-2.5-10-2.5-15.5c0-27,21.9-48.8,48.8-48.8c27,0,48.8,21.9,48.8,48.8c0,5.5-1,10.7-2.6,15.7h26.1c2.5,0,6.7-3,9.2-6.7H690
	l5.6,2.1H714v-10.9h-6.9c-3.4,0-5.4,0.1-10.7,1.6C695.2,357.5,692.9,357.9,689.9,358.6L689.9,358.6z M299.2,370.1
	c0.1-10.7,0.1-28.5-8.3-42c-2.1-3.2-4.3-6.3-6.7-9.1c-9.6-10.9-23-18.6-41.8-18.6c-13.3,0-24.2,3.7-32.9,9.6
	c-6.7,4.7-12.2,10.7-16.6,17.6c-8.1,12.7-6.7,31.2-5.9,37.7l7.3,1.2c-0.8-3.6-1.4-7.4-1.4-11.3c0-27,21.9-48.8,48.8-48.8
	c27,0,48.8,21.9,48.8,48.8c0,5.1-0.8,10-2.2,14.6L299.2,370.1L299.2,370.1z"
          />
          <path
            fill="#EBEBEB"
            d="M697.6,281.5c-5.8-0.1-16.6,1.5-16.6,13.2c0,3.4,1.5,6.1,3.9,8.1c5.8,5,16.5,6.2,21.9,6.2
	c7.6,0,9.8-7.6,9.8-12.8c0-3.2-0.4-5.4-1.2-7.2c-1.1-1.6-3.9-3.6-4.4-4C704.5,281.3,703,281.7,697.6,281.5L697.6,281.5z"
          />
          <path
            fill="#656565"
            d="M153,378c3,0,10.7-2.6,14.3-8.9c0.1-0.1,0.3-0.7,0.6-1.2h-3.7c-6.1,0-11,5.4-15,5.9c0.1,0.5,0.1,1,0.3,1.1
	C149.9,376.2,150.8,378,153,378L153,378z"
          />
          <path
            fill="#EBEBEB"
            d="M140.2,316.9c0,0,16.8-0.4,24.4-1c4.7-0.4,7.7-3,9.5-6.2c1.1-1.9,1.8-4,2.2-5.6c1-4.7-0.6-8.9-4.4-12
	c-0.6-0.4-1.2-0.7-1.9-1c-4-1.4-10.2,0.3-18.4,4c-9.6,4.4-15.7,11.6-16.2,15.7C134.7,314.8,136.7,316.9,140.2,316.9L140.2,316.9z
	 M305.3,312.6c0,2.2,1.8,3.9,3.9,3.9c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9S305.3,310.6,305.3,312.6z M176.2,327.1h-15.5
	c-2.1,0-3.9,1.6-3.9,3.9c0,2.1,1.6,3.9,3.9,3.9h15.5c2.1,0,3.9-1.6,3.9-3.9S178.4,327.1,176.2,327.1L176.2,327.1z"
          />
          <g>
            <path
              fill="#EBEBEB"
              d="M391.9,237.1c8.5-6.9,21-16,22.1-16.9c1.2-1.1,2.6-2.2,1.1-4.3c-1.2-1.6-3.7-4.7-4.4-5.5
		c-4.3,2.5-11.3,6.5-18.8,11c-10.3,6.1-21.7,12.8-28.2,16.9c-3,1.9-6.6,4.3-10.6,6.7c-13.3,8.5-29.7,19.3-31.5,20.5
		c2.3,0.7,5,1.4,11,2.9c8.3,2.2,14.9,3,20.4,3.3c0.3-0.1,0.4-0.3,0.4-0.3c0.8-1.8,26.1-23.5,33-29.9
		C387.8,240.6,389.7,238.9,391.9,237.1L391.9,237.1z M423.4,225.7c-2.5,1.1-20.2,13.5-31,22.1l-0.5,0.4c-9.2,7.6-20.4,19.8-23.2,23
		c-0.1,0.1-0.4,0.4-0.7,0.8h31c0.5-2.2,1.8-5.9,1.8-5.9c0.7-3.4,2.5-7.6,3.6-9.4c1.2-1.6,4.4-3.4,10.3-3.4h1.5
		c1.9,0.1,3.7,0.3,5.4,0.7c0.1-0.7,0.3-1.5,0.4-1.8c1.4-5.9,6.7-24.6,7.8-27.2C430.8,222.1,425.9,224.6,423.4,225.7L423.4,225.7z"
            />
          </g>
          <path
            fill="#656565"
            d="M479,285.7c-1.7,1-5.4,3-7.3,5.1c-0.3,0.3-0.4,0.5-0.7,1c-1,1.9,0.4,4.1,2.6,4.1H493c4,0,5-2.2,5.5-5
	c0-0.3,0.1-0.4,0.1-0.7c0.5-3-0.3-5.9-4.4-5.9h-11C481.9,284.3,480.8,284.7,479,285.7L479,285.7z M729.9,331.8
	c-3,0.4-10.3,1.6-13.3,2.2c-3.9,0.8-3.3,2.9-0.7,2.9h13.9v-5.1L729.9,331.8L729.9,331.8z M512.6,267.6c21-1.5,38.2-3.3,55.6-5.2
	c15.5-1.6,28.2-4,31.4-4.5c-8.5-1.6-39.5-5.2-39.5-5.2c-4.7,5.1-16.1,8.8-22.1,9.6c-4.1,0.5-11,1.4-16.1,1.8
	c-2.2,0.1-4.1,0.4-5.1,0.4C514.1,264.6,512.8,267,512.6,267.6L512.6,267.6z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="241.6"
            cy="355.4"
            r="48.8"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="241.6"
            cy="355.4"
            r="36.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="241.6"
            cy="355.4"
            r="34.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M245.8,327.1c9.6,1.4,17.7,7.7,21.7,16.1 M215.7,343
	c4-8.4,12.1-14.6,21.7-16 M221.6,376.1c-5.4-5.2-8.7-12.5-8.7-20.6c0-1.4,0.1-2.9,0.3-4.3 M254.2,381.1c-3.9,1.9-8.1,2.9-12.7,2.9
	c-4.7,0-9.2-1.1-13.2-3.2 M270,351.3c0.1,1.4,0.3,2.8,0.3,4.1c0,8.3-3.4,15.7-9.1,20.9 M245.6,334.2c6.5,1.2,11.8,5.2,15,10.7
	 M222.4,345.6c2.9-5.6,8.1-9.8,14.4-11.1 M226,370.3c-3.7-3.9-5.9-9.1-5.9-14.9v-1.5 M250.4,375.1c-2.6,1.2-5.6,1.8-8.8,1.8
	c-3,0-5.9-0.7-8.5-1.8 M263,353.4c0,0.7,0.1,1.4,0.1,2.1c0,5.6-2.2,10.9-5.8,14.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="241.6"
            cy="355.4"
            r="10.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="241.6"
            cy="345.2"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="241.6"
            cy="365.3"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="251.6"
            cy="355.3"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="231.5"
            cy="355.3"
            r="3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M241,321.3c1.6,0,5.2,1.7,5.2,1.7s-1.5,11-0.4,14.3
	c1.1,3.3,4.3,8.3,8.8,8.4s13.8-2.3,16.8-4.3c0,0,1.9,1.5,2.9,3.4 M208.3,345.6c0.6-1.6,3.2-4.4,3.2-4.4s10,4.8,13.5,4.8
	s9.2-1.5,10.7-5.8c1.5-4.3,2.1-13.8,1.1-17.2c0,0,1.9-1.4,4.1-1.8 M221.3,384.3c-1.4-1-3.2-4.4-3.2-4.4s7.7-8.1,8.7-11.4
	c1.1-3.3,1.4-9.2-2.2-12s-12.5-6.2-16.1-6.5c0,0-0.7-2.3-0.4-4.4 M262.2,383.8c-1.4,1-5.2,1.6-5.2,1.6s-5.4-9.8-8.1-11.8
	c-2.8-2.1-8.3-4.1-12-1.6c-3.7,2.6-9.8,9.9-11,13.3c0,0-2.5,0-4.4-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M274.3,344.8c0.5,1.7,0,5.5,0,5.5s-11,2.1-13.8,4.1
	c-2.8,2.1-6.6,6.6-5.2,10.9c1.2,4.3,6.5,12.4,9.2,14.6c0,0-0.8,2.3-2.3,3.9"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="596.3"
            cy="355.4"
            r="48.8"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="596.3"
            cy="355.4"
            r="36.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="596.3"
            cy="355.4"
            r="34.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M600.5,327.1c9.6,1.4,17.7,7.7,21.7,16.1 M570.5,343
	c4-8.4,12.1-14.6,21.7-16 M609,381.1c-3.9,1.9-8.1,2.9-12.7,2.9c-4.7,0-9.2-1.1-13.2-3.2 M624.7,351.3c0.1,1.4,0.3,2.8,0.3,4.1
	c0,8.3-3.4,15.7-9.1,20.9 M600.2,334.2c6.5,1.2,11.8,5.2,15,10.7 M577.1,345.6c2.9-5.6,8.1-9.8,14.4-11.1 M580.7,370.3
	c-3.7-3.9-5.9-9.1-5.9-14.9v-1.5 M605,375.1c-2.6,1.2-5.6,1.8-8.8,1.8c-3,0-5.9-0.7-8.5-1.8 M617.7,353.4c0.1,0.7,0.1,1.4,0.1,2.1
	c0,5.6-2.2,10.9-5.8,14.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="596.3"
            cy="355.4"
            r="10.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="596.3"
            cy="345.2"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="596.3"
            cy="365.3"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="606.4"
            cy="355.3"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="586.3"
            cy="355.3"
            r="3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M595.7,321.3c1.7,0,5.2,1.7,5.2,1.7s-1.5,11-0.4,14.3
	c1.1,3.3,4.3,8.3,8.8,8.4s13.8-2.3,16.8-4.3c0,0,1.9,1.5,2.9,3.4 M563,345.6c0.5-1.6,3.2-4.4,3.2-4.4s10,4.8,13.5,4.8
	s9.2-1.5,10.7-5.8c1.5-4.3,2.1-13.8,1.1-17.2c0,0,1.9-1.4,4.1-1.8 M576.1,384.3c-1.4-1-3.2-4.4-3.2-4.4s7.7-8.1,8.7-11.4
	c1.1-3.3,1.4-9.2-2.2-12c-3.6-2.8-12.5-6.2-16.1-6.5c0,0-0.7-2.3-0.4-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M616.8,383.8c-1.4,1-5.2,1.6-5.2,1.6s-5.4-9.8-8.1-11.8
	c-2.8-2.1-8.3-4.1-12-1.6c-3.7,2.6-9.8,9.9-11,13.3c0,0-2.5,0-4.4-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.9,344.8c0.6,1.7,0,5.5,0,5.5s-11,2.1-13.8,4.1
	c-2.8,2.1-6.6,6.6-5.2,10.9c1.2,4.3,6.5,12.4,9.2,14.6c0,0-0.8,2.3-2.3,3.9 M399.1,272.2c0,0-35.6-0.1-41.8-0.1
	c-6.1,0-13.9-0.7-24.6-3.4c-10.7-2.8-10.9-2.6-17.2-5c0,0-6.2,1.9-9.9,1.9s-33.3-0.1-43.3-0.1s-26,1.8-36.3,3.9
	c-10.3,2.1-27.5,7.2-27.5,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M293.3,265.7c0,0-29.3,1.5-42.4,2.8c-13.1,1.2-31.4,3.9-41.4,5.8
	c-10,1.9-23,4.4-34.9,8.4s-28.1,11.1-39.1,18.8c-11,7.6-10.3,7.7-13.3,10.7c-2.9,2.9-3.6,6.3-4.4,10.7c-0.8,4.4-1.5,11-1.5,13.6
	c0,2.6,1,4.7,2.9,4.7h6.5c0,0,4.4,1.6,6.2,6.3c1.8,4.7,2.1,9.9,1.6,12.8c-0.6,2.9-5.4,5-5.4,7.4c0,2.5,2.8,2.9,7.3,5
	c4.5,2.1,9.2,1.1,13.3,1.1c4,0,9.2-6.1,15.5-6.1h23c0,0-4.4-24.5,5.6-40c9.9-15.5,25.7-27.2,49.5-27.2s38.9,12.1,48.6,27.6
	c9.8,15.5,8.3,36.7,8.3,46.4c0,0,53.2-1.9,80.6-1.6c27.5,0.3,113.5-0.7,125.3,0c12,0.7,28.5,1.6,32.1,1.6c0,0,5.1-7.6,4.8-15.5
	s-1.5-20.4,4.7-33c6.1-12.7,21.6-29.6,49.5-29.6s42,13.6,48.1,24.5c6.1,10.9,6.7,15.3,6.7,45.4c0,0,39.9-7.7,45.1-9.2
	c5.4-1.5,7.3-1.6,10.7-1.6h10.2c2.8,0,8.9-0.3,13.9-8.5v-8.8c0,0-0.5-1.2-1.5-1.2h-13.8c0,0-3.3-2.1,0.7-2.9
	c3.9-0.8,14.7-2.5,14.7-2.5v-13.8l-12.1-7.2c0,0-0.1-19.9-0.1-24.1s-2.2-9.8-2.8-12.9c-0.5-3.3,0.5-8.1-6.2-7.7
	c-6.6,0.3-23.5,0.7-33.6-0.7c-10-1.2-37.1-5.6-56.1-6.7c-14-0.8-20.1-0.7-20.1-0.7s-14.3,2.8-32.2,4.7c-18,1.9-35.8,3.7-57.9,5.4
	c-22.1,1.6-72.2,3.3-92.9,3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M149.3,374c0,0,0.6,4,3.7,4c3.3,0,11.6-2.9,14.9-10 M180.1,330.8
	c0,2.1-1.6,3.9-3.9,3.9h-15.5c-2.1,0-3.9-1.6-3.9-3.9c0-2.1,1.6-3.9,3.9-3.9h15.5C178.4,327.1,180.1,328.7,180.1,330.8L180.1,330.8z
	 M187.6,340.3h-20.8c0,0-25.6,5.5-25.6,7.2c0,1.6,1.5,1.6,5.6,1.6h39.2 M169.9,291c0,0,17.6-6.5,32.2-9.9c14.6-3.4,36.9-7,56.8-8.4
	c19.9-1.5,57.2-1.2,63.1-1.2c5.9,0,11.3-0.3,14.4-1.8 M321.5,265.9c0,0,28.6-18.8,42.2-27.4s47.7-28.3,51-30.1
	c3.3-1.8,6.6-1.8,7.6-0.8s8.1,9.5,9.2,11.7c1.1,2.1,1.2,3.3,0.8,5.5c-0.5,2.2-8.3,30.1-8.3,30.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M362.5,271.9c0,0,18.4-19.3,27.8-27.1c9.4-7.7,27.2-20.9,30.3-22.6
	c3-1.7,6.1-2.5,11.1-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M368.1,271.9c0,0,13.8-15.4,24.5-24.1c10.7-8.8,28.5-21,31-22.1
	c2.5-1.1,7.4-3.6,6.3-0.7c-1.1,2.9-7.6,25.4-8.1,28.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M410.9,210.6c0,0,2.9,3.6,4.4,5.5s0.1,3-1.1,4.3
	c-1.2,1.1-20.8,15.3-27.6,21.6c-7,6.3-32.2,28.1-33,29.9c0,0-7.4,3.6-9.9,5.8c-2.5,2.2-4.3,4-4.4,8.3c-0.1,4.3-2.1,29.3-2.1,36.5
	s0.3,7,1,11.7c0.7,4.7,2.5,15.7,3.4,18.4c1,2.8,2.1,4.5,5.6,4.5s121.5-1.2,131.9-1.9c10.6-0.7,20.1-3,26.8-14.9
	c6.7-11.8,9.9-23.7,10.9-35.5c1-11.8-1.8-27.8-5.4-36.7 M541.7,352c-6.2,0-27.7-0.3-31.9-0.3c-4.3,0-8.9-3-3.4-4.8
	c5.5-1.8,12.7-2.2,17.6-3.3c5-1,10.3-1.2,11.6-1.8c1.1-0.6,2.9-0.8,3.6-5.2c0.7-4.4,10.2-43.7,57-43.7s55.6,34.4,56.4,37.8
	c0.8,3.6,1,7.4,3.9,7.4H682c0,0,3.6,2.6-2.9,4c-6.5,1.5-22,2.9-28.2,2.9 M651,361.3c0,0,47.7-8.8,55.2-10.7
	c7.4-1.9,19.8-3.7,24.9-3.7 M729.9,332v4.8 M731.3,317.9h-47.6c0,0-7.2-1.7-1.1-2.9c6.1-1.2,24.5-3.6,36.6-4.3 M626.2,302.9h58.7
	 M703.6,279.2c0,0,9.9-9.5,10.3-12.5 M714,287.5l4.5-4.3 M642.6,371.1h26.1c0,0,9.4-5,10.7-10.2 M678.1,364.2h11.8l5.6,2.2h18.3
	v-10.7 M689.9,364.2v-5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M494.4,284.3c4.1,0,4.8,2.9,4.4,5.9c-0.5,3-1.2,5.6-5.6,5.6h-19.4
	c-2.6,0-3.9-3-1.9-5.1c1.9-2.1,5.6-4.1,7.3-5.1c1.6-1,2.8-1.4,4.3-1.4L494.4,284.3L494.4,284.3z M118,322c0,0,33.8-0.1,44.7-0.5
	c10.9-0.6,31-1.8,31-1.8s16.8-23.4,48.8-23.4s48.4,22.8,52.5,30.5c4.1,7.8,6.5,15,6.5,17.6s0.8,6.1,5.8,6.1c5,0,91.2-0.1,107.2-0.4
	s49.2-1.2,49.2-4.3c0-3-24.1-4.4-42.8-4.8c-18.7-0.5-86.3-1.1-120.5-1.1"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="309.1"
            cy="312.6"
            r="3.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.7,309.6h35.8 M140.2,316.9c-3.4,0-5.6-1.9-5-6.1
	c0.6-4.1,6.7-11.3,16.2-15.7c9.6-4.4,16.4-5.9,20.2-3c3.9,2.9,5.4,7.3,4.4,12c-1,4.7-4.1,11.3-11.7,12
	C157,316.5,140.2,316.9,140.2,316.9z M138.8,304.2h-7.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M138,304.2c0,0-6.3,4.3-7.4,8.1s-1.4,6.7,2.6,6.7h60.7 M119.1,316.8h-7.3
	v27.8h5.8v-3.9 M512.4,267.8c0,0,1.2-3.2,4.4-3.3c3.2-0.1,15.1-1.4,21.2-2.2c5.9-0.8,17.5-4.5,22.2-9.6c0,0,31.6,3.6,39.8,5.2
	 M584,264.2c0,0,15.3-1.8,31.2-1.8s33.3,1.8,51.7,5.4c18.4,3.6,28.5,6.7,34.4,9.9c5.8,3.2,9.5,6.2,11.7,8.4c2.2,2.2,3.4,5,3.4,10.2
	c0,5.2-2.2,12.8-9.8,12.8c-7.6,0-25.7-2.5-25.7-14.2c0-11.8,10.9-13.3,16.6-13.2c5.8,0.1,7.2-0.4,14.9,4.7 M305,374.4v-8.3h235.9
	 M400.9,266.1c0.7-3.4,2.5-7.6,3.6-9.4c1.2-1.6,4.4-3.4,10.3-3.4c5.8,0,10.3,1.1,13.3,5c3,3.9,2.9,12-3.2,12h-7.3
	c0,0-3.4,4.7-4.4,6.1c0,0-7.2,0.5-10.6,0.1c-3.4-0.4-4-1.8-3.9-2.9C398.8,272.5,400.9,266.1,400.9,266.1L400.9,266.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M416.1,253.3c0,0,5.8,2.2,8,4.4c2.1,2.1,4,5.1,4,5.1 M508.8,267.6
	c0,0-1.8-8-1.8-15.5s3.9-17.6,8.1-17.6c4.3,0,6.7,0.1,6.7,3.9v26.1 M687.7,294.5c0-6.3,6.6-10.5,10.6-10.5c4,0,4.7,2.6,4.7,3.6
	c0,0.8-1.2,2.9-1.2,6.7c0,4.1,2.9,7.7,2.9,9.1s-1.1,2.2-4.3,2.2C697.2,305.5,687.7,302.3,687.7,294.5L687.7,294.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M707.6,285c1.4,0,3.9,3.9,4.4,9.2c0.5,5.4,0.5,8.5,0.4,10.2
	c-0.1,1.6-1.9,2.2-2.8,2.2s-4.7-3.9-5.9-10.5C702.3,289.7,704.7,285.1,707.6,285L707.6,285z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M413.2,276.3
		c0,0-11.6,1.1-13.8-0.8c0,0-1.1-0.5-0.7-2.2c0.4-1.8,2.1-7.2,2.1-7.2s1.9-7.8,4.3-10c0,0,2.3-3,12.2-2.6c0,0,9.6,0,12.1,7.6
		c0,0,2.3,8.4-4.1,9.2h-7.7L413.2,276.3L413.2,276.3z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M399.1,272.1
		l-31-0.1c0,0,13.3-14.6,19.1-19.5l4.1-3.7c0,0,17.6-14.3,31.5-22.8c0,0,2.3-1.4,5.2-2.2c0,0,2.2-0.7,1.9,0.8
		c-0.3,1.5-3.9,10.9-8.4,29.4c0,0-2.9-0.8-6.9-0.7s-8.5,0.7-10.6,4c-2.1,3.3-2.8,6.7-3.3,8.5C400.3,267.5,399.1,272.1,399.1,272.1
		L399.1,272.1z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M210.2,309.6h-36.3
		c0,0,3.2-5.4,2.3-9.8c-0.8-4.4-2.6-6.9-6.5-8.7c0,0,29.6-12.7,78-17.3c0,0,24.2-2.8,72.2-2.3c0,0,12.1,0.4,16.4-1.9
		c0,0,8.5,1.9,16.4,2.3c0,0-7.8,3.4-10.9,7s-2.8,5-2.9,6.9c-0.1,1.9-1.9,30.4-1.9,30.4s-0.5,10.6,0.1,13.2
		c0.7,2.6,1.2,11.3,3.6,21.2h-42.5c0,0-0.8-26.1-28.1-43.1c0,0-7.6-4.8-18.2-6.3C241.4,299.8,225.6,300,210.2,309.6L210.2,309.6z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="241.4"
              cy="355.3"
              r="49"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="596.5"
              cy="355.7"
              r="49"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M352.9,271.9
		c0,0,7.7,0.5,46.2,0.3c0,0-0.8,2.5,0,3.2c0.8,0.5,2.2,1.4,5.2,1.5c3,0,6.1-0.1,8.8-0.3l3.6-4.8c0,0,52.4-1.4,94.6-3.7
		c0,0,12.9,31-1.2,63.8c0,0-5.1,13.2-12.9,18c-7.8,4.8-15.7,5.2-21.9,5.4l-128.2,1.8c0,0-4.7,0.8-6.1-6.1c0,0-1.8-8.4-2.3-12.5
		c0,0-1-6.7-1.2-8.5c0,0-0.3-1.5-0.4-3.2c0-1.6,0.1-8.8,0.8-19.8l1.2-20.5c0,0-0.3-4,2.3-6.6c0,0,1.8-2.5,6.6-5.2L352.9,271.9
		L352.9,271.9z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M541.7,352
		l-47.5-0.7c0,0,7.3-3.3,11-10.3c3.9-7,5.8-10.2,8.7-20.5c0,0,4-14.7,2.6-27.6c0,0-0.4-12.5-5.2-25c0,0,30.5-2.5,64.1-6.2l8.5,2.8
		c0,0,29.4-3.3,48.7-1.1c19.4,2.2,42.8,4,67.5,14.2c0,0,8.4,4.7,12.1,8.4l-0.5,0.4c0,0-6.2-4.4-9.6-4.1c-3.4,0.1-9.4-1.1-15.3,1.9
		c0,0-5.5,2.2-6.1,9.4c0,0-1.2,5.6,4,9.8h-59.6c0,0-11.1-6.9-30.3-6.5s-34,8.4-44.4,22.6C550.8,319,541.3,330.1,541.7,352L541.7,352
		z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Convertible2DrLeft);
