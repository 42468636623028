import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeReadableDate } from '../../utils/helpers';

class Accounts extends Component {
  renderPersonalAccount(accountList) {
    const personalAccount = accountList[0];
    if (!personalAccount) return null;

    return (
      <div>
        <h3>Personal Account</h3>
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '10px 15px',
          }}
        >
          {personalAccount.accountName}
        </div>
      </div>
    );
  }

  renderDealerAccounts(accountList) {
    const marketplaceFeatures =
      (this.props.marketplaceFeatures.features || '').split(',') || [];

    const dealerAccounts = accountList.slice(1);

    if (!dealerAccounts.length) return null;

    return (
      <div>
        <h3>Dealer Accounts</h3>
        <div>
          {dealerAccounts.map((account, index, arr) => {
            const dateFormat = 'ddd MM/DD/YYYY LT z';
            const firstItem = index === 0;
            const lastItem = index === arr.length - 1;

            return (
              <div
                key={index}
                style={{
                  backgroundColor: '#fff',
                  borderRadius: firstItem ? '4px 0' : lastItem ? '0 4px' : '0',
                  border: '1px solid #ddd',
                  borderTop: firstItem ? '1px solid #ddd' : 'initial',
                  padding: '10px 15px',
                  minHeight: 80,
                }}
              >
                <div style={{ fontWeight: 'bold', marginBottom: 5 }}>
                  {account.accountName}
                </div>
                {this.renderRow(
                  'Account Number: ',
                  account.accountNumber || 'null',
                  marketplaceFeatures.includes('469')
                )}
                {this.renderRow(
                  'Badge Number: ',
                  account.badgeNumber || 'null',
                  marketplaceFeatures.includes('469')
                )}
                {this.renderRow(
                  'Message: ',
                  account.badgeMessage,
                  !!account.badgeMessage
                )}
                {account.enableBidding &&
                  this.renderRow(
                    'Enabled to Buy: ',
                    account.enableBiddingActivationDate !==
                    '1900-01-01T00:00:00'
                      ? makeReadableDate(
                          account.enableBiddingActivationDate,
                          dateFormat
                        )
                      : 'Date N/A',
                    !!account.enableBidding
                  )}

                {this.renderRow(
                  'Enabled to Sell: ',
                  account.sellerActivationDate !== '1900-01-01T00:00:00'
                    ? makeReadableDate(account.sellerActivationDate, dateFormat)
                    : 'Date N/A',
                  account.seller === 1
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderRow(label, value, condition) {
    if (!condition) return null;

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
        }}
      >
        {label && <div style={{ paddingRight: 10, width: '18%' }}>{label}</div>}
        <div>{value}</div>
      </div>
    );
  }

  render() {
    const { accountList } = this.props;

    if (!accountList.length) return null;

    return (
      <div className="container-fluid">
        {this.renderPersonalAccount(accountList)}
        {this.renderDealerAccounts(accountList)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { marketplaceFeatures } = state.entities;
  return { marketplaceFeatures };
};

export default connect(
  mapStateToProps,
  {}
)(Accounts);
