import React, { Component } from 'react';
import { connect } from 'react-redux';

class SportsVanLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 140 810 340">
          <path
            fill="#FFFFFF"
            d="M802.5,282.9c-0.4-2.6-1-5.2-1.5-7.7c-0.3-1-0.4-1.9-0.7-2.9c-1.5-6.6-3.3-12.8-5.4-18.8l-0.7-2.1
	c-0.1-0.6-0.4-1.1-0.5-1.5c-5.9-16.6-13.2-30.5-20.2-41.5c-11.1-17.6-21.7-28.1-24.8-31.2c0.3-3.3,2.2-6.7-3.7-8.4
	c-4.8-1.4-20.8-3.7-36.5-5c3.2,0.3,6.5,0.7,9.9,1.2c-6.3-0.6-13.2-1.1-19.5-1.2l-0.5-0.6H698c1.8,0,3.4,0,5.2,0.1
	c-1.7-0.1-3.4-0.1-5.1-0.3c-3.3-3.6-8.9-5.4-18.7-5.9c-5.4-0.3-77.7-2.5-146-2.3c-42.9,0-84.6,1.4-104.8,2.1
	c-5.5,0.1-9.5,0.4-11.4,0.4c-14.9,0.6-20.6,1-34.1,6.7c-12.9,0.8-34.4,1.5-65.3,12.1c-21.2,7.3-58.5,26.7-129.2,74.3
	c-3.3,0.8-6.5,1.4-11.3,1.2c-14.3,2.9-89.1,16.1-117.1,32.9c-1.7,1-3,1.8-4.4,2.6c-4.1,2.5-7.7,5.1-13.5,13.3
	c-4.8,6.7-10.3,15-14.2,21.5c-0.1,0.3-0.3,0.4-0.4,0.7c-2.2,3.9-3.9,6.9-4.4,8.7c-0.3,0.8-1.2,4.5-1.5,6.3c-0.5,0.3-0.4,5-0.1,6.1
	v0.1c-0.7,8.7-1,19.7-1.1,21.5c5.8,1.1,7,8.1,8.7,13.5c0.8,3,1.1,7.2,0,7.6c-1.2,0.1-1.4,0.1-1.5,1.1c-0.1,1,0.8,2.6,1.9,3.3
	c5.2,3.7,8.3,4.1,11.3,4.3c2.1,0.1,4.4,0.4,4.4,0.4s0.1,5.4,0,8.3c2.6,0.8,32.5,2.3,37.1,2.5c2.8,0.1,2.5-0.1,2.6-1.9
	c0.1-1.5,0-7.7,0-7.7c-0.4,0-1.1-0.1-1.1-0.1v-1.1c-0.3-2.1-1.7-3.2-1.8-6.9c-1.1-22.3,4.3-78.7,67-78.5
	c77.7,0.3,70.4,81.4,70.3,84.3c-0.1,3.9,0.4,4.8,2.9,4.8h23.9c81-0.1,340.7-0.4,344.9-0.5c5.2-0.1,5.2-1,5.4-3.6
	c0.1-1.6,0.3-6.9-0.3-8c0.1-3.7,0.5-11,1.5-16.8c3.6-24.5,15.8-61.2,68-61.4c19.8,0,33.8,4.5,44,11.3c21,14.3,24.5,38.5,25.6,51.7
	c0.8,10.7-0.5,15.7-0.8,17.5c-0.1,0.3-0.1,0.6-0.3,0.8c-0.3,1-0.5,1.8-0.1,2.6c34.1-3.2,74.4-9.1,81-11.1c5-1.6,5-10.9,4.8-11.4
	l0.1-0.1c0.3-0.3,0.8-1.1,1.4-1.8c0.4-0.5,0.7-1.1,1.1-1.5c1.7-4.3,0-44.2,0-47.2c0-3-9.1-5.9-16.8-8.7c-0.5-9.1-1.5-17.5-2.8-25.5
	C802.8,284.3,802.7,283.6,802.5,282.9L802.5,282.9z"
          />
          <path
            fill="#FFFFFF"
            d="M22,343.6C22,343.7,22,343.6,22,343.6z M61.2,337.3l-38.9,0.1l-0.4,6.1v0.1c1.2,0,29,0.3,36.6,0
	c2.3-0.1,2.5,0.3,3.2-1C64.5,336.4,64,337.3,61.2,337.3L61.2,337.3z M410.9,328.9c-77.3,1.1-154.2,3.9-163.8,4.1
	c-1.4,0-2.3-0.1-1.8,1.5c1.4,4.4,0.7,3.7,4.1,3.7c12.1,0,85.2-0.4,161.4-1.5h0.3c72.6-1,145.5-2.3,159.3-2.8c3-0.1,3.4,0,3.8-1.2
	c2.2-5.1,2.5-4.8-0.4-4.8C562.2,327.9,486.8,327.8,410.9,328.9L410.9,328.9z M822.5,325.3c0,0-62-0.3-71.8-0.1
	c-2.8,0-2.6-0.5,0.4,5.1c1,1.6,1.7,1.2,4.1,1.2c11.1,0,67.5,0.3,67.5,0.3L822.5,325.3L822.5,325.3z"
          />
          <path
            fill="#EAEBEB"
            d="M56,286.9c-3.3,2.1-6.3,4.1-10.5,9.4c-3.9,6.3-7.7,15.3-6.3,18.3c1.2,2.9,4.4,4.3,5.5,4.8
	c5.1-2.1,11.6-5.4,19.9-10.5c1.5-1,3.2-1.9,4.8-2.9c3.3-5.8,6.5-12.4,8.5-17.1c0.4-1,0.8-1.9,1.1-2.6l-1.7-1.5
	C71.9,284.7,60.8,285.4,56,286.9C56.1,286.8,56.1,286.9,56,286.9L56,286.9z"
          />
          <path
            fill="#5E5E5E"
            d="M151.9,327.6c-30.8,0-55.8,24.9-55.8,55.6c0,30.8,24.9,55.9,55.7,55.9c26.6,0,48.7-18.3,54.5-43.1
	c1-4,1.4-8.3,1.4-12.5C207.6,352.7,182.7,327.6,151.9,327.6L151.9,327.6z"
          />
          <path
            fill="#EAEBEB"
            d="M151.2,345c-21.2,0-38.4,17.2-38.5,38.4c0,21.2,17.2,38.4,38.4,38.5c21.2,0,38.4-17.1,38.5-38.4
	C189.6,362.3,172.4,345,151.2,345z"
          />
          <path
            fill="#5E5E5E"
            d="M219.6,394.1c0.1-2.9,7.4-84.1-70.3-84.3c-62.7-0.1-68.1,56.3-67,78.6c0.1,3.7,1.5,4.8,1.8,6.9h13.1
	c-0.8-3.9-1.2-7.8-1.2-12c0-30.8,25-55.7,55.8-55.6c30.8,0,55.7,25,55.7,55.9c0,4.3-0.6,8.5-1.4,12.5l13.5-1.2L219.6,394.1
	L219.6,394.1z"
          />
          <path
            fill="#EAEBEB"
            d="M316.8,176.4c-21.3,7.6-58.6,27.1-128.2,73.9c-0.1,0-0.3,0-0.4,0.1c0.6,0.1,1,0.4,1.1,0.3
	c8.7-2.2,13.9-7.3,19-10.6c7-4.5,50.3-35.4,95.6-57.4c2.9-1.4,6.9-3.6,12.4-5.8C316.4,176.9,316.7,176.7,316.8,176.4L316.8,176.4z"
          />
          <path
            fill="#FFFFFF"
            d="M257.1,254.4c-4.4,0.6-5.5,1-6.3,3c-0.6,1.2-6.2,1.1-7,1.1c-1.9-0.1-5.2-1.1-7-2.5c-1.5-1.2-1.1-6.6,0-11
	c1.6-6.9,2.8-7.4,4.7-8.7c1.5-1.1,4.3-2.9,6.7-4.4c2.5-1.5,5-2.2,7.8-1.9c0.1,0,0.4,0,0.5,0.1v-5.5c-7.4,4.8-14.4,9.6-20.6,14
	l-2.1,21.5c0,0,1.8-20.1,2.1-21.3c-14.2,10-25.2,18.7-31.5,23.8c6.5-0.4,16.5-1.2,29.3-2.1c7.2-0.5,15.1-1,23.9-1.5v-4.7
	L257.1,254.4L257.1,254.4z M233.9,260.4l-0.1,0.3C233.9,260.5,233.9,260.4,233.9,260.4z"
          />
          <path
            fill="#EAEBEB"
            d="M302.8,197.5c-16.6,8.7-32.2,18.2-46.1,27.4v5.4c1.2,0.3,2.6,1.2,3,3.7c1,5.2,1.6,11.8,1.9,14.3
	c0.3,2.3,0,5.4-3.9,6.1v4.7c36.2-2.3,85.2-5.2,138.8-7.4c0.1-2.6,2.3-42.1,3-76.1c-5.4,0.3-19.1,1-27.4,2.1
	C337.2,181.9,312.7,192.3,302.8,197.5L302.8,197.5z"
          />
          <path
            fill="#FFFFFF"
            d="M362.1,272.3c-3.9,0.5-2.6,6.5,0,6.6c1.6,0.1,3.6-0.1,4.8-0.3c0.8-0.1,1.2-0.3,1.2-0.3s1.1-0.8,1.6-1
	c1.9-0.1,9.6-0.4,14.3-0.3c0.3,0.3,1.1,0.5,1.1,0.5h10.6c2.9,0,5.4,0.4,5.4-3.4c0-3.2-1.1-4.1-3.9-4.1c-1.1,0-5.8,0.1-11.4,0.4
	c-6.1,0.3-13.3,0.7-18.6,1.1C365.2,271.9,363.3,272.1,362.1,272.3L362.1,272.3z M423.8,268.9c-2.8,0.1-3.7,1.2-3.6,4.4
	c0.1,3.9,2.6,3.2,5.6,3c1.5-0.1,6.3-0.4,9.2-0.5c1.4-0.1,2.2-0.1,2.2-0.1s0.8-0.4,1.1-0.7c4.8-0.3,12.1-0.4,14-0.4
	c0.4,0,1.6,0.8,1.6,0.8s1.1,0.1,2.2,0.1c1.1,0,2.6,0.1,3.9,0c2.8-0.3,3.7-6.2-0.3-6.6c-1.1-0.1-2.8-0.1-4.7-0.3
	c-4.8-0.1-11.6-0.1-17.5,0c-13.9,0.1,5.5-0.1-7.7,0.1C426.7,268.8,424.5,268.9,423.8,268.9L423.8,268.9z"
          />
          <path
            fill="#EAEBEB"
            d="M424.7,174.4v76.1l147.6-6.1l-2.8-71.9C521,172.2,472.7,172.6,424.7,174.4z"
          />
          <path
            fill="#FFFFFF"
            d="M428.8,156.8l0.1,5.4C428.4,159.8,428.8,157.1,428.8,156.8z M533.6,154.7c-42.9,0-84.6,1.4-104.8,2.1
	c-5.5,0.1-9.5,0.4-11.4,0.4c-14.9,0.6-20.6,1-34.1,6.7h-0.1c0,0.3,0.1,0.6,0.3,0.6c2.2-0.1,12.8-0.7,29.3-1.4
	c38-1.5,106.9-3.7,176.4-2.9c45,0.6,85.4,2.3,101.9,3c2.3-0.1,4.8-0.1,7.2-0.1h0.4l-0.1-0.1h-0.1l-0.8-0.8
	c-3.6-2.8-9.5-4.7-17.9-5.2C674.2,156.9,601.8,154.7,533.6,154.7L533.6,154.7z M662.1,161.6l-0.4-5
	C662.4,158.2,662.5,160.4,662.1,161.6L662.1,161.6z"
          />
          <path
            fill="#EAEBEB"
            d="M592.6,173.3c1.1,7.2,7.3,34.3,8.7,70.2c0.1,0,0.1,0.1,0.3,0.1h1.2c20.8-0.6,39.3-1.1,57.4-1.2
	c48.7-0.6,89.1-1.6,99.5-2.1c0-1-0.3-1.8-0.3-1.8s-10.2-23.2-21.5-37c-18-22-34.8-27.6-63.4-30.5c-2.1-0.3-4.1-0.4-6.2-0.6
	c-26-2.3-72.5-2.2-76.5-2.2h-0.1C591.9,169.7,592.3,171.5,592.6,173.3L592.6,173.3z"
          />
          <path
            fill="#5E5E5E"
            d="M667.3,327.6c-30.8,0-55.9,24.9-55.9,55.6c0,30.8,24.9,55.9,55.7,55.9c28.6,0,52.1-21.3,55.4-49
	c0.3-2.2,0.4-4.4,0.4-6.6C723.2,352.7,698.1,327.6,667.3,327.6L667.3,327.6z"
          />
          <path
            fill="#EAEBEB"
            d="M666.8,345c-21.2,0-38.5,17.2-38.5,38.4s17.2,38.4,38.4,38.5c21.2,0,38.5-17.1,38.5-38.4
	C705.2,362.3,688,345,666.8,345L666.8,345z"
          />
          <path
            fill="#5E5E5E"
            d="M665.7,308.6c-52,0.1-64.4,36.9-68,61.4c-0.8,5.8-1.4,13.2-1.5,16.8c0.4,1.1,0.3,6.1,0.3,7.8h16.1
	c-0.7-3.6-1.1-7.4-1.1-11.3c0-30.8,25-55.7,55.8-55.6c30.8,0,55.7,25,55.7,55.9c0,2.2-0.1,4.5-0.4,6.6l11.4-0.3
	c0.1-0.3,0.1-0.5,0.3-0.8c0.3-1.8,1.7-6.7,0.8-17.5c-1.1-13.2-4.5-37.4-25.6-51.7C699.5,313,685.5,308.5,665.7,308.6L665.7,308.6z"
          />
          <path
            fill="#EAEBEB"
            d="M761.6,194.7c10.2,13.3,23.4,34.7,30.3,56.5h2.5l-0.5-1.5c-5.9-16.6-13.2-30.5-20.2-41.5
	c-11.1-17.6-21.7-28.1-24.8-31.2l-0.3-0.6l-0.7,2.3C747.8,178.8,753.8,184.7,761.6,194.7L761.6,194.7z M802.5,282.9
	c-0.4-2.6-1-5.2-1.5-7.7l-0.7-2.9c-1.5-6.6-3.3-12.8-5.4-18.8c-0.3-0.7-0.4-1.4-0.7-2.1h-2.5c-3.7,0-9.2,0-13.2-0.1
	c-2.2,0-3.9-0.1-4.4-0.1c-2.3-0.1-4.8,0-3.2,5.4s8,18.7,9.4,53.9c0.8,0,1.9,0,3,0.1c7.7,0.1,19.9,0.3,22.1,0.3c0,0,0.1-0.1,0.3-0.1
	h-0.1c-0.5-9.1-1.5-17.5-2.8-25.4L802.5,282.9L802.5,282.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M188.7,250.3c-3.3,0.8-6.5,1.4-11.3,1.2c-14.3,2.9-89.1,16.1-117.1,32.9
	c-6.5,3.9-10.2,5-18,16c-7.8,11-17.6,26.1-18.8,30.8c-0.3,0.8-1.2,4.5-1.5,6.3c-0.5,0.3-0.4,5.4-0.1,6.2c-0.7,8.8-1,19.7-1.1,21.5
	c5.8,1.1,7,8.1,8.7,13.5c0.8,3,1.1,7.2,0,7.6c-1.2,0.1-1.4,0.1-1.5,1.1c-0.1,1,0.8,2.6,1.9,3.3c5.2,3.7,8.3,4.1,11.3,4.3
	c2.1,0.1,4.4,0.4,4.4,0.4s0.1,5.4,0,8.3c2.6,0.8,32.5,2.3,37.1,2.5c2.8,0.1,2.5-0.1,2.6-1.9c0.1-1.5,0-7.7,0-7.7
	c-0.4,0-1.1-0.1-1.1-0.1s0-1.5-0.4-2.6c-0.7-1.4-1.2-3.7-1.4-5.5c-1.1-22.3,4.3-78.7,67-78.5c77.7,0.3,70.4,81.4,70.3,84.3
	c-0.1,3.9,0.4,4.8,2.9,4.8c4,0,363.9-0.4,368.7-0.5c5.2-0.1,5.2-1,5.4-3.6c0.1-1.6,0.3-6.9-0.3-8c0.1-3.7,0.5-11,1.5-16.8
	c3.6-24.5,15.8-61.2,68-61.4c61.1-0.1,68,43.6,69.5,63c0.8,10.7-0.5,15.7-0.8,17.5c-0.3,1.1-1,2.3-0.4,3.4
	c34.1-3.2,74.4-9.1,81-11.1c5-1.6,5-10.9,4.8-11.4c0,0,1.5-1.9,2.6-3.6c1.7-4.3,0-44.2,0-47.2c0-3-9.1-5.9-16.8-8.7
	c-1.4-23.2-5.5-42-11.3-59c-0.1-0.6-0.4-1.1-0.5-1.5c-15.3-43.1-39.9-67.7-45-72.8c0.3-3.3,2.2-6.7-3.7-8.4
	c-5.9-1.6-28.3-5-46.8-5.5c-3.3-3.6-8.9-5.4-18.7-5.9c-5.4-0.3-77.7-2.5-146-2.3c-54.6,0-107.3,2.2-116.2,2.5
	c-14.9,0.6-20.6,1-34.1,6.7c-12.9,0.8-34.4,1.5-65.3,12.1C296.6,183.3,259.3,202.7,188.7,250.3L188.7,250.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M412.7,163.1l-1.6,85.8c0,0-0.7,2.9-0.7,4.8s0.5,38,0.8,132.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M596.5,386.6c0,0-305.4-0.1-365.5,0.7c-6.6,0.1-11,0.8-11,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.2,232.7c0,0,0,5-0.3,6.2s-2.1,21.3-2.1,21.3s-0.7,1.5-1,4
	c-1.8,5.5-3.7,23.1-2.8,43.5c0.6,12.7,3,34,6.3,52.7c0.8,1,1.2,4,0.8,5.4c1.8,9.2,3.6,16,5.5,21.6c0.1,6.1,1.9,9.2,3.9,11.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.3,360.5L579,356c0,0,2.2,1.2,4.7,2.3c2.9,1.4,6.2,2.6,6.2,2.6
	L226.4,366c0,0,3.9-1.5,6.2-3C234.4,361.9,236.3,360.5,236.3,360.5L236.3,360.5z M196.2,263.2c1.2-1.4,45.8-39.1,102.5-69.7
	c9.6-5.2,33.4-15.5,65.6-20.4c10.7-1.5,20.5-2.2,48.4-3.4c30.5-1.4,83.5-1.6,179.1-1.8c4,0,50.5,0,76.5,2.2
	c32.5,2.9,50.5,7.6,69.7,31.1c11.3,13.9,21.5,37,21.5,37s0.4,1.2,0.3,2.5c-0.3,2.3-1.7,3-6.6,3.2c-16.2,0.6-75.7,1-150.2,3
	c-60,1.6-127.4,4.8-192,7.4c-116,4.5-208.1,12.9-211.2,12.8C195.1,267.2,194.9,264.6,196.2,263.2L196.2,263.2z M794.3,251.3
	c-0.7,0-17.7-0.1-19.9-0.3c-2.3-0.1-4.8,0-3.2,5.4c1.7,5.4,8,18.7,9.4,53.9c7.6,0.3,25.5,0.4,25.5,0.4 M780.4,310.6c0,0,1.7,8-7.4,8
	s-63.4,1.4-63.4,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M579,356c6.6-30.5,25.7-68.5,85.6-68.6c63.4-0.1,81.4,36.5,87.1,63.7
	c-2.9,2.1-5.2,5.8-5.2,5.8s4.3,20.1,1,34.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M591.4,386.5c0.1-3.9,0.5-10.3,1.4-16.4c3.9-27,17.1-67.3,73.2-67.4
	c65.9-0.1,73.2,47.9,75,69.2c1,11.8-0.8,19.9-0.8,19.7 M45.9,394.9c0,0,37.7,1.8,38.4,1.9 M225.5,387.5c0.1-21.6-5.4-84.6-75.8-83.9
	c-38.5,0.3-58.1,19.9-65.3,37.6c-6.2,15.1-7.7,28.3-7.2,46.6c0.1,2.9,0.6,6.2,1,8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.3,360.5c-3-12.9-7.8-23.2-10.3-28.8c-11.6-25-36.5-42.4-75.7-42.2
	c-59.6,0-79.1,40-84.1,71.8c3.7,1.2,8,3.9,8,3.9s-1.1,14.4-0.1,30.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M66.3,361.2c0,0-24.2,1.9-39.1,2.8c-3.7,0.1,2.1,1.4,9.2,1.5
	c7.3,0.1,35.9-0.1,37.7-0.1 M822.4,366.1c0,0-11.8,1.5-16.8,1.9c-6.2,0.5-6.2,2.2,0.3,2.1c6.3-0.1,14.3-0.5,14.3-0.5 M759.5,240.3
	c-1,0.1-43.5,1.4-99.5,2.1c-18,0.1-36.7,0.7-57.4,1.2c-60,1.6-127.4,4.8-192,7.4c-116,4.7-214.5,12.2-214.5,12.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M572.3,244.7c0-10.6-1.8-56.7-2.8-72.2c-73.5,0.1-117.3,0.8-144.9,1.9
	c-0.3,5.5-0.1,75.9-0.1,75.9 M204.7,262.6c13.8-11.1,51.9-40.7,98.1-64.9c9.9-5.2,34.4-15.5,69.5-19.9c8.3-1.1,22-1.8,27.4-2.1
	c-0.8,35.4-3,76.2-3,76.2 M256.7,224.7l-0.1,5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.9,256.1c1.6,1.4,5.1,2.3,7,2.5c0.8,0,6.5,0.3,7-1.1
	c0.8-2.1,1.9-2.5,6.3-3c4.4-0.6,4.7-3.7,4.4-6.2s-0.8-9.1-1.9-14.3c-0.5-2.9-2.2-3.7-3.6-3.9c-2.9-0.3-5.4,0.4-7.8,1.9
	c-2.5,1.4-5.2,3.3-6.7,4.4c-1.8,1.2-3,1.8-4.7,8.7C235.6,249.5,235.4,254.9,236.9,256.1L236.9,256.1z M641,262.1
	c6.5-0.4,12,4.5,12.2,11s-4.5,12-11,12.2c-6.5,0.4-12-4.5-12.2-11C629.6,268.1,634.6,262.6,641,262.1L641,262.1z M367.4,271.6
	c1-1.9,2.9-2.9,5.2-2.9l7.8-0.1c2.1,0,3.6,0.5,4.7,2.1 M386.8,277.7c-0.4,2.9-2.9,5.1-6.1,5.2l-7.8,0.1c-2.9,0-5.2-1.7-5.9-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M362.1,272.3c7-1.1,32.5-2.1,35.2-2.1c2.8,0,3.7,1,3.9,4.1
	c0,3.9-2.3,3.3-5.4,3.4h-10.6l-1.1-0.5c-4.7,0-12.4,0.3-14.3,0.3c-0.4,0-1.6,1-1.6,1s-3.4,0.7-6.2,0.5
	C359.5,278.8,358.2,272.9,362.1,272.3L362.1,272.3z M455.2,268.6c-1-1.6-2.5-2.5-4.8-2.5h-7.8c-2.1,0-4.1,0.7-5.1,2.5 M436.2,275.8
	c0.4,2.9,2.9,4.8,5.9,5h7.8c2.9,0.1,5.4-1.9,6.1-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M459.9,268.9c-7.2-0.7-33.3-0.1-36,0c-2.8,0.1-3.7,1.2-3.6,4.4
	c0.1,3.9,2.6,3.2,5.6,3c2.3-0.1,11.6-0.7,11.6-0.7s0.8-0.4,1.1-0.7c4.8-0.3,12.1-0.4,14-0.4c0.4,0,1.7,0.8,1.7,0.8s3.3,0.4,6.1,0.1
	C462.9,275.2,463.9,269.3,459.9,268.9L459.9,268.9z M27.8,322.4c8.9,0.5,17.5-1.6,41.8-16.5c4-7,7.8-15.3,9.6-19.7
	c0.1-0.4-0.7-1.5-1.7-1.5c-5.9-0.1-17.6,0.5-21.6,2.2 M69.3,306.2c0,0-8.1,17.6-9.1,20.2c-1,2.6-2.5,4.3,3.7,4.5
	c6.2,0.1,34.7-1.4,34.7-1.4 M22.3,337.4c0,0,30.4-0.1,38.9-0.1c2.2,0,2.8-0.3,2.1,1.6c-0.5,1.2-1.2,2.9-1.7,3.9
	c-0.5,1.2-0.7,1-3.2,1c-7.7,0.3-36.6,0-36.6,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M79.2,286.2c6.6-2.1,31.9-4.3,50.8-5.9c64.5-5.9,273.8-18,280.6-18.3
	c6.7-0.3,345.7-9.9,354.4-10.2c5-0.1,5.1-3.2,2.6-10.2c-2.9-8.1-13.8-25.7-18.4-32.3c-10.3-14.2-25.4-30.5-47.9-35.1
	c-10.9-2.2-18.8-2.8-28.2-3.4 M77.6,284.7c0,0,27.9-11.7,120.9-17.3 M247.1,333c9.6-0.4,86.5-3,163.8-4.1
	c75.9-1.1,151.2-1,162.9-0.8c2.1,0,2.5,0,1.8,1.6c-0.4,1-1,2.3-1.4,3.3c-0.5,1.2-0.8,1.1-3.9,1.2c-13.8,0.3-86.9,1.6-159.7,2.8
	c-76.1,1.1-149.3,1.4-161.4,1.5c-2.2,0-2.9,0.4-3.3-1c-0.3-0.8-0.6-1.9-0.8-2.8C244.7,332.7,245.8,333,247.1,333L247.1,333z
	 M751.6,351c0,0,54.3,0.4,59.4,0.5c8.9,0.1,9.4,1.1-1.5,1.5c-16.2,0.7-63.3,3.9-63.3,3.9 M822.2,318.3l-46.8,0.1 M822.5,325.3
	c0,0-62-0.3-71.8-0.1c-1.7,0-2.5-0.3-1.7,1.4c0.5,1.1,1.4,2.6,2.1,3.9c1,1.6,1.7,1.2,4.1,1.2c11.3,0,67.5,0.3,67.5,0.3 M23.5,331.1
	l38.2-0.4 M47.5,294.1c-4.4,5.9-9.8,16.9-8.3,20.5c1.7,3.6,5.8,4.7,5.8,4.7 M257.7,254.6v4.1 M201.9,244.7l7.6,8 M84.1,395.2
	c0,0,5.2,0,12.4,0.1 M733.8,389.8l-11.4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M571.6,386.2c0.7-9.4,1.8-18,3-25.2c0.7-1.6,1.1-3.4,1-5.1
	c6.2-28.6,16.4-41.1,25-49.7c4.1-4.1,4.7-9.1,4.8-12c0.3-14.2-1.4-45.1-2.5-46.9c0.1-1.6-0.4-3.3-1.5-4c-1.4-35.8-7.6-63-8.7-70.2
	c-1.1-7.2-2.9-12.8-2.9-12.8 M205.2,396.1l14.3-1.1 M596.8,394.5h15.5 M802,275.2h-14.6l1.1,7.7h14.2 M803.1,285h-14.3l0.8,7.7h13.9
	 M708.2,163.7c12.4,1,27.8,3.9,39.5,15.1 M590.1,168.3c0,0,8.7,38.9,9.9,75.2 M78.1,289c0,0-0.3-2.2-1.8-2.6
	c-0.8-0.1-8.1-0.1-10.9,0.4c-2.1,0.4-2.3,1.6-3.2,6.2c-0.8,4.1-1.5,11.7-1.7,13.5c-0.1,1.8,0.4,2.6,4.1,2.3 M39.1,314
	c-1.7,1.1-8.9,6.3-10.7,7.7 M428.8,156.8c0,0-0.4,2.9,0.1,5.4 M661.7,156.6c0.6,1.5,0.8,3.7,0.4,5.1 M255.7,230.2c0,0,1,1.1,1.5,3.3
	c0.8,2.9,1.4,7.8,1.6,10c0.7,4.5-0.5,5-3.4,5.4c-6.7,1-8.7,1.2-11.8,1.8c-2.9,0.6-5.6,2.1-7.4,4.4 M667.3,327.6
	c30.8,0,55.7,25,55.7,55.9s-25,55.7-55.9,55.6c-30.8,0-55.7-25-55.7-55.9C611.6,352.5,636.6,327.6,667.3,327.6z M396.3,271.9
	c1.1,0,1.9,1,1.9,2.2c0,1.2-0.8,2.2-1.9,2.2s-1.9-1-1.9-2.2C394.4,272.9,395.2,271.9,396.3,271.9L396.3,271.9z M391.6,270.5
	c0.1,1,0.4,5.8,0.1,7 M430.9,268.8c0,0,0.1,5.4-0.1,7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M383,164.5c0,0,102.6-5.5,206.1-4.3c58.7,0.8,109.6,3.4,109.6,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M788.5,309.9c0,0,0-12.8-2.2-27c-2.2-13.6-6.6-28.6-7.6-31.5
	c-1.7-4.8-7.2-22.3-23-44.7c-12-16.9-21.3-25.4-32.2-30.7c-8.9-4.3-20.6-7.8-41.7-9.6c-27.9-2.3-44.8-2.5-91.6-3.2 M317.2,176.6
	c-5.9,2.5-10.3,4.7-13.3,6.2c-45.3,22.1-88.6,52.8-95.6,57.4c-5.2,3.3-10.6,8.7-19.7,10.7 M49.2,302v-0.4c0-3.2,1-7.7,2.5-8
	c1.2-0.3,1.8,1,1.9,1.4 M54.1,307.8c-0.1,2.2-1.1,3.4-2.2,3.4c-0.5,0-1.4-0.8-1.7-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M49.2,301.9c-0.3-0.3-0.5-0.4-0.8-0.4c-1.1,0-1.9,2.6-1.9,5.5
	s1,5.2,2.1,5.2c0.8,0,1.4-0.7,1.8-2.5 M53.7,294.9c0.4-4,1.4-5.9,2.6-5.9c1.7,0,3,4.8,3,11c0,6.1-1.2,11-2.9,11
	c-1,0-1.9-0.7-2.5-3.4 M795.1,253.5c0,0-7.6-0.4-12.1-0.4c-2.5,0-3.2,0.3-3.2,1.2 M784.1,270.7c0,0,1.1,1.5,3.4,1.5h13.2
	 M787.4,254.6c3-0.3,6.5,3.2,7.7,7.6c1.4,4.4,0,8.1-3,8.4c-3,0.3-6.5-3.2-7.7-7.6C783,258.7,784.4,254.9,787.4,254.6L787.4,254.6z
	 M791.7,251c-6.9-22-19.9-42.8-30.1-56.3c-7.7-10-13.8-16-13.8-16l0.7-2.3 M151.9,327.6c30.8,0,55.7,25,55.7,55.9
	s-25,55.7-55.8,55.6c-30.8,0-55.7-25-55.7-55.9C96,352.5,121.1,327.6,151.9,327.6L151.9,327.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151.2,345c21.2,0,38.4,17.2,38.4,38.5c0,21.2-17.3,38.4-38.5,38.4
	s-38.4-17.2-38.4-38.5C112.7,362.2,130,345,151.2,345L151.2,345z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M142.5,365.6c-1-1-3-3-6.6-7.6c-0.8-1.1-0.8-1.6,1-2.5
	c2.6-1.2,4.7-2.1,7.4-2.6c2.2-0.4,2.1,0.1,2.1,1.2c0,5.8-0.1,8.8-0.3,10.2C145.8,367.8,145,368.1,142.5,365.6L142.5,365.6z
	 M156,364.2c-0.1-1.4-0.4-4.3-0.1-10c0-1.4,0.4-1.8,2.3-1.2c2.8,0.7,5,1.4,7.4,2.8c1.9,1.1,1.5,1.5,0.8,2.3
	c-3.7,4.4-5.8,6.6-6.7,7.6C157.1,368.1,156.3,367.7,156,364.2L156,364.2z M167.3,371.8c0.8-1.1,2.5-3.6,6.3-7.7
	c1-1.1,1.5-1.1,2.6,0.5c1.6,2.3,2.9,4.3,3.9,6.9c0.8,2.1,0.3,2.1-0.8,2.3c-5.6,1-8.7,1.4-10,1.5
	C165.5,375.5,165.2,374.7,167.3,371.8L167.3,371.8z M170.9,384.9c1.4-0.3,4.1-1.1,9.8-1.9c1.4-0.1,1.8,0.1,1.7,2.1
	c-0.1,2.9-0.6,5.1-1.4,7.8c-0.7,2.1-1.2,1.8-2.2,1.2c-5-2.9-7.6-4.5-8.7-5.4C167.3,386.6,167.4,385.8,170.9,384.9L170.9,384.9z
	 M165.4,397.2c1.2,0.5,4,1.8,8.8,4.8c1.2,0.7,1.4,1.2-0.1,2.8c-2.1,2.1-3.7,3.6-6.1,5.1c-1.9,1.2-2.1,0.7-2.5-0.4
	c-1.9-5.4-2.9-8.3-3.2-9.6C161.4,396.1,162.2,395.7,165.4,397.2L165.4,397.2z M153.1,403.2c0.6,1.2,1.8,4,3.6,9.4
	c0.4,1.4,0.3,1.8-1.8,2.1c-2.9,0.3-5.1,0.3-7.8-0.1c-2.2-0.3-1.9-0.8-1.5-1.9c1.9-5.4,3.2-8.3,3.7-9.5
	C150.8,399.8,151.6,399.8,153.1,403.2L153.1,403.2z M140,399.7c-0.4,1.4-1.1,4.1-3.3,9.5c-0.6,1.4-1,1.5-2.8,0.4
	c-2.3-1.6-4.1-3-6.1-5.1c-1.5-1.6-1-1.9,0-2.5c5-2.9,7.7-4.3,8.9-4.8C140.3,395.7,140.9,396.4,140,399.7L140,399.7z M132.1,388.7
	c-1.1,0.8-3.6,2.5-8.5,5.1c-1.2,0.7-1.8,0.5-2.3-1.4c-0.8-2.8-1.2-5-1.4-7.8c0-2.2,0.6-2.1,1.7-1.9c5.6,1,8.7,1.7,9.9,2.1
	C135,386,135,386.8,132.1,388.7L132.1,388.7z M133,375.2c-1.4-0.1-4.3-0.4-9.9-1.5c-1.4-0.3-1.7-0.7-0.8-2.6c1.1-2.6,2.2-4.7,4-6.9
	c1.4-1.8,1.7-1.2,2.5-0.4c3.7,4.4,5.5,6.9,6.3,8C137.2,375,136.6,375.6,133,375.2L133,375.2z M152.6,371.2c1.4,0.8,1.8,2.5,1,3.9
	c-0.8,1.4-2.5,1.8-3.9,1c-1.2-0.8-1.7-2.5-1-3.9C149.5,370.8,151.2,370.4,152.6,371.2L152.6,371.2z M163.1,380.9
	c-0.4,1.5-1.8,2.5-3.3,2.1s-2.5-1.9-2.1-3.3c0.4-1.5,1.8-2.5,3.3-2.1C162.6,378,163.4,379.4,163.1,380.9z M157.1,393.8
	c-1.5,0.1-2.9-1-3-2.6c-0.1-1.5,1-2.9,2.6-3c1.5-0.1,2.9,1,3,2.6C159.7,392.4,158.6,393.8,157.1,393.8L157.1,393.8z M142.8,392.1
	c-0.6-1.4,0.1-3,1.5-3.6s3,0.1,3.6,1.5c0.6,1.4-0.1,3-1.5,3.6S143.3,393.5,142.8,392.1L142.8,392.1z M140,378.1
	c1.1-1,2.9-0.8,3.9,0.3c1,1.1,0.8,2.9-0.3,3.9c-1.2,1-2.9,0.8-4-0.3C138.8,380.9,138.9,379.2,140,378.1z M156.1,384.4
	c-0.7,2.8-3.4,4.4-6.1,3.7c-2.8-0.7-4.4-3.4-3.7-6.1c0.7-2.8,3.4-4.4,6.1-3.7S156.7,381.8,156.1,384.4L156.1,384.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M154.8,348.1c0-1.4,0.1-1.9,1.9-1.6c4.3,0.7,9.9,2.3,14.2,5.2
	c1,0.7,1,1.1,0.3,2.1c-1,1.2-11.8,14-12.5,14.9c-1.9,2.1-3.4,1.5-3.6-1.2C154.8,363.4,154.9,348.8,154.8,348.1L154.8,348.1z
	 M176.6,358.7c0.8-1.1,1.4-1.4,2.5,0c2.8,3.3,6.1,8.1,7.6,13.1c0.3,1.1,0,1.4-1.1,1.6c-1.5,0.4-18,3.2-19.1,3.3
	c-2.9,0.4-3.6-1-1.9-3.3C166.7,370.4,176.2,359.3,176.6,358.7L176.6,358.7z M186.5,380.9c1.4-0.3,1.9-0.1,1.9,1.6
	c0,4.3-0.6,10.2-2.6,14.9c-0.4,1.1-0.8,1.1-1.9,0.5c-1.4-0.7-15.8-9.2-16.8-9.8c-2.3-1.5-2.2-3.2,0.6-3.7
	C171.4,383.5,185.8,381,186.5,380.9L186.5,380.9z M179.8,404.2c1.2,0.6,1.5,1.1,0.4,2.5c-2.8,3.3-7,7.4-11.6,9.6
	c-1.1,0.5-1.4,0.3-1.9-0.8c-0.6-1.4-6.2-17.2-6.6-18.3c-0.8-2.8,0.4-3.7,2.9-2.5C166.6,396.5,179.2,404,179.8,404.2L179.8,404.2z
	 M159.7,417.9c0.6,1.2,0.4,1.8-1.2,2.2c-4.3,0.7-10,1.2-15,0c-1.1-0.3-1.2-0.7-0.8-1.8c0.4-1.5,6.3-17.2,6.7-18.3
	c1.1-2.6,2.8-2.6,3.9-0.1C154.5,403.4,159.4,417.2,159.7,417.9L159.7,417.9z M135.5,415.3c-0.4,1.2-0.8,1.6-2.3,0.8
	c-3.7-2.1-8.5-5.6-11.6-9.6c-0.7-1-0.6-1.4,0.6-1.9c1.2-0.8,15.8-9.1,16.9-9.6c2.6-1.2,3.7-0.3,2.9,2.3
	C140.9,401,135.8,414.7,135.5,415.3L135.5,415.3z M118.7,397.8c-1.1,0.7-1.8,0.8-2.3-0.8c-1.5-4-2.9-9.8-2.6-14.9
	c0.1-1.1,0.4-1.4,1.7-1.2c1.5,0.1,18,3.2,19.1,3.4c2.8,0.7,3,2.2,0.7,3.7C131.9,390.2,119.3,397.5,118.7,397.8L118.7,397.8z
	 M116.9,373.6c-1.4-0.1-1.8-0.5-1.2-2.2c1.5-4,4-9.4,7.6-13.1c0.8-0.8,1.2-0.7,2.1,0.1c1.1,1.1,11.7,14,12.4,15
	c1.7,2.3,1,3.7-1.8,3.3C132.1,376.3,117.6,373.7,116.9,373.6L116.9,373.6z M131.2,354c-1-1-1.1-1.5,0.4-2.5
	c3.7-2.1,9.1-4.5,14.2-5.1c1.1-0.1,1.4,0.3,1.5,1.4c0.1,1.5,0,18.3-0.1,19.4c-0.1,2.9-1.6,3.4-3.6,1.4
	C141,365.7,131.6,354.5,131.2,354L131.2,354z M666.8,345c21.2,0,38.4,17.2,38.4,38.5c0,21.2-17.3,38.4-38.5,38.4
	c-21.2,0-38.4-17.2-38.4-38.5C628.3,362.2,645.6,345,666.8,345L666.8,345z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M658.1,365.6c-1-1-3-3-6.6-7.6c-0.8-1.1-0.8-1.6,1-2.5
	c2.6-1.2,4.7-2.1,7.4-2.6c2.2-0.4,2.2,0.1,2.1,1.2c0,5.8-0.1,8.8-0.3,10.2C661.4,367.8,660.6,368.1,658.1,365.6L658.1,365.6z
	 M671.6,364.2c-0.1-1.4-0.4-4.3-0.1-10c0-1.4,0.4-1.8,2.3-1.2c2.8,0.7,5,1.4,7.4,2.8c1.9,1.1,1.5,1.5,0.8,2.3
	c-3.7,4.4-5.8,6.6-6.7,7.6C672.7,368.1,671.9,367.7,671.6,364.2L671.6,364.2z M682.9,371.8c0.8-1.1,2.5-3.6,6.3-7.7
	c1-1.1,1.5-1.1,2.6,0.5c1.7,2.3,2.9,4.3,3.9,6.9c0.8,2.1,0.3,2.1-0.8,2.3c-5.6,1-8.7,1.4-10,1.5
	C681.1,375.5,680.8,374.7,682.9,371.8L682.9,371.8z M686.4,384.9c1.4-0.3,4.1-1.1,9.8-1.9c1.4-0.1,1.8,0.1,1.7,2.1
	c-0.1,2.9-0.5,5.1-1.4,7.8c-0.7,2.1-1.2,1.8-2.2,1.2c-5-2.9-7.6-4.5-8.7-5.4C682.9,386.6,683,385.8,686.4,384.9L686.4,384.9z
	 M680.9,397.2c1.2,0.5,4,1.8,8.8,4.8c1.2,0.7,1.4,1.2-0.1,2.8c-2.1,2.1-3.7,3.6-6.1,5.1c-1.9,1.2-2.1,0.7-2.5-0.4
	c-1.9-5.4-2.9-8.3-3.2-9.6C677.1,396.1,677.8,395.7,680.9,397.2L680.9,397.2z M668.7,403.2c0.5,1.2,1.8,4,3.6,9.4
	c0.4,1.4,0.3,1.8-1.8,2.1c-2.9,0.3-5.1,0.3-8-0.1c-2.2-0.3-1.9-0.8-1.5-1.9c1.9-5.4,3.2-8.3,3.7-9.5
	C666.4,399.8,667.2,399.8,668.7,403.2L668.7,403.2z M655.6,399.7c-0.4,1.4-1.1,4.1-3.3,9.5c-0.6,1.4-1,1.5-2.8,0.4
	c-2.3-1.6-4.1-3-6.1-5.1c-1.5-1.6-1-1.9,0-2.5c5-2.9,7.7-4.3,8.9-4.8C655.9,395.7,656.6,396.4,655.6,399.7L655.6,399.7z
	 M647.7,388.7c-1.1,0.8-3.6,2.5-8.5,5.1c-1.2,0.7-1.8,0.5-2.3-1.4c-0.8-2.8-1.2-5-1.4-7.8c0-2.2,0.5-2.1,1.7-1.9
	c5.6,1,8.7,1.7,9.9,2.1C650.5,386,650.5,386.8,647.7,388.7L647.7,388.7z M648.6,375.2c-1.4-0.1-4.3-0.4-9.9-1.5
	c-1.4-0.3-1.7-0.7-0.8-2.6c1.1-2.6,2.2-4.7,4-6.9c1.4-1.8,1.7-1.2,2.5-0.4c3.7,4.4,5.5,6.9,6.3,8C652.7,375,652.2,375.6,648.6,375.2
	L648.6,375.2z M668.1,371.2c1.4,0.8,1.8,2.5,1,3.9c-0.8,1.4-2.5,1.8-3.9,1c-1.2-0.8-1.8-2.5-1-3.9
	C665.1,370.8,666.8,370.4,668.1,371.2L668.1,371.2z M678.7,380.9c-0.4,1.5-1.8,2.5-3.3,2.1c-1.5-0.4-2.5-1.9-2.1-3.3
	c0.4-1.5,1.8-2.5,3.3-2.1C678.2,378,679,379.4,678.7,380.9z M672.7,393.8c-1.5,0.1-2.9-1-3-2.6c-0.1-1.5,1-2.9,2.6-3
	c1.5-0.1,2.9,1,3,2.6C675.3,392.4,674.2,393.8,672.7,393.8L672.7,393.8z M658.4,392.1c-0.5-1.4,0.1-3,1.5-3.6c1.4-0.5,3,0.1,3.7,1.5
	c0.5,1.4-0.1,3-1.5,3.6C660.6,394.2,658.9,393.5,658.4,392.1L658.4,392.1z M655.6,378.1c1.1-1,2.9-0.8,3.9,0.3
	c1,1.1,0.8,2.9-0.3,3.9c-1.2,1-2.9,0.8-4-0.3C654.4,380.9,654.5,379.2,655.6,378.1L655.6,378.1z M671.7,384.4
	c-0.7,2.8-3.4,4.4-6.1,3.7c-2.8-0.7-4.4-3.4-3.7-6.1c0.7-2.8,3.4-4.4,6.1-3.7S672.3,381.8,671.7,384.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M670.3,348.1c0-1.4,0.1-1.9,1.9-1.6c4.1,0.7,9.9,2.3,14.2,5.2
	c1,0.7,1,1.1,0.3,2.1c-1,1.2-11.8,14-12.5,14.9c-1.9,2.1-3.4,1.5-3.6-1.2C670.3,363.4,670.5,348.8,670.3,348.1L670.3,348.1z
	 M692.2,358.7c0.8-1.1,1.4-1.4,2.5,0c2.8,3.3,6.1,8.1,7.6,13.1c0.3,1.1,0,1.4-1.1,1.6c-1.5,0.4-18,3.2-19.1,3.3
	c-2.9,0.4-3.6-1-1.9-3.3C682.3,370.4,691.8,359.3,692.2,358.7L692.2,358.7z M702.1,380.9c1.2-0.3,1.9-0.1,1.9,1.6
	c0,4.3-0.5,10.2-2.6,14.9c-0.4,1.1-0.8,1.1-1.9,0.5c-1.4-0.7-15.8-9.2-16.8-9.8c-2.3-1.5-2.2-3.2,0.5-3.7
	C687,383.5,701.4,381,702.1,380.9L702.1,380.9z M695.4,404.2c1.2,0.6,1.5,1.1,0.4,2.5c-2.8,3.3-7,7.4-11.6,9.6
	c-1.1,0.5-1.4,0.3-1.9-0.8c-0.5-1.4-6.2-17.2-6.6-18.3c-0.8-2.8,0.4-3.7,2.9-2.5C682.2,396.5,694.8,404,695.4,404.2L695.4,404.2z
	 M675.3,417.9c0.5,1.2,0.4,1.8-1.2,2.2c-4.3,0.7-10,1.2-15,0c-1.1-0.3-1.2-0.7-0.8-1.8c0.4-1.5,6.3-17.2,6.7-18.3
	c1.1-2.6,2.8-2.6,3.7-0.1C670.1,403.4,675,417.2,675.3,417.9L675.3,417.9z M651.1,415.3c-0.4,1.2-0.8,1.6-2.3,0.8
	c-3.7-2.1-8.5-5.5-11.6-9.6c-0.7-1-0.5-1.4,0.5-1.9c1.2-0.8,15.8-9.1,16.9-9.6c2.5-1.2,3.7-0.3,2.9,2.3
	C656.5,401,651.4,414.7,651.1,415.3L651.1,415.3z M634.3,397.8c-1.1,0.7-1.8,0.8-2.3-0.8c-1.5-4-2.9-9.8-2.6-14.9
	c0.1-1.1,0.4-1.4,1.7-1.2c1.5,0.1,18,3.2,19.1,3.4c2.8,0.7,3,2.2,0.7,3.7C647.5,390.2,634.9,397.5,634.3,397.8L634.3,397.8z
	 M632.5,373.6c-1.4-0.1-1.8-0.5-1.2-2.2c1.5-4,4-9.4,7.6-13.1c0.8-0.8,1.2-0.7,2.1,0.1c1.1,1.1,11.7,14,12.4,15
	c1.7,2.3,1,3.7-1.8,3.3C647.6,376.3,633.2,373.7,632.5,373.6L632.5,373.6z M646.8,354c-1-1-1.1-1.5,0.4-2.5
	c3.7-2.1,9.1-4.5,14.2-5.1c1.1-0.1,1.4,0.3,1.5,1.4c0.1,1.5,0,18.3-0.1,19.4c-0.1,2.9-1.7,3.4-3.6,1.4
	C656.6,365.7,647.2,354.5,646.8,354L646.8,354z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M67,330.9l32.7-1.5
		c0,0,10.9-14,31.6-17.9c0,0,27.2-6.3,51.4,5.1c0,0,15.5,6.3,25,22.7c0,0,11.7,16,12.1,49c0,0,3.3-1,15.7-1h6.9
		c0,0-3.7-11.3-5.4-21.3c0,0,0.7-3.4-0.8-5.4c0,0-6.5-37.1-6.6-62.3c0,0-0.4-19.3,2.9-33.2l-32.7,2.5c0,0-4,0.3-4.4-2.2
		c0,0-0.3-1.1,1.1-2.3l12.8-10.5l-7.4-7.8c0,0-9.8,6.9-16,6.2c0,0-3.7,1-8.5,0.7c0,0-34.3,6.1-64,13.9c0,0-37.6,8.9-56.5,21
		c0,0,9.4-1.9,20.6-1.7c0,0,1.4,0,1.7,1.5c0,0-4.4,10.3-9.6,19.7l-0.6,0.8c0,0-6.1,12.7-9.5,21.5c0,0-1,1.8,1.4,2.3
		C60.9,330.5,62,330.9,67,330.9z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M242.7,387.2
		c0,0-3.9-11.6-5.4-21.3c0,0,1-3.6-0.8-5.4c0,0-6.1-35.1-6.5-59.3c0,0-1.1-17.2,2.8-36.2c0,0,119.4-8.9,177.7-10.3l0.5,74l0.3,57.9
		L242.7,387.2z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M411.2,386.6l-0.3-57.9
		l-0.5-74c0,0,179.7-7.8,192.5-7.3c0,0,1,0.7,1.9,23.2c0,0,0.8,20.1,0.6,24.6c0,0,0,5-3.6,9.6c0,0-1.4,1.5-2.3,2.5
		c0,0-5.6,5.9-8.4,10c0,0-5.4,7-10,19.5c0,0-2.8,6.7-5.4,19c0,0,0.4,1.5-1,5.1c0,0-2.1,12.2-3,25.6L411.2,386.6L411.2,386.6z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M250.8,257.5
		c0,0,0.6-2.2,3.7-2.6c0,0,1.8-0.3,2.6-0.4c0,0,2.6-0.3,3.6-1.8c0,0,1.1-1.2,0.7-4.3c0,0-0.8-6.6-1.7-13.3c0,0-0.3-4.1-3.2-4.8
		c0,0-2.6-0.7-5.8,0.6c0,0-1.4,0.4-3.7,1.9l-6.3,4.1c0,0-1.4,0.7-2.1,2.3c0,0-2.5,5.2-2.9,13.1c0,0-0.1,2.6,0.8,3.7
		c0,0,2.1,2.1,7.2,2.6C243.8,258.6,250,259,250.8,257.5z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M257.7,259
		l-0.1-4.7c0,0,4-0.3,4-5c0,0-0.7-7.2-1.9-15.4c0,0-0.5-3.2-3-3.7v-5.5c0,0,36-23.5,55.7-31.8c0,0,28.5-12.8,65.2-16.1
		c0,0,16.2-1.4,22-1.5c0,0-1.4,49-3,76.1l-82.7,4.1L257.7,259z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M424.5,250.3v-42.9
		l0.1-33.2c0,0,40.6-1.4,75.5-1.5l69.3-0.4l1.7,38.4l1,33.7l-31.1,1.1l-64.1,2.6L424.5,250.3z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M596.3,386.8
		l-24.8-0.1c0,0,1.4-16.5,3-25.6c0,0,1.2-3,1-5.1c0,0,3.2-16.9,10.6-30.4c0,0,3.4-7.8,14.7-19.7l1-1.1c0,0,3.3-3.4,3.6-10.6
		l-0.1-9.5c0,0-0.7-22.1-1.2-28.5c0,0-0.4-6.9-1.1-8.9c0,0,88.6-2.9,143.6-3c0,0,11.3,0.3,12.2-1.4l6.9,8.8l5.4,0.6c0,0-0.6,1.2,0,3
		c0,0,0,0.8,1.7,5.1c0,0,3.2,8,5.4,21.9c0,0,2.2,12.8,2.6,28.3c0,0,0.4,2.8-0.7,4.8c0,0-1.4,3-6.1,3.2h-1.5l-62.6,1.4
		c0,0-14.4-11.3-42.8-11.3c0,0-22.6-1.2-40,9.8c0,0-17.9,9.2-25.6,35.6C601.2,354,596.6,367.7,596.3,386.8z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M601.3,243.7
		c0,0-0.4-15-4-42.9c0,0-1.5-10.5-4.5-26.1c0,0-0.5-3.9-1.1-6.5c0,0,30.8-0.1,60.7,1.2c0,0,38.2,1.4,54.2,8.3c0,0,8.3,3,15.7,8.5
		c0,0,8.9,5.9,19.5,20.4c0,0,5.6,7.7,10.6,17.5c0,0,4.1,7.8,7.2,14.4c0,0,0.4,0.8,0.1,1.8l-1.9,0.1c0,0-32.7,1-48.4,1.2l-35.9,0.6
		C673.4,242.2,628.7,242.8,601.3,243.7z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="151.3"
              cy="382.7"
              r="56.3"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="668.1"
              cy="383.5"
              r="56.3"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(SportsVanLeft);
