import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M97.8,169.4l-8.8,2.8c-1.4,2.2-0.6,4.4,2.2,6.6l8-9.5L97.8,169.4L97.8,169.4z"
          />
          <path
            fill="#FFFFFF"
            d="M99.2,169.4l16.5-1.2l11.7-0.8c22.4-1.4,46.1-2.2,71-2.5c29.2-0.3,56.1-0.6,80.9-0.7h8.5
	c45.7-0.3,83.2-0.3,112.9,0l16.5,0.1c9.8,0.1,18.4,0.3,26,0.6l13.2,1.4h0.1c19.5,2.6,35.6,7.3,48.1,14.3l3.2,1.9l12.5-4.4
	c-6.3-2.9-13.2-5.4-20.6-7.6l-0.1,1c-3.7-1.1-7.7-2.1-11.7-3.2l0.4-0.8c-4.5-1-8.5-1.9-12-2.5c-2.2,0.6-5.9,0.6-10.9-0.1l-9.2-1.1
	l-14-1.2l-15.7-0.6l-11.6-0.1v0.8l-5.8-0.1c-30.8-0.4-71.3-0.4-121.5-0.1h-8.5c-13.2,0.1-26.7,0.1-40.6,0.1h-6.7
	c-33.3,0.4-67,1.4-101.1,2.9l-3.3,0.3c-13.8,0.1-28.3,1.9-43.7,5.4l-2.2,0.3l7.6,1l8.8-2.8L99.2,169.4L99.2,169.4z M81.4,171.4
	l-1.9,2.5l9.1,5.6l2.6-0.6c-2.8-2.2-3.6-4.4-2.2-6.6L81.4,171.4z M79.5,173.8l-1,4.7l7.4,4.1l2.6-3.2"
          />
          <path
            fill="#EBEBEB"
            d="M86,182.6c-17.7,19.3-31.4,41.1-41.1,65.6l-0.1,0.3H55c5.5-22.3,17.5-45.5,36.2-69.6l-2.6,0.6L86,182.6
	L86,182.6z"
          />
          <path
            fill="#FFFFFF"
            d="M91.2,178.9C72.6,203,60.5,226.1,55,248.4h26.3v-0.6c5.8-23.8,15.1-46.8,28.2-69.1l6.3-10.7l-16.5,1.2
	L91.2,178.9L91.2,178.9z"
          />
          <path
            fill="#FFFFFF"
            d="M109.5,178.9l131.4-3c11.1-0.4,14.6,2.3,10.5,8l-31.5,57.6c-2.9,4.5-9.4,6.9-19.7,6.9H81.3v69.9h57.1
	c3.7-7.7,8.7-13.2,15-16.8c8.1-6.1,25-8.7,50.8-7.6h0.3c17.1-0.5,31.4,0.1,42.8,2.3c-0.3-18.3,0.3-33.7,1.5-46.2
	c5.2-28.1,15.5-56.8,31-86h-0.4c-24.8,0.1-51.7,0.4-80.9,0.7c-24.9,0.3-48.4,1.1-71,2.5l-11.7,0.8L109.5,178.9L109.5,178.9z
	 M115,285.9c3.9,0,7.3,1.4,10,4.1c2.8,2.8,4.1,6.1,4.1,10c0,3.9-1.4,7.2-4.1,9.9s-6.1,4.1-10,4.1c-3.9,0-7.2-1.4-9.9-4.1
	s-4.1-6.1-4.1-9.9c0-3.9,1.4-7.2,4.1-10C107.7,287.3,111,285.9,115,285.9L115,285.9z"
          />
          <path
            fill="#FFFFFF"
            d="M105,290.1c-2.8,2.8-4.1,6.1-4.1,10c0,3.9,1.4,7.2,4.1,9.9c2.8,2.8,6.1,4.1,9.9,4.1s7.3-1.4,10-4.1
	c2.8-2.8,4.1-6.1,4.1-9.9c0-3.9-1.4-7.2-4.1-10c-2.8-2.8-6.1-4.1-10-4.1C111,285.9,107.7,287.3,105,290.1L105,290.1z M130.7,165.4
	c34.1-1.5,67.8-2.5,101.1-2.9h6.7c13.9,0,27.4,0,40.6-0.1h8.5c50.2-0.3,90.7-0.3,121.5,0.1l5.8,0.1v-2.5c-2.9-1.9-6.5-2.9-11-3.2
	h-3.7c-13.1-0.1-27.6-0.1-43.9-0.1h-7.2c-18.3,0-38.4,0-60.4,0.1h-8.5c-24.8,0.1-51.7,0.4-80.9,0.8l-5,0.1l-8,0.1l-5.2,0.1
	c-2.5,0.7-5.1,2.5-7.8,5.5c2.8-3,5.4-4.8,7.8-5.5l-41.4,1.5C134.3,160.9,131.2,162.8,130.7,165.4L130.7,165.4z"
          />
          <path
            fill="#EBEBEB"
            d="M81.3,248.4h119c10.2,0,16.8-2.3,19.5-6.9l31.5-57.6c4.1-5.8,0.6-8.4-10.5-8l-131.4,3
	c-13.1,22.1-22.6,45.1-28.2,68.9V248.4z"
          />
          <path
            fill="#FFFFFF"
            d="M44.8,248.4l-0.4,1c-1.1,2.8-1.9,5.5-2.6,8.1c-0.4,1.8-0.8,3.4-1,5.2c1.2,0.5,1.8,2.3,1.7,5.6
	c-0.5,3.6-1,7.3-1.4,11c-1,9.2-1.4,19.1-1.5,29.7v8.5h-2.5l2.5,16.4v-7.3l12.9-8.5c-2.1-26.4-1.2-49.8,2.5-69.9H44.8L44.8,248.4
	L44.8,248.4z"
          />
          <path
            fill="#FFFFFF"
            d="M38.7,249.5l-2.3,4.8l3.4,1.4v1c0.7,0.3,1.4,0.7,2.1,1c0.7-2.8,1.5-5.4,2.6-8.1L38.7,249.5L38.7,249.5z"
          />
          <path
            fill="#D2D3D4"
            d="M56.1,251.4c-1.1,6.5-1.8,13.6-2.3,21.3l4-0.3c1.2-0.3,2.3-1,3.3-1.8c2.3-2.2,3.3-6.3,2.5-12.1
	c0.1-4.5-2.3-7-7.2-7.2H56.1L56.1,251.4z"
          />
          <path
            fill="#E6E7E7"
            d="M56.4,251.4h18.8c1.9,0.1,2.9,1.8,2.8,4.7l-1.1,47.7c0.3,1.1-1,1.7-3.6,1.7l-13.6,1.1v11.6h21.5v-69.9H55
	c-3.7,20.1-4.5,43.3-2.5,69.9h7.2v-11.6l-6.1,0.5c-0.3-7.7-0.4-15-0.3-21.9c0.1-4.4,0.3-8.7,0.4-12.7c0.4-7.7,1.2-14.9,2.3-21.3
	L56.4,251.4L56.4,251.4z"
          />
          <path
            fill="#E6E7E7"
            d="M56.4,251.4c5,0.1,7.3,2.6,7.2,7.2c0.7,5.8-0.1,9.9-2.5,12.1l12.2,1.8H57.8c2.9,0.8,4.3,2.2,3.9,4.4
	c0.5,3.7,0.3,6.2-1.1,7.6l13.2,1.2H57.1c3.4,1.2,5.1,4.4,4.7,9.5c0,4.1-0.5,7.2-1.8,9.1l13.3,1.4c2.6,0,3.9-0.5,3.6-1.6l1.1-47.7
	c0.1-2.9-0.8-4.5-2.8-4.7H56.4V251.4L56.4,251.4z"
          />
          <path fill="#FFFFFF" d="M35.6,279.3l1.2-9.4l-2.9-1.4l-2.2,10.6" />
          <path
            fill="#FFFFFF"
            d="M36.9,270l-1.2,9.4l3.7,0.1l1.5-10.3c0.5-3.3,0-5-1.7-5l-5.2,4.4L36.9,270L36.9,270z"
          />
          <path
            fill="#FFFFFF"
            d="M40.7,262.8l-1.7,1.4c1.7,0.1,2.2,1.8,1.7,5l-1.5,10.3h1.7c0.4-3.9,0.8-7.6,1.4-11
	C42.6,265.2,42.1,263.4,40.7,262.8L40.7,262.8z"
          />
          <path
            fill="#EBEBEB"
            d="M53.9,272.7c-0.3,4.1-0.4,8.3-0.4,12.7l3.6,0.1h1c1.1-0.1,1.9-0.5,2.6-1.2c1.2-1.4,1.7-3.9,1.1-7.6
	c0.4-2.1-1-3.6-3.8-4.4L53.9,272.7L53.9,272.7z"
          />
          <path
            fill="#BDBEC0"
            d="M61.1,270.7c-1,0.8-1.9,1.4-3.3,1.8h15.4L61.1,270.7z M59.8,304.2c-0.8,1.2-1.9,2.1-3.3,2.3l-3,0.7l6.1-0.5
	v-0.1v0.1l13.6-1.1L59.8,304.2L59.8,304.2z"
          />
          <path
            fill="#D2D3D4"
            d="M61.6,295.2c0.3-5.1-1.2-8.3-4.7-9.5l-3.6-0.1c-0.1,6.9,0,14.2,0.3,21.9l3-0.7c1.4-0.3,2.5-1.1,3.3-2.3
	C61.1,302.3,61.8,299.3,61.6,295.2z"
          />
          <path
            fill="#BDBEC0"
            d="M60.7,284.4c-0.7,0.7-1.5,1.1-2.6,1.2h15.8L60.7,284.4z"
          />
          <path
            fill="#FFFFFF"
            d="M39.6,326.8v17.3H22.3h17.3v-4.4H21.9v9.9l1.4,1.4l-0.1,1.6l-0.3,7.2l2.5,0.3c12.9-1.4,24.1-1.2,33.4,0.3
	l68.2,4c2.2-16,5.2-29.7,8.9-41.1v-0.1l2.1-5H52.5L39.6,326.8L39.6,326.8z M39.5,309.2h-8.9v4.4c3.2-0.3,5.2,0.4,6.3,2.2l2.6,0.6
	L39.5,309.2L39.5,309.2z"
          />
          <path
            fill="#FFFFFF"
            d="M21.9,366.6h4.5L23,360l0.3-7.2h-1.4 M136.2,323.2c-3.9,11.6-6.9,25.3-9.1,41.3l9.9,0.5l0.3-1.9
	c0.8-7.3,1.9-14.2,3.2-20.5c1.2-5.9,2.6-11.4,4.1-16.5c1-2.9,2.2-5.5,3.7-7.8h-9.9C137.6,319.8,136.9,321.4,136.2,323.2L136.2,323.2
	z"
          />
          <path
            fill="#656565"
            d="M144.6,326.1c-1.5,5.1-2.9,10.6-4.1,16.5h22.8l3.3-3.4c2.6-2.6,5.4-5,8.3-7c10.2-7.3,21.9-11,35.1-11
	c13.2,0,24.9,3.7,35.2,11c2.8,1.9,5.2,4.1,7.8,6.6l25.2,8.4c-0.5-2.9-1.2-5.8-1.9-8.5v-0.1c-3.3-17.5-13.1-28.6-29.3-33.6l-0.7-0.1
	c-9.4-1.8-20.5-2.8-33.7-2.6h-21.6c-16.2,0.1-27.2,2.3-33,6.5l-0.4,0.3c-3.7,2.2-6.9,5.4-9.5,9.6
	C146.8,320.6,145.5,323.2,144.6,326.1L144.6,326.1z"
          />
          <path
            fill="#FFFFFF"
            d="M158.1,308.5c5.8-4.1,16.8-6.3,33-6.5h21.6c13.2-0.1,24.5,0.7,33.7,2.6l0.7,0.1v-8.5
	c-11.4-2.2-25.7-2.9-42.8-2.3H204c-25.6-1.1-42.5,1.5-50.8,7.6c-6.3,3.4-11.3,9.1-15,16.8h9.9c2.5-4.1,5.6-7.4,9.5-9.6L158.1,308.5
	L158.1,308.5z"
          />
          <path
            fill="#656565"
            d="M140.5,342.6c-1.2,6.3-2.3,13.2-3.2,20.5h14.3c2.5-7.4,6.3-14.3,11.8-20.5L140.5,342.6L140.5,342.6z"
          />
          <path
            fill="#656565"
            d="M137,365c-0.8,7-1.5,14.6-1.9,22.6l-10.3-1.4L56.4,377v12.1c-0.1,6.5,4,9.6,12.2,9.4h82
	c-1.4-5.1-1.9-10.5-1.9-16c0-6.9,1-13.3,3-19.5h-14.4L137,365L137,365z"
          />
          <path
            fill="#FFFFFF"
            d="M127.1,364.5c-1,6.9-1.8,14-2.5,21.7l10.3,1.4c0.4-8,1.1-15.5,1.9-22.6L127.1,364.5L127.1,364.5z"
          />
          <path
            fill="#656565"
            d="M148.6,382.5c0,5.6,0.7,11,1.9,16c0.7,2.5,1.4,4.8,2.3,7.2l1.1,2.5c2.9,6.3,7.2,12.4,12.5,17.7
	c12,12,26.4,18,43.5,18c16.9,0,31.4-6.1,43.5-18c10-10,16-21.9,17.5-35.4c0.3-2.3,0.4-4.8,0.5-7.3v-0.8c0-4.5-0.4-8.9-1.2-13.1
	c-2.3-11.4-8-21.6-16.6-30.4l-0.6-0.4c-2.5-2.5-5.1-4.7-7.8-6.6c-10.2-7.3-22-11-35.2-11c-13.2,0-24.9,3.7-35.1,11
	c-2.9,2.1-5.6,4.4-8.3,7l-3.3,3.4c-5.4,6.2-9.4,13.1-11.8,20.5C149.5,369.2,148.6,375.6,148.6,382.5L148.6,382.5z M241.3,351.3
	c8.7,8.7,12.9,19.1,13.1,31.4c0,12.2-4.4,22.7-12.9,31.4c-8.7,8.7-19.1,13.1-31.4,13.1s-22.7-4.4-31.4-13.1
	c-1.8-1.8-3.4-3.7-4.8-5.6l-1.6-2.5c-1.5-2.3-2.6-4.7-3.6-7.1l-0.1-0.1c-1.8-5-2.8-10.2-2.8-15.8c0-12.2,4.3-22.7,12.9-31.4
	s19.1-12.9,31.4-12.9C222.3,338.4,232.6,342.6,241.3,351.3L241.3,351.3z"
          />
          <path
            fill="#EBEBEB"
            d="M210.1,338.4c-12.2,0-22.7,4.3-31.4,12.9c-8.7,8.7-12.9,19.1-12.9,31.4c0,5.6,1,11,2.8,15.8l0.1,0.1
	c1,2.5,2.2,5,3.6,7.1l1.6,2.5c1.5,1.9,3,3.9,4.8,5.6c8.7,8.7,19.1,13.1,31.4,13.1s22.7-4.4,31.4-13.1s12.9-19.1,12.9-31.4
	c0-12.2-4.4-22.7-12.9-31.4C232.6,342.6,222.3,338.4,210.1,338.4L210.1,338.4z M214.5,340.7h0.7c9.4,1.1,17.6,5.1,24.6,12.1
	c3.9,3.9,6.7,8,8.8,12.5c1.4,2.9,2.2,5.9,2.9,9.1c0.4,2.6,0.7,5.2,0.7,8.1c0,11.7-4.1,21.6-12.4,29.9l-1.5,1.4
	c-2.5,2.3-5.2,4.3-8.1,5.8c-6.1,3.3-12.8,5.1-20.2,5.1c-7.6,0-14.4-1.8-20.6-5.2c-2.9-1.6-5.5-3.7-8.1-6.1l-1.1-1
	c-4.1-4.1-7.3-8.8-9.4-13.8c-2.1-5-3-10.3-3-16c0-3,0.3-5.9,0.8-8.8c1.6-8,5.5-14.9,11.6-21c7.2-7.1,15.5-11.1,25.3-12.1h0.4l4-0.1
	L214.5,340.7L214.5,340.7z"
          />
          <path
            fill="#EBEBEB"
            d="M237.2,357.9c1,1,1.8,2.1,2.6,3l1.6,2.3c0.4,1.5,0,2.2-1.4,2.5l-10.6,2.9l-5.2,1.4l-1.6,4.8l24.1-7.2
	c1.5-0.3,2.1-1,1.9-2.1v-0.3c-2.1-4.5-5-8.7-8.8-12.5c-7-7-15.1-11-24.6-12.1h-0.7c-1,0.3-1.2,1-1.1,2.3l0.7,25.2l4.1-3.2l-0.3-5.2
	l-0.3-5l-0.3-6.2c-0.3-1.2,0.3-1.9,1.5-2.1l0.4,0.1c5.6,1.4,10.7,4.1,15.1,8.1L237.2,357.9L237.2,357.9z"
          />
          <path
            fill="#EBEBEB"
            d="M234.4,355.1h-16.8l0.3,5c1.2,0.4,2.5,1,3.7,1.7l15.5-3.7L234.4,355.1L234.4,355.1z"
          />
          <path
            fill="#656565"
            d="M218.9,346.9c-1.2,0.1-1.6,0.8-1.5,2.1l0.3,6.2h16.8c-4.5-4-9.5-6.7-15.1-8.1L218.9,346.9L218.9,346.9z"
          />
          <path
            fill="#EBEBEB"
            d="M214.5,340.7l-4.4-0.3l-4,0.1c1.1,0.1,1.7,1,1.4,2.5l-0.7,25.2v0.4l3.4-0.4c1.4,0,2.8,0.1,4,0.5v-0.5
	l-0.7-25.2C213.2,341.8,213.5,341,214.5,340.7L214.5,340.7z M214.6,340.7h-0.1l0.7,0.1"
          />
          <path
            fill="#EBEBEB"
            d="M205.9,340.7h-0.4c-9.8,1-18.2,5-25.3,12.1c-6.1,6.1-9.9,13.1-11.6,21l0.3-0.3c0.4-1.1,1.4-1.4,2.8-0.7
	l23.7,8.4l0.3,0.1c0.1-2.6,1-5,2.5-6.9l-0.6-0.1l-24.1-7.2c-1.4-0.3-2.1-0.8-1.9-1.8l0.3-0.5l-0.3,0.5c-0.1,1,0.6,1.6,1.9,1.8
	l24.1,7.2l-1.6-4.8h-0.3l-6.7-1.8l-7.6-2.1l-1.2-0.3c-1.4-0.1-1.8-1-1.4-2.2l0.1-0.1l0.1-0.1c0.7-1.1,1.5-2.2,2.5-3.3l2.6-2.8
	c5.1-5.1,10.9-8.4,17.5-9.8c1.5,0,2.1,0.7,1.9,2.1l-0.3,6.6l-0.3,4.3l-0.3,5.4l4.1,3.2l0.7-25.2
	C207.6,341.7,207.2,340.8,205.9,340.7L205.9,340.7z"
          />
          <path
            fill="#EBEBEB"
            d="M203,355.6l-21.6,6.5v3.6l7.6,2.1l0.7-1.5h3.2l0.3-0.3c2.9-2.9,6.1-4.8,9.6-5.9L203,355.6L203,355.6z"
          />
          <path
            fill="#656565"
            d="M203.5,349c0.3-1.4-0.4-2.1-1.9-2.1c-6.6,1.5-12.4,4.7-17.5,9.8l-2.6,2.8l-2.5,3.3l2.5-0.7l21.6-6.5
	L203.5,349L203.5,349z"
          />
          <path
            fill="#EBEBEB"
            d="M251.5,374.5c-0.6-3.2-1.5-6.3-2.9-9.1v0.3c0.3,1.1-0.4,1.9-1.9,2.2l-24.1,7.2l-0.4,0.1
	c1.4,2.1,2.1,4.4,2.1,7l0.6-0.1l23.7-8.4c1.2-0.7,2.1-0.5,2.6,0.4L251.5,374.5L251.5,374.5z"
          />
          <path
            fill="#EBEBEB"
            d="M251.2,373.9c-0.6-1-1.4-1-2.6-0.4l-23.7,8.4l4.1,3l4.8-1.8l8.1-3l2.3-1c1.1-0.5,1.9-0.3,2.3,0.8l0.1,2.8
	c0,4.1-0.6,8-1.8,11.7c-1.5,4.4-3.7,8.5-7,12.1c-1.2,0.8-2.2,0.7-2.8-0.5l-5.9-9.1l-2.9-4.5h-5.2l12.7,18.4l1.5,2.2
	c0.7,1.4,1.5,1.8,2.6,1.1l0.3-0.1l1.5-1.4c8.3-8.3,12.4-18.2,12.4-29.9c0-2.8-0.3-5.5-0.7-8.1L251.2,373.9L251.2,373.9z"
          />
          <path
            fill="#656565"
            d="M179.1,362.7L179,363c-0.4,1.2,0.1,2.1,1.4,2.2l1.2,0.3v-3.6L179.1,362.7L179.1,362.7z"
          />
          <path
            fill="#EBEBEB"
            d="M184.2,381.6l7,2.8l4.1-3l-23.7-8.4c-1.4-0.7-2.2-0.4-2.8,0.7l-0.3,0.3c-0.6,2.8-0.8,5.8-0.8,8.8
	c0,5.8,1,11.1,3,16c2.1,5.1,5.2,9.6,9.4,13.8l1.1,1l0.6,0.3c1,0.4,1.6,0,2.3-1.2l14.2-20.8h-5.1l-4.3,6.6l-4.5,7
	c-0.6,1.2-1.5,1.4-2.6,0.7l-0.1-0.1c-1.9-2.3-3.6-4.8-4.8-7.4l-0.4-1c-2.1-4.5-3-9.6-3-15l0.1-3c0.6-1.4,1.4-1.8,2.6-1.1l5.5,2.1
	L184.2,381.6L184.2,381.6z"
          />
          <path
            fill="#D2D3D4"
            d="M184.1,382c-0.4,1.4,0.1,2.3,1.6,2.8c-0.1,5.1,1,9.6,3.3,13.8l4.3-6.6c-0.7-0.8-1.2-1.6-1.5-2.6V389
	l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4l-7-2.8L184.1,382L184.1,382z"
          />
          <path
            fill="#656565"
            d="M173.3,379.6l-0.1,3c0,5.4,1,10.5,3,15c3.4-1.4,5.2-4.1,5.1-8.4v-8.7l-5.5-2.1
	C174.8,377.8,173.9,378.3,173.3,379.6L173.3,379.6z M194.9,366l1,3.3h0.3c0.7-1.1,1.5-1.9,2.5-2.5l0.8-0.5c1-0.6,2.1-1,3.2-1.2
	l0.3-5.4c-3.6,1.1-6.9,3-9.6,5.9l-0.3,0.3h1.9L194.9,366L194.9,366z"
          />
          <path fill="#D2D3D4" d="M189.7,366l-0.7,1.5l6.7,1.9l-0.8-3.4h-2.1" />
          <path
            fill="#EBEBEB"
            d="M206.6,368.2l-4.1-3.2c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.5c-1,0.7-1.9,1.5-2.5,2.5l1.6,4.8l0.6,0.1
	c0.6-0.7,1.1-1.4,1.6-1.9c1.9-1.9,4.3-3.3,6.7-3.9L206.6,368.2L206.6,368.2z"
          />
          <path
            fill="#EBEBEB"
            d="M199.9,372.5c-0.7,0.7-1.2,1.2-1.6,1.9l0.7,0.3c1.5,0.5,2.9,0.3,4.1-0.4c0.7-0.3,1.2-0.4,1.6-0.8
	c1.4-1,2.1-2.2,2.1-4v-0.8C204.1,369.2,201.9,370.5,199.9,372.5L199.9,372.5z"
          />
          <path
            fill="#EBEBEB"
            d="M206.6,369.5c0,1.6-0.7,3-2.1,4c-0.4,0.3-1,0.5-1.6,0.8c-1.2,0.8-2.6,1-4.1,0.4l-0.7-0.3
	c-1.5,2.1-2.3,4.4-2.5,6.9l0.8,0.3c1.5,0.5,2.6,1.5,3,3c0.1,0.5,0.3,1.2,0.4,1.9c0.3,1.4,0,2.8-1,4l-0.4,0.7l1.2,1.4
	c1.4,1.4,2.9,2.5,4.5,3.2l0.6-0.8c1-1.4,2.2-1.9,3.7-2.1c0.7,0,1.4,0.1,2.1,0.3c1.5,0.1,2.8,0.8,3.6,2.1l0.6,0.7
	c1.9-0.7,3.6-1.8,5.2-3.4l0.8-0.8l-0.6-0.8c-1-1.4-1.2-2.9-0.7-4.4c0.3-0.5,0.4-1,0.8-1.5c0.6-1.4,1.5-2.3,3.2-2.9l0.7-0.3
	c-0.1-2.6-0.8-5-2.1-7l-0.8,0.3c-1.5,0.6-2.9,0.3-4.1-0.5c-0.6-0.4-1.1-0.8-1.5-1.5c-1.1-1-1.8-2.2-1.8-3.9v-0.7
	c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4v1.2L206.6,369.5L206.6,369.5z M204.6,377.2c1.5-1.5,3.3-2.2,5.5-2.2c2.1,0,4,0.8,5.5,2.2
	c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3c-2.2,0-4-0.8-5.5-2.3c-1.5-1.5-2.2-3.3-2.2-5.5
	S203,378.7,204.6,377.2L204.6,377.2z"
          />
          <path
            fill="#EBEBEB"
            d="M202.4,382.7c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5
	s-0.8-4-2.2-5.5c-1.5-1.5-3.3-2.2-5.5-2.2c-2.2,0-4,0.8-5.5,2.2C203,378.7,202.4,380.5,202.4,382.7L202.4,382.7z M195.5,381.3
	l-4.1,3c-0.1,1.1,0,2.2,0.3,3.4l0.3,1.1v0.3c0.3,1,0.8,1.9,1.5,2.6h5.1l0.3-0.4c-1.9-2.5-3-5.4-3-8.8v-1.2H195.5L195.5,381.3z"
          />
          <path
            fill="#EBEBEB"
            d="M195.8,382.7c0,3.3,1,6.3,3,8.8l0.4-0.7c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9c-0.4-1.5-1.5-2.5-3-3
	l-0.8-0.3L195.8,382.7L195.8,382.7z"
          />
          <path
            fill="#EBEBEB"
            d="M198.8,391.3l-0.3,0.4l-14.3,20.8c-0.7,1.2-1.5,1.6-2.3,1.2l-0.6-0.3c2.5,2.3,5.2,4.4,8.1,6.1l-0.6-0.5
	c-0.8-0.8-0.8-1.7,0.3-2.8l15.3-19.9l0.1-0.3c-1.6-0.7-3.2-1.8-4.5-3.2L198.8,391.3L198.8,391.3z"
          />
          <path
            fill="#EBEBEB"
            d="M204.4,396.1l1.5,5c1,0.4,2.2,0.8,3.3,1h1.1c1.1,0,2.2-0.1,3.3-0.5l1.5-5l-0.3-0.3c-1.5,0.5-3.2,0.8-5,0.8
	c-1.9,0-3.9-0.4-5.5-1.1L204.4,396.1L204.4,396.1z"
          />
          <path
            fill="#EBEBEB"
            d="M204.6,395.9c1.6,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8l-0.6-0.7c-1-1.2-2.1-1.9-3.6-2.1
	c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1L204.6,395.9L204.6,395.9z"
          />
          <path
            fill="#EBEBEB"
            d="M205.9,401l-1.5-5l-15.3,19.9c-1.1,1.1-1.1,2.1-0.3,2.8l0.6,0.5c6.2,3.6,13.1,5.2,20.6,5.2
	c7.4,0,14.2-1.6,20.2-5.1l0.7-0.7c0.7-0.8,0.6-1.6-0.4-2.6l-1.6-2.2l-13.6-17.7l-1.5,5l3.3,4.1l6.9,8.5c1,1,1,1.9-0.3,2.8
	c-4.1,1.6-8.7,2.5-13.6,2.5c-4.9,0-9.5-0.8-13.8-2.6c-1.4-1.1-1.5-2.2-0.7-3.2l6.7-8.4L205.9,401L205.9,401z"
          />
          <path
            fill="#656565"
            d="M202.4,405.2c2.3,0.8,5,1.1,7.6,1.1c2.5,0,4.8-0.3,7-1l-3.3-4.1c-1.1,0.4-2.2,0.7-3.3,0.5h-1.1
	c-1.2-0.1-2.3-0.4-3.3-1L202.4,405.2L202.4,405.2z"
          />
          <path
            fill="#EBEBEB"
            d="M214.1,369.5c0,1.6,0.6,2.9,1.8,3.9c0.6,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3
	c-0.6-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.2-3.7L214.1,369.5L214.1,369.5z"
          />
          <path
            fill="#656565"
            d="M218.2,365.2c1.2,0.3,2.3,1,3.3,1.8l0.6,0.4c0.8,0.7,1.6,1.7,2.2,2.8l5.2-1.4c-0.8-1-1.6-2.1-2.6-2.9
	c-0.4-0.4-1-0.8-1.4-1.2c-1.2-1.1-2.6-2.1-4-2.8c-1.2-0.7-2.3-1.2-3.7-1.6L218.2,365.2z"
          />
          <path
            fill="#EBEBEB"
            d="M214.1,368.2v0.5c2.3,0.5,4.3,1.8,6.2,3.7c0.8,0.8,1.5,1.7,2.1,2.5l0.4-0.1l1.6-4.8
	c-0.7-1.1-1.4-1.9-2.2-2.8l-0.6-0.4c-1-0.8-2.1-1.4-3.3-1.8L214.1,368.2L214.1,368.2z"
          />
          <path
            fill="#656565"
            d="M225.6,364.5l14.2-3.4c-0.8-1.1-1.7-2.1-2.6-3l-15.5,3.7C223,362.4,224.2,363.4,225.6,364.5L225.6,364.5z"
          />
          <path
            fill="#EBEBEB"
            d="M229.5,368.6l10.6-2.9c1.4-0.1,1.8-1,1.4-2.5l-1.6-2.3l-14.2,3.4c0.4,0.4,1,0.8,1.4,1.2
	C227.8,366.7,228.6,367.7,229.5,368.6L229.5,368.6z M224.4,382.1v0.5c0,3.6-1.1,6.6-3.4,9.2l0.1,0.3h5.2c0.8-1,1.5-1.9,1.9-2.9
	l0.3-0.7c0.6-1.2,0.7-2.5,0.7-3.7l-4.1-3L224.4,382.1L224.4,382.1z"
          />
          <path
            fill="#EBEBEB"
            d="M223.7,382.4c-1.5,0.4-2.6,1.4-3.2,2.9c-0.3,0.5-0.6,1-0.8,1.5c-0.6,1.5-0.4,3,0.7,4.4l0.6,0.8
	c2.3-2.6,3.4-5.8,3.4-9.2v-0.5L223.7,382.4L223.7,382.4z"
          />
          <path
            fill="#656565"
            d="M233.9,383.1c-0.1,4.1-1.1,8-3,11.3l11.1-4.8V380L233.9,383.1L233.9,383.1z M242,379.9v14.3h3
	c1.2-3.6,1.8-7.6,1.8-11.7l-0.1-2.8c-0.4-1.1-1.2-1.4-2.3-0.8L242,379.9L242,379.9z"
          />
          <path
            fill="#EBEBEB"
            d="M215.3,396.4l13.6,17.9c1.6-1.1,3.3-2.3,5-3.6l-12.7-18.4l-0.1-0.3l-0.8,0.8c-1.5,1.5-3.3,2.6-5.2,3.4
	L215.3,396.4L215.3,396.4z"
          />
          <path
            fill="#656565"
            d="M228.2,389.3c-0.4,1-1.1,1.9-1.9,2.9l2.9,4.5c0.6-0.8,1.1-1.5,1.6-2.3c1.9-3.3,2.9-7.2,3-11.3l-4.8,1.8
	c0.1,1.2-0.1,2.5-0.7,3.7L228.2,389.3z"
          />
          <path
            fill="#EBEBEB"
            d="M238,413.9c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2c-1.5,1.4-3.3,2.6-5,3.6l1.6,2.2c1,1,1.1,1.8,0.4,2.6l-0.7,0.7
	c2.9-1.5,5.5-3.6,8.1-5.8L238,413.9L238,413.9z"
          />
          <path
            fill="#FFFFFF"
            d="M127.1,364.5l-68.2-4c-9.4-1.5-20.5-1.6-33.4-0.3l13.2,16.4l2.8-0.1l15,0.5l68.2,9.2
	C125.3,378.5,126.1,371.2,127.1,364.5L127.1,364.5z"
          />
          <path
            fill="#FFFFFF"
            d="M38.8,376.6l-13.3-16.4L23,360l3.4,6.6l5.5,10.6"
          />
          <path
            fill="#FFFFFF"
            d="M40.4,379.9l1.1-3.4l-2.8,0.1l-6.9,0.6l1.4,2.8 M426.6,160.2l-11.6-0.1v1.6l11.6,0.1l15.7,0.6l14,1.2
	l9.2,1.1c5,0.6,8.5,0.7,10.9,0.1l-1.4-0.3c-5.8-1-11.7-1.9-17.9-2.6l-14.9-1.4L426.6,160.2L426.6,160.2z M456.4,166.3l-13.2-1.4
	c-7.6-0.3-16.2-0.4-26-0.6l-16.5-0.1c-1.6,27.1-2.5,56.8-2.6,89.3l12,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.6-0.3-0.8l1.4-67.4
	c0.3-6.1,2.2-9.2,5.9-9.6h0.3l14.3,0.3c27.5,1.2,48.6,5.9,63.1,14.2c12.9,6.9,26.8,16.6,41.7,29.2l3.2,2.8l0.1,0.1l18.6,15.7
	c3.7,4.4,5,9.5,3.9,15.3c-1.8,6.6-10,9.9-24.8,9.6L398,259.8v123.5h178.4c3.7-16.6,6.5-36.6,8.3-59.6c2.1-29.9,0.1-56.7-5.8-80.6
	c-0.8-3.7-0.3-5.9,1.9-6.6c-27.1-23.1-51.5-41.1-72.9-54.1l-3.2-1.9C492.2,173.6,476.1,168.7,456.4,166.3L456.4,166.3z M575.7,329.6
	l-1.7,10.2c-0.4,1.8-2.1,2.6-5,2.5H399.9v-16h172.2C575.2,326.3,576.3,327.5,575.7,329.6L575.7,329.6z M411.7,277.3l8.4,0.1l6.9,0.1
	c0.1-1.1,0.4-2.1,0.8-2.9c0.8-1.6,2.3-2.6,4.4-2.9l5-0.3l4.7,0.3c2.1,0.3,3.6,1.2,4.4,2.9c0.4,0.8,0.7,1.9,0.8,3h1.6l7.7,0.1
	c1,0.1,1.6,0.7,1.9,1.8v2.5c-0.3,1-1,1.5-2.2,1.8l-7.6,0.1h-1.9c-0.1,2.2-0.8,4-2.2,5.2c-0.7,0.5-1.5,1.1-2.5,1.4l-1,0.3
	c-1.2,0.3-2.3,0.4-3.6,0.4h-0.5l-4.1-0.4l-1-0.3c-1-0.3-1.8-0.8-2.5-1.4c-1.4-1.1-2.1-2.8-2.2-5l-7,0.1l-8.7,0.1
	c-0.8,0-1.4-0.4-1.5-1.4c-0.3-1.6-0.3-3.3,0.1-4.7C410.2,277.8,410.8,277.3,411.7,277.3L411.7,277.3z"
          />
          <path
            fill="#FFFFFF"
            d="M572.1,326.3H399.9v16.1h169.2c2.9,0.1,4.5-0.7,5-2.5l1.7-10.2C576.3,327.5,575.2,326.3,572.1,326.3
	L572.1,326.3z M410.1,278.7c-0.3,1.4-0.4,3-0.1,4.7c0.1,1,0.7,1.4,1.5,1.4l8.7-0.1v-7l-8.4-0.1C410.8,277.3,410.2,277.8,410.1,278.7
	L410.1,278.7z M417,280.2c0.3,0.3,0.4,0.8,0.4,1.2s-0.1,0.8-0.4,1.2c-0.3,0.3-0.8,0.5-1.2,0.5s-0.8-0.1-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.1-1,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5C416.1,279.6,416.5,279.8,417,280.2L417,280.2z"
          />
          <path
            fill="#656565"
            d="M415.6,279.6c-0.4,0-0.8,0.1-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
	s1-0.1,1.2-0.5c0.3-0.3,0.4-0.7,0.4-1.2c0-0.4-0.1-1-0.4-1.2C416.5,279.8,416.1,279.6,415.6,279.6L415.6,279.6z"
          />
          <path
            fill="#FFFFFF"
            d="M427.1,284.4l19.7-0.3l1.9-0.1v-6.3h-1.6l-20.1-0.1l-6.9-0.1v7.2"
          />
          <path
            fill="#FFFFFF"
            d="M427.7,274.7c-0.4,0.8-0.7,1.6-0.8,2.9l20.2,0.1c-0.1-1.2-0.4-2.2-0.8-3c-0.8-1.6-2.3-2.6-4.4-2.9l-4.7-0.3
	l-5,0.3C430,272.1,428.6,273,427.7,274.7L427.7,274.7z M456.4,277.8l-7.7-0.1v6.3l7.6-0.1c1.2-0.3,1.9-1,2.2-1.8v-2.5
	C458.1,278.5,457.4,278,456.4,277.8L456.4,277.8z M446.8,284.2l-19.5,0.3c0.1,2.1,0.8,3.7,2.2,5c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3
	l4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4C446,288.1,446.7,286.4,446.8,284.2L446.8,284.2z M499.9,170.4
	l0.3-2.1l-1.1-0.3l-4.8-1.2l-5-1.2l-0.8,1.8l-0.4,0.8c4,1,8,2.1,11.7,3.2L499.9,170.4L499.9,170.4z"
          />
          <path fill="#FFFFFF" d="M498.9,168.1l-2.3-6.6l-2.6-0.7l0.1,6.1" />
          <path
            fill="#656565"
            d="M492.9,192c13.2,7.2,27.5,17.2,42.8,30.3v-2.9c-15-12.5-28.9-22.3-41.7-29.2c-14.6-8.3-35.6-13.1-63.1-14.2
	l-14.3-0.3h-0.3c-3.7,0.4-5.6,3.6-5.9,9.6l-1.4,67.4c0,0.3,0.1,0.6,0.3,0.8c0.3,0.1,0.4,0.3,0.8,0.3l126.6,3.2v-0.8l-1.9-0.1
	c-2.2-0.1-4-0.7-5.4-1.4l-118.2-2.9l1.4-66.3c0.1-4.5,1.5-7,3.9-7.6l14.3,0.3C457.8,179.2,478.4,183.9,492.9,192L492.9,192z"
          />
          <path
            fill="#EBEBEB"
            d="M492.9,192c-14.4-8.1-35.1-12.8-62.2-13.9l-14.3-0.3c-2.5,0.6-3.7,3-3.9,7.6l-1.4,66.3l118.2,2.9
	c-1.9-1.1-2.9-2.8-3.2-5c0-2.1,0.1-4,0.3-5.8c0.4-3.4,1.1-6.2,2.2-8.4c1.2-2.3,3.7-3.9,7.4-4.5l-0.3-8.7
	C520.4,209.2,506.1,199.1,492.9,192L492.9,192z"
          />
          <path
            fill="#FFFFFF"
            d="M356.6,154.3c0-0.4-0.1-0.6-0.5-0.6H350c-0.4,0-0.5,0.1-0.5,0.6v2.3h7.2V154.3z"
          />
          <path
            fill="#656565"
            d="M381.6,174.8l-66.6-0.1c-18.7,0-30.4,5.9-34.7,17.6c-4.1,9.1-7.2,17.5-9.1,25l-1.9,10
	c-0.3,2.6-0.4,5.2-0.4,7.6v0.3c-0.1,1,0,2.1,0.1,2.9c1.1,8.1,10.7,12.1,28.9,12.2l84.2,2.5c0.3,0,0.5-0.1,0.7-0.3
	c0.3-0.3,0.3-0.4,0.4-0.8l3.6-70.3C386.6,177.3,384.8,175.1,381.6,174.8L381.6,174.8z M384.6,181.7l-3.6,68.9l-83.1-2.5
	c-16.6,0-25.5-3.4-26.8-10.3c-0.1-0.8-0.1-1.5,0-2.3V235c-0.1-11.4,3.6-25.3,11.3-41.8c4.3-10.7,15.1-16.1,32.6-16.1l66.3,0.1
	C383.4,177.3,384.4,178.9,384.6,181.7L384.6,181.7z"
          />
          <path
            fill="#EBEBEB"
            d="M381.5,177l-66.3-0.1c-17.6,0-28.5,5.4-32.6,16.1c-7.6,16.5-11.3,30.4-11.3,41.8v0.4c-0.1,0.8-0.1,1.6,0,2.3
	c1.2,6.9,10.2,10.3,26.8,10.3l83.1,2.5l3.6-68.9C384.4,178.9,383.4,177.3,381.5,177L381.5,177z"
          />
          <path
            fill="#FFFFFF"
            d="M386.8,181.5l-3.6,70.3c0,0.3-0.1,0.6-0.4,0.8c-0.1,0.1-0.4,0.3-0.7,0.3l15.8,0.4c0.1-32.5,1-62.2,2.6-89.3
	c-29.6-0.3-67.3-0.3-112.9,0h-8.3c-15.4,29.3-25.7,57.9-30.9,86c-1.2,12.5-1.8,27.9-1.5,46.2l1.5,0.3c19.9,5.5,31.8,18.8,35.8,39.9
	c3.3,14.3,5.8,30,7.4,46.8h106.2V259.8l-90.4-2.3c-5.2,0.1-10.6-0.3-15.8-1l-3.7-0.7c-7.8-1.4-13.5-3.3-16.6-5.5
	c-3-2.2-4.3-6.6-3.7-13.3l1.8-9.5c0.4-3.2,1-6.6,1.9-10c1.9-7.6,5-16,9.1-25c4.4-11.7,16-17.5,34.7-17.6l66.6,0.1
	C384.8,175.1,386.6,177.3,386.8,181.5L386.8,181.5z M262.5,279.5c-0.3-1.6-0.3-3.3,0.1-4.7c0.1-1,0.7-1.4,1.6-1.4l8.4,0.1l6.9,0.1
	c0.1-1.1,0.4-2.1,0.8-2.9c0.8-1.6,2.3-2.8,4.4-2.9l5-0.3l4.8,0.3c2.1,0.3,3.6,1.2,4.4,2.9c0.4,0.8,0.7,1.8,0.8,3h1.6l7.7,0.1
	c1,0.1,1.6,0.7,1.9,1.8v2.5c-0.3,1-1,1.5-2.2,1.8l-7.6,0.1h-1.9c-0.1,2.2-0.8,4-2.2,5.2c-0.7,0.5-1.5,1.1-2.5,1.4l-1,0.3
	c-1.2,0.3-2.3,0.4-3.6,0.4h-0.5c-1.4,0-2.8-0.1-4.1-0.4l-1-0.3c-1-0.3-1.8-0.8-2.5-1.4c-1.4-1.1-2.1-2.8-2.2-5l-7,0.1l-8.7,0.1
	C263.2,280.7,262.6,280.3,262.5,279.5L262.5,279.5z M308.4,326.3h86.9v16.1h-81.4c-2.1-0.1-3.9-3.4-5.5-10.2c-1.2-3.6-1-5.5,0.5-5.8
	h-0.5L308.4,326.3L308.4,326.3z"
          />
          <path
            fill="#FFFFFF"
            d="M264,280.7l8.7-0.1v-7l-8.4-0.1c-1,0-1.5,0.4-1.6,1.4c-0.3,1.4-0.4,2.9-0.1,4.7
	C262.6,280.3,263.2,280.7,264,280.7L264,280.7z M280.4,270.8c-0.4,0.8-0.7,1.6-0.8,2.9l20.1,0.1c-0.1-1.2-0.4-2.2-0.8-3
	c-0.8-1.6-2.3-2.8-4.4-2.9l-4.8-0.3l-5,0.3C282.7,268.1,281.2,269,280.4,270.8L280.4,270.8z"
          />
          <path
            fill="#FFFFFF"
            d="M299.3,280.2h1.9v-6.3h-1.6l-20.1-0.1l-6.9-0.1v7l7.2-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M284.5,286.8l1,0.3c1.4,0.3,2.8,0.5,4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4
	c1.4-1.2,2.1-2.9,2.2-5.2l-19.5,0.3c0.1,2.1,0.8,3.7,2.2,5C282.7,286.1,283.5,286.5,284.5,286.8L284.5,286.8z M311,278.2v-2.5
	c-0.3-1.1-1-1.6-1.9-1.8l-7.7-0.1v6.3l7.6-0.1C309.9,279.8,310.8,279.2,311,278.2L311,278.2z M308.6,332c1.5,6.7,3.4,10.2,5.5,10.2
	h81.4v-16h-86.4C307.5,326.5,307.3,328.5,308.6,332L308.6,332z M382.2,252.9l-84.2-2.5c-18.2-0.1-27.8-4.1-28.9-12.2
	c-0.1-1-0.1-1.9-0.1-2.9V235c0-2.5,0.1-5,0.4-7.6l-1.8,9.5c-0.5,6.7,0.7,11.3,3.7,13.3c3.3,2.3,8.8,4.1,16.6,5.5l3.7,0.7
	c5.2,0.7,10.6,1.1,15.8,1l90.4,2.3v-6.5L382.2,252.9L382.2,252.9z M398,259.8l138.8,2.9l-0.1-5.8l-126.6-3.2l-12.1-0.4 M271.3,217.3
	c-0.8,3.4-1.5,6.9-1.9,10L271.3,217.3L271.3,217.3z M186.5,155.4v2.3l8-0.1v-2.3c0-0.4-0.1-0.6-0.6-0.6h-6.9
	C186.7,154.9,186.5,155.1,186.5,155.4L186.5,155.4z"
          />
          <path
            fill="#656565"
            d="M617.4,241.8l-19.1,1c-3,0.1-5.5-0.7-7.4-2.3c-21.3-16.8-43.9-33.4-68-50.2c-4.4-2.1-5.6-3.9-3.6-5.2
	l6.7-3.7l-5.6-3.3l-12.5,4.4c21.5,12.9,45.8,31,72.9,54.1l8,6.9c6.3,5,14.3,7.4,23.9,7.6h0.5l12.4-2.9L617.4,241.8L617.4,241.8z"
          />
          <path
            fill="#FFFFFF"
            d="M612.8,251.1c-9.6-0.1-17.6-2.6-23.9-7.6l-8-6.9c-2.1,0.7-2.8,2.9-1.9,6.6c5.9,23.9,8,50.8,5.8,80.6
	c-1.8,23-4.5,42.9-8.3,59.6h9.5c2.3-22.8,5.9-41.8,10.9-56.8v-0.1c3.9-10.2,9.5-17.5,17.1-21.7c8.3-6.1,25-8.7,50.8-7.6h0.3
	c17.7-0.5,32.3,0.3,44,2.6l0.3,0.1c12.7,3.6,22.1,10.2,28.3,20.1l21,0.8l-7.2-31.5c-0.4-3.7,0.4-5.6,2.5-5.8h22l-1.7-4
	c-0.5-1.1-1.1-2.1-1.8-3l-1-1.1c-1.9-2.2-4.1-3.9-6.7-4.7c-17.6-4.8-42.2-9.2-73.7-13.1c-23.9-2.8-49.9-4.8-78-6.5h-0.3L612.8,251.1
	L612.8,251.1z"
          />
          <path
            fill="#EBEBEB"
            d="M617.4,241.8c-30.8-23.2-61.2-43.3-91.2-60.5l-6.7,3.7c-2.1,1.4-0.8,3.2,3.6,5.2
	c23.9,16.8,46.6,33.6,68,50.2c1.9,1.6,4.4,2.5,7.4,2.3L617.4,241.8L617.4,241.8z"
          />
          <path
            fill="#FFFFFF"
            d="M557.5,237.8L539,222.1l-0.1-0.1l-3.2-2.8v2.9l0.3,8.7l5.1-0.3c2.9-0.3,5,0.7,5.9,3l1.9,8.7h5.5
	c1-0.1,1.4,0.7,1.2,2.3c-0.4,4.5-3,8.3-7.8,10.9c-1.4,0.3-3.2,0.6-5.2,0.6l-0.3,0.4c-0.1,0.3-0.4,0.3-0.8,0.3l-4.8-0.1l0.1,5.8
	c14.7,0.3,23-3,24.8-9.6C562.5,247.3,561.3,242.2,557.5,237.8L557.5,237.8z"
          />
          <path
            fill="#FFFFFF"
            d="M554.4,242.3c-0.1,2.8-1,5.2-2.5,7.2h-3c-0.3,2.5-2.3,4.4-6.3,6.1l-1.8,0.7h1.8c2.1-0.1,3.9-0.3,5.2-0.6
	c4.8-2.6,7.4-6.3,7.8-10.9C555.8,243,555.3,242.3,554.4,242.3L554.4,242.3z"
          />
          <path
            fill="#FFFFFF"
            d="M554.4,242.3h-5.5c-3.3,0.7-7.6,1.1-12.7,1.2c-3,0.1-6.3,0.1-9.9,0.1c-0.1,1.8-0.3,3.7-0.3,5.8
	c0.3,2.2,1.4,3.9,3.2,5c1.4,0.8,3.2,1.2,5.4,1.4l1.9,0.1l4.1,0.1l1.8-0.7c4-1.6,6.2-3.6,6.3-6.1h3
	C553.6,247.6,554.4,245.2,554.4,242.3L554.4,242.3z"
          />
          <path
            fill="#FFFFFF"
            d="M528.5,235.5c-1.1,2.2-1.8,5-2.2,8.4c3.6,0,6.9,0,9.9-0.1c5.1-0.3,9.2-0.7,12.7-1.2l-1.9-8.7
	c-1-2.3-3-3.3-5.9-3l-5.1,0.3C532.2,231.5,529.8,233,528.5,235.5L528.5,235.5z M536.6,256.9l4.8,0.1c0.3,0,0.5-0.1,0.8-0.3l0.3-0.4
	h-1.8l-4.1-0.1L536.6,256.9L536.6,256.9z M664.7,297.2c-25.6-1.1-42.5,1.5-50.8,7.6c-7.6,4.3-13.2,11.4-17.1,21.7v0.1
	c-5,15-8.5,34-10.9,56.8h9.5c1.8-21.6,5-39.6,9.5-53.9c2.9-8.3,7.3-14,13.2-17.5l0.4-0.3c5.9-4.1,16.9-6.3,33-6.5h21.6
	c13.2-0.1,24.5,0.8,33.7,2.6c8.1,2.3,14.6,6.2,19.4,11.6l11.3,0.4c-6.2-9.8-15.7-16.5-28.3-20.1l-0.3-0.1c-11.7-2.3-26.3-3.2-44-2.6
	h-0.3L664.7,297.2L664.7,297.2z"
          />
          <path
            fill="#656565"
            d="M651.6,305.3c-16.1,0.1-27.1,2.3-33,6.5l-0.4,0.3c-5.9,3.4-10.3,9.2-13.2,17.5c-4.5,14.3-7.6,32.3-9.5,53.9
	l-0.3,3l14.2-7.4c0.8-15.4,6.7-28.8,17.9-39.9c2.6-2.6,5.4-5,8.3-7c10.2-7.3,21.9-11,35.2-11c13.2,0,24.9,3.7,35.1,11
	c2.9,2.1,5.6,4.4,8.3,7l2.9,3.2c5.8,0,12.4,1.2,19.9,3.6l-0.8-4.1c-1.5-9.1-4.8-16.5-10-22.3c-4.8-5.4-11.3-9.2-19.4-11.6
	c-9.2-1.8-20.5-2.8-33.7-2.6L651.6,305.3L651.6,305.3z"
          />
          <path
            fill="#656565"
            d="M635.5,332c-2.9,2.1-5.6,4.4-8.3,7c-11.1,11.1-17.2,24.5-17.9,39.9l-0.1,3.6c0,5.8,0.7,11.4,2.1,16.6
	c0.5,2.2,1.4,4.4,2.2,6.6l1.1,2.5c3,6.3,7.2,12.4,12.5,17.7c12,12,26.4,18,43.5,18c16.9,0,31.4-6.1,43.5-18c12-12,18-26.4,18-43.5
	c0-3.6-0.3-7.2-0.8-10.6c-1.8-11.1-6.5-21-14.2-29.7l-2.9-3.2c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.1-11
	C657.6,321.2,645.9,324.7,635.5,332L635.5,332z M670.8,338.4c12.2,0,22.7,4.3,31.4,12.9s12.9,19.1,12.9,31.4
	c0,12.2-4.3,22.7-12.9,31.4s-19.1,13.1-31.4,13.1s-22.7-4.4-31.4-13.1c-1.8-1.8-3.4-3.7-4.8-5.6l-1.7-2.5
	c-4.3-6.9-6.5-14.6-6.5-23.2c0-12.2,4.3-22.7,12.9-31.4C648.1,342.6,658.5,338.4,670.8,338.4L670.8,338.4z"
          />
          <path
            fill="#EBEBEB"
            d="M639.4,351.3c-8.7,8.7-12.9,19.1-12.9,31.4c0,8.7,2.2,16.4,6.5,23.2l1.7,2.5c1.4,1.9,3,3.9,4.8,5.6
	c8.7,8.7,19.1,13.1,31.4,13.1c12.2,0,22.7-4.4,31.4-13.1s12.9-19.1,12.9-31.4c0-12.2-4.3-22.7-12.9-31.4
	c-8.7-8.7-19.1-12.9-31.4-12.9S648.1,342.6,639.4,351.3L639.4,351.3z M670.8,340.6l4.4,0.3h0.1l0.5,0.1c9.4,1.1,17.6,5.1,24.6,12.1
	c3.9,3.9,6.7,8,8.8,12.5c1.4,2.9,2.3,5.9,2.9,9.1c0.4,2.6,0.7,5.2,0.7,8.1c0,11.7-4.1,21.6-12.4,29.9L699,414
	c-2.5,2.3-5.2,4.3-8.1,5.8c-6.1,3.3-12.8,5.1-20.2,5.1c-7.6,0-14.4-1.8-20.6-5.2c-2.8-1.6-5.5-3.7-8-6.1l-1.1-1
	c-8.3-8.3-12.4-18.2-12.4-29.9c0-3,0.3-5.9,0.8-8.8c1.7-8,5.5-14.9,11.6-21c7.2-7.2,15.5-11.1,25.3-12.1h0.4L670.8,340.6
	L670.8,340.6z"
          />
          <path
            fill="#EBEBEB"
            d="M675.2,340.7l-4.4-0.3l-4,0.1c1.1,0.1,1.7,1,1.4,2.5l-0.7,25.2v0.4l3.4-0.4c1.4,0,2.8,0.1,4,0.5v-0.5
	l-0.7-25.2C673.9,341.8,674.3,341,675.2,340.7L675.2,340.7z"
          />
          <path
            fill="#EBEBEB"
            d="M674.1,343.2l0.7,25.2l4.1-3.2l-0.3-5.2l-0.5-11.1c-0.3-1.2,0.3-1.9,1.5-2.1l0.4,0.1c4,1,7.8,2.6,11.3,5.1
	c1.5,1,2.9,2.2,4.3,3.4l1.2,1.1c2.1,2.1,3.9,4.4,5.4,6.7c0.4,1.5,0,2.2-1.4,2.5l-10.6,2.9l-5.2,1.4l-1.7,4.8l24.1-7.2
	c1.5-0.3,2.1-1,1.8-2.2v-0.3c-2.1-4.5-5-8.7-8.8-12.5c-7-7-15.1-11-24.6-12.1H675C674.3,341,673.9,341.8,674.1,343.2L674.1,343.2z
	 M666.6,340.7h-0.4c-9.8,1-18.2,5-25.3,12.1c-6.1,6.1-9.9,13.1-11.6,21l0.3-0.3c0.4-1.1,1.4-1.4,2.8-0.7l23.7,8.4l0.3,0.1
	c0.3-2.6,1.1-5,2.5-6.9l-0.5-0.1l-24.1-7.2c-1.4-0.3-2.1-0.8-1.9-1.8l0.3-0.5l-0.3,0.5c-0.1,1,0.5,1.6,1.9,1.8l24.1,7.2l-1.7-4.8
	h-0.3l-6.7-1.8l-8.7-2.3c-1.4-0.1-1.8-1-1.4-2.2l0.1-0.1c1.5-2.2,3.2-4.3,5.1-6.2c2.8-2.8,5.8-5.1,9.1-6.7c1.5-0.8,3-1.5,4.5-2.1
	c1.2-0.4,2.5-0.8,3.7-1c1.5,0,2.1,0.7,1.9,2.1l-0.3,4.1l-0.3,6.7l-0.3,5.4l4.1,3.2l0.7-25.2C668.3,341.7,667.9,340.8,666.6,340.7
	L666.6,340.7z"
          />
          <path fill="#EBEBEB" d="M675.8,340.8l-0.5-0.1h-0.1" />
          <path
            fill="#656565"
            d="M662.2,346.8c-1.2,0.3-2.5,0.5-3.7,1l5.4,5.2l0.3-4.1C664.3,347.6,663.7,346.9,662.2,346.8L662.2,346.8z"
          />
          <path
            fill="#EBEBEB"
            d="M658.5,347.9c-1.5,0.6-3.2,1.2-4.5,2.1l9.6,9.9l0.3-6.7L658.5,347.9L658.5,347.9z"
          />
          <path
            fill="#656565"
            d="M644.8,356.7c-1.9,1.9-3.6,4-5.1,6.2v0.1c-0.4,1.2,0.1,2.1,1.4,2.2l8.7,2.3l0.7-1.5h3l0.3-0.3
	c2.9-2.9,6.1-4.8,9.6-5.9l-9.6-9.9C650.7,351.6,647.7,353.8,644.8,356.7L644.8,356.7z"
          />
          <path
            fill="#EBEBEB"
            d="M629.8,373.6l-0.3,0.3c-0.5,2.8-0.8,5.8-0.8,8.8c0,11.7,4.1,21.6,12.4,29.9l1.1,1c0.1,0,0.3,0.1,0.4,0.3
	c1,0.4,1.7,0,2.3-1.2l14.2-20.8H654l-4.3,6.6l-0.7,1l-4,6.1c-0.5,1.2-1.5,1.4-2.6,0.7l-0.1-0.1c-2.9-3.4-5.1-7.3-6.5-11.4
	c-0.8-2.3-1.4-4.8-1.7-7.4c-0.1-1.5-0.3-2.9-0.3-4.4l0.1-3c0.5-1.4,1.4-1.8,2.6-1.1l8.1,3.2l7.2,2.8l4.1-3l-23.7-8.4
	C631.1,372.2,630.2,372.5,629.8,373.6L629.8,373.6z"
          />
          <path
            fill="#656565"
            d="M634,382.7c0,1.5,0.1,3,0.3,4.4h12.2v-2.3c-1.5-0.4-2.1-1.4-1.7-2.8v-0.3l-8.1-3.2c-1.2-0.5-2.1-0.3-2.6,1.1
	L634,382.7L634,382.7z M653.6,366h2.1l0.8,3.3h0.3c0.5-1.1,1.5-1.9,2.5-2.5l0.8-0.5c1-0.6,2.1-1,3.2-1.2l0.3-5.4
	c-3.6,1.1-6.9,3-9.6,5.9L653.6,366L653.6,366z"
          />
          <path
            fill="#D2D3D4"
            d="M649.7,367.5l6.7,1.9l-0.8-3.4h-5.2 M644.8,382c-0.4,1.4,0.1,2.3,1.7,2.8v2.3c0.1,2.1,0.4,4,1,5.9
	s1.4,3.7,2.3,5.4l4.3-6.6c-0.7-0.8-1.2-1.6-1.5-2.6l-0.1-0.3l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4l-7.2-2.8L644.8,382L644.8,382z"
          />
          <path
            fill="#EBEBEB"
            d="M656.7,369.5l1.7,4.8l0.5,0.1c0.5-0.7,1.1-1.4,1.7-1.9c1.9-1.9,4.3-3.3,6.7-3.9v-0.4l-4.1-3.2
	c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.5C658.2,367.5,657.4,368.3,656.7,369.5L656.7,369.5z"
          />
          <path
            fill="#EBEBEB"
            d="M667.3,369.5c0,1.6-0.7,3-2.1,4c-0.4,0.3-1,0.5-1.7,0.8c-1.2,0.8-2.6,1-4.1,0.4l-0.7-0.3
	c-1.5,2.1-2.3,4.4-2.5,6.9l0.8,0.3c1.5,0.5,2.6,1.5,3,3c0.1,0.5,0.3,1.2,0.4,1.9c0.3,1.4,0,2.8-1,4l-0.4,0.7l1.2,1.4
	c1.4,1.4,2.9,2.5,4.5,3.2l0.5-0.8c1-1.4,2.2-1.9,3.7-2.1c0.5,0,1.2,0.1,2.1,0.3c1.5,0.1,2.8,0.8,3.7,2.1l0.4,0.7
	c1.9-0.7,3.6-1.8,5.2-3.4l0.8-0.8l-0.5-0.8c-1-1.4-1.2-2.9-0.7-4.4l0.8-1.5c0.5-1.4,1.5-2.3,3-2.9l0.7-0.3c-0.1-2.6-0.8-5-2.1-7
	l-0.8,0.3c-1.5,0.6-2.9,0.3-4.1-0.5c-0.5-0.4-1.1-0.8-1.5-1.5c-1.1-1-1.8-2.2-1.8-3.9v-0.7c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4v1.2
	L667.3,369.5L667.3,369.5z M676.3,377.2c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3
	c-2.1,0-4-0.8-5.5-2.3c-1.5-1.5-2.2-3.3-2.2-5.5s0.8-4,2.2-5.5c1.5-1.5,3.3-2.2,5.5-2.2S674.8,375.6,676.3,377.2L676.3,377.2z"
          />
          <path
            fill="#EBEBEB"
            d="M670.8,375c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3
	c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5C674.8,375.6,673,375,670.8,375L670.8,375z M667.3,368.6
	c-2.5,0.5-4.8,1.9-6.7,3.9c-0.7,0.7-1.2,1.2-1.7,1.9l0.7,0.3c1.5,0.5,2.9,0.3,4.1-0.4c0.5-0.3,1.1-0.4,1.7-0.8c1.4-1,2.1-2.2,2.1-4
	v-0.8L667.3,368.6L667.3,368.6z M674.8,369.5c0,1.6,0.5,2.9,1.8,3.9c0.5,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3
	c-0.5-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.1-3.7v0.5H674.8L674.8,369.5z"
          />
          <path
            fill="#EBEBEB"
            d="M674.8,368.9c2.3,0.5,4.3,1.8,6.1,3.7c0.8,0.8,1.5,1.6,2.1,2.5l0.4-0.1l1.7-4.8c-0.6-1.1-1.4-1.9-2.2-2.8
	l-0.5-0.4c-1-0.8-2.1-1.4-3.3-1.8l-4.1,3.2L674.8,368.9L674.8,368.9z M656.2,381.3l-4.1,3c-0.1,1.1,0,2.2,0.3,3.4l0.3,1.1l0.1,0.3
	c0.3,1,0.8,1.9,1.5,2.6h5.1l0.3-0.4c-1.9-2.5-3-5.4-3-8.8v-1.2H656.2L656.2,381.3z"
          />
          <path
            fill="#EBEBEB"
            d="M657.3,381.7l-0.8-0.3v1.2c0,3.3,1,6.3,3,8.8l0.4-0.7c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9
	C659.9,383.2,658.9,382.2,657.3,381.7L657.3,381.7z"
          />
          <path
            fill="#EBEBEB"
            d="M665.3,395.9c-1.7-0.7-3.2-1.8-4.5-3.2l-1.2-1.4l-0.3,0.4l-14.3,20.8c-0.7,1.2-1.5,1.6-2.3,1.2
	c-0.1-0.1-0.3-0.3-0.4-0.3c2.5,2.3,5.2,4.4,8,6.1l-0.6-0.5c-0.8-0.8-0.8-1.7,0.3-2.8l15.3-19.9L665.3,395.9L665.3,395.9z"
          />
          <path
            fill="#EBEBEB"
            d="M665.1,396.1l1.5,5c1,0.4,2.2,0.8,3.3,1h1.1c1.1,0,2.2-0.1,3.3-0.5l1.5-5l-0.3-0.3c-1.5,0.5-3.2,0.8-5,0.8
	c-1.9,0-3.9-0.4-5.5-1.1L665.1,396.1L665.1,396.1L665.1,396.1z"
          />
          <path
            fill="#EBEBEB"
            d="M665.3,395.9c1.7,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8l-0.4-0.7c-1-1.2-2.2-1.9-3.7-2.1
	c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1L665.3,395.9L665.3,395.9z M676,396.4l13.6,17.9c1.8-1.1,3.4-2.3,5-3.6l-12.7-18.4
	l-0.1-0.3l-0.8,0.8c-1.5,1.5-3.3,2.6-5.2,3.4L676,396.4L676,396.4z"
          />
          <path
            fill="#656565"
            d="M671.2,401.9h-1.1c-1.2-0.1-2.3-0.4-3.3-1l-3.4,4.3c2.3,0.8,5,1.1,7.6,1.1c2.5,0,4.8-0.3,7-1l-3.3-4.1
	C673.4,401.8,672.3,402.1,671.2,401.9L671.2,401.9z"
          />
          <path
            fill="#EBEBEB"
            d="M666.6,401l-1.5-5l-15.3,19.9c-1.1,1.1-1.1,2.1-0.3,2.8l0.5,0.5c6.2,3.6,13.1,5.2,20.6,5.2
	c7.4,0,14.2-1.6,20.2-5.1l0.7-0.7c0.7-0.8,0.5-1.6-0.4-2.6l-1.7-2.2L676,396.1l-1.5,5l3.3,4.1l6.9,8.5c1,1,1,1.9-0.3,2.8
	c-4.1,1.6-8.7,2.5-13.6,2.5c-5,0-9.5-0.8-13.8-2.6c-1.4-1.1-1.5-2.2-0.5-3.2l6.7-8.4L666.6,401L666.6,401z"
          />
          <path
            fill="#656565"
            d="M646.4,387.1h-12.2l13.2,5.9C646.8,391,646.5,389.1,646.4,387.1z"
          />
          <path
            fill="#656565"
            d="M647.4,393l-13.2-5.9c0.3,2.6,0.8,5.1,1.7,7.4c4.1,2.9,8.5,4.5,13.2,5l0.7-1
	C648.8,396.7,647.9,394.9,647.4,393z M695.5,355.4l-11.8,7c1.4,1,2.8,2.1,4,3.3c1,1,1.8,1.9,2.5,2.9l10.6-2.9c1.4-0.1,1.8-1,1.4-2.5
	c-1.5-2.3-3.3-4.5-5.4-6.7L695.5,355.4L695.5,355.4z"
          />
          <path
            fill="#656565"
            d="M691.3,352l-12.5,8c1.8,0.5,3.4,1.5,5,2.5l11.8-7C694.1,354.2,692.6,353.1,691.3,352L691.3,352z M680,346.9
	l-0.4-0.1c-1.2,0.1-1.7,0.8-1.5,2.1l0.5,11.1l12.5-8C687.7,349.6,684,348,680,346.9L680,346.9z"
          />
          <path
            fill="#656565"
            d="M682.7,367.4c0.8,0.7,1.7,1.7,2.2,2.6l5.2-1.4c-0.8-1-1.7-2.1-2.5-2.9c-1.2-1.2-2.6-2.3-4-3.3
	c-1.5-1-3.3-1.9-5-2.5l0.3,5.2c1.2,0.3,2.3,1,3.3,1.8L682.7,367.4L682.7,367.4z"
          />
          <path
            fill="#EBEBEB"
            d="M694.6,383.1l5.2-1.9l5.2-2.1c1.1-0.5,1.9-0.3,2.3,0.8v0.4c0,0.8,0.1,1.5,0.1,2.3c0,2.1-0.1,4-0.4,5.9
	c-0.6,3.9-1.7,7.4-3.4,10.9c-1.4,2.5-2.9,5-5,7.2c-1.2,0.8-2.2,0.7-2.8-0.5l-3-4.5l-3-4.5l-2.9-4.5h-5.2l12.7,18.4l1.5,2.2
	c0.7,1.4,1.5,1.8,2.6,1.1l0.3-0.1l1.5-1.4c8.3-8.3,12.4-18.2,12.4-29.9c0-2.8-0.3-5.5-0.7-8.1l-0.1-0.5c-0.5-1-1.4-1-2.6-0.4
	l-23.7,8.4l4.1,3L694.6,383.1L694.6,383.1z"
          />
          <path
            fill="#656565"
            d="M689.7,384.9c0.1,1.2-0.1,2.5-0.7,3.7l-0.3,0.7c-0.4,1-1.1,1.9-1.9,2.9l2.9,4.5c0.7-1,1.2-1.8,1.8-2.8
	c1.8-3.3,2.8-6.9,2.8-10.9L689.7,384.9L689.7,384.9z"
          />
          <path
            fill="#EBEBEB"
            d="M681.4,385.1l-0.8,1.5c-0.5,1.5-0.4,3,0.7,4.4l0.5,0.8c2.3-2.6,3.4-5.8,3.4-9.2v-0.5l-0.7,0.3
	C682.9,382.8,681.9,383.8,681.4,385.1z M685.1,382.1l0.5-0.1l23.7-8.4c1.2-0.7,2.1-0.5,2.6,0.4l0.1,0.5c-0.5-3.2-1.5-6.3-2.9-9.1
	v0.3c0.3,1.1-0.3,1.9-1.8,2.2l-24.1,7.2l-0.4,0.1C684.2,377.2,685.1,379.5,685.1,382.1L685.1,382.1z"
          />
          <path
            fill="#EBEBEB"
            d="M685.1,382.7c0,3.6-1.1,6.6-3.4,9.2l0.1,0.3h5.2c0.8-1,1.5-1.9,1.9-2.9l0.3-0.7c0.5-1.2,0.7-2.5,0.7-3.7
	l-4.1-3l-0.5,0.1L685.1,382.7L685.1,382.7z"
          />
          <path
            fill="#656565"
            d="M699.8,381.1l-0.5,11.3c2.6-1,5.1-2.2,7.7-3.9c0.3-1.9,0.4-3.9,0.4-5.9c0-0.8,0-1.5-0.1-2.3v-0.4
	c-0.4-1.1-1.2-1.4-2.3-0.8L699.8,381.1L699.8,381.1z"
          />
          <path
            fill="#656565"
            d="M707.1,388.4c-2.6,1.6-5.1,2.9-7.7,3.9c-2.5,0.8-5,1.4-7.6,1.6c-0.6,1-1.1,1.9-1.8,2.8l3,4.5
	c3.3,0.3,6.9-0.3,10.6-2.1C705.3,395.9,706.5,392.3,707.1,388.4L707.1,388.4z"
          />
          <path
            fill="#EBEBEB"
            d="M694.6,383.1c-0.1,4-1,7.6-2.8,10.9c2.5-0.1,5.1-0.7,7.6-1.6l0.5-11.3L694.6,383.1L694.6,383.1z M691,419.7
	c2.9-1.5,5.5-3.6,8.1-5.8l-0.3,0.1c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2c-1.5,1.4-3.3,2.6-5,3.6l1.7,2.2c1,1,1.1,1.8,0.4,2.6L691,419.7
	L691,419.7z"
          />
          <path
            fill="#656565"
            d="M609.4,379.1l-14.2,7.4l-0.3,4.1H284.1v7.2h317.6l1.1,1.4h8.7c-1.4-5.2-2.1-10.7-2.1-16.6L609.4,379.1
	L609.4,379.1z"
          />
          <path fill="#FFFFFF" d="M595.5,383.5H586l-0.7,7v0.1h9.6l0.3-4.1" />
          <path
            fill="#FFFFFF"
            d="M398,383.5H283.4l0.7,7.2h301.3v-0.1l0.7-7h-9.5"
          />
          <path
            fill="#EBEBEB"
            d="M751.5,289.4l7.2,31.5l19.8,1.1l1.8,0.1h0.7l15.4,1.8h0.3l3.4,0.5l-0.3-3v-0.1l-1.2-13.5l-1.8-11.7
	l-2.5-10.5l-18-1.9H754C751.9,283.6,751.1,285.5,751.5,289.4L751.5,289.4z M755,284.4l4.8,0.5l5.9,34.3l-6.1-0.5l-6.5-29.2
	C752.3,286.4,752.9,284.7,755,284.4L755,284.4z M772.1,289.4l12,0.7v18.7l-17.9,0.7l-4-23l9.9,1V289.4z M766.2,311.3l27,1.5h0.3
	c0.8,0.3,1.2,1,1.2,2.1v3.6c0.1,1.5,0.3,2.6,0.7,3.4l-1.8-0.1l-10.7-0.8l-15.8-1.4L766.2,311.3L766.2,311.3z"
          />
          <path
            fill="#EBEBEB"
            d="M753.2,289.5l6.5,29.2l6.1,0.5l-5.9-34.3l-4.8-0.5C752.9,284.7,752.3,286.4,753.2,289.5L753.2,289.5z
	 M762.1,286.4l4,23.1l18-0.7v-18.7l-12-0.7v-2.1 M766.9,319.5l15.8,1.4l10.7,0.8l1.8,0.1c-0.4-0.8-0.7-1.9-0.7-3.4v-3.6
	c0-1.1-0.3-1.8-1.2-2.1h-0.3l-27-1.5L766.9,319.5L766.9,319.5z M773.5,316.1c0-0.7,0.1-1.2,0.5-1.6c0.3-0.4,0.8-0.7,1.2-0.7
	c0.5,0,1,0.3,1.2,0.7c0.3,0.4,0.5,1,0.5,1.6s-0.1,1.2-0.5,1.6c-0.3,0.4-0.8,0.7-1.2,0.7c-0.5,0-1-0.3-1.2-0.7
	C773.7,317.3,773.5,316.8,773.5,316.1L773.5,316.1z M790.7,315l0.3,0.1l0.3,0.3c0.3,0.4,0.5,1,0.5,1.6s-0.1,1.2-0.5,1.6L791,319
	l-0.3,0.1l-0.7,0.3h-0.4c-0.4-0.1-0.7-0.3-1-0.7l-0.1-0.1c-0.3-0.4-0.4-1-0.4-1.5v-0.3l0.1-0.7l0.4-0.7l0.1-0.1l0.4-0.4l0.3-0.1
	l0.5-0.1C790.2,314.7,790.4,314.7,790.7,315L790.7,315z"
          />
          <path
            fill="#EBEBEB"
            d="M773.9,317.7c0.3,0.4,0.8,0.7,1.2,0.7c0.6,0,1-0.3,1.2-0.7s0.5-1,0.5-1.6s-0.1-1.2-0.5-1.6
	c-0.3-0.4-0.8-0.7-1.2-0.7c-0.5,0-1,0.3-1.2,0.7c-0.3,0.4-0.5,1-0.5,1.6C773.5,316.8,773.7,317.3,773.9,317.7L773.9,317.7z
	 M789.9,314.7l-0.5,0.1l-0.3,0.1l-0.4,0.4l-0.1,0.1l-0.4,0.7l-0.1,0.7v0.3c0,0.5,0.1,1.1,0.4,1.5l0.1,0.1c0.3,0.4,0.7,0.5,1,0.7h0.3
	l0.7-0.3l0.3-0.1l0.3-0.3c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.1-1.2-0.5-1.6l-0.3-0.3l-0.3-0.1C790.4,314.8,790.2,314.7,789.9,314.7
	L789.9,314.7z"
          />
          <path
            fill="#FFFFFF"
            d="M726.3,319.5c5.1,5.6,8.4,13.1,10,22.3l0.8,4.1c2.1,9.9,3.6,20.2,4.7,31.2c0.5,5.1,1,10.3,1.2,15.7v2.8h19.5
	l16.8-2.8l14.6-1c6.9-8.9,12.4-19,16.6-30l0.4-1.5l-62.3-1.8c-1.1-6.5-2.3-12.7-3.7-18.7c-0.8-4.3-1.9-8.3-3.4-11.8
	c-1.1-2.9-2.5-5.5-4.1-8.1L726.3,319.5L726.3,319.5z M780.4,381.1v-0.7l0.3-1.4c1.1-4,3.2-7.7,6.3-11.3l0.6-0.7l0.5-0.4
	c1.8-1.5,3.9-2.2,6.2-2.2h6.6c1.5-0.3,2.2,0.5,2.1,2.3c-1.8,4.7-4.8,10.2-9.2,16.5c-1.4,1.6-3,2.3-5,1.9l-4.5-0.7
	c-2.1-0.5-3.2-1.5-3.6-2.8L780.4,381.1z"
          />
          <path
            fill="#FFFFFF"
            d="M780.4,381.1l0.1,0.7l0.8,0.1c1,0,2.1-0.8,3.4-2.3c1.2-1.5,2.3-3.3,3.2-5.5c0.8-2.2,1.1-4,1-5.5
	c-0.1-1-0.3-1.5-0.7-1.9l-0.6,0.4l-0.5,0.7c0.1,0.3,0.3,0.8,0.3,1.4c0,1.4-0.3,3-1,5.1c-0.8,1.9-1.7,3.6-2.8,5
	c-1.1,1.4-2.1,2.1-2.8,2.1L780.4,381.1L780.4,381.1z"
          />
          <path
            fill="#EBEBEB"
            d="M780.7,381.1c0.8,0,1.7-0.7,2.8-2.1c1.1-1.4,1.9-3,2.8-5c0.7-1.9,1.1-3.6,1-5.1c0-0.6-0.1-1-0.3-1.4
	c-3.2,3.6-5.2,7.4-6.3,11.3l-0.3,1.4v0.7L780.7,381.1L780.7,381.1z"
          />
          <path
            fill="#FFFFFF"
            d="M780.5,381.7c0.4,1.4,1.5,2.2,3.6,2.8l4.5,0.7c2.1,0.4,3.7-0.3,5-1.9c4.4-6.3,7.6-11.8,9.2-16.5
	c0.1-1.8-0.5-2.6-2.1-2.3h-6.6c-2.2,0-4.3,0.7-6.2,2.2c0.4,0.4,0.5,1,0.7,1.9c0.1,1.5-0.1,3.3-1,5.5c-0.8,2.2-1.8,4-3.2,5.5
	c-1.2,1.5-2.5,2.3-3.4,2.3L780.5,381.7L780.5,381.7z M747.7,331.5l42.8,1.5h0.3l3.7,0.1h0.4l8,0.7l7.7,1.2h0.1l-0.1-0.1l-1.9-2.9
	l-0.6-0.7l-0.1-0.3l-0.8-1l-0.3-0.4l-0.3-0.3l-0.1-0.3l-1.5-1.4l-0.1-0.1l-0.4-0.4l-0.3-0.3l-0.8-0.5l-0.1-0.1l-0.3-0.3l-2.5-1.6
	l-3.4-0.6h-0.3l-15.4-1.8h-0.7l-1.8-0.1l-19.8-1.1l-21-0.8c1.5,2.5,2.9,5.2,4.1,8.1C743.4,330.2,745.3,331.3,747.7,331.5
	L747.7,331.5z"
          />
          <path
            fill="#656565"
            d="M717.1,342.4c7.7,8.7,12.4,18.6,14.2,29.7l10.5,5.1c-1.1-11-2.6-21.5-4.7-31.2
	C729.5,343.4,722.9,342.2,717.1,342.4L717.1,342.4z"
          />
          <path
            fill="#FFFFFF"
            d="M794.3,285.5l2.5,10.5l1.8,11.7l1.2,13.5v0.1l0.3,3l2.5,1.6l-0.1-3.6v-2.2c-0.1-1.9-0.3-3.7-0.4-5.6
	l-0.1-1.5c-0.1-2.1-0.4-4-0.7-6.1l-0.3-1.5c-0.3-1.6-0.4-3.3-0.8-5l-0.1-0.5l-0.5-2.8l-2.8-11.6L794.3,285.5L794.3,285.5z
	 M799,286.4h4.3l0.5-0.1h2.3l4.3-0.1l-0.8-2.8H797l-0.3,2.3l2.8,11.6l1,0.1v-0.1l-1.1-5l-0.3-1.2l-1.2-4.7h1"
          />
          <path fill="#656565" d="M799.1,291l0.4-3.3l-0.7-1.4h-1" />
          <path
            fill="#FFFFFF"
            d="M799.8,286.4h-0.3l-0.1,1l-0.4-1h-0.3l0.7,1.4l-0.3,3.3l0.3,1.2l1.4,3l-0.3,2.1v0.1h0.1l0.3-1.8l0.8,1.8h0.3
	l-1-2.2l0.4-3.3c0.5,1.1,1,2.2,1.5,3.3l-0.3,2.3h0.3l0.3-1.9l0.8,1.9h0.3l-1-2.5l0.4-3.3l1.5,3.3l-0.3,2.5h0.3l0.3-2.1l0.8,2.1h0.3
	l-1.1-2.6l0.4-3.3l1.5,3.3l-0.4,2.6h0.3l0.3-2.2l1,2.3h0.3l-1.2-2.8l0.4-3.3l1.1,2.6l-0.3-1.2l-0.8-1.8v-0.7l-0.1-0.7l-0.1,1
	c-0.5-1.1-1-2.2-1.5-3.3l0.1-1.4v-0.1h-0.1l-0.1,1.1l-0.5-1.1h-0.3l0.7,1.5l-0.3,3.3l-1.5-3.3l0.1-1.5h-0.3l-0.1,1l-0.4-1h-0.3
	l0.7,1.4l-0.3,3.3l-1.5-3.3l0.1-1.4H802l-0.1,1l-0.4-1h-0.3l0.7,1.4l-0.3,3.3l-1.5-3.3L799.8,286.4L799.8,286.4z M803.9,288.1
	l1.5,3.3l-0.4,3.3l-1.5-3.3C803.8,290.4,803.8,289.3,803.9,288.1L803.9,288.1z M801.2,291.4l-0.4,3.3l-1.5-3.3l0.3-3.3L801.2,291.4z
	 M801.9,288.1l1.5,3.3l-0.4,3.3l-1.5-3.3L801.9,288.1L801.9,288.1z M806.1,288.1l1.5,3.3l-0.4,3.3l-1.5-3.3L806.1,288.1L806.1,288.1
	z"
          />
          <path
            fill="#656565"
            d="M803.6,291.5l1.5,3.3l0.4-3.3l-1.5-3.3C803.8,289.3,803.8,290.4,803.6,291.5z M799.4,291.5l1.4,3.3l0.4-3.3
	l-1.5-3.3 M803,294.8l0.4-3.3l-1.5-3.3l-0.4,3.3 M807.2,294.8l0.4-3.3l-1.5-3.3l-0.4,3.3 M801.3,291l0.3-3.3l-0.7-1.4h-1.1l-0.1,1.4
	 M799.5,286.4H799l0.5,1 M803.9,287.7l1.7,3.3l0.3-3.3l-0.7-1.5h-1.1 M803.8,287.3v-1.1l-0.5,0.1 M802,286.4l-0.1,1.4l1.5,3.3
	l0.4-3.3l-0.8-1.4 M801.2,286.4l0.4,1l0.1-1 M802.5,297.5l0.3-2.3c-0.4-1.1-1-2.2-1.5-3.3l-0.4,3.3l1,2.2L802.5,297.5L802.5,297.5z"
          />
          <path
            fill="#FFFFFF"
            d="M802,297.5h-0.3l-1-0.1h-0.1l-0.8-0.1l0.5,2.8h1l12.2,0.7l0.8,6.5c0.3,1.4,0.7,2.5,1.2,3.4l0.1,0.3h0.3
	l0.3-0.1c0.4-0.3,0.7-1.1,0.4-2.3l-1.1-0.3l-0.4-5.5l-0.1-1.8l-0.5-2.6l-4.5-0.3h-1.4l-1.2-0.1h-1l-1.2-0.1h-1.1l-1.1-0.1L802,297.5
	L802,297.5L802,297.5z"
          />
          <path
            fill="#656565"
            d="M800.9,295.6l-0.3,1.8l1.1,0.1 M799.4,292.3l1.1,5l0.3-2.1 M805,295.2l-1.5-3.3l-0.4,3.3l1.1,2.5h0.4
	 M803.1,295.6l-0.3,1.9l1.1,0.1 M806.1,297.8l-1-2.2l-0.3,2.1"
          />
          <path fill="#FFFFFF" d="M800.2,300h-0.1l0.1,0.5" />
          <path
            fill="#656565"
            d="M800.2,300.5l0.8,5l0.1,0.4l0.4-2.5l-0.3-1.9l-0.5-1.5h-0.4L800.2,300.5L800.2,300.5z M800.9,300.1l0.1,0.3
	v-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M801,300.4l0.3,1.1l0.3,1.9l0.1,0.7c0.3,1.7,0.5,3.4,0.7,5.2l0.1,0.8h0.3l1.8,0.1h0.4l1.7,0.1h0.4l1.7,0.1
	h0.5l1.5,0.1h1l3.6,0.3l-0.1-0.3c-0.6-0.8-0.8-2.1-1.2-3.4l-0.8-6.5l-12.2-0.7L801,300.4L801,300.4z"
          />
          <path
            fill="#FFFFFF"
            d="M800.6,300.1l0.7,1.4l-0.3-1.1l-0.1-0.3 M801.2,306.9l1.4,3.3l-0.7,2.9l0.1,1.7l1,2.9l-0.5,2.6v0.8l0.7-3
	l0.1,0.1l-0.1-1.1v0.1l-1.2-3.3l0.7-3.2v-0.5l-0.1-0.8l-1.1-3l0.4-2.2l-0.1-0.7l-0.4,2.5l-0.3-0.4l0.3,1.5"
          />
          <path
            fill="#656565"
            d="M801.3,306.3l1.1,2.9c-0.3-1.8-0.4-3.4-0.7-5.2L801.3,306.3L801.3,306.3z M801.2,307c0.3,1.9,0.5,4,0.7,6.1
	l0.7-2.9l-1.2-3.3L801.2,307L801.2,307z M806,286.2h-0.5l0.4,1.1 M806.1,287.7c0.5,1.1,1.1,2.2,1.5,3.3l0.1-1
	c-0.4-1.4-1-2.6-1.5-3.7L806.1,287.7L806.1,287.7z M807.9,291.5l0.7,1.8l-0.7-2.5 M807.4,295.2l1.2,2.8h0.3l0.3-2.3l-0.1-1.1
	l-1.2-2.6 M807.2,295.2l-1.5-3.3l-0.4,3.3l1.1,2.6h0.4 M808.3,297.9l-1-2.3l-0.3,2.2"
          />
          <path fill="#FFFFFF" d="M809.4,296.4l-0.3-0.8l-0.3,2.3h0.3" />
          <path fill="#656565" d="M809.7,297.9l-0.3-1.5l-0.3,1.5" />
          <path
            fill="#FFFFFF"
            d="M814.8,300.9l1.1,0.3l-0.1-1.8l-1.5-1.1 M815.3,308.2l1.1,0.3l-0.5-7.3l-1.1-0.3l0.1,1.7v0.1 M806.7,310.4
	l-0.5,2.9l-1.1-3h-0.4l-0.7,3l-1.2-3.2h-0.3v0.5v-0.1l1.2,3.3L803,317l0.1,1.2l1.1,3.2l-0.7,2.9v1l0.8-3.3l1.1,3.3l-0.8,2.8l0.1,0.1
	l0.7-2.5l1,3.3l-0.1,0.5l0.1,0.3l0.1-0.3l0.1,0.5l0.3,0.4l-0.4-1.4l1-3.3l1,3.3l-0.7,2.3l0.1,0.3l0.7-2.1c0.3,1,0.5,2.1,0.8,3
	l0.4,0.1l1-3.2l1,3.3l-0.1,0.3l0.4,0.1l-0.1-0.4l0.5-1.8v-1l-0.7,2.2l-0.8-3.3l1-3.3l0.7,2.3v-0.8l-0.6-2.1l0.5-2.1v-0.8l-0.7,2.6
	l-1-3.3l0.8-3.3l0.8,2.5v-0.8l-0.7-2.1l0.4-1.9l-0.1-0.8l-0.5,2.5l-1.1-3.3l0.5-3h-0.3l-0.5,2.8l-1-2.8H809l1.2,3.2l-0.8,3.3
	l-1.1-3.3c0.3-1.1,0.4-2.2,0.7-3.3h-0.1l-0.5,2.9l-1.1-2.9L806.7,310.4L806.7,310.4z M808.2,313.9l-0.7,3.3l-1.1-3.3l0.7-3.3
	L808.2,313.9L808.2,313.9z M808.3,314.3l1.1,3.3l-0.8,3.3l-1.1-3.3L808.3,314.3z M806.1,314.3l1.1,3.3l-0.8,3.3l-1.1-3.3
	L806.1,314.3L806.1,314.3z M805.3,317.2l-1.1-3.3l0.7-3.3l1.2,3.3L805.3,317.2z M811.6,317.6l-0.8,3.3l-1.1-3.3l0.8-3.3L811.6,317.6
	z M805.6,324.6l-1-3.3l0.8-3.3c0.4,1.1,0.8,2.2,1.1,3.3L805.6,324.6L805.6,324.6z M806.5,321.7l1.1,3.3l-1,3.3l-1-3.3L806.5,321.7
	L806.5,321.7z M806.7,321.3l0.8-3.3l1.1,3.3l-0.8,3.3L806.7,321.3L806.7,321.3z M809.7,325l-1,3.3l-1-3.3l0.8-3.3L809.7,325
	L809.7,325z M808.7,321.3l0.8-3.3l1.1,3.3l-0.8,3.3L808.7,321.3z M811.9,325l-1,3.3l-1-3.3l0.8-3.3L811.9,325L811.9,325z
	 M808.9,328.7l1-3.3l1,3.3l-1,3.3L808.9,328.7L808.9,328.7z M805.2,317.6l-0.8,3.3l-1.1-3.3l0.7-3.3L805.2,317.6z"
          />
          <path
            fill="#656565"
            d="M806.3,313.7l1.1,3.4l0.8-3.3l-1.2-3.3 M808.6,320.9l0.8-3.3l-1.1-3.3l-0.8,3.3 M806.5,320.9l0.8-3.3
	l-1.2-3.3l-0.7,3.3 M804.7,310.6l-0.7,3.3l1.2,3.3l0.7-3.3 M809.7,317.6l1.1,3.3l0.8-3.3l-1.2-3.3 M806.4,321.3
	c-0.3-1.1-0.7-2.2-1.1-3.3l-0.8,3.3l1,3.3L806.4,321.3L806.4,321.3z M806.7,328.3l1-3.3l-1.1-3.3l-0.8,3.3 M808.6,321.3l-1.2-3.3
	l-0.7,3.3l1,3.3 M807.8,325l1,3.3l1-3.3l-1-3.3 M810.7,321.3l-1.1-3.3l-0.8,3.3l1.1,3.3 M810.8,321.7L810,325l1,3.3l1-3.3
	 M810.8,328.7l-1-3.3l-1,3.3l1,3.3 M803.2,317.6l1.1,3.3l0.8-3.3l-1.2-3.3 M809,310.6c-0.1,1.1-0.4,2.2-0.7,3.3l1.1,3.3l0.8-3.3
	L809,310.6L809,310.6z M808.9,310.6l-1.8-0.1l1.1,3 M805,310.3l1.1,3l0.5-2.9 M810.9,310.7l-1.7-0.1l1.1,2.9"
          />
          <path fill="#FFFFFF" d="M812,312.8l-0.1-1l-0.3-1.1h-0.4" />
          <path
            fill="#656565"
            d="M810.5,313.7l1.1,3.4l0.5-2.5l-0.1-1.9l-0.8-2.1h-0.1 M811.9,311.8l-0.1-1.1h-0.1 M811.9,317.6l0.7,2.1
	l-0.3-4.1 M810.9,321.3l1.1,3.3l0.5-2.6v-1.5l-0.8-2.5 M812.2,325l0.5,1.9v-4 M812,325.4l-1,3.3l1,3.3l0.7-2.2v-1.9"
          />
          <path
            fill="#FFFFFF"
            d="M814.8,333.5l-2.2-0.5l-0.3-0.1l-0.4-0.1l-1.8-0.4l-0.4-0.1l-1-0.3l1.9,2.9l0.1,0.1l5.2,1.6l0.3-1.5
	c0-0.4-0.1-0.8-0.4-1.1l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.3L814.8,333.5L814.8,333.5z"
          />
          <path
            fill="#656565"
            d="M810.9,329.1l-1,3.2l1.8,0.4l0.1-0.3 M812.2,332.4v0.4l0.4,0.1v-2.3 M806.7,329.1l-0.1,0.4l0.3,0.1
	 M805.6,325.4l-0.7,2.5l1.5,1.4l0.1-0.5 M808,331.1l0.7-2.3l-1.1-3.3l-0.8,3.3l0.3,1.4 M808.7,332l1,0.3c-0.3-1-0.5-2.1-0.8-3
	l-0.7,2.1L808.7,332L808.7,332z"
          />
          <path
            fill="#FFFFFF"
            d="M815.9,336.7l-5.2-1.6l1,2.3l4.1,1 M814.5,356.1c-1.4,0-2.5,1.4-3.4,4.3l-0.4,1.5c-4.3,11-9.9,20.9-16.6,30
	c-1.8,2.3-3.6,4.7-5.6,6.9l7.6,1.5l1.9,0.1l3-3.7c0.8-1.4,0.5-2.3-1.1-3c-1.2-0.7-1.2-1.8-0.3-3.2l0.3-0.4
	c6.6-8.9,11-17.2,13.3-24.9c0.7-2.6,1.8-3.9,3.3-3.7h5.2c-0.1-0.5-0.3-1.1-0.7-1.5l-2.6-3.3L814.5,356.1L814.5,356.1z"
          />
          <path
            fill="#656565"
            d="M802.5,310.7c0.3,2.1,0.4,4.1,0.5,6.3l0.7-3.2l-1.2-3.3V310.7z M803.9,313.5l0.7-3.2l-1.9-0.1 M801.9,313.9
	l1.1,3.3V317c-0.1-2.2-0.3-4.3-0.5-6.3L801.9,313.9L801.9,313.9z M802.4,320.2l0.7-2.6l-1-2.9C802.1,316.5,802.3,318.4,802.4,320.2
	L802.4,320.2z M802.4,321v0.5l1,3l0.1-0.5l-0.3-5.9l-0.1-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M803.2,325l-0.3,1.2l0.1,0.1l0.3-1l0.1,0.5v-1.9l-0.1,0.5l-1-3l0.1,0.8"
          />
          <path fill="#656565" d="M803.5,326l-0.1-0.5l-0.3,1l0.8,0.7" />
          <path fill="#FFFFFF" d="M804.2,327.4l-0.7-2.3v1l0.4,1.1" />
          <path
            fill="#656565"
            d="M803.2,325l-0.7-2.6v3.6l0.4,0.3 M804.2,327.4l0.5,0.4l0.7-2.8l-1.1-3.3l-0.8,3.3 M803.2,318.1l0.3,5.9
	l0.7-2.8"
          />
          <path
            fill="#FFFFFF"
            d="M793.9,391.9l-14.6,1l-16.8,2.8c9.4,0.7,18,1.8,25.9,3.2C790.3,396.5,792.2,394.2,793.9,391.9z M799.5,390.5
	c-1,1.4-1,2.5,0.3,3.2c1.7,0.7,1.9,1.6,1.1,3l-3,3.7c1-0.1,1.9-0.7,2.6-1.5c7.4-7.2,13.6-17.2,18.7-30h1.2l1.1-4.5
	c0.1-1.1,0.3-2.1,0.1-2.8h-5.2c-1.5-0.1-2.6,1.1-3.3,3.7c-2.3,7.7-6.7,16-13.3,24.9L799.5,390.5L799.5,390.5z M802.7,388.3
	c5.5-7.7,9.1-14.7,11-21h2.6c1.1,0.1,1.1,1.4,0,3.9c-2.8,6.7-5.8,12.2-8.9,16.5c-0.8,1.1-1.7,1.5-2.8,1L802.7,388.3L802.7,388.3z"
          />
          <path
            fill="#656565"
            d="M804.6,388.7c1.1,0.4,1.9,0.1,2.8-1c3.2-4.3,6.2-9.8,8.9-16.5c1.1-2.5,1.1-3.9,0-3.9h-2.6
	c-1.9,6.3-5.6,13.3-11,21L804.6,388.7z"
          />
          <path
            fill="#FFFFFF"
            d="M799,407.1c0.1-2.6-1-4.8-3-6.9l-7.6-1.5c-7.8-1.4-16.4-2.5-25.9-3.2H743v16.8l56-0.5L799,407.1L799,407.1z
	 M247.1,304.8c16.2,4.8,26,16.1,29.2,33.6v0.1c0.7,2.8,1.2,5.6,1.9,8.5c1.9,9.4,3.4,19.5,4.5,30.1c0.3,2.1,0.4,4.1,0.7,6.2h8.4
	c-1.5-16.9-4-32.5-7.4-46.8c-4-21-15.8-34.4-35.8-39.9l-1.5-0.3L247.1,304.8L247.1,304.8z"
          />
          <path
            fill="#656565"
            d="M253.5,339.2c8.8,8.8,14.3,19,16.6,30.4l12.5,7.7c-1.1-10.7-2.8-20.8-4.5-30.1l-25.2-8.4L253.5,339.2
	L253.5,339.2z M271.4,383.5h12c-0.1-2.1-0.4-4.1-0.7-6.2l-12.5-7.7c0.8,4.1,1.2,8.5,1.2,13.1L271.4,383.5L271.4,383.5z"
          />
          <path
            fill="#656565"
            d="M284.1,390.6l-0.7-7.3h-12c0,2.5-0.3,4.8-0.5,7.3H284.1L284.1,390.6z"
          />
          <path
            fill="#FFFFFF"
            d="M676.1,249.6c34.3,3.4,64.7,7.8,91.3,13.2l5.8,1.2h0.1l2.8,0.7l6.9,1.1l7,0.5l6.3-0.1h-0.1l-0.5-0.3
	l-8.9-2.8l-8.8-2.2l-6.6-1.4c-4.5-1-9.6-1.8-15.1-2.8c-12.1-1.9-26.3-4-42.5-5.8c-22.4-2.5-47-4.5-73.7-5.8c-1.9,0.1-6.6,1-14.4,2.8
	l-12.4,2.9c27.9,1.6,53.9,3.7,78,6.5c31.5,4,56,8.4,73.7,13.1c2.6,0.8,5,2.5,6.7,4.7l1,1.1c0.7,1,1.2,1.9,1.8,3l1.7,4h0.3l18,1.9
	l2.3,0.3l-3-3.9l-2.5-2.5l-3.6-2.6l-0.4-0.3l-4-1.9l-1.2-0.4l-11.6-3.3l-0.3-0.1c-29.9-8.3-63.7-14.3-101.7-18.4
	c-11.4-1.5-23.1-2.3-35.1-2.8c5.8-2.1,10-3,12.7-2.8L676.1,249.6L676.1,249.6z"
          />
          <path
            fill="#FFFFFF"
            d="M646.3,246.9c-2.8-0.3-7,0.7-12.7,2.8c11.8,0.4,23.5,1.4,35.1,2.8c38,4,71.8,10.2,101.7,18.4l0.3,0.1
	l11.6,3.3l1.2,0.4l4,1.9l0.4,0.3l3.6,2.6l2.5,2.5l3,3.9l0.3-2.3h12.5c-0.3-0.7-0.5-1.4-1-1.9l-2.2-4.1c-0.6-1-1.2-1.9-1.8-2.9
	l-2.3-3.2l-2.6-2.9v-0.1l-1.7-1.2c-0.4-0.3-0.8-0.4-1.4-0.7l-6.3,0.1l-7-0.5l-6.9-1.1l-2.8-0.7h-0.1l-5.8-1.2
	c-26.7-5.2-57.1-9.6-91.3-13.2L646.3,246.9L646.3,246.9z M741.6,328c1.5,3.7,2.6,7.6,3.4,11.8c1.4,6.1,2.6,12.2,3.7,18.7l62.3,1.8
	c1-2.9,2.2-4.3,3.4-4.3l4,0.5l2.3,0.3v-12.8l-5.6-2.2l-1.1-0.5l-0.7-0.5l-0.1-0.1l-0.4-0.7l-1.1-2.6l-1-2.5h-0.1l-7.7-1.2l-8-0.7
	h-0.4l-3.7-0.1h-0.3l-42.8-1.5C745.3,331.3,743.4,330.2,741.6,328L741.6,328z"
          />
          <path
            fill="none"
            d="M804.7,327.8l0.7-2.8l-1.1-3.3l-0.8,3.3l0.7,2.3 M803,326.3l0.3-1.2l-0.7-2.6 M803.1,326.4l0.3-1l0.1,0.5
	l0.4,1.1 M805.2,317.6l-1.2-3.3l-0.7,3.3l1.1,3.3 M802.4,320.2l0.5-2.6l-1-2.9 M802.4,321.6l1,3l0.1-0.5l0.7-2.8l-1-3.2l-0.1-0.1
	l-0.7,3 M802.7,310.2l1.2,3.3l0.7-3.2 M802.5,310.7v-0.1l1.2,3.3l-0.7,3.2v0.1l-1.2-3.3 M809.8,332l-1-3.3l1-3.3l1,3.3 M809.6,332.2
	c-0.3-1-0.5-2.1-0.8-3l-0.7,2.1 M806.8,329.7l-0.1-0.5l-0.1,0.4 M807.9,331.1l0.8-2.3l-1.1-3.3l-0.8,3.3l0.3,1.4 M806.4,329.3
	l0.1-0.5l-1-3.3l-0.7,2.5 M812.7,329.8L812,332l-1-3.3l1-3.3l0.7,2.5 M812.2,332.9v-0.4l0.4-1.8 M811.8,332.7l0.1-0.3l-1-3.3l-1,3.2
	 M810.8,321.7l1.1,3.3l-1,3.3l-1-3.3 M812.7,326.9l-0.5-1.9l0.5-2.1 M809.8,324.6l-1.1-3.3l0.8-3.3l1.1,3.3 M808.7,321.7l1,3.3
	l-1,3.3l-1-3.3 M807.6,324.6l-1-3.3l0.7-3.3l1.2,3.3 M805.7,325l0.8-3.3l1.1,3.3l-1,3.3 M806.4,321.3l-0.8,3.3l-1-3.3l0.8-3.3
	C805.7,319.1,806.1,320.2,806.4,321.3L806.4,321.3z M810.4,314.3l1.2,3.3l-0.8,3.3l-1.1-3.3 M806,313.9l-0.7,3.3l-1.2-3.3l0.7-3.3
	 M805.4,317.6l0.7-3.3l1.2,3.3l-0.8,3.3 M807.5,317.6l0.8-3.3l1.1,3.3l-0.8,3.3 M812.6,322l-0.5,2.6l-1.1-3.3l0.8-3.3l0.8,2.5
	 M812.6,319.6l-0.7-2.1l0.4-2.1 M811.1,310.7l-0.5,3l1.1,3.4l0.5-2.5 M811.2,310.7l0.8,2.1 M811.9,311.8l-0.3-1.1 M809.3,310.6
	l1.1,2.9l0.5-2.8 M806.7,310.4l-0.5,2.9l-1.1-3 M807,310.6l1.2,3.3l-0.8,3.3l-1.1-3.4 M807.1,310.4l1.1,3l0.7-2.9 M809,310.6
	c-0.1,1.1-0.4,2.2-0.7,3.3l1.1,3.3l0.8-3.3L809,310.6 M808.9,297.9l0.3-2.3 M809.4,296.4l-0.3,1.5 M808.3,297.9l-1-2.3l-0.3,2.2
	 M806.8,297.8l0.4-2.6l-1.5-3.3l-0.4,3.3l1.1,2.6 M806.1,288.1l-0.4,3.3l1.5,3.3l0.4-3.3 M809,294.5l-1.2-2.6l-0.4,3.3l1.2,2.8
	 M807.9,290.8v0.7l0.7,1.8 M806.1,286.4l-0.1,1.4c0.5,1.1,1.1,2.2,1.5,3.3l0.1-1 M805.4,286.2l0.4,1.1l0.1-1.1 M801.3,301.5
	l-0.7-1.4 M801.9,313l0.7-2.9l-1.4-3.3v0.1 M802.4,309.3l-1.1-3l0.4-2.2 M801.6,303.4l-0.4,2.5l-0.3-0.4 M801,300.4l-0.1-0.3
	 M800.2,300v0.5 M806.1,297.8l-1-2.2l-0.3,2.1 M804.6,297.6l0.4-2.5l-1.5-3.3l-0.4,3.3l1.1,2.5 M803.9,297.6l-0.8-2.1l-0.3,1.9
	 M802,297.5l-1-2.2l0.4-3.3c0.5,1.1,1,2.2,1.5,3.3l-0.3,2.3 M801.7,297.5l-0.8-1.9l-0.3,1.8 M800.5,297.2l0.3-2.1l-1.4-2.9
	 M801.4,291.5l0.4-3.3l1.5,3.3l-0.4,3.3 M799.7,288.1l1.5,3.3l-0.4,3.3l-1.4-3.3 M803.9,288.1c-0.1,1.1-0.3,2.2-0.3,3.3l1.5,3.3
	l0.4-3.3L803.9,288.1L803.9,288.1z M801.2,286.4l0.4,1l0.1-1 M802,286.4l-0.1,1.4l1.5,3.3l0.4-3.3l-0.8-1.4 M803.2,286.4l0.5,1v-1.1
	 M804.1,286.2l-0.1,1.5l1.7,3.3l0.3-3.3l-0.7-1.5 M799.1,291l0.4-3.3l-0.7-1.4 M799,286.4l0.5,1v-1 M799.8,286.4l-0.1,1.4l1.7,3.3
	l0.3-3.3l-0.7-1.4 M59.7,306.7v11.6 M60.7,284.4c-0.7,0.7-1.5,1.1-2.6,1.2 M60.7,284.4c1.2-1.4,1.7-3.9,1.1-7.6
	c0.4-2.1-1-3.6-3.8-4.4 M53.7,307.3l6.1-0.5 M53.9,272.7c-0.3,4.1-0.4,8.3-0.4,12.7 M61.1,270.7c-0.8,0.8-1.9,1.4-3.3,1.8 M57,285.7
	l-3.6-0.3 M53.7,307.3l3-0.7c1.4-0.3,2.5-1.1,3.3-2.3 M57,285.7h1.1 M53.4,285.4c-0.1,6.9,0,14.2,0.3,21.9 M59.7,306.6v0.1
	 M59.7,306.7l13.6-1.1"
          />
          <path
            fill="none"
            d="M58.1,285.7h15.8l-13.2-1.2 M59.8,304.2c1.2-1.9,1.8-5,1.8-9.1c0.3-5.1-1.2-8.3-4.7-9.5 M73.3,305.6l-13.5-1.4
	 M57.8,272.5h15.5l-12.2-1.8 M56.4,251.4c5,0.1,7.3,2.6,7.2,7.2c0.7,5.8-0.1,9.9-2.5,12.1 M57.8,272.5l-3.9,0.3 M526.2,181.3
	l-6.7,3.7c-2.1,1.4-0.8,3.2,3.6,5.2c23.9,16.8,46.6,33.6,68,50.2c1.9,1.6,4.4,2.5,7.4,2.3l19.1-1 M755,284.4
	c-2.1,0.3-2.6,1.9-1.8,5.1l6.5,29.2l6.1,0.5l-5.9-34.3L755,284.4L755,284.4z M772.1,289.4v-2.1l-10-1l4,23.1l18-0.7v-18.7"
          />
          <path
            fill="none"
            d="M56.4,251.4h18.8c1.9,0.1,2.9,1.8,2.8,4.7l-1.1,47.7c0.3,1.1-1,1.7-3.6,1.7"
          />
          <path
            fill="none"
            d="M53.9,272.7c0.4-7.7,1.2-14.9,2.3-21.3h0.1 M766.2,311.3l0.7,8.3l15.8,1.4l10.7,0.8l1.8,0.1
	c-0.4-0.8-0.7-1.9-0.7-3.4v-3.6c0-1.1-0.3-1.8-1.2-2.1h-0.3L766.2,311.3z"
          />
          <path
            fill="none"
            d="M773.5,316.1c0,0.7,0.1,1.2,0.5,1.6c0.3,0.4,0.8,0.7,1.2,0.7c0.5,0,1-0.3,1.2-0.7s0.5-1,0.5-1.6
	s-0.1-1.2-0.5-1.6c-0.3-0.4-0.8-0.7-1.2-0.7c-0.5,0-1,0.3-1.2,0.7C773.7,314.8,773.5,315.4,773.5,316.1z M790.7,315
	c-0.3-0.1-0.5-0.3-0.8-0.3l-0.5,0.1l-0.3,0.1l-0.4,0.4l-0.1,0.1l-0.4,0.7l-0.1,0.7v0.3c0,0.5,0.1,1.1,0.4,1.5l0.1,0.1
	c0.3,0.4,0.7,0.5,1,0.7h0.3l0.7-0.3l0.3-0.1l0.3-0.3c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.1-1.2-0.5-1.6l-0.3-0.3L790.7,315L790.7,315z
	 M124.8,386.2L56.4,377 M163.3,342.6c-5.4,6.2-9.4,13.1-11.8,20.5 M140.5,342.6c-1.2,6.3-2.3,13.2-3.2,20.5 M23,360l0.3-7.2
	 M127.1,364.5l-68.2-4c-9.4-1.5-20.5-1.6-33.4-0.3 M38.8,376.6l-6.9,0.6 M38.8,376.6l-13.3-16.4 M56.4,377v12.1
	c-0.1,6.5,4,9.6,12.2,9.4h82 M41.5,376.5l-2.8,0.1 M41.5,376.5l14.9,0.5 M23,360l3.4,6.6 M25.5,360.2L23,360 M31.9,377.2l1.4,2.8
	h7.2l1.1-3.4 M26.4,366.6l5.5,10.6 M137.3,363.1h14.3 M127.1,364.5c-1,6.9-1.8,14-2.5,21.7 M137,365c-0.8,7-1.5,14.6-1.9,22.6
	l-10.3-1.4 M150.5,398.6c0.7,2.5,1.4,4.8,2.3,7.2l1.1,2.5c2.9,6.3,7.2,12.4,12.5,17.7c12,12,26.4,18,43.5,18
	c16.9,0,31.4-6.1,43.5-18c10-10,16-21.9,17.5-35.4"
          />
          <path
            fill="none"
            d="M151.6,363.1c-2.1,6.1-3,12.5-3,19.5c0,5.6,0.7,11,1.9,16 M137,365l-9.9-0.5 M137.3,363.1L137,365 M138.2,318.3
	c3.7-7.7,8.7-13.2,15-16.8c8.1-6.1,25-8.7,50.8-7.6h0.3c17.1-0.5,31.4,0.1,42.8,2.3 M81.3,318.3h56.9 M163.3,342.6h-22.8
	 M148.2,318.3h-9.9"
          />
          <path
            fill="none"
            d="M247.1,304.8l-0.7-0.1c-9.4-1.8-20.5-2.8-33.7-2.6h-21.6c-16.2,0.1-27.2,2.3-33,6.5l-0.4,0.3
	c-3.7,2.2-6.9,5.4-9.5,9.6 M148.2,318.3c-1.4,2.3-2.6,5-3.7,7.8c-1.5,5.1-2.9,10.6-4.1,16.5 M138.2,318.3c-0.7,1.5-1.5,3.2-2.1,5
	v0.1c-3.9,11.4-6.7,25.2-8.9,41.1 M115,285.9c-3.9,0-7.2,1.4-9.9,4.1c-2.8,2.8-4.1,6.1-4.1,10c0,3.9,1.4,7.2,4.1,9.9
	s6.1,4.1,9.9,4.1s7.3-1.4,10-4.1s4.1-6.1,4.1-9.9c0-3.9-1.4-7.2-4.1-10C122.2,287.3,118.9,285.9,115,285.9L115,285.9z M35.6,279.3
	l3.7,0.1 M39.2,264.2l-5.2,4.4 M39.5,309.2c0.1-10.6,0.7-20.5,1.5-29.7 M23.2,352.8h-1.4v13.8h4.5 M39.6,339.9H21.9v9.9l1.5,1.4
	l-0.1,1.6 M39.5,309.2h-8.9v4.4c3.2-0.3,5.2,0.4,6.3,2.2l2.6,0.6 M81.3,318.3H52.5"
          />
          <path
            fill="none"
            d="M39.6,339.9v4.4H22.3 M39.6,334.1v5.8 M39.5,316.4v-7.2 M39.6,334.1l-2.5-16.4h2.3v-1.4"
          />
          <path
            fill="none"
            d="M52.5,318.3l-12.9,8.5v7.3 M39.3,279.5H41 M39.2,264.2c1.7,0.1,2.2,1.8,1.7,5l-1.5,10.3 M40.7,262.8l-1.5,1.4
	 M40.7,262.8c0.3-1.6,0.5-3.4,1-5.2"
          />
          <path
            fill="none"
            d="M41,279.5c0.4-3.9,0.8-7.6,1.4-11c0.3-3.3-0.3-5.1-1.7-5.6 M34,268.6l2.9,1.4l-1.2,9.4 M34,268.6l-2.2,10.6
	l3.8,0.1 M55,248.4c-3.7,20.1-4.5,43.3-2.5,69.9 M41.8,257.6c0.7-2.8,1.5-5.4,2.6-8.1 M81.3,318.3v-69.9 M415,160.1l11.6,0.1
	l15.7,0.6l14.9,1.4c6.2,0.7,12.2,1.5,17.9,2.6l1.4,0.3"
          />
          <path
            fill="none"
            d="M415,161.7l11.6,0.1l15.7,0.6l14,1.2l9.2,1.1c5,0.6,8.5,0.7,10.9,0.1 M488.5,167.4l-0.4,0.8c4,1,8,2.1,11.7,3.2
	l0.1-1 M499.9,170.4l0.3-2.1l-1.2-0.3"
          />
          <path
            fill="none"
            d="M494.1,166.8l-4.8-1.1l-0.8,1.6 M498.9,168.1l-2.3-6.6l-2.6-0.7l0.1,6.1 M498.9,168.1l-4.8-1.2 M535.7,222.3
	c-15.4-13.1-29.6-23.1-42.8-30.3c-14.4-8.1-35.1-12.8-62.2-13.9l-14.3-0.3c-2.5,0.6-3.7,3-3.9,7.6l-1.4,66.3l118.2,2.9 M476.5,164.9
	c3.4,0.7,7.6,1.5,12,2.5"
          />
          <path
            fill="none"
            d="M410.1,253.8c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.6-0.3-0.8l1.4-67.4c0.3-6.1,2.2-9.2,5.9-9.6h0.3l14.3,0.3
	c27.5,1.2,48.6,5.9,63.1,14.2c12.9,6.9,26.8,16.6,41.7,29.2 M356.6,156.6v-2.3c0-0.4-0.1-0.6-0.5-0.6H350c-0.4,0-0.5,0.1-0.5,0.6
	v2.3 M356.6,156.6h-7.3 M415,160.1c-2.9-1.9-6.5-2.9-11-3.2h-3.7c-13.1-0.1-27.6-0.1-43.9-0.1 M130.7,165.4
	c34.1-1.5,67.8-2.5,101.1-2.9h6.7c13.9,0,27.4,0,40.6-0.1h8.5c50.2-0.3,90.7-0.3,121.5,0.1l5.8,0.1v-0.8 M415,161.7v-1.6
	 M271.3,217.3c1.9-7.6,5-16,9.1-25c4.4-11.7,16-17.5,34.7-17.6l66.6,0.1c3.2,0.3,4.8,2.5,5.2,6.7l-3.6,70.3c0,0.3-0.1,0.6-0.4,0.8
	c-0.1,0.1-0.4,0.3-0.7,0.3"
          />
          <path
            fill="none"
            d="M384.6,181.7c-0.3-2.8-1.4-4.4-3.3-4.7l-66.3-0.1c-17.6,0-28.5,5.4-32.6,16.1c-7.6,16.5-11.3,30.4-11.3,41.8
	v0.4c-0.1,0.8-0.1,1.6,0,2.3c1.2,6.9,10.2,10.3,26.8,10.3l83.1,2.5L384.6,181.7L384.6,181.7z M382.2,252.9l15.8,0.4 M410.1,253.8
	l-12.1-0.4 M398,253.3c0.1-32.5,1-62.2,2.6-89.3"
          />
          <path
            fill="none"
            d="M400.7,164.1c-29.6-0.3-67.3-0.3-112.9,0h-8.3 M349.3,156.6c-18.3,0-38.4,0-60.4,0.1h-8.5
	c-24.8,0.1-51.7,0.4-80.9,0.8l-5,0.1 M269.4,227.3l1.9-10 M269.4,227.3c0.4-3.2,1-6.6,1.9-10 M382.2,252.9l-84.2-2.5
	c-18.2-0.1-27.8-4.1-28.9-12.2c-0.1-1-0.1-1.9-0.1-2.9V235c0-2.5,0.1-5,0.4-7.6"
          />
          <path
            fill="none"
            d="M398,259.8l-90.4-2.3c-5.2,0.1-10.6-0.3-15.8-1l-3.7-0.7c-7.8-1.4-13.5-3.3-16.6-5.5c-3-2.2-4.3-6.6-3.7-13.3
	l1.8-9.5 M186.5,157.9h-5.4 M194.5,157.7v-2.3c0-0.4-0.1-0.6-0.6-0.6h-6.9c-0.4,0-0.6,0.1-0.6,0.6v2.3 M181.2,157.9
	c-2.5,0.7-5.1,2.5-7.8,5.5 M186.5,157.9l8-0.1 M81.3,248.4h119c10.2,0,16.8-2.3,19.5-6.9l31.5-57.6c4.1-5.8,0.6-8.4-10.5-8l-131.4,3
	 M279.5,164.1c-15.4,29.3-25.7,57.9-31,86c-1.2,12.5-1.8,27.9-1.5,46.2 M796.5,266.3l-6.3,0.1l-7-0.5l-6.9-1.1l-2.8-0.7h-0.1
	l-5.8-1.2c-26.7-5.2-57.1-9.6-91.3-13.2l-29.9-2.8c-2.8-0.3-7,0.7-12.7,2.8c11.8,0.4,23.5,1.4,35.1,2.8c38,4,71.8,10.2,101.7,18.4
	l0.3,0.1l11.6,3.3l1.2,0.4l4,1.9l0.4,0.3l3.6,2.6l2.5,2.5l3,3.9 M625.8,248.1l-8.4-6.3c-30.8-23.2-61.2-43.3-91.2-60.5l-5.6-3.3
	 M613.4,251.1h-0.5c-9.6-0.1-17.6-2.6-23.9-7.6l-8-6.9 M613.4,251.1l12.4-3"
          />
          <path
            fill="none"
            d="M796.5,266.3l-0.5-0.3l-8.9-2.8l-8.8-2.2l-6.6-1.4c-4.5-1-9.6-1.8-15.1-2.8c-12.1-1.9-26.3-4-42.5-5.8
	c-22.4-2.5-47-4.5-73.7-5.8c-1.9,0.1-6.6,1-14.4,2.8 M520.5,178.1l-12.7,4.4 M580.9,236.6c-2.1,0.7-2.8,2.9-1.9,6.6
	c5.9,23.9,8,50.8,5.8,80.6c-1.8,23-4.5,42.9-8.3,59.6 M536.8,262.7c14.7,0.3,23-3,24.8-9.6c1.1-5.8-0.3-10.9-4-15.3L539,222.1
	l-0.1-0.1l-3.2-2.8"
          />
          <path
            fill="none"
            d="M542.5,256.2c2.1-0.1,3.9-0.3,5.2-0.6c4.8-2.6,7.4-6.3,7.8-10.9c0.1-1.6-0.3-2.5-1.2-2.3"
          />
          <path
            fill="none"
            d="M554.4,242.3c-0.1,2.8-1,5.2-2.5,7.2h-3c-0.3,2.5-2.3,4.4-6.3,6.1l-1.8,0.7 M535.7,219.2v3 M536,230.9
	c-3.7,0.6-6.2,2.1-7.4,4.5c-1.1,2.2-1.8,5-2.2,8.4"
          />
          <path
            fill="none"
            d="M548.9,242.3l-1.9-8.7c-1-2.3-3-3.3-5.9-3l-5.1,0.3 M526.5,243.9c3.6,0,6.9,0,9.9-0.1c5.1-0.3,9.2-0.7,12.7-1.2
	 M526.5,243.9c-0.1,1.8-0.3,3.7-0.3,5.8c0.3,2.2,1.4,3.9,3.2,5 M535.7,222.3l0.3,8.7 M580.9,236.6c-27.1-23.1-51.4-41.1-72.9-54.1
	 M554.4,242.3h-5.5 M529.3,254.4c1.4,0.8,3.2,1.2,5.4,1.4l1.9,0.1 M536.6,256.1v0.8 M536.8,262.7l-0.1-5.8 M536.6,256.9l4.8,0.1
	c0.3,0,0.5-0.1,0.8-0.3l0.3-0.4 M542.5,256.2h-1.8 M540.8,256.2l-4.1-0.1 M575.7,329.6c0.5-2.2-0.5-3.3-3.6-3.3H399.9v16.1h169.2
	c2.9,0.1,4.5-0.7,5-2.5L575.7,329.6L575.7,329.6z M586,383.5c2.3-22.8,5.9-41.8,10.9-56.8v-0.1c3.9-10.2,9.5-17.5,17.1-21.7
	c8.3-6.1,25-8.7,50.8-7.6h0.3c17.7-0.5,32.3,0.3,44,2.6l0.3,0.1c12.7,3.6,22.1,10.2,28.3,20.1"
          />
          <path
            fill="none"
            d="M726.3,319.5c-4.8-5.4-11.3-9.2-19.4-11.6c-9.2-1.8-20.5-2.8-33.7-2.6h-21.6c-16.1,0.1-27.1,2.3-33,6.5
	l-0.4,0.3c-5.9,3.4-10.3,9.2-13.2,17.5c-4.5,14.3-7.6,32.3-9.5,53.9"
          />
          <path
            fill="none"
            d="M675.2,340.7c-1,0.3-1.2,1-1.1,2.3l0.7,25.2 M667.5,368.2l0.7-25.2c0.3-1.5-0.3-2.3-1.4-2.5"
          />
          <path
            fill="none"
            d="M666.6,340.7l4.1-0.1l4.4,0.1 M675.2,340.7h0.1l0.5,0.1 M675.8,340.8l-0.7-0.1 M663.9,353.1l0.3-4.1
	c0.3-1.4-0.4-2.1-1.9-2.1c-1.2,0.3-2.5,0.5-3.7,1 M663.9,353.1l-5.4-5.2 M663.6,359.8l0.3-6.7"
          />
          <path
            fill="none"
            d="M629.5,373.9c1.7-8,5.5-14.9,11.6-21c7.2-7.2,15.5-11.1,25.3-12.1h0.4"
          />
          <path
            fill="none"
            d="M654,349.8c-3.3,1.6-6.3,4-9.1,6.7c-1.9,1.9-3.6,4-5.1,6.2l-0.1,0.3c-0.4,1.2,0.1,2.1,1.4,2.2l8.7,2.3
	 M658.5,347.9c-1.5,0.6-3.2,1.2-4.5,2.1"
          />
          <path
            fill="none"
            d="M717.1,342.4l-2.9-3.2c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.1-11s-24.9,3.7-35.2,11
	c-2.9,2.1-5.6,4.4-8.3,7c-11.1,11.1-17.2,24.5-17.9,39.9"
          />
          <path
            fill="none"
            d="M670.8,338.4c-12.2,0-22.7,4.3-31.4,12.9s-12.9,19.1-12.9,31.4c0,8.7,2.2,16.4,6.5,23.2l1.7,2.5
	c1.4,1.9,3,3.9,4.8,5.6c8.7,8.7,19.1,13.1,31.4,13.1c12.2,0,22.7-4.4,31.4-13.1s12.9-19.1,12.9-31.4c0-12.2-4.3-22.7-12.9-31.4
	C693.5,342.6,683,338.4,670.8,338.4L670.8,338.4z M654,349.8l9.6,10 M609.4,379.1l-0.1,3.6c0,5.8,0.7,11.4,2.1,16.6"
          />
          <path
            fill="none"
            d="M658.4,374.3l-24.1-7.2c-1.4-0.3-2.1-0.8-1.9-1.8l0.3-0.5 M656.2,381.3l-23.7-8.4c-1.4-0.7-2.2-0.4-2.8,0.7
	l-0.3,0.3 M644.9,381.6l-8.1-3.2c-1.2-0.5-2.1-0.3-2.6,1.1l-0.1,3c0,1.5,0.1,3,0.3,4.4 M642,413.3l-1.1-1
	c-8.3-8.3-12.4-18.2-12.4-29.9c0-3,0.3-5.9,0.8-8.8"
          />
          <path
            fill="none"
            d="M611.5,399.2c0.5,2.2,1.4,4.4,2.2,6.6l1.1,2.5c3,6.3,7.2,12.4,12.5,17.7c12,12,26.4,18,43.5,18
	c16.9,0,31.4-6.1,43.5-18c12-12,18-26.4,18-43.5c0-3.6-0.3-7.2-0.8-10.6 M284.1,390.6v7.2h317.5l1.1,1.4h8.8 M595.2,386.5l-0.3,4.1
	h-9.6 M595.5,383.5l-0.3,3 M609.4,379.1l-14.2,7.4 M663.6,359.8c-3.6,1.1-6.9,3-9.6,5.9l-0.3,0.3 M653.6,366h-3.2l-0.7,1.5
	 M656.5,369.5l-0.8-3.4h-2.1 M644.9,381.6v0.3c-0.4,1.4,0.1,2.3,1.7,2.8v2.3 M651.9,384.3l-7-2.8 M663.3,365.2
	c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.6c-1.1,0.7-1.9,1.5-2.5,2.5 M656.5,369.5h0.3 M667.3,368.6l0.1-0.4 M658.9,374.5l0.7,0.3
	c1.5,0.5,2.9,0.3,4.1-0.4c0.5-0.3,1.1-0.4,1.7-0.8c1.4-1,2.1-2.2,2.1-4v-0.8 M674.8,368.9v0.7c0,1.6,0.5,2.9,1.8,3.9
	c0.5,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3 M674.8,368.2v0.7"
          />
          <path
            fill="none"
            d="M674.8,368.9c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4 M676.3,377.2c-1.5-1.5-3.3-2.2-5.5-2.2c-2.1,0-4,0.8-5.5,2.2
	c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5
	S677.8,378.7,676.3,377.2L676.3,377.2z M658.4,374.3l0.5,0.3 M656.5,381.4l-0.3-0.1 M659.5,391.3l0.4-0.7c1-1.2,1.2-2.6,1-4
	c-0.1-0.7-0.3-1.4-0.4-1.9c-0.4-1.5-1.5-2.5-3-3l-0.8-0.3 M656.5,381.4v1.2c0,3.3,1,6.3,3,8.8 M658.9,374.5c-1.5,2.1-2.3,4.4-2.5,7
	 M656.7,369.5l1.7,4.8 M667.3,368.6c-2.5,0.5-4.8,1.9-6.7,3.9c-0.7,0.7-1.2,1.2-1.7,1.9 M667.5,368.2l-4.1-3 M665.3,395.9l-0.1,0.3
	 M659.5,391.3l-0.3,0.4 M675.7,396.1l-0.4-0.7c-1-1.2-2.2-1.9-3.7-2.1c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1l-0.5,0.8
	 M665.3,395.9c-1.7-0.7-3.2-1.8-4.5-3.2l-1.2-1.4 M675.7,396.1l0.3,0.3"
          />
          <path
            fill="none"
            d="M665.3,395.9c1.7,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8 M674.5,401.4c-1.1,0.4-2.1,0.7-3.3,0.5h-1.1
	c-1.2-0.1-2.3-0.4-3.3-1 M666.6,401l-3.4,4.3 M676,396.4l-1.5,5 M666.6,401l-1.5-4.8 M663.2,405.2c2.3,0.8,5,1.1,7.6,1.1
	c2.5,0,4.8-0.3,7-1 M654,391.7c-0.7-0.8-1.2-1.6-1.5-2.6l-0.1-0.3l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4 M646.4,387.1
	c0.1,2.1,0.4,4,1,5.9 M649.7,398.3l4.3-6.6 M647.4,393c0.5,1.9,1.4,3.7,2.3,5.4 M635.8,394.5c1.4,4.1,3.6,8,6.5,11.4l0.1,0.1
	c1.1,0.8,2.1,0.5,2.6-0.7l4-6.1 M649,399.4l0.7-1.1 M649,399.4c-4.7-0.4-9.1-2.1-13.2-5 M649.7,367.5l6.7,1.9 M654,391.7h5.2
	 M651.9,384.3l4.3-3 M659.2,391.7l-14.3,20.8c-0.7,1.2-1.5,1.6-2.3,1.2c-0.1-0.1-0.3-0.3-0.4-0.3 M665.1,396.1l-15.3,19.9
	c-1.1,1.1-1.1,2.1-0.3,2.8l0.5,0.5 M650.1,419.4c-2.8-1.6-5.5-3.7-8-6.1"
          />
          <path
            fill="none"
            d="M663.2,405.2l-6.7,8.4c-1,1-0.7,2.1,0.5,3.2c4.3,1.6,8.8,2.6,13.8,2.6c4.8,0,9.4-0.8,13.6-2.5
	c1.2-1,1.4-1.8,0.3-2.8l-6.9-8.5 M650.1,419.4c6.2,3.6,13.1,5.2,20.6,5.2c7.4,0,14.2-1.6,20.2-5.1 M634.2,387.1
	c0.3,2.6,0.8,5.1,1.7,7.4 M646.4,387.1h-12.2 M634.2,387.1l13.2,5.9 M586,383.5h-9.5 M585.3,390.6v-0.1l0.7-7 M595.5,383.5H586
	 M663.6,359.8l-0.3,5.4 M776,283.5h-22c-2.1,0.1-2.9,2.1-2.5,5.8l7.2,31.5 M737.6,319.9l-11.3-0.4 M741.6,328
	c1.7,2.2,3.6,3.3,5.9,3.4l42.8,1.5h0.3l3.7,0.1h0.4l8,0.7l7.7,1.2h0.1"
          />
          <path
            fill="none"
            d="M741.6,328c1.5,3.7,2.6,7.6,3.4,11.8c1.4,6.1,2.6,12.2,3.7,18.7l62.3,1.8 M726.3,319.5
	c5.1,5.6,8.4,13.1,10,22.3l0.8,4.1 M758.7,320.8l-21-0.8 M737.6,319.9c1.5,2.5,2.9,5.2,4.1,8.1 M690.2,368.6l10.6-2.9
	c1.4-0.1,1.8-1,1.4-2.5c-1.5-2.3-3.3-4.5-5.4-6.7l-1.2-1.1 M695.5,355.4c-1.4-1.2-2.8-2.5-4.3-3.4 M691.3,352
	c-3.4-2.3-7.3-4.1-11.3-5.1l-0.4-0.1c-1.2,0.1-1.7,0.8-1.5,2.1l0.5,11.1 M737.1,345.9c-7.6-2.3-14.3-3.6-19.9-3.6 M776,283.5l-1.7-4
	c-0.5-1.1-1.1-2.1-1.8-3l-1-1.1c-1.9-2.2-4.1-3.9-6.7-4.7c-17.6-4.8-42.2-9.2-73.7-13.1c-23.9-2.8-49.9-4.8-78-6.5 M809.6,283.3
	c-0.3-0.7-0.5-1.4-1-1.9l-2.2-4.1c-0.5-1-1.2-1.9-1.8-2.9l-2.3-3.2l-2.6-2.9v-0.1L798,267c-0.4-0.3-0.8-0.4-1.4-0.7 M796.8,285.7
	l-2.3-0.1h-0.1 M806.1,286.2h-2.3l-0.5,0.1h-5.4l1.2,4.7l0.3,1.2l1.1,5v0.1"
          />
          <path
            fill="none"
            d="M796.8,285.7l0.3-2.3h12.5 M809.7,297.9h-1.4l-1.2-0.1h-1l-1.2-0.1h-1l-1.1-0.1h-1.1l-1.1-0.1h-0.1
	 M800.5,297.4l-1-0.1 M800.1,300l0.1,0.5l0.8,5l0.3,1.5c0.3,1.9,0.5,4,0.7,6.1l0.1,1.5c0.1,1.8,0.3,3.7,0.4,5.6v2.2l0.1,3.6
	 M801,300.1v0.4l0.3,1.1l0.3,1.9l0.1,0.7c0.3,1.6,0.5,3.4,0.7,5.2l0.1,0.8 M801,300.1h-0.4l-0.4-0.1h-0.1 M799.5,297.2l0.5,2.8
	 M800.1,324.3l-0.1-3v-0.1l-1.4-13.5l-1.8-11.7l-2.5-10.5 M799.5,297.2l-2.8-11.6"
          />
          <path
            fill="none"
            d="M794.3,285.5l-18-2.1H776 M806.1,286.2l4.3-0.1l-0.8-2.8"
          />
          <path
            fill="none"
            d="M809.7,297.9l-0.3-1.6l-0.1-0.8l-0.3-1.1l-0.3-1.2l-0.7-2.6l-0.1-0.7c-0.4-1.4-1-2.6-1.5-3.7v-0.1 M81.4,171.4
	l-1.9,2.5 M81.3,248.4H55 M91.2,178.9C72.6,203,60.5,226.1,55,248.4 M79.5,173.8l9.1,5.6 M44.4,249.5h-5.8l-2.3,4.8l3.4,1.4v1
	c0.7,0.3,1.4,0.7,2.1,1 M44.4,249.5l0.4-1.1 M815.9,301.2l-0.1-1.8l-1.5-1.1 M814.8,300.9l-0.5-2.6 M816.4,308.5l-1.1-0.3l-0.4-5.5
	v-0.1l-0.1-1.7 M814.2,298.3l-4.5-0.4 M801,300.1l12.2,0.7l0.8,6.5c0.3,1.4,0.7,2.5,1.2,3.4l0.1,0.3 M815.9,301.2l-1.1-0.3
	 M811.6,310.7h-0.7l-1.7-0.1h-0.4l-1.8-0.1h-0.4l-1.7-0.1h-0.4l-1.9-0.1h-0.1 M44.8,248.4l0.1-0.3c9.8-24.5,23.4-46.4,41.1-65.6
	 M55,248.4H44.8 M86,182.6l-7.4-4.1l1-4.7 M279.5,164.1h-0.3c-24.8,0.1-51.7,0.4-80.9,0.7c-24.9,0.3-48.4,1.1-71,2.5l-11.7,0.8
	 M109.5,178.9c-13.1,22.1-22.6,45.1-28.2,68.9v0.6"
          />
          <path
            fill="none"
            d="M815.3,311h0.3l0.3-0.1c0.4-0.3,0.7-1.1,0.4-2.3 M812.6,333v-2.3l0.1-0.8V323l-0.1-1v-2.3l-0.3-4.1l-0.1-0.8
	l-0.1-1.9l-0.1-1l-0.1-1.1l3.6,0.3 M181.2,157.9l-41.4,1.5c-5.5,1.4-8.4,3.3-9.1,5.9 M109.5,178.9l6.2-10.9 M115.7,168.1l-16.5,1.4
	 M91.2,178.9c-2.8-2.2-3.6-4.4-2.2-6.6 M99.2,169.4l-8,9.5 M88.6,179.5l2.6-0.6 M88.6,179.5l-2.6,3.2 M81.4,171.4l2.2-0.3
	c15.4-3.4,30-5.2,43.7-5.5l3.3-0.3 M89,172.2l-7.6-0.8 M89,172.2l8.8-2.8h1.4 M815.9,336.7l0.3-1.5c0-0.4-0.1-0.8-0.4-1.1l-0.1-0.1
	c-0.1-0.1-0.4-0.3-0.7-0.3h-0.1l-2.2-0.5 M812.6,333l-0.4-0.1l-0.4-0.1l-1.8-0.4l-0.4-0.1l-0.8-0.1 M808.7,332l-0.5-0.7l-0.3-0.3
	l-0.8-1l-0.3-0.4l-0.3-0.1l-0.1-0.3l-1.5-1.4l-0.1-0.1l-0.5-0.4l-0.3-0.3l-0.8-0.7l-0.1-0.1l-0.4-0.3 M811.6,337.4l4.1,0.8l0.1-1.5"
          />
          <path
            fill="none"
            d="M818.5,356.7l2.3,0.3v-12.7l-5.6-2.2l-1.1-0.6l-0.7-0.5l-0.1-0.1l-0.4-0.7l-1.2-2.8 M810.7,335.1l1,2.3
	 M808.7,332l1.8,2.8l0.1,0.3 M810.7,335.1l5.2,1.6 M818.5,356.7l-4-0.5c-1.4,0-2.5,1.4-3.4,4.3 M800.1,324.3l-3.4-0.4l-0.1-0.1
	l-15.4-1.8h-0.7l-1.8-0.1h-0.1l-19.8-1.1 M802.5,310.2v0.5c0.3,2.1,0.4,4.1,0.5,6.3l0.1,1.2l0.3,5.9v1.9 M802.5,326l-2.5-1.6
	 M816.4,308.5l-0.5-7.3 M780.4,381.1h0.3c0.8,0,1.7-0.7,2.8-2.1c1.1-1.4,1.9-3,2.8-5c0.7-1.9,1.1-3.6,1-5.1c0-0.6-0.1-1-0.3-1.4"
          />
          <path
            fill="none"
            d="M788.1,366.6c0.4,0.4,0.5,1,0.7,1.9c0.1,1.5-0.1,3.3-1,5.5c-0.8,2.2-1.8,4-3.2,5.5c-1.2,1.5-2.5,2.3-3.4,2.3
	l-0.8-0.1 M780.5,381.7l-0.1-0.5 M780.4,381.1v-0.7l0.3-1.4c1.1-4,3.2-7.7,6.3-11.3 M787,367.7l0.6-0.7l0.5-0.4"
          />
          <path
            fill="none"
            d="M780.5,381.7c0.4,1.4,1.5,2.2,3.6,2.8l4.5,0.7c2.1,0.4,3.7-0.3,5-1.9c4.4-6.3,7.6-11.8,9.2-16.5
	c0.1-1.8-0.5-2.6-2.1-2.3h-6.6c-2.2,0-4.3,0.7-6.2,2.2 M793.9,391.9c-1.8,2.3-3.6,4.7-5.6,6.9 M804.6,388.7l-1.9-0.4
	c5.5-7.7,9.1-14.7,11-21h2.6c1.1,0.1,1.1,1.4,0,3.9c-2.8,6.7-5.8,12.2-8.9,16.5C806.7,388.8,805.7,389.1,804.6,388.7L804.6,388.7z"
          />
          <path
            fill="none"
            d="M821.8,361.5h-5.2c-1.5-0.1-2.6,1.1-3.3,3.7c-2.3,7.7-6.7,16-13.3,24.9l-0.3,0.4c-1,1.4-1,2.5,0.3,3.2
	c1.7,0.7,1.9,1.6,1.1,3l-3,3.7 M743,395.6v16.8l56-0.5v-4.7c0.1-2.6-1-4.8-3-6.9 M795.9,400.3l1.9,0.1"
          />
          <path
            fill="none"
            d="M797.9,400.4c1-0.1,1.9-0.7,2.6-1.5c7.4-7.2,13.6-17.2,18.7-30h1.2l1.1-4.5c0.1-1.1,0.3-2.1,0.1-2.8
	 M795.9,400.3l-7.6-1.5 M793.9,391.9l-14.4,1l-16.9,2.8 M811.1,360.4l-0.4,1.5c-4.3,11-9.9,20.9-16.6,30 M821.8,361.5
	c-0.1-0.5-0.3-1.1-0.7-1.5l-2.6-3.3 M684.9,370c-0.6-1.1-1.4-1.9-2.2-2.8l-0.5-0.4c-1-0.8-2.1-1.4-3.3-1.8 M683.6,362.4
	c-1.5-1-3.3-1.9-5-2.5 M678.6,360l0.3,5.2 M690.2,368.6c-0.8-1-1.7-2.1-2.5-2.9c-1.2-1.2-2.6-2.3-4-3.3 M694.6,383.1l-4.8,1.8
	 M685.1,382.1l-0.7,0.3c-1.5,0.4-2.5,1.4-3,2.9l-0.8,1.5c-0.5,1.5-0.4,3,0.7,4.4l0.5,0.8 M683,375.1c1.4,2.1,2.1,4.4,2.1,7"
          />
          <path
            fill="none"
            d="M685.1,382.1v0.5c0,3.6-1.1,6.6-3.4,9.2 M683.4,375l-0.4,0.1 M685.6,381.8l-0.5,0.3 M684.9,370l5.2-1.4
	 M689.7,384.9l-4.1-3 M684.9,370l-1.5,5 M683,375.1c-0.5-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.1-3.7 M709.3,365.3v0.3
	c0.3,1.1-0.3,1.9-1.8,2.1l-24.1,7.2 M699,413.7l1.5-1.4c8.3-8.3,12.4-18.2,12.4-29.9c0-2.8-0.3-5.5-0.7-8.1 M685.6,381.8l23.7-8.4
	c1.2-0.7,2.1-0.5,2.6,0.4l0.1,0.5 M707.1,388.4c0.3-1.9,0.4-3.9,0.4-5.9c0-0.8,0-1.5-0.1-2.3v-0.4c-0.4-1.1-1.2-1.4-2.3-0.8
	l-5.2,2.1 M712.2,374.5c-0.5-3.2-1.5-6.3-2.9-9.1 M703.6,399.3c1.7-3.3,2.9-7,3.4-10.9 M692.9,401.2l3,4.5c0.5,1.2,1.5,1.5,2.8,0.5
	c1.9-2.2,3.6-4.7,5-7.2 M699.4,392.3c2.6-1,5.1-2.2,7.7-3.9 M681.9,392.1h5.1 M681.8,391.9l-0.8,0.8c-1.5,1.5-3.3,2.8-5.2,3.4
	 M681.8,391.9l0.1,0.3 M687,392.1c0.8-1,1.5-1.9,1.9-2.9l0.3-0.7c0.5-1.2,0.7-2.5,0.7-3.7 M691.8,393.9c-0.5,1-1.1,1.9-1.8,2.8
	 M690,396.7l-3-4.5 M681.9,392.1l12.7,18.4 M692.9,401.2l-2.9-4.5 M699.8,381.1l-5.2,1.9 M694.6,383.1c-0.1,4-1,7.6-2.8,10.9
	 M703.6,399.3c-3.9,1.6-7.4,2.3-10.6,2.1 M691.8,393.9c2.5-0.1,5.1-0.7,7.6-1.6 M699.4,392.3l0.4-11.1 M741.7,377.2
	c0.5,5.1,1,10.3,1.2,15.7v2.8 M762.5,395.6H743 M731.3,372.1l10.5,5.1 M691,419.7l0.7-0.7c0.7-0.8,0.5-1.7-0.4-2.6l-1.7-2.2
	 M699,413.7l-0.3,0.1c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2 M691,419.7c2.9-1.5,5.5-3.6,8.1-5.8 M694.6,410.6c-1.5,1.4-3.3,2.6-5,3.6
	 M709.3,365.3c-2.1-4.5-5-8.7-8.8-12.5c-7-7-15.1-11-24.6-12.1 M737.1,345.9c2.1,9.9,3.6,20.2,4.7,31.2 M788.4,398.8
	c-7.8-1.4-16.4-2.5-25.9-3.2 M683.6,362.4l12-7 M678.6,360l12.7-8 M731.3,372.1c-1.8-11.1-6.5-21-14.2-29.7 M677.8,405.5l-3.3-4.1
	 M678.9,365.2l-4.1,3 M689.6,414.3L676,396.4 M247.1,296.4v8.4 M247.1,296.4l1.5,0.3c19.9,5.5,31.8,18.8,35.8,39.9
	c3.3,14.3,5.8,30,7.4,46.8 M237.2,357.9l-2.8-2.8 M234.4,355.1c-4.5-4-9.5-6.7-15.1-8.1l-0.4-0.1c-1.2,0.1-1.6,0.8-1.5,2.1l0.3,6.2
	 M214.1,368.2l-0.7-25.2c-0.1-1.4,0.1-2.1,1.1-2.3 M214.5,340.7l0.7,0.1"
          />
          <path
            fill="none"
            d="M205.9,340.7l4.1-0.1l4.4,0.1 M214.5,340.7h0.1l0.6,0.1 M253,338.6c-2.5-2.5-5.1-4.7-7.8-6.6
	c-10.2-7.3-22-11-35.2-11c-13.2,0-24.9,3.7-35.1,11c-2.9,2.1-5.6,4.4-8.3,7l-3.3,3.4 M215.1,340.8c9.4,1.1,17.6,5.1,24.6,12.1
	c3.9,3.9,6.7,8,8.8,12.5"
          />
          <path
            fill="none"
            d="M241.3,351.3c-8.7-8.7-19.1-12.9-31.4-12.9s-22.7,4.3-31.4,12.9s-12.9,19.1-12.9,31.4c0,5.6,1,11,2.8,15.8
	l0.1,0.1c1,2.5,2.2,5,3.6,7.1l1.6,2.5c1.5,1.9,3,3.9,4.8,5.6c8.7,8.7,19.1,13.1,31.4,13.1s22.7-4.4,31.4-13.1
	c8.7-8.7,12.9-19.1,12.9-31.4C254.4,370.4,250,360,241.3,351.3L241.3,351.3z M234.4,355.1h-16.8"
          />
          <path
            fill="none"
            d="M206.6,368.2l0.7-25.2c0.3-1.5-0.3-2.3-1.4-2.5 M203,355.6l-0.1,4.3 M203,355.6l0.3-6.6
	c0.3-1.4-0.4-2.1-1.9-2.1c-6.6,1.5-12.4,4.7-17.5,9.8l-2.6,2.8c-0.8,1.1-1.7,2.1-2.5,3.3"
          />
          <path
            fill="none"
            d="M168.8,373.9c1.7-8,5.5-14.9,11.6-21c7.2-7.2,15.5-11.1,25.3-12.1h0.4 M272.6,280.6l-8.7,0.1
	c-0.8,0-1.4-0.4-1.5-1.4c-0.3-1.6-0.3-3.3,0.1-4.7c0.1-1,0.7-1.4,1.6-1.4l8.4,0.1 M299.6,273.8c-0.1-1.2-0.4-2.2-0.8-3
	c-0.8-1.6-2.3-2.8-4.4-2.9l-4.8-0.3l-5,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.6-0.8,2.9 M272.6,280.6l7.2-0.1 M272.6,273.6v7
	 M279.5,273.7l-6.9-0.1 M279.8,280.4c0.1,2.1,0.8,3.7,2.2,5c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.4,0.3,2.8,0.5,4.1,0.4h0.5
	c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.2,2.1-2.9,2.2-5.2 M301.3,280.2l7.6-0.1c1.2-0.3,1.9-1,2.2-1.8v-2.5
	c-0.3-1.1-1-1.6-1.9-1.8l-7.7-0.1 M301.3,273.8v6.3 M299.3,280.2h1.9 M301.3,273.8h-1.6 M308.4,326.3h0.7 M309.1,326.3
	c-1.5,0.3-1.8,2.2-0.5,5.8c1.5,6.7,3.4,10.2,5.5,10.2h81.4v-16H309.1L309.1,326.3z M247.1,304.8c16.2,4.8,26,16.1,29.3,33.6v0.1
	c0.7,2.8,1.2,5.6,1.9,8.5 M270.2,369.5c-2.3-11.4-8-21.6-16.6-30.4l-0.6-0.4 M278.2,347l-25.2-8.4 M299.6,273.8l-20.1-0.1
	 M299.3,280.2l-19.5,0.3 M271.4,383.5v-0.8c0-4.5-0.4-8.9-1.2-13.1 M251.5,374.5l-0.3-0.6c-0.6-1-1.4-1-2.6-0.4l-23.7,8.4
	 M251.5,374.5c0.4,2.6,0.7,5.2,0.7,8.1c0,11.7-4.1,21.6-12.4,29.9l-1.5,1.4 M291.8,383.5h-8.4 M283.4,383.5
	c-0.1-2.1-0.4-4.1-0.7-6.2 M271,390.6h13.1 M282.7,377.2l-12.5-7.7 M283.4,383.5h-12 M283.4,383.5l0.7,7.2 M271,390.6
	c0.3-2.3,0.4-4.8,0.5-7.3"
          />
          <path
            fill="none"
            d="M172,364.8l-0.3,0.6c-0.1,1,0.6,1.6,1.9,1.8l24.1,7.2 M181.4,362l-2.3,0.7 M181.4,365.5V362 M179.1,362.7
	L179,363c-0.4,1.2,0.1,2.1,1.4,2.2l1.2,0.3 M181.4,380.6l2.8,1 M184.2,381.6v0.3c-0.4,1.4,0.1,2.3,1.6,2.8c-0.1,5.1,1,9.6,3.3,13.8
	 M195.5,381.3l-23.7-8.4c-1.4-0.7-2.2-0.4-2.8,0.7l-0.3,0.3 M181.4,380.6l-5.5-2.1c-1.2-0.5-2.2-0.3-2.6,1.1l-0.1,3
	c0,5.4,1,10.5,3,15"
          />
          <path
            fill="none"
            d="M181.3,413.3l-1.1-1c-4.1-4.1-7.3-8.7-9.4-13.8c-2.1-5-3-10.3-3-16c0-3,0.3-5.9,0.8-8.8 M195.8,369.5l-0.8-3.4
	h-2.1 M202.9,359.8c-3.6,1.1-6.9,3-9.6,5.9L193,366 M192.9,366h-3.2l-0.7,1.5 M189,367.5l6.7,1.9 M195.8,369.5h0.3 M206.6,368.6
	v-0.4 M202.5,365.2c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.6c-1,0.7-1.9,1.5-2.5,2.5 M206.6,368.2l-4.1-3 M198.2,374.5
	c0.6-0.7,1.1-1.4,1.6-1.9c1.9-1.9,4.3-3.3,6.7-3.9"
          />
          <path
            fill="none"
            d="M206.6,368.6v0.8c0,1.6-0.7,3-2.1,4c-0.4,0.3-1,0.5-1.6,0.8c-1.2,0.8-2.6,1-4.1,0.4l-0.7-0.3 M204.6,377.2
	c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5
	s-0.8-4-2.2-5.5c-1.5-1.5-3.3-2.2-5.5-2.2C207.9,375,206.1,375.6,204.6,377.2L204.6,377.2z M195.8,381.4l-0.3-0.1 M195.5,381.3
	l-4.3,3 M195.8,381.4v1.2c0,3.3,1,6.3,3,8.8 M198.8,391.3l0.4-0.7c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9c-0.4-1.5-1.5-2.5-3-3
	l-0.8-0.3 M198.2,374.5c-1.5,2.1-2.3,4.4-2.5,7 M197.7,374.3l0.6,0.3 M196,369.5l1.6,4.8 M193.3,391.7c-0.7-0.8-1.2-1.6-1.5-2.6
	v-0.3l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4 M189,398.3l4.3-6.6 M204.6,395.9c-1.6-0.7-3.2-1.8-4.5-3.2l-1.2-1.4 M204.4,396.1l0.1-0.3
	 M198.8,391.3l-0.4,0.4 M215,396.1l-0.6-0.7c-1-1.2-2.1-1.9-3.6-2.1c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1l-0.6,0.8
	 M205.9,401l-3.6,4.3 M193.3,391.7h5.1 M205.9,401l-1.5-4.8 M189,398.3l-4.5,7c-0.6,1.2-1.5,1.4-2.6,0.7l-0.1-0.1
	c-1.9-2.3-3.6-4.8-4.8-7.4l-0.4-1"
          />
          <path
            fill="none"
            d="M176.4,397.6c3.4-1.4,5.2-4.1,5.1-8.4v-8.7 M189,367.5l-7.6-2.1 M191.2,384.3l-7-2.8 M214.1,368.9v0.7
	c0,1.6,0.6,2.9,1.8,3.9c0.6,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3 M217.9,360l0.3,5.2 M218.2,365.2l-4.1,3 M214.1,368.2
	v0.7 M214.1,368.9c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4 M224.2,370l5.2-1.4 M225.6,364.5c-1.2-1.1-2.6-2.1-4-2.8 M224.2,370
	c-0.7-1.1-1.4-1.9-2.2-2.8l-0.6-0.4c-1-0.8-2.1-1.4-3.3-1.8 M225.6,364.5c0.4,0.4,1,0.8,1.4,1.2c1,1,1.8,1.9,2.6,2.9 M224.4,382.1
	c-0.1-2.6-0.8-5-2.1-7 M224.9,381.8l-0.6,0.3 M222.3,375.1l0.3-0.1 M229,384.9l-4.1-3 M224.4,382.1l-0.7,0.3
	c-1.5,0.4-2.6,1.4-3.2,2.9c-0.3,0.5-0.6,1-0.8,1.5c-0.6,1.5-0.4,3,0.7,4.4l0.6,0.8"
          />
          <path
            fill="none"
            d="M224.4,382.1v0.5c0,3.6-1.1,6.6-3.4,9.2 M221.6,361.6c-1.2-0.7-2.3-1.2-3.7-1.6 M222.6,375l1.6-5 M222.3,375.1
	c-0.6-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.2-3.7 M242,379.9l-8.1,3.2 M229.5,368.6l10.6-2.9c1.4-0.1,1.8-1,1.4-2.5l-1.6-2.3
	 M245,394.2c1.2-3.6,1.8-7.6,1.8-11.7l-0.1-2.8c-0.4-1.1-1.2-1.4-2.3-0.8l-2.3,1 M248.6,365.3v0.3c0.3,1.1-0.4,1.9-1.9,2.1
	l-24.1,7.2 M229.3,396.7l5.9,9.1c0.6,1.2,1.5,1.5,2.8,0.5c3.2-3.7,5.5-7.7,7-12.1 M230.8,394.3l11.1-4.8 M242,389.5v4.7h3
	 M215,396.1l0.3,0.3 M204.6,395.9c1.6,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8 M220.9,391.9l0.3,0.3 M229,384.9
	c0.1,1.2-0.1,2.5-0.7,3.7l-0.3,0.7c-0.4,1-1.1,1.9-1.9,2.9"
          />
          <path
            fill="none"
            d="M220.9,391.9l-0.8,0.8c-1.5,1.5-3.3,2.8-5.2,3.4 M226.3,392.1l3,4.5 M221.2,392.1h5.1 M213.8,401.4
	c-1.1,0.4-2.2,0.7-3.3,0.5h-1.1c-1.2-0.1-2.3-0.4-3.3-1 M217.1,405.5l-3.3-4.1 M202.4,405.2c2.3,0.8,5,1.1,7.6,1.1
	c2.5,0,4.8-0.3,7-1 M215.3,396.4l-1.5,5 M233.9,410.6l-12.7-18.4 M233.9,383.1l-4.8,1.8 M229.3,396.7c0.6-0.8,1.1-1.5,1.6-2.3
	 M225.6,364.5l14.2-3.6 M242,389.5v-9.6 M230.8,394.3c1.9-3.3,2.9-7.2,3-11.3"
          />
          <path
            fill="none"
            d="M202.4,405.2l-6.7,8.4c-1,1-0.7,2.1,0.7,3.2c4.3,1.6,8.8,2.6,13.8,2.6c4.8,0,9.4-0.8,13.6-2.5
	c1.2-1,1.4-1.8,0.3-2.8l-6.9-8.5 M238.3,413.7l-0.3,0.1c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2 M238.3,413.7c-2.5,2.3-5.2,4.3-8.1,5.8"
          />
          <path
            fill="none"
            d="M230.3,419.7l0.7-0.7c0.7-0.8,0.6-1.7-0.4-2.6l-1.6-2.2"
          />
          <path
            fill="none"
            d="M189.4,419.4c6.2,3.6,13.1,5.2,20.6,5.2c7.4,0,14.2-1.6,20.2-5.1 M198.4,391.7l-14.2,20.8
	c-0.7,1.2-1.5,1.6-2.3,1.2l-0.6-0.3 M204.4,396.1l-15.3,19.9c-1.1,1.1-1.1,2.1-0.3,2.8l0.6,0.5 M181.3,413.3
	c2.5,2.3,5.2,4.4,8.1,6.1 M228.9,414.3c1.6-1.1,3.3-2.3,5-3.6 M228.9,414.3l-13.6-17.9 M282.7,377.2c-1.1-10.7-2.8-20.8-4.5-30.1
	 M217.6,355.1l0.3,4.8 M239.8,360.9c-0.8-1.1-1.6-2.1-2.6-3 M237.2,357.9l-15.5,3.7 M181.4,362l21.6-6.5 M248.6,365.3
	c1.4,2.9,2.2,5.9,2.9,9.1 M202.9,359.8l-0.4,5.4 M420.1,284.6l-8.7,0.1c-0.8,0-1.4-0.4-1.5-1.4c-0.3-1.6-0.3-3.3,0.1-4.7
	c0.1-0.8,0.7-1.4,1.6-1.2l8.4,0.1"
          />
          <path
            fill="none"
            d="M417,280.2c-0.3-0.3-0.8-0.5-1.2-0.5s-0.8,0.1-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.1,0.8,0.5,1.2
	c0.3,0.3,0.7,0.5,1.2,0.5s1-0.1,1.2-0.5c0.3-0.3,0.4-0.7,0.4-1.2S417.2,280.4,417,280.2L417,280.2z M420.1,277.4v7.2 M447.1,277.7
	c-0.1-1.2-0.4-2.2-0.8-3c-0.8-1.6-2.3-2.6-4.4-2.9l-4.7-0.3l-5,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.6-0.8,2.9 M448.7,284
	l7.6-0.1c1.2-0.3,1.9-1,2.2-1.8v-2.5c-0.3-1.1-1-1.6-1.9-1.8l-7.7-0.1 M448.7,277.7h-1.6 M427.1,284.4c0.1,2.1,0.8,3.7,2.2,5
	c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3l4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.2,2.1-3,2.2-5.2 M446.8,284.2
	l1.9-0.1 M447.1,277.7l-20.1-0.1 M446.8,284.2l-19.7,0.3 M448.7,277.7v6.3 M398,383.5V259.8 M420.1,284.6l7-0.1 M427,277.6l-6.9-0.1
	 M398,383.5H291.8 M499.9,170.4c7.4,2.2,14.3,4.8,20.6,7.6 M536.6,256.9l-126.6-3.2 M398,253.3v6.5 M576.5,383.5H398 M398,259.8
	l138.8,2.9 M284.1,390.6h301.3 M507.9,182.5l-3.2-1.9c-12.7-7-28.8-11.8-48.1-14.3h-0.1l-13.2-1.4c-7.6-0.3-16.2-0.4-26-0.6
	l-16.5-0.1 M97.8,169.4l-8.8,2.8c-1.4,2.2-0.6,4.4,2.2,6.6l8-9.5L97.8,169.4L97.8,169.4z"
          />
          <path
            fill="none"
            d="M99.2,169.4l16.5-1.2l11.7-0.8c22.4-1.4,46.1-2.2,71-2.5c29.2-0.3,56.1-0.6,80.9-0.7h8.5
	c45.7-0.3,83.2-0.3,112.9,0l16.5,0.1c9.8,0.1,18.4,0.3,26,0.6l13.2,1.4h0.1c19.5,2.6,35.6,7.3,48.1,14.3l3.2,1.9l12.5-4.4
	c-6.3-2.9-13.2-5.4-20.6-7.6l-0.1,1c-3.7-1.1-7.7-2.1-11.7-3.2l0.4-0.8c-4.5-1-8.5-1.9-12-2.5c-2.2,0.6-5.9,0.6-10.9-0.1l-9.2-1.1
	l-14-1.2l-15.7-0.6l-11.6-0.1v0.8l-5.8-0.1c-30.8-0.4-71.3-0.4-121.5-0.1h-8.5c-13.2,0.1-26.7,0.1-40.6,0.1h-6.7
	c-33.3,0.4-67,1.4-101.1,2.9l-3.3,0.3c-13.8,0.1-28.3,1.9-43.7,5.4l-2.2,0.3l7.6,1l8.8-2.8L99.2,169.4L99.2,169.4z M81.4,171.4
	l-1.9,2.5l9.1,5.6l2.6-0.6c-2.8-2.2-3.6-4.4-2.2-6.6L81.4,171.4z M79.5,173.8l-1,4.7l7.4,4.1l2.6-3.2"
          />
          <path
            fill="none"
            d="M86,182.6c-17.7,19.3-31.4,41.1-41.1,65.6l-0.1,0.3H55c5.5-22.3,17.5-45.5,36.2-69.6l-2.6,0.6L86,182.6
	L86,182.6z"
          />
          <path
            fill="none"
            d="M91.2,178.9C72.6,203,60.5,226.1,55,248.4h26.3v-0.6c5.8-23.8,15.1-46.8,28.2-69.1l6.3-10.7l-16.5,1.2
	L91.2,178.9L91.2,178.9z"
          />
          <path
            fill="none"
            d="M109.5,178.9l131.4-3c11.1-0.4,14.6,2.3,10.5,8l-31.5,57.6c-2.9,4.5-9.4,6.9-19.7,6.9H81.3v69.9h57.1
	c3.7-7.7,8.7-13.2,15-16.8c8.1-6.1,25-8.7,50.8-7.6h0.3c17.1-0.5,31.4,0.1,42.8,2.3c-0.3-18.3,0.3-33.7,1.5-46.2
	c5.2-28.1,15.5-56.8,31-86h-0.4c-24.8,0.1-51.7,0.4-80.9,0.7c-24.9,0.3-48.4,1.1-71,2.5l-11.7,0.8L109.5,178.9L109.5,178.9z
	 M115,285.9c3.9,0,7.3,1.4,10,4.1c2.8,2.8,4.1,6.1,4.1,10c0,3.9-1.4,7.2-4.1,9.9s-6.1,4.1-10,4.1c-3.9,0-7.2-1.4-9.9-4.1
	s-4.1-6.1-4.1-9.9c0-3.9,1.4-7.2,4.1-10C107.7,287.3,111,285.9,115,285.9L115,285.9z"
          />
          <path
            fill="none"
            d="M105,290.1c-2.8,2.8-4.1,6.1-4.1,10c0,3.9,1.4,7.2,4.1,9.9c2.8,2.8,6.1,4.1,9.9,4.1s7.3-1.4,10-4.1
	c2.8-2.8,4.1-6.1,4.1-9.9c0-3.9-1.4-7.2-4.1-10c-2.8-2.8-6.1-4.1-10-4.1C111,285.9,107.7,287.3,105,290.1L105,290.1z M130.7,165.4
	c34.1-1.5,67.8-2.5,101.1-2.9h6.7c13.9,0,27.4,0,40.6-0.1h8.5c50.2-0.3,90.7-0.3,121.5,0.1l5.8,0.1v-2.5c-2.9-1.9-6.5-2.9-11-3.2
	h-3.7c-13.1-0.1-27.6-0.1-43.9-0.1h-7.2c-18.3,0-38.4,0-60.4,0.1h-8.5c-24.8,0.1-51.7,0.4-80.9,0.8l-5,0.1l-8,0.1l-5.2,0.1
	c-2.5,0.7-5.1,2.5-7.8,5.5c2.8-3,5.4-4.8,7.8-5.5l-41.4,1.5C134.3,160.9,131.2,162.8,130.7,165.4L130.7,165.4z M81.3,248.4h119
	c10.2,0,16.8-2.3,19.5-6.9l31.5-57.6c4.1-5.8,0.6-8.4-10.5-8l-131.4,3c-13.1,22.1-22.6,45.1-28.2,68.9V248.4z M44.8,248.4l-0.4,1
	c-1.1,2.8-1.9,5.5-2.6,8.1c-0.4,1.8-0.8,3.4-1,5.2c1.2,0.5,1.8,2.3,1.7,5.6c-0.5,3.6-1,7.3-1.4,11c-1,9.2-1.4,19.1-1.5,29.7v8.5
	h-2.5l2.5,16.4v-7.3l12.9-8.5c-2.1-26.4-1.2-49.8,2.5-69.9H44.8L44.8,248.4L44.8,248.4z"
          />
          <path
            fill="none"
            d="M38.7,249.5l-2.3,4.8l3.4,1.4v1c0.7,0.3,1.4,0.7,2.1,1c0.7-2.8,1.5-5.4,2.6-8.1L38.7,249.5L38.7,249.5z
	 M56.1,251.4c-1.1,6.5-1.8,13.6-2.3,21.3l4-0.3c1.2-0.3,2.3-1,3.3-1.8c2.3-2.2,3.3-6.3,2.5-12.1c0.1-4.5-2.3-7-7.2-7.2H56.1
	L56.1,251.4z"
          />
          <path
            fill="none"
            d="M56.4,251.4h18.8c1.9,0.1,2.9,1.8,2.8,4.7l-1.1,47.7c0.3,1.1-1,1.7-3.6,1.7l-13.6,1.1v11.6h21.5v-69.9H55
	c-3.7,20.1-4.5,43.3-2.5,69.9h7.2v-11.6l-6.1,0.5c-0.3-7.7-0.4-15-0.3-21.9c0.1-4.4,0.3-8.7,0.4-12.7c0.4-7.7,1.2-14.9,2.3-21.3
	L56.4,251.4L56.4,251.4z"
          />
          <path
            fill="none"
            d="M56.4,251.4c5,0.1,7.3,2.6,7.2,7.2c0.7,5.8-0.1,9.9-2.5,12.1l12.2,1.8H57.8c2.9,0.8,4.3,2.2,3.9,4.4
	c0.5,3.7,0.3,6.2-1.1,7.6l13.2,1.2H57.1c3.4,1.2,5.1,4.4,4.7,9.5c0,4.1-0.5,7.2-1.8,9.1l13.3,1.4c2.6,0,3.9-0.5,3.6-1.6l1.1-47.7
	c0.1-2.9-0.8-4.5-2.8-4.7H56.4V251.4L56.4,251.4z M35.6,279.3l1.2-9.4l-2.9-1.4l-2.2,10.6"
          />
          <path
            fill="none"
            d="M36.9,270l-1.2,9.4l3.7,0.1l1.5-10.3c0.5-3.3,0-5-1.7-5l-5.2,4.4L36.9,270L36.9,270z"
          />
          <path
            fill="none"
            d="M40.7,262.8l-1.7,1.4c1.7,0.1,2.2,1.8,1.7,5l-1.5,10.3h1.7c0.4-3.9,0.8-7.6,1.4-11
	C42.6,265.2,42.1,263.4,40.7,262.8L40.7,262.8z M53.9,272.7c-0.3,4.1-0.4,8.3-0.4,12.7l3.6,0.1h1c1.1-0.1,1.9-0.5,2.6-1.2
	c1.2-1.4,1.7-3.9,1.1-7.6c0.4-2.1-1-3.6-3.8-4.4L53.9,272.7L53.9,272.7z"
          />
          <path
            fill="none"
            d="M61.1,270.7c-1,0.8-1.9,1.4-3.3,1.8h15.4L61.1,270.7z M59.8,304.2c-0.8,1.2-1.9,2.1-3.3,2.3l-3,0.7l6.1-0.5
	v-0.1v0.1l13.6-1.1L59.8,304.2L59.8,304.2z"
          />
          <path
            fill="none"
            d="M61.6,295.2c0.3-5.1-1.2-8.3-4.7-9.5l-3.6-0.1c-0.1,6.9,0,14.2,0.3,21.9l3-0.7c1.4-0.3,2.5-1.1,3.3-2.3
	C61.1,302.3,61.8,299.3,61.6,295.2z M60.7,284.4c-0.7,0.7-1.5,1.1-2.6,1.2h15.8L60.7,284.4z M39.6,326.8v17.3H22.3h17.3v-4.4H21.9
	v9.9l1.4,1.4l-0.1,1.6l-0.3,7.2l2.5,0.3c12.9-1.4,24.1-1.2,33.4,0.3l68.2,4c2.2-16,5.2-29.7,8.9-41.1v-0.1l2.1-5H52.5L39.6,326.8
	L39.6,326.8z M39.5,309.2h-8.9v4.4c3.2-0.3,5.2,0.4,6.3,2.2l2.6,0.6L39.5,309.2L39.5,309.2z"
          />
          <path
            fill="none"
            d="M21.9,366.6h4.5L23,360l0.3-7.2h-1.4 M136.2,323.2c-3.9,11.6-6.9,25.3-9.1,41.3l9.9,0.5l0.3-1.9
	c0.8-7.3,1.9-14.2,3.2-20.5c1.2-5.9,2.6-11.4,4.1-16.5c1-2.9,2.2-5.5,3.7-7.8h-9.9C137.6,319.8,136.9,321.4,136.2,323.2L136.2,323.2
	z"
          />
          <path
            fill="none"
            d="M144.6,326.1c-1.5,5.1-2.9,10.6-4.1,16.5h22.8l3.3-3.4c2.6-2.6,5.4-5,8.3-7c10.2-7.3,21.9-11,35.1-11
	c13.2,0,24.9,3.7,35.2,11c2.8,1.9,5.2,4.1,7.8,6.6l25.2,8.4c-0.5-2.9-1.2-5.8-1.9-8.5v-0.1c-3.3-17.5-13.1-28.6-29.3-33.6l-0.7-0.1
	c-9.4-1.8-20.5-2.8-33.7-2.6h-21.6c-16.2,0.1-27.2,2.3-33,6.5l-0.4,0.3c-3.7,2.2-6.9,5.4-9.5,9.6
	C146.8,320.6,145.5,323.2,144.6,326.1L144.6,326.1z"
          />
          <path
            fill="none"
            d="M158.1,308.5c5.8-4.1,16.8-6.3,33-6.5h21.6c13.2-0.1,24.5,0.7,33.7,2.6l0.7,0.1v-8.5
	c-11.4-2.2-25.7-2.9-42.8-2.3H204c-25.6-1.1-42.5,1.5-50.8,7.6c-6.3,3.4-11.3,9.1-15,16.8h9.9c2.5-4.1,5.6-7.4,9.5-9.6L158.1,308.5
	L158.1,308.5z M140.5,342.6c-1.2,6.3-2.3,13.2-3.2,20.5h14.3c2.5-7.4,6.3-14.3,11.8-20.5L140.5,342.6L140.5,342.6z"
          />
          <path
            fill="none"
            d="M137,365c-0.8,7-1.5,14.6-1.9,22.6l-10.3-1.4L56.4,377v12.1c-0.1,6.5,4,9.6,12.2,9.4h82
	c-1.4-5.1-1.9-10.5-1.9-16c0-6.9,1-13.3,3-19.5h-14.4L137,365L137,365z"
          />
          <path
            fill="none"
            d="M127.1,364.5c-1,6.9-1.8,14-2.5,21.7l10.3,1.4c0.4-8,1.1-15.5,1.9-22.6L127.1,364.5L127.1,364.5z M148.6,382.5
	c0,5.6,0.7,11,1.9,16c0.7,2.5,1.4,4.8,2.3,7.2l1.1,2.5c2.9,6.3,7.2,12.4,12.5,17.7c12,12,26.4,18,43.5,18c16.9,0,31.4-6.1,43.5-18
	c10-10,16-21.9,17.5-35.4c0.3-2.3,0.4-4.8,0.5-7.3v-0.8c0-4.5-0.4-8.9-1.2-13.1c-2.3-11.4-8-21.6-16.6-30.4l-0.6-0.4
	c-2.5-2.5-5.1-4.7-7.8-6.6c-10.2-7.3-22-11-35.2-11c-13.2,0-24.9,3.7-35.1,11c-2.9,2.1-5.6,4.4-8.3,7l-3.3,3.4
	c-5.4,6.2-9.4,13.1-11.8,20.5C149.5,369.2,148.6,375.6,148.6,382.5L148.6,382.5z M241.3,351.3c8.7,8.7,12.9,19.1,13.1,31.4
	c0,12.2-4.4,22.7-12.9,31.4c-8.7,8.7-19.1,13.1-31.4,13.1s-22.7-4.4-31.4-13.1c-1.8-1.8-3.4-3.7-4.8-5.6l-1.6-2.5
	c-1.5-2.3-2.6-4.7-3.6-7.1l-0.1-0.1c-1.8-5-2.8-10.2-2.8-15.8c0-12.2,4.3-22.7,12.9-31.4s19.1-12.9,31.4-12.9
	C222.3,338.4,232.6,342.6,241.3,351.3L241.3,351.3z"
          />
          <path
            fill="none"
            d="M210.1,338.4c-12.2,0-22.7,4.3-31.4,12.9c-8.7,8.7-12.9,19.1-12.9,31.4c0,5.6,1,11,2.8,15.8l0.1,0.1
	c1,2.5,2.2,5,3.6,7.1l1.6,2.5c1.5,1.9,3,3.9,4.8,5.6c8.7,8.7,19.1,13.1,31.4,13.1s22.7-4.4,31.4-13.1s12.9-19.1,12.9-31.4
	c0-12.2-4.4-22.7-12.9-31.4C232.6,342.6,222.3,338.4,210.1,338.4L210.1,338.4z M214.5,340.7h0.7c9.4,1.1,17.6,5.1,24.6,12.1
	c3.9,3.9,6.7,8,8.8,12.5c1.4,2.9,2.2,5.9,2.9,9.1c0.4,2.6,0.7,5.2,0.7,8.1c0,11.7-4.1,21.6-12.4,29.9l-1.5,1.4
	c-2.5,2.3-5.2,4.3-8.1,5.8c-6.1,3.3-12.8,5.1-20.2,5.1c-7.6,0-14.4-1.8-20.6-5.2c-2.9-1.6-5.5-3.7-8.1-6.1l-1.1-1
	c-4.1-4.1-7.3-8.8-9.4-13.8c-2.1-5-3-10.3-3-16c0-3,0.3-5.9,0.8-8.8c1.6-8,5.5-14.9,11.6-21c7.2-7.1,15.5-11.1,25.3-12.1h0.4l4-0.1
	L214.5,340.7L214.5,340.7z"
          />
          <path
            fill="none"
            d="M237.2,357.9c1,1,1.8,2.1,2.6,3l1.6,2.3c0.4,1.5,0,2.2-1.4,2.5l-10.6,2.9l-5.2,1.4l-1.6,4.8l24.1-7.2
	c1.5-0.3,2.1-1,1.9-2.1v-0.3c-2.1-4.5-5-8.7-8.8-12.5c-7-7-15.1-11-24.6-12.1h-0.7c-1,0.3-1.2,1-1.1,2.3l0.7,25.2l4.1-3.2l-0.3-5.2
	l-0.3-5l-0.3-6.2c-0.3-1.2,0.3-1.9,1.5-2.1l0.4,0.1c5.6,1.4,10.7,4.1,15.1,8.1L237.2,357.9L237.2,357.9z"
          />
          <path
            fill="none"
            d="M234.4,355.1h-16.8l0.3,5c1.2,0.4,2.5,1,3.7,1.7l15.5-3.7L234.4,355.1L234.4,355.1z"
          />
          <path
            fill="none"
            d="M218.9,346.9c-1.2,0.1-1.6,0.8-1.5,2.1l0.3,6.2h16.8c-4.5-4-9.5-6.7-15.1-8.1L218.9,346.9L218.9,346.9z
	 M214.5,340.7l-4.4-0.3l-4,0.1c1.1,0.1,1.7,1,1.4,2.5l-0.7,25.2v0.4l3.4-0.4c1.4,0,2.8,0.1,4,0.5v-0.5l-0.7-25.2
	C213.2,341.8,213.5,341,214.5,340.7L214.5,340.7z M214.6,340.7h-0.1l0.7,0.1"
          />
          <path
            fill="none"
            d="M205.9,340.7h-0.4c-9.8,1-18.2,5-25.3,12.1c-6.1,6.1-9.9,13.1-11.6,21l0.3-0.3c0.4-1.1,1.4-1.4,2.8-0.7
	l23.7,8.4l0.3,0.1c0.1-2.6,1-5,2.5-6.9l-0.6-0.1l-24.1-7.2c-1.4-0.3-2.1-0.8-1.9-1.8l0.3-0.5l-0.3,0.5c-0.1,1,0.6,1.6,1.9,1.8
	l24.1,7.2l-1.6-4.8h-0.3l-6.7-1.8l-7.6-2.1l-1.2-0.3c-1.4-0.1-1.8-1-1.4-2.2l0.1-0.1l0.1-0.1c0.7-1.1,1.5-2.2,2.5-3.3l2.6-2.8
	c5.1-5.1,10.9-8.4,17.5-9.8c1.5,0,2.1,0.7,1.9,2.1l-0.3,6.6l-0.3,4.3l-0.3,5.4l4.1,3.2l0.7-25.2
	C207.6,341.7,207.2,340.8,205.9,340.7L205.9,340.7z"
          />
          <path
            fill="none"
            d="M203,355.6l-21.6,6.5v3.6l7.6,2.1l0.7-1.5h3.2l0.3-0.3c2.9-2.9,6.1-4.8,9.6-5.9L203,355.6L203,355.6z"
          />
          <path
            fill="none"
            d="M203.5,349c0.3-1.4-0.4-2.1-1.9-2.1c-6.6,1.5-12.4,4.7-17.5,9.8l-2.6,2.8l-2.5,3.3l2.5-0.7l21.6-6.5L203.5,349
	L203.5,349z M251.5,374.5c-0.6-3.2-1.5-6.3-2.9-9.1v0.3c0.3,1.1-0.4,1.9-1.9,2.2l-24.1,7.2l-0.4,0.1c1.4,2.1,2.1,4.4,2.1,7l0.6-0.1
	l23.7-8.4c1.2-0.7,2.1-0.5,2.6,0.4L251.5,374.5L251.5,374.5z"
          />
          <path
            fill="none"
            d="M251.2,373.9c-0.6-1-1.4-1-2.6-0.4l-23.7,8.4l4.1,3l4.8-1.8l8.1-3l2.3-1c1.1-0.5,1.9-0.3,2.3,0.8l0.1,2.8
	c0,4.1-0.6,8-1.8,11.7c-1.5,4.4-3.7,8.5-7,12.1c-1.2,0.8-2.2,0.7-2.8-0.5l-5.9-9.1l-2.9-4.5h-5.2l12.7,18.4l1.5,2.2
	c0.7,1.4,1.5,1.8,2.6,1.1l0.3-0.1l1.5-1.4c8.3-8.3,12.4-18.2,12.4-29.9c0-2.8-0.3-5.5-0.7-8.1L251.2,373.9L251.2,373.9z
	 M179.1,362.7L179,363c-0.4,1.2,0.1,2.1,1.4,2.2l1.2,0.3v-3.6L179.1,362.7L179.1,362.7z M181.4,380.6v8.7c0.1,4.3-1.6,7-5.1,8.4
	l0.4,1c1.2,2.6,2.9,5.1,4.8,7.4l0.1,0.1c1.1,0.8,2.1,0.5,2.6-0.7l4.5-7c-2.3-4.1-3.4-8.7-3.3-13.8c-1.5-0.4-2.1-1.4-1.6-2.8v-0.3
	L181.4,380.6L181.4,380.6z"
          />
          <path
            fill="none"
            d="M184.2,381.6l7,2.8l4.1-3l-23.7-8.4c-1.4-0.7-2.2-0.4-2.8,0.7l-0.3,0.3c-0.6,2.8-0.8,5.8-0.8,8.8
	c0,5.8,1,11.1,3,16c2.1,5.1,5.2,9.6,9.4,13.8l1.1,1l0.6,0.3c1,0.4,1.6,0,2.3-1.2l14.2-20.8h-5.1l-4.3,6.6l-4.5,7
	c-0.6,1.2-1.5,1.4-2.6,0.7l-0.1-0.1c-1.9-2.3-3.6-4.8-4.8-7.4l-0.4-1c-2.1-4.5-3-9.6-3-15l0.1-3c0.6-1.4,1.4-1.8,2.6-1.1l5.5,2.1
	L184.2,381.6L184.2,381.6z"
          />
          <path
            fill="none"
            d="M184.1,382c-0.4,1.4,0.1,2.3,1.6,2.8c-0.1,5.1,1,9.6,3.3,13.8l4.3-6.6c-0.7-0.8-1.2-1.6-1.5-2.6V389l-0.3-1.1
	c-0.3-1.2-0.3-2.3-0.3-3.4l-7-2.8L184.1,382L184.1,382z M173.3,379.6l-0.1,3c0,5.4,1,10.5,3,15c3.4-1.4,5.2-4.1,5.1-8.4v-8.7
	l-5.5-2.1C174.8,377.8,173.9,378.3,173.3,379.6L173.3,379.6z M194.9,366l1,3.3h0.3c0.7-1.1,1.5-1.9,2.5-2.5l0.8-0.5
	c1-0.6,2.1-1,3.2-1.2l0.3-5.4c-3.6,1.1-6.9,3-9.6,5.9l-0.3,0.3h1.9L194.9,366L194.9,366z"
          />
          <path
            fill="none"
            d="M189.7,366l-0.7,1.5l6.7,1.9l-0.8-3.4h-2.1 M206.6,368.2l-4.1-3.2c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.5
	c-1,0.7-1.9,1.5-2.5,2.5l1.6,4.8l0.6,0.1c0.6-0.7,1.1-1.4,1.6-1.9c1.9-1.9,4.3-3.3,6.7-3.9L206.6,368.2L206.6,368.2z"
          />
          <path
            fill="none"
            d="M199.9,372.5c-0.7,0.7-1.2,1.2-1.6,1.9l0.7,0.3c1.5,0.5,2.9,0.3,4.1-0.4c0.7-0.3,1.2-0.4,1.6-0.8
	c1.4-1,2.1-2.2,2.1-4v-0.8C204.1,369.2,201.9,370.5,199.9,372.5L199.9,372.5z"
          />
          <path
            fill="none"
            d="M206.6,369.5c0,1.6-0.7,3-2.1,4c-0.4,0.3-1,0.5-1.6,0.8c-1.2,0.8-2.6,1-4.1,0.4l-0.7-0.3
	c-1.5,2.1-2.3,4.4-2.5,6.9l0.8,0.3c1.5,0.5,2.6,1.5,3,3c0.1,0.5,0.3,1.2,0.4,1.9c0.3,1.4,0,2.8-1,4l-0.4,0.7l1.2,1.4
	c1.4,1.4,2.9,2.5,4.5,3.2l0.6-0.8c1-1.4,2.2-1.9,3.7-2.1c0.7,0,1.4,0.1,2.1,0.3c1.5,0.1,2.8,0.8,3.6,2.1l0.6,0.7
	c1.9-0.7,3.6-1.8,5.2-3.4l0.8-0.8l-0.6-0.8c-1-1.4-1.2-2.9-0.7-4.4c0.3-0.5,0.4-1,0.8-1.5c0.6-1.4,1.5-2.3,3.2-2.9l0.7-0.3
	c-0.1-2.6-0.8-5-2.1-7l-0.8,0.3c-1.5,0.6-2.9,0.3-4.1-0.5c-0.6-0.4-1.1-0.8-1.5-1.5c-1.1-1-1.8-2.2-1.8-3.9v-0.7
	c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4v1.2L206.6,369.5L206.6,369.5z M204.6,377.2c1.5-1.5,3.3-2.2,5.5-2.2c2.1,0,4,0.8,5.5,2.2
	c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3c-2.2,0-4-0.8-5.5-2.3c-1.5-1.5-2.2-3.3-2.2-5.5
	S203,378.7,204.6,377.2L204.6,377.2z"
          />
          <path
            fill="none"
            d="M202.4,382.7c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5
	s-0.8-4-2.2-5.5c-1.5-1.5-3.3-2.2-5.5-2.2c-2.2,0-4,0.8-5.5,2.2C203,378.7,202.4,380.5,202.4,382.7L202.4,382.7z M195.5,381.3
	l-4.1,3c-0.1,1.1,0,2.2,0.3,3.4l0.3,1.1v0.3c0.3,1,0.8,1.9,1.5,2.6h5.1l0.3-0.4c-1.9-2.5-3-5.4-3-8.8v-1.2H195.5L195.5,381.3z"
          />
          <path
            fill="none"
            d="M195.8,382.7c0,3.3,1,6.3,3,8.8l0.4-0.7c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9c-0.4-1.5-1.5-2.5-3-3
	l-0.8-0.3L195.8,382.7L195.8,382.7z"
          />
          <path
            fill="none"
            d="M198.8,391.3l-0.3,0.4l-14.3,20.8c-0.7,1.2-1.5,1.6-2.3,1.2l-0.6-0.3c2.5,2.3,5.2,4.4,8.1,6.1l-0.6-0.5
	c-0.8-0.8-0.8-1.7,0.3-2.8l15.3-19.9l0.1-0.3c-1.6-0.7-3.2-1.8-4.5-3.2L198.8,391.3L198.8,391.3z"
          />
          <path
            fill="none"
            d="M204.4,396.1l1.5,5c1,0.4,2.2,0.8,3.3,1h1.1c1.1,0,2.2-0.1,3.3-0.5l1.5-5l-0.3-0.3c-1.5,0.5-3.2,0.8-5,0.8
	c-1.9,0-3.9-0.4-5.5-1.1L204.4,396.1L204.4,396.1z"
          />
          <path
            fill="none"
            d="M204.6,395.9c1.6,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8l-0.6-0.7c-1-1.2-2.1-1.9-3.6-2.1
	c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1L204.6,395.9L204.6,395.9z"
          />
          <path
            fill="none"
            d="M205.9,401l-1.5-5l-15.3,19.9c-1.1,1.1-1.1,2.1-0.3,2.8l0.6,0.5c6.2,3.6,13.1,5.2,20.6,5.2
	c7.4,0,14.2-1.6,20.2-5.1l0.7-0.7c0.7-0.8,0.6-1.6-0.4-2.6l-1.6-2.2l-13.6-17.7l-1.5,5l3.3,4.1l6.9,8.5c1,1,1,1.9-0.3,2.8
	c-4.1,1.6-8.7,2.5-13.6,2.5c-4.9,0-9.5-0.8-13.8-2.6c-1.4-1.1-1.5-2.2-0.7-3.2l6.7-8.4L205.9,401L205.9,401z"
          />
          <path
            fill="none"
            d="M202.4,405.2c2.3,0.8,5,1.1,7.6,1.1c2.5,0,4.8-0.3,7-1l-3.3-4.1c-1.1,0.4-2.2,0.7-3.3,0.5h-1.1
	c-1.2-0.1-2.3-0.4-3.3-1L202.4,405.2L202.4,405.2z M214.1,369.5c0,1.6,0.6,2.9,1.8,3.9c0.6,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5
	l0.8-0.3c-0.6-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.2-3.7L214.1,369.5L214.1,369.5z M218.2,365.2c1.2,0.3,2.3,1,3.3,1.8l0.6,0.4
	c0.8,0.7,1.6,1.7,2.2,2.8l5.2-1.4c-0.8-1-1.6-2.1-2.6-2.9c-0.4-0.4-1-0.8-1.4-1.2c-1.2-1.1-2.6-2.1-4-2.8c-1.2-0.7-2.3-1.2-3.7-1.6
	L218.2,365.2z"
          />
          <path
            fill="none"
            d="M214.1,368.2v0.5c2.3,0.5,4.3,1.8,6.2,3.7c0.8,0.8,1.5,1.7,2.1,2.5l0.4-0.1l1.6-4.8c-0.7-1.1-1.4-1.9-2.2-2.8
	l-0.6-0.4c-1-0.8-2.1-1.4-3.3-1.8L214.1,368.2L214.1,368.2z M225.6,364.5l14.2-3.4c-0.8-1.1-1.7-2.1-2.6-3l-15.5,3.7
	C223,362.4,224.2,363.4,225.6,364.5L225.6,364.5z"
          />
          <path
            fill="none"
            d="M229.5,368.6l10.6-2.9c1.4-0.1,1.8-1,1.4-2.5l-1.6-2.3l-14.2,3.4c0.4,0.4,1,0.8,1.4,1.2
	C227.8,366.7,228.6,367.7,229.5,368.6L229.5,368.6z M224.4,382.1v0.5c0,3.6-1.1,6.6-3.4,9.2l0.1,0.3h5.2c0.8-1,1.5-1.9,1.9-2.9
	l0.3-0.7c0.6-1.2,0.7-2.5,0.7-3.7l-4.1-3L224.4,382.1L224.4,382.1z"
          />
          <path
            fill="none"
            d="M223.7,382.4c-1.5,0.4-2.6,1.4-3.2,2.9c-0.3,0.5-0.6,1-0.8,1.5c-0.6,1.5-0.4,3,0.7,4.4l0.6,0.8
	c2.3-2.6,3.4-5.8,3.4-9.2v-0.5L223.7,382.4L223.7,382.4z M233.9,383.1c-0.1,4.1-1.1,8-3,11.3l11.1-4.8V380L233.9,383.1L233.9,383.1z
	 M242,379.9v14.3h3c1.2-3.6,1.8-7.6,1.8-11.7l-0.1-2.8c-0.4-1.1-1.2-1.4-2.3-0.8L242,379.9L242,379.9z"
          />
          <path
            fill="none"
            d="M238,406.5c3.2-3.7,5.5-7.7,7-12.1h-3v-4.8l-11.1,4.8c-0.4,0.8-1,1.6-1.6,2.3l5.9,9.1
	C235.8,407.1,236.7,407.3,238,406.5L238,406.5z M215.3,396.4l13.6,17.9c1.6-1.1,3.3-2.3,5-3.6l-12.7-18.4l-0.1-0.3l-0.8,0.8
	c-1.5,1.5-3.3,2.6-5.2,3.4L215.3,396.4L215.3,396.4z"
          />
          <path
            fill="none"
            d="M228.2,389.3c-0.4,1-1.1,1.9-1.9,2.9l2.9,4.5c0.6-0.8,1.1-1.5,1.6-2.3c1.9-3.3,2.9-7.2,3-11.3l-4.8,1.8
	c0.1,1.2-0.1,2.5-0.7,3.7L228.2,389.3z M210.1,406.5c-2.8,0-5.2-0.4-7.6-1.1l-6.7,8.4c-1,1-0.7,2.1,0.7,3.2
	c4.3,1.6,8.8,2.6,13.8,2.6c4.8,0,9.4-0.8,13.6-2.5c1.2-1,1.4-1.8,0.3-2.8l-6.9-8.5C214.9,406.2,212.5,406.5,210.1,406.5L210.1,406.5
	z M238,413.9c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2c-1.5,1.4-3.3,2.6-5,3.6l1.6,2.2c1,1,1.1,1.8,0.4,2.6l-0.7,0.7
	c2.9-1.5,5.5-3.6,8.1-5.8L238,413.9L238,413.9z M127.1,364.5l-68.2-4c-9.4-1.5-20.5-1.6-33.4-0.3l13.2,16.4l2.8-0.1l15,0.5l68.2,9.2
	C125.3,378.5,126.1,371.2,127.1,364.5L127.1,364.5z"
          />
          <path
            fill="none"
            d="M38.8,376.6l-13.3-16.4L23,360l3.4,6.6l5.5,10.6"
          />
          <path
            fill="none"
            d="M40.4,379.9l1.1-3.4l-2.8,0.1l-6.9,0.6l1.4,2.8 M426.6,160.2l-11.6-0.1v1.6l11.6,0.1l15.7,0.6l14,1.2l9.2,1.1
	c5,0.6,8.5,0.7,10.9,0.1l-1.4-0.3c-5.8-1-11.7-1.9-17.9-2.6l-14.9-1.4L426.6,160.2L426.6,160.2z M456.4,166.3l-13.2-1.4
	c-7.6-0.3-16.2-0.4-26-0.6l-16.5-0.1c-1.6,27.1-2.5,56.8-2.6,89.3l12,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.6-0.3-0.8l1.4-67.4
	c0.3-6.1,2.2-9.2,5.9-9.6h0.3l14.3,0.3c27.5,1.2,48.6,5.9,63.1,14.2c12.9,6.9,26.8,16.6,41.7,29.2l3.2,2.8l0.1,0.1l18.6,15.7
	c3.7,4.4,5,9.5,3.9,15.3c-1.8,6.6-10,9.9-24.8,9.6L398,259.8v123.5h178.4c3.7-16.6,6.5-36.6,8.3-59.6c2.1-29.9,0.1-56.7-5.8-80.6
	c-0.8-3.7-0.3-5.9,1.9-6.6c-27.1-23.1-51.5-41.1-72.9-54.1l-3.2-1.9C492.2,173.6,476.1,168.7,456.4,166.3L456.4,166.3z M575.7,329.6
	l-1.7,10.2c-0.4,1.8-2.1,2.6-5,2.5H399.9v-16h172.2C575.2,326.3,576.3,327.5,575.7,329.6L575.7,329.6z M411.7,277.3l8.4,0.1l6.9,0.1
	c0.1-1.1,0.4-2.1,0.8-2.9c0.8-1.6,2.3-2.6,4.4-2.9l5-0.3l4.7,0.3c2.1,0.3,3.6,1.2,4.4,2.9c0.4,0.8,0.7,1.9,0.8,3h1.6l7.7,0.1
	c1,0.1,1.6,0.7,1.9,1.8v2.5c-0.3,1-1,1.5-2.2,1.8l-7.6,0.1h-1.9c-0.1,2.2-0.8,4-2.2,5.2c-0.7,0.5-1.5,1.1-2.5,1.4l-1,0.3
	c-1.2,0.3-2.3,0.4-3.6,0.4h-0.5l-4.1-0.4l-1-0.3c-1-0.3-1.8-0.8-2.5-1.4c-1.4-1.1-2.1-2.8-2.2-5l-7,0.1l-8.7,0.1
	c-0.8,0-1.4-0.4-1.5-1.4c-0.3-1.6-0.3-3.3,0.1-4.7C410.2,277.8,410.8,277.3,411.7,277.3L411.7,277.3z"
          />
          <path
            fill="none"
            d="M572.1,326.3H399.9v16.1h169.2c2.9,0.1,4.5-0.7,5-2.5l1.7-10.2C576.3,327.5,575.2,326.3,572.1,326.3
	L572.1,326.3z M410.1,278.7c-0.3,1.4-0.4,3-0.1,4.7c0.1,1,0.7,1.4,1.5,1.4l8.7-0.1v-7l-8.4-0.1C410.8,277.3,410.2,277.8,410.1,278.7
	L410.1,278.7z M417,280.2c0.3,0.3,0.4,0.8,0.4,1.2s-0.1,0.8-0.4,1.2c-0.3,0.3-0.8,0.5-1.2,0.5s-0.8-0.1-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.1-1,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5C416.1,279.6,416.5,279.8,417,280.2L417,280.2z"
          />
          <path
            fill="none"
            d="M415.6,279.6c-0.4,0-0.8,0.1-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
	s1-0.1,1.2-0.5c0.3-0.3,0.4-0.7,0.4-1.2c0-0.4-0.1-1-0.4-1.2C416.5,279.8,416.1,279.6,415.6,279.6L415.6,279.6z M427.1,284.4
	l19.7-0.3l1.9-0.1v-6.3h-1.6l-20.1-0.1l-6.9-0.1v7.2"
          />
          <path
            fill="none"
            d="M427.7,274.7c-0.4,0.8-0.7,1.6-0.8,2.9l20.2,0.1c-0.1-1.2-0.4-2.2-0.8-3c-0.8-1.6-2.3-2.6-4.4-2.9l-4.7-0.3
	l-5,0.3C430,272.1,428.6,273,427.7,274.7L427.7,274.7z M456.4,277.8l-7.7-0.1v6.3l7.6-0.1c1.2-0.3,1.9-1,2.2-1.8v-2.5
	C458.1,278.5,457.4,278,456.4,277.8L456.4,277.8z M446.8,284.2l-19.5,0.3c0.1,2.1,0.8,3.7,2.2,5c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3
	l4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4C446,288.1,446.7,286.4,446.8,284.2L446.8,284.2z M499.9,170.4
	l0.3-2.1l-1.1-0.3l-4.8-1.2l-5-1.2l-0.8,1.8l-0.4,0.8c4,1,8,2.1,11.7,3.2L499.9,170.4L499.9,170.4z"
          />
          <path
            fill="none"
            d="M498.9,168.1l-2.3-6.6l-2.6-0.7l0.1,6.1 M492.9,192c13.2,7.2,27.5,17.2,42.8,30.3v-2.9
	c-15-12.5-28.9-22.3-41.7-29.2c-14.6-8.3-35.6-13.1-63.1-14.2l-14.3-0.3h-0.3c-3.7,0.4-5.6,3.6-5.9,9.6l-1.4,67.4
	c0,0.3,0.1,0.6,0.3,0.8c0.3,0.1,0.4,0.3,0.8,0.3l126.6,3.2v-0.8l-1.9-0.1c-2.2-0.1-4-0.7-5.4-1.4l-118.2-2.9l1.4-66.3
	c0.1-4.5,1.5-7,3.9-7.6l14.3,0.3C457.8,179.2,478.4,183.9,492.9,192L492.9,192z"
          />
          <path
            fill="none"
            d="M492.9,192c-14.4-8.1-35.1-12.8-62.2-13.9l-14.3-0.3c-2.5,0.6-3.7,3-3.9,7.6l-1.4,66.3l118.2,2.9
	c-1.9-1.1-2.9-2.8-3.2-5c0-2.1,0.1-4,0.3-5.8c0.4-3.4,1.1-6.2,2.2-8.4c1.2-2.3,3.7-3.9,7.4-4.5l-0.3-8.7
	C520.4,209.2,506.1,199.1,492.9,192L492.9,192z M356.6,154.3c0-0.4-0.1-0.6-0.5-0.6H350c-0.4,0-0.5,0.1-0.5,0.6v2.3h7.2V154.3z
	 M381.6,174.8l-66.6-0.1c-18.7,0-30.4,5.9-34.7,17.6c-4.1,9.1-7.2,17.5-9.1,25l-1.9,10c-0.3,2.6-0.4,5.2-0.4,7.6v0.3
	c-0.1,1,0,2.1,0.1,2.9c1.1,8.1,10.7,12.1,28.9,12.2l84.2,2.5c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.4-0.8l3.6-70.3
	C386.6,177.3,384.8,175.1,381.6,174.8L381.6,174.8z M384.6,181.7l-3.6,68.9l-83.1-2.5c-16.6,0-25.5-3.4-26.8-10.3
	c-0.1-0.8-0.1-1.5,0-2.3V235c-0.1-11.4,3.6-25.3,11.3-41.8c4.3-10.7,15.1-16.1,32.6-16.1l66.3,0.1
	C383.4,177.3,384.4,178.9,384.6,181.7L384.6,181.7z"
          />
          <path
            fill="none"
            d="M381.5,177l-66.3-0.1c-17.6,0-28.5,5.4-32.6,16.1c-7.6,16.5-11.3,30.4-11.3,41.8v0.4c-0.1,0.8-0.1,1.6,0,2.3
	c1.2,6.9,10.2,10.3,26.8,10.3l83.1,2.5l3.6-68.9C384.4,178.9,383.4,177.3,381.5,177L381.5,177z"
          />
          <path
            fill="none"
            d="M386.8,181.5l-3.6,70.3c0,0.3-0.1,0.6-0.4,0.8c-0.1,0.1-0.4,0.3-0.7,0.3l15.8,0.4c0.1-32.5,1-62.2,2.6-89.3
	c-29.6-0.3-67.3-0.3-112.9,0h-8.3c-15.4,29.3-25.7,57.9-30.9,86c-1.2,12.5-1.8,27.9-1.5,46.2l1.5,0.3c19.9,5.5,31.8,18.8,35.8,39.9
	c3.3,14.3,5.8,30,7.4,46.8h106.2V259.8l-90.4-2.3c-5.2,0.1-10.6-0.3-15.8-1l-3.7-0.7c-7.8-1.4-13.5-3.3-16.6-5.5
	c-3-2.2-4.3-6.6-3.7-13.3l1.8-9.5c0.4-3.2,1-6.6,1.9-10c1.9-7.6,5-16,9.1-25c4.4-11.7,16-17.5,34.7-17.6l66.6,0.1
	C384.8,175.1,386.6,177.3,386.8,181.5L386.8,181.5z M262.5,279.5c-0.3-1.6-0.3-3.3,0.1-4.7c0.1-1,0.7-1.4,1.6-1.4l8.4,0.1l6.9,0.1
	c0.1-1.1,0.4-2.1,0.8-2.9c0.8-1.6,2.3-2.8,4.4-2.9l5-0.3l4.8,0.3c2.1,0.3,3.6,1.2,4.4,2.9c0.4,0.8,0.7,1.8,0.8,3h1.6l7.7,0.1
	c1,0.1,1.6,0.7,1.9,1.8v2.5c-0.3,1-1,1.5-2.2,1.8l-7.6,0.1h-1.9c-0.1,2.2-0.8,4-2.2,5.2c-0.7,0.5-1.5,1.1-2.5,1.4l-1,0.3
	c-1.2,0.3-2.3,0.4-3.6,0.4h-0.5c-1.4,0-2.8-0.1-4.1-0.4l-1-0.3c-1-0.3-1.8-0.8-2.5-1.4c-1.4-1.1-2.1-2.8-2.2-5l-7,0.1l-8.7,0.1
	C263.2,280.7,262.6,280.3,262.5,279.5L262.5,279.5z M308.4,326.3h86.9v16.1h-81.4c-2.1-0.1-3.9-3.4-5.5-10.2c-1.2-3.6-1-5.5,0.5-5.8
	h-0.5L308.4,326.3L308.4,326.3z"
          />
          <path
            fill="none"
            d="M264,280.7l8.7-0.1v-7l-8.4-0.1c-1,0-1.5,0.4-1.6,1.4c-0.3,1.4-0.4,2.9-0.1,4.7
	C262.6,280.3,263.2,280.7,264,280.7L264,280.7z M280.4,270.8c-0.4,0.8-0.7,1.6-0.8,2.9l20.1,0.1c-0.1-1.2-0.4-2.2-0.8-3
	c-0.8-1.6-2.3-2.8-4.4-2.9l-4.8-0.3l-5,0.3C282.7,268.1,281.2,269,280.4,270.8L280.4,270.8z"
          />
          <path
            fill="none"
            d="M299.3,280.2h1.9v-6.3h-1.6l-20.1-0.1l-6.9-0.1v7l7.2-0.1"
          />
          <path
            fill="none"
            d="M284.5,286.8l1,0.3c1.4,0.3,2.8,0.5,4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4
	c1.4-1.2,2.1-2.9,2.2-5.2l-19.5,0.3c0.1,2.1,0.8,3.7,2.2,5C282.7,286.1,283.5,286.5,284.5,286.8L284.5,286.8z M311,278.2v-2.5
	c-0.3-1.1-1-1.6-1.9-1.8l-7.7-0.1v6.3l7.6-0.1C309.9,279.8,310.8,279.2,311,278.2L311,278.2z M308.6,332c1.5,6.7,3.4,10.2,5.5,10.2
	h81.4v-16h-86.4C307.5,326.5,307.3,328.5,308.6,332L308.6,332z M382.2,252.9l-84.2-2.5c-18.2-0.1-27.8-4.1-28.9-12.2
	c-0.1-1-0.1-1.9-0.1-2.9V235c0-2.5,0.1-5,0.4-7.6l-1.8,9.5c-0.5,6.7,0.7,11.3,3.7,13.3c3.3,2.3,8.8,4.1,16.6,5.5l3.7,0.7
	c5.2,0.7,10.6,1.1,15.8,1l90.4,2.3v-6.5L382.2,252.9L382.2,252.9z M398,259.8l138.8,2.9l-0.1-5.8l-126.6-3.2l-12.1-0.4 M271.3,217.3
	c-0.8,3.4-1.5,6.9-1.9,10L271.3,217.3L271.3,217.3z M186.5,155.4v2.3l8-0.1v-2.3c0-0.4-0.1-0.6-0.6-0.6h-6.9
	C186.7,154.9,186.5,155.1,186.5,155.4L186.5,155.4z M617.4,241.8l-19.1,1c-3,0.1-5.5-0.7-7.4-2.3c-21.3-16.8-43.9-33.4-68-50.2
	c-4.4-2.1-5.6-3.9-3.6-5.2l6.7-3.7l-5.6-3.3l-12.5,4.4c21.5,12.9,45.8,31,72.9,54.1l8,6.9c6.3,5,14.3,7.4,23.9,7.6h0.5l12.4-2.9
	L617.4,241.8L617.4,241.8z"
          />
          <path
            fill="none"
            d="M612.8,251.1c-9.6-0.1-17.6-2.6-23.9-7.6l-8-6.9c-2.1,0.7-2.8,2.9-1.9,6.6c5.9,23.9,8,50.8,5.8,80.6
	c-1.8,23-4.5,42.9-8.3,59.6h9.5c2.3-22.8,5.9-41.8,10.9-56.8v-0.1c3.9-10.2,9.5-17.5,17.1-21.7c8.3-6.1,25-8.7,50.8-7.6h0.3
	c17.7-0.5,32.3,0.3,44,2.6l0.3,0.1c12.7,3.6,22.1,10.2,28.3,20.1l21,0.8l-7.2-31.5c-0.4-3.7,0.4-5.6,2.5-5.8h22l-1.7-4
	c-0.5-1.1-1.1-2.1-1.8-3l-1-1.1c-1.9-2.2-4.1-3.9-6.7-4.7c-17.6-4.8-42.2-9.2-73.7-13.1c-23.9-2.8-49.9-4.8-78-6.5h-0.3L612.8,251.1
	L612.8,251.1z"
          />
          <path
            fill="none"
            d="M617.4,241.8c-30.8-23.2-61.2-43.3-91.2-60.5l-6.7,3.7c-2.1,1.4-0.8,3.2,3.6,5.2c23.9,16.8,46.6,33.6,68,50.2
	c1.9,1.6,4.4,2.5,7.4,2.3L617.4,241.8L617.4,241.8z M557.5,237.8L539,222.1l-0.1-0.1l-3.2-2.8v2.9l0.3,8.7l5.1-0.3
	c2.9-0.3,5,0.7,5.9,3l1.9,8.7h5.5c1-0.1,1.4,0.7,1.2,2.3c-0.4,4.5-3,8.3-7.8,10.9c-1.4,0.3-3.2,0.6-5.2,0.6l-0.3,0.4
	c-0.1,0.3-0.4,0.3-0.8,0.3l-4.8-0.1l0.1,5.8c14.7,0.3,23-3,24.8-9.6C562.5,247.3,561.3,242.2,557.5,237.8L557.5,237.8z"
          />
          <path
            fill="none"
            d="M554.4,242.3c-0.1,2.8-1,5.2-2.5,7.2h-3c-0.3,2.5-2.3,4.4-6.3,6.1l-1.8,0.7h1.8c2.1-0.1,3.9-0.3,5.2-0.6
	c4.8-2.6,7.4-6.3,7.8-10.9C555.8,243,555.3,242.3,554.4,242.3L554.4,242.3z"
          />
          <path
            fill="none"
            d="M554.4,242.3h-5.5c-3.3,0.7-7.6,1.1-12.7,1.2c-3,0.1-6.3,0.1-9.9,0.1c-0.1,1.8-0.3,3.7-0.3,5.8
	c0.3,2.2,1.4,3.9,3.2,5c1.4,0.8,3.2,1.2,5.4,1.4l1.9,0.1l4.1,0.1l1.8-0.7c4-1.6,6.2-3.6,6.3-6.1h3
	C553.6,247.6,554.4,245.2,554.4,242.3L554.4,242.3z"
          />
          <path
            fill="none"
            d="M528.5,235.5c-1.1,2.2-1.8,5-2.2,8.4c3.6,0,6.9,0,9.9-0.1c5.1-0.3,9.2-0.7,12.7-1.2l-1.9-8.7
	c-1-2.3-3-3.3-5.9-3l-5.1,0.3C532.2,231.5,529.8,233,528.5,235.5L528.5,235.5z M536.6,256.9l4.8,0.1c0.3,0,0.5-0.1,0.8-0.3l0.3-0.4
	h-1.8l-4.1-0.1L536.6,256.9L536.6,256.9z M664.7,297.2c-25.6-1.1-42.5,1.5-50.8,7.6c-7.6,4.3-13.2,11.4-17.1,21.7v0.1
	c-5,15-8.5,34-10.9,56.8h9.5c1.8-21.6,5-39.6,9.5-53.9c2.9-8.3,7.3-14,13.2-17.5l0.4-0.3c5.9-4.1,16.9-6.3,33-6.5h21.6
	c13.2-0.1,24.5,0.8,33.7,2.6c8.1,2.3,14.6,6.2,19.4,11.6l11.3,0.4c-6.2-9.8-15.7-16.5-28.3-20.1l-0.3-0.1c-11.7-2.3-26.3-3.2-44-2.6
	h-0.3L664.7,297.2L664.7,297.2z"
          />
          <path
            fill="none"
            d="M651.6,305.3c-16.1,0.1-27.1,2.3-33,6.5l-0.4,0.3c-5.9,3.4-10.3,9.2-13.2,17.5c-4.5,14.3-7.6,32.3-9.5,53.9
	l-0.3,3l14.2-7.4c0.8-15.4,6.7-28.8,17.9-39.9c2.6-2.6,5.4-5,8.3-7c10.2-7.3,21.9-11,35.2-11c13.2,0,24.9,3.7,35.1,11
	c2.9,2.1,5.6,4.4,8.3,7l2.9,3.2c5.8,0,12.4,1.2,19.9,3.6l-0.8-4.1c-1.5-9.1-4.8-16.5-10-22.3c-4.8-5.4-11.3-9.2-19.4-11.6
	c-9.2-1.8-20.5-2.8-33.7-2.6L651.6,305.3L651.6,305.3z"
          />
          <path
            fill="none"
            d="M635.5,332c-2.9,2.1-5.6,4.4-8.3,7c-11.1,11.1-17.2,24.5-17.9,39.9l-0.1,3.6c0,5.8,0.7,11.4,2.1,16.6
	c0.5,2.2,1.4,4.4,2.2,6.6l1.1,2.5c3,6.3,7.2,12.4,12.5,17.7c12,12,26.4,18,43.5,18c16.9,0,31.4-6.1,43.5-18c12-12,18-26.4,18-43.5
	c0-3.6-0.3-7.2-0.8-10.6c-1.8-11.1-6.5-21-14.2-29.7l-2.9-3.2c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.1-11
	C657.6,321.2,645.9,324.7,635.5,332L635.5,332z M670.8,338.4c12.2,0,22.7,4.3,31.4,12.9s12.9,19.1,12.9,31.4
	c0,12.2-4.3,22.7-12.9,31.4s-19.1,13.1-31.4,13.1s-22.7-4.4-31.4-13.1c-1.8-1.8-3.4-3.7-4.8-5.6l-1.7-2.5
	c-4.3-6.9-6.5-14.6-6.5-23.2c0-12.2,4.3-22.7,12.9-31.4C648.1,342.6,658.5,338.4,670.8,338.4L670.8,338.4z"
          />
          <path
            fill="none"
            d="M639.4,351.3c-8.7,8.7-12.9,19.1-12.9,31.4c0,8.7,2.2,16.4,6.5,23.2l1.7,2.5c1.4,1.9,3,3.9,4.8,5.6
	c8.7,8.7,19.1,13.1,31.4,13.1c12.2,0,22.7-4.4,31.4-13.1s12.9-19.1,12.9-31.4c0-12.2-4.3-22.7-12.9-31.4
	c-8.7-8.7-19.1-12.9-31.4-12.9S648.1,342.6,639.4,351.3L639.4,351.3z M670.8,340.6l4.4,0.3h0.1l0.5,0.1c9.4,1.1,17.6,5.1,24.6,12.1
	c3.9,3.9,6.7,8,8.8,12.5c1.4,2.9,2.3,5.9,2.9,9.1c0.4,2.6,0.7,5.2,0.7,8.1c0,11.7-4.1,21.6-12.4,29.9L699,414
	c-2.5,2.3-5.2,4.3-8.1,5.8c-6.1,3.3-12.8,5.1-20.2,5.1c-7.6,0-14.4-1.8-20.6-5.2c-2.8-1.6-5.5-3.7-8-6.1l-1.1-1
	c-8.3-8.3-12.4-18.2-12.4-29.9c0-3,0.3-5.9,0.8-8.8c1.7-8,5.5-14.9,11.6-21c7.2-7.2,15.5-11.1,25.3-12.1h0.4L670.8,340.6
	L670.8,340.6z"
          />
          <path
            fill="none"
            d="M675.2,340.7l-4.4-0.3l-4,0.1c1.1,0.1,1.7,1,1.4,2.5l-0.7,25.2v0.4l3.4-0.4c1.4,0,2.8,0.1,4,0.5v-0.5l-0.7-25.2
	C673.9,341.8,674.3,341,675.2,340.7L675.2,340.7z"
          />
          <path
            fill="none"
            d="M674.1,343.2l0.7,25.2l4.1-3.2l-0.3-5.2l-0.5-11.1c-0.3-1.2,0.3-1.9,1.5-2.1l0.4,0.1c4,1,7.8,2.6,11.3,5.1
	c1.5,1,2.9,2.2,4.3,3.4l1.2,1.1c2.1,2.1,3.9,4.4,5.4,6.7c0.4,1.5,0,2.2-1.4,2.5l-10.6,2.9l-5.2,1.4l-1.7,4.8l24.1-7.2
	c1.5-0.3,2.1-1,1.8-2.2v-0.3c-2.1-4.5-5-8.7-8.8-12.5c-7-7-15.1-11-24.6-12.1H675C674.3,341,673.9,341.8,674.1,343.2L674.1,343.2z
	 M666.6,340.7h-0.4c-9.8,1-18.2,5-25.3,12.1c-6.1,6.1-9.9,13.1-11.6,21l0.3-0.3c0.4-1.1,1.4-1.4,2.8-0.7l23.7,8.4l0.3,0.1
	c0.3-2.6,1.1-5,2.5-6.9l-0.5-0.1l-24.1-7.2c-1.4-0.3-2.1-0.8-1.9-1.8l0.3-0.5l-0.3,0.5c-0.1,1,0.5,1.6,1.9,1.8l24.1,7.2l-1.7-4.8
	h-0.3l-6.7-1.8l-8.7-2.3c-1.4-0.1-1.8-1-1.4-2.2l0.1-0.1c1.5-2.2,3.2-4.3,5.1-6.2c2.8-2.8,5.8-5.1,9.1-6.7c1.5-0.8,3-1.5,4.5-2.1
	c1.2-0.4,2.5-0.8,3.7-1c1.5,0,2.1,0.7,1.9,2.1l-0.3,4.1l-0.3,6.7l-0.3,5.4l4.1,3.2l0.7-25.2C668.3,341.7,667.9,340.8,666.6,340.7
	L666.6,340.7z"
          />
          <path
            fill="none"
            d="M675.8,340.8l-0.5-0.1h-0.1 M662.2,346.8c-1.2,0.3-2.5,0.5-3.7,1l5.4,5.2l0.3-4.1
	C664.3,347.6,663.7,346.9,662.2,346.8L662.2,346.8z M658.5,347.9c-1.5,0.6-3.2,1.2-4.5,2.1l9.6,9.9l0.3-6.7L658.5,347.9L658.5,347.9
	z M644.8,356.7c-1.9,1.9-3.6,4-5.1,6.2v0.1c-0.4,1.2,0.1,2.1,1.4,2.2l8.7,2.3l0.7-1.5h3l0.3-0.3c2.9-2.9,6.1-4.8,9.6-5.9l-9.6-9.9
	C650.7,351.6,647.7,353.8,644.8,356.7L644.8,356.7z M629.8,373.6l-0.3,0.3c-0.5,2.8-0.8,5.8-0.8,8.8c0,11.7,4.1,21.6,12.4,29.9
	l1.1,1c0.1,0,0.3,0.1,0.4,0.3c1,0.4,1.7,0,2.3-1.2l14.2-20.8H654l-4.3,6.6l-0.7,1l-4,6.1c-0.5,1.2-1.5,1.4-2.6,0.7l-0.1-0.1
	c-2.9-3.4-5.1-7.3-6.5-11.4c-0.8-2.3-1.4-4.8-1.7-7.4c-0.1-1.5-0.3-2.9-0.3-4.4l0.1-3c0.5-1.4,1.4-1.8,2.6-1.1l8.1,3.2l7.2,2.8
	l4.1-3l-23.7-8.4C631.1,372.2,630.2,372.5,629.8,373.6L629.8,373.6z"
          />
          <path
            fill="none"
            d="M634,382.7c0,1.5,0.1,3,0.3,4.4h12.2v-2.3c-1.5-0.4-2.1-1.4-1.7-2.8v-0.3l-8.1-3.2c-1.2-0.5-2.1-0.3-2.6,1.1
	L634,382.7L634,382.7z M653.6,366h2.1l0.8,3.3h0.3c0.5-1.1,1.5-1.9,2.5-2.5l0.8-0.5c1-0.6,2.1-1,3.2-1.2l0.3-5.4
	c-3.6,1.1-6.9,3-9.6,5.9L653.6,366L653.6,366z"
          />
          <path
            fill="none"
            d="M649.7,367.5l6.7,1.9l-0.8-3.4h-5.2 M644.8,382c-0.4,1.4,0.1,2.3,1.7,2.8v2.3c0.1,2.1,0.4,4,1,5.9
	s1.4,3.7,2.3,5.4l4.3-6.6c-0.7-0.8-1.2-1.6-1.5-2.6l-0.1-0.3l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4l-7.2-2.8L644.8,382L644.8,382z
	 M656.7,369.5l1.7,4.8l0.5,0.1c0.5-0.7,1.1-1.4,1.7-1.9c1.9-1.9,4.3-3.3,6.7-3.9v-0.4l-4.1-3.2c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.5
	C658.2,367.5,657.4,368.3,656.7,369.5L656.7,369.5z"
          />
          <path
            fill="none"
            d="M667.3,369.5c0,1.6-0.7,3-2.1,4c-0.4,0.3-1,0.5-1.7,0.8c-1.2,0.8-2.6,1-4.1,0.4l-0.7-0.3
	c-1.5,2.1-2.3,4.4-2.5,6.9l0.8,0.3c1.5,0.5,2.6,1.5,3,3c0.1,0.5,0.3,1.2,0.4,1.9c0.3,1.4,0,2.8-1,4l-0.4,0.7l1.2,1.4
	c1.4,1.4,2.9,2.5,4.5,3.2l0.5-0.8c1-1.4,2.2-1.9,3.7-2.1c0.5,0,1.2,0.1,2.1,0.3c1.5,0.1,2.8,0.8,3.7,2.1l0.4,0.7
	c1.9-0.7,3.6-1.8,5.2-3.4l0.8-0.8l-0.5-0.8c-1-1.4-1.2-2.9-0.7-4.4l0.8-1.5c0.5-1.4,1.5-2.3,3-2.9l0.7-0.3c-0.1-2.6-0.8-5-2.1-7
	l-0.8,0.3c-1.5,0.6-2.9,0.3-4.1-0.5c-0.5-0.4-1.1-0.8-1.5-1.5c-1.1-1-1.8-2.2-1.8-3.9v-0.7c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4v1.2
	L667.3,369.5L667.3,369.5z M676.3,377.2c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3
	c-2.1,0-4-0.8-5.5-2.3c-1.5-1.5-2.2-3.3-2.2-5.5s0.8-4,2.2-5.5c1.5-1.5,3.3-2.2,5.5-2.2S674.8,375.6,676.3,377.2L676.3,377.2z"
          />
          <path
            fill="none"
            d="M670.8,375c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3
	c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5C674.8,375.6,673,375,670.8,375L670.8,375z M667.3,368.6
	c-2.5,0.5-4.8,1.9-6.7,3.9c-0.7,0.7-1.2,1.2-1.7,1.9l0.7,0.3c1.5,0.5,2.9,0.3,4.1-0.4c0.5-0.3,1.1-0.4,1.7-0.8c1.4-1,2.1-2.2,2.1-4
	v-0.8L667.3,368.6L667.3,368.6z M674.8,369.5c0,1.6,0.5,2.9,1.8,3.9c0.5,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3
	c-0.5-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.1-3.7v0.5H674.8L674.8,369.5z"
          />
          <path
            fill="none"
            d="M674.8,368.9c2.3,0.5,4.3,1.8,6.1,3.7c0.8,0.8,1.5,1.6,2.1,2.5l0.4-0.1l1.7-4.8c-0.6-1.1-1.4-1.9-2.2-2.8
	l-0.5-0.4c-1-0.8-2.1-1.4-3.3-1.8l-4.1,3.2L674.8,368.9L674.8,368.9z M656.2,381.3l-4.1,3c-0.1,1.1,0,2.2,0.3,3.4l0.3,1.1l0.1,0.3
	c0.3,1,0.8,1.9,1.5,2.6h5.1l0.3-0.4c-1.9-2.5-3-5.4-3-8.8v-1.2H656.2L656.2,381.3z"
          />
          <path
            fill="none"
            d="M657.3,381.7l-0.8-0.3v1.2c0,3.3,1,6.3,3,8.8l0.4-0.7c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9
	C659.9,383.2,658.9,382.2,657.3,381.7L657.3,381.7z"
          />
          <path
            fill="none"
            d="M665.3,395.9c-1.7-0.7-3.2-1.8-4.5-3.2l-1.2-1.4l-0.3,0.4l-14.3,20.8c-0.7,1.2-1.5,1.6-2.3,1.2
	c-0.1-0.1-0.3-0.3-0.4-0.3c2.5,2.3,5.2,4.4,8,6.1l-0.6-0.5c-0.8-0.8-0.8-1.7,0.3-2.8l15.3-19.9L665.3,395.9L665.3,395.9z"
          />
          <path
            fill="none"
            d="M665.1,396.1l1.5,5c1,0.4,2.2,0.8,3.3,1h1.1c1.1,0,2.2-0.1,3.3-0.5l1.5-5l-0.3-0.3c-1.5,0.5-3.2,0.8-5,0.8
	c-1.9,0-3.9-0.4-5.5-1.1L665.1,396.1L665.1,396.1L665.1,396.1z"
          />
          <path
            fill="none"
            d="M665.3,395.9c1.7,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8l-0.4-0.7c-1-1.2-2.2-1.9-3.7-2.1
	c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1L665.3,395.9L665.3,395.9z M676,396.4l13.6,17.9c1.8-1.1,3.4-2.3,5-3.6l-12.7-18.4
	l-0.1-0.3l-0.8,0.8c-1.5,1.5-3.3,2.6-5.2,3.4L676,396.4L676,396.4z M671.2,401.9h-1.1c-1.2-0.1-2.3-0.4-3.3-1l-3.4,4.3
	c2.3,0.8,5,1.1,7.6,1.1c2.5,0,4.8-0.3,7-1l-3.3-4.1C673.4,401.8,672.3,402.1,671.2,401.9L671.2,401.9z"
          />
          <path
            fill="none"
            d="M666.6,401l-1.5-5l-15.3,19.9c-1.1,1.1-1.1,2.1-0.3,2.8l0.5,0.5c6.2,3.6,13.1,5.2,20.6,5.2
	c7.4,0,14.2-1.6,20.2-5.1l0.7-0.7c0.7-0.8,0.5-1.6-0.4-2.6l-1.7-2.2L676,396.1l-1.5,5l3.3,4.1l6.9,8.5c1,1,1,1.9-0.3,2.8
	c-4.1,1.6-8.7,2.5-13.6,2.5c-5,0-9.5-0.8-13.8-2.6c-1.4-1.1-1.5-2.2-0.5-3.2l6.7-8.4L666.6,401L666.6,401z"
          />
          <path
            fill="none"
            d="M663.2,405.2l-6.7,8.4c-1,1-0.7,2.1,0.5,3.2c4.3,1.6,8.8,2.6,13.8,2.6c4.8,0,9.4-0.8,13.6-2.5
	c1.2-1,1.4-1.8,0.3-2.8l-6.9-8.5c-2.2,0.7-4.5,1-7,1C668,406.5,665.5,406,663.2,405.2L663.2,405.2z M646.4,387.1h-12.2l13.2,5.9
	C646.8,391,646.5,389.1,646.4,387.1z"
          />
          <path
            fill="none"
            d="M647.4,393l-13.2-5.9c0.3,2.6,0.8,5.1,1.7,7.4c4.1,2.9,8.5,4.5,13.2,5l0.7-1C648.8,396.7,647.9,394.9,647.4,393
	z"
          />
          <path
            fill="none"
            d="M642.6,406c1.1,0.8,2.1,0.6,2.6-0.7l4-6.1c-4.7-0.4-9.1-2.1-13.2-5C637.3,398.6,639.4,402.5,642.6,406
	L642.6,406z M695.5,355.4l-11.8,7c1.4,1,2.8,2.1,4,3.3c1,1,1.8,1.9,2.5,2.9l10.6-2.9c1.4-0.1,1.8-1,1.4-2.5
	c-1.5-2.3-3.3-4.5-5.4-6.7L695.5,355.4L695.5,355.4z"
          />
          <path
            fill="none"
            d="M691.3,352l-12.5,8c1.8,0.5,3.4,1.5,5,2.5l11.8-7C694.1,354.2,692.6,353.1,691.3,352L691.3,352z M680,346.9
	l-0.4-0.1c-1.2,0.1-1.7,0.8-1.5,2.1l0.5,11.1l12.5-8C687.7,349.6,684,348,680,346.9L680,346.9z"
          />
          <path
            fill="none"
            d="M682.7,367.4c0.8,0.7,1.7,1.7,2.2,2.6l5.2-1.4c-0.8-1-1.7-2.1-2.5-2.9c-1.2-1.2-2.6-2.3-4-3.3
	c-1.5-1-3.3-1.9-5-2.5l0.3,5.2c1.2,0.3,2.3,1,3.3,1.8L682.7,367.4L682.7,367.4z M694.6,383.1l5.2-1.9l5.2-2.1
	c1.1-0.5,1.9-0.3,2.3,0.8v0.4c0,0.8,0.1,1.5,0.1,2.3c0,2.1-0.1,4-0.4,5.9c-0.6,3.9-1.7,7.4-3.4,10.9c-1.4,2.5-2.9,5-5,7.2
	c-1.2,0.8-2.2,0.7-2.8-0.5l-3-4.5l-3-4.5l-2.9-4.5h-5.2l12.7,18.4l1.5,2.2c0.7,1.4,1.5,1.8,2.6,1.1l0.3-0.1l1.5-1.4
	c8.3-8.3,12.4-18.2,12.4-29.9c0-2.8-0.3-5.5-0.7-8.1l-0.1-0.5c-0.5-1-1.4-1-2.6-0.4l-23.7,8.4l4.1,3L694.6,383.1L694.6,383.1z"
          />
          <path
            fill="none"
            d="M689.7,384.9c0.1,1.2-0.1,2.5-0.7,3.7l-0.3,0.7c-0.4,1-1.1,1.9-1.9,2.9l2.9,4.5c0.7-1,1.2-1.8,1.8-2.8
	c1.8-3.3,2.8-6.9,2.8-10.9L689.7,384.9L689.7,384.9z M681.4,385.1l-0.8,1.5c-0.5,1.5-0.4,3,0.7,4.4l0.5,0.8c2.3-2.6,3.4-5.8,3.4-9.2
	v-0.5l-0.7,0.3C682.9,382.8,681.9,383.8,681.4,385.1z M685.1,382.1l0.5-0.1l23.7-8.4c1.2-0.7,2.1-0.5,2.6,0.4l0.1,0.5
	c-0.5-3.2-1.5-6.3-2.9-9.1v0.3c0.3,1.1-0.3,1.9-1.8,2.2l-24.1,7.2l-0.4,0.1C684.2,377.2,685.1,379.5,685.1,382.1L685.1,382.1z"
          />
          <path
            fill="none"
            d="M685.1,382.7c0,3.6-1.1,6.6-3.4,9.2l0.1,0.3h5.2c0.8-1,1.5-1.9,1.9-2.9l0.3-0.7c0.5-1.2,0.7-2.5,0.7-3.7l-4.1-3
	l-0.5,0.1L685.1,382.7L685.1,382.7z M699.8,381.1l-0.5,11.3c2.6-1,5.1-2.2,7.7-3.9c0.3-1.9,0.4-3.9,0.4-5.9c0-0.8,0-1.5-0.1-2.3
	v-0.4c-0.4-1.1-1.2-1.4-2.3-0.8L699.8,381.1L699.8,381.1z"
          />
          <path
            fill="none"
            d="M707.1,388.4c-2.6,1.6-5.1,2.9-7.7,3.9c-2.5,0.8-5,1.4-7.6,1.6c-0.6,1-1.1,1.9-1.8,2.8l3,4.5
	c3.3,0.3,6.9-0.3,10.6-2.1C705.3,395.9,706.5,392.3,707.1,388.4L707.1,388.4z"
          />
          <path
            fill="none"
            d="M703.6,399.3c-3.9,1.6-7.4,2.3-10.6,2.1l3,4.5c0.6,1.2,1.5,1.5,2.8,0.5C700.6,404.1,702.3,401.8,703.6,399.3
	L703.6,399.3z M694.6,383.1c-0.1,4-1,7.6-2.8,10.9c2.5-0.1,5.1-0.7,7.6-1.6l0.5-11.3L694.6,383.1L694.6,383.1z M691,419.7
	c2.9-1.5,5.5-3.6,8.1-5.8l-0.3,0.1c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2c-1.5,1.4-3.3,2.6-5,3.6l1.7,2.2c1,1,1.1,1.8,0.4,2.6L691,419.7
	L691,419.7z M609.4,379.1l-14.2,7.4l-0.3,4.1H284.1v7.2h317.6l1.1,1.4h8.7c-1.4-5.2-2.1-10.7-2.1-16.6L609.4,379.1L609.4,379.1z"
          />
          <path fill="none" d="M595.5,383.5H586l-0.7,7v0.1h9.6l0.3-4.1" />
          <path
            fill="none"
            d="M398,383.5H283.4l0.7,7.2h301.3v-0.1l0.7-7h-9.5 M751.5,289.4l7.2,31.5l19.8,1.1l1.8,0.1h0.7l15.4,1.8h0.3
	l3.4,0.5l-0.3-3v-0.1l-1.2-13.5l-1.8-11.7l-2.5-10.5l-18-1.9H754C751.9,283.6,751.1,285.5,751.5,289.4L751.5,289.4z M755,284.4
	l4.8,0.5l5.9,34.3l-6.1-0.5l-6.5-29.2C752.3,286.4,752.9,284.7,755,284.4L755,284.4z M772.1,289.4l12,0.7v18.7l-17.9,0.7l-4-23
	l9.9,1V289.4z M766.2,311.3l27,1.5h0.3c0.8,0.3,1.2,1,1.2,2.1v3.6c0.1,1.5,0.3,2.6,0.7,3.4l-1.8-0.1l-10.7-0.8l-15.8-1.4
	L766.2,311.3L766.2,311.3z"
          />
          <path
            fill="none"
            d="M753.2,289.5l6.5,29.2l6.1,0.5l-5.9-34.3l-4.8-0.5C752.9,284.7,752.3,286.4,753.2,289.5L753.2,289.5z
	 M762.1,286.4l4,23.1l18-0.7v-18.7l-12-0.7v-2.1 M766.9,319.5l15.8,1.4l10.7,0.8l1.8,0.1c-0.4-0.8-0.7-1.9-0.7-3.4v-3.6
	c0-1.1-0.3-1.8-1.2-2.1h-0.3l-27-1.5L766.9,319.5L766.9,319.5z M773.5,316.1c0-0.7,0.1-1.2,0.5-1.6c0.3-0.4,0.8-0.7,1.2-0.7
	c0.5,0,1,0.3,1.2,0.7c0.3,0.4,0.5,1,0.5,1.6s-0.1,1.2-0.5,1.6c-0.3,0.4-0.8,0.7-1.2,0.7c-0.5,0-1-0.3-1.2-0.7
	C773.7,317.3,773.5,316.8,773.5,316.1L773.5,316.1z M790.7,315l0.3,0.1l0.3,0.3c0.3,0.4,0.5,1,0.5,1.6s-0.1,1.2-0.5,1.6L791,319
	l-0.3,0.1l-0.7,0.3h-0.4c-0.4-0.1-0.7-0.3-1-0.7l-0.1-0.1c-0.3-0.4-0.4-1-0.4-1.5v-0.3l0.1-0.7l0.4-0.7l0.1-0.1l0.4-0.4l0.3-0.1
	l0.5-0.1C790.2,314.7,790.4,314.7,790.7,315L790.7,315z"
          />
          <path
            fill="none"
            d="M773.9,317.7c0.3,0.4,0.8,0.7,1.2,0.7c0.6,0,1-0.3,1.2-0.7s0.5-1,0.5-1.6s-0.1-1.2-0.5-1.6
	c-0.3-0.4-0.8-0.7-1.2-0.7c-0.5,0-1,0.3-1.2,0.7c-0.3,0.4-0.5,1-0.5,1.6C773.5,316.8,773.7,317.3,773.9,317.7L773.9,317.7z
	 M789.9,314.7l-0.5,0.1l-0.3,0.1l-0.4,0.4l-0.1,0.1l-0.4,0.7l-0.1,0.7v0.3c0,0.5,0.1,1.1,0.4,1.5l0.1,0.1c0.3,0.4,0.7,0.5,1,0.7h0.3
	l0.7-0.3l0.3-0.1l0.3-0.3c0.3-0.4,0.5-1,0.5-1.7c0-0.7-0.1-1.2-0.5-1.6l-0.3-0.3l-0.3-0.1C790.4,314.8,790.2,314.7,789.9,314.7
	L789.9,314.7z M726.3,319.5c5.1,5.6,8.4,13.1,10,22.3l0.8,4.1c2.1,9.9,3.6,20.2,4.7,31.2c0.5,5.1,1,10.3,1.2,15.7v2.8h19.5l16.8-2.8
	l14.6-1c6.9-8.9,12.4-19,16.6-30l0.4-1.5l-62.3-1.8c-1.1-6.5-2.3-12.7-3.7-18.7c-0.8-4.3-1.9-8.3-3.4-11.8c-1.1-2.9-2.5-5.5-4.1-8.1
	L726.3,319.5L726.3,319.5z M780.4,381.1v-0.7l0.3-1.4c1.1-4,3.2-7.7,6.3-11.3l0.6-0.7l0.5-0.4c1.8-1.5,3.9-2.2,6.2-2.2h6.6
	c1.5-0.3,2.2,0.5,2.1,2.3c-1.8,4.7-4.8,10.2-9.2,16.5c-1.4,1.6-3,2.3-5,1.9l-4.5-0.7c-2.1-0.5-3.2-1.5-3.6-2.8L780.4,381.1z"
          />
          <path
            fill="none"
            d="M780.4,381.1l0.1,0.7l0.8,0.1c1,0,2.1-0.8,3.4-2.3c1.2-1.5,2.3-3.3,3.2-5.5c0.8-2.2,1.1-4,1-5.5
	c-0.1-1-0.3-1.5-0.7-1.9l-0.6,0.4l-0.5,0.7c0.1,0.3,0.3,0.8,0.3,1.4c0,1.4-0.3,3-1,5.1c-0.8,1.9-1.7,3.6-2.8,5
	c-1.1,1.4-2.1,2.1-2.8,2.1L780.4,381.1L780.4,381.1z"
          />
          <path
            fill="none"
            d="M780.7,381.1c0.8,0,1.7-0.7,2.8-2.1c1.1-1.4,1.9-3,2.8-5c0.7-1.9,1.1-3.6,1-5.1c0-0.6-0.1-1-0.3-1.4
	c-3.2,3.6-5.2,7.4-6.3,11.3l-0.3,1.4v0.7L780.7,381.1L780.7,381.1z"
          />
          <path
            fill="none"
            d="M780.5,381.7c0.4,1.4,1.5,2.2,3.6,2.8l4.5,0.7c2.1,0.4,3.7-0.3,5-1.9c4.4-6.3,7.6-11.8,9.2-16.5
	c0.1-1.8-0.5-2.6-2.1-2.3h-6.6c-2.2,0-4.3,0.7-6.2,2.2c0.4,0.4,0.5,1,0.7,1.9c0.1,1.5-0.1,3.3-1,5.5c-0.8,2.2-1.8,4-3.2,5.5
	c-1.2,1.5-2.5,2.3-3.4,2.3L780.5,381.7L780.5,381.7z M747.7,331.5l42.8,1.5h0.3l3.7,0.1h0.4l8,0.7l7.7,1.2h0.1l-0.1-0.1l-1.9-2.9
	l-0.6-0.7l-0.1-0.3l-0.8-1l-0.3-0.4l-0.3-0.3l-0.1-0.3l-1.5-1.4l-0.1-0.1l-0.4-0.4l-0.3-0.3l-0.8-0.5l-0.1-0.1l-0.3-0.3l-2.5-1.6
	l-3.4-0.6h-0.3l-15.4-1.8h-0.7l-1.8-0.1l-19.8-1.1l-21-0.8c1.5,2.5,2.9,5.2,4.1,8.1C743.4,330.2,745.3,331.3,747.7,331.5
	L747.7,331.5z M717.1,342.4c7.7,8.7,12.4,18.6,14.2,29.7l10.5,5.1c-1.1-11-2.6-21.5-4.7-31.2C729.5,343.4,722.9,342.2,717.1,342.4
	L717.1,342.4z"
          />
          <path
            fill="none"
            d="M794.3,285.5l2.5,10.5l1.8,11.7l1.2,13.5v0.1l0.3,3l2.5,1.6l-0.1-3.6v-2.2c-0.1-1.9-0.3-3.7-0.4-5.6l-0.1-1.5
	c-0.1-2.1-0.4-4-0.7-6.1l-0.3-1.5c-0.3-1.6-0.4-3.3-0.8-5l-0.1-0.5l-0.5-2.8l-2.8-11.6L794.3,285.5L794.3,285.5z M799,286.4h4.3
	l0.5-0.1h2.3l4.3-0.1l-0.8-2.8H797l-0.3,2.3l2.8,11.6l1,0.1v-0.1l-1.1-5l-0.3-1.2l-1.2-4.7h1"
          />
          <path fill="none" d="M799.1,291l0.4-3.3l-0.7-1.4h-1" />
          <path
            fill="none"
            d="M799.8,286.4h-0.3l-0.1,1l-0.4-1h-0.3l0.7,1.4l-0.3,3.3l0.3,1.2l1.4,3l-0.3,2.1v0.1h0.1l0.3-1.8l0.8,1.8h0.3
	l-1-2.2l0.4-3.3c0.5,1.1,1,2.2,1.5,3.3l-0.3,2.3h0.3l0.3-1.9l0.8,1.9h0.3l-1-2.5l0.4-3.3l1.5,3.3l-0.3,2.5h0.3l0.3-2.1l0.8,2.1h0.3
	l-1.1-2.6l0.4-3.3l1.5,3.3l-0.4,2.6h0.3l0.3-2.2l1,2.3h0.3l-1.2-2.8l0.4-3.3l1.1,2.6l-0.3-1.2l-0.8-1.8v-0.7l-0.1-0.7l-0.1,1
	c-0.5-1.1-1-2.2-1.5-3.3l0.1-1.4v-0.1h-0.1l-0.1,1.1l-0.5-1.1h-0.3l0.7,1.5l-0.3,3.3l-1.5-3.3l0.1-1.5h-0.3l-0.1,1l-0.4-1h-0.3
	l0.7,1.4l-0.3,3.3l-1.5-3.3l0.1-1.4H802l-0.1,1l-0.4-1h-0.3l0.7,1.4l-0.3,3.3l-1.5-3.3L799.8,286.4L799.8,286.4z M803.9,288.1
	l1.5,3.3l-0.4,3.3l-1.5-3.3C803.8,290.4,803.8,289.3,803.9,288.1L803.9,288.1z M801.2,291.4l-0.4,3.3l-1.5-3.3l0.3-3.3L801.2,291.4z
	 M801.9,288.1l1.5,3.3l-0.4,3.3l-1.5-3.3L801.9,288.1L801.9,288.1z M806.1,288.1l1.5,3.3l-0.4,3.3l-1.5-3.3L806.1,288.1L806.1,288.1
	z"
          />
          <path
            fill="none"
            d="M803.6,291.5l1.5,3.3l0.4-3.3l-1.5-3.3C803.8,289.3,803.8,290.4,803.6,291.5z M799.4,291.5l1.4,3.3l0.4-3.3
	l-1.5-3.3 M803,294.8l0.4-3.3l-1.5-3.3l-0.4,3.3 M807.2,294.8l0.4-3.3l-1.5-3.3l-0.4,3.3 M801.3,291l0.3-3.3l-0.7-1.4h-1.1l-0.1,1.4
	 M799.5,286.4H799l0.5,1 M803.9,287.7l1.7,3.3l0.3-3.3l-0.7-1.5h-1.1 M803.8,287.3v-1.1l-0.5,0.1 M802,286.4l-0.1,1.4l1.5,3.3
	l0.4-3.3l-0.8-1.4 M801.2,286.4l0.4,1l0.1-1 M802.5,297.5l0.3-2.3c-0.4-1.1-1-2.2-1.5-3.3l-0.4,3.3l1,2.2L802.5,297.5L802.5,297.5z"
          />
          <path
            fill="none"
            d="M802,297.5h-0.3l-1-0.1h-0.1l-0.8-0.1l0.5,2.8h1l12.2,0.7l0.8,6.5c0.3,1.4,0.7,2.5,1.2,3.4l0.1,0.3h0.3l0.3-0.1
	c0.4-0.3,0.7-1.1,0.4-2.3l-1.1-0.3l-0.4-5.5l-0.1-1.8l-0.5-2.6l-4.5-0.3h-1.4l-1.2-0.1h-1l-1.2-0.1h-1.1l-1.1-0.1L802,297.5
	L802,297.5L802,297.5z"
          />
          <path
            fill="none"
            d="M800.9,295.6l-0.3,1.8l1.1,0.1 M799.4,292.3l1.1,5l0.3-2.1 M805,295.2l-1.5-3.3l-0.4,3.3l1.1,2.5h0.4
	 M803.1,295.6l-0.3,1.9l1.1,0.1 M806.1,297.8l-1-2.2l-0.3,2.1 M800.2,300h-0.1l0.1,0.5 M800.2,300.5l0.8,5l0.1,0.4l0.4-2.5l-0.3-1.9
	l-0.5-1.5h-0.4L800.2,300.5L800.2,300.5z M800.9,300.1l0.1,0.3v-0.3"
          />
          <path
            fill="none"
            d="M801,300.4l0.3,1.1l0.3,1.9l0.1,0.7c0.3,1.7,0.5,3.4,0.7,5.2l0.1,0.8h0.3l1.8,0.1h0.4l1.7,0.1h0.4l1.7,0.1h0.5
	l1.5,0.1h1l3.6,0.3l-0.1-0.3c-0.6-0.8-0.8-2.1-1.2-3.4l-0.8-6.5l-12.2-0.7L801,300.4L801,300.4z"
          />
          <path
            fill="none"
            d="M800.6,300.1l0.7,1.4l-0.3-1.1l-0.1-0.3 M801.2,306.9l1.4,3.3l-0.7,2.9l0.1,1.7l1,2.9l-0.5,2.6v0.8l0.7-3
	l0.1,0.1l-0.1-1.1v0.1l-1.2-3.3l0.7-3.2v-0.5l-0.1-0.8l-1.1-3l0.4-2.2l-0.1-0.7l-0.4,2.5l-0.3-0.4l0.3,1.5"
          />
          <path
            fill="none"
            d="M801.3,306.3l1.1,2.9c-0.3-1.8-0.4-3.4-0.7-5.2L801.3,306.3L801.3,306.3z M801.2,307c0.3,1.9,0.5,4,0.7,6.1
	l0.7-2.9l-1.2-3.3L801.2,307L801.2,307z M806,286.2h-0.5l0.4,1.1 M806.1,287.7c0.5,1.1,1.1,2.2,1.5,3.3l0.1-1
	c-0.4-1.4-1-2.6-1.5-3.7L806.1,287.7L806.1,287.7z M807.9,291.5l0.7,1.8l-0.7-2.5 M807.4,295.2l1.2,2.8h0.3l0.3-2.3l-0.1-1.1
	l-1.2-2.6 M807.2,295.2l-1.5-3.3l-0.4,3.3l1.1,2.6h0.4 M808.3,297.9l-1-2.3l-0.3,2.2 M809.4,296.4l-0.3-0.8l-0.3,2.3h0.3
	 M809.7,297.9l-0.3-1.5l-0.3,1.5 M814.8,300.9l1.1,0.3l-0.1-1.8l-1.5-1.1 M815.3,308.2l1.1,0.3l-0.5-7.3l-1.1-0.3l0.1,1.7v0.1"
          />
          <path
            fill="none"
            d="M806.7,310.4l-0.5,2.9l-1.1-3h-0.4l-0.7,3l-1.2-3.2h-0.3v0.5v-0.1l1.2,3.3L803,317l0.1,1.2l1.1,3.2l-0.7,2.9v1
	l0.8-3.3l1.1,3.3l-0.8,2.8l0.1,0.1l0.7-2.5l1,3.3l-0.1,0.5l0.1,0.3l0.1-0.3l0.1,0.5l0.3,0.4l-0.4-1.4l1-3.3l1,3.3l-0.7,2.3l0.1,0.3
	l0.7-2.1c0.3,1,0.5,2.1,0.8,3l0.4,0.1l1-3.2l1,3.3l-0.1,0.3l0.4,0.1l-0.1-0.4l0.5-1.8v-1l-0.7,2.2l-0.8-3.3l1-3.3l0.7,2.3v-0.8
	l-0.6-2.1l0.5-2.1v-0.8l-0.7,2.6l-1-3.3l0.8-3.3l0.8,2.5v-0.8l-0.7-2.1l0.4-1.9l-0.1-0.8l-0.5,2.5l-1.1-3.3l0.5-3h-0.3l-0.5,2.8
	l-1-2.8H809l1.2,3.2l-0.8,3.3l-1.1-3.3c0.3-1.1,0.4-2.2,0.7-3.3h-0.1l-0.5,2.9l-1.1-2.9L806.7,310.4L806.7,310.4z M808.2,313.9
	l-0.7,3.3l-1.1-3.3l0.7-3.3L808.2,313.9L808.2,313.9z M808.3,314.3l1.1,3.3l-0.8,3.3l-1.1-3.3L808.3,314.3z M806.1,314.3l1.1,3.3
	l-0.8,3.3l-1.1-3.3L806.1,314.3L806.1,314.3z M805.3,317.2l-1.1-3.3l0.7-3.3l1.2,3.3L805.3,317.2z M811.6,317.6l-0.8,3.3l-1.1-3.3
	l0.8-3.3L811.6,317.6z M805.6,324.6l-1-3.3l0.8-3.3c0.4,1.1,0.8,2.2,1.1,3.3L805.6,324.6L805.6,324.6z M806.5,321.7l1.1,3.3l-1,3.3
	l-1-3.3L806.5,321.7L806.5,321.7z M806.7,321.3l0.8-3.3l1.1,3.3l-0.8,3.3L806.7,321.3L806.7,321.3z M809.7,325l-1,3.3l-1-3.3
	l0.8-3.3L809.7,325L809.7,325z M808.7,321.3l0.8-3.3l1.1,3.3l-0.8,3.3L808.7,321.3z M811.9,325l-1,3.3l-1-3.3l0.8-3.3L811.9,325
	L811.9,325z M808.9,328.7l1-3.3l1,3.3l-1,3.3L808.9,328.7L808.9,328.7z M805.2,317.6l-0.8,3.3l-1.1-3.3l0.7-3.3L805.2,317.6z"
          />
          <path
            fill="none"
            d="M806.3,313.7l1.1,3.4l0.8-3.3l-1.2-3.3 M808.6,320.9l0.8-3.3l-1.1-3.3l-0.8,3.3 M806.5,320.9l0.8-3.3l-1.2-3.3
	l-0.7,3.3 M804.7,310.6l-0.7,3.3l1.2,3.3l0.7-3.3 M809.7,317.6l1.1,3.3l0.8-3.3l-1.2-3.3 M806.4,321.3c-0.3-1.1-0.7-2.2-1.1-3.3
	l-0.8,3.3l1,3.3L806.4,321.3L806.4,321.3z M806.7,328.3l1-3.3l-1.1-3.3l-0.8,3.3 M808.6,321.3l-1.2-3.3l-0.7,3.3l1,3.3 M807.8,325
	l1,3.3l1-3.3l-1-3.3 M810.7,321.3l-1.1-3.3l-0.8,3.3l1.1,3.3 M810.8,321.7L810,325l1,3.3l1-3.3 M810.8,328.7l-1-3.3l-1,3.3l1,3.3
	 M803.2,317.6l1.1,3.3l0.8-3.3l-1.2-3.3 M809,310.6c-0.1,1.1-0.4,2.2-0.7,3.3l1.1,3.3l0.8-3.3L809,310.6L809,310.6z M808.9,310.6
	l-1.8-0.1l1.1,3 M805,310.3l1.1,3l0.5-2.9 M810.9,310.7l-1.7-0.1l1.1,2.9 M812,312.8l-0.1-1l-0.3-1.1h-0.4 M810.5,313.7l1.1,3.4
	l0.5-2.5l-0.1-1.9l-0.8-2.1h-0.1 M811.9,311.8l-0.1-1.1h-0.1 M811.9,317.6l0.7,2.1l-0.3-4.1 M810.9,321.3l1.1,3.3l0.5-2.6v-1.5
	l-0.8-2.5 M812.2,325l0.5,1.9v-4 M812,325.4l-1,3.3l1,3.3l0.7-2.2v-1.9 M814.8,333.5l-2.2-0.5l-0.3-0.1l-0.4-0.1l-1.8-0.4l-0.4-0.1
	l-1-0.3l1.9,2.9l0.1,0.1l5.2,1.6l0.3-1.5c0-0.4-0.1-0.8-0.4-1.1l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.3L814.8,333.5L814.8,333.5z"
          />
          <path
            fill="none"
            d="M810.9,329.1l-1,3.2l1.8,0.4l0.1-0.3 M812.2,332.4v0.4l0.4,0.1v-2.3 M806.7,329.1l-0.1,0.4l0.3,0.1
	 M805.6,325.4l-0.7,2.5l1.5,1.4l0.1-0.5 M808,331.1l0.7-2.3l-1.1-3.3l-0.8,3.3l0.3,1.4 M808.7,332l1,0.3c-0.3-1-0.5-2.1-0.8-3
	l-0.7,2.1L808.7,332L808.7,332z M815.9,336.7l-5.2-1.6l1,2.3l4.1,1 M814.5,356.1c-1.4,0-2.5,1.4-3.4,4.3l-0.4,1.5
	c-4.3,11-9.9,20.9-16.6,30c-1.8,2.3-3.6,4.7-5.6,6.9l7.6,1.5l1.9,0.1l3-3.7c0.8-1.4,0.5-2.3-1.1-3c-1.2-0.7-1.2-1.8-0.3-3.2l0.3-0.4
	c6.6-8.9,11-17.2,13.3-24.9c0.7-2.6,1.8-3.9,3.3-3.7h5.2c-0.1-0.5-0.3-1.1-0.7-1.5l-2.6-3.3L814.5,356.1L814.5,356.1z M802.5,310.7
	c0.3,2.1,0.4,4.1,0.5,6.3l0.7-3.2l-1.2-3.3V310.7z M803.9,313.5l0.7-3.2l-1.9-0.1 M801.9,313.9l1.1,3.3V317
	c-0.1-2.2-0.3-4.3-0.5-6.3L801.9,313.9L801.9,313.9z M802.4,320.2l0.7-2.6l-1-2.9C802.1,316.5,802.3,318.4,802.4,320.2L802.4,320.2z
	 M802.4,321v0.5l1,3l0.1-0.5l-0.3-5.9l-0.1-0.1"
          />
          <path
            fill="none"
            d="M803.2,325l-0.3,1.2l0.1,0.1l0.3-1l0.1,0.5v-1.9l-0.1,0.5l-1-3l0.1,0.8"
          />
          <path fill="none" d="M803.5,326l-0.1-0.5l-0.3,1l0.8,0.7" />
          <path
            fill="none"
            d="M804.2,327.4l-0.7-2.3v1l0.4,1.1 M803.2,325l-0.7-2.6v3.6l0.4,0.3"
          />
          <path
            fill="none"
            d="M804.2,327.4l0.5,0.4l0.7-2.8l-1.1-3.3l-0.8,3.3 M803.2,318.1l0.3,5.9l0.7-2.8 M793.9,391.9l-14.6,1l-16.8,2.8
	c9.4,0.7,18,1.8,25.9,3.2C790.3,396.5,792.2,394.2,793.9,391.9z M799.5,390.5c-1,1.4-1,2.5,0.3,3.2c1.7,0.7,1.9,1.6,1.1,3l-3,3.7
	c1-0.1,1.9-0.7,2.6-1.5c7.4-7.2,13.6-17.2,18.7-30h1.2l1.1-4.5c0.1-1.1,0.3-2.1,0.1-2.8h-5.2c-1.5-0.1-2.6,1.1-3.3,3.7
	c-2.3,7.7-6.7,16-13.3,24.9L799.5,390.5L799.5,390.5z M802.7,388.3c5.5-7.7,9.1-14.7,11-21h2.6c1.1,0.1,1.1,1.4,0,3.9
	c-2.8,6.7-5.8,12.2-8.9,16.5c-0.8,1.1-1.7,1.5-2.8,1L802.7,388.3L802.7,388.3z"
          />
          <path
            fill="none"
            d="M804.6,388.7c1.1,0.4,1.9,0.1,2.8-1c3.2-4.3,6.2-9.8,8.9-16.5c1.1-2.5,1.1-3.9,0-3.9h-2.6
	c-1.9,6.3-5.6,13.3-11,21L804.6,388.7z M799,407.1c0.1-2.6-1-4.8-3-6.9l-7.6-1.5c-7.8-1.4-16.4-2.5-25.9-3.2H743v16.8l56-0.5
	L799,407.1L799,407.1z M247.1,304.8c16.2,4.8,26,16.1,29.2,33.6v0.1c0.7,2.8,1.2,5.6,1.9,8.5c1.9,9.4,3.4,19.5,4.5,30.1
	c0.3,2.1,0.4,4.1,0.7,6.2h8.4c-1.5-16.9-4-32.5-7.4-46.8c-4-21-15.8-34.4-35.8-39.9l-1.5-0.3L247.1,304.8L247.1,304.8z"
          />
          <path
            fill="none"
            d="M253.5,339.2c8.8,8.8,14.3,19,16.6,30.4l12.5,7.7c-1.1-10.7-2.8-20.8-4.5-30.1l-25.2-8.4L253.5,339.2
	L253.5,339.2z M271.4,383.5h12c-0.1-2.1-0.4-4.1-0.7-6.2l-12.5-7.7c0.8,4.1,1.2,8.5,1.2,13.1L271.4,383.5L271.4,383.5z"
          />
          <path
            fill="none"
            d="M284.1,390.6l-0.7-7.3h-12c0,2.5-0.3,4.8-0.5,7.3H284.1L284.1,390.6z M676.1,249.6c34.3,3.4,64.7,7.8,91.3,13.2
	l5.8,1.2h0.1l2.8,0.7l6.9,1.1l7,0.5l6.3-0.1h-0.1l-0.5-0.3l-8.9-2.8l-8.8-2.2l-6.6-1.4c-4.5-1-9.6-1.8-15.1-2.8
	c-12.1-1.9-26.3-4-42.5-5.8c-22.4-2.5-47-4.5-73.7-5.8c-1.9,0.1-6.6,1-14.4,2.8l-12.4,2.9c27.9,1.6,53.9,3.7,78,6.5
	c31.5,4,56,8.4,73.7,13.1c2.6,0.8,5,2.5,6.7,4.7l1,1.1c0.7,1,1.2,1.9,1.8,3l1.7,4h0.3l18,1.9l2.3,0.3l-3-3.9l-2.5-2.5l-3.6-2.6
	l-0.4-0.3l-4-1.9l-1.2-0.4l-11.6-3.3l-0.3-0.1c-29.9-8.3-63.7-14.3-101.7-18.4c-11.4-1.5-23.1-2.3-35.1-2.8c5.8-2.1,10-3,12.7-2.8
	L676.1,249.6L676.1,249.6z"
          />
          <path
            fill="none"
            d="M646.3,246.9c-2.8-0.3-7,0.7-12.7,2.8c11.8,0.4,23.5,1.4,35.1,2.8c38,4,71.8,10.2,101.7,18.4l0.3,0.1l11.6,3.3
	l1.2,0.4l4,1.9l0.4,0.3l3.6,2.6l2.5,2.5l3,3.9l0.3-2.3h12.5c-0.3-0.7-0.5-1.4-1-1.9l-2.2-4.1c-0.6-1-1.2-1.9-1.8-2.9l-2.3-3.2
	l-2.6-2.9v-0.1l-1.7-1.2c-0.4-0.3-0.8-0.4-1.4-0.7l-6.3,0.1l-7-0.5l-6.9-1.1l-2.8-0.7h-0.1l-5.8-1.2c-26.7-5.2-57.1-9.6-91.3-13.2
	L646.3,246.9L646.3,246.9z M741.6,328c1.5,3.7,2.6,7.6,3.4,11.8c1.4,6.1,2.6,12.2,3.7,18.7l62.3,1.8c1-2.9,2.2-4.3,3.4-4.3l4,0.5
	l2.3,0.3v-12.8l-5.6-2.2l-1.1-0.5l-0.7-0.5l-0.1-0.1l-0.4-0.7l-1.1-2.6l-1-2.5h-0.1l-7.7-1.2l-8-0.7h-0.4l-3.7-0.1h-0.3l-42.8-1.5
	C745.3,331.3,743.4,330.2,741.6,328L741.6,328z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M804.7,327.8l0.7-2.8l-1.1-3.3l-0.8,3.3l0.7,2.3 M803,326.3l0.3-1.2
	l-0.7-2.6 M803.1,326.4l0.3-1l0.1,0.5l0.4,1.1 M805.2,317.6l-1.2-3.3l-0.7,3.3l1.1,3.3L805.2,317.6z M802.4,320.2l0.5-2.6l-1-2.9
	 M802.4,321.6l1,3l0.1-0.5l0.7-2.8l-1-3.2l-0.1-0.1l-0.7,3 M802.7,310.2l1.2,3.3l0.7-3.2 M802.5,310.7v-0.1l1.2,3.3l-0.7,3.2v0.1
	l-1.2-3.3L802.5,310.7z M809.8,332l-1-3.3l1-3.3l1,3.3L809.8,332z M809.6,332.2c-0.3-1-0.5-2.1-0.8-3l-0.7,2.1 M806.8,329.7
	l-0.1-0.5l-0.1,0.4 M807.9,331.1l0.8-2.3l-1.1-3.3l-0.8,3.3l0.3,1.4 M806.4,329.3l0.1-0.5l-1-3.3l-0.7,2.5 M812.7,329.8L812,332
	l-1-3.3l1-3.3l0.7,2.5 M812.2,332.9v-0.4l0.4-1.8 M811.8,332.7l0.1-0.3l-1-3.3l-1,3.2 M810.8,321.7l1.1,3.3l-1,3.3l-1-3.3
	L810.8,321.7z M812.7,326.9l-0.5-1.9l0.5-2.1 M809.8,324.6l-1.1-3.3l0.8-3.3l1.1,3.3L809.8,324.6z M808.7,321.7l1,3.3l-1,3.3l-1-3.3
	L808.7,321.7z M807.6,324.6l-1-3.3l0.7-3.3l1.2,3.3L807.6,324.6z M805.7,325l0.8-3.3l1.1,3.3l-1,3.3L805.7,325z M806.4,321.3
	l-0.8,3.3l-1-3.3l0.8-3.3C805.7,319.1,806.1,320.2,806.4,321.3L806.4,321.3z M810.4,314.3l1.2,3.3l-0.8,3.3l-1.1-3.3L810.4,314.3z
	 M806,313.9l-0.7,3.3l-1.2-3.3l0.7-3.3L806,313.9z M805.4,317.6l0.7-3.3l1.2,3.3l-0.8,3.3L805.4,317.6z M807.5,317.6l0.8-3.3
	l1.1,3.3l-0.8,3.3L807.5,317.6z M812.6,322l-0.5,2.6l-1.1-3.3l0.8-3.3l0.8,2.5 M812.6,319.6l-0.7-2.1l0.4-2.1 M811.1,310.7l-0.5,3
	l1.1,3.4l0.5-2.5 M811.2,310.7l0.8,2.1 M811.9,311.8l-0.3-1.1 M809.3,310.6l1.1,2.9l0.5-2.8 M806.7,310.4l-0.5,2.9l-1.1-3
	 M807,310.6l1.2,3.3l-0.8,3.3l-1.1-3.4L807,310.6z M807.1,310.4l1.1,3l0.7-2.9 M809,310.6c-0.1,1.1-0.4,2.2-0.7,3.3l1.1,3.3l0.8-3.3
	L809,310.6 M808.9,297.9l0.3-2.3 M809.4,296.4l-0.3,1.5 M808.3,297.9l-1-2.3l-0.3,2.2 M806.8,297.8l0.4-2.6l-1.5-3.3l-0.4,3.3
	l1.1,2.6 M806.1,288.1l-0.4,3.3l1.5,3.3l0.4-3.3L806.1,288.1z M809,294.5l-1.2-2.6l-0.4,3.3l1.2,2.8 M807.9,290.8v0.7l0.7,1.8
	 M806.1,286.4l-0.1,1.4c0.5,1.1,1.1,2.2,1.5,3.3l0.1-1 M805.4,286.2l0.4,1.1l0.1-1.1 M801.3,301.5l-0.7-1.4 M801.9,313l0.7-2.9
	l-1.4-3.3v0.1 M802.4,309.3l-1.1-3l0.4-2.2 M801.6,303.4l-0.4,2.5l-0.3-0.4 M801,300.4l-0.1-0.3 M800.2,300v0.5 M806.1,297.8l-1-2.2
	l-0.3,2.1 M804.6,297.6l0.4-2.5l-1.5-3.3l-0.4,3.3l1.1,2.5 M803.9,297.6l-0.8-2.1l-0.3,1.9 M802,297.5l-1-2.2l0.4-3.3
	c0.5,1.1,1,2.2,1.5,3.3l-0.3,2.3 M801.7,297.5l-0.8-1.9l-0.3,1.8 M800.5,297.2l0.3-2.1l-1.4-2.9 M801.4,291.5l0.4-3.3l1.5,3.3
	l-0.4,3.3L801.4,291.5z M799.7,288.1l1.5,3.3l-0.4,3.3l-1.4-3.3L799.7,288.1z M803.9,288.1c-0.1,1.1-0.3,2.2-0.3,3.3l1.5,3.3
	l0.4-3.3L803.9,288.1L803.9,288.1z M801.2,286.4l0.4,1l0.1-1 M802,286.4l-0.1,1.4l1.5,3.3l0.4-3.3l-0.8-1.4 M803.2,286.4l0.5,1v-1.1
	 M804.1,286.2l-0.1,1.5l1.7,3.3l0.3-3.3l-0.7-1.5 M799.1,291l0.4-3.3l-0.7-1.4 M799,286.4l0.5,1v-1 M799.8,286.4l-0.1,1.4l1.7,3.3
	l0.3-3.3l-0.7-1.4 M59.7,306.7v11.6 M60.7,284.4c-0.7,0.7-1.5,1.1-2.6,1.2 M60.7,284.4c1.2-1.4,1.7-3.9,1.1-7.6
	c0.4-2.1-1-3.6-3.8-4.4 M53.7,307.3l6.1-0.5 M53.9,272.7c-0.3,4.1-0.4,8.3-0.4,12.7 M61.1,270.7c-0.8,0.8-1.9,1.4-3.3,1.8 M57,285.7
	l-3.6-0.3 M53.7,307.3l3-0.7c1.4-0.3,2.5-1.1,3.3-2.3 M57,285.7h1.1 M53.4,285.4c-0.1,6.9,0,14.2,0.3,21.9 M59.7,306.6v0.1
	 M59.7,306.7l13.6-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M58.1,285.7h15.8l-13.2-1.2 M59.8,304.2c1.2-1.9,1.8-5,1.8-9.1
	c0.3-5.1-1.2-8.3-4.7-9.5 M73.3,305.6l-13.5-1.4 M57.8,272.5h15.5l-12.2-1.8 M56.4,251.4c5,0.1,7.3,2.6,7.2,7.2
	c0.7,5.8-0.1,9.9-2.5,12.1 M57.8,272.5l-3.9,0.3 M526.2,181.3l-6.7,3.7c-2.1,1.4-0.8,3.2,3.6,5.2c23.9,16.8,46.6,33.6,68,50.2
	c1.9,1.6,4.4,2.5,7.4,2.3l19.1-1 M755,284.4c-2.1,0.3-2.6,1.9-1.8,5.1l6.5,29.2l6.1,0.5l-5.9-34.3L755,284.4L755,284.4z
	 M772.1,289.4v-2.1l-10-1l4,23.1l18-0.7v-18.7L772.1,289.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M56.4,251.4h18.8c1.9,0.1,2.9,1.8,2.8,4.7l-1.1,47.7
	c0.3,1.1-1,1.7-3.6,1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M53.9,272.7c0.4-7.7,1.2-14.9,2.3-21.3h0.1 M766.2,311.3l0.7,8.3l15.8,1.4
	l10.7,0.8l1.8,0.1c-0.4-0.8-0.7-1.9-0.7-3.4v-3.6c0-1.1-0.3-1.8-1.2-2.1h-0.3L766.2,311.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M773.5,316.1c0,0.7,0.1,1.2,0.5,1.6c0.3,0.4,0.8,0.7,1.2,0.7
	c0.5,0,1-0.3,1.2-0.7s0.5-1,0.5-1.6s-0.1-1.2-0.5-1.6c-0.3-0.4-0.8-0.7-1.2-0.7c-0.5,0-1,0.3-1.2,0.7
	C773.7,314.8,773.5,315.4,773.5,316.1z M790.7,315c-0.3-0.1-0.5-0.3-0.8-0.3l-0.5,0.1l-0.3,0.1l-0.4,0.4l-0.1,0.1l-0.4,0.7l-0.1,0.7
	v0.3c0,0.5,0.1,1.1,0.4,1.5l0.1,0.1c0.3,0.4,0.7,0.5,1,0.7h0.3l0.7-0.3l0.3-0.1l0.3-0.3c0.3-0.4,0.5-1,0.5-1.7
	c0-0.7-0.1-1.2-0.5-1.6l-0.3-0.3L790.7,315L790.7,315z M124.8,386.2L56.4,377 M163.3,342.6c-5.4,6.2-9.4,13.1-11.8,20.5
	 M140.5,342.6c-1.2,6.3-2.3,13.2-3.2,20.5 M23,360l0.3-7.2 M127.1,364.5l-68.2-4c-9.4-1.5-20.5-1.6-33.4-0.3 M38.8,376.6l-6.9,0.6
	 M38.8,376.6l-13.3-16.4 M56.4,377v12.1c-0.1,6.5,4,9.6,12.2,9.4h82 M41.5,376.5l-2.8,0.1 M41.5,376.5l14.9,0.5 M23,360l3.4,6.6
	 M25.5,360.2L23,360 M31.9,377.2l1.4,2.8h7.2l1.1-3.4 M26.4,366.6l5.5,10.6 M137.3,363.1h14.3 M127.1,364.5c-1,6.9-1.8,14-2.5,21.7
	 M137,365c-0.8,7-1.5,14.6-1.9,22.6l-10.3-1.4 M150.5,398.6c0.7,2.5,1.4,4.8,2.3,7.2l1.1,2.5c2.9,6.3,7.2,12.4,12.5,17.7
	c12,12,26.4,18,43.5,18c16.9,0,31.4-6.1,43.5-18c10-10,16-21.9,17.5-35.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151.6,363.1c-2.1,6.1-3,12.5-3,19.5c0,5.6,0.7,11,1.9,16 M137,365
	l-9.9-0.5 M137.3,363.1L137,365 M138.2,318.3c3.7-7.7,8.7-13.2,15-16.8c8.1-6.1,25-8.7,50.8-7.6h0.3c17.1-0.5,31.4,0.1,42.8,2.3
	 M81.3,318.3h56.9 M163.3,342.6h-22.8 M148.2,318.3h-9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.1,304.8l-0.7-0.1c-9.4-1.8-20.5-2.8-33.7-2.6h-21.6
	c-16.2,0.1-27.2,2.3-33,6.5l-0.4,0.3c-3.7,2.2-6.9,5.4-9.5,9.6 M148.2,318.3c-1.4,2.3-2.6,5-3.7,7.8c-1.5,5.1-2.9,10.6-4.1,16.5
	 M138.2,318.3c-0.7,1.5-1.5,3.2-2.1,5v0.1c-3.9,11.4-6.7,25.2-8.9,41.1 M115,285.9c-3.9,0-7.2,1.4-9.9,4.1c-2.8,2.8-4.1,6.1-4.1,10
	c0,3.9,1.4,7.2,4.1,9.9s6.1,4.1,9.9,4.1s7.3-1.4,10-4.1s4.1-6.1,4.1-9.9c0-3.9-1.4-7.2-4.1-10C122.2,287.3,118.9,285.9,115,285.9
	L115,285.9z M35.6,279.3l3.7,0.1 M39.2,264.2l-5.2,4.4 M39.5,309.2c0.1-10.6,0.7-20.5,1.5-29.7 M23.2,352.8h-1.4v13.8h4.5
	 M39.6,339.9H21.9v9.9l1.5,1.4l-0.1,1.6 M39.5,309.2h-8.9v4.4c3.2-0.3,5.2,0.4,6.3,2.2l2.6,0.6 M81.3,318.3H52.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M39.6,339.9v4.4H22.3 M39.6,334.1v5.8 M39.5,316.4v-7.2 M39.6,334.1
	l-2.5-16.4h2.3v-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M52.5,318.3l-12.9,8.5v7.3 M39.3,279.5H41 M39.2,264.2
	c1.7,0.1,2.2,1.8,1.7,5l-1.5,10.3 M40.7,262.8l-1.5,1.4 M40.7,262.8c0.3-1.6,0.5-3.4,1-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M41,279.5c0.4-3.9,0.8-7.6,1.4-11c0.3-3.3-0.3-5.1-1.7-5.6 M34,268.6
	l2.9,1.4l-1.2,9.4 M34,268.6l-2.2,10.6l3.8,0.1 M55,248.4c-3.7,20.1-4.5,43.3-2.5,69.9 M41.8,257.6c0.7-2.8,1.5-5.4,2.6-8.1
	 M81.3,318.3v-69.9 M415,160.1l11.6,0.1l15.7,0.6l14.9,1.4c6.2,0.7,12.2,1.5,17.9,2.6l1.4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M415,161.7l11.6,0.1l15.7,0.6l14,1.2l9.2,1.1c5,0.6,8.5,0.7,10.9,0.1
	 M488.5,167.4l-0.4,0.8c4,1,8,2.1,11.7,3.2l0.1-1 M499.9,170.4l0.3-2.1l-1.2-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M494.1,166.8l-4.8-1.1l-0.8,1.6 M498.9,168.1l-2.3-6.6l-2.6-0.7l0.1,6.1
	 M498.9,168.1l-4.8-1.2 M535.7,222.3c-15.4-13.1-29.6-23.1-42.8-30.3c-14.4-8.1-35.1-12.8-62.2-13.9l-14.3-0.3
	c-2.5,0.6-3.7,3-3.9,7.6l-1.4,66.3l118.2,2.9 M476.5,164.9c3.4,0.7,7.6,1.5,12,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M410.1,253.8c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.6-0.3-0.8l1.4-67.4
	c0.3-6.1,2.2-9.2,5.9-9.6h0.3l14.3,0.3c27.5,1.2,48.6,5.9,63.1,14.2c12.9,6.9,26.8,16.6,41.7,29.2 M356.6,156.6v-2.3
	c0-0.4-0.1-0.6-0.5-0.6H350c-0.4,0-0.5,0.1-0.5,0.6v2.3 M356.6,156.6h-7.3 M415,160.1c-2.9-1.9-6.5-2.9-11-3.2h-3.7
	c-13.1-0.1-27.6-0.1-43.9-0.1 M130.7,165.4c34.1-1.5,67.8-2.5,101.1-2.9h6.7c13.9,0,27.4,0,40.6-0.1h8.5
	c50.2-0.3,90.7-0.3,121.5,0.1l5.8,0.1v-0.8 M415,161.7v-1.6 M271.3,217.3c1.9-7.6,5-16,9.1-25c4.4-11.7,16-17.5,34.7-17.6l66.6,0.1
	c3.2,0.3,4.8,2.5,5.2,6.7l-3.6,70.3c0,0.3-0.1,0.6-0.4,0.8c-0.1,0.1-0.4,0.3-0.7,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M384.6,181.7c-0.3-2.8-1.4-4.4-3.3-4.7l-66.3-0.1
	c-17.6,0-28.5,5.4-32.6,16.1c-7.6,16.5-11.3,30.4-11.3,41.8v0.4c-0.1,0.8-0.1,1.6,0,2.3c1.2,6.9,10.2,10.3,26.8,10.3l83.1,2.5
	L384.6,181.7L384.6,181.7z M382.2,252.9l15.8,0.4 M410.1,253.8l-12.1-0.4 M398,253.3c0.1-32.5,1-62.2,2.6-89.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M400.7,164.1c-29.6-0.3-67.3-0.3-112.9,0h-8.3 M349.3,156.6
	c-18.3,0-38.4,0-60.4,0.1h-8.5c-24.8,0.1-51.7,0.4-80.9,0.8l-5,0.1 M269.4,227.3l1.9-10 M269.4,227.3c0.4-3.2,1-6.6,1.9-10
	 M382.2,252.9l-84.2-2.5c-18.2-0.1-27.8-4.1-28.9-12.2c-0.1-1-0.1-1.9-0.1-2.9V235c0-2.5,0.1-5,0.4-7.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M398,259.8l-90.4-2.3c-5.2,0.1-10.6-0.3-15.8-1l-3.7-0.7
	c-7.8-1.4-13.5-3.3-16.6-5.5c-3-2.2-4.3-6.6-3.7-13.3l1.8-9.5 M186.5,157.9h-5.4 M194.5,157.7v-2.3c0-0.4-0.1-0.6-0.6-0.6h-6.9
	c-0.4,0-0.6,0.1-0.6,0.6v2.3 M181.2,157.9c-2.5,0.7-5.1,2.5-7.8,5.5 M186.5,157.9l8-0.1 M81.3,248.4h119c10.2,0,16.8-2.3,19.5-6.9
	l31.5-57.6c4.1-5.8,0.6-8.4-10.5-8l-131.4,3 M279.5,164.1c-15.4,29.3-25.7,57.9-31,86c-1.2,12.5-1.8,27.9-1.5,46.2 M796.5,266.3
	l-6.3,0.1l-7-0.5l-6.9-1.1l-2.8-0.7h-0.1l-5.8-1.2c-26.7-5.2-57.1-9.6-91.3-13.2l-29.9-2.8c-2.8-0.3-7,0.7-12.7,2.8
	c11.8,0.4,23.5,1.4,35.1,2.8c38,4,71.8,10.2,101.7,18.4l0.3,0.1l11.6,3.3l1.2,0.4l4,1.9l0.4,0.3l3.6,2.6l2.5,2.5l3,3.9 M625.8,248.1
	l-8.4-6.3c-30.8-23.2-61.2-43.3-91.2-60.5l-5.6-3.3 M613.4,251.1h-0.5c-9.6-0.1-17.6-2.6-23.9-7.6l-8-6.9 M613.4,251.1l12.4-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M796.5,266.3l-0.5-0.3l-8.9-2.8l-8.8-2.2l-6.6-1.4
	c-4.5-1-9.6-1.8-15.1-2.8c-12.1-1.9-26.3-4-42.5-5.8c-22.4-2.5-47-4.5-73.7-5.8c-1.9,0.1-6.6,1-14.4,2.8 M520.5,178.1l-12.7,4.4
	 M580.9,236.6c-2.1,0.7-2.8,2.9-1.9,6.6c5.9,23.9,8,50.8,5.8,80.6c-1.8,23-4.5,42.9-8.3,59.6 M536.8,262.7c14.7,0.3,23-3,24.8-9.6
	c1.1-5.8-0.3-10.9-4-15.3L539,222.1l-0.1-0.1l-3.2-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M542.5,256.2c2.1-0.1,3.9-0.3,5.2-0.6c4.8-2.6,7.4-6.3,7.8-10.9
	c0.1-1.6-0.3-2.5-1.2-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M554.4,242.3c-0.1,2.8-1,5.2-2.5,7.2h-3c-0.3,2.5-2.3,4.4-6.3,6.1
	l-1.8,0.7 M535.7,219.2v3 M536,230.9c-3.7,0.6-6.2,2.1-7.4,4.5c-1.1,2.2-1.8,5-2.2,8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.9,242.3l-1.9-8.7c-1-2.3-3-3.3-5.9-3l-5.1,0.3 M526.5,243.9
	c3.6,0,6.9,0,9.9-0.1c5.1-0.3,9.2-0.7,12.7-1.2 M526.5,243.9c-0.1,1.8-0.3,3.7-0.3,5.8c0.3,2.2,1.4,3.9,3.2,5 M535.7,222.3l0.3,8.7
	 M580.9,236.6c-27.1-23.1-51.4-41.1-72.9-54.1 M554.4,242.3h-5.5 M529.3,254.4c1.4,0.8,3.2,1.2,5.4,1.4l1.9,0.1 M536.6,256.1v0.8
	 M536.8,262.7l-0.1-5.8 M536.6,256.9l4.8,0.1c0.3,0,0.5-0.1,0.8-0.3l0.3-0.4 M542.5,256.2h-1.8 M540.8,256.2l-4.1-0.1 M575.7,329.6
	c0.5-2.2-0.5-3.3-3.6-3.3H399.9v16.1h169.2c2.9,0.1,4.5-0.7,5-2.5L575.7,329.6L575.7,329.6z M586,383.5c2.3-22.8,5.9-41.8,10.9-56.8
	v-0.1c3.9-10.2,9.5-17.5,17.1-21.7c8.3-6.1,25-8.7,50.8-7.6h0.3c17.7-0.5,32.3,0.3,44,2.6l0.3,0.1c12.7,3.6,22.1,10.2,28.3,20.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M726.3,319.5c-4.8-5.4-11.3-9.2-19.4-11.6c-9.2-1.8-20.5-2.8-33.7-2.6
	h-21.6c-16.1,0.1-27.1,2.3-33,6.5l-0.4,0.3c-5.9,3.4-10.3,9.2-13.2,17.5c-4.5,14.3-7.6,32.3-9.5,53.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M675.2,340.7c-1,0.3-1.2,1-1.1,2.3l0.7,25.2 M667.5,368.2l0.7-25.2
	c0.3-1.5-0.3-2.3-1.4-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M666.6,340.7l4.1-0.1l4.4,0.1 M675.2,340.7h0.1l0.5,0.1 M675.8,340.8
	l-0.7-0.1 M663.9,353.1l0.3-4.1c0.3-1.4-0.4-2.1-1.9-2.1c-1.2,0.3-2.5,0.5-3.7,1 M663.9,353.1l-5.4-5.2 M663.6,359.8l0.3-6.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.5,373.9c1.7-8,5.5-14.9,11.6-21c7.2-7.2,15.5-11.1,25.3-12.1h0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M654,349.8c-3.3,1.6-6.3,4-9.1,6.7c-1.9,1.9-3.6,4-5.1,6.2l-0.1,0.3
	c-0.4,1.2,0.1,2.1,1.4,2.2l8.7,2.3 M658.5,347.9c-1.5,0.6-3.2,1.2-4.5,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M717.1,342.4l-2.9-3.2c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.1-11
	s-24.9,3.7-35.2,11c-2.9,2.1-5.6,4.4-8.3,7c-11.1,11.1-17.2,24.5-17.9,39.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M670.8,338.4c-12.2,0-22.7,4.3-31.4,12.9s-12.9,19.1-12.9,31.4
	c0,8.7,2.2,16.4,6.5,23.2l1.7,2.5c1.4,1.9,3,3.9,4.8,5.6c8.7,8.7,19.1,13.1,31.4,13.1c12.2,0,22.7-4.4,31.4-13.1
	s12.9-19.1,12.9-31.4c0-12.2-4.3-22.7-12.9-31.4C693.5,342.6,683,338.4,670.8,338.4L670.8,338.4z M654,349.8l9.6,10 M609.4,379.1
	l-0.1,3.6c0,5.8,0.7,11.4,2.1,16.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M658.4,374.3l-24.1-7.2c-1.4-0.3-2.1-0.8-1.9-1.8l0.3-0.5 M656.2,381.3
	l-23.7-8.4c-1.4-0.7-2.2-0.4-2.8,0.7l-0.3,0.3 M644.9,381.6l-8.1-3.2c-1.2-0.5-2.1-0.3-2.6,1.1l-0.1,3c0,1.5,0.1,3,0.3,4.4
	 M642,413.3l-1.1-1c-8.3-8.3-12.4-18.2-12.4-29.9c0-3,0.3-5.9,0.8-8.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M611.5,399.2c0.5,2.2,1.4,4.4,2.2,6.6l1.1,2.5c3,6.3,7.2,12.4,12.5,17.7
	c12,12,26.4,18,43.5,18c16.9,0,31.4-6.1,43.5-18c12-12,18-26.4,18-43.5c0-3.6-0.3-7.2-0.8-10.6 M284.1,390.6v7.2h317.5l1.1,1.4h8.8
	 M595.2,386.5l-0.3,4.1h-9.6 M595.5,383.5l-0.3,3 M609.4,379.1l-14.2,7.4 M663.6,359.8c-3.6,1.1-6.9,3-9.6,5.9l-0.3,0.3 M653.6,366
	h-3.2l-0.7,1.5 M656.5,369.5l-0.8-3.4h-2.1 M644.9,381.6v0.3c-0.4,1.4,0.1,2.3,1.7,2.8v2.3 M651.9,384.3l-7-2.8 M663.3,365.2
	c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.6c-1.1,0.7-1.9,1.5-2.5,2.5 M656.5,369.5h0.3 M667.3,368.6l0.1-0.4 M658.9,374.5l0.7,0.3
	c1.5,0.5,2.9,0.3,4.1-0.4c0.5-0.3,1.1-0.4,1.7-0.8c1.4-1,2.1-2.2,2.1-4v-0.8 M674.8,368.9v0.7c0,1.6,0.5,2.9,1.8,3.9
	c0.5,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3 M674.8,368.2v0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M674.8,368.9c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4 M676.3,377.2
	c-1.5-1.5-3.3-2.2-5.5-2.2c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3
	c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5S677.8,378.7,676.3,377.2L676.3,377.2z M658.4,374.3l0.5,0.3 M656.5,381.4l-0.3-0.1
	 M659.5,391.3l0.4-0.7c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9c-0.4-1.5-1.5-2.5-3-3l-0.8-0.3 M656.5,381.4v1.2
	c0,3.3,1,6.3,3,8.8 M658.9,374.5c-1.5,2.1-2.3,4.4-2.5,7 M656.7,369.5l1.7,4.8 M667.3,368.6c-2.5,0.5-4.8,1.9-6.7,3.9
	c-0.7,0.7-1.2,1.2-1.7,1.9 M667.5,368.2l-4.1-3 M665.3,395.9l-0.1,0.3 M659.5,391.3l-0.3,0.4 M675.7,396.1l-0.4-0.7
	c-1-1.2-2.2-1.9-3.7-2.1c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1l-0.5,0.8 M665.3,395.9c-1.7-0.7-3.2-1.8-4.5-3.2l-1.2-1.4
	 M675.7,396.1l0.3,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M665.3,395.9c1.7,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8 M674.5,401.4
	c-1.1,0.4-2.1,0.7-3.3,0.5h-1.1c-1.2-0.1-2.3-0.4-3.3-1 M666.6,401l-3.4,4.3 M676,396.4l-1.5,5 M666.6,401l-1.5-4.8 M663.2,405.2
	c2.3,0.8,5,1.1,7.6,1.1c2.5,0,4.8-0.3,7-1 M654,391.7c-0.7-0.8-1.2-1.6-1.5-2.6l-0.1-0.3l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4
	 M646.4,387.1c0.1,2.1,0.4,4,1,5.9 M649.7,398.3l4.3-6.6 M647.4,393c0.5,1.9,1.4,3.7,2.3,5.4 M635.8,394.5c1.4,4.1,3.6,8,6.5,11.4
	l0.1,0.1c1.1,0.8,2.1,0.5,2.6-0.7l4-6.1 M649,399.4l0.7-1.1 M649,399.4c-4.7-0.4-9.1-2.1-13.2-5 M649.7,367.5l6.7,1.9 M654,391.7
	h5.2 M651.9,384.3l4.3-3 M659.2,391.7l-14.3,20.8c-0.7,1.2-1.5,1.6-2.3,1.2c-0.1-0.1-0.3-0.3-0.4-0.3 M665.1,396.1l-15.3,19.9
	c-1.1,1.1-1.1,2.1-0.3,2.8l0.5,0.5 M650.1,419.4c-2.8-1.6-5.5-3.7-8-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M663.2,405.2l-6.7,8.4c-1,1-0.7,2.1,0.5,3.2c4.3,1.6,8.8,2.6,13.8,2.6
	c4.8,0,9.4-0.8,13.6-2.5c1.2-1,1.4-1.8,0.3-2.8l-6.9-8.5 M650.1,419.4c6.2,3.6,13.1,5.2,20.6,5.2c7.4,0,14.2-1.6,20.2-5.1
	 M634.2,387.1c0.3,2.6,0.8,5.1,1.7,7.4 M646.4,387.1h-12.2 M634.2,387.1l13.2,5.9 M586,383.5h-9.5 M585.3,390.6v-0.1l0.7-7
	 M595.5,383.5H586 M663.6,359.8l-0.3,5.4 M776,283.5h-22c-2.1,0.1-2.9,2.1-2.5,5.8l7.2,31.5 M737.6,319.9l-11.3-0.4 M741.6,328
	c1.7,2.2,3.6,3.3,5.9,3.4l42.8,1.5h0.3l3.7,0.1h0.4l8,0.7l7.7,1.2h0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M741.6,328c1.5,3.7,2.6,7.6,3.4,11.8c1.4,6.1,2.6,12.2,3.7,18.7l62.3,1.8
	 M726.3,319.5c5.1,5.6,8.4,13.1,10,22.3l0.8,4.1 M758.7,320.8l-21-0.8 M737.6,319.9c1.5,2.5,2.9,5.2,4.1,8.1 M690.2,368.6l10.6-2.9
	c1.4-0.1,1.8-1,1.4-2.5c-1.5-2.3-3.3-4.5-5.4-6.7l-1.2-1.1 M695.5,355.4c-1.4-1.2-2.8-2.5-4.3-3.4 M691.3,352
	c-3.4-2.3-7.3-4.1-11.3-5.1l-0.4-0.1c-1.2,0.1-1.7,0.8-1.5,2.1l0.5,11.1 M737.1,345.9c-7.6-2.3-14.3-3.6-19.9-3.6 M776,283.5l-1.7-4
	c-0.5-1.1-1.1-2.1-1.8-3l-1-1.1c-1.9-2.2-4.1-3.9-6.7-4.7c-17.6-4.8-42.2-9.2-73.7-13.1c-23.9-2.8-49.9-4.8-78-6.5 M809.6,283.3
	c-0.3-0.7-0.5-1.4-1-1.9l-2.2-4.1c-0.5-1-1.2-1.9-1.8-2.9l-2.3-3.2l-2.6-2.9v-0.1L798,267c-0.4-0.3-0.8-0.4-1.4-0.7 M796.8,285.7
	l-2.3-0.1h-0.1 M806.1,286.2h-2.3l-0.5,0.1h-5.4l1.2,4.7l0.3,1.2l1.1,5v0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M796.8,285.7l0.3-2.3h12.5 M809.7,297.9h-1.4l-1.2-0.1h-1l-1.2-0.1h-1
	l-1.1-0.1h-1.1l-1.1-0.1h-0.1 M800.5,297.4l-1-0.1 M800.1,300l0.1,0.5l0.8,5l0.3,1.5c0.3,1.9,0.5,4,0.7,6.1l0.1,1.5
	c0.1,1.8,0.3,3.7,0.4,5.6v2.2l0.1,3.6 M801,300.1v0.4l0.3,1.1l0.3,1.9l0.1,0.7c0.3,1.6,0.5,3.4,0.7,5.2l0.1,0.8 M801,300.1h-0.4
	l-0.4-0.1h-0.1 M799.5,297.2l0.5,2.8 M800.1,324.3l-0.1-3v-0.1l-1.4-13.5l-1.8-11.7l-2.5-10.5 M799.5,297.2l-2.8-11.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M794.3,285.5l-18-2.1H776 M806.1,286.2l4.3-0.1l-0.8-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M809.7,297.9l-0.3-1.6l-0.1-0.8l-0.3-1.1l-0.3-1.2l-0.7-2.6l-0.1-0.7
	c-0.4-1.4-1-2.6-1.5-3.7v-0.1 M81.4,171.4l-1.9,2.5 M81.3,248.4H55 M91.2,178.9C72.6,203,60.5,226.1,55,248.4 M79.5,173.8l9.1,5.6
	 M44.4,249.5h-5.8l-2.3,4.8l3.4,1.4v1c0.7,0.3,1.4,0.7,2.1,1 M44.4,249.5l0.4-1.1 M815.9,301.2l-0.1-1.8l-1.5-1.1 M814.8,300.9
	l-0.5-2.6 M816.4,308.5l-1.1-0.3l-0.4-5.5v-0.1l-0.1-1.7 M814.2,298.3l-4.5-0.4 M801,300.1l12.2,0.7l0.8,6.5
	c0.3,1.4,0.7,2.5,1.2,3.4l0.1,0.3 M815.9,301.2l-1.1-0.3 M811.6,310.7h-0.7l-1.7-0.1h-0.4l-1.8-0.1h-0.4l-1.7-0.1h-0.4l-1.9-0.1
	h-0.1 M44.8,248.4l0.1-0.3c9.8-24.5,23.4-46.4,41.1-65.6 M55,248.4H44.8 M86,182.6l-7.4-4.1l1-4.7 M279.5,164.1h-0.3
	c-24.8,0.1-51.7,0.4-80.9,0.7c-24.9,0.3-48.4,1.1-71,2.5l-11.7,0.8 M109.5,178.9c-13.1,22.1-22.6,45.1-28.2,68.9v0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M815.3,311h0.3l0.3-0.1c0.4-0.3,0.7-1.1,0.4-2.3 M812.6,333v-2.3l0.1-0.8
	V323l-0.1-1v-2.3l-0.3-4.1l-0.1-0.8l-0.1-1.9l-0.1-1l-0.1-1.1l3.6,0.3 M181.2,157.9l-41.4,1.5c-5.5,1.4-8.4,3.3-9.1,5.9
	 M109.5,178.9l6.2-10.9 M115.7,168.1l-16.5,1.4 M91.2,178.9c-2.8-2.2-3.6-4.4-2.2-6.6 M99.2,169.4l-8,9.5 M88.6,179.5l2.6-0.6
	 M88.6,179.5l-2.6,3.2 M81.4,171.4l2.2-0.3c15.4-3.4,30-5.2,43.7-5.5l3.3-0.3 M89,172.2l-7.6-0.8 M89,172.2l8.8-2.8h1.4
	 M815.9,336.7l0.3-1.5c0-0.4-0.1-0.8-0.4-1.1l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.3h-0.1l-2.2-0.5 M812.6,333l-0.4-0.1l-0.4-0.1
	l-1.8-0.4l-0.4-0.1l-0.8-0.1 M808.7,332l-0.5-0.7l-0.3-0.3l-0.8-1l-0.3-0.4l-0.3-0.1l-0.1-0.3l-1.5-1.4l-0.1-0.1l-0.5-0.4l-0.3-0.3
	l-0.8-0.7l-0.1-0.1l-0.4-0.3 M811.6,337.4l4.1,0.8l0.1-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M818.5,356.7l2.3,0.3v-12.7l-5.6-2.2l-1.1-0.6l-0.7-0.5l-0.1-0.1l-0.4-0.7
	l-1.2-2.8 M810.7,335.1l1,2.3 M808.7,332l1.8,2.8l0.1,0.3 M810.7,335.1l5.2,1.6 M818.5,356.7l-4-0.5c-1.4,0-2.5,1.4-3.4,4.3
	 M800.1,324.3l-3.4-0.4l-0.1-0.1l-15.4-1.8h-0.7l-1.8-0.1h-0.1l-19.8-1.1 M802.5,310.2v0.5c0.3,2.1,0.4,4.1,0.5,6.3l0.1,1.2l0.3,5.9
	v1.9 M802.5,326l-2.5-1.6 M816.4,308.5l-0.5-7.3 M780.4,381.1h0.3c0.8,0,1.7-0.7,2.8-2.1c1.1-1.4,1.9-3,2.8-5c0.7-1.9,1.1-3.6,1-5.1
	c0-0.6-0.1-1-0.3-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M788.1,366.6c0.4,0.4,0.5,1,0.7,1.9c0.1,1.5-0.1,3.3-1,5.5
	c-0.8,2.2-1.8,4-3.2,5.5c-1.2,1.5-2.5,2.3-3.4,2.3l-0.8-0.1 M780.5,381.7l-0.1-0.5 M780.4,381.1v-0.7l0.3-1.4
	c1.1-4,3.2-7.7,6.3-11.3 M787,367.7l0.6-0.7l0.5-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M780.5,381.7c0.4,1.4,1.5,2.2,3.6,2.8l4.5,0.7c2.1,0.4,3.7-0.3,5-1.9
	c4.4-6.3,7.6-11.8,9.2-16.5c0.1-1.8-0.5-2.6-2.1-2.3h-6.6c-2.2,0-4.3,0.7-6.2,2.2 M793.9,391.9c-1.8,2.3-3.6,4.7-5.6,6.9
	 M804.6,388.7l-1.9-0.4c5.5-7.7,9.1-14.7,11-21h2.6c1.1,0.1,1.1,1.4,0,3.9c-2.8,6.7-5.8,12.2-8.9,16.5
	C806.7,388.8,805.7,389.1,804.6,388.7L804.6,388.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M821.8,361.5h-5.2c-1.5-0.1-2.6,1.1-3.3,3.7c-2.3,7.7-6.7,16-13.3,24.9
	l-0.3,0.4c-1,1.4-1,2.5,0.3,3.2c1.7,0.7,1.9,1.6,1.1,3l-3,3.7 M743,395.6v16.8l56-0.5v-4.7c0.1-2.6-1-4.8-3-6.9 M795.9,400.3
	l1.9,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M797.9,400.4c1-0.1,1.9-0.7,2.6-1.5c7.4-7.2,13.6-17.2,18.7-30h1.2
	l1.1-4.5c0.1-1.1,0.3-2.1,0.1-2.8 M795.9,400.3l-7.6-1.5 M793.9,391.9l-14.4,1l-16.9,2.8 M811.1,360.4l-0.4,1.5
	c-4.3,11-9.9,20.9-16.6,30 M821.8,361.5c-0.1-0.5-0.3-1.1-0.7-1.5l-2.6-3.3 M684.9,370c-0.6-1.1-1.4-1.9-2.2-2.8l-0.5-0.4
	c-1-0.8-2.1-1.4-3.3-1.8 M683.6,362.4c-1.5-1-3.3-1.9-5-2.5 M678.6,360l0.3,5.2 M690.2,368.6c-0.8-1-1.7-2.1-2.5-2.9
	c-1.2-1.2-2.6-2.3-4-3.3 M694.6,383.1l-4.8,1.8 M685.1,382.1l-0.7,0.3c-1.5,0.4-2.5,1.4-3,2.9l-0.8,1.5c-0.5,1.5-0.4,3,0.7,4.4
	l0.5,0.8 M683,375.1c1.4,2.1,2.1,4.4,2.1,7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M685.1,382.1v0.5c0,3.6-1.1,6.6-3.4,9.2 M683.4,375l-0.4,0.1 M685.6,381.8
	l-0.5,0.3 M684.9,370l5.2-1.4 M689.7,384.9l-4.1-3 M684.9,370l-1.5,5 M683,375.1c-0.5-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.1-3.7
	 M709.3,365.3v0.3c0.3,1.1-0.3,1.9-1.8,2.1l-24.1,7.2 M699,413.7l1.5-1.4c8.3-8.3,12.4-18.2,12.4-29.9c0-2.8-0.3-5.5-0.7-8.1
	 M685.6,381.8l23.7-8.4c1.2-0.7,2.1-0.5,2.6,0.4l0.1,0.5 M707.1,388.4c0.3-1.9,0.4-3.9,0.4-5.9c0-0.8,0-1.5-0.1-2.3v-0.4
	c-0.4-1.1-1.2-1.4-2.3-0.8l-5.2,2.1 M712.2,374.5c-0.5-3.2-1.5-6.3-2.9-9.1 M703.6,399.3c1.7-3.3,2.9-7,3.4-10.9 M692.9,401.2l3,4.5
	c0.5,1.2,1.5,1.5,2.8,0.5c1.9-2.2,3.6-4.7,5-7.2 M699.4,392.3c2.6-1,5.1-2.2,7.7-3.9 M681.9,392.1h5.1 M681.8,391.9l-0.8,0.8
	c-1.5,1.5-3.3,2.8-5.2,3.4 M681.8,391.9l0.1,0.3 M687,392.1c0.8-1,1.5-1.9,1.9-2.9l0.3-0.7c0.5-1.2,0.7-2.5,0.7-3.7 M691.8,393.9
	c-0.5,1-1.1,1.9-1.8,2.8 M690,396.7l-3-4.5 M681.9,392.1l12.7,18.4 M692.9,401.2l-2.9-4.5 M699.8,381.1l-5.2,1.9 M694.6,383.1
	c-0.1,4-1,7.6-2.8,10.9 M703.6,399.3c-3.9,1.6-7.4,2.3-10.6,2.1 M691.8,393.9c2.5-0.1,5.1-0.7,7.6-1.6 M699.4,392.3l0.4-11.1
	 M741.7,377.2c0.5,5.1,1,10.3,1.2,15.7v2.8 M762.5,395.6H743 M731.3,372.1l10.5,5.1 M691,419.7l0.7-0.7c0.7-0.8,0.5-1.7-0.4-2.6
	l-1.7-2.2 M699,413.7l-0.3,0.1c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2 M691,419.7c2.9-1.5,5.5-3.6,8.1-5.8 M694.6,410.6
	c-1.5,1.4-3.3,2.6-5,3.6 M709.3,365.3c-2.1-4.5-5-8.7-8.8-12.5c-7-7-15.1-11-24.6-12.1 M737.1,345.9c2.1,9.9,3.6,20.2,4.7,31.2
	 M788.4,398.8c-7.8-1.4-16.4-2.5-25.9-3.2 M683.6,362.4l12-7 M678.6,360l12.7-8 M731.3,372.1c-1.8-11.1-6.5-21-14.2-29.7
	 M677.8,405.5l-3.3-4.1 M678.9,365.2l-4.1,3 M689.6,414.3L676,396.4 M247.1,296.4v8.4 M247.1,296.4l1.5,0.3
	c19.9,5.5,31.8,18.8,35.8,39.9c3.3,14.3,5.8,30,7.4,46.8 M237.2,357.9l-2.8-2.8 M234.4,355.1c-4.5-4-9.5-6.7-15.1-8.1l-0.4-0.1
	c-1.2,0.1-1.6,0.8-1.5,2.1l0.3,6.2 M214.1,368.2l-0.7-25.2c-0.1-1.4,0.1-2.1,1.1-2.3 M214.5,340.7l0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M205.9,340.7l4.1-0.1l4.4,0.1 M214.5,340.7h0.1l0.6,0.1 M253,338.6
	c-2.5-2.5-5.1-4.7-7.8-6.6c-10.2-7.3-22-11-35.2-11c-13.2,0-24.9,3.7-35.1,11c-2.9,2.1-5.6,4.4-8.3,7l-3.3,3.4 M215.1,340.8
	c9.4,1.1,17.6,5.1,24.6,12.1c3.9,3.9,6.7,8,8.8,12.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M241.3,351.3c-8.7-8.7-19.1-12.9-31.4-12.9s-22.7,4.3-31.4,12.9
	s-12.9,19.1-12.9,31.4c0,5.6,1,11,2.8,15.8l0.1,0.1c1,2.5,2.2,5,3.6,7.1l1.6,2.5c1.5,1.9,3,3.9,4.8,5.6c8.7,8.7,19.1,13.1,31.4,13.1
	s22.7-4.4,31.4-13.1c8.7-8.7,12.9-19.1,12.9-31.4C254.4,370.4,250,360,241.3,351.3L241.3,351.3z M234.4,355.1h-16.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.6,368.2l0.7-25.2c0.3-1.5-0.3-2.3-1.4-2.5 M203,355.6l-0.1,4.3
	 M203,355.6l0.3-6.6c0.3-1.4-0.4-2.1-1.9-2.1c-6.6,1.5-12.4,4.7-17.5,9.8l-2.6,2.8c-0.8,1.1-1.7,2.1-2.5,3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M168.8,373.9c1.7-8,5.5-14.9,11.6-21c7.2-7.2,15.5-11.1,25.3-12.1h0.4
	 M272.6,280.6l-8.7,0.1c-0.8,0-1.4-0.4-1.5-1.4c-0.3-1.6-0.3-3.3,0.1-4.7c0.1-1,0.7-1.4,1.6-1.4l8.4,0.1 M299.6,273.8
	c-0.1-1.2-0.4-2.2-0.8-3c-0.8-1.6-2.3-2.8-4.4-2.9l-4.8-0.3l-5,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.6-0.8,2.9 M272.6,280.6
	l7.2-0.1 M272.6,273.6v7 M279.5,273.7l-6.9-0.1 M279.8,280.4c0.1,2.1,0.8,3.7,2.2,5c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3
	c1.4,0.3,2.8,0.5,4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.2,2.1-2.9,2.2-5.2 M301.3,280.2l7.6-0.1
	c1.2-0.3,1.9-1,2.2-1.8v-2.5c-0.3-1.1-1-1.6-1.9-1.8l-7.7-0.1 M301.3,273.8v6.3 M299.3,280.2h1.9 M301.3,273.8h-1.6 M308.4,326.3
	h0.7 M309.1,326.3c-1.5,0.3-1.8,2.2-0.5,5.8c1.5,6.7,3.4,10.2,5.5,10.2h81.4v-16H309.1L309.1,326.3z M247.1,304.8
	c16.2,4.8,26,16.1,29.3,33.6v0.1c0.7,2.8,1.2,5.6,1.9,8.5 M270.2,369.5c-2.3-11.4-8-21.6-16.6-30.4l-0.6-0.4 M278.2,347l-25.2-8.4
	 M299.6,273.8l-20.1-0.1 M299.3,280.2l-19.5,0.3 M271.4,383.5v-0.8c0-4.5-0.4-8.9-1.2-13.1 M251.5,374.5l-0.3-0.6
	c-0.6-1-1.4-1-2.6-0.4l-23.7,8.4 M251.5,374.5c0.4,2.6,0.7,5.2,0.7,8.1c0,11.7-4.1,21.6-12.4,29.9l-1.5,1.4 M291.8,383.5h-8.4
	 M283.4,383.5c-0.1-2.1-0.4-4.1-0.7-6.2 M271,390.6h13.1 M282.7,377.2l-12.5-7.7 M283.4,383.5h-12 M283.4,383.5l0.7,7.2 M271,390.6
	c0.3-2.3,0.4-4.8,0.5-7.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M172,364.8l-0.3,0.6c-0.1,1,0.6,1.6,1.9,1.8l24.1,7.2 M181.4,362l-2.3,0.7
	 M181.4,365.5V362 M179.1,362.7L179,363c-0.4,1.2,0.1,2.1,1.4,2.2l1.2,0.3 M181.4,380.6l2.8,1 M184.2,381.6v0.3
	c-0.4,1.4,0.1,2.3,1.6,2.8c-0.1,5.1,1,9.6,3.3,13.8 M195.5,381.3l-23.7-8.4c-1.4-0.7-2.2-0.4-2.8,0.7l-0.3,0.3 M181.4,380.6
	l-5.5-2.1c-1.2-0.5-2.2-0.3-2.6,1.1l-0.1,3c0,5.4,1,10.5,3,15"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M181.3,413.3l-1.1-1c-4.1-4.1-7.3-8.7-9.4-13.8c-2.1-5-3-10.3-3-16
	c0-3,0.3-5.9,0.8-8.8 M195.8,369.5l-0.8-3.4h-2.1 M202.9,359.8c-3.6,1.1-6.9,3-9.6,5.9L193,366 M192.9,366h-3.2l-0.7,1.5 M189,367.5
	l6.7,1.9 M195.8,369.5h0.3 M206.6,368.6v-0.4 M202.5,365.2c-1.2,0.3-2.2,0.7-3.2,1.2l-0.8,0.6c-1,0.7-1.9,1.5-2.5,2.5 M206.6,368.2
	l-4.1-3 M198.2,374.5c0.6-0.7,1.1-1.4,1.6-1.9c1.9-1.9,4.3-3.3,6.7-3.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.6,368.6v0.8c0,1.6-0.7,3-2.1,4c-0.4,0.3-1,0.5-1.6,0.8
	c-1.2,0.8-2.6,1-4.1,0.4l-0.7-0.3 M204.6,377.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3
	c2.1,0,4-0.8,5.5-2.3c1.5-1.5,2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5c-1.5-1.5-3.3-2.2-5.5-2.2C207.9,375,206.1,375.6,204.6,377.2
	L204.6,377.2z M195.8,381.4l-0.3-0.1 M195.5,381.3l-4.3,3 M195.8,381.4v1.2c0,3.3,1,6.3,3,8.8 M198.8,391.3l0.4-0.7
	c1-1.2,1.2-2.6,1-4c-0.1-0.7-0.3-1.4-0.4-1.9c-0.4-1.5-1.5-2.5-3-3l-0.8-0.3 M198.2,374.5c-1.5,2.1-2.3,4.4-2.5,7 M197.7,374.3
	l0.6,0.3 M196,369.5l1.6,4.8 M193.3,391.7c-0.7-0.8-1.2-1.6-1.5-2.6v-0.3l-0.3-1.1c-0.3-1.2-0.3-2.3-0.3-3.4 M189,398.3l4.3-6.6
	 M204.6,395.9c-1.6-0.7-3.2-1.8-4.5-3.2l-1.2-1.4 M204.4,396.1l0.1-0.3 M198.8,391.3l-0.4,0.4 M215,396.1l-0.6-0.7
	c-1-1.2-2.1-1.9-3.6-2.1c-0.7-0.3-1.4-0.3-2.1-0.3c-1.5,0-2.8,0.7-3.7,2.1l-0.6,0.8 M205.9,401l-3.6,4.3 M193.3,391.7h5.1
	 M205.9,401l-1.5-4.8 M189,398.3l-4.5,7c-0.6,1.2-1.5,1.4-2.6,0.7l-0.1-0.1c-1.9-2.3-3.6-4.8-4.8-7.4l-0.4-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M176.4,397.6c3.4-1.4,5.2-4.1,5.1-8.4v-8.7 M189,367.5l-7.6-2.1
	 M191.2,384.3l-7-2.8 M214.1,368.9v0.7c0,1.6,0.6,2.9,1.8,3.9c0.6,0.7,1,1.1,1.5,1.5c1.2,0.8,2.6,1,4.1,0.5l0.8-0.3 M217.9,360
	l0.3,5.2 M218.2,365.2l-4.1,3 M214.1,368.2v0.7 M214.1,368.9c-1.2-0.4-2.6-0.5-4-0.5l-3.4,0.4 M224.2,370l5.2-1.4 M225.6,364.5
	c-1.2-1.1-2.6-2.1-4-2.8 M224.2,370c-0.7-1.1-1.4-1.9-2.2-2.8l-0.6-0.4c-1-0.8-2.1-1.4-3.3-1.8 M225.6,364.5c0.4,0.4,1,0.8,1.4,1.2
	c1,1,1.8,1.9,2.6,2.9 M224.4,382.1c-0.1-2.6-0.8-5-2.1-7 M224.9,381.8l-0.6,0.3 M222.3,375.1l0.3-0.1 M229,384.9l-4.1-3
	 M224.4,382.1l-0.7,0.3c-1.5,0.4-2.6,1.4-3.2,2.9c-0.3,0.5-0.6,1-0.8,1.5c-0.6,1.5-0.4,3,0.7,4.4l0.6,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M224.4,382.1v0.5c0,3.6-1.1,6.6-3.4,9.2 M221.6,361.6
	c-1.2-0.7-2.3-1.2-3.7-1.6 M222.6,375l1.6-5 M222.3,375.1c-0.6-1-1.2-1.8-2.1-2.5c-1.8-1.8-3.9-3-6.2-3.7 M242,379.9l-8.1,3.2
	 M229.5,368.6l10.6-2.9c1.4-0.1,1.8-1,1.4-2.5l-1.6-2.3 M245,394.2c1.2-3.6,1.8-7.6,1.8-11.7l-0.1-2.8c-0.4-1.1-1.2-1.4-2.3-0.8
	l-2.3,1 M248.6,365.3v0.3c0.3,1.1-0.4,1.9-1.9,2.1l-24.1,7.2 M229.3,396.7l5.9,9.1c0.6,1.2,1.5,1.5,2.8,0.5c3.2-3.7,5.5-7.7,7-12.1
	 M230.8,394.3l11.1-4.8 M242,389.5v4.7h3 M215,396.1l0.3,0.3 M204.6,395.9c1.6,0.7,3.6,1.1,5.5,1.1c1.8,0,3.4-0.3,5-0.8
	 M220.9,391.9l0.3,0.3 M229,384.9c0.1,1.2-0.1,2.5-0.7,3.7l-0.3,0.7c-0.4,1-1.1,1.9-1.9,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M220.9,391.9l-0.8,0.8c-1.5,1.5-3.3,2.8-5.2,3.4 M226.3,392.1l3,4.5
	 M221.2,392.1h5.1 M213.8,401.4c-1.1,0.4-2.2,0.7-3.3,0.5h-1.1c-1.2-0.1-2.3-0.4-3.3-1 M217.1,405.5l-3.3-4.1 M202.4,405.2
	c2.3,0.8,5,1.1,7.6,1.1c2.5,0,4.8-0.3,7-1 M215.3,396.4l-1.5,5 M233.9,410.6l-12.7-18.4 M233.9,383.1l-4.8,1.8 M229.3,396.7
	c0.6-0.8,1.1-1.5,1.6-2.3 M225.6,364.5l14.2-3.6 M242,389.5v-9.6 M230.8,394.3c1.9-3.3,2.9-7.2,3-11.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M202.4,405.2l-6.7,8.4c-1,1-0.7,2.1,0.7,3.2c4.3,1.6,8.8,2.6,13.8,2.6
	c4.8,0,9.4-0.8,13.6-2.5c1.2-1,1.4-1.8,0.3-2.8l-6.9-8.5 M238.3,413.7l-0.3,0.1c-1,0.7-1.9,0.3-2.6-1.1l-1.5-2.2 M238.3,413.7
	c-2.5,2.3-5.2,4.3-8.1,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.3,419.7l0.7-0.7c0.7-0.8,0.6-1.7-0.4-2.6l-1.6-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M189.4,419.4c6.2,3.6,13.1,5.2,20.6,5.2c7.4,0,14.2-1.6,20.2-5.1
	 M198.4,391.7l-14.2,20.8c-0.7,1.2-1.5,1.6-2.3,1.2l-0.6-0.3 M204.4,396.1l-15.3,19.9c-1.1,1.1-1.1,2.1-0.3,2.8l0.6,0.5
	 M181.3,413.3c2.5,2.3,5.2,4.4,8.1,6.1 M228.9,414.3c1.6-1.1,3.3-2.3,5-3.6 M228.9,414.3l-13.6-17.9 M282.7,377.2
	c-1.1-10.7-2.8-20.8-4.5-30.1 M217.6,355.1l0.3,4.8 M239.8,360.9c-0.8-1.1-1.6-2.1-2.6-3 M237.2,357.9l-15.5,3.7 M181.4,362
	l21.6-6.5 M248.6,365.3c1.4,2.9,2.2,5.9,2.9,9.1 M202.9,359.8l-0.4,5.4 M420.1,284.6l-8.7,0.1c-0.8,0-1.4-0.4-1.5-1.4
	c-0.3-1.6-0.3-3.3,0.1-4.7c0.1-0.8,0.7-1.4,1.6-1.2l8.4,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417,280.2c-0.3-0.3-0.8-0.5-1.2-0.5s-0.8,0.1-1.2,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.2s0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5s1-0.1,1.2-0.5c0.3-0.3,0.4-0.7,0.4-1.2S417.2,280.4,417,280.2
	L417,280.2z M420.1,277.4v7.2 M447.1,277.7c-0.1-1.2-0.4-2.2-0.8-3c-0.8-1.6-2.3-2.6-4.4-2.9l-4.7-0.3l-5,0.3
	c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.6-0.8,2.9 M448.7,284l7.6-0.1c1.2-0.3,1.9-1,2.2-1.8v-2.5c-0.3-1.1-1-1.6-1.9-1.8
	l-7.7-0.1 M448.7,277.7h-1.6 M427.1,284.4c0.1,2.1,0.8,3.7,2.2,5c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3l4.1,0.4h0.5c1.2,0,2.3-0.1,3.6-0.4
	l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.2,2.1-3,2.2-5.2 M446.8,284.2l1.9-0.1 M447.1,277.7l-20.1-0.1 M446.8,284.2l-19.7,0.3
	 M448.7,277.7v6.3 M398,383.5V259.8 M420.1,284.6l7-0.1 M427,277.6l-6.9-0.1 M398,383.5H291.8 M499.9,170.4
	c7.4,2.2,14.3,4.8,20.6,7.6 M536.6,256.9l-126.6-3.2 M398,253.3v6.5 M576.5,383.5H398 M398,259.8l138.8,2.9 M284.1,390.6h301.3
	 M507.9,182.5l-3.2-1.9c-12.7-7-28.8-11.8-48.1-14.3h-0.1l-13.2-1.4c-7.6-0.3-16.2-0.4-26-0.6l-16.5-0.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M758.7,320.8
		l-21-0.8l-11.3-0.4c0,0-7.7-8.9-19.9-11.7c0,0-14.6-3-33.4-2.5l-12-0.1h-8.7c0,0-24.2-0.6-34,6.7c0,0-8.8,4.5-13.2,17.5
		c0,0-3.6,12.1-6.1,26.5c0,0-2.1,12.7-3.3,27.4h-19c0,0,3.3-15.7,4.7-26.4c0,0,2.2-14.2,3.7-36.3c0,0,1.7-21.7-0.6-44.7
		c0,0-0.4-10.7-5.4-32.2c0,0-1.9-6.2,1.8-7.3l8.3,7.2c0,0,4.4,3.7,12.4,5.9c0,0,4.4,1.4,13.1,1.5c0,0,29.6,1.7,58.7,4.5
		c0,0,20.4,1.8,37.1,4.5c0,0,15.7,2.2,34.8,5.9c0,0,11.7,2.3,19.3,4.5c0,0,7.7,1.1,11.6,12.9h-22c0,0-3.2-0.3-2.5,5.8L758.7,320.8z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M583.1,264.1l-49.5-1.4
		L398,259.8v123.5h178.4c0,0,3.2-12.7,6.2-37.6c0,0,3.4-25,2.9-51C585.6,294.9,585.3,277.7,583.1,264.1z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M547.8,255.7
		c0,0-3.9,1.4-15.1,0c0,0-6.1-0.7-6.6-6.1c0,0-0.3-9.5,2.8-14.9c0,0,1.8-3.2,7-3.9l5.1-0.3c0,0,4.4-0.7,5.9,3l1.9,8.7h5.5
		c0,0,1.5-0.3,1.2,2.8C555.6,245.1,555.5,251.6,547.8,255.7z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M529.5,254.4
		l-118.3-2.9l1.4-66c0,0-0.4-6.7,4-7.7l14.3,0.3c0,0,33.6,0.4,56,10.9c0,0,17.6,7.2,49,33.3l0.3,8.7c0,0-4.7,0.4-7,3.9
		c0,0-1.9,2.8-2.6,9.1c0,0-0.3,3.9-0.3,5.6C526.2,249.5,526.3,252.9,529.5,254.4z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M398,383.5H291.8
		c0,0-3-27.4-6.1-40.6c-3-13.2-5.2-36.5-37.1-46.2l-1.5-0.3c0,0-0.6-13.8,0.7-36.6l21.9-11.4c0,0,2.6,6.1,28.5,8.7l6.9,0.3l93.1,2.3
		v123.8H398z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M247.1,304.8
		c0,0-0.6-20.5,0.7-45l-30.4-15.5c0,0-4.7,4.1-16.6,4.1H81.3v69.9h66.9c0,0,3.4-6.6,12.4-11.3c0,0,6.3-3.9,22.4-4.7
		c0,0,5.9-0.3,13.9-0.3c0,0,7.4,0,13.3,0C210.2,302,230.7,301.4,247.1,304.8z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M219.8,241.5l31.5-57.6
		c0,0,2.9-3.3,1.1-5.6c0,0-1-2.3-9.1-2.5l-133.9,3c0,0-19,29.9-28.2,69.1v0.6h119.5C200.7,248.4,215.3,249.1,219.8,241.5z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M381.1,250.6
		l3.6-68.1v-0.8c0,0,0-4.1-3.3-4.7l-66.3-0.1c0,0-12-0.3-20.1,3.4c0,0-8.3,2.6-12.7,12.7c0,0-6.9,14.2-9.8,27.8
		c0,0-1.8,7.7-1.5,14.6c0,0-1,4.5,4,8.3c0,0,4.1,3.7,18.3,4.4L381.1,250.6z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="210.2"
              cy="382.7"
              r="61.4"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5025"
              strokeMiterlimit="10"
              cx="670.6"
              cy="382.5"
              r="61.7"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv4DrRight);
