import React, { Component } from 'react';
import { connect } from 'react-redux';

class Crossover4DrRight extends Component {
  constructor(props) {
    super(props);
  }
  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M220.7,201.8c0.1,0.3,0.3,0.4,0.6,0.6l0.7,0.1l0.7-0.3c2.9-0.7,6.1-1.2,9.6-1.8c16.5-1.9,31.4-3.2,45-3.7
	c31.5-1.7,64.2-2.1,98.1-1.1c21.5,0.3,39.3,1.1,53.8,2.3c10.5,1,19.1,2.2,26,3.6l0.7,0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.5-0.6
	c0.1-0.3,0-0.6-0.1-0.7c-0.1-0.3-0.3-0.4-0.6-0.6c-2.3-0.7-5.1-1.4-8.1-1.9c-2.8-0.6-5.8-1-8.9-1.4c-10.3-1.6-24.6-2.9-42.8-3.9
	c19,0.4,36.2,1.2,51.6,2.6l18.7,1.9c15.7,2.6,27.5,7,35.4,13.5H459c-27.1-1.8-56.5-2.8-88.3-3c-27-0.1-55.6,0.1-85.8,1
	c-14.6,0-24.1,2.6-28.3,7.8L198,214l0.1-0.1L198,214l-11.8-0.6c0.8-2.6,3.4-4.5,7.8-5.5c6.3-2.1,9.5-4.7,9.4-7.7
	c6.3-1,13.2-1.9,20.5-2.8l19.8-1.9l7.4-0.6c-7.6,0.8-14.7,1.9-21.5,3.3c-3,0.6-5.8,1.2-8.1,1.9c-0.3,0.1-0.4,0.3-0.6,0.6
	C220.7,201.3,220.7,201.5,220.7,201.8L220.7,201.8z"
          />
          <path
            fill="#FFFFFF"
            d="M221.2,202.3c-0.3-0.1-0.4-0.3-0.6-0.6c-0.1-0.3,0-0.6,0.1-0.7c0.1-0.3,0.3-0.4,0.6-0.6
	c2.3-0.7,5.1-1.4,8.1-1.9c6.7-1.4,13.9-2.5,21.5-3.3l16-1.1c23.9-1.4,51.5-2.1,82.4-2.2c9.4,0,18.6,0,27.2,0.1l19.7,0.6
	c18.3,1,32.5,2.2,42.8,3.9c3.3,0.4,6.3,1,8.9,1.4c3,0.6,5.8,1.2,8.1,1.9c0.3,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.6,0.1,0.7
	c-0.1,0.3-0.3,0.4-0.5,0.6l-0.7,0.1l-0.7-0.3c-6.9-1.5-15.5-2.8-26-3.6c-14.4-1.4-32.5-2.1-53.8-2.3c-33.8-1-66.6-0.6-98.1,1.1
	c-13.5,0.6-28.5,1.6-45,3.7c-3.6,0.6-6.7,1.1-9.6,1.8l-0.7,0.3L221.2,202.3L221.2,202.3z M256.7,222.3l-2.8,12.1l3.9,27.9l-12.9-0.5
	l9.2-40.2c0.4-1.8,1.2-3.4,2.3-5c4.3-5.2,13.8-8,28.3-7.8c30.3-0.8,58.9-1.2,85.8-1l0.5,2.8h-10.3c-24.1,0-49.4,0.3-76.1,1.1
	C268,211.5,258.6,215.1,256.7,222.3L256.7,222.3z M245.3,264.5H244l-4.4,16.2l-0.4,26c13.1,3,24.8,9.2,35.1,18.7
	c7.4,6.9,12.9,14.4,16.6,22.7c4.3,10.2,6.7,20.6,7.6,31.5l90.5,1.6c2.3-25.2,2.5-50.1,0.4-74.7c-1-14-3.2-26.1-6.7-36.5L245.3,264.5
	L245.3,264.5z M278.8,291.5c0,0.5-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.7c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7
	c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2h-1.2c-0.6,0.5-1.1,0.8-1.8,0.8h-0.3c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3
	s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7
	c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6h0.5c1.7,0,3,0.3,4.1,0.7C278.3,290.2,278.8,290.8,278.8,291.5L278.8,291.5z"
          />
          <path
            fill="#FFFFFF"
            d="M277.1,289.8c1.1,0.4,1.6,1,1.6,1.5s-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.5L255,293h-1.2
	c-0.6,0.5-1.1,0.8-1.8,0.8h-0.3c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1
	c0.7,0,1.4,0.3,1.9,1l1.8,0.1l16.8,0.8h0.5C274.6,289.2,276,289.4,277.1,289.8L277.1,289.8z"
          />
          <path
            fill="#FFFFFF"
            d="M270.7,286.6c0.8,0.8,1.4,1.6,1.6,2.6l-16.8-0.8c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7
	C267.6,284.6,269.4,285.3,270.7,286.6L270.7,286.6z M272.4,293.6c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7
	c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2L272.4,293.6L272.4,293.6z M244.7,261.7l-0.7,2.8l-92.7-3.9l3.6-2.6l16.1,0.7
	 M161.5,208.2c-0.8-0.7-1-1.5-0.3-2.5l0.1-0.1c1-1.4,3.4-2.3,7.4-2.9l34.4-2.2c0,3-3,5.5-9.4,7.7c-4.4,1-7,2.8-7.8,5.5l-8.7-0.4
	C169.3,212.6,164.1,211,161.5,208.2L161.5,208.2z"
          />
          <path
            fill="#FFFFFF"
            d="M177.3,213.3l8.7,0.4c-10,11.8-20.4,26.6-31,44.3l-14-0.5C153.5,239.7,165.8,225,177.3,213.3L177.3,213.3z"
          />
          <path
            fill="#FFFFFF"
            d="M193,220.9l1.5,2.2l-15.8,27.5l-3.6,1.1l-4.1,7l-16.1-0.7c10.6-17.7,20.9-32.6,31-44.3l11.8,0.6L193,220.9
	L193,220.9z M154.9,258l-3.6,2.6l-10.7-0.4c-0.4,0-0.7-0.1-1-0.4l-0.1-0.1c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.7-0.4,1-0.4L154.9,258L154.9,258z"
          />
          <path
            fill="#D3D3D3"
            d="M149.1,271.6h-4.8c-2.2,0.1-3.9,0.8-5.1,2.2l0.7-1.4c1.4-1.5,3.4-2.3,6.2-2.5h5.9c3.3,0,5.4,1.8,6.1,5.2
	c0.7,4.8,0.6,11.8-0.4,20.8c-0.1,10.2-1.9,17.5-5.2,21.6l-0.3,0.4c-1.5,1.4-4,2.1-7.7,2.3c-4,0.1-6.9-0.7-8.4-2.8
	c-0.6-0.7-1-1.5-1.2-2.3l-0.1-0.8c-0.3-2.9-0.4-6.7-0.1-11.6c-0.1,4.1,0,7.3,0.3,9.6c0.8,3.3,3.6,4.8,8,4.5c3-0.3,5.1-1,6.3-2.1
	c2.9-3.6,4.4-10.2,4.5-19.8c0.7-8.1,0.8-14.3,0.3-18.7C153.5,273.3,151.9,271.8,149.1,271.6L149.1,271.6z"
          />
          <path
            fill="#FFFFFF"
            d="M143.3,267.4l-1.2,1.5c-0.8,1.1-1.5,2.2-2.2,3.4c1.4-1.5,3.4-2.3,6.2-2.5h5.9c3.3,0,5.4,1.8,6.1,5.2
	c0.7,4.8,0.6,11.8-0.4,20.8c-0.1,10.2-1.9,17.5-5.2,21.6l-0.3,0.4c-1.5,1.4-4,2.1-7.7,2.3c-4,0.1-6.9-0.7-8.4-2.8
	c-0.6-0.7-1-1.5-1.2-2.3l-0.1-0.8v11.3h30.4l0.1-0.1c15-13.9,33.2-20.9,54.5-20.9c6.7,0,13.3,0.7,19.4,2.2l0.4-26l4.4-16.2
	l-92.7-3.9C148.3,262.3,145.5,264.6,143.3,267.4L143.3,267.4z M204,277c2.5-2.5,5.5-3.7,9.1-3.7s6.6,1.2,9.2,3.7
	c2.5,2.5,3.7,5.6,3.7,9.2s-1.2,6.6-3.7,9.1c-2.5,2.5-5.6,3.9-9.2,3.9s-6.6-1.2-9.1-3.9c-2.5-2.5-3.9-5.5-3.9-9.1
	S201.5,279.6,204,277L204,277z"
          />
          <path
            fill="#FFFFFF"
            d="M200.2,286.2c0-3.6,1.2-6.6,3.9-9.2c2.5-2.5,5.5-3.7,9.1-3.7s6.6,1.2,9.2,3.7c2.5,2.5,3.7,5.6,3.7,9.2
	s-1.2,6.6-3.7,9.1c-2.5,2.5-5.6,3.9-9.2,3.9s-6.6-1.2-9.1-3.9C201.5,292.8,200.2,289.8,200.2,286.2L200.2,286.2z M151.3,260.6
	c-3,1.6-5.8,4-8.1,6.7l-1.2,1.5c-0.8,1.1-1.5,2.2-2.2,3.4c-0.7,0.7-1.1,1.5-1.5,2.5v0.1c-1.5,4-2.6,11.6-3.4,22.7l-0.3,5.1
	c-0.3,4.8-0.3,8.7,0.1,11.6v11.3l-10.7,8.7c-5.5-23-5.5-42.8,0.3-59c0.6-1.1,1.1-2.1,1.8-3l2.2-3.2c3.2-4.3,7-7.4,11.4-9.5l0.1,0.1
	c0.3,0.3,0.6,0.4,1,0.4L151.3,260.6L151.3,260.6z M274.3,325.6c7.4,6.9,12.9,14.4,16.6,22.7c4.3,10.2,6.7,20.6,7.6,31.5l-18.4-0.4
	l18.4,0.4l90.5,1.6l148.4,2.8l5.5,0.1l15.4,0.3v16.1l-217.2-1.8l-61.1-0.4c0.1-4.7,0-9.6-0.1-14.9c0-1.4-0.1-2.8-0.3-4
	c-0.3-2.9-0.7-5.9-1.4-8.7c-0.5-2.8-1.4-5.4-2.3-8c-0.8-2.3-1.8-4.7-2.9-6.9c-2.8-5.5-6.3-10.6-10.9-15.4
	c-7-7.4-14.7-12.7-23.4-15.8l0.3-18C252.3,309.7,264,316.1,274.3,325.6L274.3,325.6z"
          />
          <path
            fill="#FFFFFF"
            d="M389,381.4l148.4,2.8c2.6-15,4.4-34.3,5.4-57.6l0.1-1v-0.3c-0.1-28.3-4.4-44.7-13.1-49.1h-0.5l-11.1-0.4
	l0.5,1.4l0.5,1.2l3.6,4.7c1.8,2.6,1,4.8-2.3,6.6c-5.4,1.6-10.6,1.6-15.7,0c-3.9-1.6-5.5-4.8-5-9.6c-2.5,0-4.4-1.2-6.2-3.4
	c-0.5-0.7-1-1.4-1.2-2.1L383,270c3.6,10.3,5.9,22.4,6.7,36.5C391.6,331.3,391.5,356.2,389,381.4L389,381.4z M391.9,294.5
	c0-1,0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7
	c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6h0.5c1.6,0,3,0.3,4.1,0.7s1.6,1,1.6,1.5s-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.5
	c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2h-1.2c-0.5,0.5-1.1,0.8-1.8,0.8h-0.1
	c-2.1,0-3.9-0.4-5.4-1C392.6,296.1,391.9,295.3,391.9,294.5L391.9,294.5z"
          />
          <path
            fill="#FFFFFF"
            d="M394.3,296.8c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1
	l1.8,0.1l16.8,0.8h0.5c1.6,0,3,0.3,4.1,0.7c1.1,0.4,1.6,1,1.6,1.5c0,0.5-0.5,1.1-1.6,1.5c-1.1,0.4-2.5,0.7-4.1,0.7h-0.5l-17.3-0.5
	h-1.2c-0.5,0.5-1.1,0.8-1.8,0.8h-0.1C397.6,297.6,395.8,297.4,394.3,296.8L394.3,296.8z"
          />
          <path
            fill="#FFFFFF"
            d="M404.7,290.5c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6l-16.8-0.8
	C403.7,291.6,404.2,291,404.7,290.5L404.7,290.5z M420.3,297.5c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7
	c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2L420.3,297.5L420.3,297.5z M186.1,331.9c-3.4,2.5-6.6,5.2-9.6,8.5
	c-3.4,3.7-6.3,7.6-8.8,11.6h-20.8c1.8-4.5,4-8.8,6.6-12.8c2.6-4,5.8-7.8,9.5-11.4l2.2-2.1l0.1-0.1c15-13.9,33.2-20.9,54.5-20.9
	c6.7,0,13.3,0.7,19.4,2.2l-0.3,18c-6.1-2.2-12.7-3.3-19.5-3.3C206.9,321.4,195.9,324.9,186.1,331.9L186.1,331.9z"
          />
          <path
            fill="#FFFFFF"
            d="M165.2,325.7l-2.2,2.1c-3.6,3.6-6.7,7.4-9.5,11.4c-2.6,4.1-4.9,8.4-6.6,12.8c-1.4,3.7-2.5,7.6-3.3,11.6
	c-1.8,6.1-5.1,9.2-9.8,9.5h-12.5c-2.3,0-3.7-1.4-4.3-3.9c-2.5-5.9-4.8-7.8-6.9-5.9l-1.4,5.2c-1-3.9-1.5-9.8-1.4-17.6
	c0-2.2-1-3.6-2.9-4.1l1.1-5.1c0.4-1.4,1.8-2.1,4-1.9c6.2,0.1,11.1-1.6,14.6-5.4l10.7-8.7L165.2,325.7L165.2,325.7z"
          />
          <path
            fill="#FFFFFF"
            d="M143.5,363.7c0.8-4,1.9-7.8,3.3-11.6h20.8l-0.6,0.8c-5.4,9.4-8.3,19.8-8.5,31.5v9.1
	c-16.6-1-28.6-3.6-35.8-7.7c-4.1-2.3-6.7-5.2-7.6-8.7c-0.1-0.5-0.3-1.2-0.4-1.9c-1.9-5.6-4-7.8-6.3-6.7l1.4-5.2
	c2.2-1.9,4.4,0,6.9,5.9c0.6,2.5,2.1,3.9,4.3,3.9h12.5C138.4,372.9,141.7,369.7,143.5,363.7L143.5,363.7z"
          />
          <path
            fill="#D3D3D3"
            d="M138.4,274.8c0.4-1,0.8-1.8,1.5-2.5l-0.7,1.4l-0.8,1.2L138.4,274.8L138.4,274.8z"
          />
          <path
            fill="#FFFFFF"
            d="M674.1,325.6c1.1,1,2.1,1.9,3,2.9c6.9,7.1,11.8,15,15,23.7c1.2,3.4,2.2,7,2.9,10.6h-16.6
	c-0.8-2.3-1.9-4.7-3-6.9c-2.9-5.5-6.5-10.6-11-15.4c-12.1-12.7-26.7-19-43.9-19c-12.7,0-24.1,3.4-34,10.5c-3.4,2.5-6.7,5.2-9.9,8.5
	c-3.7,3.9-6.9,8.1-9.5,12.5c-5.5,9.4-8.4,19.8-8.7,31.5l-15.4-0.3c0.5-5.6,1.4-12,2.5-19c1.7-9.5,5-18.2,10-26
	c2.6-4,5.6-7.8,9.2-11.4l2.3-2.2c4.3-4,8.8-7.6,13.6-10.5s10-5.2,15.4-6.9c7.6-2.5,15.7-3.6,24.3-3.6
	C641.5,304.7,659.3,311.5,674.1,325.6L674.1,325.6z"
          />
          <path
            fill="#FFFFFF"
            d="M729.4,337.8c-0.8,4,0.4,6.3,3.7,7c3.9,0,6.1,2.2,6.3,6.3v12.5c0,2.1-1,3.4-2.6,4.1
	c-0.5,0.3-1.4,0.4-2.1,0.5h-3.2c-2.3,0.3-3.4,1.8-3.6,4.7v13.2c0,1.9,0.8,3.3,2.8,4.3l1,0.4l1.9,0.5l0.4,0.1l-1.7-0.3l-1.9-0.5
	l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-5c-0.1-1.9-1-3-2.5-3.3l-21.9-1.9c-4.3,0-6.5-1.9-6.5-5.6c-0.1-2.5-0.5-5-1-7.4
	c-0.7-3.7-1.7-7.1-2.9-10.6c-3.2-8.5-8.1-16.5-15-23.7l39.9,3.7v2.5c-0.1,1.1,0,1.9,0.4,2.5c0.4,0.4,1,0.7,1.7,0.7
	c0.5-0.4,1-1,1.2-1.6c0.4-1.5,1.4-2.2,2.6-2.2h4.3C729.1,334.1,729.9,335.3,729.4,337.8L729.4,337.8z"
          />
          <path
            fill="#FFFFFF"
            d="M720.3,336.2c-0.3,0.7-0.7,1.2-1.2,1.6c-0.7,0-1.2-0.3-1.7-0.7l1.1-2.5c0.8-1.8,2.3-2.6,4.3-2.6
	c2.3,0.1,3.3,0.8,2.9,1.9h-2.9C721.7,334,720.7,334.8,720.3,336.2L720.3,336.2z"
          />
          <path
            fill="#FFFFFF"
            d="M717,334.6v-2.5c0-5.1,0.8-11.3,2.3-18.3c1-3.6,2.6-5.6,5.1-6.2c0.5,9.9,0,18-1.5,24.4
	c-2.1-0.1-3.4,0.7-4.3,2.6l-1.1,2.5C717,336.6,716.8,335.7,717,334.6L717,334.6z M731.4,368.3h3.2c0.3,3.4,0.3,6.9-0.1,10.3
	c-0.4,4-1.4,8.1-2.8,12.4l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-13.2C728,370.1,729.1,368.6,731.4,368.3L731.4,368.3z"
          />
          <path
            fill="#FFFFFF"
            d="M734.6,368.3c0.8-0.1,1.5-0.3,2.1-0.5c0.8,7.7-0.1,15.7-3,23.8l-1.9-0.5c1.4-4.1,2.3-8.3,2.9-12.4
	C734.9,375.2,734.9,371.8,734.6,368.3L734.6,368.3z"
          />
          <path
            fill="#FFFFFF"
            d="M726.8,381.1v5c0,1.9,0.8,3.3,2.8,4.3l1,0.4l1.9,0.6l1.7,0.3l2.3,0.3c1.2,0.5,1,1.9-0.8,4.3
	c-1.7,1.6-5.2,2.9-10.9,3.7c-7.2,1-16.1,1.6-26.7,1.9c-4.8,0.1-10,0.3-15.7,0.3c0.3-5.6,0.3-12,0-18.6c-0.3-7.4-1.7-14.3-4-20.8H695
	c0.4,2.5,0.8,4.8,1,7.4c0,3.9,2.2,5.8,6.5,5.6l21.9,1.9C725.8,378.1,726.6,379.2,726.8,381.1L726.8,381.1z M685.2,412.9
	c-2.3,0.4-3.6,0.1-3.6-1v-0.3c0.3-3,0.5-6.2,0.7-9.5c5.6,0,10.9-0.1,15.7-0.3c-0.3,4.4-1.9,7.2-5.2,8.5L685.2,412.9L685.2,412.9z
	 M508.2,258.3c1,0.4,1.9,1,2.9,1.5l0.5,0.4c2.6,1.8,4.1,3.9,4.7,6.3c0.1,0.7,0.3,1.2,0.3,1.9c0,0.8-0.1,1.5-0.3,2.3
	c-0.1,0.7-0.5,1.5-1,2.2c-0.5,1-1.2,1.8-2.2,2.6l-2.1,1.5c-1.8,1.1-3.6,1.9-5.8,2.5l-2.8,0.4l-1.2,0.1c-2.2-0.3-4-1.4-5.5-3.4
	c-0.4-0.5-0.8-1.2-1.2-1.9c-0.4-0.8-0.8-1.8-1.1-2.8c-0.3-1.1-0.4-2.3-0.4-3.6c0-3.2,0.8-5.9,2.6-8.3c1.5-2.1,3.4-3.2,5.5-3.3
	l1.2,0.1C504.6,257.2,506.5,257.6,508.2,258.3L508.2,258.3z"
          />
          <path
            fill="#D3D3D3"
            d="M516.6,268.5c0-0.7-0.1-1.4-0.3-1.9l6.5,6.7l-5.6-0.3l-0.8-2.2C516.4,270,516.6,269.3,516.6,268.5
	L516.6,268.5z"
          />
          <path
            fill="#FFFFFF"
            d="M499.9,256.8h1.5c-2.2,0.3-4,1.4-5.5,3.3c-1.8,2.3-2.6,5.1-2.6,8.3c0,1.2,0.1,2.5,0.4,3.6
	c0.3,1,0.5,1.9,1.1,2.8c0.4,0.7,0.8,1.4,1.2,1.9c1.5,2.1,3.4,3.2,5.5,3.4h-1.8c-2.5,0-4.4-1.2-6.2-3.4c-0.5-0.7-1-1.4-1.2-2.1
	c-0.4-0.8-0.8-1.8-1-2.8c-0.3-1.1-0.4-2.2-0.4-3.4c0-3.2,0.8-5.9,2.6-8.3C495.2,258,497.4,256.8,499.9,256.8L499.9,256.8z
	 M546,288.7l16.2,15.7c2.3,2.2,5.2,3.3,8.5,3.2l10-0.4c3-0.1,3.3-1.2,0.8-3.3L564.6,289c-1.9-1.8-4.4-2.6-7.3-2.6l-9.4-0.3
	c-1.8,0-2.8,0.1-2.9,0.8v0.4C545,287.7,545.4,288.1,546,288.7L546,288.7z M551.8,288.1c1.2,0.1,2.5,0.5,3.9,1.2
	c1.4,0.5,2.5,1.2,3.2,1.9l13.1,11.7c0.8,0.7,1.1,1.2,1.1,1.8c-0.1,0.5-0.7,0.8-1.7,0.8s-2.1-0.3-3.4-0.7c-1.4-0.5-2.5-1.2-3.3-1.9
	l-14.4-12.8c-0.8-0.8-1.1-1.4-0.8-1.8C549.7,288,550.4,287.9,551.8,288.1L551.8,288.1z"
          />
          <path
            fill="#FFFFFF"
            d="M549.4,288.4c0.3-0.4,1.1-0.5,2.3-0.3c1.2,0.1,2.5,0.5,3.9,1.2c1.4,0.5,2.5,1.2,3.2,1.9l13.1,11.7
	c0.8,0.7,1.1,1.2,1.1,1.8c-0.1,0.5-0.7,0.8-1.7,0.8s-2.1-0.3-3.4-0.7c-1.4-0.5-2.5-1.2-3.3-1.9l-14.4-12.8
	C549.3,289.4,549,288.8,549.4,288.4L549.4,288.4z M502.7,280l2.8-0.4c2.1-0.5,4-1.4,5.8-2.5l2.1-1.5c1-0.8,1.7-1.8,2.2-2.6
	c0.4-0.7,0.7-1.4,1-2.2l0.8,2.2l1.1,2.8l0.5,1.4c-0.7,3.9-6.3,4.8-17.2,3L502.7,280L502.7,280z"
          />
          <path
            fill="#FFFFFF"
            d="M499.6,280.2h1.8c10.9,1.8,16.6,0.8,17.2-3l0.5,1.2l3.6,4.7c1.8,2.6,1,4.8-2.3,6.6c-5.4,1.6-10.6,1.6-15.7,0
	C500.7,288,499.1,284.8,499.6,280.2L499.6,280.2z M245.4,264.6l-0.1-0.1H244l0.7-2.8l12.9,0.5l109.8,4.5l14.7,0.5l0.5,2.8
	 M371.1,210.6l-0.5-2.8c31.8,0.3,61.2,1.2,88.3,3c0.3,0,0.5,0.1,0.8,0.3c22,18.2,42.8,37.1,61.9,57.1l5,5.2l3.2,2.8h-0.5l-11.1-0.4
	L517,273l5.6,0.3l-6.5-6.7c-0.5-2.3-2.1-4.5-4.7-6.3l-0.5-0.4c-1-0.5-1.9-1.1-2.9-1.5c-15.7-15.5-32.3-30.4-49.8-44.8
	c-23.9-1.5-49.7-2.5-77.2-2.9L371.1,210.6L371.1,210.6L371.1,210.6z"
          />
          <path fill="#FFFFFF" d="M371.1,210.6h10l16,57.5l-15-0.7" />
          <path
            fill="#FFFFFF"
            d="M382.7,270.1l-0.5-2.8l15,0.7l94.1,3.9c0.3,1,0.5,1.9,1,2.8L382.7,270.1L382.7,270.1z M382.2,267.4
	l-14.7-0.5l-6.6-56.4h10.3L382.2,267.4L382.2,267.4z M677.1,328.5l39.9,3.7c0-5.1,0.8-11.3,2.3-18.3c1-3.6,2.6-5.6,5.1-6.2l0.8-0.1
	c1.9-0.7,2.5-2.1,1.2-4.4c-1.8-3.6-7.4-8-16.9-13.1c-17.1-8.5-42.4-14.2-76.1-16.9c-11.1-0.4-22.8-0.5-35.4-0.5l-48.1,2.5
	c18,9.8,33.4,20.8,46.2,33c7.6-2.5,15.7-3.6,24.3-3.6c20.9,0,38.7,7,53.5,20.9L677.1,328.5L677.1,328.5z M665.4,285.7
	c15.3,3.6,25.9,7.4,31.6,11.6c3.3,2.3,5.6,5.6,6.9,9.8c1.5,6.5,1.8,11.4,1,14.9c-0.3,3-2.5,4.5-6.5,4.5c-6.2,0.1-11.1-1.2-15-4.1
	c-10.7-8.3-19.1-16.1-25.6-23.7c-3.9-4.3-5-7.8-3.3-10.7c1.4-2.3,4.5-3.2,9.2-2.5c-15-2.6-29.6-4.3-43.6-5.1
	c14,0.8,28.5,2.5,43.6,5.1L665.4,285.7L665.4,285.7z"
          />
          <path
            fill="#D3D3D3"
            d="M663.9,285.4c-4.7-0.7-7.8,0.1-9.2,2.5c-1.7,2.9-0.5,6.5,3.3,10.7c6.3,7.6,14.9,15.5,25.6,23.7
	c3.8,2.9,8.8,4.3,15,4.1c4,0,6.2-1.5,6.5-4.5c0.8-3.6,0.5-8.5-1-14.9c-1.2-4.1-3.6-7.4-6.9-9.8c-5.9-4.1-16.5-8-31.6-11.6
	L663.9,285.4L663.9,285.4z M670.9,288.6c11.8,2.9,20.1,6.1,24.9,9.5c3.2,2.2,5.4,5.2,6.6,8.9c1,3.7,1.4,7,1.5,9.8v0.3
	c0,1.5-0.1,2.6-0.4,3.7c0,0.4-0.1,0.8-0.3,1.2c-1.1,1.9-3.7,2.5-7.8,1.8c-4-0.5-8.1-2.6-12.2-5.9c-8.3-6.5-14.9-13.1-19.7-19.4
	c-2.6-3.4-3-6.2-1.1-8.3C664,288.1,666.8,287.7,670.9,288.6L670.9,288.6z"
          />
          <path
            fill="#FFFFFF"
            d="M521.6,268.2c-19.3-19.9-39.9-38.9-61.9-57.1c-0.3-0.1-0.5-0.3-0.8-0.3h11.3l70.2,62.6h-13.8L521.6,268.2z"
          />
          <path
            fill="#FFFFFF"
            d="M529.9,276.2l-3.2-2.8h13.8l29-0.1c6.9-0.1,13.5-0.3,19.9-0.4l8.7-0.1l-48.1,2.5l-14,0.7L529.9,276.2
	L529.9,276.2z M529.9,276.2l6.1-0.3l7,4.8c1.1,1,1.7,1.9,1.9,3.2v4.1c2.3,4,8.7,10.5,19,19.4c1.8,1.1,3.4,1.8,5.1,1.9h0.1
	c2.8-0.4,4.8-0.1,6.3,1l0.3,0.3l5.1,4.5c-4.8,2.9-9.4,6.3-13.6,10.5l-2.3,2.2c-3.6,3.6-6.7,7.4-9.2,11.4c-5.1,7.8-8.5,16.5-10,26
	c-1.2,7-2.1,13.2-2.5,19l-5.5-0.1c2.6-15,4.4-34.3,5.4-57.6l0.1-1v-0.3C543,297,538.6,280.6,529.9,276.2L529.9,276.2z"
          />
          <path
            fill="#FFFFFF"
            d="M545,287.3c0.1,0.4,0.4,0.8,1,1.4l16.2,15.7c2.3,2.2,5.2,3.3,8.5,3.2l10-0.4c3-0.1,3.3-1.2,0.8-3.3
	L564.6,289c-1.9-1.8-4.4-2.6-7.3-2.6l-9.4-0.3c-1.8,0-2.8,0.1-2.9,0.8v-3c-0.3-1.2-1-2.2-1.9-3.2l-7-4.8l14-0.7
	c18,9.8,33.4,20.8,46.2,33c-5.4,1.6-10.5,4-15.4,6.9l-5.1-4.5l-0.3-0.3c-1.5-1.1-3.6-1.5-6.3-1h-0.1c-1.7-0.1-3.4-0.8-5.1-1.9
	c-10.5-8.9-16.8-15.4-19-19.4L545,287.3L545,287.3z M217.9,179.5c0.3,0.1,0.4,0.3,0.4,0.4l9.9,11.7v0.1c0.4,1.1-0.4,1.8-2.2,1.9
	l-9.2-13.3v-0.6c0.1-0.3,0.3-0.4,0.4-0.4L217.9,179.5L217.9,179.5z"
          />
          <path
            fill="#FFFFFF"
            d="M226,193.6c1.9-0.1,2.6-0.8,2.2-1.9v-0.1c1.8-0.8,5-1,9.5-0.7c3.2,0,5.1,1.6,5.9,5l-19.8,1.9
	C224.2,195,224.9,193.6,226,193.6L226,193.6z"
          />
          <path
            fill="#656565"
            d="M273.2,355.8c1.1,2.2,2.1,4.5,2.9,6.9c1,2.6,1.6,5.2,2.3,8c0.7,2.8,1.1,5.6,1.4,8.7c0.1,1.4,0.3,2.8,0.3,4
	c0.3,5.4,0.3,10.3,0.1,14.9v1.2l-0.7-0.1c-0.5,0-1.1,0.3-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6v1.9l-11.4-0.1c2.8-6.3,4.3-13.2,4.3-20.6
	c0-12.4-3.9-23.2-11.7-32.3c-1-1.1-2.1-2.3-3-3.3c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1
	c-2.3,1.6-4.5,3.6-6.6,5.6c-2.6,2.6-4.8,5.2-6.6,8c-5.5,8.1-8.1,17.3-8.1,27.6c0,5.2,0.7,10.2,2.1,14.7l-12.5-0.8v-12.5
	c0.3-11.7,3-22.1,8.5-31.5l0.6-0.8c2.5-4.1,5.4-8,8.8-11.6c3-3.3,6.3-6.1,9.6-8.5c9.8-7,20.8-10.5,33.3-10.5
	c6.9,0,13.5,1.1,19.5,3.3c8.7,3,16.5,8.4,23.4,15.8C266.7,345.2,270.4,350.3,273.2,355.8L273.2,355.8z"
          />
          <path
            fill="#656565"
            d="M280.2,399.7v-1.2l61.1,0.4c-1.8,2.1-5.5,3-11,3.3l-48.4-0.1v-0.1c0-0.5-0.3-1.1-0.7-1.6
	C280.8,400,280.5,399.7,280.2,399.7z"
          />
          <path
            fill="#656565"
            d="M277.3,401.8c0-0.5,0.3-1.1,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7l0.7,0.1c0.3,0.1,0.7,0.3,1,0.5
	c0.4,0.4,0.7,1,0.7,1.6v5.6c0,0.6-0.3,1.1-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7c-0.5,0-1.1-0.3-1.7-0.7c-0.4-0.4-0.7-1-0.7-1.6V401.8
	L277.3,401.8z M183.9,347.2c-2.6,2.6-4.8,5.2-6.6,8.1c-5.5,8.1-8.1,17.3-8.1,27.6c0,5.2,0.7,10.2,2.1,14.7c1.4,4.5,3.4,8.9,6.1,12.9
	c1.9,2.8,4.1,5.5,6.6,8c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12
	c1.1-1.1,2.2-2.2,3-3.3c3.2-3.7,5.6-7.6,7.4-11.7c2.8-6.3,4.3-13.2,4.3-20.6c0-12.4-3.9-23.2-11.7-32.3c-1-1.1-2.1-2.3-3-3.3
	c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1s-20.6,3-29.2,9.1C188.2,343.2,186,345.1,183.9,347.2L183.9,347.2z M232.3,343.4
	h-0.6H232.3z M255,382.9c0,10-3.6,18.6-10.7,25.7s-15.7,10.6-25.7,10.6c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7
	c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6S255,372.9,255,382.9L255,382.9z"
          />
          <path
            fill="#656565"
            d="M218.2,368.3l-3.2,0.5c-1.1,0.3-2.1-0.7-2.6-2.9l-1.6-11.6c2.5-0.7,4.9-1,7.7-1l1.4,11.4
	C220,367.1,219.4,368.3,218.2,368.3L218.2,368.3z"
          />
          <path
            fill="#656565"
            d="M224.4,368.9c-0.6,2.6-1.5,4.8-2.9,6.5l-0.3,0.5c-0.4,0.7-1,1.2-1.6,1.6l-0.4,0.1c-0.8,0.4-1.7,0.4-2.5,0.3
	c-1-0.1-1.8-0.5-2.5-1.1l-0.4-0.4c-1.6-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14c-2.3,1.4-4.5,3-6.5,5c-1.6,1.6-3.2,3.6-4.4,5.4
	c5.5,1.6,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7l0.4,0.4c0.6,0.5,0.8,1.2,1,2.1v0.4c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1
	l-0.6,0.3c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3c1,5.1,3,9.6,6.6,13.6c3.3-4.7,6.5-8.4,9.5-11.3
	c2.1-1.8,4.1-2.9,6.1-3.4c0.1-0.1,0.4-0.1,0.6-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.6,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3
	v0.5c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2c-3.4-4.5-6.1-8.8-7.8-12.7
	c-1.1-2.5-1.5-4.8-1.5-6.9l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1
	c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3c-5.4,1.9-10.2,3-14.4,3.6c-2.6,0.3-5,0-7-0.8
	l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.6-0.7-1-1.4-1.1-2.3s-0.1-1.9,0.3-2.8l0.3-0.6c0.7-2.1,1.9-4,3.9-5.9
	c3-3,6.9-5.9,11.8-8.9c-3.9-3.7-8.4-6.1-13.3-7.2C225.6,359.8,225.2,364.8,224.4,368.9L224.4,368.9z M216.1,380.3
	c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C215,381.8,215.4,381,216.1,380.3L216.1,380.3z"
          />
          <path
            fill="#656565"
            d="M232.2,378l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1c1.5,2.2,2.6,4.5,3.4,7l-10.6,5
	C233.9,379.4,232.6,379.1,232.2,378L232.2,378z M241.7,401.4c-0.7,0.8-1.4,1.6-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3l-8-8.5
	c-1.4-1.9-1.6-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8L241.7,401.4L241.7,401.4z M206.9,396.7c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5
	c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3c-2.5-1-4.7-2.2-6.9-3.7L206.9,396.7L206.9,396.7z M201.9,376.1c2.2,0.7,3.2,1.6,2.8,2.8
	l-0.6,3.2c-0.1,1.1-1.2,1.6-3.6,1.5l-11.4-1.9c0.1-2.8,0.6-5.2,1.4-7.7L201.9,376.1L201.9,376.1z M158.6,393.5v3.4l-7.4-0.4l-44-8.4
	c-2.6-0.5-4-1.4-3.9-2.3c0.4-1.5,2.3-1.9,6.2-1.5l1.4,0.1l2.9,0.4l9.2,1.2C130,389.9,142,392.4,158.6,393.5L158.6,393.5z"
          />
          <path
            fill="#656565"
            d="M107,374.1c-1.8-1.4-1.2-3.3,1.5-5.5c2.3-1.1,4.4,1.1,6.3,6.7c0,0.7,0.1,1.2,0.4,1.9
	c-1.2,0.8-1.8,3.3-1.5,7.6l-2.9-0.4C110.2,378.4,109,375,107,374.1L107,374.1z"
          />
          <path
            fill="#656565"
            d="M122.8,385.8l-9.2-1.2c-0.1-4.3,0.4-6.7,1.5-7.6C116.2,380.6,118.7,383.5,122.8,385.8z M656.6,347.2
	c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6c-2.6,2.6-4.8,5.2-6.6,8
	c-5.5,8.1-8.1,17.3-8.1,27.6c0,6.3,1.1,12.4,3,17.9l-15-0.1v-16.1c0.3-11.7,3.2-22.1,8.7-31.5c2.6-4.4,5.8-8.5,9.5-12.5
	c3.2-3.3,6.5-6.1,9.9-8.5c9.9-7,21.3-10.5,34-10.5c17.2,0,31.8,6.3,43.9,19c4.5,4.8,8.3,9.9,11,15.4c1.1,2.2,2.2,4.5,3,6.9
	c2.3,6.5,3.7,13.3,4,20.8c0.3,6.7,0.3,12.9,0,18.7l-14.9,0.4c2.5-6.1,3.9-12.5,3.9-19.7c0-12.4-3.9-23.2-11.7-32.3
	C658.8,349.4,657.7,348.3,656.6,347.2L656.6,347.2z"
          />
          <path
            fill="#656565"
            d="M650,341.5c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6
	c-2.6,2.5-4.8,5.2-6.6,8c-5.5,8.1-8.1,17.3-8.1,27.6c0,6.3,1.1,12.4,3,17.9c1.2,3.4,3,6.7,5.1,9.9c1.9,2.8,4.1,5.5,6.6,8
	c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c1.1-1.1,2.2-2.2,3-3.3
	c3.3-4,6.1-8.3,7.8-12.8c2.5-6.1,3.9-12.5,3.9-19.7c0-12.4-3.9-23.2-11.7-32.3c-1-1.1-2.1-2.3-3-3.3
	C654.5,345.1,652.3,343.2,650,341.5L650,341.5z M619.9,419.2c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7
	c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7
	c0,10-3.6,18.6-10.7,25.7C638.6,415.7,629.9,419.2,619.9,419.2L619.9,419.2z"
          />
          <path
            fill="#656565"
            d="M594.7,367.4c5.5,1.7,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7l0.4,0.4c0.5,0.6,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1l-0.5,0.3c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3c1,5.1,3,9.6,6.6,13.6
	c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4c0.1-0.1,0.4-0.1,0.5-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.7,0.5,2.2,1.2
	c0.8,0.7,1.2,1.5,1.4,2.3v0.5c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2
	c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8
	c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3
	c-5.4,1.9-10.2,3-14.4,3.6c-2.8,0.3-5,0-7-0.8l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.5-0.7-1-1.4-1.1-2.3s-0.1-1.9,0.3-2.8
	l0.3-0.5c0.7-2.1,1.9-4,3.8-5.9c3-3,6.9-5.9,11.8-8.9c-3.8-3.7-8.4-6.1-13.3-7.2c0.1,5.6-0.3,10.6-1.1,14.7
	c-0.5,2.6-1.5,4.8-2.9,6.5l-0.3,0.5c-0.4,0.7-1,1.2-1.7,1.6l-0.4,0.1c-0.8,0.4-1.7,0.4-2.5,0.3c-1-0.1-1.8-0.5-2.5-1.1l-0.4-0.4
	c-1.7-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14c-2.3,1.4-4.5,3-6.5,5C597.3,363.7,595.9,365.6,594.7,367.4L594.7,367.4z
	 M617.2,380.3c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1.1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C616.3,381.8,616.6,381,617.2,380.3L617.2,380.3z"
          />
          <path
            fill="#656565"
            d="M616.3,368.9c-1.1,0.3-2.1-0.7-2.6-2.9l-1.7-11.6c2.5-0.7,5-1,7.7-1l1.4,11.4c0,2.3-0.5,3.4-1.8,3.4
	L616.3,368.9z M603.1,376.1c2.2,0.7,3.2,1.6,2.8,2.8l-0.5,3.2c-0.1,1.1-1.2,1.6-3.6,1.5l-11.4-1.9c0.1-2.8,0.5-5.2,1.4-7.7
	L603.1,376.1L603.1,376.1z M647.9,373.7l-10.6,5c-2.2,0.7-3.4,0.5-3.8-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1
	C646,368.8,647.1,371.1,647.9,373.7L647.9,373.7z M629.4,398.2c-1.4-1.9-1.7-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4
	l-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3L629.4,398.2L629.4,398.2z M611.6,394.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3
	c-2.5-1-4.7-2.2-6.9-3.7l5.6-10C609.5,394.8,610.6,394.2,611.6,394.8L611.6,394.8z"
          />
          <path
            fill="#EBEBEB"
            d="M256.4,216.6c-1.2,1.4-1.9,3-2.3,5l-9.2,40.2l-73.7-3l4.1-7l3.6-1.1l15.8-27.5l-1.5-2.2l4.8-6.7L256.4,216.6
	L256.4,216.6z M144.3,271.6h4.8c2.8,0,4.4,1.7,5.1,4.8c0.6,4.4,0.4,10.6-0.3,18.7c-0.1,9.5-1.6,16.1-4.5,19.8
	c-1.2,1.2-3.3,1.9-6.3,2.1c-4.4,0.3-7-1.4-8-4.5c-0.3-2.3-0.4-5.6-0.3-9.6l0.3-5.1c0.8-11.1,1.9-18.7,3.4-22.7l0.8-1.2
	C140.5,272.5,142.1,271.8,144.3,271.6L144.3,271.6z M244.3,357.2c-7.2-7.2-15.7-10.6-25.7-10.6c-10,0-18.6,3.6-25.7,10.6
	c-7.2,7.2-10.7,15.7-10.7,25.7c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6c10,0,18.6-3.6,25.7-10.6
	c7.2-7.2,10.7-15.7,10.7-25.7C255,372.9,251.5,364.2,244.3,357.2L244.3,357.2z M220.1,348l0.1,0.1c1.4,0.8,2.5,1.6,3.3,2.3
	c7,1,13.1,4,18.3,9.2l1.2,1.2c1,0.3,2.2,0.8,3.6,1.5h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3l-1.1,1
	c1.8,2.6,3.2,5.4,4.1,8.4l1.5-0.3h0.4c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.1c-0.4,1.5-0.7,2.8-1.1,3.7
	c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6c0,1-0.1,2.2-0.3,3.6v0.3c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3
	l-1.4-0.8c-0.8,1-1.6,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6l0.7,1.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3
	c-1.5,0.1-2.8,0.1-3.7,0.1c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7c-1,0.3-2.2,0.7-3.7,1c0,0-0.1,0.1-0.3,0.1l-0.4-0.1
	l-0.3-0.3v-0.3l0.3-1.5c-2.9-1.1-5.6-2.6-8.3-4.5l-1.1,1.1h-0.1v0.1l-0.3,0.1H197l-0.3-0.4v-0.3c-0.6-1.5-1.1-2.8-1.4-3.9l-0.1-0.1
	c-5.6-5.6-8.8-12.2-9.5-19.8c-0.7-0.8-1.4-1.9-2.1-3.3l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1l1.7-0.1
	c0.1-3.2,0.7-6.3,1.6-9.2l-1.4-0.7h-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1c1.2-1,2.2-1.8,3.2-2.3
	c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9c0.6-0.8,1.5-1.9,2.5-3l0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.3s0.3,0,0.4,0.1l0.1,0.3l0.6,1.5
	c2.9-0.8,5.9-1.2,9.1-1.2h0.3l0.3-1.6v-0.1l0.1-0.3l0.4-0.3L220.1,348L220.1,348z"
          />
          <path
            fill="#EBEBEB"
            d="M219.6,347.9l0.6,0.1l0.1,0.1l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6c-0.1,3.9-0.4,6.3-0.8,7.2l-0.3,0.5
	c-0.4,0.7-1,1.2-1.6,1.6l-0.4,0.1c-0.8,0.4-1.6,0.4-2.5,0.3c-1-0.1-1.8-0.6-2.5-1.1l-0.4-0.4c-0.6-0.8-1.5-3-2.6-6.7
	c-1-3-1.8-7.8-2.6-14.6l-0.6-5.1v-0.3l0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.3s0.3,0,0.4,0.1l0.1,0.3v0.1l0.6,4.8
	c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.6,1.1,1,1.8,1.1l0.4,0.1c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8
	c0.4-0.8,0.6-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.7-4.8v-0.1l0.1-0.3L219.6,347.9L219.6,347.9z"
          />
          <path
            fill="#EBEBEB"
            d="M220.4,348.4l-0.1-0.3c1.4,0.8,2.5,1.6,3.3,2.3c1.4,1.1,1.9,2.1,1.9,2.9v0.8c0.1,5.6-0.3,10.6-1.1,14.7
	c-0.6,2.6-1.5,4.8-2.9,6.5c0.4-1,0.7-3.3,0.8-7.2c0.1-3-0.3-8-1.2-14.6L220.4,348.4L220.4,348.4z"
          />
          <path
            fill="#EBEBEB"
            d="M219.1,348.5l-0.3,1.6l-0.4,2.6v0.5l1.4,11.4c0,2.3-0.6,3.4-1.8,3.4l-3.2,0.5c-1.1,0.3-2.1-0.7-2.6-2.9
	l-1.6-11.6v-0.4l-1.1-2.6l-0.6-1.5v0.1l0.6,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1
	c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.6-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7L219.1,348.5L219.1,348.5z M215.8,371.4
	c0.6-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1c0.4,0.5,0.6,1.2,0.4,1.9s-0.4,1.2-1.1,1.8c-0.6,0.4-1.2,0.5-1.9,0.4
	c-0.7-0.1-1.4-0.4-1.8-1.1c-0.4-0.5-0.6-1.2-0.6-1.9C214.9,372.5,215.1,371.8,215.8,371.4L215.8,371.4z"
          />
          <path
            fill="#EBEBEB"
            d="M214.7,373.2c-0.1,0.7,0,1.4,0.6,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.6-0.4,1-1,1.1-1.8
	c0.1-0.7,0-1.4-0.4-1.9c-0.4-0.5-1-1-1.8-1.1c-0.7-0.1-1.4,0-1.9,0.4C215.1,371.8,214.9,372.5,214.7,373.2L214.7,373.2z
	 M216.2,372.1c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3
	c-0.6-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C215.6,372.8,215.7,372.3,216.2,372.1L216.2,372.1z"
          />
          <path
            fill="#EBEBEB"
            d="M215.4,373.3c0.1-0.5,0.3-1,0.8-1.2c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4
	c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3c-0.6-0.1-1-0.3-1.2-0.7C215.4,374.3,215.3,373.9,215.4,373.3L215.4,373.3z
	 M207.9,349.9v0.3l0.6,5.1c0.8,6.7,1.6,11.6,2.6,14.6c1.1,3.7,2.1,6.1,2.6,6.7c-1.6-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14
	l-0.1-0.8c-0.3-0.8,0.1-1.9,1.2-3.4C205.9,352,206.8,351,207.9,349.9L207.9,349.9z M210.7,354.3v-0.4l-1.1-2.6
	c2.9-0.8,5.9-1.2,9.1-1.2h0.3l-0.4,2.6v0.5C215.8,353.4,213.2,353.8,210.7,354.3L210.7,354.3z M225.5,354.2v-0.8
	c0-0.8-0.7-1.8-1.9-2.9c7,1,13.1,4,18.3,9.2l1.2,1.2c-1.8-0.6-2.9-0.6-3.6-0.1l-0.7,0.4C234.8,357.6,230.4,355.3,225.5,354.2
	L225.5,354.2z M215,382.9c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6
	s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1C215.4,384.7,215,383.9,215,382.9L215,382.9z M228.8,371.6
	c2.5-1.8,6.9-4.1,13.1-7l4.5-2.2h0.6c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3h-0.1l-4.4,1.9c-6.2,2.9-10.6,5.2-13.1,7
	c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.6-0.6,1.2-0.6,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4
	c2.9-0.8,7.6-2.8,13.6-5.8l4.3-2.2h0.4c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.1l-0.1,0.1l-4.5,2.2
	c-6.2,3-10.7,5-13.6,5.8c-3.7,1.1-6.2,1.5-7.2,1.5l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.6-0.7-1-1.4-1.1-2.3
	s-0.1-1.9,0.3-2.8l0.3-0.5C223.7,375.4,225.6,373.8,228.8,371.6L228.8,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M228.8,371.6c-3.2,2.2-5.1,3.7-5.6,4.5c0.7-2.1,1.9-4,3.9-5.9c3-3,6.9-5.9,11.8-8.9l0.7-0.4
	c0.7-0.5,1.8-0.4,3.6,0.1c1,0.3,2.2,0.8,3.6,1.5h-0.3l-4.5,2.2C235.6,367.5,231.2,369.9,228.8,371.6L228.8,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M230.8,375.1c-0.7-1,0-2.1,2.1-3.3l10.5-5.1l0.4-0.1l2.2-1.9l1.1-1h-0.1l-4.4,1.9c-6.2,2.9-10.6,5.2-13.1,7
	c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.6,1.2-0.6,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4
	c3-0.8,7.6-2.8,13.6-5.8l4.3-2.2l-1.5,0.3l-2.8,0.4l-0.4,0.3l-10.6,5c-2.2,0.7-3.4,0.5-3.9-0.5L230.8,375.1L230.8,375.1z
	 M228.5,376.6c0.6,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.6-1.9
	c0.4-0.5,1-1,1.6-1.1S227.9,376.2,228.5,376.6L228.5,376.6z"
          />
          <path
            fill="#EBEBEB"
            d="M226.6,376.2c-0.7,0.1-1.2,0.4-1.6,1.1c-0.4,0.5-0.6,1.2-0.6,1.9c0.1,0.7,0.4,1.4,1.1,1.8
	c0.6,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1c0.4-0.5,0.6-1.2,0.4-1.9s-0.4-1.2-1.1-1.8C227.9,376.2,227.3,376.1,226.6,376.2
	L226.6,376.2z M228.9,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3s-0.7-0.7-0.8-1.2s0-1,0.3-1.4
	c0.3-0.4,0.7-0.7,1.2-0.8c0.6-0.1,1,0,1.4,0.4C228.5,377.6,228.8,378,228.9,378.4L228.9,378.4z"
          />
          <path
            fill="#EBEBEB"
            d="M228.1,377.2c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3
	c-0.4-0.3-0.7-0.7-0.8-1.2s0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8C227.3,376.7,227.7,376.9,228.1,377.2L228.1,377.2z M225.7,385
	l0.7,0.1c1,0.3,3,1.5,6.1,3.9c2.5,1.8,6.1,5.2,10.7,10.3l3.3,3.7l0.1,0.1v0.3c0,0.1-0.1,0.3-0.3,0.4s-0.3,0.1-0.4,0.1h-0.3l-3.3-3.6
	c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3c-0.6,0.3-1,0.7-1.2,1.2
	c-0.4,0.8-0.6,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1
	h-0.3l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4
	c0.4-0.8,1.1-1.4,1.8-1.8C224,385.1,224.8,384.9,225.7,385L225.7,385z"
          />
          <path
            fill="#EBEBEB"
            d="M223.5,386.6c-0.6,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.6,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9
	c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4l-0.7-1.4l-1.2-2.5l-0.4-0.4l-8-8.5c-1.4-1.9-1.6-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8
	l8.1,8.4l0.3,0.3l2.5,1.5l1.4,0.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1
	L223.5,386.6L223.5,386.6z M227.4,388.4c0.3,0.7,0.4,1.4,0.1,2.1c-0.3,0.7-0.7,1.2-1.4,1.5c-0.7,0.3-1.4,0.4-2.1,0.1
	s-1.2-0.7-1.5-1.4s-0.4-1.4-0.1-2.1c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1C226.6,387.3,227.1,387.7,227.4,388.4
	L227.4,388.4z"
          />
          <path
            fill="#EBEBEB"
            d="M225.9,387.1c-0.7-0.3-1.4-0.1-2.1,0.1c-0.7,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1
	c0.4,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1c0.7-0.3,1.1-0.8,1.4-1.5c0.3-0.7,0.1-1.4-0.1-2.1C227.1,387.7,226.6,387.3,225.9,387.1
	L225.9,387.1z M225.6,387.7c0.6,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.6,0.8-1,1.1c-0.6,0.3-1,0.3-1.5,0.1
	s-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1C224.6,387.6,225.2,387.6,225.6,387.7L225.6,387.7z"
          />
          <path
            fill="#EBEBEB"
            d="M224.2,387.9c0.4-0.3,1-0.3,1.4-0.1c0.6,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.5-0.6,0.8-1,1.1
	c-0.6,0.3-1,0.3-1.5,0.1s-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4C223.4,388.4,223.7,388.2,224.2,387.9L224.2,387.9z M226.3,385.1
	c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5l0.6,0.4c0.7,0.5,1,1.8,1,3.7c0,1-0.1,2.2-0.3,3.6l-0.1-0.1l-3.3-3.7
	c-4.7-5.1-8.3-8.5-10.7-10.3C229.3,386.6,227.3,385.4,226.3,385.1L226.3,385.1z M233.6,384c-2.6,0.3-5,0-7-0.8
	c1,0.1,3.3-0.4,7.2-1.5c2.9-0.8,7.4-2.8,13.6-5.8l4.5-2.2l0.1-0.1c-0.4,1.5-0.7,2.8-1.1,3.7c-0.7,1.6-1.4,2.6-2.2,2.9l-0.7,0.3
	C242.7,382.4,237.8,383.5,233.6,384L233.6,384z M205.8,374.8c3.6,1.4,5.8,2.3,6.6,3l0.4,0.4c0.6,0.6,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1l-0.6,0.3c-1,0.4-3.4,0.6-7.3,0.4c-3,0-7.8-0.7-14.6-2.1l-5.1-1l-0.3-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4s0.1-0.3,0.3-0.4l0.3-0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.6-1.4
	l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2c-0.7-0.5-2.8-1.5-6.2-2.8c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8h-0.1
	l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4s0.1-0.3,0.3-0.4h0.4l5.1,0.8C198,372.6,202.8,373.7,205.8,374.8L205.8,374.8z"
          />
          <path
            fill="#EBEBEB"
            d="M212.4,377.7c-0.8-0.7-3-1.6-6.6-3c-3-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3c1.2-1,2.2-1.8,3.2-2.3
	c1.5-1,2.6-1.2,3.4-1l0.8,0.3c5.5,1.6,10,3.6,13.8,5.6C209.5,374.4,211.2,376.1,212.4,377.7L212.4,377.7z M215.1,386.6
	c0.8,0.1,1.6,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3v0.5c0,1-0.6,3.3-1.8,7c-1,2.9-3.2,7.4-6.5,13.3l-2.5,4.4l-0.1,0.1
	c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9
	l-0.3-0.3c-0.4-0.4-1-0.7-1.6-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4H198v0.1
	l-0.3,0.1h-0.4l-0.3-0.4v-0.5l2.3-4.5c3.2-5.9,5.8-10.2,7.7-12.5c2.3-3,4-4.8,4.8-5.4c0.1-0.1,0.4-0.1,0.6-0.3
	c0.7-0.3,1.5-0.4,2.3-0.3L215.1,386.6L215.1,386.6z"
          />
          <path
            fill="#EBEBEB"
            d="M212.4,382.7c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2c-0.7-0.6-2.8-1.5-6.2-2.8
	c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8l1.4,0.7l2.5,1.2l0.6,0.1l11.4,2.2c2.2,0.7,3.2,1.6,2.8,2.8l-0.6,3.2c-0.1,1.1-1.2,1.6-3.6,1.5
	l-11.4-1.9l-0.6-0.1l-2.8,0.3l-1.7,0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.6-1.4L212.4,382.7
	L212.4,382.7z M206.9,382c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1c0.7,0.3,1.1,0.8,1.4,1.5
	c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4c-0.7,0.3-1.4,0.1-2.1-0.1C207.6,383.2,207,382.7,206.9,382L206.9,382z"
          />
          <path
            fill="#EBEBEB"
            d="M208.1,383.6c0.7,0.3,1.4,0.4,2.1,0.1s1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1s-0.7-1.2-1.4-1.5
	c-0.7-0.3-1.4-0.4-2.1-0.1c-0.7,0.3-1.2,0.7-1.5,1.4c-0.3,0.7-0.4,1.4-0.1,2.1C207,382.7,207.6,383.2,208.1,383.6L208.1,383.6z
	 M207.6,381.8c-0.1-0.5-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1c0.6-0.1,1-0.1,1.5,0.1c0.4,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.5
	c-0.3,0.5-0.6,0.8-1.1,1l-1.5-0.1C208,382.7,207.7,382.2,207.6,381.8L207.6,381.8z"
          />
          <path
            fill="#EBEBEB"
            d="M208.5,382.9c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.6-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1c0.6-0.1,1-0.1,1.5,0.1
	c0.4,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.5c-0.3,0.5-0.6,0.8-1.1,1L208.5,382.9L208.5,382.9z M212.4,388.3c-0.8,0.4-2.3,2.2-4.5,5.1
	c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4l1.1-1.1l1.9-1.9l0.3-0.5l5.6-10c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9
	l-5.5,10.3l-0.1,0.4l-0.7,2.9l-0.3,1.5l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3
	c-0.4-0.4-1-0.7-1.6-0.8C214.1,387.7,213.2,387.7,212.4,388.3L212.4,388.3z M212.3,389.3c0.6-0.5,1.1-0.8,1.9-0.8
	c0.8,0,1.4,0.3,1.9,0.8c0.6,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9c-0.6,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8
	s-0.8-1.1-0.8-1.9C211.6,390.4,211.8,389.7,212.3,389.3L212.3,389.3z"
          />
          <path
            fill="#EBEBEB"
            d="M211.6,391c0,0.8,0.3,1.4,0.8,1.9c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8s0.8-1.2,0.8-1.9
	s-0.3-1.4-0.8-1.9c-0.6-0.5-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8C211.8,389.7,211.6,390.4,211.6,391z M212.3,391
	c0-0.5,0.1-1,0.6-1.4c0.4-0.4,0.8-0.5,1.4-0.5s1,0.1,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6
	s-1-0.1-1.4-0.5C212.5,392,212.3,391.6,212.3,391L212.3,391z"
          />
          <path
            fill="#EBEBEB"
            d="M212.8,392.4c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.5,0.1-1,0.6-1.4c0.4-0.4,0.8-0.5,1.4-0.5s1,0.1,1.4,0.5
	c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6S213.2,392.8,212.8,392.4L212.8,392.4z M243.4,366.7l0.4-0.1
	l2.2-1.9c1.8,2.6,3.2,5.4,4.1,8.4l-2.8,0.4l-0.4,0.3C246,371.1,244.7,368.8,243.4,366.7L243.4,366.7z M248,380.5l0.7-0.3
	c0.8-0.3,1.5-1.2,2.2-2.9c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6c0-1.9-0.3-3.2-1-3.7l-0.6-0.4c1.8-3.9,2.8-8,2.8-12.5
	L248,380.5L248,380.5z M239.5,403.7c0.8-0.8,1.5-1.5,2.2-2.3l0.3,0.3l2.5,1.5c-0.8,1-1.6,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6
	l-1.2-2.5l-0.4-0.4C237.3,405.8,238.4,404.8,239.5,403.7L239.5,403.7z M237.4,411.7l0.3,0.1c-1.5,0.1-2.8,0.1-3.7,0.1
	c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9c0.3,1,1.5,3,3.6,6.3
	c1.8,2.6,4.9,6.3,9.6,11.1L237.4,411.7L237.4,411.7z M216.9,397.9c1.2-3.7,1.9-6.1,1.8-7c0.7,2.1,0.8,4.4,0.4,7
	c-0.7,4.3-2.2,8.9-4.3,14.2l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1c-1,0.3-2.2,0.7-3.7,1l0.1-0.1l2.5-4.4
	C213.8,405.2,216,400.8,216.9,397.9L216.9,397.9z"
          />
          <path
            fill="#EBEBEB"
            d="M230.4,410.7c0.4,0.7,1.6,1.1,3.6,1.2c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7
	c1.8-0.5,2.9-1.2,3-2.1l0.3-0.7l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2L230.4,410.7L230.4,410.7z M208.1,410.4l-0.1,0.4l-0.7,2.9
	c-2.9-1.1-5.6-2.6-8.3-4.5l1.9-1.9l0.3-0.6C203.5,408.4,205.7,409.6,208.1,410.4L208.1,410.4z M211.8,387.2
	c-0.8,0.5-2.5,2.3-4.8,5.4c-1.9,2.5-4.4,6.6-7.7,12.5l-2.3,4.5v0.3c-0.6-1.5-1.1-2.8-1.4-3.9c-0.4-1.6-0.4-2.9,0.1-3.4l0.6-0.7
	c3.3-4.7,6.5-8.4,9.5-11.3C207.7,389,209.8,387.7,211.8,387.2L211.8,387.2z M204.1,356.2l0.1,0.8c-2.3,1.4-4.5,3-6.5,5
	c-1.6,1.7-3.2,3.6-4.4,5.4l-0.8-0.3c-0.7-0.3-1.9,0-3.4,1c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9
	C204.3,354.3,203.9,355.6,204.1,356.2L204.1,356.2z M190,373.7l0.6,0.1c-0.8,2.5-1.2,5-1.4,7.7l-0.6-0.1l-2.8,0.3
	c0.1-3.2,0.7-6.3,1.6-9.2L190,373.7L190,373.7z M183.9,382.9l0.3,0.1l5.1,1c6.7,1.2,11.6,1.9,14.6,2.1c3.9,0.1,6.3,0,7.3-0.4
	c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3h-0.8c-0.8,0-1.8-0.7-2.9-2.1C185.4,385.4,184.6,384.3,183.9,382.9L183.9,382.9z"
          />
          <path
            fill="#EBEBEB"
            d="M188.9,388.3h0.8c1,5.1,3,9.6,6.6,13.6l-0.6,0.7c-0.6,0.6-0.6,1.8-0.1,3.4l-0.1-0.1
	c-5.6-5.6-8.8-12.2-9.5-19.8C187.1,387.6,188,388.3,188.9,388.3L188.9,388.3z M645.6,408.7c7.2-7.2,10.7-15.7,10.7-25.7
	c0-10-3.6-18.7-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6c-10,0-18.6,3.6-25.7,10.6c-7.2,7.2-10.7,15.7-10.7,25.7
	c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6C629.9,419.2,638.6,415.7,645.6,408.7L645.6,408.7z M653,372.8
	c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1c-0.4,1.5-0.7,2.8-1.1,3.7c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6
	c0,1-0.1,2.2-0.3,3.6v0.3c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3l-1.4-0.8c-0.8,1-1.7,1.9-2.6,2.9
	c-1.4,1.4-2.8,2.5-4.1,3.6l0.7,1.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3c-1.5,0.1-2.8,0.1-3.7,0.1
	c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7c-1,0.3-2.2,0.7-3.7,1c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l0.3-1.5
	c-2.9-1.1-5.6-2.6-8.3-4.5l-1.1,1.1h-0.1v0.1l-0.3,0.1H598c-0.1-0.1-0.3-0.3-0.3-0.4V410c-0.5-1.5-1.1-2.8-1.4-3.9l-0.1-0.1
	c-5.6-5.6-8.8-12.2-9.5-19.8c-0.7-0.8-1.4-1.9-2.1-3.3l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1l1.7-0.1
	c0.1-3.2,0.7-6.3,1.7-9.2l-1.4-0.7h-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1c1.2-1,2.2-1.8,3.2-2.3
	c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9c0.6-0.8,1.5-1.9,2.5-3l0.1-0.1l0.4-0.3c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.3
	l0.5,1.5c2.9-0.8,5.9-1.2,9.1-1.2h0.3l0.3-1.6v-0.1l0.1-0.3c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.4,0,0.5,0.1l0.1,0.1
	c1.4,0.8,2.5,1.6,3.3,2.3c7,1,13.1,4,18.3,9.2l1.2,1.2c1,0.3,2.2,0.8,3.6,1.5h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4
	l-0.1,0.3l-1.1,1c1.8,2.6,3.2,5.4,4.1,8.4l1.5-0.3h0.1L653,372.8L653,372.8z"
          />
          <path
            fill="#EBEBEB"
            d="M652.6,372.8l-1.5,0.3l-2.8,0.4l-0.4,0.3l-10.6,5c-2.2,0.7-3.4,0.5-3.8-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3
	l10.5-5.1l0.4-0.1l2.2-1.9l1.1-1h-0.1l-4.4,1.9c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.5,1.2-0.5,2.1v0.4
	c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8L652.6,372.8L652.6,372.8z
	 M630.9,378.4c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.5-1.9c0.4-0.5,1-1,1.7-1.1
	c0.7-0.1,1.4,0.1,1.9,0.5C630.5,377,630.7,377.6,630.9,378.4L630.9,378.4z"
          />
          <path
            fill="#EBEBEB"
            d="M629.8,376.6c-0.5-0.4-1.2-0.5-1.9-0.5c-0.7,0.1-1.2,0.4-1.7,1.1c-0.4,0.5-0.5,1.2-0.5,1.9
	c0.1,0.7,0.4,1.4,1.1,1.8c0.5,0.4,1.2,0.5,1.9,0.4s1.4-0.4,1.8-1.1c0.4-0.5,0.5-1.2,0.4-1.9C630.7,377.6,630.5,377,629.8,376.6
	L629.8,376.6z M630.2,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2
	c-0.1-0.6,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0,1.4,0.4C629.8,377.6,630,378,630.2,378.4L630.2,378.4z"
          />
          <path
            fill="#EBEBEB"
            d="M629.4,377.2c0.4,0.3,0.7,0.7,0.8,1.2s0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3
	c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.6,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8S628.9,376.9,629.4,377.2L629.4,377.2z"
          />
          <path
            fill="#EBEBEB"
            d="M652.6,372.8h0.4c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1l-0.1,0.1l-4.5,2.2c-6.2,3-10.7,5-13.6,5.8
	c-3.7,1.1-6.2,1.5-7.2,1.5l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.5-0.7-1-1.4-1.1-2.3c-0.1-1-0.1-1.9,0.3-2.8l0.3-0.5
	c0.5-0.8,2.5-2.3,5.6-4.5c2.5-1.8,6.9-4.1,13.1-7l4.5-2.2h0.5c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3h-0.1l-4.4,1.9
	c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.5,1.2-0.5,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1
	c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8L652.6,372.8L652.6,372.8z"
          />
          <path
            fill="#EBEBEB"
            d="M653.2,373.9l0.1-0.1c-0.4,1.5-0.7,2.8-1.1,3.7c-0.7,1.6-1.4,2.6-2.2,2.9l-0.7,0.3c-5.4,1.9-10.2,3-14.4,3.6
	c-2.8,0.3-5,0-7-0.8c1,0.1,3.3-0.4,7.2-1.5c2.9-0.8,7.4-2.8,13.6-5.8L653.2,373.9L653.2,373.9z"
          />
          <path
            fill="#EBEBEB"
            d="M650,380.2c0.8-0.3,1.5-1.2,2.2-2.9c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6c0-1.9-0.3-3.2-1-3.7
	l-0.5-0.4c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3L650,380.2L650,380.2z M620.4,348.1c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.4,0,0.5,0.1l0.1,0.1
	l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6c-0.1,3.9-0.4,6.3-0.8,7.2l-0.3,0.5c-0.4,0.7-1,1.2-1.7,1.6l-0.4,0.1
	c-0.8,0.4-1.7,0.4-2.5,0.3c-1-0.1-1.8-0.6-2.5-1.1l-0.4-0.4c-0.5-0.8-1.5-3-2.6-6.7c-1-3-1.8-7.8-2.6-14.6l-0.5-5.1v-0.3l0.1-0.1
	l0.4-0.3c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.3v0.1l0.5,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3
	c0.4,0.6,1.1,1,1.8,1.1l0.4,0.1c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.5-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.8-4.7
	L620.4,348.1L620.4,348.1L620.4,348.1z"
          />
          <path
            fill="#EBEBEB"
            d="M621.5,348.4l-0.1-0.3c1.4,0.8,2.5,1.6,3.3,2.3c1.4,1.1,1.9,2.1,1.9,2.9v0.8c0.1,5.6-0.3,10.6-1.1,14.7
	c-0.5,2.6-1.5,4.8-2.9,6.5c0.4-1,0.7-3.3,0.8-7.2c0.1-3-0.3-8-1.2-14.6L621.5,348.4L621.5,348.4z"
          />
          <path
            fill="#EBEBEB"
            d="M620.3,348.5l-0.3,1.6l-0.4,2.6v0.5l1.4,11.4c0,2.3-0.5,3.4-1.8,3.4l-3.2,0.5c-1.1,0.3-2.1-0.7-2.6-2.9
	l-1.7-11.6v-0.4l-1.1-2.6l-0.5-1.5v0.1l0.5,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1
	c0.5,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.5-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7L620.3,348.5L620.3,348.5z M617,371.4
	c0.5-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1c0.4,0.5,0.5,1.2,0.4,1.9c-0.1,0.7-0.4,1.2-1.1,1.8c-0.5,0.4-1.2,0.5-1.9,0.4
	s-1.4-0.4-1.8-1.1c-0.4-0.5-0.5-1.2-0.5-1.9C616,372.5,616.4,371.8,617,371.4L617,371.4z"
          />
          <path
            fill="#EBEBEB"
            d="M615.9,373.2c-0.1,0.7,0,1.4,0.5,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.5-0.4,1-1,1.1-1.8
	c0.1-0.7,0-1.4-0.4-1.9c-0.4-0.5-1-1-1.8-1.1c-0.7-0.1-1.4,0-1.9,0.4C616.4,371.8,616,372.5,615.9,373.2L615.9,373.2z M617.4,372.1
	c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3
	c-0.5-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C616.7,372.8,617,372.3,617.4,372.1L617.4,372.1z"
          />
          <path
            fill="#EBEBEB"
            d="M616.7,373.3c0.1-0.5,0.3-1,0.8-1.2c0.4-0.3,0.8-0.4,1.4-0.4c0.5,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4
	c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3c-0.5-0.1-1-0.3-1.2-0.7C616.7,374.3,616.6,373.9,616.7,373.3L616.7,373.3z
	 M599.1,362c-1.7,1.6-3.2,3.6-4.4,5.4l-0.8-0.3c-0.7-0.3-1.9,0-3.4,1c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9
	c-1.1,1.5-1.5,2.6-1.2,3.4l0.1,0.8C603.2,358.3,601,360,599.1,362L599.1,362z M616.3,382.9c0-1,0.4-1.9,1.1-2.6s1.5-1.1,2.6-1.1
	c1.1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1
	C616.6,384.7,616.3,383.9,616.3,382.9L616.3,382.9z"
          />
          <path
            fill="#EBEBEB"
            d="M590.4,368.2c1.5-1,2.6-1.2,3.4-1l0.8,0.3c5.5,1.6,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7
	c-0.8-0.7-3-1.6-6.6-3c-2.9-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3C588.5,369.6,589.6,368.8,590.4,368.2L590.4,368.2z M609.1,349.9v0.3
	l0.5,5.1c0.8,6.7,1.7,11.6,2.6,14.6c1.1,3.7,2.1,6.1,2.6,6.7c-1.7-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14l-0.1-0.8
	c-0.3-0.8,0.1-1.9,1.2-3.4C607.2,352,608,351,609.1,349.9L609.1,349.9z M612,353.9l-1.1-2.6c2.9-0.8,5.9-1.2,9.1-1.2h0.3l-0.4,2.6
	v0.5c-2.8,0-5.2,0.3-7.7,1L612,353.9L612,353.9z"
          />
          <path
            fill="#EBEBEB"
            d="M606.5,375.9c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8l1.4,0.7l2.5,1.2l0.5,0.1l11.4,2.2c2.2,0.7,3.2,1.6,2.8,2.8
	l-0.5,3.2c-0.1,1.1-1.2,1.6-3.6,1.5l-11.4-1.9l-0.5-0.1l-2.8,0.3l-1.7,0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4
	c0.7-0.3,1.2-0.7,1.7-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2C612.2,378.1,610.1,377.2,606.5,375.9L606.5,375.9
	z M613.1,380.3c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4s-1.4,0.1-2.1-0.1c-0.7-0.3-1.1-0.8-1.4-1.5
	c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1C612.4,379.2,613,379.6,613.1,380.3L613.1,380.3z"
          />
          <path
            fill="#EBEBEB"
            d="M611.9,378.8c-0.7-0.3-1.4-0.4-2.1-0.1s-1.2,0.7-1.5,1.4s-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5
	s1.4,0.4,2.1,0.1c0.7-0.3,1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1S612.4,379.2,611.9,378.8L611.9,378.8z M611.5,379.5
	c0.4,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.5c-0.3,0.5-0.5,0.8-1.1,1l-1.5-0.1c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.5-0.1-1,0.1-1.5
	c0.3-0.4,0.7-0.8,1.1-1C610.5,379.2,611.1,379.2,611.5,379.5L611.5,379.5z"
          />
          <path
            fill="#EBEBEB"
            d="M610.1,379.4c0.5-0.1,1-0.1,1.5,0.1c0.4,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.5c-0.3,0.5-0.5,0.8-1.1,1
	l-1.5-0.1c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.5-0.1-1,0.1-1.5C609.1,379.9,609.5,379.6,610.1,379.4L610.1,379.4z"
          />
          <path
            fill="#EBEBEB"
            d="M606.5,375.9c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8h-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4
	c0-0.1,0.1-0.3,0.3-0.4h0.4l5.1,0.8c6.6,1.2,11.4,2.3,14.3,3.3c3.6,1.4,5.8,2.3,6.6,3l0.4,0.4c0.5,0.5,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.7-1.8,2.1l-0.5,0.3c-1,0.4-3.4,0.6-7.3,0.4c-3,0-7.8-0.7-14.6-2.1l-5.1-1l-0.3-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4
	c0.7-0.3,1.2-0.7,1.7-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.7-1.1-2.2C612.2,378.1,610.1,377.2,606.5,375.9L606.5,375.9
	z M616,386.6h0.4c0.8,0.1,1.7,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3v0.5c0,1-0.6,3.3-1.8,7c-1,2.9-3.2,7.4-6.5,13.3l-2.5,4.4
	l-0.1,0.1c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6
	c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3c-0.4-0.4-1-0.7-1.7-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1
	c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4h-0.1v0.1l-0.3,0.1h-0.4c-0.1-0.1-0.3-0.3-0.3-0.4v-0.5l2.3-4.5c3.2-5.9,5.8-10.2,7.7-12.5
	c2.3-3,4-4.8,4.8-5.4c0.1-0.1,0.4-0.1,0.6-0.3C614.4,386.6,615,386.5,616,386.6L616,386.6z"
          />
          <path
            fill="#EBEBEB"
            d="M613.7,388.3c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4l1.1-1.1l1.9-1.9l0.3-0.5l5.6-10
	c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3l-0.1,0.4l-0.7,2.9l-0.3,1.5l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3
	c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3c-0.4-0.4-1-0.7-1.7-0.8C615.2,387.7,614.4,387.7,613.7,388.3L613.7,388.3z
	 M613.5,389.3c0.5-0.5,1.1-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8
	c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.6-0.8-1.1-0.8-1.9C612.7,390.4,613,389.7,613.5,389.3L613.5,389.3z"
          />
          <path
            fill="#EBEBEB"
            d="M612.7,391c0,0.8,0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9
	s-0.3-1.4-0.8-1.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8C613,389.7,612.7,390.4,612.7,391z M615.5,393
	c-0.5,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4c0.4-0.4,0.8-0.5,1.4-0.5c0.5,0,1,0.1,1.4,0.5s0.5,0.8,0.5,1.4
	c0,0.5-0.1,1-0.5,1.4S616,393,615.5,393z"
          />
          <path
            fill="#EBEBEB"
            d="M616.8,392.4c-0.4,0.4-0.8,0.6-1.4,0.6c-0.5,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4
	c0.4-0.4,0.8-0.5,1.4-0.5c0.5,0,1,0.1,1.4,0.5s0.5,0.8,0.5,1.4C617.4,391.6,617.1,392,616.8,392.4z M612.3,385.5
	c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3H590c-0.8,0-1.8-0.7-2.9-2.1c-0.7-0.8-1.4-1.9-2.1-3.3l0.3,0.1l5.1,1
	c6.7,1.2,11.6,1.9,14.6,2.1C608.9,386.1,611.3,386,612.3,385.5L612.3,385.5z M591.2,373.7l0.5,0.1c-0.8,2.5-1.2,5-1.4,7.7l-0.5-0.1
	l-2.8,0.3c0.1-3.2,0.7-6.3,1.7-9.2L591.2,373.7L591.2,373.7z"
          />
          <path
            fill="#EBEBEB"
            d="M590.1,388.3h0.8c1,5.1,3,9.6,6.6,13.6l-0.5,0.7c-0.5,0.6-0.5,1.8-0.1,3.4l-0.1-0.1
	c-5.6-5.6-8.8-12.2-9.5-19.8C588.4,387.6,589.3,388.3,590.1,388.3L590.1,388.3z M626.7,354.2v-0.8c0-0.8-0.7-1.8-1.9-2.9
	c7,1,13.1,4,18.3,9.2l1.2,1.2c-1.8-0.6-2.9-0.6-3.6-0.1l-0.7,0.4C636.1,357.6,631.7,355.3,626.7,354.2L626.7,354.2z"
          />
          <path
            fill="#EBEBEB"
            d="M647.9,362.4h-0.3l-4.5,2.2c-6.2,2.9-10.6,5.2-13.1,7c-3.2,2.2-5.1,3.7-5.6,4.5c0.7-2.1,1.9-4,3.8-5.9
	c3-2.9,6.9-5.9,11.8-8.9l0.7-0.4c0.7-0.5,1.8-0.4,3.6,0.1C645.3,361.3,646.4,361.7,647.9,362.4L647.9,362.4z M644.5,366.7l0.4-0.1
	l2.2-1.9c1.8,2.6,3.2,5.4,4.1,8.4l-2.8,0.4l-0.4,0.3C647.1,371.1,646,368.8,644.5,366.7z M626.9,385l0.7,0.1c1,0.3,3,1.5,6.1,3.9
	c2.5,1.8,6.1,5.2,10.7,10.3l3.3,3.7l0.1,0.1v0.3c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3l-3.3-3.6
	c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3c-0.5,0.3-1,0.7-1.2,1.2
	c-0.4,0.8-0.5,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1
	h-0.3l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4
	c0.4-0.8,1.1-1.4,1.8-1.8C625.2,385.1,626.1,384.9,626.9,385L626.9,385z"
          />
          <path
            fill="#EBEBEB"
            d="M624.8,386.6c-0.5,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.5,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9
	c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4l-0.7-1.4l-1.2-2.5l-0.4-0.4l-8-8.5c-1.4-1.9-1.7-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8
	l8.1,8.4l0.3,0.3l2.5,1.5l1.4,0.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1
	L624.8,386.6L624.8,386.6z M628.7,388.4c0.3,0.7,0.4,1.4,0.1,2.1s-0.7,1.2-1.4,1.5s-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4
	s-0.4-1.4-0.1-2.1c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1C627.8,387.3,628.4,387.7,628.7,388.4L628.7,388.4z"
          />
          <path
            fill="#EBEBEB"
            d="M627.2,387.1c-0.7-0.3-1.4-0.1-2.1,0.1c-0.7,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1
	c0.4,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1s1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1C628.4,387.7,627.8,387.3,627.2,387.1L627.2,387.1z
	 M626.9,387.7c0.5,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.5,0.8-1,1.1c-0.5,0.3-1,0.3-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1
	c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1C625.9,387.6,626.5,387.6,626.9,387.7L626.9,387.7z"
          />
          <path
            fill="#EBEBEB"
            d="M625.5,387.9c0.4-0.3,1-0.3,1.4-0.1c0.5,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.5-0.5,0.8-1,1.1
	c-0.5,0.3-1,0.3-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4C624.7,388.4,625,388.2,625.5,387.9L625.5,387.9z
	 M627.6,385.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5l0.5,0.4c0.7,0.5,1,1.8,1,3.7c0,1-0.1,2.2-0.3,3.6l-0.1-0.1l-3.3-3.7
	c-4.7-5.1-8.3-8.5-10.7-10.3C630.6,386.6,628.5,385.4,627.6,385.1L627.6,385.1z M618.2,397.9c1.2-3.7,1.9-6.1,1.8-7
	c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1c-1,0.3-2.2,0.7-3.7,1l0.1-0.1l2.5-4.4
	C615,405.2,617.2,400.8,618.2,397.9L618.2,397.9z M621.9,390.6c0.3,1,1.5,3,3.6,6.3c1.8,2.6,5,6.3,9.6,11.1l3.6,3.6l0.3,0.1
	c-1.5,0.1-2.8,0.1-3.7,0.1c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6c-3.4-4.5-6.1-8.8-7.8-12.7C622.3,395,621.8,392.7,621.9,390.6
	L621.9,390.6z M642.8,401.4l0.3,0.3l2.5,1.5c-0.8,1-1.7,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6l-1.2-2.5l-0.4-0.4
	c1.2-1,2.3-1.9,3.4-3L642.8,401.4L642.8,401.4z"
          />
          <path
            fill="#EBEBEB"
            d="M631.1,410.2l0.4,0.5c0.4,0.7,1.7,1.1,3.6,1.2c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7
	c1.8-0.5,2.9-1.2,3-2.1l0.3-0.7l3.7,0.1C624,412.4,627.7,411.5,631.1,410.2L631.1,410.2z M596.8,406.2c-0.4-1.6-0.4-2.9,0.1-3.4
	l0.5-0.7c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4c-0.8,0.5-2.5,2.3-4.8,5.4c-1.9,2.5-4.4,6.6-7.7,12.5l-2.3,4.5v0.3
	C597.6,408.5,597,407.1,596.8,406.2L596.8,406.2z M602.3,407.3l0.3-0.6c2.2,1.6,4.4,2.9,6.9,3.7l-0.1,0.4l-0.7,2.9
	c-2.9-1.1-5.6-2.6-8.3-4.5L602.3,407.3L602.3,407.3z M483,219.9c-2.8-3-1.5-4.7,4.1-5.2h20.1l68.9,52.3l-32.5,0.7
	c-6.5,0-13.8-3.9-21.6-11.4C503.6,241,490.7,228.9,483,219.9L483,219.9z M501.4,256.9h-1.5c-2.5,0-4.7,1.1-6.5,3.4s-2.6,5.1-2.6,8.3
	c0,1.2,0.1,2.3,0.4,3.4l-94.1-3.9l-15.8-57.5c27.5,0.4,53.2,1.2,77.2,2.9c17.5,14.4,34.1,29.4,49.8,44.8c-1.7-0.7-3.6-1.1-5.5-1.4
	L501.4,256.9L501.4,256.9z M256.7,222.3c1.9-7.2,11.3-10.7,28.1-10.7c26.7-0.8,52-1.1,76.1-1.1l6.6,56.4l-109.8-4.5l-3.9-27.9
	L256.7,222.3L256.7,222.3z M662.4,289.9c1.5-1.8,4.4-2.2,8.4-1.5c11.8,2.9,20.1,6.1,24.9,9.5c3.2,2.2,5.4,5.2,6.6,8.9
	c1,3.7,1.4,7,1.5,9.8v0.3c0,1.5-0.1,2.6-0.4,3.7c0,0.4-0.1,0.8-0.3,1.2c-1.1,1.9-3.7,2.5-7.8,1.8c-4-0.6-8.1-2.6-12.2-5.9
	c-8.3-6.5-14.9-13.1-19.7-19.4C660.9,294.9,660.6,292,662.4,289.9L662.4,289.9z"
          />
          <path
            fill="#FFFFFF"
            d="M487.1,214.7c-5.6,0.6-7,2.3-4.1,5.2c7.7,8.9,20.6,21,39.1,36.3c8,7.6,15.1,11.4,21.6,11.4l32.5-0.7
	l13.3,5.8c-6.5,0-13.1,0.1-19.9,0.4l-29,0.1l-70.2-62.6h31.8l5.2,4L487.1,214.7L487.1,214.7z"
          />
          <path
            fill="none"
            d="M576.1,267l-32.5,0.7c-6.5,0-13.8-3.9-21.6-11.4c-18.3-15.3-31.4-27.4-39.1-36.3c-2.8-3-1.5-4.7,4.1-5.2h20.1
	 M228.2,191.6l-9.9-11.7c0-0.3-0.1-0.4-0.4-0.4h-0.6l-0.4,0.4c0,0-0.1,0.4,0,0.6l9.2,13.3"
          />
          <path
            fill="none"
            d="M226,193.6c1.9-0.1,2.6-0.8,2.2-1.9v-0.1 M228.2,191.6c1.8-0.8,5-1,9.5-0.7c3.2,0,5.1,1.7,5.9,5 M396.5,192.8
	c18.3,1,32.5,2.2,42.8,3.9c3.3,0.4,6.3,1,8.9,1.4c3,0.6,5.8,1.2,8.1,1.9c0.3,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.6,0.1,0.7
	c-0.1,0.3-0.3,0.4-0.5,0.6l-0.7,0.1l-0.7-0.3c-6.9-1.5-15.5-2.8-26-3.6c-14.4-1.4-32.5-2.1-53.8-2.3c-33.8-1-66.6-0.6-98.1,1.1
	c-13.5,0.6-28.5,1.6-45,3.7c-3.6,0.6-6.7,1.1-9.6,1.8l-0.7,0.3l-0.7-0.1c-0.3-0.1-0.4-0.3-0.6-0.6c-0.1-0.3,0-0.6,0.1-0.7
	c0.1-0.3,0.3-0.4,0.6-0.6c2.3-0.7,5.1-1.4,8.1-1.9c6.7-1.4,13.9-2.5,21.5-3.3 M243.6,195.8l7.4-0.6 M257.7,262.3l-3.9-27.9l2.7-12.1
	c1.9-7.2,11.3-10.7,28.1-10.7c26.7-0.8,52-1.1,76.1-1.1 M256.4,216.6c-1.2,1.4-1.9,3-2.3,5l-9.2,40.2 M244,264.5h1.4l137.3,5.6
	 M244.7,261.7l-0.7,2.8 M244.7,261.7l12.9,0.5 M223.7,197.8l19.9-1.9 M177.3,213.3c-7.8-0.7-13.1-2.3-15.7-5.1
	c-0.8-0.7-1-1.5-0.3-2.5l0.1-0.1c1-1.4,3.4-2.3,7.4-2.9l34.4-2.2 M177.3,213.3l8.5,0.4"
          />
          <path
            fill="none"
            d="M185.9,213.7c0.8-2.6,3.4-4.5,7.8-5.5c6.3-2.1,9.5-4.7,9.4-7.7 M197.8,214.1l-4.8,6.7l1.5,2.2l-15.8,27.5
	l-3.6,1.1l-4.1,7"
          />
          <path
            fill="none"
            d="M185.9,213.7l12,0.4l58.6,2.5 M151.3,260.6l3.6-2.6 M139.2,273.8c1.2-1.4,2.9-2.1,5.1-2.2h4.8
	c2.8,0,4.4,1.6,5.1,4.8c0.6,4.4,0.4,10.6-0.3,18.7c-0.1,9.5-1.6,16.1-4.5,19.8c-1.2,1.2-3.3,1.9-6.3,2.1c-4.4,0.3-7-1.4-8-4.5
	c-0.3-2.3-0.4-5.6-0.3-9.6 M151.3,260.6c-3,1.6-5.8,4-8.1,6.7l-1.2,1.5c-0.8,1.1-1.5,2.2-2.2,3.4"
          />
          <path
            fill="none"
            d="M134.8,314.4l0.1,0.8c0.3,1,0.7,1.7,1.2,2.3c1.6,2.1,4.4,2.9,8.4,2.8c3.6-0.3,6.2-1.1,7.7-2.3l0.3-0.4
	c3.3-4.1,5-11.3,5.2-21.6c1-8.9,1.1-16,0.4-20.8c-0.8-3.4-2.8-5.2-6.1-5.2h-5.9c-2.8,0.1-4.7,1-6.2,2.5 M244.7,261.7l-73.7-3
	l-16.1-0.7 M154.9,258c10.6-17.7,20.9-32.6,31-44.3 M244,264.5l-92.7-3.9 M203.2,200.5c6.3-1,13.2-1.9,20.5-2.8 M226,193.6
	c-1,0-1.8,1.4-2.3,4.1 M251.1,195.3l16-1.1c23.9-1.4,51.5-2.1,82.4-2.2c9.4,0,18.6,0,27.2,0.1l19.7,0.6 M371.1,210.6l-0.5-2.8
	 M371.1,210.6h10 M382.7,270.1l-0.5-2.8 M382.2,267.4l-14.7-0.5 M367.4,266.8l-6.6-56.4 M382.2,267.4l-11-56.8 M360.8,210.4h10.3
	 M370.6,207.8c-27-0.1-55.6,0.1-85.8,1c-14.6,0-24.1,2.6-28.3,7.8 M272.4,289.1h0.5c1.6,0,3,0.3,4.1,0.7c1.1,0.4,1.6,1,1.6,1.5
	s-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.5 M255.6,288.3c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1
	c0.8,0.8,1.4,1.6,1.6,2.6 M272.4,293.6c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7c-1.9,0-3.6-0.7-5-2.1
	c-1-1-1.6-2.1-1.9-3.2 M239.2,306.7c13.1,3,24.8,9.2,35.1,18.7c7.4,6.9,12.9,14.4,16.6,22.7c4.3,10.2,6.7,20.6,7.6,31.5 M402.9,297
	h-1.2c-0.5,0.5-1.1,0.8-1.8,0.8h-0.1c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1
	c0.7,0,1.4,0.3,1.9,1l1.8,0.1 M238.9,324.7c8.7,3,16.5,8.4,23.4,15.8c4.4,4.8,8.1,9.9,10.9,15.4c1.1,2.2,2.1,4.5,2.9,6.9
	c1,2.6,1.6,5.2,2.3,8c0.7,2.8,1.1,5.6,1.4,8.7c0.1,1.4,0.3,2.8,0.3,4c0.3,5.4,0.3,10.3,0.1,14.9 M280.1,379.4l18.4,0.4 M280.2,398.5
	v1.2 M280.2,399.7l-0.7-0.1c-0.5,0-1.1,0.3-1.7,0.7c-0.4,0.4-0.7,1-0.7,1.6v1.9"
          />
          <path
            fill="none"
            d="M281.7,402v-0.1c0-0.5-0.3-1.1-0.7-1.6c-0.3-0.3-0.5-0.4-1-0.5 M277.3,403.7v3.9c0,0.5,0.3,1.1,0.7,1.6
	c0.4,0.4,1,0.7,1.6,0.7c0.5,0,1.1-0.3,1.7-0.7c0.4-0.4,0.7-1,0.7-1.6v-5.5 M341.2,398.9c-1.8,2.1-5.5,3-11,3.3l-48.4-0.1
	 M280.2,398.5l60.9,0.4 M298.5,379.8l90.5,1.6 M389,381.4c2.3-25.2,2.5-50.1,0.4-74.7c-1-13.9-3.2-26.1-6.7-36.5 M204,277
	c2.5-2.5,5.5-3.7,9.1-3.7s6.6,1.2,9.2,3.7c2.5,2.5,3.7,5.6,3.7,9.2s-1.2,6.6-3.7,9.1c-2.5,2.5-5.6,3.9-9.2,3.9s-6.6-1.2-9.1-3.9
	c-2.5-2.5-3.9-5.5-3.9-9.1S201.5,279.6,204,277L204,277z M167.7,352.1c2.5-4.1,5.4-8,8.8-11.6c3-3.3,6.3-6.1,9.6-8.5
	c9.8-7,20.8-10.5,33.3-10.5c6.9,0,13.5,1.1,19.5,3.3"
          />
          <path
            fill="none"
            d="M171.1,397.6c-1.4-4.7-2.1-9.5-2.1-14.7c0-10.3,2.8-19.5,8.1-27.6c1.9-2.8,4.1-5.5,6.6-8c2.1-2.1,4.3-4,6.6-5.6
	c8.4-6.1,18.2-9.1,29.2-9.1c11,0,20.6,3,29.2,9.1c2.3,1.6,4.5,3.6,6.6,5.6c1.1,1.1,2.2,2.2,3,3.3c7.8,9.2,11.7,19.9,11.7,32.3
	c0,7.4-1.4,14.3-4.3,20.6 M165.2,325.7l-2.2,2.1c-3.6,3.6-6.7,7.4-9.5,11.4c-2.6,4.1-4.9,8.4-6.6,12.8 M165.2,325.7l0.1-0.1
	c15-13.9,33.2-20.9,54.5-20.9c6.7,0,13.3,0.7,19.4,2.2 M255,293h-1.2c-0.6,0.5-1.1,0.8-1.8,0.8h-0.3c-2.1,0-3.9-0.4-5.4-1
	c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1 M239.2,306.7l0.4-26l4.4-16.2"
          />
          <path
            fill="none"
            d="M219.1,348.5v-0.1l0.1-0.3l0.3-0.3l0.6,0.1l0.1,0.1 M220.2,348.1l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6
	c-0.1,3.9-0.4,6.3-0.8,7.2"
          />
          <path
            fill="none"
            d="M209,349.9l0.6,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1
	c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.6-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.7-4.8 M207.9,349.9l0.1-0.1
	c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.3,0,0.4,0.1l0.1,0.3 M239.2,306.7l-0.3,18"
          />
          <path
            fill="none"
            d="M207.9,349.9v0.3l0.6,5.1c0.8,6.7,1.6,11.6,2.6,14.6c1.1,3.7,2.1,6.1,2.6,6.7 M209.6,351.3l1.1,2.6v0.4
	 M209,349.9l0.7,1.4 M218.6,353.4l-0.1-0.5l0.4-2.6 M209.6,351.3c2.9-0.8,5.9-1.2,9.1-1.2h0.3 M210.7,354.3l1.6,11.6
	c0.6,2.2,1.4,3.2,2.6,2.9l3.2-0.6c1.1,0,1.8-1.2,1.8-3.4l-1.4-11.4 M210.7,354.3c2.5-0.7,5-1,7.7-1 M225.5,354.2v-0.8
	c0-0.8-0.7-1.8-1.9-2.9 M221.5,375.4c1.4-1.6,2.3-3.9,2.9-6.5c0.8-4.1,1.2-9.1,1.1-14.7 M246.7,362.4h-0.3l-4.5,2.2
	c-6.2,2.9-10.6,5.2-13.1,7c-3.2,2.2-5.1,3.7-5.6,4.5 M228.5,376.6c0.6,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1
	c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.6-1.9c0.4-0.5,1-1,1.6-1.1S227.9,376.2,228.5,376.6L228.5,376.6z"
          />
          <path
            fill="none"
            d="M228.9,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3s-0.7-0.7-0.8-1.2s0-1,0.3-1.4
	c0.3-0.4,0.7-0.7,1.2-0.8c0.6-0.1,1,0,1.4,0.4C228.5,377.6,228.8,378,228.9,378.4L228.9,378.4z M223.1,376.2l-0.3,0.5
	c-0.4,0.8-0.6,1.6-0.3,2.8c0.1,0.8,0.6,1.7,1.1,2.3l0.3,0.3c0.7,0.5,1.4,1,2.1,1.1l0.7,0.1 M223.1,376.2c0.7-2.1,1.9-4,3.9-5.9
	c3-3,6.9-5.9,11.8-8.9 M246.8,373.7l-10.6,5c-2.2,0.7-3.4,0.5-3.9-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1"
          />
          <path
            fill="none"
            d="M247.1,363.5l-4.4,2.1c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.6,1.2-0.6,2.1v0.4
	c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8l4.3-2.2 M220.7,390.6l-0.1-0.7
	c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1"
          />
          <path
            fill="none"
            d="M227.4,388.4c0.3,0.7,0.4,1.4,0.1,2.1c-0.3,0.7-0.7,1.2-1.4,1.5c-0.7,0.3-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4
	s-0.4-1.4-0.1-2.1c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1C226.6,387.3,227.1,387.7,227.4,388.4L227.4,388.4z"
          />
          <path
            fill="none"
            d="M245.7,403.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3
	c-0.6,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.6,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4"
          />
          <path
            fill="none"
            d="M225.6,387.7c0.6,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.6,0.8-1,1.1c-0.6,0.3-1,0.3-1.5,0.1
	s-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1C224.6,387.6,225.2,387.6,225.6,387.7L225.6,387.7z M226.3,385.1
	c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5 M248,380.5c-5.4,1.9-10.2,3-14.4,3.6c-2.6,0.3-5,0-7-0.8"
          />
          <path
            fill="none"
            d="M226.7,383.3c1,0.1,3.3-0.4,7.2-1.5c2.9-0.8,7.4-2.8,13.6-5.8l4.5-2.2l0.1-0.1 M212.4,377.7
	c-0.8-0.7-3-1.6-6.6-3c-3-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3"
          />
          <path
            fill="none"
            d="M193.4,367.4c5.5,1.7,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7 M212.4,377.7l0.4,0.4c0.6,0.5,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1l-0.6,0.3 M215.8,371.4c0.6-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1
	c0.4,0.5,0.6,1.2,0.4,1.9s-0.4,1.2-1.1,1.8c-0.6,0.4-1.2,0.5-1.9,0.4c-0.7-0.1-1.4-0.4-1.8-1.1c-0.4-0.5-0.6-1.2-0.6-1.9
	C214.9,372.5,215.1,371.8,215.8,371.4L215.8,371.4z"
          />
          <path
            fill="none"
            d="M216.2,372.1c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2
	c-0.4,0.3-1,0.4-1.4,0.3c-0.6-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C215.6,372.8,215.7,372.3,216.2,372.1L216.2,372.1z
	 M213.6,376.5l0.4,0.4c0.7,0.5,1.5,1,2.5,1.1c0.8,0.1,1.6,0,2.5-0.3l0.4-0.1c0.7-0.4,1.4-1,1.6-1.6l0.3-0.5 M216.1,380.3
	c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C215,381.8,215.4,381,216.1,380.3L216.1,380.3z M211.8,387.2
	c0.1-0.1,0.4-0.1,0.6-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.6,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3v0.6 M207.6,381.8
	c-0.1-0.5-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1c0.6-0.1,1-0.1,1.5,0.1c0.4,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.5c-0.3,0.5-0.6,0.8-1.1,1
	l-1.5-0.1C208,382.7,207.7,382.2,207.6,381.8L207.6,381.8z"
          />
          <path
            fill="none"
            d="M206.9,382c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1c0.7,0.3,1.1,0.8,1.4,1.5
	c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4c-0.7,0.3-1.4,0.1-2.1-0.1C207.6,383.2,207,382.7,206.9,382L206.9,382z"
          />
          <path
            fill="none"
            d="M184.2,381.8l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.6-1.4l0.1-0.4
	c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2c-0.7-0.6-2.8-1.5-6.2-2.8c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8 M207,415.1l2.3-4.3
	c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3c-0.4-0.4-1-0.7-1.6-0.8c-1-0.1-1.8-0.1-2.5,0.4
	c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4 M213.6,376.5c-1.6-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14
	 M246.7,362.4h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3 M247.1,363.5l-1.2,1 M238.8,361.3l0.7-0.4
	c0.7-0.5,1.8-0.4,3.6,0.1 M243.4,366.7l0.3-0.3l2.2-1.9 M223.5,350.5c7,1,13.1,4,18.3,9.2l1.2,1.2 M243.1,361.1
	c1,0.3,2.2,0.8,3.6,1.5 M250,373l-2.9,0.4l-0.3,0.1 M251.3,372.8h0.4c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1 M250,373
	l1.4-0.3 M252,373.7c-0.4,1.5-0.7,2.8-1.1,3.7 M248,380.5l0.7-0.3c0.8-0.3,1.5-1.2,2.2-2.9 M245.4,395.5c1.8-3.9,2.8-8,2.8-12.5
	l-0.1-2.3"
          />
          <path
            fill="none"
            d="M250.9,377.3c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6 M243.4,366.7c1.5,2.2,2.6,4.5,3.4,7"
          />
          <path
            fill="none"
            d="M255,382.9c0,10-3.6,18.6-10.7,25.7s-15.7,10.6-25.7,10.6c-10,0-18.6-3.6-25.7-10.6
	c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6S255,372.9,255,382.9
	L255,382.9z M245.8,364.5c1.8,2.6,3.2,5.4,4.1,8.4"
          />
          <path
            fill="none"
            d="M245.4,395.5l0.6,0.4c0.7,0.6,1,1.8,1,3.7 M246.7,403.2l-0.1-0.1l-3.3-3.7c-4.7-5.1-8.3-8.5-10.7-10.3
	c-3.2-2.3-5.1-3.7-6.1-3.9 M246.9,399.6c0,1-0.1,2.2-0.3,3.6 M246.7,403.2v0.3c0,0.1-0.1,0.3-0.3,0.4s-0.3,0.1-0.4,0.1h-0.3
	 M241.7,401.4c-0.7,0.8-1.4,1.6-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3 M241.7,401.4l0.3,0.3l2.5,1.5 M244.4,403.2c-0.8,1-1.6,1.9-2.6,2.9
	c-1.4,1.4-2.8,2.5-4.1,3.6 M245.7,403.8l-1.2-0.7"
          />
          <path
            fill="none"
            d="M265.9,403.6c-1.9,4.1-4.4,8.1-7.4,11.7c-1,1.1-2.1,2.2-3,3.3c-5.5,5.5-11.7,9.5-18.6,12
	c-5.4,1.9-11,2.9-17.2,2.9c-6.1,0-11.8-1-17.2-2.9c-6.9-2.5-13.1-6.5-18.6-12c-2.6-2.6-4.8-5.2-6.6-8c-2.8-4-4.7-8.4-6.1-12.9"
          />
          <path
            fill="none"
            d="M237.7,411.8l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3 M238.4,410.9
	c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3 M237.7,409.6l0.7,1.2 M212.3,391c0-0.5,0.1-1,0.6-1.4
	c0.4-0.4,0.8-0.5,1.4-0.5s1,0.1,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6s-1-0.1-1.4-0.5
	C212.5,392,212.3,391.6,212.3,391L212.3,391z"
          />
          <path
            fill="none"
            d="M212.3,389.3c0.6-0.5,1.1-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8c0.6,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
	c-0.6,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8s-0.8-1.1-0.8-1.9C211.6,390.4,211.8,389.7,212.3,389.3L212.3,389.3z M201.3,406.7
	l5.6-10c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3 M207.9,415.8l0.1-0.1l2.5-4.4
	c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.7,1.9-6.1,1.8-7"
          />
          <path
            fill="none"
            d="M218.9,390.9c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2 M236.1,406.7l-8-8.5c-1.4-1.9-1.6-3.2-0.7-3.9
	l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4 M237.7,409.6l-1.4-2.5l-0.3-0.4 M234,412c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6 M208.1,410.4
	l-0.1,0.4l-0.7,2.9 M207,415.1l0.3-1.4 M207.9,415.8c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3 M211.6,415c-1,0.3-2.2,0.7-3.7,1
	 M215,412.1l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1"
          />
          <path
            fill="none"
            d="M215,412.1l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2 M234,412c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7"
          />
          <path
            fill="none"
            d="M230,410.2c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9 M196.9,409.9v-0.3l2.3-4.5
	c3.2-5.9,5.8-10.2,7.7-12.5c2.3-3,4-4.8,4.8-5.4"
          />
          <path
            fill="none"
            d="M196.2,401.9c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4 M238.8,361.3c-3.9-3.7-8.4-6.1-13.3-7.2
	 M237.7,411.8c-1.5,0.1-2.8,0.1-3.7,0.1 M146.8,352.1c-1.4,3.7-2.5,7.6-3.3,11.6c-1.8,6.1-5.1,9.2-9.8,9.5h-12.5
	c-2.3,0-3.7-1.4-4.3-3.9c-2.5-5.9-4.8-7.8-6.9-5.9l-1.4,5.2 M167.7,352.1l-0.6,0.8c-5.4,9.4-8.3,19.8-8.5,31.5v9.1 M146.8,352.1
	h20.9 M204.3,357.1l-0.1-0.8c-0.3-0.8,0.1-1.9,1.2-3.4 M189.3,368.2c1.5-1,2.6-1.2,3.4-1l0.8,0.3 M204.3,357.1c-2.3,1.4-4.5,3-6.5,5
	c-1.6,1.7-3.2,3.6-4.4,5.4"
          />
          <path
            fill="none"
            d="M189.3,368.2c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9 M190.5,373.9l-0.6-0.1l-2.5-1.2"
          />
          <path
            fill="none"
            d="M189.1,381.4l11.4,1.9c2.3,0.1,3.6-0.4,3.6-1.5l0.6-3.2c0.3-1.1-0.6-2.1-2.8-2.8l-11.4-2.2 M186.1,371.8H186
	l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1 M187.5,372.5l-1.4-0.7 M183.9,382.9l0.3,0.1l5.1,1
	c6.7,1.2,11.6,1.9,14.6,2.1c3.9,0.1,6.3,0,7.3-0.4 M183.9,382.9l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1
	 M185.9,381.7l2.8-0.3h0.6 M189.6,388.4h-0.8c-0.8,0-1.8-0.7-2.9-2.1 M184.2,381.8l1.6-0.1 M186,386.2c-0.7-0.8-1.4-1.9-2.1-3.3
	 M185.9,381.7c0.1-3.2,0.7-6.3,1.6-9.2 M190.5,373.9c-0.8,2.5-1.2,5-1.4,7.7 M186.1,370.5c1.2-1,2.2-1.8,3.2-2.3 M199.1,409.2
	l1.9-1.9l0.3-0.6 M195.6,406.2c-0.4-1.6-0.4-2.9,0.1-3.4l0.6-0.7 M195.6,406.2c-5.6-5.6-8.8-12.2-9.5-19.9 M198.1,410.3H198v0.1
	l-0.3,0.1h-0.4l-0.4-0.4v-0.3 M199.1,409.2l-1,1.1 M196.9,409.9c-0.6-1.5-1.1-2.8-1.4-3.9 M158.6,393.5v3.4 M158.6,397l12.5,0.7
	 M158.6,397l-7.4-0.4l-44-8.4c-2.6-0.6-4-1.4-3.9-2.3c0.4-1.5,2.3-1.9,6.2-1.5l1.4,0.1"
          />
          <path
            fill="none"
            d="M211,385.5c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3 M189.6,388.4c1,5.1,3,9.6,6.6,13.6 M208.1,410.4
	c-2.5-1-4.7-2.2-6.9-3.7 M207.3,413.7c-2.9-1.1-5.6-2.6-8.3-4.5 M205.4,352.8c0.6-0.8,1.5-1.9,2.5-3 M218.9,350.2l0.3-1.6
	 M223.5,350.5c-0.8-0.7-1.9-1.5-3.3-2.3 M272.4,293.6L255,293 M255.6,288.3l16.8,0.8 M277.3,403.7l-11.4-0.1 M257.7,262.3l109.8,4.5
	 M139.6,259.5l0.1,0.1c0.3,0.3,0.6,0.4,1,0.4l10.7,0.4 M139.6,259.5c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.7-0.4,1-0.4 M123.9,334.4c-5.5-23-5.5-42.8,0.3-59c0.6-1.1,1.1-2.1,1.8-3l2.2-3.2c3.2-4.3,7-7.4,11.4-9.5 M139.9,272.5
	c-0.7,0.7-1.1,1.5-1.5,2.5v0.1 M138.4,275.1l0.8-1.2 M139.9,272.5l-0.7,1.4"
          />
          <path
            fill="none"
            d="M134.7,302.7l0.3-5.1c0.8-11.1,1.9-18.7,3.4-22.7 M134.8,314.4v11.3 M123.3,334.9l0.7-0.5 M123.9,334.4
	l10.9-8.7 M123.9,334.4c-3.4,3.9-8.3,5.6-14.6,5.4c-2.2-0.1-3.6,0.5-4,1.9l-1.1,5.1c1.9,0.7,2.9,2.1,2.9,4.1
	c-0.1,7.8,0.4,13.8,1.4,17.6 M134.7,302.7c-0.3,4.8-0.3,8.7,0.1,11.6 M110.9,384.3c-0.7-5.9-1.9-9.2-3.7-10.2
	c-1.8-1.4-1.2-3.3,1.5-5.5"
          />
          <path
            fill="none"
            d="M108.7,368.6c2.3-1.1,4.4,1.1,6.3,6.7c0,0.7,0.1,1.2,0.4,1.9 M110.9,384.3l2.8,0.3 M113.6,384.6l9.2,1.2
	 M113.6,384.6c-0.1-4.3,0.4-6.7,1.5-7.6 M115.3,377.2c1,3.4,3.4,6.3,7.6,8.7 M122.8,385.8c7.2,4.1,19.1,6.7,35.8,7.7 M154.9,258
	l-14-0.5 M134.8,325.7h30.4 M140.9,257.5c12.8-17.7,24.9-32.5,36.5-44.2 M663.9,285.4l1.5,0.3c15.3,3.6,25.9,7.4,31.6,11.6
	c3.3,2.3,5.6,5.6,6.9,9.8c1.5,6.5,1.8,11.4,1,14.9c-0.3,3-2.5,4.5-6.5,4.5c-6.2,0.1-11.1-1.2-15-4.1c-10.7-8.3-19.1-16.1-25.6-23.7
	c-3.9-4.3-5-7.8-3.3-10.7C656,285.5,659.1,284.7,663.9,285.4L663.9,285.4z"
          />
          <path
            fill="none"
            d="M670.9,288.6c11.8,2.9,20.1,6.1,24.9,9.5c3.2,2.2,5.4,5.2,6.6,8.9c1,3.7,1.4,7,1.5,9.8v0.3
	c0,1.5-0.1,2.6-0.4,3.7c0,0.4-0.1,0.8-0.3,1.2c-1.1,1.9-3.7,2.5-7.8,1.8c-4-0.5-8.1-2.6-12.2-5.9c-8.3-6.5-14.9-13.1-19.7-19.4
	c-2.6-3.4-3-6.2-1.1-8.3C664,288.1,666.8,287.7,670.9,288.6L670.9,288.6z M596.2,308.2c7.6-2.5,15.7-3.6,24.3-3.6
	c20.9,0,38.7,7,53.5,20.9c1.1,1,2.1,1.9,3,2.9 M573.4,400.8c-2.1-5.5-3-11.4-3-17.9c0-10.3,2.8-19.5,8.1-27.6c1.9-2.8,4.1-5.5,6.6-8
	c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1c11,0,20.6,3,29.2,9.1c2.3,1.6,4.5,3.6,6.6,5.6c1.1,1.1,2.2,2.2,3,3.3
	c7.8,9.2,11.7,19.9,11.7,32.3c0,7-1.2,13.6-3.8,19.7"
          />
          <path
            fill="none"
            d="M598.1,272.7c12.4,0,24.2,0.1,35.4,0.5c33.7,2.6,59,8.3,76.1,16.9c9.5,5.1,15.1,9.5,16.9,13.1
	c1.1,2.2,0.7,3.7-1.2,4.4l-0.8,0.1 M725.8,334.1h1.4c2.1,0,2.8,1.2,2.2,3.7c-0.8,4,0.4,6.3,3.7,7c3.9,0,6.1,2.2,6.3,6.3v12.5
	c0,2.1-1,3.4-2.6,4.1 M725.8,334.1h-2.9c-1.2,0-2.1,0.7-2.6,2.2c-0.3,0.7-0.7,1.2-1.2,1.6c-0.7,0-1.2-0.3-1.7-0.7 M716.8,332.2v2.5
	c-0.1,1.1,0,1.9,0.4,2.5 M717.4,337.1l1.1-2.5c0.8-1.8,2.3-2.6,4.3-2.6 M722.9,332.2c2.3,0.1,3.3,0.8,2.9,1.9"
          />
          <path
            fill="none"
            d="M716.8,332.2c0-5.1,0.8-11.3,2.3-18.3c1-3.6,2.6-5.6,5.1-6.2 M724.3,307.7c0.5,9.9,0,18-1.5,24.3 M731.7,391
	l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-13.2c0-2.9,1.2-4.5,3.6-4.7h3.2 M734.6,368.3c0.8-0.1,1.5-0.3,2.1-0.5"
          />
          <path
            fill="none"
            d="M734,391.6l-1.7-0.3l-1.9-0.5l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-5c-0.1-1.9-1-3-2.5-3.3l-21.9-1.9
	c-4.3,0-6.5-1.9-6.5-5.6c-0.1-2.5-0.5-5-1-7.4"
          />
          <path
            fill="none"
            d="M731.7,391c1.4-4.1,2.3-8.3,2.9-12.4c0.4-3.4,0.4-6.9,0.1-10.3 M733.6,391.6l-1.9-0.5 M733.6,391.6h0.4
	 M734,391.6l2.3,0.3c1.2,0.5,1,1.9-0.8,4.3c-1.7,1.6-5.2,2.9-10.9,3.7c-7.2,1-16.1,1.6-26.7,1.9 M736.7,367.8
	c0.8,7.7-0.1,15.7-3,23.8 M558.4,384.4c0.3-11.7,3.2-22.1,8.7-31.5c2.6-4.4,5.8-8.5,9.5-12.5c3.2-3.3,6.5-6.1,9.9-8.5
	c9.9-7,21.3-10.5,34-10.5c17.2,0,31.8,6.3,43.9,19c4.5,4.8,8.3,9.9,11,15.4c1.1,2.2,2.2,4.5,3,6.9"
          />
          <path
            fill="none"
            d="M648.2,363.5l-4.4,2.1c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.5,1.2-0.5,2.1v0.4
	c0.1,0.5,0.4,1.1,0.8,1.6c0.5,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8l4.3-2.2 M652.6,372.8h0.4
	c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1"
          />
          <path
            fill="none"
            d="M653.3,373.7l-0.1,0.1l-4.5,2.2c-6.2,3-10.7,5-13.6,5.8c-3.7,1.1-6.2,1.5-7.2,1.5 M652.6,372.8l-1.4,0.3
	 M653.3,373.7c-0.4,1.5-0.7,2.8-1.1,3.7 M649.3,380.5l0.7-0.3c0.8-0.3,1.5-1.2,2.2-2.9"
          />
          <path
            fill="none"
            d="M652.2,377.3c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6 M677.1,328.5c6.9,7.2,11.8,15,15,23.7
	c1.2,3.4,2.2,7,2.9,10.6 M698.1,401.9c-0.3,4.4-1.9,7.2-5.2,8.5l-7.7,2.6c-2.3,0.4-3.6,0.1-3.6-1v-0.3c0.3-3,0.5-6.2,0.7-9.5
	 M682.5,402.2c5.6,0,10.9-0.1,15.7-0.3 M667.6,402.6c-1.9,4.5-4.5,8.8-7.8,12.8c-1,1.1-2.1,2.2-3,3.3c-5.5,5.5-11.7,9.5-18.6,12
	c-5.4,1.9-11,2.9-17.2,2.9c-6.1,0-11.8-1-17.2-2.9c-6.9-2.5-13.1-6.5-18.6-12c-2.6-2.6-4.8-5.2-6.6-8c-2.1-3.2-3.7-6.5-5.1-9.9
	 M678.5,362.7c2.3,6.5,3.7,13.3,4,20.8c0.3,6.7,0.3,12.9,0,18.7 M695,362.7h-16.5 M682.5,402.2l-14.9,0.4 M677.1,328.5l39.8,3.7
	 M502,210.7l5.2,4l68.9,52.3l13.3,5.9 M508.2,258.3c1,0.4,1.9,1,2.9,1.5l0.5,0.4c2.6,1.8,4.1,3.9,4.7,6.3 M516.3,266.5
	c0.1,0.7,0.3,1.2,0.3,1.9c0,0.8-0.1,1.5-0.3,2.3 M491.2,271.9c-0.3-1.1-0.4-2.2-0.4-3.4c0-3.2,0.8-5.9,2.6-8.3
	c1.8-2.3,3.9-3.4,6.5-3.4h1.5 M501.4,256.9l1.2,0.1c1.9,0.3,3.9,0.7,5.5,1.4"
          />
          <path
            fill="none"
            d="M501.4,280.2c-2.2-0.3-4-1.4-5.5-3.4c-0.4-0.5-0.8-1.2-1.2-1.9c-0.4-0.8-0.8-1.8-1.1-2.8
	c-0.3-1.1-0.4-2.3-0.4-3.6c0-3.2,0.8-5.9,2.6-8.3c1.5-2.1,3.4-3.2,5.5-3.3 M470.2,210.7H502 M526.7,273.4l-5.1-5.2
	c-19.3-19.9-39.9-38.9-61.9-57.1c-0.3-0.1-0.5-0.3-0.8-0.3 M396.5,192.8c19,0.4,36.2,1.2,51.6,2.6l18.7,1.9
	c15.7,2.6,27.5,7,35.4,13.5 M458.9,210.7h11.3 M397.1,268.1l94.1,3.9 M381.2,210.6c27.5,0.4,53.2,1.2,77.2,2.9
	c17.5,14.4,34.1,29.4,49.8,44.8 M518.1,275.8l11.3,0.4h0.6 M529.9,276.2l-3.2-2.8 M536,275.9l-6.1,0.3 M549.4,288.4
	c-0.3,0.4,0,1,0.8,1.8l14.4,12.8c0.8,0.7,1.9,1.4,3.3,1.9c1.4,0.6,2.5,0.7,3.4,0.7s1.5-0.3,1.7-0.8c0-0.5-0.3-1.1-1.1-1.8
	l-13.1-11.7c-0.8-0.7-1.8-1.4-3.2-1.9c-1.4-0.7-2.8-1.1-3.9-1.2C550.4,287.9,549.7,288,549.4,288.4L549.4,288.4z"
          />
          <path
            fill="none"
            d="M545,287.3c0.1,0.4,0.4,0.8,1,1.4l16.2,15.7c2.3,2.2,5.2,3.3,8.5,3.2l10-0.4c3-0.1,3.3-1.2,0.8-3.3L564.6,289
	c-1.9-1.8-4.4-2.6-7.3-2.6l-9.4-0.3c-1.8,0-2.8,0.1-2.9,0.8"
          />
          <path
            fill="none"
            d="M580.8,315.1l-5.1-4.5l-0.3-0.3c-1.5-1.1-3.6-1.5-6.3-1H569c-1.7-0.1-3.4-0.8-5.1-1.9
	c-10.5-8.9-16.8-15.4-19-19.4v-0.7 M545,287.3v-0.4 M545,286.9v-3c-0.3-1.2-1-2.2-1.9-3.2l-7-4.8 M550,275.2l-14,0.7 M526.7,273.4
	l13.8-0.1 M580.8,315.1c-4.8,2.9-9.4,6.3-13.6,10.5l-2.3,2.2c-3.6,3.6-6.7,7.4-9.2,11.4c-5.1,7.8-8.5,16.5-10,26
	c-1.2,7-2.1,13.2-2.5,19 M529.9,276.2c8.7,4.4,13.1,20.8,13.1,49.1v0.3l-0.1,1c-1.1,23.5-2.8,42.6-5.4,57.6 M540.5,273.3l29-0.1
	c6.9-0.1,13.5-0.3,19.9-0.4 M589.5,272.9l8.7-0.1 M620.3,348.5v-0.1l0.1-0.3c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.4,0,0.5,0.1l0.1,0.1"
          />
          <path
            fill="none"
            d="M621.4,348.1l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6c-0.1,3.9-0.4,6.3-0.8,7.2"
          />
          <path
            fill="none"
            d="M610.2,349.9l0.5,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1
	c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.5-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.7-4.8 M609.1,349.9l0.1-0.1l0.4-0.3
	c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.3 M594.7,367.4c1.2-1.9,2.8-3.7,4.4-5.4c2.1-2.1,4.1-3.7,6.5-5 M590.4,368.2
	c1.5-1,2.6-1.2,3.4-1l0.8,0.3 M590.4,368.2c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9"
          />
          <path
            fill="none"
            d="M609.1,349.9v0.3l0.5,5.1c0.8,6.7,1.7,11.6,2.6,14.6c1.1,3.7,2.1,6.1,2.6,6.7 M605.6,357.1l-0.1-0.8
	c-0.3-0.8,0.1-1.9,1.2-3.4 M606.7,352.8c0.5-0.8,1.5-1.9,2.5-3 M610.9,351.3l1.1,2.6v0.4 M610.2,349.9l0.7,1.4 M612,354.3l1.7,11.6
	c0.6,2.2,1.4,3.2,2.6,2.9l3.2-0.6c1.1,0,1.8-1.2,1.8-3.4l-1.4-11.4 M585.5,381.8l5,1c6.7,1.2,11.6,1.9,14.6,2.1
	c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.7-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2s-2.8-1.5-6.2-2.8
	c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8"
          />
          <path
            fill="none"
            d="M590.4,381.4l11.4,1.9c2.3,0.1,3.6-0.4,3.6-1.5l0.5-3.2c0.3-1.1-0.5-2.1-2.8-2.8l-11.4-2.2 M617.4,372.1
	c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3
	c-0.5-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C616.7,372.8,617,372.3,617.4,372.1L617.4,372.1z"
          />
          <path
            fill="none"
            d="M617,371.4c0.5-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1c0.4,0.5,0.5,1.2,0.4,1.9c-0.1,0.7-0.4,1.2-1.1,1.8
	c-0.5,0.4-1.2,0.5-1.9,0.4s-1.4-0.4-1.8-1.1c-0.4-0.5-0.5-1.2-0.5-1.9C616,372.5,616.4,371.8,617,371.4L617,371.4z"
          />
          <path
            fill="none"
            d="M614.9,376.5l0.4,0.4c0.7,0.5,1.5,1,2.5,1.1c0.8,0.1,1.7,0,2.5-0.3l0.4-0.1c0.7-0.4,1.4-1,1.7-1.6l0.3-0.5
	 M614.9,376.5c-1.7-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14 M612.3,385.5l0.5-0.3c0.8-0.4,1.4-1.1,1.8-2.1
	c0.4-0.7,0.5-1.5,0.4-2.5v-0.4c-0.1-0.8-0.5-1.5-1-2.1l-0.4-0.4 M611.5,379.5c0.4,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.5
	c-0.3,0.5-0.5,0.8-1.1,1l-1.5-0.1c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.5-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1
	C610.5,379.2,611.1,379.2,611.5,379.5L611.5,379.5z"
          />
          <path
            fill="none"
            d="M613.1,380.3c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4s-1.4,0.1-2.1-0.1c-0.7-0.3-1.1-0.8-1.4-1.5
	c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1C612.4,379.2,613,379.6,613.1,380.3L613.1,380.3z M617.2,380.3
	c0.7-0.7,1.5-1.1,2.6-1.1c1.1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1
	c-0.7-0.7-1.1-1.5-1.1-2.6C616.3,381.8,616.6,381,617.2,380.3L617.2,380.3z M613.1,387.2c0.1-0.1,0.4-0.1,0.6-0.3
	c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.7,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3v0.6"
          />
          <path
            fill="none"
            d="M608.2,415.1l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3
	c-0.4-0.4-1-0.7-1.7-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4 M613.5,377.7
	c-1.2-1.8-2.9-3.3-5.2-4.7c-3.7-2.1-8.3-3.9-13.8-5.6 M612.3,385.5c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3"
          />
          <path
            fill="none"
            d="M585.2,382.9l0.3,0.1l5.1,1c6.7,1.2,11.6,1.9,14.6,2.1c3.9,0.1,6.3,0,7.3-0.4 M613.5,377.7
	c-0.8-0.7-3-1.6-6.6-3c-2.9-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3 M587.4,371.8h-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4
	c0-0.1,0.1-0.3,0.3-0.4h0.1 M588.8,372.5l-1.4-0.7 M591.7,373.9l-0.4-0.1l-2.5-1.2 M587.1,381.7l2.8-0.3h0.5 M590.8,388.4H590
	c-0.8,0-1.8-0.7-2.9-2.1 M591.7,373.9c-0.8,2.5-1.2,5-1.4,7.7 M587.1,381.7c0.1-3.2,0.7-6.3,1.7-9.2 M587.4,370.5
	c1.2-1,2.2-1.8,3.2-2.3 M619.7,353.4v-0.5l0.4-2.6 M610.9,351.3c2.9-0.8,5.9-1.2,9.1-1.2h0.3 M626.7,354.2v-0.8
	c0-0.8-0.7-1.8-1.9-2.9"
          />
          <path
            fill="none"
            d="M622.8,375.4c1.4-1.6,2.3-3.9,2.9-6.5c0.8-4.1,1.2-9.1,1.1-14.7 M624.7,350.5c7,1,13.1,4,18.3,9.2l1.2,1.2
	 M647.9,362.4h-0.3l-4.5,2.2c-6.2,2.9-10.6,5.2-13.1,7c-3.2,2.2-5.1,3.7-5.6,4.5 M647.9,362.4h0.3c0.1,0.1,0.3,0.1,0.3,0.3
	c0,0.1,0.1,0.3,0,0.4l-0.1,0.3 M644.5,366.7l0.4-0.3l2.2-1.9 M644.2,361.1c1,0.3,2.2,0.8,3.6,1.5 M647.1,364.5l1.1-1 M639.9,361.3
	l0.7-0.4c0.7-0.5,1.8-0.4,3.6,0.1 M651.2,373l-2.9,0.4l-0.4,0.1 M646.7,395.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3"
          />
          <path
            fill="none"
            d="M627.8,383.3c1.9,0.7,4.3,1,7,0.8c4.3-0.5,9.1-1.6,14.4-3.6 M647.9,373.7l-10.6,5c-2.2,0.7-3.4,0.5-3.8-0.5
	l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1"
          />
          <path
            fill="none"
            d="M624.4,376.2l-0.3,0.5c-0.4,0.8-0.5,1.6-0.3,2.8c0.1,0.8,0.5,1.7,1.1,2.3l0.3,0.3c0.7,0.5,1.4,1,2.1,1.1
	l0.7,0.1"
          />
          <path
            fill="none"
            d="M630.9,378.4c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.5-1.9
	c0.4-0.5,1-1,1.7-1.1c0.7-0.1,1.4,0.1,1.9,0.5C630.5,377,630.7,377.6,630.9,378.4L630.9,378.4z"
          />
          <path
            fill="none"
            d="M630.2,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2
	c-0.1-0.6,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0,1.4,0.4C629.8,377.6,630,378,630.2,378.4L630.2,378.4z M628.7,388.4
	c0.3,0.7,0.4,1.4,0.1,2.1s-0.7,1.2-1.4,1.5s-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4s-0.4-1.4-0.1-2.1c0.3-0.7,0.7-1.2,1.4-1.5
	c0.7-0.3,1.4-0.4,2.1-0.1C627.8,387.3,628.4,387.7,628.7,388.4L628.7,388.4z"
          />
          <path
            fill="none"
            d="M621.9,390.6l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8c1-0.5,1.8-0.7,2.6-0.5
	l0.7,0.1"
          />
          <path
            fill="none"
            d="M647,403.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3
	c-0.5,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.5,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4"
          />
          <path
            fill="none"
            d="M626.9,387.7c0.5,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.5,0.8-1,1.1c-0.5,0.3-1,0.3-1.5,0.1
	c-0.5-0.1-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1C625.9,387.6,626.5,387.6,626.9,387.7L626.9,387.7z
	 M624.4,376.2c0.7-2.1,1.9-4,3.9-5.9c3-3,6.9-5.9,11.8-8.9 M644.5,366.7c1.5,2.2,2.6,4.5,3.4,7 M626.7,354.2c5,1.1,9.4,3.4,13.3,7.1
	 M627.6,385.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5 M609.1,415.8l0.1-0.1l2.5-4.4c3.3-6.1,5.5-10.5,6.5-13.3
	c1.2-3.7,1.9-6.1,1.8-7 M639,411.8l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3"
          />
          <path
            fill="none"
            d="M631.1,410.2c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9 M620,390.9c0.7,2.1,0.8,4.4,0.4,7
	c-0.7,4.3-2.2,8.9-4.3,14.2 M637.2,406.7l-8-8.5c-1.4-1.9-1.7-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4 M646.7,395.5
	l0.5,0.4c0.7,0.6,1,1.8,1,3.7 M642.8,401.4l0.4,0.3l2.3,1.5 M647.9,403.2v0.3c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1H647
	 M645.6,403.2c-0.8,1-1.7,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6 M647.9,403.2l-0.1-0.1l-3.3-3.7c-4.7-5.1-8.3-8.5-10.7-10.3
	c-3.2-2.3-5.1-3.7-6.1-3.9 M647,403.8l-1.4-0.7 M648.2,399.6c0,1-0.1,2.2-0.3,3.6"
          />
          <path
            fill="none"
            d="M642.8,401.4l-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3 M638.8,409.6l-1.2-2.5l-0.4-0.4 M639.5,410.9
	c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3 M638.8,409.6l0.7,1.2 M639,411.8
	c-1.5,0.1-2.8,0.1-3.7,0.1 M635.1,412c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6 M616.1,412.1l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2"
          />
          <path
            fill="none"
            d="M619.9,419.2c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.7,10.7-25.7
	c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7c0,10-3.6,18.6-10.7,25.7
	C638.6,415.7,629.9,419.2,619.9,419.2L619.9,419.2z"
          />
          <path
            fill="none"
            d="M635.1,412c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7 M596.8,406.2c-0.4-1.6-0.4-2.9,0.1-3.4l0.5-0.7
	 M596.8,406.2c-5.6-5.6-8.8-12.2-9.5-19.9 M613.5,389.3c0.5-0.5,1.1-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9
	s-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.6-0.8-1.1-0.8-1.9C612.7,390.4,613,389.7,613.5,389.3
	L613.5,389.3z"
          />
          <path
            fill="none"
            d="M615.5,393c-0.5,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4c0.4-0.4,0.8-0.5,1.4-0.5
	c0.5,0,1,0.1,1.4,0.5s0.5,0.8,0.5,1.4c0,0.5-0.1,1-0.5,1.4S616,393,615.5,393z M602.5,406.7l5.6-10c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5
	c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3 M600.3,409.2l1.9-1.9l0.3-0.6"
          />
          <path
            fill="none"
            d="M609.4,410.4l-0.3,0.4l-0.5,2.9 M608.2,415.1l0.4-1.4 M609.1,415.8c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3
	 M616.1,412.1l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1 M612.8,415c-1,0.3-2.2,0.7-3.7,1 M598.1,409.9v-0.3l2.3-4.5
	c3.2-5.9,5.8-10.2,7.7-12.5c2.3-3,4-4.8,4.8-5.4 M600.3,409.2l-1.1,1.1 M599.2,410.3l-0.1,0.1l-0.3,0.1h-0.4
	c-0.1-0.1-0.3-0.3-0.3-0.4v-0.3 M609.4,410.4c-2.5-1-4.7-2.2-6.9-3.7"
          />
          <path
            fill="none"
            d="M597.4,401.9c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4 M608.6,413.7c-2.9-1.1-5.6-2.6-8.3-4.5
	 M598.1,409.9c-0.5-1.5-1.1-2.8-1.4-3.9 M590.8,388.4c1,5.1,3,9.6,6.6,13.6 M612,354.3c2.5-0.7,5-1,7.7-1 M537.5,384.2h5.5
	 M585.2,382.9l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1 M573.4,400.8l-15-0.3 M558.4,400.5v-16.1
	 M543,384.2l15.4,0.3 M587.3,386.2c-0.7-0.8-1.4-1.9-2.1-3.3 M585.5,381.8l1.7-0.1 M620.1,350.2l0.1-1.6 M621.4,348.1
	c1.4,0.8,2.5,1.6,3.3,2.3 M596.2,308.2c-5.4,1.6-10.5,4-15.4,6.9 M598.1,272.7l-48.1,2.5 M550,275.2c18,9.8,33.4,20.8,46.2,33
	 M403.5,292.1c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6 M420.3,293.1h0.5
	c1.6,0,3,0.3,4.1,0.7c1.1,0.4,1.6,1,1.6,1.5c0,0.5-0.5,1.1-1.6,1.5c-1.1,0.4-2.5,0.7-4.1,0.7h-0.5 M420.3,297.5
	c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2 M420.3,297.5l-17.3-0.5 M403.5,292.1
	l16.8,1 M492.2,274.7l1.2,2.1c1.6,2.2,3.7,3.3,6.2,3.4 M516.3,270.8c-0.1,0.7-0.5,1.5-1,2.2c-0.5,1-1.2,1.8-2.2,2.6l-2.1,1.5
	c-1.8,1.1-3.6,1.9-5.8,2.5l-2.8,0.4l-1.2,0.1 M499.6,280.2h1.8"
          />
          <path
            fill="none"
            d="M518.6,277.1l0.5,1.2l3.6,4.7c1.8,2.6,1,4.8-2.3,6.6c-5.4,1.6-10.6,1.6-15.7,0c-3.9-1.6-5.5-4.8-5-9.6
	 M518.1,275.8l0.5,1.4 M516.3,266.5l6.5,6.7l-5.6-0.3 M517.1,273l1,2.8 M501.4,280.2c10.9,1.8,16.6,0.8,17.2-3 M470.2,210.7
	l70.3,62.6 M491.2,271.9c0.3,1,0.5,1.9,1,2.8 M516.3,270.8l0.8,2.2 M647.1,364.5c1.8,2.6,3.2,5.4,4.1,8.4 M663.9,285.4
	c-15-2.6-29.6-4.3-43.6-5.1 M389,381.4l148.4,2.8 M382.7,270.1l109.5,4.5 M397.1,268.1l-15-0.7 M381.2,210.6l16,57.5 M458.9,210.7
	c-27.1-1.8-56.5-2.8-88.3-3 M558.4,400.5l-217.2-1.7"
          />
          <path
            fill="none"
            d="M220.7,201.8c0.1,0.3,0.3,0.4,0.6,0.6l0.7,0.1l0.7-0.3c2.9-0.7,6.1-1.2,9.6-1.8c16.5-1.9,31.4-3.2,45-3.7
	c31.5-1.7,64.2-2.1,98.1-1.1c21.5,0.3,39.3,1.1,53.8,2.3c10.5,1,19.1,2.2,26,3.6l0.7,0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.5-0.6
	c0.1-0.3,0-0.6-0.1-0.7c-0.1-0.3-0.3-0.4-0.6-0.6c-2.3-0.7-5.1-1.4-8.1-1.9c-2.8-0.6-5.8-1-8.9-1.4c-10.3-1.6-24.6-2.9-42.8-3.9
	c19,0.4,36.2,1.2,51.6,2.6l18.7,1.9c15.7,2.6,27.5,7,35.4,13.5H459c-27.1-1.8-56.5-2.8-88.3-3c-27-0.1-55.6,0.1-85.8,1
	c-14.6,0-24.1,2.6-28.3,7.8L198,214l0.1-0.1L198,214l-11.8-0.6c0.8-2.6,3.4-4.5,7.8-5.5c6.3-2.1,9.5-4.7,9.4-7.7
	c6.3-1,13.2-1.9,20.5-2.8l19.8-1.9l7.4-0.6c-7.6,0.8-14.7,1.9-21.5,3.3c-3,0.6-5.8,1.2-8.1,1.9c-0.3,0.1-0.4,0.3-0.6,0.6
	C220.7,201.3,220.7,201.5,220.7,201.8L220.7,201.8z"
          />
          <path
            fill="none"
            d="M221.2,202.3c-0.3-0.1-0.4-0.3-0.6-0.6c-0.1-0.3,0-0.6,0.1-0.7c0.1-0.3,0.3-0.4,0.6-0.6
	c2.3-0.7,5.1-1.4,8.1-1.9c6.7-1.4,13.9-2.5,21.5-3.3l16-1.1c23.9-1.4,51.5-2.1,82.4-2.2c9.4,0,18.6,0,27.2,0.1l19.7,0.6
	c18.3,1,32.5,2.2,42.8,3.9c3.3,0.4,6.3,1,8.9,1.4c3,0.6,5.8,1.2,8.1,1.9c0.3,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.6,0.1,0.7
	c-0.1,0.3-0.3,0.4-0.5,0.6l-0.7,0.1l-0.7-0.3c-6.9-1.5-15.5-2.8-26-3.6c-14.4-1.4-32.5-2.1-53.8-2.3c-33.8-1-66.6-0.6-98.1,1.1
	c-13.5,0.6-28.5,1.6-45,3.7c-3.6,0.6-6.7,1.1-9.6,1.8l-0.7,0.3L221.2,202.3L221.2,202.3z M256.7,222.3l-2.8,12.1l3.9,27.9l-12.9-0.5
	l9.2-40.2c0.4-1.8,1.2-3.4,2.3-5c4.3-5.2,13.8-8,28.3-7.8c30.3-0.8,58.9-1.2,85.8-1l0.5,2.8h-10.3c-24.1,0-49.4,0.3-76.1,1.1
	C268,211.5,258.6,215.1,256.7,222.3L256.7,222.3z"
          />
          <path
            fill="none"
            d="M256.4,216.6c-1.2,1.4-1.9,3-2.3,5l-9.2,40.2l-73.7-3l4.1-7l3.6-1.1l15.8-27.5l-1.5-2.2l4.8-6.7L256.4,216.6
	L256.4,216.6z M245.3,264.5H244l-4.4,16.2l-0.4,26c13.1,3,24.8,9.2,35.1,18.7c7.4,6.9,12.9,14.4,16.6,22.7
	c4.3,10.2,6.7,20.6,7.6,31.5l90.5,1.6c2.3-25.2,2.5-50.1,0.4-74.7c-1-14-3.2-26.1-6.7-36.5L245.3,264.5L245.3,264.5z M278.8,291.5
	c0,0.5-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.7c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7c-1.9,0-3.6-0.7-5-2.1
	c-1-1-1.6-2.1-1.9-3.2h-1.2c-0.6,0.5-1.1,0.8-1.8,0.8h-0.3c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3
	c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1
	c0.8,0.8,1.4,1.6,1.6,2.6h0.5c1.7,0,3,0.3,4.1,0.7C278.3,290.2,278.8,290.8,278.8,291.5L278.8,291.5z"
          />
          <path
            fill="none"
            d="M277.1,289.8c1.1,0.4,1.6,1,1.6,1.5s-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.5L255,293h-1.2
	c-0.6,0.5-1.1,0.8-1.8,0.8h-0.3c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1
	c0.7,0,1.4,0.3,1.9,1l1.8,0.1l16.8,0.8h0.5C274.6,289.2,276,289.4,277.1,289.8L277.1,289.8z"
          />
          <path
            fill="none"
            d="M270.7,286.6c0.8,0.8,1.4,1.6,1.6,2.6l-16.8-0.8c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7
	C267.6,284.6,269.4,285.3,270.7,286.6L270.7,286.6z M272.4,293.6c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7
	c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2L272.4,293.6L272.4,293.6z M244.7,261.7l-0.7,2.8l-92.7-3.9l3.6-2.6l16.1,0.7
	 M161.5,208.2c-0.8-0.7-1-1.5-0.3-2.5l0.1-0.1c1-1.4,3.4-2.3,7.4-2.9l34.4-2.2c0,3-3,5.5-9.4,7.7c-4.4,1-7,2.8-7.8,5.5l-8.7-0.4
	C169.3,212.6,164.1,211,161.5,208.2L161.5,208.2z"
          />
          <path
            fill="none"
            d="M177.3,213.3l8.7,0.4c-10,11.8-20.4,26.6-31,44.3l-14-0.5C153.5,239.7,165.8,225,177.3,213.3L177.3,213.3z"
          />
          <path
            fill="none"
            d="M193,220.9l1.5,2.2l-15.8,27.5l-3.6,1.1l-4.1,7l-16.1-0.7c10.6-17.7,20.9-32.6,31-44.3l11.8,0.6L193,220.9
	L193,220.9z M154.9,258l-3.6,2.6l-10.7-0.4c-0.4,0-0.7-0.1-1-0.4l-0.1-0.1c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.7-0.4,1-0.4L154.9,258L154.9,258z M149.1,271.6h-4.8c-2.2,0.1-3.9,0.8-5.1,2.2l0.7-1.4c1.4-1.5,3.4-2.3,6.2-2.5h5.9
	c3.3,0,5.4,1.8,6.1,5.2c0.7,4.8,0.6,11.8-0.4,20.8c-0.1,10.2-1.9,17.5-5.2,21.6l-0.3,0.4c-1.5,1.4-4,2.1-7.7,2.3
	c-4,0.1-6.9-0.7-8.4-2.8c-0.6-0.7-1-1.5-1.2-2.3l-0.1-0.8c-0.3-2.9-0.4-6.7-0.1-11.6c-0.1,4.1,0,7.3,0.3,9.6c0.8,3.3,3.6,4.8,8,4.5
	c3-0.3,5.1-1,6.3-2.1c2.9-3.6,4.4-10.2,4.5-19.8c0.7-8.1,0.8-14.3,0.3-18.7C153.5,273.3,151.9,271.8,149.1,271.6L149.1,271.6z"
          />
          <path
            fill="none"
            d="M144.3,271.6h4.8c2.8,0,4.4,1.7,5.1,4.8c0.6,4.4,0.4,10.6-0.3,18.7c-0.1,9.5-1.6,16.1-4.5,19.8
	c-1.2,1.2-3.3,1.9-6.3,2.1c-4.4,0.3-7-1.4-8-4.5c-0.3-2.3-0.4-5.6-0.3-9.6l0.3-5.1c0.8-11.1,1.9-18.7,3.4-22.7l0.8-1.2
	C140.5,272.5,142.1,271.8,144.3,271.6L144.3,271.6z"
          />
          <path
            fill="none"
            d="M143.3,267.4l-1.2,1.5c-0.8,1.1-1.5,2.2-2.2,3.4c1.4-1.5,3.4-2.3,6.2-2.5h5.9c3.3,0,5.4,1.8,6.1,5.2
	c0.7,4.8,0.6,11.8-0.4,20.8c-0.1,10.2-1.9,17.5-5.2,21.6l-0.3,0.4c-1.5,1.4-4,2.1-7.7,2.3c-4,0.1-6.9-0.7-8.4-2.8
	c-0.6-0.7-1-1.5-1.2-2.3l-0.1-0.8v11.3h30.4l0.1-0.1c15-13.9,33.2-20.9,54.5-20.9c6.7,0,13.3,0.7,19.4,2.2l0.4-26l4.4-16.2
	l-92.7-3.9C148.3,262.3,145.5,264.6,143.3,267.4L143.3,267.4z M204,277c2.5-2.5,5.5-3.7,9.1-3.7s6.6,1.2,9.2,3.7
	c2.5,2.5,3.7,5.6,3.7,9.2s-1.2,6.6-3.7,9.1c-2.5,2.5-5.6,3.9-9.2,3.9s-6.6-1.2-9.1-3.9c-2.5-2.5-3.9-5.5-3.9-9.1
	S201.5,279.6,204,277L204,277z"
          />
          <path
            fill="none"
            d="M200.2,286.2c0-3.6,1.2-6.6,3.9-9.2c2.5-2.5,5.5-3.7,9.1-3.7s6.6,1.2,9.2,3.7c2.5,2.5,3.7,5.6,3.7,9.2
	s-1.2,6.6-3.7,9.1c-2.5,2.5-5.6,3.9-9.2,3.9s-6.6-1.2-9.1-3.9C201.5,292.8,200.2,289.8,200.2,286.2L200.2,286.2z M151.3,260.6
	c-3,1.6-5.8,4-8.1,6.7l-1.2,1.5c-0.8,1.1-1.5,2.2-2.2,3.4c-0.7,0.7-1.1,1.5-1.5,2.5v0.1c-1.5,4-2.6,11.6-3.4,22.7l-0.3,5.1
	c-0.3,4.8-0.3,8.7,0.1,11.6v11.3l-10.7,8.7c-5.5-23-5.5-42.8,0.3-59c0.6-1.1,1.1-2.1,1.8-3l2.2-3.2c3.2-4.3,7-7.4,11.4-9.5l0.1,0.1
	c0.3,0.3,0.6,0.4,1,0.4L151.3,260.6L151.3,260.6z M274.3,325.6c7.4,6.9,12.9,14.4,16.6,22.7c4.3,10.2,6.7,20.6,7.6,31.5l-18.4-0.4
	l18.4,0.4l90.5,1.6l148.4,2.8l5.5,0.1l15.4,0.3v16.1l-217.2-1.8l-61.1-0.4c0.1-4.7,0-9.6-0.1-14.9c0-1.4-0.1-2.8-0.3-4
	c-0.3-2.9-0.7-5.9-1.4-8.7c-0.5-2.8-1.4-5.4-2.3-8c-0.8-2.3-1.8-4.7-2.9-6.9c-2.8-5.5-6.3-10.6-10.9-15.4
	c-7-7.4-14.7-12.7-23.4-15.8l0.3-18C252.3,309.7,264,316.1,274.3,325.6L274.3,325.6z"
          />
          <path
            fill="none"
            d="M273.2,355.8c1.1,2.2,2.1,4.5,2.9,6.9c1,2.6,1.6,5.2,2.3,8c0.7,2.8,1.1,5.6,1.4,8.7c0.1,1.4,0.3,2.8,0.3,4
	c0.3,5.4,0.3,10.3,0.1,14.9v1.2l-0.7-0.1c-0.5,0-1.1,0.3-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6v1.9l-11.4-0.1c2.8-6.3,4.3-13.2,4.3-20.6
	c0-12.4-3.9-23.2-11.7-32.3c-1-1.1-2.1-2.3-3-3.3c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1
	c-2.3,1.6-4.5,3.6-6.6,5.6c-2.6,2.6-4.8,5.2-6.6,8c-5.5,8.1-8.1,17.3-8.1,27.6c0,5.2,0.7,10.2,2.1,14.7l-12.5-0.8v-12.5
	c0.3-11.7,3-22.1,8.5-31.5l0.6-0.8c2.5-4.1,5.4-8,8.8-11.6c3-3.3,6.3-6.1,9.6-8.5c9.8-7,20.8-10.5,33.3-10.5
	c6.9,0,13.5,1.1,19.5,3.3c8.7,3,16.5,8.4,23.4,15.8C266.7,345.2,270.4,350.3,273.2,355.8L273.2,355.8z"
          />
          <path
            fill="none"
            d="M280.2,399.7v-1.2l61.1,0.4c-1.8,2.1-5.5,3-11,3.3l-48.4-0.1v-0.1c0-0.5-0.3-1.1-0.7-1.6
	C280.8,400,280.5,399.7,280.2,399.7z"
          />
          <path
            fill="none"
            d="M277.3,401.8c0-0.5,0.3-1.1,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7l0.7,0.1c0.3,0.1,0.7,0.3,1,0.5
	c0.4,0.4,0.7,1,0.7,1.6v5.6c0,0.6-0.3,1.1-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7c-0.5,0-1.1-0.3-1.7-0.7c-0.4-0.4-0.7-1-0.7-1.6V401.8
	L277.3,401.8z M389,381.4l148.4,2.8c2.6-15,4.4-34.3,5.4-57.6l0.1-1v-0.3c-0.1-28.3-4.4-44.7-13.1-49.1h-0.5l-11.1-0.4l0.5,1.4
	l0.5,1.2l3.6,4.7c1.8,2.6,1,4.8-2.3,6.6c-5.4,1.6-10.6,1.6-15.7,0c-3.9-1.6-5.5-4.8-5-9.6c-2.5,0-4.4-1.2-6.2-3.4
	c-0.5-0.7-1-1.4-1.2-2.1L383,270c3.6,10.3,5.9,22.4,6.7,36.5C391.6,331.3,391.5,356.2,389,381.4L389,381.4z M391.9,294.5
	c0-1,0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7
	c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6h0.5c1.6,0,3,0.3,4.1,0.7s1.6,1,1.6,1.5s-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.5
	c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2h-1.2c-0.5,0.5-1.1,0.8-1.8,0.8h-0.1
	c-2.1,0-3.9-0.4-5.4-1C392.6,296.1,391.9,295.3,391.9,294.5L391.9,294.5z"
          />
          <path
            fill="none"
            d="M394.3,296.8c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1
	l16.8,0.8h0.5c1.6,0,3,0.3,4.1,0.7c1.1,0.4,1.6,1,1.6,1.5c0,0.5-0.5,1.1-1.6,1.5c-1.1,0.4-2.5,0.7-4.1,0.7h-0.5l-17.3-0.5h-1.2
	c-0.5,0.5-1.1,0.8-1.8,0.8h-0.1C397.6,297.6,395.8,297.4,394.3,296.8L394.3,296.8z"
          />
          <path
            fill="none"
            d="M404.7,290.5c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6l-16.8-0.8
	C403.7,291.6,404.2,291,404.7,290.5L404.7,290.5z M420.3,297.5c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7
	c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2L420.3,297.5L420.3,297.5z M186.1,331.9c-3.4,2.5-6.6,5.2-9.6,8.5
	c-3.4,3.7-6.3,7.6-8.8,11.6h-20.8c1.8-4.5,4-8.8,6.6-12.8c2.6-4,5.8-7.8,9.5-11.4l2.2-2.1l0.1-0.1c15-13.9,33.2-20.9,54.5-20.9
	c6.7,0,13.3,0.7,19.4,2.2l-0.3,18c-6.1-2.2-12.7-3.3-19.5-3.3C206.9,321.4,195.9,324.9,186.1,331.9L186.1,331.9z"
          />
          <path
            fill="none"
            d="M183.9,347.2c-2.6,2.6-4.8,5.2-6.6,8.1c-5.5,8.1-8.1,17.3-8.1,27.6c0,5.2,0.7,10.2,2.1,14.7
	c1.4,4.5,3.4,8.9,6.1,12.9c1.9,2.8,4.1,5.5,6.6,8c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9
	c6.9-2.5,13.1-6.5,18.6-12c1.1-1.1,2.2-2.2,3-3.3c3.2-3.7,5.6-7.6,7.4-11.7c2.8-6.3,4.3-13.2,4.3-20.6c0-12.4-3.9-23.2-11.7-32.3
	c-1-1.1-2.1-2.3-3-3.3c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1s-20.6,3-29.2,9.1C188.2,343.2,186,345.1,183.9,347.2
	L183.9,347.2z M232.3,343.4h-0.6H232.3z M255,382.9c0,10-3.6,18.6-10.7,25.7s-15.7,10.6-25.7,10.6c-10,0-18.6-3.6-25.7-10.6
	c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6S255,372.9,255,382.9
	L255,382.9z"
          />
          <path
            fill="none"
            d="M244.3,357.2c-7.2-7.2-15.7-10.6-25.7-10.6c-10,0-18.6,3.6-25.7,10.6c-7.2,7.2-10.7,15.7-10.7,25.7
	c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6c10,0,18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7
	C255,372.9,251.5,364.2,244.3,357.2L244.3,357.2z M220.1,348l0.1,0.1c1.4,0.8,2.5,1.6,3.3,2.3c7,1,13.1,4,18.3,9.2l1.2,1.2
	c1,0.3,2.2,0.8,3.6,1.5h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3l-1.1,1c1.8,2.6,3.2,5.4,4.1,8.4l1.5-0.3h0.4
	c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.1c-0.4,1.5-0.7,2.8-1.1,3.7c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6
	c0,1-0.1,2.2-0.3,3.6v0.3c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3l-1.4-0.8c-0.8,1-1.6,1.9-2.6,2.9
	c-1.4,1.4-2.8,2.5-4.1,3.6l0.7,1.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3c-1.5,0.1-2.8,0.1-3.7,0.1
	c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7c-1,0.3-2.2,0.7-3.7,1c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l0.3-1.5
	c-2.9-1.1-5.6-2.6-8.3-4.5l-1.1,1.1h-0.1v0.1l-0.3,0.1H197l-0.3-0.4v-0.3c-0.6-1.5-1.1-2.8-1.4-3.9l-0.1-0.1
	c-5.6-5.6-8.8-12.2-9.5-19.8c-0.7-0.8-1.4-1.9-2.1-3.3l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1l1.7-0.1
	c0.1-3.2,0.7-6.3,1.6-9.2l-1.4-0.7h-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1c1.2-1,2.2-1.8,3.2-2.3
	c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9c0.6-0.8,1.5-1.9,2.5-3l0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.3s0.3,0,0.4,0.1l0.1,0.3l0.6,1.5
	c2.9-0.8,5.9-1.2,9.1-1.2h0.3l0.3-1.6v-0.1l0.1-0.3l0.4-0.3L220.1,348L220.1,348z"
          />
          <path
            fill="none"
            d="M219.6,347.9l0.6,0.1l0.1,0.1l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6c-0.1,3.9-0.4,6.3-0.8,7.2l-0.3,0.5
	c-0.4,0.7-1,1.2-1.6,1.6l-0.4,0.1c-0.8,0.4-1.6,0.4-2.5,0.3c-1-0.1-1.8-0.6-2.5-1.1l-0.4-0.4c-0.6-0.8-1.5-3-2.6-6.7
	c-1-3-1.8-7.8-2.6-14.6l-0.6-5.1v-0.3l0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.3s0.3,0,0.4,0.1l0.1,0.3v0.1l0.6,4.8
	c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.6,1.1,1,1.8,1.1l0.4,0.1c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8
	c0.4-0.8,0.6-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.7-4.8v-0.1l0.1-0.3L219.6,347.9L219.6,347.9z"
          />
          <path
            fill="none"
            d="M220.4,348.4l-0.1-0.3c1.4,0.8,2.5,1.6,3.3,2.3c1.4,1.1,1.9,2.1,1.9,2.9v0.8c0.1,5.6-0.3,10.6-1.1,14.7
	c-0.6,2.6-1.5,4.8-2.9,6.5c0.4-1,0.7-3.3,0.8-7.2c0.1-3-0.3-8-1.2-14.6L220.4,348.4L220.4,348.4z"
          />
          <path
            fill="none"
            d="M219.1,348.5l-0.3,1.6l-0.4,2.6v0.5l1.4,11.4c0,2.3-0.6,3.4-1.8,3.4l-3.2,0.5c-1.1,0.3-2.1-0.7-2.6-2.9
	l-1.6-11.6v-0.4l-1.1-2.6l-0.6-1.5v0.1l0.6,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1
	c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.6-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7L219.1,348.5L219.1,348.5z M215.8,371.4
	c0.6-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1c0.4,0.5,0.6,1.2,0.4,1.9s-0.4,1.2-1.1,1.8c-0.6,0.4-1.2,0.5-1.9,0.4
	c-0.7-0.1-1.4-0.4-1.8-1.1c-0.4-0.5-0.6-1.2-0.6-1.9C214.9,372.5,215.1,371.8,215.8,371.4L215.8,371.4z"
          />
          <path
            fill="none"
            d="M214.7,373.2c-0.1,0.7,0,1.4,0.6,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.6-0.4,1-1,1.1-1.8
	c0.1-0.7,0-1.4-0.4-1.9c-0.4-0.5-1-1-1.8-1.1c-0.7-0.1-1.4,0-1.9,0.4C215.1,371.8,214.9,372.5,214.7,373.2L214.7,373.2z
	 M216.2,372.1c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3
	c-0.6-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C215.6,372.8,215.7,372.3,216.2,372.1L216.2,372.1z"
          />
          <path
            fill="none"
            d="M215.4,373.3c0.1-0.5,0.3-1,0.8-1.2c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4
	c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3c-0.6-0.1-1-0.3-1.2-0.7C215.4,374.3,215.3,373.9,215.4,373.3L215.4,373.3z
	 M207.9,349.9v0.3l0.6,5.1c0.8,6.7,1.6,11.6,2.6,14.6c1.1,3.7,2.1,6.1,2.6,6.7c-1.6-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14
	l-0.1-0.8c-0.3-0.8,0.1-1.9,1.2-3.4C205.9,352,206.8,351,207.9,349.9L207.9,349.9z M210.7,354.3v-0.4l-1.1-2.6
	c2.9-0.8,5.9-1.2,9.1-1.2h0.3l-0.4,2.6v0.5C215.8,353.4,213.2,353.8,210.7,354.3L210.7,354.3z"
          />
          <path
            fill="none"
            d="M218.2,368.3l-3.2,0.5c-1.1,0.3-2.1-0.7-2.6-2.9l-1.6-11.6c2.5-0.7,4.9-1,7.7-1l1.4,11.4
	C220,367.1,219.4,368.3,218.2,368.3L218.2,368.3z M225.5,354.2v-0.8c0-0.8-0.7-1.8-1.9-2.9c7,1,13.1,4,18.3,9.2l1.2,1.2
	c-1.8-0.6-2.9-0.6-3.6-0.1l-0.7,0.4C234.8,357.6,230.4,355.3,225.5,354.2L225.5,354.2z"
          />
          <path
            fill="none"
            d="M224.4,368.9c-0.6,2.6-1.5,4.8-2.9,6.5l-0.3,0.5c-0.4,0.7-1,1.2-1.6,1.6l-0.4,0.1c-0.8,0.4-1.7,0.4-2.5,0.3
	c-1-0.1-1.8-0.5-2.5-1.1l-0.4-0.4c-1.6-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14c-2.3,1.4-4.5,3-6.5,5c-1.6,1.6-3.2,3.6-4.4,5.4
	c5.5,1.6,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7l0.4,0.4c0.6,0.5,0.8,1.2,1,2.1v0.4c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1
	l-0.6,0.3c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3c1,5.1,3,9.6,6.6,13.6c3.3-4.7,6.5-8.4,9.5-11.3
	c2.1-1.8,4.1-2.9,6.1-3.4c0.1-0.1,0.4-0.1,0.6-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.6,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3
	v0.5c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2c-3.4-4.5-6.1-8.8-7.8-12.7
	c-1.1-2.5-1.5-4.8-1.5-6.9l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1
	c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3c-5.4,1.9-10.2,3-14.4,3.6c-2.6,0.3-5,0-7-0.8
	l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.6-0.7-1-1.4-1.1-2.3s-0.1-1.9,0.3-2.8l0.3-0.6c0.7-2.1,1.9-4,3.9-5.9
	c3-3,6.9-5.9,11.8-8.9c-3.9-3.7-8.4-6.1-13.3-7.2C225.6,359.8,225.2,364.8,224.4,368.9L224.4,368.9z M216.1,380.3
	c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C215,381.8,215.4,381,216.1,380.3L216.1,380.3z"
          />
          <path
            fill="none"
            d="M215,382.9c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6
	s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1C215.4,384.7,215,383.9,215,382.9L215,382.9z M228.8,371.6
	c2.5-1.8,6.9-4.1,13.1-7l4.5-2.2h0.6c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3h-0.1l-4.4,1.9c-6.2,2.9-10.6,5.2-13.1,7
	c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.6-0.6,1.2-0.6,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4
	c2.9-0.8,7.6-2.8,13.6-5.8l4.3-2.2h0.4c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.1l-0.1,0.1l-4.5,2.2
	c-6.2,3-10.7,5-13.6,5.8c-3.7,1.1-6.2,1.5-7.2,1.5l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.6-0.7-1-1.4-1.1-2.3
	s-0.1-1.9,0.3-2.8l0.3-0.5C223.7,375.4,225.6,373.8,228.8,371.6L228.8,371.6z"
          />
          <path
            fill="none"
            d="M228.8,371.6c-3.2,2.2-5.1,3.7-5.6,4.5c0.7-2.1,1.9-4,3.9-5.9c3-3,6.9-5.9,11.8-8.9l0.7-0.4
	c0.7-0.5,1.8-0.4,3.6,0.1c1,0.3,2.2,0.8,3.6,1.5h-0.3l-4.5,2.2C235.6,367.5,231.2,369.9,228.8,371.6L228.8,371.6z"
          />
          <path
            fill="none"
            d="M230.8,375.1c-0.7-1,0-2.1,2.1-3.3l10.5-5.1l0.4-0.1l2.2-1.9l1.1-1h-0.1l-4.4,1.9c-6.2,2.9-10.6,5.2-13.1,7
	c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.6,1.2-0.6,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4
	c3-0.8,7.6-2.8,13.6-5.8l4.3-2.2l-1.5,0.3l-2.8,0.4l-0.4,0.3l-10.6,5c-2.2,0.7-3.4,0.5-3.9-0.5L230.8,375.1L230.8,375.1z
	 M228.5,376.6c0.6,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.6-1.9
	c0.4-0.5,1-1,1.6-1.1S227.9,376.2,228.5,376.6L228.5,376.6z"
          />
          <path
            fill="none"
            d="M226.6,376.2c-0.7,0.1-1.2,0.4-1.6,1.1c-0.4,0.5-0.6,1.2-0.6,1.9c0.1,0.7,0.4,1.4,1.1,1.8
	c0.6,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1c0.4-0.5,0.6-1.2,0.4-1.9s-0.4-1.2-1.1-1.8C227.9,376.2,227.3,376.1,226.6,376.2
	L226.6,376.2z M228.9,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3s-0.7-0.7-0.8-1.2s0-1,0.3-1.4
	c0.3-0.4,0.7-0.7,1.2-0.8c0.6-0.1,1,0,1.4,0.4C228.5,377.6,228.8,378,228.9,378.4L228.9,378.4z"
          />
          <path
            fill="none"
            d="M228.1,377.2c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3
	c-0.4-0.3-0.7-0.7-0.8-1.2s0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8C227.3,376.7,227.7,376.9,228.1,377.2L228.1,377.2z M232.2,378
	l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1c1.5,2.2,2.6,4.5,3.4,7l-10.6,5C233.9,379.4,232.6,379.1,232.2,378L232.2,378z M225.7,385
	l0.7,0.1c1,0.3,3,1.5,6.1,3.9c2.5,1.8,6.1,5.2,10.7,10.3l3.3,3.7l0.1,0.1v0.3c0,0.1-0.1,0.3-0.3,0.4s-0.3,0.1-0.4,0.1h-0.3l-3.3-3.6
	c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3c-0.6,0.3-1,0.7-1.2,1.2
	c-0.4,0.8-0.6,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1
	h-0.3l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4
	c0.4-0.8,1.1-1.4,1.8-1.8C224,385.1,224.8,384.9,225.7,385L225.7,385z"
          />
          <path
            fill="none"
            d="M223.5,386.6c-0.6,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.6,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1
	l3.3,3.4l-0.7-1.4l-1.2-2.5l-0.4-0.4l-8-8.5c-1.4-1.9-1.6-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4l0.3,0.3l2.5,1.5
	l1.4,0.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1L223.5,386.6L223.5,386.6z
	 M227.4,388.4c0.3,0.7,0.4,1.4,0.1,2.1c-0.3,0.7-0.7,1.2-1.4,1.5c-0.7,0.3-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4s-0.4-1.4-0.1-2.1
	c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1C226.6,387.3,227.1,387.7,227.4,388.4L227.4,388.4z"
          />
          <path
            fill="none"
            d="M225.9,387.1c-0.7-0.3-1.4-0.1-2.1,0.1c-0.7,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1
	c0.4,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1c0.7-0.3,1.1-0.8,1.4-1.5c0.3-0.7,0.1-1.4-0.1-2.1C227.1,387.7,226.6,387.3,225.9,387.1
	L225.9,387.1z M225.6,387.7c0.6,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.6,0.8-1,1.1c-0.6,0.3-1,0.3-1.5,0.1
	s-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1C224.6,387.6,225.2,387.6,225.6,387.7L225.6,387.7z"
          />
          <path
            fill="none"
            d="M224.2,387.9c0.4-0.3,1-0.3,1.4-0.1c0.6,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.5-0.6,0.8-1,1.1
	c-0.6,0.3-1,0.3-1.5,0.1s-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4C223.4,388.4,223.7,388.2,224.2,387.9L224.2,387.9z M226.3,385.1
	c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5l0.6,0.4c0.7,0.5,1,1.8,1,3.7c0,1-0.1,2.2-0.3,3.6l-0.1-0.1l-3.3-3.7
	c-4.7-5.1-8.3-8.5-10.7-10.3C229.3,386.6,227.3,385.4,226.3,385.1L226.3,385.1z M233.6,384c-2.6,0.3-5,0-7-0.8
	c1,0.1,3.3-0.4,7.2-1.5c2.9-0.8,7.4-2.8,13.6-5.8l4.5-2.2l0.1-0.1c-0.4,1.5-0.7,2.8-1.1,3.7c-0.7,1.6-1.4,2.6-2.2,2.9l-0.7,0.3
	C242.7,382.4,237.8,383.5,233.6,384L233.6,384z M205.8,374.8c3.6,1.4,5.8,2.3,6.6,3l0.4,0.4c0.6,0.6,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1l-0.6,0.3c-1,0.4-3.4,0.6-7.3,0.4c-3,0-7.8-0.7-14.6-2.1l-5.1-1l-0.3-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4s0.1-0.3,0.3-0.4l0.3-0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.6-1.4
	l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2c-0.7-0.5-2.8-1.5-6.2-2.8c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8h-0.1
	l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4s0.1-0.3,0.3-0.4h0.4l5.1,0.8C198,372.6,202.8,373.7,205.8,374.8L205.8,374.8z"
          />
          <path
            fill="none"
            d="M212.4,377.7c-0.8-0.7-3-1.6-6.6-3c-3-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3c1.2-1,2.2-1.8,3.2-2.3
	c1.5-1,2.6-1.2,3.4-1l0.8,0.3c5.5,1.6,10,3.6,13.8,5.6C209.5,374.4,211.2,376.1,212.4,377.7L212.4,377.7z M215.1,386.6
	c0.8,0.1,1.6,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3v0.5c0,1-0.6,3.3-1.8,7c-1,2.9-3.2,7.4-6.5,13.3l-2.5,4.4l-0.1,0.1
	c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9
	l-0.3-0.3c-0.4-0.4-1-0.7-1.6-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4H198v0.1
	l-0.3,0.1h-0.4l-0.3-0.4v-0.5l2.3-4.5c3.2-5.9,5.8-10.2,7.7-12.5c2.3-3,4-4.8,4.8-5.4c0.1-0.1,0.4-0.1,0.6-0.3
	c0.7-0.3,1.5-0.4,2.3-0.3L215.1,386.6L215.1,386.6z"
          />
          <path
            fill="none"
            d="M212.4,382.7c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2c-0.7-0.6-2.8-1.5-6.2-2.8
	c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8l1.4,0.7l2.5,1.2l0.6,0.1l11.4,2.2c2.2,0.7,3.2,1.6,2.8,2.8l-0.6,3.2c-0.1,1.1-1.2,1.6-3.6,1.5
	l-11.4-1.9l-0.6-0.1l-2.8,0.3l-1.7,0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.6-1.4L212.4,382.7
	L212.4,382.7z M206.9,382c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1c0.7,0.3,1.1,0.8,1.4,1.5
	c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4c-0.7,0.3-1.4,0.1-2.1-0.1C207.6,383.2,207,382.7,206.9,382L206.9,382z"
          />
          <path
            fill="none"
            d="M208.1,383.6c0.7,0.3,1.4,0.4,2.1,0.1s1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1s-0.7-1.2-1.4-1.5
	c-0.7-0.3-1.4-0.4-2.1-0.1c-0.7,0.3-1.2,0.7-1.5,1.4c-0.3,0.7-0.4,1.4-0.1,2.1C207,382.7,207.6,383.2,208.1,383.6L208.1,383.6z
	 M207.6,381.8c-0.1-0.5-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1c0.6-0.1,1-0.1,1.5,0.1c0.4,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.5
	c-0.3,0.5-0.6,0.8-1.1,1l-1.5-0.1C208,382.7,207.7,382.2,207.6,381.8L207.6,381.8z"
          />
          <path
            fill="none"
            d="M208.5,382.9c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.6-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1c0.6-0.1,1-0.1,1.5,0.1
	c0.4,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.5c-0.3,0.5-0.6,0.8-1.1,1L208.5,382.9L208.5,382.9z M212.4,388.3c-0.8,0.4-2.3,2.2-4.5,5.1
	c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4l1.1-1.1l1.9-1.9l0.3-0.5l5.6-10c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9
	l-5.5,10.3l-0.1,0.4l-0.7,2.9l-0.3,1.5l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3
	c-0.4-0.4-1-0.7-1.6-0.8C214.1,387.7,213.2,387.7,212.4,388.3L212.4,388.3z M212.3,389.3c0.6-0.5,1.1-0.8,1.9-0.8
	c0.8,0,1.4,0.3,1.9,0.8c0.6,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9c-0.6,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8
	s-0.8-1.1-0.8-1.9C211.6,390.4,211.8,389.7,212.3,389.3L212.3,389.3z"
          />
          <path
            fill="none"
            d="M211.6,391c0,0.8,0.3,1.4,0.8,1.9c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8s0.8-1.2,0.8-1.9
	s-0.3-1.4-0.8-1.9c-0.6-0.5-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8C211.8,389.7,211.6,390.4,211.6,391z M212.3,391
	c0-0.5,0.1-1,0.6-1.4c0.4-0.4,0.8-0.5,1.4-0.5s1,0.1,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6
	s-1-0.1-1.4-0.5C212.5,392,212.3,391.6,212.3,391L212.3,391z"
          />
          <path
            fill="none"
            d="M212.8,392.4c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.5,0.1-1,0.6-1.4c0.4-0.4,0.8-0.5,1.4-0.5s1,0.1,1.4,0.5
	c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6S213.2,392.8,212.8,392.4L212.8,392.4z M243.4,366.7l0.4-0.1
	l2.2-1.9c1.8,2.6,3.2,5.4,4.1,8.4l-2.8,0.4l-0.4,0.3C246,371.1,244.7,368.8,243.4,366.7L243.4,366.7z M248,380.5l0.7-0.3
	c0.8-0.3,1.5-1.2,2.2-2.9c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6c0-1.9-0.3-3.2-1-3.7l-0.6-0.4c1.8-3.9,2.8-8,2.8-12.5
	L248,380.5L248,380.5z M239.5,403.7c0.8-0.8,1.5-1.5,2.2-2.3l0.3,0.3l2.5,1.5c-0.8,1-1.6,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6
	l-1.2-2.5l-0.4-0.4C237.3,405.8,238.4,404.8,239.5,403.7L239.5,403.7z"
          />
          <path
            fill="none"
            d="M241.7,401.4c-0.7,0.8-1.4,1.6-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3l-8-8.5c-1.4-1.9-1.6-3.2-0.7-3.9l2.2-2.2
	c0.7-1,2.1-0.7,3.9,0.8L241.7,401.4L241.7,401.4z M237.4,411.7l0.3,0.1c-1.5,0.1-2.8,0.1-3.7,0.1c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6
	c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9c0.3,1,1.5,3,3.6,6.3c1.8,2.6,4.9,6.3,9.6,11.1L237.4,411.7L237.4,411.7z
	 M206.9,396.7c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3c-2.5-1-4.7-2.2-6.9-3.7L206.9,396.7L206.9,396.7z
	 M216.9,397.9c1.2-3.7,1.9-6.1,1.8-7c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1
	c-1,0.3-2.2,0.7-3.7,1l0.1-0.1l2.5-4.4C213.8,405.2,216,400.8,216.9,397.9L216.9,397.9z"
          />
          <path
            fill="none"
            d="M230.4,410.7c0.4,0.7,1.6,1.1,3.6,1.2c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7c1.8-0.5,2.9-1.2,3-2.1
	l0.3-0.7l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2L230.4,410.7L230.4,410.7z M208.1,410.4l-0.1,0.4l-0.7,2.9c-2.9-1.1-5.6-2.6-8.3-4.5
	l1.9-1.9l0.3-0.6C203.5,408.4,205.7,409.6,208.1,410.4L208.1,410.4z M211.8,387.2c-0.8,0.5-2.5,2.3-4.8,5.4
	c-1.9,2.5-4.4,6.6-7.7,12.5l-2.3,4.5v0.3c-0.6-1.5-1.1-2.8-1.4-3.9c-0.4-1.6-0.4-2.9,0.1-3.4l0.6-0.7c3.3-4.7,6.5-8.4,9.5-11.3
	C207.7,389,209.8,387.7,211.8,387.2L211.8,387.2z M204.1,356.2l0.1,0.8c-2.3,1.4-4.5,3-6.5,5c-1.6,1.7-3.2,3.6-4.4,5.4l-0.8-0.3
	c-0.7-0.3-1.9,0-3.4,1c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9C204.3,354.3,203.9,355.6,204.1,356.2L204.1,356.2z M190,373.7
	l0.6,0.1c-0.8,2.5-1.2,5-1.4,7.7l-0.6-0.1l-2.8,0.3c0.1-3.2,0.7-6.3,1.6-9.2L190,373.7L190,373.7z"
          />
          <path
            fill="none"
            d="M201.9,376.1c2.2,0.7,3.2,1.6,2.8,2.8l-0.6,3.2c-0.1,1.1-1.2,1.6-3.6,1.5l-11.4-1.9c0.1-2.8,0.6-5.2,1.4-7.7
	L201.9,376.1L201.9,376.1z M183.9,382.9l0.3,0.1l5.1,1c6.7,1.2,11.6,1.9,14.6,2.1c3.9,0.1,6.3,0,7.3-0.4c-1.8,1.2-4,2.1-6.6,2.6
	c-4.1,0.5-9.1,0.7-14.9,0.3h-0.8c-0.8,0-1.8-0.7-2.9-2.1C185.4,385.4,184.6,384.3,183.9,382.9L183.9,382.9z"
          />
          <path
            fill="none"
            d="M188.9,388.3h0.8c1,5.1,3,9.6,6.6,13.6l-0.6,0.7c-0.6,0.6-0.6,1.8-0.1,3.4l-0.1-0.1c-5.6-5.6-8.8-12.2-9.5-19.8
	C187.1,387.6,188,388.3,188.9,388.3L188.9,388.3z M165.2,325.7l-2.2,2.1c-3.6,3.6-6.7,7.4-9.5,11.4c-2.6,4.1-4.9,8.4-6.6,12.8
	c-1.4,3.7-2.5,7.6-3.3,11.6c-1.8,6.1-5.1,9.2-9.8,9.5h-12.5c-2.3,0-3.7-1.4-4.3-3.9c-2.5-5.9-4.8-7.8-6.9-5.9l-1.4,5.2
	c-1-3.9-1.5-9.8-1.4-17.6c0-2.2-1-3.6-2.9-4.1l1.1-5.1c0.4-1.4,1.8-2.1,4-1.9c6.2,0.1,11.1-1.6,14.6-5.4l10.7-8.7L165.2,325.7
	L165.2,325.7z"
          />
          <path
            fill="none"
            d="M143.5,363.7c0.8-4,1.9-7.8,3.3-11.6h20.8l-0.6,0.8c-5.4,9.4-8.3,19.8-8.5,31.5v9.1c-16.6-1-28.6-3.6-35.8-7.7
	c-4.1-2.3-6.7-5.2-7.6-8.7c-0.1-0.5-0.3-1.2-0.4-1.9c-1.9-5.6-4-7.8-6.3-6.7l1.4-5.2c2.2-1.9,4.4,0,6.9,5.9c0.6,2.5,2.1,3.9,4.3,3.9
	h12.5C138.4,372.9,141.7,369.7,143.5,363.7L143.5,363.7z"
          />
          <path
            fill="none"
            d="M158.6,393.5v3.4l-7.4-0.4l-44-8.4c-2.6-0.5-4-1.4-3.9-2.3c0.4-1.5,2.3-1.9,6.2-1.5l1.4,0.1l2.9,0.4l9.2,1.2
	C130,389.9,142,392.4,158.6,393.5L158.6,393.5z M138.4,274.8c0.4-1,0.8-1.8,1.5-2.5l-0.7,1.4l-0.8,1.2L138.4,274.8L138.4,274.8z"
          />
          <path
            fill="none"
            d="M107,374.1c-1.8-1.4-1.2-3.3,1.5-5.5c2.3-1.1,4.4,1.1,6.3,6.7c0,0.7,0.1,1.2,0.4,1.9c-1.2,0.8-1.8,3.3-1.5,7.6
	l-2.9-0.4C110.2,378.4,109,375,107,374.1L107,374.1z"
          />
          <path
            fill="none"
            d="M122.8,385.8l-9.2-1.2c-0.1-4.3,0.4-6.7,1.5-7.6C116.2,380.6,118.7,383.5,122.8,385.8z M674.1,325.6
	c1.1,1,2.1,1.9,3,2.9c6.9,7.1,11.8,15,15,23.7c1.2,3.4,2.2,7,2.9,10.6h-16.6c-0.8-2.3-1.9-4.7-3-6.9c-2.9-5.5-6.5-10.6-11-15.4
	c-12.1-12.7-26.7-19-43.9-19c-12.7,0-24.1,3.4-34,10.5c-3.4,2.5-6.7,5.2-9.9,8.5c-3.7,3.9-6.9,8.1-9.5,12.5
	c-5.5,9.4-8.4,19.8-8.7,31.5l-15.4-0.3c0.5-5.6,1.4-12,2.5-19c1.7-9.5,5-18.2,10-26c2.6-4,5.6-7.8,9.2-11.4l2.3-2.2
	c4.3-4,8.8-7.6,13.6-10.5s10-5.2,15.4-6.9c7.6-2.5,15.7-3.6,24.3-3.6C641.5,304.7,659.3,311.5,674.1,325.6L674.1,325.6z"
          />
          <path
            fill="none"
            d="M656.6,347.2c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1
	c-2.3,1.6-4.5,3.6-6.6,5.6c-2.6,2.6-4.8,5.2-6.6,8c-5.5,8.1-8.1,17.3-8.1,27.6c0,6.3,1.1,12.4,3,17.9l-15-0.1v-16.1
	c0.3-11.7,3.2-22.1,8.7-31.5c2.6-4.4,5.8-8.5,9.5-12.5c3.2-3.3,6.5-6.1,9.9-8.5c9.9-7,21.3-10.5,34-10.5c17.2,0,31.8,6.3,43.9,19
	c4.5,4.8,8.3,9.9,11,15.4c1.1,2.2,2.2,4.5,3,6.9c2.3,6.5,3.7,13.3,4,20.8c0.3,6.7,0.3,12.9,0,18.7l-14.9,0.4
	c2.5-6.1,3.9-12.5,3.9-19.7c0-12.4-3.9-23.2-11.7-32.3C658.8,349.4,657.7,348.3,656.6,347.2L656.6,347.2z"
          />
          <path
            fill="none"
            d="M650,341.5c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6c-2.6,2.5-4.8,5.2-6.6,8
	c-5.5,8.1-8.1,17.3-8.1,27.6c0,6.3,1.1,12.4,3,17.9c1.2,3.4,3,6.7,5.1,9.9c1.9,2.8,4.1,5.5,6.6,8c5.5,5.5,11.7,9.5,18.6,12
	c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c1.1-1.1,2.2-2.2,3-3.3c3.3-4,6.1-8.3,7.8-12.8
	c2.5-6.1,3.9-12.5,3.9-19.7c0-12.4-3.9-23.2-11.7-32.3c-1-1.1-2.1-2.3-3-3.3C654.5,345.1,652.3,343.2,650,341.5L650,341.5z
	 M619.9,419.2c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6
	c10,0,18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7c0,10-3.6,18.6-10.7,25.7C638.6,415.7,629.9,419.2,619.9,419.2L619.9,419.2z"
          />
          <path
            fill="none"
            d="M645.6,408.7c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.7-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6
	c-10,0-18.6,3.6-25.7,10.6c-7.2,7.2-10.7,15.7-10.7,25.7c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6
	C629.9,419.2,638.6,415.7,645.6,408.7L645.6,408.7z M653,372.8c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1
	c-0.4,1.5-0.7,2.8-1.1,3.7c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6c0,1-0.1,2.2-0.3,3.6v0.3c0,0.1-0.1,0.3-0.3,0.4
	c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3l-1.4-0.8c-0.8,1-1.7,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6l0.7,1.4v0.4c0,0.1,0,0.3-0.1,0.4
	c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3c-1.5,0.1-2.8,0.1-3.7,0.1c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7c-1,0.3-2.2,0.7-3.7,1
	c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l0.3-1.5c-2.9-1.1-5.6-2.6-8.3-4.5l-1.1,1.1h-0.1v0.1l-0.3,0.1H598
	c-0.1-0.1-0.3-0.3-0.3-0.4V410c-0.5-1.5-1.1-2.8-1.4-3.9l-0.1-0.1c-5.6-5.6-8.8-12.2-9.5-19.8c-0.7-0.8-1.4-1.9-2.1-3.3l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1l1.7-0.1c0.1-3.2,0.7-6.3,1.7-9.2l-1.4-0.7h-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1c1.2-1,2.2-1.8,3.2-2.3c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9
	c0.6-0.8,1.5-1.9,2.5-3l0.1-0.1l0.4-0.3c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.3l0.5,1.5c2.9-0.8,5.9-1.2,9.1-1.2h0.3l0.3-1.6
	v-0.1l0.1-0.3c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.4,0,0.5,0.1l0.1,0.1c1.4,0.8,2.5,1.6,3.3,2.3c7,1,13.1,4,18.3,9.2l1.2,1.2
	c1,0.3,2.2,0.8,3.6,1.5h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3l-1.1,1c1.8,2.6,3.2,5.4,4.1,8.4l1.5-0.3h0.1
	L653,372.8L653,372.8z"
          />
          <path
            fill="none"
            d="M652.6,372.8l-1.5,0.3l-2.8,0.4l-0.4,0.3l-10.6,5c-2.2,0.7-3.4,0.5-3.8-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3
	l10.5-5.1l0.4-0.1l2.2-1.9l1.1-1h-0.1l-4.4,1.9c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.5,1.2-0.5,2.1v0.4
	c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8L652.6,372.8L652.6,372.8z
	 M630.9,378.4c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.5-1.9c0.4-0.5,1-1,1.7-1.1
	c0.7-0.1,1.4,0.1,1.9,0.5C630.5,377,630.7,377.6,630.9,378.4L630.9,378.4z"
          />
          <path
            fill="none"
            d="M629.8,376.6c-0.5-0.4-1.2-0.5-1.9-0.5c-0.7,0.1-1.2,0.4-1.7,1.1c-0.4,0.5-0.5,1.2-0.5,1.9
	c0.1,0.7,0.4,1.4,1.1,1.8c0.5,0.4,1.2,0.5,1.9,0.4s1.4-0.4,1.8-1.1c0.4-0.5,0.5-1.2,0.4-1.9C630.7,377.6,630.5,377,629.8,376.6
	L629.8,376.6z M630.2,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2
	c-0.1-0.6,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0,1.4,0.4C629.8,377.6,630,378,630.2,378.4L630.2,378.4z"
          />
          <path
            fill="none"
            d="M629.4,377.2c0.4,0.3,0.7,0.7,0.8,1.2s0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3
	c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.6,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8S628.9,376.9,629.4,377.2L629.4,377.2z"
          />
          <path
            fill="none"
            d="M652.6,372.8h0.4c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1l-0.1,0.1l-4.5,2.2c-6.2,3-10.7,5-13.6,5.8
	c-3.7,1.1-6.2,1.5-7.2,1.5l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.5-0.7-1-1.4-1.1-2.3c-0.1-1-0.1-1.9,0.3-2.8l0.3-0.5
	c0.5-0.8,2.5-2.3,5.6-4.5c2.5-1.8,6.9-4.1,13.1-7l4.5-2.2h0.5c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3h-0.1l-4.4,1.9
	c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3c-0.4,0.5-0.5,1.2-0.5,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1
	c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8L652.6,372.8L652.6,372.8z"
          />
          <path
            fill="none"
            d="M653.2,373.9l0.1-0.1c-0.4,1.5-0.7,2.8-1.1,3.7c-0.7,1.6-1.4,2.6-2.2,2.9l-0.7,0.3c-5.4,1.9-10.2,3-14.4,3.6
	c-2.8,0.3-5,0-7-0.8c1,0.1,3.3-0.4,7.2-1.5c2.9-0.8,7.4-2.8,13.6-5.8L653.2,373.9L653.2,373.9z"
          />
          <path
            fill="none"
            d="M650,380.2c0.8-0.3,1.5-1.2,2.2-2.9c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6c0-1.9-0.3-3.2-1-3.7
	l-0.5-0.4c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3L650,380.2L650,380.2z M620.4,348.1c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.4,0,0.5,0.1l0.1,0.1
	l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6c-0.1,3.9-0.4,6.3-0.8,7.2l-0.3,0.5c-0.4,0.7-1,1.2-1.7,1.6l-0.4,0.1
	c-0.8,0.4-1.7,0.4-2.5,0.3c-1-0.1-1.8-0.6-2.5-1.1l-0.4-0.4c-0.5-0.8-1.5-3-2.6-6.7c-1-3-1.8-7.8-2.6-14.6l-0.5-5.1v-0.3l0.1-0.1
	l0.4-0.3c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.3v0.1l0.5,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3
	c0.4,0.6,1.1,1,1.8,1.1l0.4,0.1c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.5-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.8-4.7
	L620.4,348.1L620.4,348.1L620.4,348.1z"
          />
          <path
            fill="none"
            d="M621.5,348.4l-0.1-0.3c1.4,0.8,2.5,1.6,3.3,2.3c1.4,1.1,1.9,2.1,1.9,2.9v0.8c0.1,5.6-0.3,10.6-1.1,14.7
	c-0.5,2.6-1.5,4.8-2.9,6.5c0.4-1,0.7-3.3,0.8-7.2c0.1-3-0.3-8-1.2-14.6L621.5,348.4L621.5,348.4z"
          />
          <path
            fill="none"
            d="M620.3,348.5l-0.3,1.6l-0.4,2.6v0.5l1.4,11.4c0,2.3-0.5,3.4-1.8,3.4l-3.2,0.5c-1.1,0.3-2.1-0.7-2.6-2.9
	l-1.7-11.6v-0.4l-1.1-2.6l-0.5-1.5v0.1l0.5,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1
	c0.5,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.5-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7L620.3,348.5L620.3,348.5z M617,371.4
	c0.5-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1c0.4,0.5,0.5,1.2,0.4,1.9c-0.1,0.7-0.4,1.2-1.1,1.8c-0.5,0.4-1.2,0.5-1.9,0.4
	s-1.4-0.4-1.8-1.1c-0.4-0.5-0.5-1.2-0.5-1.9C616,372.5,616.4,371.8,617,371.4L617,371.4z"
          />
          <path
            fill="none"
            d="M615.9,373.2c-0.1,0.7,0,1.4,0.5,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.5-0.4,1-1,1.1-1.8
	c0.1-0.7,0-1.4-0.4-1.9c-0.4-0.5-1-1-1.8-1.1c-0.7-0.1-1.4,0-1.9,0.4C616.4,371.8,616,372.5,615.9,373.2L615.9,373.2z M617.4,372.1
	c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3
	c-0.5-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C616.7,372.8,617,372.3,617.4,372.1L617.4,372.1z"
          />
          <path
            fill="none"
            d="M616.7,373.3c0.1-0.5,0.3-1,0.8-1.2c0.4-0.3,0.8-0.4,1.4-0.4c0.5,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4
	c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3c-0.5-0.1-1-0.3-1.2-0.7C616.7,374.3,616.6,373.9,616.7,373.3L616.7,373.3z
	 M599.1,362c-1.7,1.6-3.2,3.6-4.4,5.4l-0.8-0.3c-0.7-0.3-1.9,0-3.4,1c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9
	c-1.1,1.5-1.5,2.6-1.2,3.4l0.1,0.8C603.2,358.3,601,360,599.1,362L599.1,362z"
          />
          <path
            fill="none"
            d="M594.7,367.4c5.5,1.7,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7l0.4,0.4c0.5,0.6,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1l-0.5,0.3c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3c1,5.1,3,9.6,6.6,13.6
	c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4c0.1-0.1,0.4-0.1,0.5-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.7,0.5,2.2,1.2
	c0.8,0.7,1.2,1.5,1.4,2.3v0.5c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2
	c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8
	c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3
	c-5.4,1.9-10.2,3-14.4,3.6c-2.8,0.3-5,0-7-0.8l-0.7-0.1c-0.7-0.1-1.4-0.5-2.1-1.1l-0.3-0.3c-0.5-0.7-1-1.4-1.1-2.3s-0.1-1.9,0.3-2.8
	l0.3-0.5c0.7-2.1,1.9-4,3.8-5.9c3-3,6.9-5.9,11.8-8.9c-3.8-3.7-8.4-6.1-13.3-7.2c0.1,5.6-0.3,10.6-1.1,14.7
	c-0.5,2.6-1.5,4.8-2.9,6.5l-0.3,0.5c-0.4,0.7-1,1.2-1.7,1.6l-0.4,0.1c-0.8,0.4-1.7,0.4-2.5,0.3c-1-0.1-1.8-0.5-2.5-1.1l-0.4-0.4
	c-1.7-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14c-2.3,1.4-4.5,3-6.5,5C597.3,363.7,595.9,365.6,594.7,367.4L594.7,367.4z
	 M617.2,380.3c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1.1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C616.3,381.8,616.6,381,617.2,380.3L617.2,380.3z"
          />
          <path
            fill="none"
            d="M616.3,382.9c0-1,0.4-1.9,1.1-2.6s1.5-1.1,2.6-1.1c1.1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6
	c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1C616.6,384.7,616.3,383.9,616.3,382.9L616.3,382.9z M590.4,368.2
	c1.5-1,2.6-1.2,3.4-1l0.8,0.3c5.5,1.6,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7c-0.8-0.7-3-1.6-6.6-3c-2.9-1.1-7.7-2.2-14.3-3.3
	l-5.1-0.8h-0.3C588.5,369.6,589.6,368.8,590.4,368.2L590.4,368.2z M609.1,349.9v0.3l0.5,5.1c0.8,6.7,1.7,11.6,2.6,14.6
	c1.1,3.7,2.1,6.1,2.6,6.7c-1.7-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14l-0.1-0.8c-0.3-0.8,0.1-1.9,1.2-3.4
	C607.2,352,608,351,609.1,349.9L609.1,349.9z M612,353.9l-1.1-2.6c2.9-0.8,5.9-1.2,9.1-1.2h0.3l-0.4,2.6v0.5c-2.8,0-5.2,0.3-7.7,1
	L612,353.9L612,353.9z"
          />
          <path
            fill="none"
            d="M616.3,368.9c-1.1,0.3-2.1-0.7-2.6-2.9l-1.7-11.6c2.5-0.7,5-1,7.7-1l1.4,11.4c0,2.3-0.5,3.4-1.8,3.4
	L616.3,368.9z M606.5,375.9c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8l1.4,0.7l2.5,1.2l0.5,0.1l11.4,2.2c2.2,0.7,3.2,1.6,2.8,2.8l-0.5,3.2
	c-0.1,1.1-1.2,1.6-3.6,1.5l-11.4-1.9l-0.5-0.1l-2.8,0.3l-1.7,0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4
	c0.7-0.3,1.2-0.7,1.7-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2C612.2,378.1,610.1,377.2,606.5,375.9L606.5,375.9
	z M613.1,380.3c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4s-1.4,0.1-2.1-0.1c-0.7-0.3-1.1-0.8-1.4-1.5
	c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1C612.4,379.2,613,379.6,613.1,380.3L613.1,380.3z"
          />
          <path
            fill="none"
            d="M611.9,378.8c-0.7-0.3-1.4-0.4-2.1-0.1s-1.2,0.7-1.5,1.4s-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5
	s1.4,0.4,2.1,0.1c0.7-0.3,1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1S612.4,379.2,611.9,378.8L611.9,378.8z M611.5,379.5
	c0.4,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.5c-0.3,0.5-0.5,0.8-1.1,1l-1.5-0.1c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.5-0.1-1,0.1-1.5
	c0.3-0.4,0.7-0.8,1.1-1C610.5,379.2,611.1,379.2,611.5,379.5L611.5,379.5z"
          />
          <path
            fill="none"
            d="M610.1,379.4c0.5-0.1,1-0.1,1.5,0.1c0.4,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.5c-0.3,0.5-0.5,0.8-1.1,1
	l-1.5-0.1c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.5-0.1-1,0.1-1.5C609.1,379.9,609.5,379.6,610.1,379.4L610.1,379.4z"
          />
          <path
            fill="none"
            d="M606.5,375.9c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8h-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4
	c0-0.1,0.1-0.3,0.3-0.4h0.4l5.1,0.8c6.6,1.2,11.4,2.3,14.3,3.3c3.6,1.4,5.8,2.3,6.6,3l0.4,0.4c0.5,0.5,0.8,1.2,1,2.1v0.4
	c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.7-1.8,2.1l-0.5,0.3c-1,0.4-3.4,0.6-7.3,0.4c-3,0-7.8-0.7-14.6-2.1l-5.1-1l-0.3-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4
	c0.7-0.3,1.2-0.7,1.7-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.7-1.1-2.2C612.2,378.1,610.1,377.2,606.5,375.9L606.5,375.9
	z"
          />
          <path
            fill="none"
            d="M603.1,376.1c2.2,0.7,3.2,1.6,2.8,2.8l-0.5,3.2c-0.1,1.1-1.2,1.6-3.6,1.5l-11.4-1.9c0.1-2.8,0.5-5.2,1.4-7.7
	L603.1,376.1L603.1,376.1z M616,386.6h0.4c0.8,0.1,1.7,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3v0.5c0,1-0.6,3.3-1.8,7
	c-1,2.9-3.2,7.4-6.5,13.3l-2.5,4.4l-0.1,0.1c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3
	c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3c-0.4-0.4-1-0.7-1.7-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1
	c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4h-0.1v0.1l-0.3,0.1h-0.4c-0.1-0.1-0.3-0.3-0.3-0.4v-0.5l2.3-4.5c3.2-5.9,5.8-10.2,7.7-12.5
	c2.3-3,4-4.8,4.8-5.4c0.1-0.1,0.4-0.1,0.6-0.3C614.4,386.6,615,386.5,616,386.6L616,386.6z"
          />
          <path
            fill="none"
            d="M613.7,388.3c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4l1.1-1.1l1.9-1.9l0.3-0.5l5.6-10
	c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3l-0.1,0.4l-0.7,2.9l-0.3,1.5l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3
	c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3c-0.4-0.4-1-0.7-1.7-0.8C615.2,387.7,614.4,387.7,613.7,388.3L613.7,388.3z
	 M613.5,389.3c0.5-0.5,1.1-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8
	c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.6-0.8-1.1-0.8-1.9C612.7,390.4,613,389.7,613.5,389.3L613.5,389.3z"
          />
          <path
            fill="none"
            d="M612.7,391c0,0.8,0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9
	s-0.3-1.4-0.8-1.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8C613,389.7,612.7,390.4,612.7,391z M615.5,393
	c-0.5,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4c0.4-0.4,0.8-0.5,1.4-0.5c0.5,0,1,0.1,1.4,0.5s0.5,0.8,0.5,1.4
	c0,0.5-0.1,1-0.5,1.4S616,393,615.5,393z"
          />
          <path
            fill="none"
            d="M616.8,392.4c-0.4,0.4-0.8,0.6-1.4,0.6c-0.5,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4
	c0.4-0.4,0.8-0.5,1.4-0.5c0.5,0,1,0.1,1.4,0.5s0.5,0.8,0.5,1.4C617.4,391.6,617.1,392,616.8,392.4z M612.3,385.5
	c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3H590c-0.8,0-1.8-0.7-2.9-2.1c-0.7-0.8-1.4-1.9-2.1-3.3l0.3,0.1l5.1,1
	c6.7,1.2,11.6,1.9,14.6,2.1C608.9,386.1,611.3,386,612.3,385.5L612.3,385.5z M591.2,373.7l0.5,0.1c-0.8,2.5-1.2,5-1.4,7.7l-0.5-0.1
	l-2.8,0.3c0.1-3.2,0.7-6.3,1.7-9.2L591.2,373.7L591.2,373.7z"
          />
          <path
            fill="none"
            d="M590.1,388.3h0.8c1,5.1,3,9.6,6.6,13.6l-0.5,0.7c-0.5,0.6-0.5,1.8-0.1,3.4l-0.1-0.1c-5.6-5.6-8.8-12.2-9.5-19.8
	C588.4,387.6,589.3,388.3,590.1,388.3L590.1,388.3z M626.7,354.2v-0.8c0-0.8-0.7-1.8-1.9-2.9c7,1,13.1,4,18.3,9.2l1.2,1.2
	c-1.8-0.6-2.9-0.6-3.6-0.1l-0.7,0.4C636.1,357.6,631.7,355.3,626.7,354.2L626.7,354.2z"
          />
          <path
            fill="none"
            d="M647.9,362.4h-0.3l-4.5,2.2c-6.2,2.9-10.6,5.2-13.1,7c-3.2,2.2-5.1,3.7-5.6,4.5c0.7-2.1,1.9-4,3.8-5.9
	c3-2.9,6.9-5.9,11.8-8.9l0.7-0.4c0.7-0.5,1.8-0.4,3.6,0.1C645.3,361.3,646.4,361.7,647.9,362.4L647.9,362.4z M644.5,366.7l0.4-0.1
	l2.2-1.9c1.8,2.6,3.2,5.4,4.1,8.4l-2.8,0.4l-0.4,0.3C647.1,371.1,646,368.8,644.5,366.7z"
          />
          <path
            fill="none"
            d="M647.9,373.7l-10.6,5c-2.2,0.7-3.4,0.5-3.8-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1
	C646,368.8,647.1,371.1,647.9,373.7L647.9,373.7z M626.9,385l0.7,0.1c1,0.3,3,1.5,6.1,3.9c2.5,1.8,6.1,5.2,10.7,10.3l3.3,3.7
	l0.1,0.1v0.3c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7
	c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3c-0.5,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.5,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1
	l3.3,3.4v0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3
	l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8C625.2,385.1,626.1,384.9,626.9,385L626.9,385z"
          />
          <path
            fill="none"
            d="M624.8,386.6c-0.5,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.5,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1
	l3.3,3.4l-0.7-1.4l-1.2-2.5l-0.4-0.4l-8-8.5c-1.4-1.9-1.7-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4l0.3,0.3l2.5,1.5
	l1.4,0.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1L624.8,386.6L624.8,386.6z
	 M628.7,388.4c0.3,0.7,0.4,1.4,0.1,2.1s-0.7,1.2-1.4,1.5s-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4s-0.4-1.4-0.1-2.1
	c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1C627.8,387.3,628.4,387.7,628.7,388.4L628.7,388.4z"
          />
          <path
            fill="none"
            d="M627.2,387.1c-0.7-0.3-1.4-0.1-2.1,0.1c-0.7,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1
	c0.4,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1s1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1C628.4,387.7,627.8,387.3,627.2,387.1L627.2,387.1z
	 M626.9,387.7c0.5,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.5,0.8-1,1.1c-0.5,0.3-1,0.3-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1
	c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1C625.9,387.6,626.5,387.6,626.9,387.7L626.9,387.7z"
          />
          <path
            fill="none"
            d="M625.5,387.9c0.4-0.3,1-0.3,1.4-0.1c0.5,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.5-0.5,0.8-1,1.1
	c-0.5,0.3-1,0.3-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4C624.7,388.4,625,388.2,625.5,387.9L625.5,387.9z
	 M627.6,385.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5l0.5,0.4c0.7,0.5,1,1.8,1,3.7c0,1-0.1,2.2-0.3,3.6l-0.1-0.1l-3.3-3.7
	c-4.7-5.1-8.3-8.5-10.7-10.3C630.6,386.6,628.5,385.4,627.6,385.1L627.6,385.1z M618.2,397.9c1.2-3.7,1.9-6.1,1.8-7
	c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1c-1,0.3-2.2,0.7-3.7,1l0.1-0.1l2.5-4.4
	C615,405.2,617.2,400.8,618.2,397.9L618.2,397.9z M621.9,390.6c0.3,1,1.5,3,3.6,6.3c1.8,2.6,5,6.3,9.6,11.1l3.6,3.6l0.3,0.1
	c-1.5,0.1-2.8,0.1-3.7,0.1c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6c-3.4-4.5-6.1-8.8-7.8-12.7C622.3,395,621.8,392.7,621.9,390.6
	L621.9,390.6z M629.4,398.2c-1.4-1.9-1.7-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4l-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3
	L629.4,398.2L629.4,398.2z"
          />
          <path
            fill="none"
            d="M642.8,401.4l0.3,0.3l2.5,1.5c-0.8,1-1.7,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6l-1.2-2.5l-0.4-0.4
	c1.2-1,2.3-1.9,3.4-3L642.8,401.4L642.8,401.4z M631.1,410.2l0.4,0.5c0.4,0.7,1.7,1.1,3.6,1.2c-4.5,2.5-9.6,3.7-15.3,3.7
	c-2.5,0-4.8-0.3-7-0.7c1.8-0.5,2.9-1.2,3-2.1l0.3-0.7l3.7,0.1C624,412.4,627.7,411.5,631.1,410.2L631.1,410.2z M596.8,406.2
	c-0.4-1.6-0.4-2.9,0.1-3.4l0.5-0.7c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4c-0.8,0.5-2.5,2.3-4.8,5.4
	c-1.9,2.5-4.4,6.6-7.7,12.5l-2.3,4.5v0.3C597.6,408.5,597,407.1,596.8,406.2L596.8,406.2z M611.6,394.8l2.9,1.5
	c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3c-2.5-1-4.7-2.2-6.9-3.7l5.6-10C609.5,394.8,610.6,394.2,611.6,394.8L611.6,394.8z"
          />
          <path
            fill="none"
            d="M602.3,407.3l0.3-0.6c2.2,1.6,4.4,2.9,6.9,3.7l-0.1,0.4l-0.7,2.9c-2.9-1.1-5.6-2.6-8.3-4.5L602.3,407.3
	L602.3,407.3z M729.4,337.8c-0.8,4,0.4,6.3,3.7,7c3.9,0,6.1,2.2,6.3,6.3v12.5c0,2.1-1,3.4-2.6,4.1c-0.5,0.3-1.4,0.4-2.1,0.5h-3.2
	c-2.3,0.3-3.4,1.8-3.6,4.7v13.2c0,1.9,0.8,3.3,2.8,4.3l1,0.4l1.9,0.5l0.4,0.1l-1.7-0.3l-1.9-0.5l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-5
	c-0.1-1.9-1-3-2.5-3.3l-21.9-1.9c-4.3,0-6.5-1.9-6.5-5.6c-0.1-2.5-0.5-5-1-7.4c-0.7-3.7-1.7-7.1-2.9-10.6c-3.2-8.5-8.1-16.5-15-23.7
	l39.9,3.7v2.5c-0.1,1.1,0,1.9,0.4,2.5c0.4,0.4,1,0.7,1.7,0.7c0.5-0.4,1-1,1.2-1.6c0.4-1.5,1.4-2.2,2.6-2.2h4.3
	C729.1,334.1,729.9,335.3,729.4,337.8L729.4,337.8z"
          />
          <path
            fill="none"
            d="M720.3,336.2c-0.3,0.7-0.7,1.2-1.2,1.6c-0.7,0-1.2-0.3-1.7-0.7l1.1-2.5c0.8-1.8,2.3-2.6,4.3-2.6
	c2.3,0.1,3.3,0.8,2.9,1.9h-2.9C721.7,334,720.7,334.8,720.3,336.2L720.3,336.2z"
          />
          <path
            fill="none"
            d="M717,334.6v-2.5c0-5.1,0.8-11.3,2.3-18.3c1-3.6,2.6-5.6,5.1-6.2c0.5,9.9,0,18-1.5,24.4
	c-2.1-0.1-3.4,0.7-4.3,2.6l-1.1,2.5C717,336.6,716.8,335.7,717,334.6L717,334.6z M731.4,368.3h3.2c0.3,3.4,0.3,6.9-0.1,10.3
	c-0.4,4-1.4,8.1-2.8,12.4l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-13.2C728,370.1,729.1,368.6,731.4,368.3L731.4,368.3z"
          />
          <path
            fill="none"
            d="M734.6,368.3c0.8-0.1,1.5-0.3,2.1-0.5c0.8,7.7-0.1,15.7-3,23.8l-1.9-0.5c1.4-4.1,2.3-8.3,2.9-12.4
	C734.9,375.2,734.9,371.8,734.6,368.3L734.6,368.3z"
          />
          <path
            fill="none"
            d="M726.8,381.1v5c0,1.9,0.8,3.3,2.8,4.3l1,0.4l1.9,0.6l1.7,0.3l2.3,0.3c1.2,0.5,1,1.9-0.8,4.3
	c-1.7,1.6-5.2,2.9-10.9,3.7c-7.2,1-16.1,1.6-26.7,1.9c-4.8,0.1-10,0.3-15.7,0.3c0.3-5.6,0.3-12,0-18.6c-0.3-7.4-1.7-14.3-4-20.8H695
	c0.4,2.5,0.8,4.8,1,7.4c0,3.9,2.2,5.8,6.5,5.6l21.9,1.9C725.8,378.1,726.6,379.2,726.8,381.1L726.8,381.1z M685.2,412.9
	c-2.3,0.4-3.6,0.1-3.6-1v-0.3c0.3-3,0.5-6.2,0.7-9.5c5.6,0,10.9-0.1,15.7-0.3c-0.3,4.4-1.9,7.2-5.2,8.5L685.2,412.9L685.2,412.9z
	 M483,219.9c-2.8-3-1.5-4.7,4.1-5.2h20.1l68.9,52.3l-32.5,0.7c-6.5,0-13.8-3.9-21.6-11.4C503.6,241,490.7,228.9,483,219.9L483,219.9
	z M508.2,258.3c1,0.4,1.9,1,2.9,1.5l0.5,0.4c2.6,1.8,4.1,3.9,4.7,6.3c0.1,0.7,0.3,1.2,0.3,1.9c0,0.8-0.1,1.5-0.3,2.3
	c-0.1,0.7-0.5,1.5-1,2.2c-0.5,1-1.2,1.8-2.2,2.6l-2.1,1.5c-1.8,1.1-3.6,1.9-5.8,2.5l-2.8,0.4l-1.2,0.1c-2.2-0.3-4-1.4-5.5-3.4
	c-0.4-0.5-0.8-1.2-1.2-1.9c-0.4-0.8-0.8-1.8-1.1-2.8c-0.3-1.1-0.4-2.3-0.4-3.6c0-3.2,0.8-5.9,2.6-8.3c1.5-2.1,3.4-3.2,5.5-3.3
	l1.2,0.1C504.6,257.2,506.5,257.6,508.2,258.3L508.2,258.3z"
          />
          <path
            fill="none"
            d="M516.6,268.5c0-0.7-0.1-1.4-0.3-1.9l6.5,6.7l-5.6-0.3l-0.8-2.2C516.4,270,516.6,269.3,516.6,268.5L516.6,268.5z
	 M501.4,256.9h-1.5c-2.5,0-4.7,1.1-6.5,3.4s-2.6,5.1-2.6,8.3c0,1.2,0.1,2.3,0.4,3.4l-94.1-3.9l-15.8-57.5
	c27.5,0.4,53.2,1.2,77.2,2.9c17.5,14.4,34.1,29.4,49.8,44.8c-1.7-0.7-3.6-1.1-5.5-1.4L501.4,256.9L501.4,256.9z"
          />
          <path
            fill="none"
            d="M499.9,256.8h1.5c-2.2,0.3-4,1.4-5.5,3.3c-1.8,2.3-2.6,5.1-2.6,8.3c0,1.2,0.1,2.5,0.4,3.6
	c0.3,1,0.5,1.9,1.1,2.8c0.4,0.7,0.8,1.4,1.2,1.9c1.5,2.1,3.4,3.2,5.5,3.4h-1.8c-2.5,0-4.4-1.2-6.2-3.4c-0.5-0.7-1-1.4-1.2-2.1
	c-0.4-0.8-0.8-1.8-1-2.8c-0.3-1.1-0.4-2.2-0.4-3.4c0-3.2,0.8-5.9,2.6-8.3C495.2,258,497.4,256.8,499.9,256.8L499.9,256.8z
	 M546,288.7l16.2,15.7c2.3,2.2,5.2,3.3,8.5,3.2l10-0.4c3-0.1,3.3-1.2,0.8-3.3L564.6,289c-1.9-1.8-4.4-2.6-7.3-2.6l-9.4-0.3
	c-1.8,0-2.8,0.1-2.9,0.8v0.4C545,287.7,545.4,288.1,546,288.7L546,288.7z M551.8,288.1c1.2,0.1,2.5,0.5,3.9,1.2
	c1.4,0.5,2.5,1.2,3.2,1.9l13.1,11.7c0.8,0.7,1.1,1.2,1.1,1.8c-0.1,0.5-0.7,0.8-1.7,0.8s-2.1-0.3-3.4-0.7c-1.4-0.5-2.5-1.2-3.3-1.9
	l-14.4-12.8c-0.8-0.8-1.1-1.4-0.8-1.8C549.7,288,550.4,287.9,551.8,288.1L551.8,288.1z"
          />
          <path
            fill="none"
            d="M549.4,288.4c0.3-0.4,1.1-0.5,2.3-0.3c1.2,0.1,2.5,0.5,3.9,1.2c1.4,0.5,2.5,1.2,3.2,1.9l13.1,11.7
	c0.8,0.7,1.1,1.2,1.1,1.8c-0.1,0.5-0.7,0.8-1.7,0.8s-2.1-0.3-3.4-0.7c-1.4-0.5-2.5-1.2-3.3-1.9l-14.4-12.8
	C549.3,289.4,549,288.8,549.4,288.4L549.4,288.4z M502.7,280l2.8-0.4c2.1-0.5,4-1.4,5.8-2.5l2.1-1.5c1-0.8,1.7-1.8,2.2-2.6
	c0.4-0.7,0.7-1.4,1-2.2l0.8,2.2l1.1,2.8l0.5,1.4c-0.7,3.9-6.3,4.8-17.2,3L502.7,280L502.7,280z"
          />
          <path
            fill="none"
            d="M499.6,280.2h1.8c10.9,1.8,16.6,0.8,17.2-3l0.5,1.2l3.6,4.7c1.8,2.6,1,4.8-2.3,6.6c-5.4,1.6-10.6,1.6-15.7,0
	C500.7,288,499.1,284.8,499.6,280.2L499.6,280.2z M256.7,222.3c1.9-7.2,11.3-10.7,28.1-10.7c26.7-0.8,52-1.1,76.1-1.1l6.6,56.4
	l-109.8-4.5l-3.9-27.9L256.7,222.3L256.7,222.3z"
          />
          <path
            fill="none"
            d="M245.4,264.6l-0.1-0.1H244l0.7-2.8l12.9,0.5l109.8,4.5l14.7,0.5l0.5,2.8 M371.1,210.6l-0.5-2.8
	c31.8,0.3,61.2,1.2,88.3,3c0.3,0,0.5,0.1,0.8,0.3c22,18.2,42.8,37.1,61.9,57.1l5,5.2l3.2,2.8h-0.5l-11.1-0.4L517,273l5.6,0.3
	l-6.5-6.7c-0.5-2.3-2.1-4.5-4.7-6.3l-0.5-0.4c-1-0.5-1.9-1.1-2.9-1.5c-15.7-15.5-32.3-30.4-49.8-44.8c-23.9-1.5-49.7-2.5-77.2-2.9
	L371.1,210.6L371.1,210.6L371.1,210.6z"
          />
          <path fill="none" d="M371.1,210.6h10l16,57.5l-15-0.7" />
          <path
            fill="none"
            d="M382.7,270.1l-0.5-2.8l15,0.7l94.1,3.9c0.3,1,0.5,1.9,1,2.8L382.7,270.1L382.7,270.1z M382.2,267.4l-14.7-0.5
	l-6.6-56.4h10.3L382.2,267.4L382.2,267.4z M677.1,328.5l39.9,3.7c0-5.1,0.8-11.3,2.3-18.3c1-3.6,2.6-5.6,5.1-6.2l0.8-0.1
	c1.9-0.7,2.5-2.1,1.2-4.4c-1.8-3.6-7.4-8-16.9-13.1c-17.1-8.5-42.4-14.2-76.1-16.9c-11.1-0.4-22.8-0.5-35.4-0.5l-48.1,2.5
	c18,9.8,33.4,20.8,46.2,33c7.6-2.5,15.7-3.6,24.3-3.6c20.9,0,38.7,7,53.5,20.9L677.1,328.5L677.1,328.5z M665.4,285.7
	c15.3,3.6,25.9,7.4,31.6,11.6c3.3,2.3,5.6,5.6,6.9,9.8c1.5,6.5,1.8,11.4,1,14.9c-0.3,3-2.5,4.5-6.5,4.5c-6.2,0.1-11.1-1.2-15-4.1
	c-10.7-8.3-19.1-16.1-25.6-23.7c-3.9-4.3-5-7.8-3.3-10.7c1.4-2.3,4.5-3.2,9.2-2.5c-15-2.6-29.6-4.3-43.6-5.1
	c14,0.8,28.5,2.5,43.6,5.1L665.4,285.7L665.4,285.7z"
          />
          <path
            fill="none"
            d="M663.9,285.4c-4.7-0.7-7.8,0.1-9.2,2.5c-1.7,2.9-0.5,6.5,3.3,10.7c6.3,7.6,14.9,15.5,25.6,23.7
	c3.8,2.9,8.8,4.3,15,4.1c4,0,6.2-1.5,6.5-4.5c0.8-3.6,0.5-8.5-1-14.9c-1.2-4.1-3.6-7.4-6.9-9.8c-5.9-4.1-16.5-8-31.6-11.6
	L663.9,285.4L663.9,285.4z M670.9,288.6c11.8,2.9,20.1,6.1,24.9,9.5c3.2,2.2,5.4,5.2,6.6,8.9c1,3.7,1.4,7,1.5,9.8v0.3
	c0,1.5-0.1,2.6-0.4,3.7c0,0.4-0.1,0.8-0.3,1.2c-1.1,1.9-3.7,2.5-7.8,1.8c-4-0.5-8.1-2.6-12.2-5.9c-8.3-6.5-14.9-13.1-19.7-19.4
	c-2.6-3.4-3-6.2-1.1-8.3C664,288.1,666.8,287.7,670.9,288.6L670.9,288.6z"
          />
          <path
            fill="none"
            d="M662.4,289.9c1.5-1.8,4.4-2.2,8.4-1.5c11.8,2.9,20.1,6.1,24.9,9.5c3.2,2.2,5.4,5.2,6.6,8.9c1,3.7,1.4,7,1.5,9.8
	v0.3c0,1.5-0.1,2.6-0.4,3.7c0,0.4-0.1,0.8-0.3,1.2c-1.1,1.9-3.7,2.5-7.8,1.8c-4-0.6-8.1-2.6-12.2-5.9c-8.3-6.5-14.9-13.1-19.7-19.4
	C660.9,294.9,660.6,292,662.4,289.9L662.4,289.9z M487.1,214.7c-5.6,0.6-7,2.3-4.1,5.2c7.7,8.9,20.6,21,39.1,36.3
	c8,7.6,15.1,11.4,21.6,11.4l32.5-0.7l13.3,5.8c-6.5,0-13.1,0.1-19.9,0.4l-29,0.1l-70.2-62.6h31.8l5.2,4L487.1,214.7L487.1,214.7z"
          />
          <path
            fill="none"
            d="M521.6,268.2c-19.3-19.9-39.9-38.9-61.9-57.1c-0.3-0.1-0.5-0.3-0.8-0.3h11.3l70.2,62.6h-13.8L521.6,268.2z"
          />
          <path
            fill="none"
            d="M529.9,276.2l-3.2-2.8h13.8l29-0.1c6.9-0.1,13.5-0.3,19.9-0.4l8.7-0.1l-48.1,2.5l-14,0.7L529.9,276.2
	L529.9,276.2z M529.9,276.2l6.1-0.3l7,4.8c1.1,1,1.7,1.9,1.9,3.2v4.1c2.3,4,8.7,10.5,19,19.4c1.8,1.1,3.4,1.8,5.1,1.9h0.1
	c2.8-0.4,4.8-0.1,6.3,1l0.3,0.3l5.1,4.5c-4.8,2.9-9.4,6.3-13.6,10.5l-2.3,2.2c-3.6,3.6-6.7,7.4-9.2,11.4c-5.1,7.8-8.5,16.5-10,26
	c-1.2,7-2.1,13.2-2.5,19l-5.5-0.1c2.6-15,4.4-34.3,5.4-57.6l0.1-1v-0.3C543,297,538.6,280.6,529.9,276.2L529.9,276.2z"
          />
          <path
            fill="none"
            d="M545,287.3c0.1,0.4,0.4,0.8,1,1.4l16.2,15.7c2.3,2.2,5.2,3.3,8.5,3.2l10-0.4c3-0.1,3.3-1.2,0.8-3.3L564.6,289
	c-1.9-1.8-4.4-2.6-7.3-2.6l-9.4-0.3c-1.8,0-2.8,0.1-2.9,0.8v-3c-0.3-1.2-1-2.2-1.9-3.2l-7-4.8l14-0.7c18,9.8,33.4,20.8,46.2,33
	c-5.4,1.6-10.5,4-15.4,6.9l-5.1-4.5l-0.3-0.3c-1.5-1.1-3.6-1.5-6.3-1h-0.1c-1.7-0.1-3.4-0.8-5.1-1.9c-10.5-8.9-16.8-15.4-19-19.4
	L545,287.3L545,287.3z M217.9,179.5c0.3,0.1,0.4,0.3,0.4,0.4l9.9,11.7v0.1c0.4,1.1-0.4,1.8-2.2,1.9l-9.2-13.3v-0.6
	c0.1-0.3,0.3-0.4,0.4-0.4L217.9,179.5L217.9,179.5z"
          />
          <path
            fill="none"
            d="M226,193.6c1.9-0.1,2.6-0.8,2.2-1.9v-0.1c1.8-0.8,5-1,9.5-0.7c3.2,0,5.1,1.6,5.9,5l-19.8,1.9
	C224.2,195,224.9,193.6,226,193.6L226,193.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M576.1,267l-32.5,0.7c-6.5,0-13.8-3.9-21.6-11.4
	c-18.3-15.3-31.4-27.4-39.1-36.3c-2.8-3-1.5-4.7,4.1-5.2h20.1 M228.2,191.6l-9.9-11.7c0-0.3-0.1-0.4-0.4-0.4h-0.6l-0.4,0.4
	c0,0-0.1,0.4,0,0.6l9.2,13.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226,193.6c1.9-0.1,2.6-0.8,2.2-1.9v-0.1 M228.2,191.6
	c1.8-0.8,5-1,9.5-0.7c3.2,0,5.1,1.7,5.9,5 M396.5,192.8c18.3,1,32.5,2.2,42.8,3.9c3.3,0.4,6.3,1,8.9,1.4c3,0.6,5.8,1.2,8.1,1.9
	c0.3,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.6,0.1,0.7c-0.1,0.3-0.3,0.4-0.5,0.6l-0.7,0.1l-0.7-0.3c-6.9-1.5-15.5-2.8-26-3.6
	c-14.4-1.4-32.5-2.1-53.8-2.3c-33.8-1-66.6-0.6-98.1,1.1c-13.5,0.6-28.5,1.6-45,3.7c-3.6,0.6-6.7,1.1-9.6,1.8l-0.7,0.3l-0.7-0.1
	c-0.3-0.1-0.4-0.3-0.6-0.6c-0.1-0.3,0-0.6,0.1-0.7c0.1-0.3,0.3-0.4,0.6-0.6c2.3-0.7,5.1-1.4,8.1-1.9c6.7-1.4,13.9-2.5,21.5-3.3
	 M243.6,195.8l7.4-0.6 M257.7,262.3l-3.9-27.9l2.7-12.1c1.9-7.2,11.3-10.7,28.1-10.7c26.7-0.8,52-1.1,76.1-1.1 M256.4,216.6
	c-1.2,1.4-1.9,3-2.3,5l-9.2,40.2 M244,264.5h1.4l137.3,5.6 M244.7,261.7l-0.7,2.8 M244.7,261.7l12.9,0.5 M223.7,197.8l19.9-1.9
	 M177.3,213.3c-7.8-0.7-13.1-2.3-15.7-5.1c-0.8-0.7-1-1.5-0.3-2.5l0.1-0.1c1-1.4,3.4-2.3,7.4-2.9l34.4-2.2 M177.3,213.3l8.5,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M185.9,213.7c0.8-2.6,3.4-4.5,7.8-5.5c6.3-2.1,9.5-4.7,9.4-7.7
	 M197.8,214.1l-4.8,6.7l1.5,2.2l-15.8,27.5l-3.6,1.1l-4.1,7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M185.9,213.7l12,0.4l58.6,2.5 M151.3,260.6l3.6-2.6 M139.2,273.8
	c1.2-1.4,2.9-2.1,5.1-2.2h4.8c2.8,0,4.4,1.6,5.1,4.8c0.6,4.4,0.4,10.6-0.3,18.7c-0.1,9.5-1.6,16.1-4.5,19.8
	c-1.2,1.2-3.3,1.9-6.3,2.1c-4.4,0.3-7-1.4-8-4.5c-0.3-2.3-0.4-5.6-0.3-9.6 M151.3,260.6c-3,1.6-5.8,4-8.1,6.7l-1.2,1.5
	c-0.8,1.1-1.5,2.2-2.2,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M134.8,314.4l0.1,0.8c0.3,1,0.7,1.7,1.2,2.3c1.6,2.1,4.4,2.9,8.4,2.8
	c3.6-0.3,6.2-1.1,7.7-2.3l0.3-0.4c3.3-4.1,5-11.3,5.2-21.6c1-8.9,1.1-16,0.4-20.8c-0.8-3.4-2.8-5.2-6.1-5.2h-5.9
	c-2.8,0.1-4.7,1-6.2,2.5 M244.7,261.7l-73.7-3l-16.1-0.7 M154.9,258c10.6-17.7,20.9-32.6,31-44.3 M244,264.5l-92.7-3.9 M203.2,200.5
	c6.3-1,13.2-1.9,20.5-2.8 M226,193.6c-1,0-1.8,1.4-2.3,4.1 M251.1,195.3l16-1.1c23.9-1.4,51.5-2.1,82.4-2.2c9.4,0,18.6,0,27.2,0.1
	l19.7,0.6 M371.1,210.6l-0.5-2.8 M371.1,210.6h10 M382.7,270.1l-0.5-2.8 M382.2,267.4l-14.7-0.5 M367.4,266.8l-6.6-56.4
	 M382.2,267.4l-11-56.8 M360.8,210.4h10.3 M370.6,207.8c-27-0.1-55.6,0.1-85.8,1c-14.6,0-24.1,2.6-28.3,7.8 M272.4,289.1h0.5
	c1.6,0,3,0.3,4.1,0.7c1.1,0.4,1.6,1,1.6,1.5s-0.5,1.1-1.6,1.5s-2.5,0.7-4.1,0.7h-0.5 M255.6,288.3c0.3-0.5,0.8-1.2,1.4-1.6
	c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1c0.8,0.8,1.4,1.6,1.6,2.6 M272.4,293.6c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1
	h-3.7c-1.9,0-3.6-0.7-5-2.1c-1-1-1.6-2.1-1.9-3.2 M239.2,306.7c13.1,3,24.8,9.2,35.1,18.7c7.4,6.9,12.9,14.4,16.6,22.7
	c4.3,10.2,6.7,20.6,7.6,31.5 M402.9,297h-1.2c-0.5,0.5-1.1,0.8-1.8,0.8h-0.1c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3
	s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1c0.7,0,1.4,0.3,1.9,1l1.8,0.1 M238.9,324.7c8.7,3,16.5,8.4,23.4,15.8
	c4.4,4.8,8.1,9.9,10.9,15.4c1.1,2.2,2.1,4.5,2.9,6.9c1,2.6,1.6,5.2,2.3,8c0.7,2.8,1.1,5.6,1.4,8.7c0.1,1.4,0.3,2.8,0.3,4
	c0.3,5.4,0.3,10.3,0.1,14.9 M280.1,379.4l18.4,0.4 M280.2,398.5v1.2 M280.2,399.7l-0.7-0.1c-0.5,0-1.1,0.3-1.7,0.7
	c-0.4,0.4-0.7,1-0.7,1.6v1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M281.7,402v-0.1c0-0.5-0.3-1.1-0.7-1.6c-0.3-0.3-0.5-0.4-1-0.5
	 M277.3,403.7v3.9c0,0.5,0.3,1.1,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.5,0,1.1-0.3,1.7-0.7c0.4-0.4,0.7-1,0.7-1.6v-5.5 M341.2,398.9
	c-1.8,2.1-5.5,3-11,3.3l-48.4-0.1 M280.2,398.5l60.9,0.4 M298.5,379.8l90.5,1.6 M389,381.4c2.3-25.2,2.5-50.1,0.4-74.7
	c-1-13.9-3.2-26.1-6.7-36.5 M204,277c2.5-2.5,5.5-3.7,9.1-3.7s6.6,1.2,9.2,3.7c2.5,2.5,3.7,5.6,3.7,9.2s-1.2,6.6-3.7,9.1
	c-2.5,2.5-5.6,3.9-9.2,3.9s-6.6-1.2-9.1-3.9c-2.5-2.5-3.9-5.5-3.9-9.1S201.5,279.6,204,277L204,277z M167.7,352.1
	c2.5-4.1,5.4-8,8.8-11.6c3-3.3,6.3-6.1,9.6-8.5c9.8-7,20.8-10.5,33.3-10.5c6.9,0,13.5,1.1,19.5,3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M171.1,397.6c-1.4-4.7-2.1-9.5-2.1-14.7c0-10.3,2.8-19.5,8.1-27.6
	c1.9-2.8,4.1-5.5,6.6-8c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1c11,0,20.6,3,29.2,9.1c2.3,1.6,4.5,3.6,6.6,5.6
	c1.1,1.1,2.2,2.2,3,3.3c7.8,9.2,11.7,19.9,11.7,32.3c0,7.4-1.4,14.3-4.3,20.6 M165.2,325.7l-2.2,2.1c-3.6,3.6-6.7,7.4-9.5,11.4
	c-2.6,4.1-4.9,8.4-6.6,12.8 M165.2,325.7l0.1-0.1c15-13.9,33.2-20.9,54.5-20.9c6.7,0,13.3,0.7,19.4,2.2 M255,293h-1.2
	c-0.6,0.5-1.1,0.8-1.8,0.8h-0.3c-2.1,0-3.9-0.4-5.4-1c-1.6-0.7-2.5-1.4-2.5-2.3s0.8-1.6,2.5-2.3c1.5-0.5,3.3-1,5.4-1h0.1
	c0.7,0,1.4,0.3,1.9,1l1.8,0.1 M239.2,306.7l0.4-26l4.4-16.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M219.1,348.5v-0.1l0.1-0.3l0.3-0.3l0.6,0.1l0.1,0.1 M220.2,348.1l0.1,0.3
	l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6c-0.1,3.9-0.4,6.3-0.8,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M209,349.9l0.6,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3
	c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.6-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.7-4.8
	 M207.9,349.9l0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.3,0,0.4,0.1l0.1,0.3 M239.2,306.7l-0.3,18"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M207.9,349.9v0.3l0.6,5.1c0.8,6.7,1.6,11.6,2.6,14.6
	c1.1,3.7,2.1,6.1,2.6,6.7 M209.6,351.3l1.1,2.6v0.4 M209,349.9l0.7,1.4 M218.6,353.4l-0.1-0.5l0.4-2.6 M209.6,351.3
	c2.9-0.8,5.9-1.2,9.1-1.2h0.3 M210.7,354.3l1.6,11.6c0.6,2.2,1.4,3.2,2.6,2.9l3.2-0.6c1.1,0,1.8-1.2,1.8-3.4l-1.4-11.4 M210.7,354.3
	c2.5-0.7,5-1,7.7-1 M225.5,354.2v-0.8c0-0.8-0.7-1.8-1.9-2.9 M221.5,375.4c1.4-1.6,2.3-3.9,2.9-6.5c0.8-4.1,1.2-9.1,1.1-14.7
	 M246.7,362.4h-0.3l-4.5,2.2c-6.2,2.9-10.6,5.2-13.1,7c-3.2,2.2-5.1,3.7-5.6,4.5 M228.5,376.6c0.6,0.4,1,1,1.1,1.8
	c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.6-1.9c0.4-0.5,1-1,1.6-1.1
	S227.9,376.2,228.5,376.6L228.5,376.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M228.9,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7
	c-0.6,0.1-1,0-1.4-0.3s-0.7-0.7-0.8-1.2s0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.6-0.1,1,0,1.4,0.4
	C228.5,377.6,228.8,378,228.9,378.4L228.9,378.4z M223.1,376.2l-0.3,0.5c-0.4,0.8-0.6,1.6-0.3,2.8c0.1,0.8,0.6,1.7,1.1,2.3l0.3,0.3
	c0.7,0.5,1.4,1,2.1,1.1l0.7,0.1 M223.1,376.2c0.7-2.1,1.9-4,3.9-5.9c3-3,6.9-5.9,11.8-8.9 M246.8,373.7l-10.6,5
	c-2.2,0.7-3.4,0.5-3.9-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.1,363.5l-4.4,2.1c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3
	c-0.4,0.5-0.6,1.2-0.6,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.6,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8
	l4.3-2.2 M220.7,390.6l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4c0.4-0.8,1.1-1.4,1.8-1.8c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M227.4,388.4c0.3,0.7,0.4,1.4,0.1,2.1c-0.3,0.7-0.7,1.2-1.4,1.5
	c-0.7,0.3-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4s-0.4-1.4-0.1-2.1c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1
	C226.6,387.3,227.1,387.7,227.4,388.4L227.4,388.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M245.7,403.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3
	c-2.9-2.2-4.8-3.4-5.6-3.7c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3c-0.6,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.6,1.6-0.4,2.5
	c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1l3.3,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.6,387.7c0.6,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5
	c-0.1,0.6-0.6,0.8-1,1.1c-0.6,0.3-1,0.3-1.5,0.1s-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1
	C224.6,387.6,225.2,387.6,225.6,387.7L225.6,387.7z M226.3,385.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5 M248,380.5
	c-5.4,1.9-10.2,3-14.4,3.6c-2.6,0.3-5,0-7-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226.7,383.3c1,0.1,3.3-0.4,7.2-1.5c2.9-0.8,7.4-2.8,13.6-5.8l4.5-2.2
	l0.1-0.1 M212.4,377.7c-0.8-0.7-3-1.6-6.6-3c-3-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M193.4,367.4c5.5,1.7,10,3.6,13.8,5.6c2.3,1.4,4,3,5.2,4.7 M212.4,377.7
	l0.4,0.4c0.6,0.5,0.8,1.2,1,2.1v0.4c0.1,1-0.1,1.8-0.4,2.5c-0.4,1-1.1,1.6-1.8,2.1l-0.6,0.3 M215.8,371.4c0.6-0.4,1.2-0.5,1.9-0.4
	c0.7,0.1,1.4,0.4,1.8,1.1c0.4,0.5,0.6,1.2,0.4,1.9s-0.4,1.2-1.1,1.8c-0.6,0.4-1.2,0.5-1.9,0.4c-0.7-0.1-1.4-0.4-1.8-1.1
	c-0.4-0.5-0.6-1.2-0.6-1.9C214.9,372.5,215.1,371.8,215.8,371.4L215.8,371.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M216.2,372.1c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8
	c0.3,0.4,0.4,1,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3c-0.6-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5
	C215.6,372.8,215.7,372.3,216.2,372.1L216.2,372.1z M213.6,376.5l0.4,0.4c0.7,0.5,1.5,1,2.5,1.1c0.8,0.1,1.6,0,2.5-0.3l0.4-0.1
	c0.7-0.4,1.4-1,1.6-1.6l0.3-0.5 M216.1,380.3c0.7-0.7,1.5-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6
	s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C215,381.8,215.4,381,216.1,380.3
	L216.1,380.3z M211.8,387.2c0.1-0.1,0.4-0.1,0.6-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.6,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3
	v0.6 M207.6,381.8c-0.1-0.5-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1c0.6-0.1,1-0.1,1.5,0.1c0.4,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.5
	c-0.3,0.5-0.6,0.8-1.1,1l-1.5-0.1C208,382.7,207.7,382.2,207.6,381.8L207.6,381.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.9,382c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4
	c0.7-0.3,1.4-0.1,2.1,0.1c0.7,0.3,1.1,0.8,1.4,1.5c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4c-0.7,0.3-1.4,0.1-2.1-0.1
	C207.6,383.2,207,382.7,206.9,382L206.9,382z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M184.2,381.8l5,1c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4
	c0.7-0.3,1.2-0.7,1.6-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2c-0.7-0.6-2.8-1.5-6.2-2.8
	c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8 M207,415.1l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6c0-0.7-0.3-1.4-0.7-1.9
	l-0.3-0.3c-0.4-0.4-1-0.7-1.6-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4
	 M213.6,376.5c-1.6-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14 M246.7,362.4h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4
	l-0.1,0.3 M247.1,363.5l-1.2,1 M238.8,361.3l0.7-0.4c0.7-0.5,1.8-0.4,3.6,0.1 M243.4,366.7l0.3-0.3l2.2-1.9 M223.5,350.5
	c7,1,13.1,4,18.3,9.2l1.2,1.2 M243.1,361.1c1,0.3,2.2,0.8,3.6,1.5 M250,373l-2.9,0.4l-0.3,0.1 M251.3,372.8h0.4
	c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1 M250,373l1.4-0.3 M252,373.7c-0.4,1.5-0.7,2.8-1.1,3.7 M248,380.5l0.7-0.3
	c0.8-0.3,1.5-1.2,2.2-2.9 M245.4,395.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.9,377.3c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6
	 M243.4,366.7c1.5,2.2,2.6,4.5,3.4,7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M255,382.9c0,10-3.6,18.6-10.7,25.7s-15.7,10.6-25.7,10.6
	c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6
	c10,0,18.6,3.6,25.7,10.6S255,372.9,255,382.9L255,382.9z M245.8,364.5c1.8,2.6,3.2,5.4,4.1,8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M245.4,395.5l0.6,0.4c0.7,0.6,1,1.8,1,3.7 M246.7,403.2l-0.1-0.1l-3.3-3.7
	c-4.7-5.1-8.3-8.5-10.7-10.3c-3.2-2.3-5.1-3.7-6.1-3.9 M246.9,399.6c0,1-0.1,2.2-0.3,3.6 M246.7,403.2v0.3c0,0.1-0.1,0.3-0.3,0.4
	s-0.3,0.1-0.4,0.1h-0.3 M241.7,401.4c-0.7,0.8-1.4,1.6-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3 M241.7,401.4l0.3,0.3l2.5,1.5 M244.4,403.2
	c-0.8,1-1.6,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6 M245.7,403.8l-1.2-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.9,403.6c-1.9,4.1-4.4,8.1-7.4,11.7c-1,1.1-2.1,2.2-3,3.3
	c-5.5,5.5-11.7,9.5-18.6,12c-5.4,1.9-11,2.9-17.2,2.9c-6.1,0-11.8-1-17.2-2.9c-6.9-2.5-13.1-6.5-18.6-12c-2.6-2.6-4.8-5.2-6.6-8
	c-2.8-4-4.7-8.4-6.1-12.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237.7,411.8l-0.3-0.1l-3.6-3.6c-4.7-5-8-8.7-9.6-11.1
	c-2.2-3.2-3.4-5.4-3.6-6.3 M238.4,410.9c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3 M237.7,409.6
	l0.7,1.2 M212.3,391c0-0.5,0.1-1,0.6-1.4c0.4-0.4,0.8-0.5,1.4-0.5s1,0.1,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4
	c-0.4,0.4-0.8,0.6-1.4,0.6s-1-0.1-1.4-0.5C212.5,392,212.3,391.6,212.3,391L212.3,391z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.3,389.3c0.6-0.5,1.1-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8
	c0.6,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9c-0.6,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8s-0.8-1.1-0.8-1.9
	C211.6,390.4,211.8,389.7,212.3,389.3L212.3,389.3z M201.3,406.7l5.6-10c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9
	l-5.5,10.3 M207.9,415.8l0.1-0.1l2.5-4.4c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.7,1.9-6.1,1.8-7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M218.9,390.9c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2
	 M236.1,406.7l-8-8.5c-1.4-1.9-1.6-3.2-0.7-3.9l2.2-2.2c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4 M237.7,409.6l-1.4-2.5l-0.3-0.4 M234,412
	c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6 M208.1,410.4l-0.1,0.4l-0.7,2.9 M207,415.1l0.3-1.4 M207.9,415.8c0,0-0.1,0.1-0.3,0.1l-0.4-0.1
	l-0.3-0.3v-0.3 M211.6,415c-1,0.3-2.2,0.7-3.7,1 M215,412.1l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M215,412.1l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2 M234,412
	c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230,410.2c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9
	 M196.9,409.9v-0.3l2.3-4.5c3.2-5.9,5.8-10.2,7.7-12.5c2.3-3,4-4.8,4.8-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M196.2,401.9c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4
	 M238.8,361.3c-3.9-3.7-8.4-6.1-13.3-7.2 M237.7,411.8c-1.5,0.1-2.8,0.1-3.7,0.1 M146.8,352.1c-1.4,3.7-2.5,7.6-3.3,11.6
	c-1.8,6.1-5.1,9.2-9.8,9.5h-12.5c-2.3,0-3.7-1.4-4.3-3.9c-2.5-5.9-4.8-7.8-6.9-5.9l-1.4,5.2 M167.7,352.1l-0.6,0.8
	c-5.4,9.4-8.3,19.8-8.5,31.5v9.1 M146.8,352.1h20.9 M204.3,357.1l-0.1-0.8c-0.3-0.8,0.1-1.9,1.2-3.4 M189.3,368.2
	c1.5-1,2.6-1.2,3.4-1l0.8,0.3 M204.3,357.1c-2.3,1.4-4.5,3-6.5,5c-1.6,1.7-3.2,3.6-4.4,5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M189.3,368.2c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9 M190.5,373.9
	l-0.6-0.1l-2.5-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M189.1,381.4l11.4,1.9c2.3,0.1,3.6-0.4,3.6-1.5l0.6-3.2
	c0.3-1.1-0.6-2.1-2.8-2.8l-11.4-2.2 M186.1,371.8H186l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1 M187.5,372.5
	l-1.4-0.7 M183.9,382.9l0.3,0.1l5.1,1c6.7,1.2,11.6,1.9,14.6,2.1c3.9,0.1,6.3,0,7.3-0.4 M183.9,382.9l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1 M185.9,381.7l2.8-0.3h0.6 M189.6,388.4h-0.8c-0.8,0-1.8-0.7-2.9-2.1
	 M184.2,381.8l1.6-0.1 M186,386.2c-0.7-0.8-1.4-1.9-2.1-3.3 M185.9,381.7c0.1-3.2,0.7-6.3,1.6-9.2 M190.5,373.9
	c-0.8,2.5-1.2,5-1.4,7.7 M186.1,370.5c1.2-1,2.2-1.8,3.2-2.3 M199.1,409.2l1.9-1.9l0.3-0.6 M195.6,406.2c-0.4-1.6-0.4-2.9,0.1-3.4
	l0.6-0.7 M195.6,406.2c-5.6-5.6-8.8-12.2-9.5-19.9 M198.1,410.3H198v0.1l-0.3,0.1h-0.4l-0.4-0.4v-0.3 M199.1,409.2l-1,1.1
	 M196.9,409.9c-0.6-1.5-1.1-2.8-1.4-3.9 M158.6,393.5v3.4 M158.6,397l12.5,0.7 M158.6,397l-7.4-0.4l-44-8.4c-2.6-0.6-4-1.4-3.9-2.3
	c0.4-1.5,2.3-1.9,6.2-1.5l1.4,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M211,385.5c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3
	 M189.6,388.4c1,5.1,3,9.6,6.6,13.6 M208.1,410.4c-2.5-1-4.7-2.2-6.9-3.7 M207.3,413.7c-2.9-1.1-5.6-2.6-8.3-4.5 M205.4,352.8
	c0.6-0.8,1.5-1.9,2.5-3 M218.9,350.2l0.3-1.6 M223.5,350.5c-0.8-0.7-1.9-1.5-3.3-2.3 M272.4,293.6L255,293 M255.6,288.3l16.8,0.8
	 M277.3,403.7l-11.4-0.1 M257.7,262.3l109.8,4.5 M139.6,259.5l0.1,0.1c0.3,0.3,0.6,0.4,1,0.4l10.7,0.4 M139.6,259.5
	c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.7-0.4,1-0.4 M123.9,334.4c-5.5-23-5.5-42.8,0.3-59c0.6-1.1,1.1-2.1,1.8-3
	l2.2-3.2c3.2-4.3,7-7.4,11.4-9.5 M139.9,272.5c-0.7,0.7-1.1,1.5-1.5,2.5v0.1 M138.4,275.1l0.8-1.2 M139.9,272.5l-0.7,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M134.7,302.7l0.3-5.1c0.8-11.1,1.9-18.7,3.4-22.7 M134.8,314.4v11.3
	 M123.3,334.9l0.7-0.5 M123.9,334.4l10.9-8.7 M123.9,334.4c-3.4,3.9-8.3,5.6-14.6,5.4c-2.2-0.1-3.6,0.5-4,1.9l-1.1,5.1
	c1.9,0.7,2.9,2.1,2.9,4.1c-0.1,7.8,0.4,13.8,1.4,17.6 M134.7,302.7c-0.3,4.8-0.3,8.7,0.1,11.6 M110.9,384.3
	c-0.7-5.9-1.9-9.2-3.7-10.2c-1.8-1.4-1.2-3.3,1.5-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M108.7,368.6c2.3-1.1,4.4,1.1,6.3,6.7c0,0.7,0.1,1.2,0.4,1.9 M110.9,384.3
	l2.8,0.3 M113.6,384.6l9.2,1.2 M113.6,384.6c-0.1-4.3,0.4-6.7,1.5-7.6 M115.3,377.2c1,3.4,3.4,6.3,7.6,8.7 M122.8,385.8
	c7.2,4.1,19.1,6.7,35.8,7.7 M154.9,258l-14-0.5 M134.8,325.7h30.4 M140.9,257.5c12.8-17.7,24.9-32.5,36.5-44.2 M663.9,285.4l1.5,0.3
	c15.3,3.6,25.9,7.4,31.6,11.6c3.3,2.3,5.6,5.6,6.9,9.8c1.5,6.5,1.8,11.4,1,14.9c-0.3,3-2.5,4.5-6.5,4.5c-6.2,0.1-11.1-1.2-15-4.1
	c-10.7-8.3-19.1-16.1-25.6-23.7c-3.9-4.3-5-7.8-3.3-10.7C656,285.5,659.1,284.7,663.9,285.4L663.9,285.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M670.9,288.6c11.8,2.9,20.1,6.1,24.9,9.5c3.2,2.2,5.4,5.2,6.6,8.9
	c1,3.7,1.4,7,1.5,9.8v0.3c0,1.5-0.1,2.6-0.4,3.7c0,0.4-0.1,0.8-0.3,1.2c-1.1,1.9-3.7,2.5-7.8,1.8c-4-0.5-8.1-2.6-12.2-5.9
	c-8.3-6.5-14.9-13.1-19.7-19.4c-2.6-3.4-3-6.2-1.1-8.3C664,288.1,666.8,287.7,670.9,288.6L670.9,288.6z M596.2,308.2
	c7.6-2.5,15.7-3.6,24.3-3.6c20.9,0,38.7,7,53.5,20.9c1.1,1,2.1,1.9,3,2.9 M573.4,400.8c-2.1-5.5-3-11.4-3-17.9
	c0-10.3,2.8-19.5,8.1-27.6c1.9-2.8,4.1-5.5,6.6-8c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1c11,0,20.6,3,29.2,9.1
	c2.3,1.6,4.5,3.6,6.6,5.6c1.1,1.1,2.2,2.2,3,3.3c7.8,9.2,11.7,19.9,11.7,32.3c0,7-1.2,13.6-3.8,19.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M598.1,272.7c12.4,0,24.2,0.1,35.4,0.5c33.7,2.6,59,8.3,76.1,16.9
	c9.5,5.1,15.1,9.5,16.9,13.1c1.1,2.2,0.7,3.7-1.2,4.4l-0.8,0.1 M725.8,334.1h1.4c2.1,0,2.8,1.2,2.2,3.7c-0.8,4,0.4,6.3,3.7,7
	c3.9,0,6.1,2.2,6.3,6.3v12.5c0,2.1-1,3.4-2.6,4.1 M725.8,334.1h-2.9c-1.2,0-2.1,0.7-2.6,2.2c-0.3,0.7-0.7,1.2-1.2,1.6
	c-0.7,0-1.2-0.3-1.7-0.7 M716.8,332.2v2.5c-0.1,1.1,0,1.9,0.4,2.5 M717.4,337.1l1.1-2.5c0.8-1.8,2.3-2.6,4.3-2.6 M722.9,332.2
	c2.3,0.1,3.3,0.8,2.9,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M716.8,332.2c0-5.1,0.8-11.3,2.3-18.3c1-3.6,2.6-5.6,5.1-6.2 M724.3,307.7
	c0.5,9.9,0,18-1.5,24.3 M731.7,391l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-13.2c0-2.9,1.2-4.5,3.6-4.7h3.2 M734.6,368.3
	c0.8-0.1,1.5-0.3,2.1-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M734,391.6l-1.7-0.3l-1.9-0.5l-1-0.4c-1.9-1-2.8-2.5-2.8-4.3v-5
	c-0.1-1.9-1-3-2.5-3.3l-21.9-1.9c-4.3,0-6.5-1.9-6.5-5.6c-0.1-2.5-0.5-5-1-7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M731.7,391c1.4-4.1,2.3-8.3,2.9-12.4c0.4-3.4,0.4-6.9,0.1-10.3
	 M733.6,391.6l-1.9-0.5 M733.6,391.6h0.4 M734,391.6l2.3,0.3c1.2,0.5,1,1.9-0.8,4.3c-1.7,1.6-5.2,2.9-10.9,3.7
	c-7.2,1-16.1,1.6-26.7,1.9 M736.7,367.8c0.8,7.7-0.1,15.7-3,23.8 M558.4,384.4c0.3-11.7,3.2-22.1,8.7-31.5c2.6-4.4,5.8-8.5,9.5-12.5
	c3.2-3.3,6.5-6.1,9.9-8.5c9.9-7,21.3-10.5,34-10.5c17.2,0,31.8,6.3,43.9,19c4.5,4.8,8.3,9.9,11,15.4c1.1,2.2,2.2,4.5,3,6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M648.2,363.5l-4.4,2.1c-6.2,2.9-10.6,5.2-13.1,7c-3,2.1-4.8,3.6-5.4,4.3
	c-0.4,0.5-0.5,1.2-0.5,2.1v0.4c0.1,0.5,0.4,1.1,0.8,1.6c0.5,0.7,1.4,1.1,2.2,1.1c1,0.1,3.2-0.4,6.6-1.4c2.9-0.8,7.6-2.8,13.6-5.8
	l4.3-2.2 M652.6,372.8h0.4c0.1,0,0.3,0.1,0.3,0.3s0.1,0.3,0,0.4l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M653.3,373.7l-0.1,0.1l-4.5,2.2c-6.2,3-10.7,5-13.6,5.8
	c-3.7,1.1-6.2,1.5-7.2,1.5 M652.6,372.8l-1.4,0.3 M653.3,373.7c-0.4,1.5-0.7,2.8-1.1,3.7 M649.3,380.5l0.7-0.3
	c0.8-0.3,1.5-1.2,2.2-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M652.2,377.3c0.3,1.8,0.4,3.6,0.4,5.5c0,6.2-1.5,11.7-4.4,16.6
	 M677.1,328.5c6.9,7.2,11.8,15,15,23.7c1.2,3.4,2.2,7,2.9,10.6 M698.1,401.9c-0.3,4.4-1.9,7.2-5.2,8.5l-7.7,2.6
	c-2.3,0.4-3.6,0.1-3.6-1v-0.3c0.3-3,0.5-6.2,0.7-9.5 M682.5,402.2c5.6,0,10.9-0.1,15.7-0.3 M667.6,402.6c-1.9,4.5-4.5,8.8-7.8,12.8
	c-1,1.1-2.1,2.2-3,3.3c-5.5,5.5-11.7,9.5-18.6,12c-5.4,1.9-11,2.9-17.2,2.9c-6.1,0-11.8-1-17.2-2.9c-6.9-2.5-13.1-6.5-18.6-12
	c-2.6-2.6-4.8-5.2-6.6-8c-2.1-3.2-3.7-6.5-5.1-9.9 M678.5,362.7c2.3,6.5,3.7,13.3,4,20.8c0.3,6.7,0.3,12.9,0,18.7 M695,362.7h-16.5
	 M682.5,402.2l-14.9,0.4 M677.1,328.5l39.8,3.7 M502,210.7l5.2,4l68.9,52.3l13.3,5.9 M508.2,258.3c1,0.4,1.9,1,2.9,1.5l0.5,0.4
	c2.6,1.8,4.1,3.9,4.7,6.3 M516.3,266.5c0.1,0.7,0.3,1.2,0.3,1.9c0,0.8-0.1,1.5-0.3,2.3 M491.2,271.9c-0.3-1.1-0.4-2.2-0.4-3.4
	c0-3.2,0.8-5.9,2.6-8.3c1.8-2.3,3.9-3.4,6.5-3.4h1.5 M501.4,256.9l1.2,0.1c1.9,0.3,3.9,0.7,5.5,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M501.4,280.2c-2.2-0.3-4-1.4-5.5-3.4c-0.4-0.5-0.8-1.2-1.2-1.9
	c-0.4-0.8-0.8-1.8-1.1-2.8c-0.3-1.1-0.4-2.3-0.4-3.6c0-3.2,0.8-5.9,2.6-8.3c1.5-2.1,3.4-3.2,5.5-3.3 M470.2,210.7H502 M526.7,273.4
	l-5.1-5.2c-19.3-19.9-39.9-38.9-61.9-57.1c-0.3-0.1-0.5-0.3-0.8-0.3 M396.5,192.8c19,0.4,36.2,1.2,51.6,2.6l18.7,1.9
	c15.7,2.6,27.5,7,35.4,13.5 M458.9,210.7h11.3 M397.1,268.1l94.1,3.9 M381.2,210.6c27.5,0.4,53.2,1.2,77.2,2.9
	c17.5,14.4,34.1,29.4,49.8,44.8 M518.1,275.8l11.3,0.4h0.6 M529.9,276.2l-3.2-2.8 M536,275.9l-6.1,0.3 M549.4,288.4
	c-0.3,0.4,0,1,0.8,1.8l14.4,12.8c0.8,0.7,1.9,1.4,3.3,1.9c1.4,0.6,2.5,0.7,3.4,0.7s1.5-0.3,1.7-0.8c0-0.5-0.3-1.1-1.1-1.8
	l-13.1-11.7c-0.8-0.7-1.8-1.4-3.2-1.9c-1.4-0.7-2.8-1.1-3.9-1.2C550.4,287.9,549.7,288,549.4,288.4L549.4,288.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M545,287.3c0.1,0.4,0.4,0.8,1,1.4l16.2,15.7c2.3,2.2,5.2,3.3,8.5,3.2
	l10-0.4c3-0.1,3.3-1.2,0.8-3.3L564.6,289c-1.9-1.8-4.4-2.6-7.3-2.6l-9.4-0.3c-1.8,0-2.8,0.1-2.9,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M580.8,315.1l-5.1-4.5l-0.3-0.3c-1.5-1.1-3.6-1.5-6.3-1H569
	c-1.7-0.1-3.4-0.8-5.1-1.9c-10.5-8.9-16.8-15.4-19-19.4v-0.7 M545,287.3v-0.4 M545,286.9v-3c-0.3-1.2-1-2.2-1.9-3.2l-7-4.8
	 M550,275.2l-14,0.7 M526.7,273.4l13.8-0.1 M580.8,315.1c-4.8,2.9-9.4,6.3-13.6,10.5l-2.3,2.2c-3.6,3.6-6.7,7.4-9.2,11.4
	c-5.1,7.8-8.5,16.5-10,26c-1.2,7-2.1,13.2-2.5,19 M529.9,276.2c8.7,4.4,13.1,20.8,13.1,49.1v0.3l-0.1,1c-1.1,23.5-2.8,42.6-5.4,57.6
	 M540.5,273.3l29-0.1c6.9-0.1,13.5-0.3,19.9-0.4 M589.5,272.9l8.7-0.1 M620.3,348.5v-0.1l0.1-0.3c0.1-0.1,0.3-0.3,0.4-0.3
	c0.1,0,0.4,0,0.5,0.1l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M621.4,348.1l0.1,0.3l0.7,5.1c1,6.7,1.4,11.6,1.2,14.6
	c-0.1,3.9-0.4,6.3-0.8,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M610.2,349.9l0.5,4.8c0.8,6.7,1.8,11.7,2.6,14.6c1.1,3.6,1.9,5.6,2.5,6.3
	c0.4,0.5,1.1,1,1.8,1.1l0.4,0.1c0.6,0,1.2,0,1.8-0.3c0.8-0.4,1.5-1,1.8-1.8c0.4-0.8,0.5-3.2,0.7-6.7c0.1-3-0.3-8-1.2-14.7l-0.7-4.8
	 M609.1,349.9l0.1-0.1l0.4-0.3c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.3 M594.7,367.4c1.2-1.9,2.8-3.7,4.4-5.4
	c2.1-2.1,4.1-3.7,6.5-5 M590.4,368.2c1.5-1,2.6-1.2,3.4-1l0.8,0.3 M590.4,368.2c1.5-3,3.6-5.9,6.2-8.5c3-3,6.3-5.2,9.9-6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.1,349.9v0.3l0.5,5.1c0.8,6.7,1.7,11.6,2.6,14.6
	c1.1,3.7,2.1,6.1,2.6,6.7 M605.6,357.1l-0.1-0.8c-0.3-0.8,0.1-1.9,1.2-3.4 M606.7,352.8c0.5-0.8,1.5-1.9,2.5-3 M610.9,351.3l1.1,2.6
	v0.4 M610.2,349.9l0.7,1.4 M612,354.3l1.7,11.6c0.6,2.2,1.4,3.2,2.6,2.9l3.2-0.6c1.1,0,1.8-1.2,1.8-3.4l-1.4-11.4 M585.5,381.8l5,1
	c6.7,1.2,11.6,1.9,14.6,2.1c3.6,0.1,5.9,0,6.7-0.4c0.7-0.3,1.2-0.7,1.7-1.4l0.1-0.4c0.3-0.5,0.3-1.1,0.3-1.8c-0.1-1-0.4-1.6-1.1-2.2
	s-2.8-1.5-6.2-2.8c-2.9-1.1-7.7-2.2-14.4-3.3l-4.8-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M590.4,381.4l11.4,1.9c2.3,0.1,3.6-0.4,3.6-1.5l0.5-3.2
	c0.3-1.1-0.5-2.1-2.8-2.8l-11.4-2.2 M617.4,372.1c0.4-0.3,0.8-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8c0.3,0.4,0.4,1,0.3,1.4
	c-0.1,0.5-0.3,1-0.8,1.2c-0.4,0.3-1,0.4-1.4,0.3c-0.5-0.1-1-0.3-1.2-0.7c-0.3-0.4-0.4-1-0.4-1.5C616.7,372.8,617,372.3,617.4,372.1
	L617.4,372.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617,371.4c0.5-0.4,1.2-0.5,1.9-0.4c0.7,0.1,1.4,0.4,1.8,1.1
	c0.4,0.5,0.5,1.2,0.4,1.9c-0.1,0.7-0.4,1.2-1.1,1.8c-0.5,0.4-1.2,0.5-1.9,0.4s-1.4-0.4-1.8-1.1c-0.4-0.5-0.5-1.2-0.5-1.9
	C616,372.5,616.4,371.8,617,371.4L617,371.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.9,376.5l0.4,0.4c0.7,0.5,1.5,1,2.5,1.1c0.8,0.1,1.7,0,2.5-0.3
	l0.4-0.1c0.7-0.4,1.4-1,1.7-1.6l0.3-0.5 M614.9,376.5c-1.7-1.2-3.2-3-4.4-5.5c-1.9-3.7-3.4-8.4-4.8-14 M612.3,385.5l0.5-0.3
	c0.8-0.4,1.4-1.1,1.8-2.1c0.4-0.7,0.5-1.5,0.4-2.5v-0.4c-0.1-0.8-0.5-1.5-1-2.1l-0.4-0.4 M611.5,379.5c0.4,0.3,0.8,0.5,1,1.1
	c0.1,0.5,0.1,1-0.1,1.5c-0.3,0.5-0.5,0.8-1.1,1l-1.5-0.1c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.5-0.1-1,0.1-1.5c0.3-0.4,0.7-0.8,1.1-1
	C610.5,379.2,611.1,379.2,611.5,379.5L611.5,379.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M613.1,380.3c0.3,0.7,0.1,1.4-0.1,2.1c-0.3,0.7-0.8,1.1-1.5,1.4
	s-1.4,0.1-2.1-0.1c-0.7-0.3-1.1-0.8-1.4-1.5c-0.3-0.7-0.1-1.4,0.1-2.1s0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1
	C612.4,379.2,613,379.6,613.1,380.3L613.1,380.3z M617.2,380.3c0.7-0.7,1.5-1.1,2.6-1.1c1.1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6
	s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6C616.3,381.8,616.6,381,617.2,380.3
	L617.2,380.3z M613.1,387.2c0.1-0.1,0.4-0.1,0.6-0.3c0.7-0.3,1.5-0.4,2.3-0.3h0.4c0.8,0.1,1.7,0.5,2.2,1.2c0.8,0.7,1.2,1.5,1.4,2.3
	v0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M608.2,415.1l2.3-4.3c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.6,1.8-5.8,1.8-6.6
	c0-0.7-0.3-1.4-0.7-1.9l-0.3-0.3c-0.4-0.4-1-0.7-1.7-0.8c-1-0.1-1.8-0.1-2.5,0.4c-0.8,0.4-2.3,2.2-4.5,5.1
	c-1.9,2.3-4.4,6.6-7.6,12.7l-2.2,4.4 M613.5,377.7c-1.2-1.8-2.9-3.3-5.2-4.7c-3.7-2.1-8.3-3.9-13.8-5.6 M612.3,385.5
	c-1.8,1.2-4,2.1-6.6,2.6c-4.1,0.5-9.1,0.7-14.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M585.2,382.9l0.3,0.1l5.1,1c6.7,1.2,11.6,1.9,14.6,2.1
	c3.9,0.1,6.3,0,7.3-0.4 M613.5,377.7c-0.8-0.7-3-1.6-6.6-3c-2.9-1.1-7.7-2.2-14.3-3.3l-5.1-0.8h-0.3 M587.4,371.8h-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4h0.1 M588.8,372.5l-1.4-0.7 M591.7,373.9l-0.4-0.1l-2.5-1.2 M587.1,381.7l2.8-0.3
	h0.5 M590.8,388.4H590c-0.8,0-1.8-0.7-2.9-2.1 M591.7,373.9c-0.8,2.5-1.2,5-1.4,7.7 M587.1,381.7c0.1-3.2,0.7-6.3,1.7-9.2
	 M587.4,370.5c1.2-1,2.2-1.8,3.2-2.3 M619.7,353.4v-0.5l0.4-2.6 M610.9,351.3c2.9-0.8,5.9-1.2,9.1-1.2h0.3 M626.7,354.2v-0.8
	c0-0.8-0.7-1.8-1.9-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M622.8,375.4c1.4-1.6,2.3-3.9,2.9-6.5c0.8-4.1,1.2-9.1,1.1-14.7
	 M624.7,350.5c7,1,13.1,4,18.3,9.2l1.2,1.2 M647.9,362.4h-0.3l-4.5,2.2c-6.2,2.9-10.6,5.2-13.1,7c-3.2,2.2-5.1,3.7-5.6,4.5
	 M647.9,362.4h0.3c0.1,0.1,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-0.1,0.3 M644.5,366.7l0.4-0.3l2.2-1.9 M644.2,361.1
	c1,0.3,2.2,0.8,3.6,1.5 M647.1,364.5l1.1-1 M639.9,361.3l0.7-0.4c0.7-0.5,1.8-0.4,3.6,0.1 M651.2,373l-2.9,0.4l-0.4,0.1
	 M646.7,395.5c1.8-3.9,2.8-8,2.8-12.5l-0.1-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.8,383.3c1.9,0.7,4.3,1,7,0.8c4.3-0.5,9.1-1.6,14.4-3.6 M647.9,373.7
	l-10.6,5c-2.2,0.7-3.4,0.5-3.8-0.5l-1.5-2.9c-0.7-1,0-2.1,2.1-3.3l10.5-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M624.4,376.2l-0.3,0.5c-0.4,0.8-0.5,1.6-0.3,2.8c0.1,0.8,0.5,1.7,1.1,2.3
	l0.3,0.3c0.7,0.5,1.4,1,2.1,1.1l0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M630.9,378.4c0.1,0.7,0,1.4-0.4,1.9s-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4
	s-1-1-1.1-1.8c-0.1-0.7,0-1.4,0.5-1.9c0.4-0.5,1-1,1.7-1.1c0.7-0.1,1.4,0.1,1.9,0.5C630.5,377,630.7,377.6,630.9,378.4L630.9,378.4z
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M630.2,378.4c0.1,0.5,0,1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.7
	c-0.6,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.6,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8c0.5-0.1,1,0,1.4,0.4
	C629.8,377.6,630,378,630.2,378.4L630.2,378.4z M628.7,388.4c0.3,0.7,0.4,1.4,0.1,2.1s-0.7,1.2-1.4,1.5s-1.4,0.4-2.1,0.1
	s-1.2-0.7-1.5-1.4s-0.4-1.4-0.1-2.1c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1C627.8,387.3,628.4,387.7,628.7,388.4
	L628.7,388.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M621.9,390.6l-0.1-0.7c-0.1-0.7,0.1-1.5,0.4-2.3l0.1-0.4
	c0.4-0.8,1.1-1.4,1.8-1.8c1-0.5,1.8-0.7,2.6-0.5l0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M647,403.8l-3.3-3.6c-4.7-5.1-8.3-8.5-10.7-10.3c-2.9-2.2-4.8-3.4-5.6-3.7
	c-0.7-0.1-1.4-0.1-2.1,0.1l-0.4,0.3c-0.5,0.3-1,0.7-1.2,1.2c-0.4,0.8-0.5,1.6-0.4,2.5c0.1,1,1.4,2.9,3.4,5.9c1.8,2.6,5,6.3,9.6,11.1
	l3.3,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626.9,387.7c0.5,0.1,0.8,0.4,1.1,1c0.3,0.4,0.3,1,0.1,1.5
	c-0.1,0.6-0.5,0.8-1,1.1c-0.5,0.3-1,0.3-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.4c0.1-0.5,0.4-0.8,1-1.1
	C625.9,387.6,626.5,387.6,626.9,387.7L626.9,387.7z M624.4,376.2c0.7-2.1,1.9-4,3.9-5.9c3-3,6.9-5.9,11.8-8.9 M644.5,366.7
	c1.5,2.2,2.6,4.5,3.4,7 M626.7,354.2c5,1.1,9.4,3.4,13.3,7.1 M627.6,385.1c2.1,0,4.4,0.7,6.9,1.8c3.7,1.9,7.8,4.8,12.2,8.5
	 M609.1,415.8l0.1-0.1l2.5-4.4c3.3-6.1,5.5-10.5,6.5-13.3c1.2-3.7,1.9-6.1,1.8-7 M639,411.8l-0.3-0.1l-3.6-3.6
	c-4.7-5-8-8.7-9.6-11.1c-2.2-3.2-3.4-5.4-3.6-6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M631.1,410.2c-3.4-4.5-6.1-8.8-7.8-12.7c-1.1-2.5-1.5-4.8-1.5-6.9
	 M620,390.9c0.7,2.1,0.8,4.4,0.4,7c-0.7,4.3-2.2,8.9-4.3,14.2 M637.2,406.7l-8-8.5c-1.4-1.9-1.7-3.2-0.7-3.9l2.2-2.2
	c0.7-1,2.1-0.7,3.9,0.8l8.1,8.4 M646.7,395.5l0.5,0.4c0.7,0.6,1,1.8,1,3.7 M642.8,401.4l0.4,0.3l2.3,1.5 M647.9,403.2v0.3
	c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1H647 M645.6,403.2c-0.8,1-1.7,1.9-2.6,2.9c-1.4,1.4-2.8,2.5-4.1,3.6 M647.9,403.2
	l-0.1-0.1l-3.3-3.7c-4.7-5.1-8.3-8.5-10.7-10.3c-3.2-2.3-5.1-3.7-6.1-3.9 M647,403.8l-1.4-0.7 M648.2,399.6c0,1-0.1,2.2-0.3,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M642.8,401.4l-2.2,2.3c-1.1,1.1-2.2,2.1-3.4,3 M638.8,409.6l-1.2-2.5
	l-0.4-0.4 M639.5,410.9c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3 M638.8,409.6l0.7,1.2
	 M639,411.8c-1.5,0.1-2.8,0.1-3.7,0.1 M635.1,412c-1.9-0.1-3.2-0.5-3.6-1.2l-0.4-0.6 M616.1,412.1l3.7,0.1c4.1,0,7.8-0.7,11.3-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M619.9,419.2c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7
	c0-10,3.6-18.7,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7
	c0,10-3.6,18.6-10.7,25.7C638.6,415.7,629.9,419.2,619.9,419.2L619.9,419.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M635.1,412c-4.5,2.5-9.6,3.7-15.3,3.7c-2.5,0-4.8-0.3-7-0.7 M596.8,406.2
	c-0.4-1.6-0.4-2.9,0.1-3.4l0.5-0.7 M596.8,406.2c-5.6-5.6-8.8-12.2-9.5-19.9 M613.5,389.3c0.5-0.5,1.1-0.8,1.9-0.8
	c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8
	c-0.5-0.6-0.8-1.1-0.8-1.9C612.7,390.4,613,389.7,613.5,389.3L613.5,389.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M615.5,393c-0.5,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4
	c0-0.5,0.1-1,0.5-1.4c0.4-0.4,0.8-0.5,1.4-0.5c0.5,0,1,0.1,1.4,0.5s0.5,0.8,0.5,1.4c0,0.5-0.1,1-0.5,1.4S616,393,615.5,393z
	 M602.5,406.7l5.6-10c1.4-1.9,2.5-2.5,3.4-1.8l2.9,1.5c1.1,0.4,1.2,1.8,0.4,3.9l-5.5,10.3 M600.3,409.2l1.9-1.9l0.3-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.4,410.4l-0.3,0.4l-0.5,2.9 M608.2,415.1l0.4-1.4 M609.1,415.8
	c0,0-0.1,0.1-0.3,0.1l-0.4-0.1l-0.3-0.3v-0.3 M616.1,412.1l-0.3,0.7c-0.3,0.8-1.2,1.5-3,2.1 M612.8,415c-1,0.3-2.2,0.7-3.7,1
	 M598.1,409.9v-0.3l2.3-4.5c3.2-5.9,5.8-10.2,7.7-12.5c2.3-3,4-4.8,4.8-5.4 M600.3,409.2l-1.1,1.1 M599.2,410.3l-0.1,0.1l-0.3,0.1
	h-0.4c-0.1-0.1-0.3-0.3-0.3-0.4v-0.3 M609.4,410.4c-2.5-1-4.7-2.2-6.9-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M597.4,401.9c3.3-4.7,6.5-8.4,9.5-11.3c2.1-1.8,4.1-2.9,6.1-3.4
	 M608.6,413.7c-2.9-1.1-5.6-2.6-8.3-4.5 M598.1,409.9c-0.5-1.5-1.1-2.8-1.4-3.9 M590.8,388.4c1,5.1,3,9.6,6.6,13.6 M612,354.3
	c2.5-0.7,5-1,7.7-1 M537.5,384.2h5.5 M585.2,382.9l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1 M573.4,400.8
	l-15-0.3 M558.4,400.5v-16.1 M543,384.2l15.4,0.3 M587.3,386.2c-0.7-0.8-1.4-1.9-2.1-3.3 M585.5,381.8l1.7-0.1 M620.1,350.2l0.1-1.6
	 M621.4,348.1c1.4,0.8,2.5,1.6,3.3,2.3 M596.2,308.2c-5.4,1.6-10.5,4-15.4,6.9 M598.1,272.7l-48.1,2.5 M550,275.2
	c18,9.8,33.4,20.8,46.2,33 M403.5,292.1c0.3-0.5,0.8-1.2,1.4-1.6c1.4-1.4,3-2.1,5-2.1h3.7c1.9,0,3.6,0.7,5.1,2.1
	c0.8,0.8,1.4,1.6,1.6,2.6 M420.3,293.1h0.5c1.6,0,3,0.3,4.1,0.7c1.1,0.4,1.6,1,1.6,1.5c0,0.5-0.5,1.1-1.6,1.5
	c-1.1,0.4-2.5,0.7-4.1,0.7h-0.5 M420.3,297.5c-0.3,1-1,1.8-1.6,2.6c-1.4,1.4-3,2.1-5.1,2.1h-3.7c-1.9,0-3.6-0.7-5-2.1
	c-1-1-1.6-2.1-1.9-3.2 M420.3,297.5l-17.3-0.5 M403.5,292.1l16.8,1 M492.2,274.7l1.2,2.1c1.6,2.2,3.7,3.3,6.2,3.4 M516.3,270.8
	c-0.1,0.7-0.5,1.5-1,2.2c-0.5,1-1.2,1.8-2.2,2.6l-2.1,1.5c-1.8,1.1-3.6,1.9-5.8,2.5l-2.8,0.4l-1.2,0.1 M499.6,280.2h1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M518.6,277.1l0.5,1.2l3.6,4.7c1.8,2.6,1,4.8-2.3,6.6
	c-5.4,1.6-10.6,1.6-15.7,0c-3.9-1.6-5.5-4.8-5-9.6 M518.1,275.8l0.5,1.4 M516.3,266.5l6.5,6.7l-5.6-0.3 M517.1,273l1,2.8
	 M501.4,280.2c10.9,1.8,16.6,0.8,17.2-3 M470.2,210.7l70.3,62.6 M491.2,271.9c0.3,1,0.5,1.9,1,2.8 M516.3,270.8l0.8,2.2
	 M647.1,364.5c1.8,2.6,3.2,5.4,4.1,8.4 M663.9,285.4c-15-2.6-29.6-4.3-43.6-5.1 M389,381.4l148.4,2.8 M382.7,270.1l109.5,4.5
	 M397.1,268.1l-15-0.7 M381.2,210.6l16,57.5 M458.9,210.7c-27.1-1.8-56.5-2.8-88.3-3 M558.4,400.5l-217.2-1.7"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M695,362.7h-16.6
		c0,0-8.4-26-35.6-37.1c0,0-25-11.6-51.7,3.3c0,0-22.7,12-30,38.1c0,0-2.6,8.5-2.6,17.3l-20.9-0.4c0,0,2.8-16.8,3.9-32.2
		c0,0,1.1-13.5,1.5-25.4l0.1-1c0,0,1.1-42.1-13.1-49.2l20.1-1c0,0,9.6,5,24.8,15.4c0,0,8.4,5.6,21.5,17.6c0,0,10.2-4.1,27.7-3.6
		c0,0,16.5-0.4,34.4,9.2c0,0,14.2,7.2,25.6,22.7C683.7,336.4,692.5,347.7,695,362.7z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M537.5,384.2
		c0,0,2.9-18.8,3.7-31.1c0,0,1.2-16,1.7-26.5l0.1-1c0,0,1.4-41.8-13.1-49.2l-11.8-0.4l1,2.6l3.6,4.7c0,0,1.9,2.3,0.3,4.5
		c0,0-0.8,1.2-2.6,2.1c0,0-4.3,1.5-9.9,1.2c0,0-2.9-0.1-5.8-1.2c0,0-3.6-1.2-4.7-4.8c0,0-0.7-1.7-0.3-4.7c0,0-2.6,0.1-5.1-2.2
		c0,0-1.4-1.4-2.3-3.2l-59.2-2.5l-6.6-0.3l-43.7-1.8c0,0,4.1,9.9,5.9,27.1c0,0,2.2,18.7,2.3,43.5c0,0,0.1,15.8-1.9,40.7l50.1,1
		l56.5,1.1L537.5,384.2z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M516.3,270.8l2.9,7.6
		l3.2,4.3c0,0,2.3,2.3,0.8,4.7c0,0-0.8,1.4-2.9,2.5c0,0-4,1.5-10.3,1.1c0,0-5.5-0.3-8-2.8c0,0-3.2-2.1-2.3-8l0,0
		c0,0-3.3,0.1-6.1-3.3c0,0-2.3-2.6-2.8-7c0,0-0.7-4.8,1.9-8.8c0,0,2.3-4.1,6.1-4.3c0,0,3.7-0.4,8.8,1.4c0,0,5.6,2.1,7.8,6.5
		C515.6,264.6,517.1,267.1,516.3,270.8z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M491.2,271.9
		l-49.1-2.1l-44.8-1.8l-8-28.8l-8-28.8c0,0,22.7,0.1,50.2,1.4c0,0,19.5,1,27,1.5c0,0,15.1,12.4,27.4,23.7c0,0,18.2,16.6,22.4,21.2
		c0,0-6.1-2.3-10.6-1.2c0,0-3.7,1-5.8,6.3C491.7,263.4,490.1,267.2,491.2,271.9z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M389,381.4
		c0,0,1.9-21.3,1.9-43.5c0,0,0.1-18.7-2.9-45.3c0,0-2.1-14.7-5.4-22.4l-74.7-3l-64-2.6l-4.4,16.2l-0.4,26l-0.3,18
		c0,0,15.3,4.1,28.9,22.4c0,0,10.7,13.8,12,32.3l45.9,0.8l46.4,0.8L389,381.4z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M244.7,261.7l9.2-40.2
		c0,0,0.8-3.4,2.3-5l-13.6-0.6l-45-1.9l-4.8,6.7l1.5,2.2l-15.8,27.5l-3.6,1.1l-4.1,7C171,258.7,244.3,261.7,244.7,261.7z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M238.9,324.7
		l0.7-44l4.4-16.2l-50.6-2.1l-42.1-1.8c0,0-7.3,3.6-11.4,11.8c0,0,1.9-2.3,5.5-2.5h6.5c0,0,4.8-0.4,6.1,5.2c0,0,0.7,5.2,0.3,10.7
		c0,0-0.1,4.3-0.7,10c0,0,0.1,8.1-1.9,14.9c0,0-1.2,4.4-3.6,7c0,0-2.5,2.5-8.8,2.3c0,0-6.9,0.4-8.4-5.1l-0.1,10.5h30.4
		c0,0-12.5,10.9-18.3,26.4h20.8c0,0,10.6-18.8,29.6-26.4c0,0,9.2-4.4,23.1-4.1C220.4,321.4,230,321.3,238.9,324.7z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="621"
              cy="382.9"
              r="50.6"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="219.7"
              cy="383.1"
              r="50.5"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M367.4,266.8
		l-55.3-2.3l-40.7-1.7l-13.9-0.5l-3.7-27.9l2.8-12.1c0,0,0.7-5.1,8.5-8.1c0,0,5.1-2.3,17.5-2.6l15.1-0.3l23.5-0.6
		c0,0,20.1-0.4,39.5-0.3l2.6,22.4l2.5,21.2L367.4,266.8z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Crossover4DrRight);
