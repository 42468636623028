import React, { Component } from 'react';

import Button from '../../ui/buttons';
import DropDownMenu from '../../ui/dropDownMenu';
import Icon from '../../ui/icons';

class Card extends Component {
  constructor(props) {
    super(props);
  }

  handleMenuClick(item) {
    switch (item) {
      case 'Gate Pass':
        this.handleGatePass();
        break;
      case 'Send Out':
        this.handleSendOut();
        break;
      case 'Printer':
        this.handlePrint();
        break;
      case 'Delete':
        this.handleDelete();
        break;
      default:
        return null;
    }
  }

  handleGatePass() {
    //
  }
  handleSendOut() {
    //
  }
  handlePrint() {
    //
  }
  handleDelete() {
    //
  }

  render() {
    const { identifier, vehicle } = this.props;
    const menuOptions = ['Gate Pass', 'Send Out', 'Printer', 'Delete'];
    return (
      <div className="sr-card">
        <div>
          <img
            src="https://platform.cstatic-images.com/large/in/v2/50a979b3-e672-5035-b20c-9d5c7b3037e9/60359288-3d7e-42f6-8a5b-b204e0151d83/ttL9BbulaGBmGLeMzkuKRv6ht1s.jpg"
            alt=""
          />
          <div>
            <DropDownMenu
              identifier={identifier}
              toggleLabel={
                <Icon name="HorizontalEllipsis" size={20} color={'#98989E'} />
              }
              menus={menuOptions}
              clickHandler={item => this.handleMenuClick(item)}
            />
          </div>
        </div>
        <div className="sr-card-details">
          <div className="flex items-center justify-between">
            <span>BMW X1 Xdrive28I 2018 Black</span>
            <DropDownMenu
              identifier={identifier}
              toggleLabel={
                <Icon name="HorizontalEllipsis" size={20} color={'#98989E'} />
              }
              menus={menuOptions}
              clickHandler={item => this.handleMenuClick(item)}
            />
          </div>
          <div>
            <div>
              <span>
                Vin: <span>2C4RDGBGXHR789578</span>
              </span>
              <span>
                Mileage: <span>2</span>
              </span>
              <span>
                Dealer name: <span>GSA NEW MARSHALLING</span>
              </span>
              <span>
                Dealer number: <span>G00194</span>
              </span>
            </div>
            <div className="ml-2">
              <span>
                Sale date: <span>n/a</span>
              </span>
              <span>
                Lot number: <span>n/a</span>
              </span>
              <span>
                Gatepassed: <span>True</span>
              </span>
              <span>
                Status: <span>Unavailable</span>
              </span>
            </div>
          </div>
          <div className="sr-card-buttons">
            <Button
              btnText="View"
              height="45px"
              borderRadius="6px"
              outlined={true}
              fontWeight={600}
            />
            <Button
              btnText="Edit"
              height="45px"
              borderRadius="6px"
              fontWeight={600}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
