import { createConfirmation } from 'react-confirm';
import ConfirmDialog from './ConfirmDialog';
import AmountConfirmDialog from './AmountConfirmDialog';

export const confirm = (confirmation, options = {}) => {
  return createConfirmation(ConfirmDialog)({ confirmation, ...options });
};

export const confirmAmount = (confirmation, options = {}) => {
  return createConfirmation(AmountConfirmDialog)({ confirmation, ...options });
};
