import React, { Component } from 'react';
import { connect } from 'react-redux';

class Wagon4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M49.9,367.1c0-1.8,1.2-1.4,1.2-2.8c0-3.2-0.8-1.4-1.1-7.6c0-1.2-0.1-5.1-0.1-6.9c0-0.3,0.4-0.5,0.3-1
	c0-0.3-0.4-0.5-0.4-0.8c-0.1-0.8-0.1-1.5-0.1-1.9c0-0.8,0-4.5-0.1-7.4c0-0.3,0.1-0.5,0.1-0.8s-0.1-0.5-0.1-0.8
	c-0.1-1.1-0.1-10.2-0.1-10.7c0-1.4,1.2-1.5,1.7-2.8c0.3-1.2,5.8-4.3,5.9-4.8c0.1-0.5,1.9-6.3,5.4-12.9c3.4-6.6,5.5-9.2,5.6-9.5
	c0.3-0.5,0.3-1.5-0.7-1.5c-3.3,0-4.4,1.6-5.9,1.6s-3,0.5-3-0.3c0-0.4,0.3-1.2,1-2.2c0.3-0.4,1.5-0.8,1.8-1.2c0.3-0.4-0.3-1,0-1.5
	c7-8.9,39.5-19.8,74.8-26c25.7-4.5,67-8.4,85.4-10.5c6.1-0.7,8.5-1.9,8.7-1.9c0.8,0,9.6,2.1,17.5,2.1c1.2,0,2.1-0.4,3.3-1.1
	c11.3-6.9,70-44.3,108.4-62c0.4-0.1,0.5-0.8,1.1-1c28.1-12.8,80.2-18.7,165.1-18.7c49.8,0,111.7,3.7,154.3,6.9
	c0.5,0,1.1,0.6,1.7,0.6c0.4,0,0.7-0.4,1.1-0.3c1,0.1,2.1,0.1,3,0.3c21.9,1.6,26.4,3.4,26.5,3.4c0.3,0.1,1.7,1.2,1.7,1.8
	c0,1.4-3.3,5.8-3.3,6.2c0,0.3,13.1,9.5,27.8,23.5c16.2,15.4,25.9,28.8,27,29c3.7,0.7,9.2,1.9,9.5,2.1s1.5,2.1,1.5,2.5
	c0,7.2,2.9,14.4,3.4,16c0.5,1.4,1.4,1.5,1.4,2.8v3.9c0,1.2-0.4,1.6-2.5,1.6c-0.7,0-1.2,0-1,0.7c0.3,0.7,1.5,9.1,1.5,18.2
	c0,6.7-0.3,11.4-0.3,12.4s1.5,1.4,2.1,2.9c0.4,1.5,3.4,8.4,5.2,18c0.1,0.8,0.7,1,4,1.5c3.2,0.5,3.6,0.7,3.6,2.5
	c0,2.9,0.4,4.3,0.4,6.3c0,1.2-0.1,4.4-0.1,7.3c0,0.5-0.8,0.5-0.8,1.2v0.8c0,0.8,0.7,1.4,0.7,2.3c-0.1,3.7-0.3,7.4-0.3,8.4
	c0,2.1-0.1,2.6-0.4,3.4c-0.3,0.7-1.9,5.1-2.8,5.6c-0.8,0.5-15.4,2.3-21.5,2.3c-2.9,0-3.2-0.1-8.3-0.1c-7.7,0-26.5,3.2-42.6,5.6
	c-13.8,2.2-20.4,3.6-26.4,4.5c-1.8,0.3-2.3-0.5-2.3-2.3c0-5.4-0.3-10.3-1-15c0-0.4,0.5-0.8,0.5-1.1c0-0.4-0.7-0.7-0.8-1.1
	c-5.6-37.4-29.7-56-60.3-56c-43.2,0-58,35.5-60.4,64c0,0.3-0.3,0.6-0.3,0.7c0,0.4-0.1,1-0.1,1.4c0,0.1,0.3,0.3,0.3,0.4
	c-0.4,3.7-0.7,7.4-0.8,11c-0.1,1.8-0.1,2.5-2.2,2.5c-0.5,0-27.5-0.8-64.9-0.8c-65.1,0-162,0.1-209,0.1c-16.8,0-27.1,0.8-27.4,0.8
	c-1.2,0-2.3-0.3-2.3-1.9c0.3-3.4,0.3-6.6,0.3-9.9c0-0.4,0.4-0.7,0.4-1.1v-1.8c0-0.4-0.4-0.7-0.4-1.1c-1.8-42.2-26.3-66.2-60.1-65.9
	c-48.4,0.4-64.4,43.1-63.3,79.4c0,0.8,0,2.3-1.5,2.3s-33.4-1.1-59-2.2c-3-0.1-9.5,1.1-12.4,0.8c-1-0.1-2.6,0.5-4.1,0.3
	c-3-0.5-6.3-2.1-7.4-2.8c-1.8-1-2.8-2.1-2.8-3.7L49.9,367.1L49.9,367.1z"
          />
          <path
            fill="#5E5E5E"
            d="M84.1,365.9h0.7c3.6-0.1,8-2.5,12.5-2.5c4.7,0,9.4-0.3,13.6-0.5c-18.6-17.2-23.2-19-28.3-19
	c-4.1,0-22.1,3.2-29.4,4.4c-1.8,0.3-3,0.5-3,0.5c0,0.4-0.3,0.8-0.3,1c0,1.8,0,5.6,0.1,6.9c0.3,6.3,1.1,4.4,1.1,7.6
	c0,0.7-0.3,1-0.5,1.2c0,0.4,0,1,0.1,1.5c0.4,0.1,1.1,0.3,1.9,0.3c0.5,0,7.8-0.3,17.9-0.7c1.7,0,3.3,0.8,5,0.7
	C78.3,367.1,81.2,366.1,84.1,365.9L84.1,365.9z"
          />
          <path
            fill="#EAEBEB"
            d="M63.7,313.7c0.3,0,0.6,0,0.8-0.1c5.2-1.8,15.3-4.8,16.9-4.8s1,2.2,2.6,2.2c0.1,0,0.4,0,0.8-0.1
	c4.7-1.2,24.8-9.8,32.5-14c0.6-0.3,1.2-0.7,1.9-1.1c4.1-2.6,9.5-6.6,14.6-10.7c5.2-4.3,10-8.8,13.1-12.2c1.1-1.2-0.3-1.8-0.6-1.8
	c-6.3,1.2-12.5,2.6-18.4,4.1c-21.2,5.5-45.4,15.3-52.6,20.5c-0.3,0.3-0.7,0.5-0.8,0.7c-5.1,4.4-6.6,7.3-11,15
	C62.3,312.9,62.7,313.7,63.7,313.7L63.7,313.7z M72.6,349.4c-1.4,0.1-1.7,1-1.9,1.4c-0.6,1.2-0.7,2.5-0.7,3.9
	c0,5.4,4.7,10.7,9.6,10.2c1.4-1.5,1.7-4.3,1.7-7.7c0-3.6-0.6-6.7-1.2-8.5L72.6,349.4L72.6,349.4z"
          />
          <path
            fill="#5E5E5E"
            d="M64.1,295.7l-2.9,1.4h-2.3c-0.4,0-0.7,0.3-1.1,0.5c-0.1,0.1-0.3,0.3-0.4,0.3c-1,0.3-1.7,0.3-2.1,1.1
	c-0.7,1.2-2.1,6.1-2.1,8.9c0-0.1,0.1-0.3,0.1-0.3c-0.1,0.1-0.1,0.3-0.1,0.3c-0.7,1.1-1,2.3-1,3.6c0,1.1,0.8,1.8,1.5,1.8h1.4
	c-0.5,0.4-2.9,2.1-3.7,3.4c-0.4,0.8-0.1,1.8,1.7,1.8c0.1,0,0,0.4,0,0.7c-1.4,1-1.7,1.8-1.7,3.9c1.1-1.1,4-2.9,5.1-3.7
	c0.3-0.1,0.4-0.3,0.4-0.4s0.3-1,0.7-2.2c0.1-0.3,0.1-0.5,0.3-0.8c0.5-1.5,1.2-3.4,2.3-5.6l4.3-8.4c0,0,0-0.1,0.1-0.1
	c0.6-1,1-1.6,1.4-2.3c0.8-1.4,1.7-2.3,1.8-3C68.4,294.2,66.2,294.7,64.1,295.7L64.1,295.7z M199.5,314.7
	c-30.3,0-54.8,24.5-54.8,54.8c0,6.9,1.2,13.5,3.6,19.7c7.8,20.5,27.8,35.1,51.2,35.1c24.2,0,44.7-15.7,52-37.4
	c1.8-5.5,2.8-11.3,2.8-17.3C254.2,339.3,229.7,314.7,199.5,314.7L199.5,314.7z"
          />
          <path
            fill="#EAEBEB"
            d="M162.6,369.5c0-20.4,16.5-36.9,36.9-36.9s36.9,16.5,36.9,36.9s-16.5,36.9-36.9,36.9S162.6,389.8,162.6,369.5
	z"
          />
          <path
            fill="#5E5E5E"
            d="M254.2,369.5c0,6.1-1,11.8-2.8,17.3h1.5c1.6,0,4.8-3.3,5.8-3.3s13.6,3.2,20.6,4c1.1,0.1,2.1,0.3,2.9,0.3h8.4
	c1.2,0,2.9-0.8,3.7-0.8h3.2c0.8,0,0.3,1.6,0.8,1.6h2.3c1.6,0,6.2-2.2,9.5-2.2h20.4c1.2,0,1.9,2.2,2.9,2.2h2.3c0.8,0,1.4-2.2,2.6-2.2
	h28.3c0.8,0,0.5,1.1,1.8,1.1h4.7c1.1,0,1.2,1.5,5,1.5c3.9,0,8.1-1.1,11-1.1h2.5v-1.4h6.7c1,0,0,1.8,1.4,1.7c0.7-0.1,0.1-1,0.8-1
	c1.1,0,0.4,1.4,1.4,1.6c0.5,0.3,0.7-0.4,0.7-0.7v-1.9h22.6c0.5,0,0.4,0.4,0.7,0.4h5.4c2.1,0,1.9,1.1,4.1,1.1h60
	c3.4,0,2.3-1.6,4.4-1.6h2.2c1.9,0,1.9-0.8,3.3-0.8h27.5c2.2,0,33.3,4,35.5,4h7.4c-1.9-5.6-3-11.8-3-18.2c0-30.3,24.5-54.8,54.8-54.8
	c1.9,0,3.7,0.1,5.6,0.3c27.7,2.8,49.1,26.1,49.1,54.5c0,2.9-0.3,5.6-0.7,8.4c3.4-1.4,3.9-6.3,3.9-6.9c0-0.7,0.4-1,3.9-2.5
	c-0.1-3.6-0.3-7-0.7-10.3c0-0.4-0.1-0.8-0.1-1.2s0.5-0.8,0.5-1.1c0-0.4-0.7-0.7-0.8-1.1c0-0.4-0.1-0.7-0.1-1.1
	c-3.9-24.2-15.7-40.4-31.9-48.6c-8.4-4.3-17.9-6.3-28.2-6.3c-43.2,0-58.1,35.5-60.4,64c0,0.1-0.1,0.3-0.1,0.3
	c-0.1,0.1-0.1,0.3-0.1,0.4l-0.1,1.4c0,0.1,0.3,0.3,0.3,0.4c-0.4,3.7-0.7,7.4-0.8,11c-0.1,1.8-0.1,2.5-2.2,2.5c-0.1,0-2.1,0-5.4-0.1
	c-9.8-0.3-31.6-0.7-59.6-0.7l-209,0.1H286c-7.3,0-13.2,0.3-17.2,0.4c-3.7,0.1-5.8,0.3-5.8,0.3c-1.2,0-2.3-0.3-2.3-1.9
	c0.3-3.4,0.3-6.6,0.3-9.9c0-0.4,0.4-0.7,0.4-1.1v-1.8c0-0.4-0.4-0.7-0.4-1.1c-1.8-42.2-26.3-66.2-60.1-65.9
	c-12.9,0.1-23.5,3.3-32.2,8.5c-19.1,11.7-28.2,33.6-30.5,55.7c0,0.3,0,0.5-0.1,0.8c0,0.4,0,0.7-0.1,1.1c-0.4,4.4-0.6,8.9-0.4,13.3
	v0.4c0,0.8-0.1,1.9-1.5,1.9c-1.5,0-33.4-1.1-59-2.2c-2.2-0.1-6.2,0.5-9.4,0.8v0.3c0.1,0.5,0.8,1.2,2.3,1.6c1.8,0.5,6.1,1.6,7.2,1.6
	s1.4-2.6,2.8-2.6c1.4,0,5.6,1,7.2,1s5.5,2.6,8.1,2.6h14.4c2.6,0,7.3,2.9,12.1,2.9s9.8,2.5,12,2.5h15c-2.3-6.1-3.6-12.7-3.6-19.7
	c0-30.3,24.5-54.8,54.8-54.8C229.7,314.7,254.2,339.3,254.2,369.5L254.2,369.5z"
          />
          <path
            fill="#5E5E5E"
            d="M627.3,316.2c-30.3,0-54.8,24.5-54.8,54.8c0,6.9,1.2,13.5,3.6,19.7c7.8,20.5,27.8,35.1,51.2,35.1
	c24.2,0,44.7-15.7,52-37.4c1.8-5.5,2.8-11.3,2.8-17.3C682,340.7,657.4,316.2,627.3,316.2L627.3,316.2z"
          />
          <path
            fill="#EAEBEB"
            d="M590.4,371c0-20.4,16.5-36.9,36.9-36.9s36.9,16.5,36.9,36.9s-16.5,36.9-36.9,36.9
	C606.9,407.7,590.4,391.3,590.4,371L590.4,371z M336.8,203c-35.6,19-76.8,45.1-86,50.8c-0.6,0.3-1,0.6-1.5,0.8l-1.6,1h3.6
	c5.4-0.3,11.7-1.8,17.5-3.6l0.4-0.1l0.3-0.1c0.1,0,0.3-0.1,0.4-0.1l0.3-0.1c7.8-4.1,61.4-41.1,96.7-55.8c-6.6-3.3-7.8-3.6-7.8-3.6
	C352.2,195,344.6,198.7,336.8,203L336.8,203z"
          />
          <path
            fill="#5E5E5E"
            d="M296,248.5c-3.7,2.9-9.4,7.4-13.2,13.8c3.6-0.3,12.7-0.8,23.4-1.4c-2.1-0.7-3.3-2.2-3.3-3.3
	c-3.6-0.4-4.3-0.5-4.3-1.4v-3c-1.1-1.4-1.9-3-1.9-5v-0.4L296,248.5L296,248.5z"
          />
          <path
            fill="#EAEBEB"
            d="M385.3,200.5c-17.6,4.5-46.5,20.5-68.2,34.4c-0.3,0.1-0.7,0.4-1.2,0.7c3.6,0,6.3,0.3,7.2,1.4
	c1.5,1.9,1.8,5.4,1.8,12.5c0,8.4-0.4,9.5-3.2,10.5h1.6c28.5-1.5,70.2-3.6,116-5.8l13.8-61.5l0.3-1.4
	C415.2,193.1,395.9,197.8,385.3,200.5L385.3,200.5z M470.2,192.3l-5.1,60.8c33.4-1.5,68.1-3.2,100.6-4.5l5.2-56
	c-21-0.8-52.5-1.8-88.5-1.8c-4.3,0-8.3,0-12.1,0.1L470.2,192.3L470.2,192.3z M573.2,192.7l-5,55.7c12.2-0.6,24.1-1,35.5-1.5
	c-1.1-2.9-2.8-7.3-4.7-12.2c-0.3-0.4-0.4-1-0.7-1.6c-6.3-16.8-15.1-39.8-15.1-39.8C580.2,193.1,576.9,193,573.2,192.7L573.2,192.7z
	 M593.5,193.6c4.5,11.8,17.7,46.5,20.1,53c38.8-1.6,64.4-5.1,77.4-9.6c4.7-1.6,2.5-6.2,0.7-8.1c-7.6-8.1-23.5-19.9-33-26.3
	c-4-2.8-5.5-3.4-11.8-4.4C630.9,196.1,611.6,194.6,593.5,193.6L593.5,193.6z"
          />
          <path
            fill="#EAEBEB"
            d="M320.5,247.6c-4.4,0.1-14.7,0.7-15.4,0.8c-0.8,0.1-1.1,0.6-1.1,0.8s1.6,3.6,3.7,3.6c1.4,0,9.6-0.1,13.1-0.3
	h0.1c0.5,0,1-0.4,1-0.8v-3C321.8,248,321.3,247.6,320.5,247.6L320.5,247.6z M706.8,255.4c-0.7,1.9-0.7,3.2,0.7,4.3
	c11,7.6,33.3,16.4,38.5,18.2c0.1,0,0.3,0.1,0.3,0.1c4.4,1.5,8,1.6,12,2.3c4,0.5,8.4,2.8,9.4,2.8s1.2-0.7,1.1-1.5
	c-0.8-4.3-1.7-8.1-2.1-8.8c-0.3-0.5-0.3-1-0.3-1.4c0-0.8,0.1-1.5,0-2.2c-0.1-1.1-2.8-10-6.3-16c-1.5-2.6-4.5-4.7-6.3-5.4
	c-0.7-0.3-1.4-0.4-2.1-0.7c-0.1,0-0.1,0-0.3-0.1c-2.2-0.7-7.3-1.2-10.6-0.8c-3.4,0.6-22.4,3.7-26.7,4.7c-1.8,0.4-3.2,1-4.3,1.5
	C708.3,253.3,707.2,254.3,706.8,255.4L706.8,255.4z M710.4,202.2c1.5,1.8,27.8,29.2,35.5,34.7c3.9,2.8,8.5,4.5,11.6,5.1l0.5,0.1
	l5.9,0.3c-0.3-0.3-0.5-0.7-1-1.1c-1.1-1.2-2.6-3-4.4-5.2c-0.3-0.4-0.7-0.8-1.1-1.2c-4.5-5.4-11.1-12.7-19.4-20.5
	c-8.4-8-16.2-14.4-21.5-18.6c0,0-4.4,2.1-5.8,3.2C709.6,200,708.9,200.4,710.4,202.2L710.4,202.2z"
          />
          <path
            fill="#5E5E5E"
            d="M761.4,233.1c0,0-2.2-0.7-3.9,1.6c0.4,0.4,0.7,0.8,1.1,1.2c1.9,2.2,3.4,4,4.4,5.2c0.1-0.1,1.4-1.9,1.2-2.8
	l2.8-3.4l-2.8-2.6C762.2,232.2,761.4,233.1,761.4,233.1L761.4,233.1z M773.8,329.6v9.4c0,1.8,1,2.8,2.5,2.8h5V334
	c0-2.8-2.5-15.4-2.5-16.1v-0.3h-5.5v0.4C773.2,318.8,773.8,322.4,773.8,329.6L773.8,329.6z M788.1,356.9c-1.5,0-2.1,2.6-6.5,6.7
	h-6.3c-6.2,0-16.8,1.5-24.8,2.6c4.1-0.5,7.6-0.8,9.9-0.8c5.1,0,5.4,0.1,8.3,0.1c5.1,0,16.4-1.2,20.2-1.9c0.7-0.1,1.1-0.3,1.2-0.3
	c0.8-0.5,2.3-4.4,2.8-5.5c-0.1-0.5-0.4-1-0.8-1c-1.5,0-1.2,2.6-4.3,6.7h-2.2C787.4,361.9,789.6,356.9,788.1,356.9L788.1,356.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M598.3,231.9
	c0,1.8,1.1,3.6,1.1,3.6 M479.5,172.6h7.4 M236.2,369.5c0,20.4-16.5,36.9-36.9,36.9s-36.9-16.5-36.9-36.9s16.5-36.9,36.9-36.9
	C219.8,332.7,236.2,349.2,236.2,369.5L236.2,369.5z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="627.3"
            cy="371"
            r="37"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M60.8,291.9
	c0,0,5.6-0.3,7.2-0.3s2.2,0.4,2.2,1.1c0,0.7-1.2,1.8-4,6.3 M146.1,270.7c0,0,1.9,0.4,0.6,1.8c-6.5,7.2-21.3,19.5-29.6,24.1
	c-8.3,4.5-30.8,14.2-33.3,14.2c-1.7,0-1-2.2-2.6-2.2c-1.5,0-11.7,3-16.9,4.8c-1.4,0.4-2.3-0.1-1.1-2.3c4.5-7.8,5.9-10.6,11-15
	c5.8-5.1,31.2-15.4,53.4-21.2c25.6-6.7,58.5-11,81.6-13.6c13.9-1.5,24.6-2.9,28.9-2.9c5.6,0,9.6-3,12.9-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M63.7,313.7
	c0-0.4,0.1-1,0.6-1.5c4.5-7.8,5.9-10.6,11-15c5.8-5.1,31.2-15.4,53.4-21.2c5.8-1.5,12-2.9,18.4-4.1 M85.6,310.4
	c-1.7,0-0.8-2.2-2.6-2.2c-0.4,0-1.5,0.3-2.9,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M66.2,291.9
	c5.1-8.8,38.1-18.8,75.8-25.6c41.5-7.4,94.1-11.1,96.6-11.1c5,0,5.2,0.3,11.3,0.3c5.6,0,12.7-1.6,19-3.6c0.1,0,0.3-0.1,0.4-0.1
	s0.1,0,0.3-0.1c0.1,0,0.3-0.1,0.4-0.1s0.3-0.1,0.3-0.1c8.4-4.4,68.9-46.2,103.7-58.6c25.5-8.9,72.4-14.4,136.7-14.4
	c53,0,137.8,4.8,172.9,7.8 M117.2,296.7c0,0,8.1,8.8,10,10.7c1.9,1.9,3.9,2.5,8.3,2.2c6.6-0.3,32.7-2.2,32.7-2.2 M67.4,297.1
	l6.2-0.3 M68.4,295.4h7 M54.8,376.2c19.1,0,82.4,2.6,82.4,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M130,378.4
	c-2.5-59.3,31.2-85.4,69.5-86.8c39.6-1.5,69.1,30.4,69.1,73.3v15.5 M261,367l305.1-1.6 M302.5,239.2c3.6-2.6,8.4-3.6,11.8-3.6
	c4.3,0,7.7,0,8.8,1.4c1.5,1.9,1.8,5.4,1.8,12.5c0,9.2-0.5,9.6-3.9,10.7c-2.1,0.7-6.2,1.1-11.4,1.1c-3.9,0-6.5-2.2-6.5-3.9
	c0-1.1-6.2-4.1-6.2-9.4C296.7,245.6,298.9,241.8,302.5,239.2L302.5,239.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M313.5,235.6
	c3.2,0,5.6,0.3,6.5,1.4c1.5,1.9,1.8,5.4,1.8,12.5c0,9.2-0.5,10.3-4,11.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M321.8,248.5v3
	c0,0.4-0.5,0.8-1.1,0.8s-11,0.4-13.1,0.4s-3.7-3.3-3.7-3.6s0.3-0.7,1.1-0.8c0.8-0.1,13.1-0.8,15.4-0.8
	C321.3,247.4,321.8,247.8,321.8,248.5L321.8,248.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M317.4,247.6
	c0.8,0,1.2,0.4,1.2,1.1v3c0,0.4-0.5,0.8-1.1,0.8 M321.6,256c-9.9,1.4-18.3,1.7-18.6,1.7c-3.6-0.4-4.3-0.5-4.3-1.4v-3 M298.6,243.2
	c-1.2,0.8-2.5,1.8-3.6,2.6c-4.3,3.2-11.4,8-16.1,16.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.1,0.3-0.1,0.4
	c-6.3,12.9-5.4,27.8-5.4,44.4c0,17.1,2.6,49,3.2,56.8c0.1,1.1,0,1.9-1.1,1.9 M614.5,249.5c42.6-1.9,72.4-5.8,87.8-10.9
	c5.6-1.8,3-6.9,0.8-9.1c-8.9-9.1-27.8-22.4-39.1-29.6c-4.7-3-6.6-4-14-4.8c-49.7-6.2-99-7.7-167-7.7c-61.1,0-86.5,6.6-100.3,9.9
	c-20.4,5.1-52.3,24.2-74.4,39.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M596.1,298.6
	c6.9-7.3,19.9-37,19.9-42.1c0-1.8-1.2-6.5-2.1-8.8c-0.8-2.3-22-57.8-22-57.8 M603.8,247c-5-13.2-20.5-53.8-20.5-53.8 M296.6,248
	c-3.7,2.9-9.8,7.6-13.8,14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M593.5,193.6
	c-13.6-0.7-57.6-2.9-111-2.9c-58.2,0-84,6.3-97.1,9.6c-17.9,4.5-47.6,21-69.5,35.2 M470.3,192.3c-6.2,0.1-12,0.3-17.5,0.6
	 M277.6,265.3c0,0,98.1-5.4,187.8-9.6c73.9-3.6,142.9-6.3,149.1-6.3 M306.5,260.9c-12.8,0.7-19.5,1.1-23.7,1.2 M613.7,246.6
	c-94.6,3.7-228.4,10.2-292.2,13.5 M570.9,192.7l-5.2,55.9 M573.2,192.8l-5,55.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M462.5,188.8
	c-3.7,21.6-9.2,60-9.9,64.7c-0.7,4.7-6.1,24.2-6.1,61.6c0,33.2,1.7,43.6,2.2,47.5c0.1,1.4,0.3,2.1-0.5,2.1 M359.9,190.9
	c0,0,1.9,0.6,8.5,3.9 M359,191.7c0,0,1.2,0.3,7.8,3.6 M336.8,203c0.7,0.6,1.1,1,2.6,0.3s12.2-5.8,13.2-6.2s1.5-0.6,2.6-0.1
	c1.1,0.4,3.9,1.9,3.9,1.9 M269.5,251.6l9.2,11.4 M470.3,190.9l-5.2,62.2 M453.1,191.4l-13.9,62.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M448.9,363.3
	c0.1,0.8,0.3,1.5,0.7,1.5l117.3-0.3 M276.1,363.4c0.1,0.8,0.5,1.5,1.2,1.5l170.9-0.1 M51.5,323c0-3.4,0.8-3.4,6.2-6.5 M53.1,319.1
	c0-2.2,0.5-2.3,3.3-4 M66.2,298.9c-1.2,2.1-3.6,2.3-6.3,3.6c-3.6,1.8-7.6,4.7-7.6,9.1c0,1.1,0.8,1.8,1.5,1.8h2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M58.5,306.9
	c-2.2,1.1-4.1,2.3-5.1,4.4c-0.1,0.4-0.1,1.9,0.7,2.1 M57.9,315.7c-2.2,0-1.9-1.4-1.7-1.8c0.7-1.9,2.2-3,4-3.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M61.4,307.4
	c-3.2,1.5-3.2,0.3-3-0.3c1-3.2,4.8-3,6.2-5.8 M53.2,308c0-3,1.5-7.8,2.1-8.9c0.4-0.8,1.1-0.8,2.1-1.1c0.4-0.1,0.7-0.8,1.4-0.8h4.8
	 M57.8,297.5c0,0-0.8,1.9-1.4,3.7 M681.1,379.4c3.6-1.2,4-6.3,4-7s0.5-1,3.9-2.6 M655.1,306.6c0,0,67.7-4.4,72.9-4.8
	c5.5-0.3,6.5,0,8.4-3.9c1.9-3.9,9.6-20.1,9.6-20.1 M681.4,179.5c0,0,1.4,4.1,2.1,6.1c0.7,2.1,12.2,10.2,12.7,10.5
	c0.5,0.3,0.7,0.6,1.7,3.6c0.3,1,28.8,30.1,34.1,35.8c5.2,5.8,8.9,7.4,13.6,9.4c2.3,1,7.8,2.6,12.9,4.7c5.5,2.1,10.5,4.4,10.7,4.8
	c0.4,0.6,5.9,9.8,6.9,17.6 M778.5,272.1l-12.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M768.7,281.7
	c0.1,0.8-0.1,1.5-1.1,1.5s-5.4-2.2-9.4-2.8c-4-0.5-7.6-0.7-12-2.3c-4.4-1.5-27.7-10.5-38.8-18.3c-1.5-1.1-1.5-2.3-0.7-4.3
	c0.7-1.9,3.2-3.4,7.4-4.4c4.3-1.1,23.2-4.3,26.7-4.7c3.4-0.6,8.8,0.1,10.9,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M753.7,247.8
	c1.8,0.7,4.8,2.8,6.3,5.4c3.6,5.8,6.2,14.9,6.3,16c0.1,1.1-0.4,2.3,0.3,3.7c0.6,1.4,3.4,14.4,3.4,19.9c0,3.2-0.3,3.6,2.2,5.6
	c2.5,1.9,6.9,5.8,6.9,5.8 M692.9,193.8c9.9-6.3,19.3-10.9,19.3-10.9 M713.5,184.6c0,0-11.3,6.7-17.2,11.8 M710.2,190.8
	c-5.4,2.2-11,6.6-11.7,7.3c-0.7,0.7-1.1,1.5-0.3,2.3 M730.5,234.2c2.9,2.9,5.2,3.9,14.2,5.5c8.9,1.5,21.9,3.9,21.9,3.9 M764,242.3
	l-5.9-0.3 M747.1,245.5l26.5-0.4 M779.7,264.1l-5.4,1.4 M780.7,265.6h-6.2 M781,270.4h-5.2 M558.5,366.1V380 M596.2,298.5
	c-12.7,6.5-21.6,16.6-27.5,27.9c-6.7,12.8-9.1,27.1-10.2,37.7v2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M697.9,375.1
	c0-42-21.5-83.6-70.6-83.6c-12.2,0-22.4,2.6-31.1,7 M56.4,319.2c5.4-3.2,19.5-5.2,28.2-8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M119.1,295.6
	c-8.9,6.6-22.8,14.2-31.5,18.2c-3.4,1.6-7,2.6-13.9,4.3c-5.9,1.4-23,5.8-24.1,7.4 M50.6,365.5c0,4,2.8,9.4,3.3,10s6.1,3.9,11,3.9
	 M49.9,369.3c0,2.6,2.1,7.3,4.4,8.3 M50.3,349c0,0,27.2-5,32.3-5s9.8,1.8,28.3,19"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M55,323
	c-1.2,2.1-3.4,10.7-3.4,16.1v7.2c0,1.8,1.9,2.3,1.9,2.3 M49.9,366.3c0,0,0.1,1,2.8,1c0.5,0,7.8-0.3,17.9-0.7c1.7,0,3.3,0.8,5,0.7
	c2.9-0.1,5.9-1.2,9.1-1.4c6.7-0.3,13.8-0.4,20.5-0.5c16.9-0.4,32.3-0.3,32.3-0.3 M61.4,349.2c0,0,16.4-2.5,20.2-2.5
	c1.2,0,3.7,0,5.9,1.5c4.8,3.3,9.9,9.9,9.9,12.9v2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M137.6,364.1
	c0,0-2.9,0.1-6.6,0c-3.6-0.1-7.6-1.5-13.3-1.4c-6.2,0.1-13.2,0.8-20.4,0.8c-4.7,0-8.9,2.2-12.5,2.5 M51.5,353.5c0,1.4,0,4.4,0.1,5.4
	c0.3,5,0.7,3.4,0.7,5.9c0,1.1-0.8,1.9,0.5,1.9s4.4-1,19.1-2.5 M53.2,350.7h14.9 M82.5,346.9c1.2,0.1,3.2,4.8,3.2,12.2
	c0,5.5-0.8,6.7-1.7,6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M50.1,348.4
	c0,0,0.4-0.8,1.9-1.1s27.8-5.1,32.3-5.1c6.1,0,13.5,4.7,31,20.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M78.7,347
	c1.4-0.1,2.6,4.5,2.6,9.9c0,5.5-0.8,9.1-5.2,9.1c-3.9,0-8.8-6.5-8.4-11.8c0.3-4.5,1.4-6.2,3-6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M79.7,364.8
	c-5,0.6-9.6-4.7-9.6-10.2c0-1.4,0.3-2.6,0.7-3.9c0.1-0.4,0.6-1.1,1.9-1.4l7.4-1 M67.3,379.2c0,0.7,0.6,1.4,2.3,1.9
	c1.8,0.5,6.1,1.6,7.2,1.6s1.4-2.6,2.8-2.6s5.6,1,7.2,1s5.5,2.6,8.1,2.6h14.4c2.6,0,7.3,2.9,12.1,2.9s9.8,2.5,12,2.5h15 M54.9,305.9
	c0,0-0.4-0.3-0.3-1.6c0.1-1.4,1.5-3,2.6-3.7s2.8-1.1,4-1.8c1.2-0.7,3.2-2.8,3.7-3.7 M53.1,318.4c0,0-1.8,0-1.8-1
	c0-1.8,4.4-4.1,4.4-4.1 M788.9,363.7h-13.6c-6.2,0-16.8,1.6-24.9,2.6 M786,324.2c1,5.2,1.5,10.3,1.5,20.2 M784.1,316.4
	c-2.3,0-3.9-0.7-4.8-0.7h-4.4c-1.5,0-1.5,0.7-1.5,2.2c0,1,0.5,4.5,0.5,11.6v10.3c0,2.6,1.5,3.2,3.2,3.2h8.1 M773.2,317.5h5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M780.7,315.9
	c-1.2,1-1.9,1-1.9,1.8c0,0.8,2.5,13.3,2.5,16.1v7.7 M784.7,343.2c-1.7,0-2.2-1.5-4-1.5h-4.4c-1.4,0-2.5-1-2.5-2.8 M781.6,363.7
	c4.4-4.1,5-6.7,6.5-6.7c1.5,0-0.7,5-2.6,6.7 M787.8,363.7c3-4.1,2.8-6.7,4.3-6.7c0.4,0,0.7,0.4,0.8,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M687.7,355.1
	c0,0,29.2-3.6,56.1-6.1c21.5-2.1,37.6-3.2,41-3.2c0.4,0,2.5-3.6,2.9-3.6h5.2 M688.2,359.7c0,0,28.6-4,55.7-6.6
	c21.5-2.1,37.6-3.6,41.1-3.6c0.4,0,2.5-3.6,2.9-3.6h5.8 M793.9,343.7h-13.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M784.8,345.8
	c0,0.4-1.2,1.1-1.2,1.5s1.5,1.5,1.5,2.1 M688.6,357.3c0,0,27-3.9,53.9-6.5c21.5-2.1,37.6-3.4,41-3.4 M251.3,386.8h1.5
	c1.6,0,4.8-3.3,5.8-3.3c1.1,0,18,4.3,23.5,4.3h8.4c1.2,0,2.9-0.8,3.7-0.8h3.2c0.8,0,0.3,1.6,0.8,1.6h2.3c1.6,0,6.2-2.2,9.5-2.2h20.4
	c1.2,0,1.9,2.2,2.9,2.2h2.3c0.8,0,1.4-2.2,2.6-2.2h28.3c0.8,0,0.5,1.1,1.8,1.1h4.7c1.1,0,1.2,1.5,5,1.5c3.9,0,8.1-1.1,11-1.1h2.5
	v-1.4h6.7c1,0,0,1.8,1.4,1.7c0.7-0.1,0.1-1.1,0.8-1.1c1.1,0,0.4,1.4,1.4,1.6c0.5,0.3,0.7-0.4,0.7-0.7v-1.9h22.6
	c0.5,0,0.4,0.4,0.7,0.4h5.4c2.1,0,1.9,1.1,4.1,1.1h60c3.4,0,2.3-1.6,4.4-1.6h2.2c1.9,0,1.9-0.8,3.3-0.8h27.5c2.2,0,33.3,4,35.5,4
	h7.6 M613.7,246.6c38.8-1.6,64.4-5.1,77.5-9.6c4.7-1.6,2.5-6.2,0.7-8.1c-7.6-8.1-23.5-19.9-33-26.3c-4-2.8-5.5-3.4-11.8-4.3
	c-16-2.1-35.4-3.6-53.5-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M254.2,369.5
	c0,30.3-24.5,54.8-54.8,54.8s-54.8-24.5-54.8-54.8s24.5-54.8,54.8-54.8S254.2,339.3,254.2,369.5z M682,371
	c0,30.3-24.5,54.8-54.8,54.8s-54.8-24.5-54.8-54.8c0-30.3,24.5-54.8,54.8-54.8C657.4,316.2,682,340.7,682,371L682,371z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M221.2,254.7
	c6.1-0.7,8.5-1.9,8.7-1.9c0.8,0,9.6,2.1,17.5,2.1c1.2,0,2.1-0.4,3.3-1.1c11.3-6.9,70-44.3,108.4-62c0.4-0.1,0.5-0.8,1.1-1
	C388.2,178,440.3,172,525.2,172c49.8,0,111.7,3.7,154.3,6.9c0.5,0,1.1,0.6,1.7,0.6c0.4,0,0.7-0.4,1.1-0.3c1,0.1,1.9,0.1,3,0.3
	c21.9,1.6,26.4,3.4,26.5,3.4c0.3,0.1,1.7,1.2,1.7,1.8c0,1.4-3.3,5.8-3.3,6.2c0,0.3,13.1,9.5,27.8,23.5c16.2,15.4,25.9,28.8,27,29
	c3.7,0.7,9.2,1.9,9.5,2.1s1.5,2.1,1.5,2.5c0,7.2,2.9,14.4,3.4,16c0.5,1.4,1.4,1.5,1.4,2.8v3.9c0,1.2-0.4,1.6-2.5,1.6
	c-0.7,0-1.2,0-1,0.7c0.3,0.7,1.5,9.1,1.5,18.2c0,6.7-0.3,11.4-0.3,12.4s1.5,1.4,2.1,2.9c0.4,1.5,3.4,8.4,5.2,18c0.1,0.8,0.7,1,4,1.5
	c3.2,0.5,3.6,0.7,3.6,2.5c0,2.9,0.4,4.3,0.4,6.3c0,1.2-0.1,4.4-0.1,7.3c0,0.6-0.8,0.6-0.8,1.2v0.8c0,0.8,0.7,1.4,0.7,2.3
	c-0.1,3.7-0.3,7.4-0.3,8.4c0,2.1-0.1,2.6-0.4,3.4c-0.3,0.7-1.9,5.1-2.8,5.6s-15.4,2.3-21.5,2.3c-2.9,0-3.2-0.1-8.3-0.1
	c-7.7,0-26.5,3.2-42.6,5.6c-13.8,2.2-20.4,3.6-26.4,4.5c-1.8,0.3-2.3-0.5-2.3-2.3c0-5.4-0.3-10.3-1-15c0-0.4,0.5-0.8,0.5-1.1
	c0-0.4-0.7-0.7-0.8-1.1c-5.6-37.4-29.7-56-60.3-56c-43.2,0-58.1,35.5-60.4,64c0,0.3-0.3,0.5-0.3,0.7c0,0.4-0.1,1-0.1,1.4
	c0,0.1,0.3,0.3,0.3,0.4c-0.4,3.7-0.7,7.4-0.8,11c-0.1,1.8-0.1,2.5-2.2,2.5c-0.6,0-27.5-0.8-64.9-0.8c-65.1,0-162.1,0.1-209,0.1
	c-16.8,0-27.1,0.8-27.4,0.8c-1.2,0-2.3-0.3-2.3-1.9c0.3-3.4,0.3-6.6,0.3-9.9c0-0.4,0.4-0.7,0.4-1.1V366c0-0.4-0.4-0.7-0.4-1.1
	c-1.8-42.2-26.3-66.2-60.1-65.9c-48.4,0.4-64.4,43.1-63.3,79.4c0,0.8,0,2.3-1.5,2.3s-33.4-1.1-59-2.2c-3-0.1-9.5,1.1-12.4,0.8
	c-1-0.1-2.6,0.5-4.1,0.3c-3-0.5-6.3-2.1-7.4-2.8c-1.8-1-2.8-2.1-2.8-3.7v-6.1c0-1.8,1.2-1.4,1.2-2.8c0-3.2-0.8-1.4-1.1-7.6
	c0-1.2-0.1-5.1-0.1-6.9c0-0.3,0.4-0.7,0.3-1c0-0.3-0.4-0.5-0.4-0.8c-0.1-0.8-0.1-1.5-0.1-1.9c0-0.8,0-4.5-0.1-7.4
	c0-0.3,0.1-0.5,0.1-0.8s-0.1-0.5-0.1-0.8c-0.1-1.1-0.1-10.2-0.1-10.7c0-1.4,1.2-1.5,1.7-2.8c0.3-1.2,5.8-4.3,5.9-4.8
	c0.1-0.5,1.9-6.3,5.4-12.9c3.4-6.6,5.5-9.2,5.6-9.5c0.3-0.5,0.3-1.5-0.7-1.5c-3.3,0-4.4,1.6-5.9,1.6s-3,0.5-3-0.3
	c0-0.4,0.3-1.2,1-2.2c0.3-0.4,1.5-0.8,1.8-1.2c0.3-0.4-0.3-1,0-1.5c7-8.9,39.5-19.8,74.8-26C161.4,260.8,202.8,256.8,221.2,254.7
	L221.2,254.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M716.6,195.7
	c0,0-4.4,2.1-5.8,3.2c-1.2,1.1-1.9,1.5-0.4,3.3c1.5,1.8,27.8,29.2,35.5,34.7c4.1,3,9.1,4.7,12.1,5.2 M758.5,236c0,0,2.6-1,2.9-0.7
	c0.1,0.3,2.2,2.3,2.8,3c0.5,0.6-1.1,2.9-1.1,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M761.4,235.3l2.9-2.9
	l2.8,2.6l-2.8,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M764.3,232.4
	c-1.9-0.3-2.9,0.7-2.9,0.7v2.2 M761.4,233.1c0,0-2.2-0.7-3.9,1.6"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M168.2,307.4
		l-32.1,2.2c0,0-4.3,0.3-5.8-0.3c0,0-1.6-0.4-2.6-1.2l-10.6-11.3c0,0,9.8-6.3,16.4-12c0,0,10.3-8.8,12.9-12c0,0,1.5-1.7-0.1-2.1
		c0,0,16.9-3.7,52.5-8.1c0,0,32.6-3.9,36.6-4c0,0,1.4-0.1,2.6-0.1c1.2,0,2.5,0,4.5-0.5c0,0,5.1-1.7,7.2-2.5c0,0,7.6,0.1,19.7-3.9
		l9.2,11.4c0,0-5.2,8.4-5.6,26.4c0,0-0.1,17.1,0,22c0,0,0.3,13.3,1.1,24.8l1.9,27.2l0.1,0.8c0,0,0.4,2.3-0.5,2.6l-14.6,0.1v-1
		c0,0,0-0.3-0.3-0.5c-0.3-0.3-0.3-1-0.3-1s0.1-19.9-10.6-38.7c0,0-13.5-26.8-49.5-26.8C200.4,299,183.4,298.2,168.2,307.4z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M298.8,253.2v3
		c0,0,0,0.5,0.7,0.8c0.7,0.1,3.6,0.5,3.6,0.5s0.3,2.5,4.1,3.6c0,0,1.1,0.3,2.2,0.3c0,0,5.2,0.1,9.6-0.7c0,0,4-0.5,5-2.2
		c0,0,0.8-1,0.8-5.4c0,0,0.3-7.3-0.4-12.5c0,0-0.3-2.3-1.5-3.9c0,0-0.7-1.1-4.8-1.1c0,0-3.9-0.1-5.1,0c-1.2,0.1-6.3,0.6-10.6,3.7
		c0,0-3.6,2.3-5.2,7.3C297,246.6,295.6,249.4,298.8,253.2z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M321.6,260.1
		l117.6-5.8l14-62.9c0,0-22,0.6-43.2,4c0,0-11.8,1.5-27.1,5.8c0,0-7.8,1.9-25.3,10.6c0,0-22.6,11.1-41.8,23.9c0,0,5.1-0.1,6.6,1
		c0,0,1.8,1,1.9,6.2c0,0,0.3,5.4,0.1,10.2c0,0-0.1,4.3-0.7,5.4C324,258.3,323.5,259.4,321.6,260.1z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M470.3,190.9
		c0,0,38-0.6,100.6,1.7l-5.2,56l-100.6,4.5L470.3,190.9z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="573.2,192.8 
		583.3,193.2 603.8,247 568.3,248.5 	"
              id="Left Qtr Glass 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M593.4,193.6
		c0,0,38.4,1.8,59,5.8c0,0,2.1,0.4,6.3,3.3c0,0,23.1,15.7,33.6,27c0,0,4.1,5-0.7,7.3c0,0-18.8,7.7-78,9.8L593.4,193.6z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M277.6,265.3l174.4-8.9
		c0,0-2.9,12.8-4.4,30.3c0,0-1.1,12.9-1.1,23.8c0,0,0.1,33.2,0.7,35.2c0,0,1,14.9,1.6,17.6c0,0,0.3,1.4-0.8,1.4l-170.3,0.1
		c0,0-1.5,0.3-1.8-1.9l-2.3-34.3c0,0-1.4-20.6-0.4-43.7C273.2,285,273.3,276,277.6,265.3z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M452,256.4l162.5-6.9
		c0,0,1.7,5.1,1.5,7.2c0,0,0.3,1.2-1.8,6.5c0,0-3.7,10.3-9.4,21.2c0,0-4.8,9.6-8.8,14.2c0,0-17.6,7.2-29,31.2c0,0-7.2,14.7-8.5,34.7
		L450,364.6c0,0-0.7,0.3-1-1.1c-0.3-1.4-2.6-18.7-2.2-33c0,0-0.7-20.5,0.8-42.9C447.6,287.6,448.6,274,452,256.4z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M599.5,306.6
		l-3.6-8.1c0,0,4-4.7,8.1-12.9c0,0,5.6-10.9,9.4-20.6c0,0,2.1-5.9,2.3-7.3c0,0,0.1-1.5-0.1-3.4l-1.2-4.8c0,0,16.5-0.7,37.3-2.5
		c0,0,24.2-1.9,45.5-7l4.8-1.4c0,0,3.4-0.7,3.3-3.9L732,236c0,0,6.1,6.3,14.2,9.2l5.9,2.1c0,0-2.8-1.5-9.5-1.2l-3,0.3
		c0,0-14.4,2.3-24.1,4.3c0,0-4.5,1-6.7,2.3c-2.2,1.4-3.2,4.3-2.5,5.8c0,0,0.6,0.8,1.9,1.7c0,0,9.1,5.9,25.3,12.7l12.2,4.8l-9.6,20.1
		c0,0-1.4,3.6-4.1,3.6l-76.5,5.1c0,0-12.2-6.7-29.7-6.3C626.1,300.3,611.1,300.4,599.5,306.6z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="199.5"
              cy="369.4"
              r="54.8"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="627.3"
              cy="371"
              r="54.8"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Wagon4DrLeft);
