import * as ActionTypes from '../../actions';
import _ from 'lodash';
import { persistStore } from 'redux-persist';
import { createStore } from 'redux';
import rootReducer from '..';
import { getConfig, transformGetConfig } from '../../utils/helpers';

// Keep initialState in alphabetical order
const initialState = {
  accountList: [],
  condition: {
    areas: {},
    damages: {},
    parts: {},
    severity: {},
  },
  coreSections: [],
  coreValidationError: null,
  crTypes: {},
  eventList: [],
  eventListError: null,
  file: {},
  fileChecksum: {},
  item: {},
  isVerified: false,
  autoGrade: {},
  lot: [],
  lotError: null,
  selectedCondition: {},
  showSideBar: true,
  variant: {},
  vehicleInfo: {},
  vin: {},
  visited: [],
};

export const coreReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CORE_META_FAILURE:
      return _.assign({}, state, {
        login: { amStatus: 'Error', amMessage: action.error },
      });

    case ActionTypes.CORE_META_SUCCESS: {
      const metaDetails = transformGetConfig(action.response);
      return _.assign({}, state, {
        login: metaDetails,
        serviceUrl: {
          serviceUrl: getConfig('coreRoot'),
          apiKey: metaDetails.apiKey,
          amStatus: 'Success',
          amMessage: 'Successfully logged in.',
          defaultSellerAccountNumber: '',
        },
      });
    }

    // case ActionTypes.UPDATE_ITEM: {
    //   const result = _.mergeWith(
    //     {},
    //     state,
    //     { item: action.response },
    //     (a, b) => (_.isArray(b) ? b : undefined)
    //   );
    //   return result;
    // }

    case ActionTypes.UPDATE_ITEM: {
      return _.mergeWith({}, state, { item: action.response }, (a, b) =>
        _.isArray(a) ? b : undefined
      );
    }

    case ActionTypes.RESET_ITEM:
      return _.assign({}, state, {
        item: {},
      });
    case ActionTypes.AUTO_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        item: { ...state.item, autoAnnouncements: action.response },
      };
    case ActionTypes.VIN_SUCCESS:
      return _.assign({}, state, {
        vin: action.response,
      });

    case ActionTypes.VIN_FAILURE:
      return _.assign({}, state, {
        vin: { amStatus: 'Error', amMessage: action.error },
      });

    case ActionTypes.RESET_VIN: {
      const store = createStore(rootReducer); // wtf is this??
      persistStore(store).purge();
      return _.assign({}, state, initialState);
    }

    case ActionTypes.UPDATE_VIN:
      return _.merge({}, state, {
        vin: action.response,
      });

    case ActionTypes.LOT_SUCCESS:
      return _.assign({}, state, {
        lot: action.response.slots,
      });

    case ActionTypes.LOT_FAILURE:
      return _.assign({}, state, {
        lotError: { amStatus: 'Error', amMessage: action.error },
      });

    case ActionTypes.RESET_LOT:
      return _.assign({}, state, {
        lot: [],
      });

    case ActionTypes.UPDATE_VARIANT:
      return _.assign({}, state, {
        variant: action.response,
      });
    case ActionTypes.GET_ITEM_AUTOGRADE_SUCCESS:
      return _.assign({}, state, {
        autoGrade: action.response,
      });

    case ActionTypes.GET_ITEM_AUTOGRADE_FAILURE:
      return _.assign({}, state, {
        autoGrade: action.error,
      });

    // added this to keep a dealer accounts state
    case ActionTypes.ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        accountList: action.response.values,
      };
    case ActionTypes.ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        accountList: action.error,
      };
    // added this for events list

    case ActionTypes.EVENT_LIST_SUCCESS:
      return {
        ...state,
        eventList: action.response.values,
      };
    case ActionTypes.EVENT_LIST_FAILURE:
      return {
        ...state,
        eventListError: action.error,
      };

    case ActionTypes.SET_SIDEBAR_ENABLED_SUCCESS:
      return {
        ...state,
        showSideBar: action.status,
      };

    //Condition related:-
    case ActionTypes.UPDATE_SELECTED_CONDITION:
      try {
        const selectedConditionJson = JSON.stringify(
          _.merge(state.selectedCondition, action.response)
        );
      } catch (error) {
        console.error(error);
      }
      return _.merge({}, state, {
        selectedCondition: action.response,
      });
    case ActionTypes.RESET_SELECTED_CONDITION:
      return _.assign({}, state, {
        selectedCondition: {},
      });
    case ActionTypes.CONDITION_PARTS_SUCCESS:
      return _.assign({}, state, {
        condition: {
          areas: state.condition.areas,
          parts: action.response,
          damages: state.condition.damages,
          severity: state.condition.severity,
        },
      });
    case ActionTypes.CONDITION_PARTS_FAILURE:
      return _.assign({}, state, {
        condition: {
          areas: state.condition.areas,
          parts: { amStatus: 'Error', amMessage: action.error },
          damages: state.condition.damages,
          severity: state.condition.severity,
        },
      });
    case ActionTypes.CONDITION_DAMAGES_SUCCESS:
      return _.assign({}, state, {
        condition: {
          areas: state.condition.areas,
          parts: state.condition.parts,
          damages: action.response,
          severity: state.condition.severity,
        },
      });
    case ActionTypes.CONDITION_DAMAGES_FAILURE:
      return _.assign({}, state, {
        condition: {
          areas: state.condition.areas,
          parts: state.condition.parts,
          damages: { amStatus: 'Error', amMessage: action.error },
          severity: state.condition.severity,
        },
      });
    case ActionTypes.CONDITION_DAMAGE_ACTIONS_SUCCESS:
      return _.assign({}, state, {
        condition: {
          areas: state.condition.areas,
          parts: state.condition.parts,
          damages: state.condition.damages,
          severity: action.response,
        },
      });
    case ActionTypes.CONDITION_DAMAGE_ACTIONS_FAILURE:
      return _.assign({}, state, {
        condition: {
          areas: state.condition.areas,
          parts: state.condition.parts,
          damages: state.condition.damages,
          severity: { amStatus: 'Error', amMessage: action.error },
        },
      });
    case ActionTypes.UPLOAD_FILES_SUCCESS:
      return _.assign({}, state, {
        file: action.response,
      });
    case ActionTypes.UPLOAD_FILES_FAILURE:
      return _.assign({}, state, {
        file: { amStatus: 'Error', amMessage: action.error },
      });
    case ActionTypes.RESET_UPLOAD_FILES:
      return _.assign({}, state, {
        file: {},
      });
    case ActionTypes.LOAD_PRESELECTED_DAMAGES:
      return _.assign({}, state, {
        selectedCondition: {
          area: action.response,
        },
      });
    case ActionTypes.CR_TYPES_SUCCESS:
      return _.assign({}, state, {
        crTypes: action.response,
      });
    case ActionTypes.RESET_CR_TYPES:
      return _.assign({}, state, {
        crTypes: {},
      });
    case ActionTypes.CONDITION_AREAS_SUCCESS:
      return _.assign({}, state, {
        condition: {
          areas: action.response,
          parts: state.condition.parts,
        },
      });
    case ActionTypes.CONDITION_AREAS_FAILURE:
      return _.assign({}, state, {
        condition: { areas: { amStatus: 'Error', amMessage: action.error } },
      });

    // Image related:-
    case ActionTypes.RESET_PHOTOS:
      return { ...state, item: { ...state.item, photos: [] } };
    case ActionTypes.UPDATE_PHOTOS: {
      return { ...state, item: { ...state.item, photos: action.response } };
    }
    case ActionTypes.UPDATE_PHOTOS_ASYNC: {
      return { ...state, item: { ...state.item, photos: action.response } };
    }
    // case ActionTypes.UPLOAD_FILES_CHECKSUM_SUCCESS:
    //   return _.assign({}, state, {
    //     fileChecksum: action.response,
    //   });
    // case ActionTypes.UPLOAD_FILES_CHECKSUM_FAILURE:
    //   return _.assign({}, state, {
    //     fileChecksum: { amStatus: 'Error', amMessage: action.error },
    //   });
    // case ActionTypes.RESET_UPLOAD_FILES_CHECKSUM:
    //   return _.assign({}, state, {
    //     fileChecksum: {},
    //   });

    // Post Item related:-
    case ActionTypes.POST_ITEM_SUCCESS:
      return _.assign({}, state, {
        post: action.response,
      });
    case ActionTypes.POST_ITEM_FAILURE:
      return _.assign({}, state, {
        post: { amStatus: 'Error', amMessage: action.error },
      });
    case ActionTypes.RESET_POST_ITEM:
      return _.assign({}, state, {
        post: {},
      });
    case ActionTypes.DELETE_POST_ITEM_SUCCESS:
      return _.assign({}, state, {
        deletion: action.response,
      });
    case ActionTypes.DELETE_POST_ITEM_FAILURE:
      return _.assign({}, state, {
        deletion: { amStatus: 'Error', amMessage: action.error },
      });
    case ActionTypes.DELETE_RESET_POST_ITEM:
      return _.assign({}, state, {
        deletion: {},
      });
    case ActionTypes.PRINT_POST_ITEM_SUCCESS:
      return _.assign({}, state, {
        print: action.response,
      });
    case ActionTypes.PRINT_POST_ITEM_FAILURE:
      return _.assign({}, state, {
        print: { amStatus: 'Error', amMessage: action.error },
      });
    case ActionTypes.PRINT_RESET_POST_ITEM:
      return _.assign({}, state, {
        print: {},
      });
    case ActionTypes.SET_IS_VERIFIED:
      return { ...state, isVerified: action.response };

    case ActionTypes.CORE_SECTIONS_SUCCESS:
      return { ...state, coreSections: action.response };

    case ActionTypes.VISITED_SUCCESS:
      return { ...state, visited: _.uniq([...state.visited, action.response]) };

    case ActionTypes.LOAD_CORE_VALDIATION_ERROR:
      return { ...state, coreValidationError: action.response };

    case ActionTypes.RESET_CORE_VALIDATION_ERROR:
      return { ...state, coreValidationError: null };

    default:
      return state;
  }
};
