import React, { Component } from 'react';

export default class Lights extends Component {
  updateLights(items) {
    let lights = {
      one: '#ccc',
      two: '#ccc',
      three: '#ccc'
    };

    items.forEach((value, index) => {
      switch (index) {
        case 0:
          lights.one = this.getColor(value);
          break;
        case 1:
          lights.two = this.getColor(value);
          break;
        case 2:
          lights.three = this.getColor(value);
          break;
        default:
          break;
      }
    });

    return lights;
  }

  getColor(color) {
    switch (color) {
      case 'Green':
        return '#008040';
      case 'Yellow':
        return '#ffff00';
      case 'Red':
        return '#ff0000';
      case 'Blue':
        return '#004080';
      case 'White':
        return '#ffff';
      case 'Purple':
        return '#800080';
      default:
        return '#ccc';
    }
  }

  render() {
    const { lights, style } = this.props;

    if (!lights) return null;

    const lightsObj = this.updateLights(lights.split(','));

    return (
      <div
        className="lights-container shadow"
        title={'Announced Lights: ' + lights}
        style={style}
      >
        <div className="light" style={{ backgroundColor: lightsObj.one }} />
        <div className="light" style={{ backgroundColor: lightsObj.two }} />
        <div className="light" style={{ backgroundColor: lightsObj.three }} />
      </div>
    );
  }
}
