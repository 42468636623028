import React, { Component } from 'react';
import { connect } from 'react-redux';

class SportsVanExtendedRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#5D5D5D"
            d="M418.5,174.8c-68.3,2.6-77.3,4.4-84.5,6.5c-1.4-1.3-5-0.6-9.6,4.7c-7.2,8.6-17.8,26.6-27.2,68
	c4.3-2,17.1-1.6,18.2-1.6c1-2.6,2.7-5.8,3-6.5h-0.1c2.1-20.3,12.2-53.4,14-58.5c24.5-5.2,82.4-6.5,88.1-6.5V179l-14-0.1
	c0,0-0.2-3.3,3.5-3.4h10.5v-2C419.1,173.6,418.6,174.7,418.5,174.8L418.5,174.8z"
          />
          <path
            fill="#FFFFFF"
            d="M296.8,256.3c-4.7,22.3-4.9,48.8-4.9,48.8s0,0.1-0.1,0.2c-0.4,0.8-1.4,3.8-1.5,11.3
	c-0.1,6.2,0.5,17.2,1.3,25.9v0.4c0.4,3.9,0.7,7.2,0.9,9.3c0.6,4.4,1.5,12.5,4.1,18.2c1.4,3.1,3.4,5.6,6.1,6.1
	c2.7,0.6,3.8,0.7,4.9,0.8c2.3,0.1,2.4-0.2,2.4-2c0-1.3,0.1-2.7,0.1-2.7h108.5v0.1h93.1c4.7-2.6,8.7-2.3,12.5,0h7.3
	c0,0,0,1.3,0.1,2.7c0,1.7,0.1,2.1,2.4,2c1-0.1,2.2-0.2,4.9-0.8s4.7-2.9,6.1-6.1c2.6-5.6,3.5-13.8,4.1-18.2c0.3-2.1,0.7-5.5,0.9-9.3
	v-0.4c0.7-8.6,1.3-19.7,1.3-25.9c-0.1-7.5-1.2-10.5-1.5-11.3l-0.1-0.2c0,0-0.3-27.3-5-49.5l0.1,0.7h2c8.5-0.1,17.7-1.8,21.3-3
	c3.7-1.3,3.1-5.8,3.3-10.5c0.1-4.7-1.9-5.4-5.8-7.6c-4.1-2.2-12.1-2.2-16.8-2.2c-4.7-0.1-6.4,1.9-6.3,4.1s0.6,6.8,1.2,9.1
	c0.5,1.9,0.6,2.8,0.2,3.3c0,0.1-0.2,0.2-0.6,0.4c-8.7-38.8-18.8-55.4-25.8-63.8c-4.4-5.2-8-6.1-9.6-4.7c-7.2-2.1-16.2-4-84.5-6.5
	c0-0.1-0.5-0.9-1.4-1.3c-0.4-0.1-1-0.1-1.5-0.1v-0.1c-1.5,0.2-2,1.2-2.1,1.4c-68.3,2.6-77.3,4.4-84.5,6.5c-1.4-1.3-5-0.6-9.6,4.7
	c-7,8.4-17.2,25.5-26.3,64.2c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.5-0.2-1.4,0.2-3.3c0.6-2.4,1-6.9,1.2-9.1s-1.6-4.2-6.3-4.1
	c-4.7,0.1-12.7,0-16.8,2.2c-4.1,2.2-5.9,2.9-5.8,7.6c0.1,4.7-0.5,9.2,3.3,10.5c3.6,1.3,12.8,2.9,21.3,3h2L296.8,256.3L296.8,256.3z"
          />
          <path
            fill="#EAEBEB"
            d="M332.5,187.2c-1.8,5.1-11.9,38.2-14,58.5h0.1c9.9-0.5,98.6-2,102.4-2.1h0.2c3.8,0.1,92.4,1.6,102.3,2.1h0.1
	c-2.1-20.3-12.2-53.4-14-58.5c-25.2-5.4-85.4-6.5-88.5-6.6h-0.1h-0.5C414.8,180.8,356.9,182,332.5,187.2L332.5,187.2z M420.6,175.5
	h-10.5c-3.7,0-3.5,3.4-3.5,3.4l14,0.1h0.6l14.2-0.1c0,0,0.2-3.3-3.5-3.4H420.6L420.6,175.5z"
          />
          <path
            fill="#5D5D5D"
            d="M387.4,264.4c0,0,0.6,0.6,2.2,0.8c1.5,0.2,3.4,0.4,3.8,0.4c0.1,0.1,0.7,0.7,2.6,0.9c1.4,0.2,3.4,0.2,4.2,0.2
	h0.5c0,0,0.6,0.6,2,0.8c1.4,0.2,4.2,0.4,4.2,0.4l4.9,0.2c0,0,1.8,0,2.8,0.9c0,0,0.1,0,0.1,0.1c0.2,0,0.2,0,0.6,0.5
	c0.9,0.7,1.9,1.4,2.9,1.9c0.9,0.4,2.2,0.5,2.8,0.5h0.2c0.6,0,1.8-0.1,2.8-0.5c1-0.5,2.1-1.2,3-2c0-0.2,0.2-0.4,0.4-0.4h0.1l0.1-0.1
	c1-0.9,2.8-0.9,2.8-0.9l4.9-0.2c0,0,2.8-0.1,4.2-0.4c1.4-0.2,2-0.8,2-0.8h0.5c0.9,0,2.8,0,4.2-0.2c1.9-0.2,2.6-0.9,2.6-0.9
	c0.4,0,2.3-0.1,3.7-0.4c1.6-0.2,2.2-0.7,2.2-0.7l-0.5-0.1h-0.9l-9,0.2c0,0-14.1,0.1-15.7,0.1c-1.6,0-3-0.9-4.4-1.5s-2.9-0.5-3.1-0.5
	c-0.1,0-1.6,0-3,0.5c-1.5,0.6-2.8,1.4-4.4,1.5c-1.6,0-15.7-0.1-15.7-0.1l-9-0.2C388.9,264.3,387.8,264.3,387.4,264.4L387.4,264.4z"
          />
          <path
            fill="#FFFFFF"
            stroke="#231F20"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M276.6,236.6c-3.7,2.1-5.5,2.7-5.4,7
	s-0.4,8.4,3,9.6c3.3,1.2,11.8,2.7,19.7,2.8c1.4,0,2.3-6.8,2.9-9.3c0.6-2.2,0.9-6.3,1-8.4s-1.5-3.8-5.8-3.7
	C287.8,234.6,280.4,234.5,276.6,236.6L276.6,236.6z"
          />
          <path
            fill="#EAEBEB"
            d="M296.7,257c-4.5,22.1-4.7,48.1-4.7,48.1h20.6c0.9-34.6,2.9-52.8,2.9-52.8v0c-0.7,0-6.6-0.2-11.8,0.4
	c-2.6,0.2-4.9,0.7-6.4,1.3c-0.1,0.7-0.4,1.5-0.5,2.2v0.1c0,0.1,0,0.2-0.1,0.4C296.7,256.8,296.7,256.8,296.7,257L296.7,257z"
          />
          <path
            fill="#5D5D5D"
            d="M299.5,410.5c-3.5-0.1-4.4-3.7-4.8-8.9c-0.2-3.7-0.1-32.3-0.1-37.8c0.6,2.3,1.3,4.5,2.1,6.5
	c1.4,3.1,3.4,5.6,6.1,6.1c2.7,0.6,3.8,0.7,4.9,0.8c2.3,0.1,2.4-0.2,2.4-2c0-1.3,0.1-2.7,0.1-2.7h12.1c0,5.7,0,23.9,0.1,28.8
	c0.1,6.8-0.7,9-4.2,9h-8.5C309.7,410.5,299.5,410.5,299.5,410.5L299.5,410.5z"
          />
          <path
            fill="#EAEBEB"
            d="M301.8,318.4h-9.6c0,1.5-0.1,3.4-0.1,5.2h9.7V318.4z"
          />
          <path
            fill="#FFFFFF"
            stroke="#231F20"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M565.1,235.6c3.7,2.1,5.5,2.7,5.4,7
	c-0.1,4.3,0.3,8.4-3,9.6c-3.3,1.2-11.8,2.7-19.7,2.8c-1.4,0-2.3-6.8-2.9-9.3c-0.6-2.2-0.9-6.3-1-8.4c-0.1-2.1,1.5-3.8,5.8-3.7
	C554,233.6,561.4,233.5,565.1,235.6L565.1,235.6z"
          />
          <path
            fill="#EAEBEB"
            d="M545.3,257c4.5,22.1,4.7,48.1,4.7,48.1h-20.6c-0.9-34.6-2.9-52.8-2.9-52.8v0c0.7,0,6.6-0.2,11.8,0.4
	c2.6,0.2,4.9,0.7,6.4,1.3c0.1,0.7,0.3,1.5,0.5,2.2v0.1c0,0.1,0,0.2,0.1,0.4C545.3,256.8,545.3,256.8,545.3,257L545.3,257z"
          />
          <path
            fill="#5D5D5D"
            d="M542.5,410.5c3.5-0.1,4.4-3.7,4.8-8.9c0.2-3.7,0.1-32.3,0.1-37.8c-0.6,2.3-1.3,4.5-2.1,6.5
	c-1.4,3.1-3.4,5.6-6.1,6.1c-2.7,0.6-3.8,0.7-4.9,0.8c-2.3,0.1-2.5-0.2-2.5-2c0-1.3-0.1-2.7-0.1-2.7h-7.5c-1.5-1.2-3.2-1.6-4.9-1.6
	c0,5.7,0.2,25.6,0.1,30.5c-0.1,6.8,0.7,9,4.2,9L542.5,410.5L542.5,410.5z"
          />
          <path
            fill="#EAEBEB"
            d="M540.3,318.4h9.6c0,1.5,0.1,3.4,0.1,5.2h-9.7V318.4z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M297.3,254c4.4-2.1,18.3-1.6,18.3-1.6s-2,18.2-2.9,52.8H292 M367.2,273.7
	h54 M421.2,261.2c0,0-37,0-44.4,0.1c-5.2,0.1-10.5,2.4-10.5,9.3c0,6.8,0.7,21.8,1.6,26.2c0.9,4.4,3.3,10.3,15.4,11
	c8.7,0.5,37.8,0.1,37.8,0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M380.3,274.1c0,0,0.1,15,0.2,18c0.1,2.9,2.1,8.5,9.7,9
	c7.6,0.5,30.8,0.1,30.8,0.1 M421.1,180.7c0,0-62.8,1.1-88.7,6.6c-1.8,5.1-11.9,38.2-14,58.5c9.4-0.5,102.7-2.1,102.7-2.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M333.7,181.2c0,0-1.6,2.3-3.3,7.5c-1.9,5.6-7.7,19.8-15,63.9
	c1.3-2.9,3.1-6.8,3.1-6.8 M332.5,187.1c0,0,2.4-5.2,11.3-6.6c5.9-0.9,34.4-2.9,63.4-4 M418.5,174.8c0,0,0.6-1.4,2.6-1.4
	 M291.7,342.6c-0.1-3.6,0.6-24.1,0.7-28.2c0.1-2.6,0.8-2.7,2.8-2.6c1.9,0.1,7.8,0.1,9.2,0.2s1.7,0.5,1.7,2.4c0,3.4,0,19.7,0.2,24
	c0.1,3.3,1.6,9.2,8.4,9.4s106.4,0,106.4,0"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M312.6,305.2c0,0,0.2,26.5,0.5,29.8c0.2,3.4,1.5,4.7,5.4,4.7
	c3.8,0,102.7,0.1,102.7,0.1 M421.1,175.5h-11l-3.5,3.4l14.5,0.1 M312.5,305.7c0,0-3.8,5.2-7,6.5 M293.1,311.9c0,0-0.7-1.6-1.3-6.8
	 M298.5,360.4c0,0,60.1,1,122.5,1.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M421.1,357.9c-62.4-0.1-119.1-1.3-122.2-1.2c-2.9,0.2,1.2,8.4,4,8.7
	s55.7,1.2,118.1,1.3 M292.2,318.4h9.6v5.4h-9.7 M307.1,261.9c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3
	S305.8,261.9,307.1,261.9L307.1,261.9z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M307.2,255.8c3.7,0.8,6.1,4.9,5.1,9.1c-0.9,4.2-4.7,6.9-8.4,6.2
	c-3.7-0.8-6.1-4.9-5.1-9.1C299.6,257.7,303.4,255,307.2,255.8L307.2,255.8z M365.7,269.5c0-0.2,0.4-0.2,0.6-0.2l48.5-0.2
	c0.2,0,0.2,0,0.6,0.5l0.1,0.2c0,0.2,0.5,0.2,0.2,0.2h-49.5C365.9,270,365.7,269.8,365.7,269.5 M320.5,339.8l-4.4,3.4l0.1,2.8
	l105,0.2 M315.7,252.4c0.4,0,38.4-1.9,105.7-2.1 M544.7,254c-4.4-2.1-18.3-1.6-18.3-1.6s2,18.2,2.9,52.8H550 M474.8,273.7h-53.6
	 M421.2,261.2c0,0,36.6,0,44.1,0.1c5.2,0.1,10.5,2.4,10.5,9.3c0,6.8-0.7,21.8-1.5,26.2c-0.9,4.4-3.3,10.3-15.4,11
	c-8.7,0.5-37.8,0.1-37.8,0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M461.7,274.1c0,0-0.1,15-0.2,18c-0.1,2.9-2.1,8.5-9.7,9
	c-7.6,0.5-30.8,0.1-30.8,0.1 M420.9,180.7c0,0,62.8,1.1,88.7,6.6c1.7,5.1,11.9,38.2,14,58.5c-9.4-0.5-102.7-2.1-102.7-2.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M507.8,181.4c0,0,2.1,2.1,3.7,7.2c1.9,5.6,7.7,19.8,15,63.9
	c-1.3-2.9-3.2-6.8-3.2-6.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M509.5,187.1c0,0-2.4-5.2-11.3-6.6c-5.9-0.9-34.4-2.9-63.4-4 M423.5,174.8
	c0,0-0.6-1.4-2.6-1.4 M550.5,342.6c0.1-3.6-0.7-24.1-0.7-28.2c-0.1-2.6-0.8-2.7-2.8-2.6c-2,0.1-7.8,0.1-9.2,0.2
	c-1.3,0.1-1.8,0.5-1.8,2.4c0,3.4,0,19.7-0.2,24c-0.1,3.3-1.6,9.2-8.3,9.4c-6.8,0.2-106.4,0-106.4,0"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M529.4,305.2c0,0-0.2,26.5-0.5,29.8c-0.2,3.4-1.5,4.7-5.4,4.7
	c-3.4,0-3.4,0-102.4,0.1 M420.9,175.5h11l3.5,3.4l-14.1,0.1 M529.4,305.7c0,0,4,5.2,7.1,6.5 M548.9,311.9c0,0,0.7-1.6,1.3-6.8
	 M543.5,360.4c0,0-60.1,1-122.5,1.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M420.9,357.9c62.4-0.1,119.1-1.3,122.2-1.2c2.9,0.2-1.3,8.4-4,8.7
	c-2.8,0.4-55.7,1.2-118.1,1.3 M549.8,318.4h-9.4v5.4h9.6 M311.2,282h-15.5l-0.4,6.2h15.4L311.2,282z M311.7,273.9h-15.5l-0.4,6.3
	h15.5L311.7,273.9z M426.5,270c-0.2,0,0.5-0.1,0.5-0.5c0-0.2,0.2-0.5,0.4-0.5l48.6,0.1c0.1,0,0.5,0.4,0.5,0.4c0,0.2-0.2,0.5-0.5,0.5
	h-48.5 M521.5,339.8l4.4,3.4l-0.1,2.8l-104.7,0.2 M526.4,252.4c-0.3,0-38-1.9-105.4-2.1 M315.9,343.2h210.2 M313,318l215.9-1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M423.5,174.8c68.3,2.6,77.4,4.4,84.5,6.5c1.4-1.3,5-0.6,9.6,4.7
	c7.2,8.6,17.8,26.6,27.2,68c5.1,22.7,5.4,51.1,5.4,51.1s1.5,2.4,1.5,11.5c0.1,9.1-1.3,28.7-2.2,35.7c-1,6.9-2.7,22.8-10.1,24.2
	c-2.7,0.6-3.8,0.7-4.9,0.8c-2.4,0.1-2.4-0.2-2.4-2c0-1.3-0.1-2.7-0.1-2.7h-7.5c-3.8-2.9-10.1-1.6-12.5,0h-90.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M418.6,174.8c-68.3,2.6-77.4,4.4-84.5,6.5c-1.4-1.3-5-0.6-9.6,4.7
	c-7.2,8.6-17.8,26.6-27.2,68c-5.1,22.7-5.4,51.1-5.4,51.1s-1.5,2.4-1.6,11.5c-0.1,9.1,1.3,28.7,2.2,35.7c1,6.9,2.7,22.8,10.1,24.2
	c2.7,0.6,3.8,0.7,4.9,0.8c2.3,0.1,2.4-0.2,2.4-2c0-1.3,0.1-2.7,0.1-2.7h110.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M294.6,364.3c0.1,3.7-0.1,33.5,0.2,37.3c0.4,5.1,1.3,8.7,4.8,8.9h18.8
	c3.6,0,4.3-2.2,4.2-9c-0.1-5.8-0.1-28.6-0.1-28.6 M547.4,363.9c-0.1,3.7,0.1,33.9-0.1,37.8c-0.3,5.1-1.3,8.7-4.8,8.9h-18.8
	c-3.5,0-4.3-2.2-4.2-9c0.1-5.8-0.2-30.4-0.2-30.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.25"
            d="M452.5,265.2c-1.5,0.2-3.4,0.4-3.7,0.4c-0.1,0.1-0.7,0.7-2.6,0.9
	c-1.4,0.2-3.4,0.2-4.2,0.2h-0.5c0,0-0.6,0.6-2,0.8c-1.4,0.2-4.2,0.4-4.2,0.4l-4.9,0.2c0,0-1.7,0-2.8,0.9s-2.3,1.9-3.6,2.5
	c-0.9,0.4-2.2,0.5-2.8,0.5h-0.2c-0.5,0-1.8-0.1-2.8-0.5c-1.3-0.5-2.6-1.5-3.6-2.5s-2.8-0.9-2.8-0.9l-4.9-0.2c0,0-2.8-0.1-4.2-0.4
	c-1.4-0.2-2-0.8-2-0.8h-0.5c-0.9,0-2.8,0-4.2-0.2c-1.9-0.2-2.6-0.9-2.6-0.9c-0.4,0-2.3-0.1-3.8-0.4c-1.6-0.2-2.2-0.7-2.2-0.7
	c0.7,0.2,1.5,0.4,1.8,0.4c0.2,0,4.5,0.1,4.5,0.1l5,0.1l3.7,0.1h12.6c0.2,0,0.1,0.1,0.1,0.1l-0.2,0.2c-0.2,0.2-0.6,0.1-0.6,0.1h-7.2
	c0,0-4.8,0-8.2-0.1h-5.1c-0.1,0-0.2,0-0.2,0.1c0.1,0.1,0.5,0.4,1.2,0.4c0.9,0,5.4,0.1,5.4,0.1s4.8,0.1,5.4,0.1h9.4
	c0,0,0.2,0.1,0.1,0.2c-0.2,0.1-0.4,0.2-0.4,0.2s-0.1,0.1-0.5,0.1c-0.2,0-5.9-0.1-7.1-0.1c-1.2,0-4.8-0.1-5.5-0.1
	c-0.5,0-0.7,0-0.8,0.1c0.7,0.4,2.9,0.4,2.9,0.4s9.3,0.2,10.3,0.4c0.9,0,1.6,0.8,2,1.3s1.2,1.2,2.3,1.6c0.9,0.5,2.4,0.5,2.9,0.5
	c0.6,0,2.1-0.1,2.9-0.5c1-0.5,2-1.3,2.3-1.6c0.4-0.5,1-1.2,2-1.3c0.9,0,10.3-0.4,10.3-0.4s2.2,0,2.9-0.4c-0.1-0.1-0.4-0.1-0.8-0.1
	c-0.7,0-4.3,0.1-5.5,0.1s-6.8,0.1-7.1,0.1c-0.2,0-0.4-0.1-0.4-0.1s-0.2-0.1-0.4-0.2c-0.2-0.1,0.1-0.2,0.1-0.2h9.4l5.4-0.1
	c0,0,4.4-0.1,5.4-0.1c0.7,0,1-0.2,1.2-0.4c0,0-0.1-0.1-0.2-0.1h-5.1c-3.4,0-8.2,0.1-8.2,0.1h-7.2c0,0-0.4,0-0.6-0.1l-0.2-0.2
	c0,0-0.1-0.1,0.1-0.1h12.6l3.7-0.1l5-0.1c0,0,4.3-0.1,4.5-0.1s1-0.1,1.7-0.4C454.9,264.5,454.2,265,452.5,265.2L452.5,265.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.25"
            d="M387.4,264.4c0.4-0.2,1.5-0.1,1.5-0.1l9,0.2c0,0,14.1,0.1,15.7,0.1
	s3-0.9,4.4-1.5c1.4-0.6,2.9-0.5,3-0.5c0.1,0,1.6,0,3.1,0.5c1.5,0.6,2.8,1.4,4.4,1.5c1.6,0,15.7-0.1,15.7-0.1l9-0.2h0.9l0.5,0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.25"
            d="M416.2,267c0-1.7,2.2-3.3,5-3.3c2.8,0,5,1.4,5,3.3c0,1.8-2.2,3.3-5,3.3
	C418.5,270.1,416.2,268.7,416.2,267L416.2,267z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.25"
            d="M424,264.6c0.6,0.6,0.9,1.3,0.9,2.2c0,1-0.4,1.8-0.8,2.2
	c-0.1,0.1,0,0.1,0.1,0.1c1-0.6,1.7-1.4,1.7-2.3C425.8,266,425.1,265.1,424,264.6C423.9,264.5,423.7,264.6,424,264.6z M417.7,267.1
	c-0.1-1,0.2-1.9,0.9-2.3c0.1-0.1,0.1-0.2-0.1-0.1c-1.2,0.6-1.9,1.4-1.9,2.3s0.7,1.8,1.9,2.3c0.2,0.1,0.2,0,0.1-0.1
	C418,268.7,417.7,268,417.7,267.1L417.7,267.1z"
          />
          <ellipse
            fill="none"
            stroke="#000000"
            strokeWidth="0.25"
            cx="421.3"
            cy="267"
            rx="2.8"
            ry="2.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M298,250.2c-0.6-0.1-0.7-1.1-0.1-3.5c0.6-2.4,1-6.9,1.2-9.1
	c0.1-2.2-1.6-4.2-6.3-4.1s-12.7,0-16.8,2.2s-5.9,2.9-5.8,7.6c0.1,4.7-0.5,9.2,3.3,10.5c3.7,1.3,14.6,3,23.3,3 M543.7,249.5
	c0.6-0.1,0.5,0,0.2-2.4s-1.2-7.8-1.2-10.1c-0.1-2.2,1.6-4.2,6.3-4.1c4.7,0.1,12.7,0,16.8,2.2s5.9,2.9,5.8,7.6s0.5,9.2-3.3,10.5
	s-14.6,3-23.3,3"
          />
          <ellipse
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            cx="535"
            cy="264.2"
            rx="2.3"
            ry="2.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M534.8,255.8c-3.7,0.8-5.9,4.9-5.1,9.1c0.9,4.2,4.7,6.9,8.4,6.2
	c3.7-0.8,5.9-4.9,5.1-9.1C542.3,257.7,538.6,255,534.8,255.8L534.8,255.8z M530.8,282h15.4l0.3,6.2h-15.3L530.8,282z M530.3,273.9
	h15.4l0.3,6.3h-15.4L530.3,273.9z"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M318.5,245.8
		c0,0,0.8-9,3.5-20.1c0,0,2.9-13.1,7.1-27.5c0,0,2.3-7.8,3.4-11c0,0,11.5-2.9,44.2-4.8c0,0,32.5-1.7,44.3-1.7
		c11.8,0,44.6,1.7,44.6,1.7s34,2.1,43.9,4.8c0,0,3.7,11.4,8,27.9c0,0,5.4,20.5,5.8,30.7h-0.8l-47.8-1l-53.9-0.9l-52.5,0.9l-49.7,1
		L318.5,245.8L318.5,245.8z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M315.6,252.4l3-6.5l50.5-1
		l51.9-0.9l53.9,0.9l48.6,1l2.9,6.5c0,0,1.7,20.8,2.1,31.4c0,0,0.8,18.3,0.7,30c0,0-0.1,10.1-0.3,21c0,0,0.1,2.7-1.3,4
		c0,0-1.2,1-4.1,1h-43.9l-58.5,0.1h-50.8h-51.2c0,0-3.4,0.2-4.8-1.2c0,0-1.3-0.8-1.3-4.1l-0.4-22.6v-6.8
		C312.6,305.1,313.5,275.1,315.6,252.4z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M312.6,305.1H292
		c0,0,0.4-15.3,1.9-29.6c0,0,1.4-12.8,3.5-21.4c0,0,3-2,18.2-1.6c0,0-1.9,22.5-2.4,40.8L312.6,305.1z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M529.4,305.1h20.8
		c0,0-0.2-14.8-2-31c0,0-1.8-13.6-3.3-20.1c0,0-3.4-2-18.3-1.6c0,0,2,23,2.4,37.2L529.4,305.1z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M292,305.1h20.6l0.5,29.8
		c0,0,0,3.1,1.7,3.8c0,0,0.9,0.9,5.5,0.8l64.8,0.1h36.1h44.8l57-0.1c0,0,3,0.4,4.8-1c0,0,1.2-0.8,1.3-3.6l0.3-16.9l0.1-12.8h20.8
		c0,0,1.9,2.7,1.5,15.2c0,0-0.2,11.9-1.1,20.8c0,0-0.6,10.4-2.6,20.2c-1.9,9.8-4.9,14-8,15.2c0,0-3.7,0.9-6.1,1c0,0-2,0.4-2.1-0.9
		l-0.1-3.7h-7.5c0,0-2.2-1.9-6.1-1.7c0,0-3.3-0.1-6.4,1.7h-47.2h-43.7H377h-53.5h-13.1l-0.1,2.9c0,0,0,1.3-0.4,1.5
		c0,0-0.5,0.5-2.1,0.2l-2.8-0.4l-2.1-0.5c0,0-2.3-0.1-4.7-3.5c0,0-2.4-3.3-4-11.3c0,0-1.6-7.6-2.7-20.8c0,0-1.4-16.3-1-26.9
		C290.5,314.1,290.6,308.2,292,305.1z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(SportsVanExtendedRear);
