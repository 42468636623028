import React, { Component } from 'react';
import { connect } from 'react-redux';

class Sedan4DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 90 800 420">
          <path
            fill="#5F605F"
            d="M698,359.8l-70,19c0,0,0.8,108.7,1,116c0,7.3,5.6,12.1,11.9,12.1s34.6,0.3,46.8-0.8s12.6-9.4,12.6-14.9
	C700.3,485,698,359.8,698,359.8L698,359.8z M170.7,359.8l70,35.4c0,0-0.8,92.3-1,99.6c0,7.3-5.6,12.1-11.9,12.1s-34.6,0.3-46.8-0.8
	s-12.6-9.4-12.6-14.9C168.4,485,170.7,359.8,170.7,359.8z"
          />
          <path
            fill="#FFFFFF"
            d="M426,429.6c-34.1,0.3-106.7,1.8-121.1,1.8c-2,0-5.3-0.8-6.3-0.8H294l-3.8-4.8c-0.3-1,1.5-4,1.3-5.1
	c-1-3-1.8-6.1-3-8.3c-4-7.1-6.3-6.3-14.7-6.3c-11.1,0-26,1.8-30.3,1.8c-10.9,0-14.9,1.3-18.2,12.4c-1,3-1.8,5.8-2.8,8.1
	c0,0.5-0.3,0.8-0.5,0.8c-10.6,5.8-12.6,6.1-25.5,15.4c-0.8,0.5-2.3,1.8-2.8,1.8c-0.3,0-1-0.5-2.5-0.8c-2.5-0.5-6.3-0.5-9.6-0.8
	c-0.5,0-1.5,0-2-0.8c-6.6-9.4-7.1-19.2-8.3-19c-0.3-0.5,0-1.3,0-1.8c0.2-0.5,1.3-3.3,1.3-4.3c-0.3-1,0.8-7.3-2.5-22.2l-0.3,10.1
	c-0.3,8.1-2.8,11.9-3.3,7.1c-0.8-3.5-3.8-14.2-2.3-19c0-4.3,1.8-7.6,1.8-8.3c0-1-0.3-2.8-0.3-4.5c-1-8.9-2.8-28.6-3.5-46.8
	c-1-24.5,0-38.4,0.3-39.4c0-1,1.3-2,1.5-2.3c0.3-1.3,1.3-4.8,1.3-4.8c0.5-3.5,0.8-6.8,1.8-11.9l0.8-3l0.3-0.8c0,0-3.5-1.5-4.8-2.3
	c-1.3-0.8-2-2-2-3.3c0-1.3,0.3-3.8,0.5-5.1c0.3-1,2.8-1.5,3.3-1.5c0-1.5,0.3-3.8,0.5-5.1c0.3-1,1.5-1.5,3.3-1.8
	c1.8,0,6.1-0.3,6.1-0.3c1-2.5,2.5-5.6,3-6.6c0.3-0.3,0.3-0.8,0.3-1c0,0,0.3-2,0.3-4c0-0.5,0.3-1.3,0.8-2.3c1-1.8,2.3-3,4.6-5.1
	c0.5-0.5,1.8-1.5,2.5-2c0,0-2.3-0.3-3.3,0.3c-1,0.5-3.3,1.5-7.6,1.3c-8.1-0.3-26.3-2.3-32.6-3.3s-10.1-4-12.4-7.3s-3.8-8.1-4-8.6
	c-0.3-0.8-0.3-1.8,0-2.5c0.3-0.8,2-5.3,4.6-9.1c2.5-3.8,6.1-5.3,14.2-8.1c2.5-0.8,6.1-1.5,9.9-2c7.8-1,20-1.3,24.3-1.3
	c4.3-0.3,7.1,1.8,9.1,4.3c2,2.3,6.8,11.6,7.8,13.7c0.8,1.8,1.5,3.3,4.6,3.5c2.8,0.3,3.3-0.8,3.3-0.8c3.3-9.1,8.1-22.2,20.7-48.8
	c8.6-17.7,19.2-33.9,25.5-43.2c1.3-1.8,2.5-2,2.5-2c0-0.3,0.3-0.3,0.3-0.5c0.5-1.3,1.8-3.3,2.8-3.3c0.5-1,1-1.8,1.3-2.8
	c0.3-0.5,0.8-1,1.3-1.3c0.3-0.3,0.5-0.3,0.8,0c0.5-1.3,2.5-3.3,5.6-5.8c3.3-2.3,7.1-3.3,7.6-3.3s1,0,1.3,0.3l1,1V103
	c0,0,0-0.3,0.5-0.3c35.9-4.6,90.5-8.1,154.2-8.1c0-0.8,0.3-2.5,0.5-3.3c0.3-0.8,0.8-1,1-1h0.5c0.3,0,1,0.3,1,1
	c0.3,0.8,0.3,2.5,0.5,3.3c63.7,0,118.3,3.5,154.2,8.1c0.3,0,0.5,0.3,0.5,0.3v0.3l1-1c0.3-0.3,0.8-0.5,1.3-0.3c0.5,0,4.3,1,7.6,3.3
	s5.1,4.6,5.6,5.8c0.3-0.3,0.5-0.3,0.8,0c0.3,0.3,1,0.8,1.3,1.3c0.5,1,1,2,1.3,2.8c1,0,2.3,2.3,2.8,3.3c0,0.3,0.3,0.3,0.3,0.5
	c0,0,1.3,0.3,2.5,2c6.3,9.1,16.9,25.3,25.5,43.2c12.9,26.5,17.7,39.7,20.7,48.8c0,0.3,4.3,1,7.1,1c2.8-0.3,2-1.5,3-3.3
	c1-2,3.5-11.6,5.6-13.9c2-2.3,4.8-4.3,9.1-4.3c4.3,0.3,11.1,0.3,19,1.5c3.8,0.5,7.3,1.3,9.9,2c8.1,2.8,11.6,4.3,14.2,8.1
	c2.5,3.8,4.5,8.3,4.5,9.1c0.3,0.8,0.3,1.8,0,2.5s-1.8,5.3-4,8.6c-2.3,3.3-6.1,6.3-12.4,7.3s-19.5,3-27.6,3.3
	c-4.3,0.3-6.6-0.8-7.6-1.3c-1-0.5-3.3-0.3-3.3-0.3c0.8,0.5,2,1.5,2.5,2c2.3,1.8,3.5,3,4.5,5.1c0.5,0.8,0.5,1.5,0.8,2.3
	c0.3,2,0.3,4,0.3,4c0.3,0.3,0.3,0.8,0.3,1c0.5,1.3,2,4,3,6.6c0,0,4.6,0,6.1,0.3c1.8,0,3,0.5,3.3,1.8c0.3,1,0.5,3.5,0.5,5.1
	c0.5,0,3,0.3,3.3,1.5c0.3,1,0.5,3.8,0.5,5.1s-0.5,2.5-2,3.3c-1.3,0.8-4.8,2.3-4.8,2.3l0.3,0.8l0.8,3c1,4.8,1.3,8.3,1.8,11.9
	c0,0.3,1,3.5,1.3,4.8c0.3,0.3,1.5,1.3,1.5,2.3s1.3,14.9,0.3,39.4c-0.8,18.2-2.5,37.9-3.5,46.8c-0.3,1.8-0.3,3.5-0.3,4.6
	c0.3,0.8,1.8,4,1.8,8.3c1.3,5.1-1.5,15.4-2.3,19c-0.5,4.6-3,1-3.3-7.1l-0.3-10.1c-3.3,14.9-2.3,21.2-2.5,22.2c-0.2,1,1,3.5,1.3,4.3
	c0.3,0.5,0.3,1.3,0.3,1.8c-1.3,0-1.8,9.9-8.3,19c-0.5,0.8-1.5,0.8-2,0.8c-3.3,0-7.1,0.3-9.6,0.8c-1.3,0.3-2.3,0.8-2.5,0.8
	c-0.8,0-2.3-1.3-2.8-1.8c-12.9-9.1-14.9-9.4-25.5-15.4c-0.5-0.3-0.5-0.5-0.5-0.8c-1-2.3-1.8-5.3-2.8-8.1
	c-3.5-10.9-7.3-12.4-18.2-12.4c-4.3,0-19.2-1.8-30.3-1.8c-8.3,0-10.6-1-14.7,6.3c-1.3,2.3-2,5.3-3,8.3c-0.3,1,1.5,4,1.3,5.1
	l-3.8,4.8H563c-1,0-4.3,0.8-6.3,0.8C543.1,431.4,461.1,429.9,426,429.6L426,429.6z"
          />
          <path
            fill="#5F605F"
            d="M424.2,101.7c-0.3,0.5-1,2.8-1,3.5v0.5c0,0.8,0.8,1.5,1.5,1.5c1,0,4.8,0.3,6.1,0.3c1.5,0,5.1-0.3,6.1-0.3
	s1.5-1,1.5-1.8c-0.3-0.8-1-3-1-3.5c-0.3-0.5-0.5-1-1-1c-0.5-0.3-2-0.5-2.5-0.8c-0.5,0-1-1-1-1.5c0-0.5-0.3-2.3-0.5-3.8v-0.3
	c-0.2-1.5-0.2-2.8-0.5-3c0-0.8-0.8-0.8-1-1c-0.3,0-1,0.3-1,1l-0.3,1.8c0,0.5-0.3,1-0.3,1.5c-0.3,1.8-0.5,3.5-0.5,3.8
	c0,0.8-0.5,1.5-1,1.5s-2,0.5-2.5,0.8C424.5,100.7,424.2,101.2,424.2,101.7L424.2,101.7z"
          />
          <path
            fill="#E9EAE9"
            d="M379.2,124.5c-46.8,1.8-69,4-94.3,6.8c-2.5,0.3-8.9,2-10.4,6.8s-13.6,50.8-14.9,57.6c-0.3,1.5,0,2.3,0.5,3
	c0.8,0.8,2.5,1,4.3,0.8c29.6-1.5,77.1-3.8,166.6-3.8c89.5,0,137,2.3,166.6,3.8c1.8,0,3.8,0,4.3-0.8s0.8-1.5,0.5-3
	c-1.3-6.8-13.7-52.8-14.9-57.6c-1.5-4.6-7.8-6.6-10.4-6.8c-25.3-2.8-47.8-5.1-94.3-6.8c-0.8,0-1.5,2.5-2.8,2.5s-32.6-0.8-49-0.8
	s-47.8,0.5-49,0.8C380.7,127,380,124.5,379.2,124.5L379.2,124.5z M226.8,165.2c-15.2,31.1-19.5,44.8-22.5,53.9c4.3-1.3,9.1-1,10.1-1
	c0.5,0,1.8-1.5,2.8-3.5c0.8-1.8,1.3-3.8,1.8-5.3c3-8.8,8.3-28.8,17.9-53.3c5.6-14.2,9.1-22.2,12.1-29.1
	C242.7,136.6,233.6,150.8,226.8,165.2L226.8,165.2z M185.6,210.9c-1-2.5-3-6.8-4.6-10.1c-1.8-3.3-3-5.1-7.3-5.1
	c-4.3,0.3-17.7,1.3-25,3c-7.3,1.8-11.4,5.1-13.1,7.6c-1.8,2.8-2.8,5.3-3.3,7.1c-0.5,1.3,0,2.8,0.5,4c0.5,1.5,2.3,4.3,3.5,6.1
	c1.3,1.8,3.3,3.8,8.1,4.6c4.8,0.8,26.8,3,31.1,3s6.1-1.5,6.8-3.8c0.8-2.3,3.3-9.1,3.8-11.1C186.3,214.5,186.6,213.5,185.6,210.9
	L185.6,210.9z"
          />
          <path
            fill="#5F605F"
            d="M412.1,239.7c-5.6,0-10.1,4-10.1,9.1s4.5,9.1,10.1,9.1c2.3,0,4.5-0.8,6.3-2c1.8,1.3,3.8,2,6.3,2
	c2.3,0,4.6-0.8,6.3-2c1.8,1.3,3.8,2,6.3,2c2.3,0,4.6-0.8,6.3-2c1.8,1.3,3.8,2,6.3,2c5.6,0,10.1-4,10.1-9.1c0-5.1-4.5-9.1-10.1-9.1
	c-2.3,0-4.5,0.8-6.3,2c-1.8-1.3-3.8-2-6.3-2c-2.3,0-4.5,0.8-6.3,2c-1.8-1.3-3.8-2-6.3-2c-2.3,0-4.5,0.8-6.3,2
	C416.6,240.2,414.4,239.7,412.1,239.7L412.1,239.7z M234.4,470.8l395.9-0.5v-23.8c-32.9,2.8-60.9,5.1-66,5.1
	c-7.1,0-11.9-2.5-13.9-4.8c-0.5-0.8-2-3-2.3-3c-39.4,1.3-84.4,0-116.8,0c-32.4,0-82.2,1-121.4-0.5c0,0-1.5,2.3-2,2.8
	c-1.8,2-6.6,5.3-13.9,5.3c-5.3,0-26.5-1.3-59.7-4L234.4,470.8L234.4,470.8z M166.9,255.2l10.4-0.3c-1.3,4.3-2.8,8.6-4.3,12.9l-2.8-1
	l-2.3,7.3l-6.1-3c-0.5-0.3-0.8-0.8-0.8-1.3c0-2,0.3-4,0.8-6.1c0-0.5,0.3-0.8,0.8-1l1.8-1c0.5-0.3,0.8-0.8,0.8-1.3
	c0-1.5,0.3-2.8,0.5-4.3C165.6,255.7,166.1,255.2,166.9,255.2L166.9,255.2z"
          />
          <path
            fill="#E9EAE9"
            d="M280.9,238.5c-34.4-0.3-56.6,1.8-64.5,3.8c-7.8,1.8-8.3,4-9.4,7.1c-2.8,9.6-4,22.5-5.1,32.4
	c10.9-0.3,51.3-2,64.7-2.8h0.3c24-3.5,54.9-7.8,55.9-7.8c1,0,1.5-1,1.5-1l0.5-0.8c0-0.3,0-0.5,0.3-0.8c0.5-1.3,8.1-15.9,9.9-20.5
	c2-4.8-1.5-6.8-4.3-7.3C328.4,240.2,315.5,238.7,280.9,238.5L280.9,238.5z M635.3,165.7c15.2,31.1,19.5,44.8,22.5,53.9
	c-4.3-1.3-9.1-1-10.1-1c-0.5,0-1.8-1.5-2.8-3.5c-0.8-1.8-1.3-3.8-1.8-5.3c-3-8.9-8.3-28.8-18-53.3c-5.6-14.2-9.1-22.2-12.1-29.1
	C619.2,137.1,628.3,151.3,635.3,165.7L635.3,165.7z M672.5,210.7c1-2.5,2.8-6.8,4.3-10.1c1.5-3.3,2.8-5.1,6.6-5.1
	c3.8,0.3,16.2,1.3,22.8,3c6.6,1.8,10.4,5.1,12.1,7.6c1.5,2.8,2.5,5.3,2.8,7.1c0.3,1.3,0,2.8-0.3,4c-0.5,1.5-2,4.3-3.3,6.1
	c-1.3,1.8-3,3.8-7.3,4.6c-4.3,0.8-24.5,3-28.3,3s-5.6-1.5-6.3-3.8c-0.8-2.3-3-9.1-3.5-11.1C671.7,214.2,671.5,213.2,672.5,210.7
	L672.5,210.7z"
          />
          <path
            fill="#5F605F"
            d="M695,254.7l-10.4-0.2c1.3,4.3,2.8,8.6,4.3,12.9l2.8-1l2.3,7.3l6.1-3c0.5-0.3,0.8-0.8,0.8-1.3
	c0-2-0.3-4-0.8-6.1c0-0.5-0.3-0.8-0.8-1l-1.8-1c-0.5-0.3-0.8-0.8-0.8-1.3c0-1.5-0.3-2.8-0.5-4.3C696.3,255.2,695.8,254.7,695,254.7
	L695,254.7z"
          />
          <path
            fill="#E9EAE9"
            d="M581,239.2c34.4-0.3,56.6,1.8,64.5,3.8c7.8,1.8,8.3,4,9.4,7.1c2.8,9.6,4,22.5,5.1,32.4
	c-10.9-0.2-51.3-2-64.7-2.8h-0.3c-24-3.5-54.9-7.8-55.9-7.8s-1.5-1-1.5-1l-0.5-0.8c0-0.3,0-0.5-0.3-0.8c-0.5-1.3-8.1-15.9-9.9-20.5
	c-2-4.8,1.5-6.8,4.3-7.3C533.7,240.8,546.6,239.2,581,239.2L581,239.2z"
          />
          <path
            fill="#5F605F"
            d="M240.4,408.1h-0.5c-8.3,0.3-11.6,2.5-14.7,12.4c-1,3-1.8,5.8-2.8,8.1c0,0.5-0.3,0.8-0.5,0.8
	c-10.1,5.6-12.4,6.1-23.8,14.2c8.9,1.5,22.2,2.8,42,4c32.4,2.8,53.3,4,58.4,4c7.3,0,12.1-3,13.7-5.3c0.5-0.5,2-2.8,2-2.8
	c38.4,1.3,87.2,0.5,119.1,0.5c31.9,0,76.1,1.3,114.8,0c0.3,0,1.8,2.3,2.3,3c1.8,2.3,6.6,4.8,13.7,4.8c5.1,0,67.2-5.1,99.6-7.8
	c0-0.3,0.3-0.3,0.3-0.5c-11.1-7.8-13.4-8.3-23.5-13.9c-0.5-0.3-0.5-0.5-0.5-0.8c-1-2.3-1.8-5.3-2.8-8.1
	c-3.5-10.9-7.3-12.4-18.2-12.4c-4.3,0-19.2-1.8-30.3-1.8c-8.3,0-10.6-1-14.7,6.3c-1.3,2.3-2,5.3-3,8.3c-0.3,1,1.5,4,1.3,5.1
	l-3.8,4.8h-4.6c-1,0-4.3,0.8-6.3,0.8c-14.2,0-96.3-1.5-131.5-1.8c-34.1,0.3-106.7,1.8-121.1,1.8c-2,0-5.3-0.8-6.3-0.8H294l-3.8-4.8
	c-0.3-1,1.5-4,1.3-5.1c-1-3-1.8-6.1-3-8.3c-4-7.1-6.3-6.3-14.7-6.3c-11.1,0-26,1.8-30.3,1.8h-0.3L240.4,408.1L240.4,408.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M258.6,111.1c-5.8,12.1-9.9,20.2-19.5,44.7
	c-9.6,25-15.7,44.5-18.7,53.3c-3,8.9-6.3,12.6-8.3,12.6c-2.3,0-6.8,0-10.4,1c-3.5,0.8-6.3,4.3-6.6,4.6 M274.3,103.2l-1-1
	c-0.3-0.3-0.8-0.5-1.3-0.3c-0.5,0-4.3,1-7.6,3.3c-3.3,2.3-5.1,4.6-5.8,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M255.3,115.4c0.5-1,1-2,1.3-2.8
	c0.3-0.5,0.8-1,1.3-1.3c0.3-0.3,0.5-0.3,0.8,0 M214.4,218.3c1,0,3.8-4.8,5.1-8.9c3-8.8,9.1-29.3,18.7-54.1
	c8.9-22.5,12.1-29.6,17.2-39.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M194.9,227.4c2.3-4,3.5-5.8,7.8-7.6
	c4.6-2,10.4-1.5,11.6-1.5 M252.3,119.2c-4,8.6-7.6,16.7-15.4,36.4c-9.9,24.8-15.2,45-18.2,53.9c-0.5,2.3-2.3,6.3-3,7.3 M255.3,115.4
	c-1,0-2.3,2.3-2.8,3.3c0,0.3-0.3,0.3-0.3,0.5 M244.2,211.2c1.3,2.3,6.8,10.1,7.1,10.6c0.5,0.5,0.8,1.3,1,2c0.3,0.8,0.5,1.5,0.5,2.3
	c0,0.5,0.3,1.5,0.3,1.8c0,0.5,2,8.3,2.3,9.1c0.3,0.8,0.5,2-0.3,2 M272.8,102.2c-2.5,7.3-11.6,33.4-19,57.6
	c-5.1,16.4-9.1,32.9-10.4,38.9c-1.5,7.8-0.5,9.9,0.8,12.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M201.5,220.3c3.3-9.6,6.8-22,23-55.4
	c8.6-17.9,19.2-34.1,25.5-43.5c1.3-1.8,2.5-2.3,2.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M204.3,219.3c3-9.4,7.1-22,22.5-54.4
	c7.3-15.4,16.2-29.6,22.5-39.2 M210.9,217.5c3.8-12.1,18.5-51.1,28.6-68.5 M195.7,226.9c0,0-8.6,6.1-11.9,8.9
	c-2.3,1.8-4,4.3-5.1,6.1c-0.5,0.8-0.5,1.5-0.8,2.3c-0.3,2-0.3,4-0.3,4 M179.2,240.8c2-2.3,11.4-2.5,29.8,3.8 M163.1,295.9
	c-0.3,3.8-1,17.2-0.8,36.9c0.3,22.5,4,54.4,4,54.4 M173.9,267.8c0,0-3.5,10.1-6.1,15.4s-4.3,10.1-4.6,11.6v1 M170.1,266.3
	c-1.3,3.5-2.5,7.6-3.3,11.1c-1,4.8-1.8,9.4-2.3,12.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M179,244.8c0,0.3-0.5,2-1.5,4.3
	c-0.5,1.3-1.5,3-2.5,5.6 M170.1,275.4c0.3,0.3,2.3-6.3,3.5-9.4s4.3-11.9,3.8-12.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M176.7,257.2c0,0-3.3-0.8-5.6-1.3
	c-2.5-0.5-3.5-0.5-3.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M176.7,254.4c0,0-6.3,0.3-8.1,0.3
	c-1.8,0.3-3,0.5-3.3,1.8c-0.3,1-0.5,3.3-0.5,4.8c0,1,0.3,1.8,1,2.5c0.3,0.3,1,0.8,1.3,1c1.3,0.8,5.8,2.8,5.8,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M170.1,255.7c-0.5,0-1,0-1,1.3
	c-0.3,1-0.5,3.8-0.5,4.8c0,1.3,0.3,2,1,2.8c0.8,0.8,2.8,2,2.8,2 M167.1,257.9c0.5,0,0.8,0,0.8,0.3s-0.3,1.5-0.3,2
	c0,0.5,0,0.5-0.3,0.5s-0.8,0-0.8-0.5v-1.8C166.9,258.2,166.9,257.9,167.1,257.9L167.1,257.9z M162.6,264.3c0.5,0,0.8,0,0.8,0.3
	c0,0.3-0.3,1.5-0.3,2c0,0.3,0,0.5-0.3,0.5s-0.8,0-0.8-0.5v-1.8C162.3,264.5,162.3,264.3,162.6,264.3L162.6,264.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M164.8,261.5c-0.5,0-3,0.3-3.3,1.5
	c-0.3,1-0.5,3.8-0.5,5.1s0.5,2.5,2,3.3c1.5,0.8,4.8,2.3,4.8,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M165.1,262.2c-0.3,0-0.5,0.3-0.8,1
	c-0.3,0.8-0.5,4.3-0.5,5.3s0.5,2,1.3,2.3c0.5,0.3,3.3,2,3.3,2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M167.4,264.8c0.8,2.8-0.8,4.3-1.5,4
	c-0.8-0.3-1.3-1.5-1-2.8c0.3-1.5,0.8-2,1.3-2.3 M163.3,295.4c0,0-1.5,1.3-1.5,2.3c0,1-1.3,15.2-0.3,39.9c0.8,18.5,2.5,38.2,3.5,47.3
	c0.3,1.8,0.3,3.5,0.5,4.5 M430.8,197.5c-91.8,0-149.7,3-185.6,6.3c-1,0-1.8,0.8-1.8,1.8s0.3,4.3,1.3,6.6 M603.2,111.1
	c5.6,12.1,9.9,20.2,19.5,44.7c9.6,25,15.7,44.5,18.7,53.3s6.3,12.6,8.3,12.6c2.3,0,6.8,0,10.4,1c3.5,0.8,6.3,4.3,6.6,4.6
	 M587.8,103.2l1-1c0.3-0.3,0.8-0.5,1.3-0.3c0.5,0,4.3,1,7.6,3.3s5.1,4.6,5.8,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M606.8,115.4l-1.5-3c-0.3-0.5-0.8-1-1.3-1.3
	c-0.3-0.3-0.5-0.3-0.8,0 M647.7,218.3c-1,0-3.8-4.8-5.1-8.9c-3-8.8-9.1-29.3-18.7-54.1c-8.8-22.5-12.1-29.3-16.9-39.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M666.9,227.4c-2-4-3.5-5.8-7.8-7.6
	c-4.5-2-10.4-1.5-11.6-1.5 M609.8,119.4c4,8.6,7.6,16.7,15.4,36.2c9.9,24.8,15.2,45,18.2,53.9c0.5,2.3,2.3,6.3,3,7.3 M606.5,115.4
	c1,0,2.3,2.3,2.8,3.3l0.3,0.8 M274.3,103c-1,2.8-9.1,25.5-9.4,25.8c0,0.3-0.3,0.5,0,0.8c0.3,0.2,0.8,0.8,0.8,0.8 M429.5,94.4
	c-64,0-118.6,3.5-154.7,8.3c-0.3,0-0.5,0.3-0.5,0.3 M587.8,103.5c0-0.3,0-0.3-0.3-0.5c0,0,0-0.3-0.5-0.3c-36.2-4.6-91-8.1-154.7-8.3
	 M596.4,130.5l0.8-0.8c0.3-0.3,0-0.5,0-0.8s-7.8-21.2-9.1-25.5 M616.9,212.4c-2,3-6.1,8.9-6.3,9.6c-0.5,0.5-0.8,1.3-1,2
	c-0.3,0.8-0.5,1.5-0.5,2.3c0,0.5-0.3,1.5-0.3,1.8c0,0.5-2,8.3-2.3,9.1c-0.3,0.8-0.5,2,0.3,2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M589.1,102.2c2.5,7.3,11.6,33.4,19,57.6
	c5.1,16.4,9.1,32.9,10.4,38.9c1.5,7.8,0.5,9.9-0.8,12.4c-0.3,0.3-0.5,0.8-0.8,1.3 M265,130.5c0,0-0.3,0-0.5,0.5
	c-3,9.1-6.3,19-9.1,28.8c-5.1,16.4-9.1,32.9-10.4,38.9c-0.5,2-0.8,3.5-0.8,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M596.9,130.5c0,0-60.7-10.1-166.1-10.1
	c-105.2,0-166.1,10.1-166.1,10.1 M617.9,203.8c-0.3-1.3-0.5-3-0.8-5.1c-1.3-6.1-5.3-22.5-10.4-38.9c-3-9.9-6.1-19.7-9.1-28.8
	c-0.3-0.5-0.5-0.5-0.5-0.5 M660.6,220.3c-3.3-9.6-6.8-22-23-55.4c-8.6-17.9-19.2-34.1-25.5-43.5c-1.3-1.8-2.5-2.3-2.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M657.8,219.3c-3-9.4-7.1-22-22.5-54.4
	c-7.3-15.4-16.2-29.6-22.5-39.2 M651.3,217.5c-3.8-12.1-18.5-51.1-28.6-68.5 M666.2,226.9c0,0,8.6,6.1,11.9,8.9
	c2.3,1.8,4,4.3,5.1,6.1c0.5,0.8,0.5,1.5,0.8,2.3c0.3,2,0.3,4,0.3,4 M682.6,240.8c-2-2.3-11.4-2.5-29.8,3.8 M698.5,294.9
	c0.3,1.5,1.3,15.4,0.8,37.9c-0.3,22.5-4,54.4-4,54.4 M687.9,267.8c0,0,3.5,10.1,6.3,15.4c2.5,5.3,4.3,10.1,4.5,11.6 M692.7,360.1
	c0,0-7.3-4.8-15.4-9.4s-19.5-8.6-49-9.9c-29.6-1.5-106.4-3.8-197.5-3.8s-167.9,2.3-197.5,3.8c-29.6,1.3-41,5.6-49,9.9
	c-8.1,4.5-15.4,9.4-15.4,9.4 M534.2,264.3c-24-0.5-57.9-1.3-103.1-1.3c-45.5,0-79.4,0.8-103.1,1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M691.7,266.3c1.3,3.5,2.5,7.6,3.3,11.1
	c1,4.8,1.8,9.4,2.3,12.9 M682.9,244.8c0,0.3,0.5,2,1.5,4.3c0.5,1.3,1.5,3,2.5,5.6 M691.7,275.4c-0.5,0.3-2.3-6.3-3.5-9.4
	s-4.3-11.9-3.8-12.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M685.1,257.2c0,0,3.3-0.8,5.6-1.3
	c2.5-0.5,3.5-0.5,3.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M685.1,254.4c0,0,6.3,0.3,8.1,0.3
	c1.8,0.3,3,0.5,3.3,1.8c0.2,1,0.5,3.3,0.5,4.8c0,1-0.3,1.8-1,2.5c-0.3,0.3-1,0.8-1.5,1c-1.3,0.8-5.8,2.8-5.8,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M691.7,255.7c0.5,0,1,0,1,1.3
	c0.3,1,0.5,3.8,0.5,4.8c0,1.3-0.3,2-1,2.8c-0.8,0.8-2.8,2-2.8,2 M694.7,257.9h-0.3c-0.3,0-0.5,0-0.5,0.3s0.3,1.5,0.3,2
	c0,0.5,0,0.5,0.3,0.5s0.8,0,0.8-0.5v-1.8C695.3,258.5,695.3,257.9,694.7,257.9L694.7,257.9z M699.3,264.3H699c-0.3,0-0.5,0-0.5,0.3
	c0,0.3,0.3,1.5,0.3,2c0,0.3,0,0.5,0.3,0.5s0.8,0,0.8-0.5v-1.8C699.6,264.5,699.6,264.3,699.3,264.3L699.3,264.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M697,261.5c0.5,0,3,0.3,3.3,1.5
	c0.3,1,0.5,3.8,0.5,5.1s-0.5,2.5-2,3.3c-1.5,0.8-4.8,2.3-4.8,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M697,262.2c0.3,0,0.5,0.3,0.8,1
	c0.3,0.8,0.5,4.3,0.5,5.3s-0.5,2-1.3,2.3c-0.5,0.3-3.3,2-3.3,2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M694.7,264.8c-0.8,2.8,0.8,4.3,1.5,4
	s1.3-1.5,1-2.8c-0.3-1.5-0.8-2-1.3-2.3 M643.2,241.3c-4.5-2.5-12.6-8.8-36.2-14.7c-28.6-7.3-79.4-14.2-176-14.2
	c-96.6,0-147.4,6.8-176,14.2c-23.5,5.8-31.6,12.1-36.2,14.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M650.2,242.5c-6.6-4.8-15.2-13.1-42.5-20.2
	c-29.6-7.6-81.7-13.9-176.7-13.9c-95.3,0-147.1,6.3-176.7,13.9c-27.3,7.1-35.9,15.4-42.5,20.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M605.5,239c0,0-13.9,55.1-19.7,69.5
	c-5.8,14.7-11.4,20-30.6,20.2c-19.5,0.3-68.5-0.8-124.1-0.8c-55.9,0-104.9,1-124.1,0.8c-19.5-0.3-25-5.6-30.6-20.2
	c-5.8-14.7-19.7-69.5-19.7-69.5 M179.8,444.3c4.3-2.5,13.1-8.6,17.7-11.6c1.3-0.8,2-1,2.8-1c0.8,0,2.8,0.3,5.3-1
	c2.5-1,9.4-4,11.6-4.8c1.3-0.3,3-0.5,4.6-0.5 M430.8,417.7c-53.1,0-96.3,0.8-123.1,0.8h-6.1c0,0-2-0.3-0.5-1
	c1.5-0.8,2.8-1.3,3.5-1.5c0.8-0.3,3-0.8,4-0.8c36.2-1.3,91.3-2.5,122.4-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M163.6,303.7c0.3,0,0.5,1,0.3,2.3
	c0,1.5,1,29.8,1.3,38.4c0,2.8,0.5,6.8,0.8,11.6c1.5,17.4,4,44,6.3,58.9c0.3,2.8,0.8,5.6-0.8,8.1c-0.2,0.5-0.5,1-0.5,1.5
	c0.3,0.5,3.5,11.1,7.8,19c0.3,0.5,0.8,1,1.5,1c1,0,11.1,1,11.1,1c0.3,0.5,0.8,1.3,2,0.8c1-0.5,8.6-5.8,13.4-8.9
	c4.8-2.8,12.1-6.8,13.9-7.8c1.8-1,2.3-1.8,2.8-3.8c0.5-2,2-7.8,4.3-11.6c2-3.8,4-5.6,9.6-6.1c5.3-0.8,29.3-2.3,39.9-2.5
	c2.8,0,7.3,0,9.9,3.8s3.8,6.6,4,8.3c0.5,1.5,0.8,4-0.5,6.6c-0.3,0.5-0.5,0.8-0.3,1.3c0.3,0.5,3,4,3,4s0.5,0.5,1.5,0.5h4.5l1.8,0.3
	c0,0,0.8,0.3,2,0.3c0.5,0,25-0.3,52.3-0.3c0.3,0,0.5-0.3,0.5-0.5s0.3-0.8,0.5-0.8h0.8c0,0,0.2,0,0.2,0.3c0,0.3,0.3,0.8,1,0.8
	c30.1,0,62.7-0.3,71.5-0.3 M698.5,295.4c0,0,1.5,1.3,1.5,2.3c0,1,1.3,15.2,0.2,39.9c-1,24.8-4,52.1-4,52.1 M637.1,240.2
	c-10.4-5.1-28.6-7.8-39.7-9.4c-20.5-2.8-119.8-6.6-166.4-6.6c-46.5,0-145.9,3.8-166.6,6.6c-11.1,1.5-29.3,4.3-39.7,9.4 M431.3,197.5
	c91.8,0,149.7,3,185.6,6.3c1,0,1.8,0.8,1.8,1.8s-0.3,4.3-1.3,6.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M431.1,195.8c89.5,0,137,2.3,166.6,3.8
	c1.8,0,3.8,0,4.3-0.8s0.8-1.5,0.5-3c-1.3-6.8-13.7-52.8-14.9-57.6c-1.5-4.8-7.8-6.6-10.4-6.8c-25.3-2.8-47.8-5.1-94.3-6.8
	c-0.8,0-1.5,2.5-2.8,2.5s-32.6-0.8-49-0.8s-47.8,0.5-49,0.8c-1.3,0-2-2.5-2.8-2.5c-46.8,1.8-69,4-94.3,6.8c-2.5,0.3-8.9,2-10.4,6.8
	c-1.5,4.8-13.7,50.8-14.9,57.6c-0.3,1.5-0.3,2.3,0.5,3c0.8,0.8,2.5,1,4.3,0.8C294,198,341.3,195.8,431.1,195.8 M438.6,105.2
	c0.3,0.8-0.5,2-1.5,2c-1,0-4.8,0.3-6.1,0.3c-1.5,0-5.3-0.3-6.1-0.3s-1.3-0.8-1.5-1.5 M423.2,105.8v-0.5c0.3-0.8,1-3,1-3.5
	c0.3-0.5,0.5-1,1-1c0.5-0.3,2-0.5,2.5-0.8c0.5,0,1-1,1-1.5c0-0.8,0.8-6.3,1-7.1c0.3-0.8,0.8-1,1-1c0.3,0,1,0.3,1,1
	c0.3,0.8,1,6.6,1,7.1c0,0.8,0.5,1.5,1,1.5s2,0.5,2.5,0.8c0.5,0.3,1,0.5,1,1c0.3,0.5,1,2.8,1,3.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M437.9,102.7c0,0.3,0,0.5-0.8,0.5h-11.9
	c-0.8,0-0.8-0.5-0.8-0.5 M203.3,214.2c0,0-3.3-0.3-6.1-0.5c-2.8-0.3-3.5-1.8-4.6-3.5c-1-2-5.8-11.4-7.8-13.7c-2-2.5-4.8-4.3-9.1-4.3
	c-4.3,0.3-13.4,0.8-21.2,1.8c-3.8,0.5-7.3,1.3-9.9,2c-8.3,2.8-11.6,5.1-14.2,8.9c-2.5,3.8-3.8,7.3-4,8.3c-0.3,0.8-0.3,1.8,0,2.5
	c0.3,0.8,2,5.3,4,8.9c2.3,3.5,6.1,6.3,12.4,7.3c6.3,1,24.5,3,32.4,3.3c4.3,0.3,6.6-0.8,7.6-1.3c1-0.5,3.3-0.3,3.3-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M191.9,212.9c0.3,0.8,1.5,2.3,4.3,2.5
	c2.8,0.3,6.6,0.5,6.6,0.5 M132.2,213.4c-0.5,1.3,0,3,0.5,4c0.5,1.5,2.3,4.3,3.5,6.1c1.3,1.8,3.3,3.8,8.1,4.6c4.8,0.8,26.8,3,31.1,3
	s6.1-1.5,6.8-3.8c1-2.3,3.3-9.1,3.8-11.1c0.5-2,0.8-3-0.3-5.6c-1-2.5-3-6.8-4.6-10.1c-1.8-3.3-3-5.1-7.3-5.1c-4.3,0.3-17.7,1.3-25,3
	s-11.4,5.1-13.1,7.8C133.7,209.2,132.7,211.9,132.2,213.4L132.2,213.4z M127.7,219h2.5l1.3,2.5h-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M130.7,221.8c0.8,1.5,4.3,7.6,11.4,8.6
	c6.8,1.3,28.3,3,34.1,3.3c6.1,0.3,8.3-3.5,9.4-6.3c1-2.5,3.3-8.6,3.5-10.9c0.5-2,0.5-3.5-0.3-5.8c-0.8-2.3-3.5-9.4-5.6-12.1
	c-2.3-3.3-4.3-5.1-8.9-4.8c-3.3,0-19.7,1.5-27.3,3.5c-6.6,1.5-12.1,5.1-14.4,7.8c-2,2.5-4,6.8-4.3,7.8c-0.3,0.8-0.5,1.8-0.3,2.8
	c0.3,1,1,3,1,3 M184.1,195.5c0,0-0.5,0.3,0,0.8c0.3,0.3,2,3.3,3.5,6.6c1,2,2,4.6,2.3,5.3c0.8,1.8,1,1.5,1,1.3c0-0.3,0.3-1.8-0.3-2.8
	 M676.8,234.7c0,0,2.5,0.8,4.5,0.5c7.3-0.5,24-1.8,29.8-3c5.8-1,10.6-4.3,12.6-7.8c2-3.5,3.5-8.1,3.8-8.9c0.3-0.8,0.3-1.8,0-2.5
	c-0.2-0.8-1.3-4.3-3.5-8.3c-2.5-3.8-5.6-6.1-13.1-8.9c-2.3-0.8-5.6-1.5-8.8-2c-7.3-1.3-15.7-1.8-19.5-1.8c-4-0.3-6.8,1.8-8.6,4
	c-2,3-5.6,12.4-6.3,14.4c-0.8,1.8-0.8,3.3-0.3,4.6c0.3,1,2,7.8,3.8,12.4c0.5,1.5,1,2.5,1.5,3.3 M658.6,214.2c0,0,3-0.3,5.6-0.3
	c2,0,2.8-0.5,2.8-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M721.3,213.4c0.3,1.3,0,3-0.3,4
	c-0.5,1.5-2,4.3-3.3,6.1c-1.3,1.8-3,3.8-7.3,4.6s-24.3,3-28.1,3c-3.8,0-5.6-1.5-6.3-3.8c-0.8-2.3-3-9.1-3.5-11.1
	c-0.5-2-0.8-3,0.3-5.6c1-2.5,2.5-6.8,4.3-10.1c1.5-3.3,2.8-5.1,6.6-5.1c3.8,0.3,15.9,1.3,22.5,3c6.6,1.8,10.4,5.1,11.9,7.8
	C720,209.2,720.8,211.9,721.3,213.4L721.3,213.4z M726.1,219h-3.3l-1.3,2.5h3.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M722.3,221.8c-0.8,1.5-4,7.6-10.4,8.6
	c-6.3,1.3-26,3-31.6,3.3s-7.8-3.5-8.6-6.3c-0.8-2.5-3-8.6-3.3-10.9c-0.3-2-0.5-3.5,0.3-5.8c0.8-2.3,3.3-9.4,5.1-12.1
	c2-3.3,4-5.1,8.1-4.8c3,0,19,1.8,26,3.5c6.1,1.5,10.6,5.1,12.6,7.8c2,2.5,3.8,6.8,4,7.8c0.3,0.8,0.5,1.8,0.3,2.8c-0.3,1-1,3-1,3
	 M659.6,226.3c-0.5,0-1,0.3,0.3,0.8c1.3,0.5,9.6,6.3,13.7,11.1c0.3,0.5,0.5,1.5,0.5,2.3s-0.3,2.3,0.8,4c1,1.8,6.1,12.6,7.1,14.7
	c1,2,1.8,2.5,2.5,2.8c0.8,0,0.8-1,0.3-2c-0.3-1-4-10.1-6.3-14.7c-0.8-1.5-1-2.5-0.8-3.5c0.3-1,0.3-2.5-0.5-3.5
	c-0.5-0.5-3.5-3.3-6.6-5.6c-3.3-2.5-6.8-4.8-7.3-5.1C662.1,227.1,661.4,226.6,659.6,226.3L659.6,226.3z M166.1,387.9
	c0,0.8-2.5,5.6-2.5,9.9c-0.3,4.6,4,14.4,5.1,18"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M198.2,443.5c50.6,4.8,94.6,7.6,101.6,7.6c9.4,0,14.4-8.1,14.4-8.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M625.5,470.6H236.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M430.8,443.3c-34.1,0-82.2,1.5-122.4-0.3 M449.8,256.2
	c-1.8,0-3.3-0.5-4.5-1.5c1.3-1.5,2-3.5,2-5.6s-0.8-4-2-5.6c1.3-1,2.8-1.5,4.5-1.5c4.3,0,7.6,3.3,7.6,7.1
	C457.4,252.9,454.1,256.2,449.8,256.2L449.8,256.2z M442.2,249.1c0-1.5,0.5-2.8,1.3-4c0.8,1.3,1.3,2.5,1.3,4c0,1.5-0.5,2.8-1.3,4
	C442.7,251.9,442.2,250.6,442.2,249.1L442.2,249.1z M441.9,254.7c-1.3,1-2.8,1.5-4.6,1.5s-3.3-0.5-4.5-1.5c1.3-1.5,2-3.5,2-5.6
	s-0.8-4-2-5.6c1.3-1,2.8-1.5,4.5-1.5s3.3,0.5,4.5,1.5c-1.3,1.5-2,3.5-2,5.6C439.7,251.1,440.4,253.1,441.9,254.7L441.9,254.7z
	 M431.1,253.1c-0.8-1-1.3-2.5-1.3-4c0-1.5,0.5-2.8,1.3-4c0.8,1.3,1.3,2.5,1.3,4C432.3,250.6,431.8,251.9,431.1,253.1L431.1,253.1z
	 M429.3,254.7c-1.3,1-2.8,1.5-4.6,1.5s-3.3-0.5-4.5-1.5c1.3-1.5,2-3.5,2-5.6s-0.8-4-2-5.6c1.3-1,2.8-1.5,4.5-1.5s3.3,0.5,4.5,1.5
	c-1.3,1.5-2,3.5-2,5.6S428,253.1,429.3,254.7L429.3,254.7z M418.4,253.1c-0.8-1-1.3-2.5-1.3-4c0-1.5,0.5-2.8,1.3-4
	c0.8,1.3,1.3,2.5,1.3,4C419.7,250.6,419.2,251.9,418.4,253.1L418.4,253.1z M412.1,256.2c-4,0-7.6-3.3-7.6-7.1c0-4,3.3-7.1,7.6-7.1
	c1.8,0,3.3,0.5,4.5,1.5c-1.3,1.5-2,3.5-2,5.6s0.8,4,2,5.6C415.4,255.7,413.9,256.2,412.1,256.2L412.1,256.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M449.8,239.7c-2.3,0-4.5,0.8-6.3,2c-1.8-1.3-3.8-2-6.3-2
	c-2.3,0-4.5,0.8-6.3,2c-1.8-1.3-3.8-2-6.3-2c-2.3,0-4.5,0.8-6.3,2c-1.8-1.3-3.8-2-6.3-2c-5.6,0-10.1,4-10.1,9.4
	c0,5.1,4.6,9.4,10.1,9.4c2.3,0,4.5-0.8,6.3-2c1.8,1.3,3.8,2,6.3,2c2.5,0,4.6-0.8,6.3-2c1.8,1.3,3.8,2,6.3,2c2.3,0,4.6-0.8,6.3-2
	c1.8,1.3,3.8,2,6.3,2c5.6,0,10.1-4,10.1-9.4C459.9,244,455.3,239.7,449.8,239.7L449.8,239.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.9,249.1c0,4.6-3.8,8.1-8.6,8.1c-4.8,0-8.6-3.5-8.6-8.1
	s3.8-8.1,8.6-8.1C416.9,241,420.9,244.5,420.9,249.1L420.9,249.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M424.7,256.9c-4.8,0-8.6-3.5-8.6-8.1s3.8-8.1,8.6-8.1
	c4.8,0,8.8,3.5,8.8,8.1C433.3,253.4,429.5,256.9,424.7,256.9L424.7,256.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M437.1,256.9c-4.8,0-8.6-3.5-8.6-8.1s3.8-8.1,8.6-8.1
	c4.8,0,8.6,3.5,8.6,8.1C446,253.4,441.9,256.9,437.1,256.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M440.9,249.1c0,4.6,3.8,8.1,8.6,8.1c4.8,0,8.6-3.5,8.6-8.1
	s-3.8-8.1-8.6-8.1C445,241,440.9,244.5,440.9,249.1L440.9,249.1z M418.4,241.8v3.3 M420.2,243.5l-1.8-0.5l-1.8,0.5 M418.4,253.1v3.3
	 M416.6,254.7h3.5 M443.4,241.8v3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M445.2,243.5l-1.8-0.5l-1.5,0.5 M443.4,253.1v3.3 M441.9,254.7h3.3
	 M429.3,254.7h3.3 M431.1,253.1v3.3 M431.1,241.8v3.3 M429.3,243.5l1.8-0.5l1.5,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M219.4,281.2c0.5,5.8,2.5,25,3.5,30.8
	c1,5.8,2.3,10.4,11.6,10.6c9.4,0,44.2-0.3,49.3-0.3c43.5-0.8,117.6-2,146.9-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M642.2,281.2c-0.5,5.8-2.5,25-3.5,30.8
	s-2.3,10.4-11.6,10.6c-9.4,0-44.2-0.3-49.3-0.3c-43.5-0.8-117.6-2-146.9-2 M299.6,430.9c1.8-0.5,5.1-0.8,6.6-0.8
	c1.5,0,110-0.5,124.9-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M221.2,434.7c0,0,13.4,7.3,38.4,9.1c25,1.8,33.9-2.3,36.2-3.3
	c3.5-1.8,7.1-5.3,8.6-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M226.8,420.3c0.8-2,2.5-5.6,3.3-7.1
	c1-1.3,3-2.5,6.1-3c3-0.5,10.6-1.3,22-2c11.1-0.5,17.7-0.8,20.7-0.8s5.8,0.8,7.6,3.5c1.8,2.8,3,5.6,3.8,7.1c0.8,1.8,1.3,6.3-4.3,6.6
	c-5.6,0.3-18,0.5-28.8,0.5h-26C228,425.6,225.8,423.3,226.8,420.3L226.8,420.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M258.1,410.4c-11.1,0.5-17.9,1.5-21,2
	c-3.3,0.5-4.3,1.8-4.8,2.3c-0.8,1-2.3,4.3-3,6.6c0,0.3-0.3,0.5-0.3,0.8c0,0.3,0,0.5,0.3,0.8c0.5,0.5,1.3,1,2.3,1h25
	c11.1,0,22.5-0.3,27.8-0.5c1.3,0,2-0.5,2.5-1c0.5-0.8,0.3-2,0-2.8c-0.8-1.8-2-4.6-3.5-7.1c-1-1.8-2.8-2.5-6.1-2.5
	C274.8,409.4,268.7,409.6,258.1,410.4L258.1,410.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M430.8,385.4c-45.5,0-144.9,2.3-191.1,5.6
	c-14.4,1-19.5,4-22.8,5.8c-3.3,2-9.6,7.6-12.6,15.7c-1.8,4.5-3.3,10.1-4.6,16.2v0.3c-0.3,1-0.3,1.8-0.3,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M430.8,397.5c-43.7,0-143.9,2.3-190.9,5.6
	c-9.4,0.8-18.5,2.3-22.8,22.5 M172.7,422c5.1-2.5,13.1-6.1,16.2-7.3c2.8-1.3,3.8-1.5-0.5,0.5c-4.3,2-12.1,5.8-16.4,8.3 M682.1,444.3
	c-4.3-2.5-13.1-8.6-17.7-11.6c-1.3-0.8-2-1-2.8-1s-2.8,0.3-5.3-1c-2.5-1-9.4-4-11.6-4.8c-1.3-0.3-3-0.5-4.5-0.5
	c-0.5,0-0.5-0.5-0.5-0.5 M430.8,417.7c53.1,0,96.3,0.8,123.1,0.8h6.1c0,0,2-0.3,0.5-1c-1.5-0.8-2.8-1.3-3.5-1.5
	c-0.8-0.3-3-0.8-4-0.8c-36.2-1.3-91.3-2.5-122.4-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M698,303.7c-0.3,0-0.5,1-0.3,2.3
	c0,1.5-1,29.8-1.3,38.4c0,2.8-0.5,6.8-0.8,11.6c-1.5,17.4-4,44-6.1,58.9c-0.3,2.8-0.8,5.6,0.8,8.1c0.3,0.5,0.5,1,0.5,1.5
	c-0.3,0.5-3.5,11.1-7.8,19c-0.3,0.5-0.8,1-1.5,1c-1,0-11.1,1-11.1,1c-0.3,0.5-0.8,1.3-2,0.8c-1-0.5-8.6-5.8-13.4-8.9
	c-4.8-2.8-12.1-6.8-13.9-7.8c-1.8-1-2.3-1.8-2.8-3.8c-0.5-2-2-7.8-4.3-11.6c-2-3.8-4-5.6-9.6-6.1c-5.3-0.8-29.3-2.3-39.9-2.5
	c-2.8,0-7.3,0-9.9,3.8c-2.5,3.8-3.5,6.6-4,8.3c-0.5,1.5-0.8,4,0.5,6.6c0.3,0.5,0.5,0.8,0.3,1.3c-0.3,0.5-3,4-3,4s-0.5,0.5-1.5,0.5
	h-4.5l-1.8,0.3c0,0-0.8,0.3-2,0.3c-0.5,0-25-0.3-52.3-0.3c-0.3,0-0.5-0.3-0.5-0.5s-0.3-0.8-0.5-0.8h-0.8c0,0-0.3,0-0.3,0.3
	c0,0.3-0.3,0.8-1,0.8c-30.1,0-62.7-0.3-71.5-0.3 M695.5,387.9c0,0.8,2.5,5.6,2.5,9.9c0,4.6-1.5,11.9-2.5,15.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M663.4,443.5c-50.6,4.8-94.6,7.6-101.6,7.6c-9.4,0-14.4-8.1-14.4-8.1
	 M430.8,443.3c33.9,0,82.2,1.5,122.4-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M562.3,430.9c-1.8-0.5-5.1-0.8-6.6-0.8
	c-1.5,0-110-0.5-124.6-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M640.4,434.7c0,0-13.4,7.3-38.4,9.1c-25,1.8-33.9-2.3-36.2-3.3
	c-3.5-1.8-6.8-5.3-8.6-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M634.8,420.3c-0.8-2-2.5-5.6-3.3-7.1
	c-1-1.3-3-2.5-6.1-3c-3-0.5-10.6-1.3-22-2c-11.1-0.5-17.7-0.8-20.7-0.8s-5.8,0.8-7.6,3.5s-3,5.6-3.8,7.1c-0.8,1.8-1.3,6.3,4.3,6.6
	c5.6,0.3,17.9,0.5,28.8,0.5h25.8C633.6,425.6,636.1,423.3,634.8,420.3L634.8,420.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M603.5,410.4c11.1,0.5,18,1.5,21,2
	c3.3,0.5,4.5,1.8,4.8,2.3c0.8,1,2.3,4.3,3,6.6c0,0.3,0.3,0.5,0.3,0.8c0,0.3,0,0.5-0.3,0.8c-0.5,0.5-1.3,1-2.3,1h-25
	c-11.1,0-22.5-0.3-27.8-0.5c-1.3,0-2-0.5-2.5-1c-0.5-0.8-0.3-2,0-2.8c0.8-1.8,2-4.6,3.5-7.1c1-1.8,2.8-2.5,6.1-2.5
	C586.8,409.4,592.9,409.6,603.5,410.4L603.5,410.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M430.8,385.4c45.5,0,144.9,2.3,191.1,5.6
	c14.4,1,19.5,4,22.8,5.8c3.3,2,9.6,7.6,12.6,15.7c1.8,4.5,3.3,10.1,4.5,16.2v0.3c0.3,1,0.3,1.8,0.3,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M430.8,397.5c43.7,0,143.9,2.3,190.9,5.6
	c9.4,0.8,18.5,2.3,22.8,22.5 M689.2,422c-5.1-2.5-13.1-6.1-16.2-7.3c-2.8-1.3-3.8-1.5,0.5,0.5c4.3,2,12.1,5.8,16.4,8.3 M258.4,241.3
	c0,0,8.9,34.4,8.9,34.9c0,0.5,0.8,0.5,0.8,0.5s54.4-7.3,54.9-7.3c0.8-0.3,0.8-0.8,0.8-0.8s8.6-18.2,9.4-20.2c0.5-1.3,1-2.5,0-3.8
	c-0.5-0.5-2-1-3.5-1.3c-2.8-0.3-12.9-1-31.9-1.5C287.5,241.5,273.8,241.3,258.4,241.3L258.4,241.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M326.9,248.3c0,0-4.3,7.1-5.8,9.1
	c-1.8,2.3-1.8,2.5-6.1,2.8c-3.5,0.3-51.6,0.8-51.6,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M328.4,248.3c0,0-5.3,8.9-6.8,10.9
	c-1.8,2.3-1.8,2.5-6.1,2.8c-3.5,0.3-51.6,1.3-51.6,1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M329.9,248.3c0,0-6.1,10.4-7.6,12.4
	c-1.8,2.3-2,2.5-6.1,2.8c-3.5,0.3-51.8,1.5-51.8,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M331.2,248.3c0,0-6.6,11.9-8.1,13.9
	c-1.8,2.3-2.8,2.8-6.8,3c-3.5,0.3-51.3,1.8-51.3,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M332.7,248.6c0,0-6.8,12.4-8.3,14.4
	c-1.8,2.3-3.3,3.3-7.3,3.5 M268,275.6l-1.8-7.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M265.5,268.6c11.9-0.8,42-1.8,58.4-2.3l-1,2
	 M259.9,247.1c12.9,0.3,63.2,1,73.1,1.5 M259.1,243.5c11.9,0.3,56.9,1,70.8,1.5c1,0,2.5,0.3,2.3,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M324.9,248.3c-1.3,2.3-3.8,6.1-4.8,7.6
	c-1.8,2.3-1.8,2.5-6.1,2.8c-3.5,0.3-50.3,0.8-50.3,0.8l-1-3.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M316.5,248.1c-1,1.8-2.3,3.3-3,4.3
	c-1.8,2.3-1.8,2.5-6.1,2.8c-2.8,0.3-33.9,0.3-45.8,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M315.3,248.1c-0.8,1.3-1.8,2.5-2.3,3
	c-1.8,2.3-1.8,2.5-6.1,2.8c-2.8,0.3-32.9,0-44.8,0.3l-0.8-3.3 M260.9,250.6c6.6,0,46.8,0.3,52.1,0.3 M267.2,250.6l0.5,3.3
	 M273,250.9v3 M236.1,250.9h4.8 M230.8,250.9h5.1 M278.9,250.9l-0.3,3 M284.4,250.9l-0.5,3 M290.2,250.9l-1,3 M296.1,250.9l-1.5,3
	 M301.6,250.9l-1.8,3 M307.4,250.9l-2,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M294.5,250.1l-24-0.3v-1.5l24,0.3V250.1z
	 M291.5,267.3v5.1 M205.5,279.4c3-0.3,54.6-2.5,57.1-2.8c0.8,0,1-0.3,0.8-0.8l-8.9-34.9c-19.2,0.3-32.1,1.8-37.7,3s-7.1,3.8-7.6,5.3
	c-1.8,6.1-4.6,25.5-4.8,29.1C204.5,278.7,204,279.4,205.5,279.4L205.5,279.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M213.1,245.6c3.3-0.8,13.1-1.5,20-1.8
	s17.9,0,21.7,0 M209.3,249.4c3.3-0.8,17.2-2,24-2c6.8-0.3,19,0,22.8,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M256.9,250.6h-14.4c-1,0-1.5-0.5-2-1.5
	c-0.5-1.3-0.8-1.8-0.8-1.8 M217.2,244.8c-0.3,0.3-1,1.5-1,2.8 M228,247.6c0,0,1.8,4.6,2.3,5.6c0.5,1,1.3,2.5,3.5,2.3
	c2.3,0,20.7-0.3,24.5-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M222.5,247.8c0,0,2.5,8.3,2.8,9.4
	c0.5,2,1,2.5,3.5,2.3c2.3,0,25.8-0.5,29.3-0.5l-1-3.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M220.2,248.1c0,0,3,10.1,3.3,11.1
	c0.8,2.3,2.3,2.5,4.8,2.5c2.3,0,27.6-0.5,31.4-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M229.3,247.3c0.5,1.5,1.5,3.8,1.8,4.3
	c0.5,1,1.3,2.5,3.5,2.3c2,0,16.4,0,22.5-0.3l-0.8-3.3 M236.1,250.9l1.3,3.3 M234.9,247.1l1.3,3.5 M241.2,250.4h0.5 M241.2,250.1v0.3
	 M240.9,250.9l1.3,3.3 M239.7,247.1l1.3,3.3 M246,250.9l1,3 M251.1,250.9l1,3 M251.6,250.9h4.8 M246.5,250.9h4.6 M241.4,250.9h4.6
	 M261.2,267.3c-7.1,0.3-29.1,1.3-32.6,1.5c-3.3,0.2-5.6,1-6.8,3.5c-0.8,1.8-2,4.3-2.5,5.6 M260.1,263.3c-4,0-32.4,1-39.7,1.3
	c-1.8,0-2.5,0-2.8-0.2c-0.3,0-0.8-0.3-1.5,0c-0.5,0-5.1,0.2-7.6,0.5c-0.3,0.5-1,1.5-0.3,3c2.5-0.3,7.1-0.5,7.6-0.5s1.3-0.3,1.8-0.3
	c0.5-0.3,2.3-0.5,2.8-0.5c7.3-0.3,35.9-1.3,39.9-1.5 M208.6,267.8c-0.5,3.3-0.8,6.8-1.3,10.6 M211.9,248.8
	c-0.8,3.5-1.8,8.8-2.8,15.7 M214.9,248.6c0.5,3,1.8,13.1,2.3,15.7 M216.9,248.3c0.5,4.3,2.3,13.7,3,16.2 M216.9,267.3
	c-0.8,2.3-2.3,7.6-3,11.1 M219.2,266.8c-0.8,2.3-2.8,8.1-3.5,11.4 M603,241.3c0,0-8.8,34.4-8.8,34.9c0,0.5-0.8,0.5-0.8,0.5
	s-54.4-7.3-54.9-7.3c-0.8-0.3-0.8-0.8-0.8-0.8s-8.6-18.2-9.4-20.2c-0.5-1.3-1-2.5,0-3.8c0.5-0.5,2-1,3.5-1.3
	c2.8-0.3,12.9-1,31.9-1.5C573.9,241.5,587.6,241.3,603,241.3L603,241.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M534.5,248.3c0,0,4.3,7.1,5.8,9.1
	c1.8,2.3,1.8,2.5,6.1,2.8c3.5,0.3,51.6,0.8,51.6,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M532.9,248.3c0,0,5.3,8.9,6.8,10.9
	c1.8,2,1.8,2.5,6.1,2.8c3.5,0.3,51.6,1.3,51.6,1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M531.4,248.3c0,0,6.1,10.4,7.6,12.4
	c1.8,2.3,2,2.5,6.1,2.8c3.5,0.3,51.8,1.5,51.8,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M530.2,248.3c0,0,6.6,11.9,8.1,13.9
	c1.8,2.3,2.8,2.8,6.8,3c3.5,0.3,51.3,1.8,51.3,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M528.6,248.6c0,0,6.8,12.4,8.3,14.4
	c1.8,2.3,3.3,3.3,7.3,3.5 M593.4,275.6l1.8-7.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M595.9,268.6c-11.9-0.8-42-1.8-58.4-2.3l1,2
	 M601.5,247.1c-12.9,0.3-63.2,1-73.1,1.5 M602.5,243.5c-11.9,0.3-56.9,1-70.8,1.5c-1,0-2.5,0.3-2.3,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M536.5,248.3c1.3,2.3,3.8,6.1,4.8,7.6
	c1.8,2.3,1.8,2.5,6.1,2.8c3.5,0.3,50.3,0.8,50.3,0.8l1-3.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M544.8,248.1c1,1.8,2.3,3.3,3,4.3
	c1.8,2.3,1.8,2.5,6.1,2.8c2.8,0.3,33.9,0.3,45.8,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M546.3,248.1c0.8,1.3,1.8,2.5,2.3,3
	c1.8,2.3,1.8,2.5,6.1,2.8c2.8,0.3,32.9,0,44.7,0.3l0.8-3.3 M600.4,250.6c-6.6,0-46.8,0.3-52.3,0.3 M594.1,250.6l-0.3,3.3
	 M588.3,250.9v3 M625.2,250.9h-4.8 M630.5,250.9h-5.1 M582.8,250.9l0.2,3 M576.9,250.9l0.8,3 M571.1,250.9l1,3 M565.6,250.9l1.3,3
	 M559.7,250.9l1.8,3 M553.9,250.9l2,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M567.1,250.1l23.8-0.3v-1.5l-23.8,0.3L567.1,250.1
	z M569.9,267.3v5.1 M656.1,279.4c-3-0.3-54.6-2.5-57.1-2.8c-0.8,0-1-0.3-0.8-0.8L607,241c19.2,0.3,32.1,1.8,37.7,3
	c5.6,1.3,7.1,3.8,7.6,5.3c1.8,6.1,4.5,25.5,4.8,29.1C657.1,278.7,657.3,279.4,656.1,279.4L656.1,279.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M648.2,245.6c-3.3-0.8-13.1-1.5-20-1.8
	s-18,0-21.7,0 M652,249.4c-3.3-0.8-17.2-2-24-2c-6.8-0.3-19,0-22.8,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M604.5,250.6h14.4c1,0,1.5-0.5,2-1.5
	c0.5-1.3,0.8-1.8,0.8-1.8 M644.2,244.8c0.3,0.3,1,1.5,1,2.8 M633.3,247.6c0,0-1.8,4.6-2.3,5.6s-1.3,2.5-3.5,2.3
	c-2.3,0-20.7-0.3-24.5-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M638.9,247.8c0,0-2.5,8.3-2.8,9.4
	c-0.5,2-1,2.5-3.5,2.3c-2.3,0-25.8-0.5-29.3-0.5l1-3.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M641.2,248.1c0,0-3,10.1-3.3,11.1
	c-0.8,2.3-2.3,2.5-4.8,2.5c-2.3,0-27.6-0.5-31.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M632,247.3c-0.5,1.5-1.5,3.8-1.8,4.3
	c-0.5,1-1.3,2.5-3.5,2.3c-2,0-16.4,0-22.5-0.3l0.8-3.3 M625.2,250.9l-1.3,3.3 M626.5,247.1l-1.3,3.5 M620.2,250.4h-0.5 M620.4,250.1
	l-0.3,0.3 M620.4,250.9l-1,3.3 M621.7,247.1l-1.3,3.3 M615.4,250.9l-1,3 M610.3,250.9l-1,3 M610.1,250.9h-4.8 M614.9,250.9h-4.5
	 M619.9,250.9h-4.5 M600.2,267.3c7.1,0.3,29.1,1.3,32.6,1.5c3.3,0.2,5.6,1,6.8,3.5c0.8,1.8,2,4.3,2.5,5.6 M601.2,263.3
	c4,0,32.4,1,39.7,1.3c1.8,0,2.5,0,2.8-0.2c0.3,0,0.8-0.3,1.5,0c0.5,0,5.1,0.2,7.6,0.5c0.3,0.5,1,1.5,0.3,3c-2.5-0.3-7.1-0.5-7.6-0.5
	s-1.3-0.3-1.8-0.3c-0.5-0.3-2.3-0.5-2.8-0.5c-7.3-0.3-35.9-1.3-39.9-1.5 M652.8,267.8c0.5,3.3,0.8,6.8,1.3,10.6 M649.5,248.8
	c0.8,3.5,1.8,8.8,2.8,15.7 M646.5,248.6c-0.5,3-1.8,13.1-2.3,15.7 M644.7,248.3c-0.5,4.3-2.3,13.7-3,16.2 M644.4,267.3
	c0.8,2.3,2.3,7.6,3,11.1 M642.4,266.8c0.8,2.3,2.8,8.1,3.5,11.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M699.6,287.8c1.5,3.3,2.3,6.1,2.5,7.1v0.8
	c0,0,1.5,1.3,1.5,2.3c0,1,1.3,15.2,0.3,39.9c-1,24.8-4,52.1-4,52.1l-0.8-1.8c0,0.8,2.5,5.6,2.5,9.9c0.2,4.5-1.5,9.4-2.5,12.9v0.8
	c0.5,34.6,1.3,76.1,1.3,79.4c0,5.8-0.5,14.2-12.6,15.2s-40.7,0.3-47,0.5c-6.3,0-11.9-4.8-11.9-12.1v-24H627l-192.9,0.5l-193.4-0.3
	h-1v24c0,7.3-5.6,12.4-11.9,12.1c-6.3,0-34.9,0.5-47-0.5c-12.1-1-12.6-9.4-12.6-15.2c0-3.3,0.8-43.7,1.3-78.1v0.5
	c-0.8-3.5-2.5-11.1-2.5-15.4c0-4.5,2.5-9.1,2.5-10.1l-0.8,1.5c0-1.3-0.3-2.8-0.5-4.5c-1-9.1-2.8-28.8-3.5-47.3
	c-1-24.8,0-38.9,0.3-39.9c0-1,1.5-2.3,1.5-2.3v-0.5c0-0.8,0.8-2.8,1.8-5.3l-0.5,1c0.5-3.5,1.3-8.1,2.3-12.9c0.3-1,0.5-2.3,0.8-3.3
	v-0.3c0,0-3.5-1.5-4.8-2.3c-1.3-0.8-2-2-2-3.3s0.3-4,0.5-5.1c0.3-1,2.8-1.5,3.3-1.5V262c0-1.5,0.3-3.5,0.5-4.8
	c0.3-1,1.5-1.5,3.3-1.8c1.3,0,4.6-0.3,6.6-0.3h-0.3c1-2.5,2-4.3,2.5-5.6c0.3-0.5,0.5-1.3,0.8-1.8l-0.5,0.8c0,0,0.3-2,0.3-4
	c0-0.5,0.3-1.3,0.8-2.3c1-2,2.8-4.3,5.1-6.1c0.8-0.5,1.8-1.5,2.8-2.3l0.3-0.3c0,0-2.3-0.3-3.3,0.3s-3.3,1.5-7.6,1.3
	c-8.1-0.3-26-2.3-32.6-3.3c-6.3-1-10.1-4-12.4-7.3c-2.3-3.5-3.8-8.1-4-8.9c-0.3-0.8-0.3-1.8,0-2.5c0.3-0.8,1.3-4.3,4-8.3
	c2.5-3.8,6.1-6.1,14.2-8.9c2.5-0.8,6.1-1.5,9.9-2c7.8-1,17.2-1.8,21.2-1.8c4.3-0.3,7.1,1.8,9.1,4.3c2,2.3,6.8,11.6,7.8,13.7
	c0.8,1.8,1.5,3.3,4.6,3.5c2.8,0.3,6.1,0.5,6.1,0.5v0.5c3-9.1,7.8-22.2,21-49.8c8.6-17.9,19.2-34.4,25.5-43.5
	c1.3-1.8,2.5-2.3,2.5-2.3c0-0.3,0.3-0.3,0.3-0.5c0.5-1.3,1.8-3.3,2.8-3.3c0.5-1,1-2,1.3-3c0.3-0.5,0.8-1,1.3-1.3
	c0.3-0.3,0.5-0.3,0.8,0c0.5-1.3,2.5-3.3,5.8-5.8s7.1-3.3,7.6-3.3c0.5,0,1,0,1.3,0.3l1,1V103c0,0,0-0.3,0.5-0.3
	c36.2-4.6,90.8-8.1,154.7-8.3c0.3-1.5,0.5-2.8,0.5-3.3c0.3-0.8,0.8-1,1-1c0.3,0,1,0.3,1,1c0,0.3,0.3,1.8,0.5,3.3
	c64,0,118.8,3.5,154.7,8.3c0.2,0,0.5,0.3,0.5,0.3s0,0.3,0.3,0.5v-0.3l1-1c0.2-0.3,0.8-0.5,1.3-0.3c0.5,0,4.3,1,7.6,3.3
	c3.3,2.5,5.1,4.6,5.8,5.8c0.3-0.3,0.5-0.3,0.8,0s1,0.8,1.3,1.3l1.5,3v-0.3c1,0,2.3,2.3,2.8,3.3l0.3,0.8c0,0,1.3,0.3,2.5,2.3
	c6.3,9.4,16.9,25.5,25.5,43.5c12.6,26.3,17.4,39.4,20.7,48.5l0.3,0.5c0,0,3-0.3,5.6-0.3c2,0,2.8-0.5,2.8-1v-0.8
	c0-0.5,0.3-0.8,0.5-1.3c0.8-1.8,4.3-11.1,6.3-14.4c1.8-2.5,4.5-4.3,8.6-4c3.8,0.3,12.1,0.8,19.5,1.8c3.5,0.5,6.6,1.3,8.8,2
	c7.6,2.8,10.6,5.1,12.9,8.9c2.5,3.8,3.5,7.3,3.5,8.3c0.3,0.8,0.3,1.8,0,2.5c-0.3,0.8-1.8,5.3-3.8,8.9c-2,3.5-6.8,6.6-12.6,7.8
	c-5.8,1-22.5,2.5-29.8,3c-2,0.3-4.5-0.5-4.5-0.5l1.3,1c2.3,1.8,4,4.3,5.1,6.1c0.5,0.8,0.5,1.5,0.8,2.3c0.3,2,0.3,4,0.3,4l-0.8-1.3
	c0.3,0.8,0.5,1.5,1,2.3c0.5,1.3,1.5,3,2.5,5.6h-0.8c2,0,5.8,0.3,7.1,0.3c1.8,0,3,0.5,3.3,1.8c0.3,1,0.5,3.3,0.5,4.8v0.3
	c0.5,0,3,0.3,3.3,1.5c0.3,1,0.5,3.8,0.5,5.1s-0.5,2.5-2,3.3c-1.3,0.8-4.8,2.3-4.8,2.3l-0.3-0.3c0.5,1.3,0.8,2.8,1,4
	c1,4.8,1.8,9.4,2.3,12.9"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="3.864"
              d="M263.7,199.5
		c0,0-4.8,0.5-4-3c0.8-3.5,10.1-40.7,15.2-58.4c0,0,1.5-5.1,9.9-6.8c0,0,49.3-5.6,94.8-6.8c0,0,0.5,0,0.8,0.5c0.3,0.5,1.5,2,2,1.8
		c0.5,0,63.7-1.5,98.1,0c0,0,1,0,2-1.8c0,0,0.3-0.8,1-0.8c0,0,47,1.5,94.8,6.8c0,0,6.8,0.8,9.4,5.8c0,0,11.6,41.5,15.4,58.7
		c0,0,1.3,4-3.3,3.8C594.4,199.3,465.2,190.9,263.7,199.5z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M617.9,203.8
		c0,0-183.5-13.9-373.7,0c0,0-3,2.5,1.5,9.1s6.6,9.9,6.6,9.9l4.8,19c0,0,13.4,51.8,19.2,67c0,0,4.8,13.4,12.4,16.7
		s13.9,3.5,31.9,3.5c0,0,95.3-1,226,0c0,0,24,2,31.6-6.6c7.6-8.6,9.4-17.7,9.4-17.7s15.2-51.6,22.5-81.7l0.3-0.8l6.6-9.9
		C616.9,212.4,619.7,206.6,617.9,203.8z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M606.8,241.3
		c0,0,38.2,0.3,43.5,5.6c0,0,1.5,1.3,2,3.5c0.5,2,3.8,19.5,4.6,27.8c0,0,0.5,1.5-1,1.3c-1.5,0-57.1-2.8-57.1-2.8s-1,0.3-0.8-0.8
		C598.2,275.1,606.8,241.3,606.8,241.3z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M254.6,241.3l8.8,34.9
		c0,0,0,0.8-0.8,0.8c-1,0-57.1,2.8-57.1,2.8s-1-0.3-1-1c0-0.8,2.8-23.5,4.8-29.1c0,0,1-4.3,7.6-5.3
		C223.5,243,231.8,241.5,254.6,241.3z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M166.6,361.9
		c0,0,19.5-12.6,26-14.7c6.6-2,11.4-4.3,36.4-6.1c24.8-1.5,205.3-7.8,377.5-1.3c0,0,33.9,0.3,53.6,4.3c0,0,12.6,1,35.6,17.4
		l-6.1,54.9c0,0-0.8,3.8,0.5,6.1c1.3,2,0.8,2.3,0.5,2.5c0,0.3-3.3,10.9-7.6,18.7c0,0-0.5,1-1.8,1c-1.5,0-10.9,1-10.9,1s-0.5,1-1,1
		s-1.5-0.5-1.5-0.5s-15.4-10.6-26.8-16.2c0,0-1.8-0.8-2.8-3.8c0,0-1.3-6.6-4.3-11.9c0,0-2-5.1-8.1-5.6c-6.1-0.8-23.8-2-41.2-2.8
		c0,0-7.1-1-9.9,3.8s-4.6,7.6-4.6,10.9c0,3,0.8,3.8,1,4.3c0.3,0.5,0.5,1-0.3,1.8c-0.5,0.8-2.8,3.5-2.8,3.5s-0.5,0.5-1.8,0.5
		c-1.3,0-4.3,0-4.3,0s-1.8,0.5-3.8,0.5c-2,0-253.8,0-253.8,0s-2.5-0.3-3-0.5c-0.8-0.3-3.5-0.3-4,0c-0.5,0-2.8,0.3-3.3-0.3
		s-3.3-4.3-3.3-4.3s-0.5-0.3,0.3-1.3c0.8-1.3,1.8-4.6-0.3-8.9c-2-4-4-7.8-5.8-8.6s-3.5-1.5-6.8-1.5c-3.5,0-9.1,0.3-9.1,0.3
		s-25,1.3-32.6,2.5c0,0-4.6,0.5-6.6,3.3s-3.8,7.6-6.1,14.7c0,0-0.3,2-2,3c-2,1-16.2,8.3-27.6,16.7c0,0-1.3,0.8-2-0.8l-11.1-1
		c0,0-1.3-0.3-1.8-1.5c-0.8-1.3-5.3-11.6-7.6-18.5c0,0-0.5-0.5,0.5-1.5c0.8-1,1.3-3,0.8-6.8C171.9,412.4,166.6,361.9,166.6,361.9z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Sedan4DrRear);
