import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback3DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M303.4,194.7C303.4,194.7,303.2,194.7,303.4,194.7l-24,42.7l-0.8,1.7l-4,7l0.3-0.2
	c10.1-5,24.4-8.6,42.7-11.1c1.5-1.5,2.7-3.7,3.8-6.3c3.7-22.6,5.5-40.4,5.3-53.2v-1.5l-0.2-2.2c-5.5,0.7-9.3,2-11.3,3.8
	c-3.5,3-6,6.3-7.3,10l-0.2,0.3l-4.2,9L303.4,194.7L303.4,194.7z"
          />
          <path
            fill="#646464"
            d="M346.4,180.2h1.2c21-2,45.6-3,73.8-3.2c27.9,0.2,52.2,1.3,72.8,3.2h1.2c2.5,0.3,3.8,1.7,4.2,3.7l3.2,28.8
	c-0.3,4.2-2.2,7.6-5.7,10.3c-4.7,3-9.1,4.7-13.6,4.7l37.1,1l-0.2-0.5c-3.7-22.6-5.5-40.4-5.3-53.2l-4.2-0.3v1
	c-16.3-1.5-33.3-2.7-50.9-3.3c-8.8-0.3-18-0.7-27.3-0.7H410c-27.9,0.3-54.2,1.7-79,4v-1l-4.2,0.3c0.2,12.8-1.7,30.6-5.3,53.2
	l-0.2,0.5l37.1-1c-4.5-0.2-9-1.7-13.6-4.7c-3.5-2.8-5.3-6.3-5.7-10.3l3.2-28.8C342.6,181.9,343.9,180.6,346.4,180.2L346.4,180.2z"
          />
          <path
            fill="#EBEBEB"
            d="M346.6,180.2c-2.5,0.5-4,1.7-4.2,3.8l-3.2,28.8c0.3,4.2,2.2,7.6,5.7,10.3c4.7,3,9.1,4.7,13.6,4.7l19.6-0.5
	c14.1-0.3,28.4-0.7,43.4-0.7l17.8,0.2c8.3,0.2,16.5,0.2,24.6,0.5l19.6,0.5c4.5-0.2,9-1.7,13.6-4.7c3.5-2.8,5.3-6.3,5.7-10.3
	l-3.2-28.8c-0.3-2.2-1.7-3.3-4.2-3.7h-1.2c-20.6-2-44.9-3-73-3.2c-28.4,0.2-53.1,1.3-73.8,3.2L346.6,180.2L346.6,180.2z"
          />
          <path
            fill="#FFFFFF"
            d="M332.3,170.4c-0.8,0.2-1.5,0.3-2,0.5c0.3,0.5,0.7,1.2,0.7,2.3v2.7c24.8-2.3,51.1-3.7,79-4h22.8
	c9.3,0,18.5,0.3,27.3,0.7c17.6,0.7,34.6,1.8,50.9,3.3v-1v-1.7c0.2-1.2,0.3-1.8,0.7-2.3c-0.5-0.2-1.2-0.3-2-0.5
	c-15.6-1.5-32.1-2.7-49.6-3.2c-8.8-0.3-18-0.7-27.3-0.7H410C382,166.9,356.1,168.1,332.3,170.4L332.3,170.4z"
          />
          <path
            fill="#646464"
            d="M331,173.2c-0.2-1.2-0.3-1.8-0.7-2.3c-0.5,0.2-0.8,0.5-1,0.8l-2,0.2l-0.7-0.5l0.2,2.2L331,173.2L331,173.2z"
          />
          <path fill="#646464" d="M331,173.2l-4.3,0.3l0.2,1.7l4.2-0.3" />
          <path
            fill="#FFFFFF"
            d="M239.3,222.1c-3.7,0.5-6,2.8-7.3,7v0.2l-0.2,0.3l-0.3,5.3l0.2,2.7c0,0.7,0.2,1.2,0.5,1.7l0.2,0.3h0.2
	c1,1.3,2.2,2.3,3.8,2.7l2.3,0.5l15.3,1.7l6.5-0.3c4.7-1,7.8-3.2,9.5-6.7c1-3.5,1.3-7.3,0.8-11.3c-1.2-3.2-5-4.7-11.8-4.8h-3.2
	C248.5,221.3,243,221.5,239.3,222.1L239.3,222.1z M233.8,229.6c1.2-3.8,3.3-5.8,6.5-6.3h0.2c3.3-0.7,8.1-0.8,15-0.7h2.8
	c6,0.2,9.5,1.7,10.5,4.3c0.5,3.7,0.3,7-0.7,10.1c-2.2,4.5-6.8,6.5-14.3,6.3l-13.8-1.5c-1.5-0.2-2.7-0.5-3.7-1l-0.8-0.7
	c-0.5-0.3-0.8-0.8-1.2-1.3l-0.3-0.2l-0.5-1.3l-0.3-2.8l0.3-4.8L233.8,229.6L233.8,229.6z"
          />
          <path
            fill="#EBEBEB"
            d="M233.8,229.6v0.5l-0.3,4.8l0.3,2.8l0.3,1.3l0.3,0.2c0.3,0.5,0.7,1,1.2,1.3l0.8,0.7c1,0.5,2.2,0.8,3.7,1
	l13.8,1.5c7.5,0.2,12.3-1.8,14.3-6.3c1-3.2,1.2-6.7,0.7-10.1c-1-2.8-4.5-4.2-10.6-4.3h-2.8c-6.7-0.2-11.6,0.2-15,0.7h-0.2
	C237.2,223.8,235,225.8,233.8,229.6L233.8,229.6z"
          />
          <path
            fill="#FFFFFF"
            d="M231.5,234.9l0.3-5.3c-1,0-1.7,0.5-1.8,1.5v1.8C230,233.9,230.5,234.6,231.5,234.9L231.5,234.9z
	 M254.1,244.9l6.3,0.5c2.8-0.5,6-2,9.5-4.7c0.8-0.5,1.8-0.7,2.7-0.3c2.7,0.3,4.7-0.2,5.8-1.2l0.8-1.7l-0.5-0.2h-0.2
	c-1.7-0.2-2.7-0.5-3.5-1l-0.7-0.8l-3.8-9.3c0.5,4,0.3,7.8-0.8,11.3c-1.7,3.5-4.8,5.7-9.5,6.7l-6.5,0.3L254.1,244.9L254.1,244.9z
	 M321.3,228.8l0.2-0.5c-1.2,2.7-2.3,4.8-3.8,6.3l3.7-0.5c17.8-1.3,36.3-2.3,55.2-2.8c14.6-0.5,29.6-0.7,44.9-0.7l27.1,0.3
	c5.7,0.2,11.3,0.3,17,0.3c19,0.5,37.3,1.5,55.2,2.8l3.7,0.5c-1.5-1.5-2.7-3.7-3.8-6.3l0.2,0.5l-37.3-1l-19.6-0.5
	c-8.1-0.3-16.3-0.3-24.6-0.5l-17.8-0.2c-14.8,0-29.3,0.2-43.4,0.7l-19.6,0.5L321.3,228.8L321.3,228.8z"
          />
          <path
            fill="#FFFFFF"
            d="M321.3,234.3l-3.7,0.5c0,4.2,0,8.5,0.2,13.3v1.3c0.2,5.5,0.5,11.5,1,18c0,2.2,1.7,4.2,4.7,5.8
	c6.2,2.8,9.8,5.8,11.1,8.6l5,12l1.2,3c0.8,2.3,3.3,3.5,7.5,3.5c24.8,0.8,49.1,1.2,73.2,1l26.3-0.2c15.1-0.2,30.4-0.3,45.9-0.8
	c4.2,0,6.5-1.2,7.5-3.5l1.2-3l5-12c1.2-2.8,5-5.8,11.1-8.6c3.2-1.7,4.7-3.7,4.7-5.8c0.5-6.5,0.8-12.5,1-18v-1.3
	c0.2-4.8,0.2-9.3,0.2-13.3l-3.7-0.5c-17.8-1.3-36.3-2.3-55.2-2.8c-5.7-0.2-11.3-0.3-17-0.3l-27.1-0.3c-15.3,0-30.3,0.2-44.9,0.7
	C357.6,231.9,339.1,232.9,321.3,234.3L321.3,234.3z M395.2,231.9l6.3-0.2l9.1-0.2h29.8l4.7,0.2h2.3l16.8,0.3c2.3,1,5.3,3.7,8.8,8
	c2.7,2.8,4.2,6.2,4.2,9.8c-0.2,1.7-0.5,3.7-0.8,5.8c-0.8,5.2-2.3,11.6-4.3,19.1c-1.8,9.5-5.7,14.6-11.3,15.6
	c-10.8,1-24.1,1.3-39.6,1.2c-16,0.3-29.4,0-40.6-1.2c-5.7-1-9.5-6.2-11.3-15.6c-2-7.6-3.5-14-4.3-19.1c-0.3-2.2-0.7-4.2-0.8-5.8
	c0.2-3.8,1.5-7.2,4.2-9.8c3.5-4.3,6.5-7,8.8-8C383.4,232.1,389.2,231.9,395.2,231.9L395.2,231.9z"
          />
          <path
            fill="#FFFFFF"
            d="M395.2,231.9l2.2,4.2c0.5,0.7,1.8,1,4,1h0.2v-5.2L395.2,231.9L395.2,231.9z"
          />
          <path
            fill="#FFFFFF"
            d="M397.3,236.1l-2.2-4.2c-6,0.2-11.8,0.2-17.6,0.3c-2.3,1-5.3,3.7-8.8,8c-2.7,2.8-4.2,6.2-4.2,9.8
	c0.2,1.7,0.3,3.7,0.8,5.8h111.3c0.3-2.2,0.7-4.2,0.8-5.8c-0.2-3.8-1.5-7.1-4.2-9.8c-3.5-4.3-6.5-7-8.8-8l-16.8-0.3h-0.8l-2.2,4.2
	c-0.5,0.7-1.8,1-4,1h-39.4C399.2,237.1,397.8,236.8,397.3,236.1L397.3,236.1z M412,241.9l1.2-0.8c1.3-0.8,2.8-1.3,4.7-1.7l2-0.2
	c2.7-0.2,5,0.3,7.2,1.5c0.5,0.2,1,0.5,1.3,0.8l1.2,1c0.3,0.3,0.7,0.8,0.8,1.2c0.3,0.5,0.5,1,0.5,1.5c0.2,0.7,0,1.2-0.2,1.8
	c-0.2,0.5-0.3,0.8-0.7,1.3l-1,1.2l-1.2,0.8c-1,0.7-1.8,1-3,1.3c-1.2,0.3-2.3,0.5-3.7,0.7l-4-0.3c-1.2-0.3-2.2-0.7-3.2-1.2
	s-1.8-1.2-2.5-1.8c-0.5-0.3-0.7-0.8-1-1.2c-0.3-0.5-0.5-1-0.7-1.5c-0.2-0.5,0-1.2,0.2-1.8c0.2-0.5,0.3-1,0.7-1.5
	C411.3,242.8,411.6,242.3,412,241.9L412,241.9z"
          />
          <path
            fill="#C8C8C8"
            d="M413.1,241.1l-1.2,0.8c-0.3,0.3-0.7,0.8-1,1.2c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.7-0.2,1.2-0.2,1.8
	c0.2,0.5,0.3,1,0.7,1.5c0.2,0.3,0.5,0.8,1,1.2c0.7,0.7,1.5,1.3,2.5,1.8c1,0.5,2,0.8,3.2,1.2l4,0.3c1.3-0.2,2.5-0.3,3.7-0.7
	c1-0.3,2-0.8,3-1.3l1.2-0.8l1-1.2c0.3-0.5,0.7-1,0.7-1.3c0.2-0.7,0.3-1.2,0.2-1.8c0-0.5-0.2-1-0.5-1.5c-0.2-0.3-0.5-0.8-0.8-1.2
	l-1.2-1c-0.5-0.3-0.8-0.5-1.3-0.8c-2.2-1-4.5-1.5-7.2-1.5l-2,0.2C416.1,239.8,414.5,240.3,413.1,241.1L413.1,241.1z M412.5,245.1
	v0.3h3.2v0.5l-0.2,0.3l-0.3,0.2h-3c-0.2,0-0.2-0.2-0.2-0.3h3c0.2,0,0.3-0.2,0.2-0.3H412v-0.7c0-0.2,0.2-0.3,0.3-0.3h3.3v0.3
	L412.5,245.1L412.5,245.1z M412.1,244.4c0.3-0.7,0.7-1.3,1.2-1.8s1.2-1,1.8-1.3c1.5-0.8,3.3-1.3,5.3-1.3l-0.2,0.2
	c-0.5,0.5-1,1.2-1.3,2c-0.3,0.8-0.7,1.5-0.8,2.5h-6L412.1,244.4L412.1,244.4z M420.4,241.8c0-0.7,0-1.3,0.2-1.8l3,0.3
	c0.8,0.2,1.7,0.5,2.3,0.8c1.7,0.8,2.7,2,3,3.3h-7.5l-0.3-0.3l-0.3-0.5C420.6,243.1,420.4,242.4,420.4,241.8L420.4,241.8z
	 M419.8,244.8v0.3h-3.2v1h3.2v0.3h-3.3c-0.2,0-0.3-0.2-0.3-0.3v-1c0-0.2,0.2-0.2,0.3-0.3H419.8L419.8,244.8z M419.6,247.1l0.3,0.5
	c0.3,0.3,0.5,1,0.5,1.8s0,1.5-0.2,2.2c-2,0-3.7-0.5-5.2-1.3s-2.5-2-3-3.3h6.8C419.1,246.7,419.3,246.9,419.6,247.1L419.6,247.1z
	 M425.3,244.8h3.3c0.2,0,0.3,0.2,0.3,0.2v1.2c-0.2,0.2-0.3,0.2-0.5,0.2v-1.3h-2.7v1.3h-0.7v-1.5L425.3,244.8L425.3,244.8z
	 M424.8,245.9l-0.2,0.3l-0.8,0.2h-2.3l-0.3-0.3c0,0-0.2-0.2-0.2-0.5s0-0.5,0.2-0.5c0,0,0-0.2,0.2-0.2h2.7l0.7,0.2l0.2,0.3
	L424.8,245.9L424.8,245.9z M420.1,244.8h0.5v1.5h-0.5V244.8L420.1,244.8z M421.8,249.4l0.7-1.2c0.2-0.5,0.3-1,0.3-1.3h6.2
	c-0.3,1.5-1.5,2.7-3,3.5c-0.7,0.3-1.7,0.7-2.5,0.8l-3,0.3L421.8,249.4L421.8,249.4z"
          />
          <path
            fill="#C8C8C8"
            d="M412.5,245.2l3.2-0.2v-0.3h-3.3c-0.2,0-0.3,0.2-0.3,0.3v0.7h3.2c0,0.2,0,0.3-0.2,0.3h-3c0,0.2,0,0.2,0.2,0.3
	h3l0.3-0.2l0.2-0.3v-0.5h-3.2V245.2L412.5,245.2z"
          />
          <path
            fill="#818181"
            d="M413.3,242.4c-0.5,0.5-0.8,1.2-1.2,1.8h5.8c0.2-0.8,0.5-1.7,0.8-2.5c0.3-0.7,0.8-1.3,1.3-2l0.2-0.2
	c-2,0-3.8,0.5-5.3,1.3C414.5,241.4,413.8,241.9,413.3,242.4L413.3,242.4z M420.4,239.9c-0.2,0.7-0.2,1.3-0.2,1.8
	c0,0.7,0.2,1.3,0.3,1.8l0.3,0.5l0.3,0.3h7.5c-0.5-1.5-1.5-2.5-3-3.3c-0.7-0.3-1.5-0.7-2.3-0.8L420.4,239.9L420.4,239.9z"
          />
          <path
            fill="#C8C8C8"
            d="M419.8,245.1v-0.3h-3.3c-0.2,0-0.3,0.2-0.3,0.2v1c0,0.2,0.2,0.2,0.3,0.3h3.3v-0.2h-3.2v-1H419.8L419.8,245.1
	L419.8,245.1z"
          />
          <path
            fill="#818181"
            d="M419.9,247.6l-0.3-0.5c-0.2-0.2-0.3-0.3-0.5-0.3h-6.8c0.5,1.3,1.5,2.5,3,3.3c1.5,0.8,3.2,1.3,5.2,1.3
	c0.2-0.7,0.2-1.5,0.2-2.2C420.4,248.6,420.3,247.9,419.9,247.6L419.9,247.6z"
          />
          <path
            fill="#C8C8C8"
            d="M425.3,244.8v1.5h0.7v-1.3h2.7v1.3c0.3,0,0.3,0,0.5-0.2v-1.2c0-0.2-0.2-0.2-0.3-0.2L425.3,244.8L425.3,244.8
	L425.3,244.8z M424.6,246.2l0.2-0.3v-0.8l-0.2-0.3l-0.8-0.2h-2.5l-0.2,0.2c-0.2,0-0.2,0-0.2,0.2s-0.2,0.3-0.2,0.5
	c0,0.3,0,0.5,0.2,0.5l0.3,0.3L424.6,246.2L424.6,246.2z M424.3,245.6v0.5h-2.5v-0.8h2.5L424.3,245.6L424.3,245.6z"
          />
          <path
            fill="#C8C8C8"
            d="M424.3,245.9v-0.8h-2.5l-0.2,0.3v0.5 M420.8,244.8h-0.7v1.5h0.7"
          />
          <path
            fill="#818181"
            d="M422.4,248.1l-0.7,1.2l-1.5,2.2l3-0.3c1-0.2,1.8-0.5,2.5-0.8c1.7-0.8,2.7-2,3-3.5h-6.1
	C422.8,247.2,422.6,247.7,422.4,248.1L422.4,248.1z"
          />
          <path
            fill="#FFFFFF"
            d="M401.5,231.8v5.2h9.1v-5.3L401.5,231.8L401.5,231.8z M406.5,235.3c-0.2,0.2-0.5,0.3-0.8,0.3
	c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.7,0.2,0.8,0.3
	c0.2,0.2,0.3,0.5,0.3,0.8C406.8,234.8,406.6,235.1,406.5,235.3L406.5,235.3z"
          />
          <path
            fill="#FFFFFF"
            d="M405.6,235.6c0.3,0,0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8
	c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.7,0.3,0.8C405,235.4,405.3,235.6,405.6,235.6
	L405.6,235.6z M410.6,231.8v5.3h10.1v-5.3H410.6z M416.1,235.6c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3s0.7,0.2,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.2,0.7-0.3,0.8
	C416.8,235.4,416.5,235.6,416.1,235.6L416.1,235.6z"
          />
          <path
            fill="#FFFFFF"
            d="M415.3,235.3c0.2,0.2,0.5,0.3,0.8,0.3s0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8
	c-0.2-0.2-0.5-0.3-0.8-0.3s-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8C415,234.8,415.1,235.1,415.3,235.3z M432.4,231.8h-1.2
	 M431.3,231.8h-0.5 M432.4,231.8h-1.2v5.3h9.1v-5.2L432.4,231.8L432.4,231.8z M435.4,233.6c0.2-0.2,0.5-0.3,0.8-0.3s0.7,0.2,0.8,0.3
	c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.7-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	C435.1,234.1,435.2,233.9,435.4,233.6z"
          />
          <path
            fill="#FFFFFF"
            d="M436.2,233.3c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.7,0.3,0.8
	c0.2,0.2,0.5,0.3,0.8,0.3s0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8s-0.2-0.5-0.3-0.8C436.7,233.4,436.6,233.3,436.2,233.3
	L436.2,233.3z M431.3,231.8h-10.5v5.3h10.6v-5.3H431.3z M426.6,233.6c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.7-0.3,0.8
	c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	C426.1,233.3,426.3,233.4,426.6,233.6L426.6,233.6z"
          />
          <path
            fill="#FFFFFF"
            d="M426.8,234.4c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3
	c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.7,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.7-0.2,0.8-0.3
	C426.8,235.1,426.8,234.8,426.8,234.4L426.8,234.4z M369.6,275.2c1.8,9.5,5.7,14.6,11.3,15.6c11.1,1,24.6,1.3,40.6,1.2
	c15.5,0.3,28.6-0.2,39.6-1.2c5.7-1,9.5-6.2,11.3-15.6c2-7.6,3.5-14,4.3-19.1H365.4C366.1,261.2,367.6,267.5,369.6,275.2z
	 M446.7,231.9h-1.5l-4.7-0.2v5.2h0.2c2.2,0,3.5-0.3,4-1L446.7,231.9L446.7,231.9z M445.1,231.9h1.7 M447.6,231.9h-0.8"
          />
          <path
            fill="#EBEBEB"
            d="M317.8,249.4v-1.3c-10.3-0.3-19-0.3-25.9,0.3c-4.2,0.5-6.3,2.7-6.5,6.5V271l1-0.7v-16c0.7-2.5,3-4,6.7-4.3
	l13.3-0.7C310,249.2,313.7,249.2,317.8,249.4L317.8,249.4z"
          />
          <path
            fill="#EBEBEB"
            d="M317.8,249.4h-11.3l-13.3,0.7c-3.8,0.3-6,1.8-6.7,4.3v0.5l3.5-0.3l1-0.2c0.7-1.7,2.5-2.7,6-3.3h0.3l1.8-0.2
	l8.6-0.5h9.3l0.7,17.5c0.2,2.2,1.3,3.7,3.3,4.7c4.7,2.3,8.1,4.5,10.5,6.7l-26.9-3.8h-0.3c-1.5,0-2.8-0.2-4-0.7l-5.5-0.8
	c-2.2-0.5-3.3-1.3-3.8-2.5c-0.2-0.5-0.3-0.8-0.8-1c-0.7-0.5-1.8-0.5-3.5-0.2l-1,0.7V287l54.2,6.7l-5-12c-1.3-2.8-5-5.8-11.1-8.6
	c-3.2-1.7-4.7-3.7-4.7-5.8C318.3,260.9,318,254.9,317.8,249.4L317.8,249.4z M286.4,275.4v-0.2c0-0.2,0.2-0.2,0.2-0.2h0.2l45.9,6.5
	l0.2,0.2l0.2,0.2l3.7,10v0.2l-0.2,0.2h-0.3l-49.4-6.3l-0.2-0.2l-0.2-0.2L286.4,275.4L286.4,275.4z"
          />
          <path
            fill="#EBEBEB"
            d="M286.6,275v0.2l0.3,10.3v0.2l0.2,0.2l49.4,6.3h0.3l0.2-0.2v-0.2l-3.7-10l-0.2-0.2l-0.2-0.2l-45.9-6.5
	L286.6,275L286.6,275z M287.4,285.2l-0.2-9.5l24.4,3.5l20.8,3l3.5,9.3l-24.3-3.2L287.4,285.2L287.4,285.2z"
          />
          <path
            fill="#EBEBEB"
            d="M287.2,275.7l0.2,9.5l24.3,3.2v-9.1L287.2,275.7L287.2,275.7z M299.4,281.8c0-0.5,0.2-0.8,0.5-1.2
	s0.7-0.5,1.2-0.5s0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.2,0.8-0.5,1c-0.3,0.3-0.7,0.5-1.2,0.5c-0.3,0-0.8-0.2-1.2-0.5
	S299.4,282.2,299.4,281.8L299.4,281.8z"
          />
          <path
            fill="#EBEBEB"
            d="M299.9,280.7c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5
	c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.8-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5S300.2,280.3,299.9,280.7L299.9,280.7z M311.5,279.2v9.1
	l24.3,3.2l-3.5-9.3L311.5,279.2L311.5,279.2z M324,284.8c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5s-0.8-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5s0.8,0.2,1.2,0.5C323.8,284,324,284.3,324,284.8L324,284.8z"
          />
          <path
            fill="#EBEBEB"
            d="M323.6,286c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.8-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5s-0.8,0.2-1.2,0.5
	c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2s0.7,0.5,1.2,0.5S323.3,286.3,323.6,286L323.6,286z"
          />
          <path
            fill="#FFFFFF"
            d="M317.7,248.1c-0.2-4.8-0.2-9.3-0.2-13.3c-18.3,2.5-32.6,6.2-42.7,11.1c2.3-1.2,3.5,1,3.3,6.2l-0.7,34.8
	l8.1,0.3v-32.3c0.2-3.8,2.3-6,6.5-6.5C298.9,247.7,307.5,247.7,317.7,248.1L317.7,248.1z"
          />
          <path
            fill="#646464"
            d="M274.6,245.9l4-7c-1.3,1.2-3.2,1.5-5.8,1.2c-0.8-0.2-1.8,0-2.7,0.3c-3.5,2.7-6.7,4.2-9.5,4.7L274.6,245.9
	L274.6,245.9z"
          />
          <path
            fill="#FFFFFF"
            d="M274.8,245.8l-0.3,0.2c-1.5,1-3,4-4.2,8.8c-0.8,5.2-2.2,9.6-3.8,13.3l-2.7,6.3v0.2c-1.3,4.3-3.3,8-6.2,11
	c0,6,0.2,13,0.5,21H258l-0.2,0.2v0.2c0.3,8.3,1,17.5,1.7,27.8l0.2,2.7l-1-39.2c0.2-5.7,0.7-4.8,1.3,2.5l7-10.3c1-2.2,4.2-3.3,10-3.5
	l0.7-34.8C278.2,246.7,277.1,244.8,274.8,245.8L274.8,245.8z M273.9,250.7h0.3c0.2,0,0.2,0.2,0.2,0.7l-1.7,8l-2.2,9
	c-1.2,4.5-2,6.7-2.3,6.7c-1,1.7-1.7,2.3-2.2,2.5c-0.5,0-0.7-0.8-0.7-2.3c-0.3,0,0.2-2.2,1.5-6.5c0.8-3,1.8-5.8,2.8-8.6l3-8.1
	c0.3-0.5,0.5-0.8,0.5-0.8C273.6,250.9,273.8,250.7,273.9,250.7L273.9,250.7z"
          />
          <path
            fill="#FFFFFF"
            d="M274.3,250.7h-0.3c-0.2,0-0.3,0.2-0.5,0.3c-0.2,0-0.3,0.3-0.5,0.8l-3,8.2c-1,2.8-2,5.8-2.8,8.6
	c-1.3,4.3-1.8,6.5-1.5,6.5c0,1.5,0.2,2.3,0.7,2.3c0.5,0,1.2-0.8,2.2-2.5c0.3,0,1.2-2.3,2.3-6.6l2.2-9l1.7-8
	C274.4,250.9,274.3,250.7,274.3,250.7L274.3,250.7z"
          />
          <path
            fill="#EBEBEB"
            d="M297.2,251.1h-0.3c-3.3,0.7-5.3,1.8-6,3.3l-1,0.2v16c0.3,0.3,0.7,0.7,0.8,1c0.5,1.2,1.7,2,3.8,2.5l5.5,0.7
	c-2-0.7-3.7-1.7-5.3-3.3c-2.5-2.5-3.8-5.7-3.8-9.3c0-3.7,1.3-6.7,3.8-9.3C295.4,252.2,296.2,251.6,297.2,251.1L297.2,251.1z"
          />
          <path
            fill="#EBEBEB"
            d="M298.9,250.9l-1.8,0.2c-0.8,0.5-1.7,1.2-2.5,2c-2.5,2.7-3.8,5.7-3.8,9.3c0,3.7,1.3,6.7,3.8,9.3
	c1.5,1.5,3.3,2.7,5.3,3.3l4.3,0.7c3.5,0,6.5-1.3,9-3.8c2.7-2.5,3.8-5.7,3.8-9.3c0-3.7-1.3-6.7-3.8-9.3c-1.2-1.2-2.5-2-3.8-2.7h-1.8
	c2,0.5,3.7,1.7,5.2,3.2c2.5,2.5,3.7,5.5,3.7,8.8c0,3.5-1.2,6.3-3.7,8.8s-5.3,3.7-8.8,3.7c-3.5,0-6.3-1.2-8.8-3.7s-3.7-5.3-3.7-8.8
	s1.2-6.3,3.7-8.8C296.2,252.2,297.5,251.4,298.9,250.9L298.9,250.9z"
          />
          <path
            fill="#A7A9AC"
            d="M295,253.4c-2.5,2.5-3.7,5.5-3.7,8.8c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7c3.5,0,6.3-1.2,8.8-3.7
	s3.7-5.3,3.7-8.8s-1.2-6.3-3.7-8.8c-1.5-1.5-3.2-2.5-5.2-3.2l-8.6,0.5C297.5,251.4,296.2,252.2,295,253.4L295,253.4z M295.2,262.2
	c0-2.5,0.8-4.5,2.5-6.2c1.7-1.7,3.8-2.5,6.2-2.5c2.3,0,4.5,0.8,6.2,2.5c1.7,1.7,2.7,3.8,2.7,6.2c0,2.5-0.8,4.5-2.7,6.2
	c-1.7,1.7-3.8,2.7-6.2,2.7c-2.5,0-4.5-0.8-6.2-2.7C296,266.7,295.2,264.7,295.2,262.2L295.2,262.2z"
          />
          <path
            fill="#EBEBEB"
            d="M297.7,256.1c-1.7,1.7-2.5,3.8-2.5,6.2c0,2.5,0.8,4.5,2.5,6.2s3.8,2.7,6.2,2.7c2.3,0,4.5-0.8,6.2-2.7
	c1.7-1.7,2.7-3.7,2.7-6.2s-0.8-4.5-2.7-6.2c-1.7-1.7-3.8-2.5-6.2-2.5C301.5,253.6,299.5,254.4,297.7,256.1L297.7,256.1z M296,262.2
	c0-2.2,0.8-4,2.3-5.7c1.5-1.5,3.3-2.3,5.7-2.3c2.2,0,4,0.8,5.5,2.3c1.5,1.5,2.3,3.5,2.3,5.7s-0.8,4-2.3,5.5
	c-1.5,1.5-3.3,2.3-5.5,2.3s-4-0.8-5.7-2.3C296.9,266.2,296,264.4,296,262.2z"
          />
          <path
            fill="#EBEBEB"
            d="M298.4,256.6c-1.5,1.5-2.3,3.5-2.3,5.7s0.8,4,2.3,5.5c1.5,1.5,3.3,2.3,5.7,2.3c2.2,0,4-0.8,5.5-2.3
	c1.5-1.5,2.3-3.3,2.3-5.5s-0.8-4-2.3-5.7c-1.5-1.5-3.3-2.3-5.5-2.3C301.7,254.4,299.9,255.1,298.4,256.6z M300.2,259.2
	c0.3,0.3,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.5-0.7c-0.3-0.3-0.7-0.8-0.7-1.3
	c0-0.5,0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.5-0.5C299.4,258.7,299.9,258.9,300.2,259.2L300.2,259.2z M299,266c0-0.5,0.2-1,0.5-1.5
	c0.3-0.3,0.8-0.5,1.5-0.5c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3s-0.8,0.7-1.3,0.7s-1-0.2-1.5-0.7
	C299.2,267,299,266.5,299,266L299,266z M305.2,255.4c0.3,0.3,0.7,0.8,0.7,1.3s-0.2,1-0.7,1.3c-0.3,0.5-0.8,0.7-1.3,0.7
	c-0.5,0-1-0.2-1.5-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3s0.8-0.7,1.5-0.7C304.4,254.7,304.9,254.9,305.2,255.4L305.2,255.4z
	 M306.7,260.2c0-0.5,0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.5,0.3,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3
	c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.3-0.7C306.9,261.2,306.7,260.7,306.7,260.2L306.7,260.2z M302.5,263.5c-0.3-0.3-0.5-0.8-0.5-1.3
	s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.5-0.5c0.5,0,1,0.2,1.3,0.5c0.3,0.5,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7
	C303.4,264.2,302.9,263.9,302.5,263.5L302.5,263.5z M308.7,264.7c0.3,0.5,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3
	c-0.3,0.3-0.8,0.7-1.3,0.7c-0.5,0-1-0.2-1.5-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.5-0.5
	C307.9,264,308.2,264.2,308.7,264.7L308.7,264.7z"
          />
          <path
            fill="#A7A9AC"
            d="M300.9,260.7c0-0.5-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5c-0.3,0.3-0.7,0.8-0.7,1.5
	c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7C300.5,261.7,300.9,261.2,300.9,260.7L300.9,260.7z M299.5,264.7
	c-0.3,0.5-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3c0.5,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.3-0.5C300.4,264,300,264.2,299.5,264.7L299.5,264.7z M305.9,256.7c0-0.5-0.2-1-0.7-1.3
	c-0.3-0.3-0.8-0.7-1.3-0.7c-0.5,0-1,0.2-1.5,0.7c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	C305.7,257.7,305.9,257.2,305.9,256.7L305.9,256.7z M307.4,258.7c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5S307.7,258.4,307.4,258.7
	L307.4,258.7z M301.9,262.2c0,0.5,0.2,1,0.5,1.3s0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5
	c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.5,0.5C302,261.2,301.9,261.7,301.9,262.2L301.9,262.2z M309.2,266c0-0.5-0.2-1-0.7-1.5
	c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3s0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	C309,267,309.2,266.5,309.2,266L309.2,266z M289.9,254.6l-3.5,0.3v15.5c1.5-0.3,2.7-0.3,3.5,0.2V254.6L289.9,254.6z"
          />
          <path
            fill="#FFFFFF"
            d="M266.4,268.2c-1.7,0.3-2.5,1.2-2.5,2.5c-0.2,1.5-0.2,2.7,0,3.8L266.4,268.2z M260.3,300.5
	c-0.8-7.3-1.2-8.1-1.3-2.5l1,39.2l2,26.9v-51.9L260.3,300.5L260.3,300.5z M267.4,290.3l-7,10.3l1.7,12l7,52.1l0.5,1.8
	c4.7-4.8,16.1-7.5,34.3-7.6c5.5-0.5,9.1-2.3,10.8-5.2c6.3-10.1,12.5-18.5,18.1-24.5c4.7-5.2,10.3-7.8,17.3-7.8h142.4
	c7,0.2,12.6,2.8,17.3,7.8c5.7,6.2,11.6,14.3,18.1,24.5c1.8,2.8,5.3,4.7,10.8,5.2c18.1,0.3,29.4,2.8,34.3,7.6l0.5-1.8l7-52.1l1.7-12
	l-7-10.3c-1-2.2-4.2-3.3-10-3.5l0.2,16.5l-2.8,54.4l2.8-54.4l-0.2-16.5l-8.1,0.3l-54.2,6.7l-1.2,3c-0.8,2.3-3.3,3.5-7.5,3.5
	c-15.5,0.5-30.8,0.8-45.9,0.8l-26.3,0.2c-24,0-48.4-0.3-73.2-1c-4.2,0-6.5-1.2-7.5-3.5l-1.2-3l-54.2-6.7l-8.1-0.3l-0.2,9.3v7.1
	c0.2,4.5,0.3,8.8,0.5,13.3l2.3,41.1l-2.3-41.1c-0.3-4.5-0.3-9-0.5-13.3v-7.1l0.2-9.3C271.6,286.8,268.3,288,267.4,290.3L267.4,290.3
	z M558,316.3c2.7-0.5,3.8,0.5,3.5,3l-1,36.1c0,1.3-1.5,2-4.5,1.7c-3.3,0.2-5.3-1.7-6-5.2l-0.8-27.3
	C549.7,319.8,552.7,316.9,558,316.3L558,316.3z M280.2,319.3c-0.3-2.5,0.8-3.5,3.5-3c5.5,0.7,8.3,3.5,8.6,8.3l-0.7,27.3
	c-0.7,3.5-2.7,5.3-6,5.2c-3,0.2-4.5-0.3-4.5-1.7L280.2,319.3L280.2,319.3z"
          />
          <path
            fill="#FFFFFF"
            d="M561.5,319.3c0.3-2.5-0.8-3.5-3.5-3c-5.5,0.7-8.3,3.5-8.6,8.3l0.7,27.3c0.7,3.5,2.7,5.3,6,5.2
	c3,0.2,4.5-0.3,4.5-1.7L561.5,319.3L561.5,319.3z M554.5,324.1c0.2-0.3,0.5-0.3,1-0.3l2.3,0.2c0.8,0,1.3,0.5,1.3,1.5v27.9
	c0,0.8-0.3,1.3-1.3,1.3l-2.3,0.2c-0.5,0-0.8,0-1-0.3c-0.3-0.2-0.3-0.5-0.3-1l-0.2-28.3C554.2,324.6,554.3,324.3,554.5,324.1
	L554.5,324.1z"
          />
          <path
            fill="#FFFFFF"
            d="M555.5,323.8c-0.5,0-0.8,0-1,0.3c-0.3,0.2-0.3,0.5-0.3,1l0.2,28.3c0,0.5,0.2,0.8,0.3,1
	c0.2,0.3,0.5,0.3,1,0.3l2.3-0.2c0.8,0,1.3-0.5,1.3-1.3v-27.9c0-1-0.5-1.5-1.3-1.5L555.5,323.8L555.5,323.8z M557.8,324.6
	c0.3,0,0.7,0.3,0.7,0.7v27.9c0,0.3-0.2,0.7-0.5,0.7l-2.2,0.2c-0.2,0-0.3,0-0.5-0.2l-0.2-0.5l-0.2-28.3c0-0.3,0-0.5,0.2-0.5
	c0.2-0.2,0.3-0.2,0.5-0.2L557.8,324.6L557.8,324.6z"
          />
          <path
            fill="#A7A9AC"
            d="M558.5,325.2c0-0.5-0.2-0.7-0.7-0.7l-2.3-0.2c-0.2,0-0.3,0-0.5,0.2s-0.2,0.2-0.2,0.5l0.2,28.3l0.2,0.5
	c0.2,0.2,0.3,0.2,0.5,0.2l2.2-0.2c0.3,0,0.5-0.2,0.5-0.7v-27.9H558.5z M557.3,338.4c0.2,0.2,0.2,0.3,0.2,0.5s0,0.3-0.2,0.5
	c-0.2,0.2-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.2-0.5-0.2c-0.2-0.2-0.2-0.3-0.2-0.5s0-0.3,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2
	C557,338.2,557.2,338.4,557.3,338.4z"
          />
          <path
            fill="#A7A9AC"
            d="M557.5,339.1c0-0.2,0-0.3-0.2-0.5s-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.5,0.2s-0.2,0.3-0.2,0.5s0,0.5,0.2,0.5
	c0.2,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.5-0.2S557.5,339.2,557.5,339.1z"
          />
          <path
            fill="#FFFFFF"
            d="M283.7,316.3c-2.7-0.5-3.8,0.5-3.5,3l1,36.1c0,1.3,1.5,2,4.5,1.7c3.3,0.2,5.3-1.7,6-5.2l0.7-27.3
	C292.1,319.8,289.1,316.9,283.7,316.3L283.7,316.3z M282.7,325.2c0-1,0.5-1.5,1.3-1.5l2.3-0.2c0.5,0,0.8,0,1,0.3
	c0.2,0.2,0.3,0.7,0.3,1l-0.2,28.3c0,0.5-0.2,0.8-0.3,1c-0.2,0.3-0.5,0.3-1,0.3l-2.3-0.2c-0.8,0-1.2-0.5-1.2-1.3L282.7,325.2
	L282.7,325.2z"
          />
          <path
            fill="#FFFFFF"
            d="M283.9,323.8c-0.8,0-1.3,0.5-1.3,1.5v27.9c0,0.8,0.3,1.3,1.2,1.3l2.3,0.2c0.5,0,0.8-0.2,1-0.3
	c0.2-0.2,0.3-0.5,0.3-1l0.2-28.3c0-0.5-0.2-0.8-0.3-1c-0.2-0.3-0.7-0.3-1-0.3L283.9,323.8L283.9,323.8z M286.4,324.4
	c0.2,0,0.3,0,0.5,0.2l0.2,0.5l-0.2,28.3l-0.2,0.5l-0.5,0.2l-2.2-0.2c-0.3,0-0.5-0.2-0.5-0.7v-27.9c0-0.5,0.2-0.7,0.7-0.7
	L286.4,324.4L286.4,324.4z"
          />
          <path
            fill="#A7A9AC"
            d="M286.9,324.6c-0.2-0.2-0.3-0.2-0.5-0.2l-2.3,0.2c-0.3,0-0.7,0.3-0.7,0.7v27.9c0,0.3,0.2,0.7,0.5,0.7l2.2,0.2
	l0.5-0.2l0.2-0.5l0.2-28.3L286.9,324.6L286.9,324.6z M284.6,339.6c-0.2-0.2-0.2-0.3-0.2-0.5s0-0.3,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2
	s0.3,0,0.5,0.2s0.2,0.3,0.2,0.5s0,0.5-0.2,0.5s-0.3,0.2-0.5,0.2S284.6,339.7,284.6,339.6L284.6,339.6z"
          />
          <path
            fill="#A7A9AC"
            d="M284.2,339.1c0,0.2,0,0.5,0.2,0.5c0.2,0,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.5-0.2c0.2-0.2,0.2-0.3,0.2-0.5
	s0-0.3-0.2-0.5c-0.2-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.5,0.2C284.4,338.6,284.2,338.7,284.2,339.1z"
          />
          <path
            fill="#EBEBEB"
            d="M313.2,252.9c2.7,2.7,3.8,5.7,3.8,9.3c0,3.7-1.3,6.7-3.8,9.3c-2.5,2.5-5.5,3.8-9,3.8l26.9,3.8
	c-2.3-2.2-5.8-4.3-10.5-6.7c-2-1-3.2-2.7-3.3-4.7l-0.7-17.5h-7.5C310.7,250.9,312,251.7,313.2,252.9L313.2,252.9z"
          />
          <path
            fill="#FFFFFF"
            d="M304,275.4h0.3l-4.3-0.7C301.2,275.2,302.5,275.4,304,275.4L304,275.4z"
          />
          <path
            fill="#646464"
            d="M515.1,173.6l-4.2-0.3v1.7l4.2,0.3V173.6L515.1,173.6z"
          />
          <path
            fill="#646464"
            d="M510.9,173.2l4.2,0.3l0.2-2.2l-0.7,0.5l-2-0.2c-0.2-0.3-0.5-0.5-1-0.8C511.3,171.4,510.9,172.1,510.9,173.2
	L510.9,173.2z"
          />
          <path
            fill="#FFFFFF"
            d="M515.2,171.4l-0.2,2.2v1.5c-0.2,12.8,1.7,30.6,5.3,53.2c1.2,2.7,2.3,4.8,3.8,6.3
	c18.3,2.5,32.6,6.2,42.7,11.1l0.3,0.2l-4-7l-0.8-1.7l-11.8-21l0.5,15.6l0.7,2.2l0.5,4.5c-2.7-1-4.3-1.8-5.2-2.7l0.8-4.3l0.5-15.5
	c0-0.5,0.2-0.8,0.5-0.8h0.3v-1.2l-10.8-19.3l-0.2-0.2h-0.2l-4.2-9l-0.2-0.3c-1.3-3.7-3.8-7-7.3-10
	C524.6,173.4,520.7,172.1,515.2,171.4L515.2,171.4z"
          />
          <path
            fill="#EBEBEB"
            d="M524.1,248.1v1.3h11.5l13.3,0.7c3.8,0.3,6,1.8,6.7,4.3v16l1,0.7v-16.1c-0.2-3.8-2.3-6-6.5-6.5
	C543,247.7,534.4,247.7,524.1,248.1L524.1,248.1z"
          />
          <path
            fill="#FFFFFF"
            d="M524.2,234.8c0,4.2,0,8.5-0.2,13.3c10.3-0.3,19-0.3,25.9,0.3c4.2,0.5,6.3,2.7,6.5,6.5v32.3l8.2-0.3
	l-0.8-34.8c-0.2-5.2,0.8-7.3,3.3-6.2C556.8,240.8,542.7,237.1,524.2,234.8L524.2,234.8z"
          />
          <path
            fill="#EBEBEB"
            d="M523.1,267.2c0,2.2-1.7,4.2-4.7,5.8c-6.2,2.8-9.8,5.8-11.1,8.6l-5,12l54.2-6.7v-16l-1-0.7
	c-1.5-0.3-2.7-0.3-3.3,0.2c-0.5,0.3-0.7,0.7-0.8,1c-0.5,1.2-1.7,2-3.8,2.5l-5.5,0.7c-1.3,0.3-2.7,0.7-4,0.7h-0.3l-26.9,3.8
	c2.3-2.2,5.8-4.3,10.5-6.7c2-1,3.2-2.7,3.3-4.7l0.7-17.5h9.3l8.6,0.5l1.8,0.2h0.3c3.3,0.7,5.3,1.8,6,3.3l1,0.2l3.5,0.3v-0.5
	c-0.7-2.5-3-4-6.7-4.3l-13.3-0.7h-11.5C523.9,254.9,523.6,260.9,523.1,267.2L523.1,267.2z M508.9,281.5l0.2-0.2l45.9-6.5h0.2
	l0.2,0.2v0.2l-0.2,10.3l-0.2,0.2l-0.2,0.2l-49.4,6.3h-0.3l-0.2-0.2v-0.2L508.9,281.5L508.9,281.5z"
          />
          <path
            fill="#EBEBEB"
            d="M509.1,281.5l-0.2,0.2l-0.2,0.2l-3.7,10v0.2l0.2,0.2h0.3l49.4-6.3l0.2-0.2l0.2-0.2l0.2-10.3V275l-0.2-0.2
	h-0.2L509.1,281.5L509.1,281.5z M505.9,291.5l3.5-9.3l20.8-3l24.3-3.5l-0.2,9.5l-24.3,3.2L505.9,291.5L505.9,291.5z"
          />
          <path
            fill="#EBEBEB"
            d="M509.4,282.2l-3.5,9.3l24.3-3.2l0.2-9.1L509.4,282.2L509.4,282.2z M519.4,286.5c-0.5,0-0.8-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
	c0,0.3-0.2,0.8-0.5,1.2S519.7,286.5,519.4,286.5L519.4,286.5z"
          />
          <path
            fill="#EBEBEB"
            d="M518.2,286c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.8-0.2,1.2-0.5s0.5-0.7,0.5-1.2s-0.2-0.8-0.5-1.2
	c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2S517.9,285.7,518.2,286L518.2,286z M530.2,279.2
	l-0.2,9.1l24.3-3.2l0.2-9.5L530.2,279.2L530.2,279.2z M539.7,280.7c0.3-0.3,0.7-0.5,1.2-0.5c0.3,0,0.8,0.2,1.2,0.5
	c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2
	C539.4,281.3,539.5,281,539.7,280.7L539.7,280.7z"
          />
          <path
            fill="#EBEBEB"
            d="M540.9,280.2c-0.5,0-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2
	c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.8-0.2,1.2-0.5s0.5-0.7,0.5-1.2c0-0.5-0.2-0.8-0.5-1.2C541.7,280.3,541.4,280.2,540.9,280.2
	L540.9,280.2z M525.1,250.2l-0.7,17.5c-0.2,2.2-1.3,3.7-3.3,4.7c-4.7,2.3-8.1,4.5-10.5,6.7l26.9-3.8c-3.5,0-6.5-1.3-9-3.8
	c-2.7-2.5-3.8-5.7-3.8-9.3c0-3.7,1.3-6.7,3.8-9.3c1.2-1.2,2.5-2,3.8-2.7L525.1,250.2L525.1,250.2z"
          />
          <path
            fill="#646464"
            d="M549.7,165.4l-0.3,0.2v49.7H549c-0.3,0.2-0.7,0.3-0.5,0.8l-0.5,15.5l-0.8,4.3c0.8,1,2.5,1.8,5.2,2.7
	l-0.5-4.5l-0.7-2.2l-0.5-15.6v-0.2c0-0.5-0.2-0.8-0.5-0.8h-0.3v-44.4l0.2-0.3v-4.8l-0.2-0.3L549.7,165.4L549.7,165.4L549.7,165.4z"
          />
          <path
            fill="#FFFFFF"
            d="M602.7,222.1c-3.8-0.7-9.3-1-16.6-0.8h-3.2c-6.7,0.2-10.6,1.8-11.8,4.8c-0.5,4-0.3,7.8,0.7,11.3
	c1.7,3.5,4.8,5.7,9.5,6.7l6.5,0.3l15.3-1.7l2.3-0.5c1.7-0.5,2.8-1.3,3.8-2.7h0.2l0.2-0.2c0.2-0.5,0.3-1.2,0.5-1.7l0.2-2.7l-0.3-5.3
	l-0.2-0.3v-0.2C608.5,225,606.2,222.6,602.7,222.1L602.7,222.1z M583.6,222.6h2.8c6.7-0.2,11.6,0.2,15,0.7h0.2
	c3.2,0.5,5.3,2.5,6.5,6.3v0.3l0.3,4.8l-0.3,2.8l-0.3,1.3l-0.3,0.2l-1.2,1.3l-0.8,0.7c-1,0.5-2.2,0.8-3.7,1l-13.8,1.5
	c-7.5,0.2-12.3-1.8-14.3-6.3c-0.8-3.2-1.2-6.7-0.7-10.1C574.1,224.1,577.6,222.8,583.6,222.6L583.6,222.6z"
          />
          <path
            fill="#EBEBEB"
            d="M586.4,222.6h-2.8c-6,0.2-9.5,1.7-10.5,4.3c-0.5,3.7-0.2,7,0.7,10.1c2.2,4.5,6.8,6.5,14.3,6.3l13.8-1.5
	c1.5-0.2,2.7-0.5,3.7-1l0.8-0.7l1.2-1.3h0.3l0.3-1.3l0.3-2.8l-0.3-4.8v-0.3c-1.2-3.7-3.3-5.8-6.5-6.3h-0.2
	C598.1,222.6,593.1,222.5,586.4,222.6L586.4,222.6z"
          />
          <path
            fill="#FFFFFF"
            d="M587.8,244.8l-6.5-0.5c-4.7-1-7.8-3.2-9.5-6.7c-1-3.5-1.3-7.3-0.8-11.3l-3.8,9.3l-0.7,0.8
	c-0.8,0.5-1.8,0.8-3.5,1h-0.2l-0.5,0.2l0.8,1.7c1.3,1.2,3.3,1.5,5.8,1.2c0.8-0.2,1.8,0,2.7,0.3c3.5,2.7,6.7,4.2,9.5,4.7l6.2-0.5
	L587.8,244.8L587.8,244.8z M610,229.6l0.3,5.3c1-0.3,1.5-1.2,1.5-2.2v-1.7C611.7,230.1,611,229.6,610,229.6L610,229.6z M550,215.3
	l-0.2-0.3v0.3"
          />
          <path
            fill="#646464"
            d="M571.8,240.6c-0.8-0.5-1.8-0.7-2.7-0.3c-2.7,0.3-4.7-0.2-5.8-1.2l4,7l2.5-0.3l11.5-0.3
	C578.4,244.8,575.3,243.3,571.8,240.6L571.8,240.6z"
          />
          <path
            fill="#FFFFFF"
            d="M567.3,245.9l-0.3-0.2c-2.3-1.2-3.5,1-3.3,6.2l0.7,34.8c5.7,0.2,9,1.3,10,3.5l7,10.3
	c0.8-7.3,1.2-8.1,1.3-2.5l-1,39.2l0.2-2.7c0.8-10.3,1.3-19.5,1.7-27.8v-0.2l-0.2-0.2h-0.2c0.3-8,0.5-15,0.5-21
	c-2.7-3-4.7-6.7-6.2-11v-0.2l-2.7-6.3c-1.7-3.7-3-8.1-3.8-13.3C570.3,249.9,568.8,246.9,567.3,245.9L567.3,245.9z"
          />
          <path
            fill="#A7A9AC"
            d="M555.5,254.9l-3.5-0.3l0.2,16c0.7-0.5,1.8-0.5,3.3-0.2V254.9z"
          />
          <path
            fill="#EBEBEB"
            d="M552,254.6l-1-0.2c-0.7-1.7-2.5-2.7-6-3.3h-0.3c0.8,0.5,1.7,1.2,2.5,2c2.5,2.7,3.8,5.7,3.8,9.3
	c0,3.7-1.3,6.7-3.8,9.3c-1.5,1.5-3.3,2.7-5.2,3.3l5.5-0.8c2.2-0.5,3.3-1.3,3.8-2.5c0.2-0.5,0.3-0.8,0.8-1L552,254.6L552,254.6z"
          />
          <path
            fill="#EBEBEB"
            d="M547.2,252.9c-0.8-0.8-1.7-1.3-2.5-2l-1.8-0.2c1.3,0.7,2.7,1.5,3.8,2.7c2.5,2.5,3.7,5.5,3.7,8.8
	c0,3.5-1.2,6.3-3.7,8.8s-5.3,3.7-8.8,3.7s-6.3-1.2-8.8-3.7s-3.7-5.3-3.7-8.8s1.2-6.3,3.7-8.8c1.5-1.5,3.2-2.5,5.2-3.2h-1.8
	c-1.3,0.7-2.7,1.5-3.8,2.7c-2.7,2.7-3.8,5.7-3.8,9.3c0,3.7,1.3,6.7,3.8,9.3c2.5,2.5,5.5,3.8,9,3.8l4.3-0.7c2-0.7,3.7-1.7,5.2-3.3
	c2.5-2.5,3.8-5.7,3.8-9.3C551,258.6,549.7,255.6,547.2,252.9L547.2,252.9z"
          />
          <path
            fill="#A7A9AC"
            d="M546.7,253.4c-1.2-1.2-2.5-2-3.8-2.7l-8.6-0.5c-2,0.5-3.7,1.7-5.2,3.2c-2.5,2.5-3.7,5.5-3.7,8.8
	c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7s6.3-1.2,8.8-3.7s3.7-5.3,3.7-8.8C550.3,258.9,549.2,255.9,546.7,253.4L546.7,253.4z
	 M544,256.1c1.7,1.7,2.5,3.8,2.5,6.2c0,2.5-0.8,4.5-2.5,6.2c-1.7,1.7-3.8,2.7-6.2,2.7c-2.5,0-4.5-0.8-6.2-2.7
	c-1.7-1.7-2.5-3.7-2.5-6.2s0.8-4.5,2.5-6.2c1.7-1.7,3.8-2.5,6.2-2.5C540.4,253.6,542.4,254.4,544,256.1L544,256.1z"
          />
          <path
            fill="#EBEBEB"
            d="M546.7,262.2c0-2.5-0.8-4.5-2.5-6.2c-1.7-1.7-3.8-2.5-6.2-2.5c-2.5,0-4.5,0.8-6.2,2.5
	c-1.7,1.7-2.5,3.8-2.5,6.2c0,2.5,0.8,4.5,2.5,6.2c1.7,1.7,3.8,2.7,6.2,2.7s4.5-0.8,6.2-2.7C545.8,266.7,546.7,264.7,546.7,262.2
	L546.7,262.2z M543.5,256.6c1.5,1.5,2.3,3.5,2.3,5.7s-0.8,4-2.3,5.5c-1.5,1.5-3.5,2.3-5.7,2.3s-4-0.8-5.5-2.3
	c-1.5-1.5-2.3-3.3-2.3-5.5s0.8-4,2.3-5.7c1.5-1.5,3.3-2.3,5.5-2.3C540,254.4,542,255.1,543.5,256.6L543.5,256.6z"
          />
          <path
            fill="#EBEBEB"
            d="M545.8,262.2c0-2.2-0.8-4-2.3-5.7c-1.5-1.5-3.5-2.3-5.7-2.3s-4,0.8-5.5,2.3c-1.5,1.5-2.3,3.5-2.3,5.7
	s0.8,4,2.3,5.5c1.5,1.5,3.3,2.3,5.5,2.3s4-0.8,5.7-2.3C545,266.2,545.8,264.4,545.8,262.2L545.8,262.2z M539.4,255.4
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.5-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.7,1.3-0.7C538.5,254.7,539,254.9,539.4,255.4L539.4,255.4z M541.7,259.2c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	s0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3
	C541,260.2,541.2,259.7,541.7,259.2L541.7,259.2z M539.9,262.2c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7
	c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.5,0.5C539.7,261.2,539.9,261.7,539.9,262.2L539.9,262.2z
	 M531.7,261.6c-0.3-0.3-0.7-0.8-0.7-1.3s0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.7,0.8,0.7,1.5
	c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7C532.5,262.2,532.2,262,531.7,261.6L531.7,261.6z M534.5,264c0.5,0,1,0.2,1.5,0.5
	c0.3,0.5,0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.5-0.3-0.7-0.8-0.7-1.3
	s0.2-1,0.7-1.5C533.5,264.2,534,264,534.5,264L534.5,264z M539.5,264.7c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.5,0.5
	c0.3,0.5,0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3
	S539,265,539.5,264.7L539.5,264.7z"
          />
          <path
            fill="#A7A9AC"
            d="M539.9,256.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.7-1.5-0.7c-0.5,0-1,0.2-1.3,0.7c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.5-0.7C539.7,257.7,539.9,257.2,539.9,256.7L539.9,256.7z M543,258.7
	c-0.5,0-1,0.2-1.3,0.5c-0.5,0.3-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.3-0.7
	c0.3-0.3,0.5-0.8,0.5-1.3c0-0.5-0.2-1-0.5-1.5C544,258.9,543.5,258.7,543,258.7L543,258.7z M539.4,263.5c0.3-0.3,0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.5-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.5-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7C538.5,264.2,539,263.9,539.4,263.5L539.4,263.5z M531.2,260.2c0,0.5,0.2,1,0.7,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.3-0.5
	c-0.5,0-1,0.2-1.3,0.5C531.4,259.2,531.2,259.7,531.2,260.2L531.2,260.2z M536,264.7c-0.3-0.3-0.8-0.5-1.5-0.5c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.5-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.3,0.7c0.5,0,1-0.2,1.5-0.7c0.3-0.3,0.7-0.8,0.7-1.3
	S536.4,265,536,264.7L536,264.7z M540.9,264c-0.5,0-1,0.2-1.3,0.5c-0.3,0.5-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7c0.5,0,1-0.2,1.5-0.7c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.5C541.9,264.2,541.5,264,540.9,264L540.9,264z"
          />
          <path
            fill="#FFFFFF"
            d="M537.7,275.4h0.3c1.5,0,2.8-0.2,4-0.7L537.7,275.4L537.7,275.4z M577.9,270.7c0-1.3-0.8-2.2-2.5-2.5l2.7,6.3
	C578.1,273.4,578.1,272,577.9,270.7L577.9,270.7z M582.9,298c-0.2-5.7-0.7-4.8-1.3,2.5l-1.7,12v51.9l2-26.9L582.9,298L582.9,298z
	 M538.4,358.7c-5.5-0.5-9.1-2.3-10.8-5.2c-6.3-10.1-12.5-18.5-18.1-24.5c-4.7-5.2-10.3-7.8-17.3-7.8H349.8c-7,0.2-12.6,2.8-17.3,7.8
	c-5.7,6.2-11.6,14.3-18.1,24.5c-1.8,2.8-5.3,4.7-10.8,5.2c-18.1,0.3-29.4,2.8-34.3,7.6l1.3,5.2c2,5,4.5,7.2,7.6,6.5
	c-0.3-1.3,1.8-2.7,6-4c6.8-1.7,14-2.5,21.5-2.8c2.2-0.2,4.2-0.2,6.3-0.2l0.3-1.2l3.8-11.5c5.8-10.3,11.6-19,17.8-26.1
	c4.3-5.5,10.1-8.1,17.6-8h139c7.5-0.3,13.3,2.3,17.6,8c6.2,7.2,12,15.8,17.8,26.1l3.8,11.5l0.3,1.2c2.2,0,4.2,0,6.3,0.2
	c7.5,0.3,14.5,1.2,21.5,2.8c4.2,1.3,6.2,2.7,6,4c3.2,0.7,5.7-1.5,7.7-6.5l1.3-5.2C567.8,361.5,556.5,358.8,538.4,358.7L538.4,358.7z
	"
          />
          <path
            fill="#646464"
            d="M563.6,378c0.3-1.3-1.8-2.7-6-4c-6.8-1.7-14-2.5-21.3-2.8V419c0.3,3.7,3.8,5.8,10.5,6.7h0.3v0.2l0.2,0.2
	l8.3,0.3l12.5-0.3l0.2-0.2v-0.2l1-0.2c6.7-0.8,10.1-3,10.5-6.7V312.3l-7,52.2l-0.5,1.8l-1.3,5.2C569.3,376.5,566.8,378.6,563.6,378
	L563.6,378z"
          />
          <path
            fill="#FFFFFF"
            d="M530,371l-0.3-1.2h-16.1l1.7,10.5c0.2,1,1.5,1.5,4,1.5h17.1v-10.6C534.2,371,532.2,371,530,371L530,371z"
          />
          <path
            fill="#FFFFFF"
            d="M536.4,384.6v-2.8h-17.1c-2.5,0.2-3.8-0.3-4-1.5l-1.7-10.5h-27.9c-7,2.2-15.8,3.3-26.3,3.3
	c-2.3,0-3.8,0.3-4.3,0.8l-9.6,7c-1.3,1-2.8,1.5-4.3,1.7h-51.9l-2.3-0.3l-17-4.7c-2.8-0.8-4.3-1.8-4.7-2.7h-30.6
	c-1.3,0.8-2.7,1.3-4.3,1.3c-1,0-2-0.2-2.8-0.5l-0.7,4.3c-0.2,1-1.5,1.5-4,1.5h-17.1v2.8h230.7V384.6L536.4,384.6z"
          />
          <path
            fill="#646464"
            d="M507.9,332.4c-4.3-5.5-10.1-8.1-17.6-8h-139c-7.5-0.3-13.3,2.3-17.6,8c-6.2,7.2-12,15.8-17.8,26.1L312,370
	h4.3c0-0.8,0-1.5,0.2-2.2c0.3-3.2,1.7-5.8,3.8-8.1c0.5-0.5,0.8-0.8,1.3-1.3c2.3-2,5.2-3,8.3-3c2.8,0,5.3,0.8,7.5,2.5
	c19.3-0.5,38.3-0.7,57-0.5h52.4c25.8-0.3,52.1,0,78.7,1.2C520.1,348.2,514.1,339.6,507.9,332.4L507.9,332.4z M489.8,331.9h12.5
	c0.7,0.2,0.8,0.5,0.7,0.8H339c-0.2-0.3,0.2-0.5,0.7-0.8h12.5c0.2-0.5,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h133.4
	c0.7,0.2,1.3,0.3,1.8,0.5C489.6,331.1,489.8,331.4,489.8,331.9L489.8,331.9z M493.5,339.4c0.3,0.2,0.3,0.5,0.5,1h14.3
	c0.7,0.3,0.8,0.5,0.7,0.8H333c-0.2-0.3,0.2-0.5,0.7-0.8h14.3c0-0.3,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h141.9
	C492.3,338.9,493,339.1,493.5,339.4L493.5,339.4z M497,347.7c0.7,0.2,1.3,0.3,1.8,0.5c0.3,0.3,0.3,0.5,0.5,1h15.6
	c0.7,0.3,0.8,0.5,0.7,0.8H326.5c-0.2-0.3,0.2-0.5,0.7-0.8h15.6c0-0.5,0.2-0.7,0.5-1c0.5-0.2,1.2-0.5,1.8-0.5H497L497,347.7z"
          />
          <path
            fill="#818181"
            d="M502.3,331.9h-12.5c0-0.5-0.2-0.7-0.5-1c-0.5-0.3-1-0.5-1.8-0.5h-12.6l-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7
	s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.7-0.8h-19.6c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.5-0.2-2.2-0.7
	c-0.3-0.2-0.7-0.5-0.8-0.8h-20c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.7-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.7-0.8
	H398c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.8-0.8h-19.6c-0.2,0.3-0.3,0.7-0.8,0.8
	c-0.7,0.5-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.7-0.8h-12.6c-0.7,0.2-1.3,0.3-1.8,0.5c-0.3,0.3-0.3,0.5-0.5,1h-12.5
	c-0.7,0.2-0.8,0.5-0.7,0.8h164C503.1,332.4,502.8,332.1,502.3,331.9L502.3,331.9z"
          />
          <path
            fill="#818181"
            d="M474.2,331.1l0.7-0.8H469c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7
	C473,331.7,473.7,331.6,474.2,331.1L474.2,331.1z M448.7,331.1c0.3-0.3,0.7-0.5,0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8
	c0.5,0.5,1.3,0.7,2.2,0.7C447.4,331.7,448.2,331.6,448.7,331.1L448.7,331.1z M422.9,331.1c0.3-0.3,0.7-0.5,0.8-0.8H418
	c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7C421.8,331.7,422.4,331.6,422.9,331.1L422.9,331.1z M397.3,331.1
	c0.3-0.3,0.7-0.5,0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7C396.2,331.7,396.8,331.6,397.3,331.1L397.3,331.1z
	 M371.9,331.1c0.3-0.3,0.7-0.5,0.8-0.8h-5.8c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7S371.4,331.6,371.9,331.1L371.9,331.1z
	 M494,340.4c-0.2-0.3-0.2-0.7-0.5-1c-0.5-0.3-1-0.5-1.8-0.5H477c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7
	s-1.5-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.7-0.8h-20.5c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7
	c-0.3-0.3-0.7-0.5-0.7-0.8h-21.3c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.3-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.7-0.8H397
	l-0.7,0.8c-0.5,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.5-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.8-0.8h-20.5l-0.7,0.8c-0.5,0.5-1.3,0.7-2.2,0.7
	s-1.5-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.8-0.8h-14.6c-0.7,0.2-1.3,0.3-1.8,0.5c-0.3,0.2-0.3,0.5-0.5,1h-14.3c-0.7,0.3-0.8,0.5-0.7,0.8
	h175.8c0.2-0.3-0.2-0.5-0.7-0.8H494L494,340.4z"
          />
          <path
            fill="#818181"
            d="M450,339.7c0.3-0.2,0.7-0.5,0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7
	S449.5,340,450,339.7L450,339.7z M476.3,339.7c0.3-0.2,0.7-0.5,0.8-0.8h-5.8c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7
	S475.7,340,476.3,339.7L476.3,339.7z M396,339.7l0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7
	C394.8,340.4,395.5,340,396,339.7L396,339.7z M422.9,339.7c0.3-0.3,0.7-0.5,0.8-0.8H418c0.2,0.3,0.3,0.7,0.7,0.8
	c0.7,0.3,1.3,0.7,2.2,0.7C421.8,340.4,422.4,340,422.9,339.7L422.9,339.7z M365.6,339.7c0.7,0.5,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7
	l0.7-0.8h-5.8C364.9,339.1,365.2,339.4,365.6,339.7L365.6,339.7z M498.6,348.4c-0.5-0.2-1-0.5-1.8-0.5H479c-0.2,0.3-0.3,0.7-0.7,0.8
	c-0.7,0.3-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.8-0.8h-22c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.3-1.3,0.7-2.2,0.7
	s-1.7-0.2-2.2-0.7c-0.3-0.2-0.5-0.5-0.7-0.8h-21.8l-0.8,0.8c-0.5,0.3-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7l-0.7-0.8H396
	c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.3-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.8-0.8h-22c-0.2,0.3-0.3,0.7-0.7,0.8
	c-0.7,0.3-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.8-0.8h-17.8c-0.7,0.2-1.3,0.3-1.8,0.5c-0.3,0.3-0.3,0.5-0.5,1h-15.6
	c-0.7,0.3-0.8,0.5-0.7,0.8h188.9c0.2-0.3-0.2-0.5-0.7-0.8h-15.6C499.1,348.9,499,348.5,498.6,348.4L498.6,348.4z"
          />
          <path
            fill="#818181"
            d="M445.6,347.7c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7c0.8,0,1.5-0.2,2.2-0.7
	c0.3-0.2,0.7-0.5,0.7-0.8H445.6L445.6,347.7z M474,348.5c0.5,0.3,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8h-5.8
	C473.5,348,473.7,348.4,474,348.5L474,348.5z M395.5,348.5c0.3-0.3,0.7-0.5,0.8-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8
	c0.7,0.3,1.3,0.7,2.2,0.7C394.2,349.2,395,349,395.5,348.5L395.5,348.5z M420.9,349.2c0.8,0,1.5-0.2,2.2-0.7l0.8-0.8h-5.8l0.7,0.8
	C419.3,349,419.9,349.2,420.9,349.2L420.9,349.2z M362.7,347.7c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.3,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7
	c0.3-0.2,0.7-0.5,0.7-0.8H362.7L362.7,347.7z"
          />
          <path
            fill="#D1D3D4"
            d="M454.9,374.1c0.5-0.5,2-0.8,4.3-0.8c10.5-0.2,19.3-1.2,26.3-3.3h-30.6c-3.2-0.3-6.5,0.2-9.6,1.8
	c-4.7,2.2-9.6,3.5-14.8,3.7h-18.8c-5.2-0.3-10.1-1.5-14.8-3.7c-3.2-1.5-6.5-2.2-9.6-1.8h-22v5.3c0.3,1,1.8,1.8,4.7,2.7l17,4.7
	l2.3,0.3h51.9c1.5-0.2,3-0.8,4.3-1.7L454.9,374.1L454.9,374.1z"
          />
          <path
            fill="#646464"
            d="M445.1,371.6c3.2-1.5,6.5-2.2,9.6-1.8h30.6l0.7-0.2l-0.7,0.2h44.1l-3.8-11.5c-26.6-1.2-52.9-1.5-78.7-1.2
	h-52.4c-18.8-0.3-37.9-0.2-57,0.5c0.7,0.5,1.3,1.2,2,1.8c0.7,0.8,1.3,1.5,1.8,2.3c1.5,2.3,2.2,5,2.2,8h43.4c3.2-0.3,6.5,0.2,9.6,1.8
	c4.7,2.2,9.6,3.5,14.8,3.7h18.8C435.6,375.1,440.4,374,445.1,371.6L445.1,371.6z M436.9,359.8c1.2,0.3,1.7,0.8,2,1.5
	c0.3,0.7,0.2,1.5-0.3,2.3c-1.7,3.3-3.7,6-6.2,8c-1.5,0.7-3.8,1-7.2,0.8h-8.5c-3.3,0.2-5.8-0.2-7.2-0.8c-2.5-1.8-4.5-4.5-6.2-8
	c-0.5-1-0.5-1.8-0.3-2.3c0.3-0.7,0.8-1.2,1.8-1.5h0.2c5.8-0.3,11.3-0.5,16.5-0.3h0.3C426.6,359.3,431.6,359.5,436.9,359.8
	L436.9,359.8z"
          />
          <path
            fill="#818181"
            d="M436.9,359.8c-5.3-0.3-10.5-0.3-15.3-0.3h-0.3c-5.2-0.2-10.6,0-16.5,0.3h-0.2c-1,0.3-1.7,0.8-1.8,1.5
	c-0.3,0.7-0.2,1.5,0.3,2.3c1.7,3.3,3.7,6,6.2,8c1.5,0.7,3.8,1,7.1,0.8h8.5c3.3,0.2,5.8-0.2,7.1-0.8c2.5-1.8,4.5-4.5,6.2-8
	c0.5-1,0.7-1.8,0.3-2.3C438.6,360.5,437.9,360.2,436.9,359.8L436.9,359.8z M438.2,361.5c0.2,0.5,0,1-0.3,1.8c-1.7,3.3-3.7,5.8-6,7.6
	c-1.3,0.5-3.7,0.8-6.8,0.7h-8.5c-3.2,0.2-5.5-0.2-6.8-0.7c-2.3-1.8-4.3-4.3-6-7.6c-0.3-0.8-0.3-1.3-0.3-1.8c0.2-0.3,0.7-0.8,1.3-1
	c5.7-0.3,11.1-0.3,16.3-0.3h0.2c4.8,0,10,0,15.1,0.3C437.6,360.7,438.1,361.2,438.2,361.5L438.2,361.5z"
          />
          <path
            fill="#A7A9AC"
            d="M437.9,363.3c0.3-0.8,0.3-1.3,0.3-1.8c-0.2-0.3-0.7-0.8-1.3-1c-5.3-0.3-10.3-0.3-15.1-0.3h-0.2
	c-5.2-0.2-10.6,0-16.3,0.3c-0.7,0.2-1.2,0.5-1.3,1c-0.2,0.5-0.2,1,0.3,1.8c1.7,3.3,3.7,5.8,6,7.6c1.3,0.5,3.7,0.8,6.8,0.7h8.5
	c3.2,0.2,5.5-0.2,6.8-0.7C434.3,369.2,436.2,366.5,437.9,363.3L437.9,363.3z M421.8,366.2c0,0.3-0.2,0.5-0.3,0.7
	c-0.2,0.2-0.5,0.3-0.7,0.3c-0.2,0-0.5-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	s0.5,0,0.7,0.3C421.8,365.7,421.8,366,421.8,366.2L421.8,366.2z"
          />
          <path
            fill="#A7A9AC"
            d="M421.4,367c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.7-0.3c-0.2,0-0.5,0.2-0.8,0.3
	c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3C421.1,367.3,421.3,367.2,421.4,367z"
          />
          <path
            fill="#D1D3D4"
            d="M365.1,375.3V370h-21.5c0-3-0.7-5.7-2.2-8h-8c0.8,0.3,1.5,0.8,2.2,1.5c1.5,1.5,2.3,3.3,2.3,5.5v1
	c-0.3,1.7-1,3.2-2.2,4.3l-1.2,1L365.1,375.3L365.1,375.3z"
          />
          <path
            fill="#818181"
            d="M321.8,358.3c-0.5,0.3-0.8,0.8-1.3,1.3c-2.2,2.3-3.5,5-3.8,8.1l6-0.8c0.3-1.3,1-2.5,2-3.5
	c1.5-1.5,3.3-2.3,5.5-2.3c1.2,0,2.3,0.2,3.3,0.8h8c-0.5-0.8-1.2-1.7-1.8-2.3c-0.7-0.7-1.3-1.3-2-1.8c-2.2-1.7-4.7-2.5-7.5-2.5
	C327,355.4,324.2,356.4,321.8,358.3L321.8,358.3z"
          />
          <path
            fill="#646464"
            d="M269.3,366.3l-0.5-1.8l-7-52.1V419c0.3,3.7,3.8,5.8,10.5,6.7l1,0.2l0.2,0.2l12.5,0.3l8.3-0.3h0.2v-0.2h0.3
	c6.7-0.8,10.1-3,10.5-6.7v-47.9c-7.5,0.2-14.5,1.2-21.5,2.8c-4.2,1.3-6.2,2.7-6,4c-3.2,0.7-5.7-1.5-7.6-6.5L269.3,366.3L269.3,366.3
	z"
          />
          <path
            fill="#818181"
            d="M337.8,368.8c0-2.2-0.8-4-2.3-5.5c-0.7-0.7-1.3-1.2-2.2-1.5c-1-0.5-2.2-0.8-3.3-0.8c-2.2,0-4,0.8-5.5,2.3
	c-1,1-1.7,2.2-2,3.5c-0.2,0.7-0.2,1.2-0.2,1.8v1c0.2,1.7,1,3.2,2.2,4.3c0.8,0.8,1.7,1.3,2.7,1.8c0.8,0.3,1.8,0.5,2.8,0.5
	c1.7,0,3-0.5,4.3-1.3l1.2-1c1.3-1.3,2-2.7,2.2-4.3L337.8,368.8L337.8,368.8z M336.3,368.8v1c-0.2,1.3-0.8,2.3-1.8,3.3
	c-1.2,1.2-2.7,1.8-4.3,1.8c-1,0-1.7-0.2-2.5-0.5c-0.7-0.3-1.3-0.8-1.8-1.3c-1-1-1.5-2-1.7-3.3l-0.2-1c0-1.7,0.7-3.2,1.8-4.3
	c1.2-1.2,2.7-1.8,4.3-1.8c1.7,0,3.2,0.7,4.3,1.8C335.6,365.8,336.3,367.2,336.3,368.8L336.3,368.8z"
          />
          <path
            fill="#818181"
            d="M336.3,370v-1c0-1.7-0.7-3.2-1.8-4.3c-1.2-1.2-2.7-1.8-4.3-1.8c-1.7,0-3.2,0.7-4.3,1.8
	c-1.2,1.2-1.8,2.7-1.8,4.3l0.2,1c0.2,1.3,0.8,2.3,1.7,3.3c0.5,0.5,1.2,1,1.8,1.3c0.8,0.3,1.5,0.5,2.5,0.5c1.7,0,3.2-0.7,4.3-1.8
	C335.5,372.3,336,371.2,336.3,370L336.3,370z"
          />
          <path
            fill="#D1D3D4"
            d="M326.6,380.5l0.7-4.3c-1-0.3-1.8-1-2.7-1.8c-1.3-1.3-2-2.7-2.2-4.3v-1c0-0.7,0.2-1.3,0.2-1.8l-6,0.8
	c-0.2,0.7-0.2,1.5-0.2,2.2h-4.3l-0.3,1.2c-2.2,0-4.2,0-6.3,0.2v10.6h17.1C325.1,382,326.5,381.5,326.6,380.5L326.6,380.5z"
          />
          <path
            fill="none"
            d="M412,241.9c-0.3,0.3-0.7,0.8-1,1.2c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.7-0.2,1.2-0.2,1.8c0.2,0.5,0.3,1,0.7,1.5
	c0.2,0.3,0.5,0.8,1,1.2c0.7,0.7,1.5,1.3,2.5,1.8c1,0.5,2,0.8,3.2,1.2l4,0.3c1.3,0,2.5-0.3,3.7-0.7c1-0.3,2-0.7,3-1.3l1.2-0.8l1-1.2
	c0.3-0.5,0.7-1,0.7-1.3c0.2-0.7,0.3-1.2,0.2-1.8c0-0.5-0.2-1-0.5-1.5c-0.2-0.5-0.5-0.8-0.8-1.2l-1.2-1c-0.5-0.3-0.8-0.5-1.3-0.8
	c-2.2-1-4.5-1.5-7.2-1.5l-2,0.2c-1.8,0.3-3.3,0.8-4.7,1.7L412,241.9L412,241.9z"
          />
          <path
            fill="none"
            d="M412.5,245.1h3.2v-0.3h-3.3c-0.2,0-0.3,0.2-0.3,0.3v0.7h3.2c0,0.2,0,0.3-0.2,0.3h-3c0,0.2,0,0.2,0.2,0.3h3
	l0.3-0.2l0.2-0.3v-0.5h-3.2V245.1L412.5,245.1z M412.1,244.4h5.8c0.2-0.8,0.5-1.7,0.8-2.5c0.3-0.7,0.8-1.3,1.3-2l0.2-0.2
	c-2,0-3.8,0.5-5.3,1.3c-0.7,0.3-1.3,0.8-1.8,1.3C412.8,243.1,412.5,243.6,412.1,244.4L412.1,244.4z M420.4,241.8
	c0,0.7,0.2,1.3,0.3,1.8l0.3,0.5l0.3,0.3h7.5c-0.5-1.5-1.5-2.5-3-3.3c-0.7-0.3-1.5-0.7-2.3-0.8l-3-0.3
	C420.4,240.4,420.4,241.1,420.4,241.8L420.4,241.8z M419.8,244.8h-3.3c-0.2,0-0.3,0.2-0.3,0.3v1c0,0.2,0.2,0.2,0.3,0.3h3.3v-0.3
	h-3.2v-1h3.2L419.8,244.8L419.8,244.8z M419.6,247.1c-0.2-0.2-0.3-0.3-0.5-0.3h-6.8c0.5,1.3,1.5,2.5,3,3.3c1.5,0.8,3.2,1.3,5.2,1.3
	c0.2-0.7,0.2-1.5,0.2-2.2c-0.2-0.8-0.3-1.5-0.5-1.8L419.6,247.1L419.6,247.1z M425.3,244.8v1.5h0.7v-1.3h2.7v1.3
	c0.3,0,0.3,0,0.5-0.2v-1.2c0-0.2-0.2-0.2-0.3-0.2L425.3,244.8L425.3,244.8L425.3,244.8z M424.8,245.9v-0.8l-0.2-0.3l-0.8-0.2h-2.5
	l-0.2,0.2c-0.2,0-0.2,0-0.2,0.2s-0.2,0.3-0.2,0.5c0,0.3,0,0.5,0.2,0.5l0.3,0.3h2.3l0.8-0.2L424.8,245.9L424.8,245.9z"
          />
          <path
            fill="none"
            d="M424.3,245.9h-2.7v-0.5l0.2-0.3h2.5v0.5 M420.1,244.8v1.5h0.7v-1.5 M421.8,249.4l-1.5,2.2l3-0.3
	c1-0.2,1.8-0.5,2.5-0.8c1.7-0.8,2.7-2,3-3.5h-6.1c0,0.5-0.2,1-0.3,1.3L421.8,249.4L421.8,249.4z M451.4,347.7h-5.8 M445.6,347.7
	c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7c0.8,0,1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8 M436.9,359.8
	c1,0.3,1.7,0.8,1.8,1.5c0.3,0.7,0.2,1.5-0.3,2.3c-1.7,3.3-3.7,6-6.2,8c-1.5,0.7-3.8,1-7.1,0.8h-8.5c-3.3,0.2-5.8-0.2-7.1-0.8
	c-2.5-1.8-4.5-4.5-6.2-8c-0.5-1-0.5-1.8-0.3-2.3c0.3-0.7,0.8-1.2,1.8-1.5c5.8-0.3,11.3-0.5,16.5-0.3h0.3
	C426.6,359.3,431.6,359.5,436.9,359.8L436.9,359.8z M445.1,338.9c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7
	c0.3-0.2,0.7-0.5,0.7-0.8 M443.7,330.2h5.8 M443.7,330.2c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7
	c0.3-0.3,0.7-0.5,0.8-0.8 M469.2,330.2h5.8 M469.2,330.2c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7l0.7-0.8"
          />
          <path
            fill="none"
            d="M362.7,347.7h-17.8c-0.7,0.2-1.3,0.3-1.8,0.5c-0.2,0.3-0.3,0.5-0.5,1H327c-0.7,0.3-0.8,0.5-0.7,0.8h188.9
	c0.2-0.3-0.2-0.5-0.7-0.8h-15.6c-0.2-0.5-0.2-0.7-0.5-1c-0.5-0.2-1-0.5-1.8-0.5h-17.8 M477,338.9h14.6c0.7,0,1.3,0.3,1.8,0.5
	c0.3,0.3,0.3,0.5,0.5,1h14.3c0.5,0.2,0.8,0.5,0.7,0.8H333c-0.2-0.3,0.2-0.5,0.7-0.8h14.3c0-0.5,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5
	h14.6 M475,330.2h12.6c0.7,0.2,1.3,0.3,1.8,0.5c0.3,0.2,0.5,0.5,0.5,1h12.5c0.7,0.3,0.8,0.5,0.7,0.8h-164c-0.2-0.3,0.2-0.5,0.7-0.8
	h12.5c0.2-0.3,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h12.6 M557.3,338.4c-0.2-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.2-0.5,0.2
	c-0.2,0.2-0.2,0.3-0.2,0.5s0,0.3,0.2,0.5c0.2,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.2,0.5-0.2c0.2-0.2,0.2-0.3,0.2-0.5
	S557.5,338.6,557.3,338.4z"
          />
          <path
            fill="none"
            d="M557.8,324.6l-2.3-0.2c-0.2,0-0.3,0-0.5,0.2c0,0.2-0.2,0.3-0.2,0.5l0.2,28.3l0.2,0.5c0.2,0.2,0.3,0.2,0.5,0.2
	l2.2-0.2c0.3,0,0.5-0.2,0.5-0.7v-27.9C558.5,324.8,558.3,324.6,557.8,324.6L557.8,324.6z"
          />
          <path
            fill="none"
            d="M555.5,323.8l2.3,0.2c0.8,0,1.3,0.5,1.3,1.5v27.9c0,0.8-0.3,1.3-1.3,1.3l-2.3,0.2c-0.5,0-0.8,0-1-0.3
	c-0.3-0.2-0.3-0.5-0.3-1l-0.2-28.3c0-0.5,0.2-0.8,0.3-1C554.7,323.8,555,323.8,555.5,323.8L555.5,323.8z M450.9,338.9h-5.8
	 M471.3,338.9c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8 M477,338.9h-5.7
	 M473.3,347.7c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.3,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8 M479.2,347.7h-5.8
	 M449.5,330.2h19.6 M450.9,338.9h20.5 M473.3,347.7h-22 M438.2,361.5c-0.2-0.3-0.7-0.8-1.3-1c-5.3-0.3-10.3-0.3-15.1-0.3h-0.2
	c-5.2-0.2-10.6,0-16.3,0.3c-0.7,0.2-1.2,0.5-1.3,1c-0.2,0.5-0.2,1,0.3,1.8c1.7,3.3,3.7,5.8,6,7.6c1.3,0.5,3.7,0.8,6.8,0.7h8.5
	c3.2,0.2,5.5-0.2,6.8-0.7c2.3-1.8,4.3-4.3,6-7.6C438.2,362.5,438.4,362,438.2,361.5L438.2,361.5z M418,330.2
	c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7c0.8,0,1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M418,330.2h5.8 M392.3,330.2
	c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.7-0.8 M392.3,330.2h5.8 M391,338.9
	c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7l0.7-0.8 M390.5,347.7c0.2,0.3,0.3,0.7,0.8,0.8
	c0.7,0.3,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.8-0.8 M418,338.9c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7
	c0.8,0,1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M423.8,338.9H418 M418,347.7l0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7
	c0.8,0,1.5-0.2,2.2-0.7l0.8-0.8 M423.8,347.7H418 M398.2,330.2H418 M396.8,338.9h21.1 M418,347.7h-21.6 M483.5,227.8
	c4.5-0.2,9-1.7,13.6-4.7c3.5-2.8,5.3-6.3,5.7-10.3l-3.2-28.8c-0.3-2.2-1.7-3.3-4.2-3.7h-1.2c-20.6-2-44.9-3-73-3.2
	c-28.4,0.2-53.1,1.3-73.8,3.2h-1.2c-2.5,0.3-3.8,1.7-4.2,3.7l-3.2,28.8c0.3,4.2,2.2,7.6,5.7,10.3c4.7,3,9.1,4.7,13.6,4.7
	 M317.8,249.4h-11.3l-13.3,0.7c-3.8,0.3-6,1.8-6.7,4.3v0.5"
          />
          <path
            fill="none"
            d="M297.2,251.1h-0.3c-3.3,0.7-5.3,1.8-6,3.3l-1,0.2 M298.9,250.9l-1.7,0.2 M300.2,259.2c-0.3-0.3-0.8-0.5-1.3-0.5
	s-1,0.2-1.5,0.5c-0.3,0.3-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	c0.3-0.3,0.7-0.8,0.7-1.3C300.9,260.2,300.5,259.7,300.2,259.2L300.2,259.2z"
          />
          <path
            fill="none"
            d="M299.9,274.7c-2-0.7-3.7-1.7-5.3-3.3c-2.5-2.5-3.8-5.7-3.8-9.3c0-3.7,1.3-6.7,3.8-9.3c0.8-0.8,1.7-1.3,2.5-2"
          />
          <path
            fill="none"
            d="M298.9,250.9c-1.3,0.7-2.7,1.5-3.8,2.7c-2.5,2.5-3.7,5.5-3.7,8.8c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7
	c3.5,0,6.3-1.2,8.8-3.7s3.7-5.3,3.7-8.8s-1.2-6.3-3.7-8.8c-1.5-1.5-3.2-2.5-5.2-3.2"
          />
          <path
            fill="none"
            d="M296,262.2c0,2.2,0.8,4,2.3,5.5c1.5,1.5,3.3,2.3,5.7,2.3c2.2,0,4-0.8,5.5-2.3c1.5-1.5,2.3-3.3,2.3-5.5
	s-0.8-4-2.3-5.7c-1.5-1.5-3.3-2.3-5.5-2.3s-4,0.8-5.7,2.3C296.9,258.2,296,260.1,296,262.2z"
          />
          <path
            fill="none"
            d="M295.2,262.2c0,2.5,0.8,4.5,2.5,6.2s3.8,2.7,6.2,2.7c2.3,0,4.5-0.8,6.2-2.7c1.7-1.7,2.7-3.7,2.7-6.2
	s-0.8-4.5-2.7-6.2c-1.7-1.7-3.8-2.5-6.2-2.5c-2.5,0-4.5,0.8-6.2,2.5C296,257.7,295.2,259.9,295.2,262.2L295.2,262.2z M289.9,254.6
	l-3.5,0.3 M286.4,270.4l-1,0.7 M286.4,270.4c1.5-0.3,2.7-0.3,3.5,0.2 M286.6,275c0-0.2,0.2-0.2,0.2-0.2h0.2l45.9,6.5l0.2,0.2
	l0.2,0.2l3.7,10v0.2l-0.2,0.2h-0.3l-49.4-6.3l-0.2-0.2v-0.2l-0.3-10.3L286.6,275L286.6,275z"
          />
          <path
            fill="none"
            d="M289.9,270.5c0.3,0.3,0.7,0.7,0.8,1c0.5,1.2,1.7,2,3.8,2.5l5.5,0.7 M299,266c0,0.5,0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5
	C299.2,265,299,265.5,299,266L299,266z M286.4,254.9v15.5 M289.9,270.5v-16 M366.9,330.2c0.2,0.3,0.3,0.7,0.7,0.8
	c0.7,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M364.7,338.9c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7
	s1.7-0.2,2.2-0.7l0.7-0.8 M362.7,347.7c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.3,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8
	 M368.6,347.7h-5.8 M370.5,338.9H391 M372.7,330.2h-5.8 M370.5,338.9h-5.8 M299.4,281.8c0,0.3,0.2,0.8,0.5,1
	c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1c0-0.5-0.2-0.8-0.5-1.2s-0.7-0.5-1.2-0.5c-0.3,0-0.8,0.2-1.2,0.5
	C299.5,281,299.4,281.3,299.4,281.8L299.4,281.8z M390.5,347.7h-22 M421.8,366.2c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.7-0.3
	c-0.2,0-0.5,0-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3s0.5-0.2,0.7-0.3
	C421.8,366.8,421.8,366.5,421.8,366.2L421.8,366.2z"
          />
          <path
            fill="none"
            d="M311.5,279.2l-24.3-3.5l0.2,9.5l24.3,3.2 M309.3,250.2c1.3,0.7,2.7,1.5,3.8,2.7c2.7,2.7,3.8,5.7,3.8,9.3
	c0,3.7-1.3,6.7-3.8,9.3c-2.5,2.5-5.5,3.8-9,3.8 M309.3,250.2h-1.8 M305.2,255.4c-0.3-0.3-0.8-0.7-1.3-0.7c-0.5,0-1,0.2-1.5,0.7
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.5,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3
	S305.7,255.7,305.2,255.4z"
          />
          <path
            fill="none"
            d="M306.7,260.2c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.3-0.7c0.5-0.3,0.7-0.8,0.7-1.3
	s-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5C306.9,259.2,306.7,259.7,306.7,260.2L306.7,260.2z M302.5,263.5
	c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5
	c-0.3,0.5-0.5,0.8-0.5,1.5C301.9,262.7,302,263.2,302.5,263.5L302.5,263.5z M308.7,264.7c-0.3-0.3-0.8-0.5-1.3-0.5
	c-0.5,0-1,0.2-1.5,0.5c-0.3,0.5-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	c0.3-0.3,0.7-0.8,0.7-1.3S309,265,308.7,264.7L308.7,264.7z M299.9,274.7c1.3,0.3,2.7,0.7,4,0.7h0.3"
          />
          <path
            fill="none"
            d="M304.2,275.4l26.9,3.8c-2.3-2.2-5.8-4.3-10.5-6.7c-2-1-3.2-2.7-3.3-4.7l-0.7-17.5h-7.5 M324,284.8
	c0-0.5-0.2-0.8-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5s-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2
	c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5S324,285.3,324,284.8L324,284.8z M311.7,288.3l-0.2-9.1"
          />
          <path
            fill="none"
            d="M311.7,288.3l24.1,3.2l-3.3-9.3l-21-3 M307.5,250.2l-8.6,0.7 M304.2,275.4l-4.3-0.7 M530.2,279.2l24.4-3.5
	l-0.2,9.5l-24.3,3.2 M530.2,279.2v9.1"
          />
          <path
            fill="none"
            d="M540.9,280.2c0.3,0,0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5
	c-0.5,0-0.8-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.8,0.5-1.2C540,280.3,540.4,280.2,540.9,280.2L540.9,280.2z
	 M534.4,250.2l8.5,0.7 M537.7,275.4l4.3-0.7 M540.9,264c0.5,0,1,0.2,1.5,0.5c0.3,0.5,0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3
	s-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3s0.2-1,0.7-1.5C539.9,264.2,540.4,264,540.9,264L540.9,264z
	 M542,274.7c-1.3,0.3-2.7,0.7-4,0.7h-0.3 M536,264.7c0.3,0.5,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.5,0.7
	c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3s0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.3-0.5C535.2,264,535.5,264.2,536,264.7L536,264.7z
	"
          />
          <path
            fill="none"
            d="M532.5,250.2c-1.3,0.7-2.7,1.5-3.8,2.7c-2.7,2.7-3.8,5.7-3.8,9.3c0,3.7,1.3,6.7,3.8,9.3c2.5,2.5,5.5,3.8,9,3.8"
          />
          <path
            fill="none"
            d="M531.2,260.2c0-0.5,0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.5
	c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.3,0.7c-0.5,0-1-0.2-1.3-0.7C531.4,261.2,531.2,260.7,531.2,260.2L531.2,260.2z
	 M532.5,250.2h1.8"
          />
          <path
            fill="none"
            d="M545.8,262.2c0,2.2-0.8,4-2.3,5.5c-1.5,1.5-3.5,2.3-5.7,2.3s-4-0.8-5.5-2.3c-1.5-1.5-2.3-3.3-2.3-5.5
	s0.8-4,2.3-5.7c1.5-1.5,3.3-2.3,5.5-2.3s4,0.8,5.7,2.3C545,258.2,545.8,260.1,545.8,262.2L545.8,262.2z"
          />
          <path
            fill="none"
            d="M546.7,262.2c0,2.5-0.8,4.5-2.5,6.2c-1.7,1.7-3.8,2.7-6.2,2.7c-2.5,0-4.5-0.8-6.2-2.7c-1.7-1.7-2.5-3.7-2.5-6.2
	s0.8-4.5,2.5-6.2c1.7-1.7,3.8-2.5,6.2-2.5s4.5,0.8,6.2,2.5C545.8,257.7,546.7,259.9,546.7,262.2L546.7,262.2z"
          />
          <path
            fill="none"
            d="M534.4,250.2c-2,0.5-3.7,1.7-5.2,3.2c-2.5,2.5-3.7,5.5-3.7,8.8c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7
	s6.3-1.2,8.8-3.7c2.5-2.5,3.7-5.3,3.7-8.8s-1.2-6.3-3.7-8.8c-1.2-1.2-2.5-2-3.8-2.7 M542.9,250.9l1.8,0.2"
          />
          <path
            fill="none"
            d="M539.4,263.5c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.5
	c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.5,0.5c0.3,0.5,0.5,0.8,0.5,1.5C539.9,262.7,539.7,263.2,539.4,263.5L539.4,263.5z M543,258.7
	c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.3-0.7c-0.5-0.3-0.7-0.8-0.7-1.3
	c0-0.5,0.2-1,0.7-1.5C542,258.9,542.5,258.7,543,258.7L543,258.7z M539.9,256.7c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7
	c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3c0.3-0.3,0.8-0.7,1.3-0.7s1,0.2,1.5,0.7
	C539.7,255.7,539.9,256.2,539.9,256.7L539.9,256.7z"
          />
          <path
            fill="none"
            d="M542,274.7c2-0.7,3.7-1.7,5.2-3.3c2.5-2.5,3.8-5.7,3.8-9.3c0-3.7-1.3-6.7-3.8-9.3c-0.8-0.8-1.7-1.3-2.5-2
	 M552,254.6v16 M555.5,270.4v-15.5"
          />
          <path
            fill="none"
            d="M552,270.5c-0.5,0.3-0.7,0.7-0.8,1c-0.5,1.2-1.7,2-3.8,2.5l-5.5,0.7 M555.5,270.4c-1.5-0.3-2.7-0.3-3.3,0.2
	 M556.5,271l-1-0.7 M544.7,251.1h0.3c3.3,0.7,5.3,1.8,6,3.3l1,0.2"
          />
          <path
            fill="none"
            d="M555.5,254.9v-0.5c-0.7-2.5-3-4-6.7-4.3l-13.3-0.7h-11.5 M555.5,254.9l-3.5-0.3 M549.8,214.8v-43.9l0.2-0.3v-5
	l-0.3-0.2l-0.3,0.2v48.6 M530.2,288.3l-24.3,3.2l3.5-9.3l20.8-3"
          />
          <path
            fill="none"
            d="M508.9,281.5l-0.2,0.2l-3.7,10.1v0.2l0.2,0.2l0.2,0.2l49.4-6.5h0.2l0.2-0.3l0.2-10.1V275h-0.2l-0.2-0.2
	l-45.9,6.7 M537.7,275.4l-26.9,3.8c2.3-2.2,5.8-4.3,10.5-6.7c2-1,3.2-2.7,3.3-4.7l0.7-17.5h7.5 M372.7,330.2h19.6 M396.3,347.7h-5.8
	 M391,338.9h5.8 M423.8,330.2h20 M423.8,338.9h21.3 M445.6,347.7h-21.8 M286.9,324.6l0.2,0.5l-0.2,28.3l-0.2,0.5l-0.5,0.2l-2.2-0.2
	c-0.3,0-0.5-0.2-0.5-0.7v-27.9c0-0.5,0.2-0.7,0.7-0.7l2.3-0.2C286.6,324.4,286.7,324.4,286.9,324.6L286.9,324.6z"
          />
          <path
            fill="none"
            d="M282.7,325.2v27.9c0,0.8,0.3,1.3,1.2,1.3l2.3,0.2c0.5,0,0.8-0.2,1-0.3c0.2-0.2,0.3-0.5,0.3-1l0.2-28.3
	c0-0.5-0.2-0.8-0.3-1c-0.2-0.3-0.7-0.3-1-0.3l-2.3,0.2C283.1,323.9,282.7,324.3,282.7,325.2L282.7,325.2z M416.1,235.6
	c0.3,0,0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3s-0.7,0.2-0.8,0.3
	c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.7,0.3,0.8C415.6,235.4,415.8,235.6,416.1,235.6L416.1,235.6z M436.2,233.3c0.3,0,0.7,0.2,0.8,0.3
	c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.7-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	s0.2-0.5,0.3-0.8C435.6,233.4,435.9,233.3,436.2,233.3L436.2,233.3z M426.8,234.4c0,0.3-0.2,0.7-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3
	c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.7,0.2,0.8,0.3
	C426.8,233.9,426.8,234.1,426.8,234.4L426.8,234.4z"
          />
          <path
            fill="none"
            d="M284.2,339.1c0-0.2,0-0.3,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2c0.2,0,0.3,0,0.5,0.2c0.2,0.2,0.2,0.3,0.2,0.5
	s0,0.5-0.2,0.5c-0.2,0-0.3,0.2-0.5,0.2c-0.2,0-0.3,0-0.5-0.2C284.4,339.4,284.2,339.2,284.2,339.1z M406.5,235.3
	c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
	c0,0.3,0.2,0.7,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3C406,235.6,406.1,235.4,406.5,235.3L406.5,235.3z M518.2,286
	c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
	c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5C518.9,286.5,518.6,286.3,518.2,286L518.2,286z M510.9,173.2l4.2,0.3 M515.2,171.4l-0.7,0.5
	l-2-0.2c-0.2-0.3-0.5-0.5-1-0.8 M515.1,173.6l0.2-2.2 M510.9,173.2c0.2-1.2,0.3-1.8,0.7-2.3 M510.9,174.9l4.2,0.3 M515.1,175.2v-1.7
	 M510.9,174.9v-1.7 M550.7,216.5l0.3,15.6l0.7,2.2l0.5,4.5c-2.7-1-4.3-1.8-5.2-2.7l0.8-4.3l0.5-15.5c0-0.5,0.2-0.8,0.7-0.8h0.3v-1.2
	L538.5,195l-0.2-0.2h-0.2l-4.2-9l-0.2-0.3c-1.3-3.7-3.8-7-7.3-10c-2-1.8-5.8-3.2-11.3-3.8 M401.5,237.1c-2.3,0-3.7-0.3-4.2-1
	l-2.2-4.2 M395.2,231.9l6.3-0.2 M401.5,231.8v5.3 M401.5,237.1h9.1 M410.6,231.8v5.3 M401.5,231.8h9.1 M431.3,231.8h1.2
	 M432.4,231.8h-1.2 M430.8,231.8h0.5 M431.3,231.8h-0.5 M420.8,237.1h10.5 M431.3,231.8v5.3 M420.8,237.1v-5.3 M420.8,231.8h10
	 M410.6,237.1h10.1 M420.8,231.8h-10.1 M365.2,255.9c-0.3-2.2-0.7-4.2-0.8-5.8c0.2-3.8,1.5-7.2,4.2-9.8c3.5-4.3,6.5-7,8.8-8
	c5.8-0.2,11.6-0.3,17.6-0.3 M365.2,255.9c0.8,5.2,2.3,11.6,4.3,19.1c1.8,9.5,5.7,14.6,11.3,15.6c11.1,1,24.6,1.3,40.6,1.2
	c15.5,0.3,28.6-0.2,39.6-1.2c5.7-1,9.5-6.2,11.3-15.6c2-7.6,3.5-14,4.3-19.1 M520.4,228.3c1.2,2.7,2.3,4.8,3.8,6.3 M524.1,248.1v1.3
	c-0.2,5.5-0.5,11.5-1,18c0,2.2-1.7,4.2-4.7,5.8c-6.2,2.8-9.8,5.8-11.1,8.6l-5,12 M524.2,234.8c0,4.2,0,8.5-0.2,13.3 M445.1,231.9
	h1.7 M447.6,231.9h-0.8 M446.7,231.9h-1.7 M446.7,231.9h0.8 M440.4,237.1c2.3,0,3.7-0.3,4.2-1l2.2-4.2 M445.1,231.9l-4.7-0.2
	 M440.4,237.1v-5.3 M447.6,231.9l16.8,0.3c2.3,1,5.3,3.7,8.8,8c2.7,2.8,4.2,6.2,4.2,9.8c-0.2,1.7-0.5,3.7-0.8,5.8 M515.1,175.2
	c-0.2,12.8,1.7,30.6,5.3,53.2 M476.5,255.9H365.2 M431.3,237.1h9.1 M440.4,231.8h-8 M610,229.6l-0.2-0.3v-0.2
	c-1.3-4.2-3.7-6.5-7.2-7h-0.2c-3.7-0.7-9.1-1-16.6-0.8h-3.2c-6.7,0.2-10.6,1.8-11.8,4.8"
          />
          <path
            fill="none"
            d="M583.6,222.6c-6,0.2-9.5,1.7-10.5,4.3c-0.5,3.7-0.2,7,0.7,10.1c2.2,4.5,6.8,6.5,14.3,6.3l13.8-1.5
	c1.5-0.2,2.7-0.5,3.7-1l0.8-0.7l1.2-1.3h0.3l0.3-1.3l0.3-2.8l-0.3-4.8v-0.3c-1.2-3.7-3.3-5.8-6.5-6.3h-0.2c-3.3-0.7-8.2-0.8-15-0.7
	L583.6,222.6L583.6,222.6L583.6,222.6z M581.3,245.2l6.3-0.3l0.2-0.2l0.2-0.2"
          />
          <path
            fill="none"
            d="M587.9,244.6l-6.5-0.3c-4.7-1-7.8-3.2-9.5-6.7c-1-3.5-1.3-7.3-0.8-11.3 M610.4,234.9l-0.3-5.3 M610.4,234.9
	c1-0.3,1.5-1.2,1.5-2.2v-1.7c-0.2-1-0.7-1.5-1.8-1.5 M587.9,244.6l15.3-1.7l2.3-0.5c1.7-0.3,2.8-1.3,3.8-2.7h0.2l0.2-0.3
	c0.3-0.5,0.3-1.2,0.5-1.7l0.2-2.7 M549.8,215v-0.2 M550,215.3l-0.2-0.3 M550.7,216.5v-0.3c0-0.5-0.2-0.8-0.5-0.8 M550,215.3h-0.2
	V215 M571.1,226.3l-3.8,9.3l-0.7,0.8c-0.8,0.5-1.8,0.8-3.5,1H563l-0.5,0.2 M563.3,239.1c1.3,1.2,3.3,1.5,5.8,1.2
	c0.8-0.2,1.8,0,2.7,0.3c3.5,2.7,6.7,4.2,9.5,4.7 M562.5,237.4l0.8,1.7 M567.3,245.9l-0.2-0.2"
          />
          <path
            fill="none"
            d="M567.3,245.9l2.5-0.3l11.5-0.3 M563.3,239.1l4,6.8 M562.5,237.4l-11.8-21 M575.5,268.2c-1.7-3.7-3-8.1-3.8-13.3
	c-1.3-4.8-2.7-7.8-4.2-8.8 M524.1,248.1c10.3-0.3,19-0.3,25.9,0.3c4.2,0.5,6.3,2.7,6.5,6.5v32.3 M564.5,286.7l-8,0.3 M561.8,357.7
	l2.8-54.4l-0.2-16.6 M567.1,245.8c-2.3-1.2-3.5,1-3.3,6.2l0.7,34.8 M577.9,274.4c0.2-1.2,0.2-2.3,0-3.8c0-1.3-0.8-2.2-2.5-2.5
	 M581.9,337.4l0.2-2.7c0.8-10.3,1.3-19.5,1.7-27.8v-0.2l-0.2-0.2h-0.2c0.3-8,0.5-15,0.5-21c-2.7-3-4.7-6.7-6.2-11v-0.2 M577.9,274.4
	l-2.5-6.2"
          />
          <path
            fill="none"
            d="M564.5,286.7c5.7,0.2,9,1.3,10,3.5l7,10.3 M581.9,337.4l1-39.2c-0.2-5.7-0.7-4.8-1.3,2.5 M567.1,245.8
	c-10.1-5-24.5-8.6-42.7-11.1 M556.5,287l-54.2,6.7 M321.3,228.3c3.7-22.6,5.5-40.4,5.3-53.2 M339.6,293.6l-54.2-6.7 M274.8,245.8
	c10.1-5,24.4-8.6,42.7-11.1 M502.3,293.6l-1.2,3c-0.8,2.3-3.3,3.5-7.5,3.5c-15.5,0.5-30.8,0.8-45.9,0.8l-26.3,0.2
	c-23.9,0.2-48.4-0.2-73.2-1c-4.2,0-6.5-1.2-7.5-3.5l-1.2-3 M285.4,287l-8.1-0.3"
          />
          <path
            fill="none"
            d="M277.2,286.7l0.7-34.8c0.2-5.2-0.8-7.3-3.3-6.2 M279.9,357.7l-2.3-41.1c-0.3-4.5-0.3-9-0.5-13.3
	c-0.2-2.3-0.2-4.8,0-7.1l0.2-9.3 M277.2,286.7c-5.7,0.2-9,1.3-10,3.5l-7,10.3 M260.3,300.5c-0.8-7.3-1.2-8.1-1.3-2.5l1,39.2
	 M263.9,274.4l2.5-6.2"
          />
          <path
            fill="none"
            d="M263.9,274.4v0.2c-1.3,4.3-3.3,8-6.2,11c0,6,0.2,13,0.5,21h-0.2l-0.2,0.2v0.2c0.3,8.3,1,17.5,1.7,27.8l0.2,2.7"
          />
          <path
            fill="none"
            d="M266.4,268.2c-1.7,0.3-2.5,1.2-2.5,2.5c-0.2,1.5-0.2,2.7,0,3.8 M274.6,245.9c-1.5,1-3,4-4.2,8.8
	c-0.8,5.2-2.2,9.6-3.8,13.3 M274.3,250.7c0.2,0,0.2,0.2,0.2,0.7l-1.7,8l-2.2,9c-1.2,4.5-2,6.7-2.3,6.7c-1,1.7-1.7,2.3-2.2,2.5
	c-0.5,0-0.7-0.7-0.7-2.3c-0.3,0,0.2-2.2,1.5-6.5c0.8-3,1.8-5.8,2.8-8.6l3-8.1c0.3-0.5,0.5-0.8,0.5-0.8c0.2-0.2,0.3-0.3,0.5-0.3
	L274.3,250.7L274.3,250.7z M285.4,287v-32.1c0.2-3.8,2.3-6,6.5-6.5c7-0.7,15.6-0.8,25.9-0.3 M279.4,237.4l-1,1.7 M260.5,245.2
	l14.1,0.7 M270.8,226.3c0.5,4,0.2,7.8-0.8,11.3c-1.7,3.5-4.8,5.7-9.5,6.7l-6.5,0.3 M278.4,239.1c-1.3,1.2-3.2,1.5-5.8,1.2
	c-0.8-0.2-1.8,0-2.7,0.3c-3.5,2.7-6.7,4.2-9.5,4.7 M274.6,245.9l0.2-0.2 M278.4,239.1l-3.8,6.8 M279.4,237.4l-0.5-0.2h-0.2
	c-1.7-0.2-2.7-0.5-3.5-1l-0.7-0.8l-3.8-9.3 M317.5,234.8c0,4.2,0,8.5,0.2,13.3"
          />
          <path
            fill="none"
            d="M317.7,248.1v1.3c0.2,5.5,0.5,11.5,1,18c0,2.2,1.7,4.2,4.7,5.8c6.2,2.8,9.8,5.8,11.1,8.6l5,12 M524.2,234.8
	l-3.7-0.5c-17.8-1.3-36.3-2.3-55.2-2.8c-5.7-0.2-11.3-0.3-17-0.3l-27.1-0.3c-15.3,0-30.3,0.2-44.9,0.7c-19,0.5-37.3,1.5-55.2,2.8
	l-3.7,0.5 M317.5,234.8c1.5-1.5,2.7-3.7,3.8-6.3 M520.4,228.3l0.2,0.5l-37.1-1l-19.6-0.5c-8.1-0.3-16.3-0.3-24.6-0.5l-17.8-0.2
	c-14.8,0-29.3,0.2-43.4,0.7l-19.6,0.5l-37.1,1l0.2-0.5 M254,244.6v0.2h0.2v0.2h0.2l6.2,0.3 M231.5,234.9l0.2,2.7
	c0,0.7,0.2,1.2,0.5,1.7l0.2,0.2h0.2c1,1.3,2.2,2.3,3.8,2.7l2.3,0.5l15.3,1.7"
          />
          <path
            fill="none"
            d="M231.8,229.6c-1,0-1.7,0.5-1.8,1.5v1.8c0,1,0.5,1.8,1.5,2.2 M231.5,234.9l0.3-5.3 M233.8,229.6
	c1.2-3.8,3.3-5.8,6.5-6.3h0.2c3.3-0.7,8.1-0.8,15-0.7h2.8c6,0.2,9.5,1.7,10.5,4.3c0.5,3.7,0.3,7-0.7,10.1c-2.2,4.5-6.8,6.5-14.3,6.3
	l-13.8-1.5c-1.5-0.2-2.7-0.5-3.7-1l-0.8-0.7c-0.5-0.3-0.8-0.8-1.2-1.3l-0.3-0.2l-0.5-1.3l-0.3-2.8l0.3-4.8L233.8,229.6L233.8,229.6z
	"
          />
          <path
            fill="none"
            d="M270.8,226.3c-1.2-3-5-4.7-11.8-4.8h-3.2c-7.5-0.2-13,0.2-16.6,0.8H239c-3.5,0.5-6,2.8-7.2,7v0.2l-0.2,0.3
	 M326.8,175.2l4.2-0.3 M326.8,175.2l-0.2-1.7 M331,174.9v-1.7 M331,173.2l-4.3,0.3 M331,174.9v1c24.8-2.3,51.1-3.7,79-4h22.8
	c9.3,0.2,18.5,0.3,27.3,0.7c17.6,0.7,34.6,1.8,50.9,3.3v-1 M330.3,170.9c-0.5,0.2-0.8,0.5-1,0.8l-2,0.2l-0.7-0.5 M326.6,173.6v-2.2
	 M330.3,170.9c0.3,0.5,0.7,1.2,0.7,2.3"
          />
          <path
            fill="none"
            d="M511.6,170.9c-0.5-0.2-1.2-0.3-2-0.5c-15.6-1.5-32.1-2.7-49.6-3.2c-8.8-0.3-18-0.7-27.3-0.7H410
	c-27.9,0.3-53.9,1.7-77.7,4c-0.8,0.2-1.5,0.3-2,0.5 M326.6,171.4c-5.5,0.7-9.3,2-11.3,3.8c-3.5,3-6,6.3-7.3,10l-0.2,0.3l-4.2,9h-0.2
	c0,0-0.2,0-0.2,0.2l-24,42.7 M561.5,319.3l-1,36.1c0,1.3-1.5,2-4.5,1.7c-3.3,0-5.3-1.7-6-5.2l-0.7-27.3c0.3-5,3.2-7.6,8.6-8.3
	C560.7,315.8,561.8,316.8,561.5,319.3L561.5,319.3z"
          />
          <path
            fill="none"
            d="M572.6,366.3c-4.7-4.8-16.1-7.5-34.3-7.6c-5.5-0.5-9.1-2.3-10.8-5.2c-6.3-10.1-12.5-18.5-18.1-24.5
	c-4.7-5.2-10.3-7.8-17.3-7.8H349.8c-7,0.2-12.6,2.8-17.3,7.8c-5.7,6.2-11.6,14.3-18.1,24.5c-1.8,2.8-5.3,4.7-10.8,5.2
	c-18.1,0.3-29.4,2.8-34.3,7.6 M572.6,366.3l-1.3,5.2c-2,5-4.5,7.2-7.7,6.5c0.3-1.3-1.8-2.7-6-4c-6.8-1.7-14-2.5-21.3-2.8
	 M536.4,371.2c-2.2-0.2-4.2-0.2-6.3-0.2l-0.3-1.2 M536.4,381.8v-10.6 M536.4,384.6v-2.8 M579.9,312.4l-7,52.1l-0.3,1.8 M579.9,364.3
	l2-26.9 M579.9,364.3v-51.9"
          />
          <path
            fill="none"
            d="M536.4,384.6v34.4c0.3,3.7,3.8,5.8,10.5,6.7h0.3v0.2h0.2l8.3,0.3l12.5-0.3l0.2-0.2l1-0.2
	c6.7-0.8,10.1-3,10.5-6.7v-54.7 M525.7,358.5c-5.8-10.3-11.6-19-17.8-26.1c-4.3-5.5-10.1-8.1-17.6-8h-139c-7.5-0.3-13.3,2.3-17.6,8
	c-6.2,7.2-12,15.8-17.8,26.1L312,370 M365.1,375.3c0.3,1,1.8,1.8,4.7,2.7l17,4.7l2.3,0.3h51.9c1.5-0.2,3-0.8,4.3-1.7l9.6-7
	c0.5-0.5,2-0.8,4.3-0.8c10.5-0.2,19.3-1.2,26.3-3.3"
          />
          <path
            fill="none"
            d="M365.1,370h22c3.2-0.3,6.5,0.2,9.6,1.8c4.7,2.2,9.6,3.5,14.8,3.7h18.8c5.2-0.3,10.1-1.5,14.8-3.7
	c3.2-1.5,6.5-2.2,9.6-1.8h30.6 M536.4,381.8h-17.1c-2.5,0.2-3.8-0.3-4-1.5l-1.7-10.5 M486.1,369.8l-0.7,0.2 M485.5,370h27.9
	 M337.6,357.8c19.3-0.5,38.3-0.7,57-0.5h52.4c25.8-0.3,52.1,0,78.7,1.2 M365.1,375.3V370 M513.4,370h16.1 M529.5,370l-3.8-11.5
	 M337.6,357.8c-2.2-1.7-4.7-2.5-7.5-2.5c-3.2,0-5.8,1-8.3,3c-0.5,0.3-0.8,0.8-1.3,1.3c-2.2,2.3-3.5,5-3.8,8.1"
          />
          <path
            fill="none"
            d="M341.4,362c-0.5-0.8-1.2-1.7-1.8-2.3c-0.7-0.7-1.3-1.3-2-1.8 M280.2,319.3l1,36.1c0,1.3,1.5,2,4.5,1.7
	c3.3,0,5.3-1.7,6-5.2l0.7-27.3c-0.3-5-3.2-7.6-8.6-8.3C281.1,315.8,279.9,316.8,280.2,319.3L280.2,319.3z M269.3,366.3l-0.5-1.8
	l-6.8-52.1 M305.5,371.2c-7.5,0.2-14.5,1.2-21.3,2.8c-4.2,1.3-6.2,2.7-6,4c-3.2,0.7-5.7-1.5-7.6-6.5l-1.3-5.2 M336.3,368.8
	c0-1.7-0.7-3.2-1.8-4.3c-1.2-1.2-2.7-1.8-4.3-1.8c-1.7,0-3.2,0.7-4.3,1.8c-1.2,1.2-1.8,2.7-1.8,4.3l0.2,1c0.2,1.3,0.8,2.3,1.7,3.3
	c0.5,0.5,1.2,1,1.8,1.3c0.8,0.3,1.5,0.5,2.5,0.5c1.7,0,3.2-0.7,4.3-1.8c1-1,1.5-2,1.8-3.3L336.3,368.8L336.3,368.8z M365.1,370
	h-21.5c0-3-0.7-5.7-2.2-8"
          />
          <path
            fill="none"
            d="M334.5,375.3l1.2-1c1.3-1.3,2-2.7,2.2-4.3v-1c0-2.2-0.8-4-2.3-5.5c-0.7-0.7-1.3-1.2-2.2-1.5"
          />
          <path
            fill="none"
            d="M333.5,362c-1-0.5-2.2-0.8-3.3-0.8c-2.2,0-4,0.8-5.5,2.3c-1,1-1.7,2.2-2,3.5 M305.5,381.8h17.1
	c2.5,0.2,3.8-0.3,4-1.5l0.7-4.3 M327.5,376.1c0.8,0.3,1.8,0.5,2.8,0.5c1.7,0,3-0.5,4.3-1.3 M322.7,367c-0.2,0.7-0.2,1.2-0.2,1.8v1
	c0.2,1.7,1,3.2,2.2,4.3c0.8,0.8,1.7,1.3,2.7,1.8 M333.5,362h8 M316.7,367.8c-0.2,0.7-0.2,1.5-0.2,2.2h-4.3 M322.7,367l-6,0.8
	 M312.2,370l-0.3,1.2c-2.2,0-4.2,0-6.3,0.2 M305.5,384.6v-2.8 M305.5,371.2v10.6 M260,337.4l2,26.9 M261.9,312.4v51.9 M261.9,364.3
	v54.7c0.3,3.7,3.8,5.8,10.5,6.7l1,0.2l0.2,0.2l12.5,0.3l8.3-0.3h0.2v-0.2h0.3c6.7-0.8,10.1-3,10.5-6.7v-34.4 M305.5,384.6h230.8
	 M334.5,375.3h30.6 M581.4,300.5l-1.5,12 M260.3,300.5l1.7,12 M303.4,194.7C303.4,194.7,303.2,194.7,303.4,194.7l-24,42.7l-0.8,1.7
	l-4,7l0.3-0.2c10.1-5,24.4-8.6,42.7-11.1c1.5-1.5,2.7-3.7,3.8-6.3c3.7-22.6,5.5-40.4,5.3-53.2v-1.5l-0.2-2.2
	c-5.5,0.7-9.3,2-11.3,3.8c-3.5,3-6,6.3-7.3,10l-0.2,0.3l-4.2,9L303.4,194.7L303.4,194.7z"
          />
          <path
            fill="none"
            d="M346.4,180.2h1.2c21-2,45.6-3,73.8-3.2c27.9,0.2,52.2,1.3,72.8,3.2h1.2c2.5,0.3,3.8,1.7,4.2,3.7l3.2,28.8
	c-0.3,4.2-2.2,7.6-5.7,10.3c-4.7,3-9.1,4.7-13.6,4.7l37.1,1l-0.2-0.5c-3.7-22.6-5.5-40.4-5.3-53.2l-4.2-0.3v1
	c-16.3-1.5-33.3-2.7-50.9-3.3c-8.8-0.3-18-0.7-27.3-0.7H410c-27.9,0.3-54.2,1.7-79,4v-1l-4.2,0.3c0.2,12.8-1.7,30.6-5.3,53.2
	l-0.2,0.5l37.1-1c-4.5-0.2-9-1.7-13.6-4.7c-3.5-2.8-5.3-6.3-5.7-10.3l3.2-28.8C342.6,181.9,343.9,180.6,346.4,180.2L346.4,180.2z"
          />
          <path
            fill="none"
            d="M346.6,180.2c-2.5,0.5-4,1.7-4.2,3.8l-3.2,28.8c0.3,4.2,2.2,7.6,5.7,10.3c4.7,3,9.1,4.7,13.6,4.7l19.6-0.5
	c14.1-0.3,28.4-0.7,43.4-0.7l17.8,0.2c8.3,0.2,16.5,0.2,24.6,0.5l19.6,0.5c4.5-0.2,9-1.7,13.6-4.7c3.5-2.8,5.3-6.3,5.7-10.3
	l-3.2-28.8c-0.3-2.2-1.7-3.3-4.2-3.7h-1.2c-20.6-2-44.9-3-73-3.2c-28.4,0.2-53.1,1.3-73.8,3.2L346.6,180.2L346.6,180.2z
	 M332.3,170.4c-0.8,0.2-1.5,0.3-2,0.5c0.3,0.5,0.7,1.2,0.7,2.3v2.7c24.8-2.3,51.1-3.7,79-4h22.8c9.3,0,18.5,0.3,27.3,0.7
	c17.6,0.7,34.6,1.8,50.9,3.3v-1v-1.7c0.2-1.2,0.3-1.8,0.7-2.3c-0.5-0.2-1.2-0.3-2-0.5c-15.6-1.5-32.1-2.7-49.6-3.2
	c-8.8-0.3-18-0.7-27.3-0.7H410C382,166.9,356.1,168.1,332.3,170.4L332.3,170.4z"
          />
          <path
            fill="none"
            d="M331,173.2c-0.2-1.2-0.3-1.8-0.7-2.3c-0.5,0.2-0.8,0.5-1,0.8l-2,0.2l-0.7-0.5l0.2,2.2L331,173.2L331,173.2z"
          />
          <path
            fill="none"
            d="M331,173.2l-4.3,0.3l0.2,1.7l4.2-0.3 M239.3,222.1c-3.7,0.5-6,2.8-7.3,7v0.2l-0.2,0.3l-0.3,5.3l0.2,2.7
	c0,0.7,0.2,1.2,0.5,1.7l0.2,0.3h0.2c1,1.3,2.2,2.3,3.8,2.7l2.3,0.5l15.3,1.7l6.5-0.3c4.7-1,7.8-3.2,9.5-6.7c1-3.5,1.3-7.3,0.8-11.3
	c-1.2-3.2-5-4.7-11.8-4.8h-3.2C248.5,221.3,243,221.5,239.3,222.1L239.3,222.1z M233.8,229.6c1.2-3.8,3.3-5.8,6.5-6.3h0.2
	c3.3-0.7,8.1-0.8,15-0.7h2.8c6,0.2,9.5,1.7,10.5,4.3c0.5,3.7,0.3,7-0.7,10.1c-2.2,4.5-6.8,6.5-14.3,6.3l-13.8-1.5
	c-1.5-0.2-2.7-0.5-3.7-1l-0.8-0.7c-0.5-0.3-0.8-0.8-1.2-1.3l-0.3-0.2l-0.5-1.3l-0.3-2.8l0.3-4.8L233.8,229.6L233.8,229.6z"
          />
          <path
            fill="none"
            d="M233.8,229.6v0.5l-0.3,4.8l0.3,2.8l0.3,1.3l0.3,0.2c0.3,0.5,0.7,1,1.2,1.3l0.8,0.7c1,0.5,2.2,0.8,3.7,1
	l13.8,1.5c7.5,0.2,12.3-1.8,14.3-6.3c1-3.2,1.2-6.7,0.7-10.1c-1-2.8-4.5-4.2-10.6-4.3h-2.8c-6.7-0.2-11.6,0.2-15,0.7h-0.2
	C237.2,223.8,235,225.8,233.8,229.6L233.8,229.6z M231.5,234.9l0.3-5.3c-1,0-1.7,0.5-1.8,1.5v1.8C230,233.9,230.5,234.6,231.5,234.9
	L231.5,234.9z"
          />
          <path
            fill="none"
            d="M254.1,244.9l6.3,0.5c2.8-0.5,6-2,9.5-4.7c0.8-0.5,1.8-0.7,2.7-0.3c2.7,0.3,4.7-0.2,5.8-1.2l0.8-1.7l-0.5-0.2
	h-0.2c-1.7-0.2-2.7-0.5-3.5-1l-0.7-0.8l-3.8-9.3c0.5,4,0.3,7.8-0.8,11.3c-1.7,3.5-4.8,5.7-9.5,6.7l-6.5,0.3L254.1,244.9L254.1,244.9
	z M321.3,228.8l0.2-0.5c-1.2,2.7-2.3,4.8-3.8,6.3l3.7-0.5c17.8-1.3,36.3-2.3,55.2-2.8c14.6-0.5,29.6-0.7,44.9-0.7l27.1,0.3
	c5.7,0.2,11.3,0.3,17,0.3c19,0.5,37.3,1.5,55.2,2.8l3.7,0.5c-1.5-1.5-2.7-3.7-3.8-6.3l0.2,0.5l-37.3-1l-19.6-0.5
	c-8.1-0.3-16.3-0.3-24.6-0.5l-17.8-0.2c-14.8,0-29.3,0.2-43.4,0.7l-19.6,0.5L321.3,228.8L321.3,228.8z"
          />
          <path
            fill="none"
            d="M321.3,234.3l-3.7,0.5c0,4.2,0,8.5,0.2,13.3v1.3c0.2,5.5,0.5,11.5,1,18c0,2.2,1.7,4.2,4.7,5.8
	c6.2,2.8,9.8,5.8,11.1,8.6l5,12l1.2,3c0.8,2.3,3.3,3.5,7.5,3.5c24.8,0.8,49.1,1.2,73.2,1l26.3-0.2c15.1-0.2,30.4-0.3,45.9-0.8
	c4.2,0,6.5-1.2,7.5-3.5l1.2-3l5-12c1.2-2.8,5-5.8,11.1-8.6c3.2-1.7,4.7-3.7,4.7-5.8c0.5-6.5,0.8-12.5,1-18v-1.3
	c0.2-4.8,0.2-9.3,0.2-13.3l-3.7-0.5c-17.8-1.3-36.3-2.3-55.2-2.8c-5.7-0.2-11.3-0.3-17-0.3l-27.1-0.3c-15.3,0-30.3,0.2-44.9,0.7
	C357.6,231.9,339.1,232.9,321.3,234.3L321.3,234.3z M395.2,231.9l6.3-0.2l9.1-0.2h29.8l4.7,0.2h2.3l16.8,0.3c2.3,1,5.3,3.7,8.8,8
	c2.7,2.8,4.2,6.2,4.2,9.8c-0.2,1.7-0.5,3.7-0.8,5.8c-0.8,5.2-2.3,11.6-4.3,19.1c-1.8,9.5-5.7,14.6-11.3,15.6
	c-10.8,1-24.1,1.3-39.6,1.2c-16,0.3-29.4,0-40.6-1.2c-5.7-1-9.5-6.2-11.3-15.6c-2-7.6-3.5-14-4.3-19.1c-0.3-2.2-0.7-4.2-0.8-5.8
	c0.2-3.8,1.5-7.2,4.2-9.8c3.5-4.3,6.5-7,8.8-8C383.4,232.1,389.2,231.9,395.2,231.9L395.2,231.9z"
          />
          <path
            fill="none"
            d="M395.2,231.9l2.2,4.2c0.5,0.7,1.8,1,4,1h0.2v-5.2L395.2,231.9L395.2,231.9z"
          />
          <path
            fill="none"
            d="M397.3,236.1l-2.2-4.2c-6,0.2-11.8,0.2-17.6,0.3c-2.3,1-5.3,3.7-8.8,8c-2.7,2.8-4.2,6.2-4.2,9.8
	c0.2,1.7,0.3,3.7,0.8,5.8h111.3c0.3-2.2,0.7-4.2,0.8-5.8c-0.2-3.8-1.5-7.1-4.2-9.8c-3.5-4.3-6.5-7-8.8-8l-16.8-0.3h-0.8l-2.2,4.2
	c-0.5,0.7-1.8,1-4,1h-39.4C399.2,237.1,397.8,236.8,397.3,236.1L397.3,236.1z M412,241.9l1.2-0.8c1.3-0.8,2.8-1.3,4.7-1.7l2-0.2
	c2.7-0.2,5,0.3,7.2,1.5c0.5,0.2,1,0.5,1.3,0.8l1.2,1c0.3,0.3,0.7,0.8,0.8,1.2c0.3,0.5,0.5,1,0.5,1.5c0.2,0.7,0,1.2-0.2,1.8
	c-0.2,0.5-0.3,0.8-0.7,1.3l-1,1.2l-1.2,0.8c-1,0.7-1.8,1-3,1.3c-1.2,0.3-2.3,0.5-3.7,0.7l-4-0.3c-1.2-0.3-2.2-0.7-3.2-1.2
	s-1.8-1.2-2.5-1.8c-0.5-0.3-0.7-0.8-1-1.2c-0.3-0.5-0.5-1-0.7-1.5c-0.2-0.5,0-1.2,0.2-1.8c0.2-0.5,0.3-1,0.7-1.5
	C411.3,242.8,411.6,242.3,412,241.9L412,241.9z"
          />
          <path
            fill="none"
            d="M413.1,241.1l-1.2,0.8c-0.3,0.3-0.7,0.8-1,1.2c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.7-0.2,1.2-0.2,1.8
	c0.2,0.5,0.3,1,0.7,1.5c0.2,0.3,0.5,0.8,1,1.2c0.7,0.7,1.5,1.3,2.5,1.8c1,0.5,2,0.8,3.2,1.2l4,0.3c1.3-0.2,2.5-0.3,3.7-0.7
	c1-0.3,2-0.8,3-1.3l1.2-0.8l1-1.2c0.3-0.5,0.7-1,0.7-1.3c0.2-0.7,0.3-1.2,0.2-1.8c0-0.5-0.2-1-0.5-1.5c-0.2-0.3-0.5-0.8-0.8-1.2
	l-1.2-1c-0.5-0.3-0.8-0.5-1.3-0.8c-2.2-1-4.5-1.5-7.2-1.5l-2,0.2C416.1,239.8,414.5,240.3,413.1,241.1L413.1,241.1z M412.5,245.1
	v0.3h3.2v0.5l-0.2,0.3l-0.3,0.2h-3c-0.2,0-0.2-0.2-0.2-0.3h3c0.2,0,0.3-0.2,0.2-0.3H412v-0.7c0-0.2,0.2-0.3,0.3-0.3h3.3v0.3
	L412.5,245.1L412.5,245.1z M412.1,244.4c0.3-0.7,0.7-1.3,1.2-1.8s1.2-1,1.8-1.3c1.5-0.8,3.3-1.3,5.3-1.3l-0.2,0.2
	c-0.5,0.5-1,1.2-1.3,2c-0.3,0.8-0.7,1.5-0.8,2.5h-6L412.1,244.4L412.1,244.4z M420.4,241.8c0-0.7,0-1.3,0.2-1.8l3,0.3
	c0.8,0.2,1.7,0.5,2.3,0.8c1.7,0.8,2.7,2,3,3.3h-7.5l-0.3-0.3l-0.3-0.5C420.6,243.1,420.4,242.4,420.4,241.8L420.4,241.8z
	 M419.8,244.8v0.3h-3.2v1h3.2v0.3h-3.3c-0.2,0-0.3-0.2-0.3-0.3v-1c0-0.2,0.2-0.2,0.3-0.3H419.8L419.8,244.8z M419.6,247.1l0.3,0.5
	c0.3,0.3,0.5,1,0.5,1.8s0,1.5-0.2,2.2c-2,0-3.7-0.5-5.2-1.3s-2.5-2-3-3.3h6.8C419.1,246.7,419.3,246.9,419.6,247.1L419.6,247.1z
	 M425.3,244.8h3.3c0.2,0,0.3,0.2,0.3,0.2v1.2c-0.2,0.2-0.3,0.2-0.5,0.2v-1.3h-2.7v1.3h-0.7v-1.5L425.3,244.8L425.3,244.8z
	 M424.8,245.9l-0.2,0.3l-0.8,0.2h-2.3l-0.3-0.3c0,0-0.2-0.2-0.2-0.5s0-0.5,0.2-0.5c0,0,0-0.2,0.2-0.2h2.7l0.7,0.2l0.2,0.3
	L424.8,245.9L424.8,245.9z M420.1,244.8h0.5v1.5h-0.5V244.8L420.1,244.8z M421.8,249.4l0.7-1.2c0.2-0.5,0.3-1,0.3-1.3h6.2
	c-0.3,1.5-1.5,2.7-3,3.5c-0.7,0.3-1.7,0.7-2.5,0.8l-3,0.3L421.8,249.4L421.8,249.4z"
          />
          <path
            fill="none"
            d="M412.5,245.2l3.2-0.2v-0.3h-3.3c-0.2,0-0.3,0.2-0.3,0.3v0.7h3.2c0,0.2,0,0.3-0.2,0.3h-3c0,0.2,0,0.2,0.2,0.3h3
	l0.3-0.2l0.2-0.3v-0.5h-3.2V245.2L412.5,245.2z M413.3,242.4c-0.5,0.5-0.8,1.2-1.2,1.8h5.8c0.2-0.8,0.5-1.7,0.8-2.5
	c0.3-0.7,0.8-1.3,1.3-2l0.2-0.2c-2,0-3.8,0.5-5.3,1.3C414.5,241.4,413.8,241.9,413.3,242.4L413.3,242.4z M420.4,239.9
	c-0.2,0.7-0.2,1.3-0.2,1.8c0,0.7,0.2,1.3,0.3,1.8l0.3,0.5l0.3,0.3h7.5c-0.5-1.5-1.5-2.5-3-3.3c-0.7-0.3-1.5-0.7-2.3-0.8L420.4,239.9
	L420.4,239.9z M419.8,245.1v-0.3h-3.3c-0.2,0-0.3,0.2-0.3,0.2v1c0,0.2,0.2,0.2,0.3,0.3h3.3v-0.2h-3.2v-1H419.8L419.8,245.1
	L419.8,245.1z M419.9,247.6l-0.3-0.5c-0.2-0.2-0.3-0.3-0.5-0.3h-6.8c0.5,1.3,1.5,2.5,3,3.3c1.5,0.8,3.2,1.3,5.2,1.3
	c0.2-0.7,0.2-1.5,0.2-2.2C420.4,248.6,420.3,247.9,419.9,247.6L419.9,247.6z M425.3,244.8v1.5h0.7v-1.3h2.7v1.3c0.3,0,0.3,0,0.5-0.2
	v-1.2c0-0.2-0.2-0.2-0.3-0.2L425.3,244.8L425.3,244.8L425.3,244.8z M424.6,246.2l0.2-0.3v-0.8l-0.2-0.3l-0.8-0.2h-2.5l-0.2,0.2
	c-0.2,0-0.2,0-0.2,0.2s-0.2,0.3-0.2,0.5c0,0.3,0,0.5,0.2,0.5l0.3,0.3L424.6,246.2L424.6,246.2z M424.3,245.6v0.5h-2.5v-0.8h2.5
	L424.3,245.6L424.3,245.6z"
          />
          <path
            fill="none"
            d="M424.3,245.9v-0.8h-2.5l-0.2,0.3v0.5 M420.8,244.8h-0.7v1.5h0.7 M422.4,248.1l-0.7,1.2l-1.5,2.2l3-0.3
	c1-0.2,1.8-0.5,2.5-0.8c1.7-0.8,2.7-2,3-3.5h-6.1C422.8,247.2,422.6,247.7,422.4,248.1L422.4,248.1z M401.5,231.8v5.2h9.1v-5.3
	L401.5,231.8L401.5,231.8z M406.5,235.3c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.7,0.2,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8
	C406.8,234.8,406.6,235.1,406.5,235.3L406.5,235.3z"
          />
          <path
            fill="none"
            d="M405.6,235.6c0.3,0,0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
	c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.7,0.3,0.8C405,235.4,405.3,235.6,405.6,235.6L405.6,235.6z M410.6,231.8
	v5.3h10.1v-5.3H410.6z M416.1,235.6c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.5,0.3-0.8
	c0.2-0.2,0.5-0.3,0.8-0.3s0.7,0.2,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.2,0.7-0.3,0.8C416.8,235.4,416.5,235.6,416.1,235.6
	L416.1,235.6z"
          />
          <path
            fill="none"
            d="M415.3,235.3c0.2,0.2,0.5,0.3,0.8,0.3s0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8
	c-0.2-0.2-0.5-0.3-0.8-0.3s-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8C415,234.8,415.1,235.1,415.3,235.3z M432.4,231.8h-1.2
	 M431.3,231.8h-0.5 M432.4,231.8h-1.2v5.3h9.1v-5.2L432.4,231.8L432.4,231.8z M435.4,233.6c0.2-0.2,0.5-0.3,0.8-0.3s0.7,0.2,0.8,0.3
	c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.7-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	C435.1,234.1,435.2,233.9,435.4,233.6z"
          />
          <path
            fill="none"
            d="M436.2,233.3c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.7,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
	s0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8s-0.2-0.5-0.3-0.8C436.7,233.4,436.6,233.3,436.2,233.3L436.2,233.3z M431.3,231.8h-10.5
	v5.3h10.6v-5.3H431.3z M426.6,233.6c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.7-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3
	c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	C426.1,233.3,426.3,233.4,426.6,233.6L426.6,233.6z"
          />
          <path
            fill="none"
            d="M426.8,234.4c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
	c0,0.3,0.2,0.7,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.7-0.2,0.8-0.3C426.8,235.1,426.8,234.8,426.8,234.4L426.8,234.4z
	 M369.6,275.2c1.8,9.5,5.7,14.6,11.3,15.6c11.1,1,24.6,1.3,40.6,1.2c15.5,0.3,28.6-0.2,39.6-1.2c5.7-1,9.5-6.2,11.3-15.6
	c2-7.6,3.5-14,4.3-19.1H365.4C366.1,261.2,367.6,267.5,369.6,275.2z M446.7,231.9h-1.5l-4.7-0.2v5.2h0.2c2.2,0,3.5-0.3,4-1
	L446.7,231.9L446.7,231.9z M445.1,231.9h1.7 M447.6,231.9h-0.8 M317.8,249.4v-1.3c-10.3-0.3-19-0.3-25.9,0.3
	c-4.2,0.5-6.3,2.7-6.5,6.5V271l1-0.7v-16c0.7-2.5,3-4,6.7-4.3l13.3-0.7C310,249.2,313.7,249.2,317.8,249.4L317.8,249.4z"
          />
          <path
            fill="none"
            d="M317.8,249.4h-11.3l-13.3,0.7c-3.8,0.3-6,1.8-6.7,4.3v0.5l3.5-0.3l1-0.2c0.7-1.7,2.5-2.7,6-3.3h0.3l1.8-0.2
	l8.6-0.5h9.3l0.7,17.5c0.2,2.2,1.3,3.7,3.3,4.7c4.7,2.3,8.1,4.5,10.5,6.7l-26.9-3.8h-0.3c-1.5,0-2.8-0.2-4-0.7l-5.5-0.8
	c-2.2-0.5-3.3-1.3-3.8-2.5c-0.2-0.5-0.3-0.8-0.8-1c-0.7-0.5-1.8-0.5-3.5-0.2l-1,0.7V287l54.2,6.7l-5-12c-1.3-2.8-5-5.8-11.1-8.6
	c-3.2-1.7-4.7-3.7-4.7-5.8C318.3,260.9,318,254.9,317.8,249.4L317.8,249.4z M286.4,275.4v-0.2c0-0.2,0.2-0.2,0.2-0.2h0.2l45.9,6.5
	l0.2,0.2l0.2,0.2l3.7,10v0.2l-0.2,0.2h-0.3l-49.4-6.3l-0.2-0.2l-0.2-0.2L286.4,275.4L286.4,275.4z"
          />
          <path
            fill="none"
            d="M286.6,275v0.2l0.3,10.3v0.2l0.2,0.2l49.4,6.3h0.3l0.2-0.2v-0.2l-3.7-10l-0.2-0.2l-0.2-0.2l-45.9-6.5L286.6,275
	L286.6,275z M287.4,285.2l-0.2-9.5l24.4,3.5l20.8,3l3.5,9.3l-24.3-3.2L287.4,285.2L287.4,285.2z"
          />
          <path
            fill="none"
            d="M287.2,275.7l0.2,9.5l24.3,3.2v-9.1L287.2,275.7L287.2,275.7z M299.4,281.8c0-0.5,0.2-0.8,0.5-1.2
	s0.7-0.5,1.2-0.5s0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.2,0.8-0.5,1c-0.3,0.3-0.7,0.5-1.2,0.5c-0.3,0-0.8-0.2-1.2-0.5
	S299.4,282.2,299.4,281.8L299.4,281.8z"
          />
          <path
            fill="none"
            d="M299.9,280.7c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5
	c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.8-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5S300.2,280.3,299.9,280.7L299.9,280.7z M311.5,279.2v9.1
	l24.3,3.2l-3.5-9.3L311.5,279.2L311.5,279.2z M324,284.8c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5s-0.8-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5s0.8,0.2,1.2,0.5C323.8,284,324,284.3,324,284.8L324,284.8z"
          />
          <path
            fill="none"
            d="M323.6,286c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.8-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5s-0.8,0.2-1.2,0.5
	c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2s0.7,0.5,1.2,0.5S323.3,286.3,323.6,286L323.6,286z M317.7,248.1
	c-0.2-4.8-0.2-9.3-0.2-13.3c-18.3,2.5-32.6,6.2-42.7,11.1c2.3-1.2,3.5,1,3.3,6.2l-0.7,34.8l8.1,0.3v-32.3c0.2-3.8,2.3-6,6.5-6.5
	C298.9,247.7,307.5,247.7,317.7,248.1L317.7,248.1z M274.6,245.9l4-7c-1.3,1.2-3.2,1.5-5.8,1.2c-0.8-0.2-1.8,0-2.7,0.3
	c-3.5,2.7-6.7,4.2-9.5,4.7L274.6,245.9L274.6,245.9z"
          />
          <path
            fill="none"
            d="M274.8,245.8l-0.3,0.2c-1.5,1-3,4-4.2,8.8c-0.8,5.2-2.2,9.6-3.8,13.3l-2.7,6.3v0.2c-1.3,4.3-3.3,8-6.2,11
	c0,6,0.2,13,0.5,21H258l-0.2,0.2v0.2c0.3,8.3,1,17.5,1.7,27.8l0.2,2.7l-1-39.2c0.2-5.7,0.7-4.8,1.3,2.5l7-10.3c1-2.2,4.2-3.3,10-3.5
	l0.7-34.8C278.2,246.7,277.1,244.8,274.8,245.8L274.8,245.8z M273.9,250.7h0.3c0.2,0,0.2,0.2,0.2,0.7l-1.7,8l-2.2,9
	c-1.2,4.5-2,6.7-2.3,6.7c-1,1.7-1.7,2.3-2.2,2.5c-0.5,0-0.7-0.8-0.7-2.3c-0.3,0,0.2-2.2,1.5-6.5c0.8-3,1.8-5.8,2.8-8.6l3-8.1
	c0.3-0.5,0.5-0.8,0.5-0.8C273.6,250.9,273.8,250.7,273.9,250.7L273.9,250.7z"
          />
          <path
            fill="none"
            d="M274.3,250.7h-0.3c-0.2,0-0.3,0.2-0.5,0.3c-0.2,0-0.3,0.3-0.5,0.8l-3,8.2c-1,2.8-2,5.8-2.8,8.6
	c-1.3,4.3-1.8,6.5-1.5,6.5c0,1.5,0.2,2.3,0.7,2.3c0.5,0,1.2-0.8,2.2-2.5c0.3,0,1.2-2.3,2.3-6.6l2.2-9l1.7-8
	C274.4,250.9,274.3,250.7,274.3,250.7L274.3,250.7z M297.2,251.1h-0.3c-3.3,0.7-5.3,1.8-6,3.3l-1,0.2v16c0.3,0.3,0.7,0.7,0.8,1
	c0.5,1.2,1.7,2,3.8,2.5l5.5,0.7c-2-0.7-3.7-1.7-5.3-3.3c-2.5-2.5-3.8-5.7-3.8-9.3c0-3.7,1.3-6.7,3.8-9.3
	C295.4,252.2,296.2,251.6,297.2,251.1L297.2,251.1z"
          />
          <path
            fill="none"
            d="M298.9,250.9l-1.8,0.2c-0.8,0.5-1.7,1.2-2.5,2c-2.5,2.7-3.8,5.7-3.8,9.3c0,3.7,1.3,6.7,3.8,9.3
	c1.5,1.5,3.3,2.7,5.3,3.3l4.3,0.7c3.5,0,6.5-1.3,9-3.8c2.7-2.5,3.8-5.7,3.8-9.3c0-3.7-1.3-6.7-3.8-9.3c-1.2-1.2-2.5-2-3.8-2.7h-1.8
	c2,0.5,3.7,1.7,5.2,3.2c2.5,2.5,3.7,5.5,3.7,8.8c0,3.5-1.2,6.3-3.7,8.8s-5.3,3.7-8.8,3.7c-3.5,0-6.3-1.2-8.8-3.7s-3.7-5.3-3.7-8.8
	s1.2-6.3,3.7-8.8C296.2,252.2,297.5,251.4,298.9,250.9L298.9,250.9z"
          />
          <path
            fill="none"
            d="M295,253.4c-2.5,2.5-3.7,5.5-3.7,8.8c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7c3.5,0,6.3-1.2,8.8-3.7
	s3.7-5.3,3.7-8.8s-1.2-6.3-3.7-8.8c-1.5-1.5-3.2-2.5-5.2-3.2l-8.6,0.5C297.5,251.4,296.2,252.2,295,253.4L295,253.4z M295.2,262.2
	c0-2.5,0.8-4.5,2.5-6.2c1.7-1.7,3.8-2.5,6.2-2.5c2.3,0,4.5,0.8,6.2,2.5c1.7,1.7,2.7,3.8,2.7,6.2c0,2.5-0.8,4.5-2.7,6.2
	c-1.7,1.7-3.8,2.7-6.2,2.7c-2.5,0-4.5-0.8-6.2-2.7C296,266.7,295.2,264.7,295.2,262.2L295.2,262.2z"
          />
          <path
            fill="none"
            d="M297.7,256.1c-1.7,1.7-2.5,3.8-2.5,6.2c0,2.5,0.8,4.5,2.5,6.2s3.8,2.7,6.2,2.7c2.3,0,4.5-0.8,6.2-2.7
	c1.7-1.7,2.7-3.7,2.7-6.2s-0.8-4.5-2.7-6.2c-1.7-1.7-3.8-2.5-6.2-2.5C301.5,253.6,299.5,254.4,297.7,256.1L297.7,256.1z M296,262.2
	c0-2.2,0.8-4,2.3-5.7c1.5-1.5,3.3-2.3,5.7-2.3c2.2,0,4,0.8,5.5,2.3c1.5,1.5,2.3,3.5,2.3,5.7s-0.8,4-2.3,5.5
	c-1.5,1.5-3.3,2.3-5.5,2.3s-4-0.8-5.7-2.3C296.9,266.2,296,264.4,296,262.2z"
          />
          <path
            fill="none"
            d="M298.4,256.6c-1.5,1.5-2.3,3.5-2.3,5.7s0.8,4,2.3,5.5c1.5,1.5,3.3,2.3,5.7,2.3c2.2,0,4-0.8,5.5-2.3
	c1.5-1.5,2.3-3.3,2.3-5.5s-0.8-4-2.3-5.7c-1.5-1.5-3.3-2.3-5.5-2.3C301.7,254.4,299.9,255.1,298.4,256.6z M300.2,259.2
	c0.3,0.3,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.5-0.7c-0.3-0.3-0.7-0.8-0.7-1.3
	c0-0.5,0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.5-0.5C299.4,258.7,299.9,258.9,300.2,259.2L300.2,259.2z M299,266c0-0.5,0.2-1,0.5-1.5
	c0.3-0.3,0.8-0.5,1.5-0.5c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3s-0.8,0.7-1.3,0.7s-1-0.2-1.5-0.7
	C299.2,267,299,266.5,299,266L299,266z M305.2,255.4c0.3,0.3,0.7,0.8,0.7,1.3s-0.2,1-0.7,1.3c-0.3,0.5-0.8,0.7-1.3,0.7
	c-0.5,0-1-0.2-1.5-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3s0.8-0.7,1.5-0.7C304.4,254.7,304.9,254.9,305.2,255.4L305.2,255.4z
	 M306.7,260.2c0-0.5,0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.5,0.3,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3
	c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.3-0.7C306.9,261.2,306.7,260.7,306.7,260.2L306.7,260.2z M302.5,263.5c-0.3-0.3-0.5-0.8-0.5-1.3
	s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.5-0.5c0.5,0,1,0.2,1.3,0.5c0.3,0.5,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7
	C303.4,264.2,302.9,263.9,302.5,263.5L302.5,263.5z M308.7,264.7c0.3,0.5,0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3
	c-0.3,0.3-0.8,0.7-1.3,0.7c-0.5,0-1-0.2-1.5-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.5-0.5
	C307.9,264,308.2,264.2,308.7,264.7L308.7,264.7z"
          />
          <path
            fill="none"
            d="M300.9,260.7c0-0.5-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5c-0.3,0.3-0.7,0.8-0.7,1.5
	c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7C300.5,261.7,300.9,261.2,300.9,260.7L300.9,260.7z M299.5,264.7
	c-0.3,0.5-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3c0.5,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.3-0.5C300.4,264,300,264.2,299.5,264.7L299.5,264.7z M305.9,256.7c0-0.5-0.2-1-0.7-1.3
	c-0.3-0.3-0.8-0.7-1.3-0.7c-0.5,0-1,0.2-1.5,0.7c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	C305.7,257.7,305.9,257.2,305.9,256.7L305.9,256.7z M307.4,258.7c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5S307.7,258.4,307.4,258.7
	L307.4,258.7z M301.9,262.2c0,0.5,0.2,1,0.5,1.3s0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5
	c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.5,0.5C302,261.2,301.9,261.7,301.9,262.2L301.9,262.2z M309.2,266c0-0.5-0.2-1-0.7-1.5
	c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3s0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	C309,267,309.2,266.5,309.2,266L309.2,266z M289.9,254.6l-3.5,0.3v15.5c1.5-0.3,2.7-0.3,3.5,0.2V254.6L289.9,254.6z M266.4,268.2
	c-1.7,0.3-2.5,1.2-2.5,2.5c-0.2,1.5-0.2,2.7,0,3.8L266.4,268.2z M260.3,300.5c-0.8-7.3-1.2-8.1-1.3-2.5l1,39.2l2,26.9v-51.9
	L260.3,300.5L260.3,300.5z M267.4,290.3l-7,10.3l1.7,12l7,52.1l0.5,1.8c4.7-4.8,16.1-7.5,34.3-7.6c5.5-0.5,9.1-2.3,10.8-5.2
	c6.3-10.1,12.5-18.5,18.1-24.5c4.7-5.2,10.3-7.8,17.3-7.8h142.4c7,0.2,12.6,2.8,17.3,7.8c5.7,6.2,11.6,14.3,18.1,24.5
	c1.8,2.8,5.3,4.7,10.8,5.2c18.1,0.3,29.4,2.8,34.3,7.6l0.5-1.8l7-52.1l1.7-12l-7-10.3c-1-2.2-4.2-3.3-10-3.5l0.2,16.5l-2.8,54.4
	l2.8-54.4l-0.2-16.5l-8.1,0.3l-54.2,6.7l-1.2,3c-0.8,2.3-3.3,3.5-7.5,3.5c-15.5,0.5-30.8,0.8-45.9,0.8l-26.3,0.2
	c-24,0-48.4-0.3-73.2-1c-4.2,0-6.5-1.2-7.5-3.5l-1.2-3l-54.2-6.7l-8.1-0.3l-0.2,9.3v7.1c0.2,4.5,0.3,8.8,0.5,13.3l2.3,41.1
	l-2.3-41.1c-0.3-4.5-0.3-9-0.5-13.3v-7.1l0.2-9.3C271.6,286.8,268.3,288,267.4,290.3L267.4,290.3z M558,316.3c2.7-0.5,3.8,0.5,3.5,3
	l-1,36.1c0,1.3-1.5,2-4.5,1.7c-3.3,0.2-5.3-1.7-6-5.2l-0.8-27.3C549.7,319.8,552.7,316.9,558,316.3L558,316.3z M280.2,319.3
	c-0.3-2.5,0.8-3.5,3.5-3c5.5,0.7,8.3,3.5,8.6,8.3l-0.7,27.3c-0.7,3.5-2.7,5.3-6,5.2c-3,0.2-4.5-0.3-4.5-1.7L280.2,319.3L280.2,319.3
	z"
          />
          <path
            fill="none"
            d="M561.5,319.3c0.3-2.5-0.8-3.5-3.5-3c-5.5,0.7-8.3,3.5-8.6,8.3l0.7,27.3c0.7,3.5,2.7,5.3,6,5.2
	c3,0.2,4.5-0.3,4.5-1.7L561.5,319.3L561.5,319.3z M554.5,324.1c0.2-0.3,0.5-0.3,1-0.3l2.3,0.2c0.8,0,1.3,0.5,1.3,1.5v27.9
	c0,0.8-0.3,1.3-1.3,1.3l-2.3,0.2c-0.5,0-0.8,0-1-0.3c-0.3-0.2-0.3-0.5-0.3-1l-0.2-28.3C554.2,324.6,554.3,324.3,554.5,324.1
	L554.5,324.1z"
          />
          <path
            fill="none"
            d="M555.5,323.8c-0.5,0-0.8,0-1,0.3c-0.3,0.2-0.3,0.5-0.3,1l0.2,28.3c0,0.5,0.2,0.8,0.3,1c0.2,0.3,0.5,0.3,1,0.3
	l2.3-0.2c0.8,0,1.3-0.5,1.3-1.3v-27.9c0-1-0.5-1.5-1.3-1.5L555.5,323.8L555.5,323.8z M557.8,324.6c0.3,0,0.7,0.3,0.7,0.7v27.9
	c0,0.3-0.2,0.7-0.5,0.7l-2.2,0.2c-0.2,0-0.3,0-0.5-0.2l-0.2-0.5l-0.2-28.3c0-0.3,0-0.5,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2L557.8,324.6
	L557.8,324.6z"
          />
          <path
            fill="none"
            d="M558.5,325.2c0-0.5-0.2-0.7-0.7-0.7l-2.3-0.2c-0.2,0-0.3,0-0.5,0.2s-0.2,0.2-0.2,0.5l0.2,28.3l0.2,0.5
	c0.2,0.2,0.3,0.2,0.5,0.2l2.2-0.2c0.3,0,0.5-0.2,0.5-0.7v-27.9H558.5z M557.3,338.4c0.2,0.2,0.2,0.3,0.2,0.5s0,0.3-0.2,0.5
	c-0.2,0.2-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.2-0.5-0.2c-0.2-0.2-0.2-0.3-0.2-0.5s0-0.3,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2
	C557,338.2,557.2,338.4,557.3,338.4z"
          />
          <path
            fill="none"
            d="M557.5,339.1c0-0.2,0-0.3-0.2-0.5s-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.5,0.2s-0.2,0.3-0.2,0.5s0,0.5,0.2,0.5
	c0.2,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.5-0.2S557.5,339.2,557.5,339.1z M283.7,316.3c-2.7-0.5-3.8,0.5-3.5,3l1,36.1
	c0,1.3,1.5,2,4.5,1.7c3.3,0.2,5.3-1.7,6-5.2l0.7-27.3C292.1,319.8,289.1,316.9,283.7,316.3L283.7,316.3z M282.7,325.2
	c0-1,0.5-1.5,1.3-1.5l2.3-0.2c0.5,0,0.8,0,1,0.3c0.2,0.2,0.3,0.7,0.3,1l-0.2,28.3c0,0.5-0.2,0.8-0.3,1c-0.2,0.3-0.5,0.3-1,0.3
	l-2.3-0.2c-0.8,0-1.2-0.5-1.2-1.3L282.7,325.2L282.7,325.2z"
          />
          <path
            fill="none"
            d="M283.9,323.8c-0.8,0-1.3,0.5-1.3,1.5v27.9c0,0.8,0.3,1.3,1.2,1.3l2.3,0.2c0.5,0,0.8-0.2,1-0.3
	c0.2-0.2,0.3-0.5,0.3-1l0.2-28.3c0-0.5-0.2-0.8-0.3-1c-0.2-0.3-0.7-0.3-1-0.3L283.9,323.8L283.9,323.8z M286.4,324.4
	c0.2,0,0.3,0,0.5,0.2l0.2,0.5l-0.2,28.3l-0.2,0.5l-0.5,0.2l-2.2-0.2c-0.3,0-0.5-0.2-0.5-0.7v-27.9c0-0.5,0.2-0.7,0.7-0.7
	L286.4,324.4L286.4,324.4z"
          />
          <path
            fill="none"
            d="M286.9,324.6c-0.2-0.2-0.3-0.2-0.5-0.2l-2.3,0.2c-0.3,0-0.7,0.3-0.7,0.7v27.9c0,0.3,0.2,0.7,0.5,0.7l2.2,0.2
	l0.5-0.2l0.2-0.5l0.2-28.3L286.9,324.6L286.9,324.6z M284.6,339.6c-0.2-0.2-0.2-0.3-0.2-0.5s0-0.3,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2
	s0.3,0,0.5,0.2s0.2,0.3,0.2,0.5s0,0.5-0.2,0.5s-0.3,0.2-0.5,0.2S284.6,339.7,284.6,339.6L284.6,339.6z"
          />
          <path
            fill="none"
            d="M284.2,339.1c0,0.2,0,0.5,0.2,0.5c0.2,0,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.5-0.2c0.2-0.2,0.2-0.3,0.2-0.5
	s0-0.3-0.2-0.5c-0.2-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.5,0.2C284.4,338.6,284.2,338.7,284.2,339.1z M313.2,252.9
	c2.7,2.7,3.8,5.7,3.8,9.3c0,3.7-1.3,6.7-3.8,9.3c-2.5,2.5-5.5,3.8-9,3.8l26.9,3.8c-2.3-2.2-5.8-4.3-10.5-6.7c-2-1-3.2-2.7-3.3-4.7
	l-0.7-17.5h-7.5C310.7,250.9,312,251.7,313.2,252.9L313.2,252.9z"
          />
          <path
            fill="none"
            d="M304,275.4h0.3l-4.3-0.7C301.2,275.2,302.5,275.4,304,275.4L304,275.4z M515.1,173.6l-4.2-0.3v1.7l4.2,0.3
	V173.6L515.1,173.6z"
          />
          <path
            fill="none"
            d="M510.9,173.2l4.2,0.3l0.2-2.2l-0.7,0.5l-2-0.2c-0.2-0.3-0.5-0.5-1-0.8C511.3,171.4,510.9,172.1,510.9,173.2
	L510.9,173.2z"
          />
          <path
            fill="none"
            d="M515.2,171.4l-0.2,2.2v1.5c-0.2,12.8,1.7,30.6,5.3,53.2c1.2,2.7,2.3,4.8,3.8,6.3c18.3,2.5,32.6,6.2,42.7,11.1
	l0.3,0.2l-4-7l-0.8-1.7l-11.8-21l0.5,15.6l0.7,2.2l0.5,4.5c-2.7-1-4.3-1.8-5.2-2.7l0.8-4.3l0.5-15.5c0-0.5,0.2-0.8,0.5-0.8h0.3v-1.2
	l-10.8-19.3l-0.2-0.2h-0.2l-4.2-9l-0.2-0.3c-1.3-3.7-3.8-7-7.3-10C524.6,173.4,520.7,172.1,515.2,171.4L515.2,171.4z M524.1,248.1
	v1.3h11.5l13.3,0.7c3.8,0.3,6,1.8,6.7,4.3v16l1,0.7v-16.1c-0.2-3.8-2.3-6-6.5-6.5C543,247.7,534.4,247.7,524.1,248.1L524.1,248.1z"
          />
          <path
            fill="none"
            d="M524.2,234.8c0,4.2,0,8.5-0.2,13.3c10.3-0.3,19-0.3,25.9,0.3c4.2,0.5,6.3,2.7,6.5,6.5v32.3l8.2-0.3l-0.8-34.8
	c-0.2-5.2,0.8-7.3,3.3-6.2C556.8,240.8,542.7,237.1,524.2,234.8L524.2,234.8z"
          />
          <path
            fill="none"
            d="M523.1,267.2c0,2.2-1.7,4.2-4.7,5.8c-6.2,2.8-9.8,5.8-11.1,8.6l-5,12l54.2-6.7v-16l-1-0.7
	c-1.5-0.3-2.7-0.3-3.3,0.2c-0.5,0.3-0.7,0.7-0.8,1c-0.5,1.2-1.7,2-3.8,2.5l-5.5,0.7c-1.3,0.3-2.7,0.7-4,0.7h-0.3l-26.9,3.8
	c2.3-2.2,5.8-4.3,10.5-6.7c2-1,3.2-2.7,3.3-4.7l0.7-17.5h9.3l8.6,0.5l1.8,0.2h0.3c3.3,0.7,5.3,1.8,6,3.3l1,0.2l3.5,0.3v-0.5
	c-0.7-2.5-3-4-6.7-4.3l-13.3-0.7h-11.5C523.9,254.9,523.6,260.9,523.1,267.2L523.1,267.2z M508.9,281.5l0.2-0.2l45.9-6.5h0.2
	l0.2,0.2v0.2l-0.2,10.3l-0.2,0.2l-0.2,0.2l-49.4,6.3h-0.3l-0.2-0.2v-0.2L508.9,281.5L508.9,281.5z"
          />
          <path
            fill="none"
            d="M509.1,281.5l-0.2,0.2l-0.2,0.2l-3.7,10v0.2l0.2,0.2h0.3l49.4-6.3l0.2-0.2l0.2-0.2l0.2-10.3V275l-0.2-0.2h-0.2
	L509.1,281.5L509.1,281.5z M505.9,291.5l3.5-9.3l20.8-3l24.3-3.5l-0.2,9.5l-24.3,3.2L505.9,291.5L505.9,291.5z"
          />
          <path
            fill="none"
            d="M509.4,282.2l-3.5,9.3l24.3-3.2l0.2-9.1L509.4,282.2L509.4,282.2z M519.4,286.5c-0.5,0-0.8-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
	c0,0.3-0.2,0.8-0.5,1.2S519.7,286.5,519.4,286.5L519.4,286.5z"
          />
          <path
            fill="none"
            d="M518.2,286c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.8-0.2,1.2-0.5s0.5-0.7,0.5-1.2s-0.2-0.8-0.5-1.2
	c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2S517.9,285.7,518.2,286L518.2,286z M530.2,279.2
	l-0.2,9.1l24.3-3.2l0.2-9.5L530.2,279.2L530.2,279.2z M539.7,280.7c0.3-0.3,0.7-0.5,1.2-0.5c0.3,0,0.8,0.2,1.2,0.5
	c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2
	C539.4,281.3,539.5,281,539.7,280.7L539.7,280.7z"
          />
          <path
            fill="none"
            d="M540.9,280.2c-0.5,0-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
	c0.3,0,0.8-0.2,1.2-0.5s0.5-0.7,0.5-1.2c0-0.5-0.2-0.8-0.5-1.2C541.7,280.3,541.4,280.2,540.9,280.2L540.9,280.2z M525.1,250.2
	l-0.7,17.5c-0.2,2.2-1.3,3.7-3.3,4.7c-4.7,2.3-8.1,4.5-10.5,6.7l26.9-3.8c-3.5,0-6.5-1.3-9-3.8c-2.7-2.5-3.8-5.7-3.8-9.3
	c0-3.7,1.3-6.7,3.8-9.3c1.2-1.2,2.5-2,3.8-2.7L525.1,250.2L525.1,250.2z M549.7,165.4l-0.3,0.2v49.7H549c-0.3,0.2-0.7,0.3-0.5,0.8
	l-0.5,15.5l-0.8,4.3c0.8,1,2.5,1.8,5.2,2.7l-0.5-4.5l-0.7-2.2l-0.5-15.6v-0.2c0-0.5-0.2-0.8-0.5-0.8h-0.3v-44.4l0.2-0.3v-4.8
	l-0.2-0.3L549.7,165.4L549.7,165.4L549.7,165.4z M602.7,222.1c-3.8-0.7-9.3-1-16.6-0.8h-3.2c-6.7,0.2-10.6,1.8-11.8,4.8
	c-0.5,4-0.3,7.8,0.7,11.3c1.7,3.5,4.8,5.7,9.5,6.7l6.5,0.3l15.3-1.7l2.3-0.5c1.7-0.5,2.8-1.3,3.8-2.7h0.2l0.2-0.2
	c0.2-0.5,0.3-1.2,0.5-1.7l0.2-2.7l-0.3-5.3l-0.2-0.3v-0.2C608.5,225,606.2,222.6,602.7,222.1L602.7,222.1z M583.6,222.6h2.8
	c6.7-0.2,11.6,0.2,15,0.7h0.2c3.2,0.5,5.3,2.5,6.5,6.3v0.3l0.3,4.8l-0.3,2.8l-0.3,1.3l-0.3,0.2l-1.2,1.3l-0.8,0.7
	c-1,0.5-2.2,0.8-3.7,1l-13.8,1.5c-7.5,0.2-12.3-1.8-14.3-6.3c-0.8-3.2-1.2-6.7-0.7-10.1C574.1,224.1,577.6,222.8,583.6,222.6
	L583.6,222.6z"
          />
          <path
            fill="none"
            d="M586.4,222.6h-2.8c-6,0.2-9.5,1.7-10.5,4.3c-0.5,3.7-0.2,7,0.7,10.1c2.2,4.5,6.8,6.5,14.3,6.3l13.8-1.5
	c1.5-0.2,2.7-0.5,3.7-1l0.8-0.7l1.2-1.3h0.3l0.3-1.3l0.3-2.8l-0.3-4.8v-0.3c-1.2-3.7-3.3-5.8-6.5-6.3h-0.2
	C598.1,222.6,593.1,222.5,586.4,222.6L586.4,222.6z"
          />
          <path
            fill="none"
            d="M587.8,244.8l-6.5-0.5c-4.7-1-7.8-3.2-9.5-6.7c-1-3.5-1.3-7.3-0.8-11.3l-3.8,9.3l-0.7,0.8
	c-0.8,0.5-1.8,0.8-3.5,1h-0.2l-0.5,0.2l0.8,1.7c1.3,1.2,3.3,1.5,5.8,1.2c0.8-0.2,1.8,0,2.7,0.3c3.5,2.7,6.7,4.2,9.5,4.7l6.2-0.5
	L587.8,244.8L587.8,244.8z M610,229.6l0.3,5.3c1-0.3,1.5-1.2,1.5-2.2v-1.7C611.7,230.1,611,229.6,610,229.6L610,229.6z M550,215.3
	l-0.2-0.3v0.3"
          />
          <path
            fill="none"
            d="M571.8,240.6c-0.8-0.5-1.8-0.7-2.7-0.3c-2.7,0.3-4.7-0.2-5.8-1.2l4,7l2.5-0.3l11.5-0.3
	C578.4,244.8,575.3,243.3,571.8,240.6L571.8,240.6z"
          />
          <path
            fill="none"
            d="M567.3,245.9l-0.3-0.2c-2.3-1.2-3.5,1-3.3,6.2l0.7,34.8c5.7,0.2,9,1.3,10,3.5l7,10.3c0.8-7.3,1.2-8.1,1.3-2.5
	l-1,39.2l0.2-2.7c0.8-10.3,1.3-19.5,1.7-27.8v-0.2l-0.2-0.2h-0.2c0.3-8,0.5-15,0.5-21c-2.7-3-4.7-6.7-6.2-11v-0.2l-2.7-6.3
	c-1.7-3.7-3-8.1-3.8-13.3C570.3,249.9,568.8,246.9,567.3,245.9L567.3,245.9z M555.5,254.9l-3.5-0.3l0.2,16c0.7-0.5,1.8-0.5,3.3-0.2
	V254.9z M552,254.6l-1-0.2c-0.7-1.7-2.5-2.7-6-3.3h-0.3c0.8,0.5,1.7,1.2,2.5,2c2.5,2.7,3.8,5.7,3.8,9.3c0,3.7-1.3,6.7-3.8,9.3
	c-1.5,1.5-3.3,2.7-5.2,3.3l5.5-0.8c2.2-0.5,3.3-1.3,3.8-2.5c0.2-0.5,0.3-0.8,0.8-1L552,254.6L552,254.6z"
          />
          <path
            fill="none"
            d="M547.2,252.9c-0.8-0.8-1.7-1.3-2.5-2l-1.8-0.2c1.3,0.7,2.7,1.5,3.8,2.7c2.5,2.5,3.7,5.5,3.7,8.8
	c0,3.5-1.2,6.3-3.7,8.8s-5.3,3.7-8.8,3.7s-6.3-1.2-8.8-3.7s-3.7-5.3-3.7-8.8s1.2-6.3,3.7-8.8c1.5-1.5,3.2-2.5,5.2-3.2h-1.8
	c-1.3,0.7-2.7,1.5-3.8,2.7c-2.7,2.7-3.8,5.7-3.8,9.3c0,3.7,1.3,6.7,3.8,9.3c2.5,2.5,5.5,3.8,9,3.8l4.3-0.7c2-0.7,3.7-1.7,5.2-3.3
	c2.5-2.5,3.8-5.7,3.8-9.3C551,258.6,549.7,255.6,547.2,252.9L547.2,252.9z"
          />
          <path
            fill="none"
            d="M546.7,253.4c-1.2-1.2-2.5-2-3.8-2.7l-8.6-0.5c-2,0.5-3.7,1.7-5.2,3.2c-2.5,2.5-3.7,5.5-3.7,8.8
	c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7s6.3-1.2,8.8-3.7s3.7-5.3,3.7-8.8C550.3,258.9,549.2,255.9,546.7,253.4L546.7,253.4z
	 M544,256.1c1.7,1.7,2.5,3.8,2.5,6.2c0,2.5-0.8,4.5-2.5,6.2c-1.7,1.7-3.8,2.7-6.2,2.7c-2.5,0-4.5-0.8-6.2-2.7
	c-1.7-1.7-2.5-3.7-2.5-6.2s0.8-4.5,2.5-6.2c1.7-1.7,3.8-2.5,6.2-2.5C540.4,253.6,542.4,254.4,544,256.1L544,256.1z"
          />
          <path
            fill="none"
            d="M546.7,262.2c0-2.5-0.8-4.5-2.5-6.2c-1.7-1.7-3.8-2.5-6.2-2.5c-2.5,0-4.5,0.8-6.2,2.5c-1.7,1.7-2.5,3.8-2.5,6.2
	c0,2.5,0.8,4.5,2.5,6.2c1.7,1.7,3.8,2.7,6.2,2.7s4.5-0.8,6.2-2.7C545.8,266.7,546.7,264.7,546.7,262.2L546.7,262.2z M543.5,256.6
	c1.5,1.5,2.3,3.5,2.3,5.7s-0.8,4-2.3,5.5c-1.5,1.5-3.5,2.3-5.7,2.3s-4-0.8-5.5-2.3c-1.5-1.5-2.3-3.3-2.3-5.5s0.8-4,2.3-5.7
	c1.5-1.5,3.3-2.3,5.5-2.3C540,254.4,542,255.1,543.5,256.6L543.5,256.6z"
          />
          <path
            fill="none"
            d="M545.8,262.2c0-2.2-0.8-4-2.3-5.7c-1.5-1.5-3.5-2.3-5.7-2.3s-4,0.8-5.5,2.3c-1.5,1.5-2.3,3.5-2.3,5.7
	s0.8,4,2.3,5.5c1.5,1.5,3.3,2.3,5.5,2.3s4-0.8,5.7-2.3C545,266.2,545.8,264.4,545.8,262.2L545.8,262.2z M539.4,255.4
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.5-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.7,1.3-0.7C538.5,254.7,539,254.9,539.4,255.4L539.4,255.4z M541.7,259.2c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	s0.7,0.8,0.7,1.5c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3
	C541,260.2,541.2,259.7,541.7,259.2L541.7,259.2z M539.9,262.2c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7
	c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.5,0.5C539.7,261.2,539.9,261.7,539.9,262.2L539.9,262.2z
	 M531.7,261.6c-0.3-0.3-0.7-0.8-0.7-1.3s0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.7,0.8,0.7,1.5
	c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.3,0.7C532.5,262.2,532.2,262,531.7,261.6L531.7,261.6z M534.5,264c0.5,0,1,0.2,1.5,0.5
	c0.3,0.5,0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.5-0.3-0.7-0.8-0.7-1.3
	s0.2-1,0.7-1.5C533.5,264.2,534,264,534.5,264L534.5,264z M539.5,264.7c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.5,0.5
	c0.3,0.5,0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3
	S539,265,539.5,264.7L539.5,264.7z"
          />
          <path
            fill="none"
            d="M539.9,256.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.7-1.5-0.7c-0.5,0-1,0.2-1.3,0.7c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.5-0.7C539.7,257.7,539.9,257.2,539.9,256.7L539.9,256.7z M543,258.7
	c-0.5,0-1,0.2-1.3,0.5c-0.5,0.3-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.3-0.7
	c0.3-0.3,0.5-0.8,0.5-1.3c0-0.5-0.2-1-0.5-1.5C544,258.9,543.5,258.7,543,258.7L543,258.7z M539.4,263.5c0.3-0.3,0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.5-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.5-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7C538.5,264.2,539,263.9,539.4,263.5L539.4,263.5z M531.2,260.2c0,0.5,0.2,1,0.7,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.5c-0.3-0.3-0.8-0.5-1.3-0.5
	c-0.5,0-1,0.2-1.3,0.5C531.4,259.2,531.2,259.7,531.2,260.2L531.2,260.2z M536,264.7c-0.3-0.3-0.8-0.5-1.5-0.5c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.5-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.3,0.7c0.5,0,1-0.2,1.5-0.7c0.3-0.3,0.7-0.8,0.7-1.3
	S536.4,265,536,264.7L536,264.7z M540.9,264c-0.5,0-1,0.2-1.3,0.5c-0.3,0.5-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3
	c0.3,0.3,0.8,0.7,1.3,0.7c0.5,0,1-0.2,1.5-0.7c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.5C541.9,264.2,541.5,264,540.9,264L540.9,264z
	 M537.7,275.4h0.3c1.5,0,2.8-0.2,4-0.7L537.7,275.4L537.7,275.4z M577.9,270.7c0-1.3-0.8-2.2-2.5-2.5l2.7,6.3
	C578.1,273.4,578.1,272,577.9,270.7L577.9,270.7z M582.9,298c-0.2-5.7-0.7-4.8-1.3,2.5l-1.7,12v51.9l2-26.9L582.9,298L582.9,298z
	 M538.4,358.7c-5.5-0.5-9.1-2.3-10.8-5.2c-6.3-10.1-12.5-18.5-18.1-24.5c-4.7-5.2-10.3-7.8-17.3-7.8H349.8c-7,0.2-12.6,2.8-17.3,7.8
	c-5.7,6.2-11.6,14.3-18.1,24.5c-1.8,2.8-5.3,4.7-10.8,5.2c-18.1,0.3-29.4,2.8-34.3,7.6l1.3,5.2c2,5,4.5,7.2,7.6,6.5
	c-0.3-1.3,1.8-2.7,6-4c6.8-1.7,14-2.5,21.5-2.8c2.2-0.2,4.2-0.2,6.3-0.2l0.3-1.2l3.8-11.5c5.8-10.3,11.6-19,17.8-26.1
	c4.3-5.5,10.1-8.1,17.6-8h139c7.5-0.3,13.3,2.3,17.6,8c6.2,7.2,12,15.8,17.8,26.1l3.8,11.5l0.3,1.2c2.2,0,4.2,0,6.3,0.2
	c7.5,0.3,14.5,1.2,21.5,2.8c4.2,1.3,6.2,2.7,6,4c3.2,0.7,5.7-1.5,7.7-6.5l1.3-5.2C567.8,361.5,556.5,358.8,538.4,358.7L538.4,358.7z
	"
          />
          <path
            fill="none"
            d="M563.6,378c0.3-1.3-1.8-2.7-6-4c-6.8-1.7-14-2.5-21.3-2.8V419c0.3,3.7,3.8,5.8,10.5,6.7h0.3v0.2l0.2,0.2
	l8.3,0.3l12.5-0.3l0.2-0.2v-0.2l1-0.2c6.7-0.8,10.1-3,10.5-6.7V312.3l-7,52.2l-0.5,1.8l-1.3,5.2C569.3,376.5,566.8,378.6,563.6,378
	L563.6,378z M530,371l-0.3-1.2h-16.1l1.7,10.5c0.2,1,1.5,1.5,4,1.5h17.1v-10.6C534.2,371,532.2,371,530,371L530,371z"
          />
          <path
            fill="none"
            d="M536.4,384.6v-2.8h-17.1c-2.5,0.2-3.8-0.3-4-1.5l-1.7-10.5h-27.9c-7,2.2-15.8,3.3-26.3,3.3
	c-2.3,0-3.8,0.3-4.3,0.8l-9.6,7c-1.3,1-2.8,1.5-4.3,1.7h-51.9l-2.3-0.3l-17-4.7c-2.8-0.8-4.3-1.8-4.7-2.7h-30.6
	c-1.3,0.8-2.7,1.3-4.3,1.3c-1,0-2-0.2-2.8-0.5l-0.7,4.3c-0.2,1-1.5,1.5-4,1.5h-17.1v2.8h230.7V384.6L536.4,384.6z M507.9,332.4
	c-4.3-5.5-10.1-8.1-17.6-8h-139c-7.5-0.3-13.3,2.3-17.6,8c-6.2,7.2-12,15.8-17.8,26.1L312,370h4.3c0-0.8,0-1.5,0.2-2.2
	c0.3-3.2,1.7-5.8,3.8-8.1c0.5-0.5,0.8-0.8,1.3-1.3c2.3-2,5.2-3,8.3-3c2.8,0,5.3,0.8,7.5,2.5c19.3-0.5,38.3-0.7,57-0.5h52.4
	c25.8-0.3,52.1,0,78.7,1.2C520.1,348.2,514.1,339.6,507.9,332.4L507.9,332.4z M489.8,331.9h12.5c0.7,0.2,0.8,0.5,0.7,0.8H339
	c-0.2-0.3,0.2-0.5,0.7-0.8h12.5c0.2-0.5,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h133.4c0.7,0.2,1.3,0.3,1.8,0.5
	C489.6,331.1,489.8,331.4,489.8,331.9L489.8,331.9z M493.5,339.4c0.3,0.2,0.3,0.5,0.5,1h14.3c0.7,0.3,0.8,0.5,0.7,0.8H333
	c-0.2-0.3,0.2-0.5,0.7-0.8h14.3c0-0.3,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h141.9C492.3,338.9,493,339.1,493.5,339.4L493.5,339.4z
	 M497,347.7c0.7,0.2,1.3,0.3,1.8,0.5c0.3,0.3,0.3,0.5,0.5,1h15.6c0.7,0.3,0.8,0.5,0.7,0.8H326.5c-0.2-0.3,0.2-0.5,0.7-0.8h15.6
	c0-0.5,0.2-0.7,0.5-1c0.5-0.2,1.2-0.5,1.8-0.5H497L497,347.7z"
          />
          <path
            fill="none"
            d="M502.3,331.9h-12.5c0-0.5-0.2-0.7-0.5-1c-0.5-0.3-1-0.5-1.8-0.5h-12.6l-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7
	s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.7-0.8h-19.6c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.5-0.2-2.2-0.7
	c-0.3-0.2-0.7-0.5-0.8-0.8h-20c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.7-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.7-0.8
	H398c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.8-0.8h-19.6c-0.2,0.3-0.3,0.7-0.8,0.8
	c-0.7,0.5-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.7-0.8h-12.6c-0.7,0.2-1.3,0.3-1.8,0.5c-0.3,0.3-0.3,0.5-0.5,1h-12.5
	c-0.7,0.2-0.8,0.5-0.7,0.8h164C503.1,332.4,502.8,332.1,502.3,331.9L502.3,331.9z"
          />
          <path
            fill="none"
            d="M474.2,331.1l0.7-0.8H469c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7C473,331.7,473.7,331.6,474.2,331.1
	L474.2,331.1z M448.7,331.1c0.3-0.3,0.7-0.5,0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7
	C447.4,331.7,448.2,331.6,448.7,331.1L448.7,331.1z M422.9,331.1c0.3-0.3,0.7-0.5,0.8-0.8H418c0.2,0.3,0.3,0.7,0.7,0.8
	c0.7,0.5,1.3,0.7,2.2,0.7C421.8,331.7,422.4,331.6,422.9,331.1L422.9,331.1z M397.3,331.1c0.3-0.3,0.7-0.5,0.7-0.8h-5.8
	c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7C396.2,331.7,396.8,331.6,397.3,331.1L397.3,331.1z M371.9,331.1
	c0.3-0.3,0.7-0.5,0.8-0.8h-5.8c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7S371.4,331.6,371.9,331.1L371.9,331.1z M494,340.4
	c-0.2-0.3-0.2-0.7-0.5-1c-0.5-0.3-1-0.5-1.8-0.5H477c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7
	c-0.3-0.3-0.7-0.5-0.7-0.8h-20.5c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.5-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.7-0.8
	h-21.3c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.3-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.7-0.8H397l-0.7,0.8
	c-0.5,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.5-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.8-0.8h-20.5l-0.7,0.8c-0.5,0.5-1.3,0.7-2.2,0.7
	s-1.5-0.2-2.2-0.7c-0.3-0.3-0.7-0.5-0.8-0.8h-14.6c-0.7,0.2-1.3,0.3-1.8,0.5c-0.3,0.2-0.3,0.5-0.5,1h-14.3c-0.7,0.3-0.8,0.5-0.7,0.8
	h175.8c0.2-0.3-0.2-0.5-0.7-0.8H494L494,340.4z"
          />
          <path
            fill="none"
            d="M450,339.7c0.3-0.2,0.7-0.5,0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7S449.5,340,450,339.7
	L450,339.7z M476.3,339.7c0.3-0.2,0.7-0.5,0.8-0.8h-5.8c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7S475.7,340,476.3,339.7
	L476.3,339.7z M396,339.7l0.7-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7C394.8,340.4,395.5,340,396,339.7L396,339.7
	z M422.9,339.7c0.3-0.3,0.7-0.5,0.8-0.8H418c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7C421.8,340.4,422.4,340,422.9,339.7
	L422.9,339.7z M365.6,339.7c0.7,0.5,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7l0.7-0.8h-5.8C364.9,339.1,365.2,339.4,365.6,339.7L365.6,339.7
	z M498.6,348.4c-0.5-0.2-1-0.5-1.8-0.5H479c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.3-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7
	c-0.3-0.2-0.7-0.5-0.8-0.8h-22c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.3-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7c-0.3-0.2-0.5-0.5-0.7-0.8h-21.8
	l-0.8,0.8c-0.5,0.3-1.3,0.7-2.2,0.7s-1.7-0.2-2.2-0.7l-0.7-0.8H396c-0.2,0.3-0.3,0.7-0.8,0.8c-0.7,0.3-1.3,0.7-2.2,0.7
	s-1.5-0.2-2.2-0.7c-0.3-0.2-0.7-0.5-0.8-0.8h-22c-0.2,0.3-0.3,0.7-0.7,0.8c-0.7,0.3-1.3,0.7-2.2,0.7s-1.5-0.2-2.2-0.7
	c-0.3-0.2-0.7-0.5-0.8-0.8h-17.8c-0.7,0.2-1.3,0.3-1.8,0.5c-0.3,0.3-0.3,0.5-0.5,1h-15.6c-0.7,0.3-0.8,0.5-0.7,0.8h188.9
	c0.2-0.3-0.2-0.5-0.7-0.8h-15.6C499.1,348.9,499,348.5,498.6,348.4L498.6,348.4z"
          />
          <path
            fill="none"
            d="M445.6,347.7c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7c0.8,0,1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8
	H445.6L445.6,347.7z M474,348.5c0.5,0.3,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8h-5.8
	C473.5,348,473.7,348.4,474,348.5L474,348.5z M395.5,348.5c0.3-0.3,0.7-0.5,0.8-0.8h-5.8c0.2,0.3,0.3,0.7,0.8,0.8
	c0.7,0.3,1.3,0.7,2.2,0.7C394.2,349.2,395,349,395.5,348.5L395.5,348.5z M420.9,349.2c0.8,0,1.5-0.2,2.2-0.7l0.8-0.8h-5.8l0.7,0.8
	C419.3,349,419.9,349.2,420.9,349.2L420.9,349.2z M362.7,347.7c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.3,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7
	c0.3-0.2,0.7-0.5,0.7-0.8H362.7L362.7,347.7z M454.9,374.1c0.5-0.5,2-0.8,4.3-0.8c10.5-0.2,19.3-1.2,26.3-3.3h-30.6
	c-3.2-0.3-6.5,0.2-9.6,1.8c-4.7,2.2-9.6,3.5-14.8,3.7h-18.8c-5.2-0.3-10.1-1.5-14.8-3.7c-3.2-1.5-6.5-2.2-9.6-1.8h-22v5.3
	c0.3,1,1.8,1.8,4.7,2.7l17,4.7l2.3,0.3h51.9c1.5-0.2,3-0.8,4.3-1.7L454.9,374.1L454.9,374.1z"
          />
          <path
            fill="none"
            d="M445.1,371.6c3.2-1.5,6.5-2.2,9.6-1.8h30.6l0.7-0.2l-0.7,0.2h44.1l-3.8-11.5c-26.6-1.2-52.9-1.5-78.7-1.2h-52.4
	c-18.8-0.3-37.9-0.2-57,0.5c0.7,0.5,1.3,1.2,2,1.8c0.7,0.8,1.3,1.5,1.8,2.3c1.5,2.3,2.2,5,2.2,8h43.4c3.2-0.3,6.5,0.2,9.6,1.8
	c4.7,2.2,9.6,3.5,14.8,3.7h18.8C435.6,375.1,440.4,374,445.1,371.6L445.1,371.6z M436.9,359.8c1.2,0.3,1.7,0.8,2,1.5
	c0.3,0.7,0.2,1.5-0.3,2.3c-1.7,3.3-3.7,6-6.2,8c-1.5,0.7-3.8,1-7.2,0.8h-8.5c-3.3,0.2-5.8-0.2-7.2-0.8c-2.5-1.8-4.5-4.5-6.2-8
	c-0.5-1-0.5-1.8-0.3-2.3c0.3-0.7,0.8-1.2,1.8-1.5h0.2c5.8-0.3,11.3-0.5,16.5-0.3h0.3C426.6,359.3,431.6,359.5,436.9,359.8
	L436.9,359.8z"
          />
          <path
            fill="none"
            d="M436.9,359.8c-5.3-0.3-10.5-0.3-15.3-0.3h-0.3c-5.2-0.2-10.6,0-16.5,0.3h-0.2c-1,0.3-1.7,0.8-1.8,1.5
	c-0.3,0.7-0.2,1.5,0.3,2.3c1.7,3.3,3.7,6,6.2,8c1.5,0.7,3.8,1,7.1,0.8h8.5c3.3,0.2,5.8-0.2,7.1-0.8c2.5-1.8,4.5-4.5,6.2-8
	c0.5-1,0.7-1.8,0.3-2.3C438.6,360.5,437.9,360.2,436.9,359.8L436.9,359.8z M438.2,361.5c0.2,0.5,0,1-0.3,1.8c-1.7,3.3-3.7,5.8-6,7.6
	c-1.3,0.5-3.7,0.8-6.8,0.7h-8.5c-3.2,0.2-5.5-0.2-6.8-0.7c-2.3-1.8-4.3-4.3-6-7.6c-0.3-0.8-0.3-1.3-0.3-1.8c0.2-0.3,0.7-0.8,1.3-1
	c5.7-0.3,11.1-0.3,16.3-0.3h0.2c4.8,0,10,0,15.1,0.3C437.6,360.7,438.1,361.2,438.2,361.5L438.2,361.5z"
          />
          <path
            fill="none"
            d="M437.9,363.3c0.3-0.8,0.3-1.3,0.3-1.8c-0.2-0.3-0.7-0.8-1.3-1c-5.3-0.3-10.3-0.3-15.1-0.3h-0.2
	c-5.2-0.2-10.6,0-16.3,0.3c-0.7,0.2-1.2,0.5-1.3,1c-0.2,0.5-0.2,1,0.3,1.8c1.7,3.3,3.7,5.8,6,7.6c1.3,0.5,3.7,0.8,6.8,0.7h8.5
	c3.2,0.2,5.5-0.2,6.8-0.7C434.3,369.2,436.2,366.5,437.9,363.3L437.9,363.3z M421.8,366.2c0,0.3-0.2,0.5-0.3,0.7
	c-0.2,0.2-0.5,0.3-0.7,0.3c-0.2,0-0.5-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	s0.5,0,0.7,0.3C421.8,365.7,421.8,366,421.8,366.2L421.8,366.2z"
          />
          <path
            fill="none"
            d="M421.4,367c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.7-0.3c-0.2,0-0.5,0.2-0.8,0.3
	c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3C421.1,367.3,421.3,367.2,421.4,367z M365.1,375.3V370
	h-21.5c0-3-0.7-5.7-2.2-8h-8c0.8,0.3,1.5,0.8,2.2,1.5c1.5,1.5,2.3,3.3,2.3,5.5v1c-0.3,1.7-1,3.2-2.2,4.3l-1.2,1L365.1,375.3
	L365.1,375.3z"
          />
          <path
            fill="none"
            d="M321.8,358.3c-0.5,0.3-0.8,0.8-1.3,1.3c-2.2,2.3-3.5,5-3.8,8.1l6-0.8c0.3-1.3,1-2.5,2-3.5
	c1.5-1.5,3.3-2.3,5.5-2.3c1.2,0,2.3,0.2,3.3,0.8h8c-0.5-0.8-1.2-1.7-1.8-2.3c-0.7-0.7-1.3-1.3-2-1.8c-2.2-1.7-4.7-2.5-7.5-2.5
	C327,355.4,324.2,356.4,321.8,358.3L321.8,358.3z M269.3,366.3l-0.5-1.8l-7-52.1V419c0.3,3.7,3.8,5.8,10.5,6.7l1,0.2l0.2,0.2
	l12.5,0.3l8.3-0.3h0.2v-0.2h0.3c6.7-0.8,10.1-3,10.5-6.7v-47.9c-7.5,0.2-14.5,1.2-21.5,2.8c-4.2,1.3-6.2,2.7-6,4
	c-3.2,0.7-5.7-1.5-7.6-6.5L269.3,366.3L269.3,366.3z"
          />
          <path
            fill="none"
            d="M337.8,368.8c0-2.2-0.8-4-2.3-5.5c-0.7-0.7-1.3-1.2-2.2-1.5c-1-0.5-2.2-0.8-3.3-0.8c-2.2,0-4,0.8-5.5,2.3
	c-1,1-1.7,2.2-2,3.5c-0.2,0.7-0.2,1.2-0.2,1.8v1c0.2,1.7,1,3.2,2.2,4.3c0.8,0.8,1.7,1.3,2.7,1.8c0.8,0.3,1.8,0.5,2.8,0.5
	c1.7,0,3-0.5,4.3-1.3l1.2-1c1.3-1.3,2-2.7,2.2-4.3L337.8,368.8L337.8,368.8z M336.3,368.8v1c-0.2,1.3-0.8,2.3-1.8,3.3
	c-1.2,1.2-2.7,1.8-4.3,1.8c-1,0-1.7-0.2-2.5-0.5c-0.7-0.3-1.3-0.8-1.8-1.3c-1-1-1.5-2-1.7-3.3l-0.2-1c0-1.7,0.7-3.2,1.8-4.3
	c1.2-1.2,2.7-1.8,4.3-1.8c1.7,0,3.2,0.7,4.3,1.8C335.6,365.8,336.3,367.2,336.3,368.8L336.3,368.8z"
          />
          <path
            fill="none"
            d="M336.3,370v-1c0-1.7-0.7-3.2-1.8-4.3c-1.2-1.2-2.7-1.8-4.3-1.8c-1.7,0-3.2,0.7-4.3,1.8
	c-1.2,1.2-1.8,2.7-1.8,4.3l0.2,1c0.2,1.3,0.8,2.3,1.7,3.3c0.5,0.5,1.2,1,1.8,1.3c0.8,0.3,1.5,0.5,2.5,0.5c1.7,0,3.2-0.7,4.3-1.8
	C335.5,372.3,336,371.2,336.3,370L336.3,370z"
          />
          <path
            fill="none"
            d="M326.6,380.5l0.7-4.3c-1-0.3-1.8-1-2.7-1.8c-1.3-1.3-2-2.7-2.2-4.3v-1c0-0.7,0.2-1.3,0.2-1.8l-6,0.8
	c-0.2,0.7-0.2,1.5-0.2,2.2h-4.3l-0.3,1.2c-2.2,0-4.2,0-6.3,0.2v10.6h17.1C325.1,382,326.5,381.5,326.6,380.5L326.6,380.5z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M412,241.9c-0.3,0.3-0.7,0.8-1,1.2c-0.3,0.5-0.5,1-0.7,1.5
	c-0.2,0.7-0.2,1.2-0.2,1.8c0.2,0.5,0.3,1,0.7,1.5c0.2,0.3,0.5,0.8,1,1.2c0.7,0.7,1.5,1.3,2.5,1.8c1,0.5,2,0.8,3.2,1.2l4,0.3
	c1.3,0,2.5-0.3,3.7-0.7c1-0.3,2-0.7,3-1.3l1.2-0.8l1-1.2c0.3-0.5,0.7-1,0.7-1.3c0.2-0.7,0.3-1.2,0.2-1.8c0-0.5-0.2-1-0.5-1.5
	c-0.2-0.5-0.5-0.8-0.8-1.2l-1.2-1c-0.5-0.3-0.8-0.5-1.3-0.8c-2.2-1-4.5-1.5-7.2-1.5l-2,0.2c-1.8,0.3-3.3,0.8-4.7,1.7L412,241.9
	L412,241.9z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M412.5,245.1h3.2v-0.3h-3.3c-0.2,0-0.3,0.2-0.3,0.3v0.7h3.2
	c0,0.2,0,0.3-0.2,0.3h-3c0,0.2,0,0.2,0.2,0.3h3l0.3-0.2l0.2-0.3v-0.5h-3.2V245.1L412.5,245.1z M412.1,244.4h5.8
	c0.2-0.8,0.5-1.7,0.8-2.5c0.3-0.7,0.8-1.3,1.3-2l0.2-0.2c-2,0-3.8,0.5-5.3,1.3c-0.7,0.3-1.3,0.8-1.8,1.3
	C412.8,243.1,412.5,243.6,412.1,244.4L412.1,244.4z M420.4,241.8c0,0.7,0.2,1.3,0.3,1.8l0.3,0.5l0.3,0.3h7.5c-0.5-1.5-1.5-2.5-3-3.3
	c-0.7-0.3-1.5-0.7-2.3-0.8l-3-0.3C420.4,240.4,420.4,241.1,420.4,241.8L420.4,241.8z M419.8,244.8h-3.3c-0.2,0-0.3,0.2-0.3,0.3v1
	c0,0.2,0.2,0.2,0.3,0.3h3.3v-0.3h-3.2v-1h3.2L419.8,244.8L419.8,244.8z M419.6,247.1c-0.2-0.2-0.3-0.3-0.5-0.3h-6.8
	c0.5,1.3,1.5,2.5,3,3.3c1.5,0.8,3.2,1.3,5.2,1.3c0.2-0.7,0.2-1.5,0.2-2.2c-0.2-0.8-0.3-1.5-0.5-1.8L419.6,247.1L419.6,247.1z
	 M425.3,244.8v1.5h0.7v-1.3h2.7v1.3c0.3,0,0.3,0,0.5-0.2v-1.2c0-0.2-0.2-0.2-0.3-0.2L425.3,244.8L425.3,244.8L425.3,244.8z
	 M424.8,245.9v-0.8l-0.2-0.3l-0.8-0.2h-2.5l-0.2,0.2c-0.2,0-0.2,0-0.2,0.2s-0.2,0.3-0.2,0.5c0,0.3,0,0.5,0.2,0.5l0.3,0.3h2.3
	l0.8-0.2L424.8,245.9L424.8,245.9z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M424.3,245.9h-2.7v-0.5l0.2-0.3h2.5v0.5L424.3,245.9z M420.1,244.8v1.5
	h0.7v-1.5L420.1,244.8z M421.8,249.4l-1.5,2.2l3-0.3c1-0.2,1.8-0.5,2.5-0.8c1.7-0.8,2.7-2,3-3.5h-6.1c0,0.5-0.2,1-0.3,1.3
	L421.8,249.4L421.8,249.4z M451.4,347.7h-5.8 M445.6,347.7c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7c0.8,0,1.5-0.2,2.2-0.7
	c0.3-0.2,0.7-0.5,0.7-0.8 M436.9,359.8c1,0.3,1.7,0.8,1.8,1.5c0.3,0.7,0.2,1.5-0.3,2.3c-1.7,3.3-3.7,6-6.2,8c-1.5,0.7-3.8,1-7.1,0.8
	h-8.5c-3.3,0.2-5.8-0.2-7.1-0.8c-2.5-1.8-4.5-4.5-6.2-8c-0.5-1-0.5-1.8-0.3-2.3c0.3-0.7,0.8-1.2,1.8-1.5c5.8-0.3,11.3-0.5,16.5-0.3
	h0.3C426.6,359.3,431.6,359.5,436.9,359.8L436.9,359.8z M445.1,338.9c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7
	s1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8 M443.7,330.2h5.8 M443.7,330.2c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7
	s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M469.2,330.2h5.8 M469.2,330.2c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7
	s1.7-0.2,2.2-0.7l0.7-0.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M362.7,347.7h-17.8c-0.7,0.2-1.3,0.3-1.8,0.5c-0.2,0.3-0.3,0.5-0.5,1H327
	c-0.7,0.3-0.8,0.5-0.7,0.8h188.9c0.2-0.3-0.2-0.5-0.7-0.8h-15.6c-0.2-0.5-0.2-0.7-0.5-1c-0.5-0.2-1-0.5-1.8-0.5h-17.8 M477,338.9
	h14.6c0.7,0,1.3,0.3,1.8,0.5c0.3,0.3,0.3,0.5,0.5,1h14.3c0.5,0.2,0.8,0.5,0.7,0.8H333c-0.2-0.3,0.2-0.5,0.7-0.8h14.3
	c0-0.5,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h14.6 M475,330.2h12.6c0.7,0.2,1.3,0.3,1.8,0.5c0.3,0.2,0.5,0.5,0.5,1h12.5
	c0.7,0.3,0.8,0.5,0.7,0.8h-164c-0.2-0.3,0.2-0.5,0.7-0.8h12.5c0.2-0.3,0.2-0.7,0.5-1c0.5-0.3,1-0.5,1.8-0.5h12.6 M557.3,338.4
	c-0.2-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.2-0.5,0.2c-0.2,0.2-0.2,0.3-0.2,0.5s0,0.3,0.2,0.5c0.2,0.2,0.3,0.2,0.5,0.2
	c0.2,0,0.3-0.2,0.5-0.2c0.2-0.2,0.2-0.3,0.2-0.5S557.5,338.6,557.3,338.4z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M557.8,324.6l-2.3-0.2c-0.2,0-0.3,0-0.5,0.2c0,0.2-0.2,0.3-0.2,0.5
	l0.2,28.3l0.2,0.5c0.2,0.2,0.3,0.2,0.5,0.2l2.2-0.2c0.3,0,0.5-0.2,0.5-0.7v-27.9C558.5,324.8,558.3,324.6,557.8,324.6L557.8,324.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M555.5,323.8l2.3,0.2c0.8,0,1.3,0.5,1.3,1.5v27.9c0,0.8-0.3,1.3-1.3,1.3
	l-2.3,0.2c-0.5,0-0.8,0-1-0.3c-0.3-0.2-0.3-0.5-0.3-1l-0.2-28.3c0-0.5,0.2-0.8,0.3-1C554.7,323.8,555,323.8,555.5,323.8L555.5,323.8
	z M450.9,338.9h-5.8 M471.3,338.9c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8
	 M477,338.9h-5.7 M473.3,347.7c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.3,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.7-0.8
	 M479.2,347.7h-5.8 M449.5,330.2h19.6 M450.9,338.9h20.5 M473.3,347.7h-22 M438.2,361.5c-0.2-0.3-0.7-0.8-1.3-1
	c-5.3-0.3-10.3-0.3-15.1-0.3h-0.2c-5.2-0.2-10.6,0-16.3,0.3c-0.7,0.2-1.2,0.5-1.3,1c-0.2,0.5-0.2,1,0.3,1.8c1.7,3.3,3.7,5.8,6,7.6
	c1.3,0.5,3.7,0.8,6.8,0.7h8.5c3.2,0.2,5.5-0.2,6.8-0.7c2.3-1.8,4.3-4.3,6-7.6C438.2,362.5,438.4,362,438.2,361.5L438.2,361.5z
	 M418,330.2c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7c0.8,0,1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M418,330.2h5.8
	 M392.3,330.2c0.2,0.3,0.3,0.7,0.8,0.8c0.5,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.7-0.8 M392.3,330.2h5.8
	 M391,338.9c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7l0.7-0.8 M390.5,347.7c0.2,0.3,0.3,0.7,0.8,0.8
	c0.7,0.3,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7c0.3-0.2,0.7-0.5,0.8-0.8 M418,338.9c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7
	c0.8,0,1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M423.8,338.9H418 M418,347.7l0.7,0.8c0.7,0.3,1.3,0.7,2.2,0.7
	c0.8,0,1.5-0.2,2.2-0.7l0.8-0.8 M423.8,347.7H418 M398.2,330.2H418 M396.8,338.9h21.1 M418,347.7h-21.6 M483.5,227.8
	c4.5-0.2,9-1.7,13.6-4.7c3.5-2.8,5.3-6.3,5.7-10.3l-3.2-28.8c-0.3-2.2-1.7-3.3-4.2-3.7h-1.2c-20.6-2-44.9-3-73-3.2
	c-28.4,0.2-53.1,1.3-73.8,3.2h-1.2c-2.5,0.3-3.8,1.7-4.2,3.7l-3.2,28.8c0.3,4.2,2.2,7.6,5.7,10.3c4.7,3,9.1,4.7,13.6,4.7
	 M317.8,249.4h-11.3l-13.3,0.7c-3.8,0.3-6,1.8-6.7,4.3v0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M297.2,251.1h-0.3c-3.3,0.7-5.3,1.8-6,3.3l-1,0.2 M298.9,250.9l-1.7,0.2
	 M300.2,259.2c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5c-0.3,0.3-0.7,0.8-0.7,1.5c0,0.5,0.2,1,0.7,1.3c0.3,0.3,0.8,0.7,1.5,0.7
	c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3C300.9,260.2,300.5,259.7,300.2,259.2L300.2,259.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M299.9,274.7c-2-0.7-3.7-1.7-5.3-3.3c-2.5-2.5-3.8-5.7-3.8-9.3
	c0-3.7,1.3-6.7,3.8-9.3c0.8-0.8,1.7-1.3,2.5-2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M298.9,250.9c-1.3,0.7-2.7,1.5-3.8,2.7c-2.5,2.5-3.7,5.5-3.7,8.8
	c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7c3.5,0,6.3-1.2,8.8-3.7s3.7-5.3,3.7-8.8s-1.2-6.3-3.7-8.8c-1.5-1.5-3.2-2.5-5.2-3.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M296,262.2c0,2.2,0.8,4,2.3,5.5c1.5,1.5,3.3,2.3,5.7,2.3
	c2.2,0,4-0.8,5.5-2.3c1.5-1.5,2.3-3.3,2.3-5.5s-0.8-4-2.3-5.7c-1.5-1.5-3.3-2.3-5.5-2.3s-4,0.8-5.7,2.3
	C296.9,258.2,296,260.1,296,262.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M295.2,262.2c0,2.5,0.8,4.5,2.5,6.2s3.8,2.7,6.2,2.7
	c2.3,0,4.5-0.8,6.2-2.7c1.7-1.7,2.7-3.7,2.7-6.2s-0.8-4.5-2.7-6.2c-1.7-1.7-3.8-2.5-6.2-2.5c-2.5,0-4.5,0.8-6.2,2.5
	C296,257.7,295.2,259.9,295.2,262.2L295.2,262.2z M289.9,254.6l-3.5,0.3 M286.4,270.4l-1,0.7 M286.4,270.4c1.5-0.3,2.7-0.3,3.5,0.2
	 M286.6,275c0-0.2,0.2-0.2,0.2-0.2h0.2l45.9,6.5l0.2,0.2l0.2,0.2l3.7,10v0.2l-0.2,0.2h-0.3l-49.4-6.3l-0.2-0.2v-0.2l-0.3-10.3
	L286.6,275L286.6,275z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M289.9,270.5c0.3,0.3,0.7,0.7,0.8,1c0.5,1.2,1.7,2,3.8,2.5l5.5,0.7
	 M299,266c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.5
	c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.5,0.5C299.2,265,299,265.5,299,266L299,266z M286.4,254.9v15.5 M289.9,270.5v-16 M366.9,330.2
	c0.2,0.3,0.3,0.7,0.7,0.8c0.7,0.5,1.3,0.7,2.2,0.7s1.5-0.2,2.2-0.7c0.3-0.3,0.7-0.5,0.8-0.8 M364.7,338.9c0.2,0.3,0.3,0.7,0.8,0.8
	c0.7,0.5,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7l0.7-0.8 M362.7,347.7c0.2,0.3,0.3,0.7,0.8,0.8c0.7,0.3,1.3,0.7,2.2,0.7s1.7-0.2,2.2-0.7
	c0.3-0.2,0.7-0.5,0.7-0.8 M368.6,347.7h-5.8 M370.5,338.9H391 M372.7,330.2h-5.8 M370.5,338.9h-5.8 M299.4,281.8
	c0,0.3,0.2,0.8,0.5,1c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1c0-0.5-0.2-0.8-0.5-1.2s-0.7-0.5-1.2-0.5
	c-0.3,0-0.8,0.2-1.2,0.5C299.5,281,299.4,281.3,299.4,281.8L299.4,281.8z M390.5,347.7h-22 M421.8,366.2c0-0.3-0.2-0.5-0.3-0.8
	c-0.2-0.2-0.5-0.3-0.7-0.3c-0.2,0-0.5,0-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
	s0.5-0.2,0.7-0.3C421.8,366.8,421.8,366.5,421.8,366.2L421.8,366.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M311.5,279.2l-24.3-3.5l0.2,9.5l24.3,3.2 M309.3,250.2
	c1.3,0.7,2.7,1.5,3.8,2.7c2.7,2.7,3.8,5.7,3.8,9.3c0,3.7-1.3,6.7-3.8,9.3c-2.5,2.5-5.5,3.8-9,3.8 M309.3,250.2h-1.8 M305.2,255.4
	c-0.3-0.3-0.8-0.7-1.3-0.7c-0.5,0-1,0.2-1.5,0.7c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.5,0.8,0.7,1.5,0.7
	c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3S305.7,255.7,305.2,255.4z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M306.7,260.2c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.3,0.7s1-0.2,1.3-0.7
	c0.5-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5C306.9,259.2,306.7,259.7,306.7,260.2L306.7,260.2
	z M302.5,263.5c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7c0.3-0.3,0.7-0.8,0.7-1.3s-0.2-1-0.7-1.5c-0.3-0.3-0.8-0.5-1.3-0.5
	s-1,0.2-1.5,0.5c-0.3,0.5-0.5,0.8-0.5,1.5C301.9,262.7,302,263.2,302.5,263.5L302.5,263.5z M308.7,264.7c-0.3-0.3-0.8-0.5-1.3-0.5
	c-0.5,0-1,0.2-1.5,0.5c-0.3,0.5-0.5,0.8-0.5,1.5c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.8,0.7,1.5,0.7c0.5,0,1-0.2,1.3-0.7
	c0.3-0.3,0.7-0.8,0.7-1.3S309,265,308.7,264.7L308.7,264.7z M299.9,274.7c1.3,0.3,2.7,0.7,4,0.7h0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M304.2,275.4l26.9,3.8c-2.3-2.2-5.8-4.3-10.5-6.7c-2-1-3.2-2.7-3.3-4.7
	l-0.7-17.5h-7.5 M324,284.8c0-0.5-0.2-0.8-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5s-0.8,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2
	c0,0.3,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5S324,285.3,324,284.8L324,284.8z M311.7,288.3l-0.2-9.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M311.7,288.3l24.1,3.2l-3.3-9.3l-21-3 M307.5,250.2l-8.6,0.7 M304.2,275.4
	l-4.3-0.7 M530.2,279.2l24.4-3.5l-0.2,9.5l-24.3,3.2 M530.2,279.2v9.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M540.9,280.2c0.3,0,0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
	c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.8,0.5-1.2
	C540,280.3,540.4,280.2,540.9,280.2L540.9,280.2z M534.4,250.2l8.5,0.7 M537.7,275.4l4.3-0.7 M540.9,264c0.5,0,1,0.2,1.5,0.5
	c0.3,0.5,0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3s-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3s0.2-1,0.7-1.5
	C539.9,264.2,540.4,264,540.9,264L540.9,264z M542,274.7c-1.3,0.3-2.7,0.7-4,0.7h-0.3 M536,264.7c0.3,0.5,0.7,0.8,0.7,1.5
	c0,0.5-0.2,1-0.7,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.7-0.8-0.7-1.3s0.2-1,0.7-1.5
	c0.3-0.3,0.8-0.5,1.3-0.5C535.2,264,535.5,264.2,536,264.7L536,264.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M532.5,250.2c-1.3,0.7-2.7,1.5-3.8,2.7c-2.7,2.7-3.8,5.7-3.8,9.3
	c0,3.7,1.3,6.7,3.8,9.3c2.5,2.5,5.5,3.8,9,3.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M531.2,260.2c0-0.5,0.2-1,0.7-1.5c0.3-0.3,0.8-0.5,1.3-0.5
	c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.3,0.7c-0.5,0-1-0.2-1.3-0.7
	C531.4,261.2,531.2,260.7,531.2,260.2L531.2,260.2z M532.5,250.2h1.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M545.8,262.2c0,2.2-0.8,4-2.3,5.5c-1.5,1.5-3.5,2.3-5.7,2.3
	s-4-0.8-5.5-2.3c-1.5-1.5-2.3-3.3-2.3-5.5s0.8-4,2.3-5.7c1.5-1.5,3.3-2.3,5.5-2.3s4,0.8,5.7,2.3C545,258.2,545.8,260.1,545.8,262.2
	L545.8,262.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M546.7,262.2c0,2.5-0.8,4.5-2.5,6.2c-1.7,1.7-3.8,2.7-6.2,2.7
	c-2.5,0-4.5-0.8-6.2-2.7c-1.7-1.7-2.5-3.7-2.5-6.2s0.8-4.5,2.5-6.2c1.7-1.7,3.8-2.5,6.2-2.5s4.5,0.8,6.2,2.5
	C545.8,257.7,546.7,259.9,546.7,262.2L546.7,262.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M534.4,250.2c-2,0.5-3.7,1.7-5.2,3.2c-2.5,2.5-3.7,5.5-3.7,8.8
	c0,3.5,1.2,6.3,3.7,8.8s5.3,3.7,8.8,3.7s6.3-1.2,8.8-3.7c2.5-2.5,3.7-5.3,3.7-8.8s-1.2-6.3-3.7-8.8c-1.2-1.2-2.5-2-3.8-2.7
	 M542.9,250.9l1.8,0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M539.4,263.5c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7
	c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.5c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.5,0.5c0.3,0.5,0.5,0.8,0.5,1.5
	C539.9,262.7,539.7,263.2,539.4,263.5L539.4,263.5z M543,258.7c0.5,0,1,0.2,1.3,0.5s0.5,0.8,0.5,1.5c0,0.5-0.2,1-0.5,1.3
	c-0.3,0.3-0.8,0.7-1.3,0.7s-1-0.2-1.3-0.7c-0.5-0.3-0.7-0.8-0.7-1.3c0-0.5,0.2-1,0.7-1.5C542,258.9,542.5,258.7,543,258.7L543,258.7
	z M539.9,256.7c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.7-1.5,0.7c-0.5,0-1-0.2-1.3-0.7c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.7,1.3-0.7s1,0.2,1.5,0.7C539.7,255.7,539.9,256.2,539.9,256.7L539.9,256.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M542,274.7c2-0.7,3.7-1.7,5.2-3.3c2.5-2.5,3.8-5.7,3.8-9.3
	c0-3.7-1.3-6.7-3.8-9.3c-0.8-0.8-1.7-1.3-2.5-2 M552,254.6v16 M555.5,270.4v-15.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M552,270.5c-0.5,0.3-0.7,0.7-0.8,1c-0.5,1.2-1.7,2-3.8,2.5l-5.5,0.7
	 M555.5,270.4c-1.5-0.3-2.7-0.3-3.3,0.2 M556.5,271l-1-0.7 M544.7,251.1h0.3c3.3,0.7,5.3,1.8,6,3.3l1,0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M555.5,254.9v-0.5c-0.7-2.5-3-4-6.7-4.3l-13.3-0.7h-11.5 M555.5,254.9
	l-3.5-0.3 M549.8,214.8v-43.9l0.2-0.3v-5l-0.3-0.2l-0.3,0.2v48.6 M530.2,288.3l-24.3,3.2l3.5-9.3l20.8-3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M508.9,281.5l-0.2,0.2l-3.7,10.1v0.2l0.2,0.2l0.2,0.2l49.4-6.5h0.2
	l0.2-0.3l0.2-10.1V275h-0.2l-0.2-0.2l-45.9,6.7L508.9,281.5z M537.7,275.4l-26.9,3.8c2.3-2.2,5.8-4.3,10.5-6.7c2-1,3.2-2.7,3.3-4.7
	l0.7-17.5h7.5 M372.7,330.2h19.6 M396.3,347.7h-5.8 M391,338.9h5.8 M423.8,330.2h20 M423.8,338.9h21.3 M445.6,347.7h-21.8
	 M286.9,324.6l0.2,0.5l-0.2,28.3l-0.2,0.5l-0.5,0.2l-2.2-0.2c-0.3,0-0.5-0.2-0.5-0.7v-27.9c0-0.5,0.2-0.7,0.7-0.7l2.3-0.2
	C286.6,324.4,286.7,324.4,286.9,324.6L286.9,324.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M282.7,325.2v27.9c0,0.8,0.3,1.3,1.2,1.3l2.3,0.2c0.5,0,0.8-0.2,1-0.3
	c0.2-0.2,0.3-0.5,0.3-1l0.2-28.3c0-0.5-0.2-0.8-0.3-1c-0.2-0.3-0.7-0.3-1-0.3l-2.3,0.2C283.1,323.9,282.7,324.3,282.7,325.2
	L282.7,325.2z M416.1,235.6c0.3,0,0.7-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
	s-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.7,0.3,0.8C415.6,235.4,415.8,235.6,416.1,235.6L416.1,235.6z M436.2,233.3
	c0.3,0,0.7,0.2,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.2,0.7-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.2-0.8-0.3
	c-0.2-0.2-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8C435.6,233.4,435.9,233.3,436.2,233.3L436.2,233.3z M426.8,234.4c0,0.3-0.2,0.7-0.3,0.8
	c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	c0.3,0,0.7,0.2,0.8,0.3C426.8,233.9,426.8,234.1,426.8,234.4L426.8,234.4z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M284.2,339.1c0-0.2,0-0.3,0.2-0.5c0.2-0.2,0.3-0.2,0.5-0.2
	c0.2,0,0.3,0,0.5,0.2c0.2,0.2,0.2,0.3,0.2,0.5s0,0.5-0.2,0.5c-0.2,0-0.3,0.2-0.5,0.2c-0.2,0-0.3,0-0.5-0.2
	C284.4,339.4,284.2,339.2,284.2,339.1z M406.5,235.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
	c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.7,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
	C406,235.6,406.1,235.4,406.5,235.3L406.5,235.3z M518.2,286c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5
	c0.5,0,0.8,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.2,0.8-0.5,1.2s-0.7,0.5-1.2,0.5C518.9,286.5,518.6,286.3,518.2,286
	L518.2,286z M510.9,173.2l4.2,0.3 M515.2,171.4l-0.7,0.5l-2-0.2c-0.2-0.3-0.5-0.5-1-0.8 M515.1,173.6l0.2-2.2 M510.9,173.2
	c0.2-1.2,0.3-1.8,0.7-2.3 M510.9,174.9l4.2,0.3 M515.1,175.2v-1.7 M510.9,174.9v-1.7 M550.7,216.5l0.3,15.6l0.7,2.2l0.5,4.5
	c-2.7-1-4.3-1.8-5.2-2.7l0.8-4.3l0.5-15.5c0-0.5,0.2-0.8,0.7-0.8h0.3v-1.2L538.5,195l-0.2-0.2h-0.2l-4.2-9l-0.2-0.3
	c-1.3-3.7-3.8-7-7.3-10c-2-1.8-5.8-3.2-11.3-3.8 M401.5,237.1c-2.3,0-3.7-0.3-4.2-1l-2.2-4.2 M395.2,231.9l6.3-0.2 M401.5,231.8v5.3
	 M401.5,237.1h9.1 M410.6,231.8v5.3 M401.5,231.8h9.1 M431.3,231.8h1.2 M432.4,231.8h-1.2 M430.8,231.8h0.5 M431.3,231.8h-0.5
	 M420.8,237.1h10.5 M431.3,231.8v5.3 M420.8,237.1v-5.3 M420.8,231.8h10 M410.6,237.1h10.1 M420.8,231.8h-10.1 M365.2,255.9
	c-0.3-2.2-0.7-4.2-0.8-5.8c0.2-3.8,1.5-7.2,4.2-9.8c3.5-4.3,6.5-7,8.8-8c5.8-0.2,11.6-0.3,17.6-0.3 M365.2,255.9
	c0.8,5.2,2.3,11.6,4.3,19.1c1.8,9.5,5.7,14.6,11.3,15.6c11.1,1,24.6,1.3,40.6,1.2c15.5,0.3,28.6-0.2,39.6-1.2
	c5.7-1,9.5-6.2,11.3-15.6c2-7.6,3.5-14,4.3-19.1 M520.4,228.3c1.2,2.7,2.3,4.8,3.8,6.3 M524.1,248.1v1.3c-0.2,5.5-0.5,11.5-1,18
	c0,2.2-1.7,4.2-4.7,5.8c-6.2,2.8-9.8,5.8-11.1,8.6l-5,12 M524.2,234.8c0,4.2,0,8.5-0.2,13.3 M445.1,231.9h1.7 M447.6,231.9h-0.8
	 M446.7,231.9h-1.7 M446.7,231.9h0.8 M440.4,237.1c2.3,0,3.7-0.3,4.2-1l2.2-4.2 M445.1,231.9l-4.7-0.2 M440.4,237.1v-5.3
	 M447.6,231.9l16.8,0.3c2.3,1,5.3,3.7,8.8,8c2.7,2.8,4.2,6.2,4.2,9.8c-0.2,1.7-0.5,3.7-0.8,5.8 M515.1,175.2
	c-0.2,12.8,1.7,30.6,5.3,53.2 M476.5,255.9H365.2 M431.3,237.1h9.1 M440.4,231.8h-8 M610,229.6l-0.2-0.3v-0.2
	c-1.3-4.2-3.7-6.5-7.2-7h-0.2c-3.7-0.7-9.1-1-16.6-0.8h-3.2c-6.7,0.2-10.6,1.8-11.8,4.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M583.6,222.6c-6,0.2-9.5,1.7-10.5,4.3c-0.5,3.7-0.2,7,0.7,10.1
	c2.2,4.5,6.8,6.5,14.3,6.3l13.8-1.5c1.5-0.2,2.7-0.5,3.7-1l0.8-0.7l1.2-1.3h0.3l0.3-1.3l0.3-2.8l-0.3-4.8v-0.3
	c-1.2-3.7-3.3-5.8-6.5-6.3h-0.2c-3.3-0.7-8.2-0.8-15-0.7L583.6,222.6L583.6,222.6L583.6,222.6z M581.3,245.2l6.3-0.3l0.2-0.2
	l0.2-0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M587.9,244.6l-6.5-0.3c-4.7-1-7.8-3.2-9.5-6.7c-1-3.5-1.3-7.3-0.8-11.3
	 M610.4,234.9l-0.3-5.3 M610.4,234.9c1-0.3,1.5-1.2,1.5-2.2v-1.7c-0.2-1-0.7-1.5-1.8-1.5 M587.9,244.6l15.3-1.7l2.3-0.5
	c1.7-0.3,2.8-1.3,3.8-2.7h0.2l0.2-0.3c0.3-0.5,0.3-1.2,0.5-1.7l0.2-2.7 M549.8,215v-0.2 M550,215.3l-0.2-0.3 M550.7,216.5v-0.3
	c0-0.5-0.2-0.8-0.5-0.8 M550,215.3h-0.2V215 M571.1,226.3l-3.8,9.3l-0.7,0.8c-0.8,0.5-1.8,0.8-3.5,1H563l-0.5,0.2 M563.3,239.1
	c1.3,1.2,3.3,1.5,5.8,1.2c0.8-0.2,1.8,0,2.7,0.3c3.5,2.7,6.7,4.2,9.5,4.7 M562.5,237.4l0.8,1.7 M567.3,245.9l-0.2-0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M567.3,245.9l2.5-0.3l11.5-0.3 M563.3,239.1l4,6.8 M562.5,237.4l-11.8-21
	 M575.5,268.2c-1.7-3.7-3-8.1-3.8-13.3c-1.3-4.8-2.7-7.8-4.2-8.8 M524.1,248.1c10.3-0.3,19-0.3,25.9,0.3c4.2,0.5,6.3,2.7,6.5,6.5
	v32.3 M564.5,286.7l-8,0.3 M561.8,357.7l2.8-54.4l-0.2-16.6 M567.1,245.8c-2.3-1.2-3.5,1-3.3,6.2l0.7,34.8 M577.9,274.4
	c0.2-1.2,0.2-2.3,0-3.8c0-1.3-0.8-2.2-2.5-2.5 M581.9,337.4l0.2-2.7c0.8-10.3,1.3-19.5,1.7-27.8v-0.2l-0.2-0.2h-0.2
	c0.3-8,0.5-15,0.5-21c-2.7-3-4.7-6.7-6.2-11v-0.2 M577.9,274.4l-2.5-6.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M564.5,286.7c5.7,0.2,9,1.3,10,3.5l7,10.3 M581.9,337.4l1-39.2
	c-0.2-5.7-0.7-4.8-1.3,2.5 M567.1,245.8c-10.1-5-24.5-8.6-42.7-11.1 M556.5,287l-54.2,6.7 M321.3,228.3c3.7-22.6,5.5-40.4,5.3-53.2
	 M339.6,293.6l-54.2-6.7 M274.8,245.8c10.1-5,24.4-8.6,42.7-11.1 M502.3,293.6l-1.2,3c-0.8,2.3-3.3,3.5-7.5,3.5
	c-15.5,0.5-30.8,0.8-45.9,0.8l-26.3,0.2c-23.9,0.2-48.4-0.2-73.2-1c-4.2,0-6.5-1.2-7.5-3.5l-1.2-3 M285.4,287l-8.1-0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M277.2,286.7l0.7-34.8c0.2-5.2-0.8-7.3-3.3-6.2 M279.9,357.7l-2.3-41.1
	c-0.3-4.5-0.3-9-0.5-13.3c-0.2-2.3-0.2-4.8,0-7.1l0.2-9.3 M277.2,286.7c-5.7,0.2-9,1.3-10,3.5l-7,10.3 M260.3,300.5
	c-0.8-7.3-1.2-8.1-1.3-2.5l1,39.2 M263.9,274.4l2.5-6.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M263.9,274.4v0.2c-1.3,4.3-3.3,8-6.2,11c0,6,0.2,13,0.5,21h-0.2l-0.2,0.2
	v0.2c0.3,8.3,1,17.5,1.7,27.8l0.2,2.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M266.4,268.2c-1.7,0.3-2.5,1.2-2.5,2.5c-0.2,1.5-0.2,2.7,0,3.8
	 M274.6,245.9c-1.5,1-3,4-4.2,8.8c-0.8,5.2-2.2,9.6-3.8,13.3 M274.3,250.7c0.2,0,0.2,0.2,0.2,0.7l-1.7,8l-2.2,9
	c-1.2,4.5-2,6.7-2.3,6.7c-1,1.7-1.7,2.3-2.2,2.5c-0.5,0-0.7-0.7-0.7-2.3c-0.3,0,0.2-2.2,1.5-6.5c0.8-3,1.8-5.8,2.8-8.6l3-8.1
	c0.3-0.5,0.5-0.8,0.5-0.8c0.2-0.2,0.3-0.3,0.5-0.3L274.3,250.7L274.3,250.7z M285.4,287v-32.1c0.2-3.8,2.3-6,6.5-6.5
	c7-0.7,15.6-0.8,25.9-0.3 M279.4,237.4l-1,1.7 M260.5,245.2l14.1,0.7 M270.8,226.3c0.5,4,0.2,7.8-0.8,11.3c-1.7,3.5-4.8,5.7-9.5,6.7
	l-6.5,0.3 M278.4,239.1c-1.3,1.2-3.2,1.5-5.8,1.2c-0.8-0.2-1.8,0-2.7,0.3c-3.5,2.7-6.7,4.2-9.5,4.7 M274.6,245.9l0.2-0.2
	 M278.4,239.1l-3.8,6.8 M279.4,237.4l-0.5-0.2h-0.2c-1.7-0.2-2.7-0.5-3.5-1l-0.7-0.8l-3.8-9.3 M317.5,234.8c0,4.2,0,8.5,0.2,13.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M317.7,248.1v1.3c0.2,5.5,0.5,11.5,1,18c0,2.2,1.7,4.2,4.7,5.8
	c6.2,2.8,9.8,5.8,11.1,8.6l5,12 M524.2,234.8l-3.7-0.5c-17.8-1.3-36.3-2.3-55.2-2.8c-5.7-0.2-11.3-0.3-17-0.3l-27.1-0.3
	c-15.3,0-30.3,0.2-44.9,0.7c-19,0.5-37.3,1.5-55.2,2.8l-3.7,0.5 M317.5,234.8c1.5-1.5,2.7-3.7,3.8-6.3 M520.4,228.3l0.2,0.5l-37.1-1
	l-19.6-0.5c-8.1-0.3-16.3-0.3-24.6-0.5l-17.8-0.2c-14.8,0-29.3,0.2-43.4,0.7l-19.6,0.5l-37.1,1l0.2-0.5 M254,244.6v0.2h0.2v0.2h0.2
	l6.2,0.3 M231.5,234.9l0.2,2.7c0,0.7,0.2,1.2,0.5,1.7l0.2,0.2h0.2c1,1.3,2.2,2.3,3.8,2.7l2.3,0.5l15.3,1.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M231.8,229.6c-1,0-1.7,0.5-1.8,1.5v1.8c0,1,0.5,1.8,1.5,2.2 M231.5,234.9
	l0.3-5.3 M233.8,229.6c1.2-3.8,3.3-5.8,6.5-6.3h0.2c3.3-0.7,8.1-0.8,15-0.7h2.8c6,0.2,9.5,1.7,10.5,4.3c0.5,3.7,0.3,7-0.7,10.1
	c-2.2,4.5-6.8,6.5-14.3,6.3l-13.8-1.5c-1.5-0.2-2.7-0.5-3.7-1l-0.8-0.7c-0.5-0.3-0.8-0.8-1.2-1.3l-0.3-0.2l-0.5-1.3l-0.3-2.8
	l0.3-4.8L233.8,229.6L233.8,229.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M270.8,226.3c-1.2-3-5-4.7-11.8-4.8h-3.2c-7.5-0.2-13,0.2-16.6,0.8H239
	c-3.5,0.5-6,2.8-7.2,7v0.2l-0.2,0.3 M326.8,175.2l4.2-0.3 M326.8,175.2l-0.2-1.7 M331,174.9v-1.7 M331,173.2l-4.3,0.3 M331,174.9v1
	c24.8-2.3,51.1-3.7,79-4h22.8c9.3,0.2,18.5,0.3,27.3,0.7c17.6,0.7,34.6,1.8,50.9,3.3v-1 M330.3,170.9c-0.5,0.2-0.8,0.5-1,0.8l-2,0.2
	l-0.7-0.5 M326.6,173.6v-2.2 M330.3,170.9c0.3,0.5,0.7,1.2,0.7,2.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M511.6,170.9c-0.5-0.2-1.2-0.3-2-0.5c-15.6-1.5-32.1-2.7-49.6-3.2
	c-8.8-0.3-18-0.7-27.3-0.7H410c-27.9,0.3-53.9,1.7-77.7,4c-0.8,0.2-1.5,0.3-2,0.5 M326.6,171.4c-5.5,0.7-9.3,2-11.3,3.8
	c-3.5,3-6,6.3-7.3,10l-0.2,0.3l-4.2,9h-0.2c0,0-0.2,0-0.2,0.2l-24,42.7 M561.5,319.3l-1,36.1c0,1.3-1.5,2-4.5,1.7
	c-3.3,0-5.3-1.7-6-5.2l-0.7-27.3c0.3-5,3.2-7.6,8.6-8.3C560.7,315.8,561.8,316.8,561.5,319.3L561.5,319.3z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M572.6,366.3c-4.7-4.8-16.1-7.5-34.3-7.6c-5.5-0.5-9.1-2.3-10.8-5.2
	c-6.3-10.1-12.5-18.5-18.1-24.5c-4.7-5.2-10.3-7.8-17.3-7.8H349.8c-7,0.2-12.6,2.8-17.3,7.8c-5.7,6.2-11.6,14.3-18.1,24.5
	c-1.8,2.8-5.3,4.7-10.8,5.2c-18.1,0.3-29.4,2.8-34.3,7.6 M572.6,366.3l-1.3,5.2c-2,5-4.5,7.2-7.7,6.5c0.3-1.3-1.8-2.7-6-4
	c-6.8-1.7-14-2.5-21.3-2.8 M536.4,371.2c-2.2-0.2-4.2-0.2-6.3-0.2l-0.3-1.2 M536.4,381.8v-10.6 M536.4,384.6v-2.8 M579.9,312.4
	l-7,52.1l-0.3,1.8 M579.9,364.3l2-26.9 M579.9,364.3v-51.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M536.4,384.6v34.4c0.3,3.7,3.8,5.8,10.5,6.7h0.3v0.2h0.2l8.3,0.3l12.5-0.3
	l0.2-0.2l1-0.2c6.7-0.8,10.1-3,10.5-6.7v-54.7 M525.7,358.5c-5.8-10.3-11.6-19-17.8-26.1c-4.3-5.5-10.1-8.1-17.6-8h-139
	c-7.5-0.3-13.3,2.3-17.6,8c-6.2,7.2-12,15.8-17.8,26.1L312,370 M365.1,375.3c0.3,1,1.8,1.8,4.7,2.7l17,4.7l2.3,0.3h51.9
	c1.5-0.2,3-0.8,4.3-1.7l9.6-7c0.5-0.5,2-0.8,4.3-0.8c10.5-0.2,19.3-1.2,26.3-3.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M365.1,370h22c3.2-0.3,6.5,0.2,9.6,1.8c4.7,2.2,9.6,3.5,14.8,3.7h18.8
	c5.2-0.3,10.1-1.5,14.8-3.7c3.2-1.5,6.5-2.2,9.6-1.8h30.6 M536.4,381.8h-17.1c-2.5,0.2-3.8-0.3-4-1.5l-1.7-10.5 M486.1,369.8
	l-0.7,0.2 M485.5,370h27.9 M337.6,357.8c19.3-0.5,38.3-0.7,57-0.5h52.4c25.8-0.3,52.1,0,78.7,1.2 M365.1,375.3V370 M513.4,370h16.1
	 M529.5,370l-3.8-11.5 M337.6,357.8c-2.2-1.7-4.7-2.5-7.5-2.5c-3.2,0-5.8,1-8.3,3c-0.5,0.3-0.8,0.8-1.3,1.3c-2.2,2.3-3.5,5-3.8,8.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M341.4,362c-0.5-0.8-1.2-1.7-1.8-2.3c-0.7-0.7-1.3-1.3-2-1.8 M280.2,319.3
	l1,36.1c0,1.3,1.5,2,4.5,1.7c3.3,0,5.3-1.7,6-5.2l0.7-27.3c-0.3-5-3.2-7.6-8.6-8.3C281.1,315.8,279.9,316.8,280.2,319.3L280.2,319.3
	z M269.3,366.3l-0.5-1.8l-6.8-52.1 M305.5,371.2c-7.5,0.2-14.5,1.2-21.3,2.8c-4.2,1.3-6.2,2.7-6,4c-3.2,0.7-5.7-1.5-7.6-6.5
	l-1.3-5.2 M336.3,368.8c0-1.7-0.7-3.2-1.8-4.3c-1.2-1.2-2.7-1.8-4.3-1.8c-1.7,0-3.2,0.7-4.3,1.8c-1.2,1.2-1.8,2.7-1.8,4.3l0.2,1
	c0.2,1.3,0.8,2.3,1.7,3.3c0.5,0.5,1.2,1,1.8,1.3c0.8,0.3,1.5,0.5,2.5,0.5c1.7,0,3.2-0.7,4.3-1.8c1-1,1.5-2,1.8-3.3L336.3,368.8
	L336.3,368.8z M365.1,370h-21.5c0-3-0.7-5.7-2.2-8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M334.5,375.3l1.2-1c1.3-1.3,2-2.7,2.2-4.3v-1c0-2.2-0.8-4-2.3-5.5
	c-0.7-0.7-1.3-1.2-2.2-1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M333.5,362c-1-0.5-2.2-0.8-3.3-0.8c-2.2,0-4,0.8-5.5,2.3
	c-1,1-1.7,2.2-2,3.5 M305.5,381.8h17.1c2.5,0.2,3.8-0.3,4-1.5l0.7-4.3 M327.5,376.1c0.8,0.3,1.8,0.5,2.8,0.5c1.7,0,3-0.5,4.3-1.3
	 M322.7,367c-0.2,0.7-0.2,1.2-0.2,1.8v1c0.2,1.7,1,3.2,2.2,4.3c0.8,0.8,1.7,1.3,2.7,1.8 M333.5,362h8 M316.7,367.8
	c-0.2,0.7-0.2,1.5-0.2,2.2h-4.3 M322.7,367l-6,0.8 M312.2,370l-0.3,1.2c-2.2,0-4.2,0-6.3,0.2 M305.5,384.6v-2.8 M305.5,371.2v10.6
	 M260,337.4l2,26.9 M261.9,312.4v51.9 M261.9,364.3v54.7c0.3,3.7,3.8,5.8,10.5,6.7l1,0.2l0.2,0.2l12.5,0.3l8.3-0.3h0.2v-0.2h0.3
	c6.7-0.8,10.1-3,10.5-6.7v-34.4 M305.5,384.6h230.8 M334.5,375.3h30.6 M581.4,300.5l-1.5,12 M260.3,300.5l1.7,12"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M321.3,228.8
		c0,0,6-32.3,5.5-53.7l4.2-0.3v1c0,0,35.8-4,89.8-4c54.1,0,90.1,4,90.1,4v-1l4.2,0.3c0,0-1,14.1,5.5,53.7c0,0-65.2-2-99.8-2
		C386.4,226.8,321.3,228.8,321.3,228.8z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M321.3,228.8c0,0,65.2-2,99.5-2
		c34.3,0,99.8,2,99.8,2s2.3,5,3.7,6c0,0,0.2,15.8-1.3,32.6c0,0,0.3,2.8-4,5.3c0,0-9,4-11.1,7.8l-0.7,1.2l-6.2,14.6
		c0,0-0.7,3.7-6.7,3.8c0,0-27.9,1-73.7,1s-73.5-1-73.5-1s-5.7,0-6.8-4l-5.8-14c0,0-1.5-3.7-5.7-6c0,0-2-1.3-6.3-3.5c0,0-3.7-2.2-4-5
		c0,0-1.7-22.5-1.3-32.9C317.5,234.8,319.7,232.3,321.3,228.8z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M278.2,378
		c0,0-0.3-2,4.7-3.5c0,0,7-3.2,28.9-3.3l0.3-1.2h4.3c0,0-0.2-14,13.5-14.6c0,0,6.3-0.7,11,6c0,0,2.5,3.2,2.7,8.6h43.6
		c0,0,4.2-0.5,7.8,1c3.7,1.5,8.8,4.2,16.3,4.5h19.3c0,0,6.3,0,14-3.3c0,0,5-3,10.5-2.2h74.8l0.3,1.2c0,0,8.8-0.5,23.1,1.8
		c0,0,10.6,1.7,10.6,5c0,0,4.5,1.7,7.7-6.5l1.7-7l8.5-64l-7-10.1c0,0-0.5-3.5-10-3.7l-8.2,0.3l-54.1,6.7l-1.2,2.5
		c0,0-0.8,3.8-6.7,3.8c0,0-34.1,1-73.7,1s-71.4-1-71.4-1s-7.5,0.7-9-4l-1-2.5l-54.2-6.7l-8.1-0.3c0,0-8.3-0.2-10,3.5l-7,10.3l8.5,64
		l1.7,6.7C270.4,371.2,273.1,379,278.2,378z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M317.8,248.1
		c0,0-17-0.7-25.4,0.3c0,0-7,0-7,6.5V287l54.2,6.7l-4.8-11.6c0,0-1.3-4-8-7.3c0,0-7.6-3.3-7.8-6.8L317.8,248.1z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M502.3,293.6l54.1-6.7
		v-32.6c0,0,0.2-5.3-6.3-6c0,0-7.3-1.2-25.9-0.2c0,0-0.5,13.1-1,19c0,0,0.2,3-3.7,5.2c0,0-8.3,3.7-11.1,7.5l-0.8,1.5L502.3,293.6z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback3DrRear);
