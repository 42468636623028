import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import CoreModalsContainer from '../../modals/CoreModalsContainer';

let styles = {};

class CoreButton extends Component {
  state = {
    symbol: Symbol(), // smybol is used by the CoreModalsContainer to identify unique actions
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <CoreModalsContainer
          symbol={this.state.symbol}
          existingVin={this.props.existingVin}
          sellerActivityList={this.props.sellerActivityList}
        />

        <Button
          style={{ ...styles.button, ...this.props.style }}
          onClick={() => this.setState({ symbol: Symbol() })}
        >
          {this.props.label || 'CORE'}
        </Button>
      </div>
    );
  }
}

styles = {
  button: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    padding: '6px 12px',
    backgroundColor: 'gray',
    color: 'white',
    borderRadius: 2,
    boxShadow: 'none',
  },
};

CoreButton.defaultProps = {
  style: {},
};

CoreButton.propTypes = {
  existingVin: PropTypes.string,
  label: PropTypes.string,
  sellerActivityList: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default connect(
  null,
  {}
)(CoreButton);
