import React, { Component } from 'react';

class AdditionalIcon extends Component {
  render() {
    const { size = 24, color = '#fff' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.875 3.375H3.75C3.54375 3.375 3.375 3.54375 3.375 3.75V10.875C3.375 11.0813 3.54375 11.25 3.75 11.25H10.875C11.0813 11.25 11.25 11.0813 11.25 10.875V3.75C11.25 3.54375 11.0813 3.375 10.875 3.375ZM9.65625 9.65625H4.96875V4.96875H9.65625V9.65625ZM20.25 3.375H13.125C12.9187 3.375 12.75 3.54375 12.75 3.75V10.875C12.75 11.0813 12.9187 11.25 13.125 11.25H20.25C20.4562 11.25 20.625 11.0813 20.625 10.875V3.75C20.625 3.54375 20.4562 3.375 20.25 3.375ZM19.0312 9.65625H14.3438V4.96875H19.0312V9.65625ZM20.25 12.75H13.125C12.9187 12.75 12.75 12.9187 12.75 13.125V20.25C12.75 20.4562 12.9187 20.625 13.125 20.625H20.25C20.4562 20.625 20.625 20.4562 20.625 20.25V13.125C20.625 12.9187 20.4562 12.75 20.25 12.75ZM19.0312 19.0312H14.3438V14.3438H19.0312V19.0312ZM9.9375 16.6875H6.9375V13.6875C6.9375 13.5844 6.85313 13.5 6.75 13.5H5.625C5.52187 13.5 5.4375 13.5844 5.4375 13.6875V16.6875H2.4375C2.33437 16.6875 2.25 16.7719 2.25 16.875V18C2.25 18.1031 2.33437 18.1875 2.4375 18.1875H5.4375V21.1875C5.4375 21.2906 5.52187 21.375 5.625 21.375H6.75C6.85313 21.375 6.9375 21.2906 6.9375 21.1875V18.1875H9.9375C10.0406 18.1875 10.125 18.1031 10.125 18V16.875C10.125 16.7719 10.0406 16.6875 9.9375 16.6875Z" />
      </svg>
    );
  }
}

export default AdditionalIcon;
