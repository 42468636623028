import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M698.4,331.6c-1-2.9-2.2-5.5-3.7-7.8c-2.5-4.1-5.6-7.4-9.5-9.6l-0.4-0.3c-5.8-4.1-16.8-6.3-33-6.5h-21.6
	c-13.2-0.1-24.5,0.7-33.7,2.6l-0.7,0.1c-16.2,4.8-26,16.1-29.3,33.6v0.1c-0.7,2.8-1.2,5.6-1.9,8.5l25.2-8.4c2.5-2.5,5.1-4.7,7.8-6.6
	c10.2-7.3,22-11,35.2-11s24.9,3.7,35.1,11c2.9,2.1,5.6,4.4,8.3,7l3.3,3.4h22.8C701.3,342.2,699.9,336.7,698.4,331.6L698.4,331.6z"
          />
          <path
            fill="#656565"
            d="M702.4,348.1h-22.8c5.4,6.2,9.4,13.1,11.8,20.5h14.3C704.7,361.3,703.6,354.5,702.4,348.1z M705.8,370.5
	l-0.3-1.9h-14.3c2.1,6.1,3,12.5,3,19.5c0,5.6-0.7,11-1.9,16h82c8.3,0.3,12.4-2.9,12.2-9.4v-12.1l-68.2,9.2l-10.3,1.4
	C707.4,385.1,706.7,377.6,705.8,370.5z"
          />
          <path
            fill="#FFFFFF"
            d="M270.9,331.8c-3,0-4.3,1.1-3.6,3.3l1.6,10.2c0.4,1.8,2.1,2.6,5,2.5h169.2v-16H270.9L270.9,331.8z
	 M432.8,284.2c-0.1-0.8-0.7-1.4-1.6-1.2l-8.4,0.1v7l8.7,0.1c0.8,0,1.4-0.4,1.5-1.4C433.2,287,433.2,285.5,432.8,284.2L432.8,284.2z
	 M426,285.7c0.3-0.3,0.8-0.5,1.2-0.5s0.8,0.1,1.2,0.5c0.3,0.3,0.6,0.8,0.6,1.2s-0.1,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5
	s-1-0.1-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.2C425.5,286.4,425.6,285.9,426,285.7L426,285.7z"
          />
          <path
            fill="#656565"
            d="M427.3,285.1c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.2s0.1,0.8,0.4,1.2c0.3,0.3,0.8,0.5,1.2,0.5
	s0.8-0.1,1.2-0.5c0.3-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-1-0.5-1.2C428.1,285.3,427.7,285.1,427.3,285.1L427.3,285.1z"
          />
          <path
            fill="#FFFFFF"
            d="M422.7,290.1v-7.2L416,283l-20.1,0.1h-1.6v6.3l1.9,0.1l19.5,0.3"
          />
          <path
            fill="#FFFFFF"
            d="M415.2,280.2c-0.8-1.7-2.3-2.6-4.4-2.9l-5-0.3l-4.7,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.9-0.8,3
	l20.1-0.3C415.9,281.8,415.6,281,415.2,280.2L415.2,280.2z M386.4,283.3c-1,0.1-1.6,0.7-1.9,1.8v2.5c0.3,1,1,1.5,2.2,1.8l7.6,0.1
	v-6.3L386.4,283.3L386.4,283.3z M396.2,289.7c0.1,2.2,0.8,4,2.2,5.2c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5
	l4.1-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.1,2.1-2.8,2.2-5L396.2,289.7L396.2,289.7z M342.9,175.9l0.1,1c3.7-1.1,7.7-2.2,11.7-3.2
	l-0.4-0.8l-0.8-1.8l-5,1.2l-4.8,1.2l-1.1,0.3L342.9,175.9L342.9,175.9z"
          />
          <path fill="#FFFFFF" d="M348.7,172.3l0.1-6.1l-2.6,0.7l-2.3,6.6" />
          <path
            fill="#656565"
            d="M350,197.5c14.4-8.1,35.1-12.8,62.2-13.9l14.3-0.3c2.5,0.6,3.7,3,3.9,7.6l1.4,66.3l-118.2,2.9
	c-1.4,0.8-3.2,1.2-5.4,1.4l-1.9,0.1v0.8l126.6-3.2c0.3,0,0.5-0.1,0.8-0.3c0.1-0.3,0.3-0.5,0.3-0.8l-1.4-67.4
	c-0.3-6.1-2.2-9.2-5.9-9.6h-0.3l-14.2,0.3c-27.5,1.2-48.6,5.9-63.1,14.2c-12.9,6.9-26.8,16.6-41.7,29.2v2.9
	C322.4,214.7,336.8,204.6,350,197.5L350,197.5z"
          />
          <path
            fill="#EBEBEB"
            d="M350,197.5c-13.2,7.2-27.5,17.2-42.8,30.3l-0.3,8.7c3.7,0.6,6.2,2.1,7.4,4.5c1.1,2.2,1.8,5,2.2,8.4
	c0.1,1.8,0.3,3.7,0.3,5.8c-0.3,2.2-1.4,3.9-3.2,5l118.2-2.9l-1.4-66.3c-0.1-4.5-1.5-7-3.9-7.6l-14.3,0.3
	C385,184.7,364.4,189.4,350,197.5L350,197.5z"
          />
          <path
            fill="#FFFFFF"
            d="M486.4,159.8v2.3h7.2v-2.3c0-0.4-0.1-0.6-0.5-0.6H487C486.6,159.2,486.4,159.5,486.4,159.8L486.4,159.8z"
          />
          <path
            fill="#656565"
            d="M461.2,180.3c-3.2,0.3-4.8,2.5-5.2,6.7l3.6,70.3c0,0.3,0.1,0.5,0.4,0.8c0.1,0.1,0.4,0.3,0.7,0.3l84.2-2.5
	c18.2-0.1,27.8-4.1,28.9-12.2c0.1-1,0.1-1.9,0.1-2.9v-0.3c0-2.5-0.1-4.9-0.4-7.6l-1.9-10c-1.9-7.6-5-16-9.1-25
	c-4.4-11.7-16-17.5-34.7-17.6L461.2,180.3L461.2,180.3z M458.2,187.2c0.3-2.8,1.4-4.4,3.3-4.7l66.3-0.1c17.6,0,28.5,5.4,32.6,16.1
	c7.6,16.5,11.3,30.4,11.3,41.8v0.4c0.1,0.8,0.1,1.6,0,2.3c-1.2,6.9-10.2,10.3-26.8,10.3l-83.1,2.5L458.2,187.2L458.2,187.2z"
          />
          <path
            fill="#FFFFFF"
            d="M558.5,292.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.1,2.1-2.8,2.2-5l-19.5-0.3c0.1,2.2,0.8,4,2.2,5.2
	c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5c1.4,0,2.8-0.1,4.1-0.4L558.5,292.3L558.5,292.3z M531.8,283.7
	c0.3,1,1,1.5,2.2,1.8l7.6,0.1v-6.3l-7.7,0.1c-1,0.1-1.7,0.7-1.9,1.8v2.5H531.8z M534.4,337.5c1.2-3.6,1-5.5-0.5-5.8h-86.4v16.1h81.4
	C531,347.7,532.8,344.3,534.4,337.5L534.4,337.5z M460.7,258.4l-15.8,0.4v6.5l90.4-2.3c5.2,0.1,10.6-0.3,15.8-1l3.7-0.7
	c7.8-1.4,13.5-3.3,16.6-5.5c3-2.2,4.3-6.6,3.7-13.3l-1.8-9.5c0.3,2.6,0.4,5.2,0.4,7.6v0.3c0.1,1,0,2.1-0.1,2.9
	c-1.1,8.1-10.7,12.1-28.9,12.2L460.7,258.4L460.7,258.4z M444.9,258.8l-12,0.4l-126.7,3.2l-0.1,5.8l138.8-2.9 M571.6,222.8l1.9,10
	C573.1,229.7,572.5,226.2,571.6,222.8z M656.5,160.9c0-0.4-0.1-0.6-0.5-0.6h-7c-0.4,0-0.5,0.1-0.5,0.6v2.3l8,0.1V160.9z"
          />
          <path
            fill="#656565"
            d="M225.5,247.3l-8.4,6.3l12.4,2.9h0.6c9.6-0.1,17.6-2.6,23.9-7.6l8-6.9c27.1-23.1,51.4-41.1,72.9-54.1
	l-12.5-4.4l-5.6,3.3l6.7,3.7c2.1,1.4,0.8,3.2-3.6,5.2c-23.9,16.8-46.6,33.6-68,50.2c-1.9,1.7-4.4,2.5-7.4,2.3L225.5,247.3
	L225.5,247.3z"
          />
          <path
            fill="#FFFFFF"
            d="M230,256.6h-0.6c-27.9,1.6-53.9,3.7-78,6.5c-31.5,4-56,8.4-73.7,13.1c-2.6,0.8-5,2.5-6.7,4.7l-1,1.1
	c-0.7,0.8-1.2,1.9-1.8,3l-1.7,4h22c2.1,0.1,2.9,2.1,2.5,5.8l-7.2,31.5l21-0.8c6.2-9.8,15.7-16.5,28.3-20.1l0.3-0.1
	c11.7-2.3,26.3-3.2,44-2.6h0.3c25.6-1.1,42.5,1.5,50.8,7.6c7.6,4.3,13.2,11.4,17.1,21.7v0.1c5,15,8.5,34,10.9,56.8h9.5
	c-3.7-16.6-6.5-36.6-8.3-59.6c-2.1-29.9-0.1-56.7,5.8-80.6c0.8-3.7,0.3-5.9-1.9-6.6l-8,6.9C247.6,253.9,239.6,256.5,230,256.6
	L230,256.6z"
          />
          <path
            fill="#EBEBEB"
            d="M225.5,247.3l19.1,1c3,0.1,5.5-0.7,7.4-2.3c21.3-16.8,43.9-33.4,68-50.2c4.4-2.1,5.6-3.9,3.6-5.2l-6.7-3.7
	C286.7,204,256.3,224.2,225.5,247.3z"
          />
          <path
            fill="#FFFFFF"
            d="M285.3,243.3c-3.7,4.4-5,9.5-4,15.3c1.8,6.6,10,9.9,24.8,9.6l0.1-5.8l-4.8,0.1c-0.3,0-0.5-0.1-0.8-0.3
	l-0.3-0.4c-2.1-0.1-3.9-0.3-5.2-0.5c-4.8-2.6-7.4-6.3-7.8-10.9c-0.1-1.6,0.3-2.5,1.2-2.3h5.5l1.9-8.7c1-2.3,3-3.3,5.9-3l5.1,0.3
	l0.3-8.7v-2.9l-3.2,2.8l-0.1,0.1L285.3,243.3L285.3,243.3z"
          />
          <path
            fill="#FFFFFF"
            d="M288.5,247.8c-1-0.1-1.4,0.7-1.2,2.3c0.4,4.5,3,8.3,7.8,10.9c1.4,0.3,3.2,0.5,5.2,0.5h1.8l-1.8-0.7
	c-4-1.6-6.2-3.6-6.3-6.1h-3C289.3,253.1,288.5,250.7,288.5,247.8L288.5,247.8z"
          />
          <path
            fill="#FFFFFF"
            d="M288.5,247.8c0.1,2.8,1,5.2,2.5,7.2h3c0.3,2.5,2.3,4.4,6.3,6.1l1.8,0.7l4.1-0.1l1.9-0.1
	c2.2-0.1,4-0.7,5.4-1.4c1.9-1.1,2.9-2.8,3.2-5c0-2.1-0.1-4-0.3-5.8c-3.6,0-6.9,0-9.9-0.1c-5.1-0.3-9.2-0.7-12.7-1.2L288.5,247.8
	L288.5,247.8z"
          />
          <path
            fill="#FFFFFF"
            d="M314.3,241c-1.2-2.3-3.7-3.9-7.4-4.5l-5.1-0.3c-2.9-0.3-5,0.7-5.9,3l-1.9,8.7c3.3,0.7,7.6,1.1,12.7,1.2
	c3,0.1,6.3,0.1,9.9,0.1C316.1,245.9,315.4,243.2,314.3,241L314.3,241z M306.2,262.4v-0.8l-4.1,0.1h-1.8l0.3,0.4
	c0.1,0.3,0.4,0.3,0.8,0.3H306.2L306.2,262.4z M178.1,302.7h-0.3c-17.7-0.5-32.3,0.3-44,2.6l-0.3,0.1c-12.7,3.6-22.1,10.2-28.3,20.1
	l11.3-0.4c4.8-5.4,11.3-9.2,19.4-11.6c9.2-1.8,20.5-2.8,33.7-2.6h21.6c16.1,0.1,27.1,2.3,33,6.5l0.4,0.3c5.9,3.4,10.3,9.2,13.2,17.5
	c4.5,14.3,7.6,32.3,9.5,53.9h9.5c-2.3-22.8-5.9-41.8-10.9-56.8v-0.1c-3.9-10.2-9.5-17.5-17.1-21.7
	C220.8,304.2,203.9,301.8,178.1,302.7L178.1,302.7z"
          />
          <path
            fill="#656565"
            d="M191.2,310.8h-21.6c-13.2-0.1-24.5,0.8-33.7,2.6c-8.1,2.3-14.6,6.2-19.4,11.6c-5.1,5.6-8.4,13.1-10,22.3
	l-0.8,4.1c7.6-2.3,14.3-3.6,19.9-3.6l2.9-3.2c2.6-2.6,5.4-5,8.3-7c10.2-7.3,21.9-11,35.1-11s24.9,3.7,35.2,11c2.9,2.1,5.6,4.4,8.3,7
	c11.1,11.1,17.2,24.5,17.9,39.9l14.2,7.4l-0.3-3c-1.8-21.6-5-39.6-9.5-53.9c-2.9-8.3-7.3-14-13.2-17.5l-0.4-0.3
	C218.5,313,207.4,311,191.2,310.8L191.2,310.8z"
          />
          <path
            fill="#656565"
            d="M207.3,337.5c-10.2-7.3-21.9-11-35.2-11c-13.2,0-24.9,3.7-35.1,11c-2.9,2.1-5.6,4.4-8.3,7l-2.9,3.2
	c-7.7,8.7-12.4,18.6-14.2,29.7c-0.6,3.4-0.8,7-0.8,10.6c0,16.9,6.1,31.5,18,43.5c12,12,26.6,18,43.5,18s31.5-6.1,43.5-18
	c5.4-5.4,9.6-11.3,12.5-17.7l1.1-2.5c0.8-2.2,1.6-4.4,2.2-6.6c1.4-5.2,2.1-10.7,2.1-16.6l-0.1-3.6c-0.8-15.4-6.7-28.8-17.9-39.9
	C213,341.9,210.2,339.6,207.3,337.5L207.3,337.5z M172.1,343.9c12.2,0,22.7,4.3,31.4,12.9s12.9,19.1,12.9,31.4
	c0,8.7-2.2,16.4-6.5,23.2l-1.6,2.5c-1.4,1.9-3,3.9-4.8,5.6c-8.7,8.7-19.1,13.1-31.4,13.1s-22.7-4.4-31.4-13.1s-12.9-19.1-12.9-31.4
	c0-12.2,4.3-22.7,12.9-31.4C149.5,348.1,159.8,343.9,172.1,343.9L172.1,343.9z"
          />
          <path
            fill="#EBEBEB"
            d="M203.5,356.8c-8.7-8.7-19.1-12.9-31.4-12.9s-22.7,4.3-31.4,12.9s-12.9,19.1-12.9,31.4
	c0,12.2,4.3,22.7,12.9,31.4c8.7,8.7,19.1,13.1,31.4,13.1s22.7-4.4,31.4-13.1c1.8-1.8,3.4-3.7,4.8-5.6l1.6-2.5
	c4.3-6.9,6.5-14.6,6.5-23.2C216.4,375.9,212.1,365.5,203.5,356.8L203.5,356.8z M172.1,346.1l4,0.1h0.4c9.8,1,18.2,5,25.3,12.1
	c6.1,6.1,9.9,13.1,11.6,21c0.6,2.8,0.8,5.8,0.8,8.8c0,11.7-4.1,21.6-12.4,29.9l-1.1,1c-2.5,2.3-5.2,4.4-8,6.1
	c-6.2,3.6-13.1,5.2-20.6,5.2c-7.4,0-14.2-1.6-20.2-5.1c-2.9-1.5-5.5-3.6-8.1-5.8l-1.5-1.4c-8.3-8.3-12.4-18.2-12.4-29.9
	c0-2.8,0.3-5.5,0.7-8.1c0.6-3.2,1.5-6.3,2.9-9.1c2.1-4.5,5-8.7,8.8-12.5c7-7,15.1-11,24.6-12.1l0.6-0.1h0.1L172.1,346.1L172.1,346.1
	z"
          />
          <path
            fill="#EBEBEB"
            d="M213.2,379.1c-0.4-1.1-1.4-1.4-2.8-0.7l-23.7,8.4l4.1,3l7.2-2.8l8.1-3.2c1.2-0.5,2.1-0.3,2.6,1.1l0.1,3
	c0,1.5-0.1,3-0.3,4.4c-0.3,2.6-0.8,5.1-1.6,7.4c-1.4,4.1-3.6,8-6.5,11.4l-0.1,0.1c-1.1,0.8-2.1,0.5-2.6-0.7l-4-6.1l-0.7-1l-4.3-6.6
	h-5.1l14.2,20.8c0.7,1.2,1.5,1.6,2.3,1.2c0.1-0.1,0.3-0.3,0.4-0.3l1.1-1c8.3-8.3,12.4-18.2,12.4-29.9c0-3-0.3-5.9-0.8-8.8
	L213.2,379.1L213.2,379.1z"
          />
          <path
            fill="#D2D3D4"
            d="M192.5,371.5h-5.2l-0.8,3.4l6.7-1.9 M198.1,387.5v-0.3l-7.2,2.8c0.1,1.1,0,2.2-0.3,3.4l-0.3,1.1l-0.1,0.3
	c-0.3,1-0.8,1.9-1.5,2.6l4.3,6.6c1-1.6,1.8-3.6,2.3-5.4c0.6-1.9,0.8-3.9,1-5.9v-2.3C198,389.7,198.5,388.8,198.1,387.5L198.1,387.5z
	"
          />
          <path
            fill="#EBEBEB"
            d="M186.1,375c-0.6-1.1-1.5-1.9-2.5-2.5l-0.8-0.5c-1-0.5-2.1-1-3.2-1.2l-4.1,3.2v0.4c2.5,0.5,4.8,1.9,6.7,3.9
	c0.7,0.7,1.2,1.2,1.7,1.9l0.6-0.1L186.1,375L186.1,375z"
          />
          <path
            fill="#EBEBEB"
            d="M175.5,375v-0.8l-3.4-0.4c-1.4,0-2.8,0.1-4,0.5v0.7c0,1.6-0.6,2.9-1.8,3.9c-0.6,0.7-1,1.1-1.5,1.5
	c-1.2,0.8-2.6,1-4.1,0.5l-0.8-0.3c-1.4,2.1-2.1,4.4-2.1,7l0.7,0.3c1.5,0.4,2.5,1.4,3,2.9l0.8,1.5c0.6,1.5,0.4,3-0.7,4.4l-0.6,0.8
	l0.8,0.8c1.5,1.5,3.3,2.6,5.2,3.4l0.4-0.7c1-1.2,2.2-1.9,3.7-2.1c0.7-0.3,1.4-0.3,2.1-0.3c1.5,0,2.8,0.7,3.7,2.1l0.6,0.8
	c1.6-0.7,3.2-1.8,4.5-3.2l1.2-1.4l-0.4-0.7c-1-1.2-1.2-2.6-1-4c0.1-0.7,0.3-1.4,0.4-1.9c0.4-1.5,1.5-2.5,3-3l0.8-0.3
	c-0.3-2.6-1.1-5-2.5-6.9l-0.7,0.3c-1.5,0.5-2.9,0.3-4.1-0.4c-0.6-0.3-1.1-0.4-1.6-0.8C176.1,378,175.5,376.7,175.5,375L175.5,375z
	 M166.6,382.7c1.5-1.5,3.3-2.2,5.5-2.2c2.1,0,4,0.8,5.5,2.2c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3
	c-2.1,0-4-0.8-5.5-2.3s-2.2-3.3-2.2-5.5S165.2,384.2,166.6,382.7z"
          />
          <path
            fill="#EBEBEB"
            d="M172.1,380.5c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3
	c2.1,0,4-0.8,5.5-2.3s2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5C176.1,381.1,174.3,380.5,172.1,380.5L172.1,380.5z M175.5,374.1v0.8
	c0,1.6,0.7,3,2.1,4c0.4,0.3,1,0.5,1.6,0.8c1.2,0.8,2.6,1,4.1,0.4l0.7-0.3c-0.6-0.7-1.1-1.4-1.6-1.9
	C180.2,376.1,178,374.7,175.5,374.1L175.5,374.1z M168.1,375v-0.7c-2.3,0.5-4.3,1.8-6.1,3.7c-0.8,0.8-1.5,1.6-2.1,2.5l0.8,0.3
	c1.5,0.5,2.9,0.3,4.1-0.5c0.6-0.4,1.1-0.8,1.5-1.5C167.6,377.8,168.1,376.6,168.1,375L168.1,375z"
          />
          <path
            fill="#EBEBEB"
            d="M168.1,374.4v-0.5l-4.1-3.2c-1.2,0.3-2.3,1-3.3,1.8l-0.6,0.4c-0.8,0.7-1.6,1.6-2.2,2.8l1.6,4.8l0.4,0.1
	c0.6-1,1.2-1.8,2.1-2.5C163.8,376.2,165.8,375,168.1,374.4L168.1,374.4z M186.7,386.8l-0.3,0.1v1.2c0,3.3-1,6.3-3,8.8l0.3,0.4h5.1
	c0.7-0.8,1.2-1.7,1.5-2.6l0.1-0.3l0.3-1.1c0.3-1.2,0.3-2.3,0.3-3.4L186.7,386.8L186.7,386.8z"
          />
          <path
            fill="#EBEBEB"
            d="M185.6,387.2c-1.5,0.5-2.6,1.5-3,3c-0.1,0.5-0.3,1.2-0.4,1.9c-0.3,1.4,0,2.8,1,4l0.4,0.7
	c1.9-2.5,3-5.4,3-8.8v-1.2L185.6,387.2L185.6,387.2z"
          />
          <path
            fill="#656565"
            d="M162.9,352.4c-4,1-7.8,2.6-11.3,5.1l12.5,8l0.6-11.1c0.3-1.2-0.3-1.9-1.5-2.1L162.9,352.4L162.9,352.4z
	 M160.1,372.9l0.6-0.4c1-0.8,2.1-1.4,3.3-1.8l0.3-5.2c-1.8,0.6-3.4,1.5-4.9,2.5c-1.4,1-2.8,2.1-4,3.3c-1,1-1.8,1.9-2.5,2.9l5.2,1.4
	C158.5,374.5,159.3,373.6,160.1,372.9L160.1,372.9z"
          />
          <path
            fill="#EBEBEB"
            d="M148.3,388.6l4.8,1.8l4.1-3l-23.7-8.4c-1.2-0.7-2.1-0.6-2.6,0.4l-0.1,0.6c-0.4,2.6-0.7,5.2-0.7,8.1
	c0,11.7,4.1,21.6,12.4,29.9l1.5,1.4l0.3,0.1c1,0.7,1.9,0.3,2.6-1.1l1.5-2.2l12.7-18.4h-5.2l-2.9,4.5l-3,4.5l-3,4.5
	c-0.6,1.2-1.5,1.5-2.8,0.5c-1.9-2.2-3.6-4.7-5-7.2c-1.6-3.3-2.9-7-3.4-10.9c-0.3-1.9-0.4-3.9-0.4-5.9c0-0.8,0-1.5,0.1-2.3v-0.4
	c0.4-1.1,1.2-1.4,2.3-0.8l5.2,2.1L148.3,388.6L148.3,388.6z"
          />
          <path
            fill="#656565"
            d="M153.1,390.4l-4.8-1.8c0.1,4,1,7.6,2.8,10.9c0.6,1,1.1,1.9,1.8,2.8l2.9-4.5c-0.8-1-1.5-1.9-1.9-2.9l-0.3-0.7
	C153.2,393,153,391.7,153.1,390.4L153.1,390.4z"
          />
          <path
            fill="#EBEBEB"
            d="M161.5,390.6c-0.6-1.4-1.5-2.3-3-2.9l-0.7-0.3v0.5c0,3.6,1.1,6.6,3.4,9.2l0.6-0.8c1-1.4,1.2-2.9,0.7-4.4
	L161.5,390.6L161.5,390.6z"
          />
          <path
            fill="#EBEBEB"
            d="M157.8,387.6c0.1-2.6,0.8-5,2.1-7l-0.4-0.1l-24.1-7.2c-1.5-0.3-2.1-1-1.8-2.2v-0.3c-1.4,2.9-2.3,5.9-2.9,9.1
	l0.1-0.5c0.6-1,1.4-1,2.6-0.4l23.7,8.4L157.8,387.6L157.8,387.6z"
          />
          <path
            fill="#EBEBEB"
            d="M157.8,388.2v-0.5l-0.6-0.1l-4.1,3c-0.1,1.2,0.1,2.5,0.7,3.7l0.3,0.7c0.4,1,1.1,1.9,1.9,2.9h5.2l0.1-0.3
	C158.9,394.8,157.8,391.6,157.8,388.2L157.8,388.2z"
          />
          <path
            fill="#656565"
            d="M143.1,386.6l-5.2-2.1c-1.1-0.5-1.9-0.3-2.3,0.8v0.4c0,0.8-0.1,1.5-0.1,2.3c0,2.1,0.1,4,0.4,5.9
	c2.6,1.6,5.1,2.9,7.7,3.9L143.1,386.6L143.1,386.6z"
          />
          <path
            fill="#656565"
            d="M135.8,393.9c0.6,3.9,1.6,7.4,3.4,10.9c3.9,1.6,7.4,2.3,10.6,2.1l3-4.5c-0.7-1-1.2-1.8-1.8-2.8
	c-2.5-0.1-5.1-0.7-7.6-1.6S138.4,395.6,135.8,393.9z"
          />
          <path
            fill="#EBEBEB"
            d="M148.3,388.6l-5.2-1.9l0.6,11.3c2.5,0.8,5,1.4,7.6,1.6C149.3,396.1,148.3,392.6,148.3,388.6L148.3,388.6z
	 M151.9,425.2l-0.7-0.7c-0.7-0.8-0.6-1.6,0.4-2.6l1.6-2.2c-1.8-1.1-3.4-2.3-5-3.6l-1.5,2.2c-0.7,1.4-1.5,1.8-2.6,1.1l-0.3-0.1
	C146.4,421.6,149.1,423.5,151.9,425.2L151.9,425.2z"
          />
          <path
            fill="#656565"
            d="M233.4,384.6l0.1,3.6c0,5.8-0.7,11.4-2.1,16.6h8.7l1.1-1.4h317.6v-7.2H248l-0.3-4.1L233.4,384.6L233.4,384.6
	z"
          />
          <path fill="#FFFFFF" d="M247.6,392l0.3,4.1h9.6V396l-0.5-7h-9.6" />
          <path
            fill="#FFFFFF"
            d="M266.3,389H257l0.5,7v0.1h301.4l0.6-7.2H444.9"
          />
          <path
            fill="#EBEBEB"
            d="M91.3,294.9c0.4-3.7-0.4-5.6-2.5-5.8H66.6l-18,1.9l-2.5,10.5l-1.8,11.7l-1.2,13.5v0.1l-0.3,3l3.4-0.5h0.3
	l15.4-1.8h0.7l1.8-0.1l19.8-1.1L91.3,294.9L91.3,294.9z M87.9,289.9c2.1,0.3,2.6,1.9,1.8,5.1l-6.5,29.2l-6.1,0.5l5.9-34.3
	L87.9,289.9L87.9,289.9z M70.7,294.9v-2.1l9.9-1l-4,23l-17.9-0.7v-18.6L70.7,294.9L70.7,294.9z M76.6,316.8l-0.7,8.3l-15.8,1.4
	l-10.7,0.8l-1.8,0.1c0.4-0.8,0.7-1.9,0.7-3.4v-3.6c0-1.1,0.3-1.8,1.2-2.1h0.3L76.6,316.8L76.6,316.8z"
          />
          <path
            fill="#EBEBEB"
            d="M89.7,295c0.8-3.2,0.3-5-1.8-5.1l-4.8,0.5l-5.9,34.3l6.1-0.6L89.7,295L89.7,295z M70.7,292.8v2.1l-12,0.7
	v18.7l18,0.7l4-23.1 M75.9,325l0.7-8.3l-27.1,1.5h-0.3c-0.8,0.3-1.2,1-1.2,2.1v3.6c-0.1,1.5-0.3,2.6-0.7,3.4l1.8-0.1l10.7-0.8
	L75.9,325L75.9,325z M69.5,321.6c0,0.7-0.1,1.2-0.6,1.6c-0.3,0.4-0.8,0.7-1.2,0.7c-0.6,0-1-0.3-1.2-0.7c-0.3-0.4-0.6-1-0.6-1.6
	s0.1-1.2,0.6-1.6c0.3-0.4,0.8-0.7,1.2-0.7c0.6,0,1,0.3,1.2,0.7C69.2,320.3,69.5,320.9,69.5,321.6L69.5,321.6z M52.3,320.5
	c0.3-0.1,0.5-0.3,0.8-0.3l0.5,0.1l0.3,0.1l0.4,0.4l0.1,0.1l0.4,0.7l0.1,0.7v0.3c0,0.5-0.1,1.1-0.4,1.5l-0.1,0.1
	c-0.3,0.4-0.7,0.5-1,0.7h-0.3l-0.7-0.3l-0.3-0.1l-0.3-0.3c-0.3-0.4-0.5-1-0.5-1.6s0.1-1.2,0.5-1.6l0.3-0.3L52.3,320.5L52.3,320.5z"
          />
          <path
            fill="#EBEBEB"
            d="M68.9,323.2c0.3-0.4,0.6-1,0.6-1.6s-0.1-1.2-0.6-1.6c-0.3-0.4-0.8-0.7-1.2-0.7c-0.6,0-1,0.3-1.2,0.7
	c-0.3,0.4-0.6,1-0.6,1.6s0.1,1.2,0.6,1.6c0.3,0.4,0.8,0.7,1.2,0.7C68.1,323.9,68.5,323.6,68.9,323.2L68.9,323.2z M53,320.2
	c-0.3,0-0.5,0.1-0.8,0.3l-0.3,0.1l-0.3,0.3c-0.3,0.4-0.5,1-0.5,1.6s0.1,1.2,0.5,1.6l0.3,0.3l0.3,0.1l0.7,0.3h0.3
	c0.4-0.1,0.7-0.3,1-0.7l0.1-0.1c0.3-0.4,0.4-1,0.4-1.5v-0.3l-0.1-0.7l-0.4-0.7l-0.1-0.1l-0.4-0.4l-0.3-0.1L53,320.2L53,320.2
	L53,320.2z"
          />
          <path
            fill="#FFFFFF"
            d="M116.5,325l-11.3,0.4c-1.5,2.5-2.9,5.2-4.1,8.1c-1.5,3.7-2.6,7.6-3.4,11.8c-1.4,6.1-2.6,12.2-3.7,18.7
	l-62.3,1.8l0.4,1.5c4.3,11,9.9,20.9,16.6,30l14.6,1l16.8,2.8h19.5v-2.8c0.3-5.4,0.7-10.6,1.2-15.7c1.1-11,2.6-21.5,4.7-31.2l0.8-4.1
	C108.1,338.2,111.4,330.8,116.5,325L116.5,325z M62.5,386.6l-0.1,0.7c-0.4,1.4-1.5,2.2-3.6,2.8l-4.5,0.7c-2.1,0.4-3.7-0.3-5-1.9
	c-4.4-6.3-7.6-11.8-9.2-16.5c-0.1-1.8,0.5-2.6,2.1-2.3h6.6c2.2,0,4.3,0.7,6.2,2.2l0.5,0.4l0.5,0.7c3.2,3.6,5.2,7.4,6.3,11.3l0.3,1.4
	L62.5,386.6L62.5,386.6z"
          />
          <path
            fill="#FFFFFF"
            d="M62.5,386.6h-0.3c-0.8,0-1.7-0.7-2.8-2.1s-1.9-3-2.8-5c-0.7-1.9-1.1-3.6-1-5.1c0-0.6,0.1-1,0.3-1.4l-0.5-0.7
	l-0.5-0.4c-0.4,0.4-0.5,1-0.7,1.9c-0.1,1.5,0.1,3.3,1,5.5c0.8,2.2,1.8,4,3.2,5.5c1.2,1.5,2.5,2.3,3.4,2.3l0.8-0.1L62.5,386.6
	L62.5,386.6z"
          />
          <path
            fill="#EBEBEB"
            d="M62.2,386.6h0.3V386l-0.3-1.4c-1.1-4-3.2-7.7-6.3-11.3c-0.1,0.3-0.3,0.8-0.3,1.4c0,1.4,0.3,3,1,5.1
	c0.8,1.9,1.7,3.6,2.8,5C60.5,386,61.5,386.6,62.2,386.6L62.2,386.6z"
          />
          <path
            fill="#FFFFFF"
            d="M62.3,387.2l-0.8,0.1c-1,0-2.1-0.8-3.4-2.3c-1.2-1.5-2.3-3.3-3.2-5.5c-0.8-2.2-1.1-4-1-5.5
	c0.1-1,0.3-1.5,0.7-1.9c-1.8-1.5-3.9-2.2-6.2-2.2h-6.6c-1.5-0.3-2.2,0.5-2.1,2.3c1.8,4.7,4.8,10.2,9.2,16.5c1.4,1.7,3,2.3,5,1.9
	l4.5-0.7C60.8,389.5,61.9,388.6,62.3,387.2L62.3,387.2z M95.2,337c2.3-0.1,4.3-1.2,5.9-3.4c1.1-2.9,2.5-5.5,4.1-8.1l-21,0.8
	l-19.8,1.1l-1.8,0.1h-0.8l-15.4,1.8h-0.3l-3.4,0.5l-2.5,1.6l-0.3,0.3l-0.1,0.1l-0.8,0.5l-0.3,0.3l-0.4,0.4l-0.1,0.1l-1.5,1.4
	l-0.1,0.3l-0.3,0.3l-0.3,0.4l-0.8,1l-0.1,0.3l-0.5,0.7l-1.9,2.9l-0.1,0.1h0.1l7.7-1.2l8-0.7h0.4l3.7-0.1h0.3L95.2,337L95.2,337z"
          />
          <path
            fill="#656565"
            d="M125.7,347.9c-5.8,0-12.4,1.2-19.9,3.6c-2.1,9.9-3.6,20.2-4.7,31.2l10.5-5.1
	C113.4,366.4,118,356.5,125.7,347.9L125.7,347.9z"
          />
          <path
            fill="#FFFFFF"
            d="M48.6,291l-2.5,0.3l-2.8,11.6l-0.5,2.8l-0.1,0.5l-0.8,5l-0.3,1.5c-0.3,1.9-0.5,4-0.7,6.1l-0.1,1.5
	c-0.1,1.8-0.3,3.7-0.4,5.6v2.2l-0.1,3.6l2.5-1.6l0.3-3V327l1.2-13.5l1.8-11.7L48.6,291L48.6,291z M44,291.9h1l-1.2,4.7l-0.3,1.2
	l-1.1,5v0.1h0.1l0.8-0.1l2.8-11.6l-0.3-2.3H33.3l-0.8,2.8l4.3,0.1h2.3l0.5,0.1h4.3"
          />
          <path fill="#656565" d="M45,291.9h-1l-0.5,1.4l0.3,3.3" />
          <path
            fill="#FFFFFF"
            d="M43.1,291.9l0.1,1.4l-1.5,3.3l-0.3-3.3l0.7-1.4h-0.3l-0.5,1l-0.1-1h-0.3l0.1,1.4l-1.5,3.3l-0.3-3.3l0.7-1.4
	h-0.3l-0.4,1l-0.1-1h-0.3l0.1,1.5l-1.5,3.3l-0.3-3.3l0.7-1.5h-0.3L37,293l-0.1-1.1h-0.1v0.1l0.1,1.4c-0.5,1.1-1.1,2.2-1.5,3.3
	l-0.1-1l-0.1,0.7v0.7l-0.8,1.8l-0.3,1.2l1.1-2.6l0.4,3.3l-1.2,2.8h0.3l1-2.3l0.3,2.2h0l-0.4-2.6l1.5-3.3l0.4,3.3l-1.1,2.6h0.3
	l0.8-2.1l0.3,2.1h0.3l-0.3-2.5l1.5-3.3l0.4,3.3l-1,2.5H39l0.8-1.9l0.3,1.9h0.3l-0.3-2.3c0.4-1.1,1-2.2,1.5-3.3l0.4,3.3l-1,2.2h0.3
	l0.8-1.8l0.3,1.8h0.1v-0.1l-0.3-2.1l1.4-3l0.3-1.2l-0.3-3.3l0.7-1.4H44l-0.4,1l-0.1-1L43.1,291.9L43.1,291.9z M38.9,293.7
	c0.1,1.1,0.3,2.2,0.3,3.3l-1.5,3.3l-0.4-3.3L38.9,293.7z M41.7,297l1.5-3.3l0.3,3.3l-1.5,3.3L41.7,297z M41.1,293.7l0.3,3.3
	l-1.5,3.3l-0.4-3.3L41.1,293.7L41.1,293.7z M36.9,293.7l0.3,3.3l-1.5,3.3l-0.4-3.3L36.9,293.7L36.9,293.7z"
          />
          <path
            fill="#656565"
            d="M39.3,297c-0.1-1.1-0.3-2.2-0.3-3.3l-1.5,3.3l0.4,3.3C38,300.3,39.3,297,39.3,297z M43.2,293.6l-1.5,3.3
	l0.4,3.3l1.5-3.3 M41.4,297l-0.3-3.3l-1.7,3.3l0.4,3.3 M37.1,297l-0.3-3.3l-1.5,3.3l0.3,3.3 M43.2,293.2l-0.1-1.4H42l-0.7,1.4
	l0.4,3.3 M43.3,292.8l0.5-1h-0.5 M38.8,291.7h-1l-0.8,1.5l0.3,3.3l1.7-3.3 M39.6,291.9l-0.5-0.1v1.1 M39.9,291.9l-0.7,1.4l0.3,3.3
	l1.5-3.3l-0.1-1.4 M41.1,291.9l0.1,1l0.4-1 M40.3,303h0.5l1-2.2l-0.4-3.3c-0.5,1.1-1,2.2-1.5,3.3L40.3,303L40.3,303z"
          />
          <path
            fill="#FFFFFF"
            d="M40.9,303H40l-1.1,0.1h-1.1l-1.2,0.1h-0.8l-1.2,0.1h-1.4l-4.5,0.3l-0.5,2.6l-0.1,1.8l-0.4,5.5l-1.1,0.3
	c-0.1,1.4,0,2.2,0.4,2.3l0.3,0.1h0.4l0.1-0.3c0.5-0.8,0.8-2.1,1.2-3.4l0.8-6.5l12.2-0.7h1l0.5-2.8l-0.8,0.1h-0.1l-1,0.1L40.9,303
	L40.9,303L40.9,303z"
          />
          <path
            fill="#656565"
            d="M41.1,303l1.1-0.1l-0.3-1.8 M42.1,300.7l0.3,2.1l1.1-5 M38.2,303.1h0.4l1.1-2.5l-0.4-3.3l-1.5,3.3
	 M38.9,303.1L40,303l-0.3-1.9 M38,303.1l-0.3-2.1l-1,2.2"
          />
          <path fill="#FFFFFF" d="M42.6,306l0.1-0.5h-0.1" />
          <path
            fill="#656565"
            d="M42.6,306l-0.1-0.5h-0.4l-0.5,1.5l-0.3,1.9l0.4,2.5l0.1-0.4C42.2,309.3,42.4,307.7,42.6,306L42.6,306z
	 M41.8,305.6v0.3V305.6"
          />
          <path
            fill="#FFFFFF"
            d="M41.8,305.9v-0.4l-12.2,0.7l-0.8,6.5c-0.3,1.4-0.7,2.5-1.2,3.4l-0.1,0.3l3.6-0.3h1l1.5-0.1H34l1.7-0.1H36
	l1.7-0.1h0.4l1.8-0.1h0.3l0.1-0.8c0.3-1.8,0.4-3.4,0.7-5.2l0.1-0.7l0.3-1.9L41.8,305.9L41.8,305.9z"
          />
          <path
            fill="#FFFFFF"
            d="M42,305.6l-0.1,0.3l-0.3,1.1l0.7-1.4 M41.7,312.5L42,311l-0.1,0.4l-0.5-2.5l-0.1,0.7l0.4,2.2l-1.1,3
	l-0.1,0.8v0.5l0.7,3.2l-1.2,3.3v-0.1l-0.1,1.1l0.1-0.1l0.7,3v-0.8l-0.5-2.6l1-2.9l0.1-1.6l-0.5-2.9l1.2-3.3"
          />
          <path
            fill="#656565"
            d="M41.5,311.8l-0.4-2.2c-0.3,1.6-0.5,3.4-0.7,5.2L41.5,311.8z M41.7,312.5v-0.3l-1.2,3.3l0.7,2.9
	C41.3,316.5,41.4,314.6,41.7,312.5z M37,292.8l0.5-1.1h-0.7 M36.7,293.2l-0.1-1.4c-0.5,1.1-1.1,2.3-1.5,3.7l0.1,1
	C35.8,295.4,36.2,294.3,36.7,293.2L36.7,293.2z M34.9,296.3l-0.7,2.5l0.7-1.8 M35.1,297.4l-1.2,2.6l-0.1,1.1l0.3,2.3h0.3l1.2-2.8
	 M36,303.3h0.4l1.1-2.6l-0.3-3.3l-1.5,3.3 M35.8,303.3l-0.3-2.2l-1,2.3"
          />
          <path fill="#FFFFFF" d="M33.7,303.4H34l-0.3-2.3l-0.1,0.8" />
          <path fill="#656565" d="M33.7,303.4l-0.1-1.5l-0.4,1.5" />
          <path fill="#FFFFFF" d="M28.8,303.8l-1.6,1.1l-0.1,1.8l1.2-0.3" />
          <path
            fill="#FFFFFF"
            d="M28.1,308.1l0.1-1.6l-1.2,0.3l-0.4,7.3l1-0.3 M36.2,315.9h-0.4l-1.1,2.9l-0.5-2.9h-0.3
	c0.1,1.1,0.4,2.2,0.7,3.3l-1.1,3.3l-0.8-3.3l1.2-3.2h-0.3l-1,2.8l-0.5-2.8h-0.3l0.6,3l-1.1,3.3l-0.5-2.5l-0.1,0.8l0.4,1.9l-0.7,2.1
	v0.8l0.8-2.5l0.8,3.3l-1,3.3l-0.7-2.6v0.8l0.5,2.1l-0.5,2.1v0.8l0.7-2.3l1,3.3l-0.8,3.3l-0.7-2.2v1l0.5,1.8l-0.1,0.4l0.4-0.1
	l-0.1-0.3l1-3.3l1,3.2l0.4-0.1c0.3-1,0.5-2.1,0.8-3l0.7,2.1l0.1-0.3l-0.7-2.3l1-3.3l1,3.3l-0.4,1.4l0.3-0.4l0.1-0.5l0.1,0.3l0.1-0.3
	l-0.1-0.5l1-3.3l0.7,2.5l0.1-0.1l-0.8-2.8l1.1-2.8l0.8,3.3v-1l-0.7-2.9l1.1-3.2l0.1-1.2l-0.7-3.2l1.2-3.3v0.1v-0.6h-0.3l-1.2,3.2
	l-0.7-3h-0.4l-1.1,3L36.2,315.9L36.2,315.9z M34.8,319.4l1.2-3.3l0.7,3.3l-1.1,3.3L34.8,319.4z M34.5,319.8l0.7,3.3l-1.1,3.3
	l-0.8-3.3L34.5,319.8z M36.7,319.8l0.7,3.3l-1.1,3.3l-0.8-3.3L36.7,319.8L36.7,319.8z M37.7,322.7l-0.7-3.3l1.2-3.3l0.7,3.3
	L37.7,322.7z M31.2,323.1l1.1-3.3l0.8,3.3l-1.1,3.3L31.2,323.1z M37.3,330.1l-0.8-3.3c0.3-1.1,0.7-2.2,1.1-3.3l0.8,3.3L37.3,330.1
	L37.3,330.1z M36.3,327.2l0.8,3.3l-1,3.3l-1-3.3L36.3,327.2L36.3,327.2z M36.2,326.8l-1,3.3l-0.8-3.3l1.1-3.3L36.2,326.8L36.2,326.8
	z M33.2,330.5l1.1-3.3l0.8,3.3l-1,3.3L33.2,330.5L33.2,330.5z M34.1,326.8l-1.1,3.3l-0.8-3.3l1.1-3.3L34.1,326.8L34.1,326.8z
	 M31,330.5l1.1-3.3l0.8,3.3l-1,3.3L31,330.5L31,330.5z M34,334.2l-0.8,3.3l-1-3.3l1-3.3L34,334.2z M37.7,323.1l1.2-3.3l0.7,3.3
	l-1.1,3.3L37.7,323.1z"
          />
          <path
            fill="#656565"
            d="M35.9,316.1l-1.1,3.3l0.7,3.3l1.1-3.4 M35.4,323.1l-0.8-3.3l-1.1,3.3l0.8,3.3 M37.4,323.1l-0.7-3.3l-1.1,3.3
	l0.7,3.3 M36.9,319.4l0.8,3.3l1.1-3.3l-0.7-3.3 M32.5,319.8l-1.2,3.3l0.8,3.3l1.1-3.3 M36.5,326.8l0.8,3.3l1-3.3l-0.8-3.3
	C37.1,324.6,36.9,325.7,36.5,326.8L36.5,326.8z M37.1,330.5l-0.8-3.3l-1,3.3l0.8,3.3 M35.2,330.1l1-3.3l-0.7-3.3l-1.2,3.3
	 M34.3,327.2l-1.1,3.3l1,3.3l1-3.3 M33,330.1l1.1-3.3l-0.8-3.3l-1.1,3.3 M32.1,327.2l-1.1,3.3l1,3.3l1-3.3 M33,337.5l1-3.3l-1-3.3
	l-1,3.3 M38.9,319.8l-1.2,3.3l0.8,3.3l1.1-3.3 M33.8,316.1l-1.2,3.2l0.8,3.3l1.1-3.3C34.3,318.3,34,317.2,33.8,316.1L33.8,316.1z
	 M34.1,316.1l0.5,2.9l1.1-3 M36.2,315.9l0.5,2.9l1.1-3 M32.5,319l1.1-2.9l-1.6,0.1"
          />
          <path fill="#FFFFFF" d="M31.6,316.2h-0.3l-0.4,1.1l-0.1,1" />
          <path
            fill="#656565"
            d="M31.8,316.2h-0.1l-0.8,2.1l-0.1,1.9l0.5,2.5l1.1-3.4 M31.4,316.2h-0.3l-0.1,1.1 M30.5,321l-0.3,4.1L31,323
	 M31.1,323.5l-0.8,2.5v1.5l0.7,2.6l1-3.3 M30.3,328.5l-0.1,4l0.5-1.9 M30.1,333.4l0.1,1.9l0.7,2.2l0.8-3.3l-1-3.3"
          />
          <path
            fill="#FFFFFF"
            d="M28.1,339H28c-0.3,0.1-0.5,0.1-0.7,0.3l-0.1,0.1c-0.3,0.3-0.3,0.7-0.4,1.1l0.3,1.5l5.2-1.6l0.1-0.1l1.9-2.9
	l-1,0.3l-0.4,0.1l-1.8,0.4l-0.4,0.1l-0.3,0.1L28.1,339L28.1,339z"
          />
          <path
            fill="#656565"
            d="M31.1,337.9v0.3l1.8-0.4l-1-3.2 M30.3,336.2v2.3l0.4-0.1l0.1-0.4 M36,335.2l0.3-0.1l-0.1-0.4 M36.3,334.2
	l0.1,0.5l1.5-1.4l-0.7-2.5 M35.8,335.6l0.3-1.4l-0.8-3.3l-1,3.3l0.7,2.3 M34.3,337.5l0.5-0.7l-0.7-2.1c-0.3,1-0.5,2.1-0.8,3
	L34.3,337.5L34.3,337.5z"
          />
          <path
            fill="#FFFFFF"
            d="M27.2,343.9l4-1l1-2.3l-5.2,1.6 M28.3,361.6l-4,0.5l-2.6,3.3c-0.3,0.4-0.5,0.8-0.7,1.5h5.2
	c1.5-0.1,2.6,1.1,3.3,3.7c2.3,7.7,6.7,16,13.3,24.9l0.3,0.4c1,1.4,1,2.5-0.3,3.2c-1.7,0.7-1.9,1.6-1.1,3l3,3.7l1.9-0.1l7.6-1.5
	c-1.9-2.2-3.9-4.5-5.6-6.9c-6.9-8.9-12.4-19-16.6-30l-0.4-1.5C30.8,363.1,29.6,361.6,28.3,361.6L28.3,361.6z"
          />
          <path
            fill="#656565"
            d="M40.3,316.2v-0.1l-1.2,3.3l0.7,3.2C39.9,320.3,40,318.3,40.3,316.2z M40.2,315.7l-1.8,0.1l0.5,3.2"
          />
          <path
            fill="#656565"
            d="M41,319.4l-0.7-3.2c-0.3,2.1-0.4,4.1-0.5,6.3v0.1L41,319.4L41,319.4z M40.4,325.7c0.1-1.9,0.3-3.7,0.4-5.6
	l-1,3L40.4,325.7L40.4,325.7z M39.8,323.5l-0.1,0.1l-0.3,5.9l0.1,0.5l1-3v-0.5"
          />
          <path
            fill="#FFFFFF"
            d="M40.4,327.9v-0.8l-1,3l-0.1-0.5v1.9l0.1-0.5l0.3,1l0.1-0.1l-0.3-1.2"
          />
          <path fill="#656565" d="M39.1,332.6l0.7-0.7l-0.3-1l-0.1,0.5" />
          <path fill="#FFFFFF" d="M39.1,332.6l0.3-1.1v-1l-0.7,2.3" />
          <path
            fill="#656565"
            d="M39.9,331.8l0.4-0.3l0.1-3.6l-0.8,2.6 M39.3,330.5l-0.8-3.3l-1.1,3.3l0.8,2.8l0.4-0.4 M38.7,326.8l0.7,2.8
	l0.3-5.9"
          />
          <path
            fill="#FFFFFF"
            d="M49,397.4c1.8,2.3,3.6,4.7,5.6,6.9c7.8-1.4,16.4-2.5,25.9-3.2l-16.8-2.8L49,397.4L49,397.4z M43.3,396
	l-0.3-0.4c-6.6-8.9-11-17.2-13.3-24.9c-0.7-2.6-1.8-3.9-3.3-3.7h-5.2c-0.1,0.8-0.1,1.6,0.1,2.8l1.1,4.5h1.2
	c5.1,12.8,11.3,22.8,18.7,30c0.8,0.8,1.7,1.4,2.6,1.5l-3-3.7c-0.8-1.4-0.5-2.3,1.1-3C44.3,398.5,44.3,397.4,43.3,396L43.3,396z
	 M40.2,393.8l-1.9,0.4c-1.1,0.4-1.9,0.1-2.8-1c-3.2-4.3-6.2-9.8-8.9-16.5c-1.1-2.5-1.1-3.9,0-3.9h2.6
	C31.1,379.1,34.7,386.1,40.2,393.8z"
          />
          <path
            fill="#656565"
            d="M38.2,394.2l1.9-0.4c-5.5-7.7-9.1-14.7-11-21h-2.6c-1.1,0.1-1.1,1.4,0,3.9c2.8,6.7,5.8,12.2,8.9,16.5
	C36.2,394.3,37.1,394.6,38.2,394.2L38.2,394.2z"
          />
          <path
            fill="#FFFFFF"
            d="M43.9,412.6v4.7l56,0.5V401H80.3c-9.4,0.7-18,1.8-25.9,3.2l-7.6,1.5C44.8,407.8,43.9,410.2,43.9,412.6
	L43.9,412.6z M595.8,310.3v-8.5l-1.5,0.3c-19.9,5.5-31.8,18.8-35.8,39.9c-3.3,14.3-5.8,30-7.4,46.8h8.4c0.1-2.1,0.4-4.1,0.7-6.2
	c1.1-10.7,2.8-20.8,4.5-30.1c0.5-2.9,1.2-5.8,1.9-8.5v-0.1C569.9,326.4,579.6,315.2,595.8,310.3L595.8,310.3z"
          />
          <path
            fill="#656565"
            d="M589.5,344.7l0.5-0.4l-25.2,8.4c-1.9,9.4-3.4,19.5-4.5,30.1l12.5-7.7C575,363.5,580.7,353.5,589.5,344.7
	L589.5,344.7z"
          />
          <path
            fill="#656565"
            d="M571.4,389v-0.8c0-4.5,0.4-8.9,1.2-13.1l-12.5,7.7c-0.3,2.1-0.4,4.1-0.7,6.2L571.4,389L571.4,389z"
          />
          <path
            fill="#656565"
            d="M558.9,396.1H572c-0.3-2.3-0.4-4.8-0.6-7.3h-12L558.9,396.1z"
          />
          <path
            fill="#FFFFFF"
            d="M166.7,255.1l29.9-2.8c2.8-0.3,7,0.7,12.7,2.8c-11.8,0.4-23.5,1.4-35.1,2.8c-38,4-71.8,10.2-101.7,18.4
	l-0.3,0.1l-11.6,3.3l-1.2,0.4l-4,1.9l-0.4,0.3l-3.6,2.6l-2.5,2.5l-3,3.9l2.3-0.3l18-1.9h0.3l1.7-4c0.6-1.1,1.1-2.1,1.8-3l1-1.1
	c1.9-2.2,4.1-3.9,6.7-4.7c17.6-4.8,42.2-9.2,73.7-13.1c23.9-2.8,49.9-4.8,78-6.5l-12.4-2.9c-7.7-1.8-12.5-2.8-14.4-2.8
	c-26.7,1.4-51.3,3.3-73.7,5.8c-16.2,1.9-30.4,3.9-42.5,5.8c-5.5,0.8-10.5,1.8-15.1,2.8l-6.6,1.4l-8.8,2.2l-8.9,2.8l-0.5,0.3h-0.1
	l6.3,0.1l7-0.5l6.9-1.1l2.8-0.7h0.1l5.8-1.2C102.1,263.1,132.5,258.7,166.7,255.1L166.7,255.1z"
          />
          <path
            fill="#FFFFFF"
            d="M196.6,252.4l-29.9,2.8c-34.3,3.4-64.7,7.8-91.3,13.2l-5.8,1.2h-0.1l-2.8,0.7l-6.9,1.1l-7,0.5l-6.3-0.1
	c-0.5,0.3-1,0.4-1.4,0.7l-1.7,1.2v0.1l-2.6,2.9l-2.3,3.2c-0.7,1-1.2,1.9-1.8,2.9l-2.2,4.1c-0.3,0.7-0.7,1.2-1,1.9H46l0.3,2.3l3-3.9
	l2.5-2.5l3.6-2.6l0.4-0.3l4-1.9l1.2-0.4l11.6-3.3l0.3-0.1c29.9-8.3,63.7-14.3,101.7-18.4c11.4-1.5,23.1-2.3,35.1-2.8
	C203.6,253.1,199.3,252.2,196.6,252.4L196.6,252.4z M101.2,333.5c-1.7,2.2-3.6,3.3-5.9,3.4l-42.8,1.5h-0.3l-3.7,0.1h-0.4l-8,0.7
	l-7.7,1.2h-0.1l-1,2.5l-1.1,2.6l-0.4,0.7l-0.1,0.1L29,347l-1.1,0.5l-5.6,2.2v12.8l2.3-0.3l4-0.5c1.4,0,2.5,1.4,3.4,4.3l62.3-1.8
	c1.1-6.5,2.3-12.7,3.7-18.7C98.6,341.1,99.7,337.1,101.2,333.5L101.2,333.5z"
          />
          <path
            fill="none"
            d="M38.7,332.9l0.7-2.3l-0.8-3.3l-1.1,3.3l0.8,2.8 M40.4,327.9l-0.8,2.6l0.3,1.2 M39.1,332.6l0.3-1.1l0.1-0.5
	l0.3,1 M37.7,323.1l0.8,3.3l1.1-3.3l-0.7-3.3 M40.9,320.2l-1,2.9l0.5,2.6 M40.4,326.5l-0.7-3l-0.1,0.1l-1,3.2l0.7,2.8l0.1,0.5l1-3
	 M38.4,315.8l0.5,3.2l1.2-3.3 M40.3,316.2l0.7,3.2l-1.2,3.3v-0.1l-0.7-3.2l1.2-3.3 M33,337.5l-1-3.3l1-3.3l1,3.3 M34.8,336.8
	l-0.7-2.1c-0.3,1-0.5,2.1-0.8,3 M36.3,335.1l-0.1-0.4l-0.1,0.5 M35.8,335.6l0.3-1.4l-0.8-3.3l-1,3.3l0.7,2.3 M38,333.4l-0.7-2.5
	l-1,3.3l0.1,0.5 M30.1,333.4l0.7-2.5l1,3.3l-0.8,3.3l-0.7-2.2 M30.3,336.2l0.5,1.8l-0.1,0.4 M32.9,337.8l-1-3.2l-0.8,3.3v0.3
	 M32.1,327.2l0.8,3.3l-1,3.3l-1-3.3 M30.3,328.5l0.4,2.1l-0.5,1.9 M33,330.1l-0.8-3.3l1.1-3.3l0.8,3.3 M34.3,327.2l0.8,3.3l-1,3.3
	l-1-3.3 M35.2,330.1l-1-3.3l1.2-3.3l0.7,3.3 M37.1,330.5l-1,3.3l-0.8-3.3l1-3.3 M36.5,326.8c0.3-1.1,0.7-2.2,1.1-3.3l0.8,3.3l-1,3.3
	L36.5,326.8L36.5,326.8z M32.5,319.8l0.7,3.3l-1.1,3.3l-0.8-3.3 M36.9,319.4l1.2-3.3l0.7,3.3l-1.1,3.3 M37.4,323.1l-1.1,3.3
	l-0.7-3.3l1.1-3.3 M35.4,323.1l-1.1,3.3l-0.8-3.3l1.1-3.3 M30.3,326l0.8-2.5l0.8,3.3l-1,3.3l-0.7-2.6 M30.5,321l0.5,2.1l-0.8,2.1
	 M30.7,320.2l0.5,2.5l1.1-3.4l-0.5-3 M30.8,318.3l0.8-2.1 M31.4,316.2l-0.4,1.1 M31.9,316.2l0.5,2.8l1.1-2.9 M37.8,315.8l-1.1,3
	l-0.5-2.9 M35.9,316.1l0.7,3.2l-1.1,3.4l-0.7-3.3 M34.1,316.1l0.5,2.9l1.1-3 M33.8,316.1l-1.2,3.2l0.8,3.3l1.1-3.3
	C34.3,318.3,34,317.2,33.8,316.1 M33.7,301.1l0.3,2.3 M33.7,303.4l-0.1-1.5 M35.8,303.3l-0.3-2.2l-1,2.3 M36.5,303.3l1.1-2.6
	l-0.4-3.3l-1.4,3.3l0.3,2.6 M36.9,293.6l-1.5,3.3l0.3,3.3l1.5-3.3 M34.3,303.4l1.2-2.8l-0.4-3.3l-1.2,2.6 M34.3,298.7l0.7-1.8v-0.7
	 M35.1,295.6l0.1,1c0.5-1.1,1-2.2,1.5-3.3l-0.1-1.4 M36.9,291.7l0.1,1.1l0.5-1.1 M42.2,305.6l-0.7,1.4 M41.7,312.5v-0.1l-1.2,3.3
	l0.5,2.9 M41.1,309.6l0.4,2.2l-1.1,3 M42,311l-0.1,0.4l-0.5-2.5 M42,305.6l-0.1,0.3 M42.6,306v-0.5 M38,303.1l-0.3-2.1l-1,2.2
	 M38.7,303.1l1.1-2.5l-0.4-3.3l-1.5,3.3l0.4,2.5 M40,303l-0.3-1.9l-0.8,2.1 M40.3,303l-0.3-2.3c0.4-1.1,1-2.2,1.5-3.3l0.4,3.3
	l-1,2.2 M42.2,302.9l-0.3-1.8l-0.8,1.9 M43.5,297.8l-1.4,2.9l0.3,2.1 M41.4,297l-1.5,3.3l-0.4-3.3l1.7-3.3 M43.2,293.6l0.4,3.3
	l-1.5,3.3l-0.4-3.3 M38.9,293.6l-1.5,3.3l0.4,3.3l1.5-3.3L38.9,293.6L38.9,293.6z M41.1,291.9l0.1,1l0.4-1 M39.9,291.9l-0.7,1.4
	l0.3,3.3l1.5-3.3l-0.1-1.4 M39.1,291.7v1.1l0.5-1 M37.8,291.7l-0.8,1.5l0.3,3.3l1.7-3.3l-0.1-1.5 M44,291.9l-0.5,1.4l0.3,3.3
	 M43.3,291.9v1l0.5-1 M42,291.9l-0.7,1.4l0.4,3.3l1.5-3.3l-0.1-1.4 M783.2,323.8v-11.6 M784.8,291.2c-1.1-0.1-1.9-0.5-2.6-1.2
	 M785.1,278c-2.9,0.8-4.3,2.2-3.9,4.4c-0.5,3.7-0.3,6.2,1.1,7.6 M783.2,312.2l6.1,0.5 M789.5,290.9c-0.1-4.4-0.3-8.7-0.4-12.7
	 M785.1,278c-1.2-0.3-2.3-1-3.3-1.8 M789.5,290.9l-3.6,0.3 M783,309.7c0.8,1.2,1.9,2.1,3.3,2.3l3,0.7 M784.8,291.2h1.1 M789.2,312.8
	c0.3-7.7,0.4-15,0.3-21.9 M783.2,312.2v-0.1 M769.5,311.1l13.6,1.1"
          />
          <path
            fill="none"
            d="M782.3,289.9l-13.3,1.2h15.8 M785.9,291.2c-3.4,1.2-5.1,4.4-4.7,9.5c0,4.1,0.5,7.2,1.8,9.1 M783,309.7
	l-13.5,1.4 M781.8,276.2l-12.2,1.8h15.5 M781.8,276.2c-2.3-2.2-3.3-6.3-2.5-12.1c-0.1-4.5,2.3-7,7.2-7.2 M788.9,278.2l-3.8-0.3
	 M225.5,247.3l19.1,1c3,0.1,5.5-0.7,7.4-2.3c21.3-16.8,43.9-33.4,68-50.2c4.4-2.1,5.6-3.9,3.6-5.2l-6.7-3.7 M87.9,289.9l-4.8,0.5
	l-5.9,34.3l6.1-0.5l6.5-29.2C90.5,291.9,90,290.2,87.9,289.9L87.9,289.9z M70.7,294.9l-12,0.7v18.7l18,0.7l4-23.1l-10,1"
          />
          <path
            fill="none"
            d="M769.5,311.1c-2.6,0-3.8-0.5-3.6-1.6l-1.1-47.7c-0.1-2.9,0.8-4.5,2.8-4.7h18.8 M786.5,256.9h0.3
	c1.1,6.5,1.8,13.6,2.3,21.3 M76.6,316.8l-27.1,1.5h-0.3c-0.8,0.3-1.2,0.8-1.2,2.1v3.6c-0.1,1.5-0.3,2.6-0.7,3.4l1.8-0.1l10.7-0.8
	l15.8-1.4L76.6,316.8L76.6,316.8z"
          />
          <path
            fill="none"
            d="M69.5,321.6c0-0.7-0.1-1.2-0.6-1.6c-0.3-0.4-0.8-0.7-1.2-0.7c-0.6,0-1,0.3-1.2,0.7c-0.3,0.4-0.6,1-0.6,1.6
	s0.1,1.2,0.6,1.6c0.3,0.4,0.8,0.7,1.2,0.7c0.6,0,1-0.3,1.2-0.7C69.2,322.8,69.5,322.3,69.5,321.6z M52.3,320.5l-0.3,0.1l-0.3,0.3
	c-0.3,0.4-0.5,1-0.5,1.6s0.1,1.2,0.5,1.6l0.3,0.3l0.3,0.1l0.7,0.3h0.3c0.4-0.1,0.7-0.3,1-0.7l0.1-0.1c0.3-0.4,0.4-1,0.4-1.5v-0.3
	l-0.1-0.7l-0.4-0.7l-0.1-0.1l-0.4-0.4l-0.3-0.1l-0.5-0.1C52.7,320.2,52.4,320.3,52.3,320.5L52.3,320.5z M786.5,382.5l-68.2,9.2
	 M691.3,368.6c-2.5-7.4-6.3-14.3-11.8-20.5 M705.7,368.6c-0.8-7.3-1.9-14.2-3.2-20.5 M819.6,358.3l0.3,7.2 M817.4,365.7
	c-12.9-1.4-24.1-1.2-33.4,0.3l-68.2,4 M810.9,382.7l-6.9-0.5 M817.4,365.7l-13.3,16.4 M692.4,404.1h82c8.3,0.3,12.4-2.9,12.2-9.4
	v-12.1 M804.1,382.1l-2.6-0.1 M786.5,382.5l15-0.5 M816.4,372.1l3.4-6.6 M819.9,365.5l-2.5,0.3 M801.4,382l1,3.4h7.2l1.4-2.8
	 M810.9,382.7l5.5-10.6 M691.3,368.6h14.4 M718.2,391.7c-0.7-7.7-1.5-15-2.5-21.7 M718.2,391.7l-10.3,1.4c-0.4-8-1.1-15.5-1.9-22.6
	 M571.9,396.1c1.7,13.5,7.4,25.3,17.5,35.4c12,12,26.5,18,43.5,18s31.5-6.1,43.5-18c5.4-5.4,9.6-11.3,12.5-17.7l1.1-2.5
	c1-2.3,1.8-4.7,2.3-7.2 M692.4,404.1c1.4-5.1,1.9-10.5,1.9-16c0-6.9-1-13.3-3-19.5 M715.7,370l-9.9,0.5 M705.8,370.5l-0.1-1.9
	 M595.8,301.9c11.4-2.2,25.7-2.9,42.8-2.3h0.3c25.6-1.1,42.5,1.5,50.8,7.6c6.3,3.4,11.3,9.1,15,16.8 M704.6,323.8h57 M702.4,348.1
	h-22.8 M704.6,323.8h-9.9"
          />
          <path
            fill="none"
            d="M694.7,323.8c-2.5-4.1-5.6-7.4-9.5-9.6l-0.4-0.3c-5.8-4.1-16.8-6.3-33-6.5h-21.6c-13.2-0.1-24.5,0.7-33.7,2.6
	l-0.7,0.1 M702.4,348.1c-1.2-5.9-2.6-11.4-4.1-16.5c-1-2.9-2.2-5.5-3.7-7.8 M715.7,370c-2.2-16-5.2-29.7-8.9-41.1v-0.1
	c-0.7-1.6-1.4-3.3-2.1-5 M728,291.5c-3.9,0-7.3,1.4-10,4.1c-2.8,2.8-4.1,6.1-4.1,10s1.4,7.2,4.1,9.9c2.8,2.8,6.1,4.1,10,4.1
	s7.2-1.4,9.9-4.1c2.8-2.8,4.1-6.1,4.1-9.9s-1.4-7.2-4.1-10C735.1,292.8,731.8,291.4,728,291.5L728,291.5z M803.5,285l3.7-0.1
	 M808.9,274.1l-5.1-4.4 M801.9,285c1,9.2,1.4,19.1,1.5,29.7 M816.4,372.1h4.5v-13.8h-1.4 M819.6,358.3v-1.6l1.4-1.4v-9.9h-17.7
	 M803.4,321.9l2.6-0.5c1.1-1.8,3.2-2.5,6.3-2.2v-4.4h-8.9 M790.3,323.8h-28.8"
          />
          <path
            fill="none"
            d="M820.7,349.8h-17.5v-4.4 M803.2,345.4v-5.8 M803.4,314.7v7.1 M803.4,321.9v1.4h2.3l-2.5,16.4 M803.2,339.6v-7.3
	l-12.9-8.5 M801.9,285h1.7 M803.5,285l-1.5-10.3c-0.5-3.3,0-5,1.7-5 M803.8,269.7l-1.7-1.4 M801,263.1c0.4,1.8,0.8,3.4,1,5.2"
          />
          <path
            fill="none"
            d="M802.1,268.3c-1.2,0.5-1.8,2.3-1.7,5.6c0.5,3.6,1,7.3,1.4,11 M807.2,284.8l-1.2-9.4l2.9-1.4"
          />
          <path
            fill="none"
            d="M807.2,284.8l3.9-0.1l-2.2-10.6 M790.3,323.8c2.1-26.4,1.2-49.8-2.5-69.9 M798.4,255c1.1,2.8,1.9,5.5,2.6,8.1
	 M761.6,253.9v69.9 M366.5,170.4l1.4-0.3c5.8-1,11.7-1.9,17.9-2.6l14.9-1.4l15.7-0.6l11.6-0.1 M366.5,170.4
	c2.2,0.6,5.9,0.6,10.9-0.1l9.2-1.1l14-1.2l15.7-0.6l11.6-0.1 M342.9,175.9l0.1,1c3.7-1.1,7.7-2.2,11.7-3.2l-0.4-0.8 M343.9,173.6
	l-1.2,0.3l0.3,2.1"
          />
          <path
            fill="none"
            d="M354.4,172.9l-0.7-1.6l-5,1.1 M348.7,172.3l0.1-6.1l-2.6,0.7l-2.3,6.6 M348.7,172.3l-4.8,1.2 M313.5,259.9
	l118.2-2.9l-1.4-66.3c-0.1-4.5-1.5-7-3.9-7.6l-14.3,0.3c-27.1,1.2-47.7,5.8-62.2,13.9c-13.2,7.2-27.5,17.2-42.8,30.3 M354.4,172.9
	c4.5-1,8.5-1.9,12-2.5"
          />
          <path
            fill="none"
            d="M307.2,224.7c15-12.5,28.9-22.3,41.7-29.2c14.6-8.3,35.6-13.1,63.1-14.2l14.3-0.3h0.3c3.7,0.4,5.6,3.6,5.9,9.6
	l1.4,67.4c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.1-0.4,0.3-0.8,0.3 M493.6,162.1v-2.3c0-0.4-0.1-0.6-0.5-0.6H487c-0.4,0-0.5,0.1-0.5,0.6v2.3
	 M493.6,162.1h-7.2 M486.4,162.1c-16.1,0-30.8,0-43.9,0.1h-3.7c-4.4,0.3-8.1,1.4-11,3.2 M427.8,167.2v0.8l5.8-0.1
	c30.8-0.4,71.3-0.4,121.5-0.1h8.5c13.2,0.1,26.7,0.1,40.6,0.1h6.7c33.3,0.4,67,1.4,101.1,2.9 M427.8,165.6v1.6 M460.7,258.4
	c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.3-0.4-0.4-0.8L456,187c0.4-4.3,2.1-6.5,5.2-6.7l66.6-0.1c18.7,0,30.4,5.9,34.7,17.6
	c4.1,9.1,7.2,17.5,9.1,25"
          />
          <path
            fill="none"
            d="M458.2,187.2l3.6,68.9l83.1-2.5c16.6,0,25.5-3.4,26.8-10.3c0.1-0.8,0.1-1.5,0-2.3v-0.4
	c0.1-11.4-3.6-25.3-11.3-41.8c-4.3-10.7-15.1-16.1-32.6-16.1l-66.3,0.1C459.6,182.8,458.5,184.4,458.2,187.2L458.2,187.2z
	 M444.9,258.8l15.8-0.4 M444.9,258.8l-12,0.4 M442.3,169.6c1.6,27.1,2.5,56.8,2.6,89.3 M563.3,169.6H555c-45.7-0.3-83.2-0.3-112.9,0
	 M648.5,163.2l-5-0.1c-29.2-0.3-56.1-0.6-80.9-0.8h-8.5c-22-0.1-42.1-0.1-60.4-0.1 M571.6,222.8l1.9,10 M571.6,222.8
	c0.8,3.4,1.5,6.9,1.9,10"
          />
          <path
            fill="none"
            d="M573.5,232.8c0.3,2.6,0.4,5.2,0.4,7.6v0.3c0.1,1,0,2.1-0.1,2.9c-1.1,8.1-10.7,12.1-28.9,12.2l-84.2,2.5"
          />
          <path
            fill="none"
            d="M573.5,232.8l1.8,9.5c0.5,6.7-0.7,11.1-3.7,13.3c-3.3,2.3-8.8,4.1-16.6,5.5l-3.7,0.7c-5.2,0.7-10.6,1.1-15.8,1
	l-90.4,2.3 M661.7,163.4h-5.2 M656.5,163.4V161c0-0.4-0.1-0.6-0.5-0.6h-7c-0.4,0-0.5,0.1-0.5,0.6v2.3 M669.5,168.9
	c-2.8-3-5.4-4.8-7.8-5.5 M648.5,163.2l8,0.1 M733.3,184.4l-131.4-3c-11.1-0.4-14.6,2.3-10.5,8L623,247c2.9,4.5,9.4,6.9,19.5,6.9h119
	 M595.8,301.9c0.3-18.3-0.3-33.7-1.5-46.2c-5.2-28.1-15.5-56.8-31-86 M46.1,291.2l3-3.9l2.5-2.5l3.6-2.6l0.4-0.3l4-1.9l1.2-0.4
	l11.6-3.3l0.3-0.1c29.8-8.3,63.7-14.3,101.7-18.4c11.4-1.5,23.1-2.3,35.1-2.8c-5.8-2.1-10-3-12.7-2.8l-29.9,2.8
	c-34.3,3.4-64.7,7.8-91.3,13.2l-5.8,1.2h-0.1l-2.8,0.7l-6.9,1.1l-7,0.5l-6.3-0.1 M322.5,183.6l-5.6,3.3
	c-30.1,17.2-60.5,37.3-91.2,60.5l-8.4,6.3 M261.9,242.1l-8,6.9c-6.3,5-14.3,7.4-23.9,7.6h-0.6 M217.2,253.6l12.2,3"
          />
          <path
            fill="none"
            d="M217.2,253.6c-7.7-1.8-12.5-2.8-14.4-2.8c-26.7,1.4-51.3,3.3-73.7,5.8c-16.2,1.9-30.4,3.9-42.5,5.8
	c-5.5,0.8-10.5,1.8-15.1,2.8l-6.6,1.4l-8.8,2.2l-8.9,2.8l-0.5,0.3h-0.1 M335,188l-12.5-4.4 M266.3,389c-3.7-16.6-6.5-36.6-8.3-59.6
	c-2.1-29.9-0.1-56.7,5.8-80.6c0.8-3.7,0.3-5.9-1.9-6.6 M307.2,224.7l-3.2,2.8l-0.1,0.1l-18.6,15.7c-3.7,4.4-5,9.5-4,15.3
	c1.8,6.6,10,9.9,24.8,9.6"
          />
          <path
            fill="none"
            d="M288.5,247.8c-1-0.1-1.4,0.7-1.2,2.3c0.4,4.5,3,8.3,7.8,10.9c1.4,0.3,3.2,0.5,5.2,0.5"
          />
          <path
            fill="none"
            d="M302.1,261.7l-1.8-0.7c-4-1.6-6.2-3.6-6.3-6.1h-3c-1.6-1.9-2.5-4.4-2.5-7.2 M307.2,227.8v-3 M316.5,249.4
	c-0.4-3.4-1.1-6.2-2.2-8.4c-1.2-2.3-3.7-3.9-7.4-4.5 M306.9,236.4l-5.1-0.3c-2.9-0.3-5,0.7-5.9,3l-1.9,8.7"
          />
          <path
            fill="none"
            d="M294,247.8c3.3,0.7,7.6,1.1,12.7,1.2c3,0.1,6.3,0.1,9.9,0.1"
          />
          <path
            fill="none"
            d="M313.5,259.9c1.9-1.1,2.9-2.8,3.2-5c0-2.1-0.1-4-0.3-5.8 M306.9,236.4l0.3-8.7 M335,188
	c-21.5,12.9-45.8,31-72.9,54.1 M294,247.8h-5.5 M306.2,261.6l1.9-0.1c2.2-0.1,4-0.7,5.4-1.4 M306.2,262.4v-0.8 M306.2,262.4
	l-0.1,5.8 M300.4,261.7l0.3,0.4c0.1,0.3,0.4,0.3,0.8,0.3l4.8-0.1 M302.1,261.7h-1.6 M306.2,261.6l-4.1,0.1 M267.1,335.1l1.6,10.2
	c0.4,1.8,2.1,2.6,5,2.5H443v-16H270.9C267.8,331.8,266.6,333,267.1,335.1z M105.4,325.4c6.2-9.8,15.7-16.5,28.3-20.1l0.3-0.1
	c11.7-2.3,26.3-3.2,44-2.6h0.3c25.6-1.1,42.5,1.5,50.8,7.6c7.6,4.3,13.2,11.4,17.1,21.7v0.1c4.9,15,8.5,34,10.9,56.8"
          />
          <path
            fill="none"
            d="M247.3,389c-1.8-21.6-5-39.6-9.5-53.9c-2.9-8.3-7.3-14-13.2-17.5l-0.4-0.3c-5.9-4.1-16.9-6.3-33-6.5h-21.6
	c-13.2-0.1-24.5,0.8-33.7,2.6c-8.1,2.3-14.6,6.2-19.4,11.6"
          />
          <path
            fill="none"
            d="M168.1,373.7l0.7-25.2c0.1-1.4-0.1-2.1-1.1-2.3 M176.2,346.2c-1.1,0.1-1.7,1-1.4,2.5l0.7,25.2"
          />
          <path
            fill="none"
            d="M167.7,346.2l4.4-0.1l4.1,0.1 M167,346.3l0.6-0.1h0.1 M167.7,346.2l-0.7,0.1 M184.3,353.4
	c-1.2-0.4-2.5-0.8-3.7-1c-1.5,0-2.1,0.7-1.9,2.1l0.3,4.1 M184.3,353.4l-5.4,5.2 M179,358.6l0.3,6.7"
          />
          <path
            fill="none"
            d="M176.2,346.2h0.4c9.8,1,18.2,5,25.3,12.1c6.1,6.1,9.9,13.1,11.6,21"
          />
          <path
            fill="none"
            d="M193.1,373l8.7-2.3c1.4-0.1,1.8-1,1.4-2.2l-0.1-0.1c-1.5-2.2-3.2-4.3-5.1-6.2c-2.8-2.8-5.8-5.1-9.1-6.7
	 M189,355.3c-1.5-0.8-3-1.5-4.5-2.1"
          />
          <path
            fill="none"
            d="M233.4,384.6c-0.8-15.4-6.7-28.8-17.9-39.9c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.2-11
	c-13.2,0-24.9,3.7-35.1,11c-2.9,2.1-5.6,4.4-8.3,7l-2.9,3.2"
          />
          <path
            fill="none"
            d="M172.1,343.9c-12.2,0-22.7,4.3-31.4,12.9s-12.9,19.1-12.9,31.4c0,12.2,4.3,22.7,12.9,31.4
	c8.7,8.7,19.1,13.1,31.4,13.1s22.7-4.4,31.4-13.1c1.8-1.8,3.4-3.7,4.8-5.6l1.7-2.5c4.3-6.9,6.5-14.6,6.5-23.2
	c0-12.2-4.3-22.7-12.9-31.4S184.3,343.9,172.1,343.9L172.1,343.9z M179.2,365.3l9.8-10 M231.4,404.7c1.4-5.2,2.1-10.7,2.1-16.6
	l-0.1-3.6"
          />
          <path
            fill="none"
            d="M210.2,370.3l0.3,0.5c0.1,1-0.6,1.6-1.9,1.8l-24.1,7.2 M213.4,379.4l-0.3-0.3c-0.4-1.1-1.4-1.4-2.8-0.7
	l-23.7,8.4 M208.7,392.6c0.1-1.5,0.3-2.9,0.3-4.4l-0.1-3c-0.6-1.4-1.4-1.8-2.6-1.1l-8.1,3.2 M213.4,379.4c0.6,2.8,0.8,5.8,0.8,8.8
	c0,11.7-4.1,21.6-12.4,29.9l-1.1,1"
          />
          <path
            fill="none"
            d="M111.6,377.6c-0.6,3.4-0.8,7-0.8,10.6c0,16.9,6.1,31.5,18,43.5s26.6,18,43.5,18s31.5-6.1,43.5-18
	c5.4-5.4,9.6-11.3,12.5-17.7l1.1-2.5c0.8-2.2,1.6-4.4,2.2-6.6 M231.4,404.7h8.8l1.1-1.4h317.6v-7.2 M257.5,396.1h-9.6l-0.3-4.1
	 M247.6,392l-0.3-3 M247.6,392l-14.2-7.4 M189.3,371.5l-0.3-0.3c-2.9-2.9-6.1-4.8-9.6-5.9 M193.1,373l-0.7-1.5h-3.2 M189.3,371.5
	h-2.1l-0.8,3.4 M196.4,392.6v-2.3c1.5-0.4,2.1-1.4,1.7-2.8v-0.3 M198,387.1l-7,2.8 M186.1,375c-0.6-1.1-1.5-1.9-2.5-2.5l-0.8-0.5
	c-1-0.5-2.1-1-3.2-1.2 M186.1,375h0.3 M175.5,373.7v0.4 M175.5,374.1v0.8c0,1.6,0.7,3,2.1,4c0.4,0.3,1,0.5,1.6,0.8
	c1.2,0.8,2.6,1,4.1,0.4l0.7-0.3 M159.9,380.6l0.8,0.3c1.5,0.5,2.9,0.3,4.1-0.5c0.6-0.4,1.1-0.8,1.5-1.5c1.1-1,1.8-2.2,1.8-3.9v-0.7
	 M168.1,374.4v-0.7"
          />
          <path
            fill="none"
            d="M175.5,374.1l-3.4-0.4c-1.4,0-2.8,0.1-4,0.5 M166.6,382.7c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5
	c1.5,1.5,3.3,2.3,5.5,2.3c2.1,0,4-0.8,5.5-2.3s2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5c-1.5-1.5-3.3-2.2-5.5-2.2
	C170,380.5,168.1,381.1,166.6,382.7z M183.9,380l0.6-0.3 M186.7,386.8l-0.3,0.1 M186.4,386.9l-0.8,0.3c-1.5,0.5-2.6,1.5-3,3
	c-0.1,0.5-0.3,1.2-0.4,1.9c-0.3,1.4,0,2.8,1,4l0.4,0.7 M183.5,396.8c1.9-2.5,3-5.4,3-8.8v-1.2 M186.4,386.9c-0.3-2.6-1.1-5-2.5-7
	 M184.5,379.8l1.6-4.8 M183.9,380c-0.6-0.7-1.1-1.4-1.6-1.9c-1.9-1.9-4.3-3.3-6.7-3.9 M179.7,370.7l-4.1,3 M177.7,401.6l-0.1-0.3
	 M183.8,397.2l-0.3-0.4 M177.6,401.4l-0.6-0.8c-1-1.4-2.2-1.9-3.7-2.1c-0.6,0-1.2,0.1-2.1,0.3c-1.5,0.1-2.8,0.8-3.7,2.1l-0.4,0.7
	 M183.5,396.8l-1.2,1.4c-1.4,1.4-2.9,2.5-4.5,3.2 M166.9,401.9l0.3-0.3"
          />
          <path
            fill="none"
            d="M167.1,401.6c1.5,0.5,3.2,0.8,5,0.8c1.9,0,3.9-0.4,5.5-1.1 M176.2,406.5c-1,0.4-2.2,0.8-3.3,1h-1.1
	c-1.1,0-2.2-0.1-3.3-0.5 M179.7,410.7l-3.4-4.3 M168.5,406.9l-1.6-5 M177.7,401.6l-1.5,4.8 M165.1,411c2.2,0.7,4.5,1,7,1
	c2.8,0,5.2-0.4,7.6-1.1 M190.9,389.8c0.1,1.1,0,2.2-0.3,3.4l-0.3,1.1l-0.1,0.3c-0.3,1-0.8,1.9-1.5,2.6 M195.5,398.5
	c0.6-1.9,0.8-3.9,1-5.9 M188.9,397.2l4.3,6.6 M193.1,403.8c1-1.6,1.8-3.6,2.3-5.4 M193.8,404.9l4,6.1c0.6,1.2,1.5,1.4,2.6,0.7
	l0.1-0.1c2.9-3.4,5.1-7.3,6.5-11.4 M193.1,403.8l0.7,1.1 M207,400c-4.1,2.9-8.5,4.5-13.2,5 M186.4,375l6.7-1.9 M183.8,397.2h5.1
	 M186.7,386.8l4.3,3 M200.8,418.8c-0.1,0-0.3,0.1-0.4,0.3c-1,0.4-1.6,0-2.3-1.2l-14.2-20.8 M192.7,424.9l0.6-0.5
	c0.8-0.8,0.8-1.6-0.3-2.8l-15.3-19.9 M200.8,418.8c-2.5,2.3-5.2,4.4-8,6.1"
          />
          <path
            fill="none"
            d="M165.1,411l-6.9,8.5c-1,1-0.8,1.9,0.3,2.8c4.1,1.6,8.7,2.5,13.6,2.5c4.9,0,9.5-0.8,13.8-2.6
	c1.4-1.1,1.5-2.2,0.6-3.2l-6.7-8.4 M151.9,425.2c6.1,3.3,12.8,5.1,20.2,5.1c7.6,0,14.4-1.8,20.6-5.2 M207,400
	c0.8-2.3,1.4-4.8,1.6-7.4 M208.7,392.6h-12.2 M195.5,398.5l13.2-5.9 M266.3,389H257 M257,389l0.5,7v0.1 M257,389h-9.6 M179.7,370.7
	l-0.4-5.4 M84.2,326.3l7.2-31.5c0.4-3.7-0.4-5.6-2.5-5.8h-22 M116.5,325l-11.1,0.4 M32.2,340.6l7.8-1.2l8-0.7h0.4l3.7-0.1h0.3
	l42.8-1.5c2.3-0.1,4.3-1.2,5.9-3.4"
          />
          <path
            fill="none"
            d="M31.8,365.9l62.3-1.8c1.1-6.5,2.3-12.7,3.7-18.7c0.8-4.3,1.9-8.3,3.4-11.8 M105.8,351.4l0.8-4.1
	c1.5-9.1,4.8-16.5,10-22.3 M105.4,325.4l-21.2,0.8 M101.2,333.5c1.1-2.9,2.5-5.5,4.1-8.1 M147.3,360.9l-1.2,1.1
	c-2.1,2.1-3.9,4.4-5.4,6.7c-0.4,1.5,0,2.2,1.4,2.5l10.6,2.9 M151.7,357.5c-1.5,1-2.9,2.2-4.3,3.4"
          />
          <path
            fill="none"
            d="M164.2,365.5l0.6-11.1c0.3-1.2-0.3-1.9-1.5-2.1l-0.4,0.1c-4,1-7.8,2.6-11.3,5.1 M125.7,347.9
	c-5.8,0-12.4,1.2-19.9,3.6 M229.5,256.6c-27.9,1.6-53.9,3.7-78,6.5c-31.5,4-56,8.4-73.7,13.1c-2.6,0.8-5,2.5-6.7,4.7l-1,1.1
	c-0.7,0.8-1.2,1.9-1.8,3l-1.7,4 M46.4,271.8c-0.5,0.3-1,0.4-1.4,0.7l-1.7,1.2v0.1l-2.6,2.9l-2.3,3.2c-0.7,1-1.2,1.9-1.8,2.9
	l-2.2,4.1c-0.3,0.7-0.7,1.2-1,1.9 M48.6,291l-2.5,0.1 M42.4,302.9v-0.1l1.1-5l0.3-1.2l1.2-4.7h-5.4l-0.5-0.1h-2.3"
          />
          <path
            fill="none"
            d="M33.3,288.8h12.5l0.3,2.3 M42.4,302.9L42.4,302.9l-1.2,0.1H40l-1.1,0.1h-1l-1.2,0.1h-1l-1.2,0.1h-1.4
	 M43.3,302.7l-0.8,0.1h-0.1 M40.3,331.5l0.1-3.6v-2.2c0.1-1.9,0.3-3.7,0.4-5.6l0.1-1.5c0.1-2.1,0.4-4,0.7-6.1L42,311
	c0.3-1.6,0.4-3.3,0.8-5l0.1-0.5 M40.3,315.7l0.1-0.8c0.3-1.8,0.4-3.4,0.7-5.2l0.1-0.7l0.3-1.9l0.3-1.1v-0.4 M42.8,305.5L42.8,305.5
	l-0.5,0.1h-0.4 M42.8,305.5l0.5-2.8 M48.6,291l-2.5,10.5l-1.8,11.7l-1.4,13.5v0.1l-0.1,3 M46.1,291.2l-2.8,11.6 M66.9,289h-0.3
	l-18,2.1 M33.3,288.8l-0.8,2.8l4.3,0.1"
          />
          <path
            fill="none"
            d="M36.7,291.7c-0.5,1.2-1.1,2.5-1.5,3.9l-0.1,0.7l-0.7,2.6l-0.3,1.2l-0.3,1.1l-0.1,0.8l-0.3,1.6 M763.3,179.3
	l-1.9-2.5 M787.8,253.9h-26.3 M787.8,253.9c-5.5-22.3-17.5-45.5-36.2-69.6 M754.3,185l9.1-5.6 M801,263.1c0.7-0.3,1.4-0.7,2.1-1v-1
	l3.4-1.4l-2.3-4.8h-5.8 M798,253.9l0.4,1.1 M28.8,303.8l-1.6,1.1l-0.1,1.8 M28.8,303.8l-0.5,2.6 M28.2,306.4l-0.1,1.6v0.1l-0.5,5.5
	l-1,0.3 M33.2,303.4l-4.4,0.4 M27.5,316.5l0.1-0.3c0.5-0.8,0.8-2.1,1.2-3.4l0.8-6.5l12.2-0.7 M28.2,306.4l-1.2,0.3 M40.3,315.7
	L40.3,315.7l-1.9,0.1h-0.5l-1.7,0.1h-0.4l-1.7,0.1h-0.6l-1.6,0.1h-0.5 M756.9,188.1c17.7,19.3,31.4,41.1,41.1,65.6l0.1,0.3
	 M798,253.9h-10.2 M763.3,179.3l1,4.7l-7.4,4.1 M727.2,173.6l-11.7-0.8c-22.4-1.4-46.1-2.2-71-2.5c-29.3-0.3-56.1-0.6-80.9-0.7h-0.3
	 M761.6,253.9v-0.6c-5.8-23.8-15.1-46.8-28.2-69.1 M26.5,314c-0.1,1.4,0,2.2,0.4,2.3l0.3,0.1h0.3 M27.5,316.5l3.6-0.3l-0.1,1.1
	l-0.1,1l-0.1,1.9l-0.1,0.8l-0.3,4.1v3.3l-0.1,4v1l0.1,1.9v3.2 M712.2,170.9c-0.5-2.6-3.6-4.5-9.1-5.9l-41.4-1.7 M727.2,173.6
	l6.2,10.9 M743.7,174.9l-16.5-1.4 M753.8,177.7c1.4,2.2,0.5,4.4-2.2,6.6 M751.6,184.4l-8-9.5 M751.6,184.4l2.6,0.6 M756.9,188.1
	l-2.6-3.2 M712.2,170.9l3.3,0.3c13.8,0.1,28.3,1.9,43.7,5.4l2.2,0.3 M761.4,176.9l-7.6,0.8 M743.7,174.9h1.4l8.8,2.8 M30.3,338.5
	l-2.2,0.5H28c-0.3,0.1-0.5,0.1-0.7,0.3l-0.1,0.1c-0.3,0.3-0.3,0.7-0.4,1.1l0.3,1.5 M34.3,337.5l-1,0.1l-0.4,0.1l-1.8,0.4l-0.4,0.1
	l-0.4,0.1 M40.3,331.5l-0.4,0.3l-0.1,0.1l-0.7,0.7l-0.4,0.3l-0.4,0.4l-0.3,0.1l-1.5,1.4l-0.1,0.3l-0.3,0.1l-0.3,0.4l-0.8,1l-0.1,0.3
	l-0.5,0.7 M27,342.2l0.3,1.5l4-0.8"
          />
          <path
            fill="none"
            d="M31.2,342.9l-1.1,2.8l-0.5,0.7l-0.1,0.1l-0.7,0.5l-1.1,0.5l-5.5,2.2v12.7l2.2-0.3 M31.2,342.9l1-2.3
	 M32.2,340.6l0.1-0.3l1.9-2.8 M27,342.2l5.2-1.6"
          />
          <path
            fill="none"
            d="M31.8,365.9c-1-2.9-2.2-4.3-3.4-4.3l-4,0.5 M84.2,326.3l-19.8,1.1l-1.8,0.1h-0.7l-15.5,1.8l-0.1,0.1l-3.4,0.4
	 M39.3,331.5v-1.9l0.3-5.9l0.1-1.2c0.1-2.2,0.3-4.3,0.5-6.3v-0.5 M42.8,329.8l-2.5,1.6 M27,306.7l-0.4,7.3 M56,373.2
	c-0.1,0.3-0.3,0.8-0.3,1.4c0,1.4,0.3,3,1,5.1c0.8,1.9,1.7,3.6,2.8,5s2.1,2.1,2.8,2.1h0.3"
          />
          <path
            fill="none"
            d="M62.3,387.2l-0.8,0.1c-1,0-2.1-0.8-3.4-2.3c-1.2-1.5-2.3-3.3-3.2-5.5c-0.8-2.2-1.1-4-1-5.5
	c0.1-1,0.3-1.5,0.7-1.9 M62.5,386.6l-0.1,0.5 M56,373.2c3.2,3.6,5.2,7.4,6.3,11.3l0.3,1.4v0.7 M54.8,372.1l0.5,0.4l0.7,0.7"
          />
          <path
            fill="none"
            d="M54.8,372.1c-1.8-1.5-3.9-2.2-6.2-2.2H42c-1.5-0.3-2.2,0.5-2.1,2.3c1.8,4.7,4.8,10.2,9.2,16.5
	c1.4,1.7,3,2.3,5,1.9l4.5-0.7c2.1-0.5,3.2-1.5,3.6-2.8 M54.5,404.2c-1.9-2.2-3.9-4.5-5.6-6.9 M38.2,394.2c-1.1,0.4-1.9,0.1-2.8-1
	c-3.2-4.3-6.2-9.8-8.9-16.5c-1.1-2.5-1.1-3.9,0-3.9h2.6c1.9,6.3,5.6,13.3,11,21L38.2,394.2L38.2,394.2z"
          />
          <path
            fill="none"
            d="M45,405.9l-3-3.7c-0.8-1.4-0.5-2.3,1.1-3c1.2-0.7,1.2-1.8,0.3-3.2l-0.3-0.4c-6.6-8.9-11-17.2-13.3-24.9
	c-0.7-2.6-1.8-3.9-3.3-3.7h-5.2 M46.9,405.8c-2.1,2.1-3,4.3-3,6.9v4.7l56,0.5v-16.8 M45,405.9l1.9-0.1"
          />
          <path
            fill="none"
            d="M21.2,367c-0.1,0.8-0.1,1.6,0.1,2.8l1.1,4.5h1.2c5.1,12.8,11.3,22.8,18.7,30c0.8,0.8,1.7,1.4,2.6,1.5
	 M54.5,404.2l-7.6,1.5 M80.3,401.1l-16.8-2.8l-14.6-1 M49,397.4c-6.9-8.9-12.4-19-16.6-30l-0.4-1.5 M24.4,362.2l-2.6,3.3
	c-0.3,0.4-0.5,1-0.7,1.5 M164,370.7c-1.2,0.3-2.3,1-3.3,1.8l-0.6,0.4c-0.8,0.7-1.6,1.6-2.2,2.8 M164.2,365.5
	c-1.8,0.5-3.4,1.5-4.9,2.5 M164,370.7l0.3-5.2 M159.3,367.9c-1.4,1-2.8,2.1-4,3.3c-1,1-1.8,1.9-2.5,2.9 M153.1,390.4l-4.8-1.8
	 M161.2,397.4l0.6-0.8c1-1.4,1.2-2.9,0.7-4.4l-0.8-1.5c-0.6-1.4-1.5-2.3-3-2.9l-0.7-0.3 M157.8,387.6c0.1-2.6,0.8-5,2.1-7
	 M161.2,397.4c-2.3-2.6-3.4-5.8-3.4-9.2v-0.5 M159.9,380.6l-0.3-0.1 M157.8,387.6l-0.6-0.3 M152.7,374.1l5.2,1.4 M157.2,387.3
	l-4.1,3 M159.6,380.5l-1.6-5 M168.1,374.4c-2.3,0.5-4.3,1.8-6.1,3.7c-0.8,0.8-1.5,1.6-2.1,2.5 M159.6,380.5l-24.1-7.2
	c-1.5-0.3-2.1-1-1.8-2.1V371 M130.8,380c-0.4,2.6-0.7,5.2-0.7,8.1c0,11.7,4.1,21.6,12.4,29.9l1.5,1.4 M130.8,380l0.1-0.5
	c0.6-1,1.4-1,2.6-0.4l23.7,8.4 M143.1,386.6l-5.2-2.1c-1.1-0.5-1.9-0.3-2.3,0.8v0.4c0,0.8-0.1,1.5-0.1,2.3c0,2.1,0.1,4,0.4,5.9
	 M133.6,370.8c-1.4,2.9-2.3,5.9-2.9,9.1 M135.8,393.9c0.6,3.9,1.6,7.4,3.4,10.9 M139.2,404.8c1.4,2.5,2.9,5,5,7.2
	c1.2,0.8,2.2,0.7,2.8-0.5l3-4.5 M135.8,393.9c2.6,1.6,5.1,2.9,7.7,3.9 M155.9,397.6h5.1 M167.1,401.6c-1.9-0.7-3.6-1.8-5.2-3.4
	l-0.8-0.8 M160.9,397.6l0.3-0.3 M153.1,390.4c-0.1,1.2,0.1,2.5,0.7,3.7l0.3,0.7c0.4,1,1.1,1.9,1.9,2.9 M152.8,402.2
	c-0.7-1-1.2-1.8-1.8-2.8 M155.9,397.6l-3,4.5 M148.3,416.1l12.7-18.4 M152.8,402.2l-2.9,4.5 M148.3,388.6l-5.2-1.9 M151,399.4
	c-1.8-3.3-2.8-6.9-2.8-10.9 M149.9,406.7c-3.3,0.3-6.9-0.3-10.6-2.1 M143.5,397.8c2.5,0.8,4.9,1.4,7.6,1.6 M143.1,386.6l0.4,11.1
	 M99.9,401.1v-2.8c0.3-5.4,0.7-10.6,1.2-15.7 M99.9,401.1H80.3 M101.1,382.7l10.5-5.1 M153.2,419.8l-1.6,2.2c-1,1-1.1,1.8-0.4,2.6
	l0.7,0.7"
          />
          <path
            fill="none"
            d="M148.3,416.1l-1.5,2.2c-0.7,1.4-1.5,1.8-2.6,1.1l-0.3-0.1 M143.9,419.2c2.5,2.3,5.2,4.3,8.1,5.8 M153.2,419.8
	c-1.8-1.1-3.4-2.3-5-3.6 M167,346.3c-9.4,1.1-17.6,5.1-24.6,12.1c-3.9,3.9-6.7,8-8.8,12.5 M101.1,382.7c1.1-11,2.6-21.5,4.7-31.2
	 M80.3,401.1c-9.4,0.7-18,1.8-25.9,3.2 M147.3,360.9l12,7 M151.7,357.5l12.5,8 M125.7,347.9c-7.7,8.7-12.4,18.6-14.2,29.7
	 M168.5,406.9l-3.4,4.1 M168.1,373.7l-4.1-3 M166.9,401.9l-13.6,17.9 M595.8,310.3v-8.4 M551.1,389c1.5-16.9,4-32.5,7.4-46.8
	c4-21,15.8-34.4,35.8-39.9l1.5-0.3 M608.4,360.6l-2.8,2.8 M625.2,360.6l0.3-6.2c0.3-1.2-0.3-1.9-1.5-2.1l-0.4,0.1
	c-5.6,1.4-10.7,4.1-15.1,8.1 M628.4,346.2c1,0.3,1.2,1,1.1,2.3l-0.7,25.2 M627.7,346.3l0.7-0.1 M628.4,346.2l4.4-0.1l4.1,0.1
	 M627.7,346.3l0.7-0.1"
          />
          <path
            fill="none"
            d="M679.6,348.1l-3.3-3.4c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.1-11s-24.9,3.7-35.2,11
	c-2.8,1.9-5.2,4.1-7.8,6.6 M594.3,370.8c2.1-4.5,5-8.7,8.8-12.5c7-7,15.1-11,24.6-12.1"
          />
          <path
            fill="none"
            d="M601.6,356.8c-8.7,8.7-12.9,19.1-12.9,31.4c0,12.2,4.4,22.7,12.9,31.4c8.7,8.7,19.1,13.1,31.4,13.1
	c12.2,0,22.7-4.4,31.4-13.1c1.8-1.8,3.4-3.7,4.8-5.6l1.7-2.5c1.5-2.3,2.6-4.7,3.6-7.2l0.1-0.1c1.8-5,2.8-10.2,2.8-15.8
	c0-12.2-4.3-22.7-12.9-31.4c-8.7-8.7-19.1-12.9-31.4-12.9C620.7,344,610.2,348.1,601.6,356.8L601.6,356.8z M625.2,360.6h-16.8"
          />
          <path
            fill="none"
            d="M636.9,346.2c-1.1,0.1-1.7,1-1.4,2.5l0.7,25.2 M640.1,365.3l-0.3-4.3 M663.7,368.2c-0.7-1.1-1.5-2.2-2.5-3.3
	l-2.6-2.8c-5.1-5.1-10.9-8.4-17.5-9.8c-1.5,0-2.1,0.7-1.9,2.1l0.3,6.6"
          />
          <path
            fill="none"
            d="M636.9,346.2h0.4c9.8,1,18.2,5,25.3,12.1c6.1,6.1,9.9,13.1,11.6,21 M570.2,279.1l8.4-0.1c1,0,1.5,0.4,1.7,1.4
	c0.3,1.4,0.4,2.9,0.1,4.7c-0.1,0.8-0.7,1.4-1.5,1.4l-8.7-0.1 M563.3,279.2c-0.1-1.1-0.4-2.1-0.8-2.9c-0.8-1.6-2.3-2.8-4.4-2.9
	l-5-0.3l-4.8,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.8-0.8,3 M563.2,285.9l7,0.1 M570.2,286.1v-7 M570.2,279.1l-6.9,0.1
	 M543.5,285.7c0.1,2.2,0.8,4,2.2,5.2c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5c1.4,0,2.8-0.1,4.1-0.4l1-0.3
	c1-0.3,1.8-0.8,2.5-1.4c1.4-1.1,2.1-2.8,2.2-5 M541.6,279.3l-7.7,0.1c-1,0.1-1.7,0.7-1.9,1.8v2.5c0.3,1,1,1.5,2.2,1.8l7.6,0.1
	 M541.6,285.7v-6.3 M541.6,285.7h1.9 M543.2,279.3h-1.7 M533.9,331.8h0.5 M533.9,331.8h-86.4v16.1h81.4c2.1-0.1,3.9-3.4,5.5-10.2
	C535.5,334,535.4,332,533.9,331.8L533.9,331.8z M564.7,352.5c0.5-2.9,1.2-5.8,1.9-8.5v-0.1c3.3-17.5,13.1-28.6,29.3-33.6
	 M589.9,344.1l-0.5,0.4c-8.8,8.8-14.3,19-16.6,30.4 M589.9,344.1l-25.2,8.4 M563.3,279.2l-20.1,0.1 M563.2,285.9l-19.7-0.3
	 M572.7,375c-0.8,4.1-1.2,8.5-1.2,13.1v0.8 M617.9,387.3l-23.7-8.4c-1.2-0.7-2.1-0.6-2.6,0.4l-0.3,0.6 M604.6,419.2l-1.5-1.4
	c-8.3-8.3-12.4-18.2-12.4-29.9c0-2.8,0.3-5.5,0.7-8.1 M559.5,389h-8.4 M560.2,382.7c-0.3,2.1-0.4,4.1-0.7,6.2 M558.9,396.1h12.9
	 M572.7,375l-12.5,7.7 M571.4,389h-12 M558.9,396.1l0.6-7.2 M571.4,389c0,2.5,0.3,4.8,0.5,7.3"
          />
          <path
            fill="none"
            d="M645.2,379.8l24.1-7.2c1.4-0.3,2.1-0.8,1.9-1.8l-0.3-0.5 M663.7,368.2l-2.3-0.7 M661.4,367.5v3.4 M661.4,371
	l1.2-0.3c1.4-0.1,1.8-1,1.4-2.2l-0.1-0.1l-0.1-0.1 M658.7,387.1l2.8-1 M653.8,403.8c2.3-4.1,3.4-8.7,3.3-13.8
	c1.5-0.4,2.1-1.4,1.7-2.8v-0.3 M674.1,379.4l-0.3-0.3c-0.4-1.1-1.4-1.4-2.8-0.7l-23.7,8.4 M666.5,403.2c2.1-4.5,3-9.6,3-15l-0.1-3
	c-0.5-1.4-1.4-1.8-2.6-1.1l-5.5,2.1"
          />
          <path
            fill="none"
            d="M674.1,379.4c0.5,2.8,0.8,5.8,0.8,8.8c0,5.8-1,11.1-3,16c-2.1,5.1-5.2,9.6-9.4,13.8l-1.1,1 M650,371.5h-1.9
	l-1,3.4 M650,371.5l-0.3-0.3c-2.9-2.9-6.1-4.8-9.6-5.9 M653.8,373l-0.7-1.5H650 M647.1,375l6.7-1.9 M646.8,375h0.3 M636.2,373.7v0.4
	 M646.8,375c-0.7-1.1-1.5-1.9-2.5-2.5l-0.8-0.5c-1-0.5-2.1-1-3.2-1.2 M640.4,370.7l-4.1,3 M636.2,374.1c2.5,0.5,4.8,1.9,6.7,3.9
	c0.7,0.7,1.2,1.2,1.7,1.9 M644.6,380l-0.7,0.3c-1.5,0.5-2.9,0.3-4.1-0.4c-0.7-0.3-1.2-0.4-1.7-0.8c-1.4-1-2.1-2.2-2.1-4v-0.8
	 M638.3,382.7c-1.5-1.5-3.3-2.2-5.5-2.2c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5
	c1.5,1.5,3.3,2.3,5.5,2.3s4-0.8,5.5-2.3s2.2-3.3,2.2-5.5S639.8,384.2,638.3,382.7z M647.5,386.8l-0.4,0.1 M651.6,389.8l-4.1-3
	 M644.2,396.8c1.9-2.5,3-5.4,3-8.8v-1.2 M647.1,386.9l-0.8,0.3c-1.5,0.5-2.6,1.5-3,3c-0.1,0.5-0.3,1.2-0.4,1.9c-0.3,1.4,0,2.8,1,4
	l0.4,0.7 M647.1,386.9c-0.1-2.6-1-5-2.5-7 M644.6,380l0.5-0.3 M645.2,379.8l1.7-4.8 M651.6,389.8c0.1,1.1,0,2.2-0.3,3.4l-0.3,1.1
	v0.3c-0.3,1-0.8,1.9-1.5,2.6 M649.6,397.2l4.3,6.6 M644.2,396.8l-1.2,1.4c-1.4,1.4-2.9,2.5-4.5,3.2 M638.3,401.4l0.3,0.3
	 M644.5,397.2l-0.3-0.4 M638.3,401.4l-0.5-0.8c-1-1.4-2.2-1.9-3.7-2.1c-0.7,0-1.4,0.1-2.1,0.3c-1.5,0.1-2.8,0.8-3.6,2.1l-0.5,0.7
	 M640.5,410.7l-3.6-4.3 M644.5,397.2h5.1 M638.6,401.6l-1.7,4.8"
          />
          <path
            fill="none"
            d="M666.5,403.2l-0.4,1c-1.2,2.6-2.9,5.1-4.8,7.4l-0.1,0.1c-1.1,0.8-2.1,0.5-2.6-0.7l-4.5-7 M661.4,386.1v8.7
	c-0.1,4.3,1.7,7,5.1,8.4 M661.4,371l-7.6,2.1 M658.7,387.1l-7,2.8 M620.7,380.6l0.8,0.3c1.5,0.5,2.9,0.3,4.1-0.5
	c0.5-0.4,1.1-0.8,1.5-1.5c1.1-1,1.8-2.2,1.8-3.9v-0.7 M624.7,370.7l0.3-5.2 M628.8,373.7l-4.1-3 M628.8,374.4v-0.7 M636.2,374.1
	l-3.4-0.4c-1.4,0-2.8,0.1-4,0.5 M613.4,374.1l5.2,1.4 M621.2,367.1c-1.4,0.8-2.8,1.6-4,2.8 M624.7,370.7c-1.2,0.3-2.3,1-3.3,1.8
	l-0.5,0.4c-0.8,0.7-1.7,1.6-2.2,2.8 M613.4,374.1c0.8-1,1.7-2.1,2.6-2.9c0.4-0.4,1-0.8,1.4-1.2 M620.7,380.6c-1.4,2.1-2.1,4.4-2.1,7
	 M618.5,387.6l-0.5-0.3 M620.3,380.5l0.4,0.1 M617.9,387.3l-4.1,3"
          />
          <path
            fill="none"
            d="M621.9,397.4l0.5-0.8c1-1.4,1.2-2.9,0.7-4.4c-0.3-0.5-0.4-1-0.8-1.5c-0.5-1.4-1.5-2.3-3.2-2.9l-0.7-0.3
	 M621.9,397.4c-2.3-2.6-3.4-5.8-3.4-9.2v-0.5 M625,365.5c-1.2,0.4-2.5,1-3.7,1.6 M618.6,375.5l1.7,5 M628.8,374.4
	c-2.3,0.5-4.3,1.8-6.2,3.7c-0.8,0.8-1.5,1.6-2.1,2.5 M609,388.6l-8.1-3.2 M603.1,366.4l-1.7,2.3c-0.4,1.5,0,2.2,1.4,2.5l10.6,2.9
	 M600.9,385.4l-2.3-1c-1.1-0.5-1.9-0.3-2.3,0.8l-0.1,2.8c0,4.1,0.5,8,1.8,11.7 M620.3,380.5l-24.1-7.2c-1.5-0.3-2.2-1-1.9-2.1V371
	 M597.8,399.7c1.5,4.4,3.7,8.5,7,12.1c1.2,0.8,2.2,0.7,2.8-0.5l5.9-9.1 M600.9,395l11.1,4.8 M597.8,399.7h3V395 M627.7,401.9
	l0.1-0.3 M627.8,401.6c1.5,0.5,3.2,0.8,5,0.8c1.9,0,3.9-0.4,5.5-1.1 M621.8,397.6l0.1-0.3 M616.6,397.6c-0.8-1-1.5-1.9-1.9-2.9
	l-0.3-0.7c-0.6-1.2-0.7-2.5-0.7-3.7 M627.8,401.6c-1.9-0.7-3.6-1.8-5.2-3.4l-0.8-0.8 M613.7,402.2l2.9-4.5 M616.6,397.6h5.2
	 M636.9,406.5c-1,0.4-2.2,0.8-3.3,1h-1.1c-1.1,0-2.2-0.1-3.3-0.5 M629.2,406.9l-3.3,4.1 M625.9,411c2.2,0.7,4.5,1,7,1
	c2.8,0,5.2-0.4,7.6-1.1 M629.2,406.9l-1.5-5 M621.8,397.6L609,416.1 M613.8,390.4l-4.8-1.8 M612,399.9c0.4,0.8,1,1.6,1.7,2.3
	 M603.1,366.4l14.3,3.6 M600.9,385.4v9.6 M609,388.6c0.1,4.1,1.1,8,3,11.3"
          />
          <path
            fill="none"
            d="M625.9,411l-6.9,8.5c-1,1-1,1.9,0.3,2.8c4.1,1.6,8.7,2.5,13.6,2.5c5,0,9.5-0.8,13.8-2.6
	c1.4-1.1,1.5-2.2,0.7-3.2l-6.7-8.4 M609,416.1l-1.5,2.2c-0.7,1.4-1.5,1.8-2.6,1.1l-0.3-0.1 M612.7,425.2c-2.9-1.5-5.5-3.6-8.1-5.8"
          />
          <path
            fill="none"
            d="M613.9,419.8l-1.7,2.2c-1,1-1.1,1.8-0.4,2.6l0.7,0.7 M612.7,425.2c6.1,3.3,12.8,5.1,20.2,5.1
	c7.6,0,14.4-1.8,20.6-5.2 M661.5,418.8l-0.5,0.3c-1,0.4-1.7,0-2.3-1.2l-14.2-20.8 M653.4,424.9l0.5-0.5c1-0.8,0.8-1.6-0.3-2.8
	l-15.3-19.9 M653.4,424.9c2.9-1.6,5.5-3.7,8.1-6.1 M609,416.1c1.5,1.4,3.3,2.6,5,3.6 M627.7,401.9l-13.8,17.9 M564.7,352.5
	c-1.9,9.4-3.4,19.5-4.5,30.1 M625,365.5l0.3-4.8 M605.7,363.4c-1,1-1.8,2.1-2.6,3 M621.2,367.1l-15.5-3.7 M639.8,361.1l21.6,6.5
	 M591.5,380c0.5-3.2,1.5-6.3,2.9-9.1 M640.4,370.7l-0.3-5.4 M422.7,282.9l8.4-0.1c1,0,1.5,0.4,1.6,1.2c0.3,1.4,0.4,3,0.1,4.7
	c-0.1,0.8-0.7,1.4-1.5,1.4l-8.7-0.1"
          />
          <path
            fill="none"
            d="M426,285.7c-0.3,0.3-0.4,0.8-0.4,1.2s0.1,0.8,0.4,1.2c0.3,0.3,0.8,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.3-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-1-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5S426.3,285.3,426,285.7L426,285.7z M422.7,290.1v-7.2
	 M416,283.1c-0.1-1.1-0.4-2.1-0.8-2.9c-0.8-1.6-2.3-2.6-4.4-2.9l-5-0.3l-4.7,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.9-0.8,3
	 M394.3,283.2l-7.7,0.1c-1,0.1-1.6,0.7-1.9,1.8v2.5c0.3,1,1,1.5,2.2,1.8l7.6,0.1 M395.9,283.2h-1.6 M396.2,289.7
	c0.1,2.2,0.8,4,2.2,5.2c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5l4.1-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4
	c1.4-1.1,2.1-2.8,2.2-5 M394.3,289.5l1.9,0.1 M416,283.1l-20.1,0.1 M415.7,289.9l-19.5-0.3 M394.3,289.5v-6.3 M444.9,265.3V389
	 M415.7,289.9l7,0.1 M422.7,282.9L416,283 M551.1,389H444.9 M322.5,183.6c6.3-2.9,13.2-5.4,20.6-7.6 M432.9,259.3l-126.7,3.2
	 M444.9,265.3v-6.5 M444.9,389H266.3 M306.1,268.2l138.8-2.9 M257.5,396.1h301.4 M442.3,169.6l-16.5,0.1c-9.8,0.1-18.4,0.3-26,0.6
	l-13.2,1.4h-0.1c-19.5,2.6-35.6,7.3-48.1,14.3l-3.2,1.9 M745,174.9l-1.4-0.1l8,9.5c2.8-2.2,3.6-4.4,2.2-6.6L745,174.9L745,174.9z"
          />
          <path
            fill="none"
            d="M743.7,174.9l1.4,0.1l8.8,2.8l7.6-1l-2.2-0.3c-15.5-3.4-30.1-5.2-43.7-5.4l-3.3-0.3
	c-34.1-1.5-67.8-2.5-101.1-2.9h-6.7c-13.9,0-27.4,0-40.6-0.1h-8.5c-50.2-0.3-90.7-0.3-121.5,0.1l-5.8,0.1v-0.8l-11.6,0.1l-15.7,0.6
	l-14,1.2l-9.2,1.1c-5,0.6-8.5,0.7-10.9,0.1c-3.4,0.7-7.6,1.5-12,2.5l0.4,0.8c-4,1-8,2.1-11.7,3.2l-0.1-1c-7.4,2.2-14.3,4.8-20.6,7.6
	l12.5,4.4l3.2-1.9c12.7-7,28.8-11.8,48.1-14.3h0.1l13.2-1.4c7.6-0.3,16.2-0.4,26-0.6l16.5-0.1c29.6-0.3,67.3-0.3,112.9,0h8.5
	c24.8,0.1,51.7,0.4,80.9,0.7c24.9,0.3,48.4,1.1,71,2.5l11.7,0.8L743.7,174.9L743.7,174.9z"
          />
          <path
            fill="none"
            d="M761.4,176.9l-7.6,1c1.4,2.2,0.5,4.4-2.2,6.6l2.6,0.6l9.1-5.6L761.4,176.9L761.4,176.9z"
          />
          <path fill="none" d="M754.3,185l2.6,3.2l7.4-4.1l-1-4.7" />
          <path
            fill="none"
            d="M756.9,188.1l-2.6-3.2l-2.6-0.6c18.7,24.1,30.7,47.3,36.2,69.6h10.3l-0.1-0.3
	C788.2,229.3,774.6,207.4,756.9,188.1L756.9,188.1z"
          />
          <path
            fill="none"
            d="M751.6,184.4l-8-9.5l-16.5-1.2l6.3,10.7c13.1,22.1,22.4,45.1,28.2,69.1v0.6H788
	C782.3,231.6,770.2,208.5,751.6,184.4L751.6,184.4z"
          />
          <path
            fill="none"
            d="M733.3,184.4l-6.3-10.7l-11.7-0.8c-22.4-1.4-46.1-2.2-71-2.5c-29.3-0.3-56.1-0.6-80.9-0.7h-0.3
	c15.4,29.3,25.7,57.9,31,86c1.2,12.5,1.8,27.9,1.5,46.2c11.4-2.2,25.7-2.9,42.8-2.3h0.3c25.6-1.1,42.5,1.5,50.8,7.6
	c6.3,3.4,11.3,9.1,15,16.8h57.1v-70h-119c-10.2,0-16.8-2.3-19.7-6.9l-31.4-57.8c-4.1-5.8-0.5-8.4,10.5-8L733.3,184.4L733.3,184.4z
	 M728,291.5c3.9,0,7.2,1.4,9.9,4.1c2.8,2.8,4.1,6.1,4.1,10s-1.4,7.2-4.1,9.9c-2.8,2.8-6.1,4.1-9.9,4.1c-3.9,0-7.3-1.4-10-4.1
	c-2.8-2.8-4.1-6.1-4.1-9.9s1.4-7.2,4.1-10C720.7,292.8,724,291.4,728,291.5L728,291.5z"
          />
          <path
            fill="none"
            d="M737.9,295.6c-2.8-2.8-6.1-4.1-9.9-4.1c-3.9,0-7.3,1.4-10,4.1c-2.8,2.8-4.1,6.1-4.1,10s1.4,7.2,4.1,9.9
	c2.8,2.8,6.1,4.1,10,4.1s7.2-1.4,9.9-4.1c2.8-2.8,4.1-6.1,4.1-9.9C742,301.6,740.6,298.3,737.9,295.6L737.9,295.6z M712.2,170.9
	c-0.5-2.6-3.6-4.5-9.1-5.9l-41.4-1.7c2.5,0.7,5.1,2.5,7.8,5.5c-2.8-3-5.4-4.8-7.8-5.5l-5.2-0.1l-8-0.1l-5-0.1
	c-29.2-0.3-56.1-0.6-80.9-0.8h-8.5c-22-0.1-42.1-0.1-60.4-0.1h-7.2c-16.1,0-30.8,0-43.9,0.1H439c-4.4,0.3-8.1,1.4-11,3.2v2.5
	l5.8-0.1c30.8-0.4,71.3-0.4,121.5-0.1h8.5c13.2,0.1,26.7,0.1,40.6,0.1h6.7C644.4,168.5,678.1,169.4,712.2,170.9L712.2,170.9z
	 M761.6,253.9v-0.6c-5.8-23.8-15.1-46.8-28.2-69.1L602,181.4c-11.1-0.4-14.6,2.3-10.5,8L623,247c2.9,4.5,9.4,6.9,19.5,6.9H761.6
	L761.6,253.9z M798,253.9h-10.3c3.7,20.1,4.5,43.3,2.5,69.9l12.9,8.5v7.3l2.5-16.4h-2.2v-8.5c-0.1-10.6-0.7-20.5-1.5-29.7
	c-0.4-3.9-0.8-7.6-1.4-11c-0.3-3.3,0.3-5.1,1.7-5.6c-0.3-1.6-0.5-3.4-1-5.2c-0.7-2.8-1.5-5.4-2.6-8.1L798,253.9z"
          />
          <path
            fill="none"
            d="M804.2,255h-5.8c1.1,2.8,1.9,5.5,2.6,8.1c0.7-0.3,1.4-0.7,2.1-1v-1l3.4-1.4L804.2,255z M786.7,256.9h-0.3
	c-5,0.1-7.3,2.6-7.2,7.2c-0.7,5.8,0.1,9.9,2.5,12.1c0.8,0.8,1.9,1.4,3.3,1.8l4,0.3C788.5,270.5,787.8,263.4,786.7,256.9L786.7,256.9
	z"
          />
          <path
            fill="none"
            d="M786.5,256.9h0.3c1.1,6.5,1.8,13.6,2.3,21.3c0.3,4.1,0.4,8.3,0.4,12.7c0.1,6.9,0,14.2-0.3,21.9l-6.1-0.5v11.6
	h7.2c2.1-26.4,1.2-49.8-2.5-69.9h-26.3v69.9H783v-11.6l-13.6-1.1c-2.6,0-3.9-0.5-3.6-1.6l-1.1-47.7c-0.1-2.9,0.8-4.5,2.8-4.7
	L786.5,256.9L786.5,256.9z"
          />
          <path
            fill="none"
            d="M786.5,256.9h-18.8c-1.9,0.1-2.9,1.8-2.8,4.7l1.1,47.7c-0.3,1.1,1,1.6,3.6,1.6l13.3-1.4c-1.2-1.9-1.8-5-1.8-9.1
	c-0.3-5.1,1.2-8.3,4.7-9.5H769l13.2-1.2c-1.2-1.4-1.7-3.9-1.1-7.6c-0.4-2.1,1-3.6,3.9-4.4h-15.4l12.2-1.8c-2.3-2.2-3.3-6.3-2.5-12.1
	C779.2,259.5,781.5,257.2,786.5,256.9L786.5,256.9z M811.1,284.7l-2.2-10.6l-2.9,1.4l1.2,9.4"
          />
          <path
            fill="none"
            d="M806,275.5l2.9-1.4l-5.2-4.4c-1.7,0.1-2.2,1.8-1.7,5l1.5,10.3l3.7-0.1L806,275.5L806,275.5z"
          />
          <path
            fill="none"
            d="M802.1,268.3c-1.2,0.5-1.8,2.3-1.7,5.6c0.5,3.6,1,7.3,1.4,11h1.7l-1.5-10.3c-0.5-3.3,0-5,1.7-5L802.1,268.3
	L802.1,268.3z M788.9,278.2l-4-0.3c-2.9,0.8-4.3,2.2-3.9,4.4c-0.5,3.7-0.3,6.2,1.1,7.6c0.7,0.7,1.5,1.1,2.6,1.2h1l3.6-0.1
	C789.3,286.5,789.2,282.4,788.9,278.2L788.9,278.2z"
          />
          <path
            fill="none"
            d="M781.8,276.2l-12.2,1.8H785C783.7,277.7,782.7,277,781.8,276.2L781.8,276.2z M783,309.7l-13.3,1.4l13.6,1.1
	v-0.1v0.1l6.1,0.5l-3-0.7C784.8,311.8,783.7,311,783,309.7L783,309.7z"
          />
          <path
            fill="none"
            d="M781.2,300.7c0,4.1,0.5,7.2,1.8,9.1c0.8,1.2,1.9,2.1,3.3,2.3l3,0.7c0.3-7.7,0.4-15,0.3-21.9L786,291
	C782.5,292.4,780.8,295.6,781.2,300.7L781.2,300.7z M782.3,289.9l-13.2,1.2h15.8C783.7,291,782.9,290.6,782.3,289.9L782.3,289.9z
	 M803.2,332.3l-12.9-8.5h-85.7c0.7,1.5,1.5,3.2,2.1,5v0.1c3.9,11.4,6.7,25.2,8.9,41.1l68.2-4c9.4-1.5,20.5-1.6,33.4-0.3l2.5-0.3
	l-0.3-7.2l-0.1-1.6l1.4-1.4v-9.9H803v4.4h17.3H803v-10.2L803.2,332.3z M803.4,314.7v7.1l2.6-0.5c1.1-1.8,3.2-2.5,6.3-2.2v-4.4H803.4
	L803.4,314.7z"
          />
          <path
            fill="none"
            d="M821,358.3h-1.4l0.3,7.2l-3.4,6.6h4.5 M706.7,328.7c-0.7-1.6-1.4-3.3-2.1-5h-9.9c1.4,2.3,2.6,5,3.7,7.8
	c1.5,5.1,2.9,10.6,4.1,16.5c1.2,6.3,2.3,13.2,3.2,20.5l0.3,1.9l9.9-0.5C713.5,354,710.5,340.3,706.7,328.7L706.7,328.7z"
          />
          <path
            fill="none"
            d="M698.4,331.6c-1-2.9-2.2-5.5-3.7-7.8c-2.5-4.1-5.6-7.4-9.5-9.6l-0.4-0.3c-5.8-4.1-16.8-6.3-33-6.5h-21.6
	c-13.2-0.1-24.5,0.7-33.7,2.6l-0.7,0.1c-16.2,4.8-26,16.1-29.3,33.6v0.1c-0.7,2.8-1.2,5.6-1.9,8.5l25.2-8.4c2.5-2.5,5.1-4.7,7.8-6.6
	c10.2-7.3,22-11,35.2-11s24.9,3.7,35.1,11c2.9,2.1,5.6,4.4,8.3,7l3.3,3.4h22.8C701.3,342.2,699.9,336.7,698.4,331.6L698.4,331.6z"
          />
          <path
            fill="none"
            d="M684.8,314l0.4,0.3c3.7,2.2,6.9,5.4,9.5,9.6h9.9c-3.7-7.7-8.7-13.2-15-16.8c-8.1-6.1-25-8.7-50.8-7.6h-0.3
	c-17.1-0.5-31.4,0.1-42.8,2.3v8.5l0.7-0.1c9.4-1.8,20.5-2.8,33.7-2.6h21.6C668,307.7,679,309.9,684.8,314L684.8,314z M702.4,348.1
	h-22.8c5.4,6.2,9.4,13.1,11.8,20.5h14.3C704.7,361.3,703.6,354.5,702.4,348.1z M705.8,370.5l-0.3-1.9h-14.3c2.1,6.1,3,12.5,3,19.5
	c0,5.6-0.7,11-1.9,16h82c8.3,0.3,12.4-2.9,12.2-9.4v-12.1l-68.2,9.2l-10.3,1.4C707.4,385.1,706.7,377.6,705.8,370.5z"
          />
          <path
            fill="none"
            d="M715.7,370l-9.9,0.5c0.8,7,1.5,14.6,1.9,22.6l10.3-1.4C717.5,384,716.7,376.7,715.7,370z M694.3,388
	c0-6.9-1-13.3-3-19.5c-2.5-7.4-6.3-14.3-11.8-20.5l-3.3-3.4c-2.6-2.6-5.4-5-8.3-7c-10.2-7.3-21.9-11-35.1-11
	c-13.2,0-24.9,3.7-35.2,11c-2.8,1.9-5.2,4.1-7.8,6.6l-0.5,0.4c-8.8,8.8-14.3,19-16.6,30.4c-0.8,4.1-1.2,8.5-1.2,13.1v0.8
	c0,2.5,0.3,4.8,0.5,7.3c1.7,13.5,7.4,25.3,17.5,35.4c12,12,26.5,18,43.5,18s31.5-6.1,43.5-18c5.4-5.4,9.6-11.3,12.5-17.7l1.1-2.5
	c1-2.3,1.8-4.7,2.3-7.2C693.6,399,694.3,393.7,694.3,388L694.3,388z M601.6,356.8c8.7-8.7,19.1-12.9,31.4-12.9
	c12.2,0,22.7,4.3,31.4,12.9s12.9,19.1,12.9,31.4c0,5.6-1,11-2.8,15.8l-0.1,0.1c-1,2.5-2.2,5-3.6,7.1l-1.7,2.5
	c-1.5,1.9-3,3.9-4.8,5.6c-8.7,8.7-19.1,13.1-31.4,13.1c-12.2,0-22.7-4.4-31.4-13.1s-12.9-19.1-12.9-31.4
	C588.5,375.9,592.9,365.5,601.6,356.8L601.6,356.8z"
          />
          <path
            fill="none"
            d="M632.8,343.9c-12.2,0-22.7,4.3-31.4,12.9c-8.7,8.7-12.9,19.1-12.9,31.4c0,12.2,4.4,22.7,12.9,31.4
	c8.7,8.7,19.1,13.1,31.4,13.1c12.2,0,22.7-4.4,31.4-13.1c1.8-1.8,3.4-3.7,4.8-5.6l1.7-2.5c1.5-2.3,2.6-4.7,3.6-7.2l0.1-0.1
	c1.8-5,2.8-10.2,2.8-15.8c0-12.2-4.3-22.7-12.9-31.4C655.5,348.1,645,343.9,632.8,343.9L632.8,343.9z M628.4,346.2l4.4-0.3l4,0.1
	h0.4c9.8,1,18.2,5,25.3,12.1c6.1,6.1,9.9,13.1,11.6,21c0.5,2.8,0.8,5.8,0.8,8.8c0,5.8-1,11.1-3,16c-2.1,5.1-5.1,9.6-9.4,13.8l-1.1,1
	c-2.5,2.3-5.2,4.4-8.1,6.1c-6.2,3.6-13.1,5.2-20.6,5.2c-7.4,0-14.2-1.6-20.2-5.1c-2.9-1.5-5.5-3.6-8.1-5.8l-1.5-1.4
	c-8.3-8.3-12.4-18.2-12.4-29.9c0-2.8,0.3-5.5,0.7-8.1c0.5-3.2,1.5-6.3,2.9-9.1c2.1-4.5,5-8.7,8.8-12.5c7-7,15.1-11,24.6-12.1
	L628.4,346.2L628.4,346.2z"
          />
          <path
            fill="none"
            d="M605.7,363.4l2.8-2.8c4.5-4,9.5-6.7,15.1-8.1l0.4-0.1c1.2,0.1,1.7,0.8,1.5,2.1l-0.3,6.2l-0.3,5l-0.3,5.2
	l4.1,3.2l0.7-25.2c0.1-1.4-0.1-2.1-1.1-2.3h-0.7c-9.4,1.1-17.6,5.1-24.6,12.1c-3.8,3.9-6.7,8-8.8,12.5v0.3c-0.3,1.1,0.4,1.9,1.9,2.1
	l24.1,7.2l-1.7-4.8l-5.2-1.4l-10.6-2.9c-1.4-0.1-1.8-1-1.4-2.5l1.7-2.3C603.9,365.3,604.7,364.4,605.7,363.4L605.7,363.4z"
          />
          <path
            fill="none"
            d="M608.4,360.6l-2.8,2.8l15.5,3.7c1.2-0.7,2.3-1.2,3.7-1.6l0.3-5h-16.8L608.4,360.6L608.4,360.6z"
          />
          <path
            fill="none"
            d="M624.1,352.4l-0.4,0.1c-5.6,1.4-10.7,4.1-15.1,8.1h16.8l0.3-6.2C625.8,353.2,625.2,352.5,624.1,352.4
	L624.1,352.4z M628.4,346.2c1,0.3,1.2,1,1.1,2.3l-0.7,25.2v0.5c1.2-0.4,2.6-0.5,4-0.5l3.4,0.4v-0.4l-0.7-25.2
	c-0.3-1.5,0.3-2.3,1.4-2.5l-4-0.1L628.4,346.2z M627.7,346.3l0.7-0.1"
          />
          <path
            fill="none"
            d="M636.9,346.2c-1.1,0.1-1.7,1-1.4,2.5l0.7,25.2l4.1-3.2l-0.3-5.4l-0.3-4.3l-0.3-6.6c-0.3-1.4,0.4-2.1,1.9-2.1
	c6.6,1.5,12.4,4.7,17.5,9.8l2.6,2.8l2.5,3.3l0.1,0.1l0.1,0.1c0.4,1.2-0.1,2.1-1.4,2.2l-1.2,0.3l-7.6,2.1l-6.7,1.8h-0.3l-1.7,4.8
	l24.1-7.2c1.4-0.3,2.1-0.8,1.9-1.8l-0.3-0.6l0.3,0.5c0.1,1-0.5,1.6-1.9,1.8l-24.1,7.2l-0.5,0.1c1.5,2.1,2.3,4.4,2.5,6.9l0.3-0.1
	l23.7-8.4c1.4-0.7,2.2-0.4,2.8,0.7l0.3,0.3c-1.7-8-5.5-14.9-11.6-21c-7.2-7.2-15.5-11.1-25.3-12.1L636.9,346.2L636.9,346.2z"
          />
          <path
            fill="none"
            d="M639.8,361.1l0.3,4.3c3.6,1.1,6.9,3,9.6,5.9l0.3,0.3h3.2l0.7,1.5l7.6-2.1v-3.6L639.8,361.1L639.8,361.1z"
          />
          <path
            fill="none"
            d="M639.5,354.5l0.3,6.6l21.6,6.5l2.5,0.7c-0.7-1.1-1.5-2.2-2.5-3.3l-2.6-2.8c-5.1-5.1-10.9-8.4-17.5-9.8
	C639.8,352.4,639.3,353.1,639.5,354.5L639.5,354.5z M591.5,380l0.3-0.5c0.5-1,1.4-1,2.6-0.4l23.7,8.4l0.5,0.1c0.1-2.6,0.8-5,2.1-7
	l-0.4-0.1l-24.1-7.1c-1.5-0.3-2.2-1-1.9-2.2v-0.3C593,373.7,592.1,376.7,591.5,380L591.5,380z"
          />
          <path
            fill="none"
            d="M591.7,379.4l-0.3,0.5c-0.4,2.6-0.7,5.2-0.7,8.1c0,11.7,4.1,21.6,12.4,29.9l1.5,1.4l0.3,0.1
	c1,0.7,1.9,0.3,2.6-1.1l1.5-2.2l12.7-18.4h-5.2l-2.9,4.5l-5.9,9.1c-0.5,1.2-1.5,1.5-2.8,0.5c-3.2-3.7-5.5-7.7-7-12.1
	c-1.2-3.6-1.8-7.6-1.8-11.7l0.1-2.8c0.4-1.1,1.2-1.4,2.3-0.8l2.3,1l8.1,3l4.8,1.8l4.1-3l-23.7-8.4
	C593,378.4,592.2,378.5,591.7,379.4L591.7,379.4z M663.7,368.2l-2.5-0.7v3.6l1.2-0.3c1.4-0.1,1.8-1,1.4-2.2L663.7,368.2L663.7,368.2
	z M661.4,386.1l-2.6,1v0.3c0.4,1.4-0.1,2.3-1.7,2.8c0.1,5.1-1,9.6-3.3,13.8l4.5,7c0.5,1.2,1.5,1.4,2.6,0.7l0.1-0.1
	c1.9-2.3,3.6-4.8,4.8-7.4l0.4-1c-3.4-1.4-5.2-4.1-5.1-8.4v-8.5h0.3V386.1z"
          />
          <path
            fill="none"
            d="M658.7,387.1l2.6-1l5.5-2.1c1.2-0.5,2.1-0.3,2.6,1.1l0.1,3c0,5.4-1,10.5-3,15l-0.4,1c-1.2,2.6-2.9,5.1-4.8,7.4
	l-0.1,0.1c-1.1,0.8-2.1,0.5-2.6-0.7l-4.5-7l-4.3-6.6h-5.1l14.2,20.8c0.7,1.2,1.5,1.6,2.3,1.2l0.6-0.3l1.1-1
	c4.1-4.1,7.3-8.7,9.4-13.8c2.1-5,3-10.3,3-16c0-3-0.3-5.9-0.8-8.8l-0.3-0.3c-0.4-1.1-1.4-1.4-2.8-0.7l-23.7,8.4l4.1,3L658.7,387.1
	L658.7,387.1z"
          />
          <path
            fill="none"
            d="M658.8,387.5v-0.3l-7,2.8c0.1,1.1,0,2.2-0.3,3.4l-0.3,1.1v0.3c-0.3,1-0.8,1.9-1.5,2.6l4.3,6.6
	c2.3-4.1,3.4-8.7,3.3-13.8C658.7,389.7,659.2,388.8,658.8,387.5L658.8,387.5z M669.5,385.1c-0.5-1.4-1.4-1.8-2.6-1.1l-5.5,2.1v8.7
	c-0.1,4.3,1.7,7,5.1,8.4c2.1-4.5,3-9.6,3-15V385.1L669.5,385.1z M648.1,371.5h2.1l-0.3-0.3c-2.9-2.9-6.1-4.8-9.6-5.9l0.3,5.4
	c1.2,0.3,2.2,0.7,3.2,1.2l0.8,0.5c1,0.7,1.9,1.5,2.5,2.5h0.3L648.1,371.5L648.1,371.5z"
          />
          <path
            fill="none"
            d="M650,371.5h-1.9l-1,3.4l6.7-1.9l-0.7-1.5 M636.2,373.7v0.4c2.5,0.5,4.8,1.9,6.7,3.9c0.7,0.7,1.2,1.2,1.7,1.9
	l0.5-0.1l1.7-4.8c-0.7-1.1-1.5-1.9-2.5-2.5l-0.8-0.5c-1-0.5-2.1-1-3.2-1.2L636.2,373.7L636.2,373.7z"
          />
          <path
            fill="none"
            d="M643,378c-1.9-1.9-4.3-3.3-6.7-3.9v0.8c0,1.6,0.7,3,2.1,4c0.4,0.3,1,0.5,1.7,0.8c1.2,0.8,2.6,1,4.1,0.4l0.7-0.3
	C644.1,379.4,643.5,378.7,643,378L643,378z"
          />
          <path
            fill="none"
            d="M636.2,375v-0.8l-3.4-0.4c-1.4,0-2.8,0.1-4,0.5v0.7c0,1.6-0.5,2.9-1.8,3.9c-0.5,0.7-1,1.1-1.5,1.5
	c-1.2,0.8-2.6,1-4.1,0.5l-0.8-0.3c-1.4,2.1-2.1,4.4-2.1,7l0.7,0.3c1.5,0.4,2.6,1.4,3.2,2.9c0.3,0.5,0.5,1,0.8,1.5
	c0.5,1.5,0.4,3-0.7,4.4l-0.5,0.8l0.8,0.8c1.5,1.5,3.3,2.6,5.2,3.4l0.5-0.7c1-1.2,2.1-1.9,3.6-2.1c0.7-0.3,1.4-0.3,2.1-0.3
	c1.5,0,2.8,0.7,3.7,2.1l0.5,0.8c1.7-0.7,3.2-1.8,4.5-3.2l1.2-1.4l-0.4-0.7c-1-1.2-1.2-2.6-1-4c0.1-0.7,0.3-1.4,0.4-1.9
	c0.4-1.5,1.5-2.5,3-3l0.8-0.3c-0.1-2.6-1-5-2.5-6.9l-0.7,0.3c-1.5,0.5-2.9,0.3-4.1-0.4c-0.7-0.3-1.2-0.4-1.7-0.8
	C636.9,378,636.2,376.7,636.2,375L636.2,375z M638.3,382.7c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3
	c-2.1,0-4-0.8-5.5-2.3s-2.2-3.3-2.2-5.5s0.8-4,2.2-5.5c1.5-1.5,3.3-2.2,5.5-2.2S636.8,381.1,638.3,382.7z"
          />
          <path
            fill="none"
            d="M640.5,388.2c0-2.2-0.8-4-2.2-5.5c-1.5-1.5-3.3-2.2-5.5-2.2c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5
	c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3s4-0.8,5.5-2.3C639.8,392.1,640.5,390.2,640.5,388.2z M647.5,386.8l-0.3,0.1v1.2
	c0,3.3-1,6.3-3,8.8l0.3,0.4h5.1c0.7-0.8,1.2-1.7,1.5-2.6v-0.3l0.3-1.1c0.3-1.2,0.3-2.3,0.3-3.4L647.5,386.8L647.5,386.8z"
          />
          <path
            fill="none"
            d="M647.1,388.2V387l-0.8,0.3c-1.5,0.5-2.6,1.5-3,3c-0.1,0.5-0.3,1.2-0.4,1.9c-0.3,1.4,0,2.8,1,4l0.4,0.7
	C646.1,394.3,647.1,391.5,647.1,388.2L647.1,388.2z"
          />
          <path
            fill="none"
            d="M644.2,396.8l-1.2,1.4c-1.4,1.4-2.9,2.5-4.5,3.2l0.1,0.3l15.3,19.9c1.1,1.1,1.1,2.1,0.3,2.8l-0.7,0.5
	c2.9-1.6,5.5-3.7,8.1-6.1l-0.5,0.3c-1,0.4-1.7,0-2.3-1.2l-14.2-20.8L644.2,396.8L644.2,396.8z"
          />
          <path
            fill="none"
            d="M638.6,401.6l-0.1-0.3c-1.7,0.7-3.6,1.1-5.5,1.1c-1.8,0-3.4-0.3-5-0.8l-0.3,0.3l1.5,5c1.1,0.4,2.2,0.7,3.3,0.6
	h1.1c1.2-0.1,2.3-0.4,3.3-1L638.6,401.6L638.6,401.6z"
          />
          <path
            fill="none"
            d="M638.3,401.4l-0.5-0.8c-1-1.4-2.2-1.9-3.7-2.1c-0.7,0-1.4,0.1-2.1,0.3c-1.5,0.1-2.8,0.8-3.6,2.1l-0.5,0.7
	c1.5,0.5,3.2,0.8,5,0.8C634.9,402.5,636.6,402,638.3,401.4L638.3,401.4z"
          />
          <path
            fill="none"
            d="M636.9,406.5l3.4,4.3l6.7,8.4c1,1,0.7,2.1-0.7,3.2c-4.3,1.6-8.8,2.6-13.8,2.6c-4.8,0-9.4-0.8-13.6-2.5
	c-1.2-1-1.4-1.8-0.3-2.8l6.9-8.5l3.3-4.1l-1.5-5l-13.6,17.7L612,422c-1,1-1.1,1.8-0.4,2.6l0.7,0.7c6.1,3.3,12.8,5.1,20.2,5.1
	c7.6,0,14.4-1.8,20.6-5.2l0.5-0.5c0.8-0.8,0.8-1.6-0.3-2.8L638,402L636.9,406.5L636.9,406.5z"
          />
          <path
            fill="none"
            d="M640.5,410.7l-3.4-4.3c-1,0.4-2.2,0.8-3.3,1h-1.1c-1.1,0-2.2-0.1-3.3-0.5l-3.3,4.1c2.2,0.7,4.5,1,7,1
	S638,411.5,640.5,410.7L640.5,410.7z M628.8,375v-0.7c-2.3,0.5-4.3,1.8-6.2,3.7c-0.8,0.8-1.5,1.6-2.1,2.5l0.8,0.3
	c1.5,0.5,2.9,0.3,4.1-0.5c0.6-0.4,1.1-0.8,1.5-1.5C628.3,377.8,628.8,376.6,628.8,375L628.8,375z M624.7,370.7l0.3-5.2
	c-1.2,0.4-2.5,1-3.7,1.6c-1.4,0.8-2.8,1.7-4,2.8c-0.4,0.4-0.8,0.8-1.4,1.2c-1,1-1.8,1.9-2.6,2.9l5.2,1.4c0.7-1.1,1.4-1.9,2.2-2.8
	l0.6-0.4C622.3,371.5,623.4,371,624.7,370.7L624.7,370.7z"
          />
          <path
            fill="none"
            d="M628.8,373.7l-4.1-3.2c-1.2,0.3-2.3,1-3.3,1.8l-0.5,0.4c-0.8,0.7-1.7,1.6-2.2,2.8l1.7,4.8l0.4,0.1
	c0.5-1,1.2-1.8,2.1-2.5c1.8-1.8,3.9-3,6.2-3.7v-0.5L628.8,373.7L628.8,373.7z M617.4,370c1.2-1.1,2.6-2.1,4-2.8l-15.5-3.7
	c-1,1-1.8,2.1-2.6,3L617.4,370L617.4,370z"
          />
          <path
            fill="none"
            d="M613.4,374.1c0.8-1,1.7-2.1,2.6-2.9c0.4-0.4,1-0.8,1.4-1.2l-14.2-3.4l-1.7,2.3c-0.4,1.5,0,2.2,1.4,2.5
	L613.4,374.1L613.4,374.1z M618.5,387.6l-0.5-0.1l-4.1,3c-0.1,1.2,0.1,2.5,0.7,3.7l0.3,0.7c0.4,1,1.1,1.9,1.9,2.9h5.2l0.1-0.3
	c-2.3-2.6-3.4-5.8-3.4-9.2L618.5,387.6L618.5,387.6z"
          />
          <path
            fill="none"
            d="M619.2,387.9l-0.7-0.3v0.5c0,3.6,1.1,6.6,3.4,9.2l0.5-0.8c1-1.4,1.2-2.9,0.7-4.4c-0.3-0.5-0.4-1-0.8-1.5
	C621.8,389.3,620.7,388.3,619.2,387.9z M609,388.6l-8.1-3v9.5l11.1,4.8C610.1,396.4,609,392.7,609,388.6L609,388.6z M600.9,385.4
	l-2.3-1c-1.1-0.5-1.9-0.3-2.3,0.8l-0.1,2.8c0,4.1,0.5,8,1.8,11.7h3L600.9,385.4L600.9,385.4z"
          />
          <path
            fill="none"
            d="M604.9,412c1.2,0.8,2.2,0.7,2.8-0.5l5.9-9.1c-0.5-0.8-1.1-1.5-1.7-2.3l-11.1-4.8v4.8h-3
	C599.4,404.3,601.7,408.2,604.9,412L604.9,412z M627.7,401.9l0.3-0.3c-1.9-0.7-3.6-1.8-5.2-3.4l-0.8-0.8l-0.1,0.3l-12.7,18.4
	c1.5,1.4,3.3,2.6,5,3.6L627.7,401.9L627.7,401.9z"
          />
          <path
            fill="none"
            d="M614.6,394.8l-0.3-0.7c-0.6-1.2-0.7-2.5-0.7-3.7l-4.8-1.8c0.1,4.1,1.1,8,3,11.3c0.4,0.8,1,1.7,1.7,2.3l2.9-4.5
	C615.7,396.7,615,395.7,614.6,394.8L614.6,394.8z M632.8,412c-2.5,0-4.8-0.3-7-1l-6.9,8.5c-1,1-1,1.9,0.3,2.8
	c4.1,1.6,8.7,2.5,13.6,2.5s9.5-0.8,13.8-2.6c1.4-1.1,1.5-2.2,0.7-3.2l-6.7-8.4C638,411.5,635.5,412,632.8,412z M604.9,419.4
	l-0.3-0.1c2.5,2.3,5.2,4.3,8.1,5.8l-0.7-0.7c-0.7-0.8-0.5-1.6,0.4-2.6l1.7-2.2c-1.7-1.1-3.3-2.3-5-3.6l-1.5,2.2
	C606.8,419.7,606,420.1,604.9,419.4L604.9,419.4z M715.7,370c1,6.9,1.8,14,2.5,21.7l68.2-9.2l15-0.5l2.8,0.1l13.2-16.4
	c-12.9-1.4-24.1-1.2-33.4,0.3L715.7,370z"
          />
          <path
            fill="none"
            d="M810.9,382.7l5.5-10.6l3.4-6.6l-2.5,0.3L804,382.2 M809.6,385.4l1.4-2.8l-6.9-0.5l-2.6-0.1l1,3.4 M416.3,165.7
	l-15.7,0.6l-14.9,1.4c-6.2,0.7-12.2,1.5-17.9,2.6l-1.4,0.3c2.2,0.6,5.9,0.6,10.9-0.1l9.2-1.1l14-1.2l15.7-0.6l11.6-0.1v-1.6h-11.5
	V165.7z M386.4,171.8c-19.7,2.6-35.8,7.3-48.3,14.3L335,188c-21.5,12.9-45.8,31-72.9,54.1c2.1,0.7,2.8,2.9,1.9,6.6
	c-5.9,23.9-8,50.8-5.8,80.6c1.8,23,4.5,42.9,8.3,59.6h178.4V265.3l-138.8,2.9c-14.7,0.3-23-3-24.8-9.6c-1.1-5.8,0.3-10.9,3.9-15.3
	l18.6-15.7l0.1-0.1l3.2-2.8c15-12.5,28.9-22.3,41.7-29.2c14.6-8.3,35.6-13.1,63.1-14.2l14.3-0.3h0.3c3.7,0.4,5.6,3.6,5.9,9.6
	l1.4,67.4c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.1-0.4,0.3-0.8,0.3l12-0.3c-0.1-32.5-1-62.2-2.6-89.3l-16.5,0.1c-9.8,0.1-18.4,0.3-26,0.6
	L386.4,171.8L386.4,171.8z M267.1,335.1c-0.5-2.2,0.5-3.3,3.6-3.3H443v16.1H273.8c-2.9,0.1-4.5-0.7-5-2.5L267.1,335.1L267.1,335.1z
	 M431.1,282.8c1,0,1.5,0.4,1.6,1.2c0.3,1.4,0.4,3,0.1,4.7c-0.1,0.8-0.7,1.4-1.5,1.4l-8.7-0.1l-7-0.1c-0.1,2.1-0.8,3.7-2.2,5
	c-0.7,0.5-1.5,1.1-2.5,1.4l-1,0.3l-4.1,0.4h-0.5c-1.2,0-2.3-0.1-3.6-0.4l-1-0.3c-1-0.3-1.8-0.8-2.5-1.4c-1.4-1.2-2.1-3-2.2-5.2h-1.9
	l-7.6-0.1c-1.2-0.3-1.9-1-2.2-1.8v-2.5c0.3-1.1,1-1.6,1.9-1.8l7.7-0.1h1.6c0.1-1.2,0.4-2.2,0.8-3c0.8-1.6,2.3-2.6,4.4-2.9l4.7-0.3
	l5,0.3c2.1,0.3,3.6,1.2,4.4,2.9c0.4,0.8,0.7,1.6,0.8,2.9l6.9-0.1L431.1,282.8L431.1,282.8z"
          />
          <path
            fill="none"
            d="M270.9,331.8c-3,0-4.3,1.1-3.6,3.3l1.6,10.2c0.4,1.8,2.1,2.6,5,2.5h169.2v-16H270.9L270.9,331.8z M432.8,284.2
	c-0.1-0.8-0.7-1.4-1.6-1.2l-8.4,0.1v7l8.7,0.1c0.8,0,1.4-0.4,1.5-1.4C433.2,287,433.2,285.5,432.8,284.2L432.8,284.2z M426,285.7
	c0.3-0.3,0.8-0.5,1.2-0.5s0.8,0.1,1.2,0.5c0.3,0.3,0.6,0.8,0.6,1.2s-0.1,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5s-1-0.1-1.2-0.5
	c-0.3-0.3-0.4-0.7-0.4-1.2C425.5,286.4,425.6,285.9,426,285.7L426,285.7z"
          />
          <path
            fill="none"
            d="M427.3,285.1c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.2s0.1,0.8,0.4,1.2c0.3,0.3,0.8,0.5,1.2,0.5
	s0.8-0.1,1.2-0.5c0.3-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-1-0.5-1.2C428.1,285.3,427.7,285.1,427.3,285.1L427.3,285.1z M422.7,290.1v-7.2
	L416,283l-20.1,0.1h-1.6v6.3l1.9,0.1l19.5,0.3"
          />
          <path
            fill="none"
            d="M415.2,280.2c-0.8-1.7-2.3-2.6-4.4-2.9l-5-0.3l-4.7,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.9-0.8,3
	l20.1-0.3C415.9,281.8,415.6,281,415.2,280.2L415.2,280.2z M386.4,283.3c-1,0.1-1.6,0.7-1.9,1.8v2.5c0.3,1,1,1.5,2.2,1.8l7.6,0.1
	v-6.3L386.4,283.3L386.4,283.3z M396.2,289.7c0.1,2.2,0.8,4,2.2,5.2c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5
	l4.1-0.4l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.1,2.1-2.8,2.2-5L396.2,289.7L396.2,289.7z M342.9,175.9l0.1,1c3.7-1.1,7.7-2.2,11.7-3.2
	l-0.4-0.8l-0.8-1.8l-5,1.2l-4.8,1.2l-1.1,0.3L342.9,175.9L342.9,175.9z"
          />
          <path
            fill="none"
            d="M348.7,172.3l0.1-6.1l-2.6,0.7l-2.3,6.6 M350,197.5c14.4-8.1,35.1-12.8,62.2-13.9l14.3-0.3
	c2.5,0.6,3.7,3,3.9,7.6l1.4,66.3l-118.2,2.9c-1.4,0.8-3.2,1.2-5.4,1.4l-1.9,0.1v0.8l126.6-3.2c0.3,0,0.5-0.1,0.8-0.3
	c0.1-0.3,0.3-0.5,0.3-0.8l-1.4-67.4c-0.3-6.1-2.2-9.2-5.9-9.6h-0.3l-14.2,0.3c-27.5,1.2-48.6,5.9-63.1,14.2
	c-12.9,6.9-26.8,16.6-41.7,29.2v2.9C322.4,214.7,336.8,204.6,350,197.5L350,197.5z"
          />
          <path
            fill="none"
            d="M350,197.5c-13.2,7.2-27.5,17.2-42.8,30.3l-0.3,8.7c3.7,0.6,6.2,2.1,7.4,4.5c1.1,2.2,1.8,5,2.2,8.4
	c0.1,1.8,0.3,3.7,0.3,5.8c-0.3,2.2-1.4,3.9-3.2,5l118.2-2.9l-1.4-66.3c-0.1-4.5-1.5-7-3.9-7.6l-14.3,0.3
	C385,184.7,364.4,189.4,350,197.5L350,197.5z M486.4,159.8v2.3h7.2v-2.3c0-0.4-0.1-0.6-0.5-0.6H487
	C486.6,159.2,486.4,159.5,486.4,159.8L486.4,159.8z M461.2,180.3c-3.2,0.3-4.8,2.5-5.2,6.7l3.6,70.3c0,0.3,0.1,0.5,0.4,0.8
	c0.1,0.1,0.4,0.3,0.7,0.3l84.2-2.5c18.2-0.1,27.8-4.1,28.9-12.2c0.1-1,0.1-1.9,0.1-2.9v-0.3c0-2.5-0.1-4.9-0.4-7.6l-1.9-10
	c-1.9-7.6-5-16-9.1-25c-4.4-11.7-16-17.5-34.7-17.6L461.2,180.3L461.2,180.3z M458.2,187.2c0.3-2.8,1.4-4.4,3.3-4.7l66.3-0.1
	c17.6,0,28.5,5.4,32.6,16.1c7.6,16.5,11.3,30.4,11.3,41.8v0.4c0.1,0.8,0.1,1.6,0,2.3c-1.2,6.9-10.2,10.3-26.8,10.3l-83.1,2.5
	L458.2,187.2L458.2,187.2z"
          />
          <path
            fill="none"
            d="M461.5,182.5c-1.9,0.3-3,1.8-3.3,4.7l3.6,68.9l83.1-2.5c16.6,0,25.5-3.4,26.8-10.3c0.1-0.8,0.1-1.5,0-2.3v-0.4
	c0.1-11.4-3.6-25.3-11.3-41.8c-4.3-10.7-15.1-16.1-32.6-16.1L461.5,182.5L461.5,182.5z"
          />
          <path
            fill="none"
            d="M456,187c0.4-4.3,2.1-6.5,5.2-6.7l66.6-0.1c18.7,0,30.4,5.9,34.7,17.6c4.1,9.1,7.2,17.5,9.1,25
	c0.8,3.4,1.5,6.9,1.9,10l1.8,9.5c0.6,6.7-0.7,11.1-3.7,13.3c-3.3,2.3-8.8,4.1-16.6,5.5l-3.7,0.7c-5.2,0.7-10.6,1.1-15.8,1l-90.4,2.3
	v123.5h106.2c1.5-16.9,4-32.5,7.4-46.8c4-21,15.8-34.4,35.8-39.9l1.5-0.3c0.3-18.3-0.3-33.7-1.5-46.2c-5.2-28.1-15.5-56.8-31-86
	h-8.3c-45.7-0.3-83.2-0.3-112.9,0c1.6,27.1,2.5,56.8,2.6,89.3l15.8-0.4c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.3-0.4-0.4-0.8L456,187
	L456,187z M580.4,285c-0.1,0.8-0.7,1.4-1.5,1.4l-8.7-0.1l-7-0.1c-0.1,2.1-0.8,3.7-2.2,5c-0.7,0.5-1.5,1.1-2.5,1.4l-1,0.3
	c-1.4,0.3-2.8,0.5-4.1,0.4h-0.5c-1.2,0-2.3-0.1-3.6-0.4l-1-0.3c-1-0.3-1.8-0.8-2.5-1.4c-1.4-1.2-2.1-2.9-2.2-5.2h-1.9l-7.6-0.1
	c-1.2-0.3-1.9-1-2.2-1.8v-2.5c0.3-1.1,1-1.6,1.9-1.8l7.7-0.1h1.7c0.1-1.2,0.4-2.2,0.8-3c0.8-1.6,2.3-2.8,4.4-2.9l4.8-0.3l5,0.3
	c2.1,0.3,3.6,1.2,4.4,2.9c0.4,0.8,0.7,1.6,0.8,2.9l6.9-0.1l8.4-0.1c1,0,1.5,0.4,1.7,1.4C580.5,281.7,580.7,283.2,580.4,285
	L580.4,285z M534.4,331.8h-0.5c1.5,0.3,1.8,2.2,0.5,5.8c-1.5,6.7-3.4,10.2-5.5,10.2h-81.4v-16H534.4L534.4,331.8z"
          />
          <path
            fill="none"
            d="M578.9,286.2c0.8,0,1.4-0.4,1.5-1.4c0.3-1.6,0.3-3.3-0.1-4.7c-0.1-1-0.7-1.4-1.7-1.4l-8.4,0.1v7.2L578.9,286.2
	L578.9,286.2z M562.5,276.3c-0.8-1.6-2.3-2.8-4.4-2.9l-5-0.3l-4.8,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.8-0.8,3l20.1-0.1
	C563.2,278,562.9,277,562.5,276.3L562.5,276.3z"
          />
          <path
            fill="none"
            d="M563.2,285.9l7,0.1v-7l-6.9,0.1l-20.1,0.1h-1.7v6.3h1.9"
          />
          <path
            fill="none"
            d="M558.5,292.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.1,2.1-2.8,2.2-5l-19.5-0.3c0.1,2.2,0.8,4,2.2,5.2
	c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5c1.4,0,2.8-0.1,4.1-0.4L558.5,292.3L558.5,292.3z M531.8,283.7
	c0.3,1,1,1.5,2.2,1.8l7.6,0.1v-6.3l-7.7,0.1c-1,0.1-1.7,0.7-1.9,1.8v2.5H531.8z M534.4,337.5c1.2-3.6,1-5.5-0.5-5.8h-86.4v16.1h81.4
	C531,347.7,532.8,344.3,534.4,337.5L534.4,337.5z M460.7,258.4l-15.8,0.4v6.5l90.4-2.3c5.2,0.1,10.6-0.3,15.8-1l3.7-0.7
	c7.8-1.4,13.5-3.3,16.6-5.5c3-2.2,4.3-6.6,3.7-13.3l-1.8-9.5c0.3,2.6,0.4,5.2,0.4,7.6v0.3c0.1,1,0,2.1-0.1,2.9
	c-1.1,8.1-10.7,12.1-28.9,12.2L460.7,258.4L460.7,258.4z M444.9,258.8l-12,0.4l-126.7,3.2l-0.1,5.8l138.8-2.9 M571.6,222.8l1.9,10
	C573.1,229.7,572.5,226.2,571.6,222.8z M656.5,160.9c0-0.4-0.1-0.6-0.5-0.6h-7c-0.4,0-0.5,0.1-0.5,0.6v2.3l8,0.1V160.9z
	 M225.5,247.3l-8.4,6.3l12.4,2.9h0.6c9.6-0.1,17.6-2.6,23.9-7.6l8-6.9c27.1-23.1,51.4-41.1,72.9-54.1l-12.5-4.4l-5.6,3.3l6.7,3.7
	c2.1,1.4,0.8,3.2-3.6,5.2c-23.9,16.8-46.6,33.6-68,50.2c-1.9,1.7-4.4,2.5-7.4,2.3L225.5,247.3L225.5,247.3z"
          />
          <path
            fill="none"
            d="M230,256.6h-0.6c-27.9,1.6-53.9,3.7-78,6.5c-31.5,4-56,8.4-73.7,13.1c-2.6,0.8-5,2.5-6.7,4.7l-1,1.1
	c-0.7,0.8-1.2,1.9-1.8,3l-1.7,4h22c2.1,0.1,2.9,2.1,2.5,5.8l-7.2,31.5l21-0.8c6.2-9.8,15.7-16.5,28.3-20.1l0.3-0.1
	c11.7-2.3,26.3-3.2,44-2.6h0.3c25.6-1.1,42.5,1.5,50.8,7.6c7.6,4.3,13.2,11.4,17.1,21.7v0.1c5,15,8.5,34,10.9,56.8h9.5
	c-3.7-16.6-6.5-36.6-8.3-59.6c-2.1-29.9-0.1-56.7,5.8-80.6c0.8-3.7,0.3-5.9-1.9-6.6l-8,6.9C247.6,253.9,239.6,256.5,230,256.6
	L230,256.6z"
          />
          <path
            fill="none"
            d="M225.5,247.3l19.1,1c3,0.1,5.5-0.7,7.4-2.3c21.3-16.8,43.9-33.4,68-50.2c4.4-2.1,5.6-3.9,3.6-5.2l-6.7-3.7
	C286.7,204,256.3,224.2,225.5,247.3z M285.3,243.3c-3.7,4.4-5,9.5-4,15.3c1.8,6.6,10,9.9,24.8,9.6l0.1-5.8l-4.8,0.1
	c-0.3,0-0.5-0.1-0.8-0.3l-0.3-0.4c-2.1-0.1-3.9-0.3-5.2-0.5c-4.8-2.6-7.4-6.3-7.8-10.9c-0.1-1.6,0.3-2.5,1.2-2.3h5.5l1.9-8.7
	c1-2.3,3-3.3,5.9-3l5.1,0.3l0.3-8.7v-2.9l-3.2,2.8l-0.1,0.1L285.3,243.3L285.3,243.3z"
          />
          <path
            fill="none"
            d="M288.5,247.8c-1-0.1-1.4,0.7-1.2,2.3c0.4,4.5,3,8.3,7.8,10.9c1.4,0.3,3.2,0.5,5.2,0.5h1.8l-1.8-0.7
	c-4-1.6-6.2-3.6-6.3-6.1h-3C289.3,253.1,288.5,250.7,288.5,247.8L288.5,247.8z"
          />
          <path
            fill="none"
            d="M288.5,247.8c0.1,2.8,1,5.2,2.5,7.2h3c0.3,2.5,2.3,4.4,6.3,6.1l1.8,0.7l4.1-0.1l1.9-0.1c2.2-0.1,4-0.7,5.4-1.4
	c1.9-1.1,2.9-2.8,3.2-5c0-2.1-0.1-4-0.3-5.8c-3.6,0-6.9,0-9.9-0.1c-5.1-0.3-9.2-0.7-12.7-1.2L288.5,247.8L288.5,247.8z"
          />
          <path
            fill="none"
            d="M314.3,241c-1.2-2.3-3.7-3.9-7.4-4.5l-5.1-0.3c-2.9-0.3-5,0.7-5.9,3l-1.9,8.7c3.3,0.7,7.6,1.1,12.7,1.2
	c3,0.1,6.3,0.1,9.9,0.1C316.1,245.9,315.4,243.2,314.3,241L314.3,241z M306.2,262.4v-0.8l-4.1,0.1h-1.8l0.3,0.4
	c0.1,0.3,0.4,0.3,0.8,0.3H306.2L306.2,262.4z M178.1,302.7h-0.3c-17.7-0.5-32.3,0.3-44,2.6l-0.3,0.1c-12.7,3.6-22.1,10.2-28.3,20.1
	l11.3-0.4c4.8-5.4,11.3-9.2,19.4-11.6c9.2-1.8,20.5-2.8,33.7-2.6h21.6c16.1,0.1,27.1,2.3,33,6.5l0.4,0.3c5.9,3.4,10.3,9.2,13.2,17.5
	c4.5,14.3,7.6,32.3,9.5,53.9h9.5c-2.3-22.8-5.9-41.8-10.9-56.8v-0.1c-3.9-10.2-9.5-17.5-17.1-21.7
	C220.8,304.2,203.9,301.8,178.1,302.7L178.1,302.7z"
          />
          <path
            fill="none"
            d="M191.2,310.8h-21.6c-13.2-0.1-24.5,0.8-33.7,2.6c-8.1,2.3-14.6,6.2-19.4,11.6c-5.1,5.6-8.4,13.1-10,22.3
	l-0.8,4.1c7.6-2.3,14.3-3.6,19.9-3.6l2.9-3.2c2.6-2.6,5.4-5,8.3-7c10.2-7.3,21.9-11,35.1-11s24.9,3.7,35.2,11c2.9,2.1,5.6,4.4,8.3,7
	c11.1,11.1,17.2,24.5,17.9,39.9l14.2,7.4l-0.3-3c-1.8-21.6-5-39.6-9.5-53.9c-2.9-8.3-7.3-14-13.2-17.5l-0.4-0.3
	C218.5,313,207.4,311,191.2,310.8L191.2,310.8z"
          />
          <path
            fill="none"
            d="M207.3,337.5c-10.2-7.3-21.9-11-35.2-11c-13.2,0-24.9,3.7-35.1,11c-2.9,2.1-5.6,4.4-8.3,7l-2.9,3.2
	c-7.7,8.7-12.4,18.6-14.2,29.7c-0.6,3.4-0.8,7-0.8,10.6c0,16.9,6.1,31.5,18,43.5c12,12,26.6,18,43.5,18s31.5-6.1,43.5-18
	c5.4-5.4,9.6-11.3,12.5-17.7l1.1-2.5c0.8-2.2,1.6-4.4,2.2-6.6c1.4-5.2,2.1-10.7,2.1-16.6l-0.1-3.6c-0.8-15.4-6.7-28.8-17.9-39.9
	C213,341.9,210.2,339.6,207.3,337.5L207.3,337.5z M172.1,343.9c12.2,0,22.7,4.3,31.4,12.9s12.9,19.1,12.9,31.4
	c0,8.7-2.2,16.4-6.5,23.2l-1.6,2.5c-1.4,1.9-3,3.9-4.8,5.6c-8.7,8.7-19.1,13.1-31.4,13.1s-22.7-4.4-31.4-13.1s-12.9-19.1-12.9-31.4
	c0-12.2,4.3-22.7,12.9-31.4C149.5,348.1,159.8,343.9,172.1,343.9L172.1,343.9z"
          />
          <path
            fill="none"
            d="M203.5,356.8c-8.7-8.7-19.1-12.9-31.4-12.9s-22.7,4.3-31.4,12.9s-12.9,19.1-12.9,31.4
	c0,12.2,4.3,22.7,12.9,31.4c8.7,8.7,19.1,13.1,31.4,13.1s22.7-4.4,31.4-13.1c1.8-1.8,3.4-3.7,4.8-5.6l1.6-2.5
	c4.3-6.9,6.5-14.6,6.5-23.2C216.4,375.9,212.1,365.5,203.5,356.8L203.5,356.8z M172.1,346.1l4,0.1h0.4c9.8,1,18.2,5,25.3,12.1
	c6.1,6.1,9.9,13.1,11.6,21c0.6,2.8,0.8,5.8,0.8,8.8c0,11.7-4.1,21.6-12.4,29.9l-1.1,1c-2.5,2.3-5.2,4.4-8,6.1
	c-6.2,3.6-13.1,5.2-20.6,5.2c-7.4,0-14.2-1.6-20.2-5.1c-2.9-1.5-5.5-3.6-8.1-5.8l-1.5-1.4c-8.3-8.3-12.4-18.2-12.4-29.9
	c0-2.8,0.3-5.5,0.7-8.1c0.6-3.2,1.5-6.3,2.9-9.1c2.1-4.5,5-8.7,8.8-12.5c7-7,15.1-11,24.6-12.1l0.6-0.1h0.1L172.1,346.1L172.1,346.1
	z"
          />
          <path
            fill="none"
            d="M167.7,346.2c1,0.3,1.2,1,1.1,2.3l-0.7,25.2v0.5c1.2-0.4,2.6-0.5,4-0.5l3.4,0.4v-0.4l-0.7-25.2
	c-0.3-1.5,0.3-2.3,1.4-2.5l-4-0.1L167.7,346.2L167.7,346.2z"
          />
          <path
            fill="none"
            d="M168.8,348.7c0.1-1.4-0.1-2.1-1.1-2.3H167c-9.4,1.1-17.6,5.1-24.6,12.1c-3.9,3.9-6.7,8-8.8,12.5v0.3
	c-0.3,1.1,0.3,1.9,1.8,2.2l24.1,7.2l-1.6-4.8l-5.2-1.4l-10.6-2.9c-1.4-0.1-1.8-1-1.4-2.5c1.5-2.3,3.3-4.5,5.4-6.7l1.2-1.1
	c1.4-1.2,2.8-2.5,4.3-3.4c3.4-2.3,7.3-4.1,11.3-5.1l0.4-0.1c1.2,0.1,1.6,0.8,1.5,2.1l-0.6,11.1l-0.3,5.2l4.1,3.2L168.8,348.7
	L168.8,348.7z M176.2,346.2c-1.1,0.1-1.7,1-1.4,2.5l0.7,25.2l4.1-3.2l-0.3-5.4l-0.3-6.7l-0.3-4.1c-0.3-1.4,0.4-2.1,1.9-2.1
	c1.2,0.3,2.5,0.5,3.7,1c1.5,0.5,3.2,1.2,4.5,2.1c3.3,1.6,6.3,4,9.1,6.7c1.9,1.9,3.6,4,5.1,6.2l0.1,0.1c0.4,1.2-0.1,2.1-1.4,2.2
	L193,373l-6.7,1.8H186l-1.6,4.8l24.1-7.2c1.4-0.3,2.1-0.8,1.9-1.8l-0.3-0.5l0.3,0.5c0.1,1-0.6,1.6-1.9,1.8l-24.1,7.2l-0.6,0.1
	c1.5,2.1,2.3,4.4,2.5,6.9l0.3-0.1l23.7-8.4c1.4-0.7,2.2-0.4,2.8,0.7l0.3,0.3c-1.7-8-5.5-14.9-11.6-21c-7.2-7.2-15.5-11.1-25.3-12.1
	L176.2,346.2L176.2,346.2z M167.7,346.2h-0.1l-0.6,0.1"
          />
          <path
            fill="none"
            d="M180.6,352.3c-1.5,0-2.1,0.7-1.9,2.1l0.3,4.1l5.4-5.2C183.1,352.9,181.9,352.5,180.6,352.3L180.6,352.3z
	 M184.3,353.4l-5.4,5.2l0.3,6.7l9.6-9.9C187.5,354.6,186,353.9,184.3,353.4z M198.1,362.2c-2.8-2.8-5.8-5.1-9.1-6.7l-9.6,9.9
	c3.6,1.1,6.9,3,9.6,5.9l0.3,0.3h3l0.7,1.5l8.7-2.3c1.4-0.1,1.8-1,1.4-2.2l-0.1-0.1C201.7,366.1,200,364.1,198.1,362.2L198.1,362.2z
	 M213.2,379.1c-0.4-1.1-1.4-1.4-2.8-0.7l-23.7,8.4l4.1,3l7.2-2.8l8.1-3.2c1.2-0.5,2.1-0.3,2.6,1.1l0.1,3c0,1.5-0.1,3-0.3,4.4
	c-0.3,2.6-0.8,5.1-1.6,7.4c-1.4,4.1-3.6,8-6.5,11.4l-0.1,0.1c-1.1,0.8-2.1,0.5-2.6-0.7l-4-6.1l-0.7-1l-4.3-6.6h-5.1l14.2,20.8
	c0.7,1.2,1.5,1.6,2.3,1.2c0.1-0.1,0.3-0.3,0.4-0.3l1.1-1c8.3-8.3,12.4-18.2,12.4-29.9c0-3-0.3-5.9-0.8-8.8L213.2,379.1L213.2,379.1z
	"
          />
          <path
            fill="none"
            d="M208.8,388.2l-0.1-3c-0.6-1.4-1.4-1.8-2.6-1.1l-8.1,3.2v0.3c0.4,1.4-0.1,2.3-1.6,2.8v2.3h12.2
	C208.8,391,208.8,389.7,208.8,388.2L208.8,388.2z M189.3,371.5l-0.3-0.3c-2.9-2.9-6.1-4.8-9.6-5.9l0.3,5.4c1.2,0.3,2.2,0.7,3.2,1.2
	l0.8,0.5c1.1,0.7,1.9,1.5,2.5,2.5h0.3l0.8-3.3L189.3,371.5L189.3,371.5z"
          />
          <path
            fill="none"
            d="M192.5,371.5h-5.2l-0.8,3.4l6.7-1.9 M198.1,387.5v-0.3l-7.2,2.8c0.1,1.1,0,2.2-0.3,3.4l-0.3,1.1l-0.1,0.3
	c-0.3,1-0.8,1.9-1.5,2.6l4.3,6.6c1-1.6,1.8-3.6,2.3-5.4c0.6-1.9,0.8-3.9,1-5.9v-2.3C198,389.7,198.5,388.8,198.1,387.5L198.1,387.5z
	 M186.1,375c-0.6-1.1-1.5-1.9-2.5-2.5l-0.8-0.5c-1-0.5-2.1-1-3.2-1.2l-4.1,3.2v0.4c2.5,0.5,4.8,1.9,6.7,3.9c0.7,0.7,1.2,1.2,1.7,1.9
	l0.6-0.1L186.1,375L186.1,375z"
          />
          <path
            fill="none"
            d="M175.5,375v-0.8l-3.4-0.4c-1.4,0-2.8,0.1-4,0.5v0.7c0,1.6-0.6,2.9-1.8,3.9c-0.6,0.7-1,1.1-1.5,1.5
	c-1.2,0.8-2.6,1-4.1,0.5l-0.8-0.3c-1.4,2.1-2.1,4.4-2.1,7l0.7,0.3c1.5,0.4,2.5,1.4,3,2.9l0.8,1.5c0.6,1.5,0.4,3-0.7,4.4l-0.6,0.8
	l0.8,0.8c1.5,1.5,3.3,2.6,5.2,3.4l0.4-0.7c1-1.2,2.2-1.9,3.7-2.1c0.7-0.3,1.4-0.3,2.1-0.3c1.5,0,2.8,0.7,3.7,2.1l0.6,0.8
	c1.6-0.7,3.2-1.8,4.5-3.2l1.2-1.4l-0.4-0.7c-1-1.2-1.2-2.6-1-4c0.1-0.7,0.3-1.4,0.4-1.9c0.4-1.5,1.5-2.5,3-3l0.8-0.3
	c-0.3-2.6-1.1-5-2.5-6.9l-0.7,0.3c-1.5,0.5-2.9,0.3-4.1-0.4c-0.6-0.3-1.1-0.4-1.6-0.8C176.1,378,175.5,376.7,175.5,375L175.5,375z
	 M166.6,382.7c1.5-1.5,3.3-2.2,5.5-2.2c2.1,0,4,0.8,5.5,2.2c1.5,1.5,2.2,3.3,2.2,5.5c0,2.1-0.8,4-2.2,5.5c-1.5,1.5-3.3,2.3-5.5,2.3
	c-2.1,0-4-0.8-5.5-2.3s-2.2-3.3-2.2-5.5S165.2,384.2,166.6,382.7z"
          />
          <path
            fill="none"
            d="M172.1,380.5c-2.1,0-4,0.8-5.5,2.2c-1.5,1.5-2.2,3.3-2.2,5.5c0,2.1,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3
	c2.1,0,4-0.8,5.5-2.3s2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5C176.1,381.1,174.3,380.5,172.1,380.5L172.1,380.5z M175.5,374.1v0.8
	c0,1.6,0.7,3,2.1,4c0.4,0.3,1,0.5,1.6,0.8c1.2,0.8,2.6,1,4.1,0.4l0.7-0.3c-0.6-0.7-1.1-1.4-1.6-1.9
	C180.2,376.1,178,374.7,175.5,374.1L175.5,374.1z M168.1,375v-0.7c-2.3,0.5-4.3,1.8-6.1,3.7c-0.8,0.8-1.5,1.6-2.1,2.5l0.8,0.3
	c1.5,0.5,2.9,0.3,4.1-0.5c0.6-0.4,1.1-0.8,1.5-1.5C167.6,377.8,168.1,376.6,168.1,375L168.1,375z"
          />
          <path
            fill="none"
            d="M168.1,374.4v-0.5l-4.1-3.2c-1.2,0.3-2.3,1-3.3,1.8l-0.6,0.4c-0.8,0.7-1.6,1.6-2.2,2.8l1.6,4.8l0.4,0.1
	c0.6-1,1.2-1.8,2.1-2.5C163.8,376.2,165.8,375,168.1,374.4L168.1,374.4z M186.7,386.8l-0.3,0.1v1.2c0,3.3-1,6.3-3,8.8l0.3,0.4h5.1
	c0.7-0.8,1.2-1.7,1.5-2.6l0.1-0.3l0.3-1.1c0.3-1.2,0.3-2.3,0.3-3.4L186.7,386.8L186.7,386.8z"
          />
          <path
            fill="none"
            d="M185.6,387.2c-1.5,0.5-2.6,1.5-3,3c-0.1,0.5-0.3,1.2-0.4,1.9c-0.3,1.4,0,2.8,1,4l0.4,0.7c1.9-2.5,3-5.4,3-8.8
	v-1.2L185.6,387.2L185.6,387.2z"
          />
          <path
            fill="none"
            d="M177.6,401.4l0.1,0.3l15.3,19.9c1.1,1.1,1.1,2.1,0.3,2.8l-0.6,0.5c2.8-1.6,5.5-3.7,8-6.1
	c-0.1,0-0.3,0.1-0.4,0.3c-1,0.4-1.6,0-2.3-1.2l-14.2-20.8l-0.3-0.4l-1.2,1.4C180.9,399.6,179.2,400.7,177.6,401.4L177.6,401.4z
	 M177.7,401.6l-0.1-0.3c-1.6,0.7-3.6,1.1-5.5,1.1c-1.8,0-3.4-0.3-5-0.8l-0.3,0.3l1.5,5c1.1,0.4,2.1,0.7,3.3,0.6h1.1
	c1.2-0.1,2.3-0.4,3.3-1L177.7,401.6L177.7,401.6z"
          />
          <path
            fill="none"
            d="M177.6,401.4l-0.6-0.8c-1-1.4-2.2-1.9-3.7-2.1c-0.6,0-1.2,0.1-2.1,0.3c-1.5,0.1-2.8,0.8-3.7,2.1l-0.4,0.7
	c1.5,0.5,3.2,0.8,5,0.8C174,402.5,175.9,402,177.6,401.4L177.6,401.4z M166.9,401.9l0.3-0.3c-1.9-0.7-3.6-1.8-5.2-3.4l-0.8-0.8
	l-0.1,0.3l-12.7,18.4c1.5,1.4,3.3,2.6,5,3.6L166.9,401.9L166.9,401.9z M171.7,407.4c-1.1,0-2.2-0.1-3.3-0.5l-3.3,4.1
	c2.2,0.7,4.5,1,7,1c2.8,0,5.2-0.4,7.6-1.1l-3.4-4.3c-1,0.4-2.2,0.8-3.3,1L171.7,407.4L171.7,407.4z"
          />
          <path
            fill="none"
            d="M176.2,406.5l3.4,4.3l6.7,8.4c1,1,0.7,2.1-0.6,3.2c-4.3,1.6-8.8,2.6-13.8,2.6c-4.8,0-9.4-0.8-13.6-2.5
	c-1.2-1-1.4-1.8-0.3-2.8l6.9-8.5l3.3-4.1l-1.5-5l-13.6,17.7l-1.6,2.2c-1,1-1.1,1.8-0.4,2.6l0.7,0.7c6.1,3.3,12.8,5.1,20.2,5.1
	c7.6,0,14.4-1.8,20.6-5.2l0.6-0.5c0.8-0.8,0.8-1.6-0.3-2.8L177.6,402L176.2,406.5L176.2,406.5z"
          />
          <path
            fill="none"
            d="M179.7,410.7c-2.3,0.8-5,1.1-7.6,1.1c-2.5,0-4.8-0.3-7-1l-6.9,8.5c-1,1-0.8,1.9,0.3,2.8
	c4.1,1.6,8.7,2.5,13.6,2.5c4.9,0,9.5-0.8,13.8-2.6c1.4-1.1,1.5-2.2,0.6-3.2L179.7,410.7L179.7,410.7z M196.4,392.6
	c-0.1,2.1-0.4,4-1,5.9l13.2-5.9H196.4z"
          />
          <path
            fill="none"
            d="M195.5,398.5c-0.6,1.9-1.4,3.7-2.3,5.4l0.7,1c4.7-0.4,9.1-2.1,13.2-5c0.8-2.3,1.4-4.8,1.6-7.4L195.5,398.5
	L195.5,398.5z"
          />
          <path
            fill="none"
            d="M200.3,411.5c3-3.6,5.2-7.4,6.6-11.6c-4.1,2.9-8.5,4.5-13.2,5l4,6.1C198.4,412.1,199.2,412.4,200.3,411.5
	L200.3,411.5z M147.3,360.9l-1.2,1.1c-2.1,2.1-3.9,4.4-5.4,6.7c-0.4,1.5,0,2.2,1.4,2.5l10.6,2.9c0.8-1,1.6-2.1,2.5-2.9
	c1.2-1.2,2.6-2.3,4-3.3L147.3,360.9L147.3,360.9z M151.7,357.5c-1.5,1-2.9,2.2-4.3,3.4l11.8,7c1.5-1,3.3-1.9,5-2.5L151.7,357.5
	L151.7,357.5z"
          />
          <path
            fill="none"
            d="M162.9,352.4c-4,1-7.8,2.6-11.3,5.1l12.5,8l0.6-11.1c0.3-1.2-0.3-1.9-1.5-2.1L162.9,352.4L162.9,352.4z
	 M160.1,372.9l0.6-0.4c1-0.8,2.1-1.4,3.3-1.8l0.3-5.2c-1.8,0.6-3.4,1.5-4.9,2.5c-1.4,1-2.8,2.1-4,3.3c-1,1-1.8,1.9-2.5,2.9l5.2,1.4
	C158.5,374.5,159.3,373.6,160.1,372.9L160.1,372.9z M148.3,388.6l4.8,1.8l4.1-3l-23.7-8.4c-1.2-0.7-2.1-0.6-2.6,0.4l-0.1,0.6
	c-0.4,2.6-0.7,5.2-0.7,8.1c0,11.7,4.1,21.6,12.4,29.9l1.5,1.4l0.3,0.1c1,0.7,1.9,0.3,2.6-1.1l1.5-2.2l12.7-18.4h-5.2l-2.9,4.5
	l-3,4.5l-3,4.5c-0.6,1.2-1.5,1.5-2.8,0.5c-1.9-2.2-3.6-4.7-5-7.2c-1.6-3.3-2.9-7-3.4-10.9c-0.3-1.9-0.4-3.9-0.4-5.9
	c0-0.8,0-1.5,0.1-2.3v-0.4c0.4-1.1,1.2-1.4,2.3-0.8l5.2,2.1L148.3,388.6L148.3,388.6z"
          />
          <path
            fill="none"
            d="M153.1,390.4l-4.8-1.8c0.1,4,1,7.6,2.8,10.9c0.6,1,1.1,1.9,1.8,2.8l2.9-4.5c-0.8-1-1.5-1.9-1.9-2.9l-0.3-0.7
	C153.2,393,153,391.7,153.1,390.4L153.1,390.4z M161.5,390.6c-0.6-1.4-1.5-2.3-3-2.9l-0.7-0.3v0.5c0,3.6,1.1,6.6,3.4,9.2l0.6-0.8
	c1-1.4,1.2-2.9,0.7-4.4L161.5,390.6L161.5,390.6z"
          />
          <path
            fill="none"
            d="M157.8,387.6c0.1-2.6,0.8-5,2.1-7l-0.4-0.1l-24.1-7.2c-1.5-0.3-2.1-1-1.8-2.2v-0.3c-1.4,2.9-2.3,5.9-2.9,9.1
	l0.1-0.5c0.6-1,1.4-1,2.6-0.4l23.7,8.4L157.8,387.6L157.8,387.6z"
          />
          <path
            fill="none"
            d="M157.8,388.2v-0.5l-0.6-0.1l-4.1,3c-0.1,1.2,0.1,2.5,0.7,3.7l0.3,0.7c0.4,1,1.1,1.9,1.9,2.9h5.2l0.1-0.3
	C158.9,394.8,157.8,391.6,157.8,388.2L157.8,388.2z M143.1,386.6l-5.2-2.1c-1.1-0.5-1.9-0.3-2.3,0.8v0.4c0,0.8-0.1,1.5-0.1,2.3
	c0,2.1,0.1,4,0.4,5.9c2.6,1.6,5.1,2.9,7.7,3.9L143.1,386.6L143.1,386.6z"
          />
          <path
            fill="none"
            d="M135.8,393.9c0.6,3.9,1.6,7.4,3.4,10.9c3.9,1.6,7.4,2.3,10.6,2.1l3-4.5c-0.7-1-1.2-1.8-1.8-2.8
	c-2.5-0.1-5.1-0.7-7.6-1.6S138.4,395.6,135.8,393.9z"
          />
          <path
            fill="none"
            d="M139.2,404.8c1.4,2.5,2.9,5,5,7.2c1.2,0.8,2.2,0.7,2.8-0.5l3-4.5C146.6,407.1,143.1,406.5,139.2,404.8
	L139.2,404.8z M148.3,388.6l-5.2-1.9l0.6,11.3c2.5,0.8,5,1.4,7.6,1.6C149.3,396.1,148.3,392.6,148.3,388.6L148.3,388.6z
	 M151.9,425.2l-0.7-0.7c-0.7-0.8-0.6-1.6,0.4-2.6l1.6-2.2c-1.8-1.1-3.4-2.3-5-3.6l-1.5,2.2c-0.7,1.4-1.5,1.8-2.6,1.1l-0.3-0.1
	C146.4,421.6,149.1,423.5,151.9,425.2L151.9,425.2z M233.4,384.6l0.1,3.6c0,5.8-0.7,11.4-2.1,16.6h8.7l1.1-1.4h317.6v-7.2H248
	l-0.3-4.1L233.4,384.6L233.4,384.6z"
          />
          <path fill="none" d="M247.6,392l0.3,4.1h9.6V396l-0.5-7h-9.6" />
          <path
            fill="none"
            d="M266.3,389H257l0.5,7v0.1h301.4l0.6-7.2H444.9 M91.3,294.9c0.4-3.7-0.4-5.6-2.5-5.8H66.6l-18,1.9l-2.5,10.5
	l-1.8,11.7l-1.2,13.5v0.1l-0.3,3l3.4-0.5h0.3l15.4-1.8h0.7l1.8-0.1l19.8-1.1L91.3,294.9L91.3,294.9z M87.9,289.9
	c2.1,0.3,2.6,1.9,1.8,5.1l-6.5,29.2l-6.1,0.5l5.9-34.3L87.9,289.9L87.9,289.9z M70.7,294.9v-2.1l9.9-1l-4,23l-17.9-0.7v-18.6
	L70.7,294.9L70.7,294.9z M76.6,316.8l-0.7,8.3l-15.8,1.4l-10.7,0.8l-1.8,0.1c0.4-0.8,0.7-1.9,0.7-3.4v-3.6c0-1.1,0.3-1.8,1.2-2.1
	h0.3L76.6,316.8L76.6,316.8z"
          />
          <path
            fill="none"
            d="M89.7,295c0.8-3.2,0.3-5-1.8-5.1l-4.8,0.5l-5.9,34.3l6.1-0.6L89.7,295L89.7,295z M70.7,292.8v2.1l-12,0.7v18.7
	l18,0.7l4-23.1 M75.9,325l0.7-8.3l-27.1,1.5h-0.3c-0.8,0.3-1.2,1-1.2,2.1v3.6c-0.1,1.5-0.3,2.6-0.7,3.4l1.8-0.1l10.7-0.8L75.9,325
	L75.9,325z M69.5,321.6c0,0.7-0.1,1.2-0.6,1.6c-0.3,0.4-0.8,0.7-1.2,0.7c-0.6,0-1-0.3-1.2-0.7c-0.3-0.4-0.6-1-0.6-1.6
	s0.1-1.2,0.6-1.6c0.3-0.4,0.8-0.7,1.2-0.7c0.6,0,1,0.3,1.2,0.7C69.2,320.3,69.5,320.9,69.5,321.6L69.5,321.6z M52.3,320.5
	c0.3-0.1,0.5-0.3,0.8-0.3l0.5,0.1l0.3,0.1l0.4,0.4l0.1,0.1l0.4,0.7l0.1,0.7v0.3c0,0.5-0.1,1.1-0.4,1.5l-0.1,0.1
	c-0.3,0.4-0.7,0.5-1,0.7h-0.3l-0.7-0.3l-0.3-0.1l-0.3-0.3c-0.3-0.4-0.5-1-0.5-1.6s0.1-1.2,0.5-1.6l0.3-0.3L52.3,320.5L52.3,320.5z"
          />
          <path
            fill="none"
            d="M68.9,323.2c0.3-0.4,0.6-1,0.6-1.6s-0.1-1.2-0.6-1.6c-0.3-0.4-0.8-0.7-1.2-0.7c-0.6,0-1,0.3-1.2,0.7
	c-0.3,0.4-0.6,1-0.6,1.6s0.1,1.2,0.6,1.6c0.3,0.4,0.8,0.7,1.2,0.7C68.1,323.9,68.5,323.6,68.9,323.2L68.9,323.2z M53,320.2
	c-0.3,0-0.5,0.1-0.8,0.3l-0.3,0.1l-0.3,0.3c-0.3,0.4-0.5,1-0.5,1.6s0.1,1.2,0.5,1.6l0.3,0.3l0.3,0.1l0.7,0.3h0.3
	c0.4-0.1,0.7-0.3,1-0.7l0.1-0.1c0.3-0.4,0.4-1,0.4-1.5v-0.3l-0.1-0.7l-0.4-0.7l-0.1-0.1l-0.4-0.4l-0.3-0.1L53,320.2L53,320.2
	L53,320.2z M116.5,325l-11.3,0.4c-1.5,2.5-2.9,5.2-4.1,8.1c-1.5,3.7-2.6,7.6-3.4,11.8c-1.4,6.1-2.6,12.2-3.7,18.7l-62.3,1.8l0.4,1.5
	c4.3,11,9.9,20.9,16.6,30l14.6,1l16.8,2.8h19.5v-2.8c0.3-5.4,0.7-10.6,1.2-15.7c1.1-11,2.6-21.5,4.7-31.2l0.8-4.1
	C108.1,338.2,111.4,330.8,116.5,325L116.5,325z M62.5,386.6l-0.1,0.7c-0.4,1.4-1.5,2.2-3.6,2.8l-4.5,0.7c-2.1,0.4-3.7-0.3-5-1.9
	c-4.4-6.3-7.6-11.8-9.2-16.5c-0.1-1.8,0.5-2.6,2.1-2.3h6.6c2.2,0,4.3,0.7,6.2,2.2l0.5,0.4l0.5,0.7c3.2,3.6,5.2,7.4,6.3,11.3l0.3,1.4
	L62.5,386.6L62.5,386.6z"
          />
          <path
            fill="none"
            d="M62.5,386.6h-0.3c-0.8,0-1.7-0.7-2.8-2.1s-1.9-3-2.8-5c-0.7-1.9-1.1-3.6-1-5.1c0-0.6,0.1-1,0.3-1.4l-0.5-0.7
	l-0.5-0.4c-0.4,0.4-0.5,1-0.7,1.9c-0.1,1.5,0.1,3.3,1,5.5c0.8,2.2,1.8,4,3.2,5.5c1.2,1.5,2.5,2.3,3.4,2.3l0.8-0.1L62.5,386.6
	L62.5,386.6z"
          />
          <path
            fill="none"
            d="M62.2,386.6h0.3V386l-0.3-1.4c-1.1-4-3.2-7.7-6.3-11.3c-0.1,0.3-0.3,0.8-0.3,1.4c0,1.4,0.3,3,1,5.1
	c0.8,1.9,1.7,3.6,2.8,5C60.5,386,61.5,386.6,62.2,386.6L62.2,386.6z"
          />
          <path
            fill="none"
            d="M62.3,387.2l-0.8,0.1c-1,0-2.1-0.8-3.4-2.3c-1.2-1.5-2.3-3.3-3.2-5.5c-0.8-2.2-1.1-4-1-5.5
	c0.1-1,0.3-1.5,0.7-1.9c-1.8-1.5-3.9-2.2-6.2-2.2h-6.6c-1.5-0.3-2.2,0.5-2.1,2.3c1.8,4.7,4.8,10.2,9.2,16.5c1.4,1.7,3,2.3,5,1.9
	l4.5-0.7C60.8,389.5,61.9,388.6,62.3,387.2L62.3,387.2z M95.2,337c2.3-0.1,4.3-1.2,5.9-3.4c1.1-2.9,2.5-5.5,4.1-8.1l-21,0.8
	l-19.8,1.1l-1.8,0.1h-0.8l-15.4,1.8h-0.3l-3.4,0.5l-2.5,1.6l-0.3,0.3l-0.1,0.1l-0.8,0.5l-0.3,0.3l-0.4,0.4l-0.1,0.1l-1.5,1.4
	l-0.1,0.3l-0.3,0.3l-0.3,0.4l-0.8,1l-0.1,0.3l-0.5,0.7l-1.9,2.9l-0.1,0.1h0.1l7.7-1.2l8-0.7h0.4l3.7-0.1h0.3L95.2,337L95.2,337z
	 M125.7,347.9c-5.8,0-12.4,1.2-19.9,3.6c-2.1,9.9-3.6,20.2-4.7,31.2l10.5-5.1C113.4,366.4,118,356.5,125.7,347.9L125.7,347.9z"
          />
          <path
            fill="none"
            d="M48.6,291l-2.5,0.3l-2.8,11.6l-0.5,2.8l-0.1,0.5l-0.8,5l-0.3,1.5c-0.3,1.9-0.5,4-0.7,6.1l-0.1,1.5
	c-0.1,1.8-0.3,3.7-0.4,5.6v2.2l-0.1,3.6l2.5-1.6l0.3-3V327l1.2-13.5l1.8-11.7L48.6,291L48.6,291z M44,291.9h1l-1.2,4.7l-0.3,1.2
	l-1.1,5v0.1h0.1l0.8-0.1l2.8-11.6l-0.3-2.3H33.3l-0.8,2.8l4.3,0.1h2.3l0.5,0.1h4.3"
          />
          <path fill="none" d="M45,291.9h-1l-0.5,1.4l0.3,3.3" />
          <path
            fill="none"
            d="M43.1,291.9l0.1,1.4l-1.5,3.3l-0.3-3.3l0.7-1.4h-0.3l-0.5,1l-0.1-1h-0.3l0.1,1.4l-1.5,3.3l-0.3-3.3l0.7-1.4
	h-0.3l-0.4,1l-0.1-1h-0.3l0.1,1.5l-1.5,3.3l-0.3-3.3l0.7-1.5h-0.3L37,293l-0.1-1.1h-0.1v0.1l0.1,1.4c-0.5,1.1-1.1,2.2-1.5,3.3
	l-0.1-1l-0.1,0.7v0.7l-0.8,1.8l-0.3,1.2l1.1-2.6l0.4,3.3l-1.2,2.8h0.3l1-2.3l0.3,2.2h0l-0.4-2.6l1.5-3.3l0.4,3.3l-1.1,2.6h0.3
	l0.8-2.1l0.3,2.1h0.3l-0.3-2.5l1.5-3.3l0.4,3.3l-1,2.5H39l0.8-1.9l0.3,1.9h0.3l-0.3-2.3c0.4-1.1,1-2.2,1.5-3.3l0.4,3.3l-1,2.2h0.3
	l0.8-1.8l0.3,1.8h0.1v-0.1l-0.3-2.1l1.4-3l0.3-1.2l-0.3-3.3l0.7-1.4H44l-0.4,1l-0.1-1L43.1,291.9L43.1,291.9z M38.9,293.7
	c0.1,1.1,0.3,2.2,0.3,3.3l-1.5,3.3l-0.4-3.3L38.9,293.7z M41.7,297l1.5-3.3l0.3,3.3l-1.5,3.3L41.7,297z M41.1,293.7l0.3,3.3
	l-1.5,3.3l-0.4-3.3L41.1,293.7L41.1,293.7z M36.9,293.7l0.3,3.3l-1.5,3.3l-0.4-3.3L36.9,293.7L36.9,293.7z"
          />
          <path
            fill="none"
            d="M39.3,297c-0.1-1.1-0.3-2.2-0.3-3.3l-1.5,3.3l0.4,3.3C38,300.3,39.3,297,39.3,297z M43.2,293.6l-1.5,3.3
	l0.4,3.3l1.5-3.3 M41.4,297l-0.3-3.3l-1.7,3.3l0.4,3.3 M37.1,297l-0.3-3.3l-1.5,3.3l0.3,3.3 M43.2,293.2l-0.1-1.4H42l-0.7,1.4
	l0.4,3.3 M43.3,292.8l0.5-1h-0.5 M38.8,291.7h-1l-0.8,1.5l0.3,3.3l1.7-3.3 M39.6,291.9l-0.5-0.1v1.1 M39.9,291.9l-0.7,1.4l0.3,3.3
	l1.5-3.3l-0.1-1.4 M41.1,291.9l0.1,1l0.4-1 M40.3,303h0.5l1-2.2l-0.4-3.3c-0.5,1.1-1,2.2-1.5,3.3L40.3,303L40.3,303z"
          />
          <path
            fill="none"
            d="M40.9,303H40l-1.1,0.1h-1.1l-1.2,0.1h-0.8l-1.2,0.1h-1.4l-4.5,0.3l-0.5,2.6l-0.1,1.8l-0.4,5.5l-1.1,0.3
	c-0.1,1.4,0,2.2,0.4,2.3l0.3,0.1h0.4l0.1-0.3c0.5-0.8,0.8-2.1,1.2-3.4l0.8-6.5l12.2-0.7h1l0.5-2.8l-0.8,0.1h-0.1l-1,0.1L40.9,303
	L40.9,303L40.9,303z"
          />
          <path
            fill="none"
            d="M41.1,303l1.1-0.1l-0.3-1.8 M42.1,300.7l0.3,2.1l1.1-5 M38.2,303.1h0.4l1.1-2.5l-0.4-3.3l-1.5,3.3 M38.9,303.1
	L40,303l-0.3-1.9 M38,303.1l-0.3-2.1l-1,2.2 M42.6,306l0.1-0.5h-0.1"
          />
          <path
            fill="none"
            d="M42.6,306l-0.1-0.5h-0.4l-0.5,1.5l-0.3,1.9l0.4,2.5l0.1-0.4C42.2,309.3,42.4,307.7,42.6,306L42.6,306z
	 M41.8,305.6v0.3V305.6"
          />
          <path
            fill="none"
            d="M41.8,305.9v-0.4l-12.2,0.7l-0.8,6.5c-0.3,1.4-0.7,2.5-1.2,3.4l-0.1,0.3l3.6-0.3h1l1.5-0.1H34l1.7-0.1H36
	l1.7-0.1h0.4l1.8-0.1h0.3l0.1-0.8c0.3-1.8,0.4-3.4,0.7-5.2l0.1-0.7l0.3-1.9L41.8,305.9L41.8,305.9z"
          />
          <path
            fill="none"
            d="M42,305.6l-0.1,0.3l-0.3,1.1l0.7-1.4 M41.7,312.5L42,311l-0.1,0.4l-0.5-2.5l-0.1,0.7l0.4,2.2l-1.1,3l-0.1,0.8
	v0.5l0.7,3.2l-1.2,3.3v-0.1l-0.1,1.1l0.1-0.1l0.7,3v-0.8l-0.5-2.6l1-2.9l0.1-1.6l-0.5-2.9l1.2-3.3"
          />
          <path
            fill="none"
            d="M41.5,311.8l-0.4-2.2c-0.3,1.6-0.5,3.4-0.7,5.2L41.5,311.8z M41.7,312.5v-0.3l-1.2,3.3l0.7,2.9
	C41.3,316.5,41.4,314.6,41.7,312.5z M37,292.8l0.5-1.1h-0.7 M36.7,293.2l-0.1-1.4c-0.5,1.1-1.1,2.3-1.5,3.7l0.1,1
	C35.8,295.4,36.2,294.3,36.7,293.2L36.7,293.2z M34.9,296.3l-0.7,2.5l0.7-1.8 M35.1,297.4l-1.2,2.6l-0.1,1.1l0.3,2.3h0.3l1.2-2.8
	 M36,303.3h0.4l1.1-2.6l-0.3-3.3l-1.5,3.3 M35.8,303.3l-0.3-2.2l-1,2.3"
          />
          <path fill="none" d="M33.7,303.4H34l-0.3-2.3l-0.1,0.8" />
          <path
            fill="none"
            d="M33.7,303.4l-0.1-1.5l-0.4,1.5 M28.8,303.8l-1.6,1.1l-0.1,1.8l1.2-0.3"
          />
          <path
            fill="none"
            d="M571.4,389v-0.8c0-4.5,0.4-8.9,1.2-13.1l-12.5,7.7c-0.3,2.1-0.4,4.1-0.7,6.2L571.4,389L571.4,389z"
          />
          <path
            fill="none"
            d="M558.9,396.1H572c-0.3-2.3-0.4-4.8-0.6-7.3h-12L558.9,396.1z M166.7,255.1l29.9-2.8c2.8-0.3,7,0.7,12.7,2.8
	c-11.8,0.4-23.5,1.4-35.1,2.8c-38,4-71.8,10.2-101.7,18.4l-0.3,0.1l-11.6,3.3l-1.2,0.4l-4,1.9l-0.4,0.3l-3.6,2.6l-2.5,2.5l-3,3.9
	l2.3-0.3l18-1.9h0.3l1.7-4c0.6-1.1,1.1-2.1,1.8-3l1-1.1c1.9-2.2,4.1-3.9,6.7-4.7c17.6-4.8,42.2-9.2,73.7-13.1
	c23.9-2.8,49.9-4.8,78-6.5l-12.4-2.9c-7.7-1.8-12.5-2.8-14.4-2.8c-26.7,1.4-51.3,3.3-73.7,5.8c-16.2,1.9-30.4,3.9-42.5,5.8
	c-5.5,0.8-10.5,1.8-15.1,2.8l-6.6,1.4l-8.8,2.2l-8.9,2.8l-0.5,0.3h-0.1l6.3,0.1l7-0.5l6.9-1.1l2.8-0.7h0.1l5.8-1.2
	C102.1,263.1,132.5,258.7,166.7,255.1L166.7,255.1z"
          />
          <path
            fill="none"
            d="M196.6,252.4l-29.9,2.8c-34.3,3.4-64.7,7.8-91.3,13.2l-5.8,1.2h-0.1l-2.8,0.7l-6.9,1.1l-7,0.5l-6.3-0.1
	c-0.5,0.3-1,0.4-1.4,0.7l-1.7,1.2v0.1l-2.6,2.9l-2.3,3.2c-0.7,1-1.2,1.9-1.8,2.9l-2.2,4.1c-0.3,0.7-0.7,1.2-1,1.9H46l0.3,2.3l3-3.9
	l2.5-2.5l3.6-2.6l0.4-0.3l4-1.9l1.2-0.4l11.6-3.3l0.3-0.1c29.9-8.3,63.7-14.3,101.7-18.4c11.4-1.5,23.1-2.3,35.1-2.8
	C203.6,253.1,199.3,252.2,196.6,252.4L196.6,252.4z M101.2,333.5c-1.7,2.2-3.6,3.3-5.9,3.4l-42.8,1.5h-0.3l-3.7,0.1h-0.4l-8,0.7
	l-7.7,1.2h-0.1l-1,2.5l-1.1,2.6l-0.4,0.7l-0.1,0.1L29,347l-1.1,0.5l-5.6,2.2v12.8l2.3-0.3l4-0.5c1.4,0,2.5,1.4,3.4,4.3l62.3-1.8
	c1.1-6.5,2.3-12.7,3.7-18.7C98.6,341.1,99.7,337.1,101.2,333.5L101.2,333.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M38.7,332.9l0.7-2.3l-0.8-3.3l-1.1,3.3l0.8,2.8 M40.4,327.9l-0.8,2.6
	l0.3,1.2 M39.1,332.6l0.3-1.1l0.1-0.5l0.3,1 M37.7,323.1l0.8,3.3l1.1-3.3l-0.7-3.3L37.7,323.1z M40.9,320.2l-1,2.9l0.5,2.6
	 M40.4,326.5l-0.7-3l-0.1,0.1l-1,3.2l0.7,2.8l0.1,0.5l1-3 M38.4,315.8l0.5,3.2l1.2-3.3 M40.3,316.2l0.7,3.2l-1.2,3.3v-0.1l-0.7-3.2
	l1.2-3.3V316.2z M33,337.5l-1-3.3l1-3.3l1,3.3L33,337.5z M34.8,336.8l-0.7-2.1c-0.3,1-0.5,2.1-0.8,3 M36.3,335.1l-0.1-0.4l-0.1,0.5
	 M35.8,335.6l0.3-1.4l-0.8-3.3l-1,3.3l0.7,2.3 M38,333.4l-0.7-2.5l-1,3.3l0.1,0.5 M30.1,333.4l0.7-2.5l1,3.3l-0.8,3.3l-0.7-2.2
	 M30.3,336.2l0.5,1.8l-0.1,0.4 M32.9,337.8l-1-3.2l-0.8,3.3v0.3 M32.1,327.2l0.8,3.3l-1,3.3l-1-3.3L32.1,327.2z M30.3,328.5l0.4,2.1
	l-0.5,1.9 M33,330.1l-0.8-3.3l1.1-3.3l0.8,3.3L33,330.1z M34.3,327.2l0.8,3.3l-1,3.3l-1-3.3L34.3,327.2z M35.2,330.1l-1-3.3l1.2-3.3
	l0.7,3.3L35.2,330.1z M37.1,330.5l-1,3.3l-0.8-3.3l1-3.3L37.1,330.5z M36.5,326.8c0.3-1.1,0.7-2.2,1.1-3.3l0.8,3.3l-1,3.3
	L36.5,326.8L36.5,326.8z M32.5,319.8l0.7,3.3l-1.1,3.3l-0.8-3.3L32.5,319.8z M36.9,319.4l1.2-3.3l0.7,3.3l-1.1,3.3L36.9,319.4z
	 M37.4,323.1l-1.1,3.3l-0.7-3.3l1.1-3.3L37.4,323.1z M35.4,323.1l-1.1,3.3l-0.8-3.3l1.1-3.3L35.4,323.1z M30.3,326l0.8-2.5l0.8,3.3
	l-1,3.3l-0.7-2.6 M30.5,321l0.5,2.1l-0.8,2.1 M30.7,320.2l0.5,2.5l1.1-3.4l-0.5-3 M30.8,318.3l0.8-2.1 M31.4,316.2l-0.4,1.1
	 M31.9,316.2l0.5,2.8l1.1-2.9 M37.8,315.8l-1.1,3l-0.5-2.9 M35.9,316.1l0.7,3.2l-1.1,3.4l-0.7-3.3L35.9,316.1z M34.1,316.1l0.5,2.9
	l1.1-3 M33.8,316.1l-1.2,3.2l0.8,3.3l1.1-3.3C34.3,318.3,34,317.2,33.8,316.1 M33.7,301.1l0.3,2.3 M33.7,303.4l-0.1-1.5 M35.8,303.3
	l-0.3-2.2l-1,2.3 M36.5,303.3l1.1-2.6l-0.4-3.3l-1.4,3.3l0.3,2.6 M36.9,293.6l-1.5,3.3l0.3,3.3l1.5-3.3L36.9,293.6z M34.3,303.4
	l1.2-2.8l-0.4-3.3l-1.2,2.6 M34.3,298.7l0.7-1.8v-0.7 M35.1,295.6l0.1,1c0.5-1.1,1-2.2,1.5-3.3l-0.1-1.4 M36.9,291.7l0.1,1.1
	l0.5-1.1 M42.2,305.6l-0.7,1.4 M41.7,312.5v-0.1l-1.2,3.3l0.5,2.9 M41.1,309.6l0.4,2.2l-1.1,3 M42,311l-0.1,0.4l-0.5-2.5 M42,305.6
	l-0.1,0.3 M42.6,306v-0.5 M38,303.1l-0.3-2.1l-1,2.2 M38.7,303.1l1.1-2.5l-0.4-3.3l-1.5,3.3l0.4,2.5 M40,303l-0.3-1.9l-0.8,2.1
	 M40.3,303l-0.3-2.3c0.4-1.1,1-2.2,1.5-3.3l0.4,3.3l-1,2.2 M42.2,302.9l-0.3-1.8l-0.8,1.9 M43.5,297.8l-1.4,2.9l0.3,2.1 M41.4,297
	l-1.5,3.3l-0.4-3.3l1.7-3.3L41.4,297z M43.2,293.6l0.4,3.3l-1.5,3.3l-0.4-3.3L43.2,293.6z M38.9,293.6l-1.5,3.3l0.4,3.3l1.5-3.3
	L38.9,293.6L38.9,293.6z M41.1,291.9l0.1,1l0.4-1 M39.9,291.9l-0.7,1.4l0.3,3.3l1.5-3.3l-0.1-1.4 M39.1,291.7v1.1l0.5-1 M37.8,291.7
	l-0.8,1.5l0.3,3.3l1.7-3.3l-0.1-1.5 M44,291.9l-0.5,1.4l0.3,3.3 M43.3,291.9v1l0.5-1 M42,291.9l-0.7,1.4l0.4,3.3l1.5-3.3l-0.1-1.4
	 M783.2,323.8v-11.6 M784.8,291.2c-1.1-0.1-1.9-0.5-2.6-1.2 M785.1,278c-2.9,0.8-4.3,2.2-3.9,4.4c-0.5,3.7-0.3,6.2,1.1,7.6
	 M783.2,312.2l6.1,0.5 M789.5,290.9c-0.1-4.4-0.3-8.7-0.4-12.7 M785.1,278c-1.2-0.3-2.3-1-3.3-1.8 M789.5,290.9l-3.6,0.3 M783,309.7
	c0.8,1.2,1.9,2.1,3.3,2.3l3,0.7 M784.8,291.2h1.1 M789.2,312.8c0.3-7.7,0.4-15,0.3-21.9 M783.2,312.2v-0.1 M769.5,311.1l13.6,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M782.3,289.9l-13.3,1.2h15.8 M785.9,291.2c-3.4,1.2-5.1,4.4-4.7,9.5
	c0,4.1,0.5,7.2,1.8,9.1 M783,309.7l-13.5,1.4 M781.8,276.2l-12.2,1.8h15.5 M781.8,276.2c-2.3-2.2-3.3-6.3-2.5-12.1
	c-0.1-4.5,2.3-7,7.2-7.2 M788.9,278.2l-3.8-0.3 M225.5,247.3l19.1,1c3,0.1,5.5-0.7,7.4-2.3c21.3-16.8,43.9-33.4,68-50.2
	c4.4-2.1,5.6-3.9,3.6-5.2l-6.7-3.7 M87.9,289.9l-4.8,0.5l-5.9,34.3l6.1-0.5l6.5-29.2C90.5,291.9,90,290.2,87.9,289.9L87.9,289.9z
	 M70.7,294.9l-12,0.7v18.7l18,0.7l4-23.1l-10,1V294.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M769.5,311.1c-2.6,0-3.8-0.5-3.6-1.6l-1.1-47.7c-0.1-2.9,0.8-4.5,2.8-4.7
	h18.8 M786.5,256.9h0.3c1.1,6.5,1.8,13.6,2.3,21.3 M76.6,316.8l-27.1,1.5h-0.3c-0.8,0.3-1.2,0.8-1.2,2.1v3.6
	c-0.1,1.5-0.3,2.6-0.7,3.4l1.8-0.1l10.7-0.8l15.8-1.4L76.6,316.8L76.6,316.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M69.5,321.6c0-0.7-0.1-1.2-0.6-1.6c-0.3-0.4-0.8-0.7-1.2-0.7
	c-0.6,0-1,0.3-1.2,0.7c-0.3,0.4-0.6,1-0.6,1.6s0.1,1.2,0.6,1.6c0.3,0.4,0.8,0.7,1.2,0.7c0.6,0,1-0.3,1.2-0.7
	C69.2,322.8,69.5,322.3,69.5,321.6z M52.3,320.5l-0.3,0.1l-0.3,0.3c-0.3,0.4-0.5,1-0.5,1.6s0.1,1.2,0.5,1.6l0.3,0.3l0.3,0.1l0.7,0.3
	h0.3c0.4-0.1,0.7-0.3,1-0.7l0.1-0.1c0.3-0.4,0.4-1,0.4-1.5v-0.3l-0.1-0.7l-0.4-0.7l-0.1-0.1l-0.4-0.4l-0.3-0.1l-0.5-0.1
	C52.7,320.2,52.4,320.3,52.3,320.5L52.3,320.5z M786.5,382.5l-68.2,9.2 M691.3,368.6c-2.5-7.4-6.3-14.3-11.8-20.5 M705.7,368.6
	c-0.8-7.3-1.9-14.2-3.2-20.5 M819.6,358.3l0.3,7.2 M817.4,365.7c-12.9-1.4-24.1-1.2-33.4,0.3l-68.2,4 M810.9,382.7l-6.9-0.5
	 M817.4,365.7l-13.3,16.4 M692.4,404.1h82c8.3,0.3,12.4-2.9,12.2-9.4v-12.1 M804.1,382.1l-2.6-0.1 M786.5,382.5l15-0.5 M816.4,372.1
	l3.4-6.6 M819.9,365.5l-2.5,0.3 M801.4,382l1,3.4h7.2l1.4-2.8 M810.9,382.7l5.5-10.6 M691.3,368.6h14.4 M718.2,391.7
	c-0.7-7.7-1.5-15-2.5-21.7 M718.2,391.7l-10.3,1.4c-0.4-8-1.1-15.5-1.9-22.6 M571.9,396.1c1.7,13.5,7.4,25.3,17.5,35.4
	c12,12,26.5,18,43.5,18s31.5-6.1,43.5-18c5.4-5.4,9.6-11.3,12.5-17.7l1.1-2.5c1-2.3,1.8-4.7,2.3-7.2 M692.4,404.1
	c1.4-5.1,1.9-10.5,1.9-16c0-6.9-1-13.3-3-19.5 M715.7,370l-9.9,0.5 M705.8,370.5l-0.1-1.9 M595.8,301.9c11.4-2.2,25.7-2.9,42.8-2.3
	h0.3c25.6-1.1,42.5,1.5,50.8,7.6c6.3,3.4,11.3,9.1,15,16.8 M704.6,323.8h57 M702.4,348.1h-22.8 M704.6,323.8h-9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M694.7,323.8c-2.5-4.1-5.6-7.4-9.5-9.6l-0.4-0.3c-5.8-4.1-16.8-6.3-33-6.5
	h-21.6c-13.2-0.1-24.5,0.7-33.7,2.6l-0.7,0.1 M702.4,348.1c-1.2-5.9-2.6-11.4-4.1-16.5c-1-2.9-2.2-5.5-3.7-7.8 M715.7,370
	c-2.2-16-5.2-29.7-8.9-41.1v-0.1c-0.7-1.6-1.4-3.3-2.1-5 M728,291.5c-3.9,0-7.3,1.4-10,4.1c-2.8,2.8-4.1,6.1-4.1,10s1.4,7.2,4.1,9.9
	c2.8,2.8,6.1,4.1,10,4.1s7.2-1.4,9.9-4.1c2.8-2.8,4.1-6.1,4.1-9.9s-1.4-7.2-4.1-10C735.1,292.8,731.8,291.4,728,291.5L728,291.5z
	 M803.5,285l3.7-0.1 M808.9,274.1l-5.1-4.4 M801.9,285c1,9.2,1.4,19.1,1.5,29.7 M816.4,372.1h4.5v-13.8h-1.4 M819.6,358.3v-1.6
	l1.4-1.4v-9.9h-17.7 M803.4,321.9l2.6-0.5c1.1-1.8,3.2-2.5,6.3-2.2v-4.4h-8.9 M790.3,323.8h-28.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M820.7,349.8h-17.5v-4.4 M803.2,345.4v-5.8 M803.4,314.7v7.1 M803.4,321.9
	v1.4h2.3l-2.5,16.4 M803.2,339.6v-7.3l-12.9-8.5 M801.9,285h1.7 M803.5,285l-1.5-10.3c-0.5-3.3,0-5,1.7-5 M803.8,269.7l-1.7-1.4
	 M801,263.1c0.4,1.8,0.8,3.4,1,5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M802.1,268.3c-1.2,0.5-1.8,2.3-1.7,5.6c0.5,3.6,1,7.3,1.4,11 M807.2,284.8
	l-1.2-9.4l2.9-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M807.2,284.8l3.9-0.1l-2.2-10.6 M790.3,323.8c2.1-26.4,1.2-49.8-2.5-69.9
	 M798.4,255c1.1,2.8,1.9,5.5,2.6,8.1 M761.6,253.9v69.9 M366.5,170.4l1.4-0.3c5.8-1,11.7-1.9,17.9-2.6l14.9-1.4l15.7-0.6l11.6-0.1
	 M366.5,170.4c2.2,0.6,5.9,0.6,10.9-0.1l9.2-1.1l14-1.2l15.7-0.6l11.6-0.1 M342.9,175.9l0.1,1c3.7-1.1,7.7-2.2,11.7-3.2l-0.4-0.8
	 M343.9,173.6l-1.2,0.3l0.3,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M354.4,172.9l-0.7-1.6l-5,1.1 M348.7,172.3l0.1-6.1l-2.6,0.7l-2.3,6.6
	 M348.7,172.3l-4.8,1.2 M313.5,259.9l118.2-2.9l-1.4-66.3c-0.1-4.5-1.5-7-3.9-7.6l-14.3,0.3c-27.1,1.2-47.7,5.8-62.2,13.9
	c-13.2,7.2-27.5,17.2-42.8,30.3 M354.4,172.9c4.5-1,8.5-1.9,12-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M307.2,224.7c15-12.5,28.9-22.3,41.7-29.2c14.6-8.3,35.6-13.1,63.1-14.2
	l14.3-0.3h0.3c3.7,0.4,5.6,3.6,5.9,9.6l1.4,67.4c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.1-0.4,0.3-0.8,0.3 M493.6,162.1v-2.3
	c0-0.4-0.1-0.6-0.5-0.6H487c-0.4,0-0.5,0.1-0.5,0.6v2.3 M493.6,162.1h-7.2 M486.4,162.1c-16.1,0-30.8,0-43.9,0.1h-3.7
	c-4.4,0.3-8.1,1.4-11,3.2 M427.8,167.2v0.8l5.8-0.1c30.8-0.4,71.3-0.4,121.5-0.1h8.5c13.2,0.1,26.7,0.1,40.6,0.1h6.7
	c33.3,0.4,67,1.4,101.1,2.9 M427.8,165.6v1.6 M460.7,258.4c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.3-0.4-0.4-0.8L456,187
	c0.4-4.3,2.1-6.5,5.2-6.7l66.6-0.1c18.7,0,30.4,5.9,34.7,17.6c4.1,9.1,7.2,17.5,9.1,25"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M458.2,187.2l3.6,68.9l83.1-2.5c16.6,0,25.5-3.4,26.8-10.3
	c0.1-0.8,0.1-1.5,0-2.3v-0.4c0.1-11.4-3.6-25.3-11.3-41.8c-4.3-10.7-15.1-16.1-32.6-16.1l-66.3,0.1
	C459.6,182.8,458.5,184.4,458.2,187.2L458.2,187.2z M444.9,258.8l15.8-0.4 M444.9,258.8l-12,0.4 M442.3,169.6
	c1.6,27.1,2.5,56.8,2.6,89.3 M563.3,169.6H555c-45.7-0.3-83.2-0.3-112.9,0 M648.5,163.2l-5-0.1c-29.2-0.3-56.1-0.6-80.9-0.8h-8.5
	c-22-0.1-42.1-0.1-60.4-0.1 M571.6,222.8l1.9,10 M571.6,222.8c0.8,3.4,1.5,6.9,1.9,10"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.5,232.8c0.3,2.6,0.4,5.2,0.4,7.6v0.3c0.1,1,0,2.1-0.1,2.9
	c-1.1,8.1-10.7,12.1-28.9,12.2l-84.2,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.5,232.8l1.8,9.5c0.5,6.7-0.7,11.1-3.7,13.3
	c-3.3,2.3-8.8,4.1-16.6,5.5l-3.7,0.7c-5.2,0.7-10.6,1.1-15.8,1l-90.4,2.3 M661.7,163.4h-5.2 M656.5,163.4V161c0-0.4-0.1-0.6-0.5-0.6
	h-7c-0.4,0-0.5,0.1-0.5,0.6v2.3 M669.5,168.9c-2.8-3-5.4-4.8-7.8-5.5 M648.5,163.2l8,0.1 M733.3,184.4l-131.4-3
	c-11.1-0.4-14.6,2.3-10.5,8L623,247c2.9,4.5,9.4,6.9,19.5,6.9h119 M595.8,301.9c0.3-18.3-0.3-33.7-1.5-46.2
	c-5.2-28.1-15.5-56.8-31-86 M46.1,291.2l3-3.9l2.5-2.5l3.6-2.6l0.4-0.3l4-1.9l1.2-0.4l11.6-3.3l0.3-0.1
	c29.8-8.3,63.7-14.3,101.7-18.4c11.4-1.5,23.1-2.3,35.1-2.8c-5.8-2.1-10-3-12.7-2.8l-29.9,2.8c-34.3,3.4-64.7,7.8-91.3,13.2
	l-5.8,1.2h-0.1l-2.8,0.7l-6.9,1.1l-7,0.5l-6.3-0.1 M322.5,183.6l-5.6,3.3c-30.1,17.2-60.5,37.3-91.2,60.5l-8.4,6.3 M261.9,242.1
	l-8,6.9c-6.3,5-14.3,7.4-23.9,7.6h-0.6 M217.2,253.6l12.2,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M217.2,253.6c-7.7-1.8-12.5-2.8-14.4-2.8c-26.7,1.4-51.3,3.3-73.7,5.8
	c-16.2,1.9-30.4,3.9-42.5,5.8c-5.5,0.8-10.5,1.8-15.1,2.8l-6.6,1.4l-8.8,2.2l-8.9,2.8l-0.5,0.3h-0.1 M335,188l-12.5-4.4 M266.3,389
	c-3.7-16.6-6.5-36.6-8.3-59.6c-2.1-29.9-0.1-56.7,5.8-80.6c0.8-3.7,0.3-5.9-1.9-6.6 M307.2,224.7l-3.2,2.8l-0.1,0.1l-18.6,15.7
	c-3.7,4.4-5,9.5-4,15.3c1.8,6.6,10,9.9,24.8,9.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M288.5,247.8c-1-0.1-1.4,0.7-1.2,2.3c0.4,4.5,3,8.3,7.8,10.9
	c1.4,0.3,3.2,0.5,5.2,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M302.1,261.7l-1.8-0.7c-4-1.6-6.2-3.6-6.3-6.1h-3
	c-1.6-1.9-2.5-4.4-2.5-7.2 M307.2,227.8v-3 M316.5,249.4c-0.4-3.4-1.1-6.2-2.2-8.4c-1.2-2.3-3.7-3.9-7.4-4.5 M306.9,236.4l-5.1-0.3
	c-2.9-0.3-5,0.7-5.9,3l-1.9,8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M167.1,401.6c1.5,0.5,3.2,0.8,5,0.8c1.9,0,3.9-0.4,5.5-1.1 M176.2,406.5
	c-1,0.4-2.2,0.8-3.3,1h-1.1c-1.1,0-2.2-0.1-3.3-0.5 M179.7,410.7l-3.4-4.3 M168.5,406.9l-1.6-5 M177.7,401.6l-1.5,4.8 M165.1,411
	c2.2,0.7,4.5,1,7,1c2.8,0,5.2-0.4,7.6-1.1 M190.9,389.8c0.1,1.1,0,2.2-0.3,3.4l-0.3,1.1l-0.1,0.3c-0.3,1-0.8,1.9-1.5,2.6
	 M195.5,398.5c0.6-1.9,0.8-3.9,1-5.9 M188.9,397.2l4.3,6.6 M193.1,403.8c1-1.6,1.8-3.6,2.3-5.4 M193.8,404.9l4,6.1
	c0.6,1.2,1.5,1.4,2.6,0.7l0.1-0.1c2.9-3.4,5.1-7.3,6.5-11.4 M193.1,403.8l0.7,1.1 M207,400c-4.1,2.9-8.5,4.5-13.2,5 M186.4,375
	l6.7-1.9 M183.8,397.2h5.1 M186.7,386.8l4.3,3 M200.8,418.8c-0.1,0-0.3,0.1-0.4,0.3c-1,0.4-1.6,0-2.3-1.2l-14.2-20.8 M192.7,424.9
	l0.6-0.5c0.8-0.8,0.8-1.6-0.3-2.8l-15.3-19.9 M200.8,418.8c-2.5,2.3-5.2,4.4-8,6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M165.1,411l-6.9,8.5c-1,1-0.8,1.9,0.3,2.8c4.1,1.6,8.7,2.5,13.6,2.5
	c4.9,0,9.5-0.8,13.8-2.6c1.4-1.1,1.5-2.2,0.6-3.2l-6.7-8.4 M151.9,425.2c6.1,3.3,12.8,5.1,20.2,5.1c7.6,0,14.4-1.8,20.6-5.2
	 M207,400c0.8-2.3,1.4-4.8,1.6-7.4 M208.7,392.6h-12.2 M195.5,398.5l13.2-5.9 M266.3,389H257 M257,389l0.5,7v0.1 M257,389h-9.6
	 M179.7,370.7l-0.4-5.4 M84.2,326.3l7.2-31.5c0.4-3.7-0.4-5.6-2.5-5.8h-22 M116.5,325l-11.1,0.4 M32.2,340.6l7.8-1.2l8-0.7h0.4
	l3.7-0.1h0.3l42.8-1.5c2.3-0.1,4.3-1.2,5.9-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M31.8,365.9l62.3-1.8c1.1-6.5,2.3-12.7,3.7-18.7c0.8-4.3,1.9-8.3,3.4-11.8
	 M105.8,351.4l0.8-4.1c1.5-9.1,4.8-16.5,10-22.3 M105.4,325.4l-21.2,0.8 M101.2,333.5c1.1-2.9,2.5-5.5,4.1-8.1 M147.3,360.9
	l-1.2,1.1c-2.1,2.1-3.9,4.4-5.4,6.7c-0.4,1.5,0,2.2,1.4,2.5l10.6,2.9 M151.7,357.5c-1.5,1-2.9,2.2-4.3,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M164.2,365.5l0.6-11.1c0.3-1.2-0.3-1.9-1.5-2.1l-0.4,0.1
	c-4,1-7.8,2.6-11.3,5.1 M125.7,347.9c-5.8,0-12.4,1.2-19.9,3.6 M229.5,256.6c-27.9,1.6-53.9,3.7-78,6.5c-31.5,4-56,8.4-73.7,13.1
	c-2.6,0.8-5,2.5-6.7,4.7l-1,1.1c-0.7,0.8-1.2,1.9-1.8,3l-1.7,4 M46.4,271.8c-0.5,0.3-1,0.4-1.4,0.7l-1.7,1.2v0.1l-2.6,2.9l-2.3,3.2
	c-0.7,1-1.2,1.9-1.8,2.9l-2.2,4.1c-0.3,0.7-0.7,1.2-1,1.9 M48.6,291l-2.5,0.1 M42.4,302.9v-0.1l1.1-5l0.3-1.2l1.2-4.7h-5.4l-0.5-0.1
	h-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M33.3,288.8h12.5l0.3,2.3 M42.4,302.9L42.4,302.9l-1.2,0.1H40l-1.1,0.1h-1
	l-1.2,0.1h-1l-1.2,0.1h-1.4 M43.3,302.7l-0.8,0.1h-0.1 M40.3,331.5l0.1-3.6v-2.2c0.1-1.9,0.3-3.7,0.4-5.6l0.1-1.5
	c0.1-2.1,0.4-4,0.7-6.1L42,311c0.3-1.6,0.4-3.3,0.8-5l0.1-0.5 M40.3,315.7l0.1-0.8c0.3-1.8,0.4-3.4,0.7-5.2l0.1-0.7l0.3-1.9l0.3-1.1
	v-0.4 M42.8,305.5L42.8,305.5l-0.5,0.1h-0.4 M42.8,305.5l0.5-2.8 M48.6,291l-2.5,10.5l-1.8,11.7l-1.4,13.5v0.1l-0.1,3 M46.1,291.2
	l-2.8,11.6 M66.9,289h-0.3l-18,2.1 M33.3,288.8l-0.8,2.8l4.3,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M36.7,291.7c-0.5,1.2-1.1,2.5-1.5,3.9l-0.1,0.7l-0.7,2.6l-0.3,1.2
	l-0.3,1.1l-0.1,0.8l-0.3,1.6 M763.3,179.3l-1.9-2.5 M787.8,253.9h-26.3 M787.8,253.9c-5.5-22.3-17.5-45.5-36.2-69.6 M754.3,185
	l9.1-5.6 M801,263.1c0.7-0.3,1.4-0.7,2.1-1v-1l3.4-1.4l-2.3-4.8h-5.8 M798,253.9l0.4,1.1 M28.8,303.8l-1.6,1.1l-0.1,1.8 M28.8,303.8
	l-0.5,2.6 M28.2,306.4l-0.1,1.6v0.1l-0.5,5.5l-1,0.3 M33.2,303.4l-4.4,0.4 M27.5,316.5l0.1-0.3c0.5-0.8,0.8-2.1,1.2-3.4l0.8-6.5
	l12.2-0.7 M28.2,306.4l-1.2,0.3 M40.3,315.7L40.3,315.7l-1.9,0.1h-0.5l-1.7,0.1h-0.4l-1.7,0.1h-0.6l-1.6,0.1h-0.5 M756.9,188.1
	c17.7,19.3,31.4,41.1,41.1,65.6l0.1,0.3 M798,253.9h-10.2 M763.3,179.3l1,4.7l-7.4,4.1 M727.2,173.6l-11.7-0.8
	c-22.4-1.4-46.1-2.2-71-2.5c-29.3-0.3-56.1-0.6-80.9-0.7h-0.3 M761.6,253.9v-0.6c-5.8-23.8-15.1-46.8-28.2-69.1 M26.5,314
	c-0.1,1.4,0,2.2,0.4,2.3l0.3,0.1h0.3 M27.5,316.5l3.6-0.3l-0.1,1.1l-0.1,1l-0.1,1.9l-0.1,0.8l-0.3,4.1v3.3l-0.1,4v1l0.1,1.9v3.2
	 M712.2,170.9c-0.5-2.6-3.6-4.5-9.1-5.9l-41.4-1.7 M727.2,173.6l6.2,10.9 M743.7,174.9l-16.5-1.4 M753.8,177.7
	c1.4,2.2,0.5,4.4-2.2,6.6 M751.6,184.4l-8-9.5 M751.6,184.4l2.6,0.6 M756.9,188.1l-2.6-3.2 M712.2,170.9l3.3,0.3
	c13.8,0.1,28.3,1.9,43.7,5.4l2.2,0.3 M761.4,176.9l-7.6,0.8 M743.7,174.9h1.4l8.8,2.8 M30.3,338.5l-2.2,0.5H28
	c-0.3,0.1-0.5,0.1-0.7,0.3l-0.1,0.1c-0.3,0.3-0.3,0.7-0.4,1.1l0.3,1.5 M34.3,337.5l-1,0.1l-0.4,0.1l-1.8,0.4l-0.4,0.1l-0.4,0.1
	 M40.3,331.5l-0.4,0.3l-0.1,0.1l-0.7,0.7l-0.4,0.3l-0.4,0.4l-0.3,0.1l-1.5,1.4l-0.1,0.3l-0.3,0.1l-0.3,0.4l-0.8,1l-0.1,0.3l-0.5,0.7
	 M27,342.2l0.3,1.5l4-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M31.2,342.9l-1.1,2.8l-0.5,0.7l-0.1,0.1l-0.7,0.5l-1.1,0.5l-5.5,2.2v12.7
	l2.2-0.3 M31.2,342.9l1-2.3 M32.2,340.6l0.1-0.3l1.9-2.8 M27,342.2l5.2-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M31.8,365.9c-1-2.9-2.2-4.3-3.4-4.3l-4,0.5 M84.2,326.3l-19.8,1.1
	l-1.8,0.1h-0.7l-15.5,1.8l-0.1,0.1l-3.4,0.4 M39.3,331.5v-1.9l0.3-5.9l0.1-1.2c0.1-2.2,0.3-4.3,0.5-6.3v-0.5 M42.8,329.8l-2.5,1.6
	 M27,306.7l-0.4,7.3 M56,373.2c-0.1,0.3-0.3,0.8-0.3,1.4c0,1.4,0.3,3,1,5.1c0.8,1.9,1.7,3.6,2.8,5s2.1,2.1,2.8,2.1h0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M62.3,387.2l-0.8,0.1c-1,0-2.1-0.8-3.4-2.3c-1.2-1.5-2.3-3.3-3.2-5.5
	c-0.8-2.2-1.1-4-1-5.5c0.1-1,0.3-1.5,0.7-1.9 M62.5,386.6l-0.1,0.5 M56,373.2c3.2,3.6,5.2,7.4,6.3,11.3l0.3,1.4v0.7 M54.8,372.1
	l0.5,0.4l0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M54.8,372.1c-1.8-1.5-3.9-2.2-6.2-2.2H42c-1.5-0.3-2.2,0.5-2.1,2.3
	c1.8,4.7,4.8,10.2,9.2,16.5c1.4,1.7,3,2.3,5,1.9l4.5-0.7c2.1-0.5,3.2-1.5,3.6-2.8 M54.5,404.2c-1.9-2.2-3.9-4.5-5.6-6.9 M38.2,394.2
	c-1.1,0.4-1.9,0.1-2.8-1c-3.2-4.3-6.2-9.8-8.9-16.5c-1.1-2.5-1.1-3.9,0-3.9h2.6c1.9,6.3,5.6,13.3,11,21L38.2,394.2L38.2,394.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M45,405.9l-3-3.7c-0.8-1.4-0.5-2.3,1.1-3c1.2-0.7,1.2-1.8,0.3-3.2
	l-0.3-0.4c-6.6-8.9-11-17.2-13.3-24.9c-0.7-2.6-1.8-3.9-3.3-3.7h-5.2 M46.9,405.8c-2.1,2.1-3,4.3-3,6.9v4.7l56,0.5v-16.8 M45,405.9
	l1.9-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M21.2,367c-0.1,0.8-0.1,1.6,0.1,2.8l1.1,4.5h1.2
	c5.1,12.8,11.3,22.8,18.7,30c0.8,0.8,1.7,1.4,2.6,1.5 M54.5,404.2l-7.6,1.5 M80.3,401.1l-16.8-2.8l-14.6-1 M49,397.4
	c-6.9-8.9-12.4-19-16.6-30l-0.4-1.5 M24.4,362.2l-2.6,3.3c-0.3,0.4-0.5,1-0.7,1.5 M164,370.7c-1.2,0.3-2.3,1-3.3,1.8l-0.6,0.4
	c-0.8,0.7-1.6,1.6-2.2,2.8 M164.2,365.5c-1.8,0.5-3.4,1.5-4.9,2.5 M164,370.7l0.3-5.2 M159.3,367.9c-1.4,1-2.8,2.1-4,3.3
	c-1,1-1.8,1.9-2.5,2.9 M153.1,390.4l-4.8-1.8 M161.2,397.4l0.6-0.8c1-1.4,1.2-2.9,0.7-4.4l-0.8-1.5c-0.6-1.4-1.5-2.3-3-2.9l-0.7-0.3
	 M157.8,387.6c0.1-2.6,0.8-5,2.1-7 M161.2,397.4c-2.3-2.6-3.4-5.8-3.4-9.2v-0.5 M159.9,380.6l-0.3-0.1 M157.8,387.6l-0.6-0.3
	 M152.7,374.1l5.2,1.4 M157.2,387.3l-4.1,3 M159.6,380.5l-1.6-5 M168.1,374.4c-2.3,0.5-4.3,1.8-6.1,3.7c-0.8,0.8-1.5,1.6-2.1,2.5
	 M159.6,380.5l-24.1-7.2c-1.5-0.3-2.1-1-1.8-2.1V371 M130.8,380c-0.4,2.6-0.7,5.2-0.7,8.1c0,11.7,4.1,21.6,12.4,29.9l1.5,1.4
	 M130.8,380l0.1-0.5c0.6-1,1.4-1,2.6-0.4l23.7,8.4 M143.1,386.6l-5.2-2.1c-1.1-0.5-1.9-0.3-2.3,0.8v0.4c0,0.8-0.1,1.5-0.1,2.3
	c0,2.1,0.1,4,0.4,5.9 M133.6,370.8c-1.4,2.9-2.3,5.9-2.9,9.1 M135.8,393.9c0.6,3.9,1.6,7.4,3.4,10.9 M139.2,404.8
	c1.4,2.5,2.9,5,5,7.2c1.2,0.8,2.2,0.7,2.8-0.5l3-4.5 M135.8,393.9c2.6,1.6,5.1,2.9,7.7,3.9 M155.9,397.6h5.1 M167.1,401.6
	c-1.9-0.7-3.6-1.8-5.2-3.4l-0.8-0.8 M160.9,397.6l0.3-0.3 M153.1,390.4c-0.1,1.2,0.1,2.5,0.7,3.7l0.3,0.7c0.4,1,1.1,1.9,1.9,2.9
	 M152.8,402.2c-0.7-1-1.2-1.8-1.8-2.8 M155.9,397.6l-3,4.5 M148.3,416.1l12.7-18.4 M152.8,402.2l-2.9,4.5 M148.3,388.6l-5.2-1.9
	 M151,399.4c-1.8-3.3-2.8-6.9-2.8-10.9 M149.9,406.7c-3.3,0.3-6.9-0.3-10.6-2.1 M143.5,397.8c2.5,0.8,4.9,1.4,7.6,1.6 M143.1,386.6
	l0.4,11.1 M99.9,401.1v-2.8c0.3-5.4,0.7-10.6,1.2-15.7 M99.9,401.1H80.3 M101.1,382.7l10.5-5.1 M153.2,419.8l-1.6,2.2
	c-1,1-1.1,1.8-0.4,2.6l0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M625.9,411l-6.9,8.5c-1,1-1,1.9,0.3,2.8c4.1,1.6,8.7,2.5,13.6,2.5
	c5,0,9.5-0.8,13.8-2.6c1.4-1.1,1.5-2.2,0.7-3.2l-6.7-8.4 M609,416.1l-1.5,2.2c-0.7,1.4-1.5,1.8-2.6,1.1l-0.3-0.1 M612.7,425.2
	c-2.9-1.5-5.5-3.6-8.1-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M613.9,419.8l-1.7,2.2c-1,1-1.1,1.8-0.4,2.6l0.7,0.7 M612.7,425.2
	c6.1,3.3,12.8,5.1,20.2,5.1c7.6,0,14.4-1.8,20.6-5.2 M661.5,418.8l-0.5,0.3c-1,0.4-1.7,0-2.3-1.2l-14.2-20.8 M653.4,424.9l0.5-0.5
	c1-0.8,0.8-1.6-0.3-2.8l-15.3-19.9 M653.4,424.9c2.9-1.6,5.5-3.7,8.1-6.1 M609,416.1c1.5,1.4,3.3,2.6,5,3.6 M627.7,401.9l-13.8,17.9
	 M564.7,352.5c-1.9,9.4-3.4,19.5-4.5,30.1 M625,365.5l0.3-4.8 M605.7,363.4c-1,1-1.8,2.1-2.6,3 M621.2,367.1l-15.5-3.7 M639.8,361.1
	l21.6,6.5 M591.5,380c0.5-3.2,1.5-6.3,2.9-9.1 M640.4,370.7l-0.3-5.4 M422.7,282.9l8.4-0.1c1,0,1.5,0.4,1.6,1.2
	c0.3,1.4,0.4,3,0.1,4.7c-0.1,0.8-0.7,1.4-1.5,1.4l-8.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M426,285.7c-0.3,0.3-0.4,0.8-0.4,1.2s0.1,0.8,0.4,1.2
	c0.3,0.3,0.8,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.3-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-1-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5
	S426.3,285.3,426,285.7L426,285.7z M422.7,290.1v-7.2 M416,283.1c-0.1-1.1-0.4-2.1-0.8-2.9c-0.8-1.6-2.3-2.6-4.4-2.9l-5-0.3
	l-4.7,0.3c-2.1,0.3-3.6,1.2-4.4,2.9c-0.4,0.8-0.7,1.9-0.8,3 M394.3,283.2l-7.7,0.1c-1,0.1-1.6,0.7-1.9,1.8v2.5c0.3,1,1,1.5,2.2,1.8
	l7.6,0.1 M395.9,283.2h-1.6 M396.2,289.7c0.1,2.2,0.8,4,2.2,5.2c0.7,0.5,1.5,1.1,2.5,1.4l1,0.3c1.2,0.3,2.3,0.4,3.6,0.4h0.5l4.1-0.4
	l1-0.3c1-0.3,1.8-0.8,2.5-1.4c1.4-1.1,2.1-2.8,2.2-5 M394.3,289.5l1.9,0.1 M416,283.1l-20.1,0.1 M415.7,289.9l-19.5-0.3
	 M394.3,289.5v-6.3 M444.9,265.3V389 M415.7,289.9l7,0.1 M422.7,282.9L416,283 M551.1,389H444.9 M322.5,183.6
	c6.3-2.9,13.2-5.4,20.6-7.6 M432.9,259.3l-126.7,3.2 M444.9,265.3v-6.5 M444.9,389H266.3 M306.1,268.2l138.8-2.9 M257.5,396.1h301.4
	 M442.3,169.6l-16.5,0.1c-9.8,0.1-18.4,0.3-26,0.6l-13.2,1.4h-0.1c-19.5,2.6-35.6,7.3-48.1,14.3l-3.2,1.9"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M84.2,326.3l21-0.8
		l11.3-0.4c0,0,7.7-8.9,19.9-11.7c0,0,14.6-3,33.4-2.5l12-0.1h8.7c0,0,24.2-0.6,34,6.7c0,0,8.8,4.5,13.2,17.5c0,0,3.6,12.1,6.1,26.6
		c0,0,2.1,12.7,3.3,27.4h19c0,0-3.3-15.7-4.7-26.4c0,0-2.2-14.2-3.7-36.3c0,0-1.7-21.7,0.5-44.7c0,0,0.4-10.7,5.4-32.2
		c0,0,1.9-6.2-1.8-7.3l-8.3,7.2c0,0-4.4,3.7-12.4,5.9c0,0-4.4,1.4-13.1,1.5c0,0-29.6,1.6-58.7,4.5c0,0-20.4,1.8-37.1,4.5
		c0,0-15.7,2.2-34.8,5.9c0,0-11.7,2.3-19.3,4.5c0,0-7.7,1.1-11.6,12.9h22c0,0,3.2-0.3,2.5,5.8L84.2,326.3z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M259.7,269.6l49.5-1.4
		l135.6-2.9v123.5H266.5c0,0-3.2-12.7-6.2-37.6c0,0-3.4-25-2.9-51C257.4,300.4,257.5,283.2,259.7,269.6z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M295.1,261.2
		c0,0,3.9,1.4,15.1,0c0,0,6.1-0.7,6.6-6.1c0,0,0.3-9.5-2.8-14.9c0,0-1.8-3.2-7-3.9l-5.1-0.3c0,0-4.4-0.7-5.9,3l-1.9,8.7h-5.5
		c0,0-1.5-0.3-1.2,2.8C287.2,250.6,287.5,257.1,295.1,261.2z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M313.5,259.9
		l118.3-2.9l-1.4-66c0,0,0.4-6.7-4-7.7l-14.3,0.3c0,0-33.6,0.4-56,10.9c0,0-17.6,7.2-49,33.3l-0.3,8.7c0,0,4.7,0.4,7,3.9
		c0,0,1.9,2.8,2.6,9.1c0,0,0.3,3.9,0.3,5.6C316.8,255,316.5,258.4,313.5,259.9z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M444.9,389h106.2
		c0,0,3-27.4,6.1-40.6c3-13.2,5.2-36.5,37.1-46.2l1.5-0.3c0,0,0.6-13.8-0.7-36.6l-21.9-11.4c0,0-2.6,6.1-28.5,8.7l-6.9,0.3
		l-93.1,2.3V389H444.9L444.9,389z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M595.8,310.3
		c0,0,0.6-20.5-0.7-45l30.4-15.5c0,0,4.7,4.1,16.6,4.1h119.5v69.9h-67c0,0-3.4-6.6-12.4-11.3c0,0-6.3-3.9-22.4-4.7
		c0,0-5.9-0.3-13.9-0.3c0,0-7.4,0-13.3,0C632.8,307.5,612.2,306.9,595.8,310.3z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M623,247l-31.5-57.6
		c0,0-2.9-3.3-1.1-5.6c0,0,1-2.3,9.1-2.5l133.8,3c0,0,19,29.9,28.2,69.1v0.6H642C642.1,253.9,627.6,254.6,623,247z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M461.8,256.1
		l-3.6-68.1v-0.8c0,0,0-4.1,3.3-4.7l66.3-0.1c0,0,12-0.3,20.1,3.4c0,0,8.3,2.6,12.7,12.7c0,0,6.9,14.2,9.8,27.8
		c0,0,1.8,7.7,1.5,14.6c0,0,1,4.5-4,8.3c0,0-4.1,3.7-18.3,4.4L461.8,256.1z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="172.5"
              cy="386.9"
              r="62.3"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="632.5"
              cy="387.9"
              r="62.3"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv4DrLeft);
