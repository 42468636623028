import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup2DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M403.1,170.2c-5.4,0-9.4,1.2-11.8,3.7l-0.6,0.7h9.6l0.3-0.7c0.8-1.1,2.9-1.7,6.2-1.6h5
	c2.1,0,3.6,0.4,4.6,1.6h0.3c-0.9-2.4-2.3-3.6-3.9-3.6L403.1,170.2L403.1,170.2z"
          />
          <path
            fill="#FFFFFF"
            d="M400.7,173.8l-0.3,0.7c4.5,1.1,10.1,1.2,16.8,0.2l-0.7-1L400.7,173.8L400.7,173.8z"
          />
          <path
            fill="#FFFFFF"
            d="M406.9,172.2c-3.4,0-5.4,0.4-6.2,1.6h15.8c-1-1.1-2.6-1.6-4.6-1.6H406.9L406.9,172.2z M390.7,174.5l0.6-0.7
	l-71.6,0.6l-0.2,0.6c-0.6,1.1-1.2,1.9-2.3,2.1l-1.2,0.1l-1.5-0.2l-1-0.1l-1.2,4.3h3.2l19.6-0.1c26.1-0.1,52.3-0.1,78.4,0.1h4.7
	l14.1,0.1c-0.7-3.3-1.5-5.7-2.5-7.3h-4.1l0.2,0.3l-8.9,0.7c-6.8,1-12.3,0.9-16.8-0.2L390.7,174.5L390.7,174.5z"
          />
          <path
            fill="#FFFFFF"
            d="M426.1,174.1l-0.2-0.3c-2.4-2.7-6.1-3.9-11.2-3.6h-1.8c1.7,0.1,3,1.2,3.9,3.6l0.3,1L426.1,174.1L426.1,174.1
	z"
          />
          <path fill="#056DA5" d="M416.8,173.8h-0.3l0.7,1" />
          <path
            fill="#FFFFFF"
            d="M418.2,184.5l0.8,0.7c1.4,1.5,2.7,3.9,3.9,7.6c1.6,4.4,2.4,7.7,2.3,9.8h0.6c0.9-0.2,1.5-1,1.5-2.1
	c-0.7-3.9-1.9-8.3-3.8-13c-0.5-1.5-1-2.7-1.7-3.7c-0.9-1.4-2-2.3-3.4-2.7h-4.7v2.7h1.9C416.7,183.5,417.5,183.7,418.2,184.5
	L418.2,184.5z"
          />
          <path
            fill="#FFFFFF"
            d="M418.5,181c1.4,0.4,2.5,1.4,3.4,2.7c0.7,1,1.2,2.3,1.7,3.7c1.8,4.6,3.2,8.9,3.8,13c0,1.1-0.6,1.9-1.5,2.1
	l11.7,0.2c-1.1-6.9-2.5-13.2-4.3-18.8l-0.9-2.7L418.5,181L418.5,181z M413.6,183.7V181c-26.1-0.2-52.3-0.2-78.4-0.1l-19.6,0.1
	l0.6,2.7l26.9-0.1c22.7-0.1,45.4,0,68.2,0.1H413.6z"
          />
          <path
            fill="#FFFFFF"
            d="M432.4,181.1l0.9,2.7c1.8,5.7,3.2,11.9,4.3,18.8l0.3,2.7l59.6,1.9c6.9-0.1,11-2.1,12.7-6
	c3.6-6,5.6-14.3,6.1-25c-1.6-1.1-3.5-1.7-5.7-1.8l-80.8-0.7C431.1,175.4,431.9,177.8,432.4,181.1l14.7,0.6h1
	c16.2,0.2,32.3,1.2,48.2,3c2.3,0.1,3.6,0.7,4.3,1.7c0.5,0.7,0.6,1.7,0.3,2.8l-1.2,7.4c-0.9,3.2-3.5,5.1-7.8,5.5
	c-12.3,0.4-25.6,0.6-39.9,0.2h-2.1c-4.2-0.2-6.3-1.7-6.6-4.3l-1.9-12.7c-0.6-2.4,0.8-3.6,4.3-3.6h1.4L432.4,181.1L432.4,181.1z
	 M317.4,147.2c-0.7,0.4-1.2,0.9-1.8,1.5c-5.5,5.6-9.8,13.6-12.8,23.9c-0.3,0.7-0.3,1.2-0.2,1.6c0.1,0.3,0.5,0.6,0.9,0.8l10.3,1.9
	l1,0.1l10.9-32.3C322.6,144.6,319.7,145.5,317.4,147.2L317.4,147.2z M326.3,144.6h-0.6l-10.9,32.3l1.4,0.2h1.2l10.6-31.4l0.2-0.9
	 M319.6,174.9l0.2-0.6c3.6-10.7,6.4-20.2,8.2-28.6L317.5,177C318.4,176.8,319.1,176,319.6,174.9L319.6,174.9z"
          />
          <path fill="#056DA5" d="M314,181h0.5H314z" />
          <path
            fill="#FFFFFF"
            d="M312.5,181.1l1.2-4.3l-10.3-1.9c-0.2,3.2-1.2,5.4-3.2,6.8L312.5,181.1L312.5,181.1z M335.2,193.7l-7.4-1.8
	l-12.2-3.2c-7.2-2-14-4.2-20.5-6.4l-5.3,0.9l0.3,0.2l1.9,0.7c6.2,2.3,12.5,4.3,19.3,6.2l15.9,4.3l8.1,1.9c6.8,1.6,13.8,3,21,4.4h0.1
	c13.8,1.9,28.4,3.2,44.1,3.6c12.6,0.4,25.1,0.8,37.8,1l-0.3-2.7l-11.7-0.2l-0.6,0.1v-0.1l-24.9-0.8c-15.6-0.4-30.1-1.6-43.8-3.5h0.1
	C349.3,196.9,342.2,195.3,335.2,193.7L335.2,193.7z"
          />
          <path
            fill="#FFFFFF"
            d="M335.2,196.5l-8.1-1.9l-15.9-4.3c-6.6-1.9-13.1-4.1-19.3-6.2l-1.9-0.7l-0.3-0.2c-4.1,1.2-7.9,3.6-11.4,7
	c19.8,6.2,38.8,11.3,56.8,15l11,2.3c5.7,1.1,11.4,2,16.9,2.8l9,1c16.9,1.6,39.3,3,67.5,4.3l67.7,3.6c2.1,0.2,3.8,0.6,5.3,1.2
	c0.9,0.3,1.6,0.9,2.1,1.5v-2.7c-0.1-3.4,0.1-6.2,0.9-8.5v-0.1l5.7-14.4c0.1-0.3,0.3-0.6,0.6-0.7l0.6-0.1c0-7.6-1.2-13-3.6-16.5
	c-0.8-1-1.6-1.9-2.6-2.6c-0.5,10.7-2.4,19.2-6.1,25c-1.7,3.9-6,6-12.7,6l-59.6-1.9c-12.6-0.2-25.1-0.6-37.8-1
	c-15.7-0.4-30.3-1.6-44.1-3.6h-0.1C348.9,199.6,341.9,198.1,335.2,196.5L335.2,196.5z"
          />
          <path
            fill="#FFFFFF"
            d="M372.3,211.2l-9-1c-5.5-0.8-11.2-1.7-16.9-2.8l-11-2.3c-18-3.8-37-8.8-56.8-15c-1.5,0.5-2.8,1.2-4.2,2.5
	c-0.8,0.7-1.5,1.6-2.3,2.6c-5.5,7.4-10,22.2-13.3,44.4c-2,17.2-3,35.3-3,54c0,18.6,1,36.5,3,53.8c3.3,22.2,7.7,37,13.3,44.4
	c0.8,1,1.5,1.9,2.3,2.6c1.4,1.2,2.7,2,4.2,2.5c19.8-6.2,38.8-11.3,56.8-15.1l11-2.3c5.7-1,11.4-2,16.9-2.8l9-1
	c16.9-1.6,39.3-3,67.5-4.3l67.7-3.6c2.1-0.2,3.8-0.6,5.3-1.2c0.9-0.3,1.6-0.9,2.1-1.5v-3.3c-2.5,0.8-5,1.2-7.4,1.5l-67.7,3.6
	c-28.2,1.2-50.7,2.7-67.5,4.3l-9,0.9l-15.7,2.6l-3.8,0.8l-8.6,2c-17.9,4.3-35.4,9.1-52.4,14.5c-2.6,0.8-4.5-1.7-5.7-7.3
	c-4.4-23.9-6.8-51.1-7.3-81.6v-20c0.6-30.5,2.9-57.7,7.3-81.6c1.1-5.7,3-8.1,5.7-7.3c17.1,5.4,34.6,10.3,52.4,14.5l8.6,2l3.8,0.8
	l15.7,2.6l9,0.9c16.9,1.7,39.3,3,67.5,4.4l68.9,3.7h0.1c2,0.2,4.1,0.7,6.1,1.4v-3.3c-0.6-0.6-1.4-1-2.1-1.5c-1.4-0.7-3.2-1-5.3-1.2
	l-67.7-3.6C411.6,214.2,389.1,212.8,372.3,211.2L372.3,211.2z"
          />
          <path
            fill="#FFFFFF"
            d="M372.3,215.5l-9-0.9l-15.7-2.6v163.1l15.7-2.6l9-0.9c16.9-1.7,39.3-3,67.5-4.3l67.7-3.6
	c2.5-0.2,5-0.7,7.4-1.5V315c-2.7-1-3.9-3.9-3.8-8.8v-25.4c-0.1-4.8,1.1-7.8,3.8-8.8v-47.2c-2-0.7-4.1-1-6.1-1.4h-0.1l-69-3.6
	C411.6,218.6,389.1,217.2,372.3,215.5L372.3,215.5z M347.6,212l-3.8-0.8V376l3.8-0.9"
          />
          <path fill="#056DA5" d="M425.3,202.5v0.1l0.6-0.1" />
          <path
            fill="#FFFFFF"
            d="M303.5,174.9c-0.5-0.2-0.8-0.4-0.9-0.8l-21.2-3.3c1.2,4.3,3.6,8,6.9,11l1.5,1.2l5.3-0.9l5.3-0.6
	C302.3,180.3,303.3,178.1,303.5,174.9L303.5,174.9z"
          />
          <path
            fill="#FFFFFF"
            d="M281.4,170.9l-5-0.8c-3.9-1.1-8.7-1.6-14.2-1.1h-99.5c-4.2-0.2-8.7-0.3-13.4-0.3c-5.4,1.4-9.1,2.9-11,4.7
	l-1.7,2.9c3.2-1,7-1.6,11.7-1.9l5.8-0.3c1.2,0.1,1.8,0.7,1.7,1.5c-0.1,0.4-0.2,0.9-0.6,1.5l-3.2,3.9c-1.1,1.1-2.4,2.1-3.7,2.8
	l-1.7,0.7c-0.6,0.1-1.1,0.3-1.6,0.4l-8.2,2.7l151.7-5.8C285,178.8,282.8,175.2,281.4,170.9L281.4,170.9z M274.3,192.7
	c1.4-1.2,2.7-2,4.2-2.5c3.6-3.4,7.3-5.7,11.4-7l-1.5-1.2l-151.7,5.7c-1.6,0.6-3,1.4-4.3,2.4c-1.1,0.9-2.1,1.9-3,3.3
	c45.5-3.7,91.3-5.3,137.3-4.8C271.1,188.5,273.5,190,274.3,192.7L274.3,192.7z"
          />
          <path
            fill="#FFFFFF"
            d="M272,195.3c0.8-1,1.5-1.9,2.3-2.6c-0.7-2.7-3.3-4.1-7.7-4.3c-46-0.4-91.7,1.1-137.3,4.8
	c-0.7,0.9-1.2,1.9-1.7,3l-10.3,20.5c-1.2,2.6-2.6,4.4-4.1,5.5l-1.4,0.8c42.7-3.4,92.2-10,148.7-19.8
	C263.3,198.3,267.1,195.8,272,195.3L272,195.3z M276.5,170.2c-5.3-4.7-15.8-7.6-31.3-8.5h-81.8c-14,0.7-23.2,3.2-27.7,7.7l13.8-0.7
	c4.7-0.1,9.2,0,13.4,0.3h99.5C267.8,168.6,272.5,168.9,276.5,170.2L276.5,170.2z"
          />
          <path
            fill="#FFFFFF"
            d="M258.3,208.7c0.7-2,1.5-3.8,2.4-5.5C204.3,213,154.7,219.6,112,223c-1.4,21-2,44.1-2,69.1v5.4
	c0,24,0.7,46.2,1.9,66.5c42.7,3.4,92.2,10,148.7,19.8c-0.9-1.6-1.7-3.5-2.4-5.5c-64.1-8.8-107.2-16.7-128.9-23.6
	c-8.8-2-13.5-5.9-14.3-11.5v-99.5c0.8-5.6,5.5-9.5,14.3-11.5C151.1,225.4,194.2,217.5,258.3,208.7L258.3,208.7z"
          />
          <path
            fill="#FFFFFF"
            d="M260.7,203.2c-0.9,1.6-1.7,3.4-2.4,5.5c-2.4,7.4-4.4,15.6-6,24.5c-3,17.4-4.5,37.4-4.5,60.3
	c0,23,1.6,43.1,4.5,60.4c1.6,8.9,3.6,17,6,24.5c0.7,2,1.5,3.9,2.4,5.5c2.7,4.9,6.4,7.6,11.4,7.9c-5.5-7.4-10-22.2-13.3-44.4
	c-2-17.2-3-35.2-3-53.8c0-18.7,1-36.7,3-54c3.3-22.2,7.7-37,13.3-44.4C267.1,195.8,263.3,198.3,260.7,203.2L260.7,203.2z"
          />
          <path
            fill="#FFFFFF"
            d="M252.3,233.2c1.6-8.9,3.6-17,6-24.5c-64.1,8.8-107.2,16.7-128.9,23.6c-8.8,2-13.5,5.9-14.3,11.5v99.5
	c0.8,5.6,5.5,9.5,14.3,11.5c21.8,7,64.7,14.8,128.9,23.6c-2.4-7.4-4.4-15.6-6-24.5l-128.9-15.4L252.3,354c-3-17.4-4.5-37.4-4.5-60.4
	C247.8,270.6,249.3,250.5,252.3,233.2l-128.9,15.4L252.3,233.2L252.3,233.2z M274.3,394.5c-0.8-0.8-1.5-1.6-2.3-2.6
	c-5-0.5-8.7-3-11.4-7.9c-56.4-9.8-105.9-16.5-148.7-19.8c0.4,0.2,0.9,0.5,1.4,0.8c1.5,1.1,2.8,2.9,4.1,5.5l10.3,20.5
	c0.4,1.1,1,2.1,1.7,3c45.5,3.7,91.3,5.3,137.3,4.8C271,398.5,273.5,397.2,274.3,394.5L274.3,394.5z"
          />
          <path
            fill="#FFFFFF"
            d="M278.4,396.9c-1.5-0.5-2.8-1.2-4.2-2.5c-0.7,2.7-3.3,4.1-7.7,4.3c-46,0.5-91.7-1.1-137.3-4.8
	c0.8,1.2,1.8,2.4,3,3.2c1.2,1,2.7,1.8,4.3,2.4l151.7,5.7l1.5-1.2C285.7,402.7,282,400.3,278.4,396.9L278.4,396.9z M289.8,403.9
	l-1.5,1.2c-3.4,3-5.6,6.8-6.9,11l21.2-3.3c0.1-0.3,0.5-0.6,0.9-0.7c-0.2-3.2-1.2-5.4-3.2-6.8l-5.3-0.6L289.8,403.9L289.8,403.9z"
          />
          <path
            fill="#FFFFFF"
            d="M289.8,403.9l5.3,0.9c6.5-2.3,13.3-4.5,20.5-6.5l12.2-3.3l7.4-1.7c6.9-1.6,14.1-3,21.4-4.5h-0.1
	c13.6-1.9,28.3-3.2,43.8-3.6c8.3-0.2,16.6-0.6,24.9-0.7v-0.1l0.6,0.1l11.7-0.2l0.3-2.7c-12.6,0.2-25.1,0.6-37.8,1
	c-15.7,0.5-30.3,1.6-44.1,3.6h-0.1c-7.2,1.4-14.2,2.8-21,4.3l-8.1,1.9c-4.2,1-8.3,2.1-12.3,3.3l-3.6,1c-6.6,1.9-13.1,4.1-19.3,6.2
	l-1.9,0.7L289.8,403.9L289.8,403.9z"
          />
          <path
            fill="#FFFFFF"
            d="M289.8,403.9l0.3-0.2l1.9-0.7c6.2-2.3,12.5-4.3,19.3-6.2l3.6-1c3.9-1.1,8.1-2.3,12.3-3.3l8.1-1.9
	c6.8-1.6,13.8-3,21-4.3h0.1c13.8-1.9,28.4-3.2,44.1-3.6c12.6-0.5,25.1-0.8,37.8-1l59.6-1.8c4.3,0,7.5,0.8,9.8,2.4
	c1.4,0.9,2.4,2.1,3,3.6c3.6,6,5.6,14.3,6.1,25c1-0.7,1.8-1.5,2.6-2.6c2.4-3.4,3.6-8.9,3.6-16.5l-0.6-0.1c-0.2-0.1-0.5-0.3-0.6-0.7
	l-5.7-14.4v-0.1c-0.7-2.3-1-5.1-0.9-8.5v-2.8c-0.6,0.6-1.4,1-2.1,1.5c-1.4,0.7-3.2,1-5.3,1.2l-67.7,3.6c-28.2,1.2-50.7,2.7-67.5,4.3
	l-9,1c-5.5,0.8-11.2,1.7-16.9,2.8l-11,2.3c-18,3.8-37,8.8-56.8,15.1C282,400.3,285.7,402.7,289.8,403.9L289.8,403.9z M302.6,412.9
	c-0.2,0.5-0.1,1,0.2,1.6c2.9,10.3,7.2,18.1,12.8,23.9c0.6,0.6,1.1,1,1.8,1.5c2.4,1.7,5.2,2.6,8.3,2.6l-10.9-32.3l-1,0.2l-10.3,1.9
	C303.1,412.4,302.7,412.6,302.6,412.9L302.6,412.9z M276.5,417l5-0.8c1.2-4.3,3.6-8,6.9-11l-151.7-5.7l8.2,2.8l1.6,0.5l1.7,0.7
	c1.2,0.7,2.5,1.6,3.7,2.8l3.2,3.9c0.3,0.6,0.6,1,0.6,1.4c0.1,0.8-0.4,1.4-1.7,1.5l-5.7-0.3c-4.7-0.3-8.6-0.9-11.7-1.9l1.7,3
	c1.9,1.8,5.5,3.4,11,4.7c4.7,0.1,9.2,0,13.4-0.3H262C267.8,418.6,272.5,418.1,276.5,417L276.5,417z"
          />
          <path
            fill="#FFFFFF"
            d="M245.1,425.4c15.6-0.9,26-3.7,31.3-8.5c-3.9,1.1-8.7,1.6-14.2,1.1h-99.5c-4.2,0.2-8.7,0.3-13.4,0.3
	l-13.8-0.7c4.5,4.5,13.8,7.1,27.7,7.7L245.1,425.4L245.1,425.4z M300.4,405.4c1.8,1.4,2.9,3.6,3.2,6.8l10.3-1.9l-1.2-4.3
	L300.4,405.4L300.4,405.4z"
          />
          <path fill="#056DA5" d="M314,406.1h0.5l-0.3-0.1" />
          <path
            fill="#FFFFFF"
            d="M314.4,406.1h-2l1.2,4.3l1-0.2l1.5-0.2l1.2,0.1c1,0.2,1.7,1,2.3,2.3l0.2,0.6l15.4,0.1
	c18.8,0.2,37.5,0.2,56.1,0.3l-0.6-0.7h9.6c4.5-1.1,10.1-1.2,16.8-0.2l8.9,0.7l-0.2,0.3h4.1c1-1.7,1.9-4.2,2.5-7.4l-14.1,0.1h-4.7
	c-26.1,0.1-52.3,0.2-78.4,0.1l-19.6-0.1L314.4,406.1L314.4,406.1z"
          />
          <path
            fill="#FFFFFF"
            d="M316.1,403.4l-0.6,2.7l19.6,0.1c26.1,0.1,52.3,0.1,78.4-0.1v-2.7h-2.4c-22.8,0.1-45.5,0.2-68.2,0.1
	L316.1,403.4L316.1,403.4z M317.4,410l-1.2-0.1l-1.4,0.2l10.9,32.3h0.6l1.9-0.2l-0.2-0.9"
          />
          <path
            fill="#FFFFFF"
            d="M319.7,412.8l-0.2-0.6c-0.6-1.2-1.2-1.9-2.3-2.3l10.6,31.3C326.2,433,323.5,423.5,319.7,412.8L319.7,412.8z
	 M425.9,384.6c0.9,0.2,1.5,0.9,1.5,2c-0.7,4.1-1.9,8.3-3.8,13c-0.5,1.5-1,2.7-1.7,3.7c-0.9,1.4-2,2.3-3.4,2.7l14.1-0.1l0.9-2.7
	c1.8-5.7,3.2-11.9,4.3-18.8L425.9,384.6L425.9,384.6z"
          />
          <path
            fill="#FFFFFF"
            d="M427.4,386.7c0-1.1-0.6-1.8-1.5-2h-0.6c0,2.1-0.8,5.4-2.3,9.8c-1.4,3.6-2.7,6.1-3.9,7.5l-0.8,0.7
	c-0.8,0.7-1.6,0.9-2.7,0.7h-1.9v2.7h4.7c1.4-0.5,2.5-1.4,3.4-2.7c0.7-1,1.2-2.3,1.7-3.7C425.3,395,426.6,390.7,427.4,386.7
	L427.4,386.7z M438,381.6l-0.3,2.7c-1.1,6.9-2.5,13.2-4.3,18.8l-0.9,2.7l15.7-0.8h-2.5c-3.4-0.1-4.8-1.2-4.3-3.6l1.9-12.7
	c0.2-2.6,2.5-3.9,6.6-4.3l2.1-0.1c14.2-0.3,27.5-0.3,39.9,0.2c4.3,0.5,6.9,2.4,7.8,5.5l1.2,7.4c0.2,1.1,0.1,2.1-0.3,2.8
	c-0.7,1-2.1,1.6-4.3,1.7c-15.9,1.8-31.9,2.8-48.1,3.2l-15.7,0.8c-0.7,3.3-1.5,5.7-2.5,7.4l80.8-0.8c2.3-0.1,4.1-0.7,5.7-1.8
	c-0.5-10.7-2.4-19.2-6.1-25c-0.7-1.5-1.6-2.7-3-3.6c-2.3-1.6-5.5-2.4-9.8-2.4L438,381.6L438,381.6z"
          />
          <path fill="#056DA5" d="M425.3,384.5v0.1h0.6" />
          <path
            fill="#FFFFFF"
            d="M391.4,413.3c2.5,2.4,6.4,3.6,11.8,3.7h9.7c1.7-0.1,3-1.2,3.9-3.6h-0.3c-1,1.1-2.6,1.6-4.6,1.6h-5.1
	c-3.4,0-5.4-0.6-6.2-1.7l-0.3-0.7h-9.6L391.4,413.3L391.4,413.3z"
          />
          <path
            fill="#FFFFFF"
            d="M400.7,413.3c0.8,1.1,2.9,1.7,6.2,1.7h5c2.1,0,3.6-0.5,4.6-1.6L400.7,413.3L400.7,413.3z M400.3,412.6
	l0.3,0.7l15.8,0.1l0.7-1C410.5,411.4,404.8,411.5,400.3,412.6L400.3,412.6z"
          />
          <path
            fill="#FFFFFF"
            d="M425.9,413.4l0.2-0.3l-8.9-0.7l-0.3,1c-0.9,2.4-2.3,3.6-3.9,3.6h1.8C419.8,417.3,423.5,416.1,425.9,413.4
	L425.9,413.4z"
          />
          <path fill="#056DA5" d="M417.1,412.4l-0.7,1h0.3" />
          <path
            fill="#FFFFFF"
            d="M121.2,194c2.4-5.6,5.2-10.3,8.6-14c0.6-0.6,1.1-1,1.8-1.5c0.8-0.4,1.6-0.9,2.6-1.4c0.8-0.3,1.6-0.6,2.5-0.9
	l1.7-2.9c1.9-1.8,5.5-3.4,11-4.7l-13.8,0.7c-7.7,0.7-13.5,3.2-17.5,7.6l-1.4,1.7l-10.8,28.1l-4.1,10.8c0.1-0.2,0.2-0.6,0.5-0.8
	c1.4-2,4.4-3.5,8.8-4.3l6.4-14.8C118.3,195.8,119.7,194.6,121.2,194L121.2,194z M116.6,178.6c-1,1.4-1.8,2.8-2.5,4.3l-8.2,16.9
	c-1,2.5-1.1,4.7-0.2,7L116.6,178.6L116.6,178.6z M95.1,230.8c-1,2.1-1.5,7.2-1.2,15.1v95.2c-0.2,7.9,0.2,12.8,1.2,15l3,6l0.8,1.4
	l0.2,0.1l0.4,0.1c0.2,0,0.3-0.1,0.5-0.2s0.1-0.3,0.1-0.5V224.2c0-0.2,0-0.3-0.1-0.5l-0.4-0.3l-0.5,0.1l-0.2,0.1l-0.8,1.4L95.1,230.8
	L95.1,230.8z M96.6,231.6l2-4.1v132.1l-2-4.1c-0.9-2-1.2-6.8-1-14.3v-95.3C95.3,238.4,95.7,233.6,96.6,231.6L96.6,231.6z"
          />
          <path
            fill="#FFFFFF"
            d="M98.6,227.5l-2,4.1c-0.9,2-1.2,6.8-1,14.3v39.3l1.5-2.8l1.5-2.9v-52C98.5,227.5,98.6,227.5,98.6,227.5z
	 M97,307.8l1.6,1.7v-30l-1.6,2.9"
          />
          <path
            fill="#FFFFFF"
            d="M98.6,309.5l-1.5-1.8l-1.5-1.7v35.3c-0.2,7.4,0.1,12.3,1,14.3l2,4.1L98.6,309.5L98.6,309.5z"
          />
          <path fill="#FFFFFF" d="M97,307.8v-25.4l-1.5,2.8V306" />
          <path
            fill="#FFFFFF"
            d="M95.1,230.9l3-6l0.6-1.1c0.1-0.1,0.1-0.2,0.2-0.2c0.9-1.8,1.8-3.7,2.6-5.6l0.2-0.5l4.1-10.8
	c-0.9-2.1-0.9-4.5,0.2-7l-5.2,10.7c-0.7,0.2-1.2,0.4-1.9,0.7c-4.5,3.2-6.8,9.9-7,20.2v124.4c0.2,10.4,2.6,17,7,20.2
	c0.6,0.2,1.2,0.5,1.9,0.7l5.2,10.7c-1-2.5-1.1-4.8-0.2-7l-4.1-10.8l-0.2-0.3c-0.8-1.9-1.7-3.8-2.6-5.6l-0.2-0.2l-0.6-1.1l-3-6
	c-1-2.1-1.5-7.1-1.2-15v-95.2C93.8,238,94.1,233.1,95.1,230.9L95.1,230.9z"
          />
          <path
            fill="#FFFFFF"
            d="M102.2,216.7c-0.2,0.2-0.3,0.6-0.4,0.8l-0.2,0.4c-0.8,1.9-1.7,3.8-2.6,5.6l0.2-0.1l0.4-0.1l0.5,0.3
	c0.1,0.1,0.1,0.3,0.1,0.4V363c0,0.2,0,0.3-0.1,0.5s-0.2,0.2-0.4,0.2l-0.5-0.1l-0.2-0.1c0.9,1.8,1.8,3.6,2.6,5.6l0.2,0.3l0.4,0.8
	V216.7z"
          />
          <path
            fill="#FFFFFF"
            d="M109.8,216.8c0.3-1.6,0.7-3,1.1-4.4c-4.5,0.8-7.4,2.3-8.8,4.3v153.7c1.4,2,4.4,3.5,8.8,4.3
	c-0.4-1.4-0.9-2.8-1.1-4.4c-0.3-1.6-0.6-3.4-0.8-5.3c-0.2-1.1,0.2-1.7,1.2-1.6l1.7,0.6c-1.4-20.3-2-42.5-1.9-66.5v-5.4
	c-0.1-25.1,0.6-48.1,2-69.1l-1.7,0.6c-1,0.1-1.5-0.3-1.2-1.6C109.3,220.2,109.5,218.4,109.8,216.8L109.8,216.8z"
          />
          <path
            fill="#056DA5"
            d="M98.2,224.8l0.8-1.4c-0.1,0.1-0.2,0.1-0.2,0.2L98.2,224.8z M98.2,362.2l0.6,1.1l0.2,0.2"
          />
          <path
            fill="#FFFFFF"
            d="M102.2,370.4l-0.4-0.8l4.1,10.8l10.8,28.1l1.4,1.7c3.9,4.4,9.8,7,17.5,7.5l13.8,0.7c-5.4-1.4-9.1-2.8-11-4.7
	l-1.7-3c-0.9-0.2-1.7-0.6-2.5-0.9c-0.9-0.5-1.8-0.9-2.6-1.4l-1.8-1.5c-3.4-3.7-6.3-8.3-8.6-14c-1.5-0.6-2.7-1.8-3.7-3.6l-6.4-14.8
	C106.5,373.9,103.6,372.5,102.2,370.4L102.2,370.4z"
          />
          <path
            fill="#FFFFFF"
            d="M105.8,380.4c-0.9,2.1-0.9,4.5,0.2,7l8.1,16.8c0.7,1.6,1.5,3,2.5,4.3L105.8,380.4L105.8,380.4z M749.8,173.6
	l1.6,1.2h8.5c1.7-0.1,2.9,0.3,3.6,1.5l0.5,0.8l0.9,8.5l0.9,2c-0.2-4.8-0.7-8.2-1.2-10.1c-0.7-2.4-2.1-3.6-4.4-3.7h-10.3L749.8,173.6
	L749.8,173.6z"
          />
          <path
            fill="#FFFFFF"
            d="M751.4,174.8l-1.6-1.2l-3.7,1.4c-2,0.8-1.6,1.5,1.4,2l1.1-0.7L751.4,174.8L751.4,174.8z"
          />
          <path
            fill="#FFFFFF"
            d="M746.1,174.9l3.7-1.4h10.4c2.3,0.1,3.7,1.4,4.4,3.7c0.6,2,1,5.4,1.2,10.1h7.2V181c-0.1-0.8-0.2-1.6-0.3-2.3
	c-1.1-4.7-4.7-7.1-10.7-7.3h-14l-11.2-1.1h-5.4c-9.8-0.2-17.8-1.5-23.9-3.5c-10.9,1.8-22.9,2.7-36,2.7l-92.5,1.6l-10.4,0.4
	c-1.7,0.2-3.5,0.6-5.2,0.9L561,173l-35.3,0.7l-1.8,7.9l140.7-1.1H746c11.4,0.7,17.9,3.5,19.7,8.8v-1.8l-0.9-2l-0.9-1.9
	c-1-1.2-2.5-2.4-4.2-3.3c-2.6-1.4-6.1-2.4-10.3-3l-1.9-0.3C744.6,176.4,744.2,175.7,746.1,174.9L746.1,174.9z M724.8,191.4v-2.1
	h-44.6v1.2l18.5,0.7l21.2,0.1l18,0.7l-5.7-2.7h-7.3"
          />
          <path
            fill="#FFFFFF"
            d="M732.1,189.2l5.7,2.7l-18-0.7l-21.2-0.1l-18.5-0.7h-20.7v3.7l39.2,1l23.8,0.2l18.7,0.6v-6.8"
          />
          <path
            fill="#FFFFFF"
            d="M724.8,189.2h41c-1.8-5.2-8.5-8.1-19.7-8.8h-81.4l-140.7,1.1v7.7L724.8,189.2L724.8,189.2z M748.6,176.4
	l-1.1,0.7l1.9,0.3c4.3,0.7,7.7,1.6,10.3,3l-1.1-3.9h-9.9L748.6,176.4L748.6,176.4z M741.1,189.2v13.6H757v-13.6"
          />
          <path
            fill="#FFFFFF"
            d="M751.4,174.8l-2.8,1.6h10l1.1,3.9c1.8,0.9,3.2,2,4.2,3.3c-1.2-5.1-1.4-7.6-0.5-7.2c-0.7-1.1-1.8-1.6-3.6-1.5
	h-8.5L751.4,174.8L751.4,174.8z M757,196.3v6.5h-16v-6.8l-18.6-0.7l-23.8-0.2l-39.2-1.1v-0.8l-12.5-1.1l-20.5,17.5
	c-4.3,3.8-9.7,5.9-16.1,6h-82.2v44.2h5v-0.2c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6v0.2h21.9v-63.5H757L757,196.3z
	 M747.1,205.7c0.3,0.3,0.5,0.8,0.5,1.2c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.6-1.2,0.6s-0.9-0.2-1.2-0.6c-0.3-0.3-0.5-0.7-0.5-1.2
	s0.1-0.9,0.5-1.2c0.3-0.3,0.8-0.4,1.2-0.4C746.3,205.1,746.8,205.3,747.1,205.7z M738.3,208c0,0.3-0.2,0.6-0.6,0.6h-96.1
	c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h96.1c0.3,0,0.6,0.2,0.6,0.6L738.3,208L738.3,208z M738.3,216.2v3
	c0,0.3-0.2,0.6-0.6,0.6H631.4c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h106.4C738.2,215.6,738.3,215.8,738.3,216.2
	L738.3,216.2z M737.8,226.3c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6h-92.5c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6
	L737.8,226.3L737.8,226.3z M745.9,226.2c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.8,0.6-1.2,0.6s-0.9-0.2-1.2-0.6s-0.5-0.7-0.5-1.2c0-0.4,0.1-0.9,0.5-1.2C745,226.3,745.3,226.2,745.9,226.2L745.9,226.2
	z M738.3,237.7v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1
	C738.2,237.1,738.3,237.3,738.3,237.7L738.3,237.7z M638.9,226.6c0.3-0.3,0.8-0.4,1.2-0.4s0.9,0.1,1.2,0.4s0.6,0.8,0.6,1.2
	c0,0.4-0.2,0.9-0.6,1.2c-0.3,0.3-0.7,0.6-1.2,0.6c-0.5,0-0.9-0.2-1.2-0.6s-0.6-0.7-0.6-1.2C638.5,227.4,638.6,227,638.9,226.6
	L638.9,226.6z M738.3,251.7c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1
	c0.3,0,0.6,0.2,0.6,0.6L738.3,251.7L738.3,251.7z M635.8,226.3c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6h-102
	c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6L635.8,226.3L635.8,226.3z M536.4,220.6c0.3-0.3,0.8-0.6,1.2-0.6
	c0.5,0,0.9,0.2,1.2,0.6c0.3,0.3,0.6,0.8,0.6,1.2c0,0.5-0.2,0.9-0.6,1.2c-0.3,0.3-0.7,0.5-1.2,0.6c-0.4,0-0.9-0.2-1.2-0.6
	c-0.3-0.3-0.5-0.7-0.5-1.2C535.9,221.3,536,220.9,536.4,220.6L536.4,220.6z M738.3,259.8v2.7c0,0.3-0.2,0.6-0.6,0.6H533.6
	c-0.3,0-0.6-0.2-0.6-0.6v-2.7h-5v67.5h5v-2.8c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6v2.8h21.9v-67.5L738.3,259.8
	L738.3,259.8z M738.3,273.4c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1
	c0.3,0,0.6,0.2,0.6,0.6L738.3,273.4L738.3,273.4z M738.3,280.8v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3
	c0-0.3,0.2-0.6,0.6-0.6h204.1C738.2,280.3,738.3,280.4,738.3,280.8L738.3,280.8z M737.8,291.2c0.3,0,0.6,0.2,0.6,0.6v3
	c0,0.3-0.2,0.6-0.6,0.6H533.7c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6H737.8L737.8,291.2z M533.2,303.3
	c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3L533.2,303.3
	L533.2,303.3z M533.2,313.6c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6H533.6
	c-0.3,0-0.6-0.2-0.6-0.6v-3H533.2L533.2,313.6z M533.2,327.4h-5v44.1h82.2c6.4,0.2,11.8,2.3,16.1,6l20.5,17.5l12.5-1.1V393l39.2-1.1
	l21.2-0.1l21.2-0.7v-6.8h16v6.5h3.2v-63.5h-21.9v0.2c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-0.2L533.2,327.4
	L533.2,327.4z M533.2,335.5c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6H533.6
	c-0.3,0-0.6-0.2-0.6-0.6v-3H533.2L533.2,335.5z M533.2,357.3c0-0.3,0.2-0.6,0.6-0.6h102c0.3,0,0.6,0.2,0.6,0.6v3
	c0,0.3-0.2,0.6-0.6,0.6h-102c-0.3,0-0.6-0.2-0.6-0.6L533.2,357.3L533.2,357.3z M533.2,346.3c0-0.3,0.2-0.6,0.6-0.6h204.1
	c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3L533.2,346.3L533.2,346.3z M536.4,364
	c0.3-0.3,0.7-0.5,1.2-0.6c0.6,0,0.9,0.2,1.2,0.6c0.3,0.3,0.6,0.7,0.6,1.2c0,0.6-0.2,0.9-0.6,1.2s-0.7,0.5-1.2,0.5
	c-0.6,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2S536,364.4,536.4,364L536.4,364z M630.8,370.9v-3c0-0.3,0.2-0.6,0.6-0.6h106.4
	c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6H631.4C631,371.5,630.8,371.2,630.8,370.9L630.8,370.9z M638.9,357.9
	c0.3-0.3,0.8-0.5,1.2-0.5s0.9,0.2,1.2,0.5c0.3,0.3,0.6,0.8,0.6,1.2s-0.2,0.9-0.6,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.6-0.8-0.6-1.2C638.5,358.7,638.6,358.3,638.9,357.9L638.9,357.9z M644.7,357.3c0-0.3,0.2-0.6,0.6-0.6h92.5
	c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6h-92.5c-0.3,0-0.6-0.2-0.6-0.6L644.7,357.3L644.7,357.3z M641.2,379
	c0-0.3,0.2-0.6,0.6-0.6h96.1c0.3,0,0.6,0.2,0.6,0.6v3c0,0.3-0.2,0.6-0.6,0.6h-96.1c-0.3,0-0.6-0.2-0.6-0.6L641.2,379L641.2,379z
	 M745.9,357.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2s-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.5-1.2,0.5s-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.2s0.1-0.9,0.5-1.2C745,357.7,745.3,357.5,745.9,357.5L745.9,357.5z M744.5,379c0.3-0.3,0.8-0.5,1.2-0.5
	c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2s-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.5-1.2,0.5s-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.2C744.1,379.7,744.2,379.3,744.5,379L744.5,379z"
          />
          <path
            fill="#FFFFFF"
            d="M747.6,206.9c0-0.4-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.4-1.2-0.4s-0.9,0.2-1.2,0.4c-0.3,0.3-0.5,0.8-0.5,1.2
	c0,0.4,0.1,0.9,0.5,1.2c0.3,0.3,0.8,0.6,1.2,0.6s0.9-0.2,1.2-0.6C747.3,207.8,747.6,207.4,747.6,206.9z M738.3,208v-3
	c0-0.3-0.2-0.6-0.6-0.6h-96.1c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h96.1C738.2,208.6,738.3,208.4,738.3,208L738.3,208z
	 M747.6,227.9c0-0.4-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.4-1.2-0.4s-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.1,0.9,0.5,1.2
	c0.3,0.3,0.8,0.6,1.2,0.6s0.9-0.2,1.2-0.6C747.3,228.8,747.6,228.4,747.6,227.9z M738.3,229.8v-3c0-0.3-0.2-0.6-0.6-0.6h-92.5
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h92.5C738.2,230.4,738.3,230.2,738.3,229.8L738.3,229.8z M738.3,237.7
	c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6L738.3,237.7L738.3,237.7
	z M737.8,219.8c0.3,0,0.6-0.2,0.6-0.6v-3c0-0.3-0.2-0.6-0.6-0.6H631.4c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6H737.8
	L737.8,219.8z M738.3,251.7v-3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1
	C738.2,252.2,738.3,252,738.3,251.7L738.3,251.7z M738.3,273.4v-3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3
	c0,0.3,0.2,0.6,0.6,0.6h204.1C738.2,274,738.3,273.9,738.3,273.4L738.3,273.4z M738.3,259.5c0-0.3-0.2-0.6-0.6-0.6H533.6
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6L738.3,259.5L738.3,259.5z M738.3,280.8
	c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6L738.3,280.8L738.3,280.8
	z M738.3,294.7v-3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1
	C738.2,295.3,738.3,295,738.3,294.7L738.3,294.7z M738.3,316.7v-3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3
	c0,0.3,0.2,0.6,0.6,0.6h204.1C738.2,317.2,738.3,317,738.3,316.7L738.3,316.7z M738.3,306.3v-3c0-0.3-0.2-0.6-0.6-0.6H533.6
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1C738.2,306.9,738.3,306.7,738.3,306.3L738.3,306.3z M738.3,338.5v-3
	c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1C738.2,339,738.3,338.9,738.3,338.5L738.3,338.5
	z M738.3,327.6v-3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1
	C738.2,328.2,738.3,328,738.3,327.6L738.3,327.6z M738.3,349.4v-3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3
	c0,0.3,0.2,0.6,0.6,0.6h204.1C738.2,349.9,738.3,349.7,738.3,349.4L738.3,349.4z M738.3,360.3v-3c0-0.3-0.2-0.6-0.6-0.6h-92.5
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h92.5C738.2,360.9,738.3,360.6,738.3,360.3L738.3,360.3z M744.5,357.9
	c-0.3,0.3-0.5,0.8-0.5,1.2s0.1,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2
	s-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5S745,357.7,744.5,357.9z M738.3,370.9v-3c0-0.3-0.2-0.6-0.6-0.6H631.4
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h106.4C738.2,371.5,738.3,371.2,738.3,370.9L738.3,370.9z M745.9,378.5
	c-0.5,0-0.9,0.2-1.2,0.4c-0.3,0.3-0.5,0.8-0.5,1.2s0.1,0.9,0.5,1.2s0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2
	s-0.2-0.9-0.5-1.2C746.8,378.7,746.3,378.4,745.9,378.5L745.9,378.5z M738.3,382.1v-3c0-0.3-0.2-0.6-0.6-0.6h-96.1
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h96.1C738.2,382.6,738.3,382.5,738.3,382.1L738.3,382.1z M635.8,230.4
	c0.3,0,0.6-0.2,0.6-0.6v-3c0-0.3-0.2-0.6-0.6-0.6h-102c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6H635.8L635.8,230.4z
	 M638.5,227.9c0,0.4,0.2,0.9,0.6,1.2c0.3,0.3,0.8,0.6,1.2,0.6s0.9-0.2,1.2-0.6c0.3-0.3,0.6-0.7,0.6-1.2c0-0.4-0.2-0.9-0.6-1.2
	c-0.3-0.3-0.7-0.4-1.2-0.4c-0.5,0-0.9,0.1-1.2,0.4C638.6,227,638.5,227.4,638.5,227.9z M537.6,223.6c0.6,0,0.9-0.2,1.2-0.6
	c0.3-0.3,0.6-0.7,0.6-1.2s-0.2-0.9-0.6-1.2c-0.3-0.3-0.7-0.6-1.2-0.6c-0.6,0-0.9,0.2-1.2,0.6c-0.3,0.3-0.5,0.8-0.5,1.2
	c0,0.5,0.2,0.9,0.5,1.2C536.7,223.4,537,223.5,537.6,223.6L537.6,223.6z M533.2,357.3v3c0,0.3,0.2,0.6,0.6,0.6h102
	c0.3,0,0.6-0.2,0.6-0.6v-3c0-0.3-0.2-0.6-0.6-0.6h-102C533.3,356.7,533.2,356.9,533.2,357.3L533.2,357.3z M537.6,363.6
	c-0.4,0-0.9,0.2-1.2,0.6s-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.6-0.8,0.6-1.2
	s-0.2-0.9-0.6-1.2S538.2,363.6,537.6,363.6L537.6,363.6z M640.2,361c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.6-0.8,0.6-1.2
	s-0.2-0.9-0.6-1.2s-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.6,0.8-0.6,1.2s0.2,0.9,0.6,1.2
	C639.2,360.8,639.7,361,640.2,361L640.2,361z M763.9,177l-0.5-0.8c-0.9-0.2-0.8,2.1,0.5,7.2l0.9,1.9L763.9,177L763.9,177z
	 M760.2,191.7h5.7l-0.2-2.5H757v7.1h3.2"
          />
          <path
            fill="#FFFFFF"
            d="M761.6,191.7h-1.5v203.8h1.5 M765.7,189.2l0.1,2.5c0.5,0.2,0.8,1,0.9,2.3h6.2v-4.6h-7.2L765.7,189.2
	L765.7,189.2z"
          />
          <path
            fill="#FFFFFF"
            d="M766.7,193.8c-0.1-1.1-0.5-1.9-0.9-2.3h-4.2v203.8h4.2c0.5-0.2,0.8-1,0.9-2.3l0.1-1.1V198L766.7,193.8
	L766.7,193.8z M768.5,195.5h2.7v2.8h1.1v3.6h-1.1v2.6h-2.7V202h-1.6v7.6h1.6v-2.8h2.7v2.8h1.1v3.6h-1.1v2.6h-2.7v-2.6h-1.6v8.1h1.6
	v-2.8h2.7v2.8h1.1v3.6h-1.1v2.6h-2.7v-2.6h-1.6v7.9h1.6v-2.8h2.7v2.8h1.1v3.6h-1.1v2.6h-2.7v-2.6h-1.6v4.6l4.7-0.6
	c0.3,0,0.7-0.1,1-0.3c0.2-0.1,0.3-0.2,0.6-0.4l0.7-1.1l-0.8-44.4H767l0.1,4.5h1.6L768.5,195.5L768.5,195.5z"
          />
          <path
            fill="#FFFFFF"
            d="M771.2,195.5h-2.7v8.9h2.7v-6.1 M765.7,187.4v1.8h7.3v-1.8"
          />
          <path
            fill="#FFFFFF"
            d="M772.3,198.4h-1.1v3.6h1.1 M766.8,202h1.7v-3.6h-1.7 M771.2,206.6h-2.7v9h2.7v-6.2"
          />
          <path
            fill="#FFFFFF"
            d="M772.3,209.4h-1.1v3.6h1.1 M768.5,209.4h-1.7v3.6h1.7 M771.2,221.1v-2.8h-2.7v8.9h2.7v-2.5"
          />
          <path
            fill="#FFFFFF"
            d="M772.3,221.1h-1.1v3.6h1.1 M768.5,221.1h-1.7v3.6h1.7 M771.2,232.6v-2.9h-2.7v9h2.7v-2.6"
          />
          <path
            fill="#FFFFFF"
            d="M772.3,232.6h-1.1v3.5h1.1 M768.5,232.6h-1.7v3.5h1.7 M773,254.4c0.7-3.4,1-7.7,0.8-12.6v-3.4l-0.7,1.1
	c-0.1,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.7,0.2-1,0.3l-4.7,0.6v3.6h3.4c1.1,0,1.6,0.6,1.6,1.6v1.7c0,1.1-0.6,1.6-1.6,1.6h-3.4v11.3h6.1
	v-6.2l-0.1,0.7L773,254.4L773,254.4z"
          />
          <path
            fill="#FFFFFF"
            d="M770.3,249.2c1.1,0,1.6-0.6,1.6-1.6v-1.7c0-1-0.6-1.6-1.6-1.6h-3.4v5h3.4V249.2z M766.8,266.1v3.6h1.7v-3.6"
          />
          <path
            fill="#FFFFFF"
            d="M768.5,269.7h-1.7v9.2h1.7v-2.8h2.7v2.8h1.1v3.6h-1.1v2.6h-2.7v-2.6h-1.7v9.4h1.7v-2.8h2.7v2.8h1.1v3.6h-1.1
	v2.6h-2.7v-2.6h-1.7v9.1h1.7V302h2.7v2.6h1.1v3.5h-1.1v2.9h-2.7v-2.9h-1.7v9.2h1.7v-2.5h2.7v2.5h1.1v3.6h-1.1v2.8h-2.7V321h-1.7v5.7
	h6.2v-66.3h-6.2v5.6h1.7v-2.8h2.7v2.8h1.1v3.6h-1.1v2.6h-2.7"
          />
          <path
            fill="#FFFFFF"
            d="M768.5,266.1v6.2h2.7v-9h-2.7 M772.3,266.1h-1.1v3.6h1.1 M768.5,278.9h-1.7v3.6h1.7 M768.5,276.1v9h2.7v-9
	 M772.3,278.9h-1.1v3.6h1.1 M768.5,291.9h-1.7v3.6h1.7 M768.5,289.1v9h2.7v-9 M772.3,291.9h-1.1v3.6h1.1 M771.2,302h-2.7v9h2.7v-6.4
	"
          />
          <path
            fill="#FFFFFF"
            d="M772.3,304.6h-1.1v3.5h1.1 M768.5,304.6h-1.7v3.5h1.7 M771.2,317.4v-2.5h-2.7v8.9h2.7V321"
          />
          <path
            fill="#FFFFFF"
            d="M772.3,317.4h-1.1v3.6h1.1 M768.5,317.4h-1.7v3.6h1.7 M773,332.8v-6.2h-6.1v11.3h3.4c1.1,0,1.6,0.6,1.6,1.7
	v1.7c0,1.1-0.6,1.6-1.6,1.6h-3.4v3.5l4.7,0.6c0.7,0.1,1.1,0.3,1.6,0.8c0.2,0.2,0.5,0.7,0.7,1.1v-3.4
	C773.9,340.5,773.7,336.3,773,332.8l-0.1-0.7L773,332.8L773,332.8z"
          />
          <path
            fill="#FFFFFF"
            d="M766.8,337.9v5h3.4c1.1,0,1.6-0.6,1.6-1.6v-1.7c0-1.1-0.6-1.7-1.6-1.7L766.8,337.9L766.8,337.9z M766.8,351
	v3.6h1.7v-3.6"
          />
          <path
            fill="#FFFFFF"
            d="M768.5,354.6h-1.6v7.9h1.6v-2.6h2.7v2.6h1.1v3.6h-1.1v2.8h-2.7v-2.8h-1.6v8.1h1.6v-2.6h2.7v2.6h1.1v3.6h-1.1
	v2.8h-2.7v-2.8h-1.6v7.6h1.6v-2.6h2.7v2.6h1.1v3.6h-1.1v2.8h-2.7v-2.8h-1.6v3.4l-0.1,1.1h6.2l0.8-44.4c-0.2-0.5-0.3-0.9-0.7-1.1
	c-0.3-0.5-0.9-0.7-1.6-0.8l-4.7-0.6v4.6h1.6v-2.8h2.7v2.6h1.1v3.6h-1.1v2.8h-2.7v-2.8L768.5,354.6L768.5,354.6z"
          />
          <path
            fill="#FFFFFF"
            d="M768.5,351v6.4h2.7v-9h-2.7 M771.2,351v3.6h1.1v-3.6 M766.8,362.4v3.6h1.7v-3.6"
          />
          <path
            fill="#FFFFFF"
            d="M768.5,362.4v6.4h2.7v-9h-2.7 M772.3,362.4h-1.1v3.6h1.1 M772.3,374.2h-1.1v3.6h1.1 M768.5,371.6v9h2.7v-9
	 M768.5,374.2h-1.7v3.6h1.7 M768.5,382.6v9h2.7v-9 M768.5,385.2h-1.7v3.6h1.7 M772.3,385.2h-1.1v3.6h1.1 M766.7,393.2
	c-0.1,1.2-0.5,1.9-0.9,2.3l-0.1,2.4h7.2v-4.6L766.7,393.2L766.7,393.2z"
          />
          <path fill="#FFFFFF" d="M765.7,397.9l0.2-2.5h-5.7v-4.6H757v7.1" />
          <path
            fill="#FFFFFF"
            d="M757,397.9v-13.6h-15.9v13.6 M759.6,406.9c1.8-0.9,3.2-2,4.2-3.3l0.9-1.9l0.9-2v-1.7
	c-1.8,5.2-8.5,8.1-19.7,8.7h-81.4l-140.7-1.1l1.8,7.9l36.2,0.6l2.5,0.6c1.7,0.5,3.5,0.8,5.2,0.9l10.4,0.5l92.5,1.5
	c13.1,0.1,25,1,36,2.7c6.2-2.1,14.1-3.3,23.9-3.5l15.4-1.1h14c6.9-0.2,10.5-3.4,11-9.6v-6.4h-7.2c-0.2,4.8-0.7,8.2-1.2,10.1
	c-0.7,2.4-2.1,3.6-4.4,3.7h-10.4l-3.7-1.4c-2-0.8-1.6-1.5,1.4-2l1.9-0.2C753.6,409.2,757,408.3,759.6,406.9L759.6,406.9z"
          />
          <path
            fill="#FFFFFF"
            d="M763.9,403.6c-1,1.2-2.5,2.4-4.2,3.3l-1.1,3.9h-10l2.8,1.6h8.5c1.7,0.1,2.9-0.3,3.6-1.5
	C762.5,411.1,762.7,408.7,763.9,403.6L763.9,403.6z"
          />
          <path
            fill="#FFFFFF"
            d="M765.7,399.6l-0.9,2l-0.9,8.3l-0.5,0.8c-0.7,1.1-1.8,1.6-3.6,1.5h-8.5l-1.6,1.2h10.4
	c2.3-0.1,3.7-1.4,4.4-3.7C765.1,407.9,765.5,404.5,765.7,399.6L765.7,399.6z"
          />
          <path
            fill="#FFFFFF"
            d="M765.7,397.9H524.1v7.7l140.7,1.1h81.4C757.4,405.9,763.9,403.1,765.7,397.9L765.7,397.9z M764.8,401.7
	l-0.9,1.9c-1.2,5.1-1.4,7.5-0.5,7.3l0.5-0.8L764.8,401.7L764.8,401.7z M765.7,397.9v1.7h7.3v-1.7"
          />
          <path fill="#FFFFFF" d="M724.8,397.9h7.3l5.7-2.8l-18,0.8l5-0.1" />
          <path fill="#FFFFFF" d="M724.8,397.9v-2.1l-5,0.1l-39.7,0.8v1.2" />
          <path
            fill="#FFFFFF"
            d="M737.9,395.1l-5.7,2.8h9v-6.9l-21.3,0.7l-21.2,0.1l-39.2,1.1v3.7h20.7l39.7-0.8 M746.1,412.3l3.7,1.4
	l1.6-1.2l-2.8-1.6l-1.1-0.7C744.6,410.8,744.2,411.5,746.1,412.3L746.1,412.3z"
          />
          <path
            fill="#FFFFFF"
            d="M749.4,409.9l-1.9,0.2l1.1,0.7h10l1.1-3.9C757,408.3,753.6,409.2,749.4,409.9L749.4,409.9z M659.4,193.4
	v-2.9h-37.9h58.6v-1.2H569.7v26.4h40.7c6.4-0.2,11.8-2.1,16.1-6l20.4-17.4l-26.5-1.7c-1.7,0.4-2.8,2.9-3.4,7.3v11.8
	c-0.1,1.9-2.3,2.8-6.4,2.7h-40.3h40.3c4.2,0.1,6.3-0.8,6.4-2.7v-11.6l-0.1,1.2l0.1-1.2v-0.2c0.6-4.4,1.7-6.9,3.4-7.3l26.5,1.7
	L659.4,193.4L659.4,193.4z M707.4,166.8c-6.1-3.4-20.8-4.7-44-4.1l-80.9,2c-11.8,0.8-19,3.5-21.6,8.1l2.5-0.6
	c1.7-0.4,3.5-0.7,5.2-0.9L579,171l92.5-1.6C684.4,169.5,696.5,168.6,707.4,166.8z M522,195.3c-0.2,0.1-0.5,0.3-0.6,0.7l-5.7,14.4
	v0.1c-0.7,2.3-1,5.1-0.9,8.5v149.4c-0.1,3.4,0.1,6.2,0.9,8.5v0.1l5.7,14.4c0.1,0.3,0.3,0.6,0.6,0.7l0.6,0.1h0.2
	c0.2-0.1,0.5-0.3,0.6-0.6v-0.5c0.1-0.2,0.1-0.5,0-0.7v-0.1l-1.9-4.8l-1.9-4.8l-1.9-4.7v0.1c-0.7-2.1-0.9-4.7-0.7-7.8V218.9
	c-0.2-3,0.1-5.6,0.7-7.7v-0.1l1.9-4.7l1.9-4.8l1.9-4.8v-0.1c0.1-0.2,0.1-0.4,0-0.7v-0.1c-0.1-0.2-0.3-0.4-0.6-0.6l-0.2-0.1
	L522,195.3L522,195.3z M569.7,189.2h-21.5v26.4h21.5 M548.2,189.2h-20v26.4h20 M569.7,371.5h-21.5v26.5h21.5 M548.2,397.9v-26.5h-20
	v26.5"
          />
          <path
            fill="#FFFFFF"
            d="M569.7,371.5v26.5h110.5v-1.2h-58.6h37.9v-2.9l-12.5,1.1l-26.5,1.7c-1.7-0.5-2.8-2.9-3.4-7.3v-0.1l-0.1-1.2
	l0.1,1.2v-11.6c-0.1-1.9-2.3-2.8-6.4-2.7h-40.3h40.3c4.2-0.1,6.3,0.8,6.4,2.7v11.7c0.6,4.4,1.7,6.9,3.4,7.3l26.5-1.7l-20.5-17.5
	c-4.3-3.8-9.7-5.9-16.1-6L569.7,371.5L569.7,371.5z M523.6,389.4v1.1c0.1,0.2,0.1,0.5,0,0.7v0.1c-0.1,0.2-0.3,0.5-0.6,0.6h-0.2
	c0,7.6-1.2,13.1-3.6,16.5c1.9,3.3,4.2,5,6.8,5.2l-1.8-7.9v-24l-0.3,7.3L523.6,389.4L523.6,389.4z"
          />
          <path fill="#056DA5" d="M523.5,390.5v-0.2v0.1" />
          <path
            fill="#FFFFFF"
            d="M564.5,414.6l-2.5-0.6c2.6,4.6,9.8,7.3,21.6,8.1l80.9,2c23.2,0.8,37.9-0.6,44-3.9c-10.9-1.8-22.9-2.7-36-2.7
	l-92.5-1.5l-10.4-0.5C568,415.4,566.2,415.1,564.5,414.6L564.5,414.6z M663.4,162.8c-48.1-1.1-75.1-0.3-80.9,2L663.4,162.8z"
          />
          <path
            fill="#FFFFFF"
            d="M583.7,422.2c5.7,2.5,32.8,3.2,80.9,2L583.7,422.2L583.7,422.2z M523.5,196c0.1,0.2,0.1,0.4,0,0.7v0.4
	l0.5,8.5v-24l1.8-7.9c-2.6,0.1-4.8,1.9-6.8,5.2c2.4,3.4,3.6,8.9,3.6,16.5l0.2,0.1C523.2,195.4,523.4,195.6,523.5,196L523.5,196z"
          />
          <path
            fill="#FFFFFF"
            d="M528.2,189.2h-4.2v16.3c1.1,22.4,1.5,50.8,1,85.1v5.7c0.3,34.3,0,62.7-1,85.1v16.5h4.2V189.2z"
          />
          <path fill="#056DA5" d="M523.5,196.8v0.3v-0.5" />
          <path
            fill="#EBEBEB"
            d="M419,185.2l-0.8-0.7c-0.8-0.7-1.6-0.9-2.7-0.7h-4.3c-22.8-0.1-45.5-0.2-68.2-0.1l-26.9,0.1l4.5,6l-4.5-6
	l-0.6-2.7h-1.1c-0.1,0-0.2,0-0.3,0.1l-0.1-0.1h-1.6l-12.1,0.6l-5.3,0.6c6.5,2.3,13.3,4.4,20.5,6.4l12.2,3.3l7.4,1.8
	c6.9,1.6,14.1,3,21.4,4.5h-0.1c13.6,1.9,28.2,3.2,43.7,3.5l24.9,0.8c0-2.1-0.8-5.4-2.3-9.8C421.6,189.1,420.4,186.6,419,185.2
	L419,185.2z M343.7,211.2l-8.6-1.9c-17.9-4.3-35.4-9.1-52.4-14.5c-2.6-0.8-4.5,1.7-5.7,7.3c-4.4,23.9-6.8,51.1-7.3,81.6v19.9
	c0.6,30.4,2.9,57.7,7.3,81.6c1.1,5.8,3,8.1,5.7,7.3c17.1-5.4,34.6-10.3,52.4-14.5l8.6-2L343.7,211.2L343.7,211.2z M295.1,404.9
	l5.3,0.6l12.1,0.6h1.6l0.1-0.1l0.3,0.1h1.1l0.6-2.7l4.5-6l-4.5,6l26.9,0.1c22.7,0.1,45.4,0,68.2-0.1h4.3c1,0.2,1.9,0,2.7-0.7
	l0.8-0.7c1.4-1.5,2.7-4.1,3.9-7.5c1.6-4.4,2.4-7.7,2.3-9.8l-24.9,0.7c-15.6,0.5-30.1,1.6-43.8,3.6h0.1c-7.4,1.4-14.5,2.8-21.4,4.5
	l-7.4,1.7l-12.2,3.3C308.5,400.4,301.6,402.6,295.1,404.9L295.1,404.9z M122.2,196.5l2.5-1c5.1-10.1,6.9-14.8,5.4-13.6l-6.4,11.8
	L122.2,196.5L122.2,196.5z"
          />
          <path
            fill="#EBEBEB"
            d="M124.7,195.6l-2.5,1l1.5-2.7c-2,0.4-3.7,1.9-5.3,4.5l-7.9,19.2l2.6-0.1l1.5-3.3c0.8,0.1,1.5-0.6,2-1.9
	L124.7,195.6L124.7,195.6z"
          />
          <path
            fill="#EBEBEB"
            d="M129.7,180.1c-3.4,3.6-6.3,8.3-8.6,14c-1.5,0.6-2.7,1.8-3.7,3.7l-6.4,14.8c-0.4,1.4-0.9,2.7-1.1,4.4l0.6,0.7
	l7.9-19.3c1.6-2.6,3.4-4.1,5.3-4.5l6.4-11.8c1.5-1.1-0.3,3.5-5.4,13.6l-8.1,16.7c-0.4,1.4-1.1,2-2,1.9l-1.5,3.3l-2.8,6.2l1.7-0.6
	l1.4-0.8l16.2-34.8c2.9-6.2,3.6-9.2,1.8-8.9C130.8,179.1,130.3,179.5,129.7,180.1L129.7,180.1z"
          />
          <path
            fill="#EBEBEB"
            d="M127.7,196.3c0.4-1.1,1-2.1,1.7-3c0.8-1.2,1.8-2.4,3-3.3c3.7-6.9,5-10.6,3.8-11.3l12.6-1.1h0.1l6.2-0.6
	c0.3-0.6,0.6-1,0.6-1.5l-21.6,1.7c-0.9,0.4-1.8,0.9-2.6,1.4c1.7-0.3,1.1,2.6-1.8,8.9l-16.2,34.8c1.5-1.1,2.8-2.9,4.1-5.5
	C117.6,216.8,127.7,196.3,127.7,196.3z"
          />
          <path
            fill="#EBEBEB"
            d="M154.1,174.1l-5.7,0.3c-4.7,0.3-8.6,0.9-11.7,1.9c-0.9,0.2-1.7,0.6-2.5,0.9l21.6-1.6
	C155.9,174.8,155.3,174.3,154.1,174.1z M136.3,178.7c1.1,0.7-0.1,4.5-3.8,11.3c1.2-1,2.7-1.8,4.3-2.4l8.2-2.7
	c-0.2-3.6,1-6.1,3.9-7.3L136.3,178.7L136.3,178.7z"
          />
          <path
            fill="#EBEBEB"
            d="M144.9,184.9c0.6-0.2,1.1-0.3,1.6-0.4l1.7-0.7c1.2-0.7,2.5-1.6,3.7-2.8l3.2-3.9l-6.2,0.6h-0.1
	C145.9,178.9,144.6,181.3,144.9,184.9z M113,217.5l-2.6,0.1l-0.7-0.8c-0.3,1.6-0.6,3.4-0.8,5.3c-0.2,1.1,0.2,1.7,1.2,1.6L113,217.5
	L113,217.5z M113.4,364.8c-0.5-0.3-0.9-0.6-1.4-0.8l-1.7-0.6l2.8,6.2l1.5,3.3c0.8-0.1,1.6,0.6,2,1.9l8.1,16.7
	c5.1,10.1,6.9,14.8,5.4,13.6l-6.4-11.8c-2-0.5-3.7-1.9-5.3-4.5l-7.9-19.3l-0.7,0.8c0.3,1.6,0.7,3,1.1,4.4l6.4,14.8
	c1,1.9,2.3,3,3.7,3.6c2.4,5.6,5.2,10.3,8.6,14l1.8,1.5c1.7,0.3,1.1-2.6-1.8-8.8L113.4,364.8L113.4,364.8z"
          />
          <path
            fill="#EBEBEB"
            d="M110.3,363.5c-1-0.1-1.5,0.3-1.2,1.6c0.2,1.9,0.4,3.7,0.8,5.3l0.7-0.8l2.6,0.1L110.3,363.5L110.3,363.5z
	 M136.7,399.4c-1.6-0.6-3-1.4-4.3-2.4c3.7,6.9,5,10.6,3.8,11.3l12.6,1.1c-2.9-1.2-4.3-3.7-3.9-7.3L136.7,399.4L136.7,399.4z"
          />
          <path
            fill="#EBEBEB"
            d="M146.5,402.7l-1.6-0.5c-0.2,3.6,1,6.1,3.9,7.3h0.1l6.2,0.6l-3.2-3.9c-1.1-1.1-2.4-2.1-3.7-2.8
	C147.6,403.1,147.1,402.8,146.5,402.7L146.5,402.7z"
          />
          <path
            fill="#EBEBEB"
            d="M136.3,408.4c1.1-0.7-0.1-4.5-3.8-11.3c-1.1-0.9-2.1-1.9-3-3.2c-0.7-0.9-1.2-1.9-1.7-3l-10.3-20.5
	c-1.2-2.6-2.6-4.4-4.1-5.5l16.2,34.8c2.9,6.2,3.6,9.1,1.8,8.8c0.8,0.5,1.6,0.9,2.6,1.4l21.6,1.6c-0.1-0.5-0.2-0.9-0.6-1.4l-6.2-0.6
	h-0.1L136.3,408.4L136.3,408.4z"
          />
          <path
            fill="#EBEBEB"
            d="M116.5,374.8c-0.4-1.4-1.1-1.9-2-1.9l-1.5-3.3l-2.6-0.1l7.9,19.3c1.6,2.6,3.4,4.1,5.3,4.5l-1.5-2.7l2.5,1
	L116.5,374.8L116.5,374.8z"
          />
          <path
            fill="#EBEBEB"
            d="M123.6,393.2l6.4,11.8c1.5,1.1-0.3-3.4-5.4-13.6l-2.5-1L123.6,393.2L123.6,393.2z M154.1,412.9
	c1.2-0.1,1.8-0.7,1.7-1.5l-21.6-1.6c0.8,0.3,1.6,0.6,2.5,0.9c3.2,1,7,1.6,11.7,1.9L154.1,412.9z M447.2,181.7h-1.4
	c-3.4,0.1-4.8,1.2-4.3,3.6l1.9,12.7c0.2,2.6,2.5,3.9,6.6,4.3h2.1c14.2,0.3,27.5,0.3,39.9-0.2c4.3-0.4,6.9-2.4,7.8-5.5l1.2-7.4
	c0.2-1.1,0.1-2.1-0.3-2.8c-0.7-1-2.1-1.6-4.3-1.7c-15.9-1.8-32-2.8-48.2-3h-1.1V181.7L447.2,181.7z M450.7,196.2l-1.4-9.7
	c-0.5-1.8,0.7-2.7,3.2-2.8c11.8,0.1,23.6,0.8,35.2,2.1l2.6,0.2c2.8,0.1,3.9,1.2,3.5,3.5l-0.9,5.6c-0.7,2.5-2.6,3.8-5.8,4.3
	c-9.7,0.3-20.1,0.4-31.3,0.1c-2-0.1-3.5-0.7-4.3-1.6C451,197.4,450.8,196.9,450.7,196.2L450.7,196.2z"
          />
          <path
            fill="#EBEBEB"
            d="M449.3,186.5l1.4,9.7c0.1,0.7,0.3,1.2,0.7,1.7c0.8,0.9,2.3,1.5,4.3,1.6c11.3,0.3,21.6,0.3,31.3-0.1
	c3.2-0.3,5.1-1.8,5.7-4.3l0.9-5.6c0.5-2.3-0.7-3.4-3.5-3.5l-2.6-0.3c-11.6-1.4-23.3-2-35.2-2.1C449.9,183.8,448.9,184.7,449.3,186.5
	L449.3,186.5z M514.8,272.2c-2.7,1-3.9,3.9-3.8,8.8v25.4c-0.1,4.8,1.1,7.8,3.8,8.8V272.2L514.8,272.2z M500.9,397.6l-1.2-7.4
	c-0.9-3.2-3.5-5.1-7.8-5.5c-12.3-0.5-25.6-0.6-39.9-0.2l-2.1,0.1c-4.2,0.2-6.3,1.7-6.6,4.3l-1.9,12.7c-0.6,2.4,0.8,3.6,4.3,3.6h2.5
	c16.2-0.2,32.2-1.2,48.1-3.2c2.3-0.1,3.6-0.7,4.3-1.7C501.1,399.8,501.2,398.7,500.9,397.6L500.9,397.6z M493.5,397.4
	c0.5,2.3-0.7,3.4-3.5,3.5l-2.6,0.2c-11.6,1.4-23.3,2-35.2,2.1c-2.6-0.1-3.6-1-3.2-2.8l1.4-9.7c0.1-0.7,0.3-1.2,0.7-1.7
	c0.8-0.9,2.3-1.5,4.3-1.6c11.3-0.3,21.6-0.3,31.3,0.1c3.2,0.3,5.2,1.8,5.9,4.2L493.5,397.4L493.5,397.4z"
          />
          <path
            fill="#EBEBEB"
            d="M490.1,400.8c2.8-0.1,3.9-1.2,3.5-3.5l-0.9-5.6c-0.7-2.5-2.6-3.8-5.9-4.2c-9.7-0.5-20.1-0.5-31.3-0.1
	c-2,0.1-3.5,0.7-4.3,1.6c-0.5,0.5-0.7,1-0.7,1.7l-1.4,9.7c-0.5,1.8,0.6,2.7,3.2,2.8c11.8-0.1,23.6-0.8,35.2-2.1L490.1,400.8
	L490.1,400.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M493.5,397.4l-0.9-5.6c-0.7-2.5-2.6-3.8-5.9-4.2
	c-9.7-0.5-20.1-0.5-31.3-0.1c-2,0.1-3.5,0.7-4.3,1.6c-0.5,0.5-0.7,1-0.7,1.7l-1.4,9.7c-0.5,1.8,0.6,2.7,3.2,2.8
	c11.8-0.1,23.6-0.8,35.2-2.1l2.6-0.2C492.8,400.6,494,399.5,493.5,397.4L493.5,397.4z M763.9,403.6c-1.2,5.1-1.4,7.5-0.5,7.3
	 M759.6,406.9l-1.1,3.9h-9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M751.4,412.4h8.5c1.7,0.1,2.9-0.3,3.6-1.5 M763.5,410.9l0.5-0.8l0.9-8.5
	 M748.6,410.8l2.8,1.6 M748.6,410.8l-1.1-0.7 M749.8,413.6l1.6-1.2 M763.5,176.3c-0.7-1.1-1.8-1.6-3.6-1.5h-8.5 M763.5,176.3
	c-0.9-0.2-0.8,2.1,0.5,7.2 M748.6,176.4h9.9l1.1,3.8 M764.8,185.5l-0.9-8.5l-0.5-0.8 M751.4,174.8l-2.8,1.6 M748.6,176.4l-1.1,0.6
	 M751.4,174.8l-1.6-1.2 M110.3,363.5l2.7,6.2 M148.9,409.6c-2.9-1.2-4.3-3.7-3.9-7.3 M113.4,364.8l16.2,34.8
	c2.9,6.2,3.6,9.1,1.8,8.8 M155.8,411.5l-21.6-1.6 M148.9,409.6l6.2,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M124.7,391.5l-2.5-1l1.5,2.7 M123.6,393.2c-2-0.5-3.7-1.9-5.3-4.5
	l-7.9-19.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M123.6,393.2l6.4,11.8c1.5,1.1-0.3-3.4-5.4-13.6 M110.4,369.5l2.6,0.1
	 M110.4,369.5l-0.7,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M113,369.7l1.5,3.3c0.8-0.1,1.5,0.6,2,1.9l8.1,16.7 M132.4,397
	c3.7,6.9,5,10.6,3.8,11.3l12.6,1.1 M110.3,223.6l2.7-6.1 M123.6,193.8c-2,0.5-3.7,1.9-5.3,4.5l-7.9,19.3 M110.4,217.6l-0.7-0.8
	 M113,217.5l-2.6,0.1 M113,217.5l1.5-3.3c0.8,0.1,1.5-0.6,2-1.9l8.1-16.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M113.4,222.2l16.2-34.8c2.9-6.2,3.6-9.2,1.8-8.9 M148.9,177.6
	c-2.9,1.2-4.3,3.7-3.9,7.3 M134.1,177.3l21.6-1.7 M155.1,177l-6.2,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M148.9,177.6l-12.6,1.1c1.1,0.7-0.1,4.5-3.8,11.3 M124.7,195.6
	c5.1-10.1,6.9-14.8,5.4-13.6l-6.4,11.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M124.7,195.6l-2.5,0.9l1.5-2.7 M449.3,186.5c-0.5-1.8,0.7-2.7,3.2-2.8
	c11.8,0.1,23.6,0.8,35.2,2.1l2.6,0.2c2.8,0.1,3.9,1.2,3.5,3.5l-0.9,5.6c-0.7,2.5-2.6,3.8-5.8,4.3c-9.7,0.3-20.1,0.4-31.3,0.1
	c-2-0.1-3.5-0.7-4.3-1.6c-0.5-0.4-0.7-1-0.7-1.7L449.3,186.5L449.3,186.5z M412.8,170.2h-9.7c-5.4,0-9.4,1.2-11.8,3.7 M400.7,173.8
	l-0.3,0.7 M416.4,173.8c-1-1.1-2.6-1.6-4.6-1.6h-5c-3.4,0-5.4,0.4-6.2,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M400.3,174.5h-9.6l0.7-0.7 M430,173.8h-4.1 M417.1,174.8l9-0.7l-0.2-0.3
	 M425.9,173.8c-2.4-2.7-6.1-3.9-11.2-3.6h-1.8 M416.8,173.8h-0.3 M412.8,170.2c1.7,0.1,3,1.2,3.9,3.6 M417.1,174.8l-0.7-1
	 M416.8,173.8l0.3,1 M413.6,183.7h1.9c1-0.2,1.9,0,2.7,0.7l0.8,0.7c1.4,1.5,2.7,3.9,3.9,7.6c1.6,4.4,2.4,7.7,2.3,9.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M418.5,181c1.4,0.4,2.5,1.4,3.4,2.7c0.7,1,1.2,2.3,1.7,3.7
	c1.8,4.6,3.2,8.9,3.8,13c0,1.1-0.6,1.9-1.5,2.1 M413.6,183.7V181 M316.1,183.7l26.9-0.1c22.7-0.1,45.4,0,68.2,0.1h2.4 M413.6,181
	h4.8 M437.6,202.7c-1.1-6.9-2.5-13.2-4.3-18.8l-0.9-2.7 M432.4,181.1c-0.7-3.3-1.5-5.7-2.5-7.3 M432.4,181.1l-14-0.1 M400.3,174.5
	c4.5,1.1,10.1,1.2,16.8,0.2 M416.4,173.8h-15.8 M325.7,144.6c-3.2,0-5.9,0.9-8.3,2.6c-0.7,0.4-1.2,0.9-1.8,1.5
	c-5.5,5.6-9.8,13.6-12.8,23.9c-0.3,0.7-0.3,1.2-0.2,1.6 M328,145.7l0.2-0.9l-1.9-0.2h-0.6 M317.4,177.2c1-0.3,1.7-1,2.3-2.3l0.2-0.6
	 M314.8,176.9l1.4,0.2h1.2 M313.8,176.8l1,0.1 M314.4,181h1.1 M315.6,181l0.6,2.7 M320.6,189.8l-4.5-6v-0.1 M314,181h0.5 M314.4,181
	H314 M312.5,181.1l1.5-0.1 M312.5,181.1l1.2-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M425.3,202.5l-24.9-0.8c-15.6-0.4-30.1-1.6-43.8-3.5h0.1
	c-7.4-1.4-14.5-2.8-21.4-4.5l-7.4-1.8l-12.3-3.2c-7.2-2-14-4.2-20.5-6.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M289.8,183.1l0.3,0.2l1.9,0.7c6.2,2.3,12.5,4.3,19.3,6.2l15.9,4.3l8.1,1.9
	c6.8,1.6,13.8,3,21,4.4h0.1c13.8,1.9,28.4,3.2,44.1,3.6c12.6,0.4,25.1,0.8,37.8,1 M413.6,181c-26.1-0.2-52.3-0.2-78.4-0.1l-19.6,0.1
	 M319.7,174.3c3.6-10.7,6.4-20.2,8.2-28.6 M325.7,144.6l-10.9,32.3 M317.4,177.2l10.6-31.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M514.8,221.7c-0.6-0.6-1.4-1-2.1-1.5c-1.4-0.7-3.2-1-5.3-1.2l-67.7-3.6
	c-28.2-1.2-50.7-2.7-67.5-4.3l-9-1c-5.5-0.8-11.2-1.7-16.9-2.8l-11-2.3c-18-3.8-37-8.8-56.8-15 M514.8,224.9c-2-0.7-4.1-1-6.1-1.4
	h-0.1l-68.9-3.7c-28.2-1.2-50.7-2.7-67.5-4.4l-9-0.9l-15.7-2.6 M347.6,212l-3.8-0.8 M343.7,211.2l-8.6-1.9
	c-17.9-4.3-35.4-9.1-52.4-14.5c-2.6-0.8-4.5,1.7-5.7,7.3c-4.4,23.9-6.8,51.1-7.3,81.6v19.9c0.6,30.4,2.9,57.7,7.3,81.6
	c1.1,5.8,3,8.1,5.7,7.3c17.1-5.4,34.6-10.3,52.4-14.5l8.6-2 M438,205.4l-0.3-2.7 M437.6,202.7l-11.7-0.2 M425.9,202.5l-0.6,0.1v-0.1
	 M425.9,202.5h-0.6 M391.4,173.8l-71.7,0.6 M303.5,174.9c-0.5-0.2-0.8-0.4-0.9-0.8 M281.4,170.9l-5-0.7 M274.3,192.7
	c1.4-1.2,2.7-2,4.2-2.5 M272,195.3c0.8-1,1.5-1.9,2.3-2.6 M289.8,183.1l-1.4-1.1 M295.1,182.2l-5.3,0.9 M295.1,182.2l5.3-0.6
	 M302.6,174.1l-21.2-3.3 M278.4,190.1c3.6-3.4,7.3-5.7,11.4-7 M288.4,182c-3.4-3-5.6-6.8-6.9-11 M303.5,174.9
	c-0.2,3.2-1.2,5.4-3.2,6.8 M276.5,170.2c-5.3-4.7-15.8-7.6-31.3-8.5h-81.8c-14,0.7-23.2,3.2-27.7,7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M274.3,192.7c-0.7-2.7-3.3-4.1-7.7-4.3c-46-0.4-91.7,1.1-137.3,4.8
	 M276.5,170.2c-3.9-1.1-8.7-1.6-14.2-1.1h-99.5c-4.2-0.2-8.7-0.3-13.4-0.3 M260.7,203.2c-0.9,1.6-1.7,3.4-2.4,5.5 M252.3,233.2
	c1.6-8.9,3.6-17,6-24.5 M272,391.9c-5.5-7.4-10-22.2-13.3-44.4c-2-17.2-3-35.2-3-53.8c0-18.7,1-36.7,3-54c3.3-22.2,7.7-37,13.3-44.4
	 M272,195.3c-5,0.4-8.7,3-11.4,7.9 M252.3,354c-3-17.4-4.5-37.4-4.5-60.4c0-22.9,1.6-43.1,4.5-60.3 M274.3,394.5
	c-0.8-0.8-1.5-1.6-2.3-2.6 M278.4,396.9c-1.5-0.5-2.8-1.2-4.2-2.5 M289.8,403.9l-1.4,1.2 M289.8,403.9l5.3,1 M438,381.6
	c-12.6,0.2-25.1,0.6-37.8,1c-15.7,0.4-30.3,1.6-44.1,3.6H356c-7.2,1.4-14.2,2.8-21,4.3l-8.1,1.9c-4.2,1-8.3,2.1-12.3,3.3l-3.6,1
	c-6.6,1.9-13.1,4.1-19.3,6.2l-1.9,0.7l-0.3,0.2 M300.4,405.4l-5.3-0.5 M278.4,396.9c3.6,3.4,7.3,5.8,11.4,7 M258.3,378.5
	c0.7,2,1.5,3.9,2.4,5.5 M302.6,412.9c-0.2,0.5-0.1,1,0.2,1.6c2.9,10.3,7.2,18.1,12.8,23.9c0.6,0.6,1.1,1,1.8,1.5
	c2.4,1.7,5.2,2.6,8.3,2.6 M302.6,412.9c0.1-0.3,0.5-0.6,0.9-0.7 M276.5,417l5-0.8 M281.4,416.2l21.2-3.3 M135.5,417.8
	c4.5,4.5,13.8,7.1,27.7,7.7H245c15.6-0.9,26-3.7,31.3-8.5 M281.4,416.2c1.2-4.3,3.6-8,6.9-11"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M149.3,418.5c4.7,0.1,9.2,0,13.4-0.3h99.5c5.5,0.5,10.3,0,14.2-1.1
	 M274.3,394.5c-0.7,2.7-3.3,4.1-7.7,4.3c-46,0.5-91.7-1.1-137.3-4.8 M272,391.9c-5-0.5-8.7-3-11.4-7.9 M303.5,412.3
	c-0.2-3.2-1.2-5.4-3.2-6.8 M258.3,378.5c-2.4-7.4-4.4-15.6-6-24.5 M514.8,365.4c-0.6,0.6-1.4,1-2.1,1.5c-1.4,0.7-3.2,1-5.3,1.2
	l-67.7,3.6c-28.2,1.2-50.7,2.7-67.5,4.3l-9,1c-5.5,0.8-11.2,1.7-16.9,2.8l-11,2.3c-18,3.8-37,8.8-56.8,15.1 M347.6,375.1l15.7-2.6
	l9-0.9c16.9-1.7,39.3-3,67.5-4.3l67.7-3.6c2.5-0.2,5-0.7,7.4-1.5 M347.6,375.1l-3.8,0.9 M295.1,404.9c6.5-2.3,13.3-4.5,20.5-6.5
	l12.2-3.3l7.4-1.7c6.9-1.6,14.1-3,21.4-4.5h-0.1c13.6-1.9,28.3-3.2,43.8-3.6c8.3-0.2,16.6-0.6,24.9-0.7 M320.6,397.4l-4.5,6
	 M314.4,406.1H314 M315.6,406.1h-1.1 M314.4,406.1l-0.3-0.1l-0.1,0.1 M316.1,403.4l-0.6,2.7 M314,406.1h-1.5 M413.6,406.1
	c-26.1,0.1-52.3,0.2-78.4,0.1l-19.6-0.1 M313.8,410.3l1-0.2 M314.8,410.1l1.4-0.2l1.2,0.1 M317.4,410c1,0.3,1.7,1,2.3,2.3l0.2,0.6
	 M325.7,442.5h0.6l1.9-0.2l-0.2-0.9 M319.7,412.8l15.4,0.1c18.8,0.2,37.5,0.2,56.1,0.3 M328,441.3c-1.8-8.3-4.5-17.9-8.2-28.5
	 M314.8,410.1l10.9,32.3 M317.4,410l10.6,31.3 M312.5,406.1l1.2,4.3 M413.6,403.4h-2.4c-22.8,0.1-45.5,0.2-68.2,0.1l-26.9-0.1
	 M418.5,406.1c1.4-0.5,2.5-1.4,3.4-2.7c0.7-1,1.2-2.3,1.7-3.7c1.8-4.6,3.2-9,3.8-13c0-1.1-0.6-1.8-1.5-2 M425.9,384.6l11.7-0.2
	 M437.6,384.4l0.3-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M425.3,384.6v-0.1l0.6,0.1 M425.3,384.6c0,2.1-0.8,5.4-2.3,9.8
	c-1.4,3.6-2.7,6.1-3.9,7.5l-0.8,0.7c-0.8,0.7-1.6,0.9-2.7,0.7h-1.9 M413.6,403.4v2.7 M413.6,406.1h4.8 M437.6,384.4
	c-1.1,6.9-2.5,13.2-4.3,18.8l-0.9,2.7 M432.4,405.9l-14,0.1 M425.9,384.6h-0.6 M391.4,413.3c2.5,2.4,6.4,3.6,11.8,3.7h9.7
	 M416.4,413.4c-1,1.1-2.6,1.6-4.6,1.6h-5c-3.4,0-5.4-0.6-6.2-1.7 M400.7,413.3l-0.3-0.7 M400.3,412.6h-9.6l0.7,0.7 M425.9,413.4h4.1
	 M417.1,412.4l9,0.7l-0.2,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M412.8,417h1.8c5.2,0.3,8.9-0.9,11.2-3.6 M417.1,412.4l-0.7,1
	 M416.8,413.4l0.3-1 M412.8,417c1.7-0.1,3-1.2,3.9-3.6 M416.8,413.4h-0.3 M400.7,413.3l15.8,0.1 M400.3,412.6
	c4.5-1.1,10.1-1.2,16.8-0.2 M430,413.4c1-1.7,1.9-4.2,2.5-7.4 M300.4,181.7l12.2-0.6 M347.6,212v163.1 M312.5,406.1l-12.2-0.7
	 M313.8,176.8l-10.3-1.9 M343.7,376V211.2 M303.5,412.3l10.3-1.9 M111,212.4l6.4-14.8c1-1.9,2.3-3,3.7-3.7c2.4-5.6,5.2-10.3,8.6-14
	c0.6-0.6,1.1-1,1.8-1.5c0.8-0.4,1.6-0.9,2.6-1.4c0.8-0.3,1.6-0.6,2.5-0.9 M129.4,193.3c-0.7,0.9-1.2,1.9-1.7,3l-10.3,20.5
	c-1.2,2.6-2.6,4.4-4.1,5.5l-1.4,0.8 M135.5,169.4c-7.7,0.7-13.5,3.2-17.5,7.6l-1.4,1.7 M116.6,178.6c-1,1.4-1.8,2.8-2.5,4.3
	l-8.2,16.9 M136.7,187.8l8.2-2.7c0.6-0.2,1.1-0.3,1.6-0.4l1.7-0.7c1.2-0.7,2.5-1.6,3.7-2.8l3.2-3.9c0.3-0.6,0.6-1,0.6-1.5
	c0.1-0.8-0.4-1.4-1.7-1.5l-5.7,0.3c-4.7,0.3-8.6,0.9-11.7,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M129.4,193.3c0.8-1.2,1.8-2.4,3-3.3c1.2-1,2.7-1.8,4.3-2.4 M149.3,168.7
	c-5.4,1.4-9.1,2.9-11,4.7l-1.7,2.9 M149.3,168.7l-13.9,0.7 M98.2,362.2l-3-6c-1-2.1-1.5-7.1-1.2-15V246c-0.2-7.9,0.2-12.8,1.2-15
	l3-6 M105.8,206.7c-0.9-2.1-0.9-4.5,0.2-7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M105.9,199.8l-5.2,10.7c-0.7,0.2-1.2,0.5-1.9,0.7c-4.5,3.2-6.8,9.9-7,20.2
	v124.4c0.2,10.4,2.6,17,7,20.2c0.6,0.2,1.2,0.5,1.9,0.7l5.2,10.7 M102.2,216.7c-0.2,0.2-0.3,0.6-0.4,0.8 M111,212.4
	c-0.4,1.4-0.9,2.7-1.1,4.4c-0.3,1.6-0.6,3.4-0.8,5.3c-0.2,1.1,0.2,1.7,1.2,1.6l1.7-0.6 M101.8,217.5l4.1-10.8 M102.2,216.7
	c1.4-2,4.4-3.5,8.8-4.3 M98.2,224.8l0.8-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M99,223.5l0.2-0.1l0.4-0.1l0.5,0.3c0.1,0.1,0.1,0.3,0.1,0.4v139
	c0,0.2,0,0.3-0.1,0.5s-0.2,0.2-0.4,0.2l-0.5-0.1l-0.2-0.1 M99,223.5c0.9-1.8,1.8-3.7,2.6-5.6l0.2-0.4 M98.2,224.8l0.6-1.1
	c0.1-0.1,0.1-0.2,0.2-0.2 M96.6,231.6c-0.9,2-1.2,6.8-1,14.3v95.3c-0.2,7.4,0.1,12.3,1,14.3l2,4.1v-132L96.6,231.6L96.6,231.6z
	 M258.3,208.7c-64.1,8.8-107.2,16.7-128.9,23.6c-8.8,2-13.5,5.9-14.3,11.5v99.5c0.8,5.6,5.5,9.5,14.3,11.5
	c21.8,7,64.7,14.8,128.9,23.6 M116.6,178.6l-10.8,28.1 M112,364c-1.4-20.3-2-42.5-1.9-66.5v-5.4c-0.1-25.1,0.6-48.1,2-69.1
	 M98.6,309.5l-1.5-1.8 M97,307.8v-25.4 M98.2,362.2l0.6,1.1l0.2,0.2 M99,363.6l-0.8-1.4 M129.4,393.9c-0.7-0.9-1.2-1.9-1.7-3
	l-10.3-20.5c-1.2-2.6-2.6-4.4-4.1-5.5c-0.5-0.3-0.9-0.6-1.4-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M112,364l-1.7-0.6c-1-0.1-1.5,0.3-1.2,1.6c0.2,1.9,0.4,3.7,0.8,5.3
	c0.3,1.6,0.7,3,1.1,4.4 M136.7,399.4c-1.6-0.6-3-1.4-4.3-2.4c-1.1-0.9-2.1-1.9-3-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M136.5,410.7c3.2,1,7,1.6,11.7,1.9l5.8,0.3c1.2-0.1,1.8-0.7,1.7-1.5
	c-0.1-0.5-0.2-0.9-0.6-1.4l-3.2-3.9c-1.1-1.1-2.4-2.1-3.7-2.8l-1.7-0.7l-1.6-0.5l-8.2-2.8 M102.2,370.4l-0.4-0.9 M99,363.6
	c0.9,1.8,1.8,3.6,2.6,5.6l0.2,0.3 M105.8,380.4c-0.9,2.1-0.9,4.5,0.2,7 M111,374.7c-4.5-0.8-7.4-2.3-8.8-4.3 M101.8,369.5l4.1,10.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M136.5,410.7c-0.9-0.2-1.7-0.6-2.5-0.9c-0.9-0.5-1.8-0.9-2.6-1.4l-1.8-1.5
	c-3.4-3.7-6.3-8.3-8.6-14c-1.5-0.6-2.7-1.8-3.7-3.6l-6.4-14.8 M105.9,387.4l8.1,16.8c0.7,1.6,1.5,3,2.5,4.3 M105.8,380.4l10.8,28.2
	 M149.3,418.5c-5.4-1.4-9.1-2.8-11-4.7l-1.7-3 M135.5,417.8l13.9,0.7 M116.6,408.5l1.4,1.7c3.9,4.4,9.8,7,17.5,7.5 M97,307.8
	l-1.5-1.7 M97,282.4l-1.5,2.8 M102.2,370.4V216.7 M97,282.4l1.6-2.9 M112,223c42.7-3.4,92.2-10,148.7-19.8 M260.7,383.9
	c-56.4-9.8-105.9-16.5-148.7-19.8 M288.4,405.2l-151.7-5.7 M288.4,182l-151.7,5.7 M123.4,338.4L252.3,354 M123.4,248.6l128.9-15.4
	 M765.7,187.4l-0.9-2l-0.9-1.9c-1-1.2-2.5-2.4-4.2-3.3c-2.6-1.4-6.1-2.4-10.3-3l-1.9-0.3c-2.9-0.6-3.4-1.2-1.4-2l3.7-1.4h10.4
	c2.3,0.1,3.7,1.4,4.4,3.7C765.1,179.3,765.5,182.7,765.7,187.4L765.7,187.4z M724.8,189.2v2.1 M732.1,189.2l5.7,2.7l-18-0.7
	l-21.2-0.1l-18.5-0.7 M724.8,189.2h7.3 M741.1,189.2v6.8 M741.1,196l-18.7-0.6l-23.8-0.2l-39.2-1v-0.8 M732.1,189.2h9 M761.6,191.7
	h-1.5v4.6 M760.2,196.3H757 M757,196.3v-7.1 M765.7,189.2l0.2,2.5 M765.9,191.7c0.5,0.2,0.8,1,0.9,2.3 M771.2,198.4v-2.8h-2.7v2.8
	 M765.7,189.2v-1.8 M765.9,191.7h-4.3 M757,189.2h8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M773,187.4V181c-0.1-0.8-0.2-1.6-0.3-2.3c-1.1-4.7-4.7-7.1-10.7-7.3h-14
	l-11.2-1.1h-5.4c-9.8-0.2-17.8-1.5-23.9-3.5 M773,189.2v-1.8 M773,193.8v-4.6 M765.7,187.4h7.3 M773,189.2h-7.3 M766.7,193.8h6.3
	 M757,189.2h-15.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524.1,181.6l140.7-1.1h81.4c11.4,0.7,17.9,3.5,19.7,8.8 M771.2,202h1.1
	v-3.6h-1.1 M771.2,202v-3.6 M766.8,202h1.7 M766.8,198.4v3.6 M768.5,198.4h-1.7 M768.5,198.4v3.6 M768.5,202v2.5h2.7V202
	 M771.2,209.4v-2.8h-2.7v2.8 M771.2,213h1.1v-3.6h-1.1 M768.5,209.4h-1.7 M768.5,213v2.6h2.7V213 M766.8,213h1.7 M768.5,209.4v3.6
	 M771.2,213v-3.6 M766.8,209.4v3.6 M766.8,202v7.4 M771.2,221.1v-2.8h-2.7v2.8 M771.2,224.7h1.1v-3.6h-1.1 M768.5,221.1h-1.7
	 M768.5,224.7v2.5h2.7v-2.5 M766.8,224.7h1.7 M766.8,221.1v3.6 M768.5,221.1v3.6 M771.2,224.7v-3.6 M771.2,232.6v-2.9h-2.7v2.9
	 M771.2,236.1h1.1v-3.5h-1.1 M768.5,232.6h-1.7 M766.8,236.1h1.7 M768.5,236.1v2.6h2.7v-2.6 M766.8,232.6v3.5 M768.5,232.6v3.5
	 M771.2,236.1v-3.5 M766.8,224.7v7.9 M766.8,213v8.1 M747.1,205.7c-0.3-0.3-0.8-0.4-1.2-0.4s-0.9,0.2-1.2,0.4
	c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.1,0.9,0.5,1.2c0.3,0.3,0.8,0.6,1.2,0.6s0.9-0.2,1.2-0.6c0.3-0.3,0.5-0.7,0.5-1.2
	S747.3,206,747.1,205.7z M738.3,208c0,0.3-0.2,0.6-0.6,0.6h-96.1c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h96.1
	c0.3,0,0.6,0.2,0.6,0.6V208L738.3,208z M747.1,226.6c-0.3-0.3-0.8-0.4-1.2-0.4s-0.9,0.1-1.2,0.4s-0.5,0.8-0.5,1.2
	c0,0.4,0.1,0.9,0.5,1.2c0.3,0.3,0.8,0.6,1.2,0.6s0.9-0.2,1.2-0.6s0.5-0.7,0.5-1.2C747.6,227.4,747.3,227,747.1,226.6z M738.3,229.8
	c0,0.3-0.2,0.6-0.6,0.6h-92.5c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h92.5c0.3,0,0.6,0.2,0.6,0.6L738.3,229.8L738.3,229.8
	z M738.3,237.7v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1
	C738.2,237.1,738.3,237.3,738.3,237.7L738.3,237.7z M737.8,219.8H631.4c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h106.4
	c0.3,0,0.6,0.2,0.6,0.6v3C738.3,219.5,738.2,219.8,737.8,219.8L737.8,219.8z M738.3,251.7c0,0.3-0.2,0.6-0.6,0.6H533.6
	c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6L738.3,251.7L738.3,251.7z M738.3,273.4
	c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6L738.3,273.4
	L738.3,273.4z M738.3,259.5v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1
	C738.2,259,738.3,259.2,738.3,259.5L738.3,259.5z M738.3,280.8v3c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3
	c0-0.3,0.2-0.6,0.6-0.6h204.1C738.2,280.3,738.3,280.4,738.3,280.8L738.3,280.8z M773,254.4c0.7-3.4,1-7.7,0.8-12.6v-3.4
	 M772.9,254.9l0.1-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M773.8,238.4l-0.7,1.1c-0.1,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.7,0.2-1,0.3
	l-4.7,0.6 M766.8,240.7v3.6 M766.8,244.3h3.4c1.1,0,1.6,0.6,1.6,1.6v1.7c0,1.1-0.6,1.6-1.6,1.6h-3.4 M766.8,244.3v4.8 M766.8,266.1
	v3.6 M768.5,269.7h-1.7 M768.5,266.1v3.6 M766.8,266.1h1.7 M771.2,269.7h1.1v-3.6h-1.1 M771.2,269.7v-3.6 M766.8,260.4v5.6
	 M771.2,266.1v-2.8h-2.7v2.8 M768.5,269.7v2.6h2.7v-2.6 M768.5,278.9h-1.7 M771.2,278.9v-2.8h-2.7v2.8 M771.2,282.5h1.1v-3.6h-1.1
	 M766.8,269.7v9.2 M773,260.4v-6.1 M773,260.4h-6.2 M766.8,249.2v11.3 M766.8,236.1v4.6 M741.1,196v6.9H757v-6.5 M773.8,238.4
	l-0.8-44.5 M766.7,193.8l0.1,4.5 M768.5,282.5v2.6h2.7v-2.6 M766.8,282.5h1.7 M768.5,291.9h-1.7 M771.2,291.9v-2.8h-2.7v2.8
	 M771.2,295.5h1.1V292h-1.1 M766.8,282.5v9.4 M768.5,295.5v2.6h2.7v-2.6 M766.8,295.5h1.7 M771.2,304.6V302h-2.7v2.6 M766.8,291.9
	v3.6 M771.2,295.5V292 M768.5,291.9v3.6 M771.2,308.1h1.1v-3.5h-1.1 M768.5,304.6h-1.7 M766.8,308.1h1.7 M768.5,308.1v2.9h2.7v-2.9
	 M766.8,304.6v3.5 M768.5,308.1v-3.5 M771.2,304.6v3.5 M771.2,317.4v-2.5h-2.7v2.5 M771.2,321h1.1v-3.6h-1.1 M768.5,317.4h-1.7
	 M766.8,321h1.7 M768.5,321v2.8h2.7V321 M766.8,317.4v3.6 M771.2,317.4v3.6 M768.5,321v-3.6 M766.8,308.1v9.2 M766.8,295.5v9.1
	 M738.3,294.7c0,0.3-0.2,0.6-0.6,0.6H533.6c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h204.1c0.3,0,0.6,0.2,0.6,0.6
	L738.3,294.7L738.3,294.7z M738.3,313.6c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1
	c0.3,0,0.6-0.2,0.6-0.6L738.3,313.6L738.3,313.6z M738.3,303.3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3
	c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6L738.3,303.3L738.3,303.3z M738.3,335.5c0-0.3-0.2-0.6-0.6-0.6H533.6
	c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6L738.3,335.5L738.3,335.5z M738.3,324.6
	c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6L738.3,324.6L738.3,324.6
	z M738.3,346.3c0-0.3-0.2-0.6-0.6-0.6H533.6c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h204.1c0.3,0,0.6-0.2,0.6-0.6
	L738.3,346.3L738.3,346.3z M738.3,360.3c0,0.3-0.2,0.6-0.6,0.6h-92.5c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h92.5
	c0.3,0,0.6,0.2,0.6,0.6L738.3,360.3L738.3,360.3z M744.5,357.9c0.3-0.3,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5
	c0.3,0.3,0.5,0.8,0.5,1.2s-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.5-1.2,0.5s-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2
	S744.2,358.3,744.5,357.9z M773,332.8v-6.1 M772.9,332.1l0.1,0.7 M766.8,337.9v5 M766.8,326.7v11.2 M766.8,342.8h3.4
	c1.1,0,1.6-0.6,1.6-1.6v-1.7c0-1.1-0.6-1.7-1.6-1.7h-3.4 M766.8,351v3.6 M768.5,354.6h-1.7 M768.5,351v3.6 M766.8,351h1.7
	 M771.2,351v3.6 M771.2,351h1.1v3.6h-1.1 M768.5,351v-2.6h2.7v2.6 M766.8,346.3v4.6 M773.8,348.8c-0.2-0.5-0.3-0.9-0.7-1.1
	c-0.3-0.4-0.9-0.7-1.6-0.8l-4.7-0.6 M766.8,362.4v3.6 M768.5,366h-1.7 M768.5,362.4v3.6 M766.8,362.4h1.7 M771.2,362.4h1.1v3.6h-1.1
	 M771.2,366v-3.6 M771.2,354.6v2.8h-2.7v-2.8 M768.5,362.4v-2.6h2.7v2.6 M766.8,354.6v7.9 M773.8,348.8v-3.4c0.1-5-0.1-9.1-0.8-12.6
	 M766.8,342.8v3.5 M773,326.7h-6.2 M766.8,321v5.7 M771.2,366v2.8h-2.7V366 M771.2,374.2h1.1v3.6h-1.1 M768.5,374.2v-2.6h2.7v2.6
	 M766.8,374.2h1.7 M771.2,377.8v2.8h-2.7v-2.8 M768.5,377.8h-1.7 M768.5,385.2v-2.6h2.7v2.6 M766.8,385.2h1.7 M771.2,385.2h1.1v3.6
	h-1.1 M766.8,377.8v7.4 M771.2,374.2v3.6 M768.5,374.2v3.6 M766.8,374.2v3.6 M771.2,388.8v2.8h-2.7v-2.8 M768.5,388.8h-1.7
	 M766.8,388.8v3.4l-0.1,1 M766.7,393.2c-0.1,1.2-0.5,1.9-0.9,2.3 M765.9,395.5l-0.2,2.5 M760.2,390.8v4.6h1.5 M757,390.8h3.2
	 M757,397.9v-7.1 M765.7,399.6l-0.9,2l-0.9,1.9c-1,1.2-2.5,2.4-4.2,3.3c-2.6,1.4-6.1,2.4-10.3,3l-1.9,0.2c-2.9,0.6-3.4,1.2-1.4,2
	l3.7,1.4h10.4c2.3-0.1,3.7-1.4,4.4-3.7C765.1,407.9,765.5,404.5,765.7,399.6L765.7,399.6z M765.9,395.5h-4.3 M765.7,397.9H757
	 M765.7,397.9v1.7 M773,397.9v-4.7 M773,399.6v-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M708.6,420.3c6.2-2.1,14.1-3.3,23.9-3.5l15.4-1.1h14
	c6.9-0.2,10.5-3.4,11-9.6v-6.4 M766.8,385.2v3.6 M768.5,385.2v3.6 M771.2,385.2v3.6 M765.7,399.6h7.3 M766.7,393.2h6.3 M773,397.9
	h-7.3 M757,390.8v-6.5h-15.9v6.8 M738.3,370.9c0,0.3-0.2,0.6-0.6,0.6H631.4c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h106.4
	c0.3,0,0.6,0.2,0.6,0.6L738.3,370.9L738.3,370.9z M745.9,378.5c0.5,0,0.9,0.2,1.2,0.4c0.3,0.3,0.5,0.8,0.5,1.2s-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.8,0.5-1.2,0.5s-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2s0.1-0.9,0.5-1.2C745,378.7,745.3,378.4,745.9,378.5
	L745.9,378.5z M738.3,379c0-0.3-0.2-0.6-0.6-0.6h-96.1c-0.3,0-0.6,0.2-0.6,0.6v3c0,0.3,0.2,0.6,0.6,0.6h96.1c0.3,0,0.6-0.2,0.6-0.6
	V379L738.3,379z M724.8,397.9h7.3 M719.8,395.9l5-0.1v2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M719.8,395.9l18-0.8l-5.7,2.8 M741.1,397.9v-6.9 M732.1,397.9h9
	 M524.1,405.5l140.7,1.1h81.4c11.4-0.7,17.9-3.5,19.7-8.7 M741.1,397.9H757 M773,393.2l0.8-44.4 M766.8,366v8.1 M773,326.7v-66.3
	 M761.6,395.5V191.7 M766.8,278.9v3.6 M760.2,196.3v194.5 M771.2,282.5v-3.6 M768.5,278.9v3.6 M659.4,193.4v-2.9 M646.9,192.3
	l12.5,1.1 M680.2,190.5v-1.2 M659.4,190.5h20.7 M617,198.1v11.6c-0.1,1.9-2.3,2.8-6.4,2.7h-40.3 M616.9,199.2l0.1-1.1 M617,198.1
	v-0.2c0.6-4.4,1.7-6.9,3.4-7.3l26.5,1.7 M635.8,230.4h-102c-0.3,0-0.6-0.2-0.6-0.6v-3c0-0.3,0.2-0.6,0.6-0.6h102
	c0.3,0,0.6,0.2,0.6,0.6v3C636.3,230.2,636.1,230.4,635.8,230.4L635.8,230.4z M638.9,229.1c0.3,0.3,0.8,0.6,1.2,0.6s0.9-0.2,1.2-0.6
	s0.6-0.7,0.6-1.2c0-0.4-0.2-0.9-0.6-1.2c-0.3-0.3-0.7-0.4-1.2-0.4c-0.5,0-0.9,0.1-1.2,0.4s-0.6,0.8-0.6,1.2
	C638.5,228.4,638.6,228.8,638.9,229.1L638.9,229.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M569.7,215.6h40.7c6.4-0.2,11.8-2.1,16.1-6l20.4-17.4 M707.4,166.8
	c-6.1-3.4-20.8-4.7-44-4.1 M707.4,166.8c-10.9,1.8-22.9,2.7-36,2.7l-92.5,1.6l-10.4,0.4c-1.7,0.2-3.5,0.6-5.2,0.9l-2.5,0.6
	 M659.4,190.5h-37.9 M560.9,173.1l-35.2,0.6 M522.7,195.2l-0.6,0.1c-0.2,0.1-0.5,0.3-0.6,0.7l-5.7,14.4v0.1c-0.7,2.3-1,5.1-0.9,8.5
	v2.8 M522.7,195.2l0.2,0.1c0.2,0.1,0.5,0.3,0.6,0.6v0.1c0.1,0.2,0.1,0.4,0,0.7 M519,178.9c2.4,3.4,3.6,8.9,3.6,16.5 M524.1,189.2
	v-7.7 M528.2,189.2h-4.2 M516.4,176.3c-1.6-1.1-3.5-1.7-5.7-1.8l-80.8-0.7 M519,178.9c-0.8-1-1.6-1.9-2.6-2.6 M525.8,173.7
	c-2.6,0.1-4.8,1.9-6.8,5.2 M524.1,181.6l1.7-7.9 M569.7,189.2h-21.5 M582.6,164.9c-11.8,0.8-19,3.5-21.6,8.1 M548.2,189.2h-20
	 M447.2,181.7h-1.4c-3.4,0.1-4.8,1.2-4.3,3.6l1.9,12.7c0.2,2.6,2.5,3.9,6.6,4.3h2.1c14.2,0.3,27.5,0.3,39.9-0.2
	c4.3-0.4,6.9-2.4,7.8-5.5l1.2-7.4c0.2-1.1,0.1-2.1-0.3-2.8c-0.7-1-2.1-1.6-4.3-1.7c-15.9-1.8-32-2.8-48.2-3h-1.1V181.7L447.2,181.7z
	 M538.8,223c0.3-0.3,0.6-0.7,0.6-1.2s-0.2-0.9-0.6-1.2c-0.3-0.3-0.7-0.6-1.2-0.6c-0.6,0-0.9,0.2-1.2,0.6c-0.3,0.3-0.5,0.8-0.5,1.2
	c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.6C538.2,223.5,538.5,223.4,538.8,223L538.8,223z M548.2,215.6h21.5 M523.5,197.1
	l0.6,8.5 M521.6,201.6l-1.9,4.7 M523.5,196.8l-1.9,4.8 M523.5,196.8v0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M519.7,206.4l-1.9,4.7v0.1c-0.7,2.1-0.9,4.7-0.7,7.7v149.3
	c-0.2,3,0.1,5.6,0.7,7.8v-0.1l1.9,4.7 M514.8,221.7v3.3 M514.8,315c-2.7-1-3.9-3.9-3.8-8.8v-25.4c-0.1-4.8,1.1-7.8,3.8-8.8
	 M528.2,215.6h20 M514.8,272.2v-47.2 M516.4,176.3c-0.5,10.7-2.4,19.2-6.1,25c-1.7,3.9-6,6-12.7,6l-59.6-1.9 M523.5,196.7v0.1
	 M528.2,215.6v-26.4 M548.2,189.2v26.4 M524.1,189.2v16.3 M523.5,197.1v-0.5 M569.7,215.6v-26.4 M524.1,205.6
	c1.1,22.4,1.5,50.8,1,85.1v5.7c0.3,34.3,0,62.7-1,85.1 M514.8,362.2v3.2 M533.2,360.3c0,0.3,0.2,0.6,0.6,0.6h102
	c0.3,0,0.6-0.2,0.6-0.6v-3c0-0.3-0.2-0.6-0.6-0.6h-102c-0.3,0-0.6,0.2-0.6,0.6L533.2,360.3L533.2,360.3z M537.6,363.6
	c0.6,0,0.9,0.2,1.2,0.6s0.6,0.7,0.6,1.2s-0.2,0.9-0.6,1.2s-0.7,0.5-1.2,0.5c-0.6,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2
	s0.2-0.9,0.5-1.2C536.7,363.8,537,363.6,537.6,363.6L537.6,363.6z M514.8,362.2V315 M448.1,405.3c16.2-0.2,32.2-1.2,48.1-3.2
	c2.3-0.1,3.6-0.7,4.3-1.7c0.5-0.7,0.6-1.7,0.3-2.8l-1.2-7.4c-0.9-3.2-3.5-5.1-7.8-5.5c-12.3-0.5-25.6-0.6-39.9-0.2l-2.1,0.1
	c-4.2,0.2-6.3,1.7-6.6,4.3l-1.9,12.7c-0.6,2.4,0.8,3.6,4.3,3.6L448.1,405.3L448.1,405.3z M569.7,371.5h-21.5 M548.2,397.9h21.5
	 M548.2,371.5v26.5 M569.7,371.5v26.5 M519.7,380.7l1.9,4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M516.4,410.9c-0.5-10.7-2.4-19.2-6.1-25c-0.7-1.5-1.6-2.7-3-3.6
	c-2.3-1.6-5.5-2.4-9.8-2.4l-59.6,1.8 M522.7,391.9l-0.6-0.1c-0.2-0.1-0.5-0.3-0.6-0.7l-5.7-14.4v-0.1c-0.7-2.3-1-5.1-0.9-8.5v-2.8
	 M524.1,381.5l-0.5,7.4v0.5l-0.1,0.9 M523.5,390.3v0.2 M523.5,390.4v0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M523.5,390.5c0.1,0.2,0.1,0.5,0,0.7v0.1c-0.1,0.2-0.3,0.5-0.6,0.6h-0.2
	 M523.5,390.3v0.1 M524.1,405.5v-7.6 M524.1,397.9h4.2 M522.7,391.9c0,7.6-1.2,13.1-3.6,16.5 M521.6,385.5l1.9,4.8 M528.2,371.5
	v26.5 M524.1,381.5v16.5 M525.8,413.5l36.3,0.6 M708.6,420.3c-10.9-1.8-22.9-2.7-36-2.7l-92.5-1.5l-10.4-0.5
	c-1.7-0.2-3.5-0.6-5.2-0.9l-2.5-0.6 M430,413.4l80.8-0.8c2.3-0.1,4.1-0.7,5.7-1.8 M562,414.1c2.6,4.6,9.8,7.3,21.6,8.1 M519,408.3
	c1.9,3.3,4.2,5,6.8,5.2 M516.4,410.9c1-0.7,1.8-1.5,2.6-2.6 M525.8,413.5l-1.7-8 M548.2,371.5h-20 M528.2,397.9h20 M640.2,361
	c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.6-0.8-0.6-1.2s0.2-0.9,0.6-1.2s0.8-0.5,1.2-0.5s0.9,0.2,1.2,0.5c0.3,0.3,0.6,0.8,0.6,1.2
	s-0.2,0.9-0.6,1.2C641.1,360.8,640.7,361,640.2,361L640.2,361z M570.3,374.7h40.3c4.2-0.1,6.3,0.8,6.4,2.7V389 M617,389l-0.1-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617,389v0.1c0.6,4.4,1.7,6.9,3.4,7.3l26.5-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.9,394.9l-20.5-17.5c-4.3-3.8-9.7-5.7-16.1-6h-40.7 M680.2,397.9v-1.2
	 M741.1,391.1l-21.3,0.7l-21.2,0.1l-39.2,1.1v0.8 M680.2,396.7l39.7-0.8 M659.4,393.8v2.9 M659.4,393.8l-12.5,1.1 M680.2,396.7
	h-20.7 M664.5,424.3c23.2,0.8,37.9-0.6,44-3.9 M659.4,396.7h-37.9 M663.4,162.8c-48.1-1.1-75.1-0.3-80.9,2 M583.7,422.2
	c5.7,2.5,32.8,3.2,80.9,2 M680.2,189.2H569.7 M528.2,371.5V215.6 M569.7,397.9h110.5 M663.4,162.8l-80.8,2 M514.8,272.2V315
	 M664.5,424.3l-80.8-2.1 M680.2,189.2h44.6 M680.2,397.9h44.6 M432.4,181.1l14.8,0.6 M432.4,405.9l15.7-0.7"
          />
          <path
            fill="#929497"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M760.2,395.5V191.6h5.7c0,0,1-0.1,1,6.6v192.5
	c0,0,0.1,3.9-1,4.6h-5.7L760.2,395.5L760.2,395.5z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M274.3,394.5l-2.3-2.6
		c0,0-9.2,0.5-13.8-13.4c0,0-5.6-16.3-8.2-40.5c0,0-2.3-20.4-2.3-44.5c0,0-0.2-34.5,4.5-60.3c0,0,3.9-22.5,8.2-30
		c0,0,3.6-7.4,11.4-7.9c0,0,1.5-1.9,2.3-2.6c0,0-0.7-4.3-8-4.3c0,0-44.5-0.2-71.2,0.9c0,0-25.8,0.8-65.6,3.9c0,0-1,1.5-1.5,2.6
		l-10.6,21.2c0,0-2.3,5-5.3,6.1c0,0-2.1,30.3-2,70.6c0,0-0.5,31.7,2,70.6c0,0,2.4,0.5,5.1,5.5l10.6,21.2c0,0,1,2.1,1.7,3
		c0,0,47.6,3.5,72.1,4.2c0,0,34.7,1.4,66.3,0.7C267.8,398.6,273.1,398.4,274.3,394.5z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M282.8,392.4
		c0,0-3.9,1.9-5.7-7.3c0,0-7.8-40.3-7.3-91.5c0,0-1-46.9,7.3-91.5c0,0,1.4-8.2,5.3-7.6c0,0,30.9,9.9,61.4,16.7v82.4V376
		C343.7,376,305.4,384.9,282.8,392.4z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M347.6,375.1v-81.5V212l15.7,2.6
		c0,0,17.5,2.4,57.3,4.4l88.2,4.7c0,0,3.9,0.7,6.1,1.4v47.2c0,0-3.8,1.1-3.8,7.6v28.5c0,0,0,5.5,3.8,6.6v47.2c0,0-3.9,1.2-7.4,1.5
		l-86.9,4.5c0,0-37.8,2.1-57.3,4.4L347.6,375.1z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M523.5,390.3l-5.7-14.3
		c0,0-0.8-2.3-0.7-6.6v-4.2v-71.6v-74.8c0,0-0.4-4.7,0.8-7.7l5.7-14.4c0,0,1.1,19.3,1.5,37c0,0,0.6,31.7,0.1,60
		c0,0,0.7,46.7-0.6,76.1C524.5,369.7,524.1,381.8,523.5,390.3z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="528.2,397.9 
		528.2,293.6 528.2,189.2 757,189.2 757,196.3 760.2,196.3 760.2,390.8 757,390.8 757,397.9 618.7,397.9 	"
              id="Truck Bed"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Truck Bed')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('truckBedColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup2DrTop);
