import React, { Component } from 'react';
import BackButton from '../../ui/buttons/BackButton';
import DropDownMenu from '../../ui/dropDownMenu';
import Icon from '../../ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateItem,
  loadDeletePostItem,
  resetDeletePostItem,
} from '../../../../../actions';
import VariantModal from '../../modals/VariantModal';
import ConfirmationModal from '../../modals/ConfirmationModal';
import SaleInventoryModal from '../../modals/SaleInventoryModal';
import { Item } from '../../../utils/Item';
import { toast } from 'react-toastify';
import ToastContent from '../../ui/toastContent';

class VehicleDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openVariantModal: false,
      dropdown: true,
      openConfirmationModal: false,
      openSalesInventoryModal: false,
    };
  }
  handleMenuClick(item) {
    if (item === 'Edit') {
      this.setState({ openVariantModal: true, dropdown: false });
    }
    if (item === 'Delete') {
      this.setState({ openConfirmationModal: true, dropdown: false });
    }
    if (item === 'Turn / Rerun') {
      this.props.updateItem({ turnRerun: true });
      this.setState({ openSalesInventoryModal: true, dropdown: false });
    }
  }
  closeVariantModal = () => {
    this.setState({ openVariantModal: false, dropdown: true });
  };
  closeConfirmationModal = () => {
    this.setState({ openConfirmationModal: false, dropdown: true });
  };
  closeSalesInventoryModal = () => {
    this.setState({ openSalesInventoryModal: false, dropdown: true });
  };
  handleDelete = () => {
    const vehicle = Item(this.props.item);
    vehicle.action = 'delete-listing';

    this.props
      .loadDeletePostItem(vehicle)
      .then(({ response, error }) => {
        if (error) {
          toast(
            <ToastContent
              status="error"
              text="Oops. Something went wrong please try again"
            />,
            {
              className: 'review-toast',
            }
          );
          return;
        }
        if (response.amStatus === 'Success') {
          this.props.resetDeletePostItem();
          toast(
            <ToastContent status="success" text="Item successfully deleted." />,
            {
              className: 'review-toast',
            }
          );
          setTimeout(() => {
            const path = `/inventory/${
              this.props.item.entryType === 'Inventory' ? 'unlisted' : 'listed'
            }`;
            this.props.navigate(path, { replace: true });
          }, 3000);
        } else if (response.amStatus === 'Error') {
          this.props.resetDeletePostItem();
          toast(<ToastContent status="error" text={response.amMessage} />, {
            className: 'review-toast',
          });
        }
      })
      .catch(error => console.error(error));
  };
  render() {
    const menuOptions = ['Turn / Rerun', 'Edit', 'Delete'];
    const {
      openVariantModal,
      dropdown,
      openConfirmationModal,
      openSalesInventoryModal,
    } = this.state;
    const { item, vin } = this.props;
    return (
      <div className="vehicle-details-container flex justify-center">
        <div className="w-3/4">
          <BackButton style={{ marginTop: 35, width: 'fit-content' }} />
          <div className="vehicle-details-card">
            <div className="flex items-center justify-between">
              <div className="vd-title">{item.name}</div>
              <div className={`${dropdown !== true && 'hidden'}`}>
                <DropDownMenu
                  toggleLabel={
                    <Icon
                      name="HorizontalEllipsis"
                      size={24}
                      color={'#98989E'}
                    />
                  }
                  menus={menuOptions}
                  clickHandler={item => this.handleMenuClick(item)}
                />
              </div>
            </div>
            <div className="vd-body">
              <div className="flex-col">
                <div>Info</div>
                <div className="vd-column">
                  <div>
                    <span>Dealer name :</span>
                    <span>{item.dealerName}</span>
                  </div>
                  <div>
                    <span>Dealer number :</span>
                    <span>{item.accountNumber}</span>
                  </div>
                  <div>
                    <span>Sale date :</span>
                    <span>{item.saleDate}</span>
                  </div>

                  <div>
                    <span>Lot number :</span>
                    <span>{item.lotNumber}</span>
                  </div>
                  <div>
                    <span>Sent out :</span>
                    <span>{item.sent_out}</span>
                  </div>
                  <div>
                    <span>Stock number :</span>
                    <span>{item.stockNumber}</span>
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <div>Vehicle detail</div>
                <div className="vd-column">
                  <div>
                    <span>Mileage :</span>
                    <span>{item.odometer}</span>
                  </div>
                  <div>
                    <span>Body :</span>
                    <span>{item.bodyStyle}</span>
                  </div>
                  <div>
                    <span>Transmission :</span>
                    <span>{item.transmission}</span>
                  </div>
                  <div>
                    <span>Engine :</span>
                    <span>{item.engine}</span>
                  </div>
                  <div>
                    <span>Driveline :</span>
                    <span>{item.driveline}</span>
                  </div>
                  <div>
                    <span>Vin :</span>
                    <span>{item.vin}</span>
                  </div>

                  <div>
                    <span>Status :</span>
                    <span>{item.status}</span>
                  </div>
                  <div>
                    <span>Gatepassed :</span>
                    <span>{item.gatepassed}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            title="Delete"
            body="Are you sure you want to delete?"
            action={this.handleDelete}
            open={openConfirmationModal}
            close={this.closeConfirmationModal}
          />
          <SaleInventoryModal
            open={openSalesInventoryModal}
            close={this.closeSalesInventoryModal}
          />
        </div>
        <VariantModal open={openVariantModal} close={this.closeVariantModal} />
      </div>
    );
  }
}

VehicleDetailsView.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { vin, item } = state.core;
  return { vin, item };
};

export default connect(mapStateToProps, {
  updateItem,
  loadDeletePostItem,
  resetDeletePostItem,
})(VehicleDetailsView);
