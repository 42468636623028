import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { getConfig } from '../../utils/helpers';
import $ from 'jquery';
window.$ = window.jQuery = $;
require('bootstrap');

/* 03/19/2024
TODO (housekeeping) - Marking as candidate for cleanup. 
  - this component doesn't look to be utilized and has no future value.
*/
export default class EnableTrackingModal extends Component {
  state = {
    orderId: '',
    loading: false,
  };

  onClose = e => {
    this.setState({
      orderId: '',
      loading: false,
    });
    $('#enableTrackingModal.in').modal('hide'); // this
    $('#enableTrackingModal').modal('hide');
  };

  handleChange = e => {
    this.setState({ orderId: e.target.value });
  };

  handleSave = () => {
    const { orderId } = this.state;
    const { row } = this.props;

    // makes a PUT to AWG to add the orderId to to the item.
    // if successful call the Transportation list api again to get the updated data
    this.setState({ loading: true }, () => {
      const mpId = getConfig('marketplaceId');
      const transactionId = row.transactionId;

      this.props
        .loadTransportationSaveOrderId(mpId, transactionId, orderId)
        .then(({ response }) => {
          if (response.wsStatus === 'Success') {
            toastr.success('Success', response.wsMessage);
            this.props.loadData();
          } else {
            toastr.error('Error', response.wsMessage);
          }
        })
        .catch(error => console.error(error));

      this.onClose();
    });
  };

  render() {
    const { orderId, loading } = this.state;

    return (
      <div className="modal fade" id="enableTrackingModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClose}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title">
                <span className="fa fa-car" /> Enable Tracking
              </h3>
            </div>
            <div className="modal-body recall-data">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Order Id"
                value={orderId}
                onChange={this.handleChange}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.handleSave}
                disabled={orderId === '' || loading}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EnableTrackingModal.defaultProps = {
  loadData: () => null,
  loadTransportationSaveOrderId: () => null,
};

EnableTrackingModal.propTypes = {
  loadData: PropTypes.func,
  loadTransportationSaveOrderId: PropTypes.func,
  row: PropTypes.object.isRequired,
};
