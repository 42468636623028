import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback5DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 110 800 340">
          <path
            fill="#FFFFFF"
            d="M505,157.2c1.8,0.8,3,1.9,4,3.6l6.2,10.5l2.9,0.3c0.7,0.1,1.4,0.3,1.9,0.4l-6.1-10.5c-1-1.7-2.1-2.8-3.6-3.2
	c-1.4-0.4-2.9-0.7-4.4-1L505,157.2L505,157.2z"
          />
          <path
            fill="#FFFFFF"
            d="M509,160.8c-1-1.7-2.3-2.9-4-3.6c-20.5-2.6-40.9-4.3-61.5-5c-6.5-0.3-13-0.4-19.3-0.4h-5
	c-26.9,0.1-53.7,1.9-80.7,5.4c-1.8,0.8-3,1.9-4,3.6l-6.2,10.5c31-2.8,61.9-4.1,92.6-4.3h1.8c30.7,0,61.6,1.4,92.6,4.3L509,160.8
	L509,160.8z M510.5,158.3c1.5,0.6,2.6,1.5,3.6,3.2l6.1,10.5l0.3,0.1l0.5,0.3l0.1,0.1l0.7,0.4c1,0.7,1.7,1.7,2.1,2.9l0.3,0.7
	c5.7,14.3,10.6,28.9,14.9,43.5c1,2.8,1.4,5.2,1.5,7.2c0,1.2-0.3,2.5-0.8,3.9c3.2,3,6.2,7.6,9.1,13.9c-1.5-6.2-2.9-12.1-4.4-17.8
	l-2.5-9l-3.6-12.3l-3.7-11.7c-2.1-6.2-4.3-12.3-6.5-17.8l-1.8-4.5l-1.1-2.2C522.9,164.3,518.1,160.4,510.5,158.3L510.5,158.3z"
          />
          <path
            fill="#FFFFFF"
            d="M515.2,171.4l-0.5,1.8l5,0.4l0.6-1.5c-0.5-0.3-1.2-0.4-1.9-0.4L515.2,171.4L515.2,171.4z M514.6,173.2
	l0.5-1.8c-31-2.8-61.9-4.1-92.6-4.3h-1.8c-30.7,0-61.6,1.4-92.6,4.3l0.5,1.8c30.9-2.8,61.6-4.1,92-4.1h1.8
	C453,169.1,483.8,170.5,514.6,173.2L514.6,173.2z"
          />
          <path
            fill="#FFFFFF"
            d="M520.2,172.1l-0.5,1.5c5.9,16.3,10.7,31.4,14.3,45.5l0.3,1.2c1,3.2,1.7,6.9,2.1,11l0.4-0.4l0.7-1.1
	c-0.4-3.2-1-6.3-1.9-9.5l-0.4-1.2c-3.7-14.3-8.5-29.9-14.7-46.7L520.2,172.1L520.2,172.1z"
          />
          <path
            fill="#FFFFFF"
            d="M521.9,173.1l-0.7-0.4c-0.1,0-0.1-0.1-0.1-0.1l-0.5-0.3c6.2,16.8,11,32.4,14.7,46.7l0.4,1.2
	c1,3.2,1.5,6.5,1.9,9.5l0.4-1.2c0.5-2.3,0.3-5.5-1-9.5c-3.7-14.3-8-28.5-13-42.4l-0.3-0.7C523.5,174.6,522.8,173.8,521.9,173.1
	L521.9,173.1z M520.4,172.3l0.7,0.3"
          />
          <path
            fill="#656565"
            d="M519.6,173.6l-5-0.4c-30.9-2.8-61.6-4.1-92-4.1h-1.8c-30.5,0-61.2,1.4-92,4.1l-5,0.4
	c-5.9,16.3-10.7,31.4-14.3,45.5l-0.4,1.2c-1,3.2-1.7,6.9-2.1,11c0.4,0.4,0.8,0.7,1.5,1c2.1,1,4.3,1.8,6.8,2.3c1.9,0.4,4,0.8,6.2,1.2
	c5.8,0.7,11.3,1,16.7,0.8l22.3-0.3l-22.3-0.6c-4.4-0.1-9-0.4-13.8-1.1c-5.6-0.6-9.1-2.8-10.3-6.6c-0.8-3-0.7-6.9,0.6-11.7
	c2.5-9.2,5.4-18.9,8.8-29.1c2.2-6.8,8.1-10.5,17.6-11.4c26.7-2.1,53.3-3.2,79.8-3.2h1.8c26.5,0,53,1.1,79.8,3.2
	c9.5,0.8,15.4,4.7,17.6,11.4c3.4,10.2,6.3,19.8,8.8,29.1c1.2,4.8,1.4,8.7,0.5,11.7c-1.2,3.9-4.7,6.1-10.3,6.6
	c-4.7,0.6-9.2,1-13.6,1.1l-22.3,0.6l22.3,0.3c5.4,0.1,10.9-0.3,16.5-0.8l6.3-1.2c2.5-0.6,4.8-1.4,6.8-2.3c0.5-0.3,1.1-0.6,1.5-1
	c-0.4-4.1-1.2-7.9-2.1-11l-0.3-1.2C530.4,205,525.7,189.9,519.6,173.6L519.6,173.6z"
          />
          <path
            fill="#FFFFFF"
            d="M421.8,125.4c-0.4,0-0.7,0.1-1,0.6h-0.1l-0.1,0.6c-0.1,0.3-0.1,0.6-0.1,1l0.1,1.4v13.4
	c0,2.1-0.1,4.1-0.5,6.1l-0.1,0.4c-0.1,1.1-0.4,2.1-0.8,3.2h5l-0.8-3.2c-0.4-2.2-0.6-4.4-0.6-6.8v-13.1l0.1-1.4l-0.1-1l-0.3-0.6
	C422.5,125.7,422.2,125.4,421.8,125.4L421.8,125.4z"
          />
          <path
            fill="#FFFFFF"
            d="M422.9,141.9c0,2.3,0.1,4.5,0.5,6.8c-0.4-3-0.5-5.4-0.5-7V141.9L422.9,141.9z M420.1,148.4
	c0.3-1.9,0.5-4,0.5-6.1L420.1,148.4z"
          />
          <path
            fill="#EBEBEB"
            d="M422.6,172.8h-1.8c-26.5,0-53,1.1-79.8,3.2c-9.5,0.8-15.4,4.7-17.6,11.4c-3.4,10.2-6.3,19.8-8.8,29.1
	c-1.2,4.8-1.4,8.7-0.5,11.7c1.2,3.9,4.7,6.1,10.3,6.6c4.7,0.6,9.2,1,13.8,1.1l22.3,0.6l28.4-0.1h65.6l28.4,0.1l22.3-0.6
	c4.4-0.1,9-0.4,13.6-1.1c5.7-0.6,9.1-2.8,10.3-6.6c0.8-3,0.7-6.9-0.5-11.7c-2.5-9.2-5.4-18.9-8.8-29.1c-2.2-6.8-8.1-10.5-17.6-11.4
	C475.6,173.9,449.1,172.9,422.6,172.8L422.6,172.8z"
          />
          <path fill="#FFFFFF" d="M389,236.3v12.1l9,20l9.4,10.7" />
          <path
            fill="#FFFFFF"
            d="M389,248.4v-12.1l-28.4,0.1l-22.3,0.3c-5.4,0.1-10.9-0.3-16.7-0.8c-2.2-0.3-4.3-0.7-6.2-1.2
	c-2.5-0.6-4.8-1.4-6.8-2.3c-0.5-0.3-1.1-0.6-1.5-1l-0.4-0.4l-3.2,0.3c0.4,1.1,1.1,2.2,1.9,3.2c11.7,16.1,28.1,31.8,49.2,47.4
	c-5.9-15.6-14.1-30-24.4-43.1c11.6,12.7,20.8,27,27.8,43.3c14.7-1.4,29.4-2.2,44.1-2.6c1.7-0.1,3.4-0.1,5.1-0.1l-9.5-10.7L389,248.4
	L389,248.4z M379.6,241.1v0.8h0.7v1.8h-3.2V242h0.8v-0.8H379.6L379.6,241.1z"
          />
          <path fill="#FFFFFF" d="M379.6,242v-0.8h-1.8v1.8h1.8" />
          <path
            fill="#FFFFFF"
            d="M377.8,242.9v-1h-0.7v1.8h3.2V242h-0.7v1 M454.6,248.4l-9,20.1l-9.5,10.7c16.4,0.3,32.9,1.2,49.2,2.8
	c7-16.1,16.4-30.6,27.8-43.3c-10.3,13.1-18.5,27.6-24.4,43.1c21.1-15.6,37.5-31.4,49.2-47.4c0.8-1.1,1.5-2.2,1.9-3.2l-3.2-0.3
	l-0.4,0.4c-0.4,0.4-0.8,0.7-1.5,1c-2.1,1-4.3,1.8-6.8,2.3l-6.3,1.2c-5.8,0.7-11.3,1-16.5,0.8l-22.3-0.3l-28.2-0.3L454.6,248.4
	L454.6,248.4z M463.9,241.1h1.7v0.8h0.8v1.8h-3.2V242h0.7L463.9,241.1L463.9,241.1z"
          />
          <path fill="#FFFFFF" d="M465.6,241.1h-1.7v1.8h1.7v-1" />
          <path
            fill="#FFFFFF"
            d="M463.9,242.9v-1h-0.7v1.8h3.2V242h-0.8v1 M445.6,268.4l9-20v-12.1l-18.5,42.9 M454.6,236.3H389l18.3,42.9
	h28.8 M524.3,176.7L524,176c-0.4-1.2-1.2-2.2-2.1-2.9c0.8,0.7,1.5,1.7,1.9,2.8l0.3,0.7c5,13.9,9.2,28.1,13,42.4c1.1,4,1.5,7.2,1,9.5
	l-0.4,1.2l-0.7,1.1l3.2,0.3c0.6-1.2,0.8-2.5,0.8-3.9c-0.1-1.9-0.5-4.4-1.5-7.2C534.9,205.6,529.9,191,524.3,176.7L524.3,176.7z"
          />
          <path
            fill="#EBEBEB"
            d="M521.3,266.6l-2.1,1.2c-7.2,4.3-16,10.7-26.5,19.3c-0.8,0.7-1,1.2-0.5,2.1c0.1,0.4,0.5,0.7,1.1,1.1
	c2.1,1.7,4.4,3,7,4.3c1.5,0.6,3.2,0.7,4.8,0.6c1.9-0.1,4.1-0.5,6.8-1.2c7-1.8,13.5-3.9,19.6-6.1l3.7-1.5l5.1-2.3
	c1.4-0.8,2.6-1.7,3.7-2.8c0-2.1-0.1-4.1-0.5-6.3l-0.1-0.8v-0.7l-0.3-1.7l5.1-2.8c0-1.9-0.1-3.9-0.4-5.8l-0.7-3.9
	c-0.8,1.1-1.9,1.9-3.3,2.6c-1.9,1-4.3,1.4-7,1.7l-6.8,0.5c-1.7,0.3-3.3,0.7-4.8,1.2C523.6,265.5,522.5,265.9,521.3,266.6
	L521.3,266.6z M518.2,269.7c0.4-0.3,0.7-0.4,1.1-0.7l1.5-0.8c0.4-0.3,0.8-0.4,1.2-0.5c1.8-1,3.6-1.7,5.4-2.1c1.5-0.4,3-0.5,4.5-0.4
	l1.9,0.3c1.2,0.3,2.3,0.7,3.6,1.2l0.6,0.3l6.5-2.5l1,3.9l-4.3,2.2c0.3,1.1,0.4,2.2,0.5,3.3c0.1,0.7,0.1,1.5,0.1,2.2l-0.1,1.4
	c0,1-0.5,1.8-1.5,2.5L540,280c-4.1,1.9-8.3,3.7-12.5,5.4l-1.8,0.7l-1.2,0.4c-5,1.8-10.2,3.2-15.6,4.4L508,291l-4-10.9l-2.1,1.7
	c1-0.7,1.8-0.5,2.3,0.5l2.3,6.2c0.3,1.2,0.3,2.2,0,3.2c-0.4,1.1-1.4,1.8-2.9,1.9c-1.4,0.1-2.8-0.1-4.1-0.8c-2.1-1-3.9-2.2-5.5-3.4
	l-0.3-0.3l-0.1-0.3c0-0.3,0.1-0.4,0.4-0.7l7.6-6.5l2.1-1.7C509,275.9,513.8,272.4,518.2,269.7L518.2,269.7z M542.3,279.3l1,1.1
	c-0.8,0.8-1.8,1.5-2.8,2.2l-5.4,2.6l-6.3,2.5c-5.2,1.8-10.9,3.6-16.9,5.1l-4.8,1l0.1-2.1c6.2-1.4,12.3-3,17.9-5.1l3.4-1.2
	c3.6-1.4,7-2.9,10.3-4.4L542.3,279.3L542.3,279.3z"
          />
          <path
            fill="#EBEBEB"
            d="M518.9,270.5l0.3-1.5c-0.3,0.3-0.7,0.4-1.1,0.7c-4.3,2.8-9.1,6.2-14.5,10.3l4,10.9l0.8-0.1
	c5.4-1.2,10.6-2.8,15.6-4.4l1.2-0.4l-1.2-0.6c-1-0.5-1.8-1.2-2.6-2.1c-2.3-2.3-3.4-5.2-3.4-8.5C518.2,273.3,518.4,271.9,518.9,270.5
	L518.9,270.5z M517.3,274.6c0,3.6,1.2,6.6,3.9,9.1c0.7,0.7,1.4,1.2,2.1,1.8c-4.8,1.7-9.8,3-14.7,4.1l-3.4-9.2
	c4.7-3.6,9-6.8,12.8-9.2C517.4,272.4,517.3,273.5,517.3,274.6L517.3,274.6z"
          />
          <path
            fill="#EBEBEB"
            d="M521,283.9c-2.5-2.5-3.9-5.5-3.9-9.1c0-1.2,0.1-2.3,0.4-3.4c-3.9,2.5-8.1,5.5-12.8,9.2l3.4,9.2
	c5.1-1.2,10.1-2.6,14.7-4.1C522.4,285.1,521.7,284.5,521,283.9L521,283.9z M514,281.9v0.3l-0.3,0.4c-0.1,0.1-0.4,0.3-0.7,0.3
	s-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3s0.4,0.1,0.7,0.3l0.3,0.4V281.9
	L514,281.9z"
          />
          <path
            fill="#EBEBEB"
            d="M514,282.2v-0.6l-0.3-0.4c-0.1-0.1-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7
	c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3s0.4-0.1,0.7-0.3L514,282.2L514,282.2z M519.3,269l-0.4,1.5
	c-0.4,1.2-0.7,2.6-0.7,4.1c0,3.3,1.1,6.2,3.4,8.5c0.8,0.8,1.7,1.5,2.6,2.1l1.2,0.5l1.8-0.7l-1.5-0.5c-1.2-0.6-2.3-1.4-3.4-2.3
	c-2.1-2.1-3.2-4.7-3.2-7.6c0-1.9,0.4-3.6,1.2-5.1l0.1-0.3c0.3-0.7,0.8-1.2,1.2-1.8c-0.4,0.1-0.8,0.4-1.2,0.5L519.3,269L519.3,269z"
          />
          <path
            fill="#EBEBEB"
            d="M521.9,267.5c-0.6,0.5-1,1.1-1.2,1.8l-0.1,0.3c-0.8,1.5-1.2,3.3-1.2,5.1c0,3,1.1,5.5,3.2,7.6
	c1,1,2.2,1.8,3.4,2.3l1.5,0.5c4.3-1.7,8.5-3.4,12.5-5.4l0.3-0.1c1-0.7,1.5-1.5,1.5-2.5l0.1-1.4c0-0.8,0-1.5-0.1-2.2
	c-0.1-1.1-0.5-2.1-1.2-2.9l-4-4.3c-0.8-0.6-1.7-1-2.6-1.2l-1.9-0.3c-1.5-0.1-2.9,0.1-4.5,0.4C525.5,265.9,523.7,266.6,521.9,267.5
	L521.9,267.5z M529.8,267.5c2.2,0,4,0.8,5.5,2.3s2.3,3.4,2.3,5.5c0,2.2-0.8,4-2.3,5.5s-3.3,2.3-5.5,2.3h-0.1c-2.2,0-4-0.8-5.5-2.3
	s-2.2-3.3-2.2-5.5s0.8-4,2.2-5.5C525.7,268.3,527.6,267.5,529.8,267.5L529.8,267.5z"
          />
          <path
            fill="#EBEBEB"
            d="M535.3,269.8c-1.5-1.5-3.3-2.3-5.5-2.3s-4,0.8-5.5,2.3s-2.2,3.4-2.2,5.5c0,2.2,0.8,4,2.2,5.5
	c1.5,1.5,3.3,2.3,5.5,2.3h0.1c2.2,0,4-0.8,5.5-2.3s2.3-3.3,2.3-5.5C537.7,273.1,536.8,271.3,535.3,269.8L535.3,269.8z M529.8,268.4
	c1.9,0,3.6,0.7,5,2.1c1.4,1.4,2.1,3,2.1,5c0,1.9-0.7,3.6-2.1,5c-1.4,1.4-3,2.1-5,2.1c-1.9,0-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5
	s0.7-3.6,2.1-5C526.2,269.1,527.9,268.4,529.8,268.4L529.8,268.4z"
          />
          <path
            fill="#EBEBEB"
            d="M534.6,270.5c-1.4-1.4-3-2.1-5-2.1c-1.9,0-3.6,0.7-5,2.1c-1.4,1.4-2.1,3-2.1,5c0,1.9,0.7,3.6,2.1,5
	c1.4,1.4,3,2.1,5,2.1c1.9,0,3.6-0.7,5-2.1c1.4-1.4,2.1-3,2.1-5S536,271.9,534.6,270.5L534.6,270.5z M533.8,265.1
	c1,0.3,1.8,0.7,2.6,1.2l4,4.3c0.7,0.8,1.1,1.8,1.2,2.9c-0.1-1.1-0.3-2.1-0.5-3.3l4.3-2.2l-1-3.9l-6.5,2.5l-0.5-0.3
	C536.1,265.8,534.9,265.4,533.8,265.1L533.8,265.1z M504,282.2c-0.5-1.1-1.2-1.2-2.3-0.5l-7.6,6.5c-0.3,0.3-0.4,0.4-0.4,0.7l0.1,0.3
	l0.3,0.3c1.7,1.2,3.4,2.5,5.5,3.4c1.4,0.7,2.8,1,4.1,0.8c1.5-0.3,2.5-0.8,2.9-1.9c0.3-1,0.3-2.1,0-3.2L504,282.2L504,282.2z
	 M501.1,289.4c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.4,0.8-0.4s0.5,0.1,0.8,0.4
	c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.5-0.4,0.8C501.8,289.2,501.5,289.4,501.1,289.4L501.1,289.4z"
          />
          <path
            fill="#EBEBEB"
            d="M500.3,289.1c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3c0.3-0.3,0.4-0.5,0.4-0.8s-0.1-0.5-0.4-0.8
	c-0.3-0.3-0.5-0.4-0.8-0.4s-0.5,0.1-0.8,0.4c-0.3,0.3-0.3,0.5-0.3,0.8S500.2,288.8,500.3,289.1L500.3,289.1z M542.3,279.5l-3.4,1.7
	c-3.3,1.7-6.8,3-10.3,4.4l-3.4,1.2c-5.8,2.1-11.7,3.7-17.9,5.1l-0.1,2.1l4.8-1c6.1-1.7,11.6-3.3,16.9-5.1l6.3-2.5l5.4-2.6
	c1-0.5,1.9-1.4,2.8-2.2L542.3,279.5L542.3,279.5z"
          />
          <path
            fill="#EBEBEB"
            d="M519.2,267.9l2.1-1.2c1.2-0.5,2.3-1.1,3.6-1.5c1.7-0.5,3.2-1.1,4.8-1.2l6.8-0.5c2.8-0.1,5.1-0.7,7-1.7
	c1.4-0.7,2.5-1.5,3.3-2.6l-0.4-1.9c-2.6,3-5.7,4.5-9.4,4.5l-4.5,0.1c-3.6,0.3-6.9,1.1-10.1,2.5c-0.4,0.1-0.7,0.3-1.1,0.4
	c-8.8,4.5-17.9,11.2-27.6,19.7l-2.5,2.2c-1,1-1,1.9,0,2.9c0.4,0.5,0.8,1,1.5,1.5l0.6,0.4c2.3,1.9,5.4,3.4,9,4.5
	c2.3,0.5,5.8,0.1,10.5-1.1c8.5-2.2,16.3-4.8,23.3-7.6c5.2-2.1,9-4.5,11.2-7.4c0.4-2.8,0.8-5.5,0.8-8.3c-0.3,3.7-1.5,6.8-4,9.2
	c-1.1,1-2.3,1.9-3.7,2.8l-5.1,2.3l-3.7,1.5c-6.1,2.2-12.5,4.3-19.6,6.1c-2.6,0.7-5,1.1-6.8,1.2c-1.7,0.1-3.3,0-4.8-0.5
	c-2.6-1.2-5-2.6-7-4.3c-0.6-0.4-0.8-0.7-1.1-1.1c-0.4-0.7-0.3-1.4,0.5-2.1C503.2,278.5,512,272.1,519.2,267.9L519.2,267.9z"
          />
          <path
            fill="#FFFFFF"
            d="M521.3,264.8c0.4-0.1,0.7-0.3,1.1-0.4c3.2-1.4,6.5-2.2,10.1-2.5l4.5-0.1c3.7,0,6.8-1.5,9.4-4.5l1-1.2
	c1.2-1.9,2.2-3.9,3-5.9l-0.3-1.2l-0.8-3.6c-2.9-6.2-5.9-10.9-9.1-13.9c-0.4,1.1-1.1,2.2-1.9,3.2c-11.7,16.1-28.1,31.8-49.2,47.4
	c5.9-15.6,14.1-30,24.4-43.1c-11.6,12.7-20.8,27-27.8,43.3l8.4,2.6C503.3,276,512.4,269.4,521.3,264.8L521.3,264.8z"
          />
          <path
            fill="#FFFFFF"
            d="M543.7,223l-1.7-4.7l2.5,9c1.5,5.8,3,11.7,4.4,17.8l0.8,3.6l1.9-0.8l0.1-1l-2.5-7.4L543.7,223L543.7,223z
	 M558.9,231.8c-0.4,1.4-0.7,2.9-0.8,4.5c-0.1,1.1,0,2.2,0,3.4c0.1,1.7,0.4,3.3,0.8,5.1c1.1,1.1,3.4,1.4,6.9,1.2l14.2-1.4
	c2.1-2.5,4.5-4.4,7.3-5.5c2.1-0.8,3.9-1.7,5.4-2.6c0.1-2.1-0.7-3.6-2.5-4.5c-7.3-3.4-15.6-5-24.7-4.8
	C561.8,227.5,559.6,229,558.9,231.8L558.9,231.8z"
          />
          <path
            fill="#FFFFFF"
            d="M558.2,239.9c-0.1-1.2-0.1-2.3,0-3.4l-1.1-1.9c-1.7-2.8-3.9-5.4-6.3-7.7l1,2.9c0.7,1.4,1.1,2.9,1.5,4.5
	c1.5,1.2,2.9,2.5,3.9,3.9L558.2,239.9L558.2,239.9z"
          />
          <path
            fill="#FFFFFF"
            d="M559,244.9c-0.4-1.8-0.7-3.4-0.8-5.1l-1.2-1.8c-1-1.2-2.2-2.6-3.9-3.9c0.4,1.8,0.7,3.7,0.8,5.8v0.1
	c0,3.7-0.4,8-1.4,12.8v0.1c5.8,6.9,10.2,15.2,13.4,24.8l0.1-3.3c0.3-3.7,0.3-7.4,0.3-11l-2.8,5.4c-2.2-4.5-4.8-9.1-8-13.6l1.5-3
	l1.5-3c0.6-1.1,0.7-2.3,0.4-3.6V244.9L559,244.9z"
          />
          <path
            fill="#656565"
            d="M565.9,246.1c-3.4,0.3-5.8-0.1-6.9-1.2l0.1,0.7c0.3,1.2,0.1,2.5-0.4,3.6l-1.5,3c2.6,0.8,5.2,1.4,8,1.9
	l5.2,0.7l1.4-2.9c0.1-0.3,0.3-0.6,0.5-0.8l0.3-0.3c0.5-0.4,1.1-0.6,1.9-0.7c7.6-0.3,12.8-2.6,15.6-6.9l-10.1,1.5L565.9,246.1
	L565.9,246.1z"
          />
          <path
            fill="#FFFFFF"
            d="M552.3,232.1l-3.3,1.4c0.4,2.1,0.7,4.1,0.7,6.2l2.5,7.4c0.5-3.4,0.8-6.8,0.8-9.5v-0.1
	C552.7,235.4,552.5,233.7,552.3,232.1L552.3,232.1z"
          />
          <path
            fill="#FFFFFF"
            d="M551.6,229.6l0.6,2.3c0.3,1.7,0.6,3.4,0.7,5.2v0.1c0,2.9-0.3,6.1-0.8,9.5l-0.1,1l-0.8,4
	c0.3,0.8,0.7,1.7,1.1,2.5l0.1,0.3l0.4-1.7v-0.1c1-4.8,1.5-9.1,1.4-12.8v-0.1c-0.1-2.1-0.3-4-0.8-5.8
	C552.8,232.6,552.3,231.1,551.6,229.6L551.6,229.6z"
          />
          <path fill="#FFFFFF" d="M551.8,247.9l-1.9,0.7l0.3,1.4l0.7,1.9" />
          <path
            fill="#FFFFFF"
            d="M550.9,251.9l-0.7-1.9c-0.8,2.1-1.8,4-3,5.9c1.9,8.4,2.3,15.8,1.1,22c0.8,1.4,1.9,2.6,3.3,3.6
	c-0.3-1.2-0.4-2.5-0.4-3.9l1-9.9c0-1.2,0.5-1.7,1.7-1l7,6.1c-2.5-5.9-5.4-12-8.7-18.2l-0.1-0.3C551.7,253.5,551.3,252.7,550.9,251.9
	L550.9,251.9z"
          />
          <path
            fill="#FFFFFF"
            d="M552.5,253.1l-0.4,1.7c3.2,6.2,6.1,12.3,8.7,18.2l1.1,1c0.4,0.3,0.8,0.7,1.1,1.1c0.5,0.7,1,1.7,1.4,2.8
	c0.8,2.5,1.5,5.2,2.1,8c-0.4-2.6-0.7-5.2-0.6-7.9C562.7,268.3,558.3,259.9,552.5,253.1L552.5,253.1z M546.3,257.1l0.4,1.9l0.7,3.9
	c0.3,1.9,0.4,3.9,0.4,5.8v3.2c-0.1,2.8-0.4,5.5-0.8,8.3c0.5-0.7,1-1.4,1.4-2.2c1.2-6.3,0.8-13.6-1.1-22L546.3,257.1L546.3,257.1z"
          />
          <path
            fill="#FFFFFF"
            d="M553.9,266.8c-1.1-0.7-1.7-0.3-1.7,1l-1.1,9.9c0,1.4,0.1,2.6,0.4,3.9l8,4.5l1.1-7.6c0.7-3,1.5-4.3,2.3-3.4
	c-0.3-0.4-0.7-0.8-1.1-1.1l-1.1-1L553.9,266.8L553.9,266.8z"
          />
          <path
            fill="#EBEBEB"
            d="M547.8,271.9v-3.2l-5.1,2.8l0.3,1.7v0.7l0.1,0.8c0.3,2.2,0.4,4.3,0.5,6.3
	C546.3,278.6,547.7,275.6,547.8,271.9L547.8,271.9z"
          />
          <path
            fill="#656565"
            d="M569.3,257l1.1-2.2l-5.2-0.7c-2.8-0.6-5.4-1.1-8-1.9l-1.5,3c3.2,4.5,5.8,9.1,8,13.6l2.8-5.4v-0.4l0.1-0.4
	L569.3,257L569.3,257z"
          />
          <path
            fill="#FFFFFF"
            d="M569.3,259.9V257l-2.6,5.6C568.4,262,569.3,261,569.3,259.9L569.3,259.9z"
          />
          <path
            fill="#EBEBEB"
            d="M592.5,238.3l0.3-1.8c-1.5,1-3.3,1.8-5.4,2.6c-2.8,1.1-5.2,3-7.3,5.5l10.1-1.5l0.5-1.1L592.5,238.3
	L592.5,238.3z M590,238.9l0.1-0.1l0.3-0.1h0.3l0.1,0.1v0.1l-1.2,3.4l-0.1,0.1l-0.3,0.1h-0.3l-0.3-0.1v-0.1L590,238.9L590,238.9z"
          />
          <path
            fill="#EBEBEB"
            d="M590.2,238.8l-0.1,0.1l-1.2,3.4v0.3h0.7l0.3-0.3l1.2-3.4v-0.1l-0.3-0.1h-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M329.3,161.5c1-1.7,2.1-2.8,3.6-3.2c-7.4,2.1-12.4,5.9-14.7,11.4l-1.1,2.2l-1.8,4.5
	c-2.2,5.6-4.4,11.6-6.5,17.8l-3.7,11.7c-1.2,4-2.3,8-3.6,12.3c-0.8,2.9-1.7,5.9-2.5,9c-1.5,5.8-3,11.7-4.4,17.8
	c2.9-6.2,5.9-10.9,9.1-13.9c-0.5-1.2-0.8-2.5-0.8-3.9c0.1-1.9,0.5-4.4,1.5-7.2c4.3-14.7,9.2-29.2,14.9-43.5l0.3-0.7
	c0.4-1.2,1.2-2.2,2.1-2.9l0.3-0.3c0.1-0.1,0.4-0.1,0.5-0.3c0,0,0.1,0,0.1-0.1l0.4-0.3l0.3-0.1L329.3,161.5L329.3,161.5z"
          />
          <path
            fill="#FFFFFF"
            d="M332.9,158.3c-1.5,0.6-2.6,1.5-3.6,3.2l-6.1,10.5c0.5-0.3,1.2-0.4,1.9-0.4l2.9-0.3l6.2-10.5
	c1-1.7,2.3-2.9,4-3.6l-1.1,0.1C335.8,157.6,334.4,157.9,332.9,158.3L332.9,158.3z M321.9,172.8l-0.3,0.3c-0.8,0.7-1.5,1.7-1.9,2.8
	l-0.3,0.7c-5,13.9-9.2,28.1-13,42.4c-1.1,4-1.5,7.2-1,9.5l0.4,1.2c0.4-3.2,1-6.3,1.9-9.5l0.4-1.2c3.7-14.3,8.5-29.9,14.7-46.7
	l-0.4,0.3c0,0-0.1,0-0.1,0.1C322.3,172.7,322.1,172.8,321.9,172.8L321.9,172.8z"
          />
          <path
            fill="#FFFFFF"
            d="M323.8,173.6l-0.5-1.5l-0.3,0.1c-6.2,16.8-11,32.4-14.7,46.7l-0.4,1.2c-1,3.2-1.5,6.5-1.9,9.5l0.7,1.1
	l0.4,0.4c0.4-4.1,1.1-7.9,2.1-11l0.4-1.2C313.2,205,317.9,189.9,323.8,173.6L323.8,173.6z M323.4,172.1l0.5,1.5l5-0.4l-0.5-1.8
	l-2.9,0.3C324.6,171.7,323.9,171.8,323.4,172.1L323.4,172.1z"
          />
          <path fill="#FFFFFF" d="M323,172.3h0.1l-0.6,0.3" />
          <path
            fill="#EBEBEB"
            d="M250.8,236.6c0,0.6,0.1,1.1,0.3,1.8l1.7,4l0.6,1.1l10.1,1.5c-2.1-2.5-4.5-4.4-7.3-5.5
	C254.1,238.4,252.3,237.6,250.8,236.6L250.8,236.6z M252.8,238.7h0.3l0.3,0.1l0.1,0.1l1.2,3.4v0.1l-0.3,0.1h-0.3l-0.3-0.1l-0.1-0.1
	l-1.2-3.4v-0.1C252.7,238.7,252.7,238.7,252.8,238.7L252.8,238.7z"
          />
          <path
            fill="#EBEBEB"
            d="M253,238.7h-0.3c-0.1,0-0.1,0-0.1,0.1v0.1l1.2,3.4l0.1,0.1l0.3,0.1h0.3l0.3-0.1v-0.1l-1.2-3.4l-0.1-0.1
	L253,238.7L253,238.7z"
          />
          <path
            fill="#FFFFFF"
            d="M250.8,236.6c1.5,1,3.3,1.8,5.4,2.6c2.8,1.1,5.2,3,7.3,5.5l14.2,1.4c3.4,0.3,5.8-0.1,6.9-1.2
	c0.4-1.8,0.7-3.4,0.8-5.1c0.1-1.2,0.1-2.3,0-3.4c-0.1-1.7-0.3-3.2-0.8-4.5c-0.7-2.8-2.9-4.3-6.8-4.5c-9.1-0.1-17.4,1.4-24.7,4.8
	C251.5,233,250.5,234.5,250.8,236.6L250.8,236.6z"
          />
          <path
            fill="#FFFFFF"
            d="M292.9,226.7v-0.1c-3.2,3-5.6,6.3-7.6,9.8c0.1,1.1,0,2.2,0,3.4c1-1.9,2.8-3.7,5-5.6c0.4-1.7,1-3.2,1.5-4.5
	l0.7-2.3L292.9,226.7L292.9,226.7z"
          />
          <path
            fill="#FFFFFF"
            d="M291.3,232.1l0.5-2.3c-0.7,1.4-1.1,2.9-1.5,4.5c-0.4,1.8-0.7,3.7-0.8,5.8v0.1c0,3.7,0.4,8,1.4,12.8v0.1
	l0.4,1.7l1.2-2.8l-0.8-4l-0.1-1c-0.5-3.4-0.8-6.8-0.8-9.5v-0.1C290.7,235.4,291,233.7,291.3,232.1L291.3,232.1z"
          />
          <path
            fill="#FFFFFF"
            d="M294.7,233.3l-3.3-1.4c-0.3,1.7-0.5,3.4-0.7,5.2v0.1c0,2.9,0.3,6.1,0.8,9.5l2.5-7.4
	C294,237.6,294.2,235.5,294.7,233.3L294.7,233.3z M285.4,239.9c-0.1,1.7-0.4,3.3-0.8,5.1l-0.1,0.7c-0.3,1.2-0.1,2.5,0.4,3.6l1.5,3
	l1.5,3c-3.2,4.5-5.8,9.1-8,13.6l-2.8-5.8c0,3.9,0.1,7.6,0.3,11.4l0.1,3.3c3.2-9.6,7.7-17.9,13.4-24.8V253c-1-4.8-1.5-9.1-1.4-12.8
	V240c0.1-2.1,0.4-4,0.8-5.8C288.1,236,286.3,238,285.4,239.9L285.4,239.9z"
          />
          <path
            fill="#656565"
            d="M284.5,244.9c-1.1,1.1-3.4,1.4-6.9,1.2l-14.2-1.4l-10.1-1.5c2.9,4.4,8,6.6,15.6,6.9c1.5,0.1,2.3,0.7,2.9,1.8
	l1.4,2.9c4.5-0.4,9-1.4,13.1-2.6l-1.5-3c-0.5-1.1-0.7-2.3-0.4-3.6L284.5,244.9L284.5,244.9z"
          />
          <path
            fill="#FFFFFF"
            d="M294,239.5l-2.5,7.4l0.1,1l1.9,0.8l0.8-3.6c1.5-6.2,2.9-12.1,4.4-17.8c0.8-3,1.7-6.1,2.5-9l-1.7,4.7
	L294,239.5L294,239.5z"
          />
          <path
            fill="#656565"
            d="M276.8,262.6l0.1,0.4l2.8,5.8c2.2-4.5,4.8-9.1,8-13.6l-1.5-3c-4.3,1.2-8.5,2.2-13.1,2.6l1.1,2.2L276.8,262.6
	L276.8,262.6z"
          />
          <path
            fill="#FFFFFF"
            d="M274.2,259.9c0,1.1,0.8,2.1,2.6,2.8l-2.6-5.6V259.9L274.2,259.9z M293.6,248.6l-0.3,1.2c0.8,2.1,1.8,4,3,5.9
	l1,1.2c2.6,3,5.6,4.5,9.4,4.5l4.5,0.1c3.6,0.3,6.9,1.1,10.1,2.5c0.4,0.1,0.7,0.3,1.1,0.4c8.8,4.5,17.9,11.2,27.6,19.7l8.4-2.6
	c-7-16.1-16.4-30.6-27.8-43.3c10.3,13.1,18.5,27.6,24.4,43.1c-21.1-15.6-37.5-31.4-49.2-47.4c-0.8-1.1-1.5-2.2-1.9-3.2
	c-3.2,3-6.2,7.6-9.1,13.9L293.6,248.6L293.6,248.6z"
          />
          <path fill="#FFFFFF" d="M293.2,250l0.4-1.4l-1.9-0.7l0.8,4" />
          <path
            fill="#FFFFFF"
            d="M293.2,250l-0.7,1.9l-1.2,2.8c-3.2,6.2-6.1,12.3-8.7,18.2l7-6.1c1.1-0.7,1.7-0.3,1.7,1l1,9.9
	c0,1.4-0.1,2.6-0.4,3.9c1.2-1,2.3-2.2,3.3-3.6c-1.2-6.3-0.8-13.6,1.1-22C295.1,254,294,252,293.2,250L293.2,250z"
          />
          <path
            fill="#EBEBEB"
            d="M296.8,259.1l0.4-1.9l-1-1.2c-1.9,8.4-2.3,15.8-1.1,22c0.4,0.7,0.8,1.5,1.4,2.2c-0.4-2.8-0.8-5.5-0.8-8.3
	v-3.2c0-1.9,0.1-3.9,0.4-5.8L296.8,259.1L296.8,259.1z"
          />
          <path
            fill="#EBEBEB"
            d="M297.2,257.1l-0.4,1.9c0.8,1.1,1.9,1.9,3.3,2.6c1.9,1,4.3,1.4,7,1.7l6.8,0.5c1.7,0.3,3.3,0.7,4.8,1.2
	c1.2,0.4,2.3,1,3.6,1.5l2.1,1.2c7.2,4.3,16,10.7,26.5,19.3c0.8,0.7,1,1.2,0.5,2.1c-0.1,0.4-0.5,0.7-1.1,1.1c-2.1,1.7-4.4,3-7,4.3
	c-1.5,0.6-3.2,0.7-4.8,0.6c-1.9-0.1-4.1-0.6-6.8-1.2c-7-1.8-13.6-3.9-19.6-6.1l-3.7-1.5c-1.1-0.4-2.2-1-3.2-1.4l-1.9-1
	c-1.4-0.8-2.6-1.7-3.7-2.8c-2.5-2.5-3.7-5.5-4-9.2c0.1,2.8,0.4,5.5,0.8,8.3c2.2,2.9,5.9,5.4,11.2,7.4c7,2.8,14.7,5.4,23.3,7.6
	c4.5,1.2,8.1,1.5,10.5,1.1c3.6-1.1,6.5-2.6,9-4.5l0.6-0.4c0.5-0.4,1.1-1,1.5-1.5c0.8-1,0.8-1.9-0.1-2.9l-2.3-2.2
	c-9.6-8.5-18.7-15.2-27.6-19.7c-0.4-0.1-0.7-0.3-1.1-0.4c-3.2-1.4-6.5-2.2-10.1-2.5l-4.5-0.1C302.9,261.7,299.8,260.2,297.2,257.1
	L297.2,257.1z"
          />
          <path
            fill="#FFFFFF"
            d="M291.3,254.6l-0.4-1.7c-5.8,6.9-10.2,15.2-13.4,24.8c0,2.6-0.1,5.2-0.5,7.9c0.5-2.8,1.2-5.5,2.1-8
	c0.4-1.1,0.8-2.1,1.4-2.8c0.3-0.4,0.7-0.8,1.1-1.1l1.1-1C285.2,266.9,288.1,260.9,291.3,254.6L291.3,254.6z"
          />
          <path
            fill="#EBEBEB"
            d="M296.1,262.9c-0.3,1.9-0.4,3.9-0.4,5.8l5.1,2.8l-0.3,1.7v0.7l-0.1,0.8c-0.3,2.2-0.4,4.3-0.6,6.3
	c1.1,1,2.2,1.9,3.7,2.8l1.9,1c1,0.4,2.1,1,3.2,1.4l3.7,1.5c5.9,2.2,12.5,4.3,19.6,6.1c2.6,0.7,5,1.1,6.8,1.2c1.7,0.1,3.3,0,4.8-0.5
	c2.6-1.2,5-2.6,7-4.3c0.5-0.4,0.8-0.7,1.1-1.1c0.4-0.7,0.3-1.4-0.5-2.1c-10.5-8.5-19.4-15-26.5-19.3l-2.1-1.2
	c-1.2-0.6-2.3-1.1-3.6-1.5c-1.7-0.5-3.2-1.1-4.8-1.2l-6.8-0.5c-2.8-0.1-5.1-0.7-7-1.7c-1.4-0.7-2.5-1.5-3.3-2.6L296.1,262.9
	L296.1,262.9z M302.4,270.4l-4.3-2.2l1-3.9l6.5,2.5l0.5-0.3c1.1-0.5,2.3-1,3.6-1.2l1.9-0.3c1.5-0.1,2.9,0.1,4.5,0.4
	c1.8,0.5,3.6,1.2,5.4,2.1l2.6,1.5l1.1,0.7c4.4,2.8,9.1,6.2,14.5,10.3l2.1,1.7c2.5,2.1,5,4.1,7.6,6.5c0.3,0.3,0.4,0.4,0.4,0.7
	l-0.1,0.3l-0.3,0.3c-1.7,1.2-3.4,2.5-5.5,3.4c-1.4,0.7-2.8,1-4.1,0.8c-1.5-0.3-2.5-0.8-2.9-1.9c-0.3-1-0.3-2.1,0-3.2l2.3-6.2
	c0.5-1.1,1.2-1.2,2.3-0.5l-2.1-1.7l-4,10.9l-0.8-0.1c-5.4-1.2-10.6-2.8-15.6-4.4l-1.2-0.4l-1.8-0.7c-4.3-1.7-8.5-3.4-12.5-5.4
	l-0.3-0.1c-1-0.7-1.5-1.5-1.5-2.5l-0.1-1.4c0-0.8,0-1.5,0.1-2.2C302,272.6,302.2,271.5,302.4,270.4L302.4,270.4z M336.3,291.9
	l0.1,2.1l-4.8-1c-6.1-1.7-11.6-3.3-16.9-5.1l-6.3-2.5l-5.4-2.6c-1-0.6-1.9-1.4-2.8-2.2l0.8-1.1v-0.1l3.3,1.7c3.3,1.7,6.8,3,10.3,4.4
	l3.4,1.2C324.1,288.7,330,290.5,336.3,291.9L336.3,291.9z"
          />
          <path
            fill="#EBEBEB"
            d="M298.2,268l4.3,2.2c-0.3,1.1-0.4,2.2-0.5,3.3c0.1-1.1,0.5-2.1,1.2-2.9l4-4.3c0.8-0.6,1.7-1,2.6-1.2
	c-1.2,0.3-2.3,0.7-3.6,1.2l-0.5,0.3l-6.5-2.5L298.2,268L298.2,268z"
          />
          <path
            fill="#EBEBEB"
            d="M307.1,266.4l-4,4.3c-0.7,0.8-1.1,1.8-1.2,2.9c-0.1,0.7-0.1,1.5-0.1,2.2l0.1,1.4c0,1,0.5,1.8,1.5,2.5
	l0.3,0.1c4.1,1.9,8.3,3.7,12.5,5.4l1.5-0.5c1.2-0.5,2.3-1.4,3.4-2.3c2.1-2.1,3.2-4.7,3.2-7.6c0-1.9-0.4-3.6-1.2-5.1l-0.1-0.3
	c-0.3-0.7-0.8-1.2-1.2-1.8c-1.8-1-3.6-1.7-5.4-2.1c-1.5-0.4-3-0.5-4.5-0.4l-1.9,0.3C308.8,265.4,308,265.8,307.1,266.4L307.1,266.4z
	 M308.2,269.8c1.5-1.5,3.3-2.3,5.5-2.3s4,0.8,5.5,2.3s2.2,3.4,2.2,5.5c0,2.2-0.8,4-2.2,5.5c-1.5,1.5-3.4,2.3-5.5,2.3h-0.1
	c-2.2,0-4-0.8-5.5-2.3s-2.3-3.3-2.3-5.5C305.9,273.1,306.7,271.3,308.2,269.8L308.2,269.8z"
          />
          <path
            fill="#FFFFFF"
            d="M313.7,267.5c-2.2,0-4,0.8-5.5,2.3s-2.3,3.4-2.3,5.5c0,2.2,0.8,4,2.3,5.5s3.3,2.3,5.5,2.3h0.1
	c2.1,0,4-0.8,5.5-2.3s2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5C317.7,268.3,316,267.5,313.7,267.5L313.7,267.5z M308.8,270.5
	c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5,2.1s2.1,3,2.1,5c0,1.9-0.7,3.6-2.1,5s-3,2.1-5,2.1s-3.6-0.7-5-2.1s-2.1-3-2.1-5
	S307.4,271.9,308.8,270.5L308.8,270.5z"
          />
          <path
            fill="#EBEBEB"
            d="M313.7,268.4c-1.9,0-3.6,0.7-5,2.1s-2.1,3-2.1,5c0,1.9,0.7,3.6,2.1,5s3,2.1,5,2.1s3.6-0.7,5-2.1s2.1-3,2.1-5
	s-0.7-3.6-2.1-5S315.7,268.4,313.7,268.4L313.7,268.4z"
          />
          <path
            fill="#FFFFFF"
            d="M322.8,269.3l0.1,0.3c0.8,1.5,1.2,3.3,1.2,5.1c0,3-1.1,5.5-3.2,7.6c-1,1-2.2,1.8-3.4,2.3l-1.5,0.5l1.8,0.7
	l1.2-0.6c1-0.5,1.8-1.2,2.6-2.1c2.3-2.3,3.4-5.2,3.4-8.5c0-1.5-0.3-2.9-0.7-4.1l-0.3-1.5l-2.6-1.5
	C322.1,268,322.6,268.7,322.8,269.3L322.8,269.3z"
          />
          <path
            fill="#EBEBEB"
            d="M324.6,270.5c0.5,1.2,0.7,2.8,0.7,4.1c0,3.3-1.1,6.2-3.4,8.5c-0.8,0.8-1.7,1.5-2.6,2.1l-1.2,0.5l1.2,0.4
	c5,1.8,10.2,3.2,15.6,4.4l0.8,0.1l4-10.9c-5.2-4.1-10.1-7.6-14.5-10.3l-1.1-0.7L324.6,270.5L324.6,270.5z M322.4,283.9
	c2.5-2.5,3.9-5.5,3.9-9.1c0-1.2-0.1-2.3-0.4-3.4c3.9,2.5,8.1,5.5,12.8,9.2l-3.4,9.2c-5.1-1.2-10.1-2.6-14.7-4.1
	C321.2,285.1,321.9,284.6,322.4,283.9L322.4,283.9z"
          />
          <path
            fill="#EBEBEB"
            d="M326.3,274.6c0,3.6-1.2,6.6-3.9,9.1c-0.7,0.7-1.4,1.2-2.1,1.8c4.8,1.7,9.8,3,14.7,4.1l3.4-9.2
	c-4.7-3.6-9-6.8-12.8-9.2C326.1,272.4,326.3,273.5,326.3,274.6L326.3,274.6z M329.6,282.2v-0.6c0-0.1,0.1-0.3,0.3-0.4
	c0.1-0.1,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7c0,0.3-0.1,0.4-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3
	c-0.3,0-0.4-0.1-0.7-0.3L329.6,282.2L329.6,282.2z"
          />
          <path
            fill="#EBEBEB"
            d="M329.5,281.9v0.3l0.3,0.4c0.1,0.1,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7
	c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3c-0.3,0-0.4,0.1-0.7,0.3c-0.1,0.1-0.1,0.3-0.3,0.4L329.5,281.9L329.5,281.9z M341.7,281.7
	c-1-0.7-1.8-0.5-2.3,0.5l-2.3,6.2c-0.3,1.2-0.3,2.2,0,3.2c0.4,1.1,1.4,1.8,2.9,1.9c1.4,0.1,2.8-0.1,4.1-0.8c2.1-1,3.9-2.2,5.5-3.4
	l0.3-0.3l0.1-0.3c0-0.3-0.1-0.4-0.4-0.7C346.7,285.8,344.2,283.7,341.7,281.7L341.7,281.7z M343.5,288.3c0,0.3-0.1,0.6-0.3,0.8
	c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.4-0.5-0.4-0.8s0.1-0.5,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4
	c0.3,0,0.5,0.1,0.8,0.4C343.4,287.6,343.5,287.9,343.5,288.3L343.5,288.3z"
          />
          <path
            fill="#EBEBEB"
            d="M343.2,289.1c0.3-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.5,0.1-0.8,0.4
	c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.5,0.4,0.8c0.3,0.3,0.5,0.3,0.8,0.3C342.7,289.4,343,289.2,343.2,289.1L343.2,289.1z M336.5,293.8
	l-0.1-2.1c-6.2-1.4-12.3-3-17.9-5.1l-3.4-1.2c-3.6-1.4-7-2.9-10.3-4.4l-3.3-1.7v0.1l-0.8,1.1c0.8,0.8,1.8,1.5,2.8,2.2l5.4,2.6
	l6.3,2.5c5.2,1.8,10.9,3.6,16.9,5.1L336.5,293.8L336.5,293.8z"
          />
          <path
            fill="#FFFFFF"
            d="M291.3,267.7c0-1.2-0.5-1.7-1.7-1l-7,6.1l-1.1,1c-0.4,0.3-0.7,0.7-1.1,1.1c0.8-0.8,1.5,0.4,2.2,3.4l1.1,7.6
	l8-4.5c0.3-1.2,0.4-2.5,0.4-3.9L291.3,267.7L291.3,267.7z"
          />
          <path
            fill="#EBEBEB"
            d="M295.7,268.8v3.2c0.3,3.7,1.5,6.8,4,9.2c0-2.1,0.1-4.1,0.6-6.3l0.1-0.8v-0.7l0.3-1.7L295.7,268.8
	L295.7,268.8z"
          />
          <path
            fill="#FFFFFF"
            d="M319.4,176.5l0.3-0.7c0.4-1.1,1.1-2.1,1.9-2.8c-1,0.7-1.7,1.7-2.1,2.9l-0.3,0.7
	c-5.6,14.3-10.6,28.9-14.9,43.5c-1,2.8-1.4,5.2-1.5,7.2c0,1.2,0.3,2.5,0.8,3.9l3.2-0.3l-0.7-1.1l-0.4-1.2c-0.5-2.3-0.3-5.5,1-9.5
	C310.2,204.6,314.4,190.4,319.4,176.5L319.4,176.5z M358.8,283.4l-0.7-1.5l-8.3,2.6l2.3,2.2c1,1,1,1.9,0.1,2.9
	c-0.4,0.5-0.8,1-1.5,1.5l-0.6,0.4c-2.3,1.9-5.4,3.4-9,4.5c-2.3,0.5-5.8,0.1-10.5-1.1c-8.5-2.2-16.3-4.8-23.3-7.6
	c-5.2-2.1-9-4.5-11.2-7.4c-0.6-0.7-1-1.4-1.4-2.2l0.3,1.2c1.7,5.5,5.6,9.6,12.1,12.3c7,2.8,14.7,5.2,23.3,7.6c7,1.5,12.5,1,16.8-1.7
	l2.8-1.9c0.1-0.1,0.3-0.1,0.3-0.3l2.1-1.5l3.6-3.2C358.8,288.4,359.5,286.1,358.8,283.4L358.8,283.4z"
          />
          <path
            fill="#FFFFFF"
            d="M358.1,281.9l0.7,1.5c0.8,2.5,0,5-2.3,7l-3.6,3.2l-2.1,1.5c-0.1,0.1-0.1,0.1-0.3,0.3l-2.8,1.9
	c-4.1,2.8-9.8,3.2-16.8,1.6c-8.5-2.2-16.3-4.8-23.3-7.6c-6.5-2.6-10.6-6.8-12.1-12.3l-0.3-1.2c-0.8,1.4-1.9,2.6-3.3,3.6l-0.7,2.5
	c-1.2,3.2-1.5,6.3-0.8,9.5c0.1,3.7,2.3,6.8,6.6,9.4c7.9,5.1,23.3,9.2,46.2,12.3c0.1-0.6,0.4-1,0.8-1.5c5.1-5.4,10.7-10.2,17.1-14.5
	l4.3-2.2c5.1-2.3,10.6-3.6,16.7-4l40.8-1.2l39,1.2c6.1,0.4,11.6,1.7,16.7,4l4.3,2.2c6.3,4.1,12,9,17.1,14.5c0.4,0.5,0.7,1,1,1.5
	c22.9-3.2,38.2-7.3,46-12.3c4.1-2.6,6.3-5.8,6.6-9.4c0.5-3,0.3-6.2-0.8-9.5l-0.7-2.5c-1.2-1-2.3-2.2-3.3-3.6l-0.3,1.2
	c-1.7,5.5-5.7,9.6-12.1,12.3c-7,2.8-14.7,5.2-23.3,7.6c-7,1.5-12.5,1-16.8-1.7l-5.1-3.6c-1.4-1.1-2.6-2.1-3.6-3.2
	c-2.3-2.2-3.2-4.5-2.3-7l0.7-1.5c-16.4-1.5-32.8-2.3-49.2-2.8h-28.8c-1.7,0-3.4,0-5.1,0.1C387.5,279.7,372.9,280.5,358.1,281.9
	L358.1,281.9z"
          />
          <path
            fill="#FFFFFF"
            d="M344.2,317.2c-0.1-0.5-0.1-1.1,0-1.5c0.1-0.4,0.3-0.8,0.7-1.2c1.2-1.4,2.5-2.6,3.9-3.9l1.5-1.5
	c1.8-1.7,3.6-3.2,5.4-4.5l1.9-1.5l4-2.9h0.1l0.7-0.3c1-0.5,1.9-1.1,3-1.7s2.3-1.1,3.6-1.5c4-1.5,8.4-2.3,13.1-2.6l2.1-0.1l0.8-0.1
	l24.5-1h24.8l24.5,1l0.8,0.1l2.1,0.1c4.7,0.3,9.1,1.1,13.1,2.6c1.2,0.4,2.3,1,3.6,1.5c1,0.4,2.1,1,3,1.7l0.7,0.3h0.1l4,2.9l1.9,1.5
	c1.9,1.4,3.7,2.9,5.4,4.5l1.5,1.5c1.2,1.2,2.6,2.5,3.9,3.9c0.3,0.4,0.5,0.8,0.7,1.2c0.1,0.5,0.1,1.1,0,1.5l-0.3,0.4
	c-1,1.5-2.2,2.9-3.7,4.1l-2.3,1.5c-4.4,2.3-10.5,3.4-18.2,3.4h-2.5l-0.4,1.4h2.6c13.2,0,21.6-3.2,25.5-9.6c0.7-1.1,0.8-2.2,0.4-3.4
	c-0.3-0.5-0.5-1-1-1.5c-5.1-5.4-10.7-10.2-17.1-14.5l-4.3-2.2c-5.1-2.3-10.6-3.6-16.7-4l-39-1.2l-40.8,1.2c-6.1,0.4-11.6,1.7-16.7,4
	L361,299c-6.3,4.1-12,9-17.1,14.5c-0.4,0.5-0.7,1-0.8,1.5c-0.4,1.1-0.4,2.2,0.3,3.4c3.7,6.5,12.3,9.6,25.5,9.6h2.6l-0.4-1.4h-2.2
	c-7.7,0-13.8-1.1-18.2-3.4l-2.3-1.5c-1.5-1.1-2.8-2.5-3.7-4.1L344.2,317.2L344.2,317.2z"
          />
          <path
            fill="#FFFFFF"
            d="M344.2,315.7c-0.1,0.6-0.1,1.1,0,1.5h154.9c0.1-0.5,0.1-1.1,0-1.5L344.2,315.7L344.2,315.7z"
          />
          <path
            fill="#656565"
            d="M344.9,314.5c-0.3,0.4-0.5,0.8-0.7,1.2h22.3l-1.7-5.1h-16.3C347.4,311.8,346.1,313.2,344.9,314.5
	L344.9,314.5z"
          />
          <path
            fill="#FFFFFF"
            d="M343.2,318.4c-0.7-1.1-0.8-2.2-0.3-3.4c-22.9-3.2-38.2-7.3-46.2-12.3c-4.1-2.6-6.3-5.8-6.6-9.4
	c-0.5-3-0.3-6.2,0.8-9.5l0.7-2.5l-8,4.5l3.7,23.6l-3.7-23.6l-6.8,12.1l5.8,36.4l2.6,15.3h17l-2.3-0.4c-2.2-0.4-3.4-1.5-4-3.4
	c-1.7-5.8-3-11.9-4.3-18c-0.3-1.1-0.1-1.8,0.5-2.2c0.4-0.4,1.2-0.5,2.3-0.3h0.1l26.5,8.3c4.1,1.5,8,4.1,11.4,7.9l0.1,0.1
	c3,3.2,5.5,6.3,7.4,9.6c0.8,1.2,0.8,2.1,0.1,2.6c-0.4,0.5-1.5,0.7-3,0.5l-13.6-1.2l39.5,5.6c-1.4-0.4-2.6-1-4-1.8
	c-3.7-2.5-7-6.2-10.1-11.3l-0.1-0.4l-6.2-10.2c2.5,3,5.1,5.6,7.9,8l0.1,0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-0.3
	c0.1-0.1,0.3-0.1,0.3-0.1h140.7c0.1,0,0.3,0,0.3,0.1l0.1,0.3l-0.1,0.3l-0.1,0.1l0.1-0.1c2.8-2.3,5.4-5.1,7.9-8l-6.2,10.2l-0.1,0.4
	c-3,5.1-6.3,8.8-10.1,11.3c-1.2,0.8-2.6,1.5-4,1.8l39.5-5.6l-13.6,1.2c-1.5,0.1-2.6,0-3-0.5c-0.7-0.6-0.7-1.4,0.1-2.6
	c1.9-3.2,4.4-6.5,7.4-9.6l0.1-0.1c3.4-3.9,7.3-6.5,11.4-7.9l26.5-8.3h0.1c1.1-0.3,1.9-0.1,2.3,0.3c0.6,0.4,0.8,1.1,0.6,2.2
	c-1.1,6.2-2.6,12.3-4.3,18c-0.4,1.9-1.8,3-4,3.4l-2.3,0.4h17l2.6-15.3l5.8-36.4l-6.8-12.1l-3.7,23.6l3.7-23.6l-8-4.5l0.7,2.5
	c1.2,3.2,1.5,6.3,0.8,9.5c-0.1,3.7-2.3,6.8-6.6,9.4c-7.9,5.1-23.1,9.2-46,12.3c0.4,1.1,0.3,2.2-0.4,3.4c-3.7,6.5-12.3,9.6-25.5,9.6
	h-2.6l-3.7,12.7h-93.3l-3.7-12.7h-2.6C355.5,328.1,347.1,324.9,343.2,318.4L343.2,318.4z M321.5,326.9c3.7,1.2,6.2,2.9,7.4,5.1
	L292,321.3c3-1.4,6.6-1.4,10.7-0.1L321.5,326.9L321.5,326.9z M540.7,321.2c4.1-1.2,7.7-1.2,10.8,0.1L514.5,332
	c1.2-2.2,3.7-3.9,7.4-5.1L540.7,321.2L540.7,321.2z"
          />
          <path
            fill="#FFFFFF"
            d="M328.9,332c-1.2-2.2-3.7-3.9-7.4-5.1l-18.7-5.6c-4.1-1.2-7.7-1.2-10.7,0.1L328.9,332L328.9,332z
	 M551.6,321.3c-3-1.4-6.6-1.4-10.7-0.1l-18.7,5.6c-3.7,1.2-6.2,2.9-7.4,5.1L551.6,321.3L551.6,321.3z"
          />
          <path
            fill="#656565"
            d="M344.2,317.2l0.1,0.4c1,1.5,2.2,2.9,3.7,4.1h20.3l-1.5-4.5L344.2,317.2L344.2,317.2z"
          />
          <path fill="#FFFFFF" d="M350.3,309.2l-1.5,1.5h146.1l-1.5-1.5H364.5" />
          <path
            fill="#656565"
            d="M355.6,304.7c-1.9,1.4-3.7,2.9-5.4,4.5h14.2l-1.5-4.5H355.6L355.6,304.7z M365.3,298.3c-1,0.4-2.1,1-3,1.7
	l1.1,3.2h22.5l-1-4.8L365.3,298.3L365.3,298.3z"
          />
          <path
            fill="#FFFFFF"
            d="M361.7,300.4h-0.1l1,2.9h0.8l-1.1-3.3 M363.4,303.3h-5.8l-1.9,1.4h132.3l-1.9-1.4H385.8"
          />
          <path fill="#FFFFFF" d="M363.9,304.7h-1l1.5,4.5h0.8" />
          <path fill="#656565" d="M361.6,300.4l-4,2.9h5" />
          <path
            fill="#FFFFFF"
            d="M364.9,310.7l1.7,5h0.8l-1.5-5 M368.9,297c-1.2,0.4-2.3,1-3.6,1.5h112.8c-1.1-0.5-2.3-1.1-3.6-1.5L368.9,297
	L368.9,297z"
          />
          <path
            fill="#656565"
            d="M363.9,304.7l1.4,4.5h21.6l-1-4.5 M365.8,310.7l1.5,5h20.8l-1-5"
          />
          <path fill="#FFFFFF" d="M367.9,317.2h-0.8l1.5,4.5h0.8" />
          <path
            fill="#656565"
            d="M321.6,333.6l-26.6-8.3h-0.1c-1.1-0.3-1.9-0.1-2.3,0.3c-0.5,0.4-0.8,1.1-0.5,2.2c1.1,6.2,2.6,12.3,4.3,18
	c0.4,1.9,1.8,3,4,3.4l2.3,0.4l16.3,2.8h0.3l4.8,0.7l13.6,1.2c1.5,0.1,2.6,0,3-0.5c0.7-0.6,0.7-1.4-0.1-2.6c-1.9-3.2-4.4-6.5-7.4-9.6
	l-0.1-0.1C329.6,337.7,325.7,335.1,321.6,333.6L321.6,333.6z M321,335c3.6,1.2,6.9,3.4,9.9,6.5l2.1,2.2c2.5,2.8,4.7,5.5,6.3,8.4
	c0.3,0.4,0.4,0.7,0.3,0.8l-0.3,0.1h-1.7c-6.1-0.4-12.3-1.1-18.5-1.9l-18.9-3.2c-1.5-0.3-2.5-1.1-2.9-2.5c-1.7-5.8-3.2-11.9-4.3-17.9
	c-0.1-0.4,0-0.7,0-0.8c0.3-0.1,0.5-0.1,1.1,0l0.1,0.1l17.2,5.4L321,335L321,335z"
          />
          <path
            fill="#656565"
            d="M320.9,335l-9.2-2.9c2.1,1.7,3.4,3.6,3.7,5.9c0.1,0.7,0.1,1.4,0.1,2.1l-0.3,1.9l-0.1,0.8l-0.7,1.8
	c-0.4,0.8-1,1.5-1.7,2.1c-0.5,0.4-1.1,0.7-1.7,1c9,2.1,18.3,3.9,28.2,5.4l0.3-0.1c0-0.1,0-0.4-0.3-0.8c-1.7-2.8-3.9-5.6-6.3-8.4
	l-2.1-2.2C327.8,338.4,324.5,336.4,320.9,335L320.9,335z M329.6,341.3l1.5,2.2c-5.1-0.5-9.9-1.7-14.6-3c-0.4-0.7-0.4-1.4-0.3-2.2
	C320.5,339.7,325,340.6,329.6,341.3L329.6,341.3z M316.2,345.3c-0.4-0.6-0.5-1.4-0.3-2.2c5.5,1.9,11.3,3.2,17.5,3.7l1.5,2.2
	C328.2,348.5,322,347.2,316.2,345.3L316.2,345.3z"
          />
          <path
            fill="#656565"
            d="M331.1,343.5l-1.5-2.2c-4.7-0.7-9.1-1.7-13.4-3c-0.1,0.8-0.1,1.5,0.3,2.2C321.2,341.9,326,343,331.1,343.5
	L331.1,343.5z M315.8,343.1c-0.1,0.8,0,1.5,0.3,2.2c5.8,1.9,12.1,3.2,18.6,3.7l-1.5-2.2C327.2,346.3,321.3,345,315.8,343.1
	L315.8,343.1z M315.3,342.1l0.3-1.9c0-0.8,0-1.5-0.1-2.1c-0.4-2.3-1.7-4.3-3.7-5.9l-17.2-5.4c-0.1,0.1-0.1,0.4,0,0.8
	c1,5.4,2.2,10.6,3.6,15.7c0.5,2.5,2.2,3.9,5.1,4l6.3,0.5l1.7-0.1c0.7-0.1,1.2-0.4,1.7-1c0.7-0.6,1.2-1.2,1.7-2.1l0.7-1.8
	L315.3,342.1L315.3,342.1z M300.4,340.2c0-1.9,0.7-3.6,2.1-5.1c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1
	c0,1.9-0.7,3.6-2.1,5s-3,2.1-5.1,2.1c-1.9,0-3.6-0.7-5-2.1S300.4,342.1,300.4,340.2L300.4,340.2z"
          />
          <path
            fill="#EBEBEB"
            d="M302.4,335.1c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.6,2.1,5s3,2.1,5,2.1s3.6-0.7,5.1-2.1c1.4-1.4,2.1-3,2.1-5
	s-0.7-3.6-2.1-5.1c-1.4-1.4-3-2.1-5.1-2.1C305.5,333.1,303.8,333.7,302.4,335.1L302.4,335.1z M303.1,344.5c-1.2-1.2-1.8-2.6-1.8-4.3
	c0-1.7,0.5-3.2,1.8-4.4c1.2-1.2,2.6-1.8,4.3-1.8c1.7,0,3.2,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.4s-0.6,3.2-1.8,4.3
	c-1.2,1.2-2.6,1.8-4.3,1.8C305.8,346.3,304.2,345.7,303.1,344.5L303.1,344.5z"
          />
          <path
            fill="#EBEBEB"
            d="M301.3,340.2c0,1.7,0.5,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8c1.7,0,3.2-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3
	c0-1.7-0.6-3.2-1.8-4.4s-2.6-1.8-4.3-1.8c-1.7,0-3.2,0.5-4.3,1.8C301.9,337,301.3,338.4,301.3,340.2L301.3,340.2z M303.7,343.9
	c-1.1-1-1.5-2.2-1.5-3.7s0.6-2.8,1.5-3.7c1-1,2.2-1.5,3.7-1.5s2.8,0.6,3.7,1.5c1,1.1,1.5,2.2,1.5,3.7s-0.5,2.8-1.5,3.7
	c-1.1,1.1-2.2,1.5-3.7,1.5C306,345.5,304.8,344.9,303.7,343.9L303.7,343.9z"
          />
          <path
            fill="#EBEBEB"
            d="M302.2,340.2c0,1.5,0.6,2.8,1.5,3.7c1,1.1,2.2,1.5,3.7,1.5s2.8-0.5,3.7-1.5c1-1,1.5-2.2,1.5-3.7
	s-0.5-2.8-1.5-3.7c-1.1-1-2.2-1.5-3.7-1.5s-2.8,0.6-3.7,1.5C302.7,337.5,302.2,338.7,302.2,340.2L302.2,340.2z M306.6,341
	c-0.3-0.3-0.4-0.6-0.4-0.8c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8
	c0,0.4-0.1,0.7-0.4,0.8c-0.3,0.3-0.5,0.4-0.8,0.4C307.1,341.5,306.9,341.3,306.6,341z"
          />
          <path
            fill="#EBEBEB"
            d="M306.2,340.2c0,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.6,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.6,0.4-0.8
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-0.8,0.4C306.3,339.5,306.2,339.8,306.2,340.2L306.2,340.2z"
          />
          <path
            fill="#656565"
            d="M311.1,347.8l-1.7,0.1l-6.3-0.6c-2.8-0.1-4.5-1.4-5.1-4c-1.4-5.1-2.6-10.3-3.6-15.7c-0.1-0.4-0.1-0.7,0-0.8
	l-0.1-0.1c-0.5-0.1-0.8-0.1-1.1,0c-0.1,0.1-0.1,0.4,0,0.8c1.1,6.2,2.6,12.1,4.3,17.9c0.4,1.4,1.4,2.2,2.9,2.5l18.9,3.2
	c6.2,0.8,12.4,1.5,18.5,1.9h1.7C329.5,351.7,320.1,349.9,311.1,347.8L311.1,347.8z"
          />
          <path
            fill="#FFFFFF"
            d="M318.8,352.5l-16.3-2.8h-16.9c10.7,4,30.5,8.3,59.2,12.5c2.1,0.1,4.1,0.1,6.2,0.1l4.1-0.1h133.2l4.1,0.1
	c2.1,0.1,4,0.1,6.2-0.1c28.8-4.4,48.5-8.5,59.2-12.5h-16.9l-16.3,2.8h-0.1l-4.8,0.7l-39.5,5.6l-2.6,0.4H366.4l-2.6-0.4l-39.5-5.6
	l-4.8-0.7L318.8,352.5L318.8,352.5z M319.1,352.5h-0.3h0.1 M314.2,368.6l7.2,0.3l8.7,1.5l5.4-2.3h-38.7 M370.1,326.7h0.8l-1.1-3.4
	H369"
          />
          <path
            fill="#656565"
            d="M368.7,326.7h1.4l-1.1-3.4h-18.5C354.9,325.6,361,326.7,368.7,326.7L368.7,326.7z"
          />
          <path
            fill="#FFFFFF"
            d="M369.4,321.8h-21.2l2.3,1.5H493l2.3-1.5h-106"
          />
          <path fill="#656565" d="M369.8,323.3l1.1,3.4h19.4l-0.7-3.4" />
          <path fill="#FFFFFF" d="M370.9,326.7l0.4,1.4h1.9l-0.1-1.4" />
          <path fill="#FFFFFF" d="M373.3,328.1h-1.9l3.7,12.7" />
          <path fill="#FFFFFF" d="M373.1,326.7l0.1,1.4h97l0.1-1.4h-80.1" />
          <path fill="#FFFFFF" d="M373.3,328.1l1.8,12.7h93.4l1.8-12.7" />
          <path
            fill="#FFFFFF"
            d="M351.1,343.2c-2.9-2.5-5.5-5.1-8-8.1l6.2,10.2l0.1,0.4c3,5.1,6.3,8.8,10.1,11.3c1.2,0.8,2.6,1.5,4,1.8
	l2.6,0.4h111.3l2.6-0.4c1.4-0.4,2.8-1,4-1.8c3.7-2.5,7-6.2,10.1-11.3l0.1-0.4l6.2-10.2c-2.5,3-5.1,5.6-7.9,8l-0.1,0.1l-2.1,3.4
	c-2.5,3.9-5.4,6.9-8.4,9c-2.1,1.4-4.3,2.2-6.5,2.2H367.9c-2.3-0.1-4.4-0.8-6.5-2.2c-3-2.1-5.8-5.1-8.4-9L351.1,343.2L351.1,343.2z"
          />
          <path
            fill="#FFFFFF"
            d="M351.4,342.4c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3c0,0.1,0,0.3,0.1,0.3l0.1,0.1l2.1,3.4c2.5,3.9,5.4,6.9,8.4,9
	c2.1,1.4,4.3,2.2,6.5,2.2h107.6c2.3-0.1,4.4-0.8,6.5-2.2c3-2.1,5.8-5.1,8.4-9l2.1-3.4l0.1-0.1l0.1-0.3l-0.1-0.3
	c-0.1-0.1-0.3-0.1-0.3-0.1L351.4,342.4L351.4,342.4z M375.6,343.4h115.6l-1.9,3.2v0.1l-1,1.4l-0.1,0.1c-1,1.4-2.1,2.6-3.2,3.6
	l-1.7,1.5l-1.8,1.4c-1.9,1.2-3.9,2.1-5.9,2.1H368.2c-2.1-0.1-4.1-0.8-5.9-2.1l-1.8-1.4l-1.7-1.5c-1.1-1.1-2.2-2.3-3.2-3.6l-0.1-0.1
	l-1-1.4l-0.1-0.1l-1.9-3.2L375.6,343.4L375.6,343.4z"
          />
          <path fill="#FFFFFF" d="M376.4,343.4h-0.8l1.1,3.3h0.8" />
          <path
            fill="#FFFFFF"
            d="M355.2,348.1l0.1,0.1H488l0.1-0.1l1-1.4H354.3"
          />
          <path
            fill="#656565"
            d="M354.3,346.6v0.1h22.5l-1.1-3.3h-23.3 M362.1,354.8c1.9,1.2,3.9,1.9,5.9,2.1h12l-1.1-3.4h-18.6L362.1,354.8
	L362.1,354.8z"
          />
          <path fill="#FFFFFF" d="M358.5,351.9l1.7,1.5h123.2l1.5-1.5H378.4" />
          <path
            fill="#656565"
            d="M355.4,348.2c1,1.4,2.1,2.6,3.2,3.6h19.7l-1.1-3.6H355.4L355.4,348.2z"
          />
          <path
            fill="#FFFFFF"
            d="M467.9,343.4h-0.8l-1.1,3.3h0.8 M466.3,348.2h-0.8l-1.1,3.7h0.8 M462.7,356.9h0.8l1.1-3.4h-0.8"
          />
          <path
            fill="#656565"
            d="M464.6,353.4l-1.1,3.4h12c2.1-0.1,4.1-0.8,5.9-2.1l1.8-1.4H464.6L464.6,353.4z M462.7,356.9l1.1-3.4h-16
	l-0.7,3.4 M464.4,351.9l1.1-3.7h-16.7l-0.7,3.7 M466.3,348.2l-1.1,3.6h19.7c1.1-1.1,2.2-2.3,3.2-3.6H466.3L466.3,348.2z
	 M489.1,346.7l0.1-0.1l1.9-3.2h-23.3l-1.1,3.3 M466,346.7l1.1-3.3h-17.4l-0.7,3.3"
          />
          <path
            fill="#FFFFFF"
            d="M412.7,343.4h-1l0.1,3.3h1 M394.6,343.4h-0.8l0.6,3.3h0.8"
          />
          <path fill="#656565" d="M395.6,348.2l0.7,3.7h15.8l-0.1-3.7" />
          <path fill="#FFFFFF" d="M396.3,351.9l-0.7-3.7h-1l0.8,3.7" />
          <path fill="#656565" d="M395.5,351.9l-0.8-3.7H378l1.2,3.7" />
          <path fill="#FFFFFF" d="M396.6,353.4h-0.8l0.7,3.4h0.8" />
          <path fill="#656565" d="M396.4,356.9l-0.7-3.4h-16l1,3.4" />
          <path
            fill="#FFFFFF"
            d="M378.4,351.9h0.8l-1.2-3.7h-0.8 M379.9,356.9h0.8l-1-3.4h-1"
          />
          <path fill="#656565" d="M377.5,346.7h16.8l-0.6-3.3h-17.4" />
          <path fill="#FFFFFF" d="M412.4,356.9h0.8l-0.1-3.4h-0.8" />
          <path
            fill="#656565"
            d="M412.4,356.9l-0.1-3.4h-15.7l0.7,3.4 M413.1,353.4l0.1,3.4h16.9l0.3-3.4"
          />
          <path fill="#FFFFFF" d="M412,348.2l0.1,3.7h0.8l-0.1-3.7" />
          <path
            fill="#656565"
            d="M412.8,348.2l0.1,3.7h17.5l0.1-3.7 M411.7,343.4h-17.1l0.5,3.3h16.7 M412.7,343.4l0.1,3.3h17.9l0.1-3.3"
          />
          <path
            fill="#FFFFFF"
            d="M449.7,343.4h-0.8l-0.7,3.3h0.8 M431.8,343.4h-1l-0.1,3.3h0.8 M431.6,348.2h-1l-0.1,3.7h0.8 M430.2,356.9h1
	l0.1-3.4h-0.8"
          />
          <path
            fill="#656565"
            d="M431.3,353.4l-0.1,3.4h15.2l0.5-3.4 M431.6,348.2l-0.3,3.7h16l0.7-3.7"
          />
          <path
            fill="#FFFFFF"
            d="M448.8,348.2H448l-0.7,3.7h0.8 M446.3,356.9h0.8l0.7-3.4h-1"
          />
          <path
            fill="#656565"
            d="M448.9,343.4h-17.1l-0.3,3.3h16.7 M356.9,365.7c-1.9,0.1-3.6,0.4-5.2,0.8l-3.2,1c-1.2,0.4-2.9,0.7-4.8,0.7
	h-8.3l-5.4,2.3l5.4,1h172.8l5.4-1l-5.4-2.3h-8.3c-1.9,0-3.6-0.1-5-0.7l-3.2-1c-1.5-0.4-3.3-0.7-5.2-0.8L356.9,365.7L356.9,365.7z"
          />
          <path
            fill="#FFFFFF"
            d="M351.6,366.5c1.5-0.4,3.3-0.7,5.2-0.8h129.7c1.9,0.1,3.6,0.4,5.2,0.8l3.2,1c1.4,0.4,2.9,0.7,5,0.7h47
	c0.8-0.5,1.8-1.2,2.5-1.9c4.4-3.9,7.2-9.2,8.5-16.4c-10.7,4-30.5,8.3-59.2,12.5c-2.1,0.1-4.1,0.1-6.2,0.1l-4.1-0.1H355.1l-4.1,0.1
	c-1.9,0.1-4,0.1-6.2-0.1c-28.8-4.4-48.5-8.5-59.2-12.5c1.2,7.2,4.1,12.5,8.5,16.4l2.5,1.9h47c1.9,0,3.6-0.1,4.8-0.7L351.6,366.5
	L351.6,366.5z"
          />
          <path fill="#656565" d="M367.9,317.2l1.5,4.5h20l-0.8-4.5" />
          <path
            fill="#FFFFFF"
            d="M282.7,278.5c-0.7-3-1.5-4.3-2.2-3.4c-0.5,0.7-1.1,1.7-1.4,2.8c-0.8,2.5-1.5,5.2-2.1,8l-0.1,0.3
	c-0.3,1.7-0.5,3.3-0.8,5.1l1.1,6.9l6.8-12.1L282.7,278.5L282.7,278.5z"
          />
          <path
            fill="#656565"
            d="M277,301.8v3.3c0,8.8,0.5,17.5,1.7,25.9l0.4,3.2l0.1-20.9L277,301.8L277,301.8z"
          />
          <path
            fill="#656565"
            d="M279,334.2v67.5c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.3-2.3,7.3-7.2v-33.1l-17.5-0.5l-2.5-1.9
	c-4.4-3.9-7.2-9.2-8.5-16.4l-2.6-15.3l-3.9-21.2L279,334.2L279,334.2z"
          />
          <path
            fill="#FFFFFF"
            d="M275.2,339.5c-0.4,2.1-0.1,3.6,0.5,4.8l3.2,5.1v-5.2c-0.8-4.5-1.7-9.1-2.2-13.6L275.2,339.5L275.2,339.5z
	 M566.2,274.6l-0.1,3.3c0,2.6,0.1,5.2,0.5,7.9l0.1,0.3l0.8,5.1l0.7,6.8v0.7l0.5-7.6c0.3-2.2,0.1-4.3-0.4-6.6L566.2,274.6
	L566.2,274.6z"
          />
          <path
            fill="#FFFFFF"
            d="M564.4,277.8c-0.4-1.1-0.8-2.1-1.4-2.8c-0.8-0.8-1.7,0.4-2.3,3.4l-1.1,7.6l6.8,12.1l1.1-6.9l-0.8-5.1
	l-0.1-0.3C566,282.9,565.3,280.3,564.4,277.8L564.4,277.8z M548.1,279.2l0.3-1.2c-0.4,0.7-0.8,1.5-1.4,2.2
	c-2.2,2.9-5.9,5.4-11.2,7.4c-7,2.8-14.7,5.4-23.3,7.6c-4.5,1.2-8.1,1.5-10.5,1.1c-3.6-1.1-6.5-2.6-9-4.5l-0.5-0.4
	c-0.6-0.4-1.1-1-1.5-1.5c-0.8-1-0.8-1.9,0-2.9l2.5-2.2l-8.3-2.6l-0.7,1.5c-0.8,2.5,0,5,2.3,7c1.1,1,2.2,2.1,3.6,3.2l5.1,3.6
	c4.1,2.8,9.8,3.2,16.8,1.7c8.5-2.2,16.3-4.8,23.3-7.6C542.5,288.8,546.5,284.7,548.1,279.2L548.1,279.2z"
          />
          <path
            fill="#656565"
            d="M566.5,301.8l-2.1,11.4l0.1,20.9l0.4-3.2c1.1-8.4,1.5-17.1,1.7-25.9v-3.3H566.5L566.5,301.8z"
          />
          <path
            fill="#FFFFFF"
            d="M564.4,313.2l2.1-11.4l0.7-4.1c0.4-1.2,0.7-1.2,0.8,0.3l-0.7-6.8l-1.1,6.9l-5.8,36.4L564.4,313.2
	L564.4,313.2z"
          />
          <path
            fill="#FFFFFF"
            d="M567.3,297.6l-0.7,4.1v3.3c0,8.8-0.5,17.5-1.7,25.9l-0.4,3.2v10.2c0.8-4.5,1.7-9.1,2.2-13.6
	c1.1-8.4,1.5-16.9,1.7-25.9l-0.1-6.2v-0.7C568,296.4,567.6,296.3,567.3,297.6L567.3,297.6z"
          />
          <path
            fill="#FFFFFF"
            d="M568.2,339.5l-1.7-8.8c-0.5,4.5-1.2,9.1-2.2,13.6v5.2l3.2-5.1C568.4,343.1,568.7,341.5,568.2,339.5
	L568.2,339.5z"
          />
          <path
            fill="#656565"
            d="M564.5,344.4v-10.2l-0.1-20.9l-3.9,21.2l-2.6,15.3c-1.2,7.2-4.1,12.5-8.5,16.4c-0.8,0.7-1.7,1.4-2.5,1.9
	l-17.5,0.6v33.1c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.4-2.3,7.4-7.2L564.5,344.4L564.5,344.4z"
          />
          <path
            fill="#656565"
            d="M551.4,327.8c0.3-1.1,0.1-1.8-0.5-2.2c-0.4-0.4-1.2-0.5-2.3-0.3h-0.1l-26.5,8.3c-4.1,1.5-8,4.1-11.4,7.9
	l-0.1,0.1c-3,3.2-5.5,6.3-7.4,9.6c-0.8,1.2-0.8,2.1-0.1,2.6c0.4,0.5,1.5,0.7,3,0.5l13.6-1.2l4.8-0.7h0.1l16.3-2.8l2.3-0.4
	c2.2-0.4,3.4-1.5,4-3.4C548.8,340.2,550.2,334.2,551.4,327.8L551.4,327.8z M550.3,326.7c0.1,0.1,0.1,0.4,0,0.8
	c-1.1,6.2-2.6,12.1-4.3,17.9c-0.4,1.4-1.4,2.2-2.9,2.5l-18.9,3.2c-6.3,0.8-12.4,1.5-18.5,1.9h-1.7l-0.4-0.1c0-0.1,0-0.4,0.3-0.8
	c1.7-2.8,3.9-5.6,6.3-8.4l2.1-2.2c3-3,6.3-5.1,9.9-6.5l9.2-2.9l17.2-5.4l0.1-0.1C549.8,326.6,550.1,326.6,550.3,326.7L550.3,326.7z"
          />
          <path
            fill="#656565"
            d="M549.1,326.7c0.1,0.1,0.1,0.4,0,0.8c-1,5.4-2.2,10.6-3.6,15.7c-0.5,2.5-2.2,3.9-5.1,4l-6.3,0.5l-1.7-0.1
	c-9,2.1-18.3,3.9-28.2,5.4h1.6c6.1-0.4,12.3-1.1,18.5-1.9l18.9-3.2c1.5-0.3,2.5-1.1,2.9-2.5c1.7-5.8,3.2-11.9,4.3-17.9
	c0.1-0.4,0-0.7,0-0.8c-0.3-0.1-0.5-0.1-1.1,0L549.1,326.7L549.1,326.7z"
          />
          <path
            fill="#656565"
            d="M549.1,327.5c0.1-0.4,0.1-0.7,0-0.8l-17.2,5.4c-2.1,1.7-3.4,3.6-3.7,5.9c-0.1,0.7-0.1,1.4-0.1,2.1
	c0,0.5,0.1,1.2,0.3,1.9l0.3,0.8c0.1,0.7,0.4,1.2,0.7,1.8c0.4,0.8,1,1.5,1.7,2.1c0.6,0.4,1.1,0.7,1.7,1l1.7,0.1l6.3-0.5
	c2.8-0.1,4.5-1.4,5.1-4C546.9,338.3,548,333.1,549.1,327.5L549.1,327.5z M543.2,340.2c0,1.9-0.7,3.6-2.1,5c-1.4,1.4-3,2.1-5,2.1
	s-3.6-0.7-5.1-2.1c-1.4-1.4-2.1-3-2.1-5s0.7-3.6,2.1-5.1c1.4-1.4,3-2.1,5.1-2.1c1.9,0,3.6,0.7,5,2.1
	C542.5,336.5,543.2,338.3,543.2,340.2L543.2,340.2z"
          />
          <path
            fill="#EBEBEB"
            d="M541.1,345.2c1.4-1.4,2.1-3,2.1-5s-0.7-3.6-2.1-5.1c-1.4-1.4-3-2.1-5-2.1s-3.6,0.7-5.1,2.1
	c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5.1,2.1C538.1,347.2,539.7,346.6,541.1,345.2L541.1,345.2z M540.4,335.8
	c1.2,1.2,1.8,2.6,1.8,4.4c0,1.7-0.6,3.2-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8s-3.2-0.5-4.4-1.8c-1.2-1.2-1.8-2.6-1.8-4.3
	c0-1.7,0.6-3.2,1.8-4.4c1.2-1.2,2.6-1.8,4.4-1.8C537.8,334,539.2,334.7,540.4,335.8L540.4,335.8z"
          />
          <path
            fill="#EBEBEB"
            d="M542.2,340.2c0-1.7-0.6-3.2-1.8-4.4s-2.6-1.8-4.3-1.8s-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4
	c0,1.7,0.6,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.4,1.8c1.7,0,3.2-0.5,4.3-1.8C541.6,343.2,542.2,341.9,542.2,340.2L542.2,340.2z
	 M539.7,336.5c1.1,1.1,1.5,2.2,1.5,3.7s-0.5,2.8-1.5,3.7c-1,1.1-2.2,1.5-3.7,1.5s-2.8-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7
	s0.5-2.8,1.5-3.7c1.1-1,2.2-1.5,3.7-1.5S538.8,335.4,539.7,336.5z"
          />
          <path
            fill="#EBEBEB"
            d="M541.4,340.2c0-1.5-0.5-2.8-1.5-3.7c-1-1-2.2-1.5-3.7-1.5s-2.8,0.6-3.7,1.5c-1,1.1-1.5,2.2-1.5,3.7
	s0.5,2.8,1.5,3.7c1.1,1.1,2.2,1.5,3.7,1.5s2.8-0.5,3.7-1.5C540.8,342.8,541.4,341.6,541.4,340.2L541.4,340.2z M536,341.5
	c-0.3,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.6-0.4-0.8c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4
	c0.3,0.3,0.4,0.6,0.4,0.8c0,0.4-0.1,0.7-0.4,0.8C536.7,341.3,536.4,341.5,536,341.5L536,341.5z"
          />
          <path
            fill="#EBEBEB"
            d="M535.2,341c0.3,0.3,0.6,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.6,0.4-0.8c0-0.4-0.1-0.7-0.4-0.8
	c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,0.8S534.9,340.8,535.2,341L535.2,341z"
          />
          <path
            fill="#656565"
            d="M522.5,335c-3.6,1.2-6.9,3.4-9.9,6.5l-2.1,2.2c-2.5,2.8-4.7,5.5-6.3,8.4c-0.3,0.4-0.4,0.7-0.3,0.8l0.4,0.1
	c9.9-1.5,19.3-3.3,28.2-5.4c-0.7-0.1-1.2-0.4-1.7-1c-0.7-0.5-1.2-1.2-1.7-2.1c-0.3-0.5-0.6-1.1-0.7-1.8l-0.3-0.8
	c-0.1-0.7-0.3-1.4-0.3-1.9c0-0.8,0-1.5,0.1-2.1c0.4-2.3,1.7-4.3,3.7-5.9L522.5,335L522.5,335z M527.7,343.1c0.1,0.8,0,1.5-0.3,2.2
	c-5.8,1.9-12.1,3.2-18.7,3.7l1.5-2.2C516.3,346.3,522.2,345,527.7,343.1L527.7,343.1z M527.2,338.3c0.1,0.8,0.1,1.5-0.3,2.2
	c-4.7,1.5-9.5,2.5-14.6,3l1.5-2.2C518.5,340.6,522.9,339.7,527.2,338.3L527.2,338.3z"
          />
          <path
            fill="#656565"
            d="M527.3,345.3c0.4-0.6,0.5-1.4,0.3-2.2c-5.5,1.9-11.3,3.2-17.5,3.7l-1.5,2.2
	C515.3,348.5,521.5,347.2,527.3,345.3L527.3,345.3z M526.9,340.5c0.4-0.7,0.4-1.4,0.3-2.2c-4.3,1.4-8.7,2.3-13.4,3l-1.5,2.2
	C517.4,343,522.2,341.9,526.9,340.5L526.9,340.5z M499.2,315.7c-0.1-0.4-0.3-0.8-0.7-1.2c-1.2-1.4-2.5-2.6-3.9-3.9h-16.3l-1.7,5.1
	L499.2,315.7L499.2,315.7z M499.1,317.8l0.3-0.4h-22.9l-1.5,4.5h20.4C496.9,320.6,498.1,319.3,499.1,317.8L499.1,317.8z"
          />
          <path fill="#FFFFFF" d="M459.5,294.2h-0.8l-0.6,2.8h0.8" />
          <path
            fill="#656565"
            d="M461.6,294.3l-2.1-0.1L459,297h15.6C470.7,295.4,466.3,294.6,461.6,294.3L461.6,294.3z"
          />
          <path
            fill="#FFFFFF"
            d="M476.5,317.2h-1l-1.4,4.5h0.8 M476.1,315.7h0.8l1.7-5h-0.8 M458.7,298.3h-0.8l-1,5h0.8"
          />
          <path
            fill="#656565"
            d="M458.7,298.3l-1,4.8h22.5l1.1-3.2c-1-0.5-2.1-1.1-3-1.7L458.7,298.3L458.7,298.3z"
          />
          <path
            fill="#FFFFFF"
            d="M481.8,300.4l-0.7-0.4l-1,3.3h0.8l1-2.9 M480.6,304.7h-1l-1.4,4.5h0.8"
          />
          <path
            fill="#656565"
            d="M486,303.3l-4-2.9l-1,2.9 M493.3,309.2c-1.8-1.7-3.6-3.2-5.4-4.5h-7.3l-1.5,4.5H493.3L493.3,309.2z
	 M478.3,309.2l1.4-4.5h-22.2l-0.8,4.5 M476.1,315.7l1.7-5h-21.4l-1.1,5 M458.1,297l0.5-2.8l-24.5-1L434,297"
          />
          <path fill="#FFFFFF" d="M473.6,323.3l-1,3.4h0.8l1.1-3.4" />
          <path
            fill="#656565"
            d="M472.6,326.7l1-3.4h-19.8l-0.5,3.4h17.2 M473.4,326.7h1.4c7.7,0,13.8-1.1,18.2-3.4h-18.5L473.4,326.7
	L473.4,326.7z"
          />
          <path fill="#FFFFFF" d="M472.6,326.7h-2.2l-0.1,1.4h1.9" />
          <path
            fill="#FFFFFF"
            d="M472.2,328.1h-1.9l-1.8,12.7 M522.1,368.9l7.3-0.3l17.5-0.5h-38.7l5.4,2.3 M524.4,352.5h0.1H524.4z"
          />
          <path fill="#656565" d="M474.1,321.8l1.4-4.5H455l-1,4.5" />
          <path
            fill="#FFFFFF"
            d="M434.2,293.2h-0.8l-0.1,3.7h0.8 M432.8,303.3h1l0.1-5h-0.8 M433.6,304.7h-0.8l-0.3,4.5h1"
          />
          <path
            fill="#656565"
            d="M433.9,298.3l-0.1,5h23.1l1-5 M432.8,303.3l0.3-5h-22.6l0.1,5"
          />
          <path
            fill="#FFFFFF"
            d="M432.2,315.7h0.8l0.3-5h-0.8 M433.1,317.2h-0.8l-0.3,4.5h0.8"
          />
          <path
            fill="#656565"
            d="M433.3,310.7l-0.3,5h21.4l1.1-5 M432.2,315.7l0.3-5H411l0.3,5 M432.5,309.2l0.3-4.5h-22l0.1,4.5
	 M433.6,304.7l-0.1,4.5h22.2l1-4.5"
          />
          <path
            fill="#FFFFFF"
            d="M456.4,310.7h-0.8l-1.1,5h0.8 M455,317.2h-0.8l-1,4.5h0.8 M455.7,309.2h1l0.8-4.5h-0.8"
          />
          <path
            fill="#656565"
            d="M454.2,317.2h-21.1l-0.3,4.5h20.4 M433.2,297l0.1-3.7h-23.1l0.1,3.7"
          />
          <path fill="#FFFFFF" d="M410.2,293.2h-1l0.3,3.7h0.8" />
          <path
            fill="#656565"
            d="M383.9,294.2l-2.1,0.1c-4.7,0.3-9.1,1.1-13.1,2.6h15.7L383.9,294.2L383.9,294.2z"
          />
          <path
            fill="#FFFFFF"
            d="M384.8,294.2h-1l0.5,2.8h0.8 M385.5,298.3h-0.8l1.1,5h0.8 M386.9,304.7h-1l1,4.5h0.8"
          />
          <path fill="#656565" d="M385.5,298.3l1.1,5h23.1l-0.3-5" />
          <path fill="#FFFFFF" d="M389.1,315.7l-1.1-5h-0.8l1,5" />
          <path fill="#656565" d="M388,310.7l1.1,5h21.4l-0.3-5" />
          <path fill="#FFFFFF" d="M389.4,317.2h-0.8l0.8,4.5h0.8" />
          <path fill="#656565" d="M386.9,304.7l0.8,4.5h22.3l-0.1-4.5" />
          <path
            fill="#FFFFFF"
            d="M410.6,303.3l-0.1-5h-1l0.3,5 M410.8,304.7h-0.8l0.1,4.5h0.8 M410.5,315.7h0.8l-0.3-5h-0.8 M411.3,317.2
	h-0.8l0.3,4.5h0.8"
          />
          <path
            fill="#656565"
            d="M409.2,293.2l-24.4,1l0.4,2.8h24.2 M410.5,317.2h-21.1l0.8,4.5h20.5 M411.6,323.3l0.3,3.4h19.8l0.1-3.4"
          />
          <path fill="#FFFFFF" d="M411.9,326.7l-0.3-3.4h-0.8l0.1,3.4" />
          <path fill="#656565" d="M410.9,326.7l-0.1-3.4h-20.1l0.6,3.4" />
          <path
            fill="#FFFFFF"
            d="M389.7,323.3l0.7,3.4h0.8l-0.6-3.4 M453.2,326.7l0.5-3.4h-0.8l-0.5,3.4"
          />
          <path fill="#656565" d="M452.4,326.7l0.6-3.4h-20.3l-0.1,3.4" />
          <path fill="#FFFFFF" d="M431.8,323.3l-0.1,3.4h0.8l0.1-3.4" />
          <path fill="#656565" d="M432.2,317.2h-20.9l0.3,4.5H432" />
          <path
            fill="#FFFFFF"
            d="M528.3,176.5c2.2,5.6,4.4,11.6,6.5,17.8l3.7,11.7l2.2,5.5c1.9,4.8,4,9.8,5.8,14.7l1.1,3.3
	c0.4,1.2,0.7,2.5,1,3.7l3.3-1.4l-0.5-2.3l-1-2.9l-0.1-0.1l-5.5-15c-5-12.1-10.3-23.8-15.8-35l-2.3-4.5L528.3,176.5L528.3,176.5z"
          />
          <path
            fill="#FFFFFF"
            d="M538.6,206l3.6,12.3l1.7,4.7l2.9,3.4c-1.9-5-3.9-9.9-5.8-14.7L538.6,206L538.6,206z"
          />
          <path
            fill="#FFFFFF"
            d="M548.8,233.3c-0.3-1.2-0.5-2.5-1-3.7l-1.1-3.3l-2.9-3.4l5.8,16.5C549.5,237.6,549.2,235.5,548.8,233.3
	L548.8,233.3z M292.9,226.5v0.1l-0.3,0.6l-0.7,2.3l-0.5,2.3l3.3,1.4c0.3-1.2,0.6-2.5,1-3.7l1.1-3.3c1.9-5,3.9-9.9,5.8-14.7l2.2-5.5
	l3.7-11.7c2.2-6.2,4.3-12.3,6.5-17.8l1.8-4.5l-2.3,4.5c-5.5,11.2-10.9,22.9-15.8,35C296.8,216.5,294.9,221.4,292.9,226.5
	L292.9,226.5z"
          />
          <path
            fill="#FFFFFF"
            d="M295.7,229.6c-0.4,1.2-0.7,2.5-1,3.7c-0.4,2.1-0.7,4.1-0.7,6.2l5.8-16.5l-2.9,3.4L295.7,229.6L295.7,229.6z
	 M301.3,218.3c1.2-4.3,2.3-8.3,3.6-12.3l-2.2,5.5c-2.1,4.8-4,9.8-5.8,14.7l2.9-3.4L301.3,218.3L301.3,218.3z M277.5,277.9l-0.1-3.3
	l-1.9,9.9c-0.5,2.2-0.7,4.4-0.4,6.6l0.5,7.6v-0.8l0.6-6.6c0.3-1.8,0.5-3.4,0.8-5.1l0.1-0.3C277.4,283.2,277.5,280.6,277.5,277.9
	L277.5,277.9z"
          />
          <path
            fill="#FFFFFF"
            d="M275.4,297.8v1.5c-0.1,1.8-0.1,3.6-0.1,5.5c0,8.8,0.5,17.5,1.7,25.9c0.5,4.5,1.2,9.1,2.2,13.6v-10.2
	l-0.4-3.2c-1.1-8.4-1.5-17.1-1.7-25.9v-3.3l-0.7-4.1C275.9,296.4,275.6,296.4,275.4,297.8L275.4,297.8z"
          />
          <path
            fill="#FFFFFF"
            d="M276.3,297.6l0.7,4.1l2.1,11.4l3.9,21.2l-5.8-36.4l-1.1-6.9l-0.5,6.6C275.6,296.4,275.9,296.4,276.3,297.6
	L276.3,297.6z"
          />
          <path
            fill="none"
            d="M519.3,269l-0.4,1.5c-0.4,1.2-0.7,2.6-0.7,4.1c0,3.3,1.1,6.2,3.4,8.5c0.8,0.8,1.7,1.5,2.6,2.1l1.2,0.5
	 M519.3,269c-0.4,0.3-0.7,0.4-1.1,0.7c-4.4,2.8-9.1,6.2-14.5,10.3"
          />
          <path
            fill="none"
            d="M521.9,267.5c-0.6,0.5-1,1.1-1.2,1.8l-0.1,0.3c-0.8,1.5-1.2,3.3-1.2,5.1c0,3,1.1,5.5,3.2,7.6
	c1,1,2.2,1.8,3.4,2.3l1.5,0.5"
          />
          <path
            fill="none"
            d="M543.9,281.1c-1.1,1-2.3,1.9-3.7,2.8l-5,2.3l-3.7,1.5c-6.1,2.2-12.5,4.3-19.6,6.1c-2.6,0.7-5,1.1-6.8,1.2
	c-1.7,0.1-3.3,0-4.8-0.5c-2.6-1.2-5-2.6-7-4.3c-0.6-0.4-0.8-0.7-1.1-1.1c-0.4-0.7-0.3-1.4,0.6-2.1c10.5-8.5,19.4-15,26.5-19.3
	l2.1-1.2c1.2-0.6,2.3-1.1,3.6-1.5c1.7-0.5,3.2-1.1,4.8-1.2l6.8-0.5c2.8-0.1,5.1-0.7,7-1.7c1.4-0.7,2.5-1.5,3.3-2.6"
          />
          <path
            fill="none"
            d="M521.9,267.5c-0.4,0.1-0.8,0.4-1.2,0.5l-1.5,0.8 M541.6,273.5c-0.1-1.1-0.5-2.1-1.2-2.9l-4-4.3
	c-0.8-0.6-1.7-1-2.6-1.2 M529.8,267.5c-2.2,0-4,0.8-5.5,2.3s-2.2,3.4-2.2,5.5c0,2.2,0.8,4,2.2,5.5c1.5,1.5,3.3,2.3,5.5,2.3h0.1
	c2.2,0,4-0.8,5.5-2.3s2.3-3.3,2.3-5.5s-0.8-4-2.3-5.5C533.8,268.3,531.9,267.5,529.8,267.5L529.8,267.5z"
          />
          <path
            fill="none"
            d="M529.8,268.4c-1.9,0-3.6,0.7-5,2.1c-1.4,1.4-2.1,3-2.1,5c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5,2.1
	s3.6-0.7,5-2.1c1.4-1.4,2.1-3,2.1-5s-0.7-3.6-2.1-5C533.4,269.1,531.7,268.4,529.8,268.4L529.8,268.4z M533.8,265.1l-1.9-0.3
	c-1.5-0.1-2.9,0.1-4.5,0.4c-1.8,0.5-3.6,1.2-5.4,2.1 M377.1,348.2l1.2,3.7 M376.4,343.4l1.1,3.3 M295.7,271.9c0.3,3.7,1.5,6.8,4,9.2
	 M295.7,271.9c0.1,2.8,0.4,5.5,0.8,8.3 M543.9,281.1c2.5-2.5,3.7-5.5,4-9.2 M547.8,271.9c-0.1,2.8-0.4,5.5-0.8,8.3 M546.3,257.1
	l0.4,1.9"
          />
          <path
            fill="none"
            d="M541.6,273.5c-0.1-1.1-0.3-2.1-0.5-3.3l4.3-2.2l-1-3.9l-6.5,2.5l-0.5-0.3c-1.1-0.5-2.3-1-3.6-1.2 M546.7,259.1
	l0.7,3.9c0.3,1.9,0.4,3.9,0.4,5.8 M547.8,271.9v-3"
          />
          <path
            fill="none"
            d="M547.8,268.8l-5.1,2.8l0.3,1.7v0.7l0.1,0.8c0.3,2.2,0.4,4.3,0.5,6.3 M457.5,304.7l-0.8,4.5 M456.9,303.3l1-5
	 M410.8,321.8l-0.3-4.5 M389.4,321.8l-0.8-4.5 M390.2,321.8l-0.8-4.5 M411.6,321.8l-0.3-4.5 M432.8,321.8l0.3-4.5 M432,321.8
	l0.3-4.5 M453.2,321.8l1-4.5 M454,321.8l1-4.5 M431.6,346.7l0.3-3.3 M430.7,346.7l0.1-3.3 M448.2,346.7l0.7-3.3 M449.1,346.7
	l0.7-3.3 M447.1,356.9l0.7-3.4 M446.9,353.4l-0.6,3.4 M447.3,351.9l0.7-3.7 M448.1,351.9l0.7-3.7 M431.3,351.9l0.3-3.7 M430.5,351.9
	l0.1-3.7 M430.5,353.4l-0.3,3.4 M431.1,356.9l0.1-3.4 M432.5,326.7l0.1-3.4 M431.8,323.3l-0.1,3.4 M452.4,326.7l0.6-3.4
	 M453.7,323.3l-0.5,3.4 M412.8,346.7l-0.1-3.3 M411.9,346.7l-0.1-3.3 M395.2,346.7l-0.5-3.3 M394.4,346.7l-0.6-3.3 M396.6,353.4
	l0.7,3.4 M413,351.9l-0.1-3.7 M412.1,351.9l-0.1-3.7 M413.2,356.9l-0.1-3.4 M412.4,356.9l-0.1-3.4 M379.7,353.4l1,3.4 M379.2,351.9
	l-1.2-3.7 M378.8,353.4l1.1,3.4 M396.4,356.9l-0.7-3.4 M395.5,351.9l-0.8-3.7 M396.3,351.9l-0.7-3.7 M390.6,323.3l0.6,3.4
	 M389.7,323.3l0.7,3.4 M410.9,326.7l-0.1-3.4 M411.9,326.7l-0.3-3.4 M409.5,297l-0.3-3.7 M384.4,297l-0.5-2.8 M410.2,293.2l0.1,3.7
	 M384.8,294.2l0.4,2.8 M410.8,304.7l0.1,4.5 M409.9,304.7l0.1,4.5 M411,310.7l0.3,5 M410.5,315.7l-0.3-5 M409.8,303.3l-0.3-5
	 M410.5,298.3l0.1,5 M387.7,309.2l-0.8-4.5 M385.9,304.7l1,4.5 M388.1,315.7l-1-5 M388,310.7l1.1,5 M385.5,298.3l1.1,5 M385.8,303.3
	l-1.1-5 M433.3,293.2l-0.1,3.7 M434.2,293.2L434,297 M456.4,310.7l-1.1,5 M454.4,315.7l1.1-5 M455.7,309.2l1-4.5 M433.6,304.7
	l-0.1,4.5 M432.5,309.2l0.3-4.5 M432.2,315.7l0.3-5 M433.3,310.7l-0.3,5 M432.8,303.3l0.3-5 M433.8,303.3l0.1-5 M474.1,321.8
	l1.4-4.5 M476.5,317.2l-1.5,4.5 M536,341.5c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.6,0.4-0.8c0-0.4-0.1-0.7-0.4-0.8
	c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.6-0.4,0.8c0,0.4,0.1,0.7,0.4,0.8
	C535.4,341.3,535.7,341.5,536,341.5L536,341.5z M297.2,257.1l-0.4,1.9 M466,346.7l1.1-3.3 M467.9,343.4l-1.1,3.3 M465.2,351.9
	l1.1-3.7 M464.4,351.9l1.1-3.7 M462.7,356.9l1.1-3.4 M464.6,353.4l-1.1,3.4 M296.8,259.1l-0.7,3.9c-0.3,1.9-0.4,3.9-0.4,5.8
	 M473.4,326.7l1.1-3.4 M295.7,268.8v3 M472.6,326.7l1-3.4 M458.7,294.2l-0.6,2.8 M476.1,315.7l1.7-5 M459.5,294.2L459,297
	 M478.5,310.7l-1.7,5 M478.3,309.2l1.4-4.5 M480.6,304.7l-1.5,4.5 M482,300.4l-1,2.9 M481.2,300l-1,3.3 M457.7,303.3l1-5
	 M501.1,289.4c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.4-0.5,0.4-0.8s-0.1-0.5-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4s-0.5,0.1-0.8,0.4
	c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8C500.6,289.2,500.9,289.4,501.1,289.4L501.1,289.4z"
          />
          <path
            fill="none"
            d="M501.8,281.7l-7.6,6.5c-0.3,0.3-0.4,0.4-0.4,0.7l0.1,0.3l0.3,0.3c1.7,1.2,3.4,2.5,5.5,3.4
	c1.4,0.7,2.8,1,4.1,0.8c1.5-0.3,2.5-0.8,2.9-1.9c0.3-1,0.3-2.1,0-3.2l-2.3-6.2C503.6,281.2,502.8,281,501.8,281.7L501.8,281.7z
	 M503.8,280l-1.9,1.7 M514,281.9v-0.3l-0.3-0.4c-0.1-0.1-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7
	c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3s0.4-0.1,0.7-0.3l0.3-0.4V281.9L514,281.9z M525.5,285.8l1.7-0.7"
          />
          <path
            fill="none"
            d="M503.8,280l4,10.9l0.8-0.1c5.4-1.2,10.6-2.8,15.6-4.4l1.2-0.4"
          />
          <path
            fill="none"
            d="M521,283.9c0.7,0.7,1.4,1.2,2.1,1.8c-4.8,1.7-9.8,3-14.7,4.1l-3.4-9.2c4.7-3.6,9-6.8,12.8-9.2
	c-0.3,1.1-0.4,2.2-0.4,3.4C517.3,278.4,518.5,281.4,521,283.9L521,283.9z M540.4,335.8c-1.2-1.2-2.6-1.8-4.3-1.8s-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4c0,1.7,0.6,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.4,1.8c1.7,0,3.2-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3
	C542.2,338.4,541.6,337,540.4,335.8L540.4,335.8z"
          />
          <path
            fill="none"
            d="M539.7,336.5c-1-1-2.2-1.5-3.7-1.5s-2.8,0.6-3.7,1.5c-1,1.1-1.5,2.2-1.5,3.7s0.5,2.8,1.5,3.7
	c1.1,1.1,2.2,1.5,3.7,1.5s2.8-0.5,3.7-1.5c1.1-1,1.5-2.2,1.5-3.7C541.4,338.7,540.8,337.5,539.7,336.5z M301.9,273.5
	c0.1-1.1,0.3-2.1,0.5-3.3l-4.3-2.2l1-3.9l6.5,2.5l0.5-0.3c1.1-0.5,2.3-1,3.6-1.2"
          />
          <path
            fill="none"
            d="M299.6,281.1c1.1,1,2.2,1.9,3.7,2.8l1.9,1c1,0.4,2.1,1,3.2,1.4l3.7,1.5c5.9,2.2,12.5,4.3,19.6,6.1
	c2.6,0.7,5,1.1,6.8,1.2c1.7,0.1,3.3,0,4.8-0.5c2.6-1.2,5-2.6,7-4.3c0.5-0.4,0.8-0.7,1.1-1.1c0.4-0.7,0.3-1.4-0.5-2.1
	c-10.5-8.5-19.4-15-26.5-19.3l-2.1-1.2c-1.2-0.6-2.3-1.1-3.6-1.5c-1.7-0.5-3.2-1.1-4.8-1.2l-6.8-0.5c-2.8-0.1-5.1-0.7-7-1.7
	c-1.4-0.7-2.5-1.5-3.3-2.6"
          />
          <path
            fill="none"
            d="M309.8,265.1c-1,0.3-1.8,0.7-2.6,1.2l-4,4.3c-0.7,0.8-1.1,1.8-1.2,2.9 M309.8,265.1l1.9-0.3
	c1.5-0.1,2.9,0.1,4.5,0.4c1.8,0.5,3.6,1.2,5.4,2.1 M313.7,268.4c1.9,0,3.6,0.7,5,2.1s2.1,3,2.1,5c0,1.9-0.7,3.6-2.1,5s-3,2.1-5,2.1
	s-3.6-0.7-5-2.1s-2.1-3-2.1-5s0.7-3.6,2.1-5S311.8,268.4,313.7,268.4L313.7,268.4z"
          />
          <path
            fill="none"
            d="M313.7,267.5c2.2,0,4,0.8,5.5,2.3s2.2,3.4,2.2,5.5c0,2.2-0.8,4-2.2,5.5c-1.5,1.5-3.4,2.3-5.5,2.3h-0.1
	c-2.2,0-4-0.8-5.5-2.3s-2.3-3.3-2.3-5.5s0.8-4,2.3-5.5C309.7,268.3,311.5,267.5,313.7,267.5z"
          />
          <path
            fill="none"
            d="M316.2,285.1l1.5-0.5c1.2-0.6,2.3-1.4,3.4-2.3c2.1-2.1,3.2-4.7,3.2-7.6c0-1.9-0.4-3.6-1.2-5.1l-0.1-0.3
	c-0.3-0.7-0.8-1.2-1.2-1.8"
          />
          <path
            fill="none"
            d="M324.2,269l0.3,1.5c0.4,1.2,0.7,2.6,0.7,4.1c0,3.3-1.1,6.2-3.4,8.5c-0.8,0.8-1.7,1.5-2.6,2.1l-1.2,0.5
	 M324.2,269l1.1,0.7c4.4,2.8,9.1,6.2,14.5,10.3 M321.6,267.5l2.6,1.5 M542.3,279.5l0.8,1.1c-0.8,0.8-1.8,1.5-2.8,2.2l-5.4,2.6
	l-6.3,2.5c-5.2,1.8-10.9,3.6-16.9,5.1l-4.8,1l0.1-2.1c6.2-1.4,12.3-3,17.9-5.1l3.4-1.2c3.6-1.4,7-2.9,10.3-4.4L542.3,279.5
	L542.3,279.5z"
          />
          <path
            fill="none"
            d="M527.2,285.1c4.3-1.7,8.5-3.4,12.5-5.4l0.3-0.1c1-0.7,1.5-1.5,1.5-2.5l0.1-1.4c0-0.8,0-1.5-0.1-2.2
	 M295.7,268.8l5.1,2.8l-0.3,1.7v0.7l-0.1,0.8c-0.3,2.2-0.4,4.3-0.5,6.3 M341.7,281.7c-1-0.7-1.8-0.5-2.3,0.5l-2.3,6.2
	c-0.3,1.2-0.3,2.2,0,3.2c0.4,1.1,1.4,1.8,2.9,1.9c1.4,0.1,2.8-0.1,4.1-0.8c2.1-1,3.9-2.2,5.5-3.4l0.3-0.3l0.1-0.3
	c0-0.3-0.1-0.4-0.4-0.7C346.7,285.8,344.2,283.7,341.7,281.7L341.7,281.7z M341.7,281.7l-2.1-1.7"
          />
          <path
            fill="none"
            d="M343.2,289.1c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.4-0.5-0.4-0.8s0.1-0.5,0.4-0.8
	c0.3-0.3,0.5-0.4,0.8-0.4c0.3,0,0.5,0.1,0.8,0.4c0.3,0.3,0.3,0.5,0.3,0.8S343.4,288.8,343.2,289.1L343.2,289.1z M362.2,300l1.1,3.3
	 M361.6,300.4l1,2.9 M364.9,310.7l1.7,5 M364.5,309.2l-1.5-4.5 M363.9,304.7l1.4,4.5 M365.8,310.7l1.5,5 M329.5,281.9v-0.3
	c0-0.1,0.1-0.3,0.3-0.4s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7c0,0.3-0.1,0.4-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3
	c-0.3,0-0.4-0.1-0.7-0.3l-0.3-0.4L329.5,281.9L329.5,281.9z"
          />
          <path
            fill="none"
            d="M322.4,283.9c-0.7,0.7-1.4,1.2-2.1,1.8c4.8,1.7,9.8,3,14.7,4.1l3.4-9.2c-4.7-3.6-9-6.8-12.8-9.2
	c0.3,1.1,0.4,2.2,0.4,3.4C326.3,278.4,325,281.4,322.4,283.9L322.4,283.9z"
          />
          <path
            fill="none"
            d="M339.6,280l-4,10.9l-0.8-0.1c-5.4-1.2-10.6-2.8-15.6-4.4l-1.2-0.4"
          />
          <path
            fill="none"
            d="M336.3,291.9c-6.2-1.4-12.3-3-17.9-5.1l-3.4-1.2c-3.6-1.4-7-2.9-10.3-4.4l-3.3-1.7v0.1l-0.8,1.1
	c0.8,0.8,1.8,1.5,2.8,2.2l5.4,2.6l6.3,2.5c5.2,1.8,10.9,3.6,16.9,5.1l4.8,1L336.3,291.9L336.3,291.9z"
          />
          <path
            fill="none"
            d="M316.2,285.1c-4.3-1.7-8.5-3.4-12.5-5.4l-0.3-0.1c-1-0.7-1.5-1.5-1.5-2.5l-0.1-1.4c0-0.8,0-1.5,0.1-2.2
	 M318,285.8l-1.8-0.7 M301.3,340.2c0-1.7,0.5-3.2,1.8-4.4c1.2-1.2,2.6-1.8,4.3-1.8c1.7,0,3.2,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.4
	c0,1.7-0.6,3.2-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8c-1.7,0-3.2-0.5-4.3-1.8C301.9,343.2,301.3,341.9,301.3,340.2L301.3,340.2z"
          />
          <path
            fill="none"
            d="M302.2,340.2c0-1.5,0.6-2.8,1.5-3.7c1-1,2.2-1.5,3.7-1.5s2.8,0.6,3.7,1.5c1,1.1,1.5,2.2,1.5,3.7
	s-0.5,2.8-1.5,3.7c-1.1,1.1-2.2,1.5-3.7,1.5s-2.8-0.5-3.7-1.5C302.7,342.8,302.2,341.6,302.2,340.2L302.2,340.2z"
          />
          <path
            fill="none"
            d="M306.2,340.2c0-0.3,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8
	c0,0.4-0.1,0.7-0.4,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.7-0.1-0.8-0.4C306.3,340.8,306.2,340.5,306.2,340.2L306.2,340.2z
	 M370.1,326.7l-1.1-3.4 M370.9,326.7l-1.1-3.4 M367.9,317.2l1.5,4.5 M376.7,346.7l-1.1-3.3 M368.6,321.8l-1.5-4.5 M489.1,346.7
	H354.3 M488,348.2H355.4 M355.4,348.2c1,1.4,2.1,2.6,3.2,3.6"
          />
          <path
            fill="none"
            d="M351.1,343.2l2.1,3.4c2.5,3.9,5.4,6.9,8.4,9c2.1,1.4,4.3,2.2,6.5,2.2h107.6c2.3-0.1,4.4-0.8,6.5-2.2
	c3-2.1,5.8-5.1,8.4-9l2.1-3.4 M339.2,353c-9.9-1.5-19.3-3.3-28.2-5.4 M323.9,353.2l39.5,5.8 M295.1,277.9c-0.8,1.4-1.9,2.6-3.3,3.6
	 M343,315.1c-22.9-3.2-38.2-7.3-46.2-12.3c-4.1-2.6-6.3-5.8-6.6-9.4c-0.5-3-0.3-6.2,0.8-9.5l0.7-2.5 M282.7,272.8l-1.1,1
	c-0.4,0.3-0.7,0.7-1.1,1.1 M277.4,274.6l0.1,3.3 M283.8,286.1l-1.1-7.6c-0.7-3-1.5-4.3-2.2-3.4 M280.5,274.9
	c-0.5,0.7-1.1,1.7-1.4,2.8c-0.8,2.5-1.5,5.2-2.1,8 M291.8,281.5l-8,4.5 M277.4,274.6l-1.9,9.9c-0.5,2.2-0.7,4.4-0.4,6.6l0.5,7.6"
          />
          <path
            fill="none"
            d="M277,285.8l-0.1,0.3c-0.3,1.7-0.5,3.3-0.8,5.1 M275.4,297.8v0.8 M275.4,298.6v0.7c-0.1,1.8-0.1,3.6-0.1,5.5
	c0,8.8,0.5,17.5,1.7,25.9 M277,301.8l-0.7-4.1c-0.4-1.2-0.7-1.2-0.8,0.1 M279,334.2l-0.4-3.2c-1.1-8.4-1.5-17.1-1.7-25.9v-3.3
	 M277,285.8c0.4-2.6,0.7-5.2,0.5-7.9 M279.2,313.2l-2.2-11.4 M276,291.2l1.1,6.9 M283.8,286.1l3.7,23.6 M277.1,298.1l6.8-12
	 M276,291.2l-0.5,6.6 M279,344.4v-10.2 M276.8,330.7l-1.7,8.8c-0.4,2.1-0.1,3.6,0.5,4.8l3.2,5.1 M285.6,349.7
	c1.2,7.2,4.1,12.5,8.5,16.4l2.5,1.9 M279,349.6v-5.2 M276.8,330.7c0.5,4.5,1.2,9.1,2.2,13.6 M283,334.4l2.6,15.3 M283,334.4
	l-3.9-21.2 M277.1,298.1l5.9,36.4 M279.2,313.2l-0.1,20.9"
          />
          <path
            fill="none"
            d="M279,349.6v52.1c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.3-2.3,7.3-7.2v-33.1 M330,370.4l5.4,1h172.8l5.4-1
	 M330,370.4l5.4-2.3 M285.6,349.7h16.9 M296.7,368l17.5,0.5 M294.5,326.7l17.2,5.4 M296.7,368h38.7 M480.1,359l-2.6,0.3H366.1
	l-2.6-0.3 M358.5,351.9l1.7,1.5 M360.2,353.4l1.8,1.4c1.9,1.2,3.9,1.9,5.9,2.1h107.5c2.1-0.1,4.1-0.8,5.9-2.1l1.8-1.4"
          />
          <path
            fill="none"
            d="M557.9,349.7c-10.7,4-30.5,8.3-59.2,12.5c-2.1,0.1-4.1,0.1-6.2,0.1l-4.1-0.1H355.1l-4.1,0.1
	c-1.9,0.1-4,0.1-6.2-0.1c-28.8-4.4-48.5-8.5-59.2-12.5 M335.4,368h8.3c1.9,0,3.6-0.1,4.8-0.7l3.2-1c1.5-0.4,3.3-0.7,5.2-0.8h129.7
	c1.9,0.1,3.6,0.4,5.2,0.8l3.2,1c1.4,0.4,2.9,0.7,5,0.7h8.3 M354.3,346.7l1,1.4l0.1,0.1 M351.1,343.2l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-0.3c0.1-0.1,0.3-0.1,0.3-0.1h140.7c0.1,0,0.3,0,0.4,0.1l0.1,0.3l-0.1,0.3l-0.1,0.1"
          />
          <path
            fill="none"
            d="M363.5,359c-1.4-0.4-2.6-1-4-1.8c-3.7-2.5-7-6.2-10.1-11.3l-0.1-0.4l-6.2-10.2c2.5,3,5.1,5.6,7.9,8l0.1,0.1
	 M348.2,321.8l2.3,1.5 M371.3,328.1l3.7,12.7 M375.1,340.8l-1.8-12.7 M354.3,346.7v-0.1l-1.9-3.2h138.9l-1.9,3.2l-0.1,0.1
	 M373.1,326.7l0.1,1.4 M373.3,328.1h-1.9 M373.1,326.7h-2.2 M370.9,326.7l0.4,1.4 M350.5,323.3H493 M495.4,321.8H348.2 M350.5,323.3
	c4.4,2.3,10.5,3.4,18.2,3.4h2.2 M314.2,368.6l7.2,0.3l8.7,1.5 M319.1,352.5h-0.3 M323.9,353.2l-4.8-0.7 M319.1,352.5h-0.3
	 M318.8,352.5l-16.3-2.8 M339.2,353h-1.7c-6.1-0.4-12.3-1.1-18.5-1.9l-18.9-3.2c-1.5-0.3-2.5-1.1-2.9-2.5
	c-1.7-5.8-3.2-11.9-4.3-17.9c-0.1-0.4,0-0.7,0-0.8c0.3-0.1,0.5-0.1,1.1,0l0.1,0.1"
          />
          <path
            fill="none"
            d="M294.5,326.7c-0.1,0.1-0.1,0.4,0,0.8c1,5.4,2.2,10.6,3.6,15.7c0.5,2.5,2.2,3.9,5.1,4l6.3,0.5l1.7-0.1
	 M316.2,345.3c5.8,1.9,12.1,3.2,18.6,3.7l-1.5-2.2c-6.2-0.6-12.1-1.8-17.5-3.7C315.7,343.9,315.8,344.8,316.2,345.3L316.2,345.3z
	 M311.1,347.8c0.7-0.1,1.2-0.4,1.7-1c0.7-0.6,1.2-1.2,1.7-2.1l0.7-1.8l0.1-0.8l0.3-1.9c0-0.8,0-1.5-0.1-2.1
	c-0.4-2.3-1.7-4.3-3.7-5.9"
          />
          <path
            fill="none"
            d="M302.4,335.1c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1c0,1.9-0.7,3.6-2.1,5s-3,2.1-5.1,2.1
	c-1.9,0-3.6-0.7-5-2.1s-2.1-3-2.1-5S301.1,336.5,302.4,335.1L302.4,335.1z M328.9,332L292,321.3c3-1.4,6.6-1.4,10.7-0.1l18.7,5.6
	C325.2,328.1,327.7,329.7,328.9,332L328.9,332z M329.6,341.3c-4.7-0.7-9.1-1.7-13.4-3c-0.1,0.8-0.1,1.5,0.3,2.2
	c4.7,1.5,9.5,2.5,14.6,3L329.6,341.3L329.6,341.3z"
          />
          <path
            fill="none"
            d="M302.6,349.7l-2.3-0.4c-2.2-0.4-3.4-1.5-4-3.4c-1.7-5.8-3-11.9-4.3-18c-0.3-1.1-0.1-1.8,0.5-2.2
	c0.4-0.4,1.2-0.5,2.3-0.3h0.1l26.5,8.3c4.1,1.5,8,4.1,11.4,7.9l0.1,0.1c3,3.2,5.5,6.3,7.4,9.6c0.8,1.2,0.8,2.1,0.1,2.6
	c-0.4,0.5-1.5,0.7-3,0.5l-13.6-1.2"
          />
          <path
            fill="none"
            d="M311.7,332.1l9.2,2.9c3.6,1.2,6.9,3.4,9.9,6.5l2.1,2.2c2.5,2.8,4.7,5.5,6.3,8.4c0.3,0.4,0.4,0.7,0.3,0.8
	l-0.3,0.1 M357.6,303.3l-1.9,1.4 M358.1,281.9l-8.3,2.6 M344.2,317.2h155 M499.2,315.7h-155 M368.9,297c-1.2,0.4-2.3,1-3.6,1.5
	 M348.7,310.7h146.1 M493.3,309.2h-143 M355.6,304.7h132.3 M486,303.3H357.6 M365.3,298.3c-1,0.4-2.1,1-3,1.7l-0.7,0.3h-0.1l-4,2.9
	 M355.6,304.7c-1.9,1.4-3.7,2.9-5.4,4.5 M350.3,309.2l-1.5,1.5 M344.2,317.2l0.1,0.4c1,1.5,2.2,2.9,3.7,4.1"
          />
          <path
            fill="none"
            d="M371.3,328.1h-2.6c-13.2,0-21.6-3.2-25.5-9.6c-0.7-1.1-0.8-2.2-0.3-3.4"
          />
          <path
            fill="none"
            d="M343,315.1c0.1-0.6,0.4-1,0.8-1.5c5.1-5.4,10.7-10.2,17.1-14.5l4.3-2.2c5.1-2.3,10.6-3.6,16.7-4l40.8-1.2
	l39,1.2c6.1,0.4,11.6,1.7,16.7,4l4.3,2.2c6.3,4.1,12,9,17.1,14.5c0.4,0.5,0.7,1,1,1.5"
          />
          <path
            fill="none"
            d="M348.7,310.7c-1.2,1.2-2.6,2.5-3.9,3.9c-0.3,0.4-0.5,0.8-0.7,1.2 M344.2,315.7c-0.1,0.6-0.1,1.1,0,1.5
	 M295.1,277.9c0.4,0.7,0.8,1.5,1.4,2.2c2.2,2.9,5.9,5.4,11.2,7.4c7,2.8,14.7,5.4,23.3,7.6c4.5,1.2,8.1,1.5,10.5,1.1
	c3.6-1.1,6.5-2.6,9-4.5l0.5-0.4c0.6-0.4,1.1-1,1.5-1.5c0.8-1,0.8-1.9-0.1-2.9l-2.3-2.2"
          />
          <path
            fill="none"
            d="M358.1,281.9l0.7,1.5c0.8,2.5,0,5-2.3,7l-3.6,3.2l-2.1,1.5c-0.1,0.1-0.1,0.1-0.3,0.3l-2.8,1.9
	c-4.1,2.8-9.8,3.2-16.8,1.6c-8.5-2.2-16.3-4.8-23.3-7.6c-6.5-2.6-10.6-6.8-12.1-12.3l-0.3-1.2 M294.5,245.1
	c2.9-6.2,5.9-10.9,9.1-13.9 M296.8,226.3l2.9-3.3 M566.2,274.6l-0.3,3.3 M566.6,285.8c-0.5-2.8-1.2-5.5-2.1-8
	c-0.4-1.1-0.8-2.1-1.4-2.8 M563,274.9c-0.4-0.4-0.7-0.8-1.1-1.1l-1.1-1 M559.6,286.1l1.1-7.6c0.7-3,1.5-4.3,2.3-3.4 M566.6,285.8
	v0.3l0.8,5.1 M568.1,298.6l0.5-7.6c0.3-2.2,0.1-4.3-0.4-6.6l-1.9-9.9 M565.9,277.9c0,2.6,0.1,5.2,0.6,7.9 M500.6,315.1
	c22.9-3.2,38.2-7.3,46-12.3c4.1-2.6,6.3-5.8,6.6-9.4c0.6-3,0.3-6.2-0.8-9.5l-0.7-2.5"
          />
          <path
            fill="none"
            d="M548.4,277.9l-0.3,1.2c-1.7,5.5-5.7,9.6-12.1,12.3c-7,2.8-14.7,5.2-23.3,7.6c-7,1.5-12.5,1-16.8-1.7l-5.1-3.6
	c-1.4-1.1-2.6-2.1-3.6-3.2c-2.3-2.2-3.2-4.5-2.3-7l0.7-1.5 M551.6,281.5c-1.2-1-2.3-2.2-3.3-3.6 M299.7,223l-5.6,16.5 M294.5,245.1
	c1.5-6.2,2.9-12.1,4.4-17.8c0.8-3,1.7-6.1,2.5-9"
          />
          <path
            fill="none"
            d="M493.7,284.5l-2.5,2.2c-1,1-1,1.9,0,2.9c0.4,0.5,0.8,1,1.5,1.5l0.6,0.4c2.3,1.9,5.4,3.4,9,4.5
	c2.3,0.5,5.8,0.1,10.5-1.1c8.5-2.2,16.3-4.8,23.3-7.6c5.2-2.1,9-4.5,11.2-7.4c0.6-0.7,1-1.4,1.4-2.2 M564.4,313.2l2.1-11.4
	 M566.5,301.8l0.7-4.1c0.4-1.2,0.7-1.2,0.8,0.3 M564.5,334.2l0.4-3.2c1.1-8.4,1.5-17.1,1.7-25.9v-3.3 M566.6,330.7
	c1.1-8.4,1.5-16.9,1.7-25.9l-0.1-6.2 M568.1,298.6v-0.7 M566.3,298.1l-6.8-12 M567.4,291.2l-1.1,6.9 M559.6,286.1l-3.7,23.6
	 M551.6,281.5l8,4.5 M568.1,297.9l-0.7-6.8 M564.5,349.6l3.2-5.1c0.8-1.2,1-2.9,0.6-4.8l-1.7-8.8 M564.5,344.4v-10.2 M566.6,330.7
	c-0.5,4.5-1.2,9.1-2.2,13.6 M519.6,353.2l-13.6,1.2c-1.5,0.1-2.6,0-3-0.6c-0.7-0.6-0.7-1.4,0.1-2.6c1.9-3.2,4.4-6.5,7.4-9.6l0.1-0.1
	c3.4-3.9,7.3-6.5,11.4-7.9l26.5-8.3h0.1c1.1-0.3,1.9-0.1,2.3,0.3c0.6,0.4,0.8,1.1,0.6,2.2c-1.1,6.2-2.6,12.3-4.3,18
	c-0.4,1.9-1.8,3-4,3.4l-2.3,0.4"
          />
          <path
            fill="none"
            d="M532.4,347.8l1.7,0.1l6.3-0.6c2.8-0.1,4.5-1.4,5.1-4c1.4-5.1,2.6-10.3,3.6-15.7c0.1-0.4,0.1-0.7,0-0.8"
          />
          <path
            fill="none"
            d="M549.1,326.7l0.1-0.1c0.5-0.1,0.8-0.1,1.1,0c0.1,0.1,0.1,0.4,0,0.8c-1.1,6.2-2.6,12.1-4.3,17.9
	c-0.4,1.4-1.4,2.2-2.9,2.5l-18.9,3.2c-6.3,0.8-12.4,1.5-18.5,1.9h-1.7 M303.5,231.2l3.2-0.3 M360.6,236.5l-22.3,0.3
	c-5.4,0.1-10.9-0.3-16.7-0.8c-2.2-0.3-4.3-0.7-6.2-1.2c-2.5-0.6-4.8-1.4-6.8-2.3c-0.6-0.3-1.1-0.6-1.5-1"
          />
          <path
            fill="none"
            d="M543.2,340.2c0-1.9-0.7-3.6-2.1-5.1c-1.4-1.4-3-2.1-5-2.1s-3.6,0.7-5.1,2.1c-1.4,1.4-2.1,3-2.1,5.1
	c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.6-0.7,5-2.1C542.5,343.8,543.2,342.1,543.2,340.2L543.2,340.2z M546.9,368
	c0.8-0.5,1.8-1.2,2.5-1.9c4.4-3.9,7.2-9.2,8.5-16.4 M541,349.7h17 M564.5,344.4v5.2 M557.9,349.7l2.6-15.3 M564.5,334.2l-0.1-20.9
	 M560.5,334.4l3.9-21.2 M560.5,334.4l5.8-36.4 M540.7,321.2l-18.7,5.6c-3.7,1.2-6.2,2.9-7.4,5.1l36.9-10.6
	C548.4,320,544.8,320,540.7,321.2L540.7,321.2z"
          />
          <path
            fill="none"
            d="M529.4,368.6v33.1c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.4-2.3,7.4-7.2v-52.2 M303.5,231.2c0.4,1.1,1.1,2.2,1.9,3.2
	c11.7,16.1,28.1,31.8,49.2,47.4c-5.9-15.6-14.1-30-24.4-43.1c11.6,12.7,20.8,27,27.8,43.3 M307.1,231.4l-0.4-0.4 M306.7,230.9
	l-0.7-1.1 M317.1,172l-2.3,4.5c-5.5,11.2-10.9,22.9-15.8,35c-2.1,5-4,9.9-5.9,15 M301.3,218.3l-1.7,4.7 M296.8,226.3
	c1.9-5,3.9-9.9,5.8-14.7l2.2-5.5 M301.3,218.3c1.2-4.3,2.3-8.3,3.6-12.3 M499.2,315.7c-0.1-0.4-0.3-0.8-0.7-1.2
	c-1.2-1.4-2.5-2.6-3.9-3.9 M499.2,317.2c0.1-0.5,0.1-1.1,0-1.5 M495.4,321.8c1.5-1.1,2.8-2.5,3.7-4.1l0.3-0.4"
          />
          <path
            fill="none"
            d="M500.6,315.1c0.4,1.1,0.3,2.2-0.4,3.4c-3.7,6.5-12.3,9.6-25.5,9.6h-2.6 M485.3,281.9l8.4,2.6 M474.7,297
	c-4-1.5-8.4-2.3-13.1-2.6l-2.1-0.1l-0.8-0.1l-24.5-1h-24.8l-24.5,1l-0.8,0.1l-2.1,0.1c-4.7,0.3-9.1,1.1-13.1,2.6 M474.7,297H368.9
	 M478.3,298.3h-113 M323.8,173.6c-5.9,16.3-10.7,31.4-14.3,45.5l-0.4,1.2c-1,3.2-1.7,6.9-2.1,11 M486,303.3l-4-2.9h-0.1l-0.7-0.3
	c-1-0.5-2.1-1.1-3-1.7"
          />
          <path
            fill="none"
            d="M306,229.8c0.4-3.2,1-6.3,1.9-9.5l0.4-1.2c3.7-14.3,8.5-29.9,14.7-46.7 M304.9,206l3.7-11.7
	c2.2-6.2,4.3-12.3,6.5-17.8l1.8-4.5 M494.8,310.7l-1.5-1.5 M493.3,309.2c-1.8-1.7-3.6-3.2-5.4-4.5 M487.9,304.7l-1.9-1.4
	 M321.6,173.1c-1,0.7-1.7,1.7-2.1,2.9l-0.3,0.7c-5.6,14.3-10.6,28.9-14.9,43.5c-1,2.8-1.4,5.2-1.5,7.2c0,1.2,0.3,2.5,0.8,3.9"
          />
          <path
            fill="none"
            d="M306,229.8l-0.4-1.2c-0.5-2.3-0.3-5.5,1-9.5c3.7-14.3,8-28.5,13-42.4l0.3-0.7c0.4-1.1,1.1-2.1,1.9-2.8
	 M478.3,298.3c-1.1-0.6-2.3-1.1-3.6-1.5 M253.4,243.4l10.1,1.4 M293.6,248.6l0.8-3.4 M273.1,254.9c4.5-0.4,9-1.4,13.1-2.6
	 M291.6,246.9l0.1,1 M493,323.3l2.3-1.5 M492.5,343.2c2.9-2.5,5.5-5.1,8-8.1l-6.2,10.2l-0.1,0.4c-3,5.1-6.3,8.8-10.1,11.3
	c-1.2,0.8-2.6,1.5-4,1.8"
          />
          <path
            fill="none"
            d="M286.2,252.2l1.5,3c-3.2,4.5-5.8,9.1-8,13.6l-2.8-5.8 M472.6,326.7h2.2c7.7,0,13.8-1.1,18.2-3.4 M291.8,281.5
	c0.3-1.2,0.4-2.5,0.4-3.9l-1-9.9c0-1.2-0.5-1.7-1.7-1l-7,6.1 M470.4,326.7h2.2 M472.2,328.1l0.4-1.4 M470.3,328.1l0.1-1.4
	 M472.2,328.1h-1.9 M470.3,328.1l-1.8,12.7 M468.5,340.8l3.7-12.7 M292.5,251.9l-1.2,2.8 M360.2,353.4h123.2 M291.7,247.9l1.9,0.7
	 M358.5,351.9h126.4 M296.2,255.9c-1.2-1.9-2.2-3.9-3-5.9 M291.7,247.9l0.8,4 M488,348.2l0.1-0.1l1-1.4 M483.4,353.4l1.5-1.5
	 M484.9,351.9c1.1-1.1,2.2-2.3,3.2-3.6 M291.3,254.6l-0.4-1.5 M349.8,284.5c-9.6-8.5-18.7-15.2-27.6-19.7c-0.4-0.1-0.7-0.3-1.1-0.4
	c-3.2-1.4-6.5-2.2-10.1-2.5l-4.5-0.1c-3.7,0-6.8-1.5-9.4-4.5l-1-1.2 M531.9,332.1l-9.2,2.9c-3.6,1.2-6.9,3.4-9.9,6.5l-2.1,2.2
	c-2.5,2.8-4.7,5.5-6.3,8.4c-0.3,0.4-0.4,0.7-0.3,0.8l0.4,0.1 M532.4,347.8c-0.7-0.1-1.2-0.4-1.7-1c-0.7-0.6-1.2-1.2-1.7-2.1
	c-0.3-0.5-0.5-1.1-0.7-1.8l-0.3-0.8c-0.1-0.7-0.3-1.4-0.3-1.9c0-0.8,0-1.5,0.1-2.1c0.4-2.3,1.7-4.3,3.7-5.9"
          />
          <path
            fill="none"
            d="M527.3,345.3c-5.8,1.9-12.1,3.2-18.7,3.7l1.5-2.2c6.2-0.6,12.1-1.8,17.5-3.7
	C527.9,343.9,527.7,344.8,527.3,345.3z M293.2,250l-0.7,1.9 M526.9,340.5c-4.7,1.5-9.5,2.5-14.6,3l1.5-2.2c4.7-0.7,9.1-1.7,13.4-3
	C527.5,339.1,527.3,339.8,526.9,340.5L526.9,340.5z M513.5,370.4l8.5-1.5l7.3-0.3 M524.4,352.5h0.1 M524.6,352.5h-0.1 M519.6,353.2
	l4.8-0.7 M504.2,353c9.9-1.5,19.3-3.3,28.2-5.4 M480.1,359l39.5-5.8 M293.2,250l0.4-1.4 M276.8,262.6l-2.6-5.6 M253.4,243.4
	c2.9,4.4,8,6.6,15.6,6.9c1.5,0.1,2.3,0.7,2.9,1.8l1.4,2.9 M273.1,254.9l1.1,2.1 M274.2,257v2.9c0,1.1,0.8,2.1,2.6,2.8 M276.8,262.6
	l0.3,0.5 M290.3,234.3c-2.3,1.8-4,3.7-5,5.6 M292.9,226.5c-3.2,3-5.6,6.3-7.6,9.8 M294,239.5c0-2.1,0.3-4.1,0.7-6.2 M294,239.5
	l-2.5,7.4 M291.3,232.1c-0.3,1.7-0.5,3.4-0.7,5.2v0.1c0,2.9,0.3,6.1,0.8,9.5 M290.9,253.1c-1-4.8-1.5-9.1-1.4-12.8v-0.1
	c0.1-2.1,0.4-4,0.8-5.8 M284.5,244.9l-0.1,0.7c-0.3,1.2-0.1,2.5,0.4,3.6l1.5,3 M407.3,279.2c-1.7,0-3.4,0-5.1,0.1
	c-14.7,0.4-29.5,1.2-44.1,2.6 M284.5,244.9c-1.1,1.1-3.4,1.4-6.9,1.2l-14.2-1.4 M285.4,239.9c-0.1,1.7-0.4,3.3-0.8,5.1 M285.4,236.3
	c0.1,1.1,0,2.2,0,3.4 M291.3,232.1l3.4,1.2 M294.7,233.3c0.3-1.2,0.5-2.5,1-3.7l1.1-3.3 M291.8,229.6c-0.7,1.4-1.1,2.9-1.5,4.5
	 M291.8,229.6l-0.5,2.5 M292.9,226.5v0.1l-0.3,0.6l-0.8,2.3 M285.4,236.3c-0.1-1.7-0.3-3.2-0.8-4.5c-0.7-2.8-2.9-4.3-6.8-4.5
	c-9.1-0.1-17.4,1.4-24.7,4.8c-1.8,1-2.6,2.3-2.5,4.5 M263.5,244.7c-2.1-2.5-4.5-4.4-7.3-5.5c-2.1-0.8-3.9-1.7-5.4-2.6"
          />
          <path
            fill="none"
            d="M250.8,236.6c0,0.6,0.1,1.1,0.3,1.8l1.7,4l0.6,1.1 M252.8,238.7c-0.1,0-0.1,0-0.1,0.1v0.1l1.2,3.4l0.1,0.1
	l0.3,0.1h0.3l0.3-0.1v-0.1l-1.2-3.4l-0.1-0.1l-0.3-0.1H252.8z M338.5,157.2l-1.1,0.1c-1.5,0.3-3,0.6-4.4,1 M328.2,171.4l6.2-10.5
	c1-1.7,2.3-2.9,4-3.6 M332.9,158.3c-7.4,2.1-12.4,5.9-14.7,11.4l-1.1,2.2 M328.8,173.2l-5,0.4 M328.2,171.4l0.5,1.8 M323.4,172.1
	c0.5-0.3,1.2-0.4,1.9-0.4l2.9-0.3 M470.4,326.7h-97.3 M323.1,172.3l-0.6,0.3 M322.6,172.5l0.4-0.3 M323,172.3h0.1 M323.1,172.3
	l0.3-0.1 M323.4,172.1l0.4,1.5 M321.6,173.1l0.3-0.3c0.1-0.1,0.4-0.1,0.5-0.3c0,0,0.1,0,0.1-0.1"
          />
          <path
            fill="none"
            d="M332.9,158.3c-1.5,0.6-2.6,1.5-3.6,3.2l-6.1,10.5 M436.1,279.2h-28.7 M485.3,281.9c-16.4-1.5-32.8-2.3-49.2-2.8
	 M508.2,368l5.4,2.3 M536.8,230.9l0.7-1.1 M536.8,230.9l3.2,0.3 M543.7,223l5.8,16.5 M546.7,226.3l-3-3.3 M590.2,243.4l-10.1,1.4
	 M592.8,236.6c-1.5,1-3.3,1.8-5.4,2.6c-2.8,1.1-5.2,3-7.3,5.5 M590.2,238.8l0.3-0.1h0.3l0.3,0.1v0.1l-1.2,3.4l-0.3,0.3h-0.7v-0.3
	l1.2-3.4"
          />
          <path
            fill="none"
            d="M590.2,243.4l0.5-1.1l1.8-4l0.3-1.7 M549.1,245.1l0.8,3.4 M551.8,247.9l0.1-1 M566.7,262.6l2.6-5.6
	 M557.2,252.2l-1.5,3c3.2,4.5,5.8,9.1,8,13.6l2.8-5.4 M566.5,263.5v-0.3l0.3-0.5"
          />
          <path
            fill="none"
            d="M570.3,254.9l-5.2-0.7c-2.8-0.6-5.4-1.1-8-1.9 M566.7,262.6c1.8-0.7,2.6-1.7,2.6-2.8V257 M569.3,257l1-2.1
	 M570.3,254.9l1.4-2.9c0.1-0.3,0.3-0.6,0.6-0.8l0.3-0.3c0.5-0.4,1.1-0.6,1.9-0.7c7.6-0.3,12.8-2.6,15.6-6.9 M560.8,272.8l-7-6.1
	c-1.1-0.7-1.7-0.3-1.7,1l-1,9.9c0,1.4,0.1,2.6,0.4,3.9 M550.2,250c-0.8,2.1-1.8,4-3,5.9 M552.5,253.1l-0.3,1.5 M552.3,254.6
	l-0.1-0.3c-0.4-0.8-0.8-1.7-1.1-2.5 M549.9,248.6l0.3,1.4 M550.9,251.9l1-4 M550.9,251.9l-0.7-1.9 M551.8,247.9l-1.9,0.7
	 M549.5,239.5c0-2.1-0.3-4.1-0.7-6.2 M558.2,239.9l-1.2-1.8c-1-1.2-2.2-2.6-3.9-3.9 M549.1,245.1c-2.9-6.2-5.9-10.9-9.1-13.9
	 M542.2,218.3l2.5,9c1.5,5.8,3,11.7,4.4,17.8 M549.5,239.5l2.5,7.4"
          />
          <path
            fill="none"
            d="M553.2,234.3c0.4,1.8,0.7,3.7,0.8,5.8v0.1c0,3.7-0.4,8-1.4,12.8v0.1 M524.6,352.5l16.4-2.8 M531.9,332.1
	l17.2-5.4 M529.4,368.6l17.5-0.5 M552,246.9c0.5-3.4,0.8-6.8,0.8-9.5v-0.1c-0.1-1.8-0.3-3.6-0.7-5.2 M540,231.2
	c-0.4,1.1-1.1,2.2-1.9,3.2c-11.7,16.1-28.1,31.8-49.2,47.4c5.9-15.6,14.1-30,24.4-43.1c-11.6,12.7-20.8,27-27.8,43.3 M546.9,368
	h-38.7 M338.5,157.2c27-3.4,53.9-5.2,80.7-5.4 M389,236.3l18.5,42.9 M553.2,234.3c-0.4-1.7-1-3.2-1.5-4.5 M558,236.3l-1.1-1.9
	c-1.7-2.8-3.9-5.4-6.3-7.7 M566.2,274.6c0.3-3.7,0.3-7.4,0.3-11 M277.1,263.2c0,3.9,0.1,7.6,0.3,11.4 M548.4,277.9
	c1.2-6.3,0.8-13.6-1.1-22 M552.3,254.6c3.2,6.2,6.1,12.3,8.7,18.2 M454.6,236.3l-18.5,42.9 M551.6,229.6l0.7,2.5 M282.7,272.8
	c2.5-5.9,5.4-12.1,8.7-18.2 M296.2,255.9c-1.9,8.4-2.3,15.8-1.1,22 M552.3,232.1l-3.4,1.2 M550.6,226.7l1,2.9 M468.5,340.8h-93.4
	 M373.3,328.1h97 M277.5,277.9c3.2-9.6,7.7-17.9,13.4-24.8 M552.5,253.1c5.8,6.9,10.2,15.2,13.4,24.8 M548.8,233.3
	c-0.3-1.2-0.5-2.5-1-3.7l-1.1-3.3 M389,236.3l-28.4,0.1 M559,244.9l0.1,0.7c0.3,1.2,0.1,2.5-0.4,3.6l-1.5,3"
          />
          <path
            fill="none"
            d="M580.1,244.7l-14.2,1.4c-3.4,0.3-5.8-0.1-6.9-1.2 M559,244.9c-0.4-1.8-0.7-3.4-0.8-5.1 M558.2,239.9
	c-0.1-1.2-0.1-2.3,0-3.4 M592.8,236.6c0.1-2.1-0.7-3.6-2.5-4.5c-7.3-3.4-15.6-5-24.7-4.8c-3.9,0.3-6.1,1.8-6.8,4.5
	c-0.4,1.4-0.7,2.9-0.8,4.5 M543.7,223l-1.5-4.7 M546.7,226.3c-1.9-5-3.9-9.9-5.8-14.7l-2.2-5.5 M538.6,206l3.6,12.3 M483,236.5
	l-28.4-0.1 M526.5,172l2.3,4.5c5.6,11.2,10.9,22.9,15.8,35l5.9,15l0.1,0.1 M493.7,284.5c9.6-8.5,18.7-15.2,27.6-19.7
	c0.4-0.1,0.7-0.3,1.1-0.4c3.2-1.4,6.5-2.2,10.1-2.5l4.5-0.1c3.7,0,6.8-1.5,9.4-4.5l1-1.2 M536.4,231.4l0.4-0.4 M536.4,231.4
	c-0.4,0.4-0.8,0.7-1.5,1c-2.1,1-4.3,1.8-6.8,2.3l-6.3,1.2c-5.8,0.7-11.3,1-16.5,0.8l-22.3-0.3 M463.9,242h-0.7v1.8h3.2V242h-0.8"
          />
          <path
            fill="none"
            d="M465.6,242v1h-1.7v-1 M465.6,242v-0.8h-1.7v0.8 M537.5,229.8c-0.4-3.2-1-6.3-1.9-9.5l-0.4-1.2
	c-3.7-14.3-8.5-29.9-14.7-46.7"
          />
          <path
            fill="none"
            d="M519.6,173.6c5.9,16.3,10.8,31.4,14.3,45.5l0.3,1.2c1,3.2,1.7,6.9,2.1,11 M537.5,229.8l0.4-1.2
	c0.5-2.3,0.3-5.5-1-9.5c-3.7-14.3-8-28.5-13-42.4l-0.3-0.7c-0.4-1.1-1.1-2.1-1.9-2.8 M526.5,172l1.8,4.5c2.2,5.6,4.4,11.6,6.5,17.8
	l3.7,11.7"
          />
          <path
            fill="none"
            d="M540,231.2c0.6-1.2,0.8-2.5,0.8-3.9c-0.1-1.9-0.6-4.4-1.5-7.2c-4.3-14.7-9.2-29.2-14.9-43.5l-0.3-0.7
	c-0.4-1.2-1.2-2.2-2.1-2.9 M454.6,236.3H389 M436.1,279.2l9.5-10.7l9-20v-12.1 M407.3,279.2l-9.4-10.7l-9-20v-12.1 M379.6,242h0.7
	v1.8h-3.2V242h0.7"
          />
          <path
            fill="none"
            d="M377.8,242v1h1.8v-1 M379.6,242v-0.8h-1.8v0.8 M424.2,151.9l-0.8-3.2 M424.2,151.9c6.5,0,12.8,0.1,19.3,0.4
	c20.4,0.7,40.9,2.3,61.5,5 M419.3,151.9h5 M483,236.5l22.3-0.6c4.4-0.1,9-0.4,13.6-1.1c5.6-0.6,9.1-2.8,10.3-6.6
	c0.8-3,0.7-6.9-0.6-11.7c-2.5-9.2-5.4-18.9-8.8-29.1c-2.2-6.8-8.1-10.5-17.6-11.4c-26.7-2.1-53.3-3.2-79.8-3.2h-1.8
	c-26.5,0-53,1.1-79.8,3.2c-9.5,0.8-15.4,4.7-17.6,11.4c-3.4,10.2-6.3,19.8-8.8,29.1c-1.2,4.8-1.4,8.7-0.5,11.7
	c1.2,3.9,4.7,6.1,10.3,6.6c4.7,0.6,9.2,1,13.6,1.1l22.3,0.6"
          />
          <path
            fill="none"
            d="M514.6,173.2c-30.9-2.8-61.6-4.1-92-4.1h-1.8c-30.5,0-61.2,1.4-92,4.1"
          />
          <path
            fill="none"
            d="M515.2,171.4c-31-2.8-61.9-4.1-92.6-4.3h-1.8c-30.7,0-61.6,1.4-92.6,4.3 M419.3,151.9c0.3-1.1,0.5-2.1,0.8-3.2
	l0.1-0.4 M420.7,142.2l-0.6,6.2 M420.1,148.4c0.3-1.9,0.5-4,0.5-6.1 M423.4,148.7c-0.4-3-0.5-5.4-0.5-7"
          />
          <path
            fill="none"
            d="M422.9,141.8v0.3c0,2.3,0.1,4.5,0.5,6.8 M420.7,142.2v-2.8v-10.6l-0.1-1.4c0-0.4,0-0.7,0.1-1l0.1-0.6h0.1
	c0.1-0.4,0.4-0.6,0.8-0.6h0.1c0.4,0,0.5,0.1,0.8,0.6l0.3,0.6v1l-0.1,1.4v12.8 M519.6,173.6l-5-0.4 M510.5,158.3
	c7.4,2.1,12.4,5.9,14.7,11.4l1.1,2.2 M520.2,172.1c-0.5-0.3-1.2-0.4-1.9-0.4l-2.9-0.3 M521.1,172.5l-0.7-0.3 M521.1,172.5l-0.7-0.3
	 M521.9,173.1l-0.7-0.4c-0.1,0-0.1-0.1-0.1-0.1 M520.4,172.3l-0.3-0.1 M520.2,172.1l-0.5,1.5 M514.6,173.2l0.5-1.8 M505,157.2
	l1.1,0.1c1.5,0.3,3,0.6,4.4,1"
          />
          <path
            fill="none"
            d="M510.5,158.3c1.5,0.6,2.6,1.5,3.6,3.2l6.1,10.5 M515.2,171.4L509,161c-1-1.7-2.3-2.9-4-3.6"
          />
          <path
            fill="none"
            d="M505,157.2c1.8,0.8,3,1.9,4,3.6l6.2,10.5l2.9,0.3c0.7,0.1,1.4,0.3,1.9,0.4l-6.1-10.5c-1-1.7-2.1-2.8-3.6-3.2
	c-1.4-0.4-2.9-0.7-4.4-1L505,157.2L505,157.2z"
          />
          <path
            fill="none"
            d="M509,160.8c-1-1.7-2.3-2.9-4-3.6c-20.5-2.6-40.9-4.3-61.5-5c-6.5-0.3-13-0.4-19.3-0.4h-5
	c-26.9,0.1-53.7,1.9-80.7,5.4c-1.8,0.8-3,1.9-4,3.6l-6.2,10.5c31-2.8,61.9-4.1,92.6-4.3h1.8c30.7,0,61.6,1.4,92.6,4.3L509,160.8
	L509,160.8z M510.5,158.3c1.5,0.6,2.6,1.5,3.6,3.2l6.1,10.5l0.3,0.1l0.5,0.3l0.1,0.1l0.7,0.4c1,0.7,1.7,1.7,2.1,2.9l0.3,0.7
	c5.7,14.3,10.6,28.9,14.9,43.5c1,2.8,1.4,5.2,1.5,7.2c0,1.2-0.3,2.5-0.8,3.9c3.2,3,6.2,7.6,9.1,13.9c-1.5-6.2-2.9-12.1-4.4-17.8
	l-2.5-9l-3.6-12.3l-3.7-11.7c-2.1-6.2-4.3-12.3-6.5-17.8l-1.8-4.5l-1.1-2.2C522.9,164.3,518.1,160.4,510.5,158.3L510.5,158.3z"
          />
          <path
            fill="none"
            d="M515.2,171.4l-0.5,1.8l5,0.4l0.6-1.5c-0.5-0.3-1.2-0.4-1.9-0.4L515.2,171.4L515.2,171.4z M514.6,173.2l0.5-1.8
	c-31-2.8-61.9-4.1-92.6-4.3h-1.8c-30.7,0-61.6,1.4-92.6,4.3l0.5,1.8c30.9-2.8,61.6-4.1,92-4.1h1.8
	C453,169.1,483.8,170.5,514.6,173.2L514.6,173.2z"
          />
          <path
            fill="none"
            d="M520.2,172.1l-0.5,1.5c5.9,16.3,10.7,31.4,14.3,45.5l0.3,1.2c1,3.2,1.7,6.9,2.1,11l0.4-0.4l0.7-1.1
	c-0.4-3.2-1-6.3-1.9-9.5l-0.4-1.2c-3.7-14.3-8.5-29.9-14.7-46.7L520.2,172.1L520.2,172.1z"
          />
          <path
            fill="none"
            d="M521.9,173.1l-0.7-0.4c-0.1,0-0.1-0.1-0.1-0.1l-0.5-0.3c6.2,16.8,11,32.4,14.7,46.7l0.4,1.2
	c1,3.2,1.5,6.5,1.9,9.5l0.4-1.2c0.5-2.3,0.3-5.5-1-9.5c-3.7-14.3-8-28.5-13-42.4l-0.3-0.7C523.5,174.6,522.8,173.8,521.9,173.1
	L521.9,173.1z M520.4,172.3l0.7,0.3 M519.6,173.6l-5-0.4c-30.9-2.8-61.6-4.1-92-4.1h-1.8c-30.5,0-61.2,1.4-92,4.1l-5,0.4
	c-5.9,16.3-10.7,31.4-14.3,45.5l-0.4,1.2c-1,3.2-1.7,6.9-2.1,11c0.4,0.4,0.8,0.7,1.5,1c2.1,1,4.3,1.8,6.8,2.3c1.9,0.4,4,0.8,6.2,1.2
	c5.8,0.7,11.3,1,16.7,0.8l22.3-0.3l-22.3-0.6c-4.4-0.1-9-0.4-13.8-1.1c-5.6-0.6-9.1-2.8-10.3-6.6c-0.8-3-0.7-6.9,0.6-11.7
	c2.5-9.2,5.4-18.9,8.8-29.1c2.2-6.8,8.1-10.5,17.6-11.4c26.7-2.1,53.3-3.2,79.8-3.2h1.8c26.5,0,53,1.1,79.8,3.2
	c9.5,0.8,15.4,4.7,17.6,11.4c3.4,10.2,6.3,19.8,8.8,29.1c1.2,4.8,1.4,8.7,0.5,11.7c-1.2,3.9-4.7,6.1-10.3,6.6
	c-4.7,0.6-9.2,1-13.6,1.1l-22.3,0.6l22.3,0.3c5.4,0.1,10.9-0.3,16.5-0.8l6.3-1.2c2.5-0.6,4.8-1.4,6.8-2.3c0.5-0.3,1.1-0.6,1.5-1
	c-0.4-4.1-1.2-7.9-2.1-11l-0.3-1.2C530.4,205,525.7,189.9,519.6,173.6L519.6,173.6z M421.8,125.4c-0.4,0-0.7,0.1-1,0.6h-0.1
	l-0.1,0.6c-0.1,0.3-0.1,0.6-0.1,1l0.1,1.4v13.4c0,2.1-0.1,4.1-0.5,6.1l-0.1,0.4c-0.1,1.1-0.4,2.1-0.8,3.2h5l-0.8-3.2
	c-0.4-2.2-0.6-4.4-0.6-6.8v-13.1l0.1-1.4l-0.1-1l-0.3-0.6C422.5,125.7,422.2,125.4,421.8,125.4L421.8,125.4z"
          />
          <path
            fill="none"
            d="M422.9,141.9c0,2.3,0.1,4.5,0.5,6.8c-0.4-3-0.5-5.4-0.5-7V141.9L422.9,141.9z M420.1,148.4
	c0.3-1.9,0.5-4,0.5-6.1L420.1,148.4z M422.6,172.8h-1.8c-26.5,0-53,1.1-79.8,3.2c-9.5,0.8-15.4,4.7-17.6,11.4
	c-3.4,10.2-6.3,19.8-8.8,29.1c-1.2,4.8-1.4,8.7-0.5,11.7c1.2,3.9,4.7,6.1,10.3,6.6c4.7,0.6,9.2,1,13.8,1.1l22.3,0.6l28.4-0.1h65.6
	l28.4,0.1l22.3-0.6c4.4-0.1,9-0.4,13.6-1.1c5.7-0.6,9.1-2.8,10.3-6.6c0.8-3,0.7-6.9-0.5-11.7c-2.5-9.2-5.4-18.9-8.8-29.1
	c-2.2-6.8-8.1-10.5-17.6-11.4C475.6,173.9,449.1,172.9,422.6,172.8L422.6,172.8z"
          />
          <path fill="none" d="M389,236.3v12.1l9,20l9.4,10.7" />
          <path
            fill="none"
            d="M389,248.4v-12.1l-28.4,0.1l-22.3,0.3c-5.4,0.1-10.9-0.3-16.7-0.8c-2.2-0.3-4.3-0.7-6.2-1.2
	c-2.5-0.6-4.8-1.4-6.8-2.3c-0.5-0.3-1.1-0.6-1.5-1l-0.4-0.4l-3.2,0.3c0.4,1.1,1.1,2.2,1.9,3.2c11.7,16.1,28.1,31.8,49.2,47.4
	c-5.9-15.6-14.1-30-24.4-43.1c11.6,12.7,20.8,27,27.8,43.3c14.7-1.4,29.4-2.2,44.1-2.6c1.7-0.1,3.4-0.1,5.1-0.1l-9.5-10.7L389,248.4
	L389,248.4z M379.6,241.1v0.8h0.7v1.8h-3.2V242h0.8v-0.8H379.6L379.6,241.1z"
          />
          <path fill="none" d="M379.6,242v-0.8h-1.8v1.8h1.8" />
          <path
            fill="none"
            d="M377.8,242.9v-1h-0.7v1.8h3.2V242h-0.7v1 M454.6,248.4l-9,20.1l-9.5,10.7c16.4,0.3,32.9,1.2,49.2,2.8
	c7-16.1,16.4-30.6,27.8-43.3c-10.3,13.1-18.5,27.6-24.4,43.1c21.1-15.6,37.5-31.4,49.2-47.4c0.8-1.1,1.5-2.2,1.9-3.2l-3.2-0.3
	l-0.4,0.4c-0.4,0.4-0.8,0.7-1.5,1c-2.1,1-4.3,1.8-6.8,2.3l-6.3,1.2c-5.8,0.7-11.3,1-16.5,0.8l-22.3-0.3l-28.2-0.3L454.6,248.4
	L454.6,248.4z M463.9,241.1h1.7v0.8h0.8v1.8h-3.2V242h0.7L463.9,241.1L463.9,241.1z"
          />
          <path fill="none" d="M465.6,241.1h-1.7v1.8h1.7v-1" />
          <path
            fill="none"
            d="M463.9,242.9v-1h-0.7v1.8h3.2V242h-0.8v1 M445.6,268.4l9-20v-12.1l-18.5,42.9 M454.6,236.3H389l18.3,42.9h28.8
	 M524.3,176.7L524,176c-0.4-1.2-1.2-2.2-2.1-2.9c0.8,0.7,1.5,1.7,1.9,2.8l0.3,0.7c5,13.9,9.2,28.1,13,42.4c1.1,4,1.5,7.2,1,9.5
	l-0.4,1.2l-0.7,1.1l3.2,0.3c0.6-1.2,0.8-2.5,0.8-3.9c-0.1-1.9-0.5-4.4-1.5-7.2C534.9,205.6,529.9,191,524.3,176.7L524.3,176.7z
	 M521.3,266.6l-2.1,1.2c-7.2,4.3-16,10.7-26.5,19.3c-0.8,0.7-1,1.2-0.5,2.1c0.1,0.4,0.5,0.7,1.1,1.1c2.1,1.7,4.4,3,7,4.3
	c1.5,0.6,3.2,0.7,4.8,0.6c1.9-0.1,4.1-0.5,6.8-1.2c7-1.8,13.5-3.9,19.6-6.1l3.7-1.5l5.1-2.3c1.4-0.8,2.6-1.7,3.7-2.8
	c0-2.1-0.1-4.1-0.5-6.3l-0.1-0.8v-0.7l-0.3-1.7l5.1-2.8c0-1.9-0.1-3.9-0.4-5.8l-0.7-3.9c-0.8,1.1-1.9,1.9-3.3,2.6
	c-1.9,1-4.3,1.4-7,1.7l-6.8,0.5c-1.7,0.3-3.3,0.7-4.8,1.2C523.6,265.5,522.5,265.9,521.3,266.6L521.3,266.6z M518.2,269.7
	c0.4-0.3,0.7-0.4,1.1-0.7l1.5-0.8c0.4-0.3,0.8-0.4,1.2-0.5c1.8-1,3.6-1.7,5.4-2.1c1.5-0.4,3-0.5,4.5-0.4l1.9,0.3
	c1.2,0.3,2.3,0.7,3.6,1.2l0.6,0.3l6.5-2.5l1,3.9l-4.3,2.2c0.3,1.1,0.4,2.2,0.5,3.3c0.1,0.7,0.1,1.5,0.1,2.2l-0.1,1.4
	c0,1-0.5,1.8-1.5,2.5L540,280c-4.1,1.9-8.3,3.7-12.5,5.4l-1.8,0.7l-1.2,0.4c-5,1.8-10.2,3.2-15.6,4.4L508,291l-4-10.9l-2.1,1.7
	c1-0.7,1.8-0.5,2.3,0.5l2.3,6.2c0.3,1.2,0.3,2.2,0,3.2c-0.4,1.1-1.4,1.8-2.9,1.9c-1.4,0.1-2.8-0.1-4.1-0.8c-2.1-1-3.9-2.2-5.5-3.4
	l-0.3-0.3l-0.1-0.3c0-0.3,0.1-0.4,0.4-0.7l7.6-6.5l2.1-1.7C509,275.9,513.8,272.4,518.2,269.7L518.2,269.7z M542.3,279.3l1,1.1
	c-0.8,0.8-1.8,1.5-2.8,2.2l-5.4,2.6l-6.3,2.5c-5.2,1.8-10.9,3.6-16.9,5.1l-4.8,1l0.1-2.1c6.2-1.4,12.3-3,17.9-5.1l3.4-1.2
	c3.6-1.4,7-2.9,10.3-4.4L542.3,279.3L542.3,279.3z"
          />
          <path
            fill="none"
            d="M518.9,270.5l0.3-1.5c-0.3,0.3-0.7,0.4-1.1,0.7c-4.3,2.8-9.1,6.2-14.5,10.3l4,10.9l0.8-0.1
	c5.4-1.2,10.6-2.8,15.6-4.4l1.2-0.4l-1.2-0.6c-1-0.5-1.8-1.2-2.6-2.1c-2.3-2.3-3.4-5.2-3.4-8.5C518.2,273.3,518.4,271.9,518.9,270.5
	L518.9,270.5z M517.3,274.6c0,3.6,1.2,6.6,3.9,9.1c0.7,0.7,1.4,1.2,2.1,1.8c-4.8,1.7-9.8,3-14.7,4.1l-3.4-9.2
	c4.7-3.6,9-6.8,12.8-9.2C517.4,272.4,517.3,273.5,517.3,274.6L517.3,274.6z"
          />
          <path
            fill="none"
            d="M521,283.9c-2.5-2.5-3.9-5.5-3.9-9.1c0-1.2,0.1-2.3,0.4-3.4c-3.9,2.5-8.1,5.5-12.8,9.2l3.4,9.2
	c5.1-1.2,10.1-2.6,14.7-4.1C522.4,285.1,521.7,284.5,521,283.9L521,283.9z M514,281.9v0.3l-0.3,0.4c-0.1,0.1-0.4,0.3-0.7,0.3
	s-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3s0.4,0.1,0.7,0.3l0.3,0.4V281.9
	L514,281.9z"
          />
          <path
            fill="none"
            d="M514,282.2v-0.6l-0.3-0.4c-0.1-0.1-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7
	c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3s0.4-0.1,0.7-0.3L514,282.2L514,282.2z M519.3,269l-0.4,1.5
	c-0.4,1.2-0.7,2.6-0.7,4.1c0,3.3,1.1,6.2,3.4,8.5c0.8,0.8,1.7,1.5,2.6,2.1l1.2,0.5l1.8-0.7l-1.5-0.5c-1.2-0.6-2.3-1.4-3.4-2.3
	c-2.1-2.1-3.2-4.7-3.2-7.6c0-1.9,0.4-3.6,1.2-5.1l0.1-0.3c0.3-0.7,0.8-1.2,1.2-1.8c-0.4,0.1-0.8,0.4-1.2,0.5L519.3,269L519.3,269z"
          />
          <path
            fill="none"
            d="M521.9,267.5c-0.6,0.5-1,1.1-1.2,1.8l-0.1,0.3c-0.8,1.5-1.2,3.3-1.2,5.1c0,3,1.1,5.5,3.2,7.6
	c1,1,2.2,1.8,3.4,2.3l1.5,0.5c4.3-1.7,8.5-3.4,12.5-5.4l0.3-0.1c1-0.7,1.5-1.5,1.5-2.5l0.1-1.4c0-0.8,0-1.5-0.1-2.2
	c-0.1-1.1-0.5-2.1-1.2-2.9l-4-4.3c-0.8-0.6-1.7-1-2.6-1.2l-1.9-0.3c-1.5-0.1-2.9,0.1-4.5,0.4C525.5,265.9,523.7,266.6,521.9,267.5
	L521.9,267.5z M529.8,267.5c2.2,0,4,0.8,5.5,2.3s2.3,3.4,2.3,5.5c0,2.2-0.8,4-2.3,5.5s-3.3,2.3-5.5,2.3h-0.1c-2.2,0-4-0.8-5.5-2.3
	s-2.2-3.3-2.2-5.5s0.8-4,2.2-5.5C525.7,268.3,527.6,267.5,529.8,267.5L529.8,267.5z"
          />
          <path
            fill="none"
            d="M535.3,269.8c-1.5-1.5-3.3-2.3-5.5-2.3s-4,0.8-5.5,2.3s-2.2,3.4-2.2,5.5c0,2.2,0.8,4,2.2,5.5
	c1.5,1.5,3.3,2.3,5.5,2.3h0.1c2.2,0,4-0.8,5.5-2.3s2.3-3.3,2.3-5.5C537.7,273.1,536.8,271.3,535.3,269.8L535.3,269.8z M529.8,268.4
	c1.9,0,3.6,0.7,5,2.1c1.4,1.4,2.1,3,2.1,5c0,1.9-0.7,3.6-2.1,5c-1.4,1.4-3,2.1-5,2.1c-1.9,0-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5
	s0.7-3.6,2.1-5C526.2,269.1,527.9,268.4,529.8,268.4L529.8,268.4z"
          />
          <path
            fill="none"
            d="M534.6,270.5c-1.4-1.4-3-2.1-5-2.1c-1.9,0-3.6,0.7-5,2.1c-1.4,1.4-2.1,3-2.1,5c0,1.9,0.7,3.6,2.1,5
	c1.4,1.4,3,2.1,5,2.1c1.9,0,3.6-0.7,5-2.1c1.4-1.4,2.1-3,2.1-5S536,271.9,534.6,270.5L534.6,270.5z M533.8,265.1
	c1,0.3,1.8,0.7,2.6,1.2l4,4.3c0.7,0.8,1.1,1.8,1.2,2.9c-0.1-1.1-0.3-2.1-0.5-3.3l4.3-2.2l-1-3.9l-6.5,2.5l-0.5-0.3
	C536.1,265.8,534.9,265.4,533.8,265.1L533.8,265.1z M504,282.2c-0.5-1.1-1.2-1.2-2.3-0.5l-7.6,6.5c-0.3,0.3-0.4,0.4-0.4,0.7l0.1,0.3
	l0.3,0.3c1.7,1.2,3.4,2.5,5.5,3.4c1.4,0.7,2.8,1,4.1,0.8c1.5-0.3,2.5-0.8,2.9-1.9c0.3-1,0.3-2.1,0-3.2L504,282.2L504,282.2z
	 M501.1,289.4c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.4,0.8-0.4s0.5,0.1,0.8,0.4
	c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.5-0.4,0.8C501.8,289.2,501.5,289.4,501.1,289.4L501.1,289.4z"
          />
          <path
            fill="none"
            d="M500.3,289.1c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3c0.3-0.3,0.4-0.5,0.4-0.8s-0.1-0.5-0.4-0.8
	c-0.3-0.3-0.5-0.4-0.8-0.4s-0.5,0.1-0.8,0.4c-0.3,0.3-0.3,0.5-0.3,0.8S500.2,288.8,500.3,289.1L500.3,289.1z M542.3,279.5l-3.4,1.7
	c-3.3,1.7-6.8,3-10.3,4.4l-3.4,1.2c-5.8,2.1-11.7,3.7-17.9,5.1l-0.1,2.1l4.8-1c6.1-1.7,11.6-3.3,16.9-5.1l6.3-2.5l5.4-2.6
	c1-0.5,1.9-1.4,2.8-2.2L542.3,279.5L542.3,279.5z"
          />
          <path
            fill="none"
            d="M519.2,267.9l2.1-1.2c1.2-0.5,2.3-1.1,3.6-1.5c1.7-0.5,3.2-1.1,4.8-1.2l6.8-0.5c2.8-0.1,5.1-0.7,7-1.7
	c1.4-0.7,2.5-1.5,3.3-2.6l-0.4-1.9c-2.6,3-5.7,4.5-9.4,4.5l-4.5,0.1c-3.6,0.3-6.9,1.1-10.1,2.5c-0.4,0.1-0.7,0.3-1.1,0.4
	c-8.8,4.5-17.9,11.2-27.6,19.7l-2.5,2.2c-1,1-1,1.9,0,2.9c0.4,0.5,0.8,1,1.5,1.5l0.6,0.4c2.3,1.9,5.4,3.4,9,4.5
	c2.3,0.5,5.8,0.1,10.5-1.1c8.5-2.2,16.3-4.8,23.3-7.6c5.2-2.1,9-4.5,11.2-7.4c0.4-2.8,0.8-5.5,0.8-8.3c-0.3,3.7-1.5,6.8-4,9.2
	c-1.1,1-2.3,1.9-3.7,2.8l-5.1,2.3l-3.7,1.5c-6.1,2.2-12.5,4.3-19.6,6.1c-2.6,0.7-5,1.1-6.8,1.2c-1.7,0.1-3.3,0-4.8-0.5
	c-2.6-1.2-5-2.6-7-4.3c-0.6-0.4-0.8-0.7-1.1-1.1c-0.4-0.7-0.3-1.4,0.5-2.1C503.2,278.5,512,272.1,519.2,267.9L519.2,267.9z"
          />
          <path
            fill="none"
            d="M521.3,264.8c0.4-0.1,0.7-0.3,1.1-0.4c3.2-1.4,6.5-2.2,10.1-2.5l4.5-0.1c3.7,0,6.8-1.5,9.4-4.5l1-1.2
	c1.2-1.9,2.2-3.9,3-5.9l-0.3-1.2l-0.8-3.6c-2.9-6.2-5.9-10.9-9.1-13.9c-0.4,1.1-1.1,2.2-1.9,3.2c-11.7,16.1-28.1,31.8-49.2,47.4
	c5.9-15.6,14.1-30,24.4-43.1c-11.6,12.7-20.8,27-27.8,43.3l8.4,2.6C503.3,276,512.4,269.4,521.3,264.8L521.3,264.8z"
          />
          <path
            fill="none"
            d="M543.7,223l-1.7-4.7l2.5,9c1.5,5.8,3,11.7,4.4,17.8l0.8,3.6l1.9-0.8l0.1-1l-2.5-7.4L543.7,223L543.7,223z
	 M558.9,231.8c-0.4,1.4-0.7,2.9-0.8,4.5c-0.1,1.1,0,2.2,0,3.4c0.1,1.7,0.4,3.3,0.8,5.1c1.1,1.1,3.4,1.4,6.9,1.2l14.2-1.4
	c2.1-2.5,4.5-4.4,7.3-5.5c2.1-0.8,3.9-1.7,5.4-2.6c0.1-2.1-0.7-3.6-2.5-4.5c-7.3-3.4-15.6-5-24.7-4.8
	C561.8,227.5,559.6,229,558.9,231.8L558.9,231.8z"
          />
          <path
            fill="none"
            d="M558.2,239.9c-0.1-1.2-0.1-2.3,0-3.4l-1.1-1.9c-1.7-2.8-3.9-5.4-6.3-7.7l1,2.9c0.7,1.4,1.1,2.9,1.5,4.5
	c1.5,1.2,2.9,2.5,3.9,3.9L558.2,239.9L558.2,239.9z"
          />
          <path
            fill="none"
            d="M559,244.9c-0.4-1.8-0.7-3.4-0.8-5.1l-1.2-1.8c-1-1.2-2.2-2.6-3.9-3.9c0.4,1.8,0.7,3.7,0.8,5.8v0.1
	c0,3.7-0.4,8-1.4,12.8v0.1c5.8,6.9,10.2,15.2,13.4,24.8l0.1-3.3c0.3-3.7,0.3-7.4,0.3-11l-2.8,5.4c-2.2-4.5-4.8-9.1-8-13.6l1.5-3
	l1.5-3c0.6-1.1,0.7-2.3,0.4-3.6V244.9L559,244.9z"
          />
          <path
            fill="none"
            d="M565.9,246.1c-3.4,0.3-5.8-0.1-6.9-1.2l0.1,0.7c0.3,1.2,0.1,2.5-0.4,3.6l-1.5,3c2.6,0.8,5.2,1.4,8,1.9l5.2,0.7
	l1.4-2.9c0.1-0.3,0.3-0.6,0.5-0.8l0.3-0.3c0.5-0.4,1.1-0.6,1.9-0.7c7.6-0.3,12.8-2.6,15.6-6.9l-10.1,1.5L565.9,246.1L565.9,246.1z
	 M552.3,232.1l-3.3,1.4c0.4,2.1,0.7,4.1,0.7,6.2l2.5,7.4c0.5-3.4,0.8-6.8,0.8-9.5v-0.1C552.7,235.4,552.5,233.7,552.3,232.1
	L552.3,232.1z"
          />
          <path
            fill="none"
            d="M551.6,229.6l0.6,2.3c0.3,1.7,0.6,3.4,0.7,5.2v0.1c0,2.9-0.3,6.1-0.8,9.5l-0.1,1l-0.8,4
	c0.3,0.8,0.7,1.7,1.1,2.5l0.1,0.3l0.4-1.7v-0.1c1-4.8,1.5-9.1,1.4-12.8v-0.1c-0.1-2.1-0.3-4-0.8-5.8
	C552.8,232.6,552.3,231.1,551.6,229.6L551.6,229.6z"
          />
          <path fill="none" d="M551.8,247.9l-1.9,0.7l0.3,1.4l0.7,1.9" />
          <path
            fill="none"
            d="M550.9,251.9l-0.7-1.9c-0.8,2.1-1.8,4-3,5.9c1.9,8.4,2.3,15.8,1.1,22c0.8,1.4,1.9,2.6,3.3,3.6
	c-0.3-1.2-0.4-2.5-0.4-3.9l1-9.9c0-1.2,0.5-1.7,1.7-1l7,6.1c-2.5-5.9-5.4-12-8.7-18.2l-0.1-0.3C551.7,253.5,551.3,252.7,550.9,251.9
	L550.9,251.9z"
          />
          <path
            fill="none"
            d="M552.5,253.1l-0.4,1.7c3.2,6.2,6.1,12.3,8.7,18.2l1.1,1c0.4,0.3,0.8,0.7,1.1,1.1c0.5,0.7,1,1.7,1.4,2.8
	c0.8,2.5,1.5,5.2,2.1,8c-0.4-2.6-0.7-5.2-0.6-7.9C562.7,268.3,558.3,259.9,552.5,253.1L552.5,253.1z M546.3,257.1l0.4,1.9l0.7,3.9
	c0.3,1.9,0.4,3.9,0.4,5.8v3.2c-0.1,2.8-0.4,5.5-0.8,8.3c0.5-0.7,1-1.4,1.4-2.2c1.2-6.3,0.8-13.6-1.1-22L546.3,257.1L546.3,257.1z"
          />
          <path
            fill="none"
            d="M553.9,266.8c-1.1-0.7-1.7-0.3-1.7,1l-1.1,9.9c0,1.4,0.1,2.6,0.4,3.9l8,4.5l1.1-7.6c0.7-3,1.5-4.3,2.3-3.4
	c-0.3-0.4-0.7-0.8-1.1-1.1l-1.1-1L553.9,266.8L553.9,266.8z M547.8,271.9v-3.2l-5.1,2.8l0.3,1.7v0.7l0.1,0.8
	c0.3,2.2,0.4,4.3,0.5,6.3C546.3,278.6,547.7,275.6,547.8,271.9L547.8,271.9z M569.3,257l1.1-2.2l-5.2-0.7c-2.8-0.6-5.4-1.1-8-1.9
	l-1.5,3c3.2,4.5,5.8,9.1,8,13.6l2.8-5.4v-0.4l0.1-0.4L569.3,257L569.3,257z"
          />
          <path
            fill="none"
            d="M569.3,259.9V257l-2.6,5.6C568.4,262,569.3,261,569.3,259.9L569.3,259.9z M592.5,238.3l0.3-1.8
	c-1.5,1-3.3,1.8-5.4,2.6c-2.8,1.1-5.2,3-7.3,5.5l10.1-1.5l0.5-1.1L592.5,238.3L592.5,238.3z M590,238.9l0.1-0.1l0.3-0.1h0.3l0.1,0.1
	v0.1l-1.2,3.4l-0.1,0.1l-0.3,0.1h-0.3l-0.3-0.1v-0.1L590,238.9L590,238.9z"
          />
          <path
            fill="none"
            d="M590.2,238.8l-0.1,0.1l-1.2,3.4v0.3h0.7l0.3-0.3l1.2-3.4v-0.1l-0.3-0.1h-0.3 M329.3,161.5
	c1-1.7,2.1-2.8,3.6-3.2c-7.4,2.1-12.4,5.9-14.7,11.4l-1.1,2.2l-1.8,4.5c-2.2,5.6-4.4,11.6-6.5,17.8l-3.7,11.7c-1.2,4-2.3,8-3.6,12.3
	c-0.8,2.9-1.7,5.9-2.5,9c-1.5,5.8-3,11.7-4.4,17.8c2.9-6.2,5.9-10.9,9.1-13.9c-0.5-1.2-0.8-2.5-0.8-3.9c0.1-1.9,0.5-4.4,1.5-7.2
	c4.3-14.7,9.2-29.2,14.9-43.5l0.3-0.7c0.4-1.2,1.2-2.2,2.1-2.9l0.3-0.3c0.1-0.1,0.4-0.1,0.5-0.3c0,0,0.1,0,0.1-0.1l0.4-0.3l0.3-0.1
	L329.3,161.5L329.3,161.5z"
          />
          <path
            fill="none"
            d="M332.9,158.3c-1.5,0.6-2.6,1.5-3.6,3.2l-6.1,10.5c0.5-0.3,1.2-0.4,1.9-0.4l2.9-0.3l6.2-10.5
	c1-1.7,2.3-2.9,4-3.6l-1.1,0.1C335.8,157.6,334.4,157.9,332.9,158.3L332.9,158.3z M321.9,172.8l-0.3,0.3c-0.8,0.7-1.5,1.7-1.9,2.8
	l-0.3,0.7c-5,13.9-9.2,28.1-13,42.4c-1.1,4-1.5,7.2-1,9.5l0.4,1.2c0.4-3.2,1-6.3,1.9-9.5l0.4-1.2c3.7-14.3,8.5-29.9,14.7-46.7
	l-0.4,0.3c0,0-0.1,0-0.1,0.1C322.3,172.7,322.1,172.8,321.9,172.8L321.9,172.8z"
          />
          <path
            fill="none"
            d="M323.8,173.6l-0.5-1.5l-0.3,0.1c-6.2,16.8-11,32.4-14.7,46.7l-0.4,1.2c-1,3.2-1.5,6.5-1.9,9.5l0.7,1.1l0.4,0.4
	c0.4-4.1,1.1-7.9,2.1-11l0.4-1.2C313.2,205,317.9,189.9,323.8,173.6L323.8,173.6z M323.4,172.1l0.5,1.5l5-0.4l-0.5-1.8l-2.9,0.3
	C324.6,171.7,323.9,171.8,323.4,172.1L323.4,172.1z"
          />
          <path
            fill="none"
            d="M323,172.3h0.1l-0.6,0.3 M250.8,236.6c0,0.6,0.1,1.1,0.3,1.8l1.7,4l0.6,1.1l10.1,1.5c-2.1-2.5-4.5-4.4-7.3-5.5
	C254.1,238.4,252.3,237.6,250.8,236.6L250.8,236.6z M252.8,238.7h0.3l0.3,0.1l0.1,0.1l1.2,3.4v0.1l-0.3,0.1h-0.3l-0.3-0.1l-0.1-0.1
	l-1.2-3.4v-0.1C252.7,238.7,252.7,238.7,252.8,238.7L252.8,238.7z"
          />
          <path
            fill="none"
            d="M253,238.7h-0.3c-0.1,0-0.1,0-0.1,0.1v0.1l1.2,3.4l0.1,0.1l0.3,0.1h0.3l0.3-0.1v-0.1l-1.2-3.4l-0.1-0.1
	L253,238.7L253,238.7z M250.8,236.6c1.5,1,3.3,1.8,5.4,2.6c2.8,1.1,5.2,3,7.3,5.5l14.2,1.4c3.4,0.3,5.8-0.1,6.9-1.2
	c0.4-1.8,0.7-3.4,0.8-5.1c0.1-1.2,0.1-2.3,0-3.4c-0.1-1.7-0.3-3.2-0.8-4.5c-0.7-2.8-2.9-4.3-6.8-4.5c-9.1-0.1-17.4,1.4-24.7,4.8
	C251.5,233,250.5,234.5,250.8,236.6L250.8,236.6z"
          />
          <path
            fill="none"
            d="M292.9,226.7v-0.1c-3.2,3-5.6,6.3-7.6,9.8c0.1,1.1,0,2.2,0,3.4c1-1.9,2.8-3.7,5-5.6c0.4-1.7,1-3.2,1.5-4.5
	l0.7-2.3L292.9,226.7L292.9,226.7z"
          />
          <path
            fill="none"
            d="M291.3,232.1l0.5-2.3c-0.7,1.4-1.1,2.9-1.5,4.5c-0.4,1.8-0.7,3.7-0.8,5.8v0.1c0,3.7,0.4,8,1.4,12.8v0.1l0.4,1.7
	l1.2-2.8l-0.8-4l-0.1-1c-0.5-3.4-0.8-6.8-0.8-9.5v-0.1C290.7,235.4,291,233.7,291.3,232.1L291.3,232.1z"
          />
          <path
            fill="none"
            d="M294.7,233.3l-3.3-1.4c-0.3,1.7-0.5,3.4-0.7,5.2v0.1c0,2.9,0.3,6.1,0.8,9.5l2.5-7.4
	C294,237.6,294.2,235.5,294.7,233.3L294.7,233.3z M285.4,239.9c-0.1,1.7-0.4,3.3-0.8,5.1l-0.1,0.7c-0.3,1.2-0.1,2.5,0.4,3.6l1.5,3
	l1.5,3c-3.2,4.5-5.8,9.1-8,13.6l-2.8-5.8c0,3.9,0.1,7.6,0.3,11.4l0.1,3.3c3.2-9.6,7.7-17.9,13.4-24.8V253c-1-4.8-1.5-9.1-1.4-12.8
	V240c0.1-2.1,0.4-4,0.8-5.8C288.1,236,286.3,238,285.4,239.9L285.4,239.9z"
          />
          <path
            fill="none"
            d="M284.5,244.9c-1.1,1.1-3.4,1.4-6.9,1.2l-14.2-1.4l-10.1-1.5c2.9,4.4,8,6.6,15.6,6.9c1.5,0.1,2.3,0.7,2.9,1.8
	l1.4,2.9c4.5-0.4,9-1.4,13.1-2.6l-1.5-3c-0.5-1.1-0.7-2.3-0.4-3.6L284.5,244.9L284.5,244.9z M294,239.5l-2.5,7.4l0.1,1l1.9,0.8
	l0.8-3.6c1.5-6.2,2.9-12.1,4.4-17.8c0.8-3,1.7-6.1,2.5-9l-1.7,4.7L294,239.5L294,239.5z"
          />
          <path
            fill="none"
            d="M276.8,262.6l0.1,0.4l2.8,5.8c2.2-4.5,4.8-9.1,8-13.6l-1.5-3c-4.3,1.2-8.5,2.2-13.1,2.6l1.1,2.2L276.8,262.6
	L276.8,262.6z"
          />
          <path
            fill="none"
            d="M274.2,259.9c0,1.1,0.8,2.1,2.6,2.8l-2.6-5.6V259.9L274.2,259.9z M293.6,248.6l-0.3,1.2c0.8,2.1,1.8,4,3,5.9
	l1,1.2c2.6,3,5.6,4.5,9.4,4.5l4.5,0.1c3.6,0.3,6.9,1.1,10.1,2.5c0.4,0.1,0.7,0.3,1.1,0.4c8.8,4.5,17.9,11.2,27.6,19.7l8.4-2.6
	c-7-16.1-16.4-30.6-27.8-43.3c10.3,13.1,18.5,27.6,24.4,43.1c-21.1-15.6-37.5-31.4-49.2-47.4c-0.8-1.1-1.5-2.2-1.9-3.2
	c-3.2,3-6.2,7.6-9.1,13.9L293.6,248.6L293.6,248.6z"
          />
          <path fill="none" d="M293.2,250l0.4-1.4l-1.9-0.7l0.8,4" />
          <path
            fill="none"
            d="M293.2,250l-0.7,1.9l-1.2,2.8c-3.2,6.2-6.1,12.3-8.7,18.2l7-6.1c1.1-0.7,1.7-0.3,1.7,1l1,9.9
	c0,1.4-0.1,2.6-0.4,3.9c1.2-1,2.3-2.2,3.3-3.6c-1.2-6.3-0.8-13.6,1.1-22C295.1,254,294,252,293.2,250L293.2,250z"
          />
          <path
            fill="none"
            d="M296.8,259.1l0.4-1.9l-1-1.2c-1.9,8.4-2.3,15.8-1.1,22c0.4,0.7,0.8,1.5,1.4,2.2c-0.4-2.8-0.8-5.5-0.8-8.3v-3.2
	c0-1.9,0.1-3.9,0.4-5.8L296.8,259.1L296.8,259.1z"
          />
          <path
            fill="none"
            d="M297.2,257.1l-0.4,1.9c0.8,1.1,1.9,1.9,3.3,2.6c1.9,1,4.3,1.4,7,1.7l6.8,0.5c1.7,0.3,3.3,0.7,4.8,1.2
	c1.2,0.4,2.3,1,3.6,1.5l2.1,1.2c7.2,4.3,16,10.7,26.5,19.3c0.8,0.7,1,1.2,0.5,2.1c-0.1,0.4-0.5,0.7-1.1,1.1c-2.1,1.7-4.4,3-7,4.3
	c-1.5,0.6-3.2,0.7-4.8,0.6c-1.9-0.1-4.1-0.6-6.8-1.2c-7-1.8-13.6-3.9-19.6-6.1l-3.7-1.5c-1.1-0.4-2.2-1-3.2-1.4l-1.9-1
	c-1.4-0.8-2.6-1.7-3.7-2.8c-2.5-2.5-3.7-5.5-4-9.2c0.1,2.8,0.4,5.5,0.8,8.3c2.2,2.9,5.9,5.4,11.2,7.4c7,2.8,14.7,5.4,23.3,7.6
	c4.5,1.2,8.1,1.5,10.5,1.1c3.6-1.1,6.5-2.6,9-4.5l0.6-0.4c0.5-0.4,1.1-1,1.5-1.5c0.8-1,0.8-1.9-0.1-2.9l-2.3-2.2
	c-9.6-8.5-18.7-15.2-27.6-19.7c-0.4-0.1-0.7-0.3-1.1-0.4c-3.2-1.4-6.5-2.2-10.1-2.5l-4.5-0.1C302.9,261.7,299.8,260.2,297.2,257.1
	L297.2,257.1z M291.3,254.6l-0.4-1.7c-5.8,6.9-10.2,15.2-13.4,24.8c0,2.6-0.1,5.2-0.5,7.9c0.5-2.8,1.2-5.5,2.1-8
	c0.4-1.1,0.8-2.1,1.4-2.8c0.3-0.4,0.7-0.8,1.1-1.1l1.1-1C285.2,266.9,288.1,260.9,291.3,254.6L291.3,254.6z"
          />
          <path
            fill="none"
            d="M296.1,262.9c-0.3,1.9-0.4,3.9-0.4,5.8l5.1,2.8l-0.3,1.7v0.7l-0.1,0.8c-0.3,2.2-0.4,4.3-0.6,6.3
	c1.1,1,2.2,1.9,3.7,2.8l1.9,1c1,0.4,2.1,1,3.2,1.4l3.7,1.5c5.9,2.2,12.5,4.3,19.6,6.1c2.6,0.7,5,1.1,6.8,1.2c1.7,0.1,3.3,0,4.8-0.5
	c2.6-1.2,5-2.6,7-4.3c0.5-0.4,0.8-0.7,1.1-1.1c0.4-0.7,0.3-1.4-0.5-2.1c-10.5-8.5-19.4-15-26.5-19.3l-2.1-1.2
	c-1.2-0.6-2.3-1.1-3.6-1.5c-1.7-0.5-3.2-1.1-4.8-1.2l-6.8-0.5c-2.8-0.1-5.1-0.7-7-1.7c-1.4-0.7-2.5-1.5-3.3-2.6L296.1,262.9
	L296.1,262.9z M302.4,270.4l-4.3-2.2l1-3.9l6.5,2.5l0.5-0.3c1.1-0.5,2.3-1,3.6-1.2l1.9-0.3c1.5-0.1,2.9,0.1,4.5,0.4
	c1.8,0.5,3.6,1.2,5.4,2.1l2.6,1.5l1.1,0.7c4.4,2.8,9.1,6.2,14.5,10.3l2.1,1.7c2.5,2.1,5,4.1,7.6,6.5c0.3,0.3,0.4,0.4,0.4,0.7
	l-0.1,0.3l-0.3,0.3c-1.7,1.2-3.4,2.5-5.5,3.4c-1.4,0.7-2.8,1-4.1,0.8c-1.5-0.3-2.5-0.8-2.9-1.9c-0.3-1-0.3-2.1,0-3.2l2.3-6.2
	c0.5-1.1,1.2-1.2,2.3-0.5l-2.1-1.7l-4,10.9l-0.8-0.1c-5.4-1.2-10.6-2.8-15.6-4.4l-1.2-0.4l-1.8-0.7c-4.3-1.7-8.5-3.4-12.5-5.4
	l-0.3-0.1c-1-0.7-1.5-1.5-1.5-2.5l-0.1-1.4c0-0.8,0-1.5,0.1-2.2C302,272.6,302.2,271.5,302.4,270.4L302.4,270.4z M336.3,291.9
	l0.1,2.1l-4.8-1c-6.1-1.7-11.6-3.3-16.9-5.1l-6.3-2.5l-5.4-2.6c-1-0.6-1.9-1.4-2.8-2.2l0.8-1.1v-0.1l3.3,1.7c3.3,1.7,6.8,3,10.3,4.4
	l3.4,1.2C324.1,288.7,330,290.5,336.3,291.9L336.3,291.9z"
          />
          <path
            fill="none"
            d="M298.2,268l4.3,2.2c-0.3,1.1-0.4,2.2-0.5,3.3c0.1-1.1,0.5-2.1,1.2-2.9l4-4.3c0.8-0.6,1.7-1,2.6-1.2
	c-1.2,0.3-2.3,0.7-3.6,1.2l-0.5,0.3l-6.5-2.5L298.2,268L298.2,268z"
          />
          <path
            fill="none"
            d="M307.1,266.4l-4,4.3c-0.7,0.8-1.1,1.8-1.2,2.9c-0.1,0.7-0.1,1.5-0.1,2.2l0.1,1.4c0,1,0.5,1.8,1.5,2.5l0.3,0.1
	c4.1,1.9,8.3,3.7,12.5,5.4l1.5-0.5c1.2-0.5,2.3-1.4,3.4-2.3c2.1-2.1,3.2-4.7,3.2-7.6c0-1.9-0.4-3.6-1.2-5.1l-0.1-0.3
	c-0.3-0.7-0.8-1.2-1.2-1.8c-1.8-1-3.6-1.7-5.4-2.1c-1.5-0.4-3-0.5-4.5-0.4l-1.9,0.3C308.8,265.4,308,265.8,307.1,266.4L307.1,266.4z
	 M308.2,269.8c1.5-1.5,3.3-2.3,5.5-2.3s4,0.8,5.5,2.3s2.2,3.4,2.2,5.5c0,2.2-0.8,4-2.2,5.5c-1.5,1.5-3.4,2.3-5.5,2.3h-0.1
	c-2.2,0-4-0.8-5.5-2.3s-2.3-3.3-2.3-5.5C305.9,273.1,306.7,271.3,308.2,269.8L308.2,269.8z"
          />
          <path
            fill="none"
            d="M313.7,267.5c-2.2,0-4,0.8-5.5,2.3s-2.3,3.4-2.3,5.5c0,2.2,0.8,4,2.3,5.5s3.3,2.3,5.5,2.3h0.1
	c2.1,0,4-0.8,5.5-2.3s2.2-3.3,2.2-5.5s-0.8-4-2.2-5.5C317.7,268.3,316,267.5,313.7,267.5L313.7,267.5z M308.8,270.5
	c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5,2.1s2.1,3,2.1,5c0,1.9-0.7,3.6-2.1,5s-3,2.1-5,2.1s-3.6-0.7-5-2.1s-2.1-3-2.1-5
	S307.4,271.9,308.8,270.5L308.8,270.5z"
          />
          <path
            fill="none"
            d="M313.7,268.4c-1.9,0-3.6,0.7-5,2.1s-2.1,3-2.1,5c0,1.9,0.7,3.6,2.1,5s3,2.1,5,2.1s3.6-0.7,5-2.1s2.1-3,2.1-5
	s-0.7-3.6-2.1-5S315.7,268.4,313.7,268.4L313.7,268.4z"
          />
          <path
            fill="none"
            d="M322.8,269.3l0.1,0.3c0.8,1.5,1.2,3.3,1.2,5.1c0,3-1.1,5.5-3.2,7.6c-1,1-2.2,1.8-3.4,2.3l-1.5,0.5l1.8,0.7
	l1.2-0.6c1-0.5,1.8-1.2,2.6-2.1c2.3-2.3,3.4-5.2,3.4-8.5c0-1.5-0.3-2.9-0.7-4.1l-0.3-1.5l-2.6-1.5
	C322.1,268,322.6,268.7,322.8,269.3L322.8,269.3z"
          />
          <path
            fill="none"
            d="M324.6,270.5c0.5,1.2,0.7,2.8,0.7,4.1c0,3.3-1.1,6.2-3.4,8.5c-0.8,0.8-1.7,1.5-2.6,2.1l-1.2,0.5l1.2,0.4
	c5,1.8,10.2,3.2,15.6,4.4l0.8,0.1l4-10.9c-5.2-4.1-10.1-7.6-14.5-10.3l-1.1-0.7L324.6,270.5L324.6,270.5z M322.4,283.9
	c2.5-2.5,3.9-5.5,3.9-9.1c0-1.2-0.1-2.3-0.4-3.4c3.9,2.5,8.1,5.5,12.8,9.2l-3.4,9.2c-5.1-1.2-10.1-2.6-14.7-4.1
	C321.2,285.1,321.9,284.6,322.4,283.9L322.4,283.9z"
          />
          <path
            fill="none"
            d="M326.3,274.6c0,3.6-1.2,6.6-3.9,9.1c-0.7,0.7-1.4,1.2-2.1,1.8c4.8,1.7,9.8,3,14.7,4.1l3.4-9.2
	c-4.7-3.6-9-6.8-12.8-9.2C326.1,272.4,326.3,273.5,326.3,274.6L326.3,274.6z M329.6,282.2v-0.6c0-0.1,0.1-0.3,0.3-0.4
	c0.1-0.1,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7c0,0.3-0.1,0.4-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3
	c-0.3,0-0.4-0.1-0.7-0.3L329.6,282.2L329.6,282.2z"
          />
          <path
            fill="none"
            d="M329.5,281.9v0.3l0.3,0.4c0.1,0.1,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7
	c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3c-0.3,0-0.4,0.1-0.7,0.3c-0.1,0.1-0.1,0.3-0.3,0.4L329.5,281.9L329.5,281.9z M341.7,281.7
	c-1-0.7-1.8-0.5-2.3,0.5l-2.3,6.2c-0.3,1.2-0.3,2.2,0,3.2c0.4,1.1,1.4,1.8,2.9,1.9c1.4,0.1,2.8-0.1,4.1-0.8c2.1-1,3.9-2.2,5.5-3.4
	l0.3-0.3l0.1-0.3c0-0.3-0.1-0.4-0.4-0.7C346.7,285.8,344.2,283.7,341.7,281.7L341.7,281.7z M343.5,288.3c0,0.3-0.1,0.6-0.3,0.8
	c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.4-0.5-0.4-0.8s0.1-0.5,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4
	c0.3,0,0.5,0.1,0.8,0.4C343.4,287.6,343.5,287.9,343.5,288.3L343.5,288.3z"
          />
          <path
            fill="none"
            d="M343.2,289.1c0.3-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.5,0.1-0.8,0.4
	c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.5,0.4,0.8c0.3,0.3,0.5,0.3,0.8,0.3C342.7,289.4,343,289.2,343.2,289.1L343.2,289.1z M336.5,293.8
	l-0.1-2.1c-6.2-1.4-12.3-3-17.9-5.1l-3.4-1.2c-3.6-1.4-7-2.9-10.3-4.4l-3.3-1.7v0.1l-0.8,1.1c0.8,0.8,1.8,1.5,2.8,2.2l5.4,2.6
	l6.3,2.5c5.2,1.8,10.9,3.6,16.9,5.1L336.5,293.8L336.5,293.8z M291.3,267.7c0-1.2-0.5-1.7-1.7-1l-7,6.1l-1.1,1
	c-0.4,0.3-0.7,0.7-1.1,1.1c0.8-0.8,1.5,0.4,2.2,3.4l1.1,7.6l8-4.5c0.3-1.2,0.4-2.5,0.4-3.9L291.3,267.7L291.3,267.7z M295.7,268.8
	v3.2c0.3,3.7,1.5,6.8,4,9.2c0-2.1,0.1-4.1,0.6-6.3l0.1-0.8v-0.7l0.3-1.7L295.7,268.8L295.7,268.8z M319.4,176.5l0.3-0.7
	c0.4-1.1,1.1-2.1,1.9-2.8c-1,0.7-1.7,1.7-2.1,2.9l-0.3,0.7c-5.6,14.3-10.6,28.9-14.9,43.5c-1,2.8-1.4,5.2-1.5,7.2
	c0,1.2,0.3,2.5,0.8,3.9l3.2-0.3l-0.7-1.1l-0.4-1.2c-0.5-2.3-0.3-5.5,1-9.5C310.2,204.6,314.4,190.4,319.4,176.5L319.4,176.5z"
          />
          <path
            fill="none"
            d="M358.8,283.4l-0.7-1.5l-8.3,2.6l2.3,2.2c1,1,1,1.9,0.1,2.9c-0.4,0.5-0.8,1-1.5,1.5l-0.6,0.4
	c-2.3,1.9-5.4,3.4-9,4.5c-2.3,0.5-5.8,0.1-10.5-1.1c-8.5-2.2-16.3-4.8-23.3-7.6c-5.2-2.1-9-4.5-11.2-7.4c-0.6-0.7-1-1.4-1.4-2.2
	l0.3,1.2c1.7,5.5,5.6,9.6,12.1,12.3c7,2.8,14.7,5.2,23.3,7.6c7,1.5,12.5,1,16.8-1.7l2.8-1.9c0.1-0.1,0.3-0.1,0.3-0.3l2.1-1.5
	l3.6-3.2C358.8,288.4,359.5,286.1,358.8,283.4L358.8,283.4z"
          />
          <path
            fill="none"
            d="M358.1,281.9l0.7,1.5c0.8,2.5,0,5-2.3,7l-3.6,3.2l-2.1,1.5c-0.1,0.1-0.1,0.1-0.3,0.3l-2.8,1.9
	c-4.1,2.8-9.8,3.2-16.8,1.6c-8.5-2.2-16.3-4.8-23.3-7.6c-6.5-2.6-10.6-6.8-12.1-12.3l-0.3-1.2c-0.8,1.4-1.9,2.6-3.3,3.6l-0.7,2.5
	c-1.2,3.2-1.5,6.3-0.8,9.5c0.1,3.7,2.3,6.8,6.6,9.4c7.9,5.1,23.3,9.2,46.2,12.3c0.1-0.6,0.4-1,0.8-1.5c5.1-5.4,10.7-10.2,17.1-14.5
	l4.3-2.2c5.1-2.3,10.6-3.6,16.7-4l40.8-1.2l39,1.2c6.1,0.4,11.6,1.7,16.7,4l4.3,2.2c6.3,4.1,12,9,17.1,14.5c0.4,0.5,0.7,1,1,1.5
	c22.9-3.2,38.2-7.3,46-12.3c4.1-2.6,6.3-5.8,6.6-9.4c0.5-3,0.3-6.2-0.8-9.5l-0.7-2.5c-1.2-1-2.3-2.2-3.3-3.6l-0.3,1.2
	c-1.7,5.5-5.7,9.6-12.1,12.3c-7,2.8-14.7,5.2-23.3,7.6c-7,1.5-12.5,1-16.8-1.7l-5.1-3.6c-1.4-1.1-2.6-2.1-3.6-3.2
	c-2.3-2.2-3.2-4.5-2.3-7l0.7-1.5c-16.4-1.5-32.8-2.3-49.2-2.8h-28.8c-1.7,0-3.4,0-5.1,0.1C387.5,279.7,372.9,280.5,358.1,281.9
	L358.1,281.9z"
          />
          <path
            fill="none"
            d="M344.2,317.2c-0.1-0.5-0.1-1.1,0-1.5c0.1-0.4,0.3-0.8,0.7-1.2c1.2-1.4,2.5-2.6,3.9-3.9l1.5-1.5
	c1.8-1.7,3.6-3.2,5.4-4.5l1.9-1.5l4-2.9h0.1l0.7-0.3c1-0.5,1.9-1.1,3-1.7s2.3-1.1,3.6-1.5c4-1.5,8.4-2.3,13.1-2.6l2.1-0.1l0.8-0.1
	l24.5-1h24.8l24.5,1l0.8,0.1l2.1,0.1c4.7,0.3,9.1,1.1,13.1,2.6c1.2,0.4,2.3,1,3.6,1.5c1,0.4,2.1,1,3,1.7l0.7,0.3h0.1l4,2.9l1.9,1.5
	c1.9,1.4,3.7,2.9,5.4,4.5l1.5,1.5c1.2,1.2,2.6,2.5,3.9,3.9c0.3,0.4,0.5,0.8,0.7,1.2c0.1,0.5,0.1,1.1,0,1.5l-0.3,0.4
	c-1,1.5-2.2,2.9-3.7,4.1l-2.3,1.5c-4.4,2.3-10.5,3.4-18.2,3.4h-2.5l-0.4,1.4h2.6c13.2,0,21.6-3.2,25.5-9.6c0.7-1.1,0.8-2.2,0.4-3.4
	c-0.3-0.5-0.5-1-1-1.5c-5.1-5.4-10.7-10.2-17.1-14.5l-4.3-2.2c-5.1-2.3-10.6-3.6-16.7-4l-39-1.2l-40.8,1.2c-6.1,0.4-11.6,1.7-16.7,4
	L361,299c-6.3,4.1-12,9-17.1,14.5c-0.4,0.5-0.7,1-0.8,1.5c-0.4,1.1-0.4,2.2,0.3,3.4c3.7,6.5,12.3,9.6,25.5,9.6h2.6l-0.4-1.4h-2.2
	c-7.7,0-13.8-1.1-18.2-3.4l-2.3-1.5c-1.5-1.1-2.8-2.5-3.7-4.1L344.2,317.2L344.2,317.2z"
          />
          <path
            fill="none"
            d="M344.2,315.7c-0.1,0.6-0.1,1.1,0,1.5h154.9c0.1-0.5,0.1-1.1,0-1.5L344.2,315.7L344.2,315.7z M344.9,314.5
	c-0.3,0.4-0.5,0.8-0.7,1.2h22.3l-1.7-5.1h-16.3C347.4,311.8,346.1,313.2,344.9,314.5L344.9,314.5z"
          />
          <path
            fill="none"
            d="M343.2,318.4c-0.7-1.1-0.8-2.2-0.3-3.4c-22.9-3.2-38.2-7.3-46.2-12.3c-4.1-2.6-6.3-5.8-6.6-9.4
	c-0.5-3-0.3-6.2,0.8-9.5l0.7-2.5l-8,4.5l3.7,23.6l-3.7-23.6l-6.8,12.1l5.8,36.4l2.6,15.3h17l-2.3-0.4c-2.2-0.4-3.4-1.5-4-3.4
	c-1.7-5.8-3-11.9-4.3-18c-0.3-1.1-0.1-1.8,0.5-2.2c0.4-0.4,1.2-0.5,2.3-0.3h0.1l26.5,8.3c4.1,1.5,8,4.1,11.4,7.9l0.1,0.1
	c3,3.2,5.5,6.3,7.4,9.6c0.8,1.2,0.8,2.1,0.1,2.6c-0.4,0.5-1.5,0.7-3,0.5l-13.6-1.2l39.5,5.6c-1.4-0.4-2.6-1-4-1.8
	c-3.7-2.5-7-6.2-10.1-11.3l-0.1-0.4l-6.2-10.2c2.5,3,5.1,5.6,7.9,8l0.1,0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-0.3
	c0.1-0.1,0.3-0.1,0.3-0.1h140.7c0.1,0,0.3,0,0.3,0.1l0.1,0.3l-0.1,0.3l-0.1,0.1l0.1-0.1c2.8-2.3,5.4-5.1,7.9-8l-6.2,10.2l-0.1,0.4
	c-3,5.1-6.3,8.8-10.1,11.3c-1.2,0.8-2.6,1.5-4,1.8l39.5-5.6l-13.6,1.2c-1.5,0.1-2.6,0-3-0.5c-0.7-0.6-0.7-1.4,0.1-2.6
	c1.9-3.2,4.4-6.5,7.4-9.6l0.1-0.1c3.4-3.9,7.3-6.5,11.4-7.9l26.5-8.3h0.1c1.1-0.3,1.9-0.1,2.3,0.3c0.6,0.4,0.8,1.1,0.6,2.2
	c-1.1,6.2-2.6,12.3-4.3,18c-0.4,1.9-1.8,3-4,3.4l-2.3,0.4h17l2.6-15.3l5.8-36.4l-6.8-12.1l-3.7,23.6l3.7-23.6l-8-4.5l0.7,2.5
	c1.2,3.2,1.5,6.3,0.8,9.5c-0.1,3.7-2.3,6.8-6.6,9.4c-7.9,5.1-23.1,9.2-46,12.3c0.4,1.1,0.3,2.2-0.4,3.4c-3.7,6.5-12.3,9.6-25.5,9.6
	h-2.6l-3.7,12.7h-93.3l-3.7-12.7h-2.6C355.5,328.1,347.1,324.9,343.2,318.4L343.2,318.4z M321.5,326.9c3.7,1.2,6.2,2.9,7.4,5.1
	L292,321.3c3-1.4,6.6-1.4,10.7-0.1L321.5,326.9L321.5,326.9z M540.7,321.2c4.1-1.2,7.7-1.2,10.8,0.1L514.5,332
	c1.2-2.2,3.7-3.9,7.4-5.1L540.7,321.2L540.7,321.2z"
          />
          <path
            fill="none"
            d="M328.9,332c-1.2-2.2-3.7-3.9-7.4-5.1l-18.7-5.6c-4.1-1.2-7.7-1.2-10.7,0.1L328.9,332L328.9,332z M551.6,321.3
	c-3-1.4-6.6-1.4-10.7-0.1l-18.7,5.6c-3.7,1.2-6.2,2.9-7.4,5.1L551.6,321.3L551.6,321.3z M344.2,317.2l0.1,0.4c1,1.5,2.2,2.9,3.7,4.1
	h20.3l-1.5-4.5L344.2,317.2L344.2,317.2z M350.3,309.2l-1.5,1.5h146.1l-1.5-1.5H364.5 M355.6,304.7c-1.9,1.4-3.7,2.9-5.4,4.5h14.2
	l-1.5-4.5H355.6L355.6,304.7z M365.3,298.3c-1,0.4-2.1,1-3,1.7l1.1,3.2h22.5l-1-4.8L365.3,298.3L365.3,298.3z"
          />
          <path
            fill="none"
            d="M361.7,300.4h-0.1l1,2.9h0.8l-1.1-3.3 M363.4,303.3h-5.8l-1.9,1.4h132.3l-1.9-1.4H385.8"
          />
          <path
            fill="none"
            d="M363.9,304.7h-1l1.5,4.5h0.8 M361.6,300.4l-4,2.9h5 M364.9,310.7l1.7,5h0.8l-1.5-5 M368.9,297
	c-1.2,0.4-2.3,1-3.6,1.5h112.8c-1.1-0.5-2.3-1.1-3.6-1.5L368.9,297L368.9,297z"
          />
          <path
            fill="none"
            d="M363.9,304.7l1.4,4.5h21.6l-1-4.5 M365.8,310.7l1.5,5h20.8l-1-5 M367.9,317.2h-0.8l1.5,4.5h0.8 M321.6,333.6
	l-26.6-8.3h-0.1c-1.1-0.3-1.9-0.1-2.3,0.3c-0.5,0.4-0.8,1.1-0.5,2.2c1.1,6.2,2.6,12.3,4.3,18c0.4,1.9,1.8,3,4,3.4l2.3,0.4l16.3,2.8
	h0.3l4.8,0.7l13.6,1.2c1.5,0.1,2.6,0,3-0.5c0.7-0.6,0.7-1.4-0.1-2.6c-1.9-3.2-4.4-6.5-7.4-9.6l-0.1-0.1
	C329.6,337.7,325.7,335.1,321.6,333.6L321.6,333.6z M321,335c3.6,1.2,6.9,3.4,9.9,6.5l2.1,2.2c2.5,2.8,4.7,5.5,6.3,8.4
	c0.3,0.4,0.4,0.7,0.3,0.8l-0.3,0.1h-1.7c-6.1-0.4-12.3-1.1-18.5-1.9l-18.9-3.2c-1.5-0.3-2.5-1.1-2.9-2.5c-1.7-5.8-3.2-11.9-4.3-17.9
	c-0.1-0.4,0-0.7,0-0.8c0.3-0.1,0.5-0.1,1.1,0l0.1,0.1l17.2,5.4L321,335L321,335z"
          />
          <path
            fill="none"
            d="M320.9,335l-9.2-2.9c2.1,1.7,3.4,3.6,3.7,5.9c0.1,0.7,0.1,1.4,0.1,2.1l-0.3,1.9l-0.1,0.8l-0.7,1.8
	c-0.4,0.8-1,1.5-1.7,2.1c-0.5,0.4-1.1,0.7-1.7,1c9,2.1,18.3,3.9,28.2,5.4l0.3-0.1c0-0.1,0-0.4-0.3-0.8c-1.7-2.8-3.9-5.6-6.3-8.4
	l-2.1-2.2C327.8,338.4,324.5,336.4,320.9,335L320.9,335z M329.6,341.3l1.5,2.2c-5.1-0.5-9.9-1.7-14.6-3c-0.4-0.7-0.4-1.4-0.3-2.2
	C320.5,339.7,325,340.6,329.6,341.3L329.6,341.3z M316.2,345.3c-0.4-0.6-0.5-1.4-0.3-2.2c5.5,1.9,11.3,3.2,17.5,3.7l1.5,2.2
	C328.2,348.5,322,347.2,316.2,345.3L316.2,345.3z"
          />
          <path
            fill="none"
            d="M331.1,343.5l-1.5-2.2c-4.7-0.7-9.1-1.7-13.4-3c-0.1,0.8-0.1,1.5,0.3,2.2C321.2,341.9,326,343,331.1,343.5
	L331.1,343.5z M315.8,343.1c-0.1,0.8,0,1.5,0.3,2.2c5.8,1.9,12.1,3.2,18.6,3.7l-1.5-2.2C327.2,346.3,321.3,345,315.8,343.1
	L315.8,343.1z M315.3,342.1l0.3-1.9c0-0.8,0-1.5-0.1-2.1c-0.4-2.3-1.7-4.3-3.7-5.9l-17.2-5.4c-0.1,0.1-0.1,0.4,0,0.8
	c1,5.4,2.2,10.6,3.6,15.7c0.5,2.5,2.2,3.9,5.1,4l6.3,0.5l1.7-0.1c0.7-0.1,1.2-0.4,1.7-1c0.7-0.6,1.2-1.2,1.7-2.1l0.7-1.8
	L315.3,342.1L315.3,342.1z M300.4,340.2c0-1.9,0.7-3.6,2.1-5.1c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1
	c0,1.9-0.7,3.6-2.1,5s-3,2.1-5.1,2.1c-1.9,0-3.6-0.7-5-2.1S300.4,342.1,300.4,340.2L300.4,340.2z"
          />
          <path
            fill="none"
            d="M302.4,335.1c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.6,2.1,5s3,2.1,5,2.1s3.6-0.7,5.1-2.1c1.4-1.4,2.1-3,2.1-5
	s-0.7-3.6-2.1-5.1c-1.4-1.4-3-2.1-5.1-2.1C305.5,333.1,303.8,333.7,302.4,335.1L302.4,335.1z M303.1,344.5c-1.2-1.2-1.8-2.6-1.8-4.3
	c0-1.7,0.5-3.2,1.8-4.4c1.2-1.2,2.6-1.8,4.3-1.8c1.7,0,3.2,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.4s-0.6,3.2-1.8,4.3
	c-1.2,1.2-2.6,1.8-4.3,1.8C305.8,346.3,304.2,345.7,303.1,344.5L303.1,344.5z"
          />
          <path
            fill="none"
            d="M301.3,340.2c0,1.7,0.5,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8c1.7,0,3.2-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3
	c0-1.7-0.6-3.2-1.8-4.4s-2.6-1.8-4.3-1.8c-1.7,0-3.2,0.5-4.3,1.8C301.9,337,301.3,338.4,301.3,340.2L301.3,340.2z M303.7,343.9
	c-1.1-1-1.5-2.2-1.5-3.7s0.6-2.8,1.5-3.7c1-1,2.2-1.5,3.7-1.5s2.8,0.6,3.7,1.5c1,1.1,1.5,2.2,1.5,3.7s-0.5,2.8-1.5,3.7
	c-1.1,1.1-2.2,1.5-3.7,1.5C306,345.5,304.8,344.9,303.7,343.9L303.7,343.9z"
          />
          <path
            fill="none"
            d="M302.2,340.2c0,1.5,0.6,2.8,1.5,3.7c1,1.1,2.2,1.5,3.7,1.5s2.8-0.5,3.7-1.5c1-1,1.5-2.2,1.5-3.7
	s-0.5-2.8-1.5-3.7c-1.1-1-2.2-1.5-3.7-1.5s-2.8,0.6-3.7,1.5C302.7,337.5,302.2,338.7,302.2,340.2L302.2,340.2z M306.6,341
	c-0.3-0.3-0.4-0.6-0.4-0.8c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8
	c0,0.4-0.1,0.7-0.4,0.8c-0.3,0.3-0.5,0.4-0.8,0.4C307.1,341.5,306.9,341.3,306.6,341z"
          />
          <path
            fill="none"
            d="M306.2,340.2c0,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.6,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.6,0.4-0.8
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-0.8,0.4C306.3,339.5,306.2,339.8,306.2,340.2L306.2,340.2z"
          />
          <path
            fill="none"
            d="M311.1,347.8l-1.7,0.1l-6.3-0.6c-2.8-0.1-4.5-1.4-5.1-4c-1.4-5.1-2.6-10.3-3.6-15.7c-0.1-0.4-0.1-0.7,0-0.8
	l-0.1-0.1c-0.5-0.1-0.8-0.1-1.1,0c-0.1,0.1-0.1,0.4,0,0.8c1.1,6.2,2.6,12.1,4.3,17.9c0.4,1.4,1.4,2.2,2.9,2.5l18.9,3.2
	c6.2,0.8,12.4,1.5,18.5,1.9h1.7C329.5,351.7,320.1,349.9,311.1,347.8L311.1,347.8z"
          />
          <path
            fill="none"
            d="M318.8,352.5l-16.3-2.8h-16.9c10.7,4,30.5,8.3,59.2,12.5c2.1,0.1,4.1,0.1,6.2,0.1l4.1-0.1h133.2l4.1,0.1
	c2.1,0.1,4,0.1,6.2-0.1c28.8-4.4,48.5-8.5,59.2-12.5h-16.9l-16.3,2.8h-0.1l-4.8,0.7l-39.5,5.6l-2.6,0.4H366.4l-2.6-0.4l-39.5-5.6
	l-4.8-0.7L318.8,352.5L318.8,352.5z M319.1,352.5h-0.3h0.1 M314.2,368.6l7.2,0.3l8.7,1.5l5.4-2.3h-38.7 M370.1,326.7h0.8l-1.1-3.4
	H369"
          />
          <path
            fill="none"
            d="M368.7,326.7h1.4l-1.1-3.4h-18.5C354.9,325.6,361,326.7,368.7,326.7L368.7,326.7z M369.4,321.8h-21.2l2.3,1.5
	H493l2.3-1.5h-106"
          />
          <path
            fill="none"
            d="M369.8,323.3l1.1,3.4h19.4l-0.7-3.4 M370.9,326.7l0.4,1.4h1.9l-0.1-1.4"
          />
          <path fill="none" d="M373.3,328.1h-1.9l3.7,12.7" />
          <path fill="none" d="M373.1,326.7l0.1,1.4h97l0.1-1.4h-80.1" />
          <path fill="none" d="M373.3,328.1l1.8,12.7h93.4l1.8-12.7" />
          <path
            fill="none"
            d="M351.1,343.2c-2.9-2.5-5.5-5.1-8-8.1l6.2,10.2l0.1,0.4c3,5.1,6.3,8.8,10.1,11.3c1.2,0.8,2.6,1.5,4,1.8l2.6,0.4
	h111.3l2.6-0.4c1.4-0.4,2.8-1,4-1.8c3.7-2.5,7-6.2,10.1-11.3l0.1-0.4l6.2-10.2c-2.5,3-5.1,5.6-7.9,8l-0.1,0.1l-2.1,3.4
	c-2.5,3.9-5.4,6.9-8.4,9c-2.1,1.4-4.3,2.2-6.5,2.2H367.9c-2.3-0.1-4.4-0.8-6.5-2.2c-3-2.1-5.8-5.1-8.4-9L351.1,343.2L351.1,343.2z"
          />
          <path
            fill="none"
            d="M351.4,342.4c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3c0,0.1,0,0.3,0.1,0.3l0.1,0.1l2.1,3.4c2.5,3.9,5.4,6.9,8.4,9
	c2.1,1.4,4.3,2.2,6.5,2.2h107.6c2.3-0.1,4.4-0.8,6.5-2.2c3-2.1,5.8-5.1,8.4-9l2.1-3.4l0.1-0.1l0.1-0.3l-0.1-0.3
	c-0.1-0.1-0.3-0.1-0.3-0.1L351.4,342.4L351.4,342.4z M375.6,343.4h115.6l-1.9,3.2v0.1l-1,1.4l-0.1,0.1c-1,1.4-2.1,2.6-3.2,3.6
	l-1.7,1.5l-1.8,1.4c-1.9,1.2-3.9,2.1-5.9,2.1H368.2c-2.1-0.1-4.1-0.8-5.9-2.1l-1.8-1.4l-1.7-1.5c-1.1-1.1-2.2-2.3-3.2-3.6l-0.1-0.1
	l-1-1.4l-0.1-0.1l-1.9-3.2L375.6,343.4L375.6,343.4z"
          />
          <path fill="none" d="M376.4,343.4h-0.8l1.1,3.3h0.8" />
          <path fill="none" d="M355.2,348.1l0.1,0.1H488l0.1-0.1l1-1.4H354.3" />
          <path
            fill="none"
            d="M354.3,346.6v0.1h22.5l-1.1-3.3h-23.3 M362.1,354.8c1.9,1.2,3.9,1.9,5.9,2.1h12l-1.1-3.4h-18.6L362.1,354.8
	L362.1,354.8z M358.5,351.9l1.7,1.5h123.2l1.5-1.5H378.4 M355.4,348.2c1,1.4,2.1,2.6,3.2,3.6h19.7l-1.1-3.6H355.4L355.4,348.2z
	 M467.9,343.4h-0.8l-1.1,3.3h0.8 M466.3,348.2h-0.8l-1.1,3.7h0.8 M462.7,356.9h0.8l1.1-3.4h-0.8"
          />
          <path
            fill="none"
            d="M464.6,353.4l-1.1,3.4h12c2.1-0.1,4.1-0.8,5.9-2.1l1.8-1.4H464.6L464.6,353.4z M462.7,356.9l1.1-3.4h-16
	l-0.7,3.4 M464.4,351.9l1.1-3.7h-16.7l-0.7,3.7 M466.3,348.2l-1.1,3.6h19.7c1.1-1.1,2.2-2.3,3.2-3.6H466.3L466.3,348.2z
	 M489.1,346.7l0.1-0.1l1.9-3.2h-23.3l-1.1,3.3 M466,346.7l1.1-3.3h-17.4l-0.7,3.3 M412.7,343.4h-1l0.1,3.3h1 M394.6,343.4h-0.8
	l0.6,3.3h0.8 M395.6,348.2l0.7,3.7h15.8l-0.1-3.7 M396.3,351.9l-0.7-3.7h-1l0.8,3.7 M395.5,351.9l-0.8-3.7H378l1.2,3.7 M396.6,353.4
	h-0.8l0.7,3.4h0.8"
          />
          <path
            fill="none"
            d="M396.4,356.9l-0.7-3.4h-16l1,3.4 M378.4,351.9h0.8l-1.2-3.7h-0.8"
          />
          <path
            fill="none"
            d="M379.9,356.9h0.8l-1-3.4h-1 M377.5,346.7h16.8l-0.6-3.3h-17.4 M412.4,356.9h0.8l-0.1-3.4h-0.8"
          />
          <path
            fill="none"
            d="M412.4,356.9l-0.1-3.4h-15.7l0.7,3.4 M413.1,353.4l0.1,3.4h16.9l0.3-3.4 M412,348.2l0.1,3.7h0.8l-0.1-3.7"
          />
          <path
            fill="none"
            d="M412.8,348.2l0.1,3.7h17.5l0.1-3.7 M411.7,343.4h-17.1l0.5,3.3h16.7 M412.7,343.4l0.1,3.3h17.9l0.1-3.3
	 M449.7,343.4h-0.8l-0.7,3.3h0.8"
          />
          <path
            fill="none"
            d="M431.8,343.4h-1l-0.1,3.3h0.8 M431.6,348.2h-1l-0.1,3.7h0.8 M430.2,356.9h1l0.1-3.4h-0.8 M431.3,353.4l-0.1,3.4
	h15.2l0.5-3.4 M431.6,348.2l-0.3,3.7h16l0.7-3.7 M448.8,348.2H448l-0.7,3.7h0.8 M446.3,356.9h0.8l0.7-3.4h-1"
          />
          <path
            fill="none"
            d="M448.9,343.4h-17.1l-0.3,3.3h16.7 M356.9,365.7c-1.9,0.1-3.6,0.4-5.2,0.8l-3.2,1c-1.2,0.4-2.9,0.7-4.8,0.7h-8.3
	l-5.4,2.3l5.4,1h172.8l5.4-1l-5.4-2.3h-8.3c-1.9,0-3.6-0.1-5-0.7l-3.2-1c-1.5-0.4-3.3-0.7-5.2-0.8L356.9,365.7L356.9,365.7z"
          />
          <path
            fill="none"
            d="M351.6,366.5c1.5-0.4,3.3-0.7,5.2-0.8h129.7c1.9,0.1,3.6,0.4,5.2,0.8l3.2,1c1.4,0.4,2.9,0.7,5,0.7h47
	c0.8-0.5,1.8-1.2,2.5-1.9c4.4-3.9,7.2-9.2,8.5-16.4c-10.7,4-30.5,8.3-59.2,12.5c-2.1,0.1-4.1,0.1-6.2,0.1l-4.1-0.1H355.1l-4.1,0.1
	c-1.9,0.1-4,0.1-6.2-0.1c-28.8-4.4-48.5-8.5-59.2-12.5c1.2,7.2,4.1,12.5,8.5,16.4l2.5,1.9h47c1.9,0,3.6-0.1,4.8-0.7L351.6,366.5
	L351.6,366.5z M367.9,317.2l1.5,4.5h20l-0.8-4.5 M282.7,278.5c-0.7-3-1.5-4.3-2.2-3.4c-0.5,0.7-1.1,1.7-1.4,2.8
	c-0.8,2.5-1.5,5.2-2.1,8l-0.1,0.3c-0.3,1.7-0.5,3.3-0.8,5.1l1.1,6.9l6.8-12.1L282.7,278.5L282.7,278.5z M277,301.8v3.3
	c0,8.8,0.5,17.5,1.7,25.9l0.4,3.2l0.1-20.9L277,301.8L277,301.8z"
          />
          <path
            fill="none"
            d="M279,334.2v67.5c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.3-2.3,7.3-7.2v-33.1l-17.5-0.5l-2.5-1.9
	c-4.4-3.9-7.2-9.2-8.5-16.4l-2.6-15.3l-3.9-21.2L279,334.2L279,334.2z M275.2,339.5c-0.4,2.1-0.1,3.6,0.5,4.8l3.2,5.1v-5.2
	c-0.8-4.5-1.7-9.1-2.2-13.6L275.2,339.5L275.2,339.5z M566.2,274.6l-0.1,3.3c0,2.6,0.1,5.2,0.5,7.9l0.1,0.3l0.8,5.1l0.7,6.8v0.7
	l0.5-7.6c0.3-2.2,0.1-4.3-0.4-6.6L566.2,274.6L566.2,274.6z"
          />
          <path
            fill="none"
            d="M564.4,277.8c-0.4-1.1-0.8-2.1-1.4-2.8c-0.8-0.8-1.7,0.4-2.3,3.4l-1.1,7.6l6.8,12.1l1.1-6.9l-0.8-5.1l-0.1-0.3
	C566,282.9,565.3,280.3,564.4,277.8L564.4,277.8z M548.1,279.2l0.3-1.2c-0.4,0.7-0.8,1.5-1.4,2.2c-2.2,2.9-5.9,5.4-11.2,7.4
	c-7,2.8-14.7,5.4-23.3,7.6c-4.5,1.2-8.1,1.5-10.5,1.1c-3.6-1.1-6.5-2.6-9-4.5l-0.5-0.4c-0.6-0.4-1.1-1-1.5-1.5c-0.8-1-0.8-1.9,0-2.9
	l2.5-2.2l-8.3-2.6l-0.7,1.5c-0.8,2.5,0,5,2.3,7c1.1,1,2.2,2.1,3.6,3.2l5.1,3.6c4.1,2.8,9.8,3.2,16.8,1.7c8.5-2.2,16.3-4.8,23.3-7.6
	C542.5,288.8,546.5,284.7,548.1,279.2L548.1,279.2z M566.5,301.8l-2.1,11.4l0.1,20.9l0.4-3.2c1.1-8.4,1.5-17.1,1.7-25.9v-3.3H566.5
	L566.5,301.8z"
          />
          <path
            fill="none"
            d="M564.4,313.2l2.1-11.4l0.7-4.1c0.4-1.2,0.7-1.2,0.8,0.3l-0.7-6.8l-1.1,6.9l-5.8,36.4L564.4,313.2L564.4,313.2z"
          />
          <path
            fill="none"
            d="M567.3,297.6l-0.7,4.1v3.3c0,8.8-0.5,17.5-1.7,25.9l-0.4,3.2v10.2c0.8-4.5,1.7-9.1,2.2-13.6
	c1.1-8.4,1.5-16.9,1.7-25.9l-0.1-6.2v-0.7C568,296.4,567.6,296.3,567.3,297.6L567.3,297.6z"
          />
          <path
            fill="none"
            d="M568.2,339.5l-1.7-8.8c-0.5,4.5-1.2,9.1-2.2,13.6v5.2l3.2-5.1C568.4,343.1,568.7,341.5,568.2,339.5L568.2,339.5
	z"
          />
          <path
            fill="none"
            d="M564.5,344.4v-10.2l-0.1-20.9l-3.9,21.2l-2.6,15.3c-1.2,7.2-4.1,12.5-8.5,16.4c-0.8,0.7-1.7,1.4-2.5,1.9
	l-17.5,0.6v33.1c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.4-2.3,7.4-7.2L564.5,344.4L564.5,344.4z"
          />
          <path
            fill="none"
            d="M551.4,327.8c0.3-1.1,0.1-1.8-0.5-2.2c-0.4-0.4-1.2-0.5-2.3-0.3h-0.1l-26.5,8.3c-4.1,1.5-8,4.1-11.4,7.9
	l-0.1,0.1c-3,3.2-5.5,6.3-7.4,9.6c-0.8,1.2-0.8,2.1-0.1,2.6c0.4,0.5,1.5,0.7,3,0.5l13.6-1.2l4.8-0.7h0.1l16.3-2.8l2.3-0.4
	c2.2-0.4,3.4-1.5,4-3.4C548.8,340.2,550.2,334.2,551.4,327.8L551.4,327.8z M550.3,326.7c0.1,0.1,0.1,0.4,0,0.8
	c-1.1,6.2-2.6,12.1-4.3,17.9c-0.4,1.4-1.4,2.2-2.9,2.5l-18.9,3.2c-6.3,0.8-12.4,1.5-18.5,1.9h-1.7l-0.4-0.1c0-0.1,0-0.4,0.3-0.8
	c1.7-2.8,3.9-5.6,6.3-8.4l2.1-2.2c3-3,6.3-5.1,9.9-6.5l9.2-2.9l17.2-5.4l0.1-0.1C549.8,326.6,550.1,326.6,550.3,326.7L550.3,326.7z"
          />
          <path
            fill="none"
            d="M549.1,326.7c0.1,0.1,0.1,0.4,0,0.8c-1,5.4-2.2,10.6-3.6,15.7c-0.5,2.5-2.2,3.9-5.1,4l-6.3,0.5l-1.7-0.1
	c-9,2.1-18.3,3.9-28.2,5.4h1.6c6.1-0.4,12.3-1.1,18.5-1.9l18.9-3.2c1.5-0.3,2.5-1.1,2.9-2.5c1.7-5.8,3.2-11.9,4.3-17.9
	c0.1-0.4,0-0.7,0-0.8c-0.3-0.1-0.5-0.1-1.1,0L549.1,326.7L549.1,326.7z"
          />
          <path
            fill="none"
            d="M549.1,327.5c0.1-0.4,0.1-0.7,0-0.8l-17.2,5.4c-2.1,1.7-3.4,3.6-3.7,5.9c-0.1,0.7-0.1,1.4-0.1,2.1
	c0,0.5,0.1,1.2,0.3,1.9l0.3,0.8c0.1,0.7,0.4,1.2,0.7,1.8c0.4,0.8,1,1.5,1.7,2.1c0.6,0.4,1.1,0.7,1.7,1l1.7,0.1l6.3-0.5
	c2.8-0.1,4.5-1.4,5.1-4C546.9,338.3,548,333.1,549.1,327.5L549.1,327.5z M543.2,340.2c0,1.9-0.7,3.6-2.1,5c-1.4,1.4-3,2.1-5,2.1
	s-3.6-0.7-5.1-2.1c-1.4-1.4-2.1-3-2.1-5s0.7-3.6,2.1-5.1c1.4-1.4,3-2.1,5.1-2.1c1.9,0,3.6,0.7,5,2.1
	C542.5,336.5,543.2,338.3,543.2,340.2L543.2,340.2z"
          />
          <path
            fill="none"
            d="M541.1,345.2c1.4-1.4,2.1-3,2.1-5s-0.7-3.6-2.1-5.1c-1.4-1.4-3-2.1-5-2.1s-3.6,0.7-5.1,2.1
	c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5.1,2.1C538.1,347.2,539.7,346.6,541.1,345.2L541.1,345.2z M540.4,335.8
	c1.2,1.2,1.8,2.6,1.8,4.4c0,1.7-0.6,3.2-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8s-3.2-0.5-4.4-1.8c-1.2-1.2-1.8-2.6-1.8-4.3
	c0-1.7,0.6-3.2,1.8-4.4c1.2-1.2,2.6-1.8,4.4-1.8C537.8,334,539.2,334.7,540.4,335.8L540.4,335.8z"
          />
          <path
            fill="none"
            d="M542.2,340.2c0-1.7-0.6-3.2-1.8-4.4s-2.6-1.8-4.3-1.8s-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4
	c0,1.7,0.6,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.4,1.8c1.7,0,3.2-0.5,4.3-1.8C541.6,343.2,542.2,341.9,542.2,340.2L542.2,340.2z
	 M539.7,336.5c1.1,1.1,1.5,2.2,1.5,3.7s-0.5,2.8-1.5,3.7c-1,1.1-2.2,1.5-3.7,1.5s-2.8-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7
	s0.5-2.8,1.5-3.7c1.1-1,2.2-1.5,3.7-1.5S538.8,335.4,539.7,336.5z"
          />
          <path
            fill="none"
            d="M541.4,340.2c0-1.5-0.5-2.8-1.5-3.7c-1-1-2.2-1.5-3.7-1.5s-2.8,0.6-3.7,1.5c-1,1.1-1.5,2.2-1.5,3.7
	s0.5,2.8,1.5,3.7c1.1,1.1,2.2,1.5,3.7,1.5s2.8-0.5,3.7-1.5C540.8,342.8,541.4,341.6,541.4,340.2L541.4,340.2z M536,341.5
	c-0.3,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.6-0.4-0.8c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4
	c0.3,0.3,0.4,0.6,0.4,0.8c0,0.4-0.1,0.7-0.4,0.8C536.7,341.3,536.4,341.5,536,341.5L536,341.5z"
          />
          <path
            fill="none"
            d="M535.2,341c0.3,0.3,0.6,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.6,0.4-0.8c0-0.4-0.1-0.7-0.4-0.8
	c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,0.8S534.9,340.8,535.2,341L535.2,341z M522.5,335
	c-3.6,1.2-6.9,3.4-9.9,6.5l-2.1,2.2c-2.5,2.8-4.7,5.5-6.3,8.4c-0.3,0.4-0.4,0.7-0.3,0.8l0.4,0.1c9.9-1.5,19.3-3.3,28.2-5.4
	c-0.7-0.1-1.2-0.4-1.7-1c-0.7-0.5-1.2-1.2-1.7-2.1c-0.3-0.5-0.6-1.1-0.7-1.8l-0.3-0.8c-0.1-0.7-0.3-1.4-0.3-1.9c0-0.8,0-1.5,0.1-2.1
	c0.4-2.3,1.7-4.3,3.7-5.9L522.5,335L522.5,335z M527.7,343.1c0.1,0.8,0,1.5-0.3,2.2c-5.8,1.9-12.1,3.2-18.7,3.7l1.5-2.2
	C516.3,346.3,522.2,345,527.7,343.1L527.7,343.1z M527.2,338.3c0.1,0.8,0.1,1.5-0.3,2.2c-4.7,1.5-9.5,2.5-14.6,3l1.5-2.2
	C518.5,340.6,522.9,339.7,527.2,338.3L527.2,338.3z"
          />
          <path
            fill="none"
            d="M527.3,345.3c0.4-0.6,0.5-1.4,0.3-2.2c-5.5,1.9-11.3,3.2-17.5,3.7l-1.5,2.2
	C515.3,348.5,521.5,347.2,527.3,345.3L527.3,345.3z M526.9,340.5c0.4-0.7,0.4-1.4,0.3-2.2c-4.3,1.4-8.7,2.3-13.4,3l-1.5,2.2
	C517.4,343,522.2,341.9,526.9,340.5L526.9,340.5z M499.2,315.7c-0.1-0.4-0.3-0.8-0.7-1.2c-1.2-1.4-2.5-2.6-3.9-3.9h-16.3l-1.7,5.1
	L499.2,315.7L499.2,315.7z M499.1,317.8l0.3-0.4h-22.9l-1.5,4.5h20.4C496.9,320.6,498.1,319.3,499.1,317.8L499.1,317.8z
	 M459.5,294.2h-0.8l-0.6,2.8h0.8 M461.6,294.3l-2.1-0.1L459,297h15.6C470.7,295.4,466.3,294.6,461.6,294.3L461.6,294.3z
	 M476.5,317.2h-1l-1.4,4.5h0.8"
          />
          <path
            fill="none"
            d="M476.1,315.7h0.8l1.7-5h-0.8 M458.7,298.3h-0.8l-1,5h0.8"
          />
          <path
            fill="none"
            d="M458.7,298.3l-1,4.8h22.5l1.1-3.2c-1-0.5-2.1-1.1-3-1.7L458.7,298.3L458.7,298.3z"
          />
          <path
            fill="none"
            d="M481.8,300.4l-0.7-0.4l-1,3.3h0.8l1-2.9 M480.6,304.7h-1l-1.4,4.5h0.8"
          />
          <path
            fill="none"
            d="M486,303.3l-4-2.9l-1,2.9 M493.3,309.2c-1.8-1.7-3.6-3.2-5.4-4.5h-7.3l-1.5,4.5H493.3L493.3,309.2z
	 M478.3,309.2l1.4-4.5h-22.2l-0.8,4.5 M476.1,315.7l1.7-5h-21.4l-1.1,5 M458.1,297l0.5-2.8l-24.5-1L434,297 M473.6,323.3l-1,3.4h0.8
	l1.1-3.4 M472.6,326.7l1-3.4h-19.8l-0.5,3.4h17.2 M473.4,326.7h1.4c7.7,0,13.8-1.1,18.2-3.4h-18.5L473.4,326.7L473.4,326.7z
	 M472.6,326.7h-2.2l-0.1,1.4h1.9"
          />
          <path
            fill="none"
            d="M472.2,328.1h-1.9l-1.8,12.7 M522.1,368.9l7.3-0.3l17.5-0.5h-38.7l5.4,2.3 M524.4,352.5h0.1H524.4z
	 M474.1,321.8l1.4-4.5H455l-1,4.5 M434.2,293.2h-0.8l-0.1,3.7h0.8 M432.8,303.3h1l0.1-5h-0.8 M433.6,304.7h-0.8l-0.3,4.5h1
	 M433.9,298.3l-0.1,5h23.1l1-5"
          />
          <path
            fill="none"
            d="M432.8,303.3l0.3-5h-22.6l0.1,5 M432.2,315.7h0.8l0.3-5h-0.8 M433.1,317.2h-0.8l-0.3,4.5h0.8"
          />
          <path
            fill="none"
            d="M433.3,310.7l-0.3,5h21.4l1.1-5 M432.2,315.7l0.3-5H411l0.3,5 M432.5,309.2l0.3-4.5h-22l0.1,4.5 M433.6,304.7
	l-0.1,4.5h22.2l1-4.5 M456.4,310.7h-0.8l-1.1,5h0.8 M455,317.2h-0.8l-1,4.5h0.8"
          />
          <path
            fill="none"
            d="M455.7,309.2h1l0.8-4.5h-0.8 M454.2,317.2h-21.1l-0.3,4.5h20.4 M433.2,297l0.1-3.7h-23.1l0.1,3.7 M410.2,293.2
	h-1l0.3,3.7h0.8 M383.9,294.2l-2.1,0.1c-4.7,0.3-9.1,1.1-13.1,2.6h15.7L383.9,294.2L383.9,294.2z"
          />
          <path
            fill="none"
            d="M384.8,294.2h-1l0.5,2.8h0.8 M385.5,298.3h-0.8l1.1,5h0.8 M386.9,304.7h-1l1,4.5h0.8 M385.5,298.3l1.1,5h23.1
	l-0.3-5 M389.1,315.7l-1.1-5h-0.8l1,5"
          />
          <path
            fill="none"
            d="M388,310.7l1.1,5h21.4l-0.3-5 M389.4,317.2h-0.8l0.8,4.5h0.8 M386.9,304.7l0.8,4.5h22.3l-0.1-4.5 M410.6,303.3
	l-0.1-5h-1l0.3,5"
          />
          <path
            fill="none"
            d="M410.8,304.7h-0.8l0.1,4.5h0.8 M410.5,315.7h0.8l-0.3-5h-0.8 M411.3,317.2h-0.8l0.3,4.5h0.8 M409.2,293.2
	l-24.4,1l0.4,2.8h24.2 M410.5,317.2h-21.1l0.8,4.5h20.5 M411.6,323.3l0.3,3.4h19.8l0.1-3.4"
          />
          <path fill="none" d="M411.9,326.7l-0.3-3.4h-0.8l0.1,3.4" />
          <path fill="none" d="M410.9,326.7l-0.1-3.4h-20.1l0.6,3.4" />
          <path
            fill="none"
            d="M389.7,323.3l0.7,3.4h0.8l-0.6-3.4 M453.2,326.7l0.5-3.4h-0.8l-0.5,3.4 M452.4,326.7l0.6-3.4h-20.3l-0.1,3.4"
          />
          <path
            fill="none"
            d="M431.8,323.3l-0.1,3.4h0.8l0.1-3.4 M432.2,317.2h-20.9l0.3,4.5H432 M528.3,176.5c2.2,5.6,4.4,11.6,6.5,17.8
	l3.7,11.7l2.2,5.5c1.9,4.8,4,9.8,5.8,14.7l1.1,3.3c0.4,1.2,0.7,2.5,1,3.7l3.3-1.4l-0.5-2.3l-1-2.9l-0.1-0.1l-5.5-15
	c-5-12.1-10.3-23.8-15.8-35l-2.3-4.5L528.3,176.5L528.3,176.5z"
          />
          <path
            fill="none"
            d="M538.6,206l3.6,12.3l1.7,4.7l2.9,3.4c-1.9-5-3.9-9.9-5.8-14.7L538.6,206L538.6,206z"
          />
          <path
            fill="none"
            d="M548.8,233.3c-0.3-1.2-0.5-2.5-1-3.7l-1.1-3.3l-2.9-3.4l5.8,16.5C549.5,237.6,549.2,235.5,548.8,233.3
	L548.8,233.3z M292.9,226.5v0.1l-0.3,0.6l-0.7,2.3l-0.5,2.3l3.3,1.4c0.3-1.2,0.6-2.5,1-3.7l1.1-3.3c1.9-5,3.9-9.9,5.8-14.7l2.2-5.5
	l3.7-11.7c2.2-6.2,4.3-12.3,6.5-17.8l1.8-4.5l-2.3,4.5c-5.5,11.2-10.9,22.9-15.8,35C296.8,216.5,294.9,221.4,292.9,226.5
	L292.9,226.5z"
          />
          <path
            fill="none"
            d="M295.7,229.6c-0.4,1.2-0.7,2.5-1,3.7c-0.4,2.1-0.7,4.1-0.7,6.2l5.8-16.5l-2.9,3.4L295.7,229.6L295.7,229.6z
	 M301.3,218.3c1.2-4.3,2.3-8.3,3.6-12.3l-2.2,5.5c-2.1,4.8-4,9.8-5.8,14.7l2.9-3.4L301.3,218.3L301.3,218.3z M277.5,277.9l-0.1-3.3
	l-1.9,9.9c-0.5,2.2-0.7,4.4-0.4,6.6l0.5,7.6v-0.8l0.6-6.6c0.3-1.8,0.5-3.4,0.8-5.1l0.1-0.3C277.4,283.2,277.5,280.6,277.5,277.9
	L277.5,277.9z"
          />
          <path
            fill="none"
            d="M275.4,297.8v1.5c-0.1,1.8-0.1,3.6-0.1,5.5c0,8.8,0.5,17.5,1.7,25.9c0.5,4.5,1.2,9.1,2.2,13.6v-10.2l-0.4-3.2
	c-1.1-8.4-1.5-17.1-1.7-25.9v-3.3l-0.7-4.1C275.9,296.4,275.6,296.4,275.4,297.8L275.4,297.8z"
          />
          <path
            fill="none"
            d="M276.3,297.6l0.7,4.1l2.1,11.4l3.9,21.2l-5.8-36.4l-1.1-6.9l-0.5,6.6C275.6,296.4,275.9,296.4,276.3,297.6
	L276.3,297.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M519.3,269l-0.4,1.5c-0.4,1.2-0.7,2.6-0.7,4.1c0,3.3,1.1,6.2,3.4,8.5
	c0.8,0.8,1.7,1.5,2.6,2.1l1.2,0.5 M519.3,269c-0.4,0.3-0.7,0.4-1.1,0.7c-4.4,2.8-9.1,6.2-14.5,10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M521.9,267.5c-0.6,0.5-1,1.1-1.2,1.8l-0.1,0.3c-0.8,1.5-1.2,3.3-1.2,5.1
	c0,3,1.1,5.5,3.2,7.6c1,1,2.2,1.8,3.4,2.3l1.5,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.9,281.1c-1.1,1-2.3,1.9-3.7,2.8l-5,2.3l-3.7,1.5
	c-6.1,2.2-12.5,4.3-19.6,6.1c-2.6,0.7-5,1.1-6.8,1.2c-1.7,0.1-3.3,0-4.8-0.5c-2.6-1.2-5-2.6-7-4.3c-0.6-0.4-0.8-0.7-1.1-1.1
	c-0.4-0.7-0.3-1.4,0.6-2.1c10.5-8.5,19.4-15,26.5-19.3l2.1-1.2c1.2-0.6,2.3-1.1,3.6-1.5c1.7-0.5,3.2-1.1,4.8-1.2l6.8-0.5
	c2.8-0.1,5.1-0.7,7-1.7c1.4-0.7,2.5-1.5,3.3-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M521.9,267.5c-0.4,0.1-0.8,0.4-1.2,0.5l-1.5,0.8 M541.6,273.5
	c-0.1-1.1-0.5-2.1-1.2-2.9l-4-4.3c-0.8-0.6-1.7-1-2.6-1.2 M529.8,267.5c-2.2,0-4,0.8-5.5,2.3s-2.2,3.4-2.2,5.5c0,2.2,0.8,4,2.2,5.5
	c1.5,1.5,3.3,2.3,5.5,2.3h0.1c2.2,0,4-0.8,5.5-2.3s2.3-3.3,2.3-5.5s-0.8-4-2.3-5.5C533.8,268.3,531.9,267.5,529.8,267.5L529.8,267.5
	z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M529.8,268.4c-1.9,0-3.6,0.7-5,2.1c-1.4,1.4-2.1,3-2.1,5
	c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5,2.1s3.6-0.7,5-2.1c1.4-1.4,2.1-3,2.1-5s-0.7-3.6-2.1-5C533.4,269.1,531.7,268.4,529.8,268.4
	L529.8,268.4z M533.8,265.1l-1.9-0.3c-1.5-0.1-2.9,0.1-4.5,0.4c-1.8,0.5-3.6,1.2-5.4,2.1 M377.1,348.2l1.2,3.7 M376.4,343.4l1.1,3.3
	 M295.7,271.9c0.3,3.7,1.5,6.8,4,9.2 M295.7,271.9c0.1,2.8,0.4,5.5,0.8,8.3 M543.9,281.1c2.5-2.5,3.7-5.5,4-9.2 M547.8,271.9
	c-0.1,2.8-0.4,5.5-0.8,8.3 M546.3,257.1l0.4,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M541.6,273.5c-0.1-1.1-0.3-2.1-0.5-3.3l4.3-2.2l-1-3.9l-6.5,2.5l-0.5-0.3
	c-1.1-0.5-2.3-1-3.6-1.2 M546.7,259.1l0.7,3.9c0.3,1.9,0.4,3.9,0.4,5.8 M547.8,271.9v-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M547.8,268.8l-5.1,2.8l0.3,1.7v0.7l0.1,0.8c0.3,2.2,0.4,4.3,0.5,6.3
	 M457.5,304.7l-0.8,4.5 M456.9,303.3l1-5 M410.8,321.8l-0.3-4.5 M389.4,321.8l-0.8-4.5 M390.2,321.8l-0.8-4.5 M411.6,321.8l-0.3-4.5
	 M432.8,321.8l0.3-4.5 M432,321.8l0.3-4.5 M453.2,321.8l1-4.5 M454,321.8l1-4.5 M431.6,346.7l0.3-3.3 M430.7,346.7l0.1-3.3
	 M448.2,346.7l0.7-3.3 M449.1,346.7l0.7-3.3 M447.1,356.9l0.7-3.4 M446.9,353.4l-0.6,3.4 M447.3,351.9l0.7-3.7 M448.1,351.9l0.7-3.7
	 M431.3,351.9l0.3-3.7 M430.5,351.9l0.1-3.7 M430.5,353.4l-0.3,3.4 M431.1,356.9l0.1-3.4 M432.5,326.7l0.1-3.4 M431.8,323.3
	l-0.1,3.4 M452.4,326.7l0.6-3.4 M453.7,323.3l-0.5,3.4 M412.8,346.7l-0.1-3.3 M411.9,346.7l-0.1-3.3 M395.2,346.7l-0.5-3.3
	 M394.4,346.7l-0.6-3.3 M396.6,353.4l0.7,3.4 M413,351.9l-0.1-3.7 M412.1,351.9l-0.1-3.7 M413.2,356.9l-0.1-3.4 M412.4,356.9
	l-0.1-3.4 M379.7,353.4l1,3.4 M379.2,351.9l-1.2-3.7 M378.8,353.4l1.1,3.4 M396.4,356.9l-0.7-3.4 M395.5,351.9l-0.8-3.7
	 M396.3,351.9l-0.7-3.7 M390.6,323.3l0.6,3.4 M389.7,323.3l0.7,3.4 M410.9,326.7l-0.1-3.4 M411.9,326.7l-0.3-3.4 M409.5,297
	l-0.3-3.7 M384.4,297l-0.5-2.8 M410.2,293.2l0.1,3.7 M384.8,294.2l0.4,2.8 M410.8,304.7l0.1,4.5 M409.9,304.7l0.1,4.5 M411,310.7
	l0.3,5 M410.5,315.7l-0.3-5 M409.8,303.3l-0.3-5 M410.5,298.3l0.1,5 M387.7,309.2l-0.8-4.5 M385.9,304.7l1,4.5 M388.1,315.7l-1-5
	 M388,310.7l1.1,5 M385.5,298.3l1.1,5 M385.8,303.3l-1.1-5 M433.3,293.2l-0.1,3.7 M434.2,293.2L434,297 M456.4,310.7l-1.1,5
	 M454.4,315.7l1.1-5 M455.7,309.2l1-4.5 M433.6,304.7l-0.1,4.5 M432.5,309.2l0.3-4.5 M432.2,315.7l0.3-5 M433.3,310.7l-0.3,5
	 M432.8,303.3l0.3-5 M433.8,303.3l0.1-5 M474.1,321.8l1.4-4.5 M476.5,317.2l-1.5,4.5 M536,341.5c0.4,0,0.7-0.1,0.8-0.4
	c0.3-0.3,0.4-0.6,0.4-0.8c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.6-0.4,0.8
	c0,0.4,0.1,0.7,0.4,0.8C535.4,341.3,535.7,341.5,536,341.5L536,341.5z M297.2,257.1l-0.4,1.9 M466,346.7l1.1-3.3 M467.9,343.4
	l-1.1,3.3 M465.2,351.9l1.1-3.7 M464.4,351.9l1.1-3.7 M462.7,356.9l1.1-3.4 M464.6,353.4l-1.1,3.4 M296.8,259.1l-0.7,3.9
	c-0.3,1.9-0.4,3.9-0.4,5.8 M473.4,326.7l1.1-3.4 M295.7,268.8v3 M472.6,326.7l1-3.4 M458.7,294.2l-0.6,2.8 M476.1,315.7l1.7-5
	 M459.5,294.2L459,297 M478.5,310.7l-1.7,5 M478.3,309.2l1.4-4.5 M480.6,304.7l-1.5,4.5 M482,300.4l-1,2.9 M481.2,300l-1,3.3
	 M457.7,303.3l1-5 M501.1,289.4c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.4-0.5,0.4-0.8s-0.1-0.5-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4
	s-0.5,0.1-0.8,0.4c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8C500.6,289.2,500.9,289.4,501.1,289.4L501.1,289.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M501.8,281.7l-7.6,6.5c-0.3,0.3-0.4,0.4-0.4,0.7l0.1,0.3l0.3,0.3
	c1.7,1.2,3.4,2.5,5.5,3.4c1.4,0.7,2.8,1,4.1,0.8c1.5-0.3,2.5-0.8,2.9-1.9c0.3-1,0.3-2.1,0-3.2l-2.3-6.2
	C503.6,281.2,502.8,281,501.8,281.7L501.8,281.7z M503.8,280l-1.9,1.7 M514,281.9v-0.3l-0.3-0.4c-0.1-0.1-0.4-0.3-0.7-0.3
	s-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3s0.4-0.1,0.7-0.3l0.3-0.4V281.9
	L514,281.9z M525.5,285.8l1.7-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M503.8,280l4,10.9l0.8-0.1c5.4-1.2,10.6-2.8,15.6-4.4l1.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M521,283.9c0.7,0.7,1.4,1.2,2.1,1.8c-4.8,1.7-9.8,3-14.7,4.1l-3.4-9.2
	c4.7-3.6,9-6.8,12.8-9.2c-0.3,1.1-0.4,2.2-0.4,3.4C517.3,278.4,518.5,281.4,521,283.9L521,283.9z M540.4,335.8
	c-1.2-1.2-2.6-1.8-4.3-1.8s-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4c0,1.7,0.6,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.4,1.8
	c1.7,0,3.2-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3C542.2,338.4,541.6,337,540.4,335.8L540.4,335.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M539.7,336.5c-1-1-2.2-1.5-3.7-1.5s-2.8,0.6-3.7,1.5
	c-1,1.1-1.5,2.2-1.5,3.7s0.5,2.8,1.5,3.7c1.1,1.1,2.2,1.5,3.7,1.5s2.8-0.5,3.7-1.5c1.1-1,1.5-2.2,1.5-3.7
	C541.4,338.7,540.8,337.5,539.7,336.5z M301.9,273.5c0.1-1.1,0.3-2.1,0.5-3.3l-4.3-2.2l1-3.9l6.5,2.5l0.5-0.3c1.1-0.5,2.3-1,3.6-1.2
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M299.6,281.1c1.1,1,2.2,1.9,3.7,2.8l1.9,1c1,0.4,2.1,1,3.2,1.4l3.7,1.5
	c5.9,2.2,12.5,4.3,19.6,6.1c2.6,0.7,5,1.1,6.8,1.2c1.7,0.1,3.3,0,4.8-0.5c2.6-1.2,5-2.6,7-4.3c0.5-0.4,0.8-0.7,1.1-1.1
	c0.4-0.7,0.3-1.4-0.5-2.1c-10.5-8.5-19.4-15-26.5-19.3l-2.1-1.2c-1.2-0.6-2.3-1.1-3.6-1.5c-1.7-0.5-3.2-1.1-4.8-1.2l-6.8-0.5
	c-2.8-0.1-5.1-0.7-7-1.7c-1.4-0.7-2.5-1.5-3.3-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M309.8,265.1c-1,0.3-1.8,0.7-2.6,1.2l-4,4.3c-0.7,0.8-1.1,1.8-1.2,2.9
	 M309.8,265.1l1.9-0.3c1.5-0.1,2.9,0.1,4.5,0.4c1.8,0.5,3.6,1.2,5.4,2.1 M313.7,268.4c1.9,0,3.6,0.7,5,2.1s2.1,3,2.1,5
	c0,1.9-0.7,3.6-2.1,5s-3,2.1-5,2.1s-3.6-0.7-5-2.1s-2.1-3-2.1-5s0.7-3.6,2.1-5S311.8,268.4,313.7,268.4L313.7,268.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M313.7,267.5c2.2,0,4,0.8,5.5,2.3s2.2,3.4,2.2,5.5c0,2.2-0.8,4-2.2,5.5
	c-1.5,1.5-3.4,2.3-5.5,2.3h-0.1c-2.2,0-4-0.8-5.5-2.3s-2.3-3.3-2.3-5.5s0.8-4,2.3-5.5C309.7,268.3,311.5,267.5,313.7,267.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.2,285.1l1.5-0.5c1.2-0.6,2.3-1.4,3.4-2.3c2.1-2.1,3.2-4.7,3.2-7.6
	c0-1.9-0.4-3.6-1.2-5.1l-0.1-0.3c-0.3-0.7-0.8-1.2-1.2-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M324.2,269l0.3,1.5c0.4,1.2,0.7,2.6,0.7,4.1c0,3.3-1.1,6.2-3.4,8.5
	c-0.8,0.8-1.7,1.5-2.6,2.1l-1.2,0.5 M324.2,269l1.1,0.7c4.4,2.8,9.1,6.2,14.5,10.3 M321.6,267.5l2.6,1.5 M542.3,279.5l0.8,1.1
	c-0.8,0.8-1.8,1.5-2.8,2.2l-5.4,2.6l-6.3,2.5c-5.2,1.8-10.9,3.6-16.9,5.1l-4.8,1l0.1-2.1c6.2-1.4,12.3-3,17.9-5.1l3.4-1.2
	c3.6-1.4,7-2.9,10.3-4.4L542.3,279.5L542.3,279.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M527.2,285.1c4.3-1.7,8.5-3.4,12.5-5.4l0.3-0.1c1-0.7,1.5-1.5,1.5-2.5
	l0.1-1.4c0-0.8,0-1.5-0.1-2.2 M295.7,268.8l5.1,2.8l-0.3,1.7v0.7l-0.1,0.8c-0.3,2.2-0.4,4.3-0.5,6.3 M341.7,281.7
	c-1-0.7-1.8-0.5-2.3,0.5l-2.3,6.2c-0.3,1.2-0.3,2.2,0,3.2c0.4,1.1,1.4,1.8,2.9,1.9c1.4,0.1,2.8-0.1,4.1-0.8c2.1-1,3.9-2.2,5.5-3.4
	l0.3-0.3l0.1-0.3c0-0.3-0.1-0.4-0.4-0.7C346.7,285.8,344.2,283.7,341.7,281.7L341.7,281.7z M341.7,281.7l-2.1-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M343.2,289.1c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.4-0.5-0.4-0.8s0.1-0.5,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.3,0,0.5,0.1,0.8,0.4c0.3,0.3,0.3,0.5,0.3,0.8
	S343.4,288.8,343.2,289.1L343.2,289.1z M362.2,300l1.1,3.3 M361.6,300.4l1,2.9 M364.9,310.7l1.7,5 M364.5,309.2l-1.5-4.5
	 M363.9,304.7l1.4,4.5 M365.8,310.7l1.5,5 M329.5,281.9v-0.3c0-0.1,0.1-0.3,0.3-0.4s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
	s0.3,0.4,0.3,0.7c0,0.3-0.1,0.4-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3c-0.3,0-0.4-0.1-0.7-0.3l-0.3-0.4L329.5,281.9L329.5,281.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M322.4,283.9c-0.7,0.7-1.4,1.2-2.1,1.8c4.8,1.7,9.8,3,14.7,4.1l3.4-9.2
	c-4.7-3.6-9-6.8-12.8-9.2c0.3,1.1,0.4,2.2,0.4,3.4C326.3,278.4,325,281.4,322.4,283.9L322.4,283.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M339.6,280l-4,10.9l-0.8-0.1c-5.4-1.2-10.6-2.8-15.6-4.4l-1.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M336.3,291.9c-6.2-1.4-12.3-3-17.9-5.1l-3.4-1.2c-3.6-1.4-7-2.9-10.3-4.4
	l-3.3-1.7v0.1l-0.8,1.1c0.8,0.8,1.8,1.5,2.8,2.2l5.4,2.6l6.3,2.5c5.2,1.8,10.9,3.6,16.9,5.1l4.8,1L336.3,291.9L336.3,291.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.2,285.1c-4.3-1.7-8.5-3.4-12.5-5.4l-0.3-0.1c-1-0.7-1.5-1.5-1.5-2.5
	l-0.1-1.4c0-0.8,0-1.5,0.1-2.2 M318,285.8l-1.8-0.7 M301.3,340.2c0-1.7,0.5-3.2,1.8-4.4c1.2-1.2,2.6-1.8,4.3-1.8
	c1.7,0,3.2,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.4c0,1.7-0.6,3.2-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8c-1.7,0-3.2-0.5-4.3-1.8
	C301.9,343.2,301.3,341.9,301.3,340.2L301.3,340.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M302.2,340.2c0-1.5,0.6-2.8,1.5-3.7c1-1,2.2-1.5,3.7-1.5s2.8,0.6,3.7,1.5
	c1,1.1,1.5,2.2,1.5,3.7s-0.5,2.8-1.5,3.7c-1.1,1.1-2.2,1.5-3.7,1.5s-2.8-0.5-3.7-1.5C302.7,342.8,302.2,341.6,302.2,340.2
	L302.2,340.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306.2,340.2c0-0.3,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,0.8-0.4
	c0.4,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8c0,0.4-0.1,0.7-0.4,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.7-0.1-0.8-0.4
	C306.3,340.8,306.2,340.5,306.2,340.2L306.2,340.2z M370.1,326.7l-1.1-3.4 M370.9,326.7l-1.1-3.4 M367.9,317.2l1.5,4.5 M376.7,346.7
	l-1.1-3.3 M368.6,321.8l-1.5-4.5 M489.1,346.7H354.3 M488,348.2H355.4 M355.4,348.2c1,1.4,2.1,2.6,3.2,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M351.1,343.2l2.1,3.4c2.5,3.9,5.4,6.9,8.4,9c2.1,1.4,4.3,2.2,6.5,2.2
	h107.6c2.3-0.1,4.4-0.8,6.5-2.2c3-2.1,5.8-5.1,8.4-9l2.1-3.4 M339.2,353c-9.9-1.5-19.3-3.3-28.2-5.4 M323.9,353.2l39.5,5.8
	 M295.1,277.9c-0.8,1.4-1.9,2.6-3.3,3.6 M343,315.1c-22.9-3.2-38.2-7.3-46.2-12.3c-4.1-2.6-6.3-5.8-6.6-9.4c-0.5-3-0.3-6.2,0.8-9.5
	l0.7-2.5 M282.7,272.8l-1.1,1c-0.4,0.3-0.7,0.7-1.1,1.1 M277.4,274.6l0.1,3.3 M283.8,286.1l-1.1-7.6c-0.7-3-1.5-4.3-2.2-3.4
	 M280.5,274.9c-0.5,0.7-1.1,1.7-1.4,2.8c-0.8,2.5-1.5,5.2-2.1,8 M291.8,281.5l-8,4.5 M277.4,274.6l-1.9,9.9
	c-0.5,2.2-0.7,4.4-0.4,6.6l0.5,7.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M277,285.8l-0.1,0.3c-0.3,1.7-0.5,3.3-0.8,5.1 M275.4,297.8v0.8
	 M275.4,298.6v0.7c-0.1,1.8-0.1,3.6-0.1,5.5c0,8.8,0.5,17.5,1.7,25.9 M277,301.8l-0.7-4.1c-0.4-1.2-0.7-1.2-0.8,0.1 M279,334.2
	l-0.4-3.2c-1.1-8.4-1.5-17.1-1.7-25.9v-3.3 M277,285.8c0.4-2.6,0.7-5.2,0.5-7.9 M279.2,313.2l-2.2-11.4 M276,291.2l1.1,6.9
	 M283.8,286.1l3.7,23.6 M277.1,298.1l6.8-12 M276,291.2l-0.5,6.6 M279,344.4v-10.2 M276.8,330.7l-1.7,8.8c-0.4,2.1-0.1,3.6,0.5,4.8
	l3.2,5.1 M285.6,349.7c1.2,7.2,4.1,12.5,8.5,16.4l2.5,1.9 M279,349.6v-5.2 M276.8,330.7c0.5,4.5,1.2,9.1,2.2,13.6 M283,334.4
	l2.6,15.3 M283,334.4l-3.9-21.2 M277.1,298.1l5.9,36.4 M279.2,313.2l-0.1,20.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M279,349.6v52.1c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.3-2.3,7.3-7.2v-33.1
	 M330,370.4l5.4,1h172.8l5.4-1 M330,370.4l5.4-2.3 M285.6,349.7h16.9 M296.7,368l17.5,0.5 M294.5,326.7l17.2,5.4 M296.7,368h38.7
	 M480.1,359l-2.6,0.3H366.1l-2.6-0.3 M358.5,351.9l1.7,1.5 M360.2,353.4l1.8,1.4c1.9,1.2,3.9,1.9,5.9,2.1h107.5
	c2.1-0.1,4.1-0.8,5.9-2.1l1.8-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M557.9,349.7c-10.7,4-30.5,8.3-59.2,12.5c-2.1,0.1-4.1,0.1-6.2,0.1
	l-4.1-0.1H355.1l-4.1,0.1c-1.9,0.1-4,0.1-6.2-0.1c-28.8-4.4-48.5-8.5-59.2-12.5 M335.4,368h8.3c1.9,0,3.6-0.1,4.8-0.7l3.2-1
	c1.5-0.4,3.3-0.7,5.2-0.8h129.7c1.9,0.1,3.6,0.4,5.2,0.8l3.2,1c1.4,0.4,2.9,0.7,5,0.7h8.3 M354.3,346.7l1,1.4l0.1,0.1 M351.1,343.2
	l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-0.3c0.1-0.1,0.3-0.1,0.3-0.1h140.7c0.1,0,0.3,0,0.4,0.1l0.1,0.3l-0.1,0.3l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M363.5,359c-1.4-0.4-2.6-1-4-1.8c-3.7-2.5-7-6.2-10.1-11.3l-0.1-0.4
	l-6.2-10.2c2.5,3,5.1,5.6,7.9,8l0.1,0.1 M348.2,321.8l2.3,1.5 M371.3,328.1l3.7,12.7 M375.1,340.8l-1.8-12.7 M354.3,346.7v-0.1
	l-1.9-3.2h138.9l-1.9,3.2l-0.1,0.1 M373.1,326.7l0.1,1.4 M373.3,328.1h-1.9 M373.1,326.7h-2.2 M370.9,326.7l0.4,1.4 M350.5,323.3
	H493 M495.4,321.8H348.2 M350.5,323.3c4.4,2.3,10.5,3.4,18.2,3.4h2.2 M314.2,368.6l7.2,0.3l8.7,1.5 M319.1,352.5h-0.3 M323.9,353.2
	l-4.8-0.7 M319.1,352.5h-0.3 M318.8,352.5l-16.3-2.8 M339.2,353h-1.7c-6.1-0.4-12.3-1.1-18.5-1.9l-18.9-3.2
	c-1.5-0.3-2.5-1.1-2.9-2.5c-1.7-5.8-3.2-11.9-4.3-17.9c-0.1-0.4,0-0.7,0-0.8c0.3-0.1,0.5-0.1,1.1,0l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M294.5,326.7c-0.1,0.1-0.1,0.4,0,0.8c1,5.4,2.2,10.6,3.6,15.7
	c0.5,2.5,2.2,3.9,5.1,4l6.3,0.5l1.7-0.1 M316.2,345.3c5.8,1.9,12.1,3.2,18.6,3.7l-1.5-2.2c-6.2-0.6-12.1-1.8-17.5-3.7
	C315.7,343.9,315.8,344.8,316.2,345.3L316.2,345.3z M311.1,347.8c0.7-0.1,1.2-0.4,1.7-1c0.7-0.6,1.2-1.2,1.7-2.1l0.7-1.8l0.1-0.8
	l0.3-1.9c0-0.8,0-1.5-0.1-2.1c-0.4-2.3-1.7-4.3-3.7-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M302.4,335.1c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1
	c0,1.9-0.7,3.6-2.1,5s-3,2.1-5.1,2.1c-1.9,0-3.6-0.7-5-2.1s-2.1-3-2.1-5S301.1,336.5,302.4,335.1L302.4,335.1z M328.9,332L292,321.3
	c3-1.4,6.6-1.4,10.7-0.1l18.7,5.6C325.2,328.1,327.7,329.7,328.9,332L328.9,332z M329.6,341.3c-4.7-0.7-9.1-1.7-13.4-3
	c-0.1,0.8-0.1,1.5,0.3,2.2c4.7,1.5,9.5,2.5,14.6,3L329.6,341.3L329.6,341.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M302.6,349.7l-2.3-0.4c-2.2-0.4-3.4-1.5-4-3.4c-1.7-5.8-3-11.9-4.3-18
	c-0.3-1.1-0.1-1.8,0.5-2.2c0.4-0.4,1.2-0.5,2.3-0.3h0.1l26.5,8.3c4.1,1.5,8,4.1,11.4,7.9l0.1,0.1c3,3.2,5.5,6.3,7.4,9.6
	c0.8,1.2,0.8,2.1,0.1,2.6c-0.4,0.5-1.5,0.7-3,0.5l-13.6-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M311.7,332.1l9.2,2.9c3.6,1.2,6.9,3.4,9.9,6.5l2.1,2.2
	c2.5,2.8,4.7,5.5,6.3,8.4c0.3,0.4,0.4,0.7,0.3,0.8l-0.3,0.1 M357.6,303.3l-1.9,1.4 M358.1,281.9l-8.3,2.6 M344.2,317.2h155
	 M499.2,315.7h-155 M368.9,297c-1.2,0.4-2.3,1-3.6,1.5 M348.7,310.7h146.1 M493.3,309.2h-143 M355.6,304.7h132.3 M486,303.3H357.6
	 M365.3,298.3c-1,0.4-2.1,1-3,1.7l-0.7,0.3h-0.1l-4,2.9 M355.6,304.7c-1.9,1.4-3.7,2.9-5.4,4.5 M350.3,309.2l-1.5,1.5 M344.2,317.2
	l0.1,0.4c1,1.5,2.2,2.9,3.7,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M371.3,328.1h-2.6c-13.2,0-21.6-3.2-25.5-9.6c-0.7-1.1-0.8-2.2-0.3-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M343,315.1c0.1-0.6,0.4-1,0.8-1.5c5.1-5.4,10.7-10.2,17.1-14.5l4.3-2.2
	c5.1-2.3,10.6-3.6,16.7-4l40.8-1.2l39,1.2c6.1,0.4,11.6,1.7,16.7,4l4.3,2.2c6.3,4.1,12,9,17.1,14.5c0.4,0.5,0.7,1,1,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M348.7,310.7c-1.2,1.2-2.6,2.5-3.9,3.9c-0.3,0.4-0.5,0.8-0.7,1.2
	 M344.2,315.7c-0.1,0.6-0.1,1.1,0,1.5 M295.1,277.9c0.4,0.7,0.8,1.5,1.4,2.2c2.2,2.9,5.9,5.4,11.2,7.4c7,2.8,14.7,5.4,23.3,7.6
	c4.5,1.2,8.1,1.5,10.5,1.1c3.6-1.1,6.5-2.6,9-4.5l0.5-0.4c0.6-0.4,1.1-1,1.5-1.5c0.8-1,0.8-1.9-0.1-2.9l-2.3-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M358.1,281.9l0.7,1.5c0.8,2.5,0,5-2.3,7l-3.6,3.2l-2.1,1.5
	c-0.1,0.1-0.1,0.1-0.3,0.3l-2.8,1.9c-4.1,2.8-9.8,3.2-16.8,1.6c-8.5-2.2-16.3-4.8-23.3-7.6c-6.5-2.6-10.6-6.8-12.1-12.3l-0.3-1.2
	 M294.5,245.1c2.9-6.2,5.9-10.9,9.1-13.9 M296.8,226.3l2.9-3.3 M566.2,274.6l-0.3,3.3 M566.6,285.8c-0.5-2.8-1.2-5.5-2.1-8
	c-0.4-1.1-0.8-2.1-1.4-2.8 M563,274.9c-0.4-0.4-0.7-0.8-1.1-1.1l-1.1-1 M559.6,286.1l1.1-7.6c0.7-3,1.5-4.3,2.3-3.4 M566.6,285.8
	v0.3l0.8,5.1 M568.1,298.6l0.5-7.6c0.3-2.2,0.1-4.3-0.4-6.6l-1.9-9.9 M565.9,277.9c0,2.6,0.1,5.2,0.6,7.9 M500.6,315.1
	c22.9-3.2,38.2-7.3,46-12.3c4.1-2.6,6.3-5.8,6.6-9.4c0.6-3,0.3-6.2-0.8-9.5l-0.7-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.4,277.9l-0.3,1.2c-1.7,5.5-5.7,9.6-12.1,12.3
	c-7,2.8-14.7,5.2-23.3,7.6c-7,1.5-12.5,1-16.8-1.7l-5.1-3.6c-1.4-1.1-2.6-2.1-3.6-3.2c-2.3-2.2-3.2-4.5-2.3-7l0.7-1.5 M551.6,281.5
	c-1.2-1-2.3-2.2-3.3-3.6 M299.7,223l-5.6,16.5 M294.5,245.1c1.5-6.2,2.9-12.1,4.4-17.8c0.8-3,1.7-6.1,2.5-9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M493.7,284.5l-2.5,2.2c-1,1-1,1.9,0,2.9c0.4,0.5,0.8,1,1.5,1.5l0.6,0.4
	c2.3,1.9,5.4,3.4,9,4.5c2.3,0.5,5.8,0.1,10.5-1.1c8.5-2.2,16.3-4.8,23.3-7.6c5.2-2.1,9-4.5,11.2-7.4c0.6-0.7,1-1.4,1.4-2.2
	 M564.4,313.2l2.1-11.4 M566.5,301.8l0.7-4.1c0.4-1.2,0.7-1.2,0.8,0.3 M564.5,334.2l0.4-3.2c1.1-8.4,1.5-17.1,1.7-25.9v-3.3
	 M566.6,330.7c1.1-8.4,1.5-16.9,1.7-25.9l-0.1-6.2 M568.1,298.6v-0.7 M566.3,298.1l-6.8-12 M567.4,291.2l-1.1,6.9 M559.6,286.1
	l-3.7,23.6 M551.6,281.5l8,4.5 M568.1,297.9l-0.7-6.8 M564.5,349.6l3.2-5.1c0.8-1.2,1-2.9,0.6-4.8l-1.7-8.8 M564.5,344.4v-10.2
	 M566.6,330.7c-0.5,4.5-1.2,9.1-2.2,13.6 M519.6,353.2l-13.6,1.2c-1.5,0.1-2.6,0-3-0.6c-0.7-0.6-0.7-1.4,0.1-2.6
	c1.9-3.2,4.4-6.5,7.4-9.6l0.1-0.1c3.4-3.9,7.3-6.5,11.4-7.9l26.5-8.3h0.1c1.1-0.3,1.9-0.1,2.3,0.3c0.6,0.4,0.8,1.1,0.6,2.2
	c-1.1,6.2-2.6,12.3-4.3,18c-0.4,1.9-1.8,3-4,3.4l-2.3,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M532.4,347.8l1.7,0.1l6.3-0.6c2.8-0.1,4.5-1.4,5.1-4
	c1.4-5.1,2.6-10.3,3.6-15.7c0.1-0.4,0.1-0.7,0-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M549.1,326.7l0.1-0.1c0.5-0.1,0.8-0.1,1.1,0c0.1,0.1,0.1,0.4,0,0.8
	c-1.1,6.2-2.6,12.1-4.3,17.9c-0.4,1.4-1.4,2.2-2.9,2.5l-18.9,3.2c-6.3,0.8-12.4,1.5-18.5,1.9h-1.7 M303.5,231.2l3.2-0.3
	 M360.6,236.5l-22.3,0.3c-5.4,0.1-10.9-0.3-16.7-0.8c-2.2-0.3-4.3-0.7-6.2-1.2c-2.5-0.6-4.8-1.4-6.8-2.3c-0.6-0.3-1.1-0.6-1.5-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.2,340.2c0-1.9-0.7-3.6-2.1-5.1c-1.4-1.4-3-2.1-5-2.1
	s-3.6,0.7-5.1,2.1c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.6-0.7,5-2.1
	C542.5,343.8,543.2,342.1,543.2,340.2L543.2,340.2z M546.9,368c0.8-0.5,1.8-1.2,2.5-1.9c4.4-3.9,7.2-9.2,8.5-16.4 M541,349.7h17
	 M564.5,344.4v5.2 M557.9,349.7l2.6-15.3 M564.5,334.2l-0.1-20.9 M560.5,334.4l3.9-21.2 M560.5,334.4l5.8-36.4 M540.7,321.2
	l-18.7,5.6c-3.7,1.2-6.2,2.9-7.4,5.1l36.9-10.6C548.4,320,544.8,320,540.7,321.2L540.7,321.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M529.4,368.6v33.1c0,4.7,2.5,7.2,7.3,7.2h20.4c5,0,7.4-2.3,7.4-7.2v-52.2
	 M303.5,231.2c0.4,1.1,1.1,2.2,1.9,3.2c11.7,16.1,28.1,31.8,49.2,47.4c-5.9-15.6-14.1-30-24.4-43.1c11.6,12.7,20.8,27,27.8,43.3
	 M307.1,231.4l-0.4-0.4 M306.7,230.9l-0.7-1.1 M317.1,172l-2.3,4.5c-5.5,11.2-10.9,22.9-15.8,35c-2.1,5-4,9.9-5.9,15 M301.3,218.3
	l-1.7,4.7 M296.8,226.3c1.9-5,3.9-9.9,5.8-14.7l2.2-5.5 M301.3,218.3c1.2-4.3,2.3-8.3,3.6-12.3 M499.2,315.7
	c-0.1-0.4-0.3-0.8-0.7-1.2c-1.2-1.4-2.5-2.6-3.9-3.9 M499.2,317.2c0.1-0.5,0.1-1.1,0-1.5 M495.4,321.8c1.5-1.1,2.8-2.5,3.7-4.1
	l0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.6,315.1c0.4,1.1,0.3,2.2-0.4,3.4c-3.7,6.5-12.3,9.6-25.5,9.6h-2.6
	 M485.3,281.9l8.4,2.6 M474.7,297c-4-1.5-8.4-2.3-13.1-2.6l-2.1-0.1l-0.8-0.1l-24.5-1h-24.8l-24.5,1l-0.8,0.1l-2.1,0.1
	c-4.7,0.3-9.1,1.1-13.1,2.6 M474.7,297H368.9 M478.3,298.3h-113 M323.8,173.6c-5.9,16.3-10.7,31.4-14.3,45.5l-0.4,1.2
	c-1,3.2-1.7,6.9-2.1,11 M486,303.3l-4-2.9h-0.1l-0.7-0.3c-1-0.5-2.1-1.1-3-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306,229.8c0.4-3.2,1-6.3,1.9-9.5l0.4-1.2c3.7-14.3,8.5-29.9,14.7-46.7
	 M304.9,206l3.7-11.7c2.2-6.2,4.3-12.3,6.5-17.8l1.8-4.5 M494.8,310.7l-1.5-1.5 M493.3,309.2c-1.8-1.7-3.6-3.2-5.4-4.5 M487.9,304.7
	l-1.9-1.4 M321.6,173.1c-1,0.7-1.7,1.7-2.1,2.9l-0.3,0.7c-5.6,14.3-10.6,28.9-14.9,43.5c-1,2.8-1.4,5.2-1.5,7.2
	c0,1.2,0.3,2.5,0.8,3.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306,229.8l-0.4-1.2c-0.5-2.3-0.3-5.5,1-9.5c3.7-14.3,8-28.5,13-42.4
	l0.3-0.7c0.4-1.1,1.1-2.1,1.9-2.8 M478.3,298.3c-1.1-0.6-2.3-1.1-3.6-1.5 M253.4,243.4l10.1,1.4 M293.6,248.6l0.8-3.4 M273.1,254.9
	c4.5-0.4,9-1.4,13.1-2.6 M291.6,246.9l0.1,1 M493,323.3l2.3-1.5 M492.5,343.2c2.9-2.5,5.5-5.1,8-8.1l-6.2,10.2l-0.1,0.4
	c-3,5.1-6.3,8.8-10.1,11.3c-1.2,0.8-2.6,1.5-4,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M286.2,252.2l1.5,3c-3.2,4.5-5.8,9.1-8,13.6l-2.8-5.8 M472.6,326.7h2.2
	c7.7,0,13.8-1.1,18.2-3.4 M291.8,281.5c0.3-1.2,0.4-2.5,0.4-3.9l-1-9.9c0-1.2-0.5-1.7-1.7-1l-7,6.1 M470.4,326.7h2.2 M472.2,328.1
	l0.4-1.4 M470.3,328.1l0.1-1.4 M472.2,328.1h-1.9 M470.3,328.1l-1.8,12.7 M468.5,340.8l3.7-12.7 M292.5,251.9l-1.2,2.8 M360.2,353.4
	h123.2 M291.7,247.9l1.9,0.7 M358.5,351.9h126.4 M296.2,255.9c-1.2-1.9-2.2-3.9-3-5.9 M291.7,247.9l0.8,4 M488,348.2l0.1-0.1l1-1.4
	 M483.4,353.4l1.5-1.5 M484.9,351.9c1.1-1.1,2.2-2.3,3.2-3.6 M291.3,254.6l-0.4-1.5 M349.8,284.5c-9.6-8.5-18.7-15.2-27.6-19.7
	c-0.4-0.1-0.7-0.3-1.1-0.4c-3.2-1.4-6.5-2.2-10.1-2.5l-4.5-0.1c-3.7,0-6.8-1.5-9.4-4.5l-1-1.2 M531.9,332.1l-9.2,2.9
	c-3.6,1.2-6.9,3.4-9.9,6.5l-2.1,2.2c-2.5,2.8-4.7,5.5-6.3,8.4c-0.3,0.4-0.4,0.7-0.3,0.8l0.4,0.1 M532.4,347.8
	c-0.7-0.1-1.2-0.4-1.7-1c-0.7-0.6-1.2-1.2-1.7-2.1c-0.3-0.5-0.5-1.1-0.7-1.8l-0.3-0.8c-0.1-0.7-0.3-1.4-0.3-1.9c0-0.8,0-1.5,0.1-2.1
	c0.4-2.3,1.7-4.3,3.7-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M527.3,345.3c-5.8,1.9-12.1,3.2-18.7,3.7l1.5-2.2
	c6.2-0.6,12.1-1.8,17.5-3.7C527.9,343.9,527.7,344.8,527.3,345.3z M293.2,250l-0.7,1.9 M526.9,340.5c-4.7,1.5-9.5,2.5-14.6,3
	l1.5-2.2c4.7-0.7,9.1-1.7,13.4-3C527.5,339.1,527.3,339.8,526.9,340.5L526.9,340.5z M513.5,370.4l8.5-1.5l7.3-0.3 M524.4,352.5h0.1
	 M524.6,352.5h-0.1 M519.6,353.2l4.8-0.7 M504.2,353c9.9-1.5,19.3-3.3,28.2-5.4 M480.1,359l39.5-5.8 M293.2,250l0.4-1.4
	 M276.8,262.6l-2.6-5.6 M253.4,243.4c2.9,4.4,8,6.6,15.6,6.9c1.5,0.1,2.3,0.7,2.9,1.8l1.4,2.9 M273.1,254.9l1.1,2.1 M274.2,257v2.9
	c0,1.1,0.8,2.1,2.6,2.8 M276.8,262.6l0.3,0.5 M290.3,234.3c-2.3,1.8-4,3.7-5,5.6 M292.9,226.5c-3.2,3-5.6,6.3-7.6,9.8 M294,239.5
	c0-2.1,0.3-4.1,0.7-6.2 M294,239.5l-2.5,7.4 M291.3,232.1c-0.3,1.7-0.5,3.4-0.7,5.2v0.1c0,2.9,0.3,6.1,0.8,9.5 M290.9,253.1
	c-1-4.8-1.5-9.1-1.4-12.8v-0.1c0.1-2.1,0.4-4,0.8-5.8 M284.5,244.9l-0.1,0.7c-0.3,1.2-0.1,2.5,0.4,3.6l1.5,3 M407.3,279.2
	c-1.7,0-3.4,0-5.1,0.1c-14.7,0.4-29.5,1.2-44.1,2.6 M284.5,244.9c-1.1,1.1-3.4,1.4-6.9,1.2l-14.2-1.4 M285.4,239.9
	c-0.1,1.7-0.4,3.3-0.8,5.1 M285.4,236.3c0.1,1.1,0,2.2,0,3.4 M291.3,232.1l3.4,1.2 M294.7,233.3c0.3-1.2,0.5-2.5,1-3.7l1.1-3.3
	 M291.8,229.6c-0.7,1.4-1.1,2.9-1.5,4.5 M291.8,229.6l-0.5,2.5 M292.9,226.5v0.1l-0.3,0.6l-0.8,2.3 M285.4,236.3
	c-0.1-1.7-0.3-3.2-0.8-4.5c-0.7-2.8-2.9-4.3-6.8-4.5c-9.1-0.1-17.4,1.4-24.7,4.8c-1.8,1-2.6,2.3-2.5,4.5 M263.5,244.7
	c-2.1-2.5-4.5-4.4-7.3-5.5c-2.1-0.8-3.9-1.7-5.4-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.8,236.6c0,0.6,0.1,1.1,0.3,1.8l1.7,4l0.6,1.1 M252.8,238.7
	c-0.1,0-0.1,0-0.1,0.1v0.1l1.2,3.4l0.1,0.1l0.3,0.1h0.3l0.3-0.1v-0.1l-1.2-3.4l-0.1-0.1l-0.3-0.1H252.8z M338.5,157.2l-1.1,0.1
	c-1.5,0.3-3,0.6-4.4,1 M328.2,171.4l6.2-10.5c1-1.7,2.3-2.9,4-3.6 M332.9,158.3c-7.4,2.1-12.4,5.9-14.7,11.4l-1.1,2.2 M328.8,173.2
	l-5,0.4 M328.2,171.4l0.5,1.8 M323.4,172.1c0.5-0.3,1.2-0.4,1.9-0.4l2.9-0.3 M470.4,326.7h-97.3 M323.1,172.3l-0.6,0.3 M322.6,172.5
	l0.4-0.3 M323,172.3h0.1 M323.1,172.3l0.3-0.1 M323.4,172.1l0.4,1.5 M321.6,173.1l0.3-0.3c0.1-0.1,0.4-0.1,0.5-0.3
	c0,0,0.1,0,0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.9,158.3c-1.5,0.6-2.6,1.5-3.6,3.2l-6.1,10.5 M436.1,279.2h-28.7
	 M485.3,281.9c-16.4-1.5-32.8-2.3-49.2-2.8 M508.2,368l5.4,2.3 M536.8,230.9l0.7-1.1 M536.8,230.9l3.2,0.3 M543.7,223l5.8,16.5
	 M546.7,226.3l-3-3.3 M590.2,243.4l-10.1,1.4 M592.8,236.6c-1.5,1-3.3,1.8-5.4,2.6c-2.8,1.1-5.2,3-7.3,5.5 M590.2,238.8l0.3-0.1h0.3
	l0.3,0.1v0.1l-1.2,3.4l-0.3,0.3h-0.7v-0.3l1.2-3.4L590.2,238.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M590.2,243.4l0.5-1.1l1.8-4l0.3-1.7 M549.1,245.1l0.8,3.4 M551.8,247.9
	l0.1-1 M566.7,262.6l2.6-5.6 M557.2,252.2l-1.5,3c3.2,4.5,5.8,9.1,8,13.6l2.8-5.4 M566.5,263.5v-0.3l0.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M570.3,254.9l-5.2-0.7c-2.8-0.6-5.4-1.1-8-1.9 M566.7,262.6
	c1.8-0.7,2.6-1.7,2.6-2.8V257 M569.3,257l1-2.1 M570.3,254.9l1.4-2.9c0.1-0.3,0.3-0.6,0.6-0.8l0.3-0.3c0.5-0.4,1.1-0.6,1.9-0.7
	c7.6-0.3,12.8-2.6,15.6-6.9 M560.8,272.8l-7-6.1c-1.1-0.7-1.7-0.3-1.7,1l-1,9.9c0,1.4,0.1,2.6,0.4,3.9 M550.2,250
	c-0.8,2.1-1.8,4-3,5.9 M552.5,253.1l-0.3,1.5 M552.3,254.6l-0.1-0.3c-0.4-0.8-0.8-1.7-1.1-2.5 M549.9,248.6l0.3,1.4 M550.9,251.9
	l1-4 M550.9,251.9l-0.7-1.9 M551.8,247.9l-1.9,0.7 M549.5,239.5c0-2.1-0.3-4.1-0.7-6.2 M558.2,239.9l-1.2-1.8
	c-1-1.2-2.2-2.6-3.9-3.9 M549.1,245.1c-2.9-6.2-5.9-10.9-9.1-13.9 M542.2,218.3l2.5,9c1.5,5.8,3,11.7,4.4,17.8 M549.5,239.5l2.5,7.4
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M553.2,234.3c0.4,1.8,0.7,3.7,0.8,5.8v0.1c0,3.7-0.4,8-1.4,12.8v0.1
	 M524.6,352.5l16.4-2.8 M531.9,332.1l17.2-5.4 M529.4,368.6l17.5-0.5 M552,246.9c0.5-3.4,0.8-6.8,0.8-9.5v-0.1
	c-0.1-1.8-0.3-3.6-0.7-5.2 M540,231.2c-0.4,1.1-1.1,2.2-1.9,3.2c-11.7,16.1-28.1,31.8-49.2,47.4c5.9-15.6,14.1-30,24.4-43.1
	c-11.6,12.7-20.8,27-27.8,43.3 M546.9,368h-38.7 M338.5,157.2c27-3.4,53.9-5.2,80.7-5.4 M389,236.3l18.5,42.9 M553.2,234.3
	c-0.4-1.7-1-3.2-1.5-4.5 M558,236.3l-1.1-1.9c-1.7-2.8-3.9-5.4-6.3-7.7 M566.2,274.6c0.3-3.7,0.3-7.4,0.3-11 M277.1,263.2
	c0,3.9,0.1,7.6,0.3,11.4 M548.4,277.9c1.2-6.3,0.8-13.6-1.1-22 M552.3,254.6c3.2,6.2,6.1,12.3,8.7,18.2 M454.6,236.3l-18.5,42.9
	 M551.6,229.6l0.7,2.5 M282.7,272.8c2.5-5.9,5.4-12.1,8.7-18.2 M296.2,255.9c-1.9,8.4-2.3,15.8-1.1,22 M552.3,232.1l-3.4,1.2
	 M550.6,226.7l1,2.9 M468.5,340.8h-93.4 M373.3,328.1h97 M277.5,277.9c3.2-9.6,7.7-17.9,13.4-24.8 M552.5,253.1
	c5.8,6.9,10.2,15.2,13.4,24.8 M548.8,233.3c-0.3-1.2-0.5-2.5-1-3.7l-1.1-3.3 M389,236.3l-28.4,0.1 M559,244.9l0.1,0.7
	c0.3,1.2,0.1,2.5-0.4,3.6l-1.5,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M580.1,244.7l-14.2,1.4c-3.4,0.3-5.8-0.1-6.9-1.2 M559,244.9
	c-0.4-1.8-0.7-3.4-0.8-5.1 M558.2,239.9c-0.1-1.2-0.1-2.3,0-3.4 M592.8,236.6c0.1-2.1-0.7-3.6-2.5-4.5c-7.3-3.4-15.6-5-24.7-4.8
	c-3.9,0.3-6.1,1.8-6.8,4.5c-0.4,1.4-0.7,2.9-0.8,4.5 M543.7,223l-1.5-4.7 M546.7,226.3c-1.9-5-3.9-9.9-5.8-14.7l-2.2-5.5 M538.6,206
	l3.6,12.3 M483,236.5l-28.4-0.1 M526.5,172l2.3,4.5c5.6,11.2,10.9,22.9,15.8,35l5.9,15l0.1,0.1 M493.7,284.5
	c9.6-8.5,18.7-15.2,27.6-19.7c0.4-0.1,0.7-0.3,1.1-0.4c3.2-1.4,6.5-2.2,10.1-2.5l4.5-0.1c3.7,0,6.8-1.5,9.4-4.5l1-1.2 M536.4,231.4
	l0.4-0.4 M536.4,231.4c-0.4,0.4-0.8,0.7-1.5,1c-2.1,1-4.3,1.8-6.8,2.3l-6.3,1.2c-5.8,0.7-11.3,1-16.5,0.8l-22.3-0.3 M463.9,242h-0.7
	v1.8h3.2V242h-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M465.6,242v1h-1.7v-1 M465.6,242v-0.8h-1.7v0.8 M537.5,229.8
	c-0.4-3.2-1-6.3-1.9-9.5l-0.4-1.2c-3.7-14.3-8.5-29.9-14.7-46.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M519.6,173.6c5.9,16.3,10.8,31.4,14.3,45.5l0.3,1.2c1,3.2,1.7,6.9,2.1,11
	 M537.5,229.8l0.4-1.2c0.5-2.3,0.3-5.5-1-9.5c-3.7-14.3-8-28.5-13-42.4l-0.3-0.7c-0.4-1.1-1.1-2.1-1.9-2.8 M526.5,172l1.8,4.5
	c2.2,5.6,4.4,11.6,6.5,17.8l3.7,11.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M540,231.2c0.6-1.2,0.8-2.5,0.8-3.9c-0.1-1.9-0.6-4.4-1.5-7.2
	c-4.3-14.7-9.2-29.2-14.9-43.5l-0.3-0.7c-0.4-1.2-1.2-2.2-2.1-2.9 M454.6,236.3H389 M436.1,279.2l9.5-10.7l9-20v-12.1 M407.3,279.2
	l-9.4-10.7l-9-20v-12.1 M379.6,242h0.7v1.8h-3.2V242h0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M377.8,242v1h1.8v-1 M379.6,242v-0.8h-1.8v0.8 M424.2,151.9l-0.8-3.2
	 M424.2,151.9c6.5,0,12.8,0.1,19.3,0.4c20.4,0.7,40.9,2.3,61.5,5 M419.3,151.9h5 M483,236.5l22.3-0.6c4.4-0.1,9-0.4,13.6-1.1
	c5.6-0.6,9.1-2.8,10.3-6.6c0.8-3,0.7-6.9-0.6-11.7c-2.5-9.2-5.4-18.9-8.8-29.1c-2.2-6.8-8.1-10.5-17.6-11.4
	c-26.7-2.1-53.3-3.2-79.8-3.2h-1.8c-26.5,0-53,1.1-79.8,3.2c-9.5,0.8-15.4,4.7-17.6,11.4c-3.4,10.2-6.3,19.8-8.8,29.1
	c-1.2,4.8-1.4,8.7-0.5,11.7c1.2,3.9,4.7,6.1,10.3,6.6c4.7,0.6,9.2,1,13.6,1.1l22.3,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M514.6,173.2c-30.9-2.8-61.6-4.1-92-4.1h-1.8c-30.5,0-61.2,1.4-92,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M515.2,171.4c-31-2.8-61.9-4.1-92.6-4.3h-1.8c-30.7,0-61.6,1.4-92.6,4.3
	 M419.3,151.9c0.3-1.1,0.5-2.1,0.8-3.2l0.1-0.4 M420.7,142.2l-0.6,6.2 M420.1,148.4c0.3-1.9,0.5-4,0.5-6.1 M423.4,148.7
	c-0.4-3-0.5-5.4-0.5-7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M422.9,141.8v0.3c0,2.3,0.1,4.5,0.5,6.8 M420.7,142.2v-2.8v-10.6l-0.1-1.4
	c0-0.4,0-0.7,0.1-1l0.1-0.6h0.1c0.1-0.4,0.4-0.6,0.8-0.6h0.1c0.4,0,0.5,0.1,0.8,0.6l0.3,0.6v1l-0.1,1.4v12.8 M519.6,173.6l-5-0.4
	 M510.5,158.3c7.4,2.1,12.4,5.9,14.7,11.4l1.1,2.2 M520.2,172.1c-0.5-0.3-1.2-0.4-1.9-0.4l-2.9-0.3 M521.1,172.5l-0.7-0.3
	 M521.1,172.5l-0.7-0.3 M521.9,173.1l-0.7-0.4c-0.1,0-0.1-0.1-0.1-0.1 M520.4,172.3l-0.3-0.1 M520.2,172.1l-0.5,1.5 M514.6,173.2
	l0.5-1.8 M505,157.2l1.1,0.1c1.5,0.3,3,0.6,4.4,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M510.5,158.3c1.5,0.6,2.6,1.5,3.6,3.2l6.1,10.5 M515.2,171.4L509,161
	c-1-1.7-2.3-2.9-4-3.6"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M307.1,231.4
		c0,0,0.6-5.2,1.8-9.8c0,0,5.6-23,15-47.9c0,0,41.5-4.5,97.8-4.5c0,0,35.8-0.7,98,4.5c0,0,8.7,23.4,15.2,48.6c0,0,1.4,5.5,1.7,9.1
		c0,0-5.1,5.6-32.8,5.4c0,0-16.4-0.3-38.9-0.3c-22.5,0-43,0-43,0h-35.5c0,0-25.6,0.1-35.7,0.3h-14.5
		C336.2,236.7,315.7,237.2,307.1,231.4z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M293.4,250l1.4-5.4
		c0,0,3.6-8.1,9-13.4l3.2-0.3c0,0,4.1,5,26.2,5.8l25.5-0.3c0,0,46.6-0.1,63.2-0.1l62.7,0.1c0,0,18.7,0.1,22.9,0.3c0,0,23,0,29.5-5.9
		l3.2,0.3c0,0,5.2,4.8,9.1,13.9c0,0,0.7,3.6,1.1,4.8c0,0-3.3,10.6-11.6,11.6l-1,0.1c0,0-5.5,0-8.4,0.7c0,0-4.3,0.8-8.5,2.9
		c0,0-3.9,1.9-12,7.4c0,0-6.9,4.8-15,12.1l-8.3-2.6c0,0-24.1-2.8-63.7-2.8c-39.4,0-63.7,2.8-63.7,2.8l-8.3,2.6
		c0,0-9.6-8.8-20.3-15.6c0,0-6.2-4.1-11.4-5.8c0,0-3.7-1.5-10.9-1.5c0,0-5.8,0.4-9.9-4.5C297.2,257.1,295.4,254.9,293.4,250z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M296.4,255.9
		c0,0,3.7,5.5,9.6,5.8c0,0,8.3-0.3,14.3,2.3c0,0,4.4,1.2,15.6,9.4c0,0,7.3,5.2,13.9,11.2l2.5,2.3c0,0,1.4,1.4,0,2.8
		c0,0-3.2,4.1-10.9,6.5c0,0-2.6,0.8-9.1-0.7c0,0-7.3-1.8-15.7-4.5c0,0-9.1-2.9-14.1-5.8c0,0-5.2-2.8-7.4-7.2c0,0-1.1-3.6-0.6-11.4
		C294.6,266.5,294.9,262.2,296.4,255.9z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M491.2,289.6
		c0,0-1.4-1.5,0-2.9c0,0,6.3-6.1,14.7-12.3c0,0,9.6-7.2,15.8-9.9c0,0,5.8-2.8,12.4-2.9l2.6-0.1c0,0,5.8,0.6,10.3-5.8
		c0,0,1.8,6.1,1.8,14.2c0,0,0,4.7-0.7,7.9c0,0-1.9,5.1-11.2,9.1c0,0-7.3,3-13.8,5c0,0-12.3,4-17.6,4.3c0,0-1.8,0.3-3.6-0.1
		C502.2,296.3,495.5,294.3,491.2,289.6z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M566.3,298.1l-6.8-12.1
		l-8-4.5l0.7,2.5c0,0,1.9,4.1,0.8,9.5c0,0,0.3,2.6-2.2,5.6c0,0-2.6,3.7-10.2,6.8c0,0-10.2,5.1-40.2,9.4c0,0,0.7,1.8-0.3,3.2
		c-1,1.4-2.3,3.7-6.1,5.9c0,0-4.1,2.9-13.5,3.7l-4.7,0.3H369.4c0,0-6.3,0.3-13.5-1.2c0,0-4.8-1-9-4c0,0-2.8-2.1-4.1-4.8
		c0,0-0.8-1.2-0.1-3c0,0-14.7-1.7-30.7-6.1c0,0-10.3-2.6-16.5-7.2c0,0-5.2-3.3-5.4-8.5c0,0-1.1-4.5,0.8-9.5l0.7-2.5l-8,4.5
		l-6.8,12.1c0,0,5.8,36.1,8.4,51.7c0,0,1.5,12.4,11,18.3l24.8,0.8l14.1,2.5h86.4h86.4l13.9-2.5l24.8-0.8c0,0,5.4-3.2,8.4-9.9
		c0,0,1.8-3.3,2.6-8.5c0.8-5.1-0.1,0-0.1,0S560.9,333.3,566.3,298.1z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M344.5,317.8c0,0-1.1-1.7,0.4-3.2
		c1.5-1.7,8.5-8.8,16.7-14.2c0,0,8.8-5.9,22.3-6.1l25.4-1h12.4h11.6l25.4,1c0,0,11.3,0,19.4,4.3l3.6,1.9c0,0,6.1,3.9,12.8,10.1
		l3.9,3.7c0,0,1.9,1.8,0.7,3.4c0,0-1.7,3.2-6.1,5.5c0,0-5.2,3.3-16.8,3.4h-54.4h-50.8c0,0-12.8,0.7-20.4-3.4l-2.3-1.5
		C348.2,321.8,346.1,320.5,344.5,317.8z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback5DrFront);
