import React, { Component } from 'react';

class Loader extends Component {
  render() {
    const {
      className = 'loader-container',
      size = 40,
      color = '#4D9BB0',
      textVisibility = true,
      style = {},
      strokeWidth = 4,
    } = this.props;

    return (
      <div className={className} style={style}>
        <div>
          <div>
            <svg
              width={size}
              height={size}
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              stroke={color}
              preserveAspectRatio="xMinYMin"
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth={strokeWidth}>
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </div>
          <div style={{ display: textVisibility ? '' : 'none' }}>
            Loading Please Wait...
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
