import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';
import { connect } from 'react-redux';
import { loadPrivateLotEvents } from '../../actions';
import { getConfig } from '../../utils/helpers';
window.$ = window.jQuery = $;

class AddPrivateLotModal extends Component {
  constructor(props) {
    super(props);
    this.addPrivateLotModalAction = this.addPrivateLotModalAction.bind(this);
  }

  componentDidMount() {
    if (!this.props.privateLot.items) {
      this.props.loadPrivateLotEvents(getConfig('marketplaceId'));
    }
  }

  addPrivateLotModalAction(e) {}

  renderPrivateLots() {
    //

    const items = _.values(this.props.privateLot.items).map((item, index) => {
      return (
        <div>
          <input type="checkbox" id={item.eventId} name="checkbox1" />
          <label>{item.eventName}</label>
          <br />
        </div>
      );
    });

    if (items.length > 0) {
      return <div>{items}</div>;
    } else {
      return <div>You dont have any privatelots</div>;
    }
  }

  render() {
    return (
      <div className="modal fade" id="addPrivateLotModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title">
                <span className="fa fa-table" />
                Add to Private Lot
              </h3>
            </div>
            <div className="modal-body">{this.renderPrivateLots()}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.addPrivateLotModalAction}
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { privateLot },
  } = state;

  return {
    privateLot,
  };
};

AddPrivateLotModal.propTypes = {
  loadPrivateLotEvents: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    loadPrivateLotEvents,
  }
)(AddPrivateLotModal);
