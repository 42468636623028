import React, { Component } from 'react';
import { loadEvents, resetEvents } from '../actions';
import { getConfig } from '../utils/helpers';
import { connect } from 'react-redux';
import _ from 'lodash';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import jstz from 'jstz';
const timezone = jstz.determine().name();

class Calendar extends Component {
  componentDidMount() {
    this.props.loadEvents(getConfig('marketplaceId'));
  }

  createEventTimeList() {
    if (this.props.events.eventList) {
      const items = _.values(this.props.events.eventList).map(item => {
        return {
          title: item.eventName,
          start: moment(`${item.liveStartDate}` - '8:00').tz(timezone),
          end: moment(`${item.liveStartDate}` - '8:00')
            .add(4, 'h')
            .tz(timezone),
        };
      });

      return items;
    }
  }

  render() {
    if (!this.props.events) {
      return null;
    }
    const items = _.values(this.props.events.eventList).map(item => {
      if (item.eventTypeId === 3) {
        return {
          title: item.eventName,

          start: moment(`${item.liveStartDate}` - '8:00').tz(timezone),
          end: moment(`${item.liveStartDate}` - '8:00')
            .add(4, 'h')
            .tz(timezone),
        };
      }
      return null;
    });
    BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
    return (
      <div
        className="container-fluid"
        style={{ paddingTop: '10px', backgroundColor: '#fff' }}
      >
        <div>
          <BigCalendar
            selectable
            events={items}
            defaultView="week"
            onSelectEvent={event => alert(event.title)}
            onSelectSlot={slotInfo =>
              alert(
                `selected slot: \n\nstart ${slotInfo.start.toLocaleString()}\nend: ${slotInfo.end.toLocaleString()}`
              )
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { events },
  } = state;

  return {
    events,
  };
};

export default connect(
  mapStateToProps,
  {
    loadEvents,
    resetEvents,
  }
)(Calendar);
