import React, { Component } from 'react';
import { connect } from 'react-redux';

class Crossover4DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M523.4,257.8c-0.9-1-2-1.9-3-2.7c-1-0.7-2.2-1.4-3.4-1.9c-2.3-0.9-5-1.5-7.9-1.5h-15.6
	c2.1,4.4,3.3,9.6,3.5,15.3v37.2c0.1,1,0.1,2-0.1,2.9h34.7l3.6-17.8c-0.1-2.1-0.6-4.2-1.2-6.4l-1-3.5l-1.2-4.2l-0.1-0.7
	c-0.6-2.2-1.2-4.2-2-6.2c-0.6-1.5-1.3-2.9-2-4.2l-0.8-1.4l-1.2-1.7C525,259.8,524.2,258.7,523.4,257.8L523.4,257.8z M525.7,287.1
	c0.3,6.1-0.3,10.1-2.2,12.4l-0.1,0.1c-1.5,1.6-4,2.7-7.2,3.1c-3.7,0.4-6.8,0.1-9.1-0.7c-3.6-1.2-5.2-5-4.9-11.8v-26.2
	c-0.1-2,0.2-3.5,1.3-4.4c0.9-1,2.5-1.5,4.5-1.4c4.5,0,8.5,2.7,11.7,7.9v0.1C522.5,271.6,524.5,278.6,525.7,287.1L525.7,287.1z"
          />
          <path
            fill="#D2D2D2"
            d="M525.7,287.3c-1.3-8.6-3.3-15.6-6.1-21.1V266c-3.1-5.2-7-7.8-11.7-7.9c-2.1-0.1-3.6,0.5-4.5,1.4
	c-1,0.9-1.5,2.4-1.3,4.4v26.2c-0.5,6.6,1.2,10.6,4.9,11.7c2.3,0.8,5.4,1,9.1,0.7c3.3-0.5,5.7-1.5,7.2-3.1l0.1-0.1
	C525.4,297.4,526.1,293.2,525.7,287.3L525.7,287.3z M523.4,287.5c0.3,5.1-0.2,8.6-1.7,10.6c-1.3,1.2-3.3,2-5.8,2.4
	c-3.4,0.4-6.1,0.1-8-0.6c-2.6-1-3.6-4.2-3.3-9.3v-26.6c-0.1-1.2,0.1-2.1,0.6-2.7c0.6-0.6,1.5-0.8,2.8-0.7c3.8,0.1,7,2.3,9.7,6.8
	C520.3,272.6,522.2,279.3,523.4,287.5L523.4,287.5z"
          />
          <path
            fill="#FFFFFF"
            d="M531.1,249.1c-3.8,1.9-5.6,4.7-5.5,8.3l0.1,2c0.3,1.3,0.7,2.3,1.2,3.3l0.8,1.4c0.7,0.9,1.4,1.6,2.2,2.1
	c0.9,0.6,2,0.8,3,0.8l0.6-0.9c-2.1,0-3.7-0.9-5-2.7l-0.7-1.2c-0.5-0.8-0.8-1.9-1-2.9l-0.1-1.9c-0.2-3.3,1.4-5.7,4.9-7.5
	c2.5-1.3,5.8-1.3,9.8,0c3.6,1.3,5.9,3,6.9,5.5l0.1,0.4c1,2.9,0.5,5.5-1.8,7.6c-2.3,2.3-6.6,3.3-13.1,2.8l-0.6,0.9h0.2
	c7.1,0.5,11.9-0.6,14.5-3.1c2.4-2.3,3-5.1,2-8.4l-0.1-0.4c-1.2-2.6-3.6-4.7-7.7-6.1C537.5,247.8,533.9,247.8,531.1,249.1
	L531.1,249.1z"
          />
          <path
            fill="#D2D2D2"
            d="M525.9,259.5v1.5l1.2,1.8C526.6,261.7,526.2,260.7,525.9,259.5L525.9,259.5z"
          />
          <path
            fill="#FFFFFF"
            d="M532.6,268.3c0.2-0.5,0.5-0.8,0.8-1.2h-0.2l-0.1,0.2l0.1-0.2c-1.2,0-2.2-0.4-3-0.8s-1.6-1.2-2.2-2.1
	c0.7,1.3,1.4,2.7,2,4.2c0.7,1.9,1.4,4,2,6.2c-0.1-1.5,0-3.1,0.2-4.7C532.2,269.1,532.4,268.7,532.6,268.3L532.6,268.3z M531.8,275
	l1.2,4.2h3.3c0.5,0,0.8-0.2,1-0.5c0.3-0.4,0.5-0.7,0.5-1.2v-0.8c0-0.5-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5h-4.3L531.8,275L531.8,275z
	 M536.6,279.5l-0.3-0.2H533l1,3.5h1.4c0.5,0,0.8-0.1,1.2-0.5s0.5-0.7,0.5-1.2v-0.5C537.1,280.2,536.9,279.8,536.6,279.5L536.6,279.5
	z M536.8,302.9c0.3-5.9-0.2-10.5-1.6-13.8l-3.6,17.8l-4.5,22.5h5.1l3-17.8L536.8,302.9L536.8,302.9z M532.2,329.6H527l-1,5.1
	c-1,7.3-3.7,11.3-8.3,11.7l-16,0.9c-4.9-7.8-10.7-10.6-17.3-8.6c-5.4,0.6-9.8,4-13.3,10.4c-1.5,1.2-3.1,1.9-4.8,2.1
	c-1.2,0.2-2.3,0.2-3.6,0h-83.7c-1.3,0.2-2.4,0.2-3.6,0c-1.8-0.2-3.3-0.9-4.8-2.1c-3.5-6.4-7.9-9.8-13.3-10.4
	c-6.5-2.1-12.4,0.8-17.3,8.6l-16-0.9c-4.5-0.4-7.3-4.2-8.3-11.7l-1-5.1h-5.1l2.8,16.4c0.6,5.9,2.9,9.8,7.2,11.5l1.2,0.4
	c4.3,2.5,9.1,4.3,14.2,5.6c2.9,0.8,6.1,1.4,9.3,1.7v-9.4c0.2-7.1,3.3-10.7,9-10.8c5.8,0.1,9.3,2.4,10.5,7.2c0.7,2.8,1.3,7,1.6,12.6
	l19.5,0.8c7.8,0.2,16.1,0.4,24.8,0.5l14.6,0.1c11.5,0,22.4-0.2,32.5-0.6l19.5-0.8c0.4-5.6,0.9-9.8,1.6-12.6
	c1.2-4.7,4.7-7.1,10.5-7.2c5.7,0.1,8.7,3.7,9,10.8v9.4c3.3-0.5,6.4-1,9.3-1.7c5.1-1.3,9.9-3.1,14.2-5.6l1.2-0.4
	c4.2-1.7,6.6-5.6,7.2-11.5L532.2,329.6L532.2,329.6z"
          />
          <path
            fill="#FFFFFF"
            d="M526.1,334.7l1-5.1l4.5-22.5h-34.7c-0.5,3.3-2.2,5.7-5.2,7.6l-0.5,0.2c-2.8,1.4-6.9,2.1-12.1,2.1H362.9
	c-5.2,0-9.3-0.7-12.1-2.1l-0.5-0.2c-3-1.9-4.8-4.4-5.2-7.6h-34.7l4.5,22.5l1,5.1c1,7.3,3.7,11.3,8.3,11.7l16,0.9
	c4.9-7.8,10.7-10.6,17.3-8.6c5.4,0.6,9.8,4,13.3,10.4c1.5,1.2,3.1,1.9,4.8,2.1h3.6l0.9-0.1l-0.9,0.1h83.7l-0.9-0.1l0.9,0.1h3.6
	c1.8-0.2,3.3-0.9,4.8-2.1c3.5-6.4,7.9-9.8,13.3-10.4c6.5-2.1,12.4,0.8,17.3,8.6l16-0.9C522.2,346,525,342.2,526.1,334.7L526.1,334.7
	z M524,328.3l-0.2,1.2l-0.6,3l-0.8,4l-0.7,3.3l-0.1,0.7c-0.1,0.5-0.5,0.9-0.9,1.2c-0.5,0.2-1,0.4-1.7,0.2c-0.6-0.1-1-0.4-1.5-0.8
	c-0.3-0.5-0.5-0.9-0.5-1.4l0.1-0.6l0.6-3.3l0.7-4l0.6-3l0.2-1c0.1-0.6,0.5-1,0.9-1.4c0.6-0.4,1.2-0.6,1.9-0.5
	c0.7,0.1,1.3,0.4,1.6,0.8C524,327.1,524.1,327.7,524,328.3L524,328.3z M346.6,328.8c0.8,0,1.5,0.2,2.1,0.8c0.6,0.6,0.9,1.3,0.9,2.1
	s-0.4,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.1,0.9s-1.5-0.4-2.1-0.9c-0.6-0.6-0.8-1.3-0.8-2.1s0.2-1.5,0.8-2.1
	C344.9,329,345.8,328.8,346.6,328.8L346.6,328.8z M320,326c0.7-0.1,1.3,0.1,1.9,0.5s0.8,0.8,0.9,1.4l0.2,1.1l0.6,3l0.7,4l0.6,3.3
	l0.1,0.6c0.1,0.5,0,0.9-0.5,1.4c-0.4,0.4-0.9,0.7-1.5,0.8c-0.7,0.1-1.3,0-1.8-0.2c-0.6-0.2-0.8-0.7-0.9-1.2l-0.1-0.7l-0.7-3.3
	l-0.8-4l-0.6-3l-0.2-1.2c-0.1-0.6,0-1.2,0.5-1.5C318.7,326.2,319.2,326,320,326z M381.4,331.1c-0.5-3.5,0.7-5.7,3.5-6.8
	c1.2-0.5,2.7-0.7,4.4-0.7h63.1c1.7,0,3.3,0.2,4.4,0.7c2.8,1,4,3.3,3.5,6.8l-1.2,11.3c-0.4,1.2-1,2.2-2.3,2.9
	c-1.3,0.8-3.1,1.4-5.4,1.6h-61.2c-2.3-0.4-4.1-0.8-5.4-1.6c-1.3-0.8-2-1.7-2.3-2.9L381.4,331.1L381.4,331.1z M495.3,328.8
	c0.8,0,1.5,0.2,2.1,0.8c0.6,0.6,0.8,1.3,0.8,2.1s-0.2,1.5-0.8,2.1c-0.6,0.6-1.3,0.9-2.1,0.9s-1.5-0.4-2.1-0.9
	c-0.6-0.6-0.9-1.3-0.9-2.1s0.4-1.5,0.9-2.1C493.8,329,494.5,328.8,495.3,328.8L495.3,328.8z"
          />
          <path
            fill="#FFFFFF"
            d="M523.8,329.5l0.2-1.2c0.1-0.6,0-1.2-0.5-1.5c-0.3-0.5-0.9-0.7-1.6-0.8c-0.7-0.1-1.3,0.1-1.9,0.5
	c-0.6,0.4-0.8,0.8-0.9,1.4l-0.2,1.1L523.8,329.5L523.8,329.5z M522.5,336.5l0.7-4.1l-4.8-0.5l-0.7,4"
          />
          <path
            fill="#FFFFFF"
            d="M521.8,339.7l0.7-3.3l-4.8-0.6l-0.6,3.3 M523.2,332.4l0.6-2.9l-4.9-0.7l-0.5,3.1"
          />
          <path
            fill="#FFFFFF"
            d="M520.7,341.6c0.6-0.2,0.8-0.7,0.9-1.2l0.1-0.7l-4.7-0.6l-0.1,0.6c-0.1,0.5,0,0.9,0.5,1.4
	c0.3,0.4,0.9,0.7,1.5,0.8C519.6,341.9,520.1,341.9,520.7,341.6L520.7,341.6z M348.7,329.6c-0.6-0.6-1.3-0.8-2.1-0.8
	s-1.5,0.2-2.1,0.8c-0.6,0.6-0.8,1.3-0.8,2.1s0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.4,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	C349.5,330.9,349.3,330.2,348.7,329.6z M321.7,326.3c-0.6-0.4-1.2-0.6-1.9-0.5c-0.7,0.1-1.3,0.4-1.6,0.8c-0.5,0.5-0.6,0.9-0.5,1.5
	l0.2,1.2l4.9-0.6l-0.2-1C322.7,327.1,322.3,326.7,321.7,326.3L321.7,326.3z M318.7,332.4l0.7,4.1l4.8-0.6l-0.7-4"
          />
          <path
            fill="#FFFFFF"
            d="M318.7,332.4l4.8-0.5l-0.6-3.1l-4.8,0.7 M319.4,336.5l0.7,3.3l4.7-0.6l-0.6-3.3"
          />
          <path
            fill="#FFFFFF"
            d="M324.8,339.6l-0.1-0.6l-4.7,0.6l0.1,0.7c0.1,0.5,0.5,0.9,0.9,1.2c0.5,0.2,1,0.4,1.8,0.2
	c0.6-0.1,1-0.4,1.5-0.8C324.8,340.7,324.9,340.2,324.8,339.6L324.8,339.6z M384.9,324.3c-2.8,1-4,3.3-3.5,6.8l1.2,11.3
	c0.4,1.2,1,2.2,2.3,2.9c1.3,0.8,3.1,1.4,5.4,1.6h61.2c2.3-0.4,4.1-0.8,5.4-1.6c1.3-0.8,2-1.7,2.3-2.9l1.2-11.3
	c0.5-3.5-0.7-5.7-3.5-6.8c-1.2-0.5-2.7-0.7-4.4-0.7h-63.1C387.6,323.6,386.1,323.9,384.9,324.3L384.9,324.3z M497.4,329.6
	c-0.6-0.6-1.3-0.8-2.1-0.8s-1.5,0.2-2.1,0.8c-0.6,0.6-0.9,1.3-0.9,2.1s0.4,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.4,2.1-0.9
	c0.6-0.6,0.8-1.3,0.8-2.1C498.3,330.9,498,330.2,497.4,329.6L497.4,329.6z M421.6,186.3c0-0.2-0.1-0.4-0.2-0.5
	c-0.1-0.1-0.2-0.2-0.5-0.2s-0.4,0.1-0.5,0.2s-0.2,0.2-0.2,0.5l-0.4,5.8v1.3c0,0.9,0.5,1.3,1.2,1.3s1.2-0.4,1-1.3l-0.1-1.3
	L421.6,186.3L421.6,186.3z"
          />
          <path
            fill="#FFFFFF"
            d="M422.2,192.1l0.1,1.3c0,0.9-0.4,1.3-1,1.3c-0.8,0-1.2-0.4-1.2-1.3v-1.3c-1.2,0.2-1.9,1.1-2.2,2.2v1.6
	c0.1,0.5,0.4,0.7,0.9,0.7h4.5c0.5,0,0.8-0.2,0.9-0.7v-1.6C423.9,193.2,423.3,192.5,422.2,192.1L422.2,192.1z"
          />
          <path
            fill="#FFFFFF"
            d="M424.2,196c-0.1,0.5-0.4,0.7-0.9,0.7h-4.5c-0.5,0-0.8-0.2-0.9-0.7v-1.6h-1.6c-23.2,0.1-41.4,1.3-54.6,3.5
	c-0.1-0.5-0.5-0.8-0.9-1.1l-0.6,3.4c-0.1,0.4-0.4,0.6-0.8,0.8c-0.5,0.2-0.9,0.4-1.4,0.4h-1.2c-0.5,0-0.8-0.1-1.2-0.5
	s-0.6-0.6-0.7-0.9l-1.3-3.1h-0.2c-4.2,0.9-8.2,4.2-11.8,9.9c1.4,0.4,1.8,1.3,1.2,2.9l-2.6,4.7c2.2-0.5,5.1-0.8,8.6-1.2l9.8-0.7
	c0.7-3.8,3.3-6.2,7.6-7.1c4.8-1.6,13.6-2.7,26.6-3.4l28.1-1.1l28.2,0.8c12.9,0.7,21.8,1.9,26.6,3.4c4.3,0.9,6.9,3.3,7.6,7.1l9.8,0.7
	c3.5,0.4,6.4,0.7,8.6,1.2l-2.6-4.7c-0.6-1.5-0.2-2.6,1.2-2.9c-3.6-5.7-7.6-9-11.8-9.9h-0.2l-1.3,3.1c-0.1,0.2-0.4,0.6-0.7,0.9
	s-0.7,0.5-1.2,0.5h-1.2c-0.5,0-0.9-0.1-1.4-0.4s-0.7-0.5-0.8-0.8s-0.4-1.5-0.6-3.4c-0.6,0.2-0.8,0.6-0.9,1.1
	c-12.9-2.2-30.8-3.4-53.4-3.5h-2.6L424.2,196L424.2,196z"
          />
          <path
            fill="#FFFFFF"
            d="M417.4,211.6h6.9c8.9-0.1,17.1-0.1,24.6-0.1c13.9,0,25.3,0.4,34.2,0.8c-0.7-3.8-3.3-6.2-7.6-7.1
	c-4.8-1.6-13.6-2.7-26.6-3.4l-28.1-0.9l-28,0.8c-12.9,0.7-21.8,1.9-26.6,3.4c-4.3,0.9-6.9,3.3-7.6,7.1c9-0.5,20.3-0.7,34.2-0.8
	C400.4,211.4,408.6,211.5,417.4,211.6L417.4,211.6z M494.5,216.3l2.5,2.2l8.9,24.7l-0.8,2.3l2,6.2h2.2c2.9,0,5.6,0.6,7.9,1.5
	c-5.1-16-10.3-29-15.4-39.1c-2.2-0.5-5.1-0.8-8.6-1.2L494.5,216.3L494.5,216.3z"
          />
          <path
            fill="#FFFFFF"
            d="M497,218.4l-2.5-2.1L493,213l-9.9-0.8l10.4,39.4H507l-2-6.1l0.8-2.3 M487,200l1.3-3.1
	c-0.2-0.7-0.5-1.3-0.8-1.9c-0.4-0.7-1.3-1.1-2.8-1.1c-1.6,0.1-2.6,0.5-2.9,0.9c-0.4,0.6-0.6,1.3-0.6,1.9c0.4,2,0.5,3.1,0.6,3.4
	c0.1,0.4,0.4,0.6,0.8,0.8c0.5,0.2,0.9,0.4,1.4,0.4h1.2c0.5,0,0.8-0.1,1.2-0.5C486.7,200.5,486.9,200.2,487,200L487,200z
	 M353.6,196.8l1.3,3.1c0.1,0.2,0.4,0.6,0.7,0.9s0.7,0.5,1.2,0.5h1.2c0.5,0,0.9-0.1,1.4-0.4c0.5-0.2,0.7-0.5,0.8-0.8l0.6-3.4
	c0-0.7-0.2-1.3-0.6-1.9c-0.4-0.5-1.3-0.8-2.9-0.9c-1.5,0-2.4,0.2-2.8,1.1C354,195.5,353.7,196.1,353.6,196.8L353.6,196.8z
	 M347.4,216.3l1.5-3.3c-3.5,0.4-6.4,0.7-8.6,1.2c-5.2,10-10.4,23-15.4,39.1c2.3-0.9,5-1.5,7.9-1.5h2.2l2-6.2l-0.8-2.3l8.9-24.7
	L347.4,216.3L347.4,216.3z"
          />
          <path
            fill="#FFFFFF"
            d="M348.9,213l-1.5,3.3l-2.4,2.1l-8.9,24.8l0.8,2.3l-2,6.1h13.4l10.4-39.4 M310.1,250.1c3.4,1.7,5,4.2,4.9,7.5
	l-0.1,1.9c-0.4,1.2-0.7,2.1-1,2.9c-0.2,0.4-0.5,0.8-0.7,1.2c-1.3,1.8-2.9,2.7-5,2.7l0.6,0.9c1.2,0,2.2-0.4,3-0.8
	c0.8-0.5,1.6-1.2,2.2-2.1c0.2-0.5,0.5-0.9,0.8-1.4c0.5-0.9,0.9-2,1.2-3.3l0.1-2c0.2-3.6-1.6-6.4-5.5-8.3c-2.8-1.4-6.4-1.4-10.8,0
	c-4,1.4-6.5,3.4-7.7,6.1l-0.1,0.4c-1.2,3.3-0.5,6.1,2,8.4c2.6,2.6,7.5,3.6,14.5,3.1h0.2l-0.6-0.9c-6.4,0.4-10.7-0.6-13.1-2.8
	c-2.2-2.1-2.8-4.7-1.8-7.6l0.1-0.4c1-2.3,3.3-4.2,6.9-5.5C304.4,248.8,307.6,248.8,310.1,250.1L310.1,250.1z M324.9,253.2
	c-1.2,0.5-2.3,1.2-3.4,1.9c-1,0.8-2.1,1.6-3,2.7c-0.9,0.9-1.6,2.1-2.4,3.3c-0.5,0.6-0.8,1.2-1.2,1.8c-0.2,0.5-0.6,0.9-0.8,1.4
	c-0.7,1.3-1.4,2.7-2,4.2c-0.7,1.9-1.4,4-2,6.2l-0.1,0.7l-1.2,4.2l-1,3.5c-0.6,2.2-1,4.3-1.2,6.4l3.6,17.8h34.7
	c-0.1-0.9-0.1-1.9-0.1-2.9v-37.2c0.2-5.8,1.4-10.8,3.5-15.3h-15.6C329.8,251.7,327.2,252.2,324.9,253.2z M333.9,258.2
	c2.1-0.1,3.6,0.5,4.5,1.4c1,0.9,1.5,2.4,1.3,4.4v26.2c0.5,6.6-1.2,10.6-4.9,11.7c-2.3,0.8-5.4,1-9.1,0.7c-3.3-0.5-5.7-1.5-7.2-3.1
	l-0.1-0.1c-1.9-2.2-2.7-6.3-2.2-12.2V287c1.3-8.5,3.3-15.5,6.1-21v-0.1C325.5,260.9,329.3,258.4,333.9,258.2L333.9,258.2z"
          />
          <path
            fill="#D2D2D2"
            d="M338.4,259.8c-0.9-1-2.4-1.5-4.5-1.4c-4.5,0-8.5,2.7-11.7,7.9v0.1c-2.8,5.4-4.8,12.4-6.1,21v0.1
	c-0.4,5.9,0.4,10,2.2,12.2l0.1,0.1c1.5,1.6,4,2.7,7.2,3.1c3.7,0.4,6.8,0.1,9.1-0.7c3.6-1.2,5.2-5,4.9-11.8v-26.2
	C339.9,262.2,339.5,260.7,338.4,259.8L338.4,259.8z M333.9,260.6c1.3,0,2.2,0.2,2.9,0.7c0.5,0.6,0.7,1.5,0.6,2.7v26.7
	c0.4,5.2-0.8,8.4-3.3,9.3c-2.1,0.7-4.8,0.9-8,0.6c-2.7-0.5-4.5-1.3-5.8-2.4c-1.5-2-2-5.5-1.7-10.6c1.2-8.2,3.1-14.9,5.8-20.2
	C326.9,263,330.1,260.7,333.9,260.6L333.9,260.6z M314.9,262.8c0.4-0.6,0.8-1.2,1.2-1.8v-1.5C315.7,260.7,315.3,261.7,314.9,262.8
	L314.9,262.8z"
          />
          <path
            fill="#FFFFFF"
            d="M308.7,267.1h-0.2c0.2,0.4,0.5,0.7,0.8,1.2l0.6,1.4c0.2,1.6,0.4,3.1,0.2,4.7c0.6-2.2,1.2-4.2,2-6.2
	c0.6-1.5,1.3-2.9,2-4.2c-0.7,0.9-1.4,1.6-2.2,2.1C310.9,266.8,309.9,267.1,308.7,267.1l0.1,0.2L308.7,267.1L308.7,267.1z
	 M308.8,279.2l1.2-4.2h-4.4c-0.5,0-0.8,0.1-1.2,0.5s-0.5,0.7-0.5,1.2v0.8c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1,0.5h3.4V279.2z
	 M308.8,279.2h-3.3l-0.4,0.2c-0.4,0.4-0.5,0.7-0.5,1.2v0.5c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5h1.4L308.8,279.2L308.8,279.2z
	 M350.2,314.7l0.5,0.2c2.8,1.4,6.9,2.1,12.1,2.1H479c5.2,0,9.3-0.7,12.1-2.1l0.5-0.2c3-1.9,4.8-4.4,5.2-7.6c0.1-0.9,0.1-1.9,0.1-2.9
	V267c-0.2-5.8-1.4-10.8-3.5-15.3H348.3c-2.1,4.4-3.3,9.6-3.5,15.3v37.2c-0.1,1-0.1,2,0.1,2.9C345.4,310.2,347.2,312.8,350.2,314.7z
	 M405.9,273.6l-1.2-1.3l-1-1.3l-1-1.4h12.1c0.4-0.5,0.7-1,1.2-1.5c1.5-1.5,3.4-2.3,5.6-2.3c2.1,0,4,0.8,5.5,2.3
	c0.5,0.5,0.8,0.9,1.2,1.5h12.1l-1,1.4l-1,1.3l-1.2,1.3l-1.3,1.3l-1.5,1.3l-1.9,1.4l-2,1.4h-3.3l-0.2,0.2c-1.5,1.5-3.4,2.3-5.5,2.3
	c-2.2,0-4-0.8-5.6-2.3l-0.2-0.2h-3.3l-2-1.4l-1.9-1.4l-1.5-1.3L405.9,273.6L405.9,273.6z"
          />
          <path
            fill="#D2D2D2"
            d="M404.7,272.3l1.2,1.3h7.8c0-0.5,0-0.8,0.1-1.3H404.7L404.7,272.3z M402.6,269.7l1,1.4h10.4
	c0.2-0.5,0.4-0.9,0.7-1.4H402.6z"
          />
          <path
            fill="#D2D2D2"
            d="M403.7,271.1l1,1.3h9c0.1-0.5,0.2-0.9,0.4-1.3H403.7z M407.2,274.9l1.5,1.3h5.4c-0.1-0.5-0.2-0.9-0.4-1.3
	L407.2,274.9L407.2,274.9z"
          />
          <path
            fill="#D2D2D2"
            d="M408.7,276.2l1.8,1.4h4.2l-0.7-1.4H408.7L408.7,276.2z M410.5,277.6l2,1.4h3.3l-1-1.4H410.5L410.5,277.6z
	 M405.9,273.6l1.3,1.3h6.6c0-0.4-0.1-0.8-0.1-1.2h-7.8L405.9,273.6L405.9,273.6z M428.9,271.1c0.1,0.5,0.2,0.8,0.4,1.3h9l1-1.3
	H428.9z"
          />
          <path
            fill="#D2D2D2"
            d="M429.2,272.5c-0.1-0.5-0.2-0.9-0.4-1.3c-0.2-0.5-0.4-1-0.7-1.4c-0.4-0.5-0.7-1-1.2-1.5
	c-1.5-1.5-3.4-2.3-5.5-2.3c-2.2,0-4,0.8-5.6,2.3c-0.5,0.5-0.8,0.9-1.2,1.5l-0.7,1.4c-0.1,0.5-0.2,0.9-0.4,1.3
	c-0.1,0.5-0.1,0.8-0.1,1.3c0,0.4,0,0.8,0.1,1.2c0.1,0.5,0.1,0.9,0.4,1.3c0.1,0.5,0.4,0.9,0.7,1.4c0.2,0.5,0.6,0.9,1,1.4l0.2,0.2
	c1.5,1.5,3.4,2.3,5.6,2.3s4-0.8,5.5-2.3l0.2-0.2l1-1.4c0.2-0.5,0.5-0.9,0.7-1.4l0.4-1.3l0.1-1.2L429.2,272.5L429.2,272.5z
	 M426.3,269c1.3,1.3,2,2.9,2,4.8s-0.7,3.5-2,4.8c-1.3,1.3-2.9,2-4.8,2c-1.9,0-3.5-0.7-4.8-2c-1.3-1.3-2-2.9-2-4.8s0.7-3.5,2-4.8
	c1.3-1.3,2.9-2,4.8-2C423.4,267,424.9,267.6,426.3,269L426.3,269z"
          />
          <path
            fill="#D2D2D2"
            d="M428.3,273.7c0-1.9-0.7-3.5-2-4.8c-1.3-1.3-2.9-2-4.8-2c-1.9,0-3.5,0.7-4.8,2c-1.3,1.3-2,2.9-2,4.8
	s0.7,3.5,2,4.8c1.3,1.3,2.9,2,4.8,2c1.9,0,3.5-0.7,4.8-2C427.6,277.1,428.3,275.6,428.3,273.7L428.3,273.7z M415.4,273.7
	c0-1.8,0.6-3.1,1.7-4.3s2.7-1.8,4.3-1.8c1.8,0,3.1,0.6,4.3,1.8s1.7,2.7,1.7,4.3c0,1.8-0.6,3.1-1.7,4.3s-2.7,1.9-4.3,1.9
	c-1.7,0-3.1-0.6-4.3-1.9C415.9,276.9,415.3,275.4,415.4,273.7L415.4,273.7z"
          />
          <path
            fill="#D2D2D2"
            d="M417.2,269.3c-1.2,1.2-1.8,2.7-1.8,4.3c0,1.8,0.6,3.1,1.8,4.3c1.2,1.2,2.7,1.9,4.3,1.9
	c1.8,0,3.1-0.6,4.3-1.9c1.2-1.2,1.8-2.7,1.8-4.3c0-1.8-0.6-3.1-1.8-4.3s-2.7-1.8-4.3-1.8C419.8,267.6,418.4,268.1,417.2,269.3
	L417.2,269.3z M417,272l1,2.2l1-2.2h0.8v3.4h-0.8v-1.9l-0.9,1.9h-0.2l-0.8-1.9v1.9h-0.9V272L417,272L417,272z M420.6,272h0.8v3.4
	h-0.8L420.6,272L420.6,272z M422.2,272h0.6l1.6,2.1V272h0.7v3.4h-0.7l-1.6-2v2h-0.7V272H422.2z M426.7,275.4h-0.8V272h0.8V275.4z"
          />
          <path
            fill="#D2D2D2"
            d="M418,274.2l-1-2.2h-0.8v3.4h0.8v-1.9l0.9,1.9h0.2l0.9-1.9v1.9h0.7V272h-0.7 M421.4,272h-0.8v3.4h0.8
	 M422.8,272h-0.6v3.4h0.7v-2l1.5,2h0.7V272h-0.7v2.1 M425.8,275.4h0.8V272h-0.8 M428.3,269.7l0.7,1.4h10.5l1-1.4H428.3z
	 M429.3,273.6v0.1l-0.1,1.2h6.6l1.3-1.3 M429.2,272.5l0.1,1.2h7.8l1.2-1.3 M434.4,276.2l1.5-1.3h-6.6l-0.2,1.3"
          />
          <path
            fill="#D2D2D2"
            d="M428.3,277.6h4.3l1.9-1.4H429C428.8,276.7,428.6,277.1,428.3,277.6L428.3,277.6z M432.6,277.6h-4.3l-1,1.4
	h3.4"
          />
          <path
            fill="#FFFFFF"
            d="M310.2,307.1l-3.6-17.8c-1.5,3.3-2,7.8-1.6,13.6v0.1l1.6,8.9l3,17.8h5.1L310.2,307.1L310.2,307.1z"
          />
          <path
            fill="#D2D2D2"
            d="M375.5,351.1c1.2,0.2,2.3,0.2,3.6,0H375.5L375.5,351.1z M462.8,351.1c1.3,0.2,2.4,0.2,3.6,0H462.8z"
          />
          <path
            fill="#646464"
            d="M532.2,329.6l-2.8,16.4c-0.6,5.9-2.9,9.8-7.2,11.5l-1.2,0.4c-4.3,2.5-9.1,4.3-14.2,5.6v25.4
	c0.1,4.5,1.4,7.5,4,8.6c0.8,0.4,2,0.6,3.5,0.7h9.6c1.5,0.1,2.8,0.1,4,0c1.5-0.1,2.7-0.4,3.5-0.7c2.6-1.3,3.8-4.1,4-8.6v-77.2
	L532.2,329.6L532.2,329.6z"
          />
          <path
            fill="#646464"
            d="M353.3,345.1c-5.7,0.1-8.7,3.7-9,10.8v9.4c-3.3-0.5-6.4-1-9.3-1.7v16.3c3.1,0.5,6.9,1,11.5,1.4
	c7.9,0.2,13.6-0.6,17.3-2.4c2.4-1.3,4.1-3.3,5-6.1h104.3c0.9,2.8,2.7,4.8,5,6.1c3.6,1.9,9.4,2.7,17.3,2.4c4.5-0.5,8.4-0.9,11.5-1.4
	v-16.3c-2.9,0.8-6.1,1.4-9.3,1.7v-9.4c-0.2-7.1-3.3-10.7-9-10.8c-5.8,0.1-9.3,2.4-10.5,7.2c-0.7,2.8-1.3,7-1.6,12.6l-19.5,0.8
	c-10.1,0.4-21.1,0.5-32.5,0.6l-14.6-0.1c-8.6-0.1-16.9-0.2-24.8-0.5l-19.5-0.8c-0.4-5.6-0.9-9.8-1.6-12.6
	C362.7,347.5,359.2,345.1,353.3,345.1L353.3,345.1z M349.3,353.1c0-1.5,0.5-2.7,1.5-3.7c1-1,2.3-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5
	c1,1,1.5,2.3,1.5,3.7c0,1.5-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5s-2.7-0.5-3.7-1.5C349.7,355.9,349.3,354.6,349.3,353.1
	L349.3,353.1z M482,353.1c0-1.5,0.5-2.7,1.5-3.7s2.3-1.5,3.7-1.5c1.5,0,2.7,0.5,3.7,1.5s1.5,2.3,1.5,3.7c0,1.5-0.5,2.7-1.5,3.7
	s-2.3,1.5-3.7,1.5c-1.5,0-2.7-0.5-3.7-1.5C482.5,355.9,482,354.6,482,353.1z"
          />
          <path
            fill="#646464"
            d="M350.8,349.4c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5
	c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.7-1.5S351.8,348.3,350.8,349.4z M350.8,353.1c0-1,0.4-1.9,1-2.7
	c0.7-0.7,1.6-1,2.7-1s1.9,0.4,2.7,1c0.7,0.7,1,1.6,1,2.7s-0.4,1.9-1,2.7c-0.7,0.7-1.6,1-2.7,1s-1.9-0.4-2.7-1
	C351.1,355.1,350.8,354.2,350.8,353.1z"
          />
          <path
            fill="#646464"
            d="M351.9,350.6c-0.7,0.7-1,1.6-1,2.7s0.4,1.9,1,2.7c0.7,0.7,1.6,1,2.7,1s1.9-0.4,2.7-1c0.7-0.7,1-1.6,1-2.7
	s-0.4-1.9-1-2.7c-0.7-0.7-1.6-1-2.7-1S352.6,349.9,351.9,350.6z M483.5,349.4c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7
	s2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5s1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7s-2.3-1.5-3.7-1.5S484.6,348.3,483.5,349.4z
	 M484.7,355.8c-0.7-0.7-1-1.6-1-2.7s0.4-1.9,1-2.7c0.7-0.7,1.6-1,2.7-1c1,0,1.9,0.4,2.7,1c0.7,0.7,1,1.6,1,2.7s-0.4,1.9-1,2.7
	c-0.7,0.7-1.6,1-2.7,1S485.4,356.5,484.7,355.8z"
          />
          <path
            fill="#646464"
            d="M483.5,353.1c0,1,0.4,1.9,1,2.7c0.7,0.7,1.6,1,2.7,1s1.9-0.4,2.7-1c0.7-0.7,1-1.6,1-2.7s-0.4-1.9-1-2.7
	c-0.7-0.7-1.6-1-2.7-1s-1.9,0.4-2.7,1C484,351.3,483.5,352.1,483.5,353.1L483.5,353.1z M312.4,346l-2.8-16.4l-3-17.8v77.2
	c0.1,4.5,1.4,7.5,4,8.6c0.8,0.4,2,0.6,3.5,0.7c1.2,0.1,2.4,0.1,4,0h9.6c1.5-0.1,2.7-0.4,3.5-0.7c2.6-1.3,3.8-4.1,4-8.6v-25.4
	c-5.1-1.3-9.9-3.1-14.2-5.6l-1.2-0.4C315.4,355.8,313,352,312.4,346L312.4,346z M380.5,374.7c0.4-0.1,0.9-0.7,1.8-1.9h-6.8l1.6,1.5
	c0.4,0.4,0.7,0.5,1.2,0.5L380.5,374.7L380.5,374.7z M399,372.8c1.9,1.4,5.4,2.3,10.7,2.8l6.8,0.5h9.2c8.9-0.4,14.6-1.4,17.1-3.3H399
	L399,372.8z M461.3,374.7l2.2,0.1c0.5,0,0.8-0.1,1.2-0.5l1.6-1.5h-6.8C460.5,373.9,461,374.6,461.3,374.7L461.3,374.7z"
          />
          <path
            fill="#EBEBEB"
            d="M521.7,298.1c1.4-2,2-5.5,1.7-10.6c-1.2-8.2-3.1-14.9-5.8-20.2c-2.7-4.4-5.8-6.6-9.7-6.8
	c-1.3,0-2.2,0.2-2.8,0.7c-0.5,0.6-0.7,1.5-0.6,2.7v26.7c-0.4,5.2,0.8,8.4,3.3,9.3c2.1,0.7,4.8,0.9,8,0.6
	C518.4,300.1,520.4,299.3,521.7,298.1L521.7,298.1z M508.7,271.3c1-1,2.3-1.6,3.8-1.6c1.5,0,2.8,0.6,3.8,1.6c1,1,1.6,2.3,1.6,3.8
	s-0.6,2.8-1.6,3.8c-1,1-2.3,1.6-3.8,1.6s-2.8-0.6-3.8-1.6c-1-1-1.6-2.3-1.6-3.8S507.7,272.3,508.7,271.3L508.7,271.3z M514.1,288.5
	c1.4,0,2.4,0.5,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4c-0.9,0.9-2.1,1.4-3.4,1.4c-1.4,0-2.5-0.5-3.4-1.4
	c-0.9-0.9-1.4-2.1-1.4-3.4c0-1.4,0.5-2.4,1.4-3.4C511.6,289,512.7,288.5,514.1,288.5z"
          />
          <path
            fill="#EBEBEB"
            d="M512.5,269.8c-1.5,0-2.8,0.6-3.8,1.6c-1,1-1.6,2.3-1.6,3.8c0,1.5,0.6,2.8,1.6,3.8c1,1,2.3,1.6,3.8,1.6
	s2.8-0.6,3.8-1.6c1-1,1.6-2.3,1.6-3.8c0-1.5-0.6-2.8-1.6-3.8C515.3,270.2,514,269.8,512.5,269.8L512.5,269.8z M517.5,289.9
	c-0.9-0.9-2.1-1.4-3.4-1.4c-1.4,0-2.4,0.5-3.4,1.4c-0.9,0.9-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4c0.9,0.9,2.1,1.4,3.4,1.4
	c1.4,0,2.4-0.5,3.4-1.4c0.9-0.9,1.4-2.1,1.4-3.4C518.9,292,518.4,290.9,517.5,289.9z M531.7,250.1c-3.4,1.7-5,4.2-4.9,7.5l0.1,1.9
	c0.3,1.2,0.7,2.1,1,2.9l0.7,1.2c1.3,1.8,2.9,2.7,5,2.7c6.4,0.4,10.7-0.6,13.1-2.8c2.2-2.1,2.8-4.7,1.8-7.6l-0.1-0.4
	c-1-2.3-3.3-4.2-6.9-5.5C537.5,248.8,534.3,248.8,531.7,250.1L531.7,250.1z M424.3,211.6h-6.9c-8.9-0.1-17.1-0.1-24.6-0.1
	c-13.9,0-25.3,0.4-34.2,0.8l-10.4,39.4h145.1l-10.4-39.4c-9-0.5-20.3-0.7-34.2-0.8C441.5,211.4,433.3,211.5,424.3,211.6L424.3,211.6
	z M439.6,213.8c0.2,0.2,0.5,0.6,0.5,1.1c0,0.4-0.1,0.7-0.5,1c-0.2,0.2-0.6,0.5-1,0.5l-10.1,0.8l-8.5,0.1l-4.8-0.1l-11.3-0.8
	c-0.4,0-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1.1c0-0.4,0.1-0.7,0.5-1c0.2-0.2,0.6-0.5,1-0.5h34.9C439,213.5,439.4,213.6,439.6,213.8
	L439.6,213.8z M442.2,218.6l9.8-3.1h11.7c4.4,0,7.1,1.9,7.9,5.5l5.2,19.6c1,3.7,0.4,5.8-2.2,6.3h-40c-3.6-2.2-8-3.4-13.3-3.4
	c-5.4,0-9.8,1.2-13.4,3.4h-40c-2.6-0.5-3.3-2.6-2.2-6.3l5.1-19.6c0.8-3.6,3.5-5.5,7.9-5.5h11.7l9.8,3.1L442.2,218.6L442.2,218.6z"
          />
          <path
            fill="#EBEBEB"
            d="M440.1,214.9c0-0.4-0.1-0.7-0.5-1.1c-0.2-0.2-0.6-0.5-1-0.5h-34.9c-0.4,0-0.7,0.1-1,0.5
	c-0.2,0.2-0.5,0.6-0.5,1.1s0.1,0.7,0.5,1c0.2,0.2,0.6,0.5,1,0.5l11.3,0.8l4.8,0.1l8.5-0.1l10.1-0.8c0.4,0,0.7-0.1,1-0.5
	C439.9,215.6,440.1,215.2,440.1,214.9L440.1,214.9z"
          />
          <path
            fill="#EBEBEB"
            d="M452,215.5l-9.8,3.1h-42l-9.8-3.1h-11.7c-4.4,0-7.1,1.9-7.9,5.5l-5.1,19.6c-1,3.7-0.4,5.8,2.2,6.3h40
	c3.6-2.2,8-3.4,13.4-3.4c5.2,0.1,9.8,1.2,13.3,3.4h40c2.6-0.5,3.3-2.6,2.2-6.3l-5.1-19.6c-0.8-3.6-3.5-5.5-7.9-5.5L452,215.5
	L452,215.5z"
          />
          <path
            fill="#FFFFFF"
            d="M500.3,206.7c-1.4,0.4-1.9,1.3-1.2,2.8l2.4,4.7c5.2,10,10.4,23,15.4,39.1c1.2,0.5,2.3,1.2,3.4,1.9
	c-0.3-1-0.6-2-0.9-3l-0.6-1.6c-5.2-16.2-10.7-29.5-16.6-39.9L500.3,206.7L500.3,206.7z M341.6,206.7c-0.6,0.9-1.2,1.8-1.6,2.8
	l-0.6,1.1c-5.7,10.4-11.3,23.7-16.6,39.9l-1.4,4.8c1-0.7,2.2-1.4,3.4-1.9c5.1-16,10.3-29,15.4-39.1l2.6-4.7
	C343.3,208,343,207.1,341.6,206.7L341.6,206.7z"
          />
          <path
            fill="#EBEBEB"
            d="M315,257.5c0.2-3.3-1.4-5.7-4.9-7.5c-2.6-1.3-5.8-1.3-9.8,0c-3.6,1.3-5.9,3-6.9,5.5l-0.1,0.4
	c-1,2.9-0.5,5.5,1.8,7.6c2.3,2.3,6.6,3.3,13.1,2.8c2.1,0,3.7-0.9,5-2.7c0.2-0.5,0.5-0.8,0.7-1.2c0.5-0.8,0.8-1.9,1-2.9L315,257.5
	L315,257.5z M334,260.6c-3.8,0.1-7,2.3-9.7,6.8c-2.7,5.2-4.5,11.9-5.8,20.2c-0.4,5.1,0.2,8.6,1.8,10.6c1.3,1.2,3.3,2,5.8,2.4
	c3.4,0.4,6.1,0.1,8-0.6c2.6-1,3.6-4.2,3.3-9.3v-26.6c0.1-1.2-0.1-2.1-0.6-2.7C336.2,260.8,335.3,260.6,334,260.6L334,260.6z
	 M334.8,275.1c0,1.5-0.6,2.8-1.6,3.8c-1,1-2.3,1.6-3.8,1.6c-1.5,0-2.8-0.6-3.8-1.6c-1-1-1.6-2.3-1.6-3.8s0.6-2.8,1.6-3.8
	c1-1,2.3-1.6,3.8-1.6c1.5,0,2.8,0.6,3.8,1.6C334.2,272.3,334.8,273.6,334.8,275.1L334.8,275.1z M327.8,288.5c1.4,0,2.4,0.5,3.4,1.4
	s1.4,2.1,1.4,3.4c0,1.4-0.5,2.4-1.4,3.4s-2.1,1.4-3.4,1.4c-1.4,0-2.4-0.5-3.4-1.4s-1.4-2.1-1.4-3.4c0-1.4,0.5-2.4,1.4-3.4
	S326.4,288.5,327.8,288.5z"
          />
          <path
            fill="#EBEBEB"
            d="M333.2,279c1-1,1.6-2.3,1.6-3.8s-0.6-2.8-1.6-3.8c-1-1-2.3-1.6-3.8-1.6c-1.5,0-2.8,0.6-3.8,1.6
	c-1,1-1.6,2.3-1.6,3.8s0.6,2.8,1.6,3.8c1,1,2.3,1.6,3.8,1.6C330.8,280.5,332.1,280,333.2,279L333.2,279z M331.2,289.9
	c-0.9-0.9-2.1-1.4-3.4-1.4c-1.4,0-2.4,0.5-3.4,1.4s-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4s2.1,1.4,3.4,1.4c1.4,0,2.4-0.5,3.4-1.4
	s1.4-2.1,1.4-3.4C332.6,292,332.1,290.9,331.2,289.9z"
          />
          <path
            fill="none"
            d="M439.6,213.8c-0.2-0.2-0.6-0.5-1-0.5h-34.9c-0.4,0-0.7,0.1-1,0.5c-0.2,0.2-0.5,0.6-0.5,1.1c0,0.4,0.1,0.7,0.5,1
	c0.2,0.2,0.6,0.5,1,0.5l11.3,0.8l4.8,0.1l8.5-0.1l10.1-0.8c0.4,0,0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1.1
	C440.1,214.4,439.9,214.2,439.6,213.8L439.6,213.8z"
          />
          <path
            fill="none"
            d="M452,215.5h11.7c4.4,0,7.1,1.9,7.9,5.5l5.2,19.6c1,3.7,0.4,5.8-2.2,6.3h-40c-3.6-2.2-8-3.4-13.3-3.4
	c-5.4,0-9.8,1.2-13.4,3.4h-40c-2.6-0.5-3.3-2.6-2.2-6.3l5.1-19.6c0.8-3.6,3.5-5.5,7.9-5.5h11.7l9.8,3.1h42L452,215.5L452,215.5z
	 M403.7,271.1h10.4 M405.9,273.6h7.8 M404.7,272.3h9 M410.5,277.6h4.2 M408.7,276.2h5.4 M407.2,274.9h6.6 M426.7,275.4V272h-0.8v3.4
	H426.7z M435.9,274.9h-6.6 M434.4,276.2H429 M432.6,277.6h-4.3 M422.8,272l1.6,2.1V272h0.7v3.4h-0.7l-1.6-2v2h-0.7V272H422.8z
	 M420.6,272v3.4h0.8V272H420.6L420.6,272z M415.3,273.7c0,1.7,0.6,3.1,1.7,4.3c1.2,1.2,2.7,1.9,4.3,1.9c1.8,0,3.1-0.6,4.3-1.9
	c1.2-1.2,1.7-2.7,1.7-4.3c0-1.8-0.6-3.1-1.7-4.3s-2.7-1.8-4.3-1.8c-1.7,0-3.1,0.6-4.3,1.8C415.9,270.6,415.3,272,415.3,273.7
	L415.3,273.7z M417,272h-0.8v3.4h0.8v-1.9l0.8,1.9h0.2l0.9-1.9v1.9h0.8V272H419l-1,2.2L417,272L417,272z M437.1,273.6h-7.8
	 M439.4,271.1h-10.5 M438.3,272.3h-9 M426.3,269c-1.3-1.3-2.9-2-4.8-2c-1.9,0-3.5,0.7-4.8,2c-1.3,1.3-2,2.9-2,4.8s0.7,3.5,2,4.8
	c1.3,1.3,2.9,2,4.8,2c1.9,0,3.5-0.7,4.8-2c1.3-1.3,2-2.9,2-4.8S427.6,270.2,426.3,269L426.3,269z M316,259.5l0.1-2
	c0.2-3.6-1.6-6.4-5.5-8.3c-2.8-1.4-6.4-1.4-10.8,0c-4,1.4-6.5,3.4-7.7,6.1l-0.1,0.4c-1.2,3.3-0.5,6.1,2,8.4
	c2.6,2.6,7.5,3.6,14.5,3.1 M321.5,255.1c1-0.7,2.2-1.4,3.4-1.9 M321.5,255.1c-1,0.8-2.1,1.6-3,2.7c-0.9,0.9-1.6,2.1-2.5,3.3
	 M334.9,251.6h-2.2c-2.9,0-5.6,0.6-7.9,1.5 M348.3,251.6h-13.4 M324.9,253.2c5.1-16,10.3-29,15.4-39.1 M358.7,212.2l-10.4,39.4"
          />
          <path
            fill="none"
            d="M414.8,269.7h-12.1l1,1.4l1,1.3l1.2,1.3l1.3,1.3l1.5,1.3l1.7,1.4l2,1.4h3.3 M333.9,260.6
	c-3.8,0.1-7,2.3-9.7,6.8c-2.7,5.2-4.5,11.9-5.8,20.2c-0.4,5.1,0.2,8.6,1.8,10.6c1.3,1.2,3.3,2,5.8,2.4c3.4,0.4,6.1,0.1,8-0.6
	c2.6-1,3.6-4.2,3.3-9.3v-26.6c0.1-1.2-0.1-2.1-0.6-2.7C336.2,260.8,335.3,260.6,333.9,260.6L333.9,260.6z M338.4,259.8
	c1,0.9,1.5,2.4,1.3,4.4v26.2c0.5,6.6-1.2,10.6-4.9,11.7c-2.3,0.8-5.4,1-9.1,0.7c-3.3-0.5-5.7-1.5-7.3-3.1l-0.1-0.1
	c-1.9-2.2-2.7-6.3-2.2-12.2v-0.1c1.3-8.5,3.3-15.5,6.1-21V266c3.1-5.2,7-7.8,11.7-7.9C336,258.2,337.5,258.7,338.4,259.8
	L338.4,259.8z M334.8,275.1c0-1.5-0.6-2.8-1.6-3.8c-1-1-2.3-1.6-3.8-1.6c-1.5,0-2.8,0.6-3.8,1.6c-1,1-1.6,2.3-1.6,3.8
	s0.6,2.8,1.6,3.8c1,1,2.3,1.6,3.8,1.6c1.5,0,2.8-0.6,3.8-1.6C334.2,277.9,334.8,276.7,334.8,275.1L334.8,275.1z M314.9,262.8
	c0.4-0.6,0.8-1.2,1.2-1.8 M316,259.5c-0.4,1.3-0.7,2.3-1.2,3.3 M314.9,262.8c-0.2,0.5-0.6,0.9-0.8,1.4 M316,260.9v-1.5 M308.7,267.1
	h-0.2 M308.8,267.3l-0.1-0.2 M314,264.1c-0.7,0.9-1.4,1.6-2.2,2.1c-0.9,0.6-2,0.8-3,0.8 M308.7,267.1l-0.6-0.9 M308.5,267.1
	c0.2,0.4,0.5,0.7,0.8,1.2l0.6,1.4c0.2,1.6,0.4,3.1,0.2,4.7 M310.1,274.4c0.6-2.2,1.2-4.2,2-6.2c0.6-1.5,1.3-2.9,2-4.2 M310.1,275
	l-1.2,4.2 M308.8,279.2h-3.3 M310.1,274.4l-0.1,0.7 M310.1,275h-4.4c-0.5,0-0.8,0.1-1.2,0.5c-0.4,0.4-0.5,0.7-0.5,1.2v0.8
	c0,0.5,0.1,0.8,0.5,1.2c0.4,0.4,0.7,0.5,1,0.5 M305.5,279.2l-0.4,0.2c-0.4,0.4-0.5,0.7-0.5,1.2v0.5c0,0.5,0.1,0.8,0.5,1.2
	s0.7,0.5,1.2,0.5h1.4 M307.9,282.7c-0.6,2.2-0.9,4.3-1.2,6.5 M307.9,282.7l1-3.5 M327.8,288.5c-1.4,0-2.4,0.5-3.4,1.4
	s-1.4,2.1-1.4,3.4c0,1.4,0.5,2.4,1.4,3.4s2.1,1.4,3.4,1.4c1.4,0,2.4-0.5,3.4-1.4s1.4-2.1,1.4-3.4c0-1.4-0.5-2.4-1.4-3.4
	S329.1,288.5,327.8,288.5z M496.9,307.1c-0.5,3.3-2.2,5.7-5.2,7.6l-0.5,0.2c-2.8,1.4-6.9,2.1-12.1,2.1H362.9
	c-5.2,0-9.3-0.7-12.1-2.1l-0.5-0.2c-3-1.9-4.8-4.4-5.2-7.6 M344.9,307.1c-0.1-0.9-0.1-1.9-0.1-2.9V267c0.2-5.8,1.4-10.8,3.5-15.3
	 M344.9,307.1h-34.7 M310.2,307.1l-3.6-17.8 M346.6,328.8c-0.8,0-1.5,0.2-2.1,0.8c-0.6,0.6-0.8,1.3-0.8,2.1s0.2,1.5,0.8,2.1
	c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.4,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1s-0.4-1.5-0.9-2.1C348.1,329,347.4,328.8,346.6,328.8L346.6,328.8
	z M349.3,353.1c0,1.5,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7
	c-1-1-2.3-1.5-3.7-1.5s-2.7,0.5-3.7,1.5C349.7,350.4,349.3,351.7,349.3,353.1z M350.8,353.1c0,1,0.4,1.9,1,2.7c0.7,0.7,1.6,1,2.7,1
	s1.9-0.4,2.7-1c0.7-0.7,1-1.6,1-2.7s-0.4-1.9-1-2.7c-0.7-0.7-1.6-1-2.7-1s-1.9,0.4-2.7,1C351.1,351.3,350.8,352.1,350.8,353.1z
	 M506.9,363.5c-2.9,0.8-6.1,1.4-9.3,1.7v-9.4c-0.2-7.1-3.3-10.7-9-10.8c-5.8,0.1-9.3,2.5-10.5,7.2c-0.7,2.8-1.3,7-1.6,12.6
	l-19.5,0.8c-10.1,0.4-21.1,0.5-32.5,0.6l-14.6-0.1c-8.6-0.1-16.9-0.2-24.8-0.5l-19.5-0.8c-0.4-5.6-0.9-9.8-1.6-12.6
	c-1.2-4.7-4.7-7.1-10.5-7.2c-5.7,0.1-8.7,3.7-9,10.8v9.4c-3.3-0.5-6.4-1-9.3-1.7 M375.5,351.1c-1.7-0.2-3.3-0.9-4.8-2.1
	c-3.5-6.4-7.9-9.8-13.3-10.4c-6.5-2.1-12.4,0.8-17.3,8.6l-16-0.9c-4.5-0.4-7.3-4.2-8.3-11.7l-1-5.1 M318.1,329.5l-0.2-1.2
	c-0.1-0.6,0-1.2,0.5-1.5c0.4-0.5,0.9-0.7,1.6-0.8c0.7-0.1,1.3,0.1,1.9,0.5s0.8,0.8,0.9,1.4l0.2,1.1 M319.4,336.5l-0.8-4
	 M318.7,332.4l4.8-0.6 M323.5,331.9l0.7,4 M320.1,339.7l-0.7-3.3 M319.4,336.5l4.8-0.6 M324.2,335.9l0.6,3.3 M324.8,339.1l0.1,0.6
	c0.1,0.5,0,0.9-0.5,1.4c-0.4,0.4-0.9,0.7-1.5,0.8c-0.7,0.1-1.3,0-1.8-0.2c-0.6-0.2-0.8-0.7-0.9-1.2l-0.1-0.7 M320.1,339.7l4.7-0.6
	 M322.9,328.8l0.6,3 M309.6,329.6h5.1 M318.1,329.5l0.6,3 M322.9,328.8l-4.9,0.6 M335,363.5c-5.1-1.3-9.9-3.1-14.2-5.6l-1.2-0.4
	c-4.2-1.7-6.6-5.6-7.2-11.5l-2.8-16.4 M306.6,311.7v77.2c0.1,4.5,1.4,7.5,4,8.6c0.8,0.4,2,0.6,3.5,0.7c1.2,0.1,2.4,0.1,4,0h9.6
	c1.5-0.1,2.7-0.4,3.5-0.7c2.6-1.3,3.8-4.1,4-8.6v-9.1 M335,363.5v16.3 M335,379.8c3.1,0.5,6.9,1,11.5,1.4c7.9,0.2,13.6-0.6,17.3-2.4
	c2.4-1.3,4.1-3.3,5-6.1h6.8 M381.4,331.1l1.2,11.3c0.4,1.2,1,2.2,2.3,2.9c1.3,0.8,3.1,1.4,5.4,1.6h61.2c2.3-0.4,4.1-0.8,5.4-1.6
	c1.3-0.8,2-1.7,2.3-2.9l1.2-11.3c0.5-3.5-0.7-5.7-3.5-6.8c-1.2-0.5-2.7-0.7-4.4-0.7h-63.1c-1.8,0-3.3,0.2-4.4,0.7
	C382.1,325.4,381,327.6,381.4,331.1L381.4,331.1z M379.1,351.1c-1.3,0.2-2.4,0.2-3.6,0 M379.1,351.1h-3.6 M379.9,351l-0.9,0.1
	 M375.6,372.8l1.6,1.5c0.4,0.4,0.7,0.5,1.2,0.5l2.2-0.1c0.4-0.1,0.9-0.7,1.8-1.9 M382.2,372.8h-6.8 M399,372.8
	c1.9,1.4,5.4,2.3,10.7,2.8l6.8,0.5h9.2c8.9-0.4,14.6-1.4,17.1-3.3 M382.2,372.8H399 M306.6,311.8l3,17.8 M314.8,329.6l-4.5-22.5
	 M512.5,269.8c1.5,0,2.8,0.6,3.8,1.6c1,1,1.6,2.3,1.6,3.8c0,1.5-0.6,2.8-1.6,3.8s-2.3,1.6-3.8,1.6s-2.8-0.6-3.8-1.6
	c-1-1-1.6-2.3-1.6-3.8c0-1.5,0.6-2.8,1.6-3.8C509.8,270.2,511.1,269.8,512.5,269.8L512.5,269.8z M517.5,289.9
	c0.9,0.9,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4c-0.9,0.9-2.1,1.4-3.4,1.4c-1.4,0-2.5-0.5-3.4-1.4c-0.9-0.9-1.4-2.1-1.4-3.4
	c0-1.4,0.5-2.4,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4C515.4,288.5,516.5,289,517.5,289.9L517.5,289.9z M497,307.1
	c0.1-0.9,0.1-1.9,0.1-2.9V267c-0.2-5.8-1.4-10.8-3.5-15.3 M427.2,279l1-1.4l0.7-1.4l0.4-1.3l0.1-1.2l-0.1-1.3
	c-0.1-0.5-0.2-0.9-0.4-1.3c-0.2-0.5-0.4-1-0.7-1.4 M308.1,266.2c2.1,0,3.7-0.9,5-2.7c0.2-0.5,0.5-0.8,0.7-1.2c0.5-0.8,0.8-1.9,1-2.9
	l0.1-1.9c0.2-3.3-1.4-5.7-4.9-7.5c-2.6-1.3-5.8-1.3-9.8,0c-3.6,1.3-5.9,3-6.9,5.5l-0.1,0.4c-1,2.9-0.5,5.5,1.8,7.6
	C297.4,265.7,301.8,266.6,308.1,266.2L308.1,266.2z"
          />
          <path
            fill="none"
            d="M428.3,269.7c-0.4-0.5-0.7-1-1.2-1.5c-1.5-1.5-3.4-2.3-5.5-2.3c-2.2,0-4,0.8-5.6,2.3c-0.5,0.5-0.8,0.9-1.2,1.5
	 M427.2,279h3.3l2-1.4l1.9-1.4l1.5-1.3l1.3-1.3l1.2-1.3l1-1.3l1-1.4h-12.1 M348.9,213c-3.5,0.4-6.4,0.7-8.6,1.2 M358.7,212.2
	l-9.8,0.7 M348.9,213l-1.5,3.3l-2.4,2.2l-8.9,24.7l0.8,2.3l-2,6.2 M414.8,269.7l-0.7,1.4c-0.1,0.5-0.2,0.9-0.4,1.3
	c-0.1,0.5-0.1,0.8-0.1,1.3c0,0.4,0,0.8,0.1,1.2c0.1,0.5,0.1,0.9,0.4,1.3c0.1,0.5,0.4,0.9,0.7,1.4c0.2,0.5,0.6,0.9,1,1.4
	 M340.3,214.2l2.6-4.7c0.6-1.5,0.2-2.6-1.2-2.9 M341.6,206.7c-0.6,0.9-1.2,1.8-1.6,2.8l-0.6,1.1c-5.7,10.4-11.3,23.7-16.6,39.9
	l-1.4,4.8 M353.6,196.8h-0.2c-4.2,0.9-8.2,4.2-11.8,9.9 M360.7,196.7c0-0.7-0.2-1.3-0.6-1.9c-0.4-0.5-1.3-0.8-2.9-0.9
	c-1.5,0-2.4,0.2-2.8,1.1c-0.4,0.6-0.6,1.2-0.8,1.9 M415.7,279l0.2,0.2c1.5,1.5,3.4,2.3,5.6,2.3c2.1,0,4-0.8,5.5-2.3l0.2-0.2"
          />
          <path
            fill="none"
            d="M360.7,196.7l-0.6,3.4c-0.1,0.4-0.4,0.6-0.8,0.8s-0.9,0.4-1.4,0.4h-1.2c-0.5,0-0.8-0.1-1.2-0.5
	s-0.6-0.6-0.7-0.9l-1.3-3.1 M493,213c3.5,0.4,6.4,0.7,8.6,1.2 M493.5,251.6l-10.4-39.4 M501.6,214.2c5.2,10,10.4,23,15.4,39.1
	 M466.4,351.1c-1.2,0.2-2.3,0.2-3.6,0 M466.4,351.1h-3.6 M462.8,351.1l-0.9-0.1 M459.5,372.8c0.8,1.2,1.4,1.7,1.8,1.9l2.2,0.1
	c0.5,0,0.8-0.1,1.2-0.5l1.6-1.5 M459.5,372.8h6.8 M442.7,372.8h16.8 M518.4,331.9l-0.7,4 M517.7,335.9l-0.6,3.3 M518.9,328.8
	l-0.5,3.1 M497.4,329.6c0.6,0.6,0.8,1.3,0.8,2.1s-0.2,1.5-0.8,2.1c-0.6,0.6-1.3,0.9-2.1,0.9s-1.5-0.4-2.1-0.9
	c-0.6-0.6-0.9-1.3-0.9-2.1s0.4-1.5,0.9-2.1c0.6-0.6,1.3-0.8,2.1-0.8S496.8,329,497.4,329.6L497.4,329.6z M483.5,349.4
	c1-1,2.3-1.5,3.7-1.5c1.5,0,2.7,0.5,3.7,1.5s1.5,2.3,1.5,3.7c0,1.5-0.5,2.7-1.5,3.7s-2.3,1.5-3.7,1.5c-1.5,0-2.7-0.5-3.7-1.5
	s-1.5-2.3-1.5-3.7S482.5,350.4,483.5,349.4z"
          />
          <path
            fill="none"
            d="M483.5,353.1c0-1,0.4-1.9,1-2.7c0.7-0.7,1.6-1,2.7-1s1.9,0.4,2.7,1c0.7,0.7,1,1.6,1,2.7s-0.4,1.9-1,2.7
	c-0.7,0.7-1.6,1-2.7,1s-1.9-0.4-2.7-1C484,355.1,483.5,354.2,483.5,353.1L483.5,353.1z M466.3,372.8h6.8c0.9,2.8,2.7,4.8,5,6.1
	c3.6,1.9,9.4,2.7,17.3,2.4c4.5-0.5,8.4-0.9,11.5-1.4 M506.9,379.8v-16.3 M399,372.8h43.7 M462.8,351.1h-83.7 M348.3,251.6h145.1
	 M493.5,251.6H507 M507,251.6h2.2c2.9,0,5.6,0.6,7.9,1.5 M493,213l-9.9-0.8 M488.3,196.8c-0.2-0.7-0.5-1.3-0.8-1.9
	c-0.4-0.7-1.3-1.1-2.8-1.1c-1.6,0.1-2.6,0.5-2.9,0.9c-0.4,0.6-0.6,1.3-0.6,1.9"
          />
          <path
            fill="none"
            d="M500.2,206.7c-3.6-5.7-7.6-9-11.8-9.9h-0.2 M481.1,196.7c0.4,2,0.5,3.1,0.6,3.4c0.1,0.4,0.4,0.6,0.8,0.8
	c0.5,0.2,0.9,0.4,1.4,0.4h1.2c0.5,0,0.8-0.1,1.2-0.5s0.6-0.6,0.7-0.9l1.3-3.1 M521.8,339.7l-4.7-0.6 M522.5,336.5l-4.8-0.6
	 M518.9,328.8l4.9,0.7 M518.4,331.9l4.8,0.5 M531.6,307.1H497 M517,253.2c1.2,0.5,2.3,1.2,3.4,1.9 M507,251.6l-2-6.1l0.8-2.3
	l-8.9-24.8l-2.5-2.1L493,213 M500.2,206.7c-1.4,0.4-1.7,1.3-1.2,2.9l2.5,4.7"
          />
          <path
            fill="none"
            d="M520.4,255.1c-0.3-1-0.6-2-0.9-3l-0.6-1.6c-5.2-16.2-10.7-29.5-16.6-39.9l-2.1-3.7 M358.7,212.2
	c0.7-3.8,3.3-6.2,7.6-7.1c4.8-1.6,13.6-2.7,26.6-3.4l28-0.8l28.2,0.8c12.9,0.7,21.8,1.9,26.6,3.4c4.3,0.9,6.9,3.3,7.6,7.1
	 M481.1,196.7c-0.6,0.2-0.8,0.6-0.9,1.1c-12.9-2.2-30.8-3.4-53.4-3.5h-2.6"
          />
          <path
            fill="none"
            d="M483.1,212.2c-9-0.5-20.3-0.7-34.2-0.8c-7.5,0-15.7,0-24.6,0.1h-6.9c-8.9-0.1-17.1-0.1-24.6-0.1
	c-13.9,0-25.3,0.4-34.2,0.8 M420,192.1c-1.2,0.2-1.9,1.1-2.2,2.2 M417.8,194.2h-1.6c-23.2,0.1-41.4,1.3-54.6,3.5
	c-0.1-0.5-0.5-0.8-0.9-1.1 M424.2,194.2c-0.4-1.1-0.9-1.8-2-2.2"
          />
          <path
            fill="none"
            d="M417.8,194.2v1.6c0.1,0.5,0.4,0.7,0.9,0.7h4.5c0.5,0,0.8-0.2,0.9-0.7v-1.6"
          />
          <path
            fill="none"
            d="M422.2,192.1l0.1,1.3c0,0.9-0.4,1.3-1,1.3c-0.8,0-1.2-0.4-1.2-1.3v-1.3"
          />
          <path
            fill="none"
            d="M422.2,192.1l-0.5-5.8c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.5-0.2s-0.4,0.1-0.5,0.2s-0.2,0.2-0.2,0.5
	l-0.4,5.8 M531.6,307.1l-4.4,22.5 M535.3,311.7l-3.1,17.8 M535.3,311.7v77.2c-0.1,4.5-1.4,7.5-4,8.6c-0.8,0.4-2,0.6-3.5,0.7
	c-1.2,0.1-2.4,0.1-4,0h-9.6c-1.5-0.1-2.7-0.4-3.5-0.7c-2.6-1.3-3.8-4.1-4-8.6v-9.1"
          />
          <path
            fill="none"
            d="M506.9,363.5c5.1-1.3,9.9-3.1,14.2-5.6l1.2-0.4c4.2-1.7,6.6-5.6,7.2-11.5l2.8-16.4 M517.1,339.1l-0.1,0.6
	c-0.1,0.5,0,0.9,0.5,1.4c0.3,0.4,0.9,0.7,1.5,0.8c0.7,0.1,1.3,0,1.8-0.2c0.6-0.2,0.8-0.7,0.9-1.2l0.1-0.7"
          />
          <path
            fill="none"
            d="M527.1,329.6l-1,5.1c-1,7.3-3.7,11.3-8.3,11.7l-16,0.9c-4.9-7.8-10.7-10.6-17.3-8.6c-5.4,0.6-9.8,4-13.3,10.4
	c-1.5,1.2-3.1,1.9-4.8,2.1 M523.2,332.4l0.6-2.9 M521.8,339.7l0.7-3.3 M306.7,289.1c-1.5,3.3-2,7.8-1.6,13.6v0.1l1.6,8.9
	 M522.5,336.5l0.7-4.1 M523.8,329.5l0.2-1.2c0.1-0.6,0-1.2-0.5-1.5c-0.3-0.5-0.9-0.7-1.6-0.8c-0.7-0.1-1.3,0.1-1.9,0.5
	c-0.6,0.4-0.8,0.8-0.9,1.4l-0.2,1.1 M532.2,329.6h-5 M535.2,289.1l-3.6,18 M521.7,298.1c-1.3,1.2-3.3,2-5.8,2.4
	c-3.4,0.4-6.1,0.1-8-0.6c-2.6-1-3.6-4.2-3.3-9.3v-26.6c-0.1-1.2,0.1-2.1,0.6-2.7c0.6-0.6,1.5-0.8,2.8-0.7c3.8,0.1,7,2.3,9.7,6.8
	c2.7,5.2,4.5,11.9,5.8,20.2C523.8,292.6,523.2,296.1,521.7,298.1L521.7,298.1z M535.2,289.1c1.5,3.3,2,7.8,1.6,13.6v0.1l-1.6,8.9
	 M533.1,267.1l0.6-0.9 M533,279.2l1,3.5 M533,279.2h3.3"
          />
          <path
            fill="none"
            d="M534,282.7c0.6,2.2,0.9,4.3,1.2,6.5 M536.2,279.2l0.3,0.2c0.3,0.4,0.5,0.7,0.5,1.2v0.5c0,0.5-0.1,0.8-0.5,1.2
	s-0.7,0.5-1.2,0.5H534 M531.8,275h4.4c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2v0.8c0,0.5-0.1,0.8-0.5,1.2s-0.7,0.5-1,0.5
	 M525.7,287.1c-1.3-8.5-3.3-15.5-6.1-21V266c-3.1-5.2-7-7.8-11.7-7.9c-2.1-0.1-3.6,0.5-4.5,1.4c-1,0.9-1.5,2.4-1.3,4.4v26.2
	c-0.5,6.6,1.2,10.6,4.9,11.7c2.3,0.8,5.4,1,9.1,0.7c3.3-0.5,5.7-1.5,7.2-3.1l0.1-0.1C525.4,297.4,526.1,293.2,525.7,287.1
	L525.7,287.1z M531.8,275l1.2,4.2 M531.7,274.4l0.1,0.7 M527,262.8c-0.5-0.9-0.9-2-1.2-3.3 M527.8,264.1c0.7,1.3,1.4,2.7,2,4.2
	c0.7,1.9,1.4,4,2,6.2 M527,262.8l-1.2-1.8 M533.3,267.1h-0.2 M533.1,267.1c-1.2,0-2.2-0.4-3-0.8c-0.8-0.5-1.6-1.2-2.2-2.1
	 M533.1,267.3v-0.2"
          />
          <path
            fill="none"
            d="M533.3,267.1c-0.2,0.4-0.5,0.7-0.8,1.2c-0.2,0.5-0.5,0.8-0.6,1.4c-0.2,1.6-0.3,3.1-0.2,4.7 M525.9,260.9v-1.4
	 M527.8,264.1l-0.8-1.3 M533.7,266.2c6.4,0.4,10.7-0.6,13.1-2.8c2.2-2.1,2.8-4.7,1.8-7.6l-0.1-0.4c-1-2.3-3.3-4.2-6.9-5.5
	c-4-1.3-7.2-1.3-9.8,0c-3.4,1.8-5,4.2-4.9,7.5l0.1,1.9c0.3,1.2,0.7,2.1,1,2.9l0.7,1.2C529.9,265.4,531.7,266.2,533.7,266.2
	L533.7,266.2z"
          />
          <path
            fill="none"
            d="M525.9,259.5l-0.1-2c-0.2-3.6,1.6-6.4,5.5-8.3c2.8-1.4,6.4-1.4,10.8,0c4,1.4,6.5,3.4,7.7,6.1l0.1,0.4
	c1.2,3.3,0.5,6.1-2,8.4c-2.6,2.6-7.5,3.6-14.5,3.1 M520.4,255.1c1,0.8,2.1,1.6,3,2.7c0.9,0.9,1.6,2.1,2.4,3.3"
          />
          <path
            fill="none"
            d="M523.4,257.8c-0.9-1-2-1.9-3-2.7c-1-0.7-2.2-1.4-3.4-1.9c-2.3-0.9-5-1.5-7.9-1.5h-15.6
	c2.1,4.4,3.3,9.6,3.5,15.3v37.2c0.1,1,0.1,2-0.1,2.9h34.7l3.6-17.8c-0.1-2.1-0.6-4.2-1.2-6.4l-1-3.5l-1.2-4.2l-0.1-0.7
	c-0.6-2.2-1.2-4.2-2-6.2c-0.6-1.5-1.3-2.9-2-4.2l-0.8-1.4l-1.2-1.7C525,259.8,524.2,258.7,523.4,257.8L523.4,257.8z M525.7,287.1
	c0.3,6.1-0.3,10.1-2.2,12.4l-0.1,0.1c-1.5,1.6-4,2.7-7.2,3.1c-3.7,0.4-6.8,0.1-9.1-0.7c-3.6-1.2-5.2-5-4.9-11.8v-26.2
	c-0.1-2,0.2-3.5,1.3-4.4c0.9-1,2.5-1.5,4.5-1.4c4.5,0,8.5,2.7,11.7,7.9v0.1C522.5,271.6,524.5,278.6,525.7,287.1L525.7,287.1z"
          />
          <path
            fill="none"
            d="M525.7,287.3c-1.3-8.6-3.3-15.6-6.1-21.1V266c-3.1-5.2-7-7.8-11.7-7.9c-2.1-0.1-3.6,0.5-4.5,1.4
	c-1,0.9-1.5,2.4-1.3,4.4v26.2c-0.5,6.6,1.2,10.6,4.9,11.7c2.3,0.8,5.4,1,9.1,0.7c3.3-0.5,5.7-1.5,7.2-3.1l0.1-0.1
	C525.4,297.4,526.1,293.2,525.7,287.3L525.7,287.3z M523.4,287.5c0.3,5.1-0.2,8.6-1.7,10.6c-1.3,1.2-3.3,2-5.8,2.4
	c-3.4,0.4-6.1,0.1-8-0.6c-2.6-1-3.6-4.2-3.3-9.3v-26.6c-0.1-1.2,0.1-2.1,0.6-2.7c0.6-0.6,1.5-0.8,2.8-0.7c3.8,0.1,7,2.3,9.7,6.8
	C520.3,272.6,522.2,279.3,523.4,287.5L523.4,287.5z"
          />
          <path
            fill="none"
            d="M521.7,298.1c1.4-2,2-5.5,1.7-10.6c-1.2-8.2-3.1-14.9-5.8-20.2c-2.7-4.4-5.8-6.6-9.7-6.8
	c-1.3,0-2.2,0.2-2.8,0.7c-0.5,0.6-0.7,1.5-0.6,2.7v26.7c-0.4,5.2,0.8,8.4,3.3,9.3c2.1,0.7,4.8,0.9,8,0.6
	C518.4,300.1,520.4,299.3,521.7,298.1L521.7,298.1z M508.7,271.3c1-1,2.3-1.6,3.8-1.6c1.5,0,2.8,0.6,3.8,1.6c1,1,1.6,2.3,1.6,3.8
	s-0.6,2.8-1.6,3.8c-1,1-2.3,1.6-3.8,1.6s-2.8-0.6-3.8-1.6c-1-1-1.6-2.3-1.6-3.8S507.7,272.3,508.7,271.3L508.7,271.3z M514.1,288.5
	c1.4,0,2.4,0.5,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4c-0.9,0.9-2.1,1.4-3.4,1.4c-1.4,0-2.5-0.5-3.4-1.4
	c-0.9-0.9-1.4-2.1-1.4-3.4c0-1.4,0.5-2.4,1.4-3.4C511.6,289,512.7,288.5,514.1,288.5z"
          />
          <path
            fill="none"
            d="M512.5,269.8c-1.5,0-2.8,0.6-3.8,1.6c-1,1-1.6,2.3-1.6,3.8c0,1.5,0.6,2.8,1.6,3.8c1,1,2.3,1.6,3.8,1.6
	s2.8-0.6,3.8-1.6c1-1,1.6-2.3,1.6-3.8c0-1.5-0.6-2.8-1.6-3.8C515.3,270.2,514,269.8,512.5,269.8L512.5,269.8z M517.5,289.9
	c-0.9-0.9-2.1-1.4-3.4-1.4c-1.4,0-2.4,0.5-3.4,1.4c-0.9,0.9-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4c0.9,0.9,2.1,1.4,3.4,1.4
	c1.4,0,2.4-0.5,3.4-1.4c0.9-0.9,1.4-2.1,1.4-3.4C518.9,292,518.4,290.9,517.5,289.9z M531.1,249.1c-3.8,1.9-5.6,4.7-5.5,8.3l0.1,2
	c0.3,1.3,0.7,2.3,1.2,3.3l0.8,1.4c0.7,0.9,1.4,1.6,2.2,2.1c0.9,0.6,2,0.8,3,0.8l0.6-0.9c-2.1,0-3.7-0.9-5-2.7l-0.7-1.2
	c-0.5-0.8-0.8-1.9-1-2.9l-0.1-1.9c-0.2-3.3,1.4-5.7,4.9-7.5c2.5-1.3,5.8-1.3,9.8,0c3.6,1.3,5.9,3,6.9,5.5l0.1,0.4
	c1,2.9,0.5,5.5-1.8,7.6c-2.3,2.3-6.6,3.3-13.1,2.8l-0.6,0.9h0.2c7.1,0.5,11.9-0.6,14.5-3.1c2.4-2.3,3-5.1,2-8.4l-0.1-0.4
	c-1.2-2.6-3.6-4.7-7.7-6.1C537.5,247.8,533.9,247.8,531.1,249.1L531.1,249.1z"
          />
          <path
            fill="none"
            d="M531.7,250.1c-3.4,1.7-5,4.2-4.9,7.5l0.1,1.9c0.3,1.2,0.7,2.1,1,2.9l0.7,1.2c1.3,1.8,2.9,2.7,5,2.7
	c6.4,0.4,10.7-0.6,13.1-2.8c2.2-2.1,2.8-4.7,1.8-7.6l-0.1-0.4c-1-2.3-3.3-4.2-6.9-5.5C537.5,248.8,534.3,248.8,531.7,250.1
	L531.7,250.1z M525.9,259.5v1.5l1.2,1.8C526.6,261.7,526.2,260.7,525.9,259.5L525.9,259.5z"
          />
          <path
            fill="none"
            d="M532.6,268.3c0.2-0.5,0.5-0.8,0.8-1.2h-0.2l-0.1,0.2l0.1-0.2c-1.2,0-2.2-0.4-3-0.8s-1.6-1.2-2.2-2.1
	c0.7,1.3,1.4,2.7,2,4.2c0.7,1.9,1.4,4,2,6.2c-0.1-1.5,0-3.1,0.2-4.7C532.2,269.1,532.4,268.7,532.6,268.3L532.6,268.3z M531.8,275
	l1.2,4.2h3.3c0.5,0,0.8-0.2,1-0.5c0.3-0.4,0.5-0.7,0.5-1.2v-0.8c0-0.5-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5h-4.3L531.8,275L531.8,275z
	 M536.6,279.5l-0.3-0.2H533l1,3.5h1.4c0.5,0,0.8-0.1,1.2-0.5s0.5-0.7,0.5-1.2v-0.5C537.1,280.2,536.9,279.8,536.6,279.5L536.6,279.5
	z M536.8,302.9c0.3-5.9-0.2-10.5-1.6-13.8l-3.6,17.8l-4.5,22.5h5.1l3-17.8L536.8,302.9L536.8,302.9z M532.2,329.6H527l-1,5.1
	c-1,7.3-3.7,11.3-8.3,11.7l-16,0.9c-4.9-7.8-10.7-10.6-17.3-8.6c-5.4,0.6-9.8,4-13.3,10.4c-1.5,1.2-3.1,1.9-4.8,2.1
	c-1.2,0.2-2.3,0.2-3.6,0h-83.7c-1.3,0.2-2.4,0.2-3.6,0c-1.8-0.2-3.3-0.9-4.8-2.1c-3.5-6.4-7.9-9.8-13.3-10.4
	c-6.5-2.1-12.4,0.8-17.3,8.6l-16-0.9c-4.5-0.4-7.3-4.2-8.3-11.7l-1-5.1h-5.1l2.8,16.4c0.6,5.9,2.9,9.8,7.2,11.5l1.2,0.4
	c4.3,2.5,9.1,4.3,14.2,5.6c2.9,0.8,6.1,1.4,9.3,1.7v-9.4c0.2-7.1,3.3-10.7,9-10.8c5.8,0.1,9.3,2.4,10.5,7.2c0.7,2.8,1.3,7,1.6,12.6
	l19.5,0.8c7.8,0.2,16.1,0.4,24.8,0.5l14.6,0.1c11.5,0,22.4-0.2,32.5-0.6l19.5-0.8c0.4-5.6,0.9-9.8,1.6-12.6
	c1.2-4.7,4.7-7.1,10.5-7.2c5.7,0.1,8.7,3.7,9,10.8v9.4c3.3-0.5,6.4-1,9.3-1.7c5.1-1.3,9.9-3.1,14.2-5.6l1.2-0.4
	c4.2-1.7,6.6-5.6,7.2-11.5L532.2,329.6L532.2,329.6z"
          />
          <path
            fill="none"
            d="M526.1,334.7l1-5.1l4.5-22.5h-34.7c-0.5,3.3-2.2,5.7-5.2,7.6l-0.5,0.2c-2.8,1.4-6.9,2.1-12.1,2.1H362.9
	c-5.2,0-9.3-0.7-12.1-2.1l-0.5-0.2c-3-1.9-4.8-4.4-5.2-7.6h-34.7l4.5,22.5l1,5.1c1,7.3,3.7,11.3,8.3,11.7l16,0.9
	c4.9-7.8,10.7-10.6,17.3-8.6c5.4,0.6,9.8,4,13.3,10.4c1.5,1.2,3.1,1.9,4.8,2.1h3.6l0.9-0.1l-0.9,0.1h83.7l-0.9-0.1l0.9,0.1h3.6
	c1.8-0.2,3.3-0.9,4.8-2.1c3.5-6.4,7.9-9.8,13.3-10.4c6.5-2.1,12.4,0.8,17.3,8.6l16-0.9C522.2,346,525,342.2,526.1,334.7L526.1,334.7
	z M524,328.3l-0.2,1.2l-0.6,3l-0.8,4l-0.7,3.3l-0.1,0.7c-0.1,0.5-0.5,0.9-0.9,1.2c-0.5,0.2-1,0.4-1.7,0.2c-0.6-0.1-1-0.4-1.5-0.8
	c-0.3-0.5-0.5-0.9-0.5-1.4l0.1-0.6l0.6-3.3l0.7-4l0.6-3l0.2-1c0.1-0.6,0.5-1,0.9-1.4c0.6-0.4,1.2-0.6,1.9-0.5
	c0.7,0.1,1.3,0.4,1.6,0.8C524,327.1,524.1,327.7,524,328.3L524,328.3z M346.6,328.8c0.8,0,1.5,0.2,2.1,0.8c0.6,0.6,0.9,1.3,0.9,2.1
	s-0.4,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.1,0.9s-1.5-0.4-2.1-0.9c-0.6-0.6-0.8-1.3-0.8-2.1s0.2-1.5,0.8-2.1
	C344.9,329,345.8,328.8,346.6,328.8L346.6,328.8z M320,326c0.7-0.1,1.3,0.1,1.9,0.5s0.8,0.8,0.9,1.4l0.2,1.1l0.6,3l0.7,4l0.6,3.3
	l0.1,0.6c0.1,0.5,0,0.9-0.5,1.4c-0.4,0.4-0.9,0.7-1.5,0.8c-0.7,0.1-1.3,0-1.8-0.2c-0.6-0.2-0.8-0.7-0.9-1.2l-0.1-0.7l-0.7-3.3
	l-0.8-4l-0.6-3l-0.2-1.2c-0.1-0.6,0-1.2,0.5-1.5C318.7,326.2,319.2,326,320,326z M381.4,331.1c-0.5-3.5,0.7-5.7,3.5-6.8
	c1.2-0.5,2.7-0.7,4.4-0.7h63.1c1.7,0,3.3,0.2,4.4,0.7c2.8,1,4,3.3,3.5,6.8l-1.2,11.3c-0.4,1.2-1,2.2-2.3,2.9
	c-1.3,0.8-3.1,1.4-5.4,1.6h-61.2c-2.3-0.4-4.1-0.8-5.4-1.6c-1.3-0.8-2-1.7-2.3-2.9L381.4,331.1L381.4,331.1z M495.3,328.8
	c0.8,0,1.5,0.2,2.1,0.8c0.6,0.6,0.8,1.3,0.8,2.1s-0.2,1.5-0.8,2.1c-0.6,0.6-1.3,0.9-2.1,0.9s-1.5-0.4-2.1-0.9
	c-0.6-0.6-0.9-1.3-0.9-2.1s0.4-1.5,0.9-2.1C493.8,329,494.5,328.8,495.3,328.8L495.3,328.8z"
          />
          <path
            fill="none"
            d="M523.8,329.5l0.2-1.2c0.1-0.6,0-1.2-0.5-1.5c-0.3-0.5-0.9-0.7-1.6-0.8c-0.7-0.1-1.3,0.1-1.9,0.5
	c-0.6,0.4-0.8,0.8-0.9,1.4l-0.2,1.1L523.8,329.5L523.8,329.5z M522.5,336.5l0.7-4.1l-4.8-0.5l-0.7,4"
          />
          <path
            fill="none"
            d="M521.8,339.7l0.7-3.3l-4.8-0.6l-0.6,3.3 M523.2,332.4l0.6-2.9l-4.9-0.7l-0.5,3.1"
          />
          <path
            fill="none"
            d="M520.7,341.6c0.6-0.2,0.8-0.7,0.9-1.2l0.1-0.7l-4.7-0.6l-0.1,0.6c-0.1,0.5,0,0.9,0.5,1.4
	c0.3,0.4,0.9,0.7,1.5,0.8C519.6,341.9,520.1,341.9,520.7,341.6L520.7,341.6z M348.7,329.6c-0.6-0.6-1.3-0.8-2.1-0.8
	s-1.5,0.2-2.1,0.8c-0.6,0.6-0.8,1.3-0.8,2.1s0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.4,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	C349.5,330.9,349.3,330.2,348.7,329.6z M321.7,326.3c-0.6-0.4-1.2-0.6-1.9-0.5c-0.7,0.1-1.3,0.4-1.6,0.8c-0.5,0.5-0.6,0.9-0.5,1.5
	l0.2,1.2l4.9-0.6l-0.2-1C322.7,327.1,322.3,326.7,321.7,326.3L321.7,326.3z M318.7,332.4l0.7,4.1l4.8-0.6l-0.7-4"
          />
          <path
            fill="none"
            d="M318.7,332.4l4.8-0.5l-0.6-3.1l-4.8,0.7 M319.4,336.5l0.7,3.3l4.7-0.6l-0.6-3.3"
          />
          <path
            fill="none"
            d="M324.8,339.6l-0.1-0.6l-4.7,0.6l0.1,0.7c0.1,0.5,0.5,0.9,0.9,1.2c0.5,0.2,1,0.4,1.8,0.2c0.6-0.1,1-0.4,1.5-0.8
	C324.8,340.7,324.9,340.2,324.8,339.6L324.8,339.6z M384.9,324.3c-2.8,1-4,3.3-3.5,6.8l1.2,11.3c0.4,1.2,1,2.2,2.3,2.9
	c1.3,0.8,3.1,1.4,5.4,1.6h61.2c2.3-0.4,4.1-0.8,5.4-1.6c1.3-0.8,2-1.7,2.3-2.9l1.2-11.3c0.5-3.5-0.7-5.7-3.5-6.8
	c-1.2-0.5-2.7-0.7-4.4-0.7h-63.1C387.6,323.6,386.1,323.9,384.9,324.3L384.9,324.3z M497.4,329.6c-0.6-0.6-1.3-0.8-2.1-0.8
	s-1.5,0.2-2.1,0.8c-0.6,0.6-0.9,1.3-0.9,2.1s0.4,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.4,2.1-0.9c0.6-0.6,0.8-1.3,0.8-2.1
	C498.3,330.9,498,330.2,497.4,329.6L497.4,329.6z M532.2,329.6l-2.8,16.4c-0.6,5.9-2.9,9.8-7.2,11.5l-1.2,0.4
	c-4.3,2.5-9.1,4.3-14.2,5.6v25.4c0.1,4.5,1.4,7.5,4,8.6c0.8,0.4,2,0.6,3.5,0.7h9.6c1.5,0.1,2.8,0.1,4,0c1.5-0.1,2.7-0.4,3.5-0.7
	c2.6-1.3,3.8-4.1,4-8.6v-77.2L532.2,329.6L532.2,329.6z M421.6,186.3c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.5-0.2
	s-0.4,0.1-0.5,0.2s-0.2,0.2-0.2,0.5l-0.4,5.8v1.3c0,0.9,0.5,1.3,1.2,1.3s1.2-0.4,1-1.3l-0.1-1.3L421.6,186.3L421.6,186.3z"
          />
          <path
            fill="none"
            d="M422.2,192.1l0.1,1.3c0,0.9-0.4,1.3-1,1.3c-0.8,0-1.2-0.4-1.2-1.3v-1.3c-1.2,0.2-1.9,1.1-2.2,2.2v1.6
	c0.1,0.5,0.4,0.7,0.9,0.7h4.5c0.5,0,0.8-0.2,0.9-0.7v-1.6C423.9,193.2,423.3,192.5,422.2,192.1L422.2,192.1z"
          />
          <path
            fill="none"
            d="M424.2,196c-0.1,0.5-0.4,0.7-0.9,0.7h-4.5c-0.5,0-0.8-0.2-0.9-0.7v-1.6h-1.6c-23.2,0.1-41.4,1.3-54.6,3.5
	c-0.1-0.5-0.5-0.8-0.9-1.1l-0.6,3.4c-0.1,0.4-0.4,0.6-0.8,0.8c-0.5,0.2-0.9,0.4-1.4,0.4h-1.2c-0.5,0-0.8-0.1-1.2-0.5
	s-0.6-0.6-0.7-0.9l-1.3-3.1h-0.2c-4.2,0.9-8.2,4.2-11.8,9.9c1.4,0.4,1.8,1.3,1.2,2.9l-2.6,4.7c2.2-0.5,5.1-0.8,8.6-1.2l9.8-0.7
	c0.7-3.8,3.3-6.2,7.6-7.1c4.8-1.6,13.6-2.7,26.6-3.4l28.1-1.1l28.2,0.8c12.9,0.7,21.8,1.9,26.6,3.4c4.3,0.9,6.9,3.3,7.6,7.1l9.8,0.7
	c3.5,0.4,6.4,0.7,8.6,1.2l-2.6-4.7c-0.6-1.5-0.2-2.6,1.2-2.9c-3.6-5.7-7.6-9-11.8-9.9h-0.2l-1.3,3.1c-0.1,0.2-0.4,0.6-0.7,0.9
	s-0.7,0.5-1.2,0.5h-1.2c-0.5,0-0.9-0.1-1.4-0.4s-0.7-0.5-0.8-0.8s-0.4-1.5-0.6-3.4c-0.6,0.2-0.8,0.6-0.9,1.1
	c-12.9-2.2-30.8-3.4-53.4-3.5h-2.6L424.2,196L424.2,196z"
          />
          <path
            fill="none"
            d="M417.4,211.6h6.9c8.9-0.1,17.1-0.1,24.6-0.1c13.9,0,25.3,0.4,34.2,0.8c-0.7-3.8-3.3-6.2-7.6-7.1
	c-4.8-1.6-13.6-2.7-26.6-3.4l-28.1-0.9l-28,0.8c-12.9,0.7-21.8,1.9-26.6,3.4c-4.3,0.9-6.9,3.3-7.6,7.1c9-0.5,20.3-0.7,34.2-0.8
	C400.4,211.4,408.6,211.5,417.4,211.6L417.4,211.6z"
          />
          <path
            fill="none"
            d="M424.3,211.6h-6.9c-8.9-0.1-17.1-0.1-24.6-0.1c-13.9,0-25.3,0.4-34.2,0.8l-10.4,39.4h145.1l-10.4-39.4
	c-9-0.5-20.3-0.7-34.2-0.8C441.5,211.4,433.3,211.5,424.3,211.6L424.3,211.6z M439.6,213.8c0.2,0.2,0.5,0.6,0.5,1.1
	c0,0.4-0.1,0.7-0.5,1c-0.2,0.2-0.6,0.5-1,0.5l-10.1,0.8l-8.5,0.1l-4.8-0.1l-11.3-0.8c-0.4,0-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1.1
	c0-0.4,0.1-0.7,0.5-1c0.2-0.2,0.6-0.5,1-0.5h34.9C439,213.5,439.4,213.6,439.6,213.8L439.6,213.8z M442.2,218.6l9.8-3.1h11.7
	c4.4,0,7.1,1.9,7.9,5.5l5.2,19.6c1,3.7,0.4,5.8-2.2,6.3h-40c-3.6-2.2-8-3.4-13.3-3.4c-5.4,0-9.8,1.2-13.4,3.4h-40
	c-2.6-0.5-3.3-2.6-2.2-6.3l5.1-19.6c0.8-3.6,3.5-5.5,7.9-5.5h11.7l9.8,3.1L442.2,218.6L442.2,218.6z"
          />
          <path
            fill="none"
            d="M440.1,214.9c0-0.4-0.1-0.7-0.5-1.1c-0.2-0.2-0.6-0.5-1-0.5h-34.9c-0.4,0-0.7,0.1-1,0.5
	c-0.2,0.2-0.5,0.6-0.5,1.1s0.1,0.7,0.5,1c0.2,0.2,0.6,0.5,1,0.5l11.3,0.8l4.8,0.1l8.5-0.1l10.1-0.8c0.4,0,0.7-0.1,1-0.5
	C439.9,215.6,440.1,215.2,440.1,214.9L440.1,214.9z"
          />
          <path
            fill="none"
            d="M452,215.5l-9.8,3.1h-42l-9.8-3.1h-11.7c-4.4,0-7.1,1.9-7.9,5.5l-5.1,19.6c-1,3.7-0.4,5.8,2.2,6.3h40
	c3.6-2.2,8-3.4,13.4-3.4c5.2,0.1,9.8,1.2,13.3,3.4h40c2.6-0.5,3.3-2.6,2.2-6.3l-5.1-19.6c-0.8-3.6-3.5-5.5-7.9-5.5L452,215.5
	L452,215.5z M500.3,206.7c-1.4,0.4-1.9,1.3-1.2,2.8l2.4,4.7c5.2,10,10.4,23,15.4,39.1c1.2,0.5,2.3,1.2,3.4,1.9c-0.3-1-0.6-2-0.9-3
	l-0.6-1.6c-5.2-16.2-10.7-29.5-16.6-39.9L500.3,206.7L500.3,206.7z"
          />
          <path
            fill="none"
            d="M494.5,216.3l2.5,2.2l8.9,24.7l-0.8,2.3l2,6.2h2.2c2.9,0,5.6,0.6,7.9,1.5c-5.1-16-10.3-29-15.4-39.1
	c-2.2-0.5-5.1-0.8-8.6-1.2L494.5,216.3L494.5,216.3z"
          />
          <path
            fill="none"
            d="M497,218.4l-2.5-2.1L493,213l-9.9-0.8l10.4,39.4H507l-2-6.1l0.8-2.3 M487,200l1.3-3.1c-0.2-0.7-0.5-1.3-0.8-1.9
	c-0.4-0.7-1.3-1.1-2.8-1.1c-1.6,0.1-2.6,0.5-2.9,0.9c-0.4,0.6-0.6,1.3-0.6,1.9c0.4,2,0.5,3.1,0.6,3.4c0.1,0.4,0.4,0.6,0.8,0.8
	c0.5,0.2,0.9,0.4,1.4,0.4h1.2c0.5,0,0.8-0.1,1.2-0.5C486.7,200.5,486.9,200.2,487,200L487,200z M353.6,196.8l1.3,3.1
	c0.1,0.2,0.4,0.6,0.7,0.9s0.7,0.5,1.2,0.5h1.2c0.5,0,0.9-0.1,1.4-0.4c0.5-0.2,0.7-0.5,0.8-0.8l0.6-3.4c0-0.7-0.2-1.3-0.6-1.9
	c-0.4-0.5-1.3-0.8-2.9-0.9c-1.5,0-2.4,0.2-2.8,1.1C354,195.5,353.7,196.1,353.6,196.8L353.6,196.8z M341.6,206.7
	c-0.6,0.9-1.2,1.8-1.6,2.8l-0.6,1.1c-5.7,10.4-11.3,23.7-16.6,39.9l-1.4,4.8c1-0.7,2.2-1.4,3.4-1.9c5.1-16,10.3-29,15.4-39.1
	l2.6-4.7C343.3,208,343,207.1,341.6,206.7L341.6,206.7z"
          />
          <path
            fill="none"
            d="M347.4,216.3l1.5-3.3c-3.5,0.4-6.4,0.7-8.6,1.2c-5.2,10-10.4,23-15.4,39.1c2.3-0.9,5-1.5,7.9-1.5h2.2l2-6.2
	l-0.8-2.3l8.9-24.7L347.4,216.3L347.4,216.3z"
          />
          <path
            fill="none"
            d="M348.9,213l-1.5,3.3l-2.4,2.1l-8.9,24.8l0.8,2.3l-2,6.1h13.4l10.4-39.4 M310.1,250.1c3.4,1.7,5,4.2,4.9,7.5
	l-0.1,1.9c-0.4,1.2-0.7,2.1-1,2.9c-0.2,0.4-0.5,0.8-0.7,1.2c-1.3,1.8-2.9,2.7-5,2.7l0.6,0.9c1.2,0,2.2-0.4,3-0.8
	c0.8-0.5,1.6-1.2,2.2-2.1c0.2-0.5,0.5-0.9,0.8-1.4c0.5-0.9,0.9-2,1.2-3.3l0.1-2c0.2-3.6-1.6-6.4-5.5-8.3c-2.8-1.4-6.4-1.4-10.8,0
	c-4,1.4-6.5,3.4-7.7,6.1l-0.1,0.4c-1.2,3.3-0.5,6.1,2,8.4c2.6,2.6,7.5,3.6,14.5,3.1h0.2l-0.6-0.9c-6.4,0.4-10.7-0.6-13.1-2.8
	c-2.2-2.1-2.8-4.7-1.8-7.6l0.1-0.4c1-2.3,3.3-4.2,6.9-5.5C304.4,248.8,307.6,248.8,310.1,250.1L310.1,250.1z"
          />
          <path
            fill="none"
            d="M315,257.5c0.2-3.3-1.4-5.7-4.9-7.5c-2.6-1.3-5.8-1.3-9.8,0c-3.6,1.3-5.9,3-6.9,5.5l-0.1,0.4
	c-1,2.9-0.5,5.5,1.8,7.6c2.3,2.3,6.6,3.3,13.1,2.8c2.1,0,3.7-0.9,5-2.7c0.2-0.5,0.5-0.8,0.7-1.2c0.5-0.8,0.8-1.9,1-2.9L315,257.5
	L315,257.5z M324.9,253.2c-1.2,0.5-2.3,1.2-3.4,1.9c-1,0.8-2.1,1.6-3,2.7c-0.9,0.9-1.6,2.1-2.4,3.3c-0.5,0.6-0.8,1.2-1.2,1.8
	c-0.2,0.5-0.6,0.9-0.8,1.4c-0.7,1.3-1.4,2.7-2,4.2c-0.7,1.9-1.4,4-2,6.2l-0.1,0.7l-1.2,4.2l-1,3.5c-0.6,2.2-1,4.3-1.2,6.4l3.6,17.8
	h34.7c-0.1-0.9-0.1-1.9-0.1-2.9v-37.2c0.2-5.8,1.4-10.8,3.5-15.3h-15.6C329.8,251.7,327.2,252.2,324.9,253.2z M333.9,258.2
	c2.1-0.1,3.6,0.5,4.5,1.4c1,0.9,1.5,2.4,1.3,4.4v26.2c0.5,6.6-1.2,10.6-4.9,11.7c-2.3,0.8-5.4,1-9.1,0.7c-3.3-0.5-5.7-1.5-7.2-3.1
	l-0.1-0.1c-1.9-2.2-2.7-6.3-2.2-12.2V287c1.3-8.5,3.3-15.5,6.1-21v-0.1C325.5,260.9,329.3,258.4,333.9,258.2L333.9,258.2z"
          />
          <path
            fill="none"
            d="M338.4,259.8c-0.9-1-2.4-1.5-4.5-1.4c-4.5,0-8.5,2.7-11.7,7.9v0.1c-2.8,5.4-4.8,12.4-6.1,21v0.1
	c-0.4,5.9,0.4,10,2.2,12.2l0.1,0.1c1.5,1.6,4,2.7,7.2,3.1c3.7,0.4,6.8,0.1,9.1-0.7c3.6-1.2,5.2-5,4.9-11.8v-26.2
	C339.9,262.2,339.5,260.7,338.4,259.8L338.4,259.8z M333.9,260.6c1.3,0,2.2,0.2,2.9,0.7c0.5,0.6,0.7,1.5,0.6,2.7v26.7
	c0.4,5.2-0.8,8.4-3.3,9.3c-2.1,0.7-4.8,0.9-8,0.6c-2.7-0.5-4.5-1.3-5.8-2.4c-1.5-2-2-5.5-1.7-10.6c1.2-8.2,3.1-14.9,5.8-20.2
	C326.9,263,330.1,260.7,333.9,260.6L333.9,260.6z"
          />
          <path
            fill="none"
            d="M334,260.6c-3.8,0.1-7,2.3-9.7,6.8c-2.7,5.2-4.5,11.9-5.8,20.2c-0.4,5.1,0.2,8.6,1.8,10.6
	c1.3,1.2,3.3,2,5.8,2.4c3.4,0.4,6.1,0.1,8-0.6c2.6-1,3.6-4.2,3.3-9.3v-26.6c0.1-1.2-0.1-2.1-0.6-2.7
	C336.2,260.8,335.3,260.6,334,260.6L334,260.6z M334.8,275.1c0,1.5-0.6,2.8-1.6,3.8c-1,1-2.3,1.6-3.8,1.6c-1.5,0-2.8-0.6-3.8-1.6
	c-1-1-1.6-2.3-1.6-3.8s0.6-2.8,1.6-3.8c1-1,2.3-1.6,3.8-1.6c1.5,0,2.8,0.6,3.8,1.6C334.2,272.3,334.8,273.6,334.8,275.1L334.8,275.1
	z M327.8,288.5c1.4,0,2.4,0.5,3.4,1.4s1.4,2.1,1.4,3.4c0,1.4-0.5,2.4-1.4,3.4s-2.1,1.4-3.4,1.4c-1.4,0-2.4-0.5-3.4-1.4
	s-1.4-2.1-1.4-3.4c0-1.4,0.5-2.4,1.4-3.4S326.4,288.5,327.8,288.5z"
          />
          <path
            fill="none"
            d="M333.2,279c1-1,1.6-2.3,1.6-3.8s-0.6-2.8-1.6-3.8c-1-1-2.3-1.6-3.8-1.6c-1.5,0-2.8,0.6-3.8,1.6
	c-1,1-1.6,2.3-1.6,3.8s0.6,2.8,1.6,3.8c1,1,2.3,1.6,3.8,1.6C330.8,280.5,332.1,280,333.2,279L333.2,279z M331.2,289.9
	c-0.9-0.9-2.1-1.4-3.4-1.4c-1.4,0-2.4,0.5-3.4,1.4s-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4s2.1,1.4,3.4,1.4c1.4,0,2.4-0.5,3.4-1.4
	s1.4-2.1,1.4-3.4C332.6,292,332.1,290.9,331.2,289.9z M314.9,262.8c0.4-0.6,0.8-1.2,1.2-1.8v-1.5
	C315.7,260.7,315.3,261.7,314.9,262.8L314.9,262.8z M308.7,267.1h-0.2c0.2,0.4,0.5,0.7,0.8,1.2l0.6,1.4c0.2,1.6,0.4,3.1,0.2,4.7
	c0.6-2.2,1.2-4.2,2-6.2c0.6-1.5,1.3-2.9,2-4.2c-0.7,0.9-1.4,1.6-2.2,2.1C310.9,266.8,309.9,267.1,308.7,267.1l0.1,0.2L308.7,267.1
	L308.7,267.1z M308.8,279.2l1.2-4.2h-4.4c-0.5,0-0.8,0.1-1.2,0.5s-0.5,0.7-0.5,1.2v0.8c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1,0.5h3.4
	V279.2z M308.8,279.2h-3.3l-0.4,0.2c-0.4,0.4-0.5,0.7-0.5,1.2v0.5c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5h1.4L308.8,279.2
	L308.8,279.2z M350.2,314.7l0.5,0.2c2.8,1.4,6.9,2.1,12.1,2.1H479c5.2,0,9.3-0.7,12.1-2.1l0.5-0.2c3-1.9,4.8-4.4,5.2-7.6
	c0.1-0.9,0.1-1.9,0.1-2.9V267c-0.2-5.8-1.4-10.8-3.5-15.3H348.3c-2.1,4.4-3.3,9.6-3.5,15.3v37.2c-0.1,1-0.1,2,0.1,2.9
	C345.4,310.2,347.2,312.8,350.2,314.7z M405.9,273.6l-1.2-1.3l-1-1.3l-1-1.4h12.1c0.4-0.5,0.7-1,1.2-1.5c1.5-1.5,3.4-2.3,5.6-2.3
	c2.1,0,4,0.8,5.5,2.3c0.5,0.5,0.8,0.9,1.2,1.5h12.1l-1,1.4l-1,1.3l-1.2,1.3l-1.3,1.3l-1.5,1.3l-1.9,1.4l-2,1.4h-3.3l-0.2,0.2
	c-1.5,1.5-3.4,2.3-5.5,2.3c-2.2,0-4-0.8-5.6-2.3l-0.2-0.2h-3.3l-2-1.4l-1.9-1.4l-1.5-1.3L405.9,273.6L405.9,273.6z"
          />
          <path
            fill="none"
            d="M404.7,272.3l1.2,1.3h7.8c0-0.5,0-0.8,0.1-1.3H404.7L404.7,272.3z M402.6,269.7l1,1.4h10.4
	c0.2-0.5,0.4-0.9,0.7-1.4H402.6z"
          />
          <path
            fill="none"
            d="M403.7,271.1l1,1.3h9c0.1-0.5,0.2-0.9,0.4-1.3H403.7z M407.2,274.9l1.5,1.3h5.4c-0.1-0.5-0.2-0.9-0.4-1.3
	L407.2,274.9L407.2,274.9z"
          />
          <path
            fill="none"
            d="M408.7,276.2l1.8,1.4h4.2l-0.7-1.4H408.7L408.7,276.2z M410.5,277.6l2,1.4h3.3l-1-1.4H410.5L410.5,277.6z
	 M405.9,273.6l1.3,1.3h6.6c0-0.4-0.1-0.8-0.1-1.2h-7.8L405.9,273.6L405.9,273.6z M428.9,271.1c0.1,0.5,0.2,0.8,0.4,1.3h9l1-1.3
	H428.9z"
          />
          <path
            fill="none"
            d="M429.2,272.5c-0.1-0.5-0.2-0.9-0.4-1.3c-0.2-0.5-0.4-1-0.7-1.4c-0.4-0.5-0.7-1-1.2-1.5
	c-1.5-1.5-3.4-2.3-5.5-2.3c-2.2,0-4,0.8-5.6,2.3c-0.5,0.5-0.8,0.9-1.2,1.5l-0.7,1.4c-0.1,0.5-0.2,0.9-0.4,1.3
	c-0.1,0.5-0.1,0.8-0.1,1.3c0,0.4,0,0.8,0.1,1.2c0.1,0.5,0.1,0.9,0.4,1.3c0.1,0.5,0.4,0.9,0.7,1.4c0.2,0.5,0.6,0.9,1,1.4l0.2,0.2
	c1.5,1.5,3.4,2.3,5.6,2.3s4-0.8,5.5-2.3l0.2-0.2l1-1.4c0.2-0.5,0.5-0.9,0.7-1.4l0.4-1.3l0.1-1.2L429.2,272.5L429.2,272.5z
	 M426.3,269c1.3,1.3,2,2.9,2,4.8s-0.7,3.5-2,4.8c-1.3,1.3-2.9,2-4.8,2c-1.9,0-3.5-0.7-4.8-2c-1.3-1.3-2-2.9-2-4.8s0.7-3.5,2-4.8
	c1.3-1.3,2.9-2,4.8-2C423.4,267,424.9,267.6,426.3,269L426.3,269z"
          />
          <path
            fill="none"
            d="M428.3,273.7c0-1.9-0.7-3.5-2-4.8c-1.3-1.3-2.9-2-4.8-2c-1.9,0-3.5,0.7-4.8,2c-1.3,1.3-2,2.9-2,4.8
	s0.7,3.5,2,4.8c1.3,1.3,2.9,2,4.8,2c1.9,0,3.5-0.7,4.8-2C427.6,277.1,428.3,275.6,428.3,273.7L428.3,273.7z M415.4,273.7
	c0-1.8,0.6-3.1,1.7-4.3s2.7-1.8,4.3-1.8c1.8,0,3.1,0.6,4.3,1.8s1.7,2.7,1.7,4.3c0,1.8-0.6,3.1-1.7,4.3s-2.7,1.9-4.3,1.9
	c-1.7,0-3.1-0.6-4.3-1.9C415.9,276.9,415.3,275.4,415.4,273.7L415.4,273.7z"
          />
          <path
            fill="none"
            d="M417.2,269.3c-1.2,1.2-1.8,2.7-1.8,4.3c0,1.8,0.6,3.1,1.8,4.3c1.2,1.2,2.7,1.9,4.3,1.9c1.8,0,3.1-0.6,4.3-1.9
	c1.2-1.2,1.8-2.7,1.8-4.3c0-1.8-0.6-3.1-1.8-4.3s-2.7-1.8-4.3-1.8C419.8,267.6,418.4,268.1,417.2,269.3L417.2,269.3z M417,272l1,2.2
	l1-2.2h0.8v3.4h-0.8v-1.9l-0.9,1.9h-0.2l-0.8-1.9v1.9h-0.9V272L417,272L417,272z M420.6,272h0.8v3.4h-0.8L420.6,272L420.6,272z
	 M422.2,272h0.6l1.6,2.1V272h0.7v3.4h-0.7l-1.6-2v2h-0.7V272H422.2z M426.7,275.4h-0.8V272h0.8V275.4z"
          />
          <path
            fill="none"
            d="M418,274.2l-1-2.2h-0.8v3.4h0.8v-1.9l0.9,1.9h0.2l0.9-1.9v1.9h0.7V272h-0.7 M421.4,272h-0.8v3.4h0.8 M422.8,272
	h-0.6v3.4h0.7v-2l1.5,2h0.7V272h-0.7v2.1 M425.8,275.4h0.8V272h-0.8 M428.3,269.7l0.7,1.4h10.5l1-1.4H428.3z M429.3,273.6v0.1
	l-0.1,1.2h6.6l1.3-1.3 M429.2,272.5l0.1,1.2h7.8l1.2-1.3 M434.4,276.2l1.5-1.3h-6.6l-0.2,1.3"
          />
          <path
            fill="none"
            d="M428.3,277.6h4.3l1.9-1.4H429C428.8,276.7,428.6,277.1,428.3,277.6L428.3,277.6z M432.6,277.6h-4.3l-1,1.4h3.4
	 M310.2,307.1l-3.6-17.8c-1.5,3.3-2,7.8-1.6,13.6v0.1l1.6,8.9l3,17.8h5.1L310.2,307.1L310.2,307.1z M353.3,345.1
	c-5.7,0.1-8.7,3.7-9,10.8v9.4c-3.3-0.5-6.4-1-9.3-1.7v16.3c3.1,0.5,6.9,1,11.5,1.4c7.9,0.2,13.6-0.6,17.3-2.4c2.4-1.3,4.1-3.3,5-6.1
	h104.3c0.9,2.8,2.7,4.8,5,6.1c3.6,1.9,9.4,2.7,17.3,2.4c4.5-0.5,8.4-0.9,11.5-1.4v-16.3c-2.9,0.8-6.1,1.4-9.3,1.7v-9.4
	c-0.2-7.1-3.3-10.7-9-10.8c-5.8,0.1-9.3,2.4-10.5,7.2c-0.7,2.8-1.3,7-1.6,12.6l-19.5,0.8c-10.1,0.4-21.1,0.5-32.5,0.6l-14.6-0.1
	c-8.6-0.1-16.9-0.2-24.8-0.5l-19.5-0.8c-0.4-5.6-0.9-9.8-1.6-12.6C362.7,347.5,359.2,345.1,353.3,345.1L353.3,345.1z M349.3,353.1
	c0-1.5,0.5-2.7,1.5-3.7c1-1,2.3-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7c0,1.5-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5
	s-2.7-0.5-3.7-1.5C349.7,355.9,349.3,354.6,349.3,353.1L349.3,353.1z M482,353.1c0-1.5,0.5-2.7,1.5-3.7s2.3-1.5,3.7-1.5
	c1.5,0,2.7,0.5,3.7,1.5s1.5,2.3,1.5,3.7c0,1.5-0.5,2.7-1.5,3.7s-2.3,1.5-3.7,1.5c-1.5,0-2.7-0.5-3.7-1.5
	C482.5,355.9,482,354.6,482,353.1z"
          />
          <path
            fill="none"
            d="M350.8,349.4c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5
	c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.7-1.5S351.8,348.3,350.8,349.4z M350.8,353.1c0-1,0.4-1.9,1-2.7
	c0.7-0.7,1.6-1,2.7-1s1.9,0.4,2.7,1c0.7,0.7,1,1.6,1,2.7s-0.4,1.9-1,2.7c-0.7,0.7-1.6,1-2.7,1s-1.9-0.4-2.7-1
	C351.1,355.1,350.8,354.2,350.8,353.1z"
          />
          <path
            fill="none"
            d="M351.9,350.6c-0.7,0.7-1,1.6-1,2.7s0.4,1.9,1,2.7c0.7,0.7,1.6,1,2.7,1s1.9-0.4,2.7-1c0.7-0.7,1-1.6,1-2.7
	s-0.4-1.9-1-2.7c-0.7-0.7-1.6-1-2.7-1S352.6,349.9,351.9,350.6z M483.5,349.4c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7
	s2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5s1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7s-2.3-1.5-3.7-1.5S484.6,348.3,483.5,349.4z
	 M484.7,355.8c-0.7-0.7-1-1.6-1-2.7s0.4-1.9,1-2.7c0.7-0.7,1.6-1,2.7-1c1,0,1.9,0.4,2.7,1c0.7,0.7,1,1.6,1,2.7s-0.4,1.9-1,2.7
	c-0.7,0.7-1.6,1-2.7,1S485.4,356.5,484.7,355.8z"
          />
          <path
            fill="none"
            d="M483.5,353.1c0,1,0.4,1.9,1,2.7c0.7,0.7,1.6,1,2.7,1s1.9-0.4,2.7-1c0.7-0.7,1-1.6,1-2.7s-0.4-1.9-1-2.7
	c-0.7-0.7-1.6-1-2.7-1s-1.9,0.4-2.7,1C484,351.3,483.5,352.1,483.5,353.1L483.5,353.1z M312.4,346l-2.8-16.4l-3-17.8v77.2
	c0.1,4.5,1.4,7.5,4,8.6c0.8,0.4,2,0.6,3.5,0.7c1.2,0.1,2.4,0.1,4,0h9.6c1.5-0.1,2.7-0.4,3.5-0.7c2.6-1.3,3.8-4.1,4-8.6v-25.4
	c-5.1-1.3-9.9-3.1-14.2-5.6l-1.2-0.4C315.4,355.8,313,352,312.4,346L312.4,346z M375.5,351.1c1.2,0.2,2.3,0.2,3.6,0H375.5
	L375.5,351.1z M380.5,374.7c0.4-0.1,0.9-0.7,1.8-1.9h-6.8l1.6,1.5c0.4,0.4,0.7,0.5,1.2,0.5L380.5,374.7L380.5,374.7z M399,372.8
	c1.9,1.4,5.4,2.3,10.7,2.8l6.8,0.5h9.2c8.9-0.4,14.6-1.4,17.1-3.3H399L399,372.8z M462.8,351.1c1.3,0.2,2.4,0.2,3.6,0H462.8z
	 M461.3,374.7l2.2,0.1c0.5,0,0.8-0.1,1.2-0.5l1.6-1.5h-6.8C460.5,373.9,461,374.6,461.3,374.7L461.3,374.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M439.6,213.8c-0.2-0.2-0.6-0.5-1-0.5h-34.9c-0.4,0-0.7,0.1-1,0.5
	c-0.2,0.2-0.5,0.6-0.5,1.1c0,0.4,0.1,0.7,0.5,1c0.2,0.2,0.6,0.5,1,0.5l11.3,0.8l4.8,0.1l8.5-0.1l10.1-0.8c0.4,0,0.7-0.1,1-0.5
	c0.2-0.2,0.5-0.6,0.5-1.1C440.1,214.4,439.9,214.2,439.6,213.8L439.6,213.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M452,215.5h11.7c4.4,0,7.1,1.9,7.9,5.5l5.2,19.6c1,3.7,0.4,5.8-2.2,6.3
	h-40c-3.6-2.2-8-3.4-13.3-3.4c-5.4,0-9.8,1.2-13.4,3.4h-40c-2.6-0.5-3.3-2.6-2.2-6.3l5.1-19.6c0.8-3.6,3.5-5.5,7.9-5.5h11.7l9.8,3.1
	h42L452,215.5L452,215.5z M403.7,271.1h10.4 M405.9,273.6h7.8 M404.7,272.3h9 M410.5,277.6h4.2 M408.7,276.2h5.4 M407.2,274.9h6.6
	 M426.7,275.4V272h-0.8v3.4H426.7z M435.9,274.9h-6.6 M434.4,276.2H429 M432.6,277.6h-4.3 M422.8,272l1.6,2.1V272h0.7v3.4h-0.7
	l-1.6-2v2h-0.7V272H422.8z M420.6,272v3.4h0.8V272H420.6L420.6,272z M415.3,273.7c0,1.7,0.6,3.1,1.7,4.3c1.2,1.2,2.7,1.9,4.3,1.9
	c1.8,0,3.1-0.6,4.3-1.9c1.2-1.2,1.7-2.7,1.7-4.3c0-1.8-0.6-3.1-1.7-4.3s-2.7-1.8-4.3-1.8c-1.7,0-3.1,0.6-4.3,1.8
	C415.9,270.6,415.3,272,415.3,273.7L415.3,273.7z M417,272h-0.8v3.4h0.8v-1.9l0.8,1.9h0.2l0.9-1.9v1.9h0.8V272H419l-1,2.2L417,272
	L417,272z M437.1,273.6h-7.8 M439.4,271.1h-10.5 M438.3,272.3h-9 M426.3,269c-1.3-1.3-2.9-2-4.8-2c-1.9,0-3.5,0.7-4.8,2
	c-1.3,1.3-2,2.9-2,4.8s0.7,3.5,2,4.8c1.3,1.3,2.9,2,4.8,2c1.9,0,3.5-0.7,4.8-2c1.3-1.3,2-2.9,2-4.8S427.6,270.2,426.3,269L426.3,269
	z M316,259.5l0.1-2c0.2-3.6-1.6-6.4-5.5-8.3c-2.8-1.4-6.4-1.4-10.8,0c-4,1.4-6.5,3.4-7.7,6.1l-0.1,0.4c-1.2,3.3-0.5,6.1,2,8.4
	c2.6,2.6,7.5,3.6,14.5,3.1 M321.5,255.1c1-0.7,2.2-1.4,3.4-1.9 M321.5,255.1c-1,0.8-2.1,1.6-3,2.7c-0.9,0.9-1.6,2.1-2.5,3.3
	 M334.9,251.6h-2.2c-2.9,0-5.6,0.6-7.9,1.5 M348.3,251.6h-13.4 M324.9,253.2c5.1-16,10.3-29,15.4-39.1 M358.7,212.2l-10.4,39.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M414.8,269.7h-12.1l1,1.4l1,1.3l1.2,1.3l1.3,1.3l1.5,1.3l1.7,1.4l2,1.4
	h3.3 M333.9,260.6c-3.8,0.1-7,2.3-9.7,6.8c-2.7,5.2-4.5,11.9-5.8,20.2c-0.4,5.1,0.2,8.6,1.8,10.6c1.3,1.2,3.3,2,5.8,2.4
	c3.4,0.4,6.1,0.1,8-0.6c2.6-1,3.6-4.2,3.3-9.3v-26.6c0.1-1.2-0.1-2.1-0.6-2.7C336.2,260.8,335.3,260.6,333.9,260.6L333.9,260.6z
	 M338.4,259.8c1,0.9,1.5,2.4,1.3,4.4v26.2c0.5,6.6-1.2,10.6-4.9,11.7c-2.3,0.8-5.4,1-9.1,0.7c-3.3-0.5-5.7-1.5-7.3-3.1l-0.1-0.1
	c-1.9-2.2-2.7-6.3-2.2-12.2v-0.1c1.3-8.5,3.3-15.5,6.1-21V266c3.1-5.2,7-7.8,11.7-7.9C336,258.2,337.5,258.7,338.4,259.8
	L338.4,259.8z M334.8,275.1c0-1.5-0.6-2.8-1.6-3.8c-1-1-2.3-1.6-3.8-1.6c-1.5,0-2.8,0.6-3.8,1.6c-1,1-1.6,2.3-1.6,3.8
	s0.6,2.8,1.6,3.8c1,1,2.3,1.6,3.8,1.6c1.5,0,2.8-0.6,3.8-1.6C334.2,277.9,334.8,276.7,334.8,275.1L334.8,275.1z M314.9,262.8
	c0.4-0.6,0.8-1.2,1.2-1.8 M316,259.5c-0.4,1.3-0.7,2.3-1.2,3.3 M314.9,262.8c-0.2,0.5-0.6,0.9-0.8,1.4 M316,260.9v-1.5 M308.7,267.1
	h-0.2 M308.8,267.3l-0.1-0.2 M314,264.1c-0.7,0.9-1.4,1.6-2.2,2.1c-0.9,0.6-2,0.8-3,0.8 M308.7,267.1l-0.6-0.9 M308.5,267.1
	c0.2,0.4,0.5,0.7,0.8,1.2l0.6,1.4c0.2,1.6,0.4,3.1,0.2,4.7 M310.1,274.4c0.6-2.2,1.2-4.2,2-6.2c0.6-1.5,1.3-2.9,2-4.2 M310.1,275
	l-1.2,4.2 M308.8,279.2h-3.3 M310.1,274.4l-0.1,0.7 M310.1,275h-4.4c-0.5,0-0.8,0.1-1.2,0.5c-0.4,0.4-0.5,0.7-0.5,1.2v0.8
	c0,0.5,0.1,0.8,0.5,1.2c0.4,0.4,0.7,0.5,1,0.5 M305.5,279.2l-0.4,0.2c-0.4,0.4-0.5,0.7-0.5,1.2v0.5c0,0.5,0.1,0.8,0.5,1.2
	s0.7,0.5,1.2,0.5h1.4 M307.9,282.7c-0.6,2.2-0.9,4.3-1.2,6.5 M307.9,282.7l1-3.5 M327.8,288.5c-1.4,0-2.4,0.5-3.4,1.4
	s-1.4,2.1-1.4,3.4c0,1.4,0.5,2.4,1.4,3.4s2.1,1.4,3.4,1.4c1.4,0,2.4-0.5,3.4-1.4s1.4-2.1,1.4-3.4c0-1.4-0.5-2.4-1.4-3.4
	S329.1,288.5,327.8,288.5z M496.9,307.1c-0.5,3.3-2.2,5.7-5.2,7.6l-0.5,0.2c-2.8,1.4-6.9,2.1-12.1,2.1H362.9
	c-5.2,0-9.3-0.7-12.1-2.1l-0.5-0.2c-3-1.9-4.8-4.4-5.2-7.6 M344.9,307.1c-0.1-0.9-0.1-1.9-0.1-2.9V267c0.2-5.8,1.4-10.8,3.5-15.3
	 M344.9,307.1h-34.7 M310.2,307.1l-3.6-17.8 M346.6,328.8c-0.8,0-1.5,0.2-2.1,0.8c-0.6,0.6-0.8,1.3-0.8,2.1s0.2,1.5,0.8,2.1
	c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.4,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1s-0.4-1.5-0.9-2.1C348.1,329,347.4,328.8,346.6,328.8L346.6,328.8
	z M349.3,353.1c0,1.5,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7
	c-1-1-2.3-1.5-3.7-1.5s-2.7,0.5-3.7,1.5C349.7,350.4,349.3,351.7,349.3,353.1z M350.8,353.1c0,1,0.4,1.9,1,2.7c0.7,0.7,1.6,1,2.7,1
	s1.9-0.4,2.7-1c0.7-0.7,1-1.6,1-2.7s-0.4-1.9-1-2.7c-0.7-0.7-1.6-1-2.7-1s-1.9,0.4-2.7,1C351.1,351.3,350.8,352.1,350.8,353.1z
	 M506.9,363.5c-2.9,0.8-6.1,1.4-9.3,1.7v-9.4c-0.2-7.1-3.3-10.7-9-10.8c-5.8,0.1-9.3,2.5-10.5,7.2c-0.7,2.8-1.3,7-1.6,12.6
	l-19.5,0.8c-10.1,0.4-21.1,0.5-32.5,0.6l-14.6-0.1c-8.6-0.1-16.9-0.2-24.8-0.5l-19.5-0.8c-0.4-5.6-0.9-9.8-1.6-12.6
	c-1.2-4.7-4.7-7.1-10.5-7.2c-5.7,0.1-8.7,3.7-9,10.8v9.4c-3.3-0.5-6.4-1-9.3-1.7 M375.5,351.1c-1.7-0.2-3.3-0.9-4.8-2.1
	c-3.5-6.4-7.9-9.8-13.3-10.4c-6.5-2.1-12.4,0.8-17.3,8.6l-16-0.9c-4.5-0.4-7.3-4.2-8.3-11.7l-1-5.1 M318.1,329.5l-0.2-1.2
	c-0.1-0.6,0-1.2,0.5-1.5c0.4-0.5,0.9-0.7,1.6-0.8c0.7-0.1,1.3,0.1,1.9,0.5s0.8,0.8,0.9,1.4l0.2,1.1 M319.4,336.5l-0.8-4
	 M318.7,332.4l4.8-0.6 M323.5,331.9l0.7,4 M320.1,339.7l-0.7-3.3 M319.4,336.5l4.8-0.6 M324.2,335.9l0.6,3.3 M324.8,339.1l0.1,0.6
	c0.1,0.5,0,0.9-0.5,1.4c-0.4,0.4-0.9,0.7-1.5,0.8c-0.7,0.1-1.3,0-1.8-0.2c-0.6-0.2-0.8-0.7-0.9-1.2l-0.1-0.7 M320.1,339.7l4.7-0.6
	 M322.9,328.8l0.6,3 M309.6,329.6h5.1 M318.1,329.5l0.6,3 M322.9,328.8l-4.9,0.6 M335,363.5c-5.1-1.3-9.9-3.1-14.2-5.6l-1.2-0.4
	c-4.2-1.7-6.6-5.6-7.2-11.5l-2.8-16.4 M306.6,311.7v77.2c0.1,4.5,1.4,7.5,4,8.6c0.8,0.4,2,0.6,3.5,0.7c1.2,0.1,2.4,0.1,4,0h9.6
	c1.5-0.1,2.7-0.4,3.5-0.7c2.6-1.3,3.8-4.1,4-8.6v-9.1 M335,363.5v16.3 M335,379.8c3.1,0.5,6.9,1,11.5,1.4c7.9,0.2,13.6-0.6,17.3-2.4
	c2.4-1.3,4.1-3.3,5-6.1h6.8 M381.4,331.1l1.2,11.3c0.4,1.2,1,2.2,2.3,2.9c1.3,0.8,3.1,1.4,5.4,1.6h61.2c2.3-0.4,4.1-0.8,5.4-1.6
	c1.3-0.8,2-1.7,2.3-2.9l1.2-11.3c0.5-3.5-0.7-5.7-3.5-6.8c-1.2-0.5-2.7-0.7-4.4-0.7h-63.1c-1.8,0-3.3,0.2-4.4,0.7
	C382.1,325.4,381,327.6,381.4,331.1L381.4,331.1z M379.1,351.1c-1.3,0.2-2.4,0.2-3.6,0 M379.1,351.1h-3.6 M379.9,351l-0.9,0.1
	 M375.6,372.8l1.6,1.5c0.4,0.4,0.7,0.5,1.2,0.5l2.2-0.1c0.4-0.1,0.9-0.7,1.8-1.9 M382.2,372.8h-6.8 M399,372.8
	c1.9,1.4,5.4,2.3,10.7,2.8l6.8,0.5h9.2c8.9-0.4,14.6-1.4,17.1-3.3 M382.2,372.8H399 M306.6,311.8l3,17.8 M314.8,329.6l-4.5-22.5
	 M512.5,269.8c1.5,0,2.8,0.6,3.8,1.6c1,1,1.6,2.3,1.6,3.8c0,1.5-0.6,2.8-1.6,3.8s-2.3,1.6-3.8,1.6s-2.8-0.6-3.8-1.6
	c-1-1-1.6-2.3-1.6-3.8c0-1.5,0.6-2.8,1.6-3.8C509.8,270.2,511.1,269.8,512.5,269.8L512.5,269.8z M517.5,289.9
	c0.9,0.9,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4c-0.9,0.9-2.1,1.4-3.4,1.4c-1.4,0-2.5-0.5-3.4-1.4c-0.9-0.9-1.4-2.1-1.4-3.4
	c0-1.4,0.5-2.4,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4C515.4,288.5,516.5,289,517.5,289.9L517.5,289.9z M497,307.1
	c0.1-0.9,0.1-1.9,0.1-2.9V267c-0.2-5.8-1.4-10.8-3.5-15.3 M427.2,279l1-1.4l0.7-1.4l0.4-1.3l0.1-1.2l-0.1-1.3
	c-0.1-0.5-0.2-0.9-0.4-1.3c-0.2-0.5-0.4-1-0.7-1.4 M308.1,266.2c2.1,0,3.7-0.9,5-2.7c0.2-0.5,0.5-0.8,0.7-1.2c0.5-0.8,0.8-1.9,1-2.9
	l0.1-1.9c0.2-3.3-1.4-5.7-4.9-7.5c-2.6-1.3-5.8-1.3-9.8,0c-3.6,1.3-5.9,3-6.9,5.5l-0.1,0.4c-1,2.9-0.5,5.5,1.8,7.6
	C297.4,265.7,301.8,266.6,308.1,266.2L308.1,266.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M428.3,269.7c-0.4-0.5-0.7-1-1.2-1.5c-1.5-1.5-3.4-2.3-5.5-2.3
	c-2.2,0-4,0.8-5.6,2.3c-0.5,0.5-0.8,0.9-1.2,1.5 M427.2,279h3.3l2-1.4l1.9-1.4l1.5-1.3l1.3-1.3l1.2-1.3l1-1.3l1-1.4h-12.1
	 M348.9,213c-3.5,0.4-6.4,0.7-8.6,1.2 M358.7,212.2l-9.8,0.7 M348.9,213l-1.5,3.3l-2.4,2.2l-8.9,24.7l0.8,2.3l-2,6.2 M414.8,269.7
	l-0.7,1.4c-0.1,0.5-0.2,0.9-0.4,1.3c-0.1,0.5-0.1,0.8-0.1,1.3c0,0.4,0,0.8,0.1,1.2c0.1,0.5,0.1,0.9,0.4,1.3c0.1,0.5,0.4,0.9,0.7,1.4
	c0.2,0.5,0.6,0.9,1,1.4 M340.3,214.2l2.6-4.7c0.6-1.5,0.2-2.6-1.2-2.9 M341.6,206.7c-0.6,0.9-1.2,1.8-1.6,2.8l-0.6,1.1
	c-5.7,10.4-11.3,23.7-16.6,39.9l-1.4,4.8 M353.6,196.8h-0.2c-4.2,0.9-8.2,4.2-11.8,9.9 M360.7,196.7c0-0.7-0.2-1.3-0.6-1.9
	c-0.4-0.5-1.3-0.8-2.9-0.9c-1.5,0-2.4,0.2-2.8,1.1c-0.4,0.6-0.6,1.2-0.8,1.9 M415.7,279l0.2,0.2c1.5,1.5,3.4,2.3,5.6,2.3
	c2.1,0,4-0.8,5.5-2.3l0.2-0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M360.7,196.7l-0.6,3.4c-0.1,0.4-0.4,0.6-0.8,0.8s-0.9,0.4-1.4,0.4h-1.2
	c-0.5,0-0.8-0.1-1.2-0.5s-0.6-0.6-0.7-0.9l-1.3-3.1 M493,213c3.5,0.4,6.4,0.7,8.6,1.2 M493.5,251.6l-10.4-39.4 M501.6,214.2
	c5.2,10,10.4,23,15.4,39.1 M466.4,351.1c-1.2,0.2-2.3,0.2-3.6,0 M466.4,351.1h-3.6 M462.8,351.1l-0.9-0.1 M459.5,372.8
	c0.8,1.2,1.4,1.7,1.8,1.9l2.2,0.1c0.5,0,0.8-0.1,1.2-0.5l1.6-1.5 M459.5,372.8h6.8 M442.7,372.8h16.8 M518.4,331.9l-0.7,4
	 M517.7,335.9l-0.6,3.3 M518.9,328.8l-0.5,3.1 M497.4,329.6c0.6,0.6,0.8,1.3,0.8,2.1s-0.2,1.5-0.8,2.1c-0.6,0.6-1.3,0.9-2.1,0.9
	s-1.5-0.4-2.1-0.9c-0.6-0.6-0.9-1.3-0.9-2.1s0.4-1.5,0.9-2.1c0.6-0.6,1.3-0.8,2.1-0.8S496.8,329,497.4,329.6L497.4,329.6z
	 M483.5,349.4c1-1,2.3-1.5,3.7-1.5c1.5,0,2.7,0.5,3.7,1.5s1.5,2.3,1.5,3.7c0,1.5-0.5,2.7-1.5,3.7s-2.3,1.5-3.7,1.5
	c-1.5,0-2.7-0.5-3.7-1.5s-1.5-2.3-1.5-3.7S482.5,350.4,483.5,349.4z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M483.5,353.1c0-1,0.4-1.9,1-2.7c0.7-0.7,1.6-1,2.7-1s1.9,0.4,2.7,1
	c0.7,0.7,1,1.6,1,2.7s-0.4,1.9-1,2.7c-0.7,0.7-1.6,1-2.7,1s-1.9-0.4-2.7-1C484,355.1,483.5,354.2,483.5,353.1L483.5,353.1z
	 M466.3,372.8h6.8c0.9,2.8,2.7,4.8,5,6.1c3.6,1.9,9.4,2.7,17.3,2.4c4.5-0.5,8.4-0.9,11.5-1.4 M506.9,379.8v-16.3 M399,372.8h43.7
	 M462.8,351.1h-83.7 M348.3,251.6h145.1 M493.5,251.6H507 M507,251.6h2.2c2.9,0,5.6,0.6,7.9,1.5 M493,213l-9.9-0.8 M488.3,196.8
	c-0.2-0.7-0.5-1.3-0.8-1.9c-0.4-0.7-1.3-1.1-2.8-1.1c-1.6,0.1-2.6,0.5-2.9,0.9c-0.4,0.6-0.6,1.3-0.6,1.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M500.2,206.7c-3.6-5.7-7.6-9-11.8-9.9h-0.2 M481.1,196.7
	c0.4,2,0.5,3.1,0.6,3.4c0.1,0.4,0.4,0.6,0.8,0.8c0.5,0.2,0.9,0.4,1.4,0.4h1.2c0.5,0,0.8-0.1,1.2-0.5s0.6-0.6,0.7-0.9l1.3-3.1
	 M521.8,339.7l-4.7-0.6 M522.5,336.5l-4.8-0.6 M518.9,328.8l4.9,0.7 M518.4,331.9l4.8,0.5 M531.6,307.1H497 M517,253.2
	c1.2,0.5,2.3,1.2,3.4,1.9 M507,251.6l-2-6.1l0.8-2.3l-8.9-24.8l-2.5-2.1L493,213 M500.2,206.7c-1.4,0.4-1.7,1.3-1.2,2.9l2.5,4.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M520.4,255.1c-0.3-1-0.6-2-0.9-3l-0.6-1.6c-5.2-16.2-10.7-29.5-16.6-39.9
	l-2.1-3.7 M358.7,212.2c0.7-3.8,3.3-6.2,7.6-7.1c4.8-1.6,13.6-2.7,26.6-3.4l28-0.8l28.2,0.8c12.9,0.7,21.8,1.9,26.6,3.4
	c4.3,0.9,6.9,3.3,7.6,7.1 M481.1,196.7c-0.6,0.2-0.8,0.6-0.9,1.1c-12.9-2.2-30.8-3.4-53.4-3.5h-2.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M483.1,212.2c-9-0.5-20.3-0.7-34.2-0.8c-7.5,0-15.7,0-24.6,0.1h-6.9
	c-8.9-0.1-17.1-0.1-24.6-0.1c-13.9,0-25.3,0.4-34.2,0.8 M420,192.1c-1.2,0.2-1.9,1.1-2.2,2.2 M417.8,194.2h-1.6
	c-23.2,0.1-41.4,1.3-54.6,3.5c-0.1-0.5-0.5-0.8-0.9-1.1 M424.2,194.2c-0.4-1.1-0.9-1.8-2-2.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M417.8,194.2v1.6c0.1,0.5,0.4,0.7,0.9,0.7h4.5c0.5,0,0.8-0.2,0.9-0.7v-1.6
	"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M422.2,192.1l0.1,1.3c0,0.9-0.4,1.3-1,1.3c-0.8,0-1.2-0.4-1.2-1.3v-1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M422.2,192.1l-0.5-5.8c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.5-0.2
	s-0.4,0.1-0.5,0.2s-0.2,0.2-0.2,0.5l-0.4,5.8 M531.6,307.1l-4.4,22.5 M535.3,311.7l-3.1,17.8 M535.3,311.7v77.2
	c-0.1,4.5-1.4,7.5-4,8.6c-0.8,0.4-2,0.6-3.5,0.7c-1.2,0.1-2.4,0.1-4,0h-9.6c-1.5-0.1-2.7-0.4-3.5-0.7c-2.6-1.3-3.8-4.1-4-8.6v-9.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M506.9,363.5c5.1-1.3,9.9-3.1,14.2-5.6l1.2-0.4c4.2-1.7,6.6-5.6,7.2-11.5
	l2.8-16.4 M517.1,339.1l-0.1,0.6c-0.1,0.5,0,0.9,0.5,1.4c0.3,0.4,0.9,0.7,1.5,0.8c0.7,0.1,1.3,0,1.8-0.2c0.6-0.2,0.8-0.7,0.9-1.2
	l0.1-0.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M527.1,329.6l-1,5.1c-1,7.3-3.7,11.3-8.3,11.7l-16,0.9
	c-4.9-7.8-10.7-10.6-17.3-8.6c-5.4,0.6-9.8,4-13.3,10.4c-1.5,1.2-3.1,1.9-4.8,2.1 M523.2,332.4l0.6-2.9 M521.8,339.7l0.7-3.3
	 M306.7,289.1c-1.5,3.3-2,7.8-1.6,13.6v0.1l1.6,8.9 M522.5,336.5l0.7-4.1 M523.8,329.5l0.2-1.2c0.1-0.6,0-1.2-0.5-1.5
	c-0.3-0.5-0.9-0.7-1.6-0.8c-0.7-0.1-1.3,0.1-1.9,0.5c-0.6,0.4-0.8,0.8-0.9,1.4l-0.2,1.1 M532.2,329.6h-5 M535.2,289.1l-3.6,18
	 M521.7,298.1c-1.3,1.2-3.3,2-5.8,2.4c-3.4,0.4-6.1,0.1-8-0.6c-2.6-1-3.6-4.2-3.3-9.3v-26.6c-0.1-1.2,0.1-2.1,0.6-2.7
	c0.6-0.6,1.5-0.8,2.8-0.7c3.8,0.1,7,2.3,9.7,6.8c2.7,5.2,4.5,11.9,5.8,20.2C523.8,292.6,523.2,296.1,521.7,298.1L521.7,298.1z
	 M535.2,289.1c1.5,3.3,2,7.8,1.6,13.6v0.1l-1.6,8.9 M533.1,267.1l0.6-0.9 M533,279.2l1,3.5 M533,279.2h3.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M534,282.7c0.6,2.2,0.9,4.3,1.2,6.5 M536.2,279.2l0.3,0.2
	c0.3,0.4,0.5,0.7,0.5,1.2v0.5c0,0.5-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5H534 M531.8,275h4.4c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2
	v0.8c0,0.5-0.1,0.8-0.5,1.2s-0.7,0.5-1,0.5 M525.7,287.1c-1.3-8.5-3.3-15.5-6.1-21V266c-3.1-5.2-7-7.8-11.7-7.9
	c-2.1-0.1-3.6,0.5-4.5,1.4c-1,0.9-1.5,2.4-1.3,4.4v26.2c-0.5,6.6,1.2,10.6,4.9,11.7c2.3,0.8,5.4,1,9.1,0.7c3.3-0.5,5.7-1.5,7.2-3.1
	l0.1-0.1C525.4,297.4,526.1,293.2,525.7,287.1L525.7,287.1z M531.8,275l1.2,4.2 M531.7,274.4l0.1,0.7 M527,262.8
	c-0.5-0.9-0.9-2-1.2-3.3 M527.8,264.1c0.7,1.3,1.4,2.7,2,4.2c0.7,1.9,1.4,4,2,6.2 M527,262.8l-1.2-1.8 M533.3,267.1h-0.2
	 M533.1,267.1c-1.2,0-2.2-0.4-3-0.8c-0.8-0.5-1.6-1.2-2.2-2.1 M533.1,267.3v-0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M533.3,267.1c-0.2,0.4-0.5,0.7-0.8,1.2c-0.2,0.5-0.5,0.8-0.6,1.4
	c-0.2,1.6-0.3,3.1-0.2,4.7 M525.9,260.9v-1.4 M527.8,264.1l-0.8-1.3 M533.7,266.2c6.4,0.4,10.7-0.6,13.1-2.8
	c2.2-2.1,2.8-4.7,1.8-7.6l-0.1-0.4c-1-2.3-3.3-4.2-6.9-5.5c-4-1.3-7.2-1.3-9.8,0c-3.4,1.8-5,4.2-4.9,7.5l0.1,1.9
	c0.3,1.2,0.7,2.1,1,2.9l0.7,1.2C529.9,265.4,531.7,266.2,533.7,266.2L533.7,266.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M525.9,259.5l-0.1-2c-0.2-3.6,1.6-6.4,5.5-8.3c2.8-1.4,6.4-1.4,10.8,0
	c4,1.4,6.5,3.4,7.7,6.1l0.1,0.4c1.2,3.3,0.5,6.1-2,8.4c-2.6,2.6-7.5,3.6-14.5,3.1 M520.4,255.1c1,0.8,2.1,1.6,3,2.7
	c0.9,0.9,1.6,2.1,2.4,3.3"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M348.3,251.6h39.1H413h13.6
		h27.4h24.2h15.2l-10.4-39.4c0,0-9.4-0.5-29.8-0.8c0,0-12.4,0-28.4,0.1h-3.8h-3.7c0,0-14.9-0.1-31.1-0.1c0,0-13.4,0.1-27.3,0.7
		l-4,14.9l-4,15L348.3,251.6z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M344.9,304.2v-12.2v-16.8V267
		c0,0-0.1-4.2,1.2-8.9c0,0,0.8-3.3,2.3-6.4h21h25.4h26.2h26.6h22.6h23.2c0,0,3.3,6.3,3.5,15.3v15.2v16.7v5.5c0,0,0.6,4.9-2.6,8.3
		c0,0-2.3,2.7-6.2,3.5c0,0-2.9,0.9-7.3,0.9h-25.5h-34.4h-29.8h-29c0,0-6.4,0.1-10.4-1.6c0,0-4.1-1.4-5.9-5.6
		C345.8,309.8,344.5,307.7,344.9,304.2z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M339.7,290.5l0.1-14.6
		l-0.1-11.7c0,0,0.6-3.4-2-5c0,0-1.4-1-3.6-0.9c0,0-2.6-0.2-5.2,1.2c0,0-3.6,1.5-6.6,6.6c0,0-2.9,5-5.1,15.3c0,0-0.6,2.6-1,5.8
		c0,0-0.6,6.2,0.9,9.9c0,0,0.7,3,4.4,4.4c0,0,3,1.4,7.3,1.4c0,0,4.4,0.1,7.3-1.4c0,0,2.3-1.2,3.1-4.9
		C339.3,296.6,339.9,294.7,339.7,290.5z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M502.1,290.4v-26.2
		c0,0-0.5-3,1.4-4.5c0,0,1.2-1.4,4.1-1.3c0,0,3.5-0.2,6.5,1.9c0,0,3,1.9,5.5,5.9c0,0,2,3.6,4,10.3c0,0,1.2,3.8,2.2,11
		c0,0,0.6,5.6-0.7,9.3c0,0-0.7,2.8-3.1,4.3c0,0-2,1.4-5.9,2c0,0-5.9,0.6-9.2-0.7c0,0-3.4-0.8-4.3-5.5
		C502.4,296.7,501.8,294.5,502.1,290.4z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M497,307.1h19.8h14.9
		l-4.5,22.5h5.1l-2.8,16.4c0,0-0.3,4.7-2.3,7.5c0,0-1.9,3.3-6.1,4.4c0,0-5.5,3-10.8,4.7c0,0-5.4,1.7-12.7,2.7v-9.4
		c0,0,0-5.9-2.9-8.5c0,0-2-2.4-6.4-2.3c0,0-5.1-0.1-8,3c0,0-2,1.5-2.7,6.6c0,0-0.6,3.5-1.2,10l-21.1,0.8L430,366h-9.1l-13.3-0.1
		l-22.7-0.5l-19.5-0.8c0,0-0.2-4.3-0.8-8.3c0,0-0.6-4.9-2-7.1c0,0-1.4-2.8-5.5-3.8c0,0-4.5-1.4-8.4,0.6c0,0-2.3,1-3.5,4.1
		c0,0-0.9,2-1,5.5v9.8c0,0-6.8-0.7-14.3-3.3c0,0-3.3-0.9-9.2-4.1c0,0-3.7-0.7-6.1-4.5c0,0-1.6-1.9-2.2-7.5l-1.7-10.4l-1-6.1h5.1
		l-2.6-12.6l-2-9.9h34.6c0,0,0.6,4.1,4.1,6.8c0,0,3,3.1,12.7,3.1h34.3h25.1h30.4h29.5c0,0,8.4,0.1,12.1-3.1
		C493,313.7,496.3,311.6,497,307.1z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Crossover4DrRear);
