import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadPreselectedDamages } from '../../../../../../actions';
import Layout from '../../../templates/forms/Layout';
import Splat from '../../../templates/forms/condition/splats';
import FormFooter from '../../../templates/forms/formFooter';
import Interior from './Interior';
import Mechanical from './Mechanical';
import Frame from './Frame';
import All from './All';
import '../../../../styles/form/condition.css';

class Condition extends Component {
  state = {
    selectedRow: 0,
  };

  componentDidMount() {
    this.props.loadPreselectedDamages(this.props && this.props.item);

    if (this.props.tabName === 'All') {
      this.setState({ selectedRow: 7 });
    }

    let locationState = this.props.location.state;
    if (locationState && locationState.condition) {
      this.setState({
        selectedRow: locationState.condition.selectedRow
          ? locationState.condition.selectedRow
          : 0,
      });
    }
  }

  hasSplat = bodyStyle => {
    switch (bodyStyle) {
      case 'CONVERTIBLE 2-DR':
      case 'COUPE':
      case 'COUPE 2-DR':
      case 'HARDTOP':
      case 'HARDTOP 2-DR':
      case 'SEDAN 2-DR':
      case 'COUPE 4-DR':
      case 'CREW CAB PICKUP 4-DR':
      case 'CROSSOVER 4-DR':
      case 'MPV':
      case 'CLUB CAB PICKUP':
      case 'CLUB CAB PICKUP 2-DR':
      case 'EXTENDED CAB PICKUP':
      case 'EXTENDED CAB PICKUP 2-DR':
      case 'EXTENDED CAB PICKUP 3-DR':
      case 'EXTENDED CAB PICKUP 4-DR':
      case 'HATCHBACK':
      case 'HATCHBACK 2-DR':
      case 'HATCHBACK 3-DR':
      case 'LIFTBACK 3-DR':
      case 'HATCHBACK 4-DR':
      case 'HATCHBACK 5-DR':
      case 'LIFTBACK 5-DR':
      case 'CONVENTIONAL CAB':
      case 'CREW CAB PICKUP 2-DR':
      case 'PICKUP':
      case 'REGULAR CAB PICKUP 2-DR':
      case 'TRUCK':
      case 'SEDAN':
      case 'SEDAN 4-DR':
      case 'SPORT UTILITY 2-DR':
      case 'SPORT UTILITY 2-DR HARD-TOP':
      case 'SPORT UTILITY 2-DR SOFT-TOP':
      case 'SPORT UTILITY 4-DR':
      case 'SPORT UTILITY TRUCK 4-DR':
      case 'SUV':
      case 'CAMPER VAN':
      case 'CARGO CONVERSION VAN':
      case 'PASSENGER VAN':
      case 'SPORTS VAN':
      case 'STEP VAN':
      case 'VAN':
      case 'WINDOW VAN':
      case 'EXTENDED SPORTS VAN':
      case 'EXTENDED WINDOW VAN':
      case 'WAGON':
      case 'WAGON 4-DR':
      case 'WAGON 5-DR':
        return true;

      case 'BUS':
      case 'CARGO CUTAWAY':
      case 'CARGO VAN':
      case 'CARGO VAN 2-DR':
      case 'CARGO VAN 3-DR':
      case 'CONVERSION VAN':
      case 'EXTENDED CARGO VAN':
      case 'UTILITY VAN':
      default:
        return false;
    }
  };

  render() {
    const { selectedRow } = this.state;
    const { item, vin, variant } = this.props;

    const bodyStyle =
      item.bodyStyle ||
      (this.props.vin &&
        vin.decodedItems &&
        vin.decodedItems[variant && variant.index] &&
        vin.decodedItems[variant.index].bodyStyles[0]);

    const bodyHasSplat = this.hasSplat(bodyStyle);
    const splatData = bodyHasSplat
      ? [
          {
            tabName: 'Left',
            component: (
              <Splat
                type={bodyStyle}
                area="left"
                {...this.props}
                selectedRow={selectedRow}
              />
            ),
          },
          {
            tabName: 'Rear',
            component: (
              <Splat
                type={bodyStyle}
                area="rear"
                {...this.props}
                selectedRow={selectedRow}
              />
            ),
          },
          {
            tabName: 'Top',
            component: (
              <Splat
                type={bodyStyle}
                area="top"
                {...this.props}
                selectedRow={selectedRow}
              />
            ),
          },
          {
            tabName: 'Right',
            component: (
              <Splat
                type={bodyStyle}
                area="right"
                {...this.props}
                selectedRow={selectedRow}
              />
            ),
          },
          {
            tabName: 'Front',
            component: (
              <Splat
                type={bodyStyle}
                area="front"
                {...this.props}
                selectedRow={selectedRow}
              />
            ),
          },
          {
            tabName: 'Interior',
            component: <Interior {...this.props} selectedRow={selectedRow} />,
          },
          {
            tabName: 'Frame',
            component: <Frame {...this.props} selectedRow={selectedRow} />,
          },
          {
            tabName: 'Mechanical',
            component: <Mechanical {...this.props} selectedRow={selectedRow} />,
          },
          {
            tabName: 'All',
            component: <All {...this.props} selectedRow={selectedRow} />,
          },
        ]
      : [
          {
            tabName: 'All',
            component: <All selectedRow={selectedRow} />,
          },
          {
            tabName: 'Interior',
            component: <Interior selectedRow={selectedRow} />,
          },
          {
            tabName: 'Mechanical',
            component: <Mechanical selectedRow={selectedRow} />,
          },
        ];

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Condition</div>
            </div>
            <div className="form-body-container">
              <div className="form-body form-body-vertical conditions-form-body">
                <div className="splats">
                  {splatData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`splat-type ${this.state.selectedRow ===
                          index && 'splat-type-selected'}`}
                        onClick={() => this.setState({ selectedRow: index })}
                      >
                        {item.tabName}
                      </div>
                    );
                  })}
                </div>
                <div className="condition-form-vehicle">
                  <div className="condition-section-title">Select an area</div>
                  {splatData[this.state.selectedRow].component}
                </div>
              </div>
            </div>

            <FormFooter currentSection="s6" />
          </div>
        </div>
      </Layout>
    );
  }
}

Condition.propTypes = {
  item: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  vin: PropTypes.object.isRequired,
  loadPreselectedDamages: PropTypes.func.isRequired,
  tabName: PropTypes.string,
};

const mapStateToProps = state => {
  const { item, variant, vin, coreSections } = state.core;
  return { item, variant, vin, coreSections };
};

export default connect(
  mapStateToProps,
  { loadPreselectedDamages }
)(Condition);
