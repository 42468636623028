import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../../../../ui/inputField';
import SelectMenu from '../../../../ui/selectMenu';
import TextArea from '../../../../ui/textArea';
import ImageUploader from '../../ImageUploader';
import { showRow } from '../../../../../utils/helpers';
import { commafy } from '../../../../../../../utils/helpers';

class ConditionForm extends Component {
  render() {
    const {
      actionItems,
      area,
      chargeableSelectedIndex,
      conditionKey,
      coreSections,
      damage,
      laborCode,
      laborHours,
      materialEstimate,
      needReviewIndex,
      notes,
      paintCode,
      paintHours,
      part,
      repairCost,
      severity,
      sublet,
    } = this.props;

    return (
      <div className="form-body-container">
        <div className="damage-form-title">Info</div>
        <div className="damage-summary">
          <span>Damage Summary</span>
          <div>
            <div>
              <span>Area : </span>
              <span>{area}</span>
            </div>
            <div>
              <span>Part : </span>
              <span>{part}</span>
            </div>
            <div>
              <span>Damage : </span>
              <span>{damage}</span>
            </div>
            <div>
              <span>Severity : </span>
              <span>{severity}</span>
            </div>
          </div>
        </div>

        {/* <hr /> */}

        {showRow('s6:r4', coreSections) && (
          <div className="form-checkbox-container damage-form-body-item">
            <span>Needs Review</span>
            <div className="h-100">
              <label className="form-checkbox-label mr-4">
                Yes
                <input
                  type="radio"
                  name="needReview"
                  defaultChecked={
                    needReviewIndex === undefined
                      ? undefined
                      : needReviewIndex === 0
                  }
                  onChange={() =>
                    this.props.handleStateChange('needReviewIndex', 0)
                  }
                />
                <span className="form-checkbox-checkmark" />
              </label>
              <label className="form-checkbox-label mr-4">
                No
                <input
                  type="radio"
                  name="needReview"
                  defaultChecked={
                    needReviewIndex === undefined
                      ? undefined
                      : needReviewIndex === 1
                  }
                  onChange={() =>
                    this.props.handleStateChange('needReviewIndex', 1)
                  }
                />
                <span className="form-checkbox-checkmark" />
              </label>
            </div>
          </div>
        )}

        {showRow('s6:r5', coreSections) && (
          <div className="form-body-sub-item damage-form-body-item">
            <div className="flex">
              <SelectMenu
                title="Action Suggest"
                options={actionItems.map(actionItem => ({
                  value: actionItem.repairAction,
                  label: actionItem.repairAction,
                }))}
                onChangeHandler={e =>
                  this.props.handleStateChange(
                    'actionSuggest',
                    e.target.value,
                    'repairAction'
                  )
                }
                hideIndicatorSeparator={true}
              />
            </div>
          </div>
        )}

        {/* <hr /> */}

        {showRow('s6:r6', coreSections) && (
          <div className="form-checkbox-container damage-form-body-item">
            <span>Chargeable</span>
            <div className="h-100">
              <label className="form-checkbox-label mr-4">
                Yes
                <input
                  type="radio"
                  name="chargeable"
                  defaultChecked={
                    chargeableSelectedIndex === undefined
                      ? undefined
                      : chargeableSelectedIndex === 0
                  }
                  onChange={() =>
                    this.props.handleStateChange('chargeableSelectedIndex', 0)
                  }
                />
                <span className="form-checkbox-checkmark" />
              </label>
              <label className="form-checkbox-label mr-4">
                No
                <input
                  type="radio"
                  name="chargeable"
                  defaultChecked={
                    chargeableSelectedIndex === undefined
                      ? undefined
                      : chargeableSelectedIndex === 1
                  }
                  onChange={() =>
                    this.props.handleStateChange('chargeableSelectedIndex', 1)
                  }
                />
                <span className="form-checkbox-checkmark" />
              </label>
            </div>
          </div>
        )}

        {showRow('s6:r7', coreSections) && (
          <div className="form-body-sub-item damage-form-body-item">
            <div>
              <InputField
                currency={'$'}
                title="Estimated Repair Cost"
                placeholder="Enter estimated Repair Cost"
                onChangeHandler={e => {
                  this.props.handleOnChangeTextPrice(
                    e.target.value,
                    'repairCost'
                  );
                }}
                value={commafy(repairCost)}
              />
            </div>
          </div>
        )}
        {showRow('s6:r8', coreSections) && (
          <div className="damage-form-body-item">
            <TextArea
              title="Repair Description"
              placeholder="Enter repair description"
              onChangeHandler={e => {
                this.props.handleStateChange('notes', e.target.value);
              }}
              value={notes}
            />
          </div>
        )}

        {showRow('s6:r9', coreSections) && (
          <div className="form-body-sub-item damage-form-body-item">
            <div>
              <InputField
                currency={'$'}
                title="Material Estimate"
                placeholder="Enter material estimate"
                onChangeHandler={e => {
                  this.props.handleOnChangeTextPrice(
                    e.target.value,
                    'materialEstimate'
                  );
                }}
                value={commafy(materialEstimate)}
              />
            </div>
          </div>
        )}

        {/* <hr /> */}

        <div className="form-body-sub-item damage-form-body-item">
          <div>
            {showRow('s6:r10', coreSections) && (
              <InputField
                title="Labor Hours"
                placeholder="Enter labor hours"
                onChangeHandler={e => {
                  this.props.handleOnChangeHours('laborHours', e.target.value);
                }}
                value={laborHours}
                maxLength={5}
              />
            )}
            {showRow('s6:r11', coreSections) && (
              <InputField
                title="Labor Rate Code"
                placeholder="Enter labor rate code"
                onChangeHandler={e =>
                  this.props.handleStateChange('laborCode', e.target.value)
                }
                value={laborCode}
                maxLength={2}
              />
            )}
          </div>
        </div>

        <div className="form-body-sub-item damage-form-body-item">
          <div>
            {showRow('s6:r12', coreSections) && (
              <InputField
                title="Paint Hours"
                placeholder="Enter paint hours"
                onChangeHandler={e =>
                  this.props.handleOnChangeHours('paintHours', e.target.value)
                }
                value={paintHours}
                maxLength={5}
              />
            )}
            {showRow('s6:r13', coreSections) && (
              <InputField
                title="Paint Rate Code"
                placeholder="Enter paint rate code"
                onChangeHandler={e =>
                  this.props.handleStateChange('paintCode', e.target.value)
                }
                value={paintCode}
                maxLength={2}
              />
            )}
          </div>
        </div>

        {showRow('s6:r14', coreSections) && (
          <div className="form-body-sub-item damage-form-body-item">
            <div>
              <InputField
                currency={'$'}
                title="Sublet Estimate"
                placeholder="Enter sublet estimate"
                onChangeHandler={e =>
                  this.props.handleOnChangeTextPrice(e.target.value, 'sublet')
                }
                value={commafy(sublet)}
              />
            </div>
          </div>
        )}

        {/* <hr /> */}

        {showRow('s6:r15', coreSections) && (
          <div className="damage-form-body-item">
            <TextArea
              title="Item Notes"
              placeholder="Enter notes"
              onChangeHandler={e =>
                this.props.handleStateChange('notes', e.target.value)
              }
              value={notes}
            />
          </div>
        )}

        {/* <hr /> */}

        {showRow('s6:r16', coreSections) && (
          <div className="form-body-sub-item damage-form-body-item damage-photo">
            <span>Damage Photo</span>
            <div>
              <ImageUploader
                title=""
                photoItem={{
                  category: 'Condition',
                  description: (conditionKey || '').split('-')[1] || '', // condition index
                  area: area,
                  cloudPicker: false,
                }}
                onImageClick={() => null}
                onPhotosChange={() => null}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { coreSections } = state.core;
  return { coreSections };
};

export default connect(
  mapStateToProps,
  {}
)(ConditionForm);
