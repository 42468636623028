export const WHITE_01 = '#FFFFFF';

export const CYAN_01 = '#4D9BB0';
export const CYAN_02 = '#E3EFF3';

export const BLUE_01 = '#263347';

export const RED_01 = '#EE1B1B';
export const RED_02 = '#FFE6E7';
export const RED_03 = '#FD5960';

export const GREEN_01 = '#EBF8F1';

export const GREY_01 = '#DCDCDC';
export const GREY_02 = '#737579';
export const GREY_03 = '#D1D5DB';
export const GREY_04 = '#c0c2c4';
export const GREY_05 = '#F1F1F1';
export const GREY_06 = '#98989E';
export const GREY_07 = '#858C94';

export const BLACK_02 = '#23262F';
export const BLACK_03 = '#09101D';
