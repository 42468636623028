import React, { Component } from 'react';
import Modal from 'react-modal';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import {
  CYAN_01,
  BLACK_02,
  GREY_05,
  BLACK_03,
  WHITE_01,
  GREY_04,
} from '../../constants/colors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadLots } from '../../../../actions';
class AutogradeModal extends Component {
  onClose = () => {
    this.props.close();
  };
  handleAutoGrade = () => {
    this.props.handleGetAutoGrade();
    this.props.close();
  };
  render() {
    const { open, vin } = this.props;
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        zIndex: 99,
        position: 'absolute',
        borderRadius: '8px',
        border: 'none',
        boxShadow:
          '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    };
    return (
      <div>
        <Modal
          isOpen={open}
          shouldCloseOnEsc
          style={customStyles}
          contentLabel={'getAutograde'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            <div className="flex items-center justify-between mb-9">
              <div className="flex items-center justify-between">
                <div
                  className="fs-20 font-semibold"
                  style={{ color: BLACK_02 }}
                >
                  Get Autograde
                </div>
              </div>
              <div
                className="flex justify-center items-center border rounded-full"
                onClick={this.onClose}
              >
                <Icon name="Cross" size={20} color={CYAN_01} />
              </div>
            </div>
            <div
              className="fs-14 text-base font-medium mb-8"
              style={{ color: BLACK_03 }}
            >
              Is CR Done?
            </div>
            <div className="flex justify-between modal-mt">
              <div className="w-1/2" style={{ width: '50%' }}>
                <Button
                  btnText="No"
                  outlined={true}
                  outlinedTextColor={BLACK_03}
                  outlinedBgColor={GREY_05}
                  outlinedBorderColor={GREY_05}
                  fontSize="16px"
                  borderRadius="10px"
                  onClickHandler={this.onClose}
                />
              </div>
              <div className="ml-2" style={{ width: '50%' }}>
                <Button
                  btnText="Yes"
                  outlined={false}
                  fontSize="16px"
                  borderRadius="10px"
                  onClickHandler={this.handleAutoGrade}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AutogradeModal.propTypes = {
  loadLots: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { lots } = state.core;
  return {
    lots,
  };
};

export default connect(mapStateToProps, { loadLots })(AutogradeModal);
