import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { values, isEmpty, isArray, get } from 'lodash';
import { loadMMR, resetMMR } from '../../actions';
import {
  commafy,
  commafyCurrency,
  findMileageUnits,
  getConfig,
} from '../../utils/helpers';

const styles = {
  customStyles: {
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 150,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
};

class MMRModal extends Component {
  state = {
    mmrIndex: 0,
  };

  onOpen = () => {
    const mpId = getConfig('marketplaceId');
    this.props.loadMMR(mpId, this.props.item.vin, this.props.item.odometer);
  };

  onClose = () => {
    this.props.resetMMR();
    this.props.onClose();
  };

  updateSelect = e => {
    const mmrIndex = e.currentTarget.value;
    this.setState({ mmrIndex });
  };

  renderPricing() {
    const { item, mmr } = this.props;
    const { mmrIndex } = this.state;
    const pricing = mmr.mmrBulkBookPricing[mmrIndex];
    const mileageUnits = findMileageUnits(item);

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <h4>{`${item.year} ${item.make} ${item.model}`}</h4>
          <h4 className="miles-color">
            {`${commafy(item.odometer)} ${mileageUnits}`}
          </h4>
        </div>
        {this.renderSelect()}

        <table className="table" style={{ marginTop: '15px' }}>
          <tbody>
            <tr>
              <td>
                <span>Above Avg:</span>
                <br />
                <strong>{commafyCurrency(pricing.excellent)}</strong>
              </td>
              <td>
                <span>Average:</span>
                <br />
                <strong>{commafyCurrency(pricing.good)}</strong>
              </td>
              <td>
                <span>Below Avg:</span>
                <br />
                <strong>{commafyCurrency(pricing.fair)}</strong>
              </td>
              <td>
                <span>Avg Miles:</span>
                <br /> <strong>{commafy(pricing.auctionAverageMiles)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderSelect() {
    const { mmr } = this.props;

    const options = values(mmr.mmrBulkBookPricing).map((option, index) => {
      return (
        <option key={option.trim} value={index}>
          {option.trim}
        </option>
      );
    });

    return (
      <select
        style={{ display: 'inline-block', float: 'left', marginBottom: 20 }}
        onChange={this.updateSelect}
      >
        {options}
      </select>
    );
  }

  renderTransactionHistory() {
    const { item, mmr } = this.props;

    if (!isArray(mmr.mmrTransactions) || !mmr.mmrTransactions.length) {
      return null;
    }

    const mileageUnits = item.odometerNotes === 'KM' ? 'km' : 'mi';
    const rows = values(mmr.mmrTransactions).map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.region}</td>
          <td>{item.color}</td>
          <td>{`${commafy(item.miles)} ${mileageUnits}`}</td>
          <td>{item.frameDamage}</td>
          <td>{commafyCurrency(item.price)}</td>
          <td>{item.purchaseDate}</td>
        </tr>
      );
    });

    return (
      <div>
        <p>Transaction History</p>
        <table className="table table-striped">
          <tbody>
            <tr>
              <td>Region</td>
              <td>Color</td>
              <td>Mileage</td>
              <td>Frame Damage</td>
              <td>Price</td>
              <td>Purchase Date</td>
            </tr>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-file" />
          <span style={{ marginLeft: 10 }}>MMR</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderContent() {
    const pricing = isArray(get(this.props.mmr, 'mmrBulkBookPricing'))
      ? this.props.mmr.mmrBulkBookPricing[this.state.mmrIndex]
      : null;

    const loading = isEmpty(this.props.mmr);
    const noData = !pricing || !pricing.good;

    return (
      <div style={styles.body}>
        {loading ? (
          <h4>Loading...</h4>
        ) : noData ? (
          <h4>MMR data is currently unavailable for this vehicle.</h4>
        ) : (
          <>
            {this.renderPricing()}
            {this.renderTransactionHistory()}
          </>
        )}
      </div>
    );
  }

  renderFooter() {
    return (
      <div style={styles.footer}>
        <div />
        <div style={styles.buttons}>
          <button
            className="btn btn-primary button-cancel"
            style={{ minWidth: 100 }}
            type="button"
            onClick={this.onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="MMR Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div style={styles.container}>
          {this.renderHeader()}
          {this.renderDivider()}
          {this.renderContent()}
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

MMRModal.propTypes = {
  item: PropTypes.object.isRequired,
  mmr: PropTypes.shape({
    mmrVehicles: PropTypes.array,
    mmrTransactions: PropTypes.array,
    mmrMultiMidPricing: PropTypes.object,
  }).isRequired,
  loadMMR: PropTypes.func.isRequired,
  resetMMR: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { mmr } = state.entities;
  return { mmr };
};

export default connect(mapStateToProps, {
  loadMMR,
  resetMMR,
})(MMRModal);
