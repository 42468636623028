import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  loadBuyerList,
  loadBuyerInviteAddRequest,
  loadBuyerInviteRemoveRequest,
  loadBuyerInviteListRequest,
  loadBuyerSearch
} from '../../actions';
import $ from 'jquery';
import { getConfig } from '../../utils/helpers';

const buyerListData = props => {
  props.loadBuyerList(getConfig('marketplaceId'));
};

const buyerInviteListRequestData = props => {
  props.loadBuyerInviteListRequest();
};

const buyerAddInviteRequestData = (props, addInviteProps) => {
  props.loadBuyerInviteAddRequest(addInviteProps);
};

const buyerRemoveInviteRequestData = (props, removeInviteProps) => {
  props.loadBuyerInviteRemoveRequest(removeInviteProps);
};

const buyerSearchData = (props, searchText) => {
  props.loadBuyerSearch(getConfig('marketplaceId'), searchText);
};

class InvitedBuyerList extends Component {
  render() {
    let buyerInvitesList = this.props.invitedList.map((buyer, index) => {
      if (buyer.buttonText !== 'Invite') {
        let buyerName = buyer.accountName
          ? buyer.accountName
          : buyer.fullName
            ? buyer.fullName
            : buyer.name;
        return (
          <button className="btn btn-info btn-sm">
            <span>{buyerName}</span>
            <span
              className="fa fa-times deleteIcon"
              onClick={this.props.onRemoveInvite.bind(this, buyer, index)}
            />
          </button>
        );
      }
      return null;
    });
    return (
      <div className="buyerInviteWrapper">
        <h3 className="modal-title">Invited Buyers</h3>
        {buyerInvitesList.length > 0 ? (
          buyerInvitesList
        ) : (
          <p>You have not invited any buyers.</p>
        )}
      </div>
    );
  }
}

class BuyerListTableRow extends Component {
  render() {
    let buyerName = this.props.buyer.accountName
      ? this.props.buyer.accountName
      : this.props.buyer.fullName
        ? this.props.buyer.fullName
        : this.props.buyer.name;
    return (
      <tr>
        <td>
          <table width="100%" className="table table-striped buyerListTableRow">
            <tr>
              <td width="80%">
                <span className="buyerName">{buyerName}</span>
              </td>
              <td width="20%">
                <button
                  className="btn btn-info pull-right"
                  onClick={this.props.onAddInvite.bind(
                    this,
                    this.props.buyer,
                    this.props.id
                  )}
                >
                  {this.props.buyer.buttonText}
                </button>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    );
  }
}

class BuyerListTable extends Component {
  render() {
    const buyers =
      this.props.buyerList.items && this.props.buyerList.items.length > 0
        ? this.props.buyerList.items
        : [];
    const buyerSearchResults =
      this.props.buyerSearchResults.items &&
      this.props.buyerSearchResults.items.length > 0
        ? this.props.buyerSearchResults.items
        : [];
    const searchByPhone = this.props.searchByPhone;
    let rows =
      buyers.length > 0 && !searchByPhone ? (
        buyers.map((buyer, key) => (
          <BuyerListTableRow {...this.props} id={key} buyer={buyer} />
        ))
      ) : buyerSearchResults.length > 0 && searchByPhone ? (
        buyerSearchResults.map((buyer, key) => {
          return <BuyerListTableRow {...this.props} id={key} buyer={buyer} />;
        })
      ) : (
        <tr>
          <td>No results</td>
        </tr>
      );

    return (
      <table width="100%" className="table table-striped buyerListTable">
        {rows}
      </table>
    );
  }
}

class BuyerSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: ''
    };

    this.clickHandler = this.clickHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler(e) {
    let value = e.target.value.replace(/[\D]/, '');
    this.setState({
      searchText: value
    });
  }

  clickHandler(e) {
    let searchText = this.searchInput.value;
    if (searchText && searchText.length < 5) {
      return;
    } else {
      this.props.onSearch(searchText);
    }
    e.preventDefault();
  }

  render() {
    return (
      <div className="btn-toolbar form-inline">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={this.state.searchText}
            ref={input => (this.searchInput = input)}
            onChange={this.changeHandler}
            placeholder="Phone Number"
          />
          <span className="input-group-btn">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.clickHandler}
            >
              Search
            </button>
          </span>
        </div>
      </div>
    );
  }
}

class BuyerList extends Component {
  constructor(props) {
    super(props);

    this.addInviteHandler = this.addInviteHandler.bind(this);
    this.removeInviteHandler = this.removeInviteHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
  }
  componentDidMount() {
    buyerListData(this.props);

    buyerInviteListRequestData(this.props);

    var self = this;

    $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function(e) {
      let target = e.target.text;
      if (target === 'Your Buyers') {
        buyerListData(self.props);
        buyerInviteListRequestData(self.props);
      } else if (target === 'Search New Buyer') {
        buyerInviteListRequestData(self.props);
      }
    });
  }
  addInviteHandler(buyer, index) {
    let buttonText = 'Invited';
    buyerAddInviteRequestData(this.props, { buyer, buttonText, index });
  }
  removeInviteHandler(buyer, index) {
    let buttonText = 'Invite';
    buyerRemoveInviteRequestData(this.props, { buyer, buttonText, index });
  }
  searchHandler(searchText) {
    buyerSearchData(this.props, searchText);
  }
  render() {
    if (!this.props.buyerList.items) {
      return null;
    }
    // const buyerListItems = this.props.buyerList.items;
    const { invitedList, buttonText } = this.props.buyerInvites;
    const searchByPhone = this.props.searchByPhone;
    return (
      <div className="buyersContainer">
        {searchByPhone ? (
          <div className="buyerSearchWrapper">
            <BuyerSearch onSearch={this.searchHandler} />
          </div>
        ) : (
          ''
        )}

        <div className="buyerListWrapper">
          <BuyerListTable
            {...this.props}
            buttonText={buttonText}
            onAddInvite={this.addInviteHandler}
          />
        </div>
        <InvitedBuyerList
          invitedList={invitedList}
          onRemoveInvite={this.removeInviteHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { buyerList, buyerInvites, buyerSearchResults }
  } = state;

  return {
    buyerList,
    buyerInvites,
    buyerSearchResults
  };
};

export default connect(
  mapStateToProps,
  {
    loadBuyerList,
    loadBuyerInviteListRequest,
    loadBuyerInviteAddRequest,
    loadBuyerInviteRemoveRequest,
    loadBuyerSearch
  }
)(BuyerList);
