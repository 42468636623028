import React from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';
import { FastClick } from 'searchkit';

const NoHitsDisplayCustom = props => {
  const { searchkit, defaultSortOption, isSortedByEndingSoonest } = props;

  const {
    bemBlocks,
    filtersCount,
    noResultsLabel,
    query,
    resetFiltersFn,
    setSuggestionFn,
    suggestion,
    translate,
  } = props;

  const getSuggestionAction = () => {
    if (suggestion) {
      return (
        <FastClick handler={setSuggestionFn}>
          <div className={bemBlocks.container('step-action')}>
            {translate('NoHits.DidYouMean', { suggestion })}
          </div>
        </FastClick>
      );
    }
    return null;
  };

  const getResetFilterAction = () => {
    if (filtersCount > 0) {
      return (
        <FastClick handler={resetFiltersFn}>
          <div className={bemBlocks.container('step-action')}>
            {translate('NoHits.SearchWithoutFilters', { query })}
          </div>
        </FastClick>
      );
    }
    return null;
  };

  const getSuggestionSort = () => {
    if (isSortedByEndingSoonest()) {
      return (
        <FastClick
          handler={() => {
            const accessor = find(get(searchkit, 'accessors.accessors'), {
              key: 'sort',
            });

            if (accessor) {
              const resetSortOption =
                defaultSortOption === 'epochEndTime_asc'
                  ? '_score_desc'
                  : defaultSortOption;

              accessor.state = accessor.state.setValue(resetSortOption);
              // searchkit.performSearch(); // now handled in resetDistanceAndLocation()
            }

            props.resetDistanceAndLocation();
          }}
        >
          <div className={bemBlocks.container('step-action')}>RESET</div>
        </FastClick>
      );
    }
  };

  const text = query ? noResultsLabel : 'No results found';

  return (
    <div data-qa="no-hits" className={bemBlocks.container()}>
      <div className={bemBlocks.container('info')}>{text}</div>
      <div className={bemBlocks.container('steps')}>
        {getSuggestionAction() || getResetFilterAction() || getSuggestionSort()}
      </div>
    </div>
  );
};

NoHitsDisplayCustom.defaultProps = {
  resetDistanceAndLocation: () => {},
  userSelectedLocation: false,
  userSelectedDistance: '100000',
};

NoHitsDisplayCustom.propTypes = {
  resetDistanceAndLocation: PropTypes.func,
  userSelectedDistance: PropTypes.string,
  userSelectedLocation: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default NoHitsDisplayCustom;
