import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-modal';
import Select from 'react-select';
import { loadUserAlerts, loadAlertAdd } from '../../actions';
import {
  getConfig,
  isValidEmail,
  isValidPhoneNumber,
} from '../../utils/helpers';
import { alertTypes, deviceTypes } from '../../utils/constants';

const styles = {
  customStyles: {
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      overflow: 'visible', // allows dropdown to show up
      width: 650,
      minHeight: 300,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'auto',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  input: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: 6,
    cursor: 'default',
    color: '#787878',
  },
  dropdownStyle: {
    control: base => ({
      ...base,
      border: '1px solid #E8E8E8',
      boxShadow: 0,
      '&:hover': {
        border: '1px solid #E8E8E8',
      },
    }),
    singleValue: base => ({
      ...base,
      color: '#787878',
    }),
    option: base => ({
      ...base,
      cursor: 'pointer',
      color: '#787878',
      borderBottom: '1px solid #EAEAEA',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
      padding: 10,
    }),
  },
};

const customerDeviceTypes = getConfig('deviceTypes') || [1, 6];
const customerAlertTypes = getConfig('alertTypes') || [
  1004, 1005, 1006, 1007, 1008, 1016, 1028, 1029,
];

const initialState = {
  alertTypeId: undefined,
  deviceTypeId: undefined,
  deviceAddress: '',
};

class AddAlertModal extends Component {
  state = initialState;

  onOpen = () => {
    //
  };

  onClose = () => {
    this.setState(initialState, () => {
      this.props.onClose();
    });
  };

  updateSelectedAlertType = option => {
    this.setState({
      alertTypeId: option.value,
    });
  };

  updateSelectedDeviceType = option => {
    const { user } = this.props.userProfile;
    const deviceTypeId = option.value;
    let deviceAddress = '';

    switch (deviceTypeId) {
      case 1:
      case 2:
      case 3:
        deviceAddress = user['email'];
        break;
      case 4:
      case 6:
      case 7:
        deviceAddress = user['mobile'] || user['phone'];
        break;
      case 5:
        deviceAddress = user['fax'];
        break;
      default:
        break;
    }

    this.setState({
      deviceTypeId,
      deviceAddress,
    });
  };

  updatePhoneNumber = e => {
    const phone = e.target.value.replace(/\D/g, '');

    this.setState({
      deviceAddress: phone,
    });
  };

  updateEmailAddress = e => {
    const email = e.target.value;

    this.setState({
      deviceAddress: email,
    });
  };

  handleAddAlert = e => {
    e.preventDefault();
    const mpId = getConfig('marketplaceId');
    const { deviceTypeId, deviceAddress, alertTypeId } = this.state;

    let isValidDeviceAddress;
    let validationMessage = '';
    let formattedDeviceAddress = deviceAddress;

    switch (deviceTypeId) {
      case 1:
      case 2:
      case 3: {
        isValidDeviceAddress = isValidEmail(deviceAddress);
        if (!isValidDeviceAddress) validationMessage = 'Invalid Email';
        break;
      }
      case 4:
      case 5:
      case 6:
      case 7: {
        // TODO - validate all phone numbers coordinated with ias-connect.
        formattedDeviceAddress = deviceAddress.replace(/[^\d+]/g, '');
        isValidDeviceAddress = isValidPhoneNumber(formattedDeviceAddress);
        if (!isValidDeviceAddress) validationMessage = 'Invalid Phone Number';
        break;
      }

      default:
        break;
    }
    if (isValidDeviceAddress) {
      this.props
        .loadAlertAdd(mpId, deviceTypeId, formattedDeviceAddress, alertTypeId)
        .then(({ response }) => {
          if (response && response.wsStatus === 'Success') {
            toastr.success('Success', response.wsMessage);
            this.onClose();
            this.props.loadUserAlerts(getConfig('marketplaceId'));
            this.setState(initialState);
          } else {
            toastr.error('Error', response.wsMessage);
          }
        });
    } else {
      toastr.error('Validation Failed', validationMessage);
    }
  };

  renderAlertSelect() {
    const options = (alertTypes || [])
      .filter(item => customerAlertTypes.includes(item.id))
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      .map(item => ({ label: item.description, value: item.id }));

    return (
      <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
        <h4 style={{ marginBottom: 5 }}>Alert</h4>
        <Select
          styles={styles.dropdownStyle}
          placeholder="-- Select Alert --"
          options={options}
          onChange={this.updateSelectedAlertType}
          captureMenuScroll
        />
      </div>
    );
  }

  renderDeviceSelect() {
    const options = (deviceTypes || [])
      .filter(item => customerDeviceTypes.includes(item.id))
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .map(item => ({ label: item.description, value: item.id }));

    return (
      <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
        <h4 style={{ marginBottom: 5 }}>Alert Method</h4>
        <Select
          styles={styles.dropdownStyle}
          placeholder="-- Select Method --"
          options={options}
          onChange={this.updateSelectedDeviceType}
          captureMenuScroll
        />
      </div>
    );
  }

  renderInput() {
    const { deviceTypeId, deviceAddress } = this.state;

    if ([1, 2, 3].includes(deviceTypeId)) {
      return (
        <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
          <label>Email Address</label>
          <input
            style={styles.input}
            className="form-control"
            id="address"
            type="text"
            onChange={this.updateEmailAddress}
            value={deviceAddress}
          />
        </div>
      );
    }

    if ([4, 5, 6, 7].includes(deviceTypeId)) {
      return (
        <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
          <label>Phone Number</label>
          <input
            style={styles.input}
            className="form-control"
            id="address"
            type="text"
            onChange={this.updatePhoneNumber}
            value={deviceAddress}
          />
        </div>
      );
    }
  }

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-bell" />
          <span style={{ marginLeft: 10 }}>Add Alert</span>
        </div>
        <button type="button" className="close" onClick={this.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderFooter() {
    const { deviceTypeId, alertTypeId, deviceAddress } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          style={{ minWidth: 100 }}
          className="btn btn-primary button-cancel"
          data-dismiss="modal"
          onClick={this.onClose}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary button-action"
          style={{ marginLeft: 10, minWidth: 100 }}
          type="button"
          disabled={!deviceTypeId || !alertTypeId || !deviceAddress}
          onClick={this.handleAddAlert}
        >
          Create
        </button>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Add Alert Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div style={{ ...styles.container, height: 'auto' }}>
          {this.renderHeader()}
          {this.renderDivider()}

          {this.renderAlertSelect()}
          {this.renderDeviceSelect()}
          {this.renderInput()}

          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { userProfile } = state.entities;
  return { userProfile };
};

export default connect(mapStateToProps, {
  loadUserAlerts,
  loadAlertAdd,
})(AddAlertModal);
