import React, { Component } from 'react';

class CarIcon extends Component {
  render() {
    const { size = 24, color = '#fff' } = this.props;
    return (
      <svg
        width={size}
        height="16"
        viewBox="0 0 24 16"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.94551 0.5C3.94351 0.5 3.01801 1.001 2.46076 1.83575L0.375013 4.9535C0.129547 5.32112 -0.000991196 5.75346 1.28833e-05 6.1955V11.1642C-0.00136999 11.4972 0.108609 11.821 0.312459 12.0843C0.516309 12.3475 0.802323 12.535 1.12501 12.617L3.07051 13.1097C3.21043 13.7838 3.57792 14.3892 4.11133 14.8245C4.64473 15.2597 5.31159 15.4982 6.00001 15.5C7.38901 15.5 8.54851 14.5393 8.88301 13.25H15.867C16.2015 14.5393 17.361 15.5 18.75 15.5C20.139 15.5 21.2985 14.5393 21.633 13.25H22.5C23.3205 13.25 24 12.5705 24 11.75V9.008C23.9993 8.33949 23.7752 7.69039 23.3633 7.16383C22.9515 6.63727 22.3754 6.26344 21.7268 6.10175L17.6715 5.0705L14.742 1.5785C14.4618 1.24128 14.1108 0.969857 13.7139 0.783472C13.317 0.597087 12.884 0.500308 12.4455 0.5H4.94551ZM4.94551 2H8.25001V5H2.15626L3.70351 2.6795V2.65625C3.8394 2.45215 4.02411 2.28523 4.24087 2.17063C4.45763 2.05602 4.69958 1.99737 4.94476 2H4.94551ZM9.75001 2H12.4455C12.8903 2 13.3095 2.1965 13.5938 2.53925L15.6563 5H9.75001V2ZM1.50001 6.5H17.1563L21.375 7.5545C21.6987 7.63483 21.9859 7.82202 22.1901 8.08578C22.3942 8.34955 22.5034 8.67447 22.5 9.008V11.75H21.633C21.2985 10.4607 20.139 9.5 18.75 9.5C17.361 9.5 16.2015 10.4607 15.867 11.75H8.88301C8.54851 10.4607 7.38901 9.5 6.00001 9.5C4.67026 9.5 3.55351 10.379 3.16426 11.5858L1.50001 11.165V6.5ZM6.00001 11C6.83776 11 7.50001 11.6622 7.50001 12.5C7.50001 13.3378 6.83776 14 6.00001 14C5.16226 14 4.50001 13.3378 4.50001 12.5C4.50001 11.6622 5.16226 11 6.00001 11ZM18.75 11C19.5878 11 20.25 11.6622 20.25 12.5C20.25 13.3378 19.5878 14 18.75 14C17.9123 14 17.25 13.3378 17.25 12.5C17.25 11.6622 17.9123 11 18.75 11Z" />
      </svg>
    );
  }
}

export default CarIcon;
