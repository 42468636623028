import React, { Component } from 'react';

import Icon from '../../ui/icons';
import Button from '../../ui/buttons';

import { CYAN_01 } from '../../../constants/colors';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
  }

  render() {
    const { searchText } = this.state;
    return (
      <div className="sr-search-box-container">
        <div>
          <input
            type="text"
            value={searchText}
            onChange={e => this.setState({ searchText: e.target.value })}
            placeholder="Filter results"
          />
          <div
            className="flex justify-center items-center"
            style={{ visibility: searchText !== '' ? 'visible' : 'hidden' }}
            onClick={() => this.setState({ searchText: '' })}
          >
            <Icon name="Cross" size={24} color={CYAN_01} />
          </div>
        </div>
        <div className="flex justify-end items-center">
          <Button
            btnText="Search"
            height="43px"
            borderRadius="10px"
            outlined={true}
            iconName="search"
            marginRight="16px"
            iconSize={18}
            onClickHandler={() => alert('Searching...')}
          />
        </div>
      </div>
    );
  }
}

export default SearchBox;
