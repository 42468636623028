import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback3DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M679.2,166.3l-3.6-0.3c-30.3-1.2-60.1-1.6-89.6-1.2c-7.8,0.1-14.4,1.5-19.5,4c-6.6,1.9-12.4,2.9-17.5,2.9
	l-47.2-0.6l-1.6,2.2h6.2c48.7,0.1,95.3,1.1,139.6,3.2l11.3,0.6l11.1,0.8l13.9,1.4l17.1,1.4c8.3,0.4,15,0.4,20.5-0.1
	c-4.5-1.9-9.8-3.4-15.5-4.7C691.4,172.7,683,169.6,679.2,166.3L679.2,166.3z"
          />
          <path
            fill="#FFFFFF"
            d="M682.2,179.1l-13.9-1.4l-11.1-0.8l-11.3-0.6c-44.3-2.1-90.9-3-139.6-3.2H500c-1,0.8-1.9,1.8-2.6,2.8
	c-1.1,1.4-1.8,2.9-2.3,4.5l-0.3,0.8v0.3h8.5c25-0.3,45.1,1.2,60,4.7c17.3,5.2,25.3,8.9,23.8,11.4c-0.6,0.4-1.5,1-2.9,1.4l-2.3,0.7
	c-4.4,1.2-11.1,2.3-20.4,3.6c-12.8,1.6-26.4,2.8-40.9,3.3h-0.8c-6.9,0.3-13.9,0.4-21.2,0.4h-13.9c-35.6-0.1-68.4-1.1-97.9-2.6
	c-2.1,0-3.9,0.3-5.5,0.6c-5.9,1.2-9.4,4.4-10.7,9.5l2.1,0.4c33.3,2.5,69.3,4,108.4,4.5c24.1,1,47.9,1.4,71.3,1.2
	c19.7-0.1,39.1-0.6,58.3-1.2c25.5-0.8,51.6-2.2,78.4-3.9l42-2.6c-6.9-11.8-17.6-22.8-32.3-32.7L682.2,179.1L682.2,179.1z
	 M684.8,192.3l-0.1,0.3c-0.1,0.1-0.4,0.3-0.5,0.3H684l-5.2,0.3c-0.8-0.1-1.5,0.1-2.3,0.6v0.1c-0.4,0.3-1,0.3-1.5,0.1
	c-0.5-0.1-1-0.6-1.4-1.1c-0.4-0.6-0.6-1.1-0.6-1.6s0.3-1,0.7-1.4c0.4-0.3,0.8-0.4,1.5-0.3l0.6,0.3l0.4,0.3c0.7,0.4,1.4,0.7,2.2,0.7
	l5.6,0.3h0.3c0.1,0,0.3,0.1,0.4,0.3l0.3,0.4h16.6l0.1-0.1h2.3l0.1,0.1v0.6l-0.1,0.1h-2.2l-0.1-0.1L684.8,192.3L684.8,192.3z"
          />
          <path
            fill="#FFFFFF"
            d="M684.8,192.3h16.6l0.3,0.1h2.2l0.3-0.1v-0.6h-19.4L684,192"
          />
          <path
            fill="#FFFFFF"
            d="M684.7,191.4c-0.1-0.1-0.3-0.1-0.4-0.3H684c-0.1,0-0.3,0.1-0.4,0.3c-0.1,0.1-0.3,0.4-0.3,0.6
	c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3h0.1c0.3,0,0.4-0.1,0.5-0.3l0.1-0.3l-0.7-0.4l0.8-0.1L684.7,191.4L684.7,191.4z
	 M684.2,191.2H684"
          />
          <path
            fill="#FFFFFF"
            d="M683.3,192c0-0.3,0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.3l-5.6-0.3c-0.8,0-1.5-0.3-2.2-0.7l-0.4-0.3
	l-0.5-0.3c-0.5-0.1-1.1-0.1-1.5,0.3c-0.4,0.3-0.7,0.7-0.7,1.4c0,0.6,0.1,1.1,0.5,1.6c0.4,0.6,0.8,1,1.4,1.1c0.5,0.1,1.1,0.1,1.5-0.1
	c0.8-0.6,1.5-0.7,2.3-0.6l5.2-0.3c-0.1,0-0.4-0.1-0.5-0.3C683.3,192.4,683.3,192.3,683.3,192L683.3,192z M676.4,193.8v-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M679.2,166.3c3.9,3.3,12.2,6.3,25,9.2c5.9,1.2,11.1,2.8,15.5,4.7c6.6,2.9,11.6,6.5,15.1,10.9
	c7.3,8.3,14.2,21.6,20.8,39.8c5,16.1,7.8,34.4,8.7,54.9v24.9c-0.8,20.5-3.7,38.8-8.7,54.9c-6.6,18.3-13.5,31.5-20.8,39.9
	c-3.6,4.4-8.5,8-15.1,10.7c-4.5,1.9-9.8,3.6-15.5,4.7c-12.8,2.9-21.2,5.9-25,9.2c9.8-0.8,18.2-2.6,25.2-5.2c5.4-1.8,10.7-3.4,16-4.5
	l2.1-0.4c8.4-2.1,12.9-5.2,13.6-9.2c1.4-2.9,4.1-5.9,8.1-8.8c2.9-2.1,6.1-8,9.4-18.2c5.9-22,9.8-45.1,11.6-69.3
	c0.3-4.4,0.4-8.8,0.4-12.9v-6.5c0-4.1-0.1-8.4-0.4-12.9c-1.8-24.2-5.6-47.3-11.6-69.3c-3.3-10-6.5-16.1-9.4-18.2
	c-4-3-6.7-5.9-8.1-8.8c-0.7-4.1-5.2-7.2-13.6-9.2l-2.1-0.4c-5.4-1.2-10.7-2.8-16-4.5C697.3,168.9,688.9,167.1,679.2,166.3
	L679.2,166.3z"
          />
          <path
            fill="#FFFFFF"
            d="M717.7,190.8c-2.1-2.5-2.6-3.9-1.7-4.3l0.7-0.1l0.4,0.1l5.1-0.3c2.3,0,4.1,0.1,5.6,0.6
	c1.2,0.3,2.2,0.8,2.9,1.4c1,0.8,2.1,1.9,3.2,3.3c2.6,3.3,5.4,8.3,8.5,14.9c3,7,5.8,15.3,8.5,24.6h4.8c-6.6-18.3-13.5-31.5-20.8-39.8
	c-3.6-4.4-8.5-8-15.1-10.9c-5.4,0.6-12.2,0.6-20.5,0.1c14.6,9.9,25.3,20.9,32.2,32.7l4-0.1l-0.5-1
	C729.2,203.4,723.4,196.4,717.7,190.8L717.7,190.8z"
          />
          <path
            fill="#EBEBEB"
            d="M716.2,186.5c-1,0.4-0.4,1.8,1.7,4.3c5.8,5.6,11.4,12.8,17.2,21.3l-0.3-1.5c-2.2-10.6-4.4-17.1-6.5-19.4
	C725.6,188.7,721.7,187.2,716.2,186.5L716.2,186.5z"
          />
          <path
            fill="#D2D3D4"
            d="M716.7,186.4l-0.7,0.1c5.5,0.7,9.6,2.2,12.1,4.7c2.1,2.3,4.1,8.8,6.5,19.4c1.2-1.5,1-7.2-0.8-16.9
	c-1.7-2.2-3.3-3.6-5-3.9l-0.4-1.5c-4-0.6-6.1-1.2-6.1-2.1l-5.1,0.3L716.7,186.4L716.7,186.4z"
          />
          <path
            fill="#EBEBEB"
            d="M730.7,188c-0.7-0.6-1.7-1.1-2.9-1.4l0.4,1.5c1.7,0,3.6,1.1,5.5,3.2C732.8,189.9,731.7,188.8,730.7,188
	L730.7,188z"
          />
          <path
            fill="#D2D3D4"
            d="M728.4,188.1l0.4,1.5c1.7,0.3,3.3,1.5,5,3.9c1,1.4,1.9,2.9,2.9,4.8l0.7,1.8c2.5,3.6,5.2,9.6,8.5,18.4
	l3.4,11.1l0.8,1.1h0.7c-2.6-9.5-5.5-17.6-8.5-24.6c-3-6.6-5.9-11.6-8.5-14.9C731.8,189.2,730,188.1,728.4,188.1L728.4,188.1z"
          />
          <path
            fill="#EBEBEB"
            d="M728.4,188.1l-0.4-1.5c-1.5-0.4-3.4-0.6-5.6-0.6C722.3,187,724.4,187.7,728.4,188.1z M737.3,200.1l-0.7-1.8
	c-1-1.9-1.9-3.6-2.9-4.8c1.8,9.8,2.1,15.4,0.8,16.9l0.3,1.5l0.5,1l2.6,0.4c1.2,0.4,2.5,2.1,4,4.7l4.3,7.8
	C743.4,215.9,740.4,207.4,737.3,200.1L737.3,200.1z"
          />
          <path
            fill="#FFFFFF"
            d="M722.3,176.6c8.4,2.1,12.9,5.1,13.6,9.2c1.4,2.9,4.1,5.9,8.1,8.8c2.9,2.1,6.1,8,9.4,18.2l-0.7-2.6l-0.1-1
	c-0.5-6.9-2.9-13.8-6.9-20.9c-2.9-5-9.6-8.7-20.1-11L722.3,176.6L722.3,176.6z M738.2,213.4l-2.6-0.4l-4,0.1c2.2,3.9,4,7.7,5.4,11.7
	c5,14.3,8.4,28.1,10.6,41.4c-0.5-11.3,0.7-15.7,3.7-13.3l5,6.3c-1.5-9.8-3.6-19.3-6.2-28.5l-0.8-1.1l-2.9-3.7l-4.3-7.8
	C740.6,215.4,739.4,213.9,738.2,213.4L738.2,213.4z"
          />
          <path
            fill="#FFFFFF"
            d="M737.1,224.9c-1.4-4-3.2-7.8-5.4-11.7l-42,2.6c5.5,18.7,9.2,38.1,10.9,58.1l0.4,6.2
	c0.3,6.2,0.4,12.4,0.4,18.6c0,5.9-0.1,12-0.5,18l-0.4,6.1c-1.7,19.9-5.2,39.3-10.9,58l20.5,1.4l21.5,1.2c2.2-3.9,4-7.7,5.4-11.7
	c5-14.3,8.4-28.1,10.6-41.4l1.2-8.5l0.5-4.5c0.4-4.3,0.7-8.5,0.8-12.8v-12.2c-0.1-4.1-0.4-8.4-0.8-12.7l-0.5-4.5
	c-0.3-2.9-0.8-5.6-1.2-8.5C745.5,252.9,741.9,239.2,737.1,224.9L737.1,224.9z"
          />
          <path
            fill="#EBEBEB"
            d="M746.4,226l2.9,3.7l-3.4-11.1c-3.3-8.8-6.1-14.9-8.5-18.4C740.4,207.4,743.4,215.9,746.4,226L746.4,226z"
          />
          <path
            fill="#FFFFFF"
            d="M755.6,230.9h-5.5c2.6,9.2,4.7,18.8,6.2,28.5c1.9,12.5,3,25.5,3.2,38.8c-0.3,13.3-1.2,26.3-3.2,38.8
	c-1.5,9.8-3.6,19.3-6.2,28.6h5.5c5-16.1,7.8-34.4,8.7-54.9v-24.9C763.5,265.3,760.6,247,755.6,230.9L755.6,230.9z M92.4,223.4
	l-3.7,7.2v0.4l0.1,0.1h0.3l0.1-0.1l1.9-3.7C91.8,225.8,92,224.6,92.4,223.4L92.4,223.4z"
          />
          <path
            fill="#FFFFFF"
            d="M92.4,223.4c-0.4,1.2-0.8,2.5-1.1,3.9l-1.1,4l-0.8,3.2c-1.4,5.6-2.6,11.7-3.7,18.4L96,242.5
	c4.5-14.4,9.4-25.7,14.3-33.8l-13.9,8.8c5-11.6,12.2-20.9,22-28.1c9.2-6.7,20.4-11.6,33.8-14.3c10.3-2.6,21-6.2,32.5-10.9
	c-9.8,0.4-18.6,1.4-26.8,2.9l-11.8,1.9c-9.4,1.5-16.5,3.3-21.7,5.8c-7.6,3.7-14.3,8.5-20.4,14.6c-5.8,5.9-10.7,12.8-15,20.9
	c5.4-8.3,11.6-15.1,18.4-20.9c7-5.8,14.7-10.2,23.2-13.3c1.8-1,4-1.9,6.3-2.6l1.2-0.4c1.1,0.1,1.5,0.6,1.1,1.1s-1.5,1.5-3.2,2.6
	l-1.4,1c-2.2,1.2-5,2.8-8.5,4.7c-1.4,0.6-2.6,1.1-4,1.8c-3,1.5-5.9,3.3-8.5,5.4c-7.8,6.1-13.8,14-17.7,24.4l-1.1,2.3v0.1
	c-0.8,2.2-1.7,4.4-2.5,6.9L92.4,223.4L92.4,223.4z"
          />
          <path
            fill="#FFFFFF"
            d="M92.4,223.4l0.1-0.3c0.8-2.5,1.5-4.7,2.5-6.9l-7,14.2c-1.4,3.2-2.3,7.4-2.9,12.7
	c-2.8,21.9-4.1,40.3-4.4,55.3c0.3,15,1.7,33.3,4.4,55.2c0.6,5.2,1.5,9.5,2.9,12.7l6.9,14c-0.8-2.2-1.7-4.4-2.5-6.9v-0.1l-0.1-0.1
	l-3.6-7.2v-0.3c-1.1-4.5-2.1-9.6-2.8-15c-0.8-5.6-1.7-12.9-2.2-21.6v-0.3c-0.7-6.7-1.1-13.5-1.4-20.4v-20.2
	c0.3-6.9,0.7-13.6,1.4-20.4v-0.1c0.7-8.8,1.4-16,2.2-21.6c0.7-5.4,1.7-10.5,2.8-15v-0.1v-0.3L92.4,223.4L92.4,223.4z"
          />
          <path
            fill="#FFFFFF"
            d="M94.9,216.2l1-2.5l-12.4,8.8c-0.6,1.5-0.3,2.3,0.7,2.6c-4.7,20.5-7.2,44.8-7.7,73c0.6,28.2,3.2,52.5,7.7,73
	c-1,0.3-1.2,1.1-0.7,2.6l12.4,8.8l-1-2.5l-7-14.2c-1.4-3.2-2.3-7.3-2.9-12.7c-2.8-21.7-4.1-40.2-4.4-55.2c0.3-15,1.7-33.4,4.4-55.3
	c0.6-5.2,1.5-9.5,2.9-12.7L94.9,216.2L94.9,216.2z M89,210.3c-2.1,3.9-3.9,7.8-5.5,12.2l12.4-8.8c4-10.2,9.9-18.3,17.7-24.4
	c2.6-2.1,5.5-3.7,8.5-5.4c1.4-3.2,4.3-5.8,8.5-8.1c-8.5,3.2-16.2,7.7-23.2,13.3C100.6,195.2,94.4,202,89,210.3L89,210.3z"
          />
          <path
            fill="#FFFFFF"
            d="M89,231.1l-0.1-0.1c-1.1,4.5-2.1,9.6-2.8,15c-0.8,5.6-1.7,12.9-2.2,21.6c0.6-5,1.1-9.9,1.9-14.7
	c0.8-6.6,1.7-12,2.5-16.1H88l-0.1-0.3v-0.3l1-1.9l0.8-3.4l-0.3,0.1H89L89,231.1L89,231.1z"
          />
          <path
            fill="#FFFFFF"
            d="M90.2,231.2l-1.7,3.2l-1,1.9v0.3l0.1,0.3H88l0.1-0.1l1.2-2.3L90.2,231.2L90.2,231.2z M88.6,234.4l1.7-3.2
	l1.1-4l-1.9,3.7"
          />
          <path
            fill="#FFFFFF"
            d="M88.3,236.6l-0.1,0.1c-0.8,4.1-1.7,9.4-2.5,16.1c1.1-6.6,2.3-12.8,3.7-18.4L88.3,236.6L88.3,236.6z
	 M96,242.5l2.5-1c5-12.9,10-23.8,15-32.9h-3C105.2,216.8,100.6,228,96,242.5L96,242.5z"
          />
          <path
            fill="#FFFFFF"
            d="M98.4,241.5l-2.5,1l-10.3,10.3c-0.8,5-1.4,9.8-1.9,14.7v0.1c-0.7,6.7-1.1,13.5-1.4,20.4v20.2
	c0.3,6.9,0.7,13.6,1.4,20.4v0.3c0.6,5,1.1,9.9,1.9,14.7l10.3,10.2l2.5,1l9.5,8.8c-2.3-5.2-4.4-11.1-6.1-17.6
	c-4.7-2.3-7.7-6.7-8.9-13.1c-0.8-4.1-1.5-8.4-1.9-13.2c-0.3-2.8-0.4-5.6-0.6-8.7v-1.1l-0.7-0.3l-0.8-0.8l-0.7-1.1l-0.6-1.2
	c-0.3-1.1-0.6-2.1-0.7-3.2c-0.3-1.2-0.3-2.6-0.4-4c0-1.5,0-2.9,0.1-4.3c0.1-1.2,0.4-2.3,0.7-3.4c0.3-1.1,0.6-1.9,1-2.6
	c0.3-0.4,0.4-0.8,0.7-1.1l0.6-0.4c0.1-0.1,0.1-0.1,0.3-0.1l0.3-0.1c0-1.8,0.1-3.6,0.3-5.2l0.4-5.5v-0.1c0.4-4.7,1.1-9.1,1.9-13.1
	c1.2-6.5,4.3-10.7,8.9-13.1c1.7-6.5,3.6-12.4,6.1-17.6L98.4,241.5L98.4,241.5z"
          />
          <path
            fill="#EBEBEB"
            d="M98.4,241.5l9.5-8.8l1.8-3c1-1.5,1.8-2.9,2.8-4.4c1-2.9,2.5-5.9,4.5-9.4c1.8-2.9,4-5.8,6.5-9.1
	c-2.5-0.1-5,0.1-7.3,0.8l-2.1,0.6l-0.7,0.3C108.4,217.7,103.3,228.7,98.4,241.5L98.4,241.5z"
          />
          <path
            fill="#FFFFFF"
            d="M107.8,232.7c-2.3,5.2-4.4,11.1-6.1,17.6c-2.6,10.5-4.1,22.4-4.8,36l-0.1,6.9v10.5c0.1,2.3,0.1,4.7,0.3,7
	c0.6,13.3,2.2,25,4.7,35.4c1.7,6.5,3.6,12.4,6.1,17.6l1.8,2.9c12.4,3.7,24.6,7.2,36.9,10.3c39.9,10.3,78.6,17.6,116.1,21.9
	c-4.7-7.4-8.7-15.8-12.1-24.9c-6.3-16.8-10.6-36.5-12.7-59l-0.7-17.1c0-2.6,0-5.2,0.1-7.7c0-2.5,0.1-5,0.3-7.3c0-0.4,0-0.8,0.1-1.1
	c2.1-22.6,6.3-42.2,12.7-59c3.4-9.1,7.4-17.5,12.1-24.9l-0.1-0.1c-37.4,4.3-76.1,11.6-116,21.9c-12.1,3.2-24.5,6.6-36.9,10.3
	L107.8,232.7L107.8,232.7z M168.9,175.9c0.7-0.1,1,0,1.1,0.1c31.4-3.6,74-4.5,128.1-2.9h1c-1-3.2-0.8-4.5,0.7-4.1
	c-7.3-2.8-16.8-4.4-28.9-5c-30-1-57.5-1-82.4,0l-3.7,0.1c-11.4,4.5-22.1,8.1-32.5,10.9c-13.3,2.9-24.6,7.6-33.8,14.3
	c-9.8,7.2-17.1,16.5-22,28.1l13.9-8.8h2.2c4.1-7.7,9.4-14.2,15.4-19.3c2.7-2.3,5.6-4.4,8.8-6.2C146.1,177.8,156.8,175.3,168.9,175.9
	L168.9,175.9z"
          />
          <path
            fill="#EBEBEB"
            d="M170,176c-0.1-0.1-0.6-0.3-1.1-0.1c-12-0.4-22.7,2.1-32.2,7.3c-3.2,1.8-6.1,3.9-8.8,6.2
	c-6.1,5.1-11.1,11.6-15.4,19.3h0.8l0.7-0.3c5-8.4,10.2-14.9,15.8-19.1c1.4-1.1,2.9-2.1,4.4-2.9c0.6-0.4,1.2-0.7,1.8-1
	c2.9-2.2,6.9-4,11.7-5.4c4.3-1.1,9.2-1.6,14.9-1.6l5.8-0.8C169.8,176.9,170.3,176.3,170,176L170,176z"
          />
          <path
            fill="#FFFFFF"
            d="M170,176c0.3,0.3-0.3,0.8-1.5,1.8l-0.8,0.6c-0.1,0-0.1,0.1-0.3,0.1c-3.2,2.3-9.1,6.2-17.6,11.4l-4.8,2.9
	l1.5-0.1c27.6-1.6,57.6-2.5,89.8-2.8c7.3,0.1,14.3,1.6,20.9,4.8l5.1,3h0.1c8.8-8.8,21.9-13.3,39.2-13.6v-3l-2.9-7.8h-1
	C244,171.5,201.4,172.3,170,176L170,176z"
          />
          <path
            fill="#EBEBEB"
            d="M167.7,178.4l0.8-0.6l-5.8,0.8l-2.1,0.4c-6.9,1.5-12.9,4.1-18,8l-2.9,2.5l-0.4,0.4l-0.8,0.8h4.3l1.4-1.2
	c6.6-5.4,14.3-8.9,23.2-10.9C167.6,178.4,167.6,178.4,167.7,178.4L167.7,178.4z"
          />
          <path
            fill="#EBEBEB"
            d="M160.7,178.9l2.1-0.4c-5.6,0-10.6,0.6-14.9,1.6c-5,1.5-8.8,3.3-11.7,5.4c2.1-1,4.1-1.6,6.2-2.1
	C146.8,181.5,152.8,180,160.7,178.9L160.7,178.9z M149.8,189.8c8.5-5.2,14.4-9.1,17.6-11.4c-8.9,1.9-16.6,5.5-23.2,10.9l-1.4,1.2
	h-4.3l-1.9,1.9c-5.2,5.2-9.5,10-13.1,14.6c-2.6,3.2-4.7,6.2-6.5,9.1c5-6.6,10-12,15-16.1c3-2.8,5.4-3.9,7.3-3.4
	c1.9-1.4,3.7-2.6,5.6-3.9L149.8,189.8L149.8,189.8z"
          />
          <path
            fill="#FFFFFF"
            d="M134.8,177.7l1.5-1v-0.8C135.1,176.2,134.7,176.7,134.8,177.7z M136.3,175.9v0.8c1.6-1.1,2.8-1.9,3.2-2.6
	c-2.2,0-3.2,0.3-3.2,0.8V175.9L136.3,175.9z"
          />
          <path
            fill="#FFFFFF"
            d="M134.8,177.7c-0.1-1,0.3-1.5,1.5-1.8v-0.8c0-0.6,1-0.8,3.2-0.8c0.4-0.7,0-1.1-1.1-1.1l-1.2,0.4
	c-7.2,3.3-10.7,6.3-10.9,8.9C129.9,180.4,132.6,178.9,134.8,177.7L134.8,177.7z"
          />
          <path
            fill="#FFFFFF"
            d="M137.1,173.3c-2.5,0.8-4.5,1.6-6.3,2.6c-4.3,2.2-7,5-8.5,8.1c1.2-0.7,2.6-1.2,4-1.8
	C126.4,179.6,130,176.7,137.1,173.3L137.1,173.3z"
          />
          <path
            fill="#EBEBEB"
            d="M142.9,185.4l-0.3,1.5c5.1-3.9,11.1-6.5,18-8c-7.7,1.1-13.9,2.6-18.3,4.5
	C142.8,183.7,143.1,184.4,142.9,185.4L142.9,185.4z"
          />
          <path
            fill="#EBEBEB"
            d="M142.7,186.9l0.3-1.5c-1.1,0-2.2,0.3-3.3,0.6l-0.1,3.9l0.4-0.4L142.7,186.9L142.7,186.9z M138.5,190.6
	l0.8-0.8l0.1-3.9c-1,0.3-1.8,0.4-2.6,0.8l-0.3,5.8L138.5,190.6L138.5,190.6z"
          />
          <path
            fill="#FFFFFF"
            d="M146.5,192.7l-1.5,0.1c-1.9,1.2-3.9,2.5-5.6,3.9c-10.5,7.7-19.4,17.3-27,28.9c-1,1.4-1.8,2.9-2.8,4.4
	c12.4-3.7,24.6-7.2,36.9-10.3c39.9-10.3,78.6-17.6,116-21.9l-5.1-3c-6.6-3.2-13.6-4.7-20.9-4.8C204,190.1,174.2,191,146.5,192.7z"
          />
          <path
            fill="#EBEBEB"
            d="M139.5,185.9c1.1-0.3,2.2-0.4,3.3-0.6c0.1-1.1-0.1-1.6-0.6-1.9c-2.1,0.4-4.1,1.1-6.2,2.1
	c-0.7,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.4,2.9c-5.6,4.3-10.9,10.6-15.8,19.1l2.1-0.6c4.8-8.7,10-14.9,15.5-18.4
	c1.6-1.1,3.4-2.1,5.2-2.6C137.8,186.4,138.7,186.1,139.5,185.9L139.5,185.9z"
          />
          <path
            fill="#EBEBEB"
            d="M136.6,192.5l0.3-5.8c-1.8,0.7-3.4,1.5-5.2,2.6c-5.5,3.7-10.7,9.9-15.5,18.4c2.5-0.6,5-0.8,7.3-0.8
	C127.1,202.6,131.5,197.8,136.6,192.5L136.6,192.5z M139.4,196.5c-1.9-0.4-4.3,0.7-7.3,3.4c-5,4.1-10,9.5-15,16.1
	c-2.1,3.4-3.7,6.5-4.5,9.4C120,213.9,129,204.2,139.4,196.5L139.4,196.5z"
          />
          <path
            fill="#FFFFFF"
            d="M316.7,150.7c-0.7,1.2-1.2,2.5-1.8,3.9c-2.2,6.5-3.2,11.4-2.9,14.9c0.1,0.8,0.3,1.5,0.4,2.2
	c0.3,0.6,0.5,1.1,0.8,1.5c0.3,0.4,0.7,0.8,1.1,1.2c1.4,1.1,3.3,1.8,5.8,1.9c2.3,0.3,4-0.3,4.8-1.5l7.8-32.3
	c-6.6-0.4-11.3,2.2-13.9,7.8C318,151.4,317.2,151.5,316.7,150.7L316.7,150.7z"
          />
          <path
            fill="#656565"
            d="M333,141.6l-0.3,0.8l-7.8,32.3l1.6-0.3l0.4-1.4l0.8-3.4l6.6-27.5"
          />
          <path
            fill="#EBEBEB"
            d="M332.8,142.5l0.3-0.8l0.1-0.8c-7.2-0.7-12.7,2.6-16.5,9.9c0.5,0.8,1.4,0.7,2.2-0.4
	C321.5,144.7,326.2,142.1,332.8,142.5L332.8,142.5z"
          />
          <path
            fill="#FFFFFF"
            d="M327.8,169.7l-0.8,3.4h135.2h-0.3l-10-1c0.5-0.8,1.2-1.5,2.3-1.9l-23-0.4l-12.4,0.1
	C387.8,170.4,357.5,170.3,327.8,169.7L327.8,169.7z"
          />
          <path
            fill="#FFFFFF"
            d="M323.5,179.2l-0.1,1.1l171.4,1.1v-0.3l0.3-0.8c0.6-1.6,1.4-3.2,2.3-4.5c0.7-1,1.6-1.9,2.6-2.8h-25.2
	c-4.1,0.3-8.4,0.3-12.5,0H327l-0.3,1.4l-1.6,0.3C324.2,176.4,323.7,178,323.5,179.2L323.5,179.2z"
          />
          <path
            fill="#F2F2F2"
            d="M323.5,180.4l0.1-1.1c-0.3,0.7-1.2,1-2.8,1.1c-4.4-0.3-6.5-1.1-6.3-2.6c-0.1,0.7-0.4,1.4-1,2.1
	c-1,1.4-1.9,2.1-2.9,2.1c-4,0.7-7,1.9-8.9,3.7l27.1,8.5l6.6,0.6v-1.4c-1.8-2.5-3.3-4.7-4.3-6.6c-0.5-1.1-1.1-2.2-1.4-3.2l-0.1-0.4
	c-0.5-1.4-2.6-1.9-6.2-1.5l0.1,1l-0.1-1L323.5,180.4L323.5,180.4z"
          />
          <path
            fill="#FFFFFF"
            d="M323.5,180.4l0.1,1.1c3.6-0.4,5.6,0.1,6.2,1.5l165.2,0.4v-2.1L323.5,180.4L323.5,180.4z"
          />
          <path
            fill="#656565"
            d="M320.9,180.3c1.5-0.1,2.5-0.4,2.8-1.1c0.1-1.4,0.7-2.9,1.4-4.4c-0.8,1.2-2.3,1.8-4.8,1.5
	c-2.5-0.1-4.4-0.8-5.8-1.9c0.4,1.2,0.4,2.3,0.1,3.4C314.3,179.2,316.4,180,320.9,180.3L320.9,180.3z"
          />
          <path
            fill="#FFFFFF"
            d="M329.7,183.1l0.1,0.4c0.3,1,0.8,2.1,1.4,3.2l151.3,0.4l-0.3-1.9H495v-1.7L329.7,183.1L329.7,183.1z
	 M313.4,173.1c-0.4-0.6-0.7-1-0.8-1.5c-0.3-0.7-0.3-1.4-0.4-2.2l-12.4-0.3c-1.5-0.6-1.6,0.8-0.7,4.1h14.3L313.4,173.1L313.4,173.1z"
          />
          <path
            fill="#FFFFFF"
            d="M301.8,181v4.5c1.9-1.9,5-3.2,8.9-3.7c1,0,1.9-0.7,2.9-2.1c0.4-0.7,0.8-1.4,1-2.1c0.3-1.1,0.3-2.2-0.1-3.4
	c-0.4-0.3-0.8-0.7-1.1-1.2h-14.3L301.8,181L301.8,181z"
          />
          <path
            fill="#F2F2F2"
            d="M329.1,194.2l-27.1-8.5v-1.5c-17.3,0.3-30.4,4.8-39.2,13.6h-0.1l17.5-2.9l4.3,0.4c5.5,0.7,12.1,2.2,20.1,4.7
	c21.9,5.8,43.5,10.6,64.7,14.4l2.1,0.4c1.2-5.1,4.8-8.3,10.7-9.5C364.5,202.9,346.9,199.1,329.1,194.2L329.1,194.2z"
          />
          <path fill="#FFFFFF" d="M262.5,197.6v0.1l0.1-0.1" />
          <path
            fill="#FFFFFF"
            d="M284.2,195.2l-4.3-0.4l-17.5,2.9c-4.7,7.4-8.7,15.8-12.1,24.9C259.6,204.1,270.9,195,284.2,195.2
	L284.2,195.2z"
          />
          <path
            fill="#EBEBEB"
            d="M284.2,195.2c-11.6,22.7-19,51.4-22.1,86.5v0.1c-0.4,3-0.7,6.2-0.8,9.4l-0.1,10.3l0.1,3.3
	c0.1,3.2,0.4,6.3,0.8,9.4v0.1c3.2,35.1,10.6,64,22.1,86.5c5.5-0.7,12.1-2.2,20.1-4.7c21.9-5.8,43.5-10.6,64.7-14.4l2.1-0.4l-0.4-3.2
	c-3.4-25.3-5.4-49.2-5.6-71.7v-17.3c0.3-22.4,2.2-46.4,5.6-71.7l0.4-3.2l-2.1-0.4c-21.3-3.9-42.8-8.7-64.7-14.4
	C296.3,197.4,289.7,195.8,284.2,195.2L284.2,195.2z M284.8,210.4c2.5-5.2,7.2-7.4,14-6.7c20.2,3.4,41.1,7.7,62.6,12.8
	c4.3,1.2,6.3,4.4,5.9,9.4l-0.4,2.6c-2.9,21.7-4.4,42.1-4.8,61.1v17.3c0.3,19.1,1.9,39.5,4.8,61.1l0.4,2.6c0.3,5-1.6,8.1-5.9,9.4
	c-21.5,5-42.2,9.2-62.6,12.8c-6.9,0.7-11.6-1.5-14-6.6c-10.3-19.5-16-48.8-16.8-87.9C268.8,259.1,274.4,229.8,284.8,210.4
	L284.8,210.4z"
          />
          <path
            fill="#EBEBEB"
            d="M298.8,203.7c-6.9-0.7-11.6,1.5-14,6.7c-10.3,19.5-16,48.8-16.8,87.9c1,39.1,6.5,68.4,16.8,87.9
	c2.5,5.1,7.2,7.3,14,6.6c20.2-3.4,41.1-7.7,62.6-12.8c4.3-1.2,6.3-4.4,5.9-9.4l-0.4-2.6c-2.9-21.7-4.4-42.1-4.8-61.1v-17.3
	c0.3-19.1,1.9-39.5,4.8-61.1l0.4-2.6c0.3-5-1.6-8.1-5.9-9.4C339.9,211.4,319,207.1,298.8,203.7L298.8,203.7z"
          />
          <path
            fill="#F2F2F2"
            d="M284.2,195.3c-13.3-0.3-24.6,8.8-33.7,27.4c-6.3,16.8-10.6,36.6-12.7,59l-0.4,8.4c-0.1,2.6-0.1,5.1-0.1,7.7
	l0.7,17.1c2.1,22.4,6.3,42.1,12.7,59c9.1,18.6,20.4,27.6,33.7,27.4c-11.6-22.7-19-51.5-22.1-86.5v-0.1c-0.4-3.2-0.7-6.2-0.8-9.4
	l-0.1-3.3l0.1-10.3c0.1-3.2,0.4-6.3,0.8-9.4v-0.1C265.4,246.7,272.7,217.9,284.2,195.3L284.2,195.3z"
          />
          <path
            fill="#FFFFFF"
            d="M381.7,205.2c1.6-0.4,3.4-0.6,5.5-0.6c-16.6-2.2-33.8-5.5-51.6-9.9l-6.6-0.6
	C346.9,199.1,364.5,202.9,381.7,205.2L381.7,205.2z"
          />
          <path
            fill="#EBEBEB"
            d="M335.5,193.4v1.4c17.7,4.4,34.9,7.7,51.6,9.9c29.6,1.5,62.3,2.5,97.9,2.6l-2.6-20.2l-151.3-0.4
	C332.4,188.7,333.7,190.9,335.5,193.4L335.5,193.4z"
          />
          <path
            fill="#FFFFFF"
            d="M373.1,215.1l-2.1-0.4l-0.4,3.2l2.5,0.6c33.3,2.5,69.3,4,108.4,4.5c24.1,1,47.9,1.1,71.4,0.7l-0.1-2.8
	c-23.5,0-47.2-0.4-71.3-1.2C442.5,219.1,406.4,217.6,373.1,215.1L373.1,215.1z"
          />
          <path
            fill="#FFFFFF"
            d="M373.1,218.4l-2.5-0.6c-3.4,25.3-5.4,49.2-5.6,71.7v17.3c0.3,22.4,2.2,46.4,5.6,71.7l2.5-0.5
	c33.3-2.5,69.3-4,108.4-4.5c24.1-1,47.9-1.2,71.4-0.7c0.8-17.6,1.4-35.9,1.7-54.9l0.1-19l-0.1-20.4c-0.3-19-0.8-37.3-1.7-54.9
	c-23.5,0.4-47.3,0.1-71.4-0.7C442.5,222.5,406.4,221,373.1,218.4L373.1,218.4z M481.3,168.5v4l0.3,0.1c3.7-0.1,6.2-0.3,7.4-0.8
	l-0.5-1.1c-0.4-0.6-1-1-1.5-1.4C485.7,168.7,483.8,168.5,481.3,168.5L481.3,168.5z M481.3,172.6v-4l-20.2,0.7c-2.2,0-4,0.1-5.4,0.6
	c-0.5,0.1-1.1,0.3-1.6,0.6c-1.1,0.4-1.8,1.1-2.3,1.9l10,1c2.1-0.8,5.4-1.4,10.2-1.4C476.7,171.5,479.7,171.8,481.3,172.6
	L481.3,172.6z"
          />
          <path
            fill="#FFFFFF"
            d="M481.6,172.7l-0.3-0.1c-1.6-0.8-4.8-1.1-9.4-0.8c-4.8,0.1-8.2,0.6-10.2,1.4h0.4c4.3,0.3,8.4,0.3,12.5,0
	L481.6,172.7L481.6,172.7z"
          />
          <path
            fill="#FFFFFF"
            d="M481.6,172.7l-6.7,0.4H500l1.6-2.2l-13.2-0.1l0.5,1.1C487.8,172.3,485.3,172.6,481.6,172.7L481.6,172.7z
	 M454.2,170.4c0.4-0.3,1-0.4,1.6-0.6l-24.5,0.1L454.2,170.4L454.2,170.4z"
          />
          <path
            fill="#EBEBEB"
            d="M520.1,206.8h0.8c14.4-0.6,28.2-1.6,40.9-3.3c9.2-1.2,16-2.5,20.4-3.6c-0.7-2.3-9.8-5.6-27-10.2
	c-11.8-2.1-25.7-2.9-41.4-2.2c-4.3,0-6.3,1.2-6.5,3.6l1.2,11.4C508.4,205.5,512.3,206.8,520.1,206.8L520.1,206.8z"
          />
          <path
            fill="#FFFFFF"
            d="M487,169.6c0.7,0.4,1.2,0.8,1.5,1.4l13.2,0.3l47.2,0.7c5.1,0,10.9-1,17.5-2.9L487,169.6L487,169.6z
	 M495,184.8h2.9v1h34c12.7,0.4,23.4,1.9,32.5,4.8c10.7,3.4,17.6,6.3,20.2,8.5c1.4-0.6,2.2-1,2.9-1.4c1.5-2.5-6.3-6.2-23.8-11.4
	c-15-3.6-34.9-5.1-60-4.7h-8.5v3.2H495L495,184.8z"
          />
          <path
            fill="#656565"
            d="M497.7,184.8H495v0.3l1.4,22.1h2.6l-1.1-21.5"
          />
          <path fill="#FFFFFF" d="M495,184.8v0.3" />
          <path
            fill="#F2F2F2"
            d="M482.3,185.1l0.3,1.9l2.6,20.2h11.1l-1.4-22.1 M497.8,185.8l1.2,21.5c7.3,0,14.3-0.1,21.2-0.4
	c-7.8,0.1-11.8-1.4-11.7-4.3l-1.2-11.4c0-2.3,2.2-3.6,6.5-3.6c15.7-0.7,29.6,0,41.4,2.2c17.3,4.5,26.3,8,27,10.2l2.3-0.7
	c-2.8-2.2-9.5-5.1-20.2-8.5c-9.1-2.9-19.8-4.4-32.5-4.8h-34L497.8,185.8L497.8,185.8z M552.9,220.9l0.1,2.8
	c0.8,17.6,1.4,35.9,1.7,54.9l0.1,20.4l-0.1,19c-0.3,19-0.8,37.3-1.7,54.9l-0.1,2.8c19.7,0.1,39.1,0.6,58.3,1.2
	c25.5,0.8,51.6,2.1,78.4,3.9c5.5-18.7,9.2-38,10.9-58.1l0.4-6.1c0.3-6.1,0.5-12,0.5-18c0-6.2-0.1-12.4-0.5-18.6l-0.4-6.2
	c-1.7-20.1-5.2-39.3-10.9-58.1c-26.8,1.8-53,3-78.4,3.9C591.9,220.5,572.5,220.9,552.9,220.9L552.9,220.9z M670.5,234.2
	c7.8,4,12.5,8.3,14,12.7c2.8,12.7,4.5,25,5.2,37.3l0.1,1.6c0.3,3.9,0.3,7.8,0.3,11.8c0,4.3-0.1,8.7-0.3,12.9l-0.1,1.9
	c-0.8,12.2-2.5,24.6-5.2,37.3c-1.5,4.4-6.2,8.5-14,12.7c-5.2,2.2-10.7,3.4-16.5,3.6c-26.7-0.3-50.8-1.4-72.2-3.2
	c-4.7-0.3-6.6-2.2-5.9-5.9c1.4-19.8,1.9-39.3,1.7-58.5c0.3-19.1-0.3-38.7-1.7-58.6c-0.7-3.7,1.4-5.8,5.9-5.9
	c21.5-1.7,45.5-2.8,72.2-3.2C659.8,230.8,665.3,231.9,670.5,234.2L670.5,234.2z"
          />
          <path
            fill="#EBEBEB"
            d="M684.5,246.7c-1.5-4.4-6.2-8.5-14-12.7c-5.2-2.2-10.7-3.4-16.5-3.6c-26.7,0.4-50.8,1.4-72.2,3.2
	c-4.7,0.3-6.6,2.2-5.9,5.9c1.4,19.9,1.9,39.5,1.7,58.6c0.3,19.1-0.3,38.5-1.7,58.5c-0.7,3.7,1.4,5.8,5.9,5.9
	c21.5,1.8,45.5,2.8,72.2,3.2c5.6-0.1,11.1-1.4,16.5-3.6c7.8-4,12.5-8.3,14-12.7c2.8-12.7,4.5-25,5.2-37.3l0.1-1.9
	c0.3-4.3,0.3-8.7,0.3-12.9c0-4-0.1-7.8-0.3-11.8l-0.1-1.6C689.1,271.9,687.3,259.4,684.5,246.7L684.5,246.7z M521,389.7h-0.8
	c-7.8-0.1-11.8,1.4-11.7,4.3l-1.2,11.4c0,2.3,2.2,3.6,6.5,3.6c15.7,0.7,29.6,0,41.4-2.1c17.3-4.5,26.3-8,27-10.2
	c-4.4-1.2-11.1-2.3-20.4-3.6C549,391.3,535.4,390.2,521,389.7L521,389.7z"
          />
          <path
            fill="#FFFFFF"
            d="M520.1,389.7h0.8c14.4,0.5,28.2,1.6,40.9,3.3c9.2,1.2,16,2.5,20.4,3.6c0.8,0.3,1.7,0.4,2.3,0.7
	c1.4,0.5,2.2,1,2.9,1.4c1.5,2.5-6.3,6.3-23.8,11.4c-15,3.6-34.9,5.1-60,4.7h-8.5v0.3l0.3,0.8c0.5,1.6,1.4,3.2,2.3,4.5
	c0.7,1,1.7,1.9,2.6,2.6h6.2c48.7-0.1,95.3-1.1,139.6-3.2l11.3-0.5l11.1-0.8l13.9-1.4l17.1-1.4c14.7-10,25.5-20.9,32.3-32.7
	l-21.5-1.2l-20.5-1.4c-26.8-1.8-53-3-78.4-3.9c-19.3-0.8-38.7-1.2-58.3-1.2c-23.5-0.1-47.2,0.4-71.3,1.2
	c-39.1,0.5-75.1,2.1-108.4,4.5l-2.1,0.4c1.2,5.1,4.8,8.3,10.7,9.5c1.7,0.3,3.4,0.5,5.5,0.5c29.6-1.6,62.3-2.5,97.9-2.6h13.9
	C506.2,389.3,513.2,389.4,520.1,389.7L520.1,389.7z"
          />
          <path
            fill="#FFFFFF"
            d="M552.9,375.5l0.1-2.8c-23.5-0.4-47.3-0.1-71.4,0.7c-39.1,0.5-75.1,2.1-108.4,4.5l-2.5,0.5l0.4,3.2l2.1-0.4
	c33.3-2.5,69.5-4,108.4-4.5C505.5,375.9,529.3,375.5,552.9,375.5L552.9,375.5z"
          />
          <path fill="#656565" d="M498.9,389.1h-2.6l-1.4,22.3v0.1h2.8l0.1-1" />
          <path
            fill="#F2F2F2"
            d="M496.3,389.1h-11.1l-2.8,20.8l-0.1,1.5H495 M507.3,405.4l1.2-11.4c0-3,3.9-4.4,11.7-4.3
	c-6.9-0.3-13.9-0.4-21.2-0.5l-1.2,21.5h34c12.7-0.3,23.4-1.9,32.5-4.8c10.7-3.4,17.6-6.3,20.2-8.5c-0.7-0.3-1.5-0.5-2.3-0.7
	c-0.7,2.3-9.8,5.6-27,10.2c-11.8,2.1-25.7,2.8-41.4,2.1C509.5,408.9,507.3,407.7,507.3,405.4L507.3,405.4z"
          />
          <path
            fill="#FFFFFF"
            d="M495,411.5v-0.1V411.5z M497.7,411.5h-2.9v3.4h8.5c25,0.4,45.1-1.2,60-4.7c17.3-5.2,25.3-8.9,23.8-11.4
	c-0.5-0.4-1.5-1-2.9-1.4c-2.8,2.2-9.5,5.1-20.2,8.5c-9.1,2.9-19.8,4.4-32.5,4.8h-34L497.7,411.5L497.7,411.5z"
          />
          <path
            fill="#FFFFFF"
            d="M482.3,411.4l0.3-1.5l-151.5,0.4c-0.4,1-0.8,1.8-1.1,2.6l-0.1,0.4l165.2-0.4v-1.7h-12.8L482.3,411.4
	L482.3,411.4z M495,415.1v-0.3l-171.4,1.1l0.1,1.1c0.1,1.4,0.7,2.9,1.4,4.4l1.6,0.3l0.3,1.4h135.5c4.3-0.3,8.4-0.3,12.5,0h25.2
	c-1-0.8-1.9-1.7-2.6-2.6c-1.1-1.4-1.8-2.9-2.3-4.5L495,415.1L495,415.1z"
          />
          <path
            fill="#FFFFFF"
            d="M495,414.8v-2.1l-165.2,0.4c-0.5,1.4-2.6,1.9-6.2,1.5l-0.1,1.2L495,414.8L495,414.8z M487,427
	c0.7-0.3,1.2-0.8,1.5-1.4l0.5-1.1c-1.4-0.5-3.9-0.7-7.4-0.8c0,0-0.1,0-0.3,0.1v4C483.8,428.1,485.7,427.6,487,427L487,427z"
          />
          <path
            fill="#FFFFFF"
            d="M488.6,425.6c-0.4,0.5-1,1-1.5,1.4l79.4,0.7c-6.6-1.9-12.4-2.9-17.5-2.9l-47.2,0.7L488.6,425.6L488.6,425.6z
	"
          />
          <path
            fill="#FFFFFF"
            d="M489.2,424.5l-0.5,1.1l13.2-0.1l-1.6-2.2H475l6.7,0.5C485.3,423.8,487.8,424.1,489.2,424.5L489.2,424.5z
	 M506.4,423.2h-6.2l1.6,2.2l47.2-0.7c5.1,0,10.9,1,17.5,2.9c5.1,2.5,11.6,3.9,19.5,4c29.6,0.4,59.4,0.1,89.6-1.2l3.6-0.3
	c3.9-3.3,12.2-6.3,25-9.2c5.9-1.2,11.1-2.8,15.5-4.7c-5.4-0.4-12.2-0.5-20.5-0.1l-17.1,1.4l-13.9,1.4l-11.1,0.8l-11.3,0.5
	C601.6,422.1,555.1,423.1,506.4,423.2L506.4,423.2z"
          />
          <path
            fill="#EBEBEB"
            d="M482.4,409.9l2.6-20.6c-35.6,0.1-68.4,1.1-97.9,2.6c-16.6,2.2-33.8,5.5-51.6,9.9v1.5c-1.9,2.6-3.4,5-4.5,7.2
	L482.4,409.9z"
          />
          <path
            fill="#F2F2F2"
            d="M335.5,403.2v-1.5l-6.6,0.5l-27.1,8.5c1.9,1.8,5,3,8.9,3.7c1,0,1.9,0.7,2.9,2.1c0.4,0.7,0.8,1.4,1,2.1
	c-0.3-1.5,1.9-2.3,6.3-2.6c1.5,0,2.5,0.4,2.8,1.1l-0.1-1.1l0.1-1.2l0.1-1l-0.1,1c3.6,0.4,5.6-0.1,6.2-1.5l0.1-0.4
	c0.3-0.8,0.7-1.8,1.1-2.6C332.1,408.1,333.6,405.8,335.5,403.2L335.5,403.2z"
          />
          <path
            fill="#FFFFFF"
            d="M387.2,391.9c-2.1-0.1-3.9-0.3-5.5-0.5c-17.2,2.3-34.8,6.1-52.7,11.1l6.6-0.5
	C353.3,397.4,370.5,394.1,387.2,391.9L387.2,391.9z"
          />
          <path
            fill="#F2F2F2"
            d="M371,381.7l-2.1,0.4c-21.3,4-42.8,8.8-64.7,14.4c-7.8,2.5-14.6,4-20.1,4.7l-4.3,0.3l-17.3-2.9
	c8.8,8.8,21.9,13.3,39.3,13.6v-1.5l27.1-8.5c17.9-5.1,35.5-8.7,52.7-11.1C375.8,389.9,372.2,386.8,371,381.7L371,381.7z"
          />
          <path
            fill="#FFFFFF"
            d="M418.9,426.4l12.4,0.1l23-0.4c-1.1-0.4-1.8-1.1-2.3-1.9l10-1h0.3H327l0.8,3.4
	C357.5,426.3,387.8,426.1,418.9,426.4L418.9,426.4z"
          />
          <path
            fill="#FFFFFF"
            d="M455.7,426.7c1.5,0.4,3.3,0.5,5.4,0.5l20.2,0.7v-4c-1.6,0.7-4.8,1-9.4,0.8c-4.8,0-8.2-0.6-10.2-1.4l-10,1
	c0.5,0.8,1.2,1.4,2.3,1.9C454.6,426.3,455.2,426.5,455.7,426.7L455.7,426.7z"
          />
          <path
            fill="#FFFFFF"
            d="M462.4,423.2h-0.5c2.1,1,5.4,1.4,10.2,1.4c4.5,0.3,7.6,0,9.4-0.8c0.1-0.1,0.1-0.1,0.3-0.1l-6.7-0.5
	C470.7,423,466.6,423,462.4,423.2L462.4,423.2z M455.7,426.7c-0.5-0.1-1.1-0.3-1.6-0.5l-23,0.4L455.7,426.7L455.7,426.7z
	 M237.7,282.8c-0.1,2.3-0.3,4.8-0.3,7.3l0.4-8.4C237.8,282.1,237.8,282.4,237.7,282.8z M184.6,432.2l3.7,0.1c25,1,52.6,1,82.4,0
	c12-0.6,21.6-2.2,28.9-5c-1.5,0.5-1.7-0.8-0.7-4.1h-1c-54.1,1.8-96.7,0.8-128.1-2.8c-0.1,0.1-0.6,0.3-1.1,0.1
	c-12,0.4-22.7-2.1-32.2-7.3c-7.7-4.4-14.2-10.5-19.5-18c-1.7-2.3-3.2-4.8-4.7-7.6h-2.2l-13.9-8.8c2.6,6.1,5.8,11.6,9.8,16.4
	c10.6,13.2,25.9,21.9,46.1,26C162.5,424.1,173.3,427.6,184.6,432.2L184.6,432.2z"
          />
          <path
            fill="#FFFFFF"
            d="M236.3,406.6c7.3-0.1,14.3-1.6,20.9-4.8l5.2-3.2c-37.4-4.3-76.2-11.6-116.1-21.9
	c-12.2-3.2-24.5-6.6-36.9-10.3c1,1.5,1.8,3,2.8,4.4c6.2,9.4,13.2,17.5,21.2,24.2c1.9,1.6,3.7,3.2,5.8,4.5l5.6,3.9
	C173.1,405.4,203.5,406.3,236.3,406.6L236.3,406.6z"
          />
          <path
            fill="#FFFFFF"
            d="M257.2,401.8c-6.6,3.2-13.6,4.7-20.9,4.8c-32.7-0.3-63.3-1.2-91.3-2.9l4.8,2.9c8.5,5.2,14.4,9.1,17.6,11.4
	l1.1,0.7c1.2,0.8,1.8,1.5,1.5,1.8c31.4,3.6,74,4.5,128.1,2.8h1l2.9-7.8v-3.2c-17.3-0.3-30.5-4.8-39.3-13.6L257.2,401.8L257.2,401.8z
	"
          />
          <path
            fill="#FFFFFF"
            d="M284.2,401.2c-13.3,0.3-24.6-8.9-33.7-27.4c3.4,9.1,7.4,17.5,12.1,24.9l17.5,2.9L284.2,401.2L284.2,401.2z
	 M301.8,410.9v4.7l-2.9,7.8h14.3c0.3-0.4,0.7-0.8,1.1-1.1c0.3-1.2,0.4-2.3,0.1-3.4c-0.1-0.7-0.4-1.4-1-2.1c-1-1.4-1.9-2.1-2.9-2.1
	C306.8,413.9,303.7,412.6,301.8,410.9L301.8,410.9z"
          />
          <path
            fill="#FFFFFF"
            d="M314.5,422.1c-0.4,0.3-0.8,0.7-1.1,1.1c-0.4,0.4-0.7,1-0.8,1.6c-0.3,0.7-0.3,1.4-0.4,2.2
	c-0.3,3.4,0.7,8.4,2.9,14.9c0.5,1.4,1.1,2.8,1.8,3.9c0.5-1,1.4-0.8,2.2,0.4c2.6,5.6,7.3,8.3,13.9,7.8l-7.8-32.3
	c-0.8-1.2-2.3-1.8-4.8-1.4C317.8,420.3,315.8,420.9,314.5,422.1L314.5,422.1z"
          />
          <path
            fill="#FFFFFF"
            d="M312,427c0-0.8,0.3-1.5,0.4-2.2l0.8-1.6h-14.3c-1,3.3-0.8,4.5,0.7,4.1L312,427L312,427z"
          />
          <path
            fill="#656565"
            d="M326.6,421.9l-1.6-0.3l7.8,32.3l0.3,1l1.4-0.7l-6.6-27.5l-0.8-3.4 M323.5,417.2c-0.3-0.7-1.2-1.1-2.8-1.1
	c-4.4,0.3-6.5,1.1-6.3,2.6c0.3,1.1,0.3,2.2-0.1,3.4c1.4-1.1,3.3-1.8,5.8-1.9c2.3-0.3,4,0.1,4.8,1.4
	C324.2,420.1,323.7,418.6,323.5,417.2L323.5,417.2z"
          />
          <path
            fill="#FFFFFF"
            d="M90.9,276.6v0.1l-0.4,5.5c-0.1,1.6-0.3,3.4-0.3,5.2h0.3c0.6-0.5,2.8-0.8,6.6-1c0.6-13.6,2.2-25.7,4.8-36
	c-4.7,2.3-7.7,6.7-8.9,13.1C92,267.5,91.3,271.9,90.9,276.6L90.9,276.6z M90.5,287.5h-0.4 M89.6,287.7l0.6-0.1v-0.1l-0.3,0.1
	C89.8,287.6,89.7,287.6,89.6,287.7L89.6,287.7z"
          />
          <path
            fill="#FFFFFF"
            d="M90.1,287.5l-0.6,0.1L89,288c-0.3,0.3-0.4,0.5-0.7,1.1c-0.4,0.7-0.7,1.5-1,2.6c-0.3,1.1-0.6,2.2-0.7,3.4
	c-0.1,1.4-0.3,2.8-0.1,4.3c0,1.5,0.1,2.8,0.4,4c0.1,1.1,0.4,2.2,0.7,3.2l0.6,1.2l0.7,1.1l0.8,0.8l0.7,0.3h0.4l-0.3-0.1l0.3,0.1
	l0.7-0.6l0.7-1l1-2.8c0.6-2.3,0.8-5,0.8-7.8l-0.1-2.2c-0.1-1.9-0.6-3.6-1-5.1l-0.4-1.4l-0.7-1.1c-0.3-0.3-0.6-0.5-0.8-0.7l-0.4-0.1
	L90.1,287.5L90.1,287.5z M90.2,289.7c0.1-0.3,0.3-0.4,0.6-0.5c0.3,0,0.4,0,0.7,0.1c0.3,0.1,0.4,0.3,0.6,0.5l0.4,1l0.3,1.1
	c0.3,1.2,0.6,2.6,0.7,4.1v1.8c0,2.3-0.1,4.5-0.6,6.5l-0.3,1.2l-0.4,1l-0.4,0.8c0,0-0.4,0.4-0.6,0.4s-0.4,0-0.7-0.1l-0.4-0.4L90,307
	l-0.4-1l-0.3-1.1c-0.3-0.8-0.4-1.8-0.6-2.6c-0.1-1-0.3-2.1-0.3-3.3c0-1.2,0-2.3,0.1-3.4c0.1-1.1,0.3-1.9,0.4-2.9
	c0.1-0.8,0.4-1.6,0.7-2.2L90.2,289.7L90.2,289.7z"
          />
          <path
            fill="#C8C8C8"
            d="M90.8,289.1c-0.1,0.1-0.4,0.3-0.6,0.5l-0.6,0.8c-0.3,0.5-0.4,1.2-0.7,2.2c-0.1,0.8-0.3,1.8-0.4,2.9
	c-0.1,1.1-0.1,2.3-0.1,3.4c0,1.2,0,2.3,0.3,3.3c0.1,1,0.3,1.8,0.6,2.6l0.3,1.1l0.4,1l0.1,0.1l0.4,0.4c0.3,0.1,0.4,0.3,0.7,0.1
	c0.3-0.1,0.4-0.3,0.6-0.4l0.4-0.8l0.4-1l0.3-1.2c0.4-1.9,0.6-4.1,0.6-6.5l-0.1-1.8c-0.1-1.6-0.3-3-0.7-4.1l-0.3-1.1l-0.4-1
	c-0.1-0.3-0.3-0.4-0.6-0.5C91.2,289.1,90.9,289,90.8,289.1L90.8,289.1z M90.1,291.4c0.1-0.3,0.4-0.5,0.6-0.7v6.7l-0.3,0.3l-0.3,0.4
	l-0.4,0.1l-0.8-0.1c0-1.8,0.1-3.3,0.6-4.7C89.6,292.5,89.8,292,90.1,291.4L90.1,291.4z M90.8,290.9l0.1-0.3v2.6c0,0.1,0,0.3,0.1,0.1
	v-2.9h0.1c0.1,0.1,0.1,0.1,0.1,0.4v2.9h-0.1V291h-0.1v2.9h-0.1l-0.1-0.1V290.9L90.8,290.9z M90.8,294.3h0.3c0.1,0,0.1,0.1,0.1,0.3v3
	h-0.1v-2.8h-0.3v2.9v-2.9V294.3L90.8,294.3z M88.9,298.2c0.3,0.7,0.6,1,0.8,1.4l0.4,0.5c0.1,0.1,0.3,0.3,0.6,0.3v5.5l-0.6-0.4
	c-0.4-0.5-0.6-1.2-0.8-2.3c-0.1-0.7-0.3-1.4-0.3-2.2C88.9,300.1,88.9,299.2,88.9,298.2L88.9,298.2z M90.8,298.9h0.3l0.1,0.1v3.2
	l-0.1,0.1h-0.3l-0.1-0.1v-2.9C90.8,299,90.8,298.9,90.8,298.9L90.8,298.9z M90.8,298.5v-0.5h0.6v0.5H90.8z M91.5,290.9
	c0.3,0.3,0.4,0.5,0.7,1.1c0.1,0.4,0.4,1,0.6,1.5c0.3,1.4,0.6,2.9,0.6,4.7h-0.1l-0.7-1.2c-0.3-0.4-0.6-0.7-0.8-0.8
	C91.6,296.1,91.5,290.9,91.5,290.9z M92.4,298.2l0.7,0.1l-0.1,2.6l-0.3,2.2c-0.3,1.4-0.7,2.3-1.2,2.8v-6.7c0-0.1,0-0.3,0.1-0.4
	l0.1-0.3C92,298.3,92.2,298.3,92.4,298.2L92.4,298.2z M91.2,305.9h-0.4v-0.4h0.4v-2.3h-0.4v-0.5h0.6v2.9L91.2,305.9L91.2,305.9z"
          />
          <path
            fill="#656565"
            d="M90.7,290.8c-0.3,0.1-0.4,0.4-0.6,0.7c-0.3,0.4-0.6,1.1-0.7,1.9c-0.3,1.4-0.6,2.9-0.6,4.7l0.8,0.1l0.3-0.1
	l0.3-0.3l0.3-0.4l0.1-0.4L90.7,290.8L90.7,290.8z"
          />
          <path
            fill="#C8C8C8"
            d="M90.8,290.6l-0.1,0.3v3l0.1,0.1h0.1v-2.9h0.1v2.8h0.1V291c0-0.3,0-0.3-0.1-0.4L90.8,290.6v2.9
	c0,0.1-0.1,0-0.1-0.1L90.8,290.6L90.8,290.6z M90.9,294.3c-0.1,0-0.1,0.1-0.1,0.4v2.9v-2.9h0.3v2.8h0.1v-3c0-0.3,0-0.3-0.1-0.3
	L90.9,294.3L90.9,294.3z"
          />
          <path
            fill="#656565"
            d="M88.9,298.3v2.6c0.1,0.8,0.1,1.5,0.4,2.2c0.1,1.1,0.4,1.8,0.8,2.3l0.6,0.4v-5.5c-0.3,0-0.4-0.1-0.6-0.3
	l-0.4-0.5C89.4,299.3,89.1,298.9,88.9,298.3z"
          />
          <path
            fill="#C8C8C8"
            d="M91.1,298.9h-0.1c0,0-0.1,0.1-0.1,0.4v2.9l0.1,0.1h0.3l0.1-0.1V299l-0.1-0.1H91.1z M91.2,299.4v2.3h-0.3
	v-2.3H91.2z"
          />
          <path
            fill="#C8C8C8"
            d="M91.2,301.6v-2.2h-0.3v2.2h0.1 M90.8,298.1v0.4h0.6v-0.4"
          />
          <path
            fill="#656565"
            d="M92.2,291.9c-0.3-0.4-0.4-0.8-0.7-1.1v5.2c0.3,0.1,0.6,0.4,0.8,0.8l0.7,1.2l0.1,0.1c0-1.8-0.1-3.3-0.6-4.7
	C92.6,292.8,92.4,292.3,92.2,291.9L92.2,291.9z M93.1,298.3l-0.7-0.1c-0.3,0-0.6,0.1-0.7,0.3l-0.1,0.3c0,0.1-0.1,0.3-0.1,0.3v6.7
	c0.6-0.4,1-1.4,1.2-2.8l0.3-2.2L93.1,298.3L93.1,298.3z"
          />
          <path
            fill="#C8C8C8"
            d="M91.2,305.9l0.3-0.4v-2.9h-0.7v0.5h0.4v2.3h-0.4v0.4L91.2,305.9L91.2,305.9z"
          />
          <path
            fill="#FFFFFF"
            d="M90.5,287.5l0.4,0.1c0.3,0.1,0.6,0.4,0.8,0.7l0.7,1.1l0.4,1.4c0.4,1.5,0.8,3.2,1,5.1l0.1,2.2
	c0,2.9-0.3,5.5-0.8,7.8l-1,2.8l-0.7,1l-0.7,0.4c0.8,0.4,3,0.7,6.5,0.8c-0.1-2.3-0.1-4.7-0.3-7v-10.5l0.3-6.9
	C93.3,286.6,91.1,286.9,90.5,287.5L90.5,287.5z"
          />
          <path
            fill="#FFFFFF"
            d="M90.8,310l-0.4,0.1v1.1c0.1,3,0.3,5.9,0.6,8.7c0.4,4.7,1.1,9.1,1.9,13.2c1.2,6.5,4.3,10.7,8.9,13.1
	c-2.5-10.2-4.1-22-4.7-35.4C93.7,310.7,91.6,310.4,90.8,310L90.8,310z M85.7,343.6c-0.8-5-1.4-9.8-1.9-14.7c0.7,8.8,1.4,16,2.2,21.6
	c0.7,5.4,1.7,10.5,2.8,15l0.1-0.1h0.3l0.1,0.1l-0.8-3.4l-1-1.9v-0.3l0.1-0.3h0.3C87.4,355.6,86.5,350.3,85.7,343.6L85.7,343.6z"
          />
          <path
            fill="#EBEBEB"
            d="M109.6,366.6l-1.8-2.9l-9.5-8.8c5,12.9,10,23.8,15,32.9c0.3,0,0.4,0.1,0.7,0.3l2.1,0.5c2.5,0.7,5,1,7.3,0.8
	c-2.6-3.2-4.7-6.2-6.5-9.1c-2.1-3.4-3.7-6.5-4.5-9.4C111.4,369.6,110.5,368.1,109.6,366.6L109.6,366.6z"
          />
          <path
            fill="#FFFFFF"
            d="M98.4,354.9l-2.5-1c4.5,14.6,9.4,25.9,14.3,33.8h3C108.4,378.7,103.3,367.8,98.4,354.9L98.4,354.9z
	 M87.6,360v0.3l1,1.8l1.7,3.3l-0.8-3.3l-1.1-2.2l-0.1-0.1h-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M88.2,359.7l0.1,0.1l1.1,2.2c-1.4-5.6-2.6-11.7-3.7-18.4C86.5,350.3,87.4,355.6,88.2,359.7L88.2,359.7z
	 M89,365.3l-0.1,0.1l-0.1,0.1l0.1,0.3l3.6,7.2v0.1V373l-1.1-3.7l-1.9-3.7l-0.1-0.3"
          />
          <path fill="#FFFFFF" d="M88.6,362l0.8,3.6l1.9,3.7l-1.1-4" />
          <path
            fill="#FFFFFF"
            d="M89.4,362l0.8,3.2l1.1,4l1.1,3.9v0.1c0.8,2.5,1.5,4.7,2.5,6.9l1.1,2.6c1.8,4.5,4,8.8,6.6,12.5
	c5.1,7.3,11.7,13.1,19.8,17.1c1.2,0.7,2.6,1.2,4,1.8c3.6,1.8,6.5,3.3,8.5,4.5l1.5,1c1.6,1.1,2.8,1.9,3.2,2.6c0.4,0.7,0,1.1-1.1,1.2
	l-1.2-0.4c-2.5-0.8-4.5-1.6-6.3-2.6c-13.8-5.1-25.3-13.5-34.9-25.2c-2.3-2.9-4.5-5.9-6.7-9.1c1.7,3.2,3.4,6.2,5.4,9.1
	c7.8,11.8,17.9,20.5,29.8,26.3c5.1,2.5,12.4,4.3,21.7,5.8l11.8,1.9c8.1,1.5,17.1,2.5,26.8,2.9c-11.4-4.5-22.1-8.1-32.5-10.9
	c-20.2-4.3-35.5-12.9-46.1-26.1c-3.9-4.8-7.2-10.3-9.8-16.4l13.9,8.8c-5-8.1-9.8-19.4-14.3-33.8l-10.3-10.2
	C86.8,350.3,88,356.5,89.4,362L89.4,362z"
          />
          <path
            fill="#FFFFFF"
            d="M83.6,373.9c1.7,4.4,3.4,8.4,5.5,12.2c2.1,3.3,4.4,6.3,6.7,9.1c9.6,11.7,21.3,20.1,34.9,25.2
	c-4.3-2.2-7-5-8.5-8c-8.1-4-14.7-9.8-19.8-17.1c-2.6-3.7-4.8-8-6.6-12.5L83.6,373.9L83.6,373.9z"
          />
          <path
            fill="#EBEBEB"
            d="M113.4,387.7h-0.8c1.5,2.6,3,5.1,4.7,7.6c5.4,7.6,11.8,13.6,19.5,18c9.5,5.4,20.1,7.7,32.2,7.3
	c0.7,0,1,0,1.1-0.1c0.1-0.3-0.3-0.8-1.5-1.8l-5.8-0.8c-5.6,0-10.6-0.5-14.9-1.6c-4.9-1.5-8.8-3.3-11.7-5.4c-0.7-0.3-1.2-0.5-1.8-1
	c-5.5-3-10.6-7.8-15.4-14.6c-1.7-2.2-3.2-4.7-4.7-7.3C113.9,387.9,113.6,387.9,113.4,387.7L113.4,387.7z"
          />
          <path
            fill="#EBEBEB"
            d="M116.1,388.6L114,388c1.5,2.6,3,5.1,4.7,7.3c4.8,6.7,10,11.7,15.4,14.6c0.6,0.4,1.2,0.7,1.8,1
	c2.1,1,4.1,1.6,6.2,2.1c0.6-0.1,0.7-0.8,0.6-1.8l-3.3-0.5c-1-0.3-1.8-0.4-2.6-0.8c-5.9-2.2-11.4-7-16.5-14.4
	C118.9,393.2,117.5,390.9,116.1,388.6L116.1,388.6z"
          />
          <path
            fill="#EBEBEB"
            d="M123.5,389.4c-2.5,0-5-0.3-7.3-0.8c1.4,2.5,2.8,4.7,4.1,6.7c5.2,7.4,10.7,12.2,16.5,14.4l-0.3-5.8
	c-3-3-5.6-5.9-8.1-8.7L123.5,389.4L123.5,389.4z"
          />
          <path
            fill="#EBEBEB"
            d="M116.9,380.3c1.8,2.9,4,5.8,6.5,9.1l5,5.9c2.5,2.8,5.2,5.6,8.1,8.7l1.9,1.9h4.3c6.9,6.1,15,10,24.6,12.1
	c-3.2-2.3-9.1-6.2-17.6-11.4l-4.8-2.9l-5.6-3.9c-1.9,0.5-4.4-0.7-7.3-3.4l-1.4-1.2C126.1,391.3,121.6,386.4,116.9,380.3L116.9,380.3
	z"
          />
          <path
            fill="#EBEBEB"
            d="M112.4,371c1,2.9,2.5,5.9,4.5,9.4c4.5,6.1,9.1,11,13.6,14.9l1.4,1.2c3,2.8,5.4,3.9,7.3,3.4l-5.8-4.5
	C125.6,388.4,118.6,380.3,112.4,371L112.4,371z M136.6,404l0.3,5.8c1,0.4,1.8,0.5,2.6,0.8l-0.1-3.9l-0.8-0.8L136.6,404L136.6,404z"
          />
          <path
            fill="#EBEBEB"
            d="M138.5,405.9l0.8,0.8l1.5,1.4l1.8,1.4c5.1,3.9,11.1,6.5,18,8l2.1,0.4l5.8,0.8l-1.1-0.7
	c-9.6-2.1-17.9-6.1-24.6-12.1L138.5,405.9L138.5,405.9z"
          />
          <path
            fill="#EBEBEB"
            d="M140.9,408.1l-1.5-1.4l0.1,3.9l3.3,0.5c0-0.4-0.1-1-0.3-1.5L140.9,408.1L140.9,408.1z"
          />
          <path
            fill="#EBEBEB"
            d="M142.7,409.5c0.1,0.5,0.3,1.1,0.3,1.5c0.1,1-0.1,1.7-0.6,1.8c4.4,1.9,10.6,3.4,18.3,4.5
	C153.8,415.9,147.7,413.3,142.7,409.5L142.7,409.5z"
          />
          <path
            fill="#FFFFFF"
            d="M138.4,423.5c1.1-0.1,1.5-0.5,1.1-1.2c-2.2,0-3.2-0.3-3.2-0.8v-0.8c-1.2-0.3-1.8-1-1.5-1.8
	c-2.2-1.2-5-2.8-8.5-4.5c0.1,2.6,3.7,5.6,10.9,8.8L138.4,423.5L138.4,423.5z"
          />
          <path
            fill="#FFFFFF"
            d="M136.3,419.8l-1.5-1c-0.1,1,0.3,1.5,1.5,1.8V419.8z M130.8,420.5c1.8,1,4,1.9,6.3,2.6
	c-7.2-3.3-10.7-6.2-10.9-8.8c-1.4-0.5-2.6-1.1-4-1.8C123.7,415.5,126.6,418.1,130.8,420.5L130.8,420.5z M136.3,420.6v0.8
	c0,0.5,1,0.8,3.2,0.8c-0.4-0.7-1.5-1.5-3.2-2.6V420.6z"
          />
          <path
            fill="#EBEBEB"
            d="M142.2,412.9c-2.1-0.4-4.1-1.1-6.2-2.1c2.9,2.2,6.9,4,11.7,5.4c4.3,1.1,9.2,1.6,14.9,1.6l-2.1-0.4
	C152.8,416.4,146.8,414.8,142.2,412.9L142.2,412.9z M333,454.9l-0.3-0.8c-6.6,0.6-11.3-2.2-13.9-7.8c-1-1.2-1.6-1.4-2.2-0.4
	c3.9,7.3,9.4,10.6,16.5,9.9L333,454.9L333,454.9z"
          />
          <path
            fill="#FFFFFF"
            d="M747.7,266.4c0.4,2.9,0.8,5.8,1.2,8.5l1.8,5.5c0.4,4,0.5,7.8,0.7,11.8v12.1c-0.1,3.9-0.4,7.8-0.7,11.8
	l-1.8,5.5l-1.2,8.5c-0.5,11.3,0.7,15.7,3.7,13.3l5-6.5c-2.1-1.4-2.8-5.5-2.1-12.4c0.4-5,0.8-9.8,1-14.4l0.3-11.1l-0.3-12.8
	c-0.3-4.7-0.5-9.5-1-14.4c-0.7-6.9,0-11,2.1-12.4l-5-6.3C748.2,250.6,747,255.1,747.7,266.4L747.7,266.4z M753.3,290.1
	c0.1,0,0.3,0,0.4,0.1l0.3,0.7l0.7,4.3l0.1,6.5l-0.7,4.7l-0.3,0.8l-0.3,0.5c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.3-0.3-0.5
	l-0.4-1.9c-0.1-1.1-0.3-2.5-0.3-4l-0.1-1.6c0-2.2,0.1-4.3,0.3-6.1c0-0.4,0.1-0.8,0.1-1.1s0.1-0.7,0.3-1l0.3-0.8L753.3,290.1
	L753.3,290.1z"
          />
          <path
            fill="#C8C8C8"
            d="M753.7,290.2c-0.1-0.1-0.3-0.3-0.4-0.1l-0.3,0.4l-0.3,0.8c-0.1,0.3-0.1,0.5-0.1,1c-0.1,0.3-0.1,0.7-0.1,1.1
	c-0.3,1.8-0.4,3.9-0.4,6.1l0.1,1.6c0.1,1.5,0.1,2.9,0.3,4l0.4,1.9c0.1,0.3,0.3,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.4,0.1l0.3-0.5l0.3-0.8
	l0.7-4.7l-0.1-6.5l-0.7-4.3L753.7,290.2L753.7,290.2z M753.3,291.7h0.1v0.4h-0.3v2.2h0.3v0.4h-0.3V292L753.3,291.7z M753.3,295.3
	l0.3-0.1l0.1,0.1v3h-0.3v-0.3L753.3,295.3L753.3,295.3z M754,297.2l-0.3-0.3v-5.2c0.3,0.4,0.6,1.2,0.8,2.6l0.3,4.7v-0.1L754,297.2
	L754,297.2z M753.2,298.1v0.3l-0.1,0.3l-0.4,0.3l-0.4-0.1v-2.5l0.1-2.1c0.1-1.4,0.4-2.2,0.7-2.6L753.2,298.1L753.2,298.1z
	 M753.3,298.6h0.3v0.5h-0.3L753.3,298.6L753.3,298.6L753.3,298.6z M753.8,299.4c0.1-0.3,0.3-0.4,0.4-0.4c0.1,0,0.3,0,0.6,0.1
	c0,1.6-0.1,3.2-0.3,4.4c-0.1,1.4-0.4,2.2-0.8,2.6v-6.3L753.8,299.4L753.8,299.4z M753.6,299.4v3h-0.3v-3.2v2.6h0.3V299.4
	L753.6,299.4z M752.2,299l0.4,1.2l0.6,0.8v4.8c-0.1-0.3-0.3-0.5-0.4-1c-0.1-0.4-0.3-1-0.3-1.5C752.2,302.2,752.2,300.7,752.2,299
	L752.2,299z M753.4,303l0.1,0.1v3v-2.5l-0.1-0.1v2.8h-0.1v-3.2L753.4,303l-0.1,2.6h0.1V303L753.4,303z"
          />
          <path
            fill="#C8C8C8"
            d="M753.4,291.7h-0.1v3.2h0.3v-0.5h-0.3v-2.2h0.3v-0.4 M753.3,295.2v3.2h0.3v-3.2L753.3,295.2L753.3,295.2z
	 M753.3,295.7h0.3v2.2h-0.1l-0.1-0.1L753.3,295.7L753.3,295.7L753.3,295.7z"
          />
          <path fill="#C8C8C8" d="M753.4,295.7h-0.1v2.1l0.1,0.1" />
          <path
            fill="#FFFFFF"
            d="M753.7,296.8l0.3,0.3l0.7,1.6v0.1l-0.3-4.7c-0.1-1.4-0.4-2.2-0.8-2.6L753.7,296.8L753.7,296.8z M753,298.5
	v-6.6c-0.3,0.4-0.5,1.2-0.7,2.6l-0.1,2.1v2.5l0.4,0.1l0.4-0.3L753,298.5L753,298.5z"
          />
          <path fill="#C8C8C8" d="M753.6,298.6h-0.3v0.5h0.3" />
          <path
            fill="#FFFFFF"
            d="M754.3,298.9l-0.4,0.4l-0.1,0.3v6.3c0.3-0.4,0.5-1.2,0.8-2.6c0.1-1.2,0.3-2.8,0.3-4.4L754.3,298.9
	L754.3,298.9z"
          />
          <path
            fill="#C8C8C8"
            d="M753.6,299.4v2.5l-0.1,0.3h-0.1v-2.8v3.2h0.3v-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M752.6,300.3l-0.4-1.2c0,1.7,0.1,3.2,0.3,4.4c0.1,0.5,0.1,1.1,0.3,1.5c0.1,0.4,0.3,0.8,0.4,1v-4.8
	L752.6,300.3L752.6,300.3z"
          />
          <path
            fill="#C8C8C8"
            d="M753.4,303v2.8l-0.1-0.1V303v3.2h0.1v-2.8l0.1,0.1v2.6v-3"
          />
          <path
            fill="#FFFFFF"
            d="M756.2,259.4c-2.1,1.4-2.8,5.5-2.1,12.4c0.4,5,0.8,9.8,1,14.4l0.3,12.8l-0.3,11.1c-0.3,4.7-0.5,9.5-1,14.4
	c-0.7,6.9,0,11,2.1,12.4c1.9-12.5,3-25.4,3.2-38.8C759.2,284.8,758.3,271.9,756.2,259.4L756.2,259.4z M751.4,292.1
	c-0.1-3.9-0.4-7.8-0.7-11.8l-1.8-5.5l0.6,4.5c0.4,4.3,0.7,8.5,0.8,12.7v12.1c-0.1,4.1-0.4,8.4-0.8,12.8l-0.5,4.5l1.8-5.5
	c0.3-4,0.5-8,0.7-11.8L751.4,292.1L751.4,292.1z"
          />
          <path
            fill="#FFFFFF"
            d="M756.2,337l-5,6.5c-3,2.3-4.3-2.1-3.7-13.3c-2.2,13.3-5.6,27.2-10.6,41.4c-1.4,4-3.2,7.8-5.4,11.7l4,0.3
	l2.6-0.6c1.2-0.4,2.5-1.9,4-4.7l4.3-7.8l2.9-3.9l0.8-1.1C752.7,356.2,754.7,346.7,756.2,337L756.2,337z"
          />
          <path
            fill="#D2D3D4"
            d="M750.1,365.6l-0.8,1.1l-3.4,11.1c-3.3,8.8-6.1,14.9-8.5,18.4l-0.7,1.6c-1,1.9-1.9,3.6-2.9,4.8
	c-1.7,2.3-3.3,3.6-5,3.9l-0.4,1.5c1.7,0,3.6-1.1,5.5-3c2.6-3.3,5.4-8.3,8.5-14.9c3-7,5.8-15.3,8.5-24.6L750.1,365.6L750.1,365.6
	L750.1,365.6z"
          />
          <path
            fill="#FFFFFF"
            d="M755.6,365.6h-4.8c-2.6,9.5-5.5,17.6-8.5,24.6c-3,6.6-5.9,11.6-8.5,14.9c-1.1,1.4-2.1,2.5-3.2,3.3
	c-0.7,0.6-1.7,1.1-2.9,1.4c-1.5,0.4-3.4,0.5-5.6,0.5L717,410l-0.4,0.1l-0.7-0.1c-1-0.4-0.4-1.8,1.7-4.3c5.8-5.6,11.4-12.8,17.2-21.3
	l0.5-1l-4-0.3c-6.9,11.8-17.6,22.8-32.3,32.7c8.3-0.4,15-0.3,20.5,0.1c6.6-2.9,11.6-6.5,15.1-10.7
	C742.2,397.1,749,383.9,755.6,365.6L755.6,365.6z M752.9,386.4l0.7-2.6c-3.3,10-6.5,16.1-9.4,18.2c-4,2.9-6.7,5.9-8.1,8.8
	c-0.7,4.1-5.2,7.1-13.6,9.2l3.3-0.7c10.5-2.3,17.2-5.9,20.1-10.9c4-7.2,6.2-14.2,6.9-20.9L752.9,386.4L752.9,386.4z"
          />
          <path
            fill="#EBEBEB"
            d="M749.3,366.7l-2.9,3.9c-3,10-6.1,18.6-9.1,25.9c2.5-3.6,5.2-9.8,8.5-18.4L749.3,366.7z"
          />
          <path
            fill="#EBEBEB"
            d="M738.2,382.9l-2.6,0.5l-0.5,1l-0.3,1.5c1.2,1.5,1,7.2-0.8,16.9c1-1.2,1.9-2.9,2.9-4.8l0.7-1.6
	c3-7.2,6.1-15.8,9.1-25.9l-4.3,7.8C740.6,381,739.4,382.5,738.2,382.9L738.2,382.9z M734.6,386l0.3-1.5
	c-5.8,8.5-11.6,15.7-17.2,21.3c-2.1,2.5-2.6,3.9-1.7,4.3c5.5-0.7,9.6-2.2,12.1-4.7C730.2,402.9,732.4,396.5,734.6,386L734.6,386z
	 M728,409.8c1.2-0.3,2.2-0.8,2.9-1.4c1-0.8,2.1-1.9,3.2-3.3c-1.9,2.1-3.9,3-5.5,3L728,409.8L728,409.8z M728,409.8l0.4-1.5
	c-4,0.6-6.1,1.2-6.1,2.1C724.5,410.3,726.5,410.2,728,409.8L728,409.8z"
          />
          <path
            fill="#D2D3D4"
            d="M728.4,408.2l0.4-1.5c1.7-0.3,3.3-1.6,5-3.9c1.8-9.8,2.1-15.4,0.8-16.9c-2.2,10.6-4.4,17.1-6.5,19.4
	c-2.5,2.5-6.6,4-12.1,4.7l0.7,0.1l0.4-0.1l5.1,0.3C722.3,409.5,724.4,408.8,728.4,408.2L728.4,408.2z"
          />
          <path
            fill="none"
            d="M753.4,303v2.8l-0.1-0.1V303v3.2h0.1v-2.8l0.1,0.1v2.6v-3L753.4,303 M752.2,299c0,1.7,0.1,3.2,0.3,4.4
	c0.1,0.5,0.1,1.1,0.3,1.5c0.1,0.4,0.3,0.8,0.4,1v-4.8l-0.5-0.8L752.2,299L752.2,299z M753.6,299.4v2.5l-0.1,0.3h-0.1v-2.8v3.2h0.3
	L753.6,299.4 M753.8,299.4l-0.1,0.3v6.3c0.3-0.4,0.5-1.2,0.8-2.6c0.1-1.2,0.3-2.8,0.3-4.4c-0.1-0.1-0.3-0.1-0.5-0.1
	C754.1,299,754,299.2,753.8,299.4L753.8,299.4z M753.3,298.6v0.5h0.3v-0.5 M753.2,298.1v-6.3c-0.3,0.4-0.5,1.2-0.7,2.6l-0.1,2.1
	c0,0.8-0.1,1.7-0.1,2.5l0.4,0.1l0.4-0.3l0.1-0.3V298.1L753.2,298.1z"
          />
          <path
            fill="none"
            d="M754,297.2l0.7,1.6v0.1l-0.3-4.7c-0.1-1.4-0.4-2.2-0.8-2.6v5.2L754,297.2L754,297.2z M753.4,295.7v2.2l-0.1-0.1
	v-2.1"
          />
          <path
            fill="none"
            d="M753.3,295.3v3h0.3v-3l-0.1-0.1h-0.1 M753.3,291.7v3.2h0.3v-0.5h-0.3v-2.2h0.3v-0.4h-0.1"
          />
          <path
            fill="none"
            d="M753.3,290.1l-0.3,0.4l-0.3,0.8c-0.1,0.3-0.1,0.7-0.1,1c-0.1,0.4-0.1,0.7-0.1,1.1c-0.3,1.8-0.4,3.9-0.4,6.1
	l0.1,1.6c0.1,1.5,0.1,2.9,0.3,4l0.4,1.9c0.1,0.3,0.3,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.4,0.1l0.3-0.5l0.3-0.8l0.7-4.7l-0.1-6.5
	l-0.7-4.3l-0.3-0.7C753.6,290.1,753.4,289.9,753.3,290.1L753.3,290.1z M90.2,289.7l-0.6,0.8c-0.3,0.5-0.4,1.2-0.7,2.2
	c-0.1,0.8-0.3,1.8-0.4,2.9c-0.1,1.1-0.1,2.3-0.1,3.4c0,1.2,0,2.3,0.3,3.3c0.1,1,0.3,1.8,0.6,2.6l0.3,1.1l0.4,1l0.1,0.1l0.4,0.4
	c0.3,0.1,0.4,0.3,0.7,0.1c0.3-0.1,0.4-0.3,0.6-0.4l0.4-0.8l0.4-1l0.3-1.2c0.4-1.9,0.6-4.1,0.6-6.5l-0.1-1.8c-0.1-1.6-0.3-3-0.7-4.1
	l-0.3-1.1l-0.4-1c-0.1-0.3-0.3-0.4-0.6-0.5c-0.3-0.1-0.4-0.1-0.7-0.1C90.5,289.1,90.4,289.4,90.2,289.7L90.2,289.7z M90.1,291.4
	c-0.3,0.4-0.6,1.1-0.7,1.9c-0.3,1.4-0.6,2.9-0.6,4.7l0.8,0.1l0.3-0.1l0.3-0.3l0.3-0.4l0.1-0.4v-6.2C90.4,291,90.2,291.2,90.1,291.4
	L90.1,291.4z M90.8,290.9v3l0.1,0.1h0.1v-2.9h0.1v2.8h0.1V291c0-0.3,0-0.3-0.1-0.4h-0.3v2.9c0,0.1-0.1,0-0.1-0.1v-2.6V290.9z
	 M90.8,294.3c0,0-0.1,0.1-0.1,0.4v2.9h0.1v-2.9h0.3v2.8h0.1v-3c0-0.3,0-0.3-0.1-0.3h-0.1L90.8,294.3L90.8,294.3z M88.9,298.2
	c0,1,0,1.9,0.1,2.8c0,0.8,0.1,1.5,0.3,2.2c0.3,1.1,0.4,1.8,0.8,2.3l0.6,0.4v-5.5c-0.3,0-0.4-0.1-0.6-0.3l-0.4-0.5
	C89.4,299.3,89.1,298.9,88.9,298.2L88.9,298.2z M90.8,298.9c0,0-0.1,0.1-0.1,0.4v2.9l0.1,0.1h0.3l0.1-0.1V299l-0.1-0.1H90.8z
	 M90.8,298.5h0.6v-0.5h-0.6V298.5z M91.5,290.9v5.2c0.3,0.1,0.6,0.4,0.8,0.8l0.7,1.2l0.1,0.1c0-1.8-0.1-3.3-0.6-4.7
	c-0.1-0.7-0.3-1.1-0.6-1.5C92,291.5,91.8,291.2,91.5,290.9L91.5,290.9z M92.4,298.2c-0.3,0-0.6,0.1-0.7,0.3l-0.1,0.3
	c-0.1,0.1-0.1,0.3-0.1,0.4v6.7c0.6-0.4,1-1.4,1.2-2.8l0.3-2.2l0.1-2.6L92.4,298.2L92.4,298.2z M91.2,301.6h-0.3v-2.3h0.3V301.6z
	 M91.2,305.9l0.1-0.4v-2.9h-0.6v0.5h0.4v2.3h-0.4v0.4L91.2,305.9L91.2,305.9z M85.7,343.6c0.8,6.6,1.7,12,2.5,16.1 M160.7,417.5
	c-7.7-1.1-13.9-2.6-18.3-4.5 M160.7,417.5c-6.9-1.5-12.9-4.1-18-8 M162.7,417.9l5.8,0.8 M162.7,417.9l-2.1-0.4"
          />
          <path
            fill="none"
            d="M136.1,410.9c2.9,2.2,6.9,4,11.7,5.4c4.3,1.1,9.2,1.6,14.9,1.6 M142.9,411c0.1,1-0.1,1.7-0.6,1.8 M142.2,412.9
	c-2.1-0.4-4.1-1.1-6.2-2.1 M126.3,414.2c0.1,2.6,3.7,5.6,10.9,8.8 M136.9,409.8c-5.9-2.2-11.4-7-16.5-14.4c-1.4-2.1-2.8-4.3-4.1-6.7
	 M136.3,420.6v-0.8 M134.8,418.8c-0.1,1,0.3,1.5,1.5,1.8 M136.3,420.6v0.8c0,0.5,1,0.8,3.2,0.8 M138.5,405.9l-1.9-1.9 M136.9,409.8
	c1,0.4,1.8,0.5,2.6,0.8 M123.5,389.4l5,5.9c2.5,2.8,5.2,5.6,8.1,8.7 M138.5,405.9h4.3c6.9,6.1,15,10,24.6,12.1 M139.5,410.6
	l-0.1-3.9 M142.9,411l-3.4-0.4 M142.7,409.5c0.1,0.5,0.3,1.1,0.3,1.5 M142.7,409.5l-1.8-1.4l-1.5-1.4 M139.4,406.7l-0.8-0.8"
          />
          <path
            fill="none"
            d="M114,388c1.5,2.6,3,5.1,4.7,7.3c4.8,6.7,10,11.7,15.4,14.6c0.6,0.3,1.2,0.7,1.8,1 M136.6,404l0.3,5.8
	 M116.9,380.3c4.5,6.1,9.1,11,13.6,14.9l1.4,1.2c3,2.8,5.4,3.9,7.3,3.4 M112.4,371c1,2.9,2.5,5.9,4.5,9.4 M116.9,380.3
	c1.8,2.9,4,5.8,6.5,9.1 M123.5,389.4c-2.5,0-5-0.3-7.3-0.8 M116.1,388.6L114,388 M114,388c-0.3-0.1-0.4-0.1-0.7-0.3 M89.4,365.6
	l1.9,3.7 M92.6,373.3l-0.1-0.1 M92.4,373.2V373 M88.9,365.5l-0.1,0.1l0.1,0.3l3.6,7.2v0.1 M88.6,362l1.7,3.3 M88.6,362l0.8,3.6"
          />
          <path
            fill="none"
            d="M89.4,365.6l-0.1-0.3H89l-0.1,0.1 M89.4,362l-1.1-2.2l-0.1-0.1"
          />
          <path fill="none" d="M88.2,359.7h-0.3l-0.3,0.3v0.3l1,1.8" />
          <path
            fill="none"
            d="M88.9,365.5c-1.1-4.5-2.1-9.6-2.8-15c-0.8-5.6-1.7-12.9-2.2-21.6v-0.3 M284.2,401.2
	c-13.3,0.3-24.6-8.9-33.7-27.4 M284.2,401.2c-11.6-22.7-19-51.5-22.1-86.5v-0.1c-0.4-3.2-0.7-6.2-0.8-9.4l-0.1-3.3l0.1-10.3
	c0.1-3.2,0.4-6.3,0.8-9.4v-0.1c3.2-35.1,10.6-64,22.1-86.5 M284.2,195.3c-13.3-0.3-24.6,8.8-33.7,27.4"
          />
          <path
            fill="none"
            d="M284.8,210.4c-10.3,19.5-16,48.8-16.8,87.9c1,39.1,6.5,68.4,16.8,87.9c2.5,5.1,7.2,7.3,14,6.6
	c20.2-3.4,41.1-7.7,62.6-12.8c4.3-1.2,6.3-4.4,5.9-9.4l-0.4-2.6c-2.9-21.7-4.4-42.1-4.8-61.1v-17.3c0.3-19.1,1.9-39.5,4.8-61.1
	l0.4-2.6c0.3-5-1.6-8.1-5.9-9.4c-21.5-5-42.2-9.2-62.6-12.8C291.9,203,287.2,205.2,284.8,210.4L284.8,210.4z M284.2,195.3v-0.1
	 M142.7,186.9c5.1-3.9,11.1-6.5,18-8 M160.7,178.9c-7.7,1.1-13.9,2.6-18.3,4.5 M116.1,207.8c2.5-0.6,5-0.8,7.3-0.8 M123.5,207
	c-2.6,3.2-4.7,6.2-6.5,9.1 M113.4,208.6l0.7-0.3 M114,208.4l2.1-0.6 M116.9,216.1c-2.1,3.4-3.7,6.5-4.5,9.4 M136.6,192.5
	c-5.2,5.2-9.5,10-13.1,14.6 M126.3,182.2c0.1-2.6,3.7-5.6,10.9-8.9 M142.9,185.4c0.1-1.1-0.1-1.6-0.6-1.9 M142.2,183.6
	c-2.1,0.4-4.1,1.1-6.2,2.1"
          />
          <path
            fill="none"
            d="M116.9,216.1c5.1-6.6,10.2-12,15.1-16.1c3-2.8,5.4-3.9,7.3-3.4 M138.5,190.6l-1.9,1.9 M136.9,186.8
	c1-0.3,1.8-0.6,2.6-0.8 M114,208.4c5-8.4,10.2-14.9,15.8-19.1c1.4-1.1,2.9-2.1,4.4-2.9c0.6-0.4,1.2-0.7,1.8-1"
          />
          <path
            fill="none"
            d="M136.9,186.8c-1.8,0.7-3.4,1.5-5.2,2.6c-5.5,3.7-10.7,9.9-15.5,18.4 M136.6,192.5l0.3-5.8 M142.9,185.4
	c-1.1,0-2.2,0.3-3.3,0.6 M139.5,185.9l-0.1,3.9 M138.5,190.6l0.8-0.8 M139.4,189.8l0.4-0.4l2.9-2.5 M142.7,186.9l0.3-1.5
	 M167.4,178.5c-8.9,1.9-16.6,5.5-23.2,10.9l-1.4,1.2h-4.3 M139.5,174.1c-2.2,0-3.2,0.3-3.2,0.8v0.8 M134.8,177.7
	c-0.1-1,0.3-1.5,1.5-1.8 M136.3,175.9v0.8 M168.5,177.7l-5.8,0.8 M136.1,185.7c2.9-2.2,6.9-4,11.7-5.4c4.3-1.1,9.2-1.6,14.9-1.6
	 M162.7,178.5l-2.1,0.4 M91.3,227.2l-1.9,3.7 M88.2,236.7c-0.8,4.1-1.7,9.4-2.5,16.1"
          />
          <path
            fill="none"
            d="M83.8,267.8v-0.1c0.7-8.8,1.4-16,2.2-21.6c0.7-5.4,1.7-10.5,2.8-15 M89.4,234.4l-1.1,2.2l-0.1,0.1 M88.6,234.4
	l0.8-3.4"
          />
          <path
            fill="none"
            d="M88.2,236.7h-0.3l-0.1-0.3v-0.3l1-1.9 M88.6,234.4l1.7-3.2 M89.4,230.9l-0.1,0.1H89l-0.1-0.1 M92.4,223.4
	l0.1-0.3 M88.9,230.9v-0.3l3.6-7.2l0.1-0.1 M684.8,192.3h16.6l0.3,0.1h2.2l0.3-0.1v-0.6h-19.4 M670.5,234.2
	c-5.2-2.2-10.7-3.4-16.5-3.6c-26.7,0.4-50.8,1.4-72.2,3.2c-4.7,0.3-6.6,2.2-5.9,5.9c1.4,19.9,1.9,39.5,1.7,58.6
	c0.3,19.1-0.3,38.5-1.7,58.5c-0.7,3.7,1.4,5.8,5.9,5.9c21.5,1.8,45.5,2.8,72.2,3.2c5.6-0.1,11.1-1.4,16.5-3.6
	c7.8-4,12.5-8.3,14-12.7c2.8-12.7,4.5-25,5.2-37.3l0.1-1.9c0.3-4.3,0.3-8.7,0.3-12.9c0-4-0.1-7.8-0.3-11.8l-0.1-1.6
	c-0.8-12.2-2.5-24.6-5.2-37.3C683,242.3,678.3,238.2,670.5,234.2L670.5,234.2z M733.8,193.5c-1.7-2.2-3.3-3.6-5-3.9l-0.4-1.5
	 M728.4,188.1l-0.4-1.5 M737.3,200.1l-0.7-1.8c-1-1.9-1.9-3.6-2.9-4.8 M733.9,191.3c-1.9-2.1-3.9-3.2-5.5-3.2 M728.4,188.1
	c-4-0.6-6.1-1.2-6.1-2.1 M734.6,210.6c-2.2-10.6-4.4-17.1-6.5-19.4c-2.5-2.5-6.6-4-12.1-4.7 M735,212.1l-0.4-1.5 M749.3,229.7
	l-3.4-11.1c-3.3-8.8-6.1-14.9-8.5-18.4 M733.8,193.5c1.8,9.8,2.1,15.4,0.8,16.9 M737.3,200.1c3,7.2,6.1,15.8,9.1,25.9 M746.4,370.4
	c-3,10-6.1,18.6-9.1,25.9"
          />
          <path
            fill="none"
            d="M749.3,366.7l-3.4,11.1c-3.3,8.8-6.1,14.9-8.5,18.4 M735,384.4l-0.4,1.5 M737.3,396.3l-0.7,1.6
	c-1,1.9-1.9,3.6-2.9,4.8 M728.4,408.2l-0.4,1.5 M733.8,402.9c-1.7,2.3-3.3,3.6-5,3.9l-0.4,1.5 M733.9,405.1c-1.9,2.1-3.9,3-5.5,3
	 M722.2,410.3c0.1-0.8,2.2-1.5,6.1-2.1 M734.6,386c-2.2,10.6-4.4,17.1-6.5,19.4c-2.5,2.5-6.6,4-12.1,4.7 M733.8,402.9
	c1.8-9.8,2.1-15.4,0.8-16.9 M731.6,213.2l-42,2.6 M699.2,180.4c14.7,9.9,25.5,20.9,32.3,32.7 M719.7,180.3
	c-5.4,0.6-12.2,0.6-20.5,0.1 M750.8,230.9h-0.7 M755.6,230.9h-4.8 M747.7,266.4c-2.2-13.3-5.6-27.2-10.6-41.4
	c-1.4-4-3.2-7.8-5.4-11.7 M731.6,213.2l4-0.1 M750.1,230.9l-0.8-1.1l-2.9-3.7l-4.3-7.8c-1.5-2.8-2.8-4.3-4-4.7l-2.6-0.4
	 M753.6,212.8l-0.7-2.6l-0.1-1c-0.5-6.9-2.9-13.8-6.9-20.9c-2.9-5-9.6-8.7-20.1-11l-3.3-0.7"
          />
          <path
            fill="none"
            d="M753.6,212.8c-3.3-10-6.5-16.1-9.4-18.2c-4-3-6.7-5.9-8.1-8.8c-0.7-4.1-5.2-7.2-13.6-9.2 M719.7,180.3
	c6.6,2.9,11.6,6.5,15.1,10.9c7.3,8.3,14.2,21.6,20.8,39.8"
          />
          <path
            fill="none"
            d="M750.8,230.9c-2.6-9.5-5.5-17.6-8.5-24.6c-3-6.6-5.9-11.6-8.5-14.9c-1.1-1.4-2.1-2.5-3.2-3.3
	c-0.7-0.6-1.7-1.1-2.9-1.4c-1.5-0.4-3.4-0.6-5.6-0.6l-5.1,0.3l-0.4-0.1l-0.7,0.1c-1,0.4-0.4,1.8,1.7,4.3
	c5.8,5.6,11.4,12.8,17.2,21.3l0.5,1 M722.3,176.6l-2.1-0.4c-5.4-1.2-10.7-2.8-16-4.5c-7-2.6-15.4-4.4-25.2-5.2"
          />
          <path
            fill="none"
            d="M679.2,166.3c3.9,3.3,12.2,6.3,25,9.2c5.9,1.2,11.1,2.8,15.5,4.7 M676.4,193.8v-0.1 M676.4,193.8v-0.1
	 M676.4,193.6c0.8-0.6,1.5-0.7,2.3-0.6l5.2-0.3"
          />
          <path
            fill="none"
            d="M684,191.2l-5.6-0.3c-0.8,0-1.5-0.3-2.2-0.7l-0.4-0.3l-0.5-0.3c-0.5-0.1-1.1-0.1-1.5,0.3
	c-0.4,0.3-0.7,0.7-0.7,1.4c0,0.6,0.1,1.1,0.5,1.6c0.4,0.6,0.8,1,1.4,1.1c0.5,0.1,1.1,0.1,1.5-0.1 M684,192.8c0.3,0,0.4-0.1,0.7-0.3
	l0.1-0.3"
          />
          <path
            fill="none"
            d="M684,192.8c-0.1,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.6c0-0.3,0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.3
	 M684,191.2h0.3 M684.2,191.2H684 M684.8,192.3l-0.7-0.4l0.8-0.1l-0.3-0.4c-0.1-0.1-0.3-0.1-0.4-0.3 M699.2,180.4l-17.1-1.4
	l-13.9-1.4l-11.1-0.8l-11.3-0.6c-44.3-2.1-90.9-3-139.6-3.2H500 M566.3,168.9c5.1-2.5,11.6-3.9,19.5-4c29.6-0.4,59.4-0.1,89.6,1.2
	l3.6,0.3 M92.4,223.4c-0.4,1.2-0.8,2.5-1.1,3.7l-1.1,4l-0.8,3.3c-1.4,5.6-2.6,11.7-3.7,18.4 M94.9,216.2l1.1-2.6 M92.6,223.1
	c0.8-2.5,1.5-4.7,2.5-6.9 M83.6,222.5c-0.6,1.5-0.3,2.3,0.7,2.6c-4.7,20.5-7.2,44.8-7.7,73c0.6,28.2,3.2,52.5,7.7,73
	c-1,0.3-1.2,1.1-0.7,2.6 M89,210.3c-2.1,3.9-3.9,7.8-5.5,12.2 M98.4,241.5l-2.3,1 M107.8,232.7l-9.5,8.8 M101.9,250.3
	c1.7-6.5,3.6-12.4,6.1-17.6 M96,242.5l-10.3,10.3 M109.6,229.8l-1.8,2.9 M96,213.7l-12.4,8.8"
          />
          <path
            fill="none"
            d="M94.9,216.2l-7,14.2c-1.4,3.2-2.3,7.4-2.9,12.7c-2.8,21.9-4.1,40.3-4.4,55.3c0.3,15,1.7,33.3,4.4,55.2
	c0.6,5.2,1.5,9.5,2.9,12.7l7,14 M184.6,164.2c-9.8,0.4-18.6,1.4-26.8,2.9L146,169c-9.4,1.5-16.5,3.3-21.7,5.8
	c-7.6,3.7-14.3,8.5-20.4,14.6c-5.8,5.9-10.7,12.8-15,20.9"
          />
          <path
            fill="none"
            d="M130.8,176c-8.5,3.2-16.2,7.7-23.2,13.3c-6.9,5.8-13.1,12.7-18.4,20.9 M170,176c-0.1-0.1-0.6-0.3-1.1-0.1
	c-12-0.4-22.7,2.1-32.2,7.3c-3.2,1.8-6.1,3.9-8.8,6.2c-6.1,5.1-11.1,11.6-15.4,19.3"
          />
          <path
            fill="none"
            d="M145,192.8l4.8-2.9c8.5-5.2,14.4-9.1,17.6-11.4c0-0.1,0.1-0.1,0.3-0.1l0.8-0.6c1.2-0.8,1.8-1.5,1.5-1.8
	 M122.3,184c1.2-0.7,2.6-1.2,4-1.8c3.6-1.8,6.5-3.3,8.5-4.7l1.5-1c1.6-1.1,2.8-1.9,3.2-2.6c0.4-0.7,0-1.1-1.1-1.1l-1.2,0.4
	c-2.5,0.8-4.5,1.6-6.3,2.6"
          />
          <path
            fill="none"
            d="M262.5,197.6l-5.1-3c-6.6-3.2-13.6-4.7-20.9-4.8c-32.2,0.3-62.2,1.2-89.8,2.8l-1.5,0.1 M145,192.8
	c-1.9,1.2-3.9,2.5-5.6,3.9c-10.5,7.7-19.4,17.2-27,28.9c-1,1.4-1.8,2.9-2.8,4.4 M96,213.7c4-10.2,9.9-18.3,17.7-24.4
	c2.6-2.1,5.5-3.7,8.5-5.4 M130.8,176c-4.3,2.2-7,5-8.5,8.1 M112.5,208.6h0.8c-5,9.1-10,20.1-15,32.9 M110.3,208.6h2.2"
          />
          <path
            fill="none"
            d="M184.6,164.2c-11.4,4.5-22.1,8.1-32.5,10.9c-13.3,2.9-24.6,7.6-33.8,14.3c-9.8,7.2-17.1,16.5-22,28.1l13.9-8.8
	 M110.3,208.6c-5,8.1-9.8,19.4-14.3,33.8 M109.6,229.8c12.4-3.7,24.6-7.2,36.9-10.3c39.9-10.3,78.6-17.6,116-21.9 M316.7,150.7
	c-0.7,1.2-1.2,2.5-1.8,3.9c-2.2,6.5-3.2,11.4-2.9,14.9 M332.8,142.5l0.3-0.8 M316.7,150.7c0.5,0.8,1.4,0.7,2.2-0.4
	c2.6-5.6,7.3-8.3,13.9-7.8 M299.8,169.2c-7.3-2.8-16.8-4.4-28.9-5c-30-1-57.5-1-82.4,0l-3.7,0.1 M327.8,169.7l-0.8,3.4 M323.5,180.4
	v-1.2 M323.5,181.5v-1.1 M323.8,182.5l-0.3-1 M323.5,179.2c-0.3,0.7-1.2,1-2.8,1.1c-4.4-0.3-6.5-1.1-6.3-2.6 M329.7,183.1l0.1,0.4
	c0.3,1,0.8,2.1,1.4,3.2 M327,173.1l-0.4,1.4l-1.6,0.3 M323.5,179.2c0.1-1.4,0.7-2.9,1.4-4.4 M329.7,183.1c-0.5-1.4-2.6-1.9-6.2-1.5"
          />
          <path
            fill="none"
            d="M314.5,174.4c1.4,1.1,3.3,1.8,5.8,1.9c2.3,0.3,4-0.3,4.8-1.5 M312,169.4c0,0.8,0.3,1.5,0.4,2.2
	c0.3,0.6,0.5,1.1,0.8,1.5 M299.1,173.1h-1c-54.1-1.8-96.7-0.8-128.1,2.9 M299.8,169.2c-1.5-0.6-1.6,0.8-0.7,4.1"
          />
          <path
            fill="none"
            d="M299.1,173.1l2.8,7.8v3.2 M301.8,185.5c1.9-1.9,5-3.2,8.9-3.7c1,0,1.9-0.7,2.9-2.1c0.4-0.7,0.8-1.4,1-2.1
	 M312,169.4l-12.2-0.3 M313.4,173.1h-14.3 M313.4,173.1c0.3,0.4,0.7,0.8,1.1,1.2 M314.6,177.8c0.3-1.1,0.3-2.2-0.1-3.4 M329.1,194.2
	l-27.2-8.7 M301.8,185.5v-1.4 M262.5,197.6v0.1 M262.6,197.6h-0.1 M262.5,197.8l0.1-0.1 M371,214.7l-2.1-0.4
	c-21.3-3.9-42.8-8.7-64.7-14.4c-7.8-2.5-14.6-4-20.1-4.7l-4.3-0.4l-17.3,2.9 M301.8,184.1c-17.3,0.3-30.4,4.8-39.2,13.6
	 M262.5,197.8c-4.7,7.4-8.7,15.8-12.1,24.9c-6.3,16.8-10.6,36.6-12.7,59 M324.9,174.8l7.8-32.3 M381.7,205.2
	c1.6-0.4,3.4-0.6,5.5-0.6 M331.3,186.6c1.1,2.1,2.5,4.3,4.3,6.6v1.4 M552.9,220.9c-23.5,0-47.2-0.4-71.3-1.2
	c-39.1-0.6-75.1-2.1-108.4-4.5l-2.1-0.4 M370.6,217.9l0.4-3.2 M553,223.8c-23.5,0.4-47.3,0.1-71.4-0.7c-39.1-0.6-75.1-2.1-108.4-4.5
	l-2.5-0.6 M371,214.7c1.2-5.1,4.8-8.3,10.7-9.5 M387.2,204.6c-16.6-2.2-33.8-5.5-51.6-9.9 M481.3,172.6v-4.1 M481.6,172.7l-0.3-0.1
	 M474.9,173.1l6.7-0.4 M481.3,172.6c-1.6-0.8-4.8-1.1-9.4-0.8c-4.8,0.1-8.3,0.6-10.2,1.4 M481.3,168.5l-20.2,0.7
	c-2.2,0-4,0.1-5.4,0.6 M462.2,173.1h0.1c4.3,0.1,8.4,0.1,12.5,0 M454.2,170.4c0.4-0.3,1-0.4,1.6-0.6"
          />
          <path
            fill="none"
            d="M461.9,173.1l-10-1c0.5-0.8,1.2-1.5,2.3-1.9 M461.9,173.1h0.3 M431.3,170l-12.4,0.1
	c-31.1,0.3-61.4,0.1-91.1-0.4 M455.7,169.8l-24.5,0.1 M454.2,170.4l-23-0.4 M520.1,206.8h0.8c14.4-0.6,28.2-1.6,40.9-3.3
	c9.2-1.2,16-2.5,20.4-3.6 M488.6,170.8l0.5,1.1c-1.4,0.4-3.9,0.7-7.4,0.8 M487,169.6c0.7,0.4,1.2,0.8,1.5,1.4 M497.8,185.8l-0.1-1
	H495 M500.2,173.1c-1,0.8-1.9,1.8-2.6,2.8c-1.1,1.4-1.8,2.9-2.3,4.5l-0.3,0.8v0.3 M495,181.5v2.1 M495,184.8v0.3 M495,183.6v1.2
	 M496.3,207.3h2.6 M495,185.1h-12.7l0.3,1.9 M497.8,185.8l1.1,21.5 M496.3,207.3l-1.4-22.1 M482.6,187l2.6,20.2 M485.2,207.3h11.1
	 M582.2,199.8c-0.7-2.3-9.8-5.6-27-10.2c-11.8-2.1-25.7-2.9-41.4-2.2c-4.3,0-6.3,1.2-6.5,3.6l1.2,11.4c0,3,3.9,4.4,11.7,4.3
	 M500.2,173.1l1.7-2.1"
          />
          <path
            fill="none"
            d="M584.5,199.1c1.4-0.6,2.2-1,2.9-1.4c1.5-2.5-6.3-6.2-23.8-11.4c-15-3.6-34.9-5.1-60-4.7h-8.5 M495,184.8v0.3
	 M488.6,170.8l13.2,0.3 M552.9,220.9l0.1,2.9 M501.8,171.1l47.2,0.7c5.1,0,10.9-1,17.5-2.9 M498.9,207.3c7.3,0,14.3-0.1,21.2-0.4"
          />
          <path
            fill="none"
            d="M584.5,199.1c-2.8-2.2-9.5-5.1-20.2-8.5c-9.1-2.9-19.8-4.4-32.5-4.8h-34 M582.2,199.8l2.3-0.7 M566.3,168.9
	l-79.4,0.7 M689.6,215.8c-26.8,1.8-53,3-78.4,3.9c-19.3,0.8-38.7,1.2-58.3,1.2 M487,169.6c-1.4-0.7-3.2-1.1-5.6-1 M474.9,173.1h25.3
	 M387.2,204.6c29.6,1.5,62.3,2.5,97.9,2.6 M333,141.6l0.1-0.8c-7.2-0.7-12.7,2.6-16.5,9.9 M327.8,169.7l6.6-27.5l-1.4-0.6
	 M495,181.5l-171.4-1.1 M327,173.1h135.2 M495,183.6l-165.2-0.6 M335.5,194.7l-6.5-0.6 M482.6,187l-151.3-0.4 M329.1,194.2
	c17.9,5.1,35.5,8.7,52.7,11.1 M553,372.8c0.8-17.6,1.4-35.9,1.7-54.9l0.1-19l-0.1-20.4c-0.3-19-0.8-37.3-1.7-54.9 M520.1,389.7h0.8
	c14.4,0.5,28.2,1.6,40.9,3.3c9.2,1.2,16,2.5,20.4,3.6 M552.9,375.5l0.1-2.8 M496.3,389.1h2.6 M485.2,389.1h11.1"
          />
          <path
            fill="none"
            d="M582.2,396.5c-0.7,2.3-9.8,5.6-27,10.2c-11.8,2.1-25.7,2.8-41.4,2.1c-4.3,0-6.3-1.2-6.5-3.6l1.2-11.4
	c0-3,3.9-4.4,11.7-4.3 M495,411.5v-0.1 M497.8,410.6l-0.1,1H495 M495,411.4h-12.7l0.1-1.5 M495,411.5v-0.1 M500.2,423.2
	c-1-0.8-1.9-1.6-2.6-2.6c-1.1-1.4-1.8-2.9-2.3-4.5l-0.3-0.8V415 M495,414.8v-1.9 M488.6,425.6c-0.4,0.5-1,1-1.5,1.4"
          />
          <path
            fill="none"
            d="M481.6,423.8c3.7,0,6.2,0.3,7.4,0.8l-0.6,1.1 M495,414.8h8.5c25,0.4,45.1-1.2,60-4.7
	c17.3-5.2,25.3-8.9,23.8-11.4c-0.5-0.4-1.5-1-2.9-1.4 M699.2,415.9l-17.1,1.4l-13.9,1.4l-11.1,0.8l-11.3,0.6
	c-44.3,2.1-90.9,3-139.6,3.2H500 M495,412.9v-1.4 M501.8,425.4l-1.6-2.2 M501.8,425.4l-13.2,0.1 M495,411.4l1.4-22.3 M498.9,389.1
	l-1.1,21.5 M520.1,389.7c-6.9-0.3-13.9-0.4-21.2-0.5 M482.4,409.9l2.8-20.8 M566.3,427.5c-6.6-1.9-12.4-2.9-17.5-2.9l-47.2,0.7"
          />
          <path
            fill="none"
            d="M584.5,397.2c-2.8,2.2-9.5,5.1-20.2,8.5c-9.1,2.9-19.8,4.4-32.5,4.8h-34 M584.5,397.2c-0.7-0.3-1.5-0.5-2.3-0.7
	 M566.3,427.5c5.1,2.5,11.6,3.9,19.5,4c29.6,0.4,59.4,0.1,89.6-1.2l3.6-0.3 M487,427l79.4,0.5 M689.6,380.6c-26.8-1.8-53-3-78.4-3.9
	c-19.3-0.8-38.7-1.2-58.3-1.2 M370.6,217.9c-3.4,25.3-5.4,49.2-5.6,71.7v17.3c0.3,22.4,2.2,46.4,5.6,71.7 M370.6,378.5l0.4,3.2"
          />
          <path
            fill="none"
            d="M552.9,375.5c-23.5-0.1-47.2,0.3-71.3,1.2c-39.1,0.5-75.1,2.1-108.4,4.5l-2.1,0.4 M370.6,378.5l2.5-0.5
	c33.3-2.5,69.5-4,108.4-4.5c24.1-1,47.9-1.2,71.4-0.7 M331,410.3c1.1-2.2,2.6-4.5,4.5-7.2v-1.5 M381.7,391.2
	c1.6,0.3,3.4,0.5,5.5,0.5 M371,381.7l-2.1,0.4c-21.3,4-42.8,8.8-64.7,14.4c-7.8,2.5-14.6,4-20.1,4.7l-4.3,0.3l-17.3-2.9
	 M335.5,401.6c17.7-4.4,34.9-7.7,51.6-9.9 M371,381.7c1.2,5.1,4.8,8.3,10.7,9.5 M327.8,426.7c29.7-0.5,60.1-0.7,91.1-0.4l12.4,0.1
	 M455.7,426.7c1.5,0.4,3.3,0.5,5.4,0.5l20.2,0.7 M474.9,423.2c-4.1-0.3-8.4-0.3-12.5,0h-0.3 M454.2,426.1c0.4,0.3,1,0.4,1.6,0.5
	 M461.9,423.2l-10,1c0.5,0.8,1.2,1.4,2.3,1.9 M462.1,423.2h-0.1 M481.3,423.9c0.1-0.1,0.1-0.1,0.3-0.1 M481.3,427.9v-4 M481.3,423.9
	c-1.6,0.7-4.8,1-9.4,0.8c-4.8-0.1-8.3-0.6-10.2-1.4 M481.6,423.8l-6.7-0.5 M431.3,426.5l24.5,0.1 M431.3,426.5l23-0.4 M481.3,427.9
	c2.5,0.1,4.3-0.3,5.6-1 M500.2,423.2h-25.3 M387.2,391.9c29.6-1.6,62.3-2.5,97.9-2.6"
          />
          <path
            fill="none"
            d="M237.4,290.1c-0.1,2.6-0.1,5.1-0.1,7.7l0.7,17.1c2.1,22.4,6.3,42.1,12.7,59c3.4,9.1,7.4,17.5,12.1,24.9"
          />
          <path
            fill="none"
            d="M237.8,281.7c0,0.4-0.1,0.7-0.1,1.1c-0.1,2.3-0.3,4.8-0.3,7.3 M237.8,281.7l-0.4,8.4 M184.6,432.2l3.7,0.1
	c25,1,52.6,1,82.4,0c12-0.6,21.6-2.2,28.9-5 M262.5,398.8l-5.2,3.2c-6.6,3.2-13.6,4.7-20.9,4.8c-32.7-0.3-63.3-1.2-91.3-2.9
	 M301.8,410.9v1.5 M301.8,410.9c1.9,1.8,5,3,8.9,3.7c1,0,1.9,0.7,2.9,2.1c0.4,0.7,0.8,1.4,1,2.1 M314.5,422.1
	c-0.4,0.3-0.8,0.7-1.1,1.1 M299.1,423.2l2.8-7.8v-3"
          />
          <path
            fill="none"
            d="M170,420.5c31.4,3.6,74,4.5,128.1,2.8h1 M312,427c0-0.8,0.3-1.5,0.4-2.2l0.8-1.6 M299.8,427.4L312,427
	 M313.4,423.2h-14.3 M299.1,423.2c-1,3.3-0.8,4.5,0.7,4.1 M327,423.2l-0.4-1.4l-1.6-0.3 M329.7,413.3l0.1-0.4
	c0.3-0.8,0.7-1.8,1.1-2.6 M323.5,417.2c-0.3-0.7-1.2-1.1-2.8-1.1c-4.4,0.3-6.5,1.1-6.3,2.6 M323.5,417.2v-1.1 M323.8,414l-0.3,0.8
	 M323.5,414.8v1.2 M324.9,421.6c-0.8-1.2-2.3-1.8-4.8-1.4c-2.5,0.1-4.4,0.8-5.8,1.9 M329.7,413.3c-0.5,1.4-2.6,1.9-6.2,1.5
	 M324.9,421.6c-0.7-1.6-1.2-3-1.4-4.4 M327.8,426.7l-0.8-3.4 M329.1,402.3l-27.2,8.5 M314.6,418.7c0.3,1.1,0.3,2.2-0.1,3.4
	 M301.8,412.4c-17.3-0.3-30.5-4.8-39.3-13.6 M90.1,287.5c0-1.8,0.1-3.6,0.3-5.2l0.4-5.5v-0.1c0.4-4.7,1.1-9.1,1.9-13.1
	c1.2-6.5,4.3-10.7,8.9-13.1 M90.5,287.5h-0.3l-0.3,0.1c-0.1,0-0.3,0-0.3,0.1 M90.5,287.5l-0.3,0.1l-0.6,0.1 M89.6,287.7l-0.6,0.4
	c-0.3,0.3-0.4,0.5-0.7,1.1c-0.4,0.7-0.7,1.5-1,2.6c-0.3,1.1-0.6,2.2-0.7,3.4c-0.1,1.4-0.3,2.8-0.1,4.3c0,1.5,0.1,2.8,0.4,4
	c0.1,1.1,0.4,2.2,0.7,3.2l0.6,1.2l0.7,1.1l0.8,0.8l0.7,0.3l0.4-0.1"
          />
          <path
            fill="none"
            d="M90.8,310l0.7-0.5l0.7-1l1-2.8c0.6-2.3,0.8-5,0.8-7.8l-0.1-2.2c-0.1-1.9-0.6-3.6-1-5.1l-0.4-1.4l-0.7-1.1
	c-0.3-0.3-0.6-0.5-0.8-0.7l-0.4-0.1 M101.9,346.1c-2.5-10.2-4.1-22-4.7-35.4c-0.1-2.3-0.1-4.7-0.3-7v-10.5l0.3-6.9
	c0.6-13.6,2.2-25.7,4.8-36 M83.8,328.6c-0.7-6.7-1.1-13.5-1.4-20.4V288c0.3-6.9,0.7-13.6,1.4-20.4 M97.1,286.5
	c-3.9,0.1-6.1,0.4-6.6,1 M90.8,310l-0.3-0.3 M101.9,346.1c-4.7-2.3-7.7-6.7-8.9-13.1c-0.8-4.1-1.5-8.4-1.9-13.2
	c-0.3-2.8-0.4-5.6-0.6-8.7V310 M85.7,343.6c-0.8-5-1.4-9.8-1.9-14.7 M97.3,310.7c-3.4-0.1-5.6-0.4-6.5-0.8 M107.8,363.7
	c-2.3-5.2-4.4-11.1-6.1-17.6 M109.6,366.6l-1.8-2.9 M98.4,354.9l-2.3-1 M107.8,363.7l-9.5-8.8 M92.4,373l-1.1-3.9l-1.1-4l-0.8-3.2
	c-1.4-5.6-2.6-11.7-3.7-18.4 M83.6,373.9c1.7,4.4,3.4,8.4,5.5,12.2 M94.9,380.2c-0.8-2.2-1.7-4.4-2.5-6.9 M94.9,380.2l1.1,2.6
	 M96,382.8l-12.4-8.9 M89,386.1c1.7,3.2,3.4,6.2,5.4,9.1c8,11.8,17.9,20.5,29.8,26.3c5.1,2.5,12.4,4.3,21.7,5.8l11.8,1.9
	c8.1,1.5,17.1,2.5,26.8,2.9"
          />
          <path
            fill="none"
            d="M184.6,432.2c-11.4-4.5-22.1-8.1-32.5-10.9c-20.2-4.3-35.5-12.9-46.1-26c-3.8-4.8-7.2-10.3-9.8-16.4l13.9,8.8
	 M130.8,420.5c-13.8-5.1-25.3-13.5-34.9-25.2c-2.3-2.9-4.5-5.9-6.7-9.1"
          />
          <path
            fill="none"
            d="M96,382.8c1.8,4.5,4,8.8,6.6,12.5c5.1,7.3,11.7,13.1,19.8,17.1 M110.3,387.7h2.2 M112.5,387.7h0.8
	c-5-9.1-10-20.1-15-32.9 M145,403.7l-5.6-3.9l-5.8-4.5c-8-6.7-15.1-14.9-21.2-24.2c-1-1.5-1.9-2.9-2.8-4.4 M130.8,420.5
	c1.8,1,4,1.9,6.3,2.6l1.2,0.4c1.1-0.1,1.5-0.5,1.1-1.2c-0.4-0.7-1.5-1.5-3.2-2.6l-1.5-1c-2.2-1.2-5-2.8-8.5-4.5
	c-1.4-0.5-2.6-1.1-4-1.8 M112.5,387.7c1.5,2.6,3,5.1,4.7,7.6c5.4,7.6,11.8,13.6,19.5,18c9.5,5.4,20.1,7.7,32.2,7.3
	c0.7,0,1,0,1.1-0.1 M122.3,412.4c1.4,3.2,4.3,5.8,8.5,8"
          />
          <path
            fill="none"
            d="M170,420.5c0.3-0.3-0.3-0.8-1.5-1.8l-1.1-0.7c-3.2-2.3-9.1-6.2-17.6-11.4l-4.8-2.9 M262.5,398.8
	c-37.4-4.3-76.2-11.6-116.1-21.9c-12.2-3.2-24.5-6.6-36.9-10.3 M96,353.9l-10.3-10.3 M96,353.9c4.5,14.6,9.4,25.9,14.3,33.8
	 M312,427c-0.3,3.4,0.7,8.4,2.9,14.9c0.5,1.4,1.1,2.8,1.8,3.9 M333,454.9l-0.3-1 M332.8,453.9c-6.6,0.5-11.3-2.2-13.9-7.8
	c-1-1.2-1.6-1.4-2.2-0.4 M332.8,453.9l-7.8-32.3 M333,454.9l1.4-0.7l-6.6-27.5"
          />
          <path
            fill="none"
            d="M316.7,445.8c3.9,7.3,9.4,10.6,16.5,9.9l-0.1-0.8 M323.5,416.1l171.4-1.2 M462.1,423.2H327 M482.4,409.9
	L331,410.3 M329.1,402.3c17.9-5.1,35.5-8.7,52.7-11.1 M335.5,401.6l-6.5,0.7 M495,412.9l-165.2,0.4 M747.7,266.4
	c0.4,2.9,0.8,5.8,1.2,8.5 M756.2,259.4c-2.1,1.4-2.8,5.5-2.1,12.4c0.4,5,0.8,9.8,1,14.4l0.3,12.8l-0.3,11.1c-0.3,4.7-0.5,9.5-1,14.4
	c-0.7,6.9,0,11,2.1,12.4 M748.8,321.4l1.8-5.5c0.4-4,0.5-8,0.7-11.8v-12c-0.1-3.9-0.4-7.8-0.7-11.8l-1.8-5.5"
          />
          <path
            fill="none"
            d="M748.8,274.9l0.5,4.5c0.4,4.3,0.7,8.5,0.8,12.7v12.1c-0.1,4.1-0.4,8.4-0.8,12.8l-0.5,4.5 M753.6,383.8
	c5.9-22,9.8-45.1,11.6-69.3c0.3-4.4,0.4-8.8,0.4-12.9V295c0-4.1-0.1-8.4-0.4-12.9c-1.8-24.2-5.6-47.3-11.6-69.3"
          />
          <path
            fill="none"
            d="M756.2,337c1.9-12.5,3-25.5,3.2-38.8c-0.3-13.3-1.2-26.3-3.2-38.8"
          />
          <path
            fill="none"
            d="M747.7,330.1c-0.5,11.3,0.7,15.7,3.7,13.3l5-6.5 M748.8,321.4l-1.1,8.7"
          />
          <path
            fill="none"
            d="M755.6,230.9c5,16.1,7.8,34.4,8.7,54.9v24.9c-0.8,20.5-3.7,38.8-8.7,54.9 M689.6,380.6
	c5.5-18.7,9.2-38,10.9-58.1l0.4-6.1c0.3-6.1,0.5-12,0.5-18c0-6.2-0.1-12.4-0.5-18.6l-0.4-6.2c-1.7-20.1-5.2-39.3-10.9-58.1
	 M679.2,430.1c9.8-0.8,18.2-2.6,25.2-5.2c5.4-1.8,10.7-3.4,16-4.5l2.1-0.4 M679.2,430.1c3.9-3.3,12.2-6.3,25-9.2
	c5.9-1.2,11.1-2.8,15.5-4.7 M750.1,365.6l-0.8,1.1l-2.9,3.9l-4.3,7.8c-1.5,2.8-2.8,4.3-4,4.7l-2.6,0.5 M755.6,365.6h-4.8
	 M750.8,365.6h-0.7 M722.3,419.9l3.3-0.7c10.5-2.3,17.2-5.9,20.1-10.9c4-7.2,6.2-14.2,6.9-20.9l0.1-1l0.7-2.6 M747.7,330.1
	c-2.2,13.3-5.6,27.2-10.6,41.4c-1.4,4-3.2,7.8-5.4,11.7 M735.6,383.5l-4-0.3"
          />
          <path
            fill="none"
            d="M735.6,383.5l-0.5,1c-5.8,8.5-11.6,15.7-17.2,21.3c-2.1,2.5-2.6,3.9-1.7,4.3l0.7,0.1l0.4-0.1l5.1,0.3
	c2.3,0,4.1-0.1,5.6-0.5c1.2-0.3,2.2-0.8,2.9-1.4c1-0.8,2.1-1.9,3.2-3.3c2.6-3.3,5.4-8.3,8.4-14.9c3-7,5.8-15.3,8.5-24.6
	 M731.6,383.2l-21.5-1.2l-20.5-1.4"
          />
          <path
            fill="none"
            d="M719.7,416.1c6.6-2.9,11.6-6.5,15.1-10.7c7.3-8.3,14.2-21.6,20.8-39.9 M753.6,383.8c-3.3,10-6.5,16.1-9.4,18.2
	c-4,2.9-6.7,5.9-8.1,8.8c-0.7,4.1-5.2,7.2-13.6,9.2 M731.6,383.2c-6.9,11.8-17.6,22.8-32.3,32.7 M756.2,337
	c-1.5,9.8-3.6,19.3-6.2,28.6 M719.7,416.1c-5.4-0.4-12.2-0.5-20.5-0.1 M756.2,259.4l-5-6.3c-3-2.3-4.3,2.1-3.7,13.3 M750.1,230.9
	c2.6,9.2,4.7,18.8,6.2,28.5 M85.7,252.8c-0.8,5-1.4,9.8-1.9,14.7 M679.2,166.3l-3.6-0.3c-30.3-1.2-60.1-1.6-89.6-1.2
	c-7.8,0.1-14.4,1.5-19.5,4c-6.6,1.9-12.4,2.9-17.5,2.9l-47.2-0.6l-1.6,2.2h6.2c48.7,0.1,95.3,1.1,139.6,3.2l11.3,0.6l11.1,0.8
	l13.9,1.4l17.1,1.4c8.3,0.4,15,0.4,20.5-0.1c-4.5-1.9-9.8-3.4-15.5-4.7C691.4,172.7,683,169.6,679.2,166.3L679.2,166.3z"
          />
          <path
            fill="none"
            d="M682.2,179.1l-13.9-1.4l-11.1-0.8l-11.3-0.6c-44.3-2.1-90.9-3-139.6-3.2H500c-1,0.8-1.9,1.8-2.6,2.8
	c-1.1,1.4-1.8,2.9-2.3,4.5l-0.3,0.8v0.3h8.5c25-0.3,45.1,1.2,60,4.7c17.3,5.2,25.3,8.9,23.8,11.4c-0.6,0.4-1.5,1-2.9,1.4l-2.3,0.7
	c-4.4,1.2-11.1,2.3-20.4,3.6c-12.8,1.6-26.4,2.8-40.9,3.3h-0.8c-6.9,0.3-13.9,0.4-21.2,0.4h-13.9c-35.6-0.1-68.4-1.1-97.9-2.6
	c-2.1,0-3.9,0.3-5.5,0.6c-5.9,1.2-9.4,4.4-10.7,9.5l2.1,0.4c33.3,2.5,69.3,4,108.4,4.5c24.1,1,47.9,1.4,71.3,1.2
	c19.7-0.1,39.1-0.6,58.3-1.2c25.5-0.8,51.6-2.2,78.4-3.9l42-2.6c-6.9-11.8-17.6-22.8-32.3-32.7L682.2,179.1L682.2,179.1z
	 M684.8,192.3l-0.1,0.3c-0.1,0.1-0.4,0.3-0.5,0.3H684l-5.2,0.3c-0.8-0.1-1.5,0.1-2.3,0.6v0.1c-0.4,0.3-1,0.3-1.5,0.1
	c-0.5-0.1-1-0.6-1.4-1.1c-0.4-0.6-0.6-1.1-0.6-1.6s0.3-1,0.7-1.4c0.4-0.3,0.8-0.4,1.5-0.3l0.6,0.3l0.4,0.3c0.7,0.4,1.4,0.7,2.2,0.7
	l5.6,0.3h0.3c0.1,0,0.3,0.1,0.4,0.3l0.3,0.4h16.6l0.1-0.1h2.3l0.1,0.1v0.6l-0.1,0.1h-2.2l-0.1-0.1L684.8,192.3L684.8,192.3z"
          />
          <path
            fill="none"
            d="M684.8,192.3h16.6l0.3,0.1h2.2l0.3-0.1v-0.6h-19.4L684,192"
          />
          <path
            fill="none"
            d="M684.7,191.4c-0.1-0.1-0.3-0.1-0.4-0.3H684c-0.1,0-0.3,0.1-0.4,0.3c-0.1,0.1-0.3,0.4-0.3,0.6
	c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3h0.1c0.3,0,0.4-0.1,0.5-0.3l0.1-0.3l-0.7-0.4l0.8-0.1L684.7,191.4L684.7,191.4z
	 M684.2,191.2H684"
          />
          <path
            fill="none"
            d="M683.3,192c0-0.3,0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.3l-5.6-0.3c-0.8,0-1.5-0.3-2.2-0.7l-0.4-0.3l-0.5-0.3
	c-0.5-0.1-1.1-0.1-1.5,0.3c-0.4,0.3-0.7,0.7-0.7,1.4c0,0.6,0.1,1.1,0.5,1.6c0.4,0.6,0.8,1,1.4,1.1c0.5,0.1,1.1,0.1,1.5-0.1
	c0.8-0.6,1.5-0.7,2.3-0.6l5.2-0.3c-0.1,0-0.4-0.1-0.5-0.3C683.3,192.4,683.3,192.3,683.3,192L683.3,192z M676.4,193.8v-0.1"
          />
          <path
            fill="none"
            d="M679.2,166.3c3.9,3.3,12.2,6.3,25,9.2c5.9,1.2,11.1,2.8,15.5,4.7c6.6,2.9,11.6,6.5,15.1,10.9
	c7.3,8.3,14.2,21.6,20.8,39.8c5,16.1,7.8,34.4,8.7,54.9v24.9c-0.8,20.5-3.7,38.8-8.7,54.9c-6.6,18.3-13.5,31.5-20.8,39.9
	c-3.6,4.4-8.5,8-15.1,10.7c-4.5,1.9-9.8,3.6-15.5,4.7c-12.8,2.9-21.2,5.9-25,9.2c9.8-0.8,18.2-2.6,25.2-5.2c5.4-1.8,10.7-3.4,16-4.5
	l2.1-0.4c8.4-2.1,12.9-5.2,13.6-9.2c1.4-2.9,4.1-5.9,8.1-8.8c2.9-2.1,6.1-8,9.4-18.2c5.9-22,9.8-45.1,11.6-69.3
	c0.3-4.4,0.4-8.8,0.4-12.9v-6.5c0-4.1-0.1-8.4-0.4-12.9c-1.8-24.2-5.6-47.3-11.6-69.3c-3.3-10-6.5-16.1-9.4-18.2
	c-4-3-6.7-5.9-8.1-8.8c-0.7-4.1-5.2-7.2-13.6-9.2l-2.1-0.4c-5.4-1.2-10.7-2.8-16-4.5C697.3,168.9,688.9,167.1,679.2,166.3
	L679.2,166.3z"
          />
          <path
            fill="none"
            d="M717.7,190.8c-2.1-2.5-2.6-3.9-1.7-4.3l0.7-0.1l0.4,0.1l5.1-0.3c2.3,0,4.1,0.1,5.6,0.6c1.2,0.3,2.2,0.8,2.9,1.4
	c1,0.8,2.1,1.9,3.2,3.3c2.6,3.3,5.4,8.3,8.5,14.9c3,7,5.8,15.3,8.5,24.6h4.8c-6.6-18.3-13.5-31.5-20.8-39.8
	c-3.6-4.4-8.5-8-15.1-10.9c-5.4,0.6-12.2,0.6-20.5,0.1c14.6,9.9,25.3,20.9,32.2,32.7l4-0.1l-0.5-1
	C729.2,203.4,723.4,196.4,717.7,190.8L717.7,190.8z"
          />
          <path
            fill="none"
            d="M716.2,186.5c-1,0.4-0.4,1.8,1.7,4.3c5.8,5.6,11.4,12.8,17.2,21.3l-0.3-1.5c-2.2-10.6-4.4-17.1-6.5-19.4
	C725.6,188.7,721.7,187.2,716.2,186.5L716.2,186.5z"
          />
          <path
            fill="none"
            d="M716.7,186.4l-0.7,0.1c5.5,0.7,9.6,2.2,12.1,4.7c2.1,2.3,4.1,8.8,6.5,19.4c1.2-1.5,1-7.2-0.8-16.9
	c-1.7-2.2-3.3-3.6-5-3.9l-0.4-1.5c-4-0.6-6.1-1.2-6.1-2.1l-5.1,0.3L716.7,186.4L716.7,186.4z M730.7,188c-0.7-0.6-1.7-1.1-2.9-1.4
	l0.4,1.5c1.7,0,3.6,1.1,5.5,3.2C732.8,189.9,731.7,188.8,730.7,188L730.7,188z"
          />
          <path
            fill="none"
            d="M728.4,188.1l0.4,1.5c1.7,0.3,3.3,1.5,5,3.9c1,1.4,1.9,2.9,2.9,4.8l0.7,1.8c2.5,3.6,5.2,9.6,8.5,18.4l3.4,11.1
	l0.8,1.1h0.7c-2.6-9.5-5.5-17.6-8.5-24.6c-3-6.6-5.9-11.6-8.5-14.9C731.8,189.2,730,188.1,728.4,188.1L728.4,188.1z M728.4,188.1
	l-0.4-1.5c-1.5-0.4-3.4-0.6-5.6-0.6C722.3,187,724.4,187.7,728.4,188.1z"
          />
          <path
            fill="none"
            d="M737.3,200.1l-0.7-1.8c-1-1.9-1.9-3.6-2.9-4.8c1.8,9.8,2.1,15.4,0.8,16.9l0.3,1.5l0.5,1l2.6,0.4
	c1.2,0.4,2.5,2.1,4,4.7l4.3,7.8C743.4,215.9,740.4,207.4,737.3,200.1L737.3,200.1z M722.3,176.6c8.4,2.1,12.9,5.1,13.6,9.2
	c1.4,2.9,4.1,5.9,8.1,8.8c2.9,2.1,6.1,8,9.4,18.2l-0.7-2.6l-0.1-1c-0.5-6.9-2.9-13.8-6.9-20.9c-2.9-5-9.6-8.7-20.1-11L722.3,176.6
	L722.3,176.6z"
          />
          <path
            fill="none"
            d="M738.2,213.4l-2.6-0.4l-4,0.1c2.2,3.9,4,7.7,5.4,11.7c5,14.3,8.4,28.1,10.6,41.4c-0.5-11.3,0.7-15.7,3.7-13.3
	l5,6.3c-1.5-9.8-3.6-19.3-6.2-28.5l-0.8-1.1l-2.9-3.7l-4.3-7.8C740.6,215.4,739.4,213.9,738.2,213.4L738.2,213.4z"
          />
          <path
            fill="none"
            d="M737.1,224.9c-1.4-4-3.2-7.8-5.4-11.7l-42,2.6c5.5,18.7,9.2,38.1,10.9,58.1l0.4,6.2c0.3,6.2,0.4,12.4,0.4,18.6
	c0,5.9-0.1,12-0.5,18l-0.4,6.1c-1.7,19.9-5.2,39.3-10.9,58l20.5,1.4l21.5,1.2c2.2-3.9,4-7.7,5.4-11.7c5-14.3,8.4-28.1,10.6-41.4
	l1.2-8.5l0.5-4.5c0.4-4.3,0.7-8.5,0.8-12.8v-12.2c-0.1-4.1-0.4-8.4-0.8-12.7l-0.5-4.5c-0.3-2.9-0.8-5.6-1.2-8.5
	C745.5,252.9,741.9,239.2,737.1,224.9L737.1,224.9z M746.4,226l2.9,3.7l-3.4-11.1c-3.3-8.8-6.1-14.9-8.5-18.4
	C740.4,207.4,743.4,215.9,746.4,226L746.4,226z"
          />
          <path
            fill="none"
            d="M755.6,230.9h-5.5c2.6,9.2,4.7,18.8,6.2,28.5c1.9,12.5,3,25.5,3.2,38.8c-0.3,13.3-1.2,26.3-3.2,38.8
	c-1.5,9.8-3.6,19.3-6.2,28.6h5.5c5-16.1,7.8-34.4,8.7-54.9v-24.9C763.5,265.3,760.6,247,755.6,230.9L755.6,230.9z M92.4,223.4
	l-3.7,7.2v0.4l0.1,0.1h0.3l0.1-0.1l1.9-3.7C91.8,225.8,92,224.6,92.4,223.4L92.4,223.4z"
          />
          <path
            fill="none"
            d="M92.4,223.4c-0.4,1.2-0.8,2.5-1.1,3.9l-1.1,4l-0.8,3.2c-1.4,5.6-2.6,11.7-3.7,18.4L96,242.5
	c4.5-14.4,9.4-25.7,14.3-33.8l-13.9,8.8c5-11.6,12.2-20.9,22-28.1c9.2-6.7,20.4-11.6,33.8-14.3c10.3-2.6,21-6.2,32.5-10.9
	c-9.8,0.4-18.6,1.4-26.8,2.9l-11.8,1.9c-9.4,1.5-16.5,3.3-21.7,5.8c-7.6,3.7-14.3,8.5-20.4,14.6c-5.8,5.9-10.7,12.8-15,20.9
	c5.4-8.3,11.6-15.1,18.4-20.9c7-5.8,14.7-10.2,23.2-13.3c1.8-1,4-1.9,6.3-2.6l1.2-0.4c1.1,0.1,1.5,0.6,1.1,1.1s-1.5,1.5-3.2,2.6
	l-1.4,1c-2.2,1.2-5,2.8-8.5,4.7c-1.4,0.6-2.6,1.1-4,1.8c-3,1.5-5.9,3.3-8.5,5.4c-7.8,6.1-13.8,14-17.7,24.4l-1.1,2.3v0.1
	c-0.8,2.2-1.7,4.4-2.5,6.9L92.4,223.4L92.4,223.4z"
          />
          <path
            fill="none"
            d="M92.4,223.4l0.1-0.3c0.8-2.5,1.5-4.7,2.5-6.9l-7,14.2c-1.4,3.2-2.3,7.4-2.9,12.7c-2.8,21.9-4.1,40.3-4.4,55.3
	c0.3,15,1.7,33.3,4.4,55.2c0.6,5.2,1.5,9.5,2.9,12.7l6.9,14c-0.8-2.2-1.7-4.4-2.5-6.9v-0.1l-0.1-0.1l-3.6-7.2v-0.3
	c-1.1-4.5-2.1-9.6-2.8-15c-0.8-5.6-1.7-12.9-2.2-21.6v-0.3c-0.7-6.7-1.1-13.5-1.4-20.4v-20.2c0.3-6.9,0.7-13.6,1.4-20.4v-0.1
	c0.7-8.8,1.4-16,2.2-21.6c0.7-5.4,1.7-10.5,2.8-15v-0.1v-0.3L92.4,223.4L92.4,223.4z"
          />
          <path
            fill="none"
            d="M94.9,216.2l1-2.5l-12.4,8.8c-0.6,1.5-0.3,2.3,0.7,2.6c-4.7,20.5-7.2,44.8-7.7,73c0.6,28.2,3.2,52.5,7.7,73
	c-1,0.3-1.2,1.1-0.7,2.6l12.4,8.8l-1-2.5l-7-14.2c-1.4-3.2-2.3-7.3-2.9-12.7c-2.8-21.7-4.1-40.2-4.4-55.2c0.3-15,1.7-33.4,4.4-55.3
	c0.6-5.2,1.5-9.5,2.9-12.7L94.9,216.2L94.9,216.2z M89,210.3c-2.1,3.9-3.9,7.8-5.5,12.2l12.4-8.8c4-10.2,9.9-18.3,17.7-24.4
	c2.6-2.1,5.5-3.7,8.5-5.4c1.4-3.2,4.3-5.8,8.5-8.1c-8.5,3.2-16.2,7.7-23.2,13.3C100.6,195.2,94.4,202,89,210.3L89,210.3z"
          />
          <path
            fill="none"
            d="M89,231.1l-0.1-0.1c-1.1,4.5-2.1,9.6-2.8,15c-0.8,5.6-1.7,12.9-2.2,21.6c0.6-5,1.1-9.9,1.9-14.7
	c0.8-6.6,1.7-12,2.5-16.1H88l-0.1-0.3v-0.3l1-1.9l0.8-3.4l-0.3,0.1H89L89,231.1L89,231.1z"
          />
          <path
            fill="none"
            d="M90.2,231.2l-1.7,3.2l-1,1.9v0.3l0.1,0.3H88l0.1-0.1l1.2-2.3L90.2,231.2L90.2,231.2z M88.6,234.4l1.7-3.2l1.1-4
	l-1.9,3.7"
          />
          <path
            fill="none"
            d="M88.3,236.6l-0.1,0.1c-0.8,4.1-1.7,9.4-2.5,16.1c1.1-6.6,2.3-12.8,3.7-18.4L88.3,236.6L88.3,236.6z M96,242.5
	l2.5-1c5-12.9,10-23.8,15-32.9h-3C105.2,216.8,100.6,228,96,242.5L96,242.5z"
          />
          <path
            fill="none"
            d="M98.4,241.5l-2.5,1l-10.3,10.3c-0.8,5-1.4,9.8-1.9,14.7v0.1c-0.7,6.7-1.1,13.5-1.4,20.4v20.2
	c0.3,6.9,0.7,13.6,1.4,20.4v0.3c0.6,5,1.1,9.9,1.9,14.7l10.3,10.2l2.5,1l9.5,8.8c-2.3-5.2-4.4-11.1-6.1-17.6
	c-4.7-2.3-7.7-6.7-8.9-13.1c-0.8-4.1-1.5-8.4-1.9-13.2c-0.3-2.8-0.4-5.6-0.6-8.7v-1.1l-0.7-0.3l-0.8-0.8l-0.7-1.1l-0.6-1.2
	c-0.3-1.1-0.6-2.1-0.7-3.2c-0.3-1.2-0.3-2.6-0.4-4c0-1.5,0-2.9,0.1-4.3c0.1-1.2,0.4-2.3,0.7-3.4c0.3-1.1,0.6-1.9,1-2.6
	c0.3-0.4,0.4-0.8,0.7-1.1l0.6-0.4c0.1-0.1,0.1-0.1,0.3-0.1l0.3-0.1c0-1.8,0.1-3.6,0.3-5.2l0.4-5.5v-0.1c0.4-4.7,1.1-9.1,1.9-13.1
	c1.2-6.5,4.3-10.7,8.9-13.1c1.7-6.5,3.6-12.4,6.1-17.6L98.4,241.5L98.4,241.5z"
          />
          <path
            fill="none"
            d="M98.4,241.5l9.5-8.8l1.8-3c1-1.5,1.8-2.9,2.8-4.4c1-2.9,2.5-5.9,4.5-9.4c1.8-2.9,4-5.8,6.5-9.1
	c-2.5-0.1-5,0.1-7.3,0.8l-2.1,0.6l-0.7,0.3C108.4,217.7,103.3,228.7,98.4,241.5L98.4,241.5z"
          />
          <path
            fill="none"
            d="M107.8,232.7c-2.3,5.2-4.4,11.1-6.1,17.6c-2.6,10.5-4.1,22.4-4.8,36l-0.1,6.9v10.5c0.1,2.3,0.1,4.7,0.3,7
	c0.6,13.3,2.2,25,4.7,35.4c1.7,6.5,3.6,12.4,6.1,17.6l1.8,2.9c12.4,3.7,24.6,7.2,36.9,10.3c39.9,10.3,78.6,17.6,116.1,21.9
	c-4.7-7.4-8.7-15.8-12.1-24.9c-6.3-16.8-10.6-36.5-12.7-59l-0.7-17.1c0-2.6,0-5.2,0.1-7.7c0-2.5,0.1-5,0.3-7.3c0-0.4,0-0.8,0.1-1.1
	c2.1-22.6,6.3-42.2,12.7-59c3.4-9.1,7.4-17.5,12.1-24.9l-0.1-0.1c-37.4,4.3-76.1,11.6-116,21.9c-12.1,3.2-24.5,6.6-36.9,10.3
	L107.8,232.7L107.8,232.7z M168.9,175.9c0.7-0.1,1,0,1.1,0.1c31.4-3.6,74-4.5,128.1-2.9h1c-1-3.2-0.8-4.5,0.7-4.1
	c-7.3-2.8-16.8-4.4-28.9-5c-30-1-57.5-1-82.4,0l-3.7,0.1c-11.4,4.5-22.1,8.1-32.5,10.9c-13.3,2.9-24.6,7.6-33.8,14.3
	c-9.8,7.2-17.1,16.5-22,28.1l13.9-8.8h2.2c4.1-7.7,9.4-14.2,15.4-19.3c2.7-2.3,5.6-4.4,8.8-6.2C146.1,177.8,156.8,175.3,168.9,175.9
	L168.9,175.9z"
          />
          <path
            fill="none"
            d="M170,176c-0.1-0.1-0.6-0.3-1.1-0.1c-12-0.4-22.7,2.1-32.2,7.3c-3.2,1.8-6.1,3.9-8.8,6.2
	c-6.1,5.1-11.1,11.6-15.4,19.3h0.8l0.7-0.3c5-8.4,10.2-14.9,15.8-19.1c1.4-1.1,2.9-2.1,4.4-2.9c0.6-0.4,1.2-0.7,1.8-1
	c2.9-2.2,6.9-4,11.7-5.4c4.3-1.1,9.2-1.6,14.9-1.6l5.8-0.8C169.8,176.9,170.3,176.3,170,176L170,176z"
          />
          <path
            fill="none"
            d="M170,176c0.3,0.3-0.3,0.8-1.5,1.8l-0.8,0.6c-0.1,0-0.1,0.1-0.3,0.1c-3.2,2.3-9.1,6.2-17.6,11.4l-4.8,2.9
	l1.5-0.1c27.6-1.6,57.6-2.5,89.8-2.8c7.3,0.1,14.3,1.6,20.9,4.8l5.1,3h0.1c8.8-8.8,21.9-13.3,39.2-13.6v-3l-2.9-7.8h-1
	C244,171.5,201.4,172.3,170,176L170,176z"
          />
          <path
            fill="none"
            d="M167.7,178.4l0.8-0.6l-5.8,0.8l-2.1,0.4c-6.9,1.5-12.9,4.1-18,8l-2.9,2.5l-0.4,0.4l-0.8,0.8h4.3l1.4-1.2
	c6.6-5.4,14.3-8.9,23.2-10.9C167.6,178.4,167.6,178.4,167.7,178.4L167.7,178.4z"
          />
          <path
            fill="none"
            d="M160.7,178.9l2.1-0.4c-5.6,0-10.6,0.6-14.9,1.6c-5,1.5-8.8,3.3-11.7,5.4c2.1-1,4.1-1.6,6.2-2.1
	C146.8,181.5,152.8,180,160.7,178.9L160.7,178.9z M149.8,189.8c8.5-5.2,14.4-9.1,17.6-11.4c-8.9,1.9-16.6,5.5-23.2,10.9l-1.4,1.2
	h-4.3l-1.9,1.9c-5.2,5.2-9.5,10-13.1,14.6c-2.6,3.2-4.7,6.2-6.5,9.1c5-6.6,10-12,15-16.1c3-2.8,5.4-3.9,7.3-3.4
	c1.9-1.4,3.7-2.6,5.6-3.9L149.8,189.8L149.8,189.8z M134.8,177.7l1.5-1v-0.8C135.1,176.2,134.7,176.7,134.8,177.7z M136.3,175.9v0.8
	c1.6-1.1,2.8-1.9,3.2-2.6c-2.2,0-3.2,0.3-3.2,0.8V175.9L136.3,175.9z"
          />
          <path
            fill="none"
            d="M134.8,177.7c-0.1-1,0.3-1.5,1.5-1.8v-0.8c0-0.6,1-0.8,3.2-0.8c0.4-0.7,0-1.1-1.1-1.1l-1.2,0.4
	c-7.2,3.3-10.7,6.3-10.9,8.9C129.9,180.4,132.6,178.9,134.8,177.7L134.8,177.7z"
          />
          <path
            fill="none"
            d="M137.1,173.3c-2.5,0.8-4.5,1.6-6.3,2.6c-4.3,2.2-7,5-8.5,8.1c1.2-0.7,2.6-1.2,4-1.8
	C126.4,179.6,130,176.7,137.1,173.3L137.1,173.3z M142.9,185.4l-0.3,1.5c5.1-3.9,11.1-6.5,18-8c-7.7,1.1-13.9,2.6-18.3,4.5
	C142.8,183.7,143.1,184.4,142.9,185.4L142.9,185.4z"
          />
          <path
            fill="none"
            d="M142.7,186.9l0.3-1.5c-1.1,0-2.2,0.3-3.3,0.6l-0.1,3.9l0.4-0.4L142.7,186.9L142.7,186.9z M138.5,190.6l0.8-0.8
	l0.1-3.9c-1,0.3-1.8,0.4-2.6,0.8l-0.3,5.8L138.5,190.6L138.5,190.6z M146.5,192.7l-1.5,0.1c-1.9,1.2-3.9,2.5-5.6,3.9
	c-10.5,7.7-19.4,17.3-27,28.9c-1,1.4-1.8,2.9-2.8,4.4c12.4-3.7,24.6-7.2,36.9-10.3c39.9-10.3,78.6-17.6,116-21.9l-5.1-3
	c-6.6-3.2-13.6-4.7-20.9-4.8C204,190.1,174.2,191,146.5,192.7z"
          />
          <path
            fill="none"
            d="M139.5,185.9c1.1-0.3,2.2-0.4,3.3-0.6c0.1-1.1-0.1-1.6-0.6-1.9c-2.1,0.4-4.1,1.1-6.2,2.1
	c-0.7,0.3-1.2,0.6-1.8,1c-1.5,0.8-2.9,1.8-4.4,2.9c-5.6,4.3-10.9,10.6-15.8,19.1l2.1-0.6c4.8-8.7,10-14.9,15.5-18.4
	c1.6-1.1,3.4-2.1,5.2-2.6C137.8,186.4,138.7,186.1,139.5,185.9L139.5,185.9z"
          />
          <path
            fill="none"
            d="M136.6,192.5l0.3-5.8c-1.8,0.7-3.4,1.5-5.2,2.6c-5.5,3.7-10.7,9.9-15.5,18.4c2.5-0.6,5-0.8,7.3-0.8
	C127.1,202.6,131.5,197.8,136.6,192.5L136.6,192.5z M139.4,196.5c-1.9-0.4-4.3,0.7-7.3,3.4c-5,4.1-10,9.5-15,16.1
	c-2.1,3.4-3.7,6.5-4.5,9.4C120,213.9,129,204.2,139.4,196.5L139.4,196.5z M316.7,150.7c-0.7,1.2-1.2,2.5-1.8,3.9
	c-2.2,6.5-3.2,11.4-2.9,14.9c0.1,0.8,0.3,1.5,0.4,2.2c0.3,0.6,0.5,1.1,0.8,1.5c0.3,0.4,0.7,0.8,1.1,1.2c1.4,1.1,3.3,1.8,5.8,1.9
	c2.3,0.3,4-0.3,4.8-1.5l7.8-32.3c-6.6-0.4-11.3,2.2-13.9,7.8C318,151.4,317.2,151.5,316.7,150.7L316.7,150.7z M333,141.6l-0.3,0.8
	l-7.8,32.3l1.6-0.3l0.4-1.4l0.8-3.4l6.6-27.5"
          />
          <path
            fill="none"
            d="M332.8,142.5l0.3-0.8l0.1-0.8c-7.2-0.7-12.7,2.6-16.5,9.9c0.5,0.8,1.4,0.7,2.2-0.4
	C321.5,144.7,326.2,142.1,332.8,142.5L332.8,142.5z M327.8,169.7l-0.8,3.4h135.2h-0.3l-10-1c0.5-0.8,1.2-1.5,2.3-1.9l-23-0.4
	l-12.4,0.1C387.8,170.4,357.5,170.3,327.8,169.7L327.8,169.7z"
          />
          <path
            fill="none"
            d="M323.5,179.2l-0.1,1.1l171.4,1.1v-0.3l0.3-0.8c0.6-1.6,1.4-3.2,2.3-4.5c0.7-1,1.6-1.9,2.6-2.8h-25.2
	c-4.1,0.3-8.4,0.3-12.5,0H327l-0.3,1.4l-1.6,0.3C324.2,176.4,323.7,178,323.5,179.2L323.5,179.2z"
          />
          <path
            fill="none"
            d="M323.5,180.4l0.1-1.1c-0.3,0.7-1.2,1-2.8,1.1c-4.4-0.3-6.5-1.1-6.3-2.6c-0.1,0.7-0.4,1.4-1,2.1
	c-1,1.4-1.9,2.1-2.9,2.1c-4,0.7-7,1.9-8.9,3.7l27.1,8.5l6.6,0.6v-1.4c-1.8-2.5-3.3-4.7-4.3-6.6c-0.5-1.1-1.1-2.2-1.4-3.2l-0.1-0.4
	c-0.5-1.4-2.6-1.9-6.2-1.5l0.1,1l-0.1-1L323.5,180.4L323.5,180.4z"
          />
          <path
            fill="none"
            d="M323.5,180.4l0.1,1.1c3.6-0.4,5.6,0.1,6.2,1.5l165.2,0.4v-2.1L323.5,180.4L323.5,180.4z M320.9,180.3
	c1.5-0.1,2.5-0.4,2.8-1.1c0.1-1.4,0.7-2.9,1.4-4.4c-0.8,1.2-2.3,1.8-4.8,1.5c-2.5-0.1-4.4-0.8-5.8-1.9c0.4,1.2,0.4,2.3,0.1,3.4
	C314.3,179.2,316.4,180,320.9,180.3L320.9,180.3z"
          />
          <path
            fill="none"
            d="M329.7,183.1l0.1,0.4c0.3,1,0.8,2.1,1.4,3.2l151.3,0.4l-0.3-1.9H495v-1.7L329.7,183.1L329.7,183.1z
	 M313.4,173.1c-0.4-0.6-0.7-1-0.8-1.5c-0.3-0.7-0.3-1.4-0.4-2.2l-12.4-0.3c-1.5-0.6-1.6,0.8-0.7,4.1h14.3L313.4,173.1L313.4,173.1z"
          />
          <path
            fill="none"
            d="M301.8,181v4.5c1.9-1.9,5-3.2,8.9-3.7c1,0,1.9-0.7,2.9-2.1c0.4-0.7,0.8-1.4,1-2.1c0.3-1.1,0.3-2.2-0.1-3.4
	c-0.4-0.3-0.8-0.7-1.1-1.2h-14.3L301.8,181L301.8,181z"
          />
          <path
            fill="none"
            d="M329.1,194.2l-27.1-8.5v-1.5c-17.3,0.3-30.4,4.8-39.2,13.6h-0.1l17.5-2.9l4.3,0.4c5.5,0.7,12.1,2.2,20.1,4.7
	c21.9,5.8,43.5,10.6,64.7,14.4l2.1,0.4c1.2-5.1,4.8-8.3,10.7-9.5C364.5,202.9,346.9,199.1,329.1,194.2L329.1,194.2z M262.5,197.6
	v0.1l0.1-0.1"
          />
          <path
            fill="none"
            d="M284.2,195.2l-4.3-0.4l-17.5,2.9c-4.7,7.4-8.7,15.8-12.1,24.9C259.6,204.1,270.9,195,284.2,195.2L284.2,195.2z"
          />
          <path
            fill="none"
            d="M284.2,195.2c-11.6,22.7-19,51.4-22.1,86.5v0.1c-0.4,3-0.7,6.2-0.8,9.4l-0.1,10.3l0.1,3.3
	c0.1,3.2,0.4,6.3,0.8,9.4v0.1c3.2,35.1,10.6,64,22.1,86.5c5.5-0.7,12.1-2.2,20.1-4.7c21.9-5.8,43.5-10.6,64.7-14.4l2.1-0.4l-0.4-3.2
	c-3.4-25.3-5.4-49.2-5.6-71.7v-17.3c0.3-22.4,2.2-46.4,5.6-71.7l0.4-3.2l-2.1-0.4c-21.3-3.9-42.8-8.7-64.7-14.4
	C296.3,197.4,289.7,195.8,284.2,195.2L284.2,195.2z M284.8,210.4c2.5-5.2,7.2-7.4,14-6.7c20.2,3.4,41.1,7.7,62.6,12.8
	c4.3,1.2,6.3,4.4,5.9,9.4l-0.4,2.6c-2.9,21.7-4.4,42.1-4.8,61.1v17.3c0.3,19.1,1.9,39.5,4.8,61.1l0.4,2.6c0.3,5-1.6,8.1-5.9,9.4
	c-21.5,5-42.2,9.2-62.6,12.8c-6.9,0.7-11.6-1.5-14-6.6c-10.3-19.5-16-48.8-16.8-87.9C268.8,259.1,274.4,229.8,284.8,210.4
	L284.8,210.4z"
          />
          <path
            fill="none"
            d="M298.8,203.7c-6.9-0.7-11.6,1.5-14,6.7c-10.3,19.5-16,48.8-16.8,87.9c1,39.1,6.5,68.4,16.8,87.9
	c2.5,5.1,7.2,7.3,14,6.6c20.2-3.4,41.1-7.7,62.6-12.8c4.3-1.2,6.3-4.4,5.9-9.4l-0.4-2.6c-2.9-21.7-4.4-42.1-4.8-61.1v-17.3
	c0.3-19.1,1.9-39.5,4.8-61.1l0.4-2.6c0.3-5-1.6-8.1-5.9-9.4C339.9,211.4,319,207.1,298.8,203.7L298.8,203.7z"
          />
          <path
            fill="none"
            d="M284.2,195.3c-13.3-0.3-24.6,8.8-33.7,27.4c-6.3,16.8-10.6,36.6-12.7,59l-0.4,8.4c-0.1,2.6-0.1,5.1-0.1,7.7
	l0.7,17.1c2.1,22.4,6.3,42.1,12.7,59c9.1,18.6,20.4,27.6,33.7,27.4c-11.6-22.7-19-51.5-22.1-86.5v-0.1c-0.4-3.2-0.7-6.2-0.8-9.4
	l-0.1-3.3l0.1-10.3c0.1-3.2,0.4-6.3,0.8-9.4v-0.1C265.4,246.7,272.7,217.9,284.2,195.3L284.2,195.3z M381.7,205.2
	c1.6-0.4,3.4-0.6,5.5-0.6c-16.6-2.2-33.8-5.5-51.6-9.9l-6.6-0.6C346.9,199.1,364.5,202.9,381.7,205.2L381.7,205.2z"
          />
          <path
            fill="none"
            d="M335.5,193.4v1.4c17.7,4.4,34.9,7.7,51.6,9.9c29.6,1.5,62.3,2.5,97.9,2.6l-2.6-20.2l-151.3-0.4
	C332.4,188.7,333.7,190.9,335.5,193.4L335.5,193.4z M373.1,215.1l-2.1-0.4l-0.4,3.2l2.5,0.6c33.3,2.5,69.3,4,108.4,4.5
	c24.1,1,47.9,1.1,71.4,0.7l-0.1-2.8c-23.5,0-47.2-0.4-71.3-1.2C442.5,219.1,406.4,217.6,373.1,215.1L373.1,215.1z"
          />
          <path
            fill="none"
            d="M373.1,218.4l-2.5-0.6c-3.4,25.3-5.4,49.2-5.6,71.7v17.3c0.3,22.4,2.2,46.4,5.6,71.7l2.5-0.5
	c33.3-2.5,69.3-4,108.4-4.5c24.1-1,47.9-1.2,71.4-0.7c0.8-17.6,1.4-35.9,1.7-54.9l0.1-19l-0.1-20.4c-0.3-19-0.8-37.3-1.7-54.9
	c-23.5,0.4-47.3,0.1-71.4-0.7C442.5,222.5,406.4,221,373.1,218.4L373.1,218.4z M481.3,168.5v4l0.3,0.1c3.7-0.1,6.2-0.3,7.4-0.8
	l-0.5-1.1c-0.4-0.6-1-1-1.5-1.4C485.7,168.7,483.8,168.5,481.3,168.5L481.3,168.5z M481.3,172.6v-4l-20.2,0.7c-2.2,0-4,0.1-5.4,0.6
	c-0.5,0.1-1.1,0.3-1.6,0.6c-1.1,0.4-1.8,1.1-2.3,1.9l10,1c2.1-0.8,5.4-1.4,10.2-1.4C476.7,171.5,479.7,171.8,481.3,172.6
	L481.3,172.6z"
          />
          <path
            fill="none"
            d="M481.6,172.7l-0.3-0.1c-1.6-0.8-4.8-1.1-9.4-0.8c-4.8,0.1-8.2,0.6-10.2,1.4h0.4c4.3,0.3,8.4,0.3,12.5,0
	L481.6,172.7L481.6,172.7z"
          />
          <path
            fill="none"
            d="M481.6,172.7l-6.7,0.4H500l1.6-2.2l-13.2-0.1l0.5,1.1C487.8,172.3,485.3,172.6,481.6,172.7L481.6,172.7z
	 M454.2,170.4c0.4-0.3,1-0.4,1.6-0.6l-24.5,0.1L454.2,170.4L454.2,170.4z M520.1,206.8h0.8c14.4-0.6,28.2-1.6,40.9-3.3
	c9.2-1.2,16-2.5,20.4-3.6c-0.7-2.3-9.8-5.6-27-10.2c-11.8-2.1-25.7-2.9-41.4-2.2c-4.3,0-6.3,1.2-6.5,3.6l1.2,11.4
	C508.4,205.5,512.3,206.8,520.1,206.8L520.1,206.8z"
          />
          <path
            fill="none"
            d="M487,169.6c0.7,0.4,1.2,0.8,1.5,1.4l13.2,0.3l47.2,0.7c5.1,0,10.9-1,17.5-2.9L487,169.6L487,169.6z M495,184.8
	h2.9v1h34c12.7,0.4,23.4,1.9,32.5,4.8c10.7,3.4,17.6,6.3,20.2,8.5c1.4-0.6,2.2-1,2.9-1.4c1.5-2.5-6.3-6.2-23.8-11.4
	c-15-3.6-34.9-5.1-60-4.7h-8.5v3.2H495L495,184.8z"
          />
          <path
            fill="none"
            d="M497.7,184.8H495v0.3l1.4,22.1h2.6l-1.1-21.5 M495,184.8v0.3"
          />
          <path
            fill="none"
            d="M482.3,185.1l0.3,1.9l2.6,20.2h11.1l-1.4-22.1 M497.8,185.8l1.2,21.5c7.3,0,14.3-0.1,21.2-0.4
	c-7.8,0.1-11.8-1.4-11.7-4.3l-1.2-11.4c0-2.3,2.2-3.6,6.5-3.6c15.7-0.7,29.6,0,41.4,2.2c17.3,4.5,26.3,8,27,10.2l2.3-0.7
	c-2.8-2.2-9.5-5.1-20.2-8.5c-9.1-2.9-19.8-4.4-32.5-4.8h-34L497.8,185.8L497.8,185.8z M552.9,220.9l0.1,2.8
	c0.8,17.6,1.4,35.9,1.7,54.9l0.1,20.4l-0.1,19c-0.3,19-0.8,37.3-1.7,54.9l-0.1,2.8c19.7,0.1,39.1,0.6,58.3,1.2
	c25.5,0.8,51.6,2.1,78.4,3.9c5.5-18.7,9.2-38,10.9-58.1l0.4-6.1c0.3-6.1,0.5-12,0.5-18c0-6.2-0.1-12.4-0.5-18.6l-0.4-6.2
	c-1.7-20.1-5.2-39.3-10.9-58.1c-26.8,1.8-53,3-78.4,3.9C591.9,220.5,572.5,220.9,552.9,220.9L552.9,220.9z M670.5,234.2
	c7.8,4,12.5,8.3,14,12.7c2.8,12.7,4.5,25,5.2,37.3l0.1,1.6c0.3,3.9,0.3,7.8,0.3,11.8c0,4.3-0.1,8.7-0.3,12.9l-0.1,1.9
	c-0.8,12.2-2.5,24.6-5.2,37.3c-1.5,4.4-6.2,8.5-14,12.7c-5.2,2.2-10.7,3.4-16.5,3.6c-26.7-0.3-50.8-1.4-72.2-3.2
	c-4.7-0.3-6.6-2.2-5.9-5.9c1.4-19.8,1.9-39.3,1.7-58.5c0.3-19.1-0.3-38.7-1.7-58.6c-0.7-3.7,1.4-5.8,5.9-5.9
	c21.5-1.7,45.5-2.8,72.2-3.2C659.8,230.8,665.3,231.9,670.5,234.2L670.5,234.2z"
          />
          <path
            fill="none"
            d="M684.5,246.7c-1.5-4.4-6.2-8.5-14-12.7c-5.2-2.2-10.7-3.4-16.5-3.6c-26.7,0.4-50.8,1.4-72.2,3.2
	c-4.7,0.3-6.6,2.2-5.9,5.9c1.4,19.9,1.9,39.5,1.7,58.6c0.3,19.1-0.3,38.5-1.7,58.5c-0.7,3.7,1.4,5.8,5.9,5.9
	c21.5,1.8,45.5,2.8,72.2,3.2c5.6-0.1,11.1-1.4,16.5-3.6c7.8-4,12.5-8.3,14-12.7c2.8-12.7,4.5-25,5.2-37.3l0.1-1.9
	c0.3-4.3,0.3-8.7,0.3-12.9c0-4-0.1-7.8-0.3-11.8l-0.1-1.6C689.1,271.9,687.3,259.4,684.5,246.7L684.5,246.7z M521,389.7h-0.8
	c-7.8-0.1-11.8,1.4-11.7,4.3l-1.2,11.4c0,2.3,2.2,3.6,6.5,3.6c15.7,0.7,29.6,0,41.4-2.1c17.3-4.5,26.3-8,27-10.2
	c-4.4-1.2-11.1-2.3-20.4-3.6C549,391.3,535.4,390.2,521,389.7L521,389.7z"
          />
          <path
            fill="none"
            d="M520.1,389.7h0.8c14.4,0.5,28.2,1.6,40.9,3.3c9.2,1.2,16,2.5,20.4,3.6c0.8,0.3,1.7,0.4,2.3,0.7
	c1.4,0.5,2.2,1,2.9,1.4c1.5,2.5-6.3,6.3-23.8,11.4c-15,3.6-34.9,5.1-60,4.7h-8.5v0.3l0.3,0.8c0.5,1.6,1.4,3.2,2.3,4.5
	c0.7,1,1.7,1.9,2.6,2.6h6.2c48.7-0.1,95.3-1.1,139.6-3.2l11.3-0.5l11.1-0.8l13.9-1.4l17.1-1.4c14.7-10,25.5-20.9,32.3-32.7
	l-21.5-1.2l-20.5-1.4c-26.8-1.8-53-3-78.4-3.9c-19.3-0.8-38.7-1.2-58.3-1.2c-23.5-0.1-47.2,0.4-71.3,1.2
	c-39.1,0.5-75.1,2.1-108.4,4.5l-2.1,0.4c1.2,5.1,4.8,8.3,10.7,9.5c1.7,0.3,3.4,0.5,5.5,0.5c29.6-1.6,62.3-2.5,97.9-2.6h13.9
	C506.2,389.3,513.2,389.4,520.1,389.7L520.1,389.7z"
          />
          <path
            fill="none"
            d="M552.9,375.5l0.1-2.8c-23.5-0.4-47.3-0.1-71.4,0.7c-39.1,0.5-75.1,2.1-108.4,4.5l-2.5,0.5l0.4,3.2l2.1-0.4
	c33.3-2.5,69.5-4,108.4-4.5C505.5,375.9,529.3,375.5,552.9,375.5L552.9,375.5z M498.9,389.1h-2.6l-1.4,22.3v0.1h2.8l0.1-1"
          />
          <path
            fill="none"
            d="M496.3,389.1h-11.1l-2.8,20.8l-0.1,1.5H495 M507.3,405.4l1.2-11.4c0-3,3.9-4.4,11.7-4.3
	c-6.9-0.3-13.9-0.4-21.2-0.5l-1.2,21.5h34c12.7-0.3,23.4-1.9,32.5-4.8c10.7-3.4,17.6-6.3,20.2-8.5c-0.7-0.3-1.5-0.5-2.3-0.7
	c-0.7,2.3-9.8,5.6-27,10.2c-11.8,2.1-25.7,2.8-41.4,2.1C509.5,408.9,507.3,407.7,507.3,405.4L507.3,405.4z M495,411.5v-0.1V411.5z"
          />
          <path
            fill="none"
            d="M497.7,411.5h-2.9v3.4h8.5c25,0.4,45.1-1.2,60-4.7c17.3-5.2,25.3-8.9,23.8-11.4c-0.5-0.4-1.5-1-2.9-1.4
	c-2.8,2.2-9.5,5.1-20.2,8.5c-9.1,2.9-19.8,4.4-32.5,4.8h-34L497.7,411.5L497.7,411.5z"
          />
          <path
            fill="none"
            d="M482.3,411.4l0.3-1.5l-151.5,0.4c-0.4,1-0.8,1.8-1.1,2.6l-0.1,0.4l165.2-0.4v-1.7h-12.8L482.3,411.4
	L482.3,411.4z M495,415.1v-0.3l-171.4,1.1l0.1,1.1c0.1,1.4,0.7,2.9,1.4,4.4l1.6,0.3l0.3,1.4h135.5c4.3-0.3,8.4-0.3,12.5,0h25.2
	c-1-0.8-1.9-1.7-2.6-2.6c-1.1-1.4-1.8-2.9-2.3-4.5L495,415.1L495,415.1z"
          />
          <path
            fill="none"
            d="M495,414.8v-2.1l-165.2,0.4c-0.5,1.4-2.6,1.9-6.2,1.5l-0.1,1.2L495,414.8L495,414.8z M487,427
	c0.7-0.3,1.2-0.8,1.5-1.4l0.5-1.1c-1.4-0.5-3.9-0.7-7.4-0.8c0,0-0.1,0-0.3,0.1v4C483.8,428.1,485.7,427.6,487,427L487,427z"
          />
          <path
            fill="none"
            d="M488.6,425.6c-0.4,0.5-1,1-1.5,1.4l79.4,0.7c-6.6-1.9-12.4-2.9-17.5-2.9l-47.2,0.7L488.6,425.6L488.6,425.6z"
          />
          <path
            fill="none"
            d="M489.2,424.5l-0.5,1.1l13.2-0.1l-1.6-2.2H475l6.7,0.5C485.3,423.8,487.8,424.1,489.2,424.5L489.2,424.5z
	 M506.4,423.2h-6.2l1.6,2.2l47.2-0.7c5.1,0,10.9,1,17.5,2.9c5.1,2.5,11.6,3.9,19.5,4c29.6,0.4,59.4,0.1,89.6-1.2l3.6-0.3
	c3.9-3.3,12.2-6.3,25-9.2c5.9-1.2,11.1-2.8,15.5-4.7c-5.4-0.4-12.2-0.5-20.5-0.1l-17.1,1.4l-13.9,1.4l-11.1,0.8l-11.3,0.5
	C601.6,422.1,555.1,423.1,506.4,423.2L506.4,423.2z M482.4,409.9l2.6-20.6c-35.6,0.1-68.4,1.1-97.9,2.6c-16.6,2.2-33.8,5.5-51.6,9.9
	v1.5c-1.9,2.6-3.4,5-4.5,7.2L482.4,409.9z"
          />
          <path
            fill="none"
            d="M335.5,403.2v-1.5l-6.6,0.5l-27.1,8.5c1.9,1.8,5,3,8.9,3.7c1,0,1.9,0.7,2.9,2.1c0.4,0.7,0.8,1.4,1,2.1
	c-0.3-1.5,1.9-2.3,6.3-2.6c1.5,0,2.5,0.4,2.8,1.1l-0.1-1.1l0.1-1.2l0.1-1l-0.1,1c3.6,0.4,5.6-0.1,6.2-1.5l0.1-0.4
	c0.3-0.8,0.7-1.8,1.1-2.6C332.1,408.1,333.6,405.8,335.5,403.2L335.5,403.2z"
          />
          <path
            fill="none"
            d="M387.2,391.9c-2.1-0.1-3.9-0.3-5.5-0.5c-17.2,2.3-34.8,6.1-52.7,11.1l6.6-0.5
	C353.3,397.4,370.5,394.1,387.2,391.9L387.2,391.9z"
          />
          <path
            fill="none"
            d="M371,381.7l-2.1,0.4c-21.3,4-42.8,8.8-64.7,14.4c-7.8,2.5-14.6,4-20.1,4.7l-4.3,0.3l-17.3-2.9
	c8.8,8.8,21.9,13.3,39.3,13.6v-1.5l27.1-8.5c17.9-5.1,35.5-8.7,52.7-11.1C375.8,389.9,372.2,386.8,371,381.7L371,381.7z
	 M418.9,426.4l12.4,0.1l23-0.4c-1.1-0.4-1.8-1.1-2.3-1.9l10-1h0.3H327l0.8,3.4C357.5,426.3,387.8,426.1,418.9,426.4L418.9,426.4z"
          />
          <path
            fill="none"
            d="M455.7,426.7c1.5,0.4,3.3,0.5,5.4,0.5l20.2,0.7v-4c-1.6,0.7-4.8,1-9.4,0.8c-4.8,0-8.2-0.6-10.2-1.4l-10,1
	c0.5,0.8,1.2,1.4,2.3,1.9C454.6,426.3,455.2,426.5,455.7,426.7L455.7,426.7z"
          />
          <path
            fill="none"
            d="M462.4,423.2h-0.5c2.1,1,5.4,1.4,10.2,1.4c4.5,0.3,7.6,0,9.4-0.8c0.1-0.1,0.1-0.1,0.3-0.1l-6.7-0.5
	C470.7,423,466.6,423,462.4,423.2L462.4,423.2z M455.7,426.7c-0.5-0.1-1.1-0.3-1.6-0.5l-23,0.4L455.7,426.7L455.7,426.7z
	 M237.7,282.8c-0.1,2.3-0.3,4.8-0.3,7.3l0.4-8.4C237.8,282.1,237.8,282.4,237.7,282.8z M184.6,432.2l3.7,0.1c25,1,52.6,1,82.4,0
	c12-0.6,21.6-2.2,28.9-5c-1.5,0.5-1.7-0.8-0.7-4.1h-1c-54.1,1.8-96.7,0.8-128.1-2.8c-0.1,0.1-0.6,0.3-1.1,0.1
	c-12,0.4-22.7-2.1-32.2-7.3c-7.7-4.4-14.2-10.5-19.5-18c-1.7-2.3-3.2-4.8-4.7-7.6h-2.2l-13.9-8.8c2.6,6.1,5.8,11.6,9.8,16.4
	c10.6,13.2,25.9,21.9,46.1,26C162.5,424.1,173.3,427.6,184.6,432.2L184.6,432.2z"
          />
          <path
            fill="none"
            d="M236.3,406.6c7.3-0.1,14.3-1.6,20.9-4.8l5.2-3.2c-37.4-4.3-76.2-11.6-116.1-21.9c-12.2-3.2-24.5-6.6-36.9-10.3
	c1,1.5,1.8,3,2.8,4.4c6.2,9.4,13.2,17.5,21.2,24.2c1.9,1.6,3.7,3.2,5.8,4.5l5.6,3.9C173.1,405.4,203.5,406.3,236.3,406.6
	L236.3,406.6z"
          />
          <path
            fill="none"
            d="M257.2,401.8c-6.6,3.2-13.6,4.7-20.9,4.8c-32.7-0.3-63.3-1.2-91.3-2.9l4.8,2.9c8.5,5.2,14.4,9.1,17.6,11.4
	l1.1,0.7c1.2,0.8,1.8,1.5,1.5,1.8c31.4,3.6,74,4.5,128.1,2.8h1l2.9-7.8v-3.2c-17.3-0.3-30.5-4.8-39.3-13.6L257.2,401.8L257.2,401.8z
	"
          />
          <path
            fill="none"
            d="M284.2,401.2c-13.3,0.3-24.6-8.9-33.7-27.4c3.4,9.1,7.4,17.5,12.1,24.9l17.5,2.9L284.2,401.2L284.2,401.2z
	 M301.8,410.9v4.7l-2.9,7.8h14.3c0.3-0.4,0.7-0.8,1.1-1.1c0.3-1.2,0.4-2.3,0.1-3.4c-0.1-0.7-0.4-1.4-1-2.1c-1-1.4-1.9-2.1-2.9-2.1
	C306.8,413.9,303.7,412.6,301.8,410.9L301.8,410.9z"
          />
          <path
            fill="none"
            d="M314.5,422.1c-0.4,0.3-0.8,0.7-1.1,1.1c-0.4,0.4-0.7,1-0.8,1.6c-0.3,0.7-0.3,1.4-0.4,2.2
	c-0.3,3.4,0.7,8.4,2.9,14.9c0.5,1.4,1.1,2.8,1.8,3.9c0.5-1,1.4-0.8,2.2,0.4c2.6,5.6,7.3,8.3,13.9,7.8l-7.8-32.3
	c-0.8-1.2-2.3-1.8-4.8-1.4C317.8,420.3,315.8,420.9,314.5,422.1L314.5,422.1z"
          />
          <path
            fill="none"
            d="M312,427c0-0.8,0.3-1.5,0.4-2.2l0.8-1.6h-14.3c-1,3.3-0.8,4.5,0.7,4.1L312,427L312,427z M326.6,421.9l-1.6-0.3
	l7.8,32.3l0.3,1l1.4-0.7l-6.6-27.5l-0.8-3.4 M323.5,417.2c-0.3-0.7-1.2-1.1-2.8-1.1c-4.4,0.3-6.5,1.1-6.3,2.6
	c0.3,1.1,0.3,2.2-0.1,3.4c1.4-1.1,3.3-1.8,5.8-1.9c2.3-0.3,4,0.1,4.8,1.4C324.2,420.1,323.7,418.6,323.5,417.2L323.5,417.2z
	 M90.9,276.6v0.1l-0.4,5.5c-0.1,1.6-0.3,3.4-0.3,5.2h0.3c0.6-0.5,2.8-0.8,6.6-1c0.6-13.6,2.2-25.7,4.8-36c-4.7,2.3-7.7,6.7-8.9,13.1
	C92,267.5,91.3,271.9,90.9,276.6L90.9,276.6z M90.5,287.5h-0.4 M89.6,287.7l0.6-0.1v-0.1l-0.3,0.1C89.8,287.6,89.7,287.6,89.6,287.7
	L89.6,287.7z"
          />
          <path
            fill="none"
            d="M90.1,287.5l-0.6,0.1L89,288c-0.3,0.3-0.4,0.5-0.7,1.1c-0.4,0.7-0.7,1.5-1,2.6c-0.3,1.1-0.6,2.2-0.7,3.4
	c-0.1,1.4-0.3,2.8-0.1,4.3c0,1.5,0.1,2.8,0.4,4c0.1,1.1,0.4,2.2,0.7,3.2l0.6,1.2l0.7,1.1l0.8,0.8l0.7,0.3h0.4l-0.3-0.1l0.3,0.1
	l0.7-0.6l0.7-1l1-2.8c0.6-2.3,0.8-5,0.8-7.8l-0.1-2.2c-0.1-1.9-0.6-3.6-1-5.1l-0.4-1.4l-0.7-1.1c-0.3-0.3-0.6-0.5-0.8-0.7l-0.4-0.1
	L90.1,287.5L90.1,287.5z M90.2,289.7c0.1-0.3,0.3-0.4,0.6-0.5c0.3,0,0.4,0,0.7,0.1c0.3,0.1,0.4,0.3,0.6,0.5l0.4,1l0.3,1.1
	c0.3,1.2,0.6,2.6,0.7,4.1v1.8c0,2.3-0.1,4.5-0.6,6.5l-0.3,1.2l-0.4,1l-0.4,0.8c0,0-0.4,0.4-0.6,0.4s-0.4,0-0.7-0.1l-0.4-0.4L90,307
	l-0.4-1l-0.3-1.1c-0.3-0.8-0.4-1.8-0.6-2.6c-0.1-1-0.3-2.1-0.3-3.3c0-1.2,0-2.3,0.1-3.4c0.1-1.1,0.3-1.9,0.4-2.9
	c0.1-0.8,0.4-1.6,0.7-2.2L90.2,289.7L90.2,289.7z"
          />
          <path
            fill="none"
            d="M90.8,289.1c-0.1,0.1-0.4,0.3-0.6,0.5l-0.6,0.8c-0.3,0.5-0.4,1.2-0.7,2.2c-0.1,0.8-0.3,1.8-0.4,2.9
	c-0.1,1.1-0.1,2.3-0.1,3.4c0,1.2,0,2.3,0.3,3.3c0.1,1,0.3,1.8,0.6,2.6l0.3,1.1l0.4,1l0.1,0.1l0.4,0.4c0.3,0.1,0.4,0.3,0.7,0.1
	c0.3-0.1,0.4-0.3,0.6-0.4l0.4-0.8l0.4-1l0.3-1.2c0.4-1.9,0.6-4.1,0.6-6.5l-0.1-1.8c-0.1-1.6-0.3-3-0.7-4.1l-0.3-1.1l-0.4-1
	c-0.1-0.3-0.3-0.4-0.6-0.5C91.2,289.1,90.9,289,90.8,289.1L90.8,289.1z M90.1,291.4c0.1-0.3,0.4-0.5,0.6-0.7v6.7l-0.3,0.3l-0.3,0.4
	l-0.4,0.1l-0.8-0.1c0-1.8,0.1-3.3,0.6-4.7C89.6,292.5,89.8,292,90.1,291.4L90.1,291.4z M90.8,290.9l0.1-0.3v2.6c0,0.1,0,0.3,0.1,0.1
	v-2.9h0.1c0.1,0.1,0.1,0.1,0.1,0.4v2.9h-0.1V291h-0.1v2.9h-0.1l-0.1-0.1V290.9L90.8,290.9z M90.8,294.3h0.3c0.1,0,0.1,0.1,0.1,0.3v3
	h-0.1v-2.8h-0.3v2.9v-2.9V294.3L90.8,294.3z M88.9,298.2c0.3,0.7,0.6,1,0.8,1.4l0.4,0.5c0.1,0.1,0.3,0.3,0.6,0.3v5.5l-0.6-0.4
	c-0.4-0.5-0.6-1.2-0.8-2.3c-0.1-0.7-0.3-1.4-0.3-2.2C88.9,300.1,88.9,299.2,88.9,298.2L88.9,298.2z M90.8,298.9h0.3l0.1,0.1v3.2
	l-0.1,0.1h-0.3l-0.1-0.1v-2.9C90.8,299,90.8,298.9,90.8,298.9L90.8,298.9z M90.8,298.5v-0.5h0.6v0.5H90.8z M91.5,290.9
	c0.3,0.3,0.4,0.5,0.7,1.1c0.1,0.4,0.4,1,0.6,1.5c0.3,1.4,0.6,2.9,0.6,4.7h-0.1l-0.7-1.2c-0.3-0.4-0.6-0.7-0.8-0.8
	C91.6,296.1,91.5,290.9,91.5,290.9z M92.4,298.2l0.7,0.1l-0.1,2.6l-0.3,2.2c-0.3,1.4-0.7,2.3-1.2,2.8v-6.7c0-0.1,0-0.3,0.1-0.4
	l0.1-0.3C92,298.3,92.2,298.3,92.4,298.2L92.4,298.2z M91.2,305.9h-0.4v-0.4h0.4v-2.3h-0.4v-0.5h0.6v2.9L91.2,305.9L91.2,305.9z"
          />
          <path
            fill="none"
            d="M90.7,290.8c-0.3,0.1-0.4,0.4-0.6,0.7c-0.3,0.4-0.6,1.1-0.7,1.9c-0.3,1.4-0.6,2.9-0.6,4.7l0.8,0.1l0.3-0.1
	l0.3-0.3l0.3-0.4l0.1-0.4L90.7,290.8L90.7,290.8z"
          />
          <path
            fill="none"
            d="M90.8,290.6l-0.1,0.3v3l0.1,0.1h0.1v-2.9h0.1v2.8h0.1V291c0-0.3,0-0.3-0.1-0.4L90.8,290.6v2.9
	c0,0.1-0.1,0-0.1-0.1L90.8,290.6L90.8,290.6z M90.9,294.3c-0.1,0-0.1,0.1-0.1,0.4v2.9v-2.9h0.3v2.8h0.1v-3c0-0.3,0-0.3-0.1-0.3
	L90.9,294.3L90.9,294.3z M88.9,298.3v2.6c0.1,0.8,0.1,1.5,0.4,2.2c0.1,1.1,0.4,1.8,0.8,2.3l0.6,0.4v-5.5c-0.3,0-0.4-0.1-0.6-0.3
	l-0.4-0.5C89.4,299.3,89.1,298.9,88.9,298.3z M91.1,298.9h-0.1c0,0-0.1,0.1-0.1,0.4v2.9l0.1,0.1h0.3l0.1-0.1V299l-0.1-0.1H91.1z
	 M91.2,299.4v2.3h-0.3v-2.3H91.2z"
          />
          <path
            fill="none"
            d="M91.2,301.6v-2.2h-0.3v2.2h0.1 M90.8,298.1v0.4h0.6v-0.4 M92.2,291.9c-0.3-0.4-0.4-0.8-0.7-1.1v5.2
	c0.3,0.1,0.6,0.4,0.8,0.8l0.7,1.2l0.1,0.1c0-1.8-0.1-3.3-0.6-4.7C92.6,292.8,92.4,292.3,92.2,291.9L92.2,291.9z M93.1,298.3
	l-0.7-0.1c-0.3,0-0.6,0.1-0.7,0.3l-0.1,0.3c0,0.1-0.1,0.3-0.1,0.3v6.7c0.6-0.4,1-1.4,1.2-2.8l0.3-2.2L93.1,298.3L93.1,298.3z"
          />
          <path
            fill="none"
            d="M91.2,305.9l0.3-0.4v-2.9h-0.7v0.5h0.4v2.3h-0.4v0.4L91.2,305.9L91.2,305.9z"
          />
          <path
            fill="none"
            d="M90.5,287.5l0.4,0.1c0.3,0.1,0.6,0.4,0.8,0.7l0.7,1.1l0.4,1.4c0.4,1.5,0.8,3.2,1,5.1l0.1,2.2
	c0,2.9-0.3,5.5-0.8,7.8l-1,2.8l-0.7,1l-0.7,0.4c0.8,0.4,3,0.7,6.5,0.8c-0.1-2.3-0.1-4.7-0.3-7v-10.5l0.3-6.9
	C93.3,286.6,91.1,286.9,90.5,287.5L90.5,287.5z"
          />
          <path
            fill="none"
            d="M90.8,310l-0.4,0.1v1.1c0.1,3,0.3,5.9,0.6,8.7c0.4,4.7,1.1,9.1,1.9,13.2c1.2,6.5,4.3,10.7,8.9,13.1
	c-2.5-10.2-4.1-22-4.7-35.4C93.7,310.7,91.6,310.4,90.8,310L90.8,310z M85.7,343.6c-0.8-5-1.4-9.8-1.9-14.7c0.7,8.8,1.4,16,2.2,21.6
	c0.7,5.4,1.7,10.5,2.8,15l0.1-0.1h0.3l0.1,0.1l-0.8-3.4l-1-1.9v-0.3l0.1-0.3h0.3C87.4,355.6,86.5,350.3,85.7,343.6L85.7,343.6z
	 M109.6,366.6l-1.8-2.9l-9.5-8.8c5,12.9,10,23.8,15,32.9c0.3,0,0.4,0.1,0.7,0.3l2.1,0.5c2.5,0.7,5,1,7.3,0.8
	c-2.6-3.2-4.7-6.2-6.5-9.1c-2.1-3.4-3.7-6.5-4.5-9.4C111.4,369.6,110.5,368.1,109.6,366.6L109.6,366.6z"
          />
          <path
            fill="none"
            d="M98.4,354.9l-2.5-1c4.5,14.6,9.4,25.9,14.3,33.8h3C108.4,378.7,103.3,367.8,98.4,354.9L98.4,354.9z M87.6,360
	v0.3l1,1.8l1.7,3.3l-0.8-3.3l-1.1-2.2l-0.1-0.1h-0.3"
          />
          <path
            fill="none"
            d="M88.2,359.7l0.1,0.1l1.1,2.2c-1.4-5.6-2.6-11.7-3.7-18.4C86.5,350.3,87.4,355.6,88.2,359.7L88.2,359.7z
	 M89,365.3l-0.1,0.1l-0.1,0.1l0.1,0.3l3.6,7.2v0.1V373l-1.1-3.7l-1.9-3.7l-0.1-0.3"
          />
          <path fill="none" d="M88.6,362l0.8,3.6l1.9,3.7l-1.1-4" />
          <path
            fill="none"
            d="M89.4,362l0.8,3.2l1.1,4l1.1,3.9v0.1c0.8,2.5,1.5,4.7,2.5,6.9l1.1,2.6c1.8,4.5,4,8.8,6.6,12.5
	c5.1,7.3,11.7,13.1,19.8,17.1c1.2,0.7,2.6,1.2,4,1.8c3.6,1.8,6.5,3.3,8.5,4.5l1.5,1c1.6,1.1,2.8,1.9,3.2,2.6c0.4,0.7,0,1.1-1.1,1.2
	l-1.2-0.4c-2.5-0.8-4.5-1.6-6.3-2.6c-13.8-5.1-25.3-13.5-34.9-25.2c-2.3-2.9-4.5-5.9-6.7-9.1c1.7,3.2,3.4,6.2,5.4,9.1
	c7.8,11.8,17.9,20.5,29.8,26.3c5.1,2.5,12.4,4.3,21.7,5.8l11.8,1.9c8.1,1.5,17.1,2.5,26.8,2.9c-11.4-4.5-22.1-8.1-32.5-10.9
	c-20.2-4.3-35.5-12.9-46.1-26.1c-3.9-4.8-7.2-10.3-9.8-16.4l13.9,8.8c-5-8.1-9.8-19.4-14.3-33.8l-10.3-10.2
	C86.8,350.3,88,356.5,89.4,362L89.4,362z"
          />
          <path
            fill="none"
            d="M83.6,373.9c1.7,4.4,3.4,8.4,5.5,12.2c2.1,3.3,4.4,6.3,6.7,9.1c9.6,11.7,21.3,20.1,34.9,25.2
	c-4.3-2.2-7-5-8.5-8c-8.1-4-14.7-9.8-19.8-17.1c-2.6-3.7-4.8-8-6.6-12.5L83.6,373.9L83.6,373.9z M113.4,387.7h-0.8
	c1.5,2.6,3,5.1,4.7,7.6c5.4,7.6,11.8,13.6,19.5,18c9.5,5.4,20.1,7.7,32.2,7.3c0.7,0,1,0,1.1-0.1c0.1-0.3-0.3-0.8-1.5-1.8l-5.8-0.8
	c-5.6,0-10.6-0.5-14.9-1.6c-4.9-1.5-8.8-3.3-11.7-5.4c-0.7-0.3-1.2-0.5-1.8-1c-5.5-3-10.6-7.8-15.4-14.6c-1.7-2.2-3.2-4.7-4.7-7.3
	C113.9,387.9,113.6,387.9,113.4,387.7L113.4,387.7z"
          />
          <path
            fill="none"
            d="M116.1,388.6L114,388c1.5,2.6,3,5.1,4.7,7.3c4.8,6.7,10,11.7,15.4,14.6c0.6,0.4,1.2,0.7,1.8,1
	c2.1,1,4.1,1.6,6.2,2.1c0.6-0.1,0.7-0.8,0.6-1.8l-3.3-0.5c-1-0.3-1.8-0.4-2.6-0.8c-5.9-2.2-11.4-7-16.5-14.4
	C118.9,393.2,117.5,390.9,116.1,388.6L116.1,388.6z"
          />
          <path
            fill="none"
            d="M123.5,389.4c-2.5,0-5-0.3-7.3-0.8c1.4,2.5,2.8,4.7,4.1,6.7c5.2,7.4,10.7,12.2,16.5,14.4l-0.3-5.8
	c-3-3-5.6-5.9-8.1-8.7L123.5,389.4L123.5,389.4z"
          />
          <path
            fill="none"
            d="M116.9,380.3c1.8,2.9,4,5.8,6.5,9.1l5,5.9c2.5,2.8,5.2,5.6,8.1,8.7l1.9,1.9h4.3c6.9,6.1,15,10,24.6,12.1
	c-3.2-2.3-9.1-6.2-17.6-11.4l-4.8-2.9l-5.6-3.9c-1.9,0.5-4.4-0.7-7.3-3.4l-1.4-1.2C126.1,391.3,121.6,386.4,116.9,380.3L116.9,380.3
	z"
          />
          <path
            fill="none"
            d="M112.4,371c1,2.9,2.5,5.9,4.5,9.4c4.5,6.1,9.1,11,13.6,14.9l1.4,1.2c3,2.8,5.4,3.9,7.3,3.4l-5.8-4.5
	C125.6,388.4,118.6,380.3,112.4,371L112.4,371z M136.6,404l0.3,5.8c1,0.4,1.8,0.5,2.6,0.8l-0.1-3.9l-0.8-0.8L136.6,404L136.6,404z"
          />
          <path
            fill="none"
            d="M138.5,405.9l0.8,0.8l1.5,1.4l1.8,1.4c5.1,3.9,11.1,6.5,18,8l2.1,0.4l5.8,0.8l-1.1-0.7
	c-9.6-2.1-17.9-6.1-24.6-12.1L138.5,405.9L138.5,405.9z"
          />
          <path
            fill="none"
            d="M140.9,408.1l-1.5-1.4l0.1,3.9l3.3,0.5c0-0.4-0.1-1-0.3-1.5L140.9,408.1L140.9,408.1z"
          />
          <path
            fill="none"
            d="M142.7,409.5c0.1,0.5,0.3,1.1,0.3,1.5c0.1,1-0.1,1.7-0.6,1.8c4.4,1.9,10.6,3.4,18.3,4.5
	C153.8,415.9,147.7,413.3,142.7,409.5L142.7,409.5z M138.4,423.5c1.1-0.1,1.5-0.5,1.1-1.2c-2.2,0-3.2-0.3-3.2-0.8v-0.8
	c-1.2-0.3-1.8-1-1.5-1.8c-2.2-1.2-5-2.8-8.5-4.5c0.1,2.6,3.7,5.6,10.9,8.8L138.4,423.5L138.4,423.5z"
          />
          <path
            fill="none"
            d="M136.3,419.8l-1.5-1c-0.1,1,0.3,1.5,1.5,1.8V419.8z M130.8,420.5c1.8,1,4,1.9,6.3,2.6
	c-7.2-3.3-10.7-6.2-10.9-8.8c-1.4-0.5-2.6-1.1-4-1.8C123.7,415.5,126.6,418.1,130.8,420.5L130.8,420.5z M136.3,420.6v0.8
	c0,0.5,1,0.8,3.2,0.8c-0.4-0.7-1.5-1.5-3.2-2.6V420.6z M142.2,412.9c-2.1-0.4-4.1-1.1-6.2-2.1c2.9,2.2,6.9,4,11.7,5.4
	c4.3,1.1,9.2,1.6,14.9,1.6l-2.1-0.4C152.8,416.4,146.8,414.8,142.2,412.9L142.2,412.9z M333,454.9l-0.3-0.8
	c-6.6,0.6-11.3-2.2-13.9-7.8c-1-1.2-1.6-1.4-2.2-0.4c3.9,7.3,9.4,10.6,16.5,9.9L333,454.9L333,454.9z M747.7,266.4
	c0.4,2.9,0.8,5.8,1.2,8.5l1.8,5.5c0.4,4,0.5,7.8,0.7,11.8v12.1c-0.1,3.9-0.4,7.8-0.7,11.8l-1.8,5.5l-1.2,8.5
	c-0.5,11.3,0.7,15.7,3.7,13.3l5-6.5c-2.1-1.4-2.8-5.5-2.1-12.4c0.4-5,0.8-9.8,1-14.4l0.3-11.1l-0.3-12.8c-0.3-4.7-0.5-9.5-1-14.4
	c-0.7-6.9,0-11,2.1-12.4l-5-6.3C748.2,250.6,747,255.1,747.7,266.4L747.7,266.4z M753.3,290.1c0.1,0,0.3,0,0.4,0.1l0.3,0.7l0.7,4.3
	l0.1,6.5l-0.7,4.7l-0.3,0.8l-0.3,0.5c-0.1,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.3-0.3-0.5l-0.4-1.9c-0.1-1.1-0.3-2.5-0.3-4l-0.1-1.6
	c0-2.2,0.1-4.3,0.3-6.1c0-0.4,0.1-0.8,0.1-1.1s0.1-0.7,0.3-1l0.3-0.8L753.3,290.1L753.3,290.1z"
          />
          <path
            fill="none"
            d="M753.7,290.2c-0.1-0.1-0.3-0.3-0.4-0.1l-0.3,0.4l-0.3,0.8c-0.1,0.3-0.1,0.5-0.1,1c-0.1,0.3-0.1,0.7-0.1,1.1
	c-0.3,1.8-0.4,3.9-0.4,6.1l0.1,1.6c0.1,1.5,0.1,2.9,0.3,4l0.4,1.9c0.1,0.3,0.3,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.4,0.1l0.3-0.5l0.3-0.8
	l0.7-4.7l-0.1-6.5l-0.7-4.3L753.7,290.2L753.7,290.2z M753.3,291.7h0.1v0.4h-0.3v2.2h0.3v0.4h-0.3V292L753.3,291.7z M753.3,295.3
	l0.3-0.1l0.1,0.1v3h-0.3v-0.3L753.3,295.3L753.3,295.3z M754,297.2l-0.3-0.3v-5.2c0.3,0.4,0.6,1.2,0.8,2.6l0.3,4.7v-0.1L754,297.2
	L754,297.2z M753.2,298.1v0.3l-0.1,0.3l-0.4,0.3l-0.4-0.1v-2.5l0.1-2.1c0.1-1.4,0.4-2.2,0.7-2.6L753.2,298.1L753.2,298.1z
	 M753.3,298.6h0.3v0.5h-0.3L753.3,298.6L753.3,298.6L753.3,298.6z M753.8,299.4c0.1-0.3,0.3-0.4,0.4-0.4c0.1,0,0.3,0,0.6,0.1
	c0,1.6-0.1,3.2-0.3,4.4c-0.1,1.4-0.4,2.2-0.8,2.6v-6.3L753.8,299.4L753.8,299.4z M753.6,299.4v3h-0.3v-3.2v2.6h0.3V299.4
	L753.6,299.4z M752.2,299l0.4,1.2l0.6,0.8v4.8c-0.1-0.3-0.3-0.5-0.4-1c-0.1-0.4-0.3-1-0.3-1.5C752.2,302.2,752.2,300.7,752.2,299
	L752.2,299z M753.4,303l0.1,0.1v3v-2.5l-0.1-0.1v2.8h-0.1v-3.2L753.4,303l-0.1,2.6h0.1V303L753.4,303z"
          />
          <path
            fill="none"
            d="M753.4,291.7h-0.1v3.2h0.3v-0.5h-0.3v-2.2h0.3v-0.4 M753.3,295.2v3.2h0.3v-3.2L753.3,295.2L753.3,295.2z
	 M753.3,295.7h0.3v2.2h-0.1l-0.1-0.1L753.3,295.7L753.3,295.7L753.3,295.7z"
          />
          <path
            fill="none"
            d="M753.4,295.7h-0.1v2.1l0.1,0.1 M753.7,296.8l0.3,0.3l0.7,1.6v0.1l-0.3-4.7c-0.1-1.4-0.4-2.2-0.8-2.6
	L753.7,296.8L753.7,296.8z M753,298.5v-6.6c-0.3,0.4-0.5,1.2-0.7,2.6l-0.1,2.1v2.5l0.4,0.1l0.4-0.3L753,298.5L753,298.5z
	 M753.6,298.6h-0.3v0.5h0.3"
          />
          <path
            fill="none"
            d="M754.3,298.9l-0.4,0.4l-0.1,0.3v6.3c0.3-0.4,0.5-1.2,0.8-2.6c0.1-1.2,0.3-2.8,0.3-4.4L754.3,298.9L754.3,298.9z
	 M753.6,299.4v2.5l-0.1,0.3h-0.1v-2.8v3.2h0.3v-0.3 M752.6,300.3l-0.4-1.2c0,1.7,0.1,3.2,0.3,4.4c0.1,0.5,0.1,1.1,0.3,1.5
	c0.1,0.4,0.3,0.8,0.4,1v-4.8L752.6,300.3L752.6,300.3z M753.4,303v2.8l-0.1-0.1V303v3.2h0.1v-2.8l0.1,0.1v2.6v-3"
          />
          <path
            fill="none"
            d="M756.2,259.4c-2.1,1.4-2.8,5.5-2.1,12.4c0.4,5,0.8,9.8,1,14.4l0.3,12.8l-0.3,11.1c-0.3,4.7-0.5,9.5-1,14.4
	c-0.7,6.9,0,11,2.1,12.4c1.9-12.5,3-25.4,3.2-38.8C759.2,284.8,758.3,271.9,756.2,259.4L756.2,259.4z M751.4,292.1
	c-0.1-3.9-0.4-7.8-0.7-11.8l-1.8-5.5l0.6,4.5c0.4,4.3,0.7,8.5,0.8,12.7v12.1c-0.1,4.1-0.4,8.4-0.8,12.8l-0.5,4.5l1.8-5.5
	c0.3-4,0.5-8,0.7-11.8L751.4,292.1L751.4,292.1z"
          />
          <path
            fill="none"
            d="M756.2,337l-5,6.5c-3,2.3-4.3-2.1-3.7-13.3c-2.2,13.3-5.6,27.2-10.6,41.4c-1.4,4-3.2,7.8-5.4,11.7l4,0.3
	l2.6-0.6c1.2-0.4,2.5-1.9,4-4.7l4.3-7.8l2.9-3.9l0.8-1.1C752.7,356.2,754.7,346.7,756.2,337L756.2,337z"
          />
          <path
            fill="none"
            d="M750.1,365.6l-0.8,1.1l-3.4,11.1c-3.3,8.8-6.1,14.9-8.5,18.4l-0.7,1.6c-1,1.9-1.9,3.6-2.9,4.8
	c-1.7,2.3-3.3,3.6-5,3.9l-0.4,1.5c1.7,0,3.6-1.1,5.5-3c2.6-3.3,5.4-8.3,8.5-14.9c3-7,5.8-15.3,8.5-24.6L750.1,365.6L750.1,365.6
	L750.1,365.6z"
          />
          <path
            fill="none"
            d="M755.6,365.6h-4.8c-2.6,9.5-5.5,17.6-8.5,24.6c-3,6.6-5.9,11.6-8.5,14.9c-1.1,1.4-2.1,2.5-3.2,3.3
	c-0.7,0.6-1.7,1.1-2.9,1.4c-1.5,0.4-3.4,0.5-5.6,0.5L717,410l-0.4,0.1l-0.7-0.1c-1-0.4-0.4-1.8,1.7-4.3c5.8-5.6,11.4-12.8,17.2-21.3
	l0.5-1l-4-0.3c-6.9,11.8-17.6,22.8-32.3,32.7c8.3-0.4,15-0.3,20.5,0.1c6.6-2.9,11.6-6.5,15.1-10.7
	C742.2,397.1,749,383.9,755.6,365.6L755.6,365.6z M752.9,386.4l0.7-2.6c-3.3,10-6.5,16.1-9.4,18.2c-4,2.9-6.7,5.9-8.1,8.8
	c-0.7,4.1-5.2,7.1-13.6,9.2l3.3-0.7c10.5-2.3,17.2-5.9,20.1-10.9c4-7.2,6.2-14.2,6.9-20.9L752.9,386.4L752.9,386.4z"
          />
          <path
            fill="none"
            d="M749.3,366.7l-2.9,3.9c-3,10-6.1,18.6-9.1,25.9c2.5-3.6,5.2-9.8,8.5-18.4L749.3,366.7z"
          />
          <path
            fill="none"
            d="M738.2,382.9l-2.6,0.5l-0.5,1l-0.3,1.5c1.2,1.5,1,7.2-0.8,16.9c1-1.2,1.9-2.9,2.9-4.8l0.7-1.6
	c3-7.2,6.1-15.8,9.1-25.9l-4.3,7.8C740.6,381,739.4,382.5,738.2,382.9L738.2,382.9z M734.6,386l0.3-1.5
	c-5.8,8.5-11.6,15.7-17.2,21.3c-2.1,2.5-2.6,3.9-1.7,4.3c5.5-0.7,9.6-2.2,12.1-4.7C730.2,402.9,732.4,396.5,734.6,386L734.6,386z
	 M728,409.8c1.2-0.3,2.2-0.8,2.9-1.4c1-0.8,2.1-1.9,3.2-3.3c-1.9,2.1-3.9,3-5.5,3L728,409.8L728,409.8z M728,409.8l0.4-1.5
	c-4,0.6-6.1,1.2-6.1,2.1C724.5,410.3,726.5,410.2,728,409.8L728,409.8z"
          />
          <path
            fill="none"
            d="M728.4,408.2l0.4-1.5c1.7-0.3,3.3-1.6,5-3.9c1.8-9.8,2.1-15.4,0.8-16.9c-2.2,10.6-4.4,17.1-6.5,19.4
	c-2.5,2.5-6.6,4-12.1,4.7l0.7,0.1l0.4-0.1l5.1,0.3C722.3,409.5,724.4,408.8,728.4,408.2L728.4,408.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M753.4,303v2.8l-0.1-0.1V303v3.2h0.1v-2.8l0.1,0.1v2.6v-3L753.4,303
	L753.4,303z M752.2,299c0,1.7,0.1,3.2,0.3,4.4c0.1,0.5,0.1,1.1,0.3,1.5c0.1,0.4,0.3,0.8,0.4,1v-4.8l-0.5-0.8L752.2,299L752.2,299z
	 M753.6,299.4v2.5l-0.1,0.3h-0.1v-2.8v3.2h0.3L753.6,299.4L753.6,299.4z M753.8,299.4l-0.1,0.3v6.3c0.3-0.4,0.5-1.2,0.8-2.6
	c0.1-1.2,0.3-2.8,0.3-4.4c-0.1-0.1-0.3-0.1-0.5-0.1C754.1,299,754,299.2,753.8,299.4L753.8,299.4z M753.3,298.6v0.5h0.3v-0.5H753.3z
	 M753.2,298.1v-6.3c-0.3,0.4-0.5,1.2-0.7,2.6l-0.1,2.1c0,0.8-0.1,1.7-0.1,2.5l0.4,0.1l0.4-0.3l0.1-0.3V298.1L753.2,298.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M754,297.2l0.7,1.6v0.1l-0.3-4.7c-0.1-1.4-0.4-2.2-0.8-2.6v5.2L754,297.2
	L754,297.2z M753.4,295.7v2.2l-0.1-0.1v-2.1L753.4,295.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M753.3,295.3v3h0.3v-3l-0.1-0.1h-0.1L753.3,295.3z M753.3,291.7v3.2h0.3
	v-0.5h-0.3v-2.2h0.3v-0.4h-0.1L753.3,291.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M753.3,290.1l-0.3,0.4l-0.3,0.8c-0.1,0.3-0.1,0.7-0.1,1
	c-0.1,0.4-0.1,0.7-0.1,1.1c-0.3,1.8-0.4,3.9-0.4,6.1l0.1,1.6c0.1,1.5,0.1,2.9,0.3,4l0.4,1.9c0.1,0.3,0.3,0.4,0.3,0.5
	c0.1,0.1,0.3,0.1,0.4,0.1l0.3-0.5l0.3-0.8l0.7-4.7l-0.1-6.5l-0.7-4.3l-0.3-0.7C753.6,290.1,753.4,289.9,753.3,290.1L753.3,290.1z
	 M90.2,289.7l-0.6,0.8c-0.3,0.5-0.4,1.2-0.7,2.2c-0.1,0.8-0.3,1.8-0.4,2.9c-0.1,1.1-0.1,2.3-0.1,3.4c0,1.2,0,2.3,0.3,3.3
	c0.1,1,0.3,1.8,0.6,2.6l0.3,1.1l0.4,1l0.1,0.1l0.4,0.4c0.3,0.1,0.4,0.3,0.7,0.1c0.3-0.1,0.4-0.3,0.6-0.4l0.4-0.8l0.4-1l0.3-1.2
	c0.4-1.9,0.6-4.1,0.6-6.5l-0.1-1.8c-0.1-1.6-0.3-3-0.7-4.1l-0.3-1.1l-0.4-1c-0.1-0.3-0.3-0.4-0.6-0.5c-0.3-0.1-0.4-0.1-0.7-0.1
	C90.5,289.1,90.4,289.4,90.2,289.7L90.2,289.7z M90.1,291.4c-0.3,0.4-0.6,1.1-0.7,1.9c-0.3,1.4-0.6,2.9-0.6,4.7l0.8,0.1l0.3-0.1
	l0.3-0.3l0.3-0.4l0.1-0.4v-6.2C90.4,291,90.2,291.2,90.1,291.4L90.1,291.4z M90.8,290.9v3l0.1,0.1h0.1v-2.9h0.1v2.8h0.1V291
	c0-0.3,0-0.3-0.1-0.4h-0.3v2.9c0,0.1-0.1,0-0.1-0.1v-2.6V290.9z M90.8,294.3c0,0-0.1,0.1-0.1,0.4v2.9h0.1v-2.9h0.3v2.8h0.1v-3
	c0-0.3,0-0.3-0.1-0.3h-0.1L90.8,294.3L90.8,294.3z M88.9,298.2c0,1,0,1.9,0.1,2.8c0,0.8,0.1,1.5,0.3,2.2c0.3,1.1,0.4,1.8,0.8,2.3
	l0.6,0.4v-5.5c-0.3,0-0.4-0.1-0.6-0.3l-0.4-0.5C89.4,299.3,89.1,298.9,88.9,298.2L88.9,298.2z M90.8,298.9c0,0-0.1,0.1-0.1,0.4v2.9
	l0.1,0.1h0.3l0.1-0.1V299l-0.1-0.1H90.8z M90.8,298.5h0.6v-0.5h-0.6V298.5z M91.5,290.9v5.2c0.3,0.1,0.6,0.4,0.8,0.8l0.7,1.2
	l0.1,0.1c0-1.8-0.1-3.3-0.6-4.7c-0.1-0.7-0.3-1.1-0.6-1.5C92,291.5,91.8,291.2,91.5,290.9L91.5,290.9z M92.4,298.2
	c-0.3,0-0.6,0.1-0.7,0.3l-0.1,0.3c-0.1,0.1-0.1,0.3-0.1,0.4v6.7c0.6-0.4,1-1.4,1.2-2.8l0.3-2.2l0.1-2.6L92.4,298.2L92.4,298.2z
	 M91.2,301.6h-0.3v-2.3h0.3V301.6z M91.2,305.9l0.1-0.4v-2.9h-0.6v0.5h0.4v2.3h-0.4v0.4L91.2,305.9L91.2,305.9z M85.7,343.6
	c0.8,6.6,1.7,12,2.5,16.1 M160.7,417.5c-7.7-1.1-13.9-2.6-18.3-4.5 M160.7,417.5c-6.9-1.5-12.9-4.1-18-8 M162.7,417.9l5.8,0.8
	 M162.7,417.9l-2.1-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M136.1,410.9c2.9,2.2,6.9,4,11.7,5.4c4.3,1.1,9.2,1.6,14.9,1.6 M142.9,411
	c0.1,1-0.1,1.7-0.6,1.8 M142.2,412.9c-2.1-0.4-4.1-1.1-6.2-2.1 M126.3,414.2c0.1,2.6,3.7,5.6,10.9,8.8 M136.9,409.8
	c-5.9-2.2-11.4-7-16.5-14.4c-1.4-2.1-2.8-4.3-4.1-6.7 M136.3,420.6v-0.8 M134.8,418.8c-0.1,1,0.3,1.5,1.5,1.8 M136.3,420.6v0.8
	c0,0.5,1,0.8,3.2,0.8 M138.5,405.9l-1.9-1.9 M136.9,409.8c1,0.4,1.8,0.5,2.6,0.8 M123.5,389.4l5,5.9c2.5,2.8,5.2,5.6,8.1,8.7
	 M138.5,405.9h4.3c6.9,6.1,15,10,24.6,12.1 M139.5,410.6l-0.1-3.9 M142.9,411l-3.4-0.4 M142.7,409.5c0.1,0.5,0.3,1.1,0.3,1.5
	 M142.7,409.5l-1.8-1.4l-1.5-1.4 M139.4,406.7l-0.8-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M114,388c1.5,2.6,3,5.1,4.7,7.3c4.8,6.7,10,11.7,15.4,14.6
	c0.6,0.3,1.2,0.7,1.8,1 M136.6,404l0.3,5.8 M116.9,380.3c4.5,6.1,9.1,11,13.6,14.9l1.4,1.2c3,2.8,5.4,3.9,7.3,3.4 M112.4,371
	c1,2.9,2.5,5.9,4.5,9.4 M116.9,380.3c1.8,2.9,4,5.8,6.5,9.1 M123.5,389.4c-2.5,0-5-0.3-7.3-0.8 M116.1,388.6L114,388 M114,388
	c-0.3-0.1-0.4-0.1-0.7-0.3 M89.4,365.6l1.9,3.7 M92.6,373.3l-0.1-0.1 M92.4,373.2V373 M88.9,365.5l-0.1,0.1l0.1,0.3l3.6,7.2v0.1
	 M88.6,362l1.7,3.3 M88.6,362l0.8,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M89.4,365.6l-0.1-0.3H89l-0.1,0.1 M89.4,362l-1.1-2.2l-0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M88.2,359.7h-0.3l-0.3,0.3v0.3l1,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M88.9,365.5c-1.1-4.5-2.1-9.6-2.8-15c-0.8-5.6-1.7-12.9-2.2-21.6v-0.3
	 M284.2,401.2c-13.3,0.3-24.6-8.9-33.7-27.4 M284.2,401.2c-11.6-22.7-19-51.5-22.1-86.5v-0.1c-0.4-3.2-0.7-6.2-0.8-9.4l-0.1-3.3
	l0.1-10.3c0.1-3.2,0.4-6.3,0.8-9.4v-0.1c3.2-35.1,10.6-64,22.1-86.5 M284.2,195.3c-13.3-0.3-24.6,8.8-33.7,27.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M284.8,210.4c-10.3,19.5-16,48.8-16.8,87.9c1,39.1,6.5,68.4,16.8,87.9
	c2.5,5.1,7.2,7.3,14,6.6c20.2-3.4,41.1-7.7,62.6-12.8c4.3-1.2,6.3-4.4,5.9-9.4l-0.4-2.6c-2.9-21.7-4.4-42.1-4.8-61.1v-17.3
	c0.3-19.1,1.9-39.5,4.8-61.1l0.4-2.6c0.3-5-1.6-8.1-5.9-9.4c-21.5-5-42.2-9.2-62.6-12.8C291.9,203,287.2,205.2,284.8,210.4
	L284.8,210.4z M284.2,195.3v-0.1 M142.7,186.9c5.1-3.9,11.1-6.5,18-8 M160.7,178.9c-7.7,1.1-13.9,2.6-18.3,4.5 M116.1,207.8
	c2.5-0.6,5-0.8,7.3-0.8 M123.5,207c-2.6,3.2-4.7,6.2-6.5,9.1 M113.4,208.6l0.7-0.3 M114,208.4l2.1-0.6 M116.9,216.1
	c-2.1,3.4-3.7,6.5-4.5,9.4 M136.6,192.5c-5.2,5.2-9.5,10-13.1,14.6 M126.3,182.2c0.1-2.6,3.7-5.6,10.9-8.9 M142.9,185.4
	c0.1-1.1-0.1-1.6-0.6-1.9 M142.2,183.6c-2.1,0.4-4.1,1.1-6.2,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M116.9,216.1c5.1-6.6,10.2-12,15.1-16.1c3-2.8,5.4-3.9,7.3-3.4
	 M138.5,190.6l-1.9,1.9 M136.9,186.8c1-0.3,1.8-0.6,2.6-0.8 M114,208.4c5-8.4,10.2-14.9,15.8-19.1c1.4-1.1,2.9-2.1,4.4-2.9
	c0.6-0.4,1.2-0.7,1.8-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M136.9,186.8c-1.8,0.7-3.4,1.5-5.2,2.6c-5.5,3.7-10.7,9.9-15.5,18.4
	 M136.6,192.5l0.3-5.8 M142.9,185.4c-1.1,0-2.2,0.3-3.3,0.6 M139.5,185.9l-0.1,3.9 M138.5,190.6l0.8-0.8 M139.4,189.8l0.4-0.4
	l2.9-2.5 M142.7,186.9l0.3-1.5 M167.4,178.5c-8.9,1.9-16.6,5.5-23.2,10.9l-1.4,1.2h-4.3 M139.5,174.1c-2.2,0-3.2,0.3-3.2,0.8v0.8
	 M134.8,177.7c-0.1-1,0.3-1.5,1.5-1.8 M136.3,175.9v0.8 M168.5,177.7l-5.8,0.8 M136.1,185.7c2.9-2.2,6.9-4,11.7-5.4
	c4.3-1.1,9.2-1.6,14.9-1.6 M162.7,178.5l-2.1,0.4 M91.3,227.2l-1.9,3.7 M88.2,236.7c-0.8,4.1-1.7,9.4-2.5,16.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M83.8,267.8v-0.1c0.7-8.8,1.4-16,2.2-21.6c0.7-5.4,1.7-10.5,2.8-15
	 M89.4,234.4l-1.1,2.2l-0.1,0.1 M88.6,234.4l0.8-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M88.2,236.7h-0.3l-0.1-0.3v-0.3l1-1.9 M88.6,234.4l1.7-3.2 M89.4,230.9
	l-0.1,0.1H89l-0.1-0.1 M92.4,223.4l0.1-0.3 M88.9,230.9v-0.3l3.6-7.2l0.1-0.1 M684.8,192.3h16.6l0.3,0.1h2.2l0.3-0.1v-0.6h-19.4
	 M670.5,234.2c-5.2-2.2-10.7-3.4-16.5-3.6c-26.7,0.4-50.8,1.4-72.2,3.2c-4.7,0.3-6.6,2.2-5.9,5.9c1.4,19.9,1.9,39.5,1.7,58.6
	c0.3,19.1-0.3,38.5-1.7,58.5c-0.7,3.7,1.4,5.8,5.9,5.9c21.5,1.8,45.5,2.8,72.2,3.2c5.6-0.1,11.1-1.4,16.5-3.6
	c7.8-4,12.5-8.3,14-12.7c2.8-12.7,4.5-25,5.2-37.3l0.1-1.9c0.3-4.3,0.3-8.7,0.3-12.9c0-4-0.1-7.8-0.3-11.8l-0.1-1.6
	c-0.8-12.2-2.5-24.6-5.2-37.3C683,242.3,678.3,238.2,670.5,234.2L670.5,234.2z M733.8,193.5c-1.7-2.2-3.3-3.6-5-3.9l-0.4-1.5
	 M728.4,188.1l-0.4-1.5 M737.3,200.1l-0.7-1.8c-1-1.9-1.9-3.6-2.9-4.8 M733.9,191.3c-1.9-2.1-3.9-3.2-5.5-3.2 M728.4,188.1
	c-4-0.6-6.1-1.2-6.1-2.1 M734.6,210.6c-2.2-10.6-4.4-17.1-6.5-19.4c-2.5-2.5-6.6-4-12.1-4.7 M735,212.1l-0.4-1.5 M749.3,229.7
	l-3.4-11.1c-3.3-8.8-6.1-14.9-8.5-18.4 M733.8,193.5c1.8,9.8,2.1,15.4,0.8,16.9 M737.3,200.1c3,7.2,6.1,15.8,9.1,25.9 M746.4,370.4
	c-3,10-6.1,18.6-9.1,25.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M749.3,366.7l-3.4,11.1c-3.3,8.8-6.1,14.9-8.5,18.4 M735,384.4l-0.4,1.5
	 M737.3,396.3l-0.7,1.6c-1,1.9-1.9,3.6-2.9,4.8 M728.4,408.2l-0.4,1.5 M733.8,402.9c-1.7,2.3-3.3,3.6-5,3.9l-0.4,1.5 M733.9,405.1
	c-1.9,2.1-3.9,3-5.5,3 M722.2,410.3c0.1-0.8,2.2-1.5,6.1-2.1 M734.6,386c-2.2,10.6-4.4,17.1-6.5,19.4c-2.5,2.5-6.6,4-12.1,4.7
	 M733.8,402.9c1.8-9.8,2.1-15.4,0.8-16.9 M731.6,213.2l-42,2.6 M699.2,180.4c14.7,9.9,25.5,20.9,32.3,32.7 M719.7,180.3
	c-5.4,0.6-12.2,0.6-20.5,0.1 M750.8,230.9h-0.7 M755.6,230.9h-4.8 M747.7,266.4c-2.2-13.3-5.6-27.2-10.6-41.4
	c-1.4-4-3.2-7.8-5.4-11.7 M731.6,213.2l4-0.1 M750.1,230.9l-0.8-1.1l-2.9-3.7l-4.3-7.8c-1.5-2.8-2.8-4.3-4-4.7l-2.6-0.4
	 M753.6,212.8l-0.7-2.6l-0.1-1c-0.5-6.9-2.9-13.8-6.9-20.9c-2.9-5-9.6-8.7-20.1-11l-3.3-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M753.6,212.8c-3.3-10-6.5-16.1-9.4-18.2c-4-3-6.7-5.9-8.1-8.8
	c-0.7-4.1-5.2-7.2-13.6-9.2 M719.7,180.3c6.6,2.9,11.6,6.5,15.1,10.9c7.3,8.3,14.2,21.6,20.8,39.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M750.8,230.9c-2.6-9.5-5.5-17.6-8.5-24.6c-3-6.6-5.9-11.6-8.5-14.9
	c-1.1-1.4-2.1-2.5-3.2-3.3c-0.7-0.6-1.7-1.1-2.9-1.4c-1.5-0.4-3.4-0.6-5.6-0.6l-5.1,0.3l-0.4-0.1l-0.7,0.1c-1,0.4-0.4,1.8,1.7,4.3
	c5.8,5.6,11.4,12.8,17.2,21.3l0.5,1 M722.3,176.6l-2.1-0.4c-5.4-1.2-10.7-2.8-16-4.5c-7-2.6-15.4-4.4-25.2-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M679.2,166.3c3.9,3.3,12.2,6.3,25,9.2c5.9,1.2,11.1,2.8,15.5,4.7
	 M676.4,193.8v-0.1 M676.4,193.8v-0.1 M676.4,193.6c0.8-0.6,1.5-0.7,2.3-0.6l5.2-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M684,191.2l-5.6-0.3c-0.8,0-1.5-0.3-2.2-0.7l-0.4-0.3l-0.5-0.3
	c-0.5-0.1-1.1-0.1-1.5,0.3c-0.4,0.3-0.7,0.7-0.7,1.4c0,0.6,0.1,1.1,0.5,1.6c0.4,0.6,0.8,1,1.4,1.1c0.5,0.1,1.1,0.1,1.5-0.1
	 M684,192.8c0.3,0,0.4-0.1,0.7-0.3l0.1-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M684,192.8c-0.1,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.1,0.4-0.3 M684,191.2h0.3 M684.2,191.2H684 M684.8,192.3l-0.7-0.4l0.8-0.1l-0.3-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.3 M699.2,180.4l-17.1-1.4l-13.9-1.4l-11.1-0.8l-11.3-0.6c-44.3-2.1-90.9-3-139.6-3.2H500 M566.3,168.9
	c5.1-2.5,11.6-3.9,19.5-4c29.6-0.4,59.4-0.1,89.6,1.2l3.6,0.3 M92.4,223.4c-0.4,1.2-0.8,2.5-1.1,3.7l-1.1,4l-0.8,3.3
	c-1.4,5.6-2.6,11.7-3.7,18.4 M94.9,216.2l1.1-2.6 M92.6,223.1c0.8-2.5,1.5-4.7,2.5-6.9 M83.6,222.5c-0.6,1.5-0.3,2.3,0.7,2.6
	c-4.7,20.5-7.2,44.8-7.7,73c0.6,28.2,3.2,52.5,7.7,73c-1,0.3-1.2,1.1-0.7,2.6 M89,210.3c-2.1,3.9-3.9,7.8-5.5,12.2 M98.4,241.5
	l-2.3,1 M107.8,232.7l-9.5,8.8 M101.9,250.3c1.7-6.5,3.6-12.4,6.1-17.6 M96,242.5l-10.3,10.3 M109.6,229.8l-1.8,2.9 M96,213.7
	l-12.4,8.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M94.9,216.2l-7,14.2c-1.4,3.2-2.3,7.4-2.9,12.7
	c-2.8,21.9-4.1,40.3-4.4,55.3c0.3,15,1.7,33.3,4.4,55.2c0.6,5.2,1.5,9.5,2.9,12.7l7,14 M184.6,164.2c-9.8,0.4-18.6,1.4-26.8,2.9
	L146,169c-9.4,1.5-16.5,3.3-21.7,5.8c-7.6,3.7-14.3,8.5-20.4,14.6c-5.8,5.9-10.7,12.8-15,20.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M130.8,176c-8.5,3.2-16.2,7.7-23.2,13.3c-6.9,5.8-13.1,12.7-18.4,20.9
	 M170,176c-0.1-0.1-0.6-0.3-1.1-0.1c-12-0.4-22.7,2.1-32.2,7.3c-3.2,1.8-6.1,3.9-8.8,6.2c-6.1,5.1-11.1,11.6-15.4,19.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M145,192.8l4.8-2.9c8.5-5.2,14.4-9.1,17.6-11.4c0-0.1,0.1-0.1,0.3-0.1
	l0.8-0.6c1.2-0.8,1.8-1.5,1.5-1.8 M122.3,184c1.2-0.7,2.6-1.2,4-1.8c3.6-1.8,6.5-3.3,8.5-4.7l1.5-1c1.6-1.1,2.8-1.9,3.2-2.6
	c0.4-0.7,0-1.1-1.1-1.1l-1.2,0.4c-2.5,0.8-4.5,1.6-6.3,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.5,197.6l-5.1-3c-6.6-3.2-13.6-4.7-20.9-4.8
	c-32.2,0.3-62.2,1.2-89.8,2.8l-1.5,0.1 M145,192.8c-1.9,1.2-3.9,2.5-5.6,3.9c-10.5,7.7-19.4,17.2-27,28.9c-1,1.4-1.8,2.9-2.8,4.4
	 M96,213.7c4-10.2,9.9-18.3,17.7-24.4c2.6-2.1,5.5-3.7,8.5-5.4 M130.8,176c-4.3,2.2-7,5-8.5,8.1 M112.5,208.6h0.8
	c-5,9.1-10,20.1-15,32.9 M110.3,208.6h2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M184.6,164.2c-11.4,4.5-22.1,8.1-32.5,10.9c-13.3,2.9-24.6,7.6-33.8,14.3
	c-9.8,7.2-17.1,16.5-22,28.1l13.9-8.8 M110.3,208.6c-5,8.1-9.8,19.4-14.3,33.8 M109.6,229.8c12.4-3.7,24.6-7.2,36.9-10.3
	c39.9-10.3,78.6-17.6,116-21.9 M316.7,150.7c-0.7,1.2-1.2,2.5-1.8,3.9c-2.2,6.5-3.2,11.4-2.9,14.9 M332.8,142.5l0.3-0.8
	 M316.7,150.7c0.5,0.8,1.4,0.7,2.2-0.4c2.6-5.6,7.3-8.3,13.9-7.8 M299.8,169.2c-7.3-2.8-16.8-4.4-28.9-5c-30-1-57.5-1-82.4,0
	l-3.7,0.1 M327.8,169.7l-0.8,3.4 M323.5,180.4v-1.2 M323.5,181.5v-1.1 M323.8,182.5l-0.3-1 M323.5,179.2c-0.3,0.7-1.2,1-2.8,1.1
	c-4.4-0.3-6.5-1.1-6.3-2.6 M329.7,183.1l0.1,0.4c0.3,1,0.8,2.1,1.4,3.2 M327,173.1l-0.4,1.4l-1.6,0.3 M323.5,179.2
	c0.1-1.4,0.7-2.9,1.4-4.4 M329.7,183.1c-0.5-1.4-2.6-1.9-6.2-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M314.5,174.4c1.4,1.1,3.3,1.8,5.8,1.9c2.3,0.3,4-0.3,4.8-1.5 M312,169.4
	c0,0.8,0.3,1.5,0.4,2.2c0.3,0.6,0.5,1.1,0.8,1.5 M299.1,173.1h-1c-54.1-1.8-96.7-0.8-128.1,2.9 M299.8,169.2
	c-1.5-0.6-1.6,0.8-0.7,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M299.1,173.1l2.8,7.8v3.2 M301.8,185.5c1.9-1.9,5-3.2,8.9-3.7
	c1,0,1.9-0.7,2.9-2.1c0.4-0.7,0.8-1.4,1-2.1 M312,169.4l-12.2-0.3 M313.4,173.1h-14.3 M313.4,173.1c0.3,0.4,0.7,0.8,1.1,1.2
	 M314.6,177.8c0.3-1.1,0.3-2.2-0.1-3.4 M329.1,194.2l-27.2-8.7 M301.8,185.5v-1.4 M262.5,197.6v0.1 M262.6,197.6h-0.1 M262.5,197.8
	l0.1-0.1 M371,214.7l-2.1-0.4c-21.3-3.9-42.8-8.7-64.7-14.4c-7.8-2.5-14.6-4-20.1-4.7l-4.3-0.4l-17.3,2.9 M301.8,184.1
	c-17.3,0.3-30.4,4.8-39.2,13.6 M262.5,197.8c-4.7,7.4-8.7,15.8-12.1,24.9c-6.3,16.8-10.6,36.6-12.7,59 M324.9,174.8l7.8-32.3
	 M381.7,205.2c1.6-0.4,3.4-0.6,5.5-0.6 M331.3,186.6c1.1,2.1,2.5,4.3,4.3,6.6v1.4 M552.9,220.9c-23.5,0-47.2-0.4-71.3-1.2
	c-39.1-0.6-75.1-2.1-108.4-4.5l-2.1-0.4 M370.6,217.9l0.4-3.2 M553,223.8c-23.5,0.4-47.3,0.1-71.4-0.7c-39.1-0.6-75.1-2.1-108.4-4.5
	l-2.5-0.6 M371,214.7c1.2-5.1,4.8-8.3,10.7-9.5 M387.2,204.6c-16.6-2.2-33.8-5.5-51.6-9.9 M481.3,172.6v-4.1 M481.6,172.7l-0.3-0.1
	 M474.9,173.1l6.7-0.4 M481.3,172.6c-1.6-0.8-4.8-1.1-9.4-0.8c-4.8,0.1-8.3,0.6-10.2,1.4 M481.3,168.5l-20.2,0.7
	c-2.2,0-4,0.1-5.4,0.6 M462.2,173.1h0.1c4.3,0.1,8.4,0.1,12.5,0 M454.2,170.4c0.4-0.3,1-0.4,1.6-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M461.9,173.1l-10-1c0.5-0.8,1.2-1.5,2.3-1.9 M461.9,173.1h0.3 M431.3,170
	l-12.4,0.1c-31.1,0.3-61.4,0.1-91.1-0.4 M455.7,169.8l-24.5,0.1 M454.2,170.4l-23-0.4 M520.1,206.8h0.8c14.4-0.6,28.2-1.6,40.9-3.3
	c9.2-1.2,16-2.5,20.4-3.6 M488.6,170.8l0.5,1.1c-1.4,0.4-3.9,0.7-7.4,0.8 M487,169.6c0.7,0.4,1.2,0.8,1.5,1.4 M497.8,185.8l-0.1-1
	H495 M500.2,173.1c-1,0.8-1.9,1.8-2.6,2.8c-1.1,1.4-1.8,2.9-2.3,4.5l-0.3,0.8v0.3 M495,181.5v2.1 M495,184.8v0.3 M495,183.6v1.2
	 M496.3,207.3h2.6 M495,185.1h-12.7l0.3,1.9 M497.8,185.8l1.1,21.5 M496.3,207.3l-1.4-22.1 M482.6,187l2.6,20.2 M485.2,207.3h11.1
	 M582.2,199.8c-0.7-2.3-9.8-5.6-27-10.2c-11.8-2.1-25.7-2.9-41.4-2.2c-4.3,0-6.3,1.2-6.5,3.6l1.2,11.4c0,3,3.9,4.4,11.7,4.3
	 M500.2,173.1l1.7-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M584.5,199.1c1.4-0.6,2.2-1,2.9-1.4c1.5-2.5-6.3-6.2-23.8-11.4
	c-15-3.6-34.9-5.1-60-4.7h-8.5 M495,184.8v0.3 M488.6,170.8l13.2,0.3 M552.9,220.9l0.1,2.9 M501.8,171.1l47.2,0.7
	c5.1,0,10.9-1,17.5-2.9 M498.9,207.3c7.3,0,14.3-0.1,21.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M584.5,199.1c-2.8-2.2-9.5-5.1-20.2-8.5c-9.1-2.9-19.8-4.4-32.5-4.8h-34
	 M582.2,199.8l2.3-0.7 M566.3,168.9l-79.4,0.7 M689.6,215.8c-26.8,1.8-53,3-78.4,3.9c-19.3,0.8-38.7,1.2-58.3,1.2 M487,169.6
	c-1.4-0.7-3.2-1.1-5.6-1 M474.9,173.1h25.3 M387.2,204.6c29.6,1.5,62.3,2.5,97.9,2.6 M333,141.6l0.1-0.8c-7.2-0.7-12.7,2.6-16.5,9.9
	 M327.8,169.7l6.6-27.5l-1.4-0.6 M495,181.5l-171.4-1.1 M327,173.1h135.2 M495,183.6l-165.2-0.6 M335.5,194.7l-6.5-0.6 M482.6,187
	l-151.3-0.4 M329.1,194.2c17.9,5.1,35.5,8.7,52.7,11.1 M553,372.8c0.8-17.6,1.4-35.9,1.7-54.9l0.1-19l-0.1-20.4
	c-0.3-19-0.8-37.3-1.7-54.9 M520.1,389.7h0.8c14.4,0.5,28.2,1.6,40.9,3.3c9.2,1.2,16,2.5,20.4,3.6 M552.9,375.5l0.1-2.8
	 M496.3,389.1h2.6 M485.2,389.1h11.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M582.2,396.5c-0.7,2.3-9.8,5.6-27,10.2c-11.8,2.1-25.7,2.8-41.4,2.1
	c-4.3,0-6.3-1.2-6.5-3.6l1.2-11.4c0-3,3.9-4.4,11.7-4.3 M495,411.5v-0.1 M497.8,410.6l-0.1,1H495 M495,411.4h-12.7l0.1-1.5
	 M495,411.5v-0.1 M500.2,423.2c-1-0.8-1.9-1.6-2.6-2.6c-1.1-1.4-1.8-2.9-2.3-4.5l-0.3-0.8V415 M495,414.8v-1.9 M488.6,425.6
	c-0.4,0.5-1,1-1.5,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M481.6,423.8c3.7,0,6.2,0.3,7.4,0.8l-0.6,1.1 M495,414.8h8.5
	c25,0.4,45.1-1.2,60-4.7c17.3-5.2,25.3-8.9,23.8-11.4c-0.5-0.4-1.5-1-2.9-1.4 M699.2,415.9l-17.1,1.4l-13.9,1.4l-11.1,0.8l-11.3,0.6
	c-44.3,2.1-90.9,3-139.6,3.2H500 M495,412.9v-1.4 M501.8,425.4l-1.6-2.2 M501.8,425.4l-13.2,0.1 M495,411.4l1.4-22.3 M498.9,389.1
	l-1.1,21.5 M520.1,389.7c-6.9-0.3-13.9-0.4-21.2-0.5 M482.4,409.9l2.8-20.8 M566.3,427.5c-6.6-1.9-12.4-2.9-17.5-2.9l-47.2,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M584.5,397.2c-2.8,2.2-9.5,5.1-20.2,8.5c-9.1,2.9-19.8,4.4-32.5,4.8h-34
	 M584.5,397.2c-0.7-0.3-1.5-0.5-2.3-0.7 M566.3,427.5c5.1,2.5,11.6,3.9,19.5,4c29.6,0.4,59.4,0.1,89.6-1.2l3.6-0.3 M487,427
	l79.4,0.5 M689.6,380.6c-26.8-1.8-53-3-78.4-3.9c-19.3-0.8-38.7-1.2-58.3-1.2 M370.6,217.9c-3.4,25.3-5.4,49.2-5.6,71.7v17.3
	c0.3,22.4,2.2,46.4,5.6,71.7 M370.6,378.5l0.4,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M552.9,375.5c-23.5-0.1-47.2,0.3-71.3,1.2c-39.1,0.5-75.1,2.1-108.4,4.5
	l-2.1,0.4 M370.6,378.5l2.5-0.5c33.3-2.5,69.5-4,108.4-4.5c24.1-1,47.9-1.2,71.4-0.7 M331,410.3c1.1-2.2,2.6-4.5,4.5-7.2v-1.5
	 M381.7,391.2c1.6,0.3,3.4,0.5,5.5,0.5 M371,381.7l-2.1,0.4c-21.3,4-42.8,8.8-64.7,14.4c-7.8,2.5-14.6,4-20.1,4.7l-4.3,0.3
	l-17.3-2.9 M335.5,401.6c17.7-4.4,34.9-7.7,51.6-9.9 M371,381.7c1.2,5.1,4.8,8.3,10.7,9.5 M327.8,426.7c29.7-0.5,60.1-0.7,91.1-0.4
	l12.4,0.1 M455.7,426.7c1.5,0.4,3.3,0.5,5.4,0.5l20.2,0.7 M474.9,423.2c-4.1-0.3-8.4-0.3-12.5,0h-0.3 M454.2,426.1
	c0.4,0.3,1,0.4,1.6,0.5 M461.9,423.2l-10,1c0.5,0.8,1.2,1.4,2.3,1.9 M462.1,423.2h-0.1 M481.3,423.9c0.1-0.1,0.1-0.1,0.3-0.1
	 M481.3,427.9v-4 M481.3,423.9c-1.6,0.7-4.8,1-9.4,0.8c-4.8-0.1-8.3-0.6-10.2-1.4 M481.6,423.8l-6.7-0.5 M431.3,426.5l24.5,0.1
	 M431.3,426.5l23-0.4 M481.3,427.9c2.5,0.1,4.3-0.3,5.6-1 M500.2,423.2h-25.3 M387.2,391.9c29.6-1.6,62.3-2.5,97.9-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237.4,290.1c-0.1,2.6-0.1,5.1-0.1,7.7l0.7,17.1
	c2.1,22.4,6.3,42.1,12.7,59c3.4,9.1,7.4,17.5,12.1,24.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237.8,281.7c0,0.4-0.1,0.7-0.1,1.1c-0.1,2.3-0.3,4.8-0.3,7.3
	 M237.8,281.7l-0.4,8.4 M184.6,432.2l3.7,0.1c25,1,52.6,1,82.4,0c12-0.6,21.6-2.2,28.9-5 M262.5,398.8l-5.2,3.2
	c-6.6,3.2-13.6,4.7-20.9,4.8c-32.7-0.3-63.3-1.2-91.3-2.9 M301.8,410.9v1.5 M301.8,410.9c1.9,1.8,5,3,8.9,3.7c1,0,1.9,0.7,2.9,2.1
	c0.4,0.7,0.8,1.4,1,2.1 M314.5,422.1c-0.4,0.3-0.8,0.7-1.1,1.1 M299.1,423.2l2.8-7.8v-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M170,420.5c31.4,3.6,74,4.5,128.1,2.8h1 M312,427c0-0.8,0.3-1.5,0.4-2.2
	l0.8-1.6 M299.8,427.4L312,427 M313.4,423.2h-14.3 M299.1,423.2c-1,3.3-0.8,4.5,0.7,4.1 M327,423.2l-0.4-1.4l-1.6-0.3 M329.7,413.3
	l0.1-0.4c0.3-0.8,0.7-1.8,1.1-2.6 M323.5,417.2c-0.3-0.7-1.2-1.1-2.8-1.1c-4.4,0.3-6.5,1.1-6.3,2.6 M323.5,417.2v-1.1 M323.8,414
	l-0.3,0.8 M323.5,414.8v1.2 M324.9,421.6c-0.8-1.2-2.3-1.8-4.8-1.4c-2.5,0.1-4.4,0.8-5.8,1.9 M329.7,413.3c-0.5,1.4-2.6,1.9-6.2,1.5
	 M324.9,421.6c-0.7-1.6-1.2-3-1.4-4.4 M327.8,426.7l-0.8-3.4 M329.1,402.3l-27.2,8.5 M314.6,418.7c0.3,1.1,0.3,2.2-0.1,3.4
	 M301.8,412.4c-17.3-0.3-30.5-4.8-39.3-13.6 M90.1,287.5c0-1.8,0.1-3.6,0.3-5.2l0.4-5.5v-0.1c0.4-4.7,1.1-9.1,1.9-13.1
	c1.2-6.5,4.3-10.7,8.9-13.1 M90.5,287.5h-0.3l-0.3,0.1c-0.1,0-0.3,0-0.3,0.1 M90.5,287.5l-0.3,0.1l-0.6,0.1 M89.6,287.7l-0.6,0.4
	c-0.3,0.3-0.4,0.5-0.7,1.1c-0.4,0.7-0.7,1.5-1,2.6c-0.3,1.1-0.6,2.2-0.7,3.4c-0.1,1.4-0.3,2.8-0.1,4.3c0,1.5,0.1,2.8,0.4,4
	c0.1,1.1,0.4,2.2,0.7,3.2l0.6,1.2l0.7,1.1l0.8,0.8l0.7,0.3l0.4-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M90.8,310l0.7-0.5l0.7-1l1-2.8c0.6-2.3,0.8-5,0.8-7.8l-0.1-2.2
	c-0.1-1.9-0.6-3.6-1-5.1l-0.4-1.4l-0.7-1.1c-0.3-0.3-0.6-0.5-0.8-0.7l-0.4-0.1 M101.9,346.1c-2.5-10.2-4.1-22-4.7-35.4
	c-0.1-2.3-0.1-4.7-0.3-7v-10.5l0.3-6.9c0.6-13.6,2.2-25.7,4.8-36 M83.8,328.6c-0.7-6.7-1.1-13.5-1.4-20.4V288
	c0.3-6.9,0.7-13.6,1.4-20.4 M97.1,286.5c-3.9,0.1-6.1,0.4-6.6,1 M90.8,310l-0.3-0.3 M101.9,346.1c-4.7-2.3-7.7-6.7-8.9-13.1
	c-0.8-4.1-1.5-8.4-1.9-13.2c-0.3-2.8-0.4-5.6-0.6-8.7V310 M85.7,343.6c-0.8-5-1.4-9.8-1.9-14.7 M97.3,310.7
	c-3.4-0.1-5.6-0.4-6.5-0.8 M107.8,363.7c-2.3-5.2-4.4-11.1-6.1-17.6 M109.6,366.6l-1.8-2.9 M98.4,354.9l-2.3-1 M107.8,363.7
	l-9.5-8.8 M92.4,373l-1.1-3.9l-1.1-4l-0.8-3.2c-1.4-5.6-2.6-11.7-3.7-18.4 M83.6,373.9c1.7,4.4,3.4,8.4,5.5,12.2 M94.9,380.2
	c-0.8-2.2-1.7-4.4-2.5-6.9 M94.9,380.2l1.1,2.6 M96,382.8l-12.4-8.9 M89,386.1c1.7,3.2,3.4,6.2,5.4,9.1c8,11.8,17.9,20.5,29.8,26.3
	c5.1,2.5,12.4,4.3,21.7,5.8l11.8,1.9c8.1,1.5,17.1,2.5,26.8,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M184.6,432.2c-11.4-4.5-22.1-8.1-32.5-10.9c-20.2-4.3-35.5-12.9-46.1-26
	c-3.8-4.8-7.2-10.3-9.8-16.4l13.9,8.8 M130.8,420.5c-13.8-5.1-25.3-13.5-34.9-25.2c-2.3-2.9-4.5-5.9-6.7-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M96,382.8c1.8,4.5,4,8.8,6.6,12.5c5.1,7.3,11.7,13.1,19.8,17.1
	 M110.3,387.7h2.2 M112.5,387.7h0.8c-5-9.1-10-20.1-15-32.9 M145,403.7l-5.6-3.9l-5.8-4.5c-8-6.7-15.1-14.9-21.2-24.2
	c-1-1.5-1.9-2.9-2.8-4.4 M130.8,420.5c1.8,1,4,1.9,6.3,2.6l1.2,0.4c1.1-0.1,1.5-0.5,1.1-1.2c-0.4-0.7-1.5-1.5-3.2-2.6l-1.5-1
	c-2.2-1.2-5-2.8-8.5-4.5c-1.4-0.5-2.6-1.1-4-1.8 M112.5,387.7c1.5,2.6,3,5.1,4.7,7.6c5.4,7.6,11.8,13.6,19.5,18
	c9.5,5.4,20.1,7.7,32.2,7.3c0.7,0,1,0,1.1-0.1 M122.3,412.4c1.4,3.2,4.3,5.8,8.5,8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M170,420.5c0.3-0.3-0.3-0.8-1.5-1.8l-1.1-0.7c-3.2-2.3-9.1-6.2-17.6-11.4
	l-4.8-2.9 M262.5,398.8c-37.4-4.3-76.2-11.6-116.1-21.9c-12.2-3.2-24.5-6.6-36.9-10.3 M96,353.9l-10.3-10.3 M96,353.9
	c4.5,14.6,9.4,25.9,14.3,33.8 M312,427c-0.3,3.4,0.7,8.4,2.9,14.9c0.5,1.4,1.1,2.8,1.8,3.9 M333,454.9l-0.3-1 M332.8,453.9
	c-6.6,0.5-11.3-2.2-13.9-7.8c-1-1.2-1.6-1.4-2.2-0.4 M332.8,453.9l-7.8-32.3 M333,454.9l1.4-0.7l-6.6-27.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.7,445.8c3.9,7.3,9.4,10.6,16.5,9.9l-0.1-0.8 M323.5,416.1l171.4-1.2
	 M462.1,423.2H327 M482.4,409.9L331,410.3 M329.1,402.3c17.9-5.1,35.5-8.7,52.7-11.1 M335.5,401.6l-6.5,0.7 M495,412.9l-165.2,0.4
	 M747.7,266.4c0.4,2.9,0.8,5.8,1.2,8.5 M756.2,259.4c-2.1,1.4-2.8,5.5-2.1,12.4c0.4,5,0.8,9.8,1,14.4l0.3,12.8l-0.3,11.1
	c-0.3,4.7-0.5,9.5-1,14.4c-0.7,6.9,0,11,2.1,12.4 M748.8,321.4l1.8-5.5c0.4-4,0.5-8,0.7-11.8v-12c-0.1-3.9-0.4-7.8-0.7-11.8
	l-1.8-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M748.8,274.9l0.5,4.5c0.4,4.3,0.7,8.5,0.8,12.7v12.1
	c-0.1,4.1-0.4,8.4-0.8,12.8l-0.5,4.5 M753.6,383.8c5.9-22,9.8-45.1,11.6-69.3c0.3-4.4,0.4-8.8,0.4-12.9V295c0-4.1-0.1-8.4-0.4-12.9
	c-1.8-24.2-5.6-47.3-11.6-69.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M756.2,337c1.9-12.5,3-25.5,3.2-38.8c-0.3-13.3-1.2-26.3-3.2-38.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M747.7,330.1c-0.5,11.3,0.7,15.7,3.7,13.3l5-6.5 M748.8,321.4l-1.1,8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755.6,230.9c5,16.1,7.8,34.4,8.7,54.9v24.9c-0.8,20.5-3.7,38.8-8.7,54.9
	 M689.6,380.6c5.5-18.7,9.2-38,10.9-58.1l0.4-6.1c0.3-6.1,0.5-12,0.5-18c0-6.2-0.1-12.4-0.5-18.6l-0.4-6.2
	c-1.7-20.1-5.2-39.3-10.9-58.1 M679.2,430.1c9.8-0.8,18.2-2.6,25.2-5.2c5.4-1.8,10.7-3.4,16-4.5l2.1-0.4 M679.2,430.1
	c3.9-3.3,12.2-6.3,25-9.2c5.9-1.2,11.1-2.8,15.5-4.7 M750.1,365.6l-0.8,1.1l-2.9,3.9l-4.3,7.8c-1.5,2.8-2.8,4.3-4,4.7l-2.6,0.5
	 M755.6,365.6h-4.8 M750.8,365.6h-0.7 M722.3,419.9l3.3-0.7c10.5-2.3,17.2-5.9,20.1-10.9c4-7.2,6.2-14.2,6.9-20.9l0.1-1l0.7-2.6
	 M747.7,330.1c-2.2,13.3-5.6,27.2-10.6,41.4c-1.4,4-3.2,7.8-5.4,11.7 M735.6,383.5l-4-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M735.6,383.5l-0.5,1c-5.8,8.5-11.6,15.7-17.2,21.3
	c-2.1,2.5-2.6,3.9-1.7,4.3l0.7,0.1l0.4-0.1l5.1,0.3c2.3,0,4.1-0.1,5.6-0.5c1.2-0.3,2.2-0.8,2.9-1.4c1-0.8,2.1-1.9,3.2-3.3
	c2.6-3.3,5.4-8.3,8.4-14.9c3-7,5.8-15.3,8.5-24.6 M731.6,383.2l-21.5-1.2l-20.5-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M719.7,416.1c6.6-2.9,11.6-6.5,15.1-10.7c7.3-8.3,14.2-21.6,20.8-39.9
	 M753.6,383.8c-3.3,10-6.5,16.1-9.4,18.2c-4,2.9-6.7,5.9-8.1,8.8c-0.7,4.1-5.2,7.2-13.6,9.2 M731.6,383.2
	c-6.9,11.8-17.6,22.8-32.3,32.7 M756.2,337c-1.5,9.8-3.6,19.3-6.2,28.6 M719.7,416.1c-5.4-0.4-12.2-0.5-20.5-0.1 M756.2,259.4
	l-5-6.3c-3-2.3-4.3,2.1-3.7,13.3 M750.1,230.9c2.6,9.2,4.7,18.8,6.2,28.5 M85.7,252.8c-0.8,5-1.4,9.8-1.9,14.7"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M262.5,398.8c0,0-25-35.2-25-100.4
		s25.2-100.6,25.2-100.6s-12.1-8-26.3-7.8c0,0-48.8,0.3-91.3,2.9c0,0-19.8,10.9-35.1,36.6c0,0-12.9,19.1-12.9,68.8
		c0,49.7,13.2,69.2,13.2,69.2s11.4,21.3,34.9,36.3c0,0,34.3,1.9,91.3,2.9C236.3,406.6,250.2,407,262.5,398.8z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M284.2,401.2
		c0,0-21-38-23-103c0,0-0.1-57.6,23-103c0,0,8.5,1.1,19.4,4.4l1.1,0.3c0,0,31.6,8.3,66.3,14.7c0,0-6.1,38.8-6.1,83.5
		s6.1,83.5,6.1,83.5s-25.9,4.3-68,15.3C303.1,397,290.3,400.7,284.2,401.2z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M370.6,378.7c0,0-4.7-28.5-5.6-80.5
		c0,0-0.5-36.5,5.6-80.3l2.6,0.6c0,0,61.4,5.6,179.8,5.2c0,0,1.5,34.4,1.8,74.4c0,0,0.3,27-1.8,74.6c0,0-75.4-1.8-179.8,5.2
		L370.6,378.7z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M689.6,380.6
		c0,0-45.9-3.7-136.7-5.1c0,0,2.1-40.3,1.9-77.3c0,0,0.4-25.3-1.9-77.3c0,0,73.2-0.8,136.7-5.1c0,0,12.1,36.6,11.8,82.4
		C701.4,298.2,703.1,334.8,689.6,380.6z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M689.6,380.6
		c0,0,12.1-36,11.8-82.4c0,0,0.7-43.1-11.8-82.4l42-2.6l4-0.1c0,0,2.6,0.3,3.2,0.7c0.7,0.6,2.2,2.2,4.1,5.6l3.6,6.6l3.7,5h5.5
		c0,0,7,22,8.5,53.7v26.1c0,0-0.5,29.3-8.7,54.9H750l-3.7,5l-3.2,5.9c0,0-2.6,5.8-5,6.6l-2.6,0.5l-4-0.3
		C731.6,383.2,706.4,382.1,689.6,380.6z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback3DrTop);
