import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, uniq } from 'lodash';
import { getCoreMetaDetails, loadCoreSections } from '../../actions';
import { getConfig } from '../../utils/helpers';
import { CORE_SECTIONS } from './constants/common';
import './styles/core-style.css';
import './styles/core-common.css';
import './styles/core-modals.css';
import './styles/form/index.css';
import './styles/core-landing-page.css';
import './styles/core-search-results.css';
import './styles/core-vehicle-details.css';

class Core extends Component {
  componentDidMount() {
    const coreRoot = getConfig('coreRoot');
    if (coreRoot) {
      const mpId = getConfig('marketplaceId');
      const apiKey = this.props.cookies.get('apiKey');
      this.props.getCoreMetaDetails(mpId, apiKey).then(response => {
        if (response.type === 'CORE_META_FAILURE') {
          console.error(response.type, response.error);
        } else if (
          response.type === 'CORE_META_SUCCESS' &&
          response.response.amStatus === 'Error'
        ) {
          console.error('CORE_META_FAIL', response.response.amMessage);
        } else {
          this.makeCoreSections(response.response);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.item.heavyTruckOption !== prevProps.item.heavyTruckOption &&
      !isEmpty(this.props.login)
    ) {
      this.makeCoreSections(this.props.login);
    }
  }

  makeCoreSections(login) {
    // s1 = Details
    // s2 = Information
    // s10 = Wheels (not in mobile atm)
    // s3 = Equipment
    // s4 = Questions
    // s5 = Media
    // s6 = Condition
    // s11 = Condition Review (not in mobile atm)
    // s7 = Additional
    // s8 = Pricing
    // s99 = Vehicle Review (cannot be hidden)

    const hidden = [];

    // hide per login
    (login.crAppHiddenSections || '')
      .split(',')
      .forEach(val => hidden.push(val.toLowerCase()));

    // TODO - hide per marketplace feature
    if (false && getConfig('marketplaceId') === '550') {
      hidden.push('s2:r6', 's4:r2', 's4:r4', 's4:r14'); // cdk is gone
    }

    // hide these questions for all AM customers (was hardcoded in mobile)
    hidden.push(
      's4:r6',
      's4:r7',
      's4:r8',
      's4:r9',
      's4:r10',
      's4:r11',
      's4:r12',
      's4:r13',
      's6:r7',
      's6:r8',
      's6:r5', // condition:action suggest
      's7:r2' // additional:announcements
    );

    // user can toggle this in details section
    const isHeavyTruck = this.props.item.heavyTruckOption;
    if (!isHeavyTruck) {
      hidden.push('s9');
    }

    // condition and condition review go together
    if (hidden.includes('s6') || hidden.includes('s11')) {
      hidden.push('s11', 's6');
    }

    // Vehicl Review section cannot be hidden
    if (hidden.includes('s99')) {
      const idx = hidden.indexOf('s99');
      hidden.splice(idx, 1);
    }
    // 7.17.2024 - TODO -- Restructure with container layer
    //  -- This component already acts as a layout and content-state layer
    // Details section cannot be hidden because /core/details path is the core starting place
    // if we want to change this we have to restructure some things... make a core container, etc
    if (hidden.includes('s1')) {
      const idx = hidden.indexOf('s1');
      hidden.splice(idx, 1);
    }

    const coreSections = uniq(
      CORE_SECTIONS.filter(section => !hidden.includes(section.section)).map(
        section => {
          const hiddenRows = hidden
            .map(val => {
              const [s, r] = val.split(':');
              return r && s === section.section ? val : null;
            })
            .filter(val => val);
          return { ...section, hiddenRows };
        }
      )
    );

    this.props.loadCoreSections(coreSections);
  }

  render() {
    return <div className="bg-light">{this.props.children}</div>;
  }
}

const mapStateToProps = state => {
  const { item, login, coreSections } = state.core;
  return { item, login, coreSections };
};

export default connect(mapStateToProps, {
  getCoreMetaDetails,
  loadCoreSections,
})(Core);
