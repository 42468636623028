import React, { Component } from 'react';

class InputField extends Component {
  render() {
    const {
      autoFocus,
      title,
      value,
      placeholder = '',
      style,
      type = 'text',
      onChangeHandler,
      isRequired = false,
      isDisabled = false,
      infoText = false,
      infoTextStyles,
      infoTextClickHandler,
      error,
      defaultValue,
      maxLength = 50,
      currency,
      onFocus = () => null,
      onBlur = () => null,
      dataId,
    } = this.props;

    const inputStyle = {
      color: isDisabled && '#999999',
      backgroundColor: isDisabled && '#f2f2f2',
      borderRadius: 8,
      borderTopLeftRadius: currency ? 0 : 8,
      borderBottomLeftRadius: currency ? 0 : 8,
    };

    return (
      <div className="text-input-field-container">
        <div>
          <span>
            {title}
            {isRequired && <span className="text-red-500">*</span>}
          </span>
          {infoText && (
            <span className={infoTextStyles} onClick={infoTextClickHandler}>
              {infoText}
            </span>
          )}
        </div>
        <div className="input-group" style={{ width: '100%' }}>
          {currency && <div className="input-group-addon">{currency}</div>}
          <input
            autoFocus={!!autoFocus}
            placeholder={placeholder}
            value={value}
            type={type}
            disabled={isDisabled}
            onChange={onChangeHandler}
            className={`text-input-field text-black ${style || ''}`}
            defaultValue={defaultValue}
            maxLength={maxLength}
            style={inputStyle}
            onFocus={onFocus}
            onBlur={onBlur}
            data-id={dataId}
          />
        </div>
        {error && <div className="text-red-500 text-xs mt-1">{error}</div>}
      </div>
    );
  }
}

export default InputField;
