import React, { Component } from 'react';

class TagIcon extends Component {
  render() {
    const { size = 20, color = '#fff' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.00002 4.00002C4.80224 4.00002 4.6089 4.05867 4.44445 4.16855C4.28 4.27843 4.15182 4.43461 4.07614 4.61733C4.00045 4.80006 3.98065 5.00113 4.01923 5.19511C4.05782 5.38909 4.15306 5.56727 4.29291 5.70712C4.43276 5.84698 4.61095 5.94222 4.80493 5.9808C4.99891 6.01939 5.19997 5.99958 5.3827 5.9239C5.56543 5.84821 5.7216 5.72004 5.83149 5.55559C5.94137 5.39114 6.00002 5.1978 6.00002 5.00002C6.00002 4.7348 5.89466 4.48045 5.70712 4.29291C5.51959 4.10537 5.26523 4.00002 5.00002 4.00002ZM19.71 9.78002L10.23 0.320017C10.1368 0.21948 10.0239 0.139192 9.89828 0.0841425C9.7727 0.0290929 9.63713 0.000457809 9.50002 1.67143e-05H3.50002C3.36841 -0.000744179 3.23795 0.0244809 3.11611 0.0742455C2.99427 0.12401 2.88346 0.197335 2.79002 0.290017L0.290017 2.78002C0.197335 2.87346 0.12401 2.98427 0.0742455 3.10611C0.0244809 3.22795 -0.000744179 3.35841 1.67143e-05 3.49002V9.49002C0.0037002 9.75478 0.107283 10.0084 0.290017 10.2L9.78002 19.7C9.97165 19.8828 10.2253 19.9863 10.49 19.99C10.6216 19.9908 10.7521 19.9656 10.8739 19.9158C10.9958 19.866 11.1066 19.7927 11.2 19.7L19.71 11.19C19.8027 11.0966 19.876 10.9858 19.9258 10.8639C19.9756 10.7421 20.0008 10.6116 20 10.48C19.9938 10.2187 19.8904 9.96912 19.71 9.78002ZM10.49 17.59L2.00002 9.09002V3.90002L3.90002 2.00002H9.08002L17.58 10.49L10.49 17.59Z" />
      </svg>
    );
  }
}

export default TagIcon;
