import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import jstz from "jstz";

export const DeviceTime = props => {
  const timezone = jstz.determine().name();
  return (
    <span className="device-time">
      {moment.tz(props.sourceTime, timezone).format(props.format)}
    </span>
  );
};

DeviceTime.propTypes = {
  sourceTime: PropTypes.string,
  format: PropTypes.string
};

export default DeviceTime;
