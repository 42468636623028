import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateItem } from '../../../../../../actions';
import SelectMenu from '../../../ui/selectMenu';
import InputField from '../../../ui/inputField';
import Layout from '../../../templates/forms/Layout';
import ColorLabel from '../../../templates/forms/ColorLabel';
import FormFooter from '../../../templates/forms/formFooter';
import {
  exteriorColors,
  fuelLevels,
  interiorColors,
  interiorTypes,
  keys,
  odometerTypes,
} from '../../../../constants/common';
import { showRow } from '../../../../utils/helpers';

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source: {},
      odometer: props.item['odometer'],
    };
  }

  loadData() {
    const item = this.props.vin.decodedItems[this.props.variant.index];
    this.setState({
      source: {
        odometerTypes: odometerTypes,
        exteriorColors: (item && item['exteriorColors']) || exteriorColors,
        interiorColors: (item && item['interiorColors']) || interiorColors,
        interiorTypes: (item && item['interiorTypes']) || interiorTypes,
        fuelLevels: fuelLevels,
        keys: keys,
      },
    });
  }

  componentDidMount() {
    this.loadData();
  }

  getDefaultValue(property) {
    const { item } = this.props;
    let existingValue = item && item[property];
    const isColor =
      property === ('exteriorColor' || 'interiorColor') ? true : false;
    if (existingValue) {
      return {
        value: existingValue,
        label: isColor ? <ColorLabel color={existingValue} /> : existingValue,
      };
    } else {
      return null;
    }
  }

  handleValue(entity, value) {
    if (entity === 'odometer') {
      const re = /^\d*(\.\d{0,2})?$/;
      if (re.test(value)) {
        this.props.updateItem({ [entity]: value });
        this.setState({ [entity]: value });
      }
    } else {
      this.props.updateItem({ [entity]: value });
    }
  }

  render() {
    const { source, odometer } = this.state;
    const { coreSections } = this.props;

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Information</div>
            </div>
            <div className="form-body-container">
              <div className="form-body">
                {showRow('s2:r1', coreSections) && (
                  <InputField
                    title="Odometer"
                    placeholder="Enter odometer reading"
                    onChangeHandler={e =>
                      this.handleValue('odometer', e.target.value)
                    }
                    value={odometer || ''}
                  />
                )}
                {showRow('s2:r1', coreSections) && (
                  <SelectMenu
                    title="Odometer Units"
                    defaultValue={this.getDefaultValue('odometerType')}
                    options={
                      source.odometerTypes &&
                      source.odometerTypes.map(odometerType => ({
                        value: odometerType,
                        label: odometerType,
                      }))
                    }
                    onChangeHandler={e => {
                      this.handleValue('odometerType', e.value);
                    }}
                    hideIndicatorSeparator={true}
                  />
                )}

                {showRow('s2:r2', coreSections) && (
                  <SelectMenu
                    title="Exterior Color"
                    defaultValue={this.getDefaultValue('exteriorColor')}
                    options={
                      source.exteriorColors &&
                      source.exteriorColors.map(color => ({
                        value: color,
                        label: <ColorLabel color={color} />,
                      }))
                    }
                    onChangeHandler={e => {
                      this.handleValue('exteriorColor', e.value);
                    }}
                    hideIndicatorSeparator={true}
                  />
                )}
                {showRow('s2:r3', coreSections) && (
                  <SelectMenu
                    title="Interior Color"
                    defaultValue={this.getDefaultValue('interiorColor')}
                    options={
                      source.interiorColors &&
                      source.interiorColors.map(color => ({
                        value: color,
                        label: <ColorLabel color={color} />,
                      }))
                    }
                    onChangeHandler={e => {
                      this.handleValue('interiorColor', e.value);
                    }}
                    hideIndicatorSeparator={true}
                  />
                )}
                {showRow('s2:r4', coreSections) && (
                  <SelectMenu
                    title="Interior Type"
                    defaultValue={this.getDefaultValue('interiorType')}
                    options={
                      source.interiorTypes &&
                      source.interiorTypes.map(type => ({
                        value: type,
                        label: type,
                      }))
                    }
                    onChangeHandler={e => {
                      this.handleValue('interiorType', e.value);
                    }}
                    hideIndicatorSeparator={true}
                  />
                )}
                {showRow('s2:r7', coreSections) && (
                  <SelectMenu
                    title="Keys Available"
                    defaultValue={this.getDefaultValue('keys')}
                    options={
                      source.keys &&
                      source.keys.map(key => ({
                        value: key,
                        label: key,
                      }))
                    }
                    onChangeHandler={e => {
                      this.handleValue('keys', e.value);
                    }}
                    hideIndicatorSeparator={true}
                  />
                )}
                {showRow('s2:r6', coreSections) && (
                  <SelectMenu
                    title="Fuel Level"
                    defaultValue={this.getDefaultValue('fuelLevel')}
                    options={
                      source.fuelLevels &&
                      source.fuelLevels.map(fuelLevel => ({
                        value: fuelLevel,
                        label: fuelLevel,
                      }))
                    }
                    onChangeHandler={e => {
                      this.handleValue('fuelLevel', e.value);
                    }}
                    hideIndicatorSeparator={true}
                  />
                )}
              </div>
            </div>
            <FormFooter currentSection="s2" />
          </div>
        </div>
      </Layout>
    );
  }
}

Information.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { variant, vin, item, coreSections } = state.core;
  return { variant, vin, item, coreSections };
};

export default connect(
  mapStateToProps,
  {
    updateItem,
  }
)(Information);
