import React, { Component } from 'react';
import _ from 'lodash';
import $ from 'jquery';
window.$ = window.jQuery = $;

class AddBuyerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      disableInvite: true
    };
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  inviteAction(e) {}

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });

    if (this.state.phone.length === 6) {
      this.setState({
        disableInvite: false
      });
    }
  }

  render() {
    return (
      <div className="modal fade" id="addBuyers">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title">
                <span className="fa fa-table" />
                Add Buyer
              </h3>
              <div className="form-group">
                <label htmlFor="price">PhoneNumber</label>
                <br />
                <input
                  type="text"
                  id="phone"
                  placeholder="Phone Number"
                  value={this.state.phone}
                  onChange={this.handlePhoneChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={this.state.disableInvite}
                onClick={this.inviteAction}
                className="btn btn-primary"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBuyerModal;
