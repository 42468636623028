import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import Lightbox from 'react-image-lightbox';
import Layout from '../../templates/forms/Layout';
import { MEDIA_TYPES } from '../../../constants/common';
import {
  updateItem,
  updatePhotos,
  updatePhotosAsync,
} from '../../../../../actions';
import ImageUploader from './ImageUploader';
import FormFooter from '../../templates/forms/formFooter';
import { showRow } from '../../../utils/helpers';

class Media extends Component {
  state = {
    lightBoxOpen: false,
    lightBoxIndex: 0,
    lightBoxImages: [],
  };

  componentDidMount() {
    this.makeLightBoxImages();
  }

  makeLightBoxImages = () => {
    const lightBoxImages = MEDIA_TYPES.map(item => {
      const existingPhoto = this.findExistingPhoto(item);
      return existingPhoto;
    })
      .map(photoObj =>
        photoObj &&
        photoObj.name !== '(null)' &&
        photoObj.name.startsWith('https')
          ? photoObj.name
          : null
      )
      .filter(item => item);

    this.setState({ lightBoxImages });
  };

  findExistingPhoto = photoItem => {
    const descriptionKey = `${photoItem.category}-${photoItem.description}`;
    const existingPhoto = find(this.props.item.photos, { descriptionKey });
    return existingPhoto;
  };

  openLightbox = photoItem => {
    const existingPhoto = this.findExistingPhoto(photoItem);
    if (existingPhoto) {
      const lightBoxIndex = this.state.lightBoxImages.indexOf(
        existingPhoto.name
      );
      if (lightBoxIndex > -1) {
        this.setState({ lightBoxOpen: true, lightBoxIndex });
      }
    }
  };

  closeLightbox = () => {
    this.setState({ lightBoxOpen: false, lightBoxIndex: 0 });
  };

  moveNext = () => {
    this.setState({
      lightBoxIndex:
        (this.state.lightBoxIndex + 1) % this.state.lightBoxImages.length,
    });
  };

  movePrev = () => {
    this.setState({
      lightBoxIndex:
        (this.state.lightBoxIndex + this.state.lightBoxImages.length - 1) %
        this.state.lightBoxImages.length,
    });
  };

  renderLightBox() {
    const { lightBoxOpen, lightBoxImages, lightBoxIndex } = this.state;

    return (
      lightBoxOpen && (
        <Lightbox
          mainSrc={lightBoxImages[lightBoxIndex]}
          nextSrc={lightBoxImages[(lightBoxIndex + 1) % lightBoxImages.length]}
          prevSrc={
            lightBoxImages[
              (lightBoxIndex + lightBoxImages.length - 1) %
                lightBoxImages.length
            ]
          }
          onCloseRequest={this.closeLightbox}
          onMovePrevRequest={this.movePrev}
          onMoveNextRequest={this.moveNext}
        />
      )
    );
  }

  renderMediaItems() {
    return MEDIA_TYPES.map((item, index) => {
      return (
        <ImageUploader
          key={index}
          title={item.description}
          photoItem={item}
          onImageClick={() => this.openLightbox(item)}
          onPhotosChange={this.makeLightBoxImages}
        />
      );
    });
  }

  render() {
    const { coreSections } = this.props;
    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Media</div>
            </div>
            <div className="form-body-container">
              <div className="form-body-media">
                {this.renderLightBox()}
                {showRow('s5:r1', coreSections) && this.renderMediaItems()}
              </div>
            </div>
            <FormFooter currentSection="s5" />
          </div>
        </div>
      </Layout>
    );
  }
}

Media.propTypes = {
  updateItem: PropTypes.func.isRequired,
  updatePhotos: PropTypes.func.isRequired,
  updatePhotosAsync: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { item, coreSections } = state.core;
  return { item, coreSections };
};

export default connect(mapStateToProps, {
  updateItem,
  updatePhotos,
  updatePhotosAsync,
})(Media);
