import React, { Component } from 'react';
import { connect } from 'react-redux';

class Sedan4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M411.5,148.4c-27.8,0-49.1,1-74.8,1.4c4-6.9,7.8-13.6,8.1-14c0.4-0.6,0.5-1,0-1.1c-0.4-0.1-2.6-0.8-4.4-1
	c-3.7-0.3-7.3,0.6-10.9,2.5c-5.6,3.2-10.7,9.1-13.9,13.6c-17.3,0.4-38.1,0.8-48.7,1c-10.2,0.1-15.5-0.4-22.3-1.2s-13.5-1.9-36.9-1.8
	s-63.4,2.3-78.6,3.3c-3.8,0.3-6.3,0.8-9.1,1.4c-6.7,1.2-13.1,3.6-19.5,5.8c-13.5,4.8-20.5,11.3-27,17.7
	c-10.9,11-18.2,23.8-27.4,39.2c-3,4.5-5.4,9.6-6.3,16.4s-2.8,22.8-3.4,38.1l-0.1,48c0.8,14.4,2.9,34.4,3.9,41.1
	c1,6.1,4.4,12.1,7.8,18.3c5,8.8,10.6,17.5,16.2,24.3c5.5,6.7,11.3,14.3,19.3,20.4c5.1,3.7,9.5,6.6,15.5,8.4c2.8,1,6.6,1.8,8,2.5
	c1.9,1.1,6.6,2.8,14.9,4.3c2.8,0.5,5.6,1,9.5,1.2c15.1,0.8,53,2.6,76.3,2.8c23.4,0.1,30.1-1.1,36.9-1.8c6.7-0.8,12.1-1.4,22.3-1.2
	c10.6,0.1,26.4,0.7,43.7,1c2.1,5.1,5.8,11.6,10.6,15.8c3,2.6,6.3,4.3,10,4.8c1.8,0.3,4.1,0,4.5,0c0.5,0,0.5-0.4,0.3-1.1
	c-0.1-0.5-3.2-11-5.8-19.1h0.1c25.7,0.4,53.4,1,81.2,1h19.1c3.2,0,6.2,0,9.2-0.1c61.6-0.7,102.9-1.2,116.1-1.5
	c12.7-0.1,19.5,1.8,38.8,1.5c19.3-0.3,62-0.8,80.3-1.4c5.2-0.1,10-0.1,13.2-0.3c7.8-0.6,10.3-1.8,15.1-2.8
	c12.8-2.6,27.1-4.7,48.3-10.3c11-2.9,21.7-5.2,29.6-12.8c7.3-7.2,11.4-19.4,15.4-34.8c9.1-35.1,9.6-83.2,9.6-83.2
	s-0.5-48.4-9.8-83.5c-4.4-16.8-9.5-28.9-17.7-35.9c-7.6-6.5-16.8-8.3-26.8-10.9c-21.2-5.6-35.8-8.3-48.6-10.9c-5-1-7.3-2.2-15.1-2.8
	c-3.2-0.3-7.8-0.3-13.2-0.3c-18.4-0.4-61.1-1.1-80.3-1.4c-19.3-0.3-26.1,1.6-38.8,1.5c-13.2-0.1-54.5-0.8-116.1-1.4
	C435.8,148.5,415.6,148.4,411.5,148.4L411.5,148.4z"
          />
          <path
            fill="#5F605F"
            d="M40.7,342.6v0.1c0,3.3,1.4,9.9,2.1,11.4c0.3,0.7,0.5,0.7,1.5,1.8c1.2,1.4,1.7,1.9,2.8,1.8
	c0.5-0.1,1.5-2.1,2.1-4.3c0.5-1.5,1.7-4.8,1.7-5.4c0.3-1.8,0.3-1.8,0.1-5.5c-1.4-22-1.4-21.3-1.4-48.4c0-27,0-26.5,1.2-48.8
	c0.3-3.9,0.1-4.1-0.3-5.9c-0.1-0.6-1-2.5-1.4-4c-0.5-2.2-2.2-5.5-2.8-5.6c-1.1,0-2.3,0.1-3.4,1.5c-1,1.2-1.9,4-2.3,4.8
	c-0.7,1.5-0.5,5.2-0.5,8.5c-0.4,1.1-0.1,2.6-0.3,3c-1.2,3.4-1.4,8.5-1.8,13.9c-0.8,11.8-0.8,25.9-0.8,32.6c0,6.9,0.1,20.9,1,32.6
	c0.4,5.5,0.5,10.5,1.8,13.8c0.1,0.3,0.4,1,0.7,1.8C40.6,342.5,40.6,342.6,40.7,342.6L40.7,342.6z"
          />
          <path
            fill="#E9EAE9"
            d="M602.5,177.8c-0.8,0.4-9.6,6.3-14.7,11c-0.5,0.6-0.7,1.1-0.7,1.5c5.9-0.8,11.7-1.8,17.6-2.9
	c16.5-2.9,30.4-5.2,40.7-7c-0.3-0.3-0.7-0.7-1.9-0.8c-1.9-0.1-26-1.8-38.2-2.5C604.2,177.3,603.4,177.4,602.5,177.8L602.5,177.8z
	 M467.3,169.3c4.8,10.7,8.3,23.8,9.1,27.6c27.6,0.1,57.1-1,84.1-3.6c2.3-0.3,4.5-0.4,6.7-0.7c1.8-1.5,6.7-6.3,12.2-10.6l-3.2-9.1
	C540.3,171.2,499.8,169.7,467.3,169.3L467.3,169.3z M319.3,170.1c1.2,1.4,5.8,6.6,6.5,7.3c0.5,0.7,1.1,1.6,1.4,2.2
	c17.6,4.5,33.6,8.4,42.9,10.3c6.1,1.2,14.6,2.6,28.3,3.9c16.4,1.6,38.8,2.8,61.4,3.3c-1.9-3.6-9.1-15.5-21.3-28.1
	c-10.3-0.1-21.2-0.1-31.4-0.1c-31.9,0.1-63.3,0.3-87.2,0.6C319.7,169.7,319.4,170,319.3,170.1L319.3,170.1z M695,201.1
	c-2.6,0.1-62.9,6.6-81.2,8.5c-3.6,0.4-6.5,2.8-5.4,7.8c1.1,5.1,7.2,40,7.2,45.8c0,2.3,0,3.3,1.4,3.6c1.4,0.3,2.1,0.5,2.3,0.5
	s0.5,0.3,0.5,0.7s0.5,8.9,0.5,26.1c0,17.2-0.5,25.7-0.5,26.1c0,0.4-0.3,0.5-0.5,0.7c-0.3,0-1,0.3-2.3,0.5c-1.4,0.3-1.4,1.2-1.4,3.6
	c0,5.8-6.1,40.6-7.2,45.8c-1.1,5.2,1.8,7.4,5.4,7.8c18.3,2.1,78.5,8.4,81.2,8.5s5.1-0.5,6.6-4.3c5.6-14.4,12.4-44.4,12.4-89
	s-6.6-74.6-12.4-89C700.1,201.6,697.6,200.9,695,201.1L695,201.1z"
          />
          <path
            fill="#5F605F"
            d="M289.3,169.3c12.5,3.6,24.9,6.6,37.3,9.8c-0.4-0.6-0.8-1.5-1.5-2.2c-0.7-0.7-4.8-5.2-6.1-6.6
	c-0.3,0.3-0.4,0.6-0.7,0.8c-0.3,0.3-0.5,0.4-1,0.4c0,0.4-0.3,1.1-2.6,1.1c-2.3,0-7.3-0.3-8.9-0.3c-1,0-1.8-0.1-2.2-0.6
	c-0.5-0.1-1.2-0.3-1-0.7c0,0,0.4-0.8,1.1-2.1l-14,0.1c-0.3,0-0.7,0.1-0.8,0.3L289.3,169.3L289.3,169.3z M563.5,154.9
	c1.2-1.2,4.1-1.8,9.1-1.5c3.3,0.1,5.4,1.1,6.2,1.9c0.4,0.4,0.8,1,0.8,1.1c0.3,0.4,0.4,0.4,0.8,0.4h1.5c2.2,0,5.9-0.1,6.2-0.1
	s0.5-0.4,0.5-0.7c0-0.1-0.1-0.3-0.1-0.3c-0.4-0.4-5-4.8-17.2-5.4c-12.4-0.4-16.1,4-16.6,4.4c-0.1,0.1-0.1,0.1-0.1,0.3
	c0,0.3,0.4,0.4,0.7,0.4c0,0,6.7,0.7,7.2,0.7c0.3,0,0.3-0.1,0.5-0.4C563,155.4,563.2,155.1,563.5,154.9L563.5,154.9z M438,153.1
	c-0.4-0.4-1.8-2.1-4-3c-1.1-0.4-2.9-1.1-5.6-1.5c-2.9-0.4-6.9-0.8-12-0.6c-1.2,0-2.3,0.1-3.3,0.4c-0.1,0-0.3,0-0.4,0.1
	c-6.7,1.2-8.5,4.4-8.7,4.5c-0.1,0.1,0.3,0.6,0.7,0.6s7.2,0.3,7.2,0.3c0.5,0,0.8-2.8,5.5-2.9c4.5-0.1,6.2,0,7.8,0.4
	c1.7,0.3,3.3,1,4.1,2.8c0,0,0.3,0.1,0.5,0.1c0.4,0,7.2-0.4,7.7-0.4C438,153.9,438.4,153.5,438,153.1L438,153.1z"
          />
          <path
            fill="#E9EAE9"
            d="M73.9,184.7c-3.3,4.8-19.3,28.3-20.5,30.4c-1.1,2.1-1,4.5-0.7,6.5c0.3,1.8,1.2,5.4,1.8,8
	c2.6-5.8,6.9-13.8,12.5-22.6c8.9-13.8,18.8-24.4,31.8-33c2.1-1.4,4.5-2.6,7.4-3.9c4.1-1.8,8.8-3.4,13.5-4.7c2.6-0.7,4-1.2,3.7-2.1
	s-2.2-0.8-8.7-0.8c-3.4,0-8,0.8-12.7,2.6c0,0,0.1-0.6-0.1-0.7l-0.8,0.4c-5.8,2.3-10.6,4.4-15.1,8C81.6,176,77.3,179.9,73.9,184.7
	L73.9,184.7z M369.8,201.1c-1.5-0.3-11.1-2.2-35.8-8c-25.6-5.9-47.2-11.3-63.1-15.3c-0.3,0.1-0.5,0.4-0.5,0.4
	c-27.9,30.3-32.2,91.9-32.2,116s4.4,86,32.5,116.1l0.3,0.3c16-4,37.7-9.4,63.3-15.3c25.2-5.9,34.7-7.8,35.8-8v-0.1
	c-8.1-30.8-11-61.4-11-93C358.8,262.6,361.7,232,369.8,201.1C369.8,201.2,369.8,201.2,369.8,201.1L369.8,201.1z"
          />
          <path
            fill="#5F605F"
            stroke="#E9282A"
            strokeWidth="0.5"
            strokeMiterlimit="2.613"
            d="M576.4,173l16.1,0.8c0,0-11.1,6.5-12.9,8.3
	L576.4,173L576.4,173z"
          />
          <path
            fill="#5F605F"
            d="M796.2,284.2c-0.1-3-1.4-5.4-2.8-5.4c-1.4,0-2.3,2.5-2.3,5.5c0,1.2,0.3,2.3,0.7,3.3c-0.3,1-0.4,2.1-0.4,3.3
	c0,1.2,0.3,2.5,0.7,3.4c-0.4,1-0.5,2.1-0.7,3.4c0,1.2,0.1,2.5,0.4,3.4c-0.4,1-0.5,2.1-0.7,3.3c-0.1,3,1,5.5,2.3,5.5
	c1.4,0,2.6-2.3,2.8-5.4c0-1.2-0.1-2.5-0.4-3.4c0.3-1,0.5-2.1,0.5-3.3c0-1.4-0.1-2.6-0.5-3.6c0.3-1,0.5-2.2,0.5-3.4
	c0-1.2-0.3-2.3-0.5-3.3C796.1,286.6,796.2,285.5,796.2,284.2L796.2,284.2z"
          />
          <path
            fill="#E9EAE9"
            d="M755.9,170.1c-0.1,0-0.4,0-0.7,0.1c-0.7,0.3-4.3,1.9-4.8,2.2c-0.3,0.1-0.4,0.4-0.4,0.6
	c0,0.1,0.3,0.4,0.7,0.6c13.9,7.6,22,22.6,23,25l0.1,0.1c0.1,0.3,0.5,1,1.2,2.1l1.4,0.1c1.8,3.9,8,20.6,10.9,38.8
	c0,0.3,0.1,0.4,0.1,0.7c0,0.3,0.1,0.4,0.1,0.7c0.1,0.1,0.1,0.4,0.3,0.6c0.5,1,1.7,1.5,2.2,0.6c0.8-1.2,1.5-2.5,1.7-3
	c0.1-0.4,0.3-0.8,0.3-1.1c-0.3-1-0.8-1.2-1-1.2c0-0.6-0.1-1.1-0.1-1.6c-1.4-7.3-5.4-22.4-8.5-31.6c-2.5-7.2-7.2-17.6-9.5-22
	c-0.7-1.2-1.4-2.1-2.3-2.9c-5.8-4.9-13.1-8.1-13.8-8.3C756.6,170.3,756.3,170.3,755.9,170.1L755.9,170.1z M602.5,410.6
	c-0.8-0.4-9.6-6.3-14.7-11c-0.5-0.5-0.7-1.1-0.7-1.5c5.9,0.8,11.7,1.8,17.6,2.9c16.5,2.9,30.4,5.2,40.7,7c-0.3,0.3-0.7,0.7-1.9,0.8
	c-1.9,0.1-26,1.8-38.2,2.5C604.2,411.1,603.4,411,602.5,410.6L602.5,410.6z M467.3,419.1c4.8-10.7,8.3-23.8,9.1-27.6
	c27.6-0.1,57.1,1,84.1,3.6c2.3,0.3,4.5,0.4,6.7,0.7c1.8,1.5,6.7,6.3,12.2,10.6l-3.2,9.1C540.3,417.2,499.8,418.7,467.3,419.1
	L467.3,419.1z M319.3,418.3c1.2-1.4,5.8-6.6,6.5-7.3c0.5-0.7,1.1-1.6,1.4-2.2c17.6-4.5,33.6-8.4,42.9-10.3
	c6.1-1.2,14.6-2.6,28.3-3.9c16.4-1.6,38.8-2.8,61.4-3.3c-1.9,3.6-9.1,15.5-21.3,28.1c-10.3,0.1-21.2,0.1-31.4,0.1
	c-31.9-0.1-63.3-0.3-87.2-0.5C319.6,418.7,319.4,418.4,319.3,418.3L319.3,418.3z"
          />
          <path
            fill="#5F605F"
            d="M289.6,419c12.5-3.6,24.9-6.5,37.3-9.8c-0.3,0.5-0.8,1.5-1.5,2.2c-0.7,0.7-5.4,5.8-6.6,7.2
	c-0.3-0.3-0.4-0.5-0.7-0.8c-0.3-0.3,0-1-0.4-1c0-0.4-0.3-1.1-2.6-1.1s-7.3,0.3-8.9,0.3c-1,0-1.8,0.1-2.2,0.5c-0.5,0.1-0.8,0.5-0.5,1
	c0,0,0,0.5,0.7,1.9l-14-0.1c-0.3,0-0.7-0.1-0.8-0.1C289.4,419.1,289.6,419,289.6,419L289.6,419z M563.5,433.6
	c1.2,1.2,4.1,1.8,9.1,1.5c3.3-0.1,5.4-1.1,6.2-1.9c0.4-0.4,0.8-1,0.8-1.1c0.3-0.4,0.4-0.4,0.8-0.4h1.5c2.2,0,5.9,0.1,6.2,0.1
	s0.5,0.4,0.5,0.7c0,0.1-0.1,0.3-0.1,0.3c-0.4,0.4-5,4.8-17.2,5.4c-12.4,0.4-16.1-4-16.6-4.4c-0.1-0.1-0.1-0.1-0.1-0.3
	c0-0.3,0.4-0.4,0.7-0.4c0,0,6.7-0.7,7.2-0.7c0.3,0,0.3,0.1,0.5,0.4C562.9,433,563,433.3,563.5,433.6L563.5,433.6z M438,435.3
	c-0.4,0.4-1.8,2.1-4,3c-1.1,0.4-2.9,1.1-5.6,1.5c-2.9,0.5-6.9,0.8-12,0.5c-1.2-0.1-2.3-0.1-3.3-0.4h-0.4c-6.7-1.2-8.5-4.4-8.7-4.5
	c-0.1-0.1,0.3-0.5,0.7-0.5s7.2-0.3,7.2-0.3c0.5,0,0.8,2.8,5.5,2.9c4.5,0.1,6.2,0,7.8-0.4c1.7-0.3,3.3-1,4.1-2.8c0,0,0.3-0.1,0.5-0.1
	c0.4,0,7.2,0.4,7.7,0.4C437.9,434.5,438.4,434.9,438,435.3L438,435.3z"
          />
          <path
            fill="#E9EAE9"
            d="M73.9,403.7c-3.3-4.8-19.3-28.3-20.5-30.4c-1.1-2.1-1-4.5-0.7-6.5c0.3-1.8,1.2-5.4,1.8-8
	c2.6,5.8,6.9,13.8,12.5,22.6c8.9,13.8,18.8,24.4,31.8,33c2.1,1.4,4.5,2.8,7.4,3.9c4.1,1.8,8.8,3.4,13.5,4.7c2.6,0.7,4,1.2,3.7,2.1
	c-0.3,0.8-2.2,0.8-8.7,0.8c-3.4,0-8-0.8-12.7-2.6c0,0,0.1,0.5-0.1,0.7l-0.8-0.4c-5.6-2.3-10.6-4.4-15.1-8
	C81.6,412.5,77.3,408.5,73.9,403.7L73.9,403.7z"
          />
          <path
            fill="#5F605F"
            stroke="#E9282A"
            strokeWidth="0.5"
            strokeMiterlimit="2.613"
            d="M576.3,415.4l16.1-0.8
	c0,0-11.1-6.5-12.9-8.3L576.3,415.4L576.3,415.4z"
          />
          <path
            fill="#E9EAE9"
            d="M756.2,418.7c-0.1,0-0.4,0-0.7-0.1c-0.7-0.3-4.3-1.9-4.8-2.2c-0.3-0.1-0.4-0.4-0.4-0.5s0.3-0.4,0.7-0.5
	c13.9-7.6,22-22.6,23-25l0.1-0.1c0.1-0.3,0.6-1,1.2-2.1l1.4-0.1c1.8-3.9,8-20.6,10.9-38.8c0-0.3,0.1-0.4,0.1-0.7
	c0-0.3,0.1-0.4,0.1-0.7c0.1-0.1,0.1-0.4,0.3-0.5c0.5-1,1.7-1.5,2.2-0.5c0.8,1.2,1.5,2.5,1.7,3c0.1,0.4,0.3,0.8,0.3,1.1
	c-0.3,1-0.8,1.2-1,1.2c0,0.5-0.1,1.1-0.1,1.6c-1.4,7.3-5.4,22.4-8.5,31.6c-2.5,7.2-7.2,17.6-9.5,22c-0.7,1.2-1.4,2.1-2.3,2.8
	c-5.8,5-13.1,8.1-13.8,8.3C756.9,418.6,756.5,418.7,756.2,418.7L756.2,418.7z M321.5,162.1c-0.5,0.4-2.6,1.5-2.8,1.9
	c-0.3,0.3-0.1,0.6,0.1,0.6c0.8,0.1,4.3,0.8,5,1c0.7,0,0.8,0,1.2-0.6c0.3-0.6,1-1.8,1-2.2c0.1-0.3,0.1-0.6-0.7-0.7
	c-1-0.3-1.9-0.3-2.5-0.3S321.8,162,321.5,162.1L321.5,162.1z M322.5,424.6c-0.7-0.1-4.1-0.3-5.1-0.3c-0.3,0-0.4,0.1-0.3,0.5
	c0.1,0.4,1.6,1.8,2.2,2.3c0.4,0.4,1,0.5,1.9,0.8c0.8,0.3,1.4,0.3,2.1,0.3c0.5,0,0.8,0,0.7-0.7c0-0.3-0.3-1.8-0.5-2.3
	C323.3,424.8,323.1,424.6,322.5,424.6L322.5,424.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M369.8,387.3c0,0-8.8,1.6-35.8,8
	c-27,6.3-49.5,12-65.8,16c-0.5,0.3-1.1,0.3,0,1.5 M369.9,387.3c6.1-1.1,14.6-2.6,28.3-3.9c19.5-1.9,47.3-3.4,73.9-3.4
	c34.7,0,83.8,0.8,125,5c12.7,1.2,24.3,2.5,35.2,3.7c19,2.2,43.6,5,52.1,5.9c5.5,0.7,12.8,1.2,19.3,1.5c3.4,0.1,7,0.1,9.4,0.1
	c3.7-0.1,19.3-1.2,33.4-2.5c11.4-1,21.6-2.1,22-2.2c1.5-0.1,2.9-0.7,2.9-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M369.9,390.1c6.1-1.1,14.6-2.6,28.3-3.9
	c19.5-1.9,47.3-3.4,73.9-3.4c34.7,0,83.8,0.8,125,5c12.7,1.2,24.3,2.5,35.2,3.7c19,2.2,43.6,5,52.1,5.9c5.5,0.7,12.8,1.2,19.3,1.5
	c3.4,0.1,7,0.1,9.4,0.1c3.7-0.1,19.3-1.2,33.4-2.5c11.4-1,21.6-2.1,22-2.2c1.5-0.1,2.9-0.7,2.9-0.7 M307.7,430.2
	c-11.1-0.3-22.7-0.5-34.8-0.8c-69.9-1.9-123.5-5-166.6-12.5 M749.6,415.1c0,0-7.2,1.9-25.5,4.3c-12.8,1.6-31.4,3.3-55.4,5.4
	c-60.7,5-168.5,6.7-221.1,6.7c-39.8,0-76.6,0-120.2-0.8 M330.3,438.9c25.6,0.4,54.6,1,82.3,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M600.1,417c0,0,5.4,9.4,5.4,11.1
	c0,0.7-2.1,3.9-6.6,6.3c-5.4,2.9-11.8,5-19.4,5 M452.7,419.2c0,0,0.4,1.1,0.1,1.7l-0.7,1.4c0,0-3.7,8.3-4.7,9.1 M447.2,431.5
	c-0.4,1.2-4.3,8.3-5.2,8.3 M271.7,429.6c-0.4,1.8-3.3,8.3-4,8.1 M267,412c-3.2,2.2-13.3,8.5-27.4,8.4c-14-0.1-57.5-2.3-90.5-5.8
	c-12.5-1.4-25.2-2.8-35.5-4.5c-16.9-3-27.8-6.1-27.8-6.1s-0.8-0.3-1.2-0.7 M263.3,411.1c0,0-48.4-8-93.1-16.2
	c-44.7-8.4-81.6-19.5-102.5-29c-3-1.4-1.9-3,7-1.8c15,1.9,47.5,8.8,87.9,18c34.4,7.8,66.2,15.7,84.3,21.3
	C262.9,408.4,266.3,411.5,263.3,411.1L263.3,411.1z M263.3,178c0,0-48.4,8-93.1,16.2c-44.7,8.4-81.6,19.5-102.5,29
	c-3,1.4-1.9,3,7,1.8c15-1.9,47.5-8.8,87.9-18c34.4-7.8,66.2-15.7,84.3-21.3C262.9,180.7,266.3,177.5,263.3,178L263.3,178z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M123.7,425.6c0.3-0.8-1.2-1.4-3.7-2.1
	c-7.7-2.2-15.8-5.2-20.9-8.7c-12.9-8.7-22.8-19.3-31.8-33c-8.9-13.8-14.2-25.9-15.5-29.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M54.6,359.3c-0.7,2.5-1.5,6.1-1.8,7.8
	c-0.3,1.9-0.4,4.4,0.7,6.5c1.1,2.1,17.1,25.6,20.5,30.4c3.3,4.8,7.7,8.8,12.2,12.2c4.4,3.4,9.4,5.6,15.1,8c6.1,2.5,10.7,2.6,14,2.8
	c6.6,0.1,8-0.1,8.4-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M102.2,423.8c0,0,0.1,0.8-0.3,0.8
	s-1.2,0.4-1.8,0.4s-0.6,0.3-0.1,0.4c0.4,0.3,6.1,2.8,12,5.4c5.9,2.6,13.3,6.5,15.3,6.9 M437.9,435.3c-0.4,0.4-1.8,2.1-4,3
	c-2.3,1-7.7,2.5-17.5,1.9c-9.8-0.5-12.2-4.8-12.4-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M404,435.5c-0.1-0.1,0.3-0.5,0.7-0.5
	s7.2-0.3,7.2-0.3c0.5,0,0.8,2.8,5.5,2.9c4.5,0.1,6.2-0.1,7.8-0.4c1.7-0.3,3.3-1,4.1-2.8c0,0,0.1-0.1,0.5-0.1s7,0.4,7.6,0.4
	s1,0.4,0.6,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M431.1,434.2c0,0,0.1,0.5,0.1,1.2
	c0.1,0.7,0.3,2.3,0.4,2.8c0.1,0.4,0.1,0.8,0.1,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M404.3,434.8c0.5,0.8,4.3,4.8,13.5,5
	c3.4,0,7,0,9.9-0.5c4.8-0.7,8.5-2.3,10-4.7 M554.7,433.6c0,0.1,0,0.1,0.1,0.3c0.4,0.4,4.3,5,16.5,4.4c12.2-0.4,16.8-5,17.2-5.4
	c0.1-0.1,0.1-0.3,0.1-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M588.6,432.6c0-0.3-0.3-0.7-0.5-0.7
	c-0.4,0-7.3-0.1-7.7-0.1c-0.4,0-0.5,0-0.8,0.4c0,0-0.4,0.5-0.8,1.1c-0.8,0.8-2.9,1.6-6.2,1.9c-5.1,0.3-7.8-0.3-9.1-1.5
	c-0.3-0.3-0.4-0.5-0.5-0.8c-0.3-0.3-0.3-0.4-0.5-0.4c-0.4,0-7,0.7-7,0.7c-0.3,0-0.7,0.1-0.7,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M554.8,433.4c0.5,0.4,4.3,5,16.5,4.4
	c12.2-0.4,16.8-4.8,17.2-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M582,431.8c0,0,0.4,3.3,0.5,3.7
	c0.1,0.4,0.1,0.8,0.1,0.8 M238.1,294.3c0,24.1,4.4,86.1,32.6,116.2 M271.3,173.8c37.6,9.9,77.9,19.8,96.4,24.1
	c0.8,0.1,2.8,0.7,2.1,3.3c-8.1,30.8-11,61.4-11,93c0,31.6,2.9,62.2,11,93c0.7,2.5-1.2,3-2.1,3.3c-18.4,4.3-58.9,14.2-96.4,24.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M369.8,201.1c0,0-8.8-1.6-35.8-8
	c-27-6.3-49.5-12-65.8-16c-0.5-0.3-1.1-0.3,0-1.5 M369.9,201.1c6.1,1.1,14.6,2.6,28.3,3.9c19.5,1.9,47.3,3.4,73.9,3.4
	c34.7,0,83.8-0.8,125-5c12.7-1.2,24.3-2.5,35.2-3.7c19-2.2,43.6-5,52.1-5.9c5.5-0.7,12.8-1.2,19.3-1.5c3.4-0.1,7-0.1,9.4-0.1
	c3.7,0.1,19.3,1.2,33.4,2.5c11.4,1,21.6,2.1,22,2.2c1.5,0.1,2.9,0.7,2.9,0.7l2.2,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M369.9,198.3c6.1,1.1,14.6,2.6,28.3,4
	c19.5,1.9,47.3,3.4,73.9,3.4c34.7,0,83.8-0.8,125-5c12.7-1.2,24.3-2.5,35.2-3.7c19-2.2,43.6-4.9,52.1-5.9c5.5-0.7,12.8-1.2,19.3-1.5
	c3.4-0.1,7-0.1,9.4-0.1c3.7,0.1,19.3,1.2,33.4,2.5c11.4,1,21.6,2.1,22,2.2c1.5,0.1,2.9,0.7,2.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M705.6,192.5c-0.8,0.1-0.7,0.8-0.5,1.6
	c11.6,24.1,15.3,67.8,15.3,100c0,32.3-3.6,76.1-15.3,100c-0.3,0.7-0.4,1.5,0.5,1.6 M598.3,203.3c0,0,0.3,0.8,0.4,1.4
	c5.2,16.4,12.4,45.1,12.4,89.6s-7.2,73.2-12.4,89.6c-0.1,0.4-0.4,1.4-0.4,1.4 M749.6,173.3c0,0-7.2-1.9-25.5-4.3
	c-12.8-1.6-31.4-3.3-55.4-5.4c-60.7-5-168.5-6.7-221.1-6.7c-39.8,0-71.7,0-115.3,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M428.6,148.5c4.1,0,8.3,0,12.2,0.1
	c61.4,0.7,102.3,1.2,115.7,1.5c12.7,0.1,19.4-1.8,38.7-1.5c19.3,0.3,61.8,0.8,80.1,1.4c5.2,0.1,9.9,0.1,13.1,0.3
	c7.8,0.6,11.1,1.8,16,2.8c12.8,2.6,25.7,5.4,46.9,10.5c31.9,7.7,36.2,15,45.4,47c10,34.8,9.8,83.6,9.8,83.6s0.4,48.6-9.8,83.6
	c-9.5,32.7-12.4,39.1-44.8,47.3c-21.2,5.4-34.8,8.1-47.5,10.7c-4.8,1-8.1,1.8-16,2.3c-3.2,0.3-7.8,0.1-13.1,0.3
	c-18.3,0.4-60.8,1.1-80.1,1.4c-19.3,0.3-26-1.6-38.7-1.5c-13.2,0.1-54.2,0.8-115.7,1.5c-4,0-8.1,0.1-12.2,0.1 M337,149.5
	c25.6-0.4,47.9-1,75.7-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M600.1,171.4c0,0,5.4-9.4,5.4-11.1
	c0-0.7-2.1-3.9-6.6-6.3c-5.4-2.9-11.8-5-19.4-5 M452.7,169.2c0,0,0.4-1.1,0.1-1.6l-0.7-1.4c0,0-4-8.3-4.8-9.1 M447.1,156.9
	c-0.4-1.2-4.3-8.3-5.2-8.3 M271.8,159.1c0.5,0,1.2,0.8,1.8,2.3c0.5,1.4,3.9,6.9,4.5,7.3c0.7,0.4,0.3,1.1-0.1,1.2
	c-1.2,0.6-7.2,3.4-10.6,6.3c-6.2,5.1-14,13.2-21,28.3c-8.4,18.3-15.1,46.9-15.1,89.7c0,42.8,6.7,71.5,15.1,89.7
	c6.9,15.1,14.9,23.2,21,28.3c3.4,2.8,9.4,5.8,10.6,6.3c0.4,0.1,0.8,0.7,0.1,1.2c-0.7,0.4-4,5.9-4.5,7.3c-0.5,1.4-1.2,2.3-1.8,2.3
	 M271.7,159c-0.4-1.8-3.3-8.3-4-8.1 M404,153.1c-0.1,0.1,0.3,0.6,0.7,0.6s7.2,0.3,7.2,0.3c0.5,0,0.8-2.8,5.5-2.9
	c4.5-0.1,6.2,0,7.8,0.4c1.7,0.3,3.3,1,4.1,2.8c0,0,0.1,0.1,0.5,0.1s7-0.4,7.6-0.4s1-0.4,0.6-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M437.9,153.1c-0.4-0.4-1.8-2.1-4-3
	c-2.3-1-7.7-2.5-17.5-1.9c-9.8,0.6-12.2,4.8-12.4,4.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M431.1,154.2c0,0,0.1-0.6,0.1-1.2
	c0.1-0.7,0.4-2.3,0.4-2.8c0.1-0.4,0.1-0.8,0.1-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M404.6,153.3c1.1-1.2,5-4.5,13.2-4.7
	c3.4,0,7,0,9.9,0.6c4.5,0.7,8,2.1,9.8,4.3 M554.7,154.9c0-0.1,0-0.1,0.1-0.3c0.4-0.4,4.3-5,16.5-4.4c12.2,0.6,16.8,4.8,17.2,5.4
	c0.1,0.1,0.1,0.3,0.1,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M588.6,155.8c0,0.4-0.3,0.7-0.5,0.7
	c-0.4,0-7.3,0.1-7.7,0.1c-0.4,0-0.5,0-0.8-0.4c0-0.1-0.4-0.6-0.8-1.1c-0.8-0.8-2.9-1.8-6.2-1.9c-5.1-0.3-7.8,0.3-9.1,1.5
	c-0.3,0.3-0.4,0.6-0.5,0.8c-0.3,0.3-0.3,0.4-0.5,0.4c-0.4,0-7-0.7-7-0.7c-0.3,0-0.7-0.1-0.7-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M555.1,155.1c1.1-1.1,5.2-4.5,16.2-4.1
	c11.1,0.4,16,4.1,16.9,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M582,156.6c0,0,0.4-3.3,0.5-3.7
	c0.1-0.4,0.1-0.8,0.1-0.8 M752.3,174.8c12.7,9.1,18.2,19.5,19.5,22.8c1.7,4,4.4,10.2,6.9,19.1c4.3,16.5,8.3,42.2,8.3,77.4
	s-3.8,60.9-8.3,77.5c-2.3,8.9-5.1,15.3-6.9,19.1c-1.4,3.2-6.9,13.8-19.5,22.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M751.2,174.2c12.7,9.1,17.1,20.1,18.4,23.2
	c1.7,4,4.4,10.2,6.7,19.1c4.3,16.5,8.3,42.2,8.3,77.4c0,35.2-3.9,60.9-8.3,77.4c-2.3,8.9-5.1,15.3-6.7,19.1
	c-1.4,3.2-5.8,14.2-18.4,23.2 M238.1,294.2c0-24.1,4.4-86.1,32.6-116.2 M714,294.3c0,44.6-6.6,74.4-12.4,88.9
	c-1.4,3.7-4,4.4-6.6,4.3c-2.6-0.1-62.9-6.6-81.2-8.5c-3.6-0.4-6.5-2.8-5.4-7.8c1.1-5.1,7.2-39.9,7.2-45.7c0-2.3,0-3.3,1.4-3.6
	c1.4-0.3,2.1-0.5,2.3-0.5s0.5-0.3,0.5-0.7c0-0.4,0.5-8.9,0.5-26.1s-0.5-25.7-0.5-26.1c0-0.4-0.3-0.5-0.5-0.7c-0.3,0-1-0.3-2.3-0.5
	c-1.4-0.3-1.4-1.2-1.4-3.6c0-5.8-6.1-40.6-7.2-45.7c-1.1-5.2,1.8-7.4,5.4-7.8c18.3-2.1,78.5-8.4,81.2-8.5c2.6-0.1,5.1,0.6,6.6,4.3
	C707.2,219.9,714,249.8,714,294.3L714,294.3z M614.4,160.5c0.1-3.7,2.3-3.7,3.4-3.9c3.3-0.3,22.1,0.7,25.9,0.8c1,0,2.2,0.3,3,0.6
	c1.7,0.6,2.5,1.4,2.6,2.5c0.1,1.5,0.1,1.8,0.1,1.8s-0.1,2.2-0.7,4.1c-0.5,2.1-2.5,4.3-5.5,4.4s-16.2-0.6-21.6-1.2
	c-5.2-0.6-6.7-1.9-7-4.4C614.5,162.8,614.4,160.5,614.4,160.5L614.4,160.5z M796.2,304.7c-0.1,2.6-1.1,4.7-2.2,4.7s-1.9-2.2-1.8-4.8
	c0.1-2.6,1.1-4.7,2.2-4.7S796.4,301.9,796.2,304.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M795.1,302.3c0.1,0.7,0.1,1.4,0.1,2.2
	c-0.1,2.2-0.8,4.1-1.7,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M792.6,293.2c0.4-0.5,0.8-0.8,1.4-0.8
	c1.4,0,2.5,2.5,2.3,5.5c-0.1,3-1.2,5.4-2.6,5.4c-0.5,0-1-0.3-1.2-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M792.5,300c0.4-0.5,1-0.8,1.4-0.8
	c1.4,0,2.3,2.5,2.3,5.5c-0.1,3-1.4,5.4-2.8,5.4c-1.4,0-2.3-2.5-2.3-5.5c0-1.2,0.3-2.3,0.7-3.2c-0.3-1-0.5-2.1-0.4-3.4
	c0-1.2,0.3-2.5,0.7-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M796.4,297.9c-0.1,2.6-1.1,4.8-2.2,4.7
	c-1.1,0-1.9-2.2-1.9-4.8c0.1-2.6,1.1-4.8,2.2-4.7C795.7,293.1,796.5,295.3,796.4,297.9L796.4,297.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M793.9,299.8c0.4,0.3,0.7,0.7,1,1.4
	c-0.3,0.7-0.5,1.1-1,1.4 M795.3,295.7c0.1,0.7,0.3,1.5,0.1,2.3c0,0.7-0.1,1.5-0.3,2.1 M791.8,301.2h1 M794.8,301.2h1 M796.2,284.2
	c-0.1-2.6-1.1-4.7-2.2-4.7s-1.9,2.2-1.8,4.8c0.1,2.6,1.1,4.7,2.2,4.7S796.4,286.8,796.2,284.2L796.2,284.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M795.1,286.4c0.1-0.7,0.1-1.4,0.1-2.2
	c-0.1-2.2-0.8-4.1-1.7-4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M792.6,295.6c0.4,0.5,0.8,0.8,1.4,0.8
	c1.4,0,2.5-2.5,2.3-5.5c-0.1-3-1.2-5.4-2.6-5.4c-0.5,0-1,0.3-1.4,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M792.5,288.8c0.4,0.5,1,0.8,1.4,0.8
	c1.4,0,2.3-2.5,2.3-5.5c-0.1-3-1.4-5.4-2.8-5.4c-1.4,0-2.3,2.5-2.3,5.5c0,1.2,0.3,2.3,0.7,3.2c-0.3,1-0.5,2.1-0.4,3.4
	c0,1.2,0.3,2.5,0.7,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M796.4,290.9c-0.1-2.6-1.1-4.8-2.2-4.7
	c-1.1,0-1.9,2.2-1.9,4.8c0.1,2.6,1.1,4.8,2.2,4.7C795.7,295.7,796.5,293.5,796.4,290.9L796.4,290.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M793.9,289c0.4-0.1,0.7-0.7,1-1.4
	c-0.3-0.7-0.5-1.1-1-1.4 M795.3,293.2c0.1-0.7,0.3-1.5,0.3-2.3c0-0.7-0.1-1.5-0.3-2.1 M794,295.6c0.4-0.1,0.7-0.5,1-1.2
	c-0.3-0.5-0.5-1-1-1.2 M791.8,287.6h1 M794.8,287.6h1 M793.1,294.5h-1 M795,294.3h0.8 M621.5,209.2v170.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M310.3,438.6c-17.3-0.4-31.2-1-41.7-1.1c-10.2-0.1-15.4,0.4-22.3,1.2
	c-6.7,0.8-13.5,1.9-36.7,1.8c-23.2-0.1-62-1.6-77-2.6c-3.9-0.3-7.4-0.8-10.2-1.1c-4.3-0.5-23.1-6.5-28.1-8.5s-13.9-8.3-17.9-12.2
	c-4-4-19.4-21.6-31.2-43.6c-5.6-10.5-5.4-13.9-6.5-21.7c-2.5-17.7-3.2-46.8-3.2-56.3 M422.6,391.7c0.7,0.1,1.6,0.3,2.6,0.5
	 M588.4,188.7c4,0.8,6.7,1.4,6.7,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M688.2,150.2c2.6,0.1,4.4,0.8,6.6,1.6
	c2.1,0.7,10.5,3.4,17.1,4.8 M788.7,242.3c-0.4,0-0.8-0.4-1.1-1.6c-2.3-10.3-2.9-13.2-6.5-24.8c-3.6-11.7-7.3-17.3-7.3-17.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M750,173c0-0.1,0-0.4,0.4-0.6
	c0.7-0.3,4.3-1.9,4.8-2.2c0.7-0.3,1.1,0,1.7,0.1c0.5,0.3,8,3.4,13.8,8.3c1,0.8,1.7,1.6,2.3,2.9c2.3,4.3,7,14.7,9.5,22
	c3.2,9.4,7.3,24.5,8.5,31.6c0.4,2.2,0.1,3.3-0.3,4.3s-0.8,1.8-1.2,2.3c-0.1,0.3-0.5,0.6-0.8,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M773.8,198.6c-1.1-2.5-9.2-17.5-23-25
	c-0.4-0.1-0.5-0.4-0.7-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M750.4,173.4c0.7-0.3,4.3-1.9,4.8-2.2
	c0.7-0.3,1.1,0,1.7,0.1c0.5,0.3,7.4,3,13.2,8c1,0.8,2.2,2.8,2.9,4c2.2,3.9,5.8,11.4,8.7,18.8l-1.8,0.3l2.2,1.5
	c3.9,9.9,7.2,24.2,7.7,27.8c0.7,3.6,0.1,4.4-0.1,5.2s-0.8,2.2-0.8,2.2 M791.3,237c0,0,0.8,0.3,1.1,1.6c1.4,9.9,3.2,23.2,3.7,43.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M787.6,240.7c0.3,1.5,1.9,3,2.8,1.8
	c0.8-1.2,1.5-2.5,1.7-3c0.1-0.6,0.3-1,0.1-1.6 M756.2,177.1c2.8,1.2,5.5,2.9,7.7,4.8c5,4.3,8.8,10.3,11.7,16.9h-1.8 M775.6,198.7
	l0.7,0.6l0.7-0.1l1,0.6 M754.4,175.1c1.1-0.6,3.7-1.8,4.3-1.9v-0.7c0,0-1.1-0.6-2.1-0.8c0,0-3,1.4-5,2.5 M753.7,174.8
	c1.1-0.6,4.3-2.2,4.8-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M764.7,180.6c1.8,0.6,3.2,1,3.6,1.1
	c0.4,0.1,1.7,0.7,2.9,2.3c2.9,4,7.8,11.7,9.5,17.6h-3l-3.4-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M766.6,182.5c0.5,0.1,1.1,0.3,1.2,0.4
	c0.4,0.1,1.7,0.7,2.9,2.3c2.6,3.6,6.9,10.2,8.9,15.8H779l-0.7-0.7h-0.8l-0.8-0.6l1.2-0.1c-2.8-6.9-8.4-14.7-12.4-18.3
	c-3.9-3.7-10-5.8-13.9-7.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M758.7,173.1c2.6,1.2,6.6,3.3,9.8,6.5
	c3,3.2,8.1,10,11.1,17.2 M775,200.9l1.5,0.1c1.8,3.9,8,20.8,11,39.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M778.3,201.6c2.3,5.9,4.5,11.6,6.3,18.6
	c1.8,7.2,3.4,14.4,4.5,20.9l-0.3,0.3h-0.5c-1.1-6.6-2.8-13.8-4.5-20.9c-1.8-7.2-4.1-13.1-6.5-19"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M780.4,202.9c3.9,9.9,7.2,23.5,7.8,27.1
	s0.3,3.9,0,4.7c0,0.3-0.1,0.4-0.3,0.7 M711.8,156.6c6.6,1.4,31.5,5.9,45.9,12.4c0.5,0.3,0.7,0.4,0.3,0.6s-1.8,0.6-2.6,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M758,169c5.6,2.3,11.8,5.6,16.5,11.1
	c5,5.8,9.1,16.5,12,31.1c0.6,2.3,1.1,4,1.9,5.6c0.8,1.8,1.8,3.9,2.8,9.5s5.6,32.3,5.6,68 M788.7,346.5c-0.4,0-0.8,0.4-1.1,1.6
	c-2.3,10.3-2.9,13.2-6.5,24.8c-3.6,11.7-7.3,17.3-7.3,17.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M750,415.8c0,0.3,0,0.4,0.4,0.5
	c0.7,0.3,4.3,1.9,4.8,2.2c0.7,0.3,1.1,0,1.7-0.1c0.5-0.3,8-3.4,13.8-8.3c1-0.8,1.7-1.6,2.3-2.9c2.3-4.3,7-14.9,9.5-22
	c3.2-9.4,7.3-24.5,8.5-31.6c0.4-2.2,0.1-3.3-0.3-4.3s-0.8-1.8-1.2-2.3c-0.1-0.3-0.5-0.5-0.8-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M773.8,390.2c-1.1,2.5-9.2,17.5-23,25
	c-0.4,0.1-0.5,0.4-0.7,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M750.4,415.4c0.7,0.3,4.3,1.9,4.8,2.2
	c0.7,0.3,1.1,0,1.7-0.1c0.5-0.3,7.4-3,13.2-7.8c1-0.8,2.2-2.8,2.9-4c2.2-3.9,5.8-11.4,8.7-18.8l-1.8-0.3l2.2-1.5
	c3.9-9.9,7.2-24.2,7.7-27.8c0.7-3.6,0.1-4.4-0.1-5.2c-0.3-0.8-0.8-2.2-0.8-2.2 M791.3,352c0,0,0.8-0.3,1.1-1.6
	c1.4-9.9,3.2-23.2,3.7-43.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M787.6,348.1c0.3-1.5,1.9-3,2.8-1.8
	c0.8,1.2,1.5,2.5,1.7,3c0.1,0.5,0.3,1,0.1,1.6 M756.2,411.7c2.8-1.2,5.5-2.9,7.7-4.8c5-4.3,8.8-10.3,11.7-16.9h-1.8 M775.6,390.1
	l0.7-0.5l0.7,0.1l1-0.5 M754.4,413.7c1.1,0.5,3.7,1.8,4.3,1.9v0.7c0,0-1.1,0.5-2.1,0.8c0,0-3-1.4-5-2.5 M753.7,414
	c1.1,0.5,4.3,2.2,4.8,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M764.7,408.2c1.8-0.5,3.2-1,3.6-1.1
	c0.4-0.1,1.7-0.7,2.9-2.3c2.9-4,7.8-11.7,9.5-17.6h-3l-3.4,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M766.6,406.3c0.5-0.1,1.1-0.3,1.2-0.4
	c0.4-0.1,1.7-0.7,2.9-2.3c2.6-3.6,6.9-10.2,8.9-15.8H779l-0.7,0.7h-0.8l-0.8,0.5l1.2,0.1c-2.8,6.9-8.4,14.7-12.4,18.3
	c-3.9,3.6-10,5.8-13.9,7.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M758.7,415.7c2.6-1.2,6.6-3.3,9.8-6.5
	c3-3.2,8.1-10,11.1-17.2 M775,387.9l1.5-0.1c1.8-3.9,8-20.8,11-39.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M778.3,387.2c2.3-5.9,4.5-11.6,6.3-18.6
	c1.8-7.2,3.4-14.4,4.5-20.9l-0.3-0.3h-0.5c-1.1,6.6-2.8,13.8-4.5,20.9c-1.8,7.2-4.1,13.1-6.5,19"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M780.4,386c3.9-9.9,7.2-23.5,7.8-27.1
	c0.7-3.6,0.3-3.9,0-4.7c0-0.3-0.1-0.4-0.3-0.7 M711.8,432.2c6.6-1.4,31.5-5.9,45.9-12.4c0.5-0.3,0.7-0.4,0.3-0.6
	c-0.4-0.1-1.8-0.5-2.6-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M758,419.8c5.6-2.3,11.8-5.6,16.5-11.1
	c5-5.8,9.1-16.5,12-31.1c0.6-2.3,1.1-4,1.9-5.6c0.8-1.8,1.8-3.9,2.8-9.5c1-5.6,5.6-32.3,5.6-68 M687.5,438.2
	c2.6-0.1,5.1-0.5,7.2-1.2c2.1-0.7,10.5-3.4,17.1-4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M43.5,271.2c0,0-2.1,0.5-2.5,0.7c-0.1,0-0.3,0.1-0.4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M47.3,361.2c-0.3,0-0.5,0-1-0.1
	c-1-0.3-3.4-1.4-5-6.3c-1.5-4.8-1.5-7.7-1.5-10.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M50.6,294.3c0,27,0.1,26.8,1.4,49.1
	c0.3,3.9,0,5.6-0.3,7.6c-0.1,0.5-0.4,1.9-0.8,3.4c-0.3,1-2.2,4.8-2.8,5.8c-0.4,0.6-0.5,0.8-1,1 M46.5,358.7c-0.3,0.1-0.5,0.1-0.8,0
	c-1-0.3-2.9-1-4.3-4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M49.5,294.3c0,24.9,0,25.3,1.2,47
	c0.3,3.9,0.1,5.6-0.1,7.4c-0.1,1-0.8,2.6-1.4,4.1c-0.4,1.4-1.7,4.3-2.1,5c-0.3,0.4-0.4,0.7-0.8,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M41.3,279.8c0.1-3.7-0.5-6.9-1.4-6.9s-1.7,2.9-1.8,6.6
	c-0.1,3.7,0.5,6.9,1.4,6.9C40.3,286.5,41.1,283.5,41.3,279.8L41.3,279.8z M39.9,284.6c0.1,0.3,0.3,0.4,0.4,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M40.7,274.4c-0.7,0.3-1.2,2.5-1.4,5.2c0,1.1,0,1.9,0.1,2.9 M42,284.7
	c0.3-1.4,0.5-3,0.7-5c0.1-4.3-0.7-7.7-1.7-7.7 M40.4,287.3c0.1,0,0.4-0.1,0.5-0.3 M38.9,286.1c0.4,0.5,1,1.2,1.5,1.2 M41,272.1
	c-0.8,0.1-1.2,0.7-1.5,1 M41,287c-0.1-1-0.3-1.6-0.4-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M41,287c0.1,0.8,0.1,1.6,0.1,2.5c0,3.7-0.8,6.7-1.8,6.7
	c-0.8,0-1.5-3.2-1.5-6.9s0.8-6.7,1.7-6.7c0.4,0,0.8,0.7,1.1,1.9 M39.8,294.3c0.1,0.3,0.3,0.5,0.4,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M39.3,286.6c-0.1,0.8-0.3,1.8-0.3,2.8s0,1.8,0.1,2.6 M40.3,284.2
	c-0.1,0-0.3,0.1-0.4,0.4 M41.1,282.2c-0.1-0.3-0.4-0.4-0.5-0.4 M41.8,294.3c0.3-1.4,0.4-2.9,0.5-4.8c0-1.8-0.1-3.6-0.4-4.8
	 M40.4,297.1c0.1,0,0.4-0.1,0.5-0.4 M38.8,295.9c0.4,0.5,1,1.2,1.5,1.2 M40.6,282c-0.8,0.1-1.2,0.7-1.5,1.1 M40.6,284.6l8.9,0.1
	 M40.9,317c-0.3-0.1-0.7-0.4-1-1 M43.5,317.5c-0.8-0.1-2.5-0.4-2.6-0.4 M41.3,308.9c0.1,3.7-0.5,6.9-1.4,6.9s-1.7-2.9-1.8-6.6
	c-0.1-3.7,0.5-6.9,1.4-6.9C40.3,302.2,41.1,305.1,41.3,308.9L41.3,308.9z M39.9,304c0.1-0.3,0.3-0.4,0.4-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M40.7,314.3c-0.7-0.3-1.2-2.5-1.4-5.2c0-1.1,0-1.9,0.1-2.9 M42,303.8
	c0.3,1.4,0.5,3,0.7,5c0.1,4.3-0.7,7.7-1.7,7.7 M40.4,301.2c0.1,0,0.4,0.1,0.5,0.3 M38.9,302.5c0.4-0.7,1-1.2,1.5-1.2 M41,301.5
	c-0.1,1-0.3,1.6-0.4,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M41,301.5c0.1-0.8,0.1-1.6,0.1-2.5c0-3.7-0.8-6.7-1.8-6.7
	c-0.8,0-1.5,3.2-1.5,6.9c0,3.7,0.8,6.7,1.7,6.7c0.4,0,0.8-0.7,1.1-1.9 M39.8,294.2c0.1-0.3,0.3-0.5,0.4-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M39.3,301.9c-0.1-0.8-0.3-1.8-0.3-2.8s0-1.9,0.1-2.6 M40.3,304.4
	c-0.1,0-0.3-0.1-0.4-0.4 M41.1,306.3c-0.1,0.3-0.4,0.4-0.5,0.4 M41.8,294.3c0.3,1.2,0.4,2.9,0.5,4.7c0,1.8-0.1,3.4-0.4,4.8
	 M40.4,291.5c0.1,0,0.4,0.1,0.5,0.3 M38.8,292.7c0.4-0.5,1-1.2,1.5-1.2 M40.6,306.7c-0.8-0.1-1.2-0.7-1.5-1.1 M40.6,304h8.9
	 M40.6,294.3h8.9 M41,316.5c-0.8-0.1-1.2-0.7-1.5-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M56.4,363c-0.4,1.8-0.8,3.6-1,4.7
	c-0.3,1.9-0.4,4.3,0.7,6.5c3.3,5.9,16.6,25.7,19.9,30.5c3.3,4.8,7.6,8.7,12.7,12.1c4.1,2.9,8,5,13.5,7c4.8,1.8,9.4,2.6,12.7,2.6
	c6.6,0.1,8.5,0.1,8.7-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M57.5,365.3c-0.3,1.1-0.4,2.1-0.5,2.8
	c-0.3,1.9-0.4,4.3,0.7,6.5c3.3,5.9,16.9,25.7,20.2,30.5c3.3,4.8,7.7,8.4,12.8,11.8c4.1,2.9,7.7,4.5,13.2,6.6
	c4.8,1.8,8.9,1.8,12.2,1.9c6.1,0,6.2-0.1,6.5-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M58.6,367.5c0,0.3-0.1,0.7-0.1,1
	c-0.3,1.9-0.4,4.3,0.7,6.5c2.5,4.7,9.2,14.3,14.4,20.6c5.9,7.4,10.3,11.7,17.5,17.5c7.2,5.8,12.8,8.5,15.8,9.5
	c3,1,11.1,1.8,14.2,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M67.7,390.1c6.6,9.1,14.2,17.6,21.2,23.4
	c7.2,5.8,13.8,8.4,16.8,9.4s11,1.8,15.8,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M59.7,369.3c0,1-0.1,1.9-0.1,2.8s0,1.6,0.7,2.9
	s5.5,9.9,12.7,18.6c7,8.7,13.1,15.5,23.5,22.8c1.2,0.8,2.9,1.8,5.5,2.5c2.6,0.5,11.1,2.5,11.1,2.5 M104.1,419.4l9.1,3.3
	c0,0,0.6,0.1,1.1,0.1c0.4,0,2.9,0.3,4.1,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M60.5,370.8c0,0.4-0.1,1-0.1,1.4
	c0,0.8,0,1.6,0.7,2.9c0.7,1.2,5.5,9.9,12.7,18.6c7,8.5,11.6,14.2,21.9,21.5c1.2,0.8,2.9,1.8,5.5,2.5c1.1,0.3,3.2,0.7,5.4,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M61.6,372.8c0,0.8,0,1.6,0.7,2.8
	c0.7,1.2,5.4,8.8,12,17.5c6.9,8.8,11.6,14.2,21.9,21.5c1.2,0.8,2.9,1.8,5.5,2.5c0.3,0,0.7,0.1,1.1,0.3 M41.4,355
	c1.5,8.3,4.8,16,7.7,21.2c2.9,5.4,10.2,18.4,21.2,31.8c3.2,3.7,8.9,10.6,13.1,13.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M71.3,404.4l10.5,10.5c0.6,0.5,0.6,0.8-0.1,0.5
	s-1.5-1-2.2-1.4c-0.6-0.4-1.7-1.2-2.8-2.2s-7.2-7.8-9.5-10.9c-0.6-0.5-0.6-1,0.3-0.1C68.2,401.4,70.4,403.7,71.3,404.4L71.3,404.4z
	 M45.1,360.6c1,3.7,6.5,16,13.5,25.7s15.8,19.8,21,24.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M49.7,315.5h-4.1c-0.4,0-1,0-1.4,0.1c-0.3,0.1-0.4,0.5-0.4,0.7
	c0,0.3,0.8,11.1,1.2,16s1.2,11.7,1.4,12.5c0,0.8,0.4,1.5,0.5,1.8s3,4.7,3,4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M44,315.8c-0.3,0.1-0.5,0.8-0.5,1.1
	c0,0.3,0.8,11.1,1.2,16c0.4,4.8,1.2,11.7,1.4,12.5c0,0.8,0.4,1.5,0.5,1.8c0.1,0.3,2.3,4.5,2.3,4.5s0.3,0.4,0.7,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M40.4,316.6c0,0.3,0.8,10.5,1.2,15.3c0.4,4.8,1.2,11.7,1.4,12.5
	c0,0.8,0.4,1.6,0.5,2.1c0.1,0.3,4.3,10.6,4.3,10.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M40,316.2c0,0.3,0.8,11.3,1.2,16.1
	s1.2,11.6,1.4,12.2c0,0.8,0.4,2.3,0.5,2.6c0.1,0.3,3,9.5,3,9.5s0.3,0.7,1.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M37.7,294.3c0,13.1,1.5,32.6,1.1,26.4c0-0.3,0.4,6.1,0.8,10.9
	c0.4,4.8,1.2,11.7,1.4,12.5c0,0.8,0.4,1.6,0.5,2.1c0.1,0.3,4.3,10.6,4.3,10.6s0.3,0.4,0.8,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M46.5,357.2c-1.9,0-2.3-1.4-2.3-1.4
	s-2.8-8.8-2.9-9.1c-0.1-0.3-0.5-1.8-0.5-2.6c0-0.8-1-7.6-1.4-12.2c-0.4-4.8-0.8-11.4-1-11.7c-0.5-5.4-1-20.9-1-26"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M36.7,294.3c0.1,12.9,1.5,31.2,1.1,25.2c0-0.3,0.4,6.1,0.8,10.9
	s1.2,11.7,1.4,12.5c0,0.8,0.4,1.7,0.5,2.1c0,0,0.3,0.8,0.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M44.2,355.8c-1.1-0.4-1.2-1.1-1.2-1.1
	s-2.5-8.8-2.6-9.1s-0.5-1.8-0.5-2.6c0-0.8-1-7.6-1.4-12.2c-0.4-4.8-0.8-11.4-1-11.7c-0.5-5-1-18.8-1-24.8 M44,355.8
	c0.1,0.4,0.7,1.8,3.2,2.3 M309.9,158.4c-11.1,0.3-24.9,0.6-37.1,0.8c-69.9,1.9-123.5,5-166.6,12.5 M267,176.7
	c-3.2-2.2-13.3-8.5-27.4-8.4c-14,0.1-57.5,2.3-90.5,5.8c-12.5,1.4-25.2,2.8-35.5,4.5c-16.9,3-27.8,6.1-27.8,6.1s-0.8,0.3-1.2,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M123.7,163.1c0.3,0.8-1.2,1.4-3.7,2.1
	c-7.8,2.2-16,5.2-20.9,8.5c-12.9,8.7-22.8,19.3-31.8,33s-14.2,25.9-15.5,29.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M54.6,229.4c-0.7-2.5-1.5-6.1-1.8-7.8
	c-0.3-1.9-0.4-4.4,0.7-6.5s17.1-25.6,20.5-30.4c3.3-4.8,7.7-8.8,12.2-12.2c4.4-3.4,9.4-5.6,15.1-8c6.1-2.5,10.7-2.6,14-2.8
	c6.6-0.1,8,0.1,8.4,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M102.2,164.9c0,0,0.1-0.8-0.3-0.8
	s-1.2-0.4-1.8-0.4s-0.6-0.3-0.1-0.4c0.4-0.3,6.1-2.8,12-5.4c5.9-2.6,13.3-6.5,15.3-6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315.3,149.8c-17.3,0.4-36,1.1-46.6,1.2c-10.2,0.1-15.4-0.4-22.3-1.2
	c-6.7-0.8-13.5-1.9-36.7-1.8c-23.2,0.1-62,1.6-77,2.6c-3.9,0.3-7.4,0.8-10.2,1.2c-4.3,0.6-23.1,6.5-28.1,8.5s-13.9,8.3-17.9,12.2
	s-19.4,21.6-31.2,43.6c-5.5,10.5-5.4,13.9-6.5,21.7c-2.5,17.7-3.2,46.6-3.2,56.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M47.3,227.5c-0.3,0-0.5,0-1,0.1
	c-1,0.3-3.4,1.4-5,6.3c-1.5,4.8-1.5,7.7-1.5,10.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M50.6,294.3c0-27,0.1-26.8,1.4-49.1
	c0.3-3.9,0-5.6-0.3-7.6c-0.1-0.6-0.4-1.9-0.8-3.4c-0.3-1-2.2-4.8-2.8-5.8c-0.4-0.6-0.5-0.8-1-1 M46.5,230c-0.3-0.1-0.5-0.1-0.8,0
	c-1,0.3-2.9,1-4.3,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M49.5,294.3c0-24.9,0-25.3,1.2-47
	c0.3-3.9,0.1-5.6-0.1-7.4c-0.1-1-0.8-2.6-1.4-4.1c-0.4-1.4-1.7-4.3-2.1-5c-0.3-0.4-0.4-0.7-0.8-0.8 M56.4,225.7
	c-0.4-1.8-0.8-3.6-1-4.7c-0.3-1.9-0.4-4.3,0.7-6.5c3.3-5.9,16.6-25.7,19.9-30.5c3.3-4.8,7.6-8.7,12.7-12.1c4.1-2.9,8-5,13.5-7
	c4.8-1.8,9.4-2.6,12.7-2.6c6.6-0.1,8.5-0.1,8.7,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M57.5,223.4c-0.3-1.1-0.4-2.1-0.5-2.8
	c-0.3-1.9-0.4-4.3,0.7-6.5c3.3-5.9,16.9-25.7,20.2-30.5c3.3-4.8,7.7-8.4,12.8-11.8c4.1-2.9,7.7-4.5,13.2-6.6
	c4.8-1.8,8.9-1.8,12.2-1.9c6.1-0.1,6.2,0.1,6.5,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M58.6,221.2c0-0.4-0.1-0.7-0.1-1
	c-0.3-1.9-0.4-4.3,0.7-6.5c2.5-4.7,9.2-14.3,14.4-20.6c5.9-7.4,10.3-11.7,17.5-17.5c7.2-5.8,12.8-8.5,15.8-9.5
	c3-1,11.1-1.8,14.2-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M67.7,198.6c6.6-9.1,14.2-17.6,21.2-23.2
	c7.2-5.8,13.8-8.4,16.8-9.4s11-1.8,15.8-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M59.7,219.4c0-1-0.1-1.9-0.1-2.8
	c0-0.8,0-1.7,0.7-2.9s5.5-9.9,12.7-18.6c7-8.5,13.1-15.5,23.5-22.8c1.2-0.8,2.9-1.8,5.5-2.5c2.6-0.6,11.1-2.5,11.1-2.5 M104.1,169.3
	l9.1-3.3c0,0,0.6-0.1,1.1-0.1c0.4,0,2.9-0.3,4.1-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M60.5,217.7c0-0.4-0.1-1-0.1-1.4
	c0-0.8,0-1.6,0.7-2.9c0.7-1.2,5.5-9.9,12.7-18.6c7-8.5,11.6-14.2,21.9-21.5c1.2-0.8,2.9-1.8,5.5-2.5c1.1-0.3,3.2-0.7,5.4-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M61.6,215.9c0-0.8,0-1.6,0.7-2.8
	c0.7-1.2,5.4-8.8,12-17.5c6.9-8.8,11.6-14.2,21.9-21.5c1.2-0.8,2.9-1.8,5.5-2.5c0.3,0,0.7-0.1,1.1-0.3 M41.4,233.7
	c1.5-8.3,4.8-16,7.7-21.2c2.9-5.4,10.2-18.4,21.2-31.8c3.2-3.7,8.9-10.6,13.1-13.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M71.3,184.3l10.5-10.5c0,0,0.6-0.8-0.1-0.6
	c-0.7,0.3-1.5,1-2.2,1.4c-0.6,0.4-1.7,1.2-2.8,2.2s-7.2,7.8-9.5,10.9c-0.6,0.6-0.6,1,0.3,0.1C68.2,187.3,70.4,185,71.3,184.3
	L71.3,184.3z M45.1,228c1-3.7,6.5-16,13.5-25.7s15.8-19.8,21-24.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M49.7,273.2h-4.1c-0.4,0-1,0-1.4-0.1c-0.3-0.1-0.4-0.5-0.4-0.7
	s0.8-11.1,1.2-16c0.4-4.8,1.2-11.7,1.4-12.5c0-0.8,0.4-1.5,0.5-1.8c0.1-0.3,3-4.7,3-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M44,272.9c-0.3-0.1-0.5-0.8-0.5-1.1
	s0.8-11.1,1.2-16c0.4-4.8,1.2-11.7,1.4-12.5c0-0.8,0.4-1.5,0.5-1.8l2.3-4.7c0,0,0.3-0.4,0.7-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M40.4,271.9c0-0.3,0.8-10.5,1.2-15.3c0.4-4.8,1.2-11.7,1.4-12.5
	c0-0.8,0.4-1.6,0.5-2.1c0.1-0.3,4.3-10.6,4.3-10.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M40,272.3c0-0.3,0.8-11.3,1.2-16.1
	c0.4-4.8,1.2-11.6,1.4-12.2c0-0.8,0.4-2.3,0.5-2.6c0.1-0.3,3-9.5,3-9.5s0.3-0.7,1.2-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M37.7,294.3c0-13.1,1.5-32.6,1.1-26.4c0,0.3,0.4-6.1,0.8-10.9
	c0.4-4.8,1.2-11.7,1.4-12.5c0-0.8,0.4-1.6,0.5-2.1c0.1-0.3,4.3-10.6,4.3-10.6s0.3-0.4,0.8-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M46.5,231.5c-1.9,0-2.3,1.4-2.3,1.4
	s-2.8,8.8-2.9,9.1c-0.1,0.3-0.5,1.8-0.5,2.6s-1,7.6-1.4,12.2c-0.4,4.8-0.8,11.4-1,11.7c-0.5,5.4-1,20.9-1,26"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M36.7,294.3c0.1-12.9,1.5-31.2,1.1-25.2c0,0.3,0.4-6.1,0.8-10.9
	c0.4-4.8,1.2-11.7,1.4-12.5c0-0.8,0.4-1.7,0.5-2.1c0,0,0.3-0.8,0.7-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M44.2,232.8c-1.1,0.4-1.2,1.1-1.2,1.1
	s-2.5,8.8-2.6,9.1c-0.1,0.3-0.5,1.8-0.5,2.6c0,0.8-1,7.6-1.4,12.2c-0.4,4.8-0.8,11.4-1,11.7c-0.5,5-1,18.8-1,24.8 M44,232.8
	c0.1-0.4,0.7-1.8,3.2-2.3 M305,421.4c-16.2-0.3-26.4-0.4-26.7-0.4c-0.7,0-0.8-0.5-0.8-0.5 M278.7,419.4c0,0,0.1-0.5,1.1-0.8
	c32.3-9.6,72.5-19.8,90.1-23.5c6.1-1.2,14.6-2.6,28.3-4c7.4-0.7,16-1.6,25.2-2.2c15.1-0.8,32.2-1.2,48.7-1.2
	c28.9-0.3,60,0.8,88.3,3.6c15.8,1.5,30.3,3.7,44.7,6.2c17.6,3,31.4,5.2,41.4,7.2c13.1,2.5,19.7,4,21,4.3c1.4,0.3,2.9,1.1,2.8,1.9
	c-0.1,0.7-1.1,1.6-4,1.9c-3,0.3-30.4,2.5-66.9,4.5c-45.7,2.5-105,4.8-145.8,5.1c-14.4,0-31.2,0.3-46.9,0.3
	c-31.5-0.1-63.1-0.4-87.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M318.9,419.4c24.1,0.3,56,0.7,87.9,0.7
	c15.5,0,32.5-0.1,46.9-0.3c40.9-0.1,100.3-2.6,145.8-5.1c32.6-1.8,57.6-3.9,64.8-4.4c1.5-0.1,3.2-1,1.4-1.4
	c-5.2-1.1-19-3.7-43.2-7.8c-5.4-1-11.4-1.8-17.7-2.8c-14.4-2.5-28.3-4.7-44.2-6.2c-28.3-2.8-60.5-4-89.4-3.7
	c-16.5,0.1-33.2,0.5-48,1.5c-8.9,0.5-17.3,1.2-24.6,2.1c-13.6,1.4-22.1,2.9-28.2,4.1c-6.6,1.4-16.1,3.6-27,6.3
	c-17.5,4.4-42,11.3-61.6,16.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M597,414.2c-45.5,2.5-102.5,4.8-143.3,5
	c-14.4,0-31.2,0.3-46.9,0.3c-32-0.1-64.2-0.4-88.3-0.7 M284.9,418.7c19.7-5.2,41-10.9,58.5-15.3c10.9-2.8,20.4-5.1,27-6.5
	c6.1-1.2,15.1-2.9,28.8-4.1c19.5-1.9,45.5-3.2,72.1-3.4c28.9-0.3,61.1,1,89.4,3.7c15.8,1.5,29.7,3.7,44.2,6.2
	c34.9,5.9,54.6,9.4,60.9,10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M278.8,419.2c0,0,0,0.1,0.3,0.1h1.9
	c0.5-0.1,1.2-0.4,1.5-0.4l5.8,0.1c0.3,0,0.5-0.1,0.5-0.1c12.7-3.6,25.9-6.9,38.5-10.2c17.7-4.4,33.8-8.4,43.2-10.3
	c6.1-1.2,14.3-2.9,27.9-4.3c6.6-0.7,14.2-1.4,22.1-1.8c15.8-1,33.6-1.2,51.2-1.4c28.9-0.3,59.8,0.8,88.2,3.6
	c15.8,1.5,30.4,3.7,44.8,6.2c23,3.9,40.9,6.6,51.2,8.5c4,0.7,6.9,1.1,6.9,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M305.7,423.1c0.8-1.9,2.9-4.3,6.5-5.1
	c3.6-0.8,5.4-0.8,6.1,0.1c0.7,1.1,1.4,3.2,2.1,4.3c0.7,1.2,1.5,1.5,2.3,1.7c0.8,0,1.6,0.1,2.1,0.1c0.4,0,0.5,0.1,0.7,0.3
	c0,0.1,0.5,1.4,3.3,9.6c2.8,8.3,7.2,23.4,7.4,24.1c0.3,0.5,0.3,1-0.3,1.1c-0.4,0-2.8,0.3-4.5,0c-3.6-0.4-7-2.1-10-4.8
	c-5.4-4.8-9.4-12.2-11.3-17.6c-3-8.3-6.1-19-6.1-19c-0.4-0.7-0.7-0.7-0.7-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M321.2,429.2c0,0,1.4,6.1,3.9,12.9
	c2.3,6.9,5.2,15.1,5.9,17.1 M324,427.6c0-0.3-0.3-1.8-0.5-2.3c-0.1-0.5-0.4-0.5-1-0.7c-0.7-0.1-4.1-0.3-5.1-0.3
	c-0.3,0-0.4,0.1-0.3,0.5c0.1,0.4,1.6,1.8,2.2,2.3c0.4,0.4,1,0.5,1.9,0.8c0.8,0.3,1.4,0.3,2.1,0.3C323.8,428.3,324.1,428.2,324,427.6
	z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M323.1,429.3c-4.8-1-12.2-4.1-14-5.1
	c-1.9-1-1.6-1.4-1.2-1.9c0.3-0.4,1-0.8,4.3-0.5c3.3,0.3,8.3,1.2,8.3,1.2 M334.6,459c0,0-1.9-5.1-4.7-14.3c-2.8-9.1-4.7-15-4.7-15
	h-0.7c0,0,1.9,5.8,4.7,15c2.8,9.1,4.7,14.3,4.7,14.3 M335.8,459.1c0,0-1.9-5.1-4.7-14.3c-2.8-9.1-4.7-15-4.7-15
	c-0.5-2.1-1.2-4.3-1.4-5.2c0-0.3-0.1-0.5-0.1-0.5 M598,414.2l0.3,0.7c0,0,0.3,0.7,0.5,1.2c0.3,0.6,0.7,1,0.7,1 M468.7,389.4
	c-0.1,0.4-0.6,1.2-0.7,1.6c-0.1,0.4-1.2,4.1-4.7,10.9c-3.4,6.6-10.6,17.3-10.6,17.3 M459.5,391.2c-1.5,2.9-8.1,14.6-21,27.9
	 M476.4,391.3c-0.4,2.3-3.7,15.7-9.2,27.6 M327.1,409.1c-0.3,0.4-0.8,1-1.5,1.8c-0.7,0.8-7.2,8-7.2,8 M317.5,417
	c0-0.4-0.3-1.1-2.6-1.1s-7.3,0.3-8.9,0.3c-1.5,0.1-2.9,0.3-2.2,1.6 M288.7,419.1h1.5l14.3,0.4 M596.6,414.2c-4.7-2.6-9.9-6.2-14-9.6
	c-5.6-4.5-11.1-10.3-11.1-10.3 M591.8,414.3c-1.7-1.1-7.4-4.3-12.5-8.3c-6.2-4.8-11.7-10.6-11.7-10.6 M576.5,415l3-8.8 M600.7,412.8
	c-7.4-4.5-20.6-15.8-20.6-15.8 M654.9,409.3c-24.9,1.5-54.1,3.6-54.1,3.6c0,0.4-0.3,0.5-0.7,0.7c-0.3,0.1-3.7,0.3-3.7,0.3
	 M586.8,397.8c0,0-0.1,0.5,0.7,1.4c5.2,4.7,14,10.6,14.9,11c0.8,0.4,1.7,0.5,2.6,0.5c12.4-0.5,36.6-2.3,38.5-2.5
	c1.9-0.1,1.8-0.5,1.8-0.5 M305,166.8c-16.2,0.3-26.4,0.4-26.7,0.4c-0.7,0-0.8,0.6-0.8,0.6 M278.7,168.9c0,0,0.1,0.6,1.1,0.8
	c32.3,9.6,72.5,19.8,90.1,23.5c6.1,1.2,14.6,2.6,28.2,4c7.4,0.7,16,1.6,25.2,2.2c15.1,0.8,32.2,1.2,48.7,1.2
	c28.9,0.3,60-0.8,88.3-3.6c15.8-1.5,30.3-3.7,44.7-6.2c17.6-3,31.4-5.2,41.4-7.2c13.1-2.5,19.7-4,21-4.3c1.4-0.3,2.9-1.1,2.8-1.9
	c-0.1-0.7-1.1-1.6-4-1.9c-3-0.3-30.4-2.5-66.9-4.5c-45.5-2.5-105-4.8-145.8-5.1c-14.4,0-31.2-0.3-46.9-0.3
	c-31.5,0.1-56.3,0.4-80.2,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M320.2,168.9c24.1-0.3,54.6-0.7,86.5-0.7
	c15.5,0,32.5,0.1,46.9,0.3c40.9,0.1,100.3,2.6,145.8,5.1c32.6,1.8,57.6,3.9,64.8,4.4c1.5,0.1,3.2,1,1.4,1.4
	c-5.2,1.1-19,3.7-43.2,7.8c-5.4,1-11.4,1.8-17.7,2.8c-14.4,2.5-28.3,4.7-44.2,6.2c-28.3,2.8-60.5,4-89.4,3.7
	c-16.5-0.1-33.2-0.6-48-1.5c-8.9-0.6-17.3-1.2-24.6-2.1c-13.6-1.4-22.1-2.9-28.2-4.1c-6.6-1.4-16.1-3.6-27-6.3
	c-17.5-4.4-42-11.3-61.6-16.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M597,174.1c-45.5-2.5-102.5-4.8-143.3-5
	c-14.4,0-31.2-0.3-46.9-0.3c-32,0-63,0.4-87.1,0.7 M284.9,169.6c19.7,5.2,41,10.9,58.5,15.3c10.9,2.8,20.4,5.1,27,6.5
	c6.1,1.2,15.1,2.9,28.8,4.1c19.5,1.9,45.5,3.2,72.1,3.4c28.9,0.3,61.1-1,89.4-3.7c15.8-1.5,29.7-3.7,44.2-6.2
	c34.9-5.9,54.6-9.4,60.9-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M278.8,169c0,0,0-0.1,0.3-0.1h1.9
	c0.5,0.1,1.2,0.4,1.5,0.4l5.8-0.1c0.3,0,0.5,0.1,0.5,0.1c12.7,3.6,25.9,6.9,38.5,10c17.7,4.4,33.8,8.4,43.2,10.3
	c6.1,1.2,14.3,2.9,27.9,4.3c6.6,0.7,14.2,1.4,22.1,1.8c15.8,1,33.6,1.2,51.2,1.4c28.9,0.3,59.8-0.8,88.2-3.6
	c15.8-1.5,30.4-3.7,44.8-6.1c23-3.9,40.9-6.6,51.2-8.5c4-0.7,6.9-1.1,6.9-1.1 M598,174l0.3-0.7c0,0,0.3-0.7,0.5-1.2s0.7-1,0.7-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M468.7,198.9c-0.1-0.4-0.6-1.2-0.7-1.6
	s-1.2-4.1-4.7-10.9c-3.4-6.6-10.6-17.3-10.6-17.3 M459.5,197.1c-1.5-2.9-8.1-14.6-21-27.9 M476.4,196.9c-0.4-2.3-3.7-15.7-9.2-27.6
	 M327.1,179.2c-0.3-0.4-0.8-1-1.5-1.8c-0.7-0.8-6.3-7.2-6.3-7.2 M288.7,169.2h1.5l14.3-0.4 M596.6,174.1c-4.7,2.6-9.9,6.2-14,9.6
	c-5.6,4.5-11.1,10.3-11.1,10.3 M591.8,174c-1.7,1.1-7.4,4.3-12.5,8.3c-6.2,4.8-11.7,10.6-11.7,10.6 M576.5,173.3l3,8.8 M600.7,175.3
	c-7.4,4.5-20.6,15.8-20.6,15.8 M654.9,178.9c-24.9-1.5-54.1-3.6-54.1-3.6c0-0.4-0.3-0.6-0.7-0.7c-0.3-0.1-3.7-0.3-3.7-0.3
	 M586.8,190.5c0,0-0.1-0.6,0.7-1.4c5.2-4.7,14-10.6,14.9-11c0.8-0.4,1.7-0.6,2.6-0.6c12.4,0.6,36.6,2.3,38.5,2.5
	c1.9,0.1,1.8,0.6,1.8,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M307.3,163.5c0.4,2.1,1.9,4.8,5.2,6.3
	c3.3,1.6,5.1,2.1,5.9,1.1c0.8-0.8,1.9-2.8,2.9-3.7c1-1.1,1.8-1.2,2.6-1.1c0.8,0.1,1.6,0.3,2.1,0.3c0.4,0.1,0.7,0,0.7-0.1
	c0.1-0.1,0.8-1.2,5.4-8.7c4.5-7.4,12.1-21.3,12.5-21.9c0.4-0.6,0.5-1,0-1.1c-0.4-0.1-2.6-0.8-4.4-1c-3.7-0.3-7.3,0.6-10.7,2.5
	c-6.2,3.6-11.7,10-14.9,14.7c-5.2,8-5.2,8-11.7,20.1c-0.5,0.6-0.8,0.6-0.8,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M324,160.9c0,0,2.8-5.6,6.6-11.8
	c3.9-6.2,8.4-13.6,9.5-15.3 M325.3,162.1c0.8,0.3,0.8,0.4,0.7,0.7c-0.1,0.3-0.7,1.6-1,2.2s-0.5,0.6-1.2,0.6s-4.1-0.7-5-1
	c-0.3,0-0.4-0.3-0.1-0.6c0.3-0.3,2.2-1.5,2.8-1.9c0.3-0.1,1-0.3,1.4-0.3C323.4,161.9,324.4,161.9,325.3,162.1L325.3,162.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M325.8,161.2c-4.8-0.1-12.8,1.4-14.9,1.9
	s-1.9,1-1.6,1.6c0.1,0.6,0.7,1.1,4.1,1.5c3.3,0.4,8.4,0.7,8.4,0.7 M343.5,134.6c0,0-2.9,4.5-7.7,12.9c-4.7,8.3-7.8,13.6-7.8,13.6
	l-0.7-0.1c0,0,3.2-5.2,7.8-13.6c4.7-8.3,7.7-12.9,7.7-12.9 M344.7,134.8c0,0-2.9,4.5-7.7,12.9c-4.7,8.3-7.8,13.6-7.8,13.6
	c-1.1,1.9-2.2,3.9-2.5,4.8c-0.1,0.3-0.3,0.4-0.1,0.6 M317.5,171.5c0,0.4-0.3,1.1-2.6,1.1s-7.3-0.3-8.9-0.3c-1.5-0.1-2.9-0.3-2.2-1.6
	 M561.8,195.6h-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M330.3,439.1c25.6,0.4,54.6,1,82.3,1
	 M330.2,439.1c2.6,8.3,5.6,18.6,5.8,19.1c0.3,0.6,0.3,1-0.3,1.1c-0.4,0-2.8,0.3-4.5,0c-3.6-0.4-7-2.1-10-4.8
	c-4.5-4.1-8.1-10.2-10.2-15.1 M429.3,439.7c-3,0.5-7.2,1-12.9,0.7c-1.4-0.1-2.6-0.3-3.7-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M428.6,148.5c4.1,0,8.3,0,12.2,0.1
	c61.4,0.7,102.5,1.2,115.7,1.4c12.7,0.1,19.4-1.8,38.7-1.5c19.3,0.3,61.8,0.8,80.1,1.4c5.2,0.1,9.9,0.1,13.1,0.3
	c7.8,0.6,11.1,1.8,16,2.8c12.8,2.6,25.7,5.4,46.9,10.5c31.9,7.7,36.2,15,45.4,47c10,34.8,9.8,83.6,9.8,83.6s0.4,48.6-9.8,83.6
	c-9.5,32.7-12.4,39.1-44.8,47.3c-21.2,5.4-34.8,8.1-47.5,10.7c-4.8,1-8.1,1.8-16,2.3c-3.2,0.3-7.8,0.1-13.1,0.3
	c-18.3,0.4-60.8,1.1-80.1,1.4c-19.3,0.3-26-1.6-38.7-1.5c-13.2,0.1-54.2,0.8-115.7,1.5c-4,0-8.1,0.1-12.2,0.1 M337,149.5
	c25.6-0.4,47.9-1,75.7-1 M336.9,149.5c3.7-6.6,7.4-13.3,7.7-13.6c0.4-0.6,0.5-1,0-1.1c-0.4-0.1-2.6-0.8-4.4-1
	c-3.7-0.3-7.3,0.6-10.7,2.5c-5.8,3.3-10.9,9.1-14.2,13.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M428.6,148.7c-2.9-0.6-6.9-0.8-12.2-0.6
	c-1.1,0-2.1,0.1-2.9,0.3 M310.3,438.6c-17.3-0.4-31.2-1-41.7-1.1c-10.2-0.1-15.4,0.4-22.3,1.2c-6.7,0.8-13.5,1.9-36.7,1.8
	c-23.2-0.1-62-1.6-77-2.6c-3.9-0.3-7.4-0.8-10.2-1.1c-4.3-0.5-23.1-6.5-28.1-8.5s-13.9-8.3-17.9-12.2c-4-4-19.4-21.6-31.2-43.6
	c-5.6-10.5-5.4-13.9-6.5-21.7c-2.5-17.7-3.2-46.8-3.2-56.3 M315.3,149.8c-17.3,0.4-36,1.1-46.6,1.2c-10.2,0.1-15.4-0.4-22.3-1.2
	c-6.7-0.8-13.5-1.9-36.7-1.8c-23.2,0.1-62,1.6-77,2.6c-3.9,0.3-7.4,0.8-10.2,1.2c-4.3,0.6-23.1,6.5-28.1,8.5s-13.9,8.3-17.9,12.2
	s-19.4,21.6-31.2,43.6c-5.5,10.5-5.4,13.9-6.5,21.7c-2.5,17.7-3.2,46.6-3.2,56.3"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M369.8,387.2c0,0-11-37.7-11-88.7
		c0-51,5.9-77.7,11-97.1c0,0-90.8-20.8-99-23.4c0,0-32.6,29-32.6,120.5c0,0-0.6,75.8,32.7,112.1
		C270.9,410.6,366.3,387.6,369.8,387.2z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M369.8,387.2c0,0-11-37.6-11-88.7c0,0-1.9-46.1,11-97.1
		c0,0,32.6,5.4,81.6,6.9c0,0,39.3,0.4,81.3-0.7c0,0,39.5-1.4,65.5-4.1c0,0,12.8,34.7,12.8,90.9c0,56.1-12.8,90.9-12.8,90.9
		s-35.4-3-55.2-3.7c0,0-32.3-2.1-91.2-1.1C451.9,380.3,395.9,382,369.8,387.2z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M694.1,387.3l-80.3-8.5c0,0-6.7-0.6-5.4-7.3
		c1.2-6.7,7.3-40.9,7.3-45.9c0,0-0.1-2.9,0.3-3.3c0.4-0.4,1.5-0.8,1.5-0.8l1.9-0.4c0,0,0.6,0,0.7-1.4c0-1.4,1.1-25.4,0-51.6
		c0,0-0.1-0.7-0.7-0.7c-0.4,0-2.3-0.5-2.3-0.5s-1.2-0.1-1.2-1.8s0-2.5-0.1-4.3c-0.1-1.8-3.9-26.6-7-43.3c0,0-1.9-6.5,4.7-7.7
		l81.3-8.7c0,0,5-1,6.7,3.4c0,0,12.7,28.8,12.7,89.7c0,0,1,53.1-12.4,89C701.6,383.2,700.1,388.3,694.1,387.3z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M769.5,391.6c0,0-55.6,5.9-63.4,4.8
		c0,0-1.7-0.4-1.2-1.7l0.3-0.7c0,0,15.1-27.5,15.1-99.9c0-72.4-15.3-100-15.3-100s-0.7-2.1,1.2-2.1s19.3,0.1,63.3,4.8
		c0,0,15.1,28.1,15.1,97.4C784.8,363.5,769.5,391.6,769.5,391.6z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M267,412c0,0-35.6-20.5-35.6-117.6S267,176.9,267,176.9
		s-12.9-8.8-27.4-8.4c-14.4,0.3-108.9,3.3-153.8,16.4c0,0-14.9,8.8-34.3,51.7c0,0,0.7,3.9,0.4,8.7c0,0-3.6,40.7,0,98.2
		c0,0,0.4,4.7-0.4,8.7c0,0,12.5,32.5,34.3,51.7c0,0,31.9,11,146.1,16.2c0,0,9.2,0.8,14.6-0.3C251.9,418.7,256.7,418.4,267,412z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Sedan4DrTop);
