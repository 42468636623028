import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup2DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M602.9,211.2l-5.2-10.2c-2.3-2.3-6.7-3.7-13.6-4.3c-6.7-0.4-12.2,0.1-16.8,1.6c-2.9,0.8-4.3,3.4-4,7.8
	l1.5,12.5l38.8-5.2C603.8,212.9,603.5,212.1,602.9,211.2L602.9,211.2z"
          />
          <path
            fill="#FFFFFF"
            d="M603.4,215.9c0.3-0.7,0.4-1.5,0.4-2.2L565,219l-0.7,0.1H558l-1,4C573.6,221.7,589,219.2,603.4,215.9
	L603.4,215.9z"
          />
          <path
            fill="#FFFFFF"
            d="M602.3,217.9c0.5-0.6,0.8-1.2,1.1-1.8c-14.3,3.3-29.7,5.6-46.5,7.2l2.9,5.6l3-0.4l12.5-1.8
	c5.9-1,11.6-2.1,16.9-3.4C596.9,222,600.2,220.2,602.3,217.9L602.3,217.9z"
          />
          <path
            fill="#FFFFFF"
            d="M564.6,236.4l-1.8-8.1l-3,0.4c0.7,6.7,3,12.4,6.9,16.9l-0.3-1.1L564.6,236.4L564.6,236.4z"
          />
          <path
            fill="#FFFFFF"
            d="M566.3,244.5c1,0.1,1.8,0.3,2.5,0.6l-1.5-4.1l-2.8-4.5L566.3,244.5L566.3,244.5z"
          />
          <path
            fill="#FFFFFF"
            d="M568.8,245.2c-0.7-0.3-1.5-0.6-2.5-0.6l0.3,1.1c1.5,1.6,2.9,4.5,4.4,8.4c1.2,0.3,1.7-0.7,1.2-2.9l-0.8-3.2
	C571,246.7,570.1,245.8,568.8,245.2L568.8,245.2z"
          />
          <path
            fill="#FFFFFF"
            d="M564.1,254c1.9,0.3,3.3,2.9,4.3,7.7c0.7,3.4,0.8,6.7,0.7,10.2c-0.1,1.5-0.1,3-0.3,4.5l-0.4,8.5
	c-0.3,17.3,1.1,33.4,4.1,48.1c1.9-25.5,2.5-48.4,1.7-68.8l-1.1-4c-0.7-2.3-1.4-4.4-2.1-6.2c-1.4-4-2.9-6.7-4.4-8.4
	c-3.9-4.5-6.2-10.2-6.9-16.9l-2.9-5.6l-6.5-0.4c-4.7,6.6-7.2,15.7-7.6,27.2l3-0.3l6.6-0.1c1.2,0,2.3,0.3,3.4,0.6
	c0.7,0.3,1.4,0.4,2.1,0.8l3.7,1.8C562.6,252.8,563.3,253.3,564.1,254L564.1,254z"
          />
          <path
            fill="#FFFFFF"
            d="M568.3,261.7c-1-4.8-2.3-7.4-4.3-7.7c0.6,0.6,1.1,1.2,1.4,2.1c1.2,2.6,1.5,6.3,1,11.3
	c-0.3,2.1-1,3.9-1.8,5.4c-1,1.8-2.5,3.2-4.3,4.3c-3.4,1.4-9.9,2.3-19.5,2.5l-0.3-0.4l-1.8,2.2l-17.2,1.1c-1.5,0.1-2.8,1.2-3.4,3.2
	l-1.5,2.8l35.9-1.2c5.2,0,8.4,1.8,9.6,5.2c1.8,9.1,2.8,19.3,3,30.4c0.3,5.4-1.7,9.2-5.6,11.4c-8.4,3.6-20.5,6.7-36.2,9.6l3.3,10.3
	v0.1l1.8-0.3l6.2-1c8-1.4,16.1-3.4,24.5-5.8l8.5-2.6l3.7-1.2c1.1-2.3,2.1-4.5,2.9-6.6l-1.8-3.6c-3-14.9-4.5-31-4.1-48.1l0.4-8.5
	c0-1.5,0.1-3,0.3-4.5C569.2,268.5,569,265,568.3,261.7L568.3,261.7z"
          />
          <path
            fill="#FFFFFF"
            d="M573.1,260.4l1.1,4c0.8,20.4,0.3,43.3-1.7,68.8l1.8,3.6c1.8-4.7,3-9.1,3.7-13.2l1.4-8.7
	c2.1-14.7,3-27.8,2.9-39.2C581.3,268.5,578.3,263.5,573.1,260.4L573.1,260.4z M567.6,353.1c-0.1-0.1-0.3-0.1-0.4-0.1H567l-7.8,0.8
	l-5,0.7c-0.1,0-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.3,0.1,0.4l0.4,0.1l5-0.3l7.8-0.8h0.1c0.1,0,0.3-0.1,0.4-0.3
	c0.1-0.1,0.1-0.3,0.1-0.5C567.7,353.4,567.7,353.2,567.6,353.1L567.6,353.1z"
          />
          <path
            fill="#656565"
            d="M567,352.9c0.4-2.6,0.5-5.5,0.5-8.5l-8.5,2.6v6.7L567,352.9L567,352.9z"
          />
          <path
            fill="#FFFFFF"
            d="M566.5,361.2l-0.3-0.1h-0.1l-6.9,0.7l-4.3,0.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.3,0.1,0.4
	l0.4,0.1l3.9-0.3l7.2-0.7h0.1c0.1,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5C566.8,361.5,566.6,361.3,566.5,361.2L566.5,361.2z"
          />
          <path
            fill="#656565"
            d="M565.9,361.1h0.1c0.3-2.1,0.5-4.4,0.8-6.7l-7.8,0.8v6.5L565.9,361.1L565.9,361.1z M564.4,369.2
	c0.5-2.1,1.1-4.3,1.5-6.7l-7.2,0.7c-0.7,2.6-2.3,4.7-5.2,6.1l-9.5,3.3c-5,1.7-10,2.9-15,4c-3.4,0.7-6.9,1.4-10.3,1.8l-7.8,0.8
	c-22.3,1.6-47.7,2.6-76.6,2.6h-24.8c-29.4,0-55.6-0.8-78.1-2.6l-7.8-0.8c-3.4-0.4-6.9-1.1-10.5-1.8c-5-1.1-10-2.3-15-4l-1.9-0.7
	c-2.5-0.8-5-1.8-7.6-2.8c-2.9-1.4-4.5-3.4-5.2-6.1l-7-0.7c0.4,2.3,1,4.7,1.5,6.7c1,2.6,2.6,4.1,5,4.7c9.8,4.1,19.9,7.4,30.4,9.8
	l1,0.3l8,1.6c0.4-0.4,0.8-0.7,1.4-1c3-1.5,7.8-2.1,14.7-1.4c27.4,1.6,55,2.2,83,1.8c27.9,0.4,55.6-0.3,82.8-1.8
	c6.7-0.5,11.7-0.1,14.7,1.4c0.5,0.3,1,0.5,1.4,1l8-1.6l1-0.3c10.5-2.3,20.6-5.6,30.5-9.8C561.9,373.4,563.6,371.8,564.4,369.2
	L564.4,369.2z"
          />
          <path
            fill="#FFFFFF"
            d="M517,141.8l-5.6-0.7c3.2,3.7,5.9,9.5,8.3,17.1l2.6,0.1l3.9,0.1C522.6,149.9,519.4,144.4,517,141.8L517,141.8
	z"
          />
          <path
            fill="#FFFFFF"
            d="M511.3,141.2l-23.8-1.5l-63.4-2.1h-6.2l-66.9,2.3l-20.2,1.4c-3.2,3.7-5.9,9.5-8.3,17.1
	c26.3-0.8,52.4-1.2,78.4-1.4h40.2c26,0.1,52.1,0.6,78.4,1.4C517.2,150.6,514.5,144.9,511.3,141.2L511.3,141.2z M421.6,132.3
	c0-1.1-0.1-1.7-0.5-1.8c-0.4,0.1-0.7,0.7-0.5,1.8c-0.3,2.5-1,4.3-2.2,5.2h5.6C422.7,136.6,421.9,134.8,421.6,132.3L421.6,132.3z"
          />
          <path
            fill="#056DA5"
            d="M423.8,137.5h-2.6l2.8,0.1 M424.1,137.7l-0.3-0.1l0.1,0.1 M418.2,137.7v-0.1l-0.3,0.1 M418.2,137.5v0.1
	l2.6-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M531.7,153.3L531,152c-3.2-5.5-7.8-8.8-13.9-10c2.5,2.6,5.5,8.1,9.1,16.5c5.8,13.6,12.9,34.9,21.6,64
	l2.8,0.3l6.5,0.4C549.2,194.3,540.8,171.1,531.7,153.3L531.7,153.3z"
          />
          <path
            fill="#FFFFFF"
            d="M526,158.3l-3.9-0.1c6.3,15.3,13.5,36.6,21.3,64l4,0.1C539,193.4,531.8,172,526,158.3z M481.7,237.9
	c5.4,0,9.8,1.2,13.1,3.7l3.9,3.6c3,2.9,5.9,6.3,8.7,10c-0.5-3.6-0.5-6.9-0.1-10c1.6-10.5,9.1-18.3,22.6-23.4c-1.1-0.7-3-1.2-6.1-1.5
	c-13.6,3.4-24.3,9.8-32.1,19.3c7.7-9.4,18.4-15.8,32.1-19.3l-11.3-0.7h-183l-10.5,0.6c13.8,3.4,24.6,9.9,32.3,19.3
	c2.5-1,5.5-1.5,8.9-1.5L481.7,237.9L481.7,237.9z M557.8,219.1l0.1-0.4c-7.3-25.4-16-47.3-26.4-65.3c9.1,17.9,17.5,41.1,25.2,69.7
	L557.8,219.1L557.8,219.1z"
          />
          <path
            fill="#FFFFFF"
            d="M547.6,222.4l-4-0.1l-2.8-0.1l-10.9-0.4c-13.5,5.1-21,12.9-22.6,23.4c-0.6,3.2-0.4,6.5,0.1,10
	c0.7-0.7,1.8-1.2,3.2-1.8l1.1-0.3l31.1-3.4c0.4-11.6,2.9-20.6,7.6-27.2L547.6,222.4L547.6,222.4z M330.8,141.2l-1,0.1
	c-1.6,0.1-3.2,0.3-4.7,0.6c-2.5,2.6-5.5,8.1-9.1,16.5l3.9-0.1l2.6-0.1C324.9,150.6,327.7,144.9,330.8,141.2L330.8,141.2z
	 M238.8,215.9c0.3,0.6,0.7,1.2,1.1,1.8c2.1,2.3,5.4,4.3,10,5.4c5.4,1.2,11,2.3,16.9,3.4l12.5,1.8l3,0.4l2.9-5.6
	C268.5,221.7,253,219.2,238.8,215.9L238.8,215.9z"
          />
          <path
            fill="#FFFFFF"
            d="M238.4,213.9c0,0.7,0.1,1.4,0.4,2.2c14.3,3.3,29.7,5.6,46.5,7.2l-1-4h-6.5l-0.7-0.1L238.4,213.9L238.4,213.9
	z"
          />
          <path
            fill="#FFFFFF"
            d="M239.2,211.2c-0.6,0.8-0.8,1.6-0.8,2.5l38.8,5.2l1.5-12.5c0.3-4.4-1.1-7-4-7.8c-4.5-1.5-10-2.1-16.8-1.7
	c-6.7,0.6-11.3,1.9-13.6,4.3L239.2,211.2L239.2,211.2z M301.3,222.1l-2.8,0.1l-4,0.1l-2.8,0.3c4.7,6.6,7.2,15.7,7.6,27.2l31.1,3.4
	l1.1,0.3c1.4,0.4,2.5,1,3.2,1.8c0.5-3.6,0.5-6.9,0.1-10c-1.6-10.5-9.2-18.3-22.6-23.4L301.3,222.1L301.3,222.1z"
          />
          <path
            fill="#FFFFFF"
            d="M294.5,222.4l4-0.1c8-27.4,15.1-48.7,21.3-64l-3.9,0.1C310.3,172,303.1,193.4,294.5,222.4L294.5,222.4z"
          />
          <path
            fill="#FFFFFF"
            d="M291.8,222.5l2.8-0.3c8.5-29,15.8-50.4,21.6-64c3.6-8.4,6.6-13.9,9.1-16.5c-6.2,1.2-10.7,4.7-13.9,10
	l-0.7,1.4c-9.1,17.9-17.5,41.1-25.2,69.7L291.8,222.5L291.8,222.5z"
          />
          <path
            fill="#FFFFFF"
            d="M284.1,218.7l0.1,0.4l1,4c7.7-28.8,16.1-51.9,25.2-69.7C300.2,171.4,291.4,193.1,284.1,218.7L284.1,218.7z
	 M282.4,228.7l-3-0.4l-1.8,8.1l-1.8,8.1l-0.3,1.1C279.4,241.1,281.6,235.5,282.4,228.7z M274.7,241l-1.5,4.1
	c0.7-0.3,1.5-0.6,2.5-0.6l1.8-8.1L274.7,241L274.7,241z"
          />
          <path
            fill="#FFFFFF"
            d="M275.7,244.5c-1,0.1-1.8,0.3-2.5,0.6c-1.2,0.6-2.2,1.5-2.6,2.9l-0.8,3.2c-0.4,2.2,0,3.2,1.2,2.9
	c1.4-4,2.9-6.7,4.4-8.4L275.7,244.5L275.7,244.5z"
          />
          <path
            fill="#FFFFFF"
            d="M282.4,228.7c-0.7,6.7-3,12.4-6.9,16.9c-1.5,1.7-2.9,4.5-4.4,8.4c-0.7,1.8-1.4,3.9-2.1,6.2l-1.1,4
	c-0.8,20.4-0.3,43.3,1.6,68.8c3-14.9,4.4-31,4.1-48.1l-0.7-12.9c-0.3-3.4,0-6.7,0.7-10.2c1-4.8,2.3-7.4,4.3-7.7l0.1-0.1
	c0.7-0.6,1.4-1.1,2.2-1.4l3.7-1.8l2.1-0.8c1.1-0.3,2.3-0.6,3.4-0.6l6.6,0.1l3,0.3c-0.4-11.6-2.9-20.6-7.6-27.2l-6.5,0.4L282.4,228.7
	L282.4,228.7z M319,220.1c-3.4,0.4-5.6,1-6.9,1.6c13.5,5.1,21,12.9,22.6,23.4c0.4,3.2,0.4,6.5-0.1,10c2.9-3.7,5.8-7.2,8.8-10
	c1.2-1.2,2.6-2.5,3.9-3.6c1.2-1,2.6-1.6,4.1-2.3C343.6,230,332.9,223.5,319,220.1L319,220.1z M320.5,282.4l4.4-7.3h-18.2
	c-1.6,0-2.9,0.8-3.9,2.3l-1.2,1.8l1.8,2.2L320.5,282.4L320.5,282.4z"
          />
          <path
            fill="#FFFFFF"
            d="M324,285.7c-0.7-2.1-1.8-3-3.4-3.2l-17.2-1.1l-1.8-2.2l-0.3,0.4c-9.6-0.3-16.2-1.1-19.7-2.5
	c-1.8-1-3.3-2.5-4.3-4.3c-1-1.5-1.5-3.3-1.8-5.4c-0.5-5-0.3-8.8,1-11.3c0.4-0.8,0.8-1.5,1.5-2.1c-1.8,0.3-3.3,2.9-4.3,7.7
	c-0.7,3.4-0.8,6.7-0.7,10.2l0.7,12.9c0.3,17.3-1.1,33.4-4.1,48.1l-1.8,3.6c0.8,2.2,1.8,4.4,2.9,6.6l3.7,1.2l8.5,2.6
	c8.4,2.3,16.5,4.4,24.5,5.8l6.2,1l1.8,0.3l3.3-10.5c-15.7-2.8-27.8-6.1-36.2-9.6c-4-2.2-5.8-6.1-5.5-11.4
	c0.1-11.1,1.1-21.3,2.9-30.4c1.2-3.4,4.4-5.2,9.6-5.2l35.9,1.2L324,285.7L324,285.7z"
          />
          <path
            fill="#656565"
            d="M326.7,300.7h2.3l1.6-2.1l-37.6-0.4c-3.9-0.4-5.9,1.4-6.5,5.4l-0.1,0.4c-0.7,4.5-1,9.5-0.8,14.6v0.7
	c0.1,2.8,2.1,5,5.9,6.6c5.1,1.6,10.7,3.2,16.9,4.7l14,2.9V330l-21.2-5.2c-2.9-0.5-5.6-1.2-8.4-2.1h-0.1c-2.9-1.2-4.5-2.8-5-4.4V318
	c-0.3-5.5-0.1-10.3,0.4-14.4c0.3-2.1,2.2-3.2,5.8-3.2h14.3l1.2,0.1L326.7,300.7L326.7,300.7z"
          />
          <path
            fill="#656565"
            d="M326.7,300.7l-17.1-0.1c1.2,0.3,2.5,1.1,3.6,2.2l0.1,0.1l1.1,1.5c0.5,0.7,0.8,1.8,1,3.3
	c0.1,3.4-0.1,6.6-0.7,9.8v0.1h36v-4.4h-5.1c-6.9,0.4-11.3-1-13.5-4L326.7,300.7L326.7,300.7z"
          />
          <path
            fill="#EBEBEB"
            d="M309.8,300.5l-1.2-0.1h-14.3c-3.6,0-5.5,1.1-5.8,3.2c-0.5,4.1-0.7,8.9-0.4,14.4v0.3c0.3,1.8,1.9,3.2,5,4.4
	h0.1c2.8,1,5.5,1.6,8.4,2.1l4.1,0.5l0.5-0.1c2.8-1.1,5.2-2.8,7.2-4.7l0.8-1.1l0.3-0.4l0.5-1.4v-0.1c0.5-3.2,0.8-6.3,0.7-9.8
	c-0.1-1.5-0.4-2.6-1-3.3l-1.1-1.5l-0.1-0.1C312.3,301.6,311,300.8,309.8,300.5L309.8,300.5z M310.3,304.7l1,1.4
	c0.3,0.4,0.4,1.2,0.5,2.1c0.1,2.3,0,4.7-0.3,6.9c-0.3,1.6-1.8,3.4-4.4,5.4l-1.8,1.2c-1.2,0.7-3,0.8-5.1,0.5h-0.4
	c-2.1-0.4-4.1-1-6.1-1.6c-1.8-0.8-3-1.6-3.3-2.8c-0.3-5.1,0.1-9.6,0.8-13.5v-0.1c0.1-0.5,1.1-0.8,2.8-0.8h13.3
	C308.4,303.1,309.4,303.7,310.3,304.7L310.3,304.7z"
          />
          <path
            fill="#FFFFFF"
            d="M330.8,298.6l-5.4-10.2l-35.9-1.2c-5.2,0-8.5,1.8-9.6,5.2c-1.8,9.1-2.8,19.3-2.9,30.4
	c-0.3,5.4,1.6,9.2,5.5,11.4c8.4,3.6,20.5,6.7,36.2,9.6l3.9-10.3l-14-2.9c-6.1-1.4-11.7-3-16.9-4.7c-3.9-1.6-5.8-3.7-5.9-6.6v-0.7
	c-0.1-5.2,0.1-10,0.8-14.6l0.1-0.4c0.5-4,2.8-5.8,6.5-5.4L330.8,298.6L330.8,298.6z"
          />
          <path
            fill="#656565"
            d="M313.2,320.5c-1.9,2.1-4.4,3.6-7.2,4.8l-0.5,0.1l-4.1-0.5l21.2,5.2l0.7-0.7c4.1-4,9.2-6.1,15-6.3h12.7v-3.4
	h-36.9L313.2,320.5L313.2,320.5z M314.3,319l-0.3,0.4h213.8l-0.3-0.4c-0.3-0.4-0.4-0.8-0.5-1.4H315L314.3,319z"
          />
          <path
            fill="#FFFFFF"
            d="M322.6,330v3.4l-3.9,10.3h1.6c1.4-3.7,3-6.9,5.1-9.8c0.7-1,1.4-1.8,2.1-2.6s1.5-1.5,2.3-2.2
	c3.2-2.5,7.3-3.6,12.4-3.6H500c5.1-0.1,9.2,1.1,12.4,3.6c0.8,0.7,1.7,1.4,2.3,2.2l2.1,2.6c1.9,2.8,3.7,6.1,5.1,9.8h1.7l-4-10.3V330
	l-0.7-0.7c-4.1-4-9.1-6.1-15-6.3H338.4c-5.8,0.3-10.9,2.3-15,6.3L322.6,330L322.6,330z"
          />
          <path
            fill="#FFFFFF"
            d="M327.5,331.5c-0.7,0.8-1.4,1.7-2.1,2.6c-1.9,2.8-3.7,6.1-5.1,9.8h2.9c1.8-4.5,4-8.1,6.9-10.9
	c3.3-2.9,7.4-4.3,12.2-4.3h148.8H342.4c-5,0-9.1,1.4-12.2,4.3c-2.8,2.8-5.1,6.3-6.9,10.9h195.6c-1.8-4.5-4-8.1-6.9-10.9
	c-3.3-2.9-7.4-4.3-12.2-4.3h-8.3h8.3c5,0,9.1,1.4,12.2,4.3c2.8,2.8,5.1,6.3,6.9,10.9h2.9c-1.4-3.7-3-6.9-5.1-9.8l-2.1-2.6
	c-0.7-0.8-1.5-1.5-2.3-2.2c-3.2-2.5-7.3-3.6-12.4-3.6H342.1c-5-0.1-9.2,1.1-12.4,3.6C328.9,330,328.2,330.7,327.5,331.5L327.5,331.5
	z"
          />
          <path
            fill="#FFFFFF"
            d="M321.4,349.6c0.5-2.1,1.2-4.1,1.9-5.9h-2.9c-1.1,2.9-2.1,6.1-2.8,9.5v1h3
	C320.5,352.9,320.8,351.4,321.4,349.6L321.4,349.6z"
          />
          <path
            fill="#FFFFFF"
            d="M323.3,343.7c-0.7,1.8-1.4,3.9-1.9,5.9c-0.5,1.8-0.8,3.3-0.7,4.5l200.7-0.1c0.1-1.2-0.1-2.6-0.7-4.4
	c-0.5-2.1-1.2-4.1-1.9-5.9H323.3z M320.4,343.7h-1.6l-3.3,10.5h2.2v-1C318.3,349.8,319.3,346.6,320.4,343.7L320.4,343.7z
	 M373.8,313.2h-0.8v4.4h0.8 M372.9,323h0.8v-3.4h-0.8"
          />
          <path
            fill="#656565"
            d="M372.9,317.6v-4.4h-19.3v4.4 M373.8,313.2v4.4h23v-4.4 M373.8,319.5v3.4h23v-3.4 M372.9,323v-3.4h-19.3v3.4"
          />
          <path fill="#FFFFFF" d="M352.3,313.2h-1.4v4.4h1.4" />
          <path
            fill="#FFFFFF"
            d="M353.7,313.2h-1.4v4.4h1.4 M352.3,319.5h-1.4v3.4h1.4"
          />
          <path
            fill="#FFFFFF"
            d="M353.7,319.5h-1.4v3.4h1.4 M268,264.4l1.1-4c-5.2,3.2-8.3,8.3-9.1,15.1c-0.1,11.4,0.8,24.5,2.9,39.2l1.2,8.7
	c0.7,4.3,1.8,8.7,3.7,13.2l1.8-3.6C267.7,307.7,267.1,284.7,268,264.4L268,264.4z"
          />
          <path
            fill="#656565"
            d="M283,347l-8.5-2.6c0.1,3,0.3,5.8,0.4,8.5h0.1l7.8,0.8V347H283z M288.1,354.5c0.1,0,0.3,0.1,0.4,0.3l0.1,0.4
	c0,0.1,0,0.3-0.1,0.4l-0.4,0.1l-4.8-0.3v6.5l4.3,0.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4l-0.1,0.4l-0.4,0.1l-3.9-0.3
	c0.7,2.6,2.5,4.7,5.2,6.1c2.5,1,5.1,1.8,7.6,2.8l1.9,0.7c5.1,1.6,10,2.9,15,4c3.4,0.7,7,1.4,10.5,1.8l7.8,0.8
	c22.7,1.8,48.7,2.6,78.1,2.6h24.8c28.8,0,54.3-0.8,76.6-2.6l7.8-0.8c3.4-0.4,6.9-1.1,10.3-1.8c5-1.1,10-2.3,15-4l9.5-3.3
	c2.9-1.4,4.5-3.4,5.2-6.1l-3.9,0.3l-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1l4.3-0.5v-6.5
	l-5,0.3l-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.3l5-0.7v-6.7c-8.4,2.3-16.6,4.4-24.5,5.8
	c0.4,5.1-0.8,7.4-3.7,6.9c-1.8-0.1-2.6-2.1-2.5-5.9l-1.8,0.3l-0.3-0.1h-1.9c-0.3,1.8-2.1,3-5.1,3.4l-8.7,0.5v0.5c0,0.7-0.3,1-1,1h-1
	c-0.7,0-1-0.3-1-1v-0.4l-15.5,0.7v0.5c0,0.7-0.3,1-1,1h-1c-0.7,0-1-0.3-1-1v-0.5l-2.9,0.3l0.1,10.3c0,2.2-1.1,3.2-3.2,3H465
	c-1.9,0-2.9-1-3-3v-10.3h-3.3v0.5c0,0.7-0.3,1-1,1h-1c-0.7,0-1-0.3-1-1v-0.5h-10.2l-1,11.8h-3l-1.2-11.8h-17.9v0.3c0,0.7-0.3,1-1,1
	h-1c-0.7,0-1-0.3-1-1v-0.3h-17.5l-1.2,11.8h-3l-1-11.8h-10v0.5c0,0.7-0.3,1-1,1h-1c-0.7,0-1-0.3-1-1v-0.5h-28.1l-3-0.3v0.4
	c0,0.7-0.3,1-1,1h-1c-0.7,0-1-0.3-1-1v-0.6l-15.5-0.7v0.4c0,0.7-0.3,1-1,1h-1c-0.7,0-1-0.3-1-1v-0.5l-8.8-0.5
	c-3-0.4-4.7-1.5-5.1-3.3h-2.2l-1.8-0.3c0.1,3.9-0.7,5.8-2.5,5.9c-2.9,0.4-4.1-1.9-3.7-6.9c-8-1.4-16.1-3.4-24.5-5.8v6.7L288.1,354.5
	L288.1,354.5z"
          />
          <path
            fill="#FFFFFF"
            d="M288.3,354.7c-0.1-0.1-0.3-0.1-0.4-0.3l-5-0.7l-7.8-0.8H275c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.3,0.3-0.3,0.4
	c0,0.1,0,0.4,0.1,0.5s0.3,0.3,0.4,0.3h0.1l7.8,0.8l4.8,0.3l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4L288.3,354.7L288.3,354.7z M287.6,362.4
	c-0.1-0.1-0.3-0.1-0.4-0.1l-4.3-0.5l-6.9-0.7H276l-0.3,0.1c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1,0,0.4,0.1,0.5s0.3,0.3,0.4,0.3h0.3l7,0.7
	l3.9,0.3l0.4-0.1l0.1-0.4C287.8,362.7,287.8,362.6,287.6,362.4L287.6,362.4z"
          />
          <path
            fill="#656565"
            d="M283,361.5v-6.2l-7.8-0.8c0.3,2.3,0.4,4.5,0.8,6.7h0.1l6.9,0.7V361.5L283,361.5z"
          />
          <path
            fill="#FFFFFF"
            d="M383.8,359.1h12.8v-4.4h5.2v4.4h38.2v-4.4h5.2v4.4h41.4l2.9-0.3l2.9-0.1L508,358l2.9-0.1l8.7-0.5
	c1.2-0.8,1.9-1.9,2.1-3.4L320.9,354c0.1,1.4,0.8,2.5,2.1,3.3l8.8,0.5l2.8,0.1l15.5,0.7l2.8,0.1l3,0.3L383.8,359.1L383.8,359.1z"
          />
          <path
            fill="#FFFFFF"
            d="M386.7,359.1h-2.8v0.5c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1L386.7,359.1L386.7,359.1z M352.7,359.3v-0.4
	l-2.8-0.1v0.5c0,0.7,0.3,1,1,1h1C352.4,360.2,352.7,360,352.7,359.3L352.7,359.3z M334.4,358.2l-2.8-0.1v0.5c0,0.7,0.3,1,1,1h1
	c0.7,0,1-0.3,1-1L334.4,358.2L334.4,358.2z M322.7,357.5c-1.2-0.8-1.9-1.9-2.1-3.3h-3C318,355.8,319.7,356.9,322.7,357.5
	L322.7,357.5z M311.2,359.8c1.8-0.1,2.6-2.1,2.5-5.9l-6.2-1C307.2,357.9,308.3,360.2,311.2,359.8L311.2,359.8z"
          />
          <path
            fill="#656565"
            d="M323.4,384.6c-0.5,0.3-1,0.5-1.4,1l7.6,1.2c22.7,1.4,46.4,2.1,70.8,2.3h41.4l21-0.3l6.3-0.1l5.6-0.1l6.7-0.3
	l30.7-1.5l7.6-1.2c-0.4-0.4-0.8-0.7-1.4-1c-3-1.5-7.8-2.1-14.7-1.4c-27.2,1.6-54.9,2.2-82.8,1.8c-28.1,0.4-55.7-0.1-83-1.8
	C331.4,382.5,326.4,383.1,323.4,384.6L323.4,384.6z"
          />
          <path
            fill="#FFFFFF"
            d="M539.2,277.4c-1-1.5-2.3-2.3-3.9-2.3h-18.2l4.4,7.3l17.2-1.1l1.8-2.2L539.2,277.4L539.2,277.4z"
          />
          <path
            fill="#656565"
            d="M532.4,300.5l1.2-0.1h14.3c3.6,0,5.5,1.1,5.8,3.2c0.5,4.1,0.7,8.9,0.4,14.4v0.3c-0.3,1.8-1.9,3.2-5,4.4H549
	c-2.8,1-5.5,1.6-8.4,2.1l-21.2,5.2v3.4c5-1,9.6-1.9,14-2.9c6.2-1.4,11.8-3,16.9-4.7c3.9-1.6,5.8-3.7,5.9-6.6v-0.7
	c0.1-5.2-0.1-10-0.8-14.6l-0.1-0.4c-0.5-4-2.8-5.8-6.6-5.4l-37.6,0.4l1.7,2.1h2.3L532.4,300.5L532.4,300.5z"
          />
          <path
            fill="#EBEBEB"
            d="M533.6,300.4l-1.2,0.1c-1.2,0.3-2.5,1.1-3.6,2.2l-0.1,0.1l-1.1,1.5c-0.5,0.7-0.8,1.8-1,3.3
	c-0.1,3.4,0.1,6.6,0.7,9.8v0.1c0.1,0.4,0.3,1,0.6,1.4l0.3,0.4l0.8,1.1c1.9,2.1,4.3,3.6,7.2,4.7l0.5,0.1l4.1-0.5
	c2.9-0.5,5.6-1.2,8.4-2.1h0.1c2.9-1.2,4.5-2.8,5-4.4V318c0.3-5.5,0.1-10.3-0.4-14.4c-0.3-2.1-2.2-3.2-5.8-3.2L533.6,300.4
	L533.6,300.4z M550.8,304v0.1c0.8,3.9,1.1,8.4,0.8,13.5c-0.4,1-1.5,1.9-3.3,2.8c-1.9,0.7-4,1.2-6.1,1.7h-0.4
	c-2.1,0.4-3.9,0.3-5.1-0.6l-1.8-1.2c-2.8-1.9-4.1-3.7-4.4-5.4c-0.3-2.2-0.4-4.5-0.3-6.9c0.1-1,0.3-1.6,0.5-2.1l1-1.4
	c0.8-1,1.8-1.5,2.9-1.5h13.3C549.7,303.1,550.7,303.4,550.8,304L550.8,304z"
          />
          <path
            fill="#656565"
            d="M550.8,304.1V304c-0.1-0.5-1.1-0.8-2.8-0.8h-13.3c-1.1,0.1-2.1,0.5-2.9,1.5l-1,1.4c-0.3,0.4-0.4,1.2-0.5,2.1
	c-0.1,2.3,0,4.7,0.3,6.9c0.3,1.7,1.8,3.4,4.4,5.4l1.8,1.2c1.2,0.7,3,0.8,5.1,0.5h0.4c2.1-0.4,4.1-1,6.1-1.6c1.8-0.8,2.9-1.6,3.3-2.8
	C551.9,312.5,551.6,308,550.8,304.1L550.8,304.1z M545.9,308.2c1.4,1.4,2.1,3,2.1,5s-0.7,3.6-2.1,5c-1.4,1.4-3,2.1-5,2.1
	s-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5s0.7-3.6,2.1-5c1.4-1.4,3-2.1,5-2.1C542.8,306.3,544.5,307,545.9,308.2L545.9,308.2z"
          />
          <path
            fill="#FFFFFF"
            d="M562.2,292.3c-1.2-3.4-4.4-5.2-9.6-5.2l-35.9,1.2l-5.4,10.2l37.6-0.4c3.8-0.4,5.9,1.4,6.6,5.4l0.1,0.4
	c0.7,4.5,1,9.5,0.8,14.6v0.7c-0.1,2.8-2.1,5-5.9,6.6c-5.1,1.6-10.7,3.2-16.9,4.7c-4.4,1-9.1,1.9-14,2.9l4,10.3
	c15.7-2.8,27.8-6.1,36.2-9.6c4-2.2,5.8-6.1,5.6-11.4C565,311.5,564,301.4,562.2,292.3L562.2,292.3z M489.9,313.2h-1.5v4.4h1.5"
          />
          <path fill="#FFFFFF" d="M491.1,313.2h-1.2v4.4h1.2" />
          <path
            fill="#656565"
            d="M496.2,313.2h-5.1v4.4h36.2v-0.1c-0.5-3.2-0.8-6.3-0.7-9.8c0.1-1.5,0.4-2.6,1-3.3l1.1-1.5l0.1-0.1
	c1.1-1.2,2.3-1.9,3.6-2.2l-17.1,0.1l-5.6,8.5C507.5,312.2,503.1,313.6,496.2,313.2L496.2,313.2z"
          />
          <path fill="#FFFFFF" d="M489.9,319.5h-1.5v3.4h1.5" />
          <path fill="#FFFFFF" d="M491.1,319.5h-1.2v3.4h1.2" />
          <path
            fill="#656565"
            d="M491.1,319.5v3.4h12.7c5.8,0.3,10.9,2.3,15,6.3l0.7,0.7l21.2-5.2l-4.1,0.5l-0.5-0.1
	c-2.8-1.1-5.2-2.8-7.2-4.7l-0.8-1.1L491.1,319.5L491.1,319.5z M488.4,323v-3.4h-19.3v3.4 M488.4,317.6v-4.4h-19.3v4.4"
          />
          <path
            fill="#FFFFFF"
            d="M523.4,343.7h-1.7c1.1,2.9,2.1,6.1,2.8,9.5v0.8h2.2L523.4,343.7L523.4,343.7z"
          />
          <path
            fill="#FFFFFF"
            d="M518.9,343.7c0.7,1.8,1.4,3.9,1.9,5.9c0.5,1.6,0.8,3.2,0.7,4.4h3v-0.8c-0.8-3.4-1.7-6.6-2.8-9.5L518.9,343.7
	L518.9,343.7z M421.8,313.2h-1v4.4h1 M420.8,323h1v-3.4h-1"
          />
          <path
            fill="#656565"
            d="M421.8,313.2v4.4h22.7v-4.4 M420.8,317.6v-4.4h-23.1v4.4 M420.8,323v-3.4h-23.1v3.4 M421.8,319.5v3.4h22.7
	v-3.4"
          />
          <path
            fill="#FFFFFF"
            d="M396.7,313.2v4.4h1v-4.4 M396.7,319.5v3.4h1v-3.4 M469.1,313.2h-0.8v4.4h0.8 M468.3,323h0.8v-3.4h-0.8"
          />
          <path
            fill="#656565"
            d="M468.3,317.6v-4.4h-22.8v4.4 M468.3,323v-3.4h-22.8v3.4"
          />
          <path
            fill="#FFFFFF"
            d="M445.4,313.2h-1v4.4h1 M444.5,323h1v-3.4h-1 M468.7,364.1c1.4,1.2,3.2,1.8,5.8,1.8c2.1-0.1,3.7-0.8,4.8-1.8
	l0.4-0.4c0.7-1,1.1-2.1,1.2-3.4v-1h-1.4v1c-0.1,1-0.4,1.9-1,2.6c-0.5,0.7-1.2,1.1-2.2,1.4c-0.5,0.1-1.2,0.3-1.9,0.4l-2.9-0.3
	c-0.8-0.3-1.4-0.6-1.9-1.1c-0.7-0.7-1.1-1.6-1.2-2.9v-1H467v1C467.2,361.9,467.7,363.3,468.7,364.1L468.7,364.1z"
          />
          <path
            fill="#FFFFFF"
            d="M468.8,364.2c-1.1-1.1-1.7-2.3-1.7-4.1h-3.3l0.3,4.1H468.8z M468.5,359.1v1h11v-1H468.5z"
          />
          <path
            fill="#056DA5"
            d="M471.6,364.2l2.9,0.3c0.7,0,1.4-0.1,1.9-0.4L471.6,364.2z"
          />
          <path
            fill="#FFFFFF"
            d="M471.6,364.2l4.8-0.1c1-0.3,1.7-0.8,2.2-1.4c0.6-0.7,0.8-1.5,1-2.6h-11c0.1,1.2,0.4,2.2,1.2,2.9
	C470.2,363.5,470.9,363.9,471.6,364.2L471.6,364.2z M456.6,360.6h1c0.7,0,1-0.3,1-1v-0.5h-2.9v0.5
	C455.6,360.2,455.9,360.6,456.6,360.6L456.6,360.6z"
          />
          <path
            fill="#FFFFFF"
            d="M463.7,359.1h-1l0.5,8.9c0.1,1,0.5,1.8,1,2.2c0.4,0.5,1.1,0.7,1.8,0.5h16.2c0.7,0.1,1.2,0,1.8-0.5
	c0.4-0.4,0.7-1.2,1-2.2l0.5-9.1h-1v1l-0.3,3.9l-0.1,3.2l-0.1,0.8c-0.1,0.8-0.4,1.4-0.7,1.6s-0.5,0.3-0.8,0.3h-16.6
	c-0.3,0.1-0.7,0-0.8-0.3c-0.3-0.3-0.5-1-0.7-1.6v-0.7l-0.1-3L464,360L463.7,359.1L463.7,359.1z"
          />
          <path fill="#FFFFFF" d="M467.2,360.2l-0.1-1.1h-3.3v1.1" />
          <path
            fill="#FFFFFF"
            d="M462.8,359.3h-1v10.3c0.1,2.1,1.1,3,3,3h18.8c2.1,0.1,3.2-0.8,3.2-3l-0.1-10.3h-1.2l-0.5,9.1
	c-0.3,1-0.6,1.8-1,2.2c-0.5,0.6-1.1,0.7-1.8,0.6h-16.2c-0.7,0.1-1.2,0-1.8-0.6c-0.4-0.4-0.7-1.2-1-2.2L462.8,359.3L462.8,359.3z"
          />
          <path
            fill="#FFFFFF"
            d="M465.8,370h16.6c0.3,0.1,0.7,0,0.8-0.3c0.3-0.3,0.5-1,0.7-1.7l0.1-0.8l-19.8,0.3v0.7
	c0.1,0.8,0.4,1.4,0.7,1.6C465.2,370,465.5,370,465.8,370L465.8,370z M474.6,365.9c-2.6,0.1-4.5-0.4-5.8-1.7H464l0.1,3L484,367
	l0.1-3.2l-5,0.1C478.3,365,476.7,365.7,474.6,365.9L474.6,365.9z M440.2,359.1h5.2v-4.5h-5.2 M445.4,359.1h-5.2l1.2,11.7h3
	 M463,388.7l8.8,2.3l9.9-2.9l-6.7,0.3l-2.9,1.1l-2.8-1"
          />
          <path
            fill="#FFFFFF"
            d="M472.1,389.5l2.9-1.1l-5.6,0.1 M422.3,359.4v-0.3h-2.9v0.3c0,0.7,0.3,1,1,1h1
	C421.9,360.4,422.3,360.1,422.3,359.4L422.3,359.4z M400.7,370.8l1.2-11.7h-5.2l1,11.7 M402,354.6h-5.2v4.5h5.2"
          />
          <path
            fill="#656565"
            d="M400.4,389.1c8.1,4.7,15.8,6.6,23.4,5.8c6.2-0.5,12.2-2.5,18.2-5.8L400.4,389.1L400.4,389.1z"
          />
          <path
            fill="#FFFFFF"
            d="M531,359.8c2.9,0.4,4.1-1.9,3.7-6.9l-6.2,1C528.2,357.8,529.2,359.7,531,359.8L531,359.8z"
          />
          <path fill="#056DA5" d="M526.3,354l0.4,0.1l-0.1-0.1" />
          <path
            fill="#FFFFFF"
            d="M524.5,354h-3c-0.1,1.5-0.8,2.6-2.1,3.4C522.5,356.9,524.1,355.8,524.5,354L524.5,354z M510.6,358.4v-0.5
	l-2.9,0.1v0.4c0,0.7,0.3,1,1,1h1C510.4,359.4,510.6,359.1,510.6,358.4L510.6,358.4z M492.3,358.7l-2.9,0.1v0.4c0,0.7,0.3,1,1,1h1
	c0.7,0,1-0.3,1-1L492.3,358.7L492.3,358.7z M479.8,363.7l-0.4,0.4l5-0.1l0.3-3.9h-3.4C480.9,361.6,480.5,362.7,479.8,363.7
	L479.8,363.7z"
          />
          <path
            fill="#FFFFFF"
            d="M481.1,359.1v1h3.4v-1 M494.8,241.7c-3.3-2.5-7.6-3.9-13.1-3.7H360.4c-3.4,0-6.3,0.4-8.9,1.5
	c-1.5,0.6-2.9,1.4-4.1,2.3c-1.2,1.1-2.6,2.3-3.9,3.6c-3,2.9-5.9,6.3-8.8,10c0.7,0.7,1,1.5,1,2.5l-0.1,0.7c-1.1,3-2.3,5.8-3.6,8.4
	l-3.9,6.7l-0.5,0.5c-0.7,0.4-1.5,0.8-2.6,1l-4.4,7.3c1.5,0.1,2.8,1.2,3.4,3.2l1.5,2.8l5.4,10.2l-1.7,2.1h-2.3l5.6,8.5
	c2.2,3,6.6,4.4,13.5,4h150.2c6.9,0.4,11.3-1,13.5-4l5.6-8.5H513l-1.6-2.1l5.4-10.2l1.5-2.8c0.7-2.1,1.9-3,3.4-3.2l-4.4-7.3
	c-1.1-0.1-1.9-0.4-2.6-1l-0.5-0.5l-3.9-6.7c-1.2-2.6-2.5-5.4-3.6-8.4l-0.1-0.7c0-1,0.3-1.8,0.8-2.5c-2.8-3.7-5.6-7.2-8.7-10
	L494.8,241.7L494.8,241.7z M331.5,295l-1.9-2.6c-2.3-3.7-2.8-7-1.4-9.9l3.3-6.5c5-9.2,10.2-17.6,15.8-25.4c2.8-3.4,7.2-5.2,13.5-5.5
	h120.5c6.3,0.3,10.7,2.1,13.5,5.5c5.6,7.7,10.9,16.1,15.8,25.4l3.3,6.5c1.4,2.9,0.8,6.1-1.4,9.9l-1.9,2.6l-5.2,6.7
	c-1.9,2.2-5,3.7-9.2,4.5l-5,0.5H350.9l-5.1-0.5c-4.3-0.8-7.3-2.3-9.2-4.5L331.5,295L331.5,295z"
          />
          <path
            fill="#FFFFFF"
            d="M329.6,292.4l1.8,2.6l5.2,6.7c1.9,2.2,5,3.7,9.2,4.5l5.1,0.5h140.3l5-0.5c4.3-0.8,7.3-2.3,9.2-4.5l5.2-6.7
	l1.9-2.6c2.3-3.7,2.8-7,1.4-9.9l-3.3-6.5c-5-9.2-10.2-17.6-15.8-25.4c-2.8-3.4-7.2-5.2-13.5-5.5H360.8c-6.3,0.3-10.7,2.1-13.5,5.5
	c-5.6,7.7-10.9,16.1-15.8,25.4l-3.3,6.5C326.9,285.4,327.4,288.7,329.6,292.4L329.6,292.4z M352,253.1c2.5-2.2,5.2-3.3,8.5-3.3
	c16.8-0.3,34.3-0.4,52.4-0.4h33.8c11.8,0.1,23.5,0.3,34.8,0.4c3.3,0.1,6.1,1.2,8.5,3.3l0.1,0.1c2.8,2.9,5.4,6.3,8,10
	c1.9,2.6,3.6,5.4,5.2,8.4c1.6,3,3.3,6.2,5,9.5c1.6,3.4,1.5,6.9-0.7,10.3c-1.9,3-4.3,5.9-7,8.7l-1.6,1.4c-0.8,0.5-1.9,1.1-2.9,1.4
	c-1.5,0.4-3.4,0.7-5.6,0.7c-16.9,0.5-33.7,0.7-50.2,0.3h-38c-16.5,0.3-33.3,0.1-50.2-0.3c-2.2-0.1-4.1-0.3-5.6-0.7
	c-1.1-0.3-2.1-0.7-2.9-1.4l-1.6-1.4c-2.8-2.6-5-5.5-7-8.7c-2.1-3.4-2.3-6.9-0.7-10.3c1.6-3.4,3.3-6.6,5-9.5c1.6-3,3.4-5.8,5.2-8.4
	C346.7,259.4,349.3,256.1,352,253.1L352,253.1z"
          />
          <path
            fill="#EBEBEB"
            d="M352,252.9c-2.9,3-5.5,6.3-8,10c-1.8,2.6-3.6,5.4-5.2,8.4c-1.6,3-3.3,6.2-5,9.5c-1.6,3.4-1.5,6.9,0.7,10.3
	c1.9,3,4.3,5.9,7,8.7l1.6,1.4c0.8,0.5,1.8,1.1,2.9,1.4c1.5,0.4,3.4,0.7,5.6,0.7c16.9,0.5,33.7,0.7,50.2,0.3h38
	c16.5,0.3,33.3,0.1,50.2-0.3c2.2-0.1,4.1-0.3,5.6-0.7c1.1-0.3,2.1-0.7,2.9-1.4l1.6-1.4c2.8-2.6,5-5.5,7-8.7
	c2.1-3.4,2.3-6.9,0.7-10.3c-1.6-3.4-3.3-6.6-5-9.5c-1.6-3-3.4-5.8-5.2-8.4c-2.5-3.7-5.2-7-8-10l-0.1-0.1c-2.3-2.1-5.2-3.2-8.5-3.3
	c-11.3-0.3-23-0.3-34.8-0.4h-33.8c-18.2,0-35.6,0.1-52.4,0.4C357.3,249.8,354.4,250.9,352,252.9L352,252.9z M354,254.6
	c2.1-1.8,4.5-2.8,7.6-2.9c16.5-0.3,33.7-0.4,51.5-0.4h33.8c11.6,0.1,22.8,0.3,34,0.4c2.9,0,5.4,1,7.4,2.8c2.6,2.8,5.2,5.9,7.7,9.4
	c1.8,2.5,3.4,5.1,5.1,8c1.6,2.9,3.3,5.9,4.8,9.1c1.4,2.9,1.2,5.6-0.5,8.5c-1.9,2.9-4.1,5.5-6.6,8l-1.4,1.1l-0.1,0.1
	c-0.7,0.5-1.5,0.8-2.3,1.1c-1.4,0.3-3.2,0.5-5.4,0.7c-16.8,0.5-33.4,0.7-49.8,0.4h-36.9c-16.4,0.3-33,0.1-49.8-0.4
	c-2.1-0.1-3.9-0.3-5.4-0.7c-0.8-0.3-1.6-0.5-2.3-1.1l-0.1-0.1l-1.4-1.1c-2.6-2.5-4.8-5.1-6.6-8c-1.8-2.9-1.9-5.8-0.5-8.5
	c1.5-3.2,3.2-6.2,4.8-9.1c1.6-2.8,3.3-5.5,5.1-8C348.7,260.5,351.2,257.5,354,254.6L354,254.6z"
          />
          <path
            fill="#FFFFFF"
            d="M354,254.6c-2.8,2.9-5.2,5.9-7.7,9.4c-1.8,2.5-3.4,5.1-5.1,8c-1.6,2.9-3.3,5.9-4.8,9.1
	c-1.4,2.9-1.2,5.6,0.5,8.5c1.8,2.9,4.1,5.5,6.6,8l1.4,1.1l0.1,0.1c0.7,0.5,1.5,0.8,2.3,1.1c1.4,0.3,3.2,0.5,5.4,0.7
	c16.8,0.5,33.4,0.7,49.8,0.4h36.9c16.4,0.3,33,0.1,49.8-0.4c2.1-0.1,3.9-0.3,5.4-0.7c0.8-0.3,1.6-0.5,2.3-1.1l0.1-0.1l1.4-1.1
	c2.6-2.5,4.8-5.1,6.6-8c1.8-2.9,1.9-5.8,0.5-8.5c-1.5-3.2-3.2-6.2-4.8-9.1c-1.6-2.8-3.3-5.5-5.1-8c-2.5-3.4-5-6.6-7.7-9.4
	c-2.1-1.8-4.5-2.8-7.4-2.8c-11-0.1-22.4-0.3-34-0.4h-33.8c-17.7,0-34.9,0.1-51.5,0.4C358.5,251.8,356,252.8,354,254.6L354,254.6z
	 M354.6,255.3c1.9-1.6,4.1-2.5,6.9-2.5c16.5-0.3,33.7-0.4,51.5-0.4h33.8c11.6,0.1,22.8,0.3,34,0.4c2.6,0,5,1,6.9,2.6
	c1.8,1.9,3.6,4,5.4,6.2l2.2,3c1.6,2.3,3.3,5,5,7.6l0.1,0.3c1,1.8,2.1,3.4,3,5.4l0.7,1.4l1.1,2.2c0.8,1.5,1,3,0.8,4.5
	c-0.1,1.1-0.7,2.1-1.2,3.2c-1.8,2.8-4,5.4-6.6,7.8l-0.3,0.3l-1,0.8c-0.5,0.4-1.1,0.7-1.8,0.8H495c-1.2,0.3-3,0.5-5,0.5
	c-16.8,0.5-33.3,0.7-49.8,0.4h-37.7c-16.5,0.3-33,0.1-49.8-0.4l-5-0.6h-0.1c-0.7-0.1-1.2-0.4-1.8-0.8l-1-0.8l-0.3-0.3
	c-2.5-2.5-4.7-5.1-6.6-7.8c-1.6-2.6-1.8-5.1-0.5-7.7l1.1-2.2l0.7-1.4c1-1.8,1.9-3.6,3-5.4l0.1-0.3c1.5-2.6,3.2-5.2,5-7.6l2.2-3
	C351.1,259.3,352.9,257.2,354.6,255.3L354.6,255.3z"
          />
          <path
            fill="#EBEBEB"
            d="M386.4,257.1c1,0,1.6,0.3,1.9,1.1c0.1,0.4,0.3,1,0.3,1.6v1.7h17.2l1.5-2.1c1-1.5,2.3-2.2,4.1-2.2h19.4
	c1.8,0,3.3,0.7,4.1,2.2l1.5,2.1h17.2v-1.6c0-0.7,0-1.2,0.3-1.7c0.3-0.8,1-1.2,1.9-1.1h19.9c1.8-0.1,3.2,0.3,4,1.1l0.7,1l0.3,0.7
	l0.7,1.7h11.7c-1.8-2.2-3.4-4.3-5.4-6.2c-1.9-1.6-4.3-2.5-6.9-2.6c-11-0.1-22.3-0.3-34-0.4h-33.8c-17.7,0-34.9,0.1-51.5,0.4
	c-2.6,0-5,1-6.9,2.6c-1.8,1.9-3.6,4-5.4,6.2h11.7l0.8-1.6l0.3-0.7c0.1-0.4,0.4-0.7,0.7-1c0.8-0.8,2.2-1.2,4-1.1L386.4,257.1
	L386.4,257.1z"
          />
          <path
            fill="#656565"
            d="M377.1,258.2v-1.1h-10.5v4.4h10.5v-1.6 M377.1,272.2v-8.1h-10.5v1.2l0.1,1.6v5.2"
          />
          <path
            fill="#656565"
            d="M384.4,264.1h4.3v-2.6H361l-1.2,2.6h17.3 M388.6,261.5v2.6h15.3l1.8-2.6"
          />
          <path
            fill="#EBEBEB"
            d="M384.4,272.2h-42.2l-0.1,0.3c-1,1.8-2.1,3.4-3,5.4h65.3l-3.2-5.5l-0.1-0.1L384.4,272.2L384.4,272.2z"
          />
          <path
            fill="#656565"
            d="M351.8,264.1h8.1l1.2-2.6h-11.7l-2.2,3c-1.6,2.3-3.3,5-5,7.6h7.7v-8H348L351.8,264.1L351.8,264.1
	L351.8,264.1z"
          />
          <path fill="#FFFFFF" d="M351.8,265.3v-1.2h-1.9v8.1h1.9V267" />
          <path fill="#656565" d="M366.7,279.2v7.8l10.3,0.3v-8.1" />
          <path fill="#FFFFFF" d="M364,279.2h41.1l-0.8-1.4H339l-0.7,1.4h13.5" />
          <path
            fill="#656565"
            d="M366.7,287H364l-7.8-0.3l2.9,5.2c0.5,0.8,1.5,1.4,2.9,1.7l23.8,1c1,0,1.8-0.3,2.2-1c0.3-0.5,0.5-1.2,0.5-2.2
	v-4.1h-4.3l-7.3-0.1v6.7v-6.7L366.7,287l0,6.6L366.7,287L366.7,287z"
          />
          <path
            fill="#EBEBEB"
            d="M361.9,293.6c-1.4-0.3-2.3-0.8-2.9-1.6l-2.9-5.2l-6.3-0.3l-12.8-0.7l12.8,0.7v-7.3h-11.4l-1.1,2.2
	c-1.2,2.5-1.1,5.1,0.5,7.7c1.8,2.8,4,5.4,6.6,7.8l0.3,0.3c21.5,0.7,44,0.8,67.7,0.5c35.4,0.3,63.7,0.1,85.3-0.5l0.3-0.3
	c2.5-2.5,4.7-5.1,6.6-7.8c0.7-1.1,1.1-2.1,1.2-3.2l-13.3,0.5l-1.9,0.1l-4.4,0.3l-2.9,5.2c-0.5,0.8-1.5,1.4-2.9,1.6l-23.8,1
	c-1,0-1.8-0.3-2.2-1c-0.3-0.5-0.5-1.2-0.5-2.2v-4.1h-15.3v4.4c-0.3,1.8-1.2,2.8-2.9,2.9h-28.8c-1.6-0.1-2.6-1.1-2.9-2.9v-4.4h-15.3
	v4.1c0,1-0.3,1.6-0.5,2.2c-0.4,0.7-1.2,1-2.2,1L361.9,293.6L361.9,293.6z"
          />
          <path
            fill="#FFFFFF"
            d="M347.3,298.9l5.1,0.7c16.8,0.5,33.3,0.7,49.8,0.4h37.7c16.5,0.3,33,0.1,49.8-0.4c2.1-0.1,3.7-0.3,5-0.5h0.1
	c0.7-0.1,1.2-0.4,1.8-0.8l1-0.8c-21.5,0.7-49.9,0.8-85.3,0.5c-23.7,0.3-46.2,0.1-67.7-0.5l1,0.8
	C346.1,298.5,346.7,298.7,347.3,298.9L347.3,298.9z"
          />
          <path
            fill="#EBEBEB"
            d="M500.2,272.5l-0.1-0.3h-59l-3.3,5.6h65.3L500.2,272.5L500.2,272.5z"
          />
          <path fill="#656565" d="M478.2,264.1h4.3l-1.2-2.6h-27.6v2.6h21.9" />
          <path fill="#FFFFFF" d="M490.4,264.1v8.1h1.9l-0.1-8.1" />
          <path
            fill="#656565"
            d="M490.4,264.1h3.6h-1.8v8h7.7c-1.5-2.6-3.2-5.2-5-7.6l-2.2-3h-11.7l1.2,2.6L490.4,264.1L490.4,264.1z"
          />
          <path
            fill="#FFFFFF"
            d="M490.4,285.4v1.2l2.1-0.1l-0.1-7.3h-1.9v4.5 M490.4,279.2h13.5l-0.8-1.4h-65.3l-0.8,1.4h41.3"
          />
          <path
            fill="#656565"
            d="M492.3,279.2l0.1,7.3l13.3-0.5c0.3-1.5,0-3-0.8-4.5l-1.1-2.2H492.3z M486,286.8l-7.8,0.3h-2.8v6.6V287
	l-10.3,0.3v6.7v-6.7l-7.3,0.1h-4.3v4.1c0,1,0.3,1.6,0.5,2.2c0.4,0.7,1.2,1,2.2,1l23.8-1c1.4-0.3,2.3-0.8,2.9-1.6L486,286.8
	L486,286.8z M475.4,261.5l0.1-1.6v-2.8h-10.5v4.4 M465.1,265.3v6.9h10.3v-8.1h-10.3"
          />
          <path
            fill="#EBEBEB"
            d="M439.1,265.3l-0.8-1.1l-1.9-2.6l-1.5-2.1c-1-1.5-2.3-2.2-4.1-2.2h-19.4c-1.8,0-3.3,0.7-4.1,2.2l-1.5,2.1
	l-1.8,2.6l-0.8,1.1l-1.2,1.7l-0.8,1.2l-0.3,0.7c-0.1,0.5-0.3,1.1-0.1,1.6s0.3,1.1,0.7,1.7l0.1,0.1l3.2,5.5l0.8,1.4l0.7,1l0.5,1
	l0.5,0.8c0.8,0.8,1.9,1.5,3.3,1.9l0.7,0.1h20.5l0.7-0.1c1.4-0.4,2.5-1,3.3-1.9l0.6-0.8l0.5-1l0.5-1l0.8-1.4l3.3-5.6
	c0.4-0.5,0.6-1.1,0.7-1.6v-0.7l-0.3-1l-0.3-0.7l-0.8-1.2L439.1,265.3L439.1,265.3z M440.3,270.4c-0.1,0.5-0.3,1.1-0.7,1.6l-1.5,2.9
	l-3.4,5.5c-0.4,0.5-1,1-1.5,1.4c-0.7,0.3-1.4,0.5-2.3,0.8h-19.7c-0.8-0.1-1.7-0.4-2.2-0.8c-0.7-0.3-1.1-0.8-1.5-1.4l-3.4-5.5
	l-1.5-2.9c-0.4-0.5-0.7-1.1-0.7-1.6c-0.1-0.5,0-1.1,0.3-1.6l0.3-0.4l5.6-8c0.8-1.4,2.1-2.1,3.9-2.1h18.7c1.6,0,2.9,0.7,3.9,2.1
	l5.6,8l0.3,0.4l0.3,1L440.3,270.4L440.3,270.4z"
          />
          <path
            fill="#656565"
            d="M439.7,272.2c0.4-0.5,0.5-1.1,0.7-1.6v-0.7l-0.3-1l-0.3-0.4l-5.6-8c-0.8-1.4-2.1-2.1-3.9-2.1h-18.7
	c-1.6,0-2.9,0.7-3.9,2.1l-5.6,8l-0.3,0.4c-0.3,0.5-0.3,1.1-0.3,1.6c0.1,0.5,0.3,1.1,0.7,1.6l1.5,2.9l3.4,5.5c0.4,0.5,1,1,1.5,1.4
	c0.7,0.3,1.4,0.5,2.2,0.8h19.7c0.8-0.1,1.6-0.4,2.3-0.8c0.5-0.3,1.1-0.8,1.5-1.4l3.4-5.5L439.7,272.2L439.7,272.2z M436.1,270.4v0.5
	c0,0.5-0.1,1-0.3,1.5c-0.3,1.2-1,2.3-1.9,3.4c-0.8,1-1.9,1.8-3.2,2.5c-1,0.5-2.1,1.1-3.3,1.4l-3.6,0.7l-2.3,0.1l-3-0.1l-2.9-0.5
	c-1.4-0.4-2.6-0.8-3.7-1.5c-1.2-0.7-2.2-1.4-3-2.3c-0.7-0.7-1.2-1.4-1.6-2.2c-0.5-1.1-0.8-2.2-0.7-3.4c0-0.5,0.1-1.1,0.3-1.6
	l0.3-0.7l0.7-1.2c0.4-0.5,0.8-1.1,1.4-1.6l0.5-0.5c1-0.8,2.1-1.5,3.4-2.2c1.5-0.7,3-1.1,4.8-1.4l3-0.3h1.2l3,0.3l2.8,0.7
	c1.4,0.4,2.8,1.1,3.9,1.8l2.1,1.6l0.5,0.5l0.8,1.1l0.6,1l0.3,0.8L436.1,270.4L436.1,270.4z"
          />
          <path
            fill="#EBEBEB"
            d="M436.1,271.1v-0.5c0-0.5-0.1-1.1-0.3-1.6l-0.3-0.8l-0.5-1l-0.8-1.1l-0.5-0.5l-2.1-1.6
	c-1.1-0.7-2.5-1.4-3.9-1.8l-2.8-0.7l-3-0.3h-1.2l-3,0.3c-1.6,0.3-3.3,0.8-4.8,1.4c-1.4,0.7-2.5,1.4-3.4,2.2l-0.5,0.5
	c-0.5,0.5-1,1.1-1.4,1.6l-0.7,1.2l-0.3,0.7c-0.1,0.5-0.3,1.1-0.3,1.6c0,1.2,0.1,2.3,0.7,3.4c0.4,0.8,1,1.5,1.6,2.2
	c0.8,0.8,1.9,1.7,3,2.3c1.1,0.7,2.3,1.1,3.7,1.5l2.9,0.5l3,0.1l2.3-0.1l3.6-0.7c1.2-0.3,2.3-0.8,3.3-1.4c1.2-0.7,2.3-1.5,3.2-2.5
	c1-1.1,1.6-2.2,1.9-3.4C436.1,272,436.1,271.5,436.1,271.1L436.1,271.1z M421.8,261.7h0.4l1.6,0.3c0.7,0.1,1.4,0.3,2.1,0.4
	c0.8,0.3,1.5,0.5,2.2,1c0.5,0.3,1,0.7,1.2,1.1l0.4,0.8v0.4c0,0.4-0.1,0.8-0.4,1.2c-0.3,0.4-0.7,0.8-1.2,1.1c-0.4,0.3-1,0.5-1.5,0.7
	l-2.1,0.5v-0.5l-0.4-2.9l-0.1-0.5c-0.3-0.8-0.5-1.5-0.8-2.1c-0.3-0.5-0.5-1-0.8-1.2L421.8,261.7L421.8,261.7z M422.3,264.4l0.3,0.7
	l0.7,1.9l0.3,1.8v0.7h-0.1l-2.1,0.1l-1.9-0.1l-0.5-0.1l0.3-2.5l0.5-1.6c0.1-0.4,0.4-0.8,0.7-1.2l0.3-0.3c0.1-0.1,0.4-0.3,0.7-0.3
	c0.3,0,0.4,0.1,0.5,0.3L422.3,264.4L422.3,264.4z M417.6,270.7l0.1,3.3c0.1,0.8,0.3,1.7,0.4,2.5l0.7,2.2c0.3,0.5,0.5,1,0.8,1.4h-0.1
	c-1.2-0.1-2.5-0.4-3.4-0.8c-1.6-0.5-3-1.4-4.1-2.3c-1-0.8-1.6-1.6-2.1-2.6c-0.4-0.8-0.7-1.6-0.8-2.5v-1.2c0-0.5,0.1-1.1,0.3-1.6
	l0.1-0.3c0.1-0.5,0.4-1.1,0.8-1.6s0.8-1.1,1.4-1.7l-0.1,0.3c-0.1,0.4-0.1,1,0,1.4l0.3,0.5c0.3,0.4,0.7,0.8,1.1,1.2l0.8,0.5
	c0.7,0.4,1.5,0.7,2.3,1L417.6,270.7L417.6,270.7z M419.2,274.9c-0.1-0.8-0.3-1.6-0.3-2.3V271c1.6,0.3,3.2,0.3,4.8,0v0.1
	c0,0.8,0,1.8-0.1,2.5l-0.3,1.8l-0.5,1.6l-0.5,1l-0.4,0.3l-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.3c-0.3-0.3-0.4-0.5-0.7-1
	C419.7,276.6,419.4,275.9,419.2,274.9L419.2,274.9z M424.7,270.8l2.2-0.5c1.1-0.3,1.9-0.8,2.8-1.4c0.6-0.4,1-0.8,1.1-1.2l0.3-0.7
	l0.1-1l-0.1-0.5v-0.1l0.3,0.3l1.1,1.4l0.3,0.5l0.5,1.4l0.3,1.6c0,0.7,0,1.2-0.1,1.9c-0.3,1.1-0.8,2.2-1.5,3.2s-1.6,1.6-2.6,2.3
	c-1.2,0.7-2.5,1.2-4,1.6l-2.8,0.4l0.4-0.5c0.4-0.7,0.7-1.4,1-2.2c0.3-1,0.4-1.9,0.5-2.9L424.7,270.8L424.7,270.8z M420.5,261.7
	l-0.4,0.4c-0.3,0.3-0.5,0.7-0.8,1.1c-0.3,0.5-0.5,1.2-0.8,2.1l-0.1,0.3l-0.5,3.7v0.1h-0.1l-1.8-0.5l-0.8-0.4
	c-0.7-0.3-1.2-0.7-1.5-1.1l-0.3-0.4c-0.3-0.3-0.4-0.7-0.4-1v-0.7l0.3-0.7c0.3-0.4,0.7-0.8,1.1-1.1c0.8-0.5,1.7-1,2.8-1.2
	c0.7-0.3,1.5-0.3,2.2-0.4L420.5,261.7L420.5,261.7z"
          />
          <path
            fill="#656565"
            d="M422.2,261.7h-0.4l0.5,0.3c0.3,0.3,0.7,0.7,0.8,1.2c0.3,0.5,0.5,1.2,0.8,2.1l0.1,0.5l0.4,2.9v0.5l2.1-0.5
	c0.5-0.3,1-0.4,1.5-0.7c0.5-0.3,1-0.7,1.2-1.1c0.3-0.4,0.4-0.8,0.4-1.2v-0.4l-0.4-0.8c-0.3-0.4-0.7-0.8-1.2-1.1
	c-0.5-0.4-1.2-0.7-2.2-1c-0.7-0.3-1.4-0.4-2.1-0.4L422.2,261.7L422.2,261.7z M422.6,264.9l-0.3-0.7l-0.4-0.5
	c-0.1-0.1-0.4-0.3-0.5-0.3s-0.4,0-0.7,0.3l-0.3,0.3c-0.3,0.3-0.4,0.7-0.7,1.2l-0.5,1.6l-0.3,2.5l0.5,0.1l1.9,0.1l2.1-0.1h0.1v-0.7
	l-0.3-1.8L422.6,264.9L422.6,264.9z M417.6,270.8l-1.5-0.4c-0.8-0.3-1.6-0.5-2.3-1l-0.8-0.5c-0.4-0.4-0.8-0.8-1.1-1.2l-0.3-0.5
	c-0.1-0.4-0.1-1,0-1.4l0.1-0.3c-0.5,0.5-1,1.1-1.4,1.7l-0.8,1.6l-0.1,0.3c-0.1,0.5-0.3,1.1-0.3,1.6v1.2c0.1,0.8,0.4,1.6,0.7,2.5
	c0.5,1,1.2,1.8,2.1,2.6c1.2,1,2.6,1.8,4.1,2.3c1.1,0.4,2.2,0.7,3.4,0.8h0.3c-0.3-0.3-0.5-0.8-0.8-1.4l-0.7-2.2
	c-0.1-0.8-0.4-1.7-0.4-2.5L417.6,270.8L417.6,270.8z M418.9,272.6c0,0.8,0.1,1.5,0.3,2.3c0.1,1,0.4,1.6,0.8,2.3
	c0.3,0.4,0.4,0.7,0.7,1c0.1,0.1,0.3,0.1,0.4,0.3l0.4-0.1l0.4-0.3l0.5-1l0.5-1.6l0.3-1.8c0.1-0.8,0.1-1.6,0.1-2.5v-0.1
	c-1.6,0.1-3.2,0.1-4.8,0L418.9,272.6L418.9,272.6z"
          />
          <path
            fill="#656565"
            d="M424.8,270.8C424.7,270.8,424.7,270.8,424.8,270.8l-0.3,3.7c-0.1,1-0.3,1.9-0.5,2.9c-0.3,0.8-0.5,1.5-1,2.2
	l-0.4,0.5l2.8-0.4c1.4-0.4,2.8-1,4-1.6c1.1-0.7,1.9-1.5,2.6-2.3c0.8-1,1.4-2.1,1.5-3.2c0.1-0.7,0.1-1.2,0.1-1.9l-0.3-1.6l-0.5-1.4
	l-0.3-0.5l-1.1-1.4l-0.3-0.3v0.1l0.1,0.5l-0.1,1l-0.3,0.7c-0.3,0.4-0.7,0.8-1.1,1.2c-0.7,0.5-1.7,1-2.8,1.4L424.8,270.8L424.8,270.8
	z M420.5,261.7l-1.2,0.1c-0.8,0.1-1.5,0.3-2.2,0.4c-1.1,0.3-2.1,0.7-2.8,1.2c-0.5,0.3-0.8,0.7-1.1,1.1l-0.3,0.7v0.7
	c0,0.3,0.1,0.7,0.4,1l0.3,0.4c0.4,0.4,0.8,0.8,1.5,1.1l0.8,0.4l1.8,0.5h0.1v-0.1l0.5-3.7l0.1-0.3c0.1-0.8,0.4-1.5,0.8-2.1
	c0.3-0.5,0.5-0.8,0.8-1.1L420.5,261.7L420.5,261.7z M438.3,264.1h15.3v-2.6h-17.2 M438.3,287.5h-34.5v4.4c0.3,1.8,1.2,2.8,2.9,2.9
	h28.8c1.6-0.1,2.6-1.1,2.9-2.9L438.3,287.5L438.3,287.5z M435.7,283.7V281l-0.5,0.8c-0.8,0.8-1.8,1.5-3.3,1.9l-0.7,0.1h-20.5
	l-0.7-0.1c-1.4-0.4-2.5-1-3.3-1.9l-0.5-0.8v6.5h29.3v-2.1L435.7,283.7L435.7,283.7z M475.4,283.7v-4.5h-10.3v8.1l10.2-0.3l0.1-1.6
	 M581.1,325.7c0.1-5.5-0.4-9.2-1.8-11.1l-1.4,8.7c-0.7,4.3-1.8,8.7-3.7,13.2c-0.8,2.2-1.8,4.4-2.9,6.6l-3.7,1.2
	c-0.1,3-0.3,5.8-0.4,8.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.4-0.1,0.6c-0.1,0.1-0.3,0.3-0.4,0.3h-0.1
	c-0.3,2.3-0.4,4.5-0.8,6.7l0.3,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.3-0.4,0.3h-0.1
	c-0.4,2.3-1,4.7-1.5,6.7c-1,2.6-2.6,4.1-5,4.7c-9.8,4.1-19.9,7.4-30.5,9.8l-1,0.3v38.4c0.3,10.5,3.4,16.1,9.9,17.1l16.6,0.5
	l16.6-0.5c6.3-1,9.6-6.6,9.9-17.1v-96.4L581.1,325.7L581.1,325.7z M262.9,314.7c-1.4,1.9-1.9,5.6-1.8,11.1v96.6
	c0.3,10.5,3.6,16.1,9.9,17.1c4.3,0.7,9.8,0.8,16.6,0.5l16.6-0.5c6.3-1,9.6-6.6,9.9-17.1V384l-1-0.3c-10.5-2.3-20.6-5.6-30.4-9.8
	c-2.3-0.5-4-2.2-5-4.7c-0.5-2.1-1.1-4.3-1.5-6.7h-0.3c-0.1,0-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.3,0.3-0.4
	l0.3-0.1c-0.3-2.1-0.5-4.4-0.8-6.7h-0.1c-0.1,0-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.3,0.3-0.4
	c0.1-0.1,0.3-0.1,0.4-0.1c-0.3-2.6-0.4-5.5-0.4-8.5l-3.7-1.2c-1.1-2.3-2.1-4.5-2.9-6.6c-1.8-4.7-3-9.1-3.7-13.2
	C263.7,320.5,263.3,317.4,262.9,314.7L262.9,314.7z"
          />
          <path
            fill="#EBEBEB"
            d="M565.5,256.1c-0.4-0.8-0.8-1.5-1.4-2.1l-0.1-0.1c-0.7-0.6-1.4-1.1-2.2-1.4l-3.7-1.8l-2.1-0.8
	c-1.1-0.3-2.3-0.6-3.4-0.6l-6.6,0.1l-3,0.3l-31.1,3.4l-1.1,0.3c-1.4,0.4-2.5,1-3.2,1.8c-0.7,0.7-1,1.5-0.8,2.5
	c0-1.4,1.1-2.2,3.2-2.6l2.9-0.4c14.2-1.9,27.2-2.9,38.9-3.2h1.1c2.6-0.1,5.1,0.6,7.2,2.2c1.2,0.6,2.1,1.5,2.5,2.6
	c1.4-0.3,2.3,0.3,2.5,1.9v5.8c0,1-0.7,1.6-1.9,2.1v0.5l-2.9,8.1c-0.3,0.8-1.1,1.2-2.5,1.5l-13.2,1.6l-2.9,0.4l-1.9-2.5
	c-1.1-1.5-2.5-2.1-4.5-1.9l-20.2,0.3c0.7,0.4,1.5,0.8,2.6,1h18.2c1.7,0,2.9,0.8,3.9,2.3l1.2,1.8l0.3,0.4c9.6-0.3,16.2-1.1,19.5-2.5
	c1.8-1,3.3-2.5,4.3-4.3c1-1.5,1.5-3.3,1.8-5.4C567,262.4,566.8,258.7,565.5,256.1L565.5,256.1z"
          />
          <path
            fill="#EBEBEB"
            d="M562.6,266c1.2-0.3,1.9-1,1.9-2.1v-5.8c-0.1-1.5-1-2.2-2.5-1.9C561.7,259.7,561.8,262.8,562.6,266L562.6,266
	z"
          />
          <path
            fill="#EBEBEB"
            d="M562.6,266.5V266c-0.8-3.2-1.1-6.3-0.5-9.8c-0.4-1.1-1.2-1.9-2.5-2.6c-2.1-1.5-4.4-2.2-7-2.2h-1.1
	c-11.8,0.3-24.8,1.2-38.9,3.2l-2.9,0.4c-2.1,0.3-3.2,1.2-3.2,2.6l0.1,0.7c1.1,3,2.3,5.8,3.6,8.4l3.9,6.7l0.5,0.5l20.2-0.3
	c1.9-0.3,3.4,0.4,4.5,1.9l1.9,2.5l2.9-0.4l-1.7-2.2l-1.8-2.2c-1-1.1-2.2-1.6-4-1.6l-9.5,0.1h-12.5c-0.8-1.2-1.5-2.3-2.2-3.6l-2.3-5
	l-1.6-4.5l8.5-1c1.7-0.1,2.6-0.7,2.9-1.6l23.5-1.8l7.8-0.7h2.2c1.5,0,2.8,0.3,3.9,0.6l0.5,0.1c1.1,0.6,1.8,1.5,1.9,2.7l0.3,7.7
	c0,0.7,0,1.2-0.1,1.8c-0.1,0.5-0.3,1.1-0.5,1.6l-1.4,3c-0.1,0.4-0.5,0.8-1,1.2c-0.5,0.4-1.4,0.7-2.3,1l-12.4,1.9l1.7,2.2l13.2-1.6
	c1.4-0.3,2.1-0.8,2.5-1.5L562.6,266.5L562.6,266.5z M408.6,159.8h25c27.1,0,52.3,0.6,75.9,1.8c7.7,0.4,13.5,6.1,17.7,17.2
	c6.2,12.5,10.7,27,13.6,43.3l2.8,0.1c-8-27.4-15.1-48.7-21.3-64l-2.6-0.1c-26.3-0.8-52.4-1.2-78.4-1.4h-40.2
	c-26,0.1-52.1,0.6-78.4,1.4l-2.6,0.1c-6.3,15.3-13.5,36.6-21.3,64l2.8-0.1c2.9-16.2,7.4-30.7,13.6-43.3c4.1-11.1,10-16.9,17.7-17.2
	C356.2,160.4,381.5,159.8,408.6,159.8L408.6,159.8z"
          />
          <path
            fill="#EBEBEB"
            d="M433.6,159.8h-25c-27.1,0-52.4,0.6-75.9,1.8c-7.7,0.4-13.5,6.1-17.7,17.2c-6.2,12.5-10.7,27-13.6,43.3
	l10.9-0.4c1.2-0.8,3.4-1.2,6.9-1.6l10.5-0.6h183l11.3,0.7c2.9,0.4,5,0.8,6.1,1.5l10.9,0.4c-2.9-16.2-7.4-30.7-13.6-43.3
	c-4.1-11.1-10-16.9-17.7-17.2C485.9,160.4,460.6,159.8,433.6,159.8L433.6,159.8z M329.6,254.7l2.9,0.4c2.1,0.3,3.2,1.2,3.2,2.6
	c0-1-0.3-1.8-1-2.5c-0.7-0.7-1.8-1.2-3.2-1.8l-1.1-0.3l-31.1-3.4l-3-0.3l-6.6-0.1c-1.2,0-2.3,0.3-3.4,0.6c-0.7,0.3-1.4,0.4-2.1,0.8
	l-3.7,1.8c-0.8,0.3-1.5,0.8-2.2,1.4l-0.1,0.1c-0.5,0.6-1.1,1.2-1.5,2.1c-1.2,2.6-1.5,6.3-1,11.3c0.3,2.1,1,3.9,1.8,5.4
	c1,1.8,2.5,3.2,4.3,4.3c3.4,1.4,9.9,2.3,19.7,2.5l0.3-0.4l1.2-1.8c1-1.5,2.3-2.3,3.9-2.3h18.2c1.1-0.1,1.9-0.4,2.6-1l-20.2-0.3
	c-1.9-0.3-3.4,0.4-4.5,1.9l-1.9,2.5l-2.8-0.4l-13.2-1.6c-1.2-0.3-2.1-0.8-2.5-1.5l-3-8.1v-0.5c-1.2-0.3-1.9-1-1.9-2.1V258
	c0.3-1.5,1.1-2.2,2.5-1.9c0.4-1.1,1.2-1.9,2.5-2.6c2.1-1.5,4.4-2.2,7-2.2h1.1C302.4,251.8,315.4,252.9,329.6,254.7L329.6,254.7z"
          />
          <path
            fill="#EBEBEB"
            d="M330,267.5l1.9-4.1l1.7-4.5l-8.5-1c-1.6-0.1-2.6-0.7-2.9-1.6l-23.5-1.8c0.7,0.4,1.2,0.8,1.8,1.4l1,1.2
	l2.9-0.1c4.4-0.8,10.2-0.7,17.5,0.4l-6.9,14.4h12.5L330,267.5L330,267.5z M325.6,265.7c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1
	s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.5,0.4,1c0,0.4-0.1,0.7-0.4,1C326.3,265.6,326,265.7,325.6,265.7
	L325.6,265.7z"
          />
          <path
            fill="#EBEBEB"
            d="M324.6,265.3c0.3,0.3,0.5,0.4,1,0.4s0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1s-0.1-0.7-0.4-1
	c-0.3-0.3-0.5-0.4-1-0.4s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.5-0.4,1S324.4,265,324.6,265.3L324.6,265.3z M315,271.9l6.9-14.4
	c-7.2-1.2-13.1-1.4-17.5-0.4c-0.3,0.8-0.4,1.8-0.5,2.9c-0.1,2.2,0,4.7,0.5,7.4c0.3,1.4,0.7,2.9,1.1,4.5L315,271.9L315,271.9z
	 M312.7,263.2c0,0.3-0.1,0.4-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3c-0.3,0-0.4-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7
	c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3c0.3,0,0.4,0.1,0.7,0.3C312.5,262.8,312.7,263,312.7,263.2L312.7,263.2z"
          />
          <path
            fill="#EBEBEB"
            d="M312.4,263.9c0.1-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3
	c-0.3,0-0.4,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3
	C312,264.2,312.1,264.1,312.4,263.9L312.4,263.9z"
          />
          <path
            fill="#656565"
            d="M311.3,306l-1-1.4c-0.8-1-1.9-1.5-2.9-1.5h-13.3c-1.6,0-2.6,0.3-2.8,0.8v0.1c-0.8,3.9-1.1,8.4-0.8,13.5
	c0.3,1,1.4,1.9,3.3,2.8c1.9,0.7,4,1.2,6.1,1.7h0.4c2.1,0.4,3.9,0.3,5.1-0.6l1.8-1.2c2.8-1.9,4.1-3.7,4.4-5.4
	c0.3-2.2,0.4-4.5,0.3-6.9C311.7,307.1,311.6,306.4,311.3,306L311.3,306z M306.2,308.2c1.4,1.4,2.1,3,2.1,5s-0.7,3.6-2.1,5
	c-1.4,1.4-3,2.1-5,2.1s-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5s0.7-3.6,2.1-5c1.4-1.4,3-2.1,5-2.1C303.2,306.3,304.8,307,306.2,308.2
	L306.2,308.2z"
          />
          <path
            fill="#EBEBEB"
            d="M308.3,313.3c0-1.9-0.7-3.6-2.1-5c-1.4-1.4-3-2.1-5-2.1s-3.6,0.7-5,2.1c-1.4,1.4-2.1,3-2.1,5s0.7,3.6,2.1,5
	c1.4,1.4,3,2.1,5,2.1c1.9,0,3.6-0.7,5-2.1S308.3,315.2,308.3,313.3L308.3,313.3z M300.2,313.3c0-0.3,0.1-0.5,0.3-0.7
	c0.3-0.3,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.8,0.3c0.1,0.3,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3
	c-0.3,0-0.5-0.1-0.7-0.3C300.3,313.9,300.2,313.6,300.2,313.3L300.2,313.3z"
          />
          <path
            fill="#EBEBEB"
            d="M300.4,312.5c-0.3,0.3-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.8-0.3
	c0.1-0.3,0.3-0.4,0.3-0.8s-0.1-0.5-0.3-0.7c-0.3-0.3-0.4-0.3-0.8-0.3C300.9,312.2,300.7,312.4,300.4,312.5L300.4,312.5z
	 M303.9,259.8c0.1-1,0.3-1.9,0.5-2.9l-2.9,0.1c0.5,0.8,1.1,1.8,1.4,2.8c0.3,0.8,0.3,1.7,0.3,2.5c0,1.9-0.5,3.4-1.5,5l-1.2,1.4
	c-1.8,1.8-3.9,2.6-6.3,2.6c-1.2,0-2.3-0.3-3.4-0.7s-2.1-1.1-3-2.1c-1.8-1.8-2.6-3.9-2.6-6.3s0.8-4.7,2.6-6.5c1-0.8,1.9-1.5,3-1.9
	h0.1h-2.2c-2.5,1-3.7,2.5-3.7,4.3l-0.4,8.5l2.1,4.8l15.1,2.1c1-1.1,2.2-1.6,4-1.6c-0.4-1.6-0.8-3-1.1-4.5
	C303.9,264.5,303.6,262,303.9,259.8L303.9,259.8z"
          />
          <path
            fill="#EBEBEB"
            d="M302.8,259.8c-0.3-1-0.7-1.9-1.4-2.8l-1-1.2c-0.5-0.6-1.1-1.1-1.8-1.4l-7.8-0.7h-0.1c-1.1,0.4-2.1,1.1-3,1.9
	c-1.8,1.8-2.6,4-2.6,6.5s0.8,4.7,2.6,6.3c1,1,1.9,1.6,3,2.1s2.2,0.7,3.4,0.7c2.5,0,4.7-0.8,6.3-2.6l1.2-1.4c1-1.4,1.5-3,1.5-5
	C303.1,261.5,303.1,260.6,302.8,259.8L302.8,259.8z M298.9,257.5c0.8,0.7,1.2,1.5,1.6,2.5c0.3,0.8,0.4,1.6,0.4,2.5
	c0,1.9-0.7,3.6-2.1,5c-1.4,1.4-3,2.1-5,2.1c-1.2,0-2.3-0.3-3.4-0.8c-0.5-0.3-1.1-0.7-1.5-1.2c-1.4-1.4-2.1-3-2.1-5s0.7-3.6,2.1-5
	c0.4-0.4,1-0.8,1.5-1.2c1-0.6,2.2-0.8,3.4-0.8C296,255.4,297.7,256.1,298.9,257.5L298.9,257.5z"
          />
          <path
            fill="#EBEBEB"
            d="M300.6,259.8c-0.3-0.8-0.8-1.6-1.6-2.5c-1.4-1.4-3-2.1-5-2.1c-1.2,0-2.3,0.3-3.4,0.8
	c-0.5,0.3-1.1,0.7-1.5,1.2c-1.4,1.4-2.1,3-2.1,5s0.7,3.6,2.1,5c0.4,0.4,1,0.8,1.5,1.2c1,0.5,2.2,0.8,3.4,0.8c1.9,0,3.6-0.7,5-2.1
	c1.4-1.4,2.1-3,2.1-5C301,261.5,300.9,260.6,300.6,259.8L300.6,259.8z M298,258.4c0.4,0.4,0.8,1,1.1,1.5c0.4,0.8,0.5,1.6,0.5,2.5
	c0,1.5-0.5,2.9-1.6,4l-1.4,1c-0.8,0.4-1.6,0.7-2.6,0.7s-1.8-0.3-2.6-0.7h-0.1l-0.7-0.4l-0.5-0.5c-1.1-1.1-1.6-2.3-1.6-4
	s0.5-2.9,1.6-4l0.5-0.4c1-0.8,2.1-1.1,3.4-1.1C295.6,256.8,296.9,257.3,298,258.4L298,258.4z"
          />
          <path
            fill="#EBEBEB"
            d="M299.1,259.8c-0.3-0.5-0.7-1-1.1-1.5c-1.1-1.1-2.3-1.6-4-1.6c-1.2,0-2.5,0.4-3.4,1.1l-0.5,0.4
	c-1.1,1.1-1.6,2.5-1.6,4s0.5,2.9,1.6,4l0.5,0.5l0.7,0.4h0.1c0.7,0.4,1.6,0.7,2.6,0.7s1.8-0.3,2.6-0.7l1.4-1c1.1-1.1,1.6-2.3,1.6-4
	C299.6,261.5,299.5,260.6,299.1,259.8L299.1,259.8z M293.3,261.5c0.1-0.1,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3c0.1,0.3,0.3,0.4,0.3,0.7
	c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7C293,261.9,293.1,261.6,293.3,261.5L293.3,261.5
	z"
          />
          <path
            fill="#EBEBEB"
            d="M294,261.2c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.3-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3
	s0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C294.5,261.2,294.2,261.2,294,261.2L294,261.2z"
          />
          <path
            fill="#EBEBEB"
            d="M284.6,254.4l-0.5,0.1c-1.1,0.6-1.8,1.5-1.9,2.8l-0.3,7.7c0,0.7,0,1.2,0.1,1.8c0.1,0.5,0.3,1.1,0.5,1.6
	l1.4,3c0.1,0.4,0.5,0.8,1,1.2c0.5,0.4,1.4,0.7,2.3,1l12.4,1.9l1.8-2.2l-15.1-2.1l-2.1-4.8l0.4-8.5c-0.1-1.8,1.1-3.2,3.7-4.3
	C287.1,253.9,285.7,254,284.6,254.4L284.6,254.4z M279.9,256.2c-1.4-0.3-2.3,0.3-2.5,1.9v5.8c0,1,0.7,1.6,1.9,2.1
	C280.4,262.8,280.5,259.7,279.9,256.2L279.9,256.2z M558,254.6l-0.5-0.1c-1.1-0.3-2.5-0.6-3.9-0.6c2.5,1,3.7,2.5,3.7,4.3l0.4,8.5
	l-2.1,4.8l-15.1,2.1l1.8,2.2l12.4-1.9c1-0.3,1.8-0.5,2.3-1c0.5-0.3,0.8-0.7,1-1.2l1.4-3c0.3-0.5,0.4-1.1,0.5-1.6s0.1-1.2,0.1-1.8
	l-0.3-7.7C559.7,256.1,559.1,255.1,558,254.6L558,254.6z"
          />
          <path
            fill="#EBEBEB"
            d="M557.3,258.2c0.1-1.8-1.1-3.2-3.7-4.3h-2.2h0.1c1.1,0.4,2.1,1.1,3,1.9c1.8,1.8,2.6,4,2.6,6.5
	c0,2.5-0.8,4.7-2.6,6.3c-1,1-1.9,1.6-3,2.1c-1.1,0.4-2.2,0.7-3.4,0.7c-2.5,0-4.7-0.8-6.3-2.6c-0.4-0.4-0.8-1-1.2-1.4
	c-1-1.4-1.5-3-1.5-5c0-0.8,0.1-1.8,0.3-2.5c0.3-1,0.7-1.9,1.4-2.8l-2.9-0.1c0.3,0.8,0.4,1.8,0.6,2.9c0.1,2.2,0,4.7-0.5,7.4l-1.1,4.5
	c1.8,0,3,0.5,4,1.6l15.1-2.1l2.1-4.8L557.3,258.2L557.3,258.2z"
          />
          <path
            fill="#EBEBEB"
            d="M551.5,253.9l-8,0.6c-0.7,0.4-1.2,0.8-1.8,1.4c-0.4,0.4-0.7,0.8-1,1.2c-0.5,0.8-1.1,1.8-1.4,2.8
	c-0.3,0.8-0.3,1.7-0.3,2.5c0,1.9,0.5,3.4,1.5,5c0.3,0.5,0.8,1,1.2,1.4c1.8,1.8,3.9,2.6,6.3,2.6c1.2,0,2.3-0.3,3.4-0.7s2.1-1.1,3-2.1
	c1.8-1.8,2.6-3.9,2.6-6.3s-0.8-4.7-2.6-6.5C553.6,255,552.6,254.4,551.5,253.9L551.5,253.9z M541.5,259.8c0.3-0.8,0.8-1.6,1.7-2.5
	c1.4-1.4,3-2.1,5-2.1c1.2,0,2.3,0.3,3.4,0.8l1.5,1.2c1.4,1.4,2.1,3,2.1,5s-0.7,3.6-2.1,5l-1.5,1.2c-1,0.5-2.2,0.8-3.4,0.8
	c-1.9,0-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5C541,261.5,541.2,260.6,541.5,259.8L541.5,259.8z"
          />
          <path
            fill="#EBEBEB"
            d="M543.1,257.5c-0.8,0.7-1.4,1.5-1.7,2.5c-0.3,0.8-0.4,1.6-0.4,2.5c0,1.9,0.7,3.6,2.1,5c1.4,1.4,3,2.1,5,2.1
	c1.2,0,2.3-0.3,3.4-0.8l1.5-1.2c1.4-1.4,2.1-3,2.1-5s-0.7-3.6-2.1-5l-1.5-1.2c-1-0.6-2.2-0.8-3.4-0.8
	C546.1,255.4,544.5,256.1,543.1,257.5L543.1,257.5z M543.1,259.8c0.3-0.5,0.7-1,1.1-1.5c1.1-1.1,2.5-1.6,4-1.6
	c1.2,0,2.5,0.4,3.4,1.1l0.5,0.4c1.1,1.1,1.7,2.5,1.7,4s-0.5,2.9-1.7,4l-0.5,0.5l-0.7,0.4h-0.1c-0.8,0.4-1.7,0.7-2.6,0.7
	s-1.8-0.3-2.6-0.7l-1.4-1c-1.1-1.1-1.7-2.3-1.7-4C542.6,261.5,542.7,260.6,543.1,259.8L543.1,259.8z"
          />
          <path
            fill="#EBEBEB"
            d="M544.1,258.4c-0.4,0.4-0.8,1-1.1,1.5c-0.4,0.8-0.5,1.6-0.5,2.5c0,1.5,0.5,2.9,1.7,4l1.4,1
	c0.8,0.4,1.7,0.7,2.6,0.7s1.8-0.3,2.6-0.7h0.1l0.7-0.4l0.5-0.5c1.1-1.1,1.7-2.3,1.7-4s-0.5-2.9-1.7-4l-0.5-0.4
	c-1-0.8-2.1-1.1-3.4-1.1C546.5,256.8,545.2,257.3,544.1,258.4L544.1,258.4z M548.2,261.2c0.3,0,0.6,0.1,0.7,0.3
	c0.1,0.3,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
	C547.6,261.2,547.9,261.2,548.2,261.2L548.2,261.2z"
          />
          <path
            fill="#EBEBEB"
            d="M548.9,261.5c-0.1-0.1-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.1,0.3-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
	c0.1,0.1,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7C549.2,261.9,549,261.6,548.9,261.5L548.9,261.5z M541.7,256
	c0.5-0.6,1.1-1.1,1.8-1.4l-23.5,1.8c-0.3,1-1.2,1.5-2.9,1.6l-8.5,1l1.6,4.5l2.3,5c0.7,1.2,1.4,2.3,2.2,3.6h12.5l-6.9-14.4
	c7.2-1.2,13.1-1.4,17.5-0.4l2.9,0.1C541,256.6,541.3,256.2,541.7,256L541.7,256z M517.8,264.4c0,0.4-0.1,0.7-0.4,1
	c-0.3,0.3-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,1,0.4
	S517.8,263.9,517.8,264.4L517.8,264.4z"
          />
          <path
            fill="#EBEBEB"
            d="M517.4,265.3c0.3-0.3,0.4-0.5,0.4-1s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4
	s-0.4,0.5-0.4,1c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4C516.8,265.7,517.2,265.6,517.4,265.3z M538.3,259.8
	c-0.1-1-0.3-1.9-0.5-2.9c-4.4-0.8-10.3-0.7-17.5,0.4l6.9,14.4l9.5-0.1l1.1-4.5C538.3,264.5,538.4,262,538.3,259.8L538.3,259.8z
	 M529.5,263.2c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3s0.4,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7c0,0.3-0.1,0.4-0.3,0.7
	c-0.1,0.1-0.4,0.3-0.7,0.3s-0.4-0.1-0.7-0.3C529.6,263.7,529.5,263.5,529.5,263.2L529.5,263.2z"
          />
          <path
            fill="#EBEBEB"
            d="M529.8,262.6c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3s0.4-0.1,0.7-0.3
	c0.1-0.1,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3C530.2,262.4,529.9,262.4,529.8,262.6L529.8,262.6z
	 M547.9,313.3c0-1.9-0.7-3.6-2.1-5c-1.4-1.4-3-2.1-5-2.1s-3.6,0.7-5,2.1c-1.4,1.4-2.1,3-2.1,5s0.7,3.6,2.1,5c1.4,1.4,3,2.1,5,2.1
	s3.6-0.7,5-2.1C547.2,316.9,547.9,315.2,547.9,313.3L547.9,313.3z M541.7,312.5c0.1,0.3,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.8
	c-0.3,0.3-0.4,0.3-0.7,0.3s-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8s0.1-0.5,0.3-0.7c0.1-0.3,0.4-0.3,0.8-0.3
	C541.2,312.2,541.5,312.4,541.7,312.5L541.7,312.5z"
          />
          <path
            fill="#EBEBEB"
            d="M542,313.3c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.4-0.3-0.7-0.3s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.7
	s0.1,0.5,0.3,0.8s0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3C541.9,313.9,542,313.6,542,313.3L542,313.3z M388.3,258.2
	c-0.3-0.8-1-1.2-1.9-1.1h-9.5v1.1L388.3,258.2L388.3,258.2z M388.6,259.8c0-0.7,0-1.2-0.3-1.6h-11.3v1.6H388.6h-11.6v1.6h11.6V259.8
	z M377.1,270.4v1.8h7.3v-1.8 M377.1,268.8v1.6h7.3v-1.6 M377.1,264.1v1.2h7.3v-1.2 M377.1,265.3v1.6h7.3v-1.6"
          />
          <path
            fill="#EBEBEB"
            d="M377.1,267v1.8h7.3V267 M384.4,270.4v1.6h16.8c-0.3-0.5-0.5-1.1-0.7-1.6H384.4z M388.6,264.1h-4.3v1.2h18.7
	l0.8-1.2 M384.4,267v1.8h16.2l0.4-0.5l0.8-1.2 M384.4,265.3v1.6h17.5l1.2-1.6"
          />
          <path
            fill="#EBEBEB"
            d="M384.4,268.8v1.6h16.1c-0.1-0.5,0-1.1,0.1-1.6H384.4z M366.6,261.5v-1.6h-5l-0.7,1.6 M361.7,259.8h5v-1.6h-4
	c-0.3,0.3-0.5,0.5-0.7,1L361.7,259.8L361.7,259.8z M366.6,258.2v-1.1c-1.8-0.1-3.2,0.3-4,1.1L366.6,258.2L366.6,258.2z M359.7,264.1
	h-8v1.2H364v-1.2 M351.8,270.4v1.8H364v-1.8 M351.8,268.8v1.6H364v-1.6"
          />
          <path
            fill="#EBEBEB"
            d="M351.8,267v1.8H364V267 M351.8,265.3v1.6H364v-1.6 M366.7,267l-0.1-1.6H364v1.6 M366.6,265.3v-1.2H364v1.2
	 M364,268.8v1.6h2.8v-1.6 M364,270.4v1.8h2.8v-1.8"
          />
          <path
            fill="#EBEBEB"
            d="M366.7,268.8V267H364v1.8 M366.7,280.2v-1H364v1 M364,280.2v-1h-12.2v7.2v-7.2h-1.9l-0.1,7.3l6.5,0.3
	l7.8,0.3v-1.6h-12h12v-1.7h-12h12v-1.9h-12h12L364,280.2l-12,0 M364,285.4v1.6h2.8v-1.6 M364,283.7v1.6h2.8v-1.6 M364,281.8v1.9h2.8
	v-1.9 M366.7,281.8v-1.6H364v1.6 M384.4,283.7v-1.9h-7.3v1.9 M384.4,281.8v1.9h22v-1.9 M384.4,285.4v1.9l4.3,0.1h17.7v-2.1
	 M384.4,287.3v-1.9h-7.3v1.9 M384.4,283.7v1.6h22v-1.6 M384.4,285.4v-1.6h-7.3v1.6 M384.4,279.2v1h21.5l-0.7-1 M384.4,280.2v-1h-7.3
	v1 M384.4,280.2v1.6h22v-0.7l-0.5-1 M384.4,281.8v-1.6h-7.3v1.6 M480.1,259.1l-0.7-1h-3.9v1.6h5 M481.2,261.5l-0.7-1.6h-5l-0.1,1.6
	 M482.4,264.1h-4.3v1.2h12.2v-1.2 M478.2,265.3v-1.2h-2.8v1.2 M478.2,270.4v1.8h12.2v-1.8 M478.2,272.2v-1.8h-2.8v1.8 M478.2,267
	v-1.6h-2.8v1.6 M478.2,265.3v1.6h12.2v-1.6"
          />
          <path
            fill="#EBEBEB"
            d="M478.2,268.8V267h-2.8v1.8 M478.2,267v1.8h12.2V267"
          />
          <path
            fill="#EBEBEB"
            d="M478.2,268.8v1.6h12.2v-1.6 M478.2,270.4v-1.6h-2.8v1.6 M490.4,286.6v-1.2h-12.2v1.6l7.8-0.3 M490.4,285.4
	v-1.6h-12.2v1.6 M490.4,283.7v-1.9h-12.2v1.9 M490.4,281.8v-1.6h-12.2v1.6 M490.4,280.2v-1h-12.2v1 M478.2,283.7v-1.9h-2.8v1.9
	 M478.2,285.4v-1.6h-2.8v1.6 M478.2,287v-1.6h-2.8l-0.1,1.6 M478.2,280.2v-1h-2.8v1 M478.2,281.8v-1.6h-2.8v1.6 M475.6,257.1v1.1h4
	C478.6,257.3,477.3,256.9,475.6,257.1z M465.1,261.5v-1.6h-11.3h11.3v-1.6h-11.3c-0.1,0.4-0.3,1-0.3,1.6v1.6H465.1L465.1,261.5z
	 M465.1,258.2v-1.1h-9.5c-1,0-1.6,0.3-1.9,1.1L465.1,258.2L465.1,258.2z M465.1,267v-1.6h-7.3v1.6 M465.1,265.3v-1.2h-7.3v1.2
	 M457.8,265.3v-1.2h-19.5l0.8,1.2 M457.8,267v-1.6h-18.7l1.2,1.6 M457.8,272.2v-1.6h-16.1c-0.1,0.5-0.3,1.1-0.7,1.6H457.8z
	 M457.8,270.4v1.8h7.3v-1.8 M457.8,270.4v-1.6h-16.4l0.3,1v0.7 M457.8,268.8v1.6h7.3v-1.6"
          />
          <path
            fill="#EBEBEB"
            d="M465.1,268.8V267h-7.3v1.8 M457.8,268.8V267h-17.5l0.8,1.2l0.3,0.5 M436.4,280.2l-0.7,1v0.7h22.1v-1.6
	 M436.9,279.2l-0.5,1h21.5v-1 M435.7,287.5h17.9l4.3-0.1v-1.9h-22.1 M435.7,281.8v1.9h22.1v-1.9 M435.7,283.7v1.6h22.1v-1.6
	 M465.1,280.2v-1h-7.3v1 M457.8,285.4v1.9h7.3v-1.9 M457.8,283.7v1.6h7.3v-1.6 M457.8,281.8v1.9h7.3v-1.9 M465.1,281.8v-1.6h-7.3
	v1.6 M332.5,255.1l-2.9-0.4c-14.2-1.9-27.2-2.9-38.9-3.2h-1.1c-2.6-0.1-5,0.6-7,2.2c-1.2,0.6-2.1,1.5-2.5,2.6
	c0.5,3.3,0.3,6.6-0.5,9.8v0.5l3,8.1c0.3,0.8,1.1,1.2,2.5,1.5l13.2,1.6l1.6-2.2l-12.4-1.9c-1-0.3-1.8-0.5-2.3-1
	c-0.5-0.3-0.8-0.7-1-1.2l-1.4-3c-0.3-0.5-0.4-1.1-0.5-1.6c-0.1-0.5-0.1-1.2-0.1-1.8l0.3-7.7c0.1-1.2,0.8-2.2,1.9-2.8l0.5-0.1
	c1.1-0.3,2.5-0.6,3.9-0.6h2.2l7.8,0.7l23.5,1.8c0.3,1,1.2,1.5,2.9,1.6l8.5,1l-1.6,4.5l-1.9,4.1l-2.6,4.4H315l-9.5-0.1
	c-1.8,0-3,0.5-4,1.6l-1.8,2.2l-1.6,2.2l2.8,0.4l1.9-2.5c1-1.5,2.5-2.1,4.5-1.9l20.2,0.3l0.5-0.5l3.9-6.7c1.2-2.6,2.5-5.4,3.6-8.4
	l0.1-0.7C335.7,256.4,334.6,255.4,332.5,255.1L332.5,255.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M562.6,266c1.2-0.3,1.9-1,1.9-2.1v-5.8c-0.1-1.5-1-2.2-2.5-1.9 M544.1,278
	l13.2-1.7c1.4-0.3,2.1-0.8,2.5-1.5l2.9-8.1v-0.5 M540.9,222.1c-2.9-16.2-7.4-30.7-13.6-43.3c-4.1-11.1-10-16.9-17.7-17.2
	c-23.5-1.2-48.8-1.8-75.9-1.8h-25c-27.1,0-52.4,0.6-75.9,1.8c-7.7,0.4-13.5,6.1-17.7,17.2c-6.2,12.5-10.7,27-13.6,43.3 M562.1,256.2
	c-0.5,3.3-0.3,6.6,0.5,9.8 M517.4,265.3c-0.3,0.3-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4s-0.4-0.5-0.4-1s0.1-0.7,0.4-1
	c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4s0.4,0.5,0.4,1S517.7,265,517.4,265.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.5,254.4l-23.5,1.8c-0.3,1-1.2,1.5-2.9,1.6l-8.5,1l1.6,4.5l2.3,5
	c0.7,1.2,1.4,2.3,2.2,3.6h12.5 M540.6,273.4l1.7,2.2 M542.3,275.6l1.8,2.3 M514.5,274.1l20.2-0.3c1.9-0.3,3.4,0.4,4.5,1.9l1.9,2.5
	l2.9-0.4 M527.1,271.9l9.5-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M527.1,271.9l-6.9-14.4c7.2-1.2,13.1-1.4,17.5-0.4 M536.6,271.8
	c1.8,0,3,0.5,4,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M529.8,262.6c0.1-0.1,0.4-0.3,0.7-0.3s0.4,0.1,0.7,0.3
	c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.4-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3s-0.4-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7
	C529.5,263,529.6,262.8,529.8,262.6L529.8,262.6z M537.7,257.1c0.3,0.8,0.4,1.8,0.5,2.9c0.1,2.2,0,4.7-0.5,7.4l-1.1,4.5
	 M540.6,257.1h-2.9 M553.6,253.9h-2.2 M551.4,253.9l-7.8,0.6 M540.6,257.1c0.3-0.4,0.7-0.8,1-1.2c0.5-0.6,1.1-1.1,1.8-1.4
	 M548.9,261.5c0.1,0.3,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7
	c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3S548.6,261.2,548.9,261.5L548.9,261.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.1,257.5c1.4-1.4,3-2.1,5-2.1c1.2,0,2.3,0.3,3.4,0.8l1.5,1.2
	c1.4,1.4,2.1,3,2.1,5s-0.7,3.6-2.1,5l-1.5,1.2c-1,0.5-2.2,0.8-3.4,0.8c-1.9,0-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5
	c0-1,0.1-1.8,0.4-2.5C541.9,259,542.4,258.2,543.1,257.5L543.1,257.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M544.1,258.4c1.1-1.1,2.5-1.6,4-1.6c1.2,0,2.5,0.4,3.4,1.1l0.5,0.4
	c1.1,1.1,1.7,2.5,1.7,4s-0.5,2.9-1.7,4l-0.5,0.5l-0.7,0.4h-0.1c-0.8,0.4-1.7,0.7-2.6,0.7s-1.8-0.3-2.6-0.7l-1.4-1
	c-1.1-1.1-1.7-2.3-1.7-4c0-1,0.1-1.8,0.5-2.5C543.4,259.3,543.7,258.8,544.1,258.4L544.1,258.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M551.4,253.9c1.2,0.6,2.2,1.1,3.2,2.1c1.8,1.8,2.6,4,2.6,6.5
	s-0.8,4.7-2.6,6.3c-1,1-1.9,1.6-3,2.1c-1.1,0.4-2.2,0.7-3.4,0.7c-2.5,0-4.7-0.8-6.3-2.6c-0.4-0.4-0.8-1-1.2-1.4c-1-1.4-1.5-3-1.5-5
	c0-0.8,0.1-1.8,0.3-2.5c0.3-1,0.7-1.9,1.4-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M540.6,273.4l15.1-2.1l2.1-4.8l-0.4-8.5c0.1-1.8-1.1-3.2-3.7-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M562.1,256.2c-0.4-1.1-1.2-1.9-2.5-2.6c-2.1-1.5-4.4-2.2-7-2.2h-1.1
	c-11.8,0.3-24.8,1.2-38.9,3.2l-2.9,0.4c-2.1,0.3-3.2,1.2-3.2,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M542.3,275.6l12.4-1.9c1-0.3,1.8-0.5,2.3-1c0.5-0.3,0.8-0.7,1-1.2l1.4-3
	c0.3-0.5,0.4-1.1,0.5-1.6s0.1-1.2,0.1-1.8l-0.3-7.7c-0.1-1.2-0.8-2.2-1.9-2.8l-0.5-0.1c-1.1-0.3-2.5-0.6-3.9-0.6 M305.5,271.8
	l9.5,0.1 M299.8,275.6l1.8-2.2 M299.8,275.6l-1.6,2.3 M298.1,278l2.8,0.4l1.9-2.5c1-1.5,2.5-2.1,4.5-1.9l20.2,0.3 M279.9,256.2
	c0.5,3.3,0.3,6.6-0.5,9.8 M301.5,273.4l-15.1-2.1l-2.1-4.8l0.4-8.5c-0.1-1.8,1.1-3.2,3.7-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M279.4,266v0.5l3,8.1c0.3,0.8,1.1,1.2,2.5,1.5l13.2,1.6 M279.9,256.2
	c-1.4-0.3-2.3,0.3-2.5,1.9v5.8c0,1,0.7,1.6,1.9,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M288.5,253.9c-1.5,0-2.8,0.3-3.9,0.6l-0.5,0.1c-1.1,0.6-1.8,1.5-1.9,2.8
	l-0.3,7.7c0,0.7,0,1.2,0.1,1.8c0.1,0.5,0.3,1.1,0.5,1.6l1.4,3c0.1,0.4,0.5,0.8,1,1.2c0.5,0.4,1.4,0.7,2.3,1l12.4,1.9 M298.6,254.4
	l-7.8-0.6 M301.5,273.4c1-1.1,2.2-1.6,4-1.6 M293.3,261.5c-0.1,0.3-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3
	s0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3C293.7,261.2,293.4,261.2,293.3,261.5L293.3,261.5
	z M290.8,253.9h-2.3 M301.4,257.1l-1-1.2c-0.5-0.6-1.1-1.1-1.8-1.4 M304.3,257.1h-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298,258.4c-1.1-1.1-2.3-1.6-4-1.6c-1.2,0-2.5,0.4-3.4,1.1l-0.5,0.4
	c-1.1,1.1-1.6,2.5-1.6,4s0.5,2.9,1.6,4l0.5,0.5l0.7,0.4h0.1c0.7,0.4,1.7,0.7,2.6,0.7s1.8-0.3,2.6-0.7l1.4-1c1.1-1.1,1.6-2.3,1.6-4
	c0-1-0.1-1.8-0.5-2.5C298.8,259.3,298.4,258.8,298,258.4L298,258.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298.9,257.5c-1.4-1.4-3-2.1-5-2.1c-1.2,0-2.3,0.3-3.4,0.8
	c-0.5,0.3-1.1,0.7-1.5,1.2c-1.4,1.4-2.1,3-2.1,5s0.7,3.6,2.1,5c0.4,0.4,1,0.8,1.5,1.2c1,0.5,2.2,0.8,3.4,0.8c1.9,0,3.6-0.7,5-2.1
	c1.4-1.4,2.1-3,2.1-5c0-1-0.1-1.8-0.4-2.5C300.3,259,299.7,258.2,298.9,257.5L298.9,257.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M290.8,253.9c-1.2,0.6-2.2,1.1-3.2,2.1c-1.8,1.8-2.6,4-2.6,6.5
	s0.8,4.7,2.6,6.3c1,1,1.9,1.6,3,2.1c1.1,0.4,2.2,0.7,3.4,0.7c2.5,0,4.7-0.8,6.3-2.6l1.2-1.4c1-1.4,1.5-3,1.5-5
	c0-0.8-0.1-1.8-0.3-2.5c-0.3-1-0.7-1.9-1.4-2.8 M304.3,257.1c-0.3,0.8-0.4,1.8-0.5,2.9c-0.1,2.2,0,4.7,0.5,7.4
	c0.3,1.4,0.7,2.9,1.1,4.5 M315,271.9l6.9-14.4c-7.2-1.2-13.1-1.4-17.5-0.4 M325.6,265.7c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1
	s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.4,0.1,0.7,0.4,1
	C324.9,265.6,325.2,265.7,325.6,265.7L325.6,265.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M312.7,263.2c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3
	c-0.3,0-0.4,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7c0,0.3,0.1,0.4,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3c0.3,0,0.4-0.1,0.7-0.3
	C312.5,263.7,312.7,263.5,312.7,263.2L312.7,263.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315,271.9h12.5l2.6-4.4l1.9-4.1l1.6-4.5l-8.5-1c-1.6-0.1-2.6-0.7-2.9-1.6
	l-23.5-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M335.7,257.7c0-1.4-1.1-2.2-3.2-2.6l-2.9-0.4c-14.2-1.9-27.2-2.9-38.9-3.2
	h-1.1c-2.6-0.1-5,0.6-7,2.2c-1.2,0.6-2.1,1.5-2.5,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M366.7,281.8v-1.6 M364,279.2h2.8 M366.7,280.2H364 M364,281.8h2.8
	 M366.7,283.7H364 M366.7,285.4H364 M364,287h2.8 M364,280.2v1.7 M351.8,286.4v-7.2 M352,285.4h12 M352,283.7h12 M356.2,286.8
	l7.8,0.3 M388.6,287.5v4.1c0,1-0.3,1.6-0.5,2.2c-0.4,0.7-1.2,1-2.2,1l-23.8-1c-1.4-0.3-2.3-0.8-2.9-1.6l-2.9-5.2 M364,281.8h-12
	 M352,280.2h12 M351.7,279.2H364 M366.7,287v6.6 M384.4,281.8v1.9 M384.4,287.3v-1.9 M384.4,285.4v-1.6 M384.4,280.2v-1
	 M384.4,281.8v-1.6 M384.4,287.3h4.3 M377.1,279.2v1 M377.1,280.2v1.6 M377.1,285.4v1.9 M377.1,283.7v1.6 M377.1,281.8v1.9
	 M377.1,287.3l7.3,0.1 M377.1,293.9v-6.7 M377.1,280.2h7.3 M384.4,283.7h-7.3 M377.1,281.8h7.3 M384.4,285.4h-7.3 M384.4,279.2h-7.3
	 M377.1,257.1h-10.5 M366.6,264.1h10.3 M377.1,272.2h-10.3 M377.1,287.3l-10.3-0.3 M366.7,279.2h10.3 M366.6,261.5h10.3 M366.7,287
	v-1.6 M334.7,255.3c-0.7-0.7-1.8-1.2-3.2-1.8l-1.1-0.3l-31.1-3.4 M324.9,275.1c1.1-0.1,1.9-0.4,2.6-1l0.5-0.5l3.9-6.7
	c1.2-2.6,2.5-5.4,3.6-8.4l0.1-0.7c0-1-0.3-1.8-1-2.5 M366.7,285.4v-1.6 M342.1,272.2l-0.1,0.3c-1,1.8-2.1,3.4-3,5.4 M349.3,261.5
	l-2.2,3c-1.7,2.3-3.3,5-5,7.6 M342.1,272.2h7.7 M349.8,272.2v-8 M349.8,264.1H348 M366.7,283.7v-1.9 M364,283.7v-1.9 M364,283.7v1.6
	 M320.5,282.4l4.4-7.3 M325.5,288.4l-1.5-2.8c-0.7-2.1-1.8-3-3.4-3.2 M326.7,300.7h2.3l1.6-2.1 M309.8,300.5l17.1,0.1 M301.4,324.9
	c-2.9-0.5-5.6-1.2-8.4-2.1h-0.1c-2.9-1.2-4.5-2.8-5-4.4v-0.3c-0.3-5.5-0.1-10.3,0.4-14.4c0.3-2.1,2.2-3.2,5.8-3.2h14.3l1.2,0.1
	 M349.7,286.5v-7.3 M339,277.8l-0.7,1.4 M338.3,279.2l-1.1,2.2c-1.2,2.5-1.1,5.1,0.5,7.7c1.8,2.8,4,5.4,6.6,7.8l0.3,0.3 M331.5,295
	l5.2,6.9c1.9,2.2,5,3.7,9.2,4.5l5.1,0.5h140.3l5-0.5c4.3-0.8,7.3-2.3,9.2-4.5l5.2-6.7l1.9-2.6c2.3-3.7,2.8-7,1.4-9.9l-3.3-6.5
	c-5-9.2-10.2-17.6-15.8-25.4c-2.8-3.4-7.2-5.2-13.5-5.5H361c-6.3,0.3-10.7,2.1-13.5,5.5c-5.6,7.7-10.9,16.1-15.8,25.4l-3.3,6.5
	c-1.2,2.9-0.8,6.1,1.4,9.9L331.5,295L331.5,295z M344.6,297.2l1,0.8c0.5,0.4,1.1,0.7,1.8,0.8h0.1l5,0.5c16.8,0.5,33.3,0.7,49.8,0.4
	h37.7c16.5,0.3,33,0.1,49.8-0.4c2.1-0.1,3.7-0.3,5-0.5h0.1c0.7-0.1,1.2-0.4,1.8-0.8l1-0.8 M336.9,285.8l12.8,0.7 M349.8,279.2h-11.4
	 M330.8,298.6l-5.4-10.2 M350.9,313.2h-5.1c-6.9,0.4-11.3-1-13.5-4l-5.6-8.5 M310.3,304.7c-0.8-1-1.9-1.5-2.9-1.5h-13.3
	c-1.6,0-2.6,0.3-2.8,0.8v0.1c-0.8,3.9-1.1,8.4-0.8,13.5c0.3,1,1.4,1.9,3.3,2.8c1.9,0.7,4,1.2,6.1,1.7h0.4c2.1,0.4,3.9,0.3,5.1-0.6
	l1.8-1.2c2.8-1.9,4.1-3.7,4.4-5.4c0.3-2.2,0.4-4.5,0.3-6.9c-0.1-1-0.3-1.6-0.5-2.1L310.3,304.7L310.3,304.7z M314.9,317.6v-0.1
	c0.5-3.2,0.8-6.3,0.7-9.8c-0.1-1.5-0.4-2.6-1-3.3l-1.1-1.5l-0.1-0.1c-1.1-1.2-2.3-1.9-3.6-2.2 M314.1,319.5l-0.8,1.1
	c-1.9,2.1-4.3,3.6-7.2,4.7l-0.5,0.1l-4.1-0.5 M314.9,317.6l-0.5,1.4l-0.3,0.4 M322.6,333.4V330 M521.8,343.7c-1.4-3.7-3-6.9-5.1-9.8
	l-2.1-2.6c-0.7-0.8-1.5-1.5-2.3-2.2c-3.2-2.5-7.3-3.6-12.4-3.6H342.1c-5-0.1-9.2,1.1-12.4,3.6c-0.8,0.7-1.6,1.4-2.3,2.2
	c-0.7,0.8-1.4,1.6-2.1,2.6c-1.9,2.8-3.7,6.1-5.1,9.8 M322.6,330l0.7-0.7c4.1-4,9.2-6.1,15-6.3h12.7 M323.3,343.7
	c-0.7,1.8-1.4,3.9-1.9,5.9c-0.5,1.8-0.8,3.3-0.7,4.5 M320.4,343.7h2.9 M318.7,343.7l3.9-10.3 M320.4,343.7h-1.6 M322.6,333.4
	l-14-2.9c-6.1-1.4-11.7-3-16.9-4.7c-3.9-1.6-5.8-3.7-5.9-6.6v-0.7c-0.1-5.2,0.1-10,0.8-14.6l0.1-0.4c0.5-4,2.8-5.8,6.5-5.4l37.6,0.4
	 M491.2,328.6H342.4c-5,0-9.1,1.4-12.2,4.3c-2.8,2.8-5.1,6.3-6.9,10.9 M373.8,313.2h-0.8 M372.9,323h0.8 M372.9,317.6v-4.4
	 M373.8,317.6h-0.8 M373.8,313.2v4.4 M373.8,319.5h-0.8 M373.8,319.5v3.4 M372.9,323v-3.4 M352.3,313.2h-1.4 M353.7,313.2h-1.4
	 M352.3,319.5h-1.4 M353.7,319.5h-1.4 M352.3,317.6h1.4 M350.9,317.6h1.4 M352.3,323h1.4 M350.9,323h1.4 M353.7,319.5v3.4
	 M352.3,323v-3.4 M350.9,319.5v3.4 M350.9,313.2v4.4 M353.7,313.2v4.4 M352.3,313.2v4.4 M372.9,313.2h-19.3 M353.7,323h19.3
	 M372.9,319.5h-19.3 M353.7,317.6h19.3 M361,261.5h-11.7 M349.8,279.2h1.9 M356.2,286.8l-6.3-0.3 M349.8,272.2h1.9 M349.8,264.1h1.8
	 M350.9,317.6h-36 M350.9,319.5h-36.9 M364,285.4v1.6 M364,280.2v-1 M366.7,280.2v-1 M359.7,264.1l1.2-2.6 M364,267h-12.2
	 M351.8,268.8H364 M351.8,270.4H364 M364,272.2h-12.2 M278,254c0.8-0.7,1.5-1.2,2.3-1.5l3.7-1.8l2.1-0.8c1.1-0.3,2.3-0.6,3.4-0.6
	l6.6,0.1l3,0.3 M364,265.3h-12.2 M359.7,264.1h4.3 M366.6,261.5h-5.8 M361.7,259.8l-0.8,1.6 M301.7,279.2l-0.3,0.4
	c-9.6-0.3-16.2-1.1-19.7-2.5c-1.8-1-3.3-2.5-4.3-4.3c-1-1.5-1.5-3.3-1.8-5.4c-0.5-5-0.3-8.8,1-11.3c0.4-0.8,0.8-1.5,1.5-2.1
	 M271.1,254.2c-0.7,1.8-1.4,3.9-2.1,6.2 M364,268.8v-1.9 M366.7,272.2H364 M366.7,270.4H364 M269.6,333.1c3-14.9,4.4-31,4.1-48.1
	l-0.7-12.9c-0.3-3.4,0-6.7,0.7-10.2c1-4.8,2.3-7.4,4.3-7.7 M269.6,333.1c-1.9-25.5-2.5-48.4-1.7-68.8l1.1-4 M364,268.8h2.8
	 M366.7,267H364 M324.9,275.1h-18.2c-1.6,0-2.9,0.8-3.9,2.3l-1.2,1.8 M301.7,279.2l1.8,2.2l17.2,1.1 M366.7,268.8v-1.9 M325.5,288.4
	l-35.9-1.2c-5.2,0-8.5,1.8-9.6,5.2c-1.8,9.1-2.8,19.3-2.9,30.4c-0.3,5.4,1.6,9.2,5.5,11.4c8.4,3.6,20.5,6.7,36.2,9.6 M262.9,314.7
	c-1.4,1.9-1.9,5.6-1.8,11.1v96.6c0.3,10.5,3.6,16.1,9.9,17.1c4.3,0.7,9.8,0.8,16.6,0.5l16.6-0.5c6.3-1,9.6-6.6,9.9-17.1V384
	 M267.8,336.6c-1.8-4.7-3-9.1-3.7-13.2c-0.5-2.9-1-5.9-1.2-8.7 M306.2,308.2c-1.4-1.4-3-2.1-5-2.1s-3.6,0.7-5,2.1
	c-1.4,1.4-2.1,3-2.1,5s0.7,3.6,2.1,5c1.4,1.4,3,2.1,5,2.1c1.9,0,3.6-0.7,5-2.1c1.4-1.4,2.1-3,2.1-5
	C308.3,311.4,307.6,309.7,306.2,308.2L306.2,308.2z M300.2,313.3c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.7,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.1-0.3,0.3-0.4,0.3-0.8c0-0.4-0.1-0.5-0.3-0.7c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3
	C300.3,312.8,300.2,313,300.2,313.3L300.2,313.3z M267.8,336.6l1.8-3.6 M274.6,344.5l-3.7-1.2c-1.1-2.3-2.1-4.5-2.9-6.6 M283,347
	l-8.5-2.6 M269.1,260.4c-5.2,3.2-8.3,8.3-9.1,15.1c-0.1,11.4,0.8,24.5,2.9,39.2 M283,355.3l4.8,0.3l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	l-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.3l-5-0.7 M283.2,363.1l3.9,0.3l0.4-0.1l0.1-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1
	l-4.3-0.5 M558.8,363.1c-0.7,2.6-2.3,4.7-5.2,6.1l-9.5,3.3c-5,1.7-10,2.9-15,4c-3.4,0.7-6.9,1.4-10.3,1.8l-7.8,0.8
	c-22.3,1.6-47.7,2.6-76.6,2.6h-24.8c-29.4,0-55.6-0.8-78.1-2.6l-7.8-0.8c-3.4-0.4-6.9-1.1-10.5-1.8c-5-1.1-10-2.3-15-4l-1.9-0.7
	c-2.5-0.8-5.1-1.8-7.6-2.8c-2.9-1.4-4.5-3.4-5.2-6.1 M283.1,361.7v-6.5 M275,352.9l8,0.8 M275.1,354.5H275c-0.1,0-0.3-0.1-0.4-0.3
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.4-0.1 M276.2,362.6H276c-0.1,0-0.3-0.1-0.4-0.3
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.3,0.3-0.4l0.3-0.1 M276,361.1h0.1l6.9,0.7 M276,361.1c-0.3-2.1-0.5-4.4-0.8-6.7 M314.1,383.9
	l-1-0.3c-10.5-2.3-20.6-5.6-30.4-9.8c-2.3-0.5-4-2.2-5-4.7c-0.5-2.1-1.1-4.3-1.5-6.7 M283,355.3l-7.8-0.8 M283.2,363.1l-7-0.7
	 M386.7,359.1h-2.8 M383.8,359.1v0.5c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1v-0.5 M352.7,358.9l3,0.3h28.1 M350,358.7v0.5
	c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1v-0.4 M334.4,358.2l15.5,0.7 M331.5,357.9l2.8,0.1 M331.5,357.9v0.5c0,0.7,0.3,1,1,1h1
	c0.7,0,1-0.3,1-1V358 M320.7,354.2c0.1,1.4,0.8,2.5,2.1,3.3 M317.6,354.2v-1c0.8-3.4,1.6-6.6,2.8-9.5 M315.4,354.2l-1.8-0.3
	 M315.4,354.2h2.2 M307.5,352.8c-0.4,5.1,0.8,7.4,3.7,6.9c1.8-0.1,2.6-2.1,2.5-5.9 M320.7,354.2h-3 M317.6,354.2
	c0.4,1.8,2.1,2.9,5.1,3.3 M314.1,383.9l8,1.6 M520.1,385.5c-0.4-0.4-0.8-0.7-1.4-1c-3-1.5-7.8-2.1-14.7-1.4
	c-27.2,1.6-54.9,2.2-82.8,1.8c-28.1,0.4-55.7-0.1-83-1.8c-6.7-0.5-11.7-0.1-14.7,1.4c-0.5,0.3-1,0.5-1.4,1 M322.7,357.5l8.8,0.5
	 M322,385.5l7.6,1.2c22.7,1.4,46.4,2.1,70.8,2.3 M350,358.7l2.8,0.1 M301.4,324.9l21.2,5.2 M307.5,352.8c-8-1.4-16.1-3.4-24.5-5.8
	 M313.6,353.9l-6.2-1 M318.7,343.7l-3.3,10.5 M283,353.8V347 M274.6,344.5c0.1,3,0.3,5.8,0.4,8.5 M366.6,264.1H364 M364,265.3h2.6
	 M366.7,272.2v-1.6 M366.7,270.4v-1.6 M364,270.4v1.6 M507.5,255.3c0.7-0.7,1.8-1.2,3.2-1.8l1.1-0.3l31.1-3.4 M364,270.4v-1.6
	 M364,264.1v1.1 M364,267v-1.6 M366.6,265.3v-1.1 M366.7,267v-1.6 M351.7,265.3v1.6 M351.7,267v1.9 M351.7,268.7v1.6 M351.7,272.2
	v-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M351.8,265.3v-1.1 M351.8,264.1h8.1 M354,254.6c2.1-1.8,4.5-2.8,7.4-2.8
	c16.5-0.3,33.7-0.4,51.5-0.4h33.8c11.6,0.1,22.8,0.3,34,0.4c2.9,0,5.4,1,7.4,2.8c2.6,2.8,5.2,5.9,7.7,9.4c1.8,2.5,3.4,5.1,5.1,8
	c1.7,2.9,3.3,5.9,4.8,9.1c1.4,2.9,1.2,5.6-0.5,8.5c-1.9,2.9-4.1,5.5-6.6,8l-1.4,1.1l-0.1,0.1c-0.7,0.5-1.5,0.8-2.3,1.1
	c-1.4,0.3-3.2,0.5-5.4,0.7c-16.8,0.5-33.4,0.7-49.8,0.4h-36.9c-16.4,0.3-33,0.1-49.8-0.4c-2.1-0.1-3.9-0.3-5.4-0.7
	c-0.8-0.3-1.6-0.5-2.3-1.1l-0.1-0.1l-1.4-1.1c-2.6-2.5-4.8-5.1-6.6-8c-1.8-2.9-1.9-5.8-0.5-8.5c1.5-3.2,3.2-6.2,4.8-9.1
	c1.6-2.8,3.3-5.5,5.1-8C348.7,260.5,351.2,257.5,354,254.6L354,254.6z M540.5,279.2l-1.2-1.8c-1-1.5-2.3-2.3-3.9-2.3h-18.2
	 M352,252.9c2.3-2.1,5.2-3.2,8.5-3.3c16.8-0.3,34.3-0.4,52.4-0.4h33.8c11.8,0.1,23.5,0.3,34.8,0.4c3.3,0.1,6.1,1.2,8.5,3.3l0.1,0.1
	c2.8,2.9,5.4,6.3,8,10c1.9,2.6,3.6,5.4,5.2,8.4c1.6,3,3.3,6.2,5,9.5c1.6,3.4,1.5,6.9-0.7,10.3c-1.9,3-4.3,5.9-7,8.7l-1.6,1.4
	c-0.8,0.5-1.9,1.1-2.9,1.4c-1.5,0.4-3.4,0.7-5.6,0.7c-16.9,0.5-33.7,0.7-50.2,0.3h-38c-16.5,0.3-33.3,0.1-50.2-0.3
	c-2.2-0.1-4.1-0.3-5.6-0.7c-1.1-0.3-2.1-0.7-2.9-1.4L342,300c-2.8-2.6-5-5.5-7-8.7c-2.1-3.4-2.3-6.9-0.7-10.3c1.6-3.4,3.3-6.6,5-9.5
	c1.6-3,3.4-5.8,5.2-8.4C346.5,259.4,349.3,256.1,352,252.9L352,252.9z M540.5,279.2l-1.8,2.2l-17.2,1.1 M540.8,324.9
	c2.9-0.5,5.6-1.2,8.4-2.1h0.1c2.9-1.2,4.5-2.8,5-4.4v-0.3c0.3-5.5,0.1-10.3-0.4-14.4c-0.3-2.1-2.2-3.2-5.8-3.2h-14.3l-1.2,0.1
	 M349.3,261.5c1.8-2.2,3.4-4.3,5.4-6.2c1.9-1.6,4.3-2.5,6.9-2.6c16.5-0.3,33.7-0.4,51.5-0.4h33.8c11.6,0.1,22.8,0.3,34,0.4
	c2.6,0,5,1,6.9,2.6c1.8,1.9,3.6,4,5.4,6.2 M516.7,288.4l35.9-1.2c5.2,0,8.4,1.8,9.6,5.2c1.8,9.1,2.8,19.3,3,30.4
	c0.3,5.4-1.7,9.2-5.5,11.4c-8.4,3.6-20.5,6.7-36.2,9.6 M366.6,257.1c-1.8-0.1-3.2,0.3-4,1.1 M362.6,258.2c-0.3,0.3-0.5,0.5-0.7,1
	l-0.3,0.7 M507.3,255.3c-0.7,0.7-1,1.5-0.8,2.5l0.1,0.7c1.1,3,2.3,5.8,3.6,8.4l3.9,6.7l0.5,0.5c0.7,0.4,1.5,0.8,2.6,1 M503.1,277.8
	c-1-1.8-1.9-3.6-3-5.4l-0.1-0.3 M366.6,258.2v1.6 M480.5,259.8l-0.3-0.7l-0.7-1 M481.2,261.5l-0.7-1.6 M478.2,264.1h4.3
	 M478.2,265.3v-1.1 M478.2,272.2v-1.6 M478.2,265.3v1.6 M478.2,267v1.9 M478.2,270.4v-1.6 M490.4,264.1v1.1 M490.4,267v-1.6
	 M490.4,264.1h1.8 M490.4,270.4v-1.6 M490.4,272.2v-1.7 M492.3,272.2h-1.9 M492.2,264.1v8 M494.1,264.1h-1.8 M490.4,268.8v-1.9
	 M492.9,261.5h-11.7 M499.9,272.2c-1.5-2.6-3.2-5.2-5-7.6l-2.2-3 M482.4,264.1h8.1 M490.4,265.3h-12.2 M478.2,270.4h12.2
	 M490.4,272.2h-12.2 M490.4,267h-12.2 M478.2,268.8h12.2 M482.4,264.1l-1.2-2.6 M490.4,286.6v-1.2 M490.4,285.4v-1.6 M490.4,283.7
	v-1.9 M490.4,281.8v-1.6 M490.4,280.2v-1 M490.4,279.2h1.9 M492.3,279.2l0.1,7.3 M492.5,286.5l-1.9,0.1 M478.2,281.8v1.9
	 M478.2,283.7v1.6 M478.2,287l7.8-0.3 M478.2,285.4v1.6 M478.2,279.2v1 M478.2,280.2v1.6 M453.5,287.5v4.1c0,1,0.3,1.6,0.5,2.2
	c0.4,0.7,1.2,1,2.2,1l23.8-1c1.4-0.3,2.3-0.8,2.9-1.6l2.9-5.2 M497.6,297.2l0.3-0.3c2.5-2.5,4.7-5.1,6.6-7.8
	c0.7-1.1,1.1-2.1,1.2-3.2 M486,286.8l4.4-0.3 M490.4,283.8h-12.2 M490.4,285.4h-12.2 M490.4,280.2h-12.2 M490.4,279.2h-12.2
	 M490.4,281.8h-12.2 M505.7,285.9c0.3-1.5,0-3-0.8-4.5l-1.1-2.2 M503.9,279.2l-0.7-1.4 M511.3,298.6l1.6,2.1h2.3 M516.7,288.4
	l-5.4,10.2 M521.6,282.4c-1.5,0.1-2.8,1.2-3.4,3.2l-1.5,2.8 M492.5,286.5l13.3-0.5 M499.9,272.2h-7.7 M503.9,279.2h-11.6
	 M489.9,313.2h-1.4 M491.1,313.2h-1.2 M515.3,300.7l-5.6,8.5c-2.2,3-6.6,4.4-13.5,4h-5.1 M488.4,317.6h1.4 M489.9,317.6h1.2
	 M489.9,319.5h-1.4 M491.1,319.5h-1.2 M488.4,323h1.4 M489.9,323h1.2 M489.9,323v-3.4 M491.1,319.5v3.4 M488.4,323v-3.4
	 M491.1,317.6v-4.4 M489.9,313.2v4.4 M488.4,313.2v4.4 M519.4,330v3.4 M491.1,323h12.7c5.8,0.3,10.9,2.3,15,6.3l0.7,0.7
	 M491.5,328.6h8.3c5,0,9.1,1.4,12.2,4.3c2.8,2.8,5.1,6.3,6.9,10.9 M511.3,298.6l37.6-0.4c3.8-0.4,5.9,1.4,6.6,5.4l0.1,0.4
	c0.7,4.5,1,9.5,0.8,14.6v0.7c-0.1,2.8-2.1,5-5.9,6.6c-5.1,1.6-10.7,3.2-16.9,4.7c-4.4,1-9.1,1.9-14,2.9 M542,313.3
	c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.7,0.3s-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.4,0.1-0.5,0.3-0.7
	c0.1-0.3,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.7,0.3C541.9,312.8,542,313,542,313.3L542,313.3z M547.9,313.3c0,1.9-0.7,3.6-2.1,5
	c-1.4,1.4-3,2.1-5,2.1s-3.6-0.7-5-2.1c-1.4-1.4-2.1-3-2.1-5s0.7-3.6,2.1-5c1.4-1.4,3-2.1,5-2.1s3.6,0.7,5,2.1
	C547.2,309.7,547.9,311.4,547.9,313.3L547.9,313.3z M550.8,304c-0.1-0.5-1.1-0.8-2.8-0.8h-13.3c-1.1,0.1-2.1,0.5-2.9,1.5l-1,1.4
	c-0.3,0.4-0.4,1.2-0.5,2.1c-0.1,2.3,0,4.7,0.3,6.9c0.3,1.6,1.8,3.4,4.4,5.4l1.8,1.2c1.2,0.7,3,0.8,5.1,0.5h0.4
	c2.1-0.4,4.1-1,6.1-1.6c1.8-0.8,2.9-1.6,3.3-2.8c0.3-5.1-0.1-9.6-0.8-13.5L550.8,304L550.8,304z M532.4,300.5
	c-1.2,0.3-2.5,1.1-3.6,2.2l-0.1,0.1l-1.1,1.5c-0.5,0.7-0.8,1.8-1,3.3c-0.1,3.4,0.1,6.6,0.7,9.8v0.1 M527.3,317.6
	c0.1,0.4,0.3,1,0.6,1.4l0.3,0.4 M528.1,319.5l0.8,1.1c1.9,2.1,4.3,3.6,7.2,4.7l0.5,0.1l4.1-0.5 M523.4,343.7h-1.7 M517.2,275.1
	l4.4,7.3 M519.4,333.4l4,10.3 M528.1,319.5h-36.9 M491.1,317.6h36.2 M518.9,343.7c0.7,1.8,1.4,3.9,1.9,5.9c0.5,1.6,0.8,3.2,0.7,4.4
	 M532.4,300.5l-17.1,0.1 M519.4,330l21.2-5.2 M521.8,343.7h-2.9 M475.6,259.8v1.6 M475.6,258.2v1.6 M475.6,257.1v1.1 M465.1,259.8
	v1.6 M465.1,258.2v1.6 M465.1,257.1v1.1 M465.1,267v-1.6 M465.1,265.3v-1.1 M457.8,264.1v1.1 M457.8,265.3v1.6 M457.8,270.4v1.6
	 M457.8,268.8v1.6 M465.1,270.4v-1.6 M465.1,272.2v-1.6 M465.1,265.3h-7.3 M465.1,268.8v-1.9 M465.1,270.4h-7.3 M465.1,267h-7.3
	 M457.8,268.8v-1.9 M465.1,268.8h-7.3 M457.8,264.1h7.3 M457.8,272.2h7.3 M475.4,272.2v-1.6 M475.4,268.8v-1.9 M475.4,267v-1.6
	 M475.4,268.8v1.6 M475.4,265.3v-1.1 M475.6,257.1h-10.5 M475.4,272.2h-10.3 M465.1,264.1h10.3 M475.4,261.5h-10.3 M453.8,258.2
	c-0.1,0.4-0.3,1-0.3,1.6v1.6 M465.1,257.1h-9.5c-1,0-1.6,0.3-1.9,1.1 M439.1,265.3l-0.8-1.1 M440.3,267l-1.2-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M436.1,270.4c0-0.5-0.1-1.1-0.3-1.6l-0.3-0.8l-0.5-1l-0.8-1.1l-0.5-0.5
	l-2.1-1.6c-1.1-0.7-2.5-1.4-3.9-1.8l-2.8-0.7l-3-0.3h-1.2l-3,0.3c-1.6,0.3-3.3,0.8-4.8,1.4c-1.4,0.7-2.5,1.4-3.4,2.2l-0.5,0.5
	c-0.5,0.5-1,1.1-1.4,1.6l-0.7,1.2l-0.3,0.7c-0.1,0.5-0.3,1.1-0.3,1.6c0,1.2,0.1,2.3,0.7,3.4c0.4,0.8,1,1.5,1.6,2.2
	c0.8,0.8,1.9,1.6,3,2.3c1.1,0.7,2.3,1.1,3.7,1.5l2.9,0.5l3,0.1l2.3-0.1l3.6-0.7c1.2-0.3,2.3-0.8,3.3-1.4c1.2-0.7,2.3-1.5,3.2-2.5
	c1-1.1,1.6-2.2,1.9-3.4c0.1-0.4,0.3-1,0.3-1.5L436.1,270.4L436.1,270.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M439.7,272.2l-1.5,2.9l-3.4,5.5c-0.4,0.5-1,1-1.5,1.4
	c-0.7,0.3-1.4,0.5-2.3,0.8h-19.7c-0.8-0.1-1.6-0.4-2.2-0.8c-0.7-0.3-1.1-0.8-1.5-1.4l-3.4-5.5l-1.5-2.9c-0.4-0.5-0.7-1.1-0.7-1.6
	c-0.1-0.5,0-1.1,0.3-1.6l0.3-0.4l5.6-8c0.8-1.4,2.1-2.1,3.9-2.1h18.7c1.6,0,2.9,0.7,3.9,2.1l5.6,8l0.3,0.4l0.3,1v0.7
	C440.3,271,440.1,271.6,439.7,272.2L439.7,272.2z M441,272.2c0.4-0.5,0.5-1.1,0.7-1.6 M441.7,270.4v-0.7l-0.3-1 M441.4,268.8
	l-0.3-0.7l-0.8-1.2 M438.3,264.1h15.3 M453.5,261.5h-17.2 M438.3,264.1l-1.9-2.6 M453.5,261.5v2.6 M436.4,280.2l-0.5,1 M437.7,277.8
	l-0.8,1.4 M436.9,279.2l-0.5,1 M438.3,287.5h-2.6 M435.7,283.7v-1.9 M435.7,285.4v2.1 M435.7,283.7v1.6 M435.7,281.1v0.8
	 M403.7,287.5v4.4c0.3,1.8,1.2,2.8,2.9,2.9h28.8c1.6-0.1,2.6-1.1,2.9-2.9v-4.4 M438.3,287.5h15.3 M475.4,283.7v-1.9 M475.4,285.4
	v-1.6 M475.4,280.2v-1 M475.4,281.8v-1.6 M475.3,287v-1.6 M465.1,279.2v1 M457.8,280.2v-1 M457.8,287.3v-1.9 M457.8,285.4v-1.6
	 M457.8,281.8v1.9 M465.1,283.7v-1.9 M465.1,285.4v-1.6 M465.1,287.3v-1.9 M465.1,281.8v-1.6 M465.1,280.2h-7.3 M465.1,279.2h-7.3
	 M457.8,287.3l7.3-0.1 M457.8,285.4h7.3 M457.8,283.7h7.3 M457.8,281.8v-1.6 M465.1,281.8h-7.3 M475.4,279.2h-10.3 M475.3,293.7V287
	 M465.1,287.3l10.3-0.3 M465.1,293.9v-6.6 M453.8,258.2h11.3 M465.1,261.5h-11.6 M453.8,259.8h11.3 M457.8,265.3h-18.7 M453.5,264.1
	h4.3 M457.8,267h-17.5 M457.8,270.4h-16.1 M441.4,268.8h16.2 M457.8,272.2H441 M457.8,279.2h-20.9 M457.8,283.7h-22 M435.7,281.8h22
	 M453.5,287.5h4.3 M435.7,285.4h22 M457.8,280.2h-21.5 M437.7,277.8l3.3-5.6 M436.3,261.5l-1.5-2.1c-1-1.5-2.3-2.2-4.1-2.2h-19.4
	c-1.8,0-3.3,0.7-4.1,2.2l-1.5,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M422.2,261.7l1.6,0.3c0.7,0.1,1.4,0.3,2.1,0.4c0.8,0.3,1.5,0.5,2.2,1
	c0.5,0.3,1,0.7,1.2,1.1l0.4,0.8v0.4c0,0.4-0.1,0.8-0.4,1.2c-0.3,0.4-0.7,0.8-1.2,1.1c-0.4,0.3-1,0.5-1.5,0.7l-2.1,0.5v-0.5l-0.4-2.9
	l-0.1-0.5c-0.3-0.8-0.5-1.5-0.8-2.1c-0.3-0.5-0.5-1-0.8-1.2l-0.5-0.3L422.2,261.7L422.2,261.7L422.2,261.7z M420.5,261.7l-0.5,0.4
	c-0.3,0.3-0.5,0.7-0.8,1.1c-0.3,0.5-0.5,1.2-0.8,2.1l-0.1,0.3l-0.5,3.7v0.1h-0.1l-1.8-0.5l-0.8-0.4c-0.7-0.3-1.2-0.7-1.5-1.1
	l-0.3-0.4c-0.3-0.3-0.4-0.7-0.4-1v-0.7l0.3-0.7c0.3-0.4,0.7-0.8,1.1-1.1c0.8-0.5,1.6-1,2.8-1.2c0.7-0.3,1.5-0.3,2.2-0.4L420.5,261.7
	L420.5,261.7z M422.6,264.9l0.7,1.9l0.3,1.8v0.7h-0.1l-2.1,0.1l-1.9-0.1l-0.5-0.1l0.3-2.5l0.5-1.6c0.1-0.4,0.4-0.8,0.7-1.2l0.3-0.3
	c0.1-0.1,0.4-0.3,0.7-0.3c0.3,0,0.4,0.1,0.5,0.3l0.4,0.5L422.6,264.9L422.6,264.9z M417.6,270.8l0.1,3.2c0.1,0.8,0.3,1.6,0.4,2.5
	l0.7,2.2c0.3,0.5,0.5,1,0.8,1.4h-0.1c-1.2-0.1-2.5-0.4-3.4-0.8c-1.6-0.5-3-1.4-4.1-2.3c-1-0.8-1.6-1.6-2.1-2.6
	c-0.4-0.8-0.7-1.6-0.8-2.5v-1.2c0-0.5,0.1-1.1,0.3-1.6l0.1-0.3c0.1-0.5,0.4-1.1,0.8-1.6s0.8-1.1,1.4-1.7l-0.1,0.3
	c-0.1,0.4-0.1,1,0,1.4l0.3,0.5c0.3,0.4,0.7,0.8,1.1,1.2l0.8,0.5c0.7,0.4,1.5,0.7,2.3,1L417.6,270.8L417.6,270.8z M418.9,272.6V271
	c1.7,0.3,3.2,0.3,4.8,0v0.1c0,0.8,0,1.8-0.1,2.5l-0.3,1.8l-0.5,1.6l-0.5,1l-0.4,0.3l-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.3
	c-0.3-0.3-0.4-0.5-0.7-1c-0.3-0.5-0.5-1.4-0.8-2.3C419,274.1,418.9,273.3,418.9,272.6L418.9,272.6z M424.8,270.8l2.1-0.5
	c1.1-0.3,1.9-0.8,2.8-1.4c0.5-0.4,1-0.8,1.1-1.2l0.3-0.7l0.1-1l-0.1-0.5v-0.1l0.3,0.3l1.1,1.4l0.3,0.5l0.5,1.4l0.3,1.6
	c0,0.7,0,1.2-0.1,1.9c-0.3,1.1-0.8,2.2-1.5,3.2s-1.6,1.6-2.6,2.3c-1.2,0.7-2.5,1.2-4,1.6l-2.8,0.4l0.4-0.5c0.4-0.7,0.7-1.4,1-2.2
	c0.3-1,0.4-1.9,0.5-2.9L424.8,270.8C424.7,270.8,424.7,270.8,424.8,270.8L424.8,270.8z M401.8,267l-0.8,1.2l-0.4,0.5 M403.1,265.3
	l-1.2,1.6 M401,272.2l0.1,0.1l3.2,5.5 M400.6,268.8c-0.1,0.5-0.3,1.1-0.1,1.6 M400.4,270.4c0.1,0.5,0.3,1.1,0.7,1.6 M403.9,264.1
	l-0.8,1.2 M405.7,261.5l-1.8,2.6 M405.8,280.2l0.5,1 M405.1,279.2l0.7,1 M404.3,277.8l0.8,1.4 M406.4,283.7v1.6 M406.4,281.8v1.9
	 M406.4,287.5h-2.6 M406.4,285.4v2.1 M406.4,281.1l0.5,0.8c0.8,0.8,1.9,1.5,3.3,1.9l0.7,0.1h20.5l0.7-0.1c1.4-0.4,2.5-1,3.3-1.9
	l0.6-0.8 M406.4,281.1v0.7 M421.8,313.2h-1 M420.8,323h1 M421.8,319.5h-1 M420.8,317.6h1 M421.8,317.6v-4.4 M420.8,313.2v4.4
	 M420.8,319.5v3.4 M421.8,323v-3.4 M396.7,313.2v4.4 M397.7,313.2v4.4 M396.7,323v-3.4 M397.7,319.5v3.4 M396.7,317.6h1
	 M397.7,319.5h-1 M396.7,323h1 M397.7,313.2h-1 M420.8,313.2h-23.1 M397.7,323h23.1 M420.8,319.5h-23.1 M397.7,317.6h23.1
	 M469.1,313.2h-0.8 M468.3,323h0.8 M469.1,319.5h-0.8 M468.3,317.6h0.8 M468.3,313.2v4.4 M469.1,317.6v-4.4 M469.1,319.5v3.4
	 M468.3,319.5v3.4 M445.4,313.2h-1 M444.5,323h1 M445.4,319.5h-1 M444.5,317.6h1 M445.4,323v-3.4 M444.5,319.5v3.4 M444.5,313.2v4.4
	 M445.4,317.6v-4.4 M468.3,313.2h-22.8 M445.4,323h22.8 M468.3,319.5h-22.8 M445.4,317.6h22.8 M444.5,313.2h-22.7 M421.8,323h22.7
	 M444.5,319.5h-22.7 M421.8,317.6h22.7 M435.7,287.5h-29.3 M468.8,364.2c-1.1-1.1-1.7-2.3-1.7-4.1 M468.5,359.1v1 M471.6,364.2
	l2.9,0.3c0.7,0,1.4-0.1,1.9-0.4 M476.5,364.1l-5,0.1 M471.6,364.2c-0.8-0.3-1.4-0.5-1.9-1.1c-0.7-0.7-1.1-1.6-1.2-2.9 M455.6,359.1
	v0.5c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1v-0.5 M458.4,359.1h3.4 M463.7,359.1h-1 M467.2,360.2l-0.1-1.1 M467,359.1h-3.3 M463.7,360.2
	l0.3,4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M485.5,359.1l-0.5,9.1c-0.3,1-0.6,1.8-1,2.2c-0.5,0.6-1.1,0.7-1.8,0.6
	h-16.2c-0.7,0.1-1.2,0-1.8-0.5c-0.4-0.4-0.7-1.2-1-2.2l-0.5-8.9 M467.2,360.2h-3.4 M463.7,360.2v-1.1 M462.8,359.1h-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M461.8,359.1v10.3c0.1,2.1,1.1,3,3,3h18.8c2.1,0.1,3.2-0.8,3.2-3
	l-0.1-10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M464.3,367.4v0.7c0.1,0.8,0.4,1.4,0.7,1.7c0.3,0.3,0.5,0.3,0.8,0.3h16.6
	c0.3,0.1,0.7,0,0.8-0.3c0.3-0.3,0.5-1,0.7-1.7l0.1-0.8 M479.4,364.1c-1.1,1-2.6,1.6-4.8,1.8c-2.6,0.1-4.5-0.4-5.8-1.7 M467,359.1
	h1.5 M464,364.2l0.3,3.2 M468.8,364.2H464 M445.4,359.1h-5.2 M445.4,359.1v-4.5h-5.2v4.5 M440.2,359.1l1.2,11.7h3l1-11.7
	 M445.4,359.1h10.2 M463,388.7l8.8,2.3l9.9-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M469.4,388.6l2.8,1l2.9-1.1 M463,388.7l6.3-0.1 M469.4,388.6l5.6-0.1
	 M442,389.1l21-0.4 M455.6,359.1h2.8 M419.4,359.1v0.3c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1v-0.3 M422.3,359.1h-2.9 M396.7,359.1
	l1,11.7h3l1.2-11.7 M396.7,359.1v-4.5h5.2v4.5 M402,359.1h-5.2 M400.4,389.1c8.1,4.7,15.8,6.6,23.4,5.8c6.2-0.5,12.2-2.5,18.2-5.8
	 M402,359.1h17.5 M422.3,359.1h17.9 M442,389.1h-41.5 M559.1,353.8l-5,0.7c-0.1,0-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
	s0,0.3,0.1,0.4l0.4,0.1l5-0.3 M559.1,361.7l-4.3,0.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.3,0.1,0.4l0.4,0.1l3.9-0.3
	 M559.1,361.7v-6.5 M528.4,353.9c-0.1,3.9,0.7,5.8,2.5,5.9c2.9,0.4,4.1-1.9,3.7-6.9 M526.7,354.2l1.7-0.3 M526.3,354l0.4,0.1
	 M526.6,354l0.1,0.1 M526.3,354h0.3 M521.5,354h3 M524.5,354v-0.8c-0.8-3.4-1.7-6.6-2.8-9.5 M524.5,354h1.8 M528.4,353.9l6.2-1.1
	 M520.1,385.5l8-1.6 M510.6,357.9l8.8-0.4 M507.9,358.2v0.4c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1V358 M510.6,357.9l-2.8,0.3
	 M492.3,358.7l-2.9,0.1 M489.5,358.9v0.4c0,0.7,0.3,1,1,1h1c0.7,0,1-0.3,1-1v-0.6 M484.5,359.1h1 M486.7,359.1h-1.2 M479.5,360.1
	c-0.1,1-0.4,1.9-1,2.6c-0.5,0.7-1.2,1.1-2.2,1.4 M481.1,360.1c-0.1,1.4-0.5,2.6-1.2,3.4l-0.4,0.4 M481.1,359.1v1 M479.5,359.1h1.5
	 M479.5,360.1v-1 M484.2,363.9l0.3-3.9 M484.5,360.1v-1 M484.2,363.9l-4.8,0.1 M484.5,360.1h-3.4 M484.5,359.1h-3.4 M486.7,359.1
	l2.8-0.3 M484.1,367.1l0.1-3.2 M481.7,388.2l30.8-1.5l7.6-1.1 M492.3,358.7l15.5-0.5 M519.3,357.5c3.2-0.4,4.8-1.6,5.1-3.4
	 M519.3,357.5c1.2-0.8,1.9-1.9,2.1-3.4 M559.1,353.8V347 M479.4,258.2c-0.8-0.8-2.2-1.2-4-1.1 M488.4,313.2h-19.3 M469.1,323h19.3
	 M488.4,319.5h-19.3 M469.1,317.6h19.3 M534.6,352.8c8-1.4,16.1-3.4,24.5-5.8 M478.2,267h-2.8 M475.4,265.3h2.8 M478.2,268.8h-2.8
	 M475.4,270.4h2.8 M478.2,272.2h-2.8 M478.2,283.7h-2.8 M475.4,281.8h2.8 M478.2,285.4h-2.8 M475.3,287h2.9 M478.2,280.2h-2.8
	 M475.4,279.2h2.8 M475.4,264.1h2.8 M481.2,261.5h-5.8 M503.1,277.8h-65.3 M468.5,360.2l11-0.1 M475,388.4l6.7-0.3 M523.4,343.7
	l3.2,10.3 M497.6,297.2c-21.5,0.7-49.9,0.8-85.3,0.5c-23.7,0.3-46.2,0.1-67.7-0.5 M479.5,359.1h-11 M484.1,367.1l-19.8,0.3
	 M480.5,259.8h-5 M479.4,258.2h-3.9 M299.3,249.8c-0.4-11.6-2.9-20.6-7.6-27.2 M275.5,245.6c-1.5,1.7-2.9,4.5-4.4,8.4 M405.7,261.5
	h-17.1 M403.9,264.1h-15.3 M403.1,265.3h-18.7 M384.4,267h17.5 M400.6,268.8h-16.2 M400.4,270.4h-16.1 M384.4,280.2h21.5
	 M406.4,281.8h-22 M384.4,279.2h20.8 M384.4,283.7h22 M406.4,285.4h-22 M396.7,313.2h-23 M373.8,323h23 M396.7,319.5h-23
	 M373.8,317.6h23 M521.5,354l-200.8,0.1 M323.3,343.7h195.6 M388.6,287.5h15.1 M404.3,277.8H339 M384.4,272.2H401 M550.4,222.5
	c-4.7,6.6-7.2,15.7-7.6,27.2 M386.7,359.1h10 M362.6,258.2h4 M366.6,257.1v1.1 M361.7,259.8h5 M366.6,259.8v1.6 M384.4,265.3h-7.3
	 M384.4,264.1h-7.3 M384.4,268.8h-7.3 M377.1,267h7.3 M377.1,270.4h7.3 M384.4,272.2h-7.3 M388.6,264.1v-2.6 M388.6,261.5h-11.6
	 M388.3,258.2h-11.3 M388.5,259.8h-11.4 M384.4,270.4v-1.6 M384.4,267v-1.6 M384.4,268.8V267 M384.4,265.3v-1.2 M559.7,228.7l3-0.3
	 M566.6,245.6c-3.9-4.5-6.2-10.2-6.9-16.9 M559.1,347l8.5-2.5 M559.1,355.3l8-0.8 M603.4,215.9c-14.3,3.3-29.7,5.6-46.5,7.2
	 M384.4,264.1h4.3 M558.8,363.1l7.2-0.5 M384.4,270.4v1.8 M377.1,267v1.8 M377.1,265.3v1.6 M377.1,264.1v1.2 M377.1,268.8v1.6
	 M377.1,270.4v1.8 M377.1,261.5v-1.6 M377.1,259.8v-1.6 M377.1,258.2v-1.1 M388.6,261.5v-1.6c0-0.7,0-1.2-0.3-1.6 M388.3,258.2
	c-0.3-0.8-1-1.2-1.9-1.1h-9.5 M330.8,141.2c-3.2,3.7-5.9,9.5-8.3,17.1 M294.5,222.4c8.5-29,15.8-50.3,21.6-64 M316.1,158.3
	c3.6-8.4,6.6-13.9,9.1-16.5 M310.5,153.3c-9.1,17.9-17.5,41.1-25.2,69.7 M298.5,222.3c8-27.4,15.1-48.7,21.3-64 M351.5,239.3
	c-7.8-9.5-18.6-15.8-32.3-19.3 M312.3,221.7c13.5,5.1,21,12.9,22.6,23.4c0.4,3.2,0.4,6.5-0.1,10 M351.5,239.3
	c-1.5,0.6-2.9,1.4-4.1,2.3c-1.2,1.1-2.6,2.3-3.9,3.6c-3,2.9-5.9,6.3-8.8,10 M319,220.1c-3.4,0.4-5.6,1-6.9,1.6 M320,158.3h-3.9
	 M320,158.3l2.6-0.1 M325.1,141.8c-6.2,1.2-10.7,4.7-13.9,10l-0.7,1.4 M277.2,219l-38.8-5.1 M285.3,223.1
	c-16.8-1.4-32.2-3.7-46.5-7.2 M291.8,222.5l-6.5,0.6 M285.3,223.1l-2.9,5.6 M279.3,228.4l-1.8,8 M275.5,245.6
	c3.9-4.5,6.2-10.2,6.9-16.9 M277.5,236.4l-1.8,8.1 M275.7,244.5l-0.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M273.3,245.2c-1.2,0.6-2.2,1.5-2.6,2.9l-0.8,3.2c-0.4,2.2,0,3.2,1.2,2.9
	 M273.3,245.2c0.7-0.3,1.5-0.6,2.5-0.6 M277.5,236.4l-2.8,4.5l-1.4,4.3 M282.4,228.7l-3.2-0.3 M284.2,219.1l1.1,4 M310.5,153.3
	c-10.3,18-19.1,39.9-26.3,65.3l0.1,0.4 M284.2,219.1h-6.3l-0.7-0.1 M291.8,222.5l2.8-0.1 M294.5,222.4l4-0.1 M312.3,221.7l-11,0.4
	l-2.8,0.1 M277.2,219l1.5-12.5c0.3-4.4-1.1-7-4-7.8c-4.5-1.5-10-2.1-16.8-1.7c-6.7,0.6-11.3,1.9-13.6,4.3l-5.2,10.2
	c-0.6,0.8-0.8,1.6-0.8,2.5 M238.4,213.9c0,0.7,0.1,1.4,0.4,2.2 M238.8,215.9c0.3,0.6,0.7,1.2,1.1,1.8c2.1,2.3,5.4,4.3,10,5.4
	c5.4,1.2,11,2.3,16.9,3.4l12.5,1.8 M417.9,137.7l-66.9,2.2l-20.2,1.4 M330.8,141.2l-1,0.1c-1.6,0.1-3.2,0.3-4.7,0.6 M511.3,141.2
	c3.2,3.7,5.9,9.5,8.3,17.1 M526,158.3c-3.6-8.4-6.6-13.9-9.1-16.5 M491.9,239.3c7.7-9.4,18.4-15.8,32.1-19.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524,220.2l-11.4-0.7h-183l-10.6,0.6 M531.7,153.3
	c9.1,17.9,17.5,41.1,25.2,69.7 M526,158.3c5.8,13.6,12.9,34.9,21.6,64 M507.5,255.3c-0.5-3.6-0.5-6.9-0.1-10
	c1.6-10.5,9.1-18.3,22.6-23.4 M519.6,158.1l2.6,0.1 M522.2,158.3c6.3,15.3,13.5,36.6,21.3,64 M556.9,223.1l2.9,5.6 M529.9,221.7
	c-1.1-0.7-3-1.2-6.1-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M529.9,221.7l11,0.4l2.8,0.1 M556.9,223.1l1-4 M556.9,223.1l-6.5-0.6
	 M550.4,222.5l-2.8-0.1 M547.6,222.4l-4-0.1 M557.8,219.1l0.1-0.4c-7.3-25.4-16-47.3-26.4-65.3 M507.3,255.3
	c-2.8-3.7-5.6-7.2-8.7-10l-3.9-3.6c-3.3-2.5-7.6-3.9-13.1-3.7H360.4c-3.4,0-6.3,0.4-8.9,1.5 M522.2,158.3h3.9 M531.7,153.3L531,152
	c-3.2-5.5-7.8-8.8-13.9-10 M322.6,158.1c26.3-0.8,52.4-1.2,78.4-1.4h40.2c26,0.1,52.1,0.6,78.4,1.4 M420.8,137.5h-2.6 M418.2,137.7
	l2.6-0.1 M417.9,137.7h0.3 M418.2,137.5l-0.3,0.1 M418.2,137.5v0.1 M424,137.7l-0.1-0.1 M424,137.7h0.1 M424.1,137.7l-0.3-0.1
	 M421.2,137.5l2.8,0.1 M420.8,137.5h0.4 M423.8,137.5h-2.6 M421.4,130.5h-0.3 M420.7,130.5h0.4 M421.1,130.5
	c-0.4,0.1-0.7,0.7-0.5,1.8c-0.3,2.5-1,4.3-2.2,5.2 M423.8,137.5c-1.2-1-1.9-2.8-2.2-5.2c0-1.1-0.1-1.6-0.5-1.8 M511.3,141.2
	l-23.8-1.5l-63.4-2.2 M517,141.8l-5.6-0.6 M566.6,245.6c1.5,1.7,2.9,4.5,4.4,8.4 M567.2,352.9c0.3-2.6,0.4-5.5,0.4-8.5 M528.1,383.9
	l1-0.3c10.5-2.3,20.6-5.6,30.5-9.8c2.3-0.5,4-2.2,5-4.7c0.5-2.1,1.1-4.3,1.5-6.7 M566.2,361.1c0.3-2.1,0.5-4.4,0.8-6.7 M566.2,361.1
	h-0.3l-6.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M565.9,362.6h0.1c0.1,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5
	c0-0.1-0.1-0.3-0.3-0.4l-0.3-0.1 M567.2,352.9H567l-8,0.8 M567,354.5h0.1c0.1,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5
	c0-0.1-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.4-0.1 M574.2,336.6l-1.7-3.4 M567.6,344.5l3.7-1.2c1.1-2.3,2.1-4.5,2.9-6.6 M574.2,336.6
	c1.8-4.7,3-9.1,3.7-13.2l1.4-8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M528.1,383.9v38.4c0.3,10.5,3.4,16.1,9.9,17.1l16.6,0.5l16.6-0.5
	c6.3-1,9.6-6.6,9.9-17.1v-96.6c0.1-5.5-0.4-9.2-1.8-11.1 M579.3,314.7c2.1-14.7,3-27.8,2.9-39.2c-1-6.9-4-12-9.2-15.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.1,260.4l1.1,4c0.8,20.4,0.3,43.3-1.7,68.8 M573.1,260.4
	c-0.7-2.3-1.4-4.4-2.1-6.2 M542.8,249.8l3-0.3l6.6-0.1c1.2,0,2.3,0.3,3.4,0.6c0.7,0.3,1.4,0.4,2.1,0.8l3.7,1.8
	c0.8,0.3,1.5,0.8,2.2,1.4l0.1,0.1 M564.1,254c0.5,0.6,1.1,1.2,1.4,2.1c1.2,2.6,1.5,6.3,1,11.3c-0.3,2.1-1,3.9-1.8,5.4
	c-1,1.8-2.5,3.2-4.3,4.3c-3.4,1.4-9.9,2.3-19.5,2.5l-0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M572.5,333.1c-3-14.9-4.5-31-4.1-48.1l0.4-8.5c0-1.5,0.1-3,0.3-4.5
	c0.3-3.4,0-6.7-0.7-10.2c-1-4.8-2.3-7.4-4.3-7.7 M565,219l38.8-5.1 M568.8,245.2l-1.5-4.3l-2.8-4.5 M566.3,244.5l0.3,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M571,254.2c1.2,0.3,1.7-0.7,1.2-2.9l-0.8-3.2c-0.4-1.4-1.4-2.2-2.6-2.9
	 M566.3,244.5l-1.8-8.1 M568.8,245.2c-0.7-0.3-1.5-0.6-2.5-0.6 M564.6,236.4l-1.8-8 M565,219l-0.7,0.1h-6.5 M562.8,228.4l12.5-1.8
	c5.9-1,11.6-2.1,16.9-3.4c4.7-1.2,8-3,10-5.4c0.6-0.6,0.8-1.2,1.1-1.8 M603.4,215.9c0.3-0.7,0.4-1.5,0.4-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M603.8,213.9c0-0.8-0.3-1.6-0.8-2.5l-5.2-10.2c-2.3-2.3-6.7-3.7-13.6-4.3
	c-6.7-0.4-12.2,0.1-16.8,1.6c-2.9,0.8-4.3,3.4-4,7.8l1.5,12.5"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M298.5,222.3
		c0,0,5.9-0.3,13.6-0.6c0,0,2.3-1.4,6.9-1.7l10.3-0.6h53.9h37h8.3h45.8h38l11.7,0.7c0,0,3.4,0.3,6.1,1.5l13.6,0.6
		c0,0-10.9-38.4-21.3-64c0,0-68.6-1.9-101.1-1.4c0,0-43.1-0.1-101.1,1.4c0,0-9.6,24.1-21,62.9L298.5,222.3z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M291.8,222.5c0,0,6.9-0.4,20.5-0.8
		c0,0,2.1-1.7,8.9-1.8l8.4-0.4H378h43.1h45.7h45.5l10.9,0.7c0,0,3.4,0,6.9,1.5l13.6,0.6l4,0.1l2.8,0.3c0,0-4.4,5.5-6.5,16.4
		c0,0-0.8,4.8-1.1,10.9l-31.1,3.4c0,0-2.9,0.4-4.3,2.1c0,0-5.8-8.3-13.3-14.2c0,0-3.4-2.9-10.9-3.2h-28.5h-33.7h-34.9H359
		c0,0-5.8-0.1-10.5,2.9c0,0-5.6,3.7-13.9,14.4c0,0-1.7-1.8-4.3-2.1l-31.1-3.4c0,0-0.1-10-3.2-18.6
		C296.2,231.3,294.4,226.1,291.8,222.5z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M301.4,279.6
		l2.1-2.8c0,0,1.2-1.7,3.3-1.8h18.3c0,0,2.3-0.1,3.3-1.5l3.9-6.7c0,0,2.6-5.5,3.6-8.4c0,0,0.5-1.5-0.8-3.2c0,0-1.2-1.5-4.1-2.1
		l-34.3-3.7l-6.3-0.1c0,0-2.9-0.3-7,1.9l-2.6,1.2c0,0-1.5,0.6-2.5,1.7c0,0-2.2,1.8-2.5,7.3c0,0-1.2,11.8,6.3,15.7
		C281.7,277.1,286.3,279.3,301.4,279.6z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M534.4,275.1
		h-17.1c0,0-2.5-0.1-3.4-1.5l-3.9-6.7c0,0-2.6-5.5-3.6-8.4c0,0-0.6-1.8,0.8-3.2c0,0,1.5-1.7,4.3-2.1l34-3.7l6.5-0.1
		c0,0,3.3,0,6.7,1.8l2.9,1.4c0,0,2.5,0.7,4,4c0,0,1.7,3.3,0.7,10.9c0,0-0.5,6.7-6.1,9.6c0,0-3.7,2.3-19.5,2.5l-2.1-2.8
		C538.7,276.9,537.3,274.7,534.4,275.1z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M337,302.3c0,0-5.6-7.2-7.6-10
		c0,0-1.6-2.2-2.1-5.5c0,0-0.4-1.9,1.1-4.8l0.7-1.2c0,0,5.5-12,18.4-30.3c0,0,3.3-5,13.2-5.1h26.5h33.8h30.3h30.1
		c0,0,9.1,0,13.2,5.5c0,0,9.1,11.7,18.6,30.8l0.3,0.5c0,0,1.7,2.9,0.7,6.2c0,0-0.6,2.2-1.8,4.1l-7,9.4c0,0-2.3,3.4-9.2,4.7l-5,0.6
		h-32.6h-37.6h-34h-36.2l-5-0.6C346,306.4,340.3,305.8,337,302.3z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M267.8,284.6l5.5-8.4
		l5.1-1.9c0,0,1.2,1.9,3.6,2.9c0,0,3,1.4,10.9,1.9l8.4,0.4l0.3-0.4l1.8,2.2l17.3,1.1c0,0,2.3,0.5,3.2,3.2l6.9,12.9l-1.7,2.1h-2.3
		l5.9,8.9c0,0,2.8,4,11,3.7h2.2h27.8h34.8h38.1h37.8h11.3h0.7c0,0,5,0.3,8.3-0.7c0,0,3.2-0.8,5-3l5.9-8.8h-2.3l-1.6-2.1l6.9-12.9
		c0,0,0.8-2.9,3.4-3.2l17.2-1.1l1.8-2.2l0.3,0.4c0,0,7.4-0.1,12.8-0.8c0,0,4.5-0.7,6.9-1.7c0,0,2.5-1.5,3.3-2.8h0.4l5.1,1.9l5.5,8.4
		c0,0,0.3,18-2.1,48.6l1.8,3.6c0,0-1.8,4.5-2.9,6.6c0,0-20.1,7.4-44.6,10.9l-2.2-0.1c0,0-0.1,2.8-5.1,3.4l-33.2,1.6H446h-43.5h-46.1
		l-3-0.3l-18.4-0.7l-11.7-0.7c0,0-4.3-0.4-5.1-3.3H316c0,0-19.1-2.3-44.6-10.9c0,0-2.2-4.5-2.9-6.6l1.8-3.6c0,0-2.2-33.8-2.1-47.7
		v-0.4L267.8,284.6z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup2DrFront);
