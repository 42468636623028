import React, { Component } from 'react';

class FileUploadCloudIcon extends Component {
  render() {
    const { size = 24, color = '#858C94' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 30 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.71211 10.1558C4.45106 10.4731 3.34928 11.24 2.61378 12.3124C1.87828 13.3847 1.55969 14.6888 1.71785 15.9795C1.87602 17.2702 2.50006 18.4587 3.47273 19.3218C4.4454 20.1848 5.69975 20.663 7.00011 20.6664H8.33344M15.0001 13.9998V25.9998V13.9998ZM15.0001 13.9998L11.6668 16.6664L15.0001 13.9998ZM15.0001 13.9998L18.3334 16.6664L15.0001 13.9998Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.1068 7.51728C19.6511 5.71271 18.5262 4.14894 16.9603 3.14296C15.3944 2.13698 13.5045 1.76407 11.6738 2.09982C9.84312 2.43557 8.20858 3.45486 7.10155 4.95107C5.99451 6.44727 5.49781 8.30844 5.71213 10.1573C5.71213 10.1573 5.91613 11.3333 6.33346 11.9999"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6668 20.6668C22.6082 20.6662 23.5388 20.4662 24.3973 20.08C25.2558 19.6938 26.0228 19.1301 26.6478 18.4261C27.2728 17.7221 27.7416 16.8938 28.0233 15.9955C28.3051 15.0973 28.3934 14.1496 28.2825 13.2148C28.1716 12.2799 27.864 11.3792 27.3799 10.5718C26.8958 9.76445 26.2462 9.06876 25.4738 8.53054C24.7015 7.99233 23.8239 7.62378 22.8988 7.44918C21.9738 7.27457 21.0222 7.29785 20.1068 7.51749L18.3335 8.00016"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}

export default FileUploadCloudIcon;
