import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#5E5E5E"
            d="M698.2,349.2c0-5.9-1.1-11.6-3.2-16.7c-6.7-16.9-23.2-28.9-42.5-28.9c-25.2,0-45.6,20.4-45.6,45.6
	c0,2.7,0.2,5.4,0.7,7.9c3.7,21.4,22.4,37.7,44.9,37.7C677.8,394.7,698.2,374.4,698.2,349.2L698.2,349.2z M245.2,349.2
	c0-25.2-20.4-45.6-45.6-45.6c-25.2,0-45.6,20.4-45.6,45.6c0,2.8,0.3,5.5,0.8,8.1c3.8,21.3,22.5,37.5,44.9,37.5
	c24.9,0,45.1-19.9,45.6-44.7L245.2,349.2L245.2,349.2z"
          />
          <path
            fill="#FFFFFF"
            d="M268,343c0,0-2.9,0.1-3.5,0.1s-0.8-0.4-1-0.7c-0.2-0.4-0.8-1.8-0.8-1.8l-3.2,0.1c0,0-0.3,1.3-0.4,1.6
	c-0.1,0.4-0.4,0.6-0.4,0.6s-1.1,0.2-2,0.2s-1-1.3-1.1-2.5c-0.2-1.1-1-10-2.7-19.6c-1.5-8.5-5.5-20.1-16.3-29.1
	c-10.8-9-28.7-10.9-36.5-11s-21.5,0-32.5,6.5c-11,6.5-16.2,11.9-20.1,21.8s-4.4,20.4-4.6,24.7c-0.3,4.3,0.6,4.9,1.5,7.6
	c1,2.7-1.1,2.1-1.1,2.1c-0.4,0-1.6,0.1-2.3,0.1c-1.1,0-1.7-0.8-1.7-0.8l-0.6-1.9l-3.3-0.1c0,0-0.5,1.1-0.8,1.7s-0.8,0.6-0.8,0.6
	s-1.3-0.1-3.2-0.3c-1.3-0.2-1.3-1.4-1.2-2.7c0,0-12.7-0.8-20.5-1.1c-7.8-0.3-24.8-0.5-26.7-0.5c-1.5,0-1.8-0.4-2-1.2l-10.8,0.2
	l-3.1-28.3h-1.1c-0.7,0.1-0.7-0.8-0.9-1.8s-1.3-8.8-1.4-10c-0.1-1.1,0.5-1.3,0.5-1.3s-0.7-0.6-0.8-1.1c-0.1-0.5-0.2-1-0.3-3.5
	c-0.1-2.6-0.5-7.4-0.5-7.4s-1.2-0.9-1-6.1c0.1-3,1-4.4,1-4.4s-0.1-1.4,0-2.7s0.3-3.8,0.3-3.8s-0.5-0.3-0.4-0.8
	c0.1-0.5,0.3-3.4,0.3-3.4s-0.1-0.6,0-1.4c0.1-1,0.5-3.2,0.5-3.2H62c0,0-0.5-0.3-0.4-0.7c0.1-0.5,0.1-1.1,0.2-1.7
	c0.1-0.6,0.4-1.1,0.7-1.4c0.4-0.3,1.4-0.7,1.4-0.7s1.1-6.6,1.2-6.8c0.1-0.3,0.7-0.2,0.7-0.2s-0.1-0.4,0-0.8c0.1-0.5-0.7-1.1-1.1-1.5
	c-0.6-0.5-0.4-1.9-0.1-2.6c0.3-0.8,0.6-0.9,1.2-0.9s9.1-0.1,9.7-0.1c0.6,0,0.9,0.3,0.9,0.3h10.5c0,0,0.5-0.2,0.5-0.5
	s185.7,0,185.7,0l0.4,0.2h78.9c0.7,0,1.2,0.3,1.6,1l1.3,0.1h0.3l0.1,0.4l0.1,1.4h4.7c0.1-5.2,0.1-9.9,0.1-13.4
	c0.1-10.2,1.1-17.4,2.1-25.1c0.9-7.5,2.7-17.3,2.7-17.3s0-2.4,0.8-5.4c0.8-3.1,1.5-4.1,1.5-4.1l0.2-0.6c0,0-0.5-0.2-0.9-0.3
	s-0.5-0.3-0.5-1.1c0-0.8-0.1-2.1,0-3c0-0.9,0.9-1.1,1.2-1.2c0.4-0.1,0.7-0.1,1.3-0.3c0.9-0.3,1.3-0.2,1.3-0.2s1.6-2.1,5.5-2.4
	c3.9-0.3,17-0.3,17-0.3s0.5-0.5,1-0.5c0.5-0.1,1.3-0.1,3.7-0.4c2.4-0.3,3.9-0.7,9.8-0.8c5.8-0.1,25.9-0.4,29.1-0.3
	c3.2,0.1,11.3,1,11.8,1.1c0.4,0.1,0.5,0.5,0.5,0.5l5.7,0.1c0,0,0.2-0.3,0.6-0.4s1.3-0.1,4-0.2c2.7-0.1,5.2-0.4,7.8-0.4
	c2.6,0,18.5,0.5,20.5,0.6c1.9,0,2.3,0.2,3.2,0.3c0.9,0.1,6.5,1,7.8,1.1c1.2,0.3,1.7,0.6,1.7,0.6s1.8,0.2,3.9,0.5
	c2.1,0.3,3.7,0.5,3.7,0.5s0.5-1,3.6-0.1c2.4,0.7,2.9,1.4,2.9,1.4s3.8,1,7.6,1.8c3.6,0.9,7.3,3.1,7.3,3.1s1.4,0.2,2.3,0.6
	c0.9,0.4,0.2,0.8,0.2,0.8s20.4,13.9,33.2,23.2s30.1,22.5,30.1,22.5h1.1l0.3,0.4c0,0,0.8,0,1.2,0.1c0.4,0,0.7,0.2,0.7,0.2l0.5,0.6
	c0,0,0.5-0.5,0.9-0.8c0.4-0.2,0.9,0,0.9,0l0.1-0.3l0.4-0.4c0,0,0.4,0,0.7,0.3c0.2,0.3,1.1,1.1,1.2,1.4c0.2,0.3-0.2,0.7-0.2,0.7
	l-0.3,0.3l0.6,1.1c0,0,0.9,0.2,4.1,0.5c3.3,0.3,6.5,0.5,6.5,0.5l2.1,1.5c0,0,0.3-0.5,1-0.9c0.6-0.4,2.5-1.4,3.1-1.8
	c0.6-0.3,1-0.4,1-0.4s16.2,0.8,20.1,1.1c3.9,0.3,7.1,1.8,7.1,1.8s14,0.9,33,2.7c19,1.8,33.2,3.9,42.7,5.5c9.5,1.6,16.6,4.3,16.6,4.3
	l0.4,0.3c0,0,1.4,0.3,5.4,2.6s5.4,4.9,6,6.7s0.3,2-0.4,2.2c-0.7,0.1-3.3,1.1-3.3,1.1l-0.1,0.8c0,0,0.3,0.1,0.9,0.7
	c0.6,0.5,0.7,1.7,0.7,2.7c0,1-1.3,1-1.3,1v0.4c0,0,2.1,0.2,3.3,0.3c1.1,0.1,2.2,1.4,2.5,3c0.2,1.5,0.3,7.6,0.3,7.6s1,1.4,1.3,2.9
	s0.5,4.1,0.4,7s-1,4.2-1,4.2s0,0.5,0.1,1.3c0.1,0.8,0.1,4.6,0.1,6.5c-0.1,1.9-1.4,2.6-2.6,2.7s-3.7,0.1-3.7,0.1v1
	c0,0,2.3,0.1,2.3,1.1v1.9l-1.5,0.7v1.1c0,0,4.3,1.1,4.6,1.1c0.4,0.1,0.5,0.4,0.5,0.8v2.9c0,0.5-0.4,0.7-0.4,0.7l-0.6,0.5
	c0,0,0.2,0.4,0.5,1.1c0.2,0.6,0.8,1.7,0.8,1.7s2.2,0.4,4.1,0.7c1.9,0.2,2.3,1.1,2.3,1.1s1.4,0.3,1.4,1.7c0,0.9,0.1,1.9,0.1,1.9
	s0.2,0.1,0.6,0.1c0.3,0,0.4,0.3,0.4,1s-0.8,18.8-0.8,19.3s-0.4,0.5-0.4,0.5l-1.9,0.3c0,0-0.6,1.4-2.5,2.9c-2,1.4-4.8,1.2-4.8,1.2
	v0.3c0,0,0,0.2-0.4,0.5s-2.6,1.4-3.6,2.4c-1,1-1.1,1-1.6,2.4c-0.4,1.3-1.6,8.9-1.7,9.9c-0.1,0.9,0.3,1.2,0.3,1.2s0.9,0.7,1.1,1
	c0.3,0.2,0.3,0.7,0.3,0.7v1.8c0,0.5-0.3,0.5-0.3,0.5s-16.4,0.1-22.7,0.1c-6.3-0.1-6.9-1.1-8-3.3c-1.1-2.2-1.3-3.6-2.4-7.8
	c-0.9-3.8-1-9-1-9s-0.2-0.5-0.8-0.6c-0.6-0.2-0.9-0.8-1.1-3.1c-0.3-3.6-0.8-8.7-1.7-13.1c-1-4.4-2.1-8-4-12.9
	c-1.5-4-3.7-7.7-3.7-7.7s-1.2-1.3-0.8-1.9l0.1-0.1l-2.8-3.6c-0.6-0.2-1-0.5-1.2-0.9c-0.5-0.7-1.7-3.3-7.8-8
	c-6-4.9-18.4-10.2-37-10.2s-32.9,6-39.7,12.6c-6.8,6.6-11.1,14.4-14,23.2c-2.9,8.8-4.3,21.9-4.5,24.3c-0.3,2.5-0.9,2.7-2.6,2.6
	s-8.1-0.4-9.8-0.6c-1-0.1-1.3-1-1.3-1.9c0.1,0-211.2,0.5-215.4,0.5c-3.8,0-4.6-0.4-5-3.4h-3.9c-0.3,0.8-1.1,1.1-2.2,1.1
	c-1.7,0.1-84.2,1.3-84.2,1.2C270.2,343,269.1,342.8,268,343L268,343z"
          />
          <path
            fill="#FFFFFF"
            d="M615.8,230.8c0.1-0.1,0.3-0.3,0.5-0.4c0.6-0.4,2.5-1.4,3.1-1.8c0.6-0.3,1-0.4,1-0.4s16.2,0.8,20.1,1.1
	c3.9,0.3,7.1,1.8,7.1,1.8h0.3l-0.2,0.3c0,0-15.6-0.9-21.3-1.1C620.5,230.2,615.8,230.8,615.8,230.8L615.8,230.8z"
          />
          <path
            fill="#5E5E5E"
            d="M755.1,342.4c-1,0.2-1.7,0.1-1.7,0.1v0.3c0,0,0,0.2-0.4,0.5s-2.6,1.4-3.6,2.4c-1,1-1.1,1-1.6,2.4
	c-0.4,1.3-1.6,8.9-1.7,9.9c-0.1,0.9,0.3,1.2,0.3,1.2s0.9,0.7,1.1,1c0.3,0.2,0.3,0.7,0.3,0.7v1.8c0,0.5-0.3,0.5-0.3,0.5
	s-16.4,0.1-22.7,0.1c-6.3-0.1-6.9-1.1-8-3.3c-1.1-2.2-1.3-3.6-2.4-7.8c-0.9-3.8-1-9-1-9s-0.1-0.2-0.3-0.4L755.1,342.4L755.1,342.4z"
          />
          <path
            fill="#5E5E5E"
            d="M753.6,309.1c0,0,0.2,0.4,0.5,1.1c0.2,0.6,0.8,1.7,0.8,1.7s2.2,0.4,4.1,0.7c1.9,0.2,2.3,1.1,2.3,1.1
	s1.4,0.3,1.4,1.7c0,0.9,0.1,1.9,0.1,1.9s0.2,0.1,0.6,0.1c0.3,0,0.4,0.3,0.4,1s-0.8,18.8-0.8,19.3s-0.4,0.5-0.4,0.5l-1.9,0.3
	c0,0-0.6,1.4-2.5,2.9c-2,1.4-4.8,1.2-4.8,1.2v-0.4l-40.8,0.1h-0.1c-0.6-0.2-0.9-0.8-1.1-3.1c-0.3-3.6-0.8-8.7-1.7-13.1
	s-2.1-8-4-12.9c-1.5-4-3.7-7.7-3.7-7.7s-1.2-1.3-0.8-1.9l0.1-0.1l32-0.4l6.3,0.6l2.7,0.4l1.3,0.4l1,0.6l1.1,0.3l1.5,0.3l1.7,0.5
	l1.6,0.6l1,0.6l1.3,1.5l0.5,0.9L753.6,309.1L753.6,309.1z"
          />
          <path
            fill="#FFFFFF"
            d="M367.8,178.3L367.8,178.3c0-0.1-0.5-0.3-0.9-0.4s-0.5-0.3-0.5-1.1c0-0.8-0.1-2.1,0-3c0-0.9,0.9-1.1,1.2-1.2
	c0.4-0.1,0.7-0.1,1.3-0.3c0.9-0.3,1.3-0.2,1.3-0.2l0.1,0.1C369.4,173.8,368.5,175.9,367.8,178.3L367.8,178.3z"
          />
          <path
            fill="#EAEBEB"
            d="M679.8,349.2c0-15-12.2-27.3-27.3-27.3c-15,0-27.3,12.1-27.3,27.3c0,15,12.2,27.3,27.3,27.3
	C667.7,376.4,679.8,364.2,679.8,349.2L679.8,349.2z M226.8,349.2c0-15-12.1-27.3-27.3-27.3c-15,0-27.3,12.1-27.3,27.3
	c0,15,12.1,27.3,27.3,27.3C214.6,376.4,226.8,364.2,226.8,349.2L226.8,349.2z"
          />
          <path
            fill="#5E5E5E"
            d="M154,349.2c0-25.2,20.4-45.6,45.6-45.6c9.5,0,18.3,2.9,25.5,7.8c10.5,7.1,17.9,18.5,19.7,31.6
	c0.3,2,0.4,4.1,0.4,6.2v1l23.9-1.4l8.8-0.6v4.7c0,0.5,0.5,0.3,0.5,0.3s0.5,0.2,0.5-0.5l76.9,0.1c0,0-0.1,0.6,0.2,0.6
	s0.9,0.1,0.9-0.7v-5H594l13.9,9.5c-0.5-2.6-0.7-5.2-0.7-7.9c0-25.2,20.4-45.6,45.6-45.6c19.3,0,35.8,12,42.5,28.9l7.8-0.7l7.6,3
	c0,0,0.2,2.4,0.2,4.1c0,1.6,0.5,3.8,1.7,3.9h0.7l0.1-0.1c-0.1-0.1-0.3-0.3-0.6-0.4h-0.1c-0.6-0.1-0.9-0.7-1.1-3.1
	c-0.3-3.6-0.8-8.7-1.7-13.1c-1-4.4-2.1-8-4-12.9c-1.5-4-3.7-7.7-3.7-7.7s-1.2-1.3-0.8-1.9c0,0,0-0.1,0.1-0.1l-2.8-3.6
	c-0.5-0.2-0.9-0.4-1.1-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.5-0.7-1.7-3.3-7.8-8c-6-4.9-18.4-10.2-37-10.2s-32.9,6-39.7,12.6
	c-6.8,6.6-11.1,14.4-14,23.2c-2.9,8.8-4.3,21.9-4.5,24.3c-0.3,2.5-0.9,2.7-2.6,2.6c-0.8,0-2.5-0.1-4.4-0.2c-4.1,0-7.3-0.2-6.8-2.2
	c-69.7,0.1-212.7,0.4-215.3,0.4c-3.8,0-4.6-0.4-5-3.3h-3.9c-0.3,0.8-1.1,1-2.2,1c-1.4,0.1-63.6,1-80.2,1.2c-2.4,0-3.8,0.1-4,0.1
	c0,0.7,0,1.2-0.1,1.6c-0.3,0.9-1,1-1.7,1.1h-0.2c0,0-2.9,0.1-3.5,0.1s-0.8-0.4-1-0.7c-0.2-0.4-0.8-1.8-0.8-1.8l-3.2,0.1
	c0,0-0.3,1.3-0.4,1.6c-0.1,0.4-0.4,0.6-0.4,0.6s-1,0.2-2,0.2s-1-1.1-1.1-2.2v-0.2c-0.2-1.1-1-10-2.7-19.6c-0.8-4.2-2.1-9.2-4.6-14.3
	c-2.5-5.2-6.2-10.3-11.7-14.9c-10.8-9-28.7-10.9-36.5-11s-21.5,0-32.5,6.5s-16.2,11.9-20.1,21.8c-2.9,7.4-3.9,15-4.3,20.2
	c-0.2,1.8-0.2,3.4-0.3,4.5c-0.3,4.3,0.6,4.9,1.5,7.6c1,2.7-1.1,2.1-1.1,2.1s-1.1,0.1-2.3,0.1c-1.1,0-1.7-0.8-1.7-0.8l-0.6-1.9
	l-3.3-0.1c0,0-0.5,1.1-0.8,1.7s-0.8,0.6-0.8,0.6s-1.3-0.1-3.2-0.3c-0.4-0.1-0.8-0.2-1-0.5c-0.4-0.5-0.4-1.3-0.4-2.2
	c-1-0.1-4.5-0.3-8.7-0.5c-2.9-0.2-6-0.3-8.8-0.5l-29.1-0.7h-1.1c-1.1-0.1-1.2-0.5-1.4-1.2h-3.2v0.1c0,0,0.4-0.1-0.3,0.9
	c-0.7,1-1.1,1.8-3.3,2.7c-2.3,0.8-4.3,1.2-5,1.4c-0.7,0.3-1.6,0.5-1.7,1.4v0.6l-10.5,0.2l-0.1,0.5h-4.2v-0.5h-1.8v8.6h1.8v-0.7h1.1
	v0.8h13.8v2h10.6c0,0,0.7-0.1,1.2-0.4c0.5-0.3,4.2-2.2,5.5-2.9c0.3-0.2,0.5-0.3,0.5-0.3s0.7,0.7,2.5,0.8c1.5,0.1,44.9,3.1,61.3,4.1
	c2.7,0.2,4.6,0.3,5.6,0.4C154.2,354.7,154,352,154,349.2L154,349.2z"
          />
          <path
            fill="#5E5E5E"
            d="M74.9,316.8c0.3-1.3-0.1-2.8-0.7-4.3c-0.6-0.8-1.4-1.1-2.7-1.2c-5.1,0.1-18,0.3-18.5,0.3
	c-0.9,0.1-1.1,0.7-1.1,0.7h-1.1c0,0-0.8,0.2-0.9,1.4c-0.1,1.3,0,2.2,0.1,2.9c0.1,0.8,0.2,1.3,0.2,1.3s-0.2,2.2-0.2,4.8
	c0,2.6,0.3,8.7,0.4,12.1c0.1,3.4,0,3.6,0.4,4.6c0.4,1,1.3,2,2.5,1.8c1.1-0.2,2.6-0.6,3.1-0.7c0.5-0.2,0.7-0.1,0.6-0.6V338
	c0,0,15.1-0.3,20.7-0.4l0.5-1.6l-0.3-4l-0.3,0.2C76.6,325.3,75.9,317.3,74.9,316.8L74.9,316.8z"
          />
          <path
            fill="#FFFFFF"
            d="M67.9,247c0-0.1,0-0.3,0.1-0.5c0.4-0.7,1.3-3.6,2.6-4c0.1,0,0.3-0.1,0.4-0.1l1-1.2c-2.7,0-5.8,0.1-6.3,0.1
	c-0.7,0-1,0.2-1.2,0.9c-0.3,0.8-0.5,2.1,0.1,2.6c0.6,0.5,1.2,1.1,1.1,1.5c-0.1,0.3-0.1,0.5-0.1,0.6h0.1L67.9,247L67.9,247z
	 M63.7,254.1c-0.3,0.1-1.1,0.4-1.3,0.7c-0.4,0.3-0.7,1-0.7,1.4c-0.1,0.6-0.1,1.2-0.2,1.7s0.1,0.7,0.4,0.7h0.9v0.1h0.2
	C63.2,256.9,63.6,254.7,63.7,254.1L63.7,254.1z"
          />
          <path
            fill="#EAEBEB"
            d="M62.7,291.7c0.2,2.5,0.2,3,0.3,3.4s0.8,1.1,0.8,1.1s-0.1,0-0.1,0.1c1.5,0,5.6,0.1,7.9,0.1
	c2.8,0,4.6-1.8,5.6-3.2s1.1-4.4,0.7-9.2c-0.5-4.9-2.1-13.8-2.5-16.5c-0.5-3.6-1.7-5.8-3.3-7.7c-1.2-1.4-4.2-2.8-5.5-3.3
	c0,0-0.6-0.2-1.1,0.2c-0.5,0.4-1.4,1.1-1.6,1.3v0.1c-0.5,4-0.9,7.4-1.1,9.4c0,0.2-0.3,1.8-0.3,2c0,0.6-0.1,1.1-0.1,1.5
	c-0.1,1.2,0,2.7,0,2.7s-1.1,1.3-1.1,4.4c-0.2,5.4,1.1,6.1,1.1,6.1s0.1,1.1,0.2,2.4C62.5,288.3,62.7,290.3,62.7,291.7
	C62.7,291.6,62.7,291.6,62.7,291.7L62.7,291.7z"
          />
          <path
            fill="#FFFFFF"
            d="M506.9,173.4c0.6,0.1,5.9,1.3,6.7,1.3c0.7,0,1-0.1,1-0.3s-0.1-0.7-0.4-1.1c-0.2-0.3-0.7-1-2.3-1.3
	c-1.5-0.4-3.2-0.6-3.8-0.2c-0.2,0.1-0.6,0.3-1,0.7C506.7,172.9,506.3,173.3,506.9,173.4L506.9,173.4z M498.8,171.5
	c0.9,0,1.1-0.2,1.2-0.3v-0.3c0,0-0.5-0.4-1.7-0.6c-1.2-0.3-6.9-1.1-7.8-1.1c-0.9-0.1-1.1-0.2-3.2-0.3c-1.9-0.1-17.9-0.6-20.5-0.6
	c-2.6,0-5.1,0.3-7.8,0.4c-2.7,0.1-3.6,0.2-4,0.2c-0.3,0-0.5,0.2-0.6,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.5,0.4,1.4,0.5
	c1.3,0.1,27.7,1,30.3,1.1C488.9,171.3,497.4,171.5,498.8,171.5L498.8,171.5z M450.6,240.2c0.1,0.7,0.8,2.3,3.7,2.4
	c4.2,0.1,25.5,0.5,34.8,0.5c9.3,0,16.8,0,20.7,1.1c3.8,1.1,8.1,2.7,10.4,3.9c1.8,1,3.8,2.1,7.8,2.5c1,0.1,2.1,0.2,3.4,0.1
	c3.2,0,11.8,0.1,20.1,0.1h18.9c0.1,0,0.1,0,0.2-0.1h0.1c0.1,0,0.2-0.1,0.2-0.1h0.2l0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2h0.2l0.1-0.1
	l0.2-0.2v-2c-0.3-1.9-1.9-10.2-4-13.7l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1h-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.8-4l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1h-0.5
	c-0.6-2.3-2.3-5.6-5.8-6.1h-0.1l-0.5-0.1c-1.1-0.9-10-8.2-16-12.7c-6.4-4.8-14.7-11.3-21.5-13.6c-6.8-2.3-15.2-3.3-23-3.4
	c-7.7-0.2-24.7-0.5-29.1-0.5c-4.5,0-8.2,1.7-8.3,7.4s0,14.4,0.2,24.4c0.2,9.9,0.1,25.7,0.1,25.7S450.6,240,450.6,240.2L450.6,240.2z
	 M448.8,169.4c0,0,0.1-0.2,0.1-0.3h-0.1c0,0-0.2-0.4-0.5-0.5c-0.5-0.1-8.6-1-11.8-1.1c-3.2-0.1-23.2,0.2-29.1,0.3
	c-5.8,0.1-7.4,0.5-9.8,0.8c-2.4,0.3-3.3,0.3-3.7,0.4c-0.5,0.1-1,0.5-1,0.5h-0.2c0.1,0.2,0.3,0.4,0.5,0.4c0.3,0.1,0.8,0.2,1.5,0.2
	c1.6,0.1,48-0.3,50.5-0.3C447.3,170,448.4,169.6,448.8,169.4z M440.4,258.4l0.1,14.9c0,1.6,1.2,2.9,2.8,2.9h10.1
	c1.6,0,2.8-1.2,2.8-2.9l0.1-14.9c0-1.6-1.1-2.8-2.7-2.8h-10.3C441.6,255.6,440.4,256.8,440.4,258.4L440.4,258.4z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M564.6,202.5c-10.4-7.7-25.7-18.2-31.2-21.9c-1.2-0.9-2-1.3-2-1.3h0.1c0,0-6.2,1.8-7.9,2.5
		c-1.6,0.8-2.4,1-0.5,2.6c1,0.8,5.3,3.8,10.3,7.6c5.3,3.8,11.3,8.3,15.6,11.9c8.5,6.9,20.1,16.6,25,20.9c5,4.4,7.5,6.7,8.5,7.5
		c0.4,0.3,0.8,0.6,1,0.8c4.9-2.3,9.2-4.8,11.5-7.8h-0.2C594.8,225,577.5,212,564.6,202.5L564.6,202.5z M425.5,180
		c-3.8-0.2-26.2-0.1-29.9-0.1c-3.6,0.1-7.9,0.8-8.5,6.8c-0.7,6.1-1.5,13.7-2,21.7c-0.5,8.1-1.2,21.7-1.1,24.4
		c0.1,2.8,0.8,8.6,8.2,8.5c7.5-0.1,28.7,0,28.7,0c5.1,0.1,10-1.4,10.2-8.3s0.5-20.5,0.7-26.6c0.2-5.8,0.5-14,0.6-18.7
		C432.5,183.3,429.5,180.2,425.5,180L425.5,180z M529,199.9c-7.5-5.7-14.3-10.5-20.3-12.3c-6.1-1.7-10.7-2.5-20.3-2.9
		s-26-1-29.6-0.8c-3.5,0.2-6.4,1-6.6,5.2s-0.1,16.7,0,25.8c0.1,8.7,0.4,23.8,0.4,25.3c7.8,0.1,35.7,0.5,47.5,0.7
		c13.7,0.2,19.7,4.4,21.8,5.5c2.2,1.1,3.9,1.6,6.7,1.7c1.8,0.1,3.7,0.1,4.9,0.1h0.8c-0.3-0.1-0.6-0.3-0.8-0.4
		c-0.9-0.6-1.1-1.3-1.1-2.5c0-1.7,0.3-5.5,1-14.7l0.1-1.1c0.7-8.3,1-10.7,1.5-14c0.6-3.4,2.5-3.4,4.2-3.4c1,0,3.3,0,5.7,0.2
		c-0.9-0.7-5.9-4.8-11.5-9.1C531.9,202.1,530.4,200.9,529,199.9L529,199.9z M746.5,316c-1.1-1.3-2.2-1.5-3.4-1.5
		c-1.2,0-5.3-0.2-8.3-0.1c-2.5,0.1-3.2,2.1-3.3,2.7v0.6c-0.2,3.9-0.3,8.2-0.3,10.4c0.1,4.3,2.7,4.5,4.2,4.5s8.4,0.1,11-0.1
		c2.6-0.2,2.6-2.9,2.7-4.2c0.1-1.3,0.1-3.4,0.2-6.3C749.2,319.1,747.5,317.3,746.5,316L746.5,316z M734.5,296.8c0,0,0.5-0.5,0.5-1.7
		c0-1.3,0.1-11.3-0.1-13.4c-0.1-2.1-0.6-2.9-0.6-2.9v-1.5c0,0,0.3-0.8,0.3-2s-0.6-9.4-0.8-12.1c0-0.9-0.2-1.5-0.3-2.1
		c-0.3-1.1-0.7-1.5-0.7-1.5v-2c-4.4-0.1-17.1-0.3-32.5-0.6l0.3,8.6c0.1,2.4,0.7,2.8,1.9,3.7c1.1,0.8,3.8,2.2,8.7,8
		c0.4,0.5,0.8,1,1.2,1.5c0.6,0.7,1.1,1.4,1.6,2.2c5.9,7.9,8.1,13.8,8.9,16c0.1,0.3,0.2,0.5,0.2,0.7c0.4,1.3,1.2,1.1,1.2,1.1h9.9
		L734.5,296.8L734.5,296.8z"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M749.6,297.5c0.9,0,2.1,0,2.8-0.1c1.1-0.1,2.5-0.9,2.6-2.7c0.1-1.9,0-5.6-0.1-6.5c-0.1-0.9-0.1-1.3-0.1-1.3
	s0.8-1.3,1-4.2c0.1-2.9,0-5.5-0.4-7s-1.3-2.9-1.3-2.9s-0.1-6-0.3-7.6c-0.2-1.5-1.2-2.9-2.5-3c-0.9-0.1-2.3-0.2-3-0.3h-0.3v-0.4
	c0,0,1.3-0.1,1.3-1.1c0-1.1,0-2.2-0.7-2.7c-0.6-0.5-0.9-0.7-0.9-0.7l0.1-0.8c0,0,2.6-1,3.3-1.1c0.4-0.1,0.7-0.2,0.7-0.7l-4.3,1.3
	c0,0-1.5,0.1-1.3,2.8c0.1,2.8,0.9,15.3,1,22.7c0.1,7.4-0.3,16.2-0.1,18.7c0.1,1.9,0.8,4.4,1.1,5.5l0.9,0.3c0.2,0.1,0.5,0.2,0.7,0.2
	c0.3,0.1,0.6,0.2,0.9,0.4c0.4,0.2,0.7,0.4,1,0.6c0.7,0.5,1.1,1,1.3,1.5c0.2,0.3,0.4,0.6,0.5,0.9l0.3-0.4l0.6-0.5
	c0,0,0.4-0.2,0.4-0.7V305c0-0.4-0.2-0.8-0.5-0.8l-4.6-1.1V302c0,0,1.5,0.1,1.5-0.7v-1.9c0-0.9-2.3-1.1-2.3-1.1v-1
	C748.7,297.4,749.1,297.4,749.6,297.5L749.6,297.5z M649.1,328.1c-0.7,0.2-1.9,0.6-1.4,2c0.4,1.4,0.7,1.8,1.3,2.7
	c0.7,0.9,1.4,1.1,2.4,1.2h2.8c0.7,0,1.5-0.4,2.1-1c0.6-0.7,1.3-2.1,1.4-2.7s0.3-1.8-1.1-2.1c-1.3-0.3-2.5-0.4-3.9-0.4
	S649.8,328,649.1,328.1L649.1,328.1z M667.2,340.8c-0.6,0.9-0.7,1.7-0.4,2.7l0.9,2.6c0.2,0.7,0.8,1.3,1.6,1.7s2.4,0.6,3,0.6
	c0.6-0.1,1.8-0.3,1.7-1.6s-0.4-2.5-0.9-3.8c-0.4-1.3-1.1-2.7-1.3-3.3c-0.4-0.6-1.1-1.6-2.4-0.8C668.1,339.5,667.8,339.9,667.2,340.8
	L667.2,340.8z M660.4,362.3c-0.6,0.4-1.1,1.1-1.1,2.1c-0.1,1,0.2,2.4,0.4,3c0.3,0.6,0.9,1.6,2.1,1.1s2.2-1.2,3.3-2
	c1.1-0.9,2.2-1.8,2.7-2.4c0.5-0.5,1.1-1.5,0-2.5c-1.1-1-1.6-1-2.6-1.3c-1.1-0.3-1.9-0.1-2.7,0.5
	C661.8,361.3,660.4,362.3,660.4,362.3z M637.5,361.7c-0.5,0.4-1.2,1.3-0.4,2.3c1,1.1,1.8,1.7,3,2.6c1.1,0.8,2.5,1.5,3.1,1.8
	c0.7,0.3,1.8,0.7,2.4-0.8c0.5-1.4,0.5-1.8,0.5-2.9s-0.5-1.8-1.2-2.4c-0.8-0.6-2.3-1.5-2.3-1.5c-0.5-0.4-1.4-0.6-2.3-0.4
	C639.4,360.6,637.9,361.3,637.5,361.7L637.5,361.7z M633.7,339.1c-0.7,1.2-1,2.3-1.5,3.6c-0.4,1.3-0.7,2.8-0.8,3.4s0,2,1.4,2
	c1.5,0,1.9-0.1,2.9-0.5s1.5-1.1,1.9-1.9c0.3-0.9,0.8-2.6,0.8-2.6c0.2-0.7,0.1-1.5-0.4-2.3s-1.5-1.8-2.1-2.1
	C635.5,338.4,634.4,337.8,633.7,339.1L633.7,339.1z"
          />
          <ellipse fill="#5E5E5E" cx="652.6" cy="349.2" rx="4.5" ry="2.1" />
          <path
            fill="#5E5E5E"
            d="M196.1,328.1c-0.7,0.2-1.9,0.6-1.4,2c0.4,1.4,0.7,1.8,1.3,2.7c0.7,0.9,1.4,1.1,2.4,1.2h2.8
	c0.7,0,1.5-0.4,2.1-1c0.6-0.7,1.3-2.1,1.4-2.7s0.3-1.8-1.1-2.1c-1.3-0.3-2.5-0.4-3.9-0.4C198.2,327.8,196.8,328,196.1,328.1
	L196.1,328.1z M214.1,340.8c-0.6,0.9-0.7,1.7-0.4,2.7l0.9,2.6c0.2,0.7,0.8,1.3,1.6,1.7c0.9,0.4,2.4,0.6,3,0.6
	c0.6-0.1,1.8-0.3,1.7-1.6s-0.4-2.5-0.9-3.8c-0.4-1.3-1.1-2.7-1.3-3.3c-0.4-0.6-1.1-1.6-2.4-0.8C215.1,339.5,214.8,339.9,214.1,340.8
	L214.1,340.8z M209.6,360.7c-0.8,0.5-2.2,1.6-2.2,1.6c-0.6,0.4-1.1,1.1-1.1,2.1c-0.1,1,0.2,2.4,0.4,3c0.3,0.6,0.9,1.6,2.1,1.1
	s2.2-1.2,3.3-2c1.1-0.9,2.2-1.8,2.7-2.4c0.5-0.5,1.1-1.5,0-2.5c-1.1-1-1.6-1-2.6-1.3C211.2,359.9,210.4,360.2,209.6,360.7
	L209.6,360.7z M187.2,360.4c-0.9,0.3-2.3,1-2.7,1.3c-0.5,0.4-1.2,1.3-0.4,2.3c1,1.1,1.8,1.7,3,2.6c1.1,0.8,2.5,1.5,3.1,1.8
	c0.7,0.3,1.9,0.7,2.4-0.8c0.5-1.4,0.5-1.8,0.5-2.9s-0.5-1.8-1.2-2.4s-2.3-1.5-2.3-1.5C188.9,360.3,188.1,360.1,187.2,360.4
	L187.2,360.4z M180.7,339.1c-0.7,1.2-1.1,2.3-1.5,3.6c-0.4,1.3-0.7,2.8-0.8,3.4s0,2,1.4,2c1.5,0,1.9-0.1,2.9-0.5
	c1-0.4,1.5-1.1,1.9-1.9c0.3-0.9,0.8-2.6,0.8-2.6c0.2-0.7,0.1-1.5-0.4-2.3s-1.5-1.8-2.1-2.1C182.4,338.4,181.4,337.8,180.7,339.1
	L180.7,339.1z M199.6,347.1c-2.5,0-4.5,1-4.5,2.1c0,1.1,2,2.1,4.5,2.1c2.5,0,4.5-1,4.5-2.1C204.1,348.1,202.1,347.1,199.6,347.1
	L199.6,347.1z M389,210.1c-0.9,0-1.5,0.7-1.5,1.5c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5C390.5,210.8,389.9,210.1,389,210.1
	L389,210.1z"
          />
          <circle fill="#5E5E5E" cx="428.2" cy="194.8" r="1.5" />
          <path
            fill="#5E5E5E"
            d="M427.5,224.6c-0.9,0-1.5,0.7-1.5,1.5c0,0.9,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5
	C429.1,225.3,428.4,224.6,427.5,224.6z M595,225c-2.3,3.1-6.6,5.5-11.5,7.8c-0.3-0.2-0.6-0.5-1-0.8c-1.1-0.8-3.5-3-8.5-7.5
	c-5-4.4-16.5-14.1-25-21c-8.5-6.9-24-17.8-25.9-19.4c-1.9-1.6-1.1-1.9,0.5-2.6c0.1,0,0.1-0.1,0.2-0.1c1.9-0.8,7.7-2.4,7.7-2.4
	c0.1-0.1,0.5-0.4-0.2-0.7s-1.6-0.5-2.1-0.6c-2.7,0.9-7.7,2.4-8.7,2.8c-1.3,0.5-1.7,1.2-0.7,2.4s3.3,3,8,6.4l2,1.6
	c4.2,3.2,8.8,6.8,14,10.7c9.9,7.7,25.9,21.9,33.5,28.2c2.4,2,4.3,3.4,5.9,4.2c0-0.3,0.1-0.6,0.2-0.6c0.4-0.2,1.3-0.3,2.1-0.3
	c1.1,0,7.1-0.5,9.9-0.8c2.8-0.3,3.8,0,6.5,0.5c2.8,0.5,8.3,0.3,10,0.3c1.4-0.1,3.3-1.6,3.9-2.1V231l-0.2,0.2l-2.1-1.5
	c0,0-3.3-0.2-6.5-0.5c-3.3-0.3-4.1-0.5-4.1-0.5l-0.6-1.1l0.3-0.3c0,0,0.3-0.4,0.2-0.7c-0.2-0.3-1.1-1.1-1.2-1.4
	c-0.2-0.3-0.4-0.5-0.7-0.3l-0.4,0.4l-0.1,0.3c0,0-0.5-0.2-0.9,0s-0.9,0.8-0.9,0.8l-0.5-0.6c0,0-0.3-0.2-0.7-0.2s-1.1-0.1-1.1-0.1
	L596,225L595,225L595,225L595,225z M355.7,244.2v5.3c0.1,0.9,0.2,2.2,0.4,2.5v0.1c0,0,0.3,0.3,0.4,0.8c0.1,0.3,0.2,0.6,0.1,1v84.2
	h3.6v-38.6c0-8.5,0-22-0.1-28c0-4.2,0-10.8,0.1-17.7c0-3.2,0.1-6.3,0.1-9.5L355.7,244.2L355.7,244.2z"
          />
          <path
            fill="#EAEBEB"
            d="M539.6,229.3c-1.3-0.1-1.9,0.2-2.1,1.9s-0.5,6.4-0.5,7.6s-0.1,1.7,1.9,1.9l7.7,0.9c0.1-1.1,0.4-3.3,0.6-5.8
	c0.2-2.2,0.3-4.2,0.4-5.6C543.9,229.8,540.2,229.3,539.6,229.3L539.6,229.3z"
          />
          <path
            fill="#FFFFFF"
            d="M581,242.7c0.7,3.8,1.1,6.5,1.3,9.5l47.5,0.8l-0.8-1c-0.4-0.5-5.1-6-12.5-7.1c-7.8-1.1-19.8-2-26.7-2.4
	c-4.9-0.3-8.1-0.5-9-0.5C581,242.3,581,242.5,581,242.7L581,242.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M607.3,356.9c3.6,21.7,22.5,38.3,45.3,38.3c25.4,0,45.8-20.6,45.8-45.8
	c0-5.9-1.1-11.6-3.2-16.7l7.7-0.7l7.6,3c0,0,0.2,2.4,0.2,4.1c0,1.6,0.5,3.8,1.7,3.9c0.5,0.1,0.9,0,0.9,0s0.1,5.2,1,9
	c1,4.2,1.2,5.6,2.4,7.8c1.1,2.1,1.8,3.3,8,3.4c6.3,0.1,22.7-0.1,22.7-0.1s0.3,0,0.3-0.5v-1.8c0,0,0-0.4-0.3-0.7
	c-0.3-0.2-1.1-1-1.1-1s-0.5-0.3-0.3-1.2c0.1-0.9,1.3-8.4,1.7-9.9c0.4-1.3,0.5-1.3,1.6-2.4c1-1,3.3-2.1,3.6-2.4s0.4-0.3,0.4-0.5v-0.3
	c0,0,2.8,0.2,4.8-1.2s2.5-2.9,2.5-2.9l1.9-0.3c0,0,0.4,0.1,0.4-0.5s0.8-18.8,0.8-19.3s0-1-0.4-1s-0.6-0.1-0.6-0.1s-0.1-1-0.1-1.9
	c0-1.3-1.4-1.7-1.4-1.7s-0.4-0.9-2.3-1.1c-1.9-0.2-4.1-0.7-4.1-0.7s-0.6-1-0.8-1.7c-0.2-0.6-0.5-1.1-0.5-1.1l0.6-0.5
	c0,0,0.4-0.2,0.4-0.7v-2.9c0-0.4-0.2-0.8-0.5-0.8l-4.6-1.1v-1.1c0,0,1.5,0.1,1.5-0.7v-1.9c0-0.9-2.3-1.1-2.3-1.1v-1
	c0,0,2.6,0,3.7-0.1s2.5-0.9,2.6-2.7c0.1-1.9,0-5.6-0.1-6.5s-0.1-1.3-0.1-1.3s0.8-1.3,1-4.2c0.1-2.9,0-5.5-0.4-7
	c-0.4-1.4-1.3-2.9-1.3-2.9s-0.1-6-0.3-7.6c-0.2-1.5-1.2-2.9-2.5-3c-1.1-0.1-3.3-0.3-3.3-0.3v-0.4c0,0,1.3-0.1,1.3-1.1
	c0-1.1,0-2.2-0.7-2.7c-0.6-0.5-0.9-0.7-0.9-0.7l0.1-0.8c0,0,2.6-1,3.3-1.1s1.1-0.4,0.4-2.2c-0.6-1.8-2.1-4.4-6-6.7
	c-3.9-2.3-5.4-2.6-5.4-2.6l-0.4,0.1v-0.4c0,0-7.2-2.7-16.6-4.3c-9.5-1.6-23.6-3.7-42.7-5.6c-19-1.8-33-2.7-33-2.7s-3.2-1.5-7.1-1.8
	c-3.9-0.3-20.1-1.1-20.1-1.1s-0.4,0.1-1,0.4s-2.5,1.4-3.1,1.8c-0.6,0.4-1,0.9-1,0.9l-2.1-1.5c0,0-3.3-0.2-6.5-0.5
	c-3.3-0.3-4.1-0.5-4.1-0.5l-0.6-1.1l0.3-0.3c0,0,0.3-0.4,0.2-0.7c-0.2-0.3-1.1-1.1-1.2-1.4c-0.2-0.3-0.4-0.5-0.7-0.3l-0.4,0.4
	l-0.1,0.3c0,0-0.5-0.2-0.9,0s-0.9,0.8-0.9,0.8l-0.5-0.6c0,0-0.3-0.2-0.7-0.2s-1.1-0.1-1.1-0.1l-0.3-0.4h-1.1
	c0,0-17.3-13.1-30.1-22.5s-33.2-23.2-33.2-23.2s0.7-0.4-0.2-0.8c-0.9-0.4-2.3-0.6-2.3-0.6s-3.6-2.2-7.3-3.1
	c-3.6-0.9-7.6-1.8-7.6-1.8s-0.5-0.8-2.9-1.4c-3.2-0.9-3.6,0.1-3.6,0.1s-1.6-0.2-3.7-0.5c-2.1-0.3-3.9-0.5-3.9-0.5s-0.5-0.4-1.7-0.6
	c-1.2-0.3-6.9-1.1-7.7-1.1c-0.9-0.1-1.1-0.2-3.2-0.3c-1.9-0.1-17.9-0.6-20.5-0.6c-2.6,0-5.1,0.3-7.8,0.4c-2.7,0.1-3.6,0.2-4.1,0.2
	c-0.4,0-0.6,0.4-0.6,0.4l-5.7-0.1c0,0-0.2-0.4-0.5-0.5c-0.5-0.1-8.6-1-11.8-1.1c-3.2-0.1-23.1,0.2-29.1,0.3
	c-5.8,0.1-7.4,0.5-9.8,0.8c-2.4,0.3-3.3,0.3-3.7,0.4c-0.5,0.1-1,0.5-1,0.5s-13.1,0.1-17,0.3c-3.9,0.3-5.5,2.4-5.5,2.4
	s-0.5-0.1-1.3,0.2c-0.7,0.2-1,0.2-1.3,0.3s-1.2,0.4-1.2,1.2c-0.1,0.9,0,2.2,0,3s0,1,0.5,1.1c0.4,0.1,0.9,0.3,0.9,0.3l-0.2,0.6
	c0,0-0.8,1.1-1.5,4.1c-0.8,3.1-0.8,5.4-0.8,5.4s-1.8,9.9-2.7,17.3c-0.9,7.7-1.9,14.8-2.1,25.1c-0.1,10.2-0.4,31.4-0.3,40.6
	c0.1,9.2,0.1,35.6,0.1,35.6h-3.6c0,0-0.1-52.2,0-53.3s-0.6-1.7-0.6-1.7s-0.4-0.3-0.4-1.8v-7.2c0-0.8-0.3-0.9-0.6-0.9h-1.2
	c0,0-0.4-1-1.6-1h-78.9l-0.4-0.2c0,0-185.7-0.3-185.7,0s-0.5,0.5-0.5,0.5H75.9c0,0-0.4-0.3-0.9-0.3s-9,0.1-9.7,0.1s-1,0.2-1.2,0.9
	c-0.3,0.8-0.5,2.1,0.1,2.6c0.6,0.5,1.2,1.1,1.1,1.5c-0.1,0.5,0,0.8,0,0.8s-0.6-0.1-0.7,0.2c-0.1,0.3-1.2,6.8-1.2,6.8
	s-1.2,0.4-1.4,0.7c-0.4,0.3-0.7,1-0.7,1.4c-0.1,0.6-0.1,1.2-0.2,1.7s0.1,0.7,0.4,0.7h0.9c0,0-0.4,2.3-0.5,3.2v1.4
	c0,0-0.3,2.9-0.3,3.4c-0.1,0.5,0.4,0.8,0.4,0.8s-0.2,2.6-0.3,3.8c-0.1,1.2,0,2.7,0,2.7s-1.1,1.3-1.1,4.4c-0.2,5.4,1.1,6.2,1.1,6.2
	s0.4,4.7,0.6,7.3c0.2,2.6,0.2,3.1,0.3,3.5s0.8,1.1,0.8,1.1s-0.6,0.3-0.5,1.3c0,1.1,1.2,8.9,1.4,9.9s0.2,1.8,0.9,1.8s6,0.1,7.2,0.1
	c2.5,0,4,2.2,4.4,3.4c0.6,2.1,0.7,6,1.2,8.5c0.4,2.6,1.1,9.3,1.1,9.3l-1.9,1.5c0,0-1.5-12.1-1.8-14c-0.2-1.2-0.7-1.2-0.8-1.2
	c0.1,0,0.5-0.2,0.4-1.1c-0.2-1.1-0.3-2.1-0.6-2.9s-1.4-1.7-3.6-1.7c0,0-16.9,0.3-17.8,0.4c-0.9,0.1-1.1,0.7-1.1,0.7h-1.1
	c0,0-0.8,0.2-0.9,1.4c-0.1,1.3,0,2.2,0.1,2.9c0.1,0.8,0.2,1.3,0.2,1.3s-0.2,2.2-0.2,4.8c0,2.6,0.3,8.7,0.4,12.1
	c0.1,3.4,0,3.6,0.4,4.6c0.4,1,1.3,2,2.5,1.8c1.1-0.2,2.6-0.6,3.1-0.7c0.5-0.2,0.7-0.1,0.6-0.6v-1.8c0,0,16.6-0.6,18.8-0.5
	c1.1,0,1.5,0,1.9-0.3l0.2-0.2l-0.2,0.6c0,0,0.4-0.1-0.3,0.9c-0.7,1-1.1,1.8-3.3,2.7c-2.3,0.8-4.3,1.2-5,1.4
	c-0.7,0.3-1.6,0.5-1.7,1.4v0.6l-10.5,0.2l-0.1,0.5h-3.8v-0.5h-1.8v8.6h1.8v-0.7h1.1v0.8h13.8v2h10.6c0,0,0.7-0.1,1.2-0.4
	c0.6-0.4,6-3.2,6-3.2s0.7,0.7,2.5,0.8c1.8,0.2,63.6,4.3,67,4.5c3.9,21.3,22.6,37.5,45,37.5c24.9,0,45.2-19.9,45.7-44.7l32.5-2v4.7
	l0.5,0.3c0,0,0.5,0.2,0.5-0.5c1.5,0,76.9,0.1,76.9,0.1s-0.1,0.6,0.2,0.6s0.9,0.1,0.9-0.7v-5H594L607.3,356.9L607.3,356.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M356.8,327l3.3,0.5l-0.1,10.2h-3.2L356.8,327z M747.3,360h-27.1
	c0,0,6.7-2.3,12.7-2.5c5.9-0.1,13-0.1,13-0.1 M751.1,342.2h3.1 M270.1,340.3c0,0,82.5-1.2,84.2-1.3c1.7-0.1,2.4-0.5,2.4-2.4v-83.1
	c0,0-0.3-1.1-0.5-1.5c-0.2-0.3-0.4-2.5-0.4-3c0-0.5-0.1-6.6-0.1-6.6l-1.9-0.1c0,0,0.1,1.9-1.5,1.9S86,244.3,81.6,244.3
	s-6.4,0.8-7.8,1.2c-1.3,0.5-3.8,1.4-4.7,1.7c-0.9,0.3-1.5,0.1-1.1-0.6c0.4-0.7,1.3-3.6,2.6-4c1.2-0.3,5.8-1,5.8-1 M71.1,242.4
	l1.1-1.3 M67.8,247l-2.1-0.1c0,0-1.5,6.9-2.1,11.3c-0.6,4.4-0.9,7.9-1.1,9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M68.1,247.1c0,0-1.2,3.8-1.7,5.7c-0.5,1.9-1.1,3.4-0.2,3.6
	c0.9,0.3,4.3,1.7,5.7,3.3c1.5,1.7,2.8,4,3.3,7.7c0.4,2.8,2,11.7,2.5,16.5c0.5,4.9,0.3,7.8-0.7,9.2c-1,1.3-2.8,3.2-5.6,3.2
	s-7.7-0.1-7.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M62.7,291.1c0,0.9,0.4,1.4,1,1.4h2.8c0.5,0,1-0.7,1-1.4v-4.4
	c0-0.9-0.4-1.5-1-1.5h-3.1c-0.5,0-1,0.7-1,1.5 M76.5,311.1c0,0,1.3,1.3,1.9,7.2s1.7,16.7,1.9,18.2c0.2,1.5,0.1,2.1,2.1,2.1
	c1.9,0,19,0.2,26.7,0.5c7.7,0.3,20.5,1.1,20.5,1.1 M356.7,252.8c0,0-280.7,0.9-286,0.9c-3.5,0-4.7,1.1-4.7,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M66.5,256.6c0,0-0.5-0.2-1.1,0.2c-0.6,0.4-1.7,1.4-1.7,1.4s1.5,0,2.9,0.6
	c1.3,0.5,4.7,1.9,5.8,6.9c1.2,5.1,2.7,14.4,3,17.3c0.4,3.3,0.3,4.4,0.3,5.9s-0.8,5.1-4.6,5.3c-3.9,0.2-8,0-8,0 M63.8,254.1
	c0,0-0.5,2.7-0.9,4.7 M356.7,299.6c0,0-75.9-0.1-81.4-0.1c-5.5,0-6.9-1.6-11.2-7.2c-4.3-5.5-12.7-16.3-25.4-23.1
	c-12.7-6.8-28.4-9.5-41-9.1s-27,2.7-39,8.9c-12.1,6.3-21.9,18.5-25.8,24.6c-2.3,3.6-2.8,5.8-12.2,5.8H63.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268,343c1.2-0.2,2.5,0,2-3.6c-0.5-3.6-2.6-20.1-6.4-30.6
	c-3.8-10.6-10.8-21.3-21.6-29.1c-10.8-7.8-26.2-12.1-45.1-11.8c-18.8,0.4-31.6,5.6-41.2,11.8c-9.6,6-16.7,17-20.9,28
	c-4.1,11-4.9,29.4-5,31c-0.1,1.6-0.8,4,1.1,4.2c1.9,0.3,3.2,0.3,3.2,0.3s0.5,0.1,0.8-0.6s0.8-1.7,0.8-1.7l3.2,0.1l0.6,1.9
	c0,0,0.7,0.8,1.7,0.8c1.1,0,2.3-0.1,2.3-0.1s2,0.6,1.1-2.1c-1-2.7-1.8-3.3-1.5-7.6c0.3-4.3,0.7-14.7,4.6-24.7s9.2-15.4,20.1-21.8
	c11-6.5,24.7-6.6,32.5-6.5s25.7,1.9,36.5,11c10.8,9,14.7,20.7,16.3,29.1c1.7,9.7,2.6,18.5,2.7,19.6c0.2,1.1,0.1,2.5,1,2.5
	s2-0.2,2-0.2s0.3-0.2,0.4-0.6s0.4-1.6,0.4-1.6l3.2-0.1c0,0,0.7,1.5,0.8,1.8c0.2,0.4,0.3,0.7,1,0.7C265.1,343.1,268,343,268,343
	L268,343z M50.8,312.4l23.2-0.1 M74.8,317c0,0-21.8,0.2-22.9,0.2c-1.1,0.1-1.6,0.7-1.6,0.7 M77.7,332l0.1,5.1 M56.7,340
	c0,0-2.1-0.3-3.2-3c-1-2.7-3.3-9-3.4-14.3 M78.1,337.2h2.5 M84.7,352.1c0,0,12.2-5.6,19.3-8.8c7-3.3,8.1-3.8,8.1-3.8 M67.3,344.2
	l0.3,9.5 M52.4,345.2v7.6 M52.5,345.5h14.8 M52.5,352.6h15.1 M70.9,352.3c0,0.3,0.3,0.6,0.6,0.6h6c0.3,0,0.6-0.3,0.6-0.6v-6.1
	c0-0.3-0.3-0.6-0.6-0.6h-6c-0.3,0-0.6,0.3-0.6,0.6L70.9,352.3L70.9,352.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M72,351.3c0,0.3,0.2,0.5,0.5,0.5h3.8c0.3,0,0.5-0.2,0.5-0.5v-4.1
	c0-0.3-0.2-0.5-0.5-0.5h-3.8c-0.3,0-0.5,0.2-0.5,0.5L72,351.3L72,351.3z M355.6,249.4H170.8c0,0-86.6,0.5-92.9,0.5
	c-6.3,0-10.8,1-10.8,1 M245.2,349.2c0-25.2-20.4-45.6-45.6-45.6c-25.2,0-45.6,20.4-45.6,45.6c0,25.2,20.4,45.6,45.6,45.6
	C224.7,394.7,245.2,374.4,245.2,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226.8,349.2c0-15-12.1-27.3-27.3-27.3c-15,0-27.3,12.1-27.3,27.3
	c0,15,12.1,27.3,27.3,27.3C214.6,376.4,226.8,364.2,226.8,349.2L226.8,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.6,349.2c0-14.4-11.7-26-26-26s-26,11.7-26,26c0,14.4,11.7,26,26,26
	C214,375.2,225.6,363.6,225.6,349.2z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="199.6"
            cy="349.2"
            r="13.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M211.6,349.2c0-6.7-5.4-12.1-12.1-12.1c-6.7,0-12.1,5.5-12.1,12.1
	c0,6.7,5.4,12.1,12.1,12.1S211.6,355.8,211.6,349.2L211.6,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.2,349.2c0-3.6-3-6.6-6.6-6.6c-3.6,0-6.6,3-6.6,6.6
	c0,3.6,3,6.6,6.6,6.6C203.2,355.7,206.2,352.8,206.2,349.2z M201.1,340.6c0,1.2,1.1,2.3,2.3,2.3s2.3-1,2.3-2.3
	c0-1.2-1.1-2.3-2.3-2.3C202.1,338.3,201.1,339.3,201.1,340.6 M201.5,340.6l1-1.6h1.8l1,1.6l-1,1.6h-1.8L201.5,340.6z M207.1,343.8
	c-1.1,0.7-1.4,2-0.8,3.1c0.7,1.1,2,1.4,3.1,0.8c1.1-0.7,1.4-2,0.8-3.1C209.6,343.6,208.2,343.2,207.1,343.8 M207.3,344.1h1.8l1,1.6
	l-0.9,1.6h-1.8l-1-1.6L207.3,344.1L207.3,344.1z M207,350.9c-1.1,0.6-1.4,2-0.9,3.1c0.6,1.1,2,1.4,3.1,0.9c1.1-0.6,1.4-2,0.9-3.1
	C209.5,350.6,208.2,350.3,207,350.9 M207.3,351.2h1.8l1,1.6l-1,1.6h-1.8l-1-1.6L207.3,351.2L207.3,351.2z M194.8,342.5
	c1.1,0.6,2.5,0.2,3.1-0.9c0.6-1.1,0.2-2.5-0.9-3.1c-1.1-0.6-2.5-0.2-3.1,0.9C193.3,340.5,193.7,341.9,194.8,342.5 M195.1,342.1
	l-1-1.6l0.9-1.6h1.8l1,1.6l-0.9,1.6L195.1,342.1L195.1,342.1z M191.9,347.8c1.1-0.6,1.4-2,0.9-3.1c-0.6-1-2-1.4-3.1-0.9
	c-1.1,0.6-1.4,2-0.9,3.1C189.5,348.1,190.9,348.4,191.9,347.8 M191.7,347.5h-1.8l-0.9-1.6l1-1.6h1.8l0.9,1.6L191.7,347.5z
	 M192.1,354.9c1.1-0.6,1.4-2,0.9-3.1c-0.6-1.1-2-1.4-3.1-0.9c-1.1,0.6-1.4,2-0.9,3.1C189.7,355.1,191.1,355.5,192.1,354.9
	 M191.9,354.6h-1.8l-1-1.6l1-1.6h1.8l1,1.6L191.9,354.6L191.9,354.6z M198.3,357.8c0-1.2-1.1-2.2-2.3-2.2s-2.2,1.1-2.2,2.3
	s1.1,2.2,2.3,2.2C197.4,360,198.3,359.1,198.3,357.8 M197.8,357.8l-0.9,1.6h-1.8l-1-1.5l0.9-1.6l1.8-0.1L197.8,357.8L197.8,357.8z
	 M205.5,357.7c0-1.2-1.1-2.2-2.3-2.2s-2.2,1.1-2.2,2.3s1.1,2.2,2.3,2.2S205.5,359,205.5,357.7 M205.1,357.7l-0.9,1.6h-1.8l-1-1.5
	l0.9-1.6l1.8-0.1L205.1,357.7L205.1,357.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M204.1,349.2c0-1.1-2-2.1-4.5-2.1s-4.5,1-4.5,2.1c0,1.1,2,2.1,4.5,2.1
	C202.1,351.2,204.1,350.4,204.1,349.2L204.1,349.2z M204.5,340.6c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2
	S204.5,341.3,204.5,340.6L204.5,340.6z M208.2,354.1c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2
	C207,353.5,207.5,354.1,208.2,354.1L208.2,354.1z M196.8,339.6c-0.5-0.5-1.2-0.5-1.7,0.1c-0.5,0.5-0.5,1.2,0,1.7
	c0.5,0.5,1.2,0.5,1.7-0.1C197.3,340.9,197.3,340.1,196.8,339.6L196.8,339.6z M194.8,357.9c0,0.7,0.6,1.2,1.2,1.1
	c0.7,0,1.2-0.6,1.1-1.2c0-0.7-0.6-1.2-1.2-1.1C195.4,356.7,194.8,357.2,194.8,357.9L194.8,357.9z M202.3,358.7
	c0.5,0.5,1.2,0.5,1.7-0.1c0.5-0.5,0.5-1.2-0.1-1.7c-0.5-0.5-1.2-0.5-1.7,0C201.9,357.4,201.9,358.2,202.3,358.7L202.3,358.7z
	 M209.2,346.6c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7C207.9,347.1,208.7,347.1,209.2,346.6
	L209.2,346.6z M190.1,352.2c-0.5,0.5-0.4,1.2,0.1,1.7c0.5,0.5,1.2,0.4,1.7-0.1s0.4-1.2-0.1-1.7S190.6,351.7,190.1,352.2L190.1,352.2
	z M190.8,344.6c-0.7,0-1.2,0.6-1.1,1.2c0,0.7,0.6,1.1,1.2,1.1s1.2-0.6,1.1-1.2C192,345.1,191.4,344.6,190.8,344.6L190.8,344.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M191.4,325.9c-0.4,0.2-0.6,0.4-0.5,0.7l0.1,2.7c0,0.3-0.1,0.8-0.4,1
	c0,0-1.1,1.3-3.1,2.6c-1.9,1.2-3.2,1.9-3.2,1.9c-0.3,0.1-0.8,0.2-1.1,0.1l-2.7-0.9c-0.3-0.1-0.7,0-0.8,0.3l-0.1,0.1
	c-3.1,4.1-5.2,9.4-5.1,14.9v0.9c0,0.3,0.3,0.7,0.6,0.7l2.6,0.7c0.3,0.1,0.7,0.4,0.9,0.6c0,0,1,1.5,1.5,3.7c0.6,2.2,0.9,3.6,0.9,3.6
	c0.1,0.3-0.1,0.8-0.3,1.1l-1.6,2.3c-0.2,0.3-0.2,0.6,0,0.9l0.1,0.2c3.2,4.3,7.6,7.6,12.7,9.2l0.4,0.1c0.3,0.1,0.7,0,0.8-0.2l1.4-2.3
	c0.2-0.3,0.6-0.6,0.9-0.6c0,0,1.7-0.5,4-0.4c2.3,0.1,3.6,0.3,3.6,0.3c0.3,0,0.7,0.3,0.9,0.6l1.6,2.3c0.2,0.3,0.6,0.4,0.9,0.2
	l0.4-0.1c5.1-1.6,9.4-4.9,12.4-9.2l0.2-0.2c0.2-0.2,0.2-0.6,0-0.9l-1.7-2.1c-0.2-0.3-0.4-0.7-0.3-1.1c0,0,0.1-1.8,1-3.9
	c0.8-2.1,1.4-3.4,1.4-3.4c0.2-0.3,0.5-0.6,0.8-0.7l2.7-0.9c0.3-0.1,0.6-0.4,0.6-0.8v-0.6c0-5.5-1.8-10.6-4.9-14.8l-0.2-0.2
	c-0.2-0.3-0.6-0.4-0.9-0.3l-2.5,1c-0.3,0.1-0.8,0.1-1.1,0c0,0-1.6-0.7-3.4-2.1c-1.7-1.4-2.8-2.4-2.8-2.4c-0.2-0.2-0.4-0.7-0.4-1
	v-2.9c0-0.3-0.2-0.6-0.6-0.7l-0.3-0.1c-2.5-0.9-5.1-1.2-7.8-1.2s-5.5,0.5-7.9,1.3L191.4,325.9L191.4,325.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M222.9,357c0.6-1.8,1.1-3.7,1.1-4.1c0.1-0.8,0.1-1.1-0.4-1.1
	s-2.2-0.2-3,1.4c-0.8,1.6-1.2,3.4-1.5,4.5s-0.4,1.5,0.1,2.4c0.5,0.8,0.7,0.9,1.1,1.1c0.3,0.3,0.9,0.5,1.2-0.5
	C221.7,360.4,222.3,358.9,222.9,357L222.9,357z M185.4,329c-1.5,1.1-3.1,2.4-3.3,2.7c-0.6,0.5-0.7,0.9-0.3,1.1
	c0.4,0.3,1.6,1.4,3.3,0.6c1.6-0.9,3.1-2,3.9-2.8s1.2-1.1,1.2-2c0-1,0-1.1-0.1-1.5s-0.4-0.9-1.3-0.4C188.4,327,186.8,328,185.4,329
	L185.4,329z M214.2,329.3c-1.5-1.1-3.3-2.1-3.6-2.3c-0.7-0.4-1.1-0.4-1.1,0.1c-0.2,0.5-0.9,2,0.5,3.3s2.9,2.2,3.8,2.8
	c1,0.6,1.3,0.9,2.3,0.6c0.9-0.3,1.1-0.4,1.4-0.6c0.4-0.2,0.7-0.7-0.1-1.3C217,331.5,215.7,330.4,214.2,329.3L214.2,329.3z
	 M176.1,356.9c0.7,1.8,1.4,3.6,1.6,3.9c0.4,0.7,0.6,1,1,0.7c0.4-0.3,1.9-1.1,1.5-3c-0.4-1.8-1.1-3.4-1.5-4.5
	c-0.5-1.1-0.7-1.5-1.5-1.8c-0.9-0.3-1.1-0.3-1.5-0.3c-0.4,0-1,0.1-0.8,1.1C175.2,353.4,175.6,355.1,176.1,356.9L176.1,356.9z
	 M199.4,373.8c1.9,0,3.9-0.2,4.2-0.3c0.8-0.1,1.1-0.3,1-0.8c-0.2-0.5-0.5-2.2-2.3-2.4c-1.8-0.2-3.6-0.1-4.8,0s-1.6,0.1-2.2,0.9
	c-0.6,0.8-0.7,1-0.8,1.3c-0.2,0.4-0.2,1,0.9,1.1C195.8,373.7,197.6,373.8,199.4,373.8L199.4,373.8z M201.1,333.9
	c0.7,0,1.5-0.4,2.1-1c0.6-0.7,1.3-2.1,1.4-2.7s0.3-1.8-1.1-2.1c-1.3-0.3-2.5-0.4-3.9-0.4s-2.9,0.2-3.5,0.3c-0.7,0.2-1.9,0.6-1.4,2
	c0.4,1.4,0.7,1.8,1.3,2.7c0.7,0.9,1.4,1.1,2.4,1.2C199.3,334,201.1,333.9,201.1,333.9L201.1,333.9z M214.6,346
	c0.2,0.7,0.8,1.3,1.6,1.7c0.9,0.4,2.4,0.6,3,0.6c0.6-0.1,1.8-0.3,1.7-1.6s-0.4-2.5-0.9-3.8c-0.4-1.3-1.1-2.7-1.3-3.3
	c-0.4-0.6-1.1-1.6-2.4-0.8c-1.2,0.9-1.5,1.1-2.1,2c-0.6,0.9-0.7,1.7-0.4,2.7C214,344.3,214.6,346,214.6,346L214.6,346z M185.5,343.2
	c0.2-0.7,0.1-1.5-0.4-2.3s-1.5-1.8-2.1-2.1c-0.6-0.3-1.6-0.9-2.3,0.4c-0.7,1.2-1.1,2.3-1.5,3.6c-0.4,1.3-0.7,2.8-0.8,3.4s0,2,1.4,2
	c1.5,0,1.9-0.1,2.9-0.5c1-0.4,1.5-1.1,1.9-1.9C185,344.9,185.5,343.2,185.5,343.2L185.5,343.2z M189.5,360.7
	c-0.6-0.4-1.4-0.6-2.3-0.4c-0.9,0.3-2.3,1-2.7,1.3c-0.5,0.4-1.2,1.3-0.4,2.3c1,1.1,1.8,1.7,3,2.6c1.1,0.8,2.5,1.5,3.1,1.8
	c0.7,0.3,1.9,0.7,2.4-0.8c0.5-1.4,0.5-1.8,0.5-2.9s-0.5-1.8-1.2-2.4S189.5,360.7,189.5,360.7L189.5,360.7z M207.4,362.3
	c-0.6,0.4-1.1,1.1-1.1,2.1c-0.1,1,0.2,2.4,0.4,3c0.3,0.6,0.9,1.6,2.1,1.1s2.2-1.2,3.3-2c1.1-0.9,2.2-1.8,2.7-2.4
	c0.5-0.5,1.1-1.5,0-2.5c-1.1-1-1.6-1-2.6-1.3c-1.1-0.3-1.9-0.1-2.7,0.5C208.8,361.3,207.4,362.3,207.4,362.3L207.4,362.3z
	 M698.2,349.2c0-25.2-20.4-45.6-45.6-45.6c-25.2,0-45.6,20.4-45.6,45.6c0,25.2,20.4,45.6,45.6,45.6
	C677.8,394.7,698.2,374.4,698.2,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M679.8,349.2c0-15-12.2-27.3-27.3-27.3c-15,0-27.3,12.1-27.3,27.3
	c0,15,12.2,27.3,27.3,27.3C667.7,376.4,679.8,364.2,679.8,349.2L679.8,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M678.7,349.2c0-14.4-11.7-26-26-26s-26,11.7-26,26c0,14.4,11.7,26,26,26
	S678.7,363.6,678.7,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M665.8,349.2c0-7.3-5.9-13.2-13.2-13.2s-13.2,5.9-13.2,13.2
	c0,7.3,5.9,13.2,13.2,13.2C659.8,362.3,665.8,356.5,665.8,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664.7,349.2c0-6.7-5.5-12.1-12.1-12.1c-6.7,0-12.1,5.5-12.1,12.1
	c0,6.7,5.4,12.1,12.1,12.1C659.3,361.3,664.7,355.8,664.7,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M659.2,349.2c0-3.6-3-6.6-6.6-6.6s-6.6,3-6.6,6.6c0,3.6,3,6.6,6.6,6.6
	C656.2,355.7,659.2,352.8,659.2,349.2z M654.1,340.6c0,1.2,1.1,2.3,2.3,2.3c1.2,0,2.3-1,2.3-2.3c0-1.2-1.1-2.3-2.3-2.3
	C655.1,338.3,654.1,339.3,654.1,340.6 M654.5,340.6l1-1.6h1.8l1,1.6l-1,1.6h-1.8L654.5,340.6L654.5,340.6z M660.1,343.8
	c-1.1,0.7-1.4,2-0.8,3.1s2,1.4,3.1,0.8s1.4-2,0.8-3.1C662.6,343.6,661.3,343.2,660.1,343.8 M660.4,344.1h1.8l1,1.6l-0.9,1.6h-1.8
	l-1-1.6L660.4,344.1L660.4,344.1z M660.1,350.9c-1.1,0.6-1.4,2-0.9,3.1s2,1.4,3.1,0.9s1.4-2,0.9-3.1
	C662.5,350.6,661.2,350.3,660.1,350.9 M660.3,351.2h1.8l0.9,1.6l-1,1.6h-1.8l-0.9-1.6L660.3,351.2L660.3,351.2z M647.9,342.5
	c1.1,0.6,2.5,0.2,3.1-0.9s0.2-2.5-0.9-3.1c-1-0.6-2.5-0.2-3.1,0.9C646.3,340.5,646.7,341.9,647.9,342.5 M648.1,342.1l-1-1.6l0.9-1.6
	h1.8l1,1.6l-0.9,1.6L648.1,342.1L648.1,342.1z M645,347.8c1.1-0.6,1.4-2,0.9-3.1c-0.6-1-2-1.4-3.1-0.9s-1.4,2-0.9,3.1
	C642.5,348.1,643.9,348.4,645,347.8 M644.7,347.5h-1.8l-0.9-1.6l1-1.6h1.8l0.9,1.6L644.7,347.5z M645.1,354.9c1.1-0.6,1.4-2,0.9-3.1
	s-2-1.4-3.1-0.9s-1.4,2-0.9,3.1C642.7,355.1,644,355.5,645.1,354.9 M644.9,354.6h-1.8l-0.9-1.6l1-1.6h1.8l0.9,1.6L644.9,354.6
	L644.9,354.6z M651.3,357.8c0-1.2-1.1-2.2-2.3-2.2c-1.2,0-2.2,1.1-2.2,2.3s1.1,2.2,2.3,2.2C650.4,360,651.3,359.1,651.3,357.8
	 M650.9,357.8l-0.9,1.6h-1.8l-1-1.5l0.9-1.6l1.8-0.1L650.9,357.8L650.9,357.8z M658.5,357.7c0-1.2-1.1-2.2-2.3-2.2
	c-1.2,0-2.2,1.1-2.2,2.3s1.1,2.2,2.3,2.2C657.5,360,658.5,359,658.5,357.7 M658.1,357.7l-0.9,1.6h-1.8l-1-1.5l0.9-1.6l1.8-0.1
	L658.1,357.7L658.1,357.7z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="652.6"
            cy="349.2"
            rx="4.5"
            ry="2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M657.5,340.6c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2
	C657.1,341.8,657.5,341.3,657.5,340.6L657.5,340.6z M661.3,354.1c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2
	S660.6,354.1,661.3,354.1L661.3,354.1z M649.8,339.6c-0.5-0.5-1.2-0.5-1.7,0.1c-0.5,0.5-0.5,1.2,0,1.7s1.2,0.5,1.7-0.1
	C650.3,340.9,650.3,340.1,649.8,339.6L649.8,339.6z M647.8,357.9c0,0.7,0.6,1.2,1.2,1.1c0.7,0,1.2-0.6,1.1-1.2
	c0-0.7-0.6-1.2-1.2-1.1C648.4,356.7,647.8,357.2,647.8,357.9L647.8,357.9z M655.4,358.7c0.5,0.5,1.2,0.5,1.7-0.1
	c0.5-0.5,0.5-1.2-0.1-1.7c-0.5-0.5-1.2-0.5-1.7,0C654.9,357.4,655,358.2,655.4,358.7L655.4,358.7z M662.2,346.6
	c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7S661.7,347.1,662.2,346.6z M643.1,352.2
	c-0.5,0.5-0.4,1.2,0.1,1.7s1.2,0.4,1.7-0.1s0.4-1.2-0.1-1.7C644.3,351.6,643.6,351.7,643.1,352.2L643.1,352.2z M643.8,344.6
	c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.1,1.2,1.1c0.7,0,1.2-0.6,1.1-1.2C645,345.1,644.4,344.6,643.8,344.6L643.8,344.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M644.4,325.9c-0.4,0.2-0.6,0.4-0.5,0.7l0.1,2.7c0,0.3-0.1,0.8-0.4,1
	c0,0-1.1,1.3-3.1,2.6c-1.9,1.2-3.2,1.9-3.2,1.9c-0.3,0.1-0.8,0.2-1.1,0.1l-2.7-0.9c-0.3-0.1-0.7,0-0.8,0.3l-0.1,0.1
	c-3.1,4.1-5.2,9.4-5,14.9v0.9c0,0.3,0.3,0.7,0.6,0.7l2.6,0.7c0.3,0.1,0.7,0.4,0.9,0.6c0,0,1,1.5,1.5,3.7c0.6,2.2,0.9,3.6,0.9,3.6
	c0,0.3-0.1,0.8-0.3,1.1l-1.6,2.3c-0.2,0.3-0.2,0.6,0,0.9l0.1,0.2c3.1,4.3,7.6,7.6,12.7,9.2l0.4,0.1c0.4,0.1,0.7,0,0.9-0.2l1.4-2.3
	c0.2-0.3,0.6-0.6,0.9-0.6c0,0,1.7-0.5,4-0.4s3.6,0.3,3.6,0.3c0.3,0,0.7,0.3,0.9,0.6l1.6,2.3c0.2,0.3,0.6,0.4,0.9,0.2l0.4-0.1
	c5.1-1.6,9.4-4.9,12.4-9.2l0.2-0.2c0.2-0.2,0.2-0.6,0-0.9l-1.7-2.1c-0.2-0.3-0.3-0.7-0.3-1.1c0,0,0.1-1.8,1-3.9
	c0.8-2.1,1.4-3.4,1.4-3.4c0.2-0.3,0.5-0.6,0.8-0.7l2.7-0.9c0.3-0.1,0.6-0.4,0.6-0.8v-0.6c0-5.5-1.8-10.6-4.9-14.8l-0.2-0.2
	c-0.2-0.3-0.6-0.4-0.9-0.3l-2.5,1c-0.3,0.1-0.8,0.1-1.1,0c0,0-1.6-0.7-3.4-2.1c-1.8-1.4-2.8-2.4-2.8-2.4c-0.2-0.2-0.4-0.7-0.4-1
	v-2.9c0-0.3-0.2-0.6-0.6-0.7l-0.3-0.1c-2.5-0.9-5.1-1.2-7.8-1.2c-2.8,0-5.5,0.5-7.9,1.3L644.4,325.9L644.4,325.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M675.9,357c0.6-1.8,1.1-3.7,1.1-4.1c0.1-0.8,0.1-1.1-0.4-1.1
	s-2.2-0.2-3,1.4s-1.2,3.4-1.5,4.5c-0.3,1.1-0.4,1.5,0.1,2.4c0.5,0.8,0.7,0.9,1,1.1c0.3,0.3,0.9,0.5,1.2-0.5
	C674.7,360.4,675.4,358.9,675.9,357L675.9,357z M638.4,329c-1.5,1.1-3.1,2.4-3.3,2.7c-0.6,0.5-0.8,0.9-0.3,1.1
	c0.4,0.3,1.6,1.4,3.3,0.6c1.6-0.9,3.1-2,3.9-2.8s1.2-1.1,1.2-2c0.1-1,0-1.1-0.1-1.5s-0.4-0.9-1.3-0.4C641.4,327,639.8,328,638.4,329
	L638.4,329z M667.3,329.3c-1.5-1.1-3.3-2.1-3.6-2.3c-0.7-0.4-1.1-0.4-1.1,0.1c-0.2,0.5-0.9,2,0.5,3.3s2.9,2.2,3.8,2.8
	c1,0.6,1.3,0.9,2.3,0.6c0.9-0.3,1.1-0.4,1.4-0.6s0.7-0.7-0.1-1.3C670.1,331.5,668.7,330.4,667.3,329.3L667.3,329.3z M629.1,356.9
	c0.7,1.8,1.4,3.6,1.6,3.9c0.4,0.7,0.6,1,1,0.7s1.9-1.1,1.5-3c-0.4-1.8-1.1-3.4-1.5-4.5s-0.7-1.5-1.5-1.8s-1.2-0.3-1.5-0.3
	s-1,0.1-0.8,1.1C628.2,353.4,628.5,355.1,629.1,356.9L629.1,356.9z M652.4,373.8c1.9,0,3.8-0.2,4.2-0.3c0.8-0.1,1.1-0.3,1-0.8
	c-0.2-0.5-0.5-2.2-2.3-2.4c-1.8-0.2-3.6-0.1-4.8,0c-1.1,0.1-1.6,0.1-2.2,0.9s-0.7,1-0.8,1.3c-0.2,0.4-0.2,1,0.9,1.1
	C648.8,373.7,650.5,373.8,652.4,373.8L652.4,373.8z M654.1,333.9c0.7,0,1.5-0.4,2.1-1c0.6-0.7,1.3-2.1,1.4-2.7s0.3-1.8-1.1-2.1
	c-1.3-0.3-2.5-0.4-3.9-0.4s-2.9,0.2-3.5,0.3c-0.7,0.2-1.9,0.6-1.4,2c0.4,1.4,0.7,1.8,1.3,2.7c0.7,0.9,1.4,1.1,2.4,1.2
	C652.3,334,654.1,333.9,654.1,333.9L654.1,333.9z M667.6,346c0.2,0.7,0.8,1.3,1.6,1.7s2.4,0.6,3,0.6c0.6-0.1,1.8-0.3,1.7-1.6
	s-0.4-2.5-0.9-3.8c-0.4-1.3-1.1-2.7-1.3-3.3c-0.4-0.6-1.1-1.6-2.4-0.8c-1.2,0.9-1.5,1.1-2.1,2s-0.7,1.7-0.4,2.7
	C667,344.3,667.6,346,667.6,346L667.6,346z M638.5,343.2c0.2-0.7,0.1-1.5-0.4-2.3s-1.5-1.8-2.1-2.1c-0.5-0.3-1.6-0.9-2.3,0.4
	c-0.7,1.2-1,2.3-1.5,3.6c-0.4,1.3-0.7,2.8-0.8,3.4s0,2,1.4,2c1.5,0,1.9-0.1,2.9-0.5s1.5-1.1,1.9-1.9
	C638,344.9,638.5,343.2,638.5,343.2L638.5,343.2z M642.5,360.7c-0.5-0.4-1.4-0.6-2.3-0.4c-0.9,0.3-2.3,1-2.7,1.3
	c-0.5,0.4-1.2,1.3-0.4,2.3c1,1.1,1.8,1.7,3,2.6c1.1,0.8,2.5,1.5,3.1,1.8c0.7,0.3,1.8,0.7,2.4-0.8c0.5-1.4,0.5-1.8,0.5-2.9
	s-0.5-1.8-1.2-2.4C644,361.7,642.5,360.7,642.5,360.7L642.5,360.7z M660.4,362.3c-0.6,0.4-1.1,1.1-1.1,2.1c-0.1,1,0.2,2.4,0.4,3
	c0.3,0.6,0.9,1.6,2.1,1.1s2.2-1.2,3.3-2c1.1-0.9,2.2-1.8,2.7-2.4c0.5-0.5,1.1-1.5,0-2.5c-1.1-1-1.6-1-2.6-1.3
	c-1.1-0.3-1.9-0.1-2.7,0.5C661.8,361.3,660.4,362.3,660.4,362.3z M360.3,307c0,0-0.3,26.4,0,30.1s0.9,4.1,5,4.1s215.4-0.5,215.4-0.5
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M580.6,338.8c-0.1,1.4-0.3,3.7,1.3,3.9s8,0.6,9.8,0.6
	c1.7,0.1,2.3-0.2,2.6-2.6c0.3-2.5,1.6-15.6,4.5-24.3c2.9-8.8,7.2-16.5,14-23.2c6.8-6.6,21.1-12.6,39.7-12.6c18.7,0,30.9,5.5,37,10.2
	c6,4.9,7.4,7.4,7.8,8s1.1,1.1,2.8,1.1c1.7-0.1,10.9,0,11.9,0s2-0.1,1.2-1.7c-0.8-1.6-7-14.4-19.7-21.8
	c-12.7-7.4-29.6-10.3-44.2-10.3c-14.3,0-32.4,5.3-43.1,14.2c-10.7,8.9-17.9,21.9-21.5,34.9C581,328.1,580.6,338.8,580.6,338.8
	L580.6,338.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M700.4,300c0,0,33.1,0.1,35,0.1s2.8,0.4,2.9-1.5c0.1-2-0.1-19.9-0.5-25.4
	c-0.4-5.5-0.3-8.1-0.6-9.7s-0.6-2.6-1.2-3.6c-0.7-1-1.7-2.1-1.7-3.3c0-1.1,0.1-3.5,0.4-6.1s0.3-3.6,0.5-4.1s0.3-0.8,1.7-1.3
	c1.3-0.5,2.7-1.2,2.7-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M719.3,299.8c0,0-3.8-10.1-11.3-18.4s-17.3-14.5-29.8-18
	c-12.4-3.5-24.9-3.6-34.5-3.1c-9.6,0.5-20.8,3-30.3,8.1c-9.6,5.1-19.4,15-24.2,23.6c-4.8,8.6-4.9,8.5-13.1,8.4
	c-8.2-0.1-208.3-1-208.3-1l-7.5-0.2 M734.5,257.5c0,0-75.5-1.3-105.3-1.9c-29.8-0.7-130.7-1.5-158.3-1.7c-27.7-0.1-85.5-0.1-97.9,0
	c-12.2,0.1-12.9-0.3-12.9-0.3 M734.4,255.6c0,0-1-0.7-2.8-0.8c-2.4,0-101.9-2-101.9-2s-0.2-0.3-0.6-0.8s-5.1-6-12.5-7.1
	c-7.8-1.1-19.8-2-26.7-2.4c-6.9-0.4-9-0.5-9-0.5 M615.7,231.2c0,0-2.4,2.2-4,2.2c-1.7,0.1-7.3,0.3-10-0.3c-2.8-0.5-3.7-0.8-6.5-0.5
	c-2.8,0.3-8.8,0.8-9.9,0.8s-2.5,0.1-2.2,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M622.4,228.6c0,0-1.7,1-2.5,1.4s-1.1,0.6-1.5,1.7c-0.5,1.1-1,3.2-1,3.2
	s-0.5,1.3-1.5,1.4c-1.1,0.1-8.8,0.3-10.2,0.5c-1.4,0.2-2.5,0.3-2.4,1.1c0.1,1,0.5,5.3,0.8,5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M657.4,232.1c0,0-20.3-1.2-27.9-1.5c-7.7-0.3-13.5,0.3-13.5,0.3
	 M751.6,254.8l-4,1.2c0,0-1.5,0.1-1.3,2.8c0.1,2.8,0.9,15.3,1,22.7c0.1,7.4-0.3,16.2-0.1,18.7c0.1,2.6,1.2,5.5,1.2,5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M747.2,257c0,0,0,3.6,0.3,9.4c0.3,5.8,0.9,15.4,0.8,21.3
	c-0.1,5.8-0.2,8.9-0.1,10.3c0.1,1.4,0,1.9,0.2,3.2c0.2,1.2,0.4,1.7,0.4,1.7s0.3,0.6,1.2,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M753.7,272.1c0,0,0.3,3.7-1.7,4c-2,0.4-3.9,0.4-3.9,0.4 M754.6,286.9
	c0,0-0.2-2.8-2.3-3.3c-2.1-0.5-3.9-0.7-3.9-0.7 M749.7,302.2l-1.1,0.2 M701.9,305.6c0,0-1.9-2.2,0.1-2.3c1.9-0.1,28.6-0.4,32-0.2
	c3.3,0.2,6.9,0.8,8.3,1c1.3,0.2,2.5,1.1,2.5,1.1s2.4,0.3,4.9,1.1c2.5,0.9,3.3,2.4,3.8,3.5s2.5,5.2,2.3,9.6s-1,12-1.1,14.4
	c-0.2,2.5-0.7,8.6-3.2,8.6c-2.5,0-37.3,0.1-37.9,0c-1.3-0.1-1.9,0.6-2.2-3.1c-0.3-3.6-0.8-8.7-1.7-13.1c-1-4.4-2.1-8-4-12.9
	C704,309.2,701.9,305.6,701.9,305.6L701.9,305.6z M762.5,316.8c0,0-0.1,1.2-0.1,1.9c-0.1,0.6-0.4,1.5-1.3,1.5c-1,0-5.1-0.1-5.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M754.5,335.4c0,0,4.1,0.1,5,0.1s1.4,0.3,1.3,1.1c-0.2,0.9-0.4,1.6-0.8,2.3
	 M761.2,320.2c0,0.1,0,3.1-0.2,7.7c-0.2,4.6-0.2,8.2-0.2,8.2 M755.1,329.1h3.2c0.9,0,1.6-0.3,1.6-1.5s-0.7-1.6-1.4-1.6
	s-3.2-0.1-3.2-0.1 M761.3,314l-6.5-1 M735.7,313.2c-1.7,0.1-4.1,0.2-4.3,4.4c-0.2,4.2-0.3,8.8-0.3,10.8c-0.1,2,0.7,5.1,4.2,5.2
	s8.8,0,10.9,0c2.1-0.1,4.8-0.7,5-4.4c0.2-3.7,0.3-7.6,0.3-8.5s-0.4-1.6-1.2-3.3c-0.9-1.6-1.4-2.5-1.9-2.9s-1.4-1.3-4.2-1.3
	C741.3,313.1,735.7,313.2,735.7,313.2L735.7,313.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M731.4,317.2c0,0,0.4-2.8,3.3-2.9c3-0.1,7,0.1,8.3,0.1
	c1.2,0,2.4,0.2,3.4,1.5s2.8,3.1,2.7,5.8c-0.1,2.9-0.1,5-0.2,6.3c-0.1,1.3-0.1,4-2.7,4.2s-9.5,0.1-11,0.1c-1.5,0.1-4.2-0.2-4.2-4.8
	 M744.5,304.7c0,0,0.4,0.9-0.4,0.9c-0.7,0.1-22.5-0.2-22.5-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M726,342.2c0,0,0.5,0.1,0.5-1.1c0-1.1-0.6-11.5-1.4-18
	c-0.9-6.5-2.7-15.8-3-16.7c-0.3-1-0.9-3.4-2.1-3.3 M734.7,314.3l1.5,0.8c0,0,0.5,0.4,0.4,1.7c-0.1,1.3,0,11.8,0,12.5
	s0.1,1.2-0.5,1.3c-0.6,0.2-3.8,0.8-3.8,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M721.6,305.4c0,0-0.2-0.4-1.9-0.4s-15.7,0.1-15.7,0.1s-0.8-0.3-0.1,0.9
	s3.6,7.9,5.4,13.6c1.7,5.6,3,13.1,3.3,16.7c0.4,3.6,0,5.5-0.5,5.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M714.3,316.4c0.5,3.3,2.2,12.1,2.6,14.3c0.4,2.1,1.2,3.3,2.2,3.6
	c1,0.5,2.5,0.9,2.1-1.6c0,0-2.8-16.4-3.1-17.9c-0.4-1.5-1.5-2.5-2.5-1.9c-1,0.6-1.4,1.1-1.4,2S714.3,316.4,714.3,316.4L714.3,316.4z
	 M736.8,318.2h0.7c0,0,0.9-0.1,1,0.7c0.2,0.9,0.7,2.5,0.7,4.2s-0.5,3.3-0.7,3.9s-0.6,0.9-0.9,0.9h-1 M739.8,244.1
	c0,0-0.7,0.4-1.8,0.9c-1.1,0.5-2,0.4-2.4,1.8s-0.6,5.9-0.8,7.8c-0.2,1.8-0.3,3.3,0.2,4 M734.3,255.3c0,0-4-5.9-18.9-9
	c-15.9-3.3-50.1-7.5-69.4-8.7c-18.9-1.2-31.5-1.1-31.5-1.1 M699.1,254.2c0,0,0.3,9.8,0.4,12.1c0.1,2.3,0,3.2,2.5,4.9
	c2.5,1.7,8.8,7.3,12.8,13c4,5.6,7.9,15.8,7.9,15.8 M531.4,179.2c0,0-6.3,1.8-7.9,2.6s-2.4,1-0.5,2.6c1.9,1.6,17.4,12.4,25.9,19.4
	c8.5,6.9,20.1,16.6,25,21c5,4.4,7.5,6.7,8.5,7.5c1.1,0.8,1.7,1.2,1.7,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M528.9,178.1c0,0-7.1,2.2-8.4,2.7c-1.3,0.5-1.7,1.2-0.8,2.4
	c1.1,1.1,3.3,3,8.1,6.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.7,230.6c0,0,1.9,4.4,3.3,12.1s1.7,10.7,1.3,21.9
	c-0.5,11.2-0.4,17-1.4,27.9c-1.1,11-1,12.9-2.9,21c-1.9,8.1-6.7,19.5-7.8,22.1c-0.6,1.5-0.9,1.8-1.2,2.5c-0.4,0.8-0.3,1-1.3,1
	l-191.9-0.1c0,0-1.3,0.1-1.5-2.6c-0.1-1.6,0.1-14.2,0.1-31.6c0-14.7,0.4-36,0.3-49.6c-0.1-16.2,0.5-35,3-60.4
	c1.3-13.4,1.2-16.5,1.4-18.5c0.2-1.9,1.3-1.5,1.3-1.5s5.5,0.1,14.6-0.1c9.2-0.2,28.7,0.1,43.3,0.4c14.7,0.3,33,0.4,49.5,1.1
	c16.5,0.7,23.8,3.6,31,7.6c7.1,3.9,14.7,10.2,24.8,17.9c9.9,7.7,25.9,21.9,33.5,28.2c7.6,6.3,9.8,5.6,14.5,6.7
	c4.8,1,11.7,1.1,11.7,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M438.9,175.4c0,0-0.8,20.4-1.8,46.8s-1,37.1-1.1,54.1
	c-0.3,17-0.1,62.8-0.1,62.8 M548.2,212.8c0,0-9.8-8-16.1-12.8c-6.4-4.8-14.7-11.3-21.5-13.6c-6.8-2.3-15.2-3.3-23-3.4
	c-7.7-0.2-24.7-0.5-29.1-0.5c-4.5,0-8.2,1.7-8.3,7.4s0,14.4,0.2,24.4c0.2,9.9,0.1,25.7,0.1,25.7s0,2.6,3.8,2.7
	c4.2,0.1,25.5,0.5,34.8,0.5c9.3,0,16.8,0,20.7,1.1c3.8,1.1,8.1,2.7,10.4,3.9c2.2,1.2,5,2.7,11.3,2.6c6.4-0.1,34.5,0.3,38.3,0.4
	s4.6-1.6,4.3-3.6c-0.3-1.9-1.9-10.2-4-13.7c-2.2-3.4-6.3-7.8-9.8-10.8c-2.6-2.1-5-4.1-5-4.1 M630,253.1c0,0-20.2-0.3-29.7-0.5
	c-9.5-0.2-17.9-0.3-17.9-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M534.2,248.3c0,0-3,0-5.6-0.1c-2.8-0.1-4.5-0.8-6.7-1.7
	c-2.2-1.1-8.1-5.3-21.8-5.5c-13.7-0.2-49.6-0.7-49.6-0.7 M544.8,212.3c0,0-8.4-6.8-15.9-12.5c-7.5-5.7-14.3-10.5-20.3-12.3
	c-6.1-1.7-10.7-2.5-20.3-2.9s-26-1-29.6-0.8c-3.5,0.2-6.4,1-6.6,5.2s-0.1,16.7,0,25.8c0.1,9.2,0.4,25.2,0.4,25.2 M370.8,172.1
	c0,0-1.2,1.3-2.9,6.4 M380.3,175.1c0,0-4.3-0.2-7,0.4c-2.8,0.7-4.2,1.4-5.5,3.4 M378.9,178.6c0,0-5.1-0.3-8.1,0.9
	c-3.1,1.1-4.3,3.7-5.5,9.6 M368.7,172.6c0,0-0.3,1.2-0.7,2.8c-0.4,1.5-0.8,2.4-1.1,2.5 M508,171.8c-0.2,0.1-0.6,0.3-1,0.7
	c-0.3,0.4-0.8,0.8-0.2,0.9c0.6,0.1,5.9,1.3,6.7,1.3c0.7,0,1-0.1,1-0.3s-0.1-0.7-0.4-1.1c-0.2-0.3-0.7-1-2.3-1.3
	C510.3,171.7,508.7,171.5,508,171.8L508,171.8z M500.1,171c0,0,0.2,0.5-1.2,0.5s-10-0.2-12.4-0.3c-2.6-0.1-28.9-1.1-30.3-1.1
	c-1.3-0.1-1.5-0.6-1.5-0.6 M448.9,169.4c0,0-1.1,0.7-3.6,0.6c-2.5-0.1-48.9,0.3-50.5,0.3c-1.6-0.1-1.9-0.4-1.9-0.4 M440.4,273.3
	c0,1.6,1.2,2.9,2.8,2.9h10.1c1.6,0,2.8-1.2,2.8-2.9l0.1-14.9c0-1.6-1.1-2.8-2.7-2.8h-10.3c-1.6,0-2.9,1.2-2.9,2.8L440.4,273.3
	L440.4,273.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M452.8,256.8c-2.1,0-8.1-0.1-9.2-0.1s-2.2,0.4-2.2,2.1v8.6
	c0,1.2,0.2,2.6,2.1,2.6h3.8c0.6,0,1.3-0.2,2.1,1.1s1.6,2.6,1.9,2.9c0.3,0.3,0.6,0.5,1.2,0.5c1.3,0,2.6,0.6,2.6-3.8v-11.3
	C455.1,257.8,454.7,256.8,452.8,256.8L452.8,256.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M443.6,271.2c-1.1,0-1.5,0.1-1.5,1v1.4c0,0.5,0,1.2,1.7,1.2
	c1.6,0,4.5,0.1,5.3,0.1s1.3-0.3,0.9-1c-0.5-0.8-1.3-1.8-1.5-2.1c-0.2-0.3-0.3-0.6-1.3-0.6C445.9,271.2,443.6,271.2,443.6,271.2
	L443.6,271.2z M442.5,268.1c0,0.6,0.5,1.1,1.1,1.1h4.5c0.6,0,1.1-0.5,1.1-1.1V259c0-0.6-0.5-1.1-1.1-1.1h-4.5
	c-0.6,0-1.1,0.5-1.1,1.1L442.5,268.1L442.5,268.1z M581.5,244.1c0,0,7.5,0.3,13.9,0.7c6.4,0.4,14.5,1,19.6,1.6
	c5.1,0.7,8.4,3.4,8.8,3.6c0.4,0.3,0.2,0.6-0.1,0.5c-0.3,0-15.2-0.3-15.2-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M583.8,250l-0.1,0.4l3.2,0.1l0.4-0.9h3.4l-0.3,0.6c0,0,16.2,0.2,16.9,0.2
	c0.7,0,1.1-0.3,1.5-1.1c0.4-0.8,0.6-2,0.7-2.5c0.1-0.5,0-1.2-1.1-1.1h-16l-0.6,1.5H591l0.7-1.5l-5.3-0.1c0,0-1.1-0.1-1.7,1.4
	C584.4,248.5,583.8,250,583.8,250 M563.4,226.1c0,0-0.9-1.3-3.3-1.5c-2.4-0.2-2.9-0.3-3.4-0.4c-0.6,0-1.1,0.5-1.1,0.5
	s0.1-2.4-0.1-4.5c-0.2-2.1-1.8-6.6-6-7.3c-4.3-0.7-8.5-0.9-10.2-0.8c-1.7,0.1-3.6,0.2-4.2,3.4c-0.6,3.4-0.9,5.8-1.6,15.1
	c-0.8,9.2-1.1,12.9-1,14.7c0.1,1.7,0.5,2.5,2.9,3.3c2.4,0.9,6.3,1.6,10.8,1.4c4.5-0.2,5.5-0.1,6.4-0.6c0.9-0.6,1.1-1,1.1-1
	s1.5,0.9,4.4,1.1c2.9,0.3,2.9,0.5,3.6,0.5s1.2-0.2,1.4-0.7c0.2-0.5,0.3-1,0.3-1l0.5-0.1l0.5-5l-0.3-0.4c0,0-0.3-1.5-2.1-1.7
	c-1.8-0.2-7-1-7-1s0.3-2.7,0.4-4.4c0-1.7,0.2-2.8,0.2-2.8s4.4,0.8,6.3,0.9c1.9,0.2,2.2-0.9,2.2-0.9s0.5-0.4,0.7-1.1
	c0.2-0.7,0.7-4.6,0.5-4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M539.6,212.1c0,0-2.9-0.1-3.3,3.3c-0.5,3.4-1.4,12.2-2,18.9
	c-0.6,6.7-0.8,8.4-0.8,10c0.1,1.6-0.3,3.3,1.2,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M533.6,245.2c0,0,22.1,2.8,23.3,2.9c1.2,0.2,1.1-0.6,1.2-1.1
	c0.1-0.6,0.3-2,0.4-3s-0.6-1.1-0.6-1.1s-17.1-1.9-19.1-2.2c-2-0.2-1.9-0.9-1.9-1.9c0-1.1,0.3-5.8,0.5-7.6s0.8-2,2.1-1.9
	c1.3,0.1,15.7,1.9,15.7,1.9l2.6,0.3c1.1,0.1,1.5,0.2,1.5-1c0.1-1.1,0-2.3,0.1-2.7c0-0.4,0.1-1.3-1.5-1.4c-1.5-0.2-2.6-0.3-2.6-0.3
	l-20.2-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M555.3,231.2c0,0-0.1,3.1-0.3,5.8c-0.2,2.9-0.5,5.4-0.5,5.4 M555.6,223.7
	c-0.1,1-0.1,2.3-0.1,2.3 M553.2,248.9c0,0,0.4-0.3,0.6-1.3 M563.9,233.1c0,0-0.2-0.6-1.6-0.8c-1.4-0.2-6.9-0.8-6.9-0.8 M564.7,227.3
	c0,0-5.9-0.9-7.8-1.1 M564,243.4l-9.4-1.1 M563.2,248.6c0,0-4.9-0.3-6.7-0.6 M547.5,230.2c0,0-0.1,1.9-0.4,5.6
	c-0.3,3.6-0.5,5.8-0.5,5.8 M538.5,232.8c-0.1,0.7,0.4,1.1,1,1.2l5.7,0.6c0.7,0.1,1.2-0.4,1.3-1.1l0.1-1.2c0.1-0.7-0.4-1.1-1.1-1.2
	l-5.7-0.6c-0.7-0.1-1.2,0.4-1.3,1.1L538.5,232.8L538.5,232.8z M538,238.4c-0.1,0.6,0.4,1.1,1.1,1.2l5.7,0.6c0.7,0.1,1.2-0.4,1.3-1.1
	l0.1-1.2c0.1-0.7-0.4-1.1-1.1-1.2l-5.7-0.6c-0.7-0.1-1.2,0.4-1.3,1.1L538,238.4L538,238.4z M420.9,241.4c5.1,0.1,10-1.4,10.2-8.3
	s0.5-20.5,0.7-26.6c0.2-5.8,0.5-14,0.6-18.7c0.1-4.6-3-7.8-6.9-7.9c-3.8-0.2-26.2-0.1-29.8-0.1c-3.6,0.1-7.9,0.8-8.5,6.8
	c-0.7,6.1-1.5,13.7-2,21.7c-0.5,8.1-1.2,21.7-1.1,24.4c0.1,2.8,0.8,8.6,8.2,8.5C399.6,241.4,420.9,241.4,420.9,241.4L420.9,241.4z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="428.2"
            cy="194.8"
            r="1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M429.1,226.2c0-0.9-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5
	c0,0.9,0.7,1.5,1.5,1.5S429.1,227,429.1,226.2z M390.5,211.6c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5
	C389.9,213.2,390.5,212.5,390.5,211.6z M360.4,249.8c0,0,6-0.1,15.9,0c9.8,0.1,44.6-0.1,62.4-0.1s89.4,0.9,89.4,0.9 M570.3,251.1h12
	 M669.7,233c0,0,41.6,4.7,52.7,7.3c21.7,5.2,25.2,15.8,25.2,15.8 M705.8,309.8l47.5-0.1 M753.1,275.4v8.6 M732.3,255l0.6,2.4v2.1
	c0,0,0.9,0.8,1,3.5s0.8,10.9,0.8,12.1s-0.3,2-0.3,2v1.5c0,0,0.5,0.8,0.6,2.9c0.1,2.1,0.1,12.1,0.1,13.4s-0.5,1.7-0.5,1.7l-0.1,1.7
	h-10c0,0-0.8,0.3-1.1-1.1c-0.4-1.3-2.4-7.7-9.1-16.5c-6.7-9-10.4-10.8-11.7-11.7c-1.2-1-1.8-1.3-1.9-3.7c-0.1-2.4-0.3-11-0.3-11
	 M732.8,259.5l-32.3-0.4 M734.3,277.2h-23.2 M734.3,278.8l-21.9-0.1 M734.5,296.8H723 M733.6,260.9l-33.1-0.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M82.4,338.7
		c0,0,24.1,0.1,36.5,1.1l10.7,0.6c0,0-0.4,2.2,1,2.6c0,0,0.8,0.2,3.5,0.4c0,0,0.5,0,0.7-0.5l0.9-1.8l3.3,0.1l0.6,1.9
		c0,0,0.7,0.8,1.8,0.8l2.2-0.1c0,0,1.2,0.3,1.2-0.9c0,0,0.1-0.5-0.5-1.9c0,0-1.1-2.4-1.2-3.3c0,0-0.3-1-0.1-4c0,0,0.5-21.5,9.8-33.9
		c0,0,8.5-11.3,23.7-16.1c0,0,20.7-6.6,46.1,1c0,0,19.8,4.7,27.7,26.7c0,0,3.2,5.5,5.5,30.7c0,0,0.1,1.1,1,1.1l2-0.2
		c0,0,0.3-0.1,0.4-0.8l0.4-1.4l3.2-0.1l0.9,2c0,0,0.2,0.6,1,0.6l3.5-0.2c0,0,1.5,0,1.8-1c0,0,0.2-0.3,0.2-1.7l31.9-0.5l52.3-0.9
		c0,0,2.4,0.2,2.4-1.9l0.1-30.1l-0.1-29.5l0.1-23.8c0,0,0-1-0.6-1.7c0,0-0.4-0.7-0.4-2.1v-7.1c0,0-0.1-0.6-0.5-0.6h-1.5
		c0,0-0.2-1-1.4-1h-44.5h-34.6l-0.4-0.2l-67.8-0.1h-90.2L87.3,241c0,0-0.3-0.1-0.3,0.2c0,0.2-0.3,0.4-0.4,0.4s-10.3,0-10.3,0h-0.2
		v0.2l-5.2,0.9c0,0-0.8,0-1.2,0.7c0,0-0.4,0.4-1.7,3.3c0,0-0.3,0.5,0.1,0.7c0,0-1.3,4-2.2,7.8c0,0-0.4,1.1,0.4,1.4
		c0,0,3.3,1.1,5.2,2.8s3.3,4.7,3.8,7.8l0.2,1c0,0,1.5,8.7,2.3,14.5c0,0,1.1,7.4-0.3,10.2c0,0-2.2,3.5-5.4,3.5l-8.4-0.1
		c0,0-0.5,0.2-0.3,1.7l1.5,10.3c0,0,0,1,0.8,1l7.3,0.1c0,0,2-0.2,3.6,2l0.3-0.1c0,0,1.2,1.6,1.7,6.1l2.1,19.7c0,0,0,1,0.6,1.3
		C81.1,338.4,81.3,338.6,82.4,338.7z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M374.6,249.7h61.6
		c0,0-0.5,43.9-0.5,50.1v39.4l-60-0.1c0,0-1.5,0.1-1.5-3.1v-36.4L374.6,249.7z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M436.3,249.7l90.6,0.8
		c0,0,2.2,0.3,3.3,0.3l40,0.3l1.1-0.1l10.7,0.1c0,0,0.7,2.6-0.1,19.5c0,0-0.1,13-1.5,25.2l-0.5,5c0,0-0.2,5.4-1.8,11.5
		c0,0-0.5,5.4-8.5,24.6l-0.8,1.5l-0.3,0.6c0,0-0.2,0.5-1.1,0.5H435.6v-39.4L436.3,249.7z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M383.9,231.2
		c0,0,1.2-29.5,3-42.2l0.3-3c0,0,0.2-6.5,8.5-6.2h27l2.9,0.1c0,0,3.4,0,5.5,3.1c0,0,1.3,1.9,1.3,4.8l-1.2,44.7c0,0,0.2,4.2-2,6.2
		c0,0-2,2.8-8.2,2.6h-20.6h-7c0,0-3.2,0.3-5.2-0.7c0,0-2.9-0.9-3.9-4.8C384.3,235.9,383.8,234.3,383.9,231.2z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M452.6,240.2
		l47.7,0.7c0,0,9.9-0.2,17.9,3.6c7.9,3.8,5.2,3.3,14.1,3.7h1.8c0,0-1.8-0.6-1.8-2.6c0,0-0.1-1.2,0.2-5.3l0.8-10.1l0.7-7.7
		c0,0,0.4-3.7,1-7.2c0,0,0.3-2.1,1.5-2.8c0,0,0.9-0.7,3.2-0.6c0,0,2.5,0,5.1,0.2l-0.2-0.3c0,0-12.9-10.4-22.3-17.2c0,0-4.1-3-7.9-5
		c-3.7-2-5.7-2.5-12.1-3.8c0,0-2.1-0.5-9.5-1.1c0,0-20.2-0.9-28.9-1c0,0-5-0.2-6.5,0.1c-1.5,0.3-5,0.6-5.2,4.9v20.8L452.6,240.2"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M534.7,248.6
		c0,0,4.7,1.7,10,1.6l5.5-0.1c0,0,2.2,0.1,3.3-1.4c0,0,1.9,1,5.4,1.2l0.7,0.1l1.5,0.2c0,0,1.6,0.3,1.9-0.7l0.3-1l0.5-0.1l0.5-5
		l-0.3-0.4c0,0-0.3-1.5-2.1-1.7l-7-1c0,0,0.3-1.9,0.5-7.2c0,0,4.4,0.8,6.1,0.9c0,0,1.8,0.2,2.3-0.9c0,0,0.7-0.5,0.8-1.4
		c0,0,0.3-2,0.5-4.4c0,0-1.8-2-3.1-2.6c0,0-0.6-0.3-1.3-0.4l-3.8-0.4c0,0-0.7,0-1.1,0.5c0,0,0.1-3.3-0.3-5.6s-2.3-4.9-4.2-5.7
		c0,0-1-0.5-3.8-0.8c0,0-3.9-0.5-7.7-0.5c0,0-2.2-0.1-3.3,0.7c0,0-1.1,0.7-1.4,2.7c0,0-0.9,5.4-1.3,12.1l-1.2,16.1
		c0,0-0.2,2.5,0.3,3.3C532.6,246.9,533.3,248.1,534.7,248.6z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M580.5,340.8
		l-3.4-5h-6.8c0,0,3.3-8.3,4.1-10.5c0,0,3.3-9.2,4-13.3c0.8-4,1.2-5.5,1.8-11.8c0,0,1.3-11.7,1.7-21.5l0.6-18.6c0,0,0.1-5.7-0.1-7.8
		c-0.2-2.1,0-0.1,0-0.1s-0.2-3.4-1.4-10.1c0,0,22.5,1.1,32.5,2.4l2.2,0.3c0,0,8.1,0.4,14.1,8l69.4,1.3l0.4,13.1c0,0-0.1,1.9,1.6,3.3
		c0,0,8.7,6.4,13.6,13.5c0,0,3.6,4.9,8.1,16l-22.4,0.1c0,0-2.1,0-2.6-0.5s-1.1-1.4-1.1-1.4s-11.4-17.9-43.8-17.5
		c0,0-27.5-1.5-42.6,15c0,0-7.9,7.7-12.5,23.8c0,0-2.3,8.6-3.5,21.3c0,0,0.1,2.4-1.8,2.4c0,0-5.3-0.2-10.2-0.6
		C582.1,342.8,580.6,342.7,580.5,340.8z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="199.6"
              cy="349.2"
              r="45.6"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="652.7"
              cy="349.2"
              r="45.6"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup4DrRight);
