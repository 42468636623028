import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { loadSellerIfs, resetSellerIfs } from '../../actions';
import { getConfig, compose } from '../../utils/helpers';

class SellerIfs extends Component {
  state = {
    title: 'seller ifs',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerIfs();
    this.props.loadSellerIfs(getConfig('marketplaceId'));
  };

  render() {
    const loading = isEmpty(this.props.sellerIfs);
    const data = this.props.sellerIfs.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'buyer',
      'details',
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>If Sales</h4>

        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { sellerIfs } = state.entities;
  return { sellerIfs };
};

export default compose(
  withTable,
  connect(
    mapStateToProps,
    { loadSellerIfs, resetSellerIfs }
  )
)(SellerIfs);
