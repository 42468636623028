import React, { Component } from 'react';

class TextArea extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChangeHandler,
      style,
      title,
      isRequired = false,
      placeholder = '',
      isDisabled = false,
      error,
      defaultValue,
      maxLength = 1000,
    } = this.props;

    return (
      <div className="text-area-input-field-container">
        <div>
          {title}
          {isRequired && <span className="text-red-500">*</span>}
        </div>
        <textarea
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          onChange={onChangeHandler}
          className={`text-area-input-field text-black ${style || ''}`}
          defaultValue={defaultValue}
          maxLength={maxLength}
        />
        {error && <div className="text-red-500 text-xs mt-1">{error}</div>}
      </div>
    );
  }
}

export default TextArea;
