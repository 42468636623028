import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WishlistModal from './WishlistModal';
import { getTemplate } from '../../utils/helpers';

class WishlistButton extends Component {
  state = {
    isWishlistModalOpen: false,
  };

  render() {
    return (
      <div>
        <WishlistModal
          {...this.props}
          isOpen={this.state.isWishlistModalOpen}
          onClose={() => this.setState({ isWishlistModalOpen: false })}
        />
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ isWishlistModalOpen: true })}
          style={{
            backgroundColor: getTemplate(
              this.props.template,
              'saveSearchButton.backgroundColor'
            ),
            color: getTemplate(this.props.template, 'saveSearchButton.color'),
          }}
        >
          Save Search
        </button>
      </div>
    );
  }
}

WishlistButton.propTypes = {
  template: PropTypes.object.isRequired,
  searchResultsCount: PropTypes.number,
};

const mapStateToProps = state => {
  const { template } = state.entities;
  return { template };
};

export default connect(mapStateToProps, {})(WishlistButton);
