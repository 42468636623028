import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#5E5E5E"
            d="M586,370.8c0,9.5,2.5,18.3,7,25.9c8.9,15.4,25.6,25.7,44.7,25.7c20.4,0,38-11.7,46.4-28.9
	c3.4-6.9,5.4-14.6,5.4-22.8c0-28.6-23.1-51.7-51.7-51.7C609.1,319.1,586,342.2,586,370.8L586,370.8z"
          />
          <path
            fill="#FFFFFF"
            d="M766.8,349.4l0.5-0.5l-0.5,16h1.7c0,0,0.3-0.1,0.3-0.4l0.7-23.4c0,0,1.4-0.7,0.1-1l-1.9-0.1l-0.1,5
	c-0.7-3.7-4.5-7.7-4.5-7.7s0.4-0.5-1-2.3c0,0-8.8-10.2-20.2-16.8c0,0-2.2-1.1-5-3l-0.1,0.1c-2.6-1.5-9.9-5.4-9.9-5.4
	s-12-5.8-33.7-11.8c-7.6-2.2-16.5-4.3-26.4-6.3c0,0-10.5-2.1-17.7-4.4c0,0-30.4-7.4-73.3-5.9c-5.6,0.1-11.6,0.5-17.6,1.1
	c-19.9-11.7-72.8-42.2-106.9-55.7c0,0-0.5-1.4-2.8-2.2c0,0-42-13.8-131.8-9.9c0,0-50.3,2.8-74,10.2c0,0-2.2,0.6-2.5,1.9
	c0,0-33.8,9.4-85.8,36c0,0-9.9-0.5-22.8-0.3c-0.1,0-0.3-0.1-0.4-0.1c-3.8-1.4-7.3-2.8-9.5-3.6c0,0-9.1-2.9-11.7-3.9c0,0-3.4-1-6.7-1
	l-11.6-0.3c0,0-2.1,1.2,0.3,2.8c0,0,3.9,1,13.2,7.4c0.3,0.1,0.4,0.3,0.7,0.4c-3.2,0.4-6.2,1-9.1,1.5l-0.4,3.2l1.1,4.5
	c0,0,0.8,3.7-0.8,6.5c0,0-5,5.1-1.7,20.9l-3.3,1.8v0.1c-5.6,2.5-8.8,5.4-8.8,5.4c-1.9,1.1-3.3,2.2-4.1,3.3c-2.5,2.8-1.8,4.8-1.8,4.8
	c-0.6,2.8,2.8,2.2,2.8,2.2c1.9,0,1.1,1.9,1.1,1.9c-0.8,5.1,1.1,24.8,1.1,24.8c0,3.4-2.8,3.2-2.8,3.2c-3.4,0.3-2.2,3.7-2.2,3.7
	c0.3,6.5,3.4,8.7,3.4,8.7c5.1,11.3,10.7,16.4,10.7,16.4l3.6-0.5c-0.4-1.2-0.7-3.3-0.7-5.5c0-3.9,0.8-6.9,1.7-6.9h1.2V364
	c0.6-2.3,4.3-1,5.4,0.3l0.3,0.3c4.9,4.8,14,10.9,14.7,11.3c1.2,0.7,2.8,1.4,4.4,2.1c8,3.3,19.5,6.2,19.5,6.2
	c3,1.4,14.6,4.5,14.6,4.5c1.1,0.3,1.9,0.3,2.6,0.1h0.1c1.6-0.7,1.5-2.8,1.5-2.8c0-5.8-0.1-8.7-0.1-15c0-24.2,15.8-44.8,38.1-53.2
	c6.7-2.6,14.2-4,21.9-4c33,0,60,25.6,60,57.2l-1.2,2.2l0.5,11.3l0.5,12.9l268.8-0.7h2.5l26.8-0.1c-0.5-4.1-0.7-9.4-0.7-13.3
	l-0.8-0.4c0-3.9-0.1-6.6-0.1-11.7c0-31.5,27-57.2,60-57.2c15.5,0,29.7,5.6,40.4,14.9c2.8,2.5,5.4,5.1,7.6,8c7.6,9.5,12,21.5,12,34.3
	c0,12.2,0.1,19.5,0.1,19.5v0.3c0,0.4,0.3,1,1.5,0.8v1.9c0,1.1,0.8,1,0.8,1l17.3-0.1l25.9-0.1c1.5-0.1,3-0.3,4-0.4
	c0.7,0,1.2,0.1,1.8,0.1l1.4,0.1c2.6-0.7,0.5-3,0.5-3c2.5-2.9,6.7-6.2,6.7-6.2h2.8c0.3-0.1,0.7-0.3,1.1-0.8l2.3-3.6
	c0,0,0.7-1.8-0.5-2.5l-1.1-0.8C766.5,362,766.8,349.4,766.8,349.4L766.8,349.4z"
          />
          <path
            fill="#5E5E5E"
            d="M751.6,347c2.5,9.2,5,21.2,5,21.2c0.5,1.9,1.9,3,1.9,3l4.4,3.3l0.3,0.1c3.3-12.8,3.7-25.5,3.7-25.5l-14-2.2
	L751.6,347L751.6,347z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M551.6,316.8c3.9,1.2,10.9,3.2,10.9,3.2c5.6,1.6,7.8,0,7.8,0l4.4-3.2c1.4-1.4-1.7-1.8-1.7-1.8
		c-11.7-1.4-21.6-1.8-21.6-1.8C547.2,312.9,551.6,316.8,551.6,316.8L551.6,316.8z M473.8,246.6c17.5,9.5,40,22.4,60.3,35.9
		c0.1,0.1,0.5,0.4,1,0.7l13.3,0.1l0.3,0.1l1.8-0.3c2.5-0.3,5-0.5,7.4-0.7c-15.8-9.4-52.7-30.7-84.2-45.8c-8-3.9-15.7-7.3-22.7-10
		c0,0-4.8,1.6-6.7,3.2l-0.4,1c0.4,0.3,1,0.6,1.4,0.8C449.1,233.5,459.9,239,473.8,246.6L473.8,246.6z M714.9,315.2h-14.6
		c4.1,3.7,6.5,8,7.7,10.6c0.4,0.8,1,2.2,1.2,3c13.6,5.1,27.7,5.1,43.9,8c-5.2-6.3-13.5-12.5-24.3-18.3l-3.7,1.5
		c-1.2-0.7-2.5-1.4-3.7-1.9c-0.7-0.3-1.2-0.5-1.9-0.8C717.9,316.5,716.4,315.8,714.9,315.2L714.9,315.2z M729.4,339.9l-2.6-0.3
		c0.3,1.1,0.5,2.2,0.8,3.2c1.4,4.1,2.9,6.7,4,8.1c0.7,1,1.2,1.4,1.2,1.4l3-0.4v-0.5c-0.1-2.9-1.1-5.1-2.3-6.9
		C731.7,341.4,729.4,339.9,729.4,339.9L729.4,339.9z M240.2,226.7c0,0-33.6,9.4-85,35.6c1.1,0.1,2.5,0.3,2.9,0.4
		c6.9,1,15.1,1.9,24.3,2.8l44.4,1.6c4.8,0.8,7-4.8,7-4.8l5.4-27C239.9,230.9,240.2,226.7,240.2,226.7L240.2,226.7z M98.1,303.4
		c2.3,0,5.5-0.3,9.5-1c0,0,27.1-8.7,27.1-16.8c0-3-14.6-6.2-20.8-7.6c-1.5-0.3-2.6-0.5-2.6-0.5c-4,0.1-8.3,1.2-10.5,9.4
		c-1.2,4.3-2.1,9.2-2.6,15.1C98.2,302.5,98.2,303,98.1,303.4L98.1,303.4z M411.6,230.4c0,0-23.2-3.9-53.6-4.1l0.3,1l11.4,43.3
		l0.5,2.1l99.6,4.7c-2.1-0.5-2.9-1-4.1-1.9c-2.6-2.5-3.4-8-3.2-12.5c0.1-2.8,2.9-3.2,5.9-2.9c0.8,0.1,2.9,0.5,5.4,1.1h0.1l-0.1-1
		l-0.5-3.9C453.8,245.1,430.8,234,411.6,230.4L411.6,230.4z M279.3,241.4c5.6,10.2,6.7,11.8,13.8,20.8c0.1,0.3,0.4,0.5,0.5,0.7
		c7.2,8.1,19.8,7,19.8,7l40,1.9c-5.8-27.1-9.6-43.1-9.6-43.1s-37.8,0.8-63.1,9.4C278.3,238.9,279.3,241.4,279.3,241.4L279.3,241.4z"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M473.8,260.1l0.1,1.1c2.9,0.7,6.1,1.8,8.1,2.6c3,1.2,5.2,5.2,5.9,6.6c0.1,0.1,0.1,0.4,0.3,0.7
	c0.1,0,0.3,0,0.4,0.1c0.5,0.1,1.6,0.7,1.6,1.5c0,0.4-0.1,0.8-0.3,1.4c-0.3,0.8-1,1.6-1.9,1.8c-3.7,0.3-6.7,0.1-6.7,0.1
	s-2.3,2.3-4.1,2.9c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0-0.4,0-0.7-0.1l0.1,1.5c16,0.8,29,1.6,37.8,2.2c-1-0.7-2.6-1.9-4-2.9
	c-6.2-4.4-20.1-13.8-36.7-23.2L473.8,260.1L473.8,260.1z M381.2,304.4v-8c-3.4-0.1-9.5,0.1-8.9,3.3c0,0-0.3,1,0,1.9
	c0.4,0.8,1.2,1.8,3.6,1.9C376,303.6,377.9,304.1,381.2,304.4L381.2,304.4z M383.7,304.4v-7.8h-0.4c-0.4,0-1.1-0.1-2.1-0.1v8
	c0.7,0,1.5,0.1,2.3,0.1v-0.1h0.2V304.4z M383.9,304.4v-7.8h-0.3v7.8h0.1h-0.1v0.3C383.7,304.5,383.8,304.5,383.9,304.4z
	 M386.8,298.1c-0.1-0.1-0.3-0.4-0.4-0.5c-0.4-0.3-1.1-0.8-2.5-0.8v7.8h0.1h-0.1v0.1c0.1,0,0.3-0.1,0.5-0.1c0.3-0.1,0.4-0.1,0.7-0.3
	c0.4-0.3,1.9-1.2,2.6-1.6c3.7,0.1,7.8,0.1,10.9,0.1c3.7,0.3,4.5,1.4,4.5,1.4c0.5,0,6.5-0.1,6.5-0.1l0.4-0.3l1.1-0.8v-1.2
	c0-1-0.8-1.4-0.8-1.4s-2.9-0.8-7.3-1.2L386.8,298.1L386.8,298.1z M95.7,376.2l1.1-0.1c0.4-1.2,0.6-3,0.6-5.1c0-3.9-0.8-6.9-1.7-6.9
	V376.2L95.7,376.2z M167.3,370.8c0,6.5,1.1,12.5,3.3,18.2c7.3,19.5,26.3,33.6,48.4,33.6c18.3,0,34.4-9.5,43.6-23.9
	c5.1-8,8.1-17.6,8.1-27.8c0-28.6-23.1-51.7-51.7-51.7C190.5,319.1,167.3,342.2,167.3,370.8L167.3,370.8z"
          />
          <path
            fill="#EAEBEB"
            d="M670.2,394.1c-7.3,10.2-19.1,16.8-32.6,16.8c-12.4,0-23.5-5.6-31-14.6c-5.6-6.9-9.1-15.8-9.1-25.5
	c0-22.1,17.9-40,40-40s40,17.9,40,40C677.8,379.5,675,387.5,670.2,394.1L670.2,394.1z M219,410.9c-15,0-28.1-8.3-34.9-20.4
	c-3.3-5.8-5.1-12.5-5.1-19.7c0-22.1,17.9-40,40-40s40,17.9,40,40c0,10.2-3.9,19.5-10,26.6C241.7,405.6,231,410.9,219,410.9
	L219,410.9z"
          />
          <path
            fill="#5E5E5E"
            d="M697,391.3c-0.3-0.1-0.4-0.3-0.4-0.4s-0.1-0.3-0.1-0.4v-0.3c0,0-0.1-7.1-0.1-19.5c0-15.3-6.3-29.2-16.5-39.3
	c-11-11-26.7-17.7-43.3-17.7c-33,0-60,25.6-60,57.2c0,5.1,0.1,7.8,0.1,11.7l0.3,1.2c0,4.1,0.7,8.5,1.2,12.7l-26.8,0.1v2.6l5.2,1
	h30.7V398h3.7v-1.1h2.2c-4.4-7.6-7-16.5-7-25.9c0-28.6,23.1-51.7,51.7-51.7s51.7,23.1,51.7,51.7c0,8.3-1.9,16-5.4,22.8h0.1l12.8-1.2
	v-1.2c-0.3-0.1-0.4-0.3-0.4-0.4C696.8,391,696.9,391.2,697,391.3L697,391.3z M697,391.3v1h0.7v-0.8h-0.3h0.3
	C697.4,391.5,697.2,391.5,697,391.3c0.1,0,0.3,0.1,0.4,0.1C697.3,391.5,697.2,391.3,697,391.3L697,391.3z M698,391.5l0.1,0.7h-0.3
	v-0.8C697.7,391.5,697.9,391.5,698,391.5C697.9,391.5,697.9,391.5,698,391.5h-0.3H698L698,391.5z M179.1,329.1v8.9
	c-7.3,8.9-11.7,20.4-11.7,32.7c0,6.5,1.1,12.5,3.3,18.2l-6.3-2.9l-4-0.3c0-5.8-0.1-8.7-0.1-15c0-16.4,7.3-31.1,18.8-41.5V329.1
	l-0.1,0.1L179.1,329.1L179.1,329.1z M179.1,329.1l0.1-0.1c10.9-9.6,25.3-15.4,40.9-15.4c33,0,60,25.6,60,57.2v2.6l-0.7,10.7
	l0.5,12.9l131.1-0.4l-19,2.2h-43.6c0,0-10.5-0.8-20.9-0.4h-64.9c5.1-8,8.1-17.6,8.1-27.8c0-28.6-23.1-51.7-51.7-51.7
	c-16.1,0-30.5,7.4-40,19L179.1,329.1L179.1,329.1L179.1,329.1L179.1,329.1z M95.7,377.7c-1,0-1.8-3-1.8-6.9s0.8-6.9,1.8-6.9H97v13.8
	H95.7z M117.5,375.6v2.1H97v-13.9c0.6-2.1,4.5-0.1,5.4,0.1h0.1l0.1,0.1l0.1,0.1l0.1,0.1C108.4,370,111.8,372.3,117.5,375.6
	L117.5,375.6z M117.5,375.6l0.3-0.5L117.5,375.6c0.4,0.3,1,0.5,1.5,0.8c0,0.1,0.1,0.4,0.1,0.5l0.1,0.4l1.9,0.1
	c6.2,2.5,12.5,4.5,19,6.2c5.5,1.9,11,3.7,16.5,5h1.8c-3.6,1.9-13.9,2.1-24.4,1c-2.2-0.3-4.4-1.2-6.6-1.8c-3.8-1-4.1-2.8-5.5-5
	c-1.2-1.6-2.2-3.4-2.8-5.2h-1.9v-2.1L117.5,375.6L117.5,375.6z"
          />
          <path
            fill="#5E5E5E"
            d="M116.4,377.7H95.7c-0.4,0-0.8-0.5-1.1-1.4l-3.4,0.5c-1.4,1.1,0.3,2.5,0.3,2.5c3.2,4,8.4,5.6,8.4,5.6
	c3.2,1.1,5.1-1.6,5.1-1.6l6.2-1.4l3.7-1.6L116.4,377.7L116.4,377.7z M119.1,377c0.1,0.3,0.1,0.5,0.3,0.7h2.5
	c-1.1-0.4-2.1-0.8-2.9-1.2C119,376.6,119.1,376.9,119.1,377L119.1,377z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M766.8,349.4l1-0.8l-0.1-3.3c-0.4-3.7-4.7-8.1-4.7-8.1h-12
	c-2.6,0-1.7,2.1-1.7,2.1c2.8,7.8,7.3,29,7.3,29c0.5,1.9,1.9,3,1.9,3l4.7,3.6C766.4,362,766.8,349.4,766.8,349.4L766.8,349.4z
	 M684.2,393.7l23.1-2.3c-5.5,0-9.1,0.1-9.1,0.1c-2.2,0.1-1.7-1.2-1.7-1.2s-0.1-7.1-0.1-19.5c0-31.5-27-57.2-60-57.2
	s-60,25.6-60,57.2c0,6.5,0.1,9.2,0.1,15L286,389.3l-5.8-18.7c0-31.5-27-57.2-60-57.2s-60,25.6-60,57.2c0,6.5,0.1,9.2,0.1,15l4,0.3
	l6.5,2.9 M262.6,398.6h64.9c10.5-0.4,20.9,0.4,20.9,0.4H392l21.3-2.1l137.7-0.5v2.6l5.2,1H587v-2.2h3.7v-1.1h1.7 M141.4,383.9
	c3,1.4,14.6,4.5,14.6,4.5c1.2,0.4,2.2,0.3,2.8,0.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M158.8,388.6c0,0-2.2,2.3-17.6,1.5c0,0-16.1,0.4-19-7.3
	c0,0-2.1-2.5-3.3-6.5 M676.8,328.6l15.1-11.7 M725,319.9l11.4-4.7 M736.4,315.2l0.4-0.1c2.8,1.9,5,3,5,3
	c11.4,6.6,20.2,16.8,20.2,16.8c1.4,1.8,1,2.3,1,2.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M762.9,337.1h-12c-2.6,0-1.7,2.1-1.7,2.1c2.8,7.8,7.3,29,7.3,29
	c0.5,1.9,1.9,3,1.9,3l4.4,3.3 M762.8,374.5l1.2,1c1.2,0.7,0.5,2.5,0.5,2.5l-2.3,3.6c-1,1.4-2.1,0.7-2.1,0.7 M760.3,382.4
	c-8-3.6-13.1-0.8-13.1-0.8c-4.7,3-13.5,8.8-13.5,8.8c-5.5,2.5-8.9,2.1-8.9,2.1c-14-1.6-26.7-0.8-26.7-0.8 M698.3,391.5
	c-2.2,0.1-1.7-1.2-1.7-1.2s-0.1-7.2-0.1-19.5c0-31.5-27-57.2-60-57.2s-60,25.6-60,57.2c0,6.5,0.1,9.2,0.1,15"
          />
          <g>
            <circle
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              cx="637.7"
              cy="370.8"
              r="51.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M677.8,370.8c0,22.1-17.9,40-40,40c-22.2,0-40-17.9-40-40s17.9-40,40-40
		C659.8,330.8,677.8,348.7,677.8,370.8z"
            />
            <circle
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              cx="637.7"
              cy="370.8"
              r="4.8"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M645.5,341.8c2.1-5.1,2.2-7,2.3-7.4c0-0.4-0.3-0.8-0.7-1
		c-3-0.7-6.1-1.1-9.4-1.1c-3.2,0-6.3,0.4-9.4,1.1c-0.4,0.1-0.7,0.4-0.7,1c0,0.4,0.1,2.5,2.3,7.4c1.4,3.4,5.8,8.5,7.7,8.5
		C639.5,350.3,643.9,345.2,645.5,341.8L645.5,341.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M645.3,338.4c-2.5-0.6-5-0.8-7.6-0.8s-5.1,0.3-7.6,0.8
		c1.4,5.1,5.6,9.9,7.6,9.9C639.5,348.4,643.8,343.4,645.3,338.4L645.3,338.4z M637.7,353.5c1.8,0,7.3-6.1,9.9-12.7
		c1.7-4.3,1.9-6.1,2.2-6.5c0.1-0.4,0.6-0.7,1-0.5c3.7,1.2,7,3,9.9,5.2c3,2.2,5.6,4.8,8.1,7.8c0.3,0.4,0.1,0.8-0.1,1.1
		c-0.4,0.3-2.1,1.1-5.5,4c-5.5,4.5-9.5,11.7-8.9,13.3"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M658.7,354.2c2.8-3.2,4.5-4.7,4.5-4.7c-1.8-2.1-3.7-4-6.1-5.6
		c-2.3-1.6-4.7-3-7.2-4c0,0-1,2.2-3,5.8c-2.1,3.6-6.1,11.8-1.9,14.9C649.3,363.7,655.9,357.3,658.7,354.2L658.7,354.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M650.3,334.2c0,0,1.1,2.1,0.5,3.9c-0.4,1.4-0.8,1.9-0.8,1.9 M668.6,347.6
		c0,0-2.3-0.4-3.9,0.7c-1.1,0.8-1.5,1.2-1.5,1.2 M645.3,338.4c0.5-2.2,2.1-4.4,2.1-4.4 M630.2,338.4c-0.6-2.2-2.1-4.4-2.1-4.4
		 M637.7,357.9c2.1,0,3.7,1.7,3.7,3.7c0,2.1-1.7,3.7-3.7,3.7s-3.7-1.6-3.7-3.7S635.7,357.9,637.7,357.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M636.2,364.1l-1.4-2.5l1.4-2.5h2.9l1.4,2.5l-1.4,2.5H636.2z M667.6,369.2
		c5.5,0.4,7.4,0,7.8-0.1c0.4-0.1,0.7-0.4,0.7-1c-0.3-3-0.8-6.2-1.8-9.2s-2.3-5.9-4-8.5c-0.3-0.4-0.7-0.5-1.1-0.4
		c-0.4,0.1-2.2,1-6.5,4.5c-2.8,2.3-6.3,8.1-5.8,9.9C657.7,366.3,663.9,368.9,667.6,369.2L667.6,369.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M670.8,367.9c-0.3-2.5-0.7-5-1.5-7.4c-0.8-2.5-1.9-4.8-3.2-6.9
		c-4.4,2.9-7.7,8.5-7.2,10.3C659.6,365.6,665.5,368.2,670.8,367.9L670.8,367.9z M654.1,365.5c0.5,1.6,8,5.1,15.1,5.5
		c4.5,0.3,6.5,0,6.9,0s0.8,0.4,0.8,0.8c-0.1,3.9-0.8,7.6-1.9,11.1c-1.1,3.4-2.8,6.9-5,10c-0.3,0.4-0.7,0.4-1.1,0.3
		c-0.4-0.3-1.7-1.5-5.5-4c-6.1-3.9-14-5.5-15.4-4.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M659.9,385.5c3.9,1.7,5.9,2.9,5.9,2.9c1.4-2.3,2.6-4.8,3.4-7.4
		c0.8-2.6,1.4-5.4,1.5-8.1c0,0-2.5-0.3-6.5-1.1c-4.1-0.8-13.1-2.2-14.7,2.8C648.1,379.6,656,383.9,659.9,385.5L659.9,385.5z
		 M676.4,371.4c0,0-1.7,1.6-3.4,1.6c-1.4,0-2.1-0.1-2.1-0.1 M669.4,393c0,0-0.3-2.3-1.8-3.4l-1.7-1.1 M670.8,367.9
		c2.2-0.1,4.8,0.5,4.8,0.5 M666.1,353.6c1.9-1.2,3.6-3.3,3.6-3.3 M649.8,366.8c0.7,1.9-0.4,4-2.3,4.7c-1.9,0.7-4-0.4-4.7-2.3
		s0.4-4,2.3-4.7C647.2,363.8,649.3,364.9,649.8,366.8L649.8,366.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M643.7,367.4l1.9-2.1l2.8,0.5l0.8,2.6l-1.9,2.1l-2.8-0.5L643.7,367.4z
		 M648.5,398.8c1.2,5.4,2.3,7,2.6,7.4s0.7,0.5,1.1,0.3c2.9-1.1,5.6-2.6,8.3-4.5s4.8-4,6.9-6.3c0.3-0.3,0.3-0.8,0-1.1
		c-0.3-0.3-1.5-1.8-6.2-4.7c-3.2-1.9-9.6-3.4-11.3-2.3C648.2,388.4,647.6,395.2,648.5,398.8L648.5,398.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M650.5,401.4c2.3-1,4.5-2.2,6.6-3.7s4-3.3,5.6-5.1
		c-4.1-3.3-10.5-4.7-12-3.6C649.3,390.1,648.8,396.5,650.5,401.4L650.5,401.4z M647.9,384.7c-1.5,1.1-2.3,9.2-0.5,16.1
		c1.1,4.4,1.9,6.1,2.1,6.6c0.1,0.4-0.1,0.8-0.5,1c-3.7,1.1-7.4,1.6-11.1,1.6c-3.7,0-7.4-0.5-11.1-1.6c-0.4-0.1-0.7-0.5-0.5-1
		c0.1-0.4,1-2.1,2.1-6.6c1.8-6.9,0.8-15-0.5-16.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M630.5,396.4c-0.4,4.1-1,6.5-1,6.5c2.6,0.7,5.4,1,8.1,1
		c2.8,0,5.5-0.3,8.1-1c0,0-0.5-2.3-1-6.5s-2.1-13.1-7.2-13.1S630.9,392.3,630.5,396.4L630.5,396.4z M649,407.7c0,0-2.2-1.1-2.8-2.8
		c-0.4-1.4-0.5-1.9-0.5-1.9 M626.3,407.7c0,0,2.1-1.1,2.8-2.8c0.4-1.4,0.5-1.9,0.5-1.9 M650.5,401.4c0.8,2.1,1,4.8,1,4.8
		 M662.8,392.6c1.8,1.4,4.3,2.3,4.3,2.3 M645.3,381.1c-1.7,1.2-4,0.8-5.1-0.8c-1.2-1.6-0.8-4,0.8-5.1c1.7-1.2,4-0.8,5.1,0.8
		C647.2,377.7,646.8,379.9,645.3,381.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M642.8,375.4l2.5,1.2l0.3,2.8l-2.2,1.6l-2.6-1.1l-0.3-2.9L642.8,375.4z
		 M614.5,389.7c-4.7,2.9-5.9,4.4-6.2,4.7s-0.3,0.8,0,1.1c1.9,2.3,4.3,4.5,6.9,6.3c2.6,1.9,5.4,3.4,8.3,4.5c0.4,0.1,0.8,0,1.1-0.3
		c0.3-0.4,1.2-2.1,2.6-7.4c0.8-3.6,0.3-10.3-1.2-11.4C624.1,386.2,617.7,387.7,614.5,389.7L614.5,389.7z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M612.6,392.6c1.7,1.9,3.4,3.6,5.6,5.1c2.1,1.5,4.3,2.8,6.6,3.7
		c1.8-5,1.2-11.4-0.3-12.5C623,387.7,616.7,389.3,612.6,392.6z M627.6,384.7c-1.5-1.1-9.5,0.5-15.4,4.4c-3.9,2.5-5.2,3.7-5.5,4
		c-0.4,0.3-0.8,0.1-1.1-0.3c-2.2-3.2-3.9-6.6-5-10s-1.8-7.3-1.9-11.1c0-0.4,0.3-0.8,0.8-0.8c0.4,0,2.3,0.3,6.9,0
		c7.2-0.4,14.6-3.9,15.1-5.5"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M611.1,371.8c-4.1,0.8-6.5,1.1-6.5,1.1c0.1,2.6,0.7,5.4,1.5,8.1
		c0.8,2.6,2.1,5.2,3.4,7.4c0,0,2.1-1.2,5.9-2.9c3.9-1.6,11.8-5.9,10.3-10.9C624.1,369.7,615,371,611.1,371.8L611.1,371.8z
		 M606.1,393c0,0,0.3-2.3,1.8-3.4c1.1-0.8,1.7-1.1,1.7-1.1 M599.1,371.4c0,0,1.7,1.6,3.4,1.6c1.5,0,2.1-0.1,2.1-0.1 M612.6,392.6
		c-1.8,1.4-4.3,2.3-4.3,2.3 M624.8,401.4c-0.8,2.1-1,4.8-1,4.8 M630.2,381.1c-1.7-1.2-2.1-3.4-0.8-5.1c1.2-1.7,3.4-2.1,5.1-0.8
		c1.7,1.2,2.1,3.4,0.8,5.1S631.8,382.4,630.2,381.1L630.2,381.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M634.9,377l-0.3,2.9L632,381l-2.2-1.6l0.3-2.8l2.6-1.2L634.9,377z
		 M612.6,354.6c-4.1-3.6-6.1-4.3-6.5-4.5c-0.4-0.1-0.8,0-1.1,0.4c-1.7,2.6-3,5.5-4,8.5s-1.5,6.2-1.8,9.2c0,0.4,0.3,0.8,0.7,1
		s2.3,0.5,7.8,0.1c3.7-0.3,9.9-2.9,10.5-4.7C618.9,362.7,615.3,356.9,612.6,354.6L612.6,354.6z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M609.3,353.6c-1.2,2.2-2.3,4.4-3.2,6.9c-0.8,2.5-1.4,5-1.5,7.4
		c5.2,0.3,11.1-2.3,11.8-4.1C617,362,613.7,356.5,609.3,353.6L609.3,353.6z M621.2,365.5c0.5-1.6-3.4-8.8-8.9-13.3
		c-3.6-2.9-5.2-3.7-5.5-4c-0.4-0.3-0.4-0.8-0.1-1.1c2.3-3,5.1-5.6,8.1-7.8c3-2.2,6.3-4,9.9-5.2c0.4-0.1,0.8,0,1,0.6
		c0.1,0.4,0.4,2.3,2.2,6.5c2.6,6.6,8.1,12.7,9.9,12.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M628.4,345.8c-2.1-3.6-3-5.8-3-5.8c-2.5,1-5,2.3-7.2,4
		c-2.3,1.6-4.3,3.6-6.1,5.6c0,0,1.8,1.6,4.5,4.7c2.8,3.2,9.4,9.5,13.6,6.3C634.6,357.5,630.5,349.4,628.4,345.8L628.4,345.8z
		 M606.8,347.6c0,0,2.3-0.4,3.9,0.7c1.1,0.8,1.5,1.2,1.5,1.2 M625.1,334.2c0,0-1.1,2.1-0.6,3.9c0.4,1.4,0.7,1.9,0.7,1.9
		 M609.3,353.6c-1.9-1.2-3.6-3.3-3.6-3.3 M604.6,367.9c-2.2-0.1-4.8,0.5-4.8,0.5 M625.5,366.8c0.7-1.9,2.8-3,4.7-2.3s3,2.8,2.3,4.7
		s-2.8,3-4.7,2.3S624.8,368.8,625.5,366.8L625.5,366.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M630.9,370l-2.8,0.5l-1.9-2.1l1-2.6l2.8-0.5l1.8,2.1L630.9,370z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M214.1,270.5c8,0.1,14.3,5,14.2,10.9s-6.6,9.2-14.6,9.1
	s-14.3-3.7-14.3-9.5C199.6,275.2,206.2,270.5,214.1,270.5L214.1,270.5z M575.4,281.1c2.9,2.9,5.2,2.9,5.2,2.9
	c35.1,0.4,66.2,6.1,66.2,6.1c25.9,4.8,27,3.2,27,3.2 M555.8,285.3c100.8,0,176.1,25.4,197.7,51.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M156.3,262.4c43.6,6.5,150.6,10.2,150.6,10.2
	c100.6,3.2,219.3,10.5,219.3,10.5l22.3,0.1l5.6,2.1c6.5,2.5,8.5,2.5,8.5,2.5c40-1.1,117.9,8,164.1,31.6 M585.3,311.9
	c0,0-94.6-4.4-175.3-8.4 M372.4,301.6c-42.6-2.1-77.9-3.9-88.5-4.4c-32.3-1.6-36.3,0.1-36.3,0.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M534.4,380.2c5.4,0.3,5.9-5.1,5.9-5.1l0.8-4.5l1.4-24.8
	c1.1-19.7-0.8-32.3-0.8-32.3c-2.2-21.2-14.3-30.4-14.3-30.4 M736.9,315.2c-2.5-1.4-10-5.5-10-5.5s-21.7-10.5-60.1-18.2
	c0,0-10.5-2.1-17.7-4.4c0,0-43.2-10.5-99.2-4 M449.1,225c0,0-4.5,1.4-6.5,3.2c0,0-1.4,1.4,1.1,2.8l0.6-1.1c1.9-1.6,6.7-3.2,6.7-3.2
	 M535,283.1C494.4,256,443.8,231,443.8,231 M117.8,273.4c13.5,4.3,22.8,8.7,22.8,8.7 M98.1,303.4c0.6-6.5,1.4-11.7,2.8-16.2
	c2.5-8.9,7.4-9.4,11.6-9.5c15.3-0.3,28.8,5.6,28.8,5.6 M473.9,261.3l-0.8-4.8 M380.9,275.2l-74-3.7c-9.1,0.3-14.9-6.6-14.9-6.6
	c-8.7-8.1-15.8-23.8-15.8-23.8c-1.7-3.7,3.2-5.2,3.2-5.2c56.3-18,132.1-5.6,132.1-5.6c42,7.8,101.9,51.6,101.9,51.6 M370.2,272.3
	l-12.1-45.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M378.4,379.9L365,309.7c-5.4-25.6-8.9-38-8.9-38l-11.3-50.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M469.8,277.3l-156.4-7.4c0,0-12.7,1-19.8-7c-0.3-0.3-0.4-0.5-0.5-0.7
	c-7-8.9-8.1-10.5-13.8-20.8c0,0-1-2.5,1.4-3.2c25.3-8.7,63.1-9.4,63.1-9.4s3.9,15.3,9.8,43.1 M443,230.4
	c46.8,26.1,87.4,52.1,87.4,52.1 M242.7,229.5c15-7.3,75.1-12.9,75.1-12.9c86.4-4,125,11.6,125,11.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M535.3,380.5L329,380c0,0-12.4,0.5-18.3-5.1c0,0-5.4-1.9-13.2-18.6
	c0,0-11.6-27-16.9-46.8c-1.9-7-4.5-19.9-4.5-19.9l-9.9-50.8c-1.1-5.6,4.8-7.6,4.8-7.6c24.5-8.7,76.9-9.9,76.9-9.9
	c48.7-1.1,78.3,8.1,78.3,8.1c33.3,9.4,99.2,53.5,99.2,53.5 M116.5,277.1l0.8-3c0.6-1.9,1.9-2.2,1.9-2.2c13.5-1.6,41.1-2.8,41.1-2.8
	c14-1.4,21.5-4,21.5-4l45,1.6c4.8,0.8,7-4.8,7-4.8l5.4-27c0.8-4.3,1.1-8.7,1.1-8.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M228.1,268.6c5.4,0.5,7.8-5.6,7.8-5.6c3-14.3,6.7-38.2,6.7-38.2
	 M131.1,293.6c0,0,30.5,2.1,44.6,2.5c14,0.4,18.7,1.2,18.7,1.2 M402.5,304.2c-1.4,2.5-4.5,4.3-8.1,4.3c-3.7,0-7-1.9-8.3-4.5
	 M386.7,297.8c1.5-2.2,4.4-3.7,7.8-3.7c3.9,0,7,1.9,8.4,4.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M398.1,302.6c4.3,0.1,5.1,1.4,5.1,1.4c0.5,0,6.5-0.1,6.5-0.1l1.4-1v-1.2
	c0-1-0.8-1.4-0.8-1.4s-2.9-0.8-7.3-1.2l-16.9-1c0,0-4.7-0.1-5.5-0.1l-6.2-0.1c0,0-1.8,0.3-2.1,2.1c0,0-1.2,3.4,3.6,3.9
	c0,0,2.9,0.7,7.7,1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M385.5,296.7c-2.2-1.2-4,1-4,1s-0.8,2.5-0.1,4.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M372.4,300.4c0,0.5,1.8,1.2,1.8,1.2l7.8,0.5c6.2,0.5,21.3,0.4,21.3,0.4
	l5.6-0.1c0.8-0.4,2.1-0.8,2.1-0.8 M372.4,299.7c-0.8-4.4,11.3-3.2,11.3-3.2c2.2,0,3,1.2,3,1.2 M385.5,304c-3.4,1.4-4.3-2.1-4.3-2.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M389.4,302.6c-3,0-3.3,1.4-3.3,1.4c-1,0-2.5,0.7-2.5,0.7 M577.1,314.4
	c-9.2-1.6-26-2.6-26-2.6c-5.2,0-3.4,2.9-3.4,2.9c1.7,4.8,6.2,7,6.2,7c3.6,1.8,11.6,4.3,11.6,4.3c3.2,0.8,5.9-0.8,5.9-0.8
	c2.5-2.5,6.6-8.9,6.6-8.9C578.5,314.7,577.1,314.4,577.1,314.4L577.1,314.4z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M573.2,314.8c-11.7-1.4-21.6-1.8-21.6-1.8c-4.4-0.1,0,3.6,0,3.6
	c3.9,1.2,10.9,3.2,10.9,3.2c5.6,1.6,7.8,0,7.8,0l4.4-3.2C576.3,315.2,573.2,314.8,573.2,314.8L573.2,314.8z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M571,316.4c-5.9-0.8-12.5-1.6-12.5-1.6c-4.3-0.1-1,2.1-1,2.1l7.4,2.5
	c2.1,0.5,4.3,0.4,4.3,0.4l2.9-1.8C573.6,316.5,571,316.3,571,316.4L571,316.4z M91.3,302.7l3.4-1.9c-3.3-15.8,1.7-20.9,1.7-20.9
	c1.7-2.8,0.8-6.5,0.8-6.5l-1.1-4.5l0.4-3.2c23.5-5,57.6-3,57.6-3c51.9-26.7,85.8-36,85.8-36c0.3-1.4,2.5-1.9,2.5-1.9
	c23.7-7.6,74-10.2,74-10.2c89.8-3.7,131.8,9.9,131.8,9.9c2.2,0.8,2.8,2.2,2.8,2.2c34.1,13.5,86.9,44,106.9,55.7 M739.3,330.1
	c-6.6-5.5-12.2-9.4-18-12.1c-16-7.4-27.5-10.6-36.5-12 M279.9,397.1l298.1-0.8 M280.1,370.8l-0.7,13.3 M279.4,384.2l0.5,12.9
	 M578,396.3c-1.1-7.8-1.5-18.3-1.5-18.3 M761.3,382.5c-0.5,0.1-1-0.1-1-0.1c-8-3.6-13.1-0.8-13.1-0.8c-4.7,3-13.5,8.8-13.5,8.8
	c-5.5,2.5-8.9,2.1-8.9,2.1l5.1,0.5c2.5-0.1,13.9,0.7,17.9,1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M747.9,393.8c1,0,1.4,0.1,1.4,0.1c2.6-0.7,0.5-3,0.5-3
	c2.5-2.9,6.7-6.2,6.7-6.2c1.8-1.4,4.7-2.2,4.7-2.2 M752.1,347l14.7,2.3 M198.1,317.6c0,0-26.3-0.8-31.4-1.1
	c-5.2-0.3-10.3-1.9-13.3-3.6S126,296.1,126,296.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M98.5,265.9c14,2.5,40.6,14.3,40.6,14.3s4.3,3,1.9,5.6
	c0,0-3.9,4.8-11.4,8.7c-10.2,5.1-16.1,7-16.1,7c-16.6,3.4-21.7,1.4-21.7,1.4 M91.6,302.7c-5.6,2.5-8.8,5.4-8.8,5.4
	c-7.3,4.3-5.9,8.1-5.9,8.1c-0.6,2.8,2.8,2.2,2.8,2.2c1.9,0,1.1,1.9,1.1,1.9c-0.8,5.1,1.1,24.8,1.1,24.8c0,3.4-2.8,3.2-2.8,3.2
	c-3.4,0.3-2.2,3.7-2.2,3.7c0.3,6.5,3.4,8.7,3.4,8.7C85.4,372,91,377.1,91,377.1 M141.4,383.9c3,1.4,14.6,4.5,14.6,4.5
	c1.1,0.3,1.9,0.3,2.6,0.1 M158.6,388.7c1.8-0.5,1.7-2.9,1.7-2.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M91.2,376.9c-1.4,1.1,0.3,2.5,0.3,2.5c3.2,4,8.4,5.6,8.4,5.6
	c3.2,1.1,5.1-1.6,5.1-1.6l6.2-1.4l3.7-1.6l1.5-2.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M116.4,377.7l1.2-2.2c0,0-9.9-6.5-15-11.6c0,0-7.8-4-4.8,2.8
	c0,0,0.8,3.7,6.2,8.1l-2.1,0.4L91.3,377"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M141.4,383.9c-14.7-7.2-27.4-20.4-32.7-26.5c-5.9-6.9-7.8-6.1-16.2-6.1
	c-5.6,0-8.8-0.1-15.5-0.1 M78.6,311.3c9.8,1.1,15.7-0.7,25.7-5c3.2-1.4,16.4-7.7,16.4-7.7 M131.1,262.1c7.8,2.9,16.8,5.9,16.8,5.9
	c1.7,0.7,0,1.4,0,1.4l-25.9,1.9c-8.5,0.4-10.9-3.2-10.9-3.2c-2.2-1.8-4.3-3.2-6.1-4.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M105.1,263.7c-9.4-6.6-13.2-7.4-13.2-7.4c-2.3-1.5-0.3-2.8-0.3-2.8
	l11.6,0.3c3.2,0,6.7,1,6.7,1c2.8,1,11.7,3.9,11.7,3.9c2.2,0.8,5.6,2.2,9.5,3.6 M767.6,339.9l1.9,0.1c1.2,0.3-0.1,1-0.1,1l-0.7,23.4
	c0,0.3-0.3,0.4-0.3,0.4h-1.7L767.6,339.9L767.6,339.9z M280.1,370.8c0-31.5-27-57.2-60-57.2s-60,25.6-60,57.2c0,6.5,0.1,9.2,0.1,15
	 M155.4,370.8l-12.9-13.1 M284.5,367.4l9.2-9.1 M569.9,374.5l-12.4-7.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M764.6,378l-63.8-1.1c0,0,0.5-2.3,0-9.6c-2.9-33.6-30.1-58.3-64.5-58.3
	c-41.7,0-66.4,31.5-66.4,65.5l-285.4-7.2c-1.8-32.5-30.1-58.3-64.5-58.3c-35.6,0-64.5,27.6-64.5,61.8c0,0-20.5-3.6-38.5-14
	c-4-2.3-3.7-3,0.7-2.1c6.9,1.5,16.4,3.2,24.9,3.2c5.4-36.6,38.1-64.8,77.4-64.8c41.5,0,67.5,25.9,73.6,65.3c0,0,19.1,1.8,73.5,3.9
	c50.5,1.9,190.4,5.2,190.4,5.2c5.4-36.6,27.2-72.6,78.8-72.6c22,0,38.5,10.7,55.8,24.3c19.3,15.1,38,13.6,60.9,17.6 M474.7,261.5
	c0,0-2.2-0.8-6.3-1.4s-4.8,1.9-4.8,3.7c0,1.9,0,3.7,0.8,3.7c0,0,3.9-0.1,6.3,0.1c2.3,0.4,16.4,3.2,19.5,3.6c2.5,0.4,3.3-0.5,3.3-0.5
	"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M488.1,270.8c0,0-2.3-5.6-6.2-7.2c-3.7-1.5-11.7-3.6-13.6-3.7
	c-3-0.3-5.8,0.1-5.9,2.9c-0.3,4.7,0.5,10.2,3.2,12.5c1.6,1.5,2.9,1.6,8,2.9c4.1,1,10.9,1.2,10.9,1.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M500.7,276.6c0,0-19.4-5-26.7-6.9c-4-1-8.8-0.8-8.8-0.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M476.5,278.8c1.9-0.3,4.5-3,4.5-3s3,0.1,6.7-0.1c1.6-0.1,2.2-2.1,2.2-3
	s-1.8-1.5-1.8-1.5 M744.9,377.7c-23.2-0.7-26.5-13.9-26.5-13.9"
          />
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M687,306.3c-9.2-1.1-4.4,3.2-3.2,4.4s11,9.5,22.7,13.3c13.3,4.4,36.5,6.5,36.5,6.5
				c-6.6-5.5-12.2-9.4-18-12.1C708.2,310.6,696.2,307.5,687,306.3z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_2_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M708.9,327.9c0,0-2.9-8.8-11-14.7
			c-8.1-5.9-19.7-5.5-19.7-5.5"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_3_"
                  d="M687,306.3c-9.2-1.1-4.4,3.2-3.2,4.4s11,9.5,22.7,13.3c13.3,4.4,36.5,6.5,36.5,6.5
				c-6.6-5.5-12.2-9.4-18-12.1C708.2,310.6,696.2,307.5,687,306.3z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_4_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M728.3,332.4c-2.2-6.2-5.6-11.3-8.8-15.1"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_5_"
                  d="M687,306.3c-9.2-1.1-4.4,3.2-3.2,4.4s11,9.5,22.7,13.3c13.3,4.4,36.5,6.5,36.5,6.5
				c-6.6-5.5-12.2-9.4-18-12.1C708.2,310.6,696.2,307.5,687,306.3z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_6_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M716.7,317.5l-2.3-2.2v11l2.3-2.3V317.5z"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_7_"
                  d="M687,306.3c-9.2-1.1-4.4,3.2-3.2,4.4s11,9.5,22.7,13.3c13.3,4.4,36.5,6.5,36.5,6.5
				c-6.6-5.5-12.2-9.4-18-12.1C708.2,310.6,696.2,307.5,687,306.3z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_8_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M700.3,315.2h14"
              />
            </g>
          </g>
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M687,306.3c-9.2-1.1-4.4,3.2-3.2,4.4s11,9.5,22.7,13.3
		c13.3,4.4,36.5,6.5,36.5,6.5c-6.6-5.5-12.2-9.4-18-12.1C708.2,310.6,696.2,307.5,687,306.3z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M94.9,363.9c-1.2-2.2-0.6-4.5,0.3-6.6c3.2-7.8,9.1-1.1,12.4,2.8
	c12.4,14.3,33.8,23.9,33.8,23.9s-16.9-4.4-23.9-8.4c-5.8-3.3-9.2-5.6-15-11.6c-1.1-1.1-5.1-2.6-5.5,0"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M111.3,363.9H95.7v13.8h34c0,0-6.1-3.9-10.3-6.9
		C115.3,367.8,111.3,363.9,111.3,363.9L111.3,363.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M95.7,363.9c1,0,1.7,3,1.7,6.9s-0.8,6.9-1.7,6.9c-1,0-1.7-3-1.7-6.9
		C94,367.1,94.8,363.9,95.7,363.9z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M119.3,283.5c0-5.1-8-5.8-8-5.8s-2.6,11.3-3.7,24.8
	C107.6,302.5,119.3,293.8,119.3,283.5L119.3,283.5z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M107.6,302.5c0,0,27.1-8.7,27.1-16.8c0-3.7-23.4-8-23.4-8 M753,336.8
	l-10-6.3"
          />
          <g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_9_"
                    d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7c-0.5-1.8-0.4-3.6,2.8-3.6
					c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
					C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_10_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M718.4,369.5l-7.3-26.8
				c-0.1-1.5,1.5-2.8,3.4-2.5c8.5,1.4,19.5,3.9,25.6,6.2c4.3,1.6,5.2,4,5.4,5.1c0.3,1.9-0.1,2.2-2.3,2.5c-2.1,0.3-11,0.1-11,0.1
				s2.6,7.6,2.6,13.8c0,6.2-8.7,5-8.7,5L718.4,369.5L718.4,369.5z"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <defs>
                    <path
                      id="SVGID_11_"
                      d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7c-0.5-1.8-0.4-3.6,2.8-3.6
						c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
						C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
                    />
                  </defs>
                  <path
                    clipPath="url(#SVGID_12_)"
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.5"
                    d="M713.3,359.4l19.4,1.4"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7
			c-0.5-1.8-0.4-3.6,2.8-3.6c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
			C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_13_"
                    d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7c-0.5-1.8-0.4-3.6,2.8-3.6
					c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
					C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_14_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M719.2,338.8c2.3,10.3,6.2,13.5,6.2,13.5
				h10.5c0-8.1-6.5-12.2-6.5-12.2L719.2,338.8L719.2,338.8z"
                />
              </g>
            </g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_15_"
                    d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7c-0.5-1.8-0.4-3.6,2.8-3.6
					c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
					C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_16_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M726.5,338.8c2.3,10.3,6.2,13.5,6.2,13.5"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <defs>
                    <path
                      id="SVGID_17_"
                      d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7c-0.5-1.8-0.4-3.6,2.8-3.6
						c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
						C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
                    />
                  </defs>
                  <path
                    clipPath="url(#SVGID_18_)"
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.5"
                    d="M711.1,351.7c0-2.3,22.7-0.8,29.2,0
					c4.3,0.5,5.1,1,5.2,1.8c0.1,0.8-0.3,1.1-2.5,1.1c-2.1,0-4.8-0.1-11-0.6C712.2,352.7,711.1,351.7,711.1,351.7L711.1,351.7z"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M727.4,372.8c-4.3-0.8-7.6-5.4-9.1-8.9c-1.5-3.6-5.8-19.9-6.3-21.7
			c-0.5-1.8-0.4-3.6,2.8-3.6c3,0,26.3,4.5,29.2,5.6c3,1.1,4.8,2.2,5.8,4.8c1.9,5.1,3.2,12.2,3.4,15.5c0.3,3.7-0.8,5.8-3.2,7
			C743.9,375,731.7,373.6,727.4,372.8L727.4,372.8z"
              />
            </g>
          </g>
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M219,319.1c28.6,0,51.7,23.1,51.7,51.7s-23.1,51.7-51.7,51.7
		s-51.7-23.1-51.7-51.7S190.5,319.1,219,319.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M259,370.8c0,22.1-17.9,40-40,40s-40-17.9-40-40s17.9-40,40-40
		C241.2,330.8,259,348.7,259,370.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M223.8,370.8c0,2.6-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8
		S223.8,368.1,223.8,370.8z M226.7,341.8c2.1-5.1,2.2-7,2.3-7.4c0-0.4-0.3-0.8-0.7-1c-3-0.7-6.1-1.1-9.4-1.1c-3.2,0-6.3,0.4-9.4,1.1
		c-0.4,0.1-0.7,0.4-0.7,1c0,0.4,0.1,2.5,2.3,7.4c1.4,3.4,5.8,8.5,7.7,8.5S225.3,345.2,226.7,341.8L226.7,341.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M226.6,338.4c-2.5-0.6-5-0.8-7.6-0.8s-5.1,0.3-7.6,0.8
		c1.4,5.1,5.6,9.9,7.6,9.9C220.9,348.4,225.2,343.4,226.6,338.4L226.6,338.4z M219,353.5c1.8,0,7.3-6.1,9.9-12.7
		c1.6-4.3,1.9-6.1,2.2-6.5c0.1-0.4,0.6-0.7,1-0.5c3.7,1.2,7,3,9.9,5.2c3,2.2,5.6,4.8,8.1,7.8c0.3,0.4,0.1,0.8-0.1,1.1
		c-0.4,0.3-2.1,1.1-5.5,4c-5.5,4.5-9.5,11.7-8.9,13.3"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M239.9,354.2c2.8-3.2,4.5-4.7,4.5-4.7c-1.8-2.1-3.7-4-6.1-5.6
		c-2.2-1.6-4.7-3-7.2-4c0,0-1,2.2-3,5.8s-6.1,11.8-1.9,14.9C230.6,363.7,237.2,357.3,239.9,354.2L239.9,354.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M231.5,334.2c0,0,1.1,2.1,0.6,3.9c-0.4,1.4-0.8,1.9-0.8,1.9 M250,347.6
		c0,0-2.3-0.4-3.9,0.7c-1.1,0.8-1.5,1.2-1.5,1.2 M226.6,338.4c0.6-2.2,2.1-4.4,2.1-4.4 M211.4,338.4c-0.6-2.2-2.1-4.4-2.1-4.4
		 M219,357.9c2.1,0,3.7,1.7,3.7,3.7c0,2.1-1.6,3.7-3.7,3.7s-3.7-1.6-3.7-3.7S216.9,357.9,219,357.9L219,357.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M217.6,364.1l-1.4-2.5l1.4-2.5h2.8l1.5,2.5l-1.5,2.5H217.6z M248.9,369.2
		c5.5,0.4,7.4,0,7.8-0.1s0.7-0.4,0.7-1c-0.3-3-0.8-6.2-1.8-9.2c-1-3-2.3-5.9-4-8.5c-0.3-0.4-0.7-0.5-1.1-0.4c-0.4,0.1-2.2,1-6.5,4.5
		c-2.8,2.3-6.3,8.1-5.8,9.9C239.1,366.3,245.3,368.9,248.9,369.2L248.9,369.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M252.2,367.9c-0.3-2.5-0.7-5-1.5-7.4c-0.8-2.5-1.9-4.8-3.2-6.9
		c-4.4,2.9-7.7,8.5-7.2,10.3C240.9,365.6,246.9,368.2,252.2,367.9L252.2,367.9z M235.5,365.5c0.6,1.6,8,5.1,15.1,5.5
		c4.5,0.3,6.5,0,6.9,0s0.8,0.4,0.8,0.8c-0.1,3.9-0.8,7.6-1.9,11.1c-1.1,3.4-2.8,6.9-5,10c-0.3,0.4-0.7,0.4-1.1,0.3
		c-0.4-0.3-1.6-1.5-5.5-4c-6.1-3.9-14-5.5-15.4-4.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M241.3,385.5c3.9,1.7,5.9,2.9,5.9,2.9c1.4-2.3,2.6-4.8,3.4-7.4
		c0.8-2.6,1.4-5.4,1.5-8.1c0,0-2.5-0.3-6.5-1.1c-4.1-0.8-13.1-2.2-14.7,2.8C229.3,379.6,237.4,383.9,241.3,385.5L241.3,385.5z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M257.7,371.4c0,0-1.6,1.6-3.6,1.6c-1.4,0-2.1-0.1-2.1-0.1 M250.6,393
		c0,0-0.3-2.3-1.8-3.4l-1.6-1.1 M252.2,367.9c2.3-0.1,4.8,0.5,4.8,0.5 M247.5,353.6c1.9-1.2,3.6-3.3,3.6-3.3 M231.2,366.8
		c0.7,1.9-0.4,4-2.3,4.7c-1.9,0.7-4-0.4-4.7-2.3s0.4-4,2.3-4.7S230.6,364.9,231.2,366.8L231.2,366.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M225.1,367.4l1.8-2.1l2.8,0.5l0.8,2.6l-1.8,2.1l-2.8-0.5L225.1,367.4z
		 M229.9,398.8c1.2,5.4,2.3,7,2.6,7.4s0.7,0.5,1.1,0.3c2.9-1.1,5.6-2.6,8.3-4.5s4.8-4,6.9-6.3c0.3-0.3,0.3-0.8,0-1.1
		c-0.3-0.3-1.5-1.8-6.2-4.7c-3.2-1.9-9.6-3.4-11.3-2.3C229.5,388.4,228.9,395.2,229.9,398.8L229.9,398.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M231.9,401.4c2.3-1,4.5-2.2,6.6-3.7s4-3.3,5.6-5.1
		c-4.1-3.3-10.5-4.7-12-3.6C230.7,390.1,230.1,396.5,231.9,401.4L231.9,401.4z M229.2,384.7c-1.5,1.1-2.3,9.2-0.6,16.1
		c1.1,4.4,1.9,6.1,2.1,6.6c0.1,0.4-0.1,0.8-0.6,1c-3.7,1.1-7.4,1.6-11.1,1.6s-7.4-0.5-11.1-1.6c-0.4-0.1-0.7-0.5-0.6-1
		c0.1-0.4,1-2.1,2.1-6.6c1.8-6.9,0.8-15-0.6-16.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M211.8,396.4c-0.4,4.1-1,6.5-1,6.5c2.6,0.7,5.4,1,8.1,1
		c2.8,0,5.5-0.3,8.1-1c0,0-0.6-2.3-1-6.5s-2.1-13.1-7.2-13.1C213.8,383.3,212.3,392.3,211.8,396.4L211.8,396.4z M230.4,407.7
		c0,0-2.2-1.1-2.8-2.8c-0.4-1.4-0.6-1.9-0.6-1.9 M207.7,407.7c0,0,2.2-1.1,2.8-2.8c0.4-1.4,0.6-1.9,0.6-1.9 M231.9,401.4
		c0.8,2.1,1,4.8,1,4.8 M244.2,392.6c1.8,1.4,4.3,2.3,4.3,2.3 M226.6,381.1c-1.6,1.2-4,0.8-5.1-0.8c-1.2-1.6-0.8-4,0.8-5.1
		c1.6-1.2,4-0.8,5.1,0.8C228.6,377.7,228.2,379.9,226.6,381.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M224.1,375.4l2.6,1.2l0.3,2.8l-2.3,1.6l-2.5-1.1l-0.3-2.9L224.1,375.4z
		 M195.8,389.7c-4.7,2.9-5.9,4.4-6.2,4.7c-0.3,0.3-0.3,0.8,0,1.1c1.9,2.3,4.3,4.5,6.9,6.3c2.6,1.9,5.4,3.4,8.3,4.5
		c0.4,0.1,0.8,0,1.1-0.3c0.3-0.4,1.2-2.1,2.6-7.4c0.8-3.6,0.3-10.3-1.2-11.4C205.5,386.2,198.9,387.7,195.8,389.7L195.8,389.7z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M193.8,392.6c1.6,1.9,3.6,3.6,5.6,5.1c2.1,1.5,4.3,2.8,6.6,3.7
		c1.8-5,1.2-11.4-0.3-12.5S198,389.3,193.8,392.6z M208.8,384.7c-1.5-1.1-9.5,0.5-15.4,4.4c-3.9,2.5-5.2,3.7-5.5,4
		c-0.4,0.3-0.8,0.1-1.1-0.3c-2.2-3.2-3.9-6.6-5-10s-1.8-7.3-1.9-11.1c0-0.4,0.3-0.8,0.8-0.8c0.4,0,2.3,0.3,6.9,0
		c7.2-0.4,14.6-3.9,15.1-5.5"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M187.4,393c0,0,0.3-2.3,1.8-3.4c1.1-0.8,1.6-1.1,1.6-1.1 M180.3,371.4
		c0,0,1.6,1.6,3.4,1.6c1.4,0,2.1-0.1,2.1-0.1 M193.8,392.6c-1.8,1.4-4.3,2.3-4.3,2.3 M206.1,401.4c-0.8,2.1-1,4.8-1,4.8
		 M211.4,381.1c-1.6-1.2-2.1-3.4-0.8-5.1c1.2-1.7,3.4-2.1,5.1-0.8s2.1,3.4,0.8,5.1C215.4,382,213.1,382.4,211.4,381.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M216.2,377l-0.4,2.9l-2.5,1.1l-2.3-1.6l0.4-2.8l2.5-1.2L216.2,377z
		 M193.8,354.6c-4.1-3.6-6.1-4.3-6.5-4.5c-0.4-0.1-0.8,0-1.1,0.4c-1.7,2.6-3,5.5-4,8.5s-1.5,6.2-1.8,9.2c0,0.4,0.3,0.8,0.7,1
		s2.3,0.5,7.8,0.1c3.7-0.3,9.9-2.9,10.5-4.7C200.2,362.7,196.7,356.9,193.8,354.6L193.8,354.6z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M190.5,353.6c-1.2,2.2-2.3,4.4-3.2,6.9c-0.8,2.5-1.4,5-1.5,7.4
		c5.2,0.3,11.1-2.3,11.8-4.1C198.2,362,194.9,356.5,190.5,353.6L190.5,353.6z M202.5,365.5c0.6-1.6-3.4-8.8-8.9-13.3
		c-3.6-2.9-5.2-3.7-5.5-4c-0.4-0.3-0.4-0.8-0.1-1.1c2.3-3,5.1-5.6,8.1-7.8s6.3-4,9.9-5.2c0.4-0.1,0.8,0,1,0.6
		c0.1,0.4,0.4,2.3,2.2,6.5c2.6,6.6,8.1,12.7,9.9,12.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M209.8,345.8c-2.1-3.6-3-5.8-3-5.8c-2.5,1-5,2.3-7.2,4
		c-2.3,1.6-4.3,3.6-6.1,5.6c0,0,1.8,1.6,4.5,4.7c2.8,3.2,9.4,9.5,13.6,6.3C215.8,357.5,211.8,349.4,209.8,345.8L209.8,345.8z
		 M188.1,347.6c0,0,2.3-0.4,3.9,0.7c1.1,0.8,1.5,1.2,1.5,1.2 M206.5,334.2c0,0-1.1,2.1-0.6,3.9c0.4,1.4,0.7,1.9,0.7,1.9
		 M190.5,353.6c-1.9-1.2-3.6-3.3-3.6-3.3 M185.9,367.9c-2.3-0.1-4.8,0.5-4.8,0.5 M206.8,366.8c0.7-1.9,2.8-3,4.7-2.3s3,2.8,2.3,4.7
		s-2.8,3-4.7,2.3C207.3,370.8,206.2,368.8,206.8,366.8L206.8,366.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M212.1,370l-2.6,0.5l-1.9-2.1l0.8-2.6l2.8-0.5l1.9,2.1L212.1,370z"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M698,392.7v0.7c0,1.1,0.8,1,0.8,1l17.3-0.1l25.9-0.1
	c2.1-0.1,4.1-0.4,5.1-0.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M747.2,393.5c0.4,0,0.5-0.1,0.5-0.1c2.2-0.7,0.4-2.5,0.4-2.5
	c-0.8-0.5-2.8-1.4-2.8-1.4c-1-0.5-2.2-0.8-2.2-0.8l-4.3-0.4c-1.1-0.1-1.9,0.1-1.9,0.1c-1,0.3-3.4,1-3.4,1
	c-2.8,0.1-11.3,0.1-11.3,0.1l-24.3,0.1v3 M192.2,371.9c-4.1,0.8-6.5,1.1-6.5,1.1c0.1,2.8,0.7,5.4,1.5,8.1c0.8,2.6,2.1,5.2,3.4,7.4
	c0,0,2.1-1.2,5.9-2.9c3.9-1.6,11.8-5.9,10.3-10.9C205.4,369.9,196.3,371.1,192.2,371.9L192.2,371.9z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M576.5,385.8
		l-31,0.4l-6.9-7.6c0,0,1.5-1.4,1.8-4.5l0.7-3.6l0.8-14.4l0.7-13.6c0,0,1-17.9-1.4-32.7c0,0-2.2-17.1-13.8-26.8l21,0.1
		c0,0,11.4,4.5,14.2,4.4c0,0,36.3-1.5,85.3,7.6c0,0,33.3,5.6,58.7,15.4c0,0-22-6.5-24.9-3.9c0,0-3.3,1.2,10,10.2l-15.1,11.7
		c0,0-15.1-14.9-41.3-14.9c0,0-27.2-1.2-46.6,22.3c0,0-11.7,13.8-12.5,32.1L576.5,385.8z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M527.4,283.1l-3.3-0.1
		l-167.4-9c0,0,4.5,16.6,7.8,33.7s13.9,72.1,13.9,72.1l156.3,0.6c0,0,3.6,0.1,5.1-3.3l0.4-1.2l1-5.2c0,0,1.7-29.4,1.7-31.1
		c0-1.6,0.6-18.8-1.8-31.2C541,308.5,539.1,293.7,527.4,283.1z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M356.7,274.1
		c0,0-24.1-0.4-84.3-2.9c0,0,2.3,14.3,7.6,35.6c0,0,5.5,19.9,13.6,39.8c0,0,6.9,22,18.7,29.4c0,0,5.4,4.1,16.8,3.9h49.5l-12.5-65.5
		c0,0-1.2-6.5-2.8-13.2C363.2,301.1,359.6,283.9,356.7,274.1z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M198.1,317.6
		l-28.3-1c0,0-8.8,0.3-16-3.6l-27.7-16.9c0,0,5.4-2.5,7.8-4.1c2.5-1.8,6.1-4.5,7.7-7.2c0,0,1.1-1.9-2.5-4.7l-19.9-8.1
		c0,0,11.3-1.4,28.9-2.1l12.2-0.5c0,0,12.8-1.1,21.3-4.1c0,0,51,4.3,90.8,5.9l5.2,26l2.5,10.3c0,0,3.6,11.8,5.5,17.7
		c0,0,5.2,16,11.3,30.1l-17.1,16.9c0,0,1.1-9.9-3.7-21.6c0,0-6.9-20.6-29.4-31.1C246.8,319.6,224.1,307.4,198.1,317.6z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M513.9,282.4
		c0,0-19.5-14.3-40.7-26.1l0.8,5c0,0-4.4-1.2-7.2-1.2c-2.8,0-4.1,1-4.3,2.5c-0.1,1.7-0.7,7.8,2.6,12.5c0,0,0.8,2.1,10.7,3.9l0.3,1.7
		L513.9,282.4z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M469.8,277.3
		l-7-0.3l-92.4-4.4L358,226.2c0,0,31.9,0,58.9,5.2c0,0,19.3,3.6,56.4,24.6l0.8,5c0,0-4.8-1.5-8-1.2c-3,0.3-3.4,1.8-3.6,2.8
		c-0.1,1,0,5.8,0.7,7.7C463.7,272.3,464.3,275.9,469.8,277.3z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M353.4,271.8
		l-40-1.9c0,0-6.5,0.5-13.1-2.2c0,0-3.6-1.4-6.5-4.5c0,0-9.1-10.7-14.6-21.6c0,0-1.1-2.2,0.8-3.3c0,0,6.7-3.3,32.2-7
		c0,0,12.2-1.9,31.4-2.6L353.4,271.8z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="637.7"
              cy="370.8"
              r="51.7"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="219"
              cy="370.8"
              r="51.7"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe4DrRight);
