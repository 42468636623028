import React, { Component } from 'react';
import PrintButton from '../PrintButton';

import $ from 'jquery';
window.$ = window.jQuery = $;

export default class PrintFrame extends Component {
  newWindowPrint = () => {
    let win = window.open(
      '',
      'Title',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes'
    );
    win.document.write('<html><head>');
    win.document.write(document.head.innerHTML);
    win.document.write('</head><body>');
    win.document.write($(this.props.printTarget).html());
    win.document.write('</body></html>');
    $(win.document).ready(function() {
      setTimeout(function() {
        win.print();
        win.close();
      }, 1000);
    });
  };

  clickHandler() {
    var pri = document.getElementById('printFrame').contentWindow;

    pri.document.open();
    pri.document.write($(this.props.rintTarget).html());
    pri.document.head.innerHTML = document.head.innerHTML;
    pri.document.close();
    pri.focus();

    $('html,body').animate(
      { scrollTop: $(document).height() },
      5000,
      'swing',
      function() {
        pri.print();
      }
    );
  }

  render() {
    let showPrintButton = 'none';

    if (
      this.props.printPage === 'searchList' ||
      this.props.page === 'watchlist' ||
      this.props.page === 'search' ||
      this.props.page === 'nosales' ||
      this.props.page === 'recentlyadded' ||
      this.props.page === 'sellingnow'
    ) {
      showPrintButton = 'inline';
    }

    return (
      <div
        style={{ display: showPrintButton, float: 'right', marginLeft: '5px' }}
      >
        <PrintButton printHandler={this.newWindowPrint} />
        <iframe
          title="printFrame"
          id="printFrame"
          name="printFrame"
          className="printFrame"
        />
      </div>
    );
  }
}
