import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { get } from 'lodash';
import {
  loadBiddingOn,
  loadBuyerBids,
  loadBuyNow,
  loadFeaturedES,
  loadMarketplaces,
  loadNoSalesFromEventsES,
  loadRecentlyAdded,
  loadRecentlyViewed,
  loadRetailItems,
  loadRunningToday,
  loadSellingNow,
  loadTradeItems,
  loadUserItems,
  loadWatchlistES,
  loadWatchlistUserItems,
} from '../actions';
import { getConfig, getTemplate } from '../utils/helpers';
import { sliderSettings } from '../utils/constants';
import Vehicle from './Vehicle';
import EventCard from './EventCard';
import ChildMarketplaceCard from './ChildMarketplaceCard';

const styles = {
  blankCardStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 250,
    border: '1px solid #ccc',
    borderBottom: '2px solid #ced6db',
    borderRadius: 6,
    backgroundColor: '#fff',
    margin: 8,
  },
};

class HomeSection extends Component {
  state = {
    category: '',
    title: '',
    link: '',
    linkText: '',
    list: [],
    size: 24,
    isLoading: false,
  };

  componentDidMount() {
    this.loadOrUpdateData();
  }

  componentDidUpdate(prevProps) {
    // update watchlist section whenever someone adds/removes a watchlist item
    if (
      this.props.section &&
      this.props.section.name === 'ItemsInWatchList' &&
      this.props.watchlistObject !== prevProps.watchlistObject
    ) {
      this.loadOrUpdateData();
    }

    // update bidding on section which needs to change when someone bids on an item for the first time
    if (
      this.props.section &&
      this.props.section.name === 'BiddingOn' &&
      this.props.biddingOn !== prevProps.biddingOn
    ) {
      const list = get(this.props.biddingOn, 'hits.hits', []).map(
        (item, index, arr) =>
          this.mapToVehicleCard(item, index, arr, 'BiddingOn')
      );

      this.setState({ list });
    }

    // update any/all sections whenever signalr bid messages come in
    if (
      this.props.section &&
      this.props.bidMessagesRealTime !== prevProps.bidMessagesRealTime
    ) {
      this.loadOrUpdateData(this.state.data);
    }

    // update the events section when eventList changes
    if (
      this.props.section &&
      this.props.section.name === 'Events' &&
      this.props.events.eventList !== prevProps.events.eventList
    ) {
      this.loadOrUpdateData();
    }
  }

  next = () => {
    this.sliderRef.slickNext();
  };

  previous = () => {
    this.sliderRef.slickPrev();
  };

  returnHits = ({ response }) => {
    return response && response.hits ? response.hits.hits : [];
  };

  mapToVehicleCard = (item, index, arr, category) => {
    const itemData = {
      ...item._source,
      ...this.props.bidMessagesRealTime[item._source.id],
    };
    const navItems = arr.map(item => item.id || item._id).join(',');

    return (
      <div key={itemData.id || index}>
        <Vehicle
          index={index}
          vehicleData={itemData}
          category={category} // !important - needed for unique modal ids
          homePage={true}
          navItems={navItems}
          navigate={this.props.navigate}
        />
      </div>
    );
  };

  mapToEventCard = (item, index) => {
    return (
      <div key={item.eventId || index}>
        <EventCard
          index={index}
          item={item}
          homePage={true}
          navigate={this.props.navigate}
        />
      </div>
    );
  };

  mapToMarketplaceCard = (item, index) => {
    return (
      <div key={item.marketplaceId || index}>
        <ChildMarketplaceCard
          index={index}
          marketplaceData={item}
          homePage={true}
          navigate={this.props.navigate}
        />
      </div>
    );
  };

  sortWatchlist = (a, b) => {
    const watchlistIds = get(this.props.watchlistUserItems, 'items', []).map(
      item => item.itemId
    );
    const aIndex = watchlistIds.indexOf(Number(a._id));
    const bIndex = watchlistIds.indexOf(Number(b._id));
    return aIndex - bIndex;
  };

  sortEvents = (a, b) => {
    if (a.eventShortName < b.eventShortName) return -1;
    else if (a.eventShortName > b.eventShortName) return 1;
    else {
      if (a.eventStartTime < b.eventStartTime) return -1;
      else if (a.eventStartTime > b.eventStartTime) return 1;
      else return 0;
    }
  };

  // the main thing
  loadOrUpdateData = (existingData = undefined) => {
    // if data already exists just remap it, otherwise fetch it

    if (!this.state.loading) {
      this.setState({ loading: true }, async () => {
        const { section } = this.props;
        const { size } = this.state;
        let category = section.name || '';
        let label = section.label || '';
        let link = section.link || '';
        let linkText = section.link ? 'View All' : '';
        let list = [];

        const marketplaceCardHeight = 320;
        const eventCardHeight = 320;
        const vehicleCardHeight = getConfig('enableDynamicAttributes')
          ? 575 //  holman
          : 565;

        switch (category) {
          // marketplace cards
          case 'Marketplaces': {
            const data = existingData || (await this.loadMarketplaces());
            list = data.map(this.mapToMarketplaceCard);
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, marketplaceCardHeight)
              );
            }
            break;
          }

          // event cards
          case 'Events': {
            const data = existingData || this.filterEvents();
            list = data.map(this.mapToEventCard);
            if (!existingData && list.length >= size) {
              list.push(this.renderBlankCard(link, linkText, eventCardHeight));
            }
            break;
          }
          case 'LiveEvents': {
            const data = existingData || this.filterLiveEvents();
            list = data.map(this.mapToEventCard);
            if (!existingData && list.length >= size) {
              list.push(this.renderBlankCard(link, linkText, eventCardHeight));
            }
            break;
          }
          case 'RetailEvents': {
            const data = existingData || this.filterRetailEvents();
            list = data.map(this.mapToEventCard);
            if (!existingData && list.length >= size) {
              list.push(this.renderBlankCard(link, linkText, eventCardHeight));
            }
            break;
          }
          case 'TradeEvents': {
            const data = existingData || this.filterTradeEvents();
            list = data.map(this.mapToEventCard);
            if (!existingData && list.length >= size) {
              list.push(this.renderBlankCard(link, linkText, eventCardHeight));
            }
            break;
          }

          // item cards
          case 'ItemsInWatchList': {
            const data = existingData || (await this.loadWatchlist());
            list = data
              .sort(this.sortWatchlist)
              .map((item, index, arr) =>
                this.mapToVehicleCard(item, index, arr, category)
              );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'BiddingOn': {
            const data = existingData || (await this.loadBiddingOn());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'BuyNow': {
            const data = existingData || (await this.loadBuyNow());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'NoSales': {
            const data = existingData || (await this.loadNoSales());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'RecentlyAdded': {
            const data = existingData || (await this.loadRecentlyAdded());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'RecentlyViewed': {
            const data = existingData || (await this.loadRecentlyViewed());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'Retail': {
            const data = existingData || (await this.loadRetailItems());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'RunningToday': {
            const data = existingData || (await this.loadRunningToday());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'SellingNow': {
            const data = existingData || (await this.loadSellingNow());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'Trade': {
            const data = existingData || (await this.loadTradeItems());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }
          case 'Featured': {
            const data = existingData || (await this.loadFeatured());
            list = data.map((item, index, arr) =>
              this.mapToVehicleCard(item, index, arr, category)
            );
            if (!existingData && list.length >= size) {
              list.push(
                this.renderBlankCard(link, linkText, vehicleCardHeight)
              );
            }
            break;
          }

          default:
            break;
        }

        this.setState({
          category,
          label,
          link,
          linkText,
          list,
          loading: false,
        });
      });
    }
  };

  // load marketplace sections
  loadMarketplaces() {
    const mpId = getConfig('marketplaceId');

    return this.props.loadMarketplaces(mpId).then(({ response }) => {
      return response && response.marketplaceList
        ? response.marketplaceList
        : [];
    });
  }

  // filter events section (we don't need to load since this is already done in top nav)
  filterEvents() {
    const { events, unauthorizedEventIds } = this.props;

    const filteredEvents = (events.eventList || [])
      .filter(event => {
        const isAllowed = !unauthorizedEventIds.includes(event.eventId);
        const hasItemCount = event.itemCount > 0;
        return isAllowed && hasItemCount;
      })
      .sort(this.sortEvents);

    return filteredEvents;
  }

  filterLiveEvents() {
    const { events, unauthorizedEventIds } = this.props;

    return (events.eventList || [])
      .filter(event => {
        const isAllowed = !unauthorizedEventIds.includes(event.eventId);
        const hasItemCount = event.itemCount > 0;
        const isCorrectType = event.eventTypeId === 3;
        return isAllowed && hasItemCount && isCorrectType;
      })
      .sort(this.sortEvents);
  }

  filterRetailEvents() {
    const { events, unauthorizedEventIds } = this.props;

    return (events.eventList || [])
      .filter(event => {
        const isAllowed = !unauthorizedEventIds.includes(event.eventId);
        const hasItemCount = event.itemCount > 0;
        const isCorrectType = (event.eventName || '')
          .toLowerCase()
          .includes('retail');
        return isAllowed && hasItemCount && isCorrectType;
      })
      .sort(this.sortEvents);
  }

  filterTradeEvents() {
    const { events, unauthorizedEventIds } = this.props;

    return (events.eventList || [])
      .filter(event => {
        const isAllowed = !unauthorizedEventIds.includes(event.eventId);
        const hasItemCount = event.itemCount > 0;
        const isCorrectType = (event.eventName || '')
          .toLowerCase()
          .includes('trade');
        return isAllowed && hasItemCount && isCorrectType;
      })
      .sort(this.sortEvents);
  }

  // item sections
  loadBiddingOn() {
    const mpId = getConfig('marketplaceId');

    return this.props
      .loadBuyerBids(mpId)
      .then(({ response }) => {
        return response && response.data
          ? response.data.map(item => item.itemId)
          : [];
      })
      .then(ids => {
        return ids.length
          ? this.props
              .loadBiddingOn(
                ids,
                this.state.size,
                this.props.unauthorizedEventIds
              )
              .then(this.returnHits)
          : [];
      });
  }

  loadBuyNow() {
    return this.props
      .loadBuyNow(this.state.size, this.props.unauthorizedEventIds)
      .then(this.returnHits);
  }

  loadNoSales() {
    const { events, unauthorizedEventIds } = this.props;

    const eventIds = (events.eventList || [])
      .filter(event => {
        const isAllowed = !unauthorizedEventIds.includes(event.eventId);
        const hasItemCount = event.itemCount > 0;
        const isCorrectType = event.eventTypeId === 3;
        const noSalesAllowed = !(
          event.marketplace.marketplaceFeaturesArray || []
        ).includes('106');
        return isAllowed && hasItemCount && isCorrectType && noSalesAllowed;
      })
      .map(event => event.eventId);

    return eventIds.length
      ? this.props
          .loadNoSalesFromEventsES(
            eventIds,
            this.state.size,
            unauthorizedEventIds
          )
          .then(this.returnHits)
      : [];
  }

  loadRecentlyAdded() {
    return this.props
      .loadRecentlyAdded(
        this.props.listingStatusIds,
        this.state.size,
        this.props.unauthorizedEventIds
      )
      .then(this.returnHits);
  }

  loadRecentlyViewed() {
    const mpId = getConfig('marketplaceId');

    return this.props
      .loadUserItems(mpId)
      .then(({ response }) => {
        return response && response.itemList ? response.itemList : [];
      })
      .then(ids => {
        return ids.length
          ? this.props
              .loadRecentlyViewed(
                ids,
                this.state.size,
                this.props.unauthorizedEventIds
              )
              .then(this.returnHits)
          : [];
      });
  }

  loadRetailItems() {
    return this.props
      .loadRetailItems(
        this.props.listingStatusIds,
        this.state.size,
        this.props.unauthorizedEventIds
      )
      .then(this.returnHits);
  }

  loadRunningToday() {
    return this.props
      .loadRunningToday(this.state.size, this.props.unauthorizedEventIds)
      .then(this.returnHits);
  }

  loadSellingNow() {
    return this.props
      .loadSellingNow(this.state.size, this.props.unauthorizedEventIds)
      .then(this.returnHits);
  }

  loadTradeItems() {
    return this.props
      .loadTradeItems(
        this.props.listingStatusIds,
        this.state.size,
        this.props.unauthorizedEventIds
      )
      .then(this.returnHits);
  }

  loadWatchlist() {
    const mpId = getConfig('marketplaceId');

    return this.props
      .loadWatchlistUserItems(mpId)
      .then(({ response }) => {
        return response && response.items
          ? response.items.map(item => item.itemId)
          : [];
      })
      .then(ids => {
        return ids.length
          ? this.props
              .loadWatchlistES(
                ids,
                null, // fetch them all
                this.props.unauthorizedEventIds
              )
              .then(this.returnHits)
          : [];
      });
  }

  loadFeatured() {
    return this.props
      .loadFeaturedES(this.state.size, this.props.unauthorizedEventIds)
      .then(this.returnHits);
  }

  renderBlankCard(link, linkText, height) {
    return (
      <div key={this.state.size + 1}>
        <div style={{ ...styles.blankCardStyle, height }}>
          <h2 className="category-title">
            <Link
              to={link}
              style={{
                color: getTemplate(
                  this.props.template,
                  'homeSectionViewAll.color'
                ),
              }}
            >
              {linkText || 'View All'}
            </Link>
          </h2>
        </div>
      </div>
    );
  }

  renderSectionHeader() {
    const { label, link, linkText } = this.state;
    const { template } = this.props;

    return (
      <div className='home__section-header' style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ fontSize: 28, fontWeight: 'bold', padding: '10px 10px' }}>
          {label}
        </div>
        {link && (
          <Link
            to={link}
            style={{
              fontSize: 18,
              padding: 8,
              color: getTemplate(template, 'homeSectionViewAll.color'),
            }}
          >
            {linkText || 'View All'}
          </Link>
        )}
      </div>
    );
  }

  render() {
    const { label, list } = this.state;

    if (!label || !list.length) {
      return null;
    }

    return (
      <div className="row" style={{ paddingTop: 10, paddingBottom: 10 }}>
        <div className="col-md-12">
          {this.renderSectionHeader()}
          <div className="full-screen-section">
            <Slider ref={el => (this.sliderRef = el)} {...sliderSettings}>
              {list}
            </Slider>
            <div
              className="left-arrow arrow"
              onClick={this.previous}
              style={{ textAlign: 'center', alignItems: 'center' }}
            >
              <span className="fa fa-chevron-left" />
            </div>
            <div
              className="right-arrow arrow"
              onClick={this.next}
              style={{ textAlign: 'center', alignItems: 'center' }}
            >
              <span className="fa fa-chevron-right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  const {
    biddingOn,
    bidMessagesRealTime,
    events,
    listingStatusIds,
    template,
    unauthorizedEventIds,
    watchlistES,
    watchlistUserItems,
    watchlistObject,
  } = state.entities;
  return {
    biddingOn,
    bidMessagesRealTime,
    events,
    listingStatusIds,
    template,
    unauthorizedEventIds,
    watchlistES,
    watchlistUserItems,
    watchlistObject,
  };
};

export default connect(mapStateToProps, {
  loadBiddingOn,
  loadBuyerBids,
  loadBuyNow,
  loadFeaturedES,
  loadMarketplaces,
  loadNoSalesFromEventsES,
  loadRecentlyAdded,
  loadRecentlyViewed,
  loadRetailItems,
  loadRunningToday,
  loadSellingNow,
  loadTradeItems,
  loadUserItems,
  loadWatchlistES,
  loadWatchlistUserItems,
})(HomeSection);
