import fetch from "isomorphic-fetch";

function checkStatus(response) {
  if (response.ok) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function parseJSON(response) {
  return response.json();
}

export default function postApiWithHeader(api, body) {
  return fetch(api, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(body)
  })
    .then(checkStatus)
    .then(parseJSON);
}
