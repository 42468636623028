import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M269.6,315l-0.3-6.5v111.8c0,5.2,2.8,8,8.4,8.3H301c5.5-0.1,8.4-2.9,8.4-8.3v-23.1h-27
	c-2.1,0.1-3.3-0.6-3.4-2.2v-14.2c-4.5-5.8-7.4-17.6-8.4-35.6v-1.6L269.6,315L269.6,315z"
          />
          <path
            fill="#656565"
            d="M279,380.7v14.2c0.3,1.6,1.4,2.3,3.4,2.2h277.2c2.1,0.1,3.2-0.5,3.4-2.2v-14.2c-3.6,2.1-8.8,3.6-16,4.4
	c-36.2,2.5-72.8,3.9-109.4,4h-33.4c-36.7-0.3-73.2-1.5-109.4-4C287.8,384.3,282.6,382.8,279,380.7L279,380.7z"
          />
          <path
            fill="#656565"
            d="M572.7,308.4l-0.3,6.5l-1,28.5v1.6c-1,18-3.7,29.9-8.4,35.6v14.2c-0.3,1.7-1.4,2.3-3.4,2.2h-27v23.1
	c0,5.2,2.8,8,8.4,8.3h23.2c5.5-0.1,8.3-2.9,8.4-8.3L572.7,308.4L572.7,308.4z"
          />
          <path
            fill="none"
            d="M375.7,308.2l-2.2-3l2.2-3l2.3,3 M439.2,283.9l-1.9-2.5l-1.8,2.5 M429.8,283.9l2.2-2.9l-2.2-3.2l-2.3,3.2
	L429.8,283.9 M419.4,283.9l2.2-2.9l-2.2-3.2l-2.3,3.2L419.4,283.9 M423.8,283.9l-1.8-2.6l-1.9,2.6 M428.9,283.9l-1.8-2.5l-1.9,2.5
	 M424.7,283.9l2.2-2.9l-2.3-3.2l-2.2,3.2L424.7,283.9 M434.2,283.9l-1.9-2.6l-1.8,2.6 M367.7,277.4l-2.2,3.2l-2.3-3.2l2.3-3
	 M369.2,272.6l1.1,1.4l-2.2,3.2l-2.3-3.2l1.1-1.4 M421.1,272.5l0.8,1.1l0.8-1.1 M426.3,272.5l0.8,1.1l0.8-1.1 M423.4,272.5l-1.1,1.5
	l2.2,3.2l2.3-3.2l-1.1-1.5 M427.1,274.4l2.2,3l-2.2,3.2l-2.3-3.2 M418.2,272.5l-1.1,1.5l2.3,3l2.2-3l-1.1-1.5 M422,274.4l2.2,3
	l-2.2,3.2l-2.3-3.2 M431.4,272.5l0.8,1.1l0.8-1.1 M436.6,272.5l0.7,1.1l0.8-1.1 M433.6,272.5l-1.1,1.5l2.3,3l2.2-3l-1.1-1.5
	 M437.3,274.4l2.3,3l-2.3,3.2l-2.2-3.2 M428.5,272.5l-1.1,1.5l2.2,3l2.3-3l-1.1-1.5 M432.2,274.4l2.2,3l-2.2,3.2l-2.2-3.2
	 M364.7,272.5l0.8,1.1l0.7-1 M372,272.6l-1.1,1.4l2.3,3l2.2-3l-1-1.4 M369.9,272.6l0.7,1l0.8-1 M372.8,277.4l-2.2,3.2l-2.2-3.2
	l2.2-3 M344.9,274.4l-2.2,3l2.2,3.2l2.3-3.2 M346.5,272.3l-1.2,1.6l2.2,3l2.2-3l-1.1-1.6 M353.8,272.5l1.1,1.5l-2.2,3l-2.3-3
	l1.2-1.6 M350.1,274.4l-2.3,3l2.3,3.2l2.2-3.2 M349.1,272.3l1,1.2l0.8-1.2 M343.9,272.2l1,1.4l1.1-1.2 M341.4,272.2L340,274l2.3,3
	l2.2-3l-1.2-1.8 M413.7,283.9l-1.9-2.6l-1.9,2.6 M409.3,283.9l2.1-3l-2.2-3l-2.2,3.2l2.1,2.9 M339.8,274.4l-2.2,3l2.2,3.2l2.2-3.2
	 M337.4,276.9l2.1-2.9l-1.4-1.8 M338.7,272.2l1.1,1.4l1.1-1.4 M338.1,282.8l1.4-1.8l-1.9-2.8 M340.9,282.8l-1.1-1.4l-1,1.4
	 M343.2,282.9l1.4-1.9l-2.2-3.2l-2.3,3.2l1.5,1.8 M351.3,283.1l-1.2-1.7l-1.2,1.5 M353.3,283.1l1.6-2.1l-2.2-3.2l-2.3,3.2l1.6,2.1
	 M346.1,282.9l-1.2-1.5l-1.1,1.5 M348.3,283.1l1.4-2.1l-2.2-3.2l-2.2,3.2l1.4,1.9 M414.3,283.9l2.2-3l-2.3-3L412,281L414.3,283.9
	 M418.7,283.9l-1.9-2.5l-1.9,2.5 M356.7,272.5l-1.2,1.5l2.3,3l2.2-3l-1.1-1.5 M357.5,277.4l-2.3,3.2l-2.2-3.2l2.2-3 M361.7,272.5
	l-1.1,1.5l2.3,3l2.3-3l-1.1-1.5 M403.3,283.7l-1.9-2.5l-1.8,2.5 M399.1,283.7l2.1-2.9l-2.3-3l-2.2,3.2l2.1,2.8 M408.4,283.9
	l-1.8-2.5l-1.9,2.5 M404.2,283.9l2.1-2.9l-2.2-3.2l-2.3,3.2l2.2,2.9 M400.6,272.5l0.8,1.1l0.8-1.1 M405.8,272.5l0.7,1.1l0.8-1.1
	 M402.8,272.5l-1.1,1.5l2.3,3l2.2-3l-1.1-1.5 M404.3,277.4l2.2,3.2l2.3-3.2l-2.2-3 M399.2,277.4l2.2,3.2l2.3-3.2l-2.3-3
	 M397.7,272.5l-1.1,1.5l2.2,3l2.3-3l-1.1-1.5 M410.9,272.5l0.8,1.1l0.8-1.1 M416,272.5l0.8,1.1l0.8-1.1 M413.1,272.5L412,274l2.2,3
	l2.3-3l-1.1-1.5 M416.8,274.4l2.3,3l-2.3,3.2l-2.3-3.2 M408,272.5l-1.1,1.5l2.2,3l2.2-3l-1.1-1.5 M411.7,274.4l2.2,3l-2.2,3.2
	l-2.3-3.2 M439.4,318.7l-2.1-2.6l-1.9,2.8 M434.3,318.8l-2.1-2.8l-2.1,2.9 M429.2,319l-2.1-2.9L425,319 M424.1,319l-2.1-2.9
	l-2.2,2.9 M493.9,315.2l-2.3-3l2.3-3l2.3,3 M419,319l-2.2-2.9l-2.2,2.9 M349.5,296l-1.8,2.3l2.3,3l2.2-3l-1.6-2.3 M413.8,319
	l-2.1-2.9l-2.1,2.8 M351.2,296l1.5,1.9l1.2-1.9 M344.3,296l-1.6,2.2l2.2,3.2l2.3-3l-1.8-2.3 M346.1,296l1.4,1.9l1.4-1.9
	 M345.3,301.8l2.2-3l2.2,3l-2.2,3 M350.4,301.8l2.3-3l2.2,3l-2.2,3 M408.7,318.8l-2.1-2.8l-2.1,2.8 M340.9,295.9l1.5,2.1l1.4-2.1
	 M341.3,299.4l0.7-1.1l-1.4-1.9 M342.3,304.7l0.1,0.1l2.2-3l-2.3-3l-1,1.2 M403.5,318.8l-2.1-2.8l-1.9,2.6 M350.1,308.4l-2.3-3.2
	l2.3-3l2.2,3 M352.6,305.6l2.3,3.2l-2.2,3l-2.3-3.2 M344.9,308.4l-2.2-3.2l2.2-3l2.3,3.2 M347.5,305.6l2.2,3.2l-2.2,3l-2.2-3
	 M343.4,310.4l1.2-1.6l-2.2-3 M345.6,314.4l1.6-2.2l-2.3-3l-1.4,1.9 M349.4,316.1l0.3-0.4l-2.2-3l-1.7,2.2 M350.1,316.2v-0.1
	l-0.1,0.1 M354.2,316.5l0.7-0.8l-2.3-3l-2.2,3l0.4,0.5 M350.1,315.2l-2.3-3l2.3-3.2l2.2,3.2 M497.3,313.9l-0.8-1.2l-2.3,3l0.1,0.1
	 M496.5,305.6l2.2,3l-2.2,3.2l-2.3-3 M493.9,308.4l-2.3-3l2.3-3.2l2.3,3 M414.2,312.6l-2.2,3l2.2,3l2.3-3 M416.8,315.2l2.3-3
	l-2.3-3.2l-2.3,3 M411.3,315.7l-2.2,3l-2.2-3l2.2-3 M499.1,309.2l-2.3,3l0.8,1.2 M411.7,315.2l2.2-3l-2.2-3.2l-2.3,3.2 M411.7,308.4
	l2.2-3l-2.2-3.2l-2.3,3 M411.5,308.6l-2.3,3.2l-2.2-3l2.2-3.2 M416.8,308.4l2.3-3.2l-2.3-3l-2.3,3 M414.2,305.6l-2.2,3l2.3,3.2
	l2.2-3 M406.2,315.7l-2.2,3l-2.3-3l2.3-3 M404.3,312.2l2.3-3.2l2.2,3.2l-2.2,3 M398.9,318.7l2.2-3l-2.3-3l-2.2,3L398.9,318.7
	 M399.2,312.2l2.2-3.2l2.3,3.2l-2.3,3 M399.2,305.2l2.2-3l2.3,3l-2.3,3.2 M401.1,308.6l-2.3,3.2l-2.2-3l2.2-3.2 M404.3,305.2l2.2-3
	l2.3,3l-2.3,3.2 M406.2,308.6l-2.2,3.2l-2.3-3.2l2.3-3 M406.2,301.8l-2.2,3l-2.3-3l2.3-3 M398.8,304.8l-2.2-3l2.2-3l2.3,3
	 M398,296.7l0.8,1.2l1-1.2 M400.3,296.7l-1.1,1.6l2.2,3l2.3-3l-1.2-1.6 M403.2,296.7l0.8,1.2l0.8-1.1 M416.5,301.8l-2.3-3l-2.2,3
	l2.2,3 M409.1,304.8l-2.2-3l2.2-3l2.2,3 M488.8,315.2l-2.3-3l2.3-3.2l2.2,3.2 M405.4,296.8l-1.1,1.5l2.3,3l2.2-3l-1.1-1.5
	 M408.3,296.8l0.8,1.1l0.8-1.1 M493.3,316.1l0.3-0.4l-2.2-3l-2.3,3l0.5,0.7 M489,316.4l-0.3-0.3l-0.3,0.4 M485.9,312.2l-2.2-3.2
	l-2.3,3.2l2.3,3 M487.8,316.5l0.7-0.8l-2.3-3l-2.2,3l0.7,1.1 M484.1,316.8l-0.4-0.7l-0.5,0.7 M483.8,308.8l2.3,3l2.3-3.2l-2.3-3
	 M485.9,305.2l-2.2-3l-2.3,3l2.3,3.2 M491.2,305.6l2.3,3.2l-2.3,3l-2.2-3 M488.8,308.4l-2.3-3l2.3-3l2.2,3L488.8,308.4z M489,301.8
	l2.2-3l2.2,3l-2.2,3.2L489,301.8L489,301.8z M486.1,304.8l2.2-3l-2.2-3l-2.3,3L486.1,304.8z M483,296.1l-1.6,2.2l2.3,3l2.2-3
	l-1.6-2.2 M484.8,296.1l1.4,1.8l1.4-1.9 M488.1,296l-1.6,2.2l2.3,3l2.2-3l-1.6-2.2 M489.9,296l1.4,1.9l1.4-1.9 M500,303.7l-1.1-1.5
	l-2.3,3l2.2,3l0.3-0.4 M413.5,297l0.7,1l0.7-1 M410.5,296.8l-1.1,1.5l2.2,3l2.2-3l-1-1.4 M437,315.7l-2.2,3.2l-2.3-3.2l2.3-3
	L437,315.7L437,315.7z M435.1,312.2l2.2-3l2.2,3l-2.2,3L435.1,312.2L435.1,312.2z M429.8,312.6l-2.3,3l2.3,3l2.2-3L429.8,312.6
	L429.8,312.6z M430,312.2l2.3-3l2.2,3l-2.2,3.2L430,312.2L430,312.2z M430,305.2l2.3-3l2.2,3l-2.2,3L430,305.2z M429.8,305.6l-2.3,3
	l2.3,3l2.3-3L429.8,305.6z M437,308.6l-2.2,3l-2.3-3l2.3-3L437,308.6z M435.1,305.2l2.2-3.2l2.2,3l-2.2,3L435.1,305.2L435.1,305.2z
	 M500.9,299.7l-1.5,2.1l1,1.2 M424.5,312.6l-2.2,3l2.2,3l2.2-3.2L424.5,312.6L424.5,312.6z M427.1,315.2l2.2-3l-2.2-3l-2.2,3
	L427.1,315.2z M419.4,312.6l-2.2,3l2.3,3.2l2.2-3L419.4,312.6L419.4,312.6z M422,315.2l2.2-3.2l-2.2-3.2l-2.3,3L422,315.2L422,315.2
	z M422,308.4l2.2-3l-2.2-3.2l-2.3,3L422,308.4L422,308.4z M419.4,305.6l-2.3,3l2.3,3l2.2-3L419.4,305.6z M427.1,308.4l2.2-3
	l-2.3-3.2l-2.2,3.2L427.1,308.4L427.1,308.4z M424.5,305.6l-2.2,3l2.3,3l2.2-3L424.5,305.6z M426.9,301.8l-2.3-3l-2.3,3l2.3,3.2
	L426.9,301.8L426.9,301.8z M421.6,301.8l-2.2-3l-2.3,3l2.3,3L421.6,301.8z M418.7,297l0.7,1l0.7-1 M415.6,297l-1,1.4l2.3,3l2.2-3
	l-1-1.4 M425.9,297l-1,1.4l2.3,3l2.2-3l-1.1-1.4 M420.7,297l-1,1.2l2.3,3l2.2-3.2l-1-1.2 M423.8,297l0.7,1l0.7-1 M434.8,304.8
	l-2.3-3l2.3-3l2.2,3L434.8,304.8z M432,301.8l-2.3-3l-2.3,3l2.3,3.2L432,301.8L432,301.8z M428.9,296.8l0.8,1.1l0.8-1.1 M431,296.8
	l-1.1,1.5l2.3,3l2.2-3l-1.1-1.5 M434,296.8l0.8,1.1l0.8-1.1 M436.2,296.8l-1.1,1.5l2.2,3l2.2-3l-1.1-1.5 M398.2,318.7l-1.9-2.6
	l-1.8,2.5 M392.9,318.5l-1.8-2.5l-1.6,2.3 M387.7,318.3l-1.6-2.2l-1.5,2.1 M372.8,312.2l-2.2-3l-2.3,3l2.3,3.2L372.8,312.2z
	 M373.9,317.7l1.5-2.1l-2.2-3l-2.2,3l1.4,1.9 M371.7,317.6l-1.1-1.5l-1,1.4 M367.7,312.2l-2.2-3l-2.2,3l2.3,3L367.7,312.2z
	 M368.9,317.5l1.2-1.8l-2.3-3l-2.2,3l1.2,1.6 M366.3,317.3l-0.8-1.2l-0.8,1.1 M365.8,308.8l2.3,3l2.2-3l-2.2-3L365.8,308.8
	L365.8,308.8z M367.7,305.3l-2.2-3.2l-2.3,3l2.3,3L367.7,305.3L367.7,305.3z M372.8,305.2l-2.2-3l-2.2,3l2.2,3L372.8,305.2
	L372.8,305.2z M370.9,308.6l2.3,3l2.2-3l-2.3-3L370.9,308.6L370.9,308.6z M362.6,312.2l-2.3-3l-2.2,3l2.3,3L362.6,312.2z
	 M364.1,317.2l1.1-1.5l-2.2-3l-2.3,3l1,1.4 M361,316.9l-0.7-0.8l-0.5,0.8 M357.4,312.1l-2.2-3l-2.3,3l2.3,3L357.4,312.1z
	 M359.2,316.8l0.8-1.1l-2.3-3l-2.2,3l0.7,1 M355.6,316.6l-0.4-0.5l-0.3,0.4 M355.5,308.7l2.2,3l2.2-3l-2.2-3L355.5,308.7z
	 M357.5,305.2l-2.2-3.2l-2.3,3l2.3,3L357.5,305.2L357.5,305.2z M360.6,308.8l2.3,3l2.2-3l-2.2-3.2L360.6,308.8L360.6,308.8z
	 M362.6,305.2l-2.2-3l-2.2,3l2.2,3L362.6,305.2z M362.9,304.8l2.2-3.2l-2.2-3l-2.3,3L362.9,304.8z M357.8,304.8l2.2-3l-2.2-3l-2.2,3
	L357.8,304.8z M354.6,296.1l-1.7,2.2l2.3,3l2.2-3l-1.6-2.2 M356.4,296.1l1.4,1.8l1.4-1.8 M359.6,296.1l-1.5,2.1l2.2,3l2.2-3L361,296
	 M361.7,296.1l1.2,1.6l1.2-1.6 M373.2,304.8l2.2-3.2l-2.2-3l-2.2,3L373.2,304.8z M368.1,304.8l2.2-3l-2.2-3l-2.3,3L368.1,304.8
	L368.1,304.8z M364.8,296.3l-1.5,2.1l2.2,3l2.2-3l-1.5-2.1 M366.7,296.3l1.2,1.6l1.1-1.5 M372,296.3l1.2,1.5l1.2-1.5 M369.9,296.3
	l-1.5,2.1l2.3,3l2.2-3l-1.5-2.1 M394,312.2l2.3-3l2.2,3l-2.2,3L394,312.2z M393.8,318.6l2.1-2.9l-2.2-3l-2.3,3l2.1,2.8 M388.9,312.2
	l2.3-3l2.2,3l-2.3,3.2L388.9,312.2L388.9,312.2z M386,315.3l-2.2-3l2.2-3l2.2,3L386,315.3z M388.9,318.3l1.9-2.8l-2.2-3l-2.3,3
	l2.1,2.6 M386,308.4l-2.2-3l2.3-3.2l2.2,3L386,308.4L386,308.4z M388.6,305.6l2.2,3l-2.3,3l-2.2-3L388.6,305.6z M388.9,305.2l2.3-3
	l2.2,3l-2.2,3L388.9,305.2z M394,305.2l2.3-3.2l2.2,3l-2.2,3L394,305.2L394,305.2z M393.7,305.6l2.2,3l-2.2,3l-2.3-3L393.7,305.6
	L393.7,305.6z M382.4,318.1l-1.5-2.1l-1.4,1.9 M380.9,315.3l-2.3-3l2.3-3l2.2,3L380.9,315.3z M383.9,318.1l1.8-2.5l-2.2-3l-2.3,3.2
	l1.8,2.5 M375.7,315.3l-2.2-3l2.3-3l2.2,3L375.7,315.3L375.7,315.3z M378.9,318l1.6-2.3l-2.2-3l-2.3,3l1.7,2.2 M377.1,317.9
	l-1.2-1.8l-1.2,1.6 M376.1,308.8l2.2,3l2.3-3l-2.2-3L376.1,308.8L376.1,308.8z M435,283.9l2.1-2.9l-2.2-3l-2.3,3l2.2,2.9
	 M380.9,308.2l-2.2-3l2.3-3l2.2,3L380.9,308.2L380.9,308.2z M383.5,305.6l2.2,3l-2.2,3l-2.2-3L383.5,305.6z M385.7,301.8l-2.2,3.2
	l-2.2-3l2.2-3L385.7,301.8L385.7,301.8z M378.3,304.8l2.2-3l-2.2-3l-2.3,3L378.3,304.8z M374.9,296.4l-1.5,1.9l2.3,3l2.2-3l-1.4-1.9
	 M377.2,296.4l1.1,1.5l1.1-1.5 M379.9,296.4l-1.4,1.9l2.3,3l2.2-3.2l-1.4-1.8 M382.4,296.4l1.1,1.4l1.1-1.4 M391.4,301.8l2.3-3
	l2.2,3l-2.2,3.2L391.4,301.8L391.4,301.8z M386.3,301.8l2.3-3l2.2,3l-2.2,3L386.3,301.8L386.3,301.8z M385.2,296.5l-1.4,1.8l2.2,3
	l2.2-3l-1.2-1.8 M387.5,296.5l1,1.4l1-1.4 M395.2,296.7l-1.2,1.6l2.3,3l2.2-3.2l-1.2-1.5 M392.7,296.5l1,1.2l1-1.2 M498.5,295.9
	l-1.8,2.3l2.3,3l2.1-2.8 M494.1,301.8l2.3-3l2.2,3l-2.2,3L494.1,301.8L494.1,301.8z M390.3,296.5l-1.4,1.8l2.2,3l2.3-3l-1.2-1.6
	 M388.9,283.6l2.1-2.8l-2.2-3.2l-2.3,3.2l2.1,2.8 M387.7,283.6l-1.6-2.3l-1.6,2.3 M493.3,296l-1.8,2.3l2.3,3l2.2-3l-1.6-2.3
	 M392.9,283.7l-1.8-2.5l-1.8,2.3 M495,296l1.5,1.9l1.5-1.9 M501.1,298l-1.6-2.2 M398.1,283.7l-1.8-2.5l-1.8,2.3 M501.3,297.5
	L500,296 M459.5,318.1L458,316l-1.6,2.2 M393.8,283.7l2.1-2.8l-2.2-3l-2.3,3l2.1,2.9 M378.6,283.6l1.9-2.6l-2.2-3l-2.3,3l1.9,2.6
	 M377.3,283.5l-1.6-2.2l-1.5,2.1 M454.5,318.3l-1.6-2.2l-1.8,2.3 M449.4,318.5l-1.8-2.5l-1.9,2.6 M382.6,283.6l-1.6-2.2l-1.6,2.2
	 M383.8,283.6l1.9-2.6l-2.3-3.2l-2.2,3l2.1,2.6 M383.1,277.4l-2.2,3l-2.3-3l2.3-3L383.1,277.4z M380.2,272.6l0.7,1l0.7-1
	 M382.2,272.6l-1,1.4l2.2,3l2.2-3.2l-1-1.4 M377.1,272.6L376,274l2.3,3.2l2.2-3l-1.1-1.4 M378,277.4l-2.2,3l-2.3-3.2l2.3-3
	L378,277.4z M375,272.6l0.7,1l0.7-1 M394,277.4l2.3,3l2.2-3l-2.2-3L394,277.4z M444.5,318.7l-1.9-2.6l-1.9,2.6 M455.6,312.2l2.3-3
	l2.2,3l-2.2,3.2L455.6,312.2z M455.7,318.3l1.9-2.6l-2.2-3l-2.3,3l1.9,2.6 M450.5,318.4l2.1-2.8l-2.2-3l-2.2,3l2.1,2.8 M450.5,312.2
	l2.3-3l2.2,3l-2.2,3.2L450.5,312.2z M452.4,308.6l-2.3,3l-2.2-3l2.2-3.2L452.4,308.6L452.4,308.6z M450.5,305.3l2.2-3.2l2.2,3
	l-2.2,3L450.5,305.3L450.5,305.3z M458,308.4l-2.3-3l2.3-3l2.2,3.2L458,308.4z M457.7,308.8l-2.2,3l-2.3-3l2.3-3L457.7,308.8z
	 M445.2,318.7l2.1-3l-2.2-3l-2.3,3l2.2,2.9 M445.4,312.2l2.3-3l2.2,3l-2.2,3L445.4,312.2z M439.9,318.7l2.2-3l-2.2-3l-2.2,3
	L439.9,318.7 M390.4,272.6l0.8,1l0.8-1 M440.2,312.2l2.3-3l2.2,3l-2.2,3.2L440.2,312.2z M440.2,305.2l2.3-3l2.2,3l-2.2,3
	L440.2,305.2z M442.3,308.6l-2.2,3l-2.2-3l2.2-3L442.3,308.6L442.3,308.6z M445.4,305.2l2.3-3.2l2.2,3.2l-2.2,3L445.4,305.2z
	 M447.4,308.8l-2.2,3l-2.3-3l2.3-3L447.4,308.8z M445,304.8l-2.3-3l2.3-3l2.2,3L445,304.8L445,304.8z M439.9,304.8l-2.2-3l2.2-3
	l2.2,3L439.9,304.8L439.9,304.8z M441.4,296.7l-1.2,1.6l2.3,3l2.3-3l-1.2-1.6 M439.1,296.7l0.8,1.1l1-1.1 M395.5,272.6l0.7,1l0.8-1
	 M392.5,272.6l-1.1,1.4l2.3,3l2.2-3.2l-1.1-1.4 M386,274.4l-2.2,3l2.3,3.2l2.2-3L386,274.4L386,274.4z M388.9,277.4l2.3,3l2.2-3
	l-2.3-3L388.9,277.4L388.9,277.4z M385.3,272.6l0.7,1l0.7-1 M387.4,272.6l-1.1,1.4l2.3,3.2l2.2-3l-1.1-1.5 M368.5,283.3l1.8-2.5
	l-2.2-3l-2.3,3.2l1.8,2.3 M367,283.3l-1.5-2.1l-1.5,1.9 M373.6,283.5l1.8-2.5l-2.2-3.2l-2.2,3l1.8,2.5 M372.1,283.5l-1.5-2.1
	l-1.5,2.1 M358.4,283.2l1.6-2.3l-2.2-3.2l-2.3,3.2l1.6,2.2 M356.6,283.2l-1.4-1.8l-1.4,1.8 M363.4,283.3l1.6-2.3l-2.2-3.2l-2.3,3
	l1.8,2.3 M361.8,283.2l-1.5-1.9l-1.4,1.9 M496.2,277.4l-2.2,3.2l-2.3-3l2.3-3L496.2,277.4L496.2,277.4z M495.5,272.3l-1.2,1.6l2.3,3
	l2.2-3l-1.2-1.8 M498.9,274.4l-2.2,3l2.3,3.2l2.2-3L498.9,274.4z M500.6,272.2l-1.4,1.8l2.3,3l2.2-3l-1.4-1.8 M498,272.2l1,1.4
	l1-1.4 M492.9,272.3l1,1.2l1-1.2 M485.1,272.5L484,274l2.3,3l2.2-3l-1.1-1.5 M485.9,277.4l-2.2,3.2l-2.3-3l2.3-3L485.9,277.4z
	 M490.3,272.3L489,274l2.2,3.2l2.3-3.2l-1.2-1.6 M491,277.4l-2.2,3l-2.3-3.2l2.3-3L491,277.4z M479.8,272.5l-1.1,1.5l2.3,3l2.2-3.2
	l-1.1-1.5 M487.8,272.5l0.8,1.2l1-1.2 M482.9,272.5l0.8,1.1l0.8-1.1 M490,283.1l-1.4-1.8l-1.4,1.8 M492.1,283.1l1.5-2.1l-2.2-3
	l-2.2,3l1.5,2.2 M486.8,283.2l1.5-2.2l-2.2-3.2l-2.3,3.2l1.6,2.2 M484.9,283.2l-1.4-1.8l-1.4,1.9 M500.2,282.8l-1.1-1.5l-1.1,1.5
	 M502.5,282.8l1.4-1.8l-2.2-3l-2.3,3l1.4,1.9 M495.1,282.9l-1.2-1.6l-1.2,1.6 M497.3,282.9l1.5-1.9l-2.2-3l-2.3,3l1.5,2.1
	 M354.4,272.5l0.8,1.1l0.8-1.1 M359.5,272.5l0.8,1.1l0.8-1.1 M505.1,273l-0.7,1l0.5,0.7 M504.9,275.5l-0.8-1l-2.2,3l2.2,3l0.3-0.3
	 M503.1,272.2l1.1,1.5l1-1.2 M504.3,281.5l-1.2,1.2 M362.6,277.4l-2.2,3l-2.3-3l2.2-3L362.6,277.4z M454.1,272.6L453,274l2.3,3
	l2.2-3l-1-1.4 M452,272.6l0.8,1l0.8-1 M449,272.6l-1.1,1.4l2.3,3.2l2.2-3.2l-1.1-1.4 M450.5,277.4l2.2,3l2.2-3l-2.2-3L450.5,277.4
	L450.5,277.4z M457.1,272.6l0.7,1l0.7-1 M459.3,272.6l-1.1,1.4l2.3,3l2.2-3.2l-1-1.4 M458,274.4l-2.3,3l2.3,3l2.2-3L458,274.4z
	 M441.7,272.5l0.8,1.1l0.8-1.1 M443.9,272.5l-1.1,1.5l2.3,3.2l2.2-3l-1.1-1.5 M440.2,277.4l2.3,3.2l2.2-3l-2.3-3L440.2,277.4
	L440.2,277.4z M438.8,272.5l-1.1,1.5l2.3,3.2l2.2-3l-1.1-1.5 M446.8,272.6l0.8,1.1l0.8-1.1 M445.4,277.4l2.3,3.2l2.2-3l-2.2-3
	L445.4,277.4z M445.3,283.7l2.1-2.8l-2.2-3.2l-2.3,3l2.1,2.9 M449.4,283.7l-1.8-2.3l-1.8,2.5 M440.2,283.7l2.1-2.9l-2.3-3.2l-2.2,3
	l2.2,2.9 M444.3,283.7l-1.8-2.5l-1.8,2.5 M460.8,283.6l1.9-2.6l-2.2-3.2l-2.3,3l1.9,2.6 M455.6,283.6l2.1-2.8l-2.2-3l-2.3,3l2.1,2.8
	 M459.6,283.6l-1.6-2.2l-1.6,2.3 M450.5,283.7l2.1-2.8l-2.2-3l-2.2,3l2.1,2.8 M454.5,283.6l-1.6-2.3l-1.8,2.3 M469.5,272.6l-1.1,1.5
	l2.3,3l2.2-3.2l-1.1-1.4 M472.5,272.6l0.8,1l0.8-1.1 M474.7,272.6l-1.1,1.5l2.2,3l2.2-3.2l-1.1-1.5 M475.6,277.4l-2.2,3.2l-2.2-3
	l2.3-3L475.6,277.4z M480.7,277.4l-2.2,3.2l-2.2-3l2.2-3L480.7,277.4z M477.6,272.5l0.8,1.1l0.8-1.1 M464.4,272.6l-1.1,1.4l2.2,3.2
	l2.2-3.2l-1-1.4 M462.4,272.6l0.7,1l0.7-1 M463,274.4l2.2,3l-2.2,3.2l-2.2-3.2L463,274.4z M467.5,272.6l0.8,1l0.8-1 M470.5,277.4
	l-2.2,3l-2.3-3l2.3-3L470.5,277.4z M466.1,283.5l1.9-2.6l-2.2-3l-2.3,3.2l1.9,2.6 M469.8,283.5l-1.5-2.1l-1.6,2.2 M471.2,283.5
	l1.8-2.5l-2.2-3l-2.3,3l1.9,2.5 M464.7,283.5l-1.6-2.2l-1.6,2.2 M480,283.3l-1.4-1.9l-1.5,1.9 M481.6,283.2l1.6-2.3l-2.2-3l-2.3,3.2
	l1.8,2.3 M476.4,283.3l1.8-2.5l-2.2-3l-2.3,3.2l1.8,2.3 M474.9,283.3l-1.5-2.1l-1.5,2.1 M477.8,296.1l-1.5,2.1l2.2,3l2.3-3l-1.5-2.1
	 M479.8,296.1l1.2,1.8l1.2-1.8 M474.6,296.3l1.2,1.6l1.2-1.6 M472.5,296.3l-1.5,2.1l2.3,3l2.2-3l-1.5-2.1 M475.8,304.8l2.2-3l-2.2-3
	l-2.2,3L475.8,304.8L475.8,304.8z M481.1,304.8l2.3-3.2l-2.3-3l-2.3,3.2L481.1,304.8z M469.6,296.3l1.2,1.5l1.2-1.6 M467.4,296.4
	l-1.5,1.9l2.3,3l2.2-3l-1.5-1.9 M464.4,296.4l1.1,1.5l1.1-1.5 M462.2,296.4l-1.4,1.9l2.2,3l2.2-3l-1.4-1.9 M459.5,296.4l1.1,1.4
	l1.1-1.5 M463.3,301.8l2.3-3l2.2,3l-2.2,3L463.3,301.8z M462.8,301.8l-2.2,3.2l-2.3-3.2l2.3-3L462.8,301.8L462.8,301.8z
	 M470.7,304.8l2.2-3l-2.2-3l-2.3,3L470.7,304.8z M468.1,308.4l2.2-3l-2.2-3l-2.3,3L468.1,308.4L468.1,308.4z M468.5,308.6l2.3,3
	l2.2-3l-2.2-3L468.5,308.6z M462.8,308.8l-2.2,3l-2.3-3l2.3-3L462.8,308.8z M465.4,305.2l-2.2-3l-2.2,3l2.2,3L465.4,305.2z
	 M465.7,305.6l2.3,3l-2.2,3l-2.3-3L465.7,305.6z M461,318l1.6-2.3l-2.2-3l-2.3,3l1.8,2.5 M464.4,317.9l-1.4-1.8l-1.4,1.9
	 M466.3,317.7l1.5-2.1l-2.2-3l-2.2,3l1.6,2.2 M465.4,312.2l-2.3-3l-2.2,3l2.3,3.2L465.4,312.2z M469.4,317.6l-1.1-1.5l-1.2,1.8
	 M471.7,317.5l1.4-1.8l-2.2-3l-2.3,3l1.5,1.9 M470.5,312.2l-2.2-3l-2.3,3l2.3,3L470.5,312.2z M480.8,305.2l-2.3-3.2l-2.2,3l2.3,3
	L480.8,305.2L480.8,305.2z M478.7,308.6l2.3,3l2.2-3l-2.2-3L478.7,308.6L478.7,308.6z M475.6,305.2l-2.2-3l-2.3,3l2.3,3L475.6,305.2
	z M473.6,308.6l2.2,3l2.2-3l-2.3-3L473.6,308.6L473.6,308.6z M474.3,317.3l-1-1.2l-1,1.4 M477.1,317.2l1.1-1.5l-2.2-3l-2.2,3
	l1.2,1.6 M475.6,312.2l-2.2-3l-2.3,3l2.3,3.2L475.6,312.2z M479.1,317l-0.7-1l-0.8,1.1 M482.4,316.9l1-1.2l-2.2-3l-2.3,3l1.1,1.4
	 M480.6,312.2l-2.2-3l-2.2,3l2.2,3L480.6,312.2z M454.4,296.5l1,1.4l1.1-1.4 M457,296.5l-1.4,1.8l2.3,3l2.2-3l-1.4-1.8 M451.8,296.5
	l-1.4,1.8l2.3,3l2.2-3l-1.4-1.8 M449.3,296.5l1,1.4l1-1.4 M450.2,304.8l-2.2-3.2l2.2-3l2.2,3L450.2,304.8z M455.3,304.8l-2.3-3
	l2.3-3l2.2,3L455.3,304.8z M446.7,296.7l-1.2,1.6l2.3,3l2.2-3l-1.2-1.6 M444.2,296.7l1,1.2l1-1.2 M554.8,305.2l-20.4,1.4
	 M536.1,298.9l-0.3,7.4 M534.6,299.3l-0.1,7.3 M533.3,289.2c-0.5,1.1-1.2,2.2-2.2,3.2c-1.7,1.6-3.7,2.6-5.9,2.9H524
	c-1.4,0-2.8-0.3-3.9-0.7l-1.7-1l-1.7-1.4l-0.4-0.4c-0.7-0.7-1.1-1.5-1.5-2.2l-0.7-1.7 M526.6,301.6c0.3,0.3,0.4,0.7,0.4,1.1
	s-0.1,0.8-0.4,1.1l-0.4,0.3l-0.7,0.1c-0.4,0-0.8-0.1-1.1-0.4s-0.4-0.4-0.4-0.7v-0.4c0-0.1,0-0.3,0.1-0.4c0-0.3,0.1-0.4,0.3-0.5
	l0.1-0.1c0.3-0.3,0.5-0.4,1.1-0.4C525.9,301.2,526.3,301.4,526.6,301.6L526.6,301.6z"
          />
          <path
            fill="none"
            d="M513.1,300l0.3-1.5l39.3-1.6c2.2-0.1,3.4,0.7,3.4,2.8v5.5l-1.5,0.1"
          />
          <path
            fill="none"
            d="M554.8,305.2l-0.1-4.4c0.1-1.5-0.5-2.5-2.1-2.8l-16.5,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M551.8,366.7c-1.8,1.6-3.9,2.6-6.2,2.9l-10.9,0.7
	c-3.7,0.4-6.1-0.8-7.2-3.9l-3.2-13.2c-0.4-2.5,0.7-3.9,3.4-4.4h16c3,0,5.6,1,7.8,3c2.2,2.1,3.3,4.5,3.3,7.4S553.8,364.6,551.8,366.7
	L551.8,366.7z M565.8,259.5c1,3.9,2.1,7.4,3.2,10.9 M564.6,305.9l8.1-0.7 M561.9,242.6c5.8,0,11-0.1,15.7-0.4 M572.4,315l-0.8,28.5
	 M572.8,305.1l-0.1,0.1 M572.7,305.2v3.2 M572.7,308.4l-0.1,6.6 M572.7,308.4l0.1-3.3 M564.6,305.9c0.3-12.9-0.3-24.4-1.7-34.1
	c0-2.6-1.8-3.6-5.2-2.8l-8.8,0.7 M532.9,235.5c-0.4,7.7-2.6,14.2-6.6,19.4l-0.3,0.4l-2.8,3.2l-3,2.6 M556.2,237.7
	c-0.4,5.5-2.6,11.8-6.6,18.8c-0.4,1.4-0.5,2.9-0.7,4.7v8.4 M565.8,259.5c-1-3.6-1.8-7.4-2.6-11.4l-1.4-5.4 M572.8,305.1l0.8-23
	c-1.7-3.7-3.2-7.6-4.5-11.8 M569,270.3c2.1-0.3,3-1,2.8-2.2v-6.2c-0.3-1.7-0.8-2.5-1.8-2.5h-4.1 M431.3,293.9h1.7 M415.7,293.9
	l0.1,0.4v1c0,0.1,0,0.3,0.1,0.4l0.3,0.3h10.5l0.3-0.1l0.3-0.3l0.1-0.4v-1.1c0-0.1,0-0.3,0.1-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M427.3,293.9c0,0,0.1-0.1,0.3-0.1h3.9 M427.7,297.1h-0.1 M427.3,293.9h4
	 M408.4,293.8h6.9l0.4,0.1 M408.4,293.8l7.3,0.1 M507.3,345.1c-1.1-1.9-3.3-2.9-6.6-2.9c-27.6,0.7-54.2,1-79.8,0.7
	c-25.6,0.3-52.1,0-79.8-0.7c-3.3-0.1-5.4,0.8-6.6,2.9 M334.7,345.1l-64.1-1.6 M264.5,242.2c4.7,0.3,9.9,0.4,15.7,0.4 M273.1,270.3
	c-1.4,4.3-2.9,8.3-4.5,11.8l0.8,23 M269.5,308.4v111.8c0,5.2,2.8,8,8.4,8.3h23.2c5.5-0.1,8.4-2.9,8.4-8.3v-23.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M349.5,296h150.4l-0.3,1.6l-0.1,0.7l-0.1,0.4l-0.3,1.1l-0.7,3.3l-0.1,0.7
	l-0.8,4.3l-0.3,1.2l-0.4,2.2c-0.3,0.7-0.5,1.4-1,2.1l-0.3,0.4c-0.8,1-1.8,1.5-3,1.9l-1,0.3l-0.4,0.1l-3.3,0.3H486l-3.2,0.3h-0.5
	l-1,0.1h-0.7l-2.6,0.1h-0.5l-1.5,0.1h-0.7l-2.2,0.1h-0.6l-1.9,0.1h-0.7l-1.8,0.1h-0.5l-2.3,0.1h1.1L465,318h-0.5l-2.8,0.1h-2.1
	l-3.2,0.1h-1.8l-3.4,0.1h-1.5l-3.7,0.1h-1.2l-3.9,0.1h-1.1l-4,0.1h-32.3l-4-0.1h-1.2l-3.7-0.1H393l-3.4-0.1h-1.8l-3.2-0.1h-1.9
	l-2.9-0.1h-0.5L378,318h-0.7l-2.5-0.1h-0.5l-1.6-0.1H372l-2.1-0.1h-0.7l-1.9-0.1h-0.7l-1.8-0.1h-0.5l-2.5-0.1h-0.7l-1.2-0.1h-0.5
	l-3-0.1h-2.1l-3.4-0.3h-1.5c-1.4-0.3-2.5-0.7-3.3-1.4l-0.4-0.3c-1-0.8-1.5-1.8-2.1-3v-0.3l-0.1-0.7l-1-4.7l-0.3-1.1l-0.8-4.7
	l-0.1-0.5l-0.5-2.9l-0.1-0.5h8.4L349.5,296L349.5,296z M270.6,343.5l-1-28.5 M269.5,308.4v-3.2 M269.5,305.2l8.1,0.7 M269.4,305.1
	l0.1,3.3 M269.6,315l-0.1-6.6 M399.5,356h43.2c1,0,1.5,0.4,1.5,1.5v21.3c0,1-0.5,1.5-1.5,1.5h-43.2c-1,0-1.5-0.4-1.5-1.5v-21.5
	C398,356.4,398.5,356,399.5,356L399.5,356z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M489.7,352.5l-10.6,0.4H479c-3.4,0.3-5.5,2.6-6.2,6.9l-1.5,8.8
	c-0.1,1.8,0.3,3.3,1.4,4.1c1,1,2.3,1.4,4.3,1.4l18-0.4c4.3-0.4,6.5-1.5,6.3-3.3v-0.3c0-5.1-0.7-9.9-2.1-14.6c0-1.6-1.2-2.6-3.7-3
	C494.4,352.1,492.5,352.1,489.7,352.5L489.7,352.5z M463,384.7l-4.7,0.1 M269.5,305.2l-0.1-0.1 M273.1,270.3c1.1-3.4,2.2-7,3.2-10.9
	 M293.1,269.7l-8.8-0.7c-3.4-0.8-5.1,0.1-5.2,2.8c-1.4,9.8-1.9,21.2-1.6,34.1 M280.2,242.6l-1.4,5.4c-0.8,4-1.6,7.7-2.6,11.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M276.2,259.5h-4.1c-1,0-1.6,0.8-1.8,2.5v6.2c-0.1,1.2,0.8,1.9,2.8,2.2
	 M293.1,269.7v-8.4c0-1.8-0.3-3.3-0.7-4.7c-4-7-6.2-13.3-6.6-18.8 M334,272.1h-9.1 M414.3,286.8l-22.7-0.1l-55-1l-0.5-0.1l-0.4-0.3
	c-0.3-0.3-0.4-0.5-0.4-1L334,273v-1.1 M508.2,272.1c-0.1-0.7-0.4-1.2-1-1.8l-0.4-0.3l-0.4-0.3l-0.8-0.3l-2.2-0.3l-42.5,0.4h-83.5
	l-39.5-0.4l-1.1,0.1l-0.5,0.1c-0.3,0.1-0.7,0.3-0.8,0.4c-0.8,0.5-1.2,1.2-1.4,2.1 M548.9,269.7l-31.6,2.3 M509.8,310l54.8-4.1
	 M571.6,343.5l-64.2,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M427.7,283.9H487l2.6-0.1h13.8l0.1-1.2l0.1-1.2l0.5-4.8l0.1-0.7l0.1-1.6
	v-0.6h-0.1l-1.6-0.3H338.5c-0.5,0-1,0.1-1.2,0.3v0.1l0.4,4.3l0.1,1.4l0.4,4.5h5.8l2.3,0.1h2.6l2.5,0.1h62.9 M463,384.7
	c34-0.7,67.4-1.9,100.1-4 M309.4,397.1h223.3 M379.1,384.7c-34-0.7-67.4-1.9-100.1-4 M309.2,235.5c0.5,10.9,4.8,19.4,12.8,25.4
	 M353.4,148.5l1.9-0.1 M324.9,272.1l1.2,6.9l0.7,3.2l0.4,2.5l0.7,3.4l4.3,22 M529.8,233.8c-1.9,6.7-4.5,11.7-7.8,15.3l-0.3,0.4
	c-0.7,0.6-1.2,1.1-1.9,1.6l-1.4,0.7l-2.9,0.7l-6.5,0.3H329.7l-0.8-0.1c-2.8-0.3-4.8-0.7-6.3-1.5c-4.5-3.3-8-9.1-10.2-17.2
	 M276,229.3l-38.1,1.4 M323,151.5c-15.3,19.8-27.6,48.6-37.1,86.1 M312.4,233.8c-1.5,0.4-2.5,1-3.2,1.7 M288.7,237.1
	c6.9-27.1,15.4-49.8,25.2-67.8 M341.8,233c-1.1-1-2.8-1.4-5.1-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M336.9,231.7l14.3,16.9l-8.7-15l-0.7-0.7 M336.9,231.7l-2.1,0.1l-18.2,1.4
	c-1.6,0.1-3,0.3-4.3,0.6 M309.2,235.5c-7.8,0-14.7,0.6-20.5,1.7 M285.9,237.7l2.9-0.6 M280.2,242.6l3-13.2l0.4-0.8 M283.7,228.7
	l-5,0.4 M278.7,229.1l-2.8,0.1 M335.1,150l-9.4,1 M314.1,169.3c3.7-6.7,7.6-12.8,11.7-18.3 M325.8,151c-1,0.1-1.8,0.3-2.6,0.6
	 M323,151.5c-1.4,0.4-2.5,1-3.3,1.6c-8.1,8.8-15,19.9-20.8,33.6c-5.1,12-10.3,25.9-15.4,42 M342.4,149.3l11-0.8 M335.1,149.3
	c2.1-0.4,4.5-0.7,7.2-0.7 M342.4,149.3v-0.7 M335.1,150l7.3-0.7 M335.1,149.3v0.7 M342.1,147.6l-1.5-2.5 M342.4,148.7V148l-0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M340.6,145.1c-0.5-1-1.1-1.4-1.8-1.2h-1.2c-0.4,0.1-0.7,0.4-1,1l-1.4,3.9
	v0.7 M264.5,242.2c-1.2-0.1-1.5-1.4-0.8-3.7c0.4-1.8,1-2.8,1.6-2.9h8.8c1.6-0.3,2.6-0.8,2.8-1.4l1.7-5.2 M276,229.3
	c0-7.3-3.6-11.3-10.6-12c-5.5-0.4-10.9,0.3-16.4,1.8c-6.9,1.6-10.6,5.4-11.1,11.3v0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237.8,230.6c-0.7,4.4,1,7.4,5,8.9c5.2,1.2,12.5,2.2,21.7,2.6
	 M528.1,169.3c-3.7-6.7-7.6-12.8-11.7-18.3 M519,151.5c15.3,19.8,27.7,48.6,37.1,86.1 M566.2,229.3l38.1,1.4 M528.1,169.3
	c9.9,18,18.3,40.7,25.2,67.8 M577.6,242.2c9.2-0.4,16.5-1.4,21.7-2.6c4-1.5,5.6-4.5,5-8.9 M604.3,230.6v-0.3
	c-0.5-5.9-4.4-9.6-11.1-11.3c-5.5-1.6-10.9-2.2-16.4-1.8c-7,0.8-10.6,4.8-10.6,12 M553.4,237.1c-5.8-1.1-12.7-1.6-20.5-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.6,242.2c1.2-0.1,1.5-1.4,0.8-3.7c-0.4-1.8-1-2.8-1.7-2.9H568
	c-1.8-0.3-2.8-0.8-2.8-1.4l-1.7-5.2 M558.5,228.7l0.3,0.7l3.2,13.2 M556.2,237.7l-2.8-0.6 M566.2,229.3l-2.8-0.1 M563.5,229.1
	l-5-0.4 M532.9,235.5c-0.7-0.7-1.7-1.2-3.2-1.6 M505.3,231.7c-2.3-0.1-4,0.3-5.1,1.2 M505.3,231.7l2.1,0.1l18.2,1.4
	c1.7,0.1,3,0.3,4.3,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.2,233c-0.3,0.3-0.4,0.4-0.7,0.7l-8.7,15l14.3-16.9 M506.9,150
	l-7.1-0.7 M499.8,149.3l-11.1-0.8 M499.8,148.7v0.7 M488.6,148.5l-1.9-0.1 M501.6,145.1l-1.5,2.5 M500,147.6l-0.3,0.4v0.7
	 M499.8,148.7h2.6c1.6,0.1,3.2,0.3,4.5,0.7 M506.9,150v-0.7 M558.5,228.7c-5.1-16-10.3-30-15.4-42c-5.6-13.5-12.7-24.6-20.8-33.6
	c-0.8-0.7-1.9-1.4-3.3-1.7 M519,151.5l-2.6-0.6 M516.4,151l-9.5-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M506.9,149.3v-0.7l-1.4-3.9c-0.3-0.6-0.5-0.8-1-1h-1.2c-0.3,0-0.7,0-1,0.3
	s-0.5,0.6-0.8,1 M341.8,233c21.5-0.7,42.5-1.1,62.9-1.2h32.5c20.4,0.1,41.4,0.6,62.9,1.2 M314.1,169.3c26.8-2.5,58.5-3.9,95.2-4.1
	H433c36.7,0.1,68.5,1.5,95.2,4.1 M355.3,148.4c16.6-1.1,34.8-1.6,54.2-1.6h20.1c20.5,0,39.6,0.6,57.1,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500,147.6c0-0.4-1.2-0.8-3.7-0.8c-22-1.6-43.7-2.6-65.2-2.8h-21.6
	c-20.9,0.3-42.2,1.1-63.8,2.8c-2.5,0.1-3.7,0.4-3.7,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M340.6,145.1c0.8-0.8,2.6-1.4,5.2-1.4c21.6-1.6,42.9-2.6,63.8-2.8h21.6
	c21.3,0.1,43.1,1.1,65.2,2.8c2.6,0.1,4.4,0.6,5.2,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M409.5,141.8c-20.9,0.3-42.2,1.1-63.8,2.8c-0.8,0-1.5,0.1-1.8,0.4
	c-0.3,0.6,0.4,0.7,1.8,0.4c21.6-1.6,42.9-2.6,63.8-2.8h21.6c21.3,0.1,43.1,1.1,65.2,2.8c1.4,0.1,1.9,0,1.8-0.4
	c-0.3-0.3-0.8-0.4-1.8-0.4c-22-1.6-43.7-2.6-65.2-2.8H409.5L409.5,141.8z M486.7,148.4l1.5,2.6c0,0.3-0.1,0.4-0.4,0.6
	c-0.5,0.3-1.4,0.3-2.5,0.3c-17.5-0.8-36.5-1.2-57-1.1h-14.9c-20.4-0.1-39.3,0.3-57,1.1l-2.5-0.3c-0.3-0.1-0.4-0.3-0.4-0.6l1.5-2.6"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M288.7,237.1
		c0,0,10.9-1.7,20.5-1.7c0,0,1.7-2.1,6.7-2.2c0,0,15.8-1.1,20.9-1.5c0,0,3.3-0.3,5.1,1.2c0,0,60.1-1.7,79.1-1.2c0,0,55,0.3,79.1,1.2
		c0,0,1.5-1.5,5.1-1.2l20.2,1.5c0,0,6.1,0.1,7.4,2.2c0,0,12-0.1,20.5,1.7c0,0-6.6-25.3-11.7-38c0,0-5.2-14.3-13.5-29.9
		c0,0-19.3-1.9-50.3-3.2c0,0-29.7-0.8-44.3-1h-12.4h-13.8c0,0-14.9,0.1-40.7,0.8c0,0-28.1,1-52.5,3.2c0,0-7.6,13.6-14.2,31.9
		C299.8,201.2,293.6,218.3,288.7,237.1z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M285.9,237.7c0,0,7.3-1.9,23.2-2.2
		c0,0,1.5-2.1,6.6-2.2l19.9-1.4c0,0,4.1-0.7,6.1,1.1c0,0,42-1.2,65.3-1.2h13.9l13.1,0.1c0,0,20.9,0,66,1.1c0,0,1.4-1.5,5.1-1.2
		l21.3,1.5c0,0,5,0.3,6.3,2.2c0,0,13.8-0.1,23.2,2.2c0,0,0,7.3-6.6,18.8c0,0-0.6,1.5-0.7,4.5v8.5l-31.6,2.3h-9c0,0,0-1.9-2.5-2.6
		l-2.2-0.3c0,0-12.7,0.1-43.3,0.4h-38.9h-43.9c0,0-15-0.1-39.3-0.4c0,0-3.3,0-3.9,2.8h-9.1l-31.8-2.3v-8.7c0,0-0.3-2.8-0.7-4.4
		C292.5,256.5,286.4,246.1,285.9,237.7z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M340.6,312.2l-3.3-17.6l-2.1-10.3
		l-1.2-11.4c0,0-0.7-3.6,3.9-3.7c0,0,31.8,0.4,38.4,0.4h44.8h39.5c0,0,31.2-0.3,42.8-0.4c0,0,5-0.3,4.8,3.4l-1.2,11.7l-2.1,10.3
		l-3.3,17.6c0,0-0.7,3.9-5.4,6.1l-2.8,0.8l-29.2,1.8l-36,1.1l-25.4-0.3l-25.7-0.8l-27.8-1.8C349,319.1,342.8,318.5,340.6,312.2z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M284.5,269
		l40.4,3l7.3,38l-54.8-4.1c0,0-0.7-17.9,1.7-34.1C279.3,271.8,278.8,267.5,284.5,269z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M509.8,310
		l7.4-38l40.4-3c0,0,5.1-1.7,5.2,2.8c0,0,2.2,14,1.7,34.1L509.8,310z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M269.4,305.1l62.9,5
		c0,0,2.5,9.1,10.7,10.5l11.8,0.8l11.4,0.8l24.1,1.1l10,0.3l20.6,0.3h6.6l33.7-1l10.3-0.6c0,0,22-1.4,26.3-1.8
		c0,0,8.9-0.4,11.8-10.6l54.8-4.1l8.3-0.8l-1.2,38.4c0,0-0.3,18.7-4.5,30c0,0-1.7,5-4.1,7.4c0,0-4.1,2.8-15.7,4.3
		c0,0-58.5,3.9-98.8,4l-27.4,0.1h-25c0,0-43.9-0.1-101-4c0,0-11.1-1.2-16-4.4c0,0-7.3-7.3-8.4-37.3L269.4,305.1z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv4DrFront);
