import React, { Component } from 'react';
import { connect } from 'react-redux';

class Wagon4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M137,163.9c-37.4,0.8-64.1,17.1-79.8,48.4c-0.4,0.7-0.7,1.5-0.8,2.3c-0.1,0.6-0.3,1.2-0.3,1.9v2.6
	c0,0.7,0,1.4-0.1,2.1c0,0.4-0.1,0.8-0.1,1.2c-0.4,1.9-0.8,4-1.2,6.2c-0.8,4.4-1.5,9.4-2.2,14.7v0.4c-1,8.4-1.7,17.7-2.3,28.5
	c-0.3,5.4-0.5,11-0.7,16.9v13.3c0.1,6.1,0.4,11.8,0.7,17.3c0.5,10.6,1.4,20.1,2.3,28.5v0.4c0.7,5.4,1.4,10.3,2.2,14.7
	c0.4,2.2,0.8,4.3,1.2,6.2c0.1,0.4,0.1,0.8,0.1,1.2c0.1,0.7,0.1,1.4,0.1,2.1v2.6c0,0.7,0,1.4,0.3,1.9c0.1,0.8,0.5,1.5,0.8,2.3
	c15.7,31.4,42.4,47.5,79.8,48.4c15,0.7,29.7,1.2,44.2,1.5h1.5c8.7,0.1,17.3,0.3,25.9,0.3c11.4,0,22.4-0.1,33.3-0.4
	c3,0,6.1-0.1,9.1-0.3c4.3-0.1,8.4-0.3,12.5-0.5h3.9c2.2-0.1,4.4-0.3,6.5-0.4c1.4,0,2.8-0.1,4.1-0.1l20.6,0.4l11,16.6
	c2.2,3.4,5.6,5.2,10.3,5.2c0.8,0,1.6-0.1,2.2-0.3c1.7-0.5,2.2-1.8,1.8-3.7c-1.6-5.9-3.4-11.6-5.6-17.2c8.4,0.1,16.8,0.3,25.2,0.4
	c19.3,0.3,38.2,0.3,57,0.3h11.8c8.4,0,16.5,0,24.8-0.1h10.7c11.8-0.1,23.7-0.4,35.4-0.5h75.8l16.6,0.8l2.2,0.1h86.7
	c8.5,0,16.4-0.5,23.4-1.5c1.7-0.3,3.2-0.5,4.8-0.8c1.5-0.3,3-0.5,4.5-0.7c2.6-0.4,5.4-0.8,8-1.1c1.9-0.3,3.9-0.5,5.6-0.7l25-3
	l12.2-1.4c18.6-1.6,30.7-13.3,36.2-35.2c0.3-1,0.4-1.9,0.7-2.9c4.5-19.3,7.2-44.8,7.8-76.9V304v-15v-2.2
	c-0.7-32.1-3.3-57.6-7.8-76.9c-0.1-1-0.4-1.9-0.7-2.9c-5.5-21.9-17.6-33.6-36.2-35.2l-12.2-1.4l-25-3c-1.9-0.1-3.7-0.4-5.6-0.7
	c-2.6-0.3-5.4-0.7-8-1.1c-1.5-0.1-3-0.4-4.5-0.7c-1.7-0.3-3.2-0.6-4.8-0.8c-7-1-14.9-1.5-23.4-1.5h-86.7l-2.2,0.1l-16.6,0.8H483
	c-11.7-0.3-23.4-0.4-35.4-0.6h-10.7c-8.1-0.1-16.4-0.1-24.8-0.1h-11.8c-18.7,0-37.7,0.1-57,0.3c-8.4,0.1-16.6,0.3-25.2,0.4
	c2.2-5.6,4-11.4,5.6-17.2c0.4-1.9-0.1-3.2-1.8-3.7c-0.5-0.3-1.4-0.3-2.2-0.3c-4.7,0-8.1,1.8-10.3,5.2l-11,16.6
	c-6.7,0.1-13.6,0.3-20.6,0.4c-1.4,0-2.8-0.1-4.1-0.1c-2.1-0.1-4.3-0.3-6.5-0.4h-3.9c-4.1-0.1-8.4-0.3-12.5-0.6
	c-3-0.1-6.1-0.3-9.1-0.3c-10.7-1-21.7-1.1-33.2-1.1c-8.5,0-17.2,0.1-25.9,0.3h-1.5C166.7,162.7,152,163.2,137,163.9L137,163.9z"
          />
          <path
            fill="#EAEBEB"
            d="M119.3,172.2c-12.2,2.3-23.1,8.3-32.3,18.2c-8.7,9.1-16.2,22.1-22.8,39.2c3.2-1.8,6.9-3.6,11.1-5.1
	c0,0,0-0.1,0.1-0.1c11.7-17.1,34.8-29.6,69.3-37.7c4.8-4.5,2.2-8.1-7.7-11C132.6,174.2,126.7,173.1,119.3,172.2L119.3,172.2z"
          />
          <path
            fill="#5E5E5E"
            d="M66.9,357.6c1.4,1.1,2.8,1.9,4.3,2.8c-0.4-0.8-0.7-1.6-1.1-2.5c-1.4-3.4-2.3-7-3.2-10.7
	c-2.1-8.7-3.7-17.3-4.7-26c-0.1-1.1-0.3-2.3-0.4-3.4c-1-13.6-1.1-28.1,0-43.3c0.1-1.6,0.3-3.3,0.5-5c1-8.1,2.5-16.4,4.5-24.6
	c0.8-3.6,1.8-7.3,3.2-10.7c0.3-0.8,0.7-1.6,1.1-2.5c-1.5,0.8-3,1.8-4.3,2.9c-2.1,1.5-3.9,3.3-5.4,5.4c-3.7,4.7-6.3,10.5-7.4,17.6
	c-1,4.8-1.7,9.8-2.2,14.7c-0.1,0.8-0.1,1.5-0.3,2.3c-1.5,14.4-1.5,28.9,0,43.3c0.1,1.1,0.3,2.2,0.3,3.3c0.5,4.5,1.2,9.1,2.2,13.6
	c1.2,7,3.7,12.9,7.4,17.6C63.1,354.3,64.9,356.1,66.9,357.6L66.9,357.6z"
          />
          <path
            fill="#EAEBEB"
            d="M282.8,193.2c-7.4-0.8-11.8,1.9-13.5,8.3c-4.5,18.7-7.7,39.2-9.2,61.5c-1.5,23.2-1.5,45.1,0,65.5
	c1.5,22.7,4.7,43.5,9.2,62.5c1.6,6.5,6.1,9.2,13.5,8.3l85.7-15.4c-3.3-16.8-5.4-34.7-6.5-54.1c0-1.5-0.1-3.2-0.1-4.7
	c-0.8-20.9-0.8-42.9,0.4-65.9c1.1-18,3.2-34.8,6.2-50.5L282.8,193.2z M399.3,240.4c-2.1,38.2-2.1,75.4,0,111.6c0,3.2,1,5.9,3.2,8.1
	c2.1,2.2,4.5,3.3,7.4,3.3h40.6c2.1,0,4-0.5,5.6-1.8c0.5-0.4,1.2-1,1.8-1.5c2.1-2.2,3-5,3-8.1V240.4c0-3.2-1.1-5.9-3-8.1
	c-0.5-0.6-1.1-1.1-1.8-1.5c-1.6-1.2-3.6-1.8-5.6-1.8h-40.6c-2.9,0-5.4,1.1-7.4,3.3C400.3,234.5,399.3,237.1,399.3,240.4L399.3,240.4
	z M709.1,228.4c3.6,11.6,5.8,26.6,6.5,45c0.3,1.8,0.4,4,0.3,6.9v31.8c0.1,2.8,0,5.1-0.3,6.9c-0.8,18.4-2.9,33.4-6.5,45
	c-1.2,6.3,0.7,10.2,5.9,12c9.2,3.3,17.5,5,24.8,4.8c4.7-0.1,8.8-3.3,12.5-9.6c0.1-0.3,0.4-0.5,0.5-1c1.9-3.4,3.3-7,4.4-10.9
	c0.5-1.9,1-3.9,1.2-5.9c0.1-1.4,0.4-2.6,0.7-4c0.3-1.4,0.5-2.8,0.7-4.1c1.8-10.3,3-21.7,3.7-34.1c0.1-2.1-0.4-3.4-1.7-4.3
	c-0.1,0-0.1-0.1-0.3-0.1c-2.5-1.5-4-4.3-4.5-8.7c-0.1-0.3-0.1-0.7-0.1-1c-0.1-1.1-0.1-2.1,0.1-3.2c0.5-4.3,2.1-7.2,4.5-8.5
	c0.1,0,0.1-0.1,0.3-0.1c1.2-0.8,1.7-2.2,1.7-4.3c-0.7-12.4-1.9-23.8-3.7-34.1c-0.3-1.4-0.5-2.8-0.7-4.1c-0.3-1.4-0.5-2.6-0.7-4
	c-0.3-1.9-0.7-4-1.2-5.9c-1.1-3.7-2.5-7.3-4.4-10.7c-0.1-0.3-0.3-0.7-0.5-1c-3.9-6.3-8-9.5-12.5-9.6c-7.3-0.1-15.5,1.5-24.8,4.8
	C709.8,218.1,707.8,222.1,709.1,228.4L709.1,228.4z"
          />
          <path
            fill="#EAEBEB"
            d="M707.9,280.4l-0.1,27.9c0,1.2,0.1,2.5,0.1,3.9c0,0.3,0,0.7,0.1,1c0.7,6.1,2.3,9.5,4.7,10.3
	c1.4-0.3,2.3-1.8,2.8-4.5c0.3-1.8,0.4-4.1,0.3-6.9v-31.8c0.1-2.8,0-5.1-0.3-6.9c-0.4-2.6-1.4-4.1-2.8-4.4c-2.2,0.8-3.7,3.7-4.5,8.8
	C708.2,278.5,708,279.5,707.9,280.4L707.9,280.4z"
          />
          <path
            fill="#5E5E5E"
            d="M291.1,173.1c-4.4,0.6-8,1.2-11,2.2c10.3,2.9,20.8,5.6,31.2,8.1l-4-6.6c-0.7,0.8-1.5,1.2-2.6,1.1
	c-4.1-1.1-6.1-2.8-6.1-5C296,173,293.6,173,291.1,173.1L291.1,173.1z"
          />
          <path
            fill="#EAEBEB"
            d="M440.5,177.1c-33.7-0.6-66-0.8-97-1l-35.9-0.1l-0.4,0.8l4,6.6c10.7,2.6,21.5,4.8,32.3,6.9
	c16.2,3,32.6,5.5,49.1,7.3c20.8,1.5,41.1,2.5,60.9,2.9L440.5,177.1L440.5,177.1z M565.4,179.7c-59.6-1.2-92.9-1.9-99.7-2.1l6.3,22.8
	c2.8,0,35.5,0.1,98.2,0.1L565.4,179.7L565.4,179.7z M639,182.1c-9.2-0.1-16.1,1.6-20.8,5.5l-6.3,7c-1.4,2.1-0.7,3.3,1.9,3.6
	c9.5,0,19.5,0,30,0.1h5.9c0.3,0,0.7,0,1-0.1c1.5-0.1,2.9-0.3,4.3-0.4l30.5-8c3.8-1.8,3.4-3-1.2-3.9c-0.8-0.1-1.7-0.3-2.5-0.3
	c-12-1.6-25.3-2.9-40.2-3.4c-0.3,0-0.4-0.1-0.7-0.1L639,182.1L639,182.1z M601.7,180.6c-8.5-0.1-16.8-0.3-24.5-0.6
	c-1.1,0-2.1,0-3-0.1c-1.2,0-2.9-0.1-5.2-0.1l5,21h8L601.7,180.6L601.7,180.6z"
          />
          <path
            fill="#5E5E5E"
            d="M591.8,163.1c-8.1-0.1-16.4-0.1-24.6-0.1c-3,0.1-5,1-5.9,2.5c-0.1,0.1-0.1,0.3-0.3,0.6
	c-0.1,0.7,0.3,1,0.8,1.1h4.4c1-0.1,1.7-0.3,2.3-0.8c0.1,0,0.1-0.1,0.3-0.1c0.3-0.1,0.5-0.3,0.8-0.4l0.7-0.1h14.2
	c0.3,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.1c0,0.1,0.1,0.1,0.1,0.3c0,0.1,0.1,0.1,0.1,0.1c0.4,0.4,0.8,0.6,1.4,0.7
	h0.1c2.1,0.3,4.1,0.3,6.2,0c0.7-0.1,1.2-0.4,1.5-1.1c0-0.7-0.1-1.2-0.4-1.8c-0.5-0.8-1.5-1.2-2.9-1.4L591.8,163.1L591.8,163.1z
	 M437,162.6c-8.1-0.1-16.4-0.1-24.8-0.1c-2.9,0.1-5,1-5.9,2.5c-0.1,0.1-0.3,0.3-0.3,0.6c0,0.7,0.3,1.1,1,1.1h4.4
	c0.8-0.1,1.6-0.4,2.3-0.8l0.1-0.1c0.3-0.1,0.5-0.3,0.8-0.4c0.1-0.1,0.4-0.1,0.7-0.1h14.2c0.3,0.1,0.4,0.3,0.5,0.3l0.4,0.4l0.1,0.1
	c0.1,0.1,0.1,0.1,0.1,0.3s0.1,0.1,0.1,0.1c0.4,0.3,0.8,0.6,1.4,0.6h0.1c2.1,0.3,4.1,0.1,6.2-0.1c0.7-0.1,1.1-0.4,1.5-1.1
	c0-0.7-0.1-1.2-0.4-1.8C439.5,163,438.6,162.6,437,162.6L437,162.6z"
          />
          <path
            fill="#EAEBEB"
            d="M756,179.1c-9.1-5.9-22.6-8.4-40.9-7.2c-8.5,1.1-10.6,2.6-6.5,4.4c7,1.1,12.9,2.2,17.7,3.4
	c2.3,0.6,4.5,1.2,6.7,2.1c0.5,0.1,1.2,0.4,1.8,0.7c0.1,0,0.3,0.1,0.4,0.1c7.3,2.9,13.6,7.2,18.8,12.7c0.6,0.6,1,1.1,1.5,1.6
	c2.3,2.3,4.7,6.1,7,11c1,1.9,1.8,4.1,2.5,6.2c0.4,1.2,0.8,2.5,1.2,3.7c2.6,7,6.1,11.8,10.5,14.3c-1.5-12.8-3.4-23-6.1-30.8
	c-1.1-3.2-2.2-5.8-3.4-8.1C764.4,187.5,760.6,182.6,756,179.1L756,179.1z M119.3,420.1c-12.2-2.3-23.1-8.3-32.3-18.2
	c-8.7-9.2-16.2-22.3-22.8-39.2c3.2,1.8,6.9,3.6,11.1,5.1c0,0,0,0.1,0.1,0.1c11.7,17.1,34.8,29.6,69.3,37.7c4.8,4.5,2.2,8.1-7.7,11
	C132.6,418,126.7,419.1,119.3,420.1L119.3,420.1z"
          />
          <path
            fill="#5E5E5E"
            d="M291.1,419.1c-4.4-0.6-8-1.2-11-2.2c10.3-2.9,20.8-5.6,31.2-8.1l-4,6.6c-0.7-0.8-1.5-1.2-2.6-1.1
	c-4.1,1.1-6.1,2.8-6.1,5C296,419.2,293.6,419.2,291.1,419.1L291.1,419.1z"
          />
          <path
            fill="#EAEBEB"
            d="M440.5,415.1c-33.7,0.5-66,0.8-97,1l-35.9,0.1l-0.4-0.8l4-6.6c10.7-2.6,21.5-4.8,32.3-6.9
	c16.2-3,32.6-5.5,49.1-7.3c20.8-1.5,41.1-2.5,60.9-2.9L440.5,415.1L440.5,415.1z M565.4,412.5c-59.6,1.2-92.9,1.9-99.7,2.1l6.3-22.8
	c2.8,0,35.5-0.1,98.2-0.1L565.4,412.5L565.4,412.5z M639,410.2c-9.2,0.1-16.1-1.8-20.8-5.5l-6.3-7c-1.4-2.1-0.7-3.3,1.9-3.6
	c9.5,0,19.5,0,30-0.1h5.9c0.3,0,0.7,0,1,0.1c1.5,0.1,2.9,0.3,4.3,0.4l30.5,8c3.8,1.8,3.4,3-1.2,3.9c-0.8,0.1-1.7,0.3-2.5,0.3
	c-12,1.6-25.3,2.9-40.2,3.4c-0.3,0-0.4,0.1-0.7,0.1L639,410.2L639,410.2z M601.7,411.7c-8.5,0.1-16.8,0.3-24.5,0.5
	c-1.1,0-2.1,0-3,0.1c-1.2,0-2.9,0.1-5.2,0.1l5-21h8L601.7,411.7L601.7,411.7z"
          />
          <path
            fill="#5E5E5E"
            d="M591.8,429.2c-8.1,0.1-16.4,0.1-24.6,0.1c-3-0.1-5-1-5.9-2.5c-0.1-0.1-0.1-0.3-0.3-0.5
	c-0.1-0.7,0.3-1,0.8-1.1h4.4c1,0.1,1.7,0.3,2.3,0.8c0.1,0,0.1,0.1,0.3,0.1l0.8,0.4l0.7,0.1h14.2c0.3-0.1,0.4-0.1,0.5-0.3l0.4-0.4
	l0.1-0.1c0-0.1,0.1-0.1,0.1-0.3c0-0.1,0.1-0.1,0.1-0.1c0.4-0.4,0.8-0.5,1.4-0.7h0.1c2.1-0.3,4.1-0.3,6.2,0c0.7,0.1,1.2,0.4,1.5,1.1
	c0,0.7-0.1,1.2-0.4,1.8c-0.5,0.8-1.5,1.2-2.9,1.4L591.8,429.2L591.8,429.2z M437,429.7c-8.1,0.1-16.4,0.1-24.8,0.1
	c-2.9-0.1-5-1-5.9-2.5c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.7,0.3-1.1,1-1.1h4.4c0.8,0.1,1.6,0.3,2.3,0.8l0.1,0.1l0.8,0.4
	c0.1,0.1,0.4,0.1,0.7,0.1h14.2c0.3-0.1,0.4-0.3,0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.4l0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.3s0.1-0.1,0.1-0.1
	c0.4-0.3,0.8-0.5,1.4-0.5h0.1c2.1-0.3,4.1-0.1,6.2,0.1c0.7,0.1,1.1,0.4,1.5,1.1c0,0.7-0.1,1.2-0.4,1.8
	C439.5,429.3,438.6,429.7,437,429.7L437,429.7z"
          />
          <path
            fill="#EAEBEB"
            d="M756,413.2c-9.1,5.9-22.6,8.4-40.9,7.2c-8.5-1.1-10.6-2.6-6.5-4.4c7-1.1,12.9-2.2,17.7-3.4
	c2.3-0.5,4.5-1.2,6.7-2.1c0.5-0.1,1.2-0.4,1.8-0.7c0.1,0,0.3-0.1,0.4-0.1c7.3-2.9,13.6-7.1,18.8-12.7c0.6-0.5,1-1.1,1.5-1.6
	c2.3-2.3,4.7-6.1,7-11c1-1.9,1.8-4.1,2.5-6.2c0.4-1.2,0.8-2.5,1.2-3.7c2.6-7,6.1-11.8,10.5-14.3c-1.5,12.8-3.4,23-6.1,30.8
	c-1.1,3-2.2,5.8-3.4,8.1C764.4,404.8,760.6,409.6,756,413.2L756,413.2z"
          />
          <g>
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M784.4,385.7
		c0.3-1,0.4-1.9,0.7-2.9c4.5-19.3,7.2-44.8,7.8-76.9v-2.1v-13.5v-1.8v-2.2c-0.7-32.1-3.3-57.6-7.8-76.9c-0.1-1-0.4-1.9-0.7-2.9
		 M784.4,206.6c-5.5-21.9-17.6-33.6-36.2-35.2L736,170 M735.1,182.4c1.1,0.4,2.2,1,3.2,1.5c5,2.6,8.9,6.2,12.4,10.5 M708.7,176.3
		c7,1.1,12.9,2.2,17.7,3.4c2.3,0.6,4.5,1.2,6.7,2.1c0.5,0.1,1.2,0.4,1.8,0.7 M754.4,195.3c-1.8-0.4-3-0.7-3.6-0.8 M754.4,195.3
		c0.5,0.6,1,1.1,1.5,1.6c2.3,2.3,4.7,6.1,7,11c1,1.9,1.8,4.1,2.5,6.2l1.2,3.7c2.6,7,6.1,11.8,10.5,14.3 M770.9,201.2
		c-4.3-2.3-9.1-4.3-14.6-5.6c-0.7-0.1-1.2-0.3-1.8-0.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M770.9,201.2
		c-1.1-3.2-2.2-5.8-3.4-8.1c-3-5.8-6.7-10.5-11.4-14.2 M750.8,194.5c0.5,0.7,1.1,1.4,1.7,2.2c11,16.1,17.5,45.5,19.4,88.3
		c0.1,3.4,0.1,6.9,0.3,10.3v1.6c-0.1,3.4-0.1,6.9-0.3,10.3c-1.8,42.8-8.3,72.2-19.4,88.3c-0.5,0.7-1.1,1.5-1.7,2.2 M735.1,182.4
		c7.4,2.9,13.9,7.3,19.3,12.8 M756,179.1c-5.6-4.5-12.2-7.4-20.1-9.1 M705.3,166.3c-2.6-0.3-5.4-0.7-8-1.1c-1.5-0.1-3-0.4-4.5-0.7
		c-1.7-0.3-3.2-0.6-4.8-0.8c-7-1-14.9-1.5-23.4-1.5h-63.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M708.7,176.3
		c-4.1-1.8-2.1-3.3,6.5-4.4c18.2-1.2,31.8,1.1,40.9,7.2 M735.8,170l-25-3c-1.9-0.1-3.7-0.4-5.6-0.7 M684.2,185.9
		c-0.8-0.1-1.7-0.3-2.5-0.3c-12-1.6-25.3-2.9-40.2-3.4c-0.3,0-0.4-0.1-0.7-0.1h-2.1c-9.2-0.1-16.1,1.8-20.8,5.5l-6.3,7
		c-1.4,2.1-0.7,3.3,1.9,3.6c9.5,0,19.5,0,30,0.1h5.9c0.3,0,0.7,0,1-0.1c1.5-0.1,2.9-0.3,4.3-0.4l30.5-8
		C689.3,188,688.9,186.8,684.2,185.9 M693,205.5c2.6-1.5,5.6-3,9.2-4.3c19.4-7.7,35.6-10,48.6-6.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M592.1,200.8h14.2
		c11.8,0,24.3,0,37.6,0.1h5.9c0.3,0,0.7,0,1-0.1c2.9-0.1,5.8-0.6,8.7-1.2l42.4-10.3c3.7-2.1,2.9-3.6-2.6-4.5
		c-22.4-4.5-51.2-6.7-86.1-7 M693,205.5c-3.4,1.9-6.1,4.3-8,6.7 M715.6,319c-0.8,18.4-2.9,33.4-6.5,45c-1.2,6.3,0.7,10.2,5.9,12
		c9.2,3.3,17.5,5,24.8,4.8c4.7-0.1,8.8-3.3,12.5-9.6c0.1-0.3,0.4-0.5,0.5-1c1.9-3.4,3.3-7,4.4-10.9c0.5-1.9,1-3.9,1.2-5.9
		c0.1-1.4,0.4-2.6,0.7-4c0.3-1.4,0.5-2.8,0.7-4.1c1.8-10.3,3-21.7,3.7-34.1c0.1-2.1-0.4-3.4-1.7-4.3c-0.1,0-0.1-0.1-0.3-0.1
		c-2.5-1.5-4-4.3-4.5-8.7c-0.1-0.4-0.1-0.7-0.1-1c-0.1-1.1-0.1-2.1,0.1-3.2c0.5-4.3,2.1-7.2,4.5-8.5c0.1,0,0.1-0.1,0.3-0.1
		c1.2-0.8,1.7-2.2,1.7-4.3c-0.7-12.4-1.9-23.8-3.7-34.1c-0.3-1.4-0.5-2.8-0.7-4.1c-0.3-1.4-0.5-2.6-0.7-4c-0.3-1.9-0.7-4-1.2-5.9
		c-1.1-3.7-2.5-7.3-4.4-10.7c-0.1-0.3-0.3-0.7-0.5-1c-3.9-6.3-8-9.5-12.5-9.6c-7.3-0.1-15.5,1.5-24.8,4.8c-5.2,1.6-7.2,5.6-5.9,12
		c3.6,11.6,5.8,26.6,6.5,45 M682.2,374.7c-0.8-2.5-1.1-5-0.8-7.7c-0.1-4.5-0.3-10.2-0.5-16.9c-0.1-2.3-0.1-4.7-0.3-7
		c-1.4-34.4-1.2-68.6,0.4-103c0.3-5.8,0.3-10.6,0.4-14.6c-0.3-2.8,0-5.4,0.8-7.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M715.6,319
		c0.3-1.8,0.4-4.1,0.3-6.9v-31.8c0.1-2.8,0-5.1-0.3-6.9 M712.9,323.4c-2.5-0.8-4-4.3-4.7-10.3c-0.1-0.4-0.1-0.7-0.1-1
		c-0.1-1.2-0.1-2.5-0.1-3.9l0.1-27.9c0.1-1,0.3-1.9,0.3-2.6c0.8-5.1,2.3-8,4.5-8.8 M715.6,273.4c-0.4-2.6-1.4-4.1-2.8-4.5
		 M777,360.1c1.7-13.9,2.5-31,2.6-50.8v-0.1c0-0.8-0.4-1.8-1.2-2.9c-0.5-0.5-1.1-1.4-1.5-2.9s-0.7-3.2-0.8-5.1v-4.5
		c0.1-1.9,0.4-3.6,0.8-5.1c0.4-1.4,1-2.3,1.5-2.9c0.8-1,1.2-1.9,1.2-2.9v-0.1c-0.1-19.8-1.1-36.7-2.6-50.8 M770.9,201.2
		c2.6,7.8,4.7,18.2,6.1,30.8 M712.9,268.9c-1.5-32.3-8.1-53.5-19.8-63.4 M685.1,212.4c-1.2,1.7-2.2,3.4-2.8,5.4 M607.1,164.5
		c-1.7-0.8-3.6-1.5-5.9-2.2 M613.4,169.3c-1.1-1.8-3.3-3.3-6.3-4.8 M604.7,177.5l-3,3 M613,177.7c-2.8-0.1-5.5-0.1-8.1-0.1
		 M613,177.7c1.8-3.3,1.9-6.1,0.4-8.4 M569.5,165.4c0.3,0,0.4-0.1,0.7-0.1h14.2c0.3,0.1,0.4,0.1,0.6,0.3l0.4,0.4l0.1,0.1
		c0,0.1,0.1,0.1,0.1,0.3s0.1,0.1,0.1,0.1c0.4,0.4,0.8,0.6,1.4,0.7h0.1c2.1,0.3,4.1,0.3,6.2,0c0.7-0.1,1.2-0.4,1.5-1.1
		c0-0.7-0.1-1.2-0.4-1.8c-0.5-0.8-1.5-1.2-2.9-1.4h-0.1c-8.1-0.1-16.4-0.1-24.6-0.1c-3,0.1-5,1-5.9,2.5c-0.1,0.1-0.1,0.3-0.3,0.6
		c-0.1,0.7,0.3,1,0.8,1.1h4.4c1-0.1,1.7-0.3,2.3-0.8c0.1,0,0.1-0.1,0.3-0.1C569,165.7,569.4,165.6,569.5,165.4 M449.8,167.8
		c47-0.4,88.5-0.3,124.4,0.4h3c12.8,0.3,24.9,0.7,36.2,1.1 M601.2,162.3h-23.4l-3.7,0.1 M601.7,180.6c-8.5-0.1-16.8-0.3-24.5-0.6
		c-1.1,0-2.1,0-3-0.1c-1.2,0-2.9-0.1-5.2-0.1 M604.7,177.5c-9.8-0.1-19-0.4-27.7-0.6c-1.1,0-2.1-0.1-3-0.1c-1.5,0-2.9-0.1-4.4-0.1
		h-1.5 M568.1,176.7l0.7,3 M565.4,179.7l-0.7-3.2 M568.1,176.7h-3.4 M570.2,200.8h3.6 M573.8,200.8h8 M581.9,200.8h10.2
		 M568.8,179.7l5,21 M570.2,200.8l-4.8-21"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M577.1,162.4l-18.3,0.8
		H483c-11.7-0.3-23.4-0.4-35.4-0.6 M592.1,200.8l20.9-23.1 M601.7,180.6l-19.8,20.2 M573.8,391.5h8 M570.2,391.5h3.6 M581.9,391.5
		h10.2 M561.7,425.3h4.4c1,0.1,1.7,0.3,2.3,0.8c0.1,0,0.1,0.1,0.3,0.1c0.3,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.4,0.1,0.7,0.1h14.2
		c0.3-0.1,0.4-0.1,0.5-0.3l0.4-0.4l0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.4-0.4,0.8-0.5,1.4-0.5h0.1
		c2.1-0.3,4.1-0.3,6.2,0c0.7,0.1,1.2,0.5,1.5,1.1c0,0.7-0.1,1.2-0.4,1.8c-0.5,0.8-1.5,1.2-2.9,1.4h-0.1c-8.1,0.1-16.4,0.1-24.6,0.1
		c-3-0.1-5-1-5.9-2.5c-0.1-0.1-0.1-0.3-0.3-0.5C560.8,425.7,561.1,425.4,561.7,425.3 M601.7,411.7c-8.5,0.1-16.8,0.4-24.5,0.5h-3
		c-1.2,0-2.9,0.1-5.2,0.3 M568.1,415.5h-3.4 M568.1,415.5h1.5c1.4,0,2.9-0.1,4.4-0.1c1,0,1.9,0,3-0.1c8.7-0.1,17.9-0.4,27.7-0.5
		 M565.4,412.5l-0.7,3.2 M568.8,412.5l-0.7,3 M449.8,424.5c47,0.4,88.5,0.3,124.4-0.4h3c12.8-0.3,24.9-0.5,36.2-1.1 M601.2,430
		h-23.4l-3.7-0.1 M570.2,391.5l-4.8,21 M568.8,412.5l5-21"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M577.1,429.8l-18.3-0.8
		H483c-11.7,0.3-23.4,0.4-35.4,0.5 M592.1,391.5h14.2c11.8,0,24.3,0,37.6-0.1h5.9c0.3,0,0.7,0,1,0.1c2.9,0.3,5.8,0.7,8.7,1.2
		l42.4,10.3c3.7,2.1,2.9,3.6-2.6,4.5c-22.4,4.5-51.2,6.9-86.1,7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M655.1,394.5l30.5,8
		c3.8,1.8,3.4,3-1.2,3.9c-0.8,0.1-1.7,0.3-2.5,0.3c-12,1.6-25.3,2.8-40.2,3.4H641l-2.1,0.1c-9.2,0.1-16.1-1.6-20.8-5.5l-6.3-7
		c-1.4-2.2-0.7-3.3,1.9-3.6h36.9C652.2,394.2,653.6,394.3,655.1,394.5 M604.7,414.8l-3-3.2 M607.1,427.8c-1.7,0.8-3.6,1.5-5.9,2.2
		 M613,414.6c-2.8,0-5.5,0.1-8.1,0.1 M613.4,423c-1.1,1.8-3.3,3.3-6.3,4.8 M613,414.6c1.8,3.2,1.9,6.1,0.4,8.4 M601.7,411.7
		l-19.8-20.2 M592.1,391.5l20.9,23.1 M715.6,319c-0.4,2.6-1.4,4.1-2.8,4.5 M750.8,397.8c-12.9,3.2-29.2,1-48.6-6.7
		c-3.6-1.2-6.6-2.6-9.2-4.3 M682.2,374.7c0.5,1.9,1.5,3.6,2.8,5.2 M705.3,426c-2.6,0.3-5.4,0.7-8,1.1c-1.5,0.1-3,0.4-4.5,0.8
		c-1.7,0.3-3.2,0.5-4.8,0.7c-7,1-14.9,1.5-23.4,1.5h-63.3 M708.7,415.9c-4.1,1.8-2.1,3.3,6.5,4.4c18.2,1.2,31.8-1.2,40.9-7.2
		 M735.8,422.3l-25,3c-1.9,0.3-3.7,0.4-5.6,0.7 M693,386.8c-3.4-1.9-6.1-4.3-8-6.7 M777,360.1c-4.4,2.6-7.8,7.3-10.5,14.4
		c-0.4,1.2-0.8,2.5-1.2,3.7c-0.7,2.1-1.5,4.3-2.5,6.2c-2.3,5-4.7,8.7-7,11c-0.5,0.5-1,1.1-1.5,1.6 M754.4,397
		c0.5-0.1,1.2-0.3,1.8-0.4c5.5-1.4,10.5-3.3,14.6-5.6 M754.4,397c-1.8,0.4-3,0.7-3.6,0.8 M770.9,391c-1.1,3.2-2.2,5.8-3.4,8.1
		c-3,5.8-6.7,10.5-11.4,14.2 M735.1,409.9c1.1-0.4,2.2-1,3.2-1.5c5-2.8,8.9-6.2,12.4-10.5"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M784.4,385.7
		c-5.5,21.9-17.6,33.6-36.2,35.2l-12.2,1.4 M708.7,415.9c7-1,12.9-2.2,17.7-3.4c2.3-0.5,4.5-1.2,6.7-1.9c0.5-0.3,1.2-0.5,1.8-0.7
		 M735.1,409.9c7.4-2.9,13.9-7.3,19.3-12.8 M756,413.3c-5.6,4.5-12.2,7.4-20.1,9.1 M770.9,391c2.6-7.8,4.7-18,6.1-30.8 M712.9,323.4
		c-1.5,32.3-8.1,53.5-19.8,63.4 M613.4,423c41.8-1.4,73.7-3.7,95.3-7 M705.3,426c-32.1,1.8-64.8,2.5-98.2,1.8 M613.4,169.3
		c41.8,1.4,73.7,3.7,95.3,7 M705.3,166.3c-32.1-1.8-64.8-2.5-98.2-1.8 M784.4,385.7c-2.2,2.3-6.7,4-13.5,5.2 M784.4,206.6
		c-2.2-2.2-6.7-4-13.5-5.2 M452.3,174.4l-2.5-6.6 M447.8,162.6h-10.6 M449.8,167.8l-2.1-5.2 M464.8,174.7c-4.3-0.1-8.4-0.1-12.5-0.3
		 M412.4,162.4c-2.9,0.1-5,1-5.9,2.5c-0.1,0.1-0.3,0.3-0.3,0.6c0,0.7,0.3,1.1,1,1.1h4.4c0.8-0.1,1.6-0.4,2.3-0.8l0.1-0.1
		c0.3-0.1,0.5-0.3,0.8-0.4c0.1-0.1,0.4-0.1,0.7-0.1h14.2c0.3,0.1,0.4,0.3,0.5,0.3l0.4,0.4l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.3
		s0.1,0.1,0.1,0.1c0.4,0.3,0.8,0.6,1.4,0.6h0.1c2.1,0.3,4.1,0.1,6.2-0.1c0.7-0.1,1.1-0.4,1.5-1.1c0-0.7-0.1-1.2-0.4-1.8
		c-0.5-0.8-1.5-1.2-2.9-1.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M437.2,162.6
		C437.2,162.6,437,162.6,437.2,162.6c-8.3-0.1-16.5-0.1-24.9-0.1 M453.5,200.5c3.2,0,6.3,0.1,9.4,0.1 M465.7,177.8l-0.8-3.2
		 M452.3,174.4l10.6,26.3 M438.8,174.1c4.5,0.1,8.9,0.1,13.5,0.3 M453.5,200.5l-13.1-23.4 M440.5,177.1l-1.6-3 M457.8,232.2
		c2.1,2.2,3,5,3,8.1V352c0,3.2-1.1,5.9-3,8.1c-0.5,0.5-1.1,1.1-1.8,1.5c-1.6,1.2-3.6,1.8-5.6,1.8h-40.6c-2.9,0-5.4-1.1-7.4-3.3
		s-3.2-5-3.2-8.1c-2.1-36.2-2.1-73.5,0-111.6c0-3.2,1-5.9,3.2-8.1c2.1-2.2,4.5-3.3,7.4-3.3h40.6c2.1,0,4,0.6,5.6,1.8
		C456.7,231.1,457.3,231.6,457.8,232.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M463.5,237.7v116.9
		c0,1.4-0.1,2.8-0.7,4c-0.5,1.5-1.5,2.9-2.8,4.1c-2.2,2.2-5,3.3-8.1,3.3h-44c-3.2,0-5.9-1.1-8.1-3.3c-2.2-2.2-3.3-5-3.3-8.1
		c-1.5-38.9-1.5-78,0-116.9c0-3.2,1.1-5.9,3.3-8.1c2.2-2.2,5-3.3,8.1-3.3h44c3.2,0,5.9,1.1,8.1,3.3c1.2,1.2,2.2,2.6,2.8,4.1
		C463.2,234.9,463.5,236.3,463.5,237.7 M462.9,200.7h9.1 M465.7,177.8l6.3,22.8 M412.4,162.4h-11.8c-18.7,0-37.7,0.1-57,0.3
		c-8.4,0.1-16.6,0.3-25.2,0.4 M682.2,217.6H483.1c-36.2,0-72.2-2.6-108.3-8l-6.3-1.1 M322.3,142.1c-0.5-0.3-1.4-0.3-2.2-0.3
		c-4.7,0-8.1,1.8-10.3,5.2l-11,16.6 M318.3,163.1c2.2-5.6,4-11.4,5.6-17.2c0.4-1.9-0.1-3.2-1.8-3.7 M298.5,173h-7.4
		c-4.4,0.6-8,1.2-11,2.2 M298,167.8c0.1,1.1,0.5,2.1,1.2,2.8l0.1,0.1 M299.3,170.7c-0.5,0.8-0.8,1.8-0.8,2.5 M305.9,172.7
		c-1.6-0.1-3.2-0.6-4.7-1.1c-0.8-0.3-1.5-0.7-1.9-1.1 M298.6,163.5c-0.5,1.6-0.8,3.2-0.7,4.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M305.9,172.7
		c3-0.8,5-2.3,5.8-5l10.6-25.7 M309.4,172.9c-0.8,0-1.6,0-2.5-0.1 M309.2,173c-0.5,0-1.4-0.1-2.5-0.1 M307.6,176l1.6-3 M306.9,172.9
		c-0.3,0-0.5-0.1-1-0.1 M309.4,172.9c3.4-0.3,5.5-1.2,6.2-2.9c0.3-0.7,0.5-1.5,1-2.2 M309.2,173l0.1-0.1 M311.2,183.5l-4-6.6
		 M307.2,176.9l0.4-0.8 M298.5,173c-0.1,2.2,1.9,3.9,6.1,5c1.1,0.1,1.9-0.3,2.6-1.1 M307.6,176c13.2,0,25.2,0.1,35.9,0.1
		c31,0.1,63.3,0.4,97,1 M311.2,183.5c10.7,2.6,21.5,4.8,32.3,6.9c16.2,3,32.6,5.5,49.1,7.3c20.8,1.5,41.1,2.5,60.9,2.9 M438.8,174.1
		c-32.6-0.6-64.4-1-95.3-1.1c-11.6-0.1-23-0.1-34.3,0 M316.5,167.8c0.5-1.5,1.2-3.2,1.9-4.7 M368.5,208.6c-3,15.7-5.2,32.6-6.2,50.5
		c-1.2,23-1.2,44.8-0.4,65.9c0.1,1.5,0.1,3.2,0.1,4.7c1.1,19.3,3.2,37.3,6.5,54.1 M307.2,415.4l0.4,0.8 M306.9,419.4
		c-0.3,0-0.5,0.1-1,0.1 M298.5,419.2c-0.1-2.2,1.9-3.9,6.1-5c1.1-0.1,1.9,0.3,2.6,1.1 M309.2,419.2l0.1,0.1 M309.4,419.4
		c3.4,0.3,5.5,1.2,6.2,2.9c0.3,0.7,0.5,1.5,1,2.2 M307.6,416.2l1.6,3 M307.2,415.4l4-6.6 M309.2,419.2c-0.5,0-1.4,0.1-2.5,0.1
		 M309.4,419.4c-0.8,0-1.6,0-2.5,0.1 M299.3,421.7c-0.1,0-0.1,0-0.1,0.1c-0.7,0.7-1.1,1.6-1.2,2.8 M305.9,419.5
		c-1.6,0.1-3.2,0.6-4.7,1.1c-0.8,0.3-1.5,0.7-1.9,1.1 M298.5,419.2h-7.4c-4.4-0.5-8-1.2-11-2.3 M299.3,421.7c-0.5-1-0.8-1.8-0.8-2.5
		 M322.3,450.2c-0.5,0.1-1.4,0.3-2.2,0.3c-4.7,0-8.1-1.8-10.3-5.2l-11-16.6 M298.6,428.7c-0.5-1.6-0.8-3.2-0.7-4.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M322.3,450.2l-10.6-25.7
		c-0.8-2.6-2.8-4.3-5.8-5 M412.4,430h-11.8c-18.7,0-37.7-0.1-57-0.3c-8.4-0.1-16.6-0.3-25.2-0.4 M311.2,408.8
		c10.7-2.5,21.5-4.8,32.3-6.7c16.2-3,32.6-5.5,49.1-7.3c20.8-1.5,41.1-2.3,60.9-2.8 M438.8,418.1c-32.6,0.5-64.4,1-95.3,1.1h-34.3
		 M440.5,415.1c-33.7,0.5-66,0.8-97,1c-10.7,0-22.7,0.1-35.9,0.1 M316.5,424.5c0.5,1.6,1.2,3.2,1.9,4.7 M682.2,374.7H483.1
		c-36.2,0-72.2,2.6-108.3,8l-6.3,1.1 M412.4,430c-2.9-0.1-5-1-5.9-2.5c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.7,0.3-1.1,1-1.1h4.4
		c0.8,0.1,1.6,0.3,2.3,0.8l0.1,0.1c0.3,0.1,0.5,0.4,0.8,0.4c0.1,0,0.4,0.1,0.7,0.1h14.2c0.3-0.1,0.4-0.3,0.5-0.3
		c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0,0.1-0.1,0.1-0.1l0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.4-0.3,0.8-0.5,1.4-0.5h0.1
		c2.1-0.1,4.1-0.1,6.2,0.1c0.7,0.1,1.1,0.5,1.5,1.1c0,0.7-0.1,1.2-0.4,1.8c-0.5,0.8-1.5,1.2-2.9,1.4 M453.5,391.9
		c3.2-0.1,6.3-0.1,9.4-0.1 M440.5,415.1l-1.6,3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M437.2,429.7
		C437.2,429.7,437,429.7,437.2,429.7c-8.3,0.1-16.5,0.1-24.9,0.1 M465.7,414.4l-0.8,3.3 M447.8,429.7h-10.6 M449.8,424.5l-2.1,5.2
		 M464.8,417.7c-4.3,0.1-8.4,0.1-12.5,0.3 M452.3,417.9l-2.5,6.6 M452.3,417.9l10.6-26.1 M453.5,391.9l-13.1,23.2 M438.8,418.1
		c4.5-0.1,8.9-0.1,13.5-0.3 M462.9,391.7c3,0,6.1,0,9.1-0.1 M465.7,414.4l6.3-22.8"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M685.1,379.9H483.1
		c-36.2,0-72.2,2.8-108.3,8l-101.7,18.3c-1.1,0.1-2.5,0.3-4,0.4 M316.5,424.5h133.3 M316.5,167.8h133.3 M685.1,212.4H483.1
		c-36.2,0-72.2-2.6-108.3-8l-101.7-18.3c-1.1-0.1-2.5-0.3-4-0.4 M273.9,163.8c-2.1-0.1-4.3-0.3-6.5-0.4h-3.9 M280.1,175.3l-4-7.6
		 M298.6,163.5c-6.7,0.1-13.6,0.3-20.6,0.4c-1.4,0-2.8-0.1-4.1-0.1 M273.9,163.8l2.2,4 M276.1,167.8c-2.9,0.3-5.9,0.4-8.7,0.6
		c-6.6,0.4-12,0.7-16.4,1c-3.2,0.1-6.3,0.4-9.1,0.4c-16.2,0.7-27.2,0.6-33.3-0.6c-4-0.6-6.3-1.2-6.7-2.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M263.7,163.2
		c-4.1-0.1-8.4-0.3-12.5-0.6c-3-0.1-6.1-0.3-9.1-0.3c-11-0.1-22-0.3-33.4-0.3c-8.5,0-17.2,0.1-25.9,0.3h-1.5
		c-14.4,0.3-29.2,0.8-44.2,1.5C99.7,164.8,73,181,57.4,212.4c-0.4,0.7-0.7,1.5-0.8,2.3c-0.1,0.6-0.3,1.2-0.3,1.9v2.6
		c0,0.7,0,1.4-0.1,2.1c0,0.4-0.1,0.8-0.1,1.2c-0.4,1.9-0.8,4-1.2,6.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M201.9,167
		c1.8-0.1,4-0.3,6.7-0.4c10.5-0.8,21.5-1.5,33.3-2.2c3-0.1,6.1-0.3,9.1-0.6c4.1-0.1,8.3-0.4,12.5-0.6 M260.5,184.7
		c-3-0.3-6.2-0.6-9.5-1c-3-0.3-6.1-0.4-9.1-0.6c-10.5-0.6-21.5-0.8-33.3-0.7c-8.4,0.1-17.1,0.4-25.9,0.8c-0.6,0-1.1,0.1-1.5,0.1
		c-11.8,0.7-23.9,1.8-36.5,3 M260.5,184.7c-3.3,0.4-6.5,1.6-9.5,3.6c-2.1,1.2-4,3-5.8,5c-3.6,4-6.7,9.5-9.6,16.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M260.5,407.6
		c-3.4-1.7-6.6-4.3-9.5-7.8c-0.1-0.3-0.4-0.5-0.6-0.8c-9.2-12.1-15.5-34.7-19.1-67.8c-0.1-0.8-0.1-1.8-0.3-2.6
		c-2.2-24.4-1.9-48.1,0.8-71.5c3.6-30.8,9.8-52,18.6-63.6c0.1-0.3,0.4-0.6,0.6-0.7c2.9-3.6,6.1-6.2,9.5-7.8"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M269.2,185.7
		c-1.2-0.1-2.6-0.3-4.1-0.3c-1.5-0.1-3-0.4-4.5-0.6 M368.5,208.6l-85.7-15.4c-7.4-0.8-11.8,1.9-13.5,8.3
		c-4.5,18.7-7.7,39.2-9.2,61.5c-1.5,23.2-1.5,45.1,0,65.5c1.5,22.7,4.7,43.5,9.2,62.5c1.6,6.5,6.1,9.2,13.5,8.3l85.7-15.4
		 M269.2,185.7c1.2-4.8,4.8-8.3,10.9-10.3 M119.3,172.2c6.7-4,27.4-5.9,62-5.5h1.5c6.1,0.1,12.4,0.1,19.3,0.4 M235.6,209.6
		c-9.8,0.4-18.7,0.8-27,1.5c-9.2,0.7-18.4,1.5-27.5,2.8 M181.3,168.9c6.5-0.7,13.3-1.2,20.8-1.8 M235.6,209.6
		c-4.8,11.7-8.4,27.4-10.9,47.3c-2.8,21.9-3,45.8-0.8,71.5c0.1,1,0.1,1.8,0.3,2.6c2.5,21.9,6.3,39.1,11.4,51.6 M112.9,171.6
		c2.1,0,4.1,0.1,6.3,0.6 M137,175.6c-4.5-1.2-10.5-2.5-17.9-3.4 M144.7,186.6c4.8-4.5,2.2-8.1-7.7-11 M74.7,192.5
		c1.9,0.1,2.5,1.4,1.9,3.6l-5.4,8c-1.1,1-2.6,1.1-4.8,0.4 M66.4,204.5c2.6-4.4,5.4-8.4,8.3-12 M71.3,231.9c0.1-0.1,0.1-0.4,0.1-0.6
		c0.4-1,1-1.9,1.4-2.9c0.8-1.4,1.7-2.8,2.5-4.1"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M75.2,224.3
		C75.2,224.3,75.4,224.3,75.2,224.3c11.8-17.2,34.9-29.7,69.5-37.8 M67,245c0.8-3.6,1.8-7.3,3.2-10.7c0.3-0.8,0.7-1.6,1.1-2.5
		 M64.1,229.5c-1.4,0.8-2.6,1.6-3.6,2.5 M52.4,243.4v0.4c-1,8.4-1.8,17.7-2.3,28.3c-0.3,5.5-0.5,11.3-0.7,17.3c0,0.7,0,1.4-0.1,1.9
		v1.9 M61.5,240c-0.5,1.7-0.8,3.3-1.2,5c-2.1,8.3-3.6,16.5-4.5,24.6c-0.1,1.6-0.3,3.3-0.5,5c-1,15.1-1,29.6,0,43.3
		c0.1,1.1,0.1,2.3,0.3,3.4c1,8.7,2.6,17.3,4.7,26c0.3,1.8,0.7,3.3,1.2,5"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M66.9,234.6
		c-1.2,3.3-2.3,6.7-3,10.3c-2.1,8.3-3.6,16.5-4.5,24.6c-0.1,1.6-0.3,3.3-0.5,5c-1,15.1-1,29.6,0,43.3c0.1,1.1,0.1,2.3,0.3,3.4
		c1.1,8.7,2.6,17.3,4.7,26c0.7,3.6,1.7,7,3,10.3 M66.9,234.6c-2.1,1.5-3.9,3.3-5.4,5.4 M52.4,243.4c0.5-4.3,3.3-8.1,8.1-11.6
		 M54.6,228.7c-0.8,4.4-1.5,9.4-2.2,14.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M61.5,240
		c-3.7,4.7-6.3,10.5-7.4,17.6c-1,4.8-1.7,9.8-2.2,14.7c-0.1,0.8-0.1,1.5-0.3,2.3c-1.5,14.4-1.5,28.9,0,43.3c0.1,1.1,0.3,2.2,0.3,3.3
		c0.5,4.5,1.2,9.1,2.2,13.6c1.2,7,3.7,12.9,7.4,17.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M67,347.3
		c-2.1-8.7-3.7-17.3-4.7-26c-0.1-1.1-0.3-2.3-0.4-3.4c-1-13.6-1.1-28.1,0-43.3c0.1-1.6,0.3-3.3,0.5-5c1-8.1,2.5-16.4,4.5-24.6
		 M71.3,231.9c-1.5,0.8-3,1.8-4.3,2.9 M64.1,229.5c3.2-1.8,6.9-3.6,11.1-5.1 M60.5,231.9c7.7-20.6,16.5-35.6,26-45.1
		c7.7-7.6,16.6-12.7,26.4-15.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M119.3,172.2
		c-12.2,2.3-23.1,8.3-32.3,18.2c-8.7,9.1-16.2,22.1-22.8,39.2 M66.4,204.5c-4.3,7-8.1,15-11.8,24.2 M112.9,171.6
		c-14.2-0.1-27,6.7-38.2,20.9 M49.2,293.5v2.3 M49.2,295.9v0.3 M49.2,295.9v0.4 M49.2,296.1v2.5 M49.2,296.4v-0.3 M49.2,296.1v-0.7
		v-1.9 M49.2,298.7v-0.7v-1.6 M52.4,348.8v-0.3v-0.1c-1-8-1.7-17.1-2.2-27.2c-0.3-5.9-0.5-12-0.7-18.6c0-0.7,0-1.2-0.1-1.9v-1.9
		 M66.9,357.6c-2.1-1.5-3.9-3.3-5.4-5.4 M64.1,362.7c-1.4-0.8-2.6-1.7-3.6-2.5 M54.6,363.5c-0.8-4.4-1.5-9.4-2.2-14.7 M52.4,348.8
		c0.5,4.3,3.3,8.1,8.1,11.6 M67,347.3c0.8,3.7,1.8,7.3,3.2,10.7c0.3,0.8,0.7,1.6,1.1,2.5 M71.3,360.4c0.1,0.1,0.1,0.4,0.1,0.5
		c0.4,1,1,1.9,1.4,2.9c0.8,1.4,1.7,2.8,2.5,4.1 M71.3,360.4c-1.5-0.8-3-1.8-4.3-2.8"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M75.2,367.9
		C75.2,367.9,75.4,367.9,75.2,367.9c11.8,17.2,34.9,29.7,69.5,37.7 M74.7,399.7c1.9-0.3,2.5-1.4,1.9-3.6l-5.4-8.1
		c-1.1-1-2.6-1.1-4.8-0.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M263.7,429
		c-4.1,0.1-8.4,0.4-12.5,0.5c-3,0.1-6.1,0.3-9.1,0.3c-10.9,0.3-21.9,0.5-33.3,0.4c-8.5,0-17.2-0.1-25.9-0.3h-1.5
		c-14.4-0.3-29.2-0.8-44.2-1.5c-37.4-0.8-64.1-16.9-79.8-48.3c-0.4-0.8-0.7-1.5-0.8-2.3c-0.1-0.7-0.3-1.2-0.3-1.9v-2.6
		c0-0.7,0-1.4-0.1-2.1c0-0.4-0.1-0.8-0.1-1.2c-0.4-1.9-0.8-4-1.2-6.2 M66.4,387.7c2.6,4.4,5.4,8.4,8.3,12 M112.9,420.6
		c2.1,0,4.1-0.1,6.3-0.4 M144.7,405.6c4.8,4.5,2.2,8.1-7.7,11 M137,416.8c-4.5,1.2-10.5,2.5-17.9,3.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M60.5,360.4
		c7.7,20.6,16.5,35.6,26,45c7.7,7.7,16.6,12.7,26.4,15.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M119.3,420.2
		c-12.2-2.3-23.1-8.4-32.3-18.2c-8.7-9.2-16.2-22.3-22.8-39.2 M112.9,420.6c-14.2,0.1-27-6.7-38.2-20.9 M64.1,362.7
		c3.2,1.8,6.9,3.6,11.1,5.2 M66.4,387.7c-4.3-7-8.1-15-11.8-24.2 M144.7,405.6c12.5,1.4,24.8,2.3,36.5,3c0.6,0,1,0.1,1.5,0.1
		c8.8,0.4,17.5,0.8,25.9,0.8c11.7,0.1,22.8-0.1,33.3-0.7c3.2-0.1,6.2-0.4,9.1-0.7c3.2-0.3,6.3-0.5,9.5-1 M119.3,420.2
		c6.7,4,27.4,5.9,62,5.5h1.5c6.1,0,12.4-0.1,19.3-0.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M201.9,425.3
		c1.8,0.1,4,0.3,6.7,0.4c10.5,0.8,21.5,1.5,33.3,2.2c3,0.1,6.1,0.4,9.1,0.5c4.1,0.3,8.3,0.4,12.5,0.5 M276.1,424.5
		c-2.9-0.3-5.9-0.4-8.7-0.5c-6.6-0.4-12-0.7-16.4-1c-3.2-0.1-6.3-0.3-9.1-0.4c-16.2-0.7-27.2-0.5-33.3,0.5c-4,0.5-6.3,1.2-6.7,2.2
		 M235.6,382.7c-9.8-0.4-18.7-0.8-27-1.5c-9.2-0.7-18.4-1.5-27.5-2.8 M201.9,425.3c-7.4-0.5-14.3-1.2-20.8-1.8 M260.5,407.6
		c-3.3-0.4-6.5-1.7-9.5-3.6c-2.1-1.2-4-3-5.8-5c-3.6-4-6.7-9.4-9.6-16.2 M260.5,407.6c1.5-0.1,3-0.3,4.5-0.5
		c1.5-0.1,2.9-0.3,4.1-0.4 M273.9,428.6c-2.1,0.1-4.3,0.3-6.5,0.3h-3.9 M298.6,428.7c-6.7-0.1-13.6-0.3-20.6-0.4
		c-1.4,0.1-2.8,0.1-4.1,0.3 M276.1,424.5l-2.2,4.1 M269.2,406.6c1.2,4.8,4.8,8.3,10.9,10.3 M280.1,416.9l-4,7.6 M182.8,378.4
		c-39.9-5.2-78.6-15.5-115.8-31.1 M182.8,423.4c-19.7-1.9-34.9-4.1-45.7-6.7 M182.8,213.9c-39.9,5.2-78.6,15.7-115.8,31.1
		 M137,175.6c10.9-2.5,26-4.8,45.7-6.7 M318.3,429.2c2.2,5.6,4,11.4,5.6,17.2c0.4,1.9-0.1,3.2-1.8,3.9 M280.1,416.9
		c10.3-2.9,20.8-5.6,31.2-8.1 M298,424.5h-21.9 M280.1,175.3c10.3,2.9,20.8,5.6,31.2,8.1 M276.1,167.8H298 M472,391.6
		c2.8,0,35.5-0.1,98.2-0.1 M564.7,415.7c-62.2,1.2-95.5,1.9-99.9,2.1 M565.4,412.5c-59.6,1.2-92.9,1.8-99.7,1.9 M564.7,176.6
		c-62.2-1.2-95.5-1.9-99.9-1.9 M565.4,179.7c-59.6-1.2-92.9-1.9-99.7-2.1 M472,200.7c2.8,0,35.5,0.1,98.2,0.1"
            />
          </g>
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M260.5,407.6
		c0,0-36.6,2.3-38.1-111.3c0,0-1.5-104.8,38.1-111.4c0,0-39.8-6.1-115.8,1.8c0,0-50.9,9.1-69.3,37.7c0,0-13.9,20.5-13.9,71.8
		c0,51.3,13.5,71.5,13.8,71.9c0,0,8.7,14.7,34,26.1c0,0,16.6,7.6,35.5,11.6c0,0,34.8,3.6,54.1,3.9
		C198.8,409.5,235.2,410.7,260.5,407.6z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M368.5,383.6
		c0,0-16.9-81.7,0-175l-85.7-15.4c0,0-10.6-2.2-13.5,8c0,0-10.5,35.1-10.5,95.1c0,0-1.1,46.5,10,93.5c0,0,1.2,11,13.8,9.2
		L368.5,383.6z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M368.5,383.6
		c0,0-7.4-36.3-7.4-87.5c0,0-0.4-46.6,7.4-87.5c0,0,27.2,5.5,89,8.5c0,0,13.9,0.4,24.9,0.4h199.7c0,0-1.4,3.4-0.8,7.7
		c0,0-1.9,52.4-1.7,70.7c0,0,0,21.6,1.7,70.4c0,0-0.6,4,0.8,8.1H484.5C484.5,374.7,435.1,373.6,368.5,383.6z"
              id="Roof 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M450.4,363.4h-40.6
		c0,0-9.2,0-10.5-10.5c0,0-1.9-33.7-1.7-57c0,0,0-29.4,1.5-55.2c0,0-0.8-10.5,9.9-12h42c0,0,8.4,0.1,9.9,10.5v113.4
		C461,352.7,460.6,362.4,450.4,363.4z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M693,386.8
		c0,0,18.4-13.3,19.8-63.4c0,0,2.8-0.3,3-5.8l0.1-2.8v-37.6c0,0,0.1-7.2-3.2-8.3c0,0-0.7-47.9-19.8-63.4c0,0,33.4-17.5,57.8-11
		c0,0,20.1,19.4,21.2,101.7c0,0,0.6,73-21.2,101.7C750.8,397.8,729.2,404.7,693,386.8z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M770.9,391
		c0,0-8.4,5.1-20.1,6.7c0,0,20.5-22.3,21.2-101.7c0,0,0.6-74.7-21.2-101.7c0,0,10.3,1.2,20.1,6.7c0,0,7.8,16.5,8.8,80.5
		c0,0,0.4,2.8-1.5,4.4c0,0-1.7,1.6-2.1,7.6v4.5c0,0,0.1,5.9,2.3,8.1c0,0,1.2,1.6,1.2,3C779.6,309.3,780,365.5,770.9,391z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Wagon4DrTop);
