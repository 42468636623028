import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import CreatePrivateLotModal from './CreatePrivateLotModal';
import AddPrivateLotModal from './AddPrivateLotModal';
import AddBuyerModal from './AddBuyerModal';

window.$ = window.jQuery = $;

export default class PrivateLotButton extends Component {
  handlePrivateLot = e => {
    e.preventDefault();

    const { items } = this.props;

    if (items.length) {
      const type = e.currentTarget.getAttribute('data-type');

      if (type === 'Create PrivateLot') {
        $('#createPrivateLotModal')
          .appendTo('body')
          .modal('show');
      }

      if (type === 'Add to PrivateLot') {
        $('#addPrivateLotModal')
          .appendTo('body')
          .modal('show');
      }
    } else {
      toastr.error('Error', 'Please select a vehicle to create a Private Lot');
    }
  };

  handleSearchBuyers = e => {
    e.preventDefault();

    $('#addBuyers')
      .appendTo('body')
      .modal('show');
  };

  render() {
    const { items } = this.props;

    return (
      <div className="btn-group" style={{ marginLeft: 10 }}>
        <button
          type="button"
          className="pull-right btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Private Lot <span className="caret" />
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link
              to="#"
              data-type="Create Private Lot"
              onClick={this.handlePrivateLot}
            >
              Create PrivateLot
            </Link>
          </li>
        </ul>
        <CreatePrivateLotModal items={items} />
        <AddPrivateLotModal />
        <AddBuyerModal />
      </div>
    );
  }
}
