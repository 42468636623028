import React, { Component } from 'react';
import { connect } from 'react-redux';

class SportsVanFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M254.2,297.4c-1,5.6-1.4,48.4-0.4,54.5c0.4,2.6,0.7,4.5,0.8,5.8c0,0.1,0,0.1,0.1,0.3
	c0.1,1.4,0.3,1.9,0.3,1.9s1.4,20.1,2.5,25.9c1.1,5.9,2.1,10,10.9,12.4c1.2,5.1,1.2,9.9,7,11c1.2,0.3,10.9,1.7,22.4,2.1
	c36.7,1.4,109,2.5,121.6,2.6h1.4c12.8-0.1,87.2-1.2,123.9-2.6c11.6-0.4,21.2-1.8,22.4-2.1c5.8-1,5.8-5.9,7-11
	c8.7-2.3,9.6-6.6,10.7-12.4c1.1-5.9,2.5-25.9,2.5-25.9s0-0.6,0.3-1.9c0-0.1,0-0.1,0.1-0.3c0.1-1.2,0.4-3,0.8-5.8
	c1-6.1,0.5-48.7-0.4-54.5c-0.8-4.8-6.5-14.2-8.3-17.2c-0.4-0.5-0.6-1-0.6-1s-1.5-8.5-2.2-11.8c-0.5-3.3-4.8-8.1-6.1-10.5
	c-1-1.8-2.8-5.8-3.7-8.5c9.8,0,20.5-2.1,24.8-3.6c1.7-0.7,2.6-1.9,3.2-3.6c0.7-2.3,0.5-5.5,0.5-8.8c0.1-5.5-2.1-6.3-6.6-8.9
	c-4.5-2.6-13.5-2.6-18.7-2.6c-5.2-0.1-7.2,2.2-7,4.8c0.1,2.6,0.5,8,1.2,10.7c0.7,2.9,0.5,3.9-0.1,4.1c-1.4-3.9-2.6-7.4-3.9-11
	c-12.7-35.1-21.9-51-25.9-56.8c-4.4-6.3-9.5-8.8-16.9-7.8c-2.9-0.7-8-1-11.6-1.4c-0.4-1.2-1.2-3.6-4.7-3.6c-3.2,0-4,0.3-4.4,2.9
	c-34-3.7-69.2-4-76.5-4h-0.7c-7.3,0-40.7,0.3-74.7,4c-0.4-2.6-1.2-2.9-4.4-2.9c-3.4,0-4.1,2.3-4.7,3.6c-3.7,0.4-8.7,0.7-11.6,1.4
	c-7.6-1.1-12.7,1.5-16.9,7.8c-4,5.8-13.2,21.6-25.9,56.8c-1.2,3.4-2.5,7.2-3.9,11c-0.7-0.1-0.8-1.2-0.1-4.1s1.1-8.1,1.2-10.7
	s-1.8-5-7-4.8c-5.2,0.1-14.2,0-18.7,2.6s-6.6,3.4-6.6,8.9c0,3.3-0.1,6.5,0.6,8.8c0.4,1.6,1.4,2.9,3.2,3.6c4.1,1.5,15,3.6,24.8,3.6
	c-1.1,2.9-2.8,6.7-3.7,8.5c-1.2,2.3-5.5,7.3-6.1,10.5c-0.5,3.3-2.2,11.8-2.2,11.8s-0.3,0.3-0.5,1C260.5,283.2,255,292.7,254.2,297.4
	L254.2,297.4z"
          />
          <path
            fill="#FFFFFF"
            d="M340.7,160.2c-3.4,0-4.1,2.3-4.7,3.6h-0.1v0.7l9.1-0.8l0.1-0.6C344.9,160.5,343.9,160.1,340.7,160.2
	L340.7,160.2z"
          />
          <path
            fill="#EAEBEB"
            d="M421.1,164.9c0.3,0-49.5,0-101.5,5c-1.5,0.1-3.2,0.8-5.2,2.3c-1.9,1.5-4,3.7-6.1,6.9
	c-4.3,6.6-22.6,49.5-23.4,51.7c-0.8,2.2-1.6,5,2.3,4.7c10.2-0.8,133.6-4.4,133.7-4.4c0,0,123.7,3.6,133.9,4.4c4,0.3,3.2-2.5,2.3-4.7
	c-0.8-2.2-19.1-45.1-23.4-51.7c-4.3-6.6-8.4-8.9-11.1-9.2C470.6,164.9,420.8,164.9,421.1,164.9L421.1,164.9z M269.9,267.1
	c-4.5,2.2-4.5,3.9-4.7,6.5c-0.1,1.2-0.4,3.6-0.5,6.5c-0.3,3.3-0.3,7.4,0.1,12c0.8,8.5,9.1,12.5,16.2,13.5c7.2,0.8,14.3-2.9,14.3-2.9
	s24.2,0.7,28.9,0.5c4.7-0.1,4.5-1.5,4.5-4.1c0-2.6-1-10-3.4-19.4c-1.9-7-3.3-7.4-8.3-8.4c-4.5-1-22.3-2.9-32.1-4.1
	c-0.8-0.1-1.5-0.1-2.2-0.3C277.5,264.3,274.4,264.9,269.9,267.1L269.9,267.1z"
          />
          <path
            fill="#FFFFFF"
            d="M262.2,328v7.2c3.6,1.8,10.6,2.3,15.3,2.5c4.7,0.1,59.7,0.4,59.7,0.4l-1.7-6.2c0,0-45.9-1.2-56.8-1.2
	C266,330.7,262.2,328,262.2,328L262.2,328z M276.1,370.3c0.5,3.3,0.8,6.6,3.2,12.7c3.2,7.8,8.9,10.7,18,11.6c5.5,0.5,45,1,49.1,1.2
	c3,0.1,3.7-2.2,3.7-4.3c0-0.7,0-1.4-0.1-1.9c-0.3-2.2-1.5-8.8-3.2-14.4c-1.6-5.8-5.4-8.3-9.5-8.5s-52.7-1.6-57.5-1.6
	C275.1,364.8,275.5,367,276.1,370.3L276.1,370.3z"
          />
          <path
            fill="#EAEBEB"
            d="M302.8,366.6c-6.1,0-10.9,5.1-10.9,11.4s4.8,11.4,10.9,11.4c5.9,0,10.9-5.1,10.9-11.4
	C313.6,371.8,308.8,366.6,302.8,366.6z"
          />
          <path
            fill="#FFFFFF"
            d="M397.3,379.8c-12.5-0.1-25.6,0-26.8,0c-2.5,0-3.2,0.5-3.3,2.5c-0.1,3.7-0.4,5.6,5.2,5.5
	c3.2-0.1,14.7-0.3,25.7-0.3C397.8,384.7,397.4,380.9,397.3,379.8L397.3,379.8z M400.4,379.9c0.1,1.2,0.7,6.2,0.8,7.6h39.6
	c0.1-1.5,0.7-6.5,0.8-7.6c-10.9,0-20.9-0.1-20.6-0.1C421.4,379.8,411.2,379.9,400.4,379.9L400.4,379.9z M471.8,379.8H445
	c-0.1,1-0.6,4.8-0.8,7.7c11,0,22.6,0.1,25.7,0.3c5.8,0.3,5.4-1.9,5.2-5.5C475,380.3,474.3,379.8,471.8,379.8L471.8,379.8z
	 M338.4,271.1c-1.9,0.1-3.9,1.1-3.7,3.7h0.3c1.4,4.1,8,25.6,15.4,39.5c2.2,4,6.5,11.3,24.9,12.2c16.9,0.8,41.8,1.5,45.7,1.6
	c4-0.1,28.8-0.8,45.7-1.6c18.4-1,22.8-8.4,24.9-12.2c7.6-13.8,14.2-35.2,15.4-39.5h0.3c0.1-2.6-1.8-3.6-3.7-3.7
	c-4.8-0.4-70.2-1-72.8-1s-3-0.8-4.1-1.6c-1.5-1.1-4.8-1.2-5.8-1.2c-1,0-4.3,0.1-5.8,1.2c-1.1,0.8-1.5,1.6-4.1,1.6
	C408.6,270.1,343.2,270.7,338.4,271.1L338.4,271.1z"
          />
          <path
            fill="#5E5E5E"
            d="M259.4,392.4c0.1,7.2,0,29.4,0.3,34.3c0.4,6.9,1.5,11.7,5.9,11.8h23.7c4.5,0,5.5-2.9,5.4-12
	c-0.1-5.9-0.1-12.7-0.1-15.7c-10.2-0.6-18.3-1.6-19.4-1.9c-5.8-1-5.8-5.9-7-11C263.3,396.8,260.8,394.9,259.4,392.4L259.4,392.4z"
          />
          <path
            fill="#FFFFFF"
            d="M501.3,160.2c3.4,0,4.1,2.3,4.7,3.6h0.1v0.7l-9.1-0.8l-0.1-0.6C497.3,160.5,498.1,160.1,501.3,160.2
	L501.3,160.2z"
          />
          <path
            fill="#EAEBEB"
            d="M572.3,267.1c4.5,2.2,4.5,3.9,4.7,6.5c0.1,1.2,0.4,3.6,0.5,6.5c0.3,3.3,0.3,7.4-0.1,12
	c-0.8,8.5-9.1,12.5-16.2,13.5c-7.2,0.8-14.3-2.9-14.3-2.9s-24.2,0.7-28.9,0.5c-4.7-0.1-4.5-1.5-4.5-4.1c0-2.6,1-10,3.4-19.4
	c1.9-7,3.3-7.4,8.2-8.4c4.5-1,22.3-2.9,32.1-4.1c0.8-0.1,1.5-0.1,2.2-0.3C564.6,264.3,567.7,264.9,572.3,267.1L572.3,267.1z"
          />
          <path
            fill="#FFFFFF"
            d="M580,328v7.2c-3.6,1.8-10.6,2.3-15.3,2.5c-4.7,0.1-59.7,0.4-59.7,0.4l1.7-6.2c0,0,45.9-1.2,56.8-1.2
	C576.1,330.7,580,328,580,328L580,328z M566.1,370.3c-0.5,3.3-0.8,6.6-3.2,12.7c-3.2,7.8-8.9,10.7-18,11.6c-5.5,0.5-45,1-49.1,1.2
	c-3,0.1-3.7-2.2-3.7-4.3c0-0.7,0-1.4,0.1-1.9c0.3-2.2,1.5-8.8,3.2-14.4c1.6-5.8,5.4-8.3,9.5-8.5s52.7-1.6,57.5-1.6
	C567,364.8,566.6,367,566.1,370.3L566.1,370.3z"
          />
          <path
            fill="#EAEBEB"
            d="M539.4,366.6c6.1,0,10.9,5.1,10.9,11.4s-4.8,11.4-10.9,11.4c-5.9,0-10.9-5.1-10.9-11.4
	C528.5,371.8,533.3,366.6,539.4,366.6L539.4,366.6z"
          />
          <path
            fill="#5E5E5E"
            d="M582.7,392.4c-0.1,7.2,0,29.4-0.3,34.3c-0.4,6.9-1.5,11.7-5.9,11.8h-23.7c-4.5,0-5.5-2.9-5.4-12
	c0.1-5.9,0.1-12.7,0.1-15.7c10.2-0.6,18.3-1.6,19.4-1.9c5.8-1,5.8-5.9,7-11C578.9,396.8,581.2,394.9,582.7,392.4L582.7,392.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M278.4,240.4c-2.1,6.1-2.1,5.8-2.8,8.1 M276.9,244.1c0,0-0.5-0.8-5.6-1.1
	c-5.1-0.3-23.2-0.7-24.2-1.8 M268.1,397.6c0,0,9.6,2.2,20.6,2.3c44.6,1,132.5,1.4,132.5,1.4 M276.1,370.3c-0.5-3.3-1-5.5,3.7-5.5
	s53.4,1.4,57.5,1.6s7.8,2.8,9.5,8.5c1.6,5.6,2.9,12.2,3.2,14.4c0.3,2.2,0.5,6.5-3.6,6.3c-4.1-0.1-43.6-0.7-49.1-1.2
	c-9.1-0.8-14.9-3.7-18-11.6C276.9,376.9,276.6,373.7,276.1,370.3L276.1,370.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M302.8,368.3c5.1,0,9.1,4.3,9.1,9.6s-4.1,9.6-9.1,9.6
	c-5.1,0-9.1-4.3-9.1-9.6C293.7,372.8,297.7,368.3,302.8,368.3z M290.7,373.3c0.7-1.6,1.6-3.7,2.8-5.1c-3.2-0.1-8.1,0-9.1,0
	s-1.6,0.5-1.4,1.9c0.4,1.8,1.4,3.3,1.4,3.3 M289.7,378.5c0,2.1,0.5,3.6,1.2,5.2c-3.4-3-4.5-5.2-4.5-5.2 M315.4,374.3
	c-0.5-2.2-1.6-4.4-3-5.9c4.8-0.1,13.6-1,17.1,4.1c1,1.4,0.8,1.8,0.8,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315.4,374.3c0.4-0.3,12,0,15.3,0c3.3,0,5.9,2.1,6.5,4.4
	c0.5,2.3-3.4,1.6-5.5,1.6c-1.8,0-16-0.3-16-0.3s-1.4-0.7-1.5-2.2C314.1,375.9,314.5,374.5,315.4,374.3L315.4,374.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.5,380.5c0,0,0.7,3.3,0.8,4.4c0.1,1.4,0,1.5-1.6,1.6
	c-1.8,0.1-13.6-0.3-18.2-0.4c1.2-1.8,1.9-3.9,2.2-6.2 M290.4,373.4h-5.8c-0.8,0-1.9,0.7-1.8,3.2c0.1,1.2,1.4,1.9,2.6,1.9h4.3
	c1.5,0,2.5-1.1,2.5-2.2C292,375.4,291.6,373.3,290.4,373.4L290.4,373.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M302.8,366.6c5.9,0,10.9,5.1,10.9,11.4s-4.8,11.4-10.9,11.4
	s-10.9-5.1-10.9-11.4C291.9,371.8,296.9,366.6,302.8,366.6z M262.5,279.9c0,0-1.5,59-1.2,69.2c0.1,3.4-0.5,11.6,15.4,16.2
	c-12.5-1.6-21.6-1.6-22.6-10.6 M261.2,320.1h-7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M261.6,317.6c0,0,3.3,5.1,12.7,5.4c9.4,0.3,74.3,1.6,74.3,1.6
	 M265.2,273.6c0.3-2.8,0.3-4.4,4.7-6.5c4.5-2.2,7.6-2.6,12.8-0.4c8.9,1.1,29.3,3.4,34.3,4.4s6.5,1.4,8.3,8.4
	c2.5,9.5,3.4,16.8,3.4,19.4s0.1,4.1-4.5,4.1c-4.7,0.1-28.9-0.5-28.9-0.5s-7.2,3.9-14.3,2.9c-7-1-15.4-5-16.2-13.5
	C264,283.5,264.9,276.2,265.2,273.6L265.2,273.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283,271.2c7.8,0,14.2,6.5,14.2,14.3c0,8-6.3,14.3-14.2,14.3
	s-14.2-6.5-14.2-14.3C268.8,277.7,275.1,271.2,283,271.2z M314.9,295.4c-1.6,0.8-3.4,1.4-5.4,1.4c-6.2,0-11.1-5-11.1-10.9
	c0-6.1,5-10.9,11.1-10.9c6.1,0,11.1,5,11.1,10.9c0,0.5,0,0.5-0.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M320.1,286.9c3.3,0,5.9,2.8,5.9,6.2s-2.6,6.2-5.9,6.2
	c-3.3,0-5.9-2.8-5.9-6.2S316.8,286.9,320.1,286.9L320.1,286.9z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="283.4"
            cy="284.7"
            rx="2.6"
            ry="2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M309.7,283.2c1.5,0,2.6,1.2,2.6,2.8s-1.2,2.8-2.6,2.8
	c-1.5,0-2.6-1.2-2.6-2.8C306.9,284.4,308.1,283.2,309.7,283.2z M320,291.2c1,0,1.8,0.8,1.8,1.9s-0.8,1.9-1.8,1.9s-1.8-0.8-1.8-1.9
	C318.2,292.1,319,291.2,320,291.2L320,291.2z M295.6,235.2c0,0-7.4,3.7-9.2,4.7c-1.8,1.1-3.4,1.9-3.6,6.9c-0.1,5-0.1,19.8-0.1,19.8
	 M421.4,267.1c0,0-4.3,0-6.1,1.2c-1.1,0.8-1.5,1.6-4.1,1.6s-68,0.5-72.8,1c-2.3,0.1-4.7,1.4-3.3,5.6c1.4,4.1,7.4,28.6,15,42.4
	c2.2,3.9,6.5,11.3,24.9,12.2s46.2,1.6,46.2,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M374,321.3c-4.8-0.4-8.4-1.2-11.3-2.8c-0.3-0.1-0.4-0.5-0.3-0.8
	c0.1-0.3,0.4-0.4,0.8-0.3c2.8,1.4,6.2,2.2,10.9,2.6c7.2,0.5,24.5,0.7,36.3,0.7H422c0.3,0,0.5,0.3,0.5,0.5c0,0.3-0.3,0.5-0.5,0.5
	h-11.6C398.5,322,381.2,321.8,374,321.3 M355.6,311.5c-0.4-0.7-0.8-1.5-1.1-2.2c-0.1-0.4-0.4-0.8-0.5-1.4c-0.1-0.3,0-0.7,0.3-0.8
	c0.3-0.1,0.7,0,0.8,0.3c0.1,0.4,0.4,1,0.5,1.2c0.3,0.8,0.7,1.5,1.1,2.2c0.1,0.3,0.1,0.7-0.1,0.8c-0.1,0-0.1,0.1-0.3,0.1
	C355.9,311.8,355.7,311.7,355.6,311.5 M351.5,301.1c-0.4-1.1-0.8-2.3-1.2-3.6c-0.1-0.3,0-0.7,0.4-0.8c0.3-0.1,0.7,0,0.7,0.4
	c0.4,1.2,0.8,2.5,1.2,3.6c0.1,0.3,0,0.7-0.4,0.8H352C351.9,301.5,351.6,301.4,351.5,301.1 M348.3,291.3c-0.4-1.4-1-2.9-1.4-4.3
	c-0.1-0.3,0-0.7,0.4-0.8c0.3-0.1,0.7,0.1,0.7,0.4c0.4,1.4,0.8,2.8,1.4,4.3c0.1,0.3,0,0.3-0.4,0.4l-0.1,0.1v0.3
	C348.6,291.7,348.4,291.6,348.3,291.3 M345,280.6c-1-3.3-1.6-5.4-1.6-5.4c0-0.1,0-0.4,0.1-0.5s0.3-0.3,0.4-0.3h25.7
	c0.3,0,0.5,0.3,0.5,0.5s1.1,0.7,0.8,0.7h-26.4c0.3,1,0.8,2.5,1.4,4.5c0.1,0.3-0.1,0.7-0.4,0.8h-0.1C345.3,281,345.1,280.9,345,280.6
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M348.7,325.3c0,0-64.9-1.4-74.3-1.6v-0.5v0.5c-0.3,0-0.5-0.3-0.5-0.7
	c0-0.3,0.3-0.5,0.5-0.5l74.1,1.6h0.1c0.1,0,0.3,0,0.3,0.1c0.1,0,0.1,0.1,0.3,0.3v0.3c0,0.1,0,0.1-0.1,0.3l-0.1,0.1
	C348.9,325.3,348.7,325.3,348.7,325.3 M405.5,322.7c-0.1-0.1-0.1-0.3-0.3-0.4l-0.3-3.7h-15l0.5,3.3c0,0.3-0.1,0.7-0.4,0.7
	c-0.3,0-0.5-0.1-0.7-0.5l-0.5-3.6h-15.7l0.7,2.9v0.1c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.1,0.3-0.4,0.3h-0.4c-0.1-0.1-0.3-0.3-0.3-0.4
	l-0.7-3.2h-13.9l-1.6-0.3c-0.8-0.3-1.6-0.3-2.2-0.8s-0.8-1.2-0.8-1.9v-0.5c0.3-1.2,2.2-3.7,2.2-3.9c0.1-0.1,0.3-0.3,0.4-0.3
	l13.9-0.1l-0.7-2.8h-15.5l-1.6-0.3c-0.8-0.3-1.7-0.3-2.2-0.8c-0.5-0.5-0.8-1.2-0.8-1.9v-0.5c0.1-0.7,0.7-1.5,1.2-2.3
	c0.5-0.8,1.1-1.5,1.1-1.5c0.1-0.1,0.3-0.3,0.4-0.3l15.4-0.1c-0.1-0.8-0.4-1.5-0.5-2.3h-16l-1.6-0.3c-0.8-0.1-1.7-0.3-2.2-0.8
	s-0.8-1.2-0.8-1.9v-0.5c0.1-0.7,0.7-1.5,1.2-2.3c0.5-0.8,1.1-1.5,1.1-1.5c0.1-0.1,0.3-0.3,0.4-0.3l16.4-0.1c-0.3-1-0.4-1.8-0.7-2.6
	h-17.2c0,0-0.7,0-1.6-0.1c-0.8-0.1-1.7-0.1-2.3-0.7c-0.5-0.5-0.8-1.2-0.8-1.9v-0.5c0.3-1.1,2.2-3.9,2.2-4c0.1-0.1,0.3-0.3,0.4-0.3
	l17.3-0.1l-1-4.5c-0.1-0.3,0.1-0.7,0.4-0.7c0.3-0.1,0.7,0.1,0.7,0.4l1,4.7l18.6-0.1l-0.4-2.8c0-0.3,0.3-0.3,0.5-0.3s0.5,0.1,0.7,0.5
	l0.4,2.6l18-0.1v-0.5v-0.1c0,0,0.1-0.3,0.5-0.3c0.5,0.1,0.5,0.4,0.5,0.4v0.7l5.2,0.1c0.3,0,1.1,0.4,1,0.7c0,0.3,0.7,0.5,0.4,0.5
	l-7.2-0.3l-19.1,0.1l-37.4,0.3c-0.1,0.3-0.5,0.7-0.8,1.2c-0.5,0.8-1,1.8-1,1.9v0.3c0,0.4,0.1,0.7,0.5,1.1h0.1c0.1,0,0.3,0.1,0.4,0.1
	c0.3,0.1,0.7,0.1,1.1,0.1c0.8,0.1,1.5,0.1,1.5,0.1h74c0.3,0,0.5,0.3,0.5,0.5c0,0.3-0.3,0.5-0.5,0.5h-17.9c0.1,0.8,0.1,1.7,0.3,2.5
	l17.3-0.1c0.3,0,0.5,0.3,0.5,0.5s-0.3,0.5-0.5,0.5l-17.7,0.1l-18.3,0.1l-18.8,0.1l-16.8,0.1c-0.3,0.4-0.7,0.8-1,1.2
	c-0.5,0.7-1.1,1.8-1.1,1.8v0.3c0,0.4,0.1,0.7,0.5,1.1l0.5,0.3c0.3,0.1,0.7,0.1,1.1,0.3c0.7,0.1,1.2,0.3,1.5,0.3h70.4
	c0.3,0,0.5,0.3,0.5,0.5s-0.3,0.5-0.5,0.5h-16.9l0.1,1.9h16.5c0.3,0,0.5,0.3,0.5,0.5c0,0.3-0.3,0.5-0.5,0.5H404l-17.5,0.1l-18.2,0.1
	l-15.8,0.1c-0.1,0.3-0.5,0.7-0.8,1.2c-0.5,0.7-1.1,1.8-1.1,1.9v0.3c0,0.4,0.1,0.7,0.4,1.1l0.5,0.3c0.3,0.1,0.7,0.1,1.1,0.3
	c0.7,0.1,1.4,0.3,1.5,0.3h66.7c0.3,0,0.5,0.3,0.5,0.6s-0.3,0.5-0.5,0.5h-15.8l0.1,2.3h15.8c0.3,0,0.5,0.3,0.5,0.5s-0.3,0.5-0.5,0.5
	h-16.4l-16.6,0.1l-31.6,0.4c-0.1,0.3-0.5,0.7-0.8,1.2c-0.5,0.8-1,1.8-1,1.9v0.3c0,0.4,0.1,0.7,0.5,1.1l0.5,0.3
	c0.3,0.1,0.7,0.1,1.1,0.3c0.7,0.1,1.4,0.3,1.5,0.3h63c0.3,0,0.5,0.3,0.5,0.5s-0.3,0.6-0.5,0.6h-15.1c0.1,2.1,0.3,3.3,0.3,3.6
	c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.3C405.7,322.8,405.5,322.8,405.5,322.7 M371.3,310.7l16.2-0.1l-0.4-2.5h-16.5
	L371.3,310.7L371.3,310.7z M388.8,310.6l15.5-0.1l-0.1-2.3h-15.8L388.8,310.6L388.8,310.6z M368.8,300.3l17.1-0.1l-0.4-2.2h-17.2
	C368.4,298.6,368.7,299.4,368.8,300.3 M387,300l16.5-0.1l-0.1-1.9h-16.6L387,300L387,300z M366.5,290.1l17.7-0.1l-0.4-2.6h-18
	C365.9,288.3,366.2,289.1,366.5,290.1 M385.3,289.9l17.3-0.1l-0.1-2.5H385L385.3,289.9L385.3,289.9z M421.4,164.9
	c0,0-49.7,0-101.8,5c-2.9,0.3-6.9,2.6-11.1,9.2s-22.6,49.5-23.4,51.7c-0.8,2.2-1.6,5,2.3,4.7c10.2-0.8,133.6-4.4,133.6-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M331.5,274.8c1.4,4.1,8.9,34.8,16.4,48.7c2.2,3.9,6.5,11.3,24.9,12.2
	c18.4,1,48.3,1.6,48.3,1.6 M334.8,274.8l-11.6-0.5 M337.4,271.1c0,0-8.9-28.5-16.1-34.8c-2.1-1.9-3.3-1.4-0.4,5
	c2.9,6.3,10.6,33.6,10.6,33.6 M264.8,278.4c-0.3,2.6-1.2,9.9-0.4,18.4c0.8,8.5,9.1,12.5,16.2,13.5c7,0.8,14.3-2.9,14.3-2.9
	s26.7,0.1,31.4,0.1c4.7-0.1,4.5-1.5,4.5-4.1s-3.4-21.5-6.7-29c-0.7-1.6-1.9-3.2-6.1-3.9s-31.1-4.3-33-3.4 M333.5,386.4l16.9,5
	l8.3-0.1l8.4-4.5 M346.1,395.7l75.5,0.5 M398.1,387.5c-11,0-22.6,0.1-25.7,0.3c-4.7,0.3-5.1-1.2-5.2-2.9v-2.6
	c0.1-1.9,0.8-2.5,3.3-2.5h26.8C397.4,380.9,397.8,384.7,398.1,387.5L398.1,387.5z M421.4,379.8c0,0-10,0.1-20.9,0.1
	c0.1,1.2,0.7,6.2,0.8,7.6h19.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,377.4c0,0-59,0.4-60.7,0.4c-1.6,0-5-0.7-4.1,3.7
	c0.7,4.4,1,5.9,1.1,7.3c0.5,3.2,1.8,4.5,4.7,4.7c4.5,0.1,59,0.1,59,0.1 M344.2,275.1c-0.5,0-9.5-0.4-9.5-0.4 M335,274.8
	c1.4,4.1,8,25.6,15.4,39.5c2.2,4,6.5,11.3,24.9,12.2c18.4,1,46.2,1.6,46.2,1.6 M563.7,240.7c2.2,6.3,2.5,6.7,3,8.3 M565.1,244
	c0,0,0.7-0.8,5.8-1c5.1-0.3,23.2-0.7,24.2-1.8 M573.9,397.6c0,0-9.6,2.2-20.6,2.3c-44.6,1-132.5,1.4-132.5,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.4,159c0,0-37.7-0.3-76.1,4c-0.4-2.6-1.2-2.9-4.4-2.9
	c-3.4,0-4.1,2.3-4.7,3.6c-3.7,0.4-8.7,0.7-11.6,1.4c-7.6-1.1-12.5,1.5-16.9,7.8c-4.4,6.3-15.1,24.9-29.7,67.7
	c-0.7-0.1-0.8-1.2-0.1-4.1c0.7-2.9,1.1-8.1,1.2-10.7s-1.8-5-7-4.8c-5.2,0.1-14.2,0-18.7,2.6s-6.6,3.4-6.6,8.9
	c0.1,5.5-0.6,10.9,3.7,12.4c4.1,1.5,15,3.6,24.8,3.6c-1.1,2.9-2.8,6.7-3.7,8.5c-1.2,2.3-5.5,7.3-6.1,10.5
	c-0.5,3.3-2.2,11.8-2.2,11.8s-7.8,12.4-8.8,18c-1,5.6-1.4,48.4-0.4,54.5s1.1,7.8,1.1,7.8s1.4,20.1,2.5,25.9
	c1.1,5.9,2.1,10,10.9,12.4c1.2,5.1,1.2,9.9,7,11c1.2,0.3,10.9,1.6,22.4,2.1c40.4,1.5,123.5,2.6,123.5,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M259.4,392.7c0.1,5,0,28.8,0.4,34.1c0.4,6.9,1.5,11.7,5.9,11.8h23.7
	c4.5,0,5.5-2.9,5.4-12c-0.1-7.7-0.1-15.7-0.1-15.7 M420.9,159c0,0,37.7-0.3,76.1,4c0.3-2.6,1.2-2.9,4.4-2.9c3.4,0,4.1,2.3,4.7,3.6
	c3.6,0.4,8.7,0.7,11.6,1.4c7.6-1.1,12.7,1.5,16.9,7.8c4.4,6.3,15.1,24.9,29.7,67.7c0.7-0.1,0.4,0,0.1-2.9c-0.3-2.9-1.2-9.2-1.4-12
	c-0.1-2.6,1.8-5,7-4.8c5.2,0.1,14.2,0,18.7,2.6c4.5,2.6,6.6,3.4,6.6,8.9c-0.1,5.5,0.4,10.9-3.7,12.4c-4.1,1.5-15,3.6-24.6,3.6
	c1.1,2.9,2.8,6.7,3.7,8.5c1.2,2.3,5.5,7.3,6.1,10.5c0.5,3.3,2.2,11.8,2.2,11.8s7.8,12.4,8.8,18c1,5.6,1.4,48.4,0.4,54.5
	s-1.1,7.8-1.1,7.8s-1.4,20.1-2.5,25.9c-1.1,5.9-2.1,10-10.9,12.4c-1.2,5.1-1.2,9.9-7,11c-1.2,0.3-10.9,1.6-22.4,2.1
	c-40.4,1.5-123.5,2.6-123.5,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M582.7,392.7c-0.1,5,0,28.8-0.4,34.1c-0.4,6.9-1.5,11.7-5.9,11.8h-23.7
	c-4.5,0-5.5-2.9-5.4-12c0.1-7.7,0.1-15.5,0.1-15.5 M566.1,370.3c0.5-3.3,1-5.5-3.7-5.5c-4.7,0-53.2,1.4-57.5,1.6
	c-4.1,0.3-7.8,2.8-9.5,8.5c-1.6,5.6-2.9,12.2-3.2,14.4c-0.3,2.2-0.3,6.3,3.9,6.2s43.3-0.6,48.8-1c9.1-0.8,14.9-3.7,18-11.6
	C565.3,376.9,565.5,373.7,566.1,370.3L566.1,370.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M539.4,368.3c-5.1,0-9.1,4.3-9.1,9.6s4.1,9.6,9.1,9.6
	c5.1,0,9.1-4.3,9.1-9.6C548.5,372.8,544.3,368.3,539.4,368.3z M551.5,373.3c-0.7-1.6-1.7-3.7-2.8-5.1c3.2-0.1,8.1,0,9.1-0.1
	c1,0,1.7,0.6,1.4,1.9c-0.4,1.8-1.4,3.3-1.4,3.3 M552.5,378.5c0,2.1-0.5,3.6-1.2,5.2c3.4-3,4.5-5.2,4.5-5.2 M526.7,374.3
	c0.5-2.2,1.7-4.4,3-5.9c-4.8-0.1-13.6-1-17.1,4.1c-1,1.4-0.8,1.8-0.8,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M526.7,374.3c-0.4-0.3-12,0-15.3,0c-3.3,0-5.9,2.1-6.5,4.4
	c-0.5,2.3,3.4,1.6,5.5,1.6c1.8,0,16-0.3,16-0.3s1.4-0.7,1.5-2.2C528.1,375.9,527.6,374.5,526.7,374.3L526.7,374.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M509.5,380.5c0,0-0.7,3.3-0.8,4.4c-0.1,1.4,0,1.5,1.6,1.6
	c1.8,0.1,13.6-0.3,18.2-0.4c-1.2-1.8-1.9-3.9-2.2-6.2 M551.8,373.4h5.8c0.8,0,1.9,0.7,1.8,3.2c-0.1,1.2-1.4,1.9-2.6,1.9h-4.3
	c-1.5,0-2.5-1.1-2.5-2.2C550.1,375.4,550.5,373.3,551.8,373.4L551.8,373.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M539.4,366.6c-6.1,0-10.9,5.1-10.9,11.4s4.8,11.4,10.9,11.4
	c5.9,0,10.9-5.1,10.9-11.4C550.1,371.8,545.3,366.6,539.4,366.6L539.4,366.6z M579.7,279.9c0,0,1.5,59,1.2,69.2
	c-0.1,3.4,0.7,11.4-15.3,16.1c12.5-1.6,21.6-1.5,22.6-10.5 M580.9,320.1h7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M580.7,317.3c0,0-3.4,5.4-12.8,5.6c-9.4,0.3-74.3,1.6-74.3,1.6
	 M576.9,273.6c-0.3-2.8-0.3-4.4-4.7-6.5c-4.4-2.2-7.6-2.6-12.8-0.4c-8.9,1.1-29.3,3.4-34.3,4.4c-5,1-6.3,1.4-8.3,8.4
	c-2.5,9.5-3.4,16.8-3.4,19.4s-0.1,4.1,4.5,4.1c4.7,0.1,28.9-0.5,28.9-0.5s7.2,3.9,14.3,2.9c7.2-1,15.4-5,16.2-13.5
	C578.2,283.5,577.2,276.2,576.9,273.6L576.9,273.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M559.1,271.2c-7.8,0-14.2,6.5-14.2,14.3c0,8,6.3,14.3,14.2,14.3
	c7.8,0,14.2-6.5,14.2-14.3C573.2,277.7,566.9,271.2,559.1,271.2z M527.1,295.7c1.7,1,3.3,1.4,5.4,1.4c6.1,0,11.1-5,11.1-11.1
	s-5-11.1-11.1-11.1c-6.2,0-11.1,5-11.1,11.1c0,0.5,0,0.7,0.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M522.1,286.9c-3.3,0-5.9,2.8-5.9,6.2s2.6,6.2,5.9,6.2s5.9-2.8,5.9-6.2
	S525.4,286.9,522.1,286.9z M558.6,282c-1.5,0-2.6,1.2-2.6,2.8s1.2,2.8,2.6,2.8c1.5,0,2.6-1.2,2.6-2.8
	C561.4,283.2,560.2,282,558.6,282L558.6,282z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="532.5"
            cy="286.1"
            rx="2.6"
            ry="2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M522.2,291.2c-1,0-1.8,0.8-1.8,1.9s0.8,1.9,1.8,1.9s1.8-0.8,1.8-1.9
	C524,292.1,523.2,291.2,522.2,291.2L522.2,291.2z M546.5,235.1c0,0,7.3,3.7,9.1,4.8s3.4,1.9,3.6,6.9c0.1,5,0.3,19.8,0.3,19.8
	 M420.8,267.1c0,0,4.3,0,6.1,1.2c1.1,0.8,1.5,1.6,4.1,1.6c2.6,0,68,0.5,72.8,1c2.3,0.1,4.7,1.4,3.3,5.6c-1.4,4.1-7.4,28.6-14.9,42.4
	c-2.1,3.9-6.5,11.3-24.9,12.2s-46.2,1.6-46.2,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.3,322c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.6,0.5-0.6h11.6
	c11.7,0,29.2-0.1,36.2-0.7c4.7-0.4,8.1-1.2,10.9-2.6c0.3-0.1,0.7,0,0.8,0.3c0.1,0.3,0,0.7-0.3,0.8c-2.9,1.5-6.5,2.3-11.3,2.8
	c-7.2,0.5-24.6,0.7-36.3,0.7C425.2,322,420.3,322,420.3,322 M485.7,311.7c-0.3-0.1-0.4-0.5-0.1-0.8c0.4-0.7,0.7-1.4,1.1-2.2
	c0.1-0.4,0.3-0.8,0.5-1.2c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.5,0.3,0.8c-0.1,0.5-0.4,1-0.5,1.4c-0.4,0.8-0.7,1.5-1.1,2.3
	c-0.1,0.1-0.3,0.3-0.5,0.3L485.7,311.7 M489.9,301.5c-0.3-0.1-0.4-0.4-0.4-0.8c0.4-1.1,0.8-2.3,1.2-3.6c0.1-0.3,0.4-0.5,0.7-0.4
	c0.3,0.1,0.4,0.4,0.4,0.8c-0.4,1.2-0.8,2.5-1.2,3.6c-0.1,0.3-0.3,0.4-0.5,0.4C490,301.5,490,301.5,489.9,301.5 M493.3,291.5
	c-0.3-0.1-0.7-0.1-0.6-0.5c0.4-1.4,1-2.9,1.4-4.3c0.1-0.3,0.4-0.5,0.7-0.4c0.3,0.1,0.4,0.4,0.4,0.8c-0.4,1.4-0.8,2.8-1.4,4.3
	c-0.1,0.3-0.3,0.4-0.5,0.4l0.3-0.1L493.3,291.5L493.3,291.5z M496.5,281c-0.3-0.1-0.4-0.4-0.4-0.8c0.7-2.1,1.1-3.7,1.4-4.5
	c-4.3,0-25-0.3-25.5-0.3c-0.3,0,0.1-0.3,0.1-0.5c0-0.4,0.7-0.4,1-0.4l0.1-0.1c0.5,0,25,0.3,25,0.3c0.1,0,0.4,0.1,0.4,0.3
	c0.1,0.1,0.1,0.4,0.1,0.5c0,0-0.7,2.2-1.6,5.4c-0.1,0.3-0.3,0.4-0.6,0.4L496.5,281"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M493.4,325.3C493.3,325.3,493.3,325.3,493.4,325.3
	C493.3,325.3,493.3,325.3,493.4,325.3c-0.3,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.1-0.1-0.1-0.3v-0.3c0,0,0-0.1,0.1-0.1
	c0.1-0.1,0.3-0.1,0.4-0.1l74.3-1.6c0.3,0,0.5,0.3,0.5,0.5c0,0.3-0.3,0.5-0.5,0.7C558.5,323.9,495.1,325.3,493.4,325.3 M436.2,322.8
	c-0.1,0-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-1.1c0-0.7,0.1-1.5,0.3-2.6h-15.1c-0.3,0-0.5-0.3-0.5-0.5
	c0-0.3,0.3-0.5,0.5-0.5h62.9c0.3,0,2.3-0.4,3-0.7c0.1,0,0.1-0.1,0.1-0.1c0.3-0.3,0.4-0.7,0.5-1.1v-0.3c0-0.1-0.5-1.1-1-1.9
	c-0.3-0.5-0.7-1-0.8-1.2l-14.3-0.1l-17.3-0.1l-16.6-0.1h-16.4c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.6,0.5-0.6h15.8l0.1-2.3h-15.8
	c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.6,0.5-0.6h66.6c0.1,0,2.3-0.4,3-0.7h0.1c0.3-0.3,0.4-0.7,0.4-1.1v-0.3c0,0-0.5-1.1-1.1-1.8
	c-0.3-0.5-0.7-1-0.8-1.2l-34-0.4l-17.5-0.1h-17.1c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.6,0.5-0.6h16.5l0.1-1.9h-16.9
	c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.5,0.5-0.5h70.6c0.3,0,2.3-0.4,3-0.7c0.1,0,0.1-0.1,0.1-0.1c0.3-0.3,0.4-0.7,0.4-1.1V295
	c0,0-0.5-1.1-1.1-1.8c-0.4-0.5-0.7-1-1-1.2l-16.8-0.1l-18.8-0.1l-18.3-0.1l-17.7-0.1c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.5,0.5-0.5
	l17.3,0.1c0.1-0.8,0.1-1.6,0.1-2.5h-17.7c-0.3,0-0.5-0.3-0.5-0.5s0.3-0.5,0.5-0.5h73.9c0,0,0.7,0,1.4-0.1c0.7,0,1.5-0.3,1.8-0.3
	c0.3-0.3,0.4-0.7,0.4-1.1v-0.3c0-0.1-0.5-1.1-1-1.9c-0.3-0.5-0.7-1-0.8-1.2l-17.7-0.1l-19.7-0.1l-19.1-0.1l-6.7,0.3
	c-0.3,0,0.1-0.1,0.1-0.5l0.3-0.1c0-0.3,0.5-0.5,0.8-0.5l5-0.3c0-0.3,0-0.4,0.1-0.5c0,0,0-0.3,0.5-0.4c0.6,0.1,0.4,0.3,0.6,0.3v0.7
	l18,0.1l0.4-2.6c0-0.3,0.4-0.5,0.7-0.5s0.5-0.3,0.5,0.1l-0.1,0.7l-0.4,2.3l18.4,0.1l1.1-4.7c0-0.3,0.4-0.5,0.7-0.4
	c0.3,0.1,0.5,0.4,0.4,0.7l-1,4.5l17.3,0.1c0.1,0,0.4,0.1,0.4,0.3c0,0.1,1.9,2.8,2.2,4v0.5c0,0.8-0.3,1.5-0.8,1.9
	c-0.5,0.5-1.4,0.5-2.3,0.7c-0.8,0.1-1.5,0.1-1.7,0.1h-17.2c-0.1,0.8-0.4,1.8-0.7,2.8l16.4,0.1c0.1,0,0.3,0.1,0.4,0.3
	c0,0,0.5,0.7,1.1,1.5c0.5,0.8,1.1,1.7,1.2,2.3v0.5c0,0.8-0.3,1.5-0.8,1.9c-0.5,0.5-1.4,0.5-2.2,0.8c-0.8,0.1-1.6,0.3-1.6,0.3h-16.4
	c-0.1,0.8-0.4,1.5-0.5,2.3l15.4,0.1c0.1,0,0.3,0.1,0.4,0.3c0,0,0.6,0.7,1.1,1.5c0.5,0.8,1.1,1.6,1.2,2.3v0.5c0,0.8-0.3,1.5-0.8,1.9
	c-0.5,0.5-1.4,0.5-2.2,0.8c-0.8,0.1-1.5,0.3-1.5,0.3h-15.5l-0.7,2.8l13.9,0.1c0.1,0,0.4,0.1,0.4,0.3c0,0.1,1.9,2.6,2.2,3.9v0.6
	c0,0.8-0.3,1.5-0.8,1.9c-0.5,0.5-1.4,0.5-2.2,0.8c-0.8,0.1-1.5,0.3-1.6,0.3h-13.9c-0.4,1.9-0.7,3.2-0.8,3.2c0,0.1-0.1,0.3-0.3,0.4
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.3c0-0.1-0.1-0.3-0.1-0.3v-0.1c0,0,0.1-0.5,0.4-1.6l0.3-1.2h-15.7l-0.5,3.6
	c0,0.3-0.4,0.5-0.7,0.5s-0.5-0.4-0.4-0.7l0.5-3.3h-15c-0.1,1.4-0.1,2.5-0.3,3.2v0.7c0,0.1-0.1,0.3-0.1,0.4
	C436.6,322.8,436.5,322.8,436.2,322.8C436.4,322.8,436.2,322.8,436.2,322.8 M454.6,310.6l16.2,0.1l0.7-2.8h-16.5L454.6,310.6
	L454.6,310.6z M437.9,310.4l15.5,0.1l0.4-2.5H438L437.9,310.4L437.9,310.4z M456.3,300l17.1,0.1c0.1-0.8,0.4-1.5,0.5-2.3h-17.2
	L456.3,300L456.3,300z M438.7,299.8l16.5,0.1l0.4-2.2h-16.8L438.7,299.8L438.7,299.8z M458,289.9l17.7,0.1c0.3-1,0.4-1.8,0.7-2.6
	h-18L458,289.9L458,289.9z M439.5,289.9l17.3,0.1l0.4-2.6h-17.5L439.5,289.9L439.5,289.9z M420.8,164.9c0,0,49.7,0,101.8,5
	c2.9,0.3,6.9,2.6,11.1,9.2c4.3,6.6,22.6,49.5,23.4,51.7c0.8,2.2,1.7,5-2.3,4.7c-10.2-0.8-133.9-4.4-133.9-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M510.6,274.8c-1.4,4.1-8.9,34.8-16.4,48.7c-2.1,3.9-6.5,11.3-24.9,12.2
	s-48.3,1.6-48.3,1.6 M507.3,274.8l11.6-0.5 M504.7,271.1c0,0,8.9-28.5,16-34.8c2.1-1.9,3.3-1.4,0.4,5c-2.9,6.3-10.6,33.6-10.6,33.6
	 M508.7,386.4l-16.9,5l-8.3-0.1l-8.4-4.5 M496.1,395.5l-75.5,0.8 M444.1,387.5c11,0,22.6,0.1,25.7,0.3c4.7,0.3,5.1-1.2,5.2-2.9v-2.6
	c-0.1-1.9-0.8-2.5-3.3-2.5h-26.8C444.7,380.9,444.3,384.7,444.1,387.5L444.1,387.5z M420.8,379.8c0,0,10,0.1,20.9,0.1
	c-0.1,1.2-0.8,6.2-0.8,7.6h-19.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.9,377.4c0,0,59,0.4,60.7,0.4s5-0.7,4.1,3.7c-0.7,4.4-1,5.9-1.1,7.3
	c-0.5,3.2-1.8,4.5-4.7,4.7c-4.5,0.1-58.9,0.1-58.9,0.1 M498.1,275.1c0.5,0,9.4-0.4,9.4-0.4 M507.2,274.8c-1.4,4.1-8,25.6-15.4,39.5
	c-2.2,4-6.5,11.3-24.9,12.2c-18.4,1-46.2,1.6-46.2,1.6 M335.8,164.5l9.5-1 M496.9,163.7l9.5,0.8 M421.2,297.4v3.2 M421.2,287.3v3.2
	 M421.4,318l-0.1,4.3 M421.4,307.7l-0.1,3.4 M286.4,226.9c0,0-1.2,0.6-2.5,1.2c-1.2,0.7-2.3,1.5-2.3,1.5 M555.8,227.2
	c0,0,1.2,0.3,2.3,0.8c1.2,0.6,2.3,1.4,2.3,1.4 M326.9,165.2c0,0-10.3,2.3-12.5,7.2 M516,165.3c0,0,8.3,1.4,12.1,7.2 M577.4,278.4
	c0.3,2.6,1.1,9.9,0.3,18.4c-0.8,8.5-9.1,12.5-16.2,13.5c-7.2,0.8-14.3-2.9-14.3-2.9s-26.7,0.1-31.4,0.1c-4.7-0.1-4.5-1.5-4.5-4.1
	s3.4-21.5,6.7-29c0.7-1.6,1.9-3.2,6.1-3.9s31.1-4.3,33.2-3.4 M340.1,366.8l161.5,0.3 M480.9,273.8c-2.8,0.4-6.5,0.5-7.2,0.5
	c-0.1,0.1-1.4,1.2-5,1.6c-2.8,0.4-6.3,0.4-8,0.4h-1c0,0-1.1,1-3.7,1.4s-8,0.5-8,0.5l-9.4,0.4c0,0-3.3,0-5.4,1.5
	c-2.1,1.6-4.4,3.3-6.7,4.3c-1.8,0.7-4.3,0.8-5.2,0.8h-0.4c-1,0-3.4-0.1-5.2-0.8c-2.3-0.8-4.7-2.6-6.7-4.3s-5.4-1.5-5.4-1.5l-9.4-0.4
	c0,0-5.4-0.1-8-0.5s-3.7-1.4-3.7-1.4h-1c-1.7,0-5.2-0.1-8-0.4c-3.4-0.4-4.8-1.5-5-1.6c-0.7,0-4.4-0.1-7.2-0.5
	c-3.2-0.4-4.3-1.4-4.3-1.4v-0.1c1.2,0.4,2.9,0.5,3.4,0.5s8.5,0.3,8.5,0.3l16.6,0.3h23.7l0.3,0.3l-0.5,0.4c-0.5,0.3-1.1,0.3-1.1,0.3
	l-4.8-0.1h-8.9c0,0-9.1-0.1-15.4-0.1c-6.3-0.1-9.2,0-9.8,0c-0.3,0-0.4,0.1-0.4,0.1c0.3,0.3,1,0.7,2.2,0.7c1.7,0.1,10.2,0.3,10.2,0.3
	s9.1,0.1,10.3,0.1s8.4,0.1,11.7,0.1h6.2c0,0,0.4,0.1,0.1,0.3c-0.3,0.3-0.7,0.4-0.7,0.4s-0.3,0.1-0.8,0.1c-0.5,0-11.3-0.1-13.5-0.1
	c-2.2,0-9.1-0.1-10.3-0.1c-1,0-1.4,0.1-1.5,0.1c1.4,0.5,5.5,0.5,5.5,0.5s17.6,0.5,19.4,0.5c1.8,0.1,3.2,1.5,3.7,2.2
	c0.7,0.7,2.2,2.1,4.4,3c1.6,0.7,4.5,0.8,5.6,1c1.1,0,3.9-0.1,5.6-1c2.1-1,3.7-2.2,4.4-3c0.7-0.7,1.9-2.2,3.7-2.2
	c1.8-0.1,19.4-0.5,19.4-0.5s4.1,0,5.5-0.5c-0.1-0.1-0.5-0.1-1.5-0.1c-1.4,0-8.1,0.1-10.3,0.1s-12.9,0.1-13.5,0.1s-0.8-0.1-0.8-0.1
	s-0.3-0.1-0.7-0.4c-0.3-0.3,0.1-0.3,0.1-0.3h6.2c3.2,0,10.5,0,11.7-0.1c1.2,0,10.3-0.1,10.3-0.1s8.4-0.3,10.2-0.3
	c1.2,0,1.9-0.5,2.2-0.7c0-0.1-0.1-0.1-0.4-0.1c-0.7,0-3.4-0.1-9.8,0s-15.4,0.1-15.4,0.1h-8.9l-4.8,0.1c0,0-0.5,0.1-1.1-0.3
	c-0.5-0.3-0.5-0.4-0.5-0.4s-0.3-0.1,0.3-0.3H457l16.6-0.3c0,0,8.1-0.3,8.7-0.3s2.1-0.1,3.4-0.5v0.1
	C485.2,272.5,483.9,273.4,480.9,273.8L480.9,273.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M357.5,272.2c0.8,0,2.8-0.1,2.8-0.1l16.9,0.4c0,0,26.7,0.1,29.9,0.1
	s5.6-1.5,8.5-2.6c2.8-1,5.6-1,5.9-1s3.2-0.1,5.9,1c2.8,1,5.4,2.5,8.4,2.6c3.2,0,29.7-0.1,29.7-0.1l17.1-0.5c0,0,1.5,0.1,2.6,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M411.6,276.7c0-3.2,4.3-5.6,9.5-5.6c5.2,0,9.5,2.5,9.5,5.6
	s-4.3,5.6-9.5,5.6C415.9,282.4,411.6,279.9,411.6,276.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M426.2,272.9c1.2,1,1.8,2.3,1.8,4c-0.1,1.9-0.5,3.2-1.5,3.9
	c-0.1,0.1-0.1,0.3,0.1,0.1c2.1-1,3.3-2.3,3.3-4s-1.4-3.2-3.6-4.1C426,272.5,425.9,272.6,426.2,272.9L426.2,272.9z M414.3,277
	c-0.1-1.8,0.5-3.3,1.8-4.3c0.3-0.3,0.3-0.4-0.1-0.1c-2.2,1-3.6,2.5-3.6,4.1s1.4,3.2,3.4,4.1c0.3,0.1,0.4,0,0.1-0.1
	C415,279.9,414.5,278.8,414.3,277L414.3,277z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M415.7,276.9c0-2.8,2.3-5,5.4-5c2.9,0,5.4,2.2,5.4,5c0,2.8-2.3,5-5.4,5
	C418.2,281.8,415.7,279.6,415.7,276.9z M586.6,358.3l-6.3-3 M261.5,354.3l-7,3.6"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M287.2,235.5l8.4-0.4l60-2.1
		l65.3-1.9l77.7,2.3l47.9,1.7l7.8,0.4c0,0,2.6,0.4,3.2-1c0,0,0.7-0.8-0.6-3.9c0,0-9.2-21.9-13.8-31.6c0,0-2.8-6.2-7.6-16.1
		c0,0-5.1-11.6-13.3-13.1c0,0-21.7-2.3-64.5-4.1c0,0-24.6-1-37-0.8c0,0-20.6-0.1-43.9,1.1c0,0-29.9,1-57.8,3.9c0,0-2.6,0.3-5.2,2.3
		c0,0-3.3,2.6-6.1,6.9c0,0-3.2,5.5-7.7,15.8l-14,32.1c0,0-1.8,4.1-1.9,5c0,0-1,2.6,0.7,3.3C285.2,235.3,285.9,235.6,287.2,235.5z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M282.7,266.7c0,0,0.1-20.4,0.3-20.8
		c0,0,0-4,2.6-5.5l10.2-5.4l29.4-1l34.5-1.1l35.8-1.1l25.4-0.8l31.9,1l32.3,1l27.5,1l33.8,1.2l8.7,4.5c0,0,2.8,1.1,3.4,3.2
		c0,0,0.6,1.2,0.7,4l0.3,19.8l-2.8,0.4c0,0-1-0.4-8.1,0.4c0,0-17.9,1.9-22,2.6c0,0-5.4,0.4-6.9,1.9c0,0-1.1,0.8-1.8,2.3l-10.7,0.4
		c0,0,0.3-1.8-1.2-2.9c0,0-0.7-1-4.1-0.8l-25.7-0.4l-45.4-0.4c0,0-2.2,0.1-3.3-1.1c0,0-1.1-1.4-3.7-1.7c0,0-1.8-0.3-2.8-0.3
		c0,0-1.8,0-4,0.5c0,0-1.7,0.4-2.5,1.4c0,0-1,1.1-2.8,1.1l-18.7,0.1l-22.3,0.3l-21.7,0.3l-9.9,0.3c0,0-2.3-0.1-3.7,1.1
		c0,0-1,0.8-0.8,2.6l-10.9-0.4c0,0-0.8-2.1-2.3-2.8c0,0-1.2-0.8-3.7-1.1c0,0-8.7-1.4-16.5-2.2c0,0-14-1.8-16.6-1.2L282.7,266.7z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M295.2,302.6
		l17.6,0.4c0,0,9.5,0.3,11.8,0.1c0,0,2.6,0,3.4-1.2c0,0,0.5-0.4,0.4-2.3c0,0,0.3-2.2-1.2-10.3c0,0-1-5.4-2.6-11.1c0,0-1-3-2.1-4.5
		c0,0-1-1.6-4.3-2.2c0,0-2.6-0.8-14.6-2.2l-21.3-2.6c0,0-2.9-1.4-5.5-1.4c0,0-2.3-0.3-5.8,1.1c0,0-4.4,1.7-5.5,3.6
		c0,0-0.5,0.7-0.8,2.6c0,0-0.5,6.1-0.7,7.3c0,0-0.5,6.7,0.1,12c0,0,0.4,5.2,4.4,8.5c0,0,4.5,4.5,13.1,5.1
		C282.3,305.6,288.1,306.2,295.2,302.6z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M547,302.6
		c0,0-13.1,0.3-27.5,0.5c0,0-3.4,0.1-4.5-0.5c0,0-1.4-0.3-1.2-2.9c0,0-0.1-4.7,1.9-13.6c0,0,1.9-9.2,3.3-11.3c0,0,1.2-2.8,4.3-3.2
		c0,0,3.2-0.8,17.1-2.6l19.5-2.3c0,0,1.9-0.8,3.3-1.1c0,0,2.9-0.8,5.2,0c0,0,3.2,0.7,6.2,2.8c0,0,2.2,1.2,2.5,3.9c0,0,1,7.8,1,13.3
		c0,0,0.1,6.7-1,9.8c0,0-1,3.7-5.4,6.7c0,0-3.9,3-11.3,3.7c0,0-4.1,0.3-8.8-1.2C551.2,304.4,548.9,303.6,547,302.6z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M588.8,320.1h-8.1v-2.5
		c0,0-2.5,3-6.3,4.3c0,0-2.8,1.2-6.1,1.1c0,0,0.1-0.6-0.7-0.6l-73.6,1.6c0,0-2.8,5.5-8.3,8c0,0-4.8,2.8-14.2,3.6
		c0,0-35.6,1.7-50.2,1.8c0,0-26.8-0.7-45.4-1.5c0,0-6.3-0.1-9.5-0.8c0,0-6.7-0.8-11.8-4.1c0,0-4-2.3-6.2-6.7l-29.7-0.7l-44.2-1
		c0,0-0.5,0-0.7,0.6c0,0-4-0.1-7.2-1.6c0,0-3-1.2-5.1-3.9v2.6h-8.1c0,0-0.4,22.4,0.3,31.1l1.1,6.6c0,0,1.5,23.9,3.3,31.1
		c0,0,0.8,7.2,10.2,9.2c0,0,1.1,6.3,2.3,8.1c1.2,1.8,3.2,3,6.9,3.2c0,0,10.2,1.4,17.3,1.6c0,0,36.3,1.1,47.9,1.4l76.6,1.4h4.3
		c0,0,53.1-0.8,77.2-1.4c0,0,35.9-1,37.7-1c1.8,0,7.7-0.1,16.9-0.8c0,0,5.5-0.4,11-1.2c0,0,4.4,0,6.1-4.3c0,0,0.5-0.8,1.8-6.9
		c0,0,7-1.5,9.1-6.2c0,0,1.7-2.2,2.6-13.3l1.9-21.3l0.8-6.1c0,0,0.5-6.1,0.4-17.9L588.8,320.1z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M334.7,274.7c0,0-0.5-3.9,4.5-3.7
		c0,0,18.4-0.4,30.8-0.5l41.7-0.4c0,0,1.8,0.1,3.2-1.5c0,0,0.8-1.2,6.1-1.5c0,0,4-0.1,6.1,1.5c0,0,1.2,1.5,3.4,1.5l33.6,0.3
		c0,0,23.5,0.3,38.2,0.5c0,0,5-0.5,5.1,3.7c0,0-2.9,11.1-5.1,18.2c0,0-2.2,8.1-7.3,20.4c0,0-3.3,7.7-5.5,9.9c0,0-4.5,6.3-17.2,7.8
		c0,0-2.2,0.3-6.5,0.6c0,0-39.8,1.5-44.8,1.6c0,0-19.9-0.7-45.9-1.6c0,0-11.4-0.1-18.4-4.8c0,0-4.5-2.5-7.7-9.6
		c0,0-5.2-10.7-8.9-23.7c0,0-4.8-15.8-5.2-18.3"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(SportsVanFront);
