import { FETCH_API } from '../../middleware';

export const UPDATE_SELECTED_CONDITION = 'UPDATE_SELECTED_CONDITION';

export const updateSelectedCondition = data => ({
  type: UPDATE_SELECTED_CONDITION,
  response: data,
});

export const RESET_SELECTED_CONDITION = 'RESET_SELECTED_CONDITION';

export const resetSelectedCondition = () => ({
  type: RESET_SELECTED_CONDITION,
});

export const CONDITION_AREAS_REQUEST = 'CONDITION_AREAS_REQUEST';
export const CONDITION_AREAS_SUCCESS = 'CONDITION_AREAS_SUCCESS';
export const CONDITION_AREAS_FAILURE = 'CONDITION_AREAS_FAILURE';

const fetchConditionAreas = apiKey => {
  const endpoint = `get-cr-areas?apiKey=${apiKey}&crtype=Standard`;
  return {
    [FETCH_API]: {
      types: [
        CONDITION_AREAS_REQUEST,
        CONDITION_AREAS_SUCCESS,
        CONDITION_AREAS_FAILURE,
      ],
      endpoint,
    },
  };
};

export const loadConditionAreas = apiKey => dispatch =>
  dispatch(fetchConditionAreas(apiKey));

export const CONDITION_PARTS_REQUEST = 'CONDITION_PARTS_REQUEST';
export const CONDITION_PARTS_SUCCESS = 'CONDITION_PARTS_SUCCESS';
export const CONDITION_PARTS_FAILURE = 'CONDITION_PARTS_FAILURE';

const fetchConditionParts = (area, crType, apiKey) => {
  return {
    [FETCH_API]: {
      types: [
        CONDITION_PARTS_REQUEST,
        CONDITION_PARTS_SUCCESS,
        CONDITION_PARTS_FAILURE,
      ],
      endpoint: `get-cr-parts?apiKey=${apiKey}&area=${area}&crtype=${crType}`,
      core: true,
    },
  };
};

export const loadConditionParts = (area, crType, apiKey) => dispatch =>
  dispatch(fetchConditionParts(area, crType, apiKey));

export const CONDITION_DAMAGES_REQUEST = 'CONDITION_DAMAGES_REQUEST';
export const CONDITION_DAMAGES_SUCCESS = 'CONDITION_DAMAGES_SUCCESS';
export const CONDITION_DAMAGES_FAILURE = 'CONDITION_DAMAGES_FAILURE';

const fetchConditionDamages = (area, part, crType, apiKey) => {
  return {
    [FETCH_API]: {
      types: [
        CONDITION_DAMAGES_REQUEST,
        CONDITION_DAMAGES_SUCCESS,
        CONDITION_DAMAGES_FAILURE,
      ],
      endpoint:
        `get-cr-damages?apiKey=${apiKey}&area=${area}` +
        `&part=${part}&crtype=${crType}`,
      core: true,
    },
  };
};

export const loadConditionDamages = (area, part, crType, apiKey) => dispatch =>
  dispatch(fetchConditionDamages(area, part, crType, apiKey));

export const CONDITION_DAMAGE_ACTIONS_REQUEST =
  'CONDITION_DAMAGE_ACTIONS_REQUEST';
export const CONDITION_DAMAGE_ACTIONS_SUCCESS =
  'CONDITION_DAMAGE_ACTIONS_SUCCESS';
export const CONDITION_DAMAGE_ACTIONS_FAILURE =
  'CONDITION_DAMAGE_ACTIONS_FAILURE';

const fetchConditionDamageActions = (area, part, damage, crType, apiKey) => {
  const endpoint =
    `get-cr-damage-actions?apiKey=${apiKey}&area=` +
    `${area}&part=${part}&damage=${damage}&crType=${crType}`;
  return {
    [FETCH_API]: {
      types: [
        CONDITION_DAMAGE_ACTIONS_REQUEST,
        CONDITION_DAMAGE_ACTIONS_SUCCESS,
        CONDITION_DAMAGE_ACTIONS_FAILURE,
      ],
      endpoint,
      core: true,
    },
  };
};

export const loadConditionDamageActions = (
  area,
  part,
  damage,
  crType,
  apiKey
) => dispatch =>
  dispatch(fetchConditionDamageActions(area, part, damage, crType, apiKey));

export const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';

export const resetUploadFiles = () => ({
  type: RESET_UPLOAD_FILES,
});

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';

const fetchUploadFiles = (body, fileType, apiKey) => ({
  [FETCH_API]: {
    types: [UPLOAD_FILES_REQUEST, UPLOAD_FILES_SUCCESS, UPLOAD_FILES_FAILURE],
    endpoint:
      `upload-files?keepFileNames=true` +
      `&apiKey=${apiKey}&fileType=${fileType}`,
    core: true,
    init: {
      method: 'POST',
      body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    },
  },
});

export const loadUploadFiles = (body, fileType, apiKey) => dispatch =>
  dispatch(fetchUploadFiles(body, fileType, apiKey));

export const LOAD_PRESELECTED_DAMAGES = 'LOAD_PRESELECTED_DAMAGES';

export const loadPreselectedDamages = item => {
  const conditions = item.condition ? item.condition : [];
  const data = {};
  for (let i = 0; i < conditions.length; i++) {
    const area = conditions[i].area;

    if (area === 'Grille') {
      data.grilleColor = true;
    }

    if (area === 'Front Bumper') {
      data.frontBumperColor = true;
    }

    if (area === 'Left Front - Headlights') {
      data.leftFrontHeadlightsColor = true;
    }

    if (area === 'Right Front - Headlights') {
      data.rightFrontHeadlightsColor = true;
    }

    if (area === 'Windshield') {
      data.windshieldColor = true;
    }

    if (area === 'Left Front - Fender') {
      data.leftFrontFenderColor = true;
    }

    if (area === 'Left Front Door') {
      data.leftFrontDoorColor = true;
    }

    if (area === 'Left Front Door Glass') {
      data.leftFrontDoorGlassColor = true;
    }

    if (area === 'Left Qtr Glass') {
      data.leftQtrGlassColor = true;
    }

    if (area === 'Left Rear - Quarter Panel') {
      data.leftRearQuarterPanelColor = true;
    }

    if (area === 'Left Rear Door') {
      data.leftRearDoorColor = true;
    }

    if (area === 'Left Rear Door Glass') {
      data.leftRearDoorGlassColor = true;
    }

    if (area === 'Left Side Mirror') {
      data.leftSideMirrorColor = true;
    }

    if (area === 'Wheels And Tires - Left Front') {
      data.tiresLeftFrontColor = true;
    }

    if (area === 'Wheels And Tires - Left Rear') {
      data.tiresLeftRearColor = true;
    }

    if (area === 'Deck Lid') {
      data.deckLidColor = true;
    }

    if (area === 'Liftgate and Tailgate') {
      data.liftgateTailgateColor = true;
    }

    if (area === 'Rear Bumper') {
      data.rearBumperColor = true;
    }

    if (area === 'Rear Glass') {
      data.rearGlassColor = true;
    }

    if (area === 'Left Tail Light') {
      data.leftTailLightColor = true;
    }

    if (area === 'Right Tail Light') {
      data.rightTailLightColor = true;
    }

    if (area === 'Right Front - Fender') {
      data.rightFrontFenderColor = true;
    }

    if (area === 'Right Front Door') {
      data.rightFrontDoorColor = true;
    }

    if (area === 'Right Front Door Glass') {
      data.rightFrontDoorGlassColor = true;
    }

    if (area === 'Right Qtr Glass') {
      data.rightQtrGlassColor = true;
    }

    if (area === 'Right Rear - Quarter Panel') {
      data.rightRearQuarterPanelColor = true;
    }

    if (area === 'Right Rear Door') {
      data.rightRearDoorColor = true;
    }

    if (area === 'Right Rear Door Glass') {
      data.rightRearDoorGlassColor = true;
    }

    if (area === 'Right Side Mirror') {
      data.rightSideMirrorColor = true;
    }

    if (area === 'Wheels And Tires - Right Front') {
      data.tiresRightFrontColor = true;
    }

    if (area === 'Wheels And Tires - Right Rear') {
      data.tiresRightRearColor = true;
    }

    if (area === 'Hood') {
      data.hoodColor = true;
    }

    if (area === 'Roof') {
      data.roofColor = true;
    }

    if (area === 'Truck Bed') {
      data.truckBedColor = true;
    }
  }

  return {
    type: LOAD_PRESELECTED_DAMAGES,
    response: data,
  };
};

export const CR_TYPES_REQUEST = 'CR_TYPES_REQUEST';
export const CR_TYPES_SUCCESS = 'CR_TYPES_SUCCESS';
export const CR_TYPES_FAILURE = 'CR_TYPES_FAILURE';

const fetchCrTypes = (accountNumber, apiKey) => ({
  [FETCH_API]: {
    types: [CR_TYPES_REQUEST, CR_TYPES_SUCCESS, CR_TYPES_FAILURE],
    endpoint: `get-cr-types?apiKey=${apiKey}&accountNumber=${accountNumber}`,
    core: true,
  },
});

export const loadCrTypes = (accountNumber, apiKey) => dispatch =>
  dispatch(fetchCrTypes(accountNumber, apiKey));

export const RESET_CR_TYPES = 'RESET_CR_TYPES';

export const resetCrTypes = statusOnly => ({
  type: RESET_CR_TYPES,
});
