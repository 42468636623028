import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M177.5,155.8c-0.3-0.3-0.7-0.6-1.1-0.7l-0.7-0.1c-0.6-0.1-0.8,0-1,0.3h-0.1v0.1c-0.1,0.3-0.1,0.7,0,1.1v0.1
	l0.4,0.6l0.8,0.8l1.5,1l18.7,15.1c3.9,3.3,7.6,7,11,11.1l4.8-2.5c-3.6-1.9-6.7-4.1-9.6-6.6l-23.5-19.1
	C178.1,156.5,177.7,156.1,177.5,155.8L177.5,155.8z M194.7,196.8l-11.1,1.8l-4.7,0.8c-2.5,0.6-4.1,0-4.5-1.9l-1.1-3.4l-0.3-0.8
	c-7.3,1.4-14.4,2.9-21.3,4.4c2.3,5.4,4.8,10.9,7.7,16.5l2.8,5.1l1.9,3.4l2.1,3.6c4.3-1.9,8.5-3.7,13.1-5.4
	c6.9-7.6,12.1-15.1,15.7-22.8L194.7,196.8L194.7,196.8z"
          />
          <path
            fill="#FFFFFF"
            d="M194.7,198c-3.6,7.6-8.9,15.1-15.7,22.8l2.3,8.7c0.3,1.2,0.4,2.3,0.4,3.4c0,1.2-0.3,2.3-0.8,3.4l-16,23.9
	c-1.5,2.3-3.2,4.3-5.2,5.6c-3.4,7.4-7.8,14.2-13.1,20.2l8.5,15.8c12.9-9.4,28.1-14,45.1-14c8.4,0,16.2,1.1,23.5,3.3
	c-1-1.2-1.9-3-2.8-5.2l-8.3-19.4l-13.3-1.6l12.9,0.8c0.3-7,1.5-13.9,3.7-20.5c-1.6-0.4-3.2-1.1-4.8-2.1l-2.2-2.1
	c-1.2-1.1-2.6-2.2-3.9-3.6c-4.3-4.8-8.4-10.3-12.2-16.4c-1.8-2.9-1.9-5.2-0.3-7c0.8-0.8,1.9-1.5,3.4-2.1l3.2-0.8
	c13.5-3.6,27.4-6.5,41.5-9.1c37.7-6.6,77.9-9.5,120.5-8.7l9.9,0.3c50.5,2.6,100.4,16,149.7,40c11.8,5.8,23.7,12.2,35.5,19.3
	c8.5,5.2,16.8,10.9,24.8,16.8l5.2-12.4c-2.5-0.7-4.8-1.6-7-2.6l-0.3-0.1c-22.3-11.1-42.1-20.8-59.4-28.9
	c-17.5-8.3-35.8-15.7-55-22.6l-4.3-1.5c-1.1-0.4-1.9-0.8-2.3-1.4c-0.4-0.3-0.5-0.6-0.7-0.8l-19-4.3c-22.3-5.1-44.7-8.3-67-9.4
	l-12-0.3c-51.6-0.8-100.1,2.1-145.8,8.7l-18.8,2.6L194.7,198L194.7,198z M175.1,289.5c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9
	c-1.4-1.4-2.2-2.9-2.6-4.5c-0.1-0.8-0.3-1.5-0.3-2.5c0-0.7,0-1.4,0.1-1.9c0.4-1.9,1.2-3.6,2.8-5.1c1.9-1.9,4.3-2.9,7-2.9
	c2.8,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7C178,285.3,177,287.6,175.1,289.5L175.1,289.5z"
          />
          <path
            fill="#FFFFFF"
            d="M178,282.5c0-2.8-1-5.1-2.9-7c-1.9-1.9-4.3-2.9-7-2.9c-2.8,0-5.1,1-7,2.9c-1.5,1.5-2.3,3.2-2.8,5.1h0.1
	c1.1,0,2.2,0.3,3,0.7s1.2,1,1.2,1.5s-0.4,1.1-1.2,1.5c-0.8,0.4-1.8,0.7-3,0.7h-0.1c0.4,1.8,1.4,3.3,2.6,4.5c1.9,1.9,4.3,2.9,7,2.9
	c2.8,0,5.1-1,7-2.9C177,287.6,178,285.3,178,282.5z"
          />
          <path
            fill="#FFFFFF"
            d="M162.6,282.8c0-0.5-0.4-1.1-1.2-1.5c-0.8-0.4-1.8-0.7-3-0.7h-0.1c-0.1,0.5-0.1,1.2-0.1,1.9
	c0,0.8,0.1,1.6,0.3,2.5h0.1c1.1,0,2.2-0.3,3-0.7C162.2,283.9,162.6,283.3,162.6,282.8L162.6,282.8z M194.7,196l18.8-2.6
	c45.7-6.6,94.2-9.5,145.8-8.7l12.1,0.1c22.3,1.1,44.7,4.3,67,9.4l19.7,4.5l3.6-3.7c-36.3-8-65.1-12.7-85.8-13.9l-4.3-0.3
	c-14.2-0.8-28.5-1.2-42.9-1.2c-34.7,0.4-69.2,2.3-103.6,5.8l-9.1,0.8l-9.8,1.4l-11.1,1.8L194.7,196L194.7,196z"
          />
          <path
            fill="#FFFFFF"
            d="M195.1,189.4l-16.9,2.9l0.3,1l1.2,3.3c0.4,1.6,1.8,2.3,3.9,2.1l11.1-1.8V196L195.1,189.4L195.1,189.4z"
          />
          <path
            fill="#656565"
            d="M194.7,196.8l18.8-2.6c45.7-6.6,94.2-9.5,145.8-8.7l12,0.3c22.3,1.1,44.7,4.3,67,9.4l19,4.3l0.6-0.6
	l-19.7-4.5c-22.3-5.1-44.7-8.3-67-9.4l-12-0.3c-51.6-0.8-100.1,2.1-145.8,8.7l-18.8,2.6L194.7,196.8L194.7,196.8z"
          />
          <path
            fill="#FFFFFF"
            d="M173.1,194.1l1.1,3.4c0.6,1.8,2.1,2.5,4.5,1.9l4.7-0.8c-2.1,0.3-3.4-0.4-3.9-2.1l-1.2-3.3L173.1,194.1
	L173.1,194.1z"
          />
          <path
            fill="#656565"
            d="M161.9,219.2c0,1-1,1.9-2.9,2.9l-25.2,16.1c9.8-5.6,19.8-10.9,29.9-15.5L161.9,219.2L161.9,219.2z"
          />
          <path
            fill="#EBEBEB"
            d="M159.2,214c-19.3,12.1-36.5,25.3-51.6,39.9c2.6-1.2,5.1-2.8,7.3-4.3c6.1-3.9,12.2-7.6,18.3-11l0.7-0.4
	l25-16.1c1.9-1.1,2.9-2.1,2.9-2.9L159.2,214L159.2,214z"
          />
          <path
            fill="#FFFFFF"
            d="M156.7,230.6c2.9-1.5,5.9-3,9.1-4.4l-2.1-3.6c-10,4.8-20.1,10-29.9,15.5l-0.7,0.3c-6.2,3.6-12.2,7.3-18.3,11
	l-6.1,10c-0.6,0.8-0.8,1.6-1.1,2.6c-0.3,1.1-0.6,2.2-0.6,3.4l-0.3,6.1l-0.3,6.1c-1.8,10.7-4.1,20.5-7,29.3c2.2-2.8,4.3-5.8,6.2-9.4
	c2.3-4.8,4.5-10,6.3-15.7l1-3.2c0.4-1.2,0.7-2.5,0.8-3.7c0.1-1.4,0.3-2.8,0.3-4.1v-9.4c6.1-6.3,12.7-12.1,20.1-17.5l4-2.9l0.4-0.3
	c5.5-3.7,11.4-7.3,17.7-10.5L156.7,230.6L156.7,230.6z"
          />
          <path
            fill="#3296BA"
            d="M156.4,230.6c-6.3,3.3-12.2,6.7-17.7,10.5l-0.4,0.3l1.1,0.1l0.6-0.4c5.2-3.6,10.7-7,16.4-10.2l0.3-0.1
	c3-1.6,6.1-3.3,9.1-4.7c-3.2,1.4-6.2,2.9-9.1,4.4L156.4,230.6L156.4,230.6z"
          />
          <path
            fill="#D2D3D4"
            d="M156.4,231.1c-5.8,3.2-11.3,6.6-16.4,10.2l-0.6,0.4l2.6,0.4l4.1-2.6c1-0.6,1.9-0.8,3.2-0.8h0.5
	c5.5,0.4,10.7,1.2,15.5,2.2c3.2,0.7,4,2.2,2.3,4.5l-8.3,10l-1.1,1.2l-1.5,1.8l-1,1.2l-1.2,1.6l-0.8,1.2l-0.7,1.4
	c-1.1,2.6-2.5,4.4-4.3,5.4c-1.4,0.8-4.7,2.3-10.2,4.4c-2.8,1-6.1,2.2-10,3.4c5-1.5,9.8-3,14.4-4.7c4.5-1.6,8.9-3.3,13.2-5.1
	c1.1-0.4,2.2-1,3-1.5c2.1-1.4,3.9-3.2,5.2-5.6l16-23.9c0.6-1.1,0.8-2.2,0.8-3.4c0-1.1-0.1-2.2-0.4-3.4l-2.3-8.7
	c-4.5,1.6-8.8,3.4-13.1,5.4c-3.2,1.5-6.2,3-9.1,4.7L156.4,231.1L156.4,231.1z M152.6,236.8l-2.1-0.1l2.6-1.5l2.5-1.2l3.3-1.8
	l2.6-1.2l6.2-2.9c3.4-1.5,6.9-2.9,10.5-4.4l0.3,1.1l1.2,4.7c0.3,1.1,0.4,2.2,0.4,3.3c0,0.6-0.1,1-0.1,1.5c-0.1,0.6-0.3,1.2-0.6,1.8
	l-0.1,0.1l-1,1.4l-1.1,1.5c-0.7,0.6-1.7,0.8-3.2,0.7c-6.5-1.2-13.5-2.2-21.2-2.9L152.6,236.8L152.6,236.8L152.6,236.8z"
          />
          <path
            fill="#D2D3D4"
            d="M152.8,236.8l2.8-1.5c-0.1,0-0.1-0.1-0.3-0.1l-2.2-0.1l-2.6,1.5l2.1,0.1h0.3L152.8,236.8L152.8,236.8z
	 M161.5,230.9l-2.6,1.2l2.2,0.1h0.1c6.2,0.4,12.4,1.1,18.6,1.9c0.1-0.6,0.1-1,0.1-1.5c-5.4-0.7-10.7-1.2-16.1-1.6h-0.1L161.5,230.9
	L161.5,230.9z"
          />
          <path
            fill="#D2D3D4"
            d="M163.7,231.1h0.1c1.5-0.8,3.2-1.5,4.8-2.2c3.2-1.4,6.3-2.8,9.8-4.1l-0.3-1.1c-3.6,1.4-7,2.9-10.5,4.4
	l-6.2,2.9L163.7,231.1L163.7,231.1z M161.1,232.3l-2.2-0.1l-3.3,1.8l2.2,0.1h0.3l1.9-1.1c0.4-0.3,0.8-0.4,1.2-0.6h-0.1L161.1,232.3
	L161.1,232.3z"
          />
          <path
            fill="#D2D3D4"
            d="M160,233l-1.9,1.1c7.2,0.4,14.2,1.1,21.2,2.1l0.1-0.1c0.3-0.6,0.4-1.1,0.6-1.8c-6.1-0.8-12.2-1.5-18.6-1.9
	C160.8,232.4,160.4,232.7,160,233L160,233z"
          />
          <path
            fill="#D2D3D4"
            d="M155.6,235.3c7.7,0.4,15.3,1.1,22.7,2.2l1-1.4c-7-1-14-1.6-21.2-2.1h-0.3l-2.2-0.1l-2.5,1.2l2.2,0.1H155.6z"
          />
          <path
            fill="#D2D3D4"
            d="M152.8,236.8c7.7,0.7,14.9,1.6,21.2,2.9c1.4,0.1,2.5-0.1,3.2-0.7l1.1-1.5c-7.4-1.1-15-1.8-22.7-2.2
	L152.8,236.8z M179.7,229.4l-1.2-4.7c-3.3,1.2-6.6,2.6-9.8,4.1c-1.6,0.7-3.2,1.5-4.8,2.2c5.5,0.4,10.9,1,16.1,1.6
	C180.1,231.6,179.9,230.5,179.7,229.4L179.7,229.4z M161.4,243.6c-4.8-1-10-1.8-15.5-2.2h-0.6c-1.1,0-2.1,0.1-2.9,0.6l16.5,2.2
	c1.1,0.4,1.1,1.4-0.1,2.8c-1.5,1.8-2.9,3.6-4.4,5.2l-7.8,8.1l-2.2,2.1l-3.4,3l-5.5,3.4l-3.6,1.8l-0.8,0.3l-2.5,1.1
	c-3.4,1.2-7.2,2.3-11.1,3.2l-0.8,0.1v0.1l0.4,3c9.2-2.5,17.2-5.2,23.8-8.4c4.8-1.8,7.8-4.1,9.1-6.7l0.4-0.8l-1.2-0.1l-1.5-1.4
	l3.6,0.3l1.1-1.6H152l-1.5-1.5l2.8,0.1l1.5-1.8l-1.5-1.5l2.6,0.1l7.7-9.1c0.6-0.7,0.4-1.4-0.3-1.8C162.6,244,162,243.7,161.4,243.6
	L161.4,243.6z"
          />
          <path
            fill="#D2D3D4"
            d="M163,244.1c0.7,0.4,0.8,1.1,0.3,1.8l-7.7,9.1l4,0.3l8.3-10c1.6-2.3,0.8-3.9-2.3-4.5c-4.8-1-9.9-1.8-15.5-2.2
	h-0.6c-1.2,0-2.2,0.3-3.2,0.8l-4.1,2.6h0.3c0.8-0.4,1.8-0.6,2.9-0.6h0.6c5.5,0.4,10.7,1.2,15.5,2.2C162,243.7,162.6,244,163,244.1
	L163,244.1z"
          />
          <path
            fill="#EBEBEB"
            d="M158.8,244.1l-16.5-2.2H142l-2.6-0.3l-5.1,3.7c-6.1,4.5-11.7,9.5-16.8,14.9l-1.9,2.1v8.9
	c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-0.8,2.8h0.1l1.2-0.3c0.4-1,0.7-1.9,0.8-2.9c0.3-1.1,0.4-2.2,0.6-3.2v-0.1
	c0.1-1.4,0.3-2.8,0.3-4v-8.9H144l2.2-2.1l7.8-8.1c1.5-1.6,3-3.4,4.4-5.2C159.8,245.5,160,244.5,158.8,244.1L158.8,244.1z
	 M157.5,246.5l-0.3,0.3l-7,8.1l-2.1,2.2l-0.6,0.4c-1.8,1.2-3.6,2.1-5.6,2.3l-2.8,0.3H123l-0.6-0.1c-1.2-0.3-1.4-1-0.7-2.1
	c4.1-3.9,8.5-7.6,13.2-11l2.9-2.1l0.1-0.1l0.7-0.6l0.3-0.1c1.1-0.6,2.3-0.8,3.7-0.6h0.1l14.2,1.9
	C157.6,245.5,157.8,245.8,157.5,246.5L157.5,246.5z"
          />
          <path
            fill="#E6E7E7"
            d="M157.1,245.4l-14.2-1.9l-0.3,0.1c-0.6,0.1-1,0.3-1.5,0.7l-3.7,2.8c-2.9,2.2-5.6,4.5-8.3,6.9l-4.4,4.1v0.1
	l-0.3,0.5c-0.1,0.4,0,0.8,0.3,1l0.7,0.4h13.5l2.8-0.3c2.1-0.4,4-1.2,5.6-2.3l0.6-0.4h-0.6c-1,0.5-1.9,1.1-3,1.5l-2.6,0.5l-13.1,0.1
	l-1.4-0.1c-1-0.3-1.1-1-0.3-1.9l3-2.8c2.6-2.3,5.4-4.7,8.3-6.9l3.7-2.8c0.3-0.3,0.7-0.4,1.1-0.6l13.5,1.8c0.4,0.3,0.6,0.4,0.6,0.7
	l0.3-0.3C157.8,245.8,157.7,245.5,157.1,245.4L157.1,245.4z"
          />
          <path
            fill="#E6E7E7"
            d="M156.7,246.1l-13.5-1.8c-0.4,0.1-0.7,0.3-1.1,0.6l-3.7,2.8c-2.9,2.2-5.6,4.5-8.3,6.9l-3,2.8
	c-0.8,1-0.8,1.5,0.3,1.9l1.4,0.1l13.1-0.1l2.6-0.5c1.1-0.4,2.1-1,3-1.5l2.8-2.2l7-8.1C157.2,246.6,157,246.3,156.7,246.1
	L156.7,246.1z M143.2,245.2l12.5,1.6l-5,5.9l-2.9,3l-0.3,0.3h-0.1l-0.1,0.1l-0.8,0.5l-0.3,0.1l-0.3,0.1l-1.5,0.7
	c-0.8,0.3-1.6,0.5-2.6,0.5l-12.8,0.1c-1-0.1-1.4-0.3-1.2-0.5l0.1-0.1l1.5-1.4l1.5-1.4c2.6-2.3,5.4-4.7,8.3-6.9L143.2,245.2
	L143.2,245.2z"
          />
          <path
            fill="#E6E7E7"
            d="M143.5,246.2l10.5,1.4l-6.2,7.4l-0.3,0.3c-0.8,0.7-1.8,1.2-2.9,1.7l-1.8,0.3h1.7c1.1-0.1,2.2-0.4,3-0.8
	l0.1-0.1h0.1l0.3-0.3l2.9-3l4.9-5.9l-12.5-1.6l-4,3c-2.9,2.2-5.6,4.5-8.3,6.9l-1.5,1.4c-0.8,0.8-0.7,1.2,0.3,1.2h12.2
	c1.5,0,2.8-0.3,3.9-0.7l0.4-0.1l0.3-0.1l0.8-0.5c-0.8,0.4-1.9,0.7-3,0.8h-13.9l1.9-1.8c2.6-2.3,5.4-4.7,8.3-6.9L143.5,246.2
	L143.5,246.2z"
          />
          <path
            fill="#E6E7E7"
            d="M140.5,248.4c-2.9,2.2-5.5,4.5-8.3,6.9l-1.9,1.8h12.2l1.8-0.3c1.2-0.4,2.2-0.8,2.9-1.6l0.3-0.3l6.2-7.4
	l-10.5-1.4L140.5,248.4L140.5,248.4z M143.5,247.2l8.5,1.2l-1.2,1.6l-3.6,4.1l-0.1,0.3l-0.6,0.6l-0.3,0.3c-1.2,0.8-2.8,1.2-4.5,1.2
	h-9.2l1.5-1.5c2.3-2.1,4.8-4.1,7.4-6.2l0.1-0.1L143.5,247.2L143.5,247.2z"
          />
          <path
            fill="#E6E7E7"
            d="M141.4,248.7c-2.6,2.1-5.1,4.1-7.6,6.2l-1.5,1.5h9.2c1.8,0,3.3-0.4,4.5-1.2l0.3-0.3l0.6-0.6
	c-0.6,0.6-1.7,0.7-3.4,0.6h-8.5c2.3-2.1,4.8-4.1,7.3-6.2l6.9,1.1l1.5,0.1l1.2-1.7l-8.5-1.2L141.4,248.7L141.4,248.7z"
          />
          <path
            fill="#E6E7E7"
            d="M142.4,248.8c-2.5,1.9-5,4-7.3,6.2h7.4c1.6,0,2.9-0.7,3.9-1.8l2.9-3.3L142.4,248.8z"
          />
          <path
            fill="#E6E7E7"
            d="M143.5,255c1.6,0.1,2.9,0,3.4-0.6l0.1-0.3l3.6-4.1l-1.5-0.1l-2.9,3.3c-1,1.1-2.2,1.7-3.9,1.8L143.5,255
	L143.5,255z M127.8,257.9c-0.1,0.3,0.3,0.4,1.2,0.5l12.8-0.1c1-0.1,1.8-0.3,2.6-0.5l1.5-0.7h-0.1c-1.1,0.4-2.5,0.7-3.9,0.7h-12.2
	c-1,0-1.1-0.4-0.3-1.2C129.4,256.5,127.8,257.9,127.8,257.9z"
          />
          <path fill="#3296BA" d="M146.2,256.9l-0.4,0.1h0.1" />
          <path
            fill="#EBEBEB"
            d="M142.5,243.6l0.3-0.1L142.5,243.6c-1.4-0.3-2.6-0.1-3.7,0.6l-1.1,0.7l-0.1,0.1l-2.9,2.1
	c-4.7,3.4-9.1,7.2-13.2,11c-0.8,1.1-0.6,1.8,0.7,2.1l0.6,0.1h2.8l-0.7-0.4c-0.3-0.3-0.4-0.5-0.3-1l0.3-0.5v-0.1l4.4-4.1
	c2.6-2.3,5.4-4.7,8.3-6.9l3.7-2.8C141.4,244,142,243.7,142.5,243.6L142.5,243.6z"
          />
          <path
            fill="#3296BA"
            d="M138.5,244.3l-0.7,0.6l1-0.7 M125.5,260.1v0.1h0.1 M150.2,254.9l-2.8,2.2h0.6"
          />
          <path
            fill="#FFFFFF"
            d="M134.3,244.3c-7.4,5.4-14,11.1-20.1,17.5v9.4c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-1,3.2
	l1.4-0.4l0.8-2.8c0.4-1.2,0.7-2.5,0.8-3.7c0.1-1.4,0.3-2.8,0.3-4.1v-8.9l1.9-2.1c5.2-5.2,10.7-10.2,16.8-14.9l5.1-3.7l-1.1-0.1
	L134.3,244.3L134.3,244.3z"
          />
          <path
            fill="#656565"
            d="M192.7,214.3l2.1,3.3c10.7-2.9,21.5-5.5,32.6-7.7l3.7-0.7l6.7-1.2l3.3-5.5c-14.2,2.5-28.1,5.5-41.5,9.1
	l-3.2,0.8C194.7,212.8,193.4,213.5,192.7,214.3L192.7,214.3z"
          />
          <path
            fill="#FFFFFF"
            d="M193,221.3c3.9,6.2,8,11.6,12.2,16.4c1.2,1.4,2.5,2.5,3.9,3.6l2.3,1.6c1.5,1,3.2,1.6,4.8,2.1l1.6-2.8
	c-1.8-0.4-3.4-1.1-4.8-1.9c-0.8-0.4-1.6-1-2.3-1.6l-2.1-2.2c-3.2-3.4-6.9-8.5-11.1-15l-2.6-4l-2.1-3.3
	C191.1,215.9,191.2,218.3,193,221.3L193,221.3z"
          />
          <path
            fill="#656565"
            d="M197.3,221.4c4.3,6.5,8,11.6,11.1,15l2.1,2.2c0.7,0.7,1.5,1.2,2.3,1.6l18.3-31.2l-3.7,0.7
	c-11,2.2-21.9,4.8-32.6,7.7L197.3,221.4L197.3,221.4z M201.5,220.3c7-1.8,14-3.4,21.2-5c0.8,0,1.1,0.4,0.8,1.1L213,234.8
	c-0.7,0.7-1.4,0.8-1.9,0.1c-3.6-3.7-7-7.8-10.2-12.7C200.3,221.3,200.6,220.6,201.5,220.3L201.5,220.3z"
          />
          <path
            fill="#EBEBEB"
            d="M200.7,222.3c3.2,4.7,6.6,8.9,10.2,12.7c0.7,0.6,1.4,0.6,1.9-0.1l10.6-18.3c0.4-0.8,0.1-1.1-0.8-1.1
	c-7.2,1.5-14.2,3.2-21.2,4.9C200.6,220.6,200.3,221.3,200.7,222.3L200.7,222.3z M130.7,262.1v8.7l0.8-0.4l3.6-1.6l5.5-3.4l3.4-3.2"
          />
          <path
            fill="#EBEBEB"
            d="M130.7,270.8v-8.5h-14.2v8.9c0,1.4-0.1,2.8-0.3,4l0.8-0.1c4-0.8,7.7-1.9,11.1-3.2L130.7,270.8L130.7,270.8z"
          />
          <path
            fill="#D2D3D4"
            d="M151.7,259.4h0.1l4.1,0.1l1-1.1l-4-0.4l-2.8-0.1"
          />
          <path fill="#D2D3D4" d="M154.8,261.3l1.2-1.8l-4.1-0.1l-1.2,1.6" />
          <path
            fill="#D2D3D4"
            d="M148.7,262.1l1.2,0.1l4.1,0.3l0.7-1.2l-4.1-0.3l-3.4-0.3 M153,254.9l1.5,1.5l4,0.1l1-1.2l-3.9-0.3"
          />
          <path fill="#D2D3D4" d="M154.5,256.4L153,258l4,0.4l1.5-1.9" />
          <path
            fill="#FFFFFF"
            d="M156.5,267.4c-4.3,1.8-8.7,3.4-13.2,5.1l3.2,13.5c5.2-6.1,9.6-12.8,13.1-20.2
	C158.7,266.4,157.6,267,156.5,267.4L156.5,267.4z"
          />
          <path
            fill="#D2D3D4"
            d="M149.5,263.1c-1.2,2.6-4.3,4.8-9.1,6.7c-6.6,3.2-14.6,5.9-23.8,8.4v0.1c-0.1,1-0.4,1.8-0.8,2.8l13.1-3.9
	c4-1.2,7.3-2.5,10-3.4c5.5-2.1,8.8-3.6,10.2-4.4c1.8-1,3.2-2.8,4.3-5.4l0.7-1.4l-4.1-0.3L149.5,263.1L149.5,263.1z"
          />
          <path
            fill="#FFFFFF"
            d="M128.9,277.1l-13.1,3.9l-0.8,0.3l-1.2,0.3h-0.1l-1.4,0.4c-1.9,5.6-4,10.9-6.3,15.7c-1.9,3.6-4,6.6-6.2,9.4
	l-1.8,2.1c-2.3,2.8-5.5,4.7-9.5,5.5l-6.9,5.8c8.9-1.5,17.5-4.1,25.6-7.4c16.4-6.6,29.6-15.7,39.6-27.1l-3.2-13.5
	C138.7,274.1,133.9,275.8,128.9,277.1L128.9,277.1z M103,264.9l0.1,2.9l0.1,4h3.6l0.3-6.1c0-1.2,0.1-2.3,0.6-3.4
	c0.3-1,0.7-1.8,1.1-2.6l6.1-10c-2.3,1.5-4.8,2.9-7.3,4.3l-3.3,5.5C103.2,261,102.8,263,103,264.9L103,264.9z M102.3,275.8h1.1
	l-0.1-4l-0.3-4h-1"
          />
          <path
            fill="#3296BA"
            d="M116.7,278.2l-0.4-3c-0.1,1.1-0.3,2.2-0.6,3.2l-0.8,2.9l0.8-0.3C116.1,280.2,116.4,279.2,116.7,278.2
	L116.7,278.2z"
          />
          <path fill="#FFFFFF" d="M106.5,277.8l0.4-6.1h-3.6l0.1,4l0.1,2.1" />
          <path fill="#3296BA" d="M80.3,343.9l0.1-1.9l-0.3,1.8" />
          <path
            fill="#FFFFFF"
            d="M79.5,346.2l-1,2.6c6.6,1,12.5,5.6,17.9,14.2c0.7,1.2,1.5,2.3,2.5,3.4l0.4,0.1c-0.3-0.5-0.6-1.1-0.7-1.8
	c-3.4-11.6-9.5-18.6-18.3-21l-0.1-0.1C80.1,344.7,79.8,345.5,79.5,346.2L79.5,346.2z"
          />
          <path
            fill="#FFFFFF"
            d="M80.3,343.9c8.8,2.5,14.9,9.5,18.3,21c0.1,0.5,0.4,1.2,0.7,1.8l39.5,6.1v-0.1c1.8-15.1,8.1-28.2,18.7-39.3
	c3.3-3.4,6.9-6.5,10.6-9.1l-8.9-14.9c-3.6,2.2-6.9,4.8-10.2,7.7l-3.9,3.7c-5.8,6.2-12.7,9.8-20.4,10.9c-4.3,0.4-9.2,0.3-14.9-0.3
	c-6.1-0.8-11.7-1.8-17.1-3.3l20.4-1.9c13.3-1.5,22.6-5.2,27.8-11.3c2.1-2.5,4.4-5,7-7.3c2.2-2.1,4.5-3.9,6.9-5.6l-8.5-15.8
	c-9.9,11.4-23.1,20.4-39.6,27.1c-8.1,3.4-16.6,5.9-25.6,7.4l-1,21.5L80.3,343.9L80.3,343.9z M102.1,340.4l3,0.4l25,2.9
	c1.1,0.1,1.5,0.5,1.2,1.6c-0.8,5.5-3.7,8.5-8.7,9.1H116c-8.3,0-13.8-3.7-16.4-11.3C98.9,341.1,99.7,340.1,102.1,340.4L102.1,340.4z"
          />
          <path
            fill="#FFFFFF"
            d="M105.1,340.8l-3-0.4c-2.2-0.3-3.2,0.5-2.8,2.8c2.6,7.4,8.1,11.1,16.4,11.3h3.7c-7.8-0.3-12.9-4-15-11.3
	C104.3,342.1,104.4,341.2,105.1,340.8L105.1,340.8z"
          />
          <path
            fill="#D2D3D4"
            d="M104.5,343.2c2.1,7.3,7,11,15,11.3h3c5.1-0.5,8-3.6,8.7-9.1c0.3-1-0.1-1.5-1.2-1.6l-25-2.9
	C104.4,341.2,104.3,342.1,104.5,343.2L104.5,343.2z"
          />
          <path
            fill="#FFFFFF"
            d="M92.9,327.9c5.2,1.4,11,2.5,16.9,3.3c5.8,0.5,10.7,0.7,14.9,0.3c7.7-1.1,14.4-4.7,20.2-10.9l3.9-3.7
	c3.2-2.9,6.6-5.4,10.2-7.7l-4-7.4c-2.3,1.6-4.7,3.6-6.9,5.6c-2.6,2.3-5,4.8-7,7.3c-5.2,5.9-14.4,9.8-27.8,11.3L92.9,327.9
	L92.9,327.9z"
          />
          <path
            fill="#656565"
            d="M96.4,363.1c-5.4-8.5-11.3-13.2-17.9-14.2l1,13.3c0.3,1.9,0.8,3.3,1.8,4.4l7.7,10l10.2,1.1
	c3.7,0.3,7.2,0.7,10.6,1.2l3.4,0.7l-12.7-11.3l-1.5-1.6C97.9,365.5,97.1,364.4,96.4,363.1L96.4,363.1z"
          />
          <path fill="#3296BA" d="M99.2,366.7l-0.3-0.1l1.5,1.8" />
          <path
            fill="#FFFFFF"
            d="M100.4,368.3l12.7,11.3l25,4.8l0.1-3.6c0-2.8,0.3-5.5,0.6-8.1l-39.5-6.1L100.4,368.3L100.4,368.3z
	 M159,309.2c12.5-8,27-12,43.2-12c10.5,0,20.2,1.6,29.2,5.1l-7.7-11.1c-7.3-2.2-15.1-3.3-23.5-3.3c-17.2,0-32.2,4.7-45.1,14
	L159,309.2L159,309.2z M168.1,324.1c-3.7,2.6-7.2,5.6-10.6,9.1c-10.7,11.1-16.9,24.2-18.7,39.3v0.1l5.6,0.8l0.3-2.6
	c1.6-13.1,7.3-24.5,17.1-34.3c3-3.2,6.3-5.8,9.8-8.1L168.1,324.1L168.1,324.1z"
          />
          <path
            fill="#FFFFFF"
            d="M171.4,328.7c9.2-6.1,19.7-9.1,31.4-9.1c16.1,0,29.7,5.6,41.1,17.1s17.5,25.4,18.3,42.1l0.4,8.1l6.2,0.1
	l-0.3-5.5c-0.8-19.1-7.6-35.2-20.1-48.4c-12.5-13.1-27.6-19.7-45.4-19.7c-13.1,0-24.6,3.6-34.9,10.6L171.4,328.7L171.4,328.7z"
          />
          <path
            fill="#656565"
            d="M166.7,346.2c-9.9,9.9-15,22-15,36.2v2.6c0.4,11,4.1,20.8,11,29.2c1.2,1.5,2.5,2.9,4,4.4
	c9.9,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1l2.1-1.9c8.8-8.9,13.8-19.4,14.7-31.5c0.1-1.5,0.3-3,0.3-4.5
	c0-12.7-4-23.7-12.1-33c-0.8-1.1-1.8-2.1-2.8-3c-9.9-9.9-22-15-36-15C188.7,331.2,176.8,336.2,166.7,346.2L166.7,346.2z
	 M202.9,344.7c3.9,0,7.4,0.5,10.7,1.5c2.6,0.8,5.2,1.8,7.6,3.3c2.9,1.6,5.6,3.7,8.1,6.2l1.6,1.8c2.5,2.8,4.4,5.8,5.9,8.9
	c2.3,4.8,3.4,10.2,3.4,16c0,5.8-1.1,11-3.4,16c-1.8,3.9-4.4,7.4-7.6,10.6l-3.4,3.2c-6.6,5.2-14.3,7.8-23.1,7.8
	c-8.8,0-16.5-2.6-23.1-7.8c-1.2-1-2.3-2.1-3.4-3.2c-3-3-5.5-6.3-7.2-9.8c-2.5-5.1-3.9-10.6-3.9-16.8c0-6.1,1.2-11.7,3.9-16.8
	c1.5-2.9,3.3-5.6,5.6-8.1l1.6-1.8h0.1c4.5-4.5,9.8-7.7,15.7-9.5C195.5,345.2,199.1,344.7,202.9,344.7L202.9,344.7z"
          />
          <path
            fill="#EBEBEB"
            d="M192,346.2c-5.8,1.6-11,4.8-15.7,9.5h-0.1l-1.7,1.8c-2.3,2.5-4.1,5.2-5.6,8.1c-2.5,5.1-3.9,10.6-3.9,16.8
	c0,6.1,1.2,11.7,3.9,16.8c1.8,3.6,4.1,6.7,7.2,9.8c1.1,1.1,2.2,2.2,3.4,3.2c6.6,5.2,14.3,7.8,23.1,7.8s16.5-2.6,23.1-7.8l3.4-3.2
	c3.3-3.3,5.8-6.7,7.6-10.6c2.3-4.8,3.4-10.2,3.4-16c0-5.8-1.1-11.1-3.4-16c-1.5-3.2-3.4-6.2-5.9-8.9l-1.6-1.8
	c-2.5-2.5-5.2-4.7-8.1-6.2c-2.5-1.4-5-2.5-7.6-3.3c-3.4-1-7-1.5-10.7-1.5C199.1,344.7,195.5,345.2,192,346.2L192,346.2z
	 M202.9,345.8l5.5,0.4l3.4,0.7h0.1l2.2,0.7c1.8,0.5,3.6,1.2,5.4,2.2c0.6,0.3,1.2,0.6,1.8,1h0.1c2.6,1.5,5,3.4,7.3,5.8l0.8,1
	c1.8,1.9,3.4,4,4.7,6.2l1.2,2.2c0.6,1.1,1.1,2.3,1.5,3.4l0.7,1.8l0.6,2.3c0.7,2.9,1,5.8,1,8.9c0,3.2-0.4,6.2-1.1,9.1l-0.6,2.2
	l-0.6,1.6c-0.4,1.2-1.1,2.5-1.7,3.7l-1,1.8c-1.5,2.6-3.4,5-5.6,7.3c-1.5,1.5-3,2.8-4.5,4l-4.5,2.9c-1.8,0.8-3.4,1.6-5.4,2.2
	l-2.3,0.7c-2.9,0.7-5.8,1-8.8,1c-3.2,0-6.2-0.4-9.1-1.1l-2.2-0.5c-1.8-0.6-3.6-1.2-5.2-2.2l-1.9-1.1l-2.6-1.8
	c-1.6-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.5-5.6-7l-1.1-2.1l-1.2-2.6l-1-2.8l-0.7-2.5c-0.7-2.9-1.1-5.8-1.1-8.9s0.4-6.2,1.1-9.1
	l0.7-2.2l1-2.8l1.2-2.5l1.1-2.1c1.4-2.2,2.9-4.3,4.8-6.3l0.8-1c2.2-2.2,4.5-4.1,7-5.5c0.7-0.4,1.4-0.8,2.1-1.1
	c1.8-1,3.4-1.6,5.4-2.2l2.3-0.7l3.4-0.7L202.9,345.8L202.9,345.8z"
          />
          <path
            fill="#EBEBEB"
            d="M197.3,346.2l-3.4,0.7l-2.3,0.7l0.6,1.8l2.8,9.8c-1,0.3-1.8,0.7-2.6,1.1l-5.1-8.8l-1-1.5
	c-0.7,0.3-1.4,0.7-2.1,1.1c-2.5,1.5-4.8,3.4-7,5.5l-0.8,1c-1.9,2.1-3.6,4.1-4.8,6.3l2.3-0.4c0.8-1.1,1.6-2.3,2.6-3.4l2.3-2.5
	c1.6-1.5,3.3-2.9,4.9-4.1l0.4-2.3l1,1.5c1.1,1.6,2.2,3.3,3.3,5l6.2,10c1.6,2.9,3.3,5.8,5,8.7c0.6-0.3,1.1-0.5,1.8-0.7l-0.6-0.7
	l-0.1-0.4l-3.7-13.6l-3-13.8l2.1,1.4c2.2-0.4,4.5-0.7,6.9-0.7c2.5,0,4.8,0.3,7,0.7l1.9-1.5l-3.4-0.7l-5.5-0.4L197.3,346.2
	L197.3,346.2z"
          />
          <path
            fill="#EBEBEB"
            d="M183.8,353.5l5.5,8.4l1.1,1.9l4.5,7.4l4.5,5l-4.9-8.7l-6.2-10l-3.3-5l-1-1.5L183.8,353.5L183.8,353.5z"
          />
          <path
            fill="#656565"
            d="M183.8,353.4c-1.8,1.1-3.4,2.5-5,4.1l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4h0.1l8.5,5.4l3.2-3.9
	c1.2-1.1,2.5-2.2,3.7-3.2L183.8,353.4L183.8,353.4z"
          />
          <path
            fill="#EBEBEB"
            d="M182.4,368.8l-8.5-5.4h-0.1l-2.3,0.4l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6l-5.1-4.5l-0.7-0.3l-2.5-1.5l-5.8-3.4
	L182.4,368.8L182.4,368.8z"
          />
          <path
            fill="#EBEBEB"
            d="M182.8,369l1.4-1.7c0.8-0.8,1.6-1.8,2.8-2.5l0.8,1c0.8-0.8,1.8-1.5,2.8-2.2l-1.1-1.9c-1.2,1-2.6,1.9-3.7,3.2
	l-3.2,3.9L182.8,369L182.8,369z M171.8,366.7l8.8,5l-1.1,2.6l-9.8-2.6l-1.8-0.5l-0.7,2.2l1.6,0.3h0.1c3.4,0.7,6.7,1.4,9.9,2.2
	l2.1,0.5c4.7,1.1,9.2,2.3,13.6,3.7l0.3,0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.1-0.5,0.4-1.1,0.7-1.6c-4.5-2.5-8.9-5-13.3-7.6l-12-7.6
	L170,366L171.8,366.7L171.8,366.7z"
          />
          <path
            fill="#EBEBEB"
            d="M170.3,365.7l-1.2,2.5l-1,2.8l1.8,0.5c0.6-1.8,1.2-3.4,2.1-5L170.3,365.7L170.3,365.7z M192,349.4l-0.6-1.8
	c-1.8,0.6-3.6,1.4-5.4,2.2l1,1.5C188.7,350.6,190.4,349.9,192,349.4L192,349.4z"
          />
          <path
            fill="#656565"
            d="M187.1,351.4l5.1,8.8c0.8-0.4,1.8-0.8,2.6-1.1l-2.8-9.8C190.4,349.9,188.7,350.6,187.1,351.4z M196,348.4
	c0.6,3.2,1.1,6.2,1.8,9.1l0.1,0.8l4.8-0.4c1.8,0,3.4,0.1,5.1,0.4l0.1-0.8c0.7-2.9,1.2-5.9,1.6-9.1h0.1c-2.2-0.4-4.5-0.7-7-0.7
	C200.4,347.7,198.1,347.9,196,348.4L196,348.4z"
          />
          <path
            fill="#EBEBEB"
            d="M198,358.3l0.6,2.1l4.4-0.4l4.5,0.4l0.6-2.1c-1.6-0.3-3.3-0.4-5.1-0.4L198,358.3L198,358.3z"
          />
          <path
            fill="#EBEBEB"
            d="M196,348.4L194,347l3,13.8l3.7,13.6V374c0-0.5,0.1-1.1,0.6-1.5v-3.3l-0.1-0.6l-0.4-1.1
	c-0.8-2.3-1.5-4.7-2.1-7l-0.6-2.1l-0.1-0.8C197.1,354.5,196.4,351.4,196,348.4L196,348.4z"
          />
          <path
            fill="#EBEBEB"
            d="M198.5,360.4c0.6,2.3,1.2,4.8,2.1,7l0.4,1.1l0.1,0.5h3.6l0.1-0.5l0.4-1.1c0.8-2.3,1.5-4.7,2.2-7l-4.5-0.4
	L198.5,360.4L198.5,360.4z M187.8,365.9l1.1,1.5c0.8,1.4,1.5,3.2,2.2,5.1v1.5l0.7,0.3l3.2-3.2l-4.5-7.4
	C189.4,364.4,188.6,365,187.8,365.9L187.8,365.9z"
          />
          <path
            fill="#EBEBEB"
            d="M186.9,364.9c-1.1,0.8-1.9,1.6-2.8,2.5l-1.4,1.7l5.8,3.4h2.5c-0.7-2.1-1.5-3.7-2.2-5.1l-1.1-1.5L186.9,364.9
	L186.9,364.9z"
          />
          <path
            fill="#EBEBEB"
            d="M188.6,372.5l2.5,1.5v-1.5 M193,380.5c0.4-0.3,0.8-0.5,1.4-0.5h0.6c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.6
	c-3.2-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3l1.5,1.9v0.1h0.1c3.3,0.4,6.6,1.1,9.8,1.8l2.1,0.5l3.3,1l3.6,1.2l1.8,0.5L193,380.5
	L193,380.5z M189.7,380.5v3.6h3.2l-0.1-0.1c-0.4-0.4-0.6-1-0.6-1.5c0-0.7,0.1-1.2,0.6-1.6l0.3-0.1L189.7,380.5L189.7,380.5z"
          />
          <path
            fill="#EBEBEB"
            d="M192.7,380.6c-0.4,0.4-0.6,1-0.6,1.6c0,0.6,0.1,1.1,0.6,1.5l0.1,0.1c0.4,0.4,1,0.5,1.5,0.5h0.4l0.4-0.1
	c0.3-0.1,0.6-0.3,0.7-0.4v-0.1c0.4-0.4,0.7-1,0.7-1.5c0-0.7-0.3-1.2-0.7-1.6c-0.3-0.3-0.6-0.4-0.8-0.5l-0.3-0.1h-0.6
	c-0.6,0-1.1,0.1-1.4,0.5L192.7,380.6L192.7,380.6z M193.1,382.2c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,0.8-0.4s0.7,0.1,1,0.4
	c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-0.8-0.4C193.3,382.9,193.1,382.7,193.1,382.2
	L193.1,382.2z"
          />
          <path
            fill="#EBEBEB"
            d="M193.6,383.1c0.3,0.3,0.6,0.4,0.8,0.4s0.7-0.1,1-0.4s0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.6-0.4-1-0.4s-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1S193.3,382.9,193.6,383.1L193.6,383.1z M184.3,378.7
	c-0.3,0.1-0.7,0.4-1,0.8c-0.7,0.8-1.1,1.8-1.1,2.9s0.3,2.2,1.1,2.9l0.6,0.5l4-1.2v-4.7L184.3,378.7L184.3,378.7z"
          />
          <path
            fill="#EBEBEB"
            d="M187.9,379.9v4.7l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1v-3.6L187.9,379.9L187.9,379.9z M201.3,372.3
	c0.4-0.4,1-0.7,1.6-0.7c0.6,0,1.1,0.3,1.6,0.7l0.1,0.1l0.1-3.3h-3.6L201.3,372.3L201.3,372.3z"
          />
          <path
            fill="#EBEBEB"
            d="M201.1,372.3c-0.3,0.4-0.6,1-0.6,1.5v0.4l0.1,0.4l0.6,0.7c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.1-0.3,1.6-0.7
	l0.4-0.7l0.1-0.3v-0.5c0-0.5-0.1-1-0.6-1.4l-0.1-0.1c-0.4-0.4-1-0.7-1.6-0.7C202.1,371.7,201.5,371.9,201.1,372.3L201.1,372.3z
	 M201.9,373c0.3-0.3,0.6-0.4,0.8-0.4c0.3,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4
	c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1C201.7,373.4,201.7,373.2,201.9,373L201.9,373z"
          />
          <path
            fill="#EBEBEB"
            d="M201.7,373.9c0,0.3,0.1,0.7,0.4,1s0.6,0.4,0.8,0.4c0.3,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-0.8,0.4C201.7,373.2,201.7,373.4,201.7,373.9L201.7,373.9z
	 M199.5,376.1c-0.6,0.3-1.1,0.7-1.6,1.2c-0.6,0.5-0.8,1-1.2,1.5c-0.3,0.5-0.6,1.1-0.7,1.6c0.4,0.4,0.7,1,0.7,1.7
	c0,0.5-0.3,1.1-0.7,1.5v0.1c0.1,0.5,0.3,1.1,0.6,1.8l1.2,1.7l1.5,1.2l1.6,0.7V389c0.4-0.4,1-0.7,1.7-0.7s1.2,0.3,1.6,0.7l0.1,0.1
	c0.6-0.1,1.1-0.4,1.6-0.7c0.6-0.3,1.1-0.8,1.6-1.2c0.4-0.4,0.8-1,1.1-1.5c0.3-0.5,0.4-1.1,0.7-1.6h-0.1c-0.4-0.4-0.6-1-0.6-1.7
	c0-0.7,0.1-1.2,0.6-1.6l0.1-0.1c-0.1-0.5-0.4-1.2-0.7-1.6c-0.3-0.5-0.7-1.1-1.2-1.6c-0.6-0.4-1-0.8-1.5-1.1
	c-0.6-0.3-1.1-0.6-1.6-0.7c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.3-1.7-0.7C200.7,375.6,200,375.8,199.5,376.1L199.5,376.1z"
          />
          <path
            fill="#EBEBEB"
            d="M198,377.3c0.6-0.5,1.1-0.8,1.6-1.2l-4.5-5l-3.2,3.2l5.1,4.5C197,378.4,197.4,377.8,198,377.3L198,377.3z
	 M191.6,390.2l3.3,3.2l4.5-5l-1.5-1.2l-1.4-1.7v0.1"
          />
          <path
            fill="#EBEBEB"
            d="M196.6,385.5c-0.3-0.5-0.6-1.1-0.6-1.8c-0.3,0.1-0.4,0.3-0.7,0.4l-0.4,0.1l-13.6,3.7l-12,2.6h-0.1l-1.5,0.3
	l1.4-1.9c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2l-1.5-2.1c-0.7,2.9-1.1,5.9-1.1,9.1s0.3,6.1,1.1,8.9l0.7,2.5l1.8-0.5
	l9.8-2.8l1.1,2.6l-8.9,5.4l-1.5,1l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4l2.6,1.8l-0.3-2.3c-1.9-1.2-3.9-2.9-5.6-4.7
	c-1.6-1.6-3.2-3.6-4.5-5.5l-2.2-0.4c0.4-0.3,1-0.7,1.5-1c2.8-1.9,5.6-3.7,8.5-5.5l1.8-1.1C187.6,390.6,192,388.2,196.6,385.5
	L196.6,385.5z"
          />
          <path
            fill="#EBEBEB"
            d="M194.4,384.4c-0.6,0-1.1-0.1-1.5-0.5h-3.2l-0.4,0.1c0,0-0.1,0-0.1,0.1l-0.4,0.1l-0.8,0.3l-4,1.2l-3,0.8
	l-2.1,0.5c-3.2,0.8-6.5,1.4-9.8,1.8h-0.1l-0.1,0.1l-1.4,1.9l1.5-0.3h0.1l12-2.6l13.6-3.7L194.4,384.4L194.4,384.4z M199.5,388.4
	c-2.5,4.5-5,8.9-7.6,13.3l-7.6,12l1.9,1.1l0.8-1.5l5.1-8.8l2.6,1.1l-2.6,9.8l-0.6,1.8l2.2,0.5l3-13.8c1.1-4.8,2.3-9.4,3.7-13.8
	l0.1-0.3l0.4-0.7L199.5,388.4L199.5,388.4z"
          />
          <path
            fill="#EBEBEB"
            d="M201.1,389.1l-0.6,0.8l-0.1,0.3v0.5c0,0.6,0.1,1.1,0.6,1.5l0.1,0.1c0.4,0.4,1,0.7,1.6,0.7
	c0.7,0,1.2-0.3,1.6-0.7l0.1-0.1c0.3-0.4,0.6-1,0.6-1.5v-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.4-0.4-0.7l-0.1-0.1
	c-0.4-0.4-1-0.7-1.6-0.7C202.1,388.4,201.5,388.7,201.1,389.1L201.1,389.1z M203.6,389.8c0.3,0.3,0.4,0.5,0.4,1
	c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1
	s0.6-0.4,0.8-0.4C203.2,389.4,203.5,389.5,203.6,389.8L203.6,389.8z"
          />
          <path
            fill="#EBEBEB"
            d="M202.8,389.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4
	c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1C203.5,389.5,203.2,389.4,202.8,389.4L202.8,389.4z M201,392.1
	v3.3h3.6v-3.2l-0.1,0.1c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.1-0.3-1.7-0.7L201,392.1L201,392.1z"
          />
          <path
            fill="#EBEBEB"
            d="M200.4,390.1c-1.4,4.4-2.6,8.9-3.7,13.8l-3,13.8l2.1-1.4l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.2-5.6l0.6-2.1
	c0.7-2.6,1.5-5.2,2.3-7.8l0.3-1v-3.3c-0.4-0.4-0.6-0.8-0.6-1.5L200.4,390.1L200.4,390.1z M194.9,393.4l-4.4,7.6l-1.1,1.8
	c-1.8,2.9-3.6,5.6-5.4,8.4v0.1l0.3,2.3l7.6-12c2.6-4.4,5.1-8.8,7.6-13.3L194.9,393.4L194.9,393.4z"
          />
          <path
            fill="#EBEBEB"
            d="M191.1,390.6v1.1c-1.1,2.9-2.2,5.2-3.4,6.9l2.9,2.3l4.4-7.6l-3.2-3.2L191.1,390.6L191.1,390.6z M191.6,390.2
	l4.8-4.5c-4.5,2.5-8.9,5-13.2,7.7l-1.8,1.1c-2.9,1.8-5.8,3.6-8.5,5.5c-0.6,0.3-1,0.5-1.5,1l2.2,0.4h0.1l8.4-5.5l0.7-0.4l6.1-3.7
	l1.9-1.1L191.6,390.2L191.6,390.2z"
          />
          <path fill="#EBEBEB" d="M189.1,391.7h1.9v-1.1" />
          <path
            fill="#EBEBEB"
            d="M189.1,391.7l-6.1,3.7c1.1,1.4,2.3,2.8,3.9,3.9l0.6-0.7c1.2-1.7,2.3-4,3.4-6.9L189.1,391.7L189.1,391.7z"
          />
          <path
            fill="#656565"
            d="M168.9,375.2c-0.4,2.3-0.7,4.7-0.7,7.2c0,2.3,0.3,4.7,0.7,6.9l0.1-0.1h0.1c3.3-0.4,6.5-1.1,9.8-1.8
	c-0.3-1.5-0.6-3.2-0.6-4.8c0-1.8,0.1-3.4,0.6-5.1c-3.2-0.8-6.5-1.4-9.8-1.8L168.9,375.2L168.9,375.2z"
          />
          <path
            fill="#EBEBEB"
            d="M178.8,377.2c-0.3,1.6-0.6,3.4-0.6,5.1s0.1,3.3,0.6,4.8l2.1-0.5c-0.3-1.4-0.4-2.8-0.4-4.3s0.1-3.2,0.4-4.5
	L178.8,377.2L178.8,377.2z"
          />
          <path
            fill="#656565"
            d="M180.8,371.6l-8.8-5c-0.8,1.5-1.5,3.2-2.1,5l9.8,2.6L180.8,371.6L180.8,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M180.5,382.4c0,1.5,0.1,2.9,0.4,4.3l3-0.8l-0.6-0.5c-0.7-0.8-1.1-1.8-1.1-2.9c0-1.1,0.3-2.1,1.1-2.9
	c0.3-0.3,0.6-0.5,1-0.8l-3.3-1C180.6,379.2,180.5,380.7,180.5,382.4L180.5,382.4z"
          />
          <path
            fill="#656565"
            d="M179.7,390.4l-9.8,2.8c0.6,1.6,1.2,3.3,2.1,5l8.8-5.1L179.7,390.4L179.7,390.4z"
          />
          <path
            fill="#EBEBEB"
            d="M168.1,393.7l1,2.8l1.2,2.6l1.5-1c-0.8-1.5-1.5-3.2-2.1-5L168.1,393.7L168.1,393.7z"
          />
          <path
            fill="#656565"
            d="M173.9,401.4c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.7,3.3,5.6,4.7v-0.1c1.8-2.8,3.6-5.6,5.4-8.4
	c-1.2-1-2.6-1.9-3.9-3.2c-1.1-1.2-2.2-2.5-3.2-3.7l-8.4,5.5L173.9,401.4L173.9,401.4z"
          />
          <path
            fill="#EBEBEB"
            d="M182.4,395.9c1,1.4,1.9,2.6,3.2,3.7c1.2,1.2,2.5,2.2,3.9,3.2l1.1-1.8l-2.9-2.3l-0.6,0.7
	c-1.5-1.1-2.9-2.5-3.9-3.9L182.4,395.9L182.4,395.9z"
          />
          <path
            fill="#656565"
            d="M197.7,406.3c-0.4,1.8-0.8,3.7-1.2,5.6l-0.7,4.1l-0.1,0.1c2.3,0.4,4.7,0.7,7.2,0.7c2.3,0,4.7-0.1,6.9-0.6
	l-0.1-0.1l-0.7-4.1c-0.3-1.9-0.7-3.9-1.2-5.6l-4.8,0.4L197.7,406.3L197.7,406.3z"
          />
          <path
            fill="#EBEBEB"
            d="M207.7,406.3l-0.6-2.1l-4.3,0.4l-4.7-0.4l-0.6,2.1l5.1,0.4"
          />
          <path
            fill="#EBEBEB"
            d="M200.7,396.4c-1,2.6-1.8,5.2-2.3,7.8l4.5,0.4l4.3-0.4c-0.7-2.8-1.5-5.2-2.5-7.8l-0.3-1h-3.6L200.7,396.4
	L200.7,396.4z"
          />
          <path
            fill="#EBEBEB"
            d="M195.8,416.2l-2.1,1.4c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.3,8.8-1l2.3-0.7l-0.6-1.8l-2.8-9.8
	c0.8-0.3,1.8-0.7,2.5-1.1l5.1,8.8l1,1.5l4.5-2.9c1.6-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3l-2.3,0.3c-1.2,1.9-2.9,3.9-4.7,5.6
	c-1.6,1.8-3.6,3.2-5.5,4.5l-0.3,2.2l-7.6-11.7c-2.8-4.4-5.2-8.8-7.8-13.3c-0.6,0.3-1.1,0.5-1.6,0.7c0.1,0.1,0.3,0.4,0.4,0.7
	c0.1,0.1,0.1,0.3,0.1,0.4c1.4,4.4,2.6,8.9,3.7,13.8c0.1,0.7,0.4,1.4,0.4,2.1l2.5,11.6l-1.9-1.2c-2.2,0.4-4.5,0.5-6.9,0.5
	C200.4,416.9,198.1,416.6,195.8,416.2L195.8,416.2z M192,415.3c-1.7-0.5-3.3-1.2-5-1.9l-0.8,1.5c1.7,0.8,3.4,1.6,5.2,2.2L192,415.3
	L192,415.3z"
          />
          <path
            fill="#656565"
            d="M192,415.3l2.6-9.8l-2.6-1.1l-5.1,8.8C188.7,414.2,190.4,414.7,192,415.3L192,415.3z"
          />
          <path
            fill="#EBEBEB"
            d="M208,358.3l-0.6,2.1c-0.7,2.3-1.4,4.8-2.2,7l-0.4,1.1l-0.1,0.5l-0.1,3.3c0.4,0.4,0.6,0.8,0.6,1.4v0.5
	c0.7-2.3,1.4-4.7,1.9-7l1.8-6.7l2.9-13.9l-1.9,1.5h-0.1c-0.4,3-1,6.1-1.6,9.1V358.3L208,358.3z"
          />
          <path
            fill="#EBEBEB"
            d="M211.8,346.9l-2.9,13.9l-1.8,6.7c-0.7,2.3-1.2,4.7-1.9,7l-0.1,0.3l-0.4,0.7c0.6,0.1,1.1,0.3,1.6,0.7
	c1.5-2.9,3.2-5.8,4.8-8.7l2.8-4.7l7.4-11.8v-0.1l0.4,2.3c1.8,1.2,3.6,2.6,5.2,4.3l0.4,0.4c1.6,1.7,3.2,3.4,4.5,5.4l2.3,0.3
	c-1.2-2.2-2.9-4.1-4.7-6.2l-0.8-1c-2.2-2.3-4.7-4.1-7.3-5.8h-0.1c-0.6-0.4-1.1-0.7-1.8-1l-1,1.6l-5,8.8c-0.8-0.4-1.8-0.8-2.6-1.1
	l2.6-9.8l0.6-1.8L211.8,346.9L211.8,346.9z"
          />
          <path
            fill="#EBEBEB"
            d="M221.2,351l-7.4,11.8l-2.8,4.7c-1.6,2.9-3.3,5.8-4.8,8.7l4.5-5l4.4-7.6l1.1-1.8l5.4-8.5v-0.1L221.2,351
	L221.2,351z M214.2,347.6l-0.6,1.8c1.6,0.5,3.3,1.2,5,2.1l1-1.6C217.8,348.8,216,348.1,214.2,347.6L214.2,347.6z"
          />
          <path
            fill="#656565"
            d="M221.8,353.4l-5.4,8.5c1.2,0.8,2.6,1.9,3.9,3.2l1.1,1.2c0.7,0.8,1.4,1.6,1.9,2.5c2.9-1.8,5.6-3.6,8.4-5.5
	h0.1c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4C225.2,355.8,223.5,354.5,221.8,353.4L221.8,353.4z"
          />
          <path
            fill="#EBEBEB"
            d="M221.2,366.1l-1.1-1.2c-1.2-1.2-2.5-2.2-3.9-3.2l-1.1,1.8c1.2,0.8,2.3,1.8,3.4,2.9l0.8,1
	c0.7,0.8,1.4,1.5,1.9,2.5l1.8-1.2C222.6,367.8,221.9,367,221.2,366.1L221.2,366.1z"
          />
          <path
            fill="#EBEBEB"
            d="M221.3,369.9l-7.4,4.5l-5,4.5c4.5-2.5,8.9-5.1,13.2-7.7l11.8-7.7l-2.3-0.3h-0.1c-2.8,1.8-5.5,3.7-8.4,5.5
	L221.3,369.9L221.3,369.9z"
          />
          <path
            fill="#EBEBEB"
            d="M219.6,367.4l-0.8-1c-1.1-1.1-2.2-2.1-3.4-2.9l-4.4,7.6l3.2,3.2l7.4-4.5C220.8,369,220.2,368.2,219.6,367.4
	L219.6,367.4z"
          />
          <path
            fill="#656565"
            d="M213.6,360.2l5-8.8c-1.7-0.8-3.2-1.5-5-2.1l-2.6,9.8C211.8,359.4,212.8,359.8,213.6,360.2L213.6,360.2z"
          />
          <path
            fill="#EBEBEB"
            d="M235.4,365.7l-1.5,0.8c0.8,1.5,1.5,3.2,2.1,5l1.6-0.5l-0.7-1.8C236.5,368.1,235.9,366.8,235.4,365.7
	L235.4,365.7z"
          />
          <path
            fill="#EBEBEB"
            d="M234.1,363.5l-11.8,7.7c-4.4,2.6-8.8,5.2-13.2,7.7c0.3,0.5,0.6,1.1,0.7,1.6c0.1-0.1,0.4-0.3,0.7-0.4l0.4-0.1
	c4.4-1.4,8.9-2.6,13.8-3.7l13.8-3l-1.4,2.1l0.1,1c0.3,1.9,0.4,3.9,0.4,5.9c0,2.1-0.1,4-0.4,5.9l-0.1,1.1l1.4,2.1
	c0.7-2.9,1.1-5.9,1.1-9.1s-0.4-6.1-1-8.9L238,371l-1.6,0.5l-9.9,2.8c-0.3-1-0.7-1.8-1.1-2.6l8.8-5.1l1.5-0.8L234.1,363.5
	L234.1,363.5z"
          />
          <path
            fill="#EBEBEB"
            d="M226.8,377.4l-2.1,0.5c0.3,1.4,0.4,2.9,0.4,4.4c0,1.5-0.1,3-0.4,4.5l2.1,0.5c0.3-1.6,0.4-3.3,0.4-5.1
	C227.3,380.6,227.1,378.9,226.8,377.4L226.8,377.4z"
          />
          <path
            fill="#656565"
            d="M227.3,382.4c0,1.8-0.1,3.4-0.4,5.1c3.2,0.8,6.5,1.4,9.9,1.8l0.1,0.1l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9
	c0-2.1-0.1-4-0.4-5.9l-0.1-1l-0.1,0.1c-3.3,0.4-6.6,1.1-9.8,1.8C227.1,378.9,227.3,380.6,227.3,382.4L227.3,382.4z"
          />
          <path
            fill="#EBEBEB"
            d="M226.8,377.4c3.2-0.8,6.5-1.4,9.8-1.8l0.1-0.1l1.4-2.1l-13.8,3c-4.7,1.1-9.2,2.3-13.8,3.7h0.4
	c0.6,0,1.1,0.1,1.5,0.5h3.2l0.6-0.1l0.4-0.1c2.6-1,5.2-1.8,7.8-2.5L226.8,377.4L226.8,377.4z"
          />
          <path
            fill="#EBEBEB"
            d="M224.8,377.8c-2.6,0.7-5.2,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1v3.6l0.7,0.1l0.4,0.1c2.6,1,5.2,1.8,7.8,2.5
	c0.3-1.5,0.4-3,0.4-4.5C225.2,380.9,225.1,379.4,224.8,377.8L224.8,377.8z"
          />
          <path
            fill="#656565"
            d="M226,374.4l9.9-2.8c-0.6-1.8-1.2-3.4-2.1-5l-8.8,5.1C225.5,372.6,225.7,373.4,226,374.4L226,374.4z"
          />
          <path
            fill="#EBEBEB"
            d="M238.1,391.5l-13.8-3c-4.7-1.1-9.4-2.5-13.8-3.7l-0.3-0.1l-0.7-0.5c-0.1,0.5-0.4,1.1-0.7,1.6
	c4.5,2.5,8.9,5,13.3,7.6l1.8,1.1c2.9,1.8,5.8,3.6,8.7,5.4l1.5,1l1-1.8l-1.5-0.8l-8.9-5c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.6l1.8,0.5
	L238.1,391.5L238.1,391.5z"
          />
          <path
            fill="#EBEBEB"
            d="M237.6,393.7l-1.8-0.5c-0.6,1.6-1.2,3.3-2.1,5l1.5,0.8c0.6-1.2,1.2-2.5,1.6-3.7L237.6,393.7L237.6,393.7z
	 M236.7,389.4c-3.4-0.5-6.7-1.2-9.9-1.9l-2.1-0.5c-2.6-0.7-5.2-1.5-7.8-2.5l-0.4-0.1l-0.7-0.1h-3.3c-0.4,0.4-0.8,0.6-1.4,0.6h-0.6
	c4.4,1.4,9.1,2.6,13.8,3.7l13.8,3L236.7,389.4L236.7,389.4z"
          />
          <path
            fill="#656565"
            d="M224.9,393.1l8.9,5c0.8-1.6,1.5-3.3,2.1-5l-9.8-2.6C225.6,391.3,225.3,392.3,224.9,393.1L224.9,393.1z"
          />
          <path
            fill="#EBEBEB"
            d="M210.7,371.1l-4.5,5c0.6,0.3,1.1,0.7,1.5,1.1c0.6,0.5,1,1.1,1.2,1.6l5-4.5L210.7,371.1L210.7,371.1z
	 M210.7,380.2l-0.4,0.1c-0.3,0.1-0.4,0.1-0.7,0.4l-0.1,0.1c-0.4,0.4-0.6,1-0.6,1.6c0,0.5,0.1,1.1,0.6,1.6h0.1l0.7,0.5l0.3,0.1h0.6
	c0.6,0,1.1-0.1,1.4-0.5l0.1-0.1c0.4-0.4,0.7-1,0.7-1.6s-0.3-1.2-0.7-1.6l-0.1-0.1c-0.4-0.4-0.8-0.5-1.5-0.5L210.7,380.2L210.7,380.2
	z M212.4,382.4c0,0.3-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8
	c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.7,0.1,0.8,0.4C212.4,381.7,212.4,382,212.4,382.4z"
          />
          <path
            fill="#EBEBEB"
            d="M212.1,381.4c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1
	c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-1C212.4,382,212.4,381.7,212.1,381.4z M213.5,382.4
	c0,0.5-0.3,1.1-0.7,1.7l-0.1,0.1h3.3v-3.6h-3.2l0.1,0.1C213.2,381.1,213.5,381.7,213.5,382.4L213.5,382.4z M206.2,388.6l4.5,5
	l3.3-3.2l-4.9-4.5c-0.3,0.5-0.7,1.1-1.1,1.5C207.3,387.7,206.8,388.2,206.2,388.6L206.2,388.6z"
          />
          <path
            fill="#EBEBEB"
            d="M206.1,388.6c2.5,4.5,5.1,8.9,7.8,13.3l7.6,11.7l0.3-2.2l0.1-0.3l-5.5-8.4l-1.1-1.8l-4.5-7.6L206.1,388.6
	L206.1,388.6z M209,385.7l5,4.5c2.5,1.4,5.1,2.9,7.6,4.4l1.8,1.1c2.9,1.8,5.6,3.6,8.4,5.4h0.1l2.3-0.3l-1.5-1
	c-2.9-1.9-5.8-3.7-8.7-5.4l-1.8-1.1C218,390.6,213.5,388.2,209,385.7L209,385.7z"
          />
          <path
            fill="#EBEBEB"
            d="M210.7,393.4l4.5,7.6c1.2-0.8,2.3-1.8,3.4-2.9c1.1-1.1,2.1-2.2,2.9-3.4c-2.5-1.5-5.1-3-7.6-4.4L210.7,393.4z
	 M204.4,392.1v3.2l0.3,1c1,2.6,1.8,5.2,2.5,7.8l0.6,2.1c0.4,1.9,0.8,3.7,1.2,5.6l0.7,4.1l0.1,0.1l1.9,1.2l-2.5-11.6l-0.4-2.1
	c-1.1-4.7-2.3-9.4-3.7-13.8v0.4C205,391.3,204.8,391.7,204.4,392.1L204.4,392.1z"
          />
          <path
            fill="#656565"
            d="M210.9,405.5l2.8,9.8c1.7-0.5,3.3-1.2,5-1.9l-5.1-8.8C212.5,404.9,211.7,405.2,210.9,405.5L210.9,405.5z
	 M216.4,402.7l5.5,8.4l-0.1,0.3c1.9-1.2,3.7-2.8,5.5-4.5c1.8-1.8,3.3-3.7,4.7-5.6h-0.1c-2.8-1.8-5.6-3.6-8.4-5.4
	c-1,1.4-1.9,2.6-3.2,3.9C219,400.8,217.8,401.8,216.4,402.7L216.4,402.7z"
          />
          <path
            fill="#EBEBEB"
            d="M220.1,399.6c1.2-1.2,2.2-2.5,3.2-3.9l-1.8-1.1c-0.8,1.2-1.8,2.3-2.9,3.4c-1.1,1.1-2.2,1.9-3.4,2.9l1.1,1.8
	C217.8,401.8,219,400.8,220.1,399.6L220.1,399.6z M219.4,414.8l-1-1.5c-1.6,0.8-3.2,1.5-4.9,1.9l0.6,1.8
	C216,416.5,217.6,415.7,219.4,414.8L219.4,414.8z"
          />
          <path
            fill="#656565"
            d="M202.9,331.2c14,0,26.1,5,36,15c1,1,1.9,2.1,2.8,3c8.1,9.4,12.1,20.4,12.1,33c0,1.5-0.1,3-0.3,4.5h8.8
	l-0.4-8.1c-0.8-16.6-6.9-30.7-18.3-42.1c-11.3-11.4-25-17.1-41.1-17.1c-11.7,0-22.1,3-31.4,9.1c-3.4,2.2-6.7,5-9.8,8.1
	c-9.6,9.8-15.3,21.2-17.1,34.3l-0.3,2.6l-0.1,4.7c0,2.2-0.1,4.3-0.3,6.2l8.1,0.5v-2.6c0-14.2,5-26.1,15-36.2
	C176.8,336.2,188.7,331.2,202.9,331.2L202.9,331.2z"
          />
          <path
            fill="#FFFFFF"
            d="M202.9,313.5c17.7,0,32.9,6.6,45.4,19.7c12.7,13.2,19.4,29.3,20.1,48.4l0.3,5.5l293.1,7.7l0.5-10.2
	c0.8-19.1,7.6-35.2,20.1-48.4c12.5-13.1,27.7-19.7,45.4-19.7c10.6,0,20.2,2.3,29,7l11.3-11.1c-12.1-7.7-25.9-11.6-41.3-11.6
	c-20.2,0-37.6,6.6-51.9,19.7c-4,3.6-8.8,7.8-14.3,12.7l1.7-7.6l2.1-9.5l-5.9-1c-1.2,17.3-2.8,34.3-4.5,50.9c4.3,0.5,5.9,2.3,5.2,5.1
	c-0.4,1.6-2.5,2.5-6.1,2.6l-0.3,2.3c-0.1,2.2-0.4,3.9-0.8,5.2c-0.8,2.2-2.8,3.2-6.1,3.2l-172-4.5l-74.1-1.9
	c-7.4-0.3-13.2-2.8-17.1-7.3c-17.5-20.8-34.5-43.6-51.3-68.4c-8.9-3.3-18.7-5.1-29.2-5.1c-16.2,0-30.7,4-43.2,12l8.9,14.9
	C178.3,317,190,313.5,202.9,313.5L202.9,313.5z M143.8,384.4c0.1-1.9,0.3-4,0.3-6.2l0.1-4.7l-5.6-0.8c-0.3,2.6-0.6,5.4-0.6,8.1
	l-0.1,3.6H143.8L143.8,384.4z M461.9,196.3l-0.3-1.2l-3.6,3.7l-0.5,0.6c0.1,0.3,0.3,0.6,0.7,0.8L461.9,196.3L461.9,196.3z"
          />
          <path
            fill="#656565"
            d="M458.1,200.2c0.5,0.6,1.2,1,2.3,1.4l4.3,1.5l4-4.3l-6.9-2.8L458.1,200.2L458.1,200.2z M522.2,240.3l3.9,1.9
	l4,2.2c8.8,4.7,17.5,9.6,26.3,15c9.1,5.5,17.6,11.4,25.7,17.7l0.1,0.1l8.9,0.3c-3.2-2.5-6.5-5-9.9-7.4c-8-5.9-16.2-11.4-24.8-16.8
	c-11.8-7-23.5-13.5-35.5-19.3L522.2,240.3L522.2,240.3z"
          />
          <path
            fill="#656565"
            d="M521.1,233.9c-49.2-24.1-99.2-37.4-149.7-40l-9.9-0.3l0.7,4.8l10.9,0.3c48.7,2.8,96.8,15.8,144.2,39.1
	c1.7,0.8,3.2,1.5,4.8,2.3L521.1,233.9L521.1,233.9z"
          />
          <path
            fill="#656565"
            d="M517.4,237.8l2.8,16.1h2.2l2.3,0.6l-2.5-14.2C520.5,239.5,518.9,238.6,517.4,237.8L517.4,237.8z"
          />
          <path
            fill="#FFFFFF"
            d="M520.1,254.3l-1.1,0.4c-2.2,1-4.1,2.2-5.9,3.9c-1.1,1-1.8,2.6-2.1,4.8l15.4,2.2h0.1c1.9,0.3,3.6,1.1,4.7,2.3
	c0.8,1,1.5,2.1,1.9,3.6l5.6,0.8l1.7,0.1l1.5-5.4c0.4-1.1,0.1-1.9-0.4-2.8c-2.5-2.9-5.5-5.2-8.8-7c-1.2-0.7-2.6-1.2-4-1.8
	c-1.2-0.4-2.8-0.8-4.1-1.1l-2.3-0.6h-0.3L520.1,254.3L520.1,254.3z"
          />
          <path fill="#3296BA" d="M520.1,253.9v0.4l1.9-0.4" />
          <path
            fill="#656565"
            d="M516.6,254.6c-2.2,1-4.1,2.2-5.8,3.9c-1.2,1.1-1.9,3-2.1,5.8v3.3c0,1.5,0.1,2.8,0.4,3.9
	c0.4,1.5,1.2,2.6,2.2,3.4c1.1,0.8,2.3,1.2,4,1.5l1.7,0.1c-1.2-0.3-2.3-0.7-3.2-1.4c-1.1-0.8-1.8-1.9-2.2-3.4
	c-0.3-0.7-0.4-1.5-0.4-2.2v-6.2c0.3-2.2,0.8-3.9,2.1-4.8c1.7-1.6,3.6-2.9,5.9-3.9l1.1-0.4l-0.1-0.4
	C518.9,253.9,517.6,254.2,516.6,254.6L516.6,254.6z"
          />
          <path
            fill="#EBEBEB"
            d="M520.1,253.9l-2.8-16.1c-47.5-23.2-95.5-36.2-144.2-39.1l12.1,60l123.8,12.5c-0.4-1.1-0.5-2.3-0.4-3.9v-3.3
	c0.1-2.8,0.8-4.7,2.1-5.8c1.7-1.6,3.6-2.9,5.8-3.9C517.7,254.2,518.9,253.9,520.1,253.9L520.1,253.9z"
          />
          <path
            fill="#656565"
            d="M511.6,271.6c0.4,1.5,1.2,2.6,2.2,3.4c0.8,0.7,1.9,1.1,3.2,1.4l7.2,0.7c0.5,0,1.1,0.1,1.4,0.5l14.9-5.2
	l-1.7-0.3l-5.6-0.8l-5.6-0.7l-16.5-1.4C511.2,270.3,511.3,271,511.6,271.6L511.6,271.6z"
          />
          <path
            fill="#EBEBEB"
            d="M511.2,269.4l16.5,1.4l5.6,0.7c-0.4-1.4-1.1-2.6-1.9-3.6c-1.1-1.2-2.8-2.1-4.7-2.3h-0.1l-15.4-2.2
	L511.2,269.4L511.2,269.4z M517.1,265.5c0.4-0.4,1-0.7,1.5-0.7s1.1,0.3,1.7,0.7c0.4,0.4,0.5,1,0.5,1.5s-0.1,1.1-0.5,1.5
	c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-1-0.7-1.5S516.7,265.9,517.1,265.5L517.1,265.5z"
          />
          <path
            fill="#EBEBEB"
            d="M516.4,267.1c0,0.5,0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.7-0.7c0.4-0.4,0.5-1,0.5-1.5
	s-0.1-1.1-0.5-1.5c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-1.1,0.3-1.5,0.7C516.7,265.9,516.4,266.4,516.4,267.1L516.4,267.1z M517.8,266.3
	c0.3-0.3,0.5-0.4,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.4-0.8,0.4
	s-0.5-0.1-0.8-0.4c-0.1-0.1-0.3-0.4-0.3-0.8C517.5,266.8,517.7,266.5,517.8,266.3L517.8,266.3z"
          />
          <path
            fill="#EBEBEB"
            d="M517.5,267.1c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4c0.1-0.1,0.3-0.4,0.3-0.8
	c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3C517.7,266.6,517.5,266.8,517.5,267.1L517.5,267.1z"
          />
          <path
            fill="#FFFFFF"
            d="M515.3,276.3c-1.7-0.3-2.9-0.7-4-1.5l-139.8-14.2l3.2,20.9l18.2,2.2l-0.1-0.4l-0.1-0.4v-0.3
	c0-0.7,0.3-1.2,0.8-1.8l0.7-0.5c1-0.7,2.5-1.1,4.3-1.5c3.9-0.8,8.5-1.1,13.9-0.5c5.2,0.4,9.8,1.5,13.6,3l0.4,0.1
	c3.2,1.2,5.1,2.8,5.5,4.1c0.1,0.3,0.1,0.5,0.1,0.8c0,0.7-0.3,1.2-1,1.8l128.1,15.1l-5.5-24.1l-12-1.2l0.7,2.1l2.2,6.5
	c0.5,2.1,0.3,3.7-1,5.2c-1.2,1.5-2.9,2.6-5,3.2c-4.7,0.5-7.7-1.5-9.4-6.3l-2.8-8l-0.7-1.9c-0.1-0.4-0.4-0.7-0.5-0.8
	c-0.4-0.4-0.8-0.5-1.4-0.5l-7.2-0.7L515.3,276.3L515.3,276.3z M509.1,271.4l-123.8-12.5l-14.2-1.4l0.5,3.2l139.8,14.2
	C510.4,274,509.5,272.9,509.1,271.4L509.1,271.4z"
          />
          <path
            fill="#EBEBEB"
            d="M519.7,225.8c17.3,8.1,37.1,17.7,59.4,29l0.3,0.1c2.2,1,4.5,1.9,7,2.6l3.3,0.8
	c-38.8-23.1-79.1-42.9-121.1-59.4l-4,4.3C483.9,210,502.2,217.6,519.7,225.8L519.7,225.8z"
          />
          <path
            fill="#FFFFFF"
            d="M432,285.5c-0.5-1.4-2.3-2.8-5.5-4.1l-0.4-0.1c-3.9-1.5-8.3-2.5-13.6-3c-5.5-0.5-10-0.3-13.9,0.5
	c-1.8,0.4-3.3,1-4.3,1.5l1.2-0.3c0.7-0.3,1.6-0.5,2.6-0.8l4.5-0.7h1c0.5,0,0.8,0.1,1.1,0.5c0.3,0.4,0.4,1,0.4,1.6l19,2.3v-1
	c0.1-0.7,0.4-1,1.1-0.7l1,0.3c1.8,0.8,3.2,1.5,4.1,2.3C431.1,284.7,431.7,285.1,432,285.5L432,285.5z"
          />
          <path
            fill="#FFFFFF"
            d="M430.2,284.4c-0.8-0.3-2.1-0.5-3.4-0.8l-2.6-0.3l-19-2.3l-1.9-0.3l-0.3,5.6l1.9,0.1l18.8,1.9l5.9,0.5
	l0.8-0.4l-0.8,0.4h1l0.8-0.5c0.6-0.5,1-1.1,1-1.8c0-0.3,0-0.5-0.1-0.8C431.7,285.1,431.1,284.7,430.2,284.4L430.2,284.4z"
          />
          <path
            fill="#FFFFFF"
            d="M424,282.4v1l2.6,0.3c1.5,0.3,2.6,0.4,3.4,0.8c-1-0.8-2.3-1.5-4.1-2.3l-1-0.3
	C424.4,281.4,424.1,281.7,424,282.4L424,282.4z M424.8,290.2c-0.5,0-1-0.1-1.2-0.4c-0.1-0.1-0.1-0.4-0.1-0.8l0.1-0.7l-18.8-1.9
	l-0.4,0.8c-0.4,0.5-1,0.7-1.7,0.5h-0.1l-0.1-0.1c-4.3-1.2-6.7-2.1-7.3-2.8l-0.8-0.4l-0.8-0.5c0.8,1.2,2.6,2.5,5.4,3.6
	c3.9,1.5,8.5,2.6,13.9,3.2c5.2,0.5,9.9,0.4,13.6-0.4l0.3-0.1c1.8-0.4,3.2-1,4.1-1.5h-1c-0.8,0.4-2.1,0.8-3.3,1.1L424.8,290.2
	L424.8,290.2z"
          />
          <path
            fill="#FFFFFF"
            d="M426.2,289.9c1.2-0.3,2.5-0.7,3.3-1.1l-5.9-0.5l-0.1,0.7c0,0.3,0,0.5,0.1,0.8c0.3,0.4,0.5,0.5,1.2,0.4
	L426.2,289.9L426.2,289.9z"
          />
          <path
            fill="#FFFFFF"
            d="M426,290.4c-3.9,0.8-8.4,0.8-13.6,0.4c-5.5-0.5-10-1.5-13.9-3.2c-2.9-1.1-4.7-2.3-5.4-3.6l-0.1-0.3
	l-18.2-2.2l0.3,6.5c62.7,8.8,123.8,17.7,183.4,27l0.8-11.7l-128.1-15.1l-0.8,0.5c-1,0.5-2.3,1.1-4.1,1.5L426,290.4L426,290.4z
	 M404.6,279.2c-0.1-0.4-0.5-0.5-1.1-0.5h-1l0.4,1.9l1.9,0.3C405,280.2,404.9,279.8,404.6,279.2L404.6,279.2z"
          />
          <path
            fill="#FFFFFF"
            d="M404.6,286.5l-1.9-0.1l-0.5,1.5l0.1,0.1h0.1c0.7,0.1,1.2,0,1.6-0.5L404.6,286.5L404.6,286.5z M357.4,256.1
	l-132.3-12.9l-5.2-0.6l-2.1-0.4l-1.6,2.8l3.4,0.6l152.1,15.1l-0.5-3.2 M392.9,283.7l0.1,0.3l0.8,0.5l0.8,0.4
	c0.8,0.3,1.6,0.5,2.9,0.7l5.1,0.5l0.3-5.6l-7.6-0.5l-1.2,0.3l-0.7,0.5c-0.5,0.5-0.8,1.1-0.8,1.8v0.3l0.1,0.4L392.9,283.7
	L392.9,283.7z M399.6,281.4c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1s-0.1,0.8-0.4,1.1c-0.3,0.3-0.5,0.4-0.8,0.5h-0.3
	l-0.5-0.1l-0.5-0.4c-0.3-0.3-0.5-0.7-0.5-1.1s0.1-0.8,0.5-1.1C398.8,281.5,399.2,281.4,399.6,281.4L399.6,281.4z"
          />
          <path
            fill="#FFFFFF"
            d="M398.5,281.8c-0.3,0.3-0.5,0.7-0.5,1.1s0.1,0.8,0.5,1.1l0.5,0.4l0.8,0.1c0.3-0.1,0.7-0.3,0.8-0.5
	c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4S398.8,281.5,398.5,281.8L398.5,281.8z"
          />
          <path fill="#3296BA" d="M399.1,284.4l0.5,0.1h0.3" />
          <path
            fill="#FFFFFF"
            d="M398.1,279.3c-1,0.3-1.9,0.5-2.6,0.8l7.6,0.5l-0.4-1.9L398.1,279.3L398.1,279.3z M397.7,285.8
	c-1.1-0.1-2.1-0.4-2.9-0.7c0.5,0.5,3,1.5,7.3,2.8l0.5-1.5L397.7,285.8L397.7,285.8z M374.7,281.5l-3.2-21l-152.1-15.1l-3.3-0.6
	c-2.2,6.7-3.4,13.6-3.7,20.5l16.5,1.1v-0.5c0-0.7,0.3-1.2,0.8-1.8l0.7-0.5c1-0.5,2.5-1.1,4.3-1.5c3.9-0.8,8.5-1,13.9-0.4
	c5.2,0.4,9.8,1.5,13.6,3l0.4,0.1c3.2,1.2,5.1,2.8,5.6,4.1l0.1,0.7l34.5,3.4L374.7,281.5L374.7,281.5z"
          />
          <path
            fill="#FFFFFF"
            d="M374.7,281.5l-71.8-8.4l-34.5-3.4v0.1c0,1-0.5,1.8-1.6,2.3c-0.5,0.4-1.2,0.7-1.9,1c37.3,5,74.1,9.9,110.3,15
	L374.7,281.5L374.7,281.5z"
          />
          <path fill="#656565" d="M373.2,198.9l-11-0.4l8.9,59l14.2,1.4" />
          <path
            fill="#FFFFFF"
            d="M215.8,184.1l-9.8,1.4l0.3,2.1l9.6-1.4l8.9-0.8l-0.3-2.1"
          />
          <path
            fill="#FFFFFF"
            d="M211.8,182.6l-5,2.5l-0.8,0.4l9.8-1.4l8.8-0.8l-9.6-1.1C213.8,182.2,212.7,182.4,211.8,182.6L211.8,182.6z"
          />
          <path
            fill="#656565"
            d="M361.5,193.6c-42.6-0.8-82.8,2.1-120.5,8.7l-3.3,5.5l6.3-1.1c34.1-5.8,70-8.5,107.7-8.4
	c3.4,0,6.9,0,10.5,0.1L361.5,193.6L361.5,193.6z"
          />
          <path
            fill="#656565"
            d="M351.8,198.3l5.6,57.6l13.8,1.4l-8.9-59C358.8,198.5,355.3,198.3,351.8,198.3L351.8,198.3z M231.1,209.1
	l-18.2,31.1c1.4,0.8,3,1.5,4.8,1.9l19.9-34.4L231.1,209.1L231.1,209.1z M225.1,243.2l19-36.5l-6.3,1.1l-19.9,34.4l2.1,0.4"
          />
          <path
            fill="#FFFFFF"
            d="M262.9,267.1c1.5,0.1,2.6,0.4,3.4,0.8c-1-0.8-2.3-1.6-4.1-2.3l-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1
	c-0.7-0.3-1,0-1.1,0.5v1L262.9,267.1L262.9,267.1z"
          />
          <path
            fill="#FFFFFF"
            d="M260.3,266.7l-19-2.5l-1.9-0.1l-0.3,5.5l1.9,0.1l18.8,1.9l5.9,0.5l1.1-0.1c1.1-0.7,1.7-1.5,1.7-2.3v-0.1
	l-0.1-0.7c-0.3-0.5-0.8-0.8-1.8-1.2c-0.8-0.3-2.1-0.5-3.4-0.8L260.3,266.7L260.3,266.7z"
          />
          <path
            fill="#FFFFFF"
            d="M260.3,265.7c0-0.7,0.4-0.8,1.1-0.5c0.1,0,0.4,0.1,0.5,0.1l0.4,0.1c1.8,0.7,3.3,1.5,4.1,2.3
	c1,0.3,1.6,0.7,1.8,1.2c-0.5-1.5-2.3-2.9-5.6-4.1l-0.4-0.1c-3.7-1.5-8.3-2.5-13.6-3c-5.5-0.5-10.2-0.4-13.9,0.4
	c-1.8,0.4-3.3,1-4.3,1.5l1.2-0.1c0.7-0.3,1.6-0.5,2.6-0.8c1.4-0.3,2.9-0.5,4.5-0.7h1c0.6,0,0.8,0.1,1.1,0.5c0.3,0.4,0.4,1,0.4,1.6
	l19,2.5L260.3,265.7L260.3,265.7z M259.7,272.5l0.1-0.7l-18.8-1.9l-0.4,0.8c-0.4,0.5-1,0.7-1.6,0.5h-0.1l-0.1-0.1
	c-3.6-1-5.8-1.8-6.9-2.3l-0.6-0.4l-0.8-0.4l-0.8-0.5c0.3,0.4,0.6,0.8,1.1,1.2c1,0.8,2.5,1.6,4.4,2.3c3.9,1.5,8.5,2.6,13.9,3.2
	c5.2,0.4,9.9,0.3,13.6-0.4l2.5-0.5l-1-0.1l-2.1,0.5l-0.7,0.1c-0.5,0-1-0.1-1.2-0.4C259.7,273,259.6,272.7,259.7,272.5L259.7,272.5z"
          />
          <path
            fill="#FFFFFF"
            d="M259.9,273.2c0.3,0.3,0.5,0.4,1.2,0.4l0.7-0.1l2.1-0.5l1.9-0.7l-5.9-0.5l-0.1,0.7
	C259.6,272.7,259.7,273,259.9,273.2L259.9,273.2z"
          />
          <path
            fill="#3296BA"
            d="M266.7,272.2l-1.1,0.1l-1.9,0.7l1,0.1C265.5,272.9,266.2,272.6,266.7,272.2L266.7,272.2z"
          />
          <path
            fill="#FFFFFF"
            d="M248.7,274.1c-5.5-0.5-10.2-1.5-13.9-3.2c-1.9-0.8-3.4-1.5-4.4-2.3l-17.5-2.2l8.3,19.4
	c0.8,2.2,1.8,4,2.8,5.2c10.5,3.2,19.9,8.7,28.5,16.4c9.6,8.8,16.2,19,19.9,30.5c-3.9-7.7-9.4-14.7-16.5-21
	c-7.3-6.5-15.4-11.4-24.1-14.7c16.8,24.8,33.8,47.6,51.3,68.4c3.9,4.5,9.5,7,17.1,7.3l74.1,1.9l2.8-18.2
	c-28.9-3.6-58.2-8.1-88.3-13.6l87.8,5.9c0.4-22,0-44-1.1-65.9c-36.3-5.1-73-10-110.3-15l-2.5,0.5
	C258.5,274.5,253.9,274.7,248.7,274.1L248.7,274.1z"
          />
          <path fill="#3296BA" d="M199.6,264.8l13.2,1.6l-0.4-1" />
          <path
            fill="#FFFFFF"
            d="M212.8,266.4l17.5,2.2c-0.4-0.4-0.8-0.8-1.1-1.2l-0.3-0.7v-0.1l-16.5-1.1L212.8,266.4L212.8,266.4z
	 M234.3,262.8c-1,0.3-1.9,0.4-2.6,0.8l7.6,0.5l-0.4-1.9C237.3,262.3,235.8,262.4,234.3,262.8L234.3,262.8z M240.9,262.6
	c-0.1-0.4-0.6-0.5-1.1-0.5h-1l0.4,1.9l1.9,0.1C241.3,263.7,241,263.1,240.9,262.6L240.9,262.6z M240.9,269.9l-1.9-0.1l-0.6,1.5
	l0.1,0.1h0.1c0.7,0.1,1.2-0.1,1.6-0.5L240.9,269.9L240.9,269.9z"
          />
          <path
            fill="#FFFFFF"
            d="M239.4,264.1l-7.6-0.5l-1.2,0.1l-0.7,0.5c-0.6,0.5-0.8,1.1-0.8,1.8v0.7l0.3,0.7l0.8,0.5l0.8,0.4l2.2,0.5
	l5.6,0.5L239.4,264.1L239.4,264.1z"
          />
          <path
            fill="#FFFFFF"
            d="M238.9,269.6l-5.6-0.5l-2.2-0.5l0.6,0.4c1.1,0.5,3.3,1.2,6.9,2.3L238.9,269.6z"
          />
          <path
            fill="#EBEBEB"
            d="M225.1,243.2l132.3,12.9l-5.6-57.6c-37.7-0.3-73.6,2.6-107.7,8.4L225.1,243.2L225.1,243.2z"
          />
          <path
            fill="#FFFFFF"
            d="M698.3,291.2c7.6,2.3,13.9,4.5,19.3,6.9c5.9,2.5,11.7,5.1,17.3,7.7c3,1.4,6.1,2.9,8.8,4.5l3.4-2.2
	c-3.9-2.5-8-4.7-12.4-6.7c-35.5-15.8-76.1-28.6-121.7-38.1l-1.1-0.3c-7.3-1.5-14.6-3-22.1-4.5h-0.1l-3.3-0.8l-5.2,12.4l9.9,7.4
	l43.1,5.9l28.8,4c5,0.5,9.9,1,14.7,1h0.4c3.3,0,6.7,0.3,10,0.8h0.4C691.8,289.5,695,290.2,698.3,291.2L698.3,291.2z"
          />
          <path
            fill="#EBEBEB"
            d="M688.5,289.1c10.3,2.1,21.2,5.9,32.9,11.7c10.3,5.1,18.6,9.5,24.9,13.1l1.2,0.8c0.8,0.4,1.2,1,1.2,1.6
	c0,0.5-0.1,1.2-0.3,1.9l-1,2.9l-0.1,0.3c-0.4,0.5-1.1,0.7-2.2,0.4l-3.3-0.7l-3.6-0.8l-1.9-0.4l-10.3-2.2l-0.3-0.1
	c-11.3-2.8-23.1-5.9-35.4-9.5l-4.1-1.2h-0.4c-0.1-0.1-0.3-0.1-0.4-0.1l-0.4-0.1c-2.9-1-6.7-2.1-11.4-3.3l2.6,1.1l13.5,4.3h0.1
	c12.5,3.9,24.5,7.2,36,10l0.3,0.1l19.1,4.4l2.3-0.1l1-0.5l0.5-0.8l1-2.9c0.5-1.9,0.3-3.6-0.8-5l-0.1-0.1l-5.4-3.3
	c-2.9-1.6-5.8-3.2-8.8-4.5c-5.8-2.6-11.6-5.1-17.3-7.7c-5.2-2.2-11.7-4.5-19.3-6.9C695,290.2,691.8,289.5,688.5,289.1L688.5,289.1z"
          />
          <path
            fill="#EBEBEB"
            d="M688.2,289c-3.4-0.5-6.7-0.8-10-0.8h-0.4c-5,0-9.8-0.3-14.7-1l-28.8-4l4.5,2.5l23.2,3
	c3.9,0.5,7.6,0.8,11.1,0.8c5.1-0.1,10.5,0.3,16.1,1.2c9.6,1.6,20.4,5.5,32.1,11.3c13.2,6.5,20.9,10.5,23.1,11.8
	c0.5,0.3,1,0.8,1.1,1.4c0.1,0.4,0.1,0.8,0,1.2l-0.1,0.7l-0.6,1.5c-0.8,1.6-1.8,2.3-2.9,2.2l3.3,0.7c1.1,0.3,1.9,0.1,2.2-0.4l0.1-0.3
	l1-2.9c0.1-0.7,0.3-1.4,0.3-1.9c0-0.7-0.4-1.2-1.2-1.6l-1.2-0.8c-6.3-3.6-14.6-8-24.9-13.1c-11.6-5.8-22.6-9.6-32.9-11.7L688.2,289
	L688.2,289z"
          />
          <path
            fill="#EBEBEB"
            d="M662,288.8l-23.2-3c6.1,3.3,12.2,6.5,18.4,9.5l18.7,2.2c1.5,0.4,2.8,1.1,3.7,2.2l5.5,6.7l0.4,0.1
	c0.1,0,0.3,0.1,0.4,0.1l0.4-0.6l0.3-0.3c0.3-0.1,0.4-0.3,0.7-0.4h1.2l22.7,6.2l24.6,5.9l2.3,2.5l3.6,0.8c1.1,0.1,2.1-0.6,2.9-2.2
	l0.5-1.5l0.1-0.7c0.1-0.4,0.1-0.8,0-1.2c-0.1-0.5-0.4-1.1-1.1-1.4c-2.1-1.4-9.8-5.2-23.1-11.8c-11.7-5.8-22.4-9.5-32.1-11.3
	c-5.6-1-11-1.4-16.1-1.2C669.5,289.7,665.8,289.4,662,288.8L662,288.8z M689.7,292c9.6,1.8,20.1,5.5,31.6,11.1
	c12.5,6.2,20.1,10,22.6,11.6c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.4,0.1,0.8,0,1.2l-1.1,2.2c-0.4,0.8-1.2,1.2-2.3,1.1l-2.6-0.4l-2.3-2.2
	l-8.3-1.9l-7.2-1.6l-8.7-2.2l-1.1-0.3l-12.9-3.4l-1-0.3l-5.1-1.5l-2.3-0.7c-2.3-1-4.1-2.2-5.6-3.7l-2.5-2.8l-2.6-2.8l-17.2-1.8
	l-4.1-3.7l21.9,2.3c0.7-0.4,1.4-0.7,2.1-1c0.8-0.3,1.8-0.4,2.9-0.5C686,291.3,687.8,291.5,689.7,292L689.7,292z"
          />
          <path
            fill="#EBEBEB"
            d="M689.6,294.8l0.8,0.7h1.8l17.2,10.7l0.4,1h10.7v6.6l7.2,1.6h16.6c-0.1-0.3-0.3-0.5-0.5-0.7
	c-2.6-1.5-10.2-5.4-22.6-11.6c-11.6-5.6-22-9.4-31.6-11.1c-1.9-0.4-3.8-0.7-5.4-0.5c2.5,0.4,4.3,1.4,5.2,2.8v0.5L689.6,294.8
	L689.6,294.8z"
          />
          <path
            fill="#EBEBEB"
            d="M689.6,294.2c-1.1-1.4-2.9-2.2-5.2-2.8c-1,0-1.9,0.3-2.9,0.5v6.9l2.5,2.8c1.4,1.5,3.3,2.8,5.6,3.7
	L689.6,294.2L689.6,294.2z M690.6,295.4c1.4,1.2,2.1,3,2.1,5.2v2.6c-0.1,1.1-0.3,1.9-0.7,2.8l5.1,1.5l-4.7-12.1L690.6,295.4
	L690.6,295.4z"
          />
          <path
            fill="#EBEBEB"
            d="M689.6,294.8v10.6l2.3,0.7c0.4-0.8,0.5-1.8,0.7-2.8v-2.6c0-2.2-0.7-4-2.1-5.2L689.6,294.8L689.6,294.8z
	 M657.6,290.5l4.1,3.7L679,296l0.4-3.2"
          />
          <path
            fill="#EBEBEB"
            d="M679,296l2.6,2.8v-6.9c-0.8,0.3-1.5,0.5-2.1,1L679,296L679,296z M710.9,311.1l-1.8-4h-11.3l0.1,0.5"
          />
          <path
            fill="#EBEBEB"
            d="M694,297.5l14.9,9.2l0.3,0.4l1.8,4l1.1,0.3l-2.1-4.3l-0.4-1l-17.2-10.7l4.7,12l1,0.3l-0.1-0.5"
          />
          <path
            fill="#EBEBEB"
            d="M694,297.5l3.8,9.6h11.3l-0.3-0.4 M720.7,313.6v-6.5H710l2.1,4.3 M738.6,319.5l2.6,0.4
	c1.1,0.1,1.8-0.1,2.3-1.1l1.1-2.2c0.1-0.4,0.1-1,0-1.2H728l8.3,1.9L738.6,319.5L738.6,319.5z M657.1,295.3
	c5.4,2.6,10.9,5.2,16.2,7.7l0.3,0.1c4.7,1.2,8.5,2.5,11.4,3.3l-5.5-6.7c-1-1.1-2.2-1.8-3.7-2.2L657.1,295.3L657.1,295.3z"
          />
          <path
            fill="#FFFFFF"
            d="M634.2,283.3l-43.1-5.9l-5.8,2.1c-1.7,0.5-3.3,0.8-5.1,1.1c-4.3,0.7-8.9,0.7-14-0.1l-12.2-1.2l5.5,24.1
	l24.1,2.9c12.2-7.7,26.1-11.6,41.8-11.6c18,0,33.7,5.1,47.2,15.1l4.7-5.2l-0.8-0.4l-2.6-1.1l-0.3-0.1c-5.5-2.5-10.9-5.1-16.2-7.7
	c-6.2-3-12.4-6.2-18.4-9.5L634.2,283.3L634.2,283.3z M613.1,263.2c45.7,9.5,86.3,22.1,121.7,38.1c4.4,2.1,8.5,4.3,12.4,6.7l5.2-2.5
	c-1-0.8-2.1-1.5-3.2-2.2c-7.2-4.3-14.7-8.3-22.7-11.7c-30-12.9-62.3-22.4-97.1-28.5L613.1,263.2L613.1,263.2z"
          />
          <path
            fill="#656565"
            d="M526,242.2l2.8,13.3c1.4,0.5,2.8,1.1,4,1.8l-1-4.7c-0.3-1.8,0.5-2.3,2.3-1.6c6.7,3.7,13.5,7.6,20.1,11.7
	c5.5,3.4,10.9,7.3,16.1,11.6c-1.4,0.5-4.4,0.7-8.9,0.3l-20.9-2.2v0.1c-0.1,0.7,0,1.5,0.3,2.2l11.8,1.2l13.5,1.4l10.5,0.5
	c1.9-0.1,3.7-0.4,5.5-0.7h0.1L582,277c-8.1-6.3-16.6-12.2-25.7-17.7c-8.7-5.4-17.5-10.3-26.3-15L526,242.2L526,242.2z"
          />
          <path
            fill="#FFFFFF"
            d="M591.1,277.4l-8.9-0.3H582c-1.8,0.4-3.6,0.5-5.5,0.7l-10.5-0.5l-13.5-1.4l1.1,2.9v0.4l12.2,1.2
	c5.1,0.8,9.8,0.8,14,0.1c1.8-0.3,3.4-0.7,5.1-1.1L591.1,277.4L591.1,277.4z"
          />
          <path
            fill="#EBEBEB"
            d="M532.6,257.3c3.3,1.8,6.2,4.1,8.8,7c0.7,0.7,0.8,1.6,0.4,2.8l-1.5,5.4l20.9,2.2c4.5,0.4,7.6,0.4,8.9-0.3
	c-5.2-4.4-10.6-8.3-16.1-11.6c-6.7-4.1-13.3-8-20.1-11.7c-1.9-0.8-2.8-0.3-2.3,1.6L532.6,257.3L532.6,257.3z"
          />
          <path
            fill="#656565"
            d="M528.8,255.5l-2.8-13.3l-3.9-1.9l2.5,14.2C526,254.7,527.4,255.1,528.8,255.5L528.8,255.5z"
          />
          <path
            fill="#3296BA"
            d="M540.5,272.5l-1.7-0.1L540.5,272.5L540.5,272.5z"
          />
          <path
            fill="#FFFFFF"
            d="M553.8,278.8l-1.1-2.9l-12-1.2l1.2,3.3l11.8,1.2v-0.3"
          />
          <path
            fill="#656565"
            d="M544.9,286.5l-2.2-6.5c-5.2,1.6-10.6,1.8-15.8,0.5l2.8,8c1.7,4.8,4.7,7,9.4,6.3c2.1-0.5,3.7-1.6,5-3.2
	S545.4,288.6,544.9,286.5L544.9,286.5z"
          />
          <path
            fill="#656565"
            d="M526.2,278.7l0.7,1.9c5.2,1.4,10.6,1.1,15.8-0.5L542,278l-1.1-3.3c-0.3-0.7-0.4-1.5-0.3-2.2l-14.9,5.2
	C525.8,278,526,278.2,526.2,278.7L526.2,278.7z"
          />
          <path
            fill="#FFFFFF"
            d="M558.5,315.1l5.9,1c4.8-1.6,9.4-3.7,13.5-6.1c1.8-1.4,3.6-2.6,5.4-3.7l-24.1-2.9L558.5,315.1L558.5,315.1z
	 M560.8,333c5.6-4.8,10.3-8.9,14.3-12.7c14.3-13.1,31.6-19.7,51.9-19.7c15.4,0,29.2,3.9,41.3,11.6l4.1-2.3
	c-13.5-10-29.2-15.1-47.2-15.1c-15.7,0-29.7,3.9-41.8,11.6h0.3c-1.8,1.4-3.7,2.6-5.6,3.7c-1.8,1.4-3.6,2.9-5.4,4.4
	c-3.9,3.4-7.3,7.2-10.2,11.1L560.8,333L560.8,333z"
          />
          <path
            fill="#FFFFFF"
            d="M564.4,315.9l-2.1,9.5c2.9-3.9,6.3-7.6,10.2-11.1c1.8-1.5,3.6-3,5.4-4.4
	C573.8,312.2,569.4,314.3,564.4,315.9L564.4,315.9z"
          />
          <path
            fill="#3296BA"
            d="M583.4,306.2c-1.8,1.1-3.6,2.3-5.4,3.7c2.1-1.1,3.9-2.3,5.6-3.7L583.4,306.2L583.4,306.2z"
          />
          <path
            fill="#656565"
            d="M591.4,346.2c10-9.9,22-15,36.2-15c14.2,0,26.1,5,36.2,15c9.9,9.9,15,22,15,36.2c0,3.7-0.4,7.2-1.1,10.6
	h10.5c-0.7-3.3-1.1-7.3-1.1-11.7c-0.1-2.5-0.3-5-0.5-7.3c-1.7-13.1-7.4-24.5-17.1-34.3c-5.1-5.1-10.7-9.1-16.8-11.8
	c-7.4-3.4-15.7-5.2-24.5-5.2c-16.1,0-29.7,5.8-41.1,17.1c-11.4,11.4-17.5,25.5-18.3,42.1l-0.5,11h9.2c-0.7-3.4-1-6.9-1-10.5
	c0-12.7,4-23.7,12.1-33C589.5,348.3,590.4,347.2,591.4,346.2L591.4,346.2z"
          />
          <path
            fill="#656565"
            d="M591.4,346.2c-1,1-1.9,2.1-2.8,3c-8.1,9.4-12.1,20.4-12.1,33c0,3.6,0.3,7.2,1,10.5
	c1.9,9.6,6.6,18.2,13.9,25.7l2.1,1.9c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15c1.4-1.5,2.8-2.9,4-4.4
	c5.1-6.3,8.4-13.3,9.9-21.2c0.7-3.4,1.1-6.9,1.1-10.6c0-14.2-5-26.1-15-36.2c-10-9.9-22-15-36.2-15
	C613.4,331.2,601.4,336.2,591.4,346.2L591.4,346.2z M601,355.7c2.5-2.5,5.2-4.7,8.3-6.2c2.3-1.4,5-2.5,7.6-3.3c3.4-1,7-1.5,10.7-1.5
	c3.9,0,7.4,0.5,10.7,1.5c5.8,1.6,11.1,4.8,15.7,9.5h0.1l1.5,1.8c2.3,2.5,4.1,5.2,5.6,8.1c2.5,5.1,3.9,10.6,3.9,16.8
	c0,6.1-1.2,11.7-3.9,16.8c-1.8,3.6-4.1,6.7-7.2,9.8c-1.1,1.1-2.3,2.2-3.6,3.2c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8
	l-3.4-3.2c-3.2-3.3-5.8-6.7-7.6-10.6c-2.2-4.8-3.4-10.2-3.4-16c0-5.8,1.1-11.1,3.4-16c1.5-3.2,3.4-6.2,5.9-8.9L601,355.7L601,355.7z
	"
          />
          <path
            fill="#EBEBEB"
            d="M599.4,357.5c-2.5,2.8-4.4,5.8-5.9,8.9c-2.2,4.8-3.4,10.2-3.4,16c0,5.8,1.1,11,3.4,16
	c1.8,3.9,4.3,7.4,7.6,10.6l3.4,3.2c6.6,5.2,14.3,7.8,23.1,7.8c8.8,0,16.5-2.6,23.1-7.8c1.2-1,2.3-2.1,3.6-3.2c3-3,5.4-6.3,7.2-9.8
	c2.5-5.1,3.9-10.6,3.9-16.8c0-6.1-1.2-11.7-3.9-16.8c-1.4-2.9-3.3-5.6-5.6-8.1l-1.5-1.8h-0.1c-4.5-4.5-9.9-7.7-15.7-9.5
	c-3.4-1-7-1.5-10.7-1.5c-3.9,0-7.4,0.5-10.7,1.5c-2.6,0.8-5.2,1.8-7.6,3.3c-2.9,1.6-5.6,3.7-8.3,6.2L599.4,357.5L599.4,357.5z
	 M601.7,356.5c2.3-2.3,4.7-4.1,7.3-5.8h0.1l1.8-1c1.8-0.8,3.4-1.6,5.4-2.2l2.2-0.7h0.1l3.4-0.7l5.5-0.4l5.5,0.4
	c1.2,0.1,2.3,0.4,3.4,0.7l2.3,0.7c1.8,0.5,3.6,1.4,5.4,2.2c0.7,0.3,1.4,0.7,2.1,1.1c2.5,1.5,4.8,3.4,7,5.5l0.8,1
	c1.9,2.1,3.4,4.1,4.8,6.3c0.4,0.7,0.8,1.4,1.1,2.1l1.2,2.5c0.4,1,0.7,1.9,1.1,2.8c0.3,0.7,0.4,1.4,0.5,2.2c0.7,2.9,1.1,5.9,1.1,9.1
	s-0.4,6.1-1,8.9l-0.7,2.5l-1,2.8l-1.2,2.6l-1.1,2.1c-1.5,2.5-3.4,4.8-5.6,7c-1.5,1.5-3,2.8-4.5,4c-0.8,0.5-1.7,1.2-2.5,1.8l-1.9,1.1
	c-1.7,0.8-3.4,1.6-5.2,2.2l-2.2,0.5c-2.9,0.7-5.9,1.1-9.1,1.1c-3,0-6.1-0.4-8.8-1l-2.3-0.7c-1.8-0.5-3.6-1.2-5.4-2.2l-4.7-2.9
	c-1.5-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.7-5.6-7.3l-1-1.8c-0.7-1.2-1.2-2.5-1.7-3.7l-0.5-1.6l-0.7-2.2c-0.7-2.9-1.1-5.9-1.1-9.1
	s0.4-6.1,1-8.9l0.5-2.3l0.7-1.8c0.4-1.2,1-2.3,1.5-3.4l1.2-2.2c1.2-2.2,2.9-4.1,4.7-6.2L601.7,356.5L601.7,356.5z"
          />
          <path
            fill="#EBEBEB"
            d="M600.9,357.5c-1.8,1.9-3.4,4-4.7,6.2l2.3-0.3c1.2-1.9,2.8-3.7,4.5-5.4l0.4-0.4c1.7-1.7,3.4-3,5.2-4.3
	l0.4-2.3l7.4,11.8l2.8,4.7c1.7,2.9,3.3,5.8,4.8,8.7c0.5-0.3,1.1-0.6,1.7-0.7l-0.5-0.7l-0.1-0.3l-3.7-13.6l-2.9-13.9h-0.1l-2.2,0.7
	l0.5,1.8l2.6,9.8c-0.8,0.3-1.8,0.7-2.6,1.1l-5-8.8l-1-1.7l-1.8,1h-0.1c-2.6,1.5-5,3.4-7.3,5.8L600.9,357.5L600.9,357.5z"
          />
          <path
            fill="#EBEBEB"
            d="M598.5,363.3l-2.3,0.4c0.6,0.3,1,0.7,1.5,1c2.9,1.9,5.6,3.7,8.5,5.5l1.8,1.1c4.4,2.6,8.8,5.2,13.2,7.7
	l-4.8-4.5l-7.4-4.5l-1.8-1.2l-8.4-5.5L598.5,363.3L598.5,363.3z"
          />
          <path
            fill="#656565"
            d="M598.8,363.3l8.4,5.5c0.5-0.8,1.2-1.6,2.1-2.5l1.1-1.2c1.2-1.2,2.5-2.2,3.9-3.2c-0.8-1.5-1.8-2.9-2.8-4.4
	l-2.6-4.1v-0.1c-1.8,1.2-3.6,2.6-5.2,4.3l-0.4,0.4c-1.7,1.7-3.2,3.4-4.5,5.4L598.8,363.3L598.8,363.3z"
          />
          <path
            fill="#EBEBEB"
            d="M595,365.7l1.5,0.8l8.8,5.1l-1.1,2.6l-9.9-2.8l-1.7-0.5l-0.5,2.3c-0.7,2.9-1,5.8-1,8.9s0.4,6.2,1.1,9.1
	l1.4-2.1l-0.1-1.1c-0.3-1.9-0.4-3.9-0.4-5.9c0-2.1,0.1-4,0.4-5.9l0.1-1l-1.4-2.1l13.6,3c4.7,1.1,9.4,2.3,13.8,3.7l0.4,0.1
	c0.3,0.1,0.4,0.1,0.7,0.4l0.7-1.6c-4.4-2.5-8.9-5.1-13.2-7.7l-1.8-1.1c-2.9-1.8-5.6-3.6-8.5-5.5c-0.5-0.4-1-0.7-1.5-1L595,365.7
	L595,365.7z"
          />
          <path
            fill="#EBEBEB"
            d="M593.5,369.3l-0.7,1.8l1.7,0.5c0.6-1.8,1.2-3.4,2.1-5l-1.5-0.8C594.4,366.8,593.9,368.1,593.5,369.3
	L593.5,369.3z M593.7,375.5c3.4,0.4,6.6,1.1,9.9,1.8l2.1,0.5c2.8,0.7,5.4,1.5,7.8,2.5l0.4,0.1l0.5,0.1h3.2c0.4-0.4,1-0.6,1.5-0.6
	h0.4c-4.4-1.4-8.9-2.6-13.8-3.7l-13.6-3L593.7,375.5L593.7,375.5z"
          />
          <path
            fill="#656565"
            d="M593.6,375.4l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9c0,2.1,0.1,4,0.4,5.9l0.1,1.1l0.1-0.1c3.4-0.4,6.7-1.1,9.9-1.8
	c-0.3-1.6-0.5-3.3-0.5-5.1c0-1.6,0.1-3.3,0.4-5C600.3,376.6,597,376.1,593.6,375.4L593.6,375.4z"
          />
          <path
            fill="#EBEBEB"
            d="M593.6,389.4l-1.4,2.1l13.8-3c4.8-1.1,9.4-2.5,13.8-3.7h-0.5c-0.5,0-1-0.1-1.4-0.6h-3.3l-0.7,0.1l-0.4,0.1
	c-2.6,1-5.2,1.8-7.8,2.5l-2.1,0.5C600.3,388.2,597,388.7,593.6,389.4L593.6,389.4z"
          />
          <path
            fill="#EBEBEB"
            d="M592.9,393.7l1.8-0.5l9.8-2.6l1.1,2.6l-8.8,5l-1.5,0.8l1,1.8l1.5-1c2.9-1.9,5.8-3.7,8.7-5.4l1.8-1.1
	c4.4-2.6,8.8-5.2,13.3-7.6c-0.3-0.5-0.5-1.1-0.7-1.6l-0.7,0.5l-0.3,0.1c-4.4,1.4-9.1,2.6-13.8,3.7l-13.8,3L592.9,393.7L592.9,393.7z
	"
          />
          <path
            fill="#EBEBEB"
            d="M593.5,395.3c0.4,1.2,1.1,2.5,1.7,3.7l1.5-0.8c-0.8-1.6-1.5-3.3-2.1-5l-1.8,0.5L593.5,395.3L593.5,395.3z"
          />
          <path
            fill="#656565"
            d="M594.4,371.6l9.9,2.8l1.1-2.6l-8.8-5.1C595.6,368.2,595,369.9,594.4,371.6L594.4,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M627.6,345.8l-5.5,0.4l-3.4,0.7l1.9,1.5c2.2-0.4,4.5-0.7,7-0.7c2.3,0,4.7,0.3,6.9,0.7l2.1-1.4l-0.3,1.8
	c-0.5,3-1.2,5.9-1.9,8.8l-4.4,16.9l-0.1,0.4c-0.1,0.3-0.3,0.5-0.4,0.7c0.7,0.1,1.2,0.3,1.8,0.7c1.7-2.9,3.3-5.8,5-8.7l10.5-16.4
	l0.4,2.3c1.8,1.1,3.4,2.5,5,4.1l2.3,2.5c1,1.1,1.8,2.2,2.6,3.4l2.3,0.4c-1.4-2.2-2.9-4.3-4.8-6.3l-0.8-1c-2.2-2.2-4.5-4.1-7-5.5
	c-0.7-0.4-1.4-0.8-2.1-1.1l-1,1.5l-5.1,8.8c-0.8-0.4-1.8-0.8-2.6-1.1l2.8-9.8l0.5-1.8l-2.3-0.7c-1.1-0.3-2.2-0.5-3.4-0.7
	L627.6,345.8L627.6,345.8z"
          />
          <path
            fill="#EBEBEB"
            d="M644.2,349.8c-1.8-1-3.4-1.6-5.4-2.2l-0.5,1.8c1.8,0.5,3.4,1.2,5,2.1L644.2,349.8L644.2,349.8z"
          />
          <path
            fill="#656565"
            d="M646.7,353.5l-5.5,8.4c1.2,1,2.6,1.9,3.7,3.2c0.8,0.8,1.5,1.6,2.2,2.5l1,1.4l8.5-5.4h0.1
	c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5C650,355.8,648.3,354.6,646.7,353.5L646.7,353.5z"
          />
          <path
            fill="#EBEBEB"
            d="M646.5,353.4l-0.4-2.3l-10.5,16.4l-5,8.7l4.5-5l4.4-7.4l1.2-1.9L646.5,353.4L646.5,353.4z M656.6,363.4
	l-8.5,5.4l-0.5,0.3l-5.8,3.4l-2.5,1.5c-0.1,0.1-0.4,0.3-0.7,0.3l-5,4.5c4.5-2.5,8.9-5,13.3-7.6l12-7.6L656.6,363.4L656.6,363.4z"
          />
          <path
            fill="#EBEBEB"
            d="M658.9,363.8l-12,7.6c-4.4,2.6-8.8,5.1-13.3,7.6c0.3,0.5,0.5,1.1,0.7,1.6c0.3-0.3,0.5-0.4,0.8-0.5l0.3-0.1
	h0.1c4.4-1.4,8.9-2.6,13.6-3.7l2.1-0.5c3.3-0.8,6.6-1.5,9.9-2.2h0.1l1.7-0.3c-0.1-0.7-0.4-1.4-0.6-2.2l-1.8,0.5l-9.8,2.6
	c-0.3-1-0.7-1.8-1.1-2.6l8.8-5l1.5-0.8C659.8,365.2,659.3,364.5,658.9,363.8L658.9,363.8z"
          />
          <path
            fill="#EBEBEB"
            d="M658.5,366.7c0.8,1.5,1.5,3.2,2.1,5l1.8-0.5c-0.3-1-0.7-1.9-1.1-2.8c-0.4-0.8-0.7-1.6-1.2-2.5L658.5,366.7
	L658.5,366.7z M647,367.4c-0.7-0.8-1.4-1.6-2.2-2.5c-1.1-1.1-2.5-2.2-3.7-3.2l-1.2,1.9c1,0.7,1.8,1.4,2.8,2.2l0.8-1
	c1.1,0.8,1.9,1.6,2.8,2.5c0.5,0.5,1,1.1,1.4,1.7l0.6-0.3L647,367.4L647,367.4z"
          />
          <path
            fill="#EBEBEB"
            d="M646.1,367.4c-0.8-0.8-1.7-1.8-2.8-2.5l-0.8,1l-1.1,1.5c-0.8,1.4-1.5,3.2-2.2,5.1h2.5l5.8-3.4
	C647.1,368.5,646.7,367.9,646.1,367.4L646.1,367.4z M663.9,382.2c0-3.2-0.4-6.2-1.1-9.1l-1.5,1.9c0.4,2.3,0.7,4.7,0.7,7.2
	c0,2.3-0.3,4.7-0.7,6.9l1.4,1.9l-1.7-0.3h-0.1l-9.9-2.2l-8.8-2.2l-7-1.9l-1.1-0.5c-0.1,0.5-0.3,1.1-0.7,1.8l0.1,0.1
	c4.5,2.5,8.9,5,13.2,7.7l1.8,1.1c2.9,1.8,5.8,3.6,8.5,5.5c0.4,0.3,1,0.5,1.5,1l-2.3,0.4c-1.2,1.9-2.8,3.7-4.5,5.5
	c-1.8,1.8-3.6,3.3-5.6,4.7l-0.3,2.3c0.8-0.5,1.8-1.1,2.5-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1l-1.5-1l-8.8-5.1
	c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.8l1.8,0.5l0.7-2.5C663.6,388.3,663.9,385.4,663.9,382.2L663.9,382.2z"
          />
          <path
            fill="#EBEBEB"
            d="M646.1,378.7c0.4,0.1,0.7,0.4,1,0.8c0.7,0.8,1.1,1.8,1.1,2.9s-0.4,2.2-1.1,2.9l-0.6,0.5l3,0.8
	c0.3-1.4,0.4-2.8,0.4-4.3c0-1.5-0.1-3.2-0.4-4.5L646.1,378.7L646.1,378.7z"
          />
          <path
            fill="#EBEBEB"
            d="M649.4,377.7l2.1-0.5c3.2-0.8,6.5-1.4,9.8-1.8h0.1v-0.1l1.5-2.1l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2
	l-2.1,0.6c-4.7,1.1-9.2,2.3-13.6,3.7h0.6c0.5,0,1.1,0.1,1.5,0.5h3.3l1.8-0.5l3.6-1.2L649.4,377.7L649.4,377.7z"
          />
          <path
            fill="#EBEBEB"
            d="M646.1,378.7l-3.6,1.2v4.7l4,1.2l0.5-0.5c0.7-0.8,1.1-1.8,1.1-2.9c0-1.1-0.4-2.1-1.1-2.9
	C646.7,379.1,646.4,378.8,646.1,378.7L646.1,378.7z M651.5,377.2l-2.1,0.5c0.3,1.5,0.4,3,0.4,4.5s-0.1,2.9-0.4,4.3l2.1,0.5
	c0.3-1.5,0.4-3.2,0.4-4.8C652.1,380.6,651.9,378.8,651.5,377.2L651.5,377.2z"
          />
          <path
            fill="#656565"
            d="M652.1,382.4c0,1.7-0.1,3.3-0.4,4.8c3.2,0.8,6.5,1.4,9.6,1.8h0.1l0.1,0.1c0.4-2.2,0.7-4.5,0.7-6.9
	c0-2.5-0.3-4.8-0.7-7.1v0.1h-0.1c-3.3,0.4-6.6,1.1-9.8,1.8C651.9,378.8,652.1,380.6,652.1,382.4L652.1,382.4z M650.7,374.3l9.8-2.6
	c-0.5-1.8-1.2-3.4-2.1-5l-8.8,5C650.1,372.5,650.4,373.3,650.7,374.3z"
          />
          <path
            fill="#EBEBEB"
            d="M661.4,389h-0.1c-3.3-0.4-6.5-1.1-9.6-1.8l-2.1-0.5l-3-0.8l-4-1.2l-0.8-0.3l-0.4-0.1l-0.1-0.1l-0.4-0.1h-3.2
	c-0.4,0.4-1,0.5-1.5,0.5h-0.5l7,1.9l8.8,2.2l9.9,2.2h0.1l1.7,0.3l-1.4-1.9L661.4,389L661.4,389z M660.4,393.1
	c-0.5,1.6-1.2,3.3-2.1,5l1.5,1l1.2-2.6l1-2.8L660.4,393.1z M647,393.4c-4.3-2.8-8.7-5.2-13.2-7.7l5,4.5l0.7,0.4
	c0.5,0.4,1.2,0.7,1.9,1.1c1.7,1,3.3,1.9,5,3l1.1,0.7l0.7,0.4l8.4,5.5h0.1l2.3-0.4c-0.5-0.3-1-0.7-1.5-1c-2.8-1.9-5.6-3.7-8.5-5.5
	L647,393.4L647,393.4z"
          />
          <path
            fill="#656565"
            d="M649.6,393l8.8,5.1c0.8-1.5,1.5-3.2,2.1-5l-9.8-2.8C650.4,391.3,650.1,392.1,649.6,393L649.6,393z"
          />
          <path
            fill="#EBEBEB"
            d="M611.7,351.4c1.7-0.8,3.2-1.5,5-2.1l-0.6-1.8c-1.8,0.6-3.6,1.2-5.4,2.2L611.7,351.4L611.7,351.4z
	 M611.3,357.5c1,1.4,1.8,2.9,2.8,4.4l1.1,1.8l4.4,7.6l4.5,5c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7l-7.4-11.8l-0.4,2.3v0.1L611.3,357.5
	L611.3,357.5z"
          />
          <path
            fill="#656565"
            d="M622.2,357.5l0.3,0.8c1.7-0.3,3.3-0.4,5.1-0.4l4.8,0.4l0.1-0.8c0.7-2.9,1.2-5.9,1.8-9.1l0.1-0.1
	c-2.2-0.4-4.5-0.7-6.9-0.7c-2.5,0-4.8,0.3-7,0.7h0.1C621.1,351.4,621.7,354.5,622.2,357.5L622.2,357.5z"
          />
          <path
            fill="#EBEBEB"
            d="M620.5,348.4l-1.9-1.5l2.9,13.9l3.7,13.6v-0.5c0-0.5,0.1-1,0.5-1.4v-3.3l-0.5-1.8c-0.8-2.3-1.5-4.7-2.2-7
	l-0.5-2.1l-0.3-0.8L620.5,348.4L620.5,348.4z M614.1,361.9c-1.4,0.8-2.6,1.9-3.9,3.2l-1.1,1.2c-0.7,0.8-1.4,1.6-2.1,2.5l1.8,1.2
	c0.5-0.8,1.2-1.7,1.9-2.5l1-1c1.1-1.1,2.2-2.1,3.4-2.9L614.1,361.9L614.1,361.9z"
          />
          <path
            fill="#656565"
            d="M619.5,359.1l-2.6-9.8c-1.7,0.5-3.3,1.2-5,2.1l5,8.8C617.7,359.8,618.5,359.4,619.5,359.1L619.5,359.1z"
          />
          <path
            fill="#EBEBEB"
            d="M610.9,367.4c-0.7,0.8-1.4,1.5-1.9,2.5l7.4,4.5l3.2-3.2l-4.4-7.6c-1.2,0.8-2.3,1.8-3.4,2.9L610.9,367.4
	L610.9,367.4z M627.6,357.9c-1.8,0-3.4,0.1-5.1,0.4l0.5,2.1l4.5-0.4l4.4,0.4l0.5-2.1L627.6,357.9L627.6,357.9z"
          />
          <path
            fill="#EBEBEB"
            d="M632.4,358.3l-0.5,2.1c-0.6,2.3-1.2,4.8-2.1,7l-0.5,1.8v3.2c0.4,0.4,0.5,1,0.5,1.5v0.4l4.4-16.9
	c0.7-2.9,1.4-5.8,1.9-8.8l0.3-1.8l-2.1,1.4l-0.1,0.1c-0.4,3-1,6.1-1.8,9.1L632.4,358.3L632.4,358.3L632.4,358.3z M642.7,365.9
	c-0.8-0.8-1.8-1.5-2.8-2.2l-4.4,7.4l3.2,3.2c0.3-0.1,0.4-0.3,0.7-0.3v-1.5c0.7-2.1,1.5-3.7,2.2-5.1L642.7,365.9L642.7,365.9z"
          />
          <path
            fill="#656565"
            d="M638.3,360.2l5.1-8.8c-1.7-0.8-3.3-1.5-5-2.1l-2.8,9.8C636.5,359.4,637.3,359.8,638.3,360.2L638.3,360.2z"
          />
          <path
            fill="#EBEBEB"
            d="M625.1,367.4l0.5,1.8h3.6l0.5-1.8c0.8-2.3,1.5-4.7,2.1-7l-4.4-0.4l-4.5,0.4
	C623.6,362.7,624.4,365.2,625.1,367.4L625.1,367.4z"
          />
          <path
            fill="#EBEBEB"
            d="M625.6,369.2v3.3l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7s1.1,0.3,1.5,0.7l0.1,0.1v-3.3L625.6,369.2L625.6,369.2z"
          />
          <path
            fill="#EBEBEB"
            d="M625.6,372.5c-0.4,0.4-0.5,0.8-0.5,1.4v0.5l0.1,0.3l0.5,0.7c0.4,0.4,1,0.7,1.7,0.7s1.1-0.3,1.5-0.7
	c0.1-0.3,0.4-0.4,0.4-0.7l0.1-0.4v-0.4c0-0.5-0.1-1.1-0.6-1.5l-0.1-0.1c-0.4-0.4-1-0.7-1.5-0.7c-0.7,0-1.2,0.3-1.7,0.7L625.6,372.5
	L625.6,372.5z M627.6,372.6c0.3,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.6,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4
	s-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8C626.9,372.8,627.2,372.6,627.6,372.6L627.6,372.6z"
          />
          <path
            fill="#EBEBEB"
            d="M626.6,373c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1s0.5,0.4,0.8,0.4s0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4S626.9,372.8,626.6,373L626.6,373z M625.9,375.5c-0.5,0.1-1.1,0.3-1.7,0.7
	c-0.5,0.3-1.1,0.7-1.5,1.1c-0.5,0.5-1,1.1-1.2,1.6l-0.7,1.6l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.7h-0.1
	c0.1,0.5,0.4,1.1,0.7,1.6c0.3,0.5,0.7,1.1,1.2,1.5c0.5,0.5,1.1,1,1.7,1.2c0.5,0.3,1.1,0.5,1.8,0.7l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7
	s1.2,0.3,1.7,0.7v0.1c0.5-0.1,1.1-0.4,1.7-0.7c0.5-0.3,1.1-0.7,1.5-1.2c0.5-0.5,1-1.1,1.2-1.7c0.3-0.5,0.5-1.1,0.7-1.8v-0.1
	c-0.4-0.4-0.7-1-0.7-1.5c0-0.7,0.3-1.2,0.7-1.7c-0.1-0.5-0.4-1.1-0.7-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.5-0.5-1.1-0.8-1.7-1.2
	c-0.5-0.3-1.1-0.5-1.8-0.7c-0.4,0.4-1,0.7-1.5,0.7C626.9,376.2,626.3,375.9,625.9,375.5L625.9,375.5z"
          />
          <path
            fill="#EBEBEB"
            d="M632.5,377.3c0.4,0.5,0.8,1,1.2,1.5l5-4.5l-3.2-3.2l-4.5,5C631.4,376.5,632,376.9,632.5,377.3L632.5,377.3z
	 M639.4,372.5v1.5l2.3-1.5 M640.6,380.5v3.6l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3V380L640.6,380.5L640.6,380.5z M635.4,379.9
	l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.6c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.5v0.1l1.1,0.5h0.4c0.5,0,1.1-0.1,1.5-0.5l0.1-0.1
	c0.4-0.4,0.7-1,0.7-1.5c0-0.7-0.3-1.2-0.7-1.6l-0.1-0.1c-0.4-0.3-0.8-0.5-1.5-0.5L635.4,379.9L635.4,379.9z M636.8,383.1
	c-0.3,0.3-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,0.8-0.4
	c0.4,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1S637.1,382.9,636.8,383.1L636.8,383.1z"
          />
          <path
            fill="#EBEBEB"
            d="M637.2,382.2c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-0.8-0.4s-0.5,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1
	c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4C637.1,382.9,637.2,382.7,637.2,382.2L637.2,382.2z
	 M637.6,380.6c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.3,1.1-0.7,1.5l-0.1,0.1h3.2v-3.6h-3.3L637.6,380.6L637.6,380.6z M633.8,385.5
	c-0.3,0.5-0.7,1.1-1.2,1.7c-0.5,0.5-1,0.8-1.5,1.2l4.5,5l3.2-3.2L633.8,385.5L633.8,385.5z M639.4,390.6v1.1h1.9
	C640.6,391.3,639.9,390.9,639.4,390.6z"
          />
          <path
            fill="#EBEBEB"
            d="M638.7,390.2l-3.2,3.2l4.4,7.6l2.9-2.3c-1.2-1.7-2.3-4-3.4-6.9v-1.1L638.7,390.2z"
          />
          <path
            fill="#EBEBEB"
            d="M639.4,391.7c1.1,2.9,2.2,5.2,3.4,6.9l0.5,0.7c1.5-1.1,2.9-2.5,3.9-3.9l-1.1-0.7c-1.7-1.1-3.3-2.1-5-3
	L639.4,391.7L639.4,391.7z M624.3,388.6l-4.5,5l-4.5,7.6l-1.1,1.8l-5.5,8.4v0.3l0.4,2.2l1-1.4v-0.1c1.9-2.8,3.7-5.5,5.5-8.4l1.1-1.8
	C619.2,397.4,621.8,393,624.3,388.6L624.3,388.6z"
          />
          <path
            fill="#EBEBEB"
            d="M624.3,388.6c-2.5,4.5-5.1,8.9-7.7,13.3l-1.1,1.8c-1.8,2.9-3.6,5.6-5.5,8.4v0.1l-1,1.4l-0.4-2.2
	c-1.9-1.2-3.7-2.8-5.5-4.5c-1.8-1.8-3.3-3.7-4.5-5.6l-2.3-0.3c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4l4.7,2.9l0.8-1.5l5.1-8.8
	c0.8,0.4,1.7,0.8,2.6,1.1l-2.8,9.8l-0.4,1.8l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1l-2.1-1.4c-2.2,0.4-4.5,0.7-7,0.7
	c-2.3,0-4.7-0.1-6.9-0.5l-1.9,1.2l3-13.6c1.1-4.7,2.3-9.4,3.7-13.8l0.1-0.4c0.1-0.3,0.1-0.4,0.4-0.7
	C625.4,389,624.8,388.8,624.3,388.6L624.3,388.6z"
          />
          <path
            fill="#EBEBEB"
            d="M625.9,389.1c-0.1,0.1-0.3,0.4-0.4,0.7l-0.1,0.4v0.4c0,0.5,0.1,1.1,0.5,1.5l0.1,0.1c0.4,0.4,1,0.7,1.7,0.7
	s1.2-0.3,1.7-0.7l0.1-0.1c0.4-0.4,0.5-0.8,0.5-1.5v-0.5l-0.1-0.3l-0.5-0.7V389c-0.4-0.4-1-0.7-1.7-0.7
	C627,388.4,626.5,388.7,625.9,389.1L625.9,389.1z M628.5,391.6c-0.3,0.3-0.6,0.4-1,0.4c-0.3,0-0.7-0.1-0.8-0.4
	c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1s0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4s0.4,0.5,0.4,1
	C628.9,391,628.8,391.3,628.5,391.6L628.5,391.6z"
          />
          <path
            fill="#EBEBEB"
            d="M628.9,390.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1
	c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4C628.8,391.3,628.9,391,628.9,390.6L628.9,390.6z M629.4,389.1
	l0.5,0.7l0.1,0.3c1.4,4.4,2.6,8.9,3.7,13.8l3,13.8l2.2-0.5l-0.5-1.8l-2.6-9.8c0.8-0.3,1.8-0.7,2.6-1.1l5,8.8l0.8,1.5l1.9-1.1
	l-7.6-12c-2.6-4.4-5.2-8.8-7.6-13.3C630.5,388.7,629.9,389,629.4,389.1L629.4,389.1z M629.4,392.3c-0.4,0.4-1,0.7-1.7,0.7
	c-0.5,0-1.1-0.3-1.7-0.7l-0.1-0.1v3.2h3.6V392L629.4,392.3L629.4,392.3z"
          />
          <path
            fill="#EBEBEB"
            d="M629.4,392.1v3.3l0.1,0.5l0.1,0.4c1,2.6,1.7,5.2,2.3,7.8l0.5,2.1l1.2,5.6l0.7,4.1l0.1,0.1l2.1,1.4l-3-13.8
	c-1.1-4.8-2.5-9.4-3.7-13.8v0.5C629.9,391.3,629.8,391.7,629.4,392.1L629.4,392.1z M625.4,390.6v-0.4c-1.4,4.4-2.6,8.9-3.7,13.8
	l-3,13.6l1.9-1.2l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.1-5.6l0.5-2.1c0.7-2.8,1.5-5.2,2.5-7.8l0.3-1v-3.2
	C625.5,391.7,625.4,391.3,625.4,390.6L625.4,390.6z M631,388.4c2.5,4.5,5,8.9,7.6,13.3l7.6,12l0.3-2.3l0.1-0.1l-5.4-8.4
	c-0.4-0.7-0.7-1.2-1.1-1.8l-4.4-7.6L631,388.4L631,388.4z M605.1,382.4c0,1.5,0.1,3,0.4,4.5c2.8-0.7,5.2-1.5,7.8-2.5l0.4-0.1
	l0.7-0.1v-3.6l-0.6-0.1l-0.4-0.1c-2.6-1-5.2-1.8-7.8-2.5C605.3,379.4,605.1,380.9,605.1,382.4L605.1,382.4z"
          />
          <path
            fill="#EBEBEB"
            d="M605.6,377.8l-2.1-0.6c-0.3,1.7-0.4,3.2-0.4,5s0.1,3.4,0.5,5.1l2.1-0.5c-0.3-1.5-0.4-3-0.4-4.5
	C605.1,380.9,605.3,379.4,605.6,377.8L605.6,377.8z M619.2,380c-0.5,0-1.1,0.1-1.5,0.6l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6
	c0,0.5,0.3,1.1,0.7,1.6l0.1,0.1c0.4,0.4,0.8,0.5,1.4,0.5h0.5l0.3-0.1l0.7-0.5h0.1c0.4-0.4,0.7-1,0.7-1.6c0-0.7-0.3-1.2-0.7-1.6
	l-0.1-0.1c-0.3-0.1-0.4-0.3-0.7-0.4l-0.4-0.1L619.2,380L619.2,380z M618.3,381.4c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4
	c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1
	C617.9,382,618.1,381.7,618.3,381.4L618.3,381.4z"
          />
          <path
            fill="#EBEBEB"
            d="M617.9,382.4c0,0.3,0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-0.8,0.4C618.1,381.7,617.9,382,617.9,382.4L617.9,382.4z
	 M622.6,377.3c0.4-0.4,1-0.8,1.5-1.1l-4.5-5l-3.2,3.2l4.8,4.5C621.6,378.4,622.1,377.8,622.6,377.3z M614.5,380.6v3.6h3.3l-0.1-0.1
	c-0.4-0.4-0.7-1-0.7-1.7c0-0.7,0.3-1.2,0.7-1.6l0.1-0.1L614.5,380.6L614.5,380.6z M621.4,385.7c-4.5,2.5-8.9,5-13.3,7.6l-1.8,1.1
	c-2.9,1.8-5.8,3.6-8.7,5.4l-1.5,1l2.3,0.3h0.1l8.4-5.4l1.8-1.1c2.5-1.5,5.1-3,7.6-4.4L621.4,385.7L621.4,385.7z"
          />
          <path
            fill="#EBEBEB"
            d="M621.4,385.7l-5,4.5l3.2,3.2l4.5-5c-0.6-0.3-1.1-0.8-1.7-1.2C622.1,386.8,621.6,386.2,621.4,385.7
	L621.4,385.7z M616.4,390.2c-2.5,1.4-5.1,2.9-7.6,4.4c0.8,1.2,1.8,2.3,2.9,3.4c1.1,1.1,2.2,1.9,3.3,2.9l4.5-7.6L616.4,390.2z"
          />
          <path
            fill="#656565"
            d="M604.3,390.5l-9.8,2.6c0.5,1.6,1.2,3.3,2.1,5l8.8-5L604.3,390.5L604.3,390.5z"
          />
          <path
            fill="#EBEBEB"
            d="M610.2,399.6c1.1,1.1,2.3,2.2,3.7,3.2l1.1-1.8c-1.2-0.8-2.3-1.8-3.3-2.9c-1.1-1.1-2.1-2.2-2.9-3.4l-1.8,1.1
	C608,397.1,609,398.5,610.2,399.6z"
          />
          <path
            fill="#656565"
            d="M607.1,395.7l-8.4,5.4h-0.1c1.2,1.9,2.9,3.9,4.5,5.6c1.8,1.8,3.6,3.2,5.5,4.5V411l5.5-8.4
	c-1.2-1-2.5-1.9-3.7-3.2C609,398.5,608,397.1,607.1,395.7L607.1,395.7z M617,404.5l-5.1,8.8c1.7,0.8,3.2,1.5,5,1.9l2.8-9.8
	C618.6,405.2,617.8,404.9,617,404.5L617,404.5z"
          />
          <path
            fill="#EBEBEB"
            d="M616.8,415.3c-1.7-0.5-3.3-1.2-5-1.9l-0.8,1.5c1.7,0.8,3.4,1.6,5.4,2.2L616.8,415.3L616.8,415.3z"
          />
          <path
            fill="#656565"
            d="M620.7,416.4c2.2,0.4,4.5,0.5,6.9,0.5c2.5,0,4.8-0.3,7-0.7l-0.1-0.1l-0.7-4.1l-1.2-5.6l-5.1,0.4l-5-0.4
	c-0.4,1.9-0.8,3.7-1.1,5.6l-0.7,4.1L620.7,416.4L620.7,416.4z"
          />
          <path
            fill="#EBEBEB"
            d="M625.9,395.5l-0.3,1c-1,2.6-1.8,5.2-2.5,7.8l4.4,0.4l4.5-0.4c-0.7-2.6-1.5-5.2-2.3-7.8l-0.1-0.4l-0.1-0.5
	L625.9,395.5L625.9,395.5z"
          />
          <path
            fill="#EBEBEB"
            d="M627.6,404.7l-4.4-0.4l-0.5,2.1l5,0.4l5.1-0.4l-0.5-2.1 M641,402.7c1.4-1,2.6-1.9,3.9-3.2
	c1.2-1.2,2.2-2.5,3.2-3.7l-0.7-0.4c-1.1,1.4-2.3,2.8-3.9,3.9l-0.5-0.7l-2.9,2.3C640.4,401.5,640.6,402.2,641,402.7L641,402.7z"
          />
          <path
            fill="#656565"
            d="M635.7,405.5l2.6,9.8c1.7-0.5,3.3-1.2,5-1.9l-5-8.8C637.5,404.8,636.5,405.2,635.7,405.5L635.7,405.5z"
          />
          <path
            fill="#EBEBEB"
            d="M638.8,417c1.8-0.5,3.6-1.2,5.2-2.2l-0.8-1.5c-1.5,0.8-3.2,1.5-5,1.9L638.8,417L638.8,417z"
          />
          <path
            fill="#656565"
            d="M644.9,399.6c-1.2,1.2-2.5,2.2-3.9,3.2l5.4,8.4l-0.1,0.1c1.9-1.2,3.9-2.9,5.6-4.7c1.8-1.7,3.2-3.6,4.5-5.5
	h-0.1l-8.4-5.5C647.1,397.2,646,398.5,644.9,399.6L644.9,399.6z"
          />
          <path fill="#EBEBEB" d="M646.1,413.7" />
          <path
            fill="#FFFFFF"
            d="M568.1,392.8l0.5-11c0.8-16.6,6.9-30.5,18.3-42.1c11.4-11.4,25-17.1,41.1-17.1c8.8,0,17.1,1.8,24.5,5.2
	l4.4-4.4c-8.8-4.7-18.4-7-29-7c-17.7,0-32.9,6.6-45.4,19.7c-12.5,13.2-19.3,29.3-20.1,48.4l-0.5,10.2l6.2,0.1L568.1,392.8
	L568.1,392.8z M553.1,373.6l-78.8-3.4c-31.8-1.5-64.2-4.4-97.5-8.5l-2.8,18.2l172,4.5c3.3,0.1,5.2-1,6.1-3.2
	c0.4-1.4,0.7-3.2,0.8-5.2L553.1,373.6L553.1,373.6z"
          />
          <path
            fill="#FFFFFF"
            d="M559.1,371c0.8-2.8-1-4.4-5.2-5.1l-0.8,7.7C556.7,373.4,558.6,372.6,559.1,371L559.1,371z"
          />
          <path
            fill="#FFFFFF"
            d="M554,365.9L376.2,354l0.4,7.6c33.3,4.1,65.8,7,97.5,8.5l78.8,3.4L554,365.9L554,365.9z"
          />
          <path
            fill="#FFFFFF"
            d="M558.5,315.1c-59.6-9.2-120.6-18.2-183.4-27c1.1,21.9,1.5,43.9,1.1,65.9L554,366
	C555.8,349.4,557.3,332.4,558.5,315.1L558.5,315.1z M672.4,309.9l-4.1,2.3c3.7,2.3,7.3,5.1,10.7,8.1c11.7,10.7,18.7,23.1,21.2,37.4
	c-0.1-1.4-0.3-2.8-0.6-4.1c-2.2-15.1-9.4-28.2-21.7-39.3C676.1,312.8,674.2,311.3,672.4,309.9L672.4,309.9z M677.1,304.7l3.4,1.5
	c14.6,6.1,29.7,11.1,45.4,15l19.8,4.4c4.7,0.8,7.6-0.1,8.9-2.9l1-2.9c0.5-2.1,0.3-3.9-0.8-5.2c-1.7-1.8-3.4-3.6-5.4-5.2
	c-0.7-0.4-1.2-1-1.9-1.4l-3.4,2.2l5.4,3.3l0.1,0.1c1.1,1.4,1.4,3,0.8,5l-1,2.9l-0.5,0.8l-1,0.5l-2.3,0.1l-19.1-4.4l-0.3-0.1
	c-11.6-2.9-23.5-6.2-36-10H690l-13.5-4.3L677.1,304.7L677.1,304.7z"
          />
          <path
            fill="#FFFFFF"
            d="M677.1,304.7l-4.7,5.2c1.8,1.4,3.7,2.9,5.5,4.5c12.4,11.1,19.5,24.2,21.7,39.3c0.3,1.4,0.4,2.8,0.5,4.1
	c-2.5-14.3-9.5-26.8-21.2-37.4c-3.4-3.2-6.9-5.8-10.7-8.1L657,323.5c5.9,3.2,11.4,7.4,16.5,12.7c10.7,11.1,16.9,24.2,18.7,39.3
	c0.4,2.8,0.5,5.5,0.5,8.4c0,3.6,0.3,6.7,0.7,9.8h60.4c1.8-0.1,3.4-0.4,4.8-1.1l3.2-1.5c1.1-0.5,1.9-1,2.6-1.4
	c0.5-0.3,0.8-0.8,0.8-1.6v-1h-5L693,374.7h11.7c2.5-0.1,5.1,0.1,7.8,0.5l42.6,6.3l1.9,0.3c1.1,0.1,2.1,0.5,3,1.2l-1.5-9.5l-0.3-1.2
	l-0.5-3.4l-0.3-1.1l-1.2-6.7c-0.3-1,0.1-1.2,1-1.1l5.8,1.9v-8h-0.3c-2.1-1-3.6-2.5-4.8-4.5l-0.3-0.4l-2.3-5v-0.1l-0.3-1.1l-0.1-1.2
	c0-1,0.1-1.9,0.3-2.8l5.9-18.8c0.4-1.1,0.8-1.8,1.5-2.3c-2.1-3-4.3-5.9-6.6-8.5c-1.2-1.2-2.5-2.5-3.9-3.6L747,308
	c0.7,0.4,1.4,0.8,1.9,1.4c2.1,1.6,3.9,3.3,5.4,5.2c1.1,1.5,1.5,3.2,0.8,5.2l-1,2.9c-1.2,2.6-4.3,3.6-8.9,2.9l-19.8-4.4
	c-15.5-3.9-30.7-8.9-45.4-15L677.1,304.7L677.1,304.7z M743.4,360.9c1.5,0.8,2.9,2.1,4.3,3.7l3.2,4.8c1.5,2.5,2.8,5.1,3.9,7.8
	l0.1,0.3c0.1,1.1-0.4,1.8-1.7,2.1l-0.3,0.1l-4.5-0.3l-35.2-5.1c-2.3-0.5-4-1.8-5-3.6c-2.3-4.7-4.3-9.5-5.5-14.7
	c-0.7-2.2-0.4-3.9,0.5-4.7c1-0.8,2.6-1,5.1-0.4l19.7,4.3C734.2,356.7,739.3,358.4,743.4,360.9L743.4,360.9z M749,358.2l-48-11.6
	c5.5-0.8,13.5,0,23.7,2.3C736.4,351.7,744.5,354.7,749,358.2L749,358.2z"
          />
          <path
            fill="#656565"
            d="M743.4,360.9c-4.1-2.5-9.4-4.3-15.5-5.5l-19.7-4.3c-2.5-0.5-4.1-0.4-5.1,0.4c-1.1,0.8-1.2,2.3-0.5,4.7
	c1.2,5.2,3,10,5.5,14.7c1,1.8,2.6,3,5,3.6l35.2,5.1l4.5,0.3l0.3-0.1c1.2-0.3,1.8-1,1.7-2.1l-0.1-0.3c-1.1-2.8-2.3-5.4-3.9-7.8
	l-3.2-4.8C746.3,363,744.9,361.7,743.4,360.9L743.4,360.9z M746.7,365.6l2.5,3.7c1.7,2.6,3,5.5,4.3,8.4c0,0.3-0.3,0.4-0.7,0.5h-0.1
	l-3.9-0.3l-35.4-5.1h-0.1c-1.9-0.4-3.2-1.2-3.8-2.8c-2.3-4.5-4.1-9.4-5.4-14.4v-0.1c-0.4-1.5-0.4-2.5,0.1-3c0.4-0.3,1.1-0.4,2.1-0.4
	l21.5,4.7c6.1,1.2,11.1,3,15.1,5.4C744.2,363,745.5,364.1,746.7,365.6L746.7,365.6z"
          />
          <path
            fill="#656565"
            d="M746.7,368.3c-0.5-0.7-1.1-1.2-1.7-1.8c-0.7-0.7-1.5-1.2-2.2-1.5c-4-2.3-9.1-4.1-15.1-5.2l-13.3-2.8l2.1,2.1
	c0-0.3,0.1-0.5,0.5-0.5h0.7c0.4,0,0.5,0.1,0.5,0.5v13.3c0,0.3-0.1,0.5-0.5,0.5H717c-0.3,0-0.5-0.1-0.5-0.5c-1,0.4-1.9,0.5-3,0.7
	l35.4,5.1l3.9,0.3c-0.5-1.2-1.1-2.3-1.8-3.6l-1.4-2.5l-0.1-0.1c-0.5-1.1-1.2-2.1-1.9-3L746.7,368.3L746.7,368.3z M744.8,367.8l0.5,1
	l-24.3-3.3v-2.3l22.4,3C743.8,366.7,744.2,367.2,744.8,367.8L744.8,367.8z M748.8,374.5l-27.8-3.7v-2.2l26.5,3.6
	C747.9,372.7,748.3,373.6,748.8,374.5L748.8,374.5z"
          />
          <path
            fill="#656565"
            d="M743.3,366.3l-22.4-3v2.3l24.3,3.3l-0.5-1C744.2,367.2,743.8,366.7,743.3,366.3L743.3,366.3z M747.5,372.1
	l-26.5-3.6v2.2l27.8,3.7C748.3,373.6,747.9,372.8,747.5,372.1L747.5,372.1z"
          />
          <path
            fill="#656565"
            d="M747.2,369c0.7,1,1.4,1.9,1.9,3l0.1,0.1l1.4,2.5c0.7,1.1,1.2,2.3,1.8,3.6h0.1c0.4-0.1,0.7-0.3,0.7-0.5
	c-1.2-3-2.6-5.8-4.3-8.4l-2.5-3.7c-1.2-1.5-2.5-2.6-3.9-3.3c-4-2.3-9.1-4.1-15.1-5.4l-21.6-4.5c3,1.1,5.5,2.5,7.7,4.1l0.5,0.4
	l13.3,2.8c6.1,1.2,11.1,3,15.1,5.2c0.8,0.4,1.5,1,2.2,1.5c0.6,0.5,1.1,1.1,1.7,1.8L747.2,369L747.2,369z"
          />
          <path
            fill="#656565"
            d="M704.2,352.7c-0.6,0.5-0.6,1.5-0.1,3v0.1c1.2,5.1,3,9.9,5.4,14.4c0.7,1.5,1.9,2.3,3.9,2.8h0.1
	c1.1,0,2.1-0.3,3-0.7V359c-0.5-0.7-1.2-1.4-2.1-2.1l-0.5-0.4c-2.1-1.6-4.7-3-7.7-4.1C705.3,352.1,704.6,352.3,704.2,352.7
	L704.2,352.7z M717,358.3c-0.3,0-0.5,0.1-0.5,0.5v13.3c0,0.3,0.1,0.5,0.5,0.5h0.7c0.4,0,0.5-0.1,0.5-0.5v-13.3
	c0-0.3-0.1-0.5-0.5-0.5L717,358.3L717,358.3z"
          />
          <path fill="#3296BA" d="M713.4,372.9h-0.1H713.4" />
          <path
            fill="#FFFFFF"
            d="M724.7,349c-10.2-2.3-18.2-3.2-23.7-2.3l48,11.6C744.5,354.7,736.4,351.7,724.7,349L724.7,349z M652.6,327.8
	c6.1,2.8,11.6,6.7,16.8,11.8c9.6,9.6,15.3,21,17.1,34.3c0.3,2.3,0.5,4.8,0.5,7.3c0.1,4.4,0.4,8.3,1.1,11.7l0.1,0.7h5.4
	c-0.4-2.9-0.5-6.2-0.7-9.8c0-2.9-0.3-5.6-0.5-8.4c-1.8-15.1-8.1-28.2-18.7-39.3c-5.1-5.2-10.6-9.5-16.5-12.7L652.6,327.8
	L652.6,327.8z"
          />
          <path
            fill="#EBEBEB"
            d="M686.6,305.9l0.8,0.1l3.9,1.1c10,2.9,19.9,5.5,29.4,7.8l14.2,3.3l1.5,1.5l1.9,0.4l-2.3-2.5l-24.6-5.9
	l-22.7-6.2h-1.2C687,305.6,686.9,305.8,686.6,305.9L686.6,305.9z"
          />
          <path
            fill="#EBEBEB"
            d="M686.3,306.2l-0.4,0.5h0.4l4.1,1.2c12.2,3.6,24.1,6.7,35.4,9.5l0.3,0.1l10.3,2.2l-1.5-1.5l-14.2-3.3
	c-9.6-2.3-19.4-5-29.4-7.8l-3.8-1.1l-0.8-0.1L686.3,306.2L686.3,306.2z"
          />
          <path
            fill="#FFFFFF"
            d="M759.3,330.7l0.4-1.2l2.9-9.1c0.3-0.7,0.5-1.2,1-1.6l-0.7-1c-0.5,0.4-1.1,1.2-1.5,2.3l-5.9,18.8
	c-0.3,0.8-0.3,1.8-0.3,2.8l0.1,1.2l0.3,1.1v0.1l2.3,5l0.3,0.4c1.2,2.1,2.8,3.6,4.8,4.5h0.3v-1.2c-1.7-0.8-2.9-1.9-3.8-3.6l-0.1-0.3
	l-0.3-0.4l-0.4-0.5c-0.7-1.2-1.2-2.6-1.8-4.1l-0.1-0.7l-0.1-1.2c0-0.8,0.1-1.6,0.3-2.5l0.8-2.5l0.4-1.2l1.1-3.3L759.3,330.7
	L759.3,330.7z"
          />
          <path
            fill="#656565"
            d="M759.3,330.7h1.2l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8
	L759.3,330.7L759.3,330.7z M756.7,339c-0.1,0.8-0.3,1.6-0.3,2.5h2.5v-5h-1.4L756.7,339L756.7,339z M757.8,335.3l-0.4,1.2h3.6
	l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1L757.8,335.3L757.8,335.3z"
          />
          <path fill="#3296BA" d="M758.9,332l-1.1,3.3h1.1" />
          <path
            fill="#656565"
            d="M761.4,342.8c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1
	h-5l0.1,1.2h4.8L761.4,342.8L761.4,342.8z"
          />
          <path
            fill="#656565"
            d="M756.7,343.5c0.5,1.5,1.2,2.9,1.8,4.1h0.4v-4.8h-2.3L756.7,343.5L756.7,343.5z"
          />
          <path fill="#3296BA" d="M758.5,347.6l0.4,0.5v-0.5" />
          <path
            fill="#656565"
            d="M758.9,348.1l0.3,0.4l0.1,0.3h2.5c0.1,0,0.4,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.4-0.1-0.6
	c-0.1-0.1-0.3-0.1-0.4-0.1h-2.9L758.9,348.1L758.9,348.1z"
          />
          <path
            fill="#FFFFFF"
            d="M756.5,361.1l1.2,6.7h5.1h-5.1l0.3,1.1h5.2v-7.2l-5.8-1.9C756.6,359.7,756.2,360.1,756.5,361.1z
	 M759.9,373.4c0.1-0.1,0.1-0.3,0.1-0.5s0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8l0.3,1.2h0.7
	C759.5,373.7,759.6,373.6,759.9,373.4L759.9,373.4z M755.4,381.6c1.2,0.3,2.6,1,4,2.2l3.7,3.3h2.3l-5.1-4c-1-0.7-1.9-1.1-3-1.2
	L755.4,381.6L755.4,381.6z"
          />
          <path
            fill="#656565"
            d="M742.2,395.3l-13.6-1.8h-31.4l44.2,3.3l10.9-3.3h-4.3"
          />
          <path
            fill="#FFFFFF"
            d="M755.4,381.6l-42.6-6.3c-2.9-0.4-5.5-0.7-7.8-0.5h-11.7l67.3,12.4h2.6l-3.7-3.3
	C757.8,382.7,756.6,381.8,755.4,381.6L755.4,381.6z M747.9,393.5h-19.4l13.6,1.8 M272.1,337.9c-3.6-11.6-10.2-21.7-19.9-30.5
	c-8.4-7.7-17.9-13.2-28.5-16.4l7.7,11.1c8.8,3.3,16.8,8.1,24.1,14.7C262.7,323.2,268.2,330.2,272.1,337.9L272.1,337.9z M376.2,354
	l-87.8-5.9c30,5.5,59.4,9.9,88.3,13.6L376.2,354L376.2,354z"
          />
          <path
            fill="none"
            d="M692.4,295.4l4.7,12 M679,296l2.5,2.9 M681.5,298.9v-7 M689.6,294.8v10.6 M728,315.4l-7.3-1.8 M744.6,315.4H728
	 M741.9,321l-3.6-0.8 M744.6,315.4c0.1,0.4,0.1,0.8,0,1.2l-1.1,2.2c-0.4,0.8-1.2,1.2-2.3,1.1l-2.6-0.4l-2.3-2.2l-8.3-1.9
	 M736.4,319.8l1.9,0.4 M710.9,311.1l-12.9-3.4 M697.9,307.1h11.3 M720.7,313.6v-6.5H710 M712,311.4l8.7,2.2 M710,307.1l2.1,4.3
	 M709.1,307.1l1.8,4 M710.9,311.1l1.1,0.3 M697.9,307.1l-3.8-9.6l14.9,9.2l0.3,0.4 M710,307.1l-0.4-1l-17.2-10.7 M691.9,306l5.1,1.4
	 M697,307.4l1,0.3 M698,307.7l-0.1-0.5 M689.6,305.3c-2.3-1-4.1-2.2-5.6-3.7l-2.5-2.8 M686.6,305.9l0.8,0.1l3.9,1.1
	c10,2.9,19.9,5.5,29.4,7.8l14.2,3.3l1.5,1.5"
          />
          <path
            fill="none"
            d="M736.4,319.8l-10.3-2.2l-0.3-0.1c-11.3-2.8-23.1-5.9-35.4-9.5l-4.1-1.2h-0.4 M686.6,305.9l-0.3,0.3l-0.3,0.5
	 M738.3,320.2l-2.3-2.5l-24.6-5.9l-22.7-6.2h-1.2c-0.3,0.1-0.4,0.3-0.7,0.4 M686,306.7c-0.1-0.1-0.3-0.1-0.4-0.1l-0.4-0.1
	 M689.9,308.5h-0.1 M691.9,306l-2.3-0.7 M690.6,295.4c1.4,1.2,2.1,3,2.1,5.2v2.6c-0.1,1.1-0.3,1.9-0.7,2.8 M673.4,303l0.3,0.1
	c4.7,1.2,8.5,2.5,11.4,3.3 M681.5,291.9c0.8-0.3,1.8-0.4,2.9-0.5 M685.1,306.4l-5.5-6.7c-1-1.1-2.2-1.8-3.7-2.2l-18.7-2.2"
          />
          <path
            fill="none"
            d="M638.7,285.8l23.2,3c3.9,0.5,7.6,0.8,11.1,0.8c5.1-0.1,10.5,0.3,16.1,1.2c9.6,1.6,20.4,5.5,32,11.3
	c13.2,6.5,20.9,10.5,23.1,11.8c0.5,0.3,1,0.8,1.1,1.4c0.1,0.4,0.1,0.8,0,1.2l-0.1,0.7l-0.5,1.5c-0.8,1.6-1.8,2.3-2.9,2.2"
          />
          <path
            fill="none"
            d="M679,296l-17.3-1.8l-4.1-3.7l21.9,2.3 M681.5,291.9c-0.8,0.3-1.5,0.5-2.1,1 M679.4,292.8L679,296 M741.9,321
	l3.3,0.7c1.1,0.3,1.9,0.1,2.2-0.4l0.1-0.3l1-2.9c0.1-0.7,0.3-1.4,0.3-1.9c0-0.7-0.4-1.2-1.2-1.6l-1.2-0.8c-6.3-3.6-14.6-8-24.9-13.1
	c-11.6-5.8-22.6-9.6-32.9-11.7h-0.4 M690.6,295.4l-1-0.7 M692.4,295.4h-1.8"
          />
          <path
            fill="none"
            d="M684.4,291.5c1.7-0.1,3.4,0.1,5.4,0.5c9.6,1.8,20.1,5.5,31.6,11.1c12.5,6.2,20.1,10,22.6,11.6
	c0.3,0.1,0.4,0.4,0.5,0.7"
          />
          <path
            fill="none"
            d="M689.6,294.8v-0.5c-1.1-1.4-2.9-2.2-5.2-2.8 M152.8,236.8h-0.3l-2.1-0.1l2.6-1.5 M161.5,230.9l2.2,0.1h0.1
	 M161.4,232.3h-0.3l-2.2-0.1 M158.1,233.9l1.9-1.1c0.4-0.3,0.8-0.4,1.2-0.6 M153.1,235.2l2.2,0.1c0.1,0,0.1,0,0.3,0.1 M158.9,232.2
	l-3.3,1.6"
          />
          <path
            fill="none"
            d="M155.6,233.8l2.2,0.1h0.3 M158.9,232.2l2.6-1.2 M142.2,241.9c0.8-0.4,1.8-0.7,2.9-0.7h0.6
	c5.5,0.4,10.7,1.2,15.5,2.2c0.7,0.1,1.2,0.4,1.8,0.6c0.7,0.4,0.8,1.1,0.3,1.8l-7.7,9.1"
          />
          <path
            fill="none"
            d="M144,262.1l2.2-2.1l7.8-8.1c1.5-1.6,3-3.4,4.4-5.2c1.2-1.4,1.4-2.2,0.1-2.8l-16.5-2.2"
          />
          <path
            fill="none"
            d="M142.8,243.6l14.2,1.9c0.6,0.1,0.8,0.4,0.4,1.1l-0.3,0.3"
          />
          <path
            fill="none"
            d="M157.2,246.9c0-0.3-0.3-0.6-0.6-0.7l-13.5-1.8c-0.4,0.1-0.7,0.3-1.1,0.6l-3.7,2.8c-2.9,2.2-5.6,4.5-8.3,6.9
	l-3,2.8c-0.8,1-0.8,1.5,0.3,1.9l1.4,0.1l13.1-0.1l2.6-0.5c1.1-0.4,2.1-1,3-1.5"
          />
          <path
            fill="none"
            d="M142.1,241.9l4.1-2.6c1-0.6,1.9-0.8,3.2-0.8h0.5c5.5,0.4,10.7,1.2,15.5,2.2c3.2,0.7,4,2.2,2.3,4.5l-8.3,10
	 M153.1,235.2l2.5-1.4 M155.6,235.3l-2.8,1.5 M142.2,241.9h-0.1 M142.1,241.9H142l-2.5-0.3"
          />
          <path
            fill="none"
            d="M146.9,254.4l-0.6,0.6l-0.3,0.3c-1.2,0.8-2.8,1.2-4.5,1.2h-9.2l1.5-1.5c2.3-2.1,4.8-4.1,7.4-6.2l0.1-0.1
	l1.9-1.5l8.5,1.2l-1.2,1.6"
          />
          <path
            fill="none"
            d="M142.5,257.1l1.8-0.3c1.2-0.4,2.2-0.8,2.9-1.6l0.3-0.3l6.2-7.4l-10.5-1.4l-2.9,2.2c-2.9,2.2-5.5,4.5-8.3,6.9
	l-1.9,1.8h12.4L142.5,257.1L142.5,257.1z M125.5,260.1l-0.8-0.4c-0.3-0.3-0.4-0.5-0.3-1l0.3-0.5V258l4.4-4.1
	c2.6-2.3,5.4-4.7,8.3-6.9l3.7-2.8c0.4-0.3,1-0.6,1.5-0.7l0.3-0.1"
          />
          <path
            fill="none"
            d="M149.3,249.9l-6.9-1.1c-2.5,2.1-5,4-7.3,6.2h7.4 M138.8,244.1c1.1-0.6,2.3-0.8,3.7-0.6h0.1 M125.5,260.2h-2.8
	l-0.6-0.1c-1.2-0.3-1.4-1-0.7-2.1c4.1-3.9,8.5-7.6,13.2-11l2.9-2.1l0.1-0.1 M137.8,244.8l1-0.7 M138.8,244.1l-0.3,0.1l-0.7,0.6"
          />
          <path
            fill="none"
            d="M129.3,256.5l1.5-1.4c2.6-2.3,5.4-4.7,8.3-6.9l4-3l12.5,1.6l-5,5.9l-2.9,3l-0.3,0.3h-0.1l-0.1,0.1 M161.5,230.9
	l6.2-2.9c3.4-1.5,6.9-2.9,10.5-4.4l0.3,1.1 M179.8,234.2c-0.1,0.6-0.3,1.2-0.6,1.8l-0.1,0.1 M180.1,232.7c0,0.6-0.1,1-0.1,1.5
	 M178.4,224.6l1.2,4.7c0.3,1.1,0.4,2.2,0.4,3.3 M178.3,237.5l-1.1,1.5c-0.7,0.6-1.7,0.8-3.2,0.7c-6.5-1.2-13.5-2.2-21.2-2.9
	 M179.2,236.1l-1,1.4"
          />
          <path
            fill="none"
            d="M178.4,224.6c-3.3,1.2-6.6,2.6-9.8,4.1c-1.6,0.7-3.2,1.5-4.8,2.2 M146,257.1l-1.5,0.7c-0.8,0.3-1.6,0.5-2.6,0.5
	l-12.8,0.1c-1-0.1-1.4-0.3-1.2-0.5l0.1-0.1l1.5-1.4 M125.5,260.2h0.1 M125.5,260.1v0.1 M125.6,260.2l-0.1-0.1"
          />
          <path
            fill="none"
            d="M145.8,257.1c-1.1,0.4-2.5,0.7-3.9,0.7h-12.2c-1,0-1.1-0.4-0.3-1.2"
          />
          <path
            fill="none"
            d="M148,257.1l-0.6,0.4c-1.8,1.2-3.6,2.1-5.6,2.3l-2.8,0.3h-13.3 M142.5,255h1.1c1.6,0.1,2.9,0,3.4-0.6
	 M130.7,270.8l0.8-0.4l3.6-1.6l5.5-3.4l3.4-3.2 M116.2,275.1l0.8-0.1c4-0.8,7.7-1.9,11.1-3.2l2.5-1.1 M130.7,262.1v8.7 M144,262.1
	h-13.3 M150.8,250l-3.7,4.1l-0.1,0.3 M150.8,250l-1.5-0.1 M153,258l-2.8-0.1l1.5,1.5h0.1 M151.9,259.4l-1.2,1.6 M147.2,256.4
	l-0.7,0.4l-0.3,0.1 M146,257.1h-0.1 M146.2,256.9l-0.3,0.1 M145.8,257.1l0.4-0.1 M147.5,257.1h0.6 M147.2,256.4
	c-0.8,0.4-1.9,0.7-3,0.8h-1.6 M150.6,261l-3.4-0.3l1.5,1.4l1.2,0.1"
          />
          <path
            fill="none"
            d="M149.3,249.9l-2.9,3.3c-1,1.1-2.2,1.7-3.9,1.8 M150.2,254.9l-2.8,2.2 M150.2,254.9l-2.2,2.2 M159.4,255.3
	l-3.9-0.3 M156,259.5l1-1.1 M157,258.4l1.5-1.9 M158.5,256.5l1-1.2 M158.5,256.5l-4-0.1 M154.8,261.3l1.2-1.8 M149.9,262.3l-0.4,0.8
	c-1.2,2.6-4.3,4.8-9.1,6.7c-6.7,3.2-14.6,5.9-23.8,8.4"
          />
          <path
            fill="none"
            d="M128.9,277.1c4-1.2,7.3-2.5,10-3.4c5.5-2.1,8.8-3.6,10.2-4.4c1.8-1,3.2-2.8,4.3-5.4l0.7-1.4 M154.1,262.6
	l-4.1-0.3 M154.5,256.4L153,258 M155.6,255l-2.6-0.1l1.5,1.5 M154.1,262.6l0.7-1.2 M153,258l4,0.4 M156,259.5l-4.1-0.1 M154.8,261.3
	l-4.1-0.3 M161.4,232.3c6.2,0.4,12.4,1.1,18.6,1.9 M179.2,236.1c-7-1-14-1.7-21.2-2.1 M163.8,231.1c5.5,0.4,10.9,1,16.1,1.6
	 M155.6,235.3c7.7,0.4,15.3,1.1,22.7,2.2 M157.2,246.9l-7,8 M116.2,275.1c0.1-1.4,0.3-2.8,0.3-4v-8.9h14.2 M115.7,281.1
	c0.4-0.8,0.7-1.8,0.8-2.8v-0.1 M116.7,278.2l-0.4-3 M116.2,275.2v-0.1 M114.9,281.4c0.4-1,0.7-1.9,0.8-2.9c0.3-1.1,0.4-2.2,0.6-3.2
	 M80.5,341.9l-0.3,1.8 M80.2,343.7c-0.1,0.8-0.4,1.6-0.7,2.5l-1,2.6 M80.3,343.9l0.1-1.9 M80.3,343.9l-0.1-0.1 M81.4,320.5l-1,21.5
	 M99.6,307.1l-1.8,2.1c-2.3,2.8-5.5,4.7-9.5,5.5l-6.9,5.8 M105.1,340.8c-0.6,0.4-0.8,1.2-0.6,2.3c2.1,7.3,7,11,15,11.3"
          />
          <path
            fill="none"
            d="M119.4,354.5h-3.7c-8.3,0-13.8-3.7-16.4-11.3c-0.4-2.2,0.4-3,2.8-2.8l3,0.4 M155,301.8
	c-2.3,1.6-4.7,3.6-6.9,5.6c-2.6,2.3-5,4.8-7,7.3c-5.2,5.9-14.4,9.8-27.8,11.3l-20.5,1.9c5.2,1.4,11,2.5,16.9,3.3
	c5.8,0.5,10.7,0.7,14.9,0.3c7.7-1.1,14.4-4.7,20.2-10.9l3.9-3.7c3.2-2.9,6.6-5.4,10.2-7.7 M99.2,366.7c-0.3-0.5-0.6-1.1-0.7-1.8
	c-3.4-11.6-9.5-18.6-18.3-21"
          />
          <path
            fill="none"
            d="M78.6,349c6.6,1,12.5,5.6,17.9,14.2c0.7,1.2,1.5,2.3,2.5,3.4 M98.9,366.6l1.5,1.8 M99.2,366.7l-0.3-0.1
	 M99.2,366.7l1.2,1.6 M105.1,340.8l25,2.9c1.1,0.1,1.5,0.5,1.2,1.6c-0.8,5.5-3.7,8.5-8.7,9.1h-3 M78.6,349l1,13.3
	c0.3,1.9,0.8,3.3,1.8,4.4l7.7,10l10.2,1.1c3.7,0.3,7.2,0.7,10.6,1.2l3.4,0.7 M100.4,368.3l12.7,11.3 M146.5,285.9
	c-9.9,11.4-23.1,20.4-39.6,27.1c-8.1,3.4-16.6,5.9-25.6,7.4 M159,309.2l-4-7.4 M191.5,347.6l2.5-0.7l3.3-0.7l5.6-0.4l5.5,0.4
	l3.4,0.7"
          />
          <path
            fill="none"
            d="M192,346.2c3.4-1,7-1.5,10.9-1.5c3.9,0,7.4,0.5,10.7,1.5c2.6,0.8,5.2,1.8,7.6,3.3c2.9,1.6,5.6,3.7,8.1,6.2
	l1.6,1.8c2.5,2.8,4.4,5.8,5.9,8.9c2.3,4.8,3.4,10.2,3.4,16c0,5.8-1.1,11-3.4,16c-1.8,3.9-4.4,7.4-7.6,10.6l-3.4,3.2
	c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8c-1.2-1-2.3-2.1-3.4-3.2c-3-3-5.5-6.3-7.2-9.8c-2.5-5.1-3.9-10.6-3.9-16.8
	c0-6.1,1.2-11.7,3.9-16.8c1.5-2.9,3.3-5.6,5.6-8.1l1.6-1.8h0.1C181,351,186.3,347.9,192,346.2L192,346.2z M168.1,324.1l3.3,4.7"
          />
          <path
            fill="none"
            d="M151.9,384.9v-2.6c0-14.2,5-26.1,15-36.2c9.9-9.9,22-15,36.2-15c14,0,26.1,5,36,15c1,1,1.9,2.1,2.8,3
	c8.1,9.4,12.1,20.4,12.1,33c0,1.5-0.1,3-0.3,4.5"
          />
          <path
            fill="none"
            d="M262.6,386.9l-0.4-8.1c-0.8-16.6-6.9-30.7-18.3-42.1c-11.3-11.4-25-17.1-41.1-17.1c-11.7,0-22.1,3-31.4,9.1"
          />
          <path
            fill="none"
            d="M168.1,324.1c10.3-7,21.9-10.6,34.9-10.6c17.7,0,32.9,6.6,45.4,19.7c12.7,13.2,19.4,29.3,20.1,48.4l0.3,5.5
	 M171.4,363.8c1.4-2.2,2.9-4.3,4.8-6.3l0.8-1c2.2-2.2,4.5-4.1,7-5.5c0.7-0.4,1.4-0.8,2.1-1.1 M189.3,361.9l-5.5-8.4v-0.1
	 M183.8,353.4c-1.8,1.1-3.4,2.5-5,4.1l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4 M182.4,368.8l0.4,0.3 M173.7,363.4l-2.3,0.4 M171.8,366.7
	l-1.5-1 M168.1,371.1l1-2.8l1.2-2.6"
          />
          <path
            fill="none"
            d="M173.7,363.4h0.1l8.5,5.4 M170.3,365.7l1.1-1.9 M192,349.4c-1.7,0.5-3.3,1.2-5,2.1 M186.1,349.8
	c1.8-1,3.4-1.6,5.4-2.2 M192,349.4l-0.6-1.8 M186.1,349.8l1,1.6 M199.5,376.1c-1.6-2.9-3.3-5.8-5-8.7l-6.2-10
	c-1.1-1.6-2.2-3.3-3.3-5l-1-1.5l-0.4,2.3 M209.8,348.4c-2.2-0.4-4.5-0.7-7-0.7c-2.5,0-4.7,0.3-6.9,0.7 M198,358.3l4.8-0.4
	c1.8,0,3.4,0.1,5.1,0.4"
          />
          <path
            fill="none"
            d="M196,348.4c0.6,3.2,1.1,6.2,1.8,9.1l0.1,0.8 M207.4,360.4l-4.5-0.4l-4.4,0.4 M201.1,369.2l-0.1-0.5l-0.4-1.1
	c-0.8-2.3-1.5-4.7-2.1-7 M190.4,363.7l-1.1-1.8 M182.4,368.8l3.2-3.9c1.2-1.1,2.5-2.2,3.7-3.2 M190.4,363.7c-1,0.7-1.8,1.4-2.8,2.2"
          />
          <path
            fill="none"
            d="M187.8,365.9l-0.8-1c-1.1,0.8-1.9,1.6-2.8,2.5l-1.4,1.7 M191.1,372.5c-0.7-2.1-1.5-3.7-2.2-5.1l-1.1-1.5
	 M196,348.4L194,347l3,13.8l3.7,13.6 M187.1,351.4l5.1,8.8c0.8-0.4,1.8-0.8,2.6-1.1l-2.8-9.8 M198.5,360.4l-0.6-2.1 M191.1,374v-1.5
	 M191.1,374l0.6,0.4 M193,380.5h-3.3 M193,383.9l-0.1-0.1c-0.4-0.4-0.6-1-0.6-1.5c0-0.7,0.1-1.2,0.6-1.6l0.3-0.1 M193.1,382.2
	c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4s0.7-0.1,1-0.4s0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4
	s-0.7,0.1-0.8,0.4C193.3,381.6,193.1,381.8,193.1,382.2L193.1,382.2z M187.9,379.9l-3.6-1.2 M188.6,372.5l2.5,1.5 M191.1,372.5h-2.5
	 M189.7,380.5l-1.8-0.5 M204.7,372.5l-0.1-0.1c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.2,0.3-1.6,0.7l-0.1,0.1"
          />
          <path
            fill="none"
            d="M201.1,372.3c-0.3,0.4-0.6,1-0.6,1.5v0.4 M201.9,373c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1
	c0.3,0.3,0.6,0.4,0.8,0.4c0.3,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-1-0.4
	C202.5,372.6,202.2,372.8,201.9,373L201.9,373z M201.3,375.5c-0.7,0.1-1.2,0.3-1.8,0.7"
          />
          <path
            fill="none"
            d="M200.7,374.4l0.1,0.4l0.4,0.7 M201.3,375.5c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.1-0.3,1.6-0.7 M193,380.5
	c0.4-0.3,0.8-0.5,1.4-0.5h0.6"
          />
          <path
            fill="none"
            d="M196,380.6c-0.3-0.3-0.6-0.4-0.8-0.5l-0.3-0.1 M196.7,378.8c0.3-0.5,0.7-1.1,1.2-1.5c0.6-0.5,1.1-0.8,1.6-1.2
	 M196.7,378.8v0.1 M196,380.6c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.3,1.1-0.7,1.5v0.1 M196.7,378.9c-0.3,0.5-0.6,1.1-0.7,1.6 M199.5,376.1
	l-4.5-5 M196.6,385.7v-0.1 M196.6,385.5c-0.3-0.5-0.6-1.1-0.6-1.8 M196,383.9c-0.3,0.1-0.4,0.3-0.7,0.4l-0.4,0.1"
          />
          <path
            fill="none"
            d="M194.9,384.4h-0.4c-0.6,0-1.1-0.1-1.5-0.5 M199.5,388.4l-1.5-1.2l-1.4-1.7 M201.1,389.1l-1.6-0.7 M204.4,389.1
	c-0.6-0.5-1.1-0.8-1.6-0.8s-1.1,0.3-1.7,0.7v0.1 M201,392.1l0.1,0.1c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.3,1.6-0.7l0.1-0.1"
          />
          <path
            fill="none"
            d="M200.4,390.1v0.5c0,0.5,0.1,1.1,0.6,1.5 M201.1,389.1l-0.6,0.7l-0.1,0.3 M203.6,389.8c-0.3-0.3-0.6-0.4-0.8-0.4
	c-0.4,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4
	c0.3-0.3,0.4-0.5,0.4-0.8C204,390.4,203.9,390.1,203.6,389.8L203.6,389.8z M199.5,388.4l-4.5,5 M187.9,384.6l0.8-0.3l0.4-0.1
	l0.1-0.1l0.4-0.1 M189.7,383.9h3.3 M191.1,390.6l0.6-0.4 M191.1,390.6v1.1 M191.1,391.7h-1.9 M189.1,391.7l1.9-1.1 M191.6,374.4
	l5.1,4.4 M194.9,393.4l-3.3-3.2 M191.6,390.2l5-4.5 M189.7,383.9v-3.4 M187.9,379.9v4.7 M194.9,371.1l-3.3,3.3 M168.9,375.2
	l-1.5-2.1 M178.8,377.2c-3.2-0.8-6.5-1.4-9.8-1.8h-0.1v-0.1 M167.4,373.2l0.7-2.1 M194.9,379.9c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.6
	c-3.2-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3"
          />
          <path
            fill="none"
            d="M168.9,389.1c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2 M167.4,373.2c-0.7,2.9-1.1,5.9-1.1,9.1
	s0.3,6.1,1.1,8.9l0.7,2.5 M168.1,371.1l1.8,0.5 M181,377.7l-2.2-0.5 M169.9,371.6l9.8,2.6l1.1-2.6l-8.9-5 M178.8,377.2
	c-0.3,1.6-0.6,3.4-0.6,5.1s0.1,3.3,0.6,4.8 M180.9,386.6c-0.3-1.4-0.4-2.8-0.4-4.3c0-1.5,0.1-3.2,0.4-4.5 M183.9,385.8l-0.6-0.5
	c-0.7-0.8-1.1-1.8-1.1-2.9c0-1.1,0.3-2.1,1.1-2.9c0.3-0.3,0.6-0.5,1-0.8 M178.8,387.2l2.1-0.5 M180.9,386.6l3-0.8 M196.6,385.7
	c-4.5,2.5-8.9,5-13.2,7.7l-1.8,1.1c-2.9,1.8-5.8,3.6-8.5,5.5c-0.6,0.3-1,0.5-1.5,1l2.2,0.4 M172,398.1l8.8-5.1l-1.1-2.6l-9.8,2.8"
          />
          <path
            fill="none"
            d="M194.9,384.4l-13.6,3.7l-12.1,2.6h-0.1l-1.5,0.4l1.4-2.1"
          />
          <path
            fill="none"
            d="M168.9,389.1l0.1-0.1h0.1c3.3-0.4,6.5-1.1,9.8-1.8 M169.9,393.1l-1.8,0.5 M171.4,363.8l12,7.6
	c4.4,2.6,8.8,5.1,13.3,7.6 M184.3,378.7l-3.3-1 M201.1,369.2v3.2 M183.9,385.8l4-1.2 M171.8,366.7c-0.8,1.5-1.5,3.2-2.1,5
	 M182.8,369l5.8,3.4 M194.9,371.1l-4.5-7.4 M138,384.4h5.8 M168.1,324.1c-3.7,2.6-7.2,5.6-10.6,9.1c-10.7,11.1-16.9,24.2-18.7,39.3
	v0.1 M138.7,372.8c-0.3,2.6-0.6,5.4-0.6,8.1l-0.1,3.6 M144.3,373.6l-0.1,4.7c0,2.2-0.1,4.3-0.3,6.2 M143.8,384.4l8.1,0.4"
          />
          <path
            fill="none"
            d="M171.4,328.7c-3.4,2.2-6.7,5-9.8,8.1c-9.6,9.8-15.3,21.2-17.1,34.3l-0.3,2.6 M144.3,373.6l-5.6-0.8 M159,309.2
	l9.1,14.9 M173.9,401.4h0.1l8.4-5.5 M170.4,398.9l1.5-0.8 M168.1,393.7l1,2.6l1.4,2.6 M170.4,398.9l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7
	c1.5,1.5,3,2.8,4.5,4l2.6,1.8 M182.4,395.9l0.7-0.4 M189.3,402.7c-1.8,2.9-3.6,5.6-5.4,8.4v0.1 M173.9,401.4
	c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.7,3.3,5.6,4.7 M190.4,401l-1.1,1.8 M187.6,398.6l2.8,2.3 M182.4,395.9c1,1.4,1.9,2.6,3.2,3.7
	c1.2,1.2,2.5,2.2,3.9,3.2 M187.6,398.6l-0.6,0.7c-1.5-1.1-2.9-2.5-3.9-3.9 M197.7,406.3l5.1,0.4l4.9-0.4 M198.2,404.2
	c0.7-2.6,1.5-5.2,2.3-7.8l0.3-1 M198.2,404.2l-0.6,2.1 M207.2,404.2l-4.3,0.4l-4.7-0.4 M209.6,416.4c-2.2,0.4-4.5,0.5-6.9,0.5
	c-2.5,0-4.8-0.3-7.2-0.7"
          />
          <path
            fill="none"
            d="M195.8,416.2l0.8-4.3c0.3-1.9,0.7-3.9,1.2-5.6 M191.6,417l0.4-1.8 M191.5,417l2.2,0.5 M184.3,413.7l1.9,1.1
	 M186.3,414.8c1.7,0.8,3.4,1.6,5.2,2.2 M187.1,413.3l-0.8,1.5 M187.1,413.3c1.6,0.8,3.3,1.5,5,1.9 M193.7,417.6
	c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.4,8.8-1l2.3-0.7"
          />
          <path
            fill="none"
            d="M192,415.3l2.6-9.8l-2.5-1.1l-5.1,8.9 M195.8,416.2l-2.1,1.4 M193.7,417.6l3-13.8c1.1-4.8,2.3-9.4,3.7-13.8
	 M184.3,413.7l7.6-12c2.6-4.4,5.1-8.8,7.6-13.3"
          />
          <path
            fill="none"
            d="M151.9,384.9c0.4,11,4.1,20.8,11,29.2c1.2,1.5,2.5,2.9,4,4.4c10,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1
	l2.1-1.9c8.8-8.9,13.8-19.4,14.7-31.5 M183.9,411.4l0.4,2.3 M113.1,379.6l24.9,4.8 M138.7,372.8l-39.5-6.1 M201,395.5v-3.3
	 M191.1,391.7c-1.1,2.9-2.2,5.2-3.4,6.9 M194.9,393.4l-4.5,7.6 M169.9,393.1c0.6,1.6,1.2,3.3,2.1,5 M183.1,395.5l6.1-3.7 M159.2,214
	c-19.3,12.1-36.5,25.3-51.6,39.9 M103.3,271.8l0.1,4 M106.5,277.8l0.4-6.1 M106.9,271.8h-3.6 M103.4,275.8l0.1,2.1h2.9 M112.1,282.1
	c-1.9,5.6-4,10.9-6.3,15.7c-1.9,3.6-4,6.6-6.2,9.4 M113.5,281.7l-1.4,0.4 M114.9,249.6c-2.3,1.5-4.8,2.9-7.3,4.3 M114.9,249.6
	l-6.1,10c-0.6,0.8-0.8,1.6-1.1,2.6c-0.3,1.1-0.6,2.2-0.6,3.4l-0.3,6.1 M103.3,271.8l-0.3-4 M103,267.8h-1l0.3,8h1.1 M107.6,253.9
	l-3.3,5.5c-1.1,1.8-1.5,3.6-1.2,5.6l0.1,2.9 M165.8,226.2l-1.9-3.7 M143.3,272.5l3.2,13.5 M143.3,272.5c-4.7,1.6-9.5,3.2-14.4,4.7
	l-13.1,3.9l-0.8,0.3l-1.2,0.4h-0.1 M146.5,285.9c5.2-6.1,9.6-12.8,13.1-20.2 M158.2,280.4c-0.1,0.5-0.1,1.2-0.1,1.9
	c0,0.8,0.1,1.7,0.3,2.5 M158.3,284.8c1.2,0,2.2-0.3,3-0.7c0.8-0.4,1.2-1,1.2-1.5s-0.4-1.1-1.2-1.5c-0.8-0.4-1.8-0.7-3-0.7h-0.1"
          />
          <path
            fill="none"
            d="M159.6,265.9c-1,0.5-1.9,1.1-3,1.5c-4.3,1.8-8.7,3.4-13.2,5.1 M158.2,280.4c0.4-1.9,1.2-3.6,2.8-5.1
	c1.9-1.9,4.3-2.9,7-2.9c2.8,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7s-1,5.1-2.9,7s-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9
	c-1.4-1.4-2.2-2.9-2.6-4.5 M178.8,220.9c-4.5,1.6-8.8,3.4-13.1,5.4"
          />
          <path
            fill="none"
            d="M159.6,265.9c2.1-1.4,3.9-3.2,5.2-5.6l16-23.9c0.6-1.1,0.8-2.2,0.8-3.4c0-1.1-0.1-2.2-0.4-3.4l-2.3-8.7
	 M194.8,217.4l2.6,4c4.3,6.5,8,11.6,11.1,15l2.1,2.2c0.7,0.7,1.5,1.2,2.3,1.6 M192.7,214.3l2.1,3.2 M200.7,222.3
	c-0.4-1-0.1-1.6,0.8-1.9c7-1.8,14-3.4,21.2-5c0.8,0,1.1,0.4,0.8,1.1L213,234.8c-0.7,0.7-1.4,0.8-1.9,0.1
	C207.3,231.2,203.9,226.9,200.7,222.3L200.7,222.3z"
          />
          <path
            fill="none"
            d="M192.7,214.3c-1.6,1.8-1.5,4.1,0.3,7c3.9,6.2,8,11.6,12.2,16.4c1.2,1.4,2.5,2.5,3.9,3.6l2.3,1.6
	c1.5,1,3.2,1.6,4.8,2.1 M241,202.3c-14.2,2.5-28.1,5.5-41.5,9.1l-3.2,0.8c-1.5,0.6-2.8,1.2-3.4,2.1 M133.9,238.2
	c9.8-5.6,19.8-10.9,29.9-15.5 M133.9,238.2l-0.7,0.3c-6.2,3.6-12.2,7.3-18.3,11 M139.5,241.7l-5.2,3.7c-6.1,4.5-11.7,9.5-16.8,14.9
	l-1.9,2.1v8.9c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-0.8,2.8 M139.5,241.7l-1.2-0.1"
          />
          <path
            fill="none"
            d="M138.2,241.5l-4,2.9c-7.4,5.4-14,11.1-20.1,17.5v9.4c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-1,3.2
	 M139.5,241.7l0.6-0.4c5.2-3.6,10.7-7,16.4-10.2l0.3-0.1c3-1.6,6.1-3.3,9.1-4.7"
          />
          <path
            fill="none"
            d="M165.8,226.2c-3.2,1.4-6.2,2.9-9.1,4.4l-0.3,0.1c-6.3,3.3-12.2,6.7-17.7,10.5l-0.4,0.3 M161.9,219.2
	c0,1-1,1.9-2.9,2.9l-25.2,16.1 M161.9,219.2l-2.8-5.2 M163.8,222.5l-1.9-3.3 M183.5,198.6l11.1-1.8 M178.4,193.1l1.2,3.3
	c0.4,1.7,1.8,2.3,3.9,2.1"
          />
          <path
            fill="none"
            d="M173.1,194.1l1.1,3.4c0.6,1.8,2.1,2.5,4.5,1.9l4.7-0.8 M173.1,194.1l-0.3-0.8c-7.3,1.4-14.4,2.9-21.3,4.4
	c2.3,5.4,4.8,10.9,7.7,16.5 M178.4,193.1l-5.4,1 M195.1,189.4l-16.9,2.8l0.3,1 M194.7,196.8V196 M194.7,196l0.4-6.6 M194.7,196.8
	l-0.1,1.2c-3.6,7.6-8.9,15.1-15.7,22.8 M211.7,182.6c-3.6-1.9-6.7-4.1-9.6-6.6l-23.5-19.1c-0.4-0.6-0.8-1-1.2-1.2
	c-0.3-0.3-0.7-0.6-1.1-0.7l-0.7-0.1c-0.6-0.1-0.8,0-1,0.3h-0.1v0.1c-0.1,0.3-0.1,0.7,0,1.1v0.1l0.4,0.6l0.8,0.8l1.5,1l18.7,15.1
	c3.9,3.3,7.6,7,11,11.1 M106.5,277.8c-1.8,10.7-4.1,20.5-7,29.3 M223.7,291c-7.3-2.2-15.1-3.3-23.5-3.3c-17.2,0-32.2,4.7-45.1,14
	 M155,301.8l-8.5-15.8 M231.5,302.2c-8.9-3.3-18.7-5.1-29.2-5.1c-16.2,0-30.7,4-43.2,12 M461.5,195c-36.3-8-65.1-12.7-86-13.9
	l-4.3-0.3c-14.2-0.8-28.5-1.2-42.9-1.2c-34.7,0.4-69.2,2.3-103.6,5.8"
          />
          <path
            fill="none"
            d="M194.7,196l18.8-2.6c45.7-6.6,94.2-9.5,145.8-8.7l12.1,0.1c22.3,1.1,44.7,4.3,67,9.4l19.7,4.5"
          />
          <path
            fill="none"
            d="M457.4,199.4l-19-4.3c-22.3-5.1-44.7-8.3-67-9.4l-12-0.3c-51.6-0.8-100.1,2.1-145.8,8.7l-18.8,2.6 M461.8,196.3
	h0.1l-0.4-1.2 M458.1,198.9l-0.7,0.6 M461.5,195l-3.4,3.9 M461.8,196.3l-3.7,4 M458.1,200.2c-0.4-0.3-0.5-0.6-0.7-0.8 M468.7,199
	l-6.9-2.8 M458.1,200.2c0.5,0.6,1.2,1,2.3,1.4l4.3,1.5 M522.2,240.3l-1.1-6.3 M522.2,240.3c-1.7-0.8-3.2-1.6-4.8-2.3 M520.1,254.3
	l1.9-0.4 M509.1,271.4c-0.4-1.1-0.5-2.3-0.4-3.9v-3.3c0.1-2.8,0.8-4.7,2.1-5.8c1.7-1.6,3.6-2.9,5.8-3.9c1.1-0.4,2.2-0.7,3.4-0.7
	 M524.5,254.4l-2.3-0.6h-0.1 M522.1,253.9h-1.9 M520.1,253.9v0.4"
          />
          <path
            fill="none"
            d="M520.1,254.3l-1.1,0.4c-2.2,1-4.1,2.2-5.9,3.9c-1.1,1-1.8,2.6-2.1,4.8 M517.8,266.3c-0.1,0.1-0.3,0.4-0.3,0.7
	s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4c0.1-0.1,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.5-0.3-0.8-0.3
	S518.1,266.1,517.8,266.3L517.8,266.3z"
          />
          <path
            fill="none"
            d="M517.1,265.5c-0.4,0.4-0.7,1-0.7,1.5s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.7-0.7
	c0.4-0.4,0.5-1,0.5-1.5s-0.1-1.1-0.5-1.5c-0.4-0.4-1-0.7-1.7-0.7C518.1,264.9,517.5,265,517.1,265.5L517.1,265.5z M511.2,269.4
	c0.1,0.8,0.3,1.5,0.4,2.2c0.4,1.5,1.2,2.6,2.2,3.4c0.8,0.7,1.9,1.1,3.2,1.4 M511.2,263.2v6.2"
          />
          <path
            fill="none"
            d="M517,276.5l-1.7-0.1c-1.7-0.3-2.9-0.7-4-1.5 M511.3,274.8c-1.1-0.8-1.8-1.9-2.2-3.4 M464.7,203.1
	c19.3,6.9,37.6,14.4,55,22.6c17.3,8.1,37.1,17.7,59.4,28.9l0.3,0.1c2.2,1,4.5,1.9,7,2.6 M520.1,253.9l-2.8-16.1 M432,285.5
	c-0.3-0.4-0.8-0.8-1.8-1.2 M430.2,284.4c-1-0.8-2.3-1.5-4.1-2.3l-1-0.3c-0.5-0.3-1,0-1.1,0.7v1"
          />
          <path
            fill="none"
            d="M394.1,280.4c1-0.7,2.5-1.1,4.3-1.5c3.9-0.8,8.5-1.1,13.9-0.5c5.2,0.4,9.8,1.5,13.6,3l0.4,0.1
	c3.2,1.2,5.1,2.8,5.5,4.1"
          />
          <path
            fill="none"
            d="M430.2,284.4c-0.8-0.3-2.1-0.5-3.4-0.8l-2.6-0.3 M423.4,288.4l-0.1,0.7c0,0.3,0,0.5,0.1,0.8
	c0.3,0.4,0.5,0.5,1.2,0.4l1.4-0.3c1.2-0.3,2.5-0.7,3.3-1.1"
          />
          <path
            fill="none"
            d="M393,284c0.8,1.2,2.6,2.5,5.4,3.6c3.9,1.5,8.5,2.6,13.9,3.2c5.2,0.5,9.9,0.4,13.6-0.4l0.3-0.1
	c1.8-0.4,3.2-1,4.1-1.5 M431.3,288.3c0.6-0.5,1-1.1,1-1.8c0-0.3,0-0.5-0.1-0.8 M430.2,288.6l-0.7,0.3 M430.4,288.8l0.8-0.5
	 M429.5,288.8h1 M429.5,288.8l-6.1-0.4 M405,280.9c0-0.7-0.1-1.2-0.4-1.6c-0.1-0.4-0.5-0.5-1.1-0.5h-1"
          />
          <path
            fill="none"
            d="M402.1,287.7l0.1,0.1h0.1c0.7,0.1,1.2,0,1.6-0.5l0.4-0.8 M424,283.3l-19-2.5 M404.6,286.5l18.8,1.9
	 M371.7,260.6l-0.5-3.2 M394.1,280.4l-0.7,0.5c-0.5,0.5-0.8,1.1-0.8,1.8v0.3l0.1,0.4l0.1,0.4 M392.9,283.7l0.1,0.3 M399.9,284.6
	c0.3-0.1,0.7-0.3,0.8-0.5c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4s-0.8,0.1-1.1,0.4
	c-0.3,0.3-0.5,0.7-0.5,1.1s0.1,0.8,0.5,1.1l0.5,0.4"
          />
          <path
            fill="none"
            d="M399.1,284.4l0.5,0.1h0.3 M399.9,284.6l-0.8-0.1 M393,284l1,0.7l0.8,0.4 M395.4,280.2l-1.2,0.3 M402.5,278.8
	l-4.5,0.7c-1,0.3-1.9,0.5-2.6,0.8 M402.5,278.8l0.6,1.9 M403.1,280.7l-7.7-0.5 M402.8,286.2l-5.1-0.5c-1.1-0.1-2.1-0.4-2.9-0.7
	 M402.1,287.7l0.7-1.5 M394.8,285.1c0.5,0.5,3,1.5,7.3,2.8 M403.1,280.7l-0.3,5.5 M371.7,260.6l3,20.9 M375.1,288.1l-0.4-6.6
	 M385.3,258.8l-14.2-1.4 M374.7,281.5l18.2,2.2 M402.8,286.2l1.8,0.3 M403.1,280.7l1.9,0.1 M521.1,233.9
	c-49.2-24.1-99.2-37.4-149.7-40l-9.9-0.3 M517.4,237.8c-47.5-23.2-95.5-36.2-144.2-39.1 M373.2,198.9l12.1,60 M385.3,258.8
	l123.8,12.5 M464.7,203.1l4-4.1 M371.7,260.6l139.6,14.2 M224.9,185.4l-0.3-2.1 M224.6,183.3l-9.6-1.1c-1.2-0.1-2.2,0.1-3.2,0.4
	 M206.9,185.1l-0.8,0.4 M206.1,185.5l0.3,2.1 M224.9,185.4l-8.9,0.8l-9.6,1.4 M224.6,183.3l-8.8,0.8l-9.8,1.4 M211.7,182.6l-4.8,2.5
	 M362.2,198.5l-0.7-4.8 M362.2,198.5c-3.4-0.1-6.9-0.1-10.5-0.1 M237.7,207.8l3.3-5.5 M237.7,207.8l-6.6,1.2 M231.1,209.1l-3.7,0.7
	c-11,2.2-21.9,4.8-32.6,7.7 M225.1,243.2l-5.2-0.6l-2.1-0.4 M216.1,245l3.4,0.6l152.1,15.1 M217.8,242.2l-1.6,2.8 M212.9,240.3
	c1.4,0.8,3,1.5,4.8,1.9 M217.8,242.2l19.9-34.4 M231.1,209.1l-18.2,31.2 M266.5,267.8c-0.8-0.3-2.1-0.5-3.4-0.8l-2.6-0.3"
          />
          <path
            fill="none"
            d="M266.5,267.8c-1-0.8-2.3-1.7-4.1-2.3l-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.7-0.3-1,0-1.1,0.5v1"
          />
          <path
            fill="none"
            d="M268.2,269c-0.5-1.5-2.3-2.9-5.6-4.1l-0.4-0.1c-3.7-1.5-8.3-2.5-13.6-3c-5.5-0.5-10.2-0.4-13.9,0.4
	c-1.8,0.4-3.3,1-4.3,1.5 M259.7,271.8l-0.1,0.7c0,0.3,0,0.5,0.1,0.7c0.3,0.3,0.5,0.4,1.2,0.4l0.7-0.1l2.1-0.5 M268.4,269.7l-0.1-0.7
	 M268.2,269c-0.3-0.5-0.8-0.8-1.8-1.2 M264.7,273.2c0.8-0.3,1.5-0.5,1.9-1 M266.7,272.2c1.1-0.7,1.6-1.5,1.6-2.3v-0.1 M266.7,272.2
	l-1.1,0.1"
          />
          <path
            fill="none"
            d="M230.4,268.6c1,0.8,2.5,1.6,4.4,2.3c3.9,1.5,8.5,2.6,13.9,3.2c5.2,0.4,9.9,0.3,13.6-0.4l2.5-0.5 M263.8,273
	l0.8,0.1 M263.8,273l1.8-0.7 M265.6,272.3l-5.9-0.6 M212.4,265.5l0.4,1 M238.8,262.1c-1.6,0.1-3.2,0.4-4.5,0.7
	c-1,0.3-1.9,0.4-2.6,0.8 M241.3,264.2c0-0.7-0.1-1.2-0.4-1.6c-0.1-0.4-0.6-0.5-1.1-0.5h-1 M239.4,264.1l1.9,0.1"
          />
          <path
            fill="none"
            d="M238.4,271.1l0.1,0.1h0.1c0.7,0.1,1.2-0.1,1.6-0.5l0.4-0.8 M240.9,269.9l-1.9-0.3 M238.9,269.6l0.4-5.5
	 M238.4,271.1l0.6-1.5 M238.8,262.1l0.6,1.9 M229,266.5v0.3l0.3,0.7 M230.4,263.8l-0.7,0.5c-0.6,0.5-0.8,1.1-0.8,1.8v0.5
	 M231.7,263.5l-1.2,0.3 M231.1,268.5l0.6,0.4c1.1,0.5,3.3,1.2,6.9,2.3 M229.3,267.5l0.8,0.5l1,0.4 M238.9,269.6l-5.6-0.5l-2.2-0.5
	 M230.4,268.6c-0.4-0.4-0.8-0.8-1.1-1.2 M239.4,264.1l-7.7-0.5 M212.8,266.4l8.3,19.4c0.8,2.2,1.8,4,2.8,5.2 M229,266.5l-16.6-1.1
	 M212.8,266.4l17.6,2.2 M216.1,245c-2.2,6.7-3.4,13.6-3.7,20.5 M244,206.7l-6.3,1.1 M260.3,266.7l-19-2.5 M240.9,269.9l18.8,1.9
	 M244,206.7l-19,36.5 M361.5,193.6c-42.6-0.8-82.8,2.1-120.5,8.7 M374.7,281.5l-71.8-8.4l-34.5-3.4 M351.8,198.3
	c-37.7-0.3-73.6,2.6-107.7,8.4 M357.4,256.1l-5.6-57.8 M225.1,243.2l132.3,12.9 M264.7,273.2c37.3,5,74.1,9.9,110.3,15 M371.1,257.5
	l-8.9-59 M371.1,257.5l-13.8-1.4 M373.2,198.9l-11-0.4 M743.7,310.3c-2.9-1.6-5.8-3.2-8.8-4.5c-5.8-2.6-11.6-5.1-17.3-7.7
	c-5.2-2.2-11.7-4.5-19.3-6.9c-3.3-1-6.5-1.6-9.8-2.1 M688.2,289c-3.4-0.5-6.7-0.8-10-0.8h-0.4c-5,0-9.8-0.3-14.7-1l-28.8-4
	 M613.1,263.2L612,263c-7.3-1.5-14.6-3-22.1-4.5h-0.1 M673.4,303c-5.5-2.5-10.9-5.1-16.2-7.7c-6.2-3-12.4-6.2-18.4-9.5l-4.5-2.5
	 M752.5,305.6c-1-0.8-2.1-1.5-3.2-2.2c-7.2-4.3-14.7-8.3-22.7-11.7c-30-12.9-62.3-22.4-97.1-28.5h-16.2 M582.2,277.1L582,277
	c-8.1-6.3-16.6-12.2-25.7-17.7c-8.7-5.4-17.5-10.3-26.3-15l-4-2.2 M586.4,257.5l3.3,1 M591.1,277.4l-5.8,2.1
	c-1.7,0.5-3.3,0.8-5.1,1.1c-4.3,0.7-8.9,0.7-14-0.1l-12.2-1.2"
          />
          <path
            fill="none"
            d="M552.7,275.9l13.5,1.4l10.5,0.5c1.9-0.1,3.7-0.4,5.5-0.7h0.1 M586.4,257.5l-5.2,12.5 M581.2,270
	c3.3,2.5,6.6,5,9.9,7.4 M591.1,277.4l-8.9-0.3 M540.5,272.5l20.9,2.2c4.5,0.4,7.6,0.4,8.9-0.3c-5.2-4.4-10.6-8.3-16.1-11.6
	c-6.7-4.1-13.3-8-20.1-11.7c-1.9-0.8-2.8-0.3-2.3,1.6l1,4.7 M524.7,254.4c1.5,0.3,2.9,0.7,4.1,1.1 M528.8,255.5
	c1.4,0.6,2.8,1.1,4,1.8"
          />
          <path
            fill="none"
            d="M532.6,257.3c3.3,1.8,6.2,4.1,8.8,7c0.7,0.7,0.8,1.6,0.4,2.8l-1.5,5.4 M538.8,272.3l-5.6-0.8 M540.5,272.5
	l-1.7-0.1 M540.5,272.5l-1.7-0.1 M533.2,271.5c-0.4-1.4-1.1-2.6-1.9-3.6c-1.1-1.2-2.8-2.1-4.7-2.3h-0.1l-15.4-2.2 M533.2,271.5
	l-5.6-0.7l-16.4-1.4 M553.8,279.2v-0.4l-1.1-2.9 M526.7,280.6l2.8,8c1.7,4.8,4.7,7,9.4,6.3c2.1-0.5,3.7-1.6,5-3.2s1.7-3.2,1-5.2
	l-2.2-6.5 M517,276.5l7.2,0.7c0.5,0,1.1,0.1,1.4,0.5 M525.5,277.7c0.3,0.3,0.4,0.5,0.5,0.8l0.7,1.9 M542.5,280L542,278 M540.8,274.7
	c-0.3-0.7-0.4-1.5-0.3-2.2 M542,278l-1.2-3.3 M542.5,280c-5.2,1.6-10.6,1.8-15.8,0.5 M542,278l11.8,1.2 M540.5,272.5l-15,5.2
	 M540.8,274.7l12,1.2 M581.2,270c-8-5.9-16.2-11.4-24.8-16.8c-11.8-7-23.5-13.5-35.5-19.3 M528.8,255.5l-2.8-13.3 M634.2,283.3
	l-43.1-5.9 M559.3,303.4l-0.8,11.7 M668.3,312.2c-12.1-7.7-25.9-11.6-41.3-11.6c-20.2,0-37.6,6.6-51.9,19.7
	c-4,3.6-8.8,7.8-14.3,12.7l1.7-7.6 M577.9,309.9c-4.1,2.3-8.5,4.4-13.5,6.1"
          />
          <path
            fill="none"
            d="M562.5,325.4c2.9-3.9,6.3-7.6,10.2-11.1c1.8-1.5,3.6-3,5.4-4.4 M577.9,309.9c2.1-1.1,3.9-2.3,5.6-3.7h-0.3
	 M677.6,393c0.7-3.4,1.1-6.9,1.1-10.6c0-14.2-5-26.1-15-36.2c-10-9.9-22-15-36.2-15c-14,0-26.1,5-36.2,15c-1,1-1.9,2.1-2.8,3
	c-8.1,9.4-12.1,20.4-12.1,33c0,3.6,0.3,7.2,1,10.5 M583.4,306.2c-1.8,1.1-3.6,2.3-5.4,3.7"
          />
          <path
            fill="none"
            d="M601,355.7l-1.7,1.8c-2.5,2.8-4.4,5.8-5.9,8.9c-2.2,4.8-3.4,10.2-3.4,16c0,5.8,1.1,11,3.4,16
	c1.8,3.9,4.4,7.4,7.6,10.6l3.4,3.2c6.6,5.2,14.3,7.8,23.1,7.8c8.8,0,16.5-2.6,23.1-7.8c1.2-1,2.3-2.1,3.6-3.2c3-3,5.4-6.3,7.2-9.8
	c2.5-5.1,3.9-10.6,3.9-16.8c0-6.1-1.2-11.7-3.9-16.8c-1.4-2.9-3.3-5.6-5.6-8.1l-1.5-1.8h-0.1c-4.5-4.5-9.9-7.7-15.7-9.5
	c-3.4-1-7-1.5-10.7-1.5c-3.9,0-7.4,0.5-10.7,1.5c-2.6,0.8-5.2,1.8-7.6,3.3C606.2,351.2,603.5,353.2,601,355.7L601,355.7z"
          />
          <path
            fill="none"
            d="M610.9,349.8l-1.8,1H609c-2.6,1.5-5,3.4-7.3,5.8l-0.8,1c-1.8,1.9-3.4,4-4.7,6.2 M598.5,363.3h0.3l8.4,5.4
	 M596.2,363.5l-1.2,2.2 M595,365.7c-0.5,1.1-1.1,2.3-1.5,3.4l-0.7,1.8 M596.5,366.7l-1.5-1 M598.5,363.3l-2.3,0.3"
          />
          <path
            fill="none"
            d="M596.2,363.5c0.6,0.3,1,0.7,1.5,1c2.9,1.9,5.6,3.7,8.5,5.5l1.8,1.1c4.4,2.6,8.8,5.2,13.2,7.7 M592.8,371.1
	l-0.5,2.3c-0.7,2.9-1,5.8-1,8.9c0,3.2,0.4,6.2,1.1,9.1 M603.5,377.4c-3.2-0.8-6.5-1.4-9.9-1.8l-0.1-0.1"
          />
          <path
            fill="none"
            d="M593.6,375.4l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9c0,2.1,0.1,4,0.4,5.9l0.1,1.1 M603.6,387.3
	c-3.2,0.8-6.5,1.4-9.9,1.8l-0.1,0.1 M592.2,391.5l0.7,2.2"
          />
          <path
            fill="none"
            d="M619.6,380.2c-4.4-1.4-8.9-2.6-13.8-3.7l-13.6-3l1.4,2.1 M592.8,371.1l1.7,0.5 M592.9,393.7l0.5,1.6
	c0.4,1.2,1.1,2.5,1.7,3.7 M593.6,389.4l-1.4,2.1 M594.5,393.1l-1.7,0.5 M561.9,394.8l6.2,0.1v-2.1 M568.1,392.8h9.4"
          />
          <path
            fill="none"
            d="M652.6,327.8c-7.4-3.4-15.7-5.2-24.5-5.2c-16.1,0-29.7,5.8-41.1,17.1c-11.4,11.4-17.5,25.5-18.3,42.1l-0.6,11
	 M594.4,371.6c0.5-1.8,1.2-3.4,2.1-5 M553.1,373.6l-0.3,2.3c-0.1,2.2-0.4,3.9-0.8,5.2c-0.8,2.2-2.8,3.2-6.1,3.2l-172-4.5 M554,365.9
	c4.3,0.5,5.9,2.3,5.2,5.1c-0.4,1.6-2.5,2.5-6.1,2.6"
          />
          <path
            fill="none"
            d="M561.9,394.8l0.5-10.2c0.8-19.1,7.6-35.2,20.1-48.4c12.5-13.1,27.7-19.7,45.4-19.7c10.6,0,20.2,2.3,29,7
	 M553.1,373.6l0.8-7.7 M559.3,303.4l24.1,2.8 M564.4,315.9l-5.9-0.8 M558.5,315.1c-1.2,17.3-2.8,34.3-4.5,50.9 M562.5,325.4l1.9-9.5
	"
          />
          <path
            fill="none"
            d="M638.8,347.6l-2.3-0.7c-1.1-0.3-2.2-0.5-3.4-0.7l-5.5-0.4l-5.5,0.4l-3.4,0.7 M672.4,309.7v0.1l-4.1,2.3
	 M673.7,303.1l2.6,1.1 M676.3,304.2l0.8,0.4 M747.2,308.1c0.7,0.4,1.4,0.8,1.9,1.4c2.1,1.6,3.9,3.3,5.4,5.2c1.1,1.5,1.5,3.2,0.8,5.2
	l-1,2.9c-1.2,2.6-4.3,3.6-8.9,2.9l-19.8-4.4c-15.5-3.9-30.7-8.9-45.4-15l-3.4-1.5 M677.1,304.7l-4.7,5.1"
          />
          <path
            fill="none"
            d="M672.4,309.7c1.8,1.4,3.7,2.9,5.5,4.5c12.4,11.1,19.5,24.2,21.7,39.3c0.3,1.4,0.4,2.8,0.5,4.1
	c-2.5-14.3-9.5-26.8-21.2-37.4c-3.4-3.2-6.9-5.8-10.7-8.1 M657,323.5l-4.4,4.3 M652.6,327.8c6.1,2.8,11.6,6.7,16.8,11.8
	c9.6,9.6,15.3,21,17.1,34.3c0.3,2.3,0.5,4.8,0.5,7.3c0.1,4.4,0.4,8.4,1.1,11.7 M668.3,312.2L657,323.5"
          />
          <path
            fill="none"
            d="M657,323.5c5.9,3.2,11.4,7.4,16.5,12.7c10.7,11.1,16.9,24.2,18.7,39.3c0.4,2.8,0.5,5.5,0.5,8.4
	c0,3.6,0.3,6.7,0.7,9.8 M644.2,349.8l-1,1.5 M641.2,361.9l5.5-8.4v-0.1 M646.5,353.4l-0.4-2.3l-10.5,16.4l-5,8.7 M656.6,363.4
	c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5c-1.5-1.5-3.3-2.9-5-4.1 M658.9,363.8c-1.4-2.2-2.9-4.3-4.8-6.3l-0.8-1c-2.2-2.2-4.5-4.1-7-5.5
	c-0.7-0.4-1.4-0.8-2.1-1.1 M658.9,363.8l-2.3-0.4 M660,365.7c-0.3-0.7-0.7-1.4-1.1-2.1 M660,365.7l-1.5,0.8 M662.2,371.1
	c-0.3-1-0.7-1.9-1.1-2.8c-0.4-0.8-0.7-1.6-1.2-2.5 M656.6,363.4l-8.5,5.4 M648.1,368.8l-1-1.4c-0.7-0.8-1.4-1.6-2.2-2.5
	c-1.1-1.1-2.5-2.2-3.7-3.2 M642.7,365.9l0.8-1c1.1,0.8,1.9,1.6,2.8,2.5c0.5,0.5,1,1.1,1.4,1.7 M647.5,369l0.6-0.3 M662.2,393.7
	l0.7-2.5c0.7-2.9,1-5.8,1-8.9s-0.3-6.2-1.1-9.1 M646.1,378.7l3.3-1 M646.5,385.8l0.5-0.5c0.7-0.8,1.1-1.8,1.1-2.9
	c0-1.1-0.4-2.1-1.1-2.9c-0.3-0.3-0.6-0.5-1-0.8 M651.5,377.2c0.3,1.6,0.6,3.4,0.6,5.1s-0.1,3.3-0.4,4.8 M649.4,377.7l2.1-0.5
	 M649.6,386.6c0.3-1.4,0.4-2.8,0.4-4.3c0-1.5-0.1-3.2-0.4-4.5 M658.5,366.7l-8.8,5c0.4,0.8,0.8,1.6,1.1,2.6l9.8-2.6 M662.9,373.2
	l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2l-2.1,0.6c-4.7,1.1-9.2,2.3-13.6,3.7 M660.6,371.7l1.8-0.5 M651.5,377.2
	c3.2-0.8,6.5-1.4,9.8-1.8h0.1v-0.1 M661.4,375.2c0.4,2.3,0.7,4.7,0.7,7.2c0,2.3-0.3,4.7-0.7,6.9 M661.5,389.1l-0.1-0.1h-0.1
	c-3.3-0.4-6.5-1.1-9.6-1.8 M660.4,393.1l1.8,0.5 M661.5,389.1l1.4,1.9l-1.7-0.3h-0.1l-9.9-2.2l-8.8-2.2l-7-1.9 M651.6,387.2
	l-2.1-0.5 M649.6,386.6l-3-0.8 M633.8,385.7c4.5,2.5,8.9,5,13.2,7.7l1.8,1.1c2.9,1.8,5.8,3.6,8.5,5.5c0.4,0.3,1,0.5,1.5,1l-2.3,0.4
	 M658.5,398.1l-8.8-5.1c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.8 M662.9,373.2c-0.1-0.7-0.4-1.4-0.6-2.2 M660.6,371.7
	c-0.5-1.8-1.2-3.4-2.1-5 M661.4,375.2l1.5-2.1 M633.8,378.9c4.5-2.5,8.9-5,13.3-7.6l12-7.6 M611.7,351.4l-1-1.6 M614.1,361.9
	c-0.8-1.5-1.8-2.9-2.8-4.4l-2.6-4.1v-0.1 M608.7,353.2c-1.8,1.2-3.6,2.6-5.2,4.3l-0.4,0.4c-1.7,1.7-3.2,3.4-4.5,5.4 M624.1,376.1
	c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7l-7.4-11.8l-0.4,2.3 M622.5,358.3l-0.3-0.8c-0.7-2.9-1.2-5.9-1.7-9.1h-0.1 M618.6,346.9h-0.1
	l-2.2,0.7 M620.5,348.4l-1.9-1.5 M616.3,347.6l0.6,1.8 M615.2,363.7l-1.1-1.8 M616.7,349.4l2.6,9.8c-0.8,0.3-1.8,0.7-2.6,1.1l-5-8.8
	 M607.2,368.6l1.8,1.2 M614.1,361.9c-1.4,0.8-2.6,1.9-3.9,3.2l-1.1,1.2c-0.7,0.8-1.4,1.6-2.1,2.5 M615.2,363.7
	c-1.2,0.8-2.3,1.8-3.4,2.9l-1,1c-0.7,0.8-1.4,1.5-1.9,2.5 M616.3,347.6c-1.8,0.5-3.6,1.2-5.4,2.2 M616.7,349.4
	c-1.7,0.5-3.3,1.2-5,2.1 M618.6,346.9l2.9,13.9l3.7,13.6 M632.4,358.3l-4.8-0.4c-1.8,0-3.4,0.1-5.1,0.4 M634.4,348.4
	c-0.5,3.2-1.1,6.2-1.8,9.1l-0.1,0.8 M638.3,349.4l0.5-1.8 M629.8,374.4l4.4-16.9c0.7-2.9,1.4-5.8,1.9-8.8l0.3-1.8l-2.1,1.4
	 M639.4,372.5c0.7-2.1,1.5-3.7,2.2-5.1l1.1-1.5 M639.9,363.7c1,0.7,1.8,1.4,2.8,2.2 M639.9,363.7l1.2-1.9 M638.3,349.4l-2.8,9.8
	c1,0.3,1.8,0.7,2.6,1.1l5.1-8.8 M623,360.4c0.7,2.3,1.4,4.8,2.2,7l0.6,1.8 M629.2,369.2l0.5-1.8c0.8-2.3,1.5-4.7,2.1-7 M631.8,360.4
	l-4.4-0.4l-4.5,0.4 M625.6,369.2h3.6 M634.4,348.4c-2.2-0.4-4.5-0.7-6.9-0.7c-2.5,0-4.8,0.3-7,0.7 M632.4,358.3l-0.5,2.1 M626.6,373
	c0.3-0.3,0.5-0.4,0.8-0.4s0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4
	s-0.4-0.5-0.4-1C626.2,373.4,626.3,373.2,626.6,373L626.6,373z M625.6,372.5l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7s1.1,0.3,1.5,0.7l0.1,0.1
	 M629.1,375.5c-0.4,0.4-1,0.7-1.7,0.7s-1.2-0.3-1.7-0.7 M625.2,374.4l0.1,0.3l0.5,0.7 M625.6,372.5c-0.4,0.4-0.5,0.8-0.5,1.4v0.5
	 M625.9,375.5c-0.5,0.1-1.1,0.3-1.7,0.7 M629.8,374.4V374c0-0.5-0.1-1.1-0.5-1.5 M629.1,375.5c0.1-0.3,0.4-0.4,0.4-0.7l0.1-0.4
	 M630.9,376.1c-0.5-0.3-1.1-0.5-1.8-0.7 M630.9,376.1c0.5,0.3,1.1,0.7,1.7,1.2c0.4,0.5,0.8,1,1.2,1.5 M639.4,374l2.5-1.5
	 M641.7,372.5h-2.5 M639.4,372.5v1.5 M639.4,374c-0.1,0.1-0.4,0.3-0.7,0.3 M640.6,380.5l1.8-0.5 M635.4,379.9h0.6
	c0.5,0,1.1,0.1,1.5,0.5 M635.4,379.9l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.6 M637.2,382.2c0,0.3-0.1,0.7-0.4,0.8
	c-0.3,0.3-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,0.8-0.4
	c0.4,0,0.7,0.1,0.8,0.4C637.1,381.6,637.2,381.8,637.2,382.2L637.2,382.2z M637.5,380.5l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6
	c0,0.5-0.3,1.1-0.7,1.5l-0.1,0.1 M634.4,383.9c-0.4-0.5-0.7-1.1-0.7-1.6c0-0.7,0.3-1.2,0.7-1.6 M634.3,380.6
	c-0.1-0.5-0.4-1.1-0.7-1.6 M638.7,374.4l-3.2-3.2 M637.5,380.5h3.3 M633.8,378.8l5-4.5 M635.5,384.4l-1.1-0.5 M633.8,385.5
	c0.3-0.5,0.5-1.1,0.7-1.8 M635.5,384.4h0.4c0.6,0,1.1-0.1,1.5-0.5 M640.6,383.9l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3
	 M641.2,391.7c-0.7-0.4-1.2-0.8-1.9-1.1 M639.4,390.6l-0.7-0.4 M639.4,390.6v1.1 M641.2,391.7h-1.9 M637.5,383.9h3.2 M638.7,390.2
	l-5-4.5 M635.5,393.4l3.2-3.2 M625.9,389.1c-0.5-0.1-1.1-0.4-1.8-0.7 M625.9,389.1c0.5-0.5,1.1-0.8,1.7-0.8c0.7,0,1.2,0.3,1.7,0.7
	v0.1 M631,388.4c-0.6,0.3-1.1,0.5-1.7,0.7 M633.8,385.5c-0.3,0.5-0.7,1.1-1.2,1.7c-0.5,0.5-1,0.8-1.5,1.2 M629.4,392.1l-0.1,0.1
	c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.7-0.7l-0.1-0.1 M629.9,390.1v0.5c0,0.5-0.1,1.1-0.5,1.5 M629.4,389.1l0.5,0.7l0.1,0.3
	 M628.9,390.6c0,0.4-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-1,0.4c-0.3,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4C628.8,390.1,628.9,390.4,628.9,390.6L628.9,390.6z M625.9,392.1
	c-0.4-0.4-0.5-1-0.5-1.5v-0.4 M625.4,390.2l0.1-0.4c0.1-0.3,0.1-0.4,0.4-0.7 M640.6,383.9v-3.6 M642.6,379.9v4.7 M631,388.4l4.5,5
	 M635.4,371.1l-4.5,5 M605.6,386.9c-0.3-1.5-0.4-3-0.4-4.5c0-1.5,0.1-3,0.4-4.4 M603.5,377.4c-0.3,1.6-0.4,3.2-0.4,5
	c0,1.8,0.1,3.4,0.5,5.1 M605.6,377.8l-2.1-0.5 M594.4,371.7l9.9,2.8l1.1-2.6l-8.8-5.1 M619.6,380.2h-0.4c-0.5,0-1.1,0.1-1.5,0.5
	 M621.4,378.9c0.3-0.5,0.7-1.1,1.2-1.6c0.4-0.4,1-0.8,1.5-1.1 M620.7,384c0.5-0.5,0.7-1.1,0.7-1.6c0-0.7-0.3-1.2-0.7-1.6l-0.1-0.1
	 M617.9,382.4c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.5,0.4,0.8c0,0.3-0.1,0.7-0.4,1
	c-0.3,0.3-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-0.8-0.4C618.1,382.9,617.9,382.7,617.9,382.4L617.9,382.4z M620.7,380.7
	c-0.3-0.1-0.4-0.3-0.7-0.4l-0.4-0.1 M621.4,378.9l-0.7,1.6 M614.5,380.6l-0.7-0.1l-0.4-0.1c-2.6-1-5.2-1.8-7.8-2.5 M617.7,380.6
	h-3.2 M617.7,384.2l-0.1-0.1c-0.4-0.4-0.7-1-0.7-1.7c0-0.7,0.3-1.2,0.7-1.6l0.1-0.1 M616.4,374.4l4.8,4.5 M619.6,371.1l-3.2,3.2
	 M617.7,384.2h-3.3 M614.5,384.2l-0.7,0.1l-0.4,0.1c-2.6,1-5.2,1.8-7.8,2.5 M619.7,384.6h-0.5c-0.5,0-1-0.1-1.4-0.5 M619.7,384.6
	l0.3-0.1l0.7-0.5 M620.7,384c0.1,0.5,0.4,1.1,0.7,1.6 M624.3,388.6c-0.5-0.3-1.1-0.8-1.7-1.2c-0.4-0.4-0.8-1-1.2-1.5 M621.4,385.7
	l-5,4.5 M616.4,390.2l3.2,3.2 M621.4,385.7c-4.5,2.5-8.9,5-13.3,7.6l-1.8,1.1c-2.9,1.8-5.8,3.6-8.7,5.4l-1.5,1 M596.6,398.1l8.8-5
	l-1.1-2.6l-9.8,2.6 M605.6,386.9l-2.1,0.5 M592.2,391.5l13.8-3c4.8-1.1,9.4-2.5,13.8-3.7 M614.5,380.6v3.6 M616.4,390.2
	c-2.5,1.4-5.1,2.9-7.6,4.4 M609,369.9l7.4,4.5 M624.1,376.2l-4.5-5 M623,360.4l-0.5-2.1 M629.2,372.3v-3.2 M625.6,369.2v3.3
	 M619.6,393.4l4.5-5 M619.6,371.1l-4.4-7.6 M635.4,371.1l4.4-7.4 M644.2,349.8c-1.8-1-3.4-1.6-5.4-2.2 M643.2,351.4
	c-1.7-0.8-3.3-1.5-5-2.1 M642.6,384.6l4,1.2 M647.2,395.4l-1.1-0.7c-1.7-1.1-3.3-2.1-5-3 M642.4,379.9l3.6-1.2 M647.5,369l-5.8,3.4
	 M613.9,402.7c-1.2-1-2.5-1.9-3.7-3.2c-1.2-1.2-2.2-2.5-3.2-3.9 M608.6,411.3l0.4,2.2l1-1.4V412c1.9-2.8,3.7-5.5,5.5-8.4l1.1-1.8
	c2.8-4.4,5.2-8.8,7.7-13.3 M611.9,413.3l5.1-8.8c0.8,0.4,1.7,0.8,2.6,1.1l-2.8,9.8 M615,400.9l-1.1,1.8 M616.8,415.3l-0.4,1.8
	 M620.7,416.4l-1.9,1.2l3-13.6c1.1-4.7,2.3-9.4,3.7-13.8 M622.6,406.3c-0.4,1.9-0.8,3.7-1.1,5.6l-0.7,4.1l-0.1,0.1 M616.3,417
	l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1 M611.1,414.8l0.8-1.5 M608.4,411.3V411l5.5-8.4 M596.1,400.8
	c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4l4.7,2.9 M615.1,401c-1.2-0.8-2.3-1.8-3.3-2.9c-1.1-1.1-2.1-2.2-2.9-3.4 M611.1,414.8
	c1.7,0.8,3.4,1.6,5.4,2.2 M616.8,415.3c-1.7-0.5-3.3-1.2-5-1.9 M598.4,401.1c1.2,1.9,2.9,3.9,4.5,5.6c1.8,1.8,3.6,3.2,5.5,4.5
	 M625.9,395.4l-0.3,1c-1,2.6-1.8,5.2-2.5,7.8 M629.5,395.4l0.1,0.7l0.1,0.4c1,2.6,1.7,5.2,2.3,7.8 M632.7,406.3l-0.5-2.1
	 M632.1,404.2l-4.5,0.4l-4.4-0.4 M625.9,395.4h3.6 M632.7,406.3l-5.1,0.4l-5-0.4 M640,400.9c0.4,0.6,0.8,1.2,1.1,1.8 M643.3,413.3
	l-5-8.8c-0.8,0.4-1.7,0.8-2.6,1.1l2.6,9.8 M636.6,417.6l2.2-0.5 M636.6,417.6l-2.1-1.4 M634.6,416.2l-0.7-4.3l-1.2-5.6 M638.3,415.3
	l0.5,1.8 M620.7,416.4c2.2,0.4,4.5,0.5,6.9,0.5c2.5,0,4.8-0.3,7-0.7 M636.6,417.6l-3-13.8c-1.1-4.8-2.5-9.4-3.7-13.8 M623.2,404.2
	l-0.5,2.1 M647.9,395.9c-0.8,1.4-1.9,2.6-3.2,3.7c-1.2,1.2-2.5,2.2-3.8,3.2 M642.8,398.6l0.5,0.7c1.5-1.1,2.9-2.5,3.9-3.9
	 M647.9,395.9l-0.7-0.4 M646.1,413.7c0.8-0.5,1.8-1.1,2.5-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1 M656.6,401.4
	h-0.1l-8.4-5.5 M660,398.9l-1.5-1 M660,398.9l1.2-2.6l1-2.6 M641,402.7l5.4,8.5v0.1 M644.1,414.8l1.9-1.1h0.1 M644.1,414.8l-0.8-1.5
	 M646.4,411.4l-0.3,2.3"
          />
          <path
            fill="none"
            d="M646.4,411.4c1.9-1.2,3.9-2.9,5.6-4.7c1.8-1.6,3.2-3.6,4.5-5.5"
          />
          <path
            fill="none"
            d="M577.5,392.8c1.9,9.6,6.6,18.2,13.9,25.7l2.1,1.9c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15
	c1.4-1.5,2.8-2.9,4-4.4c5.1-6.3,8.4-13.3,9.9-21.2 M638.8,417c1.8-0.5,3.6-1.2,5.2-2.2 M642.8,398.6l-2.9,2.3 M643.2,413.3
	c-1.5,0.8-3.2,1.5-5,1.9"
          />
          <path
            fill="none"
            d="M646.1,413.7l-7.6-12c-2.6-4.4-5.2-8.8-7.6-13.3 M595.1,399l1,1.8 M595.1,399l1.5-1 M607.1,395.7l-8.4,5.5
	l-0.3-0.1 M598.4,401.1l-2.3-0.3 M658.5,398.1c0.8-1.5,1.5-3.2,2.1-5 M639.4,391.7c1.1,2.9,2.2,5.2,3.4,6.9 M639.9,401l-4.4-7.6
	 M608.9,394.8l-1.8,1 M619.6,393.4L615,401 M625.9,392.1v3.3 M629.4,392.1l0.1,3.3 M594.5,393.1c0.5,1.6,1.2,3.3,2.1,5 M743.8,310.3
	l5.4,3.3l0.1,0.1c1.1,1.4,1.4,3,0.8,5l-1,2.9l-0.5,0.8l-1,0.5l-2.3,0.1l-19.1-4.4l-0.3-0.1c-11.6-2.9-23.5-6.2-36-10 M752.5,305.6
	l-5.2,2.5 M743.8,310.3l3.4-2.2 M762.9,317.7c-2.1-3-4.3-5.9-6.6-8.5c-1.2-1.2-2.5-2.5-3.9-3.6 M758.9,332l0.4-1.4 M759.8,329.4
	l-0.4,1.2 M759.3,330.7h1.2l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8 M757.4,336.6l-0.8,2.5
	c-0.1,0.8-0.3,1.6-0.3,2.5 M757.8,335.3h1.1 M758.9,336.6h-1.5 M757.8,335.3l-0.4,1.2 M758.9,336.6h2.2l0.4-0.1
	c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-2.2 M758.9,342.8h2.5c0.1,0,0.3,0,0.4-0.1
	c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-2.5 M756.5,341.5l0.1,1.2 M756.6,342.8l0.1,0.7
	c0.5,1.5,1.2,2.9,1.8,4.1"
          />
          <path
            fill="none"
            d="M762.9,317.7c-0.5,0.4-1.1,1.2-1.5,2.3l-5.9,18.8c-0.3,0.8-0.3,1.8-0.3,2.8l0.1,1.2l0.3,1.1v0.1l2.3,5l0.3,0.4
	c1.2,2.1,2.8,3.6,4.8,4.5h0.3 M758.9,341.5v-5 M756.5,341.5h2.5 M758.9,342.8h-2.3 M758.9,335.3V332 M758.9,332l-1.1,3.3
	 M747.2,308.1c-3.9-2.5-8-4.7-12.4-6.7c-35.5-15.8-76.1-28.6-121.7-38.1 M758.9,347.6v0.5 M758.5,347.6h0.4 M758.5,347.6l0.4,0.5"
          />
          <path fill="none" d="M758.9,348.1l0.3,0.5l0.1,0.1" />
          <path
            fill="none"
            d="M759.3,348.8h2.5c0.1,0,0.4,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.4-0.1-0.6c-0.1-0.1-0.3-0.1-0.4-0.1h-2.9
	 M763.2,361.7l-5.8-1.9c-0.8-0.1-1.2,0.1-1,1.1l1.2,6.7 M757.7,367.8l0.3,1.1 M743.4,360.9c1.5,0.8,2.9,2.1,4.3,3.7l3.2,4.8
	c1.5,2.5,2.8,5.1,3.9,7.8l0.1,0.3c0.1,1.1-0.4,1.8-1.7,2.1l-0.3,0.1l-4.5-0.3l-35.2-5.1c-2.3-0.5-4-1.8-5-3.6
	c-2.3-4.7-4.3-9.5-5.5-14.7c-0.7-2.2-0.4-3.9,0.5-4.7c1-0.8,2.6-1,5.1-0.4l19.7,4.3C734.2,356.7,739.3,358.4,743.4,360.9
	L743.4,360.9z"
          />
          <path
            fill="none"
            d="M744.8,367.8c-0.5-0.5-1-1.1-1.5-1.5l-22.4-3v2.3l24.3,3.3L744.8,367.8L744.8,367.8z"
          />
          <path
            fill="none"
            d="M714.4,356.8l13.3,2.8c6.1,1.2,11.1,3,15.1,5.2c0.8,0.4,1.5,1,2.2,1.5c0.5,0.5,1.1,1.1,1.7,1.8l0.5,0.8
	c0.7,1,1.4,1.9,1.9,3l0.1,0.1l1.4,2.5c0.7,1.1,1.2,2.3,1.8,3.6"
          />
          <path
            fill="none"
            d="M752.6,378.4h0.1c0.4-0.1,0.7-0.3,0.7-0.5c-1.2-3-2.6-5.8-4.3-8.4l-2.5-3.7c-1.2-1.5-2.5-2.6-3.9-3.3
	c-4-2.3-9.1-4.1-15.1-5.4l-21.6-4.5"
          />
          <path
            fill="none"
            d="M748.8,374.5c-0.4-1-0.8-1.8-1.2-2.5l-26.5-3.6v2.2L748.8,374.5L748.8,374.5z M713.4,372.9l35.4,5.1v0.1
	l3.8,0.3 M758.8,373.7h0.7c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.5s0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8 M758.5,372.3
	l0.3,1.4 M760.3,383.1c-1-0.7-1.9-1.1-3-1.2l-1.9-0.3 M760.3,383.1l-1.5-9.4 M752.2,393.5h1.8c1.8-0.1,3.4-0.4,4.8-1.1l3.2-1.5
	c1.1-0.5,1.9-1,2.6-1.4c0.5-0.3,0.8-0.8,0.8-1.6v-0.8 M752.2,393.5h-4.3"
          />
          <path
            fill="none"
            d="M762.9,387.1l-3.7-3.3c-1.4-1.2-2.8-1.9-4-2.2 M747.9,393.5l-5.8,1.8l-13.6-1.8 M758.5,372.3l-0.5-3.4
	 M747.9,393.5h-19.4 M749,358.2c-4.5-3.4-12.7-6.5-24.3-9.2c-10.2-2.3-18.2-3.2-23.7-2.3L749,358.2L749,358.2z M706.1,352.3
	c-0.8-0.1-1.5,0-2.1,0.4c-0.5,0.5-0.5,1.5-0.1,3v0.1c1.2,5.1,3,9.9,5.4,14.4c0.7,1.5,2.1,2.3,3.8,2.8 M706.1,352.3
	c3,1.1,5.5,2.5,7.7,4.1l0.5,0.4 M716.4,372.3c0,0.3,0.1,0.5,0.6,0.5h0.7c0.4,0,0.5-0.1,0.5-0.5V359c0-0.3-0.1-0.5-0.5-0.5H717
	c-0.3,0-0.5,0.1-0.5,0.5 M716.4,358.9c-0.5-0.7-1.2-1.4-2.1-2.1 M713.4,372.9c1.1,0,2.1-0.3,3-0.7 M713.4,372.9h-0.1H713.4"
          />
          <path
            fill="none"
            d="M755.4,381.6l-42.6-6.3c-2.9-0.4-5.5-0.7-7.8-0.5h-11.7l67.3,12.4h2.6 M688,393l0.1,0.5h5.4 M693.5,393.5h3.7
	 M716.4,358.9v13.5 M758.9,347.6v-4.8 M728.5,393.5h-31.4 M759.8,329.4l2.9-9.1c0.3-0.7,0.5-1.2,1-1.6l-0.7-1 M758,368.9h5.2v-7.2
	 M763.2,361.7v-8 M763.2,353.8v-1.2c-1.7-0.8-2.9-1.9-3.9-3.6 M762.9,387.1h2.5 M697.2,393.5l44.2,3.3l10.9-3.3 M765.4,387.1l-5.1-4
	 M762.8,367.8h-5.1 M677.6,393H688 M689.7,308.5l-13.5-4.3 M231.5,302.2c8.8,3.3,16.8,8.1,24.1,14.7c7.2,6.3,12.5,13.3,16.5,21
	c-3.6-11.6-10.2-21.7-19.9-30.5c-8.4-7.7-17.9-13.2-28.5-16.4 M253.7,386.9h8.9 M262.6,386.9l6.2,0.1 M209.8,348.4
	c-0.6,3.2-1.1,6.1-1.8,9.1l-0.1,0.8 M214.2,347.6l-2.2-0.7h-0.1 M211.8,346.9l-2.1,1.5 M221.8,353.2l-0.4-2.3v0.1l-7.4,11.8
	l-2.8,4.7c-1.6,2.9-3.3,5.8-4.8,8.7 M219.4,349.8c-1.6-0.8-3.4-1.6-5.4-2.2 M218.6,351.4l0.8-1.6"
          />
          <path
            fill="none"
            d="M234.1,363.5c-1.2-2.2-2.9-4.1-4.7-6.2l-0.8-1c-2.2-2.3-4.7-4.1-7.3-5.8h-0.1c-0.6-0.4-1.1-0.7-1.8-1
	 M216.4,361.9l5.4-8.5v-0.1"
          />
          <path
            fill="none"
            d="M223.3,368.6c-0.6-0.8-1.2-1.6-1.9-2.5l-1.1-1.2c-1.2-1.2-2.5-2.2-3.9-3.2 M223.3,368.6l-1.9,1.2 M221.3,369.9
	c-0.6-0.8-1.2-1.6-1.9-2.5l-0.8-1c-1.1-1.1-2.2-2.1-3.4-2.9 M215.1,363.7l1.2-1.8 M207.4,360.4c-0.7,2.3-1.4,4.8-2.2,7l-0.4,1.1
	l-0.1,0.5 M211.8,346.9l-2.9,13.9l-1.8,6.7c-0.7,2.3-1.2,4.7-1.9,7 M213.6,349.4l-2.6,9.8c1,0.3,1.8,0.7,2.6,1.1l5-8.8 M208,358.3
	l-0.6,2.1 M214.2,347.6l-0.6,1.8 M213.6,349.4c1.6,0.5,3.3,1.2,5,2.1 M231.8,363.3c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4
	c-1.6-1.6-3.4-3-5.2-4.3 M237.6,371.1l-0.7-1.8c-0.4-1.2-1-2.3-1.5-3.4 M235.4,365.7l-1.2-2.2 M231.8,363.3h-0.1
	c-2.8,1.8-5.5,3.7-8.4,5.5 M234.1,363.5l-2.3-0.3 M235.4,365.7l-1.5,1 M226.8,377.4c0.3,1.6,0.4,3.2,0.4,5c0,1.8-0.1,3.4-0.4,5.1
	 M224.8,377.8l2.1-0.4 M224.8,386.9c0.3-1.5,0.4-3,0.4-4.5c0-1.5-0.1-3-0.4-4.4 M233.9,366.7l-8.8,5.1c0.4,0.8,0.7,1.8,1.1,2.6
	l9.9-2.8 M236.7,389.4l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9c0-2.1-0.1-4-0.4-5.9l-0.1-1 M238.1,391.5c0.7-2.9,1.1-5.9,1.1-9.1
	c0-3.2-0.3-6.1-1-8.9l-0.6-2.3 M235.9,371.6l1.6-0.5"
          />
          <path
            fill="none"
            d="M236.7,375.4l1.4-2.1l-13.8,3c-4.7,1.1-9.2,2.3-13.8,3.7 M236.7,375.4c-3.4,0.5-6.7,1.2-9.9,1.9 M237.6,393.7
	l0.6-2.2 M235.4,399c0.6-1.2,1.2-2.5,1.6-3.7l0.6-1.6 M238.1,391.5l-1.4-2.1 M235.8,393.1l1.8,0.5 M226.8,387.3
	c3.2,0.8,6.5,1.4,9.9,1.8l0.1,0.1 M226.8,387.3l-2.1-0.4 M233.7,398.1l-8.9-5c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.6 M238.1,391.5l-13.8-3
	c-4.7-1.1-9.4-2.5-13.8-3.7 M205.2,374.4v-0.5c0-0.5-0.1-1-0.6-1.4 M204.6,375.5c0.6,0.1,1.1,0.3,1.6,0.7 M206.2,376.1l0.1,0.1
	 M204.6,375.5l0.4-0.7l0.1-0.4h0.1 M213.9,374.4l-3.2-3.3 M212.7,380.6c-0.4-0.4-0.8-0.5-1.5-0.5h-0.4 M212.4,382.4
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8c0,0.3,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4
	c0.3,0,0.7-0.1,0.8-0.4C212.4,382.9,212.4,382.7,212.4,382.4z M212.7,384.2l0.1-0.1c0.4-0.4,0.7-1,0.7-1.7c0-0.7-0.3-1.2-0.7-1.6
	l-0.1-0.1 M209.6,380.7c-0.6,0.4-0.7,1-0.7,1.6c0,0.5,0.1,1.1,0.6,1.7h0.1 M210.7,380.2l-0.4,0.1c-0.3,0.1-0.4,0.1-0.7,0.4
	 M209.1,378.9c0.3,0.5,0.6,1.1,0.7,1.6 M206.3,376.2c0.6,0.3,1.1,0.7,1.5,1.1c0.6,0.5,1,1.1,1.2,1.6 M206.3,376.2l4.4-5.1
	 M213.9,374.4l-4.8,4.5 M224.8,378c-2.6,0.7-5.2,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1 M216,384.2l0.7,0.1l0.4,0.1c2.6,1,5.2,1.8,7.8,2.5
	 M209,385.7c-0.3,0.6-0.7,1.1-1.1,1.5c-0.6,0.5-1.1,1-1.7,1.2 M206.2,388.6h-0.1 M206.1,388.6c-0.6,0.3-1.1,0.5-1.6,0.7
	 M210.6,384.6h0.6c0.6,0,1.1-0.1,1.4-0.5 M209.6,384c-0.1,0.5-0.4,1.1-0.7,1.6 M209.1,385.7H209 M209.6,384l0.7,0.5h0.3
	 M213.9,390.2l-3.2,3.2 M204.4,392.1c0.3-0.4,0.6-1,0.6-1.5v-0.4"
          />
          <path
            fill="none"
            d="M205,390.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.4-0.4-0.7 M209,385.7l5,4.5 M210.7,393.4l-4.5-4.8
	 M209.1,378.9c4.5-2.5,8.9-5.1,13.2-7.7l11.8-7.7 M212.7,384.2h3.3 M216,380.6h-3.3 M221.5,394.8c-2.5-1.5-5.1-3-7.6-4.4
	 M209.1,385.7c4.5,2.5,8.9,5,13.3,7.6l1.8,1.1l8.7,5.4l1.5,1 M216,380.6v3.6 M204.7,372.5v-3.3 M221.3,369.9l-7.4,4.5 M235.9,371.6
	c-0.6-1.8-1.2-3.4-2.1-5 M210.7,371.1l4.4-7.4 M373.9,379.8l-74.1-1.9c-7.4-0.3-13.2-2.8-17.1-7.3c-17.5-20.8-34.5-43.6-51.3-68.4
	 M204.4,395.5l0.3,1c1,2.6,1.8,5.2,2.5,7.8 M218.4,413.3l-5.1-8.8c-0.8,0.4-1.6,0.8-2.5,1.1l2.8,9.8 M207.2,404.2l0.6,2.1
	 M205,390.2c1.4,4.4,2.6,8.9,3.7,13.8c0.1,0.7,0.4,1.4,0.4,2.1l2.5,11.6l-1.9-1.2 M216.4,402.7c1.2-1,2.6-1.9,3.7-3.2
	c1.2-1.2,2.2-2.5,3.2-3.9 M221.5,394.8c-0.8,1.2-1.8,2.3-2.9,3.4c-1.1,1.1-2.2,1.9-3.4,2.9 M215.3,401l1.1,1.8 M214.1,417
	c1.8-0.5,3.6-1.2,5.4-2.2 M219.4,414.8l-1-1.5 M221.9,411.3V411l-5.5-8.3 M206.1,388.6c2.5,4.5,5.1,8.9,7.8,13.3l7.6,11.7l0.3-2.2"
          />
          <path
            fill="none"
            d="M207.7,406.3c0.4,1.9,0.8,3.7,1.2,5.6l0.7,4.1l0.1,0.1 M213.6,415.3l0.4,1.8 M213.6,415.3
	c1.6-0.5,3.3-1.2,5-1.9 M231.9,401.1c-1.2,1.9-2.9,3.9-4.7,5.6c-1.6,1.8-3.6,3.2-5.5,4.5 M231.9,401.1h-0.1
	c-2.8-1.8-5.6-3.6-8.4-5.4 M234.3,400.8l1.1-1.8 M219.4,414.8l4.5-2.9c1.6-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3 M234.3,400.8
	l-2.3,0.3 M235.4,399l-1.6-1 M204.4,392.1v3.3 M233.7,398.1c0.8-1.6,1.5-3.3,2.1-5 M223.3,395.7l-1.8-1 M210.7,393.4l4.5,7.6
	 M376.2,354l0.4,7.6 M376.6,361.6l-2.8,18.2 M376.6,361.6c33.3,4.1,65.8,7,97.5,8.5l78.8,3.4"
          />
          <path
            fill="none"
            d="M376.2,354l-87.8-5.9c30,5.5,59.4,9.9,88.3,13.6 M268.8,387.1l293.1,7.7 M589.7,258.4
	c-38.8-23.1-79.1-42.9-121.1-59.4 M375.1,288.1c1.1,21.9,1.5,43.9,1.1,65.9 M223.7,291l7.8,11.1 M526,242.2l-3.8-1.9 M431.3,288.3
	l128.1,15.1 M558.5,315.1c-59.6-9.2-120.6-18.2-183.4-27 M553.8,279.2l5.5,24.2 M554,365.9L376.2,354 M583.4,306.2
	c12.2-7.7,26.1-11.6,41.8-11.6c18,0,33.7,5.1,47.2,15.1 M522.2,240.3l2.5,14.2 M206.3,187.6l-11.3,1.8 M212.4,265.5l-12.8-0.7
	l13.2,1.6 M204.7,369.2h-3.6 M204.4,395.5H201 M177.5,155.8c-0.3-0.3-0.7-0.6-1.1-0.7l-0.7-0.1c-0.6-0.1-0.8,0-1,0.3h-0.1v0.1
	c-0.1,0.3-0.1,0.7,0,1.1v0.1l0.4,0.6l0.8,0.8l1.5,1l18.7,15.1c3.9,3.3,7.6,7,11,11.1l4.8-2.5c-3.6-1.9-6.7-4.1-9.6-6.6l-23.5-19.1
	C178.1,156.5,177.7,156.1,177.5,155.8L177.5,155.8z M194.7,196.8l-11.1,1.8l-4.7,0.8c-2.5,0.6-4.1,0-4.5-1.9l-1.1-3.4l-0.3-0.8
	c-7.3,1.4-14.4,2.9-21.3,4.4c2.3,5.4,4.8,10.9,7.7,16.5l2.8,5.1l1.9,3.4l2.1,3.6c4.3-1.9,8.5-3.7,13.1-5.4
	c6.9-7.6,12.1-15.1,15.7-22.8L194.7,196.8L194.7,196.8z"
          />
          <path
            fill="none"
            d="M194.7,198c-3.6,7.6-8.9,15.1-15.7,22.8l2.3,8.7c0.3,1.2,0.4,2.3,0.4,3.4c0,1.2-0.3,2.3-0.8,3.4l-16,23.9
	c-1.5,2.3-3.2,4.3-5.2,5.6c-3.4,7.4-7.8,14.2-13.1,20.2l8.5,15.8c12.9-9.4,28.1-14,45.1-14c8.4,0,16.2,1.1,23.5,3.3
	c-1-1.2-1.9-3-2.8-5.2l-8.3-19.4l-13.3-1.6l12.9,0.8c0.3-7,1.5-13.9,3.7-20.5c-1.6-0.4-3.2-1.1-4.8-2.1l-2.2-2.1
	c-1.2-1.1-2.6-2.2-3.9-3.6c-4.3-4.8-8.4-10.3-12.2-16.4c-1.8-2.9-1.9-5.2-0.3-7c0.8-0.8,1.9-1.5,3.4-2.1l3.2-0.8
	c13.5-3.6,27.4-6.5,41.5-9.1c37.7-6.6,77.9-9.5,120.5-8.7l9.9,0.3c50.5,2.6,100.4,16,149.7,40c11.8,5.8,23.7,12.2,35.5,19.3
	c8.5,5.2,16.8,10.9,24.8,16.8l5.2-12.4c-2.5-0.7-4.8-1.6-7-2.6l-0.3-0.1c-22.3-11.1-42.1-20.8-59.4-28.9
	c-17.5-8.3-35.8-15.7-55-22.6l-4.3-1.5c-1.1-0.4-1.9-0.8-2.3-1.4c-0.4-0.3-0.5-0.6-0.7-0.8l-19-4.3c-22.3-5.1-44.7-8.3-67-9.4
	l-12-0.3c-51.6-0.8-100.1,2.1-145.8,8.7l-18.8,2.6L194.7,198L194.7,198z M175.1,289.5c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9
	c-1.4-1.4-2.2-2.9-2.6-4.5c-0.1-0.8-0.3-1.5-0.3-2.5c0-0.7,0-1.4,0.1-1.9c0.4-1.9,1.2-3.6,2.8-5.1c1.9-1.9,4.3-2.9,7-2.9
	c2.8,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7C178,285.3,177,287.6,175.1,289.5L175.1,289.5z"
          />
          <path
            fill="none"
            d="M178,282.5c0-2.8-1-5.1-2.9-7c-1.9-1.9-4.3-2.9-7-2.9c-2.8,0-5.1,1-7,2.9c-1.5,1.5-2.3,3.2-2.8,5.1h0.1
	c1.1,0,2.2,0.3,3,0.7s1.2,1,1.2,1.5s-0.4,1.1-1.2,1.5c-0.8,0.4-1.8,0.7-3,0.7h-0.1c0.4,1.8,1.4,3.3,2.6,4.5c1.9,1.9,4.3,2.9,7,2.9
	c2.8,0,5.1-1,7-2.9C177,287.6,178,285.3,178,282.5z"
          />
          <path
            fill="none"
            d="M162.6,282.8c0-0.5-0.4-1.1-1.2-1.5c-0.8-0.4-1.8-0.7-3-0.7h-0.1c-0.1,0.5-0.1,1.2-0.1,1.9
	c0,0.8,0.1,1.6,0.3,2.5h0.1c1.1,0,2.2-0.3,3-0.7C162.2,283.9,162.6,283.3,162.6,282.8L162.6,282.8z M194.7,196l18.8-2.6
	c45.7-6.6,94.2-9.5,145.8-8.7l12.1,0.1c22.3,1.1,44.7,4.3,67,9.4l19.7,4.5l3.6-3.7c-36.3-8-65.1-12.7-85.8-13.9l-4.3-0.3
	c-14.2-0.8-28.5-1.2-42.9-1.2c-34.7,0.4-69.2,2.3-103.6,5.8l-9.1,0.8l-9.8,1.4l-11.1,1.8L194.7,196L194.7,196z"
          />
          <path
            fill="none"
            d="M195.1,189.4l-16.9,2.9l0.3,1l1.2,3.3c0.4,1.6,1.8,2.3,3.9,2.1l11.1-1.8V196L195.1,189.4L195.1,189.4z"
          />
          <path
            fill="none"
            d="M194.7,196.8l18.8-2.6c45.7-6.6,94.2-9.5,145.8-8.7l12,0.3c22.3,1.1,44.7,4.3,67,9.4l19,4.3l0.6-0.6l-19.7-4.5
	c-22.3-5.1-44.7-8.3-67-9.4l-12-0.3c-51.6-0.8-100.1,2.1-145.8,8.7l-18.8,2.6L194.7,196.8L194.7,196.8z M173.1,194.1l1.1,3.4
	c0.6,1.8,2.1,2.5,4.5,1.9l4.7-0.8c-2.1,0.3-3.4-0.4-3.9-2.1l-1.2-3.3L173.1,194.1L173.1,194.1z M161.9,219.2c0,1-1,1.9-2.9,2.9
	l-25.2,16.1c9.8-5.6,19.8-10.9,29.9-15.5L161.9,219.2L161.9,219.2z"
          />
          <path
            fill="none"
            d="M159.2,214c-19.3,12.1-36.5,25.3-51.6,39.9c2.6-1.2,5.1-2.8,7.3-4.3c6.1-3.9,12.2-7.6,18.3-11l0.7-0.4l25-16.1
	c1.9-1.1,2.9-2.1,2.9-2.9L159.2,214L159.2,214z"
          />
          <path
            fill="none"
            d="M156.7,230.6c2.9-1.5,5.9-3,9.1-4.4l-2.1-3.6c-10,4.8-20.1,10-29.9,15.5l-0.7,0.3c-6.2,3.6-12.2,7.3-18.3,11
	l-6.1,10c-0.6,0.8-0.8,1.6-1.1,2.6c-0.3,1.1-0.6,2.2-0.6,3.4l-0.3,6.1l-0.3,6.1c-1.8,10.7-4.1,20.5-7,29.3c2.2-2.8,4.3-5.8,6.2-9.4
	c2.3-4.8,4.5-10,6.3-15.7l1-3.2c0.4-1.2,0.7-2.5,0.8-3.7c0.1-1.4,0.3-2.8,0.3-4.1v-9.4c6.1-6.3,12.7-12.1,20.1-17.5l4-2.9l0.4-0.3
	c5.5-3.7,11.4-7.3,17.7-10.5L156.7,230.6L156.7,230.6z"
          />
          <path
            fill="none"
            d="M156.4,230.6c-6.3,3.3-12.2,6.7-17.7,10.5l-0.4,0.3l1.1,0.1l0.6-0.4c5.2-3.6,10.7-7,16.4-10.2l0.3-0.1
	c3-1.6,6.1-3.3,9.1-4.7c-3.2,1.4-6.2,2.9-9.1,4.4L156.4,230.6L156.4,230.6z"
          />
          <path
            fill="none"
            d="M156.4,231.1c-5.8,3.2-11.3,6.6-16.4,10.2l-0.6,0.4l2.6,0.4l4.1-2.6c1-0.6,1.9-0.8,3.2-0.8h0.5
	c5.5,0.4,10.7,1.2,15.5,2.2c3.2,0.7,4,2.2,2.3,4.5l-8.3,10l-1.1,1.2l-1.5,1.8l-1,1.2l-1.2,1.6l-0.8,1.2l-0.7,1.4
	c-1.1,2.6-2.5,4.4-4.3,5.4c-1.4,0.8-4.7,2.3-10.2,4.4c-2.8,1-6.1,2.2-10,3.4c5-1.5,9.8-3,14.4-4.7c4.5-1.6,8.9-3.3,13.2-5.1
	c1.1-0.4,2.2-1,3-1.5c2.1-1.4,3.9-3.2,5.2-5.6l16-23.9c0.6-1.1,0.8-2.2,0.8-3.4c0-1.1-0.1-2.2-0.4-3.4l-2.3-8.7
	c-4.5,1.6-8.8,3.4-13.1,5.4c-3.2,1.5-6.2,3-9.1,4.7L156.4,231.1L156.4,231.1z M152.6,236.8l-2.1-0.1l2.6-1.5l2.5-1.2l3.3-1.8
	l2.6-1.2l6.2-2.9c3.4-1.5,6.9-2.9,10.5-4.4l0.3,1.1l1.2,4.7c0.3,1.1,0.4,2.2,0.4,3.3c0,0.6-0.1,1-0.1,1.5c-0.1,0.6-0.3,1.2-0.6,1.8
	l-0.1,0.1l-1,1.4l-1.1,1.5c-0.7,0.6-1.7,0.8-3.2,0.7c-6.5-1.2-13.5-2.2-21.2-2.9L152.6,236.8L152.6,236.8L152.6,236.8z"
          />
          <path
            fill="none"
            d="M152.8,236.8l2.8-1.5c-0.1,0-0.1-0.1-0.3-0.1l-2.2-0.1l-2.6,1.5l2.1,0.1h0.3L152.8,236.8L152.8,236.8z
	 M161.5,230.9l-2.6,1.2l2.2,0.1h0.1c6.2,0.4,12.4,1.1,18.6,1.9c0.1-0.6,0.1-1,0.1-1.5c-5.4-0.7-10.7-1.2-16.1-1.6h-0.1L161.5,230.9
	L161.5,230.9z"
          />
          <path
            fill="none"
            d="M163.7,231.1h0.1c1.5-0.8,3.2-1.5,4.8-2.2c3.2-1.4,6.3-2.8,9.8-4.1l-0.3-1.1c-3.6,1.4-7,2.9-10.5,4.4l-6.2,2.9
	L163.7,231.1L163.7,231.1z M161.1,232.3l-2.2-0.1l-3.3,1.8l2.2,0.1h0.3l1.9-1.1c0.4-0.3,0.8-0.4,1.2-0.6h-0.1L161.1,232.3
	L161.1,232.3z"
          />
          <path
            fill="none"
            d="M160,233l-1.9,1.1c7.2,0.4,14.2,1.1,21.2,2.1l0.1-0.1c0.3-0.6,0.4-1.1,0.6-1.8c-6.1-0.8-12.2-1.5-18.6-1.9
	C160.8,232.4,160.4,232.7,160,233L160,233z"
          />
          <path
            fill="none"
            d="M155.6,235.3c7.7,0.4,15.3,1.1,22.7,2.2l1-1.4c-7-1-14-1.6-21.2-2.1h-0.3l-2.2-0.1l-2.5,1.2l2.2,0.1H155.6z"
          />
          <path
            fill="none"
            d="M152.8,236.8c7.7,0.7,14.9,1.6,21.2,2.9c1.4,0.1,2.5-0.1,3.2-0.7l1.1-1.5c-7.4-1.1-15-1.8-22.7-2.2L152.8,236.8
	z M179.7,229.4l-1.2-4.7c-3.3,1.2-6.6,2.6-9.8,4.1c-1.6,0.7-3.2,1.5-4.8,2.2c5.5,0.4,10.9,1,16.1,1.6
	C180.1,231.6,179.9,230.5,179.7,229.4L179.7,229.4z M161.4,243.6c-4.8-1-10-1.8-15.5-2.2h-0.6c-1.1,0-2.1,0.1-2.9,0.6l16.5,2.2
	c1.1,0.4,1.1,1.4-0.1,2.8c-1.5,1.8-2.9,3.6-4.4,5.2l-7.8,8.1l-2.2,2.1l-3.4,3l-5.5,3.4l-3.6,1.8l-0.8,0.3l-2.5,1.1
	c-3.4,1.2-7.2,2.3-11.1,3.2l-0.8,0.1v0.1l0.4,3c9.2-2.5,17.2-5.2,23.8-8.4c4.8-1.8,7.8-4.1,9.1-6.7l0.4-0.8l-1.2-0.1l-1.5-1.4
	l3.6,0.3l1.1-1.6H152l-1.5-1.5l2.8,0.1l1.5-1.8l-1.5-1.5l2.6,0.1l7.7-9.1c0.6-0.7,0.4-1.4-0.3-1.8C162.6,244,162,243.7,161.4,243.6
	L161.4,243.6z"
          />
          <path
            fill="none"
            d="M163,244.1c0.7,0.4,0.8,1.1,0.3,1.8l-7.7,9.1l4,0.3l8.3-10c1.6-2.3,0.8-3.9-2.3-4.5c-4.8-1-9.9-1.8-15.5-2.2
	h-0.6c-1.2,0-2.2,0.3-3.2,0.8l-4.1,2.6h0.3c0.8-0.4,1.8-0.6,2.9-0.6h0.6c5.5,0.4,10.7,1.2,15.5,2.2C162,243.7,162.6,244,163,244.1
	L163,244.1z"
          />
          <path
            fill="none"
            d="M158.8,244.1l-16.5-2.2H142l-2.6-0.3l-5.1,3.7c-6.1,4.5-11.7,9.5-16.8,14.9l-1.9,2.1v8.9c0,1.4-0.1,2.8-0.3,4.1
	c-0.1,1.2-0.4,2.5-0.8,3.7l-0.8,2.8h0.1l1.2-0.3c0.4-1,0.7-1.9,0.8-2.9c0.3-1.1,0.4-2.2,0.6-3.2v-0.1c0.1-1.4,0.3-2.8,0.3-4v-8.9
	H144l2.2-2.1l7.8-8.1c1.5-1.6,3-3.4,4.4-5.2C159.8,245.5,160,244.5,158.8,244.1L158.8,244.1z M157.5,246.5l-0.3,0.3l-7,8.1l-2.1,2.2
	l-0.6,0.4c-1.8,1.2-3.6,2.1-5.6,2.3l-2.8,0.3H123l-0.6-0.1c-1.2-0.3-1.4-1-0.7-2.1c4.1-3.9,8.5-7.6,13.2-11l2.9-2.1l0.1-0.1l0.7-0.6
	l0.3-0.1c1.1-0.6,2.3-0.8,3.7-0.6h0.1l14.2,1.9C157.6,245.5,157.8,245.8,157.5,246.5L157.5,246.5z"
          />
          <path
            fill="none"
            d="M157.1,245.4l-14.2-1.9l-0.3,0.1c-0.6,0.1-1,0.3-1.5,0.7l-3.7,2.8c-2.9,2.2-5.6,4.5-8.3,6.9l-4.4,4.1v0.1
	l-0.3,0.5c-0.1,0.4,0,0.8,0.3,1l0.7,0.4h13.5l2.8-0.3c2.1-0.4,4-1.2,5.6-2.3l0.6-0.4h-0.6c-1,0.5-1.9,1.1-3,1.5l-2.6,0.5l-13.1,0.1
	l-1.4-0.1c-1-0.3-1.1-1-0.3-1.9l3-2.8c2.6-2.3,5.4-4.7,8.3-6.9l3.7-2.8c0.3-0.3,0.7-0.4,1.1-0.6l13.5,1.8c0.4,0.3,0.6,0.4,0.6,0.7
	l0.3-0.3C157.8,245.8,157.7,245.5,157.1,245.4L157.1,245.4z"
          />
          <path
            fill="none"
            d="M156.7,246.1l-13.5-1.8c-0.4,0.1-0.7,0.3-1.1,0.6l-3.7,2.8c-2.9,2.2-5.6,4.5-8.3,6.9l-3,2.8
	c-0.8,1-0.8,1.5,0.3,1.9l1.4,0.1l13.1-0.1l2.6-0.5c1.1-0.4,2.1-1,3-1.5l2.8-2.2l7-8.1C157.2,246.6,157,246.3,156.7,246.1
	L156.7,246.1z M143.2,245.2l12.5,1.6l-5,5.9l-2.9,3l-0.3,0.3h-0.1l-0.1,0.1l-0.8,0.5l-0.3,0.1l-0.3,0.1l-1.5,0.7
	c-0.8,0.3-1.6,0.5-2.6,0.5l-12.8,0.1c-1-0.1-1.4-0.3-1.2-0.5l0.1-0.1l1.5-1.4l1.5-1.4c2.6-2.3,5.4-4.7,8.3-6.9L143.2,245.2
	L143.2,245.2z"
          />
          <path
            fill="none"
            d="M143.5,246.2l10.5,1.4l-6.2,7.4l-0.3,0.3c-0.8,0.7-1.8,1.2-2.9,1.7l-1.8,0.3h1.7c1.1-0.1,2.2-0.4,3-0.8l0.1-0.1
	h0.1l0.3-0.3l2.9-3l4.9-5.9l-12.5-1.6l-4,3c-2.9,2.2-5.6,4.5-8.3,6.9l-1.5,1.4c-0.8,0.8-0.7,1.2,0.3,1.2h12.2c1.5,0,2.8-0.3,3.9-0.7
	l0.4-0.1l0.3-0.1l0.8-0.5c-0.8,0.4-1.9,0.7-3,0.8h-13.9l1.9-1.8c2.6-2.3,5.4-4.7,8.3-6.9L143.5,246.2L143.5,246.2z"
          />
          <path
            fill="none"
            d="M140.5,248.4c-2.9,2.2-5.5,4.5-8.3,6.9l-1.9,1.8h12.2l1.8-0.3c1.2-0.4,2.2-0.8,2.9-1.6l0.3-0.3l6.2-7.4
	l-10.5-1.4L140.5,248.4L140.5,248.4z M143.5,247.2l8.5,1.2l-1.2,1.6l-3.6,4.1l-0.1,0.3l-0.6,0.6l-0.3,0.3c-1.2,0.8-2.8,1.2-4.5,1.2
	h-9.2l1.5-1.5c2.3-2.1,4.8-4.1,7.4-6.2l0.1-0.1L143.5,247.2L143.5,247.2z"
          />
          <path
            fill="none"
            d="M141.4,248.7c-2.6,2.1-5.1,4.1-7.6,6.2l-1.5,1.5h9.2c1.8,0,3.3-0.4,4.5-1.2l0.3-0.3l0.6-0.6
	c-0.6,0.6-1.7,0.7-3.4,0.6h-8.5c2.3-2.1,4.8-4.1,7.3-6.2l6.9,1.1l1.5,0.1l1.2-1.7l-8.5-1.2L141.4,248.7L141.4,248.7z"
          />
          <path
            fill="none"
            d="M142.4,248.8c-2.5,1.9-5,4-7.3,6.2h7.4c1.6,0,2.9-0.7,3.9-1.8l2.9-3.3L142.4,248.8z"
          />
          <path
            fill="none"
            d="M143.5,255c1.6,0.1,2.9,0,3.4-0.6l0.1-0.3l3.6-4.1l-1.5-0.1l-2.9,3.3c-1,1.1-2.2,1.7-3.9,1.8L143.5,255
	L143.5,255z M127.8,257.9c-0.1,0.3,0.3,0.4,1.2,0.5l12.8-0.1c1-0.1,1.8-0.3,2.6-0.5l1.5-0.7h-0.1c-1.1,0.4-2.5,0.7-3.9,0.7h-12.2
	c-1,0-1.1-0.4-0.3-1.2C129.4,256.5,127.8,257.9,127.8,257.9z"
          />
          <path
            fill="none"
            d="M146.2,256.9l-0.4,0.1h0.1 M142.5,243.6l0.3-0.1L142.5,243.6c-1.4-0.3-2.6-0.1-3.7,0.6l-1.1,0.7l-0.1,0.1
	l-2.9,2.1c-4.7,3.4-9.1,7.2-13.2,11c-0.8,1.1-0.6,1.8,0.7,2.1l0.6,0.1h2.8l-0.7-0.4c-0.3-0.3-0.4-0.5-0.3-1l0.3-0.5v-0.1l4.4-4.1
	c2.6-2.3,5.4-4.7,8.3-6.9l3.7-2.8C141.4,244,142,243.7,142.5,243.6L142.5,243.6z"
          />
          <path
            fill="none"
            d="M138.5,244.3l-0.7,0.6l1-0.7 M125.5,260.1v0.1h0.1 M150.2,254.9l-2.8,2.2h0.6"
          />
          <path
            fill="none"
            d="M134.3,244.3c-7.4,5.4-14,11.1-20.1,17.5v9.4c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-1,3.2l1.4-0.4
	l0.8-2.8c0.4-1.2,0.7-2.5,0.8-3.7c0.1-1.4,0.3-2.8,0.3-4.1v-8.9l1.9-2.1c5.2-5.2,10.7-10.2,16.8-14.9l5.1-3.7l-1.1-0.1L134.3,244.3
	L134.3,244.3z M192.7,214.3l2.1,3.3c10.7-2.9,21.5-5.5,32.6-7.7l3.7-0.7l6.7-1.2l3.3-5.5c-14.2,2.5-28.1,5.5-41.5,9.1l-3.2,0.8
	C194.7,212.8,193.4,213.5,192.7,214.3L192.7,214.3z"
          />
          <path
            fill="none"
            d="M193,221.3c3.9,6.2,8,11.6,12.2,16.4c1.2,1.4,2.5,2.5,3.9,3.6l2.3,1.6c1.5,1,3.2,1.6,4.8,2.1l1.6-2.8
	c-1.8-0.4-3.4-1.1-4.8-1.9c-0.8-0.4-1.6-1-2.3-1.6l-2.1-2.2c-3.2-3.4-6.9-8.5-11.1-15l-2.6-4l-2.1-3.3
	C191.1,215.9,191.2,218.3,193,221.3L193,221.3z"
          />
          <path
            fill="none"
            d="M197.3,221.4c4.3,6.5,8,11.6,11.1,15l2.1,2.2c0.7,0.7,1.5,1.2,2.3,1.6l18.3-31.2l-3.7,0.7
	c-11,2.2-21.9,4.8-32.6,7.7L197.3,221.4L197.3,221.4z M201.5,220.3c7-1.8,14-3.4,21.2-5c0.8,0,1.1,0.4,0.8,1.1L213,234.8
	c-0.7,0.7-1.4,0.8-1.9,0.1c-3.6-3.7-7-7.8-10.2-12.7C200.3,221.3,200.6,220.6,201.5,220.3L201.5,220.3z"
          />
          <path
            fill="none"
            d="M200.7,222.3c3.2,4.7,6.6,8.9,10.2,12.7c0.7,0.6,1.4,0.6,1.9-0.1l10.6-18.3c0.4-0.8,0.1-1.1-0.8-1.1
	c-7.2,1.5-14.2,3.2-21.2,4.9C200.6,220.6,200.3,221.3,200.7,222.3L200.7,222.3z M130.7,262.1v8.7l0.8-0.4l3.6-1.6l5.5-3.4l3.4-3.2"
          />
          <path
            fill="none"
            d="M130.7,270.8v-8.5h-14.2v8.9c0,1.4-0.1,2.8-0.3,4l0.8-0.1c4-0.8,7.7-1.9,11.1-3.2L130.7,270.8L130.7,270.8z
	 M151.7,259.4h0.1l4.1,0.1l1-1.1l-4-0.4l-2.8-0.1"
          />
          <path fill="none" d="M154.8,261.3l1.2-1.8l-4.1-0.1l-1.2,1.6" />
          <path
            fill="none"
            d="M148.7,262.1l1.2,0.1l4.1,0.3l0.7-1.2l-4.1-0.3l-3.4-0.3 M153,254.9l1.5,1.5l4,0.1l1-1.2l-3.9-0.3"
          />
          <path
            fill="none"
            d="M154.5,256.4L153,258l4,0.4l1.5-1.9 M156.5,267.4c-4.3,1.8-8.7,3.4-13.2,5.1l3.2,13.5
	c5.2-6.1,9.6-12.8,13.1-20.2C158.7,266.4,157.6,267,156.5,267.4L156.5,267.4z M149.5,263.1c-1.2,2.6-4.3,4.8-9.1,6.7
	c-6.6,3.2-14.6,5.9-23.8,8.4v0.1c-0.1,1-0.4,1.8-0.8,2.8l13.1-3.9c4-1.2,7.3-2.5,10-3.4c5.5-2.1,8.8-3.6,10.2-4.4
	c1.8-1,3.2-2.8,4.3-5.4l0.7-1.4l-4.1-0.3L149.5,263.1L149.5,263.1z"
          />
          <path
            fill="none"
            d="M128.9,277.1l-13.1,3.9l-0.8,0.3l-1.2,0.3h-0.1l-1.4,0.4c-1.9,5.6-4,10.9-6.3,15.7c-1.9,3.6-4,6.6-6.2,9.4
	l-1.8,2.1c-2.3,2.8-5.5,4.7-9.5,5.5l-6.9,5.8c8.9-1.5,17.5-4.1,25.6-7.4c16.4-6.6,29.6-15.7,39.6-27.1l-3.2-13.5
	C138.7,274.1,133.9,275.8,128.9,277.1L128.9,277.1z M103,264.9l0.1,2.9l0.1,4h3.6l0.3-6.1c0-1.2,0.1-2.3,0.6-3.4
	c0.3-1,0.7-1.8,1.1-2.6l6.1-10c-2.3,1.5-4.8,2.9-7.3,4.3l-3.3,5.5C103.2,261,102.8,263,103,264.9L103,264.9z M102.3,275.8h1.1
	l-0.1-4l-0.3-4h-1"
          />
          <path
            fill="none"
            d="M116.7,278.2l-0.4-3c-0.1,1.1-0.3,2.2-0.6,3.2l-0.8,2.9l0.8-0.3C116.1,280.2,116.4,279.2,116.7,278.2
	L116.7,278.2z M106.5,277.8l0.4-6.1h-3.6l0.1,4l0.1,2.1 M80.3,343.9l0.1-1.9l-0.3,1.8"
          />
          <path
            fill="none"
            d="M79.5,346.2l-1,2.6c6.6,1,12.5,5.6,17.9,14.2c0.7,1.2,1.5,2.3,2.5,3.4l0.4,0.1c-0.3-0.5-0.6-1.1-0.7-1.8
	c-3.4-11.6-9.5-18.6-18.3-21l-0.1-0.1C80.1,344.7,79.8,345.5,79.5,346.2L79.5,346.2z"
          />
          <path
            fill="none"
            d="M80.3,343.9c8.8,2.5,14.9,9.5,18.3,21c0.1,0.5,0.4,1.2,0.7,1.8l39.5,6.1v-0.1c1.8-15.1,8.1-28.2,18.7-39.3
	c3.3-3.4,6.9-6.5,10.6-9.1l-8.9-14.9c-3.6,2.2-6.9,4.8-10.2,7.7l-3.9,3.7c-5.8,6.2-12.7,9.8-20.4,10.9c-4.3,0.4-9.2,0.3-14.9-0.3
	c-6.1-0.8-11.7-1.8-17.1-3.3l20.4-1.9c13.3-1.5,22.6-5.2,27.8-11.3c2.1-2.5,4.4-5,7-7.3c2.2-2.1,4.5-3.9,6.9-5.6l-8.5-15.8
	c-9.9,11.4-23.1,20.4-39.6,27.1c-8.1,3.4-16.6,5.9-25.6,7.4l-1,21.5L80.3,343.9L80.3,343.9z M102.1,340.4l3,0.4l25,2.9
	c1.1,0.1,1.5,0.5,1.2,1.6c-0.8,5.5-3.7,8.5-8.7,9.1H116c-8.3,0-13.8-3.7-16.4-11.3C98.9,341.1,99.7,340.1,102.1,340.4L102.1,340.4z"
          />
          <path
            fill="none"
            d="M105.1,340.8l-3-0.4c-2.2-0.3-3.2,0.5-2.8,2.8c2.6,7.4,8.1,11.1,16.4,11.3h3.7c-7.8-0.3-12.9-4-15-11.3
	C104.3,342.1,104.4,341.2,105.1,340.8L105.1,340.8z"
          />
          <path
            fill="none"
            d="M104.5,343.2c2.1,7.3,7,11,15,11.3h3c5.1-0.5,8-3.6,8.7-9.1c0.3-1-0.1-1.5-1.2-1.6l-25-2.9
	C104.4,341.2,104.3,342.1,104.5,343.2L104.5,343.2z M92.9,327.9c5.2,1.4,11,2.5,16.9,3.3c5.8,0.5,10.7,0.7,14.9,0.3
	c7.7-1.1,14.4-4.7,20.2-10.9l3.9-3.7c3.2-2.9,6.6-5.4,10.2-7.7l-4-7.4c-2.3,1.6-4.7,3.6-6.9,5.6c-2.6,2.3-5,4.8-7,7.3
	c-5.2,5.9-14.4,9.8-27.8,11.3L92.9,327.9L92.9,327.9z M96.4,363.1c-5.4-8.5-11.3-13.2-17.9-14.2l1,13.3c0.3,1.9,0.8,3.3,1.8,4.4
	l7.7,10l10.2,1.1c3.7,0.3,7.2,0.7,10.6,1.2l3.4,0.7l-12.7-11.3l-1.5-1.6C97.9,365.5,97.1,364.4,96.4,363.1L96.4,363.1z"
          />
          <path fill="none" d="M99.2,366.7l-0.3-0.1l1.5,1.8" />
          <path
            fill="none"
            d="M100.4,368.3l12.7,11.3l25,4.8l0.1-3.6c0-2.8,0.3-5.5,0.6-8.1l-39.5-6.1L100.4,368.3L100.4,368.3z M159,309.2
	c12.5-8,27-12,43.2-12c10.5,0,20.2,1.6,29.2,5.1l-7.7-11.1c-7.3-2.2-15.1-3.3-23.5-3.3c-17.2,0-32.2,4.7-45.1,14L159,309.2
	L159,309.2z M168.1,324.1c-3.7,2.6-7.2,5.6-10.6,9.1c-10.7,11.1-16.9,24.2-18.7,39.3v0.1l5.6,0.8l0.3-2.6
	c1.6-13.1,7.3-24.5,17.1-34.3c3-3.2,6.3-5.8,9.8-8.1L168.1,324.1L168.1,324.1z"
          />
          <path
            fill="none"
            d="M171.4,328.7c9.2-6.1,19.7-9.1,31.4-9.1c16.1,0,29.7,5.6,41.1,17.1s17.5,25.4,18.3,42.1l0.4,8.1l6.2,0.1
	l-0.3-5.5c-0.8-19.1-7.6-35.2-20.1-48.4c-12.5-13.1-27.6-19.7-45.4-19.7c-13.1,0-24.6,3.6-34.9,10.6L171.4,328.7L171.4,328.7z"
          />
          <path
            fill="none"
            d="M166.7,346.2c-9.9,9.9-15,22-15,36.2v2.6c0.4,11,4.1,20.8,11,29.2c1.2,1.5,2.5,2.9,4,4.4c9.9,9.9,22,15,36.2,15
	c13.2,0,24.5-4.4,34.1-13.1l2.1-1.9c8.8-8.9,13.8-19.4,14.7-31.5c0.1-1.5,0.3-3,0.3-4.5c0-12.7-4-23.7-12.1-33
	c-0.8-1.1-1.8-2.1-2.8-3c-9.9-9.9-22-15-36-15C188.7,331.2,176.8,336.2,166.7,346.2L166.7,346.2z M202.9,344.7
	c3.9,0,7.4,0.5,10.7,1.5c2.6,0.8,5.2,1.8,7.6,3.3c2.9,1.6,5.6,3.7,8.1,6.2l1.6,1.8c2.5,2.8,4.4,5.8,5.9,8.9c2.3,4.8,3.4,10.2,3.4,16
	c0,5.8-1.1,11-3.4,16c-1.8,3.9-4.4,7.4-7.6,10.6l-3.4,3.2c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8
	c-1.2-1-2.3-2.1-3.4-3.2c-3-3-5.5-6.3-7.2-9.8c-2.5-5.1-3.9-10.6-3.9-16.8c0-6.1,1.2-11.7,3.9-16.8c1.5-2.9,3.3-5.6,5.6-8.1l1.6-1.8
	h0.1c4.5-4.5,9.8-7.7,15.7-9.5C195.5,345.2,199.1,344.7,202.9,344.7L202.9,344.7z"
          />
          <path
            fill="none"
            d="M192,346.2c-5.8,1.6-11,4.8-15.7,9.5h-0.1l-1.7,1.8c-2.3,2.5-4.1,5.2-5.6,8.1c-2.5,5.1-3.9,10.6-3.9,16.8
	c0,6.1,1.2,11.7,3.9,16.8c1.8,3.6,4.1,6.7,7.2,9.8c1.1,1.1,2.2,2.2,3.4,3.2c6.6,5.2,14.3,7.8,23.1,7.8s16.5-2.6,23.1-7.8l3.4-3.2
	c3.3-3.3,5.8-6.7,7.6-10.6c2.3-4.8,3.4-10.2,3.4-16c0-5.8-1.1-11.1-3.4-16c-1.5-3.2-3.4-6.2-5.9-8.9l-1.6-1.8
	c-2.5-2.5-5.2-4.7-8.1-6.2c-2.5-1.4-5-2.5-7.6-3.3c-3.4-1-7-1.5-10.7-1.5C199.1,344.7,195.5,345.2,192,346.2L192,346.2z
	 M202.9,345.8l5.5,0.4l3.4,0.7h0.1l2.2,0.7c1.8,0.5,3.6,1.2,5.4,2.2c0.6,0.3,1.2,0.6,1.8,1h0.1c2.6,1.5,5,3.4,7.3,5.8l0.8,1
	c1.8,1.9,3.4,4,4.7,6.2l1.2,2.2c0.6,1.1,1.1,2.3,1.5,3.4l0.7,1.8l0.6,2.3c0.7,2.9,1,5.8,1,8.9c0,3.2-0.4,6.2-1.1,9.1l-0.6,2.2
	l-0.6,1.6c-0.4,1.2-1.1,2.5-1.7,3.7l-1,1.8c-1.5,2.6-3.4,5-5.6,7.3c-1.5,1.5-3,2.8-4.5,4l-4.5,2.9c-1.8,0.8-3.4,1.6-5.4,2.2
	l-2.3,0.7c-2.9,0.7-5.8,1-8.8,1c-3.2,0-6.2-0.4-9.1-1.1l-2.2-0.5c-1.8-0.6-3.6-1.2-5.2-2.2l-1.9-1.1l-2.6-1.8
	c-1.6-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.5-5.6-7l-1.1-2.1l-1.2-2.6l-1-2.8l-0.7-2.5c-0.7-2.9-1.1-5.8-1.1-8.9s0.4-6.2,1.1-9.1
	l0.7-2.2l1-2.8l1.2-2.5l1.1-2.1c1.4-2.2,2.9-4.3,4.8-6.3l0.8-1c2.2-2.2,4.5-4.1,7-5.5c0.7-0.4,1.4-0.8,2.1-1.1
	c1.8-1,3.4-1.6,5.4-2.2l2.3-0.7l3.4-0.7L202.9,345.8L202.9,345.8z"
          />
          <path
            fill="none"
            d="M197.3,346.2l-3.4,0.7l-2.3,0.7l0.6,1.8l2.8,9.8c-1,0.3-1.8,0.7-2.6,1.1l-5.1-8.8l-1-1.5
	c-0.7,0.3-1.4,0.7-2.1,1.1c-2.5,1.5-4.8,3.4-7,5.5l-0.8,1c-1.9,2.1-3.6,4.1-4.8,6.3l2.3-0.4c0.8-1.1,1.6-2.3,2.6-3.4l2.3-2.5
	c1.6-1.5,3.3-2.9,4.9-4.1l0.4-2.3l1,1.5c1.1,1.6,2.2,3.3,3.3,5l6.2,10c1.6,2.9,3.3,5.8,5,8.7c0.6-0.3,1.1-0.5,1.8-0.7l-0.6-0.7
	l-0.1-0.4l-3.7-13.6l-3-13.8l2.1,1.4c2.2-0.4,4.5-0.7,6.9-0.7c2.5,0,4.8,0.3,7,0.7l1.9-1.5l-3.4-0.7l-5.5-0.4L197.3,346.2
	L197.3,346.2z"
          />
          <path
            fill="none"
            d="M183.8,353.5l5.5,8.4l1.1,1.9l4.5,7.4l4.5,5l-4.9-8.7l-6.2-10l-3.3-5l-1-1.5L183.8,353.5L183.8,353.5z"
          />
          <path
            fill="none"
            d="M183.8,353.4c-1.8,1.1-3.4,2.5-5,4.1l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4h0.1l8.5,5.4l3.2-3.9
	c1.2-1.1,2.5-2.2,3.7-3.2L183.8,353.4L183.8,353.4z"
          />
          <path
            fill="none"
            d="M182.4,368.8l-8.5-5.4h-0.1l-2.3,0.4l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6l-5.1-4.5l-0.7-0.3l-2.5-1.5l-5.8-3.4
	L182.4,368.8L182.4,368.8z"
          />
          <path
            fill="none"
            d="M182.8,369l1.4-1.7c0.8-0.8,1.6-1.8,2.8-2.5l0.8,1c0.8-0.8,1.8-1.5,2.8-2.2l-1.1-1.9c-1.2,1-2.6,1.9-3.7,3.2
	l-3.2,3.9L182.8,369L182.8,369z M171.8,366.7l8.8,5l-1.1,2.6l-9.8-2.6l-1.8-0.5l-0.7,2.2l1.6,0.3h0.1c3.4,0.7,6.7,1.4,9.9,2.2
	l2.1,0.5c4.7,1.1,9.2,2.3,13.6,3.7l0.3,0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.1-0.5,0.4-1.1,0.7-1.6c-4.5-2.5-8.9-5-13.3-7.6l-12-7.6
	L170,366L171.8,366.7L171.8,366.7z"
          />
          <path
            fill="none"
            d="M170.3,365.7l-1.2,2.5l-1,2.8l1.8,0.5c0.6-1.8,1.2-3.4,2.1-5L170.3,365.7L170.3,365.7z M192,349.4l-0.6-1.8
	c-1.8,0.6-3.6,1.4-5.4,2.2l1,1.5C188.7,350.6,190.4,349.9,192,349.4L192,349.4z"
          />
          <path
            fill="none"
            d="M187.1,351.4l5.1,8.8c0.8-0.4,1.8-0.8,2.6-1.1l-2.8-9.8C190.4,349.9,188.7,350.6,187.1,351.4z M196,348.4
	c0.6,3.2,1.1,6.2,1.8,9.1l0.1,0.8l4.8-0.4c1.8,0,3.4,0.1,5.1,0.4l0.1-0.8c0.7-2.9,1.2-5.9,1.6-9.1h0.1c-2.2-0.4-4.5-0.7-7-0.7
	C200.4,347.7,198.1,347.9,196,348.4L196,348.4z"
          />
          <path
            fill="none"
            d="M198,358.3l0.6,2.1l4.4-0.4l4.5,0.4l0.6-2.1c-1.6-0.3-3.3-0.4-5.1-0.4L198,358.3L198,358.3z"
          />
          <path
            fill="none"
            d="M196,348.4L194,347l3,13.8l3.7,13.6V374c0-0.5,0.1-1.1,0.6-1.5v-3.3l-0.1-0.6l-0.4-1.1c-0.8-2.3-1.5-4.7-2.1-7
	l-0.6-2.1l-0.1-0.8C197.1,354.5,196.4,351.4,196,348.4L196,348.4z"
          />
          <path
            fill="none"
            d="M198.5,360.4c0.6,2.3,1.2,4.8,2.1,7l0.4,1.1l0.1,0.5h3.6l0.1-0.5l0.4-1.1c0.8-2.3,1.5-4.7,2.2-7l-4.5-0.4
	L198.5,360.4L198.5,360.4z M187.8,365.9l1.1,1.5c0.8,1.4,1.5,3.2,2.2,5.1v1.5l0.7,0.3l3.2-3.2l-4.5-7.4
	C189.4,364.4,188.6,365,187.8,365.9L187.8,365.9z"
          />
          <path
            fill="none"
            d="M186.9,364.9c-1.1,0.8-1.9,1.6-2.8,2.5l-1.4,1.7l5.8,3.4h2.5c-0.7-2.1-1.5-3.7-2.2-5.1l-1.1-1.5L186.9,364.9
	L186.9,364.9z"
          />
          <path
            fill="none"
            d="M188.6,372.5l2.5,1.5v-1.5 M193,380.5c0.4-0.3,0.8-0.5,1.4-0.5h0.6c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.6
	c-3.2-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3l1.5,1.9v0.1h0.1c3.3,0.4,6.6,1.1,9.8,1.8l2.1,0.5l3.3,1l3.6,1.2l1.8,0.5L193,380.5
	L193,380.5z M189.7,380.5v3.6h3.2l-0.1-0.1c-0.4-0.4-0.6-1-0.6-1.5c0-0.7,0.1-1.2,0.6-1.6l0.3-0.1L189.7,380.5L189.7,380.5z"
          />
          <path
            fill="none"
            d="M192.7,380.6c-0.4,0.4-0.6,1-0.6,1.6c0,0.6,0.1,1.1,0.6,1.5l0.1,0.1c0.4,0.4,1,0.5,1.5,0.5h0.4l0.4-0.1
	c0.3-0.1,0.6-0.3,0.7-0.4v-0.1c0.4-0.4,0.7-1,0.7-1.5c0-0.7-0.3-1.2-0.7-1.6c-0.3-0.3-0.6-0.4-0.8-0.5l-0.3-0.1h-0.6
	c-0.6,0-1.1,0.1-1.4,0.5L192.7,380.6L192.7,380.6z M193.1,382.2c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,0.8-0.4s0.7,0.1,1,0.4
	c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-0.8-0.4C193.3,382.9,193.1,382.7,193.1,382.2
	L193.1,382.2z"
          />
          <path
            fill="none"
            d="M193.6,383.1c0.3,0.3,0.6,0.4,0.8,0.4s0.7-0.1,1-0.4s0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.6-0.4-1-0.4s-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1S193.3,382.9,193.6,383.1L193.6,383.1z M184.3,378.7
	c-0.3,0.1-0.7,0.4-1,0.8c-0.7,0.8-1.1,1.8-1.1,2.9s0.3,2.2,1.1,2.9l0.6,0.5l4-1.2v-4.7L184.3,378.7L184.3,378.7z"
          />
          <path
            fill="none"
            d="M187.9,379.9v4.7l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1v-3.6L187.9,379.9L187.9,379.9z M201.3,372.3
	c0.4-0.4,1-0.7,1.6-0.7c0.6,0,1.1,0.3,1.6,0.7l0.1,0.1l0.1-3.3h-3.6L201.3,372.3L201.3,372.3z"
          />
          <path
            fill="none"
            d="M201.1,372.3c-0.3,0.4-0.6,1-0.6,1.5v0.4l0.1,0.4l0.6,0.7c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.1-0.3,1.6-0.7l0.4-0.7
	l0.1-0.3v-0.5c0-0.5-0.1-1-0.6-1.4l-0.1-0.1c-0.4-0.4-1-0.7-1.6-0.7C202.1,371.7,201.5,371.9,201.1,372.3L201.1,372.3z M201.9,373
	c0.3-0.3,0.6-0.4,0.8-0.4c0.3,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4
	c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1C201.7,373.4,201.7,373.2,201.9,373L201.9,373z"
          />
          <path
            fill="none"
            d="M201.7,373.9c0,0.3,0.1,0.7,0.4,1s0.6,0.4,0.8,0.4c0.3,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-0.8,0.4C201.7,373.2,201.7,373.4,201.7,373.9L201.7,373.9z
	 M199.5,376.1c-0.6,0.3-1.1,0.7-1.6,1.2c-0.6,0.5-0.8,1-1.2,1.5c-0.3,0.5-0.6,1.1-0.7,1.6c0.4,0.4,0.7,1,0.7,1.7
	c0,0.5-0.3,1.1-0.7,1.5v0.1c0.1,0.5,0.3,1.1,0.6,1.8l1.2,1.7l1.5,1.2l1.6,0.7V389c0.4-0.4,1-0.7,1.7-0.7s1.2,0.3,1.6,0.7l0.1,0.1
	c0.6-0.1,1.1-0.4,1.6-0.7c0.6-0.3,1.1-0.8,1.6-1.2c0.4-0.4,0.8-1,1.1-1.5c0.3-0.5,0.4-1.1,0.7-1.6h-0.1c-0.4-0.4-0.6-1-0.6-1.7
	c0-0.7,0.1-1.2,0.6-1.6l0.1-0.1c-0.1-0.5-0.4-1.2-0.7-1.6c-0.3-0.5-0.7-1.1-1.2-1.6c-0.6-0.4-1-0.8-1.5-1.1
	c-0.6-0.3-1.1-0.6-1.6-0.7c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.3-1.7-0.7C200.7,375.6,200,375.8,199.5,376.1L199.5,376.1z"
          />
          <path
            fill="none"
            d="M198,377.3c0.6-0.5,1.1-0.8,1.6-1.2l-4.5-5l-3.2,3.2l5.1,4.5C197,378.4,197.4,377.8,198,377.3L198,377.3z
	 M191.6,390.2l3.3,3.2l4.5-5l-1.5-1.2l-1.4-1.7v0.1"
          />
          <path
            fill="none"
            d="M196.6,385.5c-0.3-0.5-0.6-1.1-0.6-1.8c-0.3,0.1-0.4,0.3-0.7,0.4l-0.4,0.1l-13.6,3.7l-12,2.6h-0.1l-1.5,0.3
	l1.4-1.9c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2l-1.5-2.1c-0.7,2.9-1.1,5.9-1.1,9.1s0.3,6.1,1.1,8.9l0.7,2.5l1.8-0.5
	l9.8-2.8l1.1,2.6l-8.9,5.4l-1.5,1l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4l2.6,1.8l-0.3-2.3c-1.9-1.2-3.9-2.9-5.6-4.7
	c-1.6-1.6-3.2-3.6-4.5-5.5l-2.2-0.4c0.4-0.3,1-0.7,1.5-1c2.8-1.9,5.6-3.7,8.5-5.5l1.8-1.1C187.6,390.6,192,388.2,196.6,385.5
	L196.6,385.5z"
          />
          <path
            fill="none"
            d="M194.4,384.4c-0.6,0-1.1-0.1-1.5-0.5h-3.2l-0.4,0.1c0,0-0.1,0-0.1,0.1l-0.4,0.1l-0.8,0.3l-4,1.2l-3,0.8
	l-2.1,0.5c-3.2,0.8-6.5,1.4-9.8,1.8h-0.1l-0.1,0.1l-1.4,1.9l1.5-0.3h0.1l12-2.6l13.6-3.7L194.4,384.4L194.4,384.4z M199.5,388.4
	c-2.5,4.5-5,8.9-7.6,13.3l-7.6,12l1.9,1.1l0.8-1.5l5.1-8.8l2.6,1.1l-2.6,9.8l-0.6,1.8l2.2,0.5l3-13.8c1.1-4.8,2.3-9.4,3.7-13.8
	l0.1-0.3l0.4-0.7L199.5,388.4L199.5,388.4z"
          />
          <path
            fill="none"
            d="M201.1,389.1l-0.6,0.8l-0.1,0.3v0.5c0,0.6,0.1,1.1,0.6,1.5l0.1,0.1c0.4,0.4,1,0.7,1.6,0.7
	c0.7,0,1.2-0.3,1.6-0.7l0.1-0.1c0.3-0.4,0.6-1,0.6-1.5v-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.4-0.4-0.7l-0.1-0.1
	c-0.4-0.4-1-0.7-1.6-0.7C202.1,388.4,201.5,388.7,201.1,389.1L201.1,389.1z M203.6,389.8c0.3,0.3,0.4,0.5,0.4,1
	c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1
	s0.6-0.4,0.8-0.4C203.2,389.4,203.5,389.5,203.6,389.8L203.6,389.8z"
          />
          <path
            fill="none"
            d="M202.8,389.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4
	c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1C203.5,389.5,203.2,389.4,202.8,389.4L202.8,389.4z M201,392.1
	v3.3h3.6v-3.2l-0.1,0.1c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.1-0.3-1.7-0.7L201,392.1L201,392.1z"
          />
          <path
            fill="none"
            d="M200.4,390.1c-1.4,4.4-2.6,8.9-3.7,13.8l-3,13.8l2.1-1.4l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.2-5.6l0.6-2.1
	c0.7-2.6,1.5-5.2,2.3-7.8l0.3-1v-3.3c-0.4-0.4-0.6-0.8-0.6-1.5L200.4,390.1L200.4,390.1z M194.9,393.4l-4.4,7.6l-1.1,1.8
	c-1.8,2.9-3.6,5.6-5.4,8.4v0.1l0.3,2.3l7.6-12c2.6-4.4,5.1-8.8,7.6-13.3L194.9,393.4L194.9,393.4z"
          />
          <path
            fill="none"
            d="M191.1,390.6v1.1c-1.1,2.9-2.2,5.2-3.4,6.9l2.9,2.3l4.4-7.6l-3.2-3.2L191.1,390.6L191.1,390.6z M191.6,390.2
	l4.8-4.5c-4.5,2.5-8.9,5-13.2,7.7l-1.8,1.1c-2.9,1.8-5.8,3.6-8.5,5.5c-0.6,0.3-1,0.5-1.5,1l2.2,0.4h0.1l8.4-5.5l0.7-0.4l6.1-3.7
	l1.9-1.1L191.6,390.2L191.6,390.2z"
          />
          <path fill="none" d="M189.1,391.7h1.9v-1.1" />
          <path
            fill="none"
            d="M189.1,391.7l-6.1,3.7c1.1,1.4,2.3,2.8,3.9,3.9l0.6-0.7c1.2-1.7,2.3-4,3.4-6.9L189.1,391.7L189.1,391.7z
	 M168.9,375.2c-0.4,2.3-0.7,4.7-0.7,7.2c0,2.3,0.3,4.7,0.7,6.9l0.1-0.1h0.1c3.3-0.4,6.5-1.1,9.8-1.8c-0.3-1.5-0.6-3.2-0.6-4.8
	c0-1.8,0.1-3.4,0.6-5.1c-3.2-0.8-6.5-1.4-9.8-1.8L168.9,375.2L168.9,375.2z"
          />
          <path
            fill="none"
            d="M178.8,377.2c-0.3,1.6-0.6,3.4-0.6,5.1s0.1,3.3,0.6,4.8l2.1-0.5c-0.3-1.4-0.4-2.8-0.4-4.3s0.1-3.2,0.4-4.5
	L178.8,377.2L178.8,377.2z M180.8,371.6l-8.8-5c-0.8,1.5-1.5,3.2-2.1,5l9.8,2.6L180.8,371.6L180.8,371.6z"
          />
          <path
            fill="none"
            d="M180.5,382.4c0,1.5,0.1,2.9,0.4,4.3l3-0.8l-0.6-0.5c-0.7-0.8-1.1-1.8-1.1-2.9c0-1.1,0.3-2.1,1.1-2.9
	c0.3-0.3,0.6-0.5,1-0.8l-3.3-1C180.6,379.2,180.5,380.7,180.5,382.4L180.5,382.4z M179.7,390.4l-9.8,2.8c0.6,1.6,1.2,3.3,2.1,5
	l8.8-5.1L179.7,390.4L179.7,390.4z M168.1,393.7l1,2.8l1.2,2.6l1.5-1c-0.8-1.5-1.5-3.2-2.1-5L168.1,393.7L168.1,393.7z M173.9,401.4
	c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.7,3.3,5.6,4.7v-0.1c1.8-2.8,3.6-5.6,5.4-8.4c-1.2-1-2.6-1.9-3.9-3.2c-1.1-1.2-2.2-2.5-3.2-3.7
	l-8.4,5.5L173.9,401.4L173.9,401.4z"
          />
          <path
            fill="none"
            d="M182.4,395.9c1,1.4,1.9,2.6,3.2,3.7c1.2,1.2,2.5,2.2,3.9,3.2l1.1-1.8l-2.9-2.3l-0.6,0.7
	c-1.5-1.1-2.9-2.5-3.9-3.9L182.4,395.9L182.4,395.9z M197.7,406.3c-0.4,1.8-0.8,3.7-1.2,5.6l-0.7,4.1l-0.1,0.1
	c2.3,0.4,4.7,0.7,7.2,0.7c2.3,0,4.7-0.1,6.9-0.6l-0.1-0.1l-0.7-4.1c-0.3-1.9-0.7-3.9-1.2-5.6l-4.8,0.4L197.7,406.3L197.7,406.3z"
          />
          <path
            fill="none"
            d="M207.7,406.3l-0.6-2.1l-4.3,0.4l-4.7-0.4l-0.6,2.1l5.1,0.4"
          />
          <path
            fill="none"
            d="M200.7,396.4c-1,2.6-1.8,5.2-2.3,7.8l4.5,0.4l4.3-0.4c-0.7-2.8-1.5-5.2-2.5-7.8l-0.3-1h-3.6L200.7,396.4
	L200.7,396.4z"
          />
          <path
            fill="none"
            d="M195.8,416.2l-2.1,1.4c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.3,8.8-1l2.3-0.7l-0.6-1.8l-2.8-9.8
	c0.8-0.3,1.8-0.7,2.5-1.1l5.1,8.8l1,1.5l4.5-2.9c1.6-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3l-2.3,0.3c-1.2,1.9-2.9,3.9-4.7,5.6
	c-1.6,1.8-3.6,3.2-5.5,4.5l-0.3,2.2l-7.6-11.7c-2.8-4.4-5.2-8.8-7.8-13.3c-0.6,0.3-1.1,0.5-1.6,0.7c0.1,0.1,0.3,0.4,0.4,0.7
	c0.1,0.1,0.1,0.3,0.1,0.4c1.4,4.4,2.6,8.9,3.7,13.8c0.1,0.7,0.4,1.4,0.4,2.1l2.5,11.6l-1.9-1.2c-2.2,0.4-4.5,0.5-6.9,0.5
	C200.4,416.9,198.1,416.6,195.8,416.2L195.8,416.2z M192,415.3c-1.7-0.5-3.3-1.2-5-1.9l-0.8,1.5c1.7,0.8,3.4,1.6,5.2,2.2L192,415.3
	L192,415.3z"
          />
          <path
            fill="none"
            d="M192,415.3l2.6-9.8l-2.6-1.1l-5.1,8.8C188.7,414.2,190.4,414.7,192,415.3L192,415.3z M208,358.3l-0.6,2.1
	c-0.7,2.3-1.4,4.8-2.2,7l-0.4,1.1l-0.1,0.5l-0.1,3.3c0.4,0.4,0.6,0.8,0.6,1.4v0.5c0.7-2.3,1.4-4.7,1.9-7l1.8-6.7l2.9-13.9l-1.9,1.5
	h-0.1c-0.4,3-1,6.1-1.6,9.1V358.3L208,358.3z"
          />
          <path
            fill="none"
            d="M211.8,346.9l-2.9,13.9l-1.8,6.7c-0.7,2.3-1.2,4.7-1.9,7l-0.1,0.3l-0.4,0.7c0.6,0.1,1.1,0.3,1.6,0.7
	c1.5-2.9,3.2-5.8,4.8-8.7l2.8-4.7l7.4-11.8v-0.1l0.4,2.3c1.8,1.2,3.6,2.6,5.2,4.3l0.4,0.4c1.6,1.7,3.2,3.4,4.5,5.4l2.3,0.3
	c-1.2-2.2-2.9-4.1-4.7-6.2l-0.8-1c-2.2-2.3-4.7-4.1-7.3-5.8h-0.1c-0.6-0.4-1.1-0.7-1.8-1l-1,1.6l-5,8.8c-0.8-0.4-1.8-0.8-2.6-1.1
	l2.6-9.8l0.6-1.8L211.8,346.9L211.8,346.9z"
          />
          <path
            fill="none"
            d="M221.2,351l-7.4,11.8l-2.8,4.7c-1.6,2.9-3.3,5.8-4.8,8.7l4.5-5l4.4-7.6l1.1-1.8l5.4-8.5v-0.1L221.2,351
	L221.2,351z M214.2,347.6l-0.6,1.8c1.6,0.5,3.3,1.2,5,2.1l1-1.6C217.8,348.8,216,348.1,214.2,347.6L214.2,347.6z"
          />
          <path
            fill="none"
            d="M221.8,353.4l-5.4,8.5c1.2,0.8,2.6,1.9,3.9,3.2l1.1,1.2c0.7,0.8,1.4,1.6,1.9,2.5c2.9-1.8,5.6-3.6,8.4-5.5h0.1
	c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4C225.2,355.8,223.5,354.5,221.8,353.4L221.8,353.4z"
          />
          <path
            fill="none"
            d="M221.2,366.1l-1.1-1.2c-1.2-1.2-2.5-2.2-3.9-3.2l-1.1,1.8c1.2,0.8,2.3,1.8,3.4,2.9l0.8,1
	c0.7,0.8,1.4,1.5,1.9,2.5l1.8-1.2C222.6,367.8,221.9,367,221.2,366.1L221.2,366.1z"
          />
          <path
            fill="none"
            d="M221.3,369.9l-7.4,4.5l-5,4.5c4.5-2.5,8.9-5.1,13.2-7.7l11.8-7.7l-2.3-0.3h-0.1c-2.8,1.8-5.5,3.7-8.4,5.5
	L221.3,369.9L221.3,369.9z"
          />
          <path
            fill="none"
            d="M219.6,367.4l-0.8-1c-1.1-1.1-2.2-2.1-3.4-2.9l-4.4,7.6l3.2,3.2l7.4-4.5C220.8,369,220.2,368.2,219.6,367.4
	L219.6,367.4z M213.6,360.2l5-8.8c-1.7-0.8-3.2-1.5-5-2.1l-2.6,9.8C211.8,359.4,212.8,359.8,213.6,360.2L213.6,360.2z M235.4,365.7
	l-1.5,0.8c0.8,1.5,1.5,3.2,2.1,5l1.6-0.5l-0.7-1.8C236.5,368.1,235.9,366.8,235.4,365.7L235.4,365.7z"
          />
          <path
            fill="none"
            d="M234.1,363.5l-11.8,7.7c-4.4,2.6-8.8,5.2-13.2,7.7c0.3,0.5,0.6,1.1,0.7,1.6c0.1-0.1,0.4-0.3,0.7-0.4l0.4-0.1
	c4.4-1.4,8.9-2.6,13.8-3.7l13.8-3l-1.4,2.1l0.1,1c0.3,1.9,0.4,3.9,0.4,5.9c0,2.1-0.1,4-0.4,5.9l-0.1,1.1l1.4,2.1
	c0.7-2.9,1.1-5.9,1.1-9.1s-0.4-6.1-1-8.9L238,371l-1.6,0.5l-9.9,2.8c-0.3-1-0.7-1.8-1.1-2.6l8.8-5.1l1.5-0.8L234.1,363.5
	L234.1,363.5z"
          />
          <path
            fill="none"
            d="M226.8,377.4l-2.1,0.5c0.3,1.4,0.4,2.9,0.4,4.4c0,1.5-0.1,3-0.4,4.5l2.1,0.5c0.3-1.6,0.4-3.3,0.4-5.1
	C227.3,380.6,227.1,378.9,226.8,377.4L226.8,377.4z"
          />
          <path
            fill="none"
            d="M227.3,382.4c0,1.8-0.1,3.4-0.4,5.1c3.2,0.8,6.5,1.4,9.9,1.8l0.1,0.1l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9
	c0-2.1-0.1-4-0.4-5.9l-0.1-1l-0.1,0.1c-3.3,0.4-6.6,1.1-9.8,1.8C227.1,378.9,227.3,380.6,227.3,382.4L227.3,382.4z"
          />
          <path
            fill="none"
            d="M226.8,377.4c3.2-0.8,6.5-1.4,9.8-1.8l0.1-0.1l1.4-2.1l-13.8,3c-4.7,1.1-9.2,2.3-13.8,3.7h0.4
	c0.6,0,1.1,0.1,1.5,0.5h3.2l0.6-0.1l0.4-0.1c2.6-1,5.2-1.8,7.8-2.5L226.8,377.4L226.8,377.4z"
          />
          <path
            fill="none"
            d="M224.8,377.8c-2.6,0.7-5.2,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1v3.6l0.7,0.1l0.4,0.1c2.6,1,5.2,1.8,7.8,2.5
	c0.3-1.5,0.4-3,0.4-4.5C225.2,380.9,225.1,379.4,224.8,377.8L224.8,377.8z M226,374.4l9.9-2.8c-0.6-1.8-1.2-3.4-2.1-5l-8.8,5.1
	C225.5,372.6,225.7,373.4,226,374.4L226,374.4z M238.1,391.5l-13.8-3c-4.7-1.1-9.4-2.5-13.8-3.7l-0.3-0.1l-0.7-0.5
	c-0.1,0.5-0.4,1.1-0.7,1.6c4.5,2.5,8.9,5,13.3,7.6l1.8,1.1c2.9,1.8,5.8,3.6,8.7,5.4l1.5,1l1-1.8l-1.5-0.8l-8.9-5
	c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.6l1.8,0.5L238.1,391.5L238.1,391.5z"
          />
          <path
            fill="none"
            d="M237.6,393.7l-1.8-0.5c-0.6,1.6-1.2,3.3-2.1,5l1.5,0.8c0.6-1.2,1.2-2.5,1.6-3.7L237.6,393.7L237.6,393.7z
	 M236.7,389.4c-3.4-0.5-6.7-1.2-9.9-1.9l-2.1-0.5c-2.6-0.7-5.2-1.5-7.8-2.5l-0.4-0.1l-0.7-0.1h-3.3c-0.4,0.4-0.8,0.6-1.4,0.6h-0.6
	c4.4,1.4,9.1,2.6,13.8,3.7l13.8,3L236.7,389.4L236.7,389.4z"
          />
          <path
            fill="none"
            d="M224.9,393.1l8.9,5c0.8-1.6,1.5-3.3,2.1-5l-9.8-2.6C225.6,391.3,225.3,392.3,224.9,393.1L224.9,393.1z
	 M210.7,371.1l-4.5,5c0.6,0.3,1.1,0.7,1.5,1.1c0.6,0.5,1,1.1,1.2,1.6l5-4.5L210.7,371.1L210.7,371.1z M210.7,380.2l-0.4,0.1
	c-0.3,0.1-0.4,0.1-0.7,0.4l-0.1,0.1c-0.4,0.4-0.6,1-0.6,1.6c0,0.5,0.1,1.1,0.6,1.6h0.1l0.7,0.5l0.3,0.1h0.6c0.6,0,1.1-0.1,1.4-0.5
	l0.1-0.1c0.4-0.4,0.7-1,0.7-1.6s-0.3-1.2-0.7-1.6l-0.1-0.1c-0.4-0.4-0.8-0.5-1.5-0.5L210.7,380.2L210.7,380.2z M212.4,382.4
	c0,0.3-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8
	c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.7,0.1,0.8,0.4C212.4,381.7,212.4,382,212.4,382.4z"
          />
          <path
            fill="none"
            d="M212.1,381.4c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1
	c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-1C212.4,382,212.4,381.7,212.1,381.4z M213.5,382.4
	c0,0.5-0.3,1.1-0.7,1.7l-0.1,0.1h3.3v-3.6h-3.2l0.1,0.1C213.2,381.1,213.5,381.7,213.5,382.4L213.5,382.4z M206.2,388.6l4.5,5
	l3.3-3.2l-4.9-4.5c-0.3,0.5-0.7,1.1-1.1,1.5C207.3,387.7,206.8,388.2,206.2,388.6L206.2,388.6z"
          />
          <path
            fill="none"
            d="M206.1,388.6c2.5,4.5,5.1,8.9,7.8,13.3l7.6,11.7l0.3-2.2l0.1-0.3l-5.5-8.4l-1.1-1.8l-4.5-7.6L206.1,388.6
	L206.1,388.6z M209,385.7l5,4.5c2.5,1.4,5.1,2.9,7.6,4.4l1.8,1.1c2.9,1.8,5.6,3.6,8.4,5.4h0.1l2.3-0.3l-1.5-1
	c-2.9-1.9-5.8-3.7-8.7-5.4l-1.8-1.1C218,390.6,213.5,388.2,209,385.7L209,385.7z"
          />
          <path
            fill="none"
            d="M210.7,393.4l4.5,7.6c1.2-0.8,2.3-1.8,3.4-2.9c1.1-1.1,2.1-2.2,2.9-3.4c-2.5-1.5-5.1-3-7.6-4.4L210.7,393.4z
	 M204.4,392.1v3.2l0.3,1c1,2.6,1.8,5.2,2.5,7.8l0.6,2.1c0.4,1.9,0.8,3.7,1.2,5.6l0.7,4.1l0.1,0.1l1.9,1.2l-2.5-11.6l-0.4-2.1
	c-1.1-4.7-2.3-9.4-3.7-13.8v0.4C205,391.3,204.8,391.7,204.4,392.1L204.4,392.1z M210.9,405.5l2.8,9.8c1.7-0.5,3.3-1.2,5-1.9
	l-5.1-8.8C212.5,404.9,211.7,405.2,210.9,405.5L210.9,405.5z M216.4,402.7l5.5,8.4l-0.1,0.3c1.9-1.2,3.7-2.8,5.5-4.5
	c1.8-1.8,3.3-3.7,4.7-5.6h-0.1c-2.8-1.8-5.6-3.6-8.4-5.4c-1,1.4-1.9,2.6-3.2,3.9C219,400.8,217.8,401.8,216.4,402.7L216.4,402.7z"
          />
          <path
            fill="none"
            d="M220.1,399.6c1.2-1.2,2.2-2.5,3.2-3.9l-1.8-1.1c-0.8,1.2-1.8,2.3-2.9,3.4c-1.1,1.1-2.2,1.9-3.4,2.9l1.1,1.8
	C217.8,401.8,219,400.8,220.1,399.6L220.1,399.6z M219.4,414.8l-1-1.5c-1.6,0.8-3.2,1.5-4.9,1.9l0.6,1.8
	C216,416.5,217.6,415.7,219.4,414.8L219.4,414.8z M202.9,331.2c14,0,26.1,5,36,15c1,1,1.9,2.1,2.8,3c8.1,9.4,12.1,20.4,12.1,33
	c0,1.5-0.1,3-0.3,4.5h8.8l-0.4-8.1c-0.8-16.6-6.9-30.7-18.3-42.1c-11.3-11.4-25-17.1-41.1-17.1c-11.7,0-22.1,3-31.4,9.1
	c-3.4,2.2-6.7,5-9.8,8.1c-9.6,9.8-15.3,21.2-17.1,34.3l-0.3,2.6l-0.1,4.7c0,2.2-0.1,4.3-0.3,6.2l8.1,0.5v-2.6c0-14.2,5-26.1,15-36.2
	C176.8,336.2,188.7,331.2,202.9,331.2L202.9,331.2z"
          />
          <path
            fill="none"
            d="M202.9,313.5c17.7,0,32.9,6.6,45.4,19.7c12.7,13.2,19.4,29.3,20.1,48.4l0.3,5.5l293.1,7.7l0.5-10.2
	c0.8-19.1,7.6-35.2,20.1-48.4c12.5-13.1,27.7-19.7,45.4-19.7c10.6,0,20.2,2.3,29,7l11.3-11.1c-12.1-7.7-25.9-11.6-41.3-11.6
	c-20.2,0-37.6,6.6-51.9,19.7c-4,3.6-8.8,7.8-14.3,12.7l1.7-7.6l2.1-9.5l-5.9-1c-1.2,17.3-2.8,34.3-4.5,50.9c4.3,0.5,5.9,2.3,5.2,5.1
	c-0.4,1.6-2.5,2.5-6.1,2.6l-0.3,2.3c-0.1,2.2-0.4,3.9-0.8,5.2c-0.8,2.2-2.8,3.2-6.1,3.2l-172-4.5l-74.1-1.9
	c-7.4-0.3-13.2-2.8-17.1-7.3c-17.5-20.8-34.5-43.6-51.3-68.4c-8.9-3.3-18.7-5.1-29.2-5.1c-16.2,0-30.7,4-43.2,12l8.9,14.9
	C178.3,317,190,313.5,202.9,313.5L202.9,313.5z M143.8,384.4c0.1-1.9,0.3-4,0.3-6.2l0.1-4.7l-5.6-0.8c-0.3,2.6-0.6,5.4-0.6,8.1
	l-0.1,3.6H143.8L143.8,384.4z M461.9,196.3l-0.3-1.2l-3.6,3.7l-0.5,0.6c0.1,0.3,0.3,0.6,0.7,0.8L461.9,196.3L461.9,196.3z"
          />
          <path
            fill="none"
            d="M458.1,200.2c0.5,0.6,1.2,1,2.3,1.4l4.3,1.5l4-4.3l-6.9-2.8L458.1,200.2L458.1,200.2z M522.2,240.3l3.9,1.9
	l4,2.2c8.8,4.7,17.5,9.6,26.3,15c9.1,5.5,17.6,11.4,25.7,17.7l0.1,0.1l8.9,0.3c-3.2-2.5-6.5-5-9.9-7.4c-8-5.9-16.2-11.4-24.8-16.8
	c-11.8-7-23.5-13.5-35.5-19.3L522.2,240.3L522.2,240.3z"
          />
          <path
            fill="none"
            d="M521.1,233.9c-49.2-24.1-99.2-37.4-149.7-40l-9.9-0.3l0.7,4.8l10.9,0.3c48.7,2.8,96.8,15.8,144.2,39.1
	c1.7,0.8,3.2,1.5,4.8,2.3L521.1,233.9L521.1,233.9z"
          />
          <path
            fill="none"
            d="M517.4,237.8l2.8,16.1h2.2l2.3,0.6l-2.5-14.2C520.5,239.5,518.9,238.6,517.4,237.8L517.4,237.8z"
          />
          <path
            fill="none"
            d="M520.1,254.3l-1.1,0.4c-2.2,1-4.1,2.2-5.9,3.9c-1.1,1-1.8,2.6-2.1,4.8l15.4,2.2h0.1c1.9,0.3,3.6,1.1,4.7,2.3
	c0.8,1,1.5,2.1,1.9,3.6l5.6,0.8l1.7,0.1l1.5-5.4c0.4-1.1,0.1-1.9-0.4-2.8c-2.5-2.9-5.5-5.2-8.8-7c-1.2-0.7-2.6-1.2-4-1.8
	c-1.2-0.4-2.8-0.8-4.1-1.1l-2.3-0.6h-0.3L520.1,254.3L520.1,254.3z"
          />
          <path fill="none" d="M520.1,253.9v0.4l1.9-0.4" />
          <path
            fill="none"
            d="M516.6,254.6c-2.2,1-4.1,2.2-5.8,3.9c-1.2,1.1-1.9,3-2.1,5.8v3.3c0,1.5,0.1,2.8,0.4,3.9
	c0.4,1.5,1.2,2.6,2.2,3.4c1.1,0.8,2.3,1.2,4,1.5l1.7,0.1c-1.2-0.3-2.3-0.7-3.2-1.4c-1.1-0.8-1.8-1.9-2.2-3.4
	c-0.3-0.7-0.4-1.5-0.4-2.2v-6.2c0.3-2.2,0.8-3.9,2.1-4.8c1.7-1.6,3.6-2.9,5.9-3.9l1.1-0.4l-0.1-0.4
	C518.9,253.9,517.6,254.2,516.6,254.6L516.6,254.6z"
          />
          <path
            fill="none"
            d="M520.1,253.9l-2.8-16.1c-47.5-23.2-95.5-36.2-144.2-39.1l12.1,60l123.8,12.5c-0.4-1.1-0.5-2.3-0.4-3.9v-3.3
	c0.1-2.8,0.8-4.7,2.1-5.8c1.7-1.6,3.6-2.9,5.8-3.9C517.7,254.2,518.9,253.9,520.1,253.9L520.1,253.9z M511.6,271.6
	c0.4,1.5,1.2,2.6,2.2,3.4c0.8,0.7,1.9,1.1,3.2,1.4l7.2,0.7c0.5,0,1.1,0.1,1.4,0.5l14.9-5.2l-1.7-0.3l-5.6-0.8l-5.6-0.7l-16.5-1.4
	C511.2,270.3,511.3,271,511.6,271.6L511.6,271.6z"
          />
          <path
            fill="none"
            d="M511.2,269.4l16.5,1.4l5.6,0.7c-0.4-1.4-1.1-2.6-1.9-3.6c-1.1-1.2-2.8-2.1-4.7-2.3h-0.1l-15.4-2.2L511.2,269.4
	L511.2,269.4z M517.1,265.5c0.4-0.4,1-0.7,1.5-0.7s1.1,0.3,1.7,0.7c0.4,0.4,0.5,1,0.5,1.5s-0.1,1.1-0.5,1.5c-0.4,0.4-1,0.7-1.7,0.7
	c-0.5,0-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-1-0.7-1.5S516.7,265.9,517.1,265.5L517.1,265.5z"
          />
          <path
            fill="none"
            d="M516.4,267.1c0,0.5,0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.7-0.7c0.4-0.4,0.5-1,0.5-1.5
	s-0.1-1.1-0.5-1.5c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-1.1,0.3-1.5,0.7C516.7,265.9,516.4,266.4,516.4,267.1L516.4,267.1z M517.8,266.3
	c0.3-0.3,0.5-0.4,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.4-0.8,0.4
	s-0.5-0.1-0.8-0.4c-0.1-0.1-0.3-0.4-0.3-0.8C517.5,266.8,517.7,266.5,517.8,266.3L517.8,266.3z"
          />
          <path
            fill="none"
            d="M517.5,267.1c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4c0.1-0.1,0.3-0.4,0.3-0.8
	c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3C517.7,266.6,517.5,266.8,517.5,267.1L517.5,267.1z M515.3,276.3
	c-1.7-0.3-2.9-0.7-4-1.5l-139.8-14.2l3.2,20.9l18.2,2.2l-0.1-0.4l-0.1-0.4v-0.3c0-0.7,0.3-1.2,0.8-1.8l0.7-0.5
	c1-0.7,2.5-1.1,4.3-1.5c3.9-0.8,8.5-1.1,13.9-0.5c5.2,0.4,9.8,1.5,13.6,3l0.4,0.1c3.2,1.2,5.1,2.8,5.5,4.1c0.1,0.3,0.1,0.5,0.1,0.8
	c0,0.7-0.3,1.2-1,1.8l128.1,15.1l-5.5-24.1l-12-1.2l0.7,2.1l2.2,6.5c0.5,2.1,0.3,3.7-1,5.2c-1.2,1.5-2.9,2.6-5,3.2
	c-4.7,0.5-7.7-1.5-9.4-6.3l-2.8-8l-0.7-1.9c-0.1-0.4-0.4-0.7-0.5-0.8c-0.4-0.4-0.8-0.5-1.4-0.5l-7.2-0.7L515.3,276.3L515.3,276.3z
	 M509.1,271.4l-123.8-12.5l-14.2-1.4l0.5,3.2l139.8,14.2C510.4,274,509.5,272.9,509.1,271.4L509.1,271.4z M519.7,225.8
	c17.3,8.1,37.1,17.7,59.4,29l0.3,0.1c2.2,1,4.5,1.9,7,2.6l3.3,0.8c-38.8-23.1-79.1-42.9-121.1-59.4l-4,4.3
	C483.9,210,502.2,217.6,519.7,225.8L519.7,225.8z"
          />
          <path
            fill="none"
            d="M432,285.5c-0.5-1.4-2.3-2.8-5.5-4.1l-0.4-0.1c-3.9-1.5-8.3-2.5-13.6-3c-5.5-0.5-10-0.3-13.9,0.5
	c-1.8,0.4-3.3,1-4.3,1.5l1.2-0.3c0.7-0.3,1.6-0.5,2.6-0.8l4.5-0.7h1c0.5,0,0.8,0.1,1.1,0.5c0.3,0.4,0.4,1,0.4,1.6l19,2.3v-1
	c0.1-0.7,0.4-1,1.1-0.7l1,0.3c1.8,0.8,3.2,1.5,4.1,2.3C431.1,284.7,431.7,285.1,432,285.5L432,285.5z"
          />
          <path
            fill="none"
            d="M430.2,284.4c-0.8-0.3-2.1-0.5-3.4-0.8l-2.6-0.3l-19-2.3l-1.9-0.3l-0.3,5.6l1.9,0.1l18.8,1.9l5.9,0.5l0.8-0.4
	l-0.8,0.4h1l0.8-0.5c0.6-0.5,1-1.1,1-1.8c0-0.3,0-0.5-0.1-0.8C431.7,285.1,431.1,284.7,430.2,284.4L430.2,284.4z"
          />
          <path
            fill="none"
            d="M424,282.4v1l2.6,0.3c1.5,0.3,2.6,0.4,3.4,0.8c-1-0.8-2.3-1.5-4.1-2.3l-1-0.3
	C424.4,281.4,424.1,281.7,424,282.4L424,282.4z M424.8,290.2c-0.5,0-1-0.1-1.2-0.4c-0.1-0.1-0.1-0.4-0.1-0.8l0.1-0.7l-18.8-1.9
	l-0.4,0.8c-0.4,0.5-1,0.7-1.7,0.5h-0.1l-0.1-0.1c-4.3-1.2-6.7-2.1-7.3-2.8l-0.8-0.4l-0.8-0.5c0.8,1.2,2.6,2.5,5.4,3.6
	c3.9,1.5,8.5,2.6,13.9,3.2c5.2,0.5,9.9,0.4,13.6-0.4l0.3-0.1c1.8-0.4,3.2-1,4.1-1.5h-1c-0.8,0.4-2.1,0.8-3.3,1.1L424.8,290.2
	L424.8,290.2z"
          />
          <path
            fill="none"
            d="M426.2,289.9c1.2-0.3,2.5-0.7,3.3-1.1l-5.9-0.5l-0.1,0.7c0,0.3,0,0.5,0.1,0.8c0.3,0.4,0.5,0.5,1.2,0.4
	L426.2,289.9L426.2,289.9z"
          />
          <path
            fill="none"
            d="M426,290.4c-3.9,0.8-8.4,0.8-13.6,0.4c-5.5-0.5-10-1.5-13.9-3.2c-2.9-1.1-4.7-2.3-5.4-3.6l-0.1-0.3l-18.2-2.2
	l0.3,6.5c62.7,8.8,123.8,17.7,183.4,27l0.8-11.7l-128.1-15.1l-0.8,0.5c-1,0.5-2.3,1.1-4.1,1.5L426,290.4L426,290.4z M404.6,279.2
	c-0.1-0.4-0.5-0.5-1.1-0.5h-1l0.4,1.9l1.9,0.3C405,280.2,404.9,279.8,404.6,279.2L404.6,279.2z"
          />
          <path
            fill="none"
            d="M404.6,286.5l-1.9-0.1l-0.5,1.5l0.1,0.1h0.1c0.7,0.1,1.2,0,1.6-0.5L404.6,286.5L404.6,286.5z M357.4,256.1
	l-132.3-12.9l-5.2-0.6l-2.1-0.4l-1.6,2.8l3.4,0.6l152.1,15.1l-0.5-3.2 M392.9,283.7l0.1,0.3l0.8,0.5l0.8,0.4
	c0.8,0.3,1.6,0.5,2.9,0.7l5.1,0.5l0.3-5.6l-7.6-0.5l-1.2,0.3l-0.7,0.5c-0.5,0.5-0.8,1.1-0.8,1.8v0.3l0.1,0.4L392.9,283.7
	L392.9,283.7z M399.6,281.4c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.7,0.4,1.1s-0.1,0.8-0.4,1.1c-0.3,0.3-0.5,0.4-0.8,0.5h-0.3
	l-0.5-0.1l-0.5-0.4c-0.3-0.3-0.5-0.7-0.5-1.1s0.1-0.8,0.5-1.1C398.8,281.5,399.2,281.4,399.6,281.4L399.6,281.4z"
          />
          <path
            fill="none"
            d="M398.5,281.8c-0.3,0.3-0.5,0.7-0.5,1.1s0.1,0.8,0.5,1.1l0.5,0.4l0.8,0.1c0.3-0.1,0.7-0.3,0.8-0.5
	c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4S398.8,281.5,398.5,281.8L398.5,281.8z"
          />
          <path
            fill="none"
            d="M399.1,284.4l0.5,0.1h0.3 M398.1,279.3c-1,0.3-1.9,0.5-2.6,0.8l7.6,0.5l-0.4-1.9L398.1,279.3L398.1,279.3z
	 M397.7,285.8c-1.1-0.1-2.1-0.4-2.9-0.7c0.5,0.5,3,1.5,7.3,2.8l0.5-1.5L397.7,285.8L397.7,285.8z M374.7,281.5l-3.2-21l-152.1-15.1
	l-3.3-0.6c-2.2,6.7-3.4,13.6-3.7,20.5l16.5,1.1v-0.5c0-0.7,0.3-1.2,0.8-1.8l0.7-0.5c1-0.5,2.5-1.1,4.3-1.5c3.9-0.8,8.5-1,13.9-0.4
	c5.2,0.4,9.8,1.5,13.6,3l0.4,0.1c3.2,1.2,5.1,2.8,5.6,4.1l0.1,0.7l34.5,3.4L374.7,281.5L374.7,281.5z"
          />
          <path
            fill="none"
            d="M374.7,281.5l-71.8-8.4l-34.5-3.4v0.1c0,1-0.5,1.8-1.6,2.3c-0.5,0.4-1.2,0.7-1.9,1c37.3,5,74.1,9.9,110.3,15
	L374.7,281.5L374.7,281.5z M373.2,198.9l-11-0.4l8.9,59l14.2,1.4 M215.8,184.1l-9.8,1.4l0.3,2.1l9.6-1.4l8.9-0.8l-0.3-2.1"
          />
          <path
            fill="none"
            d="M211.8,182.6l-5,2.5l-0.8,0.4l9.8-1.4l8.8-0.8l-9.6-1.1C213.8,182.2,212.7,182.4,211.8,182.6L211.8,182.6z
	 M361.5,193.6c-42.6-0.8-82.8,2.1-120.5,8.7l-3.3,5.5l6.3-1.1c34.1-5.8,70-8.5,107.7-8.4c3.4,0,6.9,0,10.5,0.1L361.5,193.6
	L361.5,193.6z"
          />
          <path
            fill="none"
            d="M351.8,198.3l5.6,57.6l13.8,1.4l-8.9-59C358.8,198.5,355.3,198.3,351.8,198.3L351.8,198.3z M231.1,209.1
	l-18.2,31.1c1.4,0.8,3,1.5,4.8,1.9l19.9-34.4L231.1,209.1L231.1,209.1z M225.1,243.2l19-36.5l-6.3,1.1l-19.9,34.4l2.1,0.4
	 M262.9,267.1c1.5,0.1,2.6,0.4,3.4,0.8c-1-0.8-2.3-1.6-4.1-2.3l-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.7-0.3-1,0-1.1,0.5v1
	L262.9,267.1L262.9,267.1z"
          />
          <path
            fill="none"
            d="M260.3,266.7l-19-2.5l-1.9-0.1l-0.3,5.5l1.9,0.1l18.8,1.9l5.9,0.5l1.1-0.1c1.1-0.7,1.7-1.5,1.7-2.3v-0.1
	l-0.1-0.7c-0.3-0.5-0.8-0.8-1.8-1.2c-0.8-0.3-2.1-0.5-3.4-0.8L260.3,266.7L260.3,266.7z"
          />
          <path
            fill="none"
            d="M260.3,265.7c0-0.7,0.4-0.8,1.1-0.5c0.1,0,0.4,0.1,0.5,0.1l0.4,0.1c1.8,0.7,3.3,1.5,4.1,2.3
	c1,0.3,1.6,0.7,1.8,1.2c-0.5-1.5-2.3-2.9-5.6-4.1l-0.4-0.1c-3.7-1.5-8.3-2.5-13.6-3c-5.5-0.5-10.2-0.4-13.9,0.4
	c-1.8,0.4-3.3,1-4.3,1.5l1.2-0.1c0.7-0.3,1.6-0.5,2.6-0.8c1.4-0.3,2.9-0.5,4.5-0.7h1c0.6,0,0.8,0.1,1.1,0.5c0.3,0.4,0.4,1,0.4,1.6
	l19,2.5L260.3,265.7L260.3,265.7z M259.7,272.5l0.1-0.7l-18.8-1.9l-0.4,0.8c-0.4,0.5-1,0.7-1.6,0.5h-0.1l-0.1-0.1
	c-3.6-1-5.8-1.8-6.9-2.3l-0.6-0.4l-0.8-0.4l-0.8-0.5c0.3,0.4,0.6,0.8,1.1,1.2c1,0.8,2.5,1.6,4.4,2.3c3.9,1.5,8.5,2.6,13.9,3.2
	c5.2,0.4,9.9,0.3,13.6-0.4l2.5-0.5l-1-0.1l-2.1,0.5l-0.7,0.1c-0.5,0-1-0.1-1.2-0.4C259.7,273,259.6,272.7,259.7,272.5L259.7,272.5z"
          />
          <path
            fill="none"
            d="M259.9,273.2c0.3,0.3,0.5,0.4,1.2,0.4l0.7-0.1l2.1-0.5l1.9-0.7l-5.9-0.5l-0.1,0.7
	C259.6,272.7,259.7,273,259.9,273.2L259.9,273.2z M266.7,272.2l-1.1,0.1l-1.9,0.7l1,0.1C265.5,272.9,266.2,272.6,266.7,272.2
	L266.7,272.2z"
          />
          <path
            fill="none"
            d="M248.7,274.1c-5.5-0.5-10.2-1.5-13.9-3.2c-1.9-0.8-3.4-1.5-4.4-2.3l-17.5-2.2l8.3,19.4c0.8,2.2,1.8,4,2.8,5.2
	c10.5,3.2,19.9,8.7,28.5,16.4c9.6,8.8,16.2,19,19.9,30.5c-3.9-7.7-9.4-14.7-16.5-21c-7.3-6.5-15.4-11.4-24.1-14.7
	c16.8,24.8,33.8,47.6,51.3,68.4c3.9,4.5,9.5,7,17.1,7.3l74.1,1.9l2.8-18.2c-28.9-3.6-58.2-8.1-88.3-13.6l87.8,5.9
	c0.4-22,0-44-1.1-65.9c-36.3-5.1-73-10-110.3-15l-2.5,0.5C258.5,274.5,253.9,274.7,248.7,274.1L248.7,274.1z M199.6,264.8l13.2,1.6
	l-0.4-1"
          />
          <path
            fill="none"
            d="M212.8,266.4l17.5,2.2c-0.4-0.4-0.8-0.8-1.1-1.2l-0.3-0.7v-0.1l-16.5-1.1L212.8,266.4L212.8,266.4z
	 M234.3,262.8c-1,0.3-1.9,0.4-2.6,0.8l7.6,0.5l-0.4-1.9C237.3,262.3,235.8,262.4,234.3,262.8L234.3,262.8z M240.9,262.6
	c-0.1-0.4-0.6-0.5-1.1-0.5h-1l0.4,1.9l1.9,0.1C241.3,263.7,241,263.1,240.9,262.6L240.9,262.6z M240.9,269.9l-1.9-0.1l-0.6,1.5
	l0.1,0.1h0.1c0.7,0.1,1.2-0.1,1.6-0.5L240.9,269.9L240.9,269.9z"
          />
          <path
            fill="none"
            d="M239.4,264.1l-7.6-0.5l-1.2,0.1l-0.7,0.5c-0.6,0.5-0.8,1.1-0.8,1.8v0.7l0.3,0.7l0.8,0.5l0.8,0.4l2.2,0.5
	l5.6,0.5L239.4,264.1L239.4,264.1z"
          />
          <path
            fill="none"
            d="M238.9,269.6l-5.6-0.5l-2.2-0.5l0.6,0.4c1.1,0.5,3.3,1.2,6.9,2.3L238.9,269.6z M225.1,243.2l132.3,12.9
	l-5.6-57.6c-37.7-0.3-73.6,2.6-107.7,8.4L225.1,243.2L225.1,243.2z M698.3,291.2c7.6,2.3,13.9,4.5,19.3,6.9
	c5.9,2.5,11.7,5.1,17.3,7.7c3,1.4,6.1,2.9,8.8,4.5l3.4-2.2c-3.9-2.5-8-4.7-12.4-6.7c-35.5-15.8-76.1-28.6-121.7-38.1l-1.1-0.3
	c-7.3-1.5-14.6-3-22.1-4.5h-0.1l-3.3-0.8l-5.2,12.4l9.9,7.4l43.1,5.9l28.8,4c5,0.5,9.9,1,14.7,1h0.4c3.3,0,6.7,0.3,10,0.8h0.4
	C691.8,289.5,695,290.2,698.3,291.2L698.3,291.2z"
          />
          <path
            fill="none"
            d="M688.5,289.1c10.3,2.1,21.2,5.9,32.9,11.7c10.3,5.1,18.6,9.5,24.9,13.1l1.2,0.8c0.8,0.4,1.2,1,1.2,1.6
	c0,0.5-0.1,1.2-0.3,1.9l-1,2.9l-0.1,0.3c-0.4,0.5-1.1,0.7-2.2,0.4l-3.3-0.7l-3.6-0.8l-1.9-0.4l-10.3-2.2l-0.3-0.1
	c-11.3-2.8-23.1-5.9-35.4-9.5l-4.1-1.2h-0.4c-0.1-0.1-0.3-0.1-0.4-0.1l-0.4-0.1c-2.9-1-6.7-2.1-11.4-3.3l2.6,1.1l13.5,4.3h0.1
	c12.5,3.9,24.5,7.2,36,10l0.3,0.1l19.1,4.4l2.3-0.1l1-0.5l0.5-0.8l1-2.9c0.5-1.9,0.3-3.6-0.8-5l-0.1-0.1l-5.4-3.3
	c-2.9-1.6-5.8-3.2-8.8-4.5c-5.8-2.6-11.6-5.1-17.3-7.7c-5.2-2.2-11.7-4.5-19.3-6.9C695,290.2,691.8,289.5,688.5,289.1L688.5,289.1z"
          />
          <path
            fill="none"
            d="M688.2,289c-3.4-0.5-6.7-0.8-10-0.8h-0.4c-5,0-9.8-0.3-14.7-1l-28.8-4l4.5,2.5l23.2,3c3.9,0.5,7.6,0.8,11.1,0.8
	c5.1-0.1,10.5,0.3,16.1,1.2c9.6,1.6,20.4,5.5,32.1,11.3c13.2,6.5,20.9,10.5,23.1,11.8c0.5,0.3,1,0.8,1.1,1.4c0.1,0.4,0.1,0.8,0,1.2
	l-0.1,0.7l-0.6,1.5c-0.8,1.6-1.8,2.3-2.9,2.2l3.3,0.7c1.1,0.3,1.9,0.1,2.2-0.4l0.1-0.3l1-2.9c0.1-0.7,0.3-1.4,0.3-1.9
	c0-0.7-0.4-1.2-1.2-1.6l-1.2-0.8c-6.3-3.6-14.6-8-24.9-13.1c-11.6-5.8-22.6-9.6-32.9-11.7L688.2,289L688.2,289z"
          />
          <path
            fill="none"
            d="M662,288.8l-23.2-3c6.1,3.3,12.2,6.5,18.4,9.5l18.7,2.2c1.5,0.4,2.8,1.1,3.7,2.2l5.5,6.7l0.4,0.1
	c0.1,0,0.3,0.1,0.4,0.1l0.4-0.6l0.3-0.3c0.3-0.1,0.4-0.3,0.7-0.4h1.2l22.7,6.2l24.6,5.9l2.3,2.5l3.6,0.8c1.1,0.1,2.1-0.6,2.9-2.2
	l0.5-1.5l0.1-0.7c0.1-0.4,0.1-0.8,0-1.2c-0.1-0.5-0.4-1.1-1.1-1.4c-2.1-1.4-9.8-5.2-23.1-11.8c-11.7-5.8-22.4-9.5-32.1-11.3
	c-5.6-1-11-1.4-16.1-1.2C669.5,289.7,665.8,289.4,662,288.8L662,288.8z M689.7,292c9.6,1.8,20.1,5.5,31.6,11.1
	c12.5,6.2,20.1,10,22.6,11.6c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.4,0.1,0.8,0,1.2l-1.1,2.2c-0.4,0.8-1.2,1.2-2.3,1.1l-2.6-0.4l-2.3-2.2
	l-8.3-1.9l-7.2-1.6l-8.7-2.2l-1.1-0.3l-12.9-3.4l-1-0.3l-5.1-1.5l-2.3-0.7c-2.3-1-4.1-2.2-5.6-3.7l-2.5-2.8l-2.6-2.8l-17.2-1.8
	l-4.1-3.7l21.9,2.3c0.7-0.4,1.4-0.7,2.1-1c0.8-0.3,1.8-0.4,2.9-0.5C686,291.3,687.8,291.5,689.7,292L689.7,292z"
          />
          <path
            fill="none"
            d="M689.6,294.8l0.8,0.7h1.8l17.2,10.7l0.4,1h10.7v6.6l7.2,1.6h16.6c-0.1-0.3-0.3-0.5-0.5-0.7
	c-2.6-1.5-10.2-5.4-22.6-11.6c-11.6-5.6-22-9.4-31.6-11.1c-1.9-0.4-3.8-0.7-5.4-0.5c2.5,0.4,4.3,1.4,5.2,2.8v0.5L689.6,294.8
	L689.6,294.8z"
          />
          <path
            fill="none"
            d="M689.6,294.2c-1.1-1.4-2.9-2.2-5.2-2.8c-1,0-1.9,0.3-2.9,0.5v6.9l2.5,2.8c1.4,1.5,3.3,2.8,5.6,3.7L689.6,294.2
	L689.6,294.2z M690.6,295.4c1.4,1.2,2.1,3,2.1,5.2v2.6c-0.1,1.1-0.3,1.9-0.7,2.8l5.1,1.5l-4.7-12.1L690.6,295.4L690.6,295.4z"
          />
          <path
            fill="none"
            d="M689.6,294.8v10.6l2.3,0.7c0.4-0.8,0.5-1.8,0.7-2.8v-2.6c0-2.2-0.7-4-2.1-5.2L689.6,294.8L689.6,294.8z
	 M657.6,290.5l4.1,3.7L679,296l0.4-3.2"
          />
          <path
            fill="none"
            d="M679,296l2.6,2.8v-6.9c-0.8,0.3-1.5,0.5-2.1,1L679,296L679,296z M710.9,311.1l-1.8-4h-11.3l0.1,0.5"
          />
          <path
            fill="none"
            d="M694,297.5l14.9,9.2l0.3,0.4l1.8,4l1.1,0.3l-2.1-4.3l-0.4-1l-17.2-10.7l4.7,12l1,0.3l-0.1-0.5"
          />
          <path
            fill="none"
            d="M694,297.5l3.8,9.6h11.3l-0.3-0.4 M720.7,313.6v-6.5H710l2.1,4.3 M738.6,319.5l2.6,0.4c1.1,0.1,1.8-0.1,2.3-1.1
	l1.1-2.2c0.1-0.4,0.1-1,0-1.2H728l8.3,1.9L738.6,319.5L738.6,319.5z M657.1,295.3c5.4,2.6,10.9,5.2,16.2,7.7l0.3,0.1
	c4.7,1.2,8.5,2.5,11.4,3.3l-5.5-6.7c-1-1.1-2.2-1.8-3.7-2.2L657.1,295.3L657.1,295.3z"
          />
          <path
            fill="none"
            d="M634.2,283.3l-43.1-5.9l-5.8,2.1c-1.7,0.5-3.3,0.8-5.1,1.1c-4.3,0.7-8.9,0.7-14-0.1l-12.2-1.2l5.5,24.1
	l24.1,2.9c12.2-7.7,26.1-11.6,41.8-11.6c18,0,33.7,5.1,47.2,15.1l4.7-5.2l-0.8-0.4l-2.6-1.1l-0.3-0.1c-5.5-2.5-10.9-5.1-16.2-7.7
	c-6.2-3-12.4-6.2-18.4-9.5L634.2,283.3L634.2,283.3z M613.1,263.2c45.7,9.5,86.3,22.1,121.7,38.1c4.4,2.1,8.5,4.3,12.4,6.7l5.2-2.5
	c-1-0.8-2.1-1.5-3.2-2.2c-7.2-4.3-14.7-8.3-22.7-11.7c-30-12.9-62.3-22.4-97.1-28.5L613.1,263.2L613.1,263.2z M526,242.2l2.8,13.3
	c1.4,0.5,2.8,1.1,4,1.8l-1-4.7c-0.3-1.8,0.5-2.3,2.3-1.6c6.7,3.7,13.5,7.6,20.1,11.7c5.5,3.4,10.9,7.3,16.1,11.6
	c-1.4,0.5-4.4,0.7-8.9,0.3l-20.9-2.2v0.1c-0.1,0.7,0,1.5,0.3,2.2l11.8,1.2l13.5,1.4l10.5,0.5c1.9-0.1,3.7-0.4,5.5-0.7h0.1L582,277
	c-8.1-6.3-16.6-12.2-25.7-17.7c-8.7-5.4-17.5-10.3-26.3-15L526,242.2L526,242.2z"
          />
          <path
            fill="none"
            d="M591.1,277.4l-8.9-0.3H582c-1.8,0.4-3.6,0.5-5.5,0.7l-10.5-0.5l-13.5-1.4l1.1,2.9v0.4l12.2,1.2
	c5.1,0.8,9.8,0.8,14,0.1c1.8-0.3,3.4-0.7,5.1-1.1L591.1,277.4L591.1,277.4z M532.6,257.3c3.3,1.8,6.2,4.1,8.8,7
	c0.7,0.7,0.8,1.6,0.4,2.8l-1.5,5.4l20.9,2.2c4.5,0.4,7.6,0.4,8.9-0.3c-5.2-4.4-10.6-8.3-16.1-11.6c-6.7-4.1-13.3-8-20.1-11.7
	c-1.9-0.8-2.8-0.3-2.3,1.6L532.6,257.3L532.6,257.3z M528.8,255.5l-2.8-13.3l-3.9-1.9l2.5,14.2C526,254.7,527.4,255.1,528.8,255.5
	L528.8,255.5z"
          />
          <path
            fill="none"
            d="M540.5,272.5l-1.7-0.1L540.5,272.5L540.5,272.5z M553.8,278.8l-1.1-2.9l-12-1.2l1.2,3.3l11.8,1.2v-0.3
	 M544.9,286.5l-2.2-6.5c-5.2,1.6-10.6,1.8-15.8,0.5l2.8,8c1.7,4.8,4.7,7,9.4,6.3c2.1-0.5,3.7-1.6,5-3.2S545.4,288.6,544.9,286.5
	L544.9,286.5z"
          />
          <path
            fill="none"
            d="M526.2,278.7l0.7,1.9c5.2,1.4,10.6,1.1,15.8-0.5L542,278l-1.1-3.3c-0.3-0.7-0.4-1.5-0.3-2.2l-14.9,5.2
	C525.8,278,526,278.2,526.2,278.7L526.2,278.7z M558.5,315.1l5.9,1c4.8-1.6,9.4-3.7,13.5-6.1c1.8-1.4,3.6-2.6,5.4-3.7l-24.1-2.9
	L558.5,315.1L558.5,315.1z M560.8,333c5.6-4.8,10.3-8.9,14.3-12.7c14.3-13.1,31.6-19.7,51.9-19.7c15.4,0,29.2,3.9,41.3,11.6l4.1-2.3
	c-13.5-10-29.2-15.1-47.2-15.1c-15.7,0-29.7,3.9-41.8,11.6h0.3c-1.8,1.4-3.7,2.6-5.6,3.7c-1.8,1.4-3.6,2.9-5.4,4.4
	c-3.9,3.4-7.3,7.2-10.2,11.1L560.8,333L560.8,333z"
          />
          <path
            fill="none"
            d="M564.4,315.9l-2.1,9.5c2.9-3.9,6.3-7.6,10.2-11.1c1.8-1.5,3.6-3,5.4-4.4C573.8,312.2,569.4,314.3,564.4,315.9
	L564.4,315.9z M583.4,306.2c-1.8,1.1-3.6,2.3-5.4,3.7c2.1-1.1,3.9-2.3,5.6-3.7L583.4,306.2L583.4,306.2z M591.4,346.2
	c10-9.9,22-15,36.2-15c14.2,0,26.1,5,36.2,15c9.9,9.9,15,22,15,36.2c0,3.7-0.4,7.2-1.1,10.6h10.5c-0.7-3.3-1.1-7.3-1.1-11.7
	c-0.1-2.5-0.3-5-0.5-7.3c-1.7-13.1-7.4-24.5-17.1-34.3c-5.1-5.1-10.7-9.1-16.8-11.8c-7.4-3.4-15.7-5.2-24.5-5.2
	c-16.1,0-29.7,5.8-41.1,17.1c-11.4,11.4-17.5,25.5-18.3,42.1l-0.5,11h9.2c-0.7-3.4-1-6.9-1-10.5c0-12.7,4-23.7,12.1-33
	C589.5,348.3,590.4,347.2,591.4,346.2L591.4,346.2z"
          />
          <path
            fill="none"
            d="M591.4,346.2c-1,1-1.9,2.1-2.8,3c-8.1,9.4-12.1,20.4-12.1,33c0,3.6,0.3,7.2,1,10.5c1.9,9.6,6.6,18.2,13.9,25.7
	l2.1,1.9c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15c1.4-1.5,2.8-2.9,4-4.4c5.1-6.3,8.4-13.3,9.9-21.2
	c0.7-3.4,1.1-6.9,1.1-10.6c0-14.2-5-26.1-15-36.2c-10-9.9-22-15-36.2-15C613.4,331.2,601.4,336.2,591.4,346.2L591.4,346.2z
	 M601,355.7c2.5-2.5,5.2-4.7,8.3-6.2c2.3-1.4,5-2.5,7.6-3.3c3.4-1,7-1.5,10.7-1.5c3.9,0,7.4,0.5,10.7,1.5c5.8,1.6,11.1,4.8,15.7,9.5
	h0.1l1.5,1.8c2.3,2.5,4.1,5.2,5.6,8.1c2.5,5.1,3.9,10.6,3.9,16.8c0,6.1-1.2,11.7-3.9,16.8c-1.8,3.6-4.1,6.7-7.2,9.8
	c-1.1,1.1-2.3,2.2-3.6,3.2c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8l-3.4-3.2c-3.2-3.3-5.8-6.7-7.6-10.6
	c-2.2-4.8-3.4-10.2-3.4-16c0-5.8,1.1-11.1,3.4-16c1.5-3.2,3.4-6.2,5.9-8.9L601,355.7L601,355.7z"
          />
          <path
            fill="none"
            d="M599.4,357.5c-2.5,2.8-4.4,5.8-5.9,8.9c-2.2,4.8-3.4,10.2-3.4,16c0,5.8,1.1,11,3.4,16c1.8,3.9,4.3,7.4,7.6,10.6
	l3.4,3.2c6.6,5.2,14.3,7.8,23.1,7.8c8.8,0,16.5-2.6,23.1-7.8c1.2-1,2.3-2.1,3.6-3.2c3-3,5.4-6.3,7.2-9.8c2.5-5.1,3.9-10.6,3.9-16.8
	c0-6.1-1.2-11.7-3.9-16.8c-1.4-2.9-3.3-5.6-5.6-8.1l-1.5-1.8h-0.1c-4.5-4.5-9.9-7.7-15.7-9.5c-3.4-1-7-1.5-10.7-1.5
	c-3.9,0-7.4,0.5-10.7,1.5c-2.6,0.8-5.2,1.8-7.6,3.3c-2.9,1.6-5.6,3.7-8.3,6.2L599.4,357.5L599.4,357.5z M601.7,356.5
	c2.3-2.3,4.7-4.1,7.3-5.8h0.1l1.8-1c1.8-0.8,3.4-1.6,5.4-2.2l2.2-0.7h0.1l3.4-0.7l5.5-0.4l5.5,0.4c1.2,0.1,2.3,0.4,3.4,0.7l2.3,0.7
	c1.8,0.5,3.6,1.4,5.4,2.2c0.7,0.3,1.4,0.7,2.1,1.1c2.5,1.5,4.8,3.4,7,5.5l0.8,1c1.9,2.1,3.4,4.1,4.8,6.3c0.4,0.7,0.8,1.4,1.1,2.1
	l1.2,2.5c0.4,1,0.7,1.9,1.1,2.8c0.3,0.7,0.4,1.4,0.5,2.2c0.7,2.9,1.1,5.9,1.1,9.1s-0.4,6.1-1,8.9l-0.7,2.5l-1,2.8l-1.2,2.6l-1.1,2.1
	c-1.5,2.5-3.4,4.8-5.6,7c-1.5,1.5-3,2.8-4.5,4c-0.8,0.5-1.7,1.2-2.5,1.8l-1.9,1.1c-1.7,0.8-3.4,1.6-5.2,2.2l-2.2,0.5
	c-2.9,0.7-5.9,1.1-9.1,1.1c-3,0-6.1-0.4-8.8-1l-2.3-0.7c-1.8-0.5-3.6-1.2-5.4-2.2l-4.7-2.9c-1.5-1.2-3.2-2.5-4.5-4
	c-2.2-2.2-4.1-4.7-5.6-7.3l-1-1.8c-0.7-1.2-1.2-2.5-1.7-3.7l-0.5-1.6l-0.7-2.2c-0.7-2.9-1.1-5.9-1.1-9.1s0.4-6.1,1-8.9l0.5-2.3
	l0.7-1.8c0.4-1.2,1-2.3,1.5-3.4l1.2-2.2c1.2-2.2,2.9-4.1,4.7-6.2L601.7,356.5L601.7,356.5z"
          />
          <path
            fill="none"
            d="M600.9,357.5c-1.8,1.9-3.4,4-4.7,6.2l2.3-0.3c1.2-1.9,2.8-3.7,4.5-5.4l0.4-0.4c1.7-1.7,3.4-3,5.2-4.3l0.4-2.3
	l7.4,11.8l2.8,4.7c1.7,2.9,3.3,5.8,4.8,8.7c0.5-0.3,1.1-0.6,1.7-0.7l-0.5-0.7l-0.1-0.3l-3.7-13.6l-2.9-13.9h-0.1l-2.2,0.7l0.5,1.8
	l2.6,9.8c-0.8,0.3-1.8,0.7-2.6,1.1l-5-8.8l-1-1.7l-1.8,1h-0.1c-2.6,1.5-5,3.4-7.3,5.8L600.9,357.5L600.9,357.5z"
          />
          <path
            fill="none"
            d="M598.5,363.3l-2.3,0.4c0.6,0.3,1,0.7,1.5,1c2.9,1.9,5.6,3.7,8.5,5.5l1.8,1.1c4.4,2.6,8.8,5.2,13.2,7.7l-4.8-4.5
	l-7.4-4.5l-1.8-1.2l-8.4-5.5L598.5,363.3L598.5,363.3z"
          />
          <path
            fill="none"
            d="M598.8,363.3l8.4,5.5c0.5-0.8,1.2-1.6,2.1-2.5l1.1-1.2c1.2-1.2,2.5-2.2,3.9-3.2c-0.8-1.5-1.8-2.9-2.8-4.4
	l-2.6-4.1v-0.1c-1.8,1.2-3.6,2.6-5.2,4.3l-0.4,0.4c-1.7,1.7-3.2,3.4-4.5,5.4L598.8,363.3L598.8,363.3z M595,365.7l1.5,0.8l8.8,5.1
	l-1.1,2.6l-9.9-2.8l-1.7-0.5l-0.5,2.3c-0.7,2.9-1,5.8-1,8.9s0.4,6.2,1.1,9.1l1.4-2.1l-0.1-1.1c-0.3-1.9-0.4-3.9-0.4-5.9
	c0-2.1,0.1-4,0.4-5.9l0.1-1l-1.4-2.1l13.6,3c4.7,1.1,9.4,2.3,13.8,3.7l0.4,0.1c0.3,0.1,0.4,0.1,0.7,0.4l0.7-1.6
	c-4.4-2.5-8.9-5.1-13.2-7.7l-1.8-1.1c-2.9-1.8-5.6-3.6-8.5-5.5c-0.5-0.4-1-0.7-1.5-1L595,365.7L595,365.7z"
          />
          <path
            fill="none"
            d="M593.5,369.3l-0.7,1.8l1.7,0.5c0.6-1.8,1.2-3.4,2.1-5l-1.5-0.8C594.4,366.8,593.9,368.1,593.5,369.3
	L593.5,369.3z M593.7,375.5c3.4,0.4,6.6,1.1,9.9,1.8l2.1,0.5c2.8,0.7,5.4,1.5,7.8,2.5l0.4,0.1l0.5,0.1h3.2c0.4-0.4,1-0.6,1.5-0.6
	h0.4c-4.4-1.4-8.9-2.6-13.8-3.7l-13.6-3L593.7,375.5L593.7,375.5z"
          />
          <path
            fill="none"
            d="M593.6,375.4l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9c0,2.1,0.1,4,0.4,5.9l0.1,1.1l0.1-0.1c3.4-0.4,6.7-1.1,9.9-1.8
	c-0.3-1.6-0.5-3.3-0.5-5.1c0-1.6,0.1-3.3,0.4-5C600.3,376.6,597,376.1,593.6,375.4L593.6,375.4z"
          />
          <path
            fill="none"
            d="M593.6,389.4l-1.4,2.1l13.8-3c4.8-1.1,9.4-2.5,13.8-3.7h-0.5c-0.5,0-1-0.1-1.4-0.6h-3.3l-0.7,0.1l-0.4,0.1
	c-2.6,1-5.2,1.8-7.8,2.5l-2.1,0.5C600.3,388.2,597,388.7,593.6,389.4L593.6,389.4z"
          />
          <path
            fill="none"
            d="M592.9,393.7l1.8-0.5l9.8-2.6l1.1,2.6l-8.8,5l-1.5,0.8l1,1.8l1.5-1c2.9-1.9,5.8-3.7,8.7-5.4l1.8-1.1
	c4.4-2.6,8.8-5.2,13.3-7.6c-0.3-0.5-0.5-1.1-0.7-1.6l-0.7,0.5l-0.3,0.1c-4.4,1.4-9.1,2.6-13.8,3.7l-13.8,3L592.9,393.7L592.9,393.7z
	"
          />
          <path
            fill="none"
            d="M593.5,395.3c0.4,1.2,1.1,2.5,1.7,3.7l1.5-0.8c-0.8-1.6-1.5-3.3-2.1-5l-1.8,0.5L593.5,395.3L593.5,395.3z
	 M594.4,371.6l9.9,2.8l1.1-2.6l-8.8-5.1C595.6,368.2,595,369.9,594.4,371.6L594.4,371.6z M627.6,345.8l-5.5,0.4l-3.4,0.7l1.9,1.5
	c2.2-0.4,4.5-0.7,7-0.7c2.3,0,4.7,0.3,6.9,0.7l2.1-1.4l-0.3,1.8c-0.5,3-1.2,5.9-1.9,8.8l-4.4,16.9l-0.1,0.4
	c-0.1,0.3-0.3,0.5-0.4,0.7c0.7,0.1,1.2,0.3,1.8,0.7c1.7-2.9,3.3-5.8,5-8.7l10.5-16.4l0.4,2.3c1.8,1.1,3.4,2.5,5,4.1l2.3,2.5
	c1,1.1,1.8,2.2,2.6,3.4l2.3,0.4c-1.4-2.2-2.9-4.3-4.8-6.3l-0.8-1c-2.2-2.2-4.5-4.1-7-5.5c-0.7-0.4-1.4-0.8-2.1-1.1l-1,1.5l-5.1,8.8
	c-0.8-0.4-1.8-0.8-2.6-1.1l2.8-9.8l0.5-1.8l-2.3-0.7c-1.1-0.3-2.2-0.5-3.4-0.7L627.6,345.8L627.6,345.8z"
          />
          <path
            fill="none"
            d="M644.2,349.8c-1.8-1-3.4-1.6-5.4-2.2l-0.5,1.8c1.8,0.5,3.4,1.2,5,2.1L644.2,349.8L644.2,349.8z M646.7,353.5
	l-5.5,8.4c1.2,1,2.6,1.9,3.7,3.2c0.8,0.8,1.5,1.6,2.2,2.5l1,1.4l8.5-5.4h0.1c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5
	C650,355.8,648.3,354.6,646.7,353.5L646.7,353.5z M646.5,353.4l-0.4-2.3l-10.5,16.4l-5,8.7l4.5-5l4.4-7.4l1.2-1.9L646.5,353.4
	L646.5,353.4z"
          />
          <path
            fill="none"
            d="M656.6,363.4l-8.5,5.4l-0.5,0.3l-5.8,3.4l-2.5,1.5c-0.1,0.1-0.4,0.3-0.7,0.3l-5,4.5c4.5-2.5,8.9-5,13.3-7.6
	l12-7.6L656.6,363.4L656.6,363.4z"
          />
          <path
            fill="none"
            d="M658.9,363.8l-12,7.6c-4.4,2.6-8.8,5.1-13.3,7.6c0.3,0.5,0.5,1.1,0.7,1.6c0.3-0.3,0.5-0.4,0.8-0.5l0.3-0.1h0.1
	c4.4-1.4,8.9-2.6,13.6-3.7l2.1-0.5c3.3-0.8,6.6-1.5,9.9-2.2h0.1l1.7-0.3c-0.1-0.7-0.4-1.4-0.6-2.2l-1.8,0.5l-9.8,2.6
	c-0.3-1-0.7-1.8-1.1-2.6l8.8-5l1.5-0.8C659.8,365.2,659.3,364.5,658.9,363.8L658.9,363.8z"
          />
          <path
            fill="none"
            d="M658.5,366.7c0.8,1.5,1.5,3.2,2.1,5l1.8-0.5c-0.3-1-0.7-1.9-1.1-2.8c-0.4-0.8-0.7-1.6-1.2-2.5L658.5,366.7
	L658.5,366.7z M647,367.4c-0.7-0.8-1.4-1.6-2.2-2.5c-1.1-1.1-2.5-2.2-3.7-3.2l-1.2,1.9c1,0.7,1.8,1.4,2.8,2.2l0.8-1
	c1.1,0.8,1.9,1.6,2.8,2.5c0.5,0.5,1,1.1,1.4,1.7l0.6-0.3L647,367.4L647,367.4z"
          />
          <path
            fill="none"
            d="M646.1,367.4c-0.8-0.8-1.7-1.8-2.8-2.5l-0.8,1l-1.1,1.5c-0.8,1.4-1.5,3.2-2.2,5.1h2.5l5.8-3.4
	C647.1,368.5,646.7,367.9,646.1,367.4L646.1,367.4z M663.9,382.2c0-3.2-0.4-6.2-1.1-9.1l-1.5,1.9c0.4,2.3,0.7,4.7,0.7,7.2
	c0,2.3-0.3,4.7-0.7,6.9l1.4,1.9l-1.7-0.3h-0.1l-9.9-2.2l-8.8-2.2l-7-1.9l-1.1-0.5c-0.1,0.5-0.3,1.1-0.7,1.8l0.1,0.1
	c4.5,2.5,8.9,5,13.2,7.7l1.8,1.1c2.9,1.8,5.8,3.6,8.5,5.5c0.4,0.3,1,0.5,1.5,1l-2.3,0.4c-1.2,1.9-2.8,3.7-4.5,5.5
	c-1.8,1.8-3.6,3.3-5.6,4.7l-0.3,2.3c0.8-0.5,1.8-1.1,2.5-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1l-1.5-1l-8.8-5.1
	c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.8l1.8,0.5l0.7-2.5C663.6,388.3,663.9,385.4,663.9,382.2L663.9,382.2z"
          />
          <path
            fill="none"
            d="M646.1,378.7c0.4,0.1,0.7,0.4,1,0.8c0.7,0.8,1.1,1.8,1.1,2.9s-0.4,2.2-1.1,2.9l-0.6,0.5l3,0.8
	c0.3-1.4,0.4-2.8,0.4-4.3c0-1.5-0.1-3.2-0.4-4.5L646.1,378.7L646.1,378.7z"
          />
          <path
            fill="none"
            d="M649.4,377.7l2.1-0.5c3.2-0.8,6.5-1.4,9.8-1.8h0.1v-0.1l1.5-2.1l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2
	l-2.1,0.6c-4.7,1.1-9.2,2.3-13.6,3.7h0.6c0.5,0,1.1,0.1,1.5,0.5h3.3l1.8-0.5l3.6-1.2L649.4,377.7L649.4,377.7z"
          />
          <path
            fill="none"
            d="M646.1,378.7l-3.6,1.2v4.7l4,1.2l0.5-0.5c0.7-0.8,1.1-1.8,1.1-2.9c0-1.1-0.4-2.1-1.1-2.9
	C646.7,379.1,646.4,378.8,646.1,378.7L646.1,378.7z M651.5,377.2l-2.1,0.5c0.3,1.5,0.4,3,0.4,4.5s-0.1,2.9-0.4,4.3l2.1,0.5
	c0.3-1.5,0.4-3.2,0.4-4.8C652.1,380.6,651.9,378.8,651.5,377.2L651.5,377.2z"
          />
          <path
            fill="none"
            d="M652.1,382.4c0,1.7-0.1,3.3-0.4,4.8c3.2,0.8,6.5,1.4,9.6,1.8h0.1l0.1,0.1c0.4-2.2,0.7-4.5,0.7-6.9
	c0-2.5-0.3-4.8-0.7-7.1v0.1h-0.1c-3.3,0.4-6.6,1.1-9.8,1.8C651.9,378.8,652.1,380.6,652.1,382.4L652.1,382.4z M650.7,374.3l9.8-2.6
	c-0.5-1.8-1.2-3.4-2.1-5l-8.8,5C650.1,372.5,650.4,373.3,650.7,374.3z"
          />
          <path
            fill="none"
            d="M661.4,389h-0.1c-3.3-0.4-6.5-1.1-9.6-1.8l-2.1-0.5l-3-0.8l-4-1.2l-0.8-0.3l-0.4-0.1l-0.1-0.1l-0.4-0.1h-3.2
	c-0.4,0.4-1,0.5-1.5,0.5h-0.5l7,1.9l8.8,2.2l9.9,2.2h0.1l1.7,0.3l-1.4-1.9L661.4,389L661.4,389z M660.4,393.1
	c-0.5,1.6-1.2,3.3-2.1,5l1.5,1l1.2-2.6l1-2.8L660.4,393.1z M647,393.4c-4.3-2.8-8.7-5.2-13.2-7.7l5,4.5l0.7,0.4
	c0.5,0.4,1.2,0.7,1.9,1.1c1.7,1,3.3,1.9,5,3l1.1,0.7l0.7,0.4l8.4,5.5h0.1l2.3-0.4c-0.5-0.3-1-0.7-1.5-1c-2.8-1.9-5.6-3.7-8.5-5.5
	L647,393.4L647,393.4z"
          />
          <path
            fill="none"
            d="M649.6,393l8.8,5.1c0.8-1.5,1.5-3.2,2.1-5l-9.8-2.8C650.4,391.3,650.1,392.1,649.6,393L649.6,393z M611.7,351.4
	c1.7-0.8,3.2-1.5,5-2.1l-0.6-1.8c-1.8,0.6-3.6,1.2-5.4,2.2L611.7,351.4L611.7,351.4z M611.3,357.5c1,1.4,1.8,2.9,2.8,4.4l1.1,1.8
	l4.4,7.6l4.5,5c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7l-7.4-11.8l-0.4,2.3v0.1L611.3,357.5L611.3,357.5z M622.2,357.5l0.3,0.8
	c1.7-0.3,3.3-0.4,5.1-0.4l4.8,0.4l0.1-0.8c0.7-2.9,1.2-5.9,1.8-9.1l0.1-0.1c-2.2-0.4-4.5-0.7-6.9-0.7c-2.5,0-4.8,0.3-7,0.7h0.1
	C621.1,351.4,621.7,354.5,622.2,357.5L622.2,357.5z"
          />
          <path
            fill="none"
            d="M620.5,348.4l-1.9-1.5l2.9,13.9l3.7,13.6v-0.5c0-0.5,0.1-1,0.5-1.4v-3.3l-0.5-1.8c-0.8-2.3-1.5-4.7-2.2-7
	l-0.5-2.1l-0.3-0.8L620.5,348.4L620.5,348.4z M614.1,361.9c-1.4,0.8-2.6,1.9-3.9,3.2l-1.1,1.2c-0.7,0.8-1.4,1.6-2.1,2.5l1.8,1.2
	c0.5-0.8,1.2-1.7,1.9-2.5l1-1c1.1-1.1,2.2-2.1,3.4-2.9L614.1,361.9L614.1,361.9z M619.5,359.1l-2.6-9.8c-1.7,0.5-3.3,1.2-5,2.1
	l5,8.8C617.7,359.8,618.5,359.4,619.5,359.1L619.5,359.1z"
          />
          <path
            fill="none"
            d="M610.9,367.4c-0.7,0.8-1.4,1.5-1.9,2.5l7.4,4.5l3.2-3.2l-4.4-7.6c-1.2,0.8-2.3,1.8-3.4,2.9L610.9,367.4
	L610.9,367.4z M627.6,357.9c-1.8,0-3.4,0.1-5.1,0.4l0.5,2.1l4.5-0.4l4.4,0.4l0.5-2.1L627.6,357.9L627.6,357.9z"
          />
          <path
            fill="none"
            d="M632.4,358.3l-0.5,2.1c-0.6,2.3-1.2,4.8-2.1,7l-0.5,1.8v3.2c0.4,0.4,0.5,1,0.5,1.5v0.4l4.4-16.9
	c0.7-2.9,1.4-5.8,1.9-8.8l0.3-1.8l-2.1,1.4l-0.1,0.1c-0.4,3-1,6.1-1.8,9.1L632.4,358.3L632.4,358.3L632.4,358.3z M642.7,365.9
	c-0.8-0.8-1.8-1.5-2.8-2.2l-4.4,7.4l3.2,3.2c0.3-0.1,0.4-0.3,0.7-0.3v-1.5c0.7-2.1,1.5-3.7,2.2-5.1L642.7,365.9L642.7,365.9z
	 M638.3,360.2l5.1-8.8c-1.7-0.8-3.3-1.5-5-2.1l-2.8,9.8C636.5,359.4,637.3,359.8,638.3,360.2L638.3,360.2z"
          />
          <path
            fill="none"
            d="M625.1,367.4l0.5,1.8h3.6l0.5-1.8c0.8-2.3,1.5-4.7,2.1-7l-4.4-0.4l-4.5,0.4
	C623.6,362.7,624.4,365.2,625.1,367.4L625.1,367.4z"
          />
          <path
            fill="none"
            d="M625.6,369.2v3.3l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7s1.1,0.3,1.5,0.7l0.1,0.1v-3.3L625.6,369.2L625.6,369.2z"
          />
          <path
            fill="none"
            d="M625.6,372.5c-0.4,0.4-0.5,0.8-0.5,1.4v0.5l0.1,0.3l0.5,0.7c0.4,0.4,1,0.7,1.7,0.7s1.1-0.3,1.5-0.7
	c0.1-0.3,0.4-0.4,0.4-0.7l0.1-0.4v-0.4c0-0.5-0.1-1.1-0.6-1.5l-0.1-0.1c-0.4-0.4-1-0.7-1.5-0.7c-0.7,0-1.2,0.3-1.7,0.7L625.6,372.5
	L625.6,372.5z M627.6,372.6c0.3,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.6,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4
	s-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8C626.9,372.8,627.2,372.6,627.6,372.6L627.6,372.6z"
          />
          <path
            fill="none"
            d="M626.6,373c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1s0.5,0.4,0.8,0.4s0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4S626.9,372.8,626.6,373L626.6,373z M625.9,375.5c-0.5,0.1-1.1,0.3-1.7,0.7
	c-0.5,0.3-1.1,0.7-1.5,1.1c-0.5,0.5-1,1.1-1.2,1.6l-0.7,1.6l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.7h-0.1
	c0.1,0.5,0.4,1.1,0.7,1.6c0.3,0.5,0.7,1.1,1.2,1.5c0.5,0.5,1.1,1,1.7,1.2c0.5,0.3,1.1,0.5,1.8,0.7l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7
	s1.2,0.3,1.7,0.7v0.1c0.5-0.1,1.1-0.4,1.7-0.7c0.5-0.3,1.1-0.7,1.5-1.2c0.5-0.5,1-1.1,1.2-1.7c0.3-0.5,0.5-1.1,0.7-1.8v-0.1
	c-0.4-0.4-0.7-1-0.7-1.5c0-0.7,0.3-1.2,0.7-1.7c-0.1-0.5-0.4-1.1-0.7-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.5-0.5-1.1-0.8-1.7-1.2
	c-0.5-0.3-1.1-0.5-1.8-0.7c-0.4,0.4-1,0.7-1.5,0.7C626.9,376.2,626.3,375.9,625.9,375.5L625.9,375.5z"
          />
          <path
            fill="none"
            d="M632.5,377.3c0.4,0.5,0.8,1,1.2,1.5l5-4.5l-3.2-3.2l-4.5,5C631.4,376.5,632,376.9,632.5,377.3L632.5,377.3z
	 M639.4,372.5v1.5l2.3-1.5 M640.6,380.5v3.6l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3V380L640.6,380.5L640.6,380.5z M635.4,379.9
	l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.6c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.5v0.1l1.1,0.5h0.4c0.5,0,1.1-0.1,1.5-0.5l0.1-0.1
	c0.4-0.4,0.7-1,0.7-1.5c0-0.7-0.3-1.2-0.7-1.6l-0.1-0.1c-0.4-0.3-0.8-0.5-1.5-0.5L635.4,379.9L635.4,379.9z M636.8,383.1
	c-0.3,0.3-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,0.8-0.4
	c0.4,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1S637.1,382.9,636.8,383.1L636.8,383.1z"
          />
          <path
            fill="none"
            d="M637.2,382.2c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-0.8-0.4s-0.5,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1
	c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4C637.1,382.9,637.2,382.7,637.2,382.2L637.2,382.2z
	 M637.6,380.6c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.3,1.1-0.7,1.5l-0.1,0.1h3.2v-3.6h-3.3L637.6,380.6L637.6,380.6z M633.8,385.5
	c-0.3,0.5-0.7,1.1-1.2,1.7c-0.5,0.5-1,0.8-1.5,1.2l4.5,5l3.2-3.2L633.8,385.5L633.8,385.5z M639.4,390.6v1.1h1.9
	C640.6,391.3,639.9,390.9,639.4,390.6z"
          />
          <path
            fill="none"
            d="M638.7,390.2l-3.2,3.2l4.4,7.6l2.9-2.3c-1.2-1.7-2.3-4-3.4-6.9v-1.1L638.7,390.2z"
          />
          <path
            fill="none"
            d="M639.4,391.7c1.1,2.9,2.2,5.2,3.4,6.9l0.5,0.7c1.5-1.1,2.9-2.5,3.9-3.9l-1.1-0.7c-1.7-1.1-3.3-2.1-5-3
	L639.4,391.7L639.4,391.7z M624.3,388.6l-4.5,5l-4.5,7.6l-1.1,1.8l-5.5,8.4v0.3l0.4,2.2l1-1.4v-0.1c1.9-2.8,3.7-5.5,5.5-8.4l1.1-1.8
	C619.2,397.4,621.8,393,624.3,388.6L624.3,388.6z"
          />
          <path
            fill="none"
            d="M624.3,388.6c-2.5,4.5-5.1,8.9-7.7,13.3l-1.1,1.8c-1.8,2.9-3.6,5.6-5.5,8.4v0.1l-1,1.4l-0.4-2.2
	c-1.9-1.2-3.7-2.8-5.5-4.5c-1.8-1.8-3.3-3.7-4.5-5.6l-2.3-0.3c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4l4.7,2.9l0.8-1.5l5.1-8.8
	c0.8,0.4,1.7,0.8,2.6,1.1l-2.8,9.8l-0.4,1.8l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1l-2.1-1.4c-2.2,0.4-4.5,0.7-7,0.7
	c-2.3,0-4.7-0.1-6.9-0.5l-1.9,1.2l3-13.6c1.1-4.7,2.3-9.4,3.7-13.8l0.1-0.4c0.1-0.3,0.1-0.4,0.4-0.7
	C625.4,389,624.8,388.8,624.3,388.6L624.3,388.6z"
          />
          <path
            fill="none"
            d="M625.9,389.1c-0.1,0.1-0.3,0.4-0.4,0.7l-0.1,0.4v0.4c0,0.5,0.1,1.1,0.5,1.5l0.1,0.1c0.4,0.4,1,0.7,1.7,0.7
	s1.2-0.3,1.7-0.7l0.1-0.1c0.4-0.4,0.5-0.8,0.5-1.5v-0.5l-0.1-0.3l-0.5-0.7V389c-0.4-0.4-1-0.7-1.7-0.7
	C627,388.4,626.5,388.7,625.9,389.1L625.9,389.1z M628.5,391.6c-0.3,0.3-0.6,0.4-1,0.4c-0.3,0-0.7-0.1-0.8-0.4
	c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1s0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4s0.4,0.5,0.4,1
	C628.9,391,628.8,391.3,628.5,391.6L628.5,391.6z"
          />
          <path
            fill="none"
            d="M628.9,390.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4c-0.3,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1
	c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4C628.8,391.3,628.9,391,628.9,390.6L628.9,390.6z M629.4,389.1
	l0.5,0.7l0.1,0.3c1.4,4.4,2.6,8.9,3.7,13.8l3,13.8l2.2-0.5l-0.5-1.8l-2.6-9.8c0.8-0.3,1.8-0.7,2.6-1.1l5,8.8l0.8,1.5l1.9-1.1
	l-7.6-12c-2.6-4.4-5.2-8.8-7.6-13.3C630.5,388.7,629.9,389,629.4,389.1L629.4,389.1z M629.4,392.3c-0.4,0.4-1,0.7-1.7,0.7
	c-0.5,0-1.1-0.3-1.7-0.7l-0.1-0.1v3.2h3.6V392L629.4,392.3L629.4,392.3z"
          />
          <path
            fill="none"
            d="M629.4,392.1v3.3l0.1,0.5l0.1,0.4c1,2.6,1.7,5.2,2.3,7.8l0.5,2.1l1.2,5.6l0.7,4.1l0.1,0.1l2.1,1.4l-3-13.8
	c-1.1-4.8-2.5-9.4-3.7-13.8v0.5C629.9,391.3,629.8,391.7,629.4,392.1L629.4,392.1z M625.4,390.6v-0.4c-1.4,4.4-2.6,8.9-3.7,13.8
	l-3,13.6l1.9-1.2l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.1-5.6l0.5-2.1c0.7-2.8,1.5-5.2,2.5-7.8l0.3-1v-3.2
	C625.5,391.7,625.4,391.3,625.4,390.6L625.4,390.6z M631,388.4c2.5,4.5,5,8.9,7.6,13.3l7.6,12l0.3-2.3l0.1-0.1l-5.4-8.4
	c-0.4-0.7-0.7-1.2-1.1-1.8l-4.4-7.6L631,388.4L631,388.4z M605.1,382.4c0,1.5,0.1,3,0.4,4.5c2.8-0.7,5.2-1.5,7.8-2.5l0.4-0.1
	l0.7-0.1v-3.6l-0.6-0.1l-0.4-0.1c-2.6-1-5.2-1.8-7.8-2.5C605.3,379.4,605.1,380.9,605.1,382.4L605.1,382.4z"
          />
          <path
            fill="none"
            d="M605.6,377.8l-2.1-0.6c-0.3,1.7-0.4,3.2-0.4,5s0.1,3.4,0.5,5.1l2.1-0.5c-0.3-1.5-0.4-3-0.4-4.5
	C605.1,380.9,605.3,379.4,605.6,377.8L605.6,377.8z M619.2,380c-0.5,0-1.1,0.1-1.5,0.6l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6
	c0,0.5,0.3,1.1,0.7,1.6l0.1,0.1c0.4,0.4,0.8,0.5,1.4,0.5h0.5l0.3-0.1l0.7-0.5h0.1c0.4-0.4,0.7-1,0.7-1.6c0-0.7-0.3-1.2-0.7-1.6
	l-0.1-0.1c-0.3-0.1-0.4-0.3-0.7-0.4l-0.4-0.1L619.2,380L619.2,380z M618.3,381.4c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4
	c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1
	C617.9,382,618.1,381.7,618.3,381.4L618.3,381.4z"
          />
          <path
            fill="none"
            d="M617.9,382.4c0,0.3,0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-0.8,0.4C618.1,381.7,617.9,382,617.9,382.4L617.9,382.4z
	 M622.6,377.3c0.4-0.4,1-0.8,1.5-1.1l-4.5-5l-3.2,3.2l4.8,4.5C621.6,378.4,622.1,377.8,622.6,377.3z M614.5,380.6v3.6h3.3l-0.1-0.1
	c-0.4-0.4-0.7-1-0.7-1.7c0-0.7,0.3-1.2,0.7-1.6l0.1-0.1L614.5,380.6L614.5,380.6z M621.4,385.7c-4.5,2.5-8.9,5-13.3,7.6l-1.8,1.1
	c-2.9,1.8-5.8,3.6-8.7,5.4l-1.5,1l2.3,0.3h0.1l8.4-5.4l1.8-1.1c2.5-1.5,5.1-3,7.6-4.4L621.4,385.7L621.4,385.7z"
          />
          <path
            fill="none"
            d="M621.4,385.7l-5,4.5l3.2,3.2l4.5-5c-0.6-0.3-1.1-0.8-1.7-1.2C622.1,386.8,621.6,386.2,621.4,385.7L621.4,385.7z
	 M616.4,390.2c-2.5,1.4-5.1,2.9-7.6,4.4c0.8,1.2,1.8,2.3,2.9,3.4c1.1,1.1,2.2,1.9,3.3,2.9l4.5-7.6L616.4,390.2z M604.3,390.5
	l-9.8,2.6c0.5,1.6,1.2,3.3,2.1,5l8.8-5L604.3,390.5L604.3,390.5z"
          />
          <path
            fill="none"
            d="M610.2,399.6c1.1,1.1,2.3,2.2,3.7,3.2l1.1-1.8c-1.2-0.8-2.3-1.8-3.3-2.9c-1.1-1.1-2.1-2.2-2.9-3.4l-1.8,1.1
	C608,397.1,609,398.5,610.2,399.6z"
          />
          <path
            fill="none"
            d="M607.1,395.7l-8.4,5.4h-0.1c1.2,1.9,2.9,3.9,4.5,5.6c1.8,1.8,3.6,3.2,5.5,4.5V411l5.5-8.4
	c-1.2-1-2.5-1.9-3.7-3.2C609,398.5,608,397.1,607.1,395.7L607.1,395.7z M617,404.5l-5.1,8.8c1.7,0.8,3.2,1.5,5,1.9l2.8-9.8
	C618.6,405.2,617.8,404.9,617,404.5L617,404.5z"
          />
          <path
            fill="none"
            d="M616.8,415.3c-1.7-0.5-3.3-1.2-5-1.9l-0.8,1.5c1.7,0.8,3.4,1.6,5.4,2.2L616.8,415.3L616.8,415.3z M620.7,416.4
	c2.2,0.4,4.5,0.5,6.9,0.5c2.5,0,4.8-0.3,7-0.7l-0.1-0.1l-0.7-4.1l-1.2-5.6l-5.1,0.4l-5-0.4c-0.4,1.9-0.8,3.7-1.1,5.6l-0.7,4.1
	L620.7,416.4L620.7,416.4z M625.9,395.5l-0.3,1c-1,2.6-1.8,5.2-2.5,7.8l4.4,0.4l4.5-0.4c-0.7-2.6-1.5-5.2-2.3-7.8l-0.1-0.4l-0.1-0.5
	L625.9,395.5L625.9,395.5z"
          />
          <path
            fill="none"
            d="M627.6,404.7l-4.4-0.4l-0.5,2.1l5,0.4l5.1-0.4l-0.5-2.1 M641,402.7c1.4-1,2.6-1.9,3.9-3.2
	c1.2-1.2,2.2-2.5,3.2-3.7l-0.7-0.4c-1.1,1.4-2.3,2.8-3.9,3.9l-0.5-0.7l-2.9,2.3C640.4,401.5,640.6,402.2,641,402.7L641,402.7z
	 M635.7,405.5l2.6,9.8c1.7-0.5,3.3-1.2,5-1.9l-5-8.8C637.5,404.8,636.5,405.2,635.7,405.5L635.7,405.5z"
          />
          <path
            fill="none"
            d="M638.8,417c1.8-0.5,3.6-1.2,5.2-2.2l-0.8-1.5c-1.5,0.8-3.2,1.5-5,1.9L638.8,417L638.8,417z M644.9,399.6
	c-1.2,1.2-2.5,2.2-3.9,3.2l5.4,8.4l-0.1,0.1c1.9-1.2,3.9-2.9,5.6-4.7c1.8-1.7,3.2-3.6,4.5-5.5h-0.1l-8.4-5.5
	C647.1,397.2,646,398.5,644.9,399.6L644.9,399.6z M568.1,392.8l0.5-11c0.8-16.6,6.9-30.5,18.3-42.1c11.4-11.4,25-17.1,41.1-17.1
	c8.8,0,17.1,1.8,24.5,5.2l4.4-4.4c-8.8-4.7-18.4-7-29-7c-17.7,0-32.9,6.6-45.4,19.7c-12.5,13.2-19.3,29.3-20.1,48.4l-0.5,10.2
	l6.2,0.1L568.1,392.8L568.1,392.8z M553.1,373.6l-78.8-3.4c-31.8-1.5-64.2-4.4-97.5-8.5l-2.8,18.2l172,4.5c3.3,0.1,5.2-1,6.1-3.2
	c0.4-1.4,0.7-3.2,0.8-5.2L553.1,373.6L553.1,373.6z"
          />
          <path
            fill="none"
            d="M559.1,371c0.8-2.8-1-4.4-5.2-5.1l-0.8,7.7C556.7,373.4,558.6,372.6,559.1,371L559.1,371z"
          />
          <path
            fill="none"
            d="M554,365.9L376.2,354l0.4,7.6c33.3,4.1,65.8,7,97.5,8.5l78.8,3.4L554,365.9L554,365.9z"
          />
          <path
            fill="none"
            d="M558.5,315.1c-59.6-9.2-120.6-18.2-183.4-27c1.1,21.9,1.5,43.9,1.1,65.9L554,366
	C555.8,349.4,557.3,332.4,558.5,315.1L558.5,315.1z M672.4,309.9l-4.1,2.3c3.7,2.3,7.3,5.1,10.7,8.1c11.7,10.7,18.7,23.1,21.2,37.4
	c-0.1-1.4-0.3-2.8-0.6-4.1c-2.2-15.1-9.4-28.2-21.7-39.3C676.1,312.8,674.2,311.3,672.4,309.9L672.4,309.9z M677.1,304.7l3.4,1.5
	c14.6,6.1,29.7,11.1,45.4,15l19.8,4.4c4.7,0.8,7.6-0.1,8.9-2.9l1-2.9c0.5-2.1,0.3-3.9-0.8-5.2c-1.7-1.8-3.4-3.6-5.4-5.2
	c-0.7-0.4-1.2-1-1.9-1.4l-3.4,2.2l5.4,3.3l0.1,0.1c1.1,1.4,1.4,3,0.8,5l-1,2.9l-0.5,0.8l-1,0.5l-2.3,0.1l-19.1-4.4l-0.3-0.1
	c-11.6-2.9-23.5-6.2-36-10H690l-13.5-4.3L677.1,304.7L677.1,304.7z"
          />
          <path
            fill="none"
            d="M677.1,304.7l-4.7,5.2c1.8,1.4,3.7,2.9,5.5,4.5c12.4,11.1,19.5,24.2,21.7,39.3c0.3,1.4,0.4,2.8,0.5,4.1
	c-2.5-14.3-9.5-26.8-21.2-37.4c-3.4-3.2-6.9-5.8-10.7-8.1L657,323.5c5.9,3.2,11.4,7.4,16.5,12.7c10.7,11.1,16.9,24.2,18.7,39.3
	c0.4,2.8,0.5,5.5,0.5,8.4c0,3.6,0.3,6.7,0.7,9.8h60.4c1.8-0.1,3.4-0.4,4.8-1.1l3.2-1.5c1.1-0.5,1.9-1,2.6-1.4
	c0.5-0.3,0.8-0.8,0.8-1.6v-1h-5L693,374.7h11.7c2.5-0.1,5.1,0.1,7.8,0.5l42.6,6.3l1.9,0.3c1.1,0.1,2.1,0.5,3,1.2l-1.5-9.5l-0.3-1.2
	l-0.5-3.4l-0.3-1.1l-1.2-6.7c-0.3-1,0.1-1.2,1-1.1l5.8,1.9v-8h-0.3c-2.1-1-3.6-2.5-4.8-4.5l-0.3-0.4l-2.3-5v-0.1l-0.3-1.1l-0.1-1.2
	c0-1,0.1-1.9,0.3-2.8l5.9-18.8c0.4-1.1,0.8-1.8,1.5-2.3c-2.1-3-4.3-5.9-6.6-8.5c-1.2-1.2-2.5-2.5-3.9-3.6L747,308
	c0.7,0.4,1.4,0.8,1.9,1.4c2.1,1.6,3.9,3.3,5.4,5.2c1.1,1.5,1.5,3.2,0.8,5.2l-1,2.9c-1.2,2.6-4.3,3.6-8.9,2.9l-19.8-4.4
	c-15.5-3.9-30.7-8.9-45.4-15L677.1,304.7L677.1,304.7z M743.4,360.9c1.5,0.8,2.9,2.1,4.3,3.7l3.2,4.8c1.5,2.5,2.8,5.1,3.9,7.8
	l0.1,0.3c0.1,1.1-0.4,1.8-1.7,2.1l-0.3,0.1l-4.5-0.3l-35.2-5.1c-2.3-0.5-4-1.8-5-3.6c-2.3-4.7-4.3-9.5-5.5-14.7
	c-0.7-2.2-0.4-3.9,0.5-4.7c1-0.8,2.6-1,5.1-0.4l19.7,4.3C734.2,356.7,739.3,358.4,743.4,360.9L743.4,360.9z M749,358.2l-48-11.6
	c5.5-0.8,13.5,0,23.7,2.3C736.4,351.7,744.5,354.7,749,358.2L749,358.2z"
          />
          <path
            fill="none"
            d="M743.4,360.9c-4.1-2.5-9.4-4.3-15.5-5.5l-19.7-4.3c-2.5-0.5-4.1-0.4-5.1,0.4c-1.1,0.8-1.2,2.3-0.5,4.7
	c1.2,5.2,3,10,5.5,14.7c1,1.8,2.6,3,5,3.6l35.2,5.1l4.5,0.3l0.3-0.1c1.2-0.3,1.8-1,1.7-2.1l-0.1-0.3c-1.1-2.8-2.3-5.4-3.9-7.8
	l-3.2-4.8C746.3,363,744.9,361.7,743.4,360.9L743.4,360.9z M746.7,365.6l2.5,3.7c1.7,2.6,3,5.5,4.3,8.4c0,0.3-0.3,0.4-0.7,0.5h-0.1
	l-3.9-0.3l-35.4-5.1h-0.1c-1.9-0.4-3.2-1.2-3.8-2.8c-2.3-4.5-4.1-9.4-5.4-14.4v-0.1c-0.4-1.5-0.4-2.5,0.1-3c0.4-0.3,1.1-0.4,2.1-0.4
	l21.5,4.7c6.1,1.2,11.1,3,15.1,5.4C744.2,363,745.5,364.1,746.7,365.6L746.7,365.6z"
          />
          <path
            fill="none"
            d="M746.7,368.3c-0.5-0.7-1.1-1.2-1.7-1.8c-0.7-0.7-1.5-1.2-2.2-1.5c-4-2.3-9.1-4.1-15.1-5.2l-13.3-2.8l2.1,2.1
	c0-0.3,0.1-0.5,0.5-0.5h0.7c0.4,0,0.5,0.1,0.5,0.5v13.3c0,0.3-0.1,0.5-0.5,0.5H717c-0.3,0-0.5-0.1-0.5-0.5c-1,0.4-1.9,0.5-3,0.7
	l35.4,5.1l3.9,0.3c-0.5-1.2-1.1-2.3-1.8-3.6l-1.4-2.5l-0.1-0.1c-0.5-1.1-1.2-2.1-1.9-3L746.7,368.3L746.7,368.3z M744.8,367.8l0.5,1
	l-24.3-3.3v-2.3l22.4,3C743.8,366.7,744.2,367.2,744.8,367.8L744.8,367.8z M748.8,374.5l-27.8-3.7v-2.2l26.5,3.6
	C747.9,372.7,748.3,373.6,748.8,374.5L748.8,374.5z"
          />
          <path
            fill="none"
            d="M743.3,366.3l-22.4-3v2.3l24.3,3.3l-0.5-1C744.2,367.2,743.8,366.7,743.3,366.3L743.3,366.3z M747.5,372.1
	l-26.5-3.6v2.2l27.8,3.7C748.3,373.6,747.9,372.8,747.5,372.1L747.5,372.1z"
          />
          <path
            fill="none"
            d="M747.2,369c0.7,1,1.4,1.9,1.9,3l0.1,0.1l1.4,2.5c0.7,1.1,1.2,2.3,1.8,3.6h0.1c0.4-0.1,0.7-0.3,0.7-0.5
	c-1.2-3-2.6-5.8-4.3-8.4l-2.5-3.7c-1.2-1.5-2.5-2.6-3.9-3.3c-4-2.3-9.1-4.1-15.1-5.4l-21.6-4.5c3,1.1,5.5,2.5,7.7,4.1l0.5,0.4
	l13.3,2.8c6.1,1.2,11.1,3,15.1,5.2c0.8,0.4,1.5,1,2.2,1.5c0.6,0.5,1.1,1.1,1.7,1.8L747.2,369L747.2,369z"
          />
          <path
            fill="none"
            d="M704.2,352.7c-0.6,0.5-0.6,1.5-0.1,3v0.1c1.2,5.1,3,9.9,5.4,14.4c0.7,1.5,1.9,2.3,3.9,2.8h0.1
	c1.1,0,2.1-0.3,3-0.7V359c-0.5-0.7-1.2-1.4-2.1-2.1l-0.5-0.4c-2.1-1.6-4.7-3-7.7-4.1C705.3,352.1,704.6,352.3,704.2,352.7
	L704.2,352.7z M717,358.3c-0.3,0-0.5,0.1-0.5,0.5v13.3c0,0.3,0.1,0.5,0.5,0.5h0.7c0.4,0,0.5-0.1,0.5-0.5v-13.3
	c0-0.3-0.1-0.5-0.5-0.5L717,358.3L717,358.3z M713.4,372.9h-0.1H713.4 M724.7,349c-10.2-2.3-18.2-3.2-23.7-2.3l48,11.6
	C744.5,354.7,736.4,351.7,724.7,349L724.7,349z M652.6,327.8c6.1,2.8,11.6,6.7,16.8,11.8c9.6,9.6,15.3,21,17.1,34.3
	c0.3,2.3,0.5,4.8,0.5,7.3c0.1,4.4,0.4,8.3,1.1,11.7l0.1,0.7h5.4c-0.4-2.9-0.5-6.2-0.7-9.8c0-2.9-0.3-5.6-0.5-8.4
	c-1.8-15.1-8.1-28.2-18.7-39.3c-5.1-5.2-10.6-9.5-16.5-12.7L652.6,327.8L652.6,327.8z M686.6,305.9l0.8,0.1l3.9,1.1
	c10,2.9,19.9,5.5,29.4,7.8l14.2,3.3l1.5,1.5l1.9,0.4l-2.3-2.5l-24.6-5.9l-22.7-6.2h-1.2C687,305.6,686.9,305.8,686.6,305.9
	L686.6,305.9z"
          />
          <path
            fill="none"
            d="M686.3,306.2l-0.4,0.5h0.4l4.1,1.2c12.2,3.6,24.1,6.7,35.4,9.5l0.3,0.1l10.3,2.2l-1.5-1.5l-14.2-3.3
	c-9.6-2.3-19.4-5-29.4-7.8l-3.8-1.1l-0.8-0.1L686.3,306.2L686.3,306.2z M759.3,330.7l0.4-1.2l2.9-9.1c0.3-0.7,0.5-1.2,1-1.6l-0.7-1
	c-0.5,0.4-1.1,1.2-1.5,2.3l-5.9,18.8c-0.3,0.8-0.3,1.8-0.3,2.8l0.1,1.2l0.3,1.1v0.1l2.3,5l0.3,0.4c1.2,2.1,2.8,3.6,4.8,4.5h0.3v-1.2
	c-1.7-0.8-2.9-1.9-3.8-3.6l-0.1-0.3l-0.3-0.4l-0.4-0.5c-0.7-1.2-1.2-2.6-1.8-4.1l-0.1-0.7l-0.1-1.2c0-0.8,0.1-1.6,0.3-2.5l0.8-2.5
	l0.4-1.2l1.1-3.3L759.3,330.7L759.3,330.7z"
          />
          <path
            fill="none"
            d="M759.3,330.7h1.2l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8L759.3,330.7
	L759.3,330.7z M756.7,339c-0.1,0.8-0.3,1.6-0.3,2.5h2.5v-5h-1.4L756.7,339L756.7,339z M757.8,335.3l-0.4,1.2h3.6l0.4-0.1
	c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1L757.8,335.3L757.8,335.3z"
          />
          <path
            fill="none"
            d="M758.9,332l-1.1,3.3h1.1 M761.4,342.8c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.4-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1h-5l0.1,1.2h4.8L761.4,342.8L761.4,342.8z"
          />
          <path
            fill="none"
            d="M756.7,343.5c0.5,1.5,1.2,2.9,1.8,4.1h0.4v-4.8h-2.3L756.7,343.5L756.7,343.5z M758.5,347.6l0.4,0.5v-0.5"
          />
          <path
            fill="none"
            d="M758.9,348.1l0.3,0.4l0.1,0.3h2.5c0.1,0,0.4,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.4-0.1-0.6
	c-0.1-0.1-0.3-0.1-0.4-0.1h-2.9L758.9,348.1L758.9,348.1z M756.5,361.1l1.2,6.7h5.1h-5.1l0.3,1.1h5.2v-7.2l-5.8-1.9
	C756.6,359.7,756.2,360.1,756.5,361.1z M759.9,373.4c0.1-0.1,0.1-0.3,0.1-0.5s0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8l0.3,1.2
	h0.7C759.5,373.7,759.6,373.6,759.9,373.4L759.9,373.4z M755.4,381.6c1.2,0.3,2.6,1,4,2.2l3.7,3.3h2.3l-5.1-4c-1-0.7-1.9-1.1-3-1.2
	L755.4,381.6L755.4,381.6z M742.2,395.3l-13.6-1.8h-31.4l44.2,3.3l10.9-3.3h-4.3"
          />
          <path
            fill="none"
            d="M755.4,381.6l-42.6-6.3c-2.9-0.4-5.5-0.7-7.8-0.5h-11.7l67.3,12.4h2.6l-3.7-3.3
	C757.8,382.7,756.6,381.8,755.4,381.6L755.4,381.6z M747.9,393.5h-19.4l13.6,1.8 M272.1,337.9c-3.6-11.6-10.2-21.7-19.9-30.5
	c-8.4-7.7-17.9-13.2-28.5-16.4l7.7,11.1c8.8,3.3,16.8,8.1,24.1,14.7C262.7,323.2,268.2,330.2,272.1,337.9L272.1,337.9z M376.2,354
	l-87.8-5.9c30,5.5,59.4,9.9,88.3,13.6L376.2,354L376.2,354z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M692.4,295.4l4.7,12 M679,296l2.5,2.9 M681.5,298.9v-7 M689.6,294.8v10.6
	 M728,315.4l-7.3-1.8 M744.6,315.4H728 M741.9,321l-3.6-0.8 M744.6,315.4c0.1,0.4,0.1,0.8,0,1.2l-1.1,2.2c-0.4,0.8-1.2,1.2-2.3,1.1
	l-2.6-0.4l-2.3-2.2l-8.3-1.9 M736.4,319.8l1.9,0.4 M710.9,311.1l-12.9-3.4 M697.9,307.1h11.3 M720.7,313.6v-6.5H710 M712,311.4
	l8.7,2.2 M710,307.1l2.1,4.3 M709.1,307.1l1.8,4 M710.9,311.1l1.1,0.3 M697.9,307.1l-3.8-9.6l14.9,9.2l0.3,0.4 M710,307.1l-0.4-1
	l-17.2-10.7 M691.9,306l5.1,1.4 M697,307.4l1,0.3 M698,307.7l-0.1-0.5 M689.6,305.3c-2.3-1-4.1-2.2-5.6-3.7l-2.5-2.8 M686.6,305.9
	l0.8,0.1l3.9,1.1c10,2.9,19.9,5.5,29.4,7.8l14.2,3.3l1.5,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M736.4,319.8l-10.3-2.2l-0.3-0.1c-11.3-2.8-23.1-5.9-35.4-9.5l-4.1-1.2
	h-0.4 M686.6,305.9l-0.3,0.3l-0.3,0.5 M738.3,320.2l-2.3-2.5l-24.6-5.9l-22.7-6.2h-1.2c-0.3,0.1-0.4,0.3-0.7,0.4 M686,306.7
	c-0.1-0.1-0.3-0.1-0.4-0.1l-0.4-0.1 M689.9,308.5h-0.1 M691.9,306l-2.3-0.7 M690.6,295.4c1.4,1.2,2.1,3,2.1,5.2v2.6
	c-0.1,1.1-0.3,1.9-0.7,2.8 M673.4,303l0.3,0.1c4.7,1.2,8.5,2.5,11.4,3.3 M681.5,291.9c0.8-0.3,1.8-0.4,2.9-0.5 M685.1,306.4
	l-5.5-6.7c-1-1.1-2.2-1.8-3.7-2.2l-18.7-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M638.7,285.8l23.2,3c3.9,0.5,7.6,0.8,11.1,0.8c5.1-0.1,10.5,0.3,16.1,1.2
	c9.6,1.6,20.4,5.5,32,11.3c13.2,6.5,20.9,10.5,23.1,11.8c0.5,0.3,1,0.8,1.1,1.4c0.1,0.4,0.1,0.8,0,1.2l-0.1,0.7l-0.5,1.5
	c-0.8,1.6-1.8,2.3-2.9,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M679,296l-17.3-1.8l-4.1-3.7l21.9,2.3 M681.5,291.9
	c-0.8,0.3-1.5,0.5-2.1,1 M679.4,292.8L679,296 M741.9,321l3.3,0.7c1.1,0.3,1.9,0.1,2.2-0.4l0.1-0.3l1-2.9c0.1-0.7,0.3-1.4,0.3-1.9
	c0-0.7-0.4-1.2-1.2-1.6l-1.2-0.8c-6.3-3.6-14.6-8-24.9-13.1c-11.6-5.8-22.6-9.6-32.9-11.7h-0.4 M690.6,295.4l-1-0.7 M692.4,295.4
	h-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M684.4,291.5c1.7-0.1,3.4,0.1,5.4,0.5c9.6,1.8,20.1,5.5,31.6,11.1
	c12.5,6.2,20.1,10,22.6,11.6c0.3,0.1,0.4,0.4,0.5,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.6,294.8v-0.5c-1.1-1.4-2.9-2.2-5.2-2.8 M152.8,236.8h-0.3l-2.1-0.1
	l2.6-1.5 M161.5,230.9l2.2,0.1h0.1 M161.4,232.3h-0.3l-2.2-0.1 M158.1,233.9l1.9-1.1c0.4-0.3,0.8-0.4,1.2-0.6 M153.1,235.2l2.2,0.1
	c0.1,0,0.1,0,0.3,0.1 M158.9,232.2l-3.3,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M155.6,233.8l2.2,0.1h0.3 M158.9,232.2l2.6-1.2 M142.2,241.9
	c0.8-0.4,1.8-0.7,2.9-0.7h0.6c5.5,0.4,10.7,1.2,15.5,2.2c0.7,0.1,1.2,0.4,1.8,0.6c0.7,0.4,0.8,1.1,0.3,1.8l-7.7,9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M144,262.1l2.2-2.1l7.8-8.1c1.5-1.6,3-3.4,4.4-5.2
	c1.2-1.4,1.4-2.2,0.1-2.8l-16.5-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M142.8,243.6l14.2,1.9c0.6,0.1,0.8,0.4,0.4,1.1l-0.3,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M157.2,246.9c0-0.3-0.3-0.6-0.6-0.7l-13.5-1.8c-0.4,0.1-0.7,0.3-1.1,0.6
	l-3.7,2.8c-2.9,2.2-5.6,4.5-8.3,6.9l-3,2.8c-0.8,1-0.8,1.5,0.3,1.9l1.4,0.1l13.1-0.1l2.6-0.5c1.1-0.4,2.1-1,3-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M142.1,241.9l4.1-2.6c1-0.6,1.9-0.8,3.2-0.8h0.5
	c5.5,0.4,10.7,1.2,15.5,2.2c3.2,0.7,4,2.2,2.3,4.5l-8.3,10 M153.1,235.2l2.5-1.4 M155.6,235.3l-2.8,1.5 M142.2,241.9h-0.1
	 M142.1,241.9H142l-2.5-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M146.9,254.4l-0.6,0.6l-0.3,0.3c-1.2,0.8-2.8,1.2-4.5,1.2h-9.2l1.5-1.5
	c2.3-2.1,4.8-4.1,7.4-6.2l0.1-0.1l1.9-1.5l8.5,1.2l-1.2,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M142.5,257.1l1.8-0.3c1.2-0.4,2.2-0.8,2.9-1.6l0.3-0.3l6.2-7.4l-10.5-1.4
	l-2.9,2.2c-2.9,2.2-5.5,4.5-8.3,6.9l-1.9,1.8h12.4L142.5,257.1L142.5,257.1z M125.5,260.1l-0.8-0.4c-0.3-0.3-0.4-0.5-0.3-1l0.3-0.5
	V258l4.4-4.1c2.6-2.3,5.4-4.7,8.3-6.9l3.7-2.8c0.4-0.3,1-0.6,1.5-0.7l0.3-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M149.3,249.9l-6.9-1.1c-2.5,2.1-5,4-7.3,6.2h7.4 M138.8,244.1
	c1.1-0.6,2.3-0.8,3.7-0.6h0.1 M125.5,260.2h-2.8l-0.6-0.1c-1.2-0.3-1.4-1-0.7-2.1c4.1-3.9,8.5-7.6,13.2-11l2.9-2.1l0.1-0.1
	 M137.8,244.8l1-0.7 M138.8,244.1l-0.3,0.1l-0.7,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M129.3,256.5l1.5-1.4c2.6-2.3,5.4-4.7,8.3-6.9l4-3l12.5,1.6l-5,5.9l-2.9,3
	l-0.3,0.3h-0.1l-0.1,0.1 M161.5,230.9l6.2-2.9c3.4-1.5,6.9-2.9,10.5-4.4l0.3,1.1 M179.8,234.2c-0.1,0.6-0.3,1.2-0.6,1.8l-0.1,0.1
	 M180.1,232.7c0,0.6-0.1,1-0.1,1.5 M178.4,224.6l1.2,4.7c0.3,1.1,0.4,2.2,0.4,3.3 M178.3,237.5l-1.1,1.5c-0.7,0.6-1.7,0.8-3.2,0.7
	c-6.5-1.2-13.5-2.2-21.2-2.9 M179.2,236.1l-1,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.4,224.6c-3.3,1.2-6.6,2.6-9.8,4.1c-1.6,0.7-3.2,1.5-4.8,2.2
	 M146,257.1l-1.5,0.7c-0.8,0.3-1.6,0.5-2.6,0.5l-12.8,0.1c-1-0.1-1.4-0.3-1.2-0.5l0.1-0.1l1.5-1.4 M125.5,260.2h0.1 M125.5,260.1
	v0.1 M125.6,260.2l-0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M145.8,257.1c-1.1,0.4-2.5,0.7-3.9,0.7h-12.2c-1,0-1.1-0.4-0.3-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M148,257.1l-0.6,0.4c-1.8,1.2-3.6,2.1-5.6,2.3l-2.8,0.3h-13.3 M142.5,255
	h1.1c1.6,0.1,2.9,0,3.4-0.6 M130.7,270.8l0.8-0.4l3.6-1.6l5.5-3.4l3.4-3.2 M116.2,275.1l0.8-0.1c4-0.8,7.7-1.9,11.1-3.2l2.5-1.1
	 M130.7,262.1v8.7 M144,262.1h-13.3 M150.8,250l-3.7,4.1l-0.1,0.3 M150.8,250l-1.5-0.1 M153,258l-2.8-0.1l1.5,1.5h0.1 M151.9,259.4
	l-1.2,1.6 M147.2,256.4l-0.7,0.4l-0.3,0.1 M146,257.1h-0.1 M146.2,256.9l-0.3,0.1 M145.8,257.1l0.4-0.1 M147.5,257.1h0.6
	 M147.2,256.4c-0.8,0.4-1.9,0.7-3,0.8h-1.6 M150.6,261l-3.4-0.3l1.5,1.4l1.2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M149.3,249.9l-2.9,3.3c-1,1.1-2.2,1.7-3.9,1.8 M150.2,254.9l-2.8,2.2
	 M150.2,254.9l-2.2,2.2 M159.4,255.3l-3.9-0.3 M156,259.5l1-1.1 M157,258.4l1.5-1.9 M158.5,256.5l1-1.2 M158.5,256.5l-4-0.1
	 M154.8,261.3l1.2-1.8 M149.9,262.3l-0.4,0.8c-1.2,2.6-4.3,4.8-9.1,6.7c-6.7,3.2-14.6,5.9-23.8,8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M128.9,277.1c4-1.2,7.3-2.5,10-3.4c5.5-2.1,8.8-3.6,10.2-4.4
	c1.8-1,3.2-2.8,4.3-5.4l0.7-1.4 M154.1,262.6l-4.1-0.3 M154.5,256.4L153,258 M155.6,255l-2.6-0.1l1.5,1.5 M154.1,262.6l0.7-1.2
	 M153,258l4,0.4 M156,259.5l-4.1-0.1 M154.8,261.3l-4.1-0.3 M161.4,232.3c6.2,0.4,12.4,1.1,18.6,1.9 M179.2,236.1
	c-7-1-14-1.7-21.2-2.1 M163.8,231.1c5.5,0.4,10.9,1,16.1,1.6 M155.6,235.3c7.7,0.4,15.3,1.1,22.7,2.2 M157.2,246.9l-7,8
	 M116.2,275.1c0.1-1.4,0.3-2.8,0.3-4v-8.9h14.2 M115.7,281.1c0.4-0.8,0.7-1.8,0.8-2.8v-0.1 M116.7,278.2l-0.4-3 M116.2,275.2v-0.1
	 M114.9,281.4c0.4-1,0.7-1.9,0.8-2.9c0.3-1.1,0.4-2.2,0.6-3.2 M80.5,341.9l-0.3,1.8 M80.2,343.7c-0.1,0.8-0.4,1.6-0.7,2.5l-1,2.6
	 M80.3,343.9l0.1-1.9 M80.3,343.9l-0.1-0.1 M81.4,320.5l-1,21.5 M99.6,307.1l-1.8,2.1c-2.3,2.8-5.5,4.7-9.5,5.5l-6.9,5.8
	 M105.1,340.8c-0.6,0.4-0.8,1.2-0.6,2.3c2.1,7.3,7,11,15,11.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M119.4,354.5h-3.7c-8.3,0-13.8-3.7-16.4-11.3c-0.4-2.2,0.4-3,2.8-2.8
	l3,0.4 M155,301.8c-2.3,1.6-4.7,3.6-6.9,5.6c-2.6,2.3-5,4.8-7,7.3c-5.2,5.9-14.4,9.8-27.8,11.3l-20.5,1.9c5.2,1.4,11,2.5,16.9,3.3
	c5.8,0.5,10.7,0.7,14.9,0.3c7.7-1.1,14.4-4.7,20.2-10.9l3.9-3.7c3.2-2.9,6.6-5.4,10.2-7.7 M99.2,366.7c-0.3-0.5-0.6-1.1-0.7-1.8
	c-3.4-11.6-9.5-18.6-18.3-21"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M78.6,349c6.6,1,12.5,5.6,17.9,14.2c0.7,1.2,1.5,2.3,2.5,3.4 M98.9,366.6
	l1.5,1.8 M99.2,366.7l-0.3-0.1 M99.2,366.7l1.2,1.6 M105.1,340.8l25,2.9c1.1,0.1,1.5,0.5,1.2,1.6c-0.8,5.5-3.7,8.5-8.7,9.1h-3
	 M78.6,349l1,13.3c0.3,1.9,0.8,3.3,1.8,4.4l7.7,10l10.2,1.1c3.7,0.3,7.2,0.7,10.6,1.2l3.4,0.7 M100.4,368.3l12.7,11.3 M146.5,285.9
	c-9.9,11.4-23.1,20.4-39.6,27.1c-8.1,3.4-16.6,5.9-25.6,7.4 M159,309.2l-4-7.4 M191.5,347.6l2.5-0.7l3.3-0.7l5.6-0.4l5.5,0.4
	l3.4,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M192,346.2c3.4-1,7-1.5,10.9-1.5c3.9,0,7.4,0.5,10.7,1.5
	c2.6,0.8,5.2,1.8,7.6,3.3c2.9,1.6,5.6,3.7,8.1,6.2l1.6,1.8c2.5,2.8,4.4,5.8,5.9,8.9c2.3,4.8,3.4,10.2,3.4,16c0,5.8-1.1,11-3.4,16
	c-1.8,3.9-4.4,7.4-7.6,10.6l-3.4,3.2c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8c-1.2-1-2.3-2.1-3.4-3.2
	c-3-3-5.5-6.3-7.2-9.8c-2.5-5.1-3.9-10.6-3.9-16.8c0-6.1,1.2-11.7,3.9-16.8c1.5-2.9,3.3-5.6,5.6-8.1l1.6-1.8h0.1
	C181,351,186.3,347.9,192,346.2L192,346.2z M168.1,324.1l3.3,4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151.9,384.9v-2.6c0-14.2,5-26.1,15-36.2c9.9-9.9,22-15,36.2-15
	c14,0,26.1,5,36,15c1,1,1.9,2.1,2.8,3c8.1,9.4,12.1,20.4,12.1,33c0,1.5-0.1,3-0.3,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.6,386.9l-0.4-8.1c-0.8-16.6-6.9-30.7-18.3-42.1
	c-11.3-11.4-25-17.1-41.1-17.1c-11.7,0-22.1,3-31.4,9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M168.1,324.1c10.3-7,21.9-10.6,34.9-10.6c17.7,0,32.9,6.6,45.4,19.7
	c12.7,13.2,19.4,29.3,20.1,48.4l0.3,5.5 M171.4,363.8c1.4-2.2,2.9-4.3,4.8-6.3l0.8-1c2.2-2.2,4.5-4.1,7-5.5c0.7-0.4,1.4-0.8,2.1-1.1
	 M189.3,361.9l-5.5-8.4v-0.1 M183.8,353.4c-1.8,1.1-3.4,2.5-5,4.1l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4 M182.4,368.8l0.4,0.3
	 M173.7,363.4l-2.3,0.4 M171.8,366.7l-1.5-1 M168.1,371.1l1-2.8l1.2-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.7,363.4h0.1l8.5,5.4 M170.3,365.7l1.1-1.9 M192,349.4
	c-1.7,0.5-3.3,1.2-5,2.1 M186.1,349.8c1.8-1,3.4-1.6,5.4-2.2 M192,349.4l-0.6-1.8 M186.1,349.8l1,1.6 M199.5,376.1
	c-1.6-2.9-3.3-5.8-5-8.7l-6.2-10c-1.1-1.6-2.2-3.3-3.3-5l-1-1.5l-0.4,2.3 M209.8,348.4c-2.2-0.4-4.5-0.7-7-0.7
	c-2.5,0-4.7,0.3-6.9,0.7 M198,358.3l4.8-0.4c1.8,0,3.4,0.1,5.1,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M196,348.4c0.6,3.2,1.1,6.2,1.8,9.1l0.1,0.8 M207.4,360.4l-4.5-0.4
	l-4.4,0.4 M201.1,369.2l-0.1-0.5l-0.4-1.1c-0.8-2.3-1.5-4.7-2.1-7 M190.4,363.7l-1.1-1.8 M182.4,368.8l3.2-3.9
	c1.2-1.1,2.5-2.2,3.7-3.2 M190.4,363.7c-1,0.7-1.8,1.4-2.8,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M187.8,365.9l-0.8-1c-1.1,0.8-1.9,1.6-2.8,2.5l-1.4,1.7 M191.1,372.5
	c-0.7-2.1-1.5-3.7-2.2-5.1l-1.1-1.5 M196,348.4L194,347l3,13.8l3.7,13.6 M187.1,351.4l5.1,8.8c0.8-0.4,1.8-0.8,2.6-1.1l-2.8-9.8
	 M198.5,360.4l-0.6-2.1 M191.1,374v-1.5 M191.1,374l0.6,0.4 M193,380.5h-3.3 M193,383.9l-0.1-0.1c-0.4-0.4-0.6-1-0.6-1.5
	c0-0.7,0.1-1.2,0.6-1.6l0.3-0.1 M193.1,382.2c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4s0.7-0.1,1-0.4s0.4-0.5,0.4-0.8
	c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4s-0.7,0.1-0.8,0.4C193.3,381.6,193.1,381.8,193.1,382.2L193.1,382.2z M187.9,379.9
	l-3.6-1.2 M188.6,372.5l2.5,1.5 M191.1,372.5h-2.5 M189.7,380.5l-1.8-0.5 M204.7,372.5l-0.1-0.1c-0.4-0.4-1-0.7-1.6-0.7
	c-0.7,0-1.2,0.3-1.6,0.7l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M201.1,372.3c-0.3,0.4-0.6,1-0.6,1.5v0.4 M201.9,373
	c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,0.8,0.4c0.3,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-0.8
	c-0.3-0.3-0.6-0.4-1-0.4C202.5,372.6,202.2,372.8,201.9,373L201.9,373z M201.3,375.5c-0.7,0.1-1.2,0.3-1.8,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M200.7,374.4l0.1,0.4l0.4,0.7 M201.3,375.5c0.4,0.4,1,0.7,1.6,0.7
	c0.6,0,1.1-0.3,1.6-0.7 M193,380.5c0.4-0.3,0.8-0.5,1.4-0.5h0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M196,380.6c-0.3-0.3-0.6-0.4-0.8-0.5l-0.3-0.1 M196.7,378.8
	c0.3-0.5,0.7-1.1,1.2-1.5c0.6-0.5,1.1-0.8,1.6-1.2 M196.7,378.8v0.1 M196,380.6c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.3,1.1-0.7,1.5v0.1
	 M196.7,378.9c-0.3,0.5-0.6,1.1-0.7,1.6 M199.5,376.1l-4.5-5 M196.6,385.7v-0.1 M196.6,385.5c-0.3-0.5-0.6-1.1-0.6-1.8 M196,383.9
	c-0.3,0.1-0.4,0.3-0.7,0.4l-0.4,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M194.9,384.4h-0.4c-0.6,0-1.1-0.1-1.5-0.5 M199.5,388.4l-1.5-1.2l-1.4-1.7
	 M201.1,389.1l-1.6-0.7 M204.4,389.1c-0.6-0.5-1.1-0.8-1.6-0.8s-1.1,0.3-1.7,0.7v0.1 M201,392.1l0.1,0.1c0.4,0.4,1,0.7,1.6,0.7
	c0.7,0,1.2-0.3,1.6-0.7l0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M200.4,390.1v0.5c0,0.5,0.1,1.1,0.6,1.5 M201.1,389.1l-0.6,0.7l-0.1,0.3
	 M203.6,389.8c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8
	c0.3,0.3,0.6,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8C204,390.4,203.9,390.1,203.6,389.8L203.6,389.8z
	 M199.5,388.4l-4.5,5 M187.9,384.6l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1 M189.7,383.9h3.3 M191.1,390.6l0.6-0.4 M191.1,390.6v1.1
	 M191.1,391.7h-1.9 M189.1,391.7l1.9-1.1 M191.6,374.4l5.1,4.4 M194.9,393.4l-3.3-3.2 M191.6,390.2l5-4.5 M189.7,383.9v-3.4
	 M187.9,379.9v4.7 M194.9,371.1l-3.3,3.3 M168.9,375.2l-1.5-2.1 M178.8,377.2c-3.2-0.8-6.5-1.4-9.8-1.8h-0.1v-0.1 M167.4,373.2
	l0.7-2.1 M194.9,379.9c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.6c-3.2-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M168.9,389.1c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2
	 M167.4,373.2c-0.7,2.9-1.1,5.9-1.1,9.1s0.3,6.1,1.1,8.9l0.7,2.5 M168.1,371.1l1.8,0.5 M181,377.7l-2.2-0.5 M169.9,371.6l9.8,2.6
	l1.1-2.6l-8.9-5 M178.8,377.2c-0.3,1.6-0.6,3.4-0.6,5.1s0.1,3.3,0.6,4.8 M180.9,386.6c-0.3-1.4-0.4-2.8-0.4-4.3
	c0-1.5,0.1-3.2,0.4-4.5 M183.9,385.8l-0.6-0.5c-0.7-0.8-1.1-1.8-1.1-2.9c0-1.1,0.3-2.1,1.1-2.9c0.3-0.3,0.6-0.5,1-0.8 M178.8,387.2
	l2.1-0.5 M180.9,386.6l3-0.8 M196.6,385.7c-4.5,2.5-8.9,5-13.2,7.7l-1.8,1.1c-2.9,1.8-5.8,3.6-8.5,5.5c-0.6,0.3-1,0.5-1.5,1l2.2,0.4
	 M172,398.1l8.8-5.1l-1.1-2.6l-9.8,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M194.9,384.4l-13.6,3.7l-12.1,2.6h-0.1l-1.5,0.4l1.4-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M168.9,389.1l0.1-0.1h0.1c3.3-0.4,6.5-1.1,9.8-1.8 M169.9,393.1l-1.8,0.5
	 M171.4,363.8l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6 M184.3,378.7l-3.3-1 M201.1,369.2v3.2 M183.9,385.8l4-1.2 M171.8,366.7
	c-0.8,1.5-1.5,3.2-2.1,5 M182.8,369l5.8,3.4 M194.9,371.1l-4.5-7.4 M138,384.4h5.8 M168.1,324.1c-3.7,2.6-7.2,5.6-10.6,9.1
	c-10.7,11.1-16.9,24.2-18.7,39.3v0.1 M138.7,372.8c-0.3,2.6-0.6,5.4-0.6,8.1l-0.1,3.6 M144.3,373.6l-0.1,4.7c0,2.2-0.1,4.3-0.3,6.2
	 M143.8,384.4l8.1,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M171.4,328.7c-3.4,2.2-6.7,5-9.8,8.1c-9.6,9.8-15.3,21.2-17.1,34.3
	l-0.3,2.6 M144.3,373.6l-5.6-0.8 M159,309.2l9.1,14.9 M173.9,401.4h0.1l8.4-5.5 M170.4,398.9l1.5-0.8 M168.1,393.7l1,2.6l1.4,2.6
	 M170.4,398.9l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4l2.6,1.8 M182.4,395.9l0.7-0.4 M189.3,402.7
	c-1.8,2.9-3.6,5.6-5.4,8.4v0.1 M173.9,401.4c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.7,3.3,5.6,4.7 M190.4,401l-1.1,1.8 M187.6,398.6
	l2.8,2.3 M182.4,395.9c1,1.4,1.9,2.6,3.2,3.7c1.2,1.2,2.5,2.2,3.9,3.2 M187.6,398.6l-0.6,0.7c-1.5-1.1-2.9-2.5-3.9-3.9 M197.7,406.3
	l5.1,0.4l4.9-0.4 M198.2,404.2c0.7-2.6,1.5-5.2,2.3-7.8l0.3-1 M198.2,404.2l-0.6,2.1 M207.2,404.2l-4.3,0.4l-4.7-0.4 M209.6,416.4
	c-2.2,0.4-4.5,0.5-6.9,0.5c-2.5,0-4.8-0.3-7.2-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.8,416.2l0.8-4.3c0.3-1.9,0.7-3.9,1.2-5.6 M191.6,417l0.4-1.8
	 M191.5,417l2.2,0.5 M184.3,413.7l1.9,1.1 M186.3,414.8c1.7,0.8,3.4,1.6,5.2,2.2 M187.1,413.3l-0.8,1.5 M187.1,413.3
	c1.6,0.8,3.3,1.5,5,1.9 M193.7,417.6c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.4,8.8-1l2.3-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M192,415.3l2.6-9.8l-2.5-1.1l-5.1,8.9 M195.8,416.2l-2.1,1.4 M193.7,417.6
	l3-13.8c1.1-4.8,2.3-9.4,3.7-13.8 M184.3,413.7l7.6-12c2.6-4.4,5.1-8.8,7.6-13.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151.9,384.9c0.4,11,4.1,20.8,11,29.2c1.2,1.5,2.5,2.9,4,4.4
	c10,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1l2.1-1.9c8.8-8.9,13.8-19.4,14.7-31.5 M183.9,411.4l0.4,2.3 M113.1,379.6l24.9,4.8
	 M138.7,372.8l-39.5-6.1 M201,395.5v-3.3 M191.1,391.7c-1.1,2.9-2.2,5.2-3.4,6.9 M194.9,393.4l-4.5,7.6 M169.9,393.1
	c0.6,1.6,1.2,3.3,2.1,5 M183.1,395.5l6.1-3.7 M159.2,214c-19.3,12.1-36.5,25.3-51.6,39.9 M103.3,271.8l0.1,4 M106.5,277.8l0.4-6.1
	 M106.9,271.8h-3.6 M103.4,275.8l0.1,2.1h2.9 M112.1,282.1c-1.9,5.6-4,10.9-6.3,15.7c-1.9,3.6-4,6.6-6.2,9.4 M113.5,281.7l-1.4,0.4
	 M114.9,249.6c-2.3,1.5-4.8,2.9-7.3,4.3 M114.9,249.6l-6.1,10c-0.6,0.8-0.8,1.6-1.1,2.6c-0.3,1.1-0.6,2.2-0.6,3.4l-0.3,6.1
	 M103.3,271.8l-0.3-4 M103,267.8h-1l0.3,8h1.1 M107.6,253.9l-3.3,5.5c-1.1,1.8-1.5,3.6-1.2,5.6l0.1,2.9 M165.8,226.2l-1.9-3.7
	 M143.3,272.5l3.2,13.5 M143.3,272.5c-4.7,1.6-9.5,3.2-14.4,4.7l-13.1,3.9l-0.8,0.3l-1.2,0.4h-0.1 M146.5,285.9
	c5.2-6.1,9.6-12.8,13.1-20.2 M158.2,280.4c-0.1,0.5-0.1,1.2-0.1,1.9c0,0.8,0.1,1.7,0.3,2.5 M158.3,284.8c1.2,0,2.2-0.3,3-0.7
	c0.8-0.4,1.2-1,1.2-1.5s-0.4-1.1-1.2-1.5c-0.8-0.4-1.8-0.7-3-0.7h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M159.6,265.9c-1,0.5-1.9,1.1-3,1.5c-4.3,1.8-8.7,3.4-13.2,5.1
	 M158.2,280.4c0.4-1.9,1.2-3.6,2.8-5.1c1.9-1.9,4.3-2.9,7-2.9c2.8,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7s-1,5.1-2.9,7s-4.3,2.9-7,2.9
	c-2.8,0-5.1-1-7-2.9c-1.4-1.4-2.2-2.9-2.6-4.5 M178.8,220.9c-4.5,1.6-8.8,3.4-13.1,5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M159.6,265.9c2.1-1.4,3.9-3.2,5.2-5.6l16-23.9c0.6-1.1,0.8-2.2,0.8-3.4
	c0-1.1-0.1-2.2-0.4-3.4l-2.3-8.7 M194.8,217.4l2.6,4c4.3,6.5,8,11.6,11.1,15l2.1,2.2c0.7,0.7,1.5,1.2,2.3,1.6 M192.7,214.3l2.1,3.2
	 M200.7,222.3c-0.4-1-0.1-1.6,0.8-1.9c7-1.8,14-3.4,21.2-5c0.8,0,1.1,0.4,0.8,1.1L213,234.8c-0.7,0.7-1.4,0.8-1.9,0.1
	C207.3,231.2,203.9,226.9,200.7,222.3L200.7,222.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M192.7,214.3c-1.6,1.8-1.5,4.1,0.3,7c3.9,6.2,8,11.6,12.2,16.4
	c1.2,1.4,2.5,2.5,3.9,3.6l2.3,1.6c1.5,1,3.2,1.6,4.8,2.1 M241,202.3c-14.2,2.5-28.1,5.5-41.5,9.1l-3.2,0.8c-1.5,0.6-2.8,1.2-3.4,2.1
	 M133.9,238.2c9.8-5.6,19.8-10.9,29.9-15.5 M133.9,238.2l-0.7,0.3c-6.2,3.6-12.2,7.3-18.3,11 M139.5,241.7l-5.2,3.7
	c-6.1,4.5-11.7,9.5-16.8,14.9l-1.9,2.1v8.9c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-0.8,2.8 M139.5,241.7l-1.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M138.2,241.5l-4,2.9c-7.4,5.4-14,11.1-20.1,17.5v9.4
	c0,1.4-0.1,2.8-0.3,4.1c-0.1,1.2-0.4,2.5-0.8,3.7l-1,3.2 M139.5,241.7l0.6-0.4c5.2-3.6,10.7-7,16.4-10.2l0.3-0.1
	c3-1.6,6.1-3.3,9.1-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M165.8,226.2c-3.2,1.4-6.2,2.9-9.1,4.4l-0.3,0.1
	c-6.3,3.3-12.2,6.7-17.7,10.5l-0.4,0.3 M161.9,219.2c0,1-1,1.9-2.9,2.9l-25.2,16.1 M161.9,219.2l-2.8-5.2 M163.8,222.5l-1.9-3.3
	 M183.5,198.6l11.1-1.8 M178.4,193.1l1.2,3.3c0.4,1.7,1.8,2.3,3.9,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.1,194.1l1.1,3.4c0.6,1.8,2.1,2.5,4.5,1.9l4.7-0.8 M173.1,194.1
	l-0.3-0.8c-7.3,1.4-14.4,2.9-21.3,4.4c2.3,5.4,4.8,10.9,7.7,16.5 M178.4,193.1l-5.4,1 M195.1,189.4l-16.9,2.8l0.3,1 M194.7,196.8
	V196 M194.7,196l0.4-6.6 M194.7,196.8l-0.1,1.2c-3.6,7.6-8.9,15.1-15.7,22.8 M211.7,182.6c-3.6-1.9-6.7-4.1-9.6-6.6l-23.5-19.1
	c-0.4-0.6-0.8-1-1.2-1.2c-0.3-0.3-0.7-0.6-1.1-0.7l-0.7-0.1c-0.6-0.1-0.8,0-1,0.3h-0.1v0.1c-0.1,0.3-0.1,0.7,0,1.1v0.1l0.4,0.6
	l0.8,0.8l1.5,1l18.7,15.1c3.9,3.3,7.6,7,11,11.1 M106.5,277.8c-1.8,10.7-4.1,20.5-7,29.3 M223.7,291c-7.3-2.2-15.1-3.3-23.5-3.3
	c-17.2,0-32.2,4.7-45.1,14 M155,301.8l-8.5-15.8 M231.5,302.2c-8.9-3.3-18.7-5.1-29.2-5.1c-16.2,0-30.7,4-43.2,12 M461.5,195
	c-36.3-8-65.1-12.7-86-13.9l-4.3-0.3c-14.2-0.8-28.5-1.2-42.9-1.2c-34.7,0.4-69.2,2.3-103.6,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M194.7,196l18.8-2.6c45.7-6.6,94.2-9.5,145.8-8.7l12.1,0.1
	c22.3,1.1,44.7,4.3,67,9.4l19.7,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M457.4,199.4l-19-4.3c-22.3-5.1-44.7-8.3-67-9.4l-12-0.3
	c-51.6-0.8-100.1,2.1-145.8,8.7l-18.8,2.6 M461.8,196.3h0.1l-0.4-1.2 M458.1,198.9l-0.7,0.6 M461.5,195l-3.4,3.9 M461.8,196.3
	l-3.7,4 M458.1,200.2c-0.4-0.3-0.5-0.6-0.7-0.8 M468.7,199l-6.9-2.8 M458.1,200.2c0.5,0.6,1.2,1,2.3,1.4l4.3,1.5 M522.2,240.3
	l-1.1-6.3 M522.2,240.3c-1.7-0.8-3.2-1.6-4.8-2.3 M520.1,254.3l1.9-0.4 M509.1,271.4c-0.4-1.1-0.5-2.3-0.4-3.9v-3.3
	c0.1-2.8,0.8-4.7,2.1-5.8c1.7-1.6,3.6-2.9,5.8-3.9c1.1-0.4,2.2-0.7,3.4-0.7 M524.5,254.4l-2.3-0.6h-0.1 M522.1,253.9h-1.9
	 M520.1,253.9v0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M520.1,254.3l-1.1,0.4c-2.2,1-4.1,2.2-5.9,3.9c-1.1,1-1.8,2.6-2.1,4.8
	 M517.8,266.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4c0.1-0.1,0.3-0.4,0.3-0.8
	c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.5-0.3-0.8-0.3S518.1,266.1,517.8,266.3L517.8,266.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M517.1,265.5c-0.4,0.4-0.7,1-0.7,1.5s0.3,1.1,0.7,1.5
	c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.7-0.7c0.4-0.4,0.5-1,0.5-1.5s-0.1-1.1-0.5-1.5c-0.4-0.4-1-0.7-1.7-0.7
	C518.1,264.9,517.5,265,517.1,265.5L517.1,265.5z M511.2,269.4c0.1,0.8,0.3,1.5,0.4,2.2c0.4,1.5,1.2,2.6,2.2,3.4
	c0.8,0.7,1.9,1.1,3.2,1.4 M511.2,263.2v6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M517,276.5l-1.7-0.1c-1.7-0.3-2.9-0.7-4-1.5 M511.3,274.8
	c-1.1-0.8-1.8-1.9-2.2-3.4 M464.7,203.1c19.3,6.9,37.6,14.4,55,22.6c17.3,8.1,37.1,17.7,59.4,28.9l0.3,0.1c2.2,1,4.5,1.9,7,2.6
	 M520.1,253.9l-2.8-16.1 M432,285.5c-0.3-0.4-0.8-0.8-1.8-1.2 M430.2,284.4c-1-0.8-2.3-1.5-4.1-2.3l-1-0.3c-0.5-0.3-1,0-1.1,0.7v1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M394.1,280.4c1-0.7,2.5-1.1,4.3-1.5c3.9-0.8,8.5-1.1,13.9-0.5
	c5.2,0.4,9.8,1.5,13.6,3l0.4,0.1c3.2,1.2,5.1,2.8,5.5,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M430.2,284.4c-0.8-0.3-2.1-0.5-3.4-0.8l-2.6-0.3 M423.4,288.4l-0.1,0.7
	c0,0.3,0,0.5,0.1,0.8c0.3,0.4,0.5,0.5,1.2,0.4l1.4-0.3c1.2-0.3,2.5-0.7,3.3-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M393,284c0.8,1.2,2.6,2.5,5.4,3.6c3.9,1.5,8.5,2.6,13.9,3.2
	c5.2,0.5,9.9,0.4,13.6-0.4l0.3-0.1c1.8-0.4,3.2-1,4.1-1.5 M431.3,288.3c0.6-0.5,1-1.1,1-1.8c0-0.3,0-0.5-0.1-0.8 M430.2,288.6
	l-0.7,0.3 M430.4,288.8l0.8-0.5 M429.5,288.8h1 M429.5,288.8l-6.1-0.4 M405,280.9c0-0.7-0.1-1.2-0.4-1.6c-0.1-0.4-0.5-0.5-1.1-0.5
	h-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M402.1,287.7l0.1,0.1h0.1c0.7,0.1,1.2,0,1.6-0.5l0.4-0.8 M424,283.3
	l-19-2.5 M404.6,286.5l18.8,1.9 M371.7,260.6l-0.5-3.2 M394.1,280.4l-0.7,0.5c-0.5,0.5-0.8,1.1-0.8,1.8v0.3l0.1,0.4l0.1,0.4
	 M392.9,283.7l0.1,0.3 M399.9,284.6c0.3-0.1,0.7-0.3,0.8-0.5c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4
	s-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.7-0.5,1.1s0.1,0.8,0.5,1.1l0.5,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M399.1,284.4l0.5,0.1h0.3 M399.9,284.6l-0.8-0.1 M393,284l1,0.7l0.8,0.4
	 M395.4,280.2l-1.2,0.3 M402.5,278.8l-4.5,0.7c-1,0.3-1.9,0.5-2.6,0.8 M402.5,278.8l0.6,1.9 M403.1,280.7l-7.7-0.5 M402.8,286.2
	l-5.1-0.5c-1.1-0.1-2.1-0.4-2.9-0.7 M402.1,287.7l0.7-1.5 M394.8,285.1c0.5,0.5,3,1.5,7.3,2.8 M403.1,280.7l-0.3,5.5 M371.7,260.6
	l3,20.9 M375.1,288.1l-0.4-6.6 M385.3,258.8l-14.2-1.4 M374.7,281.5l18.2,2.2 M402.8,286.2l1.8,0.3 M403.1,280.7l1.9,0.1
	 M521.1,233.9c-49.2-24.1-99.2-37.4-149.7-40l-9.9-0.3 M517.4,237.8c-47.5-23.2-95.5-36.2-144.2-39.1 M373.2,198.9l12.1,60
	 M385.3,258.8l123.8,12.5 M464.7,203.1l4-4.1 M371.7,260.6l139.6,14.2 M224.9,185.4l-0.3-2.1 M224.6,183.3l-9.6-1.1
	c-1.2-0.1-2.2,0.1-3.2,0.4 M206.9,185.1l-0.8,0.4 M206.1,185.5l0.3,2.1 M224.9,185.4l-8.9,0.8l-9.6,1.4 M224.6,183.3l-8.8,0.8
	l-9.8,1.4 M211.7,182.6l-4.8,2.5 M362.2,198.5l-0.7-4.8 M362.2,198.5c-3.4-0.1-6.9-0.1-10.5-0.1 M237.7,207.8l3.3-5.5 M237.7,207.8
	l-6.6,1.2 M231.1,209.1l-3.7,0.7c-11,2.2-21.9,4.8-32.6,7.7 M225.1,243.2l-5.2-0.6l-2.1-0.4 M216.1,245l3.4,0.6l152.1,15.1
	 M217.8,242.2l-1.6,2.8 M212.9,240.3c1.4,0.8,3,1.5,4.8,1.9 M217.8,242.2l19.9-34.4 M231.1,209.1l-18.2,31.2 M266.5,267.8
	c-0.8-0.3-2.1-0.5-3.4-0.8l-2.6-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M266.5,267.8c-1-0.8-2.3-1.7-4.1-2.3l-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1
	c-0.7-0.3-1,0-1.1,0.5v1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268.2,269c-0.5-1.5-2.3-2.9-5.6-4.1l-0.4-0.1c-3.7-1.5-8.3-2.5-13.6-3
	c-5.5-0.5-10.2-0.4-13.9,0.4c-1.8,0.4-3.3,1-4.3,1.5 M259.7,271.8l-0.1,0.7c0,0.3,0,0.5,0.1,0.7c0.3,0.3,0.5,0.4,1.2,0.4l0.7-0.1
	l2.1-0.5 M268.4,269.7l-0.1-0.7 M268.2,269c-0.3-0.5-0.8-0.8-1.8-1.2 M264.7,273.2c0.8-0.3,1.5-0.5,1.9-1 M266.7,272.2
	c1.1-0.7,1.6-1.5,1.6-2.3v-0.1 M266.7,272.2l-1.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.4,268.6c1,0.8,2.5,1.6,4.4,2.3c3.9,1.5,8.5,2.6,13.9,3.2
	c5.2,0.4,9.9,0.3,13.6-0.4l2.5-0.5 M263.8,273l0.8,0.1 M263.8,273l1.8-0.7 M265.6,272.3l-5.9-0.6 M212.4,265.5l0.4,1 M238.8,262.1
	c-1.6,0.1-3.2,0.4-4.5,0.7c-1,0.3-1.9,0.4-2.6,0.8 M241.3,264.2c0-0.7-0.1-1.2-0.4-1.6c-0.1-0.4-0.6-0.5-1.1-0.5h-1 M239.4,264.1
	l1.9,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M238.4,271.1l0.1,0.1h0.1c0.7,0.1,1.2-0.1,1.6-0.5l0.4-0.8 M240.9,269.9
	l-1.9-0.3 M238.9,269.6l0.4-5.5 M238.4,271.1l0.6-1.5 M238.8,262.1l0.6,1.9 M229,266.5v0.3l0.3,0.7 M230.4,263.8l-0.7,0.5
	c-0.6,0.5-0.8,1.1-0.8,1.8v0.5 M231.7,263.5l-1.2,0.3 M231.1,268.5l0.6,0.4c1.1,0.5,3.3,1.2,6.9,2.3 M229.3,267.5l0.8,0.5l1,0.4
	 M238.9,269.6l-5.6-0.5l-2.2-0.5 M230.4,268.6c-0.4-0.4-0.8-0.8-1.1-1.2 M239.4,264.1l-7.7-0.5 M212.8,266.4l8.3,19.4
	c0.8,2.2,1.8,4,2.8,5.2 M229,266.5l-16.6-1.1 M212.8,266.4l17.6,2.2 M216.1,245c-2.2,6.7-3.4,13.6-3.7,20.5 M244,206.7l-6.3,1.1
	 M260.3,266.7l-19-2.5 M240.9,269.9l18.8,1.9 M244,206.7l-19,36.5 M361.5,193.6c-42.6-0.8-82.8,2.1-120.5,8.7 M374.7,281.5
	l-71.8-8.4l-34.5-3.4 M351.8,198.3c-37.7-0.3-73.6,2.6-107.7,8.4 M357.4,256.1l-5.6-57.8 M225.1,243.2l132.3,12.9 M264.7,273.2
	c37.3,5,74.1,9.9,110.3,15 M371.1,257.5l-8.9-59 M371.1,257.5l-13.8-1.4 M373.2,198.9l-11-0.4 M743.7,310.3
	c-2.9-1.6-5.8-3.2-8.8-4.5c-5.8-2.6-11.6-5.1-17.3-7.7c-5.2-2.2-11.7-4.5-19.3-6.9c-3.3-1-6.5-1.6-9.8-2.1 M688.2,289
	c-3.4-0.5-6.7-0.8-10-0.8h-0.4c-5,0-9.8-0.3-14.7-1l-28.8-4 M613.1,263.2L612,263c-7.3-1.5-14.6-3-22.1-4.5h-0.1 M673.4,303
	c-5.5-2.5-10.9-5.1-16.2-7.7c-6.2-3-12.4-6.2-18.4-9.5l-4.5-2.5 M752.5,305.6c-1-0.8-2.1-1.5-3.2-2.2c-7.2-4.3-14.7-8.3-22.7-11.7
	c-30-12.9-62.3-22.4-97.1-28.5h-16.2 M582.2,277.1L582,277c-8.1-6.3-16.6-12.2-25.7-17.7c-8.7-5.4-17.5-10.3-26.3-15l-4-2.2
	 M586.4,257.5l3.3,1 M591.1,277.4l-5.8,2.1c-1.7,0.5-3.3,0.8-5.1,1.1c-4.3,0.7-8.9,0.7-14-0.1l-12.2-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M552.7,275.9l13.5,1.4l10.5,0.5c1.9-0.1,3.7-0.4,5.5-0.7h0.1 M586.4,257.5
	l-5.2,12.5 M581.2,270c3.3,2.5,6.6,5,9.9,7.4 M591.1,277.4l-8.9-0.3 M540.5,272.5l20.9,2.2c4.5,0.4,7.6,0.4,8.9-0.3
	c-5.2-4.4-10.6-8.3-16.1-11.6c-6.7-4.1-13.3-8-20.1-11.7c-1.9-0.8-2.8-0.3-2.3,1.6l1,4.7 M524.7,254.4c1.5,0.3,2.9,0.7,4.1,1.1
	 M528.8,255.5c1.4,0.6,2.8,1.1,4,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M532.6,257.3c3.3,1.8,6.2,4.1,8.8,7c0.7,0.7,0.8,1.6,0.4,2.8l-1.5,5.4
	 M538.8,272.3l-5.6-0.8 M540.5,272.5l-1.7-0.1 M540.5,272.5l-1.7-0.1 M533.2,271.5c-0.4-1.4-1.1-2.6-1.9-3.6
	c-1.1-1.2-2.8-2.1-4.7-2.3h-0.1l-15.4-2.2 M533.2,271.5l-5.6-0.7l-16.4-1.4 M553.8,279.2v-0.4l-1.1-2.9 M526.7,280.6l2.8,8
	c1.7,4.8,4.7,7,9.4,6.3c2.1-0.5,3.7-1.6,5-3.2s1.7-3.2,1-5.2l-2.2-6.5 M517,276.5l7.2,0.7c0.5,0,1.1,0.1,1.4,0.5 M525.5,277.7
	c0.3,0.3,0.4,0.5,0.5,0.8l0.7,1.9 M542.5,280L542,278 M540.8,274.7c-0.3-0.7-0.4-1.5-0.3-2.2 M542,278l-1.2-3.3 M542.5,280
	c-5.2,1.6-10.6,1.8-15.8,0.5 M542,278l11.8,1.2 M540.5,272.5l-15,5.2 M540.8,274.7l12,1.2 M581.2,270c-8-5.9-16.2-11.4-24.8-16.8
	c-11.8-7-23.5-13.5-35.5-19.3 M528.8,255.5l-2.8-13.3 M634.2,283.3l-43.1-5.9 M559.3,303.4l-0.8,11.7 M668.3,312.2
	c-12.1-7.7-25.9-11.6-41.3-11.6c-20.2,0-37.6,6.6-51.9,19.7c-4,3.6-8.8,7.8-14.3,12.7l1.7-7.6 M577.9,309.9
	c-4.1,2.3-8.5,4.4-13.5,6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M562.5,325.4c2.9-3.9,6.3-7.6,10.2-11.1c1.8-1.5,3.6-3,5.4-4.4
	 M577.9,309.9c2.1-1.1,3.9-2.3,5.6-3.7h-0.3 M677.6,393c0.7-3.4,1.1-6.9,1.1-10.6c0-14.2-5-26.1-15-36.2c-10-9.9-22-15-36.2-15
	c-14,0-26.1,5-36.2,15c-1,1-1.9,2.1-2.8,3c-8.1,9.4-12.1,20.4-12.1,33c0,3.6,0.3,7.2,1,10.5 M583.4,306.2c-1.8,1.1-3.6,2.3-5.4,3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601,355.7l-1.7,1.8c-2.5,2.8-4.4,5.8-5.9,8.9c-2.2,4.8-3.4,10.2-3.4,16
	c0,5.8,1.1,11,3.4,16c1.8,3.9,4.4,7.4,7.6,10.6l3.4,3.2c6.6,5.2,14.3,7.8,23.1,7.8c8.8,0,16.5-2.6,23.1-7.8c1.2-1,2.3-2.1,3.6-3.2
	c3-3,5.4-6.3,7.2-9.8c2.5-5.1,3.9-10.6,3.9-16.8c0-6.1-1.2-11.7-3.9-16.8c-1.4-2.9-3.3-5.6-5.6-8.1l-1.5-1.8h-0.1
	c-4.5-4.5-9.9-7.7-15.7-9.5c-3.4-1-7-1.5-10.7-1.5c-3.9,0-7.4,0.5-10.7,1.5c-2.6,0.8-5.2,1.8-7.6,3.3
	C606.2,351.2,603.5,353.2,601,355.7L601,355.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M610.9,349.8l-1.8,1H609c-2.6,1.5-5,3.4-7.3,5.8l-0.8,1
	c-1.8,1.9-3.4,4-4.7,6.2 M598.5,363.3h0.3l8.4,5.4 M596.2,363.5l-1.2,2.2 M595,365.7c-0.5,1.1-1.1,2.3-1.5,3.4l-0.7,1.8
	 M596.5,366.7l-1.5-1 M598.5,363.3l-2.3,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M596.2,363.5c0.6,0.3,1,0.7,1.5,1c2.9,1.9,5.6,3.7,8.5,5.5l1.8,1.1
	c4.4,2.6,8.8,5.2,13.2,7.7 M592.8,371.1l-0.5,2.3c-0.7,2.9-1,5.8-1,8.9c0,3.2,0.4,6.2,1.1,9.1 M603.5,377.4
	c-3.2-0.8-6.5-1.4-9.9-1.8l-0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M593.6,375.4l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9c0,2.1,0.1,4,0.4,5.9
	l0.1,1.1 M603.6,387.3c-3.2,0.8-6.5,1.4-9.9,1.8l-0.1,0.1 M592.2,391.5l0.7,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M619.6,380.2c-4.4-1.4-8.9-2.6-13.8-3.7l-13.6-3l1.4,2.1 M592.8,371.1
	l1.7,0.5 M592.9,393.7l0.5,1.6c0.4,1.2,1.1,2.5,1.7,3.7 M593.6,389.4l-1.4,2.1 M594.5,393.1l-1.7,0.5 M561.9,394.8l6.2,0.1v-2.1
	 M568.1,392.8h9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M652.6,327.8c-7.4-3.4-15.7-5.2-24.5-5.2c-16.1,0-29.7,5.8-41.1,17.1
	c-11.4,11.4-17.5,25.5-18.3,42.1l-0.6,11 M594.4,371.6c0.5-1.8,1.2-3.4,2.1-5 M553.1,373.6l-0.3,2.3c-0.1,2.2-0.4,3.9-0.8,5.2
	c-0.8,2.2-2.8,3.2-6.1,3.2l-172-4.5 M554,365.9c4.3,0.5,5.9,2.3,5.2,5.1c-0.4,1.6-2.5,2.5-6.1,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M561.9,394.8l0.5-10.2c0.8-19.1,7.6-35.2,20.1-48.4
	c12.5-13.1,27.7-19.7,45.4-19.7c10.6,0,20.2,2.3,29,7 M553.1,373.6l0.8-7.7 M559.3,303.4l24.1,2.8 M564.4,315.9l-5.9-0.8
	 M558.5,315.1c-1.2,17.3-2.8,34.3-4.5,50.9 M562.5,325.4l1.9-9.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M638.8,347.6l-2.3-0.7c-1.1-0.3-2.2-0.5-3.4-0.7l-5.5-0.4l-5.5,0.4
	l-3.4,0.7 M672.4,309.7v0.1l-4.1,2.3 M673.7,303.1l2.6,1.1 M676.3,304.2l0.8,0.4 M747.2,308.1c0.7,0.4,1.4,0.8,1.9,1.4
	c2.1,1.6,3.9,3.3,5.4,5.2c1.1,1.5,1.5,3.2,0.8,5.2l-1,2.9c-1.2,2.6-4.3,3.6-8.9,2.9l-19.8-4.4c-15.5-3.9-30.7-8.9-45.4-15l-3.4-1.5
	 M677.1,304.7l-4.7,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M672.4,309.7c1.8,1.4,3.7,2.9,5.5,4.5c12.4,11.1,19.5,24.2,21.7,39.3
	c0.3,1.4,0.4,2.8,0.5,4.1c-2.5-14.3-9.5-26.8-21.2-37.4c-3.4-3.2-6.9-5.8-10.7-8.1 M657,323.5l-4.4,4.3 M652.6,327.8
	c6.1,2.8,11.6,6.7,16.8,11.8c9.6,9.6,15.3,21,17.1,34.3c0.3,2.3,0.5,4.8,0.5,7.3c0.1,4.4,0.4,8.4,1.1,11.7 M668.3,312.2L657,323.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M657,323.5c5.9,3.2,11.4,7.4,16.5,12.7c10.7,11.1,16.9,24.2,18.7,39.3
	c0.4,2.8,0.5,5.5,0.5,8.4c0,3.6,0.3,6.7,0.7,9.8 M644.2,349.8l-1,1.5 M641.2,361.9l5.5-8.4v-0.1 M646.5,353.4l-0.4-2.3l-10.5,16.4
	l-5,8.7 M656.6,363.4c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5c-1.5-1.5-3.3-2.9-5-4.1 M658.9,363.8c-1.4-2.2-2.9-4.3-4.8-6.3l-0.8-1
	c-2.2-2.2-4.5-4.1-7-5.5c-0.7-0.4-1.4-0.8-2.1-1.1 M658.9,363.8l-2.3-0.4 M660,365.7c-0.3-0.7-0.7-1.4-1.1-2.1 M660,365.7l-1.5,0.8
	 M662.2,371.1c-0.3-1-0.7-1.9-1.1-2.8c-0.4-0.8-0.7-1.6-1.2-2.5 M656.6,363.4l-8.5,5.4 M648.1,368.8l-1-1.4
	c-0.7-0.8-1.4-1.6-2.2-2.5c-1.1-1.1-2.5-2.2-3.7-3.2 M642.7,365.9l0.8-1c1.1,0.8,1.9,1.6,2.8,2.5c0.5,0.5,1,1.1,1.4,1.7 M647.5,369
	l0.6-0.3 M662.2,393.7l0.7-2.5c0.7-2.9,1-5.8,1-8.9s-0.3-6.2-1.1-9.1 M646.1,378.7l3.3-1 M646.5,385.8l0.5-0.5
	c0.7-0.8,1.1-1.8,1.1-2.9c0-1.1-0.4-2.1-1.1-2.9c-0.3-0.3-0.6-0.5-1-0.8 M651.5,377.2c0.3,1.6,0.6,3.4,0.6,5.1s-0.1,3.3-0.4,4.8
	 M649.4,377.7l2.1-0.5 M649.6,386.6c0.3-1.4,0.4-2.8,0.4-4.3c0-1.5-0.1-3.2-0.4-4.5 M658.5,366.7l-8.8,5c0.4,0.8,0.8,1.6,1.1,2.6
	l9.8-2.6 M662.9,373.2l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2l-2.1,0.6c-4.7,1.1-9.2,2.3-13.6,3.7 M660.6,371.7l1.8-0.5
	 M651.5,377.2c3.2-0.8,6.5-1.4,9.8-1.8h0.1v-0.1 M661.4,375.2c0.4,2.3,0.7,4.7,0.7,7.2c0,2.3-0.3,4.7-0.7,6.9 M661.5,389.1l-0.1-0.1
	h-0.1c-3.3-0.4-6.5-1.1-9.6-1.8 M660.4,393.1l1.8,0.5 M661.5,389.1l1.4,1.9l-1.7-0.3h-0.1l-9.9-2.2l-8.8-2.2l-7-1.9 M651.6,387.2
	l-2.1-0.5 M649.6,386.6l-3-0.8 M633.8,385.7c4.5,2.5,8.9,5,13.2,7.7l1.8,1.1c2.9,1.8,5.8,3.6,8.5,5.5c0.4,0.3,1,0.5,1.5,1l-2.3,0.4
	 M658.5,398.1l-8.8-5.1c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.8 M662.9,373.2c-0.1-0.7-0.4-1.4-0.6-2.2 M660.6,371.7
	c-0.5-1.8-1.2-3.4-2.1-5 M661.4,375.2l1.5-2.1 M633.8,378.9c4.5-2.5,8.9-5,13.3-7.6l12-7.6 M611.7,351.4l-1-1.6 M614.1,361.9
	c-0.8-1.5-1.8-2.9-2.8-4.4l-2.6-4.1v-0.1 M608.7,353.2c-1.8,1.2-3.6,2.6-5.2,4.3l-0.4,0.4c-1.7,1.7-3.2,3.4-4.5,5.4 M624.1,376.1
	c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7l-7.4-11.8l-0.4,2.3 M622.5,358.3l-0.3-0.8c-0.7-2.9-1.2-5.9-1.7-9.1h-0.1 M618.6,346.9h-0.1
	l-2.2,0.7 M620.5,348.4l-1.9-1.5 M616.3,347.6l0.6,1.8 M615.2,363.7l-1.1-1.8 M616.7,349.4l2.6,9.8c-0.8,0.3-1.8,0.7-2.6,1.1l-5-8.8
	 M607.2,368.6l1.8,1.2 M614.1,361.9c-1.4,0.8-2.6,1.9-3.9,3.2l-1.1,1.2c-0.7,0.8-1.4,1.6-2.1,2.5 M615.2,363.7
	c-1.2,0.8-2.3,1.8-3.4,2.9l-1,1c-0.7,0.8-1.4,1.5-1.9,2.5 M616.3,347.6c-1.8,0.5-3.6,1.2-5.4,2.2 M616.7,349.4
	c-1.7,0.5-3.3,1.2-5,2.1 M618.6,346.9l2.9,13.9l3.7,13.6 M632.4,358.3l-4.8-0.4c-1.8,0-3.4,0.1-5.1,0.4 M634.4,348.4
	c-0.5,3.2-1.1,6.2-1.8,9.1l-0.1,0.8 M638.3,349.4l0.5-1.8 M629.8,374.4l4.4-16.9c0.7-2.9,1.4-5.8,1.9-8.8l0.3-1.8l-2.1,1.4
	 M639.4,372.5c0.7-2.1,1.5-3.7,2.2-5.1l1.1-1.5 M639.9,363.7c1,0.7,1.8,1.4,2.8,2.2 M639.9,363.7l1.2-1.9 M638.3,349.4l-2.8,9.8
	c1,0.3,1.8,0.7,2.6,1.1l5.1-8.8 M623,360.4c0.7,2.3,1.4,4.8,2.2,7l0.6,1.8 M629.2,369.2l0.5-1.8c0.8-2.3,1.5-4.7,2.1-7 M631.8,360.4
	l-4.4-0.4l-4.5,0.4 M625.6,369.2h3.6 M634.4,348.4c-2.2-0.4-4.5-0.7-6.9-0.7c-2.5,0-4.8,0.3-7,0.7 M632.4,358.3l-0.5,2.1 M626.6,373
	c0.3-0.3,0.5-0.4,0.8-0.4s0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4
	s-0.4-0.5-0.4-1C626.2,373.4,626.3,373.2,626.6,373L626.6,373z M625.6,372.5l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7s1.1,0.3,1.5,0.7l0.1,0.1
	 M629.1,375.5c-0.4,0.4-1,0.7-1.7,0.7s-1.2-0.3-1.7-0.7 M625.2,374.4l0.1,0.3l0.5,0.7 M625.6,372.5c-0.4,0.4-0.5,0.8-0.5,1.4v0.5
	 M625.9,375.5c-0.5,0.1-1.1,0.3-1.7,0.7 M629.8,374.4V374c0-0.5-0.1-1.1-0.5-1.5 M629.1,375.5c0.1-0.3,0.4-0.4,0.4-0.7l0.1-0.4
	 M630.9,376.1c-0.5-0.3-1.1-0.5-1.8-0.7 M630.9,376.1c0.5,0.3,1.1,0.7,1.7,1.2c0.4,0.5,0.8,1,1.2,1.5 M639.4,374l2.5-1.5
	 M641.7,372.5h-2.5 M639.4,372.5v1.5 M639.4,374c-0.1,0.1-0.4,0.3-0.7,0.3 M640.6,380.5l1.8-0.5 M635.4,379.9h0.6
	c0.5,0,1.1,0.1,1.5,0.5 M635.4,379.9l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.6 M637.2,382.2c0,0.3-0.1,0.7-0.4,0.8
	c-0.3,0.3-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,0.8-0.4
	c0.4,0,0.7,0.1,0.8,0.4C637.1,381.6,637.2,381.8,637.2,382.2L637.2,382.2z M637.5,380.5l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6
	c0,0.5-0.3,1.1-0.7,1.5l-0.1,0.1 M634.4,383.9c-0.4-0.5-0.7-1.1-0.7-1.6c0-0.7,0.3-1.2,0.7-1.6 M634.3,380.6
	c-0.1-0.5-0.4-1.1-0.7-1.6 M638.7,374.4l-3.2-3.2 M637.5,380.5h3.3 M633.8,378.8l5-4.5 M635.5,384.4l-1.1-0.5 M633.8,385.5
	c0.3-0.5,0.5-1.1,0.7-1.8 M635.5,384.4h0.4c0.6,0,1.1-0.1,1.5-0.5 M640.6,383.9l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3
	 M641.2,391.7c-0.7-0.4-1.2-0.8-1.9-1.1 M639.4,390.6l-0.7-0.4 M639.4,390.6v1.1 M641.2,391.7h-1.9 M637.5,383.9h3.2 M638.7,390.2
	l-5-4.5 M635.5,393.4l3.2-3.2 M625.9,389.1c-0.5-0.1-1.1-0.4-1.8-0.7 M625.9,389.1c0.5-0.5,1.1-0.8,1.7-0.8c0.7,0,1.2,0.3,1.7,0.7
	v0.1 M631,388.4c-0.6,0.3-1.1,0.5-1.7,0.7 M633.8,385.5c-0.3,0.5-0.7,1.1-1.2,1.7c-0.5,0.5-1,0.8-1.5,1.2 M629.4,392.1l-0.1,0.1
	c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.7-0.7l-0.1-0.1 M629.9,390.1v0.5c0,0.5-0.1,1.1-0.5,1.5 M629.4,389.1l0.5,0.7l0.1,0.3
	 M628.9,390.6c0,0.4-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-1,0.4c-0.3,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4C628.8,390.1,628.9,390.4,628.9,390.6L628.9,390.6z M625.9,392.1
	c-0.4-0.4-0.5-1-0.5-1.5v-0.4 M625.4,390.2l0.1-0.4c0.1-0.3,0.1-0.4,0.4-0.7 M640.6,383.9v-3.6 M642.6,379.9v4.7 M631,388.4l4.5,5
	 M635.4,371.1l-4.5,5 M605.6,386.9c-0.3-1.5-0.4-3-0.4-4.5c0-1.5,0.1-3,0.4-4.4 M603.5,377.4c-0.3,1.6-0.4,3.2-0.4,5
	c0,1.8,0.1,3.4,0.5,5.1 M605.6,377.8l-2.1-0.5 M594.4,371.7l9.9,2.8l1.1-2.6l-8.8-5.1 M619.6,380.2h-0.4c-0.5,0-1.1,0.1-1.5,0.5
	 M621.4,378.9c0.3-0.5,0.7-1.1,1.2-1.6c0.4-0.4,1-0.8,1.5-1.1 M620.7,384c0.5-0.5,0.7-1.1,0.7-1.6c0-0.7-0.3-1.2-0.7-1.6l-0.1-0.1
	 M617.9,382.4c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.5,0.4,0.8c0,0.3-0.1,0.7-0.4,1
	c-0.3,0.3-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-0.8-0.4C618.1,382.9,617.9,382.7,617.9,382.4L617.9,382.4z M620.7,380.7
	c-0.3-0.1-0.4-0.3-0.7-0.4l-0.4-0.1 M621.4,378.9l-0.7,1.6 M614.5,380.6l-0.7-0.1l-0.4-0.1c-2.6-1-5.2-1.8-7.8-2.5 M617.7,380.6
	h-3.2 M617.7,384.2l-0.1-0.1c-0.4-0.4-0.7-1-0.7-1.7c0-0.7,0.3-1.2,0.7-1.6l0.1-0.1 M616.4,374.4l4.8,4.5 M619.6,371.1l-3.2,3.2
	 M617.7,384.2h-3.3 M614.5,384.2l-0.7,0.1l-0.4,0.1c-2.6,1-5.2,1.8-7.8,2.5 M619.7,384.6h-0.5c-0.5,0-1-0.1-1.4-0.5 M619.7,384.6
	l0.3-0.1l0.7-0.5 M620.7,384c0.1,0.5,0.4,1.1,0.7,1.6 M624.3,388.6c-0.5-0.3-1.1-0.8-1.7-1.2c-0.4-0.4-0.8-1-1.2-1.5 M621.4,385.7
	l-5,4.5 M616.4,390.2l3.2,3.2 M621.4,385.7c-4.5,2.5-8.9,5-13.3,7.6l-1.8,1.1c-2.9,1.8-5.8,3.6-8.7,5.4l-1.5,1 M596.6,398.1l8.8-5
	l-1.1-2.6l-9.8,2.6 M605.6,386.9l-2.1,0.5 M592.2,391.5l13.8-3c4.8-1.1,9.4-2.5,13.8-3.7 M614.5,380.6v3.6 M616.4,390.2
	c-2.5,1.4-5.1,2.9-7.6,4.4 M609,369.9l7.4,4.5 M624.1,376.2l-4.5-5 M623,360.4l-0.5-2.1 M629.2,372.3v-3.2 M625.6,369.2v3.3
	 M619.6,393.4l4.5-5 M619.6,371.1l-4.4-7.6 M635.4,371.1l4.4-7.4 M644.2,349.8c-1.8-1-3.4-1.6-5.4-2.2 M643.2,351.4
	c-1.7-0.8-3.3-1.5-5-2.1 M642.6,384.6l4,1.2 M647.2,395.4l-1.1-0.7c-1.7-1.1-3.3-2.1-5-3 M642.4,379.9l3.6-1.2 M647.5,369l-5.8,3.4
	 M613.9,402.7c-1.2-1-2.5-1.9-3.7-3.2c-1.2-1.2-2.2-2.5-3.2-3.9 M608.6,411.3l0.4,2.2l1-1.4V412c1.9-2.8,3.7-5.5,5.5-8.4l1.1-1.8
	c2.8-4.4,5.2-8.8,7.7-13.3 M611.9,413.3l5.1-8.8c0.8,0.4,1.7,0.8,2.6,1.1l-2.8,9.8 M615,400.9l-1.1,1.8 M616.8,415.3l-0.4,1.8
	 M620.7,416.4l-1.9,1.2l3-13.6c1.1-4.7,2.3-9.4,3.7-13.8 M622.6,406.3c-0.4,1.9-0.8,3.7-1.1,5.6l-0.7,4.1l-0.1,0.1 M616.3,417
	l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1 M611.1,414.8l0.8-1.5 M608.4,411.3V411l5.5-8.4 M596.1,400.8
	c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4l4.7,2.9 M615.1,401c-1.2-0.8-2.3-1.8-3.3-2.9c-1.1-1.1-2.1-2.2-2.9-3.4 M611.1,414.8
	c1.7,0.8,3.4,1.6,5.4,2.2 M616.8,415.3c-1.7-0.5-3.3-1.2-5-1.9 M598.4,401.1c1.2,1.9,2.9,3.9,4.5,5.6c1.8,1.8,3.6,3.2,5.5,4.5
	 M625.9,395.4l-0.3,1c-1,2.6-1.8,5.2-2.5,7.8 M629.5,395.4l0.1,0.7l0.1,0.4c1,2.6,1.7,5.2,2.3,7.8 M632.7,406.3l-0.5-2.1
	 M632.1,404.2l-4.5,0.4l-4.4-0.4 M625.9,395.4h3.6 M632.7,406.3l-5.1,0.4l-5-0.4 M640,400.9c0.4,0.6,0.8,1.2,1.1,1.8 M643.3,413.3
	l-5-8.8c-0.8,0.4-1.7,0.8-2.6,1.1l2.6,9.8 M636.6,417.6l2.2-0.5 M636.6,417.6l-2.1-1.4 M634.6,416.2l-0.7-4.3l-1.2-5.6 M638.3,415.3
	l0.5,1.8 M620.7,416.4c2.2,0.4,4.5,0.5,6.9,0.5c2.5,0,4.8-0.3,7-0.7 M636.6,417.6l-3-13.8c-1.1-4.8-2.5-9.4-3.7-13.8 M623.2,404.2
	l-0.5,2.1 M647.9,395.9c-0.8,1.4-1.9,2.6-3.2,3.7c-1.2,1.2-2.5,2.2-3.8,3.2 M642.8,398.6l0.5,0.7c1.5-1.1,2.9-2.5,3.9-3.9
	 M647.9,395.9l-0.7-0.4 M646.1,413.7c0.8-0.5,1.8-1.1,2.5-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1 M656.6,401.4
	h-0.1l-8.4-5.5 M660,398.9l-1.5-1 M660,398.9l1.2-2.6l1-2.6 M641,402.7l5.4,8.5v0.1 M644.1,414.8l1.9-1.1h0.1 M644.1,414.8l-0.8-1.5
	 M646.4,411.4l-0.3,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.4,411.4c1.9-1.2,3.9-2.9,5.6-4.7c1.8-1.6,3.2-3.6,4.5-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.5,392.8c1.9,9.6,6.6,18.2,13.9,25.7l2.1,1.9
	c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15c1.4-1.5,2.8-2.9,4-4.4c5.1-6.3,8.4-13.3,9.9-21.2 M638.8,417
	c1.8-0.5,3.6-1.2,5.2-2.2 M642.8,398.6l-2.9,2.3 M643.2,413.3c-1.5,0.8-3.2,1.5-5,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.1,413.7l-7.6-12c-2.6-4.4-5.2-8.8-7.6-13.3 M595.1,399l1,1.8
	 M595.1,399l1.5-1 M607.1,395.7l-8.4,5.5l-0.3-0.1 M598.4,401.1l-2.3-0.3 M658.5,398.1c0.8-1.5,1.5-3.2,2.1-5 M639.4,391.7
	c1.1,2.9,2.2,5.2,3.4,6.9 M639.9,401l-4.4-7.6 M608.9,394.8l-1.8,1 M619.6,393.4L615,401 M625.9,392.1v3.3 M629.4,392.1l0.1,3.3
	 M594.5,393.1c0.5,1.6,1.2,3.3,2.1,5 M743.8,310.3l5.4,3.3l0.1,0.1c1.1,1.4,1.4,3,0.8,5l-1,2.9l-0.5,0.8l-1,0.5l-2.3,0.1l-19.1-4.4
	l-0.3-0.1c-11.6-2.9-23.5-6.2-36-10 M752.5,305.6l-5.2,2.5 M743.8,310.3l3.4-2.2 M762.9,317.7c-2.1-3-4.3-5.9-6.6-8.5
	c-1.2-1.2-2.5-2.5-3.9-3.6 M758.9,332l0.4-1.4 M759.8,329.4l-0.4,1.2 M759.3,330.7h1.2l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	s-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8 M757.4,336.6l-0.8,2.5c-0.1,0.8-0.3,1.6-0.3,2.5 M757.8,335.3h1.1 M758.9,336.6
	h-1.5 M757.8,335.3l-0.4,1.2 M758.9,336.6h2.2l0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1
	h-2.2 M758.9,342.8h2.5c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-2.5
	 M756.5,341.5l0.1,1.2 M756.6,342.8l0.1,0.7c0.5,1.5,1.2,2.9,1.8,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M762.9,317.7c-0.5,0.4-1.1,1.2-1.5,2.3l-5.9,18.8
	c-0.3,0.8-0.3,1.8-0.3,2.8l0.1,1.2l0.3,1.1v0.1l2.3,5l0.3,0.4c1.2,2.1,2.8,3.6,4.8,4.5h0.3 M758.9,341.5v-5 M756.5,341.5h2.5
	 M758.9,342.8h-2.3 M758.9,335.3V332 M758.9,332l-1.1,3.3 M747.2,308.1c-3.9-2.5-8-4.7-12.4-6.7c-35.5-15.8-76.1-28.6-121.7-38.1
	 M758.9,347.6v0.5 M758.5,347.6h0.4 M758.5,347.6l0.4,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M758.9,348.1l0.3,0.5l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M759.3,348.8h2.5c0.1,0,0.4,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	s-0.1-0.4-0.1-0.6c-0.1-0.1-0.3-0.1-0.4-0.1h-2.9 M763.2,361.7l-5.8-1.9c-0.8-0.1-1.2,0.1-1,1.1l1.2,6.7 M757.7,367.8l0.3,1.1
	 M743.4,360.9c1.5,0.8,2.9,2.1,4.3,3.7l3.2,4.8c1.5,2.5,2.8,5.1,3.9,7.8l0.1,0.3c0.1,1.1-0.4,1.8-1.7,2.1l-0.3,0.1l-4.5-0.3
	l-35.2-5.1c-2.3-0.5-4-1.8-5-3.6c-2.3-4.7-4.3-9.5-5.5-14.7c-0.7-2.2-0.4-3.9,0.5-4.7c1-0.8,2.6-1,5.1-0.4l19.7,4.3
	C734.2,356.7,739.3,358.4,743.4,360.9L743.4,360.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M744.8,367.8c-0.5-0.5-1-1.1-1.5-1.5l-22.4-3v2.3l24.3,3.3L744.8,367.8
	L744.8,367.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M714.4,356.8l13.3,2.8c6.1,1.2,11.1,3,15.1,5.2c0.8,0.4,1.5,1,2.2,1.5
	c0.5,0.5,1.1,1.1,1.7,1.8l0.5,0.8c0.7,1,1.4,1.9,1.9,3l0.1,0.1l1.4,2.5c0.7,1.1,1.2,2.3,1.8,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M752.6,378.4h0.1c0.4-0.1,0.7-0.3,0.7-0.5c-1.2-3-2.6-5.8-4.3-8.4
	l-2.5-3.7c-1.2-1.5-2.5-2.6-3.9-3.3c-4-2.3-9.1-4.1-15.1-5.4l-21.6-4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M748.8,374.5c-0.4-1-0.8-1.8-1.2-2.5l-26.5-3.6v2.2L748.8,374.5
	L748.8,374.5z M713.4,372.9l35.4,5.1v0.1l3.8,0.3 M758.8,373.7h0.7c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.3,0.1-0.5s0-0.3-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8 M758.5,372.3l0.3,1.4 M760.3,383.1c-1-0.7-1.9-1.1-3-1.2l-1.9-0.3 M760.3,383.1l-1.5-9.4
	 M752.2,393.5h1.8c1.8-0.1,3.4-0.4,4.8-1.1l3.2-1.5c1.1-0.5,1.9-1,2.6-1.4c0.5-0.3,0.8-0.8,0.8-1.6v-0.8 M752.2,393.5h-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M762.9,387.1l-3.7-3.3c-1.4-1.2-2.8-1.9-4-2.2 M747.9,393.5l-5.8,1.8
	l-13.6-1.8 M758.5,372.3l-0.5-3.4 M747.9,393.5h-19.4 M749,358.2c-4.5-3.4-12.7-6.5-24.3-9.2c-10.2-2.3-18.2-3.2-23.7-2.3L749,358.2
	L749,358.2z M706.1,352.3c-0.8-0.1-1.5,0-2.1,0.4c-0.5,0.5-0.5,1.5-0.1,3v0.1c1.2,5.1,3,9.9,5.4,14.4c0.7,1.5,2.1,2.3,3.8,2.8
	 M706.1,352.3c3,1.1,5.5,2.5,7.7,4.1l0.5,0.4 M716.4,372.3c0,0.3,0.1,0.5,0.6,0.5h0.7c0.4,0,0.5-0.1,0.5-0.5V359
	c0-0.3-0.1-0.5-0.5-0.5H717c-0.3,0-0.5,0.1-0.5,0.5 M716.4,358.9c-0.5-0.7-1.2-1.4-2.1-2.1 M713.4,372.9c1.1,0,2.1-0.3,3-0.7
	 M713.4,372.9h-0.1H713.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755.4,381.6l-42.6-6.3c-2.9-0.4-5.5-0.7-7.8-0.5h-11.7l67.3,12.4h2.6
	 M688,393l0.1,0.5h5.4 M693.5,393.5h3.7 M716.4,358.9v13.5 M758.9,347.6v-4.8 M728.5,393.5h-31.4 M759.8,329.4l2.9-9.1
	c0.3-0.7,0.5-1.2,1-1.6l-0.7-1 M758,368.9h5.2v-7.2 M763.2,361.7v-8 M763.2,353.8v-1.2c-1.7-0.8-2.9-1.9-3.9-3.6 M762.9,387.1h2.5
	 M697.2,393.5l44.2,3.3l10.9-3.3 M765.4,387.1l-5.1-4 M762.8,367.8h-5.1 M677.6,393H688 M689.7,308.5l-13.5-4.3 M231.5,302.2
	c8.8,3.3,16.8,8.1,24.1,14.7c7.2,6.3,12.5,13.3,16.5,21c-3.6-11.6-10.2-21.7-19.9-30.5c-8.4-7.7-17.9-13.2-28.5-16.4 M253.7,386.9
	h8.9 M262.6,386.9l6.2,0.1 M209.8,348.4c-0.6,3.2-1.1,6.1-1.8,9.1l-0.1,0.8 M214.2,347.6l-2.2-0.7h-0.1 M211.8,346.9l-2.1,1.5
	 M221.8,353.2l-0.4-2.3v0.1l-7.4,11.8l-2.8,4.7c-1.6,2.9-3.3,5.8-4.8,8.7 M219.4,349.8c-1.6-0.8-3.4-1.6-5.4-2.2 M218.6,351.4
	l0.8-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M234.1,363.5c-1.2-2.2-2.9-4.1-4.7-6.2l-0.8-1c-2.2-2.3-4.7-4.1-7.3-5.8
	h-0.1c-0.6-0.4-1.1-0.7-1.8-1 M216.4,361.9l5.4-8.5v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M223.3,368.6c-0.6-0.8-1.2-1.6-1.9-2.5l-1.1-1.2c-1.2-1.2-2.5-2.2-3.9-3.2
	 M223.3,368.6l-1.9,1.2 M221.3,369.9c-0.6-0.8-1.2-1.6-1.9-2.5l-0.8-1c-1.1-1.1-2.2-2.1-3.4-2.9 M215.1,363.7l1.2-1.8 M207.4,360.4
	c-0.7,2.3-1.4,4.8-2.2,7l-0.4,1.1l-0.1,0.5 M211.8,346.9l-2.9,13.9l-1.8,6.7c-0.7,2.3-1.2,4.7-1.9,7 M213.6,349.4l-2.6,9.8
	c1,0.3,1.8,0.7,2.6,1.1l5-8.8 M208,358.3l-0.6,2.1 M214.2,347.6l-0.6,1.8 M213.6,349.4c1.6,0.5,3.3,1.2,5,2.1 M231.8,363.3
	c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4c-1.6-1.6-3.4-3-5.2-4.3 M237.6,371.1l-0.7-1.8c-0.4-1.2-1-2.3-1.5-3.4 M235.4,365.7l-1.2-2.2
	 M231.8,363.3h-0.1c-2.8,1.8-5.5,3.7-8.4,5.5 M234.1,363.5l-2.3-0.3 M235.4,365.7l-1.5,1 M226.8,377.4c0.3,1.6,0.4,3.2,0.4,5
	c0,1.8-0.1,3.4-0.4,5.1 M224.8,377.8l2.1-0.4 M224.8,386.9c0.3-1.5,0.4-3,0.4-4.5c0-1.5-0.1-3-0.4-4.4 M233.9,366.7l-8.8,5.1
	c0.4,0.8,0.7,1.8,1.1,2.6l9.9-2.8 M236.7,389.4l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9c0-2.1-0.1-4-0.4-5.9l-0.1-1 M238.1,391.5
	c0.7-2.9,1.1-5.9,1.1-9.1c0-3.2-0.3-6.1-1-8.9l-0.6-2.3 M235.9,371.6l1.6-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.7,375.4l1.4-2.1l-13.8,3c-4.7,1.1-9.2,2.3-13.8,3.7 M236.7,375.4
	c-3.4,0.5-6.7,1.2-9.9,1.9 M237.6,393.7l0.6-2.2 M235.4,399c0.6-1.2,1.2-2.5,1.6-3.7l0.6-1.6 M238.1,391.5l-1.4-2.1 M235.8,393.1
	l1.8,0.5 M226.8,387.3c3.2,0.8,6.5,1.4,9.9,1.8l0.1,0.1 M226.8,387.3l-2.1-0.4 M233.7,398.1l-8.9-5c0.4-0.8,0.8-1.8,1.1-2.6l9.8,2.6
	 M238.1,391.5l-13.8-3c-4.7-1.1-9.4-2.5-13.8-3.7 M205.2,374.4v-0.5c0-0.5-0.1-1-0.6-1.4 M204.6,375.5c0.6,0.1,1.1,0.3,1.6,0.7
	 M206.2,376.1l0.1,0.1 M204.6,375.5l0.4-0.7l0.1-0.4h0.1 M213.9,374.4l-3.2-3.3 M212.7,380.6c-0.4-0.4-0.8-0.5-1.5-0.5h-0.4
	 M212.4,382.4c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8c0,0.3,0.1,0.7,0.4,1
	c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.7-0.1,0.8-0.4C212.4,382.9,212.4,382.7,212.4,382.4z M212.7,384.2l0.1-0.1c0.4-0.4,0.7-1,0.7-1.7
	c0-0.7-0.3-1.2-0.7-1.6l-0.1-0.1 M209.6,380.7c-0.6,0.4-0.7,1-0.7,1.6c0,0.5,0.1,1.1,0.6,1.7h0.1 M210.7,380.2l-0.4,0.1
	c-0.3,0.1-0.4,0.1-0.7,0.4 M209.1,378.9c0.3,0.5,0.6,1.1,0.7,1.6 M206.3,376.2c0.6,0.3,1.1,0.7,1.5,1.1c0.6,0.5,1,1.1,1.2,1.6
	 M206.3,376.2l4.4-5.1 M213.9,374.4l-4.8,4.5 M224.8,378c-2.6,0.7-5.2,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1 M216,384.2l0.7,0.1l0.4,0.1
	c2.6,1,5.2,1.8,7.8,2.5 M209,385.7c-0.3,0.6-0.7,1.1-1.1,1.5c-0.6,0.5-1.1,1-1.7,1.2 M206.2,388.6h-0.1 M206.1,388.6
	c-0.6,0.3-1.1,0.5-1.6,0.7 M210.6,384.6h0.6c0.6,0,1.1-0.1,1.4-0.5 M209.6,384c-0.1,0.5-0.4,1.1-0.7,1.6 M209.1,385.7H209
	 M209.6,384l0.7,0.5h0.3 M213.9,390.2l-3.2,3.2 M204.4,392.1c0.3-0.4,0.6-1,0.6-1.5v-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M205,390.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.4-0.4-0.7 M209,385.7
	l5,4.5 M210.7,393.4l-4.5-4.8 M209.1,378.9c4.5-2.5,8.9-5.1,13.2-7.7l11.8-7.7 M212.7,384.2h3.3 M216,380.6h-3.3 M221.5,394.8
	c-2.5-1.5-5.1-3-7.6-4.4 M209.1,385.7c4.5,2.5,8.9,5,13.3,7.6l1.8,1.1l8.7,5.4l1.5,1 M216,380.6v3.6 M204.7,372.5v-3.3 M221.3,369.9
	l-7.4,4.5 M235.9,371.6c-0.6-1.8-1.2-3.4-2.1-5 M210.7,371.1l4.4-7.4 M373.9,379.8l-74.1-1.9c-7.4-0.3-13.2-2.8-17.1-7.3
	c-17.5-20.8-34.5-43.6-51.3-68.4 M204.4,395.5l0.3,1c1,2.6,1.8,5.2,2.5,7.8 M218.4,413.3l-5.1-8.8c-0.8,0.4-1.6,0.8-2.5,1.1l2.8,9.8
	 M207.2,404.2l0.6,2.1 M205,390.2c1.4,4.4,2.6,8.9,3.7,13.8c0.1,0.7,0.4,1.4,0.4,2.1l2.5,11.6l-1.9-1.2 M216.4,402.7
	c1.2-1,2.6-1.9,3.7-3.2c1.2-1.2,2.2-2.5,3.2-3.9 M221.5,394.8c-0.8,1.2-1.8,2.3-2.9,3.4c-1.1,1.1-2.2,1.9-3.4,2.9 M215.3,401
	l1.1,1.8 M214.1,417c1.8-0.5,3.6-1.2,5.4-2.2 M219.4,414.8l-1-1.5 M221.9,411.3V411l-5.5-8.3 M206.1,388.6c2.5,4.5,5.1,8.9,7.8,13.3
	l7.6,11.7l0.3-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M207.7,406.3c0.4,1.9,0.8,3.7,1.2,5.6l0.7,4.1l0.1,0.1 M213.6,415.3
	l0.4,1.8 M213.6,415.3c1.6-0.5,3.3-1.2,5-1.9 M231.9,401.1c-1.2,1.9-2.9,3.9-4.7,5.6c-1.6,1.8-3.6,3.2-5.5,4.5 M231.9,401.1h-0.1
	c-2.8-1.8-5.6-3.6-8.4-5.4 M234.3,400.8l1.1-1.8 M219.4,414.8l4.5-2.9c1.6-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3 M234.3,400.8
	l-2.3,0.3 M235.4,399l-1.6-1 M204.4,392.1v3.3 M233.7,398.1c0.8-1.6,1.5-3.3,2.1-5 M223.3,395.7l-1.8-1 M210.7,393.4l4.5,7.6
	 M376.2,354l0.4,7.6 M376.6,361.6l-2.8,18.2 M376.6,361.6c33.3,4.1,65.8,7,97.5,8.5l78.8,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.2,354l-87.8-5.9c30,5.5,59.4,9.9,88.3,13.6 M268.8,387.1l293.1,7.7
	 M589.7,258.4c-38.8-23.1-79.1-42.9-121.1-59.4 M375.1,288.1c1.1,21.9,1.5,43.9,1.1,65.9 M223.7,291l7.8,11.1 M526,242.2l-3.8-1.9
	 M431.3,288.3l128.1,15.1 M558.5,315.1c-59.6-9.2-120.6-18.2-183.4-27 M553.8,279.2l5.5,24.2 M554,365.9L376.2,354 M583.4,306.2
	c12.2-7.7,26.1-11.6,41.8-11.6c18,0,33.7,5.1,47.2,15.1 M522.2,240.3l2.5,14.2 M206.3,187.6l-11.3,1.8 M212.4,265.5l-12.8-0.7
	l13.2,1.6 M204.7,369.2h-3.6 M204.4,395.5H201"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M540.6,274.3l4.1,12.1c0,0,1.2,3.2-1.1,5.6
	c0,0-2.3,2.2-4.8,2.9c0,0-6.6,1.5-9.2-6.2l-3.4-9.9c0,0-0.1-1.5-2.1-1.6l-8.8-0.8c0,0-6.1-0.1-6.7-7.3v-5.2c0,0-0.1-4,2.9-6.2
	c0,0,4.5-4.4,10.5-3.7c0,0,7.4,0.7,13.6,5.1c0,0,3.7,2.5,5.6,5c0,0,1.7,1.4,0.8,3l-1.4,4.8C540.6,271.9,540.1,273.2,540.6,274.3
	L540.6,274.3z"
          />
          <path
            fill="#2BACE2"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M532.6,257.3l-1-4.7c0,0-0.4-1.4,0.4-1.9
	c0,0,1-0.1,1.9,0.3c0,0,24.8,13.2,36.2,23.2c0,0-1.9,1.1-11,0.1l-19-2.1l1.5-5.4c0,0,0.8-1.5-0.8-3
	C541.2,263.9,538.6,260.5,532.6,257.3L532.6,257.3z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M652.6,327.8
		l4.4-4.4l11.3-11.1l4.1-2.3l4.7-5.2c0,0-3-1.1-18.4-8.5c0,0-13.2-6.6-24.5-12.8l-43.1-5.9h-0.6c0,0-9.9,5.5-24.5,3.2l-12.2-1.2
		l5.5,24.1l-0.8,11.7c0,0-1.2,21.6-4.5,50.9l-1.1,10l6.9,6.5l2.2,12.4l6.2,0.1l0.8-15.8c0,0,0.1-21.3,18.2-39.2
		c0,0,15.1-17.2,40.6-17.1C627.6,322.5,640.9,322,652.6,327.8z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M553.8,279.2l-12-1.2
		l2.9,8.5c0,0,1.1,2.8-1,5.2c0,0-2.3,2.5-5,3.2c0,0-6.3,1.2-8.9-5.4l-3.7-10.6c0,0-0.3-1.5-2.1-1.7c-1.8-0.1-8.8-0.8-8.8-0.8
		s-2.1,0-4-1.5l-139.8-14.2l3.2,21c0,0,1.8,25.2,1.5,72.4l0.4,7.6l-2.8,18.2l171.4,4.5c0,0,3.9,0.4,5.8-1.8c0,0,1.2-1,1.7-6.5
		l1.1-10.2c0,0,3.4-29.3,4.5-50.8l0.8-11.7L553.8,279.2z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M520.1,253.9
		l-2.8-16.1c0,0-32.7-16.8-71.7-27.2c0,0-31-9.4-72.5-11.8l12.1,60l123.8,12.5c0,0-0.4-1.5-0.4-2.3v-5.4c0,0,0-4,2.9-6.1
		C511.6,257.7,515.7,253.9,520.1,253.9z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M357.4,256.1
		l-5.6-57.6c0,0-48-1.8-107.7,8.4l-19,36.5L357.4,256.1z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M210.6,234.6
		c0,0,1,1.4,2.2,0l10.6-18.3c0,0,0.7-1.1-0.8-1.1l-20.9,5c0,0-1.2,0.1-1.2,1c0,0-0.1,0.7,0.1,1.1
		C200.7,222.4,206.3,230.5,210.6,234.6z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M371.7,260.6l3.2,21
		l0.3,6.5c0,0,1.5,37.7,1.1,65.9l0.4,7.6l-2.8,18.2l-72.5-1.9c0,0-9.1,0.1-13.9-3.3c0,0-2.5-1.4-4.3-3.4l-4.8-5.9
		c0,0-17.7-20.5-54.6-74.1c0,0-1-1.1-2.3-4.3l-8.9-21.2c0,0-0.1-8.9,3.7-20.5l3.3,0.6L371.7,260.6z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M171.4,328.7
		l-3.4-4.7l-8.9-14.9l-12.5-23.2l-3.2-13.5c0,0,12.9-4.5,16.8-7c0,0,2.9-1.8,4.8-5.2l16-23.9c0,0,1.5-2.6,0.6-6.2l-1.9-7.4l12.4-4.3
		c0,0,0.1,1.1,1.4,3c1.2,1.9,2.8,4.3,7.4,10.6c0,0,4.8,6.2,8.7,9.4c0,0,3.3,2.6,7.2,3.7c0,0-3.7,10.5-3.7,20.5l9.5,22.6
		c0,0,0.8,1.6,1.8,3c0,0,23.1,33.8,34.5,48.6c0,0,11.7,15.7,22.8,29h-14.3l-5.2,4.5c0,0-1.1-9.6-5.1-18.4c0,0-12.1-27.1-39.6-33.6
		C217.1,321.3,193.1,314.7,171.4,328.7z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="627.4"
              cy="382.8"
              r="51.2"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="203.2"
              cy="382.4"
              r="51.2"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M541.5,277.6l-1.1-3.5
		c0,0-0.4-0.8,0.1-2.9l1.2-4.3c0,0,0.9-1.5-0.8-3c0,0-2.7-3.7-8.4-6.7c0,0-4.9-2.8-11.3-3.3c0,0-3-0.2-6.1,1.5c0,0-3.6,1.9-5,3.8
		c0,0-1.2,1.9-1.4,4.5v5.2c0,0-0.1,3,1.5,4.9c0,0,1.6,2.2,4.4,2.7l8.8,0.7c0,0,1.9-0.1,2.5,1.2l3.8,10.8c0,0,1.1,3.4,4,4.9
		c0,0,2.3,1.4,5.2,0.9c0,0,3-0.9,5.1-3.5c0,0,1.9-2.4,0.7-5.1l-0.7-2.2L541.5,277.6z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback4DrRight);
