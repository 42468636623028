import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
/* 2024.7.18 - Note:  this doesn't seem to be used anywhere */
class CustomToggle extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

class CustomMenuItem extends Component {
  handleClick(item) {
    this.props.clickHandler(item);
  }

  render() {
    const { label, eventKey } = this.props;
    return (
      <MenuItem onSelect={() => this.handleClick(label)} eventKey={eventKey}>
        {label}
      </MenuItem>
    );
  }
}

class DropDownMenu extends Component {
  render() {
    const { identifier, toggleLabel, menus, clickHandler } = this.props;
    return (
      <Dropdown
        id={`custom-dropdown-${identifier}`}
        className="custom-dropdown-menu"
      >
        <CustomToggle bsRole="toggle">{toggleLabel}</CustomToggle>
        <Dropdown.Menu bsRole="menu">
          {menus.map((item, index) => (
            <CustomMenuItem
              key={index}
              label={item}
              eventKey={index + 1}
              clickHandler={item => clickHandler(item)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default DropDownMenu;
