import React, { Component } from 'react';

class UpChevron extends Component {
  render() {
    const { size = 20, color = '#fff' } = this.props;
    return (
      <svg
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 9L9 1L1 9"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}

export default UpChevron;
