import React, { Component } from 'react';
import Modal from 'react-modal';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import { RED_03, GREY_06 } from '../../constants/colors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadLots } from '../../../../actions';
class SuccessErrorMessageModal extends Component {
  onClose = () => {
    this.props.close();
  };
  render() {
    const { open, message } = this.props;
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        zIndex: 99,
        position: 'absolute',
        padding: '24px',
        borderRadius: '8px',
        border: 'none',
        boxShadow:
          '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    };
    return (
      <div>
        <Modal
          isOpen={open}
          shouldCloseOnEsc
          style={customStyles}
          contentLabel={'getAutograde'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            <div
              className="flex items-center justify-between mb-4 pb-3 "
              style={{ borderBottom: '1px solid #E5E5E6' }}
            >
              <div className="flex items-center justify-between">
                <Icon name="ErrorIcon" />

                <div
                  className="pl-4 text-lg font-semibold"
                  style={{ color: RED_03 }}
                >
                  Something went wrong
                </div>
              </div>

              <div
                className="flex justify-center items-center"
                onClick={this.onClose}
              >
                <Icon name="Cross" size={28} color={GREY_06} />
              </div>
            </div>
            <div
              className="fs-16 text-base font-normal mb-8"
              style={{ color: GREY_06 }}
            >
              {message}
            </div>

            <div className="flex justify-end modal-mt">
              <div className="modal-single-button-width">
                <Button
                  btnText="Cancel"
                  outlined={true}
                  fontSize="16px"
                  borderRadius="10px"
                  onClickHandler={this.onClose}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SuccessErrorMessageModal.propTypes = {
  loadLots: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { lots } = state.core;
  return {
    lots,
  };
};

export default connect(mapStateToProps, { loadLots })(SuccessErrorMessageModal);
