import React, { Component } from 'react';
import SearchIcon from './SearchIcon';
import InfoIcon from './InfoIcon';
import CrossIcon from './CrossIcon';
import LeftArrowIcon from './LeftArrowIcon';
import RightArrowIcon from './RightArrowIcon';
import HorizontalEllipsisIcon from './HorizontalEllipsisIcon';
import CheckmarkIcon from './CheckmarkIcon';
import DetailsIcon from './DetailsIcon';
import PictureIcon from './PictureIcon';
import QuestionIcon from './QuestionIcon';
import TagIcon from './TagIcon';
import EquipmentIcon from './EquipmentIcon';
import AdditionalIcon from './AdditionalIcon';
import CarIcon from './CarIcon';
import InformationIcon from './Information';
import HeavyTruck from './HeavyTruck';
import ChevronRightIcon from './ChevronRightIcon';
import FileUploadIcon from './FileUploadIcon';
import FileUploadCloudIcon from './FileUploadCloudIcon';
import HambergerMenu from './HambergerMenu';
import UpChevron from './UpChevron';
import DownChevron from './DownChevron';
import ErrorIcon from './ErrorIcon';
import CircledCheckmarkIcon from './CircledCheckmarkIcon';
import CircledExclamationmarkIcon from './CircledExclamationmarkIcon';
import BinIcon from './BinIcon';
import IncompleteStatusIcon from './IncompleteStatusIcon';
import CompleteStatusIcon from './CompleteStatusIcon';
import ReviewIcon from './ReviewIcon';
import WheelIcon from './WheelIcon';
import ItemsIcon from './ItemsIcon';
import SaveIcon from './SaveIcon';
import BarcodeIcon from './BarcodeIcon';
class Icon extends Component {
  renderIcon() {
    switch (this.props.name) {
      case 'Search':
        return <SearchIcon {...this.props} />;
      case 'Info':
        return <InfoIcon {...this.props} />;
      case 'Cross':
        return <CrossIcon {...this.props} />;
      case 'LeftArrow':
        return <LeftArrowIcon {...this.props} />;
      case 'RightArrow':
        return <RightArrowIcon {...this.props} />;
      case 'HorizontalEllipsis':
        return <HorizontalEllipsisIcon {...this.props} />;
      case 'Checkmark':
        return <CheckmarkIcon {...this.props} />;
      case 'Details':
        return <DetailsIcon {...this.props} />;
      case 'Information':
        return <InformationIcon {...this.props} />;
      case 'Media':
        return <PictureIcon {...this.props} />;
      case 'Question':
        return <QuestionIcon {...this.props} />;
      case 'Pricing':
        return <TagIcon {...this.props} />;
      case 'Equipment':
        return <EquipmentIcon {...this.props} />;
      case 'Additional':
        return <AdditionalIcon {...this.props} />;
      case 'Condition':
        return <CarIcon {...this.props} />;
      case 'HeavyTruck':
        return <HeavyTruck {...this.props} />;
      case 'ChevronRight':
        return <ChevronRightIcon {...this.props} />;
      case 'FileUpload':
        return <FileUploadIcon {...this.props} />;
      case 'FileUploadCloud':
        return <FileUploadCloudIcon {...this.props} />;
      case 'HambergerMenu':
        return <HambergerMenu {...this.props} />;
      case 'UpChevron':
        return <UpChevron {...this.props} />;
      case 'DownChevron':
        return <DownChevron {...this.props} />;
      case 'ErrorIcon':
        return <ErrorIcon {...this.props} />;
      case 'CircledCheckmark':
        return <CircledCheckmarkIcon {...this.props} />;
      case 'CircledExclamationmark':
        return <CircledExclamationmarkIcon {...this.props} />;
      case 'Bin':
        return <BinIcon {...this.props} />;
      case 'IncompleteStatus':
        return <IncompleteStatusIcon {...this.props} />;
      case 'CompleteStatus':
        return <CompleteStatusIcon {...this.props} />;
      case 'ReviewIcon':
        return <ReviewIcon {...this.props} />;
      case 'Wheel':
        return <WheelIcon {...this.props} />;
      case 'ConditionItems':
        return <ItemsIcon {...this.props} />;
      case 'Save':
        return <SaveIcon {...this.props} />;
      case 'Barcode':
        return <BarcodeIcon {...this.props} />;
      default:
        return null;
    }
  }

  render() {
    const { marginRight, marginLeft } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          marginRight,
          marginLeft,
        }}
      >
        {this.renderIcon()}
      </div>
    );
  }
}

export default Icon;
