import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import { loadBuyerFees } from '../../actions';
import { getConfig, commafyCurrency } from '../../utils/helpers';

class Fees extends Component {
  state = {
    buyFees: -1,
    facilitationFees: -1,
  };

  componentDidMount() {
    if (Number(this.props.amount) && this.props.itemId) {
        this.setFees();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.amount !== prevProps.amount ||
      this.props.type !== prevProps.type
    ) {
      this.setFees();
    }
  }

  setFees() {
    
    if (this.props.amount) {
      this.props
        .loadBuyerFees(
          getConfig('marketplaceId'),
          this.props.itemId,
          this.props.amount,
          this.props.type,
          this.props.userId,
        )
        .then(({ response }) => {
          if (response) {
            this.setState({ 
              buyFees: response.buyFee, 
              facilitationFees: response.facilitationFee,
            });
          }
          else {
            toastr.error('Error', response.wsMessage);
          }
        });
    }
  }

  renderFeeRow = (label, value) => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between', padding: 3 }}
      >
        <div style={{ fontWeight: 'bold' }}>{label}</div>
        <div style={{}}>{value}</div>
      </div>
    );
  };

  render() {
    if (this.state.buyFees < 0 && this.state.facilitationFees < 0) {
      return null;
    }

    if (this.state.buyFees === 0 && this.state.facilitationFees === 0) {
      return (
        <div style={{ margin: 5, marginBottom: 20 }}>
          <label>* Marketplace fees may apply.</label>
        </div>
      );
    }

    if (this.props.amount && (this.state.buyFees || this.state.facilitationFees)) {
      return (
        <div style={{ marginBottom: 10 }}>
          <label>Fees</label>
          <div
            style={{ padding: 10, border: '1px solid #ccc', borderRadius: 4 }}
          >
            {this.renderFeeRow('Amount', commafyCurrency(this.props.amount))}
            {this.state.buyFees ? (this.renderFeeRow('Buy Fee', commafyCurrency(this.state.buyFees))) : null}
            {this.state.facilitationFees ? this.renderFeeRow('Facilitation Fee', commafyCurrency(this.state.facilitationFees)) : null}
            <hr style={{ marginTop: 5, marginBottom: 5 }} />
            {this.renderFeeRow(
              'Total',
              commafyCurrency(
                Number(this.props.amount) + Number(this.state.buyFees) + Number(this.state.facilitationFees)
              )
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

Fees.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    loadBuyerFees,
  }
)(Fees);
