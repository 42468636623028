import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback2DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M701.8,166.5c0,0-9.5-6.1-12-7s-12.4-3.9-16.4-4.5c-4-0.7-37.6-1.8-40.9-2.1c-3.4-0.3-52.1-0.7-52.1-0.7
	l-14.6,1.5l-66.9-1.8l-13.1-0.1c-0.4-0.1-0.8-0.4-1.5-0.6c0,0,0-0.1-4-1.2c-2.8-0.8-8-2.2-12.7-2.2c-9.8,0-15.4,3.3-15.4,3.3
	s-0.3,0.3-0.7,0.6l-48-0.3l-76.8,0.3c0,0,4.5-12,5-13.1c0.4-1.1-0.3-2.2-2.2-2.9c-2.1-0.7-9.2,1.1-11.4,2.5
	c-2.2,1.4-9.9,14.9-9.9,14.9l-25.9,1c0,0-9.9-2.2-13.1-2.5c-3.2-0.3-48.6,0-63.3,0.4c-14.9,0.4-45.5,4-50.5,5.6
	c-1.6,0.6,1.1,0.7-4.8,2.2c-5.8,1.5-27.4,11-29.8,12.5s-10.7,10.7-25.2,36.6c-14.3,25.9-13.1,81-13.1,81s-1.4,55.2,13.1,81
	c14.4,25.9,22.7,35.1,25.2,36.6c2.5,1.5,24.1,11,29.9,12.5s3,1.6,4.8,2.2c5,1.6,35.6,5.2,50.5,5.6c14.9,0.4,60.3,0.7,63.3,0.4
	c3.2-0.3,13.1-2.5,13.1-2.5l25.9,1c0,0,7.6,13.5,9.9,14.9c2.2,1.4,9.5,3.2,11.4,2.5c2.1-0.7,2.8-1.8,2.2-2.9c-0.4-1.1-5-13.1-5-13.1
	l76.8,0.3l48-0.3c0.3,0.3,0.7,0.5,0.7,0.5s5.6,3.3,15.4,3.3c4.7,0,9.8-1.4,12.7-2.2l4-1.2c0.5-0.3,1.1-0.4,1.5-0.7l13.1-0.1
	l66.9-1.8l14.6,1.5c0,0,48.7-0.4,52.1-0.7c3.3-0.3,36.9-1.4,40.9-2.1c4-0.7,13.9-3.6,16.4-4.5c2.5-1,12-7,12-7s22.7-4,33.7-16.8
	c11-12.8,23.8-31.8,23.8-106.6c0-74.7-12.8-93.8-23.8-106.6C724.5,170.5,701.8,166.5,701.8,166.5L701.8,166.5z"
          />
          <path
            fill="#EBEBEB"
            d="M738.9,289.9c0,20.5-1.9,34.4-4.1,51c-2.2,16.6-12.7,42.8-12.7,42.8s-40.4-4.1-50.1-4.8
	c-9.6-0.7-43.5-3.2-43.5-3.7s7.3-28.1,9.6-42.2c2.3-14.2,3.4-42.9,3.4-42.9s-1.1-28.8-3.4-42.9s-9.6-41.7-9.6-42.2s33.8-3,43.5-3.7
	c9.6-0.7,50.1-4.8,50.1-4.8s10.5,26.1,12.7,42.8C736.9,255.5,738.9,269.6,738.9,289.9L738.9,289.9z M316.5,166.3
	c0,0,74.4-0.4,86.9-0.6c12.5-0.3,93,1.5,93,1.5l3.7,27.4l-13.9,0.4c0,0-43.2,0.3-74.6-1.4s-88-14.7-88-14.7
	C322.6,177.3,318.7,169.3,316.5,166.3L316.5,166.3z"
          />
          <path
            fill="#EBEBEB"
            d="M500.2,194.6c0,0,53.8-2.6,80.6-6.1c26.8-3.4,61.4-8.5,62-8.5s5.8,0.1,0-1.9s-19.9-5-22.3-5.1
	c-2.3-0.1-47-2.8-50.3-2.9c-3.3-0.1-73.7-3-73.7-3L500.2,194.6L500.2,194.6z M316.5,413.6c0,0,74.4,0.4,86.9,0.5
	c12.5,0.3,93-1.5,93-1.5l3.7-27.4l-13.9-0.4c0,0-43.2-0.3-74.6,1.4c-31.4,1.6-88,14.7-88,14.7C322.6,402.7,318.7,410.6,316.5,413.6
	L316.5,413.6z"
          />
          <path
            fill="#EBEBEB"
            d="M500.2,385.3c0,0,53.8,2.6,80.6,6.1c26.8,3.4,61.4,8.5,62,8.5s5.8-0.1,0,1.9c-5.8,2.1-19.9,5-22.3,5.1
	c-2.3,0.1-47,2.8-50.3,2.9c-3.3,0.1-73.7,3-73.7,3L500.2,385.3L500.2,385.3z M223.3,289.9c0,19.8,2.1,39.6,6.2,64.1
	c4.1,24.6,20.6,45.9,20.6,45.9s0.7,0.4,1,0.5c6.5,3.6,28.2,1.4,31.6,0.8c3.4-0.4,82.8-17.5,93-19.5c10.2-2.1,7-7,7-7
	s-3.9-14.7-8-34.8c-4.1-19.9-3.4-50.1-3.4-50.1s-0.7-30,3.4-50.1c4.1-20.1,8-34.8,8-34.8s3.2-5-7-7s-89.6-19.1-93-19.5
	c-3.4-0.4-25-2.8-31.6,0.8c-0.3,0.1-1,0.6-1,0.6s-16.5,21.3-20.6,45.9C225.3,250.3,223.3,270.1,223.3,289.9L223.3,289.9z
	 M102.2,221.4c0,0,7-19.1,13.1-28.6c6.1-9.6,12.8-18.6,12.8-18.6s9.2-1.5,12.1-1.7c2.9-0.1,15-0.8,15-0.8
	c-1.6,2.5-32.7,33.6-35.6,36.9C116.7,211.7,102.2,221.4,102.2,221.4L102.2,221.4z M102.2,358.4c0,0,7,19.1,13.1,28.6
	c6.1,9.6,12.8,18.6,12.8,18.6s9.2,1.5,12.1,1.6c2.9,0.1,15,0.8,15,0.8c-1.6-2.5-32.7-33.6-35.6-36.9
	C116.7,368.2,102.2,358.4,102.2,358.4L102.2,358.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M102.2,221.4
	c0,0,7-19.1,13.1-28.6c6.1-9.6,12.8-18.6,12.8-18.6s9.2-1.5,12.1-1.7c2.9-0.1,15-0.8,15-0.8c-1.6,2.5-32.7,33.6-35.6,36.9
	C116.7,211.7,102.2,221.4,102.2,221.4L102.2,221.4z M371.4,289.9c0,0-0.7-30,3.4-50.1c4.1-20.1,8-34.8,8-34.8s3.2-5-7-7
	s-89.7-19.1-93-19.5c-3.4-0.4-25-2.8-31.6,0.8c-0.3,0.1-1,0.6-1,0.6s-16.5,21.3-20.6,45.9s-6.2,44.4-6.2,64.1 M326.9,152
	c0,0-4.1,10.2-4.8,11.8c-0.8,1.8-1.9,1.4-1.9,1.4s-2.8,0-3.7,0.1c-1,0.1-0.5,1.4-2.6,1.9s-4.8-1.1-6.7-3c-1.9-1.9-0.7-5.9-0.5-6.6
	c0.1-0.6,1.9-4.4,1.9-4.4s7.7-13.8,9.9-15.1c2.2-1.4,9.5-3.2,11.4-2.5c2.1,0.7,2.8,1.8,2.2,2.9C331.5,139.9,326.9,152,326.9,152
	L326.9,152z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M315.8,165.6
	c-0.4,0.4-0.5,1.2-2.1,1.6c-2.1,0.6-4.8-1.1-6.7-3c-1.2-1.2-1.2-3.7-1-5.2l-4.5,6.6l-2.2,0.3l6.7,9.8l17.5,3.4
	C323.5,179.1,318,168.1,315.8,165.6L315.8,165.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M299.5,166l-24.9,0.4
	c0,0-3.9,0.4-4.5,1.2c-0.7,0.8-0.3,0.8,1.4,1.2c1.6,0.6,34.8,6.7,34.8,6.7L299.5,166L299.5,166z M316.5,166.3c0,0,74.4-0.4,86.9-0.6
	c12.5-0.3,93,1.5,93,1.5l3.7,27.4l-13.9,0.4c0,0-43.2,0.3-74.6-1.4s-88-14.7-88-14.7C322.6,177.3,318.7,169.3,316.5,166.3
	L316.5,166.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M495.4,167.2
	c0,0,0.7-5.6,1.8-7.2c1.1-1.5,2.6-5.2,2.5-6.2c-0.1-1,0-1.5,0-1.5 M500.2,194.6c0,0,53.8-2.6,80.6-6.1c26.8-3.4,61.4-8.5,62-8.5
	s5.8,0.1,0-1.9s-19.9-5-22.3-5.1c-2.3-0.1-47-2.8-50.3-2.9c-3.3-0.1-73.7-3-73.7-3L500.2,194.6L500.2,194.6z M721.7,195.2l0.5,1.1
	c0,0-40.4,4.1-50.1,4.8c-9.6,0.7-44,3.6-44.4,3.6h-7.3c0,0-2.3,1.6-3.2-3.2c-1-5-2.3-9.5,4.3-10.3c6.7-1,57.5-8.5,68-8.8
	c10.5-0.3,16.1-1.9,20.1,1C713.7,186.2,719.9,192.3,721.7,195.2L721.7,195.2z M289.3,153.8c0,0,2.3,5.2,2.5,6.9s0.4,5.2,0.4,5.2
	 M698.7,164.6c0,0-10.3-0.6-12.4-1s-48.3-5-53.4-5.1c-5.1-0.1-66.7-4.7-66.7-4.7l14.4-1.5c0,0,48.7,0.4,52.1,0.7
	c3.3,0.3,36.9,1.4,40.9,2.1c4,0.7,13.9,3.6,16.4,4.5C691.4,160.1,695.7,162.7,698.7,164.6L698.7,164.6z M477.6,149.3l0.1,5
	 M479.7,154.2c8.1-0.4,8.4-0.6,8.4-0.6s0.7-0.6-3.6-2.2c0,0,0-0.1-4-1.2c-2.8-0.8-8-2.2-12.7-2.2c-9.8,0-15.4,3.3-15.4,3.3
	s-1.6,1.1-1.4,1.7s1.4,1.8,18.2,1.9C473.5,154.8,476.9,154.3,479.7,154.2L479.7,154.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M668.6,154.7
	c0,0,10,5.6,11.3,7c1.2,1.4,1.7,1.5,1.7,1.5s12.8,2.8,15.7,3.7c2.9,0.8,13.8,4.1,13.8,4.1 M128.2,174.1l3.3-4.4c0,0,0.1-0.4,2.8-1.5
	c2.6-1,11.7-4.8,16.9-5.8c5.2-1,10.5-1.5,13.3-2.3c2.8-0.8,10.2-5.4,10.2-5.4 M607.1,203l10.6-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M759.3,289.9
	c0,74.7-12.8,93.8-23.8,106.6c-11,12.8-33.7,16.8-33.7,16.8s-9.5,6.1-12,7s-12.4,3.9-16.4,4.5c-4,0.7-37.6,1.8-40.9,2.1
	c-3.4,0.3-52.1,0.7-52.1,0.7l-14.6-1.5l-66.9,1.8l-95.2,0.7l-76.8-0.3c0,0,4.5,12,5,13.1c0.4,1.1-0.3,2.2-2.2,2.9
	c-2.1,0.7-9.2-1.1-11.4-2.5c-2.2-1.4-9.9-14.9-9.9-14.9l-25.9-1c0,0-9.9,2.2-13.1,2.5c-3.2,0.3-48.6,0-63.3-0.4
	c-14.9-0.4-45.5-4-50.5-5.6c-1.6-0.5,1.1-0.7-4.8-2.2c-5.8-1.5-27.4-11-29.9-12.5c-2.5-1.5-10.7-10.7-25.2-36.6
	c-14.3-25.9-13.1-81-13.1-81s-1.4-55.2,13.1-81c14.2-25.9,22.6-35.1,25-36.7c2.5-1.5,24.1-11,29.9-12.5c5.8-1.5,3-1.6,4.8-2.2
	c5-1.7,35.6-5.2,50.5-5.6c14.9-0.4,60.3-0.7,63.3-0.4c3.2,0.3,13.1,2.5,13.1,2.5l25.9-1c0,0,7.6-13.5,9.9-14.9
	c2.2-1.4,9.5-3.2,11.4-2.5c2.1,0.7,2.8,1.8,2.2,2.9c-0.4,1.1-5,13.1-5,13.1l76.8-0.3l95.2,0.7l66.9,1.8l14.6-1.5
	c0,0,48.7,0.4,52.1,0.7c3.3,0.3,36.9,1.4,40.9,2.1c4,0.7,13.9,3.6,16.4,4.5c2.5,1,12,7,12,7s22.7,4,33.7,16.8
	C746.6,196.3,759.4,215.2,759.3,289.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M223.3,289.9
	c0,19.8,2.1,39.6,6.2,64.1c4.1,24.6,20.6,45.9,20.6,45.9s-2.3,2.9-2.8,3.9s-5.6,4.3-8.9,6.6c-3.2,2.3-14,2.1-14,2.1
	s-61.5-3.6-69.2-4.3c-1.6-2.5-32.7-33.7-35.8-36.9s-17.3-12.9-17.3-12.9s-1.8-3.9-2.8-6.6c-1-2.8-3.9-61.9-3.9-61.9
	s2.9-59.2,3.9-61.9c1-2.8,2.8-6.6,2.8-6.6s14.3-9.8,17.3-12.9c2.9-3.2,34.1-34.4,35.8-36.9c7.6-0.7,69.2-4.3,69.2-4.3
	s10.9-0.3,14,2.1c3.2,2.3,8.4,5.6,8.9,6.6c0.4,1,2.8,3.9,2.8,3.9s-16.6,21.3-20.6,45.9C225.3,250.3,223.3,270.1,223.3,289.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M102.2,358.4
	c0,0,7,19.1,13.1,28.6c6.1,9.6,12.8,18.6,12.8,18.6s9.2,1.5,12.1,1.6c2.9,0.1,15,0.8,15,0.8c-1.6-2.5-32.7-33.6-35.6-36.9
	C116.7,368.2,102.2,358.4,102.2,358.4L102.2,358.4z M371.4,289.9c0,0-0.7,30,3.4,50.1s8,34.8,8,34.8s3.2,5-7,7
	c-10.2,2.1-89.7,19.1-93,19.5c-3.4,0.4-25,2.8-31.6-0.8c-0.3-0.1-1-0.5-1-0.5s-16.5-21.3-20.6-45.9c-4.1-24.6-6.2-44.4-6.2-64.1
	 M326.9,427.9c0,0-4.1-10.2-4.8-11.8c-0.8-1.8-1.9-1.4-1.9-1.4s-2.8,0-3.7-0.1c-1-0.1-0.5-1.4-2.6-1.9c-2.1-0.5-4.8,1.1-6.7,3
	s-0.7,5.9-0.5,6.6c0.1,0.5,1.9,4.4,1.9,4.4s7.7,13.8,9.9,15.1c2.2,1.4,9.5,3.2,11.4,2.5c2.1-0.7,2.8-1.8,2.2-2.9
	C331.5,440.2,326.9,427.9,326.9,427.9L326.9,427.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M315.8,414.3
	c-0.4-0.4-0.5-1.2-2.1-1.6c-2.1-0.5-4.8,1.1-6.7,3c-1.2,1.2-1.2,3.7-1,5.2l-4.5-6.6l-2.2-0.3l6.7-9.8l17.5-3.4
	C323.5,400.8,318,411.8,315.8,414.3L315.8,414.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M299.5,413.9l-24.9-0.4
	c0,0-3.9-0.4-4.5-1.2c-0.7-0.8-0.3-0.8,1.4-1.2c1.6-0.5,34.8-6.7,34.8-6.7L299.5,413.9z M316.5,413.6c0,0,74.4,0.4,86.9,0.5
	c12.5,0.3,93-1.5,93-1.5l3.7-27.4l-13.9-0.4c0,0-43.2-0.3-74.6,1.4c-31.4,1.6-88,14.7-88,14.7C322.6,402.7,318.7,410.6,316.5,413.6
	L316.5,413.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M495.4,412.6
	c0,0,0.7,5.6,1.8,7.2c1.1,1.5,2.6,5.2,2.5,6.2c-0.1,1,0,1.5,0,1.5 M500.2,385.3c0,0,53.8,2.6,80.6,6.1c26.8,3.4,61.4,8.5,62,8.5
	s5.8-0.1,0,1.9c-5.8,2.1-19.9,5-22.3,5.1c-2.3,0.1-47,2.8-50.3,2.9c-3.3,0.1-73.7,3-73.7,3L500.2,385.3L500.2,385.3z M738.9,289.9
	c0,20.5-1.9,34.4-4.1,51c-2.2,16.6-12.7,42.8-12.7,42.8s-40.4-4.1-50.1-4.8c-9.6-0.7-43.5-3.2-43.5-3.7s7.3-28.1,9.6-42.2
	c2.3-14.2,3.4-42.9,3.4-42.9s-1.1-28.8-3.4-42.9s-9.6-41.7-9.6-42.2s33.8-3,43.5-3.7c9.6-0.7,50.1-4.8,50.1-4.8s10.5,26.1,12.7,42.8
	C736.9,255.5,738.9,269.6,738.9,289.9L738.9,289.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M619,375
	c0-0.5,5.9-27.9,8.3-42c2.3-14.2,3.4-42.9,3.4-42.9s-1.1-28.8-3.4-42.9s-8.3-41.5-8.3-42.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M721.7,384.9l0.5-1.1
	c0,0-40.4-4.1-50.1-4.8c-9.6-0.7-44-3.6-44.4-3.6h-7.3c0,0-2.3-1.6-3.2,3.2c-1,5-2.3,9.5,4.3,10.3c6.7,1,57.5,8.5,68,8.8
	c10.5,0.3,16.1,1.9,20.1-1C713.7,393.7,719.9,387.6,721.7,384.9L721.7,384.9z M289.3,426.3c0,0,2.3-5.2,2.5-6.9s0.4-5.2,0.4-5.2
	 M371.4,289.9c0,0-0.7,30,3.4,50.1s8,34.8,8,34.8s3.2,5-7,7c0,0,24.8-7.3,119.4-7.3c94.6,0,112,2.3,112,2.3s8.7-42.8,8.7-86.9
	c0-44.2-8.7-86.9-8.7-86.9s-17.3,2.3-112,2.3c-94.6,0-119.4-7.3-119.4-7.3c10.2,2.1,7,7,7,7s-3.9,14.7-8,34.8
	C370.7,259.9,371.4,289.9,371.4,289.9 M698.7,415.3c0,0-10.3,0.5-12.4,1c-2.1,0.4-48.3,5-53.4,5.1c-5.1,0.1-66.7,4.7-66.7,4.7
	l14.4,1.5c0,0,48.7-0.4,52.1-0.7c3.3-0.3,36.9-1.4,40.9-2.1c4-0.7,13.9-3.6,16.4-4.5C691.4,419.8,695.7,417.2,698.7,415.3
	L698.7,415.3z M477.6,430.5l0.1-4.8 M479.7,425.7c8.1,0.4,8.4,0.5,8.4,0.5s0.7,0.5-3.6,2.2c0,0,0,0.1-4,1.2c-2.8,0.8-8,2.2-12.7,2.2
	c-9.8,0-15.4-3.3-15.4-3.3s-1.6-1.1-1.4-1.6c0.3-0.5,1.4-1.8,18.2-1.9C473.5,425,476.9,425.7,479.7,425.7L479.7,425.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M759.3,289.9
	c0,74.7-12.8,93.8-23.8,106.6c-2.5,2.9-5.5,5.4-8.8,7.4c0,0-12.1,5.1-15.3,5.1c-3,0,12.1-6.6,19-24.3c6.9-17.7,14.7-30.4,14.7-94.6
	c0-64.4-7.8-76.9-14.7-94.6c-6.9-17.7-22-24.3-19-24.3c3,0,15.3,5.1,15.3,5.1c3.3,2.1,6.3,4.5,8.8,7.4
	C746.6,196.3,759.4,215.2,759.3,289.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M668.6,425.3
	c0,0,10-5.6,11.3-7c1.2-1.4,1.7-1.5,1.7-1.5s12.8-2.8,15.7-3.7c2.9-0.8,13.8-4.1,13.8-4.1 M128.2,405.8l3.3,4.4c0,0,0.1,0.4,2.8,1.5
	c2.6,1,11.7,4.8,16.9,5.8c5.2,1,10.5,1.5,13.3,2.3c2.8,0.8,10.2,5.4,10.2,5.4 M99.6,351.8c-1.8-1-6.3-5.1-8.7-7.8
	c-2.3-2.8-1.7-2.3-3.2-9.8c-1.7-7.6-3.4-44.3-3.4-44.3s1.8-36.7,3.4-44.3s0.8-7.2,3.2-9.8c2.3-2.6,6.9-6.9,8.7-7.8 M607.1,376.9
	l10.6,0.7"
          />
          <ellipse
            transform="matrix(0.1234 -0.9924 0.9924 0.1234 424.7027 811.33)"
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            cx="671.6"
            cy="165.3"
            rx="2.2"
            ry="11.3"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M155.3,408.2
		c0,0-25.4-26.7-36.3-37.4c0,0-8.9-7.3-16.6-12.4c0,0-2.5-5.1-2.8-6.6c-0.3-1.5-1.7-17.5-3.9-61.9c0,0,1.9-41.3,3.9-61.9l2.8-6.6
		c0,0,11-7.4,17.3-12.9c0,0,33.3-33.8,35.6-36.9l0.3-0.1l68.6-4.3c0,0,8.4-0.3,13.5,1.5l1,0.6c0,0,7,5,8.4,6.1l0.6,0.7l2.6,3.7
		c0,0-12.1,15.1-19.1,38.5c0,0-1,3.9-1.1,5c0,0-7,35.8-6.6,66.6c0,0-1,23,6.9,68.2c0,0,2.9,18.7,19.9,41.8c0,0-2.2,2.8-2.8,3.9
		l-0.7,0.7l-7,5.1c0,0-1.7,1.2-2.8,1.6c0,0-2.8,1.2-12.9,1.4l-66.7-4.1l-1.8-0.1C155.6,408.4,155.4,408.4,155.3,408.2z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M376.9,381.6l-92.7,19.5
		c0,0-1,0.4-5.5,0.7c-4.5,0.4-23.2,2.1-28.6-1.8c0,0-15.7-20.6-19.9-41.8l-1.9-11.4c0,0-5.2-28.3-5.1-56.8c0,0-0.8-15.8,3.9-49.7
		l2.5-15.5c0,0,1.4-6.9,3.4-12.4c0,0,4.5-15.5,17.1-32.5c0,0,2.1-2.1,11.6-2.5c0,0,11.1-0.4,21.6,1.1l93,19.5c0,0,6.5,0.8,7.2,4.8
		c0,0,0.1,1.2-0.4,2.1c0,0-7.2,27.9-9.2,42.6c-2.1,14.7-2.1,35.9-2.1,42.2c0,0-0.7,20.6,2.2,43.3c0,0,1.4,10.7,9.1,41.5
		c0,0,1.1,1.8-0.1,3.7C381.3,380.5,377.9,381.4,376.9,381.6z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M607.1,376.9c0,0,8.7-42.2,8.7-86.9
		s-8.7-86.9-8.7-86.9s-152.1,7-226.3-4v0.6c0,0,3.9,2.3,2.1,5.4c0,0-6.5,25.3-9.2,42s-2.2,42.9-2.2,42.9s-0.8,21.2,2.3,44.3
		c0,0,1.7,11.7,8.9,40.6c0,0,2.5,3.4-3.6,6.2h0.7C380,381,421.2,369.7,607.1,376.9z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M628.5,375.5
		c0,0,8.9-36.5,9.8-43.5c1-7,2.6-20.2,3.3-42.1c0,0-0.1-17.2-3.4-42.9c0,0-1.9-12.4-9.6-42.2l93.7-8.5c0,0,7.3,19.4,11.3,35.5
		c0,0,1.7,9.8,2.1,12.9c0.4,3.2,2.2,18.4,2.2,18.4s1.4,20.5,1.2,27c0,6.5-0.8,22.4-1.4,28.2c0,0-2.3,21.2-3.2,25.6
		c0,0-1.5,12.9-12.1,40c0,0-40.4-4-52.3-5L628.5,375.5z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M730.7,383.8h-8.5
		c0,0,9.4-22.1,12.5-42.5c0,0,4.3-29.3,4.1-51.3c0,0,0.5-21.7-5.1-57c0,0-1.4-8.8-11.6-36.9h8.5l6.2,16.2c0,0,4,12.4,5.9,28.3
		c0,0,2.3,19.5,2.3,49.2c0,0,0.3,27.9-2.1,47c0,0-1.7,19.7-7.6,34.7L730.7,383.8z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback2DrTop);
