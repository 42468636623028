import React, { Component } from "react";
import { connect } from "react-redux";
import { loadGatePass } from "../actions";
import DocumentTitle from "react-document-title";
import { getConfig } from "../utils/helpers";

export class GatePasses extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.loadGatePass(getConfig("marketplaceId"));
  }

  render() {
    return (
      <DocumentTitle title={"Gate Passes"}>
        <div>
          <h1>Gate Passes</h1>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { gatePass }
  } = state;

  return {
    gatePass
  };
};

export default connect(
  mapStateToProps,
  {
    loadGatePass
  }
)(GatePasses);
