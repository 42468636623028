import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { customContactPage, makeMailLink } from './index';
import { loadMarketplaces } from '../../actions';
import { getConfig } from '../../utils/helpers';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    padding: 20,
  },
  header: {
    margin: 20,
  },
  addressesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',
    minWidth: 350,
    margin: 20,
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    margin: 20,
    flex: `0 1 auto`,
  },
  row: {
    margin: `5px 0 5px 0`,
  },
  name: {
    fontSize: 24,
    margin: `5px 0 15px 0`,
  },
  messageContainer: {
    margin: 20,
    lineHeight: 1.5,
    fontSize: 16,
    textAlign: 'justify',
    width: '50%',
    minWidth: 420,
  },
  message: {
    marginBottom: 15,
  },
};

const siaContact = {
  name: 'Superior Integrated Auctions',
  address: '4449 Easton Way',
  city: 'Columbus',
  state: 'OH',
  zipcode: '43219',
  country: 'United States',
  phone: '(855) 999-0303',
  email: 'support@superior-ia.com',
};

const messages = [
  `Contact us about anything related to our company or services. We'll do our best to get back to you as soon as possible.`,
  `The Marketplace is a remarketing channel where our goal is to provide the most efficient and cost-effective venue for acquiring, 
  trading, and selling front-line ready vehicles. Dealers purchasing vehicles through the Marketplace benefit from reduced 
  reconditioning costs by having vehicles available for frontline retail exposure much faster than those purchased elsewhere.  
  In addition to the easily accessible inventory of vehicles, we maintain a full staff of professionals to assist you with all 
  your dealership's buying and selling needs. We look forward to your active participation and will make every effort to make your 
  experience pleasurable, profitable and rewarding.`,
];

const renderAddress = contactInfo => (
  <div style={styles.addressContainer}>
    <div style={styles.name}>{contactInfo.name}</div>
    <div style={styles.row}>{contactInfo.address}</div>
    <div
      style={styles.row}
    >{`${contactInfo.city}, ${contactInfo.state} ${contactInfo.zipcode}`}</div>
    <div style={styles.row}>{contactInfo.country}</div>
    <div style={styles.row}>{contactInfo.phone}</div>
    <div style={styles.row}>{makeMailLink(contactInfo.email)}</div>
  </div>
);

const ContactUs = ({ loadMarketplaces, marketplaces }) => {
  const [customerContact, setCustomerContact] = useState({});

  useEffect(() => {
    const mpId = getConfig('marketplaceId');
    loadMarketplaces(mpId);
  }, [loadMarketplaces]);

  useEffect(() => {
    const mpId = getConfig('marketplaceId');
    const parentMarketplace = find(
      marketplaces.marketplaceList,
      mp => mp.marketplaceId == mpId
    );
    if (parentMarketplace) {
      setCustomerContact({
        name: getConfig('name'),
        address: parentMarketplace.address1,
        address2: parentMarketplace.address2,
        city: parentMarketplace.city,
        state: parentMarketplace.state,
        zipcode: parentMarketplace.zipcode,
        country: parentMarketplace.country || 'United States',
        phone: parentMarketplace.phone,
        email: parentMarketplace.email,
      });
    }
  }, [marketplaces]);

  return (
    <DocumentTitle title="Contact Us">
      {customContactPage ? (
        customContactPage
      ) : (
        <div style={styles.container}>
          <h2 style={styles.header}>Contact Us</h2>
          <div
            style={{
              ...styles.addressesContainer,
              justifyContent: customerContact.address
                ? 'space-evenly'
                : 'center',
            }}
          >
            {customerContact.address && renderAddress(customerContact)}
            {renderAddress(siaContact)}
          </div>
          <div style={styles.messageContainer}>
            {messages.map((message, index) => (
              <div key={index} style={styles.message}>
                {message}
              </div>
            ))}
          </div>
        </div>
      )}
    </DocumentTitle>
  );
};

ContactUs.propTypes = {
  marketplaces: PropTypes.object.isRequired,
  loadMarketplaces: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { marketplaces } = state.entities;
  return { marketplaces };
};

export default connect(mapStateToProps, {
  loadMarketplaces,
})(ContactUs);
