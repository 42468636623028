import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  loadAddWatchList,
  loadRemoveWatchList,
  loadWatchlistObject,
} from '../../actions';
import { confirm } from '../common/confirm/CallConfirm';
import { getConfig } from '../../utils/helpers';

let styles = {};

class TableButtonWatchlist extends Component {
  handleBulkWatchlistClick = (
    selectedIds,
    removeFromWatchlist = false,
    buttonText
  ) => {
    if (!selectedIds.length) {
      const confirmMessage = removeFromWatchlist
        ? 'Please select items to remove from your watchlist'
        : 'Please select items to add to your watchlist';
      confirm(confirmMessage, {
        title: buttonText,
        hideCancel: true,
      });
    }

    const mpId = getConfig('marketplaceId');
    const watchlistAction = removeFromWatchlist
      ? this.props.loadRemoveWatchList
      : this.props.loadAddWatchList;
    watchlistAction(mpId, selectedIds).then(({ response }) => {
      if (response.wsStatus === 'Success') {
        this.props.loadWatchlistObject(mpId);
        const toastText = removeFromWatchlist
          ? `Items succesfully removed from your watchlist`
          : `Iitems succesfully added to your watchlist`;
        toastr.success('Success', toastText, { timeOut: 2000 });
      }
    });
  };

  render() {
    const { selectedData, dataForButtonActions } = this.props;
    const selectedIds = (selectedData || []).map(
      item => item.itemId || item.id
    );
    const dataIds = (dataForButtonActions || []).map(
      item => item.itemId || item.id
    );

    const watchlistIds = Object.keys(this.props.watchlistObject)
      .map(id => Number(id))
      .filter(id => id);

    const removeFromWatchlist = selectedIds.length
      ? selectedIds.every(id => watchlistIds.includes(id))
      : dataIds.every(id => watchlistIds.includes(id));

    const buttonText = removeFromWatchlist
      ? 'Remove from Watchlist'
      : 'Add to Watchlist';

    return (
      <div ref={node => (this.bulkWatchlist = node)} style={styles.container}>
        <button
          // disabled={!selectedIds.length}
          className="btn btn-primary"
          style={styles.button}
          onClick={() => {
            this.handleBulkWatchlistClick(
              selectedIds,
              removeFromWatchlist,
              buttonText
            );
          }}
        >
          {buttonText}
        </button>
      </div>
    );
  }
}

styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: 2,
    marginRight: 12,
    marginBottom: 2,
    marginLeft: 0,
    padding: '2px 5px',
    height: 24,
    fontSize: 12,
  },
};

TableButtonWatchlist.defaultProps = {};

TableButtonWatchlist.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  headers: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  const {
    unauthorizedEventIds,
    watchlistUserItems,
    watchlistObject,
  } = state.entities;
  return {
    unauthorizedEventIds,
    watchlistUserItems,
    watchlistObject,
  };
};
export default connect(
  mapStateToProps,
  {
    loadAddWatchList,
    loadRemoveWatchList,
    loadWatchlistObject,
  }
)(TableButtonWatchlist);
