import React, { Component } from 'react';
import { string } from 'prop-types';
import $ from 'jquery';

window.$ = window.jQuery = $;
require('bootstrap');

class ExtOfferModal extends Component {
  handleModalCancel = e => {
    const { onModalCancel } = this.props;
    onModalCancel();
  };

  render() {
    const { deeplink } = this.props;
    return (
      <div className="modal fade" id="extOfferModal">
        <div className="modal-dialog" style={{ width: '95%', height: '90%' }}>
          <div className="modal-content" style={{ height: '100%' }}>
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span onClick={this.handleModalCancel} aria-hidden="true">
                  ×
                </span>
              </button>
              <h3 className="modal-title">
                <span className="fa fa-car" /> SST First Offer
              </h3>
            </div>
            <div
              className="modal-body"
              style={{
                height: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {deeplink ? (
                <iframe
                  title="ExtOfferModal"
                  src={deeplink}
                  style={{ border: 'none', width: '100%', height: '100%' }}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ExtOfferModal.propTypes = {
  deeplink: string,
};

export default ExtOfferModal;
