import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { determineCountdownInfo, getTemplate } from '../utils/helpers';

let styles = {};

class CountdownTimerVC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownInfo: determineCountdownInfo(props.vehicleData),
    };
  }

  componentDidMount() {
    if (!this.timer) {
      this.setTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setTimer = () => {
    this.timer = setInterval(() => {
      const countdownInfo = determineCountdownInfo(this.props.vehicleData);

      if (this.state.countdownInfo.eventStatus !== countdownInfo.eventStatus) {
        this.props.onCountdownChange(countdownInfo);
      }

      if (
        this.state.countdownInfo.time !== countdownInfo.time ||
        this.state.countdownInfo.eventStatus !== countdownInfo.eventStatus ||
        this.state.countdownInfo.countdownType !== countdownInfo.countdownType
      ) {
        this.setState({ countdownInfo });
      }
    }, 1000);
  };

  renderCardBreak() {
    return <hr style={{ margin: 0, marginTop: 4, marginBottom: 4 }} />;
  }

  renderSubtleCountdownTimer() {
    return (
      <div style={styles.subtleContainer}>
        <div style={styles.label}>{this.state.countdownInfo.label}</div>
        <div style={styles.time}>{this.state.countdownInfo.time}</div>
      </div>
    );
  }

  renderLoudCountdownTimer() {
    return (
      <div
        style={{
          ...styles.loudCountainer,
          backgroundColor: getTemplate(
            this.props.template,
            'countdownTimer.backgroundColor'
          ),
          color: getTemplate(this.props.template, 'countdownTimer.color'),
        }}
      >
        <div
          className="countdown-fill"
          style={{
            display:
              this.state.countdownInfo.eventStatus === 'isInProgress24'
                ? 'block'
                : 'none',
          }}
        />
        <div style={styles.loudBlock}>
          <div style={styles.label}>{this.state.countdownInfo.label}</div>
          <div style={styles.time}>{this.state.countdownInfo.time}</div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.vehicleData || !this.state.countdownInfo.countdownType) {
      return null;
    }

    return this.state.countdownInfo.countdownType === 'loud'
      ? this.renderLoudCountdownTimer()
      : this.renderSubtleCountdownTimer();
  }
}

styles = {
  subtleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 8,
  },
  loudCountainer: {
    borderRadius: 3,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    margin: 0,
  },
  subtleBlock: {},
  loudBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#fffefe',
    fontSize: 15,
  },
  label: {
    fontWeight: 'bold',
    marginRight: 7,
  },
  time: {},
};

CountdownTimerVC.defaultProps = {
  onCountdownChange: () => null,
};

CountdownTimerVC.propTypes = {
  vehicleData: PropTypes.object.isRequired,
  onCountdownChange: PropTypes.func,
};

const mapStateToProps = state => {
  const { template } = state.entities;
  return { template };
};

export default connect(
  mapStateToProps,
  {}
)(CountdownTimerVC);
