import { find, findIndex } from 'lodash';
// 7.17.2024 - TODO -- Remove all cheeky error messages
export const isJSON = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const showRow = (sectionAndRow = '', coreSections) => {
  const [s, r] = sectionAndRow.split(':');
  if (!s || !r)
    console.error('Did you forget to pass a section & row?', sectionAndRow);
  if (!coreSections) {
    coreSections = [];
    console.error(`Did you forget to pass the coreSections?`, sectionAndRow);
  }

  const section = find(coreSections, { section: s });
  if (section && section.hiddenRows.includes(sectionAndRow)) return false;
  else return true;
};

export const findNextSection = (currentSection = '', coreSections = []) => {
  const currentSectionIndex = findIndex(coreSections, {
    section: currentSection,
  });

  return currentSectionIndex > -1 && coreSections[currentSectionIndex + 1]
    ? coreSections[currentSectionIndex + 1].path
    : '';
};

export const compose = (...funcs) => {
  if (funcs.length === 0) return arg => arg;
  if (funcs.length === 1) return funcs[0];
  return funcs.reduce((a, b) => (...args) => a(b(...args)));
};
