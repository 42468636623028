import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';

class ItemsPageNav extends Component {
  isActive(view) {
    return this.props.view === view ? 'active' : '';
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';
    var CSV_Titles = [];
    var CSV_Array = [];

    //Set Report title in first row or line

    CSV += ReportTitle + '\r\n\n';

    if (ShowLabel) {
      let titles = ',';
      titles += String(Object.keys(JSONData[0]));
      CSV_Titles.push(titles);
    }

    CSV_Array = CSV_Titles.concat(
      JSONData.map((csvObj, index) => {
        return index + 1 + ',' + String(Object.values(csvObj));
      })
    );

    CSV = CSV_Array.join('\r\n');

    if (CSV === '') {
      alert('Invalid data');
      return;
    }

    //Generate a file name
    var fileName = '';
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, '_');

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    if (
      navigator.userAgent.toUpperCase().indexOf('WOW64') >= 0 &&
      navigator.sayswho.split(' ')[1] === '11'
    ) {
      fileName = 'watchlist_Information.csv';
      var blob = new Blob([CSV], {
        type: uri,
      });
      navigator.msSaveBlob(blob, fileName);
    } else {
      var link = document.createElement('a');
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style.visibility = 'hidden';
      link.download = fileName + '.csv';

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    //this trick will generate a temp <a /> tag
  }

  handleExport(data) {
    if (data.length) {
      const exportArray = data.map(item => {
        return {
          VIN: item._source.vIN,
          'Lot Number': item._source.lotNumber,
          Year: item._source.year,
          Make: item._source.make,
          Model: item._source.model,
          'Body Style': item._source.bodyStyle,
          Drive: item._source.drive,
          Engine: item._source.engine,
          Transmission: item._source.transmission,
          Mileage: item._source.mileage,
          'Exterior Color': item._source.exteriorColor,
          'Seller Name': item._source.sellerName,
          'Event Name': item._source.eventName,
          'Buy Now Price': item._source.outrightPrice,
          'High Bid': item._source.highBid,
          Options: item._source.options,
          Announcements: item._source.announcements,
          Notes: item._source.notes,
          Grade:
            item._source.crGrade ||
            item._source.conditionRating ||
            item._source.rating,
        };
      });

      this.JSONToCSVConvertor(exportArray, `${this.props.name} Info`, true);
    }
  }

  handleView(view) {
    this.props.updateView(view);
  }

  render() {
    return (
      <div className="watchlist-nav">
        <div className="container">
          <div className="watchlist-title pull-left">
            <h4>{this.props.title}</h4>
          </div>
          <div className="col-md-4 pull-left filter-selector">
            <input
              type="text"
              className="form-control"
              onChange={this.props.searchHandler}
              placeholder="Search here..."
              value={this.props.filterValue}
            />
          </div>

          <span className="pull-right filter-selector">
            <input
              type="button"
              className="btn btn-primary"
              style={{ height: 30, width: 66 }}
              value="Export"
              onClick={() => this.handleExport(this.props.itemsList)}
            />
          </span>
          <div className="pull-right filter-selector">
            <ReactToPrint
              pageStyle="@page { size: portrait; margin: 0cm !important; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }"
              documentTitle={this.props.title}
              onBeforePrint={() => false}
              onAfterPrint={() => false}
              trigger={() => (
                <button
                  className="btn btn-primary"
                  style={{ height: 30, width: 66 }}
                >
                  Print
                </button>
              )}
              content={() => this.props.pageRef}
            />
          </div>
          <div className="pull-right sort-selector" style={{ height: 30 }}>
            <select onChange={this.props.sortHandler}>
              {[
                'Recently Added',
                'Make',
                'Model',
                'Year - Latest First',
                'Year - Oldest First',
                'Seller Name',
                'End Time',
                'Lot Number - Ascending',
                'Lot Number - Descending',
                `Odometer - Lowest First`,
                `Odometer - Highest First`,
                'Price - Lowest First',
                'Price - Highest First',
              ].map((option, index) => (
                <option key={index + option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div
            data-qa="options"
            className="toggle pull-right"
            style={{ height: 30 }}
          >
            <div className="toggle-option">
              <Link
                to="#"
                className={this.isActive('grid')}
                onClick={e => this.handleView('grid')}
              >
                Grid
              </Link>
            </div>
            <div className="toggle-option">
              <Link
                to="#"
                className={this.isActive('list')}
                onClick={e => this.handleView('list')}
              >
                List
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ItemsPageNav.propTypes = {
  filterValue: PropTypes.string,
  name: PropTypes.string,
  searchHandler: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

export default ItemsPageNav;
