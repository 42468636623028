import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-modal';
import {
  loadItemUpdatePricing,
  loadItemUpdateSellerDisclosure,
  resetItem,
} from '../actions';
import { commafyCurrency, getConfig } from '../utils/helpers';

const styles = {
  customStyles: {
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      overflow: 'visible', // allows dropdown to show up
      width: 650,
      minHeight: 300,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'auto',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  priceContainer: {
    padding: 5,
    paddingBottom: 10,
  },
  input: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: 6,
    cursor: 'default',
    color: '#555',
  },
  textarea: {
    minHeight: 100,
    maxHeight: 300,
    minWidth: '100%',
    maxWidth: '100%',
    border: 'none',
    textAlign: 'left',
    backgroundColor: '#F5F5F5',
  },
};

const initialState = {
  startingBid: '',
  reservePrice: '',
  outrightPrice: '',
  sellerDisclosure: '',
};

class UpdatePriceModal extends Component {
  state = initialState;

  onOpen = () => {
    this.loadItemData(this.props.item);
  };

  onClose = () => {
    this.setState(initialState, () => {
      this.props.onClose();
    });
  };

  loadItemData(item) {
    this.setState({
      startingBid: String(item.startingBid),
      reservePrice: String(item.reservePrice),
      outrightPrice: String(item.outrightPrice),
      sellerDisclosure: String(item.announcements),
    });
  }

  onError = error => {
    toastr.error('Error', error.message || 'An unknown error occured');
    console.error(error);
    return false;
  };

  handleItemUpdatePricing = () => {
    const { item } = this.props;
    const { startingBid, reservePrice, outrightPrice, sellerDisclosure } =
      this.state;
    const mpId = getConfig('marketplaceId');

    Promise.all([
      this.props
        .loadItemUpdatePricing(
          mpId,
          item.itemId,
          startingBid || '0',
          reservePrice || '0',
          outrightPrice || '0'
        )
        .then(({ response }) => response.wsStatus === 'Success')
        .catch(err => false),
      this.props
        .loadItemUpdateSellerDisclosure(
          mpId,
          item.itemId,
          sellerDisclosure || '-'
        )
        .then(({ response }) => response.wsStatus === 'Success')
        .catch(err => false),
    ]).then(results => {
      const [pricing, disclosure] = results;
      if (pricing && disclosure) {
        toastr.success('Success', 'Successfully completed the request');
        if (this.props.loadData) this.props.loadData();
        this.onClose();
        this.handleModalCancel();
      } else {
        this.onError({
          message: `Error Updating ${
            !pricing && !disclosure
              ? 'Pricing and Seller Disclosure'
              : !pricing
              ? 'Pricing'
              : 'Seller Disclosure'
          }`,
        });
      }
    });
  };

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>Update Pricing</span>
        </div>
        <button type="button" className="close" onClick={this.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderYMM() {
    const { item } = this.props;
    const YMM = `${item.vehicleYear} ${item.make} ${item.model}`;
    return (
      <div>
        <div style={{ fontSize: 18 }}>{YMM}</div>
        <div>{item.vin}</div>
      </div>
    );
  }

  renderPriceInputs() {
    const { item } = this.props;
    const { startingBid, reservePrice, outrightPrice, sellerDisclosure } =
      this.state;

    return (
      <form>
        <div style={styles.priceContainer}>
          <label>Starting Bid</label>
          <br />
          <div style={{ height: '100%' }}>
            <input
              style={styles.input}
              type="text"
              value={commafyCurrency(startingBid)}
              onChange={e =>
                this.setState({
                  startingBid: e.target.value.replace(/[^\d]/g, ''),
                })
              }
            />
            <button
              className="btn btn-primary"
              style={{ marginLeft: 10 }}
              placeholder={item.startingBid}
              disabled={!startingBid || startingBid === '0'}
              onClick={e => this.setState({ startingBid: '0' })}
            >
              Remove
            </button>
          </div>
        </div>

        <div style={styles.priceContainer}>
          <label>Reserve Price</label>
          <br />
          <input
            type="text"
            style={styles.input}
            value={commafyCurrency(reservePrice)}
            onChange={e =>
              this.setState({
                reservePrice: e.target.value.replace(/[^\d]/g, ''),
              })
            }
          />
          <button
            className="btn btn-primary"
            style={{ marginLeft: 10 }}
            placeholder={item.reservePrice}
            disabled={!reservePrice || reservePrice === '0'}
            onClick={e => this.setState({ reservePrice: '0' })}
          >
            Remove
          </button>
        </div>

        <div style={styles.priceContainer}>
          <label>Buy Now Price</label>
          <br />
          <input
            type="text"
            style={styles.input}
            value={commafyCurrency(outrightPrice)}
            onChange={e =>
              this.setState({
                outrightPrice: e.target.value.replace(/[^\d]/g, ''),
              })
            }
          />
          <button
            className="btn btn-primary"
            style={{ marginLeft: 10 }}
            placeholder={item.outrightPrice}
            disabled={!outrightPrice || outrightPrice === '0'}
            onClick={e => this.setState({ outrightPrice: '0' })}
          >
            Remove
          </button>
        </div>

        <div style={styles.priceContainer}>
          <label>Seller Disclosure </label>
          <br />
          <textarea
            style={styles.textarea}
            value={sellerDisclosure}
            onChange={e =>
              this.setState({
                sellerDisclosure: e.target.value.replace(/[<>]/g, ''),
              })
            }
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          className="btn btn-primary button-cancel"
          style={{ minWidth: 100 }}
          type="button"
          onClick={this.onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary button-action"
          style={{ marginLeft: 10, minWidth: 100 }}
          onClick={this.handleItemUpdatePricing}
        >
          Update
        </button>
      </div>
    );
  }

  render() {
    const { item } = this.props;

    if (!item) return null;

    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Update Pricing Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div style={{ ...styles.container, height: 'auto' }}>
          {this.renderHeader()}
          {this.renderDivider()}
          <div>
            {this.renderYMM()}
            <div style={{ marginTop: 20, fontSize: 16 }}>
              {`Starting Bid: ${commafyCurrency(item.startingBid)} | `}
              {`Reserve Price: ${commafyCurrency(item.reservePrice)} | `}
              {`Buy Now: ${commafyCurrency(item.outrightPrice)}`}
            </div>
          </div>
          {this.renderDivider()}
          {this.renderPriceInputs()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

UpdatePriceModal.propTypes = {
  item: PropTypes.object,
  loadData: PropTypes.func,
  loadItemUpdatePricing: PropTypes.func.isRequired,
  loadItemUpdateSellerDisclosure: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  resetItem: PropTypes.func.isRequired,
};

export default connect(null, {
  loadItemUpdatePricing,
  loadItemUpdateSellerDisclosure,
  resetItem,
})(UpdatePriceModal);
