import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { get, find, findIndex, has, cloneDeep } from 'lodash';
import {
  updateItem,
  updatePhotos,
  updatePhotosAsync,
} from '../../../../../actions';
import Icon from '../../ui/icons';
import Loader from '../../ui/loader';
import { getMd5Hash } from '../../../utils/getMd5Hash';
import {
  compose,
  getConfig,
  refreshApiKey,
} from '../../../../../utils/helpers';
import SuccessErrorMessageModal from '../../modals/SuccessErrorMessageModal';

class ImageUploader extends Component {
  state = {
    isUploading: false,
    isMessageModalOpen: false,
    message: null,
    imgUpload: '',
  };
  constructor(props) {
    super(props);
    this.fileUploadRef = createRef();
  }

  closeErrorSuccessModal = () => {
    this.setState({
      isMessageModalOpen: false,
      isUploading: false,
    });
  };

  findDescriptionKey(photoItem) {
    return `${photoItem.category}-${photoItem.description}`;
  }

  findExistingPhoto = photoItem => {
    const descriptionKey = this.findDescriptionKey(photoItem);
    const existingPhoto = find(this.props.item.photos, { descriptionKey });
    return existingPhoto;
  };

  uploadFiles = async staged => {
    // this.setState({ isUploading: true });

    // take this batch of staged photos, match them in the store,
    // and change only their uploadStatus to "Uploading" (in this list not the store)
    // store does NOT yet have the changes tho
    // we will refer to this array a couple times down the line
    // we clone it so we don't mistakenly change the store items (js object reference thing)
    let uploaded = cloneDeep(staged).map(photo => {
      return { ...photo, uploadStatus: 'Uploading' };
    });

    // we need to update the store now.
    // go thru the store photos and replace any store photo
    // that is about to be uploaded with the photo object in the
    // uploaded array (because it has the uploadStatus: 'Uploading')
    const updatedPhotos = this.props.item.photos
      .filter(storePhoto => storePhoto.name !== '(null)')
      .map(storePhoto => {
        // match by checksum
        const uploadingPhoto = find(uploaded, {
          filechksum: storePhoto.filechksum,
        });

        // keep our batch of photos up to speed with the latest finish status (user action for condition photos)
        if (uploadingPhoto && has(storePhoto, 'finished')) {
          uploadingPhoto.finished = storePhoto.finished;
        }
        return uploadingPhoto || storePhoto;
      });
    this.props.updatePhotosAsync(updatedPhotos);

    const requests = await this.buildRequests(uploaded);
    axios
      .all(requests)
      .then(responses => {
        // loop thru each response and...
        responses.forEach(response => {
          const photoIndex = findIndex(uploaded, {
            filechksum: response.data.filechksum,
          });

          const photo = get(uploaded, `${photoIndex}`);

          if (photo) {
            if (response.status === 200) {
              const requestStatus = get(response, 'data.amStatus');
              const filechksumStatus = get(
                response,
                'data.filesstatus.amStatus'
              );
              const uploadStatus =
                requestStatus === 'Success' && filechksumStatus === 'Success'
                  ? 'Success'
                  : 'Failure';
              const fileName = get(
                response,
                `data.files[${0}]`,
                photo.name
              ).replace(String.fromCharCode(92), '/');

              photo.uploadStatus = uploadStatus;
              photo.name = fileName;
            } else {
              photo.uploadStatus = 'Failure';
              this.setState({
                message: 'Failed to upload the image!',
                isMessageModalOpen: true,
              });
            }

            uploaded = [
              ...uploaded.slice(0, photoIndex),
              photo,
              ...uploaded.slice(photoIndex + 1),
            ];
          } else {
            this.setState({
              message: 'Failed to upload the image!',
              isMessageModalOpen: true,
            });
          }
        });
      })
      .catch(error => {
        if (error.request) {
          if (error.code === 'ECONNABORTED') {
            console.error('Error timeout', error);
          }

          // all must fail if there is a request failure (??)
          uploaded = uploaded.map(photo => {
            return { ...photo, uploadStatus: 'Failure' };
          });
          this.setState({
            message: 'Failed to upload the image!!',
            isMessageModalOpen: true,
          });
        }

        if (error.response) {
          console.error('Error response', error.response);
          this.setState({
            message: 'Failed to upload the image!',
            isMessageModalOpen: true,
          });
        }
      })
      .then(() => {
        // update the store with the final results of the requests
        const finalPhotos = this.props.item.photos.map(storePhoto => {
          const uploadingPhoto = find(uploaded, {
            filechksum: storePhoto.filechksum,
          });

          // again, keep our batch of photos up to speed with the latest finish status (user action for condition photos)
          if (uploadingPhoto && has(storePhoto, 'finished')) {
            uploadingPhoto.finished = storePhoto.finished;
          }

          return uploadingPhoto || storePhoto;
        });

        if (finalPhotos) {
          this.props.updatePhotos(finalPhotos);
        } else {
          console.error('Error finalPhotos', finalPhotos);
        }

        this.setState({ isUploading: false });
        this.props.onPhotosChange();
      });
  };

  buildRequests = async uploaded => {
    const refreshToken = this.props.cookies.get('refreshToken');
    const coreRoot = getConfig('coreRoot');
    const apiKey =
      this.props.cookies.get('apiKey') || (await refreshApiKey(refreshToken));
    const requests = uploaded.map(photo => {
      // const file = { uri: photo.uri, name: photo.name, type: 'image/jpeg' };
      let new_file = new File([photo.uri], photo.name);
      const body = new FormData();
      body.append('image', new_file);

      const request = axios({
        method: 'POST',
        url:
          `${coreRoot}` +
          `upload-files` +
          `?keepFileNames=true` +
          `&apiKey=${apiKey}` +
          `&fileType=${this.props.fileType || 'ItemPictures'}` +
          `&filecksum=${photo.filechksum}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
        data: body,
        timeout: 30000,
        validateStatus: status => {
          // TODO: - check headers?
          return true;
        },
      });
      return request;
    });

    return requests;
  };

  getBase64(file) {
    const { photoItem } = this.props;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ imgUpload: reader.result }, () =>
        this.handlePhotoUpload(photoItem, file)
      );
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  handlePhotoUpload = async (photoItem, uri) => {
    this.setState({ isUploading: true });

    const filechksum = getMd5Hash(this.state.imgUpload);
    const existingFile = find(this.props.item.photos || [], {
      filechksum,
    });

    // do we really want to stop them?
    if (existingFile && existingFile.uri) {
      this.setState({
        message: 'Photo Already Uploaded!',
        isMessageModalOpen: true,
      });
      return;
    }

    const descriptionKey = this.findDescriptionKey(photoItem);
    const name = `${this.props.item.vin}-${new Date().getTime() * 1000}.jpg`;
    const existingPhotoIndex = findIndex(this.props.item.photos, {
      descriptionKey,
    });
    let photo = {};
    if (existingPhotoIndex > -1) {
      photo = {
        ...this.props.item.photos[existingPhotoIndex],
        uri,
        name,
        filechksum,
        uploadStatus: 'Staged',
      };
      // in the store put the photo retake in the same position as the original
      this.props
        .updatePhotosAsync([
          ...this.props.item.photos.slice(0, existingPhotoIndex),
          photo,
          ...this.props.item.photos.slice(existingPhotoIndex + 1),
        ])
        .then(() => {
          this.uploadFiles([photo]);
        });
    } else {
      photo = {
        type: 'Image',
        descriptionKey,
        category: photoItem.category,
        description: `${photoItem.description}`,
        uri,
        name,
        filechksum,
        uploadStatus: 'Staged',
      };

      this.props
        .updatePhotosAsync([...this.props.item.photos, photo])
        .then(() => {
          this.uploadFiles([photo]);
        });
    }
  };

  handleUploadClick = e => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      this.getBase64(file);
    }
  };

  handlePhotoDelete = item => {
    const photos = this.props.item.photos;
    const existingPhoto = this.findExistingPhoto(item);
    // exists on server
    if (existingPhoto.photo) {
      delete existingPhoto.uri;
      existingPhoto.name = '(null)';
    } else {
      const descriptionKey = this.findDescriptionKey(item);
      const index = findIndex(photos, { descriptionKey });
      photos.splice(index, 1);
    }

    this.props.updatePhotos(photos);
  };

  handleClearClick = () => {
    this.handlePhotoDelete(this.props.photoItem);
    this.props.onPhotosChange();
  };

  renderTitle() {
    const { title = 'Photo' } = this.props;
    const isRequired = this.props.isRequired || false;

    return (
      <div>
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
    );
  }

  renderContent() {
    const { isUploading } = this.state;
    const { title = 'Photo', photoItem, onImageClick } = this.props;
    const existingPhoto = this.findExistingPhoto(photoItem);
    const placeholder = isUploading ? 'Uploading photo...' : 'Upload photo';

    if (!isUploading && existingPhoto && existingPhoto.name !== '(null)') {
      const text = String(existingPhoto.name).slice(
        String(existingPhoto.name).lastIndexOf('/') + 1
      );

      return (
        <div onClick={onImageClick}>
          <img
            src={
              existingPhoto.name instanceof File
                ? URL.createObjectURL(existingPhoto.name)
                : existingPhoto.name
            }
            alt={'Selected image for ' + title}
          />
          <span>{text}</span>
        </div>
      );
    }

    return (
      <span 
        style={{textAlign: 'center', display: 'block'}} 
        onClick={() => this.fileUploadRef.current.click()}
      >
          {placeholder}
      </span>
    )
  }

  renderActionIcon() {
    const { photoItem } = this.props;
    const { isUploading } = this.state;
    const existingPhoto = this.findExistingPhoto(photoItem);

    return isUploading ? (
      <Loader
        className="conditions-loader flex justify-center pt-2"
        textVisibility={false}
        size={20}
      />
    ) : existingPhoto && existingPhoto.name !== '(null)' ? (
      <div onClick={this.handleClearClick}>
        <Icon name="Bin" size={18} />
      </div>
    ) : (
      <div>
        {/* <div onClick={() => this.refs.fileInputRef.click()}> */}
        <div onClick={() => this.fileUploadRef.current.click()}>
          <Icon name="FileUpload" size={22} />
        </div>
        {photoItem.cloudPicker && (
          <div onClick={() => alert('Cloud file picker')}>
            <Icon name="FileUploadCloud" size={28} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { isMessageModalOpen, message } = this.state;
    const error = undefined;

    return (
      <div>
        <SuccessErrorMessageModal
          open={isMessageModalOpen}
          close={this.closeErrorSuccessModal}
          message={message}
        />

        <div className="file-picker-input-field-container">
          {this.renderTitle()}
          <div className="file-picker-input-field">
            <div style={{width: '100%'}} className="file-picker-input-field-content">
              {this.renderContent()}
            </div>
            <div className="file-picker-input-field-buttons">
              {this.renderActionIcon()}
            </div>
          </div>
          <input
            // ref="fileInputRef"
            ref={this.fileUploadRef}
            className="hidden"
            type="file"
            accept={['image/png', 'image/jpg', 'image/jpeg'].join(',')}
            disabled={false}
            onChange={this.handleUploadClick}
          />
          {error && <div className="text-red-500 text-xs mt-1">{error}</div>}
        </div>
      </div>
    );
  }
}

ImageUploader.defaultProps = {
  onPhotosChange: () => null, // for lightbox gallery
};

ImageUploader.propTypes = {
  onPhotosChange: PropTypes.func,
  onImageClick: PropTypes.func.isRequired,
  photoItem: PropTypes.shape({
    category: PropTypes.string.isRequired, // 'Medias' | 'Wheels' | 'Condition'
    description: PropTypes.string.isRequired, // 'Exterior Left Front',
    area: PropTypes.string, // 'Exterior',
    cloudPicker: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => {
  const { item } = state.core;
  return { item };
};

export default compose(
  withCookies,
  connect(mapStateToProps, {
    updateItem,
    updatePhotos,
    updatePhotosAsync,
  })
)(ImageUploader);
