import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M683.4,161.3v0.7c9.2,1.8,17.7,4.4,25.6,7.6c0.7,0.4,1.5,0.7,2.2,1.1c-3.2-1.8-6.1-3.7-8.9-5.9
	c-4-3-10.6-5.5-19.7-7.2l-7.8-1.2L683.4,161.3L683.4,161.3z"
          />
          <path
            fill="#FFFFFF"
            d="M683.4,162v-0.7l-8.7-4.8c-26-3.7-55.4-3.9-88.6-0.6l-12.2,1.1c12.5-0.6,25.3-0.7,38.4-0.4
	c15.8,0.3,32.2,1.1,48.8,2.3C669.1,159.5,676.4,160.5,683.4,162L683.4,162z"
          />
          <path
            fill="#FFFFFF"
            d="M683.4,162c-7-1.4-14.3-2.5-22.1-3c-16.8-1.2-33-2.1-48.8-2.3l7.4,4.4c17.6,1.4,35.2,2.5,52.5,2.9
	c4.7,0.1,9.2,0.7,13.6,1.5L683.4,162L683.4,162z"
          />
          <path
            fill="#FFFFFF"
            d="M711.2,170.7c-0.7-0.4-1.5-0.8-2.2-1.1c-7.8-3.2-16.4-5.8-25.6-7.6l2.6,3.4l0.7,0.1c1.9,0.3,3.8,0.7,5.6,1.2
	c12.9,3.2,23.4,7.3,31.5,12.5c2.5,1.5,4.8,3.3,6.9,5.1c-4.8-4.5-10.3-8.5-16.4-12L711.2,170.7L711.2,170.7z"
          />
          <path
            fill="#FFFFFF"
            d="M695.7,169.6l3.3,7c12.8,5.1,22.4,14,28.8,26.7c1.1,2.1,1.9,4.1,2.9,6.3l8.3,7.7c6.1,4.7,10,13.5,12.2,26.3
	c1.7,15.7,2.6,31.1,3,46.4v17.7c-0.4,15.4-1.4,30.8-3,46.4c-2.2,12.8-6.2,21.6-12.2,26.3l-8.3,7.7c-0.8,2.2-1.8,4.3-2.9,6.3
	c-6.3,12.7-16,21.6-28.8,26.7l-3.3,7c10.5-1.5,19.8-4.7,28.2-9.8c2.5-1.5,4.8-3.3,6.9-5.1c6.7-6.3,12.4-13.8,16.6-22.3l0.5-1.1
	c3.4-7,6.1-14.7,7.8-23.2c3.7-19.5,5.9-39.3,6.5-59.2v-17.3c-0.5-19.8-2.8-39.6-6.5-59.2c-1.8-8.4-4.4-16.1-7.8-23.1l-0.5-1.1
	c-4.4-8.5-9.9-15.8-16.6-22.3c-2.1-1.8-4.3-3.4-6.9-5.1C715.5,174.2,706.1,170.9,695.7,169.6L695.7,169.6z"
          />
          <path
            fill="#FFFFFF"
            d="M699,176.6l-3.3-7l-14.7,2.5c3.3,0.4,6.5,1,9.4,1.8C693.3,174.5,696.2,175.5,699,176.6L699,176.6z"
          />
          <path
            fill="#E6E7E7"
            d="M693.2,177.3l1.2-0.8l-5.2-1.5l5.2,1.5l1.8,0.6l2.1,0.8l0.5-1.4c-2.8-1.1-5.5-1.9-8.5-2.8l-2.8,1.5
	L693.2,177.3L693.2,177.3z"
          />
          <path fill="#E6E7E7" d="M694.6,176.6l-1.4,0.7l1.8,0.7l1.2-0.8" />
          <path
            fill="#D2D3D4"
            d="M686,176.4l1.8-1.1l2.8-1.5c-3-0.7-6.1-1.2-9.4-1.8l-1-0.1c-6.9,3.6-12.9,8.4-18,14.6
	c-0.5,0.8-0.8,1.8-0.4,2.9l0.1-0.1l1.2-1.2c7,1.9,13.3,4.4,19.3,7.3l1.1,0.6l2.2,0.1c-6.6-3.6-13.9-6.5-21.9-8.7l1.2-1.2l1-1
	c10,2.9,19.1,6.7,27,11.6l3.3,0.3c-5.1-3.2-10.6-5.9-16.6-8.5l-4.3-1.8c-1.5-0.6-1.7-1.4-0.3-2.6l5.9-4.1l1.7-1.1
	c0.3-0.3,0.7-0.4,1-0.7l1.7-1.1L686,176.4L686,176.4z"
          />
          <path
            fill="#E6E7E7"
            d="M687.7,175.3l-1.7,1.1l5.5,1.9l3.4-0.4l-1.8-0.7 M686,176.4l-1,0.7l5.5,1.8l1-0.6"
          />
          <path
            fill="#FFFFFF"
            d="M680.1,172l1,0.1l14.6-2.5l-8.9-4.1l-0.7-0.1c-4.4-0.8-8.9-1.2-13.6-1.5c-17.5-0.6-34.9-1.5-52.5-2.9l10,6.1
	C646.7,168.5,663.3,170.3,680.1,172L680.1,172z"
          />
          <path
            fill="#E6E7E7"
            d="M687.8,180.6l1-0.6l-5.5-1.8c-0.3,0.1-0.7,0.4-1,0.7L687.8,180.6L687.8,180.6z"
          />
          <path fill="#E6E7E7" d="M688.8,180l-1,0.6l1.8,0.6l2.8-1.6" />
          <path
            fill="#E6E7E7"
            d="M682.3,178.8l-1.7,1.1l5.5,1.8l3.4-0.6l-1.8-0.6"
          />
          <path
            fill="#EBEBEB"
            d="M687.4,187.6l-0.1-0.1l-1.1-0.6l-0.1-0.1l-0.4-0.3l-0.8-0.4l10.2-5.9c1.4-0.6,2.8-0.7,4.1-0.1
	c3.6,1.5,6.9,3.4,9.9,5.6c5.5,4,9.9,9.1,13.2,15.1l0.5,1.2l3,0.8c-6.2-11.6-15.3-19.8-27.2-24.8l-2.1-0.8l-1.2,0.8l-2.6,1.5
	l-2.8,1.6l-6.6,4c-0.5,0.6-0.3,1.2,1.1,1.9l1.9,1.1c5.2,3.2,10,6.2,14.3,9.2l0.3,0.1l2.8,0.4l-1.5-1.2
	C697.4,193.8,692.6,190.8,687.4,187.6L687.4,187.6z"
          />
          <path
            fill="#E6E7E7"
            d="M687.3,187.6l0.1,0.1c5.2,3,10,6.1,14.6,9.2c4.4,0.7,8.4,1.4,12,2.1l1.5,0.3c-0.3-1-0.7-2.1-1.2-3.2
	c-4.5-7.4-10-12.4-16.4-14.9l-1.4-0.1c-0.3,0-0.5,0.1-0.7,0.3l-9.5,5.5L687.3,187.6L687.3,187.6z M696.6,182.4l1.4,0.1
	c5.1,2.1,9.6,5.6,13.5,11c0.5,0.6,0.8,1.2,1.1,1.9v0.1c0.1,0.6-0.1,0.8-0.5,1l-9.2-1.1c-1.1-0.1-1.9-0.4-2.8-1l-11-7.7l6.9-4.1
	C696.1,182.5,696.3,182.4,696.6,182.4L696.6,182.4z"
          />
          <path
            fill="#E6E7E7"
            d="M698,182.5l-1.4-0.1c-0.3,0-0.5,0.1-0.7,0.3l-6.9,4.1l11,7.7c0.7,0.6,1.7,1,2.8,1l9.2,1.1
	c0.5-0.1,0.7-0.4,0.5-1v-0.1c-0.3-0.7-0.7-1.4-1.1-1.9C707.6,188.1,703.1,184.4,698,182.5L698,182.5z M695.8,183.5l0.7-0.1l1.2,0.1
	c4.8,1.8,8.9,5.1,12.4,9.9l0.4,0.6c0.3,0.3,0.5,0.7,0.7,1.1l-8.9-0.1c-0.7-0.1-1.4-0.4-1.9-0.8l-2.1-1.5l-7.2-5.1l-0.8-0.6
	L695.8,183.5L695.8,183.5z"
          />
          <path
            fill="#E6E7E7"
            d="M696.5,183.2l-0.7,0.1l-5.5,3.3l0.8,0.6l3.9-2.3c1.4-0.6,2.6-0.6,3.9,0c0.3,0.1,0.5,0.3,0.8,0.4l8,8h-6.3
	c-1.1,0-2.1-0.3-2.8-0.8l2.1,1.5c0.6,0.4,1.2,0.7,1.9,0.8l8.9,0.1c-0.1-0.4-0.4-0.7-0.7-1.1l-0.4-0.6c-3.6-4.8-7.7-8.1-12.4-9.9
	L696.5,183.2L696.5,183.2z"
          />
          <path
            fill="#E6E7E7"
            d="M693.3,187.2l-2.2,0.1l7.2,5.1c0.8,0.4,1.7,0.7,2.8,0.8h4.3c-2.1-0.1-4.1-0.7-5.8-1.6L693.3,187.2
	L693.3,187.2z"
          />
          <path
            fill="#E6E7E7"
            d="M691.1,187.3l2.2-0.1l3-1.9c0.8-0.3,1.5-0.4,2.3-0.4c-1.1-0.6-2.5-0.6-3.9,0L691.1,187.3L691.1,187.3z"
          />
          <path
            fill="#E6E7E7"
            d="M698.7,184.8c-0.7,0-1.5,0.1-2.3,0.4l-3,1.9l6.2,4.4c1.8,1,3.7,1.5,5.8,1.6h2.1l-8-8
	C699.2,185.1,699,185,698.7,184.8L698.7,184.8z"
          />
          <path
            fill="#D2D3D4"
            d="M686.2,188.3l-1.9-1.1c-1.4-0.7-1.8-1.4-1.1-1.9l6.6-4l-3.4,0.4l-7,4.1c-1.5,1.1-1.4,1.9,0.3,2.8
	c5.9,2.6,11.6,5.5,16.6,8.5l4.7,0.6l-0.3-0.1C696.1,194.5,691.4,191.4,686.2,188.3L686.2,188.3z"
          />
          <path
            fill="#E6E7E7"
            d="M684.7,186.1l0.8,0.4l10.2-5.9l2.1-0.1c6.7,2.5,12.4,7.4,17.1,15.1c0.7,1.2,1.1,2.3,1.4,3.3
	c-0.3-1.1-0.7-2.2-1.4-3.3c-4.7-7.6-10.3-12.7-17.1-15.1l-2.1,0.1l-10.2,5.9l0.4,0.3l0.1,0.1l9.5-5.5c0.3-0.1,0.4-0.3,0.7-0.3
	l1.4,0.1c6.5,2.5,12,7.4,16.4,14.9c0.5,1.1,1.1,2.2,1.2,3.2h0.1l3.2,0.7c-2.3-5.8-5.6-10.6-9.9-14.4c-3-2.2-6.3-4-9.9-5.6
	c-1.4-0.4-2.8-0.4-4.1,0.1L684.7,186.1L684.7,186.1z"
          />
          <path
            fill="#D2D3D4"
            d="M686.2,181.7l-5.4-1.8l-5.9,4.1c-1.4,1.2-1.2,2.1,0.3,2.6l4.3,1.8c-1.7-0.8-1.8-1.8-0.3-2.8L686.2,181.7
	L686.2,181.7z"
          />
          <path
            fill="#E6E7E7"
            d="M683.3,178.1l5.5,1.9l3.6-0.6l-1.8-0.6l-5.5-1.8"
          />
          <path fill="#E6E7E7" d="M691.5,178.4l-1,0.6l1.8,0.6l2.6-1.5" />
          <path
            fill="#EBEBEB"
            d="M699,176.6l-0.5,1.4c12,5,21,13.2,27.2,24.8l2.1,0.6C721.4,190.6,711.8,181.7,699,176.6L699,176.6z"
          />
          <path
            fill="#FFFFFF"
            d="M692.4,166.7c-1.8-0.4-3.7-0.8-5.6-1.2l8.9,4.1c10.5,1.5,19.8,4.7,28.2,9.6
	C715.6,174.1,705.2,169.8,692.4,166.7L692.4,166.7z M695.9,197.1l-3.3-0.3l-2.9-0.3h-0.1l-4.1-0.4l-2.2-0.1l-4.4-0.3h-1
	c1.7,1.6,3.2,3.7,4.5,6.5v0.1l8.5,0.7c19.9,1.9,31.8,7.7,35.6,17.3l8.9,6.3c-1.4-6.1-3-11.7-5.1-16.8c-0.8-2.2-1.8-4.4-2.9-6.3
	l-2.1-0.6l-3-0.8c-2.3-0.6-4.7-1.2-7.2-1.6c-3.9-0.8-7.8-1.5-12-2.1l-2.8-0.4L695.9,197.1L695.9,197.1z"
          />
          <path
            fill="#D2D3D4"
            d="M689.9,196.5l2.9,0.3c-7.8-4.8-16.8-8.7-27-11.6l-1,1c9.2,2.6,17.5,6.1,24.8,10.3H689.9L689.9,196.5z
	 M683.3,196l-1.1-0.6c-5.9-2.9-12.4-5.4-19.3-7.3l-1.2,1.2c5,1.4,9.6,3,14,4.8h0.5l0.8,0.6l1.8,0.8L683.3,196L683.3,196z"
          />
          <path
            fill="#656565"
            d="M683.1,203.3c1.7,3.6,3,7.8,4.1,12.9c6.3-2.1,12.7-1.9,18.7,0.1c12.5,5.9,19.7,25.6,21.2,59l0.3,14.6
	l-0.3,32.3c-1.5,33.4-8.7,53.1-21.2,59c-6.1,2.1-12.2,2.2-18.7,0.1c-1.1,5.1-2.5,9.4-4,12.9l-0.6,1.1l8.5-0.7
	c19.9-1.9,31.8-7.7,35.6-17.3h-1.7c-4.1,8.5-15.3,14-33.3,16.2l-8.4,0.7l8.4-0.7c18-2.2,29.2-7.7,33.3-16.2l0.4-1.1
	c4.5-11.8,7.2-26.1,7.7-43.3c0.3-8.5,0.4-17.1,0.4-25.5v-17.5c-0.1-8.5-0.3-16.9-0.4-25.4c-0.5-17.1-3.2-31.5-7.7-43.3l-0.4-1.1
	c-4.1-8.5-15.3-13.9-33.3-16.2l-8.4-0.7l8.4,0.7c17.9,2.2,29,7.7,33.2,16.2h1.7c-3.9-9.6-15.8-15.4-35.6-17.3l-8.5-0.7L683.1,203.3
	L683.1,203.3z"
          />
          <path
            fill="#FFFFFF"
            d="M683.1,203.4l-0.5-1.2V202c-1.4-2.6-2.9-4.8-4.5-6.5l-0.8-0.8l-0.8-0.6h-12.2c-7.4,4.4-12.4,9.5-15,15.3
	c-0.5,1.6-1.1,3.4-1.2,5.2h5.9c8.4-0.1,13.1,4.5,14,14c1.2,20.2,2.1,40.7,2.2,61.2v17.6c-0.1,20.5-1,41-2.2,61.4
	c-1,9.4-5.6,14-14,14h-5.9c0.3,1.8,0.7,3.6,1.2,5.2c2.5,5.8,7.6,10.9,15,15.3h12.2l0.8-0.5l0.8-0.8c1.7-1.6,3.2-3.7,4.5-6.5v-0.1
	l0.5-1.1c1.7-3.4,2.9-7.8,4-12.9l0.3-1c1.8-8.5,2.8-19.4,3-32.3v-98.8c-0.3-12.9-1.4-23.7-3-32.2l-0.3-1
	C686.2,211.1,684.8,206.9,683.1,203.4L683.1,203.4z"
          />
          <path
            fill="#D2D3D4"
            d="M685.6,196.1l4.1,0.4c-7.3-4.3-15.5-7.7-24.8-10.3l-1.2,1.2C671.7,189.7,679,192.5,685.6,196.1L685.6,196.1z
	"
          />
          <path
            fill="#EBEBEB"
            d="M715.6,199.3L714,199c-3.6-0.7-7.7-1.4-12-2.1l1.5,1.2c4.1,0.6,8.1,1.2,12,2.1c2.5,0.6,4.8,1.1,7.2,1.6
	l-0.5-1.2c-3.3-6.1-7.7-11.1-13.2-15.1c4.4,3.7,7.7,8.5,9.9,14.4L715.6,199.3z"
          />
          <path
            fill="#FFFFFF"
            d="M661.5,189.5c-0.3-1.2-0.1-2.2,0.4-2.9c5.2-6.2,11.1-11,18-14.6c-16.6-1.8-33.4-3.4-50.1-5
	c-0.7,4-2.1,7.8-4,11.7c2.9,0.6,5.5,1.5,7.8,2.6c5.9,3,11.1,6.9,15.8,11.4c1.8,1.8,1.8,3-0.1,3.9l-0.3,0.1c-0.5,0.3-1.2,0.4-2.2,0.6
	c-12.4,1.1-25.2,1.9-38.5,2.3l-6.6,0.1l-10.6,0.1c-36.7,1-73.5,0.7-110.2-1c-29.9-1.2-59.6-3.4-89.3-6.5c-23.7-2.9-47-5.9-70.3-9.4
	c-20.2-2.9-40.2-6.1-60.1-9.5c-2.3,2.6-4.3,5.5-5.5,8.8l13.6,0.7l7.8,1.1l103.6,14.3c2.1,0.3,3.3,1.4,3.9,3.2
	c65.5,8.3,134.8,12.5,208,12.9h55c0.3-1.8,0.7-3.6,1.2-5.2c2.5-5.8,7.6-10.9,15-15.3L661.5,189.5L661.5,189.5z"
          />
          <path
            fill="#E6E7E7"
            d="M661.7,189.4l2.3,4.8h11.7C671.3,192.4,666.6,190.8,661.7,189.4L661.7,189.4z"
          />
          <path
            fill="#FFFFFF"
            d="M649.4,196.7c1.9-0.8,1.9-2.2,0.1-3.9c-4.7-4.5-9.9-8.4-15.8-11.4c-2.3-1.2-5-2.1-7.8-2.6l-1.4,1.5
	c1.8,0.4,3.3,0.8,4.8,1.4c1.1,0.4,2.1,0.8,2.9,1.2c5.9,3.2,11.1,6.9,15.8,11.4l0.1,0.1C649.2,195.3,649.6,196,649.4,196.7
	L649.4,196.7z"
          />
          <path
            fill="#656565"
            d="M649.2,196.8l0.3-0.1c0.1-0.7-0.3-1.4-1.1-2.3l-2.8,0.3L642,195l-23.5,1.6l-4.7,0.3l-2.5,0.1
	c5.6-5.5,11.7-10.6,18-15.4c-1.5-0.6-3.2-1-4.8-1.4C616.4,186.4,609,193,602,200l6.6-0.1c13.2-0.4,26.1-1.2,38.5-2.3
	C647.9,197.2,648.6,196.9,649.2,196.8L649.2,196.8z"
          />
          <path
            fill="#656565"
            d="M645.6,194.7l2.8-0.3l-0.1-0.1c-4.7-4.5-9.9-8.4-15.8-11.4l-2.9-1.2v2.1l1.7,0.8
	C636.4,187.3,641.2,190.8,645.6,194.7L645.6,194.7z"
          />
          <path
            fill="#656565"
            d="M642,195l3.6-0.4c-4.3-4-9.2-7.4-14.6-10.2l-1.7-0.8l-2.1,1.6c-4.8,3.7-9.2,7.7-13.5,11.7l4.7-0.3
	c3.7-3.4,7.6-6.7,11.7-9.9C634.4,189.1,638.3,191.9,642,195L642,195z"
          />
          <path
            fill="#FFFFFF"
            d="M677.9,195.7h1l-1.8-0.8 M653.7,214.8h-5.9c-0.3,1.2-0.3,2.6-0.3,4v0.3h0.1h-0.1v78.6l17.2,0.1l1.4-0.1
	l1.1,0.1c0.3,0,0.4,0.1,0.5,0.3c0.3,0.1,0.4,0.3,0.6,0.5l0.1,0.3v0.1l-0.1,0.4l-0.5,0.5l-0.6,0.3l-1.1,0.1l-1.4-0.1l-17.2,0.1v78.5
	h0.1h-0.1v0.3c0,1.4,0.1,2.6,0.3,4h5.9c8.4,0.1,13.1-4.5,14-14c1.2-20.4,2.1-40.7,2.2-61.4v-17.7c-0.1-20.5-1-41-2.2-61.2
	C666.8,219.4,662.1,214.7,653.7,214.8L653.7,214.8z M647.4,218.7c0-1.4,0.1-2.6,0.3-4h-55c-73.2-0.3-142.5-4.5-208-12.9
	c0.4,1.1,0.5,2.6,0.3,4.3c65.3,8.3,134.5,12.5,207.4,12.8h54.8L647.4,218.7L647.4,218.7z"
          />
          <path
            fill="#EBEBEB"
            d="M687.1,216.2l0.3,1c1.8,8.5,2.8,19.4,3,32.2v98.8c-0.3,12.9-1.4,23.7-3,32.3l-0.3,1
	c6.3,2.1,12.7,1.9,18.7-0.1c12.5-5.9,19.7-25.6,21.2-59l0.3-32.3l-0.3-14.6c-1.5-33.4-8.7-53.1-21.2-59
	C699.8,214.3,693.6,214.1,687.1,216.2L687.1,216.2z"
          />
          <path
            fill="#FFFFFF"
            d="M735.7,226.5l-8.9-6.3c4.5,11.8,7.2,26.3,7.7,43.5c0.3,8.8,0.4,17.6,0.5,26.3v17.7
	c-0.1,8.8-0.3,17.5-0.5,26.3c-0.5,17.2-3.2,31.8-7.7,43.6l8.9-6.3c1-4.4,1.7-8.9,2.2-13.9c0.4-3.7,0.7-7.6,0.8-11.6
	c0.5-12.8,0.8-25.5,1-38.1v-17.6c-0.1-12.7-0.4-25.3-1-38.1c-0.1-4-0.4-7.8-0.8-11.6C737.3,235.5,736.5,230.8,735.7,226.5
	L735.7,226.5z"
          />
          <path
            fill="#FFFFFF"
            d="M726.8,220.2h-1.7l0.4,1.1c4.5,11.8,7.2,26.1,7.7,43.3c0.3,8.5,0.4,17.1,0.4,25.5v17.7
	c0,8.5-0.1,17.1-0.4,25.5c-0.5,17.1-3.2,31.5-7.7,43.3l-0.4,1.1h1.7c4.5-11.8,7.2-26.3,7.7-43.6c0.3-8.8,0.4-17.6,0.5-26.3v-17.7
	c-0.1-8.8-0.3-17.5-0.5-26.3C733.9,246.5,731.3,232,726.8,220.2L726.8,220.2z M737.9,240.3c0.4,3.7,0.7,7.6,0.8,11.6
	c0.5,12.8,0.8,25.5,1,38.1v17.7c-0.1,12.7-0.4,25.3-1,38.1c-0.1,4-0.4,7.8-0.8,11.6l1.2-3.4c1.4-3.4,2.1-7,2.2-10.7v-0.3
	c0.4-11.8,0.8-23.5,0.8-35.4v-17.5c-0.1-11.7-0.4-23.5-0.8-35.4v-0.1c-0.1-3.7-1-7.3-2.2-10.7L737.9,240.3L737.9,240.3z"
          />
          <path
            fill="#FFFFFF"
            d="M741.5,254.4v0.1c0.4,11.8,0.7,23.7,0.8,35.4v17.7c-0.1,11.7-0.4,23.5-0.8,35.4v0.3c-0.1,3.7-1,7.3-2.2,10.7
	l-1.2,3.4c-0.5,4.8-1.2,9.5-2.2,13.9c-1.4,6.1-3,11.7-5.1,16.8c4.8-5.8,8.3-12.9,9.9-21.5c1.1-6.5,1.8-13.3,2.2-20.8
	c0.6-12.8,0.8-25.5,1-38.1v-17.7c-0.1-12.7-0.4-25.3-1-38.1c-0.3-7.4-1.1-14.3-2.2-20.8c-1.8-8.5-5.1-15.7-9.9-21.5
	c2.1,5.2,3.7,10.7,5.1,16.8c1,4.4,1.7,9.1,2.2,13.9l1.2,3.4C740.5,247.2,741.2,250.9,741.5,254.4L741.5,254.4z"
          />
          <path
            fill="#FFFFFF"
            d="M751.1,243.6c-2.2-12.8-6.2-21.6-12.2-26.3l-8.3-7.7c4.8,5.8,8.3,12.9,9.9,21.5c1.1,6.5,1.8,13.3,2.2,20.8
	c0.6,12.8,0.8,25.5,1,38.1v17.7c-0.1,12.7-0.4,25.3-1,38.1c-0.3,7.4-1.1,14.3-2.2,20.8c-1.8,8.5-5.1,15.7-9.9,21.5l8.3-7.7
	c6.1-4.7,10-13.5,12.2-26.3c1.7-15.5,2.6-31.1,3-46.4v-17.6C753.7,274.7,752.7,259.3,751.1,243.6L751.1,243.6z"
          />
          <path
            fill="#FFFFFF"
            d="M747.9,207.7c3.4,7,6.1,14.7,7.8,23.1c3.7,19.5,5.9,39.3,6.5,59.2v17.7c-0.5,19.8-2.8,39.6-6.5,59.2
	c-1.8,8.4-4.4,16.2-7.8,23.2c4-4.8,7.3-12.7,10-23.2c3.7-19.5,5.9-39.3,6.5-59.2l0.1-12.7l-0.1-5.1c-0.5-19.8-2.8-39.6-6.5-59.2
	C755.2,220.3,751.8,212.5,747.9,207.7L747.9,207.7z M79.5,246.6l-0.1,1.9l1.5,4.5l0.6-8.1c1.8-12.8,4.5-22.4,7.8-28.9
	c-4.7,6.5-7.8,16.1-9.6,28.9L79.5,246.6L79.5,246.6z"
          />
          <path
            fill="#FFFFFF"
            d="M79.5,246.6l0.1-1.6c1.8-12.8,5.1-22.4,9.6-28.9v-0.1c1.2-1.6,2.6-3.4,4-5.1c14.6-17.2,32.1-31.6,52.5-43.1
	l0.7-0.4l1.5-0.8c-0.8-0.1-2.1,0.1-3.7,1c-23.8,13.2-43.5,30.3-58.7,51L81,225c-1,1.6-1.5,3.4-1.5,5.6L79.5,246.6L79.5,246.6
	L79.5,246.6z"
          />
          <path
            fill="#FFFFFF"
            d="M146.5,167.2l-0.7,0.4c-20.5,11.4-38,25.9-52.4,43.2c13.5-14.3,27.8-26.3,42.9-35.8l5.1-3.4
	C143.2,170.1,145,168.7,146.5,167.2L146.5,167.2z"
          />
          <path
            fill="#FFFFFF"
            d="M148.2,166.4l-1.5,0.8c-1.5,1.5-3.3,2.9-5.1,4.3c8.3-4.7,16.9-7.6,26-8.4l6.5-0.4l12-6.2l-17.2,1.5
	c-5.4,0.8-10.2,2.2-14.2,4.3C152.4,163.8,150.4,165.2,148.2,166.4z"
          />
          <path
            fill="#EBEBEB"
            d="M157.5,178l-1.4,0.4c0,1-0.6,1.8-1.8,2.2h-0.1l-0.1,0.1c-12.8,4.7-26.3,12.9-40.3,24.9
	c-2.8,2.5-6.3,6.1-11,10.7c-3.3,3-5.4,7.2-5.9,12.5l1.1-0.7l0.1-1.1l0.6-1.8c1-3.3,2.8-6.1,5.1-8.1l1.7-1.6l9.5-9.1
	c14.9-12.7,29.2-21.2,42.6-25.7c2.2-0.3,4-0.1,5.2,0.6l0.4,0.3l17.7-4.5l4.1,1.2l-17.1,4.5c-2.1,0.7-4,1.8-5.8,3.3l-0.8,0.8
	l-1.4,1.5c-1.8,1.8-3.9,3.2-6.1,4.5c-0.4,0.3-0.8,0.6-1.1,0.8c-0.8,0.4-1.5,1-2.3,1.4c-11.3,6.9-22.8,13.8-34.8,20.6l-16.9,9.8
	l-0.6,1.8c7-4,13.8-7.8,20.2-11.6c11.7-6.9,23.1-13.8,34.3-20.5l1.1-0.7c2.2-1.2,4.3-2.8,6.1-4.5l3.4-3.3c1.5-1.2,3.2-2.1,5.1-2.6
	l9.9-2.6c2.3-0.8,5-1.5,7.8-2.1c7-1.2,14.9-2.3,23.2-3.3c-10-1.5-21-2.2-33-2.1h-2.1c-0.4,0-0.8,0-1.2,0.1H172
	c-18.6,1.4-38.8,11.3-60.5,29.7c-3.3,3-7.8,7.6-13.9,13.5c-3.3,4.1-5,8.1-4.8,12.4v0.6c0.1,0.6,0.4,0.8,1,0.6v-1.5
	c0.3-3.7,1.9-7.4,4.8-11.1c5.9-5.9,10.6-10.5,13.9-13.5c15.3-12.9,29.9-21.6,43.6-26L157.5,178c6.1-1.8,12.1-2.9,17.9-3h7.6
	l-19.3,4.7L157.5,178L157.5,178z"
          />
          <path
            fill="#EBEBEB"
            d="M154.3,180.6c1.2-0.6,1.8-1.2,1.8-2.2c-13.8,4.4-28.3,13.1-43.6,26c-3.3,3-7.8,7.6-13.9,13.5
	c-3,3.6-4.5,7.3-4.8,11.1v1.5l0.7-0.3c0.7-0.4,1.5-0.8,2.2-1.2c0.7-5.4,2.6-9.5,5.9-12.5c4.7-4.5,8.3-8.1,11-10.7
	c14-12,27.5-20.2,40.3-24.9L154.3,180.6L154.3,180.6z M152.6,184.8v8.7c0.4-0.3,0.8-0.6,1.1-0.8c2.2-1.2,4.3-2.8,6.1-4.5l1.4-1.5
	v-5.5C157.6,181.7,154.8,182.8,152.6,184.8L152.6,184.8z"
          />
          <path
            fill="#EBEBEB"
            d="M152.6,185.8v-1.1c2.2-1.9,5.1-3.2,8.7-3.6h1.2c-1.2-0.7-3-0.8-5.2-0.6c-13.5,4.5-27.8,13.1-42.6,25.7
	l-9.5,9.1h10.5c11.8-6.9,23.5-13.8,34.8-20.6v-3.4C150.2,189.1,151,187.2,152.6,185.8L152.6,185.8z"
          />
          <path
            fill="#EBEBEB"
            d="M150.2,194.9c0.8-0.4,1.5-1,2.3-1.4v-7.6c-1.5,1.4-2.3,3.2-2.3,5.6V194.9L150.2,194.9z"
          />
          <path
            fill="#EBEBEB"
            d="M153.8,194.3l-1.1,0.7c-11.1,6.9-22.6,13.6-34.3,20.5c-6.5,3.9-13.2,7.7-20.2,11.6l-0.1,1.1l0.8-0.4
	l21.3-12.4c11.1-6.5,22-13.1,32.6-19.5l19.5-12.1c1.8-1,3.9-1.8,6.2-2.6l-9.9,2.6c-1.9,0.6-3.6,1.5-5.1,2.6l-3.4,3.3
	C158.1,191.6,156,193.1,153.8,194.3L153.8,194.3z"
          />
          <path
            fill="#FFFFFF"
            d="M141.4,171.6l-5.1,3.4c-15.1,9.5-29.4,21.5-42.9,35.9c-1.4,1.6-2.8,3.3-4,5.1c15.8-18,33.7-31.6,53.5-40.9
	c-19.8,9.2-37.7,22.8-53.5,40.9v0.1c-3.4,6.5-6.1,16.1-7.8,28.9l-0.6,8.1c-0.8,12.4-1.2,24.6-1.4,37V308c0.1,12.2,0.6,24.6,1.4,37
	l0.6,8.1c1.8,12.8,4.5,22.4,7.8,28.9v0.1c15.8,18,33.7,31.6,53.5,40.9c-19.8-9.2-37.7-22.8-53.5-40.9l4,5.1
	c13.5,14.4,27.9,26.4,42.9,35.9l5.1,3.4c8.3,4.7,16.9,7.6,26,8.4l6.5,0.4c-1.8-1.2-3.7-3.9-5.5-7.8c-0.3-0.7-1-1.5-1.8-2.2l-1.9-1.4
	c-17.1-2.1-35.6-11.1-56-27.4c-4.4-3.9-8.5-7.6-12.1-11.4c-6.3-7.4-9.5-14.2-9.8-20.4c0.1-3.2,2.1-4.7,5.5-4.3l2.5,0.5
	c-2.3-13.9-3.9-27.9-4.5-42L90,314l-0.3-20.2l0.3-9.2l0.3-5.1c0.7-14,2.2-28.1,4.5-42.1l-2.5,0.6c-3.6,0.4-5.4-1.1-5.5-4.3
	c0.1-6.1,3.4-12.8,9.8-20.4c3.6-3.9,7.7-7.6,12.1-11.4c20.4-16.2,38.9-25.3,56-27.4l1.9-1.4c0.8-0.7,1.5-1.5,1.8-2.2
	c1.8-4,3.6-6.6,5.5-7.8l-6.5,0.4C158.3,164.1,149.7,166.8,141.4,171.6L141.4,171.6z"
          />
          <path
            fill="#FFFFFF"
            d="M108.8,201.6c-4.4,3.9-8.5,7.6-12.1,11.4c-6.3,7.4-9.5,14.2-9.8,20.4c0.1,3.2,2.1,4.7,5.5,4.3l2.5-0.6
	l3.7-9.2l-0.8,0.4l-1.1,0.7c-0.8,0.4-1.5,0.8-2.2,1.2l-0.7,0.3c-0.6,0.1-0.8,0-1-0.6v-0.6c0-4.1,1.5-8.3,4.8-12.4
	c6.1-5.9,10.6-10.5,13.9-13.5c21.7-18.4,41.8-28.3,60.5-29.7l-7.3,0.3C147.7,176.2,129,185.4,108.8,201.6L108.8,201.6z"
          />
          <path
            fill="#EBEBEB"
            d="M105.2,215.5l-1.7,1.6c-2.3,2.2-4,4.8-5.1,8.1l17.1-9.8H105.2z"
          />
          <path
            fill="#FFFFFF"
            d="M98.6,227.9l-3.7,9.2l124.2-29.2l-120,24.2c15-10.3,30.1-18.6,45.4-25c25.3-10.2,57.1-18,95.6-23.5
	c5.1-0.7,10.3-1,15.5-1c1.4-3.2,3.2-6.2,5.5-8.8l-9.1-1.5c-16.1,0.8-30.4,2.1-43.1,3.6c-8.5,1-16.2,2.1-23.2,3.3
	c-2.9,0.6-5.5,1.2-7.8,2.1c-2.3,0.8-4.4,1.7-6.2,2.6L152.3,196c-10.6,6.5-21.5,13.1-32.6,19.5C119.7,215.5,98.6,227.9,98.6,227.9z"
          />
          <path
            fill="#FFFFFF"
            d="M144.7,207.1c-15.4,6.5-30.5,14.7-45.4,25l120-24.2L94.9,237.1c-2.3,13.9-3.9,27.9-4.5,42.1
	c13.1-2.5,26.4-4.7,39.8-6.7l24.1-3.6c7.7-0.8,15.4-1.5,22.8-1.9c11.7-0.5,23.4-0.7,34.8-0.1l-34.8,4l-22.8,3l-24.1,3.6
	c-13.5,2.1-26.8,4.3-40,6.7l-0.3,9.2l0.3,20.2c13.2,2.5,26.6,4.7,40,6.7l24.1,3.6l22.8,3l34.8,4c-11.4,0.5-23.1,0.5-34.8-0.1
	c-7.6-0.4-15.1-1.1-22.8-1.9l-24.1-3.6c-13.3-2.1-26.7-4.3-39.8-6.6c0.7,14,2.2,28.1,4.5,42l124.2,29.3L99.3,365.7
	c15,10.3,30.1,18.6,45.4,25c25.3,10.2,57.1,18,95.6,23.5c5.1,0.7,10.3,1,15.5,1v-2.9c-5.8-1.4-10.6-3.6-14.6-6.7l-2.5-2.1
	c-9.6-8.9-16.5-25.3-20.6-49c-1.6-15.3-2.6-30.8-2.8-46.6v-17.9c0.1-15.8,1.1-31.4,2.8-46.6c4.1-23.7,11-40,20.6-49
	c0.8-0.8,1.7-1.5,2.5-2.1c4-3.2,8.8-5.5,14.6-6.7v-2.9c-5.2,0-10.5,0.3-15.5,1C201.8,189.1,170,196.8,144.7,207.1L144.7,207.1z
	 M188.5,257.9c-0.4,0-0.6-0.1-0.7-0.4V256c0-0.6,0.3-0.8,0.8-0.8h1.6c0.6,0,0.8,0.3,0.8,0.8v1.5c-0.1,0.3-0.3,0.4-0.7,0.4
	L188.5,257.9L188.5,257.9z M190.9,340.7v1.2c0,0.5-0.3,0.8-0.8,0.8h-1.6c-0.6,0-0.8-0.3-0.8-0.8v-1.5c0.1-0.3,0.4-0.4,0.7-0.4h1.6
	c0.4,0,0.7,0.1,0.7,0.4L190.9,340.7L190.9,340.7z"
          />
          <path
            fill="#FFFFFF"
            d="M187.8,257.3c0.1,0.3,0.4,0.4,0.7,0.4h1.8c0.4,0,0.6-0.1,0.7-0.4v-1.5c0-0.6-0.3-0.8-0.8-0.8h-1.6
	c-0.6,0-0.8,0.3-0.8,0.8v1.2L187.8,257.3L187.8,257.3z M190.9,341.8v-1.5c-0.1-0.3-0.4-0.4-0.7-0.4h-1.8c-0.4,0-0.6,0.1-0.7,0.4v1.5
	c0,0.5,0.3,0.8,0.8,0.8h1.6C190.7,342.6,190.9,342.3,190.9,341.8L190.9,341.8z M80.9,253.1l-1.5-4.5c-1,13.9-1.5,27.8-1.7,41.5
	c0,0.4-0.1,0.8-0.1,1.2l0.1,16.5c0.1,13.8,0.7,27.6,1.7,41.5l1.5-4.5c-0.8-12.4-1.2-24.6-1.4-37v-17.7
	C79.6,277.7,80.2,265.5,80.9,253.1L80.9,253.1z M252.8,154.4h-0.3c-24.5-0.1-46.6,0.6-66.7,2.2l-12,6.2c21.9-1.5,44-3.4,66.2-5.8
	h0.3c13.8-0.6,26.7-0.8,38.9-0.7C270.7,155.1,261.9,154.6,252.8,154.4L252.8,154.4z"
          />
          <path
            fill="#FFFFFF"
            d="M279.3,156.4c-12.2-0.1-25.3,0-38.9,0.7H240c-22.1,2.3-44.2,4.3-66.2,5.8c-1.8,1.2-3.7,3.9-5.5,7.8
	c-0.3,0.7-1,1.5-1.8,2.2c20.5-1,41-2.9,61.4-5.6h0.3c9.8-1.2,18.4-3.6,26-7c2.2-1.1,5.1-1.8,8.7-2.1l20.6-0.7l-0.1-0.6L279.3,156.4
	L279.3,156.4z"
          />
          <path
            fill="#FFFFFF"
            d="M262.9,158.1c-3.6,0.3-6.5,1-8.7,2.1c-7.6,3.4-16.2,5.8-26,7h-0.3c-20.4,2.8-40.9,4.7-61.4,5.6l-1.9,1.4
	l7.3-0.3h1.1c0.4,0,0.8,0,1.2-0.1h2.1c12-0.1,23,0.6,33,2.1c12.7-1.5,27-2.8,43.1-3.6c1.5-0.4,3.2-0.8,5-1.2l8.1-0.8
	c7.7-0.3,15.4-0.4,23.1-0.6c-1.1-3.9-2.8-8-5.2-12.1L262.9,158.1L262.9,158.1z M286.3,157.3l-3-0.4l0.1,0.6"
          />
          <path
            fill="#FFFFFF"
            d="M297.8,163.4c-0.3-0.8-0.1-1.6,0.4-2.5l2.8-3l0.1-1.1l-14.9,0.6l-2.9,0.1c2.5,4.1,4.1,8.3,5.2,12.1
	c60.7-1.5,121.3-1.8,182.3-0.6c-0.5-3.6-1.5-7.6-2.9-12h-1.2l-15.8-0.6l-1.2,0.6l-3.2,0.8l-7,0.8l-1.8,0.1c-2.3,0.1-5,0.1-7.8,0.1
	l-11.1-0.4c-2.1-0.3-3.9-0.6-5.2-1c-1.2-0.3-2.3-0.6-3.2-1c-0.7-0.3-1.2-0.6-1.8-0.8c-27-0.4-54.1-0.4-81.2,0h-1.1l-1.5,3.9
	c-1,1.1-1.9,2.1-2.9,3h-0.1l-0.1,0.1l-0.7,0.6c-0.7,0.4-1.5,0.8-2.5,0.8h-7.8c-2.2,1.5-4.8,2.2-7.6,1.9
	C300.4,166,298.8,165,297.8,163.4L297.8,163.4z"
          />
          <path
            fill="#656565"
            d="M298.2,160.9c-0.5,0.8-0.7,1.5-0.4,2.5c1,1.6,2.6,2.6,5.1,2.9c2.8,0.3,5.4-0.4,7.6-1.9h-2.2
	c-1.5,0.7-3.2,0.7-4.8,0.3c-2.1-0.6-2.9-2.5-2.8-5.5l0.3-1.2L298.2,160.9L298.2,160.9z"
          />
          <path
            fill="#FFFFFF"
            d="M301.1,156.8l-0.1,1.1l-0.3,1.2c-0.1,3,0.8,5,2.8,5.5c1.7,0.4,3.2,0.3,4.8-0.3h10c0-1.1,0.1-2.3,0.5-3.7
	l1.2-4.5c2.6-9.1,6.5-17.7,11.3-25.9c-8.8,0.6-15.5,3-19.9,7.4C306.2,142.6,302.8,149.1,301.1,156.8L301.1,156.8z"
          />
          <path
            fill="#656565"
            d="M318.9,160.6c-0.4,1.4-0.5,2.6-0.5,3.7c1-0.1,1.6-0.4,2.5-0.8l0.7-0.6l0.4-1.8l1.4-5.2
	c2.2-7.6,5.2-15,8.9-22l0.1-0.1l0.1-0.1l1.2-2.3l-2.2-1.1c-4.8,8.1-8.7,16.8-11.3,25.9C319.7,157.6,319.3,159.1,318.9,160.6
	L318.9,160.6z"
          />
          <path
            fill="#EBEBEB"
            d="M185,178.1l-4.1-1.2l-17.7,4.5c1.1,1.5,0.8,2.9-1,4.5c1.8-1.5,3.7-2.6,5.8-3.3L185,178.1L185,178.1z"
          />
          <path
            fill="#EBEBEB"
            d="M161.2,181.3v5.5l0.8-0.8c1.6-1.5,1.9-3,1-4.5l-0.4-0.3L161.2,181.3L161.2,181.3z M183.1,174.9h-7.6
	c-5.8,0.1-11.8,1.2-17.9,3l6.2,1.6L183.1,174.9L183.1,174.9z"
          />
          <path
            fill="#656565"
            d="M238.8,194.3c-9.6,8.9-16.5,25.3-20.6,49c-1.6,15.3-2.6,30.8-2.8,46.6v17.7c0.1,15.8,1.1,31.4,2.8,46.6
	c4.1,23.7,11,40,20.6,49c-4.9-31.4-7.6-63.3-8.1-95.6v-17.6C231.2,257.6,234,225.8,238.8,194.3L238.8,194.3z"
          />
          <path
            fill="#EBEBEB"
            d="M316,180.3l-6.1-10l-20.5,0.4l-19.7,0.4c-3.2,0-5.9,0.3-8,0.7l1.2,0.1C280.6,174.9,298.2,177.7,316,180.3
	L316,180.3z"
          />
          <path
            fill="#656565"
            d="M310.1,170.3l6.1,10c-17.7-2.6-35.5-5.4-53.1-8.3l-1.2-0.1l-9.4,0.4l9.1,1.5c19.9,3.3,39.9,6.5,60.1,9.5
	l-7.3-13.1L310.1,170.3L310.1,170.3z"
          />
          <path
            fill="#FFFFFF"
            d="M310.1,170.3h4.3l5.1-0.1c45.9-0.6,91.9-0.6,137.8,0.3l14.2,0.3l-0.5-1.6c-60.8-1.2-121.6-1.1-182.3,0.6
	l1,1.1L310.1,170.3L310.1,170.3z"
          />
          <path
            fill="#656565"
            d="M319.3,170.1l-5.1,0.1l7.3,13.1c23.2,3.4,46.8,6.6,70.3,9.4c29.7,3,59.6,5.1,89.3,6.5l-0.5-2.5l-11.1-0.6
	c-26.5-1.2-53-3.3-79.4-5.9c-21.9-2.6-43.5-5.5-65.1-8.5L319.3,170.1L319.3,170.1z"
          />
          <path
            fill="#FFFFFF"
            d="M289.6,170.7l-1-1.1c-7.7,0.1-15.4,0.4-23.1,0.6l-8.1,0.8c-1.8,0.3-3.4,0.7-5,1.2l9.4-0.4
	c2.1-0.4,4.8-0.7,8-0.7L289.6,170.7L289.6,170.7z M277.3,184.4l-7.8-1.1l-13.6-0.7v2.9l2.9-0.6C264.3,183.9,270.5,183.7,277.3,184.4
	L277.3,184.4z"
          />
          <path
            fill="#656565"
            d="M258.8,184.8l-2.9,0.7c-5.8,1.2-10.6,3.6-14.6,6.7c-0.8,0.7-1.7,1.4-2.5,2.1c-5,31.4-7.6,63.3-8.1,95.6v17.7
	c0.6,32.3,3.2,64.2,8.1,95.6c0.8,0.8,1.6,1.5,2.5,2.1c4,3.2,8.8,5.5,14.6,6.7l2.9,0.5l126.1-17.1c0.4-1.1,0.5-2.6,0.4-4.4v-0.3
	c-2.5-27.9-3.7-55.7-4-83.5v-17.3c0.3-27.8,1.6-55.6,4-83.5v-0.3c0.1-1.8,0-3.2-0.4-4.3L258.8,184.8L258.8,184.8z M251.3,214.3
	c1.9-17.7,13.6-24.9,35.4-21.3l72.6,9.8c14,1.2,20.2,8.9,18.7,23.2c-1.5,21.5-2.3,42.8-2.5,64.1c0,2.5-0.1,4.8-0.1,7.3l0.1,10.5
	c0.1,21.3,1,42.8,2.5,64.1c1.5,14.3-4.7,22-18.7,23.2l-72.6,9.8c-21.6,3.6-33.4-3.6-35.4-21.3c-2.9-25.3-4.4-50.6-4.5-75.8v-17.7
	C246.9,264.8,248.4,239.6,251.3,214.3L251.3,214.3z"
          />
          <path
            fill="#EBEBEB"
            d="M286.5,193c-21.6-3.6-33.4,3.6-35.4,21.3c-2.9,25.3-4.4,50.6-4.5,75.8v17.7c0.1,25.2,1.6,50.5,4.5,75.8
	c1.9,17.7,13.6,24.9,35.4,21.3l72.6-9.8c14-1.2,20.2-8.9,18.7-23.2c-1.5-21.5-2.3-42.8-2.5-64.1l-0.1-10.5c0-2.5,0-4.8,0.1-7.3
	c0.1-21.3,1-42.8,2.5-64.1c1.5-14.3-4.7-22-18.7-23.2L286.5,193L286.5,193z"
          />
          <path
            fill="#FFFFFF"
            d="M277.3,184.4c-6.9-0.6-13.1-0.4-18.6,0.6L384.9,202c-0.5-1.8-1.9-2.9-3.9-3.2L277.3,184.4L277.3,184.4z
	 M413.5,157.6c1.5,0.4,3.3,0.7,5.2,1c0.1-0.6,0.4-1,0.7-1.2c0.4-0.7,1.1-1.1,1.9-1.4l0.8-0.1c3.7-0.6,7.6-0.6,11.6-0.3
	c1,0,1.8,0.3,2.5,0.8c0.4,0.3,0.8,0.8,1.2,1.4l0.5,1.2l1.8-0.1V154l-5.1-0.7c-6.6-0.6-12.7-0.1-18.3,1.4c-1.2,0.3-2.5,0.7-3.6,1.1
	l-2.3,0.8C411.2,157,412.3,157.3,413.5,157.6L413.5,157.6z M408.6,155.7c0.5,0.3,1.1,0.6,1.8,0.8l2.3-0.8H408.6L408.6,155.7z"
          />
          <path
            fill="#FFFFFF"
            d="M419.4,157.3c-0.3,0.3-0.5,0.7-0.7,1.2l11.1,0.4c2.9,0,5.5,0,7.8-0.1l-0.6-1.2l-7.4,0.1L419.4,157.3
	L419.4,157.3z M408.6,155.7h4.1c1.1-0.4,2.3-0.8,3.6-1.1c-23.4-0.1-46.9,0-70.7,0.4l-18.2,0.7C354.5,155.4,381.6,155.4,408.6,155.7
	L408.6,155.7z"
          />
          <path
            fill="#FFFFFF"
            d="M422,155.7l-0.8,0.1c-0.8,0.3-1.4,0.7-1.9,1.4l10.5,0.4l7.4-0.1c-0.3-0.6-0.7-1.1-1.2-1.4
	c-0.7-0.4-1.5-0.7-2.5-0.8C429.6,155.1,425.8,155.3,422,155.7L422,155.7z M439.5,154v4.8l7-0.8l3.2-0.8c-0.4-0.3-0.8-0.4-1.2-0.6
	c-1.5-0.7-3.2-1.2-5-1.6C442.1,154.6,440.9,154.2,439.5,154L439.5,154z M449.7,157.1l1.2-0.6h-2.5
	C448.9,156.6,449.3,156.8,449.7,157.1z"
          />
          <path
            fill="#FFFFFF"
            d="M448.5,156.5h2.5l15.8,0.6l-1.4-1.9c-7.3-0.1-14.6-0.3-22-0.3C445.2,155.3,446.8,155.8,448.5,156.5
	L448.5,156.5z"
          />
          <path
            fill="#FFFFFF"
            d="M466.8,157.1h1.2c35.5,1.2,70.8,3,106.1,5.5h0.4c7.2-2.3,15.1-2.9,23.9-1.6c1.8,0.3,3.4,0.6,5,1
	c3.6,0.8,6.9,2.1,9.9,3.6l0.5,0.1L630,167l-10-6.1l-45.8-4l-1.8,0.1c-7.3,0.4-14.6,0.6-21.9,0.3h-0.4c-27.8-1-56-1.6-84.5-2.1
	L466.8,157.1L466.8,157.1z M333.2,137.2l0.5-5.9l-1.2,2.3l-0.1,0.1l-0.1,0.1l-0.3,3.3l-8.4,22.3l-1.8,1.6l-0.4,1.8l0.1-0.1h0.1
	c1.1-0.8,2.1-1.9,2.9-3l1.5-3.9v-0.1L333.2,137.2L333.2,137.2z"
          />
          <path
            fill="#FFFFFF"
            d="M331.9,137.2l0.3-3.3c-3.7,7-6.7,14.4-8.9,22l-1.4,5.2l1.8-1.6L331.9,137.2L331.9,137.2z M327.3,155.8
	l-1.1-0.1v0.1 M385.2,206.2v0.3c-2.5,27.9-3.7,55.7-4,83.5v17.7c0.3,27.8,1.7,55.6,4,83.5v0.3c65.3-8.3,134.5-12.5,207.4-12.8h54.8
	v-78.5l-6.1,0.3l-5.4,0.5c-0.3,1.9-1.8,2.9-4.3,3h-7.8c-3.7-0.1-5.8-1.5-6.3-4c-0.1-0.3-0.1-0.5-0.1-0.8v-0.7c0-0.3,0-0.5,0.1-0.8
	c0.5-2.5,2.6-3.9,6.3-4h7.8c2.6,0.1,4,1.2,4.3,3l11.3,0.8v-78.4h-54.8C519.7,218.7,450.5,214.4,385.2,206.2L385.2,206.2z"
          />
          <path
            fill="#EBEBEB"
            d="M324.9,181.5c21.6,3,43.2,5.9,65.1,8.5c26.4,2.6,52.8,4.7,79.4,5.9l-12.2-25.6c-45.9-0.8-91.9-0.8-137.8-0.3
	L324.9,181.5z"
          />
          <path
            fill="#FFFFFF"
            d="M470.9,169l0.5,1.6l13.8,0.4c41.7,1.4,82.1,3.7,121.1,7.2l12.8,1.2l3.9,0.3l1.8,0.3l1.4-1.5l-3.2-0.6
	C574.3,173.4,523.7,170.4,470.9,169L470.9,169z"
          />
          <path fill="#656565" d="M457.1,170.4l12.2,25.6l11.1,0.6l-9.1-25.9" />
          <path
            fill="#FFFFFF"
            d="M468,157.1c1.4,4.4,2.3,8.4,2.9,12c52.8,1.4,103.4,4.4,152,9.2l3.2,0.6c1.9-3.9,3.3-7.7,4-11.7l-16.2-1.4
	h-0.3l-3.3,0.6l-7,0.4h-1.8l-7.8-0.4l-11.1-1.2c-2.1-0.4-3.7-0.8-5.2-1.2c-1.2-0.4-2.3-0.8-3.2-1.2
	C538.8,160.1,503.5,158.3,468,157.1L468,157.1z"
          />
          <path
            fill="#FFFFFF"
            d="M574.3,162.6h-0.4c0.8,0.4,1.9,0.8,3.2,1.2c1.5,0.4,3.3,0.8,5.2,1.2c0.1-0.6,0.4-0.8,0.7-1.2
	c0.1-0.3,0.4-0.4,0.7-0.6c0.5-0.4,1.2-0.7,2.1-0.7l11.6,0.4c1.2,0.1,2.2,0.7,2.9,1.5l0.5,0.8c0.1,0.3,0.3,0.8,0.4,1.2h1.8l0.1-4.8
	c-1.5-0.4-3.2-0.7-5-1C589.5,159.7,581.5,160.2,574.3,162.6L574.3,162.6z"
          />
          <path
            fill="#FFFFFF"
            d="M583.1,163.8c-0.3,0.3-0.5,0.7-0.7,1.2l11.1,1.2l7.8,0.4c-0.1-0.6-0.3-1-0.4-1.2l-0.5-0.8l-16.5-1.2
	C583.5,163.4,583.4,163.7,583.1,163.8L583.1,163.8z M585.9,162.6c-0.8,0.1-1.5,0.3-2.1,0.7l16.5,1.2c-0.7-0.8-1.7-1.4-2.9-1.5
	L585.9,162.6L585.9,162.6z M619.9,161l-7.4-4.4c-13.1-0.3-25.9-0.1-38.4,0.4L619.9,161L619.9,161z"
          />
          <path
            fill="#656565"
            d="M491.1,196.9l-10.6-0.4l0.5,2.5c36.7,1.5,73.3,1.9,110.2,1l10.6-0.1c6.9-7,14.4-13.6,22.6-19.7l-1.8-0.3
	l-3.8-0.3c-3.6,2.8-7,5.5-10.5,8.4c-3.6,3-6.9,6.2-10.2,9.4l-7.4,0.1C557.5,198.3,524.4,198.2,491.1,196.9L491.1,196.9z"
          />
          <path fill="#656565" d="M480.5,196.5l10.6,0.4l-6.1-25.9l-13.6-0.4" />
          <path
            fill="#EBEBEB"
            d="M485,171.1l6.1,25.9c33.2,1.2,66.4,1.4,99.7,0.4l7.4-0.1c3.2-3.3,6.6-6.3,10.2-9.4l-2.3-9.6
	C567.2,174.9,526.9,172.5,485,171.1L485,171.1z"
          />
          <path
            fill="#656565"
            d="M629.4,183.6v-2.1c-6.3,4.8-12.4,10-18,15.4l2.5-0.1c4.3-4.1,8.7-8,13.5-11.7L629.4,183.6L629.4,183.6z"
          />
          <path fill="#FFFFFF" d="M613.7,165.6h-0.5l0.3,0.1" />
          <path
            fill="#FFFFFF"
            d="M610.1,166.3l3.3-0.6l-0.3-0.1c-2.9-1.5-6.2-2.8-9.9-3.6l-0.1,4.8L610.1,166.3L610.1,166.3z"
          />
          <path
            fill="#656565"
            d="M606.1,178.4l2.3,9.6c3.3-2.9,6.9-5.8,10.5-8.4L606.1,178.4L606.1,178.4z"
          />
          <path
            fill="#EBEBEB"
            d="M630.2,186.8c-4.1,3.2-8,6.5-11.7,9.9L642,195C638.3,191.9,634.4,189.1,630.2,186.8L630.2,186.8z"
          />
          <path
            fill="#FFFFFF"
            d="M617.4,299.2c0,0.3,0,0.5,0.1,0.8c0.5,2.5,2.6,3.9,6.3,4h7.8c2.6-0.1,4-1.2,4.3-3l-3.2,0.4
	c-0.7,0-1.2-0.3-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8v-0.1c0-0.7,0.3-1.2,0.7-1.8c0.4-0.5,1.1-0.7,1.8-0.7l3.2,0.4
	c-0.3-1.9-1.8-2.9-4.3-3h-7.8c-3.7,0.1-5.8,1.5-6.3,4c-0.1,0.3-0.1,0.5-0.1,0.8L617.4,299.2L617.4,299.2z M617.4,299v0.1"
          />
          <path
            fill="#FFFFFF"
            d="M632.9,296.4c-0.7,0-1.2,0.3-1.8,0.7s-0.7,1.1-0.7,1.8c0-0.7,0.3-1.2,0.7-1.7l0.5-0.4
	C632.1,296.5,632.5,296.4,632.9,296.4L632.9,296.4z"
          />
          <path
            fill="#FFFFFF"
            d="M632.9,296.4c-0.5,0-1,0.1-1.2,0.4l-0.5,0.4c-0.4,0.4-0.7,1-0.7,1.6v0.1c0,0.7,0.3,1.2,0.7,1.7l0.5,0.4
	c0.3,0.3,0.8,0.4,1.2,0.4l3.2-0.4l5.4-0.5l6.1-0.3l17.2-0.1l1.4,0.1l1.1-0.1l0.5-0.3l0.6-0.5l0.1-0.4v-0.1l-0.1-0.3
	c-0.1-0.1-0.3-0.4-0.5-0.5c-0.1-0.1-0.3-0.3-0.6-0.3l-1.1-0.1l-1.4,0.1l-17.2-0.1l-11.3-0.8L632.9,296.4L632.9,296.4z"
          />
          <path
            fill="#FFFFFF"
            d="M631.3,300.7c0.4,0.4,1.1,0.7,1.8,0.7c-0.5,0-1-0.1-1.2-0.4L631.3,300.7c-0.4-0.5-0.7-1.1-0.7-1.8
	C630.5,299.6,630.7,300.1,631.3,300.7L631.3,300.7z"
          />
          <path
            fill="#656565"
            d="M481.2,398.6l-0.5,2.6l10.6-0.4c33.2-1.2,66.4-1.4,99.7-0.4h7.4c3.2,3.3,6.6,6.5,10.2,9.5
	c3.3,2.9,6.9,5.8,10.5,8.4l3.9-0.4l1.8-0.3c-8.1-6.1-15.5-12.7-22.6-19.7c-3.6-0.1-7-0.1-10.6-0.1
	C554.5,396.7,517.8,397.1,481.2,398.6L481.2,398.6z M480.5,401.2l0.5-2.6c-29.9,1.2-59.6,3.4-89.3,6.5c-23.7,2.9-47,5.9-70.3,9.4
	l-7.3,13.1l5.1,0.1l5.6-11.4c21.6-3,43.2-5.9,65.1-8.5c26.4-2.6,52.8-4.7,79.4-5.9L480.5,401.2L480.5,401.2z"
          />
          <path fill="#656565" d="M491.1,400.8l-10.6,0.4l-9.1,25.9l13.6-0.5" />
          <path
            fill="#FFFFFF"
            d="M549.8,440.6h0.4c7.3-0.3,14.6-0.3,21.9,0.1l1.8,0.1l45.8-4l10-6.1l-16.2,1.4l-0.5,0.1
	c-2.9,1.5-6.2,2.6-9.9,3.6l-5,1.1c-8.8,1.2-16.8,0.7-23.9-1.6h-0.4c-35.1,2.5-70.4,4.3-106.1,5.5h-1.2l-1.4,1.9
	C493.9,442.2,522.1,441.5,549.8,440.6L549.8,440.6z"
          />
          <path
            fill="#EBEBEB"
            d="M491.1,400.8l-6.1,25.9c41.7-1.4,82.1-3.7,121.1-7.2l2.3-9.6c-3.6-3-6.9-6.2-10.2-9.5h-7.4
	C557.5,399.4,524.4,399.6,491.1,400.8L491.1,400.8z"
          />
          <path
            fill="#656565"
            d="M608.6,398.1l-6.6-0.1c6.9,7,14.4,13.6,22.6,19.7c1.8-0.4,3.3-0.8,4.8-1.4c-6.3-4.8-12.4-9.9-18-15.4
	l2.5,0.1l4.7,0.3l23.5,1.6l3.6,0.4l2.8,0.3c0.8-0.8,1.2-1.6,1.1-2.3l-0.3-0.1l-2.2-0.5C634.7,399.3,621.8,398.5,608.6,398.1
	L608.6,398.1z"
          />
          <path
            fill="#FFFFFF"
            d="M602,397.8l6.6,0.1c13.2,0.4,26.1,1.2,38.5,2.3l2.2,0.5l0.3,0.1c1.9,0.8,1.9,2.2,0.1,3.9
	c-4.7,4.5-9.9,8.4-15.8,11.4c-2.3,1.1-5,2.1-7.8,2.6c1.9,3.9,3.3,7.7,4,11.7l50.1-5c-6.9-3.6-12.9-8.4-18-14.6
	c-0.5-0.8-0.8-1.8-0.4-3l2.5-4.7c-7.4-4.4-12.4-9.5-15-15.3c-0.5-1.6-1.1-3.4-1.2-5.2h-55c-73.2,0.3-142.5,4.5-208,12.9
	c-0.5,1.8-1.9,2.9-3.9,3.2l-103.6,14.3l-7.8,1.1l-13.8,1c1.4,3.2,3.2,6.1,5.5,8.7c19.9-3.4,39.9-6.5,60.1-9.5
	c23.2-3.4,46.8-6.5,70.3-9.4c29.7-3,59.6-5.1,89.3-6.5c36.7-1.5,73.3-1.9,110.2-1C595,397.8,598.4,397.8,602,397.8L602,397.8z"
          />
          <path
            fill="#656565"
            d="M613.8,400.8l-2.5-0.1c5.6,5.5,11.7,10.6,18,15.4v-1.9l-2.1-1.6C622.5,408.8,618.1,404.8,613.8,400.8
	L613.8,400.8z"
          />
          <path
            fill="#656565"
            d="M618.5,401.1l-4.7-0.3c4.3,4.1,8.7,8,13.5,11.7l2.1,1.6l1.7-0.8c5.4-2.9,10.2-6.2,14.6-10.3l-3.6-0.4
	c-3.6,3.2-7.6,5.9-11.8,8.3C626.1,407.8,622.2,404.4,618.5,401.1L618.5,401.1z"
          />
          <path
            fill="#FFFFFF"
            d="M593.5,431.5c-4.3,0.3-8,0.7-11.1,1.2c0.1,0.5,0.4,0.8,0.7,1.2l0.7,0.5l16.5-1.2l0.5-0.8
	c0.1-0.4,0.3-0.8,0.4-1.4L593.5,431.5L593.5,431.5z"
          />
          <path
            fill="#FFFFFF"
            d="M582.4,432.7c3.2-0.5,6.9-0.8,11.1-1.2l7.8-0.4h1.8l7,0.4c1.2,0.1,2.3,0.4,3.3,0.5l0.3,0.1l16.2-1.4
	c-0.7-4-2.1-7.8-4-11.7l-3.2,0.5c-48.4,4.7-99.2,7.7-152,9.2c-0.5,3.6-1.5,7.6-2.9,12c35.5-1.1,70.8-3,106.1-5.5
	c0.8-0.4,1.9-0.8,3.2-1.2C578.7,433.6,580.4,433.1,582.4,432.7L582.4,432.7z"
          />
          <path
            fill="#FFFFFF"
            d="M577.2,434c-1.2,0.4-2.3,0.8-3.2,1.2h0.4c7.2,2.3,15.1,2.9,23.9,1.6l5-1.1l-0.1-4.8h-1.8
	c-0.1,0.5-0.3,1-0.4,1.4l-0.5,0.8c-0.7,0.8-1.7,1.4-2.9,1.5l-11.6,0.5c-0.8-0.1-1.5-0.4-2.1-0.8l-0.7-0.5c-0.3-0.3-0.5-0.7-0.7-1.2
	C580.4,433.1,578.7,433.6,577.2,434L577.2,434z"
          />
          <path
            fill="#FFFFFF"
            d="M585.9,435.3l11.6-0.5c1.2-0.1,2.2-0.7,2.9-1.5l-16.5,1.2C584.4,434.9,585.1,435.2,585.9,435.3L585.9,435.3z
	 M574.1,440.8l12.2,1.1c33,3.3,62.6,3,88.6-0.5l8.7-4.8v-0.7c-7,1.4-14.3,2.5-22.1,3c-16.8,1.4-33,2.1-48.8,2.3
	C599.4,441.4,586.4,441.3,574.1,440.8z M632.2,415l-2.9,1.2c-1.5,0.5-3.2,1-4.8,1.4l1.4,1.5c2.9-0.6,5.5-1.5,7.8-2.6
	c5.9-3.2,11.1-6.9,15.8-11.4c1.8-1.8,1.8-3-0.1-3.9c0.1,0.7-0.3,1.4-1.1,2.3l-0.1,0.1C643.5,408,638.2,411.8,632.2,415L632.2,415z"
          />
          <path
            fill="#656565"
            d="M629.4,416.2c1.1-0.3,2.1-0.7,2.9-1.2c5.9-3.2,11.1-6.9,15.8-11.4l0.1-0.1l-2.8-0.3
	c-4.3,4-9.2,7.4-14.6,10.3l-1.7,0.8L629.4,416.2L629.4,416.2z"
          />
          <path
            fill="#FFFFFF"
            d="M622.8,419.5l3.2-0.5l-1.4-1.5l-1.8,0.3l-3.8,0.4l-12.8,1.2c-38.9,3.4-79.4,5.9-121.1,7.2l-13.8,0.4
	l-0.5,1.8C523.7,427.4,574.3,424.2,622.8,419.5L622.8,419.5z M629.9,430.7l-10,6.1c17.6-1.4,35.2-2.5,52.5-2.9
	c4.7-0.1,9.2-0.7,13.6-1.5l0.7-0.1l8.9-4l-14.6-2.6l-1,0.1L629.9,430.7L629.9,430.7z M667.3,430c1.9-0.7,4.4-1.2,7-1.5l7-0.3
	c1.1,0.1,1.9,0.3,2.5,0.4l0.5-0.1v2.1l-1.2-0.1c-0.4,0.3-1.1,0.6-1.8,0.8c-1.9,0.7-4.3,1.2-7,1.5l-7,0.3c-1.9-0.1-2.9-0.5-2.9-1.1
	S665.4,430.7,667.3,430L667.3,430z"
          />
          <path
            fill="#FFFFFF"
            d="M674.3,428.3c-2.8,0.3-5.1,0.8-7,1.5s-2.9,1.4-2.9,1.9s1,1,2.9,1.1l7-0.3c2.8-0.4,5.1-0.8,7-1.5
	c0.7-0.3,1.4-0.6,1.8-0.8l-3.2-0.4v-0.5l3.7-1c-0.5-0.3-1.2-0.4-2.5-0.4L674.3,428.3L674.3,428.3z"
          />
          <path
            fill="#FFFFFF"
            d="M680.1,430l3.2,0.4l1.2,0.1v-2.1l-0.7,0.1l-3.7,0.8"
          />
          <path
            fill="#656565"
            d="M608.4,409.8l-2.3,9.6l12.8-1.2C615.3,415.5,611.9,412.8,608.4,409.8z"
          />
          <path fill="#FFFFFF" d="M613.1,432.2h0.5l-0.3-0.1" />
          <path
            fill="#FFFFFF"
            d="M613.4,432c-1-0.3-2.1-0.4-3.3-0.5l-7-0.4l0.1,4.8c3.6-1,6.9-2.1,9.9-3.6L613.4,432L613.4,432z M612.4,441.3
	l7.4-4.4l-45.8,4C586.4,441.3,599.4,441.4,612.4,441.3z"
          />
          <path
            fill="#FFFFFF"
            d="M619.9,436.7l-7.4,4.4c15.8-0.3,32.2-1.1,48.8-2.3c7.7-0.7,15.1-1.6,22.1-3l2.6-3.4
	c-4.4,0.8-8.9,1.2-13.6,1.5C655.1,434.4,637.5,435.3,619.9,436.7L619.9,436.7z"
          />
          <path
            fill="#EBEBEB"
            d="M618.5,401.1c3.7,3.4,7.6,6.7,11.7,9.9c4.3-2.3,8.3-5.1,11.8-8.3L618.5,401.1L618.5,401.1z"
          />
          <path
            fill="#FFFFFF"
            d="M385.2,391.6c0.1,1.8,0,3.2-0.3,4.4c65.5-8.3,134.8-12.5,208-12.9h55c-0.3-1.2-0.3-2.6-0.4-4v-0.3h-54.8
	C519.7,379.1,450.5,383.3,385.2,391.6L385.2,391.6z M380.9,399c2.1-0.3,3.3-1.4,3.9-3.2l-126.1,17.1c5.5,1,11.7,1.1,18.6,0.5
	L380.9,399L380.9,399z M321.5,434.8l0.1,0.3h0.1c1.1,0.8,2.1,1.9,2.9,2.9l1.5,4h1.1c27.2,0.4,54.2,0.4,81.2,0.1
	c0.5-0.3,1.1-0.7,1.8-1c1-0.4,1.9-0.7,3.2-1c1.5-0.4,3.3-0.7,5.2-0.8l11.1-0.4c2.9,0,5.5,0,7.8,0.1l1.8,0.1c2.8,0.1,5.1,0.5,7,0.8
	l3.2,0.8c0.4,0.1,0.8,0.3,1.2,0.5l15.8-0.5h1.2c1.4-4.4,2.3-8.4,2.9-12c-60.8,1.2-121.6,1.1-182.3-0.5c-1.1,3.9-2.8,8-5.2,12.1
	l2.9,0.1l14.9,0.5l-0.1-1.1l-2.8-3c-0.5-0.8-0.7-1.5-0.4-2.5c1-1.6,2.6-2.6,5.1-2.9c2.8-0.3,5.4,0.4,7.6,1.9h7.8
	c1,0.1,1.7,0.4,2.5,0.8L321.5,434.8L321.5,434.8z"
          />
          <path
            fill="#FFFFFF"
            d="M321.6,435.1l-0.1-0.3l0.4,1.8l1.8,1.6l8.4,22.3l0.3,3.3l1.5,2.6l-0.5-5.9l-6.9-18.4v-0.1l-1.5-4
	C323.7,436.9,322.7,435.9,321.6,435.1L321.6,435.1z"
          />
          <path
            fill="#656565"
            d="M321.9,436.6l-0.4-1.8c-0.3-0.1-0.4-0.4-0.7-0.4c-0.7-0.6-1.5-0.8-2.5-0.8c0,1.1,0.1,2.3,0.5,3.7l1.2,4.5
	c2.6,9.1,6.5,17.6,11.3,25.9l2.2-1.1l-1.5-2.6c-3.7-7-6.7-14.4-8.9-22L321.9,436.6L321.9,436.6z"
          />
          <path
            fill="#FFFFFF"
            d="M323.5,438.2l-1.8-1.6l1.4,5.2c2.2,7.6,5.2,15,8.9,22l-0.3-3.3L323.5,438.2L323.5,438.2z M326.2,442.1
	l1.1-0.1h-1.1 M327.3,441.9l18.2,0.7c23.8,0.3,47.3,0.4,70.7,0.4l-3.6-1.1l-4.1,0.1C381.6,442.4,354.5,442.4,327.3,441.9
	L327.3,441.9z"
          />
          <path
            fill="#EBEBEB"
            d="M390,407.7c-21.9,2.6-43.5,5.5-65.1,8.5l-5.6,11.4c45.9,0.5,91.9,0.5,137.8-0.3l12.2-25.6
	C442.8,403,416.4,405.1,390,407.7L390,407.7z"
          />
          <path
            fill="#FFFFFF"
            d="M410.2,441.1c-0.7,0.3-1.2,0.5-1.8,1l4.1-0.1L410.2,441.1L410.2,441.1z M413.5,440.2c-1.2,0.3-2.3,0.5-3.2,1
	l2.3,0.8l3.6,1.1c5.6,1.4,11.7,1.9,18.3,1.4l5.1-0.7v-4.8l-1.8-0.1l-0.5,1.2c-0.3,0.5-0.7,1.1-1.2,1.4c-0.7,0.4-1.5,0.7-2.5,0.8
	c-4,0.3-7.8,0.1-11.6-0.3l-0.8-0.1c-0.8-0.3-1.4-0.8-1.9-1.4c-0.3-0.3-0.5-0.8-0.7-1.2C416.7,439.5,415,439.7,413.5,440.2
	L413.5,440.2z"
          />
          <path
            fill="#FFFFFF"
            d="M418.7,439.3c0.1,0.4,0.4,0.8,0.7,1.2l10.5-0.4c2.8,0,5.1,0,7.4,0.1l0.5-1.2c-2.3-0.1-5-0.1-7.8-0.1
	L418.7,439.3L418.7,439.3z"
          />
          <path
            fill="#FFFFFF"
            d="M437.2,440.2c-2.2-0.1-4.7-0.1-7.4-0.1l-10.5,0.4c0.4,0.6,1.1,1.1,1.9,1.4l0.8,0.1c3.7,0.4,7.6,0.5,11.6,0.3
	c1,0,1.8-0.3,2.5-0.8C436.5,441.3,436.9,440.7,437.2,440.2L437.2,440.2z M439.5,439.1v4.8l4-0.8c1.8-0.4,3.4-1,5-1.6l1.2-0.5
	l-3.2-0.8C444.5,439.5,442.1,439.2,439.5,439.1L439.5,439.1z M470.9,428.7l0.5-1.8l-14.2,0.3c-45.9,0.8-91.9,0.8-137.8,0.3l-5.1-0.1
	h-4.3l-20.5-0.4l-1,1.1C349.3,429.8,409.9,430,470.9,428.7L470.9,428.7z"
          />
          <path
            fill="#FFFFFF"
            d="M450.9,441.3c-0.4-0.1-0.8-0.3-1.2-0.5l-1.2,0.5H450.9L450.9,441.3z"
          />
          <path
            fill="#FFFFFF"
            d="M450.9,441.3h-2.5c-1.5,0.5-3.2,1.1-5,1.6l22-0.3l1.4-1.9L450.9,441.3L450.9,441.3z"
          />
          <path fill="#656565" d="M457.1,427.2l14.3-0.1l9.1-25.9l-11.1,0.5" />
          <path
            fill="#FFFFFF"
            d="M331.4,467.5c-4.8-8.3-8.7-16.8-11.3-25.9l-1.2-4.5c-0.4-1.4-0.5-2.6-0.5-3.7h-10c-1.5-0.7-3.2-0.7-4.8-0.3
	c-2.1,0.7-2.9,2.5-2.8,5.5l0.3,1.2l0.1,1.1c1.6,7.7,5.1,14.2,10.3,19.1C316,464.5,322.6,467,331.4,467.5L331.4,467.5z M177,330.8
	c11.7,0.5,23.2,0.7,34.8,0.1l-34.8-4l-22.8-3l-24.1-3.6c-13.5-2.1-26.8-4.4-39.9-6.7l0.3,5.1c13.1,2.3,26.4,4.5,39.8,6.6l24.1,3.6
	C161.9,329.7,169.5,330.4,177,330.8L177,330.8z M211.8,266.8c-11.4-0.5-23.1-0.5-34.8,0.1c-7.6,0.4-15.1,1.1-22.8,1.9l-24.1,3.6
	c-13.3,2.1-26.7,4.3-39.8,6.7l-0.3,5.1c13.2-2.3,26.6-4.5,39.9-6.7l24.1-3.6l22.8-3L211.8,266.8L211.8,266.8z"
          />
          <path
            fill="#EBEBEB"
            d="M180.9,420.8l4.1-1.2L168,415c-2.1-0.7-4-1.8-5.8-3.3c1.6,1.5,1.9,3,1,4.5L180.9,420.8L180.9,420.8z"
          />
          <path
            fill="#EBEBEB"
            d="M185,419.5l-4.1,1.2l-17.7-4.5l-0.4,0.3c-1.2,0.7-3,0.8-5.2,0.5c-13.5-4.5-27.8-13.1-42.6-25.7l-9.5-9.1
	l-1.7-1.6c-2.3-2.2-4-4.8-5.1-8.1l-0.6-1.8l-0.1-1.1l-1.1-0.7c0.7,5.4,2.6,9.5,5.9,12.5l11,10.7c14,12,27.5,20.2,40.3,24.9l0.3,0.1
	c1.2,0.4,1.8,1.2,1.8,2.2l1.4,0.4l6.2-1.8l19.3,4.7h-7.6c-5.8-0.1-11.8-1.1-17.9-3l-1.4-0.4c-13.8-4.4-28.3-13.1-43.6-26.1
	c-3.3-3-7.8-7.4-13.9-13.5c-3-3.6-4.5-7.3-4.8-11.1V367c-0.6-0.1-0.8,0.1-1,0.6v0.5c0,4.1,1.5,8.3,4.8,12.4
	c6.1,5.9,10.6,10.5,13.9,13.5c21.7,18.4,41.8,28.3,60.5,29.7h4.4c12,0.1,23-0.5,33-2.1c-8.5-1-16.2-2.1-23.2-3.4
	c-2.9-0.5-5.5-1.2-7.8-2.1l-9.9-2.6c-1.9-0.5-3.6-1.5-5.1-2.8l-3.4-3.3c-1.8-1.8-3.9-3.2-6.1-4.5l-1.1-0.7
	c-11.1-6.9-22.6-13.6-34.3-20.5c-6.5-3.9-13.2-7.7-20.2-11.6l0.6,1.8l16.6,10.3c11.8,6.9,23.5,13.8,34.8,20.6l2.3,1.5l1.1,0.7
	c2.2,1.2,4.3,2.8,6.1,4.5l1.4,1.4l0.7,0.7l0.1,0.1c1.8,1.5,3.7,2.6,5.8,3.3L185,419.5L185,419.5z"
          />
          <path
            fill="#FFFFFF"
            d="M172.1,413.9c1.8,1,3.9,1.8,6.2,2.6c2.3,0.8,5,1.5,7.8,2.1c7,1.2,14.9,2.5,23.2,3.4
	c12.7,1.5,27,2.6,43.1,3.4l9.1-1.5c-2.3-2.6-4.3-5.5-5.5-8.7c-5.2,0-10.5-0.3-15.5-1c-38.4-5.5-70.3-13.3-95.6-23.5
	c-15.4-6.5-30.5-14.7-45.4-25l120,24.2L94.9,360.6l3.7,9.2c7.2,4.1,14.3,8.3,21.3,12.4c11.1,6.5,22,13.1,32.6,19.5
	C159.2,405.8,165.8,409.9,172.1,413.9L172.1,413.9z"
          />
          <path
            fill="#EBEBEB"
            d="M178.3,416.5c-2.3-0.8-4.4-1.6-6.2-2.6c-6.5-4-12.9-8.1-19.5-12.1c-10.6-6.5-21.5-12.9-32.6-19.5
	c-7-4.1-14-8.3-21.3-12.4l-0.8-0.4l0.1,1.1c7,4,13.8,7.8,20.2,11.6c11.7,6.9,23.1,13.8,34.3,20.5l1.1,0.7c2.2,1.2,4.3,2.8,6.1,4.5
	l3.4,3.3c1.5,1.2,3.2,2.1,5.1,2.8L178.3,416.5L178.3,416.5z M162.6,416.5l0.4-0.3c1.1-1.5,0.8-3-1-4.5l-0.1-0.1l-0.7-0.7v5.5h1.4
	V416.5L162.6,416.5z M161.2,416.5V411l-1.4-1.4c-1.8-1.8-3.9-3.2-6.1-4.5l-1.1-0.7v8.7C154.8,415,157.6,416.1,161.2,416.5
	L161.2,416.5z"
          />
          <path
            fill="#EBEBEB"
            d="M162.6,416.5h-1.2c-3.6-0.4-6.6-1.5-8.7-3.6v-1.1c-1.5-1.4-2.3-3.2-2.3-5.6v-3.4
	c-11.3-6.9-22.8-13.8-34.8-20.6h-10.3l9.5,9.1c14.9,12.7,29.2,21.2,42.6,25.7C159.6,417.3,161.4,417.2,162.6,416.5L162.6,416.5z"
          />
          <path
            fill="#FFFFFF"
            d="M164.7,423.5l1.9,1.4c20.5,1,41,2.9,61.4,5.6h0.3c9.8,1.2,18.4,3.6,26,7c2.2,1.1,5.1,1.8,8.7,1.9l20.6,0.8
	c2.5-4.1,4.1-8.3,5.2-12.1l-23.1-0.7l-8.1-0.8l-5-1.2c-16.1-0.8-30.4-2.1-43.1-3.4c-10,1.5-21,2.2-33,2.1h-4.4L164.7,423.5
	L164.7,423.5z"
          />
          <path
            fill="#FFFFFF"
            d="M166.6,424.9c0.8,0.7,1.5,1.5,1.8,2.2c1.8,4,3.6,6.6,5.5,7.8c21.9,1.5,44,3.4,66.2,5.8h0.3
	c13.8,0.5,26.7,0.8,38.9,0.7l4-0.5l0.1-0.5l-20.6-0.8c-3.6-0.3-6.5-1-8.7-1.9c-7.6-3.4-16.2-5.8-26-7h-0.3
	C207.6,427.8,187.2,425.8,166.6,424.9L166.6,424.9z M164.7,423.5l7.3,0.3c-18.7-1.4-38.8-11.3-60.5-29.7c-3.3-3-7.8-7.6-13.9-13.5
	c-3.3-4-5-8.1-4.8-12.4v-0.5c0.1-0.6,0.4-0.7,1-0.6l0.7,0.3c0.7,0.4,1.5,0.8,2.2,1.2l1.1,0.7l0.8,0.4l-3.7-9.2l-2.5-0.5
	c-3.6-0.3-5.4,1.1-5.5,4.3c0.1,6.1,3.4,12.8,9.8,20.4c3.6,3.9,7.7,7.6,12.1,11.4C129,412.4,147.7,421.6,164.7,423.5L164.7,423.5z
	 M173.9,434.9l-6.5-0.4c-9.1-0.8-17.7-3.7-26-8.4c1.8,1.4,3.6,2.9,5.1,4.3l1.5,0.8c2.2,1.2,4.3,2.6,6.3,4.1
	c4.1,2.1,8.8,3.4,14.2,4.3l17.2,1.5L173.9,434.9z"
          />
          <path
            fill="#EBEBEB"
            d="M183.1,422.8l-19.3-4.7l-6.2,1.8c6.1,1.8,12.1,2.9,17.9,3h7.6L183.1,422.8L183.1,422.8z"
          />
          <path
            fill="#FFFFFF"
            d="M173.9,434.9l12,6.2c20.1,1.5,42.2,2.2,66.7,2.2h0.3c9.1-0.1,17.9-0.8,26.4-1.9c-12.2,0.1-25.3,0-38.9-0.7
	H240C217.9,438.5,195.8,436.6,173.9,434.9L173.9,434.9z M269.5,414.6l7.8-1.1c-6.9,0.5-13.1,0.4-18.6-0.5l-2.9-0.5v2.9L269.5,414.6
	L269.5,414.6z M269.9,426.7c-3.2-0.1-5.9-0.3-8-0.7l-9.4-0.4l5,1.2l8.1,0.8l23.1,0.7l1-1.1L269.9,426.7L269.9,426.7z"
          />
          <path
            fill="#EBEBEB"
            d="M261.8,426c2.1,0.4,4.8,0.7,8,0.7l19.7,0.4l20.5,0.4l6.1-10c-17.7,2.6-35.5,5.4-53.1,8.3L261.8,426
	L261.8,426z"
          />
          <path
            fill="#656565"
            d="M261.8,426l1.2-0.1c17.6-2.9,35.2-5.6,53.1-8.3l-6.1,10h4.3l7.3-13.1c-20.2,2.9-40.2,6.1-60.1,9.5l-9.1,1.5
	L261.8,426L261.8,426z M308.3,433.4h2.2c-2.2-1.5-4.8-2.2-7.6-1.9c-2.5,0.3-4.3,1.2-5.1,2.9c-0.3,0.8-0.1,1.6,0.4,2.5l2.8,3
	l-0.3-1.2c-0.1-3,0.8-5,2.8-5.5C305.1,432.7,306.6,432.9,308.3,433.4L308.3,433.4z"
          />
          <path
            fill="#FFFFFF"
            d="M283.2,441l3-0.5l-2.9-0.1 M81.4,352.8l-0.6-8.1l-1.5,4.5l0.1,1.9l0.1,1.5c1.8,12.8,5.1,22.4,9.6,28.9
	C86,375.2,83.2,365.6,81.4,352.8L81.4,352.8z"
          />
          <path
            fill="#FFFFFF"
            d="M79.7,352.8l-0.1-1.5v15.8c0,2.1,0.6,4,1.5,5.6l4.5,6.5c15.4,20.6,34.9,37.7,58.9,51.2
	c1.5,0.8,2.8,1.1,3.7,1l-1.5-0.8l-0.7-0.4c-20.5-11.4-38-25.9-52.4-43.2l-4-5.1v-0.1C84.7,375.2,81.4,365.6,79.7,352.8L79.7,352.8z"
          />
          <path
            fill="#EBEBEB"
            d="M150.2,402.9v3.4c0,2.3,0.8,4.3,2.3,5.6v-7.7L150.2,402.9L150.2,402.9z M105.2,382.2h10.3l-16.9-9.8
	c1,3.3,2.8,6.1,5.1,8.1L105.2,382.2L105.2,382.2z"
          />
          <path
            fill="#EBEBEB"
            d="M94.5,367.5l-0.7-0.3v1.5c0.3,3.7,1.9,7.4,4.8,11.1c5.9,5.9,10.6,10.5,13.9,13.5
	c15.3,12.9,29.9,21.7,43.6,26.1c0-1.1-0.6-1.8-1.8-2.2l-0.3-0.1c-12.8-4.7-26.3-12.9-40.3-24.9l-11-10.7c-3.3-3-5.4-7.2-5.9-12.5
	C96,368.3,95.2,367.9,94.5,367.5L94.5,367.5z"
          />
          <path
            fill="#FFFFFF"
            d="M145.8,430l0.7,0.4c-1.5-1.5-3.3-2.9-5.1-4.3l-5.1-3.4c-15.1-9.5-29.4-21.5-42.9-35.9
	C107.9,404.1,125.3,418.6,145.8,430L145.8,430z M667.7,299.8l0.5-0.5 M682.6,395.6c-1.4,2.8-2.9,5-4.5,6.5h1l4.4-0.3l2.2-0.1
	l4.1-0.3l3-0.3l3.3-0.3l4.7-0.5l2.8-0.4c4.1-0.5,8.1-1.2,12-2.1l7.2-1.6l3-0.8l2.1-0.5c1.1-2.1,1.9-4.1,2.9-6.3
	c2.1-5.1,3.7-10.7,5.1-16.8l-8.9,6.3c-3.9,9.5-15.8,15.3-35.6,17.3L682.6,395.6L682.6,395.6z"
          />
          <path
            fill="#D2D3D4"
            d="M682.3,402.3l1.1-0.5L679,402l-1.8,0.8l-0.8,0.5h-0.5c-4.4,1.9-9.1,3.6-14,5l1.2,1.2
	C669.9,407.7,676.4,405.2,682.3,402.3L682.3,402.3z"
          />
          <path
            fill="#D2D3D4"
            d="M683.3,401.8l-1.1,0.5c-5.9,2.9-12.4,5.4-19.3,7.3l-1.2-1.2l-0.1-0.1c-0.3,1.2-0.1,2.2,0.4,3
	c5.2,6.2,11.1,11,18,14.6l1-0.1c3.3-0.4,6.5-1,9.4-1.8l-2.8-1.6l-1.8-1.1l-1-0.5l-1.7-1.1l-1-0.7l-1.7-1.1l-5.9-4.1
	c-1.4-1.2-1.2-2.1,0.3-2.6l4.3-1.8c5.9-2.6,11.6-5.5,16.6-8.5l-3.3,0.3c-7.8,4.8-16.8,8.7-27,11.6l-1-1l-1.2-1.2
	c8-2.2,15.3-5.1,21.9-8.7L683.3,401.8L683.3,401.8z"
          />
          <path
            fill="#D2D3D4"
            d="M692.8,401l-3,0.3c-7.3,4.3-15.5,7.7-24.8,10.3l1,1C675.9,409.6,684.9,405.9,692.8,401L692.8,401z"
          />
          <path
            fill="#D2D3D4"
            d="M689.7,401.2l-4.1,0.3c-6.6,3.6-13.9,6.5-21.9,8.7l1.2,1.2C674.1,408.9,682.3,405.6,689.7,401.2z"
          />
          <path
            fill="#EBEBEB"
            d="M703.4,399.7l-2.8,0.4l-0.3,0.1c-4.4,3-9.1,6.2-14.3,9.2l-1.9,1.1c-1.4,0.8-1.8,1.4-1.1,1.9l6.6,4l2.8,1.6
	l2.6,1.6l1.2,0.7l2.1-0.8c12-5,21-13.2,27.2-24.8l-3,0.8l-0.5,1.1c-3.3,6.1-7.7,11.1-13.2,15.1c-3,2.2-6.3,4-9.9,5.6
	c-1.4,0.5-2.8,0.4-4.1-0.3l-10.2-5.9l0.8-0.4l0.4-0.3h0.1l1.1-0.5l0.1-0.1c5.2-3,10-6.1,14.6-9.2L703.4,399.7L703.4,399.7z"
          />
          <path
            fill="#EBEBEB"
            d="M703.5,399.7L702,401l13.5-2.5h0.1l3.2-0.7c-2.3,5.8-5.6,10.6-9.9,14.4c5.5-4.1,9.9-9.1,13.2-15.1l0.6-1.1
	l-7.2,1.6C711.6,398.3,707.6,399,703.5,399.7L703.5,399.7z"
          />
          <path
            fill="#D2D3D4"
            d="M700.5,400.3l0.3-0.1l-4.7,0.6c-5.1,3-10.6,5.9-16.6,8.5c-1.7,0.8-1.8,1.8-0.3,2.9l7,4.1l3.4,0.4l-6.6-4
	c-0.5-0.5-0.3-1.2,1.1-1.9l1.9-1.1C691.4,406.3,696.1,403.3,700.5,400.3L700.5,400.3z"
          />
          <path
            fill="#E6E7E7"
            d="M714.2,401.6c0.5-1.1,1.1-2.2,1.2-3.2L702,401c-4.4,3-9.4,6.2-14.6,9.2l-0.1,0.1l-1.1,0.5l9.5,5.5
	c0.3,0.1,0.4,0.3,0.7,0.3l1.4-0.1C704.2,414,709.7,409.1,714.2,401.6L714.2,401.6z M699.9,403.3c0.7-0.5,1.7-0.8,2.8-1l9.2-1.1
	c0.5,0.1,0.7,0.4,0.5,1v0.1c-0.3,0.7-0.7,1.4-1.1,2.1c-3.9,5.4-8.3,9.1-13.5,11l-1.4,0.1l-0.7-0.1l-6.9-4.1L699.9,403.3L699.9,403.3
	z"
          />
          <path
            fill="#E6E7E7"
            d="M702.7,402.3c-1.1,0.1-1.9,0.4-2.8,1l-11,7.8l6.9,4.1l0.7,0.1l1.4-0.1c5.1-2.1,9.6-5.6,13.5-11
	c0.6-0.7,0.8-1.2,1.1-2.1V402c0.1-0.5-0.1-0.8-0.5-1L702.7,402.3L702.7,402.3z M700.3,403.8c0.5-0.4,1.2-0.7,1.9-0.8l8.9-0.1
	l-1.1,1.6c-3.6,4.8-7.7,8.1-12.4,9.9l-1.2,0.1l-0.7-0.1l-5.5-3.3l0.8-0.6l7.2-5.1L700.3,403.8L700.3,403.8z"
          />
          <path
            fill="#E6E7E7"
            d="M705.3,404.5h2.1l-8,8l-0.8,0.4c-1.1,0.5-2.5,0.5-3.9,0l-3.9-2.3l-0.8,0.5l5.5,3.3l0.7,0.1l1.2-0.1
	c4.8-1.8,8.9-5.1,12.4-9.9l1.1-1.6L702,403c-0.7,0.1-1.4,0.4-1.9,0.8l-2.1,1.5c0.8-0.4,1.7-0.7,2.8-0.7L705.3,404.5L705.3,404.5z"
          />
          <path
            fill="#E6E7E7"
            d="M707.5,404.4h-2.1c-2.1,0.1-4.1,0.7-5.8,1.6l-6.2,4.4l3,1.9c0.8,0.3,1.5,0.4,2.3,0.4l0.8-0.4L707.5,404.4
	L707.5,404.4z"
          />
          <path
            fill="#E6E7E7"
            d="M705.3,404.5l-4.3,0.1c-1.1,0-2.1,0.3-2.8,0.7l-7.2,5.1l2.2,0.1l6.2-4.4
	C701.3,405.2,703.2,404.7,705.3,404.5L705.3,404.5z"
          />
          <path
            fill="#E6E7E7"
            d="M695,412.8c1.4,0.6,2.6,0.6,3.9,0c-0.7,0-1.5-0.1-2.3-0.4l-3-1.9l-2.2-0.1L695,412.8L695,412.8z"
          />
          <path
            fill="#E6E7E7"
            d="M715.5,398.5c-0.3,1-0.7,2.1-1.2,3.2c-4.5,7.4-10,12.4-16.4,14.9l-1.4,0.1c-0.3,0-0.5-0.1-0.7-0.3l-9.5-5.5
	h-0.1l-0.4,0.3l10.2,5.9l2.1,0.1c6.7-2.5,12.4-7.4,17.1-15.1c0.7-1.2,1.1-2.3,1.4-3.4c-0.3,1.1-0.7,2.2-1.4,3.4
	c-4.7,7.6-10.3,12.7-17.1,15.1l-2.1-0.1l-10.2-5.9l-0.8,0.4l10.2,5.9c1.4,0.7,2.8,0.7,4.1,0.3c3.6-1.5,6.9-3.4,9.9-5.6
	c4.4-3.7,7.7-8.5,9.9-14.4L715.5,398.5L715.5,398.5z"
          />
          <path
            fill="#D2D3D4"
            d="M664,403.6l-2.5,4.7l0.1,0.1c5-1.4,9.6-3,14-5H664V403.6L664,403.6z"
          />
          <path fill="#FFFFFF" d="M678.9,402h-1l-0.8,0.8" />
          <path
            fill="#D2D3D4"
            d="M675,411c-1.5,0.5-1.7,1.4-0.3,2.6l5.9,4.1l5.4-1.7l-7-4.1c-1.5-1.1-1.4-1.9,0.3-2.9L675,411L675,411z"
          />
          <path
            fill="#FFFFFF"
            d="M711.2,427.1l3-1.6c6.1-3.4,11.6-7.4,16.4-12c-2.1,1.8-4.3,3.4-6.9,5.1c-8.1,5.2-18.6,9.4-31.5,12.5
	c-1.8,0.4-3.7,0.8-5.6,1.2l-0.7,0.1l-2.6,3.4c9.2-1.8,17.7-4.4,25.6-7.6L711.2,427.1L711.2,427.1z"
          />
          <path
            fill="#FFFFFF"
            d="M702.3,433c2.9-2.2,5.8-4.1,8.9-5.9l-2.2,1.1c-7.8,3.2-16.4,5.8-25.6,7.6v0.7l-8.7,4.8l7.8-1.2
	C691.8,438.4,698.3,436,702.3,433L702.3,433z"
          />
          <path fill="#E6E7E7" d="M692.4,418.3l-1.8,0.5l1,0.5l3.4,0.6" />
          <path
            fill="#E6E7E7"
            d="M690.6,418.8l1.8-0.5l-3.6-0.5l-5.5,1.8l1.8,1.1"
          />
          <path
            fill="#E6E7E7"
            d="M691.5,419.4l-1-0.5l-5.5,1.8l1,0.7 M692.4,418.3l-2.8-1.6l-1.8,0.5l1,0.5"
          />
          <path
            fill="#E6E7E7"
            d="M689.6,416.6l-3.4-0.5l-5.5,1.8l1.7,1.1l5.5-1.8 M694.6,421.2l1.7-0.5l-1.2-0.7l-1.8,0.5"
          />
          <path
            fill="#E6E7E7"
            d="M696.2,420.6l-1.8,0.5l-5.2,1.5l5.2-1.5l-1.2-0.7l-5.5,1.8l2.8,1.6c3-0.7,5.9-1.6,8.5-2.8l-0.5-1.4
	L696.2,420.6L696.2,420.6z"
          />
          <path fill="#E6E7E7" d="M695,419.9l-3.4-0.5l-5.5,1.9l1.7,1l5.5-1.8" />
          <path
            fill="#EBEBEB"
            d="M698.3,419.8l0.5,1.4c12.8-5.1,22.4-14,28.8-26.7l-2.1,0.5C719.5,406.6,710.4,414.8,698.3,419.8L698.3,419.8
	z"
          />
          <path fill="#E6E7E7" d="M682.3,419l1,0.5l5.5-1.8l-1-0.5" />
          <path
            fill="#FFFFFF"
            d="M695.7,428.2l3.3-7c-2.8,1.1-5.5,2.1-8.5,2.8c-3,0.7-6.1,1.2-9.4,1.8L695.7,428.2L695.7,428.2z M686.7,432.3
	c1.9-0.4,3.9-0.8,5.6-1.2c12.9-3.2,23.4-7.3,31.5-12.5c-8.3,5-17.7,8.3-28.2,9.8L686.7,432.3L686.7,432.3z"
          />
          <path
            fill="none"
            d="M698.3,405.4l-7.2,5.1 M698.7,412.9c-0.7,0-1.5-0.1-2.3-0.4l-3-1.9"
          />
          <path fill="none" d="M691.1,410.4l3.9,2.3c1.4,0.6,2.6,0.6,3.9,0" />
          <path
            fill="none"
            d="M716.2,398.8c-0.3,1.1-0.7,2.2-1.4,3.4c-4.7,7.6-10.3,12.7-17.1,15.1l-2.1-0.1l-10.2-5.9"
          />
          <path
            fill="none"
            d="M715.6,398.5c-0.3,1-0.8,2.1-1.4,3.2c-4.5,7.4-10,12.4-16.4,14.9l-1.4,0.1c-0.3,0-0.5-0.1-0.7-0.3l-9.5-5.5"
          />
          <path fill="none" d="M693.3,410.6l6.2-4.4c1.8-1,3.7-1.5,5.8-1.6" />
          <path
            fill="none"
            d="M698.3,405.4l2.1-1.5c0.5-0.4,1.2-0.7,1.9-0.8l8.9-0.1l-1.1,1.6c-3.6,4.8-7.7,8.1-12.4,9.9l-1.2,0.1l-0.7-0.1
	l-5.5-3.3l0.8-0.6"
          />
          <path fill="none" d="M705.3,404.5l-4.3,0.1c-1.1,0-2.1,0.3-2.8,0.7" />
          <path
            fill="none"
            d="M702.7,402.3l9.2-1.1c0.5,0.1,0.7,0.4,0.5,1v0.1c-0.3,0.7-0.7,1.4-1.1,2.1c-3.9,5.4-8.3,9.1-13.5,11l-1.4,0.1
	l-0.7-0.1l-6.9-4.1l11-7.8C700.8,402.7,701.6,402.3,702.7,402.3L702.7,402.3z"
          />
          <path
            fill="none"
            d="M698.7,412.9l0.8-0.5l8-8l-2.2,0.1 M691.1,410.4l2.2,0.1 M691.1,187.3l7.2,5.1 M693.3,187.2l6.2,4.4
	c1.8,1,3.7,1.5,5.8,1.6"
          />
          <path
            fill="none"
            d="M705.3,193.2H701c-1.1,0-2.1-0.3-2.8-0.8 M705.3,193.2h2.1l-8-8c-0.3-0.3-0.5-0.4-0.8-0.4"
          />
          <path fill="none" d="M693.3,187.2l3-1.9c0.8-0.3,1.5-0.4,2.3-0.4" />
          <path
            fill="none"
            d="M698.7,184.8c-1.1-0.6-2.5-0.6-3.9,0l-3.9,2.3 M691.1,187.3l2.2-0.1"
          />
          <path
            fill="none"
            d="M716.2,199c-0.3-1.1-0.7-2.2-1.4-3.3c-4.7-7.6-10.3-12.7-17.1-15.1l-2.1,0.1l-10.2,5.9"
          />
          <path
            fill="none"
            d="M696.6,182.4c-0.3,0-0.5,0.1-0.7,0.3l-6.9,4.1l11,7.7c0.7,0.6,1.7,1,2.8,1l9.2,1.1c0.5-0.1,0.7-0.4,0.5-1v-0.1
	c-0.3-0.7-0.7-1.4-1.1-1.9c-3.9-5.4-8.3-9.1-13.5-11L696.6,182.4L696.6,182.4z"
          />
          <path
            fill="none"
            d="M686.2,186.9l9.5-5.5c0.3-0.1,0.4-0.3,0.7-0.3l1.4,0.1c6.5,2.5,12,7.4,16.4,14.9c0.6,1.1,1.1,2.2,1.2,3.2h0.1"
          />
          <path
            fill="none"
            d="M698.3,192.4l2.1,1.5c0.5,0.4,1.2,0.7,1.9,0.8l8.9,0.1c-0.1-0.4-0.4-0.7-0.7-1.1l-0.4-0.6
	c-3.6-4.8-7.7-8.1-12.4-9.9l-1.2-0.1l-0.7,0.1l-5.5,3.3l0.8,0.6 M683.4,162v-0.7l-8.7-4.8 M674.8,156.5l7.8,1.2
	c9.1,1.6,15.7,4.1,19.7,7.2c2.9,2.2,5.8,4.1,8.9,5.9 M683.4,162c-7-1.4-14.3-2.5-22.1-3c-16.8-1.2-33-2.1-48.8-2.3"
          />
          <path
            fill="none"
            d="M711.2,170.7c-0.7-0.4-1.5-0.8-2.2-1.1c-7.8-3.2-16.4-5.8-25.6-7.6 M711.2,170.7l3,1.6
	c6.1,3.4,11.6,7.4,16.4,12 M699,176.6l-3.3-7 M694.6,176.6l-1.4,0.7 M694.6,176.6l-5.4-1.6 M696.2,177.1l-1.7-0.6 M690.4,173.8
	c3,0.7,5.9,1.6,8.5,2.8 M687.7,175.3l-1.7,1.1 M685.1,177.1l1-0.7 M680.9,172h-0.8 M686.7,165.4H686 M688.8,180l-1,0.6 M682.3,178.8
	c0.3-0.3,0.7-0.4,1-0.7 M680.7,179.9l1.7-1.1 M708.9,185.5c-3-2.2-6.3-4-9.9-5.6c-1.4-0.4-2.8-0.4-4.1,0.1l-10.2,5.9l0.8,0.4
	l0.4,0.3l0.1,0.1l1.1,0.6l0.1,0.1c5.2,3,10,6.1,14.6,9.2"
          />
          <path
            fill="none"
            d="M700.6,197.6l-0.3-0.1c-4.4-3-9.1-6.2-14.3-9.2l-1.9-1.1c-1.4-0.7-1.8-1.4-1.1-1.9l6.6-4 M686.2,181.7l-5.5-1.8
	 M683.3,178.1l5.5,1.9 M687.8,180.6l-5.5-1.8 M686.2,181.7l-7,4.1c-1.5,1.1-1.4,1.9,0.3,2.8 M689.6,181.1l2.8-1.6 M691.5,178.4
	l-1,0.6 M690.6,178.9l1.8,0.6 M692.4,179.5l2.6-1.5 M695,178l-3.4,0.4 M693.2,177.3l-5.5-1.9 M687.7,175.3l2.8-1.5 M699,176.6
	l-0.7,1.4 M698.3,178l-2.1-0.8 M723.7,179.2c-8.1-5.2-18.6-9.4-31.5-12.5c-1.8-0.4-3.7-0.8-5.6-1.2 M695.7,169.6l-14.7,2.5
	 M686.7,165.4l8.9,4.1 M690.6,178.9l-5.5-1.8 M686,176.4l5.5,1.9 M689.6,181.1l-3.4,0.6 M687.8,180.6l1.8,0.6 M695,178l1.2-0.8
	 M692.4,179.5l-3.6,0.6 M683.3,178.1l1.8-1 M693.2,177.3l1.8,0.7 M690.4,173.8c-3-0.7-6.1-1.2-9.4-1.8 M692.8,196.8l3.2,0.3
	 M689.7,196.4l0.1,0.1l2.9,0.3 M683.3,196l-1.1-0.6c-5.9-2.9-12.4-5.4-19.3-7.3l-1.2,1.2 M683.3,196l2.3,0.1 M687.1,216.2
	c-1.1-5.1-2.5-9.4-4-12.8v-0.1l-0.5-1.1 M682.6,202.2c-1.4-2.8-2.9-4.9-4.5-6.5 M726.8,220.2c-3.9-9.6-15.8-15.4-35.6-17.3l-8.5-0.7
	 M685.6,196.1l4.1,0.3"
          />
          <path
            fill="none"
            d="M725.1,220.2c-4.1-8.5-15.3-13.9-33.3-16.2l-8.4-0.7 M708.9,185.5c4.4,3.7,7.7,8.5,9.9,14.4l-3.2-0.7
	 M715.5,199.3L714,199c-3.6-0.7-7.7-1.4-12-2.1 M702,196.8l1.5,1.2 M703.5,198h-0.1l-2.8-0.4 M722.6,201.9c-2.3-0.6-4.7-1.2-7.2-1.6
	c-3.9-0.8-7.8-1.5-12-2.1 M695.9,197.1c-5.1-3.2-10.6-5.9-16.6-8.5 M695.9,197.1l4.7,0.6 M679.4,188.6l-4.3-1.8
	c-1.5-0.6-1.7-1.4-0.3-2.6l5.9-4.1 M685.6,196.1c-6.6-3.6-13.9-6.5-21.9-8.7l1.2-1.2"
          />
          <path
            fill="none"
            d="M680.1,172c-6.9,3.6-12.9,8.4-18,14.6c-0.5,0.8-0.8,1.8-0.4,2.9 M661.7,189.4l-0.1,0.1"
          />
          <path
            fill="none"
            d="M664.8,186.2l1-1c10,2.9,19.1,6.7,27,11.6 M649.4,196.7c1.9-0.8,1.9-2.2,0.1-3.9c-4.7-4.5-9.9-8.4-15.8-11.4
	c-2.3-1.2-5-2.1-7.8-2.6 M602,199.8l6.6-0.1c13.2-0.4,26.1-1.2,38.5-2.3c0.8-0.1,1.5-0.4,2.2-0.6l0.3-0.1 M648.3,194.5l-2.8,0.3"
          />
          <path
            fill="none"
            d="M629.4,181.5c1.1,0.4,2.1,0.8,2.9,1.2c5.9,3.2,11.1,6.9,15.8,11.4l0.1,0.1 M649.4,196.7
	c0.1-0.7-0.3-1.4-1.1-2.3 M645.6,194.7L642,195 M677.9,195.7h1 M677.1,194.9l-0.8-0.7h-0.5 M678.9,195.7l-1.8-0.8 M675.7,194.2H664
	 M677.9,195.7l-0.8-0.8 M664,194.2c-7.4,4.4-12.4,9.5-15,15.3c-0.5,1.6-1.1,3.4-1.2,5.2 M661.5,189.5l2.5,4.7 M675.7,194.2
	c-4.4-1.9-9.1-3.6-14-4.8 M647.8,214.8h5.9c8.4-0.1,13.1,4.5,14,14c1.2,20.2,2.1,40.7,2.2,61.2v17.6c-0.1,20.5-1,41-2.2,61.4
	c-1,9.4-5.6,14-14,14h-5.9 M647.4,219.1v-0.3c0-1.4,0.1-2.6,0.3-4 M647.7,219.1h-0.3 M687.1,381.6l0.3-1c1.8-8.5,2.8-19.4,3-32.3
	v-98.8c-0.3-12.9-1.4-23.7-3-32.2l-0.3-1"
          />
          <path
            fill="none"
            d="M687.1,381.6c6.3,2.1,12.7,1.9,18.7-0.1c12.5-5.9,19.7-25.6,21.2-59l0.3-32.3l-0.3-14.6
	c-1.5-33.4-8.7-53.1-21.2-59c-6.1-2.1-12.2-2.2-18.7-0.1 M664.8,186.2c9.2,2.6,17.5,6.1,24.8,10.3 M678.9,195.7l4.4,0.3
	 M730.6,184.3c-2.1-1.8-4.3-3.4-6.9-5.1 M730.6,209.6c-0.8-2.2-1.8-4.4-2.9-6.3 M722.6,201.9l-0.5-1.2c-3.3-6.1-7.7-11.1-13.2-15.1
	 M725.6,202.7l-3-0.8 M727.7,203.3l-2.1-0.6 M730.6,184.3c6.7,6.3,12.4,13.8,16.6,22.3l0.5,1.1 M735.7,226.5l-8.9-6.3 M726.8,220.2
	h-1.7"
          />
          <path
            fill="none"
            d="M725.1,377.6l0.4-1.1c4.5-11.8,7.2-26.1,7.7-43.3c0.3-8.5,0.4-17.1,0.4-25.5v-17.6c-0.1-8.5-0.3-16.9-0.4-25.5
	c-0.5-17.1-3.2-31.5-7.7-43.3l-0.4-1.1 M737.9,357.5c0.4-3.7,0.7-7.6,0.8-11.6c0.5-12.8,0.8-25.5,1-38.1v-17.7
	c-0.1-12.7-0.4-25.3-1-38.1c-0.1-4-0.4-7.8-0.8-11.6"
          />
          <path
            fill="none"
            d="M737.9,240.3l1.2,3.4c1.4,3.4,2.1,7,2.2,10.7v0.1c0.4,11.8,0.7,23.7,0.8,35.4v17.7c-0.1,11.7-0.4,23.5-0.8,35.4
	v0.3c-0.1,3.7-1,7.3-2.2,10.7l-1.2,3.4"
          />
          <path
            fill="none"
            d="M730.6,388.2l8.3-7.7c6.1-4.7,10-13.5,12.2-26.3c1.7-15.5,2.6-31.1,3-46.4v-17.7c-0.4-15.3-1.4-30.8-3-46.4
	c-2.2-12.8-6.2-21.6-12.2-26.3l-8.3-7.7"
          />
          <path
            fill="none"
            d="M730.6,388.2c4.8-5.8,8.3-12.9,9.9-21.5c1.1-6.5,1.8-13.3,2.2-20.8c0.6-12.8,0.8-25.5,1-38.1v-17.7
	c-0.1-12.7-0.4-25.3-1-38.1c-0.3-7.4-1.1-14.3-2.2-20.8c-1.8-8.5-5.1-15.7-9.9-21.5 M737.9,240.3c-0.5-4.8-1.2-9.5-2.2-13.9
	 M747.9,207.7c3.4,7,6.1,14.7,7.8,23.1c3.7,19.5,5.9,39.3,6.5,59.2v17.7c-0.5,19.8-2.8,39.6-6.5,59.2c-1.8,8.4-4.4,16.2-7.8,23.2
	 M730.6,209.6c2.1,5.2,3.7,10.7,5.1,16.8"
          />
          <path
            fill="none"
            d="M747.9,390.1c4-4.8,7.3-12.7,10-23.2c3.7-19.5,5.9-39.3,6.5-59.2l0.1-12.7l-0.1-5.1
	c-0.5-19.8-2.8-39.6-6.5-59.2c-2.8-10.6-6.1-18.3-10-23.1 M686,165.4l-2.6-3.4 M695.7,169.6c10.5,1.5,19.8,4.7,28.2,9.6
	 M727.7,203.3c-6.3-12.7-16-21.6-28.8-26.7 M698.3,178c12,5,21,13.2,27.2,24.8 M686,165.4c-4.4-0.8-8.9-1.2-13.6-1.5
	c-17.5-0.6-34.9-1.5-52.5-2.9 M79.5,246.6l-0.1,1.9 M89.3,216.1c-4.7,6.5-7.8,16.1-9.6,28.9l-0.1,1.6"
          />
          <path
            fill="none"
            d="M148.2,166.4c-0.8-0.1-2.1,0.1-3.7,1c-23.9,13.3-43.6,30.4-58.9,51.2L81,225c-1,1.6-1.5,3.4-1.5,5.6v16"
          />
          <path
            fill="none"
            d="M146.5,167.2l-0.7,0.4c-20.5,11.4-38,25.9-52.4,43.2 M148.2,166.4l-1.6,0.8 M146.5,167.2
	c-1.5,1.5-3.3,2.9-5.1,4.3 M156.1,178.4l1.4-0.4 M96.7,229c0.7-5.4,2.6-9.5,5.9-12.5c4.7-4.5,8.3-8.1,11-10.7
	c14-12,27.5-20.2,40.3-24.9l0.1-0.1h0.1c1.2-0.6,1.8-1.2,1.8-2.2 M152.6,185.8v-1.1c2.2-1.9,5.1-3.2,8.7-3.6 M152.6,193.5
	c0.4-0.3,0.8-0.6,1.1-0.8c2.2-1.2,4.3-2.8,6.1-4.5l1.4-1.5 M150.2,194.9c0.8-0.4,1.5-1,2.3-1.4"
          />
          <path
            fill="none"
            d="M178.3,181.3l-9.9,2.6c-1.9,0.6-3.6,1.5-5.1,2.6l-3.4,3.3c-1.8,1.8-3.9,3.3-6.1,4.5l-1.1,0.7
	c-11.1,6.9-22.6,13.6-34.3,20.5c-6.5,3.9-13.2,7.7-20.2,11.6"
          />
          <path
            fill="none"
            d="M150.2,194.9v-3.4c0-2.3,0.8-4.3,2.3-5.6 M141.4,171.6l-5.1,3.4c-15.1,9.5-29.4,21.5-42.9,35.9 M152.6,193.5
	v-7.7 M164.7,174.2c-17.1,2.1-35.6,11.1-56,27.4c-4.4,3.9-8.5,7.6-12.1,11.4c-6.3,7.4-9.5,14.2-9.8,20.4c0.1,3.2,2.1,4.7,5.5,4.3
	l2.5-0.6"
          />
          <path
            fill="none"
            d="M105.2,215.5l-1.7,1.6c-2.3,2.2-4,4.8-5.1,8.1 M115.6,215.5h-10.3 M89.4,216.1h-0.1 M98.6,227.9l-0.8,0.4
	 M97.9,228.3l-1.2,0.7 M98.1,227.1l-0.1,1.2"
          />
          <path
            fill="none"
            d="M93.8,230.5c-0.6,0.1-0.8,0-1-0.6v-0.6c0-4.1,1.5-8.3,4.8-12.4c6.1-5.9,10.6-10.5,13.9-13.5
	c21.7-18.4,41.8-28.3,60.5-29.7 M93.8,230.5l0.7-0.3c0.7-0.4,1.5-0.8,2.2-1.2"
          />
          <path
            fill="none"
            d="M156.1,178.4c-13.8,4.4-28.3,13.1-43.6,26c-3.3,3-7.8,7.6-13.9,13.5c-3,3.6-4.5,7.3-4.8,11.1v1.5 M94.9,237.1
	l3.7-9.2 M98.6,225.3l-0.6,1.8 M80.9,253.1l0.6-8.1c1.8-12.8,4.5-22.4,7.8-28.9 M98.6,225.3l16.9-9.8 M178.3,181.3
	c-2.3,0.8-4.4,1.7-6.2,2.6L152.6,196c-10.6,6.5-21.5,13.1-32.6,19.5l-21.3,12.4 M93.4,211c-1.4,1.6-2.8,3.3-4,5.1"
          />
          <path
            fill="none"
            d="M162.6,181.3c-1.2-0.7-3-0.8-5.2-0.6c-13.5,4.5-27.8,13.1-42.6,25.7l-9.5,9.1 M115.6,215.5
	c11.8-6.9,23.5-13.8,34.8-20.6 M255.9,182.6c-5.2,0-10.5,0.3-15.5,1c-38.4,5.5-70.3,13.3-95.6,23.5c-15.4,6.5-30.5,14.7-45.4,25
	l119.8-24.2L94.9,237.1 M89.4,216.1c15.8-18,33.7-31.6,53.5-40.9 M185.9,156.6l-17.2,1.5c-5.4,0.8-10.2,2.2-14.2,4.3
	c-2.1,1.4-4.1,2.8-6.3,4.1 M80.9,253.1l-1.5-4.5 M279.3,156.4c-8.5-1.1-17.3-1.8-26.4-1.9h-0.3c-24.5-0.1-46.6,0.6-66.7,2.2"
          />
          <path
            fill="none"
            d="M279.3,156.4c-12.2-0.1-25.3,0-38.9,0.7H240c-22.1,2.3-44.2,4.3-66.2,5.8 M283.4,157.5l-20.6,0.7
	c-3.6,0.3-6.5,1-8.7,2.1c-7.6,3.4-16.2,5.8-26,7h-0.3c-20.4,2.8-40.9,4.7-61.4,5.6 M286.3,157.3l-3-0.4 M283.2,156.9l0.1,0.6
	 M301,157.9l-2.8,3c-0.5,0.8-0.7,1.5-0.4,2.5c1,1.6,2.6,2.6,5.1,2.9c2.8,0.3,5.4-0.4,7.6-1.9 M283.4,157.5l2.9-0.1 M301.1,156.8
	l-0.1,1.1"
          />
          <path
            fill="none"
            d="M301,157.9l-0.3,1.2c-0.1,3,0.8,5,2.8,5.5c1.7,0.4,3.2,0.3,4.8-0.3h2.2 M318.3,164.3c0-1.1,0.1-2.3,0.5-3.7
	l1.2-4.5c2.6-9.1,6.5-17.7,11.3-25.9 M310.5,164.3h7.8 M301.1,156.8l-14.9,0.6 M331.4,130.2c-8.8,0.6-15.5,3-19.9,7.4
	c-5.2,5.1-8.7,11.4-10.3,19.3 M283.2,156.9l-4-0.6 M141.4,171.6c8.3-4.7,16.9-7.6,26-8.4l6.5-0.4 M173.9,162.8l12-6.2 M162.2,185.9
	c1.8-1.5,3.7-2.6,5.8-3.3l17.1-4.5l-4.1-1.2l-17.7,4.5 M172,173.8h1.1c0.4,0,0.8,0,1.2-0.1h2.1c12-0.1,23,0.6,33,2.1 M173.9,162.8
	c-1.8,1.2-3.7,3.9-5.5,7.8c-0.3,0.7-1,1.5-1.8,2.2 M166.6,172.9l-1.9,1.4 M161.2,181.3v5.5 M161.2,186.8l1-0.8 M162.2,185.9
	c1.6-1.5,1.9-3,1-4.5 M161.2,181.3h1.4 M163,181.5l-0.4-0.3 M164.7,174.2l7.3-0.4 M157.5,178l6.2,1.6l19.3-4.7h-7.6
	C169.6,175.1,163.7,176.2,157.5,178L157.5,178z M209.4,175.8c-8.5,1-16.2,2.1-23.2,3.3c-2.9,0.6-5.5,1.2-7.8,2.1 M187.8,257.3v-1.5
	c0-0.6,0.3-0.8,0.8-0.8h1.6c0.6,0,0.8,0.3,0.8,0.8v1.5c-0.1,0.3-0.3,0.4-0.7,0.4h-1.9C188,257.9,187.9,257.7,187.8,257.3
	L187.8,257.3z M238.8,403.4c-9.6-8.9-16.5-25.3-20.6-49c-1.6-15.3-2.6-30.8-2.8-46.6v-17.7c0.1-15.8,1.1-31.4,2.8-46.6
	c4.1-23.7,11-40,20.6-49 M310.1,170.3l6.1,10c-17.7-2.6-35.5-5.4-53.1-8.3l-1.2-0.1 M314.3,170.3h-4.3 M319.3,170.1l-5,0.1
	 M289.6,170.7l-1-1.1 M289.6,170.7l20.5-0.4 M277.3,184.4l-7.8-1.1l-13.6-0.7 M261.8,171.8c2.1-0.4,4.8-0.7,8-0.7l19.7-0.4
	 M255.9,185.5v-2.9 M258.8,184.8l-2.9,0.7"
          />
          <path
            fill="none"
            d="M252.4,172.3c1.5-0.4,3.2-0.8,5-1.2l8.1-0.8c7.7-0.3,15.4-0.4,23.1-0.6 M255.9,182.6c1.4-3.2,3.2-6.2,5.5-8.8
	 M277.3,184.4c-6.9-0.6-13.1-0.4-18.6,0.6 M255.9,185.5c-5.8,1.2-10.6,3.6-14.6,6.7c-0.8,0.7-1.6,1.4-2.5,2.1 M252.4,172.3l9.4-0.6
	 M261.4,173.8l-8.9-1.5 M286.5,193l72.6,9.8c14,1.2,20.2,8.9,18.7,23.2c-1.5,21.5-2.3,42.8-2.5,64.1c0,2.5-0.1,4.8-0.1,7.3l0.1,10.5
	c0.1,21.3,1,42.8,2.5,64.1c1.5,14.3-4.7,22-18.7,23.2l-72.6,9.8c-21.6,3.6-33.4-3.6-35.4-21.3c-2.9-25.3-4.4-50.6-4.5-75.8v-17.7
	c0.1-25.2,1.6-50.5,4.5-75.8C253.1,196.5,264.9,189.4,286.5,193L286.5,193z M288.6,169.6c-1.1-3.9-2.8-8-5.2-12.1 M209.4,175.8
	c12.7-1.5,27-2.8,43.1-3.6 M410.2,156.6c1,0.4,1.9,0.7,3.2,1c1.5,0.4,3.3,0.7,5.2,1 M408.6,155.7c0.5,0.3,1.1,0.6,1.8,0.8
	 M419.4,157.3c-0.3,0.3-0.5,0.7-0.7,1.2 M412.6,155.8l-4-0.1 M416.1,154.7c-1.2,0.3-2.5,0.7-3.6,1.1 M410.2,156.6l2.3-0.8
	 M437.2,157.6c-0.3-0.6-0.7-1.1-1.2-1.4c-0.7-0.4-1.5-0.7-2.5-0.8c-4-0.3-7.8-0.1-11.6,0.3l-0.8,0.1c-0.8,0.3-1.4,0.7-1.9,1.4
	 M437.2,157.6h-7.3l-10.5-0.3 M439.5,154l-5.1-0.7c-6.6-0.6-12.7-0.1-18.3,1.4 M437.7,158.8l1.8-0.1 M418.7,158.6l11.1,0.4
	c2.9,0,5.5,0,7.8-0.1 M437.7,158.8l-0.5-1.2 M439.5,154v4.7 M449.7,157.1l1.2-0.6 M439.5,158.7l7-0.8l3.2-0.8 M448.5,156.5h2.5
	 M449.7,157.1c-0.4-0.3-0.8-0.4-1.2-0.6 M448.5,156.5c-1.5-0.7-3.2-1.2-5-1.6 M466.8,157.1h1.2 M466.8,157.1l-1.4-1.9 M465.4,155.1
	c-7.3-0.1-14.6-0.3-22-0.3 M450.9,156.5l15.8,0.6 M443.5,154.9c-1.2-0.3-2.6-0.7-4-0.8 M333.7,131.3l-2.3-1.1 M326.2,155.7l7-18.4
	l0.5-5.9"
          />
          <path
            fill="none"
            d="M332.2,133.9l0.1-0.1l0.1-0.1l1.2-2.3 M321.9,161.2l1.6-1.6l8.4-22.3l0.3-3.3 M327.3,155.8l-1.1-0.1
	 M326.2,155.8h1.1 M326.2,155.7v0.1 M321.5,163l0.1-0.1h0.1c1.1-0.8,2.1-1.9,2.9-3l1.5-3.9 M318.3,164.3c1-0.1,1.6-0.4,2.5-0.8
	l0.7-0.6 M321.5,163l0.4-1.8 M321.9,161.2l1.4-5.2c2.2-7.6,5.2-15,8.9-22 M416.1,154.7c-23.4-0.1-46.9,0-70.7,0.4l-18.2,0.7
	 M384.9,201.9c-0.5-1.8-1.9-2.9-3.9-3.2l-103.6-14.3 M385.2,206.2v0.3c-2.5,27.9-3.7,55.7-4,83.5v17.7c0.3,27.8,1.7,55.6,4,83.5v0.3
	 M384.9,201.9c0.4,1.1,0.5,2.6,0.3,4.3 M321.5,183.3c23.2,3.4,46.8,6.6,70.3,9.4c29.7,3,59.6,5.1,89.3,6.5 M319.3,170.1l5.6,11.4
	c21.6,3,43.2,5.9,65.1,8.5c26.4,2.6,52.8,4.7,79.4,5.9 M470.9,169l0.5,1.6 M457.1,170.4l12.2,25.6 M471.4,170.7l-14.3-0.3
	 M468,157.1c1.4,4.4,2.3,8.4,2.9,12 M327.3,155.8c27.2-0.4,54.2-0.4,81.2,0 M574.1,156.9l-1.8,0.1c-7.3,0.4-14.6,0.6-21.9,0.3H550
	c-27.8-1-56-1.6-84.5-2.1 M603.2,162c-1.5-0.4-3.2-0.7-5-1c-8.8-1.2-16.8-0.8-23.9,1.6h-0.4"
          />
          <path
            fill="none"
            d="M582.4,165c-2.1-0.4-3.7-0.8-5.2-1.2c-1.2-0.4-2.3-0.8-3.2-1.2 M583.8,163.2c-0.3,0.1-0.5,0.4-0.7,0.6
	c-0.3,0.3-0.5,0.7-0.7,1.2 M600.3,164.5c-0.7-0.8-1.7-1.4-2.9-1.5l-11.6-0.4c-0.8,0.1-1.5,0.3-2.1,0.7 M574.1,156.9l12.2-1.1
	c33-3.3,62.6-3,88.6,0.6 M619.9,161l-7.4-4.5 M612.4,156.5c-13.1-0.3-25.9-0.1-38.4,0.4 M619.9,161l-45.8-4.1 M600.3,164.5
	l-16.5-1.2 M480.5,196.5l10.6,0.4 M481.2,199.1l-0.7-2.6 M485,171.1l6.1,25.9 M618.9,179.5l3.9,0.4l1.8,0.3 M625.9,178.8l-3.2-0.6
	c-48.4-4.7-99.2-7.7-152-9.2 M624.5,180.2l1.4-1.4 M629.4,183.6v-2.1 M645.6,194.7c-4.3-4-9.2-7.4-14.6-10.2l-1.7-0.8 M624.5,180.2
	c1.8,0.4,3.3,0.8,4.8,1.4 M613.8,196.9c4.3-4.1,8.7-8,13.5-11.7l2.1-1.6 M625.9,178.8c1.9-3.9,3.3-7.7,4-11.7 M613.7,165.6h-0.5
	 M613.4,165.7l-3.3,0.6l-7,0.4 M613.1,165.6l0.3,0.1 M613.7,165.6l-0.3,0.1 M601.3,166.7c-0.1-0.6-0.3-1-0.4-1.2l-0.6-0.8
	 M603.1,166.7h-1.8 M606.1,178.4l2.3,9.6 M618.9,179.5l-12.8-1.1 M608.4,188c3.3-2.9,6.9-5.8,10.5-8.4 M618.5,196.7l-4.7,0.3
	 M613.8,196.9l-2.5,0.1c5.6-5.5,11.7-10.6,18-15.4 M602,199.8c6.9-7,14.4-13.6,22.6-19.7 M629.9,167.1l-16.2-1.5 M642,195
	c-3.6-3.2-7.6-5.9-11.8-8.3c-4.1,3.2-8,6.5-11.7,9.9 M491.1,196.9c33.2,1.2,66.4,1.4,99.7,0.4l7.4-0.1c3.2-3.3,6.6-6.3,10.2-9.4
	 M481.2,199.1c36.7,1.5,73.3,1.9,110.2,1L602,200 M601.3,166.7l-7.8-0.4l-11-1.2 M629.9,167.1l-10-6.1 M613.1,165.6
	c-2.9-1.5-6.2-2.8-9.9-3.6 M603.2,162l-0.1,4.7 M485,171.1c41.7,1.4,82.1,3.7,121.1,7.2 M647.8,214.8h-55
	c-73.2-0.3-142.5-4.5-208-12.9 M573.9,162.6c-35.1-2.5-70.4-4.3-106.1-5.5 M471.4,170.7l13.6,0.4 M480.5,196.5l-9.1-25.9 M469.4,196
	l11.1,0.6"
          />
          <path
            fill="none"
            d="M385.2,206.2c65.3,8.3,134.5,12.5,207.4,12.8h54.8 M261.4,173.8c19.9,3.3,39.9,6.5,60.1,9.5 M470.9,169
	c-60.8-1.2-121.6-1.1-182.3,0.6 M457.1,170.4c-45.9-0.8-91.9-0.8-137.8-0.3 M258.8,184.8l126.1,17.1 M321.5,183.3l-7.2-13.1
	 M636.1,300.9c-0.3,1.9-1.8,2.9-4.3,3H624c-3.7-0.1-5.8-1.5-6.3-4c-0.1-0.3-0.1-0.5-0.1-0.8V299 M617.4,299v0.1 M617.4,299.2V299
	 M617.4,299v-0.4c0-0.3,0-0.5,0.1-0.8c0.5-2.5,2.6-3.9,6.3-4h7.8c2.6,0.1,4,1.2,4.3,3"
          />
          <path
            fill="none"
            d="M632.9,296.4c-0.7,0-1.2,0.3-1.8,0.7s-0.7,1.1-0.7,1.8"
          />
          <path
            fill="none"
            d="M630.5,298.9c0-0.7,0.3-1.2,0.7-1.6l0.5-0.4c0.3-0.3,0.8-0.4,1.2-0.4"
          />
          <path
            fill="none"
            d="M636.1,296.8l-3-0.4h-0.1 M630.5,298.9c0,0.7,0.3,1.2,0.7,1.8c0.4,0.4,1.1,0.7,1.8,0.7"
          />
          <path
            fill="none"
            d="M632.9,301.4h0.1l3-0.4 M632.9,301.4c-0.5,0-1-0.1-1.2-0.4l-0.5-0.4c-0.4-0.4-0.7-1-0.7-1.6 M480.5,401.2
	l0.7-2.6 M491.1,400.8l-10.6,0.4 M465.4,442.6c28.5-0.4,56.7-1.1,84.5-2.1h0.4c7.3-0.3,14.6-0.3,21.9,0.1l1.8,0.1 M491.1,400.8
	l-6.1,25.7 M602,397.8l6.6,0.1c13.2,0.4,26.1,1.2,38.5,2.3l2.2,0.5l0.3,0.1 M613.8,400.8l-2.5-0.1c5.6,5.5,11.7,10.6,18,15.4
	 M618.5,401.1l-4.7-0.3 M608.4,409.8c-3.6-3-6.9-6.2-10.2-9.5h-7.4c-33.3-1-66.6-0.8-99.7,0.4 M582.4,432.7
	c3.2-0.5,6.9-0.8,11.1-1.2l7.8-0.4 M573.9,435.2c0.8-0.4,1.9-0.8,3.2-1.2c1.5-0.4,3.3-0.8,5.2-1.2 M603.2,435.8l-5,1.1
	c-8.8,1.2-16.8,0.7-23.9-1.6h-0.4 M583.8,434.5l-0.7-0.5c-0.3-0.3-0.5-0.7-0.7-1.2 M583.8,434.5c0.5,0.4,1.2,0.7,2.1,0.8l11.6-0.5
	c1.2-0.1,2.2-0.7,2.9-1.5 M574.1,440.8l12.2,1.1c33,3.3,62.6,3,88.6-0.5 M629.4,416.2c1.1-0.3,2.1-0.7,2.9-1.2
	c5.9-3.2,11.1-6.9,15.8-11.4l0.1-0.1 M629.4,414.2l1.7-0.8c5.4-2.9,10.2-6.2,14.6-10.3 M629.4,416.2v-2.1 M470.9,428.7
	c52.8-1.5,103.4-4.5,152-9.2l3.2-0.5 M624.5,417.6l-1.8,0.3l-3.9,0.4 M625.9,419l-1.4-1.4 M625.9,419c1.9,3.9,3.3,7.7,4,11.7"
          />
          <path
            fill="none"
            d="M649.4,401.1c1.9,0.8,1.9,2.2,0.1,3.9c-4.7,4.5-9.9,8.4-15.8,11.4c-2.3,1.1-5,2.1-7.8,2.6 M613.8,400.8
	c4.3,4.1,8.7,8,13.5,11.7l2.1,1.6 M624.5,417.6c1.8-0.4,3.3-0.8,4.8-1.4 M608.4,409.8l-2.3,9.6 M601.3,431.1c-0.1,0.5-0.3,1-0.4,1.4
	l-0.6,0.8 M601.3,431.1h1.8 M613.1,432.2h0.5 M613.4,432c-1-0.3-2.1-0.4-3.3-0.5l-7-0.4 M613.7,432.2l-0.3-0.1 M613.4,432l-0.3,0.1
	 M618.9,418.3c-3.6-2.8-7-5.5-10.5-8.4 M618.9,418.3l-12.8,1.1 M603.1,431.1l0.1,4.7 M629.9,430.7l-16.2,1.5 M613.1,432.2
	c-2.9,1.5-6.2,2.6-9.9,3.6 M619.9,436.7l-7.4,4.5 M629.9,430.7l-10,6.1 M602,397.8c6.9,7,14.4,13.6,22.6,19.7 M481.2,398.6
	c36.7-1.5,73.3-1.9,110.2-1c3.6,0,7.2,0,10.6,0.1 M600.3,433.3l-16.5,1.2 M574.1,440.8l45.8-4.1 M612.4,441.3
	c-13.1,0.3-25.9,0.1-38.4-0.4 M642,402.7c-3.6,3.2-7.6,5.9-11.8,8.3c-4.1-3.2-8-6.5-11.7-9.9 M606.1,419.4
	c-38.9,3.4-79.4,5.9-121.1,7.2 M385.2,391.6c0.1,1.8,0,3.2-0.3,4.4 M277.3,413.3L380.9,399c2.1-0.3,3.3-1.4,3.9-3.2 M326.2,441.9
	l-1.5-4c-1-1.1-1.9-2.1-2.9-2.9h-0.1l-0.1-0.3 M321.5,434.8l0.4,1.8 M318.3,433.4c1,0.1,1.6,0.4,2.5,0.8c0.3,0.1,0.4,0.3,0.7,0.4
	 M321.9,436.6l1.6,1.6l8.4,22.3l0.3,3.3 M326.2,442.1l1.1-0.1 M327.3,441.9h-1.1 M326.2,442.1v-0.1 M321.9,436.6l1.4,5.2
	c2.2,7.6,5.2,15,8.9,22 M327.3,441.9l18.2,0.7c23.8,0.3,47.3,0.4,70.7,0.4 M321.5,414.4c23.2-3.4,46.8-6.5,70.3-9.4
	c29.7-3,59.6-5.1,89.3-6.5 M469.4,401.8c-26.5,1.2-53,3.3-79.4,5.9c-21.9,2.6-43.5,5.5-65.1,8.5l-5.6,11.4 M410.2,441.1
	c-0.7,0.3-1.2,0.5-1.8,1 M418.7,439.3c-2.1,0.3-3.9,0.5-5.2,0.8c-1.2,0.3-2.3,0.5-3.2,1 M412.6,441.9l3.6,1.1 M408.6,442.1l4-0.1
	 M419.4,440.6c-0.3-0.3-0.5-0.8-0.7-1.2 M412.6,441.9l-2.3-0.8 M416.1,443c5.6,1.4,11.7,1.9,18.3,1.4l5.1-0.7 M437.7,438.9
	c-2.3-0.1-5-0.1-7.8-0.1l-11.1,0.4 M439.5,439.1l-1.8-0.1"
          />
          <path
            fill="none"
            d="M437.2,440.2c-2.2-0.1-4.7-0.1-7.4-0.1l-10.5,0.4 M437.7,438.9l-0.5,1.2 M439.5,443.7v-4.7 M419.4,440.6
	c0.4,0.5,1.1,1.1,1.9,1.4l0.8,0.1c3.7,0.4,7.6,0.5,11.6,0.3c1,0,1.8-0.3,2.5-0.8c0.4-0.4,0.8-0.8,1.2-1.4 M470.9,428.7l0.5-1.6
	 M449.7,440.7l-3.2-0.8c-1.9-0.4-4.3-0.7-7-0.8 M450.9,441.3c-0.4-0.1-0.8-0.3-1.2-0.5 M448.5,441.3h2.5 M443.5,442.9
	c1.8-0.4,3.4-1,5-1.6 M448.5,441.3l1.2-0.5 M468,440.7h-1.2 M466.8,440.7l-1.4,1.9 M443.5,442.9l21.9-0.3 M468,440.7
	c1.4-4.4,2.3-8.4,2.9-12 M466.8,440.7l-15.8,0.5 M471.4,427.1l-14.3,0.1 M439.5,443.7l4-0.8 M457.1,427.2l12.2-25.5 M408.6,442.1
	c-27,0.4-54.1,0.3-81.2-0.1 M333.7,466.4l-0.5-5.9l-7-18.4 M331.4,467.5l2.3-1.1 M332.2,463.8l1.5,2.6 M480.5,401.2l-11.1,0.5
	 M471.4,427.1l9.1-25.9 M485,426.5l-13.6,0.5 M318.3,433.4c0,1.1,0.1,2.3,0.5,3.7l1.2,4.5c2.6,9.1,6.5,17.6,11.3,25.9 M573.9,435.2
	c-35.1,2.5-70.4,4.3-106.1,5.5 M647.4,378.7h-54.8c-72.9,0.3-142.1,4.5-207.4,12.8"
          />
          <path
            fill="none"
            d="M384.9,395.9c65.5-8.3,134.8-12.5,208-12.9h55 M238.8,194.3c-4.9,31.4-7.6,63.3-8.1,95.6v17.7
	c0.6,32.3,3.2,64.2,8.1,95.6 M190.9,340.7v-0.3c-0.1-0.3-0.4-0.4-0.7-0.4h-1.8c-0.4,0-0.6,0.1-0.7,0.4v1.5c0,0.5,0.3,0.8,0.8,0.8
	h1.6c0.6,0,0.8-0.3,0.8-0.8v-1.2L190.9,340.7L190.9,340.7z M90.1,313.6c13.2,2.5,26.5,4.7,40,6.7l24.1,3.6l22.8,3l34.8,4
	c-11.4,0.5-23.1,0.5-34.8-0.1c-7.6-0.4-15.1-1.1-22.8-1.9l-24.1-3.6c-13.3-2.1-26.7-4.3-39.8-6.6 M90.1,284.2
	c13.2-2.3,26.5-4.5,40-6.7l24.1-3.6l22.8-3l34.8-4c-11.4-0.5-23.1-0.5-34.8,0.1c-7.6,0.4-15.1,1.1-22.8,1.9l-24.1,3.6
	c-13.3,2.1-26.7,4.3-39.8,6.7 M162.2,411.8c1.8,1.5,3.7,2.6,5.8,3.3l17.1,4.5l-4.1,1.2l-17.7-4.5 M178.3,416.5
	c-2.3-0.8-4.4-1.6-6.2-2.6c-6.5-4-12.9-8.1-19.5-12.1c-10.6-6.5-21.5-12.9-32.6-19.5c-7-4.1-14-8.3-21.3-12.4"
          />
          <path
            fill="none"
            d="M178.3,416.5l-9.9-2.6c-1.9-0.5-3.6-1.5-5.1-2.8l-3.4-3.3c-1.8-1.8-3.9-3.2-6.1-4.5l-1.1-0.7
	c-11.1-6.9-22.6-13.6-34.3-20.5c-6.5-3.9-13.2-7.7-20.2-11.6 M163,416.2l-0.4,0.3 M163,416.2c1.1-1.5,0.8-3-1-4.5"
          />
          <path
            fill="none"
            d="M161.2,411l0.8,0.7l0.1,0.1 M161.2,416.5V411 M162.6,416.5h-1.4 M164.7,423.5l1.9,1.4 M166.6,424.9
	c0.8,0.7,1.5,1.5,1.8,2.2c1.8,4,3.6,6.6,5.5,7.8 M209.4,422c-10,1.5-21,2.2-33,2.1H172 M164.7,423.5l7.3,0.4 M173.9,434.9l-6.5-0.4
	c-9.1-0.8-17.7-3.7-26-8.4 M148.2,431.4c2.2,1.2,4.4,2.6,6.3,4.1c4.1,2.1,8.8,3.4,14.2,4.3l17.2,1.5 M209.4,422
	c-8.5-1-16.2-2.1-23.2-3.4c-2.9-0.5-5.5-1.2-7.8-2.1 M157.5,419.8c6.1,1.8,12.1,2.9,17.9,3h7.6l-19.3-4.7L157.5,419.8L157.5,419.8z
	 M173.9,434.9l12,6.3"
          />
          <path
            fill="none"
            d="M283.4,440.3l-20.6-0.8c-3.6-0.3-6.5-1-8.7-1.9c-7.6-3.4-16.2-5.8-26-7h-0.3c-20.4-2.8-40.9-4.7-61.4-5.6
	 M279.3,441.4c-12.2,0.1-25.3,0-38.9-0.7H240c-22.1-2.3-44.2-4.3-66.2-5.8 M255.9,415.3l13.6-0.7l7.8-1.2 M261.8,426
	c2.1,0.4,4.8,0.7,8,0.7l19.7,0.4 M261.8,426l1.2-0.1c17.6-2.9,35.2-5.6,53.1-8.3l-6.1,10"
          />
          <path
            fill="none"
            d="M288.6,428.2l-23.2-0.7l-8-0.8l-5-1.2 M94.9,360.6l124.2,29.3L99.3,365.7c15,10.3,30.1,18.6,45.4,25
	c25.3,10.2,57.1,18,95.6,23.5c5.1,0.7,10.3,1,15.5,1 M258.8,412.9l-2.9-0.7 M255.9,415.3v-3 M185.9,441.3
	c20.1,1.5,42.2,2.2,66.7,2.2h0.3c9.1-0.1,17.9-0.8,26.4-1.9 M261.4,423.9c-2.3-2.6-4.3-5.5-5.5-8.7 M277.3,413.3
	c-6.9,0.5-13.1,0.4-18.6-0.5 M252.4,425.4l9.4,0.5 M261.4,423.9l-8.9,1.5 M319.3,427.5h-5 M301,439.9l-0.3-1.2c-0.1-3,0.8-5,2.8-5.5
	c1.7-0.4,3.2-0.3,4.8,0.3h2.2"
          />
          <path
            fill="none"
            d="M301,439.9l-2.8-3c-0.5-0.8-0.7-1.5-0.4-2.5c1-1.6,2.6-2.6,5.1-2.9c2.8-0.3,5.4,0.4,7.6,1.9 M310.1,427.5h4.3
	 M310.5,433.4h7.8 M289.6,427.1l-1,1.1 M283.2,441l3-0.5 M286.3,440.4l-2.9-0.1 M283.2,441l0.1-0.7 M301.1,441l-0.1-1.1
	 M286.3,440.4l14.9,0.6 M283.4,440.3c2.5-4.1,4.1-8.3,5.2-12.1 M310.1,427.5l-20.5-0.4 M279.3,441.4l4-0.4 M255.9,412.2
	c-5.8-1.4-10.6-3.6-14.6-6.7l-2.5-2.1 M252.4,425.4c-16.1-0.8-30.4-2.1-43.1-3.4 M90.4,279.1l-0.3,5.1 M90.1,284.2l-0.1,9.2
	l0.1,20.2 M90.1,313.6l0.3,5.1 M89.3,381.7c-3.4-6.5-6.1-16.1-7.8-28.9l-0.6-8.1 M79.4,248.5c-1,13.9-1.5,27.6-1.7,41.4
	c0,0.4-0.1,0.8-0.1,1.2l0.1,16.5c0.1,13.8,0.7,27.6,1.7,41.5 M80.9,344.7c-0.8-12.4-1.2-24.6-1.4-37v-17.6c0.1-12.2,0.6-24.6,1.4-37
	 M79.4,349.2l0.1,1.9"
          />
          <path
            fill="none"
            d="M79.5,351.2l0.1,1.5c1.8,12.8,5.1,22.4,9.6,28.9 M152.6,412c-1.5-1.4-2.3-3.2-2.3-5.6v-3.4 M150.2,402.9
	l2.3,1.4 M152.6,404.2l1.1,0.7c2.2,1.2,4.3,2.8,6.1,4.5l1.4,1.4 M105.2,382.2h10.3 M105.2,382.2l-1.7-1.6c-2.3-2.2-4-4.8-5.1-8.1"
          />
          <path
            fill="none"
            d="M172,423.9c-18.7-1.4-38.8-11.3-60.5-29.7c-3.3-3-7.8-7.6-13.9-13.5c-3.3-4-5-8.1-4.8-12.4v-0.5
	c0.1-0.5,0.4-0.7,1-0.5 M93.8,367.2l0.7,0.3c0.7,0.4,1.5,0.8,2.2,1.2"
          />
          <path
            fill="none"
            d="M156.1,419.4c-13.8-4.4-28.3-13.1-43.6-26.1c-3.3-3-7.8-7.4-13.9-13.5c-3-3.6-4.5-7.3-4.8-11.1v-1.5
	 M98.1,370.7l-0.1-1.2 M97.9,369.5l0.7,0.4 M96.7,368.8l1.2,0.7 M98.6,372.5l-0.6-1.8 M98.6,369.9l-3.7-9.2 M89.4,381.7h-0.1"
          />
          <path
            fill="none"
            d="M79.5,351.2v16c0,2.1,0.6,4,1.5,5.6l4.5,6.5c15.4,20.6,34.9,37.7,58.9,51.2c1.5,0.8,2.8,1.1,3.7,1 M93.4,386.8
	l-4-5.1"
          />
          <path
            fill="none"
            d="M94.9,360.6l-2.5-0.5c-3.6-0.3-5.4,1.1-5.5,4.3c0.1,6.1,3.4,12.8,9.8,20.4c3.6,3.9,7.7,7.6,12.1,11.4
	c20.4,16.2,38.9,25.3,56,27.4"
          />
          <path
            fill="none"
            d="M156.1,419.4c0-1.1-0.6-1.8-1.8-2.2l-0.3-0.1c-12.8-4.7-26.3-12.9-40.3-24.9l-11-10.7c-3.3-3-5.4-7.2-5.9-12.5"
          />
          <path
            fill="none"
            d="M162.6,416.5c-1.2,0.7-3,0.8-5.2,0.5c-13.5-4.5-27.8-13.1-42.6-25.7l-9.5-9.1 M98.6,372.5l16.9,9.8 M93.4,386.8
	c14.4,17.3,31.9,31.8,52.4,43.2l0.7,0.4 M148.2,431.4l-1.6-0.8 M146.5,430.4c-1.5-1.5-3.3-2.9-5.1-4.3"
          />
          <path
            fill="none"
            d="M161.2,416.5c-3.6-0.4-6.6-1.5-8.7-3.6v-1.1 M157.5,419.8l-1.4-0.4 M141.4,426.1l-5.1-3.4
	c-15.1-9.5-29.4-21.5-42.9-35.9 M89.4,381.7c15.8,18,33.7,31.6,53.5,40.9 M152.6,404.2v7.7 M115.6,382.2
	c11.8,6.9,23.5,13.8,34.8,20.6 M90.4,318.7c0.7,14,2.2,28.1,4.5,42 M80.9,344.7l-1.5,4.5 M301.1,441c1.6,7.7,5.1,14.2,10.3,19.1
	c4.5,4.4,11.1,6.9,19.9,7.4 M314.3,427.5l7.2-13.1 M321.5,414.4c-20.2,2.9-40.2,6.1-60.1,9.5 M288.6,428.2
	c60.7,1.5,121.3,1.8,182.3,0.5 M319.3,427.5c45.9,0.5,91.9,0.5,137.8-0.3 M258.8,412.9l126.1-17.1 M726.8,377.6
	c4.5-11.8,7.2-26.3,7.7-43.6c0.3-8.8,0.4-17.6,0.5-26.3v-17.6c-0.1-8.8-0.3-17.5-0.5-26.3c-0.5-17.2-3.2-31.8-7.7-43.5 M636.1,300.9
	l5.4-0.5l5.9-0.3 M647.4,297.6l17.2,0.1l1.4-0.1l1.1,0.1c0.3,0,0.4,0.1,0.5,0.3c0.3,0.1,0.4,0.3,0.5,0.5l0.1,0.3v0.1l-0.1,0.4
	 M668.3,299.3l-0.5,0.5 M667.7,299.8l-0.7,0.3l-1,0.1l-1.4-0.1h-17.3 M668.3,299.3l-0.5,0.5 M682.6,395.6l0.6-1.1
	c1.7-3.4,2.9-7.8,4-12.9 M677.9,402c1.7-1.6,3.2-3.7,4.5-6.5v-0.1 M683.3,401.8l-1.1,0.5c-5.9,2.9-12.4,5.4-19.3,7.3l-1.2-1.2
	 M685.6,401.6l-2.3,0.1 M695.9,400.7l-3.2,0.3 M692.8,401l-3,0.3 M689.7,401.2l-4.1,0.4 M683.4,394.5l8.4-0.7
	c18-2.2,29.2-7.7,33.3-16.2"
          />
          <path
            fill="none"
            d="M682.6,395.6l8.5-0.7c19.9-1.9,31.8-7.7,35.6-17.3 M703.5,399.7h-0.1l-2.8,0.4 M703.5,399.7L702,401
	 M700.6,400.1l-0.3,0.1c-4.4,3-9.1,6.2-14.3,9.2l-1.9,1.1c-1.4,0.8-1.8,1.4-1.1,1.9l6.6,4 M708.9,412.2c4.4-3.7,7.7-8.5,9.9-14.4
	l-3.2,0.7 M722.6,395.9l-7.2,1.6c-3.9,0.8-7.8,1.5-12,2.1 M702,401l13.5-2.5 M700.6,400.1l-4.7,0.5 M647.8,382.9
	c-0.3-1.2-0.3-2.6-0.3-4v-0.3 M647.4,378.7h0.3 M649.4,401.1c0.1,0.7-0.3,1.4-1.1,2.3 M645.6,403l-3.6-0.3 M648.3,403.3l-2.8-0.3
	 M664,403.6l-2.5,4.7 M661.7,408.4l-0.1-0.1 M678.9,402h-1 M677.1,402.9l-0.8,0.7h-0.5 M677.9,402l-0.8,0.8 M678.9,402l-1.8,0.8
	 M661.7,408.4c5-1.4,9.6-3,14-5 M675.7,403.6H664 M664,403.6c-7.4-4.4-12.4-9.5-15-15.3c-0.5-1.6-1.1-3.4-1.2-5.2 M683.8,428.6
	c-0.5-0.3-1.2-0.4-2.5-0.4l-7,0.3c-2.8,0.3-5.1,0.8-7,1.5s-2.9,1.4-2.9,1.9s1,1,2.9,1.1l7-0.3c2.8-0.4,5.1-0.8,7-1.5
	c0.7-0.3,1.4-0.5,1.8-0.8 M679.4,409.2l-4.3,1.8c-1.5,0.5-1.7,1.4-0.3,2.6l5.9,4.1 M692.8,401c-7.8,4.8-16.8,8.7-27,11.6l-1-1
	 M664.8,411.5l-1.2-1.2c8-2.2,15.3-5.1,21.9-8.7 M711.2,427.1l3-1.6c6.1-3.4,11.6-7.4,16.4-12 M674.8,441.3l7.8-1.2
	c9.1-1.6,15.7-4,19.7-7.2c2.9-2.2,5.8-4.1,8.9-5.9"
          />
          <path
            fill="none"
            d="M711.2,427.1l-2.2,1.1c-7.8,3.2-16.4,5.8-25.6,7.6 M690.6,418.8l1.8-0.5 M691.5,419.4l-1-0.5 M689.6,416.6
	l2.8,1.6 M696.2,420.6l-1.7,0.5 M695,419.9l1.2,0.7 M699,421.2l-0.7-1.4 M698.3,419.8l-2.1,0.8 M702,401c-4.4,3-9.4,6.2-14.6,9.2
	l-0.1,0.1l-1.1,0.5H686l-0.4,0.3l-0.8,0.4l10.2,5.9c1.4,0.7,2.8,0.7,4.1,0.3c3.6-1.5,6.9-3.4,9.9-5.6 M695,419.9l-3.4-0.5
	 M692.4,418.3l2.6,1.7 M693.2,420.5l1.8-0.5 M694.6,421.2l-1.4-0.7 M682.3,419l1,0.5 M680.7,417.9l1.7,1.1 M688.8,417.7l-1-0.5
	 M685.1,420.6l1,0.7 M685.1,420.6l-1.8-1.1 M683.3,419.5l5.5-1.8 M686.2,416.1l-5.5,1.8 M679.4,409.2c-1.7,0.8-1.8,1.8-0.3,2.9
	l7,4.1 M687.8,417.2l-5.5,1.8 M680.9,425.7h-0.8 M683.3,430.4l-3.2-0.4v-0.5l3.7-0.8 M683.3,430.4l1.2,0.1v-2.1l-0.7,0.1 M686,432.3
	h0.7 M695.7,428.2l3.3-7 M686.7,432.3c1.9-0.4,3.9-0.8,5.6-1.2c12.9-3.2,23.4-7.3,31.5-12.5 M688.8,417.7l3.6,0.5 M690.6,418.8
	l-5.5,1.8 M687.8,417.2l1.8-0.5 M690.4,423.9c3-0.7,5.9-1.6,8.5-2.8 M680.9,425.7c3.3-0.4,6.5-1,9.4-1.8 M695.7,428.2l-14.7-2.5
	 M686.7,432.3l8.9-4.1 M687.7,422.3l2.8,1.6 M686,421.3l1.7,1 M686,421.3l5.5-1.9 M687.7,422.3l5.5-1.8 M694.6,421.2l-5.4,1.7
	 M689.6,416.6l-3.4-0.5 M683.4,435.8v0.7l-8.7,4.8 M683.4,435.8l2.6-3.4 M661.5,408.2c-0.3,1.2-0.1,2.2,0.4,3
	c5.2,6.2,11.1,11,18,14.6 M695.9,400.7c-5.1,3-10.6,5.9-16.6,8.5 M664.8,411.5c9.2-2.6,17.5-6.1,24.8-10.3"
          />
          <path
            fill="none"
            d="M612.4,441.3c15.8-0.3,32.2-1.1,48.8-2.3c7.7-0.7,15.1-1.6,22.1-3 M686,432.3c-4.4,0.8-8.9,1.2-13.6,1.5
	c-17.5,0.5-34.9,1.5-52.5,2.9 M683.3,401.8l-4.4,0.3 M726.8,377.6l8.9-6.3 M725.1,377.6h1.7 M727.7,394.5c1.1-2.1,1.9-4.1,2.9-6.3
	 M725.6,395l-3,0.8 M727.7,394.5l-2.1,0.5 M722.6,395.9l-0.5,1.1c-3.3,6.1-7.7,11.1-13.2,15.1 M747.9,390.1l-0.5,1.1
	c-4.4,8.5-9.9,16-16.6,22.3 M735.7,371.2c-1.4,6.1-3,11.7-5.1,16.8 M730.6,413.5c-2.1,1.8-4.3,3.4-6.9,5.1 M699,421.2
	c12.8-5.1,22.4-14,28.8-26.7 M723.7,418.6c-8.3,5-17.7,8.3-28.2,9.8 M647.4,300.1v78.5 M725.6,395c-6.2,11.6-15.3,19.8-27.2,24.8
	 M735.7,371.2c1-4.4,1.7-8.9,2.2-13.9 M629.9,167.1c16.8,1.5,33.4,3.2,50.1,5 M94.9,237.1c-2.3,13.9-3.9,27.9-4.5,42.1 M618.5,196.7
	L642,195 M647.4,219.1v78.6 M642,402.7l-23.5-1.6 M629.9,430.7l50.2-5 M647.4,297.6l-11.3-0.8 M683.4,161.3v0.7
	c9.2,1.8,17.7,4.4,25.6,7.6c0.7,0.4,1.5,0.7,2.2,1.1c-3.2-1.8-6.1-3.7-8.9-5.9c-4-3-10.6-5.5-19.7-7.2l-7.8-1.2L683.4,161.3
	L683.4,161.3z"
          />
          <path
            fill="none"
            d="M683.4,162v-0.7l-8.7-4.8c-26-3.7-55.4-3.9-88.6-0.6l-12.2,1.1c12.5-0.6,25.3-0.7,38.4-0.4
	c15.8,0.3,32.2,1.1,48.8,2.3C669.1,159.5,676.4,160.5,683.4,162L683.4,162z"
          />
          <path
            fill="none"
            d="M683.4,162c-7-1.4-14.3-2.5-22.1-3c-16.8-1.2-33-2.1-48.8-2.3l7.4,4.4c17.6,1.4,35.2,2.5,52.5,2.9
	c4.7,0.1,9.2,0.7,13.6,1.5L683.4,162L683.4,162z"
          />
          <path
            fill="none"
            d="M711.2,170.7c-0.7-0.4-1.5-0.8-2.2-1.1c-7.8-3.2-16.4-5.8-25.6-7.6l2.6,3.4l0.7,0.1c1.9,0.3,3.8,0.7,5.6,1.2
	c12.9,3.2,23.4,7.3,31.5,12.5c2.5,1.5,4.8,3.3,6.9,5.1c-4.8-4.5-10.3-8.5-16.4-12L711.2,170.7L711.2,170.7z"
          />
          <path
            fill="none"
            d="M695.7,169.6l3.3,7c12.8,5.1,22.4,14,28.8,26.7c1.1,2.1,1.9,4.1,2.9,6.3l8.3,7.7c6.1,4.7,10,13.5,12.2,26.3
	c1.7,15.7,2.6,31.1,3,46.4v17.7c-0.4,15.4-1.4,30.8-3,46.4c-2.2,12.8-6.2,21.6-12.2,26.3l-8.3,7.7c-0.8,2.2-1.8,4.3-2.9,6.3
	c-6.3,12.7-16,21.6-28.8,26.7l-3.3,7c10.5-1.5,19.8-4.7,28.2-9.8c2.5-1.5,4.8-3.3,6.9-5.1c6.7-6.3,12.4-13.8,16.6-22.3l0.5-1.1
	c3.4-7,6.1-14.7,7.8-23.2c3.7-19.5,5.9-39.3,6.5-59.2v-17.3c-0.5-19.8-2.8-39.6-6.5-59.2c-1.8-8.4-4.4-16.1-7.8-23.1l-0.5-1.1
	c-4.4-8.5-9.9-15.8-16.6-22.3c-2.1-1.8-4.3-3.4-6.9-5.1C715.5,174.2,706.1,170.9,695.7,169.6L695.7,169.6z"
          />
          <path
            fill="none"
            d="M699,176.6l-3.3-7l-14.7,2.5c3.3,0.4,6.5,1,9.4,1.8C693.3,174.5,696.2,175.5,699,176.6L699,176.6z"
          />
          <path
            fill="none"
            d="M693.2,177.3l1.2-0.8l-5.2-1.5l5.2,1.5l1.8,0.6l2.1,0.8l0.5-1.4c-2.8-1.1-5.5-1.9-8.5-2.8l-2.8,1.5L693.2,177.3
	L693.2,177.3z"
          />
          <path
            fill="none"
            d="M694.6,176.6l-1.4,0.7l1.8,0.7l1.2-0.8 M686,176.4l1.8-1.1l2.8-1.5c-3-0.7-6.1-1.2-9.4-1.8l-1-0.1
	c-6.9,3.6-12.9,8.4-18,14.6c-0.5,0.8-0.8,1.8-0.4,2.9l0.1-0.1l1.2-1.2c7,1.9,13.3,4.4,19.3,7.3l1.1,0.6l2.2,0.1
	c-6.6-3.6-13.9-6.5-21.9-8.7l1.2-1.2l1-1c10,2.9,19.1,6.7,27,11.6l3.3,0.3c-5.1-3.2-10.6-5.9-16.6-8.5l-4.3-1.8
	c-1.5-0.6-1.7-1.4-0.3-2.6l5.9-4.1l1.7-1.1c0.3-0.3,0.7-0.4,1-0.7l1.7-1.1L686,176.4L686,176.4z"
          />
          <path
            fill="none"
            d="M687.7,175.3l-1.7,1.1l5.5,1.9l3.4-0.4l-1.8-0.7 M686,176.4l-1,0.7l5.5,1.8l1-0.6 M680.1,172l1,0.1l14.6-2.5
	l-8.9-4.1l-0.7-0.1c-4.4-0.8-8.9-1.2-13.6-1.5c-17.5-0.6-34.9-1.5-52.5-2.9l10,6.1C646.7,168.5,663.3,170.3,680.1,172L680.1,172z
	 M687.8,180.6l1-0.6l-5.5-1.8c-0.3,0.1-0.7,0.4-1,0.7L687.8,180.6L687.8,180.6z"
          />
          <path fill="none" d="M688.8,180l-1,0.6l1.8,0.6l2.8-1.6" />
          <path
            fill="none"
            d="M682.3,178.8l-1.7,1.1l5.5,1.8l3.4-0.6l-1.8-0.6 M687.4,187.6l-0.1-0.1l-1.1-0.6l-0.1-0.1l-0.4-0.3l-0.8-0.4
	l10.2-5.9c1.4-0.6,2.8-0.7,4.1-0.1c3.6,1.5,6.9,3.4,9.9,5.6c5.5,4,9.9,9.1,13.2,15.1l0.5,1.2l3,0.8c-6.2-11.6-15.3-19.8-27.2-24.8
	l-2.1-0.8l-1.2,0.8l-2.6,1.5l-2.8,1.6l-6.6,4c-0.5,0.6-0.3,1.2,1.1,1.9l1.9,1.1c5.2,3.2,10,6.2,14.3,9.2l0.3,0.1l2.8,0.4l-1.5-1.2
	C697.4,193.8,692.6,190.8,687.4,187.6L687.4,187.6z"
          />
          <path
            fill="none"
            d="M687.3,187.6l0.1,0.1c5.2,3,10,6.1,14.6,9.2c4.4,0.7,8.4,1.4,12,2.1l1.5,0.3c-0.3-1-0.7-2.1-1.2-3.2
	c-4.5-7.4-10-12.4-16.4-14.9l-1.4-0.1c-0.3,0-0.5,0.1-0.7,0.3l-9.5,5.5L687.3,187.6L687.3,187.6z M696.6,182.4l1.4,0.1
	c5.1,2.1,9.6,5.6,13.5,11c0.5,0.6,0.8,1.2,1.1,1.9v0.1c0.1,0.6-0.1,0.8-0.5,1l-9.2-1.1c-1.1-0.1-1.9-0.4-2.8-1l-11-7.7l6.9-4.1
	C696.1,182.5,696.3,182.4,696.6,182.4L696.6,182.4z"
          />
          <path
            fill="none"
            d="M698,182.5l-1.4-0.1c-0.3,0-0.5,0.1-0.7,0.3l-6.9,4.1l11,7.7c0.7,0.6,1.7,1,2.8,1l9.2,1.1
	c0.5-0.1,0.7-0.4,0.5-1v-0.1c-0.3-0.7-0.7-1.4-1.1-1.9C707.6,188.1,703.1,184.4,698,182.5L698,182.5z M695.8,183.5l0.7-0.1l1.2,0.1
	c4.8,1.8,8.9,5.1,12.4,9.9l0.4,0.6c0.3,0.3,0.5,0.7,0.7,1.1l-8.9-0.1c-0.7-0.1-1.4-0.4-1.9-0.8l-2.1-1.5l-7.2-5.1l-0.8-0.6
	L695.8,183.5L695.8,183.5z"
          />
          <path
            fill="none"
            d="M696.5,183.2l-0.7,0.1l-5.5,3.3l0.8,0.6l3.9-2.3c1.4-0.6,2.6-0.6,3.9,0c0.3,0.1,0.5,0.3,0.8,0.4l8,8h-6.3
	c-1.1,0-2.1-0.3-2.8-0.8l2.1,1.5c0.6,0.4,1.2,0.7,1.9,0.8l8.9,0.1c-0.1-0.4-0.4-0.7-0.7-1.1l-0.4-0.6c-3.6-4.8-7.7-8.1-12.4-9.9
	L696.5,183.2L696.5,183.2z"
          />
          <path
            fill="none"
            d="M693.3,187.2l-2.2,0.1l7.2,5.1c0.8,0.4,1.7,0.7,2.8,0.8h4.3c-2.1-0.1-4.1-0.7-5.8-1.6L693.3,187.2L693.3,187.2z
	"
          />
          <path
            fill="none"
            d="M691.1,187.3l2.2-0.1l3-1.9c0.8-0.3,1.5-0.4,2.3-0.4c-1.1-0.6-2.5-0.6-3.9,0L691.1,187.3L691.1,187.3z"
          />
          <path
            fill="none"
            d="M698.7,184.8c-0.7,0-1.5,0.1-2.3,0.4l-3,1.9l6.2,4.4c1.8,1,3.7,1.5,5.8,1.6h2.1l-8-8
	C699.2,185.1,699,185,698.7,184.8L698.7,184.8z"
          />
          <path
            fill="none"
            d="M686.2,188.3l-1.9-1.1c-1.4-0.7-1.8-1.4-1.1-1.9l6.6-4l-3.4,0.4l-7,4.1c-1.5,1.1-1.4,1.9,0.3,2.8
	c5.9,2.6,11.6,5.5,16.6,8.5l4.7,0.6l-0.3-0.1C696.1,194.5,691.4,191.4,686.2,188.3L686.2,188.3z"
          />
          <path
            fill="none"
            d="M684.7,186.1l0.8,0.4l10.2-5.9l2.1-0.1c6.7,2.5,12.4,7.4,17.1,15.1c0.7,1.2,1.1,2.3,1.4,3.3
	c-0.3-1.1-0.7-2.2-1.4-3.3c-4.7-7.6-10.3-12.7-17.1-15.1l-2.1,0.1l-10.2,5.9l0.4,0.3l0.1,0.1l9.5-5.5c0.3-0.1,0.4-0.3,0.7-0.3
	l1.4,0.1c6.5,2.5,12,7.4,16.4,14.9c0.5,1.1,1.1,2.2,1.2,3.2h0.1l3.2,0.7c-2.3-5.8-5.6-10.6-9.9-14.4c-3-2.2-6.3-4-9.9-5.6
	c-1.4-0.4-2.8-0.4-4.1,0.1L684.7,186.1L684.7,186.1z M686.2,181.7l-5.4-1.8l-5.9,4.1c-1.4,1.2-1.2,2.1,0.3,2.6l4.3,1.8
	c-1.7-0.8-1.8-1.8-0.3-2.8L686.2,181.7L686.2,181.7z M683.3,178.1l5.5,1.9l3.6-0.6l-1.8-0.6l-5.5-1.8"
          />
          <path
            fill="none"
            d="M691.5,178.4l-1,0.6l1.8,0.6l2.6-1.5 M699,176.6l-0.5,1.4c12,5,21,13.2,27.2,24.8l2.1,0.6
	C721.4,190.6,711.8,181.7,699,176.6L699,176.6z M692.4,166.7c-1.8-0.4-3.7-0.8-5.6-1.2l8.9,4.1c10.5,1.5,19.8,4.7,28.2,9.6
	C715.6,174.1,705.2,169.8,692.4,166.7L692.4,166.7z M695.9,197.1l-3.3-0.3l-2.9-0.3h-0.1l-4.1-0.4l-2.2-0.1l-4.4-0.3h-1
	c1.7,1.6,3.2,3.7,4.5,6.5v0.1l8.5,0.7c19.9,1.9,31.8,7.7,35.6,17.3l8.9,6.3c-1.4-6.1-3-11.7-5.1-16.8c-0.8-2.2-1.8-4.4-2.9-6.3
	l-2.1-0.6l-3-0.8c-2.3-0.6-4.7-1.2-7.2-1.6c-3.9-0.8-7.8-1.5-12-2.1l-2.8-0.4L695.9,197.1L695.9,197.1z"
          />
          <path
            fill="none"
            d="M689.9,196.5l2.9,0.3c-7.8-4.8-16.8-8.7-27-11.6l-1,1c9.2,2.6,17.5,6.1,24.8,10.3H689.9L689.9,196.5z
	 M683.3,196l-1.1-0.6c-5.9-2.9-12.4-5.4-19.3-7.3l-1.2,1.2c5,1.4,9.6,3,14,4.8h0.5l0.8,0.6l1.8,0.8L683.3,196L683.3,196z
	 M683.1,203.3c1.7,3.6,3,7.8,4.1,12.9c6.3-2.1,12.7-1.9,18.7,0.1c12.5,5.9,19.7,25.6,21.2,59l0.3,14.6l-0.3,32.3
	c-1.5,33.4-8.7,53.1-21.2,59c-6.1,2.1-12.2,2.2-18.7,0.1c-1.1,5.1-2.5,9.4-4,12.9l-0.6,1.1l8.5-0.7c19.9-1.9,31.8-7.7,35.6-17.3
	h-1.7c-4.1,8.5-15.3,14-33.3,16.2l-8.4,0.7l8.4-0.7c18-2.2,29.2-7.7,33.3-16.2l0.4-1.1c4.5-11.8,7.2-26.1,7.7-43.3
	c0.3-8.5,0.4-17.1,0.4-25.5v-17.5c-0.1-8.5-0.3-16.9-0.4-25.4c-0.5-17.1-3.2-31.5-7.7-43.3l-0.4-1.1c-4.1-8.5-15.3-13.9-33.3-16.2
	l-8.4-0.7l8.4,0.7c17.9,2.2,29,7.7,33.2,16.2h1.7c-3.9-9.6-15.8-15.4-35.6-17.3l-8.5-0.7L683.1,203.3L683.1,203.3z"
          />
          <path
            fill="none"
            d="M683.1,203.4l-0.5-1.2V202c-1.4-2.6-2.9-4.8-4.5-6.5l-0.8-0.8l-0.8-0.6h-12.2c-7.4,4.4-12.4,9.5-15,15.3
	c-0.5,1.6-1.1,3.4-1.2,5.2h5.9c8.4-0.1,13.1,4.5,14,14c1.2,20.2,2.1,40.7,2.2,61.2v17.6c-0.1,20.5-1,41-2.2,61.4
	c-1,9.4-5.6,14-14,14h-5.9c0.3,1.8,0.7,3.6,1.2,5.2c2.5,5.8,7.6,10.9,15,15.3h12.2l0.8-0.5l0.8-0.8c1.7-1.6,3.2-3.7,4.5-6.5v-0.1
	l0.5-1.1c1.7-3.4,2.9-7.8,4-12.9l0.3-1c1.8-8.5,2.8-19.4,3-32.3v-98.8c-0.3-12.9-1.4-23.7-3-32.2l-0.3-1
	C686.2,211.1,684.8,206.9,683.1,203.4L683.1,203.4z M685.6,196.1l4.1,0.4c-7.3-4.3-15.5-7.7-24.8-10.3l-1.2,1.2
	C671.7,189.7,679,192.5,685.6,196.1L685.6,196.1z M715.6,199.3L714,199c-3.6-0.7-7.7-1.4-12-2.1l1.5,1.2c4.1,0.6,8.1,1.2,12,2.1
	c2.5,0.6,4.8,1.1,7.2,1.6l-0.5-1.2c-3.3-6.1-7.7-11.1-13.2-15.1c4.4,3.7,7.7,8.5,9.9,14.4L715.6,199.3z"
          />
          <path
            fill="none"
            d="M661.5,189.5c-0.3-1.2-0.1-2.2,0.4-2.9c5.2-6.2,11.1-11,18-14.6c-16.6-1.8-33.4-3.4-50.1-5
	c-0.7,4-2.1,7.8-4,11.7c2.9,0.6,5.5,1.5,7.8,2.6c5.9,3,11.1,6.9,15.8,11.4c1.8,1.8,1.8,3-0.1,3.9l-0.3,0.1c-0.5,0.3-1.2,0.4-2.2,0.6
	c-12.4,1.1-25.2,1.9-38.5,2.3l-6.6,0.1l-10.6,0.1c-36.7,1-73.5,0.7-110.2-1c-29.9-1.2-59.6-3.4-89.3-6.5c-23.7-2.9-47-5.9-70.3-9.4
	c-20.2-2.9-40.2-6.1-60.1-9.5c-2.3,2.6-4.3,5.5-5.5,8.8l13.6,0.7l7.8,1.1l103.6,14.3c2.1,0.3,3.3,1.4,3.9,3.2
	c65.5,8.3,134.8,12.5,208,12.9h55c0.3-1.8,0.7-3.6,1.2-5.2c2.5-5.8,7.6-10.9,15-15.3L661.5,189.5L661.5,189.5z"
          />
          <path
            fill="none"
            d="M661.7,189.4l2.3,4.8h11.7C671.3,192.4,666.6,190.8,661.7,189.4L661.7,189.4z M649.4,196.7
	c1.9-0.8,1.9-2.2,0.1-3.9c-4.7-4.5-9.9-8.4-15.8-11.4c-2.3-1.2-5-2.1-7.8-2.6l-1.4,1.5c1.8,0.4,3.3,0.8,4.8,1.4
	c1.1,0.4,2.1,0.8,2.9,1.2c5.9,3.2,11.1,6.9,15.8,11.4l0.1,0.1C649.2,195.3,649.6,196,649.4,196.7L649.4,196.7z"
          />
          <path
            fill="none"
            d="M649.2,196.8l0.3-0.1c0.1-0.7-0.3-1.4-1.1-2.3l-2.8,0.3L642,195l-23.5,1.6l-4.7,0.3l-2.5,0.1
	c5.6-5.5,11.7-10.6,18-15.4c-1.5-0.6-3.2-1-4.8-1.4C616.4,186.4,609,193,602,200l6.6-0.1c13.2-0.4,26.1-1.2,38.5-2.3
	C647.9,197.2,648.6,196.9,649.2,196.8L649.2,196.8z"
          />
          <path
            fill="none"
            d="M645.6,194.7l2.8-0.3l-0.1-0.1c-4.7-4.5-9.9-8.4-15.8-11.4l-2.9-1.2v2.1l1.7,0.8
	C636.4,187.3,641.2,190.8,645.6,194.7L645.6,194.7z"
          />
          <path
            fill="none"
            d="M642,195l3.6-0.4c-4.3-4-9.2-7.4-14.6-10.2l-1.7-0.8l-2.1,1.6c-4.8,3.7-9.2,7.7-13.5,11.7l4.7-0.3
	c3.7-3.4,7.6-6.7,11.7-9.9C634.4,189.1,638.3,191.9,642,195L642,195z M677.9,195.7h1l-1.8-0.8 M653.7,214.8h-5.9
	c-0.3,1.2-0.3,2.6-0.3,4v0.3h0.1h-0.1v78.6l17.2,0.1l1.4-0.1l1.1,0.1c0.3,0,0.4,0.1,0.5,0.3c0.3,0.1,0.4,0.3,0.6,0.5l0.1,0.3v0.1
	l-0.1,0.4l-0.5,0.5l-0.6,0.3l-1.1,0.1l-1.4-0.1l-17.2,0.1v78.5h0.1h-0.1v0.3c0,1.4,0.1,2.6,0.3,4h5.9c8.4,0.1,13.1-4.5,14-14
	c1.2-20.4,2.1-40.7,2.2-61.4v-17.7c-0.1-20.5-1-41-2.2-61.2C666.8,219.4,662.1,214.7,653.7,214.8L653.7,214.8z M647.4,218.7
	c0-1.4,0.1-2.6,0.3-4h-55c-73.2-0.3-142.5-4.5-208-12.9c0.4,1.1,0.5,2.6,0.3,4.3c65.3,8.3,134.5,12.5,207.4,12.8h54.8L647.4,218.7
	L647.4,218.7z M687.1,216.2l0.3,1c1.8,8.5,2.8,19.4,3,32.2v98.8c-0.3,12.9-1.4,23.7-3,32.3l-0.3,1c6.3,2.1,12.7,1.9,18.7-0.1
	c12.5-5.9,19.7-25.6,21.2-59l0.3-32.3l-0.3-14.6c-1.5-33.4-8.7-53.1-21.2-59C699.8,214.3,693.6,214.1,687.1,216.2L687.1,216.2z"
          />
          <path
            fill="none"
            d="M735.7,226.5l-8.9-6.3c4.5,11.8,7.2,26.3,7.7,43.5c0.3,8.8,0.4,17.6,0.5,26.3v17.7c-0.1,8.8-0.3,17.5-0.5,26.3
	c-0.5,17.2-3.2,31.8-7.7,43.6l8.9-6.3c1-4.4,1.7-8.9,2.2-13.9c0.4-3.7,0.7-7.6,0.8-11.6c0.5-12.8,0.8-25.5,1-38.1v-17.6
	c-0.1-12.7-0.4-25.3-1-38.1c-0.1-4-0.4-7.8-0.8-11.6C737.3,235.5,736.5,230.8,735.7,226.5L735.7,226.5z"
          />
          <path
            fill="none"
            d="M726.8,220.2h-1.7l0.4,1.1c4.5,11.8,7.2,26.1,7.7,43.3c0.3,8.5,0.4,17.1,0.4,25.5v17.7c0,8.5-0.1,17.1-0.4,25.5
	c-0.5,17.1-3.2,31.5-7.7,43.3l-0.4,1.1h1.7c4.5-11.8,7.2-26.3,7.7-43.6c0.3-8.8,0.4-17.6,0.5-26.3v-17.7c-0.1-8.8-0.3-17.5-0.5-26.3
	C733.9,246.5,731.3,232,726.8,220.2L726.8,220.2z M737.9,240.3c0.4,3.7,0.7,7.6,0.8,11.6c0.5,12.8,0.8,25.5,1,38.1v17.7
	c-0.1,12.7-0.4,25.3-1,38.1c-0.1,4-0.4,7.8-0.8,11.6l1.2-3.4c1.4-3.4,2.1-7,2.2-10.7v-0.3c0.4-11.8,0.8-23.5,0.8-35.4v-17.5
	c-0.1-11.7-0.4-23.5-0.8-35.4v-0.1c-0.1-3.7-1-7.3-2.2-10.7L737.9,240.3L737.9,240.3z"
          />
          <path
            fill="none"
            d="M741.5,254.4v0.1c0.4,11.8,0.7,23.7,0.8,35.4v17.7c-0.1,11.7-0.4,23.5-0.8,35.4v0.3c-0.1,3.7-1,7.3-2.2,10.7
	l-1.2,3.4c-0.5,4.8-1.2,9.5-2.2,13.9c-1.4,6.1-3,11.7-5.1,16.8c4.8-5.8,8.3-12.9,9.9-21.5c1.1-6.5,1.8-13.3,2.2-20.8
	c0.6-12.8,0.8-25.5,1-38.1v-17.7c-0.1-12.7-0.4-25.3-1-38.1c-0.3-7.4-1.1-14.3-2.2-20.8c-1.8-8.5-5.1-15.7-9.9-21.5
	c2.1,5.2,3.7,10.7,5.1,16.8c1,4.4,1.7,9.1,2.2,13.9l1.2,3.4C740.5,247.2,741.2,250.9,741.5,254.4L741.5,254.4z"
          />
          <path
            fill="none"
            d="M751.1,243.6c-2.2-12.8-6.2-21.6-12.2-26.3l-8.3-7.7c4.8,5.8,8.3,12.9,9.9,21.5c1.1,6.5,1.8,13.3,2.2,20.8
	c0.6,12.8,0.8,25.5,1,38.1v17.7c-0.1,12.7-0.4,25.3-1,38.1c-0.3,7.4-1.1,14.3-2.2,20.8c-1.8,8.5-5.1,15.7-9.9,21.5l8.3-7.7
	c6.1-4.7,10-13.5,12.2-26.3c1.7-15.5,2.6-31.1,3-46.4v-17.6C753.7,274.7,752.7,259.3,751.1,243.6L751.1,243.6z"
          />
          <path
            fill="none"
            d="M747.9,207.7c3.4,7,6.1,14.7,7.8,23.1c3.7,19.5,5.9,39.3,6.5,59.2v17.7c-0.5,19.8-2.8,39.6-6.5,59.2
	c-1.8,8.4-4.4,16.2-7.8,23.2c4-4.8,7.3-12.7,10-23.2c3.7-19.5,5.9-39.3,6.5-59.2l0.1-12.7l-0.1-5.1c-0.5-19.8-2.8-39.6-6.5-59.2
	C755.2,220.3,751.8,212.5,747.9,207.7L747.9,207.7z M79.5,246.6l-0.1,1.9l1.5,4.5l0.6-8.1c1.8-12.8,4.5-22.4,7.8-28.9
	c-4.7,6.5-7.8,16.1-9.6,28.9L79.5,246.6L79.5,246.6z"
          />
          <path
            fill="none"
            d="M79.5,246.6l0.1-1.6c1.8-12.8,5.1-22.4,9.6-28.9v-0.1c1.2-1.6,2.6-3.4,4-5.1c14.6-17.2,32.1-31.6,52.5-43.1
	l0.7-0.4l1.5-0.8c-0.8-0.1-2.1,0.1-3.7,1c-23.8,13.2-43.5,30.3-58.7,51L81,225c-1,1.6-1.5,3.4-1.5,5.6L79.5,246.6L79.5,246.6
	L79.5,246.6z"
          />
          <path
            fill="none"
            d="M146.5,167.2l-0.7,0.4c-20.5,11.4-38,25.9-52.4,43.2c13.5-14.3,27.8-26.3,42.9-35.8l5.1-3.4
	C143.2,170.1,145,168.7,146.5,167.2L146.5,167.2z"
          />
          <path
            fill="none"
            d="M148.2,166.4l-1.5,0.8c-1.5,1.5-3.3,2.9-5.1,4.3c8.3-4.7,16.9-7.6,26-8.4l6.5-0.4l12-6.2l-17.2,1.5
	c-5.4,0.8-10.2,2.2-14.2,4.3C152.4,163.8,150.4,165.2,148.2,166.4z M157.5,178l-1.4,0.4c0,1-0.6,1.8-1.8,2.2h-0.1l-0.1,0.1
	c-12.8,4.7-26.3,12.9-40.3,24.9c-2.8,2.5-6.3,6.1-11,10.7c-3.3,3-5.4,7.2-5.9,12.5l1.1-0.7l0.1-1.1l0.6-1.8c1-3.3,2.8-6.1,5.1-8.1
	l1.7-1.6l9.5-9.1c14.9-12.7,29.2-21.2,42.6-25.7c2.2-0.3,4-0.1,5.2,0.6l0.4,0.3l17.7-4.5l4.1,1.2l-17.1,4.5c-2.1,0.7-4,1.8-5.8,3.3
	l-0.8,0.8l-1.4,1.5c-1.8,1.8-3.9,3.2-6.1,4.5c-0.4,0.3-0.8,0.6-1.1,0.8c-0.8,0.4-1.5,1-2.3,1.4c-11.3,6.9-22.8,13.8-34.8,20.6
	l-16.9,9.8l-0.6,1.8c7-4,13.8-7.8,20.2-11.6c11.7-6.9,23.1-13.8,34.3-20.5l1.1-0.7c2.2-1.2,4.3-2.8,6.1-4.5l3.4-3.3
	c1.5-1.2,3.2-2.1,5.1-2.6l9.9-2.6c2.3-0.8,5-1.5,7.8-2.1c7-1.2,14.9-2.3,23.2-3.3c-10-1.5-21-2.2-33-2.1h-2.1c-0.4,0-0.8,0-1.2,0.1
	H172c-18.6,1.4-38.8,11.3-60.5,29.7c-3.3,3-7.8,7.6-13.9,13.5c-3.3,4.1-5,8.1-4.8,12.4v0.6c0.1,0.6,0.4,0.8,1,0.6v-1.5
	c0.3-3.7,1.9-7.4,4.8-11.1c5.9-5.9,10.6-10.5,13.9-13.5c15.3-12.9,29.9-21.6,43.6-26L157.5,178c6.1-1.8,12.1-2.9,17.9-3h7.6
	l-19.3,4.7L157.5,178L157.5,178z"
          />
          <path
            fill="none"
            d="M154.3,180.6c1.2-0.6,1.8-1.2,1.8-2.2c-13.8,4.4-28.3,13.1-43.6,26c-3.3,3-7.8,7.6-13.9,13.5
	c-3,3.6-4.5,7.3-4.8,11.1v1.5l0.7-0.3c0.7-0.4,1.5-0.8,2.2-1.2c0.7-5.4,2.6-9.5,5.9-12.5c4.7-4.5,8.3-8.1,11-10.7
	c14-12,27.5-20.2,40.3-24.9L154.3,180.6L154.3,180.6z M152.6,184.8v8.7c0.4-0.3,0.8-0.6,1.1-0.8c2.2-1.2,4.3-2.8,6.1-4.5l1.4-1.5
	v-5.5C157.6,181.7,154.8,182.8,152.6,184.8L152.6,184.8z"
          />
          <path
            fill="none"
            d="M152.6,185.8v-1.1c2.2-1.9,5.1-3.2,8.7-3.6h1.2c-1.2-0.7-3-0.8-5.2-0.6c-13.5,4.5-27.8,13.1-42.6,25.7l-9.5,9.1
	h10.5c11.8-6.9,23.5-13.8,34.8-20.6v-3.4C150.2,189.1,151,187.2,152.6,185.8L152.6,185.8z"
          />
          <path
            fill="none"
            d="M150.2,194.9c0.8-0.4,1.5-1,2.3-1.4v-7.6c-1.5,1.4-2.3,3.2-2.3,5.6V194.9L150.2,194.9z"
          />
          <path
            fill="none"
            d="M153.8,194.3l-1.1,0.7c-11.1,6.9-22.6,13.6-34.3,20.5c-6.5,3.9-13.2,7.7-20.2,11.6l-0.1,1.1l0.8-0.4l21.3-12.4
	c11.1-6.5,22-13.1,32.6-19.5l19.5-12.1c1.8-1,3.9-1.8,6.2-2.6l-9.9,2.6c-1.9,0.6-3.6,1.5-5.1,2.6l-3.4,3.3
	C158.1,191.6,156,193.1,153.8,194.3L153.8,194.3z"
          />
          <path
            fill="none"
            d="M141.4,171.6l-5.1,3.4c-15.1,9.5-29.4,21.5-42.9,35.9c-1.4,1.6-2.8,3.3-4,5.1c15.8-18,33.7-31.6,53.5-40.9
	c-19.8,9.2-37.7,22.8-53.5,40.9v0.1c-3.4,6.5-6.1,16.1-7.8,28.9l-0.6,8.1c-0.8,12.4-1.2,24.6-1.4,37V308c0.1,12.2,0.6,24.6,1.4,37
	l0.6,8.1c1.8,12.8,4.5,22.4,7.8,28.9v0.1c15.8,18,33.7,31.6,53.5,40.9c-19.8-9.2-37.7-22.8-53.5-40.9l4,5.1
	c13.5,14.4,27.9,26.4,42.9,35.9l5.1,3.4c8.3,4.7,16.9,7.6,26,8.4l6.5,0.4c-1.8-1.2-3.7-3.9-5.5-7.8c-0.3-0.7-1-1.5-1.8-2.2l-1.9-1.4
	c-17.1-2.1-35.6-11.1-56-27.4c-4.4-3.9-8.5-7.6-12.1-11.4c-6.3-7.4-9.5-14.2-9.8-20.4c0.1-3.2,2.1-4.7,5.5-4.3l2.5,0.5
	c-2.3-13.9-3.9-27.9-4.5-42L90,314l-0.3-20.2l0.3-9.2l0.3-5.1c0.7-14,2.2-28.1,4.5-42.1l-2.5,0.6c-3.6,0.4-5.4-1.1-5.5-4.3
	c0.1-6.1,3.4-12.8,9.8-20.4c3.6-3.9,7.7-7.6,12.1-11.4c20.4-16.2,38.9-25.3,56-27.4l1.9-1.4c0.8-0.7,1.5-1.5,1.8-2.2
	c1.8-4,3.6-6.6,5.5-7.8l-6.5,0.4C158.3,164.1,149.7,166.8,141.4,171.6L141.4,171.6z"
          />
          <path
            fill="none"
            d="M108.8,201.6c-4.4,3.9-8.5,7.6-12.1,11.4c-6.3,7.4-9.5,14.2-9.8,20.4c0.1,3.2,2.1,4.7,5.5,4.3l2.5-0.6l3.7-9.2
	l-0.8,0.4l-1.1,0.7c-0.8,0.4-1.5,0.8-2.2,1.2l-0.7,0.3c-0.6,0.1-0.8,0-1-0.6v-0.6c0-4.1,1.5-8.3,4.8-12.4
	c6.1-5.9,10.6-10.5,13.9-13.5c21.7-18.4,41.8-28.3,60.5-29.7l-7.3,0.3C147.7,176.2,129,185.4,108.8,201.6L108.8,201.6z"
          />
          <path
            fill="none"
            d="M105.2,215.5l-1.7,1.6c-2.3,2.2-4,4.8-5.1,8.1l17.1-9.8H105.2z M98.6,227.9l-3.7,9.2l124.2-29.2l-120,24.2
	c15-10.3,30.1-18.6,45.4-25c25.3-10.2,57.1-18,95.6-23.5c5.1-0.7,10.3-1,15.5-1c1.4-3.2,3.2-6.2,5.5-8.8l-9.1-1.5
	c-16.1,0.8-30.4,2.1-43.1,3.6c-8.5,1-16.2,2.1-23.2,3.3c-2.9,0.6-5.5,1.2-7.8,2.1c-2.3,0.8-4.4,1.7-6.2,2.6L152.3,196
	c-10.6,6.5-21.5,13.1-32.6,19.5C119.7,215.5,98.6,227.9,98.6,227.9z"
          />
          <path
            fill="none"
            d="M144.7,207.1c-15.4,6.5-30.5,14.7-45.4,25l120-24.2L94.9,237.1c-2.3,13.9-3.9,27.9-4.5,42.1
	c13.1-2.5,26.4-4.7,39.8-6.7l24.1-3.6c7.7-0.8,15.4-1.5,22.8-1.9c11.7-0.5,23.4-0.7,34.8-0.1l-34.8,4l-22.8,3l-24.1,3.6
	c-13.5,2.1-26.8,4.3-40,6.7l-0.3,9.2l0.3,20.2c13.2,2.5,26.6,4.7,40,6.7l24.1,3.6l22.8,3l34.8,4c-11.4,0.5-23.1,0.5-34.8-0.1
	c-7.6-0.4-15.1-1.1-22.8-1.9l-24.1-3.6c-13.3-2.1-26.7-4.3-39.8-6.6c0.7,14,2.2,28.1,4.5,42l124.2,29.3L99.3,365.7
	c15,10.3,30.1,18.6,45.4,25c25.3,10.2,57.1,18,95.6,23.5c5.1,0.7,10.3,1,15.5,1v-2.9c-5.8-1.4-10.6-3.6-14.6-6.7l-2.5-2.1
	c-9.6-8.9-16.5-25.3-20.6-49c-1.6-15.3-2.6-30.8-2.8-46.6v-17.9c0.1-15.8,1.1-31.4,2.8-46.6c4.1-23.7,11-40,20.6-49
	c0.8-0.8,1.7-1.5,2.5-2.1c4-3.2,8.8-5.5,14.6-6.7v-2.9c-5.2,0-10.5,0.3-15.5,1C201.8,189.1,170,196.8,144.7,207.1L144.7,207.1z
	 M188.5,257.9c-0.4,0-0.6-0.1-0.7-0.4V256c0-0.6,0.3-0.8,0.8-0.8h1.6c0.6,0,0.8,0.3,0.8,0.8v1.5c-0.1,0.3-0.3,0.4-0.7,0.4
	L188.5,257.9L188.5,257.9z M190.9,340.7v1.2c0,0.5-0.3,0.8-0.8,0.8h-1.6c-0.6,0-0.8-0.3-0.8-0.8v-1.5c0.1-0.3,0.4-0.4,0.7-0.4h1.6
	c0.4,0,0.7,0.1,0.7,0.4L190.9,340.7L190.9,340.7z"
          />
          <path
            fill="none"
            d="M187.8,257.3c0.1,0.3,0.4,0.4,0.7,0.4h1.8c0.4,0,0.6-0.1,0.7-0.4v-1.5c0-0.6-0.3-0.8-0.8-0.8h-1.6
	c-0.6,0-0.8,0.3-0.8,0.8v1.2L187.8,257.3L187.8,257.3z M190.9,341.8v-1.5c-0.1-0.3-0.4-0.4-0.7-0.4h-1.8c-0.4,0-0.6,0.1-0.7,0.4v1.5
	c0,0.5,0.3,0.8,0.8,0.8h1.6C190.7,342.6,190.9,342.3,190.9,341.8L190.9,341.8z M80.9,253.1l-1.5-4.5c-1,13.9-1.5,27.8-1.7,41.5
	c0,0.4-0.1,0.8-0.1,1.2l0.1,16.5c0.1,13.8,0.7,27.6,1.7,41.5l1.5-4.5c-0.8-12.4-1.2-24.6-1.4-37v-17.7
	C79.6,277.7,80.2,265.5,80.9,253.1L80.9,253.1z M252.8,154.4h-0.3c-24.5-0.1-46.6,0.6-66.7,2.2l-12,6.2c21.9-1.5,44-3.4,66.2-5.8
	h0.3c13.8-0.6,26.7-0.8,38.9-0.7C270.7,155.1,261.9,154.6,252.8,154.4L252.8,154.4z"
          />
          <path
            fill="none"
            d="M279.3,156.4c-12.2-0.1-25.3,0-38.9,0.7H240c-22.1,2.3-44.2,4.3-66.2,5.8c-1.8,1.2-3.7,3.9-5.5,7.8
	c-0.3,0.7-1,1.5-1.8,2.2c20.5-1,41-2.9,61.4-5.6h0.3c9.8-1.2,18.4-3.6,26-7c2.2-1.1,5.1-1.8,8.7-2.1l20.6-0.7l-0.1-0.6L279.3,156.4
	L279.3,156.4z"
          />
          <path
            fill="none"
            d="M262.9,158.1c-3.6,0.3-6.5,1-8.7,2.1c-7.6,3.4-16.2,5.8-26,7h-0.3c-20.4,2.8-40.9,4.7-61.4,5.6l-1.9,1.4
	l7.3-0.3h1.1c0.4,0,0.8,0,1.2-0.1h2.1c12-0.1,23,0.6,33,2.1c12.7-1.5,27-2.8,43.1-3.6c1.5-0.4,3.2-0.8,5-1.2l8.1-0.8
	c7.7-0.3,15.4-0.4,23.1-0.6c-1.1-3.9-2.8-8-5.2-12.1L262.9,158.1L262.9,158.1z M286.3,157.3l-3-0.4l0.1,0.6"
          />
          <path
            fill="none"
            d="M297.8,163.4c-0.3-0.8-0.1-1.6,0.4-2.5l2.8-3l0.1-1.1l-14.9,0.6l-2.9,0.1c2.5,4.1,4.1,8.3,5.2,12.1
	c60.7-1.5,121.3-1.8,182.3-0.6c-0.5-3.6-1.5-7.6-2.9-12h-1.2l-15.8-0.6l-1.2,0.6l-3.2,0.8l-7,0.8l-1.8,0.1c-2.3,0.1-5,0.1-7.8,0.1
	l-11.1-0.4c-2.1-0.3-3.9-0.6-5.2-1c-1.2-0.3-2.3-0.6-3.2-1c-0.7-0.3-1.2-0.6-1.8-0.8c-27-0.4-54.1-0.4-81.2,0h-1.1l-1.5,3.9
	c-1,1.1-1.9,2.1-2.9,3h-0.1l-0.1,0.1l-0.7,0.6c-0.7,0.4-1.5,0.8-2.5,0.8h-7.8c-2.2,1.5-4.8,2.2-7.6,1.9
	C300.4,166,298.8,165,297.8,163.4L297.8,163.4z"
          />
          <path
            fill="none"
            d="M298.2,160.9c-0.5,0.8-0.7,1.5-0.4,2.5c1,1.6,2.6,2.6,5.1,2.9c2.8,0.3,5.4-0.4,7.6-1.9h-2.2
	c-1.5,0.7-3.2,0.7-4.8,0.3c-2.1-0.6-2.9-2.5-2.8-5.5l0.3-1.2L298.2,160.9L298.2,160.9z"
          />
          <path
            fill="none"
            d="M301.1,156.8l-0.1,1.1l-0.3,1.2c-0.1,3,0.8,5,2.8,5.5c1.7,0.4,3.2,0.3,4.8-0.3h10c0-1.1,0.1-2.3,0.5-3.7
	l1.2-4.5c2.6-9.1,6.5-17.7,11.3-25.9c-8.8,0.6-15.5,3-19.9,7.4C306.2,142.6,302.8,149.1,301.1,156.8L301.1,156.8z"
          />
          <path
            fill="none"
            d="M318.9,160.6c-0.4,1.4-0.5,2.6-0.5,3.7c1-0.1,1.6-0.4,2.5-0.8l0.7-0.6l0.4-1.8l1.4-5.2c2.2-7.6,5.2-15,8.9-22
	l0.1-0.1l0.1-0.1l1.2-2.3l-2.2-1.1c-4.8,8.1-8.7,16.8-11.3,25.9C319.7,157.6,319.3,159.1,318.9,160.6L318.9,160.6z M185,178.1
	l-4.1-1.2l-17.7,4.5c1.1,1.5,0.8,2.9-1,4.5c1.8-1.5,3.7-2.6,5.8-3.3L185,178.1L185,178.1z"
          />
          <path
            fill="none"
            d="M161.2,181.3v5.5l0.8-0.8c1.6-1.5,1.9-3,1-4.5l-0.4-0.3L161.2,181.3L161.2,181.3z M183.1,174.9h-7.6
	c-5.8,0.1-11.8,1.2-17.9,3l6.2,1.6L183.1,174.9L183.1,174.9z M238.8,194.3c-9.6,8.9-16.5,25.3-20.6,49c-1.6,15.3-2.6,30.8-2.8,46.6
	v17.7c0.1,15.8,1.1,31.4,2.8,46.6c4.1,23.7,11,40,20.6,49c-4.9-31.4-7.6-63.3-8.1-95.6v-17.6C231.2,257.6,234,225.8,238.8,194.3
	L238.8,194.3z M316,180.3l-6.1-10l-20.5,0.4l-19.7,0.4c-3.2,0-5.9,0.3-8,0.7l1.2,0.1C280.6,174.9,298.2,177.7,316,180.3L316,180.3z"
          />
          <path
            fill="none"
            d="M310.1,170.3l6.1,10c-17.7-2.6-35.5-5.4-53.1-8.3l-1.2-0.1l-9.4,0.4l9.1,1.5c19.9,3.3,39.9,6.5,60.1,9.5
	l-7.3-13.1L310.1,170.3L310.1,170.3z"
          />
          <path
            fill="none"
            d="M310.1,170.3h4.3l5.1-0.1c45.9-0.6,91.9-0.6,137.8,0.3l14.2,0.3l-0.5-1.6c-60.8-1.2-121.6-1.1-182.3,0.6l1,1.1
	L310.1,170.3L310.1,170.3z"
          />
          <path
            fill="none"
            d="M319.3,170.1l-5.1,0.1l7.3,13.1c23.2,3.4,46.8,6.6,70.3,9.4c29.7,3,59.6,5.1,89.3,6.5l-0.5-2.5l-11.1-0.6
	c-26.5-1.2-53-3.3-79.4-5.9c-21.9-2.6-43.5-5.5-65.1-8.5L319.3,170.1L319.3,170.1z M289.6,170.7l-1-1.1c-7.7,0.1-15.4,0.4-23.1,0.6
	l-8.1,0.8c-1.8,0.3-3.4,0.7-5,1.2l9.4-0.4c2.1-0.4,4.8-0.7,8-0.7L289.6,170.7L289.6,170.7z M277.3,184.4l-7.8-1.1l-13.6-0.7v2.9
	l2.9-0.6C264.3,183.9,270.5,183.7,277.3,184.4L277.3,184.4z"
          />
          <path
            fill="none"
            d="M258.8,184.8l-2.9,0.7c-5.8,1.2-10.6,3.6-14.6,6.7c-0.8,0.7-1.7,1.4-2.5,2.1c-5,31.4-7.6,63.3-8.1,95.6v17.7
	c0.6,32.3,3.2,64.2,8.1,95.6c0.8,0.8,1.6,1.5,2.5,2.1c4,3.2,8.8,5.5,14.6,6.7l2.9,0.5l126.1-17.1c0.4-1.1,0.5-2.6,0.4-4.4v-0.3
	c-2.5-27.9-3.7-55.7-4-83.5v-17.3c0.3-27.8,1.6-55.6,4-83.5v-0.3c0.1-1.8,0-3.2-0.4-4.3L258.8,184.8L258.8,184.8z M251.3,214.3
	c1.9-17.7,13.6-24.9,35.4-21.3l72.6,9.8c14,1.2,20.2,8.9,18.7,23.2c-1.5,21.5-2.3,42.8-2.5,64.1c0,2.5-0.1,4.8-0.1,7.3l0.1,10.5
	c0.1,21.3,1,42.8,2.5,64.1c1.5,14.3-4.7,22-18.7,23.2l-72.6,9.8c-21.6,3.6-33.4-3.6-35.4-21.3c-2.9-25.3-4.4-50.6-4.5-75.8v-17.7
	C246.9,264.8,248.4,239.6,251.3,214.3L251.3,214.3z"
          />
          <path
            fill="none"
            d="M286.5,193c-21.6-3.6-33.4,3.6-35.4,21.3c-2.9,25.3-4.4,50.6-4.5,75.8v17.7c0.1,25.2,1.6,50.5,4.5,75.8
	c1.9,17.7,13.6,24.9,35.4,21.3l72.6-9.8c14-1.2,20.2-8.9,18.7-23.2c-1.5-21.5-2.3-42.8-2.5-64.1l-0.1-10.5c0-2.5,0-4.8,0.1-7.3
	c0.1-21.3,1-42.8,2.5-64.1c1.5-14.3-4.7-22-18.7-23.2L286.5,193L286.5,193z M277.3,184.4c-6.9-0.6-13.1-0.4-18.6,0.6L384.9,202
	c-0.5-1.8-1.9-2.9-3.9-3.2L277.3,184.4L277.3,184.4z M413.5,157.6c1.5,0.4,3.3,0.7,5.2,1c0.1-0.6,0.4-1,0.7-1.2
	c0.4-0.7,1.1-1.1,1.9-1.4l0.8-0.1c3.7-0.6,7.6-0.6,11.6-0.3c1,0,1.8,0.3,2.5,0.8c0.4,0.3,0.8,0.8,1.2,1.4l0.5,1.2l1.8-0.1V154
	l-5.1-0.7c-6.6-0.6-12.7-0.1-18.3,1.4c-1.2,0.3-2.5,0.7-3.6,1.1l-2.3,0.8C411.2,157,412.3,157.3,413.5,157.6L413.5,157.6z
	 M408.6,155.7c0.5,0.3,1.1,0.6,1.8,0.8l2.3-0.8H408.6L408.6,155.7z"
          />
          <path
            fill="none"
            d="M419.4,157.3c-0.3,0.3-0.5,0.7-0.7,1.2l11.1,0.4c2.9,0,5.5,0,7.8-0.1l-0.6-1.2l-7.4,0.1L419.4,157.3
	L419.4,157.3z M408.6,155.7h4.1c1.1-0.4,2.3-0.8,3.6-1.1c-23.4-0.1-46.9,0-70.7,0.4l-18.2,0.7C354.5,155.4,381.6,155.4,408.6,155.7
	L408.6,155.7z"
          />
          <path
            fill="none"
            d="M422,155.7l-0.8,0.1c-0.8,0.3-1.4,0.7-1.9,1.4l10.5,0.4l7.4-0.1c-0.3-0.6-0.7-1.1-1.2-1.4
	c-0.7-0.4-1.5-0.7-2.5-0.8C429.6,155.1,425.8,155.3,422,155.7L422,155.7z M439.5,154v4.8l7-0.8l3.2-0.8c-0.4-0.3-0.8-0.4-1.2-0.6
	c-1.5-0.7-3.2-1.2-5-1.6C442.1,154.6,440.9,154.2,439.5,154L439.5,154z M449.7,157.1l1.2-0.6h-2.5
	C448.9,156.6,449.3,156.8,449.7,157.1z"
          />
          <path
            fill="none"
            d="M448.5,156.5h2.5l15.8,0.6l-1.4-1.9c-7.3-0.1-14.6-0.3-22-0.3C445.2,155.3,446.8,155.8,448.5,156.5L448.5,156.5
	z"
          />
          <path
            fill="none"
            d="M466.8,157.1h1.2c35.5,1.2,70.8,3,106.1,5.5h0.4c7.2-2.3,15.1-2.9,23.9-1.6c1.8,0.3,3.4,0.6,5,1
	c3.6,0.8,6.9,2.1,9.9,3.6l0.5,0.1L630,167l-10-6.1l-45.8-4l-1.8,0.1c-7.3,0.4-14.6,0.6-21.9,0.3h-0.4c-27.8-1-56-1.6-84.5-2.1
	L466.8,157.1L466.8,157.1z M333.2,137.2l0.5-5.9l-1.2,2.3l-0.1,0.1l-0.1,0.1l-0.3,3.3l-8.4,22.3l-1.8,1.6l-0.4,1.8l0.1-0.1h0.1
	c1.1-0.8,2.1-1.9,2.9-3l1.5-3.9v-0.1L333.2,137.2L333.2,137.2z"
          />
          <path
            fill="none"
            d="M331.9,137.2l0.3-3.3c-3.7,7-6.7,14.4-8.9,22l-1.4,5.2l1.8-1.6L331.9,137.2L331.9,137.2z M327.3,155.8l-1.1-0.1
	v0.1 M385.2,206.2v0.3c-2.5,27.9-3.7,55.7-4,83.5v17.7c0.3,27.8,1.7,55.6,4,83.5v0.3c65.3-8.3,134.5-12.5,207.4-12.8h54.8v-78.5
	l-6.1,0.3l-5.4,0.5c-0.3,1.9-1.8,2.9-4.3,3h-7.8c-3.7-0.1-5.8-1.5-6.3-4c-0.1-0.3-0.1-0.5-0.1-0.8v-0.7c0-0.3,0-0.5,0.1-0.8
	c0.5-2.5,2.6-3.9,6.3-4h7.8c2.6,0.1,4,1.2,4.3,3l11.3,0.8v-78.4h-54.8C519.7,218.7,450.5,214.4,385.2,206.2L385.2,206.2z
	 M324.9,181.5c21.6,3,43.2,5.9,65.1,8.5c26.4,2.6,52.8,4.7,79.4,5.9l-12.2-25.6c-45.9-0.8-91.9-0.8-137.8-0.3L324.9,181.5z
	 M470.9,169l0.5,1.6l13.8,0.4c41.7,1.4,82.1,3.7,121.1,7.2l12.8,1.2l3.9,0.3l1.8,0.3l1.4-1.5l-3.2-0.6
	C574.3,173.4,523.7,170.4,470.9,169L470.9,169z"
          />
          <path
            fill="none"
            d="M457.1,170.4l12.2,25.6l11.1,0.6l-9.1-25.9 M468,157.1c1.4,4.4,2.3,8.4,2.9,12c52.8,1.4,103.4,4.4,152,9.2
	l3.2,0.6c1.9-3.9,3.3-7.7,4-11.7l-16.2-1.4h-0.3l-3.3,0.6l-7,0.4h-1.8l-7.8-0.4l-11.1-1.2c-2.1-0.4-3.7-0.8-5.2-1.2
	c-1.2-0.4-2.3-0.8-3.2-1.2C538.8,160.1,503.5,158.3,468,157.1L468,157.1z"
          />
          <path
            fill="none"
            d="M574.3,162.6h-0.4c0.8,0.4,1.9,0.8,3.2,1.2c1.5,0.4,3.3,0.8,5.2,1.2c0.1-0.6,0.4-0.8,0.7-1.2
	c0.1-0.3,0.4-0.4,0.7-0.6c0.5-0.4,1.2-0.7,2.1-0.7l11.6,0.4c1.2,0.1,2.2,0.7,2.9,1.5l0.5,0.8c0.1,0.3,0.3,0.8,0.4,1.2h1.8l0.1-4.8
	c-1.5-0.4-3.2-0.7-5-1C589.5,159.7,581.5,160.2,574.3,162.6L574.3,162.6z"
          />
          <path
            fill="none"
            d="M583.1,163.8c-0.3,0.3-0.5,0.7-0.7,1.2l11.1,1.2l7.8,0.4c-0.1-0.6-0.3-1-0.4-1.2l-0.5-0.8l-16.5-1.2
	C583.5,163.4,583.4,163.7,583.1,163.8L583.1,163.8z M585.9,162.6c-0.8,0.1-1.5,0.3-2.1,0.7l16.5,1.2c-0.7-0.8-1.7-1.4-2.9-1.5
	L585.9,162.6L585.9,162.6z M619.9,161l-7.4-4.4c-13.1-0.3-25.9-0.1-38.4,0.4L619.9,161L619.9,161z M491.1,196.9l-10.6-0.4l0.5,2.5
	c36.7,1.5,73.3,1.9,110.2,1l10.6-0.1c6.9-7,14.4-13.6,22.6-19.7l-1.8-0.3l-3.8-0.3c-3.6,2.8-7,5.5-10.5,8.4c-3.6,3-6.9,6.2-10.2,9.4
	l-7.4,0.1C557.5,198.3,524.4,198.2,491.1,196.9L491.1,196.9z"
          />
          <path fill="none" d="M480.5,196.5l10.6,0.4l-6.1-25.9l-13.6-0.4" />
          <path
            fill="none"
            d="M485,171.1l6.1,25.9c33.2,1.2,66.4,1.4,99.7,0.4l7.4-0.1c3.2-3.3,6.6-6.3,10.2-9.4l-2.3-9.6
	C567.2,174.9,526.9,172.5,485,171.1L485,171.1z M629.4,183.6v-2.1c-6.3,4.8-12.4,10-18,15.4l2.5-0.1c4.3-4.1,8.7-8,13.5-11.7
	L629.4,183.6L629.4,183.6z M613.7,165.6h-0.5l0.3,0.1"
          />
          <path
            fill="none"
            d="M610.1,166.3l3.3-0.6l-0.3-0.1c-2.9-1.5-6.2-2.8-9.9-3.6l-0.1,4.8L610.1,166.3L610.1,166.3z M606.1,178.4
	l2.3,9.6c3.3-2.9,6.9-5.8,10.5-8.4L606.1,178.4L606.1,178.4z M630.2,186.8c-4.1,3.2-8,6.5-11.7,9.9L642,195
	C638.3,191.9,634.4,189.1,630.2,186.8L630.2,186.8z M617.4,299.2c0,0.3,0,0.5,0.1,0.8c0.5,2.5,2.6,3.9,6.3,4h7.8
	c2.6-0.1,4-1.2,4.3-3l-3.2,0.4c-0.7,0-1.2-0.3-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8v-0.1c0-0.7,0.3-1.2,0.7-1.8
	c0.4-0.5,1.1-0.7,1.8-0.7l3.2,0.4c-0.3-1.9-1.8-2.9-4.3-3h-7.8c-3.7,0.1-5.8,1.5-6.3,4c-0.1,0.3-0.1,0.5-0.1,0.8L617.4,299.2
	L617.4,299.2z M617.4,299v0.1"
          />
          <path
            fill="none"
            d="M632.9,296.4c-0.7,0-1.2,0.3-1.8,0.7s-0.7,1.1-0.7,1.8c0-0.7,0.3-1.2,0.7-1.7l0.5-0.4
	C632.1,296.5,632.5,296.4,632.9,296.4L632.9,296.4z"
          />
          <path
            fill="none"
            d="M632.9,296.4c-0.5,0-1,0.1-1.2,0.4l-0.5,0.4c-0.4,0.4-0.7,1-0.7,1.6v0.1c0,0.7,0.3,1.2,0.7,1.7l0.5,0.4
	c0.3,0.3,0.8,0.4,1.2,0.4l3.2-0.4l5.4-0.5l6.1-0.3l17.2-0.1l1.4,0.1l1.1-0.1l0.5-0.3l0.6-0.5l0.1-0.4v-0.1l-0.1-0.3
	c-0.1-0.1-0.3-0.4-0.5-0.5c-0.1-0.1-0.3-0.3-0.6-0.3l-1.1-0.1l-1.4,0.1l-17.2-0.1l-11.3-0.8L632.9,296.4L632.9,296.4z"
          />
          <path
            fill="none"
            d="M631.3,300.7c0.4,0.4,1.1,0.7,1.8,0.7c-0.5,0-1-0.1-1.2-0.4L631.3,300.7c-0.4-0.5-0.7-1.1-0.7-1.8
	C630.5,299.6,630.7,300.1,631.3,300.7L631.3,300.7z M481.2,398.6l-0.5,2.6l10.6-0.4c33.2-1.2,66.4-1.4,99.7-0.4h7.4
	c3.2,3.3,6.6,6.5,10.2,9.5c3.3,2.9,6.9,5.8,10.5,8.4l3.9-0.4l1.8-0.3c-8.1-6.1-15.5-12.7-22.6-19.7c-3.6-0.1-7-0.1-10.6-0.1
	C554.5,396.7,517.8,397.1,481.2,398.6L481.2,398.6z M480.5,401.2l0.5-2.6c-29.9,1.2-59.6,3.4-89.3,6.5c-23.7,2.9-47,5.9-70.3,9.4
	l-7.3,13.1l5.1,0.1l5.6-11.4c21.6-3,43.2-5.9,65.1-8.5c26.4-2.6,52.8-4.7,79.4-5.9L480.5,401.2L480.5,401.2z"
          />
          <path
            fill="none"
            d="M491.1,400.8l-10.6,0.4l-9.1,25.9l13.6-0.5 M549.8,440.6h0.4c7.3-0.3,14.6-0.3,21.9,0.1l1.8,0.1l45.8-4l10-6.1
	l-16.2,1.4l-0.5,0.1c-2.9,1.5-6.2,2.6-9.9,3.6l-5,1.1c-8.8,1.2-16.8,0.7-23.9-1.6h-0.4c-35.1,2.5-70.4,4.3-106.1,5.5h-1.2l-1.4,1.9
	C493.9,442.2,522.1,441.5,549.8,440.6L549.8,440.6z M491.1,400.8l-6.1,25.9c41.7-1.4,82.1-3.7,121.1-7.2l2.3-9.6
	c-3.6-3-6.9-6.2-10.2-9.5h-7.4C557.5,399.4,524.4,399.6,491.1,400.8L491.1,400.8z M608.6,398.1l-6.6-0.1c6.9,7,14.4,13.6,22.6,19.7
	c1.8-0.4,3.3-0.8,4.8-1.4c-6.3-4.8-12.4-9.9-18-15.4l2.5,0.1l4.7,0.3l23.5,1.6l3.6,0.4l2.8,0.3c0.8-0.8,1.2-1.6,1.1-2.3l-0.3-0.1
	l-2.2-0.5C634.7,399.3,621.8,398.5,608.6,398.1L608.6,398.1z"
          />
          <path
            fill="none"
            d="M602,397.8l6.6,0.1c13.2,0.4,26.1,1.2,38.5,2.3l2.2,0.5l0.3,0.1c1.9,0.8,1.9,2.2,0.1,3.9
	c-4.7,4.5-9.9,8.4-15.8,11.4c-2.3,1.1-5,2.1-7.8,2.6c1.9,3.9,3.3,7.7,4,11.7l50.1-5c-6.9-3.6-12.9-8.4-18-14.6
	c-0.5-0.8-0.8-1.8-0.4-3l2.5-4.7c-7.4-4.4-12.4-9.5-15-15.3c-0.5-1.6-1.1-3.4-1.2-5.2h-55c-73.2,0.3-142.5,4.5-208,12.9
	c-0.5,1.8-1.9,2.9-3.9,3.2l-103.6,14.3l-7.8,1.1l-13.8,1c1.4,3.2,3.2,6.1,5.5,8.7c19.9-3.4,39.9-6.5,60.1-9.5
	c23.2-3.4,46.8-6.5,70.3-9.4c29.7-3,59.6-5.1,89.3-6.5c36.7-1.5,73.3-1.9,110.2-1C595,397.8,598.4,397.8,602,397.8L602,397.8z"
          />
          <path
            fill="none"
            d="M613.8,400.8l-2.5-0.1c5.6,5.5,11.7,10.6,18,15.4v-1.9l-2.1-1.6C622.5,408.8,618.1,404.8,613.8,400.8
	L613.8,400.8z"
          />
          <path
            fill="none"
            d="M618.5,401.1l-4.7-0.3c4.3,4.1,8.7,8,13.5,11.7l2.1,1.6l1.7-0.8c5.4-2.9,10.2-6.2,14.6-10.3l-3.6-0.4
	c-3.6,3.2-7.6,5.9-11.8,8.3C626.1,407.8,622.2,404.4,618.5,401.1L618.5,401.1z M593.5,431.5c-4.3,0.3-8,0.7-11.1,1.2
	c0.1,0.5,0.4,0.8,0.7,1.2l0.7,0.5l16.5-1.2l0.5-0.8c0.1-0.4,0.3-0.8,0.4-1.4L593.5,431.5L593.5,431.5z"
          />
          <path
            fill="none"
            d="M582.4,432.7c3.2-0.5,6.9-0.8,11.1-1.2l7.8-0.4h1.8l7,0.4c1.2,0.1,2.3,0.4,3.3,0.5l0.3,0.1l16.2-1.4
	c-0.7-4-2.1-7.8-4-11.7l-3.2,0.5c-48.4,4.7-99.2,7.7-152,9.2c-0.5,3.6-1.5,7.6-2.9,12c35.5-1.1,70.8-3,106.1-5.5
	c0.8-0.4,1.9-0.8,3.2-1.2C578.7,433.6,580.4,433.1,582.4,432.7L582.4,432.7z"
          />
          <path
            fill="none"
            d="M577.2,434c-1.2,0.4-2.3,0.8-3.2,1.2h0.4c7.2,2.3,15.1,2.9,23.9,1.6l5-1.1l-0.1-4.8h-1.8
	c-0.1,0.5-0.3,1-0.4,1.4l-0.5,0.8c-0.7,0.8-1.7,1.4-2.9,1.5l-11.6,0.5c-0.8-0.1-1.5-0.4-2.1-0.8l-0.7-0.5c-0.3-0.3-0.5-0.7-0.7-1.2
	C580.4,433.1,578.7,433.6,577.2,434L577.2,434z"
          />
          <path
            fill="none"
            d="M585.9,435.3l11.6-0.5c1.2-0.1,2.2-0.7,2.9-1.5l-16.5,1.2C584.4,434.9,585.1,435.2,585.9,435.3L585.9,435.3z
	 M574.1,440.8l12.2,1.1c33,3.3,62.6,3,88.6-0.5l8.7-4.8v-0.7c-7,1.4-14.3,2.5-22.1,3c-16.8,1.4-33,2.1-48.8,2.3
	C599.4,441.4,586.4,441.3,574.1,440.8z M632.2,415l-2.9,1.2c-1.5,0.5-3.2,1-4.8,1.4l1.4,1.5c2.9-0.6,5.5-1.5,7.8-2.6
	c5.9-3.2,11.1-6.9,15.8-11.4c1.8-1.8,1.8-3-0.1-3.9c0.1,0.7-0.3,1.4-1.1,2.3l-0.1,0.1C643.5,408,638.2,411.8,632.2,415L632.2,415z"
          />
          <path
            fill="none"
            d="M629.4,416.2c1.1-0.3,2.1-0.7,2.9-1.2c5.9-3.2,11.1-6.9,15.8-11.4l0.1-0.1l-2.8-0.3c-4.3,4-9.2,7.4-14.6,10.3
	l-1.7,0.8L629.4,416.2L629.4,416.2z M622.8,419.5l3.2-0.5l-1.4-1.5l-1.8,0.3l-3.8,0.4l-12.8,1.2c-38.9,3.4-79.4,5.9-121.1,7.2
	l-13.8,0.4l-0.5,1.8C523.7,427.4,574.3,424.2,622.8,419.5L622.8,419.5z M629.9,430.7l-10,6.1c17.6-1.4,35.2-2.5,52.5-2.9
	c4.7-0.1,9.2-0.7,13.6-1.5l0.7-0.1l8.9-4l-14.6-2.6l-1,0.1L629.9,430.7L629.9,430.7z M667.3,430c1.9-0.7,4.4-1.2,7-1.5l7-0.3
	c1.1,0.1,1.9,0.3,2.5,0.4l0.5-0.1v2.1l-1.2-0.1c-0.4,0.3-1.1,0.6-1.8,0.8c-1.9,0.7-4.3,1.2-7,1.5l-7,0.3c-1.9-0.1-2.9-0.5-2.9-1.1
	S665.4,430.7,667.3,430L667.3,430z"
          />
          <path
            fill="none"
            d="M674.3,428.3c-2.8,0.3-5.1,0.8-7,1.5s-2.9,1.4-2.9,1.9s1,1,2.9,1.1l7-0.3c2.8-0.4,5.1-0.8,7-1.5
	c0.7-0.3,1.4-0.6,1.8-0.8l-3.2-0.4v-0.5l3.7-1c-0.5-0.3-1.2-0.4-2.5-0.4L674.3,428.3L674.3,428.3z"
          />
          <path
            fill="none"
            d="M680.1,430l3.2,0.4l1.2,0.1v-2.1l-0.7,0.1l-3.7,0.8 M608.4,409.8l-2.3,9.6l12.8-1.2
	C615.3,415.5,611.9,412.8,608.4,409.8z M613.1,432.2h0.5l-0.3-0.1"
          />
          <path
            fill="none"
            d="M613.4,432c-1-0.3-2.1-0.4-3.3-0.5l-7-0.4l0.1,4.8c3.6-1,6.9-2.1,9.9-3.6L613.4,432L613.4,432z M612.4,441.3
	l7.4-4.4l-45.8,4C586.4,441.3,599.4,441.4,612.4,441.3z"
          />
          <path
            fill="none"
            d="M619.9,436.7l-7.4,4.4c15.8-0.3,32.2-1.1,48.8-2.3c7.7-0.7,15.1-1.6,22.1-3l2.6-3.4c-4.4,0.8-8.9,1.2-13.6,1.5
	C655.1,434.4,637.5,435.3,619.9,436.7L619.9,436.7z M618.5,401.1c3.7,3.4,7.6,6.7,11.7,9.9c4.3-2.3,8.3-5.1,11.8-8.3L618.5,401.1
	L618.5,401.1z M385.2,391.6c0.1,1.8,0,3.2-0.3,4.4c65.5-8.3,134.8-12.5,208-12.9h55c-0.3-1.2-0.3-2.6-0.4-4v-0.3h-54.8
	C519.7,379.1,450.5,383.3,385.2,391.6L385.2,391.6z M380.9,399c2.1-0.3,3.3-1.4,3.9-3.2l-126.1,17.1c5.5,1,11.7,1.1,18.6,0.5
	L380.9,399L380.9,399z M321.5,434.8l0.1,0.3h0.1c1.1,0.8,2.1,1.9,2.9,2.9l1.5,4h1.1c27.2,0.4,54.2,0.4,81.2,0.1
	c0.5-0.3,1.1-0.7,1.8-1c1-0.4,1.9-0.7,3.2-1c1.5-0.4,3.3-0.7,5.2-0.8l11.1-0.4c2.9,0,5.5,0,7.8,0.1l1.8,0.1c2.8,0.1,5.1,0.5,7,0.8
	l3.2,0.8c0.4,0.1,0.8,0.3,1.2,0.5l15.8-0.5h1.2c1.4-4.4,2.3-8.4,2.9-12c-60.8,1.2-121.6,1.1-182.3-0.5c-1.1,3.9-2.8,8-5.2,12.1
	l2.9,0.1l14.9,0.5l-0.1-1.1l-2.8-3c-0.5-0.8-0.7-1.5-0.4-2.5c1-1.6,2.6-2.6,5.1-2.9c2.8-0.3,5.4,0.4,7.6,1.9h7.8
	c1,0.1,1.7,0.4,2.5,0.8L321.5,434.8L321.5,434.8z"
          />
          <path
            fill="none"
            d="M321.6,435.1l-0.1-0.3l0.4,1.8l1.8,1.6l8.4,22.3l0.3,3.3l1.5,2.6l-0.5-5.9l-6.9-18.4v-0.1l-1.5-4
	C323.7,436.9,322.7,435.9,321.6,435.1L321.6,435.1z"
          />
          <path
            fill="none"
            d="M321.9,436.6l-0.4-1.8c-0.3-0.1-0.4-0.4-0.7-0.4c-0.7-0.6-1.5-0.8-2.5-0.8c0,1.1,0.1,2.3,0.5,3.7l1.2,4.5
	c2.6,9.1,6.5,17.6,11.3,25.9l2.2-1.1l-1.5-2.6c-3.7-7-6.7-14.4-8.9-22L321.9,436.6L321.9,436.6z"
          />
          <path
            fill="none"
            d="M323.5,438.2l-1.8-1.6l1.4,5.2c2.2,7.6,5.2,15,8.9,22l-0.3-3.3L323.5,438.2L323.5,438.2z M326.2,442.1l1.1-0.1
	h-1.1 M327.3,441.9l18.2,0.7c23.8,0.3,47.3,0.4,70.7,0.4l-3.6-1.1l-4.1,0.1C381.6,442.4,354.5,442.4,327.3,441.9L327.3,441.9z
	 M390,407.7c-21.9,2.6-43.5,5.5-65.1,8.5l-5.6,11.4c45.9,0.5,91.9,0.5,137.8-0.3l12.2-25.6C442.8,403,416.4,405.1,390,407.7
	L390,407.7z"
          />
          <path
            fill="none"
            d="M410.2,441.1c-0.7,0.3-1.2,0.5-1.8,1l4.1-0.1L410.2,441.1L410.2,441.1z M413.5,440.2c-1.2,0.3-2.3,0.5-3.2,1
	l2.3,0.8l3.6,1.1c5.6,1.4,11.7,1.9,18.3,1.4l5.1-0.7v-4.8l-1.8-0.1l-0.5,1.2c-0.3,0.5-0.7,1.1-1.2,1.4c-0.7,0.4-1.5,0.7-2.5,0.8
	c-4,0.3-7.8,0.1-11.6-0.3l-0.8-0.1c-0.8-0.3-1.4-0.8-1.9-1.4c-0.3-0.3-0.5-0.8-0.7-1.2C416.7,439.5,415,439.7,413.5,440.2
	L413.5,440.2z"
          />
          <path
            fill="none"
            d="M418.7,439.3c0.1,0.4,0.4,0.8,0.7,1.2l10.5-0.4c2.8,0,5.1,0,7.4,0.1l0.5-1.2c-2.3-0.1-5-0.1-7.8-0.1
	L418.7,439.3L418.7,439.3z"
          />
          <path
            fill="none"
            d="M437.2,440.2c-2.2-0.1-4.7-0.1-7.4-0.1l-10.5,0.4c0.4,0.6,1.1,1.1,1.9,1.4l0.8,0.1c3.7,0.4,7.6,0.5,11.6,0.3
	c1,0,1.8-0.3,2.5-0.8C436.5,441.3,436.9,440.7,437.2,440.2L437.2,440.2z M439.5,439.1v4.8l4-0.8c1.8-0.4,3.4-1,5-1.6l1.2-0.5
	l-3.2-0.8C444.5,439.5,442.1,439.2,439.5,439.1L439.5,439.1z M470.9,428.7l0.5-1.8l-14.2,0.3c-45.9,0.8-91.9,0.8-137.8,0.3l-5.1-0.1
	h-4.3l-20.5-0.4l-1,1.1C349.3,429.8,409.9,430,470.9,428.7L470.9,428.7z"
          />
          <path
            fill="none"
            d="M450.9,441.3c-0.4-0.1-0.8-0.3-1.2-0.5l-1.2,0.5H450.9L450.9,441.3z"
          />
          <path
            fill="none"
            d="M450.9,441.3h-2.5c-1.5,0.5-3.2,1.1-5,1.6l22-0.3l1.4-1.9L450.9,441.3L450.9,441.3z M457.1,427.2l14.3-0.1
	l9.1-25.9l-11.1,0.5 M331.4,467.5c-4.8-8.3-8.7-16.8-11.3-25.9l-1.2-4.5c-0.4-1.4-0.5-2.6-0.5-3.7h-10c-1.5-0.7-3.2-0.7-4.8-0.3
	c-2.1,0.7-2.9,2.5-2.8,5.5l0.3,1.2l0.1,1.1c1.6,7.7,5.1,14.2,10.3,19.1C316,464.5,322.6,467,331.4,467.5L331.4,467.5z M177,330.8
	c11.7,0.5,23.2,0.7,34.8,0.1l-34.8-4l-22.8-3l-24.1-3.6c-13.5-2.1-26.8-4.4-39.9-6.7l0.3,5.1c13.1,2.3,26.4,4.5,39.8,6.6l24.1,3.6
	C161.9,329.7,169.5,330.4,177,330.8L177,330.8z M211.8,266.8c-11.4-0.5-23.1-0.5-34.8,0.1c-7.6,0.4-15.1,1.1-22.8,1.9l-24.1,3.6
	c-13.3,2.1-26.7,4.3-39.8,6.7l-0.3,5.1c13.2-2.3,26.6-4.5,39.9-6.7l24.1-3.6l22.8-3L211.8,266.8L211.8,266.8z M180.9,420.8l4.1-1.2
	L168,415c-2.1-0.7-4-1.8-5.8-3.3c1.6,1.5,1.9,3,1,4.5L180.9,420.8L180.9,420.8z"
          />
          <path
            fill="none"
            d="M185,419.5l-4.1,1.2l-17.7-4.5l-0.4,0.3c-1.2,0.7-3,0.8-5.2,0.5c-13.5-4.5-27.8-13.1-42.6-25.7l-9.5-9.1
	l-1.7-1.6c-2.3-2.2-4-4.8-5.1-8.1l-0.6-1.8l-0.1-1.1l-1.1-0.7c0.7,5.4,2.6,9.5,5.9,12.5l11,10.7c14,12,27.5,20.2,40.3,24.9l0.3,0.1
	c1.2,0.4,1.8,1.2,1.8,2.2l1.4,0.4l6.2-1.8l19.3,4.7h-7.6c-5.8-0.1-11.8-1.1-17.9-3l-1.4-0.4c-13.8-4.4-28.3-13.1-43.6-26.1
	c-3.3-3-7.8-7.4-13.9-13.5c-3-3.6-4.5-7.3-4.8-11.1V367c-0.6-0.1-0.8,0.1-1,0.6v0.5c0,4.1,1.5,8.3,4.8,12.4
	c6.1,5.9,10.6,10.5,13.9,13.5c21.7,18.4,41.8,28.3,60.5,29.7h4.4c12,0.1,23-0.5,33-2.1c-8.5-1-16.2-2.1-23.2-3.4
	c-2.9-0.5-5.5-1.2-7.8-2.1l-9.9-2.6c-1.9-0.5-3.6-1.5-5.1-2.8l-3.4-3.3c-1.8-1.8-3.9-3.2-6.1-4.5l-1.1-0.7
	c-11.1-6.9-22.6-13.6-34.3-20.5c-6.5-3.9-13.2-7.7-20.2-11.6l0.6,1.8l16.6,10.3c11.8,6.9,23.5,13.8,34.8,20.6l2.3,1.5l1.1,0.7
	c2.2,1.2,4.3,2.8,6.1,4.5l1.4,1.4l0.7,0.7l0.1,0.1c1.8,1.5,3.7,2.6,5.8,3.3L185,419.5L185,419.5z"
          />
          <path
            fill="none"
            d="M172.1,413.9c1.8,1,3.9,1.8,6.2,2.6c2.3,0.8,5,1.5,7.8,2.1c7,1.2,14.9,2.5,23.2,3.4c12.7,1.5,27,2.6,43.1,3.4
	l9.1-1.5c-2.3-2.6-4.3-5.5-5.5-8.7c-5.2,0-10.5-0.3-15.5-1c-38.4-5.5-70.3-13.3-95.6-23.5c-15.4-6.5-30.5-14.7-45.4-25l120,24.2
	L94.9,360.6l3.7,9.2c7.2,4.1,14.3,8.3,21.3,12.4c11.1,6.5,22,13.1,32.6,19.5C159.2,405.8,165.8,409.9,172.1,413.9L172.1,413.9z"
          />
          <path
            fill="none"
            d="M178.3,416.5c-2.3-0.8-4.4-1.6-6.2-2.6c-6.5-4-12.9-8.1-19.5-12.1c-10.6-6.5-21.5-12.9-32.6-19.5
	c-7-4.1-14-8.3-21.3-12.4l-0.8-0.4l0.1,1.1c7,4,13.8,7.8,20.2,11.6c11.7,6.9,23.1,13.8,34.3,20.5l1.1,0.7c2.2,1.2,4.3,2.8,6.1,4.5
	l3.4,3.3c1.5,1.2,3.2,2.1,5.1,2.8L178.3,416.5L178.3,416.5z M162.6,416.5l0.4-0.3c1.1-1.5,0.8-3-1-4.5l-0.1-0.1l-0.7-0.7v5.5h1.4
	V416.5L162.6,416.5z M161.2,416.5V411l-1.4-1.4c-1.8-1.8-3.9-3.2-6.1-4.5l-1.1-0.7v8.7C154.8,415,157.6,416.1,161.2,416.5
	L161.2,416.5z"
          />
          <path
            fill="none"
            d="M162.6,416.5h-1.2c-3.6-0.4-6.6-1.5-8.7-3.6v-1.1c-1.5-1.4-2.3-3.2-2.3-5.6v-3.4c-11.3-6.9-22.8-13.8-34.8-20.6
	h-10.3l9.5,9.1c14.9,12.7,29.2,21.2,42.6,25.7C159.6,417.3,161.4,417.2,162.6,416.5L162.6,416.5z M164.7,423.5l1.9,1.4
	c20.5,1,41,2.9,61.4,5.6h0.3c9.8,1.2,18.4,3.6,26,7c2.2,1.1,5.1,1.8,8.7,1.9l20.6,0.8c2.5-4.1,4.1-8.3,5.2-12.1l-23.1-0.7l-8.1-0.8
	l-5-1.2c-16.1-0.8-30.4-2.1-43.1-3.4c-10,1.5-21,2.2-33,2.1h-4.4L164.7,423.5L164.7,423.5z"
          />
          <path
            fill="none"
            d="M166.6,424.9c0.8,0.7,1.5,1.5,1.8,2.2c1.8,4,3.6,6.6,5.5,7.8c21.9,1.5,44,3.4,66.2,5.8h0.3
	c13.8,0.5,26.7,0.8,38.9,0.7l4-0.5l0.1-0.5l-20.6-0.8c-3.6-0.3-6.5-1-8.7-1.9c-7.6-3.4-16.2-5.8-26-7h-0.3
	C207.6,427.8,187.2,425.8,166.6,424.9L166.6,424.9z M164.7,423.5l7.3,0.3c-18.7-1.4-38.8-11.3-60.5-29.7c-3.3-3-7.8-7.6-13.9-13.5
	c-3.3-4-5-8.1-4.8-12.4v-0.5c0.1-0.6,0.4-0.7,1-0.6l0.7,0.3c0.7,0.4,1.5,0.8,2.2,1.2l1.1,0.7l0.8,0.4l-3.7-9.2l-2.5-0.5
	c-3.6-0.3-5.4,1.1-5.5,4.3c0.1,6.1,3.4,12.8,9.8,20.4c3.6,3.9,7.7,7.6,12.1,11.4C129,412.4,147.7,421.6,164.7,423.5L164.7,423.5z
	 M173.9,434.9l-6.5-0.4c-9.1-0.8-17.7-3.7-26-8.4c1.8,1.4,3.6,2.9,5.1,4.3l1.5,0.8c2.2,1.2,4.3,2.6,6.3,4.1
	c4.1,2.1,8.8,3.4,14.2,4.3l17.2,1.5L173.9,434.9z"
          />
          <path
            fill="none"
            d="M183.1,422.8l-19.3-4.7l-6.2,1.8c6.1,1.8,12.1,2.9,17.9,3h7.6L183.1,422.8L183.1,422.8z M173.9,434.9l12,6.2
	c20.1,1.5,42.2,2.2,66.7,2.2h0.3c9.1-0.1,17.9-0.8,26.4-1.9c-12.2,0.1-25.3,0-38.9-0.7H240C217.9,438.5,195.8,436.6,173.9,434.9
	L173.9,434.9z M269.5,414.6l7.8-1.1c-6.9,0.5-13.1,0.4-18.6-0.5l-2.9-0.5v2.9L269.5,414.6L269.5,414.6z M269.9,426.7
	c-3.2-0.1-5.9-0.3-8-0.7l-9.4-0.4l5,1.2l8.1,0.8l23.1,0.7l1-1.1L269.9,426.7L269.9,426.7z"
          />
          <path
            fill="none"
            d="M261.8,426c2.1,0.4,4.8,0.7,8,0.7l19.7,0.4l20.5,0.4l6.1-10c-17.7,2.6-35.5,5.4-53.1,8.3L261.8,426L261.8,426z"
          />
          <path
            fill="none"
            d="M261.8,426l1.2-0.1c17.6-2.9,35.2-5.6,53.1-8.3l-6.1,10h4.3l7.3-13.1c-20.2,2.9-40.2,6.1-60.1,9.5l-9.1,1.5
	L261.8,426L261.8,426z M308.3,433.4h2.2c-2.2-1.5-4.8-2.2-7.6-1.9c-2.5,0.3-4.3,1.2-5.1,2.9c-0.3,0.8-0.1,1.6,0.4,2.5l2.8,3
	l-0.3-1.2c-0.1-3,0.8-5,2.8-5.5C305.1,432.7,306.6,432.9,308.3,433.4L308.3,433.4z M283.2,441l3-0.5l-2.9-0.1 M81.4,352.8l-0.6-8.1
	l-1.5,4.5l0.1,1.9l0.1,1.5c1.8,12.8,5.1,22.4,9.6,28.9C86,375.2,83.2,365.6,81.4,352.8L81.4,352.8z"
          />
          <path
            fill="none"
            d="M79.7,352.8l-0.1-1.5v15.8c0,2.1,0.6,4,1.5,5.6l4.5,6.5c15.4,20.6,34.9,37.7,58.9,51.2c1.5,0.8,2.8,1.1,3.7,1
	l-1.5-0.8l-0.7-0.4c-20.5-11.4-38-25.9-52.4-43.2l-4-5.1v-0.1C84.7,375.2,81.4,365.6,79.7,352.8L79.7,352.8z M150.2,402.9v3.4
	c0,2.3,0.8,4.3,2.3,5.6v-7.7L150.2,402.9L150.2,402.9z"
          />
          <path
            fill="none"
            d="M105.2,382.2h10.3l-16.9-9.8c1,3.3,2.8,6.1,5.1,8.1L105.2,382.2L105.2,382.2z"
          />
          <path
            fill="none"
            d="M94.5,367.5l-0.7-0.3v1.5c0.3,3.7,1.9,7.4,4.8,11.1c5.9,5.9,10.6,10.5,13.9,13.5c15.3,12.9,29.9,21.7,43.6,26.1
	c0-1.1-0.6-1.8-1.8-2.2l-0.3-0.1c-12.8-4.7-26.3-12.9-40.3-24.9l-11-10.7c-3.3-3-5.4-7.2-5.9-12.5C96,368.3,95.2,367.9,94.5,367.5
	L94.5,367.5z M145.8,430l0.7,0.4c-1.5-1.5-3.3-2.9-5.1-4.3l-5.1-3.4c-15.1-9.5-29.4-21.5-42.9-35.9
	C107.9,404.1,125.3,418.6,145.8,430L145.8,430z M667.7,299.8l0.5-0.5 M682.6,395.6c-1.4,2.8-2.9,5-4.5,6.5h1l4.4-0.3l2.2-0.1
	l4.1-0.3l3-0.3l3.3-0.3l4.7-0.5l2.8-0.4c4.1-0.5,8.1-1.2,12-2.1l7.2-1.6l3-0.8l2.1-0.5c1.1-2.1,1.9-4.1,2.9-6.3
	c2.1-5.1,3.7-10.7,5.1-16.8l-8.9,6.3c-3.9,9.5-15.8,15.3-35.6,17.3L682.6,395.6L682.6,395.6z M682.3,402.3l1.1-0.5L679,402l-1.8,0.8
	l-0.8,0.5h-0.5c-4.4,1.9-9.1,3.6-14,5l1.2,1.2C669.9,407.7,676.4,405.2,682.3,402.3L682.3,402.3z"
          />
          <path
            fill="none"
            d="M683.3,401.8l-1.1,0.5c-5.9,2.9-12.4,5.4-19.3,7.3l-1.2-1.2l-0.1-0.1c-0.3,1.2-0.1,2.2,0.4,3
	c5.2,6.2,11.1,11,18,14.6l1-0.1c3.3-0.4,6.5-1,9.4-1.8l-2.8-1.6l-1.8-1.1l-1-0.5l-1.7-1.1l-1-0.7l-1.7-1.1l-5.9-4.1
	c-1.4-1.2-1.2-2.1,0.3-2.6l4.3-1.8c5.9-2.6,11.6-5.5,16.6-8.5l-3.3,0.3c-7.8,4.8-16.8,8.7-27,11.6l-1-1l-1.2-1.2
	c8-2.2,15.3-5.1,21.9-8.7L683.3,401.8L683.3,401.8z"
          />
          <path
            fill="none"
            d="M692.8,401l-3,0.3c-7.3,4.3-15.5,7.7-24.8,10.3l1,1C675.9,409.6,684.9,405.9,692.8,401L692.8,401z"
          />
          <path
            fill="none"
            d="M689.7,401.2l-4.1,0.3c-6.6,3.6-13.9,6.5-21.9,8.7l1.2,1.2C674.1,408.9,682.3,405.6,689.7,401.2z M703.4,399.7
	l-2.8,0.4l-0.3,0.1c-4.4,3-9.1,6.2-14.3,9.2l-1.9,1.1c-1.4,0.8-1.8,1.4-1.1,1.9l6.6,4l2.8,1.6l2.6,1.6l1.2,0.7l2.1-0.8
	c12-5,21-13.2,27.2-24.8l-3,0.8l-0.5,1.1c-3.3,6.1-7.7,11.1-13.2,15.1c-3,2.2-6.3,4-9.9,5.6c-1.4,0.5-2.8,0.4-4.1-0.3l-10.2-5.9
	l0.8-0.4l0.4-0.3h0.1l1.1-0.5l0.1-0.1c5.2-3,10-6.1,14.6-9.2L703.4,399.7L703.4,399.7z"
          />
          <path
            fill="none"
            d="M703.5,399.7L702,401l13.5-2.5h0.1l3.2-0.7c-2.3,5.8-5.6,10.6-9.9,14.4c5.5-4.1,9.9-9.1,13.2-15.1l0.6-1.1
	l-7.2,1.6C711.6,398.3,707.6,399,703.5,399.7L703.5,399.7z M700.5,400.3l0.3-0.1l-4.7,0.6c-5.1,3-10.6,5.9-16.6,8.5
	c-1.7,0.8-1.8,1.8-0.3,2.9l7,4.1l3.4,0.4l-6.6-4c-0.5-0.5-0.3-1.2,1.1-1.9l1.9-1.1C691.4,406.3,696.1,403.3,700.5,400.3L700.5,400.3
	z"
          />
          <path
            fill="none"
            d="M714.2,401.6c0.5-1.1,1.1-2.2,1.2-3.2L702,401c-4.4,3-9.4,6.2-14.6,9.2l-0.1,0.1l-1.1,0.5l9.5,5.5
	c0.3,0.1,0.4,0.3,0.7,0.3l1.4-0.1C704.2,414,709.7,409.1,714.2,401.6L714.2,401.6z M699.9,403.3c0.7-0.5,1.7-0.8,2.8-1l9.2-1.1
	c0.5,0.1,0.7,0.4,0.5,1v0.1c-0.3,0.7-0.7,1.4-1.1,2.1c-3.9,5.4-8.3,9.1-13.5,11l-1.4,0.1l-0.7-0.1l-6.9-4.1L699.9,403.3L699.9,403.3
	z"
          />
          <path
            fill="none"
            d="M702.7,402.3c-1.1,0.1-1.9,0.4-2.8,1l-11,7.8l6.9,4.1l0.7,0.1l1.4-0.1c5.1-2.1,9.6-5.6,13.5-11
	c0.6-0.7,0.8-1.2,1.1-2.1V402c0.1-0.5-0.1-0.8-0.5-1L702.7,402.3L702.7,402.3z M700.3,403.8c0.5-0.4,1.2-0.7,1.9-0.8l8.9-0.1
	l-1.1,1.6c-3.6,4.8-7.7,8.1-12.4,9.9l-1.2,0.1l-0.7-0.1l-5.5-3.3l0.8-0.6l7.2-5.1L700.3,403.8L700.3,403.8z"
          />
          <path
            fill="none"
            d="M705.3,404.5h2.1l-8,8l-0.8,0.4c-1.1,0.5-2.5,0.5-3.9,0l-3.9-2.3l-0.8,0.5l5.5,3.3l0.7,0.1l1.2-0.1
	c4.8-1.8,8.9-5.1,12.4-9.9l1.1-1.6L702,403c-0.7,0.1-1.4,0.4-1.9,0.8l-2.1,1.5c0.8-0.4,1.7-0.7,2.8-0.7L705.3,404.5L705.3,404.5z"
          />
          <path
            fill="none"
            d="M707.5,404.4h-2.1c-2.1,0.1-4.1,0.7-5.8,1.6l-6.2,4.4l3,1.9c0.8,0.3,1.5,0.4,2.3,0.4l0.8-0.4L707.5,404.4
	L707.5,404.4z"
          />
          <path
            fill="none"
            d="M705.3,404.5l-4.3,0.1c-1.1,0-2.1,0.3-2.8,0.7l-7.2,5.1l2.2,0.1l6.2-4.4C701.3,405.2,703.2,404.7,705.3,404.5
	L705.3,404.5z"
          />
          <path
            fill="none"
            d="M695,412.8c1.4,0.6,2.6,0.6,3.9,0c-0.7,0-1.5-0.1-2.3-0.4l-3-1.9l-2.2-0.1L695,412.8L695,412.8z"
          />
          <path
            fill="none"
            d="M715.5,398.5c-0.3,1-0.7,2.1-1.2,3.2c-4.5,7.4-10,12.4-16.4,14.9l-1.4,0.1c-0.3,0-0.5-0.1-0.7-0.3l-9.5-5.5
	h-0.1l-0.4,0.3l10.2,5.9l2.1,0.1c6.7-2.5,12.4-7.4,17.1-15.1c0.7-1.2,1.1-2.3,1.4-3.4c-0.3,1.1-0.7,2.2-1.4,3.4
	c-4.7,7.6-10.3,12.7-17.1,15.1l-2.1-0.1l-10.2-5.9l-0.8,0.4l10.2,5.9c1.4,0.7,2.8,0.7,4.1,0.3c3.6-1.5,6.9-3.4,9.9-5.6
	c4.4-3.7,7.7-8.5,9.9-14.4L715.5,398.5L715.5,398.5z M664,403.6l-2.5,4.7l0.1,0.1c5-1.4,9.6-3,14-5H664V403.6L664,403.6z M678.9,402
	h-1l-0.8,0.8 M675,411c-1.5,0.5-1.7,1.4-0.3,2.6l5.9,4.1l5.4-1.7l-7-4.1c-1.5-1.1-1.4-1.9,0.3-2.9L675,411L675,411z M711.2,427.1
	l3-1.6c6.1-3.4,11.6-7.4,16.4-12c-2.1,1.8-4.3,3.4-6.9,5.1c-8.1,5.2-18.6,9.4-31.5,12.5c-1.8,0.4-3.7,0.8-5.6,1.2l-0.7,0.1l-2.6,3.4
	c9.2-1.8,17.7-4.4,25.6-7.6L711.2,427.1L711.2,427.1z"
          />
          <path
            fill="none"
            d="M702.3,433c2.9-2.2,5.8-4.1,8.9-5.9l-2.2,1.1c-7.8,3.2-16.4,5.8-25.6,7.6v0.7l-8.7,4.8l7.8-1.2
	C691.8,438.4,698.3,436,702.3,433L702.3,433z M692.4,418.3l-1.8,0.5l1,0.5l3.4,0.6"
          />
          <path
            fill="none"
            d="M690.6,418.8l1.8-0.5l-3.6-0.5l-5.5,1.8l1.8,1.1"
          />
          <path
            fill="none"
            d="M691.5,419.4l-1-0.5l-5.5,1.8l1,0.7 M692.4,418.3l-2.8-1.6l-1.8,0.5l1,0.5"
          />
          <path
            fill="none"
            d="M689.6,416.6l-3.4-0.5l-5.5,1.8l1.7,1.1l5.5-1.8 M694.6,421.2l1.7-0.5l-1.2-0.7l-1.8,0.5"
          />
          <path
            fill="none"
            d="M696.2,420.6l-1.8,0.5l-5.2,1.5l5.2-1.5l-1.2-0.7l-5.5,1.8l2.8,1.6c3-0.7,5.9-1.6,8.5-2.8l-0.5-1.4L696.2,420.6
	L696.2,420.6z"
          />
          <path
            fill="none"
            d="M695,419.9l-3.4-0.5l-5.5,1.9l1.7,1l5.5-1.8 M698.3,419.8l0.5,1.4c12.8-5.1,22.4-14,28.8-26.7l-2.1,0.5
	C719.5,406.6,710.4,414.8,698.3,419.8L698.3,419.8z M682.3,419l1,0.5l5.5-1.8l-1-0.5 M695.7,428.2l3.3-7c-2.8,1.1-5.5,2.1-8.5,2.8
	c-3,0.7-6.1,1.2-9.4,1.8L695.7,428.2L695.7,428.2z M686.7,432.3c1.9-0.4,3.9-0.8,5.6-1.2c12.9-3.2,23.4-7.3,31.5-12.5
	c-8.3,5-17.7,8.3-28.2,9.8L686.7,432.3L686.7,432.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M698.3,405.4l-7.2,5.1 M698.7,412.9c-0.7,0-1.5-0.1-2.3-0.4l-3-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M691.1,410.4l3.9,2.3c1.4,0.6,2.6,0.6,3.9,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M716.2,398.8c-0.3,1.1-0.7,2.2-1.4,3.4c-4.7,7.6-10.3,12.7-17.1,15.1
	l-2.1-0.1l-10.2-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M715.6,398.5c-0.3,1-0.8,2.1-1.4,3.2c-4.5,7.4-10,12.4-16.4,14.9l-1.4,0.1
	c-0.3,0-0.5-0.1-0.7-0.3l-9.5-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M693.3,410.6l6.2-4.4c1.8-1,3.7-1.5,5.8-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M698.3,405.4l2.1-1.5c0.5-0.4,1.2-0.7,1.9-0.8l8.9-0.1l-1.1,1.6
	c-3.6,4.8-7.7,8.1-12.4,9.9l-1.2,0.1l-0.7-0.1l-5.5-3.3l0.8-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M705.3,404.5l-4.3,0.1c-1.1,0-2.1,0.3-2.8,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M702.7,402.3l9.2-1.1c0.5,0.1,0.7,0.4,0.5,1v0.1c-0.3,0.7-0.7,1.4-1.1,2.1
	c-3.9,5.4-8.3,9.1-13.5,11l-1.4,0.1l-0.7-0.1l-6.9-4.1l11-7.8C700.8,402.7,701.6,402.3,702.7,402.3L702.7,402.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M698.7,412.9l0.8-0.5l8-8l-2.2,0.1 M691.1,410.4l2.2,0.1 M691.1,187.3
	l7.2,5.1 M693.3,187.2l6.2,4.4c1.8,1,3.7,1.5,5.8,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M705.3,193.2H701c-1.1,0-2.1-0.3-2.8-0.8 M705.3,193.2h2.1l-8-8
	c-0.3-0.3-0.5-0.4-0.8-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M693.3,187.2l3-1.9c0.8-0.3,1.5-0.4,2.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M698.7,184.8c-1.1-0.6-2.5-0.6-3.9,0l-3.9,2.3 M691.1,187.3l2.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M716.2,199c-0.3-1.1-0.7-2.2-1.4-3.3c-4.7-7.6-10.3-12.7-17.1-15.1
	l-2.1,0.1l-10.2,5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M696.6,182.4c-0.3,0-0.5,0.1-0.7,0.3l-6.9,4.1l11,7.7c0.7,0.6,1.7,1,2.8,1
	l9.2,1.1c0.5-0.1,0.7-0.4,0.5-1v-0.1c-0.3-0.7-0.7-1.4-1.1-1.9c-3.9-5.4-8.3-9.1-13.5-11L696.6,182.4L696.6,182.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M686.2,186.9l9.5-5.5c0.3-0.1,0.4-0.3,0.7-0.3l1.4,0.1
	c6.5,2.5,12,7.4,16.4,14.9c0.6,1.1,1.1,2.2,1.2,3.2h0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M698.3,192.4l2.1,1.5c0.5,0.4,1.2,0.7,1.9,0.8l8.9,0.1
	c-0.1-0.4-0.4-0.7-0.7-1.1l-0.4-0.6c-3.6-4.8-7.7-8.1-12.4-9.9l-1.2-0.1l-0.7,0.1l-5.5,3.3l0.8,0.6 M683.4,162v-0.7l-8.7-4.8
	 M674.8,156.5l7.8,1.2c9.1,1.6,15.7,4.1,19.7,7.2c2.9,2.2,5.8,4.1,8.9,5.9 M683.4,162c-7-1.4-14.3-2.5-22.1-3
	c-16.8-1.2-33-2.1-48.8-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M711.2,170.7c-0.7-0.4-1.5-0.8-2.2-1.1c-7.8-3.2-16.4-5.8-25.6-7.6
	 M711.2,170.7l3,1.6c6.1,3.4,11.6,7.4,16.4,12 M699,176.6l-3.3-7 M694.6,176.6l-1.4,0.7 M694.6,176.6l-5.4-1.6 M696.2,177.1
	l-1.7-0.6 M690.4,173.8c3,0.7,5.9,1.6,8.5,2.8 M687.7,175.3l-1.7,1.1 M685.1,177.1l1-0.7 M680.9,172h-0.8 M686.7,165.4H686
	 M688.8,180l-1,0.6 M682.3,178.8c0.3-0.3,0.7-0.4,1-0.7 M680.7,179.9l1.7-1.1 M708.9,185.5c-3-2.2-6.3-4-9.9-5.6
	c-1.4-0.4-2.8-0.4-4.1,0.1l-10.2,5.9l0.8,0.4l0.4,0.3l0.1,0.1l1.1,0.6l0.1,0.1c5.2,3,10,6.1,14.6,9.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M700.6,197.6l-0.3-0.1c-4.4-3-9.1-6.2-14.3-9.2l-1.9-1.1
	c-1.4-0.7-1.8-1.4-1.1-1.9l6.6-4 M686.2,181.7l-5.5-1.8 M683.3,178.1l5.5,1.9 M687.8,180.6l-5.5-1.8 M686.2,181.7l-7,4.1
	c-1.5,1.1-1.4,1.9,0.3,2.8 M689.6,181.1l2.8-1.6 M691.5,178.4l-1,0.6 M690.6,178.9l1.8,0.6 M692.4,179.5l2.6-1.5 M695,178l-3.4,0.4
	 M693.2,177.3l-5.5-1.9 M687.7,175.3l2.8-1.5 M699,176.6l-0.7,1.4 M698.3,178l-2.1-0.8 M723.7,179.2c-8.1-5.2-18.6-9.4-31.5-12.5
	c-1.8-0.4-3.7-0.8-5.6-1.2 M695.7,169.6l-14.7,2.5 M686.7,165.4l8.9,4.1 M690.6,178.9l-5.5-1.8 M686,176.4l5.5,1.9 M689.6,181.1
	l-3.4,0.6 M687.8,180.6l1.8,0.6 M695,178l1.2-0.8 M692.4,179.5l-3.6,0.6 M683.3,178.1l1.8-1 M693.2,177.3l1.8,0.7 M690.4,173.8
	c-3-0.7-6.1-1.2-9.4-1.8 M692.8,196.8l3.2,0.3 M689.7,196.4l0.1,0.1l2.9,0.3 M683.3,196l-1.1-0.6c-5.9-2.9-12.4-5.4-19.3-7.3
	l-1.2,1.2 M683.3,196l2.3,0.1 M687.1,216.2c-1.1-5.1-2.5-9.4-4-12.8v-0.1l-0.5-1.1 M682.6,202.2c-1.4-2.8-2.9-4.9-4.5-6.5
	 M726.8,220.2c-3.9-9.6-15.8-15.4-35.6-17.3l-8.5-0.7 M685.6,196.1l4.1,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M725.1,220.2c-4.1-8.5-15.3-13.9-33.3-16.2l-8.4-0.7 M708.9,185.5
	c4.4,3.7,7.7,8.5,9.9,14.4l-3.2-0.7 M715.5,199.3L714,199c-3.6-0.7-7.7-1.4-12-2.1 M702,196.8l1.5,1.2 M703.5,198h-0.1l-2.8-0.4
	 M722.6,201.9c-2.3-0.6-4.7-1.2-7.2-1.6c-3.9-0.8-7.8-1.5-12-2.1 M695.9,197.1c-5.1-3.2-10.6-5.9-16.6-8.5 M695.9,197.1l4.7,0.6
	 M679.4,188.6l-4.3-1.8c-1.5-0.6-1.7-1.4-0.3-2.6l5.9-4.1 M685.6,196.1c-6.6-3.6-13.9-6.5-21.9-8.7l1.2-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M680.1,172c-6.9,3.6-12.9,8.4-18,14.6c-0.5,0.8-0.8,1.8-0.4,2.9
	 M661.7,189.4l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664.8,186.2l1-1c10,2.9,19.1,6.7,27,11.6 M649.4,196.7
	c1.9-0.8,1.9-2.2,0.1-3.9c-4.7-4.5-9.9-8.4-15.8-11.4c-2.3-1.2-5-2.1-7.8-2.6 M602,199.8l6.6-0.1c13.2-0.4,26.1-1.2,38.5-2.3
	c0.8-0.1,1.5-0.4,2.2-0.6l0.3-0.1 M648.3,194.5l-2.8,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.4,181.5c1.1,0.4,2.1,0.8,2.9,1.2c5.9,3.2,11.1,6.9,15.8,11.4l0.1,0.1
	 M649.4,196.7c0.1-0.7-0.3-1.4-1.1-2.3 M645.6,194.7L642,195 M677.9,195.7h1 M677.1,194.9l-0.8-0.7h-0.5 M678.9,195.7l-1.8-0.8
	 M675.7,194.2H664 M677.9,195.7l-0.8-0.8 M664,194.2c-7.4,4.4-12.4,9.5-15,15.3c-0.5,1.6-1.1,3.4-1.2,5.2 M661.5,189.5l2.5,4.7
	 M675.7,194.2c-4.4-1.9-9.1-3.6-14-4.8 M647.8,214.8h5.9c8.4-0.1,13.1,4.5,14,14c1.2,20.2,2.1,40.7,2.2,61.2v17.6
	c-0.1,20.5-1,41-2.2,61.4c-1,9.4-5.6,14-14,14h-5.9 M647.4,219.1v-0.3c0-1.4,0.1-2.6,0.3-4 M647.7,219.1h-0.3 M687.1,381.6l0.3-1
	c1.8-8.5,2.8-19.4,3-32.3v-98.8c-0.3-12.9-1.4-23.7-3-32.2l-0.3-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M687.1,381.6c6.3,2.1,12.7,1.9,18.7-0.1c12.5-5.9,19.7-25.6,21.2-59
	l0.3-32.3l-0.3-14.6c-1.5-33.4-8.7-53.1-21.2-59c-6.1-2.1-12.2-2.2-18.7-0.1 M664.8,186.2c9.2,2.6,17.5,6.1,24.8,10.3 M678.9,195.7
	l4.4,0.3 M730.6,184.3c-2.1-1.8-4.3-3.4-6.9-5.1 M730.6,209.6c-0.8-2.2-1.8-4.4-2.9-6.3 M722.6,201.9l-0.5-1.2
	c-3.3-6.1-7.7-11.1-13.2-15.1 M725.6,202.7l-3-0.8 M727.7,203.3l-2.1-0.6 M730.6,184.3c6.7,6.3,12.4,13.8,16.6,22.3l0.5,1.1
	 M735.7,226.5l-8.9-6.3 M726.8,220.2h-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M725.1,377.6l0.4-1.1c4.5-11.8,7.2-26.1,7.7-43.3
	c0.3-8.5,0.4-17.1,0.4-25.5v-17.6c-0.1-8.5-0.3-16.9-0.4-25.5c-0.5-17.1-3.2-31.5-7.7-43.3l-0.4-1.1 M737.9,357.5
	c0.4-3.7,0.7-7.6,0.8-11.6c0.5-12.8,0.8-25.5,1-38.1v-17.7c-0.1-12.7-0.4-25.3-1-38.1c-0.1-4-0.4-7.8-0.8-11.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M737.9,240.3l1.2,3.4c1.4,3.4,2.1,7,2.2,10.7v0.1
	c0.4,11.8,0.7,23.7,0.8,35.4v17.7c-0.1,11.7-0.4,23.5-0.8,35.4v0.3c-0.1,3.7-1,7.3-2.2,10.7l-1.2,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M730.6,388.2l8.3-7.7c6.1-4.7,10-13.5,12.2-26.3c1.7-15.5,2.6-31.1,3-46.4
	v-17.7c-0.4-15.3-1.4-30.8-3-46.4c-2.2-12.8-6.2-21.6-12.2-26.3l-8.3-7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M730.6,388.2c4.8-5.8,8.3-12.9,9.9-21.5c1.1-6.5,1.8-13.3,2.2-20.8
	c0.6-12.8,0.8-25.5,1-38.1v-17.7c-0.1-12.7-0.4-25.3-1-38.1c-0.3-7.4-1.1-14.3-2.2-20.8c-1.8-8.5-5.1-15.7-9.9-21.5 M737.9,240.3
	c-0.5-4.8-1.2-9.5-2.2-13.9 M747.9,207.7c3.4,7,6.1,14.7,7.8,23.1c3.7,19.5,5.9,39.3,6.5,59.2v17.7c-0.5,19.8-2.8,39.6-6.5,59.2
	c-1.8,8.4-4.4,16.2-7.8,23.2 M730.6,209.6c2.1,5.2,3.7,10.7,5.1,16.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M747.9,390.1c4-4.8,7.3-12.7,10-23.2c3.7-19.5,5.9-39.3,6.5-59.2l0.1-12.7
	l-0.1-5.1c-0.5-19.8-2.8-39.6-6.5-59.2c-2.8-10.6-6.1-18.3-10-23.1 M686,165.4l-2.6-3.4 M695.7,169.6c10.5,1.5,19.8,4.7,28.2,9.6
	 M727.7,203.3c-6.3-12.7-16-21.6-28.8-26.7 M698.3,178c12,5,21,13.2,27.2,24.8 M686,165.4c-4.4-0.8-8.9-1.2-13.6-1.5
	c-17.5-0.6-34.9-1.5-52.5-2.9 M79.5,246.6l-0.1,1.9 M89.3,216.1c-4.7,6.5-7.8,16.1-9.6,28.9l-0.1,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M148.2,166.4c-0.8-0.1-2.1,0.1-3.7,1c-23.9,13.3-43.6,30.4-58.9,51.2
	L81,225c-1,1.6-1.5,3.4-1.5,5.6v16"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M146.5,167.2l-0.7,0.4c-20.5,11.4-38,25.9-52.4,43.2 M148.2,166.4
	l-1.6,0.8 M146.5,167.2c-1.5,1.5-3.3,2.9-5.1,4.3 M156.1,178.4l1.4-0.4 M96.7,229c0.7-5.4,2.6-9.5,5.9-12.5c4.7-4.5,8.3-8.1,11-10.7
	c14-12,27.5-20.2,40.3-24.9l0.1-0.1h0.1c1.2-0.6,1.8-1.2,1.8-2.2 M152.6,185.8v-1.1c2.2-1.9,5.1-3.2,8.7-3.6 M152.6,193.5
	c0.4-0.3,0.8-0.6,1.1-0.8c2.2-1.2,4.3-2.8,6.1-4.5l1.4-1.5 M150.2,194.9c0.8-0.4,1.5-1,2.3-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.3,181.3l-9.9,2.6c-1.9,0.6-3.6,1.5-5.1,2.6l-3.4,3.3
	c-1.8,1.8-3.9,3.3-6.1,4.5l-1.1,0.7c-11.1,6.9-22.6,13.6-34.3,20.5c-6.5,3.9-13.2,7.7-20.2,11.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M150.2,194.9v-3.4c0-2.3,0.8-4.3,2.3-5.6 M141.4,171.6l-5.1,3.4
	c-15.1,9.5-29.4,21.5-42.9,35.9 M152.6,193.5v-7.7 M164.7,174.2c-17.1,2.1-35.6,11.1-56,27.4c-4.4,3.9-8.5,7.6-12.1,11.4
	c-6.3,7.4-9.5,14.2-9.8,20.4c0.1,3.2,2.1,4.7,5.5,4.3l2.5-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M105.2,215.5l-1.7,1.6c-2.3,2.2-4,4.8-5.1,8.1 M115.6,215.5h-10.3
	 M89.4,216.1h-0.1 M98.6,227.9l-0.8,0.4 M97.9,228.3l-1.2,0.7 M98.1,227.1l-0.1,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M93.8,230.5c-0.6,0.1-0.8,0-1-0.6v-0.6c0-4.1,1.5-8.3,4.8-12.4
	c6.1-5.9,10.6-10.5,13.9-13.5c21.7-18.4,41.8-28.3,60.5-29.7 M93.8,230.5l0.7-0.3c0.7-0.4,1.5-0.8,2.2-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.1,178.4c-13.8,4.4-28.3,13.1-43.6,26c-3.3,3-7.8,7.6-13.9,13.5
	c-3,3.6-4.5,7.3-4.8,11.1v1.5 M94.9,237.1l3.7-9.2 M98.6,225.3l-0.6,1.8 M80.9,253.1l0.6-8.1c1.8-12.8,4.5-22.4,7.8-28.9
	 M98.6,225.3l16.9-9.8 M178.3,181.3c-2.3,0.8-4.4,1.7-6.2,2.6L152.6,196c-10.6,6.5-21.5,13.1-32.6,19.5l-21.3,12.4 M93.4,211
	c-1.4,1.6-2.8,3.3-4,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M162.6,181.3c-1.2-0.7-3-0.8-5.2-0.6c-13.5,4.5-27.8,13.1-42.6,25.7
	l-9.5,9.1 M115.6,215.5c11.8-6.9,23.5-13.8,34.8-20.6 M255.9,182.6c-5.2,0-10.5,0.3-15.5,1c-38.4,5.5-70.3,13.3-95.6,23.5
	c-15.4,6.5-30.5,14.7-45.4,25l119.8-24.2L94.9,237.1 M89.4,216.1c15.8-18,33.7-31.6,53.5-40.9 M185.9,156.6l-17.2,1.5
	c-5.4,0.8-10.2,2.2-14.2,4.3c-2.1,1.4-4.1,2.8-6.3,4.1 M80.9,253.1l-1.5-4.5 M279.3,156.4c-8.5-1.1-17.3-1.8-26.4-1.9h-0.3
	c-24.5-0.1-46.6,0.6-66.7,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M279.3,156.4c-12.2-0.1-25.3,0-38.9,0.7H240c-22.1,2.3-44.2,4.3-66.2,5.8
	 M283.4,157.5l-20.6,0.7c-3.6,0.3-6.5,1-8.7,2.1c-7.6,3.4-16.2,5.8-26,7h-0.3c-20.4,2.8-40.9,4.7-61.4,5.6 M286.3,157.3l-3-0.4
	 M283.2,156.9l0.1,0.6 M301,157.9l-2.8,3c-0.5,0.8-0.7,1.5-0.4,2.5c1,1.6,2.6,2.6,5.1,2.9c2.8,0.3,5.4-0.4,7.6-1.9 M283.4,157.5
	l2.9-0.1 M301.1,156.8l-0.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M301,157.9l-0.3,1.2c-0.1,3,0.8,5,2.8,5.5c1.7,0.4,3.2,0.3,4.8-0.3h2.2
	 M318.3,164.3c0-1.1,0.1-2.3,0.5-3.7l1.2-4.5c2.6-9.1,6.5-17.7,11.3-25.9 M310.5,164.3h7.8 M301.1,156.8l-14.9,0.6 M331.4,130.2
	c-8.8,0.6-15.5,3-19.9,7.4c-5.2,5.1-8.7,11.4-10.3,19.3 M283.2,156.9l-4-0.6 M141.4,171.6c8.3-4.7,16.9-7.6,26-8.4l6.5-0.4
	 M173.9,162.8l12-6.2 M162.2,185.9c1.8-1.5,3.7-2.6,5.8-3.3l17.1-4.5l-4.1-1.2l-17.7,4.5 M172,173.8h1.1c0.4,0,0.8,0,1.2-0.1h2.1
	c12-0.1,23,0.6,33,2.1 M173.9,162.8c-1.8,1.2-3.7,3.9-5.5,7.8c-0.3,0.7-1,1.5-1.8,2.2 M166.6,172.9l-1.9,1.4 M161.2,181.3v5.5
	 M161.2,186.8l1-0.8 M162.2,185.9c1.6-1.5,1.9-3,1-4.5 M161.2,181.3h1.4 M163,181.5l-0.4-0.3 M164.7,174.2l7.3-0.4 M157.5,178
	l6.2,1.6l19.3-4.7h-7.6C169.6,175.1,163.7,176.2,157.5,178L157.5,178z M209.4,175.8c-8.5,1-16.2,2.1-23.2,3.3
	c-2.9,0.6-5.5,1.2-7.8,2.1 M187.8,257.3v-1.5c0-0.6,0.3-0.8,0.8-0.8h1.6c0.6,0,0.8,0.3,0.8,0.8v1.5c-0.1,0.3-0.3,0.4-0.7,0.4h-1.9
	C188,257.9,187.9,257.7,187.8,257.3L187.8,257.3z M238.8,403.4c-9.6-8.9-16.5-25.3-20.6-49c-1.6-15.3-2.6-30.8-2.8-46.6v-17.7
	c0.1-15.8,1.1-31.4,2.8-46.6c4.1-23.7,11-40,20.6-49 M310.1,170.3l6.1,10c-17.7-2.6-35.5-5.4-53.1-8.3l-1.2-0.1 M314.3,170.3h-4.3
	 M319.3,170.1l-5,0.1 M289.6,170.7l-1-1.1 M289.6,170.7l20.5-0.4 M277.3,184.4l-7.8-1.1l-13.6-0.7 M261.8,171.8
	c2.1-0.4,4.8-0.7,8-0.7l19.7-0.4 M255.9,185.5v-2.9 M258.8,184.8l-2.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M252.4,172.3c1.5-0.4,3.2-0.8,5-1.2l8.1-0.8c7.7-0.3,15.4-0.4,23.1-0.6
	 M255.9,182.6c1.4-3.2,3.2-6.2,5.5-8.8 M277.3,184.4c-6.9-0.6-13.1-0.4-18.6,0.6 M255.9,185.5c-5.8,1.2-10.6,3.6-14.6,6.7
	c-0.8,0.7-1.6,1.4-2.5,2.1 M252.4,172.3l9.4-0.6 M261.4,173.8l-8.9-1.5 M286.5,193l72.6,9.8c14,1.2,20.2,8.9,18.7,23.2
	c-1.5,21.5-2.3,42.8-2.5,64.1c0,2.5-0.1,4.8-0.1,7.3l0.1,10.5c0.1,21.3,1,42.8,2.5,64.1c1.5,14.3-4.7,22-18.7,23.2l-72.6,9.8
	c-21.6,3.6-33.4-3.6-35.4-21.3c-2.9-25.3-4.4-50.6-4.5-75.8v-17.7c0.1-25.2,1.6-50.5,4.5-75.8C253.1,196.5,264.9,189.4,286.5,193
	L286.5,193z M288.6,169.6c-1.1-3.9-2.8-8-5.2-12.1 M209.4,175.8c12.7-1.5,27-2.8,43.1-3.6 M410.2,156.6c1,0.4,1.9,0.7,3.2,1
	c1.5,0.4,3.3,0.7,5.2,1 M408.6,155.7c0.5,0.3,1.1,0.6,1.8,0.8 M419.4,157.3c-0.3,0.3-0.5,0.7-0.7,1.2 M412.6,155.8l-4-0.1
	 M416.1,154.7c-1.2,0.3-2.5,0.7-3.6,1.1 M410.2,156.6l2.3-0.8 M437.2,157.6c-0.3-0.6-0.7-1.1-1.2-1.4c-0.7-0.4-1.5-0.7-2.5-0.8
	c-4-0.3-7.8-0.1-11.6,0.3l-0.8,0.1c-0.8,0.3-1.4,0.7-1.9,1.4 M437.2,157.6h-7.3l-10.5-0.3 M439.5,154l-5.1-0.7
	c-6.6-0.6-12.7-0.1-18.3,1.4 M437.7,158.8l1.8-0.1 M418.7,158.6l11.1,0.4c2.9,0,5.5,0,7.8-0.1 M437.7,158.8l-0.5-1.2 M439.5,154v4.7
	 M449.7,157.1l1.2-0.6 M439.5,158.7l7-0.8l3.2-0.8 M448.5,156.5h2.5 M449.7,157.1c-0.4-0.3-0.8-0.4-1.2-0.6 M448.5,156.5
	c-1.5-0.7-3.2-1.2-5-1.6 M466.8,157.1h1.2 M466.8,157.1l-1.4-1.9 M465.4,155.1c-7.3-0.1-14.6-0.3-22-0.3 M450.9,156.5l15.8,0.6
	 M443.5,154.9c-1.2-0.3-2.6-0.7-4-0.8 M333.7,131.3l-2.3-1.1 M326.2,155.7l7-18.4l0.5-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.2,133.9l0.1-0.1l0.1-0.1l1.2-2.3 M321.9,161.2l1.6-1.6l8.4-22.3
	l0.3-3.3 M327.3,155.8l-1.1-0.1 M326.2,155.8h1.1 M326.2,155.7v0.1 M321.5,163l0.1-0.1h0.1c1.1-0.8,2.1-1.9,2.9-3l1.5-3.9
	 M318.3,164.3c1-0.1,1.6-0.4,2.5-0.8l0.7-0.6 M321.5,163l0.4-1.8 M321.9,161.2l1.4-5.2c2.2-7.6,5.2-15,8.9-22 M416.1,154.7
	c-23.4-0.1-46.9,0-70.7,0.4l-18.2,0.7 M384.9,201.9c-0.5-1.8-1.9-2.9-3.9-3.2l-103.6-14.3 M385.2,206.2v0.3
	c-2.5,27.9-3.7,55.7-4,83.5v17.7c0.3,27.8,1.7,55.6,4,83.5v0.3 M384.9,201.9c0.4,1.1,0.5,2.6,0.3,4.3 M321.5,183.3
	c23.2,3.4,46.8,6.6,70.3,9.4c29.7,3,59.6,5.1,89.3,6.5 M319.3,170.1l5.6,11.4c21.6,3,43.2,5.9,65.1,8.5c26.4,2.6,52.8,4.7,79.4,5.9
	 M470.9,169l0.5,1.6 M457.1,170.4l12.2,25.6 M471.4,170.7l-14.3-0.3 M468,157.1c1.4,4.4,2.3,8.4,2.9,12 M327.3,155.8
	c27.2-0.4,54.2-0.4,81.2,0 M574.1,156.9l-1.8,0.1c-7.3,0.4-14.6,0.6-21.9,0.3H550c-27.8-1-56-1.6-84.5-2.1 M603.2,162
	c-1.5-0.4-3.2-0.7-5-1c-8.8-1.2-16.8-0.8-23.9,1.6h-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M582.4,165c-2.1-0.4-3.7-0.8-5.2-1.2c-1.2-0.4-2.3-0.8-3.2-1.2
	 M583.8,163.2c-0.3,0.1-0.5,0.4-0.7,0.6c-0.3,0.3-0.5,0.7-0.7,1.2 M600.3,164.5c-0.7-0.8-1.7-1.4-2.9-1.5l-11.6-0.4
	c-0.8,0.1-1.5,0.3-2.1,0.7 M574.1,156.9l12.2-1.1c33-3.3,62.6-3,88.6,0.6 M619.9,161l-7.4-4.5 M612.4,156.5
	c-13.1-0.3-25.9-0.1-38.4,0.4 M619.9,161l-45.8-4.1 M600.3,164.5l-16.5-1.2 M480.5,196.5l10.6,0.4 M481.2,199.1l-0.7-2.6 M485,171.1
	l6.1,25.9 M618.9,179.5l3.9,0.4l1.8,0.3 M625.9,178.8l-3.2-0.6c-48.4-4.7-99.2-7.7-152-9.2 M624.5,180.2l1.4-1.4 M629.4,183.6v-2.1
	 M645.6,194.7c-4.3-4-9.2-7.4-14.6-10.2l-1.7-0.8 M624.5,180.2c1.8,0.4,3.3,0.8,4.8,1.4 M613.8,196.9c4.3-4.1,8.7-8,13.5-11.7
	l2.1-1.6 M625.9,178.8c1.9-3.9,3.3-7.7,4-11.7 M613.7,165.6h-0.5 M613.4,165.7l-3.3,0.6l-7,0.4 M613.1,165.6l0.3,0.1 M613.7,165.6
	l-0.3,0.1 M601.3,166.7c-0.1-0.6-0.3-1-0.4-1.2l-0.6-0.8 M603.1,166.7h-1.8 M606.1,178.4l2.3,9.6 M618.9,179.5l-12.8-1.1 M608.4,188
	c3.3-2.9,6.9-5.8,10.5-8.4 M618.5,196.7l-4.7,0.3 M613.8,196.9l-2.5,0.1c5.6-5.5,11.7-10.6,18-15.4 M602,199.8
	c6.9-7,14.4-13.6,22.6-19.7 M629.9,167.1l-16.2-1.5 M642,195c-3.6-3.2-7.6-5.9-11.8-8.3c-4.1,3.2-8,6.5-11.7,9.9 M491.1,196.9
	c33.2,1.2,66.4,1.4,99.7,0.4l7.4-0.1c3.2-3.3,6.6-6.3,10.2-9.4 M481.2,199.1c36.7,1.5,73.3,1.9,110.2,1L602,200 M601.3,166.7
	l-7.8-0.4l-11-1.2 M629.9,167.1l-10-6.1 M613.1,165.6c-2.9-1.5-6.2-2.8-9.9-3.6 M603.2,162l-0.1,4.7 M485,171.1
	c41.7,1.4,82.1,3.7,121.1,7.2 M647.8,214.8h-55c-73.2-0.3-142.5-4.5-208-12.9 M573.9,162.6c-35.1-2.5-70.4-4.3-106.1-5.5
	 M471.4,170.7l13.6,0.4 M480.5,196.5l-9.1-25.9 M469.4,196l11.1,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M385.2,206.2c65.3,8.3,134.5,12.5,207.4,12.8h54.8 M261.4,173.8
	c19.9,3.3,39.9,6.5,60.1,9.5 M470.9,169c-60.8-1.2-121.6-1.1-182.3,0.6 M457.1,170.4c-45.9-0.8-91.9-0.8-137.8-0.3 M258.8,184.8
	l126.1,17.1 M321.5,183.3l-7.2-13.1 M636.1,300.9c-0.3,1.9-1.8,2.9-4.3,3H624c-3.7-0.1-5.8-1.5-6.3-4c-0.1-0.3-0.1-0.5-0.1-0.8V299
	 M617.4,299v0.1 M617.4,299.2V299 M617.4,299v-0.4c0-0.3,0-0.5,0.1-0.8c0.5-2.5,2.6-3.9,6.3-4h7.8c2.6,0.1,4,1.2,4.3,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.9,296.4c-0.7,0-1.2,0.3-1.8,0.7s-0.7,1.1-0.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M630.5,298.9c0-0.7,0.3-1.2,0.7-1.6l0.5-0.4c0.3-0.3,0.8-0.4,1.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M636.1,296.8l-3-0.4h-0.1 M630.5,298.9c0,0.7,0.3,1.2,0.7,1.8
	c0.4,0.4,1.1,0.7,1.8,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.9,301.4h0.1l3-0.4 M632.9,301.4c-0.5,0-1-0.1-1.2-0.4l-0.5-0.4
	c-0.4-0.4-0.7-1-0.7-1.6 M480.5,401.2l0.7-2.6 M491.1,400.8l-10.6,0.4 M465.4,442.6c28.5-0.4,56.7-1.1,84.5-2.1h0.4
	c7.3-0.3,14.6-0.3,21.9,0.1l1.8,0.1 M491.1,400.8l-6.1,25.7 M602,397.8l6.6,0.1c13.2,0.4,26.1,1.2,38.5,2.3l2.2,0.5l0.3,0.1
	 M613.8,400.8l-2.5-0.1c5.6,5.5,11.7,10.6,18,15.4 M618.5,401.1l-4.7-0.3 M608.4,409.8c-3.6-3-6.9-6.2-10.2-9.5h-7.4
	c-33.3-1-66.6-0.8-99.7,0.4 M582.4,432.7c3.2-0.5,6.9-0.8,11.1-1.2l7.8-0.4 M573.9,435.2c0.8-0.4,1.9-0.8,3.2-1.2
	c1.5-0.4,3.3-0.8,5.2-1.2 M603.2,435.8l-5,1.1c-8.8,1.2-16.8,0.7-23.9-1.6h-0.4 M583.8,434.5l-0.7-0.5c-0.3-0.3-0.5-0.7-0.7-1.2
	 M583.8,434.5c0.5,0.4,1.2,0.7,2.1,0.8l11.6-0.5c1.2-0.1,2.2-0.7,2.9-1.5 M574.1,440.8l12.2,1.1c33,3.3,62.6,3,88.6-0.5
	 M629.4,416.2c1.1-0.3,2.1-0.7,2.9-1.2c5.9-3.2,11.1-6.9,15.8-11.4l0.1-0.1 M629.4,414.2l1.7-0.8c5.4-2.9,10.2-6.2,14.6-10.3
	 M629.4,416.2v-2.1 M470.9,428.7c52.8-1.5,103.4-4.5,152-9.2l3.2-0.5 M624.5,417.6l-1.8,0.3l-3.9,0.4 M625.9,419l-1.4-1.4
	 M625.9,419c1.9,3.9,3.3,7.7,4,11.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M649.4,401.1c1.9,0.8,1.9,2.2,0.1,3.9c-4.7,4.5-9.9,8.4-15.8,11.4
	c-2.3,1.1-5,2.1-7.8,2.6 M613.8,400.8c4.3,4.1,8.7,8,13.5,11.7l2.1,1.6 M624.5,417.6c1.8-0.4,3.3-0.8,4.8-1.4 M608.4,409.8l-2.3,9.6
	 M601.3,431.1c-0.1,0.5-0.3,1-0.4,1.4l-0.6,0.8 M601.3,431.1h1.8 M613.1,432.2h0.5 M613.4,432c-1-0.3-2.1-0.4-3.3-0.5l-7-0.4
	 M613.7,432.2l-0.3-0.1 M613.4,432l-0.3,0.1 M618.9,418.3c-3.6-2.8-7-5.5-10.5-8.4 M618.9,418.3l-12.8,1.1 M603.1,431.1l0.1,4.7
	 M629.9,430.7l-16.2,1.5 M613.1,432.2c-2.9,1.5-6.2,2.6-9.9,3.6 M619.9,436.7l-7.4,4.5 M629.9,430.7l-10,6.1 M602,397.8
	c6.9,7,14.4,13.6,22.6,19.7 M481.2,398.6c36.7-1.5,73.3-1.9,110.2-1c3.6,0,7.2,0,10.6,0.1 M600.3,433.3l-16.5,1.2 M574.1,440.8
	l45.8-4.1 M612.4,441.3c-13.1,0.3-25.9,0.1-38.4-0.4 M642,402.7c-3.6,3.2-7.6,5.9-11.8,8.3c-4.1-3.2-8-6.5-11.7-9.9 M606.1,419.4
	c-38.9,3.4-79.4,5.9-121.1,7.2 M385.2,391.6c0.1,1.8,0,3.2-0.3,4.4 M277.3,413.3L380.9,399c2.1-0.3,3.3-1.4,3.9-3.2 M326.2,441.9
	l-1.5-4c-1-1.1-1.9-2.1-2.9-2.9h-0.1l-0.1-0.3 M321.5,434.8l0.4,1.8 M318.3,433.4c1,0.1,1.6,0.4,2.5,0.8c0.3,0.1,0.4,0.3,0.7,0.4
	 M321.9,436.6l1.6,1.6l8.4,22.3l0.3,3.3 M326.2,442.1l1.1-0.1 M327.3,441.9h-1.1 M326.2,442.1v-0.1 M321.9,436.6l1.4,5.2
	c2.2,7.6,5.2,15,8.9,22 M327.3,441.9l18.2,0.7c23.8,0.3,47.3,0.4,70.7,0.4 M321.5,414.4c23.2-3.4,46.8-6.5,70.3-9.4
	c29.7-3,59.6-5.1,89.3-6.5 M469.4,401.8c-26.5,1.2-53,3.3-79.4,5.9c-21.9,2.6-43.5,5.5-65.1,8.5l-5.6,11.4 M410.2,441.1
	c-0.7,0.3-1.2,0.5-1.8,1 M418.7,439.3c-2.1,0.3-3.9,0.5-5.2,0.8c-1.2,0.3-2.3,0.5-3.2,1 M412.6,441.9l3.6,1.1 M408.6,442.1l4-0.1
	 M419.4,440.6c-0.3-0.3-0.5-0.8-0.7-1.2 M412.6,441.9l-2.3-0.8 M416.1,443c5.6,1.4,11.7,1.9,18.3,1.4l5.1-0.7 M437.7,438.9
	c-2.3-0.1-5-0.1-7.8-0.1l-11.1,0.4 M439.5,439.1l-1.8-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M437.2,440.2c-2.2-0.1-4.7-0.1-7.4-0.1l-10.5,0.4 M437.7,438.9l-0.5,1.2
	 M439.5,443.7v-4.7 M419.4,440.6c0.4,0.5,1.1,1.1,1.9,1.4l0.8,0.1c3.7,0.4,7.6,0.5,11.6,0.3c1,0,1.8-0.3,2.5-0.8
	c0.4-0.4,0.8-0.8,1.2-1.4 M470.9,428.7l0.5-1.6 M449.7,440.7l-3.2-0.8c-1.9-0.4-4.3-0.7-7-0.8 M450.9,441.3
	c-0.4-0.1-0.8-0.3-1.2-0.5 M448.5,441.3h2.5 M443.5,442.9c1.8-0.4,3.4-1,5-1.6 M448.5,441.3l1.2-0.5 M468,440.7h-1.2 M466.8,440.7
	l-1.4,1.9 M443.5,442.9l21.9-0.3 M468,440.7c1.4-4.4,2.3-8.4,2.9-12 M466.8,440.7l-15.8,0.5 M471.4,427.1l-14.3,0.1 M439.5,443.7
	l4-0.8 M457.1,427.2l12.2-25.5 M408.6,442.1c-27,0.4-54.1,0.3-81.2-0.1 M333.7,466.4l-0.5-5.9l-7-18.4 M331.4,467.5l2.3-1.1
	 M332.2,463.8l1.5,2.6 M480.5,401.2l-11.1,0.5 M471.4,427.1l9.1-25.9 M485,426.5l-13.6,0.5 M318.3,433.4c0,1.1,0.1,2.3,0.5,3.7
	l1.2,4.5c2.6,9.1,6.5,17.6,11.3,25.9 M573.9,435.2c-35.1,2.5-70.4,4.3-106.1,5.5 M647.4,378.7h-54.8c-72.9,0.3-142.1,4.5-207.4,12.8
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M384.9,395.9c65.5-8.3,134.8-12.5,208-12.9h55 M238.8,194.3
	c-4.9,31.4-7.6,63.3-8.1,95.6v17.7c0.6,32.3,3.2,64.2,8.1,95.6 M190.9,340.7v-0.3c-0.1-0.3-0.4-0.4-0.7-0.4h-1.8
	c-0.4,0-0.6,0.1-0.7,0.4v1.5c0,0.5,0.3,0.8,0.8,0.8h1.6c0.6,0,0.8-0.3,0.8-0.8v-1.2L190.9,340.7L190.9,340.7z M90.1,313.6
	c13.2,2.5,26.5,4.7,40,6.7l24.1,3.6l22.8,3l34.8,4c-11.4,0.5-23.1,0.5-34.8-0.1c-7.6-0.4-15.1-1.1-22.8-1.9l-24.1-3.6
	c-13.3-2.1-26.7-4.3-39.8-6.6 M90.1,284.2c13.2-2.3,26.5-4.5,40-6.7l24.1-3.6l22.8-3l34.8-4c-11.4-0.5-23.1-0.5-34.8,0.1
	c-7.6,0.4-15.1,1.1-22.8,1.9l-24.1,3.6c-13.3,2.1-26.7,4.3-39.8,6.7 M162.2,411.8c1.8,1.5,3.7,2.6,5.8,3.3l17.1,4.5l-4.1,1.2
	l-17.7-4.5 M178.3,416.5c-2.3-0.8-4.4-1.6-6.2-2.6c-6.5-4-12.9-8.1-19.5-12.1c-10.6-6.5-21.5-12.9-32.6-19.5
	c-7-4.1-14-8.3-21.3-12.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.3,416.5l-9.9-2.6c-1.9-0.5-3.6-1.5-5.1-2.8l-3.4-3.3
	c-1.8-1.8-3.9-3.2-6.1-4.5l-1.1-0.7c-11.1-6.9-22.6-13.6-34.3-20.5c-6.5-3.9-13.2-7.7-20.2-11.6 M163,416.2l-0.4,0.3 M163,416.2
	c1.1-1.5,0.8-3-1-4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M161.2,411l0.8,0.7l0.1,0.1 M161.2,416.5V411 M162.6,416.5h-1.4
	 M164.7,423.5l1.9,1.4 M166.6,424.9c0.8,0.7,1.5,1.5,1.8,2.2c1.8,4,3.6,6.6,5.5,7.8 M209.4,422c-10,1.5-21,2.2-33,2.1H172
	 M164.7,423.5l7.3,0.4 M173.9,434.9l-6.5-0.4c-9.1-0.8-17.7-3.7-26-8.4 M148.2,431.4c2.2,1.2,4.4,2.6,6.3,4.1
	c4.1,2.1,8.8,3.4,14.2,4.3l17.2,1.5 M209.4,422c-8.5-1-16.2-2.1-23.2-3.4c-2.9-0.5-5.5-1.2-7.8-2.1 M157.5,419.8
	c6.1,1.8,12.1,2.9,17.9,3h7.6l-19.3-4.7L157.5,419.8L157.5,419.8z M173.9,434.9l12,6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.4,440.3l-20.6-0.8c-3.6-0.3-6.5-1-8.7-1.9c-7.6-3.4-16.2-5.8-26-7
	h-0.3c-20.4-2.8-40.9-4.7-61.4-5.6 M279.3,441.4c-12.2,0.1-25.3,0-38.9-0.7H240c-22.1-2.3-44.2-4.3-66.2-5.8 M255.9,415.3l13.6-0.7
	l7.8-1.2 M261.8,426c2.1,0.4,4.8,0.7,8,0.7l19.7,0.4 M261.8,426l1.2-0.1c17.6-2.9,35.2-5.6,53.1-8.3l-6.1,10"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M288.6,428.2l-23.2-0.7l-8-0.8l-5-1.2 M94.9,360.6l124.2,29.3L99.3,365.7
	c15,10.3,30.1,18.6,45.4,25c25.3,10.2,57.1,18,95.6,23.5c5.1,0.7,10.3,1,15.5,1 M258.8,412.9l-2.9-0.7 M255.9,415.3v-3 M185.9,441.3
	c20.1,1.5,42.2,2.2,66.7,2.2h0.3c9.1-0.1,17.9-0.8,26.4-1.9 M261.4,423.9c-2.3-2.6-4.3-5.5-5.5-8.7 M277.3,413.3
	c-6.9,0.5-13.1,0.4-18.6-0.5 M252.4,425.4l9.4,0.5 M261.4,423.9l-8.9,1.5 M319.3,427.5h-5 M301,439.9l-0.3-1.2c-0.1-3,0.8-5,2.8-5.5
	c1.7-0.4,3.2-0.3,4.8,0.3h2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M301,439.9l-2.8-3c-0.5-0.8-0.7-1.5-0.4-2.5c1-1.6,2.6-2.6,5.1-2.9
	c2.8-0.3,5.4,0.4,7.6,1.9 M310.1,427.5h4.3 M310.5,433.4h7.8 M289.6,427.1l-1,1.1 M283.2,441l3-0.5 M286.3,440.4l-2.9-0.1
	 M283.2,441l0.1-0.7 M301.1,441l-0.1-1.1 M286.3,440.4l14.9,0.6 M283.4,440.3c2.5-4.1,4.1-8.3,5.2-12.1 M310.1,427.5l-20.5-0.4
	 M279.3,441.4l4-0.4 M255.9,412.2c-5.8-1.4-10.6-3.6-14.6-6.7l-2.5-2.1 M252.4,425.4c-16.1-0.8-30.4-2.1-43.1-3.4 M90.4,279.1
	l-0.3,5.1 M90.1,284.2l-0.1,9.2l0.1,20.2 M90.1,313.6l0.3,5.1 M89.3,381.7c-3.4-6.5-6.1-16.1-7.8-28.9l-0.6-8.1 M79.4,248.5
	c-1,13.9-1.5,27.6-1.7,41.4c0,0.4-0.1,0.8-0.1,1.2l0.1,16.5c0.1,13.8,0.7,27.6,1.7,41.5 M80.9,344.7c-0.8-12.4-1.2-24.6-1.4-37
	v-17.6c0.1-12.2,0.6-24.6,1.4-37 M79.4,349.2l0.1,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M79.5,351.2l0.1,1.5c1.8,12.8,5.1,22.4,9.6,28.9 M152.6,412
	c-1.5-1.4-2.3-3.2-2.3-5.6v-3.4 M150.2,402.9l2.3,1.4 M152.6,404.2l1.1,0.7c2.2,1.2,4.3,2.8,6.1,4.5l1.4,1.4 M105.2,382.2h10.3
	 M105.2,382.2l-1.7-1.6c-2.3-2.2-4-4.8-5.1-8.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M172,423.9c-18.7-1.4-38.8-11.3-60.5-29.7c-3.3-3-7.8-7.6-13.9-13.5
	c-3.3-4-5-8.1-4.8-12.4v-0.5c0.1-0.5,0.4-0.7,1-0.5 M93.8,367.2l0.7,0.3c0.7,0.4,1.5,0.8,2.2,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.1,419.4c-13.8-4.4-28.3-13.1-43.6-26.1c-3.3-3-7.8-7.4-13.9-13.5
	c-3-3.6-4.5-7.3-4.8-11.1v-1.5 M98.1,370.7l-0.1-1.2 M97.9,369.5l0.7,0.4 M96.7,368.8l1.2,0.7 M98.6,372.5l-0.6-1.8 M98.6,369.9
	l-3.7-9.2 M89.4,381.7h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M79.5,351.2v16c0,2.1,0.6,4,1.5,5.6l4.5,6.5
	c15.4,20.6,34.9,37.7,58.9,51.2c1.5,0.8,2.8,1.1,3.7,1 M93.4,386.8l-4-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M94.9,360.6l-2.5-0.5c-3.6-0.3-5.4,1.1-5.5,4.3c0.1,6.1,3.4,12.8,9.8,20.4
	c3.6,3.9,7.7,7.6,12.1,11.4c20.4,16.2,38.9,25.3,56,27.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.1,419.4c0-1.1-0.6-1.8-1.8-2.2l-0.3-0.1
	c-12.8-4.7-26.3-12.9-40.3-24.9l-11-10.7c-3.3-3-5.4-7.2-5.9-12.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M162.6,416.5c-1.2,0.7-3,0.8-5.2,0.5c-13.5-4.5-27.8-13.1-42.6-25.7
	l-9.5-9.1 M98.6,372.5l16.9,9.8 M93.4,386.8c14.4,17.3,31.9,31.8,52.4,43.2l0.7,0.4 M148.2,431.4l-1.6-0.8 M146.5,430.4
	c-1.5-1.5-3.3-2.9-5.1-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M161.2,416.5c-3.6-0.4-6.6-1.5-8.7-3.6v-1.1 M157.5,419.8l-1.4-0.4
	 M141.4,426.1l-5.1-3.4c-15.1-9.5-29.4-21.5-42.9-35.9 M89.4,381.7c15.8,18,33.7,31.6,53.5,40.9 M152.6,404.2v7.7 M115.6,382.2
	c11.8,6.9,23.5,13.8,34.8,20.6 M90.4,318.7c0.7,14,2.2,28.1,4.5,42 M80.9,344.7l-1.5,4.5 M301.1,441c1.6,7.7,5.1,14.2,10.3,19.1
	c4.5,4.4,11.1,6.9,19.9,7.4 M314.3,427.5l7.2-13.1 M321.5,414.4c-20.2,2.9-40.2,6.1-60.1,9.5 M288.6,428.2
	c60.7,1.5,121.3,1.8,182.3,0.5 M319.3,427.5c45.9,0.5,91.9,0.5,137.8-0.3 M258.8,412.9l126.1-17.1 M726.8,377.6
	c4.5-11.8,7.2-26.3,7.7-43.6c0.3-8.8,0.4-17.6,0.5-26.3v-17.6c-0.1-8.8-0.3-17.5-0.5-26.3c-0.5-17.2-3.2-31.8-7.7-43.5 M636.1,300.9
	l5.4-0.5l5.9-0.3 M647.4,297.6l17.2,0.1l1.4-0.1l1.1,0.1c0.3,0,0.4,0.1,0.5,0.3c0.3,0.1,0.4,0.3,0.5,0.5l0.1,0.3v0.1l-0.1,0.4
	 M668.3,299.3l-0.5,0.5 M667.7,299.8l-0.7,0.3l-1,0.1l-1.4-0.1h-17.3 M668.3,299.3l-0.5,0.5 M682.6,395.6l0.6-1.1
	c1.7-3.4,2.9-7.8,4-12.9 M677.9,402c1.7-1.6,3.2-3.7,4.5-6.5v-0.1 M683.3,401.8l-1.1,0.5c-5.9,2.9-12.4,5.4-19.3,7.3l-1.2-1.2
	 M685.6,401.6l-2.3,0.1 M695.9,400.7l-3.2,0.3 M692.8,401l-3,0.3 M689.7,401.2l-4.1,0.4 M683.4,394.5l8.4-0.7
	c18-2.2,29.2-7.7,33.3-16.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M682.6,395.6l8.5-0.7c19.9-1.9,31.8-7.7,35.6-17.3 M703.5,399.7h-0.1
	l-2.8,0.4 M703.5,399.7L702,401 M700.6,400.1l-0.3,0.1c-4.4,3-9.1,6.2-14.3,9.2l-1.9,1.1c-1.4,0.8-1.8,1.4-1.1,1.9l6.6,4
	 M708.9,412.2c4.4-3.7,7.7-8.5,9.9-14.4l-3.2,0.7 M722.6,395.9l-7.2,1.6c-3.9,0.8-7.8,1.5-12,2.1 M702,401l13.5-2.5 M700.6,400.1
	l-4.7,0.5 M647.8,382.9c-0.3-1.2-0.3-2.6-0.3-4v-0.3 M647.4,378.7h0.3 M649.4,401.1c0.1,0.7-0.3,1.4-1.1,2.3 M645.6,403l-3.6-0.3
	 M648.3,403.3l-2.8-0.3 M664,403.6l-2.5,4.7 M661.7,408.4l-0.1-0.1 M678.9,402h-1 M677.1,402.9l-0.8,0.7h-0.5 M677.9,402l-0.8,0.8
	 M678.9,402l-1.8,0.8 M661.7,408.4c5-1.4,9.6-3,14-5 M675.7,403.6H664 M664,403.6c-7.4-4.4-12.4-9.5-15-15.3
	c-0.5-1.6-1.1-3.4-1.2-5.2 M683.8,428.6c-0.5-0.3-1.2-0.4-2.5-0.4l-7,0.3c-2.8,0.3-5.1,0.8-7,1.5s-2.9,1.4-2.9,1.9s1,1,2.9,1.1
	l7-0.3c2.8-0.4,5.1-0.8,7-1.5c0.7-0.3,1.4-0.5,1.8-0.8 M679.4,409.2l-4.3,1.8c-1.5,0.5-1.7,1.4-0.3,2.6l5.9,4.1 M692.8,401
	c-7.8,4.8-16.8,8.7-27,11.6l-1-1 M664.8,411.5l-1.2-1.2c8-2.2,15.3-5.1,21.9-8.7 M711.2,427.1l3-1.6c6.1-3.4,11.6-7.4,16.4-12
	 M674.8,441.3l7.8-1.2c9.1-1.6,15.7-4,19.7-7.2c2.9-2.2,5.8-4.1,8.9-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M711.2,427.1l-2.2,1.1c-7.8,3.2-16.4,5.8-25.6,7.6 M690.6,418.8l1.8-0.5
	 M691.5,419.4l-1-0.5 M689.6,416.6l2.8,1.6 M696.2,420.6l-1.7,0.5 M695,419.9l1.2,0.7 M699,421.2l-0.7-1.4 M698.3,419.8l-2.1,0.8
	 M702,401c-4.4,3-9.4,6.2-14.6,9.2l-0.1,0.1l-1.1,0.5H686l-0.4,0.3l-0.8,0.4l10.2,5.9c1.4,0.7,2.8,0.7,4.1,0.3
	c3.6-1.5,6.9-3.4,9.9-5.6 M695,419.9l-3.4-0.5 M692.4,418.3l2.6,1.7 M693.2,420.5l1.8-0.5 M694.6,421.2l-1.4-0.7 M682.3,419l1,0.5
	 M680.7,417.9l1.7,1.1 M688.8,417.7l-1-0.5 M685.1,420.6l1,0.7 M685.1,420.6l-1.8-1.1 M683.3,419.5l5.5-1.8 M686.2,416.1l-5.5,1.8
	 M679.4,409.2c-1.7,0.8-1.8,1.8-0.3,2.9l7,4.1 M687.8,417.2l-5.5,1.8 M680.9,425.7h-0.8 M683.3,430.4l-3.2-0.4v-0.5l3.7-0.8
	 M683.3,430.4l1.2,0.1v-2.1l-0.7,0.1 M686,432.3h0.7 M695.7,428.2l3.3-7 M686.7,432.3c1.9-0.4,3.9-0.8,5.6-1.2
	c12.9-3.2,23.4-7.3,31.5-12.5 M688.8,417.7l3.6,0.5 M690.6,418.8l-5.5,1.8 M687.8,417.2l1.8-0.5 M690.4,423.9c3-0.7,5.9-1.6,8.5-2.8
	 M680.9,425.7c3.3-0.4,6.5-1,9.4-1.8 M695.7,428.2l-14.7-2.5 M686.7,432.3l8.9-4.1 M687.7,422.3l2.8,1.6 M686,421.3l1.7,1
	 M686,421.3l5.5-1.9 M687.7,422.3l5.5-1.8 M694.6,421.2l-5.4,1.7 M689.6,416.6l-3.4-0.5 M683.4,435.8v0.7l-8.7,4.8 M683.4,435.8
	l2.6-3.4 M661.5,408.2c-0.3,1.2-0.1,2.2,0.4,3c5.2,6.2,11.1,11,18,14.6 M695.9,400.7c-5.1,3-10.6,5.9-16.6,8.5 M664.8,411.5
	c9.2-2.6,17.5-6.1,24.8-10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M612.4,441.3c15.8-0.3,32.2-1.1,48.8-2.3c7.7-0.7,15.1-1.6,22.1-3
	 M686,432.3c-4.4,0.8-8.9,1.2-13.6,1.5c-17.5,0.5-34.9,1.5-52.5,2.9 M683.3,401.8l-4.4,0.3 M726.8,377.6l8.9-6.3 M725.1,377.6h1.7
	 M727.7,394.5c1.1-2.1,1.9-4.1,2.9-6.3 M725.6,395l-3,0.8 M727.7,394.5l-2.1,0.5 M722.6,395.9l-0.5,1.1c-3.3,6.1-7.7,11.1-13.2,15.1
	 M747.9,390.1l-0.5,1.1c-4.4,8.5-9.9,16-16.6,22.3 M735.7,371.2c-1.4,6.1-3,11.7-5.1,16.8 M730.6,413.5c-2.1,1.8-4.3,3.4-6.9,5.1
	 M699,421.2c12.8-5.1,22.4-14,28.8-26.7 M723.7,418.6c-8.3,5-17.7,8.3-28.2,9.8 M647.4,300.1v78.5 M725.6,395
	c-6.2,11.6-15.3,19.8-27.2,24.8 M735.7,371.2c1-4.4,1.7-8.9,2.2-13.9 M629.9,167.1c16.8,1.5,33.4,3.2,50.1,5 M94.9,237.1
	c-2.3,13.9-3.9,27.9-4.5,42.1 M618.5,196.7L642,195 M647.4,219.1v78.6 M642,402.7l-23.5-1.6 M629.9,430.7l50.2-5 M647.4,297.6
	l-11.3-0.8"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M255.9,412.2v2.9
		c0,0,2.1,5.1,5.5,8.7l-9.1,1.5c0,0-49.5-2.9-69.3-7.6c0,0-7.6-1.9-10.9-4c0,0-7.4-4.7-9.1-5.6c0,0-42.9-26.4-64.4-38.4l-3.7-9.2
		c0,0-5-25-5-61.8c0-36.9,5-61.9,5-61.9l3.7-9.2c0,0,51.4-29.9,64.9-38.8l8-5c0,0,2.8-2.1,12.9-4.7c0,0,21.2-4.3,67.8-7.2l8.9,1.5
		c0,0-3.4,4.1-5.5,8.8v2.9c0,0-16.6,1.4-27,22.6c0,0-6.6,11.1-10.7,35.4c0,0-2.8,17.5-2.8,55.4c0,0-0.7,20.2,2.8,55.2
		c0,0,2.8,19,9.9,33.8C228.1,387.9,235.8,408.1,255.9,412.2z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M255.3,185.1
		c-5.8,1.2-10.6,3.6-14.6,6.7c-0.8,0.7-1.7,1.4-2.5,2.1c-22,22.4-22.7,63.3-23.2,95.6v17.7c0.6,32.3,0.8,72.8,23.2,95.6
		c0.8,0.8,1.7,1.5,2.5,2.1c4,3.2,8.8,5.5,14.6,6.7l2.9,0.5l126.1-17.1c0.4-1.1,0.5-2.6,0.4-4.4l0,0v-0.3c-2.5-27.9-3.7-55.7-4-83.5
		v-17.7c0.3-27.8,1.7-55.6,4-83.5v-0.3l0,0c0.1-1.8,0-3.2-0.4-4.3l-126.1-17.1L255.3,185.1z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M385.2,206.2v0.3
		c-2.5,27.9-3.7,55.7-4,83.5v17.7c0.3,27.8,1.7,55.6,4,83.5v0.3c65.3-8.3,134.5-12.5,207.4-12.8h54.8v-78.5l-6.1,0.3l-5.4,0.5
		c-0.3,1.9-1.8,2.9-4.3,3h-7.8c-3.7-0.1-5.8-1.5-6.3-4c-0.1-0.3-0.1-0.5-0.1-0.8V299v-0.1l0,0v-0.3v-0.1c0-0.3,0-0.6,0.1-0.8
		c0.6-2.5,2.6-3.9,6.3-4h7.8c2.6,0.1,4,1.2,4.3,3l11.3,0.8v-78.4h-54.8C519.7,218.7,450.5,214.4,385.2,206.2L385.2,206.2z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M683.1,394.5
		c0,0,6.9-12.9,7.3-48.3v-96.7c0,0-0.7-31.6-7.3-46.2c0,0,34.4,0.4,42,16.9c0,0,7.8,18,8.3,46.6l0.4,32.1l-0.3,27.1
		c0,0,0.3,30.8-8.4,51.6c0,0-4.1,14.9-40.9,16.8L683.1,394.5z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M677.9,402c0,0,3-3.2,4.5-6.5
		c0,0,37.6-0.1,44.2-18c0,0,6.9-16.2,7.4-38.5l0.5-21.9l0.1-33.2c0,0-0.3-18.2-1-29c0,0-1.2-19.5-7.3-34.9c0,0-2.8-16-44.2-18.2
		c0,0-2.9-5-4.5-6.5c0,0,25,1,49.7,7.6l3.2,7.2c0,0,6.9,5.9,9.8,21.6l1.1,7.4c0,0,1.2,15.3,1.5,29.2l0.4,30.4l-0.3,27.2l-0.8,22.3
		c0,0-1,13.8-2.1,18.3c0,0-1.8,11.8-9.9,21.5l-2.9,6.3C727.7,394.5,706.5,401,677.9,402z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback4DrTop);
