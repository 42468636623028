import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="0 105 840 380">
          <path
            fill="#FFFFFF"
            d="M719.7,146.3c-2.2-0.6-4.3-1.1-6.5-1.5c-6.6-1.4-13.6-2.1-20.8-2.2h-96c0.5,6.5-0.1,10.9-1.9,13.2
	c-7.6,7.7-15.5,17.1-23.7,28.1l-0.3,0.4c-3.9,5.6-5.8,9.8-5.8,12.4H728l6.7-11.4l-137.2,0.7c-7.4-0.1-9.6-1.6-6.3-4.7
	c9.9-9.2,20.5-17.1,31.9-23.5c0.1-0.1,0.3-0.1,0.5-0.3c3-1.4,7.6-2.1,13.6-1.9l125.5-0.4v-7h-26.4
	C729,148.4,723.4,147.8,719.7,146.3L719.7,146.3z"
          />
          <path
            fill="#FFFFFF"
            d="M713.3,144.8c2.2,0.4,4.4,1,6.5,1.5c3.9,1.5,9.4,2.1,16.5,1.8H764c13.1,0,21.5,4.3,25.3,12.7
	c1.4,3.3,2.6,7.2,3.9,11.7h11.3c-2.9-10.6-6.7-17.6-11.7-21c-4.1-3.9-13.1-6.1-27.1-6.6L713.3,144.8L713.3,144.8z"
          />
          <path
            fill="#FFFFFF"
            d="M743.1,185.2c2.1,0.3,3.8,0,5.4-0.8l0.5-0.3c1.4-0.3,4.7,1.1,10,4.3l29.3-13.1c-1.4-13.5-9.9-20.4-25.7-20.2
	l-27.8,30v0.1L743.1,185.2L743.1,185.2z"
          />
          <path
            fill="#FFFFFF"
            d="M748.5,184.4c-1.5,0.8-3.3,1-5.4,0.8l-8.4,0.1l-6.7,11.4l17.1,0.4l7.4-5.6l0.4-0.1l6.3-2.9
	c-5.4-3.2-8.8-4.7-10-4.3L748.5,184.4L748.5,184.4z"
          />
          <path
            fill="#EBEBEB"
            d="M734.7,185.2l27.8-30.1l-125.5,0.4c-6.1-0.1-10.6,0.4-13.6,1.9c-0.1,0.1-0.3,0.1-0.5,0.3
	c-11.4,6.6-22,14.4-31.9,23.5c-3.2,3-1.1,4.5,6.3,4.7L734.7,185.2L734.7,185.2z"
          />
          <path
            fill="#FFFFFF"
            d="M755.5,201.9c-0.8-3.3-4.3-5-10.5-4.8l-17.1-0.4l-0.3,3c-0.3,10-0.5,19.9-0.7,29.7l-0.3,23.2
	c-0.1,14.7,0,29.2,0.1,43.5c-0.1,14.3-0.3,28.8-0.1,43.6L727,363c0.1,9.9,0.4,19.8,0.7,29.9l0.3,3l17.1-0.4
	c6.2,0.1,9.6-1.5,10.5-4.8c3.7-17.9,5.9-40,7-66.3l0.4-27.2c0-7.2-0.1-14.2-0.3-21.2l-0.3-7.4C761.4,241.9,759.1,219.8,755.5,201.9
	L755.5,201.9z"
          />
          <path
            fill="#656565"
            d="M727.7,199.7l0.3-3H414.9c-24.9-0.3-47.6-3.2-68.4-8.7l-1-0.3c-28.1-9.1-55.3-17.3-81.7-24.5l-1-0.3
	c-9.5-2.5-19.1-3.6-29-3.4h-3.7c-9.6,17.7-16.6,38.8-20.9,63.4c-3.9,21.7-5.6,46.2-5.4,73.3c-0.3,27.1,1.5,51.6,5.4,73.3
	c4.4,24.6,11.3,45.8,20.9,63.4h3.7c9.9,0.1,19.5-1,29-3.4l1-0.3c26.4-7.3,53.6-15.4,81.7-24.6l1-0.3c20.8-5.5,43.5-8.4,68.4-8.7H728
	l-0.3-3H569.4c-58.7-0.8-115.1-0.4-169.3,0.8c-18.8,0.4-38.4,3.4-58.5,8.9l-7.4,2.3l-31.2,9.9c-23.1,7.6-42.8,12.5-59,14.9
	c-14.2-32.1-21.6-76.6-22.1-133.6c0.6-56.9,8-101.4,22.1-133.4c16.2,2.2,35.9,7.2,59,14.9l31.2,9.9l7.4,2.3
	c20.1,5.5,39.6,8.5,58.5,8.9c54.2,1.4,110.6,1.6,169.3,0.8L727.7,199.7L727.7,199.7z"
          />
          <path
            fill="#D2D3D4"
            d="M762.7,148.1v7c15.8-0.1,24.5,6.7,25.7,20.2l1-0.4l3.7-2.3c-1.2-4.5-2.5-8.4-3.9-11.7
	c-3.9-8.4-12.2-12.7-25.3-12.7h-1.2L762.7,148.1L762.7,148.1z"
          />
          <path
            fill="#FFFFFF"
            d="M804.3,172.5h-11.3c0.7,1.6,1.4,4.1,2.2,7.3h11.1L804.3,172.5z M788.4,175.3v0.1 M788.4,175.3v0.1l1-0.6"
          />
          <path
            fill="#FFFFFF"
            d="M789.3,174.9l-1,0.7c3.7,17.2,6.5,37.3,8.4,60.1l0.4,4.8c1.2,16,1.9,33.3,2.2,51.9v7.7
	c-0.3,18.6-1,35.9-2.2,51.9l-0.4,4.8c-1.8,23-4.7,42.9-8.4,60.1l1,0.7l3.7,2.3c0.7-1.7,1.4-4.1,2.2-7.3c2.6-11.1,5.8-31.6,9.6-61.4
	h13.3l0.3-2.9h-12.4l-5.6,8.3l0.3-2.2l0.1-2.6c1.2-16,1.9-33.3,2.2-51.9v-7.7c-0.3-18.6-1-35.9-2.2-51.9l-0.1-2.6l-0.3-2.2l5.6,8.3
	h12.4l-0.3-2.9h-13.3c-3.7-29.7-6.9-50.2-9.6-61.4c-0.8-3.2-1.5-5.6-2.2-7.3L789.3,174.9L789.3,174.9z"
          />
          <path
            fill="#FFFFFF"
            d="M806.4,179.7h-11.1c2.6,11.1,5.8,31.6,9.6,61.4h13.3C815.6,220.6,811.8,200.2,806.4,179.7z"
          />
          <path
            fill="#EBEBEB"
            d="M48.8,198.7c1.8-5,3.6-9.4,5.5-13.2c3.9-7.4,8.3-13.5,12.8-18.2c5.5-5.8,11.6-9.6,18.2-11.6
	c4.7-1.5,6.3-2.8,4.8-3.4c-1.9-1.5-4.7-1.5-8.3,0c-10.3,6.1-18.6,13.6-24.6,22.8c-4.8,6.9-9.4,16.1-13.5,27.6L48.8,198.7L48.8,198.7
	z"
          />
          <path
            fill="#FFFFFF"
            d="M54.3,185.4c-1.9,3.9-3.7,8.3-5.5,13.2c-1,2.9-2.1,5.9-3,9.2c-1.8,5.6-3.4,12-5.2,18.7
	c-5,22.4-7.4,45.7-7.7,69.7c0.3,23.9,2.8,47.2,7.7,69.5c1.7,6.9,3.4,13.1,5.2,18.7c1,3.3,2.1,6.3,3,9.2c1.8,5,3.6,9.4,5.5,13.2
	c3.9,7.4,8.3,13.5,12.8,18.2c19,3.2,44.2,5.1,75.5,6.1c27.8,1.2,57,1.9,87.5,1.6c-9.6-17.7-16.6-38.8-20.9-63.4
	c-3.9-21.7-5.6-46.2-5.4-73.3c-0.3-27.1,1.5-51.6,5.4-73.3c4.4-24.6,11.3-45.8,20.9-63.4c-30.5-0.1-59.8,0.4-87.5,1.6
	c-31.4,1-56.5,2.9-75.5,6.1C62.5,172,58.2,178.1,54.3,185.4L54.3,185.4z M64.2,198.6l-0.8,0.1c-1.8,0.6-3.7,2.8-5.5,6.6
	c-8.8,23.4-13.5,53.6-14.3,90.9c0.8,37.3,5.6,67.5,14.3,90.9c1.8,3.9,3.7,6.1,5.5,6.6l0.8,0.1c21.6,6.2,53.1,10.2,94.5,12h33.7
	c6.2,0.5,8.8-0.5,7.7-3.4l-4.8-18.6c-0.6-2.2-1.6-3.6-3.3-4.1L79.7,369.2l112.4,6.2h5.4c1.4,0,2.3,1.6,3.2,5l6.5,24.6
	c1.2,3.9,0.1,5.6-3.2,5.4c-59,0.8-107-3.2-144.2-11.6c-6.2-1.5-10.7-8.5-13.6-21.3c-6.9-23.5-10.7-49.1-11.3-76.9v-8.4
	c0.5-27.8,4.3-53.5,11.3-76.9c2.9-12.8,7.4-19.8,13.6-21.3c37.1-8.5,85.2-12.4,144.2-11.6c3.3-0.3,4.4,1.5,3.2,5.4l-6.5,24.6
	c-0.6,2.2-1.1,3.6-1.9,4.4c-0.4,0.4-0.8,0.6-1.2,0.6H192l-112.4,6.2L192,212.9c1.5-0.4,2.5-1.5,3-3.3l5.1-19.7
	c1.1-2.8-1.5-4-7.7-3.4h-33.7C117.3,188.3,85.8,192.4,64.2,198.6L64.2,198.6z"
          />
          <path
            fill="#FFFFFF"
            d="M63.4,198.7l0.8-0.1c21.6-6.3,53.1-10.3,94.5-12h33.7c6.2-0.6,8.8,0.6,7.7,3.4l-5.1,19.7
	c-0.6,1.8-1.6,2.8-3,3.3L79.7,223.6l112.4-6.2h5.4c0.4,0,1-0.3,1.2-0.6c0.7-0.7,1.4-2.2,1.9-4.4l6.5-24.6c1.2-3.9,0.1-5.6-3.2-5.4
	c-59-0.8-107,3.2-144.2,11.6c-6.2,1.5-10.7,8.5-13.6,21.3c-7,23.5-10.7,49.1-11.3,76.9v8.1c0.5,27.8,4.3,53.4,11.3,76.9
	c2.9,12.8,7.4,19.8,13.6,21.3c37.1,8.5,85.2,12.4,144.2,11.6c3.3,0.3,4.4-1.5,3.2-5.4l-6.5-24.6c-0.8-3.3-1.8-5-3.2-5H192L79.6,369
	L192,379.6c1.6,0.5,2.8,1.9,3.3,4.1l4.8,18.6c1.1,2.8-1.5,4-7.7,3.4h-33.7c-41.4-1.8-72.9-5.8-94.5-12l-0.8-0.1
	c-1.8-0.5-3.7-2.8-5.5-6.6c-8.8-23.4-13.5-53.6-14.3-90.9c0.8-37.3,5.6-67.5,14.3-90.9C59.8,201.5,61.6,199.3,63.4,198.7L63.4,198.7
	z M43.6,202.6c-1,2.6-1.9,5.5-2.9,8.4l2.6-1.5l2.6-1.5c1-3.3,2.1-6.3,3-9.2L43.6,202.6L43.6,202.6z"
          />
          <path
            fill="#FFFFFF"
            d="M40.7,211c1-2.9,1.9-5.8,2.9-8.4c4.1-11.6,8.7-20.8,13.5-27.6c6.1-9.2,14.3-16.8,24.6-22.8
	c7-4.1,15.1-7.4,24.2-10c-29.2,1.5-48,9.6-56.4,24.1c-12.7,19.9-21.3,44.8-25.7,74.6c-1.5,14-2.5,29.7-2.9,47v16.6
	c0.4,17.3,1.4,33,2.9,47c4.5,29.7,13.1,54.6,25.7,74.6c8.5,14.6,27.4,22.6,56.4,24.1c-9.1-2.6-17.1-5.9-24.2-10
	c-10.3-6.1-18.6-13.6-24.6-22.8c-4.8-6.9-9.4-16.1-13.5-27.6c-1-2.8-1.9-5.5-2.9-8.4c-3.2-9.9-6.1-21.2-8.8-34
	c-2.1-10.6-3.4-23.2-4.1-38l-0.3-0.5l-0.3-1l-0.1-0.7c-0.1-1.6-0.3-3.3-0.4-4.8v-0.4h-0.3l-0.1-0.7c-0.1-3.2-0.1-6.2,0-9.2v-0.7V291
	h0.1v-0.8c0.1-2.6,0.4-5.4,0.8-8l0.1-0.5l0.1-0.1v1.1c0.7-14.6,2.1-27,4-37.6C34.7,232.3,37.6,220.9,40.7,211L40.7,211z"
          />
          <path
            fill="#FFFFFF"
            d="M43.3,209.5l-2.6,1.5c-3.2,9.9-6.1,21.3-8.8,34.1c-2.1,10.5-3.4,23-4,37.6v0.1l0.7,1.6v0.7l-0.5,5.5
	c0,0.3,0,0.4,0.1,0.4h0.3c0,0,0.1,0,0.1,0.1v0.7c-0.1,1.5-0.1,3-0.1,4.5l0.1,5.2l-0.1,0.1h-0.4v0.3c0.1,1.9,0.3,4,0.7,6.1l0.4,2.3
	v0.4h-0.3l-1.1-1.4v-0.1c0.7,14.7,2.1,27.4,4.1,38c2.8,12.8,5.6,24.1,8.8,34l2.6,1.5c-3.7-11-7-22.8-9.6-35.6
	c-2.6-13.6-4.1-30.7-4.5-51.2c0.3-20.5,1.8-37.6,4.5-51.2C36.3,232.3,39.6,220.3,43.3,209.5L43.3,209.5z"
          />
          <path
            fill="#FFFFFF"
            d="M45.8,207.8l-2.6,1.5c-3.7,11-7,22.8-9.6,35.6c-2.6,13.6-4.1,30.7-4.5,51.2c0.3,20.5,1.8,37.6,4.5,51.2
	c2.8,12.8,5.9,24.6,9.6,35.6l2.6,1.5c-1.8-5.6-3.4-12-5.2-18.7c-5-22.4-7.4-45.5-7.7-69.5c0.3-24.1,2.8-47.3,7.7-69.7
	C42.4,219.8,44,213.6,45.8,207.8L45.8,207.8z M90.1,152.1c1.5,0.8-0.1,2.1-4.8,3.4c-6.6,2.1-12.5,5.9-18.2,11.6
	c19-3.2,44.2-5.1,75.5-6.1c27.8-1.2,57-1.8,87.5-1.6h3.7c9.9-0.1,19.5,1,29,3.4c2.3-2.5,2.8-4.1,1.1-5.2l-6.7-9.4h-0.5l-7.7-2.6
	c-5.2-2.5-11.7-3.6-19.3-3.6H105.9c-9.1,2.6-17.1,5.9-24.2,10C85.4,150.7,88.2,150.7,90.1,152.1L90.1,152.1z M308.7,110.8
	c-3-0.3-5.5,1.7-7.3,5.6c-2.9,5.4-5.8,12.4-8.5,20.9c-0.3,1.9-0.1,3.7,0.1,4.9c0.7,2.3,2.2,3.7,4.5,4.3l6.6,2.3l1.6,0.6l1.6-7
	c2.6-11,5.2-21.2,8.1-30.5L308.7,110.8L308.7,110.8z"
          />
          <path
            fill="#FFFFFF"
            d="M317.2,115c0.4-2.1,0-3-1.5-3.2c-2.9,9.4-5.6,19.5-8.1,30.5l-1.6,7c1.1,0.1,1.9-0.1,2.3-1.1l0.3-0.8l1.1-5.1
	C311.9,132.1,314.5,123.1,317.2,115L317.2,115z"
          />
          <path
            fill="#FFFFFF"
            d="M308.3,148.2c-0.5,1-1.2,1.2-2.3,1.1l-1.6-0.6v4h101.9l39.3,0.3v-4.8h-12.2c0.1,0.4,0.1,1,0.1,1.5h-16.4
	c-0.1,0.6-1.8,1.1-5.1,1.4h-10.7c-2.8,0-4.5-0.4-5.5-1.4h-10.5c-0.1-0.4,0-1,0-1.4h-77V148.2z"
          />
          <path
            fill="#FFFFFF"
            d="M308.6,147.4l-0.3,0.8h77c0.4-1.6,2.1-2.8,5.1-3.3l4.3-0.6c7.6-0.8,16-1.2,25.6-1l3.2,0.1
	c6.2,0.4,9.5,2.1,10,4.8h99.9c0.4-1.6,2.1-2.8,5.1-3.3l4.3-0.6l2.6-0.3c-2.6-1.4-5.6-2.1-8.8-1.8H309.7L308.6,147.4L308.6,147.4z
	 M307,158.8l-0.3-5.9h-2.6v2.3c0.1,0.4,0.1,0.7,0,1c-0.1,0.8-1,1.1-2.2,1h-12.1c-1.8,0-2.6-0.4-2.6-1.2l0.8-2.8
	c-5.2,0.3-7.7,1.2-7.3,3c0.4,1,3.4,2.1,8.9,3.6l17.5,5L307,158.8L307,158.8z M306.8,152.8l0.3,6.1h138.4v-5.8l-39.2-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M301.4,157.2h0.5c1.4,0.1,2.1-0.3,2.2-1c0.1-0.3,0-0.7,0-1v-6.3l-6.6-2.3c-2.3-0.6-3.9-1.9-4.5-4.3
	c-0.4-1.4-0.4-3-0.1-4.9l-1.6,4.9l-1.8,5.9l-1.4,5l-0.8,2.8c-0.1,1,0.7,1.4,2.6,1.2L301.4,157.2L301.4,157.2z"
          />
          <path
            fill="#EBEBEB"
            d="M307,158.8l0.1,5.9l41.4,11.8c17.5,5,42.6,8,75.5,9.2h0.5c3.9-0.3,5.9-2.2,6.1-5.9l1-21L307,158.8L307,158.8
	z"
          />
          <path
            fill="#FFFFFF"
            d="M264,157.6c1.6,1.1,1.2,2.9-1.1,5.2l1,0.3c26.4,7.3,53.6,15.4,81.7,24.5l0.8,0.3c20.8,5.5,43.5,8.4,68.4,8.7
	h149.9c0-2.5,1.9-6.6,5.8-12.4l0.3-0.4c8.1-11,16.1-20.4,23.7-28.1c1.8-2.3,2.5-6.7,1.9-13.2h-12.9l-4.5,0.3l-5.9,0.8
	c5.9,0.7,8.7,2.8,8.4,6.1h-16.2c-0.7,1.1-2.5,1.6-5.2,1.9H550c-3.3-0.1-5.4-0.7-6.2-1.9h-10.5c-0.1-0.4,0-1,0-1.4h-87.8v4.8
	l120.5,1.7c9.8,0,13.3,2.5,10.6,7.4l-3.3,3.9c-3.9,4.4-8.7,9.2-14.6,14.4c-4,2.9-11.3,4.7-21.7,5.4h-93.5l-0.8,10.3l0.8-10.3h-19.4
	c-32.9-1.1-58.1-4.3-75.5-9.2l-41.4-11.8l-17.5-5c-5.5-1.4-8.5-2.6-8.9-3.6c-0.3-1.8,2.1-2.9,7.3-3l1.4-5h-32.2L264,157.6L264,157.6
	z"
          />
          <path
            fill="#FFFFFF"
            d="M256.7,148.2h32.9l1.8-5.9h-22C263.3,142.2,259,144.3,256.7,148.2z"
          />
          <path
            fill="#EBEBEB"
            d="M334.1,187.6l-31.2-9.9c-23.1-7.6-42.8-12.5-59-14.9c-14.2,32.1-21.6,76.5-22.1,133.4
	c0.6,57,8,101.4,22.1,133.6c16.2-2.2,35.9-7.2,59-14.9l31.2-9.9c-8.9-23.5-12.9-59.7-12.2-108.7C321.1,247.3,325.2,211,334.1,187.6
	L334.1,187.6z"
          />
          <path
            fill="#FFFFFF"
            d="M394.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3c-0.1,0.4-0.1,0.8,0,1.4h9.4L394.5,144.4L394.5,144.4z"
          />
          <path
            fill="#FFFFFF"
            d="M394.5,149.6h1.1c0-0.6,0.3-1,0.5-1.4c0.5-0.7,1.5-1.1,3-1.2l13.9-0.6c1.8,0.4,3,1,3.6,1.8
	c0.3,0.4,0.4,0.8,0.4,1.4h5.1l1-6.2l-3.2-0.1c-9.5-0.1-18,0.1-25.6,1v5.4L394.5,149.6L394.5,149.6z"
          />
          <path
            fill="#FFFFFF"
            d="M396.2,148.2c-0.3,0.4-0.5,0.8-0.5,1.4c1,1,2.8,1.4,5.5,1.4h10.7c3.3-0.3,5-0.7,5.1-1.4c0-0.6-0.1-1-0.4-1.4
	L396.2,148.2L396.2,148.2z M399.2,147c-1.4,0.1-2.5,0.6-3,1.2h20.5c-0.5-0.8-1.8-1.5-3.6-1.8L399.2,147L399.2,147z M399.9,198.9
	c-18.8-0.4-38.4-3.4-58.5-8.9l-7.4-2.3c-8.9,23.5-12.9,59.7-12.2,108.7c-0.7,49,3.3,85.3,12.2,108.7l7.4-2.3
	c20.1-5.5,39.6-8.5,58.5-8.9c54.2-1.4,110.6-1.6,169.3-0.8h158.5c-0.3-10-0.5-19.9-0.7-29.9l-0.3-23.2c-0.1-14.7,0-29.3,0.1-43.6
	c-0.1-14.3-0.3-28.8-0.1-43.5l0.3-23.2c0.1-9.9,0.4-19.8,0.7-29.7H569.4C510.6,200.4,454.1,200.1,399.9,198.9L399.9,198.9z
	 M713,214.6c-0.1,0.4-0.4,0.7-1,0.7l-53.1,0.1c-1.4,0.4-1.9,1.9-1.9,4.4v17.6h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.6,0.8,1.2,0.8,2.1
	v1.1c0,0.8-0.3,1.4-0.8,1.9c-0.5,0.6-1.2,0.8-2.1,0.8H657v29.4h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.2,0.8,2.1v1.1
	c0,0.8-0.3,1.5-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8H657v31.4h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.2,0.8,2.1v1.1
	c0,0.8-0.3,1.5-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8H657v29.4h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.2,0.8,1.9v1.1
	c0,0.8-0.3,1.5-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8H657v17.6c0,2.5,0.7,4,2.1,4.4l52.8,0.1c0.6,0,0.8,0.3,1,0.7v4.1
	c0,0.8-0.4,1.1-1.1,1.1H527.8l-98.5-0.7c-0.7,0-1.1-0.4-1.1-1.1v-3.7c0-0.8,0.4-1.1,1.1-1.1l55,0.1c1.1,0,1.7-1.4,1.7-4.3v-17.3
	h-35.5c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.5-0.8-1.2-0.8-2.1v-1.1c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,2.1-0.8H486v-29.4h-35.5
	c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.5-0.8-1.2-0.8-2.1v-1.1c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,2.1-0.8H486v-31.4h-35.5
	c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.5-0.8-1.2-0.8-2.1v-1.1c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,2.1-0.8H486v-29.4h-35.5
	c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.6-0.8-1.2-0.8-1.9v-1.1c0-0.8,0.3-1.5,0.8-2.1c0.5-0.6,1.2-0.8,2.1-0.8H486v-17.3
	c0-2.8-0.5-4.3-1.7-4.3l-55,0.1c-0.8,0-1.1-0.4-1.1-1.1v-3.7c0-0.7,0.4-1.1,1.1-1.1l98.5-0.7h184.1c0.8,0,1.1,0.4,1.1,1.1L713,214.6
	L713,214.6z M422.6,227.9c5.8-0.1,8.7,3.6,8.8,11.1v114.6c-0.1,7.4-3,11.1-8.8,11.1l-44.4,1.2c-6.3-0.3-9.9-2.9-10.6-7.8
	c-1.1-17.2-1.6-36-1.5-56.4v-10.6c-0.1-20.5,0.4-39.2,1.5-56.4c0.7-5,4.1-7.6,10.6-7.8L422.6,227.9L422.6,227.9z M342.9,353.9
	c0-0.4,0.1-0.5,0.5-0.7l10.9-0.8c0.4,0,0.5,0.1,0.5,0.5v3.9c0,0.4-0.1,0.5-0.5,0.5l-10.9,0.8c-0.4,0-0.5-0.1-0.5-0.5V353.9
	L342.9,353.9z"
          />
          <path
            fill="#FFFFFF"
            d="M712,215.2c0.5,0,0.8-0.3,1-0.7v-4.1c0-0.8-0.4-1.1-1.1-1.1H527.8l-98.5,0.7c-0.7,0-1.1,0.4-1.1,1.1v3.7
	c0,0.8,0.4,1.1,1.1,1.1l55-0.1H495l152.8-0.4h11L712,215.2z"
          />
          <path
            fill="#FFFFFF"
            d="M495.1,215.8c-1.1,0.1-1.6,1.5-1.6,4v17.6h156v-17.3c0-2.8-0.6-4.4-1.7-4.7L495.1,215.8L495.1,215.8z"
          />
          <path
            fill="#FFFFFF"
            d="M493.6,219.8c0-2.5,0.4-3.9,1.7-4h-10.6c1.1,0,1.6,1.5,1.6,4.3v152.4c0,2.8-0.5,4.3-1.6,4.3h10.6
	c-1.1-0.1-1.7-1.5-1.7-4V219.8L493.6,219.8z M647.9,215.4c1.1,0.3,1.7,1.9,1.7,4.7v152.4c0,2.8-0.7,4.4-2.2,4.7h11.7
	c-1.5-0.4-2.2-1.9-2.1-4.4v-153c0-2.5,0.5-4,1.9-4.4L647.9,215.4L647.9,215.4z"
          />
          <path
            fill="#FFFFFF"
            d="M657,237.4v6.9h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.6,0.8-1.2,0.8-1.9v-1.1c0-0.8-0.3-1.5-0.8-2.1
	c-0.5-0.6-1.2-0.8-2.1-0.8H657L657,237.4L657,237.4z M649.6,244.3v-6.9h-156v6.9 M649.6,273.7v-29.4h-156v29.4 M649.6,318.7v-6.7
	h-156v6.7 M657,311.9v6.9h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8
	L657,311.9L657,311.9z M649.6,311.9v-31.4h-156v31.4 M649.6,280.6v-6.9h-156v6.9 M657,273.7v6.9h42.8c0.8,0,1.5-0.3,2.1-0.8
	c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8L657,273.7L657,273.7z M657,348.3v6.9h42.8
	c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.4-0.8-1.9c-0.5-0.5-1.2-0.8-2.1-0.8H657L657,348.3L657,348.3z
	 M649.6,355.1v-6.9h-156v6.9"
          />
          <path
            fill="#FFFFFF"
            d="M647.4,377c1.5-0.3,2.2-1.9,2.2-4.7V355h-156v17.6c0,2.6,0.4,3.9,1.7,4L647.4,377L647.4,377z"
          />
          <path
            fill="#FFFFFF"
            d="M659.2,377h-11.7l-152.3-0.4h-10.6l-55-0.1c-0.8,0-1.1,0.4-1.1,1.1v3.7c0,0.7,0.4,1.1,1.1,1.1l98.5,0.7
	h184.1c0.8,0,1.1-0.4,1.1-1.1v-4.1c-0.1-0.4-0.4-0.7-1-0.7L659.2,377L659.2,377z M649.6,348.3v-29.6h-156v29.6 M486.1,244.3v-6.9
	h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.4,0.8,1.9s1.2,0.8,2.1,0.8L486.1,244.3L486.1,244.3z M448.7,274.5
	c-0.5,0.5-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.5,0.8,2.1c0.5,0.5,1.2,0.8,2.1,0.8h35.5v-6.9h-35.5C450,273.7,449.3,274,448.7,274.5z
	 M448.7,318c0.5,0.5,1.2,0.8,2.1,0.8h35.5v-6.9h-35.5c-0.8,0-1.5,0.3-2.1,0.8c-0.5,0.5-0.8,1.2-0.8,2.1v1.1
	C447.9,316.8,448.2,317.4,448.7,318L448.7,318z M486.1,355.1v-6.9h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,1.9v1.1
	c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8L486.1,355.1L486.1,355.1z"
          />
          <path
            fill="#656565"
            d="M431.3,239c-0.1-7.4-3-11.1-8.8-11.1l-44.4-1.2c-6.3,0.3-9.9,2.9-10.6,7.8c-1.1,17.2-1.6,36-1.5,56.4v10.6
	c-0.1,20.5,0.4,39.2,1.5,56.4c0.7,5,4.1,7.6,10.6,7.8l44.4-1.2c5.8,0.1,8.7-3.6,8.8-11.1V239z M422.5,229.5c4.5,0,7,3.2,7,9.4v114.6
	c-0.1,6.2-2.5,9.4-7,9.4l-44.3,1.2c-5.2-0.3-8.3-2.3-8.9-6.3c-1.1-17.2-1.5-35.9-1.5-56.3c0-1.8,0-3.6,0.1-5.4l-0.1-5.2
	c-0.1-20.4,0.4-39.2,1.5-56.4c0.7-4,3.6-6.1,8.9-6.3L422.5,229.5L422.5,229.5z"
          />
          <path
            fill="#EBEBEB"
            d="M429.6,239c-0.1-6.2-2.5-9.4-7-9.4l-44.3-1.2c-5.2,0.3-8.3,2.3-8.9,6.3c-1.1,17.2-1.5,35.9-1.5,56.4l0.1,5.2
	c0,1.8-0.1,3.6-0.1,5.4c-0.1,20.4,0.4,39.2,1.5,56.3c0.7,4,3.6,6.1,8.9,6.3l44.3-1.2c4.5,0,7-3.2,7-9.4V239z"
          />
          <path
            fill="#FFFFFF"
            d="M343.5,353.2c-0.4,0-0.5,0.3-0.5,0.7v3.9c0,0.4,0.1,0.5,0.5,0.5l10.9-0.8c0.4,0,0.5-0.3,0.5-0.5v-3.9
	c0-0.4-0.1-0.5-0.5-0.5L343.5,353.2L343.5,353.2z M433.5,149.8c0-0.6,0-1-0.1-1.5c-0.5-2.8-4-4.4-10-4.8l-1,6.2L433.5,149.8
	L433.5,149.8z"
          />
          <path
            fill="#656565"
            d="M433.7,158.8H432l-1,21c-0.1,3.7-2.2,5.8-6.1,5.9c5.5-0.3,8.1-1.9,7.8-5.2L433.7,158.8L433.7,158.8z"
          />
          <path
            fill="#FFFFFF"
            d="M445.4,153.1v5.8H508l56.7,0.8l5.8,0.4c3.9,0.8,4.8,2.8,2.8,5.9l3.3-3.9c2.6-5-0.8-7.4-10.6-7.4L445.4,153.1
	L445.4,153.1z"
          />
          <path
            fill="#FFFFFF"
            d="M460.7,158.8h-15.1l-1.9,27H460c-2.6-0.6-3.7-2.2-3.2-5.1L460.7,158.8L460.7,158.8z"
          />
          <path
            fill="#656565"
            d="M463,158.8h-2.5l-3.9,21.9c-0.5,2.9,0.4,4.5,3.2,5.1h2.1c-2.8-0.7-3.7-2.2-3-4.7L463,158.8L463,158.8z"
          />
          <path
            fill="#FFFFFF"
            d="M445.4,158.8h-11.8l-1.1,21.7c0.3,3.3-2.2,5.1-7.8,5.2h18.8L445.4,158.8L445.4,158.8z"
          />
          <path
            fill="#EBEBEB"
            d="M458.9,181.1c-0.7,2.5,0.3,4,3,4.7h69.5c14.4-1.1,23.5-3.7,27.4-7.7l10.9-11.6c2.6-3.3,2.9-5.5,0.8-6.5
	l-5.8-0.4l-56.7-0.8h-45L458.9,181.1L458.9,181.1z"
          />
          <path fill="#FFFFFF" d="M573.1,143.6l-1.9-0.1v0.6" />
          <path
            fill="#FFFFFF"
            d="M571.2,144l0.1-0.6l-3.2-0.1l-22.8,0.8l-2.6,0.3v5.2h1.1c0-0.6,0.3-1,0.5-1.4c0.6-0.7,1.5-1.1,2.9-1.2
	l13.9-0.6c2.8,0.6,4,1.6,4,3.3h5.1L571.2,144L571.2,144z"
          />
          <path
            fill="#FFFFFF"
            d="M573.1,143.6l-1.9,0.4l-1,5.6h11.1C581.6,146.3,578.9,144.4,573.1,143.6L573.1,143.6z M559.7,151.5
	c2.8-0.1,4.5-0.8,5.2-1.9c0-1.6-1.2-2.8-4-3.3l-13.9,0.6c-1.4,0.1-2.3,0.6-2.9,1.2c-0.3,0.4-0.5,0.8-0.5,1.4c1,1.2,3,1.9,6.2,1.9
	h9.9L559.7,151.5L559.7,151.5z"
          />
          <path
            fill="#656565"
            d="M573.4,166c2.1-3.2,1.1-5.1-2.8-5.9c2.1,1.1,1.8,3.3-0.8,6.5l-10.9,11.6c-3.9,4-12.9,6.5-27.4,7.7h5.6
	c10.5-0.7,17.6-2.5,21.7-5.4C564.7,175.1,569.5,170.3,573.4,166L573.4,166z"
          />
          <path
            fill="#FFFFFF"
            d="M542.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3c-0.1,0.4-0.1,0.8,0,1.4h9.4V144.4L542.5,144.4z M531.4,406.7
	h5.6c10.5,0.7,17.6,2.5,21.7,5.4c5.8,5.2,10.7,10,14.6,14.4l3.3,3.9c2.6,5-0.8,7.4-10.6,7.4l-120.5,1.6v4.8h87.8
	c-0.1-0.4-0.1-0.8,0-1.2h10.5c1-1.2,3-1.9,6.2-1.9h9.9c2.8,0.1,4.5,0.8,5.2,1.9h16.2c0.3,3.3-2.5,5.4-8.4,6.1l5.9,0.8l4.5,0.3h12.9
	c0.5-6.5-0.1-11-1.9-13.2c-7.6-7.7-15.5-17.1-23.7-28.1l-0.3-0.4c-3.9-5.8-5.8-9.8-5.8-12.4H414.8c-24.9,0.3-47.6,3.2-68.4,8.7
	l-1,0.3c-28.1,9.1-55.3,17.3-81.7,24.6l-1,0.3c2.3,2.3,2.8,4.1,1.1,5.2l-6.7,9.4h32.3l-1.4-4.8c-5.2-0.3-7.7-1.2-7.3-3
	c0.4-1,3.4-2.2,8.9-3.6l17.5-5l41.4-11.8c17.5-5,42.6-8,75.5-9.2h19.4l-0.8-10.3l0.8,10.3h18.4L531.4,406.7L531.4,406.7z"
          />
          <path
            fill="#656565"
            d="M537,406.7h-5.6c14.4,1.1,23.5,3.7,27.4,7.7l10.9,11.6c2.6,3.3,2.9,5.5,0.8,6.5c3.9-0.8,4.8-2.8,2.8-5.9
	c-3.9-4.4-8.7-9.1-14.6-14.4C554.8,409.2,547.5,407.4,537,406.7L537,406.7z"
          />
          <path
            fill="#EBEBEB"
            d="M569.8,425.9l-10.9-11.6c-3.9-4-12.9-6.6-27.4-7.7h-69.5c-2.8,0.7-3.7,2.2-3,4.7l4.1,22.3h45l56.7-0.8
	l5.8-0.4C572.5,431.4,572.4,429.2,569.8,425.9z"
          />
          <path
            fill="#FFFFFF"
            d="M570.6,432.3l-5.8,0.4l-56.7,0.8h-62.6v5.8l120.5-1.7c9.8,0,13.3-2.5,10.6-7.4l-3.3-3.9
	C575.4,429.7,574.5,431.6,570.6,432.3L570.6,432.3z M570.8,408.5c8.1,11,16.1,20.4,23.7,28.1c1.8,2.3,2.5,6.7,1.9,13.2h96
	c7.2-0.1,14-0.8,20.8-2.2l6.5-1.5c3.9-1.5,9.4-2.1,16.5-1.8h26.4v-7l-125.5-0.4c-6.1,0.1-10.6-0.4-13.6-1.9l-0.5-0.3
	c-11.4-6.6-22-14.4-31.9-23.5c-3.2-3-1.1-4.5,6.3-4.7l137.2,0.7l-6.7-11.4H564.8c0,2.5,1.9,6.6,5.8,12.4L570.8,408.5L570.8,408.5z"
          />
          <path
            fill="#EBEBEB"
            d="M597.6,406.6c-7.4,0.1-9.6,1.6-6.3,4.7c9.9,9.1,20.5,17.1,31.9,23.5l0.5,0.3c3,1.4,7.6,2.1,13.6,1.9
	l125.5,0.4L735,407.3v-0.1L597.6,406.6L597.6,406.6z"
          />
          <path
            fill="#FFFFFF"
            d="M459.9,406.7h-16.4l1.9,27h15.1l-3.9-21.9C456.2,408.9,457.3,407.3,459.9,406.7L459.9,406.7z"
          />
          <path
            fill="#656565"
            d="M461.9,406.7h-2.1c-2.6,0.5-3.7,2.2-3.2,5.1l3.9,21.9h2.5l-4.1-22.3C458.2,408.9,459.3,407.4,461.9,406.7
	L461.9,406.7z"
          />
          <path
            fill="#EBEBEB"
            d="M424.8,406.7h-0.5c-32.9,1.1-58.1,4.3-75.5,9.2l-41.4,11.8l-0.1,5.9h124.8l-1-21
	C430.7,408.9,428.6,407,424.8,406.7L424.8,406.7z"
          />
          <path
            fill="#656565"
            d="M431.8,433.7h1.8l-1.1-21.7c0.3-3.3-2.2-5.1-7.8-5.2c3.9,0.3,5.9,2.2,6.1,5.9L431.8,433.7L431.8,433.7z"
          />
          <path
            fill="#FFFFFF"
            d="M433.7,433.7H307l-0.3,5.9h99.5l39.2-0.1v-5.8"
          />
          <path
            fill="#FFFFFF"
            d="M432.5,412l1.1,21.7h11.8l-1.9-27h-18.8C430.3,406.9,432.9,408.7,432.5,412L432.5,412z M445.4,444.1v-4.8
	l-39.3,0.3H304.2v4l1.6-0.5c1.1-0.1,1.9,0.1,2.3,1.1h77c-0.1-0.4-0.1-0.8,0-1.2h10.5c1-1,2.8-1.4,5.5-1.4h10.7
	c3.3,0.3,5,0.7,5.1,1.4h16.2c0,0.5,0,1-0.1,1.5h12.4V444.1L445.4,444.1z"
          />
          <path
            fill="#FFFFFF"
            d="M423.3,449c6.2-0.4,9.5-2.1,10-4.8c0.1-0.4,0.1-1,0.1-1.5h-11.3L423.3,449z"
          />
          <path
            fill="#FFFFFF"
            d="M433.3,444.1c-0.5,2.8-4,4.4-10,4.8l-3.2,0.1c-9.5,0.1-18-0.3-25.6-1l-4.3-0.5c-3-0.5-4.7-1.6-5.1-3.4h-77
	l0.3,0.8l1.1,5.1h226.7c3.2,0.3,6.1-0.4,8.8-1.8l-2.6-0.3l-4.3-0.5c-3-0.6-4.7-1.6-5.1-3.4h-99.7L433.3,444.1L433.3,444.1z"
          />
          <path
            fill="#FFFFFF"
            d="M423.3,449l-1-6.2h-5.1c0,0.5-0.1,1-0.4,1.4c-0.5,0.8-1.8,1.5-3.6,1.8l-13.9-0.5c-1.4-0.1-2.5-0.5-3-1.2
	c-0.3-0.4-0.5-0.8-0.5-1.4h-1.1v5.2c7.6,0.8,16.1,1.1,25.6,1H423.3L423.3,449z"
          />
          <path
            fill="#FFFFFF"
            d="M417.1,442.8c-0.1-0.5-1.8-1.1-5.1-1.4h-10.7c-2.8,0-4.5,0.4-5.5,1.4c0,0.5,0.3,1,0.5,1.4h20.5
	C417,443.7,417.1,443.3,417.1,442.8L417.1,442.8z M413.1,446.1c1.8-0.3,3-1,3.6-1.8h-20.5c0.5,0.7,1.5,1.1,3,1.2L413.1,446.1
	L413.1,446.1z M390.3,447.6l4.3,0.5v-5.2h-9.4c-0.1,0.4,0,0.8,0,1.2C385.6,445.9,387.2,447,390.3,447.6L390.3,447.6z M571.2,449
	l1.9-0.1l-1.9-0.3 M573.1,448.8c5.9-0.7,8.7-2.8,8.4-6.1h-11.3l1,5.6L573.1,448.8L573.1,448.8z"
          />
          <path
            fill="#FFFFFF"
            d="M571.2,449l-0.1-0.5l-1-5.6H565c0,1.7-1.2,2.8-4,3.2l-13.9-0.5c-1.4-0.1-2.3-0.5-2.9-1.2
	c-0.3-0.4-0.5-0.8-0.5-1.4h-1.1v5.2l2.6,0.3l22.8,0.8L571.2,449L571.2,449z"
          />
          <path
            fill="#FFFFFF"
            d="M559.7,440.8h-9.9c-3.3,0.1-5.4,0.7-6.2,1.9c0,0.5,0.3,1,0.6,1.4c0.5,0.7,1.5,1.1,2.9,1.2l13.9,0.5
	c2.8-0.5,4-1.6,4-3.2C564.4,441.7,562.6,441.1,559.7,440.8L559.7,440.8z M533.2,442.9c-0.1,0.4,0,1,0,1.2c0.4,1.6,2.1,2.8,5.1,3.4
	l4.3,0.5v-5.2L533.2,442.9L533.2,442.9z M233.9,433h-3.7c-30.5,0.1-59.8-0.4-87.5-1.7c-31.4-1-56.5-2.9-75.5-6.1
	c5.5,5.8,11.6,9.6,18.2,11.6c4.7,1.5,6.3,2.8,4.8,3.6c-1.9,1.5-4.7,1.5-8.3,0c7,4.1,15.1,7.4,24.2,10h123.8c7.7,0,14-1.2,19.3-3.7
	l7.7-2.6h0.5l6.7-9.4c1.6-1.1,1.2-2.9-1.1-5.2C253.4,432,243.8,433.1,233.9,433L233.9,433z M307,433.7l0.1-5.9l-17.5,5
	c-5.5,1.4-8.5,2.6-8.9,3.6c-0.3,1.8,2.1,2.9,7.3,3l-0.8-2.8c-0.1-1,0.7-1.4,2.6-1.2H302c1.4-0.1,2.1,0.3,2.2,1c0.1,0.3,0,0.7,0,1
	v2.3h2.6L307,433.7L307,433.7z M27.8,281.7l-0.3,0.5c-0.5,2.6-0.8,5.4-0.8,8v0.8h-0.1v10l0.1,0.7H27v0.4c0.1,1.5,0.3,3.2,0.4,4.8
	l0.1,0.7v1l0.3,0.8v0.1l1.1,1.4h0.3v-0.4l-0.4-2.3c-0.3-2.1-0.5-4.1-0.7-6.1v-0.3h0.4l0.1-0.1l-0.1-5.2c0-1.5,0-3.2,0.1-4.5v-0.7
	c0-0.1,0-0.1-0.1-0.1h-0.3c-0.1,0-0.1-0.1-0.1-0.4l0.5-5.5v-0.7l-0.7-1.6v-0.4L27.8,281.7L27.8,281.7z M27.5,284.7l0.1,0.3l0.1,0.4
	v0.5c-0.1,1-0.3,1.9-0.3,2.9c-0.1,1-0.1,1.8-0.1,2.8v1h0.5v0.3c-0.1,2.1-0.1,4.3,0,6.3v0.5h-0.4c0-0.1,0,0-0.1,0l0.1,2.2
	c0.1,1.8,0.3,3.7,0.5,5.5v0.5l-0.5-0.7l-0.1-0.5V306c-0.1-2.1-0.3-4-0.4-6.1v0.4l-0.1-0.3h-0.1v-0.5c-0.1-2.2-0.1-4.3,0-6.2V293
	v-0.1h0.1v-0.3c0-2.6,0.3-5.1,0.5-7.7L27.5,284.7l-0.1-0.4v-0.1L27.5,284.7L27.5,284.7z"
          />
          <path
            fill="#FFCD05"
            d="M27.6,285l-0.1-0.3v-0.4v0.5c-0.3,2.6-0.5,5.1-0.5,7.7v0.3h-0.1v7.3H27l0.1,0.3v0.1c0.1,1.9,0.3,4,0.4,6.1
	v0.7l0.1,0.6l0.5,0.7V308c-0.3-1.8-0.4-3.7-0.5-5.5l-0.1-2.2c0-0.1,0-0.1,0.1,0h0.4v-0.6c-0.1-2.2-0.1-4.3,0-6.3v-0.3h-0.6v-1
	c0-1,0-1.9,0.1-2.8c0.1-1,0.1-1.9,0.3-2.9v-0.5L27.6,285L27.6,285z"
          />
          <path
            fill="#FFFFFF"
            d="M27.2,306.7v-0.3V306.7z M28.1,307.8v0.4h0.1 M48.8,393.8c-1-2.9-2.1-5.9-3-9.2l-2.6-1.5l-2.6-1.5
	c1,2.9,1.9,5.8,2.9,8.4L48.8,393.8L48.8,393.8z"
          />
          <path
            fill="#EBEBEB"
            d="M54.3,407c-1.9-3.9-3.7-8.3-5.5-13.2l-5.2-3.9c4.1,11.6,8.7,20.8,13.5,27.6c6.1,9.2,14.3,16.8,24.6,22.8
	c3.6,1.5,6.3,1.5,8.3,0c1.5-0.8-0.1-2.1-4.8-3.6c-6.6-2.1-12.5-5.9-18.2-11.6C62.5,420.5,58.2,414.4,54.3,407L54.3,407z"
          />
          <path
            fill="#FFFFFF"
            d="M308.6,445.1l-0.3-0.8c-0.5-1-1.2-1.2-2.3-1.1l1.7,7c2.6,11,5.2,21.2,8.1,30.5c1.5,0,2.1-1.1,1.5-3.2
	c-2.8-8.1-5.4-17.2-7.6-27.4L308.6,445.1L308.6,445.1z M304.3,443.6v-6.3c0.1-0.4,0.1-0.7,0-1c-0.1-0.8-1-1.1-2.2-1H290
	c-1.8,0-2.6,0.4-2.6,1.2l0.8,2.8l1.4,4.8l1.8,5.9l1.6,5c-0.3-1.9-0.1-3.7,0.1-5c0.7-2.3,2.1-3.7,4.5-4.3L304.3,443.6L304.3,443.6z"
          />
          <path
            fill="#FFFFFF"
            d="M304.3,443.6l-6.6,2.3c-2.3,0.5-3.9,1.9-4.5,4.3c-0.4,1.4-0.4,3-0.1,5c2.8,8.5,5.6,15.5,8.5,20.9
	c1.8,4,4.1,5.8,7.3,5.6l6.9-1.1c-2.9-9.4-5.6-19.5-8.1-30.5l-1.6-7L304.3,443.6L304.3,443.6z M291.4,450.2l-1.8-5.9h-32.9
	c2.5,4.1,6.7,6.1,12.8,5.9L291.4,450.2L291.4,450.2z M818.5,244h-12.4l1.9,2.8c1.7,2.8,2.8,7.4,3.4,13.6l0.8,8.3
	c0.4,4.5,0.7,9.1,1,13.5l0.4,14.9l-0.4,13.3c-0.1,4.4-0.5,8.8-1,13.5l-0.8,8.3c-0.7,6.2-1.8,10.7-3.4,13.6l-1.9,2.8h12.4l1.1-10.6
	l1.7-34.9l-1.2-43.3C819.6,254.3,819.1,249.2,818.5,244L818.5,244z"
          />
          <path
            fill="#FFFFFF"
            d="M807.9,246.7L806,244l-5.6-8.3l0.3,2.2c5,5.9,7.8,16.2,8.7,30.7c-0.7,2.3-1.2,4.8-1.8,7.3
	c-0.7,3.4-1.2,6.9-1.5,10.3c-0.3,3.4-0.5,6.7-0.5,10.2c0,3.3,0.1,6.6,0.5,10c0.3,3.4,0.8,6.9,1.5,10.2c0.5,2.5,1.1,4.8,1.8,7.3
	c-0.8,14.4-3.7,24.8-8.7,30.7l-0.3,2.2l5.6-8.3l1.9-2.8c1.7-2.8,2.8-7.3,3.4-13.6l0.8-8.3c0.4-4.5,0.7-9.1,1-13.5l0.4-13.3
	l-0.4-14.9c-0.1-4.4-0.5-8.8-1-13.5l-0.8-8.3C810.7,254.2,809.6,249.6,807.9,246.7L807.9,246.7z"
          />
          <path
            fill="#FFFFFF"
            d="M807.6,275.9c0.5-2.5,1.1-5,1.8-7.3c-0.8-14.4-3.7-24.8-8.7-30.7l0.1,2.6c1.2,16,1.9,33.3,2.2,51.9v7.7
	c-0.3,18.6-1,35.9-2.2,51.9l-0.1,2.6c5-5.9,7.8-16.2,8.7-30.7c-0.7-2.3-1.2-4.8-1.8-7.3c-0.7-3.4-1.2-6.7-1.5-10.2
	c-0.3-3.3-0.5-6.7-0.5-10c0-3.4,0.1-6.7,0.5-10.2C806.4,282.8,806.9,279.3,807.6,275.9L807.6,275.9z M752.3,401.1l-7.4-5.6
	l-17.1,0.4l6.7,11.4h8.4c2.1-0.3,3.9,0.1,5.4,0.8l0.5,0.3c1.4,0.3,4.7-1.1,10-4.3l-6.3-2.9L752.3,401.1L752.3,401.1z"
          />
          <path
            fill="#FFFFFF"
            d="M734.7,407.3l27.8,30.1c15.8,0.1,24.5-6.7,25.7-20.2l-29.3-13.1c-5.4,3.2-8.8,4.7-10,4.3l-0.5-0.3
	c-1.5-0.8-3.3-1.1-5.4-0.8L734.7,407.3L734.7,407.3z M789.3,417.6l-1-0.7v0.3"
          />
          <path
            fill="#D2D3D4"
            d="M793.1,419.9l-3.7-2.3l-1-0.4c-1.4,13.6-9.9,20.4-25.7,20.2v7h1.4c13.1,0,21.5-4.3,25.3-12.8
	C790.6,428.5,791.8,424.5,793.1,419.9L793.1,419.9z"
          />
          <path
            fill="#FFFFFF"
            d="M788.4,417.2v-0.3 M793.1,419.9h11.3l1.9-7.3h-11.1C794.4,415.9,793.7,418.3,793.1,419.9L793.1,419.9z
	 M804.3,419.9h-11.3c-1.2,4.5-2.5,8.4-3.9,11.7c-3.9,8.5-12.2,12.7-25.3,12.8h-27.8c-7.2-0.3-12.7,0.3-16.5,1.8l-6.5,1.5h52.4
	c13.9-0.5,23-2.8,27.1-6.6C797.6,437.7,801.4,430.5,804.3,419.9L804.3,419.9z M806.4,412.6c5.4-20.5,9.4-40.9,11.8-61.4h-13.3
	c-3.7,29.7-6.9,50.2-9.6,61.4H806.4L806.4,412.6z M752.7,191.3l-0.4,0.1l-7.4,5.6c6.2-0.1,9.6,1.5,10.5,4.8c3.7,17.9,5.9,40,7,66.3
	l0.3,7.4c0.1,7,0.3,14.2,0.3,21.2l-0.4,27.2c-1,26.4-3.3,48.6-7,66.3c-0.8,3.3-4.3,5-10.5,4.8l7.4,5.6l0.4,0.1
	c1.8-1.5,3.2-5.1,4.4-10.6c3.3-16.1,5.5-35.8,6.6-58.7l0.5-14.7l0.3-20.6c0-7-0.1-13.9-0.3-20.9l-0.5-14.9
	c-1.1-23.1-3.3-42.6-6.6-58.7C755.9,196.4,754.5,192.8,752.7,191.3L752.7,191.3z"
          />
          <path
            fill="#EBEBEB"
            d="M759.1,188.4l-6.3,2.9c1.8,1.5,3.2,5.1,4.4,10.6c3.3,16.1,5.5,35.8,6.6,58.7c0.4,0.5,0.8,1.4,1,2.2l0.1,0.5
	l0.5,15.8l-0.1-11l0.1,11c0.1,5.9,0.1,11.7,0.1,17.6l-0.1,16.8l-0.5,15.3l-0.1,0.5c-0.3,0.8-0.5,1.6-1,2.2
	c-1.1,23.1-3.3,42.6-6.6,58.7c-1.2,5.5-2.8,9.1-4.4,10.6l6.3,2.9l29.3,13.1v-0.1c3.7-17.2,6.5-37.3,8.4-60.1l0.4-4.8
	c1.2-16,1.9-33.3,2.2-51.9v-7.7c-0.3-18.6-1-35.9-2.2-51.9l-0.4-4.8c-1.8-22.8-4.7-42.9-8.4-60.1v-0.1L759.1,188.4L759.1,188.4z"
          />
          <path
            fill="#D2D3D4"
            d="M764.9,263c-0.3-0.8-0.5-1.6-1-2.2l0.5,14.9l-0.1-7.3l0.1,7.3c0.1,7,0.3,13.9,0.3,20.9l-0.3,20.6l-0.5,14.7
	c0.4-0.5,0.8-1.4,1-2.2l0.1-0.5l0.5-15.3l0.1-16.8c0-5.9,0-11.7-0.1-17.6l-0.5-15.8L764.9,263L764.9,263z"
          />
          <path
            fill="none"
            d="M788.4,175.3l-29.3,13.1 M806.4,179.7l-2.1-7.3 M806.4,179.7h-11.1 M795.3,179.7c-0.8-3.2-1.5-5.6-2.2-7.3
	 M793.1,172.5l-3.7,2.5 M762.7,155.1c15.8-0.1,24.5,6.7,25.7,20.2 M789.3,174.9l-1,0.6 M789.3,174.9l-1,0.4 M788.4,175.3v0.1
	 M788.4,175.5v-0.1"
          />
          <path
            fill="none"
            d="M713.3,144.8h52.4c13.9,0.6,23,2.8,27.1,6.6c4.8,3.4,8.7,10.5,11.7,21 M804.3,172.5h-11.3"
          />
          <path
            fill="none"
            d="M793.1,172.5c-1.2-4.5-2.5-8.4-3.9-11.7c-3.9-8.4-12.2-12.7-25.3-12.7h-1.4 M762.7,148.1v7 M727.7,199.7l0.3-3
	 M728,196.7l17.1,0.4 M752.7,191.3c1.8,1.5,3.2,5.1,4.4,10.6c3.3,16.1,5.5,35.8,6.6,58.7"
          />
          <path
            fill="none"
            d="M745,395.5c6.2,0.1,9.6-1.5,10.5-4.8c3.7-17.9,5.9-40,7-66.3l0.4-27.2c0-7.2-0.1-14.2-0.3-21.2l-0.3-7.4
	c-1-26.4-3.3-48.6-6.9-66.3c-0.8-3.3-4.3-5-10.5-4.8 M734.7,185.2v-0.1l27.9-30 M734.7,185.2l-6.7,11.4 M759.1,188.4l-6.3,2.9
	 M745,197.1l7.3-5.6l0.4-0.1 M759.1,188.4c-5.4-3.2-8.8-4.7-10-4.3l-0.6,0.3c-1.5,0.8-3.3,1-5.4,0.8l-8.4,0.1 M484.5,215.8l-55,0.1
	c-0.8,0-1.1-0.4-1.1-1.1v-3.7c0-0.7,0.4-1.1,1.1-1.1l98.5-0.7H712c0.8,0,1.1,0.4,1.1,1.1v4.1c-0.1,0.4-0.4,0.7-1,0.7l-53.1,0.1
	 M596.5,142.6h96c7.2,0.1,14,0.8,20.8,2.2 M713.3,144.8c2.2,0.4,4.4,1,6.5,1.5c3.9,1.5,9.4,2.1,16.5,1.8h26.4 M63.4,198.7
	c-1.8,0.6-3.7,2.8-5.5,6.6c-8.8,23.4-13.5,53.6-14.3,90.9c0.8,37.3,5.6,67.5,14.3,90.9c1.8,3.9,3.7,6.1,5.5,6.6l0.8,0.1
	c21.6,6.2,53.1,10.2,94.5,12h33.7c6.2,0.5,8.8-0.5,7.7-3.4l-4.8-18.6c-0.6-2.2-1.6-3.6-3.3-4.1L79.7,369.2l112.4,6.2h5.4
	c1.4,0,2.3,1.6,3.2,5l6.5,24.6c1.2,3.9,0.1,5.6-3.2,5.4c-59,0.8-107-3.2-144.2-11.6c-6.2-1.5-10.7-8.5-13.6-21.3
	c-6.9-23.5-10.7-49.1-11.3-76.9v-8.4c0.5-27.8,4.3-53.5,11.3-76.9c2.9-12.8,7.4-19.8,13.6-21.3c37.1-8.5,85.2-12.4,144.2-11.6
	c3.3-0.3,4.4,1.5,3.2,5.4l-6.5,24.6c-0.6,2.2-1.1,3.6-1.9,4.4c-0.4,0.4-0.8,0.6-1.2,0.6H192l-112.4,6.2L192,212.9
	c1.5-0.4,2.5-1.5,3-3.3l5.1-19.7c1.1-2.8-1.5-4-7.7-3.4h-33.7c-41.4,1.8-72.9,5.8-94.5,12L63.4,198.7L63.4,198.7z M67.1,167.2
	c-4.7,4.8-8.9,10.9-12.8,18.2c-1.9,3.9-3.7,8.3-5.5,13.2 M40.7,211c1-2.9,1.9-5.8,2.9-8.4 M43.3,209.5l-2.6,1.5 M45.8,207.8
	l-2.5,1.7"
          />
          <path
            fill="none"
            d="M43.6,202.6c4.1-11.6,8.7-20.8,13.5-27.6c6.1-9.2,14.3-16.8,24.6-22.8 M48.8,198.7l-5.2,3.9 M48.8,198.7
	c-1,2.9-2.1,5.9-3,9.2"
          />
          <path
            fill="none"
            d="M81.7,152.1c3.6-1.5,6.3-1.5,8.3,0c1.5,0.8-0.1,2.1-4.8,3.4c-6.6,2.1-12.5,5.9-18.2,11.6 M81.7,152.1
	c7-4.1,15.1-7.4,24.2-10"
          />
          <path
            fill="none"
            d="M105.9,142.1c-29.2,1.5-48,9.6-56.4,24.1c-12.7,19.9-21.3,44.8-25.7,74.6c-1.5,14-2.5,29.7-2.9,47v16.6
	c0.4,17.3,1.4,33,2.9,47c4.5,29.7,13.1,54.6,25.7,74.6c8.5,14.6,27.4,22.6,56.4,24.1"
          />
          <path
            fill="none"
            d="M230.3,159.5c-30.5-0.1-59.8,0.4-87.5,1.6c-31.4,1-56.5,2.9-75.5,6.1 M315.6,111.8l-6.9-1
	c-3-0.3-5.5,1.7-7.3,5.6c-2.9,5.4-5.8,12.4-8.5,20.9 M309.7,142.3c2.2-10.2,4.8-19.3,7.6-27.4c0.4-2.1,0-3-1.5-3.2 M256.7,148.2
	l-7.7-2.6c-5.2-2.5-11.7-3.6-19.3-3.6H105.9"
          />
          <path fill="none" d="M262.9,163c-9.5-2.5-19.1-3.6-29-3.4h-3.7" />
          <path
            fill="none"
            d="M230.3,433c-9.6-17.7-16.6-38.8-20.9-63.4c-3.9-21.7-5.6-46.2-5.4-73.3c-0.3-27.1,1.5-51.6,5.4-73.3
	c4.4-24.6,11.3-45.8,20.9-63.4 M305.8,149.3c1.1,0.1,1.9-0.1,2.3-1.1 M309.7,142.3l-1.1,5.1l-0.3,0.8 M306.8,152.8l0.3,6.1
	 M288.2,153.2l-0.8,2.8c-0.1,1,0.7,1.4,2.6,1.2h12.1c1.4,0.1,2.1-0.3,2.2-1c0.1-0.3,0-0.7,0-1v-2.3 M304.3,152.8v-3.9 M306.8,152.8
	h-2.5 M304.3,148.9l1.5,0.4 M307,158.8l0.3,5.9 M304.3,148.9l-6.6-2.3c-2.3-0.6-3.9-1.9-4.5-4.3c-0.4-1.4-0.4-3-0.1-4.9
	 M315.6,111.8c-2.9,9.4-5.6,19.5-8.1,30.5l-1.6,7 M292.9,137.4l-1.5,5 M257.2,148.2l6.7,9.4c1.6,1.1,1.2,2.9-1.1,5.2 M256.7,148.2
	h0.5 M564.8,196.7H414.9c-24.9-0.3-47.6-3.2-68.4-8.7l-1-0.3c-28.1-9.1-55.3-17.3-81.7-24.5l-1-0.3"
          />
          <path
            fill="none"
            d="M307.3,164.8l-17.5-5c-5.5-1.4-8.5-2.6-8.9-3.6c-0.3-1.8,2.1-2.9,7.3-3 M289.6,148.2l-1.4,5 M291.4,142.3h-22
	c-6.1-0.1-10.3,1.9-12.8,5.9 M257.2,148.2h32.3 M291.4,142.3l-1.8,5.9 M334.1,187.6l-31.2-9.9c-23.1-7.6-42.8-12.5-59-14.9
	c-14.2,32.1-21.6,76.5-22.1,133.4c0.6,57,8,101.4,22.1,133.6c16.2-2.2,35.9-7.2,59-14.9l31.2-9.9 M394.5,144.4l-4.3,0.6
	c-3,0.6-4.7,1.6-5.1,3.3 M394.5,149.6h1.1 M385.2,148.2c-0.1,0.4-0.1,0.8,0,1.4h9.4 M396.2,148.2c-0.3,0.4-0.5,0.8-0.5,1.4
	 M395.6,149.6c1,1,2.8,1.4,5.5,1.4h10.7c3.3-0.3,5-0.7,5.1-1.4 M417.1,149.8c0-0.6-0.1-1-0.4-1.4 M423.3,143.4l-3.2-0.1
	c-9.5-0.1-18,0.1-25.6,1"
          />
          <path
            fill="none"
            d="M416.7,148.2c-0.5-0.8-1.8-1.5-3.6-1.8l-13.9,0.6c-1.4,0.1-2.5,0.6-3,1.2 M396.2,148.2h20.5 M394.5,149.6v-5.2
	 M727.7,199.7H569.4c-58.7,0.8-115.1,0.4-169.3-0.8c-18.8-0.4-38.4-3.4-58.5-8.9l-7.4-2.3 M422.2,149.8h11.3c0-0.6,0-1-0.1-1.5
	 M433.7,158.8h-1.9 M445.4,148.2v4.8 M445.4,153.1v5.8 M460.7,158.8h-15.3 M463,158.8h-2.3 M433.3,148.2h12.1 M445.4,158.8h-11.7
	 M433.3,148.2c-0.5-2.8-4-4.4-10-4.8 M423.3,143.4l-1.1,6.3 M459.9,185.8h2.1 M443.5,185.8h16.4 M461.9,185.8
	c-2.8-0.7-3.7-2.2-3-4.7l4.1-22.3"
          />
          <path
            fill="none"
            d="M459.9,185.8c-2.6-0.6-3.7-2.2-3.2-5.1l3.9-21.9 M433.7,158.8l-1.1,21.7c0.3,3.3-2.2,5.1-7.8,5.2 M424.8,185.8
	h-0.5c-32.9-1.1-58.1-4.3-75.5-9.2l-41.4-11.8"
          />
          <path
            fill="none"
            d="M431.8,158.8l-1,21c-0.1,3.7-2.2,5.8-6.1,5.9 M442.8,196.1l0.7-10.3 M443.5,185.8h-18.7 M493.6,237.4v-17.6
	c0-2.5,0.4-3.9,1.7-4 M495.1,215.8h-10.6 M484.5,215.8c1.1,0,1.6,1.5,1.6,4.3v17.3 M445.4,158.8l-1.9,27 M445.4,153.1l-39.2-0.3
	h-99.5 M417.1,149.8h5.1 M573.1,143.6l-1.9-0.1 M596.5,142.6h-12.9l-4.5,0.1l-5.9,0.8 M571.2,144v-0.6 M573.1,143.6l-1.9,0.4
	 M570.6,160.1l-5.8-0.4l-56.8-0.8h-45 M565.1,149.8h5.1 M543.7,149.6c1,1.2,3,1.9,6.2,1.9h9.9c2.8-0.1,4.5-0.8,5.2-1.9"
          />
          <path
            fill="none"
            d="M573.4,166l3.3-3.9c2.6-5-0.8-7.4-10.6-7.4l-120.5-1.6 M571.2,143.4l-3.2-0.1l-22.8,0.8 M573.4,166
	c2.1-3.2,1.1-5.1-2.8-5.9 M570.2,149.8h11.3c0.3-3.3-2.5-5.4-8.4-6.1"
          />
          <path
            fill="none"
            d="M570.6,160.1c2.1,1.1,1.8,3.3-0.8,6.5l-10.9,11.6c-3.9,4-13.1,6.5-27.4,7.7 M571.2,144l-1,5.8 M543.7,149.6
	c0-0.6,0.3-1,0.5-1.4c0.5-0.7,1.5-1.1,2.9-1.2l13.9-0.6c2.8,0.6,4,1.6,4,3.3 M545.2,144.1c-2.6-1.4-5.6-2.1-8.8-1.8H309.7
	 M542.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3 M545.2,144.1l-2.6,0.3 M542.5,149.6h1.1 M533.2,148.2c-0.1,0.4-0.1,0.8,0,1.4h9.4
	 M542.5,149.6v-5.2"
          />
          <path
            fill="none"
            d="M531.4,185.8h5.6c10.5-0.7,17.6-2.5,21.7-5.4c5.8-5.2,10.7-10,14.6-14.4 M564.8,196.7c0-2.5,1.9-6.6,5.8-12.4
	l0.3-0.4c8.1-11,16.1-20.4,23.7-28.1c1.8-2.3,2.5-6.7,1.9-13.2 M762.7,155.1l-125.5,0.4c-6.1-0.1-10.6,0.4-13.6,1.9
	c-0.1,0.1-0.3,0.1-0.5,0.3c-11.4,6.6-22,14.4-31.9,23.5c-3.2,3-1.1,4.5,6.3,4.7l137.2-0.7 M647.9,215.4c1.1,0.3,1.7,1.9,1.7,4.7
	v17.3 M657,237.4v-17.6c0-2.5,0.5-4,1.9-4.4 M658.9,215.4h-11 M647.9,215.4l-152.8,0.4 M461.9,185.8h69.5 M445.4,148.2h87.8
	 M308.3,148.2h76.9 M431.8,158.8H307 M657,237.4v6.9 M649.6,244.3v-6.9 M657,273.7v-29.4 M649.6,273.7v-29.4 M649.6,318.7v-6.7
	 M657,311.9v6.7 M657,311.9v-31.4 M649.6,311.9v-31.4 M649.6,280.6v-6.9 M657,273.7v6.9 M573.4,426.5c-3.9-4.4-8.7-9.1-14.6-14.4
	c-4-2.9-11.3-4.7-21.7-5.4h-5.6"
          />
          <path
            fill="none"
            d="M531.4,406.7c14.4,1.1,23.5,3.7,27.4,7.7l10.9,11.6c2.6,3.3,2.9,5.5,0.8,6.5"
          />
          <path fill="none" d="M570.6,432.3l-5.8,0.5l-56.8,0.8h-45" />
          <path
            fill="none"
            d="M445.4,439.5l120.5-1.6c9.8,0,13.3-2.5,10.6-7.4l-3.3-3.9 M564.8,395.9c0,2.5,1.9,6.6,5.8,12.4l0.3,0.4
	c8.1,11,16.1,20.4,23.7,28.1c1.8,2.3,2.5,6.7,1.9,13.2 M573.4,426.5c2.1,3.2,1.1,5.1-2.8,5.9 M657,348.3v6.9 M649.6,355.1v-6.9
	 M647.4,377c1.5-0.3,2.2-1.9,2.2-4.7V355 M659.2,377c-1.5-0.4-2.2-1.9-2.1-4.4V355 M659.2,377h-11.8 M657,348.3v-29.4 M649.6,348.3
	v-29.4 M762.7,437.4L637.2,437c-6.1,0.1-10.6-0.4-13.6-1.9l-0.5-0.3c-11.4-6.6-22-14.4-31.9-23.5c-3.2-3-1.1-4.5,6.3-4.7l137.2,0.7
	 M486.1,244.3v-6.9 M493.6,237.4v6.9 M486.1,244.3v29.4 M493.6,244.3v29.4 M486.1,237.4h-35.5c-0.8,0-1.5,0.3-2.1,0.8
	s-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.4,0.8,1.9s1.2,0.8,2.1,0.8h35.5 M431.3,239v114.6c-0.1,7.4-3,11.1-8.8,11.1L378,366
	c-6.3-0.3-9.9-2.9-10.6-7.8c-1.1-17.2-1.6-36-1.5-56.4v-10.6c-0.1-20.5,0.4-39.2,1.5-56.4c0.7-5,4.1-7.6,10.6-7.8l44.4,1.2
	C428.2,227.8,431.1,231.5,431.3,239L431.3,239z"
          />
          <path
            fill="none"
            d="M422.5,229.5l-44.3-1.2c-5.2,0.3-8.3,2.3-8.9,6.3c-1.1,17.2-1.5,35.9-1.5,56.4l0.1,5.2c0,1.8-0.1,3.6-0.1,5.4
	c-0.1,20.4,0.4,39.2,1.5,56.3c0.7,4,3.6,6.1,8.9,6.3l44.3-1.2c4.5,0,7-3.2,7-9.4V239C429.5,232.8,427.1,229.7,422.5,229.5
	L422.5,229.5z M486.1,273.7h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8h35.5
	 M486.1,311.9h-35.5c-0.8,0-1.5,0.3-2.1,0.8c-0.5,0.5-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8h35.5 M493.6,311.9
	v6.7 M486.1,318.7v-6.7 M486.1,280.6v31.4 M493.6,280.6v31.4 M493.6,273.7v6.9 M486.1,280.6v-6.9 M343.5,353.2l10.9-0.8
	c0.4,0,0.5,0.1,0.5,0.5v3.9c0,0.4-0.1,0.5-0.5,0.5l-10.9,0.8c-0.4,0-0.5-0.1-0.5-0.5v-3.9C342.9,353.5,343.1,353.2,343.5,353.2
	L343.5,353.2z M564.8,395.9H414.9c-24.9,0.3-47.6,3.2-68.4,8.7l-1,0.3c-28.1,9.1-55.3,17.3-81.7,24.6l-1,0.3"
          />
          <path
            fill="none"
            d="M334.1,404.9l7.4-2.3c20.1-5.5,39.6-8.5,58.5-8.9c54.2-1.4,110.6-1.6,169.3-0.8h158.3 M493.6,348.3v6.9
	 M486.1,355.1v-6.9 M493.6,355.1v17.6c0,2.6,0.4,3.9,1.7,4 M486.1,355.1v17.3c0,2.8-0.5,4.3-1.6,4.3 M495.1,376.6h-10.6
	 M486.1,348.3h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,1.9v1.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8h35.5"
          />
          <path
            fill="none"
            d="M484.5,376.6l-55-0.1c-0.8,0-1.1,0.4-1.1,1.1v3.7c0,0.7,0.4,1.1,1.1,1.1l98.5,0.7H712c0.8,0,1.1-0.4,1.1-1.1
	v-4.1c-0.1-0.4-0.4-0.7-1-0.7l-52.8-0.1 M459.9,406.7h-16.4 M461.9,406.7h-2.1 M424.8,406.7h-0.5c-32.9,1.1-58.1,4.3-75.5,9.2
	l-41.4,11.8 M433.7,433.7h-1.9 M424.8,406.7c5.5,0.3,8.1,1.9,7.8,5.2l1.1,21.7"
          />
          <path
            fill="none"
            d="M431.8,433.7l-1-21c-0.1-3.7-2.2-5.8-6.1-5.9 M463,433.7l-4.1-22.3c-0.7-2.5,0.3-4.1,3-4.7 M460.7,433.7h-15.3"
          />
          <path
            fill="none"
            d="M459.9,406.7c-2.6,0.5-3.7,2.2-3.2,5.1l3.9,21.9 M463,433.7h-2.3 M443.5,406.7l-0.7-10.3 M443.5,406.7h-18.7
	 M445.4,433.7l-1.9-27 M433.7,433.7h11.7 M486.1,318.7v29.6 M493.6,318.7v29.6 M445.4,444.1v-4.7 M433.3,444.1
	c-0.5,2.8-4,4.4-10,4.8"
          />
          <path
            fill="none"
            d="M422.2,442.8h11.3c0,0.5,0,1-0.1,1.5 M423.3,449l-1.1-6.2 M433.3,444.1h12.1 M395.6,442.9c1-1,2.8-1.4,5.5-1.4
	h10.7c3.3,0.3,5,0.7,5.1,1.4 M417.1,442.8c0,0.5-0.1,1-0.4,1.4 M416.7,444.1c-0.5,0.8-1.8,1.5-3.6,1.8l-13.9-0.5
	c-1.4-0.1-2.5-0.5-3-1.2 M385.2,444.1c0.4,1.6,2.1,2.8,5.1,3.4l4.3,0.5 M396.2,444.1c-0.3-0.4-0.5-0.8-0.5-1.4 M394.5,442.9h-9.4
	c-0.1,0.4,0,0.8,0,1.2 M395.6,442.9h-1.1 M394.5,442.9v5.2 M394.5,448.1c7.6,0.8,16.1,1.1,25.6,1l3.2-0.1 M416.7,444.1h-20.5
	 M445.4,439.5l-39.2,0.1h-99.5 M422.2,442.8h-5.1 M573.1,448.8l5.9,0.8l4.5,0.3h12.9 M571.2,449l1.9-0.1 M573.1,448.8
	c5.9-0.7,8.7-2.8,8.4-6.1h-11.3 M571.2,448.5l1.9,0.3 M571.2,449v-0.4 M565.1,442.8c-0.7-1.1-2.5-1.6-5.2-1.9H550
	c-3.3,0.1-5.4,0.7-6.2,1.9 M570.2,442.8h-5.1 M543.7,442.9c0,0.5,0.3,1,0.5,1.4c0.5,0.7,1.5,1.1,2.9,1.2l13.9,0.6
	c2.8-0.5,4-1.6,4-3.2 M545.2,448.4l22.8,0.7l3.2-0.1 M571.2,448.5l-1-5.8 M543.7,442.9h-1.1 M542.5,442.9h-9.4c-0.1,0.4,0,0.8,0,1.2
	 M542.5,448.1l2.6,0.3 M533.2,444.1c0.4,1.6,2.1,2.8,5.1,3.4l4.3,0.5 M542.5,442.9v5.2 M309.7,450.2h226.7c3.2,0.3,6.1-0.4,8.8-1.8
	 M649.6,237.4h-156 M533.2,444.1h-87.8 M461.9,406.7h69.5 M647.4,377l-152.1-0.4 M445.4,433.7v5.8 M649.6,311.9h-156 M493.6,318.7
	h156 M493.6,348.3h156 M649.6,355.1h-156 M493.6,273.7h156 M649.6,280.6h-156 M649.6,244.3h-156 M262.9,429.6
	c-9.5,2.5-19.1,3.6-29,3.4h-3.7 M307,433.7l0.3-5.9 M307.3,427.8l-17.5,5c-5.5,1.4-8.5,2.6-8.9,3.6c-0.3,1.8,2.1,2.9,7.3,3
	 M334.1,187.6c-8.9,23.5-12.9,59.7-12.2,108.7c-0.7,49,3.3,85.3,12.2,108.7 M27.8,282.5v-1.1l-0.1,0.1l-0.1,0.5
	c-0.5,2.6-0.8,5.4-0.8,8v0.8h-0.1v10l0.1,0.7H27v0.4c0.1,1.5,0.3,3.2,0.4,4.8l0.1,0.7v1.1l0.3,0.8"
          />
          <path
            fill="none"
            d="M27.8,309.5l1.1,1.5h0.3v-0.4l-0.4-2.3c-0.3-2.1-0.5-4.1-0.7-6.1v-0.3h0.4l0.1-0.1l-0.1-5.2
	c0-1.5,0-3.2,0.1-4.5v-0.7c0-0.1,0-0.1-0.1-0.1h-0.3c-0.1,0-0.1-0.1-0.1-0.4l0.5-5.5v-0.7l-0.7-1.6v-0.4"
          />
          <path
            fill="none"
            d="M27.2,306.7v0.3l0.1,0.5l0.5,0.7v-0.5c-0.3-1.8-0.4-3.7-0.5-5.5l-0.1-2.2c0-0.1,0-0.1,0.1,0h0.4v-0.5
	c-0.1-2.2-0.1-4.3,0-6.3v-0.3h-0.3v-1c0-1,0-1.9,0.1-2.8c0.1-1,0.1-1.9,0.3-2.9v-0.5l-0.1-0.4l-0.1-0.3v-0.4v0.6
	c-0.3,2.6-0.5,5.1-0.5,7.7v0.3H27v7.3h0.1l0.1,0.3v0.1 M27.2,306.7v-0.3V306.7 M27.8,282.5c0.7-14.6,2.1-27,4-37.6
	c2.8-12.8,5.6-24.1,8.8-34.1"
          />
          <path
            fill="none"
            d="M43.3,209.5c-3.7,11-7,22.8-9.6,35.6c-2.6,13.6-4.1,30.7-4.5,51.2c0.3,20.5,1.8,37.6,4.5,51.2
	c2.8,12.8,5.9,24.6,9.6,35.6"
          />
          <path
            fill="none"
            d="M45.8,384.6c-1.8-5.6-3.4-12-5.2-18.7c-5-22.4-7.4-45.5-7.7-69.5c0.3-24.1,2.8-47.3,7.7-69.7
	c1.7-6.9,3.4-13.1,5.2-18.7 M48.8,393.8c-1-2.9-2.1-5.9-3-9.2 M67.1,425.2c-4.7-4.8-8.9-10.9-12.8-18.2c-1.9-3.9-3.7-8.3-5.5-13.2
	 M48.8,393.8l-5.2-3.9 M45.8,384.6l-2.5-1.5"
          />
          <path
            fill="none"
            d="M81.7,440.3c-10.3-6.1-18.6-13.6-24.6-22.8c-4.8-6.9-9.4-16.1-13.5-27.6 M40.7,381.4c-3.2-9.9-6.1-21.2-8.8-34
	c-2.1-10.6-3.4-23.2-4.1-38 M43.3,383.1l-2.6-1.7 M40.7,381.4c1,2.9,1.9,5.8,2.9,8.4"
          />
          <path
            fill="none"
            d="M230.3,433c-30.5,0.1-59.8-0.4-87.5-1.7c-31.4-1-56.5-2.9-75.5-6.1"
          />
          <path
            fill="none"
            d="M67.1,425.2c5.5,5.8,11.6,9.6,18.2,11.6c4.7,1.5,6.3,2.8,4.8,3.6c-1.9,1.5-4.7,1.5-8.3,0 M81.7,440.3
	c7,4.1,15.1,7.4,24.2,10 M309.7,450.2l-1.1-5.1l-0.3-1 M305.8,443c1.1-0.1,1.9,0.1,2.3,1.1 M304.3,439.6v4 M288.2,439.3l-0.8-2.8
	c-0.1-1,0.7-1.4,2.6-1.2h12.1c1.4-0.1,2.1,0.3,2.2,1c0.1,0.3,0,0.7,0,1v2.3 M292.9,455.1c-0.3-1.9-0.1-3.7,0.1-5
	c0.7-2.3,2.2-3.7,4.5-4.3l6.6-2.3 M304.3,443.6l1.5-0.5 M305.8,443l1.6,7c2.6,11,5.2,21.2,8.1,30.5 M306.8,439.6h-2.5"
          />
          <path
            fill="none"
            d="M292.9,455.1c2.8,8.5,5.6,15.5,8.5,20.9c1.8,4,4.1,5.8,7.3,5.6l6.9-1.1"
          />
          <path
            fill="none"
            d="M315.6,480.6c1.5,0,2.1-1.1,1.5-3.2c-2.8-8.1-5.4-17.2-7.6-27.4 M291.4,450.2l1.5,5 M288.2,439.3l1.4,4.8
	 M291.4,450.2l-1.8-6.1 M256.7,444.1c2.5,4.1,6.7,6.1,12.8,5.9h22 M257.2,444.1l6.7-9.4c1.6-1.1,1.2-2.9-1.1-5.2 M256.7,444.1h0.5
	 M289.6,444.1h-32.3 M105.9,450.3h123.8c7.7,0,14-1.2,19.3-3.7l7.7-2.6 M306.8,439.6l0.3-5.9 M307,433.7h124.8 M308.3,444.1h76.9
	 M818.5,244l-0.3-2.9 M818.5,244h-12.4 M806.1,244l1.9,2.8c1.7,2.8,2.8,7.4,3.4,13.6l0.8,8.3c0.4,4.5,0.7,9.1,1,13.5l0.4,14.9
	l-0.4,13.3c-0.1,4.4-0.5,8.8-1,13.5l-0.8,8.3c-0.7,6.2-1.8,10.7-3.4,13.6l-1.9,2.8"
          />
          <path
            fill="none"
            d="M800.6,354.6c5-5.9,7.8-16.2,8.7-30.7c-0.7-2.3-1.2-4.8-1.8-7.3c-0.7-3.4-1.2-6.7-1.5-10.2
	c-0.3-3.3-0.5-6.7-0.5-10c0-3.4,0.1-6.7,0.5-10.2c0.3-3.4,0.8-6.9,1.5-10.3c0.5-2.5,1.1-5,1.8-7.3c-0.8-14.4-3.7-24.8-8.7-30.7
	 M818.5,348.5l1.1-10.6l1.7-34.9l-1.2-43.3c-0.4-5.2-1-10.5-1.5-15.5"
          />
          <path
            fill="none"
            d="M800.6,237.9l0.1,2.6c1.2,16,1.9,33.3,2.2,51.9v7.7c-0.3,18.6-1,35.9-2.2,51.9l-0.1,2.6 M806.1,244l-5.6-8.3
	l0.1,2.2 M788.4,416.9c3.7-17.2,6.5-37.3,8.4-60.1l0.4-4.8c1.2-16,1.9-33.3,2.2-51.9v-7.7c-0.3-18.6-1-35.9-2.2-51.9l-0.4-4.8
	c-1.8-22.8-4.7-42.9-8.4-60.1 M765.4,279.3l-0.5-15.8l-0.1-0.5c-0.3-0.8-0.5-1.6-1-2.2 M764.3,268.3l0.1,7.2 M763.8,260.8l0.7,14.7
	 M765.4,279.3c0.1,5.9,0.1,11.7,0.1,17.6l-0.1,16.8l-0.5,15.3l-0.1,0.5c-0.3,0.8-0.5,1.6-1,2.2"
          />
          <path
            fill="none"
            d="M764.4,275.5c0.1,7,0.3,13.9,0.3,20.9l-0.3,20.6l-0.5,14.7 M765.3,268.3l0.1,11 M818.2,241.1h-13.3
	c-3.7-29.7-6.9-50.2-9.6-61.4 M727.7,392.8c-0.3-10-0.5-19.9-0.7-29.9l-0.3-23.2c-0.1-14.7,0-29.3,0.1-43.6
	c-0.1-14.3-0.3-28.8-0.1-43.5l0.3-23.2c0.1-9.9,0.4-19.8,0.7-29.7 M657,244.3h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.6,0.8-1.2,0.8-1.9
	v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.6-1.2-0.8-2.1-0.8H657 M657,280.6h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1
	c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8H657 M657,318.7h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1
	c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8H657 M657,355.1h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1
	c0-0.8-0.3-1.4-0.8-1.9c-0.5-0.5-1.2-0.8-2.1-0.8H657 M752.7,401.2l-0.4-0.1l-7.3-5.6 M763.8,331.8c-1.1,23.1-3.3,42.6-6.6,58.7
	c-1.2,5.5-2.8,9.1-4.4,10.6 M752.7,401.2l6.3,2.9 M728,395.9l-0.3-3 M734.7,407.3l-6.7-11.4 M734.7,407.3l27.9,30.1 M759.1,404.1
	c-5.4,3.2-8.8,4.7-10,4.3l-0.6-0.3c-1.5-0.8-3.3-1.1-5.4-0.8h-8.4 M745,395.5l-17.1,0.4 M818.2,351.4l0.3-2.9 M806.1,348.5h12.4
	 M800.6,354.6l-0.1,2.2l5.6-8.3 M789.3,417.6l-1-0.7 M793.1,419.9l-3.7-2.3 M788.4,416.9v0.3 M788.4,417.2v-0.3 M788.4,417.2l1,0.4
	 M804.3,419.9h-11.3 M795.3,412.6c-0.8,3.2-1.5,5.6-2.2,7.3 M793.1,419.9c-1.2,4.5-2.5,8.4-3.9,11.7c-3.9,8.5-12.2,12.7-25.3,12.8
	h-1.4 M806.4,412.6c5.4-20.5,9.4-40.9,11.8-61.4"
          />
          <path
            fill="none"
            d="M818.2,351.4h-13.3c-3.7,29.7-6.9,50.2-9.6,61.4 M806.4,412.6h-11.1 M804.3,419.9l2.1-7.3 M759.1,404.1
	l29.3,13.1 M762.7,444.4v-7 M713.3,447.7h52.4c13.9-0.5,23-2.8,27.1-6.6c4.8-3.4,8.7-10.5,11.7-21"
          />
          <path
            fill="none"
            d="M713.3,447.7l6.5-1.5c3.9-1.5,9.4-2.1,16.5-1.8h26.4 M596.5,449.9h96c7.2-0.1,14-0.8,20.8-2.2 M762.7,437.4
	c15.8,0.1,24.5-6.7,25.7-20.2 M728,196.7H564.8 M818.2,241.1c-2.5-20.5-6.5-40.9-11.8-61.4 M728,395.9H564.8 M719.7,146.3
	c-2.2-0.6-4.3-1.1-6.5-1.5c-6.6-1.4-13.6-2.1-20.8-2.2h-96c0.5,6.5-0.1,10.9-1.9,13.2c-7.6,7.7-15.5,17.1-23.7,28.1l-0.3,0.4
	c-3.9,5.6-5.8,9.8-5.8,12.4H728l6.7-11.4l-137.2,0.7c-7.4-0.1-9.6-1.6-6.3-4.7c9.9-9.2,20.5-17.1,31.9-23.5c0.1-0.1,0.3-0.1,0.5-0.3
	c3-1.4,7.6-2.1,13.6-1.9l125.5-0.4v-7h-26.4C729,148.4,723.4,147.8,719.7,146.3L719.7,146.3z"
          />
          <path
            fill="none"
            d="M713.3,144.8c2.2,0.4,4.4,1,6.5,1.5c3.9,1.5,9.4,2.1,16.5,1.8H764c13.1,0,21.5,4.3,25.3,12.7
	c1.4,3.3,2.6,7.2,3.9,11.7h11.3c-2.9-10.6-6.7-17.6-11.7-21c-4.1-3.9-13.1-6.1-27.1-6.6L713.3,144.8L713.3,144.8z"
          />
          <path
            fill="none"
            d="M743.1,185.2c2.1,0.3,3.8,0,5.4-0.8l0.5-0.3c1.4-0.3,4.7,1.1,10,4.3l29.3-13.1c-1.4-13.5-9.9-20.4-25.7-20.2
	l-27.8,30v0.1L743.1,185.2L743.1,185.2z"
          />
          <path
            fill="none"
            d="M748.5,184.4c-1.5,0.8-3.3,1-5.4,0.8l-8.4,0.1l-6.7,11.4l17.1,0.4l7.4-5.6l0.4-0.1l6.3-2.9
	c-5.4-3.2-8.8-4.7-10-4.3L748.5,184.4L748.5,184.4z M734.7,185.2l27.8-30.1l-125.5,0.4c-6.1-0.1-10.6,0.4-13.6,1.9
	c-0.1,0.1-0.3,0.1-0.5,0.3c-11.4,6.6-22,14.4-31.9,23.5c-3.2,3-1.1,4.5,6.3,4.7L734.7,185.2L734.7,185.2z"
          />
          <path
            fill="none"
            d="M755.5,201.9c-0.8-3.3-4.3-5-10.5-4.8l-17.1-0.4l-0.3,3c-0.3,10-0.5,19.9-0.7,29.7l-0.3,23.2
	c-0.1,14.7,0,29.2,0.1,43.5c-0.1,14.3-0.3,28.8-0.1,43.6L727,363c0.1,9.9,0.4,19.8,0.7,29.9l0.3,3l17.1-0.4
	c6.2,0.1,9.6-1.5,10.5-4.8c3.7-17.9,5.9-40,7-66.3l0.4-27.2c0-7.2-0.1-14.2-0.3-21.2l-0.3-7.4C761.4,241.9,759.1,219.8,755.5,201.9
	L755.5,201.9z"
          />
          <path
            fill="none"
            d="M727.7,199.7l0.3-3H414.9c-24.9-0.3-47.6-3.2-68.4-8.7l-1-0.3c-28.1-9.1-55.3-17.3-81.7-24.5l-1-0.3
	c-9.5-2.5-19.1-3.6-29-3.4h-3.7c-9.6,17.7-16.6,38.8-20.9,63.4c-3.9,21.7-5.6,46.2-5.4,73.3c-0.3,27.1,1.5,51.6,5.4,73.3
	c4.4,24.6,11.3,45.8,20.9,63.4h3.7c9.9,0.1,19.5-1,29-3.4l1-0.3c26.4-7.3,53.6-15.4,81.7-24.6l1-0.3c20.8-5.5,43.5-8.4,68.4-8.7H728
	l-0.3-3H569.4c-58.7-0.8-115.1-0.4-169.3,0.8c-18.8,0.4-38.4,3.4-58.5,8.9l-7.4,2.3l-31.2,9.9c-23.1,7.6-42.8,12.5-59,14.9
	c-14.2-32.1-21.6-76.6-22.1-133.6c0.6-56.9,8-101.4,22.1-133.4c16.2,2.2,35.9,7.2,59,14.9l31.2,9.9l7.4,2.3
	c20.1,5.5,39.6,8.5,58.5,8.9c54.2,1.4,110.6,1.6,169.3,0.8L727.7,199.7L727.7,199.7z M762.7,148.1v7c15.8-0.1,24.5,6.7,25.7,20.2
	l1-0.4l3.7-2.3c-1.2-4.5-2.5-8.4-3.9-11.7c-3.9-8.4-12.2-12.7-25.3-12.7h-1.2L762.7,148.1L762.7,148.1z M804.3,172.5h-11.3
	c0.7,1.6,1.4,4.1,2.2,7.3h11.1L804.3,172.5z M788.4,175.3v0.1"
          />
          <path fill="none" d="M788.4,175.3v0.1l1-0.6" />
          <path
            fill="none"
            d="M789.3,174.9l-1,0.7c3.7,17.2,6.5,37.3,8.4,60.1l0.4,4.8c1.2,16,1.9,33.3,2.2,51.9v7.7
	c-0.3,18.6-1,35.9-2.2,51.9l-0.4,4.8c-1.8,23-4.7,42.9-8.4,60.1l1,0.7l3.7,2.3c0.7-1.7,1.4-4.1,2.2-7.3c2.6-11.1,5.8-31.6,9.6-61.4
	h13.3l0.3-2.9h-12.4l-5.6,8.3l0.3-2.2l0.1-2.6c1.2-16,1.9-33.3,2.2-51.9v-7.7c-0.3-18.6-1-35.9-2.2-51.9l-0.1-2.6l-0.3-2.2l5.6,8.3
	h12.4l-0.3-2.9h-13.3c-3.7-29.7-6.9-50.2-9.6-61.4c-0.8-3.2-1.5-5.6-2.2-7.3L789.3,174.9L789.3,174.9z"
          />
          <path
            fill="none"
            d="M806.4,179.7h-11.1c2.6,11.1,5.8,31.6,9.6,61.4h13.3C815.6,220.6,811.8,200.2,806.4,179.7z M48.8,198.7
	c1.8-5,3.6-9.4,5.5-13.2c3.9-7.4,8.3-13.5,12.8-18.2c5.5-5.8,11.6-9.6,18.2-11.6c4.7-1.5,6.3-2.8,4.8-3.4c-1.9-1.5-4.7-1.5-8.3,0
	c-10.3,6.1-18.6,13.6-24.6,22.8c-4.8,6.9-9.4,16.1-13.5,27.6L48.8,198.7L48.8,198.7z"
          />
          <path
            fill="none"
            d="M54.3,185.4c-1.9,3.9-3.7,8.3-5.5,13.2c-1,2.9-2.1,5.9-3,9.2c-1.8,5.6-3.4,12-5.2,18.7
	c-5,22.4-7.4,45.7-7.7,69.7c0.3,23.9,2.8,47.2,7.7,69.5c1.7,6.9,3.4,13.1,5.2,18.7c1,3.3,2.1,6.3,3,9.2c1.8,5,3.6,9.4,5.5,13.2
	c3.9,7.4,8.3,13.5,12.8,18.2c19,3.2,44.2,5.1,75.5,6.1c27.8,1.2,57,1.9,87.5,1.6c-9.6-17.7-16.6-38.8-20.9-63.4
	c-3.9-21.7-5.6-46.2-5.4-73.3c-0.3-27.1,1.5-51.6,5.4-73.3c4.4-24.6,11.3-45.8,20.9-63.4c-30.5-0.1-59.8,0.4-87.5,1.6
	c-31.4,1-56.5,2.9-75.5,6.1C62.5,172,58.2,178.1,54.3,185.4L54.3,185.4z M64.2,198.6l-0.8,0.1c-1.8,0.6-3.7,2.8-5.5,6.6
	c-8.8,23.4-13.5,53.6-14.3,90.9c0.8,37.3,5.6,67.5,14.3,90.9c1.8,3.9,3.7,6.1,5.5,6.6l0.8,0.1c21.6,6.2,53.1,10.2,94.5,12h33.7
	c6.2,0.5,8.8-0.5,7.7-3.4l-4.8-18.6c-0.6-2.2-1.6-3.6-3.3-4.1L79.7,369.2l112.4,6.2h5.4c1.4,0,2.3,1.6,3.2,5l6.5,24.6
	c1.2,3.9,0.1,5.6-3.2,5.4c-59,0.8-107-3.2-144.2-11.6c-6.2-1.5-10.7-8.5-13.6-21.3c-6.9-23.5-10.7-49.1-11.3-76.9v-8.4
	c0.5-27.8,4.3-53.5,11.3-76.9c2.9-12.8,7.4-19.8,13.6-21.3c37.1-8.5,85.2-12.4,144.2-11.6c3.3-0.3,4.4,1.5,3.2,5.4l-6.5,24.6
	c-0.6,2.2-1.1,3.6-1.9,4.4c-0.4,0.4-0.8,0.6-1.2,0.6H192l-112.4,6.2L192,212.9c1.5-0.4,2.5-1.5,3-3.3l5.1-19.7
	c1.1-2.8-1.5-4-7.7-3.4h-33.7C117.3,188.3,85.8,192.4,64.2,198.6L64.2,198.6z"
          />
          <path
            fill="none"
            d="M63.4,198.7l0.8-0.1c21.6-6.3,53.1-10.3,94.5-12h33.7c6.2-0.6,8.8,0.6,7.7,3.4l-5.1,19.7
	c-0.6,1.8-1.6,2.8-3,3.3L79.7,223.6l112.4-6.2h5.4c0.4,0,1-0.3,1.2-0.6c0.7-0.7,1.4-2.2,1.9-4.4l6.5-24.6c1.2-3.9,0.1-5.6-3.2-5.4
	c-59-0.8-107,3.2-144.2,11.6c-6.2,1.5-10.7,8.5-13.6,21.3c-7,23.5-10.7,49.1-11.3,76.9v8.1c0.5,27.8,4.3,53.4,11.3,76.9
	c2.9,12.8,7.4,19.8,13.6,21.3c37.1,8.5,85.2,12.4,144.2,11.6c3.3,0.3,4.4-1.5,3.2-5.4l-6.5-24.6c-0.8-3.3-1.8-5-3.2-5H192L79.6,369
	L192,379.6c1.6,0.5,2.8,1.9,3.3,4.1l4.8,18.6c1.1,2.8-1.5,4-7.7,3.4h-33.7c-41.4-1.8-72.9-5.8-94.5-12l-0.8-0.1
	c-1.8-0.5-3.7-2.8-5.5-6.6c-8.8-23.4-13.5-53.6-14.3-90.9c0.8-37.3,5.6-67.5,14.3-90.9C59.8,201.5,61.6,199.3,63.4,198.7L63.4,198.7
	z M43.6,202.6c-1,2.6-1.9,5.5-2.9,8.4l2.6-1.5l2.6-1.5c1-3.3,2.1-6.3,3-9.2L43.6,202.6L43.6,202.6z"
          />
          <path
            fill="none"
            d="M40.7,211c1-2.9,1.9-5.8,2.9-8.4c4.1-11.6,8.7-20.8,13.5-27.6c6.1-9.2,14.3-16.8,24.6-22.8
	c7-4.1,15.1-7.4,24.2-10c-29.2,1.5-48,9.6-56.4,24.1c-12.7,19.9-21.3,44.8-25.7,74.6c-1.5,14-2.5,29.7-2.9,47v16.6
	c0.4,17.3,1.4,33,2.9,47c4.5,29.7,13.1,54.6,25.7,74.6c8.5,14.6,27.4,22.6,56.4,24.1c-9.1-2.6-17.1-5.9-24.2-10
	c-10.3-6.1-18.6-13.6-24.6-22.8c-4.8-6.9-9.4-16.1-13.5-27.6c-1-2.8-1.9-5.5-2.9-8.4c-3.2-9.9-6.1-21.2-8.8-34
	c-2.1-10.6-3.4-23.2-4.1-38l-0.3-0.5l-0.3-1l-0.1-0.7c-0.1-1.6-0.3-3.3-0.4-4.8v-0.4h-0.3l-0.1-0.7c-0.1-3.2-0.1-6.2,0-9.2v-0.7V291
	h0.1v-0.8c0.1-2.6,0.4-5.4,0.8-8l0.1-0.5l0.1-0.1v1.1c0.7-14.6,2.1-27,4-37.6C34.7,232.3,37.6,220.9,40.7,211L40.7,211z"
          />
          <path
            fill="none"
            d="M43.3,209.5l-2.6,1.5c-3.2,9.9-6.1,21.3-8.8,34.1c-2.1,10.5-3.4,23-4,37.6v0.1l0.7,1.6v0.7l-0.5,5.5
	c0,0.3,0,0.4,0.1,0.4h0.3c0,0,0.1,0,0.1,0.1v0.7c-0.1,1.5-0.1,3-0.1,4.5l0.1,5.2l-0.1,0.1h-0.4v0.3c0.1,1.9,0.3,4,0.7,6.1l0.4,2.3
	v0.4h-0.3l-1.1-1.4v-0.1c0.7,14.7,2.1,27.4,4.1,38c2.8,12.8,5.6,24.1,8.8,34l2.6,1.5c-3.7-11-7-22.8-9.6-35.6
	c-2.6-13.6-4.1-30.7-4.5-51.2c0.3-20.5,1.8-37.6,4.5-51.2C36.3,232.3,39.6,220.3,43.3,209.5L43.3,209.5z"
          />
          <path
            fill="none"
            d="M45.8,207.8l-2.6,1.5c-3.7,11-7,22.8-9.6,35.6c-2.6,13.6-4.1,30.7-4.5,51.2c0.3,20.5,1.8,37.6,4.5,51.2
	c2.8,12.8,5.9,24.6,9.6,35.6l2.6,1.5c-1.8-5.6-3.4-12-5.2-18.7c-5-22.4-7.4-45.5-7.7-69.5c0.3-24.1,2.8-47.3,7.7-69.7
	C42.4,219.8,44,213.6,45.8,207.8L45.8,207.8z M90.1,152.1c1.5,0.8-0.1,2.1-4.8,3.4c-6.6,2.1-12.5,5.9-18.2,11.6
	c19-3.2,44.2-5.1,75.5-6.1c27.8-1.2,57-1.8,87.5-1.6h3.7c9.9-0.1,19.5,1,29,3.4c2.3-2.5,2.8-4.1,1.1-5.2l-6.7-9.4h-0.5l-7.7-2.6
	c-5.2-2.5-11.7-3.6-19.3-3.6H105.9c-9.1,2.6-17.1,5.9-24.2,10C85.4,150.7,88.2,150.7,90.1,152.1L90.1,152.1z M308.7,110.8
	c-3-0.3-5.5,1.7-7.3,5.6c-2.9,5.4-5.8,12.4-8.5,20.9c-0.3,1.9-0.1,3.7,0.1,4.9c0.7,2.3,2.2,3.7,4.5,4.3l6.6,2.3l1.6,0.6l1.6-7
	c2.6-11,5.2-21.2,8.1-30.5L308.7,110.8L308.7,110.8z"
          />
          <path
            fill="none"
            d="M317.2,115c0.4-2.1,0-3-1.5-3.2c-2.9,9.4-5.6,19.5-8.1,30.5l-1.6,7c1.1,0.1,1.9-0.1,2.3-1.1l0.3-0.8l1.1-5.1
	C311.9,132.1,314.5,123.1,317.2,115L317.2,115z"
          />
          <path
            fill="none"
            d="M308.3,148.2c-0.5,1-1.2,1.2-2.3,1.1l-1.6-0.6v4h101.9l39.3,0.3v-4.8h-12.2c0.1,0.4,0.1,1,0.1,1.5h-16.4
	c-0.1,0.6-1.8,1.1-5.1,1.4h-10.7c-2.8,0-4.5-0.4-5.5-1.4h-10.5c-0.1-0.4,0-1,0-1.4h-77V148.2z"
          />
          <path
            fill="none"
            d="M308.6,147.4l-0.3,0.8h77c0.4-1.6,2.1-2.8,5.1-3.3l4.3-0.6c7.6-0.8,16-1.2,25.6-1l3.2,0.1
	c6.2,0.4,9.5,2.1,10,4.8h99.9c0.4-1.6,2.1-2.8,5.1-3.3l4.3-0.6l2.6-0.3c-2.6-1.4-5.6-2.1-8.8-1.8H309.7L308.6,147.4L308.6,147.4z
	 M307,158.8l-0.3-5.9h-2.6v2.3c0.1,0.4,0.1,0.7,0,1c-0.1,0.8-1,1.1-2.2,1h-12.1c-1.8,0-2.6-0.4-2.6-1.2l0.8-2.8
	c-5.2,0.3-7.7,1.2-7.3,3c0.4,1,3.4,2.1,8.9,3.6l17.5,5L307,158.8L307,158.8z M306.8,152.8l0.3,6.1h138.4v-5.8l-39.2-0.3"
          />
          <path
            fill="none"
            d="M301.4,157.2h0.5c1.4,0.1,2.1-0.3,2.2-1c0.1-0.3,0-0.7,0-1v-6.3l-6.6-2.3c-2.3-0.6-3.9-1.9-4.5-4.3
	c-0.4-1.4-0.4-3-0.1-4.9l-1.6,4.9l-1.8,5.9l-1.4,5l-0.8,2.8c-0.1,1,0.7,1.4,2.6,1.2L301.4,157.2L301.4,157.2z M307,158.8l0.1,5.9
	l41.4,11.8c17.5,5,42.6,8,75.5,9.2h0.5c3.9-0.3,5.9-2.2,6.1-5.9l1-21L307,158.8L307,158.8z"
          />
          <path
            fill="none"
            d="M264,157.6c1.6,1.1,1.2,2.9-1.1,5.2l1,0.3c26.4,7.3,53.6,15.4,81.7,24.5l0.8,0.3c20.8,5.5,43.5,8.4,68.4,8.7
	h149.9c0-2.5,1.9-6.6,5.8-12.4l0.3-0.4c8.1-11,16.1-20.4,23.7-28.1c1.8-2.3,2.5-6.7,1.9-13.2h-12.9l-4.5,0.3l-5.9,0.8
	c5.9,0.7,8.7,2.8,8.4,6.1h-16.2c-0.7,1.1-2.5,1.6-5.2,1.9H550c-3.3-0.1-5.4-0.7-6.2-1.9h-10.5c-0.1-0.4,0-1,0-1.4h-87.8v4.8
	l120.5,1.7c9.8,0,13.3,2.5,10.6,7.4l-3.3,3.9c-3.9,4.4-8.7,9.2-14.6,14.4c-4,2.9-11.3,4.7-21.7,5.4h-93.5l-0.8,10.3l0.8-10.3h-19.4
	c-32.9-1.1-58.1-4.3-75.5-9.2l-41.4-11.8l-17.5-5c-5.5-1.4-8.5-2.6-8.9-3.6c-0.3-1.8,2.1-2.9,7.3-3l1.4-5h-32.2L264,157.6L264,157.6
	z"
          />
          <path
            fill="none"
            d="M256.7,148.2h32.9l1.8-5.9h-22C263.3,142.2,259,144.3,256.7,148.2z M334.1,187.6l-31.2-9.9
	c-23.1-7.6-42.8-12.5-59-14.9c-14.2,32.1-21.6,76.5-22.1,133.4c0.6,57,8,101.4,22.1,133.6c16.2-2.2,35.9-7.2,59-14.9l31.2-9.9
	c-8.9-23.5-12.9-59.7-12.2-108.7C321.1,247.3,325.2,211,334.1,187.6L334.1,187.6z M394.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3
	c-0.1,0.4-0.1,0.8,0,1.4h9.4L394.5,144.4L394.5,144.4z"
          />
          <path
            fill="none"
            d="M394.5,149.6h1.1c0-0.6,0.3-1,0.5-1.4c0.5-0.7,1.5-1.1,3-1.2l13.9-0.6c1.8,0.4,3,1,3.6,1.8
	c0.3,0.4,0.4,0.8,0.4,1.4h5.1l1-6.2l-3.2-0.1c-9.5-0.1-18,0.1-25.6,1v5.4L394.5,149.6L394.5,149.6z"
          />
          <path
            fill="none"
            d="M396.2,148.2c-0.3,0.4-0.5,0.8-0.5,1.4c1,1,2.8,1.4,5.5,1.4h10.7c3.3-0.3,5-0.7,5.1-1.4c0-0.6-0.1-1-0.4-1.4
	L396.2,148.2L396.2,148.2z M399.2,147c-1.4,0.1-2.5,0.6-3,1.2h20.5c-0.5-0.8-1.8-1.5-3.6-1.8L399.2,147L399.2,147z M399.9,198.9
	c-18.8-0.4-38.4-3.4-58.5-8.9l-7.4-2.3c-8.9,23.5-12.9,59.7-12.2,108.7c-0.7,49,3.3,85.3,12.2,108.7l7.4-2.3
	c20.1-5.5,39.6-8.5,58.5-8.9c54.2-1.4,110.6-1.6,169.3-0.8h158.5c-0.3-10-0.5-19.9-0.7-29.9l-0.3-23.2c-0.1-14.7,0-29.3,0.1-43.6
	c-0.1-14.3-0.3-28.8-0.1-43.5l0.3-23.2c0.1-9.9,0.4-19.8,0.7-29.7H569.4C510.6,200.4,454.1,200.1,399.9,198.9L399.9,198.9z
	 M713,214.6c-0.1,0.4-0.4,0.7-1,0.7l-53.1,0.1c-1.4,0.4-1.9,1.9-1.9,4.4v17.6h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.6,0.8,1.2,0.8,2.1
	v1.1c0,0.8-0.3,1.4-0.8,1.9c-0.5,0.6-1.2,0.8-2.1,0.8H657v29.4h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.2,0.8,2.1v1.1
	c0,0.8-0.3,1.5-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8H657v31.4h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.2,0.8,2.1v1.1
	c0,0.8-0.3,1.5-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8H657v29.4h42.8c0.8,0,1.5,0.3,2.1,0.8c0.5,0.5,0.8,1.2,0.8,1.9v1.1
	c0,0.8-0.3,1.5-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8H657v17.6c0,2.5,0.7,4,2.1,4.4l52.8,0.1c0.6,0,0.8,0.3,1,0.7v4.1
	c0,0.8-0.4,1.1-1.1,1.1H527.8l-98.5-0.7c-0.7,0-1.1-0.4-1.1-1.1v-3.7c0-0.8,0.4-1.1,1.1-1.1l55,0.1c1.1,0,1.7-1.4,1.7-4.3v-17.3
	h-35.5c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.5-0.8-1.2-0.8-2.1v-1.1c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,2.1-0.8H486v-29.4h-35.5
	c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.5-0.8-1.2-0.8-2.1v-1.1c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,2.1-0.8H486v-31.4h-35.5
	c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.5-0.8-1.2-0.8-2.1v-1.1c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,2.1-0.8H486v-29.4h-35.5
	c-0.8,0-1.5-0.3-2.1-0.8c-0.5-0.6-0.8-1.2-0.8-1.9v-1.1c0-0.8,0.3-1.5,0.8-2.1c0.5-0.6,1.2-0.8,2.1-0.8H486v-17.3
	c0-2.8-0.5-4.3-1.7-4.3l-55,0.1c-0.8,0-1.1-0.4-1.1-1.1v-3.7c0-0.7,0.4-1.1,1.1-1.1l98.5-0.7h184.1c0.8,0,1.1,0.4,1.1,1.1L713,214.6
	L713,214.6z M422.6,227.9c5.8-0.1,8.7,3.6,8.8,11.1v114.6c-0.1,7.4-3,11.1-8.8,11.1l-44.4,1.2c-6.3-0.3-9.9-2.9-10.6-7.8
	c-1.1-17.2-1.6-36-1.5-56.4v-10.6c-0.1-20.5,0.4-39.2,1.5-56.4c0.7-5,4.1-7.6,10.6-7.8L422.6,227.9L422.6,227.9z M342.9,353.9
	c0-0.4,0.1-0.5,0.5-0.7l10.9-0.8c0.4,0,0.5,0.1,0.5,0.5v3.9c0,0.4-0.1,0.5-0.5,0.5l-10.9,0.8c-0.4,0-0.5-0.1-0.5-0.5V353.9
	L342.9,353.9z"
          />
          <path
            fill="none"
            d="M712,215.2c0.5,0,0.8-0.3,1-0.7v-4.1c0-0.8-0.4-1.1-1.1-1.1H527.8l-98.5,0.7c-0.7,0-1.1,0.4-1.1,1.1v3.7
	c0,0.8,0.4,1.1,1.1,1.1l55-0.1H495l152.8-0.4h11L712,215.2z"
          />
          <path
            fill="none"
            d="M495.1,215.8c-1.1,0.1-1.6,1.5-1.6,4v17.6h156v-17.3c0-2.8-0.6-4.4-1.7-4.7L495.1,215.8L495.1,215.8z"
          />
          <path
            fill="none"
            d="M493.6,219.8c0-2.5,0.4-3.9,1.7-4h-10.6c1.1,0,1.6,1.5,1.6,4.3v152.4c0,2.8-0.5,4.3-1.6,4.3h10.6
	c-1.1-0.1-1.7-1.5-1.7-4V219.8L493.6,219.8z M647.9,215.4c1.1,0.3,1.7,1.9,1.7,4.7v152.4c0,2.8-0.7,4.4-2.2,4.7h11.7
	c-1.5-0.4-2.2-1.9-2.1-4.4v-153c0-2.5,0.5-4,1.9-4.4L647.9,215.4L647.9,215.4z"
          />
          <path
            fill="none"
            d="M657,237.4v6.9h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.6,0.8-1.2,0.8-1.9v-1.1c0-0.8-0.3-1.5-0.8-2.1
	c-0.5-0.6-1.2-0.8-2.1-0.8H657L657,237.4L657,237.4z M649.6,244.3v-6.9h-156v6.9 M649.6,273.7v-29.4h-156v29.4 M649.6,318.7v-6.7
	h-156v6.7 M657,311.9v6.9h42.8c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8
	L657,311.9L657,311.9z M649.6,311.9v-31.4h-156v31.4 M649.6,280.6v-6.9h-156v6.9 M657,273.7v6.9h42.8c0.8,0,1.5-0.3,2.1-0.8
	c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8L657,273.7L657,273.7z M657,348.3v6.9h42.8
	c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.4-0.8-1.9c-0.5-0.5-1.2-0.8-2.1-0.8H657L657,348.3L657,348.3z
	 M649.6,355.1v-6.9h-156v6.9"
          />
          <path
            fill="none"
            d="M647.4,377c1.5-0.3,2.2-1.9,2.2-4.7V355h-156v17.6c0,2.6,0.4,3.9,1.7,4L647.4,377L647.4,377z"
          />
          <path
            fill="none"
            d="M659.2,377h-11.7l-152.3-0.4h-10.6l-55-0.1c-0.8,0-1.1,0.4-1.1,1.1v3.7c0,0.7,0.4,1.1,1.1,1.1l98.5,0.7h184.1
	c0.8,0,1.1-0.4,1.1-1.1v-4.1c-0.1-0.4-0.4-0.7-1-0.7L659.2,377L659.2,377z M649.6,348.3v-29.6h-156v29.6 M486.1,244.3v-6.9h-35.5
	c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.4,0.8,1.9s1.2,0.8,2.1,0.8L486.1,244.3L486.1,244.3z M448.7,274.5
	c-0.5,0.5-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.5,0.8,2.1c0.5,0.5,1.2,0.8,2.1,0.8h35.5v-6.9h-35.5C450,273.7,449.3,274,448.7,274.5z
	 M448.7,318c0.5,0.5,1.2,0.8,2.1,0.8h35.5v-6.9h-35.5c-0.8,0-1.5,0.3-2.1,0.8c-0.5,0.5-0.8,1.2-0.8,2.1v1.1
	C447.9,316.8,448.2,317.4,448.7,318L448.7,318z M486.1,355.1v-6.9h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,1.9v1.1
	c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8L486.1,355.1L486.1,355.1z M431.3,239c-0.1-7.4-3-11.1-8.8-11.1l-44.4-1.2
	c-6.3,0.3-9.9,2.9-10.6,7.8c-1.1,17.2-1.6,36-1.5,56.4v10.6c-0.1,20.5,0.4,39.2,1.5,56.4c0.7,5,4.1,7.6,10.6,7.8l44.4-1.2
	c5.8,0.1,8.7-3.6,8.8-11.1V239z M422.5,229.5c4.5,0,7,3.2,7,9.4v114.6c-0.1,6.2-2.5,9.4-7,9.4l-44.3,1.2c-5.2-0.3-8.3-2.3-8.9-6.3
	c-1.1-17.2-1.5-35.9-1.5-56.3c0-1.8,0-3.6,0.1-5.4l-0.1-5.2c-0.1-20.4,0.4-39.2,1.5-56.4c0.7-4,3.6-6.1,8.9-6.3L422.5,229.5
	L422.5,229.5z"
          />
          <path
            fill="none"
            d="M429.6,239c-0.1-6.2-2.5-9.4-7-9.4l-44.3-1.2c-5.2,0.3-8.3,2.3-8.9,6.3c-1.1,17.2-1.5,35.9-1.5,56.4l0.1,5.2
	c0,1.8-0.1,3.6-0.1,5.4c-0.1,20.4,0.4,39.2,1.5,56.3c0.7,4,3.6,6.1,8.9,6.3l44.3-1.2c4.5,0,7-3.2,7-9.4V239z M343.5,353.2
	c-0.4,0-0.5,0.3-0.5,0.7v3.9c0,0.4,0.1,0.5,0.5,0.5l10.9-0.8c0.4,0,0.5-0.3,0.5-0.5v-3.9c0-0.4-0.1-0.5-0.5-0.5L343.5,353.2
	L343.5,353.2z M433.5,149.8c0-0.6,0-1-0.1-1.5c-0.5-2.8-4-4.4-10-4.8l-1,6.2L433.5,149.8L433.5,149.8z M433.7,158.8H432l-1,21
	c-0.1,3.7-2.2,5.8-6.1,5.9c5.5-0.3,8.1-1.9,7.8-5.2L433.7,158.8L433.7,158.8z M445.4,153.1v5.8H508l56.7,0.8l5.8,0.4
	c3.9,0.8,4.8,2.8,2.8,5.9l3.3-3.9c2.6-5-0.8-7.4-10.6-7.4L445.4,153.1L445.4,153.1z"
          />
          <path
            fill="none"
            d="M460.7,158.8h-15.1l-1.9,27H460c-2.6-0.6-3.7-2.2-3.2-5.1L460.7,158.8L460.7,158.8z"
          />
          <path
            fill="none"
            d="M463,158.8h-2.5l-3.9,21.9c-0.5,2.9,0.4,4.5,3.2,5.1h2.1c-2.8-0.7-3.7-2.2-3-4.7L463,158.8L463,158.8z
	 M445.4,158.8h-11.8l-1.1,21.7c0.3,3.3-2.2,5.1-7.8,5.2h18.8L445.4,158.8L445.4,158.8z"
          />
          <path
            fill="none"
            d="M458.9,181.1c-0.7,2.5,0.3,4,3,4.7h69.5c14.4-1.1,23.5-3.7,27.4-7.7l10.9-11.6c2.6-3.3,2.9-5.5,0.8-6.5
	l-5.8-0.4l-56.7-0.8h-45L458.9,181.1L458.9,181.1z M573.1,143.6l-1.9-0.1v0.6"
          />
          <path
            fill="none"
            d="M571.2,144l0.1-0.6l-3.2-0.1l-22.8,0.8l-2.6,0.3v5.2h1.1c0-0.6,0.3-1,0.5-1.4c0.6-0.7,1.5-1.1,2.9-1.2l13.9-0.6
	c2.8,0.6,4,1.6,4,3.3h5.1L571.2,144L571.2,144z"
          />
          <path
            fill="none"
            d="M573.1,143.6l-1.9,0.4l-1,5.6h11.1C581.6,146.3,578.9,144.4,573.1,143.6L573.1,143.6z M559.7,151.5
	c2.8-0.1,4.5-0.8,5.2-1.9c0-1.6-1.2-2.8-4-3.3l-13.9,0.6c-1.4,0.1-2.3,0.6-2.9,1.2c-0.3,0.4-0.5,0.8-0.5,1.4c1,1.2,3,1.9,6.2,1.9
	h9.9L559.7,151.5L559.7,151.5z M573.4,166c2.1-3.2,1.1-5.1-2.8-5.9c2.1,1.1,1.8,3.3-0.8,6.5l-10.9,11.6c-3.9,4-12.9,6.5-27.4,7.7
	h5.6c10.5-0.7,17.6-2.5,21.7-5.4C564.7,175.1,569.5,170.3,573.4,166L573.4,166z M542.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3
	c-0.1,0.4-0.1,0.8,0,1.4h9.4V144.4L542.5,144.4z M531.4,406.7h5.6c10.5,0.7,17.6,2.5,21.7,5.4c5.8,5.2,10.7,10,14.6,14.4l3.3,3.9
	c2.6,5-0.8,7.4-10.6,7.4l-120.5,1.6v4.8h87.8c-0.1-0.4-0.1-0.8,0-1.2h10.5c1-1.2,3-1.9,6.2-1.9h9.9c2.8,0.1,4.5,0.8,5.2,1.9h16.2
	c0.3,3.3-2.5,5.4-8.4,6.1l5.9,0.8l4.5,0.3h12.9c0.5-6.5-0.1-11-1.9-13.2c-7.6-7.7-15.5-17.1-23.7-28.1l-0.3-0.4
	c-3.9-5.8-5.8-9.8-5.8-12.4H414.8c-24.9,0.3-47.6,3.2-68.4,8.7l-1,0.3c-28.1,9.1-55.3,17.3-81.7,24.6l-1,0.3
	c2.3,2.3,2.8,4.1,1.1,5.2l-6.7,9.4h32.3l-1.4-4.8c-5.2-0.3-7.7-1.2-7.3-3c0.4-1,3.4-2.2,8.9-3.6l17.5-5l41.4-11.8
	c17.5-5,42.6-8,75.5-9.2h19.4l-0.8-10.3l0.8,10.3h18.4L531.4,406.7L531.4,406.7z"
          />
          <path
            fill="none"
            d="M537,406.7h-5.6c14.4,1.1,23.5,3.7,27.4,7.7l10.9,11.6c2.6,3.3,2.9,5.5,0.8,6.5c3.9-0.8,4.8-2.8,2.8-5.9
	c-3.9-4.4-8.7-9.1-14.6-14.4C554.8,409.2,547.5,407.4,537,406.7L537,406.7z"
          />
          <path
            fill="none"
            d="M569.8,425.9l-10.9-11.6c-3.9-4-12.9-6.6-27.4-7.7h-69.5c-2.8,0.7-3.7,2.2-3,4.7l4.1,22.3h45l56.7-0.8l5.8-0.4
	C572.5,431.4,572.4,429.2,569.8,425.9z"
          />
          <path
            fill="none"
            d="M570.6,432.3l-5.8,0.4l-56.7,0.8h-62.6v5.8l120.5-1.7c9.8,0,13.3-2.5,10.6-7.4l-3.3-3.9
	C575.4,429.7,574.5,431.6,570.6,432.3L570.6,432.3z M570.8,408.5c8.1,11,16.1,20.4,23.7,28.1c1.8,2.3,2.5,6.7,1.9,13.2h96
	c7.2-0.1,14-0.8,20.8-2.2l6.5-1.5c3.9-1.5,9.4-2.1,16.5-1.8h26.4v-7l-125.5-0.4c-6.1,0.1-10.6-0.4-13.6-1.9l-0.5-0.3
	c-11.4-6.6-22-14.4-31.9-23.5c-3.2-3-1.1-4.5,6.3-4.7l137.2,0.7l-6.7-11.4H564.8c0,2.5,1.9,6.6,5.8,12.4L570.8,408.5L570.8,408.5z"
          />
          <path
            fill="none"
            d="M597.6,406.6c-7.4,0.1-9.6,1.6-6.3,4.7c9.9,9.1,20.5,17.1,31.9,23.5l0.5,0.3c3,1.4,7.6,2.1,13.6,1.9l125.5,0.4
	L735,407.3v-0.1L597.6,406.6L597.6,406.6z M459.9,406.7h-16.4l1.9,27h15.1l-3.9-21.9C456.2,408.9,457.3,407.3,459.9,406.7
	L459.9,406.7z"
          />
          <path
            fill="none"
            d="M461.9,406.7h-2.1c-2.6,0.5-3.7,2.2-3.2,5.1l3.9,21.9h2.5l-4.1-22.3C458.2,408.9,459.3,407.4,461.9,406.7
	L461.9,406.7z M424.8,406.7h-0.5c-32.9,1.1-58.1,4.3-75.5,9.2l-41.4,11.8l-0.1,5.9h124.8l-1-21C430.7,408.9,428.6,407,424.8,406.7
	L424.8,406.7z"
          />
          <path
            fill="none"
            d="M431.8,433.7h1.8l-1.1-21.7c0.3-3.3-2.2-5.1-7.8-5.2c3.9,0.3,5.9,2.2,6.1,5.9L431.8,433.7L431.8,433.7z"
          />
          <path fill="none" d="M433.7,433.7H307l-0.3,5.9h99.5l39.2-0.1v-5.8" />
          <path
            fill="none"
            d="M432.5,412l1.1,21.7h11.8l-1.9-27h-18.8C430.3,406.9,432.9,408.7,432.5,412L432.5,412z M445.4,444.1v-4.8
	l-39.3,0.3H304.2v4l1.6-0.5c1.1-0.1,1.9,0.1,2.3,1.1h77c-0.1-0.4-0.1-0.8,0-1.2h10.5c1-1,2.8-1.4,5.5-1.4h10.7
	c3.3,0.3,5,0.7,5.1,1.4h16.2c0,0.5,0,1-0.1,1.5h12.4V444.1L445.4,444.1z"
          />
          <path
            fill="none"
            d="M423.3,449c6.2-0.4,9.5-2.1,10-4.8c0.1-0.4,0.1-1,0.1-1.5h-11.3L423.3,449z"
          />
          <path
            fill="none"
            d="M433.3,444.1c-0.5,2.8-4,4.4-10,4.8l-3.2,0.1c-9.5,0.1-18-0.3-25.6-1l-4.3-0.5c-3-0.5-4.7-1.6-5.1-3.4h-77
	l0.3,0.8l1.1,5.1h226.7c3.2,0.3,6.1-0.4,8.8-1.8l-2.6-0.3l-4.3-0.5c-3-0.6-4.7-1.6-5.1-3.4h-99.7L433.3,444.1L433.3,444.1z"
          />
          <path
            fill="none"
            d="M423.3,449l-1-6.2h-5.1c0,0.5-0.1,1-0.4,1.4c-0.5,0.8-1.8,1.5-3.6,1.8l-13.9-0.5c-1.4-0.1-2.5-0.5-3-1.2
	c-0.3-0.4-0.5-0.8-0.5-1.4h-1.1v5.2c7.6,0.8,16.1,1.1,25.6,1H423.3L423.3,449z"
          />
          <path
            fill="none"
            d="M417.1,442.8c-0.1-0.5-1.8-1.1-5.1-1.4h-10.7c-2.8,0-4.5,0.4-5.5,1.4c0,0.5,0.3,1,0.5,1.4h20.5
	C417,443.7,417.1,443.3,417.1,442.8L417.1,442.8z M413.1,446.1c1.8-0.3,3-1,3.6-1.8h-20.5c0.5,0.7,1.5,1.1,3,1.2L413.1,446.1
	L413.1,446.1z M390.3,447.6l4.3,0.5v-5.2h-9.4c-0.1,0.4,0,0.8,0,1.2C385.6,445.9,387.2,447,390.3,447.6L390.3,447.6z M571.2,449
	l1.9-0.1l-1.9-0.3 M573.1,448.8c5.9-0.7,8.7-2.8,8.4-6.1h-11.3l1,5.6L573.1,448.8L573.1,448.8z"
          />
          <path
            fill="none"
            d="M571.2,449l-0.1-0.5l-1-5.6H565c0,1.7-1.2,2.8-4,3.2l-13.9-0.5c-1.4-0.1-2.3-0.5-2.9-1.2
	c-0.3-0.4-0.5-0.8-0.5-1.4h-1.1v5.2l2.6,0.3l22.8,0.8L571.2,449L571.2,449z"
          />
          <path
            fill="none"
            d="M559.7,440.8h-9.9c-3.3,0.1-5.4,0.7-6.2,1.9c0,0.5,0.3,1,0.6,1.4c0.5,0.7,1.5,1.1,2.9,1.2l13.9,0.5
	c2.8-0.5,4-1.6,4-3.2C564.4,441.7,562.6,441.1,559.7,440.8L559.7,440.8z M533.2,442.9c-0.1,0.4,0,1,0,1.2c0.4,1.6,2.1,2.8,5.1,3.4
	l4.3,0.5v-5.2L533.2,442.9L533.2,442.9z M233.9,433h-3.7c-30.5,0.1-59.8-0.4-87.5-1.7c-31.4-1-56.5-2.9-75.5-6.1
	c5.5,5.8,11.6,9.6,18.2,11.6c4.7,1.5,6.3,2.8,4.8,3.6c-1.9,1.5-4.7,1.5-8.3,0c7,4.1,15.1,7.4,24.2,10h123.8c7.7,0,14-1.2,19.3-3.7
	l7.7-2.6h0.5l6.7-9.4c1.6-1.1,1.2-2.9-1.1-5.2C253.4,432,243.8,433.1,233.9,433L233.9,433z M307,433.7l0.1-5.9l-17.5,5
	c-5.5,1.4-8.5,2.6-8.9,3.6c-0.3,1.8,2.1,2.9,7.3,3l-0.8-2.8c-0.1-1,0.7-1.4,2.6-1.2H302c1.4-0.1,2.1,0.3,2.2,1c0.1,0.3,0,0.7,0,1
	v2.3h2.6L307,433.7L307,433.7z M27.8,281.7l-0.3,0.5c-0.5,2.6-0.8,5.4-0.8,8v0.8h-0.1v10l0.1,0.7H27v0.4c0.1,1.5,0.3,3.2,0.4,4.8
	l0.1,0.7v1l0.3,0.8v0.1l1.1,1.4h0.3v-0.4l-0.4-2.3c-0.3-2.1-0.5-4.1-0.7-6.1v-0.3h0.4l0.1-0.1l-0.1-5.2c0-1.5,0-3.2,0.1-4.5v-0.7
	c0-0.1,0-0.1-0.1-0.1h-0.3c-0.1,0-0.1-0.1-0.1-0.4l0.5-5.5v-0.7l-0.7-1.6v-0.4L27.8,281.7L27.8,281.7z M27.5,284.7l0.1,0.3l0.1,0.4
	v0.5c-0.1,1-0.3,1.9-0.3,2.9c-0.1,1-0.1,1.8-0.1,2.8v1h0.5v0.3c-0.1,2.1-0.1,4.3,0,6.3v0.5h-0.4c0-0.1,0,0-0.1,0l0.1,2.2
	c0.1,1.8,0.3,3.7,0.5,5.5v0.5l-0.5-0.7l-0.1-0.5V306c-0.1-2.1-0.3-4-0.4-6.1v0.4l-0.1-0.3h-0.1v-0.5c-0.1-2.2-0.1-4.3,0-6.2V293
	v-0.1h0.1v-0.3c0-2.6,0.3-5.1,0.5-7.7L27.5,284.7l-0.1-0.4v-0.1L27.5,284.7L27.5,284.7z"
          />
          <path
            fill="none"
            d="M27.6,285l-0.1-0.3v-0.4v0.5c-0.3,2.6-0.5,5.1-0.5,7.7v0.3h-0.1v7.3H27l0.1,0.3v0.1c0.1,1.9,0.3,4,0.4,6.1v0.7
	l0.1,0.6l0.5,0.7V308c-0.3-1.8-0.4-3.7-0.5-5.5l-0.1-2.2c0-0.1,0-0.1,0.1,0h0.4v-0.6c-0.1-2.2-0.1-4.3,0-6.3v-0.3h-0.6v-1
	c0-1,0-1.9,0.1-2.8c0.1-1,0.1-1.9,0.3-2.9v-0.5L27.6,285L27.6,285z M27.2,306.7v-0.3V306.7z"
          />
          <path
            fill="none"
            d="M28.1,307.8v0.4h0.1 M48.8,393.8c-1-2.9-2.1-5.9-3-9.2l-2.6-1.5l-2.6-1.5c1,2.9,1.9,5.8,2.9,8.4L48.8,393.8
	L48.8,393.8z"
          />
          <path
            fill="none"
            d="M54.3,407c-1.9-3.9-3.7-8.3-5.5-13.2l-5.2-3.9c4.1,11.6,8.7,20.8,13.5,27.6c6.1,9.2,14.3,16.8,24.6,22.8
	c3.6,1.5,6.3,1.5,8.3,0c1.5-0.8-0.1-2.1-4.8-3.6c-6.6-2.1-12.5-5.9-18.2-11.6C62.5,420.5,58.2,414.4,54.3,407L54.3,407z
	 M308.6,445.1l-0.3-0.8c-0.5-1-1.2-1.2-2.3-1.1l1.7,7c2.6,11,5.2,21.2,8.1,30.5c1.5,0,2.1-1.1,1.5-3.2c-2.8-8.1-5.4-17.2-7.6-27.4
	L308.6,445.1L308.6,445.1z M304.3,443.6v-6.3c0.1-0.4,0.1-0.7,0-1c-0.1-0.8-1-1.1-2.2-1H290c-1.8,0-2.6,0.4-2.6,1.2l0.8,2.8l1.4,4.8
	l1.8,5.9l1.6,5c-0.3-1.9-0.1-3.7,0.1-5c0.7-2.3,2.1-3.7,4.5-4.3L304.3,443.6L304.3,443.6z"
          />
          <path
            fill="none"
            d="M304.3,443.6l-6.6,2.3c-2.3,0.5-3.9,1.9-4.5,4.3c-0.4,1.4-0.4,3-0.1,5c2.8,8.5,5.6,15.5,8.5,20.9
	c1.8,4,4.1,5.8,7.3,5.6l6.9-1.1c-2.9-9.4-5.6-19.5-8.1-30.5l-1.6-7L304.3,443.6L304.3,443.6z M291.4,450.2l-1.8-5.9h-32.9
	c2.5,4.1,6.7,6.1,12.8,5.9L291.4,450.2L291.4,450.2z M818.5,244h-12.4l1.9,2.8c1.7,2.8,2.8,7.4,3.4,13.6l0.8,8.3
	c0.4,4.5,0.7,9.1,1,13.5l0.4,14.9l-0.4,13.3c-0.1,4.4-0.5,8.8-1,13.5l-0.8,8.3c-0.7,6.2-1.8,10.7-3.4,13.6l-1.9,2.8h12.4l1.1-10.6
	l1.7-34.9l-1.2-43.3C819.6,254.3,819.1,249.2,818.5,244L818.5,244z"
          />
          <path
            fill="none"
            d="M807.9,246.7L806,244l-5.6-8.3l0.3,2.2c5,5.9,7.8,16.2,8.7,30.7c-0.7,2.3-1.2,4.8-1.8,7.3
	c-0.7,3.4-1.2,6.9-1.5,10.3c-0.3,3.4-0.5,6.7-0.5,10.2c0,3.3,0.1,6.6,0.5,10c0.3,3.4,0.8,6.9,1.5,10.2c0.5,2.5,1.1,4.8,1.8,7.3
	c-0.8,14.4-3.7,24.8-8.7,30.7l-0.3,2.2l5.6-8.3l1.9-2.8c1.7-2.8,2.8-7.3,3.4-13.6l0.8-8.3c0.4-4.5,0.7-9.1,1-13.5l0.4-13.3
	l-0.4-14.9c-0.1-4.4-0.5-8.8-1-13.5l-0.8-8.3C810.7,254.2,809.6,249.6,807.9,246.7L807.9,246.7z"
          />
          <path
            fill="none"
            d="M807.6,275.9c0.5-2.5,1.1-5,1.8-7.3c-0.8-14.4-3.7-24.8-8.7-30.7l0.1,2.6c1.2,16,1.9,33.3,2.2,51.9v7.7
	c-0.3,18.6-1,35.9-2.2,51.9l-0.1,2.6c5-5.9,7.8-16.2,8.7-30.7c-0.7-2.3-1.2-4.8-1.8-7.3c-0.7-3.4-1.2-6.7-1.5-10.2
	c-0.3-3.3-0.5-6.7-0.5-10c0-3.4,0.1-6.7,0.5-10.2C806.4,282.8,806.9,279.3,807.6,275.9L807.6,275.9z M752.3,401.1l-7.4-5.6
	l-17.1,0.4l6.7,11.4h8.4c2.1-0.3,3.9,0.1,5.4,0.8l0.5,0.3c1.4,0.3,4.7-1.1,10-4.3l-6.3-2.9L752.3,401.1L752.3,401.1z"
          />
          <path
            fill="none"
            d="M734.7,407.3l27.8,30.1c15.8,0.1,24.5-6.7,25.7-20.2l-29.3-13.1c-5.4,3.2-8.8,4.7-10,4.3l-0.5-0.3
	c-1.5-0.8-3.3-1.1-5.4-0.8L734.7,407.3L734.7,407.3z M789.3,417.6l-1-0.7v0.3"
          />
          <path
            fill="none"
            d="M793.1,419.9l-3.7-2.3l-1-0.4c-1.4,13.6-9.9,20.4-25.7,20.2v7h1.4c13.1,0,21.5-4.3,25.3-12.8
	C790.6,428.5,791.8,424.5,793.1,419.9L793.1,419.9z M788.4,417.2v-0.3 M793.1,419.9h11.3l1.9-7.3h-11.1
	C794.4,415.9,793.7,418.3,793.1,419.9L793.1,419.9z"
          />
          <path
            fill="none"
            d="M804.3,419.9h-11.3c-1.2,4.5-2.5,8.4-3.9,11.7c-3.9,8.5-12.2,12.7-25.3,12.8h-27.8c-7.2-0.3-12.7,0.3-16.5,1.8
	l-6.5,1.5h52.4c13.9-0.5,23-2.8,27.1-6.6C797.6,437.7,801.4,430.5,804.3,419.9L804.3,419.9z M806.4,412.6
	c5.4-20.5,9.4-40.9,11.8-61.4h-13.3c-3.7,29.7-6.9,50.2-9.6,61.4H806.4L806.4,412.6z M752.7,191.3l-0.4,0.1l-7.4,5.6
	c6.2-0.1,9.6,1.5,10.5,4.8c3.7,17.9,5.9,40,7,66.3l0.3,7.4c0.1,7,0.3,14.2,0.3,21.2l-0.4,27.2c-1,26.4-3.3,48.6-7,66.3
	c-0.8,3.3-4.3,5-10.5,4.8l7.4,5.6l0.4,0.1c1.8-1.5,3.2-5.1,4.4-10.6c3.3-16.1,5.5-35.8,6.6-58.7l0.5-14.7l0.3-20.6
	c0-7-0.1-13.9-0.3-20.9l-0.5-14.9c-1.1-23.1-3.3-42.6-6.6-58.7C755.9,196.4,754.5,192.8,752.7,191.3L752.7,191.3z"
          />
          <path
            fill="none"
            d="M759.1,188.4l-6.3,2.9c1.8,1.5,3.2,5.1,4.4,10.6c3.3,16.1,5.5,35.8,6.6,58.7c0.4,0.5,0.8,1.4,1,2.2l0.1,0.5
	l0.5,15.8l-0.1-11l0.1,11c0.1,5.9,0.1,11.7,0.1,17.6l-0.1,16.8l-0.5,15.3l-0.1,0.5c-0.3,0.8-0.5,1.6-1,2.2
	c-1.1,23.1-3.3,42.6-6.6,58.7c-1.2,5.5-2.8,9.1-4.4,10.6l6.3,2.9l29.3,13.1v-0.1c3.7-17.2,6.5-37.3,8.4-60.1l0.4-4.8
	c1.2-16,1.9-33.3,2.2-51.9v-7.7c-0.3-18.6-1-35.9-2.2-51.9l-0.4-4.8c-1.8-22.8-4.7-42.9-8.4-60.1v-0.1L759.1,188.4L759.1,188.4z"
          />
          <path
            fill="none"
            d="M764.9,263c-0.3-0.8-0.5-1.6-1-2.2l0.5,14.9l-0.1-7.3l0.1,7.3c0.1,7,0.3,13.9,0.3,20.9l-0.3,20.6l-0.5,14.7
	c0.4-0.5,0.8-1.4,1-2.2l0.1-0.5l0.5-15.3l0.1-16.8c0-5.9,0-11.7-0.1-17.6l-0.5-15.8L764.9,263L764.9,263z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M788.4,175.3l-29.3,13.1 M806.4,179.7l-2.1-7.3 M806.4,179.7h-11.1
	 M795.3,179.7c-0.8-3.2-1.5-5.6-2.2-7.3 M793.1,172.5l-3.7,2.5 M762.7,155.1c15.8-0.1,24.5,6.7,25.7,20.2 M789.3,174.9l-1,0.6
	 M789.3,174.9l-1,0.4 M788.4,175.3v0.1 M788.4,175.5v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M713.3,144.8h52.4c13.9,0.6,23,2.8,27.1,6.6c4.8,3.4,8.7,10.5,11.7,21
	 M804.3,172.5h-11.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M793.1,172.5c-1.2-4.5-2.5-8.4-3.9-11.7c-3.9-8.4-12.2-12.7-25.3-12.7
	h-1.4 M762.7,148.1v7 M727.7,199.7l0.3-3 M728,196.7l17.1,0.4 M752.7,191.3c1.8,1.5,3.2,5.1,4.4,10.6c3.3,16.1,5.5,35.8,6.6,58.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M745,395.5c6.2,0.1,9.6-1.5,10.5-4.8c3.7-17.9,5.9-40,7-66.3l0.4-27.2
	c0-7.2-0.1-14.2-0.3-21.2l-0.3-7.4c-1-26.4-3.3-48.6-6.9-66.3c-0.8-3.3-4.3-5-10.5-4.8 M734.7,185.2v-0.1l27.9-30 M734.7,185.2
	l-6.7,11.4 M759.1,188.4l-6.3,2.9 M745,197.1l7.3-5.6l0.4-0.1 M759.1,188.4c-5.4-3.2-8.8-4.7-10-4.3l-0.6,0.3
	c-1.5,0.8-3.3,1-5.4,0.8l-8.4,0.1 M484.5,215.8l-55,0.1c-0.8,0-1.1-0.4-1.1-1.1v-3.7c0-0.7,0.4-1.1,1.1-1.1l98.5-0.7H712
	c0.8,0,1.1,0.4,1.1,1.1v4.1c-0.1,0.4-0.4,0.7-1,0.7l-53.1,0.1 M596.5,142.6h96c7.2,0.1,14,0.8,20.8,2.2 M713.3,144.8
	c2.2,0.4,4.4,1,6.5,1.5c3.9,1.5,9.4,2.1,16.5,1.8h26.4 M63.4,198.7c-1.8,0.6-3.7,2.8-5.5,6.6c-8.8,23.4-13.5,53.6-14.3,90.9
	c0.8,37.3,5.6,67.5,14.3,90.9c1.8,3.9,3.7,6.1,5.5,6.6l0.8,0.1c21.6,6.2,53.1,10.2,94.5,12h33.7c6.2,0.5,8.8-0.5,7.7-3.4l-4.8-18.6
	c-0.6-2.2-1.6-3.6-3.3-4.1L79.7,369.2l112.4,6.2h5.4c1.4,0,2.3,1.6,3.2,5l6.5,24.6c1.2,3.9,0.1,5.6-3.2,5.4
	c-59,0.8-107-3.2-144.2-11.6c-6.2-1.5-10.7-8.5-13.6-21.3c-6.9-23.5-10.7-49.1-11.3-76.9v-8.4c0.5-27.8,4.3-53.5,11.3-76.9
	c2.9-12.8,7.4-19.8,13.6-21.3c37.1-8.5,85.2-12.4,144.2-11.6c3.3-0.3,4.4,1.5,3.2,5.4l-6.5,24.6c-0.6,2.2-1.1,3.6-1.9,4.4
	c-0.4,0.4-0.8,0.6-1.2,0.6H192l-112.4,6.2L192,212.9c1.5-0.4,2.5-1.5,3-3.3l5.1-19.7c1.1-2.8-1.5-4-7.7-3.4h-33.7
	c-41.4,1.8-72.9,5.8-94.5,12L63.4,198.7L63.4,198.7z M67.1,167.2c-4.7,4.8-8.9,10.9-12.8,18.2c-1.9,3.9-3.7,8.3-5.5,13.2 M40.7,211
	c1-2.9,1.9-5.8,2.9-8.4 M43.3,209.5l-2.6,1.5 M45.8,207.8l-2.5,1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M43.6,202.6c4.1-11.6,8.7-20.8,13.5-27.6c6.1-9.2,14.3-16.8,24.6-22.8
	 M48.8,198.7l-5.2,3.9 M48.8,198.7c-1,2.9-2.1,5.9-3,9.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M81.7,152.1c3.6-1.5,6.3-1.5,8.3,0c1.5,0.8-0.1,2.1-4.8,3.4
	c-6.6,2.1-12.5,5.9-18.2,11.6 M81.7,152.1c7-4.1,15.1-7.4,24.2-10"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M105.9,142.1c-29.2,1.5-48,9.6-56.4,24.1c-12.7,19.9-21.3,44.8-25.7,74.6
	c-1.5,14-2.5,29.7-2.9,47v16.6c0.4,17.3,1.4,33,2.9,47c4.5,29.7,13.1,54.6,25.7,74.6c8.5,14.6,27.4,22.6,56.4,24.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.3,159.5c-30.5-0.1-59.8,0.4-87.5,1.6c-31.4,1-56.5,2.9-75.5,6.1
	 M315.6,111.8l-6.9-1c-3-0.3-5.5,1.7-7.3,5.6c-2.9,5.4-5.8,12.4-8.5,20.9 M309.7,142.3c2.2-10.2,4.8-19.3,7.6-27.4
	c0.4-2.1,0-3-1.5-3.2 M256.7,148.2l-7.7-2.6c-5.2-2.5-11.7-3.6-19.3-3.6H105.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.9,163c-9.5-2.5-19.1-3.6-29-3.4h-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.3,433c-9.6-17.7-16.6-38.8-20.9-63.4c-3.9-21.7-5.6-46.2-5.4-73.3
	c-0.3-27.1,1.5-51.6,5.4-73.3c4.4-24.6,11.3-45.8,20.9-63.4 M305.8,149.3c1.1,0.1,1.9-0.1,2.3-1.1 M309.7,142.3l-1.1,5.1l-0.3,0.8
	 M306.8,152.8l0.3,6.1 M288.2,153.2l-0.8,2.8c-0.1,1,0.7,1.4,2.6,1.2h12.1c1.4,0.1,2.1-0.3,2.2-1c0.1-0.3,0-0.7,0-1v-2.3
	 M304.3,152.8v-3.9 M306.8,152.8h-2.5 M304.3,148.9l1.5,0.4 M307,158.8l0.3,5.9 M304.3,148.9l-6.6-2.3c-2.3-0.6-3.9-1.9-4.5-4.3
	c-0.4-1.4-0.4-3-0.1-4.9 M315.6,111.8c-2.9,9.4-5.6,19.5-8.1,30.5l-1.6,7 M292.9,137.4l-1.5,5 M257.2,148.2l6.7,9.4
	c1.6,1.1,1.2,2.9-1.1,5.2 M256.7,148.2h0.5 M564.8,196.7H414.9c-24.9-0.3-47.6-3.2-68.4-8.7l-1-0.3c-28.1-9.1-55.3-17.3-81.7-24.5
	l-1-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M307.3,164.8l-17.5-5c-5.5-1.4-8.5-2.6-8.9-3.6c-0.3-1.8,2.1-2.9,7.3-3
	 M289.6,148.2l-1.4,5 M291.4,142.3h-22c-6.1-0.1-10.3,1.9-12.8,5.9 M257.2,148.2h32.3 M291.4,142.3l-1.8,5.9 M334.1,187.6l-31.2-9.9
	c-23.1-7.6-42.8-12.5-59-14.9c-14.2,32.1-21.6,76.5-22.1,133.4c0.6,57,8,101.4,22.1,133.6c16.2-2.2,35.9-7.2,59-14.9l31.2-9.9
	 M394.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3 M394.5,149.6h1.1 M385.2,148.2c-0.1,0.4-0.1,0.8,0,1.4h9.4 M396.2,148.2
	c-0.3,0.4-0.5,0.8-0.5,1.4 M395.6,149.6c1,1,2.8,1.4,5.5,1.4h10.7c3.3-0.3,5-0.7,5.1-1.4 M417.1,149.8c0-0.6-0.1-1-0.4-1.4
	 M423.3,143.4l-3.2-0.1c-9.5-0.1-18,0.1-25.6,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M416.7,148.2c-0.5-0.8-1.8-1.5-3.6-1.8l-13.9,0.6c-1.4,0.1-2.5,0.6-3,1.2
	 M396.2,148.2h20.5 M394.5,149.6v-5.2 M727.7,199.7H569.4c-58.7,0.8-115.1,0.4-169.3-0.8c-18.8-0.4-38.4-3.4-58.5-8.9l-7.4-2.3
	 M422.2,149.8h11.3c0-0.6,0-1-0.1-1.5 M433.7,158.8h-1.9 M445.4,148.2v4.8 M445.4,153.1v5.8 M460.7,158.8h-15.3 M463,158.8h-2.3
	 M433.3,148.2h12.1 M445.4,158.8h-11.7 M433.3,148.2c-0.5-2.8-4-4.4-10-4.8 M423.3,143.4l-1.1,6.3 M459.9,185.8h2.1 M443.5,185.8
	h16.4 M461.9,185.8c-2.8-0.7-3.7-2.2-3-4.7l4.1-22.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M459.9,185.8c-2.6-0.6-3.7-2.2-3.2-5.1l3.9-21.9 M433.7,158.8l-1.1,21.7
	c0.3,3.3-2.2,5.1-7.8,5.2 M424.8,185.8h-0.5c-32.9-1.1-58.1-4.3-75.5-9.2l-41.4-11.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M431.8,158.8l-1,21c-0.1,3.7-2.2,5.8-6.1,5.9 M442.8,196.1l0.7-10.3
	 M443.5,185.8h-18.7 M493.6,237.4v-17.6c0-2.5,0.4-3.9,1.7-4 M495.1,215.8h-10.6 M484.5,215.8c1.1,0,1.6,1.5,1.6,4.3v17.3
	 M445.4,158.8l-1.9,27 M445.4,153.1l-39.2-0.3h-99.5 M417.1,149.8h5.1 M573.1,143.6l-1.9-0.1 M596.5,142.6h-12.9l-4.5,0.1l-5.9,0.8
	 M571.2,144v-0.6 M573.1,143.6l-1.9,0.4 M570.6,160.1l-5.8-0.4l-56.8-0.8h-45 M565.1,149.8h5.1 M543.7,149.6c1,1.2,3,1.9,6.2,1.9
	h9.9c2.8-0.1,4.5-0.8,5.2-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.4,166l3.3-3.9c2.6-5-0.8-7.4-10.6-7.4l-120.5-1.6 M571.2,143.4
	l-3.2-0.1l-22.8,0.8 M573.4,166c2.1-3.2,1.1-5.1-2.8-5.9 M570.2,149.8h11.3c0.3-3.3-2.5-5.4-8.4-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M570.6,160.1c2.1,1.1,1.8,3.3-0.8,6.5l-10.9,11.6
	c-3.9,4-13.1,6.5-27.4,7.7 M571.2,144l-1,5.8 M543.7,149.6c0-0.6,0.3-1,0.5-1.4c0.5-0.7,1.5-1.1,2.9-1.2l13.9-0.6
	c2.8,0.6,4,1.6,4,3.3 M545.2,144.1c-2.6-1.4-5.6-2.1-8.8-1.8H309.7 M542.5,144.4l-4.3,0.6c-3,0.6-4.7,1.6-5.1,3.3 M545.2,144.1
	l-2.6,0.3 M542.5,149.6h1.1 M533.2,148.2c-0.1,0.4-0.1,0.8,0,1.4h9.4 M542.5,149.6v-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M531.4,185.8h5.6c10.5-0.7,17.6-2.5,21.7-5.4c5.8-5.2,10.7-10,14.6-14.4
	 M564.8,196.7c0-2.5,1.9-6.6,5.8-12.4l0.3-0.4c8.1-11,16.1-20.4,23.7-28.1c1.8-2.3,2.5-6.7,1.9-13.2 M762.7,155.1l-125.5,0.4
	c-6.1-0.1-10.6,0.4-13.6,1.9c-0.1,0.1-0.3,0.1-0.5,0.3c-11.4,6.6-22,14.4-31.9,23.5c-3.2,3-1.1,4.5,6.3,4.7l137.2-0.7 M647.9,215.4
	c1.1,0.3,1.7,1.9,1.7,4.7v17.3 M657,237.4v-17.6c0-2.5,0.5-4,1.9-4.4 M658.9,215.4h-11 M647.9,215.4l-152.8,0.4 M461.9,185.8h69.5
	 M445.4,148.2h87.8 M308.3,148.2h76.9 M431.8,158.8H307 M657,237.4v6.9 M649.6,244.3v-6.9 M657,273.7v-29.4 M649.6,273.7v-29.4
	 M649.6,318.7v-6.7 M657,311.9v6.7 M657,311.9v-31.4 M649.6,311.9v-31.4 M649.6,280.6v-6.9 M657,273.7v6.9 M573.4,426.5
	c-3.9-4.4-8.7-9.1-14.6-14.4c-4-2.9-11.3-4.7-21.7-5.4h-5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M531.4,406.7c14.4,1.1,23.5,3.7,27.4,7.7l10.9,11.6
	c2.6,3.3,2.9,5.5,0.8,6.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M570.6,432.3l-5.8,0.5l-56.8,0.8h-45"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M445.4,439.5l120.5-1.6c9.8,0,13.3-2.5,10.6-7.4l-3.3-3.9 M564.8,395.9
	c0,2.5,1.9,6.6,5.8,12.4l0.3,0.4c8.1,11,16.1,20.4,23.7,28.1c1.8,2.3,2.5,6.7,1.9,13.2 M573.4,426.5c2.1,3.2,1.1,5.1-2.8,5.9
	 M657,348.3v6.9 M649.6,355.1v-6.9 M647.4,377c1.5-0.3,2.2-1.9,2.2-4.7V355 M659.2,377c-1.5-0.4-2.2-1.9-2.1-4.4V355 M659.2,377
	h-11.8 M657,348.3v-29.4 M649.6,348.3v-29.4 M762.7,437.4L637.2,437c-6.1,0.1-10.6-0.4-13.6-1.9l-0.5-0.3
	c-11.4-6.6-22-14.4-31.9-23.5c-3.2-3-1.1-4.5,6.3-4.7l137.2,0.7 M486.1,244.3v-6.9 M493.6,237.4v6.9 M486.1,244.3v29.4 M493.6,244.3
	v29.4 M486.1,237.4h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.4,0.8,1.9s1.2,0.8,2.1,0.8h35.5 M431.3,239
	v114.6c-0.1,7.4-3,11.1-8.8,11.1L378,366c-6.3-0.3-9.9-2.9-10.6-7.8c-1.1-17.2-1.6-36-1.5-56.4v-10.6c-0.1-20.5,0.4-39.2,1.5-56.4
	c0.7-5,4.1-7.6,10.6-7.8l44.4,1.2C428.2,227.8,431.1,231.5,431.3,239L431.3,239z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M422.5,229.5l-44.3-1.2c-5.2,0.3-8.3,2.3-8.9,6.3
	c-1.1,17.2-1.5,35.9-1.5,56.4l0.1,5.2c0,1.8-0.1,3.6-0.1,5.4c-0.1,20.4,0.4,39.2,1.5,56.3c0.7,4,3.6,6.1,8.9,6.3l44.3-1.2
	c4.5,0,7-3.2,7-9.4V239C429.5,232.8,427.1,229.7,422.5,229.5L422.5,229.5z M486.1,273.7h-35.5c-0.8,0-1.5,0.3-2.1,0.8
	s-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8h35.5 M486.1,311.9h-35.5c-0.8,0-1.5,0.3-2.1,0.8
	c-0.5,0.5-0.8,1.2-0.8,2.1v1.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8h35.5 M493.6,311.9v6.7 M486.1,318.7v-6.7 M486.1,280.6v31.4
	 M493.6,280.6v31.4 M493.6,273.7v6.9 M486.1,280.6v-6.9 M343.5,353.2l10.9-0.8c0.4,0,0.5,0.1,0.5,0.5v3.9c0,0.4-0.1,0.5-0.5,0.5
	l-10.9,0.8c-0.4,0-0.5-0.1-0.5-0.5v-3.9C342.9,353.5,343.1,353.2,343.5,353.2L343.5,353.2z M564.8,395.9H414.9
	c-24.9,0.3-47.6,3.2-68.4,8.7l-1,0.3c-28.1,9.1-55.3,17.3-81.7,24.6l-1,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M334.1,404.9l7.4-2.3c20.1-5.5,39.6-8.5,58.5-8.9
	c54.2-1.4,110.6-1.6,169.3-0.8h158.3 M493.6,348.3v6.9 M486.1,355.1v-6.9 M493.6,355.1v17.6c0,2.6,0.4,3.9,1.7,4 M486.1,355.1v17.3
	c0,2.8-0.5,4.3-1.6,4.3 M495.1,376.6h-10.6 M486.1,348.3h-35.5c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.2-0.8,1.9v1.1c0,0.8,0.3,1.5,0.8,2.1
	s1.2,0.8,2.1,0.8h35.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M484.5,376.6l-55-0.1c-0.8,0-1.1,0.4-1.1,1.1v3.7c0,0.7,0.4,1.1,1.1,1.1
	l98.5,0.7H712c0.8,0,1.1-0.4,1.1-1.1v-4.1c-0.1-0.4-0.4-0.7-1-0.7l-52.8-0.1 M459.9,406.7h-16.4 M461.9,406.7h-2.1 M424.8,406.7
	h-0.5c-32.9,1.1-58.1,4.3-75.5,9.2l-41.4,11.8 M433.7,433.7h-1.9 M424.8,406.7c5.5,0.3,8.1,1.9,7.8,5.2l1.1,21.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M431.8,433.7l-1-21c-0.1-3.7-2.2-5.8-6.1-5.9 M463,433.7l-4.1-22.3
	c-0.7-2.5,0.3-4.1,3-4.7 M460.7,433.7h-15.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M459.9,406.7c-2.6,0.5-3.7,2.2-3.2,5.1l3.9,21.9 M463,433.7h-2.3
	 M443.5,406.7l-0.7-10.3 M443.5,406.7h-18.7 M445.4,433.7l-1.9-27 M433.7,433.7h11.7 M486.1,318.7v29.6 M493.6,318.7v29.6
	 M445.4,444.1v-4.7 M433.3,444.1c-0.5,2.8-4,4.4-10,4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M422.2,442.8h11.3c0,0.5,0,1-0.1,1.5 M423.3,449l-1.1-6.2 M433.3,444.1
	h12.1 M395.6,442.9c1-1,2.8-1.4,5.5-1.4h10.7c3.3,0.3,5,0.7,5.1,1.4 M417.1,442.8c0,0.5-0.1,1-0.4,1.4 M416.7,444.1
	c-0.5,0.8-1.8,1.5-3.6,1.8l-13.9-0.5c-1.4-0.1-2.5-0.5-3-1.2 M385.2,444.1c0.4,1.6,2.1,2.8,5.1,3.4l4.3,0.5 M396.2,444.1
	c-0.3-0.4-0.5-0.8-0.5-1.4 M394.5,442.9h-9.4c-0.1,0.4,0,0.8,0,1.2 M395.6,442.9h-1.1 M394.5,442.9v5.2 M394.5,448.1
	c7.6,0.8,16.1,1.1,25.6,1l3.2-0.1 M416.7,444.1h-20.5 M445.4,439.5l-39.2,0.1h-99.5 M422.2,442.8h-5.1 M573.1,448.8l5.9,0.8l4.5,0.3
	h12.9 M571.2,449l1.9-0.1 M573.1,448.8c5.9-0.7,8.7-2.8,8.4-6.1h-11.3 M571.2,448.5l1.9,0.3 M571.2,449v-0.4 M565.1,442.8
	c-0.7-1.1-2.5-1.6-5.2-1.9H550c-3.3,0.1-5.4,0.7-6.2,1.9 M570.2,442.8h-5.1 M543.7,442.9c0,0.5,0.3,1,0.5,1.4
	c0.5,0.7,1.5,1.1,2.9,1.2l13.9,0.6c2.8-0.5,4-1.6,4-3.2 M545.2,448.4l22.8,0.7l3.2-0.1 M571.2,448.5l-1-5.8 M543.7,442.9h-1.1
	 M542.5,442.9h-9.4c-0.1,0.4,0,0.8,0,1.2 M542.5,448.1l2.6,0.3 M533.2,444.1c0.4,1.6,2.1,2.8,5.1,3.4l4.3,0.5 M542.5,442.9v5.2
	 M309.7,450.2h226.7c3.2,0.3,6.1-0.4,8.8-1.8 M649.6,237.4h-156 M533.2,444.1h-87.8 M461.9,406.7h69.5 M647.4,377l-152.1-0.4
	 M445.4,433.7v5.8 M649.6,311.9h-156 M493.6,318.7h156 M493.6,348.3h156 M649.6,355.1h-156 M493.6,273.7h156 M649.6,280.6h-156
	 M649.6,244.3h-156 M262.9,429.6c-9.5,2.5-19.1,3.6-29,3.4h-3.7 M307,433.7l0.3-5.9 M307.3,427.8l-17.5,5c-5.5,1.4-8.5,2.6-8.9,3.6
	c-0.3,1.8,2.1,2.9,7.3,3 M334.1,187.6c-8.9,23.5-12.9,59.7-12.2,108.7c-0.7,49,3.3,85.3,12.2,108.7 M27.8,282.5v-1.1l-0.1,0.1
	l-0.1,0.5c-0.5,2.6-0.8,5.4-0.8,8v0.8h-0.1v10l0.1,0.7H27v0.4c0.1,1.5,0.3,3.2,0.4,4.8l0.1,0.7v1.1l0.3,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M27.8,309.5l1.1,1.5h0.3v-0.4l-0.4-2.3c-0.3-2.1-0.5-4.1-0.7-6.1v-0.3h0.4
	l0.1-0.1l-0.1-5.2c0-1.5,0-3.2,0.1-4.5v-0.7c0-0.1,0-0.1-0.1-0.1h-0.3c-0.1,0-0.1-0.1-0.1-0.4l0.5-5.5v-0.7l-0.7-1.6v-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M27.2,306.7v0.3l0.1,0.5l0.5,0.7v-0.5c-0.3-1.8-0.4-3.7-0.5-5.5l-0.1-2.2
	c0-0.1,0-0.1,0.1,0h0.4v-0.5c-0.1-2.2-0.1-4.3,0-6.3v-0.3h-0.3v-1c0-1,0-1.9,0.1-2.8c0.1-1,0.1-1.9,0.3-2.9v-0.5l-0.1-0.4l-0.1-0.3
	v-0.4v0.6c-0.3,2.6-0.5,5.1-0.5,7.7v0.3H27v7.3h0.1l0.1,0.3v0.1 M27.2,306.7v-0.3V306.7z M27.8,282.5c0.7-14.6,2.1-27,4-37.6
	c2.8-12.8,5.6-24.1,8.8-34.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M43.3,209.5c-3.7,11-7,22.8-9.6,35.6c-2.6,13.6-4.1,30.7-4.5,51.2
	c0.3,20.5,1.8,37.6,4.5,51.2c2.8,12.8,5.9,24.6,9.6,35.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M45.8,384.6c-1.8-5.6-3.4-12-5.2-18.7c-5-22.4-7.4-45.5-7.7-69.5
	c0.3-24.1,2.8-47.3,7.7-69.7c1.7-6.9,3.4-13.1,5.2-18.7 M48.8,393.8c-1-2.9-2.1-5.9-3-9.2 M67.1,425.2c-4.7-4.8-8.9-10.9-12.8-18.2
	c-1.9-3.9-3.7-8.3-5.5-13.2 M48.8,393.8l-5.2-3.9 M45.8,384.6l-2.5-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M81.7,440.3c-10.3-6.1-18.6-13.6-24.6-22.8c-4.8-6.9-9.4-16.1-13.5-27.6
	 M40.7,381.4c-3.2-9.9-6.1-21.2-8.8-34c-2.1-10.6-3.4-23.2-4.1-38 M43.3,383.1l-2.6-1.7 M40.7,381.4c1,2.9,1.9,5.8,2.9,8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.3,433c-30.5,0.1-59.8-0.4-87.5-1.7c-31.4-1-56.5-2.9-75.5-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M67.1,425.2c5.5,5.8,11.6,9.6,18.2,11.6c4.7,1.5,6.3,2.8,4.8,3.6
	c-1.9,1.5-4.7,1.5-8.3,0 M81.7,440.3c7,4.1,15.1,7.4,24.2,10 M309.7,450.2l-1.1-5.1l-0.3-1 M305.8,443c1.1-0.1,1.9,0.1,2.3,1.1
	 M304.3,439.6v4 M288.2,439.3l-0.8-2.8c-0.1-1,0.7-1.4,2.6-1.2h12.1c1.4-0.1,2.1,0.3,2.2,1c0.1,0.3,0,0.7,0,1v2.3 M292.9,455.1
	c-0.3-1.9-0.1-3.7,0.1-5c0.7-2.3,2.2-3.7,4.5-4.3l6.6-2.3 M304.3,443.6l1.5-0.5 M305.8,443l1.6,7c2.6,11,5.2,21.2,8.1,30.5
	 M306.8,439.6h-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292.9,455.1c2.8,8.5,5.6,15.5,8.5,20.9c1.8,4,4.1,5.8,7.3,5.6l6.9-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315.6,480.6c1.5,0,2.1-1.1,1.5-3.2c-2.8-8.1-5.4-17.2-7.6-27.4
	 M291.4,450.2l1.5,5 M288.2,439.3l1.4,4.8 M291.4,450.2l-1.8-6.1 M256.7,444.1c2.5,4.1,6.7,6.1,12.8,5.9h22 M257.2,444.1l6.7-9.4
	c1.6-1.1,1.2-2.9-1.1-5.2 M256.7,444.1h0.5 M289.6,444.1h-32.3 M105.9,450.3h123.8c7.7,0,14-1.2,19.3-3.7l7.7-2.6 M306.8,439.6
	l0.3-5.9 M307,433.7h124.8 M308.3,444.1h76.9 M818.5,244l-0.3-2.9 M818.5,244h-12.4 M806.1,244l1.9,2.8c1.7,2.8,2.8,7.4,3.4,13.6
	l0.8,8.3c0.4,4.5,0.7,9.1,1,13.5l0.4,14.9l-0.4,13.3c-0.1,4.4-0.5,8.8-1,13.5l-0.8,8.3c-0.7,6.2-1.8,10.7-3.4,13.6l-1.9,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M800.6,354.6c5-5.9,7.8-16.2,8.7-30.7c-0.7-2.3-1.2-4.8-1.8-7.3
	c-0.7-3.4-1.2-6.7-1.5-10.2c-0.3-3.3-0.5-6.7-0.5-10c0-3.4,0.1-6.7,0.5-10.2c0.3-3.4,0.8-6.9,1.5-10.3c0.5-2.5,1.1-5,1.8-7.3
	c-0.8-14.4-3.7-24.8-8.7-30.7 M818.5,348.5l1.1-10.6l1.7-34.9l-1.2-43.3c-0.4-5.2-1-10.5-1.5-15.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M800.6,237.9l0.1,2.6c1.2,16,1.9,33.3,2.2,51.9v7.7
	c-0.3,18.6-1,35.9-2.2,51.9l-0.1,2.6 M806.1,244l-5.6-8.3l0.1,2.2 M788.4,416.9c3.7-17.2,6.5-37.3,8.4-60.1l0.4-4.8
	c1.2-16,1.9-33.3,2.2-51.9v-7.7c-0.3-18.6-1-35.9-2.2-51.9l-0.4-4.8c-1.8-22.8-4.7-42.9-8.4-60.1 M765.4,279.3l-0.5-15.8l-0.1-0.5
	c-0.3-0.8-0.5-1.6-1-2.2 M764.3,268.3l0.1,7.2 M763.8,260.8l0.7,14.7 M765.4,279.3c0.1,5.9,0.1,11.7,0.1,17.6l-0.1,16.8l-0.5,15.3
	l-0.1,0.5c-0.3,0.8-0.5,1.6-1,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.4,275.5c0.1,7,0.3,13.9,0.3,20.9l-0.3,20.6l-0.5,14.7 M765.3,268.3
	l0.1,11 M818.2,241.1h-13.3c-3.7-29.7-6.9-50.2-9.6-61.4 M727.7,392.8c-0.3-10-0.5-19.9-0.7-29.9l-0.3-23.2
	c-0.1-14.7,0-29.3,0.1-43.6c-0.1-14.3-0.3-28.8-0.1-43.5l0.3-23.2c0.1-9.9,0.4-19.8,0.7-29.7 M657,244.3h42.8c0.8,0,1.5-0.3,2.1-0.8
	c0.5-0.6,0.8-1.2,0.8-1.9v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.6-1.2-0.8-2.1-0.8H657 M657,280.6h42.8c0.8,0,1.5-0.3,2.1-0.8
	c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8H657 M657,318.7h42.8c0.8,0,1.5-0.3,2.1-0.8
	c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.1-0.8H657 M657,355.1h42.8c0.8,0,1.5-0.3,2.1-0.8
	c0.5-0.5,0.8-1.2,0.8-2.1v-1.1c0-0.8-0.3-1.4-0.8-1.9c-0.5-0.5-1.2-0.8-2.1-0.8H657 M752.7,401.2l-0.4-0.1l-7.3-5.6 M763.8,331.8
	c-1.1,23.1-3.3,42.6-6.6,58.7c-1.2,5.5-2.8,9.1-4.4,10.6 M752.7,401.2l6.3,2.9 M728,395.9l-0.3-3 M734.7,407.3l-6.7-11.4
	 M734.7,407.3l27.9,30.1 M759.1,404.1c-5.4,3.2-8.8,4.7-10,4.3l-0.6-0.3c-1.5-0.8-3.3-1.1-5.4-0.8h-8.4 M745,395.5l-17.1,0.4
	 M818.2,351.4l0.3-2.9 M806.1,348.5h12.4 M800.6,354.6l-0.1,2.2l5.6-8.3 M789.3,417.6l-1-0.7 M793.1,419.9l-3.7-2.3 M788.4,416.9
	v0.3 M788.4,417.2v-0.3 M788.4,417.2l1,0.4 M804.3,419.9h-11.3 M795.3,412.6c-0.8,3.2-1.5,5.6-2.2,7.3 M793.1,419.9
	c-1.2,4.5-2.5,8.4-3.9,11.7c-3.9,8.5-12.2,12.7-25.3,12.8h-1.4 M806.4,412.6c5.4-20.5,9.4-40.9,11.8-61.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M818.2,351.4h-13.3c-3.7,29.7-6.9,50.2-9.6,61.4 M806.4,412.6h-11.1
	 M804.3,419.9l2.1-7.3 M759.1,404.1l29.3,13.1 M762.7,444.4v-7 M713.3,447.7h52.4c13.9-0.5,23-2.8,27.1-6.6
	c4.8-3.4,8.7-10.5,11.7-21"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M713.3,447.7l6.5-1.5c3.9-1.5,9.4-2.1,16.5-1.8h26.4 M596.5,449.9h96
	c7.2-0.1,14-0.8,20.8-2.2 M762.7,437.4c15.8,0.1,24.5-6.7,25.7-20.2 M728,196.7H564.8 M818.2,241.1c-2.5-20.5-6.5-40.9-11.8-61.4
	 M728,395.9H564.8"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M230.3,433
		c0,0-27.8-42.5-26.3-136.7c0,0-4-81.2,26.3-136.7c0,0-47.5-0.1-83.2,1.5c0,0-48,1-79.8,6.3c0,0-6.1,5.9-12.8,18.2
		c0,0-7.3,14.4-14,42.9c0,0-7.3,30.3-7.3,68c0,0-0.1,21.7,3.3,45.3c0,0,2.9,24.5,12.5,52.3c0,0,4.7,16.9,18.3,31.5
		c0,0,22.6,4.1,60,5.5c0,0,43.9,1.8,62.5,2.1C189.6,432.7,211.6,433.1,230.3,433z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M334.1,404.9
		c0,0-11.3-24.3-12.2-84.5v-24.3v-23.4c0,0,0-53.1,12.2-85.3l-45.3-14.3c0,0-23-7.2-45-10.5c0,0-11.7,23.1-17.7,68.4
		c0,0-3.6,20.2-4.3,65.1c0,0-0.4,30.7,4.3,65.2c0,0,3.6,36,17.7,68.2c0,0,21.2-2.8,48.3-11.4L334.1,404.9z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M394.5,198.6
		c-38-2.1-60.4-11.1-60.4-11.1c-12.4,31.8-12.2,85.3-12.2,85.3v23.4v24.6c0.7,58.2,12.2,84.1,12.2,84.1c14.6-5.2,36.2-8.7,36.2-8.7
		c14.9-2.8,46.4-3,46.4-3c38.1-0.6,68.6-0.8,68.6-0.8c34.1-0.3,69.1,0.1,69.1,0.1l93.1,0.1h80.3l-0.8-32.9l-0.3-31.2l0.1-32.6
		l-0.1-36.6l0.4-31.1l0.7-28.9h-35.4h-42.8h-53.8l-36.7,0.1l-31.1,0.3c-35.2,0.3-77.2-0.3-77.2-0.3
		C417.9,199.4,394.5,198.6,394.5,198.6z M422.5,229.5c0,0,7-0.8,7,9.4v57.4v58.1c0,0,0.1,8.1-6.3,8.5l-44,1.2c0,0-9.1,0.7-9.9-6.3
		c0,0-2.1-30.5-1.4-61.6c0,0-0.7-28.9,1.4-61.5c0,0,0.4-6.1,8.9-6.3L422.5,229.5z"
              id="Roof 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M423.3,362.8l-44,1.2
		c0,0-9.1,0.7-9.9-6.3c0,0-2.1-30.5-1.4-61.6c0,0-0.7-28.9,1.4-61.5c0,0,0.4-6.1,8.9-6.3l44.3,1.2c0,0,7-0.8,7,9.4v57.4v58.1
		C429.6,354.3,429.8,362.3,423.3,362.8z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M752.7,401.2
		c0,0,3.2-2.2,5.1-13.6c0,0,4.5-22.8,6.1-55.9c0,0,1.1-1.2,1.1-2.8c0,0,1-14.9,0.7-32.7c0,0,0-24.2-0.7-32.7c0,0,0-1.1-1.1-2.8
		c0,0-0.5-17.1-3-36.2c0,0-1.5-12.4-4-23.9c0,0-1.7-7.7-4.4-9.4l35.9-16c0,0,4.7,20.8,7.3,48c0,0,2.3,22.4,3.2,47.6
		c0,0,0.7,16.1,0.6,25.2c0,0-0.1,16-0.7,28.2c0,0-0.8,19.5-2.2,36.3c0,0-1,11.7-3,27.4c0,0-1.7,13.8-5.1,29.2L752.7,401.2z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M804.9,351.4
		c0,0-6.5,54.2-11.7,68.6l-4.7-2.9c0,0,5.8-22.4,9.2-73c0,0,1.7-22.8,1.7-47.9c0,0-0.3-31.2-2.8-62.6c0,0-2.1-28.5-8.3-58.3l4.7-2.9
		c0,0,2.9,7.3,5.5,24.9l6.2,43.7l-0.6,0.3l3.7,5.4c0,0,1.8,2.9,2.8,8.7c0,0,2.5,14,2.8,40.7c0,0,0.1,23-3,42.5c0,0-1,5-2.3,7
		l-3.6,5.4L804.9,351.4z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv4DrTop);
