import React, { Component } from 'react';
import Icon from '../../ui/icons';
import { GREY_06, CYAN_01 } from '../../../constants/colors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { toast } from 'react-toastify';
import ToastContent from '../../ui/toastContent';
import { handleSideBarStatus } from '../../../../../actions';
import '../../../styles/form/accordion.css';

class AccordionItem extends Component {
  state = {
    menuOpenStatus: false,
  };

  handleMenuOpenStatus = () => {
    this.setState({ menuOpenStatus: !this.state.menuOpenStatus });
  };

  render() {
    const {
      coreValidationError,
      section,
      showSideBar,
      handleSideBarStatus,
      visited,
    } = this.props;
    const currentPath = window.location.pathname;
    const isActive = !!(
      currentPath === section.path ||
      (section.subSections && find(section.subSections, { path: currentPath }))
    );

    return (
      <details>
        <summary
          className={`accordion-item flex ${
            currentPath.includes(section.path) && 'selected-menu'
          } `}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              onClick={() => {
                if (coreValidationError) {
                  toast(
                    <ToastContent status="error" text={coreValidationError} />,
                    {
                      className: 'review-toast',
                      toastId: coreValidationError,
                    }
                  );
                } else {
                  toast.dismiss();
                  this.props.navigate(section.path);
                  handleSideBarStatus(!showSideBar);
                }
              }}
              className={` w-100 ${
                currentPath.includes(section.path)
                  ? 'selected-accordion-item'
                  : 'non-selected-accordion-item'
              }`}
            >
              <Icon
                isAccordian // just for wheels icon :(
                isActive={isActive}
                name={section.icon}
                color={`${
                  currentPath.includes(section.path) ? 'white' : GREY_06
                }`}
              />
              <span className="ml-4.5">{section.title}</span>
            </div>
            {visited.includes(section.section) && (
              <div style={{ marginRight: 10 }}>
                <Icon
                  name="Checkmark"
                  color={`${isActive ? 'white' : CYAN_01}`}
                />
              </div>
            )}
          </div>
        </summary>
      </details>
    );
  }
}
AccordionItem.propTypes = {
  handleSideBarStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { coreValidationError, showSideBar, item, visited } = state.core;
  return { coreValidationError, showSideBar, item, visited };
};

export default connect(mapStateToProps, { handleSideBarStatus })(AccordionItem);
