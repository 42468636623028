import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv2DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M696.1,206.2v3c0,0.7,0.3,1,1,1l2.9,0.3l7,0.7l2.2,0.3v-7.3l-12,1.2l-0.5,0.1
	C696.3,205.3,696.1,205.6,696.1,206.2L696.1,206.2z M696.1,206.6h-2.2v2.1h2.2"
          />
          <path
            fill="#656565"
            d="M707.1,211.1l-7-0.7l-6.6,15c-0.8,1.9-1.4,4.7-1.7,8.4l-2.8,33.8h4.1v1.6l2.9-34.8c0.3-3.9,0.8-6.5,1.5-7.8
	l5.9-11.3C704.6,213.2,705.8,211.7,707.1,211.1L707.1,211.1z"
          />
          <path
            fill="#656565"
            d="M749.6,227.3c-0.3-3.9-2.3-6.5-6.2-8c-4.7-1.1-10.3-1.8-16.9-1.9l-2.2-0.1c-6.5-0.4-12.1-0.3-17.1,0.4
	c-4.1,1.1-6.3,3.6-6.9,7.4l-4.5,108v0.5l18-0.8c3.4,0,5.8,1.9,6.6,6.1l0.1,4.3h0.5c6.5,0.4,12.1,0.3,17.1-0.4
	c4.1-1.1,6.3-3.6,6.9-7.4L749.6,227.3L749.6,227.3z M693.2,270.1v-2.6h-6.9c-1,0-1.5,0.3-1.8,1.1c-0.1,0.3-0.3,0.7-0.3,1.1l0.5,29.6
	c0.1,0.6,0.4,1.1,0.8,1.4c0.4,0.3,0.8,0.5,1.4,0.4h6.1v-31H693.2L693.2,270.1z M692.2,305.9v-4.7h-3.4v6.1 M690.6,314.8v-3.6
	c0-0.7-0.3-1-1-1h-0.8v5.2h1.2C690.3,315.4,690.6,315.1,690.6,314.8L690.6,314.8z"
          />
          <path
            fill="#656565"
            d="M691.7,316.5l-1.7-1.1l-0.4,0.1h-0.8v10.9l0.4-0.1l2.5-1.4 M695.7,333.5l-4.4,0.1c-1.1,0-1.9,0.1-2.5,0.4
	c-0.5,0.3-1,0.7-1,1.2h25c3.4,0.3,5,2.6,4.7,6.6v14.6c-0.1,2.3-1.5,5.5-4,9.8c-0.8,1.1-2.2,1.6-4,1.6h1.1h-1.1l-20.9-0.5
	c0,1.2,0.8,1.9,2.5,2.1l20.6,0.3c1.1,0.1,1.9,0,2.8-0.3c0.5-0.3,0.8-0.5,1.2-1.1l0.5-0.8l1.9-3.2c1.9-3.2,2.9-5.4,2.9-6.6l-0.4-14.6
	l-0.1-4.3c-1-4-3.2-6.1-6.6-6.1L695.7,333.5L695.7,333.5z"
          />
          <path
            fill="#656565"
            d="M691.8,328.6v-2.3h-2.6l-0.4,0.1v2.8 M717.4,356.5v-14.6c0.3-4.1-1.2-6.3-4.7-6.6h-60.3
	c0,1.8,1.5,5.6,4.4,11.3l9.5,17.9l0.5,1.1c0.7,1,2.3,1.5,5.2,1.5l13.1,0.3l3.2,0.1l20.9,0.5c1.8-0.1,3.2-0.7,4-1.6
	C715.9,362.2,717.3,358.9,717.4,356.5L717.4,356.5z M708.3,354.3v-14c0-0.3,0.1-0.5,0.5-0.5h7c0.3,0,0.6,0.1,0.6,0.5v14
	c0,0.3-0.1,0.6-0.5,0.6h-7C708.6,354.9,708.3,354.7,708.3,354.3L708.3,354.3z"
          />
          <path
            fill="#656565"
            d="M520.5,180.2c-6.2-0.1-9.2,1.9-9.4,6.3v49.1c0.1,3.6,1.4,6.1,4,7.7c2.3,1.5,5.9,2.2,10.6,1.9h112.8
	c4.3-0.3,7.4-1,9.4-2.3c2.2-1.4,3-3.4,2.6-5.9l-4.4-48.1c-0.4-5.6-3-8.5-7.8-8.8H520.5V180.2z M512.6,186.5c0.1-3.3,2.8-5,7.8-5H638
	c4,0.3,6.1,2.8,6.5,7.4l4.4,48.1c0.3,1.9-0.4,3.4-1.9,4.4c-1.8,1.1-4.7,1.8-8.7,2.1H525.5c-4.3,0.3-7.6-0.3-9.8-1.6
	c-2.1-1.4-3.2-3.4-3.3-6.5v-49h0.2V186.5z M669.7,269.6c-2.3-2.3-5.2-3.6-8.5-3.6s-6.2,1.2-8.5,3.6c-2.3,2.3-3.6,5.2-3.6,8.5
	s1.2,6.2,3.6,8.5c2.3,2.3,5.2,3.6,8.5,3.6s6.2-1.2,8.5-3.6c2.3-2.3,3.6-5.2,3.6-8.5C673.2,274.8,672.1,271.9,669.7,269.6
	L669.7,269.6z M661.3,269.4c2.3,0,4.4,0.8,6.1,2.5c1.7,1.6,2.5,3.7,2.5,6.1c0,2.3-0.8,4.4-2.5,6.1c-1.7,1.6-3.7,2.5-6.1,2.5
	c-2.3,0-4.4-0.8-6.1-2.5c-1.7-1.6-2.5-3.7-2.5-6.1c0-2.3,0.8-4.4,2.5-6.1C656.9,270.3,658.8,269.4,661.3,269.4L661.3,269.4z"
          />
          <path
            fill="#656565"
            d="M682.5,191.3L682.5,191.3l0.7,5.1l0.5-0.7c0.3-0.6,0.5-1.1,0.5-1.9c0-0.7-0.1-1.4-0.5-1.9
	C683.3,191.6,682.9,191.3,682.5,191.3L682.5,191.3z M568.8,302.2c-6.6,0.4-12,1.6-16.2,4c-0.4,0.3-0.8,0.5-1.1,0.8
	c-0.8,0.5-1.5,1.1-2.1,1.7l-4.7,4.8l-4.8,5.9c-7.2,9.1-14.3,18.8-21.3,29.4l-5.2,8.1h3.6c0.7,0,1,0.3,1,1l8.3,0.4
	c1.5-3.7,3.3-7.2,5.5-10.6c0.8-1.2,1.8-2.6,2.8-3.9c1.4-1.8,2.9-3.7,4.5-5.4l1.5-1.5c12.4-12.4,27.4-18.6,45-18.6s32.6,6.2,45,18.6
	l1.5,1.5c1.7,1.8,3.2,3.6,4.5,5.4l0.3,0.3c3.2,4.1,5.6,8.5,7.6,13.2l2.2,6.2l19.8,0.8l-9.5-17.9c-2.9-5.8-4.4-9.5-4.4-11.3h2.8
	l-1.4-3.4l-1.9-0.1l-7.2-16c-1.1-2.5-2.2-4.5-3.6-6.3c-3.7-4.8-8.7-7.6-15-8.1C609.8,300,590.7,300.3,568.8,302.2L568.8,302.2z"
          />
          <path
            fill="#656565"
            d="M171.8,271.1l0.3,0.4c0.1,0.1,0.1,0.1,0.4,0.1h1.1c0.3,0,0.6-0.1,0.6-0.5l0.1-0.4l-0.4-0.3v0.3
	c0,0.3-0.3,0.5-0.6,0.5h-1.1L171.8,271.1L171.8,271.1z"
          />
          <path
            fill="#656565"
            d="M180.8,267.6l-0.3-0.4v0.3l-0.6,3.2c0,0.3-0.3,0.5-0.6,0.5h-1.1l-0.3-0.1l0.3,0.4c0.1,0.1,0.1,0.1,0.4,0.1
	h1.1c0.3,0,0.6-0.1,0.6-0.5l0.6-3.2L180.8,267.6L180.8,267.6z M192.2,297c-10.9,0.1-18.7,1.1-23.8,2.8c-4.5,1.5-9.2,5.5-14.2,12
	c-0.6,1-1.4,1.4-2.5,1.2h-9.5l-2.6-0.3l-5.1-1.6l-1,2.3h0.4l6.9,1.8h9.6l1.9,1l-11.1,44.2h5.2c1.6-6.2,4.1-11.8,7.7-17.2
	c0.8-1.2,1.8-2.6,2.8-3.9c1.4-1.8,2.9-3.7,4.5-5.4l1.5-1.5c12.4-12.4,27.4-18.6,45-18.6s32.6,6.2,45,18.6l1.5,1.5
	c1.6,1.8,3.2,3.6,4.5,5.4l0.3,0.3c4.8,6.5,8.3,13.5,10.3,21l28.3,0.1l-1.5-2.9c-0.3-0.7-0.1-1,0.4-1h14.7l-2.1-2.8h-16
	c-11.6-17.7-23.1-33.7-34.7-48.1c-4.4-5.9-10.3-8.9-17.3-9.1L192.2,297L192.2,297z"
          />
          <path
            fill="#656565"
            d="M209,377.3h0.7l-0.3-0.3h-0.1l-0.3,0.1 M207.4,377h-0.1c-0.1,0-0.1,0-0.1,0.1v0.1h0.6v-0.1L207.4,377
	L207.4,377z M210.9,377.6v0.3c0,0.1,0.1,0.1,0.1,0.1h0.1c0.1,0,0.3,0,0.3-0.1h0.1v-0.4l-0.1-0.1h-0.1c-0.1,0-0.3,0-0.3,0.1
	L210.9,377.6L210.9,377.6z M184.3,353.5c-1.8,1.8-3.3,3.6-4.5,5.5h0.1l8.9,1.9l1.4-1.5c2.6-2.6,5.4-4.4,8.5-5.6l1-9.1
	C194,346.1,188.9,349.1,184.3,353.5z M152.6,316.2h-12.8l-8.3-2.9l-4,15.7l-0.4,1.9l5.4,1.9l-7,30.7h15.1l0.8-3.2"
          />
          <path
            fill="#656565"
            d="M143.1,363.5c0.6,0.1,0.8-1,0.8-3.2h-2.6l-0.8,3.3L143.1,363.5L143.1,363.5L143.1,363.5z M127.1,330.9
	l0.4-1.9h-23.2l-5.4,4.5c-1.2,1.1-2.1,2.6-2.5,4.5v16.1c0.3,2.3,1.1,4.7,2.6,7.3l4.5,7.8h4.1l-2.5-5.9c-1.4-3-2.2-5.9-2.2-8.4v-16.8
	c0.1-2.2,1.1-4,2.6-5.4c1.2-1.2,2.6-1.9,3.9-1.8L127.1,330.9L127.1,330.9z"
          />
          <path
            fill="#656565"
            d="M132.5,332.9l-5.4-1.9h-17.9c-1.2,0-2.5,0.5-3.9,1.8c-1.5,1.4-2.3,3.2-2.6,5.4V355c0.1,2.5,0.8,5.4,2.2,8.4
	l2.5,5.9h10.5c1.5,0,2.5-0.4,3-1.4l0.7-1.7l3.6-2.8c-2.9,0.4-4.5,0.1-5.2-0.7l-1.9-5.4c-1.1-3.2-1.7-6.1-1.7-8.7l-0.1-0.4v-0.1
	c-0.3-7.2,0-11,0.8-11.8c1.5-2.2,3.6-3.3,6.2-3.6h9.2V332.9L132.5,332.9z M112.9,341.8c0.3,1.5,0.6,3.2,0.6,5.1s-0.1,3.7-0.6,5.4
	l-0.4,1.4c-0.6,1.8-1.4,2.8-2.2,2.8c-0.8,0-1.7-1-2.2-2.8s-1-4-1-6.6s0.3-4.8,1-6.6c0.6-1.8,1.4-2.8,2.2-2.8c0.8,0,1.7,1,2.2,2.8
	L112.9,341.8L112.9,341.8z"
          />
          <path
            fill="#656565"
            d="M108.1,340.3c-0.6,1.8-1,4-1,6.6s0.3,4.8,1,6.6c0.6,1.8,1.4,2.8,2.2,2.8c0.8,0,1.7-1,2.2-2.8l0.4-1.4
	l-0.4-0.8c-0.6-1.2-0.8-2.6-0.8-4.4c0-1.6,0.3-3.2,0.8-4.4l0.4-0.8l-0.4-1.5c-0.6-1.8-1.4-2.8-2.2-2.8
	C109.5,337.4,108.7,338.5,108.1,340.3L108.1,340.3z M132.5,332.9h-8.8c-2.8,0.3-4.8,1.4-6.2,3.6c-0.8,0.8-1.1,4.7-0.8,11.8v0.1
	l0.1,0.4c0,2.6,0.6,5.5,1.7,8.7l1.9,5.4c0.7,0.8,2.3,1.1,5.2,0.7L132.5,332.9L132.5,332.9z M104.3,329c-1.8-0.1-3.4,0.4-5.1,1.8
	l-4,3.7c-1.1,1-1.8,2.2-1.9,3.3v13.9c0.1,3.9,0.7,6.7,1.7,8.8l4.3,7c0.6,1.2,1.7,1.9,3.2,1.8h1.1l-4.5-7.8c-1.5-2.5-2.3-5-2.6-7.3
	v-16c0.4-1.9,1.2-3.4,2.5-4.5L104.3,329L104.3,329z M636.6,343.9c-1.4-1.8-2.9-3.6-4.5-5.4l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6
	s-32.6,6.2-45,18.6l-1.5,1.5c-1.7,1.8-3.2,3.6-4.5,5.4c-1,1.2-1.8,2.5-2.8,3.9c-2.2,3.3-4,6.9-5.5,10.6c-2.9,7.3-4.4,15.1-4.4,23.7
	c0,12.8,3.3,24.2,9.8,34.3c0.8,1.2,1.8,2.6,2.8,3.9c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1c12.2,11.7,26.8,17.6,43.9,17.6
	c17.1,0,31.8-5.9,43.9-17.6l1.1-1.1c2.2-2.2,4.3-4.5,6.1-6.9l0.3-0.3c8.3-10.9,12.2-23.5,12.2-37.7v-2.6c-0.1-5.5-1-10.7-2.5-15.7
	l-2.2-6.2c-1.9-4.7-4.4-9.1-7.6-13.2L636.6,343.9L636.6,343.9z M627.2,338.1l2.6,2.6c1.5,1.7,3,3.3,4.4,5.1c1,1.2,1.9,2.6,2.8,4
	c6.1,9.5,9.2,20.2,9.2,32.3c0,12-3,22.7-9.2,32.2l-2.8,4c-1.8,2.3-3.7,4.4-5.8,6.6l-3.6,3.3c-11.1,9.6-24.2,14.4-39.2,14.4
	s-28.2-4.8-39.2-14.4c-1.2-1.1-2.3-2.1-3.6-3.3c-2.1-2.1-4-4.3-5.8-6.6c-1.9-2.6-3.7-5.4-5.2-8.1c-4.4-8.4-6.7-17.7-6.7-28.1
	c0-10.3,2.2-19.7,6.7-28.1c1.5-2.9,3.3-5.5,5.2-8.1c1.4-1.8,2.8-3.4,4.4-5.1l2.6-2.6c11.6-11,25.5-16.5,41.5-16.5
	C601.7,321.6,615.6,327.1,627.2,338.1L627.2,338.1z"
          />
          <path
            fill="#656565"
            d="M629.8,340.7l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6
	c-1.5,1.7-3,3.4-4.4,5.1c-1.9,2.6-3.7,5.4-5.2,8.1c-4.4,8.4-6.7,17.7-6.7,28.1c0,10.3,2.2,19.7,6.7,28.1c1.5,2.8,3.3,5.5,5.2,8.1
	c1.8,2.3,3.7,4.4,5.8,6.6c1.1,1.1,2.3,2.2,3.6,3.3c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4l3.6-3.3
	c2.1-2.1,4-4.3,5.8-6.6l2.8-4c6.1-9.5,9.2-20.2,9.2-32.2c0-12.1-3-22.8-9.2-32.3c-0.8-1.4-1.8-2.6-2.8-4
	C632.8,344,631.3,342.3,629.8,340.7L629.8,340.7z M625.8,382c0,6.2-1.2,11.8-3.6,17.1c-1.9,4.1-4.7,8-8.1,11.4
	c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6c-5.2,0-10.2-0.8-14.6-2.6c-5.1-1.9-9.6-5-13.8-9.1c-2.9-2.9-5.2-5.9-7-9.2
	c-3.2-5.8-4.8-12.2-4.8-19.3c0-11.1,4-20.6,11.8-28.5c7.8-7.8,17.3-11.8,28.5-11.8s20.6,4,28.5,11.8
	C621.9,361.5,625.8,370.8,625.8,382L625.8,382z"
          />
          <path
            fill="#656565"
            d="M609.4,358.2c-4.4-4.5-9.5-7.4-15.3-8.8l1,9.1c1.9,0.8,3.7,1.8,5.5,3.2l12.2,0.5
	C611.9,360.8,610.6,359.4,609.4,358.2L609.4,358.2z M589.5,378.3c-1.1-1.1-2.3-1.5-3.7-1.5c-1.5,0-2.8,0.5-3.7,1.5
	c-1.1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.8,1.5,3.9c1.1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5c1.1-1.1,1.7-2.3,1.7-3.9
	C591,380.5,590.4,379.2,589.5,378.3L589.5,378.3z M583.3,380.6h0.7v1.4l-0.1,0.3c-0.1,0.1-0.3,0.3-0.5,0.3H583l-0.7-0.1
	c-0.3-0.1-0.4-0.3-0.4-0.4v-0.6h0.5v0.4l0.3,0.1h0.3l0.1-0.3v-1.1L583.3,380.6L583.3,380.6z M585.1,381.3L585.1,381.3
	c0.4,0,0.5,0.1,0.6,0.4l0.1,0.3v0.3h-1.2v0.3l0.1,0.1h0.1l0.1-0.1h0.5l-0.1,0.1l-0.3,0.1l-0.6,0.1h-0.3c-0.3-0.1-0.4-0.1-0.5-0.4
	l-0.1-0.4l0.1-0.3v-0.1l0.3-0.3l0.4-0.1H585.1L585.1,381.3z M586.8,381.3h0.4c0.3,0,0.4,0.1,0.6,0.4l0.1,0.3v0.3h-1.2v0.1
	c0,0.1,0.1,0.1,0.3,0.1h0.1l0.1-0.1h0.5l-0.1,0.1l-0.4,0.1l-0.4,0.1h-0.3c-0.3-0.1-0.4-0.3-0.5-0.4V382c0-0.1,0-0.4,0.1-0.5l0.3-0.3
	L586.8,381.3L586.8,381.3z M589.7,381.6l0.1,0.3v0.5l-0.1,0.3l-0.1,0.1h-0.7l-0.1-0.1v0.5h-0.5v-2.1h0.5v0.1l0.1-0.1l0.3-0.1h0.5
	C589.6,381.4,589.7,381.4,589.7,381.6L589.7,381.6z"
          />
          <path
            fill="#656565"
            d="M585.1,381.6L585.1,381.6c-0.2,0-0.2,0-0.2,0.1v0.1h0.5v-0.1L585.1,381.6L585.1,381.6z M587,381.6L587,381.6
	l-0.2,0.1v0.1h0.6 M589.2,382.4l0.1-0.4v-0.1l-0.1-0.1H589c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3v0.3l0.1,0.1h0.1L589.2,382.4z
	 M577.2,349.4c-5.8,1.4-10.9,4.4-15.4,8.9l-1.5,1.6l12.1,0.6c1.2-0.8,2.5-1.4,3.9-1.9L577.2,349.4L577.2,349.4z M478.3,183.9
	c1.1-0.1,1.9,0.1,2.3,0.7c0.4,0.7,0.5,1.8,0.4,3.3l-1.4,46.5c0,3.6-2.2,5.8-6.9,6.7c-3.2,0.6-6.5,0.8-10,1l-71.4,0.7v1.9l71.5-0.7
	c3.6-0.1,7-0.4,10.3-1c5.9-1.1,8.7-4,8.5-8.7l1.4-46.4c0.3-2.2,0-3.7-0.8-4.7c-0.8-1.1-2.2-1.6-4.1-1.5l-75.2,1.2
	c-4.4,0.1-7.3,1.5-8.7,4.4l-17.3,31.9h2.2l16.8-31.1c1.1-2.1,3.4-3.2,7-3.3L478.3,183.9L478.3,183.9z"
          />
          <path
            fill="#656565"
            d="M488.8,266.3c0-1-0.4-1.9-1.1-2.6s-1.6-1.1-2.6-1.1l-0.8,0.1c-0.7,0.1-1.2,0.4-1.8,1
	c-0.7,0.7-1.1,1.6-1.1,2.6s0.4,1.9,1.1,2.6c0.6,0.5,1.2,1,2.1,1.1h0.5c1,0,1.9-0.4,2.6-1.1C488.5,268.2,488.8,267.2,488.8,266.3
	L488.8,266.3z M487.7,266.3c0,0.7-0.3,1.4-0.8,1.8c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.4-0.3-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8
	c0-0.7,0.3-1.2,0.7-1.8c0.5-0.5,1.1-0.8,1.8-0.8c0.7,0,1.2,0.3,1.8,0.8C487.5,264.9,487.7,265.6,487.7,266.3L487.7,266.3z"
          />
          <path
            fill="#656565"
            d="M484.4,262.7l-3,0.1c-0.7,0.1-1.2,0.4-1.8,0.7h-22.3c-0.7,0-1.2,0.3-1.8,0.7c-0.4,0.5-0.7,1.1-0.7,1.8v0.1
	c0,0.7,0.3,1.2,0.7,1.6c0.5,0.5,1.1,0.7,1.8,0.7H479c0.4,0.4,1.2,0.7,2.3,1l3.3,0.3c-0.8-0.1-1.5-0.4-2.1-1.1
	c-0.7-0.7-1.1-1.7-1.1-2.6s0.4-1.9,1.1-2.6C483.1,263.1,483.7,262.8,484.4,262.7L484.4,262.7z M478.4,264.2c0.4,0,0.7,0.1,1,0.5
	s0.4,0.8,0.4,1.4c0,0.5-0.1,1-0.4,1.4s-0.5,0.5-1,0.5h-20.9c-0.5,0-1-0.1-1.4-0.5s-0.5-0.8-0.5-1.4s0.1-1,0.5-1.4s0.8-0.5,1.4-0.5
	H478.4L478.4,264.2z"
          />
          <path
            fill="#656565"
            d="M479.4,264.8c-0.3-0.4-0.5-0.5-1-0.5h-20.9c-0.5,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4s0.1,1,0.5,1.4
	s0.8,0.5,1.4,0.5h20.9c0.4,0,0.7-0.1,1-0.5s0.4-0.8,0.4-1.4S479.7,265.2,479.4,264.8z"
          />
          <path
            fill="#656565"
            d="M459.2,262.7h-2.5c-1,0-1.6,0.3-2.3,1c-0.7,0.7-1,1.5-1,2.3c0,1,0.3,1.6,1,2.3c0.7,0.7,1.5,1,2.3,1h2.6
	c0.8,0,1.5-0.3,2.1-0.7h-4c-0.7,0-1.2-0.3-1.8-0.7c-0.4-0.4-0.7-1.1-0.7-1.6v-0.1c0-0.7,0.3-1.2,0.7-1.8c0.6-0.4,1.1-0.7,1.8-0.7
	h4.1C460.8,263,460.2,262.7,459.2,262.7L459.2,262.7z M487.9,280c0.5-0.5,0.8-1.2,0.8-2.2c0-0.8-0.3-1.5-0.8-2.1
	c-0.6-0.5-1.2-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.2,0.8c-0.6,0.5-0.8,1.2-0.8,2.1s0.3,1.5,0.8,2.2c0.5,0.5,1.2,0.8,2.2,0.8
	C486.7,281,487.4,280.6,487.9,280L487.9,280z M486.6,277.1c0.1,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.7c-0.3,0.3-0.4,0.3-0.8,0.3
	c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.8c0.3-0.1,0.4-0.3,0.7-0.3
	C486.1,276.9,486.3,277,486.6,277.1L486.6,277.1z"
          />
          <path
            fill="#656565"
            d="M486.8,278c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.1-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3
	c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3C486.7,278.4,486.8,278.2,486.8,278
	L486.8,278z"
          />
          <path
            fill="#FFFFFF"
            d="M676.5,334.1v1.2h11.1c0.1-0.5,0.4-1,1-1.2H676.5z"
          />
          <path
            fill="#6B9BD2"
            d="M470.3,346.3c-2.1,0.8-4.4,1.5-7.2,2.1C465.7,348.1,468,347.4,470.3,346.3L470.3,346.3z"
          />
          <path fill="#FFFFFF" d="M451.8,356.9v-7.6h-74.7v7.6" />
          <path
            fill="#FFFFFF"
            d="M496.9,173v-1.1L391.4,174l-29,53.2l-5.5,10c-1.2,2.2-2.3,4.7-3.3,7.8l-0.7,2.6c-1.2,4.8-2.2,10.7-2.8,17.6
	l-0.3,2.9v52c0-0.3,0.1-0.4,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.7c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v0.1c0.5-0.3,1-0.4,1.6-0.5
	h0.3l1.4-0.1h1.1l10.6,1.2c1.1,0.1,1.6,0.7,1.6,1.5v6.6c0,0.8-0.5,1.4-1.6,1.5l-10.6,1.2h-1.1l-1.4-0.1h-0.3l-1.7-0.5v0.8
	c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3h-0.7c-0.3,0-0.4-0.1-0.7-0.3c0.8,6.1,2.6,10,5.8,12c3.3,3.4,10.2,5.1,20.9,4.7h75
	c4.4,0,8.1-0.4,11.6-1c2.6-0.5,5.1-1.1,7.2-2.1l2.2-1.1c5.5-3.2,10.6-8.7,15.3-16.5c4.7-7.6,7.4-13.5,8.1-17.9v-47.5L496.9,173
	L496.9,173z M482.2,183.3c0.8,1,1.1,2.5,0.8,4.7l-1.4,46.4c0.1,4.7-2.6,7.6-8.5,8.7c-3.3,0.6-6.7,0.8-10.3,1l-71.5,0.7
	c-0.3,2.3-1.2,3.6-2.9,3.3h-12.8v4.7c0,0.7-0.3,1-1,1h-1.2l0.5,0.8h1.2c0.7,0,1,0.4,0.8,1.2l-0.3,4.7c0,0.3-0.1,0.5-0.3,0.7
	l-0.3,0.1l-0.4,0.5c-0.4,1.2-1.1,2.2-2.1,3.2c-1.6,1.6-3.7,2.6-6.1,2.6c-2.3,0-4.4-0.8-6.2-2.6c-1.6-1.6-2.5-3.7-2.5-6.1
	s0.8-4.4,2.5-6.2c1.6-1.6,3.7-2.5,6.2-2.5l1.6,0.1v-6.9l-0.8-0.7c-1-1-1.5-2.5-1.4-4.3v-11.7c0.1-1.4,1-3,2.8-5.1
	c1.9-2.1,4.1-3,6.3-2.9h1.6l17.3-31.9c1.4-2.8,4.3-4.3,8.7-4.4l75.2-1.2C480.1,181.7,481.5,182.2,482.2,183.3L482.2,183.3z
	 M465,260.2c1.5-1.5,3.3-2.2,5.4-2.2s3.9,0.7,5.2,2.2c1,1,1.6,2.1,1.9,3.3h2.1c0.5-0.3,1.1-0.5,1.8-0.7l3-0.1l0.8-0.1
	c1,0,1.9,0.4,2.6,1.1s1.1,1.6,1.1,2.6s-0.4,1.9-1.1,2.6s-1.6,1.1-2.6,1.1h-0.5l-3.3-0.3c-1.1-0.3-1.9-0.5-2.3-1h-1.9
	c-0.4,0.8-0.8,1.5-1.5,2.2c-1.5,1.5-3.2,2.2-5.2,2.2c-2.1,0-3.9-0.7-5.4-2.2c-0.7-0.7-1.2-1.4-1.5-2.2h-2.1
	c-0.5,0.5-1.2,0.7-2.1,0.7h-2.6c-1,0-1.6-0.3-2.3-1c-0.7-0.7-1-1.4-1-2.3s0.3-1.6,1-2.3c0.7-0.7,1.5-1,2.3-1h2.6
	c0.8,0,1.5,0.3,2.2,0.8h1.6C463.3,262.3,464,261.2,465,260.2L465,260.2z M488.8,278c0,0.8-0.3,1.5-0.8,2.2c-0.6,0.5-1.2,0.8-2.2,0.8
	c-0.8,0-1.5-0.3-2.2-0.8c-0.6-0.5-0.8-1.2-0.8-2.2c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,2.2-0.8c0.8,0,1.5,0.3,2.2,0.8
	C488.5,276.3,488.8,277.1,488.8,278L488.8,278z M367.4,272.6c1.1,0.1,1.6,0.7,1.6,1.5v6.6c0,0.8-0.5,1.4-1.6,1.5l-10.6,1.2h-1.1
	l-1.4-0.1H354l-1.6-0.5v0.8c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3h-0.7c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7v-11.7
	c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.7c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v0.1c0.5-0.3,1-0.4,1.6-0.5h0.3l1.4-0.1
	h1.1L367.4,272.6L367.4,272.6z"
          />
          <path
            fill="#6B9BD2"
            d="M379.1,219.4h-0.4h0.3 M375.1,261.5h0.1l-0.4,0.1v0.3"
          />
          <path fill="#6B9BD2" d="M374.7,262l0.4-0.5l-0.3,0.4" />
          <path
            fill="#EBEBEB"
            d="M693.7,270.1h-0.5v28.2h0.5c0-0.1,0.1-0.4,0.3-0.5l0.1-0.1v-26.8l-0.1-0.1
	C693.9,270.5,693.7,270.4,693.7,270.1L693.7,270.1z"
          />
          <path
            fill="#656565"
            d="M709.1,211.2c0,0.1,0,0.3,0.1,0.3l0.3,0.1l0.3-0.1l0.1-0.3V204l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1
	c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3V211.2L709.1,211.2z M708.3,340.8v13.5c0,0.3,0.1,0.5,0.5,0.5h6.5v-13.5c0-0.3-0.1-0.5-0.5-0.5H708.3
	z"
          />
          <path
            fill="#656565"
            d="M708.3,340.4v0.5h6.5c0.3,0,0.6,0.1,0.6,0.5V355h0.5c0.3,0,0.5-0.1,0.5-0.5v-14c0-0.3-0.1-0.5-0.5-0.5h-7
	C708.6,339.9,708.3,340,708.3,340.4z"
          />
          <path
            fill="#D2D3D4"
            d="M151.7,297.4c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3s1.9,1.2,3,1.2s2.2-0.4,3-1.2
	c0.8-0.8,1.2-1.9,1.2-3c0-1.2-0.4-2.2-1.2-3C154.1,297.8,153,297.4,151.7,297.4L151.7,297.4z"
          />
          <path
            fill="none"
            d="M589.7,381.6l-0.3-0.3h-0.5l-0.3,0.1l-0.1,0.1v-0.1H588v2.1h0.5V383l0.1,0.1h0.7l0.1-0.1l0.1-0.3v-0.5
	L589.7,381.6L589.7,381.6z"
          />
          <path
            fill="none"
            d="M589.2,382.4l-0.3,0.1h-0.1l-0.1-0.1v-0.3l0.1-0.3c0.1-0.1,0.1-0.1,0.3-0.1h0.1l0.1,0.1v0.1L589.2,382.4
	L589.2,382.4z M586.8,381.3c-0.1,0-0.3,0-0.4,0.1l-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.6v0.3c0.1,0.3,0.3,0.4,0.6,0.4h0.3l0.4-0.1
	l0.4-0.1l0.1-0.1h-0.5l-0.1,0.1H587c-0.1,0-0.3-0.1-0.3-0.1v-0.1h1.2v-0.3l-0.1-0.3c-0.1-0.3-0.3-0.3-0.6-0.4L586.8,381.3
	L586.8,381.3z"
          />
          <path
            fill="none"
            d="M587,381.6l0.3,0.3h-0.6v-0.1l0.1-0.1 M585.1,381.6l0.3,0.1v0.1h-0.7v-0.1c0-0.1,0.1-0.1,0.1-0.1H585.1
	L585.1,381.6z"
          />
          <path
            fill="none"
            d="M585.1,381.3h-0.3l-0.4,0.1l-0.3,0.3v0.1l-0.1,0.3l0.1,0.4c0.1,0.3,0.3,0.3,0.5,0.4h0.3l0.5-0.1l0.3-0.1
	l0.1-0.1h-0.6l-0.1,0.1H585l-0.1-0.1v-0.3h1.2V382l-0.1-0.3c-0.1-0.3-0.3-0.3-0.5-0.4L585.1,381.3L585.1,381.3z M583.3,380.6v1.5
	l-0.1,0.3h-0.3l-0.3-0.1v-0.4h-0.7v0.5l0.4,0.4l0.7,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.3l0.1-0.3V381L583.3,380.6L583.3,380.6z
	 M207.4,377l0.3,0.1v0.1h-0.6v-0.1c0-0.1,0.1-0.1,0.1-0.1H207.4L207.4,377z M204.1,377.3v0.5c0.1,0.1,0.3,0.3,0.4,0.4l0.7,0.1h0.3
	l0.6-0.3l0.1-0.3v-1.4h-0.7v1.5l-0.1,0.3h-0.3l-0.3-0.1v-0.4L204.1,377.3L204.1,377.3z M210.9,377.6l0.1-0.3
	c0.1-0.1,0.1-0.1,0.3-0.1h0.1l0.1,0.1v0.4h-0.1c0,0.1-0.1,0.1-0.3,0.1H211c-0.1,0-0.1,0-0.1-0.1V377.6L210.9,377.6z"
          />
          <path
            fill="none"
            d="M211.3,376.7l-0.3,0.1l-0.1,0.1v-0.3h-0.6v2.1h0.6v-0.5l0.1,0.1h0.7l0.1-0.1l0.1-0.3v-0.5l-0.1-0.3
	c-0.1-0.1-0.1-0.1-0.3-0.3L211.3,376.7L211.3,376.7z M209,377.3v-0.1l0.3-0.1h0.1l0.3,0.3L209,377.3 M208.1,377.2
	c-0.1-0.3-0.3-0.3-0.6-0.4h-0.4l-0.4,0.1c-0.1,0.1-0.3,0.1-0.3,0.3v0.1l-0.1,0.3l0.1,0.4c0.1,0.3,0.3,0.3,0.6,0.4h0.3l0.6-0.1
	c0.1,0,0.3-0.1,0.3-0.1l0.1-0.1h-0.6l-0.1,0.1h-0.1l-0.1-0.1v-0.3h1.2v-0.3L208.1,377.2L208.1,377.2z"
          />
          <path
            fill="none"
            d="M208.8,376.9l-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.6v0.3c0.1,0.3,0.3,0.4,0.6,0.4h0.3l0.4-0.1l0.4-0.1l0.1-0.1h-0.6
	l-0.1,0.1h-0.1c-0.1,0-0.3-0.1-0.3-0.1v-0.1h1.2v-0.3l-0.1-0.3c-0.1-0.3-0.3-0.3-0.6-0.4L208.8,376.9L208.8,376.9z M693.7,299.3v-1
	 M693.2,299.3h0.5 M693.7,298.3h-0.5 M693.7,270.1c0,0.1,0.1,0.4,0.3,0.5l0.1,0.1v26.8l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5
	 M693.7,269.2v1 M693.7,270.1h-0.5 M693.7,269.2h-0.5"
          />
          <path
            fill="none"
            d="M693.7,299.3c0,0.1,0.1,0.4,0.3,0.6l0.1,0.1v1.2l0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1l0.3-0.1l0.1-0.3V300l0.1-0.1
	l0.3-0.5v-1c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1V271l0.1-0.1l0.3-0.5v-1.2c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-0.7l-0.1-0.3l-0.3-0.1
	c-0.1,0-0.1,0-0.3,0.1l-0.1,0.3v0.7l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v0.3 M696.8,205.2c-0.4,0.1-0.5,0.4-0.5,1v0.4 M696.8,205.2
	l0.4-0.1h0.1 M697.3,205.1l-0.5,0.1 M696.1,208.6v0.4c0,0.7,0.3,1,1,1l2.9,0.3 M696.1,206.6h-2.2v2.1h2.2 M696.1,208.6v-2.1
	 M707.1,211.1l2.1,0.1 M709.1,211.2c0,0.1,0,0.3,0.1,0.3l0.3,0.1l0.3-0.1l0.1-0.3V204l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1
	c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3 M709.1,211.2V204 M707.1,211.1l-7-0.7 M709.1,204l-11.8,1.1 M720.6,343h0.5
	c6.5,0.4,12.1,0.3,17.1-0.4c4.1-1.1,6.3-3.6,6.9-7.4l4.5-108c-0.3-3.9-2.3-6.5-6.2-8c-4.7-1.1-10.3-1.8-16.9-1.9l-2.2-0.1
	c-6.5-0.4-12.1-0.3-17.1,0.4c-4.1,1.1-6.3,3.6-6.9,7.4l-4.5,108v0.5"
          />
          <path
            fill="none"
            d="M693.2,269.2l2.9-34.8c0.3-3.9,0.8-6.5,1.5-7.8l5.9-11.3c1.2-2.2,2.3-3.7,3.6-4.3 M700.1,210.4l-6.6,15
	c-0.8,1.9-1.4,4.7-1.7,8.4l-2.8,33.8 M692.2,301.2h1v-32"
          />
          <path
            fill="none"
            d="M693.2,269.2v-1.6h-4.1 M688.8,307.3l3.4-1.4v-4.7 M690.6,312.4v2.5 M690.6,312.4v-1.1c0-0.7-0.3-1-1-1h-0.8
	 M690.6,314.8h1v-2.5h-1 M690,315.4c0.3-0.1,0.4-0.3,0.5-0.5 M689.2,326.3l2.5-1.4v-8.4l-1.7-1 M695.7,333.5l-4.4,0.1
	c-1.1,0-1.9,0.1-2.5,0.4"
          />
          <path
            fill="none"
            d="M688.8,329.1l3-0.5v-2.3h-2.6 M715.2,354.9h-6.5c-0.3,0-0.5-0.1-0.5-0.5v-13.5 M715.2,354.9h0.5
	c0.3,0,0.6-0.1,0.6-0.5v-14c0-0.3-0.1-0.5-0.5-0.5h-7c-0.3,0-0.5,0.1-0.5,0.5v0.5"
          />
          <path
            fill="none"
            d="M720.6,343l-0.1-4.3c-1-4-3.2-6.1-6.6-6.1l-18,0.8"
          />
          <path fill="none" d="M708.3,340.8h6.5c0.3,0,0.6,0.1,0.6,0.5v13.5" />
          <path
            fill="none"
            d="M709.6,367.9c1.8-0.1,3.2-0.7,4-1.6c2.5-4.1,3.7-7.4,4-9.8v-14.6c0.3-4.1-1.2-6.3-4.7-6.6h-25 M716,367.5
	l1.9-3.2c1.9-3.2,2.9-5.4,2.9-6.6l-0.4-14.6 M688.6,367.4c0,1.2,0.8,1.9,2.5,2.1l20.6,0.3c1.1,0.1,1.9,0,2.8-0.3"
          />
          <path fill="none" d="M714.4,369.5c0.5-0.3,0.8-0.6,1.2-1.1l0.5-0.8" />
          <path
            fill="none"
            d="M714.4,369.5V381c0,0.3,0.1,0.5,0.5,0.5h0.7c0.3,0,0.5-0.1,0.5-0.5v-13.5 M699.1,387.1c0.1-0.1,0.4-0.7,0.7-1.5
	c0.3-1.1,0.5-2.3,0.5-3.9c0.1-1.5,0.1-2.8,0.1-4c-0.1-1.1-0.3-1.6-0.4-1.6 M700.1,376.1c-0.3,0-0.4,0.5-0.7,1.5
	c-0.3,1.1-0.4,2.3-0.5,3.9c-0.1,1.5-0.1,2.9-0.1,4c0.1,0.8,0.1,1.4,0.3,1.6 M699,386.9l0.1,0.1 M699,386.9l0.1,0.1 M710.5,368.1
	l-1-0.1 M664.2,166.8l-0.5-4c-10-1.6-20.4-2.8-31.2-3l-104.1-0.1c-11.6-0.1-21.5,0.6-29.7,1.8 M376.8,219.4l17.3-31.9
	c1.4-2.8,4.3-4.3,8.7-4.4l75.2-1.2c1.9-0.1,3.3,0.3,4.1,1.5c0.8,1,1.1,2.5,0.8,4.7l-1.4,46.4c0.1,4.7-2.6,7.6-8.5,8.7
	c-3.3,0.6-6.7,0.8-10.3,1l-71.5,0.7 M520.5,180.2h117.8c4.8,0.3,7.4,3.2,7.8,8.8l4.4,48.1c0.4,2.5-0.4,4.5-2.6,5.9
	c-1.9,1.2-5.1,2.1-9.4,2.3H525.8c-4.7,0.3-8.1-0.3-10.6-1.9c-2.5-1.6-3.9-4.1-4-7.7v-49.2C511.2,182.1,514.4,180,520.5,180.2
	L520.5,180.2z"
          />
          <path
            fill="none"
            d="M512.6,186.5v49.1c0.1,3,1.2,5.1,3.3,6.5c2.2,1.4,5.4,1.9,9.8,1.6h112.8c4-0.3,6.9-1,8.7-2.1
	c1.7-1.1,2.2-2.5,1.9-4.4l-4.4-48.1c-0.3-4.7-2.5-7.2-6.5-7.4H520.5C515.4,181.5,512.8,183.2,512.6,186.5L512.6,186.5z M496.9,173
	v-1l-105.5,2 M498.4,173h-1.5 M498.4,161.6V173 M388.2,169.4c-3,0.6-5.6,2.3-7.8,5.4l-26.8,44.3"
          />
          <path
            fill="none"
            d="M498.4,161.6l-84.7,1.8c-10,0.3-18.4,1.1-25.2,2.5c-0.4,1-0.5,2.2-0.3,3.6 M391.4,174l-3.2-4.5 M391.2,242.9
	v-19.5c0.1-2.6-0.7-4-2.3-4.1H379"
          />
          <path
            fill="none"
            d="M380.8,220.6l-5.1,2.8c-1.1,0.6-1.6,2.2-1.6,5v10.9c-0.1,1.6,0.8,3.2,2.6,4.5l4.3,3.3 M378.7,219.4h-1.9
	 M379.1,219.4h-0.4 M379,219.4h0.1 M380.8,220.6c-0.4-0.7-1-1.1-1.8-1.2"
          />
          <path
            fill="none"
            d="M380.9,247c0.3-0.6,0.3-1.1,0.3-1.9v-22.8c0-0.7-0.1-1.2-0.4-1.6 M378.7,219.4h0.3 M368.3,243.7l-0.8-0.7
	c-1-1-1.5-2.5-1.4-4.3V227c0.1-1.4,1-3,2.8-5.1c1.9-2.1,4.1-3,6.3-2.9h1.6 M391.2,244.8v-1.9 M391.2,244.8c-0.3,2.3-1.2,3.6-2.9,3.3
	h-9.2 M379.1,248.1c0.8,0,1.5-0.4,1.8-1.1 M379.1,248.1h-3.4l-0.1,0.1 M373.3,253.8h1.2c0.7,0,1-0.3,1-1v-4.7 M374,254.7l-0.7-1
	 M375.3,261.5l0.3-0.1c0.1-0.1,0.3-0.4,0.3-0.7l0.3-4.7c0.1-0.8-0.3-1.2-0.8-1.2H374 M375.1,261.5h0.1 M374.7,262l0.4-0.5
	 M374.9,261.9v-0.3 M374.9,261.6l0.4-0.1 M375.1,261.5l-0.3,0.4 M374.7,262l0.1-0.1 M368.3,250.6l-1.6-0.1c-2.3,0-4.4,0.8-6.2,2.5
	c-1.6,1.6-2.5,3.7-2.5,6.2s0.8,4.4,2.5,6.1c1.6,1.6,3.7,2.6,6.2,2.6c2.3,0,4.4-0.8,6.1-2.6c1-1,1.6-2.1,2.1-3.2 M477.5,263.5
	c-0.3-1.2-1-2.3-1.9-3.3c-1.5-1.5-3.2-2.2-5.2-2.2c-2.1,0-3.9,0.7-5.4,2.2c-1,1-1.6,2.1-1.9,3.3 M487.7,266.3c0-0.7-0.3-1.2-0.8-1.8
	s-1.1-0.8-1.8-0.8c-0.7,0-1.4,0.3-1.8,0.8c-0.5,0.5-0.7,1.1-0.7,1.8s0.3,1.4,0.7,1.8c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.2-0.3,1.8-0.7
	C487.5,267.7,487.7,267,487.7,266.3L487.7,266.3z"
          />
          <path
            fill="none"
            d="M484.6,270h0.5c1,0,1.9-0.4,2.6-1.1s1.1-1.6,1.1-2.6s-0.4-1.9-1.1-2.6s-1.6-1.1-2.6-1.1l-0.8,0.1 M484.4,262.7
	l-3,0.1c-0.7,0.1-1.2,0.4-1.8,0.7h-2.1"
          />
          <path
            fill="none"
            d="M484.4,262.7c-0.7,0.1-1.2,0.4-1.8,1c-0.7,0.7-1.1,1.6-1.1,2.6s0.4,1.9,1.1,2.6c0.5,0.5,1.2,1,2.1,1.1"
          />
          <path
            fill="none"
            d="M477.1,268.6h1.9c0.4,0.4,1.2,0.7,2.3,1l3.3,0.3 M463.5,268.6c0.4,0.8,0.8,1.5,1.5,2.2c1.5,1.5,3.3,2.2,5.4,2.2
	s3.9-0.7,5.2-2.2c0.7-0.7,1.2-1.4,1.5-2.2 M479.4,264.8c0.3,0.4,0.4,0.8,0.4,1.4s-0.1,1-0.4,1.4s-0.5,0.5-1,0.5h-20.9
	c-0.5,0-1-0.1-1.4-0.5s-0.5-0.8-0.5-1.4s0.1-1,0.5-1.4s0.8-0.5,1.4-0.5h20.9C478.9,264.2,479.1,264.4,479.4,264.8z M486.6,277.1
	c-0.3-0.1-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3
	s0.5-0.1,0.8-0.3c0.1-0.3,0.3-0.4,0.3-0.7C486.8,277.6,486.7,277.4,486.6,277.1L486.6,277.1z"
          />
          <path
            fill="none"
            d="M488.8,278c0-0.8-0.3-1.5-0.8-2.1c-0.6-0.5-1.2-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.2,0.8c-0.6,0.5-0.8,1.2-0.8,2.1
	c0,0.8,0.3,1.5,0.8,2.2c0.5,0.5,1.2,0.8,2.2,0.8c0.8,0,1.5-0.3,2.2-0.8C488.5,279.5,488.8,278.8,488.8,278L488.8,278z M463,263.5
	h-1.7 M461.2,268.6h2.2 M461.4,263.5c-0.5-0.5-1.4-0.8-2.2-0.8h-2.5c-1,0-1.6,0.3-2.3,1c-0.7,0.7-1,1.5-1,2.3c0,1,0.3,1.6,1,2.3
	c0.7,0.7,1.5,1,2.3,1h2.6c0.8,0,1.5-0.3,2.1-0.7"
          />
          <path
            fill="none"
            d="M461.4,263.5h-4.1c-0.7,0-1.2,0.3-1.8,0.7c-0.4,0.5-0.7,1.1-0.7,1.8v0.1c0,0.7,0.3,1.2,0.7,1.6
	c0.5,0.5,1.1,0.7,1.8,0.7h4 M477.5,263.5H463 M477.1,268.6h-13.6 M495.9,310.8v-3.7 M495.9,307.1c0.3,1.1,0.3,2.3,0,3.7
	 M495.9,263.4v43.7 M496.9,173l-1,90.4 M676.5,174.9c-0.7-2.2-1.9-3.6-3.7-4.4l-8.7-3.7 M680,188.4l-0.4-10.5
	c-0.1-1.8-1.1-2.8-3-3.2 M682.3,191.3l-0.4-3l-1.9,0.1 M679.3,192l-0.5-3.4v-0.1l-1.9-12.2l-0.3-1.2 M664.8,172.5l-0.7-5.6"
          />
          <path
            fill="none"
            d="M683,196.4l0.5-0.7c0.3-0.6,0.6-1.1,0.6-1.9c0-0.7-0.1-1.4-0.5-1.9c-0.3-0.6-0.7-0.8-1.2-0.8h-0.1"
          />
          <path
            fill="none"
            d="M680.2,198.7l3-0.4l-0.3-1.9 M688,261.9c-2.2-21.6-4.7-42.1-7.3-61.4l-0.3-1.8 M682.3,191.3l0.7,5.1 M680,188.4
	l0.1,1.9c0.3,1.1,0,1.7-0.7,1.7 M679.3,192l1,6.7 M513.4,356.9l5.2-8.1c7-10.6,14.2-20.4,21.3-29.4l4.8-5.9l4.5-4.8
	c0.7-0.5,1.4-1.2,2.1-1.7c0.4-0.3,0.8-0.5,1.1-0.8c4.3-2.3,9.6-3.7,16.2-4c21.9-1.9,41-2.2,57.2-0.7c6.3,0.5,11.3,3.3,15,8.1
	c1.4,1.8,2.6,4,3.6,6.3l7.2,16l1.9,0.1"
          />
          <path
            fill="none"
            d="M653.8,331.9l19.1,0.7c-6.2-13.5-12.5-25.5-19.1-35.6c-4.7-7.6-11.8-11.7-21-12.5c-23.5-1.5-46.2-1.1-68.1,1
	c-15.1,0.8-26.3,5.5-33.2,14l-12.8,16.4c-9.8,12.8-19.7,26.5-29.7,41.1"
          />
          <path
            fill="none"
            d="M666.4,332.2c-4.1-9.6-8.3-17.9-12.2-24.6l-5-7.6l-1.7-2.2c-5-5.4-12.7-7.8-23.1-7.6
	c-24.2-0.7-46.5,0.3-66.6,2.8c-8.8,1.1-16.1,5.4-21.6,12.8l-38.5,51.3 M688,261.9h-13.8 M689.1,267.5h-0.5 M684.4,268.6
	c-0.1,0.3-0.3,0.7-0.3,1.1l0.5,29.6c0.1,0.6,0.4,1.1,0.8,1.4"
          />
          <path
            fill="none"
            d="M685.6,300.8c-1.7,0.1-2.6-0.8-2.6-2.8l-0.5-27c0.3-1.5,1-2.3,1.9-2.5 M688.5,267.5h-2.2c-1,0-1.5,0.3-1.8,1.1
	 M661.3,269.4c-2.3,0-4.4,0.8-6.1,2.5c-1.7,1.6-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1c1.7,1.6,3.7,2.5,6.1,2.5
	c2.3,0,4.4-0.8,6.1-2.5c1.7-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.1C665.7,270.3,663.6,269.4,661.3,269.4L661.3,269.4z"
          />
          <path
            fill="none"
            d="M661.3,272.1c-1.7,0-3,0.5-4.3,1.8c-1.2,1.2-1.8,2.6-1.8,4.3s0.5,3,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8
	s3-0.5,4.3-1.8s1.8-2.6,1.8-4.3c0-1.6-0.6-3-1.8-4.3C664.3,272.6,662.9,272.1,661.3,272.1L661.3,272.1z"
          />
          <path
            fill="none"
            d="M661.3,266c-3.3,0-6.2,1.2-8.5,3.6s-3.6,5.2-3.6,8.5s1.2,6.2,3.6,8.5s5.2,3.6,8.5,3.6s6.2-1.2,8.5-3.6
	c2.3-2.3,3.6-5.2,3.6-8.5s-1.2-6.2-3.6-8.5C667.3,267.2,664.6,266,661.3,266L661.3,266z"
          />
          <path
            fill="none"
            d="M658.2,278.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8s0.8-1.2,0.8-2.1
	c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.1-0.8c-0.8,0-1.5,0.3-2.1,0.8S658.2,277.3,658.2,278.1L658.2,278.1z"
          />
          <path
            fill="none"
            d="M657,278.1c0,1.1,0.4,2.2,1.2,3s1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.8,1.2-3c0-1.1-0.4-2.2-1.2-3
	s-1.8-1.2-3-1.2c-1.1,0-2.2,0.4-3,1.2C657.4,275.9,657,277,657,278.1L657,278.1z M688.8,310.3v-3 M688.8,301.2H687
	c-0.5,0-1-0.1-1.4-0.4 M688.8,301.2v6.1 M688.5,267.5l-0.5-5.6 M674.2,261.9c-2.5-31.4-5.6-61.2-9.4-89.4"
          />
          <path
            fill="none"
            d="M498.4,173h77.2l89.3-0.6 M495.9,263.4l178.3-1.5 M173.7,265.2l0.6-3.6l0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1h5.2
	c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.3l-0.6,3.6 M308.3,246.3l-103,10.2l-24.1,2.2 M173.7,266.4h-0.4c-0.1,0-0.3,0-0.3-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.3v-0.3c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3-0.1,0.3-0.1h0.3 M171.8,271.1c-0.1-0.1-0.1-0.3-0.1-0.4l0.6-3.2
	c0.1-0.3,0.3-0.5,0.6-0.5h0.8 M174.2,270.8l-0.1,0.4c0,0.3-0.3,0.5-0.6,0.5h-1.1c-0.1,0-0.3,0-0.4-0.1l-0.3-0.4"
          />
          <path
            fill="none"
            d="M173.7,270.5v0.3c0,0.3-0.3,0.5-0.6,0.5H172l-0.3-0.1 M156.3,263.9c-0.6,0.4-1.1,0.8-1.5,1.4l-0.1,0.1
	 M154.8,274.1l1.5-10.2 M153.2,267.4c0.6-0.3,0.8,0.5,0.7,2.6 M171.7,279.6V278c0-0.3,0.1-0.5,0.6-0.5h2.3 M171.7,281.3v-1.6
	 M171,281.3v-1.6h0.7 M171.7,281.3H171 M153.1,284.4c3.4-1.2,7.4-1.9,12-2.3l5.9-0.8 M151.3,282.9l-0.6,2.5 M147.1,287.3l5.5-18.7
	c0.1-0.4,0.4-0.8,0.7-1.2 M153.1,284.4c-0.8,0.3-1.6,0.5-2.5,1 M143.6,290.1c-1.1,1.1-2.1,2.3-2.9,3.7c-2.5,4.1-4.8,9.2-7.2,15.3
	c-0.4,0.8-0.1,1.5,1.1,1.9 M148.7,298.6c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3s1.9,1.2,3,1.2c1.2,0,2.2-0.4,3-1.2
	c0.8-0.8,1.2-1.9,1.2-3c0-1.2-0.4-2.2-1.2-3s-1.9-1.2-3-1.2S149.5,297.8,148.7,298.6L148.7,298.6z M143.6,290.1c1-1,2.2-1.9,3.4-2.8
	"
          />
          <path
            fill="none"
            d="M154.6,265.3c-1.8,0.7-3.3,2.3-4.7,5.1l-6.3,19.7 M153.9,269.9c-2.1,2.6-2.9,6.9-2.6,12.9 M150.8,285.4
	c-1.4,0.5-2.6,1.2-3.7,1.9 M154.8,274.1l-1.7,10.3 M151.3,282.9c1.5-6.1,2.3-10.5,2.6-12.9 M153.2,267.4c0.4-0.7,0.8-1.4,1.4-1.9
	 M180.1,266.4h1l0.3-0.1l0.1-0.3v-0.3l-0.1-0.3c-0.1-0.1-0.3-0.1-0.3-0.1H180 M178.6,264.1v-0.8h-2.9l-0.3,0.8 M180.3,267.2v-0.1
	h-0.1l-0.3-0.1 M179.9,267l0.1-0.5 M179.9,265.2l-0.1,0.8l-0.1,0.4h-0.1 M180.1,266.4h-0.6 M179.5,266.4h-1.1
	c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-1.9 M178.6,267.1l-0.3,0.4l-0.6,3 M177.9,271.1l0.3,0.4c0.1,0.1,0.1,0.1,0.4,0.1h1.1
	c0.3,0,0.6-0.1,0.6-0.5l0.6-3.2v-0.4l-0.3-0.4"
          />
          <path
            fill="none"
            d="M180.3,267.2v0.3l-0.6,3.2c0,0.3-0.3,0.5-0.6,0.5H178l-0.3-0.1 M178.6,267.1c0.1-0.1,0.3-0.1,0.4-0.1h1.1
	 M175.4,264.1L175,266l-0.3,0.4l-0.3,0.1H174l-0.3-0.1 M173.7,266.4c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.8 M173.6,267h0.3l0.3,0.1h0.1
	 M173.7,266.4l-0.1,0.5"
          />
          <path
            fill="none"
            d="M174.3,267.1v0.4l-0.6,3 M177.7,270.5v0.1c0,0.1,0,0.3,0.1,0.4 M176.8,273.2l0.1-2.1l0.8-0.5 M174.2,270.8
	l0.6,0.4v2.1 M173.7,270.5l0.4,0.3 M174.3,267.1h4.3 M178.6,264.1h-3.2 M176.8,277.6h1.9c0.3,0,0.6,0.1,0.6,0.5v2.5 M174.4,277.6
	v1.8h2.2l0.1-1.8 M174.7,273.3l-0.3,4.3 M176.8,273.2v4.4 M181.2,258.7l-18,2.2c-4.5,0.8-6.9,1.8-6.9,3 M174.7,273.3l-19.9,0.8
	 M179.1,280.4l-7.4,0.8 M309.5,354.3h-16c-11.6-17.7-23.1-33.7-34.7-48.1c-4.4-5.9-10.3-8.9-17.3-9.1h-49.4
	c-10.9,0.1-18.7,1.1-23.8,2.8c-4.5,1.5-9.2,5.5-14.2,12c-0.6,1-1.4,1.4-2.5,1.2h-9.5l-2.6-0.3l-5.1-1.7 M353.5,219.1v-62h0.1
	l0.1-0.4v-1.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1s-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1c0,0.1,0.1,0.3,0.1,0.4
	v63.3 M266,248.7l-1.8-4.3l-5,0.6v0.1 M259.3,245.1l-1.2,0.1c-0.3,0-0.5,0.1-0.5,0.6v3.7 M259.3,245.1l-0.8,4.3 M257.7,249.4h0.8
	 M258.5,249.4l7.6-0.7"
          />
          <path
            fill="none"
            d="M179.1,280.4c22.6-2.2,46.6-2.5,72.5-0.8c11.7,1,21,6.3,28.1,15.7c13.1,16.4,26.4,36,40,58.9h-10.2
	 M353.5,219.1l-0.7,1.2 M358.1,229.5c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1
	s0.1,0.7,0.4,1s0.5,0.4,1,0.4S357.8,229.8,358.1,229.5L358.1,229.5z M353.5,235.7l-0.3-0.6l-0.4-0.3l-0.5-0.1c-0.4,0-0.7,0.1-1,0.4
	s-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4l0.5-0.1l0.4-0.3l0.3-0.4"
          />
          <path
            fill="none"
            d="M353.5,236.7l0.1-0.6l-0.1-0.4 M353.5,235.7v1 M350,265.3c0.5-6.9,1.5-12.8,2.8-17.6l0.7-2.6
	c1-3,2.1-5.6,3.3-7.8l5.5-10 M345.1,249.6c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1
	s-0.5-0.4-1-0.4s-0.7,0.1-1,0.4C345.3,248.9,345.1,249.2,345.1,249.6L345.1,249.6z M342.5,240.6c0-0.3,0-0.4-0.1-0.6l-0.5,0.1
	c-0.3,0.1-0.4,0.4-0.5,0.7s-0.3,0.6-0.3,0.8c0,0.3,0,0.6,0.1,0.6c0.1,0.1,0.4,0,0.5-0.1l0.5-0.7
	C342.4,241.1,342.5,240.8,342.5,240.6L342.5,240.6z M339.4,242.6l-0.4,0.7 M334.8,243l-0.1-0.4l-1.8,0.1l-0.1,0.4 M334.8,243
	l0.1,0.6 M332.5,243.9l0.3-0.7 M332.5,243.9l2.5-0.3 M339.4,242.6l-4.5,0.4 M335,243.6l4-0.3 M343.8,258c0,0.4,0.1,0.7,0.4,1
	s0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4S343.8,257.7,343.8,258
	L343.8,258z M375.5,248.3c-1.2,0.1-3-0.8-5.5-2.9l-1.8-1.5 M365.5,254.7h-1.6 M363.9,254.7l0.8,7.3c0.3,0.8,1.1,1.2,2.3,1.4h4.7
	c1.1-0.1,2.2-0.5,3.2-1.4"
          />
          <path
            fill="none"
            d="M374.9,261.6h-8.8c-0.5,0-1-0.3-1-1l-0.4-4.7c-0.1-0.8,0.1-1.1,0.8-1.2 M373.3,253.8h-4.1c-0.7,0-1-0.3-1-1
	v-2.2"
          />
          <path
            fill="none"
            d="M363.9,254.7h-3.2l0.5,8.1c0,1,0.8,1.5,2.2,1.5h7.6c1.1,0,2.3-0.7,3.7-2.2 M368.3,243.7v6.9 M351.9,221.8
	l-12.5,20.8 M352.9,220.3l-1,1.5 M362.3,227.3l-10.5-5.5 M310.3,245.1l-0.3-2.3c-0.3-0.6-1-0.6-2.1-0.1l0.3,2.8"
          />
          <path
            fill="none"
            d="M308.4,244.4c0.1,0.1,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6
	c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6C308.3,244,308.3,244.1,308.4,244.4z M308.3,245.2v1.1
	 M308.3,246.3l2.2-0.3 M310.5,246.1l-0.1-1 M355.7,283.6h1.1l10.6-1.2c1.1-0.1,1.6-0.7,1.6-1.5v-6.6c0-0.8-0.5-1.4-1.6-1.5
	l-10.6-1.2h-1.1 M355.7,271.5l-1.4,0.1H354c-0.5,0.1-1.1,0.3-1.6,0.5 M352.4,272.2v-0.1c0-0.3-0.1-0.5-0.3-0.7
	c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7v5.4"
          />
          <path
            fill="none"
            d="M357.1,276.7c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3
	c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3C357.5,276.5,357.3,276.5,357.1,276.7L357.1,276.7z"
          />
          <path
            fill="none"
            d="M355.7,283.6l11-2.2v-2.1h-11.6v-3.6h11.6v-2.2l-11-2.1 M349.8,277.3v6.3c0,0.3,0.1,0.5,0.3,0.7
	s0.4,0.3,0.7,0.3h0.7c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7v-0.8 M352.4,282.8l1.6,0.5l0.3,0.1l1.4,0.1 M352.4,277.3v5.5"
          />
          <path
            fill="none"
            d="M362.9,277.4c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8
	c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3C362.9,276.9,362.9,277.1,362.9,277.4L362.9,277.4z
	 M352.4,272.2v5.1 M349.8,320.3v-52l0.1-3 M350,265.3l-12.7,0.1 M349.8,277.3h2.6 M332.8,243.2l-22.4,1.9 M339,243.3
	c0.7,3.2,0.1,10.6-1.6,22.3 M310.5,246.1l22-2.2 M306.1,266.8c0.1-7.8,0.8-14.7,2.2-20.5 M308.3,245.2l-42.2,3.4 M257.7,249.4
	l-76.5,9.4 M337.3,265.5l-160.5,7.7 M355.7,331.9h1.1l10.6-1.2c1.1-0.1,1.6-0.7,1.6-1.5v-6.6c0-0.8-0.5-1.4-1.6-1.5l-10.6-1.2h-1.1"
          />
          <path
            fill="none"
            d="M352.4,320.5v-0.1c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.5,0.1-0.7,0.3
	c-0.1,0.1-0.3,0.4-0.3,0.7 M355.7,319.8l-1.4,0.1H354c-0.5,0.1-1.1,0.3-1.6,0.5 M357.1,325.2c-0.3,0.3-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8
	c-0.3-0.3-0.4-0.3-0.8-0.3C357.5,324.7,357.3,324.9,357.1,325.2L357.1,325.2z"
          />
          <path
            fill="none"
            d="M355.7,331.9l11-2.2v-2.1h-11.6V324h11.6v-2l-11-2.2 M350.1,332.7c0.1,0.1,0.4,0.3,0.7,0.3h0.7
	c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7v-0.8"
          />
          <path
            fill="none"
            d="M352.4,331.2l1.6,0.5h0.3l1.4,0.1 M352.4,325.7v5.5 M362.9,325.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3
	c-0.3,0-0.5,0.1-0.8,0.3C362.9,325.3,362.9,325.6,362.9,325.8L362.9,325.8z M352.4,320.5v5.2 M349.8,325.7v6.3
	c0,0.3,0.1,0.6,0.3,0.7 M349.8,320.3v5.4 M377.1,349.4h-0.3c-10.6,0.4-17.6-1.1-20.9-4.7c-3.2-1.9-5.1-5.9-5.8-12 M349.8,325.7h2.6
	 M298,360.8l-1.5-2.9c-0.3-0.7-0.1-1,0.4-1h14.7 M311.6,356.9l-2.1-2.6 M298,360.8l3.4,6.3c0.3,0.7,0.8,1,1.5,1h214.2
	c0.7,0,1-0.3,1-1v-9.2 M269.6,360.6c-1.9-7.6-5.4-14.7-10.3-21l-0.3-0.3c-1.4-1.8-2.9-3.6-4.5-5.4l-1.5-1.5
	c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6l-1.5,1.5c-1.6,1.8-3.2,3.6-4.5,5.4c-1,1.2-1.8,2.5-2.8,3.9
	c-3.4,5.4-6.1,11.1-7.7,17.2"
          />
          <path
            fill="none"
            d="M256.7,341.1c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.4-16.5-41.5-16.5s-30,5.5-41.5,16.5L164,336
	c-1.5,1.6-3,3.4-4.4,5.1c-1.9,2.6-3.7,5.4-5.2,8.1c-4.4,8.4-6.7,17.7-6.7,28.1s2.2,19.7,6.7,28.1c1.5,2.8,3.3,5.5,5.2,8.1
	c1.8,2.3,3.7,4.4,5.8,6.6c3,3,6.2,5.6,9.6,8c9.8,6.5,20.8,9.8,33.2,9.8s23.5-3.3,33.2-9.8c3.3-2.2,6.6-5,9.6-8
	c2.1-2.1,4-4.3,5.8-6.6c1-1.2,1.9-2.6,2.8-4c6.1-9.5,9.1-20.2,9.1-32.2c0-12.1-3-22.8-9.1-32.3C258.6,343.7,257.7,342.5,256.7,341.1
	L256.7,341.1z"
          />
          <path
            fill="none"
            d="M236.6,359.3c-1.2-2.1-2.9-4-4.7-5.8c-4.4-4.5-9.5-7.4-15.3-8.8"
          />
          <path
            fill="none"
            d="M216.7,344.7c-0.6-1.9-1.5-3.4-2.6-4.1c1,1,1.6,2.3,1.8,4v0.1l1.5,16.1c0,0.8,0.1,1.5,0.4,1.9
	c0.3,0.4,0.6,0.7,1,1l0.7,0.3c0.6,0.1,1.2,0,2.1-0.3l15.7-3.6c1.8-0.4,3.2-0.3,4.4,0.4c-1.1-1-2.8-1.4-5-1.2"
          />
          <path
            fill="none"
            d="M227.7,361.2l-1.6-1.6c-2.5-2.5-5.2-4.4-8.4-5.6"
          />
          <path
            fill="none"
            d="M217.8,353.8l0.6,4.7c0.1,1.5,0.7,2.6,1.6,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1 M217.8,353.8l-1.1-9.1 M236.6,359.3
	l-8.9,1.9"
          />
          <path
            fill="none"
            d="M234.5,403.8c5.4-5.4,8.7-11.4,10.2-18.3c0.6-2.6,0.8-5.4,0.8-8.3s-0.3-5.6-0.8-8.3c-0.4-1.9-1-3.7-1.6-5.5
	c-1.8-4.7-4.7-8.9-8.4-12.7c-7.3-7.3-16.1-11-26.4-11l-5.8,0.4 M241.7,375.5l-6.7,3"
          />
          <path fill="none" d="M234.1,398.9c5.1-6.1,7.7-13.2,7.7-21.5v-1.9" />
          <path
            fill="none"
            d="M241.7,375.5c1.5-1,2.5-1.9,2.9-3.2l0.3-0.7l-0.3,0.5c-0.7,1-1.6,1.8-3,2.3h-0.1l-14.9,6.3
	c-0.8,0.3-1.4,0.5-1.8,1c-0.4,0.5-0.7,1.2-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.9c1,1.5,1.2,2.9,1.1,4.3 M223,369.5
	c-1.5-0.3-3-0.3-4.7,0.3l-1,0.3c-0.8,0.4-1.4,1-1.8,1.8c-0.6,1-0.6,1.9-0.3,3c0.3,1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3
	c1.8-0.5,3-1.4,4-2.5s1.2-2.2,1-3.3C225.3,370.5,224.4,369.9,223,369.5L223,369.5z"
          />
          <path
            fill="none"
            d="M218,370.4c-1,0.3-1.5,0.8-2.1,1.6c-0.4,0.8-0.6,1.8-0.3,2.6c0.3,1,0.8,1.5,1.8,1.9c0.8,0.4,1.6,0.6,2.6,0.3
	s1.6-0.8,2.1-1.8c0.4-0.8,0.6-1.7,0.1-2.6c-0.3-1-0.8-1.6-1.6-2.1C219.8,370.3,219,370.1,218,370.4L218,370.4z M215.6,382.8
	c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.5-1.4,1.5-1.6,2.6c-0.1,0.8,0,1.6,0.4,2.5l0.6,0.8c1.1,1.5,2.2,2.5,3.6,3c1.4,0.6,2.5,0.6,3.3-0.1
	c1-0.7,1.2-1.7,1.1-3.2c-0.1-1.5-0.7-3-1.8-4.4l-0.7-0.8C217.1,383.2,216.4,382.9,215.6,382.8L215.6,382.8z M227.4,382.5
	c-0.3,0.3-0.6,0.7-0.6,1.1c-0.4,1-0.3,2.1,0.6,3.4l6.7,11.8 M235,378.5l-5.8,2.6c-0.8,0.4-1.5,0.8-1.9,1.4"
          />
          <path
            fill="none"
            d="M216.9,389.5c0.8-0.5,1.2-1.4,1.4-2.2c0.1-1,0-1.8-0.6-2.6c-0.6-0.8-1.4-1.2-2.2-1.4c-1-0.1-1.8,0-2.6,0.5
	s-1.2,1.2-1.4,2.2c-0.1,1,0,1.8,0.6,2.6c0.6,0.8,1.2,1.2,2.2,1.4C215.3,390.4,216.2,390.1,216.9,389.5L216.9,389.5z M171.1,371.4
	c-0.3,1.9-0.4,3.9-0.4,5.9c0,4.4,0.7,8.5,2.1,12.5c1.8,5.1,4.8,9.8,8.9,14c7.3,7.3,16.1,11,26.4,11s19.1-3.6,26.4-10.9 M234.5,403.8
	c0.4-1.4,0.3-3-0.4-5"
          />
          <path
            fill="none"
            d="M244.7,394.3c2.5-5.2,3.6-10.9,3.6-17.1c0-11.1-4-20.6-11.8-28.5S219.1,337,208,337s-20.6,4-28.5,11.8
	c-7.8,7.8-11.8,17.3-11.8,28.5c0,7.2,1.6,13.5,4.8,19.3c1.8,3.3,4.1,6.3,7,9.2c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6
	c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1C240.1,402.3,242.8,398.5,244.7,394.3L244.7,394.3z"
          />
          <path fill="none" d="M235,378.5c0,2.8-0.3,4.5-1.2,5.5l-6.5-1.6" />
          <path
            fill="none"
            d="M146.6,360.4c-1.4,5.4-2.2,11-2.2,16.9c0,12.8,3.3,24.2,9.8,34.3c0.8,1.2,1.8,2.6,2.8,3.9
	c1.8,2.3,3.9,4.7,6.1,6.9c2.1,2.1,4.3,4,6.5,5.8c11,8.7,23.9,12.9,38.5,12.9s27.5-4.3,38.5-12.9c2.2-1.8,4.4-3.6,6.5-5.8
	c2.2-2.2,4.3-4.5,6.1-6.9l0.3-0.3c8.3-10.9,12.2-23.5,12.2-37.7c0-5.8-0.7-11.4-2.1-16.6"
          />
          <path
            fill="none"
            d="M195.6,408.8c-1.6,1.4-3.3,1.9-4.7,1.9c1.4-0.1,2.8-1,3.9-2.3l10.6-12.1c0.4-0.7,1-1.1,1.5-1.4
	c0.8-0.3,1.5-0.3,2.2,0c0.6,0.3,1.1,0.7,1.5,1.4l10.7,12.2c1.1,1.2,2.3,1.9,3.7,2.2c-1.5,0.1-3-0.5-4.7-1.8 M269.6,360.6l28.3,0.1
	 M199.7,344.7c-5.8,1.4-10.9,4.4-15.4,8.9c-1.8,1.8-3.3,3.6-4.5,5.5 M188.9,360.9l-9.1-1.8 M202.4,340.3c-1.2,0.8-2.1,2.3-2.6,4.3"
          />
          <path
            fill="none"
            d="M179.8,359.1c-2.1-0.1-3.7,0.3-5,1.2c1.2-0.7,2.8-0.8,4.4-0.4l16.1,3.7l1.6,0.1l0.3-0.1c0.6-0.1,1-0.5,1.4-1.1
	c0.3-0.6,0.4-1.2,0.4-2.1l1.5-16c0.1-1.8,0.7-3.2,1.8-4.1 M199.7,344.7l-1,9.1"
          />
          <path
            fill="none"
            d="M188.9,360.9l4.4,1c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.5,1.4-1.5,1.5-3l0.6-4.5 M202.4,340.3
	c-7.8,1.1-14.9,4.7-20.6,10.6c-3.9,3.9-6.6,8.1-8.5,12.7l-0.6,1.4c-0.7,2.1-1.2,4.3-1.5,6.5"
          />
          <path
            fill="none"
            d="M198.8,353.8c-3.2,1.2-5.9,3.2-8.5,5.6l-1.4,1.5 M152.6,316.2l-1.9-1H141l-6.9-1.9h-0.4"
          />
          <path
            fill="none"
            d="M131.5,313.3l8.3,2.9h12.8 M182,398.6c-0.8,1.9-1,3.7-0.4,5.1c-0.3-1.4,0.1-2.9,1-4.4l8.3-13.8
	c0.4-0.5,0.8-1.2,0.8-1.9c0.1-0.7-0.1-1.4-0.7-2.1c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3c-0.7-0.3-1.2-0.5-1.8-1c-0.7-0.5-1.2-1.2-1.5-1.9
	"
          />
          <path
            fill="none"
            d="M171.1,371.4c0.3,0.8,0.8,1.6,1.5,2.5l1.6,1.2h0.1 M144,360.4h2.6 M140.6,363.5h2.6c0.6,0.1,0.8-1,0.8-3.2
	 M144,360.4h-2.6 M140.6,363.5l0.8-3.2 M210.9,360.8c-0.8-1.2-1.6-1.9-2.8-1.9s-2.1,0.7-2.8,1.9c-0.6,0.8-0.8,1.8-1,2.8
	c-0.1,0.5-0.1,1.1-0.1,1.8v1c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.8,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1c0.6-0.7,1-1.4,1.1-2.2
	l-0.1-2.8C211.7,362.6,211.3,361.6,210.9,360.8L210.9,360.8z"
          />
          <path
            fill="none"
            d="M210.5,363.3c-0.7-0.7-1.5-1-2.3-1c-1,0-1.8,0.3-2.5,1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1.1,2.5,1.1s1.8-0.3,2.3-1.1c0.7-0.7,1-1.5,1-2.3s-0.3-1.5-0.8-2.2L210.5,363.3L210.5,363.3z M198,370
	c-1.6-0.5-3.3-0.7-4.7-0.3c-1.5,0.3-2.3,1.1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.3,1.9,4,2.5l1,0.3c0.8,0.1,1.6,0,2.5-0.4
	c1-0.6,1.6-1.2,1.9-2.3s0.3-2.1-0.3-3c-0.4-0.8-1-1.4-1.6-1.8L198,370L198,370z"
          />
          <path
            fill="none"
            d="M195.5,370.7c-0.8,0.4-1.4,1.1-1.6,2.1c-0.3,0.8-0.3,1.8,0.3,2.6c0.4,0.8,1.1,1.4,2.1,1.7
	c0.8,0.3,1.8,0.3,2.6-0.1s1.5-1.1,1.8-2.1s0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.5-1.9-1.8C197.3,370.1,196.3,370.3,195.5,370.7
	L195.5,370.7z M208.1,372.1c-1.5,0-2.8,0.5-3.7,1.5c-1,1-1.5,2.3-1.5,3.7c0,1.4,0.6,2.8,1.5,3.9c1.1,1,2.3,1.5,3.7,1.5
	c1.5,0,2.8-0.5,3.7-1.5c1.1-1.1,1.6-2.3,1.6-3.9c0-1.5-0.6-2.8-1.6-3.7C210.9,372.5,209.6,372.1,208.1,372.1L208.1,372.1z
	 M200.7,382.8c-0.8,0.1-1.6,0.5-2.2,1.1l-0.6,0.8c-1.1,1.5-1.7,2.9-1.8,4.4s0.3,2.5,1.1,3.2s1.9,0.7,3.3,0.1s2.6-1.5,3.6-3l0.6-0.8
	c0.4-0.8,0.6-1.5,0.4-2.3c-0.1-1.1-0.7-1.9-1.5-2.6C202.8,382.9,201.8,382.7,200.7,382.8L200.7,382.8z M174.6,375.2l12.4,5.6
	c1.4,0.5,2.2,1.4,2.5,2.5c0.4,1,0.3,2.1-0.6,3.4l-6.7,12 M174.6,375.2c0,0.7-0.1,1.4-0.1,2.1c0,8.1,2.5,15.3,7.6,21.3"
          />
          <path
            fill="none"
            d="M203.3,383.9c-0.8-0.5-1.7-0.8-2.6-0.5c-1,0.1-1.6,0.5-2.2,1.4c-0.6,0.8-0.8,1.6-0.6,2.6c0.1,1,0.6,1.6,1.4,2.2
	c0.8,0.5,1.6,0.8,2.6,0.7c1-0.1,1.6-0.7,2.2-1.4c0.6-0.8,0.8-1.6,0.6-2.6C204.6,385.3,204,384.4,203.3,383.9L203.3,383.9z
	 M220.4,408.8l-9.4-10.3c-0.8-0.8-1.5-1.4-2.3-1.5l-0.8-0.1H207c-0.8,0.1-1.5,0.7-2.3,1.5l-9.2,10.2 M141.4,360.4l11.1-44.2
	 M131.5,313.3l-4,15.7 M127.1,330.9l0.4-1.9 M132.5,332.9l-5.4-1.9 M125.5,363.5c-2.9,0.4-4.5,0.1-5.2-0.7l-1.9-5.4
	c-1.1-3.2-1.7-6.1-1.7-8.7l-0.1-0.4v-0.1c-0.3-7.1,0-11,0.8-11.8c1.5-2.2,3.6-3.3,6.2-3.6h8.8 M104.3,329c-1.8-0.1-3.4,0.4-5.1,1.8
	l-4,3.7c-1.1,1-1.8,2.2-1.9,3.3v13.9c0.1,3.9,0.7,6.7,1.7,8.8l4.3,7c0.6,1.2,1.7,1.9,3.2,1.8h1.1"
          />
          <path
            fill="none"
            d="M103.4,369.3l-4.5-7.8c-1.5-2.5-2.3-5-2.6-7.3v-16c0.4-1.9,1.2-3.4,2.5-4.5l5.4-4.5"
          />
          <path
            fill="none"
            d="M107.6,369.3l-2.5-5.9c-1.4-3-2.2-5.9-2.2-8.4v-16.8c0.1-2.2,1.1-4,2.6-5.4c1.2-1.2,2.6-1.9,3.9-1.8h17.7"
          />
          <path
            fill="none"
            d="M112.9,341.8c0.3,1.5,0.6,3.2,0.6,5.1s-0.1,3.7-0.6,5.4 M112.9,352.3l-0.4,1.4c-0.6,1.8-1.4,2.8-2.2,2.8
	c-0.8,0-1.7-1-2.2-2.8s-1-4-1-6.6s0.3-4.8,1-6.6c0.6-1.8,1.4-2.8,2.2-2.8c0.8,0,1.7,1,2.2,2.8l0.4,1.5"
          />
          <path
            fill="none"
            d="M112.9,341.8l-0.4,0.8c-0.6,1.2-0.8,2.6-0.8,4.4c0,1.7,0.3,3.2,0.8,4.4l0.4,0.8 M127.5,329h-23.2 M125.5,363.5
	h0.4 M107.6,369.3H118c1.5,0,2.5-0.4,3-1.4l0.7-1.6l3.6-2.8 M125.9,363.5h-0.4 M103.4,369.3h4.1 M125.5,363.5l7-30.7 M195.6,408.8
	c3.9,1.5,8,2.3,12.5,2.3c4.4,0,8.4-0.7,12.2-2.2 M125.9,363.5h14.7 M133.7,313.3h-2.2 M690,315.4l-0.4,0.1h-0.8 M676.5,334.1v1.2
	 M688.8,334.1v-5 M688.8,326.4l0.4-0.1 M688.8,329.1v-2.8 M687.7,335.3c0.1-0.5,0.4-1,1-1.2 M688.8,326.4v-10.9 M676.5,334.1h12.2
	 M676.5,335.3h11.1 M655.2,335.3l-1.4-3.4 M655.2,335.3h-2.8c0,1.8,1.5,5.6,4.4,11.3l9.5,17.9 M676.5,335.3h-21.3 M627.2,338.1
	c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6c-1.5,1.7-3,3.4-4.4,5.1c-1.9,2.6-3.7,5.4-5.2,8.1
	c-4.4,8.4-6.7,17.7-6.7,28.1c0,10.3,2.2,19.7,6.7,28.1c1.5,2.8,3.3,5.5,5.2,8.1c1.8,2.3,3.7,4.4,5.8,6.6c1.1,1.1,2.3,2.2,3.6,3.3
	c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4l3.6-3.3c2.1-2.1,4-4.3,5.8-6.6l2.8-4c6.1-9.5,9.2-20.2,9.2-32.2
	c0-12.1-3-22.8-9.2-32.3c-0.8-1.4-1.8-2.6-2.8-4c-1.4-1.8-2.8-3.4-4.4-5.1L627.2,338.1L627.2,338.1z"
          />
          <path
            fill="none"
            d="M646.7,363.7l-2.2-6.2c-1.9-4.7-4.4-9.1-7.6-13.2l-0.3-0.3c-1.4-1.8-2.9-3.6-4.5-5.4l-1.5-1.5
	c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6l-1.5,1.5c-1.7,1.8-3.2,3.6-4.5,5.4c-1,1.2-1.8,2.5-2.8,3.9
	c-2.2,3.3-4,6.9-5.5,10.6"
          />
          <path
            fill="none"
            d="M526.3,358.3c-2.9,7.3-4.4,15.1-4.4,23.7c0,12.8,3.3,24.2,9.8,34.3c0.8,1.2,1.8,2.6,2.8,3.9
	c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1c12.2,11.7,26.8,17.6,43.9,17.6c17.1,0,31.6-5.9,43.9-17.6l1.1-1.1c2.2-2.2,4.3-4.5,6.1-6.9
	l0.3-0.3c8.3-10.9,12.2-23.5,12.2-37.7v-2.6 M649.2,379.4c-0.1-5.5-1-10.7-2.5-15.7 M614.1,363.9c2.2-0.1,3.7,0.3,5,1.2
	c-1.2-0.7-2.6-0.8-4.4-0.4l-15.7,3.6c-0.7,0.3-1.4,0.3-2.1,0.3c-0.3-0.1-0.5-0.1-0.7-0.3c-0.4-0.3-0.8-0.5-1-1
	c-0.3-0.5-0.4-1.2-0.4-1.9l-1.5-16.1v-0.1c-0.1-1.6-0.7-3-1.8-4c1.2,0.8,2.1,2.2,2.6,4.1 M605.1,365.7l8.9-1.8 M614.1,363.9
	l-1.2-1.8"
          />
          <path fill="none" d="M693.7,269.2h-0.5v1h0.5" />
          <path
            fill="none"
            d="M693.7,270.1h-0.5v28.2h0.5c0-0.1,0.1-0.4,0.3-0.5l0.1-0.1v-26.8l-0.1-0.1C693.9,270.5,693.7,270.4,693.7,270.1
	L693.7,270.1z M693.2,270.1v-2.6h-6.9c-1,0-1.5,0.3-1.8,1.1c-0.1,0.3-0.3,0.7-0.3,1.1l0.5,29.6c0.1,0.6,0.4,1.1,0.8,1.4
	c0.4,0.3,0.8,0.5,1.4,0.4h6.1v-31H693.2L693.2,270.1z M693.7,298.3h-0.5v1h0.5 M692.2,305.9v-4.7h-3.4v6.1 M690.6,314.8v-3.6
	c0-0.7-0.3-1-1-1h-0.8v5.2h1.2C690.3,315.4,690.6,315.1,690.6,314.8L690.6,314.8z M690.6,312.4v2.5h1v-2.5"
          />
          <path
            fill="none"
            d="M691.7,316.5l-1.7-1.1l-0.4,0.1h-0.8v10.9l0.4-0.1l2.5-1.4 M695.7,333.5l-4.4,0.1c-1.1,0-1.9,0.1-2.5,0.4
	c-0.5,0.3-1,0.7-1,1.2h25c3.4,0.3,5,2.6,4.7,6.6v14.6c-0.1,2.3-1.5,5.5-4,9.8c-0.8,1.1-2.2,1.6-4,1.6h1.1h-1.1l-20.9-0.5
	c0,1.2,0.8,1.9,2.5,2.1l20.6,0.3c1.1,0.1,1.9,0,2.8-0.3c0.5-0.3,0.8-0.5,1.2-1.1l0.5-0.8l1.9-3.2c1.9-3.2,2.9-5.4,2.9-6.6l-0.4-14.6
	l-0.1-4.3c-1-4-3.2-6.1-6.6-6.1L695.7,333.5L695.7,333.5z"
          />
          <path
            fill="none"
            d="M691.8,328.6v-2.3h-2.6l-0.4,0.1v2.8 M717.4,356.5v-14.6c0.3-4.1-1.2-6.3-4.7-6.6h-60.3c0,1.8,1.5,5.6,4.4,11.3
	l9.5,17.9l0.5,1.1c0.7,1,2.3,1.5,5.2,1.5l13.1,0.3l3.2,0.1l20.9,0.5c1.8-0.1,3.2-0.7,4-1.6C715.9,362.2,717.3,358.9,717.4,356.5
	L717.4,356.5z M708.3,354.3v-14c0-0.3,0.1-0.5,0.5-0.5h7c0.3,0,0.6,0.1,0.6,0.5v14c0,0.3-0.1,0.6-0.5,0.6h-7
	C708.6,354.9,708.3,354.7,708.3,354.3L708.3,354.3z"
          />
          <path
            fill="none"
            d="M708.3,340.8v13.5c0,0.3,0.1,0.5,0.5,0.5h6.5v-13.5c0-0.3-0.1-0.5-0.5-0.5H708.3z"
          />
          <path
            fill="none"
            d="M708.3,340.4v0.5h6.5c0.3,0,0.6,0.1,0.6,0.5V355h0.5c0.3,0,0.5-0.1,0.5-0.5v-14c0-0.3-0.1-0.5-0.5-0.5h-7
	C708.6,339.9,708.3,340,708.3,340.4z M716,367.5l-0.6,0.8c-0.3,0.4-0.7,0.8-1.2,1.1V381c0,0.3,0.1,0.5,0.5,0.5h0.7
	c0.3,0,0.5-0.1,0.5-0.5L716,367.5L716,367.5z M700.5,377.7c-0.1-1.1-0.3-1.6-0.4-1.6c-0.3,0-0.4,0.5-0.7,1.5
	c-0.3,1.1-0.4,2.3-0.5,3.9c-0.1,1.5-0.1,2.9-0.1,4c0.1,0.8,0.1,1.4,0.3,1.6l0.1,0.1c0.1-0.1,0.4-0.7,0.7-1.5
	c0.3-1.1,0.5-2.3,0.5-3.9C700.5,380,700.6,378.8,700.5,377.7L700.5,377.7z"
          />
          <path
            fill="none"
            d="M698.7,381.4c0.1-1.5,0.3-2.8,0.5-3.9c0.3-1.1,0.6-1.6,0.7-1.5l-14.7-4.1c-1,3.2-4.1,5.6-9.2,7.6l-1.2,0.4
	l24.1,7.2c-0.1-0.3-0.3-0.7-0.3-1.6C698.5,384.3,698.5,382.9,698.7,381.4L698.7,381.4z M699.1,387.1L699,387 M528.1,159.8
	c-11.6-0.1-21.5,0.6-29.7,1.8V173h77.2l89.4-0.6l-0.8-5.5l-0.5-4c-10-1.6-20.5-2.8-31.2-3L528.1,159.8L528.1,159.8z"
          />
          <path
            fill="none"
            d="M496.9,172v1.1h1.5v-11.4l-84.7,1.8c-10,0.3-18.4,1.1-25.2,2.5c-0.4,1-0.5,2.2-0.3,3.6l3.2,4.5L496.9,172
	L496.9,172z"
          />
          <path
            fill="none"
            d="M498.4,173h-1.5l-1,90.4l178.3-1.5c-2.5-31.4-5.6-61.2-9.4-89.4l-89.4,0.6L498.4,173L498.4,173z M511.2,186.5
	c0.1-4.4,3.2-6.5,9.4-6.3h117.8c4.8,0.3,7.4,3.2,7.8,8.8l4.4,48.1c0.4,2.5-0.4,4.5-2.6,5.9c-1.9,1.2-5.1,2.1-9.4,2.3H525.8
	c-4.7,0.3-8.1-0.3-10.6-1.9c-2.5-1.6-3.9-4.1-4-7.7V186.5L511.2,186.5z"
          />
          <path
            fill="none"
            d="M520.5,180.2c-6.2-0.1-9.2,1.9-9.4,6.3v49.1c0.1,3.6,1.4,6.1,4,7.7c2.3,1.5,5.9,2.2,10.6,1.9h112.8
	c4.3-0.3,7.4-1,9.4-2.3c2.2-1.4,3-3.4,2.6-5.9l-4.4-48.1c-0.4-5.6-3-8.5-7.8-8.8H520.5V180.2z M512.6,186.5c0.1-3.3,2.8-5,7.8-5H638
	c4,0.3,6.1,2.8,6.5,7.4l4.4,48.1c0.3,1.9-0.4,3.4-1.9,4.4c-1.8,1.1-4.7,1.8-8.7,2.1H525.5c-4.3,0.3-7.6-0.3-9.8-1.6
	c-2.1-1.4-3.2-3.4-3.3-6.5v-49h0.2V186.5z"
          />
          <path
            fill="none"
            d="M520.5,181.7c-5.1-0.1-7.7,1.5-7.8,5v49.1c0.1,3,1.2,5.1,3.3,6.5c2.2,1.4,5.4,1.9,9.8,1.6h112.8
	c4-0.3,6.9-1,8.7-2.1c1.7-1.1,2.2-2.5,1.9-4.4l-4.4-48.1c-0.3-4.7-2.5-7.2-6.5-7.4H520.5V181.7L520.5,181.7z M388.2,169.4
	c-3,0.6-5.6,2.3-7.8,5.4l-26.8,44.3l-0.7,1.2l-1,1.5l10.5,5.4l29-53.2L388.2,169.4L388.2,169.4z M495.9,307.1v3.7
	C496.2,309.5,496.2,308.2,495.9,307.1z"
          />
          <path
            fill="none"
            d="M495.9,307.1c0.3,1.1,0.3,2.3,0,3.7c-0.7,4.4-3.3,10.3-8.1,17.9c-4.5,7.8-9.6,13.2-15.3,16.5l-2.2,1.1
	c-2.3,1.1-4.7,1.6-7.2,2.1c-3.3,0.5-7.2,1-11.6,1v7.6h37.3c10-14.6,19.8-28.3,29.7-41.1l12.8-16.4c6.9-8.5,18-13.1,33.2-14
	c21.7-2.1,44.4-2.5,68.1-1c9.4,0.8,16.4,5,21.2,12.5c6.6,10.2,12.9,22.1,19.1,35.6l-19.1-0.7l1.4,3.4h21.3v-1.2
	c0.1-24.4-0.7-48.4-2.3-72.2l-178.3,1.5L495.9,307.1L495.9,307.1z M661.3,266c3.3,0,6.2,1.2,8.5,3.6c2.3,2.3,3.6,5.2,3.6,8.5
	s-1.2,6.2-3.6,8.5c-2.3,2.3-5.2,3.6-8.5,3.6s-6.2-1.2-8.5-3.6c-2.3-2.3-3.6-5.2-3.6-8.5s1.2-6.2,3.6-8.5S658,266,661.3,266
	L661.3,266z"
          />
          <path
            fill="none"
            d="M669.7,269.6c-2.3-2.3-5.2-3.6-8.5-3.6s-6.2,1.2-8.5,3.6c-2.3,2.3-3.6,5.2-3.6,8.5s1.2,6.2,3.6,8.5
	c2.3,2.3,5.2,3.6,8.5,3.6s6.2-1.2,8.5-3.6c2.3-2.3,3.6-5.2,3.6-8.5C673.2,274.8,672.1,271.9,669.7,269.6L669.7,269.6z M661.3,269.4
	c2.3,0,4.4,0.8,6.1,2.5c1.7,1.6,2.5,3.7,2.5,6.1c0,2.3-0.8,4.4-2.5,6.1c-1.7,1.6-3.7,2.5-6.1,2.5c-2.3,0-4.4-0.8-6.1-2.5
	c-1.7-1.6-2.5-3.7-2.5-6.1c0-2.3,0.8-4.4,2.5-6.1C656.9,270.3,658.8,269.4,661.3,269.4L661.3,269.4z"
          />
          <path
            fill="none"
            d="M667.3,272.1c-1.7-1.6-3.7-2.5-6.1-2.5c-2.3,0-4.4,0.8-6.1,2.5s-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1
	c1.7,1.6,3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5c1.7-1.6,2.5-3.7,2.5-6.1C669.8,275.8,669,273.7,667.3,272.1L667.3,272.1z
	 M661.3,272.1c1.7,0,3,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.3s-0.6,3-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8s-3-0.5-4.3-1.8
	s-1.8-2.6-1.8-4.3c0-1.6,0.5-3,1.8-4.3C658.1,272.6,659.6,272.1,661.3,272.1L661.3,272.1z"
          />
          <path
            fill="none"
            d="M665.4,273.8c-1.2-1.2-2.6-1.8-4.3-1.8s-3,0.5-4.3,1.8c-1.2,1.2-1.8,2.6-1.8,4.3s0.6,3,1.8,4.3
	c1.2,1.2,2.6,1.8,4.3,1.8s3-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3S666.6,275.1,665.4,273.8L665.4,273.8z M657,278.1
	c0-1.1,0.4-2.2,1.2-3s1.8-1.2,3-1.2c1.1,0,2.2,0.4,3,1.2c0.8,0.8,1.2,1.8,1.2,3c0,1.1-0.4,2.2-1.2,3s-1.8,1.2-3,1.2
	c-1.1,0-2.2-0.4-3-1.2S657,279.2,657,278.1L657,278.1z"
          />
          <path
            fill="none"
            d="M658.2,275.1c-0.8,0.8-1.2,1.8-1.2,3c0,1.1,0.4,2.2,1.2,3s1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2
	c0.8-0.8,1.2-1.8,1.2-3c0-1.1-0.4-2.2-1.2-3s-1.8-1.2-3-1.2S659.1,274.3,658.2,275.1L658.2,275.1z M658.2,278.1
	c0-0.8,0.3-1.5,0.8-2.1s1.2-0.8,2.1-0.8c0.8,0,1.5,0.3,2.1,0.8s0.8,1.2,0.8,2.1c0,0.8-0.3,1.5-0.8,2.1s-1.2,0.8-2.1,0.8
	c-0.8,0-1.5-0.3-2.1-0.8S658.2,278.9,658.2,278.1L658.2,278.1z"
          />
          <path
            fill="none"
            d="M659.1,276c-0.5,0.5-0.8,1.2-0.8,2.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8
	s0.8-1.2,0.8-2.1c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.1-0.8C660.4,275.1,659.8,275.5,659.1,276L659.1,276z M676.5,174.9
	c-0.7-2.2-1.9-3.6-3.7-4.4l-8.7-3.7l0.8,5.5c3.7,28.3,6.9,58.1,9.4,89.4H688c-2.2-21.6-4.7-42.1-7.3-61.4l-0.3-1.8l-1-6.7l-0.5-3.4
	v-0.1l-1.9-12.4L676.5,174.9L676.5,174.9z"
          />
          <path
            fill="none"
            d="M679.6,178.1c-0.1-1.8-1.1-2.8-3-3.2l0.3,1.2l1.9,12.4v0.1l0.5,3.4c0.7,0,0.8-0.6,0.7-1.7l-0.1-1.9L679.6,178.1
	L679.6,178.1z"
          />
          <path
            fill="none"
            d="M681.9,188.3l-1.9,0.3l0.1,1.9c0.3,1.1,0,1.6-0.7,1.6l1,6.7l3-0.6l-0.3-1.8l-0.8-5.1L681.9,188.3L681.9,188.3z"
          />
          <path
            fill="none"
            d="M682.5,191.3L682.5,191.3l0.7,5.1l0.5-0.7c0.3-0.6,0.5-1.1,0.5-1.9c0-0.7-0.1-1.4-0.5-1.9
	C683.3,191.6,682.9,191.3,682.5,191.3L682.5,191.3z M568.8,302.2c-6.6,0.4-12,1.6-16.2,4c-0.4,0.3-0.8,0.5-1.1,0.8
	c-0.8,0.5-1.5,1.1-2.1,1.7l-4.7,4.8l-4.8,5.9c-7.2,9.1-14.3,18.8-21.3,29.4l-5.2,8.1h3.6c0.7,0,1,0.3,1,1l8.3,0.4
	c1.5-3.7,3.3-7.2,5.5-10.6c0.8-1.2,1.8-2.6,2.8-3.9c1.4-1.8,2.9-3.7,4.5-5.4l1.5-1.5c12.4-12.4,27.4-18.6,45-18.6s32.6,6.2,45,18.6
	l1.5,1.5c1.7,1.8,3.2,3.6,4.5,5.4l0.3,0.3c3.2,4.1,5.6,8.5,7.6,13.2l2.2,6.2l19.8,0.8l-9.5-17.9c-2.9-5.8-4.4-9.5-4.4-11.3h2.8
	l-1.4-3.4l-1.9-0.1l-7.2-16c-1.1-2.5-2.2-4.5-3.6-6.3c-3.7-4.8-8.7-7.6-15-8.1C609.8,300,590.7,300.3,568.8,302.2L568.8,302.2z"
          />
          <path
            fill="none"
            d="M552.6,306.2c4.3-2.3,9.6-3.7,16.2-4c21.9-1.9,41-2.2,57.2-0.7c6.3,0.6,11.3,3.3,15,8.1c1.4,1.8,2.6,4,3.6,6.3
	l7.2,16l1.9,0.1l19.1,0.7c-6.2-13.5-12.5-25.5-19.1-35.6c-4.7-7.6-11.8-11.7-21-12.5c-23.5-1.5-46.2-1.1-68.1,1
	c-15.1,0.8-26.3,5.5-33.2,14L518.6,316c-9.8,12.8-19.7,26.5-29.7,41.1h8.7l38.5-51.3c5.5-7.3,12.8-11.6,21.6-12.8
	c20.1-2.5,42.4-3.3,66.6-2.8c10.5-0.3,18.2,2.2,23.1,7.6l1.7,2.2l5,7.6c4.1,6.7,8.3,15,12.2,24.6c-4.1-9.6-8.3-17.9-12.2-24.6
	l-5-7.6l-1.7-2.2c-5-5.4-12.7-7.8-23.1-7.6c-24.2-0.7-46.5,0.3-66.6,2.8c-8.8,1.1-16.1,5.4-21.6,12.8l-38.5,51.3h15.7l5.2-8.1
	c7-10.6,14.2-20.4,21.3-29.4l4.8-5.9l4.5-4.8c0.7-0.5,1.4-1.2,2.1-1.6C551.9,306.7,552.2,306.4,552.6,306.2L552.6,306.2z M688,261.9
	h-13.6c1.7,23.8,2.5,47.9,2.3,72.2h12.2v-33h-1.8c-0.5,0-1-0.1-1.4-0.4c-1.7,0.1-2.6-0.8-2.6-2.8l-0.5-27c0.3-1.5,1-2.3,1.9-2.5
	c0.3-0.7,1-1.1,1.8-1.1h2.2L688,261.9L688,261.9z"
          />
          <path
            fill="none"
            d="M684.1,269.7c0-0.4,0.1-0.8,0.3-1.1c-1.1,0.1-1.7,1-1.9,2.5l0.5,27c0.1,1.9,1,2.8,2.6,2.8
	c-0.4-0.3-0.7-0.8-0.8-1.4L684.1,269.7z M180.6,261.6c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1H175c-0.1,0-0.3,0-0.4,0.1
	l-0.1,0.3l-0.6,3.6l-0.1,0.8c0,0.1,0,0.3,0.1,0.3h0.1l0.3,0.1h0.4l0.3-0.1l0.1-0.3l0.4-1.9l0.3-1h2.9v1l-0.1,1.9
	c0,0.1,0,0.3,0.1,0.3h1.1l0.1-0.3l0.1-0.8L180.6,261.6L180.6,261.6z"
          />
          <path
            fill="none"
            d="M180.5,261.3c0.1,0.1,0.1,0.3,0.1,0.3l-0.6,3.6h1.1c0.1,0,0.3,0,0.3,0.1l0.1,0.3v0.3l-0.1,0.3l-0.3,0.1h-1
	l-0.1,0.5h0.3l0.1,0.1l0.1,0.1l0.3,0.4v0.4l-0.6,3.2c0,0.3-0.3,0.5-0.6,0.5h-1.1c-0.1,0-0.3,0-0.4-0.1l-0.3-0.4
	c-0.1-0.1-0.1-0.3-0.1-0.4v-0.1l-1,0.5v2.1l160.4-7.7c1.8-11.7,2.5-19,1.6-22.3l-4,0.4l-2.5,0.3l-22,2.2l-2.1,0.1
	c-1.4,5.8-2.1,12.7-2.2,20.5c0.1-7.8,0.8-14.7,2.2-20.5l-103.2,10.2l-24.1,2.2l-18,2.2c-4.5,0.8-6.9,1.8-6.9,3l-1.5,10.3l19.9-1
	l0.1-2.1l-0.6-0.4l-0.1,0.4c0,0.3-0.3,0.5-0.6,0.5H172c-0.1,0-0.3,0-0.4-0.1l-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.4l0.6-3.2
	c0.1-0.3,0.3-0.5,0.6-0.5h0.8l0.1-0.5h-0.4c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.3v-0.3c0-0.1,0-0.3,0.1-0.3
	c0.1-0.1,0.3-0.1,0.3-0.1h0.3l0.6-3.6l0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1h5.2C180.2,261.2,180.3,261.2,180.5,261.3L180.5,261.3z"
          />
          <path
            fill="none"
            d="M181.2,258.7l24.1-2.2l103-10.2v-1.1l-42.2,3.4l-7.6,0.7h-0.8 M173.1,265.3c-0.1,0.1-0.1,0.3-0.1,0.3v0.3
	c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.3,0.1h0.4h-0.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-0.8h-0.3C173.3,265.2,173.2,265.2,173.1,265.3
	L173.1,265.3z M172.8,267c-0.3,0-0.6,0.1-0.6,0.5l-0.6,3.2c0,0.1,0,0.3,0.1,0.4l0.3,0.1h1.1c0.3,0,0.6-0.1,0.6-0.5v-0.3l0.6-2.9
	v-0.3l-0.1-0.1L174,267H172.8L172.8,267z"
          />
          <path
            fill="none"
            d="M171.8,271.1l0.3,0.4c0.1,0.1,0.1,0.1,0.4,0.1h1.1c0.3,0,0.6-0.1,0.6-0.5l0.1-0.4l-0.4-0.3v0.3
	c0,0.3-0.3,0.5-0.6,0.5h-1.1L171.8,271.1L171.8,271.1z M156.3,263.9c-0.6,0.4-1.1,0.8-1.5,1.4l-0.1,0.1c-0.6,0.5-1,1.2-1.4,1.9
	c0.6-0.3,0.8,0.5,0.7,2.6c-0.3,2.5-1.1,6.9-2.6,12.9l-0.6,2.5c0.8-0.3,1.5-0.7,2.5-1l1.5-10.3L156.3,263.9L156.3,263.9z"
          />
          <path
            fill="none"
            d="M153.9,269.9c0.1-2.1,0-2.9-0.7-2.6c-0.3,0.4-0.4,0.8-0.7,1.2l-5.5,18.7c1.1-0.7,2.3-1.4,3.7-1.9l0.6-2.5
	C151,276.7,151.9,272.5,153.9,269.9L153.9,269.9z M172.2,277.6c-0.3,0-0.6,0.1-0.6,0.5v3.3l7.4-0.8v-2.5c0-0.3-0.1-0.5-0.6-0.5h-1.9
	v1.8h-2.2l0.1-1.8H172.2L172.2,277.6z"
          />
          <path
            fill="none"
            d="M171.7,278c0-0.3,0.1-0.5,0.6-0.5h2.3l0.1-4.3l-19.9,1l-1.5,10.3c3.4-1.2,7.4-1.9,12-2.3l5.9-0.8v-1.6h0.7
	L171.7,278L171.7,278z"
          />
          <path
            fill="none"
            d="M171.7,281.3v-1.6H171v1.6 M171.7,281.3l-0.7,0.1l-5.9,0.8c-4.5,0.4-8.5,1.2-12,2.3c-0.8,0.3-1.6,0.5-2.5,1
	c-1.4,0.5-2.6,1.2-3.7,1.9c-1.2,0.8-2.5,1.8-3.4,2.8c-1.1,1.1-2.1,2.3-2.9,3.7c-2.5,4.1-4.8,9.2-7.2,15.3c-0.4,0.8-0.1,1.5,1.1,1.9
	l5.1,1.7l2.6,0.3h9.5c1.1,0.1,1.9-0.3,2.5-1.2c5-6.5,9.6-10.3,14.2-12c5.1-1.6,12.9-2.5,23.8-2.8h49.4c7.2,0.1,12.9,3.3,17.3,9.1
	c11.6,14.4,23.1,30.4,34.7,48.1h26c-13.6-22.8-27-42.5-40-58.9c-6.9-9.5-16.2-14.7-28.1-15.7c-25.7-1.6-49.9-1.4-72.5,0.8
	L171.7,281.3L171.7,281.3z M148.7,298.6c0.8-0.8,1.9-1.2,3-1.2c1.2,0,2.2,0.4,3,1.2c0.8,0.8,1.2,1.9,1.2,3c0,1.2-0.4,2.2-1.2,3
	s-1.9,1.2-3,1.2c-1.2,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.9-1.2-3S147.9,299.6,148.7,298.6L148.7,298.6z"
          />
          <path
            fill="none"
            d="M151.7,297.4c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3s1.9,1.2,3,1.2s2.2-0.4,3-1.2
	c0.8-0.8,1.2-1.9,1.2-3c0-1.2-0.4-2.2-1.2-3C154.1,297.8,153,297.4,151.7,297.4L151.7,297.4z M152.6,268.6c0.1-0.4,0.4-0.8,0.7-1.2
	c0.4-0.7,0.8-1.4,1.4-1.9c-1.8,0.7-3.3,2.3-4.7,5.1l-6.3,19.7c1-1,2.2-1.9,3.4-2.8L152.6,268.6L152.6,268.6z"
          />
          <path
            fill="none"
            d="M153.9,269.9c-2.1,2.6-2.9,6.9-2.6,12.9C152.8,276.7,153.7,272.5,153.9,269.9z M181.4,265.3
	c-0.1-0.1-0.3-0.1-0.3-0.1H180l-0.1,0.8l-0.1,0.3h1.5l0.3-0.1l0.1-0.3v-0.3L181.4,265.3L181.4,265.3z M178.6,264.1v-0.8h-2.9
	l-0.3,0.8 M180.2,267.1h-1.4c-0.1,0-0.3,0-0.4,0.1l-0.1,0.4l-0.6,3v0.1c0,0.1,0,0.3,0.1,0.4l0.3,0.1h1.1c0.3,0,0.6-0.1,0.6-0.5
	l0.6-3.2v-0.3L180.2,267.1L180.2,267.1z"
          />
          <path
            fill="none"
            d="M179.9,267l0.1-0.5h-1.6c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-1.9h-3.2l-0.4,1.9l-0.1,0.3l-0.3,0.1H174l-0.3-0.1
	l-0.1,0.5h0.6l0.1,0.1h4.3c0.1-0.1,0.3-0.1,0.4-0.1H179.9L179.9,267z"
          />
          <path
            fill="none"
            d="M178.3,267.5l0.3-0.4h-4.3v0.4l-0.6,3l0.4,0.3l0.6,0.4v2.1l-0.3,4.3v1.8h2.2l0.1-1.8v-4.4l0.1-2.1l0.8-0.5
	 M180.8,267.6l-0.3-0.4v0.3l-0.6,3.2c0,0.3-0.3,0.5-0.6,0.5h-1.1l-0.3-0.1l0.3,0.4c0.1,0.1,0.1,0.1,0.4,0.1h1.1
	c0.3,0,0.6-0.1,0.6-0.5l0.6-3.2L180.8,267.6L180.8,267.6z M192.2,297c-10.9,0.1-18.7,1.1-23.8,2.8c-4.5,1.5-9.2,5.5-14.2,12
	c-0.6,1-1.4,1.4-2.5,1.2h-9.5l-2.6-0.3l-5.1-1.6l-1,2.3h0.4l6.9,1.8h9.6l1.9,1l-11.1,44.2h5.2c1.6-6.2,4.1-11.8,7.7-17.2
	c0.8-1.2,1.8-2.6,2.8-3.9c1.4-1.8,2.9-3.7,4.5-5.4l1.5-1.5c12.4-12.4,27.4-18.6,45-18.6s32.6,6.2,45,18.6l1.5,1.5
	c1.6,1.8,3.2,3.6,4.5,5.4l0.3,0.3c4.8,6.5,8.3,13.5,10.3,21l28.3,0.1l-1.5-2.9c-0.3-0.7-0.1-1,0.4-1h14.7l-2.1-2.8h-16
	c-11.6-17.7-23.1-33.7-34.7-48.1c-4.4-5.9-10.3-8.9-17.3-9.1L192.2,297L192.2,297z M352.7,155.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1
	c0,0.1,0.1,0.3,0.1,0.4v63.3l0.7-1.2v-62h0.1l0.1-0.4v-1.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1S352.9,155,352.7,155.1z
	 M266,248.7l-1.8-4.3l-5,0.6v0.1l-0.8,4.3"
          />
          <path
            fill="none"
            d="M257.7,245.8v3.7l0.8-0.1l0.8-4.1l-1.2,0.1C257.8,245.2,257.7,245.4,257.7,245.8L257.7,245.8z M357,237.4
	l5.5-10L352,222l-12.5,20.8l-0.4,0.6c0.7,3.2,0.1,10.6-1.6,22.3l12.8-0.1c0.5-6.9,1.5-12.8,2.8-17.6l0.7-2.6
	C354.5,242.1,355.6,239.4,357,237.4L357,237.4z M358.1,229.5c-0.3,0.3-0.5,0.4-1,0.4s-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1
	s0.1-0.7,0.4-1s0.5-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1C358.4,229,358.4,229.4,358.1,229.5L358.1,229.5z M352.3,234.9
	l0.5,0.1l0.4,0.3l0.3,0.6l0.1,0.4l-0.1,0.4l-0.3,0.4l-0.4,0.3l-0.5,0.1c-0.4,0-0.7-0.1-1-0.4s-0.4-0.6-0.4-1s0.1-0.7,0.4-1
	C351.6,235,352,234.9,352.3,234.9L352.3,234.9z M345.1,249.6c0-0.4,0.1-0.7,0.4-1s0.5-0.4,1-0.4s0.7,0.1,1,0.4s0.4,0.6,0.4,1
	s-0.1,0.7-0.4,1s-0.5,0.4-1,0.4s-0.7-0.1-1-0.4C345.3,250.2,345.1,249.9,345.1,249.6L345.1,249.6z M342.5,240.6c0,0.3-0.1,0.6-0.3,1
	l-0.5,0.7c-0.3,0.1-0.4,0.1-0.5,0.1c-0.1-0.1-0.3-0.3-0.1-0.6c0-0.3,0.1-0.6,0.3-0.8c0.1-0.3,0.4-0.6,0.5-0.7l0.5-0.1
	C342.5,240,342.5,240.3,342.5,240.6L342.5,240.6z M343.8,258c0-0.4,0.1-0.7,0.4-1s0.5-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4s0.4,0.5,0.4,1
	s-0.1,0.7-0.4,1s-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4S343.8,258.4,343.8,258L343.8,258z"
          />
          <path
            fill="none"
            d="M357.1,230c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4s-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1C356.4,229.8,356.7,230,357.1,230L357.1,230z M352.9,235.1l-0.5-0.1c-0.4,0-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4l0.5-0.1l0.4-0.3l0.3-0.4v-1l-0.3-0.6L352.9,235.1L352.9,235.1z"
          />
          <path
            fill="none"
            d="M353.7,236.1l-0.1-0.4v1 M345.6,248.7c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4
	c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4S345.8,248.4,345.6,248.7L345.6,248.7z M342.3,241.4c0.1-0.3,0.3-0.6,0.3-1
	c0-0.3,0-0.4-0.1-0.6l-0.5,0.1c-0.3,0.1-0.4,0.4-0.5,0.7s-0.3,0.6-0.3,0.8s0,0.6,0.1,0.6c0.1,0.1,0.4,0,0.5-0.1L342.3,241.4
	L342.3,241.4z M344.2,257.1c-0.3,0.3-0.4,0.5-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1
	s-0.5-0.4-1-0.4C344.7,256.8,344.5,256.9,344.2,257.1L344.2,257.1z M339,243.3l0.4-0.7l-4.5,0.4l0.1,0.6"
          />
          <path
            fill="none"
            d="M332.9,242.8l-0.1,0.4l-0.3,0.7l2.5-0.3l-0.1-0.6l-0.1-0.4"
          />
          <path fill="none" d="M332.5,243.9l0.3-0.7l-22.4,1.9l0.1,1" />
          <path
            fill="none"
            d="M310.1,242.6c-0.3-0.6-1-0.6-2.1-0.1l0.3,2.8l0.1,1.1l2.1-0.1l-0.1-1.1L310.1,242.6L310.1,242.6z M308.4,244.4
	c-0.1-0.1-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.3,0.5-0.3s0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.6s-0.1,0.4-0.3,0.6
	c-0.1,0.1-0.3,0.3-0.5,0.3C308.8,244.5,308.7,244.5,308.4,244.4z"
          />
          <path
            fill="none"
            d="M308.3,243.9c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.3,0.3-0.6
	s-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3C308.3,243.4,308.3,243.6,308.3,243.9z M369.1,322.5
	c0-0.8-0.5-1.4-1.6-1.5l-10.6-1.2h-1.1l11,2.2v2.1h-11.6v3.6h11.6v2.1l-11,2.2h1.1l10.6-1.2c1.1-0.1,1.6-0.7,1.6-1.5V322.5
	L369.1,322.5z M357.1,325.2c0.3-0.3,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8
	c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8C356.7,325.6,356.8,325.3,357.1,325.2L357.1,325.2z
	 M362.9,325.8c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8
	c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3S362.9,326.1,362.9,325.8L362.9,325.8z"
          />
          <path
            fill="none"
            d="M357.8,324.7c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8C358.4,324.9,358.1,324.7,357.8,324.7L357.8,324.7z
	 M363.2,325.2c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3
	c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3C363.6,324.7,363.3,324.9,363.2,325.2L363.2,325.2z"
          />
          <path
            fill="none"
            d="M354.1,319.9c-0.5,0.1-1.1,0.3-1.7,0.5V331l1.6,0.5h0.3l1.4,0.1l11-2.2v-2.1h-11.6v-3.6h11.6v-2.1l-11-2.2
	l-1.4,0.1L354.1,319.9L354.1,319.9z M311.6,356.9h-14.7c-0.7,0-0.8,0.3-0.4,1l1.5,2.9l3.4,6.3c0.3,0.7,0.8,1,1.5,1h214.2
	c0.7,0,1-0.3,1-1v-9.2c0-0.7-0.3-1-1-1L311.6,356.9L311.6,356.9z M254.6,334l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6
	s-32.6,6.2-45,18.6l-1.5,1.5c-1.6,1.8-3.2,3.6-4.5,5.4c-1,1.2-1.8,2.5-2.8,3.9c-3.4,5.4-6.1,11.1-7.7,17.2c-1.4,5.4-2.2,11-2.2,16.9
	c0,12.8,3.3,24.2,9.8,34.3c0.8,1.2,1.8,2.6,2.8,3.9c1.8,2.3,3.9,4.7,6.1,6.9c2.1,2.1,4.3,4,6.5,5.8c11,8.7,23.9,12.9,38.5,12.9
	s27.5-4.3,38.5-12.9c2.2-1.8,4.4-3.6,6.5-5.8c2.2-2.2,4.3-4.5,6.1-6.9l0.3-0.3c8.3-10.9,12.2-23.5,12.2-37.7
	c0-5.8-0.7-11.4-2.1-16.6c-1.9-7.6-5.4-14.7-10.3-21l-0.3-0.3C257.8,337.4,256.3,335.6,254.6,334L254.6,334z M256.7,341.1
	c1,1.2,1.9,2.6,2.8,4c6.1,9.5,9.1,20.2,9.1,32.3c0,12-3,22.7-9.1,32.2c-0.8,1.4-1.8,2.6-2.8,4c-1.8,2.3-3.7,4.4-5.8,6.6
	c-3,3-6.2,5.6-9.6,8c-9.6,6.5-20.8,9.8-33.2,9.8s-23.5-3.3-33.2-9.8c-3.3-2.2-6.6-5-9.6-8c-2.1-2.1-4-4.3-5.8-6.6
	c-1.9-2.6-3.7-5.4-5.2-8.1c-4.4-8.4-6.7-17.7-6.7-28.1c0-10.3,2.2-19.7,6.7-28.1c1.5-2.8,3.3-5.5,5.2-8.1c1.4-1.8,2.8-3.4,4.4-5.1
	l2.6-2.6c11.6-11,25.4-16.5,41.5-16.5s30,5.5,41.5,16.5l2.6,2.6C253.8,337.7,255.3,339.3,256.7,341.1L256.7,341.1z"
          />
          <path
            fill="none"
            d="M259.4,345.1c-0.8-1.4-1.8-2.6-2.8-4c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.4-16.5-41.5-16.5
	s-30,5.5-41.5,16.5L164,336c-1.5,1.6-3,3.4-4.4,5.1c-1.9,2.6-3.7,5.4-5.2,8.1c-4.4,8.4-6.7,17.7-6.7,28.1s2.2,19.7,6.7,28.1
	c1.5,2.8,3.3,5.5,5.2,8.1c1.8,2.3,3.7,4.4,5.8,6.6c3,3,6.2,5.6,9.6,8c9.8,6.5,20.8,9.8,33.2,9.8s23.5-3.3,33.2-9.8
	c3.3-2.2,6.6-5,9.6-8c2.1-2.1,4-4.3,5.8-6.6c1-1.2,1.9-2.6,2.8-4c6.1-9.5,9.1-20.2,9.1-32.2C268.5,365.3,265.5,354.6,259.4,345.1
	L259.4,345.1z M244.7,394.3c-1.9,4.1-4.7,8-8.1,11.4c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6s-10.2-0.8-14.6-2.6
	c-5.1-1.9-9.6-5-13.8-9.1c-2.9-2.9-5.2-5.9-7-9.2c-3.2-5.8-4.8-12.2-4.8-19.3c0-11.1,4-20.6,11.8-28.5c7.8-7.8,17.3-11.8,28.5-11.8
	s20.6,4,28.5,11.8c7.8,7.8,11.8,17.3,11.8,28.5C248.3,383.5,247.1,389.1,244.7,394.3L244.7,394.3z"
          />
          <path
            fill="none"
            d="M236.6,405.8c3.4-3.4,6.2-7.3,8.1-11.4c2.5-5.2,3.6-10.9,3.6-17.1c0-11.1-4-20.6-11.8-28.5S219.1,337,208,337
	s-20.6,4-28.5,11.8c-7.8,7.8-11.8,17.3-11.8,28.5c0,7.2,1.6,13.5,4.8,19.3c1.8,3.3,4.1,6.3,7,9.2c4.1,4.1,8.7,7.2,13.8,9.1
	c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6C227.8,412.9,232.5,409.9,236.6,405.8L236.6,405.8z M243.1,363.5
	c0.7,1.8,1.2,3.6,1.6,5.5c0.6,2.6,0.8,5.4,0.8,8.3s-0.3,5.6-0.8,8.3c-1.5,6.9-4.8,12.9-10,18.2l-0.1,0.1
	c-7.3,7.2-16.1,10.9-26.4,10.9s-19.1-3.7-26.4-11c-4.1-4.1-7.2-8.8-8.9-14c-1.4-3.9-2.1-8-2.1-12.5c0-2.1,0.1-4,0.4-5.9v-0.1
	c0.3-2.2,0.8-4.4,1.5-6.5l0.6-1.4c1.8-4.7,4.7-8.9,8.4-12.7c5.9-5.9,12.8-9.4,20.6-10.6l5.8-0.4c10.3,0,19.1,3.7,26.4,11
	C238.4,354.7,241.3,359,243.1,363.5L243.1,363.5z"
          />
          <path
            fill="none"
            d="M215.6,371.9c0.4-0.8,1-1.4,1.8-1.8l1-0.3c1.6-0.5,3.3-0.7,4.7-0.3c1.5,0.3,2.3,1,2.6,2.1s0,2.2-1,3.3
	c-1,1.1-2.2,1.9-4,2.5H235l6.6-2.8h0.1c1.4-0.5,2.3-1.4,3-2.3l0.3-0.5l-0.3,0.7c-0.6,1.1-1.5,2.2-2.9,3.2v1.9h3.7
	c0-2.9-0.3-5.6-0.8-8.3c-0.4-1.9-1-3.7-1.6-5.5c-1.8-4.7-4.7-8.9-8.4-12.7c-7.3-7.3-16.1-11-26.4-11l-5.8,0.4c-1,1-1.6,2.3-1.8,4.1
	l-1.5,16c0,0.8-0.1,1.5-0.4,2.1c-0.4,0.5-0.8,1-1.4,1.1l-0.3,0.1l-1.7-0.1l-16.1-3.7c-1.6-0.4-3.2-0.3-4.4,0.4c1.2-1,2.8-1.4,5-1.2
	c1.2-1.9,2.8-3.9,4.5-5.5c4.5-4.5,9.6-7.4,15.4-8.9c0.6-2.1,1.4-3.4,2.6-4.3c-7.8,1.1-14.9,4.7-20.6,10.6c-3.9,3.9-6.6,8.1-8.4,12.7
	l-0.6,1.4c-0.7,2.1-1.2,4.3-1.5,6.5c0.3,0.7,0.8,1.4,1.5,1.9c0.6,0.4,1.1,0.7,1.8,1l7.3,3h13.5c-1.5-0.5-2.8-1.4-3.7-2.3
	c-1-1.1-1.2-2.2-1-3.3c0.3-1.1,1.2-1.8,2.6-2.1s3-0.3,4.7,0.3l1,0.3c0.7,0.4,1.2,1,1.6,1.8c0.6,1,0.6,1.9,0.3,3s-1,1.8-1.9,2.3h4
	c0-1.5,0.6-2.8,1.5-3.7c1.1-1.1,2.3-1.5,3.7-1.5c1.5,0,2.8,0.5,3.7,1.5c1.1,1,1.6,2.3,1.6,3.7h4c-0.1,0-0.1-0.1-0.3-0.1
	c-1-0.5-1.6-1.2-1.9-2.3C215,373.8,215,372.9,215.6,371.9L215.6,371.9z M215.8,344.5v-0.1c-0.1-1.6-0.7-3-1.8-4
	c1.2,0.8,2.1,2.2,2.6,4.1c5.8,1.4,10.7,4.4,15.3,8.8c1.8,1.8,3.4,3.7,4.7,5.8c2.2-0.1,3.7,0.3,5,1.2c-1.2-0.7-2.6-0.8-4.4-0.4
	l-15.7,3.6c-0.7,0.3-1.4,0.3-2.1,0.3l-0.7-0.3c-0.4-0.3-0.8-0.5-1-1c-0.3-0.6-0.4-1.2-0.4-1.9L215.8,344.5L215.8,344.5z
	 M211.8,363.5l0.1,2.8c-0.1,0.8-0.6,1.5-1.1,2.2c-0.8,0.8-1.6,1.1-2.8,1.1s-2.1-0.4-2.8-1.1c-0.6-0.7-1-1.4-1.1-2.2v-1
	c0-0.7,0-1.2,0.1-1.8c0.1-1.1,0.6-1.9,1-2.8c0.8-1.2,1.6-1.9,2.8-1.9s2.1,0.7,2.8,1.9C211.3,361.6,211.7,362.6,211.8,363.5z"
          />
          <path
            fill="none"
            d="M174.6,375.2l-1.8-1.4c-0.8-0.8-1.2-1.6-1.5-2.5c-0.3,1.9-0.4,3.9-0.4,5.9h3.7
	C174.4,376.6,174.4,375.9,174.6,375.2L174.6,375.2z M202.9,377.3h-4c-0.8,0.4-1.5,0.5-2.5,0.4l-1-0.3c-0.1,0-0.3-0.1-0.3-0.1h-13.5
	l7.6,3.3c0.8,0.3,1.4,0.5,1.8,1c0.6,0.7,0.7,1.4,0.7,2.1c-0.1,0.5-0.4,1.2-0.8,1.9l-8.3,13.8c-1,1.5-1.2,2.9-1,4.4
	c-0.6-1.4-0.4-3.2,0.4-5.1c-5.1-6.1-7.6-13.2-7.6-21.3h-3.7c0,4.4,0.7,8.5,2.1,12.5c1.8,5.1,4.8,9.8,8.9,14c7.3,7.3,16.1,11,26.4,11
	s19.1-3.6,26.4-10.9c0.1-1.4-0.1-2.8-1.1-4.3l-8.3-13.9c-0.4-0.5-0.8-1.2-0.8-1.9c0-0.8,0.1-1.5,0.7-2.1c0.4-0.4,1-0.8,1.8-1
	l8.3-3.6h-14.3l-1,0.3c-0.8,0.1-1.5,0-2.2-0.3h-4c0,1.5-0.6,2.8-1.6,3.9c-1,1-2.3,1.5-3.7,1.5c-1.5,0-2.8-0.5-3.7-1.5
	C203.3,380,202.9,378.8,202.9,377.3L202.9,377.3z M198.5,383.9c0.6-0.5,1.4-1,2.2-1.1c1.1-0.1,2.1,0,2.9,0.7s1.4,1.5,1.5,2.6
	c0.1,0.8,0,1.6-0.4,2.3l-0.6,0.8c-1.1,1.5-2.2,2.5-3.6,3c-1.4,0.5-2.5,0.5-3.3-0.1c-0.8-0.7-1.2-1.6-1.1-3.2
	c0.1-1.5,0.7-2.9,1.8-4.4L198.5,383.9L198.5,383.9z M205.4,396.3c0.4-0.7,1-1.1,1.5-1.4c0.8-0.3,1.5-0.3,2.2,0
	c0.6,0.3,1.1,0.7,1.5,1.4l10.7,12.2c1.1,1.2,2.3,1.9,3.7,2.2c-1.5,0.1-3-0.5-4.7-1.8c-3.7,1.5-7.8,2.2-12.2,2.2
	c-4.5,0-8.7-0.8-12.5-2.3c-1.6,1.4-3.3,1.9-4.7,1.9c1.4-0.1,2.8-1,3.9-2.3L205.4,396.3L205.4,396.3z M211,386.1
	c0.1-1.1,0.7-1.9,1.6-2.6c0.8-0.7,1.8-0.8,2.9-0.7c0.8,0.1,1.5,0.5,2.2,1.1l0.7,0.8c1.1,1.4,1.6,2.9,1.8,4.4s-0.3,2.5-1.1,3.2
	s-1.9,0.7-3.3,0.1s-2.6-1.5-3.6-3l-0.6-0.8C211,387.7,210.9,386.9,211,386.1L211,386.1z"
          />
          <path
            fill="none"
            d="M245.6,377.3h-3.7c0,8.3-2.6,15.4-7.7,21.5c0.8,1.9,1,3.6,0.4,5l0.1-0.1c5.2-5.2,8.7-11.3,10-18.2
	C245.3,383.1,245.6,380.2,245.6,377.3L245.6,377.3z M231.9,353.5c-4.4-4.5-9.5-7.4-15.3-8.8l1,9.1c3,1.2,5.8,3,8.4,5.6l1.6,1.6
	l8.9-1.9C235.4,357.2,233.7,355.4,231.9,353.5L231.9,353.5z"
          />
          <path
            fill="none"
            d="M215.8,344.4v0.1l1.5,16.1c0,0.8,0.1,1.5,0.4,1.9c0.3,0.4,0.6,0.7,1,1l0.7,0.3c0.6,0.1,1.2,0,2.1-0.3l15.7-3.6
	c1.8-0.4,3.2-0.3,4.4,0.4c-1.1-1-2.8-1.4-5-1.2l-8.9,1.9l-4.4,1c-1.5,0.3-2.6,0.1-3.4-0.5c-0.8-0.5-1.4-1.5-1.6-3l-0.6-4.7l-1-9.1
	c-0.6-1.9-1.5-3.4-2.6-4.1C215,341.4,215.7,342.8,215.8,344.4z"
          />
          <path
            fill="none"
            d="M226,359.4c-2.5-2.5-5.2-4.4-8.4-5.6l0.6,4.7c0.1,1.5,0.7,2.6,1.6,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1L226,359.4z
	 M225.6,371.6c-0.3-1.1-1.2-1.8-2.6-2.1c-1.5-0.3-3-0.3-4.7,0.3l-1,0.3c-0.8,0.4-1.4,1-1.8,1.8c-0.6,1-0.6,1.9-0.3,3
	c0.3,1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3c1.8-0.5,3-1.4,4-2.5C225.6,373.7,226,372.6,225.6,371.6L225.6,371.6z
	 M218,370.4c0.8-0.3,1.8-0.3,2.6,0.1s1.4,1.1,1.6,2.1c0.3,0.8,0.3,1.8-0.1,2.6c-0.4,0.8-1.1,1.5-2.1,1.8s-1.8,0.3-2.6-0.3
	c-0.8-0.4-1.5-1.1-1.8-1.9c-0.3-1-0.3-1.8,0.3-2.6S217.2,370.7,218,370.4L218,370.4z"
          />
          <path
            fill="none"
            d="M220.7,370.7c-0.8-0.4-1.8-0.5-2.6-0.1c-1,0.3-1.5,0.8-2.1,1.6c-0.4,0.8-0.6,1.8-0.3,2.6c0.3,1,0.8,1.5,1.8,1.9
	c0.8,0.4,1.6,0.5,2.6,0.3c1-0.3,1.6-0.8,2.1-1.8c0.4-0.8,0.6-1.6,0.1-2.6C222.2,371.8,221.6,371.1,220.7,370.7L220.7,370.7z
	 M217.8,383.9c-0.6-0.5-1.4-1-2.2-1.1c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.5-1.4,1.5-1.6,2.6c-0.1,0.8,0,1.6,0.4,2.5l0.6,0.8
	c1.1,1.5,2.2,2.5,3.6,3c1.4,0.6,2.5,0.6,3.3-0.1c1-0.7,1.2-1.7,1.1-3.2c-0.1-1.5-0.7-3-1.8-4.4L217.8,383.9L217.8,383.9z
	 M216.9,389.5c-0.8,0.6-1.6,0.8-2.6,0.6c-1-0.1-1.6-0.5-2.2-1.4c-0.6-0.8-0.8-1.6-0.6-2.6c0.1-1,0.6-1.6,1.4-2.2
	c0.8-0.5,1.6-0.8,2.6-0.5c1,0.1,1.6,0.5,2.2,1.4c0.6,0.8,0.8,1.6,0.6,2.6C218.2,388.2,217.8,389,216.9,389.5L216.9,389.5z"
          />
          <path
            fill="none"
            d="M214.3,390.1c1,0.1,1.8,0,2.6-0.5c0.8-0.6,1.2-1.4,1.4-2.2c0.1-1,0-1.8-0.6-2.6c-0.6-0.8-1.4-1.2-2.2-1.4
	c-1-0.1-1.8,0-2.6,0.5s-1.2,1.2-1.4,2.2c-0.1,1,0,1.8,0.6,2.6C212.7,389.5,213.5,389.9,214.3,390.1L214.3,390.1z M224.9,410.7
	c-1.4-0.1-2.6-0.8-3.7-2.2l-10.7-12.2c-0.4-0.7-1-1.1-1.5-1.4c-0.7-0.3-1.4-0.3-2.2,0c-0.6,0.3-1.1,0.7-1.5,1.4l-10.6,12.1
	c-1.1,1.4-2.5,2.1-3.9,2.3c1.5,0.1,3-0.5,4.7-1.9l9.2-10.2c0.8-0.8,1.5-1.4,2.3-1.5h0.8l0.8,0.1c0.8,0.1,1.5,0.7,2.3,1.5l9.4,10.3
	C221.9,410.2,223.4,410.7,224.9,410.7L224.9,410.7z"
          />
          <path
            fill="none"
            d="M207.9,397.1H207c-0.8,0.1-1.5,0.7-2.3,1.5l-9.2,10.2c3.9,1.5,8,2.3,12.5,2.3c4.4,0,8.4-0.7,12.2-2.2l-9.4-10.3
	c-0.8-0.8-1.5-1.4-2.3-1.5H207.9L207.9,397.1z M211.8,363.5c-0.1-1.1-0.6-1.9-1-2.8c-0.8-1.2-1.6-1.9-2.8-1.9s-2.1,0.7-2.8,1.9
	c-0.6,0.8-0.8,1.8-1,2.8c-0.1,0.5-0.1,1.1-0.1,1.8v1c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.8,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1
	c0.6-0.7,1-1.4,1.1-2.2L211.8,363.5L211.8,363.5z M210.5,363.3l0.3,0.3c0.6,0.5,0.8,1.4,0.8,2.2c0,1-0.3,1.8-1,2.3
	c-0.7,0.7-1.5,1.1-2.3,1.1c-1,0-1.8-0.3-2.5-1.1c-0.7-0.7-1-1.5-1-2.3s0.3-1.5,0.8-2.2l0.3-0.3c0.7-0.7,1.5-1,2.5-1
	C209.1,362.3,209.8,362.6,210.5,363.3z"
          />
          <path
            fill="none"
            d="M210.7,363.5l-0.3-0.3c-0.7-0.7-1.5-1-2.3-1c-1,0-1.8,0.3-2.5,1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2
	c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1.1,2.5,1.1s1.8-0.3,2.3-1.1c0.7-0.7,1-1.5,1-2.3C211.6,364.9,211.3,364.2,210.7,363.5z
	 M198.9,370.3l-1-0.3c-1.6-0.5-3.3-0.7-4.7-0.3c-1.5,0.3-2.3,1.1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.3,1.9,4,2.5l1,0.3
	c0.8,0.1,1.6,0,2.5-0.4c1-0.6,1.6-1.2,1.9-2.3s0.3-2.1-0.3-3C200.2,371.2,199.6,370.7,198.9,370.3L198.9,370.3z M195.5,370.7
	c0.8-0.4,1.8-0.5,2.6-0.3c1,0.3,1.5,0.8,1.9,1.8c0.4,0.8,0.6,1.6,0.3,2.6s-0.8,1.5-1.8,2.1c-0.8,0.4-1.8,0.5-2.6,0.1
	c-1-0.3-1.5-0.8-2.1-1.7c-0.4-0.8-0.6-1.8-0.3-2.6C194.1,371.8,194.7,371.2,195.5,370.7L195.5,370.7z"
          />
          <path
            fill="none"
            d="M198.1,370.5c-1-0.3-1.8-0.3-2.6,0.3c-0.8,0.4-1.4,1.1-1.6,2.1c-0.3,0.8-0.3,1.8,0.3,2.6
	c0.4,0.8,1.1,1.4,2.1,1.6c0.8,0.3,1.8,0.3,2.6-0.1s1.5-1.1,1.8-2.1s0.3-1.8-0.3-2.6C199.7,371.4,199.1,370.8,198.1,370.5
	L198.1,370.5z M212,373.6c-1-1.1-2.3-1.5-3.7-1.5c-1.5,0-2.8,0.5-3.7,1.5s-1.5,2.3-1.5,3.7c0,1.4,0.6,2.8,1.5,3.9
	c1.1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5c1.1-1.1,1.6-2.3,1.6-3.9C213.6,375.8,212.9,374.5,212,373.6L212,373.6z M208.8,376.9
	l0.4-0.1h0.4c0.3,0,0.4,0.1,0.6,0.4l0.1,0.3v0.3h-1.2v0.1c0,0.1,0.1,0.1,0.3,0.1h0.1l0.1-0.1h0.6l-0.1,0.1l-0.4,0.1l-0.4,0.1H209
	c-0.3-0.1-0.4-0.3-0.6-0.4v-0.3c0-0.1,0-0.4,0.1-0.5L208.8,376.9L208.8,376.9z M208.1,377.2l0.1,0.3v0.3H207v0.3l0.1,0.1h0.1
	l0.1-0.1h0.6l-0.1,0.1c-0.1,0.1-0.1,0.1-0.3,0.1l-0.6,0.1h-0.3c-0.3-0.1-0.4-0.1-0.6-0.4l-0.1-0.4l0.1-0.3v-0.1
	c0.1-0.1,0.1-0.3,0.3-0.3l0.4-0.1h0.4C207.9,376.9,208,376.9,208.1,377.2L208.1,377.2z M211.3,376.7h0.6c0.1,0.1,0.3,0.1,0.3,0.3
	l0.1,0.3v0.5l-0.1,0.3l-0.1,0.1h-0.7l-0.1-0.1v0.5h-0.6v-1.9L211.3,376.7L211.3,376.7L211.3,376.7z M204.1,377.3h0.6v0.4l0.3,0.1
	h0.3l0.1-0.3V376h0.7v1.4l-0.1,0.3l-0.6,0.3h-0.3l-0.7-0.1c-0.3-0.1-0.4-0.3-0.4-0.4L204.1,377.3L204.1,377.3z"
          />
          <path
            fill="none"
            d="M209.1,376.7l-0.4,0.1l-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.5v0.3c0.1,0.3,0.3,0.4,0.6,0.4h0.3l0.4-0.1l0.4-0.1
	l0.1-0.1h-0.6l-0.1,0.1h-0.1c-0.1,0-0.3-0.1-0.3-0.1v-0.1h1.2v-0.3l-0.1-0.3c-0.1-0.3-0.3-0.3-0.6-0.4L209.1,376.7L209.1,376.7z
	 M209,377.3v-0.1l0.1-0.1h0.1l0.3,0.1v0.1H209L209,377.3z"
          />
          <path
            fill="none"
            d="M209,377.3h0.7l-0.3-0.3h-0.1l-0.3,0.1 M208.3,377.4l-0.1-0.3c-0.1-0.3-0.3-0.3-0.6-0.4h-0.4l-0.4,0.1
	c-0.1,0.1-0.3,0.1-0.3,0.3v0.1l-0.1,0.3l0.1,0.4c0.1,0.3,0.3,0.3,0.6,0.4h0.3l0.6-0.1c0.1,0,0.3-0.1,0.3-0.1l0.1-0.1h-0.6l-0.1,0.1
	h-0.1l-0.1-0.1v-0.3h1.2L208.3,377.4L208.3,377.4z M207.2,377h0.1l0.3,0.1v0.1H207v-0.1L207.2,377L207.2,377z"
          />
          <path
            fill="none"
            d="M207.4,377h-0.1c-0.1,0-0.1,0-0.1,0.1v0.1h0.6v-0.1L207.4,377L207.4,377z M211.7,376.7h-0.6l-0.3,0.1l-0.1,0.1
	v-0.3h-0.6v2.1h0.6v-0.5l0.1,0.1h0.7l0.1-0.1l0.1-0.3v-0.5l-0.1-0.3C212,376.9,211.8,376.9,211.7,376.7L211.7,376.7z M210.9,377.7
	l0.1-0.4c0.1-0.1,0.1-0.1,0.3-0.1h0.1l0.1,0.1v0.4h-0.1c0,0.1-0.1,0.1-0.3,0.1H211C210.9,377.8,210.9,377.8,210.9,377.7L210.9,377.7
	z"
          />
          <path
            fill="none"
            d="M210.9,377.6v0.3c0,0.1,0.1,0.1,0.1,0.1h0.1c0.1,0,0.3,0,0.3-0.1h0.1v-0.4l-0.1-0.1h-0.1c-0.1,0-0.3,0-0.3,0.1
	L210.9,377.6L210.9,377.6z M204.8,377.3h-0.6v0.5c0.1,0.1,0.3,0.3,0.4,0.4l0.7,0.1h0.3l0.6-0.3l0.1-0.3v-1.4h-0.7v1.5l-0.1,0.3h-0.3
	l-0.3-0.1l0,0L204.8,377.3L204.8,377.3z M203.7,383.6c-0.8-0.7-1.9-0.8-2.9-0.7c-0.8,0.1-1.6,0.5-2.2,1.1l-0.6,0.8
	c-1.1,1.5-1.6,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2s1.9,0.7,3.3,0.1c1.4-0.5,2.6-1.5,3.6-3l0.6-0.8c0.4-0.8,0.6-1.5,0.4-2.3
	C205.1,385,204.6,384.2,203.7,383.6L203.7,383.6z M203.3,383.9c0.8,0.5,1.2,1.4,1.4,2.3s0,1.8-0.6,2.6c-0.6,0.8-1.2,1.2-2.2,1.4
	c-1,0.1-1.8-0.1-2.6-0.7c-0.8-0.5-1.2-1.2-1.4-2.2s0-1.8,0.6-2.6c0.6-0.8,1.2-1.2,2.2-1.4C201.7,383.2,202.5,383.3,203.3,383.9
	L203.3,383.9z"
          />
          <path
            fill="none"
            d="M204.7,386.2c-0.1-1-0.6-1.7-1.4-2.3c-0.8-0.5-1.6-0.8-2.6-0.5c-1,0.1-1.6,0.5-2.2,1.4
	c-0.6,0.8-0.8,1.6-0.6,2.6c0.1,1,0.6,1.6,1.4,2.2c0.8,0.5,1.6,0.8,2.6,0.7c1-0.1,1.6-0.7,2.2-1.4C204.7,388,204.8,387.2,204.7,386.2
	L204.7,386.2z M241.7,375.5c1.5-1,2.5-2.1,3-3.2l0.3-0.7l-0.3,0.5c-0.7,1-1.6,1.8-3,2.3h-0.1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1
	c-0.4,0.5-0.7,1.2-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.9c1,1.5,1.2,2.9,1.1,4.3c0.4-1.4,0.3-3-0.4-5l-6.7-11.8
	c-0.7-1.4-1-2.5-0.6-3.4c0.1-0.4,0.3-0.8,0.6-1.1c0.4-0.5,1.1-1,1.9-1.4l5.8-2.6L241.7,375.5L241.7,375.5z"
          />
          <path
            fill="none"
            d="M241.7,375.5l-6.7,3c0,2.8-0.3,4.5-1.2,5.5l-6.5-1.7c-0.3,0.3-0.6,0.7-0.6,1.1c-0.4,1-0.3,2.1,0.6,3.4l6.7,11.8
	c5.1-6.1,7.7-13.2,7.7-21.5V375.5L241.7,375.5z"
          />
          <path
            fill="none"
            d="M229.2,381.1c-0.8,0.4-1.5,0.8-1.9,1.4l6.5,1.6c0.8-1,1.2-2.8,1.2-5.5L229.2,381.1L229.2,381.1z M184.3,353.5
	c-1.8,1.8-3.3,3.6-4.5,5.5h0.1l8.9,1.9l1.4-1.5c2.6-2.6,5.4-4.4,8.5-5.6l1-9.1C194,346.1,188.9,349.1,184.3,353.5z"
          />
          <path
            fill="none"
            d="M179.8,359.1c-2.2-0.1-3.9,0.3-5,1.2c1.2-0.7,2.8-0.8,4.4-0.4l16.1,3.7l1.6,0.1l0.3-0.1c0.6-0.1,1-0.5,1.4-1.1
	c0.3-0.6,0.4-1.2,0.4-2.1l1.5-16c0.1-1.8,0.7-3.2,1.8-4.1c-1.2,0.8-2.1,2.3-2.6,4.3l-1,9.1l-0.6,4.5c-0.1,1.5-0.7,2.5-1.5,3
	c-0.8,0.7-1.9,0.8-3.4,0.5l-4.4-1L179.8,359.1L179.8,359.1z"
          />
          <path
            fill="none"
            d="M198.2,358.3l0.6-4.5c-3.2,1.2-5.9,3.2-8.5,5.6l-1.4,1.5l4.4,1c1.5,0.3,2.6,0.1,3.4-0.5
	C197.5,360.8,198.1,359.8,198.2,358.3L198.2,358.3z M171.1,371.4c0.3,1,0.8,1.8,1.5,2.5l1.6,1.2h0.1l12.4,5.6
	c1.4,0.5,2.2,1.4,2.5,2.5c0.4,1,0.3,2.1-0.6,3.4l-6.7,12c-0.8,1.9-1,3.7-0.4,5.1c-0.3-1.4,0.1-2.9,1-4.4l8.3-13.8
	c0.4-0.5,0.8-1.2,0.8-1.9c0.1-0.7-0.1-1.4-0.7-2.1c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3c-0.7-0.3-1.2-0.5-1.8-1
	C172,372.8,171.5,372.1,171.1,371.4L171.1,371.4z"
          />
          <path
            fill="none"
            d="M186.8,380.9l-12.4-5.6c0,0.7-0.1,1.4-0.1,2.1c0,8.1,2.5,15.3,7.6,21.3l6.7-12c0.7-1.4,1-2.5,0.6-3.4
	C189,382.2,188.2,381.4,186.8,380.9L186.8,380.9z M134.1,313.3h-2.6l8.3,2.9h12.8l-1.9-1H141"
          />
          <path
            fill="none"
            d="M152.6,316.2h-12.8l-8.3-2.9l-4,15.7l-0.4,1.9l5.4,1.9l-7,30.7h15.1l0.8-3.2"
          />
          <path
            fill="none"
            d="M143.1,363.5c0.6,0.1,0.8-1,0.8-3.2h-2.6l-0.8,3.3L143.1,363.5L143.1,363.5L143.1,363.5z M127.1,330.9l0.4-1.9
	h-23.2l-5.4,4.5c-1.2,1.1-2.1,2.6-2.5,4.5v16.1c0.3,2.3,1.1,4.7,2.6,7.3l4.5,7.8h4.1l-2.5-5.9c-1.4-3-2.2-5.9-2.2-8.4v-16.8
	c0.1-2.2,1.1-4,2.6-5.4c1.2-1.2,2.6-1.9,3.9-1.8L127.1,330.9L127.1,330.9z"
          />
          <path
            fill="none"
            d="M132.5,332.9l-5.4-1.9h-17.9c-1.2,0-2.5,0.5-3.9,1.8c-1.5,1.4-2.3,3.2-2.6,5.4V355c0.1,2.5,0.8,5.4,2.2,8.4
	l2.5,5.9h10.5c1.5,0,2.5-0.4,3-1.4l0.7-1.7l3.6-2.8c-2.9,0.4-4.5,0.1-5.2-0.7l-1.9-5.4c-1.1-3.2-1.7-6.1-1.7-8.7l-0.1-0.4v-0.1
	c-0.3-7.2,0-11,0.8-11.8c1.5-2.2,3.6-3.3,6.2-3.6h9.2V332.9L132.5,332.9z M112.9,341.8c0.3,1.5,0.6,3.2,0.6,5.1s-0.1,3.7-0.6,5.4
	l-0.4,1.4c-0.6,1.8-1.4,2.8-2.2,2.8c-0.8,0-1.7-1-2.2-2.8s-1-4-1-6.6s0.3-4.8,1-6.6c0.6-1.8,1.4-2.8,2.2-2.8c0.8,0,1.7,1,2.2,2.8
	L112.9,341.8L112.9,341.8z"
          />
          <path
            fill="none"
            d="M113.5,346.9c0-1.9-0.1-3.6-0.6-5.1l-0.4,0.8c-0.6,1.2-0.8,2.6-0.8,4.4c0,1.7,0.3,3.2,0.8,4.4l0.4,0.8
	C113.2,350.7,113.5,349,113.5,346.9z"
          />
          <path
            fill="none"
            d="M108.1,340.3c-0.6,1.8-1,4-1,6.6s0.3,4.8,1,6.6c0.6,1.8,1.4,2.8,2.2,2.8c0.8,0,1.7-1,2.2-2.8l0.4-1.4l-0.4-0.8
	c-0.6-1.2-0.8-2.6-0.8-4.4c0-1.6,0.3-3.2,0.8-4.4l0.4-0.8l-0.4-1.5c-0.6-1.8-1.4-2.8-2.2-2.8C109.5,337.4,108.7,338.5,108.1,340.3
	L108.1,340.3z M132.5,332.9h-8.8c-2.8,0.3-4.8,1.4-6.2,3.6c-0.8,0.8-1.1,4.7-0.8,11.8v0.1l0.1,0.4c0,2.6,0.6,5.5,1.7,8.7l1.9,5.4
	c0.7,0.8,2.3,1.1,5.2,0.7L132.5,332.9L132.5,332.9z M104.3,329c-1.8-0.1-3.4,0.4-5.1,1.8l-4,3.7c-1.1,1-1.8,2.2-1.9,3.3v13.9
	c0.1,3.9,0.7,6.7,1.7,8.8l4.3,7c0.6,1.2,1.7,1.9,3.2,1.8h1.1l-4.5-7.8c-1.5-2.5-2.3-5-2.6-7.3v-16c0.4-1.9,1.2-3.4,2.5-4.5
	L104.3,329L104.3,329z M125.5,363.5h0.4H125.5L125.5,363.5z M676.5,334.1v1.2h11.1c0.1-0.5,0.4-1,1-1.2H676.5z M636.6,343.9
	c-1.4-1.8-2.9-3.6-4.5-5.4l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6l-1.5,1.5c-1.7,1.8-3.2,3.6-4.5,5.4
	c-1,1.2-1.8,2.5-2.8,3.9c-2.2,3.3-4,6.9-5.5,10.6c-2.9,7.3-4.4,15.1-4.4,23.7c0,12.8,3.3,24.2,9.8,34.3c0.8,1.2,1.8,2.6,2.8,3.9
	c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1c12.2,11.7,26.8,17.6,43.9,17.6c17.1,0,31.8-5.9,43.9-17.6l1.1-1.1c2.2-2.2,4.3-4.5,6.1-6.9
	l0.3-0.3c8.3-10.9,12.2-23.5,12.2-37.7v-2.6c-0.1-5.5-1-10.7-2.5-15.7l-2.2-6.2c-1.9-4.7-4.4-9.1-7.6-13.2L636.6,343.9L636.6,343.9z
	 M627.2,338.1l2.6,2.6c1.5,1.7,3,3.3,4.4,5.1c1,1.2,1.9,2.6,2.8,4c6.1,9.5,9.2,20.2,9.2,32.3c0,12-3,22.7-9.2,32.2l-2.8,4
	c-1.8,2.3-3.7,4.4-5.8,6.6l-3.6,3.3c-11.1,9.6-24.2,14.4-39.2,14.4s-28.2-4.8-39.2-14.4c-1.2-1.1-2.3-2.1-3.6-3.3
	c-2.1-2.1-4-4.3-5.8-6.6c-1.9-2.6-3.7-5.4-5.2-8.1c-4.4-8.4-6.7-17.7-6.7-28.1c0-10.3,2.2-19.7,6.7-28.1c1.5-2.9,3.3-5.5,5.2-8.1
	c1.4-1.8,2.8-3.4,4.4-5.1l2.6-2.6c11.6-11,25.5-16.5,41.5-16.5C601.7,321.6,615.6,327.1,627.2,338.1L627.2,338.1z"
          />
          <path
            fill="none"
            d="M629.8,340.7l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6c-1.5,1.7-3,3.4-4.4,5.1
	c-1.9,2.6-3.7,5.4-5.2,8.1c-4.4,8.4-6.7,17.7-6.7,28.1c0,10.3,2.2,19.7,6.7,28.1c1.5,2.8,3.3,5.5,5.2,8.1c1.8,2.3,3.7,4.4,5.8,6.6
	c1.1,1.1,2.3,2.2,3.6,3.3c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4l3.6-3.3c2.1-2.1,4-4.3,5.8-6.6l2.8-4
	c6.1-9.5,9.2-20.2,9.2-32.2c0-12.1-3-22.8-9.2-32.3c-0.8-1.4-1.8-2.6-2.8-4C632.8,344,631.3,342.3,629.8,340.7L629.8,340.7z
	 M625.8,382c0,6.2-1.2,11.8-3.6,17.1c-1.9,4.1-4.7,8-8.1,11.4c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6
	c-5.2,0-10.2-0.8-14.6-2.6c-5.1-1.9-9.6-5-13.8-9.1c-2.9-2.9-5.2-5.9-7-9.2c-3.2-5.8-4.8-12.2-4.8-19.3c0-11.1,4-20.6,11.8-28.5
	c7.8-7.8,17.3-11.8,28.5-11.8s20.6,4,28.5,11.8C621.9,361.5,625.8,370.8,625.8,382L625.8,382z"
          />
          <path
            fill="none"
            d="M622.2,399c2.5-5.2,3.6-10.9,3.6-17.1c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8s-20.6,4-28.5,11.8
	c-7.8,7.8-11.8,17.3-11.8,28.5c0,7.2,1.7,13.5,4.8,19.3c1.8,3.3,4.1,6.3,7,9.2c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6
	c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1C617.5,407,620.3,403.2,622.2,399L622.2,399z M622.2,373.7c0.6,2.6,0.8,5.4,0.8,8.3
	s-0.3,5.6-0.8,8.3c-1.5,6.9-4.8,12.9-10,18.2l-0.1,0.1c-7.3,7.1-16.1,10.9-26.4,10.9c-10.3,0-19.1-3.7-26.4-11
	c-4.1-4.1-7.2-8.8-8.9-14c-1.4-3.9-2.1-8-2.1-12.5c0-2.1,0.1-4,0.4-5.9V376c0.3-2.2,0.8-4.4,1.5-6.5l0.5-1.4
	c1.8-4.7,4.7-8.9,8.5-12.7c5.9-5.9,12.8-9.4,20.6-10.6l5.8-0.4c10.3,0,19.1,3.7,26.4,11c3.9,3.9,6.6,8.1,8.5,12.7
	C621.2,370,621.8,371.8,622.2,373.7L622.2,373.7z"
          />
          <path
            fill="none"
            d="M593,376.6c0.4-0.8,1-1.4,1.8-1.8l1-0.3c1.7-0.6,3.3-0.7,4.7-0.3c1.5,0.3,2.3,1,2.6,2.1s0,2.2-1,3.3
	c-1,1.1-2.2,1.9-4,2.5h14.3l6.6-2.8h0.1c1.4-0.5,2.3-1.4,3-2.3l0.3-0.5l-0.3,0.7c-0.5,1.1-1.5,2.2-2.9,3.2v1.9h3.7
	c0-2.9-0.3-5.6-0.8-8.3c-0.4-1.9-1-3.7-1.7-5.5c-1.8-4.7-4.7-8.9-8.5-12.7c-7.3-7.3-16.1-11-26.4-11l-5.8,0.4c-1,1-1.7,2.3-1.8,4.1
	l-1.5,16c0,0.8-0.1,1.5-0.4,2.1c-0.4,0.5-0.8,1-1.4,1.1l-0.3,0.1l-1.7-0.1l-16.1-3.7c-1.7-0.4-3.2-0.3-4.4,0.4c1.2-1,2.9-1.4,5-1.2
	c1-1.4,1.9-2.8,3-4l1.5-1.6c4.5-4.5,9.6-7.4,15.4-8.9c0.5-2.1,1.4-3.4,2.6-4.3c-7.8,1.1-14.7,4.7-20.6,10.6
	c-3.9,3.9-6.6,8.1-8.5,12.7l-0.5,1.4c-0.7,2.1-1.2,4.3-1.5,6.5c0.3,0.7,0.8,1.4,1.5,1.9c0.5,0.4,1.1,0.7,1.8,1l7.3,3h13.5
	c-1.5-0.6-2.8-1.4-3.7-2.3c-1-1.1-1.2-2.2-1-3.3c0.3-1.1,1.2-1.8,2.6-2.1c1.4-0.3,3-0.3,4.7,0.3l1,0.3c0.7,0.4,1.2,1,1.7,1.8
	c0.5,1,0.5,1.9,0.3,3c-0.3,1.1-1,1.8-1.9,2.3h4c0-1.5,0.5-2.8,1.5-3.7c1.1-1.1,2.3-1.5,3.7-1.5c1.5,0,2.8,0.5,3.7,1.5
	c1.1,1,1.7,2.3,1.7,3.7h4c-0.1,0-0.1-0.1-0.3-0.1c-1-0.5-1.7-1.2-1.9-2.3C592.5,378.5,592.5,377.6,593,376.6L593,376.6z
	 M593.3,349.2v-0.1c-0.1-1.6-0.7-3-1.8-4c1.2,0.8,2.1,2.2,2.6,4.1c5.8,1.4,10.7,4.4,15.3,8.8c1.2,1.2,2.3,2.6,3.4,4l1.2,1.8
	c2.1-0.1,3.7,0.3,5,1.2c-1.2-0.7-2.6-0.8-4.4-0.4l-15.7,3.6c-0.7,0.3-1.4,0.3-2.1,0.3c-0.3-0.1-0.5-0.1-0.7-0.3
	c-0.4-0.3-0.8-0.5-1-1c-0.3-0.5-0.4-1.2-0.4-1.9L593.3,349.2L593.3,349.2z M589.3,368.2l0.1,2.8c-0.1,0.8-0.6,1.5-1.1,2.2
	c-0.8,0.8-1.7,1.1-2.8,1.1s-2.1-0.4-2.9-1.1c-0.5-0.7-1-1.4-1.1-2.2v-1c0-0.7,0-1.2,0.1-1.8c0.1-1.1,0.5-1.9,1-2.8
	c0.8-1.2,1.7-1.9,2.9-1.9c1.1,0,2.1,0.7,2.8,1.9C588.8,366.3,589.2,367.2,589.3,368.2L589.3,368.2z"
          />
          <path
            fill="none"
            d="M552,379.9c-0.8-0.4-1.4-1-1.8-1.4c-0.8-0.8-1.2-1.6-1.5-2.5c-0.3,1.9-0.4,3.9-0.4,5.9h3.7
	C551.9,381.3,551.9,380.6,552,379.9L552,379.9z"
          />
          <path
            fill="none"
            d="M580.4,382h-4c-0.8,0.4-1.5,0.5-2.5,0.4l-1-0.3c-0.1,0-0.3-0.1-0.3-0.1h-13.5l7.6,3.3c0.8,0.3,1.4,0.5,1.8,1
	c0.5,0.7,0.7,1.4,0.7,2.1c-0.1,0.5-0.4,1.2-0.8,1.9l-8.3,13.8c-1,1.5-1.2,2.9-1,4.4c-0.5-1.4-0.4-3.2,0.4-5.1
	c-5.1-6.1-7.6-13.2-7.6-21.3h-3.7c0,4.4,0.7,8.5,2.1,12.5c1.8,5.1,4.8,9.8,8.9,14c7.3,7.3,16.1,11,26.4,11
	c10.3,0,19.1-3.6,26.4-10.9c0.1-1.4-0.1-2.8-1.1-4.3l-8.3-13.9c-0.4-0.5-0.8-1.2-0.8-1.9c0-0.8,0.1-1.5,0.7-2.1c0.4-0.4,1-0.8,1.8-1
	l8.3-3.6h-14.3l-1,0.3c-0.8,0.1-1.5,0-2.2-0.3h-4c0,1.5-0.5,2.8-1.7,3.9c-1.1,1-2.3,1.5-3.7,1.5c-1.5,0-2.8-0.5-3.7-1.5
	C580.8,384.7,580.4,383.5,580.4,382L580.4,382z M576,388.6c0.5-0.5,1.4-1,2.2-1.1c1.1-0.1,2.1,0,2.9,0.7c0.8,0.7,1.4,1.5,1.5,2.6
	c0.1,0.8,0,1.6-0.4,2.3l-0.5,0.8c-1.1,1.5-2.2,2.5-3.6,3c-1.4,0.5-2.5,0.5-3.3-0.1c-0.8-0.7-1.2-1.6-1.1-3.2
	c0.1-1.5,0.7-2.9,1.8-4.4L576,388.6L576,388.6z M582.9,401c0.4-0.7,1-1.1,1.5-1.4c0.8-0.3,1.5-0.3,2.2,0c0.5,0.3,1.1,0.7,1.5,1.4
	l10.7,12.2c1.1,1.2,2.3,1.9,3.7,2.2c-1.5,0.1-3-0.5-4.7-1.8c-3.7,1.5-7.8,2.2-12.2,2.2c-4.5,0-8.7-0.8-12.5-2.3
	c-1.7,1.4-3.3,1.9-4.7,1.9c1.4-0.1,2.8-1,3.9-2.3L582.9,401L582.9,401z M588.5,390.8c0.1-1.1,0.7-1.9,1.7-2.6
	c0.8-0.7,1.8-0.8,2.9-0.7c0.8,0.1,1.5,0.6,2.2,1.1l0.7,0.8c1.1,1.4,1.7,2.9,1.8,4.4s-0.3,2.5-1.1,3.2s-1.9,0.7-3.3,0.1
	s-2.6-1.5-3.6-3l-0.5-0.8C588.5,392.4,588.4,391.6,588.5,390.8L588.5,390.8z"
          />
          <path
            fill="none"
            d="M623,382h-3.7c0,8.3-2.6,15.4-7.7,21.5c0.8,1.9,1,3.6,0.4,5l0.1-0.1c5.2-5.2,8.7-11.3,10-18.2
	C622.8,387.6,623,384.9,623,382L623,382z M614.6,364.8c1.8-0.4,3.3-0.3,4.4,0.4c-1.1-1-2.8-1.4-5-1.2l-8.9,1.9l-4.4,1
	c-1.5,0.3-2.6,0.1-3.4-0.5c-0.8-0.6-1.4-1.5-1.7-3l-0.5-4.7l-1-9.1c-0.5-1.9-1.5-3.4-2.6-4.1c1.1,1,1.7,2.3,1.8,4v0.1l1.5,16.1
	c0,0.8,0.1,1.5,0.4,1.9c0.3,0.4,0.5,0.7,1,1c0.3,0.1,0.4,0.3,0.7,0.3c0.5,0.1,1.2,0,2.1-0.3L614.6,364.8L614.6,364.8z"
          />
          <path
            fill="none"
            d="M614.1,363.9l-1.2-1.8l-12.2-0.5l2.9,2.5l1.7,1.6L614.1,363.9L614.1,363.9z M609.4,358.2
	c-4.4-4.5-9.5-7.4-15.3-8.8l1,9.1c1.9,0.8,3.7,1.8,5.5,3.2l12.2,0.5C611.9,360.8,610.6,359.4,609.4,358.2L609.4,358.2z"
          />
          <path
            fill="none"
            d="M600.6,366.7l4.4-1l-1.7-1.6c-1-1-1.9-1.8-2.9-2.5c-1.7-1.2-3.6-2.3-5.5-3.2l0.5,4.7c0.1,1.5,0.7,2.6,1.7,3
	C598,366.8,599.2,367,600.6,366.7L600.6,366.7z M568,376.3c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.3,1.9,4,2.5l1,0.3c0.8,0.1,1.7,0,2.5-0.4
	c1-0.5,1.7-1.2,1.9-2.3c0.3-1.1,0.3-2.1-0.3-3c-0.4-0.8-1-1.4-1.7-1.8l-1-0.3c-1.7-0.5-3.3-0.7-4.7-0.3
	C569.2,374.7,568.3,375.4,568,376.3L568,376.3z M575.6,375.1c1,0.3,1.5,0.8,1.9,1.8c0.4,0.8,0.5,1.6,0.3,2.6s-0.8,1.5-1.8,2.1
	c-0.8,0.4-1.8,0.5-2.6,0.1c-1-0.3-1.5-0.8-2.1-1.6c-0.4-0.8-0.5-1.8-0.3-2.6s0.8-1.5,1.7-2.1C573.8,374.9,574.7,374.8,575.6,375.1
	L575.6,375.1z"
          />
          <path
            fill="none"
            d="M577.6,376.9c-0.4-0.8-1.1-1.5-1.9-1.8c-1-0.3-1.8-0.3-2.6,0.3c-0.8,0.4-1.4,1.1-1.7,2.1
	c-0.3,0.8-0.3,1.8,0.3,2.6c0.4,0.8,1.1,1.4,2.1,1.6c0.8,0.3,1.8,0.3,2.6-0.1s1.5-1.1,1.8-2.1C578,378.7,578,377.7,577.6,376.9
	L577.6,376.9z M594.8,374.8c-0.8,0.4-1.4,1-1.8,1.8c-0.5,1-0.5,1.9-0.3,3c0.3,1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3
	c1.8-0.5,3-1.4,4-2.5s1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1c-1.5-0.3-3-0.3-4.7,0.3L594.8,374.8L594.8,374.8z M598.1,375.4
	c0.8,0.4,1.4,1.1,1.7,2.1c0.3,0.8,0.3,1.8-0.1,2.6c-0.4,0.8-1.1,1.5-2.1,1.8s-1.8,0.3-2.6-0.3c-0.8-0.4-1.5-1.1-1.8-1.9
	c-0.3-1-0.3-1.8,0.3-2.6c0.4-0.8,1.1-1.4,2.1-1.7C596.5,374.8,597.3,375,598.1,375.4L598.1,375.4z"
          />
          <path
            fill="none"
            d="M599.9,377.3c-0.3-1-0.8-1.6-1.7-2.1c-0.8-0.4-1.8-0.5-2.6-0.1c-1,0.3-1.5,0.8-2.1,1.6
	c-0.4,0.8-0.5,1.8-0.3,2.6c0.3,1,0.8,1.5,1.8,1.9c0.8,0.4,1.7,0.5,2.6,0.3s1.7-0.8,2.1-1.8C600.1,379.1,600.2,378.3,599.9,377.3
	L599.9,377.3z M588.4,365.5c-0.8-1.2-1.7-1.9-2.8-1.9s-2.1,0.7-2.9,1.9c-0.5,0.8-0.8,1.8-1,2.8c-0.1,0.5-0.1,1.1-0.1,1.8v1
	c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.8,1.1,2.9,1.1c1.1,0,2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2l-0.1-2.8
	C589.2,367.2,588.8,366.3,588.4,365.5L588.4,365.5z M582.9,368.2l0.3-0.3c0.7-0.7,1.5-1,2.5-1s1.8,0.3,2.3,1l0.3,0.3
	c0.5,0.5,0.8,1.4,0.8,2.2c0,1-0.3,1.8-1.1,2.3c-0.7,0.7-1.5,1.1-2.3,1.1c-1,0-1.8-0.3-2.5-1.1c-0.7-0.7-1-1.5-1-2.3
	C582,369.6,582.3,368.9,582.9,368.2L582.9,368.2z"
          />
          <path
            fill="none"
            d="M583.1,367.9l-0.3,0.3c-0.5,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1.1,2.5,1.1s1.8-0.3,2.3-1.1
	c0.7-0.7,1-1.5,1-2.3s-0.3-1.5-0.8-2.2l-0.3-0.3c-0.7-0.7-1.5-1-2.3-1C584.6,367,583.8,367.2,583.1,367.9L583.1,367.9z M589.5,378.3
	c-1.1-1.1-2.3-1.5-3.7-1.5c-1.5,0-2.8,0.5-3.7,1.5c-1.1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.8,1.5,3.9c1.1,1,2.3,1.5,3.7,1.5
	c1.5,0,2.8-0.5,3.7-1.5c1.1-1.1,1.7-2.3,1.7-3.9C591,380.5,590.4,379.2,589.5,378.3L589.5,378.3z M583.3,380.6h0.7v1.4l-0.1,0.3
	c-0.1,0.1-0.3,0.3-0.5,0.3H583l-0.7-0.1c-0.3-0.1-0.4-0.3-0.4-0.4v-0.6h0.5v0.4l0.3,0.1h0.3l0.1-0.3v-1.1L583.3,380.6L583.3,380.6z
	 M585.1,381.3L585.1,381.3c0.4,0,0.5,0.1,0.6,0.4l0.1,0.3v0.3h-1.2v0.3l0.1,0.1h0.1l0.1-0.1h0.5l-0.1,0.1l-0.3,0.1l-0.6,0.1h-0.3
	c-0.3-0.1-0.4-0.1-0.5-0.4l-0.1-0.4l0.1-0.3v-0.1l0.3-0.3l0.4-0.1H585.1L585.1,381.3z M586.8,381.3h0.4c0.3,0,0.4,0.1,0.6,0.4
	l0.1,0.3v0.3h-1.2v0.1c0,0.1,0.1,0.1,0.3,0.1h0.1l0.1-0.1h0.5l-0.1,0.1l-0.4,0.1l-0.4,0.1h-0.3c-0.3-0.1-0.4-0.3-0.5-0.4V382
	c0-0.1,0-0.4,0.1-0.5l0.3-0.3L586.8,381.3L586.8,381.3z M589.7,381.6l0.1,0.3v0.5l-0.1,0.3l-0.1,0.1h-0.7l-0.1-0.1v0.5h-0.5v-2.1
	h0.5v0.1l0.1-0.1l0.3-0.1h0.5C589.6,381.4,589.7,381.4,589.7,381.6L589.7,381.6z"
          />
          <path
            fill="none"
            d="M584,380.6h-0.7v1.5l-0.1,0.3h-0.3l-0.3-0.1v-0.4h-0.7v0.5l0.4,0.4l0.7,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.3
	l0.1-0.3L584,380.6L584,380.6z M585.3,381.3h-0.4l-0.4,0.1l-0.3,0.3v0.1l-0.1,0.3l0.1,0.4c0.1,0.3,0.3,0.3,0.5,0.4h0.3l0.5-0.1
	l0.3-0.1l0.1-0.1h-0.5l-0.1,0.1h-0.1l-0.1-0.1v-0.3h1.2V382l-0.1-0.3C585.7,381.4,585.5,381.3,585.3,381.3L585.3,381.3z
	 M584.9,381.6L584.9,381.6l0.4,0.1v0.1h-0.7v-0.1C584.8,381.6,584.8,381.6,584.9,381.6L584.9,381.6z"
          />
          <path
            fill="none"
            d="M585.1,381.6L585.1,381.6c-0.2,0-0.2,0-0.2,0.1v0.1h0.5v-0.1L585.1,381.6L585.1,381.6z M587.3,381.3h-0.4
	c-0.1,0-0.3,0-0.4,0.1l-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.6v0.3c0.1,0.3,0.3,0.4,0.6,0.4h0.3l0.4-0.1l0.4-0.1l0.1-0.1h-0.5l-0.1,0.1
	H587c-0.1,0-0.3-0.1-0.3-0.1v-0.1h1.2v-0.3l-0.1-0.3C587.7,381.4,587.5,381.3,587.3,381.3L587.3,381.3z M586.8,381.6L586.8,381.6
	l0.4,0.1v0.1h-0.6v-0.1L586.8,381.6L586.8,381.6z"
          />
          <path
            fill="none"
            d="M587,381.6L587,381.6l-0.2,0.1v0.1h0.6 M589.9,381.8l-0.1-0.3l-0.3-0.3H589l-0.3,0.1l-0.1,0.1v-0.1h-0.5v2.1
	h0.5v-0.5l0.1,0.1h0.7l0.1-0.1l0.1-0.3L589.9,381.8L589.9,381.8z M589.3,382.4l-0.3,0.1h-0.3l-0.1-0.1v-0.3l0.1-0.3
	c0.1-0.1,0.1-0.1,0.3-0.1h0.1l0.1,0.1v0.1L589.3,382.4L589.3,382.4z"
          />
          <path
            fill="none"
            d="M355.7,271.5l-1.4,0.1H354c-0.5,0.1-1.1,0.3-1.6,0.5v10.6l1.6,0.5h0.3l1.4,0.1l11-2.2V279h-11.6v-3.6h11.6v-2.1
	L355.7,271.5L355.7,271.5z"
          />
          <path
            fill="none"
            d="M352.4,272.2v-0.1c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.5,0.1-0.7,0.3
	c-0.1,0.1-0.3,0.4-0.3,0.7v5.4h2.8L352.4,272.2L352.4,272.2z"
          />
          <path
            fill="none"
            d="M352.2,284.3c0.1-0.1,0.3-0.4,0.3-0.7v-6.3h-2.8v6.3c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3h0.7
	C351.7,284.6,352,284.6,352.2,284.3L352.2,284.3z"
          />
          <path
            fill="none"
            d="M179.1,278v2.5c22.6-2.2,46.6-2.5,72.5-0.8c11.7,1,21,6.3,28.1,15.7c13.1,16.4,26.4,36,40,58.9h-10.2l2.1,2.8
	h65.5v-7.6h-0.3c-10.6,0.4-17.6-1.1-20.9-4.7c-3.2-1.9-5.1-5.9-5.8-12c-0.1-0.1-0.3-0.4-0.3-0.7v-63.7l0.3-2.9l-12.8,0.1l-160.4,7.7
	l-0.1,4.4h1.9C179,277.6,179.1,277.7,179.1,278L179.1,278z"
          />
          <path
            fill="none"
            d="M352.4,320.3c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7
	v5.4h2.8v-5.1L352.4,320.3L352.4,320.3z M352.2,332.7c0.1-0.1,0.3-0.4,0.3-0.7v-6.3h-2.8v6.3c0,0.3,0.1,0.5,0.3,0.7
	c0.1,0.1,0.4,0.3,0.7,0.3h0.7C351.7,333,352,332.9,352.2,332.7L352.2,332.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M589.7,381.6l-0.3-0.3h-0.5l-0.3,0.1l-0.1,0.1v-0.1H588v2.1h0.5V383
	l0.1,0.1h0.7l0.1-0.1l0.1-0.3v-0.5L589.7,381.6L589.7,381.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M589.2,382.4l-0.3,0.1h-0.1l-0.1-0.1v-0.3l0.1-0.3
	c0.1-0.1,0.1-0.1,0.3-0.1h0.1l0.1,0.1v0.1L589.2,382.4L589.2,382.4z M586.8,381.3c-0.1,0-0.3,0-0.4,0.1l-0.3,0.3
	c-0.1,0.1-0.1,0.3-0.1,0.6v0.3c0.1,0.3,0.3,0.4,0.6,0.4h0.3l0.4-0.1l0.4-0.1l0.1-0.1h-0.5l-0.1,0.1H587c-0.1,0-0.3-0.1-0.3-0.1v-0.1
	h1.2v-0.3l-0.1-0.3c-0.1-0.3-0.3-0.3-0.6-0.4L586.8,381.3L586.8,381.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M587,381.6l0.3,0.3h-0.6v-0.1l0.1-0.1L587,381.6L587,381.6z M585.1,381.6
	l0.3,0.1v0.1h-0.7v-0.1c0-0.1,0.1-0.1,0.1-0.1H585.1L585.1,381.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M585.1,381.3h-0.3l-0.4,0.1l-0.3,0.3v0.1l-0.1,0.3l0.1,0.4
	c0.1,0.3,0.3,0.3,0.5,0.4h0.3l0.5-0.1l0.3-0.1l0.1-0.1h-0.6l-0.1,0.1H585l-0.1-0.1v-0.3h1.2V382l-0.1-0.3c-0.1-0.3-0.3-0.3-0.5-0.4
	L585.1,381.3L585.1,381.3z M583.3,380.6v1.5l-0.1,0.3h-0.3l-0.3-0.1v-0.4h-0.7v0.5l0.4,0.4l0.7,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.3
	l0.1-0.3V381L583.3,380.6L583.3,380.6z M207.4,377l0.3,0.1v0.1h-0.6v-0.1c0-0.1,0.1-0.1,0.1-0.1H207.4L207.4,377z M204.1,377.3v0.5
	c0.1,0.1,0.3,0.3,0.4,0.4l0.7,0.1h0.3l0.6-0.3l0.1-0.3v-1.4h-0.7v1.5l-0.1,0.3h-0.3l-0.3-0.1v-0.4L204.1,377.3L204.1,377.3z
	 M210.9,377.6l0.1-0.3c0.1-0.1,0.1-0.1,0.3-0.1h0.1l0.1,0.1v0.4h-0.1c0,0.1-0.1,0.1-0.3,0.1H211c-0.1,0-0.1,0-0.1-0.1V377.6
	L210.9,377.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M211.3,376.7l-0.3,0.1l-0.1,0.1v-0.3h-0.6v2.1h0.6v-0.5l0.1,0.1h0.7
	l0.1-0.1l0.1-0.3v-0.5l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.3L211.3,376.7L211.3,376.7z M209,377.3v-0.1l0.3-0.1h0.1l0.3,0.3L209,377.3
	L209,377.3z M208.1,377.2c-0.1-0.3-0.3-0.3-0.6-0.4h-0.4l-0.4,0.1c-0.1,0.1-0.3,0.1-0.3,0.3v0.1l-0.1,0.3l0.1,0.4
	c0.1,0.3,0.3,0.3,0.6,0.4h0.3l0.6-0.1c0.1,0,0.3-0.1,0.3-0.1l0.1-0.1h-0.6l-0.1,0.1h-0.1l-0.1-0.1v-0.3h1.2v-0.3L208.1,377.2
	L208.1,377.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M208.8,376.9l-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.6v0.3
	c0.1,0.3,0.3,0.4,0.6,0.4h0.3l0.4-0.1l0.4-0.1l0.1-0.1h-0.6l-0.1,0.1h-0.1c-0.1,0-0.3-0.1-0.3-0.1v-0.1h1.2v-0.3l-0.1-0.3
	c-0.1-0.3-0.3-0.3-0.6-0.4L208.8,376.9L208.8,376.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M693.7,299.3v-1
	 M693.2,299.3h0.5 M693.7,298.3h-0.5 M693.7,270.1c0,0.1,0.1,0.4,0.3,0.5l0.1,0.1v26.8l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5
	 M693.7,269.2v1 M693.7,270.1h-0.5 M693.7,269.2h-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M693.7,299.3
	c0,0.1,0.1,0.4,0.3,0.6l0.1,0.1v1.2l0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1l0.3-0.1l0.1-0.3V300l0.1-0.1l0.3-0.5v-1c0-0.1-0.1-0.4-0.3-0.5
	l-0.1-0.1V271l0.1-0.1l0.3-0.5v-1.2c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-0.7l-0.1-0.3l-0.3-0.1c-0.1,0-0.1,0-0.3,0.1l-0.1,0.3v0.7
	l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M696.8,205.2c-0.4,0.1-0.5,0.4-0.5,1v0.4 M696.8,205.2l0.4-0.1h0.1
	 M697.3,205.1l-0.5,0.1 M696.1,208.6v0.4c0,0.7,0.3,1,1,1l2.9,0.3 M696.1,206.6h-2.2v2.1h2.2 M696.1,208.6v-2.1 M707.1,211.1
	l2.1,0.1 M709.1,211.2c0,0.1,0,0.3,0.1,0.3l0.3,0.1l0.3-0.1l0.1-0.3V204l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.3,0-0.3,0.1
	l-0.1,0.3 M709.1,211.2V204 M707.1,211.1l-7-0.7 M709.1,204l-11.8,1.1 M720.6,343h0.5c6.5,0.4,12.1,0.3,17.1-0.4
	c4.1-1.1,6.3-3.6,6.9-7.4l4.5-108c-0.3-3.9-2.3-6.5-6.2-8c-4.7-1.1-10.3-1.8-16.9-1.9l-2.2-0.1c-6.5-0.4-12.1-0.3-17.1,0.4
	c-4.1,1.1-6.3,3.6-6.9,7.4l-4.5,108v0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M693.2,269.2l2.9-34.8c0.3-3.9,0.8-6.5,1.5-7.8l5.9-11.3
	c1.2-2.2,2.3-3.7,3.6-4.3 M700.1,210.4l-6.6,15c-0.8,1.9-1.4,4.7-1.7,8.4l-2.8,33.8 M692.2,301.2h1v-32"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M693.2,269.2v-1.6h-4.1 M688.8,307.3l3.4-1.4v-4.7 M690.6,312.4v2.5
	 M690.6,312.4v-1.1c0-0.7-0.3-1-1-1h-0.8 M690.6,314.8h1v-2.5h-1 M690,315.4c0.3-0.1,0.4-0.3,0.5-0.5 M689.2,326.3l2.5-1.4v-8.4
	l-1.7-1 M695.7,333.5l-4.4,0.1c-1.1,0-1.9,0.1-2.5,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M688.8,329.1l3-0.5v-2.3h-2.6 M715.2,354.9h-6.5c-0.3,0-0.5-0.1-0.5-0.5
	v-13.5 M715.2,354.9h0.5c0.3,0,0.6-0.1,0.6-0.5v-14c0-0.3-0.1-0.5-0.5-0.5h-7c-0.3,0-0.5,0.1-0.5,0.5v0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M720.6,343l-0.1-4.3c-1-4-3.2-6.1-6.6-6.1l-18,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M708.3,340.8h6.5c0.3,0,0.6,0.1,0.6,0.5v13.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M709.6,367.9c1.8-0.1,3.2-0.7,4-1.6c2.5-4.1,3.7-7.4,4-9.8v-14.6
	c0.3-4.1-1.2-6.3-4.7-6.6h-25 M716,367.5l1.9-3.2c1.9-3.2,2.9-5.4,2.9-6.6l-0.4-14.6 M688.6,367.4c0,1.2,0.8,1.9,2.5,2.1l20.6,0.3
	c1.1,0.1,1.9,0,2.8-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M714.4,369.5c0.5-0.3,0.8-0.6,1.2-1.1l0.5-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M714.4,369.5V381c0,0.3,0.1,0.5,0.5,0.5h0.7c0.3,0,0.5-0.1,0.5-0.5v-13.5
	 M699.1,387.1c0.1-0.1,0.4-0.7,0.7-1.5c0.3-1.1,0.5-2.3,0.5-3.9c0.1-1.5,0.1-2.8,0.1-4c-0.1-1.1-0.3-1.6-0.4-1.6 M700.1,376.1
	c-0.3,0-0.4,0.5-0.7,1.5c-0.3,1.1-0.4,2.3-0.5,3.9c-0.1,1.5-0.1,2.9-0.1,4c0.1,0.8,0.1,1.4,0.3,1.6 M699,386.9l0.1,0.1 M699,386.9
	l0.1,0.1 M710.5,368.1l-1-0.1 M664.2,166.8l-0.5-4c-10-1.6-20.4-2.8-31.2-3l-104.1-0.1c-11.6-0.1-21.5,0.6-29.7,1.8 M376.8,219.4
	l17.3-31.9c1.4-2.8,4.3-4.3,8.7-4.4l75.2-1.2c1.9-0.1,3.3,0.3,4.1,1.5c0.8,1,1.1,2.5,0.8,4.7l-1.4,46.4c0.1,4.7-2.6,7.6-8.5,8.7
	c-3.3,0.6-6.7,0.8-10.3,1l-71.5,0.7 M520.5,180.2h117.8c4.8,0.3,7.4,3.2,7.8,8.8l4.4,48.1c0.4,2.5-0.4,4.5-2.6,5.9
	c-1.9,1.2-5.1,2.1-9.4,2.3H525.8c-4.7,0.3-8.1-0.3-10.6-1.9c-2.5-1.6-3.9-4.1-4-7.7v-49.2C511.2,182.1,514.4,180,520.5,180.2
	L520.5,180.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M512.6,186.5v49.1c0.1,3,1.2,5.1,3.3,6.5c2.2,1.4,5.4,1.9,9.8,1.6h112.8
	c4-0.3,6.9-1,8.7-2.1c1.7-1.1,2.2-2.5,1.9-4.4l-4.4-48.1c-0.3-4.7-2.5-7.2-6.5-7.4H520.5C515.4,181.5,512.8,183.2,512.6,186.5
	L512.6,186.5z M496.9,173v-1l-105.5,2 M498.4,173h-1.5 M498.4,161.6V173 M388.2,169.4c-3,0.6-5.6,2.3-7.8,5.4l-26.8,44.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M498.4,161.6l-84.7,1.8c-10,0.3-18.4,1.1-25.2,2.5c-0.4,1-0.5,2.2-0.3,3.6
	 M391.4,174l-3.2-4.5 M391.2,242.9v-19.5c0.1-2.6-0.7-4-2.3-4.1H379"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M380.8,220.6l-5.1,2.8c-1.1,0.6-1.6,2.2-1.6,5v10.9
	c-0.1,1.6,0.8,3.2,2.6,4.5l4.3,3.3 M378.7,219.4h-1.9 M379.1,219.4h-0.4 M379,219.4h0.1 M380.8,220.6c-0.4-0.7-1-1.1-1.8-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M380.9,247c0.3-0.6,0.3-1.1,0.3-1.9v-22.8c0-0.7-0.1-1.2-0.4-1.6
	 M378.7,219.4h0.3 M368.3,243.7l-0.8-0.7c-1-1-1.5-2.5-1.4-4.3V227c0.1-1.4,1-3,2.8-5.1c1.9-2.1,4.1-3,6.3-2.9h1.6 M391.2,244.8
	v-1.9 M391.2,244.8c-0.3,2.3-1.2,3.6-2.9,3.3h-9.2 M379.1,248.1c0.8,0,1.5-0.4,1.8-1.1 M379.1,248.1h-3.4l-0.1,0.1 M373.3,253.8h1.2
	c0.7,0,1-0.3,1-1v-4.7 M374,254.7l-0.7-1 M375.3,261.5l0.3-0.1c0.1-0.1,0.3-0.4,0.3-0.7l0.3-4.7c0.1-0.8-0.3-1.2-0.8-1.2H374
	 M375.1,261.5h0.1 M374.7,262l0.4-0.5 M374.9,261.9v-0.3 M374.9,261.6l0.4-0.1 M375.1,261.5l-0.3,0.4 M374.7,262l0.1-0.1
	 M368.3,250.6l-1.6-0.1c-2.3,0-4.4,0.8-6.2,2.5c-1.6,1.6-2.5,3.7-2.5,6.2s0.8,4.4,2.5,6.1c1.6,1.6,3.7,2.6,6.2,2.6
	c2.3,0,4.4-0.8,6.1-2.6c1-1,1.6-2.1,2.1-3.2 M477.5,263.5c-0.3-1.2-1-2.3-1.9-3.3c-1.5-1.5-3.2-2.2-5.2-2.2c-2.1,0-3.9,0.7-5.4,2.2
	c-1,1-1.6,2.1-1.9,3.3 M487.7,266.3c0-0.7-0.3-1.2-0.8-1.8s-1.1-0.8-1.8-0.8c-0.7,0-1.4,0.3-1.8,0.8c-0.5,0.5-0.7,1.1-0.7,1.8
	s0.3,1.4,0.7,1.8c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.2-0.3,1.8-0.7C487.5,267.7,487.7,267,487.7,266.3L487.7,266.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M484.6,270h0.5c1,0,1.9-0.4,2.6-1.1s1.1-1.6,1.1-2.6s-0.4-1.9-1.1-2.6
	s-1.6-1.1-2.6-1.1l-0.8,0.1 M484.4,262.7l-3,0.1c-0.7,0.1-1.2,0.4-1.8,0.7h-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M484.4,262.7c-0.7,0.1-1.2,0.4-1.8,1c-0.7,0.7-1.1,1.6-1.1,2.6
	s0.4,1.9,1.1,2.6c0.5,0.5,1.2,1,2.1,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M477.1,268.6h1.9c0.4,0.4,1.2,0.7,2.3,1l3.3,0.3 M463.5,268.6
	c0.4,0.8,0.8,1.5,1.5,2.2c1.5,1.5,3.3,2.2,5.4,2.2s3.9-0.7,5.2-2.2c0.7-0.7,1.2-1.4,1.5-2.2 M479.4,264.8c0.3,0.4,0.4,0.8,0.4,1.4
	s-0.1,1-0.4,1.4s-0.5,0.5-1,0.5h-20.9c-0.5,0-1-0.1-1.4-0.5s-0.5-0.8-0.5-1.4s0.1-1,0.5-1.4s0.8-0.5,1.4-0.5h20.9
	C478.9,264.2,479.1,264.4,479.4,264.8z M486.6,277.1c-0.3-0.1-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.3-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.7,0.3s0.5-0.1,0.8-0.3c0.1-0.3,0.3-0.4,0.3-0.7C486.8,277.6,486.7,277.4,486.6,277.1
	L486.6,277.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M488.8,278c0-0.8-0.3-1.5-0.8-2.1c-0.6-0.5-1.2-0.8-2.2-0.8
	c-0.8,0-1.5,0.3-2.2,0.8c-0.6,0.5-0.8,1.2-0.8,2.1c0,0.8,0.3,1.5,0.8,2.2c0.5,0.5,1.2,0.8,2.2,0.8c0.8,0,1.5-0.3,2.2-0.8
	C488.5,279.5,488.8,278.8,488.8,278L488.8,278z M463,263.5h-1.7 M461.2,268.6h2.2 M461.4,263.5c-0.5-0.5-1.4-0.8-2.2-0.8h-2.5
	c-1,0-1.6,0.3-2.3,1c-0.7,0.7-1,1.5-1,2.3c0,1,0.3,1.6,1,2.3c0.7,0.7,1.5,1,2.3,1h2.6c0.8,0,1.5-0.3,2.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M461.4,263.5h-4.1c-0.7,0-1.2,0.3-1.8,0.7c-0.4,0.5-0.7,1.1-0.7,1.8v0.1
	c0,0.7,0.3,1.2,0.7,1.6c0.5,0.5,1.1,0.7,1.8,0.7h4 M477.5,263.5H463 M477.1,268.6h-13.6 M495.9,310.8v-3.7 M495.9,307.1
	c0.3,1.1,0.3,2.3,0,3.7 M495.9,263.4v43.7 M496.9,173l-1,90.4 M676.5,174.9c-0.7-2.2-1.9-3.6-3.7-4.4l-8.7-3.7 M680,188.4l-0.4-10.5
	c-0.1-1.8-1.1-2.8-3-3.2 M682.3,191.3l-0.4-3l-1.9,0.1 M679.3,192l-0.5-3.4v-0.1l-1.9-12.2l-0.3-1.2 M664.8,172.5l-0.7-5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M683,196.4l0.5-0.7c0.3-0.6,0.6-1.1,0.6-1.9c0-0.7-0.1-1.4-0.5-1.9
	c-0.3-0.6-0.7-0.8-1.2-0.8h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M680.2,198.7l3-0.4l-0.3-1.9 M688,261.9c-2.2-21.6-4.7-42.1-7.3-61.4
	l-0.3-1.8 M682.3,191.3l0.7,5.1 M680,188.4l0.1,1.9c0.3,1.1,0,1.7-0.7,1.7 M679.3,192l1,6.7 M513.4,356.9l5.2-8.1
	c7-10.6,14.2-20.4,21.3-29.4l4.8-5.9l4.5-4.8c0.7-0.5,1.4-1.2,2.1-1.7c0.4-0.3,0.8-0.5,1.1-0.8c4.3-2.3,9.6-3.7,16.2-4
	c21.9-1.9,41-2.2,57.2-0.7c6.3,0.5,11.3,3.3,15,8.1c1.4,1.8,2.6,4,3.6,6.3l7.2,16l1.9,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M653.8,331.9l19.1,0.7c-6.2-13.5-12.5-25.5-19.1-35.6
	c-4.7-7.6-11.8-11.7-21-12.5c-23.5-1.5-46.2-1.1-68.1,1c-15.1,0.8-26.3,5.5-33.2,14l-12.8,16.4c-9.8,12.8-19.7,26.5-29.7,41.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M666.4,332.2c-4.1-9.6-8.3-17.9-12.2-24.6l-5-7.6l-1.7-2.2
	c-5-5.4-12.7-7.8-23.1-7.6c-24.2-0.7-46.5,0.3-66.6,2.8c-8.8,1.1-16.1,5.4-21.6,12.8l-38.5,51.3 M688,261.9h-13.8 M689.1,267.5h-0.5
	 M684.4,268.6c-0.1,0.3-0.3,0.7-0.3,1.1l0.5,29.6c0.1,0.6,0.4,1.1,0.8,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M685.6,300.8c-1.7,0.1-2.6-0.8-2.6-2.8l-0.5-27c0.3-1.5,1-2.3,1.9-2.5
	 M688.5,267.5h-2.2c-1,0-1.5,0.3-1.8,1.1 M661.3,269.4c-2.3,0-4.4,0.8-6.1,2.5c-1.7,1.6-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1
	c1.7,1.6,3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5c1.7-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.1
	C665.7,270.3,663.6,269.4,661.3,269.4L661.3,269.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M661.3,272.1c-1.7,0-3,0.5-4.3,1.8c-1.2,1.2-1.8,2.6-1.8,4.3
	s0.5,3,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8s3-0.5,4.3-1.8s1.8-2.6,1.8-4.3c0-1.6-0.6-3-1.8-4.3C664.3,272.6,662.9,272.1,661.3,272.1
	L661.3,272.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M661.3,266c-3.3,0-6.2,1.2-8.5,3.6s-3.6,5.2-3.6,8.5s1.2,6.2,3.6,8.5
	s5.2,3.6,8.5,3.6s6.2-1.2,8.5-3.6c2.3-2.3,3.6-5.2,3.6-8.5s-1.2-6.2-3.6-8.5C667.3,267.2,664.6,266,661.3,266L661.3,266z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M658.2,278.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8
	c0.8,0,1.5-0.3,2.1-0.8s0.8-1.2,0.8-2.1c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.1-0.8c-0.8,0-1.5,0.3-2.1,0.8S658.2,277.3,658.2,278.1
	L658.2,278.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M657,278.1c0,1.1,0.4,2.2,1.2,3s1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2
	c0.8-0.8,1.2-1.8,1.2-3c0-1.1-0.4-2.2-1.2-3s-1.8-1.2-3-1.2c-1.1,0-2.2,0.4-3,1.2C657.4,275.9,657,277,657,278.1L657,278.1z
	 M688.8,310.3v-3 M688.8,301.2H687c-0.5,0-1-0.1-1.4-0.4 M688.8,301.2v6.1 M688.5,267.5l-0.5-5.6 M674.2,261.9
	c-2.5-31.4-5.6-61.2-9.4-89.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M498.4,173h77.2l89.3-0.6 M495.9,263.4l178.3-1.5 M173.7,265.2l0.6-3.6
	l0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1h5.2c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.3l-0.6,3.6 M308.3,246.3l-103,10.2l-24.1,2.2
	 M173.7,266.4h-0.4c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.3v-0.3c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3-0.1,0.3-0.1h0.3
	 M171.8,271.1c-0.1-0.1-0.1-0.3-0.1-0.4l0.6-3.2c0.1-0.3,0.3-0.5,0.6-0.5h0.8 M174.2,270.8l-0.1,0.4c0,0.3-0.3,0.5-0.6,0.5h-1.1
	c-0.1,0-0.3,0-0.4-0.1l-0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.7,270.5v0.3c0,0.3-0.3,0.5-0.6,0.5H172l-0.3-0.1 M156.3,263.9
	c-0.6,0.4-1.1,0.8-1.5,1.4l-0.1,0.1 M154.8,274.1l1.5-10.2 M153.2,267.4c0.6-0.3,0.8,0.5,0.7,2.6 M171.7,279.6V278
	c0-0.3,0.1-0.5,0.6-0.5h2.3 M171.7,281.3v-1.6 M171,281.3v-1.6h0.7 M171.7,281.3H171 M153.1,284.4c3.4-1.2,7.4-1.9,12-2.3l5.9-0.8
	 M151.3,282.9l-0.6,2.5 M147.1,287.3l5.5-18.7c0.1-0.4,0.4-0.8,0.7-1.2 M153.1,284.4c-0.8,0.3-1.6,0.5-2.5,1 M143.6,290.1
	c-1.1,1.1-2.1,2.3-2.9,3.7c-2.5,4.1-4.8,9.2-7.2,15.3c-0.4,0.8-0.1,1.5,1.1,1.9 M148.7,298.6c-0.8,0.8-1.2,1.9-1.2,3
	c0,1.2,0.4,2.2,1.2,3s1.9,1.2,3,1.2c1.2,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.9,1.2-3c0-1.2-0.4-2.2-1.2-3s-1.9-1.2-3-1.2
	S149.5,297.8,148.7,298.6L148.7,298.6z M143.6,290.1c1-1,2.2-1.9,3.4-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M154.6,265.3c-1.8,0.7-3.3,2.3-4.7,5.1l-6.3,19.7 M153.9,269.9
	c-2.1,2.6-2.9,6.9-2.6,12.9 M150.8,285.4c-1.4,0.5-2.6,1.2-3.7,1.9 M154.8,274.1l-1.7,10.3"
          />
          <path
            fill="#FFFFFF"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151.3,282.9c1.5-6.1,2.3-10.5,2.6-12.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M153.2,267.4c0.4-0.7,0.8-1.4,1.4-1.9 M180.1,266.4h1l0.3-0.1l0.1-0.3
	v-0.3l-0.1-0.3c-0.1-0.1-0.3-0.1-0.3-0.1H180 M178.6,264.1v-0.8h-2.9l-0.3,0.8 M180.3,267.2v-0.1h-0.1l-0.3-0.1 M179.9,267l0.1-0.5
	 M179.9,265.2l-0.1,0.8l-0.1,0.4h-0.1 M180.1,266.4h-0.6 M179.5,266.4h-1.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-1.9 M178.6,267.1l-0.3,0.4
	l-0.6,3 M177.9,271.1l0.3,0.4c0.1,0.1,0.1,0.1,0.4,0.1h1.1c0.3,0,0.6-0.1,0.6-0.5l0.6-3.2v-0.4l-0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M180.3,267.2v0.3l-0.6,3.2c0,0.3-0.3,0.5-0.6,0.5H178l-0.3-0.1
	 M178.6,267.1c0.1-0.1,0.3-0.1,0.4-0.1h1.1 M175.4,264.1L175,266l-0.3,0.4l-0.3,0.1H174l-0.3-0.1 M173.7,266.4
	c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.8 M173.6,267h0.3l0.3,0.1h0.1 M173.7,266.4l-0.1,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M174.3,267.1v0.4l-0.6,3 M177.7,270.5v0.1c0,0.1,0,0.3,0.1,0.4
	 M176.8,273.2l0.1-2.1l0.8-0.5 M174.2,270.8l0.6,0.4v2.1 M173.7,270.5l0.4,0.3 M174.3,267.1h4.3 M178.6,264.1h-3.2 M176.8,277.6h1.9
	c0.3,0,0.6,0.1,0.6,0.5v2.5 M174.4,277.6v1.8h2.2l0.1-1.8 M174.7,273.3l-0.3,4.3 M176.8,273.2v4.4 M181.2,258.7l-18,2.2
	c-4.5,0.8-6.9,1.8-6.9,3 M174.7,273.3l-19.9,0.8 M179.1,280.4l-7.4,0.8 M309.5,354.3h-16c-11.6-17.7-23.1-33.7-34.7-48.1
	c-4.4-5.9-10.3-8.9-17.3-9.1h-49.4c-10.9,0.1-18.7,1.1-23.8,2.8c-4.5,1.5-9.2,5.5-14.2,12c-0.6,1-1.4,1.4-2.5,1.2h-9.5l-2.6-0.3
	l-5.1-1.7 M353.5,219.1v-62h0.1l0.1-0.4v-1.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1s-0.3,0.1-0.4,0.1
	c-0.1,0.1-0.1,0.3-0.1,0.4v1.1c0,0.1,0.1,0.3,0.1,0.4v63.3 M266,248.7l-1.8-4.3l-5,0.6v0.1 M259.3,245.1l-1.2,0.1
	c-0.3,0-0.5,0.1-0.5,0.6v3.7 M259.3,245.1l-0.8,4.3 M257.7,249.4h0.8 M258.5,249.4l7.6-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M179.1,280.4c22.6-2.2,46.6-2.5,72.5-0.8c11.7,1,21,6.3,28.1,15.7
	c13.1,16.4,26.4,36,40,58.9h-10.2 M353.5,219.1l-0.7,1.2 M358.1,229.5c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4
	s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4S357.8,229.8,358.1,229.5L358.1,229.5z M353.5,235.7l-0.3-0.6
	l-0.4-0.3l-0.5-0.1c-0.4,0-0.7,0.1-1,0.4s-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4l0.5-0.1l0.4-0.3l0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.5,236.7l0.1-0.6l-0.1-0.4 M353.5,235.7v1 M350,265.3
	c0.5-6.9,1.5-12.8,2.8-17.6l0.7-2.6c1-3,2.1-5.6,3.3-7.8l5.5-10 M345.1,249.6c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4
	c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4s-0.7,0.1-1,0.4C345.3,248.9,345.1,249.2,345.1,249.6L345.1,249.6z
	 M342.5,240.6c0-0.3,0-0.4-0.1-0.6l-0.5,0.1c-0.3,0.1-0.4,0.4-0.5,0.7s-0.3,0.6-0.3,0.8c0,0.3,0,0.6,0.1,0.6c0.1,0.1,0.4,0,0.5-0.1
	l0.5-0.7C342.4,241.1,342.5,240.8,342.5,240.6L342.5,240.6z M339.4,242.6l-0.4,0.7 M334.8,243l-0.1-0.4l-1.8,0.1l-0.1,0.4
	 M334.8,243l0.1,0.6 M332.5,243.9l0.3-0.7 M332.5,243.9l2.5-0.3 M339.4,242.6l-4.5,0.4 M335,243.6l4-0.3 M343.8,258
	c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1s-0.1-0.7-0.4-1s-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4
	S343.8,257.7,343.8,258L343.8,258z M375.5,248.3c-1.2,0.1-3-0.8-5.5-2.9l-1.8-1.5 M365.5,254.7h-1.6 M373.3,253.8h-4.1
	c-0.7,0-1-0.3-1-1v-2.2 M363.9,254.7h-3.2l0.5,8.1c0,1,0.8,1.5,2.2,1.5h7.6c1.1,0,2.3-0.7,3.7-2.2 M368.3,243.7v6.9 M351.9,221.8
	l-12.5,20.8 M352.9,220.3l-1,1.5 M362.3,227.3l-10.5-5.5 M310.3,245.1l-0.3-2.3c-0.3-0.6-1-0.6-2.1-0.1l0.3,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M355.7,283.6l11-2.2v-2.1h-11.6v-3.6h11.6v-2.2l-11-2.1 M349.8,277.3v6.3
	c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3h0.7c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7v-0.8 M352.4,282.8l1.6,0.5l0.3,0.1l1.4,0.1
	 M352.4,277.3v5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M362.9,277.4c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	C362.9,276.9,362.9,277.1,362.9,277.4L362.9,277.4z M352.4,272.2v5.1 M349.8,320.3v-52l0.1-3 M350,265.3l-12.7,0.1 M349.8,277.3h2.6
	 M332.8,243.2l-22.4,1.9 M339,243.3c0.7,3.2,0.1,10.6-1.6,22.3 M310.5,246.1l22-2.2 M306.1,266.8c0.1-7.8,0.8-14.7,2.2-20.5
	 M308.3,245.2l-42.2,3.4 M257.7,249.4l-76.5,9.4 M337.3,265.5l-160.5,7.7 M355.7,331.9h1.1l10.6-1.2c1.1-0.1,1.6-0.7,1.6-1.5v-6.6
	c0-0.8-0.5-1.4-1.6-1.5l-10.6-1.2h-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M352.4,320.5v-0.1c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7
	c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7 M355.7,319.8l-1.4,0.1H354c-0.5,0.1-1.1,0.3-1.6,0.5 M357.1,325.2
	c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8
	c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3C357.5,324.7,357.3,324.9,357.1,325.2L357.1,325.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M171.1,371.4c0.3,0.8,0.8,1.6,1.5,2.5l1.6,1.2h0.1 M144,360.4h2.6
	 M140.6,363.5h2.6c0.6,0.1,0.8-1,0.8-3.2 M144,360.4h-2.6 M140.6,363.5l0.8-3.2 M210.9,360.8c-0.8-1.2-1.6-1.9-2.8-1.9
	s-2.1,0.7-2.8,1.9c-0.6,0.8-0.8,1.8-1,2.8c-0.1,0.5-0.1,1.1-0.1,1.8v1c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.8,1.1,2.8,1.1
	c1.1,0,2.1-0.4,2.8-1.1c0.6-0.7,1-1.4,1.1-2.2l-0.1-2.8C211.7,362.6,211.3,361.6,210.9,360.8L210.9,360.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M612.4,383.2c0,2.8-0.3,4.5-1.2,5.5l-6.5-1.6 M685.3,367.4h3.3
	 M685.3,371.8c0.4-1.4,0.4-2.9,0-4.4 M685.3,367.4l-13.1-0.3c-2.8,0-4.5-0.4-5.2-1.5l-0.5-1.1 M674.9,379.8l1.2-0.4
	c5.2-1.9,8.3-4.4,9.2-7.6 M649.2,379.4l2.2,0.5c8.4,1.9,16.2,1.9,23.5-0.1 M666.5,364.5l-19.8-0.8 M579.8,345c-1,1-1.7,2.3-1.8,4.1
	l-1.5,16c0,0.8-0.1,1.5-0.4,2.1c-0.4,0.5-0.8,1-1.4,1.1l-0.3,0.1l-1.7-0.1l-16.1-3.7c-1.7-0.4-3.2-0.3-4.4,0.4c1.2-1,2.8-1.4,5-1.2
	 M577.2,349.2c0.5-2.1,1.4-3.4,2.6-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.1,413.3c-1.7,1.4-3.3,1.9-4.7,1.9c1.4-0.1,2.8-1,3.9-2.3l10.6-12.1
	c0.4-0.7,1-1.1,1.5-1.4c0.8-0.3,1.5-0.3,2.1,0c0.5,0.3,1.1,0.7,1.5,1.4l10.7,12.2c1.1,1.2,2.3,1.9,3.7,2.2c-1.5,0.1-3-0.5-4.7-1.8
	 M604.9,387.2c-0.3,0.3-0.5,0.7-0.5,1.1c-0.4,1-0.3,2.1,0.5,3.4l6.7,11.8 M612.4,383.2l-5.8,2.6c-0.8,0.4-1.5,0.8-1.9,1.4
	 M572.4,360.4c1.2-0.8,2.5-1.4,3.9-1.9 M577.2,349.2c-5.8,1.4-10.9,4.4-15.4,8.9l-1.5,1.7 M572.4,360.4l-12.1-0.5 M600.6,361.6
	c-1.7-1.2-3.6-2.3-5.5-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.1,413.3c3.9,1.5,8,2.3,12.5,2.3c4.4,0,8.5-0.7,12.2-2.2 M612.8,362.2
	l-12.2-0.5 M518.1,357.9l8.3,0.4 M518.1,357.9c0-0.7-0.3-1-1-1h-3.6 M497.7,356.9h15.7 M463.2,348.4c-3.3,0.5-7.2,1-11.6,1
	 M470.3,346.3c-2.1,0.8-4.4,1.5-7.2,2.1 M451.8,356.9v-7.6 M463.2,348.4c2.5-0.3,4.8-1,7.2-2.1 M489,356.9h-37.3 M495.9,310.8
	c-0.7,4.4-3.3,10.3-8.1,17.9c-4.5,7.8-9.6,13.2-15.3,16.5l-2.2,1.1 M497.7,356.9H489 M377.1,349.4v7.6 M377.1,356.9h74.7
	 M451.8,349.4h-74.7 M134.7,311l-1,2.3 M374,254.7h-8.5 M674.2,261.9c1.7,23.8,2.5,47.9,2.3,72.2 M362.3,227.3l29-53.4 M377.1,356.9
	h-65.5 M688.8,315.5v-5.2 M700.1,376.1l-14.7-4.3 M674.9,379.8L699,387 M692.2,301.2h-3.4 M709.6,367.9l-20.9-0.5 M391.2,242.9
	l71.4-0.7c3.4-0.1,6.9-0.4,10-1c4.7-0.8,7-3.2,6.9-6.7l1.4-46.5c0.1-1.5,0-2.6-0.4-3.3c-0.4-0.6-1.2-0.8-2.3-0.7l-75.1,1.1
	c-3.6,0.1-5.9,1.2-7,3.3l-16.9,31"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M153.1,284.4
		c0,0,5.1-1.8,12-2.3c0,0,12.9-1.8,27.5-2.8c0,0,17.2-1.2,32.6-0.8c0,0,9.1,0,17.7,0.5l5.4,0.3c0,0,6.5-0.1,13.6,2.5
		c0,0,9.2,2.6,16.6,12.2c0,0,15.8,20.1,25.4,35.4c0,0,11.8,18.2,15.5,24.9h-10l2.1,2.8H377v-7.6c0,0-15.5,1.4-21-4.7
		c0,0-4.8-2.5-5.8-12c0,0-0.3-0.1-0.3-0.7c0-0.7,0-11.7,0-11.7v-52l0.3-2.9l-12.8,0.1l-182.5,8.7L153.1,284.4z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M368.3,244.8h-14.6
		c0,0-2.9,7.7-3.9,23.5V332c0,0,0,0.6,0.3,0.7c0,0,0.4,8.8,5.8,12c0,0,4,5.5,21,4.7h74.7c0,0,14.2,0.4,21.9-4.7
		c0,0,6.7-3.7,13.6-14.7c0,0,7.7-11.7,8.8-19v-47.7L496,245l-15.3-6.3c0,0-1.4,2.3-5,3.9c0,0-4.3,1.7-14.6,1.8l-70.2,0.7
		c0,0-0.3,3.7-2.9,3.3h-12.8v4.7c0,0,0,1-1,1H373l0.5,0.8h1.2c0,0,1,0,0.8,1l-0.4,5c0,0,0,0.7-0.8,1l0,0c-0.8,0.3-0.1,3-4.8,5.5
		c0,0-5.5,2.6-9.9-2.1c0,0-3-2.6-2.2-7.6c0,0,0.8-5,5.6-6.5c0,0,2.1-0.8,4.5-0.3L368.3,244.8z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M368.3,243.7v8.8
		c0,0-0.1,1.2,1.1,1.2h5.2c0,0,1,0.1,1-1.2v-4.4h12.8c0,0,2.5,0.7,2.9-3.7v-21c0,0,0.4-4.1-2.3-4.1h-13.8c0,0-1.9-0.4-4.3,1.1
		c0,0-4.5,3.2-4.8,6.7v11.8C366.1,239,365.6,242.1,368.3,243.7z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M451.8,356.9
		H489c0,0,10.3-16,36.5-49.8l5.8-7.3c0,0,5-6.9,14.4-10.5c0,0,7.2-3.3,19-3.9c0,0,26.6-3,60.3-1.4l7.3,0.4c0,0,7.3,0.3,13.3,4
		c0,0,4.7,3,6.9,6.5l1.4,2.1c0,0,5,7.8,8.1,13.8c0,0,8.9,16.6,11,21.9l-19.1-0.7l1.4,3.4h21.3c0,0,0.4-29.6-2.3-73.5L496,263.4v46.9
		c0,0,0.4,3.6-5.4,13.9l-3.9,6.3c0,0-3.9,5.6-5.9,7.8c0,0-4,5.5-12.5,8.9c0,0-6.1,2.2-16.6,2.2v7.3L451.8,356.9L451.8,356.9z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M379.1,219.4
		l16.8-31.1c0,0,1.4-3.3,7-3.3l75.4-1.2c0,0,3.6-0.8,2.8,4l-1.4,45.9c0,0,0.5,4.4-3.4,6.2c0,0-2.3,1.8-13.6,2.2l-71.5,0.7v-20.1
		c0,0,0.1-3.4-2.3-3.4L379.1,219.4z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M512.6,235.6v-49.1
		c0,0,0-2.6,2.2-3.9c2.2-1.1,3.2-1.1,5-1.1h52h42.5h22.6c0,0,3.3-0.4,5.4,1.5c2.2,1.9,2.3,5.2,2.3,5.2l2.2,24.3l2.3,24.5
		c0,0,0.4,2.3-1,3.6c0,0-1.5,2.5-9.6,2.9H604h-58.3h-19.9c0,0-5.2,0.6-8.8-1.1C516.7,242.6,512.6,241.2,512.6,235.6z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="208.3"
              cy="377.4"
              r="63.6"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="586"
              cy="381.8"
              r="63.6"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv2DrLeft);
