import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadConditionParts } from '../../../../../../../actions';

import Sedan4DrLeft from './sedan4Dr/Left';
import Sedan4DrRight from './sedan4Dr/Right';
import Sedan4DrFront from './sedan4Dr/Front';
import Sedan4DrRear from './sedan4Dr/Rear';
import Sedan4DrTop from './sedan4Dr/Top';

import Suv4DrLeft from './suv4Dr/Left';
import Suv4DrRight from './suv4Dr/Right';
import Suv4DrFront from './suv4Dr/Front';
import Suv4DrRear from './suv4Dr/Rear';
import Suv4DrTop from './suv4Dr/Top';

import Suv2DrLeft from './suv2Dr/Left';
import Suv2DrRight from './suv2Dr/Right';
import Suv2DrFront from './suv2Dr/Front';
import Suv2DrRear from './suv2Dr/Rear';
import Suv2DrTop from './suv2Dr/Top';

import Coupe2DrLeft from './coupe2Dr/Left';
import Coupe2DrRight from './coupe2Dr/Right';
import Coupe2DrFront from './coupe2Dr/Front';
import Coupe2DrRear from './coupe2Dr/Rear';
import Coupe2DrTop from './coupe2Dr/Top';

import Coupe4DrLeft from './coupe4Dr/Left';
import Coupe4DrRight from './coupe4Dr/Right';
import Coupe4DrFront from './coupe4Dr/Front';
import Coupe4DrRear from './coupe4Dr/Rear';
import Coupe4DrTop from './coupe4Dr/Top';

import Convertible2DrLeft from './convertible2Dr/Left';
import Convertible2DrRight from './convertible2Dr/Right';
import Convertible2DrFront from './convertible2Dr/Front';
import Convertible2DrRear from './convertible2Dr/Rear';
import Convertible2DrTop from './convertible2Dr/Top';

import CrewCabPickup4DrLeft from './crewCabPickup4Dr/Left';
import CrewCabPickup4DrRight from './crewCabPickup4Dr/Right';
import CrewCabPickup4DrFront from './crewCabPickup4Dr/Front';
import CrewCabPickup4DrRear from './crewCabPickup4Dr/Rear';
import CrewCabPickup4DrTop from './crewCabPickup4Dr/Top';

import Crossover4DrLeft from './crossover4Dr/Left';
import Crossover4DrRight from './crossover4Dr/Right';
import Crossover4DrFront from './crossover4Dr/Front';
import Crossover4DrRear from './crossover4Dr/Rear';
import Crossover4DrTop from './crossover4Dr/Top';

import ExtendedCabPickup2DrLeft from './extendedCabPickup2Dr/Left';
import ExtendedCabPickup2DrRight from './extendedCabPickup2Dr/Right';
import ExtendedCabPickup2DrFront from './extendedCabPickup2Dr/Front';
import ExtendedCabPickup2DrRear from './extendedCabPickup2Dr/Rear';
import ExtendedCabPickup2DrTop from './extendedCabPickup2Dr/Top';

import ExtendedCabPickup4DrLeft from './extendedCabPickup4Dr/Left';
import ExtendedCabPickup4DrRight from './extendedCabPickup4Dr/Right';
import ExtendedCabPickup4DrFront from './extendedCabPickup4Dr/Front';
import ExtendedCabPickup4DrRear from './extendedCabPickup4Dr/Rear';
import ExtendedCabPickup4DrTop from './extendedCabPickup4Dr/Top';

import Hatchback2DrLeft from './hatchback2Dr/Left';
import Hatchback2DrRight from './hatchback2Dr/Right';
import Hatchback2DrFront from './hatchback2Dr/Front';
import Hatchback2DrRear from './hatchback2Dr/Rear';
import Hatchback2DrTop from './hatchback2Dr/Top';

import Hatchback3DrLeft from './hatchback3Dr/Left';
import Hatchback3DrRight from './hatchback3Dr/Right';
import Hatchback3DrFront from './hatchback3Dr/Front';
import Hatchback3DrRear from './hatchback3Dr/Rear';
import Hatchback3DrTop from './hatchback3Dr/Top';

import Hatchback4DrLeft from './hatchback4Dr/Left';
import Hatchback4DrRight from './hatchback4Dr/Right';
import Hatchback4DrFront from './hatchback4Dr/Front';
import Hatchback4DrRear from './hatchback4Dr/Rear';
import Hatchback4DrTop from './hatchback4Dr/Top';

import Hatchback5DrLeft from './hatchback5Dr/Left';
import Hatchback5DrRight from './hatchback5Dr/Right';
import Hatchback5DrFront from './hatchback5Dr/Front';
import Hatchback5DrRear from './hatchback5Dr/Rear';
import Hatchback5DrTop from './hatchback5Dr/Top';

import RegularCabPickup2DrLeft from './regularCabPickup2Dr/Left';
import RegularCabPickup2DrRight from './regularCabPickup2Dr/Right';
import RegularCabPickup2DrFront from './regularCabPickup2Dr/Front';
import RegularCabPickup2DrRear from './regularCabPickup2Dr/Rear';
import RegularCabPickup2DrTop from './regularCabPickup2Dr/Top';

import SportsVanExtendedLeft from './sportsVanExtended/Left';
import SportsVanExtendedRight from './sportsVanExtended/Right';
import SportsVanExtendedFront from './sportsVanExtended/Front';
import SportsVanExtendedRear from './sportsVanExtended/Rear';
import SportsVanExtendedTop from './sportsVanExtended/Top';

import SportsVanLeft from './sportsVan/Left';
import SportsVanRight from './sportsVan/Right';
import SportsVanFront from './sportsVan/Front';
import SportsVanRear from './sportsVan/Rear';
import SportsVanTop from './sportsVan/Top';

import Wagon4DrLeft from './wagon4Dr/Left';
import Wagon4DrRight from './wagon4Dr/Right';
import Wagon4DrFront from './wagon4Dr/Front';
import Wagon4DrRear from './wagon4Dr/Rear';
import Wagon4DrTop from './wagon4Dr/Top';

class Splat extends Component {
  handleAreaPress = value => {
    this.props.navigate('/core/condition/parts', {
      state: {
        condition: {
          area: value,
          selectedRow: this.props.selectedRow,
        },
      },
    });
  };

  render() {
    if (!this.props.type && !this.props.area) {
      return null;
    }
    switch (this.props.type) {
      case 'SEDAN 4-DR':
      case 'SEDAN':
        switch (this.props.area) {
          case 'left':
            return (
              <Sedan4DrLeft
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'rear':
            return (
              <Sedan4DrRear
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'right':
            return (
              <Sedan4DrRight
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'front':
            return (
              <Sedan4DrFront
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'top':
            return (
              <Sedan4DrTop
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );

          default:
            return null;
        }
      case 'SPORT UTILITY 4-DR':
      case 'SPORT UTILITY TRUCK 4-DR':
      case 'SUV':
        switch (this.props.area) {
          case 'left':
            return <Suv4DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Suv4DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Suv4DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Suv4DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Suv4DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'SPORT UTILITY 2-DR':
      case 'SPORT UTILITY 2-DR HARD-TOP':
      case 'SPORT UTILITY 2-DR SOFT-TOP':
        switch (this.props.area) {
          case 'left':
            return <Suv2DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Suv2DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Suv2DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Suv2DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Suv2DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'COUPE':
      case 'COUPE 2-DR':
      case 'HARDTOP':
      case 'HARDTOP 2-DR':
      case 'SEDAN 2-DR':
        switch (this.props.area) {
          case 'left':
            return <Coupe2DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Coupe2DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Coupe2DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Coupe2DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Coupe2DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'COUPE 4-DR':
        switch (this.props.area) {
          case 'left':
            return <Coupe4DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Coupe4DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Coupe4DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Coupe4DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Coupe4DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'CONVERTIBLE 2-DR':
        switch (this.props.area) {
          case 'left':
            return (
              <Convertible2DrLeft handleAreaPress={this.handleAreaPress} />
            );
          case 'rear':
            return (
              <Convertible2DrRear handleAreaPress={this.handleAreaPress} />
            );
          case 'right':
            return (
              <Convertible2DrRight handleAreaPress={this.handleAreaPress} />
            );
          case 'front':
            return (
              <Convertible2DrFront handleAreaPress={this.handleAreaPress} />
            );
          case 'top':
            return <Convertible2DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'CREW CAB PICKUP 4-DR':
        switch (this.props.area) {
          case 'left':
            return (
              <CrewCabPickup4DrLeft handleAreaPress={this.handleAreaPress} />
            );
          case 'rear':
            return (
              <CrewCabPickup4DrRear handleAreaPress={this.handleAreaPress} />
            );
          case 'right':
            return (
              <CrewCabPickup4DrRight handleAreaPress={this.handleAreaPress} />
            );
          case 'front':
            return (
              <CrewCabPickup4DrFront handleAreaPress={this.handleAreaPress} />
            );
          case 'top':
            return (
              <CrewCabPickup4DrTop handleAreaPress={this.handleAreaPress} />
            );
          default:
            return null;
        }
      case 'CROSSOVER 4-DR':
      case 'MPV':
        switch (this.props.area) {
          case 'left':
            return <Crossover4DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Crossover4DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Crossover4DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Crossover4DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Crossover4DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'CLUB CAB PICKUP':
      case 'CLUB CAB PICKUP 2-DR':
      case 'EXTENDED CAB PICKUP':
      case 'EXTENDED CAB PICKUP 2-DR':
      case 'EXTENDED CAB PICKUP 3-DR':
        switch (this.props.area) {
          case 'left':
            return (
              <ExtendedCabPickup2DrLeft
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'rear':
            return (
              <ExtendedCabPickup2DrRear
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'right':
            return (
              <ExtendedCabPickup2DrRight
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'front':
            return (
              <ExtendedCabPickup2DrFront
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'top':
            return (
              <ExtendedCabPickup2DrTop handleAreaPress={this.handleAreaPress} />
            );
          default:
            return null;
        }
      case 'EXTENDED CAB PICKUP 4-DR':
        switch (this.props.area) {
          case 'left':
            return (
              <ExtendedCabPickup4DrLeft
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'rear':
            return (
              <ExtendedCabPickup4DrRear
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'right':
            return (
              <ExtendedCabPickup4DrRight
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'front':
            return (
              <ExtendedCabPickup4DrFront
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'top':
            return (
              <ExtendedCabPickup4DrTop handleAreaPress={this.handleAreaPress} />
            );
          default:
            return null;
        }
      case 'HATCHBACK 2-DR':
      case 'HATCHBACK':
        switch (this.props.area) {
          case 'left':
            return <Hatchback2DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Hatchback2DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Hatchback2DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Hatchback2DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Hatchback2DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'HATCHBACK 3-DR':
      case 'LIFTBACK 3-DR':
        switch (this.props.area) {
          case 'left':
            return <Hatchback3DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Hatchback3DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Hatchback3DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Hatchback3DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Hatchback3DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'HATCHBACK 4-DR':
        switch (this.props.area) {
          case 'left':
            return <Hatchback4DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Hatchback4DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Hatchback4DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Hatchback4DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Hatchback4DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'HATCHBACK 5-DR':
      case 'LIFTBACK 5-DR':
        switch (this.props.area) {
          case 'left':
            return <Hatchback5DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Hatchback5DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Hatchback5DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Hatchback5DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Hatchback5DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'REGULAR CAB PICKUP 2-DR':
      case 'CONVENTIONAL CAB':
      case 'CREW CAB PICKUP 2-DR':
      case 'PICKUP':
      case 'TRUCK':
        switch (this.props.area) {
          case 'left':
            return (
              <RegularCabPickup2DrLeft handleAreaPress={this.handleAreaPress} />
            );
          case 'rear':
            return (
              <RegularCabPickup2DrRear handleAreaPress={this.handleAreaPress} />
            );
          case 'right':
            return (
              <RegularCabPickup2DrRight
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'front':
            return (
              <RegularCabPickup2DrFront
                handleAreaPress={this.handleAreaPress}
              />
            );
          case 'top':
            return (
              <RegularCabPickup2DrTop handleAreaPress={this.handleAreaPress} />
            );
          default:
            return null;
        }
      case 'EXTENDED SPORTS VAN':
      case 'EXTENDED WINDOW VAN':
        switch (this.props.area) {
          case 'left':
            return (
              <SportsVanExtendedLeft handleAreaPress={this.handleAreaPress} />
            );
          case 'rear':
            return (
              <SportsVanExtendedRear handleAreaPress={this.handleAreaPress} />
            );
          case 'right':
            return (
              <SportsVanExtendedRight handleAreaPress={this.handleAreaPress} />
            );
          case 'front':
            return (
              <SportsVanExtendedFront handleAreaPress={this.handleAreaPress} />
            );
          case 'top':
            return (
              <SportsVanExtendedTop handleAreaPress={this.handleAreaPress} />
            );
          default:
            return null;
        }
      case 'SPORTS VAN':
      case 'CAMPER VAN':
      case 'CARGO CONVERSION VAN':
      case 'PASSENGER VAN':
      case 'STEP VAN':
      case 'VAN':
      case 'WINDOW VAN':
        switch (this.props.area) {
          case 'left':
            return <SportsVanLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <SportsVanRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <SportsVanRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <SportsVanFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <SportsVanTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'WAGON 4-DR':
      case 'WAGON':
      case 'WAGON 5-DR':
        switch (this.props.area) {
          case 'left':
            return <Wagon4DrLeft handleAreaPress={this.handleAreaPress} />;
          case 'rear':
            return <Wagon4DrRear handleAreaPress={this.handleAreaPress} />;
          case 'right':
            return <Wagon4DrRight handleAreaPress={this.handleAreaPress} />;
          case 'front':
            return <Wagon4DrFront handleAreaPress={this.handleAreaPress} />;
          case 'top':
            return <Wagon4DrTop handleAreaPress={this.handleAreaPress} />;
          default:
            return null;
        }
      case 'BUS':
      case 'CARGO CUTAWAY':
      case 'CARGO VAN':
      case 'CARGO VAN 2-DR':
      case 'CARGO VAN 3-DR':
      case 'CONVERSION VAN':
      case 'EXTENDED CARGO VAN':
      case 'UTILITY VAN':
      default:
        switch (this.props.area) {
          case 'left':
            return (
              <Sedan4DrLeft
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'rear':
            return (
              <Sedan4DrRear
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'right':
            return (
              <Sedan4DrRight
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'front':
            return (
              <Sedan4DrFront
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );
          case 'top':
            return (
              <Sedan4DrTop
                handleAreaPress={this.handleAreaPress}
                {...this.props}
              />
            );

          default:
            return null;
        }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { condition } = state.core;
  return { condition };
};

export default connect(
  mapStateToProps,
  {
    loadConditionParts,
  }
)(Splat);
