import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import moment from 'moment';
import PostApiWithHeader from '../common/PostApiWithHeader';
import { getConfig } from '../../utils/helpers';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SingleDatePicker from '../common/SingleDatePicker';
import jstz from 'jstz';
const timezone = jstz.determine().name();
window.$ = window.jQuery = $;

class CreateHyperLotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hyperLotName: '',
      isRerun: false,
      // startDate: moment().tz(timezone),
      lengthInHours: '',
    };

    this.createHyperLotAction = this.createHyperLotAction.bind(this);
    this.changeHyperLotName = this.changeHyperLotName.bind(this);
    this.handleRerun = this.handleRerun.bind(this);
    this.handleDateEvent = this.handleDateEvent.bind(this);
    this.callEventApi = this.callEventApi.bind(this);
  }

  changeHyperLotName(evt) {
    this.setState({
      hyperLotName: evt.target.value,
    });
  }

  handleDateEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
    });
  }

  resetData() {
    this.refs.hyperLotName.value = '';
    this.refs.lengthInHours.value = '';
    this.setState({
      hyperLotName: '',
      lengthInHours: '',
    });
  }

  createHyperLotAction(e) {
    if (!this.state.startDate) {
      toastr.error('Error', 'Please select live start time');
    } else if (!this.state.hyperLotName) {
      toastr.error('Error', 'Please enter hyperlot name');
    } else if (!this.state.lengthInHours) {
      toastr.error('Error', 'Please select length in hours');
    } else {
      const startTime = moment().tz(timezone).toDate();
      const endTime = new moment(`${this.state.startDate}` - '8:00')
        .tz(timezone)
        .add(parseInt(`${this.state.lengthInHours}` - '8:00', 10), 'h')
        .toDate();
      const createEventUrl =
        `${getConfig('apiRoot')}user-event-add` +
        `?apiKey=${this.props.cookies.get('apiKey')}`;
      let eventPostBody = {
        eventId: 0,
        accountId: 0,
        marketplaceId: parseInt(getConfig('marketplaceId'), 10),
        eventName: this.state.hyperLotName,
        description: '',
        features: '',
        eventTypeId: 3,
        eventSubTypeId: 4,
        startTime: startTime,
        endTime: endTime,
        liveStartTime: this.state.startDate.toDate(),
        automated: 0,
        privateEvent: false,
        statusId: 2,
        progressStatusId: 0,
        authorizedSellerGroupIds: [],
        authorizedBuyerGroupIds: [],
        authorizedSellers: [],
        authorizedBuyers: [],
      };

      const addVehiclesUrl = `${getConfig(
        'apiRoot'
      )}batch-items-update?apiKey=${this.props.cookies.get('apiKey')}`;
      PostApiWithHeader(createEventUrl, eventPostBody)
        .then(json => {
          if (json.wsStatus === 'Error') {
            toastr.error('Error', json.wsMessage);
          } else if (json.wsStatus === 'Success') {
            this.callEventApi(addVehiclesUrl, json.eventId);
            //toastr.success('Success', json.wsMessage)
          }
        })
        .catch(error => {});
    }
  }

  callEventApi(api, eventId) {
    let vehiclesPostBody = [];
    this.props.items.map(itemId => {
      return vehiclesPostBody.push({
        itemId: itemId,
        statusId: 1,
        eventId: eventId,
      });
    });
    let hyperLotName = this.state.hyperLotName;
    PostApiWithHeader(api, vehiclesPostBody)
      .then(json => {
        if (json.wsStatus === 'Error') {
          toastr.error('Error', json.wsMessage);
        } else if (json.wsStatus === 'Success') {
          this.resetData();
          toastr.success('Success', json.wsMessage);
          $('#createHyperLotModal').modal('hide');
          $('#createHyperLotModal.in').modal('hide');
          window.location =
            '/search?eventName[0]=' +
            encodeURIComponent(hyperLotName.toLowerCase());
        }
      })
      .catch(error => {});
  }

  onSelectChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleRerun(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="modal fade" id="createHyperLotModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title">
                <span className="fa fa-table" />
                Create HyperLot
              </h3>
            </div>
            <div className="modal-body recall-data">
              <div className="row">
                <div className="col-xs-12">
                  <p>{this.props.items.length} vehicle(s) selected.</p>
                  <label>HyperLot Name</label> <br />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    ref="hyperLotName"
                    onChange={this.changeHyperLotName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <SingleDatePicker
                    displayName={'Live Start Time'}
                    startDate={this.state.startDate}
                    handleEvent={this.handleDateEvent}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <label>Length of Time</label> <br />
                  <select
                    className="form-control"
                    ref="lengthInHours"
                    name="lengthInHours"
                    onChange={this.onSelectChange.bind(this)}
                  >
                    <option value="">select</option>
                    <option value="1">1 hour</option>
                    <option value="2">2 hours</option>
                    <option value="3">3 hours</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 checkbox" style={{ display: 'none' }}>
                  <label>
                    <input
                      name="isRerun"
                      type="checkbox"
                      onChange={this.handleRerun}
                    />
                    Rerun Vehicle Until Sold
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.createHyperLotAction}
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateHyperLotModal;
