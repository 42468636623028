import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import {
  loadAggregationsES,
  loadAllActivityES,
  loadBiddingOn,
  loadBuyNow,
  loadBuyNowCount,
  loadElasticSearchDistance,
  loadItemCount,
  loadMarketplaceCount,
  loadMarketplaceRecentlyAdded,
  loadMostRecent,
  loadNoSalesFromEventsES,
  loadRecentlyAdded,
  loadRecentlyViewed,
  loadRetailCount,
  loadRetailItems,
  loadRunningToday,
  loadRunningTodayChild,
  loadSellingNow,
  loadSellingNowCount,
  loadTradeCount,
  loadTradeItems,
  loadWatchlistES,
} from '../actions';
import { getConfig } from '../utils/helpers';

class Test extends Component {
  state = {
    statusIds: [1, 3],
    ids: [14671399, 14671399, 14631390, 14588889],
    size: null,
    results: [],
  };

  componentDidMount() {
    var start = window.performance.now();

    for (let i = 0; i < 10000; i++) {
      getConfig('marketplaceId');
    }
    var end = window.performance.now();
    var time = end - start;
    console.log('>time', time);
  }

  returnCounts = ({ response }) => {
    return response ? response.count : null;
  };

  returnHits = ({ response }) => {
    return response && response.hits ? response.hits.hits : null;
  };

  handleClick = funcs => {
    this.setState({ results: [] }, async () => {
      const results = await Promise.all(funcs.map(obj => obj.func()));

      if (results) {
        this.setState({ results });
      }
    });
  };

  loadESFuncs = () => {
    const { statusIds, ids, size } = this.state;
    const { events, unauthorizedEventIds } = this.props;
    const mpId = getConfig('marketplaceId');
    const eventIds = events.eventList.map(event => event.eventId);

    return [
      () =>
        this.props
          .loadItemCount(statusIds, unauthorizedEventIds)
          .then(this.returnCounts),

      () =>
        this.props
          .loadBuyNowCount(statusIds, unauthorizedEventIds)
          .then(this.returnCounts),

      () =>
        this.props
          .loadSellingNowCount(statusIds, unauthorizedEventIds)
          .then(this.returnCounts),

      () =>
        this.props
          .loadTradeCount(statusIds, unauthorizedEventIds)
          .then(this.returnCounts),

      () =>
        this.props
          .loadRetailCount(statusIds, unauthorizedEventIds)
          .then(this.returnCounts),

      () =>
        this.props
          .loadMarketplaceCount(mpId, statusIds, unauthorizedEventIds)
          .then(this.returnCounts),

      () =>
        this.props
          .loadWatchlistES(ids, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadBiddingOn(ids, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadRecentlyViewed(ids, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadSellingNow(size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props.loadBuyNow(size, unauthorizedEventIds).then(this.returnHits),

      () =>
        this.props
          .loadAllActivityES(ids, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadNoSalesFromEventsES(eventIds, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadRecentlyAdded(statusIds, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadRunningToday(size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadMostRecent(statusIds, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadRunningTodayChild(mpId, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadTradeItems(statusIds, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadRetailItems(statusIds, size, unauthorizedEventIds)
          .then(this.returnHits),

      () =>
        this.props
          .loadAggregationsES('', {
            query: {
              bool: {
                must_not: [{ terms: { eventId: unauthorizedEventIds } }],
              },
            },
          })
          .then(this.returnHits),

      () =>
        this.props
          .loadMarketplaceRecentlyAdded(
            mpId,
            statusIds,
            size,
            unauthorizedEventIds
          )
          .then(this.returnHits),
    ].map(func => {
      let str = String(func);
      const start = str.indexOf('props.') + 6;
      const end = str.indexOf('(', start);
      str = str.slice(start, end);

      return { func, str };
    });
  };

  renderFuncs(funcs) {
    return funcs.map((obj, index) => {
      const val = this.state.results[index];
      const icon = val === undefined ? ` • ` : val === null ? `❌` : `✅`;
      const resultVal =
        val === undefined
          ? ''
          : Array.isArray(val)
          ? `...${val.length} items`
          : `...${val} count`;

      return (
        <div key={index} style={{ marginLeft: 10 }}>
          <span style={{ paddingRight: 10, whiteSpace: 'pre' }}>{icon}</span>
          <span style={{ paddingRight: 10 }}>{obj.str}</span>
          <span>{resultVal}</span>
        </div>
      );
    });
  }

  render() {
    const esFuncs = this.loadESFuncs();

    return (
      <DocumentTitle title="Test">
        <div className="container-fluid" style={{ margin: 100 }}>
          <h3>ELASTICSEARCH API CALLS</h3>
          {this.renderFuncs(esFuncs)}
          <button
            style={{ marginTop: 10 }}
            onClick={() => this.handleClick(esFuncs)}
          >
            TEST ALL
          </button>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => {
  const { unauthorizedEventIds, events } = state.entities;
  return { unauthorizedEventIds, events };
};

export default connect(mapStateToProps, {
  loadAggregationsES,
  loadAllActivityES,
  loadBiddingOn,
  loadBuyNow,
  loadBuyNowCount,
  loadElasticSearchDistance,
  loadItemCount,
  loadMarketplaceCount,
  loadMarketplaceRecentlyAdded,
  loadMostRecent,
  loadNoSalesFromEventsES,
  loadRecentlyAdded,
  loadRecentlyViewed,
  loadRetailCount,
  loadRetailItems,
  loadRunningToday,
  loadRunningTodayChild,
  loadSellingNow,
  loadSellingNowCount,
  loadTradeCount,
  loadTradeItems,
  loadWatchlistES,
})(Test);
