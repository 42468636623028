import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup2DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M80.6,263.6c-3,9.2-5.2,20.2-6.8,33.1h27.5c7,0.1,11.8,2.2,14.4,6.6l-12,23.9c1.5,2.1,3.5,3.5,5.8,4.2
	l0.1-0.5c4.8-17.8,10.3-32.2,16.4-43.2c0.9-1.7,1.9-3.2,2.8-4.7l1.2-1.7c4.1-5.4,9.9-9.1,17.4-11l3.2-0.7c2.8-0.5,5.8-0.7,9.1-0.7
	c7.2-0.2,14.3-0.2,21.1,0l2.1,0.1c12.9,0.5,25,1.7,36.4,3.5c13.1,1.8,23.9,8.3,32.2,19.6c7.3,11.1,12.8,25,16.4,41.6
	c0.2,0.9,0.1,1.8-0.1,2.5c-0.5,1.3-1.8,2.2-3.8,2.7l-5.5,1.7l1.2,9.9l50.2-1.4c1.2,0.2,2.1,0.1,2.7-0.2c1.1-0.6,1.3-2.1,0.7-4.4
	l-0.7-1.8c-1.2-3.3-2.1-7.8-3-13.6c-1.2-8.7-2.1-17.7-2.8-26.9c-0.6-8.1-0.9-16.5-0.9-25c0-5.7,0-11.6,0.2-17.5
	c0.4-7.9,0.8-15.7,1.3-23.3H56.1l-0.4,3.3l23.4,12.8c3.2,1.5,4.1,3.8,2.9,6.9L80.6,263.6L80.6,263.6z M225.8,267.1
	c0,1.8,0.6,3,1.5,3.7"
          />
          <path
            fill="#FFFFFF"
            d="M180.7,268.9c-6.8-0.2-13.8-0.2-21.1,0c-3.2,0-6.3,0.2-9.1,0.7l-3.2,0.7c-7.4,1.8-13.2,5.4-17.4,11l-1.2,1.7
	c-0.9,1.5-1.9,3.1-2.8,4.7c-6.1,11-11.6,25.4-16.4,43.2l-0.1,0.5l4.5,0.9c5.3-15.6,11.2-29,17.7-40.3l0.9-1.5
	c5.6-8.7,13.3-13.5,23.4-14.3c7.8-0.6,15.9-0.7,24.6-0.5l21.7,1.3c15.6,1.3,27.2,4.4,34.6,9.3c10.3,6.5,17.9,18.9,23.3,37.2
	c1.7,6.7,4.2,11,7.6,12.5c0.2-0.7,0.2-1.5,0.1-2.5c-3.7-16.5-9.1-30.3-16.4-41.6c-8.4-11.3-19.1-17.8-32.2-19.6
	c-11.3-1.9-23.5-3.1-36.4-3.5L180.7,268.9L180.7,268.9L180.7,268.9z"
          />
          <path
            fill="#FFFFFF"
            d="M180.7,275.8c-8.6-0.2-16.8-0.1-24.6,0.5c-10,0.7-17.8,5.4-23.4,14.3l-0.9,1.5
	c-6.5,11.2-12.4,24.6-17.7,40.3l9.7,2.1c0.7-3,1.5-5.8,2.5-8.7c1.7-5.1,3.4-9.7,5.3-13.9c0.9-2,1.8-3.9,2.7-5.8l0.1-0.2
	c1.1-2,2-3.8,3.1-5.5l2-3.1l1.9-3c1.1-1.5,2.2-3,3.7-4.1c0.8-0.7,1.8-1.4,2.7-2c0.5-0.2,1.1-0.6,1.5-0.7l2.1-0.9
	c4.1-1.7,9.2-2.4,15.2-2.1h0.1c18.3-0.2,36.1,1.3,53.6,4.5l1.8,0.5c7.9,2.1,14.8,7.1,20.5,14.9v0.1c6,9.2,11.1,21.4,15.2,36.6
	l5.5-1.7c2-0.6,3.3-1.4,3.8-2.7c-3.3-1.5-5.9-5.8-7.6-12.5c-5.2-18.3-13-30.8-23.3-37.2c-7.4-5-18.9-8-34.5-9.3L180.7,275.8z"
          />
          <path
            fill="#656565"
            d="M166.8,284.2c-5.9-0.2-11,0.5-15.2,2.1l-2.1,0.9c-0.6,0.2-1.1,0.5-1.5,0.7c-1.1,0.6-1.9,1.3-2.7,2
	c-1.4,1.2-2.6,2.6-3.7,4.1l-1.9,3l-2,3.1c-1.1,1.8-2,3.5-3.1,5.5l-0.1,0.2c-0.9,1.8-1.9,3.8-2.7,5.8c-1.9,4.2-3.7,9-5.3,13.9
	c-1.1,3-1.9,5.8-2.5,8.7c-0.9,4-1.4,8-1.5,12c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2
	c0.2-0.1,0.4-0.2,0.6-0.4c0-2.5,0.2-4.8,0.5-7.2c0.6-4.5,1.7-9,3.3-13.3l0.6-1.7c1.5-4.4,3.1-8.5,4.7-12.3l2.7-5.7l0.1-0.2
	c1.1-1.9,2-3.7,3.1-5.3l2-3l2-3c0.9-1.4,2-2.7,3.3-3.8c0.7-0.7,1.5-1.3,2.5-1.8l3.4-1.7c4-1.5,8.9-2.2,14.5-2
	c18.2-0.2,36,1.2,53.3,4.4l1.8,0.5c7.6,2.1,14.1,6.8,19.7,14.3c3.4,5.4,6.6,11.8,9.6,19.4c1.4,3.7,2.7,7.4,3.9,11.6l1.5,5.2l1.7-0.5
	c-4.2-15.2-9.3-27.4-15.2-36.6v-0.1c-5.9-7.8-12.8-12.8-20.5-14.9l-1.8-0.5C203.1,285.5,185.1,283.9,166.8,284.2L166.8,284.2z"
          />
          <path
            fill="#FFFFFF"
            d="M344.4,161c-0.7-0.4-1.5-0.6-2.5-0.8l-4.2-1.5c-2.4-0.5-4.6-1.4-5-1.7c-1.2,0.6-1.5,1.4-1.3,3l0.5,3.3
	l-0.2,0.8c-0.4,0.9-1.1,1.5-2.1,1.8l6.8-0.9l13.7-0.2C348.7,163.2,346.8,162,344.4,161L344.4,161z M333.5,175
	c-8.7-0.2-14,4.5-16.1,14.2c-4.2,25.1-6.1,56.1-5.8,92.5c-0.1,7.1,0.1,13.9,0.5,20.5l58.4,0.1l37.3,0.2c0.6-16.9-0.1-36.2-2.2-58.1
	c-33.5-1.1-56.9-3.7-70.3-7.9c-4.4-1.9-6.6-5.1-6.7-9.7v-1.1c0.2-19,1.8-32.2,4.7-39.6l0.1-0.4c1.2-4.5,3.5-7.2,7.3-8.1
	c15-1.4,29.9-1.8,44.6-1.1c3.2-0.2,5.3,1.8,6.3,6c1.7,15.5,2.6,32,2.6,49.9c0,4.5-2.4,6.6-7,6.1l-0.8-0.1c-15.2-0.5-30.2-2-45-4.7
	c-7.4-1.2-10.9-5.2-10.4-12.2c-0.1-10,0.5-20.8,1.7-32.2c0.1-1.2,0.2-2.4,0.5-3.4c-3,7.3-4.6,20.5-4.7,39.6v1.1
	c0.1,4.6,2.4,7.8,6.7,9.7c13.2,4.2,36.7,7,70.3,7.9l-7-70.7l-29.2,0.1c-17.8,0.1-31.2,0.5-40.1,0.9L333.5,175L333.5,175z"
          />
          <path
            fill="#FFFFFF"
            d="M307.1,236.5c-0.5,7.6-0.9,15.4-1.3,23.3c-0.1,5.9-0.2,11.8-0.2,17.5c0.1,8.5,0.4,16.9,0.9,25
	c0.7,9.2,1.5,18.2,2.8,26.9c0.8,5.7,1.8,10.3,3,13.6l0.7,1.8h2c-1.1-2.2-1.9-5.3-2.7-9.1c-0.4-1.9-0.7-4-1.1-6.3
	c-1.2-8.7-2.1-17.7-2.8-26.9c-0.6-8.1-0.8-16.5-0.9-25c-0.1-5.7,0-11.6,0.1-17.5c0.4-7.9,0.8-15.7,1.3-23.3L307.1,236.5L307.1,236.5
	z"
          />
          <path
            fill="#FFFFFF"
            d="M308.4,302.2c0.7,9.2,1.5,18.2,2.8,26.9c0.4,2.2,0.7,4.4,1.1,6.3c0.7,3.8,1.7,6.8,2.7,9.1l20.7-0.1
	c-10.6-0.5-17-3.5-19.1-9.2c-2.2-10.3-3.8-21.3-4.5-33.1h-3.7L308.4,302.2L308.4,302.2z"
          />
          <path
            fill="#FFFFFF"
            d="M312,302.2c0.7,11.8,2.2,22.8,4.5,33.1c2.2,5.6,8.6,8.6,19.1,9.2l66.4,0.7c3.2-11.8,5.1-26,5.7-42.6
	l-37.3-0.2L312,302.2L312,302.2z"
          />
          <path
            fill="#FFFFFF"
            d="M319.6,349.5l33.5,0.2h1.5l3.9,0.1h2.7l11.9,0.2l108.2,0.8l23.3,0.7l58.3,1.9l4.4-15.5
	c2-7.6,4-14.5,6.1-20.9c3.1-9.1,6.1-16.9,9.4-23.3l4.4-8c0.8-1.4,1.9-2.7,3.1-3.9c5.2-5.3,13.6-8.4,25-9c18.7-0.5,36.8,0.4,54.6,2.5
	c12.8,1.7,21.8,5.1,27,10.2c2.2,2.2,4.4,4.7,6.4,7.4l12.4-10.7l-2.8-1.4c-3.3-1.7-6-3.2-8-4.6l-2.8-2l-3.5-3l-1.3-1.5
	c-0.4-0.6-0.7-1.2-0.9-1.8c-0.7-1.8-0.7-3.5,0.1-5.2c-27.8-3.4-57.5-5.1-89.3-5c-16.1,0.4-26.2,3.4-30.6,9.2
	c4.4-5.7,14.5-8.9,30.6-9.2c31.6-0.1,61.4,1.5,89.3,5c2.4-3.9,9-5.4,19.8-4.6l9.1,0.7c-5.2-4.4-18.3-9-39.2-13.8
	c-9-1.4-18.3-2.7-28-4l-19.2-2.2l-14.2-1.4c-9.7-0.8-19.2-1.2-29-1.2l-9.7,0.4h-28.2c1.5,3.3,2.8,7.4,3.8,12.3l7.9,0.2l-7.9-0.2
	c1.7,7.8,2.8,17.6,3.3,29.4c1.4,20.1,0.9,39-1.5,56.8c0,0.5-0.1,1.1-0.2,1.5c-0.2,1.1-0.5,2.1-0.9,3.1c-0.2,0.6-0.7,1.2-1.1,1.8
	c-2.7,5.7-6.6,8.3-11.9,7.8l-170.7-1.2l-61.9-0.7C317.5,348.5,318.6,349.2,319.6,349.5L319.6,349.5z"
          />
          <path
            fill="#FFFFFF"
            d="M316.7,347.4l61.9,0.7l170.7,1.2c5.3,0.5,9.2-2.1,11.9-7.8c-2.5,3.3-7.1,5.2-13.8,5.8l-145.2-2l-66.4-0.7
	l-20.7,0.1C315.5,345.7,316.1,346.6,316.7,347.4L316.7,347.4z"
          />
          <path
            fill="#656565"
            d="M109.5,331.4c-0.7,2.7-1.2,5.4-1.5,8.4c-0.2,2-0.4,4-0.4,6.1l13.5,3c1.8,0.1,2.8-0.5,3.1-1.9
	c-0.2,0-0.4,0.1-0.6,0.4c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-4,0.7-8,1.5-12l-9.7-2.1
	L109.5,331.4L109.5,331.4z"
          />
          <path
            fill="#FFFFFF"
            d="M53.8,258.1c-1.2,1.9-1.2,3.4,0,4.8l2.4,2.8c0.2-4.7,0.7-9.6,1.4-14.4L53.8,258.1L53.8,258.1z"
          />
          <path fill="#056DA5" d="M70.1,281.2h-0.7l0.7,0.1" />
          <path
            fill="#FFFFFF"
            d="M68.4,300.6h0.2c0.2,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.2,0.6c0,14.9,1.7,26.4,5.1,34.7
	c0.1,0.2,0.1,0.4,0,0.7c-0.1,0.2-0.2,0.4-0.5,0.5h-0.5v0.2l4.7,0.7l-2.2-3.5l-0.4-1.8c-3.3-14.8-4.6-26.1-3.9-34.1l-8.5-0.4v2.2
	L68.4,300.6L68.4,300.6z"
          />
          <path
            fill="#FFFFFF"
            d="M63.4,300.6h-9.1c-2-0.2-3.5,0.8-4.7,3.4v26.9l8.1,3.5l2.2,0.9l9.6,1.2l4,0.5h-0.1c-0.2-0.1-0.4-0.2-0.5-0.5
	c-3.4-8.4-5.2-20.2-5.2-35.3c0-0.2,0.1-0.5,0.2-0.6l0.4-0.2L63.4,300.6L63.4,300.6z"
          />
          <path
            fill="#FFFFFF"
            d="M68.4,300.6l-0.4,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,15.1,1.8,26.9,5.2,35.3c0.1,0.2,0.2,0.4,0.5,0.5H74
	c0.2-0.1,0.4-0.2,0.5-0.5c0.1-0.2,0.1-0.5,0-0.7c-3.4-8.3-5.1-19.8-5.1-34.7c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.1-0.4-0.2-0.6-0.2h-0.2
	L68.4,300.6L68.4,300.6z M72.3,295.6l-13.7-0.1l2.2,2.7h2.6l8.5,0.4"
          />
          <path
            fill="#FFFFFF"
            d="M60.8,298.2v2.4h2.6v-2.4 M57.2,338.1l0.6-3.5l-8.1-3.5v3.7"
          />
          <path
            fill="#FFFFFF"
            d="M57.2,338.1l5.2,0.7l11.5,1.8l-0.2-3.2l-3.9-0.7l-9.7-1.2l-2.2-0.9"
          />
          <path fill="#056DA5" d="M69.7,336.7l3.9,0.7v-0.2" />
          <path
            fill="#EBEBEB"
            d="M756.5,261.2c-0.2,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.5v28c0.7,5.4,2.1,9.9,4.3,13.7l0.2,0.2l2.5,1.6l0.7,0.1
	l0.2-0.1l0.2-0.2c0.1-0.2,0.1-0.5,0.1-0.7s-0.2-0.4-0.4-0.5l-2.4-1.5c-1.7-3.1-3-6.6-3.7-10.9l-0.2-1.9v-27.9c0-0.2-0.1-0.5-0.2-0.6
	C757,261.2,756.8,261.2,756.5,261.2L756.5,261.2z"
          />
          <path
            fill="#FFFFFF"
            d="M754,255.6l-9.7-5.9l-0.7,13.7c3,0.9,4.7,2.6,5.2,5.1v10.2c0.1,2.1-0.6,3.3-2,3.7h-2.1h2.1
	c1.4-0.4,2-1.5,2-3.7v-10.2c-0.5-2.5-2.2-4.1-5.2-5.1l-0.1,1.2c0.8,0.5,1.4,1.1,1.9,1.7l0.4,0.5c0.5,0.7,0.7,1.7,0.7,2.6v10
	c0.1,1.2-0.6,1.9-1.9,2l-2.4,7l4.4,0.5c2.6,0.9,4.6,2.4,6,4.2l0.2,0.8l2.4,7.6c-0.1,1.2-1.9,1.8-5.5,1.9l5.8,11.8l6.6-6.3l1.4-3.5
	l-0.2,0.1l-0.7-0.1l-2.5-1.7l-0.2-0.2c-2.1-3.7-3.5-8.3-4.2-13.7v-30.5L754,255.6L754,255.6z"
          />
          <path
            fill="#656565"
            d="M757.3,267.5v21.6c2.4,0.1,3.5-0.6,3.4-2l-1.7-17.7C758.9,268.2,758.3,267.6,757.3,267.5L757.3,267.5z"
          />
          <path
            fill="#FFFFFF"
            d="M757.7,291.6c0.7,4.1,2,7.8,3.7,10.9l-0.7-8.3C760.5,292.2,759.6,291.4,757.7,291.6L757.7,291.6z M744,249.5
	c-30.9-8.3-72.1-13.5-123.9-15.9c-16.6-0.7-28.1,0.4-34.2,3.1l9.7-0.4c9.8,0,19.5,0.4,29,1.2l14.2,1.4l19.2,2.2c9.7,1.2,19,2.6,28,4
	c12.4,2,24,4.1,34.8,6.5c9.9,2.8,17.5,6.7,22.7,11.7l0.7-13.7L744,249.5L744,249.5z"
          />
          <path
            fill="#FFFFFF"
            d="M725.9,260.3c5.4,0.7,10.6,1.8,15.6,3.4c0.7,0.2,1.4,0.5,2,0.8l0.1-1.2c-4-2-10-3.5-18.2-4.4L725.9,260.3
	L725.9,260.3z"
          />
          <path
            fill="#FFFFFF"
            d="M725.5,258.9l-9.1-0.7c-10.9-0.8-17.5,0.7-19.8,4.6c-0.8,1.7-0.8,3.4-0.1,5.2c0.2,0.7,0.6,1.3,0.9,1.8
	l1.3,1.5l3.5,3l2.8,2c2,1.4,4.7,3,8,4.6l2.8,1.4l2-1.7l-13.2-7.1c-0.1-0.1-0.1-0.1-0.2-0.1l-2.6-1.8l-1.4-1.2
	c-0.7-0.7-1.2-1.5-1.4-2.5c-0.1-0.5-0.2-0.9-0.1-1.4v-0.1l0.1-1.5c0.2-0.8,0.7-1.5,1.3-2.1c2.5-2.4,7.8-3.4,16.2-3.1l9.7,0.6
	L725.5,258.9L725.5,258.9z"
          />
          <path
            fill="#FFFFFF"
            d="M717.8,280.5l-2,1.7l5.5,2.6c7.3,3.9,10.6,10,10.2,18.2l-0.9,15.1c-0.6,5.3-3,9.1-7.3,11.6l-14.3,6.7
	l1.4,4.4l22,8.4h3.4l17.4-9.2l-9.7-4.5c-4.5-2.4-6.6-5.3-6.5-9.1V297c0.5-3.1,1.8-4.7,3.9-5.2h6.5c2.5,0.2,4.4,0.8,5.5,1.9l-0.2-0.8
	c-1.4-1.9-3.4-3.3-6-4.2l-4.4-0.5l-2.8-0.4l-3.9-2.4l-2.6-0.5l-2.8-0.6c-3.5-0.8-6.8-1.9-10-3.1
	C719.3,281.2,718.5,280.9,717.8,280.5L717.8,280.5z"
          />
          <path
            fill="#FFFFFF"
            d="M735.5,285.7l3.9,2.4l2.8,0.4l2.4-7.1H739 M747.3,292.1h-6.5c-2,0.4-3.3,2.1-3.9,5.2v29.3
	c-0.1,3.7,2,6.7,6.5,9.1l9.7,4.5l3.4-9.7l-3.8-2.6l-5.3-3l-3.4-1.5c-2.1-1.2-3.1-2.8-3.1-5.1v-15.2c0.1-0.9,1.3-1.4,3.4-1.4h10.7
	l-2.4-7.6C751.7,292.9,749.9,292.3,747.3,292.1L747.3,292.1z"
          />
          <path
            fill="#FFFFFF"
            d="M749.7,303.5c3.7-0.1,5.5-0.8,5.5-1.9h-10.7c-2.2-0.1-3.3,0.4-3.4,1.4l6.6,0.5L749.7,303.5L749.7,303.5z"
          />
          <path
            fill="#FFFFFF"
            d="M747.8,303.4c1.2,1.5,2,3.4,2.6,5.5v0.1l0.2,5.8l4.8,0.4l-5.8-11.8L747.8,303.4L747.8,303.4L747.8,303.4z"
          />
          <path
            fill="#FFFFFF"
            d="M741.2,303v15.2c-0.1,2.2,0.9,3.9,3.1,5.1l3.4,1.5l0.7,0.2l0.7-1.8l-1.7-0.6c-2.2-0.2-3.7-2-4.2-5.2v-10.9
	l4.7,0.2c0.4,0.7,0.7,1.5,0.9,2.6l0.4,8.7l-1.9,4.6l1.7,0.6l1.9-4.8v-0.4l-0.1-3.1l-0.2-5.8v-0.1c-0.6-2.1-1.4-4-2.6-5.5L741.2,303
	L741.2,303z M721.3,284.9l-5.5-2.6L703.4,293c6.6,9.1,11.5,20.7,14.3,34.6c0.2,0.6,0.2,1.1,0.2,1.5c0,1.3-0.8,2.2-2.5,2.8l-7.2,2.8
	l0.6,1.7l14.3-6.7c4.4-2.4,6.7-6.3,7.3-11.6l0.9-15.1C732,294.9,728.7,288.8,721.3,284.9L721.3,284.9z"
          />
          <path
            fill="#FFFFFF"
            d="M697,285.6c-5.3-5.2-14.3-8.5-27-10.2c-17.7-2.1-35.9-3-54.6-2.5c-11.6,0.7-19.8,3.7-25,9
	c-1.2,1.2-2.2,2.5-3.1,3.9l-4.4,8c-3.2,6.5-6.4,14.3-9.4,23.3c-2.1,6.4-4.1,13.3-6.1,20.9l-4.4,15.5l6.3,0.2c0.9-4.1,2-8.1,3-12
	c2.4-9,5-17.1,7.6-24.4c1.7-4.6,3.4-8.9,5.2-12.9c5.2-11,11.2-18.1,17.9-21.3c6.3-2.7,19.2-3.8,38.7-3.1c21.5,1.1,36.5,3.3,45.1,6.5
	c8.9,3.8,16.9,14.3,23.9,31.3c2.1,5.8,4.6,9.6,7.3,11.2c0-0.5,0-1.1-0.2-1.5c-3-13.9-7.7-25.4-14.3-34.6
	C701.5,290.3,699.4,287.8,697,285.6L697,285.6z M743.7,263.4c-5.2-5-12.8-8.9-22.7-11.7c-10.9-2.4-22.4-4.6-34.8-6.5
	c21,4.8,34.1,9.4,39.2,13.8C733.5,259.9,739.6,261.4,743.7,263.4L743.7,263.4z M710.3,341.9l-0.7,16.8c-0.1,5.7,1.1,9.3,3.3,10.9
	l9.7,9.4c4-3,5.5-5.7,4.5-8.1l7.1-16.9c-1.5-2.5-2.1-4-1.8-4.7l-22-8.4L710.3,341.9L710.3,341.9z"
          />
          <path
            fill="#FFFFFF"
            d="M715.5,332c1.5-0.6,2.4-1.5,2.5-2.8c-2.7-1.7-5.2-5.3-7.3-11.2c-7-17-15-27.4-23.9-31.3
	c-8.6-3.3-23.7-5.4-45.1-6.5c-19.6-0.7-32.5,0.4-38.7,3.1c-6.7,3.2-12.8,10.3-17.9,21.3c-1.8,4-3.5,8.3-5.2,12.9
	c-2.7,7.4-5.2,15.6-7.6,24.4c-1.1,3.9-2,7.9-3,12l9.1,0.2c1.1-4.3,2-8.4,3.1-12.4l0.1-0.4c2.5-9,5.1-17,7.8-24.1
	c1.3-3.3,2.6-6.4,3.9-9.2c5.5-12.6,16.6-18.9,33.3-18.9h0.8c15.6-0.4,31.8,0.7,48.6,3.4c9.4,2,16.8,7.7,22,17.2
	c3.4,6.4,7,14.8,10.5,25L715.5,332L715.5,332z M747.8,306.7l-4.7-0.2v10.9c0.6,3.3,2,5,4.3,5.2l1.9-4.6l-0.4-8.7
	C748.5,308.3,748.1,307.4,747.8,306.7L747.8,306.7z M755.6,322.4l-6.7,0.8l-0.6,1.8l-0.7-0.2l5.3,2.8"
          />
          <path
            fill="#FFFFFF"
            d="M735.9,349.1h6.7l14.8-8.5l7-15.7c-2.5-1.1-5.4-2-8.6-2.6l-2.6,5.3l3.8,2.6l-3.4,9.7L735.9,349.1
	L735.9,349.1z"
          />
          <path
            fill="#FFFFFF"
            d="M735.9,349.1l-0.1,2.4l8.4,0.1l13.7-7.9c3.1-1.5,4.8-3,5.2-4.2l5.2-12.6l-0.7-0.2c-1.1-0.6-2.1-1.2-3.3-1.7
	l-7,15.7l-14.8,8.5L735.9,349.1L735.9,349.1z"
          />
          <path
            fill="#FFFFFF"
            d="M732.4,349.1c-0.4,0.7,0.2,2.2,1.8,4.7l3,0.2l-1.4-2.6l0.1-2.4l-0.5,0.2l0.5-0.2H732.4L732.4,349.1z"
          />
          <path
            fill="#FFFFFF"
            d="M747.7,353.3l15.7-10c2.5-1.2,3.9-2.7,4.1-4.7l1.7-8.7c0.4-1.3,0.1-2.2-0.9-2.8l-5.2,12.6
	c-0.4,1.3-2.1,2.6-5.2,4.2l-13.7,7.9l-8.4-0.1l1.4,2.6C741.9,354.4,745.4,354.2,747.7,353.3L747.7,353.3z M398.6,173.9l7,70.7
	l156.1,4.4c-1.1-4.8-2.4-9-3.8-12.3h-8.5h8.5c-2.6-3.5-5.4-7-8.4-10.3c-8.6-9.8-18.7-18.7-30.1-26.7c-4.8-3.4-10-6.6-15.4-9.7
	l-0.8-0.5c-6.4-3.3-13.7-6-21.8-8.1c-9.4-1.9-18.7-3.4-27.5-4.5c-18.3-2-36.6-3-54.7-3.1L398.6,173.9L398.6,173.9z M410.6,181.6
	c0.1-0.5,0.2-0.8,0.6-1.2c0.8-0.9,2.2-1.5,4.4-1.5l23.4,0.9c14.8,1.1,28.9,3.3,42.4,6.6c8.1,2.5,15.5,5.6,21.8,9.2l1.2,0.6
	c1.9,1.2,3.8,2.4,5.7,3.5c7.8,5.1,14.9,10.6,21.3,16.6c3.8,1.3,7.2,2.9,10.3,4.8c2.2,1.3,3.2,3.4,3,6.6c-0.1,0.8-0.4,1.7-0.6,2.4
	c1.2,1.5,2.2,3,3.3,4.5c0.9,0.9,1.5,1.9,1.5,2.8c0.1,0.9-0.4,1.9-1.3,2.8c-1.5,1.4-4.7,2.7-9.7,3.9h-0.2l-114.3-3.2
	c-1.4,0-2.7-0.6-3.9-1.8c-1.5-1.5-2.6-4-3.4-7.4v-0.1l-5.5-38.8C409.6,187.6,409.6,183.8,410.6,181.6z"
          />
          <path
            fill="#FFFFFF"
            d="M410.4,192.9l5.5,38.8v0.1c0.8,3.4,1.9,5.9,3.4,7.4c1.2,1.2,2.5,1.8,3.9,1.8l114.3,3.2h0.2
	c4.8-1.2,8.1-2.5,9.7-3.9c0.9-0.9,1.4-1.8,1.3-2.8c0-0.9-0.6-1.9-1.5-2.8c-1.1-1.5-2.2-3.1-3.3-4.5c-0.2,0.6-0.5,1.1-0.8,1.7
	l2.8,3.9c0,0,0.1,0,0.1,0.1c0.7,0.6,1.1,1.2,1.1,1.8c0,0.5-0.2,0.9-0.7,1.5c-1.4,1.2-4.1,2.4-8.3,3.3l-0.5,0.1l-2.7-0.1l-0.7,0.1
	l-0.4-0.1l-4.4-0.1l-105.9-3c-2.7-0.2-4.7-3-5.8-7.9l-5.5-38.8c-0.8-5.2-0.7-8.9,0.2-10.7c0.7-0.9,1.7-1.4,3-1.4l23.5,0.9
	c14.9,1.1,29,3.3,42.6,6.7c8.3,2.5,15.5,5.7,21.8,9.3l0.4,0.2c1.2,0.6,2.2,1.3,3.4,2c6.1,3.8,11.9,8,17.1,12.5l3.4,3.1h0.2l3.5,1.1
	c-6.4-6.1-13.5-11.7-21.3-16.6l-5.7-3.5l-1.2-0.6c-6.4-3.7-13.7-6.7-21.8-9.2c-13.5-3.4-27.6-5.7-42.4-6.6l-23.4-0.9
	c-2.1,0.1-3.5,0.6-4.4,1.5c-0.2,0.4-0.5,0.7-0.6,1.2C409.6,183.8,409.6,187.6,410.4,192.9L410.4,192.9z"
          />
          <path
            fill="#FFFFFF"
            d="M527.7,215.3h-0.1l-1.9-0.4l-0.8,0.2c-2,0.8-3.7,3.3-4.8,7.3l-1.5,7.6h0.7c-0.4-1.9,0-3,1.1-3.1
	c4.6-0.5,9.1,0.2,13.5,2.1c2.5,1.2,3,2.2,1.5,3.2c1.9,0.5,3.7,0.9,5.4,1.5c-1.8-0.6-3.5-1.2-5.4-1.5c-4.8-1.2-10.2-1.9-16.1-2.2
	h-0.7l-2-0.1c0,4.3,1.3,7.2,4.1,8.9l0.9,0.4c2,0.6,4,0.7,6,0.6s4.1-0.6,6.1-1.4c1.7-0.7,3.4-1.5,5.1-2.7c1.8-0.9,3.1-2.2,4-3.7
	c0.4-0.6,0.6-1.1,0.8-1.7c0.2-0.7,0.5-1.5,0.6-2.4c0.2-3.2-0.7-5.3-3-6.6c-3.1-2-6.5-3.5-10.3-4.8
	C530,215.9,528.9,215.6,527.7,215.3L527.7,215.3z"
          />
          <path
            fill="#FFFFFF"
            d="M520,217c-1.5,2.6-2.7,6.6-3.4,11.9v0.9l2,0.1l1.5-7.6c1.2-4,2.8-6.4,4.8-7.3l-0.1-0.4
	C522.8,214.8,521.2,215.5,520,217L520,217z"
          />
          <path fill="#056DA5" d="M525.7,214.9l-0.9-0.1l0.1,0.4" />
          <path
            fill="#FFFFFF"
            d="M527.6,215.2l-3.5-3.1l0.7,2.6l0.9,0.1 M520.2,226.9c-1.1,0.1-1.4,1.2-1.1,3.1c5.8,0.2,11.1,1.1,16.1,2.2
	c1.5-0.8,0.9-1.9-1.5-3.2C529.3,227.1,524.9,226.4,520.2,226.9L520.2,226.9z M529.3,240.5v1.9l4.4,0.1"
          />
          <path fill="#056DA5" d="M534.8,242.5h-1.1l0.4,0.1" />
          <path
            fill="#FFFFFF"
            d="M533.7,242.5h0.9l7-1.4l-7.8-2.8c-2.1,0.8-4.1,1.3-6.1,1.4l1.7,0.7L533.7,242.5L533.7,242.5z"
          />
          <path
            fill="#FFFFFF"
            d="M546.9,237.7c0-0.6-0.4-1.2-1.1-1.8l-0.1-0.1l-2.8-3.9c-0.9,1.4-2.2,2.7-4,3.7c-1.7,1.2-3.4,2-5.1,2.7
	l7.8,2.8l-7,1.4l2.7,0.1l0.5-0.1c4.1-0.9,6.8-2.1,8.3-3.3C546.8,238.6,547.1,238.1,546.9,237.7L546.9,237.7z M407.7,302.6l113.1,0.7
	c12-0.5,20.4,3.1,25,10.5l16.3,25.9c0.5-0.9,0.7-2,0.9-3.1c0.1-0.5,0.1-0.9,0.2-1.5c2.4-17.8,2.8-36.8,1.5-56.8
	c-0.5-11.8-1.5-21.6-3.3-29.4l-156.1-4.4C407.6,266.4,408.4,285.7,407.7,302.6L407.7,302.6z M426.1,255.8h5.3c3,0.1,5.2,0.9,7,2.5
	c6.4,0.7,9.8,1.9,10.3,3.5l0.1,0.2c0.2,0.9,0.1,1.8-0.6,2.5l-0.6,0.5c-2.2,1.2-5.5,1.9-9.8,1.9c-1.7,2-4.1,3-7.4,2.7H427
	c-3.9-0.2-6.4-1.3-7.2-3.1l-2.4-0.2c-2-0.2-3.8-0.7-5.4-1.3c-1.1-0.4-1.5-0.9-1.7-1.9v-1.4c0.1-1.1,0.6-1.9,1.7-2.5
	c1.7-0.6,3.5-0.9,5.5-1.1c0.9-0.1,1.8-0.1,2.7,0l0.2,0.2C421.3,256.9,423.1,256.1,426.1,255.8L426.1,255.8z"
          />
          <path
            fill="#FFFFFF"
            d="M425.7,260.8h3.8c8.3,0,14.8,0.4,19.4,1.3l-0.1-0.2c-0.6-1.5-4-2.7-10.3-3.5c-0.6,1.1-1.8,1.4-3.8,1.3h-10.2
	c-1.4-0.1-2.7-0.5-3.9-1.2C422.2,259.9,423.9,260.7,425.7,260.8L425.7,260.8z"
          />
          <path
            fill="#FFFFFF"
            d="M420.5,258.4l-0.2-0.2h-2.7v8.1l2.4,0.2c1.3-2.1,3-3.3,5.1-3.4h3.2c8.3,0.1,15,0.5,20.2,1.3
	c0.7-0.7,0.8-1.5,0.6-2.5c-4.6-0.9-11.1-1.3-19.4-1.3h-3.8C423.9,260.7,422.2,259.9,420.5,258.4L420.5,258.4z"
          />
          <path
            fill="#FFFFFF"
            d="M420.5,258.4c1.2,0.7,2.5,1.2,3.9,1.2h10.2c2,0.1,3.3-0.4,3.8-1.3c-1.8-1.7-4.1-2.5-7-2.5H426
	C423.1,256.1,421.3,256.9,420.5,258.4L420.5,258.4z"
          />
          <path fill="#056DA5" d="M420.5,258.4l-0.2-0.1" />
          <path
            fill="#FFFFFF"
            d="M412,259.4c-0.9,0.6-1.5,1.4-1.7,2.5v1.4c0,0.9,0.6,1.5,1.7,1.9c1.7,0.6,3.4,0.9,5.4,1.3v-8.1
	C415.5,258.4,413.7,258.8,412,259.4L412,259.4z M415.6,260.1c0.4,0.4,0.5,0.7,0.5,1.2s-0.2,0.9-0.5,1.2c-0.4,0.4-0.7,0.5-1.2,0.5
	s-0.8-0.2-1.2-0.5c-0.4-0.4-0.5-0.7-0.5-1.2s0.1-0.8,0.5-1.2c0.4-0.4,0.7-0.5,1.2-0.5S415.4,259.7,415.6,260.1L415.6,260.1z"
          />
          <path
            fill="#FFFFFF"
            d="M414.5,259.6c-0.5,0-0.8,0.1-1.2,0.5c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.9,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5
	s0.9-0.2,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2s-0.2-0.8-0.5-1.2C415.4,259.7,414.9,259.6,414.5,259.6L414.5,259.6z M430.5,269.7
	c3.4,0.2,5.9-0.7,7.4-2.7c-0.8-1.5-2.4-2.2-4.5-2.1h-8.4c-2.7,0.1-4.5,0.7-5.2,1.9c0.9,1.8,3.3,2.7,7.2,3.1h3.4L430.5,269.7
	L430.5,269.7z"
          />
          <path
            fill="#FFFFFF"
            d="M438,266.9c4.4,0,7.7-0.7,9.8-1.9l0.6-0.5c-5.2-0.8-11.9-1.2-20.2-1.3H425c-2,0.1-3.8,1.3-5.1,3.4
	c0.7-1.2,2.5-1.8,5.2-1.9h8.4C435.6,264.7,437,265.4,438,266.9L438,266.9z M407.7,302.6c-0.6,16.5-2.5,30.8-5.7,42.6l145.2,2
	c6.7-0.5,11.3-2.5,13.8-5.8c0.4-0.5,0.7-1.1,1.1-1.8l-16.3-25.9c-4.6-7.4-12.9-11-25-10.5L407.7,302.6L407.7,302.6z M101.3,296.7
	H73.8v0.1c-1.1,7.9,0.1,19.7,3.5,35.3l0.1,0.7l0.5,1.9l42.7,3.8l-12.8,1.2c0.4-3,0.8-5.7,1.5-8.4c-2.2-0.7-4.2-2.1-5.8-4.3l12-23.7
	C113,298.9,108.3,296.8,101.3,296.7L101.3,296.7z"
          />
          <path
            fill="#FFFFFF"
            d="M58.6,245.8l-0.9,5.4c-0.7,4.8-1.2,9.7-1.4,14.4c-0.5,10.3,0.4,20.2,2.4,29.9c-0.5-4.5-0.7-8.9-0.9-13
	l-0.1-3.1l-0.1-3.3c0-3.3,0-6.5,0.1-9.6c0.2-5.2,0.6-10,1.3-14.5l0.9-5.2l18.2,7.1c2.4,1.5,3.1,3.5,2.1,6.1
	c-3.4,9.7-6,21.6-7.8,35.7l-0.4,3c-0.7,8,0.6,19.4,3.9,34.1l0.4,1.8l1.8,0.1l-0.5-1.9l-0.1-0.7c-3.4-15.6-4.7-27.4-3.5-35.3v-0.1
	h-1.2h1.2c1.7-12.9,3.9-23.9,6.8-33.1l1.5-4.4c1.2-3.1,0.2-5.3-2.9-6.8l-23.5-12.6L58.6,245.8L58.6,245.8z"
          />
          <path
            fill="#FFFFFF"
            d="M120.7,343.2l-13.1,2.7c0-2.1,0.2-4.1,0.4-6.1l12.8-1.2l-42.7-3.9l-1.8-0.1l2.2,3.5l6,0.7l31.9,3.9
	L120.7,343.2L120.7,343.2z M318,169.2c-1.2,1.4-2,3.1-2.5,5l0.9,1.3c0.7-2.8,2-5.1,3.8-6.6L318,169.2L318,169.2z"
          />
          <path
            fill="#FFFFFF"
            d="M319.9,173.1c1.1-0.9,2.2-1.8,3.7-2.5c2.2-1.1,4.8-1.7,7.8-1.9l9.7-0.9l34.4-1.2l5.2-0.1l-5.2,0.1l19.7,0.5
	c34.7,0.7,63.4,3.9,86.2,9.7l5.1,1.5l9.1,3.5l3.9,1.9l1.4-1.7c-1.1-0.6-2.2-1.2-3.4-1.8l-4.7-1.9c-3.5-1.4-7.3-2.7-11.3-3.8
	c-15.9-4-33.3-6.7-52.2-8.1l-42.6-1.7l-17-0.4l-19.5,0.2l-13.7,0.2l-6.9,0.9l-3.4,0.4c-2.2,0.5-4.1,1.3-5.7,2.7
	c-1.8,1.7-3.1,3.8-3.8,6.6l-0.5,1.8l-0.6,2.4C316.6,177,318,174.8,319.9,173.1L319.9,173.1z"
          />
          <path
            fill="#FFFFFF"
            d="M315.5,179.7l-0.4,1.4c-1.1,6.6-2,13.5-2.8,20.4c-1.2,10.3-2.2,21-3.1,32.1l-0.1,1.4l-0.1,1.5
	c-0.6,7.6-1.1,15.4-1.3,23.3c-0.1,5.9-0.2,11.8-0.1,17.5c0.1,8.5,0.4,16.9,0.9,25h3.5c-0.4-6.6-0.6-13.5-0.5-20.5
	c-0.4-36.5,1.7-67.3,5.8-92.5c2-9.7,7.4-14.3,16.1-14.2l-4.2-0.1c9-0.5,22.3-0.7,40.1-0.9l29.2-0.1h0.6c18.1,0.1,36.4,1.2,54.7,3.1
	c8.9,1.1,17.9,2.5,27.5,4.5c8.3,2.1,15.6,4.8,21.8,8.1l0.8,0.5c5.3,3.1,10.5,6.4,15.4,9.7c11.5,8,21.5,16.9,30.1,26.7
	c3,3.3,5.7,6.7,8.4,10.3h8.5c-3.3-3.5-6.6-7-10-10.3c-10.4-10.2-20.8-19.1-31.2-26.7c-7.2-5.2-14.5-9.9-21.8-13.8l-3.9-2.1l-3.9-1.9
	l-9.1-3.5l-5.1-1.5c-22.8-5.7-51.5-8.9-86.2-9.7l-19.7-0.5L341,168l-9.7,0.9c-3,0.1-5.5,0.7-7.8,1.9c-1.3,0.7-2.6,1.4-3.7,2.5
	C318,174.8,316.6,177,315.5,179.7L315.5,179.7z"
          />
          <path
            fill="#FFFFFF"
            d="M316.1,177.3c-0.8,0.2-2.1,5-3.9,14.3s-3.4,23.7-5.1,43.3h2l0.1-1.4c0.8-11.1,1.9-21.8,3.1-32.1
	c0.8-7,1.8-13.8,2.8-20.4l0.4-1.4L316.1,177.3L316.1,177.3z"
          />
          <path fill="#FFFFFF" d="M56.3,234.9l-0.2,1.5H309l0.1-1.5h-2" />
          <path
            fill="#656565"
            d="M166.8,285.8c-5.7-0.2-10.5,0.5-14.5,2l-3.4,1.7c-0.8,0.5-1.7,1.2-2.5,1.8c-1.3,1.2-2.4,2.4-3.3,3.8l-2,3
	l-2,3c-1.1,1.7-2,3.4-3.1,5.3l-0.1,0.2l-2.7,5.7c-1.7,3.8-3.2,7.9-4.7,12.3l18.3,0.9l1.9-1.9c11.3-11.3,24.9-17,41-17
	s29.6,5.7,41,17c2.8,2.8,5.2,5.7,7.3,8.7l13.5-8.1c-3-7.4-6.1-13.9-9.6-19.4c-5.7-7.4-12.2-12.2-19.7-14.3l-1.8-0.5
	C202.9,287.1,185,285.6,166.8,285.8L166.8,285.8z M243.2,342.7l11.8-6.8c-1.2-4.1-2.5-7.9-3.9-11.6l-13.5,8.1
	C239.9,335.7,241.8,339.1,243.2,342.7L243.2,342.7z"
          />
          <path
            fill="#656565"
            d="M237.7,332.5c-2.1-3.1-4.5-6-7.3-8.7c-11.3-11.3-25-17-41-17s-29.6,5.7-41,17l-1.9,1.9
	c-2.5,2.7-4.7,5.7-6.6,8.6c-4.7,7.6-7.6,15.8-8.3,25c-0.1,1.8-0.2,3.7-0.2,5.4c0,16.1,5.7,29.6,17,41c11.3,11.3,24.9,17,41,17
	c16.1,0,29.6-5.7,41-17c10.9-10.9,16.5-23.7,17-38.8v-4.7c0-1.2-0.1-2.2-0.2-3.4l-0.4-3.3c-0.1-1.1-0.4-2.1-0.6-3.1l-0.1-0.6
	c-0.4-1.8-0.8-3.5-1.4-5.3c-0.4-1.3-0.8-2.5-1.3-3.7C241.8,339.1,239.9,335.7,237.7,332.5L237.7,332.5z M215.3,390.4
	c-3.1,3.1-6.5,5.5-10.2,7.3c-1.7,0.8-3.4,1.5-5.2,2l-1.3,0.4c-2.9,0.7-6,1.1-9.2,1.1c-10,0-18.7-3.5-25.9-10.7
	c-1.8-1.8-3.3-3.7-4.7-5.5c-4-5.9-6-12.6-6-20.2c0-2.5,0.2-4.7,0.6-7c0.6-3.1,1.5-6,2.8-8.9c1.8-3.5,4.1-7,7.2-10
	c4-4,8.5-6.8,13.5-8.6c3-1.1,5.9-1.7,9.1-1.9l3.2-0.1c8.9,0,16.6,2.8,23.3,8.4c0.9,0.7,1.8,1.5,2.6,2.4c7.1,7.2,10.6,15.8,10.6,25.9
	C226,374.7,222.5,383.4,215.3,390.4L215.3,390.4z"
          />
          <path
            fill="#656565"
            d="M244.7,346.4c0.6,1.8,1.1,3.5,1.4,5.3l1.1,0.6c2.2,1.1,4.6,2.1,7.1,3.1c2.4,0.9,4.7,1.8,14.3,2.5l91.6-3.5
	l-7.1-4.6l-33.5-0.2c-1.1-0.2-2.1-0.9-3-2.1c-0.6-0.7-1.1-1.7-1.7-2.8h-2c0.6,2.2,0.4,3.8-0.7,4.4c-0.6,0.4-1.5,0.5-2.7,0.2
	l-50.2,1.4l-1.2-9.9l-1.7,0.5l-1.5-5.2l-11.8,6.8C243.8,343.9,244.2,345.1,244.7,346.4L244.7,346.4z"
          />
          <path
            fill="#656565"
            d="M254.2,355.4h-7.4l0.4,3.3l14.3-0.8C258.9,357,256.6,356.2,254.2,355.4L254.2,355.4z M308.8,360.7l-61.4,1.3
	v4.7l75.3-3.5c-3.5-0.5-7.1-1.1-10.4-1.8L308.8,360.7L308.8,360.7z"
          />
          <path
            fill="#656565"
            d="M312.3,361.4c3.3,0.7,6.7,1.3,10.4,1.8l3.3,0.5c6.9,0.8,7.1,1.5,14.9,1.8l12.2,0.1v-7.7l-44.4,2.7
	L312.3,361.4L312.3,361.4z M246.1,351.7l0.1,0.6c0.2,1.1,0.4,2,0.6,3.1h7.6c-2.5-0.9-4.8-2-7.1-3.1L246.1,351.7L246.1,351.7z"
          />
          <path
            fill="#656565"
            d="M247.4,362l61.4-1.3l44.4-2.7h1.2l2.4-0.1l0.1-0.5c0.2-1.1-0.1-2.1-0.9-3.2l-2,0.1h6.4l-91.6,3.4l-21.4,0.8
	C247.3,359.8,247.4,360.9,247.4,362L247.4,362z"
          />
          <path
            fill="#656565"
            d="M373.1,350.2l-11.9-0.2l0.1,0.8c0.1,0.8,0.2,1.7,0.2,2.6c0,1.8-0.2,3.3-0.7,4.6c-0.4,0.9-0.8,1.5-1.3,1.8
	l-0.4,0.1l-1.8,0.8c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-0.7,0-1.4-0.7-1.9-1.9l-0.4-1.1h-1.2v7.7h1.4c4-4,10-6.1,18.1-6.1v-9.3
	L373.1,350.2L373.1,350.2L373.1,350.2z"
          />
          <path
            fill="#656565"
            d="M372.5,359.5v6.1h105.2v-14.1v8.3l0.4-0.1l-0.4,0.1v0.8l3.5-0.9l18.2-6.3l5.1-1.8l-23.3-0.7l-108.2-0.8h-0.6
	 M358.5,349.8l0.1,0.2l0.2,0.6c0.2,1.1,0.5,2.2,0.5,3.5c0,1.8-0.2,3.4-0.7,4.7l-0.4,0.6c-0.2,0.5-0.5,0.8-0.8,1.1l1.8-0.8l0.4-0.1
	c0.5-0.2,0.9-0.8,1.3-1.8c0.5-1.3,0.7-2.8,0.7-4.6c0-0.9-0.1-1.8-0.2-2.6l-0.1-0.8L358.5,349.8L358.5,349.8z"
          />
          <path
            fill="#656565"
            d="M354.6,349.8l-0.2,0.9c-0.2,1.1-0.5,2.2-0.5,3.5l2-0.1c0.9,1.1,1.2,2.1,0.9,3.2l-0.1,0.5l-2.4,0.1l0.4,1.1
	c0.6,1.3,1.2,1.9,1.9,1.9h0.1c0.2,0,0.5-0.1,0.7-0.2c0.2-0.2,0.6-0.6,0.8-1.1l0.4-0.6c0.5-1.3,0.7-2.8,0.7-4.7
	c0-1.3-0.1-2.5-0.5-3.5l-0.2-0.6l-0.1-0.2L354.6,349.8L354.6,349.8z M354.2,350.7l0.2-0.9h-1.5v4.6h0.7
	C353.9,352.9,354,351.7,354.2,350.7L354.2,350.7z M372.5,359.5c-8,0-14,2.1-18.1,6.1h18.1V359.5z M127.8,326.2
	c-1.5,4.4-2.7,8.9-3.3,13.3l15.5-5.4c1.9-3,4.1-5.9,6.6-8.6l-18.3-0.9L127.8,326.2L127.8,326.2z M124.6,359.2h-23.3
	c0.2,1.7,2.2,2.6,6.1,2.8h11.3C121.9,361.9,123.9,360.9,124.6,359.2L124.6,359.2z M49.6,341.8c-0.1,1.7,0.9,2.7,3.3,3.1h10.5l-0.9-6
	l-5.3-0.8l-7.4-3.3v7.1L49.6,341.8L49.6,341.8z M97.3,343.2l18.9-0.6l-31.9-3.9C88.6,341.7,92.8,343.1,97.3,343.2z M708.9,336.5
	l-0.6-1.7c-3.4-10.3-7-18.7-10.5-25c-5.2-9.4-12.5-15.2-22-17.2c-16.9-2.6-33.1-3.8-48.6-3.4h-0.8c-16.6,0-27.7,6.3-33.3,18.9
	c-1.3,2.8-2.7,5.9-3.9,9.2c-2.8,7.1-5.4,15.1-7.8,24.1l11.6,0.1l1.3-2.7c2.7-5.4,6.5-10.5,11.1-15.1c11.3-11.3,24.9-17,41-17
	c16.1,0,29.6,5.7,41,17c5.5,5.5,9.7,11.6,12.5,18.2l10.5,0.1l0.1-1.1L708.9,336.5L708.9,336.5z"
          />
          <path
            fill="#656565"
            d="M687.3,323.6c-11.3-11.3-25-17-41-17c-16.1,0-29.6,5.7-41,17c-4.6,4.6-8.4,9.7-11.1,15.1l-1.3,2.7l-0.1,0.2
	c-1.8,4.1-3.1,8.4-3.8,13l-0.4,3c-0.1,1.7-0.2,3.3-0.4,5v4.2c0.6,15,6.1,27.9,17,38.6c11.3,11.3,24.9,17,41,17s29.6-5.7,41-17
	c11.3-11.3,17-24.9,17-41c0-8.3-1.5-15.8-4.5-22.8C697,335.2,692.8,329.2,687.3,323.6L687.3,323.6z M643.2,328.2l3.2-0.1
	c8.9,0,16.7,2.8,23.1,8.4c0.9,0.7,1.8,1.5,2.6,2.4c7.1,7.2,10.6,15.8,10.6,25.9c0,10-3.5,18.7-10.6,25.7
	c-7.1,7.1-15.7,10.7-25.9,10.7s-18.8-3.5-25.9-10.7c-1.8-1.8-3.3-3.7-4.7-5.5c-4-5.9-6-12.6-6-20.2c0-2.5,0.2-4.7,0.6-7
	c0.6-3.1,1.5-6,2.8-8.9c1.8-3.5,4.1-7,7.2-10c4-4,8.5-6.9,13.5-8.6C636.9,329.2,640,328.5,643.2,328.2L643.2,328.2z"
          />
          <path
            fill="#656565"
            d="M593,341.4l-11.6-0.1l-0.1,0.4c-1.1,3.9-2,8-3.1,12.4l10.9,0.6c0.7-4.6,2-8.9,3.8-13L593,341.4L593,341.4z
	 M588.4,362.7l-9.1,0.7l9.1,3.5"
          />
          <path
            fill="#656565"
            d="M569.1,353.7l-6.3-0.1l-58.3-1.9l-5.1,1.8l35.3,1.7l17.6,0.8l17.7,0.8l18.7,0.9l0.4-3.1l-10.9-0.6
	 M588.4,362.7l0.4-5l-18.7-0.8v7.3l9.2-0.7L588.4,362.7L588.4,362.7z"
          />
          <path
            fill="#656565"
            d="M570.1,361.4c-17.9-0.9-29.5,0-34.7,3l34.7-0.2L570.1,361.4L570.1,361.4z"
          />
          <path
            fill="#656565"
            d="M570.1,356.8l-17.7-0.8c-7.7,0.4-13.5,1.4-17.4,3.2l0.4,5.2c5.2-3,16.8-3.9,34.7-3L570.1,356.8L570.1,356.8z
	 M512.6,364.5l-22.8,0.1c1.4,2.5,3.8,3.5,7.2,3.3h9.2C510.1,368.2,512.2,367.2,512.6,364.5L512.6,364.5z"
          />
          <path
            fill="#656565"
            d="M499.5,353.5l-18.2,6.3l-3.5,0.9v3.9h12l22.8-0.1l22.8-0.1l-0.4-5.2l-0.2-4"
          />
          <path
            fill="#656565"
            d="M535,359.2c3.9-1.8,9.7-2.8,17.4-3.2l-17.6-0.8L535,359.2L535,359.2z M116.2,342.6l-18.9,0.6
	c-4.5-0.1-8.7-1.5-12.9-4.5l-6-0.7l-4.7-0.7l0.1,3.2l0.6,10.3c0.4,5.9,3.3,8.7,8.9,8.3h48.5c0.8-9.2,3.5-17.5,8.3-25l-15.5,5.4
	c-0.2,2.4-0.5,4.8-0.5,7.2c-0.2,1.4-1.2,2.1-3.1,1.9l-13.5-3l13.1-2.7L116.2,342.6L116.2,342.6z"
          />
          <path
            fill="#EBEBEB"
            d="M341.6,234.1c14.9,2.6,29.9,4.2,45,4.7l0.8,0.1c4.6,0.4,6.9-1.7,7-6.1c-0.1-17.8-0.9-34.5-2.6-49.9
	c-0.9-4.2-3.1-6.3-6.3-6c-14.6-0.7-29.5-0.4-44.6,1.1c-3.8,0.9-6.1,3.7-7.3,8.1l-0.1,0.4c-0.2,1.1-0.4,2.1-0.5,3.4
	c-1.2,11.5-1.8,22.2-1.7,32.2C330.6,228.9,334,233.1,341.6,234.1L341.6,234.1z M226,364.6c0-10-3.5-18.7-10.6-25.9
	c-0.8-0.8-1.7-1.7-2.6-2.4c-6.6-5.5-14.3-8.4-23.3-8.4l-3.2,0.1c-3.2,0.2-6.3,0.8-9.1,1.9c-5,1.8-9.4,4.6-13.5,8.6
	c-3.1,3.1-5.4,6.4-7.2,10c-1.3,2.8-2.4,5.7-2.8,8.9c-0.5,2.2-0.6,4.6-0.6,7c0,7.6,2,14.3,6,20.2c1.3,2,3,3.8,4.7,5.5
	c7.1,7.1,15.7,10.7,25.9,10.7c3.2,0,6.3-0.4,9.2-1.1l1.3-0.4c1.8-0.5,3.5-1.2,5.2-2c3.7-1.8,7-4.1,10.2-7.3
	C222.5,383.4,226,374.7,226,364.6L226,364.6z M220.2,347.5c2.8,5.1,4.4,10.9,4.4,17.1c0,2.5-0.2,4.8-0.7,7.2
	c-0.5,2.7-1.3,5.2-2.5,7.7c-0.6,1.2-1.2,2.2-1.9,3.4c-1.4,2.4-3.2,4.5-5.2,6.5c-3.2,3.2-6.6,5.7-10.4,7.3h-0.1
	c-1.2,0.5-2.4,0.9-3.7,1.4c-2.4,0.7-5,1.2-7.6,1.4l-3.1,0.1c-7.7,0-14.5-2.1-20.4-6.5L169,393l-3.2-2.6c-0.1,0.5-0.2,1.1-0.1,1.5
	c0-0.5,0-0.9,0.1-1.5l-1.1-1.1c-1.4-1.4-2.8-3-3.9-4.6c-4.2-5.9-6.4-12.6-6.4-20.2l0.1-3.2v-0.1c0.1-1.4,0.4-3,0.6-4.4
	c0.5-2.4,1.2-4.6,2.2-6.7c0.6-1.3,1.3-2.6,2-3.9c1.4-2.4,3.2-4.5,5.3-6.6c2.9-3,6.1-5.2,9.6-6.8c1.3-0.7,2.7-1.2,4.1-1.7
	c2.2-0.7,4.6-1.3,7.1-1.5l4.4-0.2c7.1,0.1,13.5,2,19.1,5.8c1.2,0.8,2.4,1.8,3.4,2.7l1.8,1.7c1.4,1.4,2.6,2.8,3.8,4.4
	C218.8,345.2,219.5,346.4,220.2,347.5L220.2,347.5z"
          />
          <path
            fill="#EBEBEB"
            d="M218,344.2c-1.1,0.2-2.2,0.7-3.5,1.7c-1.4,0.9-3.1,2.4-4.8,4.1l-10.2,9.7c0.7,0,1.2,0.2,1.8,0.7
	c0.6,0.6,0.8,1.2,0.8,2c0,0.8-0.2,1.4-0.8,2l-0.8,0.6l12.8,5c0.2,0.1,0.5,0.2,0.7,0.2c2.4,0.9,4.4,1.5,6,1.9l3.9-0.1
	c0.5-2.4,0.7-4.7,0.7-7.2c0-6.4-1.4-12-4.4-17.1l-2.6,3.3c2.1,4.1,3.2,8.7,3.2,13.8v1.4l3.7,1.8c-0.6,1.1-1.9,1.5-4,1.5
	c-1.1,0-2.2-0.2-3.7-0.5l-12.3-4.1l-1.1-6.7l9.8-8.5l2.7-1.7c2-1.1,3.4-1.2,4.2-0.4C219.5,346.4,218.8,345.2,218,344.2L218,344.2z"
          />
          <path
            fill="#EBEBEB"
            d="M215.8,347.8l-2.7,1.7l-9.8,8.5l1.4,0.5l15.5-11C219.4,346.8,217.9,346.9,215.8,347.8L215.8,347.8z"
          />
          <path
            fill="#EBEBEB"
            d="M220.2,347.5l-15.6,11l2.1,0.1l5.7-3.9l4.5-3l0.7-0.9"
          />
          <path
            fill="#656565"
            d="M216.9,351.7l-4.4,3c1.3,3,2,6.3,2,9.7l4.8,0.9l1.4,0.7v-1.4c0-5.1-1.1-9.7-3.2-13.8L216.9,351.7
	L216.9,351.7z"
          />
          <path
            fill="#EBEBEB"
            d="M219.9,371.9c-0.4,1.8-0.9,3.4-1.7,5c1.5,0.7,2.5,1.5,3.1,2.5c1.2-2.4,2-5,2.5-7.7L219.9,371.9L219.9,371.9z
	 M220.3,369.4c2,0.1,3.4-0.5,4-1.5l-18.9-4.1l-1.1,1.1l12.3,4.1C218.1,369.2,219.3,369.4,220.3,369.4L220.3,369.4z"
          />
          <path
            fill="#EBEBEB"
            d="M214.4,364.5l-7.1-1.5l-1.9,0.8l18.9,4.1l-3.7-1.9l-1.4-0.6 M221.3,379.5c-0.6-0.9-1.5-1.8-3.1-2.5
	c-1.4-0.8-3.4-1.5-5.9-2.2l-13.8-4.2l0.1,0.1l0.2,0.5c0.2,0.4,0.2,0.7,0.2,1.2c0,0.7-0.2,1.4-0.8,1.9c-0.6,0.6-1.2,0.8-2,0.8
	c-0.7,0-1.3-0.2-1.9-0.7l2.1,14.4c0.4,2.5,0.8,4.5,1.4,6.1c0.6,1.5,1.3,2.7,2,3.4c1.3-0.4,2.5-0.8,3.7-1.4c-1.2-0.1-2.2-1.1-3.2-2.8
	c-0.5-0.9-0.9-2.1-1.4-3.5l-2.5-12.8l5.1-4.6l12.2,4.4c1.3,0.6,2.4,1.3,3.3,1.9c1.7,1.2,2.5,2.4,2.4,3.5
	C220.2,381.7,220.8,380.7,221.3,379.5L221.3,379.5z"
          />
          <path
            fill="#EBEBEB"
            d="M217.1,379.2c-0.8-0.6-1.9-1.3-3.3-1.9l-12.2-4.4l0.2,1.5l17.6,8.3C219.6,381.7,218.8,380.4,217.1,379.2
	L217.1,379.2z M201.9,374.5l1.1,1.8l6.5,3.3l4.4,2.1l1.7,0.4l4,0.8"
          />
          <path
            fill="#EBEBEB"
            d="M215.5,382.1c-1.2,1.7-2.5,3.2-3.9,4.6c-2.5,2.5-5.1,4.5-8,5.9l0.4,4.1c3.8-1.8,7.2-4.1,10.4-7.3
	c2-2,3.8-4.3,5.2-6.5L215.5,382.1L215.5,382.1z"
          />
          <path
            fill="#656565"
            d="M219.9,371.9c-1.7-0.2-3.7-0.9-6-1.9c-0.4,1.5-0.8,3.2-1.5,4.6c2.5,0.7,4.4,1.4,5.9,2.2
	C218.9,375.3,219.5,373.7,219.9,371.9L219.9,371.9z"
          />
          <path
            fill="#EBEBEB"
            d="M174.3,332.9c-3.4,1.7-6.6,3.9-9.6,6.9c-2,2.1-3.8,4.2-5.3,6.6l4.2,0.7c1.2-1.5,2.4-3.1,3.8-4.5
	c2.1-2.1,4.5-3.9,7-5.3L174.3,332.9L174.3,332.9z"
          />
          <path
            fill="#EBEBEB"
            d="M174.4,337.7l2,5.7l2.1,5.8l1.7,1.2l-5.9-17.4l0.1,4.3"
          />
          <path
            fill="#EBEBEB"
            d="M177.7,335.5c0.4,0.7,0.7,1.5,1.1,2.5l2.8,12.6l-5,4.7l-12.3-4c-1.1-0.5-1.9-0.9-2.6-1.4
	c-2-1.3-2.8-2.5-2.4-3.7c-0.7,1.2-1.4,2.5-2.1,3.9c0.7,0.7,1.9,1.4,3.3,2.1c1.5,0.7,3.5,1.3,5.9,1.9h0.1l13.3,3.8
	c-0.2-0.4-0.2-0.7-0.2-1.2c0-0.7,0.2-1.4,0.8-2c0.5-0.6,1.2-0.8,2-0.8c0.5,0,0.9,0.1,1.3,0.4l-2.5-13.5v-0.4
	c-0.4-2.2-0.7-4.2-1.3-5.8c-0.5-1.5-1.1-2.7-1.8-3.5c-1.4,0.5-2.7,0.9-4.1,1.7C175.5,332.5,176.6,333.3,177.7,335.5L177.7,335.5z"
          />
          <path
            fill="#EBEBEB"
            d="M174.3,332.9l5.9,17.4l1.4,0.5l-2.8-12.6c-0.4-0.9-0.7-1.8-1.1-2.5C176.6,333.3,175.5,332.5,174.3,332.9
	L174.3,332.9z"
          />
          <path
            fill="#656565"
            d="M174.4,337.2c-2.5,1.4-4.8,3.2-7,5.3c-1.4,1.4-2.7,3-3.8,4.5l0.7,0.1l5.2,2.5c0.7-0.9,1.5-1.8,2.4-2.6
	c1.4-1.4,3-2.7,4.6-3.7l-2-5.7L174.4,337.2L174.4,337.2z"
          />
          <path
            fill="#EBEBEB"
            d="M176.4,343.3c-1.7,1.1-3.2,2.2-4.6,3.7c-0.8,0.8-1.7,1.7-2.4,2.6l5.8,2.7l3.2-3.2L176.4,343.3L176.4,343.3z"
          />
          <path
            fill="#EBEBEB"
            d="M163.7,347.1l-4.2-0.7l17,7.7l-1.1-1.8l-5.8-2.7l-5.2-2.5"
          />
          <path
            fill="#EBEBEB"
            d="M161.8,350.1c0.7,0.5,1.5,0.9,2.6,1.4l12.3,4l-0.2-1.5l-17-7.6C158.9,347.6,159.6,348.9,161.8,350.1
	L161.8,350.1z"
          />
          <path
            fill="#656565"
            d="M205.6,337.8c-4.2-2.6-9.1-4-14.3-4.4l0.4,0.8l0.5,5.4c3.3,0.2,6.4,1.2,9.2,2.7l3.5-4.3L205.6,337.8
	L205.6,337.8z"
          />
          <path
            fill="#EBEBEB"
            d="M201.3,342.5l-4.2,5.1l-0.2,2l12.2-14.2l-3.4,2.4l-0.7,0.5"
          />
          <path
            fill="#EBEBEB"
            d="M209,335.4c0.9,0.9,0.8,2.5-0.5,4.4c-0.5,0.7-1.1,1.5-1.9,2.4l-9.4,8.9l-6.6-1.8l-2.7-12.6
	c-0.1-1.2-0.1-2.2-0.1-3.1c0.1-2.4,0.8-3.7,2.1-3.9l-4.4,0.2c-0.2,1.1-0.4,2.4-0.2,3.9c0.2,1.7,0.7,3.8,1.4,6.1l3.5,13.3l0.4-0.5
	c0.5-0.5,1.2-0.8,2-0.8c0.7,0,1.4,0.2,2,0.8c0.5,0.5,0.7,1.2,0.8,1.9l10.6-8.9l0.1-0.1c1.9-1.5,3.4-3,4.6-4.2c1.1-1.2,1.7-2.4,2-3.3
	C211.4,337.2,210.2,336.3,209,335.4L209,335.4z"
          />
          <path
            fill="#EBEBEB"
            d="M208.5,339.8c1.3-2,1.4-3.4,0.5-4.4l-12.2,14.2l0.4,1.4l9.4-8.9C207.3,341.3,208.1,340.6,208.5,339.8
	L208.5,339.8z"
          />
          <path
            fill="#656565"
            d="M211.6,342.5l-1.2-1.1c-1.2,1.3-2.6,2.7-4.6,4.2l-0.1,0.1l1.4,1.3c0.9,0.9,1.8,1.9,2.5,3
	c1.8-1.8,3.4-3.1,4.8-4.1C213.6,344.6,212.7,343.6,211.6,342.5L211.6,342.5z"
          />
          <path
            fill="#EBEBEB"
            d="M214.4,345.8c1.3-0.9,2.5-1.4,3.5-1.7c-1.1-1.5-2.4-3-3.8-4.4l-1.8-1.7c-0.4,0.9-1.1,2-2,3.3l1.2,1.1
	C212.7,343.6,213.6,344.6,214.4,345.8L214.4,345.8z M207.1,347l-1.4-1.3l-10.6,8.9v0.1c0,0.7-0.2,1.4-0.8,1.9
	c0.6,0.4,1.2,0.8,1.7,1.3c0.6,0.7,1.2,1.3,1.5,2.1c0.5-0.4,0.9-0.6,1.7-0.6h0.2l10.2-9.7C208.9,348.9,208.1,347.9,207.1,347
	L207.1,347z M201.3,342.5c-2.8-1.5-5.9-2.5-9.2-2.7l0.6,6.6l4.4,1.1L201.3,342.5L201.3,342.5z M209,335.4c-5.7-3.8-12-5.8-19.1-5.8
	l1.4,3.9c5.3,0.2,10,1.7,14.3,4.3L209,335.4L209,335.4z M185.1,333.7c-0.1-1.5-0.1-3,0.2-3.9c-2.5,0.2-4.8,0.7-7.1,1.5
	c0.7,0.8,1.2,2,1.8,3.5C181.7,334.2,183.4,333.9,185.1,333.7L185.1,333.7z"
          />
          <path
            fill="#656565"
            d="M180.1,334.8c0.5,1.5,0.9,3.4,1.3,5.8v0.4c1.7-0.6,3.4-0.9,5.2-1.1c-0.7-2.4-1.2-4.5-1.4-6.1
	C183.4,333.9,181.7,334.2,180.1,334.8L180.1,334.8z"
          />
          <path
            fill="#EBEBEB"
            d="M191.8,348.2l0.9-1.8l-0.6-6.6l-0.6-5.4l-0.2-0.9l-1.4-3.9"
          />
          <path
            fill="#EBEBEB"
            d="M190.6,349.2l6.6,1.8l-0.4-1.4l0.2-2l-4.4-1.2l-0.9,1.8"
          />
          <path
            fill="#EBEBEB"
            d="M191.8,348.2l-1.9-18.7c-1.3,0.2-2,1.5-2.1,3.9c0,0.8,0,1.9,0.1,3.1l2.7,12.6L191.8,348.2L191.8,348.2z
	 M181.4,341l2.5,13.5l0.6,0.5c0.4,0.4,0.7,0.8,0.7,1.4c1.3-0.7,2.7-1.1,4.2-1.1h0.2v-0.5c0-0.6,0.1-1.1,0.5-1.5l-3.5-13.3
	C184.8,340,183,340.4,181.4,341L181.4,341z M178.5,349.1l-3.2,3.2l1.1,1.8l0.2,1.4l5-4.7l-1.4-0.5"
          />
          <path
            fill="#EBEBEB"
            d="M180.5,354.8c-0.6,0.6-0.8,1.2-0.8,2c0,0.5,0.1,0.8,0.2,1.2l0.6,0.7c0.4,0.4,0.7,0.6,1.1,0.7l0.8,0.1
	c0.7,0,1.4-0.2,2-0.8c0.6-0.6,0.8-1.2,0.8-2l-0.1-0.6c-0.1-0.6-0.4-1.1-0.7-1.4l-0.6-0.5c-0.4-0.2-0.8-0.4-1.3-0.4
	C181.7,354.1,181,354.3,180.5,354.8L180.5,354.8z"
          />
          <path
            fill="#EBEBEB"
            d="M184.4,358.7c-0.6,0.6-1.2,0.8-2,0.8l-0.8-0.1c-0.8,1.3-1.4,2.7-1.5,4.4c0.4,0.1,0.6,0.4,0.9,0.6
	c0.6,0.6,0.8,1.2,0.8,2c0,0.7-0.2,1.4-0.8,1.9l-0.1,0.1c0.5,1.1,1.1,1.9,2,2.8l1.4,1.2c0.5-0.5,1.1-0.7,1.8-0.7s1.4,0.2,2,0.8
	c0.4,0.5,0.7,0.9,0.7,1.4h0.7c1.5,0,3.1-0.4,4.4-1.1l-0.1-0.7c0-0.7,0.2-1.4,0.8-2c0.6-0.6,1.2-0.8,1.9-0.8l0.9,0.2
	c0.8-1.4,1.4-3,1.4-4.6c-0.6-0.1-1.1-0.4-1.4-0.8c-0.6-0.5-0.8-1.2-0.8-2c0-0.7,0.2-1.4,0.8-2l0.4-0.2c-0.5-0.7-0.9-1.4-1.7-2.1
	c-0.5-0.5-1.1-0.9-1.7-1.3c-0.6,0.6-1.2,0.8-2,0.8c-0.8,0-1.4-0.2-2-0.8c-0.5-0.4-0.7-0.9-0.8-1.4h-0.2c-1.5,0-3,0.4-4.2,1.1
	l0.1,0.6C185.3,357.5,185,358.2,184.4,358.7L184.4,358.7z M187.6,359.6l1.3-0.2h0.5c1.3,0,2.4,0.4,3.4,1.2l0.4,0.4
	c1.1,1.1,1.5,2.2,1.5,3.8c0,1.4-0.5,2.7-1.5,3.7c-1.1,1.1-2.2,1.5-3.8,1.5c-1.4,0-2.7-0.5-3.7-1.5l-0.7-0.8c-0.6-0.8-0.8-1.8-0.8-3
	l0.1-1.1c0.1-0.5,0.2-0.8,0.4-1.2c0.2-0.6,0.6-1.1,1.1-1.4C186.3,360.3,186.9,359.9,187.6,359.6L187.6,359.6z"
          />
          <path
            fill="#EBEBEB"
            d="M185.7,360.9c-0.5,0.5-0.8,0.9-1.1,1.4c-0.2,0.4-0.4,0.8-0.4,1.2l-0.1,1.1c0,1.1,0.2,2,0.8,3l0.7,0.8
	c1.1,1.1,2.2,1.5,3.7,1.5s2.7-0.5,3.8-1.5c1.1-1.1,1.5-2.2,1.5-3.7c0-1.4-0.5-2.7-1.5-3.8l-0.4-0.4c-0.9-0.8-2.1-1.2-3.4-1.2h-0.5
	l-1.3,0.2C186.9,359.9,186.3,360.3,185.7,360.9L185.7,360.9z M179.9,358l-13.3-3.8c-0.7,1.5-1.2,3.2-1.7,4.8l13,4.6l1.1-0.2
	c0.4,0,0.7,0.1,1.1,0.2c0.1-1.5,0.6-3.1,1.5-4.4c-0.4-0.1-0.7-0.4-1.1-0.7L179.9,358L179.9,358z M190.1,353.3
	c-0.2,0.5-0.5,0.9-0.5,1.5v0.5c0.1,0.6,0.4,1.1,0.8,1.4c0.5,0.6,1.2,0.8,2,0.8c0.7,0,1.4-0.2,2-0.8c0.5-0.5,0.8-1.2,0.8-1.9v-0.1
	c0-0.7-0.4-1.3-0.8-1.9c-0.6-0.5-1.2-0.8-2-0.8c-0.8,0-1.4,0.2-2,0.8L190.1,353.3L190.1,353.3z M194.6,370.3c-0.6,0.6-0.8,1.2-0.8,2
	l0.1,0.7c0.1,0.5,0.4,0.9,0.7,1.3l0.1,0.1c0.5,0.5,1.1,0.7,1.9,0.7c0.7,0,1.4-0.2,2-0.8c0.6-0.6,0.8-1.2,0.8-1.9
	c0-0.5-0.1-0.8-0.2-1.2l-0.2-0.5l-0.1-0.1l-0.1-0.2c-0.4-0.2-0.7-0.5-1.1-0.6l-0.9-0.2C195.8,369.4,195.1,369.8,194.6,370.3
	L194.6,370.3z"
          />
          <path
            fill="#EBEBEB"
            d="M179,363.6l-1.1,0.2c-0.4,0.1-0.6,0.4-0.9,0.6c-0.6,0.6-0.8,1.2-0.8,2c0,0.7,0.2,1.4,0.8,1.9
	c0.5,0.6,1.2,0.8,2,0.8c0.7,0,1.3-0.2,1.8-0.7l0.1-0.1c0.6-0.6,0.8-1.2,0.8-1.9c0-0.7-0.2-1.4-0.8-2c-0.2-0.2-0.6-0.5-0.9-0.6
	C179.7,363.6,179.5,363.6,179,363.6L179,363.6z M184.2,372.4c-0.6,0.6-0.8,1.2-0.9,1.8v0.2c0,0.7,0.2,1.4,0.8,1.9
	c0.6,0.6,1.2,0.8,1.9,0.8s1.4-0.2,2-0.8l0.6-0.9c0.1-0.4,0.2-0.7,0.2-1.1v-0.6c-0.1-0.6-0.4-1.1-0.7-1.4c-0.6-0.6-1.2-0.8-2-0.8
	C185.4,371.7,184.7,371.9,184.2,372.4L184.2,372.4z"
          />
          <path
            fill="#EBEBEB"
            d="M179,369.1l-10.2,9.8c0.8,1.2,1.8,2.2,3,3.4l0.7,0.7l0.2-0.1l10.6-8.6c0.1-0.6,0.4-1.2,0.8-1.7l0.1-0.1
	l-1.4-1.2c-0.8-0.8-1.5-1.8-2-2.8C180.3,368.8,179.7,369.1,179,369.1L179,369.1z M199.6,359.5h-0.2c-0.6,0-1.2,0.2-1.7,0.6l-0.4,0.2
	c-0.6,0.6-0.8,1.2-0.8,2c0,0.8,0.2,1.4,0.8,2c0.5,0.5,0.9,0.7,1.4,0.8h0.5c0.5,0,0.8-0.1,1.2-0.2l0.8-0.6c0.6-0.5,0.8-1.2,0.8-2
	c0-0.7-0.2-1.4-0.8-2C200.7,359.9,200.1,359.5,199.6,359.5L199.6,359.5z M204.4,364.7l1.1-0.9l1.9-0.8l-0.6-4.4l-2.1-0.1l-1.3-0.5"
          />
          <path
            fill="#EBEBEB"
            d="M206.8,358.6l0.6,4.5l7.1,1.4c0-3.4-0.7-6.7-2-9.7L206.8,358.6L206.8,358.6z M213.2,369.8l-12.8-5
	c-0.4,0.1-0.7,0.2-1.2,0.2h-0.5c-0.1,1.8-0.6,3.3-1.4,4.6c0.4,0.1,0.7,0.4,1.1,0.6l0.1,0.2l13.8,4.2c0.7-1.5,1.2-3.1,1.5-4.6
	C213.6,370,213.5,369.9,213.2,369.8L213.2,369.8z M196.6,377.7l1.4,0.5l1.7,1.3l3.3-3.2l-1.1-1.8l-0.2-1.4"
          />
          <path
            fill="#EBEBEB"
            d="M203.8,396.7h0.1l-0.4-4.1l-0.1-1.5l-1.5-4.7l-2.2-6.8l-1.7-1.3"
          />
          <path
            fill="#EBEBEB"
            d="M199.7,379.5l2.2,7c1.9-1.1,3.5-2.5,5.2-4.1c0.8-0.8,1.7-1.8,2.4-2.7l-6.6-3.3L199.7,379.5z"
          />
          <path
            fill="#656565"
            d="M207.1,382.3c-1.7,1.7-3.3,3-5.2,4.1l1.5,4.6l0.1,1.5c3-1.4,5.6-3.4,8-5.9c1.4-1.4,2.7-3,3.9-4.6l-1.7-0.4
	l-4.4-2.1C208.8,380.5,208.1,381.5,207.1,382.3L207.1,382.3z"
          />
          <path
            fill="#EBEBEB"
            d="M200.5,393.9c0.9,1.8,2,2.7,3.2,2.8h0.1l-5.8-18.5l-1.4-0.5l2.5,12.8C199.6,391.9,200,393,200.5,393.9
	L200.5,393.9z M181.5,381.1l4.4,1.3l1.1-1.8l1.2-1.1l-6.6-1.9l0.4,1.4"
          />
          <path
            fill="#EBEBEB"
            d="M181.5,377.7l-9.7,8.6c-0.8,0.9-1.5,1.8-2.1,2.6c-1.2,1.8-1.4,3.2-0.8,4.2h0.1l12.9-14L181.5,377.7
	L181.5,377.7z"
          />
          <path
            fill="#EBEBEB"
            d="M169,393.2l3.5-2.2l0.9-0.7l3.5-3.9l4.5-5.2l0.4-2"
          />
          <path
            fill="#EBEBEB"
            d="M181.5,381.1l-4.6,5.2c2.8,1.7,6,2.7,9.4,3.2l-0.5-7.1L181.5,381.1L181.5,381.1z M186.3,389.5l0.4,5l0.5,1.3
	l1.4,3.9l-1.7-19l-1.1,1.8"
          />
          <path
            fill="#EBEBEB"
            d="M183.3,374.3l-10.6,8.6l-0.2,0.1c-2,1.5-3.5,3-4.6,4.1c-1.1,1.2-1.8,2.4-2,3.3l3.2,2.6
	c-0.6-1.1-0.4-2.5,0.8-4.2c0.5-0.8,1.2-1.7,2.1-2.6l9.7-8.6l6.5,2l2.5,12.8c0.1,1.3,0.1,2.5,0,3.4c-0.2,2.1-0.8,3.4-2,3.8l-1.4-3.9
	c-5.4-0.4-10.3-2-14.6-4.8l-3.4,2.2c5.9,4.4,12.8,6.5,20.4,6.5l3.1-0.1c0.4-0.9,0.6-2.2,0.5-3.9c-0.1-1.8-0.5-3.8-1.1-6.1l-3.3-13.9
	l-0.6,0.9c-0.6,0.6-1.2,0.8-2,0.8c-0.7,0-1.4-0.2-1.9-0.8c-0.6-0.6-0.8-1.2-0.8-1.9v-0.2L183.3,374.3L183.3,374.3z"
          />
          <path
            fill="#EBEBEB"
            d="M188.8,373.9v0.6c0,0.4-0.1,0.7-0.2,1.1l3.3,13.9c1.7-0.1,3.3-0.5,4.7-0.8l-2.1-14.4l-0.1-0.1
	c-0.4-0.4-0.6-0.8-0.7-1.3c-1.3,0.7-2.7,1.1-4.4,1.1L188.8,373.9L188.8,373.9L188.8,373.9z"
          />
          <path
            fill="#656565"
            d="M191.9,389.5c0.7,2.4,1.1,4.5,1.1,6.1c1.8-0.2,3.4-0.5,5.1-0.9c-0.6-1.5-1.1-3.7-1.4-6.1
	C195.2,389,193.6,389.4,191.9,389.5L191.9,389.5z M186.3,389.5c-3.4-0.4-6.5-1.4-9.4-3.2l-3.4,3.9l-0.9,0.7c4.4,3,9.2,4.5,14.6,4.8
	l-0.5-1.3L186.3,389.5L186.3,389.5z"
          />
          <path
            fill="#EBEBEB"
            d="M186.9,380.6l1.7,19c1.2-0.4,1.8-1.7,2-3.8c0.1-0.9,0.1-2.1,0-3.4l-2.5-12.8L186.9,380.6L186.9,380.6z
	 M155.2,357l3.9,0.2c0.4-1.7,0.9-3.3,1.7-4.8c-1.5-0.7-2.6-1.3-3.3-2.1C156.3,352.4,155.6,354.7,155.2,357L155.2,357z M154.6,361.4
	c0.4-1.3,1.7-1.9,4.1-1.7l2.8,0.4l12.4,3.8l1.3,6.7l-9.7,8.7c-0.9,0.7-1.9,1.3-2.6,1.7c-2,1.1-3.4,1.2-4.4,0.2l2.6-3.3
	c-1.9-4-2.8-8.4-2.8-13.2v-1.4L154.6,361.4l-0.1,3.3c0,7.6,2.1,14.3,6.4,20.2c1.1-0.2,2.2-0.8,3.5-1.8c1.4-0.9,3-2.4,4.7-4.1
	l10.2-9.8c-0.8,0-1.4-0.2-2-0.8s-0.8-1.2-0.8-1.9c0-0.7,0.2-1.4,0.8-2c0.2-0.2,0.6-0.5,0.9-0.6l-13-4.6l-6-1.9l-3.9-0.2
	C154.8,358.5,154.7,359.9,154.6,361.4L154.6,361.4z"
          />
          <path
            fill="#EBEBEB"
            d="M154.6,361.4l18.3,3.5l1.1-1.1l-12.4-3.8l-2.8-0.4C156.2,359.5,154.8,360.1,154.6,361.4L154.6,361.4z
	 M164.5,364.6l6.4,1.2l1.9-0.8l-18.3-3.5l3.9,1.9l0.6,0.4"
          />
          <path
            fill="#656565"
            d="M158.3,363.3v1.4c0,4.8,0.9,9.2,2.8,13.2l0.6-0.7l4.5-3.2c-1.2-2.8-1.8-6-1.8-9.3v-0.1l-5.6-0.9L158.3,363.3
	L158.3,363.3z M166.5,354.2c-2.4-0.6-4.4-1.2-5.9-1.9c-0.7,1.5-1.2,3.2-1.7,4.8l6,1.9C165.4,357.4,165.9,355.9,166.5,354.2
	L166.5,354.2z"
          />
          <path
            fill="#EBEBEB"
            d="M170.9,365.8l0.7,4.4h2.1l1.4,0.5l-1.3-6.7l-1.1,1.1"
          />
          <path
            fill="#EBEBEB"
            d="M166.3,374l-4.6,3.2l-0.6,0.7l-2.6,3.3l15.2-11.1h-2.1 M173.7,370.1l-15.2,11.1c0.8,0.9,2.4,0.8,4.4-0.2
	c0.8-0.4,1.7-0.9,2.6-1.7l9.7-8.7L173.7,370.1L173.7,370.1z"
          />
          <path
            fill="#EBEBEB"
            d="M170.9,365.8l-6.4-1.2v0.1c0,3.3,0.6,6.5,1.8,9.3l5.4-3.8L170.9,365.8L170.9,365.8z"
          />
          <path
            fill="#656565"
            d="M164.2,383c0.9,1.3,2,2.5,3.2,3.7l0.5,0.5c1.2-1.3,2.7-2.7,4.6-4.1l-0.7-0.7c-1.1-1.1-2.1-2.2-3-3.4
	C167.2,380.6,165.7,382.1,164.2,383L164.2,383z"
          />
          <path
            fill="#EBEBEB"
            d="M167.4,386.7c-1.2-1.2-2.2-2.4-3.2-3.7c-1.3,0.9-2.5,1.5-3.5,1.8c1.2,1.5,2.5,3.1,3.9,4.6l1.1,1.1
	c0.2-1.1,0.9-2.1,2-3.3L167.4,386.7L167.4,386.7z M168.9,393l0.1,0.1 M192.6,399.5c2.6-0.2,5.2-0.7,7.6-1.4c-0.8-0.7-1.5-1.8-2-3.4
	c-1.7,0.5-3.3,0.7-5.1,0.9C193.2,397.3,192.9,398.6,192.6,399.5L192.6,399.5z M203.8,396.7h-0.1h0.2 M203.8,396.7h0.1 M59.4,257.3
	c0.6,0,0.7,0.9,0.7,3v17.4c0.2,1.2-0.7,1.9-2.6,2l5.9,0.1l1.3-2.1v-2.7l0.1-17c-0.4-4.7-1.5-7-3.4-7.1c-0.5,0.1-0.9,0.2-1.3,0.4
	L59.4,257.3L59.4,257.3z"
          />
          <path
            fill="#EBEBEB"
            d="M60,250.9c-0.6,0.2-0.9,0.6-1.2,1.2c-0.7,4.5-1.1,9.3-1.3,14.5c-0.1,3.1-0.1,6.3-0.1,9.6l0.1,3.3
	c1.9-0.1,2.7-0.7,2.6-2v-6.4c-0.4,1.5-1.1,2.4-2.1,2.5c1.1-0.1,1.7-0.9,2.1-2.5v-11c0.1-1.9-0.1-2.8-0.7-2.8L60,250.9L60,250.9z"
          />
          <path
            fill="#EBEBEB"
            d="M61.3,250.5c2,0.1,3.1,2.5,3.4,7.1h8.9c2.1-0.2,2.9,0.6,2.5,2.5l-3.8,12c-0.1,1.2-1.2,1.9-3.3,2l-4.4,0.5
	v2.5h9l-1.1,4.4l-2.4-0.2l0.4,1.4l-2.2,10.9h-6.8l-1.5-10l-2.5-1.1c0.2,4.1,0.5,8.5,0.9,13l13.7,0.1c1.8-14,4.5-26,7.8-35.7
	c0.8-2.6,0.1-4.6-2.1-6.1l-18.2-7.1l-0.9,5.2c0.2-0.5,0.6-0.9,1.2-1.2C60.3,250.6,60.8,250.6,61.3,250.5L61.3,250.5z"
          />
          <path
            fill="#EBEBEB"
            d="M73.7,257.6h-4.4l-0.2,16.5c2.1-0.1,3.3-0.7,3.3-2l3.8-12C76.5,258.2,75.7,257.4,73.7,257.6L73.7,257.6z"
          />
          <path
            fill="#EBEBEB"
            d="M64.6,274.6l4.5-0.5l0.1-16.5h-4.5 M64.6,277.2v0.2l-1.2,2.1l-5.9-0.1l0.1,3.1l2.4,1.1l0.7-2.4h9.4v0.1
	l2.4,0.2l1.2-4.4"
          />
          <path
            fill="#EBEBEB"
            d="M60.7,281.2l-0.7,2.4l1.5,10h6.8l2.2-10.9l-0.5-1.4l-0.7-0.1 M724.6,261.9h0.2c7,0.8,13.8,2.4,20.5,4.4
	c-0.5-0.7-1.1-1.2-1.9-1.7c-0.6-0.4-1.3-0.6-2-0.8c-5-1.7-10.2-2.7-15.6-3.4l-9.7-0.6c-8.4-0.4-13.7,0.7-16.2,3.1
	c-0.6,0.6-1.1,1.3-1.3,2.1h17.7h-17.7l-0.1,1.5l17.2-0.1l0.9,0.4c-0.2-4.1,2.1-5.8,7.2-4.8H724.6L724.6,261.9z"
          />
          <path
            fill="#EBEBEB"
            d="M724.5,261.9c-1.9-0.1-2.8,1.1-3,3.7v8.5c0,3.3,1.2,5.8,3.4,7.2l7.3,0.8v-1.7l-5-0.9c-0.7,0-1.3-0.2-1.9-0.9
	c-0.4-0.4-0.7-0.9-0.9-1.5c-0.4-1.5-0.7-3.1-0.8-4.7c-0.1-1.5-0.1-3.2,0-4.8l0.1-1.4c0.2-1.3,0.9-2,2.1-2.1
	c6.5,0.7,11.9,1.8,16.3,3.4h0.1c0.1,0,0.1,0,0.2,0.1l1.4,0.4c1.2,0.2,1.8-0.1,1.9-0.9l-0.4-0.5c-6.7-2-13.7-3.4-20.5-4.4h-0.1
	L724.5,261.9L724.5,261.9z"
          />
          <path
            fill="#EBEBEB"
            d="M724,261.9c-5.1-0.9-7.4,0.7-7.2,4.8l2.4,0.9v4.1l-1.9,1.1c0.1,3.8,1.1,6.6,2.8,8.6c3.2,1.3,6.5,2.4,10,3.1
	l2.2-2.6l-7.3-0.8c-2.2-1.4-3.3-3.9-3.4-7.2v-8.5c0.1-2.6,1.1-3.8,3-3.7L724,261.9L724,261.9z"
          />
          <path
            fill="#EBEBEB"
            d="M723.7,265.9l3.9,0.7l2.1-0.1v0.5l6.5,1.2l1.9-0.4h5.8l-1.4-0.4c-0.1,0-0.1-0.1-0.2-0.1h-0.1
	c-4.4-1.7-9.8-2.8-16.3-3.4C724.6,263.9,723.9,264.6,723.7,265.9L723.7,265.9z M723.6,272.3c1.1-3.2,2.4-5,4-5.5l-3.9-0.7l-0.1,1.4
	C723.5,269.1,723.5,270.6,723.6,272.3z M719.2,267.6l-2.4-0.9l-0.9-0.4l-17.2,0.1v0.1c0,0.5,0,0.9,0.1,1.4c0.2,0.9,0.7,1.8,1.4,2.5
	l1.4,1.2l2.6,1.8h12.2l0.8-0.5l1.9-1.1v-4.2L719.2,267.6L719.2,267.6z"
          />
          <path
            fill="#EBEBEB"
            d="M729.7,266.5l-2.1,0.1c-1.7,0.6-3,2.5-4,5.5c0.1,1.7,0.5,3.3,0.8,4.7c0.4,0.7,0.7,1.2,0.9,1.5l3.7,0.2
	l0.6-11.8L729.7,266.5L729.7,266.5z M732.4,282.1l-2.2,2.6l2.8,0.6c1.2-3.4,2.7-5.4,4.8-5.8l-5-0.4h-0.1l-3.7-0.2l-3.7-0.2
	c0.6,0.6,1.2,0.9,1.9,0.9l5,0.9v1.5L732.4,282.1L732.4,282.1z"
          />
          <path
            fill="#EBEBEB"
            d="M732.8,279.1l5,0.4l0.7-0.1l-0.4-11.6l-1.9,0.4c-3,0.8-5,2.5-5.9,5C729.5,275.6,730.3,277.6,732.8,279.1z"
          />
          <path
            fill="#EBEBEB"
            d="M730.3,273.1c0.9-2.5,3-4.1,5.9-5l-6.5-1.2l-0.6,11.8l3.7,0.2C730.3,277.6,729.5,275.6,730.3,273.1
	L730.3,273.1z M720,281.6c-1.8-2-2.7-5-2.8-8.6l-0.8,0.5h-12.2c0.1,0,0.2,0.1,0.2,0.1l13.2,7.1C718.5,280.9,719.3,281.2,720,281.6
	L720,281.6z M738.1,267.8l0.4,11.6l8,0.1v-10c0-1.1-0.2-1.9-0.7-2.6c-0.1,0.8-0.7,1.2-1.9,0.9L738.1,267.8L738.1,267.8z"
          />
          <path
            fill="#EBEBEB"
            d="M746.5,279.5l-8-0.1l-0.7,0.1c-2.1,0.4-3.8,2.4-4.8,5.8l2.6,0.5l3.5-4.2h5.5
	C746,281.2,746.6,280.6,746.5,279.5L746.5,279.5z M634,330.1c-5,1.8-9.4,4.6-13.5,8.6c-3.1,3.1-5.4,6.4-7.2,10
	c-1.3,2.8-2.4,5.7-2.8,8.9c-0.4,2.2-0.6,4.6-0.6,7c0,7.6,2,14.3,6,20.2c1.4,2,3,3.8,4.7,5.5c7.1,7.1,15.7,10.7,25.9,10.7
	c10,0,18.7-3.5,25.9-10.7c7.1-7.1,10.6-15.7,10.6-25.7c0-10-3.5-18.7-10.6-25.9c-0.8-0.8-1.7-1.7-2.6-2.4
	c-6.6-5.5-14.3-8.4-23.1-8.4l-3.2,0.1C640,328.5,636.9,329.2,634,330.1L634,330.1z M681.2,367.9c-0.1,1.3-0.2,2.7-0.6,3.9
	c-0.5,2.7-1.3,5.2-2.5,7.7c-0.6,1.2-1.2,2.2-1.9,3.4c-1.4,2.4-3.2,4.5-5.2,6.5c-3.2,3.2-6.6,5.7-10.4,7.3h-0.1
	c-1.2,0.5-2.4,0.9-3.7,1.4c-2.4,0.7-5,1.2-7.6,1.4l-3.1,0.1c-7.7,0-14.5-2.1-20.4-6.5l-0.1-0.1l-3.2-2.6c-0.1,0.5-0.2,1.1-0.1,1.5
	c0-0.5,0-0.9,0.1-1.5l-1.1-1.1c-1.5-1.4-2.8-3-4-4.6c-4.2-5.9-6.4-12.6-6.4-20.2l0.1-3.2v-0.1c0.1-1.4,0.4-3,0.6-4.4
	c0.5-2.4,1.3-4.6,2.2-6.7c0.6-1.3,1.3-2.6,2-3.9c1.4-2.4,3.2-4.5,5.3-6.6c3-3,6.1-5.2,9.6-6.8c1.3-0.7,2.7-1.2,4.1-1.7
	c2.2-0.7,4.6-1.3,7.1-1.5l4.4-0.2c7.2,0.1,13.6,2,19.1,5.8c1.2,0.8,2.4,1.8,3.4,2.7l1.8,1.7c1.4,1.4,2.6,2.8,3.8,4.4
	c0.8,1.1,1.5,2.1,2.1,3.3c3,5.1,4.4,10.9,4.4,17.1C681.4,365.8,681.3,366.8,681.2,367.9L681.2,367.9z"
          />
          <path
            fill="#EBEBEB"
            d="M681.4,364.6c0-6.4-1.4-12-4.4-17.1l-2.6,3.3c2.1,4.1,3.1,8.7,3.1,13.8v1.4l3.7,1.8
	C681.3,366.8,681.4,365.8,681.4,364.6L681.4,364.6z M631.1,332.9c1.3-0.5,2.5,0.4,3.5,2.6c0.4,0.7,0.7,1.5,0.9,2.5l2.8,12.6
	l-4.8,4.7l-12.4-4l-2.6-1.4c-2-1.3-2.8-2.5-2.4-3.7c-0.7,1.2-1.4,2.5-2,3.9c0.7,0.7,1.9,1.4,3.3,2.1c1.5,0.7,3.5,1.3,5.9,1.9h0.1
	l13.3,3.8c-0.2-0.4-0.2-0.7-0.2-1.2c0-0.7,0.2-1.4,0.8-2c0.6-0.6,1.2-0.8,2-0.8c0.5,0,0.9,0.1,1.3,0.4l-2.5-13.5v-0.4
	c-0.4-2.2-0.7-4.2-1.2-5.8s-1.1-2.7-1.8-3.5C633.8,331.8,632.4,332.2,631.1,332.9L631.1,332.9z"
          />
          <path
            fill="#656565"
            d="M631.2,337.2c-2.5,1.4-4.8,3.2-7,5.3c-1.4,1.4-2.7,3-3.8,4.5l0.7,0.1l5.2,2.5c0.7-0.9,1.4-1.8,2.2-2.6
	c1.4-1.4,3-2.7,4.6-3.7l-2-5.7L631.2,337.2L631.2,337.2z"
          />
          <path
            fill="#EBEBEB"
            d="M633.2,343.3l2.1,5.8l1.7,1.2l-5.9-17.4l0.1,4.3v0.5"
          />
          <path
            fill="#EBEBEB"
            d="M631.1,332.9c-3.4,1.7-6.6,3.9-9.6,6.9c-2.1,2.1-3.8,4.2-5.3,6.6l4.3,0.7c1.1-1.5,2.4-3.1,3.8-4.5
	c2.1-2.1,4.5-3.9,7-5.3L631.1,332.9L631.1,332.9z"
          />
          <path
            fill="#EBEBEB"
            d="M631.1,332.9l5.9,17.4l1.4,0.5l-2.8-12.6c-0.2-0.9-0.6-1.8-0.9-2.5C633.6,333.3,632.4,332.5,631.1,332.9
	L631.1,332.9z"
          />
          <path
            fill="#656565"
            d="M636.9,334.8c0.5,1.5,0.9,3.4,1.2,5.8v0.4c1.7-0.6,3.4-0.9,5.2-1.1c-0.7-2.4-1.2-4.5-1.4-6.1
	C640.3,333.9,638.6,334.2,636.9,334.8L636.9,334.8z"
          />
          <path
            fill="#EBEBEB"
            d="M642.1,333.7c-0.1-1.5-0.1-3,0.2-3.9c-2.5,0.2-4.7,0.7-7.1,1.5c0.7,0.8,1.2,2,1.8,3.5
	C638.6,334.2,640.3,333.9,642.1,333.7L642.1,333.7z"
          />
          <path
            fill="#EBEBEB"
            d="M642.1,333.7c0.2,1.7,0.7,3.8,1.4,6.1l3.5,13.3l0.4-0.5c0.6-0.5,1.2-0.8,2-0.8c0.7,0,1.4,0.2,2,0.8
	c0.5,0.5,0.7,1.2,0.8,1.9l10.6-8.9v-0.1c1.9-1.5,3.4-3,4.6-4.2c1.1-1.2,1.7-2.4,2-3.3c-1.2-1.1-2.2-1.9-3.4-2.7
	c0.9,0.9,0.8,2.5-0.5,4.4c-0.5,0.7-1.1,1.5-1.8,2.4l-9.4,8.9l-6.6-1.8l-2.7-12.6c-0.1-1.2-0.2-2.2-0.1-3.1c0.1-2.4,0.8-3.7,2.1-3.9
	l-4.4,0.2C642,330.8,641.9,332.1,642.1,333.7L642.1,333.7z M638.2,341l2.5,13.5c0.2,0.1,0.5,0.2,0.6,0.5c0.4,0.4,0.7,0.8,0.7,1.4
	c1.3-0.7,2.7-1.1,4.2-1.1h0.2v-0.5c0-0.6,0.1-1.1,0.5-1.5l-3.5-13.3C641.6,340,639.9,340.4,638.2,341L638.2,341z M626.4,349.6
	l5.8,2.7l3.2-3.2l-2.1-5.8c-1.7,1.1-3.2,2.2-4.6,3.7C627.8,347.8,627.1,348.7,626.4,349.6L626.4,349.6z"
          />
          <path
            fill="#EBEBEB"
            d="M616.2,346.4l17,7.7l-1.1-1.8l-5.8-2.7l-5.2-2.5h-0.7"
          />
          <path
            fill="#EBEBEB"
            d="M618.6,350.1l2.6,1.4l12.4,4l-0.2-1.5l-17-7.6C615.8,347.6,616.6,348.9,618.6,350.1L618.6,350.1z
	 M674.9,344.2c-1.1,0.2-2.2,0.7-3.5,1.7c-1.4,0.9-3,2.4-4.7,4.1c0,0-0.1,0-0.1,0.1l-10,9.6c0.7,0,1.2,0.2,1.8,0.7
	c0.6,0.6,0.8,1.2,0.8,2c0,0.8-0.2,1.4-0.8,2c-0.2,0.2-0.5,0.5-0.8,0.6l13.3,5.3c2.4,0.9,4.4,1.5,6,1.9l3.9-0.1
	c0.2-1.3,0.5-2.6,0.6-3.9c-0.6,1.1-2,1.5-4,1.5c-1.1,0-2.2-0.2-3.7-0.5l-12.3-4.1l-1.1-6.7l9.8-8.5c1.1-0.7,1.9-1.3,2.7-1.6
	c2-1.1,3.4-1.2,4.3-0.4C676.3,346.4,675.6,345.2,674.9,344.2L674.9,344.2z"
          />
          <path
            fill="#EBEBEB"
            d="M672.8,347.8c-0.8,0.4-1.8,0.9-2.7,1.7l-9.8,8.5l1.4,0.5l15.5-11C676.2,346.8,674.8,346.9,672.8,347.8
	L672.8,347.8z M665.4,339.8c1.3-2,1.4-3.4,0.5-4.4l-12.2,14.2l0.4,1.4l9.4-8.9C664.3,341.3,664.9,340.6,665.4,339.8L665.4,339.8z
	 M666.5,350c-0.7-1.1-1.5-2-2.5-3l-1.4-1.3l-10.6,8.9v0.1c0,0.7-0.2,1.4-0.7,1.9c0.6,0.4,1.2,0.8,1.7,1.3c0.7,0.7,1.2,1.3,1.5,2.1
	c0.5-0.4,0.9-0.6,1.7-0.6h0.2L666.5,350C666.4,350,666.5,350,666.5,350z"
          />
          <path
            fill="#656565"
            d="M666.5,350c1.8-1.8,3.4-3.1,4.7-4.1c-0.8-1.1-1.8-2.2-2.8-3.3l-1.2-1.1c-1.1,1.3-2.6,2.7-4.6,4.2v0.1
	l1.4,1.3C664.9,347.9,665.7,348.9,666.5,350L666.5,350z"
          />
          <path
            fill="#EBEBEB"
            d="M668.4,342.5c1.1,1.1,2,2.1,2.8,3.3c1.3-0.9,2.5-1.4,3.5-1.7c-1.1-1.5-2.4-3-3.8-4.4l-1.8-1.7
	c-0.4,0.9-1.1,2-2,3.3L668.4,342.5L668.4,342.5z"
          />
          <path
            fill="#656565"
            d="M648.5,334.4l0.5,5.4c3.3,0.2,6.4,1.2,9.2,2.7l3.7-4.2l0.7-0.5c-4.2-2.6-9.1-4-14.3-4.3L648.5,334.4
	L648.5,334.4z"
          />
          <path
            fill="#EBEBEB"
            d="M646.7,329.5l1.9,18.7l0.9-1.8l-0.6-6.6l-0.5-5.4l-0.4-0.9"
          />
          <path
            fill="#EBEBEB"
            d="M644.6,333.4c0,0.8,0,1.9,0.1,3.1l2.7,12.6l1.2-1.1l-1.9-18.7C645.4,329.8,644.7,331.1,644.6,333.4
	L644.6,333.4z"
          />
          <path
            fill="#EBEBEB"
            d="M648.1,333.4c5.3,0.2,10,1.7,14.3,4.2l3.4-2.4c-5.7-3.8-11.9-5.8-19.1-5.8L648.1,333.4L648.1,333.4z
	 M658.2,342.5l-4.2,5.1l-0.2,2l12.2-14.2l-3.4,2.4l-0.7,0.5"
          />
          <path
            fill="#EBEBEB"
            d="M649.5,346.4l-0.9,1.8l-1.2,1.1l6.6,1.8l-0.4-1.4l0.2-2 M658.2,342.5c-2.8-1.5-5.9-2.5-9.2-2.7l0.6,6.6
	l4.4,1.1L658.2,342.5L658.2,342.5z M646.4,355.3c-1.5,0-3,0.4-4.4,1.1l0.1,0.6c0,0.7-0.2,1.4-0.8,2c-0.6,0.6-1.2,0.8-2,0.8l-0.8-0.1
	c-0.8,1.3-1.4,2.7-1.5,4.4c0.4,0.1,0.6,0.4,0.9,0.6c0.6,0.6,0.8,1.2,0.8,2c0,0.7-0.2,1.4-0.8,1.9l-0.1,0.1c0.5,1.1,1.1,1.9,2,2.8
	l1.4,1.2c0.5-0.5,1.2-0.7,1.9-0.7s1.4,0.2,2,0.8c0.4,0.5,0.7,0.9,0.7,1.4h0.7c1.5,0,3-0.4,4.2-1.1l-0.1-0.7c0-0.7,0.2-1.4,0.8-2
	c0.6-0.6,1.2-0.8,1.9-0.8l0.9,0.2c0.8-1.4,1.3-3,1.4-4.6c-0.6-0.1-1.1-0.4-1.5-0.8c-0.6-0.5-0.8-1.2-0.8-2c0-0.7,0.2-1.4,0.8-2
	l0.4-0.2c-0.5-0.7-0.9-1.4-1.5-2.1c-0.5-0.5-1.1-0.9-1.7-1.3c-0.6,0.6-1.2,0.8-2,0.8c-0.7,0-1.4-0.2-2-0.8c-0.5-0.4-0.7-0.9-0.8-1.4
	L646.4,355.3L646.4,355.3z M650,360.9c1.1,1.1,1.5,2.2,1.5,3.8c0,1.4-0.5,2.7-1.5,3.7c-1.1,1.1-2.2,1.5-3.8,1.5
	c-1.4,0-2.7-0.5-3.7-1.5l-0.7-0.8c-0.6-0.8-0.8-1.8-0.8-3l0.1-1.1c0.1-0.5,0.2-0.8,0.5-1.2c0.2-0.6,0.6-1.1,1.1-1.4
	c0.6-0.6,1.2-1.1,1.9-1.3l1.3-0.2h0.5c1.3,0,2.4,0.4,3.4,1.2L650,360.9L650,360.9z"
          />
          <path
            fill="#EBEBEB"
            d="M649.7,360.6c-0.9-0.8-2.1-1.2-3.3-1.2h-0.5l-1.3,0.2c-0.7,0.2-1.4,0.7-1.9,1.3c-0.5,0.5-0.8,0.9-1.1,1.4
	c-0.2,0.4-0.4,0.8-0.5,1.2l-0.1,1.1c0,1.1,0.2,2,0.8,3l0.7,0.8c1.1,1.1,2.2,1.5,3.7,1.5s2.7-0.5,3.8-1.5c1.1-1.1,1.5-2.2,1.5-3.7
	c0-1.4-0.5-2.7-1.5-3.8L649.7,360.6L649.7,360.6z M646.9,353.3c-0.2,0.5-0.5,0.9-0.5,1.5v0.5c0.1,0.6,0.4,1.1,0.8,1.4
	c0.6,0.6,1.2,0.8,2,0.8c0.7,0,1.4-0.2,2-0.8c0.5-0.5,0.7-1.2,0.7-1.9v-0.1c0-0.7-0.4-1.3-0.8-1.9c-0.6-0.5-1.2-0.8-2-0.8
	c-0.7,0-1.4,0.2-2,0.8L646.9,353.3L646.9,353.3z M654.5,360.1l-0.4,0.2c-0.6,0.6-0.8,1.2-0.8,2c0,0.8,0.2,1.4,0.8,2
	c0.5,0.5,0.9,0.7,1.5,0.8h0.5l1.2-0.2c0.2-0.1,0.6-0.4,0.8-0.6c0.6-0.5,0.8-1.2,0.8-2c0-0.7-0.2-1.4-0.8-2c-0.5-0.5-1.1-0.7-1.8-0.7
	h-0.2C655.6,359.5,655,359.6,654.5,360.1L654.5,360.1z M661.2,364.7l1.2-0.9l1.9-0.8l-0.7-4.4l-2-0.1l-1.4-0.5"
          />
          <path
            fill="#EBEBEB"
            d="M655.7,365.1c-0.1,1.8-0.6,3.3-1.4,4.6c0.4,0.1,0.7,0.4,1.1,0.6l0.1,0.2l13.8,4.2c0.7-1.5,1.2-3.1,1.5-4.6
	l-13.3-5.3l-1.2,0.2L655.7,365.1L655.7,365.1L655.7,365.1z M661.2,364.7l12.3,4.1c1.4,0.4,2.6,0.5,3.7,0.5c2,0.1,3.4-0.5,4-1.5
	l-18.9-4.1L661.2,364.7z M655.8,371.1c0.2,0.4,0.2,0.7,0.2,1.2c0,0.7-0.2,1.4-0.8,1.9c-0.6,0.6-1.2,0.8-2,0.8
	c-0.7,0-1.3-0.2-1.9-0.7l2.1,14.4c0.4,2.5,0.8,4.5,1.3,6.1c0.6,1.5,1.3,2.7,2,3.4c1.3-0.4,2.5-0.8,3.7-1.4c-1.2-0.1-2.2-1.1-3.2-2.8
	c-0.5-0.9-0.9-2.1-1.4-3.5l-2.5-12.8l5.1-4.6l12.3,4.4c1.3,0.6,2.4,1.3,3.2,1.9c1.7,1.2,2.5,2.4,2.4,3.5c0.7-1.1,1.3-2.2,1.9-3.4
	c-0.6-0.9-1.5-1.8-3.1-2.5c-1.5-0.8-3.4-1.5-5.9-2.2l-13.8-4.2l0.1,0.1L655.8,371.1L655.8,371.1z"
          />
          <path
            fill="#EBEBEB"
            d="M655.6,370.6l-0.1-0.1l-0.1-0.2c-0.4-0.2-0.7-0.5-1.1-0.6l-0.9-0.2c-0.7,0-1.4,0.2-1.9,0.8
	c-0.6,0.6-0.8,1.2-0.8,2l0.1,0.7c0.1,0.5,0.4,0.9,0.7,1.3l0.1,0.1c0.5,0.5,1.1,0.7,1.9,0.7c0.7,0,1.4-0.2,2-0.8
	c0.5-0.6,0.8-1.2,0.8-1.9c0-0.5-0.1-0.8-0.2-1.2L655.6,370.6L655.6,370.6z M673.7,351.7l0.7-0.9l2.6-3.3l-15.5,11l2,0.1l5.7-3.9"
          />
          <path
            fill="#EBEBEB"
            d="M663.6,358.6l0.6,4.5l7.1,1.4c0-3.4-0.7-6.7-2-9.7L663.6,358.6L663.6,358.6z"
          />
          <path
            fill="#656565"
            d="M673.7,351.7l-4.4,3c1.3,3,2,6.3,2,9.7l4.8,0.9l1.4,0.7v-1.4c0-5.1-1.1-9.7-3.1-13.8L673.7,351.7
	L673.7,351.7z"
          />
          <path
            fill="#EBEBEB"
            d="M671.3,364.5l-7-1.5l-1.9,0.8l18.8,4.1l-3.7-1.9l-1.4-0.6 M676.8,371.9c-0.4,1.8-0.9,3.4-1.7,5
	c1.5,0.7,2.5,1.5,3.1,2.5c1.2-2.4,2-5,2.5-7.7L676.8,371.9z"
          />
          <path
            fill="#656565"
            d="M670.8,370.1c-0.4,1.5-0.8,3.2-1.5,4.6c2.5,0.7,4.4,1.4,5.9,2.2c0.7-1.7,1.2-3.3,1.7-5
	C675.2,371.7,673.2,371.1,670.8,370.1L670.8,370.1z"
          />
          <path
            fill="#EBEBEB"
            d="M672.3,382.1l4,0.8l-17.6-8.4l1.1,1.8l6.5,3.3l4.4,2.1"
          />
          <path
            fill="#656565"
            d="M666.3,379.6c-0.7,0.9-1.5,1.9-2.4,2.7c-1.7,1.7-3.4,3-5.2,4.1l1.5,4.6l0.1,1.7c3-1.4,5.5-3.4,8-5.9
	c1.5-1.4,2.8-3,3.9-4.6l-1.7-0.4L666.3,379.6L666.3,379.6z"
          />
          <path
            fill="#EBEBEB"
            d="M666.3,379.6l-6.6-3.3l-3.3,3.1l2.2,7c1.9-1.1,3.5-2.5,5.2-4.1C664.9,381.5,665.6,380.5,666.3,379.6
	L666.3,379.6z M674,379.2c-0.8-0.6-1.9-1.3-3.2-1.9l-12.3-4.4l0.2,1.5l17.6,8.3C676.5,381.7,675.8,380.4,674,379.2L674,379.2z
	 M672.3,382.1c-1.1,1.7-2.4,3.2-3.9,4.6c-2.5,2.5-5.1,4.5-8,5.9l0.4,4.1c3.8-1.8,7.2-4.1,10.4-7.3c2-2,3.8-4.3,5.2-6.5L672.3,382.1
	L672.3,382.1z M645.6,374.5c0,0.4-0.1,0.7-0.2,1.1l3.3,13.9c1.7-0.1,3.3-0.5,4.7-0.8l-2.1-14.4l-0.1-0.1c-0.4-0.4-0.6-0.8-0.7-1.3
	c-1.3,0.7-2.7,1.1-4.2,1.1h-0.7L645.6,374.5L645.6,374.5z"
          />
          <path
            fill="#EBEBEB"
            d="M645.6,373.9c-0.1-0.6-0.4-1.1-0.7-1.4c-0.6-0.6-1.2-0.8-2-0.8c-0.7,0-1.3,0.2-1.9,0.7l-0.1,0.1
	c-0.5,0.5-0.7,1.1-0.8,1.6v0.2c0,0.7,0.2,1.4,0.8,1.9c0.6,0.6,1.2,0.8,1.9,0.8s1.4-0.2,2-0.8c0.2-0.2,0.5-0.6,0.6-0.9
	c0.1-0.4,0.2-0.7,0.2-1.1L645.6,373.9L645.6,373.9z"
          />
          <path
            fill="#EBEBEB"
            d="M644.8,376.4c-0.6,0.6-1.2,0.8-2,0.8c-0.7,0-1.4-0.2-1.9-0.8c-0.6-0.6-0.8-1.2-0.8-1.9v-0.2l-10.6,8.6
	l-0.2,0.1c-2,1.5-3.5,3-4.6,4.1c-1.1,1.2-1.8,2.4-2,3.3l3.2,2.6c-0.6-1.1-0.4-2.5,0.8-4.2c0.5-0.8,1.2-1.7,2.1-2.6l9.7-8.6l6.6,2
	l2.4,12.8c0.1,1.3,0.1,2.5,0.1,3.4c-0.2,2.1-0.8,3.4-2,3.8l-1.4-3.9c-5.4-0.4-10.3-2-14.6-4.8L626,393c5.9,4.4,12.8,6.5,20.4,6.5
	l3.1-0.1c0.4-0.9,0.6-2.2,0.5-3.9c-0.1-1.8-0.5-3.8-1.1-6.1l-3.3-13.9C645.4,375.8,645.2,376.2,644.8,376.4L644.8,376.4z
	 M658.5,373.1l-5.1,4.6l1.4,0.5l1.7,1.3l3.3-3.2l-1.1-1.8"
          />
          <path
            fill="#EBEBEB"
            d="M660.8,396.7l-0.4-4.1l-0.1-1.5l-1.5-4.7l-2.2-6.8l-1.7-1.3"
          />
          <path
            fill="#EBEBEB"
            d="M657.5,393.9c0.9,1.8,2,2.7,3.2,2.8h0.1l-5.8-18.5l-1.4-0.5l2.5,12.8C656.5,391.9,657,393,657.5,393.9
	L657.5,393.9z"
          />
          <path
            fill="#656565"
            d="M648.8,389.5c0.7,2.4,1.1,4.5,1.1,6.1c1.8-0.2,3.4-0.5,5.1-0.9c-0.6-1.5-1.1-3.7-1.3-6.1
	C652,389,650.5,389.4,648.8,389.5L648.8,389.5z"
          />
          <path
            fill="#EBEBEB"
            d="M644.9,379.6l-1.2,0.9l1.7,19c1.2-0.4,1.8-1.7,2-3.8c0.1-0.9,0-2.1-0.1-3.4L644.9,379.6L644.9,379.6z
	 M615.5,359.6l2.8,0.4l12.4,3.8l1.2,6.7l-9.6,8.7c-0.9,0.7-1.9,1.3-2.7,1.7c-2,1.1-3.4,1.2-4.4,0.2l2.6-3.3c-1.9-4-2.8-8.4-2.8-13.2
	v-1.4l-3.8-1.9l-0.1,3.2c0,7.6,2.1,14.3,6.4,20.2c1.1-0.2,2.2-0.8,3.5-1.8c1.4-0.9,3-2.4,4.7-4.1L636,369c-0.7,0-1.4-0.2-2-0.8
	c-0.6-0.6-0.8-1.2-0.8-1.9c0-0.7,0.2-1.4,0.8-2c0.2-0.2,0.6-0.5,0.9-0.6l-13-4.6l-6-1.9l-3.9-0.2c-0.2,1.4-0.5,3-0.6,4.4
	C611.8,360.1,613.1,359.5,615.5,359.6L615.5,359.6z"
          />
          <path
            fill="#EBEBEB"
            d="M611.4,361.4l18.3,3.5l1.1-1.1l-12.4-3.8l-2.8-0.4C613.1,359.5,611.8,360.1,611.4,361.4L611.4,361.4z
	 M612,357l3.9,0.2c0.4-1.7,0.9-3.3,1.5-4.8c-1.5-0.7-2.6-1.3-3.3-2.1C613.3,352.4,612.5,354.7,612,357L612,357z"
          />
          <path
            fill="#656565"
            d="M623.6,354.3c-2.5-0.6-4.4-1.2-6-1.9c-0.7,1.5-1.2,3.2-1.5,4.8l6,1.9C622.3,357.4,622.9,355.9,623.6,354.3
	L623.6,354.3z"
          />
          <path
            fill="#EBEBEB"
            d="M621.9,359.2l13,4.6l1.1-0.2c0.4,0,0.7,0.1,1.1,0.2c0.1-1.5,0.6-3.1,1.5-4.4c-0.4-0.1-0.7-0.4-1.1-0.7
	l-0.6-0.7l-13.3-3.8C622.9,355.9,622.3,357.4,621.9,359.2z"
          />
          <path
            fill="#656565"
            d="M621.3,364.6l-5.5-0.9l-0.6-0.4v1.4c0,4.8,0.9,9.2,2.8,13.2l0.6-0.7l4.5-3.2
	C621.9,371.1,621.3,368,621.3,364.6L621.3,364.6z"
          />
          <path
            fill="#EBEBEB"
            d="M621.3,364.6c0,3.3,0.6,6.5,1.8,9.3l5.4-3.8l-0.7-4.5L621.3,364.6L621.3,364.6z"
          />
          <path
            fill="#EBEBEB"
            d="M615.8,363.6l5.5,0.9l6.5,1.2l1.8-0.8l-18.2-3.5l3.8,1.9 M633.6,355.5l4.8-4.7l-1.4-0.5l-1.7-1.2l-3.2,3.2
	l1.1,1.8 M637.4,354.8c-0.6,0.6-0.8,1.2-0.8,2c0,0.5,0.1,0.8,0.2,1.2l0.6,0.7c0.4,0.4,0.7,0.6,1.1,0.7l0.8,0.1c0.7,0,1.4-0.2,2-0.8
	c0.6-0.6,0.8-1.2,0.8-2l-0.1-0.6c-0.1-0.6-0.4-1.1-0.7-1.4c-0.2-0.2-0.4-0.4-0.6-0.5c-0.4-0.2-0.8-0.4-1.3-0.4
	C638.6,354.1,638,354.3,637.4,354.8L637.4,354.8z M636,363.6l-1.1,0.2c-0.4,0.1-0.6,0.4-0.9,0.6c-0.6,0.6-0.8,1.2-0.8,2
	c0,0.7,0.2,1.4,0.8,1.9c0.6,0.6,1.2,0.8,2,0.8c0.7,0,1.3-0.2,1.8-0.7l0.1-0.1c0.6-0.6,0.8-1.2,0.8-1.9c0-0.7-0.2-1.4-0.8-2
	c-0.2-0.2-0.6-0.5-0.9-0.6C636.7,363.6,636.3,363.6,636,363.6L636,363.6z"
          />
          <path
            fill="#EBEBEB"
            d="M637.7,368.5c-0.5,0.5-1.2,0.7-1.8,0.7l-10.2,9.8c0.8,1.2,1.8,2.2,3,3.4l0.7,0.7l0.2-0.1l10.6-8.6
	c0.1-0.6,0.4-1.2,0.8-1.6l0.1-0.1l-1.4-1.2C638.8,370.4,638.2,369.4,637.7,368.5L637.7,368.5z M628.5,370.1h2l1.4,0.5l-1.2-6.7
	l-1.2,1.1l-1.8,0.8"
          />
          <path
            fill="#EBEBEB"
            d="M623.1,374l-4.5,3.2l-0.6,0.7l-2.6,3.3l15.1-11.1h-2"
          />
          <path
            fill="#EBEBEB"
            d="M630.5,370.1l-15.2,11.1c0.8,0.9,2.2,0.8,4.4-0.2c0.8-0.4,1.7-0.9,2.7-1.7l9.6-8.7L630.5,370.1L630.5,370.1z
	"
          />
          <path
            fill="#656565"
            d="M628.6,382.3c-1.1-1.1-2-2.2-3-3.4c-1.7,1.8-3.3,3.2-4.7,4.1c0.9,1.3,2,2.5,3.2,3.7l0.5,0.5
	c1.2-1.3,2.7-2.7,4.6-4.1L628.6,382.3L628.6,382.3z"
          />
          <path
            fill="#EBEBEB"
            d="M625.9,393.2l3.4-2.2l1.1-0.7l3.4-3.9l4.6-5.2l0.2-2"
          />
          <path
            fill="#EBEBEB"
            d="M642.7,382.4l1.1-1.8l1.2-1.1l-6.5-2l0.2,1.5l-0.2,2"
          />
          <path
            fill="#EBEBEB"
            d="M638.4,381.1l-4.6,5.2c2.8,1.7,6,2.7,9.4,3.2l-0.5-7.1L638.4,381.1L638.4,381.1z"
          />
          <path
            fill="#EBEBEB"
            d="M643.3,389.5l0.2,5l0.5,1.3l1.4,3.9l-1.7-19l-1.1,1.8 M638.4,377.7l-9.7,8.6c-0.8,0.9-1.5,1.8-2.1,2.6
	c-1.2,1.8-1.4,3.2-0.8,4.2h0.1l12.8-14L638.4,377.7L638.4,377.7z"
          />
          <path
            fill="#656565"
            d="M643.3,389.5c-3.4-0.4-6.6-1.4-9.4-3.2l-3.4,3.9l-1.1,0.7c4.4,3,9.2,4.5,14.6,4.8l-0.5-1.3L643.3,389.5
	L643.3,389.5z"
          />
          <path
            fill="#EBEBEB"
            d="M624.3,386.7c-1.2-1.2-2.2-2.4-3.2-3.7c-1.3,0.9-2.5,1.5-3.5,1.8c1.2,1.5,2.5,3.1,4,4.6l1.1,1.1
	c0.4-1.1,1.1-2.1,2-3.3L624.3,386.7L624.3,386.7z M625.9,393.2l-0.1-0.1L625.9,393.2z M649.4,399.5c2.6-0.2,5.2-0.7,7.6-1.4
	c-0.8-0.7-1.5-1.8-2-3.4c-1.7,0.5-3.3,0.7-5.1,0.9C650,397.3,649.9,398.6,649.4,399.5z M660.6,396.7h0.1H660.6z M412.2,181.9
	c-0.9,2-1.1,5.6-0.2,10.7l5.5,38.8c1.1,5,3.1,7.6,5.8,7.9l105.9,3v-1.9l-1.7-0.7c-2,0.1-4-0.1-6-0.6l-0.9-0.4
	c-2.8-1.7-4.2-4.6-4.1-8.9V229c0.8-5.4,1.9-9.3,3.4-11.9c1.2-1.5,2.7-2.2,4.7-2.1l-0.7-2.6c-5.3-4.5-11-8.6-17.1-12.5
	c-1.1-0.7-2.2-1.4-3.4-2l-0.4-0.2c-6.4-3.8-13.7-6.9-21.8-9.3c-13.6-3.4-27.7-5.7-42.6-6.7l-23.5-0.9
	C413.9,180.4,412.9,180.9,412.2,181.9L412.2,181.9z M500.6,182l-1.4,1.7l3.9,2.1c7.3,4,14.6,8.6,21.8,13.8
	c10.4,7.6,20.9,16.5,31.2,26.7c3.3,3.3,6.6,6.7,10,10.3h5.8c-3.7-3.8-7.3-7.3-11.1-10.9c-10.3-9.7-20.9-18.3-31.9-26.1
	c-8.5-6-17.1-11.5-26-16.3C502.3,182.9,501.5,182.5,500.6,182L500.6,182z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M698.8,264.8h17.7 M716.7,266.7l-0.8-0.5l-17.2,0.2 M717.2,272.8l-0.8,0.5
	h-12 M732.4,282.1l-2.4,2.6 M732.9,285.2c1.2-3.4,2.7-5.4,4.8-5.8 M736.2,268.1l-6.5-1.1 M746.5,279.5l-8-0.1 M738.5,279.3
	l-0.4-11.6 M745.9,266.8c-0.1,0.8-0.7,1.2-1.9,0.9 M738.5,279.3l-0.7,0.1 M738.1,267.8h5.8 M738.1,267.8l-1.9,0.4 M729.1,278.7
	l-3.7-0.2 M727.6,266.6c-1.7,0.6-3,2.5-4,5.5 M729.1,278.7l0.6-11.7 M727.6,266.6l-3.9-0.7 M720,281.6c-1.8-2-2.7-5-2.8-8.6
	 M723.6,272.3c0.1,1.7,0.5,3.3,0.8,4.7c0.4,0.7,0.7,1.2,0.9,1.5 M732.8,279.1l-3.7-0.4 M732.8,279.1c-2.5-1.4-3.3-3.4-2.5-6
	c0.9-2.5,3-4.1,5.9-5 M737.7,279.5l-5-0.4 M725.5,278.5c0.6,0.6,1.2,0.9,1.9,0.9l5,0.9v1.7 M729.7,267.1v-0.6l-2.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M732.4,282.1l-7.3-0.8c-2.2-1.4-3.3-3.9-3.4-7.2v-8.5
	c0.1-2.6,1.1-3.8,3-3.7 M716.7,266.7l2.5,0.9v4.1l-2,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M723.7,265.9l-0.1,1.4c-0.1,1.7-0.1,3.3,0,4.8 M743.9,267.8l-1.4-0.4
	c-0.1,0-0.1-0.1-0.2-0.1h-0.1c-4.4-1.7-9.8-2.8-16.3-3.4c-1.2,0.1-1.9,0.8-2.1,2.1 M724.5,261.9l-0.6-0.1c-5.1-0.9-7.4,0.7-7.2,4.8
	 M745.4,266.2c-6.7-2-13.7-3.4-20.5-4.4h-0.4 M69.2,257.6l-0.1,16.5 M64.7,257.6l-0.1,17 M69.4,281.2h-8.7l-0.7,2.4 M57.6,282.5
	l2.4,1.1 M60.1,271.1c-0.4,1.5-1.1,2.4-2.1,2.5 M64.6,274.6l4.5-0.5 M60,283.6l1.5,10h6.9l2.2-10.9l-0.5-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M64.6,277.2v0.2l-1.3,2.1l-5.9-0.1c1.9-0.1,2.7-0.7,2.6-2v-6.4
	 M64.6,274.6v2.6 M70.1,281.3v-0.1h-0.7 M70.1,281.3l-0.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M70.1,281.3l2.4,0.2l1.2-4.4h-9.1 M69.2,257.6h-4.5 M69.1,274.1
	c2.1-0.1,3.3-0.7,3.3-2l3.8-12c0.4-2-0.5-2.8-2.5-2.5h-4.4 M60,250.9c-0.6,0.2-0.9,0.6-1.2,1.2 M60,250.9c0.4-0.2,0.8-0.2,1.3-0.4
	c2,0.1,3.1,2.5,3.4,7.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M60.1,271.1v-11c0.1-1.9-0.1-2.8-0.7-2.8l0.7-6.4 M267.6,336.1
	c0.2-0.7,0.2-1.5,0.1-2.5c-3.7-16.5-9.1-30.3-16.4-41.6c-8.4-11.3-19.1-17.8-32.2-19.6c-11.3-1.9-23.5-3.1-36.4-3.5l-2.1-0.1
	c-6.9-0.2-13.8-0.2-21.1,0c-3.2,0-6.3,0.2-9.1,0.7l-3.2,0.7c-7.4,1.8-13.2,5.4-17.4,11l-1.2,1.7c-0.9,1.5-1.9,3.1-2.8,4.7
	c-6.1,11-11.6,25.4-16.4,43.2l-0.1,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M113.9,332.4c5.3-15.6,11.2-29,17.7-40.3l0.9-1.5
	c5.6-8.7,13.3-13.5,23.4-14.3c7.8-0.6,15.9-0.7,24.6-0.5l21.7,1.3c15.6,1.3,27.2,4.4,34.5,9.3c10.3,6.5,17.9,18.9,23.3,37.2
	c1.7,6.7,4.2,11,7.6,12.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M258.2,340.6c-4.2-15.2-9.3-27.4-15.2-36.6v-0.1
	c-5.9-7.8-12.8-12.8-20.5-14.9l-1.8-0.5c-17.5-3.2-35.3-4.6-53.6-4.5H167c-5.9-0.2-11,0.5-15.2,2.1l-2.1,0.9
	c-0.6,0.2-1.1,0.5-1.5,0.7c-1.1,0.6-1.9,1.3-2.7,2c-1.4,1.2-2.6,2.6-3.7,4.1l-1.9,3l-2,3.1c-1.1,1.8-2,3.5-3.1,5.6l-0.1,0.2
	c-0.9,1.8-1.9,3.8-2.7,5.8c-1.9,4.2-3.7,9-5.3,13.9c-1.1,3-1.9,5.8-2.5,8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M251.1,324.3c-3-7.4-6.1-13.9-9.6-19.4c-5.7-7.4-12.2-12.2-19.7-14.3
	l-1.8-0.5c-17.4-3.2-35.1-4.6-53.3-4.4c-5.7-0.2-10.5,0.5-14.5,2l-3.4,1.7c-0.8,0.5-1.7,1.2-2.5,1.8c-1.3,1.2-2.4,2.4-3.3,3.8l-2,3
	l-2,3c-1.1,1.7-2,3.4-3.1,5.3l-0.1,0.2l-2.7,5.7c-1.7,3.8-3.2,7.9-4.7,12.3 M109.5,331.4c-2.2-0.7-4.2-2.1-5.8-4.2l-15.6-23.7
	c-2.6-4.4-7.4-6.6-14.4-6.6h0.1 M225.9,256.7l-9-1.4c-26-3-49.4-3.4-70-1.4c-18.1,1.7-31.2,7.7-39.4,17.8 M320.3,168.8l-2.2,0.4
	c-1.2,1.4-2,3.1-2.5,5l0.9,1.3 M499.2,183.8l-3.9-1.9l-9.1-3.5l-5.1-1.5c-22.8-5.7-51.5-8.9-86.2-9.7l-12.8-0.4h-7l-34.4,1.1
	l-9.7,0.9c-3,0.1-5.5,0.7-7.8,1.9c-1.3,0.7-2.6,1.4-3.7,2.5c-1.9,1.7-3.3,3.9-4.4,6.5 M316.6,175.4l-0.5,1.9 M316.1,177.3l-0.6,2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315.5,179.7l-0.4,1.4c-1.1,6.6-2,13.5-2.8,20.4
	c-1.2,10.3-2.2,21-3.1,32.1l-0.1,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.1,177.3c-0.8,0.2-2.1,5-3.9,14.3s-3.4,23.7-5.1,43.3 M316.6,175.4
	c0.7-2.8,2-5.1,3.8-6.6 M349.9,164.6c-1.2-1.4-3.1-2.6-5.5-3.5c-0.7-0.4-1.5-0.6-2.5-0.8l-3.9-1.4c-2.5-0.8-5.1-2-5.1-2
	c-1.2,0.6-1.8,1.7-1.7,3.1l0.5,3.3l-0.2,0.8c-0.4,0.9-1.1,1.5-2.1,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M329.3,165.7l6.9-0.9l13.7-0.2 M329.3,165.7l-3.4,0.4
	c-2.2,0.5-4.1,1.3-5.7,2.7 M500.6,182c-1.1-0.6-2.2-1.2-3.4-1.8l-4.7-1.9c-3.5-1.4-7.3-2.7-11.3-3.8c-15.9-4-33.3-6.7-52.2-8.1
	l-42.6-1.7l-17-0.4l-19.5,0.2 M312,302.2c-0.4-6.6-0.6-13.5-0.5-20.5c-0.4-36.5,1.7-67.3,5.8-92.5c2-9.7,7.4-14.3,16.1-14.2h0.6
	c9-0.5,17.6-0.9,35.4-1.1l29.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M333.3,186.3c-0.2,1.1-0.4,2.1-0.5,3.4c-1.2,11.5-1.8,22.2-1.7,32.2
	c-0.6,7,3,11,10.4,12.2c14.9,2.6,29.9,4.2,45,4.7l0.8,0.1c4.6,0.4,6.8-1.7,7-6.1c-0.1-17.8-0.9-34.5-2.6-49.9
	c-0.9-4.2-3.1-6.3-6.3-6c-14.6-0.7-29.5-0.4-44.6,1.1c-3.8,0.9-6.1,3.7-7.3,8.1L333.3,186.3L333.3,186.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M333.3,186.3c-3,7.3-4.6,20.5-4.7,39.6v1.1c0.1,4.6,2.4,7.8,6.7,9.7
	c13.2,4.2,36.7,7,70.3,7.9 M309.1,234.9h-2 M309,236.5l0.1-1.5 M309,236.5h-1.9 M307.1,236.5c-0.5,7.6-0.9,15.4-1.3,23.3
	c-0.1,5.9-0.2,11.8-0.2,17.5c0.1,8.5,0.4,16.9,0.9,25c0.7,9.2,1.5,18.2,2.8,26.9c0.8,5.7,1.8,10.3,3,13.6l0.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M308.4,302.2c-0.6-8.1-0.8-16.5-0.9-25c-0.1-5.7,0-11.6,0.1-17.5
	c0.4-7.9,0.8-15.7,1.3-23.3 M262.5,274.7c-2.1-2.4-4.5-4.6-7.2-6.5 M312,302.2h-3.5 M225.9,256.7c12.3,2.4,22.1,6.1,29.4,11.6
	 M407.7,302.6l-37.3-0.2l-58.5-0.1 M243.2,342.7c-1.4-3.7-3.3-7.1-5.6-10.3 M246.1,351.7c-0.4-1.8-0.8-3.5-1.4-5.3
	c-0.4-1.3-0.8-2.5-1.3-3.7 M220.2,347.5c-0.7-1.2-1.4-2.2-2.1-3.3 M203.3,358l9.8-8.5l2.7-1.7c2-1.1,3.4-1.2,4.2-0.4 M237.7,332.5
	l13.5-8.1 M237.7,332.5c-2.1-3.1-4.5-6-7.3-8.7c-11.3-11.3-25-17-41-17s-29.6,5.7-41,17l-1.9,1.9 M308.4,302.2
	c0.7,9.2,1.5,18.2,2.8,26.9c0.4,2.2,0.7,4.4,1.1,6.3c0.7,3.8,1.7,6.8,2.7,9.1 M316.7,347.4c0.9,1.2,1.9,1.9,3,2.1l33.5,0.2
	 M313,344.5c0.6,2.2,0.4,3.8-0.7,4.4c-0.6,0.4-1.5,0.5-2.7,0.2l-50.2,1.4l-1.2-9.9 M315,344.5h-2 M315,344.5c0.5,1.2,1.1,2,1.7,2.8
	 M258.2,340.6l5.5-1.7c2-0.6,3.3-1.4,3.8-2.7 M258.2,340.6l-1.7,0.5l-1.5-5.2 M261.4,357.9c-2.5-0.7-5-1.5-7.2-2.5 M308.8,360.7
	l3.7,0.7c3.3,0.7,6.7,1.3,10.4,1.8 M217.6,350.8l-0.7,0.9l-4.5,3 M219.9,371.9l4-0.1 M204.4,364.7l12.3,4.1c1.4,0.4,2.6,0.5,3.7,0.5
	c2,0.1,3.4-0.5,4-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M220.7,366v-1.4c0-5.1-1.1-9.7-3.2-13.8 M220.7,366l-1.4-0.6l-4.8-0.9
	 M223.9,371.8c0.5-2.4,0.7-4.7,0.7-7.2c0-6.4-1.4-12-4.4-17.1 M224.3,367.9l-3.7-1.9 M254.2,355.4c-2.5-0.9-4.8-2-7.1-3.1l-1.1-0.6
	 M247.4,362c0-1.2-0.1-2.2-0.2-3.4 M246.8,355.4c-0.1-1.1-0.4-2.1-0.6-3.1l-0.1-0.6 M247.1,358.7l-0.4-3.3 M247.4,366.7V362
	 M219.5,382.9c0.7-1.1,1.3-2.2,1.9-3.4 M221.3,379.5c-0.6-0.9-1.5-1.8-3.1-2.5 M219.5,382.9c0.1-1.2-0.7-2.4-2.4-3.5
	c-0.8-0.6-1.9-1.3-3.3-1.9l-12.2-4.4 M219.5,382.9l-4-0.8 M221.3,379.5c1.2-2.4,2-5,2.5-7.7 M218.2,377c0.7-1.7,1.3-3.3,1.7-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M215.3,390.5c7.1-7.1,10.6-15.7,10.6-25.7c0-10-3.5-18.7-10.6-25.9
	c-0.8-0.8-1.7-1.7-2.6-2.4c-6.6-5.6-14.3-8.4-23.3-8.4l-3.2,0.1c-3.2,0.2-6.3,0.8-9.1,1.9c-5,1.8-9.4,4.6-13.5,8.6
	c-3.1,3.1-5.4,6.4-7.2,10c-1.3,2.8-2.4,5.7-2.8,8.9c-0.5,2.2-0.6,4.6-0.6,7c0,7.6,2,14.3,6,20.2c1.3,2,2.9,3.8,4.7,5.6
	c7.1,7.1,15.7,10.7,25.9,10.7c3.2,0,6.3-0.4,9.2-1.1l1.3-0.4c1.8-0.5,3.5-1.2,5.2-2C208.9,396,212.2,393.5,215.3,390.5L215.3,390.5z
	 M255,335.9c-1.2-4.1-2.5-7.9-3.9-11.6 M255,335.9l-11.8,6.8 M220.2,347.5l-2.6,3.3 M247.4,362l61.4-1.3 M322.7,363.2l-75.3,3.5
	 M247.1,358.7l14.3-0.8 M254.2,355.4h-7.4 M373.1,350.2h-0.6v9.3 M358.5,349.8l2.7,0.1 M354.6,349.8h3.9 M358.5,349.8l0.1,0.2
	l0.2,0.6c0.2,1.1,0.5,2.2,0.5,3.5c0,1.8-0.2,3.4-0.7,4.7l-0.4,0.6c-0.2,0.5-0.5,0.8-0.8,1.1 M361.2,350l11.9,0.2 M354.6,349.8
	l-0.2,0.9c-0.2,1.1-0.5,2.2-0.5,3.5 M354.4,358l2.4-0.1l0.1-0.5c0.2-1.1-0.1-2.1-0.9-3.2l-2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M357.3,360.6l1.8-0.8l0.4-0.1c0.5-0.2,0.9-0.8,1.3-1.8
	c0.5-1.3,0.7-2.8,0.7-4.6c0-0.9-0.1-1.8-0.2-2.6l-0.1-0.8 M354.4,358l0.4,1.1c0.6,1.3,1.2,1.9,1.9,1.9h0.1c0.2,0,0.5-0.1,0.7-0.2
	 M372.5,359.5v6.1 M372.5,359.5c-8,0-14,2.1-18.1,6.1 M372.5,365.7h-18.1 M353,365.7V358 M322.7,363.2l3.3,0.5
	c6.9,0.8,7.1,1.5,14.9,1.8l12.2,0.1 M353,349.7v4.6 M353,349.7l1.5,0.1 M353.9,354.3h-0.8 M354.5,365.7h-1.4 M353,358h1.3
	 M335.7,344.5c-10.6-0.5-17-3.5-19.1-9.2c-2.2-10.3-3.8-21.3-4.5-33.1 M360.1,354.3l-91.5,3.5 M247.4,366.7
	c-0.5,15.1-6.1,28.1-17,38.8c-11.3,11.3-25,17-41,17c-16.1,0-29.6-5.7-41-17c-11.3-11.3-17-24.9-17-41c0-1.9,0.1-3.7,0.2-5.4
	 M353,358l-44.3,2.7 M316.7,347.4l61.9,0.7l170.7,1.2c5.3,0.5,9.2-2.1,11.9-7.8 M335.7,344.5H315 M107.6,345.8l13.1-2.6l-4.5-0.6
	 M128.4,324.7l-0.6,1.7c-1.5,4.4-2.7,8.9-3.3,13.3 M140,334.1l-15.5,5.4 M113.9,332.4l-4.5-0.9 M107.6,345.8l13.5,3
	c1.8,0.1,2.8-0.5,3.1-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M124.1,346.9c-0.2,0-0.4,0.1-0.6,0.4c-0.1,0.1-0.4,0.2-0.6,0.2
	c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c0.1-4,0.7-8,1.5-12 M107.9,339.7c-0.2,2-0.4,4-0.4,6.1 M123.7,334.5l-9.8-2.1
	 M109.5,331.4c-0.7,2.7-1.2,5.4-1.5,8.4 M124.6,339.6c-0.2,2.4-0.5,4.8-0.5,7.2 M124.6,359.2h7.1 M101.3,359.2
	c0.2,1.7,2.2,2.6,6.1,2.8h11.3c3.2-0.1,5.1-0.9,5.9-2.8 M131.7,359.2c0.8-9.2,3.5-17.5,8.3-25"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M77.9,334.7l42.7,3.8l-12.8,1.2 M124.6,359.2h-23.3 M174.3,332.9l0.1,4.3
	 M181.6,350.8l-2.8-12.6c-0.4-0.9-0.7-1.8-1.1-2.5c-1.1-2.2-2.2-3.2-3.4-2.6 M174.4,337.2v0.5l2,5.7 M178.3,331.3
	c-1.4,0.5-2.7,0.9-4.1,1.7 M176.4,343.3l2.1,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M176.4,343.3c-1.7,1.1-3.2,2.2-4.6,3.7c-0.8,0.8-1.7,1.7-2.4,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M169.6,349.6l-5.2-2.5h-0.7 M163.7,347.1c1.2-1.5,2.4-3.1,3.8-4.5
	c2.1-2.1,4.5-3.9,7-5.3 M174.3,332.9c-3.4,1.7-6.6,3.9-9.6,6.9c-2.1,2.1-3.8,4.2-5.3,6.6 M159.4,346.4c-0.5,1.2,0.2,2.5,2.4,3.7
	c0.7,0.5,1.5,0.9,2.6,1.4l12.3,4 M163.7,347.1l-4.2-0.7 M205.6,337.8l-0.7,0.5l-3.5,4.2 M212.4,338.1c-1.1-1.1-2.2-1.9-3.4-2.7
	 M197.2,351l9.4-8.9c0.7-0.8,1.4-1.7,1.9-2.4c1.3-2,1.4-3.4,0.5-4.4 M210.4,341.4l1.2,1.1c1.1,1.1,2,2.1,2.8,3.3 M214.4,345.8
	c-1.4,0.9-3.1,2.4-4.8,4.1 M209.6,350c-0.7-1.1-1.5-2-2.5-3l-1.4-1.3 M201.3,342.5l-4.2,5.1 M205.8,345.7c2-1.5,3.5-3,4.6-4.2
	 M218,344.2c-1.1-1.5-2.4-3-3.8-4.4l-1.8-1.7 M209,335.4l-3.4,2.4 M212.4,338.1c-0.4,0.9-1.1,2-2,3.3 M185.1,333.7
	c-1.8,0.2-3.4,0.6-5.1,1.1 M185.1,333.7c-0.1-1.5-0.1-3,0.2-3.9 M191.3,333.4l-1.4-3.9 M192.1,339.8l-0.6-5.4l-0.2-0.9 M191.8,348.2
	l0.9-1.8 M191.8,348.2l-1.2,1.1 M192.7,346.4l-0.6-6.6 M181.4,341c1.7-0.6,3.4-0.9,5.2-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M180.1,334.8c0.5,1.5,0.9,3.4,1.3,5.8v0.4 M189.9,329.5
	c-1.3,0.2-2,1.5-2.1,3.9c0,0.8,0,1.9,0.1,3.1l2.7,12.6 M186.6,339.8c-0.7-2.4-1.2-4.5-1.4-6.1 M189.9,329.5l1.9,18.7 M189.9,329.5
	l-4.5,0.2 M209,335.4c-5.7-3.8-12-5.8-19.1-5.8 M201.3,342.5c-2.8-1.5-5.9-2.5-9.2-2.7 M191.3,333.4c5.3,0.2,10,1.7,14.3,4.2
	 M197.1,347.6l-4.4-1.2 M196.8,349.6l12.2-14.2 M197.1,347.6l-0.2,2 M180.2,350.3l1.4,0.5 M183.8,354.4c-0.4-0.2-0.8-0.4-1.3-0.4
	c-0.8,0-1.4,0.2-2,0.8s-0.8,1.2-0.8,2c0,0.5,0.1,0.8,0.2,1.2 M185.1,356.2c-0.1-0.6-0.4-1.1-0.7-1.4l-0.6-0.5 M187.6,359.6
	c-0.7,0.2-1.4,0.7-1.9,1.3c-0.5,0.5-0.8,0.9-1.1,1.4c-0.2,0.4-0.4,0.8-0.4,1.2l-0.1,1.1c0,1.1,0.2,2,0.8,3l0.7,0.8
	c1.1,1.1,2.2,1.5,3.7,1.5s2.7-0.5,3.8-1.5c1.1-1.1,1.5-2.2,1.5-3.7c0-1.4-0.5-2.7-1.5-3.8l-0.4-0.4c-0.9-0.8-2.1-1.2-3.4-1.2h-0.5
	L187.6,359.6L187.6,359.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M181.6,359.5l0.8,0.1c0.7,0,1.4-0.2,2-0.8s0.8-1.2,0.8-2l-0.1-0.6
	 M179.9,358l0.6,0.7c0.4,0.4,0.7,0.6,1.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M189.6,355.3h-0.2c-1.5,0-3,0.4-4.2,1.1 M195.2,354.7
	c0-0.7-0.4-1.3-0.8-1.9c-0.6-0.5-1.2-0.8-2-0.8s-1.4,0.2-2,0.8l-0.4,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M190.1,353.3c-0.2,0.5-0.5,0.9-0.5,1.5v0.5 M194.4,356.7
	c0.5-0.5,0.8-1.2,0.8-1.9v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M197.7,360.1c-0.5-0.7-0.9-1.4-1.7-2.1c-0.5-0.5-1.1-0.9-1.7-1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M189.6,355.3c0.1,0.6,0.4,1.1,0.8,1.4c0.5,0.6,1.2,0.8,2,0.8
	c0.7,0,1.4-0.2,2-0.8 M197.4,369.7l-0.9-0.2c-0.7,0-1.4,0.2-1.9,0.8c-0.6,0.6-0.8,1.2-0.8,2l0.1,0.7 M180.1,363.8
	c-0.4-0.1-0.7-0.2-1.1-0.2l-1.1,0.2 M180.9,368.5l0.1-0.1c0.6-0.6,0.8-1.2,0.8-1.9c0-0.7-0.2-1.4-0.8-2c-0.2-0.2-0.6-0.5-0.9-0.6
	 M188.8,373.9c-0.1-0.6-0.4-1.1-0.7-1.4c-0.6-0.6-1.2-0.8-2-0.8c-0.7,0-1.3,0.2-1.8,0.7 M179,369.1c0.7,0,1.3-0.2,1.8-0.7
	 M184.2,372.4l-1.4-1.2c-0.8-0.8-1.5-1.8-2-2.8 M180.1,363.8c0.1-1.5,0.6-3.1,1.5-4.4 M200.5,364.8l0.8-0.6c0.6-0.5,0.8-1.2,0.8-2
	c0-0.7-0.2-1.4-0.8-2c-0.5-0.5-1.1-0.7-1.8-0.7 M204.6,358.5l-1.3-0.5 M199.6,359.5h-0.2c-0.6,0-1.2,0.2-1.7,0.6 M197.7,360.1
	l-0.4,0.2c-0.6,0.6-0.8,1.2-0.8,2c0,0.8,0.2,1.4,0.8,2c0.5,0.5,0.9,0.7,1.4,0.8 M212.4,354.7l-5.7,3.9 M214.4,364.5l-7.1-1.5
	 M213.8,370.1c-0.2-0.1-0.5-0.2-0.7-0.2l-12.8-5 M198.8,365.1h0.5c0.5,0,0.8-0.1,1.2-0.2 M205.5,363.8l-1.1,0.9 M194.6,374.3
	c0.5,0.5,1.1,0.7,1.9,0.7c0.7,0,1.4-0.2,2-0.8s0.8-1.2,0.8-1.9c0-0.5-0.1-0.8-0.2-1.2l-0.2-0.5l-0.1-0.1 M198.6,370.5l-0.1-0.2
	c-0.4-0.2-0.7-0.5-1.1-0.6 M198.8,365.1c-0.1,1.8-0.6,3.3-1.4,4.6 M214.4,364.5c0-3.4-0.7-6.7-2-9.7 M203.3,358l1.1,6.7
	 M206.8,358.6l-2.1-0.1 M207.3,362.9l-0.6-4.4 M207.3,362.9l-1.9,0.8 M201.7,373.1l0.2,1.4 M199.7,379.5l-1.7-1.3 M203,376.3
	l-3.3,3.2 M203,376.3l-1.1-1.8 M201.9,386.3c1.9-1.1,3.5-2.5,5.2-4.1c0.8-0.8,1.7-1.8,2.4-2.7 M215.5,382.1l-1.7-0.4l-4.4-2.1
	 M196.6,377.7l2.5,12.8c0.5,1.4,0.9,2.6,1.4,3.5c0.9,1.8,2,2.7,3.2,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M203.6,392.6l-0.1-1.5l-1.5-4.7 M215.5,382.1c-1.1,1.7-2.5,3.2-3.9,4.6
	c-2.5,2.5-5.1,4.5-8,5.9 M209.5,379.6l-6.5-3.3 M201.9,386.3l-2.2-6.8 M184.2,372.4c-0.6,0.6-0.8,1.2-0.9,1.8 M181.8,379.1l-0.4-1.4
	 M181.5,381.1l0.4-2 M185.9,382.4l-4.4-1.3 M186.9,380.6l-1.1,1.8 M183.3,374.3v0.2c0,0.7,0.2,1.4,0.8,1.9c0.6,0.6,1.2,0.8,1.9,0.8
	s1.4-0.2,2-0.8l0.6-0.9 M193.8,373c-1.3,0.7-2.7,1.1-4.4,1.1h-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M188.6,375.6c0.1-0.4,0.2-0.7,0.2-1.1v-0.6 M193.8,373
	c0.1,0.5,0.4,0.9,0.7,1.3l0.1,0.1 M196.7,388.7c-1.5,0.5-3.1,0.7-4.7,0.8 M187.2,395.8l-0.5-1.3l-0.4-5 M181.5,377.6l6.6,2
	 M188.1,379.6l-1.2,1.1 M188.6,399.7c1.2-0.4,1.8-1.7,2-3.8c0.1-0.9,0.1-2.1,0-3.4l-2.5-12.8 M186.3,389.5l-0.5-7.1 M196.7,388.7
	l-2.1-14.4 M188.6,375.6l3.3,13.9 M201.7,373.1l-5.1,4.6 M198,378.2l-1.4-0.5 M213.8,370.1c-0.4,1.5-0.8,3.2-1.5,4.6 M198.6,370.5
	l13.8,4.2 M157.3,350.3c-0.9,2.1-1.7,4.4-2.2,6.7 M155.2,357c-0.2,1.4-0.5,3-0.6,4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.8,363.9l-12.4-3.8l-2.8-0.4c-2.4-0.1-3.8,0.4-4.1,1.6 M159.1,357.3
	l-3.9-0.2 M164.5,364.6l-5.5-0.9l-0.6-0.4 M154.4,361.4l-0.1,3.2c0,7.6,2.1,14.3,6.4,20.2 M154.4,361.4l3.9,1.9 M158.3,363.3v1.4
	c0,4.8,0.9,9.2,2.8,13.2 M154.6,361.4h-0.1 M166.6,354.3c-2.5-0.6-4.4-1.2-6-1.9 M165.1,359.2c0.4-1.7,0.9-3.3,1.7-4.8 M176.4,354.1
	l-1.1-1.8 M176.4,354.1l0.2,1.4 M173.8,363.9l-1.1,1.1 M172.7,364.9l-1.9,0.8 M178.1,363.8c-0.4,0.1-0.6,0.4-0.9,0.6
	c-0.6,0.6-0.8,1.2-0.8,2c0,0.7,0.2,1.4,0.8,1.9c0.5,0.6,1.2,0.8,2,0.8 M171.6,370.1h2.1 M175.1,370.6l-1.3-6.7 M173.7,370.1l1.4,0.5
	 M170.9,365.8l0.7,4.4 M164.5,364.6c0,3.4,0.6,6.5,1.8,9.4 M178.1,363.8l-13-4.6 M170.9,365.8l-6.4-1.2 M168.9,378.9
	c-1.7,1.8-3.3,3.2-4.7,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M161.1,377.9l0.6-0.7l4.6-3.2 M158.5,381.2c0.8,0.9,2.4,0.8,4.4-0.2
	c0.8-0.4,1.7-0.9,2.6-1.7l9.7-8.7 M172.5,383l-0.7-0.7c-1.1-1.1-2.1-2.2-3-3.4 M172.5,383l0.2-0.2l10.5-8.5 M172.5,390.9l0.9-0.7
	l3.5-3.9 M181.5,377.7l-9.7,8.6c-0.8,0.9-1.5,1.8-2.1,2.6c-1.2,1.8-1.4,3.2-0.8,4.2 M164.2,383c0.9,1.3,2,2.5,3.2,3.7l0.5,0.5
	 M168.9,393l0.1,0.1 M165.8,390.5l3.1,2.6 M165.7,392c0-0.5,0-0.9,0.1-1.5 M168.9,393l0.1,0.1 M165.8,390.5c0.2-1.1,0.9-2.1,2-3.3
	 M160.7,384.8c1.2,1.5,2.5,3.1,3.9,4.6l1.1,1.1 M167.8,387.1c1.2-1.3,2.7-2.7,4.6-4.1 M169,393.2l3.5-2.2 M164.2,383
	c-1.3,0.9-2.5,1.5-3.5,1.8 M160.7,352.3c-1.5-0.7-2.6-1.3-3.3-2.1 M161.1,377.9l-2.6,3.3 M160.7,352.3c-0.7,1.5-1.2,3.2-1.7,4.8
	 M154.4,361.4l18.3,3.5 M166.3,374l5.3-3.9 M173.7,370.1l-15.2,11.1 M159.1,357.3l6,1.9 M185.4,329.8c-2.5,0.2-4.8,0.7-7.1,1.5
	 M159.4,346.4c-0.7,1.2-1.4,2.5-2,3.9 M176.6,355.5l5-4.7 M178.5,349.1l-3.2,3.2 M175.3,352.3l-5.8-2.7 M159.4,346.4l17,7.7
	 M195.2,354.7l10.6-9 M190.6,349.2l6.6,1.8 M190.1,353.3l-3.5-13.5 M180.1,334.8c-0.5-1.5-1.1-2.7-1.8-3.5 M181.4,341l2.5,13.5
	 M179.9,358l-13.3-3.7 M197.2,351l-0.4-1.4 M178.5,349.1l1.7,1.2 M177,386.3c2.8,1.7,6,2.7,9.4,3.2 M181.8,379.1l-12.9,14
	 M177,386.3l4.5-5.2 M180.2,350.3l-5.9-17.4 M209.6,350l-10,9.6 M168.9,378.9l10.2-9.8 M169,393.2c5.9,4.4,12.8,6.5,20.4,6.5
	l3.1-0.1 M193.1,395.6c0.1,1.5,0,3-0.5,3.9 M187.2,395.8l1.4,3.9 M200.1,398.1c-0.8-0.7-1.5-1.8-2-3.4 M203.7,396.7h0.2
	 M203.7,396.7h0.1 M203.8,396.7h0.1 M203.8,396.7h0.1 M200.1,398.1c1.3-0.4,2.5-0.8,3.7-1.4 M192.6,399.5c2.6-0.2,5.2-0.7,7.6-1.4
	 M198.1,394.7c-1.7,0.5-3.3,0.7-5.1,0.9 M203.9,396.7c3.8-1.8,7.2-4.1,10.4-7.3c2-2,3.8-4.3,5.2-6.5 M191.9,389.5
	c0.7,2.4,1.1,4.5,1.1,6.1 M146.7,325.5l-18.3-0.8 M140,334.1c1.9-3,4.1-5.9,6.6-8.6 M198.1,394.7c-0.6-1.5-1.1-3.7-1.4-6.1
	 M172.5,390.9c4.4,3,9.2,4.5,14.6,4.8 M203.9,396.7l-0.4-4.1 M198,378.2l5.8,18.5 M186.9,380.6l1.7,19 M220.2,347.5l-15.6,11
	 M201.9,374.5l17.6,8.4 M214.4,345.8c1.3-0.9,2.5-1.4,3.5-1.7 M219.9,371.9c-1.7-0.2-3.7-0.9-6-1.9 M212.4,374.7
	c2.5,0.7,4.4,1.4,5.9,2.2 M205.5,363.8l18.9,4.1 M307.1,234.9H56.3l-0.2,1.5 M55.7,239.8v-0.1l0.4-3.2 M55.7,239.8l2.8,6l-0.9,5.4
	 M57.6,251.2L54,258c-1.2,1.9-1.2,3.4,0,4.8l2.4,2.8 M58.6,295.5c-0.5-4.5-0.7-8.9-0.9-13l-0.1-3.1l-0.1-3.3c0-3.3,0-6.5,0.1-9.6
	c0.2-5.2,0.6-10,1.3-14.5l0.9-5.2l18.2,7.1c2.4,1.5,3.1,3.5,2.1,6.1c-3.4,9.7-6,21.6-7.8,35.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M73.8,296.7c1.7-12.9,3.9-23.9,6.8-33.1l1.5-4.4c1.2-3.1,0.2-5.3-2.9-6.8
	l-23.5-12.6 M72.6,296.7h1.2 M73.8,296.7c-1.2,8,0.1,19.8,3.5,35.4l0.1,0.7l0.5,1.9 M68.4,300.6h-5 M73.6,337.2H74
	c0.2-0.1,0.4-0.2,0.5-0.5c0.1-0.2,0.1-0.5,0-0.7c-3.4-8.3-5.1-19.8-5.1-34.7c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.1-0.4-0.2-0.6-0.2h-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M73.6,337.2h-0.1c-0.2-0.1-0.4-0.2-0.5-0.5c-3.4-8.4-5.2-20.2-5.2-35.3
	c0-0.2,0.1-0.5,0.2-0.6l0.4-0.2 M63.4,298.2l8.5,0.4 M63.4,300.6v-2.4 M71.9,298.6l0.4-3 M58.6,295.5l2.2,2.7 M60.8,298.2v2.4
	 M60.8,300.6h-6.5c-2-0.2-3.5,0.8-4.7,3.4v26.9 M56.2,265.6c-0.5,10.3,0.4,20.2,2.4,29.9 M72.3,295.6l-13.7-0.1 M63.4,300.6h-2.6
	 M60.8,298.2h2.6 M57.6,251.2c-0.7,4.8-1.2,9.7-1.4,14.4 M49.6,331.1v3.7 M49.6,334.7v7.1c-0.1,1.7,0.9,2.7,3.3,3.1h10.5l-0.9-6
	 M69.5,336.7l-9.6-1.2l-2.2-0.9 M57.7,334.6l-8.1-3.5 M57.2,338.1l-7.6-3.4 M62.3,338.9l-5.2-0.7 M57.2,338.1l0.6-3.5 M84.3,338.7
	c4.1,2.8,8.5,4.4,12.9,4.5l18.9-0.6 M73.6,337.4v-0.2 M78.3,338l-2.1-3.5 M76.2,334.5l1.8,0.2 M73.6,337.4l4.7,0.6 M76.2,334.5
	l-0.4-1.8c-3.3-14.8-4.6-26.1-3.9-34.1 M73.8,340.6l-0.2-3.2 M73.6,337.2l-3.9-0.5 M69.5,336.7l4,0.7 M84.3,338.7l-6-0.7
	 M73.8,340.6l0.6,10.3c0.4,5.9,3.3,8.7,8.9,8.3h18.1 M62.3,338.9l11.5,1.8 M56.1,236.5h251 M116.2,342.6l-31.9-3.9 M757.3,267.5V262
	c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.5 M755.7,261.9v-2.5l-1.7-3.8
	l-9.7-5.9 M757.3,289c2.4,0.1,3.5-0.6,3.4-2l-1.7-17.7c-0.2-1.2-0.8-1.8-1.8-2 M761.4,302.5l-0.7-8.3c0-1.9-0.9-2.7-3-2.5
	 M757.7,291.6l-0.4-1.9V289"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M763.6,305.4l-0.2,0.1l-0.7-0.1l-2.5-1.7l-0.2-0.2
	c-2.1-3.7-3.5-8.3-4.3-13.7v-28"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M763.6,305.4l0.2-0.2c0.1-0.2,0.1-0.5,0.1-0.7s-0.2-0.4-0.4-0.5l-2.4-1.5
	 M757.7,291.6c0.7,4.1,2,7.8,3.7,10.9 M757.3,267.5V289 M744.4,249.7l-0.4-0.2c-30.9-8.3-72.1-13.5-123.9-15.9
	c-16.6-0.7-28.1,0.4-34.2,3.1 M725.9,260.3l-0.5-1.4 M717.8,280.5c0.7,0.4,1.5,0.7,2.2,0.9c3.2,1.3,6.5,2.4,10,3.1l2.8,0.6l2.6,0.5
	 M743.7,263.4l-0.1,1.2 M743.5,264.6c-0.6-0.4-1.3-0.6-2-0.8c-5-1.7-10.2-2.7-15.6-3.4 M743.5,264.6c0.8,0.5,1.4,1.1,1.9,1.7
	l0.4,0.5c0.5,0.7,0.7,1.7,0.7,2.6v10c0.1,1.2-0.6,1.9-1.9,2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M743.7,263.4c3,0.9,4.7,2.6,5.2,5.1v10.2c0.1,2.1-0.6,3.3-2,3.7h-2.1
	 M744.6,281.3H739l-3.5,4.4 M735.5,285.7l3.9,2.4l2.8,0.4 M742.2,288.4l4.4,0.5c2.6,0.9,4.6,2.4,6,4.2l0.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M752.9,294c-1.2-1.1-3-1.7-5.5-1.9h-6.5c-2,0.4-3.3,2.1-3.9,5.2v29.3
	c-0.1,3.7,2,6.7,6.5,9.1l9.7,4.5 M749.7,303.5l-1.9-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M741.2,303c0.1-0.9,1.3-1.4,3.4-1.4h10.7 M747.8,303.4l-6.6-0.5
	 M708.9,336.5l14.3-6.7c4.4-2.4,6.7-6.3,7.3-11.6l0.9-15.1c0.6-8.1-2.8-14.2-10.2-18.2l-5.5-2.6 M742.2,288.4l2.4-7.1 M725.9,260.3
	l-9.7-0.6c-8.4-0.4-13.7,0.7-16.2,3.1c-0.6,0.6-1.1,1.3-1.3,2.1l-0.1,1.5v0.1c0,0.5,0,0.9,0.1,1.4c0.2,0.9,0.7,1.8,1.4,2.5l1.4,1.2
	l2.6,1.8c0.1,0,0.2,0.1,0.2,0.1l13.2,7.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M715.8,282.3l-2.8-1.4c-3.3-1.7-6-3.2-8-4.6l-2.8-2l-3.5-3l-1.3-1.5
	c-0.4-0.6-0.7-1.2-0.9-1.8c-0.7-1.8-0.7-3.5,0.1-5.2 M715.8,282.3L703.4,293 M703.4,293c-2-2.7-4.1-5.2-6.4-7.4
	c-5.3-5.2-14.3-8.5-27-10.2c-17.7-2.1-35.9-3-54.6-2.5c-11.6,0.7-19.8,3.7-25,9c-1.2,1.2-2.2,2.5-3.1,3.9l-4.4,8
	c-3.2,6.5-6.4,14.3-9.4,23.3c-2.1,6.4-4.1,13.3-6.1,20.9l-4.4,15.5 M725.5,258.9c8.1,0.9,14.2,2.4,18.2,4.4 M717.8,280.5l-2,1.8
	 M696.5,262.8c2.4-3.9,9-5.4,19.8-4.6l9.1,0.7 M725.5,258.9c-5.2-4.4-18.3-9-39.2-13.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M686.1,245.1c12.4,2,24,4.1,34.8,6.5c9.9,2.8,17.5,6.7,22.7,11.7
	 M743.7,263.4l0.7-13.7 M755.3,301.5c-0.1,1.2-1.9,1.8-5.6,1.9 M755.3,301.5l-2.5-7.6 M710.3,340.9l-1.4-4.4 M699.8,341.8l10.5,0.1
	 M710.3,341.9v-1.1 M718,329.2c0,1.3-0.8,2.2-2.5,2.8l-7.2,2.8 M708.9,336.5l-0.6-1.8 M708.3,334.7c-3.4-10.3-7-18.7-10.5-25
	c-5.2-9.4-12.5-15.2-22-17.2c-16.9-2.6-33.1-3.8-48.6-3.4h-0.8c-16.6,0-27.7,6.3-33.3,18.9c-1.3,2.8-2.7,5.9-3.9,9.2
	c-2.8,7.1-5.4,15.1-7.8,24.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M699.8,341.8c-2.8-6.6-7-12.6-12.5-18.2c-11.3-11.3-25-17-41-17
	c-16.1,0-29.6,5.7-41,17c-4.6,4.6-8.4,9.7-11.1,15.1l-1.3,2.7 M747.8,303.4c1.2,1.5,2,3.4,2.6,5.5v0.1l0.2,5.8 M747.3,322.7l1.9-4.6
	l-0.4-8.7c-0.2-1.1-0.6-1.9-0.9-2.6l-4.7-0.2v10.9C743.5,320.7,744.9,322.4,747.3,322.7L747.3,322.7z M752.9,327.6l-5.3-2.8
	 M748.8,323.3l-0.6,1.8l-0.7-0.2 M748.8,323.3l1.9-4.8l0.1-0.4l-0.1-3.1 M748.8,323.3l-1.5-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M747.5,324.8l-3.4-1.5c-2.1-1.2-3.1-2.8-3.1-5.1V303 M753.2,340l-17.4,9.1
	 M735.9,349.1h6.6l14.8-8.4l7-15.7 M735.9,349.1h-3.4 M735.9,349.1l-0.6,0.4 M718,329.2c0-0.5,0-1.1-0.2-1.5
	c-3-13.9-7.7-25.4-14.3-34.6 M710.3,341.9l-0.7,16.8c-0.1,5.7,1.1,9.3,3.3,10.9l9.7,9.4c4-3,5.5-5.7,4.5-8.1l7.1-16.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M737,354.2c4.8,0.2,8.4,0,10.6-0.9l15.7-10c2.5-1.2,3.9-2.7,4.1-4.7
	l1.7-8.7c0.4-1.3,0.1-2.2-0.9-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M768.2,326.9l-5.2,12.6c-0.4,1.3-2.1,2.6-5.2,4.2l-13.7,7.9l-8.4-0.1
	 M735.7,351.6l1.3,2.6 M734.2,354l2.8,0.2 M681.2,367.9c0.1-1.1,0.1-2.1,0.1-3.2c0-6.4-1.4-12-4.4-17.1 M732.4,349.2l-22.1-8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M588.4,367c0.6,15,6.1,27.9,17,38.6c11.3,11.3,24.9,17,41,17
	c16.1,0,29.6-5.7,41-17c11.3-11.3,17-24.9,17-41c0-8.3-1.5-15.8-4.5-22.8 M569.1,353.7c0.9-4.1,2-8.1,3-12c2.4-9,5-17.1,7.6-24.4
	c1.7-4.6,3.4-8.9,5.2-12.9c5.2-11,11.2-18.1,17.9-21.3c6.3-2.7,19.2-3.8,38.7-3.1c21.5,1.1,36.5,3.3,45.1,6.5
	c8.9,3.8,16.9,14.3,23.9,31.3c2.1,5.8,4.6,9.6,7.3,11.2 M735.7,351.6l0.1-2.5 M732.4,349.2c-0.4,0.7,0.2,2.2,1.8,4.7 M768.2,326.9
	l-0.7-0.2c-1.1-0.6-2.1-1.2-3.3-1.7 M764.2,325c-2.5-1.1-5.4-2-8.6-2.6 M755.6,315.4l6.6-6.3l1.4-3.7 M755.6,322.4l-6.7,0.8
	 M750.7,315l4.8,0.4 M755.6,322.4l-2.7,5.2 M753.2,340l3.4-9.7l-3.8-2.7 M755.6,315.4l-5.9-11.8 M557.7,236.6
	c-2.6-3.5-5.4-7-8.4-10.3c-8.6-9.8-18.7-18.7-30.1-26.7c-4.8-3.4-10-6.6-15.4-9.7l-0.8-0.5c-6.4-3.3-13.7-6-21.8-8.1
	c-9.4-1.9-18.7-3.4-27.5-4.5c-18.3-2-36.6-3-54.7-3.1h-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.7,230.2c1.2,1.5,2.2,3,3.3,4.5c0.9,0.9,1.5,1.9,1.5,2.8
	c0.1,0.9-0.4,1.9-1.3,2.8c-1.5,1.4-4.7,2.7-9.7,3.9h-0.2l-114.3-3.2c-1.4,0-2.7-0.6-3.9-1.8c-1.5-1.5-2.6-4-3.4-7.4v-0.1l-5.5-38.8
	c-0.8-5.2-0.7-9,0.1-11.3c0.1-0.5,0.2-0.8,0.6-1.2c0.8-0.9,2.2-1.5,4.4-1.5l23.4,0.9c14.8,1.1,28.9,3.3,42.4,6.6
	c8.1,2.5,15.5,5.6,21.8,9.2l1.2,0.6c1.9,1.2,3.8,2.4,5.7,3.5c7.8,5.1,14.9,10.6,21.3,16.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524,212.2c-5.3-4.5-11-8.6-17.1-12.5c-1.1-0.7-2.2-1.4-3.4-2l-0.4-0.2
	c-6.4-3.8-13.7-6.8-21.8-9.3c-13.6-3.4-27.7-5.7-42.6-6.7l-23.5-0.9c-1.3,0-2.2,0.5-3,1.4c-0.9,2-1.1,5.6-0.2,10.7l5.5,38.8v0.1
	c1.1,5,3.1,7.6,5.8,7.9l105.9,3 M572,236.6c-3.7-3.8-7.3-7.3-11.1-10.9c-10.3-9.7-20.9-18.3-31.9-26.1c-8.5-6-17.1-11.5-26-16.3
	c-0.8-0.5-1.7-0.9-2.5-1.3 M500.6,182l-1.4,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M499.2,183.8l3.9,2.1c7.3,4,14.6,8.6,21.8,13.8
	c10.4,7.6,20.9,16.5,31.2,26.7c3.3,3.3,6.6,6.7,10,10.3 M531.2,216.4l-3.5-1.1h-0.2 M516.6,229.9v-0.9c0.8-5.4,1.9-9.3,3.4-11.9
	c1.2-1.5,2.7-2.2,4.7-2.1 M524.7,214.8l0.1,0.4 M525.7,214.9l-0.9-0.1 M524.7,214.8l-0.7-2.6 M524.9,215.1l0.8-0.2 M527.6,215.2
	l-3.5-3.1 M527.6,215.2l-1.9-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524.9,215.1c-2.1,0.8-3.7,3.3-4.8,7.3l-1.5,7.6 M516.6,229.9
	c0,4.2,1.3,7.2,4.1,8.9l0.9,0.4c2,0.6,4,0.7,6,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M535.2,232.2c1.5-0.8,0.9-1.9-1.5-3.2c-4.4-1.9-8.9-2.6-13.5-2.1
	c-1.1,0.1-1.4,1.2-1.1,3.1 M519.2,230h-0.7 M535.2,232.2c-4.8-1.2-10.2-1.9-16.1-2.2 M529.3,242.4l4.4,0.1 M533.7,242.5l0.4,0.1
	l0.7-0.1 M529.3,242.4v-1.9 M529.3,240.5l4.4,2 M533.7,242.5h1.1 M527.7,239.8l1.7,0.7 M518.5,230l-1.9-0.1 M533.9,238.4
	c-2.1,0.8-4.1,1.3-6.1,1.4 M448.8,262.1c-4.6-0.9-11.1-1.3-19.4-1.3h-3.8c-1.8-0.1-3.4-0.9-5.2-2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M438.4,258.3c-1.8-1.7-4.1-2.5-7-2.5h-5.3c-3,0.2-4.8,1.1-5.7,2.6
	 M420.5,258.4l-0.2-0.1 M420.2,258.3h-2.7 M420.5,258.4l-0.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.5,258.4c1.2,0.7,2.5,1.2,3.9,1.2h10.2c2,0.1,3.3-0.4,3.9-1.3
	 M415.6,260.1c-0.4-0.4-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.9,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5
	s0.9-0.2,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2C416.2,260.8,416,260.4,415.6,260.1L415.6,260.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.5,258.3c-2,0.1-3.8,0.5-5.5,1.1c-0.9,0.6-1.5,1.4-1.7,2.5v1.4
	c0,0.9,0.6,1.5,1.7,1.9c1.7,0.6,3.4,0.9,5.4,1.3 M419.9,266.7c0.9,1.8,3.3,2.7,7.2,3.1h3.4c3.4,0.2,5.9-0.7,7.4-2.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M438,266.9c-0.8-1.5-2.4-2.2-4.5-2.1h-8.4c-2.7,0.1-4.5,0.7-5.2,1.9
	 M438,266.9c4.4,0,7.7-0.7,9.8-1.9l0.6-0.5 M448.3,264.6c0.7-0.7,0.8-1.5,0.6-2.5 M448.8,262.1l-0.1-0.2c-0.6-1.5-4-2.7-10.3-3.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M419.9,266.7c1.3-2.1,3-3.3,5.1-3.4h3.2c8.3,0.1,15,0.5,20.2,1.3
	 M417.5,266.5l2.4,0.2 M405.5,244.5c2.1,21.8,2.8,41.2,2.2,58.1 M417.5,258.3v8.1 M562.2,339.8l-16.3-25.9c-4.6-7.4-12.9-11-25-10.5
	l-113.1-0.7 M398.6,173.9l6.9,70.6 M686.1,245.1c-9-1.4-18.3-2.7-28-4l-19.2-2.2l-14.2-1.4c-9.7-0.8-19.2-1.2-29-1.2l-9.7,0.4
	 M543.7,230.2c0.2-0.7,0.5-1.5,0.6-2.4c0.2-3.2-0.7-5.3-3-6.6c-3.1-2-6.5-3.5-10.3-4.8 M534.8,242.5l2.7,0.1l0.5-0.1
	c4.1-0.9,6.8-2.1,8.3-3.3c0.5-0.5,0.7-0.9,0.7-1.5c0-0.6-0.4-1.2-1.1-1.8l-0.1-0.1l-2.8-3.9 M542.9,231.9c0.4-0.6,0.6-1.1,0.8-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M533.9,238.4c1.7-0.7,3.4-1.5,5.1-2.7c1.8-0.9,3.1-2.2,4-3.7 M566.2,236.6
	h-8.5 M561.6,248.9l7.9,0.2 M549.3,236.6h8.4 M557.7,236.6c1.5,3.3,2.8,7.4,3.8,12.3 M585.9,236.6H572 M561.6,248.9
	c1.7,7.8,2.8,17.6,3.3,29.4c1.4,20.1,0.9,39-1.5,56.8c0,0.5-0.1,1.1-0.2,1.5c-0.2,1.1-0.5,2.1-0.9,3.1 M576.7,266.9
	c4.4-5.7,14.5-8.9,30.6-9.2c31.6-0.1,61.4,1.5,89.3,5 M572,236.6h-5.8 M561.2,341.4c0.4-0.5,0.7-1.1,1.1-1.8 M561.2,341.4
	c-2.5,3.3-7.1,5.2-13.8,5.8l-145.2-2 M593,341.4l-0.1,0.2c-1.8,4.1-3.1,8.4-3.8,13 M581.4,341.3l-0.1,0.4c-1.1,3.9-2,8-3.1,12.4
	 M593,341.4l-11.6-0.1 M579.3,363.4l9.1,3.5 M589.1,354.7l-10.9-0.6 M579.3,363.4l9.1-0.7 M588.7,357.7l-0.4,5 M589.1,354.7
	l-0.4,3.1 M588.4,362.7v4.2 M569.1,353.7l-6.3-0.1 M570.1,364.1v-2.7 M570.1,361.4v-4.6 M570.1,356.8l-17.7-0.8 M570.1,364.1
	l9.2-0.7 M578.2,354.1l-9.1-0.4 M570.1,356.8l18.7,0.9 M635.3,331.3c-1.4,0.5-2.8,0.9-4.1,1.7 M631.2,337.2v0.5l2,5.7 M631.1,332.9
	l0.1,4.3 M638.4,350.8l-2.8-12.6c-0.2-0.9-0.6-1.8-0.9-2.5c-1.1-2.2-2.2-3.2-3.5-2.6 M636.9,334.8c1.7-0.5,3.3-0.9,5.1-1.1
	 M642.1,333.7c-0.1-1.5-0.1-3,0.2-3.9 M638.2,341v-0.4c-0.4-2.2-0.7-4.3-1.2-5.8 M643.4,339.8c-1.8,0.2-3.5,0.6-5.2,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M633.2,343.3c-1.7,1.1-3.2,2.2-4.6,3.7c-0.8,0.8-1.5,1.7-2.2,2.6
	 M635.4,349.1l-2.1-5.8 M642.2,329.8c-2.5,0.2-4.8,0.7-7.1,1.5 M643.4,339.8c-0.7-2.4-1.2-4.5-1.4-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M643.2,328.2c-3.2,0.2-6.3,0.8-9.2,1.9c-5,1.8-9.4,4.6-13.5,8.6
	c-3.1,3.1-5.4,6.4-7.2,10c-1.3,2.8-2.4,5.7-2.8,8.9c-0.4,2.2-0.6,4.6-0.6,7c0,7.6,2,14.3,6,20.2c1.4,2,3,3.8,4.7,5.5
	c7.1,7.1,15.7,10.7,25.9,10.7c10,0,18.7-3.5,25.9-10.7c7.1-7.1,10.6-15.7,10.6-25.7c0-10-3.5-18.7-10.6-25.9
	c-0.8-0.8-1.7-1.7-2.6-2.4c-6.6-5.5-14.3-8.4-23.1-8.4L643.2,328.2L643.2,328.2z M635.3,331.3c0.7,0.8,1.2,2,1.8,3.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M631.1,332.9c-3.4,1.7-6.6,3.9-9.6,6.9c-2.1,2.1-3.8,4.2-5.3,6.6
	 M626.4,349.6l-5.2-2.5h-0.7 M620.5,347.1c1.1-1.5,2.4-3.1,3.8-4.5c2.1-2.1,4.5-3.9,7-5.3 M616.2,346.4c-0.5,1.2,0.2,2.5,2.4,3.7
	l2.6,1.4l12.4,4 M620.5,347.1l-4.3-0.7 M669.3,338.1c-1.2-1.1-2.2-1.9-3.4-2.7 M677.1,347.5c-0.7-1.2-1.4-2.2-2.1-3.3 M660.2,358
	l9.8-8.5c1.1-0.7,1.9-1.3,2.7-1.7c2-1.1,3.4-1.2,4.2-0.4 M654,351l9.4-8.9c0.7-0.8,1.3-1.7,1.8-2.4c1.3-2,1.4-3.4,0.5-4.4
	 M666.5,350C666.5,350,666.4,350,666.5,350l-10.2,9.7 M666.5,350c-0.7-1.1-1.5-2-2.5-3l-1.4-1.3 M671.3,345.8
	c-1.4,0.9-3,2.4-4.7,4.1 M671.3,345.8c-0.8-1.1-1.8-2.2-2.8-3.3l-1.2-1.1 M674.9,344.2c-1.1,0.2-2.2,0.7-3.5,1.7 M669.3,338.1
	c-0.4,0.9-1.1,2-2,3.3 M674.9,344.2c-1.1-1.5-2.4-3-3.8-4.4l-1.8-1.7 M649,339.8l-0.5-5.4l-0.4-0.9 M646.7,329.5
	c-1.3,0.2-2,1.5-2.1,3.9c0,0.8,0,1.9,0.1,3.1l2.7,12.6 M648.1,333.4l-1.4-3.9 M662.4,337.8l-0.7,0.5l-3.5,4.2 M653.9,347.6l-0.2,2
	 M658.2,342.5l-4.2,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M667.3,341.4c-1.1,1.3-2.6,2.7-4.6,4.2v0.1 M648.6,348.2l-1.2,1.1
	 M649.5,346.4l-0.6-6.6 M648.6,348.2l0.9-1.8 M646.7,329.5l1.9,18.7 M662.4,337.8c-4.2-2.6-9.1-4-14.3-4.3 M658.2,342.5
	c-2.8-1.5-5.9-2.5-9.2-2.7 M653.9,347.6l-4.4-1.2 M665.8,335.4c-5.7-3.8-12-5.8-19.1-5.8 M665.8,335.4l-3.4,2.4 M653.7,349.6
	l12.2-14.2 M642.1,356.2c1.3-0.7,2.7-1.1,4.2-1.1h0.2 M652,354.7c0-0.7-0.4-1.3-0.8-1.9c-0.6-0.5-1.2-0.8-2-0.8
	c-0.7,0-1.4,0.2-2,0.8l-0.4,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.9,353.3c-0.2,0.5-0.5,0.9-0.5,1.5v0.5 M651.3,356.7
	c0.5-0.5,0.7-1.2,0.7-1.9v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M654.5,360.1c-0.5-0.7-0.9-1.4-1.5-2.1c-0.5-0.5-1.1-0.9-1.7-1.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.6,355.3c0.1,0.6,0.4,1.1,0.8,1.4c0.6,0.6,1.2,0.8,2,0.8
	c0.7,0,1.4-0.2,2-0.8 M650,360.9l-0.4-0.4c-0.9-0.8-2.1-1.2-3.4-1.2h-0.4l-1.3,0.2c-0.7,0.2-1.4,0.7-1.9,1.3
	c-0.5,0.5-0.8,0.9-1.1,1.4c-0.2,0.4-0.4,0.8-0.5,1.2l-0.1,1.1c0,1.1,0.2,2,0.8,3l0.7,0.8c1.1,1.1,2.2,1.5,3.7,1.5s2.7-0.5,3.8-1.5
	c1.1-1.1,1.5-2.2,1.5-3.7C651.5,363.2,651.1,361.9,650,360.9L650,360.9z M656.4,359.5h-0.2c-0.6,0-1.2,0.2-1.7,0.6 M661.6,358.5
	l-1.4-0.5 M654.5,360.1l-0.4,0.2c-0.6,0.6-0.8,1.2-0.8,2c0,0.8,0.2,1.4,0.8,2c0.5,0.5,0.9,0.7,1.5,0.8 M657.3,364.8
	c0.2-0.1,0.6-0.4,0.8-0.6c0.6-0.5,0.8-1.2,0.8-2c0-0.7-0.2-1.4-0.8-2c-0.5-0.5-1.1-0.7-1.8-0.7 M655.7,365.1h0.5l1.2-0.2
	 M662.4,363.8l-1.2,0.9 M651.4,374.3c0.5,0.5,1.2,0.7,1.9,0.7c0.7,0,1.4-0.2,2-0.8c0.5-0.6,0.8-1.2,0.8-1.9c0-0.5-0.1-0.8-0.2-1.2
	l-0.2-0.5l-0.1-0.1 M655.4,370.5l-0.1-0.2c-0.4-0.2-0.7-0.5-1.1-0.6 M655.7,365.1c-0.1,1.8-0.6,3.3-1.4,4.6 M654.3,369.7l-0.9-0.2
	c-0.7,0-1.4,0.2-1.9,0.8c-0.6,0.6-0.8,1.2-0.8,2l0.1,0.7 M661.2,364.7l-1.1-6.7 M669.3,354.7l-5.7,3.9 M674.5,350.8l-0.7,0.9l-4.5,3
	 M677.5,366l-1.4-0.6l-4.8-0.9 M680.7,371.8l-3.9,0.1 M661.2,364.7l12.3,4.1c1.4,0.4,2.6,0.5,3.7,0.5c2,0.1,3.4-0.5,4-1.5
	 M677.5,366v-1.4c0-5.1-1.1-9.7-3.1-13.8 M671.3,364.5l-7-1.5 M663.6,358.6l0.7,4.4 M676.8,371.9c-1.7-0.2-3.7-0.9-6-1.9
	 M671.3,364.5c0-3.4-0.7-6.7-2-9.7 M672.3,382.1l-1.7-0.4l-4.4-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M666.3,379.6c-0.7,0.9-1.5,1.9-2.4,2.7c-1.7,1.7-3.4,3-5.2,4.1
	 M676.3,382.9c0.1-1.2-0.7-2.4-2.4-3.5c-0.8-0.6-1.9-1.3-3.2-1.9l-12.3-4.4 M676.3,382.9c0.7-1.1,1.3-2.2,1.9-3.4 M675.2,377
	c1.5,0.7,2.5,1.5,3.1,2.5 M676.3,382.9l-4-0.8 M669.3,374.7c2.5,0.7,4.4,1.4,5.9,2.2 M672.3,382.1c-1.1,1.7-2.4,3.2-3.9,4.6
	c-2.5,2.5-5.1,4.5-8,5.9 M660.8,396.7c3.8-1.8,7.2-4.1,10.4-7.3c2-2,3.8-4.3,5.2-6.5 M645.5,375.6c0.1-0.4,0.2-0.7,0.2-1.1v-0.6
	 M640.2,374.3v0.2c0,0.7,0.2,1.4,0.8,1.9c0.6,0.6,1.2,0.8,1.9,0.8s1.4-0.2,2-0.8c0.2-0.2,0.5-0.6,0.6-0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M650.7,373c-1.3,0.7-2.7,1.1-4.2,1.1h-0.7 M645.6,373.9
	c-0.1-0.6-0.4-1.1-0.7-1.4c-0.6-0.6-1.2-0.8-2-0.8c-0.7,0-1.3,0.2-1.9,0.7 M650.7,373c0.1,0.5,0.4,0.9,0.7,1.3l0.1,0.1 M659.8,376.3
	l-1.1-1.8 M656.5,379.5l3.3-3.2 M656.5,379.5l-1.7-1.3 M658.5,373.1l0.2,1.4 M653.4,377.7l2.5,12.8c0.5,1.4,0.9,2.6,1.4,3.5
	c0.9,1.8,2,2.7,3.2,2.8 M660.4,392.6l-0.1-1.5l-1.5-4.7 M653.6,388.7c-1.5,0.5-3.1,0.7-4.7,0.8 M658.8,386.3l-2.2-6.8 M658.5,373.1
	l-5.1,4.6 M654.9,378.2l-1.4-0.5 M645.4,399.7c1.2-0.4,1.8-1.7,2-3.8c0.1-0.9,0-2.1-0.1-3.4l-2.4-12.8 M645.5,375.6l3.3,13.9
	 M653.6,388.7l-2.1-14.4 M678.2,379.5c1.2-2.4,2-5,2.5-7.7 M663.6,358.6l-2-0.1 M664.3,362.9l-1.9,0.8 M666.3,379.6l-6.5-3.3
	 M658.8,374.5l17.6,8.4 M670.8,370.1c-0.4,1.5-0.8,3.2-1.5,4.6 M675.2,377c0.7-1.7,1.2-3.3,1.7-5 M670.8,370.1l-13.5-5.3
	 M655.4,370.5l13.8,4.2 M630.8,363.9l-12.4-3.8l-2.8-0.4c-2.4-0.1-3.8,0.4-4.1,1.6 M614.2,350.3c-0.9,2.1-1.7,4.4-2.2,6.7 M612,357
	c-0.2,1.4-0.5,3-0.6,4.4 M612,357l3.9,0.2 M623.6,354.3c-0.7,1.5-1.2,3.2-1.5,4.8 M617.5,352.3c1.5,0.7,3.5,1.3,5.9,1.9h0.1
	 M621.3,364.6c0,3.4,0.6,6.5,1.8,9.4 M615.2,363.3v1.4c0,4.8,0.9,9.2,2.8,13.2 M611.4,361.4l3.8,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M611.4,361.4l-0.1,3.2c0,7.6,2.1,14.3,6.4,20.2 M615.2,363.3l0.6,0.4
	l5.5,0.9 M615.9,357.3l6,1.9 M615.9,357.3c0.4-1.7,0.9-3.3,1.5-4.8 M617.5,352.3c-1.5-0.7-2.6-1.3-3.3-2.1 M633.2,354.1l-1.1-1.8
	 M633.2,354.1l0.4,1.4 M637,350.3l1.4,0.5 M640.7,354.4c-0.4-0.2-0.8-0.4-1.3-0.4c-0.7,0-1.4,0.2-2,0.8s-0.8,1.2-0.8,2
	c0,0.5,0.1,0.8,0.2,1.2 M642.1,356.2c-0.1-0.6-0.4-1.1-0.7-1.4c-0.2-0.2-0.4-0.4-0.6-0.5 M638.4,359.5l0.8,0.1c0.7,0,1.4-0.2,2-0.8
	s0.8-1.2,0.8-2l-0.1-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M636.9,358l0.6,0.7c0.4,0.4,0.7,0.6,1.1,0.7 M637,363.8
	c-0.4-0.1-0.7-0.2-1.1-0.2l-1.1,0.2 M637.7,368.5l0.1-0.1c0.6-0.6,0.8-1.2,0.8-1.9c0-0.7-0.2-1.4-0.8-2c-0.2-0.2-0.6-0.5-0.9-0.6
	 M641,372.4l-1.4-1.2c-0.8-0.8-1.5-1.8-2-2.8 M636,369.1c0.7,0,1.3-0.2,1.8-0.7 M629.6,364.9l-1.8,0.8 M630.8,363.9l-1.2,1.1
	 M634.9,363.8c-0.4,0.1-0.6,0.4-0.9,0.6c-0.6,0.6-0.8,1.2-0.8,2c0,0.7,0.2,1.4,0.8,1.9c0.6,0.6,1.2,0.8,2,0.8 M628.5,370.1h2
	 M632,370.6l-1.2-6.7 M627.8,365.8l0.7,4.4 M630.5,370.1l1.4,0.5 M633.6,355.5l4.8-4.7 M637,363.8c0.1-1.5,0.6-3.1,1.5-4.4
	 M629.3,383l-0.7-0.7c-1.1-1.1-2-2.2-3-3.4 M629.3,383l0.2-0.2l10.6-8.5 M638.7,379.1l-0.2,2 M642.7,382.4l-4.2-1.3 M643.8,380.6
	l-1.1,1.8 M638.7,379.1l-0.2-1.4 M641,372.4c-0.6,0.6-0.8,1.2-0.9,1.8 M644,395.8l-0.5-1.3l-0.2-5 M638.4,377.7l-9.7,8.6
	c-0.8,0.9-1.5,1.8-2.1,2.6c-1.2,1.8-1.4,3.2-0.8,4.2 M633.8,386.3l-3.4,3.9l-1.1,0.7 M638.4,381.1l-4.6,5.2 M643.3,389.5l-0.6-7.1
	 M633.8,386.3c2.8,1.7,6,2.7,9.4,3.2 M623.1,374l-4.5,3.2l-0.6,0.7 M615.4,381.2c0.8,0.9,2.2,0.8,4.4-0.2c0.8-0.4,1.7-0.9,2.7-1.7
	l9.6-8.7 M625.8,378.9c-1.7,1.8-3.3,3.2-4.7,4.1 M621.1,383c0.9,1.3,2,2.5,3.2,3.7l0.5,0.5 M622.6,390.5l3.2,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M625.8,393v0.1h0.1 M625.8,393l0.1,0.1 M622.5,392c0-0.5,0-0.9,0.1-1.5
	 M622.6,390.5c0.4-1.1,1.1-2.1,2-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617.5,384.8c1.2,1.5,2.5,3.1,4,4.6l1.1,1.1 M621.1,383
	c-1.3,0.9-2.5,1.5-3.5,1.8 M618,377.9l-2.6,3.3 M624.7,387.1c1.2-1.3,2.7-2.7,4.6-4.1 M625.9,393.2l3.4-2.2 M625.9,393.2l12.8-14
	 M621.9,359.2l13,4.6 M636.9,358l-13.3-3.7 M611.4,361.4l18.2,3.5 M627.8,365.8l-6.5-1.2 M623.1,374l5.4-3.9 M630.5,370.1
	l-15.1,11.1 M636,369.1l-10.2,9.8 M616.2,346.4c-0.7,1.2-1.4,2.5-2,3.9 M631.1,332.9l5.9,17.4 M637,350.3l-1.7-1.2 M662.6,345.7
	l-10.6,9 M646.9,353.3l-3.5-13.5 M616.2,346.4l17,7.7 M677.1,347.5l-15.5,11 M677.1,347.5l-2.6,3.3 M647.4,349.2l6.6,1.8
	 M653.7,349.6l0.4,1.4 M644.9,379.6l-6.5-1.9 M632.2,352.3l3.2-3.2 M638.2,341l2.5,13.5 M644.9,379.6l-1.2,1.1 M632.2,352.3
	l-5.8-2.7 M646.7,329.5l-4.5,0.2 M625.9,393.2c5.9,4.4,12.8,6.5,20.4,6.5l3.1-0.1 M649.9,395.6c0.1,1.5,0,3-0.5,3.9 M657,398.1
	c1.3-0.4,2.5-0.8,3.7-1.4 M660.6,396.7h0.1 M657,398.1c-0.8-0.7-1.5-1.8-2-3.4 M660.6,396.7h0.1 M649.4,399.5
	c2.6-0.2,5.2-0.7,7.6-1.4 M649.9,395.6c1.8-0.2,3.4-0.5,5.1-0.9 M644,395.8l1.4,3.9 M648.8,389.5c0.7,2.4,1.1,4.5,1.1,6.1
	 M655,394.7c-0.6-1.5-1.1-3.7-1.3-6.1 M629.3,390.9c4.4,3,9.2,4.5,14.6,4.8 M660.8,396.7l-0.4-4.1 M643.8,380.6l1.7,19 M654.9,378.2
	l5.9,18.5 M504.5,351.7l-5.1,1.8 M489.8,364.6c1.4,2.5,3.8,3.5,7.2,3.3h9.2c3.9,0.5,6.1-0.7,6.4-3.4 M534.8,355.1l0.2,4 M535,359.2
	l0.4,5.2 M512.6,364.5l22.8-0.1 M534.8,355.1l-35.3-1.7 M477.7,360.7v-0.8 M477.7,359.9l0.4-0.1 M372.5,365.7h105.2v-1.1
	 M477.7,351.6v8.3 M477.7,360.7v3.9 M499.5,353.5l-18.2,6.3l-3.5,0.9 M477.7,364.6h12 M373.1,350.2l108.2,0.8l23.3,0.7 M512.6,364.5
	l-22.8,0.1 M405.5,244.5l156.1,4.4 M407.7,302.6c-0.6,16.5-2.5,30.8-5.7,42.6 M562.9,353.6l-58.3-1.9 M535.4,364.4l34.7-0.2
	 M534.8,242.5l7-1.4l-7.8-2.7 M540.7,233.8c-1.8-0.6-3.5-1.2-5.4-1.5 M552.4,356l-17.6-0.8 M535.4,364.4c5.2-3,16.8-3.9,34.7-3
	 M535,359.2c3.9-1.8,9.7-2.8,17.4-3.2 M680.7,371.8c0.2-1.3,0.5-2.6,0.6-3.9 M681.2,367.9l-3.7-1.9 M662.4,363.8l18.8,4.1
	 M402.1,345.2l-66.4-0.7"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M703.4,293
		l12.4-10.7c0,0-9.8-4.8-14.1-8.4c0,0-5-3.5-5.7-6.5c0,0-1.9-5,4.3-7.7c0,0,4.5-2.5,16.3-1.4l8.6,0.7c0,0-3.8-3.5-15.7-7.4
		c0,0-12.4-3.9-21.3-5.9c0,0-4.1-1.1-26.8-4.1c0,0-28.7-3.7-46.4-4.7c0,0-9.1-0.5-20.7-0.6l-8.3,0.4h-28.7c0,0,2.1,4.6,3.8,12.3
		c0,0,2.2,8.5,3.2,28.3c0,0,0.7,12.2,0.8,18.4c0,0,0.2,13.5-0.9,28.2c0,0-0.6,6.9-1.3,12c0,0-0.2,3.5-2.4,6c0,0-3,8.3-11.7,7.4v3.9
		l13.7,0.5l6-21.3c0,0,6.4-24.6,15.7-41.7l2.6-4.7c0,0,5.4-11.9,27.7-12.9c0,0,18.9-1.1,46.6,1.5c0,0,20.9,0.8,32,8.3
		C693.5,282.8,697.2,284.7,703.4,293z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M542.9,231.9l2.8,3.9
		c0,0,1.4,1.1,1.2,2.1c0,0,0,1.4-3.7,2.8l-5.8,1.8l-3.5-0.1l-6.1-2.7c0,0-3.5,0.2-6-0.6c0,0-5.5-1.3-5.1-10.3c0,0,0.8-7.7,3.4-11.9
		c0,0,1.3-2.5,5.7-2.1c0,0,7.8,1.4,14.8,5.8l1.8,1.2c0,0,2.4,1.5,2.2,5C544.5,226.8,544.6,229.3,542.9,231.9z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M524.7,214.8
		l-0.7-2.6c0,0-19-17.2-41.7-23.7c0,0-14.1-4.6-43.3-7.1l-23.1-0.9c0,0-2.2-0.4-3.7,1.4c0,0-1.4,2.7-0.5,9.1l5.8,40
		c0,0,1.2,7.7,5.9,8.4l68,1.9l37.9,1.1v-1.9l-1.7-0.7c0,0-5.3,0.5-7.9-1.7c0,0-3.7-2.4-3.2-9.2c0,0,0.9-8.1,3.5-12.2
		C520.1,216.8,521.7,214.8,524.7,214.8z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M394.3,232.7
		c0,0,0.2-20.3-2.6-49.9c0,0-0.8-6.4-6.3-6c0,0-22.3-1.2-44.6,1.1c0,0-6.1,0.9-7.4,9c0,0-1.9,12.9-2.1,35.5c0,0-0.5,4,1.7,7.1
		c0,0,1.8,3.2,7.2,4.4c0,0,20.9,4.3,46.6,5c0,0,4,0.8,6.1-1.3C392.7,237.4,394.3,236,394.3,232.7z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M561.6,248.9
		l-156.1-4.4c0,0,3.4,29.6,2.2,58.1c0,0,0,20.1-5.7,42.6l145.2,2c0,0,9.3-0.2,13.6-5.4c0,0,1.9-2,2.4-6c0,0,1.8-11.6,2.4-29.9
		c0,0,0.5-10.6-1.2-35.5C564.4,270.5,563.2,255.2,561.6,248.9z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="
		M259.4,350.5l-1.2-9.9l6.8-2.1c0,0,2.1-0.7,2.6-2.5c0,0,0.5-0.7,0.1-2.4c0,0-3.9-19.6-12.6-35.3c0,0-10.3-22.4-36-25.9
		c0,0-26.8-4.7-59.6-3.5c0,0-14.9-0.7-24.7,7.3c0,0-5,3-11.9,17.4c0,0-6.6,12-13.5,37.8c0,0-3.8-1.1-5.9-4.5l-15.2-23.3
		c0,0-3.3-7.1-14.5-7s-0.1,0-0.1,0s2.7-22,8.5-37.3c0,0,2.2-4.7-3.2-7l-23.3-12.6l0.4-3.3h139.1H307c0,0-1.8,22.8-1.5,37.7
		c0,0,0,22.6,2.5,44.4c0,0,2,20.2,5.1,26.1c0,0,0.9,2.7-0.2,4c0,0-0.8,1.2-3.2,0.6L259.4,350.5z"
              id="Right Rear - Quarter Panel 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M405.5,244.5
		c0,0,3.4,35.4,2.2,58.1c0,0,0,19-5.7,42.6l-68.5-0.9c0,0-13.9-0.7-17.1-9.1c0,0-3.3-13.6-4.5-33.1c0,0-1.5-11.2,0.4-67.3l16.8-5
		c0,0,1.1,4.4,5.9,6.4c0,0,6.4,2.7,27.7,5.3C362.7,241.8,383.9,244,405.5,244.5z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="646.1"
              cy="364.8"
              r="58"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="189.5"
              cy="364.9"
              r="58"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup2DrRight);
