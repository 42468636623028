import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../../templates/forms/Layout';
import InputField from '../../ui/inputField';
import {
  updateItem,
  loadCoreValidationError,
  resetCoreValidation,
} from '../../../../../actions';
import FormFooter from '../../templates/forms/formFooter';
import { showRow } from '../../../utils/helpers';
import { commafy } from '../../../../../utils/helpers';

class Pricing extends Component {
  componentDidMount() {
    // handle any pre-existing price errors
    this.handleValidation();
  }

  handleValidation = e => {
    const entity = e ? e.target.getAttribute('data-id') : null;
    const value = e ? e.target.value : null;
    const re = /[^0-9.]/g;

    // if true zeros are considered "no price" not $0 and therefore exluded from logic
    const reserveCanBeZero = true;
    const buyNowCanBeZero = true;

    const startingbid =
      entity === 'startingbid'
        ? Number(value.replace(re, ''))
        : Number(this.props.item.startingBid || '');
    const reserve =
      entity === 'reserve'
        ? Number(value.replace(re, ''))
        : Number(this.props.item.reserve || '');
    const buyNow =
      entity === 'buyNow'
        ? Number(value.replace(re, ''))
        : Number(this.props.item.buyNow || '');

    if (
      reserveCanBeZero
        ? startingbid > reserve && reserve !== 0
        : startingbid > reserve
    ) {
      this.props.loadCoreValidationError(
        'Starting Bid cannot be greater than Reserve Price'
      );
    } else if (
      buyNowCanBeZero ? reserve > buyNow && buyNow !== 0 : reserve > buyNow
    ) {
      this.props.loadCoreValidationError(
        'Reserve Price cannot be greater than Buy Now Price'
      );
    } else if (
      buyNowCanBeZero
        ? startingbid > buyNow && buyNow !== 0
        : startingbid > buyNow
    ) {
      this.props.loadCoreValidationError(
        'Starting Bid cannot be greater than Buy Now Price'
      );
    } else if (this.props.coreValidationError) {
      this.props.resetCoreValidation();
    }
  };

  handleValue = e => {
    const entity = e.target.getAttribute('data-id');
    const value = e.target.value;
    const re = /[^0-9.]/g;
    const val = value.replace(re, '');
    this.props.updateItem({ [entity]: val });
  };

  render() {
    const { coreSections, item } = this.props;
    const { startingBid, reserve, buyNow } = item;

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Pricing</div>
            </div>
            <div className="form-body-container">
              <div className="form-body">
                {showRow('s8:r1', coreSections) && (
                  <InputField
                    autoFocus
                    dataId="startingBid"
                    title="Starting Bid"
                    currency="$"
                    placeholder="Enter starting bid"
                    onChangeHandler={e => {
                      this.handleValue(e);
                      this.handleValidation(e);
                    }}
                    value={commafy(Number(startingBid || '').toFixed(0))}
                    onFocus={e => e.target.select()}
                    onBlur={this.handleValidation}
                  />
                )}
                {showRow('s8:r2', coreSections) && (
                  <InputField
                    dataId="reserve"
                    title="Reserve / Floor Price"
                    currency="$"
                    placeholder="Enter reserve / floor price"
                    onChangeHandler={e => {
                      this.handleValue(e);
                      this.handleValidation(e);
                    }}
                    value={commafy(Number(reserve || '').toFixed(0))}
                    onFocus={e => e.target.select()}
                    onBlur={this.handleValidation}
                  />
                )}
                {showRow('s8:r3', coreSections) && (
                  <InputField
                    dataId="buyNow"
                    title="Buy Now"
                    currency="$"
                    placeholder="Enter buy now"
                    onChangeHandler={e => {
                      this.handleValue(e);
                      this.handleValidation(e);
                    }}
                    value={commafy(Number(buyNow || '').toFixed(0))}
                    onFocus={e => e.target.select()}
                    onBlur={this.handleValidation}
                  />
                )}
              </div>
            </div>
            <FormFooter currentSection="s8" />
          </div>
        </div>
      </Layout>
    );
  }
}

Pricing.propTypes = {
  updateItem: PropTypes.func.isRequired,
  loadCoreValidationError: PropTypes.func.isRequired,
  resetCoreValidation: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { item, coreSections, coreValidationError } = state.core;
  return { item, coreSections, coreValidationError };
};

export default connect(mapStateToProps, {
  updateItem,
  loadCoreValidationError,
  resetCoreValidation,
})(Pricing);
