import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv2DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M516.3,158.7c-1-0.3-1.5-0.8-1.8-1.6l-7.1-0.4c-0.3,0.7-1,1.2-1.9,1.5L516.3,158.7L516.3,158.7z"
          />
          <path
            fill="#FFFFFF"
            d="M534.4,160.2l-6.7-1l-11.4-0.7l-10.9-0.6l-25.6-1l-10.6-0.3c-8-0.3-15.8-0.4-23.7-0.4l-12-0.1h-25l-11.8,0.1
	c-7.8,0.1-15.7,0.3-23.7,0.4l-10.6,0.3l-25.6,1l-10.9,0.6l-11.4,0.7l-6.7,1c-2.1,0.1-3.7,2.2-4.7,6.5l2.6,2.3
	c1.4-2.9,3.9-4.4,7.4-4.7c36.2-2.2,72.1-3.3,107.6-3.3h5.9c34,0.1,68.2,1.2,102.8,3.3c3.6,0.3,6.1,1.8,7.4,4.7l2.6-2.3
	C538.2,162.4,536.5,160.4,534.4,160.2L534.4,160.2z"
          />
          <path
            fill="#656565"
            d="M479.8,157.2c-1-0.3-1.6-0.8-2.1-1.6l-6.2-0.1c-0.5,0.8-1.2,1.4-2.3,1.5L479.8,157.2L479.8,157.2z
	 M530.2,175.8c-1.1-4.4-3.3-6.6-6.5-6.5c-30-1.6-59.4-2.6-88.5-2.9h-28.2c-29,0.3-58.5,1.2-88.5,2.9c-3.2-0.1-5.4,2.1-6.5,6.5
	l-5.9,41.7l-1.1,7.6c-0.3,1.4,0.5,2.1,2.6,2.1l13.3-0.3c34.7-1.2,68-1.6,100.1-1.2c32.2-0.4,65.5,0,100,1.2l13.3,0.3
	c1.9,0,2.8-0.7,2.6-2.1l-1.1-7.6L530.2,175.8L530.2,175.8z M518.6,174.9c3.6,0.3,5.5,1.6,5.9,4.1l3.7,39.1c-0.1,2.5-2.2,3.7-6.3,3.7
	c-31.5-1.1-63.3-1.8-95.3-1.8h-11.4c-32.1,0-63.8,0.6-95.3,1.8c-4.1,0-6.2-1.2-6.3-3.7l3.7-39.1c0.4-2.5,2.3-3.9,5.9-4.1
	c27.2-1.5,55.9-2.3,85.7-2.8h23.9C462.8,172.6,491.4,173.4,518.6,174.9L518.6,174.9z M436.2,154.7c-0.4,1-1.2,1.5-2.6,1.5l12,0.1
	c-1.4-0.3-2.3-0.8-2.6-1.6L436.2,154.7L436.2,154.7z M399.3,154.9c-0.3,1-1.1,1.5-2.6,1.6l11.8-0.1c-1.5-0.3-2.3-0.7-2.6-1.5
	L399.3,154.9L399.3,154.9z M421.1,266.1l-0.8,0.1c-1.2,0.3-2.2,0.8-3.2,1.8c-1.2,1.2-1.8,2.8-1.8,4.5v5.8h11.4v-5.8
	c0-1.8-0.5-3.3-1.8-4.5c-1-1-1.9-1.5-3.2-1.8L421.1,266.1L421.1,266.1z M415.4,279.5v2.3h11.3v-2.3 M415.4,283.1v2.5h11.3v-2.5
	 M415.4,290.2v2.2h11.3v-2.2 M415.4,286.8v2.3h11.3v-2.3 M415.4,297.1v2.6h11.3v-2.6 M415.4,300.9v2.3h11.3v-2.3 M415.4,304.4v1
	c0,0.4,0.1,0.7,0.3,1h10.9c0.1-0.3,0.3-0.7,0.3-1v-1H415.4z M415.4,293.6v2.3h11.3v-2.3 M424.9,307.8l0.5-0.3h-8.7l0.5,0.3
	c1,0.5,1.9,0.8,3.2,1l0.8,0.1l0.8-0.1C423,308.8,424,308.4,424.9,307.8z M411.3,281.8v-2.3h-12.4v2.3 M411.3,278.2v-4.7
	c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4v4.7H411.3L411.3,278.2z
	 M398.9,283.1v2.5h12.4v-2.5 M398.9,286.8v2.3h12.4v-2.3 M398.9,290.2v2.2h12.4v-2.2 M398.9,297.1v2.6h12.4v-2.6 M398.9,300.9v2.3
	h12.4v-2.3 M398.9,304.4v1.1c0,0.3,0.1,0.5,0.3,1h12c0.1-0.3,0.1-0.5,0.1-1v-1.1H398.9L398.9,304.4z M398.9,293.6v2.3h12.4v-2.3
	 M409.4,308l0.5-0.3h-9.8l0.5,0.3c1.2,0.7,2.6,1,4.4,1C406.8,308.9,408.3,308.5,409.4,308z M392.7,269.3c-1.2-1.2-2.6-1.8-4.4-1.8
	c-1.6,0-3.2,0.5-4.4,1.8s-1.8,2.6-1.8,4.4v4.7h12.4v-4.7C394.5,272.1,394,270.5,392.7,269.3L392.7,269.3z M394.5,281.8v-2.3h-12.4
	v2.3 M394.5,285.5v-2.5h-12.4v2.5 M394.5,289.1v-2.3h-12.4v2.3 M394.5,292.4v-2.2h-12.4v2.2 M394.5,299.7v-2.6h-12.4v2.6
	 M394.5,303.3v-2.3h-12.4v2.3 M394.5,305.5v-1.1h-12.4v1.1c0,0.3,0.1,0.5,0.3,1h12C394.5,306,394.5,305.8,394.5,305.5L394.5,305.5z
	 M394.5,296v-2.3h-12.4v2.3 M383.5,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.8,0,3.2-0.3,4.4-1l0.5-0.3L383.5,307.5L383.5,307.5z
	 M453.7,267.5c-1.6,0-3.2,0.5-4.4,1.8s-1.8,2.6-1.8,4.4v4.7h12.4v-4.7c0-1.6-0.5-3.2-1.8-4.4C456.8,268.2,455.5,267.5,453.7,267.5
	L453.7,267.5z M447.5,279.5v2.3h12.4v-2.3 M459.9,285.5v-2.5h-12.4v2.5 M459.9,292.4v-2.2h-12.4v2.2 M459.9,289.1v-2.3h-12.4v2.3
	 M447.5,304.4v1.1c0,0.3,0.1,0.5,0.3,1h12c0.1-0.3,0.1-0.5,0.1-1v-1.1H447.5L447.5,304.4z M447.5,300.9v2.3h12.4v-2.3 M447.5,297.1
	v2.6h12.4v-2.6 M459.9,296v-2.3h-12.4v2.3 M458.1,308l0.5-0.3h-9.8l0.5,0.3c1.2,0.7,2.6,1,4.4,1C455.5,308.9,456.8,308.5,458.1,308
	L458.1,308z M465.2,276.3V308h0.1l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.7,0,3.2-0.3,4.4-1s1.8-1.5,1.8-2.5v-25.3l-3.2-4.1L465.2,276.3
	L465.2,276.3L465.2,276.3z"
          />
          <path
            fill="#656565"
            d="M465.2,278.2v-1.9h8l3.2,4.1v-6.7c0-1.7-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.6,0-3.2,0.5-4.4,1.8
	s-1.8,2.6-1.8,4.4v4.7h1.2L465.2,278.2L465.2,278.2z M441.4,269.3c-1.2-1.2-2.6-1.8-4.4-1.8c-1.6,0-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4v4.7h12.4v-4.7C443.2,272.1,442.7,270.5,441.4,269.3L441.4,269.3z M430.9,279.5v2.3h12.4v-2.3
	 M443.2,292.4v-2.2h-12.4v2.2 M443.2,289.1v-2.3h-12.4v2.3 M443.2,285.5v-2.5h-12.4v2.5 M430.9,297.1v2.6h12.4v-2.6 M430.9,300.9
	v2.3h12.4v-2.3 M430.9,304.4v1.1c0,0.3,0.1,0.5,0.3,1h12c0.1-0.3,0.1-0.5,0.1-1v-1.1H430.9L430.9,304.4z M443.2,293.6h-12.4v2.3
	h12.4 M441.4,308l0.6-0.3h-9.8l0.5,0.3c1.2,0.7,2.6,1,4.4,1C438.7,308.9,440.2,308.5,441.4,308L441.4,308z M372,267.5
	c-1.6,0-3.2,0.5-4.4,1.8s-1.8,2.6-1.8,4.4v6.7l3.2-4.1h8v1.9h1.2v-4.7c0-1.6-0.5-3.2-1.8-4.4C375.1,268.2,373.6,267.5,372,267.5
	L372,267.5z"
          />
          <path
            fill="#656565"
            d="M376.9,279.5v-3.2h-8l-3.2,4.1v25c0,1,0.5,1.8,1.8,2.5c1.2,0.7,2.6,1,4.4,1c1.6,0,3.2-0.3,4.4-1l0.7-0.4
	v-28.1L376.9,279.5L376.9,279.5z M429.1,243.4c0,0.1,0,0.3,0.1,0.4l0.3,0.1l0.4-0.1l0.1-0.3c0.1-1.1,0-1.9-0.3-2.3
	c-0.3-0.7-0.8-1-1.5-1h-4.4l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.3h-4c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1h-4.4c-0.7,0-1.2,0.3-1.5,1
	c-0.3,0.6-0.3,1.2-0.3,2.3l0.1,0.3l0.4,0.1l0.3-0.1l0.1-0.4c-0.1-0.8,0-1.5,0.1-1.9c0.1-0.3,0.3-0.4,0.5-0.3h4.5l0.1,0.3
	c0.1,0.1,0.4,0.3,0.7,0.3h4c0.3,0,0.5-0.1,0.7-0.3l0.1-0.3h4.5c0.3,0,0.5,0.1,0.5,0.3C429.2,242.1,429.2,242.6,429.1,243.4
	L429.1,243.4z"
          />
          <path
            fill="#FFFFFF"
            d="M528.8,230.4c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8C529.2,230.9,529.1,230.6,528.8,230.4L528.8,230.4z
	 M536.1,237c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8s-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3
	s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8C535.8,236.6,536,236.8,536.1,237L536.1,237z"
          />
          <path
            fill="#656565"
            d="M517.9,256.2l-1.2,0.3l1.8,2.3l-1.4,0.8l0.4,0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.1,0,0.4,0.1,0.5,0.3
	c0.1,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.3-0.1,0.5l-0.1,0.1l0.8,1.1l1.4-0.1l2.1-0.7l0.1-0.1c-1.1-1.2-1.7-2.3-1.7-3.2L517.9,256.2
	L517.9,256.2z"
          />
          <path
            fill="#656565"
            d="M519.3,256.4l-1.4-0.1l3.4,2.3c0,0.8,0.5,1.9,1.7,3.2l1-0.3c-1.1-1.1-1.7-2.1-1.7-2.9L519.3,256.4
	L519.3,256.4z M476.2,239.7c-1.8-0.7-3.6-0.7-5.4-0.1l-1,4.4c2.1,0.6,4.5,0.7,7.6,0.4L476.2,239.7L476.2,239.7z M526.6,266.3
	l1.4-0.3l-3.2-3.7l-1.7-0.3 M526.3,266.4l0.3-0.1l-3.4-4.3l-0.3-0.3l-2.1,0.7l-1.5,0.1l3.3,4.4h2.1"
          />
          <path
            fill="#FFFFFF"
            d="M526.6,267.2l-0.3-0.8l-1.7,0.5l0.1,1l0.8,5.2l2.1-0.7 M527.8,275.6l1.4-0.7v-2.5h-1.5"
          />
          <path
            fill="#FFFFFF"
            d="M529.2,274.9l-1.4,0.7c-1.4,0.8-2.5,1.6-3.3,2.6c-0.7,0.7-1.1,1.2-1.5,1.9c-0.3,0.4-0.5,1-0.7,1.4l-0.3,0.8
	c-0.7,1.8-1.2,4.1-1.8,7c-0.7,3.7-1.1,8.1-1.5,13.5l-0.1,2.3h0.1l56,2.9h4.3v-18.7l-3.3-6.7c-1.1-2.3-3-3.7-5.6-4l-16.4-2.6
	l-1.4-0.3l-0.5-0.1l-2.3-0.4l-0.7-0.1l-9.9-1.7C535.5,273.2,532.2,273.6,529.2,274.9L529.2,274.9z M575.8,292.5
	c0.6,0,1.2,0.3,1.7,1.1c0.6,0.8,1,1.9,1.1,3.2c0.1,1.2,0.1,2.3-0.1,3.3s-0.7,1.5-1.2,1.5c-0.4,0.1-1-0.3-1.4-1.2
	c-0.4-0.8-0.8-1.9-1-3.2c-0.1-1.2-0.1-2.3,0-3.3C575,293.1,575.4,292.6,575.8,292.5L575.8,292.5z"
          />
          <path
            fill="#FFFFFF"
            d="M527.8,275.6l-0.1-3.2l-2.1,0.7l-1.2,0.4v1.4h0.1c0.1,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5
	c0,0.1-0.1,0.3-0.1,0.5l-0.1,0.1l-0.3,0.1h-0.1l0.1,1.9C525.4,277.4,526.5,276.5,527.8,275.6L527.8,275.6z"
          />
          <path
            fill="#FFFFFF"
            d="M524.4,274.9c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5l0.4,0.3h0.1l0.3-0.1
	l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.5-0.3L524.4,274.9L524.4,274.9z M518.8,305.2l0.5,0.4
	l1.8,1.4l-0.1,1.8l52.7,2.5l5.2-0.1v-3h-4.3 M592.9,217.3c-0.5-1.8-1.8-2.6-4-2.6c-8.8-0.7-18.4-0.8-28.8-0.4
	c-2.9,0.3-4.1,2.1-4.1,5.2v19c-0.1,2.9,1.8,4.5,5.6,4.8h1.7c-0.1-2.6,1.2-4.3,4.3-4.8c1.7-0.4,2.8,0.4,3,2.2v2.6h16.9
	c3.9,0.1,5.8-1.6,5.6-5.4v-11.7C593.6,222.5,593.3,219.5,592.9,217.3L592.9,217.3z M587.8,219.9c1.7,0,2.5,0.8,2.5,2.5v11.8
	c0,1.7-0.8,2.5-2.5,2.5h-19.1c-1.7,0-2.5-0.8-2.5-2.5v-11.8c0-1.6,0.8-2.5,2.5-2.5H587.8L587.8,219.9z"
          />
          <path
            fill="#FFFFFF"
            d="M590.3,222.3c0-1.6-0.8-2.5-2.5-2.5h-19.1c-1.7,0-2.5,0.8-2.5,2.5v11.8c0,1.6,0.8,2.5,2.5,2.5h19.1
	c1.7,0,2.5-0.8,2.5-2.5L590.3,222.3L590.3,222.3z"
          />
          <path fill="#656565" d="M545.9,230l0.4,2.8l0.5-0.8l-0.1-1.8" />
          <path fill="#FFFFFF" d="M545.9,224.5l-0.3-1.5l-0.7-0.6l0.4,2.9" />
          <path fill="#656565" d="M547.6,243.4l-1.1-0.3l0.3,2.6l1-0.7" />
          <path
            fill="#FFFFFF"
            d="M551.9,249.8h-1c1.1,2.9,1.1,6.1,0.3,9.5l0.7-0.1l4-0.5l4-0.5v-8.3L551.9,249.8L551.9,249.8z"
          />
          <path
            fill="#FFFFFF"
            d="M550.9,249.8h1c-0.3-1.2-0.7-2.1-1.2-2.8c-0.3-0.4-0.5-0.7-0.8-0.8c-0.3-0.3-0.7-0.4-1-0.6h-0.8l0.8,14.6
	v0.4l0.1,2.3h0.8l1.2-1.2c0.8-1.1,1.2-1.9,1-2.6l-0.7,0.1C552,255.8,552,252.7,550.9,249.8L550.9,249.8z M563.5,243.3v5.6h7.3v-8.3
	c-0.3-1.9-1.4-2.6-3-2.2C564.7,239,563.3,240.6,563.5,243.3L563.5,243.3z M571.3,256.4v-6.6H560v8.3"
          />
          <path fill="#656565" d="M568.8,248.9h-3.4v0.8h3.4" />
          <path
            fill="#FFFFFF"
            d="M555.9,265v-6.5l-4,0.5c0.1,0.7-0.1,1.6-1,2.6v2.6h1.1v0.7L555.9,265L555.9,265L555.9,265z M553.7,271.5
	c0-0.8-0.3-1.2-0.5-1.4l-0.7-0.1v5.8l1.4,0.3v-4.5H553.7L553.7,271.5z"
          />
          <path
            fill="#656565"
            d="M364.4,155.5c-0.4,0.8-1.1,1.4-2.1,1.6l10.6-0.3c-1.1-0.1-1.9-0.6-2.3-1.5L364.4,155.5L364.4,155.5z
	 M334.7,156.6l-7.2,0.4c-0.3,0.8-0.8,1.4-1.8,1.6l10.9-0.6C335.8,157.9,335.1,157.3,334.7,156.6L334.7,156.6z"
          />
          <path
            fill="#FFFFFF"
            d="M292,188.4h-0.7v69.3h0.7v-14.9 M304,236.1c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3
	c0.3-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3C304.2,235.6,304,235.9,304,236.1
	L304,236.1z M315.3,231.2c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8
	s0.1,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3C315.2,231.9,315.3,231.6,315.3,231.2L315.3,231.2z M286,219.4
	c0.1-3.2-1.2-5-4.1-5.2c-10.3-0.3-19.9-0.1-28.8,0.4c-2.2,0-3.4,0.8-4,2.6c-0.4,2.2-0.6,5.2-0.6,8.9v11.7c-0.1,3.6,1.8,5.4,5.6,5.4
	h16.9v-2.6c0.3-1.9,1.4-2.6,3-2.2c3,0.6,4.5,2.2,4.3,4.8h1.6c3.9-0.3,5.8-1.9,5.6-4.8v-19L286,219.4L286,219.4z M273.5,219.9
	c1.6,0,2.5,0.8,2.5,2.5v11.8c0,1.7-0.8,2.5-2.5,2.5h-19.1c-1.7,0-2.5-0.8-2.5-2.5v-11.8c0-1.6,0.8-2.5,2.5-2.5L273.5,219.9
	L273.5,219.9z"
          />
          <path
            fill="#FFFFFF"
            d="M276,222.3c0-1.6-0.8-2.5-2.5-2.5h-19.1c-1.7,0-2.5,0.8-2.5,2.5v11.8c0,1.6,0.8,2.5,2.5,2.5h19.1
	c1.7,0,2.5-0.8,2.5-2.5L276,222.3L276,222.3z M271.3,240.7v8.3h7.3v-5.6c0.1-2.6-1.2-4.3-4.3-4.8
	C272.7,238.1,271.7,238.8,271.3,240.7L271.3,240.7z"
          />
          <path fill="#656565" d="M276.6,249.8v-0.8h-3.4v0.8" />
          <path
            fill="#FFFFFF"
            d="M276.6,249.8h-5.9v6.6l11.4,1.7v-8.3 M291.4,247.2c-0.4,0.7-0.8,1.5-1.1,2.6h1c-1,2.6-1.1,5.5-0.5,8.7v-0.1
	c0-0.3,0.1-0.5,0.5-0.5h0.1V247.2z M292.3,246.2l-0.3,0.3v11.4h0.1c0.3,0,0.5,0.1,0.5,0.5v1.5c0.3,0,0.5,0.1,0.5,0.5v0.8
	c0,0.3-0.1,0.5-0.5,0.5v0.8c0,0.3-0.1,0.4-0.3,0.4h0.8l0.1-2.3v-0.4l0.8-14.6h-0.8C293,245.8,292.6,245.9,292.3,246.2L292.3,246.2z"
          />
          <path
            fill="#FFFFFF"
            d="M291.2,249.8h-9.1v8.3l4,0.5l4,0.5l0.5,0.1v-0.8C290,255.3,290.3,252.4,291.2,249.8L291.2,249.8z
	 M266.5,282.6l-3.3,6.7v18.7h4.3l56-2.9h0.1l-0.1-2.1c-0.4-5.4-0.8-9.9-1.5-13.5c-0.5-2.9-1.1-5.2-1.8-7l-0.3-0.8
	c-0.1-0.5-0.4-1-0.7-1.4c-0.4-0.7-1-1.4-1.5-1.9c-0.8-1-1.9-1.8-3.3-2.6l-1.4-0.7c-3-1.4-6.3-1.8-9.6-1.4l-9.9,1.6l-0.7,0.1
	l-2.3,0.4l-0.5,0.1l-1.4,0.3l-16.4,2.6C269.5,278.9,267.6,280.3,266.5,282.6L266.5,282.6z M263.6,300.1c-0.3-1-0.4-2.1-0.1-3.3
	c0.1-1.2,0.5-2.3,1.1-3.2c0.5-0.8,1.1-1.1,1.6-1.1c0.4,0.1,0.8,0.5,1,1.5s0.1,2.1,0,3.3c-0.1,1.2-0.5,2.3-1,3.2
	c-0.4,0.8-0.8,1.2-1.4,1.2C264.3,301.6,263.8,301.1,263.6,300.1L263.6,300.1z"
          />
          <path
            fill="#FFFFFF"
            d="M292.6,259.8v-1.5c0-0.3-0.1-0.5-0.5-0.5h-1c-0.3,0-0.5,0.1-0.5,0.5v1.5H292.6z M293.1,260.4
	c0-0.3-0.1-0.5-0.5-0.5h-2.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v0.8c0,0.3,0.1,0.5,0.5,0.5h2.1c0.3,0,0.5-0.1,0.5-0.5
	v-0.8L293.1,260.4L293.1,260.4z M292.2,263.1h0.3c0.1-0.1,0.3-0.3,0.3-0.4v-0.8h-1.9v0.8c0,0.3,0.1,0.5,0.5,0.5h0.8L292.2,263.1
	L292.2,263.1z"
          />
          <path
            fill="#FFFFFF"
            d="M290.1,259.9c-0.1-0.3-0.1-0.5,0-0.8l-4-0.5v6.5h4v-0.7h1.1v-1.2c-0.3,0-0.5-0.1-0.5-0.5v-0.8h-0.1
	c-0.3,0-0.5-0.1-0.5-0.5v-0.8C290,260.2,290.1,260.1,290.1,259.9L290.1,259.9z"
          />
          <path
            fill="#656565"
            d="M365.8,239.7l-1.2,4.7c3,0.3,5.5,0.1,7.6-0.4l-1-4.4C369.4,239,367.6,239,365.8,239.7L365.8,239.7z
	 M324.1,256.2l-1.4,0.1l-3,2.1c0,0.8-0.5,1.8-1.6,2.9l1,0.3c1.1-1.2,1.7-2.3,1.7-3.2L324.1,256.2L324.1,256.2z"
          />
          <path
            fill="#656565"
            d="M325.3,256.5l-1.2-0.3l-3.4,2.3c0,0.8-0.5,1.9-1.6,3.2l0.1,0.1l2.1,0.7l1.4,0.1l0.8-1.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.4,0.3-0.5s0.3-0.3,0.5-0.3c0.1,0,0.4,0.1,0.5,0.3l0.4-0.5l-1.4-0.8L325.3,256.5L325.3,256.5z
	"
          />
          <path
            fill="#656565"
            d="M324.5,260.4c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.4,0.1-0.5,0.3s-0.3,0.3-0.3,0.5c0,0.1,0.1,0.3,0.1,0.5
	l0.1,0.1l0.3,0.1c0.1,0,0.4-0.1,0.5-0.3l0.3-0.5C324.6,260.6,324.6,260.5,324.5,260.4z M319.3,261.7l-0.3,0.4l-3.6,4.1l0.3,0.1
	l1.8,0.5h2.1l3.2-4.4l-1.4-0.1"
          />
          <path fill="#656565" d="M319,262.1l-1.8,0.1l-3.2,3.7l1.4,0.3" />
          <path
            fill="#FFFFFF"
            d="M317.2,267.9l0.3-1l-1.8-0.5l-0.1,0.8l-1.2,5.2l2.1,0.7 M314.3,272.5H313v2.5l1.4,0.7"
          />
          <path
            fill="#FFFFFF"
            d="M316.4,273.2l-2.1-0.5l-0.1,3.2c1.4,0.8,2.5,1.6,3.3,2.6l0.1-1.9h-0.1l-0.3-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3h0.1v-1.4L316.4,273.2L316.4,273.2z"
          />
          <path
            fill="#FFFFFF"
            d="M318,275.1l-0.4-0.1h-0.1c-0.1,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.3,0.1,0.5l0.1,0.1
	l0.3,0.1h0.1l0.4-0.3c0.1-0.1,0.3-0.3,0.3-0.5C318.3,275.4,318.2,275.2,318,275.1L318,275.1z M322.9,305.6l0.5-0.4l-56,2.9h-4.1v3
	l5.1,0.1l52.8-2.5l-0.1-1.8"
          />
          <path
            fill="#656565"
            d="M352.4,324.3l-1.6-0.3h-33.2l-2.1,0.4l2.6,40.6l0.7,0.3l23.7-0.4h8.5l2.9-0.3"
          />
          <path
            fill="#656565"
            d="M356.7,326.4c-1.4-1-2.8-1.6-4.3-2.1l1.5,40.3c0.8-0.1,1.7-0.4,2.5-1l1.6-0.7L356.7,326.4L356.7,326.4z"
          />
          <path
            fill="#FFFFFF"
            d="M325.2,324.1l-0.8-9.1l-0.5-6.3l-2.6,0.1l-52.7,2.5l-5.2-0.1v36.3c0,2.6,0.8,6.3,2.5,10.9
	c0.3,0.4,1,0.7,2.1,0.7h6.9c-1.2-2.6-2.2-6.2-2.8-10.9l-0.4-17.6c0-1.6,1-2.5,2.6-2.6l37.3-1.6c1.5-1,2.9-1.6,4.1-1.9l1.9-0.3
	L325.2,324.1L325.2,324.1L325.2,324.1z"
          />
          <path
            fill="#656565"
            d="M315.6,324.5c-1.2,0.4-2.6,1-4.1,1.9l2.1,37.1c1.5,0.3,3,0.8,4.5,1.5L315.6,324.5L315.6,324.5z"
          />
          <path
            fill="#656565"
            d="M342.5,368.2v-3.3l-23.7,0.4l-0.7-0.3h-9.6v3.2 M342.5,364.9v3.3h7.4c0.1-2.1,0.5-3.2,1.1-3.3H342.5z"
          />
          <path
            fill="#FFFFFF"
            d="M308.6,365h9.6c-1.5-0.7-3-1.2-4.5-1.5l-5.1-0.5V365z"
          />
          <path
            fill="#656565"
            d="M308.6,368.2V363l-28.6-2.5c-2.8-0.1-4.4-0.5-5.2-1.4h-6.9c-1.1,0-1.9-0.3-2.1-0.7
	c-1.6-4.5-2.5-8.3-2.5-10.9v-33.4l-2.9,3c-0.5,0.4-1,1.9-1.2,4.3v110.7c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2
	c6.5,0.1,12.2-0.3,16.9-1.1c4-1.2,6.2-3.9,6.6-7.7v-54.8L308.6,368.2L308.6,368.2z M308.6,368.2v8.9h225.1v-8.9H342.5"
          />
          <path
            fill="#656565"
            d="M308.6,362.8l5.1,0.5l-2.1-37.1l-37.3,1.6c-1.6,0-2.6,0.8-2.6,2.6l0.4,17.6c0.5,4.7,1.5,8.3,2.8,10.9
	c0.8,0.8,2.6,1.2,5.2,1.4L308.6,362.8L308.6,362.8z M279.7,342.2c0-2.5,0.8-4.5,2.6-6.2c1.7-1.6,3.9-2.6,6.2-2.6
	c2.5,0,4.5,0.8,6.2,2.6c1.7,1.8,2.6,3.9,2.6,6.2c0,2.5-0.8,4.5-2.6,6.2c-1.8,1.8-3.9,2.6-6.2,2.6c-2.5,0-4.5-0.8-6.2-2.6
	C280.5,346.8,279.7,344.7,279.7,342.2L279.7,342.2z"
          />
          <path
            fill="#656565"
            d="M282.3,336c-1.8,1.8-2.6,3.9-2.6,6.2c0,2.5,0.8,4.5,2.6,6.2c1.7,1.8,3.9,2.6,6.2,2.6c2.5,0,4.5-0.8,6.2-2.6
	c1.7-1.6,2.6-3.9,2.6-6.2c0-2.5-0.8-4.5-2.6-6.2c-1.8-1.6-3.9-2.6-6.2-2.6C286,333.4,283.9,334.2,282.3,336L282.3,336z M281.3,342.2
	c0-1.9,0.7-3.7,2.1-5.1c1.4-1.4,3-2.1,5.1-2.1c1.9,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1c0,1.9-0.7,3.7-2.1,5.1s-3,2.1-5.1,2.1
	c-1.9,0-3.7-0.7-5.1-2.1C282,345.9,281.3,344.3,281.3,342.2L281.3,342.2z M308.6,377.2v4.4h225.1v-4.4 M376.8,368.2v-5.1h-18.6
	l-1.7,0.7c-0.8,0.4-1.6,0.8-2.5,1l-2.8,0.1c-0.5,0.1-1,1.2-1.1,3.3L376.8,368.2L376.8,368.2z M378.4,363.1v5.1h121.1v-3.3l-7.3-0.1
	H489l-1-0.1c-0.8-0.1-1.6-0.4-2.5-1l-1.6-0.7H378.4L378.4,363.1L378.4,363.1z"
          />
          <path
            fill="#656565"
            d="M376.8,363.1h107.3l1.4-36.7H356.7l1.4,36.7H376.8L376.8,363.1z M371.8,332c0-0.7,0.3-1,1-1h96.6
	c0.7,0,1,0.3,1,1v22.1c0,0.7-0.3,1-1,1h-96.6c-0.7,0-1-0.3-1-1L371.8,332L371.8,332z"
          />
          <path
            fill="#FFFFFF"
            d="M578.9,311.1l-5.2,0.1l-52.7-2.5l-2.6-0.1l-0.5,6.3l-0.8,9.1h7.6l1.9,0.3c1.2,0.4,2.6,1,4.1,1.9l37.3,1.6
	c1.7,0,2.6,0.8,2.6,2.6l-0.4,17.6c-0.5,4.7-1.5,8.3-2.8,10.9h6.9c1.1,0,1.9-0.3,2.1-0.7c1.7-4.5,2.5-8.3,2.5-10.9v-36.3L578.9,311.1
	L578.9,311.1z"
          />
          <path
            fill="#656565"
            d="M581.8,317l-2.9-3v33.4c0,2.6-0.8,6.3-2.5,10.9c-0.3,0.4-1,0.7-2.1,0.7h-6.9c-0.8,0.8-2.6,1.2-5.4,1.4
	l-28.6,2.5v69.1c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1c4-1.2,6.2-3.9,6.6-7.7V321.2
	C582.7,319,582.3,317.6,581.8,317L581.8,317z"
          />
          <path
            fill="#656565"
            d="M570.2,348.1l0.4-17.6c0-1.6-1-2.5-2.6-2.6l-37.3-1.6l-2.1,37.1l5.1-0.5l28.6-2.5c2.8-0.1,4.4-0.5,5.4-1.4
	C568.7,356.4,569.5,352.8,570.2,348.1L570.2,348.1z M559.9,336c1.8,1.8,2.6,3.9,2.6,6.2c0,2.5-0.8,4.5-2.6,6.2
	c-1.7,1.8-3.9,2.6-6.2,2.6c-2.5,0-4.5-0.8-6.2-2.6c-1.7-1.6-2.6-3.9-2.6-6.2c0-2.5,0.8-4.5,2.6-6.2c1.8-1.6,3.9-2.6,6.2-2.6
	C556.2,333.4,558.2,334.2,559.9,336L559.9,336z"
          />
          <path
            fill="#656565"
            d="M562.5,342.2c0-2.5-0.8-4.5-2.6-6.2c-1.7-1.6-3.9-2.6-6.2-2.6c-2.5,0-4.5,0.8-6.2,2.6
	c-1.7,1.8-2.6,3.9-2.6,6.2c0,2.5,0.8,4.5,2.6,6.2c1.8,1.8,3.9,2.6,6.2,2.6c2.5,0,4.5-0.8,6.2-2.6
	C561.7,346.8,562.5,344.7,562.5,342.2L562.5,342.2z M558.8,337.3c1.4,1.4,2.1,3,2.1,5.1c0,1.9-0.7,3.7-2.1,5.1
	c-1.4,1.4-3,2.1-5.1,2.1c-1.9,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3-2.1-5.1c0-1.9,0.7-3.7,2.1-5.1c1.4-1.4,3-2.1,5.1-2.1
	C555.6,335.1,557.3,335.7,558.8,337.3L558.8,337.3z M530.6,326.4c-1.5-1-2.9-1.6-4.1-1.9l-2.6,40.6c1.5-0.7,3-1.2,4.5-1.5
	L530.6,326.4z"
          />
          <path
            fill="#656565"
            d="M524.5,324.1h-33.2l-1.5,0.3l-1.5,40.3l1,0.1c1,0.1,2.1,0.1,3.2,0l7.3,0.1l23.8,0.4l0.7-0.3l2.6-40.6
	L524.5,324.1L524.5,324.1z M489.7,324.3c-1.5,0.3-3,1-4.3,2.1l-1.4,36.7l1.6,0.7c0.8,0.4,1.6,0.8,2.5,1L489.7,324.3L489.7,324.3z"
          />
          <path fill="#656565" d="M499.5,364.9v3.3h34.1V365H524l-0.7,0.3" />
          <path
            fill="#FFFFFF"
            d="M539.4,167.9l-0.3-1.2l-2.6,2.3c0.3,0.7,0.5,1.5,0.8,2.5l6.2,48.4l3,23.2l1.1,0.3l-1.4-10.9l-0.3-2.8
	l-0.5-4.7l-0.4-2.8l-5.9-45.8l1-0.8l9.9,70.3l0.8,0.8L539.4,167.9L539.4,167.9z"
          />
          <path
            fill="#FFFFFF"
            d="M536.5,169c-1.4-2.9-3.9-4.4-7.4-4.7c-34.7-2.2-68.9-3.3-102.8-3.3h-5.9c-35.5,0-71.3,1.1-107.4,3.3
	c-3.6,0.3-6.1,1.8-7.4,4.7c-0.3,0.7-0.5,1.5-0.8,2.5l-6.2,48.4c2.5-1.6,5.1-2.6,7.6-2.5l5.9-41.7c1.1-4.4,3.3-6.6,6.5-6.5
	c30-1.6,59.4-2.6,88.5-2.9h28.2c29,0.3,58.5,1.2,88.5,2.9c3.2-0.1,5.4,2.1,6.5,6.5l5.9,41.7c2.5,0,5,0.8,7.6,2.5l-6.2-48.4
	C537.2,170.5,536.9,169.7,536.5,169L536.5,169z M539.8,175.8l-1,0.8l5.9,45.8l0.8,0.4l0.3,1.5l-0.7,0.8l0.5,4.7l0.8,0.1l0.1,1.6
	l-0.5,0.8l1.4,10.9l0.1,1.5l0.1,0.6h0.8c0.4,0.1,0.7,0.3,1,0.6L539.8,175.8z M452.4,229.5v0.1h0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.3v0.1
	l-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.1c0,0.3-0.1,0.6-0.5,0.6h-61.8c-0.3,0-0.5-0.1-0.5-0.6h-0.1l-0.3-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.3v-0.1c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3-0.1,0.3-0.1h0.1v-0.1h-19l-49.8,1.5l-0.3,7.8l13.5-1.2
	c0.1-0.1,0.4-0.3,0.5-0.3l9.5-0.6l9.5-0.6c0.3,0,0.4,0.1,0.7,0.3l2.1-0.1c21.7-1.1,42.9-1.6,63.4-1.5h2.8
	c20.5-0.1,41.5,0.4,63.4,1.5l2.1,0.1c0.1-0.1,0.4-0.3,0.7-0.3l9.5,0.6l9.5,0.6c0.3,0,0.4,0.1,0.5,0.3l13.5,1.2l-0.3-7.8l-49.9-1.5
	L452.4,229.5L452.4,229.5z"
          />
          <path
            fill="#FFFFFF"
            d="M452.4,229.8v-0.3c0-0.3-0.1-0.4-0.5-0.4h-61.8c-0.3,0-0.4,0.1-0.5,0.4v1.2c0,0.3,0.1,0.6,0.5,0.6h61.8
	c0.3,0,0.5-0.1,0.5-0.6L452.4,229.8L452.4,229.8z"
          />
          <path
            fill="#FFFFFF"
            d="M451.9,229.1c0.3,0,0.4,0.1,0.5,0.4h19l49.8,1.5v-4.5c-34.7-1.2-68-1.6-100-1.2c-32.2-0.4-65.5,0-100.1,1.2
	v4.5l49.8-1.5h19c0-0.3,0.1-0.4,0.5-0.4H451.9L451.9,229.1z"
          />
          <path
            fill="#6B9BD2"
            d="M453.1,230.2L453,230c-0.1-0.1-0.3-0.1-0.3-0.1h-0.1v1.1h0.1c0.1,0,0.3,0,0.3-0.1L453.1,230.2L453.1,230.2z"
          />
          <path
            fill="#FFFFFF"
            d="M494.5,257.6c-21.2-1.2-43.7-1.8-67.7-1.8H415c-23.9,0-46.5,0.6-67.7,1.8c-13.1,0.4-21.6,4.7-25.3,12.7
	l-0.3,0.5c0,0.4-0.1,0.7-0.1,1.1h3.9c0.8-2.1,2.2-3.9,3.9-5.5c3.4-3.4,7.7-5.2,12.7-5.2s9.2,1.8,12.7,5.2c1.6,1.6,3,3.6,3.9,5.5h7.2
	c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.7c0.3-1,0.8-1.8,1.5-2.5
	c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.8c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8
	c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.4c0.1-1.5,0.7-2.8,1.8-3.9c1-1,1.9-1.5,3.2-1.8c0.1-0.1,0.1-0.1,0.3-0.1h1.1
	c0.1,0,0.1,0,0.3,0.1c1.2,0.3,2.2,0.8,3.2,1.8c1.1,1.1,1.6,2.3,1.8,3.9h4.4c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8
	c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.8c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8
	c0.8,0.8,1.2,1.6,1.5,2.5h4.7c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h7.2
	c0.8-2.1,2.1-3.9,3.9-5.5c3.4-3.4,7.7-5.2,12.7-5.2c5,0,9.2,1.8,12.7,5.2c1.7,1.6,3,3.6,3.9,5.5h3.8c0-0.4-0.1-0.7-0.1-1.1l-0.3-0.5
	C516.1,262.3,507.7,258,494.5,257.6L494.5,257.6z M421.8,261.9c0.3-0.3,0.5-0.5,1-0.7c0.3-0.1,0.7-0.3,1.1-0.3l1.2,0.1
	c0.8,0.1,1.2,0.4,1.5,1l0.3,0.7v0.5h-3.7l0.1,0.4c0.1,0.3,0.4,0.4,0.7,0.4h0.4c0.3,0,0.4-0.1,0.6-0.3h1.9v0.1l-0.3,0.4
	c-0.3,0.3-0.7,0.4-1.1,0.5L424,265l-1-0.1c-0.7-0.3-1.2-0.5-1.5-1.1c-0.1-0.1-0.1-0.4-0.1-0.7C421.2,262.7,421.5,262.3,421.8,261.9
	L421.8,261.9z M430.2,261l1.5,0.1c0.4,0.1,0.8,0.4,1.1,0.7l0.3,0.7c0.1,0.4,0.1,1-0.1,1.4c-0.1,0.3-0.3,0.5-0.5,0.7
	c-0.4,0.3-0.8,0.5-1.4,0.5l-1.2-0.1l-0.5-0.3h-0.1v1.4h-1.9V261h1.8v0.3h0.3l0.1-0.1C429.6,261.2,429.9,261.1,430.2,261L430.2,261z
	 M415.7,262l0.8-0.8c0.4-0.1,0.8-0.3,1.2-0.3l1.2,0.1c0.8,0.1,1.4,0.5,1.6,1l0.3,0.7l0.1,0.5h-3.7v0.3l0.1,0.3
	c0.1,0.1,0.3,0.3,0.5,0.3h0.5c0.3,0,0.4-0.1,0.5-0.3h1.8v0.1l-0.3,0.4c-0.3,0.3-0.5,0.4-1,0.5l-1.5,0.1l-1-0.1
	c-0.7-0.1-1.2-0.5-1.5-1.1c-0.1-0.3-0.3-0.5-0.1-1C415.4,262.6,415.6,262.3,415.7,262L415.7,262z M413.1,259.4h1.9v3.7
	c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.4-0.8,0.7-1.5,0.8l-1,0.1h-1.1l-1-0.1c-0.7-0.1-1.1-0.5-1.2-1l-0.1-0.5v-0.8h1.9v0.7l0.1,0.3
	c0.1,0.3,0.4,0.4,0.8,0.4l0.8-0.1c0.3-0.1,0.5-0.3,0.5-0.5L413.1,259.4L413.1,259.4z M464.3,271.9h-4.7c0.1,0.5,0.3,1.2,0.3,1.8
	v14.6h4.1v-14.7C464,273,464.1,272.5,464.3,271.9L464.3,271.9z M447.5,273.7c0-0.7,0.1-1.2,0.3-1.8H443c0.1,0.5,0.3,1.2,0.3,1.8
	v14.6h4.3L447.5,273.7L447.5,273.7z M431.1,271.9h-4.4v16.4h4.1v-14.7C430.9,273,431,272.5,431.1,271.9L431.1,271.9z M520.3,271.9
	h-3.9c1,2.2,1.4,4.5,1.4,7.2c0,3.3-0.8,6.3-2.3,8.9h5C520.7,282.2,520.5,276.9,520.3,271.9L520.3,271.9z M483.3,271.9h-7.2
	c0.1,0.5,0.3,1.2,0.3,1.8V288h7.8c-1.6-2.6-2.3-5.6-2.3-8.9C481.9,276.5,482.3,274,483.3,271.9L483.3,271.9z"
          />
          <path
            fill="#FFFFFF"
            d="M366.1,271.9h-7.2c1,2.2,1.4,4.5,1.4,7.2c0,3.3-0.8,6.3-2.3,8.9h7.8v-14.3
	C365.8,273,365.8,272.5,366.1,271.9L366.1,271.9z M325.8,271.9h-3.9c-0.4,5-0.4,10.3-0.1,16.1h5c-1.6-2.6-2.3-5.6-2.3-8.9
	C324.4,276.5,324.8,274,325.8,271.9L325.8,271.9z M415.4,271.9H411c0.1,0.5,0.3,1.2,0.3,1.8v14.6h4.1v-15.8V271.9L415.4,271.9z
	 M398.9,273.7c0-0.7,0.1-1.2,0.3-1.8h-4.8c0.1,0.5,0.3,1.2,0.3,1.8v14.6h4.3L398.9,273.7L398.9,273.7z M382.4,271.9h-4.7
	c0.1,0.5,0.3,1.2,0.3,1.8v14.6h4.1v-14.7C382.2,273,382.3,272.5,382.4,271.9L382.4,271.9z"
          />
          <path
            fill="#FFFFFF"
            d="M415.4,288h-4.1v16.1h4.1v-15 M398.9,289.1V288h-4.4v16.1h4.4v-13.9 M382.2,288h-4v16.1h4v-15"
          />
          <path
            fill="#FFFFFF"
            d="M382.2,304.1H378v1.4c0,0.3-0.1,0.5-0.1,1c-0.1,0.4-0.5,0.8-1,1.1l-0.7,0.4c-1.2,0.7-2.6,1-4.4,1
	c-1.6,0-3.2-0.3-4.4-1c-1.2-0.7-1.8-1.5-1.8-2.5v-1.4h-20.1c1,1.4,1.4,2.9,1.4,4.7c0,2.3-0.8,4.3-2.5,5.9c-1.7,1.6-3.6,2.5-5.9,2.5
	c-2.3,0-4.3-0.8-5.9-2.5c-1.6-1.7-2.5-3.6-2.5-5.9c0-1.8,0.4-3.3,1.4-4.7h-8.1v0.1l0.1,1.2c1.8,6.7,4.5,11,8.1,12.7
	c2.3,1.4,8.9,2.1,19.9,1.9h138.5c11,0.1,17.6-0.5,19.9-1.9c3.6-1.8,6.3-5.9,8.1-12.7l0.1-1.2v-0.1h-8c1,1.4,1.4,2.9,1.4,4.7
	c0,2.3-0.8,4.3-2.5,5.9c-1.6,1.6-3.6,2.5-5.9,2.5s-4.3-0.8-5.9-2.5c-1.7-1.7-2.5-3.6-2.5-5.9c0-1.8,0.4-3.3,1.4-4.7h-20.1v1.4
	c0,1-0.5,1.8-1.8,2.5c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.6-0.3H465c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.3-0.3-0.5-0.3-1v-1.4
	h-4.1v1.4c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.3,0.1c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3
	c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.4h-4.3v1.4c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3
	c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.4h-4.1v1.2
	c0,0.4-0.1,0.7-0.3,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3c-1,0.5-1.9,0.8-3,1l-0.3,0.1h-1.1l-0.3-0.1c-1.2-0.1-2.2-0.4-3.2-1
	l-0.5-0.3c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.7-0.3-1v-1.2h-4.1v1.4c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3
	c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.4h-4.3v1.4
	c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3
	c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.1L382.2,304.1L382.2,304.1z M365.8,288h-7.8c-0.8,1.2-1.8,2.5-2.9,3.7
	c-3.4,3.6-7.7,5.2-12.7,5.2s-9.2-1.8-12.7-5.2c-1.1-1.1-2.2-2.3-2.9-3.7h-5c0,0.5,0.1,1,0.1,1.5c0.7,3.7,1.1,8.1,1.5,13.5l0.1,1h8.1
	c0.3-0.4,0.7-0.8,1.1-1.2c1.6-1.6,3.6-2.5,5.9-2.5c2.3,0,4.3,0.8,5.9,2.5c0.4,0.4,0.7,0.8,1.1,1.2h20.1v-16H365.8L365.8,288z"
          />
          <path
            fill="#FFFFFF"
            d="M520.3,288h-5c-0.8,1.2-1.8,2.5-2.9,3.7c-3.4,3.6-7.7,5.2-12.7,5.2c-5,0-9.2-1.8-12.7-5.2
	c-1.1-1.1-2.1-2.3-2.9-3.7h-7.8v16.1h20.1c0.3-0.4,0.7-0.8,1.1-1.2c1.6-1.6,3.6-2.5,5.9-2.5c2.3,0,4.3,0.8,5.9,2.5
	c0.4,0.4,0.7,0.8,1.1,1.2h8.1l0.1-1c0.4-5.4,0.8-9.9,1.5-13.5L520.3,288L520.3,288z M464,288h-4.1v16.1h4.1v-15 M447.5,289.1V288
	h-4.3v16.1h4.3v-13.9 M430.9,288h-4.1v16.1h4.1v-15 M421.8,261.9c-0.3,0.4-0.4,0.8-0.4,1.2c0,0.3,0,0.4,0.1,0.7
	c0.3,0.5,0.8,1,1.5,1.1l1,0.1l1.5-0.1c0.4-0.1,0.8-0.3,1.1-0.5l0.3-0.4v-0.1h-1.9c-0.1,0.1-0.3,0.3-0.5,0.3H424
	c-0.4,0-0.5-0.1-0.7-0.4l-0.1-0.4h3.7v-0.5l-0.3-0.7c-0.3-0.5-0.8-0.8-1.5-1l-1.2-0.1c-0.4,0-0.8,0.1-1.1,0.3
	C422.3,261.3,421.9,261.6,421.8,261.9L421.8,261.9z M423.3,262.4v-0.1l0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.3h0.5c0.3,0.1,0.5,0.3,0.7,0.5
	v0.3h-1.9L423.3,262.4L423.3,262.4z"
          />
          <path
            fill="#6B9BD2"
            d="M423.3,262.3v0.1h1.9v-0.3c-0.1-0.3-0.3-0.4-0.7-0.5H424c-0.3,0-0.4,0.1-0.5,0.3L423.3,262.3L423.3,262.3z"
          />
          <path
            fill="#FFFFFF"
            d="M417.2,263.5v-0.3h3.7v-0.7l-0.3-0.7c-0.3-0.5-0.8-0.8-1.7-1l-1.2-0.1c-0.4,0-0.8,0.1-1.2,0.3
	s-0.7,0.4-0.8,0.8c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.4,0,0.7,0.1,1c0.3,0.5,0.8,0.8,1.5,1.1l1,0.1l1.5-0.1c0.4-0.1,0.8-0.3,1-0.5
	l0.3-0.4v-0.1H419c-0.1,0.1-0.3,0.3-0.5,0.3h-0.5c-0.3,0-0.4-0.1-0.5-0.3L417.2,263.5L417.2,263.5z M417.4,262.4l0.1-0.4
	c0.1-0.1,0.3-0.3,0.5-0.3h0.5c0.4,0.1,0.5,0.3,0.7,0.4v0.3H417.4L417.4,262.4z"
          />
          <path
            fill="#6B9BD2"
            d="M417.4,262.4l1.9,0.1v-0.3c-0.1-0.3-0.3-0.4-0.7-0.4h-0.5c-0.3,0-0.4,0.1-0.5,0.3L417.4,262.4L417.4,262.4z
	 M420.5,266.1c-0.1,0-0.1,0-0.3,0.1l0.8-0.1H420.5L420.5,266.1z"
          />
          <path
            fill="#6B9BD2"
            d="M421.8,266.3c-0.1-0.1-0.1-0.1-0.3-0.1h-0.5L421.8,266.3L421.8,266.3z"
          />
          <path
            fill="#FFFFFF"
            d="M415.4,278.2v1.2h11.3v-1.2 M415.4,285.5v1.2h11.3v-1.2 M415.4,289.1v1.1h11.3v-1.1 M415.4,292.4v1.2h11.3
	v-1.2 M415.4,296v1.1h11.3V296 M415.4,303.3v1.1h11.3v-1.1 M415.4,299.7v1.2h11.3v-1.2 M415.4,281.8v1.2h11.3v-1.2"
          />
          <path
            fill="#6B9BD2"
            d="M421.5,308.9l0.4-0.1l-0.8,0.1 M421.1,308.9l-0.8-0.1l0.3,0.1"
          />
          <path
            fill="#FFFFFF"
            d="M416.7,307.5h8.7c0.5-0.4,0.8-0.8,1.1-1.2h-10.9C415.9,306.9,416.1,307.1,416.7,307.5z M415,263.1v-3.7h-1.9
	v3.7c-0.1,0.3-0.3,0.4-0.5,0.5l-0.8,0.1c-0.4,0-0.7-0.1-0.8-0.4l-0.1-0.3v-0.7h-1.9v0.8l0.1,0.5c0.1,0.4,0.5,0.8,1.2,1l1,0.1h1.1
	l1-0.1c0.7-0.1,1.1-0.4,1.5-0.8C414.9,263.7,415,263.4,415,263.1L415,263.1z M411.3,279.5v-1.2h-12.4v1.2 M398.9,285.5v1.2h12.4
	v-1.2 M398.9,289.1v1.1h12.4v-1.1 M398.9,292.4v1.2h12.4v-1.2 M398.9,296v1.1h12.4V296 M398.9,299.7v1.2h12.4v-1.2 M398.9,303.3v1.1
	h12.4v-1.1 M411.3,283.1v-1.2h-12.4v1.2 M409.9,307.5c0.5-0.4,0.8-0.8,1.1-1.2h-12c0.1,0.4,0.5,0.8,1.1,1.2H409.9L409.9,307.5z
	 M394.5,279.5v-1.2h-12.4v1.2 M394.5,286.8v-1.2h-12.4v1.2 M394.5,293.6v-1.2h-12.4v1.2 M394.5,290.2v-1.1h-12.4v1.1 M394.5,297.1
	V296h-12.4v1.1 M394.5,300.9v-1.2h-12.4v1.2 M394.5,304.4v-1.1h-12.4v1.1 M394.5,283.1v-1.2h-12.4v1.2 M382.4,306.4
	c0.1,0.4,0.5,0.8,1.1,1.2h9.8c0.5-0.4,0.8-0.8,1.1-1.2H382.4L382.4,306.4z M447.5,278.2v1.2h12.4v-1.2 M459.9,281.8h-12.4v1.2h12.4
	 M459.9,286.8v-1.2h-12.4v1.2 M459.9,290.2v-1.1h-12.4v1.1 M459.9,293.6v-1.2h-12.4v1.2 M447.5,303.3v1.1h12.4v-1.1 M447.5,299.7
	v1.2h12.4v-1.2 M447.5,296v1.1h12.4V296 M458.6,307.5c0.5-0.4,0.8-0.8,1.1-1.2h-12c0.1,0.4,0.6,0.8,1.1,1.2H458.6L458.6,307.5z
	 M465.2,279.5v-1.2H464v1.2 M464,279.5v2.3h1.2v-2.3"
          />
          <path
            fill="#FFFFFF"
            d="M465.2,281.8H464v1.2h1.2 M464,286.8h1.2v-1.2H464 M465.2,286.8H464v2.3h1.2"
          />
          <path
            fill="#FFFFFF"
            d="M464,285.5h1.2v-2.5H464 M465.2,290.2v-1.1H464v1.1 M465.2,290.2H464v2.2h1.2 M465.2,293.6v-1.2H464v1.2"
          />
          <path
            fill="#FFFFFF"
            d="M465.2,293.6H464v2.3h1.2 M464,299.7h1.2v-2.6H464 M465.2,299.7H464v1.2h1.2 M464,297.1h1.2V296H464
	 M465.2,304.4v-1.1H464v1.1"
          />
          <path
            fill="#FFFFFF"
            d="M465.2,303.3v-2.3H464v2.3 M465.2,304.4H464v1.1c0,0.3,0.1,0.5,0.3,1h1V304.4z"
          />
          <path
            fill="#6B9BD2"
            d="M464.3,306.4c0.1,0.4,0.5,0.8,1,1.1v-1.1H464.3z"
          />
          <path
            fill="#FFFFFF"
            d="M430.9,278.2v1.2h12.4v-1.2 M443.2,281.8h-12.4v1.2h12.4 M443.2,293.6v-1.2h-12.4v1.2 M443.2,290.2v-1.1
	h-12.4v1.1 M443.2,286.8v-1.2h-12.4v1.2 M430.9,296v1.1h12.4V296 M430.9,303.3v1.1h12.4v-1.1 M430.9,299.7v1.2h12.4v-1.2 M442,307.5
	c0.5-0.4,0.8-0.8,1.1-1.2h-12c0.1,0.4,0.6,0.8,1.1,1.2H442z M433,262.4l-0.3-0.7c-0.3-0.3-0.5-0.5-1.1-0.7l-1.5-0.1
	c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1h-0.3V261h-1.8v5.1h1.9v-1.4c0,0,0-0.1,0.1,0l0.5,0.3l1.2,0.1c0.5,0,1-0.1,1.4-0.5
	c0.3-0.1,0.4-0.4,0.5-0.7C433.2,263.4,433.2,263,433,262.4L433,262.4z M431.3,262.7v0.8l-0.1,0.3c-0.1,0.3-0.4,0.3-0.8,0.3h-0.4
	c-0.3,0-0.4-0.1-0.5-0.3l-0.1-0.4v-0.3l0.1-0.5l0.1-0.3c0.1-0.3,0.5-0.3,1-0.3h0.3c0.3,0,0.4,0.1,0.5,0.3L431.3,262.7L431.3,262.7z"
          />
          <path
            fill="#FFFFFF"
            d="M431.1,263.5c0.1-0.3,0.1-0.5,0-0.8l-0.1-0.3c-0.1-0.1-0.3-0.3-0.5-0.3h-0.3c-0.4,0-0.7,0.1-1,0.3v0.1
	l-0.1,0.5v0.3l0.1,0.4c0.1,0.1,0.3,0.3,0.5,0.3h0.4c0.3,0,0.5-0.1,0.8-0.3L431.1,263.5L431.1,263.5z M499.8,261
	c-5,0-9.2,1.8-12.7,5.2c-3.4,3.4-5.2,7.7-5.2,12.7c0,5,1.8,9.2,5.2,12.7c3.4,3.6,7.7,5.2,12.7,5.2c5,0,9.2-1.8,12.7-5.2
	c3.4-3.4,5.2-7.7,5.2-12.7s-1.8-9.2-5.2-12.7C509,262.8,504.7,261.1,499.8,261L499.8,261z M487.9,267.2c3.3-3.3,7.1-4.8,11.8-4.8
	s8.5,1.6,11.8,4.8c3.3,3.3,5,7.2,5,11.8c0,4.7-1.7,8.5-5,11.8c-3.3,3.3-7.2,5-11.8,5s-8.5-1.6-11.8-5c-3.3-3.3-4.8-7.2-4.8-11.8
	C483.1,274.4,484.8,270.4,487.9,267.2L487.9,267.2z M503.3,300.4c-2.3,0-4.3,0.8-5.9,2.5c-1.6,1.7-2.5,3.6-2.5,5.9s0.8,4.3,2.5,5.9
	c1.6,1.6,3.6,2.5,5.9,2.5s4.3-0.8,5.9-2.5c1.6-1.7,2.5-3.6,2.5-5.9s-0.8-4.3-2.5-5.9C507.8,301.2,505.7,300.4,503.3,300.4
	L503.3,300.4z M498.1,303.4c1.5-1.5,3.3-2.2,5.4-2.2c2.1,0,3.9,0.7,5.4,2.2c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4
	c-1.5,1.5-3.3,2.2-5.4,2.2c-2.1,0-3.9-0.7-5.4-2.2c-1.5-1.5-2.2-3.3-2.2-5.4C495.9,306.7,496.6,304.9,498.1,303.4L498.1,303.4z
	 M342.3,261c-5,0-9.2,1.8-12.7,5.2c-3.4,3.4-5.2,7.7-5.2,12.7c0,5,1.8,9.2,5.2,12.7c3.4,3.6,7.7,5.2,12.7,5.2c5,0,9.2-1.8,12.7-5.2
	c3.4-3.4,5.2-7.7,5.2-12.7s-1.8-9.2-5.2-12.7C351.5,262.8,347.2,261.1,342.3,261L342.3,261z M330.4,267.2c3.3-3.3,7.2-4.8,11.8-4.8
	s8.5,1.6,11.8,4.8c3.3,3.3,4.8,7.2,4.8,11.8c0,4.7-1.6,8.5-4.8,11.8c-3.3,3.3-7.2,5-11.8,5c-4.5,0-8.5-1.6-11.8-5
	c-3.3-3.3-5-7.2-5-11.8C325.6,274.4,327.3,270.4,330.4,267.2L330.4,267.2z M344.6,302.9c-1.6-1.6-3.6-2.5-5.9-2.5
	c-2.3,0-4.3,0.8-5.9,2.5c-1.6,1.7-2.5,3.6-2.5,5.9s0.8,4.3,2.5,5.9c1.6,1.6,3.6,2.5,5.9,2.5c2.3,0,4.3-0.8,5.9-2.5
	c1.6-1.7,2.5-3.6,2.5-5.9S346.2,304.5,344.6,302.9L344.6,302.9z M338.7,301.2c2.1,0,3.9,0.7,5.4,2.2s2.2,3.3,2.2,5.4
	c0,2.1-0.7,3.9-2.2,5.4s-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2s-2.2-3.3-2.2-5.4c0-2.1,0.7-3.9,2.2-5.4
	C334.8,302,336.6,301.2,338.7,301.2z M376.9,278.2v1.2h1.2v-1.2 M376.9,279.5v2.3h1.2v-2.3"
          />
          <path fill="#FFFFFF" d="M378.2,281.8h-1.2v1.2h1.2" />
          <path
            fill="#FFFFFF"
            d="M378.2,286.8v-1.2h-0.8h0.8v-2.5h-1.2v9.4h1.2v-2.2h-0.8h0.8v-1.1h-0.8h0.8V286.8l-0.8,0 M378.2,293.6v-1.2
	h-1.2v1.2"
          />
          <path
            fill="#FFFFFF"
            d="M378.2,293.6h-1.2v2.3h1.2 M376.9,299.7h1.2v-2.6h-1.2 M378.2,299.7h-1.2v1.2h1.2"
          />
          <path
            fill="#FFFFFF"
            d="M378.2,296h-1.2v1.1h1.2 M378.2,303.3v-2.3h-1.2v2.3 M378.2,304.4v-1.1h-1.2v1.1 M378.2,305.5v-1.1h-1.2v1.9
	h1C378,306,378.2,305.8,378.2,305.5z"
          />
          <path
            fill="#6B9BD2"
            d="M377.9,306.4h-1v1.1C377.3,307.1,377.7,306.9,377.9,306.4z M426.6,255.8h0.3h-2.3 M389.2,230
	c-0.1,0.1-0.1,0.3-0.1,0.3v0.1c0,0.1,0,0.3,0.1,0.3l0.3,0.1h0.1v-1.1h-0.1C389.4,229.8,389.3,229.8,389.2,230L389.2,230z"
          />
          <path
            fill="#FFFFFF"
            d="M418.1,238.6h6.1c14.6-0.1,30.1,0.3,46.8,1c1.8-0.6,3.6-0.6,5.4,0.1c15.5,0.4,30.3,1.4,44.3,2.9
	c8.9,1.1,14.9,4.1,17.6,9.1c1.5,2.8,2.8,6.2,4,10.5c1.8-0.4,3.7-0.8,5.6-1.2c-2.8-12.1-8-18.8-15.7-20.4c-2.8-0.6-6.3-1.1-10.7-1.6
	l-13.5-1.2c0.1,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.4,0.3-0.7,0.3l-9.5-0.6l-9.5-0.6c-0.3,0-0.5-0.1-0.7-0.4
	c-0.1-0.3-0.3-0.6-0.3-0.8s0.1-0.6,0.3-0.8l-2.1-0.1c-21.5-0.8-42.5-1.2-63-1.2h-2.8c-20.5-0.1-41.5,0.4-63.4,1.5l-2.1,0.1
	c0.1,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.3-0.4,0.3-0.7,0.4l-9.5,0.6l-9.5,0.6c-0.3,0-0.5-0.1-0.7-0.3
	c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8l-13.5,1.2c-4.4,0.6-8,1-10.7,1.6c-7.7,1.5-12.9,8.3-15.7,20.4
	c1.9,0.4,3.9,0.8,5.6,1.2c1.2-4.3,2.5-7.7,4-10.5c2.8-5.1,8.5-8.1,17.6-9.1c14-1.5,28.8-2.5,44.3-2.9c1.8-0.7,3.6-0.7,5.4-0.1
	C387.8,238.9,403.5,238.6,418.1,238.6L418.1,238.6z"
          />
          <path
            fill="#FFFFFF"
            d="M424.1,238.6h-6.1c-14.6-0.1-30.1,0.3-46.8,1l1,4.4c-2.1,0.6-4.5,0.7-7.6,0.4l1.2-4.7
	c-15.5,0.4-30.3,1.4-44.3,2.9c-8.9,1.1-14.9,4.1-17.6,9.1c-1.5,2.8-2.8,6.2-4,10.5c5.5,1.4,10.7,3,15.5,5.1l0.1-1l-0.3-0.1l-1.4-0.3
	l3.2-3.6l1.6-0.3l0.3-0.3l-0.1-0.1l-1-0.3c1.1-1.1,1.6-2.1,1.6-2.9l3-2.1l1.4-0.1l1.2,0.3l-1.8,2.3l1.4,0.8l-0.4,0.5
	c0.1,0.1,0.3,0.3,0.3,0.5l-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3l-0.3-0.1l-0.8,1.1l-3.3,4.4h-1.6l-0.4-0.1l-0.1,1.1l2.8,1.2
	c4-8.9,13.1-13.6,27.5-14.2c22-1.2,45.7-1.9,70.8-1.8h5.5c25.2-0.1,48.8,0.6,70.8,1.8c14.3,0.4,23.5,5.2,27.5,14.2l2.8-1.2l-0.1-1.1
	l-0.4,0.1h-1.7l-3.3-4.4l-0.8-1.1l-0.3,0.1c-0.1,0-0.4-0.1-0.5-0.3s-0.3-0.3-0.3-0.5c0-0.1,0.1-0.4,0.3-0.5l-0.7-0.4l1.4-0.8
	l-1.8-2.3l1.2-0.3l1.4,0.1l3,2.1c0,0.8,0.5,1.8,1.7,2.9l-1,0.3l-0.1,0.1l0.3,0.3l1.7,0.3l3.2,3.6l-1.4,0.3l-0.3,0.1l0.1,1
	c5-2.1,10.2-3.9,15.5-5.1c-1.2-4.3-2.5-7.7-4-10.5c-2.8-5.1-8.5-8.1-17.6-9.1c-14-1.5-28.8-2.5-44.3-2.9l1.2,4.7
	c-3,0.3-5.5,0.1-7.6-0.4l1-4.4C454.2,238.9,438.7,238.6,424.1,238.6L424.1,238.6z M429.2,243.9c-0.1-0.1-0.1-0.3-0.1-0.4
	c0.1-0.8,0-1.5-0.1-1.9c-0.1-0.3-0.3-0.4-0.5-0.3h-4.5l-0.1,0.3c-0.1,0.1-0.4,0.3-0.7,0.3h-4c-0.3,0-0.5-0.1-0.7-0.3l-0.1-0.3h-4.5
	c-0.3,0-0.5,0.1-0.5,0.3c-0.1,0.4-0.1,1-0.1,1.9l-0.1,0.4l-0.3,0.1l-0.4-0.1l-0.1-0.3c-0.1-1.1,0-1.9,0.3-2.3c0.3-0.7,0.8-1,1.5-1
	h4.4l0.1-0.1c0.1-0.1,0.4-0.3,0.7-0.3h4c0.3,0,0.5,0.1,0.7,0.3l0.1,0.1h4.4c0.7,0,1.2,0.3,1.5,1c0.3,0.6,0.3,1.2,0.3,2.3l-0.1,0.3
	l-0.4,0.1L429.2,243.9L429.2,243.9z"
          />
          <path fill="#6B9BD2" d="M415.2,255.8h2.5" />
          <path
            fill="#FFFFFF"
            d="M423.8,253.3h-5.5c-25.2-0.1-48.8,0.6-70.8,1.8c-14.3,0.4-23.5,5.2-27.5,14.2l2.3,1
	c3.7-8,12.1-12.2,25.3-12.7c21.2-1.2,43.7-1.8,67.7-1.8H427c23.9,0,46.5,0.6,67.7,1.8c13.1,0.4,21.6,4.7,25.3,12.7l2.3-1
	c-4-8.9-13.1-13.6-27.5-14.2C472.5,253.9,449,253.2,423.8,253.3L423.8,253.3z M543.5,219.9c-2.5-1.6-5.1-2.6-7.6-2.5l1.1,7.6
	c0.3,1.4-0.6,2.1-2.6,2.1l-13.3-0.3v4.5l0.3,7.8c4.4,0.6,8,1,10.7,1.6c7.7,1.5,12.9,8.3,15.7,20.4l1-0.1v-0.4l-1.9-14.6l-0.3-2.6
	L543.5,219.9L543.5,219.9z M528,230.1c0.3,0,0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
	c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8
	C527.4,230.2,527.7,230.1,528,230.1L528,230.1z M536.9,237.4c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8
	c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
	C537.6,237.3,537.3,237.4,536.9,237.4L536.9,237.4z M508,237.8c-0.1-0.1-0.4-0.3-0.5-0.3L498,237l-0.1,2.2l9.5,0.6
	c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.8C508.3,238.4,508.2,238.1,508,237.8L508,237.8z"
          />
          <path
            fill="#FFFFFF"
            d="M497.8,239.2l0.1-2.2l-9.5-0.6c-0.3,0-0.4,0.1-0.7,0.3c-0.1,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8
	c0.1,0.3,0.4,0.3,0.7,0.4L497.8,239.2L497.8,239.2z M529.2,272.5v2.5c3-1.4,6.3-1.8,9.6-1.4l9.9,1.6l0.1-12.1l-0.1-2.3l-1,0.1
	c-1.9,0.4-3.9,0.8-5.6,1.2c-5.5,1.4-10.7,3-15.5,5.1l1.1,5.2L529.2,272.5L529.2,272.5z M518.8,260.4c-0.1-0.1-0.3-0.3-0.5-0.3
	c-0.1,0-0.4,0.1-0.6,0.3c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.6,0.3l0.3-0.1l0.1-0.1
	c0.1-0.1,0.1-0.3,0.1-0.5C518.9,260.6,518.9,260.5,518.8,260.4L518.8,260.4z M524.8,267.9l-2.8,1.2l0.3,0.7v0.3
	c0.3,3.2,0.4,6.5,0.4,9.9c0.4-0.7,1-1.4,1.5-1.9l-0.1-1.9l-0.4-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.4,0.3-0.5
	c0.1-0.1,0.3-0.1,0.4-0.1v-1.4l1.2-0.4L524.8,267.9L524.8,267.9z"
          />
          <path
            fill="#FFFFFF"
            d="M522.5,270.1V270l-0.3-0.7l-2.3,1l0.3,0.5c0.5,5.8,0.5,12,0.1,18.7c0.5-2.9,1.1-5.2,1.8-7l0.3-0.8
	c0.1-0.5,0.4-1,0.7-1.4c0-3.4-0.1-6.7-0.4-9.9L522.5,270.1L522.5,270.1z M518.5,304.2l-0.1,1.2c-1.8,6.7-4.5,11-8.1,12.7
	c-2.3,1.4-8.9,2.1-19.9,1.9H351.8c-11,0.1-17.6-0.5-19.9-1.9c-3.6-1.8-6.3-5.9-8.1-12.7l-0.1-1.2l-0.1-1.1l0.1,2.1h-0.1l-0.5,0.4
	l1,2.8v0.3c1.6,5.2,4.1,8.7,7.3,10.3c2.6,1.2,7.3,2.1,14.3,2.2h151.2c7-0.3,11.7-1,14.3-2.2c3.2-1.6,5.6-5,7.3-10.3v-0.3l1-2.8
	l-0.5-0.4l0.1-2.1L518.5,304.2L518.5,304.2z M547.9,245.6l-0.1-0.6l-1,0.7l1.9,14.4 M549.7,263.1h-0.8l-0.1,12.1l0.7,0.1v-8.3
	c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.4c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7v8.5l0.5,0.1V270l0.7,0.1V265
	h-1.1v-0.7h-1.1v-2.6L549.7,263.1L549.7,263.1z"
          />
          <path
            fill="#FFFFFF"
            d="M549.7,266.4c-0.1,0.1-0.3,0.4-0.3,0.7v5.4h2.3v-5.4c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.4
	C550.1,266.1,550,266.3,549.7,266.4z"
          />
          <path
            fill="#FFFFFF"
            d="M551.8,275.8v-3.3h-2.3v2.9 M292.3,148.2c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1s-0.3,0.1-0.4,0.1
	c-0.1,0.1-0.1,0.3-0.1,0.4v1.1c0,0.1,0.1,0.3,0.1,0.4v38.7h0.7v-38.7h0.1l0.1-0.4L292.3,148.2L292.3,148.2z M305.5,169l-2.6-2.3
	l-0.3,1.2l-10.7,75v3.4l0.3-0.3l9.9-70.3l1,0.8l-5.9,45.8l-0.4,2.8l-0.5,4.7l-0.3,2.8l-1.4,10.9l1.1-0.3l2.9-23.2l6.2-48.4
	C305,170.5,305.3,169.7,305.5,169L305.5,169z"
          />
          <path
            fill="#FFFFFF"
            d="M303.2,176.7l-1-0.8l-9.9,70.3c0.3-0.3,0.7-0.4,1-0.6h0.8l0.1-0.6l0.1-1.5l1.4-10.9l-0.5-0.8l0.1-1.6
	l0.8-0.1l0.5-4.7l-0.7-0.8l0.3-1.5l0.8-0.4L303.2,176.7L303.2,176.7z M306.1,217.3c-2.5,0-5,0.8-7.6,2.5l-3,23.2l-0.3,2.6l-1.9,14.6
	v0.4l1,0.1c2.8-12.1,8-18.8,15.7-20.4c2.8-0.6,6.3-1.1,10.7-1.6l0.3-7.8v-4.5l-13.3,0.3c-1.9,0-2.8-0.7-2.6-2.1L306.1,217.3
	L306.1,217.3z M304.3,237c-0.3-0.3-0.3-0.6-0.3-0.8s0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3
	c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3C304.8,237.4,304.6,237.2,304.3,237L304.3,237z
	 M314.9,230.4c0.3,0.3,0.3,0.6,0.3,0.8s-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8
	c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3C314.5,230.1,314.7,230.2,314.9,230.4z"
          />
          <path
            fill="#FFFFFF"
            d="M296.3,230l-0.8,0.1l-0.1,1.8l0.5,0.8 M296.3,224.5l0.5,0.8l0.4-2.9l-0.8,0.6 M295.6,243.2l-1.1,0.3
	l-0.3,1.6l1,0.7"
          />
          <path
            fill="#FFFFFF"
            d="M294.2,245.1v0.6l-0.8,14.6l1.8-14.4 M294.2,260.9l-1-0.1l-0.1,2.3l0.1,12.1l9.9-1.6c3.3-0.4,6.6,0,9.6,1.4
	v-2.5h1.5l1.1-5.2c-5-2.1-10.2-3.9-15.5-5.1C298.1,261.6,296.2,261.3,294.2,260.9L294.2,260.9z M292.5,263.1h-1.2v1.2h-1.1v0.7H289
	v5.1l0.7-0.1v5.8l0.5-0.1v-8.5c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.4c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v8.3
	l0.7-0.1l-0.1-12.1L292.5,263.1L292.5,263.1z"
          />
          <path
            fill="#6B9BD2"
            d="M290.5,259.8h0.1v-0.5l-0.5-0.1c-0.1,0.3-0.1,0.5,0,0.8C290.3,259.9,290.4,259.8,290.5,259.8z"
          />
          <path
            fill="#FFFFFF"
            d="M290.5,266.4c-0.1,0.1-0.3,0.4-0.3,0.7v5.4h2.3v-5.4c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-0.4
	C291.1,266.1,290.8,266.3,290.5,266.4L290.5,266.4z"
          />
          <path
            fill="#FFFFFF"
            d="M292.6,275.4v-2.9h-2.3v3.3 M289.7,270l-0.7,0.1c-0.4,0.1-0.5,0.7-0.5,1.4v4.5l1.4-0.3V270H289.7L289.7,270z
	 M354.4,236.7c-0.1-0.1-0.4-0.3-0.7-0.3l-9.5,0.6l0.1,2.2l9.5-0.6c0.3,0,0.5-0.1,0.7-0.4c0.1-0.3,0.3-0.6,0.3-0.8
	C354.6,237.2,354.5,237,354.4,236.7L354.4,236.7z"
          />
          <path
            fill="#FFFFFF"
            d="M334.8,237.4c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.8c0.3,0.3,0.4,0.3,0.7,0.3
	l9.5-0.6l-0.1-2.2L334.8,237.4L334.8,237.4z M320,269.3l-2.8-1.2l-0.8,5.1l1.4,0.4v1.4l0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.5
	c0,0.1-0.1,0.4-0.3,0.5l-0.4,0.3l-0.1,1.9c0.7,0.7,1.1,1.2,1.5,1.9c0-3.4,0.1-6.7,0.4-9.9v-0.3L320,269.3L320,269.3z"
          />
          <path
            fill="#FFFFFF"
            d="M322,270.8l0.3-0.5l-2.3-1l-0.3,0.7v0.3c-0.3,3.2-0.4,6.5-0.4,9.9c0.3,0.4,0.5,1,0.7,1.4l0.3,0.8
	c0.7,1.8,1.2,4.1,1.8,7C321.5,282.8,321.5,276.5,322,270.8L322,270.8z M350.8,324.1l1.5,0.3c1.5,0.3,3,1,4.3,2.1h128.8
	c1.4-1,2.8-1.6,4.3-2.1l1.5-0.3h25.6l0.8-9.1c-1,1.5-1.9,2.8-3.2,3.9c-1.7,1.4-3.7,2.1-6.2,2.3c2.6-0.3,4.7-1,6.2-2.3
	c1.2-1.1,2.3-2.3,3.2-3.9l0.5-6.3c-1.7,5.2-4.1,8.7-7.3,10.3c-2.6,1.2-7.3,2.1-14.3,2.2h11.1H334.3h11.1c-7-0.3-11.7-1-14.3-2.2
	c-3.2-1.6-5.6-5-7.3-10.3l0.5,6.3c1,1.5,1.9,2.8,3.2,3.9c1.6,1.4,3.7,2.1,6.2,2.3c-2.6-0.3-4.7-1-6.2-2.3c-1.2-1.1-2.3-2.3-3.2-3.9
	l0.8,9.1H350.8L350.8,324.1z"
          />
          <path
            fill="#FFFFFF"
            d="M321.1,307l0.1,1.8l2.6-0.1v-0.3l-1-2.8 M376.8,368.2h1.6v-5.1h-1.6 M521,308.8l0.1-1.8l-1.8-1.4l-1,2.8
	l-0.1,0.3 M533.6,365V363l-5.1,0.5c-1.5,0.3-3,0.8-4.5,1.5L533.6,365L533.6,365z"
          />
          <path
            fill="#EBEBEB"
            d="M524.4,179.1c-0.4-2.5-2.3-3.9-5.9-4.1c-27.2-1.5-55.9-2.3-85.7-2.8h-23.9c-30,0.4-58.5,1.2-85.7,2.8
	c-3.4,0.3-5.5,1.6-5.9,4.1l-3.7,39.1c0.1,2.5,2.2,3.7,6.3,3.7c31.5-1.1,63.3-1.8,95.3-1.8h11.4c32.2,0,64,0.6,95.3,1.8
	c4.1,0,6.2-1.2,6.3-3.7L524.4,179.1L524.4,179.1z M499.8,262.3c-4.7,0-8.5,1.6-11.8,4.8c-3.3,3.3-4.8,7.2-4.8,11.8
	c0,4.7,1.6,8.5,4.8,11.8c3.3,3.3,7.1,5,11.8,5s8.5-1.6,11.8-5c3.3-3.3,5-7.2,5-11.8s-1.7-8.5-5-11.8
	C508.4,263.9,504.4,262.3,499.8,262.3L499.8,262.3z M489.2,268.3c2.9-2.9,6.5-4.4,10.6-4.4s7.7,1.5,10.6,4.4
	c2.9,2.9,4.4,6.5,4.4,10.6s-1.5,7.7-4.4,10.6s-6.5,4.4-10.6,4.4s-7.7-1.5-10.6-4.4c-2.9-2.9-4.4-6.5-4.4-10.6
	S486.3,271.4,489.2,268.3L489.2,268.3z"
          />
          <path
            fill="#EBEBEB"
            d="M499.8,263.9c-4.1,0-7.7,1.5-10.6,4.4c-2.9,2.9-4.4,6.5-4.4,10.6s1.5,7.7,4.4,10.6c2.9,2.9,6.5,4.4,10.6,4.4
	s7.7-1.5,10.6-4.4c2.9-2.9,4.4-6.5,4.4-10.6s-1.5-7.7-4.4-10.6C507.5,265.4,504,263.9,499.8,263.9L499.8,263.9z M495.8,278.9
	c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9
	c-0.8,0.8-1.8,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.9-1.2C496.2,281,495.8,280,495.8,278.9L495.8,278.9z"
          />
          <path
            fill="#EBEBEB"
            d="M496.9,276c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2
	s1.2-1.8,1.2-2.9s-0.4-2.1-1.2-2.9c-0.8-0.8-1.8-1.2-2.9-1.2C498.7,274.9,497.7,275.4,496.9,276z M342.3,262.3
	c-4.5,0-8.5,1.6-11.8,4.8c-3.3,3.3-5,7.2-5,11.8c0,4.7,1.6,8.5,5,11.8c3.3,3.3,7.2,5,11.8,5s8.5-1.6,11.8-5
	c3.3-3.3,4.8-7.2,4.8-11.8s-1.6-8.5-4.8-11.8C350.9,263.9,346.9,262.3,342.3,262.3L342.3,262.3z M331.7,268.3
	c2.9-2.9,6.5-4.4,10.6-4.4s7.7,1.5,10.6,4.4c2.9,2.9,4.4,6.5,4.4,10.6s-1.5,7.7-4.4,10.6c-2.9,2.9-6.5,4.4-10.6,4.4
	s-7.7-1.5-10.6-4.4c-2.9-2.9-4.4-6.5-4.4-10.6S328.8,271.4,331.7,268.3L331.7,268.3z"
          />
          <path
            fill="#EBEBEB"
            d="M342.3,263.9c-4.1,0-7.7,1.5-10.6,4.4c-2.9,2.9-4.4,6.5-4.4,10.6s1.5,7.7,4.4,10.6s6.5,4.4,10.6,4.4
	s7.7-1.5,10.6-4.4c2.9-2.9,4.4-6.5,4.4-10.6s-1.5-7.7-4.4-10.6C350,265.4,346.4,263.9,342.3,263.9L342.3,263.9z M342.3,274.9
	c1.1,0,2.1,0.4,2.9,1.2c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
	c-0.8-0.8-1.2-1.8-1.2-2.9s0.4-2.1,1.2-2.9C340.2,275.4,341.2,274.9,342.3,274.9L342.3,274.9z"
          />
          <path
            fill="#EBEBEB"
            d="M345.1,276c-0.8-0.8-1.8-1.2-2.9-1.2s-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1,1.2,2.9
	c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9S346,276.9,345.1,276L345.1,276z M283.4,337.3
	c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.7,2.1,5.1c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.7-0.7,5.1-2.1c1.4-1.4,2.1-3,2.1-5.1
	c0-1.9-0.7-3.7-2.1-5.1c-1.4-1.4-3-2.1-5.1-2.1C286.4,335.1,284.8,335.7,283.4,337.3L283.4,337.3z M286.3,342.2
	c0-0.5,0.3-1.1,0.7-1.5c0.4-0.4,1-0.5,1.5-0.5s1.1,0.1,1.5,0.5c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5c-0.4,0.4-1,0.7-1.5,0.7
	s-1.1-0.3-1.5-0.7C286.5,343.3,286.3,342.9,286.3,342.2L286.3,342.2z"
          />
          <path
            fill="#EBEBEB"
            d="M287,340.7c-0.4,0.4-0.7,1-0.7,1.5s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.5-0.7
	c0.4-0.4,0.7-1,0.7-1.5s-0.3-1.1-0.7-1.5c-0.4-0.4-1-0.5-1.5-0.5S287.4,340.3,287,340.7L287,340.7z M372.8,331.1c-0.7,0-1,0.3-1,1
	v22.1c0,0.7,0.3,1,1,1h96.6c0.7,0,1-0.3,1-1V332c0-0.7-0.3-1-1-1H372.8L372.8,331.1z M376.5,353.2c-0.7,0-1-0.3-1-1v-18.6
	c0-0.7,0.3-1,1-1h89c0.7,0,1,0.3,1,1v18.6c0,0.7-0.3,1-1,1L376.5,353.2L376.5,353.2z"
          />
          <path
            fill="#EBEBEB"
            d="M375.5,352.3c0,0.7,0.3,1,1,1h89c0.7,0,1-0.3,1-1v-18.6c0-0.7-0.3-1-1-1h-89c-0.7,0-1,0.3-1,1L375.5,352.3
	L375.5,352.3z M560.8,342.2c0-1.9-0.7-3.7-2.1-5.1c-1.4-1.4-3-2.1-5.1-2.1c-1.9,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3-2.1,5.1
	c0,1.9,0.7,3.7,2.1,5.1c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.7-0.7,5.1-2.1C560.2,345.9,560.8,344.3,560.8,342.2z M555.2,340.7
	c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5s-1,0.7-1.5,0.7c-0.5,0-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-1-0.7-1.5s0.3-1.1,0.7-1.5
	c0.4-0.4,1-0.5,1.5-0.5C554.2,340.1,554.8,340.3,555.2,340.7L555.2,340.7z"
          />
          <path
            fill="#EBEBEB"
            d="M555.8,342.2c0-0.5-0.3-1.1-0.7-1.5c-0.4-0.4-1-0.5-1.5-0.5s-1.1,0.1-1.5,0.5c-0.4,0.4-0.7,1-0.7,1.5
	s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.5-0.7C555.6,343.3,555.8,342.9,555.8,342.2z"
          />
          <path
            fill="#D2D3D4"
            d="M503.3,301.2c-2.1,0-3.9,0.7-5.4,2.2s-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4s3.3,2.2,5.4,2.2
	c2.1,0,3.9-0.7,5.4-2.2s2.2-3.3,2.2-5.4c0-2.1-0.7-3.9-2.2-5.4C507.3,302,505.5,301.2,503.3,301.2L503.3,301.2z M501.8,310.3
	c-0.4-0.4-0.7-1-0.7-1.6c0-0.5,0.3-1.1,0.7-1.7c0.4-0.4,1-0.7,1.7-0.7c0.5,0,1.1,0.3,1.6,0.7c0.4,0.4,0.7,1,0.7,1.7
	c0,0.7-0.3,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7C502.8,311,502.2,310.8,501.8,310.3z"
          />
          <path
            fill="#E6E7E7"
            d="M501.1,308.8c0,0.7,0.3,1.2,0.7,1.6c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6
	c0-0.5-0.3-1.1-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7c-0.5,0-1.1,0.3-1.7,0.7C501.4,307.5,501.1,308.1,501.1,308.8L501.1,308.8z"
          />
          <path
            fill="#D2D3D4"
            d="M344,303.4c-1.5-1.5-3.3-2.2-5.4-2.2s-3.9,0.7-5.4,2.2s-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4
	s3.3,2.2,5.4,2.2s3.9-0.7,5.4-2.2s2.2-3.3,2.2-5.4C346.2,306.7,345.6,304.9,344,303.4z M341,308.8c0,0.7-0.3,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.6,0.7c-0.5,0-1.1-0.3-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.5,0.3-1.1,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7
	c0.5,0,1.1,0.3,1.6,0.7C340.7,307.5,341,308.1,341,308.8L341,308.8z"
          />
          <path
            fill="#E6E7E7"
            d="M340.3,310.3c0.4-0.4,0.7-1,0.7-1.6c0-0.5-0.3-1.1-0.7-1.7c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.1,0.3-1.6,0.7
	c-0.4,0.4-0.7,1-0.7,1.7c0,0.7,0.3,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7C339.4,311,339.9,310.8,340.3,310.3L340.3,310.3z"
          />
          <path
            fill="#D2D3D4"
            d="M577.5,293.6c-0.5-0.8-1.1-1.1-1.7-1.1c-0.4,0.1-0.8,0.5-1,1.5s-0.1,2.1,0,3.3c0.1,1.2,0.5,2.3,1,3.2
	s0.8,1.2,1.4,1.2c0.5-0.1,1-0.7,1.2-1.5c0.3-1,0.4-2.1,0.1-3.3C578.5,295.6,578.2,294.5,577.5,293.6L577.5,293.6z M263.4,296.8
	c-0.1,1.2-0.1,2.3,0.1,3.3c0.3,1,0.7,1.5,1.2,1.5c0.4,0.1,1-0.3,1.4-1.2c0.4-0.8,0.8-1.9,1-3.2c0.1-1.2,0.1-2.3,0-3.3
	c-0.1-1-0.5-1.5-1-1.5c-0.5,0-1.2,0.3-1.6,1.1C264,294.5,263.6,295.6,263.4,296.8L263.4,296.8z"
          />
          <path
            fill="none"
            d="M421.4,263.1c0,0.3,0,0.4,0.1,0.7c0.3,0.5,0.8,1,1.5,1.1l1,0.1l1.5-0.1c0.4-0.1,0.8-0.3,1.1-0.5l0.3-0.4v-0.1
	h-1.9c-0.1,0.1-0.3,0.3-0.5,0.3H424c-0.4,0-0.5-0.1-0.7-0.4l-0.1-0.4h3.7v-0.5l-0.3-0.7c-0.3-0.5-0.8-0.8-1.5-1l-1.2-0.1
	c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.1-0.7,0.4-1,0.7C421.5,262.3,421.2,262.7,421.4,263.1L421.4,263.1z"
          />
          <path
            fill="none"
            d="M423.3,262.3l0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.3h0.5c0.3,0.1,0.5,0.3,0.7,0.5v0.3h-1.9L423.3,262.3L423.3,262.3z
	 M417.4,262.4l0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.3h0.5c0.4,0.1,0.5,0.3,0.7,0.4v0.3L417.4,262.4L417.4,262.4z"
          />
          <path
            fill="none"
            d="M417.2,263.2h3.7v-0.7l-0.3-0.7c-0.3-0.5-0.8-0.8-1.7-1l-1.2-0.1c-0.4,0-0.8,0.1-1.2,0.3s-0.7,0.4-0.8,0.8
	c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.4,0,0.7,0.1,1c0.3,0.5,0.8,0.8,1.5,1.1l1,0.1l1.5-0.1c0.4-0.1,0.8-0.3,1-0.5l0.3-0.4v-0.1H419
	c-0.1,0.1-0.3,0.3-0.5,0.3h-0.5c-0.3,0-0.4-0.1-0.5-0.3l-0.1-0.3L417.2,263.2L417.2,263.2z M415,259.4h-1.9v3.7
	c-0.1,0.3-0.3,0.4-0.5,0.5l-0.8,0.1c-0.4,0-0.7-0.1-0.8-0.4l-0.1-0.3v-0.7h-1.9v0.8l0.1,0.5c0.1,0.4,0.5,0.8,1.2,1l1,0.1h1.1l1-0.1
	c0.7-0.1,1.1-0.4,1.5-0.8c0.1-0.3,0.3-0.5,0.3-0.8L415,259.4L415,259.4z M432.8,261.7c-0.3-0.3-0.5-0.5-1.1-0.7l-1.5-0.1
	c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1h-0.3V261h-1.8v5.1h1.9v-1.4c0,0,0-0.1,0.1,0l0.5,0.3l1.2,0.1c0.5,0,1-0.1,1.4-0.5
	c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.4,0.3-1,0.1-1.4L432.8,261.7L432.8,261.7z"
          />
          <path
            fill="none"
            d="M431.1,263.5l-0.1,0.3c-0.1,0.3-0.4,0.3-0.8,0.3h-0.4c-0.3,0-0.4-0.1-0.5-0.3l-0.1-0.4v-0.3l0.1-0.5l0.1-0.3
	c0.1-0.3,0.5-0.3,1-0.3h0.3c0.3,0,0.4,0.1,0.5,0.3l0.1,0.3C431.3,263,431.3,263.3,431.1,263.5L431.1,263.5z M524.4,179.1l3.7,39.1
	c-0.1,2.5-2.2,3.7-6.3,3.7c-31.5-1.1-63.3-1.8-95.3-1.8H415c-32.1,0-63.8,0.6-95.3,1.8c-4.1,0-6.2-1.2-6.3-3.7l3.7-39.1
	c0.4-2.5,2.3-3.9,5.9-4.1c27.2-1.5,55.9-2.3,85.7-2.8h23.9c30,0.4,58.5,1.2,85.7,2.8C522.1,175.2,524,176.6,524.4,179.1L524.4,179.1
	z M501.8,310.3c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-0.5-0.3-1.1-0.7-1.7c-0.4-0.4-1-0.7-1.6-0.7
	c-0.5,0-1.1,0.3-1.7,0.7c-0.4,0.4-0.7,1-0.7,1.7C501.1,309.3,501.4,309.9,501.8,310.3z M340.3,310.3c-0.4,0.4-1,0.7-1.6,0.7
	c-0.7,0-1.1-0.3-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.5,0.3-1.1,0.7-1.7c0.4-0.4,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7
	c0.4,0.4,0.7,1,0.7,1.7C341,309.3,340.7,309.9,340.3,310.3L340.3,310.3z M342.3,263.9c4.1,0,7.7,1.5,10.6,4.4
	c2.9,2.9,4.4,6.5,4.4,10.6s-1.5,7.7-4.4,10.6c-2.9,2.9-6.5,4.4-10.6,4.4s-7.7-1.5-10.6-4.4c-2.9-2.9-4.4-6.5-4.4-10.6
	s1.5-7.7,4.4-10.6C334.6,265.4,338.1,263.9,342.3,263.9L342.3,263.9z"
          />
          <path
            fill="none"
            d="M345.1,276c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
	c-0.8-0.8-1.2-1.8-1.2-2.9s0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2C343.4,274.9,344.3,275.4,345.1,276z M489.2,268.3
	c-2.9,2.9-4.4,6.5-4.4,10.6s1.5,7.7,4.4,10.6c2.9,2.9,6.5,4.4,10.6,4.4s7.7-1.5,10.6-4.4c2.9-2.9,4.4-6.5,4.4-10.6
	s-1.5-7.7-4.4-10.6c-2.9-2.9-6.5-4.4-10.6-4.4S492.1,265.4,489.2,268.3L489.2,268.3z"
          />
          <path
            fill="none"
            d="M495.8,278.9c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9
	s-0.4-2.1-1.2-2.9c-0.8-0.8-1.8-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.9,1.2C496.2,276.9,495.8,277.8,495.8,278.9L495.8,278.9z M411.3,293.6
	h-12.4 M411.3,296h-12.4 M411.3,285.5h-12.4 M411.3,286.8h-12.4 M411.3,289.1h-12.4 M411.3,281.8h-12.4 M411.3,283.1h-12.4
	 M411.3,278.2h-12.4 M447.5,278.2v-4.7c0-1.6,0.5-3.2,1.8-4.4s2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c1.2,1.2,1.8,2.6,1.8,4.4v4.7
	 M447.5,279.5v-1.2 M447.5,281.8v-2.3 M459.9,278.2v1.2 M459.9,279.5v2.3 M465.2,278.2v-1.9h8l3.2,4.1 M465.2,278.2v1.2 M464,281.8
	v-2.3 M465.2,281.8H464 M464,278.2h1.2 M464,279.5v-1.2 M465.2,279.5H464 M465.2,281.8v-2.3 M464,278.2v-4.7c0-1.6,0.6-3.2,1.8-4.4
	s2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c1.2,1.2,1.8,2.6,1.8,4.4v6.7 M459.9,281.8h-12.4 M459.9,278.2h-12.4 M447.5,279.5h12.4
	 M430.8,278.2v-4.7c0-1.6,0.5-3.2,1.8-4.4s2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c1.2,1.2,1.8,2.6,1.8,4.4v4.7 M394.5,300.9v-1.2
	 M394.5,299.7v-2.6 M426.7,279.5v2.3 M426.7,279.5v-1.2 M430.8,279.5v-1.2 M430.8,281.8v-2.3 M443.2,278.2v1.2 M443.2,279.5v2.3
	 M443.2,278.2h-12.4 M443.2,279.5h-12.4 M443.2,281.8h-12.4 M443.2,290.2v2.2 M443.2,292.4v1.2 M443.2,289.1v1.2 M443.2,286.8v2.3
	 M443.2,285.5v1.2 M443.2,283.1v2.6 M426.7,285.5v1.2 M430.8,285.5v-2.6 M430.8,286.8v-1.2 M426.7,283.1v2.6 M430.8,292.4v-2.2
	 M430.8,290.2V289 M426.7,292.4v1.2 M426.7,290.2v2.2 M426.7,289.1v1.2 M430.8,293.6v-1.2 M430.8,289.1v-2.3 M426.7,289.1v-2.3
	 M426.7,297.1v2.6 M430.8,297.1v2.6 M430.8,296v1.2 M426.7,297.1v-1.2 M430.8,300.9v2.3 M426.4,306.4c0.1-0.3,0.3-0.7,0.3-1v-1
	 M426.7,304.4v-1.2 M426.7,300.9v2.3 M430.8,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M430.8,303.3v1.2 M430.8,299.7v1.2 M426.7,300.9v-1.2
	 M443.2,297.1v-1.2 M443.2,299.7v-2.6 M443.2,300.9v-1.2 M443.2,303.3v-2.3 M443.2,304.4v-1.2 M443.1,306.4c0.1-0.3,0.1-0.5,0.1-1
	v-1.1 M430.8,283.1h12.4 M443.2,285.5h-12.4 M430.8,286.8h12.4 M443.2,289.1h-12.4 M430.8,292.4h12.4 M443.2,293.7h-12.4 M443.2,296
	v-2.3 M430.8,293.6v2.3 M430.8,290.2h12.4 M426.7,296v-2.3 M443.2,297.1h-12.4 M430.8,296h12.4 M443.2,299.7h-12.4 M430.8,304.4
	h12.4 M443.2,303.3h-12.4 M430.8,300.9h12.4 M465.2,286.8H464 M464,286.8v-1.2 M464,285.5h1.2 M465.2,285.5v-2.6 M465.2,286.8v-1.2
	 M464,283.1h1.2 M464,285.5v-2.6 M459.9,283.1v2.6 M459.9,285.5v1.2 M459.9,289.1v1.2 M459.9,292.4v-2.2 M459.9,293.7v-1.2
	 M465.2,290.2V289 M464,289.1h1.2 M464,290.2V289 M465.2,290.2H464 M465.2,293.7v-1.2 M464,292.4h1.2 M464,293.7v-1.2 M465.2,293.7
	H464 M465.2,292.4v-2.2 M464,292.4v-2.2 M465.2,289.1v-2.3 M459.9,286.8v2.3 M464,289.1v-2.3 M447.5,285.5v-2.6 M447.5,290.2V289
	 M447.5,292.4v1.2 M447.5,290.2v2.2 M447.5,289.1v-2.3 M447.5,286.8v-1.2 M447.5,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M447.5,303.3v1.2
	 M447.5,300.9v2.3 M447.5,299.7v1.2 M447.5,297.1v2.6 M447.5,296v1.2 M465.2,299.7H464 M464,299.7v-2.6 M465.2,297.1H464 M464,297.1
	v-1.2 M464,296h1.2 M465.2,297.1v-1.2 M465.2,299.7v-2.6 M459.9,297.1v-1.2 M459.9,299.7v-2.6 M459.9,303.3v-2.3 M459.9,304.4v-1.2
	 M459.7,306.5c0.1-0.3,0.1-0.5,0.1-1v-1.1 M465.2,304.4v-1.2 M465.2,303.3v-2.3 M464,300.9h1.2 M464,303.3v-2.3 M465.2,303.3H464
	 M464,304.4v-1.2 M465.2,304.4H464 M464.3,306.5c-0.1-0.3-0.3-0.5-0.3-1v-1.1 M459.9,300.9v-1.2 M465.2,300.9v-1.2 M464,300.9v-1.2
	 M465.2,296v-2.3 M459.9,283.1h-12.4 M459.9,285.5h-12.4 M447.5,289.1h12.4 M459.9,290.2h-12.4 M459.9,296v-2.3 M447.5,293.6v2.3
	 M447.5,286.8h12.4 M459.9,292.4h-12.4 M459.9,293.7h-12.4 M447.5,304.4h12.4 M459.9,303.3h-12.4 M447.5,300.9h12.4 M459.9,299.7
	h-12.4 M447.5,297.1h12.4 M447.5,296h12.4 M464,296v-2.3 M447.5,283.1v-1.2 M459.9,281.8v1.2 M465.2,283.1v-1.2 M443.2,281.8v1.2
	 M426.7,283.1v-1.2 M430.9,283.1v-1.2 M464,283.1v-1.2 M426.7,278.2h-11.3 M426.7,281.8h-11.3 M415.4,279.5h11.4 M426.7,285.5h-11.3
	 M415.4,283.1h11.4 M426.7,286.8h-11.3 M426.7,290.2h-11.3 M415.4,289.1h11.4 M426.7,292.4h-11.3 M426.7,293.7h-11.3 M426.7,299.7
	h-11.3 M415.4,297.1h11.4 M426.7,303.3h-11.3 M415.4,300.9h11.4 M415.4,296h11.4 M426.7,304.4h-11.3 M426.9,255.8h-2.3 M543.5,219.9
	c-2.5-1.6-5.1-2.6-7.6-2.5 M528,230.1c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C528.7,230.2,528.4,230.1,528,230.1
	L528,230.1z M521.2,226.7l13.3,0.3c1.9,0,2.8-0.7,2.6-2.1l-1.1-7.6 M521.2,231.2v-4.5 M508,237.8c-0.1-0.1-0.4-0.3-0.5-0.3L498,237
	 M521.5,238.9l-13.5-1.2 M517.9,256.2l-1.2,0.3l1.8,2.3l-1.4,0.8l0.4,0.5 M523,261.7l1-0.3c-1.1-1.1-1.7-2.1-1.7-2.9l-3-2.1
	l-1.4-0.1 M497.8,239.2l9.5,0.6c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8 M536.9,237.4
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.6-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8C536.4,237.3,536.6,237.4,536.9,237.4L536.9,237.4z M521.2,231.2l0.3,7.7
	 M542.1,262.1c-1.2-4.3-2.5-7.7-4-10.5c-2.8-5.1-8.5-8.1-17.6-9.1c-14-1.5-28.8-2.5-44.3-2.9"
          />
          <path
            fill="none"
            d="M521.5,238.9c4.4,0.6,8,1,10.7,1.6c7.7,1.5,12.9,8.3,15.7,20.4 M476.2,239.7c-1.8-0.7-3.6-0.7-5.4-0.1"
          />
          <path
            fill="none"
            d="M476.2,239.7l1.2,4.7c-3,0.3-5.5,0.1-7.6-0.4l1-4.4 M497.8,239.2l0.1-2.2 M498,237l-9.5-0.6
	c-0.3,0-0.4,0.1-0.7,0.3 M487.8,236.7c-0.3,0.3-0.3,0.6-0.4,0.8c0,0.3,0.1,0.6,0.3,0.8c0.1,0.3,0.4,0.3,0.7,0.4l9.5,0.6
	 M426.9,255.8c23.9,0,46.5,0.5,67.7,1.8c13.1,0.4,21.6,4.7,25.3,12.7 M411.3,292.4h-12.4"
          />
          <path
            fill="none"
            d="M487.1,266.3c-3.4,3.4-5.2,7.7-5.2,12.7c0,5,1.8,9.2,5.2,12.7c3.4,3.6,7.7,5.2,12.7,5.2c5,0,9.2-1.8,12.7-5.2
	c3.4-3.4,5.2-7.7,5.2-12.7s-1.8-9.2-5.2-12.7c-3.4-3.4-7.7-5.2-12.7-5.2C494.8,261.1,490.7,262.8,487.1,266.3L487.1,266.3z"
          />
          <path
            fill="none"
            d="M487.9,267.2c-3.3,3.3-4.8,7.2-4.8,11.8c0,4.7,1.6,8.5,4.8,11.8c3.3,3.3,7.1,5,11.8,5s8.5-1.6,11.8-5
	c3.3-3.3,5-7.2,5-11.8c0-4.7-1.7-8.5-5-11.8c-3.3-3.3-7.2-4.8-11.8-4.8C495.2,262.3,491.2,263.9,487.9,267.2L487.9,267.2z
	 M411.3,290.2h-12.4 M498.1,303.4c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4c1.5,1.5,3.3,2.2,5.4,2.2c2.1,0,3.9-0.7,5.4-2.2
	c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.7-3.9-2.2-5.4c-1.5-1.5-3.3-2.2-5.4-2.2C501.3,301.2,499.5,302,498.1,303.4L498.1,303.4z"
          />
          <path
            fill="none"
            d="M497.4,302.9c-1.6,1.7-2.5,3.6-2.5,5.9s0.8,4.3,2.5,5.9c1.6,1.6,3.6,2.5,5.9,2.5s4.3-0.8,5.9-2.5
	c1.6-1.7,2.5-3.6,2.5-5.9s-0.8-4.3-2.5-5.9c-1.6-1.6-3.6-2.5-5.9-2.5C501.1,300.4,499.1,301.2,497.4,302.9L497.4,302.9z
	 M476.4,280.4v25c0,1-0.5,1.8-1.8,2.5c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3h-0.1 M526.6,266.3l1.4-0.3l-3.2-3.7
	l-1.7-0.3 M526.3,266.4l0.3-0.1 M526.6,267.2l-0.3-0.8 M529.2,274.9l-1.4,0.7 M527.7,272.5l0.1,3.2 M525.6,273.2l2.1-0.7
	 M527.7,272.5h1.5v2.5 M526.6,267.2l1.1,5.2 M542.1,262.1c-5.5,1.4-10.7,3-15.5,5.1 M548.7,275.2l-9.9-1.6c-3.3-0.4-6.6,0-9.6,1.4
	 M519.3,262.6l1.5-0.1l2.1-0.7 M523.2,262l-0.3-0.3 M522.9,261.7h0.1 M523,261.7c-1.1-1.2-1.7-2.3-1.7-3.2l-3.4-2.3 M518.5,261.5
	l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.6-0.3c-0.1,0-0.4,0.1-0.5,0.3"
          />
          <path
            fill="none"
            d="M517.7,260.4c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.5,0.3l0.3-0.1 M519.3,262.6
	l-0.8-1.1 M524.7,267h-2.1l-3.3-4.4 M524.7,267l0.1,1 M524.8,267.9l-2.6,1.4 M524.4,274.9c-0.1,0-0.3,0.1-0.4,0.1
	c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5l0.4,0.3 M522.2,269.3l0.3,0.7v0.3c0.3,3.2,0.4,6.5,0.4,9.9 M524.4,274.9v-1.4
	l1.2-0.4"
          />
          <path
            fill="none"
            d="M524.5,276.3l0.4-0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.5-0.3h-0.1
	 M519.9,270.3l0.3,0.5c0.5,5.8,0.5,12,0.1,18.7"
          />
          <path
            fill="none"
            d="M523,280.3c-0.3,0.4-0.5,1-0.7,1.4l-0.3,0.8c-0.7,1.8-1.2,4.1-1.8,7 M524.5,278.2v-1.9 M523,280.3
	c0.4-0.7,1-1.4,1.5-1.9 M519.9,270.3l2.3-1 M518.8,303.1l-0.1,1.1l-0.1,1.2c-1.8,6.7-4.5,11-8.1,12.7c-2.3,1.4-8.9,2.1-19.9,1.9
	H351.8c-11,0.1-17.6-0.5-19.9-1.9c-3.6-1.8-6.3-5.9-8.1-12.7l-0.1-1.2l-0.1-1.1 M520.3,289.5c-0.7,3.7-1.1,8.1-1.5,13.5
	 M518.8,305.2h-0.1l0.1-2.1 M519.3,305.6l-0.5-0.4 M527.8,275.6c-1.4,0.8-2.5,1.6-3.3,2.6 M526.3,266.4l-1.7,0.5 M525.6,273.2
	l-0.8-5.2 M526.6,266.3l-3.4-4.3 M452.4,229.5h19l49.8,1.6 M469.2,156.9c-8-0.3-15.8-0.4-23.7-0.4 M320.9,226.7
	c34.7-1.2,68-1.6,100.1-1.2c32.2-0.4,65.5,0,100,1.2 M570.9,243.3h16.9c3.9,0.1,5.8-1.6,5.6-5.4v-11.7c0-3.9-0.1-6.9-0.5-8.9
	c-0.5-1.8-1.8-2.6-4-2.6c-8.8-0.7-18.4-0.8-28.8-0.4c-2.9,0.3-4.1,2.1-4.1,5.2v19c-0.1,2.9,1.8,4.5,5.6,4.8h1.7"
          />
          <path
            fill="none"
            d="M587.8,219.9h-19.1c-1.7,0-2.5,0.8-2.5,2.5v11.8c0,1.7,0.8,2.5,2.5,2.5h19.1c1.7,0,2.5-0.8,2.5-2.5v-11.8
	C590.3,220.6,589.5,219.9,587.8,219.9L587.8,219.9z M545.9,230l0.4,2.8 M545.3,225.3l0.5,4.7 M545.3,225.3l0.5-0.8l-0.3-1.5
	l-0.7-0.6 M546.3,232.7l0.5-0.8l-0.1-1.8l-0.8-0.1 M547.6,243.4l-1.1-0.3 M547.8,245.1l-0.1-1.6 M551.9,259.1l-0.7,0.1
	c0.8-3.4,0.8-6.6-0.3-9.5h1 M549.8,246.2c-0.3-0.3-0.7-0.4-1-0.6h-0.8 M551.9,249.8c-0.3-1.2-0.7-2.1-1.2-2.8 M550.7,247
	c-0.3-0.4-0.5-0.7-0.8-0.8 M547.9,245.6l-0.1-0.6 M563.5,243.3v5.6h1.9 M560,249.8h-8.1 M546.8,245.8l-0.3-2.6 M547.8,245.1l-1,0.7
	 M563.5,243.3c-0.1-2.6,1.2-4.3,4.3-4.8c1.7-0.4,2.8,0.4,3,2.2v2.6 M560,258l11.3-1.6v-6.6h-2.5 M568.8,248.9h-3.4 M565.4,249.8h3.4
	 M565.4,248.9v0.8 M568.8,248.9h2.1v-5.6 M568.8,249.8v-0.8 M565.4,249.8H560 M546.3,232.7l1.4,10.7 M555.9,258.6l4.1-0.5 M553,265
	h2.9v-6.5 M551.9,259.1c0.1,0.7-0.1,1.6-1,2.6 M550.9,261.9l-1.2,1.2h-0.8 M548.7,260.2v0.5 M548.7,260.8l0.1,2.3 M547.8,260.9
	l1-0.1 M549.4,272.5v-5.4c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.4c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7v5.4"
          />
          <path
            fill="none"
            d="M553,265h-1v-0.7h-1.1v-2.5 M553.7,276v-4.5c0-0.8-0.3-1.2-0.5-1.4 M551.8,275.8v-3.3 M552.3,275.8h-0.5
	 M553,270.1l-0.7-0.1v5.8 M549.4,275.4l-0.7-0.1 M549.4,272.5v2.9 M551.8,272.5h-2.3 M553.7,276l-1.4-0.3 M551.8,275.8l-2.3-0.4
	 M548.9,263.1l-0.1,12.1 M553,270.1V265 M555.9,258.6l-4,0.5 M575.8,292.5c-0.4,0.1-0.8,0.5-1,1.5s-0.1,2.1,0,3.3
	c0.1,1.2,0.5,2.3,1,3.2s0.8,1.2,1.4,1.2c0.5-0.1,1-0.7,1.2-1.5c0.3-1,0.4-2.1,0.1-3.3c-0.1-1.2-0.6-2.3-1.1-3.2
	C577.1,292.8,576.4,292.5,575.8,292.5L575.8,292.5z M578.9,291.7v-2.3l-3.3-6.7c-1.1-2.3-3-3.7-5.6-4l-16.4-2.6 M580.1,315v-20.2
	l-1.2-3 M546.8,245.8l1.9,14.4 M560,249.8v8.3 M548.7,260.2l-0.8-14.6 M546.5,243.2l-3-23.2 M544.9,222.4l0.4,2.9 M547.8,260.9
	c-1.9,0.4-3.9,0.8-5.6,1.2 M411.3,299.7h-12.4 M411.3,297.1h-12.4 M411.3,304.4h-12.4 M411.3,303.3h-12.4 M411.3,300.9h-12.4
	 M415.4,281.8v1.2 M394.5,283.1v-1.2 M398.9,279.5h12.4 M372.9,156.9c-1.1-0.1-1.9-0.6-2.3-1.5l-6.2,0.1c-0.4,0.8-1.1,1.4-2.1,1.6
	 M362.3,157.2l10.6-0.3 M325.8,158.7l-11.4,0.7l-6.7,1c-2.1,0.1-3.7,2.2-4.7,6.5 M336.8,158.1c-1-0.3-1.6-0.8-1.9-1.5l-7.2,0.4
	c-0.3,0.8-0.8,1.4-1.8,1.6 M325.8,158.7l11-0.6 M362.3,157.2l-25.6,1 M291.4,247.2v-97.4c-0.1-0.1-0.1-0.3-0.1-0.4v-1.1
	c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.1l-0.1,0.4H292v93.1 M305.5,169
	l-2.5-2.3"
          />
          <path
            fill="none"
            d="M303.1,166.7l-0.3,1.2l-10.7,75 M297.3,222.4l5.9-45.7l-1-1l-9.9,70.4 M305.5,169c-0.3,0.7-0.5,1.5-0.8,2.5
	l-6.2,48.4 M306.1,217.3c-2.5,0-5,0.8-7.6,2.5"
          />
          <path
            fill="none"
            d="M296.3,230l-0.8,0.1l-0.1,1.8l0.5,0.8 M296.3,230l0.5-4.7 M295.9,232.7l0.4-2.8 M297.3,222.4l-0.8,0.6l-0.1,1.5
	l0.5,0.8 M320.9,226.7l-13.3,0.3c-1.9,0-2.8-0.7-2.6-2.1l1.1-7.6 M296.9,225.3l0.4-2.9 M276,222.3v11.8c0,1.6-0.8,2.5-2.5,2.5h-19.1
	c-1.7,0-2.5-0.8-2.5-2.5v-11.8c0-1.7,0.8-2.5,2.5-2.5h19.1C275.1,219.9,276,220.6,276,222.3L276,222.3z"
          />
          <path
            fill="none"
            d="M271.3,243.3h-16.9c-3.9,0.1-5.8-1.6-5.6-5.4v-11.7c0-3.9,0.1-6.9,0.6-8.9c0.6-1.8,1.8-2.6,4-2.6
	c8.8-0.7,18.4-0.8,28.8-0.4c2.8,0.3,4.1,2.1,4.1,5.2v19c0.1,2.9-1.8,4.5-5.6,4.8h-1.6"
          />
          <path
            fill="none"
            d="M278.7,243.3c0.1-2.6-1.2-4.3-4.3-4.8c-1.7-0.4-2.8,0.4-3,2.2v2.6 M276.6,249.8v-0.8 M276.6,249.8h-3.4
	 M273.2,248.9h3.4 M273.2,248.9h-1.9v-5.6 M282.1,258l-11.4-1.6v-6.6h2.5 M273.2,249.8v-0.8"
          />
          <path
            fill="none"
            d="M276.6,248.9h2.1v-5.6 M282.1,249.8h-5.5 M304,236.1c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3
	s0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	C304.2,236.8,304,236.6,304,236.1L304,236.1z M295.6,243.2l-1.1,0.3 M294.5,243.4l-0.3,1.6 M291.4,247.2c-0.4,0.7-0.8,1.5-1.1,2.6
	 M292.3,246.2l-0.3,0.1 M294.2,245.6h-0.8c-0.4,0.1-0.7,0.3-1,0.6 M290.7,258.4c-0.7-3.2-0.5-6.1,0.5-8.7h-1 M294.2,245.1v0.6
	 M292,246.3v-3.4 M294.2,245.1l1,0.7 M295.6,243.2l-0.4,2.6 M294.5,243.4l1.4-10.7 M298.5,219.9l-2.9,23.2 M290.3,249.8h-8.1
	 M263.4,296.8c0.1-1.2,0.5-2.3,1.1-3.2c0.5-0.8,1.1-1.1,1.6-1.1c0.4,0.1,0.8,0.5,1,1.5s0.1,2.1,0,3.3c-0.1,1.2-0.5,2.3-1,3.2
	c-0.4,0.8-0.8,1.2-1.4,1.2c-0.5-0.1-1.1-0.7-1.2-1.5C263.3,299.2,263.3,298.1,263.4,296.8z M262.1,315v-20.2l1.2-3 M263.3,291.7
	v-2.3l3.3-6.7c1.1-2.3,3-3.7,5.6-4l16.4-2.6 M292,257.7c0.4,0,0.5,0.1,0.5,0.5v1.5 M292.6,259.8c0.4,0,0.5,0.1,0.5,0.5v0.8
	c0,0.3-0.1,0.5-0.5,0.5 M293.4,260.8l0.8,0.1 M292.5,263.1h0.8 M291.2,263.1h1.2 M292.6,261.7v0.8c0,0.3-0.1,0.4-0.3,0.4
	 M293.3,263.1l0.1-2.3 M293.4,260.2v0.5 M291.4,257.7c-0.4,0-0.5,0.1-0.5,0.5v0.1 M290.7,259.8h-0.1c-0.1,0-0.3,0-0.3,0.1
	 M290.1,259.9c-0.1-0.3-0.1-0.5,0-0.8 M290.7,261.7h-0.1c-0.3,0-0.5-0.1-0.5-0.5v-0.8c0-0.1,0-0.3,0.1-0.4 M290.7,259.3v0.5
	 M290.1,259.1l0.5,0.1 M290.7,259.3v-0.8 M291.2,263.1c-0.3,0-0.5-0.1-0.5-0.5v-0.8 M289,265h1.1v-0.7h1.1v-1.2 M292.6,272.5v-5.4
	c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-0.4c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v5.4 M292.6,259.8h-1.9 M291.4,257.7h0.7
	 M292.6,261.7h-1.9 M292.6,275.4v-2.9 M293.4,275.2l-0.7,0.1 M289.7,275.8V270l-0.7,0.1 M290.3,275.8l-0.5,0.1 M290.3,272.5v3.2
	 M288.5,276v-4.5c0-0.8,0.3-1.2,0.5-1.4 M289.7,275.8l-1.4,0.3 M290.3,272.5h2.3 M290.3,275.8l2.3-0.4 M294.2,260.9
	c1.9,0.4,3.9,0.8,5.6,1.2 M293.4,275.2l-0.1-12.1 M313,274.9c-3-1.4-6.3-1.8-9.6-1.4l-9.9,1.6 M289,270.1V265 M289,265h-2.9v-6.5
	 M286.1,258.6l-4-0.5 M290.1,259.1l-4-0.5 M282.1,249.8v8.3 M293.4,260.2l0.8-14.6 M295.2,245.8l-1.9,14.6 M291.4,247.2v10.6
	 M365.8,239.7c-15.5,0.4-30.3,1.4-44.3,2.9c-8.9,1.1-14.9,4.1-17.6,9.1c-1.5,2.8-2.8,6.2-4,10.5 M292,246.3v11.4 M354.4,236.7
	c-0.1-0.1-0.4-0.3-0.7-0.3l-9.5,0.6 M322.3,270.3c3.7-8,12.1-12.2,25.3-12.7c21.2-1.2,43.7-1.8,67.7-1.8 M365.8,239.7l-1.2,4.7
	c3,0.3,5.5,0.1,7.6-0.4l-1-4.4 M344.3,239.2l9.5-0.6c0.3,0,0.5-0.1,0.7-0.4s0.3-0.6,0.3-0.8s-0.1-0.6-0.3-0.8 M371.1,239.6
	c-1.8-0.6-3.6-0.6-5.4,0.1 M315.3,231.2c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.6-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3
	C315.2,230.6,315.3,230.9,315.3,231.2L315.3,231.2z M320.9,231.2v-4.5 M324.1,256.2l-1.4,0.1l-3,2.1c0,0.8-0.5,1.8-1.6,2.9l1,0.3
	 M324.5,260.4l0.4-0.5l-1.4-0.8l1.8-2.3l-1.2-0.3 M294.3,260.9c2.8-12.1,8-18.8,15.7-20.4c2.8-0.6,6.3-1.1,10.7-1.6 M344.2,237
	l-9.5,0.6c-0.3,0-0.4,0.1-0.5,0.3 M334.1,237.8c-0.3,0.3-0.3,0.6-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.4,0.3,0.7,0.3l9.5-0.6
	 M344.3,239.2l-0.1-2.2 M320.7,238.9l0.3-7.8 M334.1,237.8l-13.5,1.2 M398.9,281.8v1.2 M342.3,261c5,0,9.2,1.8,12.7,5.2
	c3.4,3.4,5.2,7.7,5.2,12.7c0,5-1.8,9.2-5.2,12.7c-3.4,3.6-7.7,5.2-12.7,5.2c-5,0-9.2-1.8-12.7-5.2s-5.2-7.7-5.2-12.7
	s1.8-9.2,5.2-12.7C333.2,262.8,337.3,261.1,342.3,261L342.3,261z M342.3,262.3c4.7,0,8.5,1.6,11.8,4.8c3.3,3.3,4.8,7.2,4.8,11.8
	c0,4.7-1.6,8.5-4.8,11.8c-3.3,3.3-7.2,5-11.8,5c-4.5,0-8.5-1.6-11.8-5c-3.3-3.3-5-7.2-5-11.8s1.6-8.5,5-11.8
	C333.7,263.9,337.7,262.3,342.3,262.3L342.3,262.3z M411.3,283.1v-1.2 M323.5,261.5l0.4,0.1c0.1,0,0.4-0.1,0.5-0.3l0.3-0.5
	c0-0.1-0.1-0.4-0.3-0.5 M324.5,260.4c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.4,0.1-0.5,0.3s-0.3,0.3-0.3,0.5c0,0.1,0.1,0.3,0.1,0.5
	l0.1,0.1 M322.7,262.6l0.8-1.1 M319.1,261.7l0.1,0.1 M319.3,261.7L319,262 M319,262.2l-1.6,0.3l-3.2,3.6l1.4,0.3 M322.7,262.6
	l-1.4-0.1l-2.1-0.7 M319.1,261.7c1.1-1.2,1.7-2.3,1.7-3.2l3.4-2.3 M317.5,267l0.4,0.1h1.6l3.3-4.4 M317.2,267.9l0.1-1.1 M320,269.3
	l-2.8-1.2 M315.4,266.3l0.3,0.1 M315.7,266.4l-0.1,1 M314.3,272.5h-1.5v2.5 M314.3,272.5l2.1,0.5 M314.3,275.6l0.1-3.2 M313,274.9
	l1.4,0.7 M321.9,289.5c-0.4-6.7-0.4-13.1,0.1-18.7l0.3-0.5 M317.6,274.9l0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.4-0.3,0.5
	l-0.4,0.3 M319.1,280.3c0-3.4,0.1-6.7,0.4-9.9v-0.3l0.3-0.7 M316.4,273.2l1.4,0.4v1.4 M317.6,276.3l-0.4-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3h0.1 M321.9,289.5c-0.5-2.9-1.1-5.2-1.8-7l-0.3-0.8
	c-0.1-0.5-0.4-1-0.7-1.4 M317.6,278.2c0.7,0.7,1.1,1.2,1.5,1.9 M317.6,276.3l-0.1,1.9 M315.6,267.2l-1.1,5.2 M322.3,270.3l-2.3-1
	 M314.3,275.6c1.4,0.8,2.5,1.6,3.3,2.6 M317.2,267.9l-0.8,5.1 M319,262.1l-3.4,4.1 M317.5,267l-1.6-0.5 M323.4,303.1
	c-0.4-5.4-0.8-9.9-1.5-13.5 M323.4,303.1l0.1,2.1h-0.1 M323.4,305.2l-0.5,0.4 M344.6,302.9c1.6,1.7,2.5,3.6,2.5,5.9
	s-0.8,4.3-2.5,5.9c-1.6,1.6-3.6,2.5-5.9,2.5c-2.3,0-4.3-0.8-5.9-2.5c-1.6-1.7-2.5-3.6-2.5-5.9s0.8-4.3,2.5-5.9
	c1.6-1.6,3.6-2.5,5.9-2.5C341,300.4,343,301.2,344.6,302.9L344.6,302.9z M344.1,303.4c1.5,1.5,2.2,3.3,2.2,5.4
	c0,2.1-0.7,3.9-2.2,5.4s-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2s-2.2-3.3-2.2-5.4c0-2.1,0.7-3.9,2.2-5.4s3.3-2.2,5.4-2.2
	S342.5,302,344.1,303.4z M378.2,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4v6.7 M376.9,278.2v1.2 M376.9,279.5v2.3 M378.2,281.8v-2.3 M378.2,279.5v-1.2 M378.2,278.2h-1.2
	 M378.2,281.8h-1.2 M376.9,279.5h1.2 M365.8,280.4l3.2-4.1h8v1.9 M382.2,279.5v2.3 M382.2,278.2v1.2 M382.2,283.1v2.6 M382.2,285.5
	v1.2 M378.2,286.8v-1.2 M378.2,285.5v-2.6 M378.2,283.1h-1.2 M378.2,286.8h-0.8 M378.2,285.5h-0.8 M378.2,290.2V289 M378.2,292.4
	v-2.2 M378.2,293.6v-1.2 M376.9,292.4v1.2 M378.2,293.6h-1.2 M376.9,292.4h1.2 M378.2,289.1h-0.8 M378.2,290.2h-0.8 M382.2,289.1
	v1.2 M382.2,292.4v1.2 M382.2,290.2v2.2 M378.2,289.1v-2.3 M382.2,286.8v2.3 M376.9,283.1v9.4 M382.2,296v1.2 M382.2,297.1v2.6
	 M378.2,299.7h-1.2 M378.2,296h-1.2 M378.2,297.1h-1.2 M376.9,296v1.2 M376.9,297.1v2.6 M378.2,297.1v-1.2 M378.2,299.7v-2.6
	 M378.2,303.3v-2.3 M378.2,304.4v-1.2 M377.9,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M376.9,303.3v1.2 M376.9,300.9v2.3 M378.2,300.9
	h-1.2 M378.2,303.3h-1.2 M378.2,304.4h-1.2 M382.2,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M382.2,303.3v1.2 M382.2,300.9v2.3 M378.2,300.9
	v-1.2 M382.2,299.7v1.2 M376.9,299.7v1.2 M382.2,293.6v2.3 M378.2,296v-2.3 M376.9,293.6v2.3 M382.2,281.8v1.2 M365.8,280.4v25
	c0,1,0.5,1.8,1.8,2.5c1.2,0.7,2.6,1,4.4,1c1.7,0,3.2-0.3,4.4-1l0.7-0.4 M378.2,283.1v-1.2 M376.9,281.8v1.2 M320.9,231.2l49.8-1.5
	h19 M299.9,262.1c5.5,1.4,10.7,3,15.5,5.1 M377.9,306.4h-1 M376.9,306.4v1.1 M376.9,307.5c0.4-0.3,0.8-0.7,1-1.1 M382.4,306.4
	c0.1,0.4,0.5,0.8,1.1,1.2 M383.5,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.8,0,3.2-0.3,4.4-1l0.5-0.3 M352.4,324.3l-1.5-0.3h-25.6
	 M356.7,326.4c-1.4-1-2.8-1.6-4.3-2.1 M371.8,332v22.1c0,0.7,0.3,1,1,1h96.6c0.7,0,1-0.3,1-1V332c0-0.7-0.3-1-1-1h-96.6
	C372.1,331.1,371.8,331.3,371.8,332L371.8,332z M376.5,353.2h89c0.7,0,1-0.3,1-1v-18.6c0-0.7-0.3-1-1-1h-89c-0.7,0-1,0.3-1,1v18.6
	C375.6,352.9,375.8,353.2,376.5,353.2L376.5,353.2z M415.4,293.6v2.3 M324.4,315c1,1.5,1.9,2.8,3.2,3.9c1.6,1.4,3.7,2.1,6.2,2.3
	 M334.3,321.2h11.1 M323.8,308.6c1.6,5.2,4.1,8.7,7.3,10.3c2.6,1.2,7.3,2.1,14.3,2.2 M322.9,305.6l-1.9,1.4l0.1,1.8 M325.2,324
	l-0.8-9.1 M323.8,308.6l0.5,6.3 M321.2,308.8l2.6-0.1 M315.6,324.5c-1.2,0.4-2.6,1-4.1,1.9 M325.2,324h-7.6l-1.9,0.3 M322.9,305.6
	l1,2.8v0.3 M342.5,364.9v3.3 M318.2,365l0.7,0.3l23.8-0.4 M308.6,365h9.6 M308.6,368.2V365 M308.6,377.1v-9.1 M318.2,365
	c-1.5-0.7-3-1.2-4.5-1.5 M308.6,365V363 M308.6,362.8l5.1,0.5 M342.5,368.2h-34 M308.6,381.5v-4.3 M376.8,368.2h1.8 M376.8,368.2
	v-5.1 M378.4,363.1v5.1 M376.8,363.1h1.8 M354,364.6c0.8-0.1,1.7-0.4,2.5-1l1.6-0.7 M350.1,368.2c0.1-2.1,0.5-3.2,1.1-3.3
	 M351.1,364.9l2.8-0.1 M350.1,368.2h26.7 M358.1,363.1h18.6 M342.5,368.2h7.4 M313.7,363.5l-2.1-37.1 M315.6,324.5l2.6,40.6
	 M352.4,324.3l1.5,40.3 M358.1,363.1l-1.4-36.7 M342.5,364.9h8.5 M311.5,326.4l-37.3,1.7c-1.6,0-2.6,0.8-2.6,2.6l0.4,17.6
	c0.5,4.7,1.5,8.3,2.8,10.9 M282.3,336c1.7-1.6,3.9-2.6,6.2-2.6c2.5,0,4.5,0.8,6.2,2.6c1.7,1.8,2.6,3.9,2.6,6.2
	c0,2.5-0.8,4.5-2.6,6.2c-1.8,1.8-3.9,2.6-6.2,2.6c-2.5,0-4.5-0.8-6.2-2.6c-1.8-1.6-2.6-3.9-2.6-6.2
	C279.7,339.9,280.5,337.8,282.3,336L282.3,336z M283.4,337.3c1.4-1.4,3-2.1,5.1-2.1c1.9,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1
	c0,1.9-0.7,3.7-2.1,5.1c-1.4,1.4-3,2.1-5.1,2.1c-1.9,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3-2.1-5.1C281.3,340.3,282,338.6,283.4,337.3
	L283.4,337.3z M287,340.7c0.4-0.4,1-0.5,1.5-0.5s1.1,0.1,1.5,0.5c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5c-0.4,0.4-1,0.7-1.5,0.7
	s-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-1-0.7-1.5S286.6,341.1,287,340.7L287,340.7z M263.3,314v-2.9 M263.3,314l-2.9,3
	c-0.5,0.4-1,1.9-1.2,4.3V432c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1
	c4-1.2,6.2-3.9,6.6-7.7v-50.5 M263.3,308.1v2.9 M274.7,359h-6.9c-1.1,0-1.9-0.3-2.1-0.7c-1.6-4.5-2.5-8.3-2.5-10.9V314 M308.6,362.8
	l-28.6-2.5c-2.8-0.1-4.4-0.5-5.2-1.4 M323.4,305.2l-56,2.9h-4.3 M263.3,311.1l5.1,0.1l52.8-2.5 M411.3,296v-2.3 M415.4,299.7v1.2
	 M411.3,300.9v-1.2 M411,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M411.3,304.4v-1.1 M411.3,303.3v-2.3 M415.4,304.4v1c0,0.4,0.1,0.7,0.3,1
	 M415.4,303.3v1.1 M578.9,308.1h-4.3l-55.8-2.9 M578.9,311.1l-5.2,0.1l-52.7-2.5 M578.9,308.1v3 M578.9,314v-2.9 M533.6,381.6V432
	c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1c4-1.2,6.2-3.9,6.6-7.7V321.3
	c-0.1-2.3-0.5-3.7-1.2-4.3l-2.9-3 M555.2,340.7c-0.4-0.4-1-0.5-1.5-0.5c-0.5,0-1.1,0.1-1.5,0.5c-0.4,0.4-0.7,1-0.7,1.5
	s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7c0.5,0,1.1-0.3,1.5-0.7s0.7-1,0.7-1.5C555.8,341.7,555.6,341.1,555.2,340.7L555.2,340.7z"
          />
          <path
            fill="none"
            d="M558.8,337.3c-1.4-1.4-3-2.1-5.1-2.1c-1.9,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.7,2.1,5.1
	c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.7-0.7,5.1-2.1c1.4-1.4,2.1-3,2.1-5.1C560.8,340.3,560.2,338.6,558.8,337.3L558.8,337.3z"
          />
          <path
            fill="none"
            d="M559.9,336c-1.7-1.6-3.9-2.6-6.2-2.6c-2.5,0-4.5,0.8-6.2,2.6c-1.7,1.8-2.6,3.9-2.6,6.2c0,2.5,0.8,4.5,2.6,6.2
	c1.8,1.8,3.9,2.6,6.2,2.6c2.5,0,4.5-0.8,6.2-2.6c1.8-1.6,2.6-3.9,2.6-6.2C562.5,339.9,561.7,337.8,559.9,336L559.9,336z"
          />
          <path
            fill="none"
            d="M567.5,359c1.2-2.6,2.1-6.2,2.8-10.9l0.4-17.6c0-1.6-1-2.5-2.6-2.6l-37.3-1.6"
          />
          <path
            fill="none"
            d="M578.9,314v33.4c0,2.6-0.8,6.3-2.5,10.9c-0.3,0.4-1,0.7-2.1,0.7h-6.9"
          />
          <path
            fill="none"
            d="M533.6,362.8l28.6-2.5c2.8-0.1,4.4-0.5,5.4-1.4 M530.6,326.4c-1.5-1-2.9-1.6-4.1-1.9 M517.8,315l0.4-6.3
	 M521,308.8l0.1-1.8l-1.8-1.4 M518.2,308.6l0.1-0.3l1-2.8 M521,308.8l-2.8-0.1 M508.3,321c2.6-0.3,4.7-1,6.2-2.3
	c1.2-1.1,2.3-2.3,3.2-3.9 M526.5,324.5l-1.9-0.4H517 M517.8,315l-0.8,9.1 M496.6,321.2c7-0.3,11.7-1,14.3-2.2
	c3.2-1.6,5.6-5,7.3-10.3 M415.4,300.9v2.3 M517,324.1h-25.7l-1.5,0.3 M489.7,324.3c-1.5,0.3-3,1-4.3,2.1 M488.2,364.6l1,0.1
	c1,0.1,2.1,0.1,3.2,0l7.3,0.1 M484.1,363.1l1.6,0.7c0.8,0.4,1.6,0.8,2.5,1 M499.5,364.9v3.3 M533.6,368.2v8.9 M533.6,365v-2.2
	 M533.6,368.2V365 M528.5,363.5l5.1-0.7"
          />
          <path
            fill="none"
            d="M524,365l-0.7,0.3l-23.8-0.4 M533.6,377.2v4.4 M524,365c1.5-0.7,3-1.2,4.5-1.5 M533.6,365H524 M496.6,321.2
	h11.3 M499.5,368.2h34.1 M526.5,324.5L524,365 M488.2,364.6l1.5-40.3 M484.1,363.1l1.4-36.7 M528.5,363.5l2.1-37.1 M465.2,307.5
	c-0.4-0.3-0.8-0.7-1-1.1 M465.2,307.5v-1.1 M458.6,307.5c0.5-0.4,0.8-0.8,1.1-1.2"
          />
          <path
            fill="none"
            d="M448.9,307.5l0.6,0.3c1.2,0.7,2.6,1,4.4,1c1.7,0,3.2-0.3,4.4-1l0.5-0.3 M465.2,306.4h-1 M447.8,306.4
	c0.1,0.4,0.5,0.8,1.1,1.2 M459.7,306.4h-12 M448.9,307.5h9.8 M442,307.5c0.5-0.4,0.8-0.8,1.1-1.2 M432.1,307.5l0.5,0.3
	c1.2,0.7,2.6,1,4.4,1c1.6,0,3.2-0.3,4.4-1l0.5-0.3 M431.1,306.4c0.1,0.4,0.5,0.8,1.1,1.2 M443.1,306.4h-12 M432.1,307.5h9.9
	 M421.9,308.8c1.1-0.1,2.2-0.4,3-1l0.5-0.3 M421.1,308.9h0.4l0.4-0.1 M421.1,308.9l-0.8-0.1 M416.7,307.5l0.5,0.3
	c1,0.5,1.9,0.8,3.2,1 M420.3,308.8l0.3,0.1h0.5 M421.9,308.8l-0.8,0.1 M425.5,307.5h-8.8 M400.2,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1
	c1.6,0,3.2-0.3,4.4-1l0.5-0.3 M409.9,307.5c0.5-0.4,0.8-0.8,1.1-1.2 M415.6,306.4c0.3,0.4,0.5,0.8,1.1,1.2 M399.1,306.4
	c0.1,0.4,0.5,0.8,1.1,1.2 M393.3,307.5c0.5-0.4,0.8-0.8,1.1-1.2 M411,306.4h-12 M409.9,307.5h-9.8 M425.5,307.5
	c0.5-0.4,0.8-0.8,1.1-1.2 M426.4,306.4h-10.9 M415.4,296v1.1 M415.4,297.1v2.6 M411.3,297.1V296 M411.3,299.7v-2.6 M394.5,297.1V296
	 M394.5,290.2v-1.1 M394.5,292.4v-2.2 M394.5,293.6v-1.2 M394.5,289.1v-2.3 M394.5,286.8v-1.2 M394.5,285.5v-2.5 M415.4,286.8v2.3
	 M411.3,289.1v-2.3 M415.4,292.4v1.2 M415.4,290.2v2.2 M411.3,293.6v-1.2 M411.3,292.4v-2.2 M411.3,290.2v-1.1 M415.4,289.1v1.1
	 M396.6,156.4c-7.8,0.1-15.7,0.3-23.7,0.4 M394.5,278.2h-12.2 M465.2,306.4v-2.1 M578.9,291.7v16.4 M263.3,308.1v-16.4 M376.9,304.4
	v2.1 M485.5,326.4H356.7 M533.6,377.2H308.6 M378.4,368.2h121.1 M533.6,381.6H308.6 M378.4,363.1h105.6 M394.4,306.4h-12
	 M393.3,307.5h-9.8 M394.5,281.8h-12.2 M394.5,279.5h-12.2 M394.5,285.5h-12.2 M394.5,283.1h-12.2 M394.5,286.8h-12.2 M394.5,290.2
	h-12.2 M394.5,289.1h-12.2 M394.5,292.4h-12.2 M394.5,293.6h-12.2 M394.5,297.1h-12.2 M394.5,296h-12.2 M411.3,286.8v-1.2
	 M394.5,299.7h-12.2 M394.5,303.3h-12.2 M394.5,304.4h-12.2 M345.4,321.2h151.2 M398.9,290.2v2.2 M398.9,292.4v1.2 M398.9,289.1v1.1
	 M398.9,286.8v2.3 M398.9,285.5v1.2 M398.9,283.1v2.5 M398.9,278.2v1.2 M398.9,279.5v2.3 M394.5,279.5v-1.2 M394.5,281.8v-2.3
	 M394.5,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4v4.7
	 M411.3,279.5v-1.2 M411.3,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4v4.7 M411.3,281.8v-2.3 M415.4,278.2v1.2 M415.4,279.5v2.3 M421.1,266.1l0.7,0.1 M421.8,266.3
	c-0.1-0.1-0.1-0.1-0.3-0.1h-0.5 M421.8,266.3c1.2,0.3,2.2,0.8,3.2,1.8c1.2,1.2,1.8,2.8,1.8,4.5v5.8 M415.4,278.2v-5.8
	c0-1.8,0.5-3.3,1.8-4.5c1-1,1.9-1.5,3.2-1.8 M421.1,266.1l-0.8,0.1"
          />
          <path
            fill="none"
            d="M420.3,266.3c0.1-0.1,0.1-0.1,0.3-0.1h0.5 M415.2,255.8h2.5 M417.6,255.8h6.9"
          />
          <path
            fill="none"
            d="M320,269.3c4-8.9,13.1-13.6,27.5-14.2c22-1.2,45.7-1.9,70.8-1.8h5.5c25.2-0.1,48.8,0.6,70.8,1.8
	c14.3,0.4,23.5,5.2,27.5,14.2 M415.2,255.8h2.5 M487.8,236.7l-2.1-0.1c-21.7-1.1-42.8-1.5-63.3-1.5h-2.8
	c-20.5-0.1-41.5,0.4-63.4,1.5l-2.1,0.1 M470.9,239.6c-16.6-0.7-32.2-1-46.8-1h-6.1c-14.6-0.1-30.1,0.3-46.8,1 M389.7,230.8v-1
	 M389.7,229.8h-0.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.3-0.1,0.3v0.1c0,0.1,0,0.3,0.1,0.3l0.3,0.1h0.1 M389.7,229.5v0.1
	 M424.5,255.8h2.5 M429.1,243.4c0.1-0.8,0-1.5-0.1-1.9c-0.1-0.3-0.3-0.4-0.5-0.3h-4.5l-0.1,0.3c-0.1,0.1-0.4,0.3-0.7,0.3h-4
	c-0.3,0-0.5-0.1-0.7-0.3l-0.1-0.3h-4.5c-0.3,0-0.5,0.1-0.5,0.3c-0.1,0.4-0.1,1-0.1,1.9l-0.1,0.4l-0.3,0.1l-0.4-0.1l-0.1-0.3
	c-0.1-1.1,0-1.9,0.3-2.3c0.3-0.7,0.8-1,1.5-1h4.4l0.1-0.1c0.1-0.1,0.4-0.3,0.7-0.3h4c0.3,0,0.5,0.1,0.7,0.3l0.1,0.1h4.4
	c0.7,0,1.2,0.3,1.5,1c0.3,0.6,0.3,1.2,0.3,2.3l-0.1,0.3l-0.4,0.1l-0.3-0.1C429.2,243.7,429.1,243.6,429.1,243.4L429.1,243.4z
	 M452.4,229.8v1.1 M389.7,230.8c0,0.3,0.1,0.6,0.5,0.6H452c0.3,0,0.5-0.1,0.5-0.6 M452.4,230.8h0.1c0.1,0,0.3,0,0.3-0.1l0.1-0.3
	v-0.1l-0.1-0.3c-0.1-0.1-0.3-0.1-0.3-0.1h-0.1 M452.4,229.5c0-0.3-0.1-0.4-0.5-0.4h-61.8c-0.3,0-0.4,0.1-0.5,0.4 M452.4,229.8v-0.3
	 M433.5,156.4h-24.9 M396.6,156.4h12 M408.6,156.4c-1.5-0.3-2.3-0.7-2.6-1.5h-6.6c-0.3,1-1.1,1.5-2.6,1.6 M433.5,156.4h12
	 M445.4,156.4c-1.4-0.3-2.3-0.8-2.6-1.6h-6.6c-0.4,1-1.2,1.5-2.6,1.5 M516.3,158.7l-10.9-0.6 M536.1,217.3l-5.9-41.7
	c-1.1-4.4-3.3-6.6-6.5-6.5c-30-1.6-59.4-2.6-88.5-2.9H407c-29,0.3-58.5,1.2-88.5,2.9c-3.2-0.1-5.4,2.1-6.5,6.5l-5.9,41.7
	 M411.3,285.5v-2.5 M543.5,219.9l-6.2-48.4c-0.3-1-0.5-1.6-0.8-2.5 M415.4,283.1v2.5 M415.4,285.5v1.2 M394.5,296v-2.3 M398.9,293.6
	v2.3 M398.9,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M398.9,303.3v1.1 M549.8,246.2l-10-70.4l-0.8,1l5.9,45.7 M539.1,166.7l-2.6,2.3"
          />
          <path
            fill="none"
            d="M536.5,169c-1.4-2.9-3.9-4.4-7.4-4.7c-34.7-2.2-68.9-3.3-102.8-3.3h-5.9c-35.5,0-71.3,1.1-107.4,3.3
	c-3.6,0.3-6.1,1.8-7.4,4.7 M550.7,247l-11.3-79.1l-0.3-1.2 M505.4,158.1l-25.6-1 M398.9,300.9v2.3 M398.9,299.7v1.2 M398.9,297.1
	v2.6 M398.9,296v1.1 M394.4,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M394.5,304.4v-1.1 M394.5,300.9h-12.2 M394.5,303.3v-2.3 M479.8,157.2
	l-10.6-0.3 M469.2,156.9c1.1-0.1,1.9-0.6,2.3-1.5l6.2,0.1c0.4,0.8,1.1,1.4,2.1,1.6 M539.1,166.7c-1-4.3-2.6-6.5-4.7-6.5l-6.7-1
	l-11.4-0.7 M505.4,158.1c1-0.3,1.6-0.8,1.9-1.5l7.2,0.4c0.3,0.8,0.8,1.4,1.8,1.6"
          />
          <path
            fill="none"
            d="M516.3,158.7c-1-0.3-1.5-0.8-1.8-1.6l-7.1-0.4c-0.3,0.7-1,1.2-1.9,1.5L516.3,158.7L516.3,158.7z"
          />
          <path
            fill="none"
            d="M534.4,160.2l-6.7-1l-11.4-0.7l-10.9-0.6l-25.6-1l-10.6-0.3c-8-0.3-15.8-0.4-23.7-0.4l-12-0.1h-25l-11.8,0.1
	c-7.8,0.1-15.7,0.3-23.7,0.4l-10.6,0.3l-25.6,1l-10.9,0.6l-11.4,0.7l-6.7,1c-2.1,0.1-3.7,2.2-4.7,6.5l2.6,2.3
	c1.4-2.9,3.9-4.4,7.4-4.7c36.2-2.2,72.1-3.3,107.6-3.3h5.9c34,0.1,68.2,1.2,102.8,3.3c3.6,0.3,6.1,1.8,7.4,4.7l2.6-2.3
	C538.2,162.4,536.5,160.4,534.4,160.2L534.4,160.2z"
          />
          <path
            fill="none"
            d="M479.8,157.2c-1-0.3-1.6-0.8-2.1-1.6l-6.2-0.1c-0.5,0.8-1.2,1.4-2.3,1.5L479.8,157.2L479.8,157.2z M539.4,167.9
	l-0.3-1.2l-2.6,2.3c0.3,0.7,0.5,1.5,0.8,2.5l6.2,48.4l3,23.2l1.1,0.3l-1.4-10.9l-0.3-2.8l-0.5-4.7l-0.4-2.8l-5.9-45.8l1-0.8
	l9.9,70.3l0.8,0.8L539.4,167.9L539.4,167.9z"
          />
          <path
            fill="none"
            d="M536.5,169c-1.4-2.9-3.9-4.4-7.4-4.7c-34.7-2.2-68.9-3.3-102.8-3.3h-5.9c-35.5,0-71.3,1.1-107.4,3.3
	c-3.6,0.3-6.1,1.8-7.4,4.7c-0.3,0.7-0.5,1.5-0.8,2.5l-6.2,48.4c2.5-1.6,5.1-2.6,7.6-2.5l5.9-41.7c1.1-4.4,3.3-6.6,6.5-6.5
	c30-1.6,59.4-2.6,88.5-2.9h28.2c29,0.3,58.5,1.2,88.5,2.9c3.2-0.1,5.4,2.1,6.5,6.5l5.9,41.7c2.5,0,5,0.8,7.6,2.5l-6.2-48.4
	C537.2,170.5,536.9,169.7,536.5,169L536.5,169z M539.8,175.8l-1,0.8l5.9,45.8l0.8,0.4l0.3,1.5l-0.7,0.8l0.5,4.7l0.8,0.1l0.1,1.6
	l-0.5,0.8l1.4,10.9l0.1,1.5l0.1,0.6h0.8c0.4,0.1,0.7,0.3,1,0.6L539.8,175.8z"
          />
          <path
            fill="none"
            d="M530.2,175.8c-1.1-4.4-3.3-6.6-6.5-6.5c-30-1.6-59.4-2.6-88.5-2.9h-28.2c-29,0.3-58.5,1.2-88.5,2.9
	c-3.2-0.1-5.4,2.1-6.5,6.5l-5.9,41.7l-1.1,7.6c-0.3,1.4,0.5,2.1,2.6,2.1l13.3-0.3c34.7-1.2,68-1.6,100.1-1.2
	c32.2-0.4,65.5,0,100,1.2l13.3,0.3c1.9,0,2.8-0.7,2.6-2.1l-1.1-7.6L530.2,175.8L530.2,175.8z M518.6,174.9c3.6,0.3,5.5,1.6,5.9,4.1
	l3.7,39.1c-0.1,2.5-2.2,3.7-6.3,3.7c-31.5-1.1-63.3-1.8-95.3-1.8h-11.4c-32.1,0-63.8,0.6-95.3,1.8c-4.1,0-6.2-1.2-6.3-3.7l3.7-39.1
	c0.4-2.5,2.3-3.9,5.9-4.1c27.2-1.5,55.9-2.3,85.7-2.8h23.9C462.8,172.6,491.4,173.4,518.6,174.9L518.6,174.9z"
          />
          <path
            fill="none"
            d="M524.4,179.1c-0.4-2.5-2.3-3.9-5.9-4.1c-27.2-1.5-55.9-2.3-85.7-2.8h-23.9c-30,0.4-58.5,1.2-85.7,2.8
	c-3.4,0.3-5.5,1.6-5.9,4.1l-3.7,39.1c0.1,2.5,2.2,3.7,6.3,3.7c31.5-1.1,63.3-1.8,95.3-1.8h11.4c32.2,0,64,0.6,95.3,1.8
	c4.1,0,6.2-1.2,6.3-3.7L524.4,179.1L524.4,179.1z M436.2,154.7c-0.4,1-1.2,1.5-2.6,1.5l12,0.1c-1.4-0.3-2.3-0.8-2.6-1.6L436.2,154.7
	L436.2,154.7z M399.3,154.9c-0.3,1-1.1,1.5-2.6,1.6l11.8-0.1c-1.5-0.3-2.3-0.7-2.6-1.5L399.3,154.9L399.3,154.9z M452.4,229.5v0.1
	h0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.3v0.1l-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.1c0,0.3-0.1,0.6-0.5,0.6h-61.8c-0.3,0-0.5-0.1-0.5-0.6
	h-0.1l-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.3v-0.1c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.3-0.1,0.3-0.1h0.1v-0.1h-19l-49.8,1.5l-0.3,7.8
	l13.5-1.2c0.1-0.1,0.4-0.3,0.5-0.3l9.5-0.6l9.5-0.6c0.3,0,0.4,0.1,0.7,0.3l2.1-0.1c21.7-1.1,42.9-1.6,63.4-1.5h2.8
	c20.5-0.1,41.5,0.4,63.4,1.5l2.1,0.1c0.1-0.1,0.4-0.3,0.7-0.3l9.5,0.6l9.5,0.6c0.3,0,0.4,0.1,0.5,0.3l13.5,1.2l-0.3-7.8l-49.9-1.5
	L452.4,229.5L452.4,229.5z"
          />
          <path
            fill="none"
            d="M452.4,229.8v-0.3c0-0.3-0.1-0.4-0.5-0.4h-61.8c-0.3,0-0.4,0.1-0.5,0.4v1.2c0,0.3,0.1,0.6,0.5,0.6h61.8
	c0.3,0,0.5-0.1,0.5-0.6L452.4,229.8L452.4,229.8z"
          />
          <path
            fill="none"
            d="M451.9,229.1c0.3,0,0.4,0.1,0.5,0.4h19l49.8,1.5v-4.5c-34.7-1.2-68-1.6-100-1.2c-32.2-0.4-65.5,0-100.1,1.2v4.5
	l49.8-1.5h19c0-0.3,0.1-0.4,0.5-0.4H451.9L451.9,229.1z"
          />
          <path
            fill="none"
            d="M453.1,230.2L453,230c-0.1-0.1-0.3-0.1-0.3-0.1h-0.1v1.1h0.1c0.1,0,0.3,0,0.3-0.1L453.1,230.2L453.1,230.2z
	 M494.5,257.6c-21.2-1.2-43.7-1.8-67.7-1.8H415c-23.9,0-46.5,0.6-67.7,1.8c-13.1,0.4-21.6,4.7-25.3,12.7l-0.3,0.5
	c0,0.4-0.1,0.7-0.1,1.1h3.9c0.8-2.1,2.2-3.9,3.9-5.5c3.4-3.4,7.7-5.2,12.7-5.2s9.2,1.8,12.7,5.2c1.6,1.6,3,3.6,3.9,5.5h7.2
	c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.7c0.3-1,0.8-1.8,1.5-2.5
	c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.8c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8
	c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.4c0.1-1.5,0.7-2.8,1.8-3.9c1-1,1.9-1.5,3.2-1.8c0.1-0.1,0.1-0.1,0.3-0.1h1.1
	c0.1,0,0.1,0,0.3,0.1c1.2,0.3,2.2,0.8,3.2,1.8c1.1,1.1,1.6,2.3,1.8,3.9h4.4c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8
	c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h4.8c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8
	c0.8,0.8,1.2,1.6,1.5,2.5h4.7c0.3-1,0.8-1.8,1.5-2.5c1.2-1.2,2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c0.8,0.8,1.2,1.6,1.5,2.5h7.2
	c0.8-2.1,2.1-3.9,3.9-5.5c3.4-3.4,7.7-5.2,12.7-5.2c5,0,9.2,1.8,12.7,5.2c1.7,1.6,3,3.6,3.9,5.5h3.8c0-0.4-0.1-0.7-0.1-1.1l-0.3-0.5
	C516.1,262.3,507.7,258,494.5,257.6L494.5,257.6z M421.8,261.9c0.3-0.3,0.5-0.5,1-0.7c0.3-0.1,0.7-0.3,1.1-0.3l1.2,0.1
	c0.8,0.1,1.2,0.4,1.5,1l0.3,0.7v0.5h-3.7l0.1,0.4c0.1,0.3,0.4,0.4,0.7,0.4h0.4c0.3,0,0.4-0.1,0.6-0.3h1.9v0.1l-0.3,0.4
	c-0.3,0.3-0.7,0.4-1.1,0.5L424,265l-1-0.1c-0.7-0.3-1.2-0.5-1.5-1.1c-0.1-0.1-0.1-0.4-0.1-0.7C421.2,262.7,421.5,262.3,421.8,261.9
	L421.8,261.9z M430.2,261l1.5,0.1c0.4,0.1,0.8,0.4,1.1,0.7l0.3,0.7c0.1,0.4,0.1,1-0.1,1.4c-0.1,0.3-0.3,0.5-0.5,0.7
	c-0.4,0.3-0.8,0.5-1.4,0.5l-1.2-0.1l-0.5-0.3h-0.1v1.4h-1.9V261h1.8v0.3h0.3l0.1-0.1C429.6,261.2,429.9,261.1,430.2,261L430.2,261z
	 M415.7,262l0.8-0.8c0.4-0.1,0.8-0.3,1.2-0.3l1.2,0.1c0.8,0.1,1.4,0.5,1.6,1l0.3,0.7l0.1,0.5h-3.7v0.3l0.1,0.3
	c0.1,0.1,0.3,0.3,0.5,0.3h0.5c0.3,0,0.4-0.1,0.5-0.3h1.8v0.1l-0.3,0.4c-0.3,0.3-0.5,0.4-1,0.5l-1.5,0.1l-1-0.1
	c-0.7-0.1-1.2-0.5-1.5-1.1c-0.1-0.3-0.3-0.5-0.1-1C415.4,262.6,415.6,262.3,415.7,262L415.7,262z M413.1,259.4h1.9v3.7
	c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.4-0.8,0.7-1.5,0.8l-1,0.1h-1.1l-1-0.1c-0.7-0.1-1.1-0.5-1.2-1l-0.1-0.5v-0.8h1.9v0.7l0.1,0.3
	c0.1,0.3,0.4,0.4,0.8,0.4l0.8-0.1c0.3-0.1,0.5-0.3,0.5-0.5L413.1,259.4L413.1,259.4z M464.3,271.9h-4.7c0.1,0.5,0.3,1.2,0.3,1.8
	v14.6h4.1v-14.7C464,273,464.1,272.5,464.3,271.9L464.3,271.9z M447.5,273.7c0-0.7,0.1-1.2,0.3-1.8H443c0.1,0.5,0.3,1.2,0.3,1.8
	v14.6h4.3L447.5,273.7L447.5,273.7z M431.1,271.9h-4.4v16.4h4.1v-14.7C430.9,273,431,272.5,431.1,271.9L431.1,271.9z M520.3,271.9
	h-3.9c1,2.2,1.4,4.5,1.4,7.2c0,3.3-0.8,6.3-2.3,8.9h5C520.7,282.2,520.5,276.9,520.3,271.9L520.3,271.9z M483.3,271.9h-7.2
	c0.1,0.5,0.3,1.2,0.3,1.8V288h7.8c-1.6-2.6-2.3-5.6-2.3-8.9C481.9,276.5,482.3,274,483.3,271.9L483.3,271.9z"
          />
          <path
            fill="none"
            d="M366.1,271.9h-7.2c1,2.2,1.4,4.5,1.4,7.2c0,3.3-0.8,6.3-2.3,8.9h7.8v-14.3C365.8,273,365.8,272.5,366.1,271.9
	L366.1,271.9z M325.8,271.9h-3.9c-0.4,5-0.4,10.3-0.1,16.1h5c-1.6-2.6-2.3-5.6-2.3-8.9C324.4,276.5,324.8,274,325.8,271.9
	L325.8,271.9z M415.4,271.9H411c0.1,0.5,0.3,1.2,0.3,1.8v14.6h4.1v-15.8V271.9L415.4,271.9z M398.9,273.7c0-0.7,0.1-1.2,0.3-1.8
	h-4.8c0.1,0.5,0.3,1.2,0.3,1.8v14.6h4.3L398.9,273.7L398.9,273.7z M382.4,271.9h-4.7c0.1,0.5,0.3,1.2,0.3,1.8v14.6h4.1v-14.7
	C382.2,273,382.3,272.5,382.4,271.9L382.4,271.9z"
          />
          <path
            fill="none"
            d="M415.4,288h-4.1v16.1h4.1v-15 M398.9,289.1V288h-4.4v16.1h4.4v-13.9 M382.2,288h-4v16.1h4v-15"
          />
          <path
            fill="none"
            d="M382.2,304.1H378v1.4c0,0.3-0.1,0.5-0.1,1c-0.1,0.4-0.5,0.8-1,1.1l-0.7,0.4c-1.2,0.7-2.6,1-4.4,1
	c-1.6,0-3.2-0.3-4.4-1c-1.2-0.7-1.8-1.5-1.8-2.5v-1.4h-20.1c1,1.4,1.4,2.9,1.4,4.7c0,2.3-0.8,4.3-2.5,5.9c-1.7,1.6-3.6,2.5-5.9,2.5
	c-2.3,0-4.3-0.8-5.9-2.5c-1.6-1.7-2.5-3.6-2.5-5.9c0-1.8,0.4-3.3,1.4-4.7h-8.1v0.1l0.1,1.2c1.8,6.7,4.5,11,8.1,12.7
	c2.3,1.4,8.9,2.1,19.9,1.9h138.5c11,0.1,17.6-0.5,19.9-1.9c3.6-1.8,6.3-5.9,8.1-12.7l0.1-1.2v-0.1h-8c1,1.4,1.4,2.9,1.4,4.7
	c0,2.3-0.8,4.3-2.5,5.9c-1.6,1.6-3.6,2.5-5.9,2.5s-4.3-0.8-5.9-2.5c-1.7-1.7-2.5-3.6-2.5-5.9c0-1.8,0.4-3.3,1.4-4.7h-20.1v1.4
	c0,1-0.5,1.8-1.8,2.5c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.6-0.3H465c-0.4-0.3-0.8-0.7-1-1.1c-0.1-0.3-0.3-0.5-0.3-1v-1.4
	h-4.1v1.4c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.3,0.1c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3
	c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.4h-4.3v1.4c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3
	c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.4h-4.1v1.2
	c0,0.4-0.1,0.7-0.3,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3c-1,0.5-1.9,0.8-3,1l-0.3,0.1h-1.1l-0.3-0.1c-1.2-0.1-2.2-0.4-3.2-1
	l-0.5-0.3c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.7-0.3-1v-1.2h-4.1v1.4c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3
	c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.4h-4.3v1.4
	c0,0.3-0.1,0.5-0.1,1c-0.3,0.4-0.5,0.8-1.1,1.2l-0.5,0.3c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1l-0.5-0.3
	c-0.5-0.4-0.8-0.8-1.1-1.2c-0.1-0.3-0.3-0.5-0.3-1v-1.1L382.2,304.1L382.2,304.1z M365.8,288h-7.8c-0.8,1.2-1.8,2.5-2.9,3.7
	c-3.4,3.6-7.7,5.2-12.7,5.2s-9.2-1.8-12.7-5.2c-1.1-1.1-2.2-2.3-2.9-3.7h-5c0,0.5,0.1,1,0.1,1.5c0.7,3.7,1.1,8.1,1.5,13.5l0.1,1h8.1
	c0.3-0.4,0.7-0.8,1.1-1.2c1.6-1.6,3.6-2.5,5.9-2.5c2.3,0,4.3,0.8,5.9,2.5c0.4,0.4,0.7,0.8,1.1,1.2h20.1v-16H365.8L365.8,288z"
          />
          <path
            fill="none"
            d="M520.3,288h-5c-0.8,1.2-1.8,2.5-2.9,3.7c-3.4,3.6-7.7,5.2-12.7,5.2c-5,0-9.2-1.8-12.7-5.2
	c-1.1-1.1-2.1-2.3-2.9-3.7h-7.8v16.1h20.1c0.3-0.4,0.7-0.8,1.1-1.2c1.6-1.6,3.6-2.5,5.9-2.5c2.3,0,4.3,0.8,5.9,2.5
	c0.4,0.4,0.7,0.8,1.1,1.2h8.1l0.1-1c0.4-5.4,0.8-9.9,1.5-13.5L520.3,288L520.3,288z M464,288h-4.1v16.1h4.1v-15 M447.5,289.1V288
	h-4.3v16.1h4.3v-13.9 M430.9,288h-4.1v16.1h4.1v-15 M421.8,261.9c-0.3,0.4-0.4,0.8-0.4,1.2c0,0.3,0,0.4,0.1,0.7
	c0.3,0.5,0.8,1,1.5,1.1l1,0.1l1.5-0.1c0.4-0.1,0.8-0.3,1.1-0.5l0.3-0.4v-0.1h-1.9c-0.1,0.1-0.3,0.3-0.5,0.3H424
	c-0.4,0-0.5-0.1-0.7-0.4l-0.1-0.4h3.7v-0.5l-0.3-0.7c-0.3-0.5-0.8-0.8-1.5-1l-1.2-0.1c-0.4,0-0.8,0.1-1.1,0.3
	C422.3,261.3,421.9,261.6,421.8,261.9L421.8,261.9z M423.3,262.4v-0.1l0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.3h0.5c0.3,0.1,0.5,0.3,0.7,0.5
	v0.3h-1.9L423.3,262.4L423.3,262.4z"
          />
          <path
            fill="none"
            d="M423.3,262.3v0.1h1.9v-0.3c-0.1-0.3-0.3-0.4-0.7-0.5H424c-0.3,0-0.4,0.1-0.5,0.3L423.3,262.3L423.3,262.3z
	 M417.2,263.5v-0.3h3.7v-0.7l-0.3-0.7c-0.3-0.5-0.8-0.8-1.7-1l-1.2-0.1c-0.4,0-0.8,0.1-1.2,0.3s-0.7,0.4-0.8,0.8
	c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.4,0,0.7,0.1,1c0.3,0.5,0.8,0.8,1.5,1.1l1,0.1l1.5-0.1c0.4-0.1,0.8-0.3,1-0.5l0.3-0.4v-0.1H419
	c-0.1,0.1-0.3,0.3-0.5,0.3h-0.5c-0.3,0-0.4-0.1-0.5-0.3L417.2,263.5L417.2,263.5z M417.4,262.4l0.1-0.4c0.1-0.1,0.3-0.3,0.5-0.3h0.5
	c0.4,0.1,0.5,0.3,0.7,0.4v0.3H417.4L417.4,262.4z"
          />
          <path
            fill="none"
            d="M417.4,262.4l1.9,0.1v-0.3c-0.1-0.3-0.3-0.4-0.7-0.4h-0.5c-0.3,0-0.4,0.1-0.5,0.3L417.4,262.4L417.4,262.4z
	 M420.5,266.1c-0.1,0-0.1,0-0.3,0.1l0.8-0.1H420.5L420.5,266.1z"
          />
          <path
            fill="none"
            d="M421.1,266.1l-0.8,0.1c-1.2,0.3-2.2,0.8-3.2,1.8c-1.2,1.2-1.8,2.8-1.8,4.5v5.8h11.4v-5.8c0-1.8-0.5-3.3-1.8-4.5
	c-1-1-1.9-1.5-3.2-1.8L421.1,266.1L421.1,266.1z"
          />
          <path
            fill="none"
            d="M421.8,266.3c-0.1-0.1-0.1-0.1-0.3-0.1h-0.5L421.8,266.3L421.8,266.3z M415.4,279.5v2.3h11.3v-2.3 M415.4,278.2
	v1.2h11.3v-1.2 M415.4,285.5v1.2h11.3v-1.2 M415.4,283.1v2.5h11.3v-2.5 M415.4,289.1v1.1h11.3v-1.1 M415.4,290.2v2.2h11.3v-2.2
	 M415.4,292.4v1.2h11.3v-1.2"
          />
          <path
            fill="none"
            d="M415.4,286.8v2.3h11.3v-2.3 M415.4,297.1v2.6h11.3v-2.6"
          />
          <path
            fill="none"
            d="M415.4,296v1.1h11.3V296 M415.4,300.9v2.3h11.3v-2.3 M415.4,303.3v1.1h11.3v-1.1 M415.4,304.4v1
	c0,0.4,0.1,0.7,0.3,1h10.9c0.1-0.3,0.3-0.7,0.3-1v-1H415.4z"
          />
          <path
            fill="none"
            d="M415.4,299.7v1.2h11.3v-1.2 M415.4,293.6v2.3h11.3v-2.3 M415.4,281.8v1.2h11.3v-1.2 M424.9,307.8l0.5-0.3h-8.7
	l0.5,0.3c1,0.5,1.9,0.8,3.2,1l0.8,0.1l0.8-0.1C423,308.8,424,308.4,424.9,307.8z"
          />
          <path
            fill="none"
            d="M421.5,308.9l0.4-0.1l-0.8,0.1 M421.1,308.9l-0.8-0.1l0.3,0.1 M416.7,307.5h8.7c0.5-0.4,0.8-0.8,1.1-1.2h-10.9
	C415.9,306.9,416.1,307.1,416.7,307.5z M415,263.1v-3.7h-1.9v3.7c-0.1,0.3-0.3,0.4-0.5,0.5l-0.8,0.1c-0.4,0-0.7-0.1-0.8-0.4
	l-0.1-0.3v-0.7h-1.9v0.8l0.1,0.5c0.1,0.4,0.5,0.8,1.2,1l1,0.1h1.1l1-0.1c0.7-0.1,1.1-0.4,1.5-0.8C414.9,263.7,415,263.4,415,263.1
	L415,263.1z M411.3,281.8v-2.3h-12.4v2.3 M411.3,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4v4.7H411.3L411.3,278.2z"
          />
          <path
            fill="none"
            d="M411.3,279.5v-1.2h-12.4v1.2 M398.9,283.1v2.5h12.4v-2.5 M398.9,285.5v1.2h12.4v-1.2"
          />
          <path fill="none" d="M398.9,286.8v2.3h12.4v-2.3" />
          <path
            fill="none"
            d="M398.9,289.1v1.1h12.4v-1.1 M398.9,292.4v1.2h12.4v-1.2 M398.9,290.2v2.2h12.4v-2.2 M398.9,296v1.1h12.4V296"
          />
          <path
            fill="none"
            d="M398.9,297.1v2.6h12.4v-2.6 M398.9,299.7v1.2h12.4v-1.2"
          />
          <path
            fill="none"
            d="M398.9,300.9v2.3h12.4v-2.3 M398.9,303.3v1.1h12.4v-1.1 M398.9,304.4v1.1c0,0.3,0.1,0.5,0.3,1h12
	c0.1-0.3,0.1-0.5,0.1-1v-1.1H398.9L398.9,304.4z M398.9,293.6v2.3h12.4v-2.3 M411.3,283.1v-1.2h-12.4v1.2 M409.4,308l0.5-0.3h-9.8
	l0.5,0.3c1.2,0.7,2.6,1,4.4,1C406.8,308.9,408.3,308.5,409.4,308z"
          />
          <path
            fill="none"
            d="M409.9,307.5c0.5-0.4,0.8-0.8,1.1-1.2h-12c0.1,0.4,0.5,0.8,1.1,1.2H409.9L409.9,307.5z M392.7,269.3
	c-1.2-1.2-2.6-1.8-4.4-1.8c-1.6,0-3.2,0.5-4.4,1.8s-1.8,2.6-1.8,4.4v4.7h12.4v-4.7C394.5,272.1,394,270.5,392.7,269.3L392.7,269.3z
	 M394.5,281.8v-2.3h-12.4v2.3"
          />
          <path
            fill="none"
            d="M394.5,279.5v-1.2h-12.4v1.2 M394.5,285.5v-2.5h-12.4v2.5"
          />
          <path
            fill="none"
            d="M394.5,286.8v-1.2h-12.4v1.2 M394.5,289.1v-2.3h-12.4v2.3 M394.5,293.6v-1.2h-12.4v1.2"
          />
          <path
            fill="none"
            d="M394.5,292.4v-2.2h-12.4v2.2 M394.5,290.2v-1.1h-12.4v1.1 M394.5,297.1V296h-12.4v1.1 M394.5,299.7v-2.6h-12.4
	v2.6 M394.5,300.9v-1.2h-12.4v1.2"
          />
          <path
            fill="none"
            d="M394.5,303.3v-2.3h-12.4v2.3 M394.5,304.4v-1.1h-12.4v1.1 M394.5,305.5v-1.1h-12.4v1.1c0,0.3,0.1,0.5,0.3,1h12
	C394.5,306,394.5,305.8,394.5,305.5L394.5,305.5z M394.5,296v-2.3h-12.4v2.3 M394.5,283.1v-1.2h-12.4v1.2"
          />
          <path
            fill="none"
            d="M382.4,306.4c0.1,0.4,0.5,0.8,1.1,1.2h9.8c0.5-0.4,0.8-0.8,1.1-1.2H382.4L382.4,306.4z"
          />
          <path
            fill="none"
            d="M383.5,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.8,0,3.2-0.3,4.4-1l0.5-0.3L383.5,307.5L383.5,307.5z M453.7,267.5
	c-1.6,0-3.2,0.5-4.4,1.8s-1.8,2.6-1.8,4.4v4.7h12.4v-4.7c0-1.6-0.5-3.2-1.8-4.4C456.8,268.2,455.5,267.5,453.7,267.5L453.7,267.5z"
          />
          <path
            fill="none"
            d="M447.5,278.2v1.2h12.4v-1.2 M447.5,279.5v2.3h12.4v-2.3"
          />
          <path fill="none" d="M459.9,281.8h-12.4v1.2h12.4" />
          <path fill="none" d="M459.9,285.5v-2.5h-12.4v2.5" />
          <path
            fill="none"
            d="M459.9,286.8v-1.2h-12.4v1.2 M459.9,290.2v-1.1h-12.4v1.1 M459.9,292.4v-2.2h-12.4v2.2"
          />
          <path
            fill="none"
            d="M459.9,293.6v-1.2h-12.4v1.2 M459.9,289.1v-2.3h-12.4v2.3 M447.5,304.4v1.1c0,0.3,0.1,0.5,0.3,1h12
	c0.1-0.3,0.1-0.5,0.1-1v-1.1H447.5L447.5,304.4z M447.5,303.3v1.1h12.4v-1.1 M447.5,300.9v2.3h12.4v-2.3"
          />
          <path
            fill="none"
            d="M447.5,299.7v1.2h12.4v-1.2 M447.5,297.1v2.6h12.4v-2.6"
          />
          <path
            fill="none"
            d="M447.5,296v1.1h12.4V296 M459.9,296v-2.3h-12.4v2.3 M458.6,307.5c0.5-0.4,0.8-0.8,1.1-1.2h-12
	c0.1,0.4,0.6,0.8,1.1,1.2H458.6L458.6,307.5z M458.1,308l0.5-0.3h-9.8l0.5,0.3c1.2,0.7,2.6,1,4.4,1
	C455.5,308.9,456.8,308.5,458.1,308L458.1,308z M465.2,276.3V308h0.1l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.7,0,3.2-0.3,4.4-1
	s1.8-1.5,1.8-2.5v-25.3l-3.2-4.1L465.2,276.3L465.2,276.3L465.2,276.3z"
          />
          <path
            fill="none"
            d="M465.2,278.2v-1.9h8l3.2,4.1v-6.7c0-1.7-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.6,0-3.2,0.5-4.4,1.8
	s-1.8,2.6-1.8,4.4v4.7h1.2L465.2,278.2L465.2,278.2z"
          />
          <path
            fill="none"
            d="M465.2,279.5v-1.2H464v1.2 M464,279.5v2.3h1.2v-2.3"
          />
          <path
            fill="none"
            d="M465.2,281.8H464v1.2h1.2 M464,286.8h1.2v-1.2H464 M465.2,286.8H464v2.3h1.2"
          />
          <path
            fill="none"
            d="M464,285.5h1.2v-2.5H464 M465.2,290.2v-1.1H464v1.1 M465.2,290.2H464v2.2h1.2 M465.2,293.6v-1.2H464v1.2"
          />
          <path
            fill="none"
            d="M465.2,293.6H464v2.3h1.2 M464,299.7h1.2v-2.6H464 M465.2,299.7H464v1.2h1.2 M464,297.1h1.2V296H464
	 M465.2,304.4v-1.1H464v1.1"
          />
          <path
            fill="none"
            d="M465.2,303.3v-2.3H464v2.3 M465.2,304.4H464v1.1c0,0.3,0.1,0.5,0.3,1h1V304.4z M464.3,306.4
	c0.1,0.4,0.5,0.8,1,1.1v-1.1H464.3z M441.4,269.3c-1.2-1.2-2.6-1.8-4.4-1.8c-1.6,0-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4v4.7
	h12.4v-4.7C443.2,272.1,442.7,270.5,441.4,269.3L441.4,269.3z"
          />
          <path
            fill="none"
            d="M430.9,278.2v1.2h12.4v-1.2 M430.9,279.5v2.3h12.4v-2.3"
          />
          <path
            fill="none"
            d="M443.2,281.8h-12.4v1.2h12.4 M443.2,292.4v-2.2h-12.4v2.2"
          />
          <path
            fill="none"
            d="M443.2,293.6v-1.2h-12.4v1.2 M443.2,290.2v-1.1h-12.4v1.1 M443.2,289.1v-2.3h-12.4v2.3 M443.2,286.8v-1.2h-12.4
	v1.2"
          />
          <path
            fill="none"
            d="M443.2,285.5v-2.5h-12.4v2.5 M430.9,297.1v2.6h12.4v-2.6"
          />
          <path
            fill="none"
            d="M430.9,296v1.1h12.4V296 M430.9,300.9v2.3h12.4v-2.3 M430.9,304.4v1.1c0,0.3,0.1,0.5,0.3,1h12
	c0.1-0.3,0.1-0.5,0.1-1v-1.1H430.9L430.9,304.4z M430.9,303.3v1.1h12.4v-1.1"
          />
          <path
            fill="none"
            d="M430.9,299.7v1.2h12.4v-1.2 M443.2,293.6h-12.4v2.3h12.4 M442,307.5c0.5-0.4,0.8-0.8,1.1-1.2h-12
	c0.1,0.4,0.6,0.8,1.1,1.2H442z M441.4,308l0.6-0.3h-9.8l0.5,0.3c1.2,0.7,2.6,1,4.4,1C438.7,308.9,440.2,308.5,441.4,308L441.4,308z
	 M433,262.4l-0.3-0.7c-0.3-0.3-0.5-0.5-1.1-0.7l-1.5-0.1c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1h-0.3V261h-1.8v5.1h1.9v-1.4
	c0,0,0-0.1,0.1,0l0.5,0.3l1.2,0.1c0.5,0,1-0.1,1.4-0.5c0.3-0.1,0.4-0.4,0.5-0.7C433.2,263.4,433.2,263,433,262.4L433,262.4z
	 M431.3,262.7v0.8l-0.1,0.3c-0.1,0.3-0.4,0.3-0.8,0.3h-0.4c-0.3,0-0.4-0.1-0.5-0.3l-0.1-0.4v-0.3l0.1-0.5l0.1-0.3
	c0.1-0.3,0.5-0.3,1-0.3h0.3c0.3,0,0.4,0.1,0.5,0.3L431.3,262.7L431.3,262.7z"
          />
          <path
            fill="none"
            d="M431.1,263.5c0.1-0.3,0.1-0.5,0-0.8l-0.1-0.3c-0.1-0.1-0.3-0.3-0.5-0.3h-0.3c-0.4,0-0.7,0.1-1,0.3v0.1l-0.1,0.5
	v0.3l0.1,0.4c0.1,0.1,0.3,0.3,0.5,0.3h0.4c0.3,0,0.5-0.1,0.8-0.3L431.1,263.5L431.1,263.5z M499.8,261c-5,0-9.2,1.8-12.7,5.2
	c-3.4,3.4-5.2,7.7-5.2,12.7c0,5,1.8,9.2,5.2,12.7c3.4,3.6,7.7,5.2,12.7,5.2c5,0,9.2-1.8,12.7-5.2c3.4-3.4,5.2-7.7,5.2-12.7
	s-1.8-9.2-5.2-12.7C509,262.8,504.7,261.1,499.8,261L499.8,261z M487.9,267.2c3.3-3.3,7.1-4.8,11.8-4.8s8.5,1.6,11.8,4.8
	c3.3,3.3,5,7.2,5,11.8c0,4.7-1.7,8.5-5,11.8c-3.3,3.3-7.2,5-11.8,5s-8.5-1.6-11.8-5c-3.3-3.3-4.8-7.2-4.8-11.8
	C483.1,274.4,484.8,270.4,487.9,267.2L487.9,267.2z"
          />
          <path
            fill="none"
            d="M499.8,262.3c-4.7,0-8.5,1.6-11.8,4.8c-3.3,3.3-4.8,7.2-4.8,11.8c0,4.7,1.6,8.5,4.8,11.8c3.3,3.3,7.1,5,11.8,5
	s8.5-1.6,11.8-5c3.3-3.3,5-7.2,5-11.8s-1.7-8.5-5-11.8C508.4,263.9,504.4,262.3,499.8,262.3L499.8,262.3z M489.2,268.3
	c2.9-2.9,6.5-4.4,10.6-4.4s7.7,1.5,10.6,4.4c2.9,2.9,4.4,6.5,4.4,10.6s-1.5,7.7-4.4,10.6s-6.5,4.4-10.6,4.4s-7.7-1.5-10.6-4.4
	c-2.9-2.9-4.4-6.5-4.4-10.6S486.3,271.4,489.2,268.3L489.2,268.3z"
          />
          <path
            fill="none"
            d="M499.8,263.9c-4.1,0-7.7,1.5-10.6,4.4c-2.9,2.9-4.4,6.5-4.4,10.6s1.5,7.7,4.4,10.6c2.9,2.9,6.5,4.4,10.6,4.4
	s7.7-1.5,10.6-4.4c2.9-2.9,4.4-6.5,4.4-10.6s-1.5-7.7-4.4-10.6C507.5,265.4,504,263.9,499.8,263.9L499.8,263.9z M495.8,278.9
	c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9
	c-0.8,0.8-1.8,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.9-1.2C496.2,281,495.8,280,495.8,278.9L495.8,278.9z"
          />
          <path
            fill="none"
            d="M496.9,276c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2
	s1.2-1.8,1.2-2.9s-0.4-2.1-1.2-2.9c-0.8-0.8-1.8-1.2-2.9-1.2C498.7,274.9,497.7,275.4,496.9,276z M503.3,300.4
	c-2.3,0-4.3,0.8-5.9,2.5c-1.6,1.7-2.5,3.6-2.5,5.9s0.8,4.3,2.5,5.9c1.6,1.6,3.6,2.5,5.9,2.5s4.3-0.8,5.9-2.5
	c1.6-1.7,2.5-3.6,2.5-5.9s-0.8-4.3-2.5-5.9C507.8,301.2,505.7,300.4,503.3,300.4L503.3,300.4z M498.1,303.4c1.5-1.5,3.3-2.2,5.4-2.2
	c2.1,0,3.9,0.7,5.4,2.2c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4c-1.5,1.5-3.3,2.2-5.4,2.2c-2.1,0-3.9-0.7-5.4-2.2
	c-1.5-1.5-2.2-3.3-2.2-5.4C495.9,306.7,496.6,304.9,498.1,303.4L498.1,303.4z"
          />
          <path
            fill="none"
            d="M503.3,301.2c-2.1,0-3.9,0.7-5.4,2.2s-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4s3.3,2.2,5.4,2.2
	c2.1,0,3.9-0.7,5.4-2.2s2.2-3.3,2.2-5.4c0-2.1-0.7-3.9-2.2-5.4C507.3,302,505.5,301.2,503.3,301.2L503.3,301.2z M501.8,310.3
	c-0.4-0.4-0.7-1-0.7-1.6c0-0.5,0.3-1.1,0.7-1.7c0.4-0.4,1-0.7,1.7-0.7c0.5,0,1.1,0.3,1.6,0.7c0.4,0.4,0.7,1,0.7,1.7
	c0,0.7-0.3,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7C502.8,311,502.2,310.8,501.8,310.3z"
          />
          <path
            fill="none"
            d="M501.1,308.8c0,0.7,0.3,1.2,0.7,1.6c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6
	c0-0.5-0.3-1.1-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7c-0.5,0-1.1,0.3-1.7,0.7C501.4,307.5,501.1,308.1,501.1,308.8L501.1,308.8z
	 M342.3,261c-5,0-9.2,1.8-12.7,5.2c-3.4,3.4-5.2,7.7-5.2,12.7c0,5,1.8,9.2,5.2,12.7c3.4,3.6,7.7,5.2,12.7,5.2c5,0,9.2-1.8,12.7-5.2
	c3.4-3.4,5.2-7.7,5.2-12.7s-1.8-9.2-5.2-12.7C351.5,262.8,347.2,261.1,342.3,261L342.3,261z M330.4,267.2c3.3-3.3,7.2-4.8,11.8-4.8
	s8.5,1.6,11.8,4.8c3.3,3.3,4.8,7.2,4.8,11.8c0,4.7-1.6,8.5-4.8,11.8c-3.3,3.3-7.2,5-11.8,5c-4.5,0-8.5-1.6-11.8-5
	c-3.3-3.3-5-7.2-5-11.8C325.6,274.4,327.3,270.4,330.4,267.2L330.4,267.2z"
          />
          <path
            fill="none"
            d="M342.3,262.3c-4.5,0-8.5,1.6-11.8,4.8c-3.3,3.3-5,7.2-5,11.8c0,4.7,1.6,8.5,5,11.8c3.3,3.3,7.2,5,11.8,5
	s8.5-1.6,11.8-5c3.3-3.3,4.8-7.2,4.8-11.8s-1.6-8.5-4.8-11.8C350.9,263.9,346.9,262.3,342.3,262.3L342.3,262.3z M331.7,268.3
	c2.9-2.9,6.5-4.4,10.6-4.4s7.7,1.5,10.6,4.4c2.9,2.9,4.4,6.5,4.4,10.6s-1.5,7.7-4.4,10.6c-2.9,2.9-6.5,4.4-10.6,4.4
	s-7.7-1.5-10.6-4.4c-2.9-2.9-4.4-6.5-4.4-10.6S328.8,271.4,331.7,268.3L331.7,268.3z"
          />
          <path
            fill="none"
            d="M342.3,263.9c-4.1,0-7.7,1.5-10.6,4.4c-2.9,2.9-4.4,6.5-4.4,10.6s1.5,7.7,4.4,10.6s6.5,4.4,10.6,4.4
	s7.7-1.5,10.6-4.4c2.9-2.9,4.4-6.5,4.4-10.6s-1.5-7.7-4.4-10.6C350,265.4,346.4,263.9,342.3,263.9L342.3,263.9z M342.3,274.9
	c1.1,0,2.1,0.4,2.9,1.2c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
	c-0.8-0.8-1.2-1.8-1.2-2.9s0.4-2.1,1.2-2.9C340.2,275.4,341.2,274.9,342.3,274.9L342.3,274.9z"
          />
          <path
            fill="none"
            d="M345.1,276c-0.8-0.8-1.8-1.2-2.9-1.2s-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1,1.2,2.9
	c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9S346,276.9,345.1,276L345.1,276z M344.6,302.9
	c-1.6-1.6-3.6-2.5-5.9-2.5c-2.3,0-4.3,0.8-5.9,2.5c-1.6,1.7-2.5,3.6-2.5,5.9s0.8,4.3,2.5,5.9c1.6,1.6,3.6,2.5,5.9,2.5
	c2.3,0,4.3-0.8,5.9-2.5c1.6-1.7,2.5-3.6,2.5-5.9S346.2,304.5,344.6,302.9L344.6,302.9z M338.7,301.2c2.1,0,3.9,0.7,5.4,2.2
	s2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4s-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2s-2.2-3.3-2.2-5.4c0-2.1,0.7-3.9,2.2-5.4
	C334.8,302,336.6,301.2,338.7,301.2z"
          />
          <path
            fill="none"
            d="M344,303.4c-1.5-1.5-3.3-2.2-5.4-2.2s-3.9,0.7-5.4,2.2s-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4s3.3,2.2,5.4,2.2
	s3.9-0.7,5.4-2.2s2.2-3.3,2.2-5.4C346.2,306.7,345.6,304.9,344,303.4z M341,308.8c0,0.7-0.3,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7
	c-0.5,0-1.1-0.3-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.5,0.3-1.1,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7
	C340.7,307.5,341,308.1,341,308.8L341,308.8z"
          />
          <path
            fill="none"
            d="M340.3,310.3c0.4-0.4,0.7-1,0.7-1.6c0-0.5-0.3-1.1-0.7-1.7c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.1,0.3-1.6,0.7
	c-0.4,0.4-0.7,1-0.7,1.7c0,0.7,0.3,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7C339.4,311,339.9,310.8,340.3,310.3L340.3,310.3z M372,267.5
	c-1.6,0-3.2,0.5-4.4,1.8s-1.8,2.6-1.8,4.4v6.7l3.2-4.1h8v1.9h1.2v-4.7c0-1.6-0.5-3.2-1.8-4.4C375.1,268.2,373.6,267.5,372,267.5
	L372,267.5z"
          />
          <path
            fill="none"
            d="M376.9,279.5v-3.2h-8l-3.2,4.1v25c0,1,0.5,1.8,1.8,2.5c1.2,0.7,2.6,1,4.4,1c1.6,0,3.2-0.3,4.4-1l0.7-0.4v-28.1
	L376.9,279.5L376.9,279.5z"
          />
          <path
            fill="none"
            d="M376.9,278.2v1.2h1.2v-1.2 M376.9,279.5v2.3h1.2v-2.3"
          />
          <path fill="none" d="M378.2,281.8h-1.2v1.2h1.2" />
          <path
            fill="none"
            d="M378.2,286.8v-1.2h-0.8h0.8v-2.5h-1.2v9.4h1.2v-2.2h-0.8h0.8v-1.1h-0.8h0.8V286.8l-0.8,0 M378.2,293.6v-1.2
	h-1.2v1.2"
          />
          <path
            fill="none"
            d="M378.2,293.6h-1.2v2.3h1.2 M376.9,299.7h1.2v-2.6h-1.2 M378.2,299.7h-1.2v1.2h1.2"
          />
          <path
            fill="none"
            d="M378.2,296h-1.2v1.1h1.2 M378.2,303.3v-2.3h-1.2v2.3 M378.2,304.4v-1.1h-1.2v1.1 M378.2,305.5v-1.1h-1.2v1.9h1
	C378,306,378.2,305.8,378.2,305.5z M377.9,306.4h-1v1.1C377.3,307.1,377.7,306.9,377.9,306.4z M426.6,255.8h0.3h-2.3 M389.2,230
	c-0.1,0.1-0.1,0.3-0.1,0.3v0.1c0,0.1,0,0.3,0.1,0.3l0.3,0.1h0.1v-1.1h-0.1C389.4,229.8,389.3,229.8,389.2,230L389.2,230z
	 M418.1,238.6h6.1c14.6-0.1,30.1,0.3,46.8,1c1.8-0.6,3.6-0.6,5.4,0.1c15.5,0.4,30.3,1.4,44.3,2.9c8.9,1.1,14.9,4.1,17.6,9.1
	c1.5,2.8,2.8,6.2,4,10.5c1.8-0.4,3.7-0.8,5.6-1.2c-2.8-12.1-8-18.8-15.7-20.4c-2.8-0.6-6.3-1.1-10.7-1.6l-13.5-1.2
	c0.1,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.4,0.3-0.7,0.3l-9.5-0.6l-9.5-0.6c-0.3,0-0.5-0.1-0.7-0.4
	c-0.1-0.3-0.3-0.6-0.3-0.8s0.1-0.6,0.3-0.8l-2.1-0.1c-21.5-0.8-42.5-1.2-63-1.2h-2.8c-20.5-0.1-41.5,0.4-63.4,1.5l-2.1,0.1
	c0.1,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.3-0.4,0.3-0.7,0.4l-9.5,0.6l-9.5,0.6c-0.3,0-0.5-0.1-0.7-0.3
	c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8l-13.5,1.2c-4.4,0.6-8,1-10.7,1.6c-7.7,1.5-12.9,8.3-15.7,20.4
	c1.9,0.4,3.9,0.8,5.6,1.2c1.2-4.3,2.5-7.7,4-10.5c2.8-5.1,8.5-8.1,17.6-9.1c14-1.5,28.8-2.5,44.3-2.9c1.8-0.7,3.6-0.7,5.4-0.1
	C387.8,238.9,403.5,238.6,418.1,238.6L418.1,238.6z"
          />
          <path
            fill="none"
            d="M424.1,238.6h-6.1c-14.6-0.1-30.1,0.3-46.8,1l1,4.4c-2.1,0.6-4.5,0.7-7.6,0.4l1.2-4.7
	c-15.5,0.4-30.3,1.4-44.3,2.9c-8.9,1.1-14.9,4.1-17.6,9.1c-1.5,2.8-2.8,6.2-4,10.5c5.5,1.4,10.7,3,15.5,5.1l0.1-1l-0.3-0.1l-1.4-0.3
	l3.2-3.6l1.6-0.3l0.3-0.3l-0.1-0.1l-1-0.3c1.1-1.1,1.6-2.1,1.6-2.9l3-2.1l1.4-0.1l1.2,0.3l-1.8,2.3l1.4,0.8l-0.4,0.5
	c0.1,0.1,0.3,0.3,0.3,0.5l-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3l-0.3-0.1l-0.8,1.1l-3.3,4.4h-1.6l-0.4-0.1l-0.1,1.1l2.8,1.2
	c4-8.9,13.1-13.6,27.5-14.2c22-1.2,45.7-1.9,70.8-1.8h5.5c25.2-0.1,48.8,0.6,70.8,1.8c14.3,0.4,23.5,5.2,27.5,14.2l2.8-1.2l-0.1-1.1
	l-0.4,0.1h-1.7l-3.3-4.4l-0.8-1.1l-0.3,0.1c-0.1,0-0.4-0.1-0.5-0.3s-0.3-0.3-0.3-0.5c0-0.1,0.1-0.4,0.3-0.5l-0.7-0.4l1.4-0.8
	l-1.8-2.3l1.2-0.3l1.4,0.1l3,2.1c0,0.8,0.5,1.8,1.7,2.9l-1,0.3l-0.1,0.1l0.3,0.3l1.7,0.3l3.2,3.6l-1.4,0.3l-0.3,0.1l0.1,1
	c5-2.1,10.2-3.9,15.5-5.1c-1.2-4.3-2.5-7.7-4-10.5c-2.8-5.1-8.5-8.1-17.6-9.1c-14-1.5-28.8-2.5-44.3-2.9l1.2,4.7
	c-3,0.3-5.5,0.1-7.6-0.4l1-4.4C454.2,238.9,438.7,238.6,424.1,238.6L424.1,238.6z M429.2,243.9c-0.1-0.1-0.1-0.3-0.1-0.4
	c0.1-0.8,0-1.5-0.1-1.9c-0.1-0.3-0.3-0.4-0.5-0.3h-4.5l-0.1,0.3c-0.1,0.1-0.4,0.3-0.7,0.3h-4c-0.3,0-0.5-0.1-0.7-0.3l-0.1-0.3h-4.5
	c-0.3,0-0.5,0.1-0.5,0.3c-0.1,0.4-0.1,1-0.1,1.9l-0.1,0.4l-0.3,0.1l-0.4-0.1l-0.1-0.3c-0.1-1.1,0-1.9,0.3-2.3c0.3-0.7,0.8-1,1.5-1
	h4.4l0.1-0.1c0.1-0.1,0.4-0.3,0.7-0.3h4c0.3,0,0.5,0.1,0.7,0.3l0.1,0.1h4.4c0.7,0,1.2,0.3,1.5,1c0.3,0.6,0.3,1.2,0.3,2.3l-0.1,0.3
	l-0.4,0.1L429.2,243.9L429.2,243.9z"
          />
          <path
            fill="none"
            d="M429.1,243.4c0,0.1,0,0.3,0.1,0.4l0.3,0.1l0.4-0.1l0.1-0.3c0.1-1.1,0-1.9-0.3-2.3c-0.3-0.7-0.8-1-1.5-1h-4.4
	l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.3h-4c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1h-4.4c-0.7,0-1.2,0.3-1.5,1c-0.3,0.6-0.3,1.2-0.3,2.3
	l0.1,0.3l0.4,0.1l0.3-0.1l0.1-0.4c-0.1-0.8,0-1.5,0.1-1.9c0.1-0.3,0.3-0.4,0.5-0.3h4.5l0.1,0.3c0.1,0.1,0.4,0.3,0.7,0.3h4
	c0.3,0,0.5-0.1,0.7-0.3l0.1-0.3h4.5c0.3,0,0.5,0.1,0.5,0.3C429.2,242.1,429.2,242.6,429.1,243.4L429.1,243.4z M415.2,255.8h2.5
	 M423.8,253.3h-5.5c-25.2-0.1-48.8,0.6-70.8,1.8c-14.3,0.4-23.5,5.2-27.5,14.2l2.3,1c3.7-8,12.1-12.2,25.3-12.7
	c21.2-1.2,43.7-1.8,67.7-1.8H427c23.9,0,46.5,0.6,67.7,1.8c13.1,0.4,21.6,4.7,25.3,12.7l2.3-1c-4-8.9-13.1-13.6-27.5-14.2
	C472.5,253.9,449,253.2,423.8,253.3L423.8,253.3z M543.5,219.9c-2.5-1.6-5.1-2.6-7.6-2.5l1.1,7.6c0.3,1.4-0.6,2.1-2.6,2.1l-13.3-0.3
	v4.5l0.3,7.8c4.4,0.6,8,1,10.7,1.6c7.7,1.5,12.9,8.3,15.7,20.4l1-0.1v-0.4l-1.9-14.6l-0.3-2.6L543.5,219.9L543.5,219.9z M528,230.1
	c0.3,0,0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.6-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8C527.4,230.2,527.7,230.1,528,230.1L528,230.1z M536.9,237.4
	c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3
	c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8C537.6,237.3,537.3,237.4,536.9,237.4L536.9,237.4z"
          />
          <path
            fill="none"
            d="M528.8,230.4c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8C529.2,230.9,529.1,230.6,528.8,230.4L528.8,230.4z
	 M536.1,237c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8s-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3
	s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8C535.8,236.6,536,236.8,536.1,237L536.1,237z M508,237.8c-0.1-0.1-0.4-0.3-0.5-0.3
	L498,237l-0.1,2.2l9.5,0.6c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.8C508.3,238.4,508.2,238.1,508,237.8L508,237.8z
	 M517.9,256.2l-1.2,0.3l1.8,2.3l-1.4,0.8l0.4,0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.1,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5
	c0,0.1-0.1,0.3-0.1,0.5l-0.1,0.1l0.8,1.1l1.4-0.1l2.1-0.7l0.1-0.1c-1.1-1.2-1.7-2.3-1.7-3.2L517.9,256.2L517.9,256.2z"
          />
          <path
            fill="none"
            d="M519.3,256.4l-1.4-0.1l3.4,2.3c0,0.8,0.5,1.9,1.7,3.2l1-0.3c-1.1-1.1-1.7-2.1-1.7-2.9L519.3,256.4L519.3,256.4z
	 M476.2,239.7c-1.8-0.7-3.6-0.7-5.4-0.1l-1,4.4c2.1,0.6,4.5,0.7,7.6,0.4L476.2,239.7L476.2,239.7z M497.8,239.2l0.1-2.2l-9.5-0.6
	c-0.3,0-0.4,0.1-0.7,0.3c-0.1,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.1,0.3,0.4,0.3,0.7,0.4L497.8,239.2L497.8,239.2z
	 M526.6,266.3l1.4-0.3l-3.2-3.7l-1.7-0.3 M526.3,266.4l0.3-0.1l-3.4-4.3l-0.3-0.3l-2.1,0.7l-1.5,0.1l3.3,4.4h2.1 M526.6,267.2
	l-0.3-0.8l-1.7,0.5l0.1,1l0.8,5.2l2.1-0.7 M527.8,275.6l1.4-0.7v-2.5h-1.5"
          />
          <path
            fill="none"
            d="M529.2,274.9l-1.4,0.7c-1.4,0.8-2.5,1.6-3.3,2.6c-0.7,0.7-1.1,1.2-1.5,1.9c-0.3,0.4-0.5,1-0.7,1.4l-0.3,0.8
	c-0.7,1.8-1.2,4.1-1.8,7c-0.7,3.7-1.1,8.1-1.5,13.5l-0.1,2.3h0.1l56,2.9h4.3v-18.7l-3.3-6.7c-1.1-2.3-3-3.7-5.6-4l-16.4-2.6
	l-1.4-0.3l-0.5-0.1l-2.3-0.4l-0.7-0.1l-9.9-1.7C535.5,273.2,532.2,273.6,529.2,274.9L529.2,274.9z M575.8,292.5
	c0.6,0,1.2,0.3,1.7,1.1c0.6,0.8,1,1.9,1.1,3.2c0.1,1.2,0.1,2.3-0.1,3.3s-0.7,1.5-1.2,1.5c-0.4,0.1-1-0.3-1.4-1.2
	c-0.4-0.8-0.8-1.9-1-3.2c-0.1-1.2-0.1-2.3,0-3.3C575,293.1,575.4,292.6,575.8,292.5L575.8,292.5z"
          />
          <path
            fill="none"
            d="M577.5,293.6c-0.5-0.8-1.1-1.1-1.7-1.1c-0.4,0.1-0.8,0.5-1,1.5s-0.1,2.1,0,3.3c0.1,1.2,0.5,2.3,1,3.2
	s0.8,1.2,1.4,1.2c0.5-0.1,1-0.7,1.2-1.5c0.3-1,0.4-2.1,0.1-3.3C578.5,295.6,578.2,294.5,577.5,293.6L577.5,293.6z M527.8,275.6
	l-0.1-3.2l-2.1,0.7l-1.2,0.4v1.4h0.1c0.1,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.3-0.1,0.5l-0.1,0.1l-0.3,0.1h-0.1
	l0.1,1.9C525.4,277.4,526.5,276.5,527.8,275.6L527.8,275.6z"
          />
          <path
            fill="none"
            d="M529.2,272.5v2.5c3-1.4,6.3-1.8,9.6-1.4l9.9,1.6l0.1-12.1l-0.1-2.3l-1,0.1c-1.9,0.4-3.9,0.8-5.6,1.2
	c-5.5,1.4-10.7,3-15.5,5.1l1.1,5.2L529.2,272.5L529.2,272.5z M518.8,260.4c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.4,0.1-0.6,0.3
	c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.6,0.3l0.3-0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5
	C518.9,260.6,518.9,260.5,518.8,260.4L518.8,260.4z M524.8,267.9l-2.8,1.2l0.3,0.7v0.3c0.3,3.2,0.4,6.5,0.4,9.9
	c0.4-0.7,1-1.4,1.5-1.9l-0.1-1.9l-0.4-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.3-0.1,0.4-0.1v-1.4l1.2-0.4
	L524.8,267.9L524.8,267.9z"
          />
          <path
            fill="none"
            d="M524.4,274.9c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5l0.4,0.3h0.1l0.3-0.1
	l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.5-0.3L524.4,274.9L524.4,274.9z M522.5,270.1V270
	l-0.3-0.7l-2.3,1l0.3,0.5c0.5,5.8,0.5,12,0.1,18.7c0.5-2.9,1.1-5.2,1.8-7l0.3-0.8c0.1-0.5,0.4-1,0.7-1.4c0-3.4-0.1-6.7-0.4-9.9
	L522.5,270.1L522.5,270.1z M518.5,304.2l-0.1,1.2c-1.8,6.7-4.5,11-8.1,12.7c-2.3,1.4-8.9,2.1-19.9,1.9H351.8
	c-11,0.1-17.6-0.5-19.9-1.9c-3.6-1.8-6.3-5.9-8.1-12.7l-0.1-1.2l-0.1-1.1l0.1,2.1h-0.1l-0.5,0.4l1,2.8v0.3c1.6,5.2,4.1,8.7,7.3,10.3
	c2.6,1.2,7.3,2.1,14.3,2.2h151.2c7-0.3,11.7-1,14.3-2.2c3.2-1.6,5.6-5,7.3-10.3v-0.3l1-2.8l-0.5-0.4l0.1-2.1L518.5,304.2
	L518.5,304.2z"
          />
          <path
            fill="none"
            d="M518.8,305.2l0.5,0.4l1.8,1.4l-0.1,1.8l52.7,2.5l5.2-0.1v-3h-4.3 M592.9,217.3c-0.5-1.8-1.8-2.6-4-2.6
	c-8.8-0.7-18.4-0.8-28.8-0.4c-2.9,0.3-4.1,2.1-4.1,5.2v19c-0.1,2.9,1.8,4.5,5.6,4.8h1.7c-0.1-2.6,1.2-4.3,4.3-4.8
	c1.7-0.4,2.8,0.4,3,2.2v2.6h16.9c3.9,0.1,5.8-1.6,5.6-5.4v-11.7C593.6,222.5,593.3,219.5,592.9,217.3L592.9,217.3z M587.8,219.9
	c1.7,0,2.5,0.8,2.5,2.5v11.8c0,1.7-0.8,2.5-2.5,2.5h-19.1c-1.7,0-2.5-0.8-2.5-2.5v-11.8c0-1.6,0.8-2.5,2.5-2.5H587.8L587.8,219.9z"
          />
          <path
            fill="none"
            d="M590.3,222.3c0-1.6-0.8-2.5-2.5-2.5h-19.1c-1.7,0-2.5,0.8-2.5,2.5v11.8c0,1.6,0.8,2.5,2.5,2.5h19.1
	c1.7,0,2.5-0.8,2.5-2.5L590.3,222.3L590.3,222.3z M545.9,230l0.4,2.8l0.5-0.8l-0.1-1.8 M545.9,224.5l-0.3-1.5l-0.7-0.6l0.4,2.9
	 M547.6,243.4l-1.1-0.3l0.3,2.6l1-0.7 M551.9,249.8h-1c1.1,2.9,1.1,6.1,0.3,9.5l0.7-0.1l4-0.5l4-0.5v-8.3L551.9,249.8L551.9,249.8z"
          />
          <path
            fill="none"
            d="M550.9,249.8h1c-0.3-1.2-0.7-2.1-1.2-2.8c-0.3-0.4-0.5-0.7-0.8-0.8c-0.3-0.3-0.7-0.4-1-0.6h-0.8l0.8,14.6v0.4
	l0.1,2.3h0.8l1.2-1.2c0.8-1.1,1.2-1.9,1-2.6l-0.7,0.1C552,255.8,552,252.7,550.9,249.8L550.9,249.8z M547.9,245.6l-0.1-0.6l-1,0.7
	l1.9,14.4 M563.5,243.3v5.6h7.3v-8.3c-0.3-1.9-1.4-2.6-3-2.2C564.7,239,563.3,240.6,563.5,243.3L563.5,243.3z M571.3,256.4v-6.6H560
	v8.3"
          />
          <path
            fill="none"
            d="M568.8,248.9h-3.4v0.8h3.4 M555.9,265v-6.5l-4,0.5c0.1,0.7-0.1,1.6-1,2.6v2.6h1.1v0.7L555.9,265L555.9,265
	L555.9,265z"
          />
          <path
            fill="none"
            d="M549.7,263.1h-0.8l-0.1,12.1l0.7,0.1v-8.3c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.4
	c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7v8.5l0.5,0.1V270l0.7,0.1V265h-1.1v-0.7h-1.1v-2.6L549.7,263.1L549.7,263.1z"
          />
          <path
            fill="none"
            d="M549.7,266.4c-0.1,0.1-0.3,0.4-0.3,0.7v5.4h2.3v-5.4c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.4
	C550.1,266.1,550,266.3,549.7,266.4z M553.7,271.5c0-0.8-0.3-1.2-0.5-1.4l-0.7-0.1v5.8l1.4,0.3v-4.5H553.7L553.7,271.5z"
          />
          <path
            fill="none"
            d="M551.8,275.8v-3.3h-2.3v2.9 M364.4,155.5c-0.4,0.8-1.1,1.4-2.1,1.6l10.6-0.3c-1.1-0.1-1.9-0.6-2.3-1.5
	L364.4,155.5L364.4,155.5z M334.7,156.6l-7.2,0.4c-0.3,0.8-0.8,1.4-1.8,1.6l10.9-0.6C335.8,157.9,335.1,157.3,334.7,156.6
	L334.7,156.6z M292.3,148.2c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1s-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1
	c0,0.1,0.1,0.3,0.1,0.4v38.7h0.7v-38.7h0.1l0.1-0.4L292.3,148.2L292.3,148.2z M305.5,169l-2.6-2.3l-0.3,1.2l-10.7,75v3.4l0.3-0.3
	l9.9-70.3l1,0.8l-5.9,45.8l-0.4,2.8l-0.5,4.7l-0.3,2.8l-1.4,10.9l1.1-0.3l2.9-23.2l6.2-48.4C305,170.5,305.3,169.7,305.5,169
	L305.5,169z"
          />
          <path
            fill="none"
            d="M303.2,176.7l-1-0.8l-9.9,70.3c0.3-0.3,0.7-0.4,1-0.6h0.8l0.1-0.6l0.1-1.5l1.4-10.9l-0.5-0.8l0.1-1.6l0.8-0.1
	l0.5-4.7l-0.7-0.8l0.3-1.5l0.8-0.4L303.2,176.7L303.2,176.7z M292,188.4h-0.7v69.3h0.7v-14.9 M306.1,217.3c-2.5,0-5,0.8-7.6,2.5
	l-3,23.2l-0.3,2.6l-1.9,14.6v0.4l1,0.1c2.8-12.1,8-18.8,15.7-20.4c2.8-0.6,6.3-1.1,10.7-1.6l0.3-7.8v-4.5l-13.3,0.3
	c-1.9,0-2.8-0.7-2.6-2.1L306.1,217.3L306.1,217.3z M304.3,237c-0.3-0.3-0.3-0.6-0.3-0.8s0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3
	s0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3C304.8,237.4,304.6,237.2,304.3,237
	L304.3,237z M314.9,230.4c0.3,0.3,0.3,0.6,0.3,0.8s-0.1,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.6-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3C314.5,230.1,314.7,230.2,314.9,230.4z"
          />
          <path
            fill="none"
            d="M304,236.1c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8
	c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3C304.2,235.6,304,235.9,304,236.1L304,236.1z M315.3,231.2
	c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3C315.2,231.9,315.3,231.6,315.3,231.2L315.3,231.2z M296.3,230l-0.8,0.1l-0.1,1.8
	l0.5,0.8 M296.3,224.5l0.5,0.8l0.4-2.9l-0.8,0.6 M286,219.4c0.1-3.2-1.2-5-4.1-5.2c-10.3-0.3-19.9-0.1-28.8,0.4
	c-2.2,0-3.4,0.8-4,2.6c-0.4,2.2-0.6,5.2-0.6,8.9v11.7c-0.1,3.6,1.8,5.4,5.6,5.4h16.9v-2.6c0.3-1.9,1.4-2.6,3-2.2
	c3,0.6,4.5,2.2,4.3,4.8h1.6c3.9-0.3,5.8-1.9,5.6-4.8v-19L286,219.4L286,219.4z M273.5,219.9c1.6,0,2.5,0.8,2.5,2.5v11.8
	c0,1.7-0.8,2.5-2.5,2.5h-19.1c-1.7,0-2.5-0.8-2.5-2.5v-11.8c0-1.6,0.8-2.5,2.5-2.5L273.5,219.9L273.5,219.9z"
          />
          <path
            fill="none"
            d="M276,222.3c0-1.6-0.8-2.5-2.5-2.5h-19.1c-1.7,0-2.5,0.8-2.5,2.5v11.8c0,1.6,0.8,2.5,2.5,2.5h19.1
	c1.7,0,2.5-0.8,2.5-2.5L276,222.3L276,222.3z M271.3,240.7v8.3h7.3v-5.6c0.1-2.6-1.2-4.3-4.3-4.8
	C272.7,238.1,271.7,238.8,271.3,240.7L271.3,240.7z"
          />
          <path
            fill="none"
            d="M276.6,249.8v-0.8h-3.4v0.8 M276.6,249.8h-5.9v6.6l11.4,1.7v-8.3 M295.6,243.2l-1.1,0.3l-0.3,1.6l1,0.7
	 M291.4,247.2c-0.4,0.7-0.8,1.5-1.1,2.6h1c-1,2.6-1.1,5.5-0.5,8.7v-0.1c0-0.3,0.1-0.5,0.5-0.5h0.1V247.2z M292.3,246.2l-0.3,0.3
	v11.4h0.1c0.3,0,0.5,0.1,0.5,0.5v1.5c0.3,0,0.5,0.1,0.5,0.5v0.8c0,0.3-0.1,0.5-0.5,0.5v0.8c0,0.3-0.1,0.4-0.3,0.4h0.8l0.1-2.3v-0.4
	l0.8-14.6h-0.8C293,245.8,292.6,245.9,292.3,246.2L292.3,246.2z"
          />
          <path
            fill="none"
            d="M291.2,249.8h-9.1v8.3l4,0.5l4,0.5l0.5,0.1v-0.8C290,255.3,290.3,252.4,291.2,249.8L291.2,249.8z M294.2,245.1
	v0.6l-0.8,14.6l1.8-14.4 M266.5,282.6l-3.3,6.7v18.7h4.3l56-2.9h0.1l-0.1-2.1c-0.4-5.4-0.8-9.9-1.5-13.5c-0.5-2.9-1.1-5.2-1.8-7
	l-0.3-0.8c-0.1-0.5-0.4-1-0.7-1.4c-0.4-0.7-1-1.4-1.5-1.9c-0.8-1-1.9-1.8-3.3-2.6l-1.4-0.7c-3-1.4-6.3-1.8-9.6-1.4l-9.9,1.6
	l-0.7,0.1l-2.3,0.4l-0.5,0.1l-1.4,0.3l-16.4,2.6C269.5,278.9,267.6,280.3,266.5,282.6L266.5,282.6z M263.6,300.1
	c-0.3-1-0.4-2.1-0.1-3.3c0.1-1.2,0.5-2.3,1.1-3.2c0.5-0.8,1.1-1.1,1.6-1.1c0.4,0.1,0.8,0.5,1,1.5s0.1,2.1,0,3.3
	c-0.1,1.2-0.5,2.3-1,3.2c-0.4,0.8-0.8,1.2-1.4,1.2C264.3,301.6,263.8,301.1,263.6,300.1L263.6,300.1z"
          />
          <path
            fill="none"
            d="M263.4,296.8c-0.1,1.2-0.1,2.3,0.1,3.3c0.3,1,0.7,1.5,1.2,1.5c0.4,0.1,1-0.3,1.4-1.2c0.4-0.8,0.8-1.9,1-3.2
	c0.1-1.2,0.1-2.3,0-3.3c-0.1-1-0.5-1.5-1-1.5c-0.5,0-1.2,0.3-1.6,1.1C264,294.5,263.6,295.6,263.4,296.8L263.4,296.8z M292.6,259.8
	v-1.5c0-0.3-0.1-0.5-0.5-0.5h-1c-0.3,0-0.5,0.1-0.5,0.5v1.5H292.6z M293.1,260.4c0-0.3-0.1-0.5-0.5-0.5h-2.1c-0.1,0-0.3,0-0.3,0.1
	c-0.1,0.1-0.1,0.3-0.1,0.4v0.8c0,0.3,0.1,0.5,0.5,0.5h2.1c0.3,0,0.5-0.1,0.5-0.5v-0.8L293.1,260.4L293.1,260.4z"
          />
          <path
            fill="none"
            d="M294.2,260.9l-1-0.1l-0.1,2.3l0.1,12.1l9.9-1.6c3.3-0.4,6.6,0,9.6,1.4v-2.5h1.5l1.1-5.2
	c-5-2.1-10.2-3.9-15.5-5.1C298.1,261.6,296.2,261.3,294.2,260.9L294.2,260.9z M292.5,263.1h-1.2v1.2h-1.1v0.7H289v5.1l0.7-0.1v5.8
	l0.5-0.1v-8.5c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.4c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v8.3l0.7-0.1l-0.1-12.1
	L292.5,263.1L292.5,263.1z"
          />
          <path
            fill="none"
            d="M292.2,263.1h0.3c0.1-0.1,0.3-0.3,0.3-0.4v-0.8h-1.9v0.8c0,0.3,0.1,0.5,0.5,0.5h0.8L292.2,263.1L292.2,263.1z
	 M290.5,259.8h0.1v-0.5l-0.5-0.1c-0.1,0.3-0.1,0.5,0,0.8C290.3,259.9,290.4,259.8,290.5,259.8z"
          />
          <path
            fill="none"
            d="M290.1,259.9c-0.1-0.3-0.1-0.5,0-0.8l-4-0.5v6.5h4v-0.7h1.1v-1.2c-0.3,0-0.5-0.1-0.5-0.5v-0.8h-0.1
	c-0.3,0-0.5-0.1-0.5-0.5v-0.8C290,260.2,290.1,260.1,290.1,259.9L290.1,259.9z M290.5,266.4c-0.1,0.1-0.3,0.4-0.3,0.7v5.4h2.3v-5.4
	c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-0.4C291.1,266.1,290.8,266.3,290.5,266.4L290.5,266.4z"
          />
          <path
            fill="none"
            d="M292.6,275.4v-2.9h-2.3v3.3 M289.7,270l-0.7,0.1c-0.4,0.1-0.5,0.7-0.5,1.4v4.5l1.4-0.3V270H289.7L289.7,270z
	 M354.4,236.7c-0.1-0.1-0.4-0.3-0.7-0.3l-9.5,0.6l0.1,2.2l9.5-0.6c0.3,0,0.5-0.1,0.7-0.4c0.1-0.3,0.3-0.6,0.3-0.8
	C354.6,237.2,354.5,237,354.4,236.7L354.4,236.7z M365.8,239.7l-1.2,4.7c3,0.3,5.5,0.1,7.6-0.4l-1-4.4
	C369.4,239,367.6,239,365.8,239.7L365.8,239.7z M324.1,256.2l-1.4,0.1l-3,2.1c0,0.8-0.5,1.8-1.6,2.9l1,0.3c1.1-1.2,1.7-2.3,1.7-3.2
	L324.1,256.2L324.1,256.2z"
          />
          <path
            fill="none"
            d="M325.3,256.5l-1.2-0.3l-3.4,2.3c0,0.8-0.5,1.9-1.6,3.2l0.1,0.1l2.1,0.7l1.4,0.1l0.8-1.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.4,0.3-0.5s0.3-0.3,0.5-0.3c0.1,0,0.4,0.1,0.5,0.3l0.4-0.5l-1.4-0.8L325.3,256.5L325.3,256.5z
	 M334.8,237.4c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.3-0.3,0.6-0.3,0.8s0.1,0.6,0.3,0.8c0.3,0.3,0.4,0.3,0.7,0.3l9.5-0.6l-0.1-2.2
	L334.8,237.4L334.8,237.4z"
          />
          <path
            fill="none"
            d="M324.5,260.4c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.4,0.1-0.5,0.3s-0.3,0.3-0.3,0.5c0,0.1,0.1,0.3,0.1,0.5l0.1,0.1
	l0.3,0.1c0.1,0,0.4-0.1,0.5-0.3l0.3-0.5C324.6,260.6,324.6,260.5,324.5,260.4z M319.3,261.7l-0.3,0.4l-3.6,4.1l0.3,0.1l1.8,0.5h2.1
	l3.2-4.4l-1.4-0.1"
          />
          <path
            fill="none"
            d="M319,262.1l-1.8,0.1l-3.2,3.7l1.4,0.3 M317.2,267.9l0.3-1l-1.8-0.5l-0.1,0.8l-1.2,5.2l2.1,0.7"
          />
          <path
            fill="none"
            d="M320,269.3l-2.8-1.2l-0.8,5.1l1.4,0.4v1.4l0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.4-0.3,0.5l-0.4,0.3
	l-0.1,1.9c0.7,0.7,1.1,1.2,1.5,1.9c0-3.4,0.1-6.7,0.4-9.9v-0.3L320,269.3L320,269.3z M314.3,272.5H313v2.5l1.4,0.7"
          />
          <path
            fill="none"
            d="M316.4,273.2l-2.1-0.5l-0.1,3.2c1.4,0.8,2.5,1.6,3.3,2.6l0.1-1.9h-0.1l-0.3-0.1l-0.1-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3h0.1v-1.4L316.4,273.2L316.4,273.2z M322,270.8l0.3-0.5
	l-2.3-1l-0.3,0.7v0.3c-0.3,3.2-0.4,6.5-0.4,9.9c0.3,0.4,0.5,1,0.7,1.4l0.3,0.8c0.7,1.8,1.2,4.1,1.8,7
	C321.5,282.8,321.5,276.5,322,270.8L322,270.8z"
          />
          <path
            fill="none"
            d="M318,275.1l-0.4-0.1h-0.1c-0.1,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.3,0.1,0.5l0.1,0.1
	l0.3,0.1h0.1l0.4-0.3c0.1-0.1,0.3-0.3,0.3-0.5C318.3,275.4,318.2,275.2,318,275.1L318,275.1z M322.9,305.6l0.5-0.4l-56,2.9h-4.1v3
	l5.1,0.1l52.8-2.5l-0.1-1.8 M350.8,324.1l1.5,0.3c1.5,0.3,3,1,4.3,2.1h128.8c1.4-1,2.8-1.6,4.3-2.1l1.5-0.3h25.6l0.8-9.1
	c-1,1.5-1.9,2.8-3.2,3.9c-1.7,1.4-3.7,2.1-6.2,2.3c2.6-0.3,4.7-1,6.2-2.3c1.2-1.1,2.3-2.3,3.2-3.9l0.5-6.3
	c-1.7,5.2-4.1,8.7-7.3,10.3c-2.6,1.2-7.3,2.1-14.3,2.2h11.1H334.3h11.1c-7-0.3-11.7-1-14.3-2.2c-3.2-1.6-5.6-5-7.3-10.3l0.5,6.3
	c1,1.5,1.9,2.8,3.2,3.9c1.6,1.4,3.7,2.1,6.2,2.3c-2.6-0.3-4.7-1-6.2-2.3c-1.2-1.1-2.3-2.3-3.2-3.9l0.8,9.1H350.8L350.8,324.1z"
          />
          <path
            fill="none"
            d="M352.4,324.3l-1.6-0.3h-33.2l-2.1,0.4l2.6,40.6l0.7,0.3l23.7-0.4h8.5l2.9-0.3"
          />
          <path
            fill="none"
            d="M356.7,326.4c-1.4-1-2.8-1.6-4.3-2.1l1.5,40.3c0.8-0.1,1.7-0.4,2.5-1l1.6-0.7L356.7,326.4L356.7,326.4z
	 M321.1,307l0.1,1.8l2.6-0.1v-0.3l-1-2.8"
          />
          <path
            fill="none"
            d="M325.2,324.1l-0.8-9.1l-0.5-6.3l-2.6,0.1l-52.7,2.5l-5.2-0.1v36.3c0,2.6,0.8,6.3,2.5,10.9
	c0.3,0.4,1,0.7,2.1,0.7h6.9c-1.2-2.6-2.2-6.2-2.8-10.9l-0.4-17.6c0-1.6,1-2.5,2.6-2.6l37.3-1.6c1.5-1,2.9-1.6,4.1-1.9l1.9-0.3
	L325.2,324.1L325.2,324.1L325.2,324.1z"
          />
          <path
            fill="none"
            d="M315.6,324.5c-1.2,0.4-2.6,1-4.1,1.9l2.1,37.1c1.5,0.3,3,0.8,4.5,1.5L315.6,324.5L315.6,324.5z"
          />
          <path
            fill="none"
            d="M342.5,368.2v-3.3l-23.7,0.4l-0.7-0.3h-9.6v3.2 M342.5,364.9v3.3h7.4c0.1-2.1,0.5-3.2,1.1-3.3H342.5z"
          />
          <path
            fill="none"
            d="M308.6,365h9.6c-1.5-0.7-3-1.2-4.5-1.5l-5.1-0.5V365z"
          />
          <path
            fill="none"
            d="M308.6,368.2V363l-28.6-2.5c-2.8-0.1-4.4-0.5-5.2-1.4h-6.9c-1.1,0-1.9-0.3-2.1-0.7c-1.6-4.5-2.5-8.3-2.5-10.9
	v-33.4l-2.9,3c-0.5,0.4-1,1.9-1.2,4.3v110.7c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1
	c4-1.2,6.2-3.9,6.6-7.7v-54.8L308.6,368.2L308.6,368.2z M308.6,368.2v8.9h225.1v-8.9H342.5"
          />
          <path
            fill="none"
            d="M308.6,362.8l5.1,0.5l-2.1-37.1l-37.3,1.6c-1.6,0-2.6,0.8-2.6,2.6l0.4,17.6c0.5,4.7,1.5,8.3,2.8,10.9
	c0.8,0.8,2.6,1.2,5.2,1.4L308.6,362.8L308.6,362.8z M279.7,342.2c0-2.5,0.8-4.5,2.6-6.2c1.7-1.6,3.9-2.6,6.2-2.6
	c2.5,0,4.5,0.8,6.2,2.6c1.7,1.8,2.6,3.9,2.6,6.2c0,2.5-0.8,4.5-2.6,6.2c-1.8,1.8-3.9,2.6-6.2,2.6c-2.5,0-4.5-0.8-6.2-2.6
	C280.5,346.8,279.7,344.7,279.7,342.2L279.7,342.2z"
          />
          <path
            fill="none"
            d="M282.3,336c-1.8,1.8-2.6,3.9-2.6,6.2c0,2.5,0.8,4.5,2.6,6.2c1.7,1.8,3.9,2.6,6.2,2.6c2.5,0,4.5-0.8,6.2-2.6
	c1.7-1.6,2.6-3.9,2.6-6.2c0-2.5-0.8-4.5-2.6-6.2c-1.8-1.6-3.9-2.6-6.2-2.6C286,333.4,283.9,334.2,282.3,336L282.3,336z M281.3,342.2
	c0-1.9,0.7-3.7,2.1-5.1c1.4-1.4,3-2.1,5.1-2.1c1.9,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1c0,1.9-0.7,3.7-2.1,5.1s-3,2.1-5.1,2.1
	c-1.9,0-3.7-0.7-5.1-2.1C282,345.9,281.3,344.3,281.3,342.2L281.3,342.2z"
          />
          <path
            fill="none"
            d="M283.4,337.3c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.7,2.1,5.1c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.7-0.7,5.1-2.1
	c1.4-1.4,2.1-3,2.1-5.1c0-1.9-0.7-3.7-2.1-5.1c-1.4-1.4-3-2.1-5.1-2.1C286.4,335.1,284.8,335.7,283.4,337.3L283.4,337.3z
	 M286.3,342.2c0-0.5,0.3-1.1,0.7-1.5c0.4-0.4,1-0.5,1.5-0.5s1.1,0.1,1.5,0.5c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5
	c-0.4,0.4-1,0.7-1.5,0.7s-1.1-0.3-1.5-0.7C286.5,343.3,286.3,342.9,286.3,342.2L286.3,342.2z"
          />
          <path
            fill="none"
            d="M287,340.7c-0.4,0.4-0.7,1-0.7,1.5s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.5-0.7
	c0.4-0.4,0.7-1,0.7-1.5s-0.3-1.1-0.7-1.5c-0.4-0.4-1-0.5-1.5-0.5S287.4,340.3,287,340.7L287,340.7z M308.6,377.2v4.4h225.1v-4.4
	 M376.8,368.2h1.6v-5.1h-1.6 M376.8,368.2v-5.1h-18.6l-1.7,0.7c-0.8,0.4-1.6,0.8-2.5,1l-2.8,0.1c-0.5,0.1-1,1.2-1.1,3.3L376.8,368.2
	L376.8,368.2z M378.4,363.1v5.1h121.1v-3.3l-7.3-0.1H489l-1-0.1c-0.8-0.1-1.6-0.4-2.5-1l-1.6-0.7H378.4L378.4,363.1L378.4,363.1z"
          />
          <path
            fill="none"
            d="M376.8,363.1h107.3l1.4-36.7H356.7l1.4,36.7H376.8L376.8,363.1z M371.8,332c0-0.7,0.3-1,1-1h96.6
	c0.7,0,1,0.3,1,1v22.1c0,0.7-0.3,1-1,1h-96.6c-0.7,0-1-0.3-1-1L371.8,332L371.8,332z"
          />
          <path
            fill="none"
            d="M372.8,331.1c-0.7,0-1,0.3-1,1v22.1c0,0.7,0.3,1,1,1h96.6c0.7,0,1-0.3,1-1V332c0-0.7-0.3-1-1-1H372.8
	L372.8,331.1z M376.5,353.2c-0.7,0-1-0.3-1-1v-18.6c0-0.7,0.3-1,1-1h89c0.7,0,1,0.3,1,1v18.6c0,0.7-0.3,1-1,1L376.5,353.2
	L376.5,353.2z"
          />
          <path
            fill="none"
            d="M375.5,352.3c0,0.7,0.3,1,1,1h89c0.7,0,1-0.3,1-1v-18.6c0-0.7-0.3-1-1-1h-89c-0.7,0-1,0.3-1,1L375.5,352.3
	L375.5,352.3z M578.9,311.1l-5.2,0.1l-52.7-2.5l-2.6-0.1l-0.5,6.3l-0.8,9.1h7.6l1.9,0.3c1.2,0.4,2.6,1,4.1,1.9l37.3,1.6
	c1.7,0,2.6,0.8,2.6,2.6l-0.4,17.6c-0.5,4.7-1.5,8.3-2.8,10.9h6.9c1.1,0,1.9-0.3,2.1-0.7c1.7-4.5,2.5-8.3,2.5-10.9v-36.3L578.9,311.1
	L578.9,311.1z"
          />
          <path
            fill="none"
            d="M581.8,317l-2.9-3v33.4c0,2.6-0.8,6.3-2.5,10.9c-0.3,0.4-1,0.7-2.1,0.7h-6.9c-0.8,0.8-2.6,1.2-5.4,1.4
	l-28.6,2.5v69.1c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1c4-1.2,6.2-3.9,6.6-7.7V321.2
	C582.7,319,582.3,317.6,581.8,317L581.8,317z"
          />
          <path
            fill="none"
            d="M570.2,348.1l0.4-17.6c0-1.6-1-2.5-2.6-2.6l-37.3-1.6l-2.1,37.1l5.1-0.5l28.6-2.5c2.8-0.1,4.4-0.5,5.4-1.4
	C568.7,356.4,569.5,352.8,570.2,348.1L570.2,348.1z M559.9,336c1.8,1.8,2.6,3.9,2.6,6.2c0,2.5-0.8,4.5-2.6,6.2
	c-1.7,1.8-3.9,2.6-6.2,2.6c-2.5,0-4.5-0.8-6.2-2.6c-1.7-1.6-2.6-3.9-2.6-6.2c0-2.5,0.8-4.5,2.6-6.2c1.8-1.6,3.9-2.6,6.2-2.6
	C556.2,333.4,558.2,334.2,559.9,336L559.9,336z"
          />
          <path
            fill="none"
            d="M562.5,342.2c0-2.5-0.8-4.5-2.6-6.2c-1.7-1.6-3.9-2.6-6.2-2.6c-2.5,0-4.5,0.8-6.2,2.6c-1.7,1.8-2.6,3.9-2.6,6.2
	c0,2.5,0.8,4.5,2.6,6.2c1.8,1.8,3.9,2.6,6.2,2.6c2.5,0,4.5-0.8,6.2-2.6C561.7,346.8,562.5,344.7,562.5,342.2L562.5,342.2z
	 M558.8,337.3c1.4,1.4,2.1,3,2.1,5.1c0,1.9-0.7,3.7-2.1,5.1c-1.4,1.4-3,2.1-5.1,2.1c-1.9,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3-2.1-5.1
	c0-1.9,0.7-3.7,2.1-5.1c1.4-1.4,3-2.1,5.1-2.1C555.6,335.1,557.3,335.7,558.8,337.3L558.8,337.3z"
          />
          <path
            fill="none"
            d="M560.8,342.2c0-1.9-0.7-3.7-2.1-5.1c-1.4-1.4-3-2.1-5.1-2.1c-1.9,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3-2.1,5.1
	c0,1.9,0.7,3.7,2.1,5.1c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.7-0.7,5.1-2.1C560.2,345.9,560.8,344.3,560.8,342.2z M555.2,340.7
	c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5s-1,0.7-1.5,0.7c-0.5,0-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-1-0.7-1.5s0.3-1.1,0.7-1.5
	c0.4-0.4,1-0.5,1.5-0.5C554.2,340.1,554.8,340.3,555.2,340.7L555.2,340.7z"
          />
          <path
            fill="none"
            d="M555.8,342.2c0-0.5-0.3-1.1-0.7-1.5c-0.4-0.4-1-0.5-1.5-0.5s-1.1,0.1-1.5,0.5c-0.4,0.4-0.7,1-0.7,1.5
	s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.5-0.7C555.6,343.3,555.8,342.9,555.8,342.2z M530.6,326.4c-1.5-1-2.9-1.6-4.1-1.9
	l-2.6,40.6c1.5-0.7,3-1.2,4.5-1.5L530.6,326.4z M521,308.8l0.1-1.8l-1.8-1.4l-1,2.8l-0.1,0.3"
          />
          <path
            fill="none"
            d="M524.5,324.1h-33.2l-1.5,0.3l-1.5,40.3l1,0.1c1,0.1,2.1,0.1,3.2,0l7.3,0.1l23.8,0.4l0.7-0.3l2.6-40.6
	L524.5,324.1L524.5,324.1z M489.7,324.3c-1.5,0.3-3,1-4.3,2.1l-1.4,36.7l1.6,0.7c0.8,0.4,1.6,0.8,2.5,1L489.7,324.3L489.7,324.3z"
          />
          <path fill="none" d="M499.5,364.9v3.3h34.1V365H524l-0.7,0.3" />
          <path
            fill="none"
            d="M533.6,365V363l-5.1,0.5c-1.5,0.3-3,0.8-4.5,1.5L533.6,365L533.6,365z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.4,263.1c0,0.3,0,0.4,0.1,0.7c0.3,0.5,0.8,1,1.5,1.1l1,0.1l1.5-0.1
	c0.4-0.1,0.8-0.3,1.1-0.5l0.3-0.4v-0.1h-1.9c-0.1,0.1-0.3,0.3-0.5,0.3H424c-0.4,0-0.5-0.1-0.7-0.4l-0.1-0.4h3.7v-0.5l-0.3-0.7
	c-0.3-0.5-0.8-0.8-1.5-1l-1.2-0.1c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.1-0.7,0.4-1,0.7C421.5,262.3,421.2,262.7,421.4,263.1L421.4,263.1z
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M423.3,262.3l0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.3h0.5
	c0.3,0.1,0.5,0.3,0.7,0.5v0.3h-1.9L423.3,262.3L423.3,262.3z M417.4,262.4l0.1-0.3c0.1-0.1,0.3-0.3,0.5-0.3h0.5
	c0.4,0.1,0.5,0.3,0.7,0.4v0.3L417.4,262.4L417.4,262.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.2,263.2h3.7v-0.7l-0.3-0.7c-0.3-0.5-0.8-0.8-1.7-1l-1.2-0.1
	c-0.4,0-0.8,0.1-1.2,0.3s-0.7,0.4-0.8,0.8c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.4,0,0.7,0.1,1c0.3,0.5,0.8,0.8,1.5,1.1l1,0.1l1.5-0.1
	c0.4-0.1,0.8-0.3,1-0.5l0.3-0.4v-0.1H419c-0.1,0.1-0.3,0.3-0.5,0.3h-0.5c-0.3,0-0.4-0.1-0.5-0.3l-0.1-0.3L417.2,263.2L417.2,263.2z
	 M415,259.4h-1.9v3.7c-0.1,0.3-0.3,0.4-0.5,0.5l-0.8,0.1c-0.4,0-0.7-0.1-0.8-0.4l-0.1-0.3v-0.7h-1.9v0.8l0.1,0.5
	c0.1,0.4,0.5,0.8,1.2,1l1,0.1h1.1l1-0.1c0.7-0.1,1.1-0.4,1.5-0.8c0.1-0.3,0.3-0.5,0.3-0.8L415,259.4L415,259.4z M432.8,261.7
	c-0.3-0.3-0.5-0.5-1.1-0.7l-1.5-0.1c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1h-0.3V261h-1.8v5.1h1.9v-1.4c0,0,0-0.1,0.1,0l0.5,0.3l1.2,0.1
	c0.5,0,1-0.1,1.4-0.5c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.4,0.3-1,0.1-1.4L432.8,261.7L432.8,261.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M431.1,263.5l-0.1,0.3c-0.1,0.3-0.4,0.3-0.8,0.3h-0.4
	c-0.3,0-0.4-0.1-0.5-0.3l-0.1-0.4v-0.3l0.1-0.5l0.1-0.3c0.1-0.3,0.5-0.3,1-0.3h0.3c0.3,0,0.4,0.1,0.5,0.3l0.1,0.3
	C431.3,263,431.3,263.3,431.1,263.5L431.1,263.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M524.4,179.1l3.7,39.1
	c-0.1,2.5-2.2,3.7-6.3,3.7c-31.5-1.1-63.3-1.8-95.3-1.8H415c-32.1,0-63.8,0.6-95.3,1.8c-4.1,0-6.2-1.2-6.3-3.7l3.7-39.1
	c0.4-2.5,2.3-3.9,5.9-4.1c27.2-1.5,55.9-2.3,85.7-2.8h23.9c30,0.4,58.5,1.2,85.7,2.8C522.1,175.2,524,176.6,524.4,179.1L524.4,179.1
	z M501.8,310.3c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-0.5-0.3-1.1-0.7-1.7c-0.4-0.4-1-0.7-1.6-0.7
	c-0.5,0-1.1,0.3-1.7,0.7c-0.4,0.4-0.7,1-0.7,1.7C501.1,309.3,501.4,309.9,501.8,310.3z M340.3,310.3c-0.4,0.4-1,0.7-1.6,0.7
	c-0.7,0-1.1-0.3-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.5,0.3-1.1,0.7-1.7c0.4-0.4,1-0.7,1.6-0.7c0.5,0,1.1,0.3,1.6,0.7
	c0.4,0.4,0.7,1,0.7,1.7C341,309.3,340.7,309.9,340.3,310.3L340.3,310.3z M342.3,263.9c4.1,0,7.7,1.5,10.6,4.4
	c2.9,2.9,4.4,6.5,4.4,10.6s-1.5,7.7-4.4,10.6c-2.9,2.9-6.5,4.4-10.6,4.4s-7.7-1.5-10.6-4.4c-2.9-2.9-4.4-6.5-4.4-10.6
	s1.5-7.7,4.4-10.6C334.6,265.4,338.1,263.9,342.3,263.9L342.3,263.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M345.1,276
	c0.8,0.8,1.2,1.8,1.2,2.9s-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-0.8-0.8-1.2-1.8-1.2-2.9s0.4-2.1,1.2-2.9
	c0.8-0.8,1.8-1.2,2.9-1.2C343.4,274.9,344.3,275.4,345.1,276z M489.2,268.3c-2.9,2.9-4.4,6.5-4.4,10.6s1.5,7.7,4.4,10.6
	c2.9,2.9,6.5,4.4,10.6,4.4s7.7-1.5,10.6-4.4c2.9-2.9,4.4-6.5,4.4-10.6s-1.5-7.7-4.4-10.6c-2.9-2.9-6.5-4.4-10.6-4.4
	S492.1,265.4,489.2,268.3L489.2,268.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M495.8,278.9
	c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9s-0.4-2.1-1.2-2.9
	c-0.8-0.8-1.8-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.9,1.2C496.2,276.9,495.8,277.8,495.8,278.9L495.8,278.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M411.3,293.6h-12.4 M411.3,296h-12.4 M411.3,285.5h-12.4 M411.3,286.8
	h-12.4 M411.3,289.1h-12.4 M411.3,281.8h-12.4 M411.3,283.1h-12.4 M411.3,278.2h-12.4 M447.5,278.2v-4.7c0-1.6,0.5-3.2,1.8-4.4
	s2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c1.2,1.2,1.8,2.6,1.8,4.4v4.7 M447.5,279.5v-1.2 M447.5,281.8v-2.3 M459.9,278.2v1.2
	 M459.9,279.5v2.3 M465.2,278.2v-1.9h8l3.2,4.1 M465.2,278.2v1.2 M464,281.8v-2.3 M465.2,281.8H464 M464,278.2h1.2 M464,279.5v-1.2
	 M465.2,279.5H464 M465.2,281.8v-2.3 M464,278.2v-4.7c0-1.6,0.6-3.2,1.8-4.4s2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8
	c1.2,1.2,1.8,2.6,1.8,4.4v6.7 M459.9,281.8h-12.4 M459.9,278.2h-12.4 M447.5,279.5h12.4 M430.8,278.2v-4.7c0-1.6,0.5-3.2,1.8-4.4
	s2.6-1.8,4.4-1.8c1.6,0,3.2,0.5,4.4,1.8c1.2,1.2,1.8,2.6,1.8,4.4v4.7 M394.5,300.9v-1.2 M394.5,299.7v-2.6 M426.7,279.5v2.3
	 M426.7,279.5v-1.2 M430.8,279.5v-1.2 M430.8,281.8v-2.3 M443.2,278.2v1.2 M443.2,279.5v2.3 M443.2,278.2h-12.4 M443.2,279.5h-12.4
	 M443.2,281.8h-12.4 M443.2,290.2v2.2 M443.2,292.4v1.2 M443.2,289.1v1.2 M443.2,286.8v2.3 M443.2,285.5v1.2 M443.2,283.1v2.6
	 M426.7,285.5v1.2 M430.8,285.5v-2.6 M430.8,286.8v-1.2 M426.7,283.1v2.6 M430.8,292.4v-2.2 M430.8,290.2V289 M426.7,292.4v1.2
	 M426.7,290.2v2.2 M426.7,289.1v1.2 M430.8,293.6v-1.2 M430.8,289.1v-2.3 M426.7,289.1v-2.3 M426.7,297.1v2.6 M430.8,297.1v2.6
	 M430.8,296v1.2 M426.7,297.1v-1.2 M430.8,300.9v2.3 M426.4,306.4c0.1-0.3,0.3-0.7,0.3-1v-1 M426.7,304.4v-1.2 M426.7,300.9v2.3
	 M430.8,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M430.8,303.3v1.2 M430.8,299.7v1.2 M426.7,300.9v-1.2 M443.2,297.1v-1.2 M443.2,299.7v-2.6
	 M443.2,300.9v-1.2 M443.2,303.3v-2.3 M443.2,304.4v-1.2 M443.1,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M430.8,283.1h12.4 M443.2,285.5
	h-12.4 M430.8,286.8h12.4 M443.2,289.1h-12.4 M430.8,292.4h12.4 M443.2,293.7h-12.4 M443.2,296v-2.3 M430.8,293.6v2.3 M430.8,290.2
	h12.4 M426.7,296v-2.3 M443.2,297.1h-12.4 M430.8,296h12.4 M443.2,299.7h-12.4 M430.8,304.4h12.4 M443.2,303.3h-12.4 M430.8,300.9
	h12.4 M465.2,286.8H464 M464,286.8v-1.2 M464,285.5h1.2 M465.2,285.5v-2.6 M465.2,286.8v-1.2 M464,283.1h1.2 M464,285.5v-2.6
	 M459.9,283.1v2.6 M459.9,285.5v1.2 M459.9,289.1v1.2 M459.9,292.4v-2.2 M459.9,293.7v-1.2 M465.2,290.2V289 M464,289.1h1.2
	 M464,290.2V289 M465.2,290.2H464 M465.2,293.7v-1.2 M464,292.4h1.2 M464,293.7v-1.2 M465.2,293.7H464 M465.2,292.4v-2.2 M464,292.4
	v-2.2 M465.2,289.1v-2.3 M459.9,286.8v2.3 M464,289.1v-2.3 M447.5,285.5v-2.6 M447.5,290.2V289 M447.5,292.4v1.2 M447.5,290.2v2.2
	 M447.5,289.1v-2.3 M447.5,286.8v-1.2 M447.5,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M447.5,303.3v1.2 M447.5,300.9v2.3 M447.5,299.7v1.2
	 M447.5,297.1v2.6 M447.5,296v1.2 M465.2,299.7H464 M464,299.7v-2.6 M465.2,297.1H464 M464,297.1v-1.2 M464,296h1.2 M465.2,297.1
	v-1.2 M465.2,299.7v-2.6 M459.9,297.1v-1.2 M459.9,299.7v-2.6 M459.9,303.3v-2.3 M459.9,304.4v-1.2 M459.7,306.5
	c0.1-0.3,0.1-0.5,0.1-1v-1.1 M465.2,304.4v-1.2 M465.2,303.3v-2.3 M464,300.9h1.2 M464,303.3v-2.3 M465.2,303.3H464 M464,304.4v-1.2
	 M465.2,304.4H464 M464.3,306.5c-0.1-0.3-0.3-0.5-0.3-1v-1.1 M459.9,300.9v-1.2 M465.2,300.9v-1.2 M464,300.9v-1.2 M465.2,296v-2.3
	 M459.9,283.1h-12.4 M459.9,285.5h-12.4 M447.5,289.1h12.4 M459.9,290.2h-12.4 M459.9,296v-2.3 M447.5,293.6v2.3 M447.5,286.8h12.4
	 M459.9,292.4h-12.4 M459.9,293.7h-12.4 M447.5,304.4h12.4 M459.9,303.3h-12.4 M447.5,300.9h12.4 M459.9,299.7h-12.4 M447.5,297.1
	h12.4 M447.5,296h12.4 M464,296v-2.3 M447.5,283.1v-1.2 M459.9,281.8v1.2 M465.2,283.1v-1.2 M443.2,281.8v1.2 M426.7,283.1v-1.2
	 M430.9,283.1v-1.2 M464,283.1v-1.2 M426.7,278.2h-11.3 M426.7,281.8h-11.3 M415.4,279.5h11.4 M426.7,285.5h-11.3 M415.4,283.1h11.4
	 M426.7,286.8h-11.3 M426.7,290.2h-11.3 M415.4,289.1h11.4 M426.7,292.4h-11.3 M426.7,293.7h-11.3 M426.7,299.7h-11.3 M415.4,297.1
	h11.4 M426.7,303.3h-11.3 M415.4,300.9h11.4 M415.4,296h11.4 M426.7,304.4h-11.3 M426.9,255.8h-2.3 M543.5,219.9
	c-2.5-1.6-5.1-2.6-7.6-2.5 M528,230.1c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.6-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C528.7,230.2,528.4,230.1,528,230.1
	L528,230.1z M521.2,226.7l13.3,0.3c1.9,0,2.8-0.7,2.6-2.1l-1.1-7.6 M521.2,231.2v-4.5 M508,237.8c-0.1-0.1-0.4-0.3-0.5-0.3L498,237
	 M521.5,238.9l-13.5-1.2 M517.9,256.2l-1.2,0.3l1.8,2.3l-1.4,0.8l0.4,0.5 M523,261.7l1-0.3c-1.1-1.1-1.7-2.1-1.7-2.9l-3-2.1
	l-1.4-0.1 M497.8,239.2l9.5,0.6c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8 M536.9,237.4
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.6-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8C536.4,237.3,536.6,237.4,536.9,237.4L536.9,237.4z M521.2,231.2l0.3,7.7
	 M542.1,262.1c-1.2-4.3-2.5-7.7-4-10.5c-2.8-5.1-8.5-8.1-17.6-9.1c-14-1.5-28.8-2.5-44.3-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M521.5,238.9c4.4,0.6,8,1,10.7,1.6c7.7,1.5,12.9,8.3,15.7,20.4
	 M476.2,239.7c-1.8-0.7-3.6-0.7-5.4-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M476.2,239.7l1.2,4.7c-3,0.3-5.5,0.1-7.6-0.4l1-4.4 M497.8,239.2l0.1-2.2
	 M498,237l-9.5-0.6c-0.3,0-0.4,0.1-0.7,0.3 M487.8,236.7c-0.3,0.3-0.3,0.6-0.4,0.8c0,0.3,0.1,0.6,0.3,0.8c0.1,0.3,0.4,0.3,0.7,0.4
	l9.5,0.6 M426.9,255.8c23.9,0,46.5,0.5,67.7,1.8c13.1,0.4,21.6,4.7,25.3,12.7 M411.3,292.4h-12.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M487.1,266.3c-3.4,3.4-5.2,7.7-5.2,12.7c0,5,1.8,9.2,5.2,12.7
	c3.4,3.6,7.7,5.2,12.7,5.2c5,0,9.2-1.8,12.7-5.2c3.4-3.4,5.2-7.7,5.2-12.7s-1.8-9.2-5.2-12.7c-3.4-3.4-7.7-5.2-12.7-5.2
	C494.8,261.1,490.7,262.8,487.1,266.3L487.1,266.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M487.9,267.2c-3.3,3.3-4.8,7.2-4.8,11.8c0,4.7,1.6,8.5,4.8,11.8
	c3.3,3.3,7.1,5,11.8,5s8.5-1.6,11.8-5c3.3-3.3,5-7.2,5-11.8c0-4.7-1.7-8.5-5-11.8c-3.3-3.3-7.2-4.8-11.8-4.8
	C495.2,262.3,491.2,263.9,487.9,267.2L487.9,267.2z M411.3,290.2h-12.4 M498.1,303.4c-1.5,1.5-2.2,3.3-2.2,5.4
	c0,2.1,0.7,3.9,2.2,5.4c1.5,1.5,3.3,2.2,5.4,2.2c2.1,0,3.9-0.7,5.4-2.2c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.7-3.9-2.2-5.4
	c-1.5-1.5-3.3-2.2-5.4-2.2C501.3,301.2,499.5,302,498.1,303.4L498.1,303.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M497.4,302.9c-1.6,1.7-2.5,3.6-2.5,5.9s0.8,4.3,2.5,5.9
	c1.6,1.6,3.6,2.5,5.9,2.5s4.3-0.8,5.9-2.5c1.6-1.7,2.5-3.6,2.5-5.9s-0.8-4.3-2.5-5.9c-1.6-1.6-3.6-2.5-5.9-2.5
	C501.1,300.4,499.1,301.2,497.4,302.9L497.4,302.9z M476.4,280.4v25c0,1-0.5,1.8-1.8,2.5c-1.2,0.7-2.6,1-4.4,1c-1.6,0-3.2-0.3-4.4-1
	l-0.5-0.3h-0.1 M526.6,266.3l1.4-0.3l-3.2-3.7l-1.7-0.3 M526.3,266.4l0.3-0.1 M526.6,267.2l-0.3-0.8 M529.2,274.9l-1.4,0.7
	 M527.7,272.5l0.1,3.2 M525.6,273.2l2.1-0.7 M527.7,272.5h1.5v2.5 M526.6,267.2l1.1,5.2 M542.1,262.1c-5.5,1.4-10.7,3-15.5,5.1
	 M548.7,275.2l-9.9-1.6c-3.3-0.4-6.6,0-9.6,1.4 M519.3,262.6l1.5-0.1l2.1-0.7 M523.2,262l-0.3-0.3 M522.9,261.7h0.1 M523,261.7
	c-1.1-1.2-1.7-2.3-1.7-3.2l-3.4-2.3 M518.5,261.5l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.6-0.3
	c-0.1,0-0.4,0.1-0.5,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M517.7,260.4c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5
	c0.1,0.1,0.3,0.3,0.5,0.3l0.3-0.1 M519.3,262.6l-0.8-1.1 M524.7,267h-2.1l-3.3-4.4 M524.7,267l0.1,1 M524.8,267.9l-2.6,1.4
	 M524.4,274.9c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.5l0.4,0.3 M522.2,269.3l0.3,0.7v0.3
	c0.3,3.2,0.4,6.5,0.4,9.9 M524.4,274.9v-1.4l1.2-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524.5,276.3l0.4-0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.5
	c0-0.1-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.3-0.5-0.3h-0.1 M519.9,270.3l0.3,0.5c0.5,5.8,0.5,12,0.1,18.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M523,280.3c-0.3,0.4-0.5,1-0.7,1.4l-0.3,0.8c-0.7,1.8-1.2,4.1-1.8,7
	 M524.5,278.2v-1.9 M523,280.3c0.4-0.7,1-1.4,1.5-1.9 M519.9,270.3l2.3-1 M518.8,303.1l-0.1,1.1l-0.1,1.2c-1.8,6.7-4.5,11-8.1,12.7
	c-2.3,1.4-8.9,2.1-19.9,1.9H351.8c-11,0.1-17.6-0.5-19.9-1.9c-3.6-1.8-6.3-5.9-8.1-12.7l-0.1-1.2l-0.1-1.1 M520.3,289.5
	c-0.7,3.7-1.1,8.1-1.5,13.5 M518.8,305.2h-0.1l0.1-2.1 M519.3,305.6l-0.5-0.4 M527.8,275.6c-1.4,0.8-2.5,1.6-3.3,2.6 M526.3,266.4
	l-1.7,0.5 M525.6,273.2l-0.8-5.2 M526.6,266.3l-3.4-4.3 M452.4,229.5h19l49.8,1.6 M469.2,156.9c-8-0.3-15.8-0.4-23.7-0.4
	 M320.9,226.7c34.7-1.2,68-1.6,100.1-1.2c32.2-0.4,65.5,0,100,1.2 M570.9,243.3h16.9c3.9,0.1,5.8-1.6,5.6-5.4v-11.7
	c0-3.9-0.1-6.9-0.5-8.9c-0.5-1.8-1.8-2.6-4-2.6c-8.8-0.7-18.4-0.8-28.8-0.4c-2.9,0.3-4.1,2.1-4.1,5.2v19c-0.1,2.9,1.8,4.5,5.6,4.8
	h1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M587.8,219.9h-19.1c-1.7,0-2.5,0.8-2.5,2.5v11.8c0,1.7,0.8,2.5,2.5,2.5
	h19.1c1.7,0,2.5-0.8,2.5-2.5v-11.8C590.3,220.6,589.5,219.9,587.8,219.9L587.8,219.9z M545.9,230l0.4,2.8 M545.3,225.3l0.5,4.7
	 M545.3,225.3l0.5-0.8l-0.3-1.5l-0.7-0.6"
          />
          <path
            fill="#FFFFFF"
            stroke="#010101"
            strokeWidth="0.5"
            d="M546.3,232.7l0.5-0.8l-0.1-1.8l-0.8-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M547.6,243.4l-1.1-0.3 M547.8,245.1l-0.1-1.6 M551.9,259.1l-0.7,0.1
	c0.8-3.4,0.8-6.6-0.3-9.5h1 M549.8,246.2c-0.3-0.3-0.7-0.4-1-0.6h-0.8 M551.9,249.8c-0.3-1.2-0.7-2.1-1.2-2.8 M550.7,247
	c-0.3-0.4-0.5-0.7-0.8-0.8 M547.9,245.6l-0.1-0.6 M563.5,243.3v5.6h1.9 M560,249.8h-8.1 M546.8,245.8l-0.3-2.6 M547.8,245.1l-1,0.7
	 M563.5,243.3c-0.1-2.6,1.2-4.3,4.3-4.8c1.7-0.4,2.8,0.4,3,2.2v2.6 M560,258l11.3-1.6v-6.6h-2.5 M568.8,248.9h-3.4 M565.4,249.8h3.4
	 M565.4,248.9v0.8 M568.8,248.9h2.1v-5.6 M568.8,249.8v-0.8 M565.4,249.8H560 M546.3,232.7l1.4,10.7 M555.9,258.6l4.1-0.5 M553,265
	h2.9v-6.5 M551.9,259.1c0.1,0.7-0.1,1.6-1,2.6 M550.9,261.9l-1.2,1.2h-0.8 M548.7,260.2v0.5 M548.7,260.8l0.1,2.3 M547.8,260.9
	l1-0.1 M549.4,272.5v-5.4c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.4c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7v5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M553,265h-1v-0.7h-1.1v-2.5 M553.7,276v-4.5c0-0.8-0.3-1.2-0.5-1.4
	 M551.8,275.8v-3.3 M552.3,275.8h-0.5 M553,270.1l-0.7-0.1v5.8 M549.4,275.4l-0.7-0.1 M549.4,272.5v2.9 M551.8,272.5h-2.3
	 M553.7,276l-1.4-0.3 M551.8,275.8l-2.3-0.4 M548.9,263.1l-0.1,12.1 M553,270.1V265 M555.9,258.6l-4,0.5 M575.8,292.5
	c-0.4,0.1-0.8,0.5-1,1.5s-0.1,2.1,0,3.3c0.1,1.2,0.5,2.3,1,3.2s0.8,1.2,1.4,1.2c0.5-0.1,1-0.7,1.2-1.5c0.3-1,0.4-2.1,0.1-3.3
	c-0.1-1.2-0.6-2.3-1.1-3.2C577.1,292.8,576.4,292.5,575.8,292.5L575.8,292.5z M578.9,291.7v-2.3l-3.3-6.7c-1.1-2.3-3-3.7-5.6-4
	l-16.4-2.6 M580.1,315v-20.2l-1.2-3 M546.8,245.8l1.9,14.4 M560,249.8v8.3 M548.7,260.2l-0.8-14.6 M546.5,243.2l-3-23.2
	 M544.9,222.4l0.4,2.9 M547.8,260.9c-1.9,0.4-3.9,0.8-5.6,1.2 M411.3,299.7h-12.4 M411.3,297.1h-12.4 M411.3,304.4h-12.4
	 M411.3,303.3h-12.4 M411.3,300.9h-12.4 M415.4,281.8v1.2 M394.5,283.1v-1.2 M398.9,279.5h12.4 M372.9,156.9
	c-1.1-0.1-1.9-0.6-2.3-1.5l-6.2,0.1c-0.4,0.8-1.1,1.4-2.1,1.6 M362.3,157.2l10.6-0.3 M325.8,158.7l-11.4,0.7l-6.7,1
	c-2.1,0.1-3.7,2.2-4.7,6.5 M336.8,158.1c-1-0.3-1.6-0.8-1.9-1.5l-7.2,0.4c-0.3,0.8-0.8,1.4-1.8,1.6 M325.8,158.7l11-0.6
	 M362.3,157.2l-25.6,1 M291.4,247.2v-97.4c-0.1-0.1-0.1-0.3-0.1-0.4v-1.1c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1
	s0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.1l-0.1,0.4H292v93.1 M305.5,169l-2.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M303.1,166.7l-0.3,1.2l-10.7,75 M297.3,222.4l5.9-45.7l-1-1l-9.9,70.4
	 M305.5,169c-0.3,0.7-0.5,1.5-0.8,2.5l-6.2,48.4 M306.1,217.3c-2.5,0-5,0.8-7.6,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M296.3,230l-0.8,0.1l-0.1,1.8l0.5,0.8 M296.3,230l0.5-4.7 M295.9,232.7
	l0.4-2.8 M297.3,222.4l-0.8,0.6l-0.1,1.5l0.5,0.8 M320.9,226.7l-13.3,0.3c-1.9,0-2.8-0.7-2.6-2.1l1.1-7.6 M296.9,225.3l0.4-2.9
	 M276,222.3v11.8c0,1.6-0.8,2.5-2.5,2.5h-19.1c-1.7,0-2.5-0.8-2.5-2.5v-11.8c0-1.7,0.8-2.5,2.5-2.5h19.1
	C275.1,219.9,276,220.6,276,222.3L276,222.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M271.3,243.3h-16.9c-3.9,0.1-5.8-1.6-5.6-5.4v-11.7c0-3.9,0.1-6.9,0.6-8.9
	c0.6-1.8,1.8-2.6,4-2.6c8.8-0.7,18.4-0.8,28.8-0.4c2.8,0.3,4.1,2.1,4.1,5.2v19c0.1,2.9-1.8,4.5-5.6,4.8h-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M278.7,243.3c0.1-2.6-1.2-4.3-4.3-4.8c-1.7-0.4-2.8,0.4-3,2.2v2.6
	 M276.6,249.8v-0.8 M276.6,249.8h-3.4 M273.2,248.9h3.4 M273.2,248.9h-1.9v-5.6 M282.1,258l-11.4-1.6v-6.6h2.5 M273.2,249.8v-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M276.6,248.9h2.1v-5.6 M282.1,249.8h-5.5 M304,236.1
	c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
	c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3C304.2,236.8,304,236.6,304,236.1L304,236.1z M295.6,243.2l-1.1,0.3 M294.5,243.4
	l-0.3,1.6 M291.4,247.2c-0.4,0.7-0.8,1.5-1.1,2.6 M292.3,246.2l-0.3,0.1 M294.2,245.6h-0.8c-0.4,0.1-0.7,0.3-1,0.6 M290.7,258.4
	c-0.7-3.2-0.5-6.1,0.5-8.7h-1 M294.2,245.1v0.6 M292,246.3v-3.4 M294.2,245.1l1,0.7 M295.6,243.2l-0.4,2.6 M294.5,243.4l1.4-10.7
	 M298.5,219.9l-2.9,23.2 M290.3,249.8h-8.1 M263.4,296.8c0.1-1.2,0.5-2.3,1.1-3.2c0.5-0.8,1.1-1.1,1.6-1.1c0.4,0.1,0.8,0.5,1,1.5
	s0.1,2.1,0,3.3c-0.1,1.2-0.5,2.3-1,3.2c-0.4,0.8-0.8,1.2-1.4,1.2c-0.5-0.1-1.1-0.7-1.2-1.5C263.3,299.2,263.3,298.1,263.4,296.8z
	 M262.1,315v-20.2l1.2-3 M263.3,291.7v-2.3l3.3-6.7c1.1-2.3,3-3.7,5.6-4l16.4-2.6 M292,257.7c0.4,0,0.5,0.1,0.5,0.5v1.5
	 M292.6,259.8c0.4,0,0.5,0.1,0.5,0.5v0.8c0,0.3-0.1,0.5-0.5,0.5 M293.4,260.8l0.8,0.1 M292.5,263.1h0.8 M291.2,263.1h1.2
	 M292.6,261.7v0.8c0,0.3-0.1,0.4-0.3,0.4 M293.3,263.1l0.1-2.3 M293.4,260.2v0.5 M291.4,257.7c-0.4,0-0.5,0.1-0.5,0.5v0.1
	 M290.7,259.8h-0.1c-0.1,0-0.3,0-0.3,0.1 M290.1,259.9c-0.1-0.3-0.1-0.5,0-0.8 M290.7,261.7h-0.1c-0.3,0-0.5-0.1-0.5-0.5v-0.8
	c0-0.1,0-0.3,0.1-0.4 M290.7,259.3v0.5 M290.1,259.1l0.5,0.1 M290.7,259.3v-0.8 M291.2,263.1c-0.3,0-0.5-0.1-0.5-0.5v-0.8 M289,265
	h1.1v-0.7h1.1v-1.2 M292.6,272.5v-5.4c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3h-0.4c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v5.4
	 M292.6,259.8h-1.9 M291.4,257.7h0.7 M292.6,261.7h-1.9 M292.6,275.4v-2.9 M293.4,275.2l-0.7,0.1 M289.7,275.8V270l-0.7,0.1
	 M290.3,275.8l-0.5,0.1 M290.3,272.5v3.2 M288.5,276v-4.5c0-0.8,0.3-1.2,0.5-1.4 M289.7,275.8l-1.4,0.3 M290.3,272.5h2.3
	 M290.3,275.8l2.3-0.4 M294.2,260.9c1.9,0.4,3.9,0.8,5.6,1.2 M293.4,275.2l-0.1-12.1 M313,274.9c-3-1.4-6.3-1.8-9.6-1.4l-9.9,1.6
	 M289,270.1V265 M289,265h-2.9v-6.5 M286.1,258.6l-4-0.5 M290.1,259.1l-4-0.5 M282.1,249.8v8.3 M293.4,260.2l0.8-14.6 M295.2,245.8
	l-1.9,14.6 M291.4,247.2v10.6 M365.8,239.7c-15.5,0.4-30.3,1.4-44.3,2.9c-8.9,1.1-14.9,4.1-17.6,9.1c-1.5,2.8-2.8,6.2-4,10.5
	 M292,246.3v11.4 M354.4,236.7c-0.1-0.1-0.4-0.3-0.7-0.3l-9.5,0.6 M322.3,270.3c3.7-8,12.1-12.2,25.3-12.7
	c21.2-1.2,43.7-1.8,67.7-1.8 M365.8,239.7l-1.2,4.7c3,0.3,5.5,0.1,7.6-0.4l-1-4.4 M344.3,239.2l9.5-0.6c0.3,0,0.5-0.1,0.7-0.4
	s0.3-0.6,0.3-0.8s-0.1-0.6-0.3-0.8 M371.1,239.6c-1.8-0.6-3.6-0.6-5.4,0.1 M315.3,231.2c0,0.3-0.1,0.6-0.3,0.8
	c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3
	c0.3,0,0.5,0.1,0.8,0.3C315.2,230.6,315.3,230.9,315.3,231.2L315.3,231.2z M320.9,231.2v-4.5 M324.1,256.2l-1.4,0.1l-3,2.1
	c0,0.8-0.5,1.8-1.6,2.9l1,0.3 M324.5,260.4l0.4-0.5l-1.4-0.8l1.8-2.3l-1.2-0.3 M294.3,260.9c2.8-12.1,8-18.8,15.7-20.4
	c2.8-0.6,6.3-1.1,10.7-1.6 M344.2,237l-9.5,0.6c-0.3,0-0.4,0.1-0.5,0.3 M334.1,237.8c-0.3,0.3-0.3,0.6-0.3,0.8
	c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.4,0.3,0.7,0.3l9.5-0.6 M344.3,239.2l-0.1-2.2 M320.7,238.9l0.3-7.8 M334.1,237.8l-13.5,1.2
	 M398.9,281.8v1.2 M342.3,261c5,0,9.2,1.8,12.7,5.2c3.4,3.4,5.2,7.7,5.2,12.7c0,5-1.8,9.2-5.2,12.7c-3.4,3.6-7.7,5.2-12.7,5.2
	c-5,0-9.2-1.8-12.7-5.2s-5.2-7.7-5.2-12.7s1.8-9.2,5.2-12.7C333.2,262.8,337.3,261.1,342.3,261L342.3,261z M342.3,262.3
	c4.7,0,8.5,1.6,11.8,4.8c3.3,3.3,4.8,7.2,4.8,11.8c0,4.7-1.6,8.5-4.8,11.8c-3.3,3.3-7.2,5-11.8,5c-4.5,0-8.5-1.6-11.8-5
	c-3.3-3.3-5-7.2-5-11.8s1.6-8.5,5-11.8C333.7,263.9,337.7,262.3,342.3,262.3L342.3,262.3z M411.3,283.1v-1.2 M323.5,261.5l0.4,0.1
	c0.1,0,0.4-0.1,0.5-0.3l0.3-0.5c0-0.1-0.1-0.4-0.3-0.5 M324.5,260.4c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.4,0.1-0.5,0.3
	s-0.3,0.3-0.3,0.5c0,0.1,0.1,0.3,0.1,0.5l0.1,0.1 M322.7,262.6l0.8-1.1 M319.1,261.7l0.1,0.1 M319.3,261.7L319,262 M319,262.2
	l-1.6,0.3l-3.2,3.6l1.4,0.3 M322.7,262.6l-1.4-0.1l-2.1-0.7 M319.1,261.7c1.1-1.2,1.7-2.3,1.7-3.2l3.4-2.3 M317.5,267l0.4,0.1h1.6
	l3.3-4.4 M317.2,267.9l0.1-1.1 M320,269.3l-2.8-1.2 M315.4,266.3l0.3,0.1 M315.7,266.4l-0.1,1 M314.3,272.5h-1.5v2.5 M314.3,272.5
	l2.1,0.5 M314.3,275.6l0.1-3.2 M313,274.9l1.4,0.7 M321.9,289.5c-0.4-6.7-0.4-13.1,0.1-18.7l0.3-0.5 M317.6,274.9l0.4,0.1
	c0.1,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.4-0.3,0.5l-0.4,0.3 M319.1,280.3c0-3.4,0.1-6.7,0.4-9.9v-0.3l0.3-0.7 M316.4,273.2l1.4,0.4
	v1.4 M317.6,276.3l-0.4-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3h0.1 M321.9,289.5
	c-0.5-2.9-1.1-5.2-1.8-7l-0.3-0.8c-0.1-0.5-0.4-1-0.7-1.4 M317.6,278.2c0.7,0.7,1.1,1.2,1.5,1.9 M317.6,276.3l-0.1,1.9 M315.6,267.2
	l-1.1,5.2 M322.3,270.3l-2.3-1 M314.3,275.6c1.4,0.8,2.5,1.6,3.3,2.6 M317.2,267.9l-0.8,5.1 M319,262.1l-3.4,4.1 M317.5,267
	l-1.6-0.5 M323.4,303.1c-0.4-5.4-0.8-9.9-1.5-13.5 M323.4,303.1l0.1,2.1h-0.1 M323.4,305.2l-0.5,0.4 M344.6,302.9
	c1.6,1.7,2.5,3.6,2.5,5.9s-0.8,4.3-2.5,5.9c-1.6,1.6-3.6,2.5-5.9,2.5c-2.3,0-4.3-0.8-5.9-2.5c-1.6-1.7-2.5-3.6-2.5-5.9
	s0.8-4.3,2.5-5.9c1.6-1.6,3.6-2.5,5.9-2.5C341,300.4,343,301.2,344.6,302.9L344.6,302.9z M344.1,303.4c1.5,1.5,2.2,3.3,2.2,5.4
	c0,2.1-0.7,3.9-2.2,5.4s-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2s-2.2-3.3-2.2-5.4c0-2.1,0.7-3.9,2.2-5.4s3.3-2.2,5.4-2.2
	S342.5,302,344.1,303.4z M378.2,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4v6.7 M376.9,278.2v1.2 M376.9,279.5v2.3 M378.2,281.8v-2.3 M378.2,279.5v-1.2 M378.2,278.2h-1.2
	 M378.2,281.8h-1.2 M376.9,279.5h1.2 M365.8,280.4l3.2-4.1h8v1.9 M382.2,279.5v2.3 M382.2,278.2v1.2 M382.2,283.1v2.6 M382.2,285.5
	v1.2 M378.2,286.8v-1.2 M378.2,285.5v-2.6 M378.2,283.1h-1.2 M378.2,286.8h-0.8 M378.2,285.5h-0.8 M378.2,290.2V289 M378.2,292.4
	v-2.2 M378.2,293.6v-1.2 M376.9,292.4v1.2 M378.2,293.6h-1.2 M376.9,292.4h1.2 M378.2,289.1h-0.8 M378.2,290.2h-0.8 M382.2,289.1
	v1.2 M382.2,292.4v1.2 M382.2,290.2v2.2 M378.2,289.1v-2.3 M382.2,286.8v2.3 M376.9,283.1v9.4 M382.2,296v1.2 M382.2,297.1v2.6
	 M378.2,299.7h-1.2 M378.2,296h-1.2 M378.2,297.1h-1.2 M376.9,296v1.2 M376.9,297.1v2.6 M378.2,297.1v-1.2 M378.2,299.7v-2.6
	 M378.2,303.3v-2.3 M378.2,304.4v-1.2 M377.9,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M376.9,303.3v1.2 M376.9,300.9v2.3 M378.2,300.9
	h-1.2 M378.2,303.3h-1.2 M378.2,304.4h-1.2 M382.2,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M382.2,303.3v1.2 M382.2,300.9v2.3 M378.2,300.9
	v-1.2 M382.2,299.7v1.2 M376.9,299.7v1.2 M382.2,293.6v2.3 M378.2,296v-2.3 M376.9,293.6v2.3 M382.2,281.8v1.2 M365.8,280.4v25
	c0,1,0.5,1.8,1.8,2.5c1.2,0.7,2.6,1,4.4,1c1.7,0,3.2-0.3,4.4-1l0.7-0.4 M378.2,283.1v-1.2 M376.9,281.8v1.2 M320.9,231.2l49.8-1.5
	h19 M299.9,262.1c5.5,1.4,10.7,3,15.5,5.1 M377.9,306.4h-1 M376.9,306.4v1.1 M376.9,307.5c0.4-0.3,0.8-0.7,1-1.1 M382.4,306.4
	c0.1,0.4,0.5,0.8,1.1,1.2 M383.5,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.8,0,3.2-0.3,4.4-1l0.5-0.3 M352.4,324.3l-1.5-0.3h-25.6
	 M356.7,326.4c-1.4-1-2.8-1.6-4.3-2.1 M371.8,332v22.1c0,0.7,0.3,1,1,1h96.6c0.7,0,1-0.3,1-1V332c0-0.7-0.3-1-1-1h-96.6
	C372.1,331.1,371.8,331.3,371.8,332L371.8,332z M376.5,353.2h89c0.7,0,1-0.3,1-1v-18.6c0-0.7-0.3-1-1-1h-89c-0.7,0-1,0.3-1,1v18.6
	C375.6,352.9,375.8,353.2,376.5,353.2L376.5,353.2z M415.4,293.6v2.3 M324.4,315c1,1.5,1.9,2.8,3.2,3.9c1.6,1.4,3.7,2.1,6.2,2.3
	 M334.3,321.2h11.1 M323.8,308.6c1.6,5.2,4.1,8.7,7.3,10.3c2.6,1.2,7.3,2.1,14.3,2.2 M322.9,305.6l-1.9,1.4l0.1,1.8 M325.2,324
	l-0.8-9.1 M323.8,308.6l0.5,6.3 M321.2,308.8l2.6-0.1 M315.6,324.5c-1.2,0.4-2.6,1-4.1,1.9 M325.2,324h-7.6l-1.9,0.3 M322.9,305.6
	l1,2.8v0.3 M342.5,364.9v3.3 M318.2,365l0.7,0.3l23.8-0.4 M308.6,365h9.6 M308.6,368.2V365 M308.6,377.1v-9.1 M318.2,365
	c-1.5-0.7-3-1.2-4.5-1.5 M308.6,365V363 M308.6,362.8l5.1,0.5 M342.5,368.2h-34 M308.6,381.5v-4.3 M376.8,368.2h1.8 M376.8,368.2
	v-5.1 M378.4,363.1v5.1 M376.8,363.1h1.8 M354,364.6c0.8-0.1,1.7-0.4,2.5-1l1.6-0.7 M350.1,368.2c0.1-2.1,0.5-3.2,1.1-3.3
	 M351.1,364.9l2.8-0.1 M350.1,368.2h26.7 M358.1,363.1h18.6 M342.5,368.2h7.4 M313.7,363.5l-2.1-37.1 M315.6,324.5l2.6,40.6
	 M352.4,324.3l1.5,40.3 M358.1,363.1l-1.4-36.7 M342.5,364.9h8.5 M311.5,326.4l-37.3,1.7c-1.6,0-2.6,0.8-2.6,2.6l0.4,17.6
	c0.5,4.7,1.5,8.3,2.8,10.9 M282.3,336c1.7-1.6,3.9-2.6,6.2-2.6c2.5,0,4.5,0.8,6.2,2.6c1.7,1.8,2.6,3.9,2.6,6.2
	c0,2.5-0.8,4.5-2.6,6.2c-1.8,1.8-3.9,2.6-6.2,2.6c-2.5,0-4.5-0.8-6.2-2.6c-1.8-1.6-2.6-3.9-2.6-6.2
	C279.7,339.9,280.5,337.8,282.3,336L282.3,336z M283.4,337.3c1.4-1.4,3-2.1,5.1-2.1c1.9,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3,2.1,5.1
	c0,1.9-0.7,3.7-2.1,5.1c-1.4,1.4-3,2.1-5.1,2.1c-1.9,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3-2.1-5.1C281.3,340.3,282,338.6,283.4,337.3
	L283.4,337.3z M287,340.7c0.4-0.4,1-0.5,1.5-0.5s1.1,0.1,1.5,0.5c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5c-0.4,0.4-1,0.7-1.5,0.7
	s-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-1-0.7-1.5S286.6,341.1,287,340.7L287,340.7z M263.3,314v-2.9 M263.3,314l-2.9,3
	c-0.5,0.4-1,1.9-1.2,4.3V432c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1
	c4-1.2,6.2-3.9,6.6-7.7v-50.5 M263.3,308.1v2.9 M274.7,359h-6.9c-1.1,0-1.9-0.3-2.1-0.7c-1.6-4.5-2.5-8.3-2.5-10.9V314 M308.6,362.8
	l-28.6-2.5c-2.8-0.1-4.4-0.5-5.2-1.4 M323.4,305.2l-56,2.9h-4.3 M263.3,311.1l5.1,0.1l52.8-2.5 M411.3,296v-2.3 M415.4,299.7v1.2
	 M411.3,300.9v-1.2 M411,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M411.3,304.4v-1.1 M411.3,303.3v-2.3 M415.4,304.4v1c0,0.4,0.1,0.7,0.3,1
	 M415.4,303.3v1.1 M578.9,308.1h-4.3l-55.8-2.9 M578.9,311.1l-5.2,0.1l-52.7-2.5 M578.9,308.1v3 M578.9,314v-2.9 M533.6,381.6V432
	c0.4,3.9,2.6,6.5,6.6,7.7c4.8,0.8,10.5,1.2,16.9,1.1h2.2c6.5,0.1,12.2-0.3,16.9-1.1c4-1.2,6.2-3.9,6.6-7.7V321.3
	c-0.1-2.3-0.5-3.7-1.2-4.3l-2.9-3 M555.2,340.7c-0.4-0.4-1-0.5-1.5-0.5c-0.5,0-1.1,0.1-1.5,0.5c-0.4,0.4-0.7,1-0.7,1.5
	s0.3,1.1,0.7,1.5c0.4,0.4,1,0.7,1.5,0.7c0.5,0,1.1-0.3,1.5-0.7s0.7-1,0.7-1.5C555.8,341.7,555.6,341.1,555.2,340.7L555.2,340.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M558.8,337.3c-1.4-1.4-3-2.1-5.1-2.1c-1.9,0-3.7,0.7-5.1,2.1
	c-1.4,1.4-2.1,3-2.1,5.1c0,1.9,0.7,3.7,2.1,5.1c1.4,1.4,3,2.1,5.1,2.1c1.9,0,3.7-0.7,5.1-2.1c1.4-1.4,2.1-3,2.1-5.1
	C560.8,340.3,560.2,338.6,558.8,337.3L558.8,337.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M559.9,336c-1.7-1.6-3.9-2.6-6.2-2.6c-2.5,0-4.5,0.8-6.2,2.6
	c-1.7,1.8-2.6,3.9-2.6,6.2c0,2.5,0.8,4.5,2.6,6.2c1.8,1.8,3.9,2.6,6.2,2.6c2.5,0,4.5-0.8,6.2-2.6c1.8-1.6,2.6-3.9,2.6-6.2
	C562.5,339.9,561.7,337.8,559.9,336L559.9,336z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M567.5,359c1.2-2.6,2.1-6.2,2.8-10.9l0.4-17.6c0-1.6-1-2.5-2.6-2.6
	l-37.3-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M578.9,314v33.4c0,2.6-0.8,6.3-2.5,10.9c-0.3,0.4-1,0.7-2.1,0.7h-6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M533.6,362.8l28.6-2.5c2.8-0.1,4.4-0.5,5.4-1.4 M530.6,326.4
	c-1.5-1-2.9-1.6-4.1-1.9 M517.8,315l0.4-6.3 M521,308.8l0.1-1.8l-1.8-1.4 M518.2,308.6l0.1-0.3l1-2.8 M521,308.8l-2.8-0.1
	 M508.3,321c2.6-0.3,4.7-1,6.2-2.3c1.2-1.1,2.3-2.3,3.2-3.9 M526.5,324.5l-1.9-0.4H517 M517.8,315l-0.8,9.1 M496.6,321.2
	c7-0.3,11.7-1,14.3-2.2c3.2-1.6,5.6-5,7.3-10.3 M415.4,300.9v2.3 M517,324.1h-25.7l-1.5,0.3 M489.7,324.3c-1.5,0.3-3,1-4.3,2.1
	 M488.2,364.6l1,0.1c1,0.1,2.1,0.1,3.2,0l7.3,0.1 M484.1,363.1l1.6,0.7c0.8,0.4,1.6,0.8,2.5,1 M499.5,364.9v3.3 M533.6,368.2v8.9
	 M533.6,365v-2.2 M533.6,368.2V365 M528.5,363.5l5.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524,365l-0.7,0.3l-23.8-0.4 M533.6,377.2v4.4 M524,365
	c1.5-0.7,3-1.2,4.5-1.5 M533.6,365H524 M496.6,321.2h11.3 M499.5,368.2h34.1 M526.5,324.5L524,365 M488.2,364.6l1.5-40.3
	 M484.1,363.1l1.4-36.7 M528.5,363.5l2.1-37.1 M465.2,307.5c-0.4-0.3-0.8-0.7-1-1.1 M465.2,307.5v-1.1 M458.6,307.5
	c0.5-0.4,0.8-0.8,1.1-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M448.9,307.5l0.6,0.3c1.2,0.7,2.6,1,4.4,1c1.7,0,3.2-0.3,4.4-1l0.5-0.3
	 M465.2,306.4h-1 M447.8,306.4c0.1,0.4,0.5,0.8,1.1,1.2 M459.7,306.4h-12 M448.9,307.5h9.8 M442,307.5c0.5-0.4,0.8-0.8,1.1-1.2
	 M432.1,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1c1.6,0,3.2-0.3,4.4-1l0.5-0.3 M431.1,306.4c0.1,0.4,0.5,0.8,1.1,1.2 M443.1,306.4h-12
	 M432.1,307.5h9.9 M421.9,308.8c1.1-0.1,2.2-0.4,3-1l0.5-0.3 M421.1,308.9h0.4l0.4-0.1 M421.1,308.9l-0.8-0.1 M416.7,307.5l0.5,0.3
	c1,0.5,1.9,0.8,3.2,1 M420.3,308.8l0.3,0.1h0.5 M421.9,308.8l-0.8,0.1 M425.5,307.5h-8.8 M400.2,307.5l0.5,0.3c1.2,0.7,2.6,1,4.4,1
	c1.6,0,3.2-0.3,4.4-1l0.5-0.3 M409.9,307.5c0.5-0.4,0.8-0.8,1.1-1.2 M415.6,306.4c0.3,0.4,0.5,0.8,1.1,1.2 M399.1,306.4
	c0.1,0.4,0.5,0.8,1.1,1.2 M393.3,307.5c0.5-0.4,0.8-0.8,1.1-1.2 M411,306.4h-12 M409.9,307.5h-9.8 M425.5,307.5
	c0.5-0.4,0.8-0.8,1.1-1.2 M426.4,306.4h-10.9 M415.4,296v1.1 M415.4,297.1v2.6 M411.3,297.1V296 M411.3,299.7v-2.6 M394.5,297.1V296
	 M394.5,290.2v-1.1 M394.5,292.4v-2.2 M394.5,293.6v-1.2 M394.5,289.1v-2.3 M394.5,286.8v-1.2 M394.5,285.5v-2.5 M415.4,286.8v2.3
	 M411.3,289.1v-2.3 M415.4,292.4v1.2 M415.4,290.2v2.2 M411.3,293.6v-1.2 M411.3,292.4v-2.2 M411.3,290.2v-1.1 M415.4,289.1v1.1
	 M396.6,156.4c-7.8,0.1-15.7,0.3-23.7,0.4 M394.5,278.2h-12.2 M465.2,306.4v-2.1 M578.9,291.7v16.4 M263.3,308.1v-16.4 M376.9,304.4
	v2.1 M485.5,326.4H356.7 M533.6,377.2H308.6 M378.4,368.2h121.1 M533.6,381.6H308.6 M378.4,363.1h105.6 M394.4,306.4h-12
	 M393.3,307.5h-9.8 M394.5,281.8h-12.2 M394.5,279.5h-12.2 M394.5,285.5h-12.2 M394.5,283.1h-12.2 M394.5,286.8h-12.2 M394.5,290.2
	h-12.2 M394.5,289.1h-12.2 M394.5,292.4h-12.2 M394.5,293.6h-12.2 M394.5,297.1h-12.2 M394.5,296h-12.2 M411.3,286.8v-1.2
	 M394.5,299.7h-12.2 M394.5,303.3h-12.2 M394.5,304.4h-12.2 M345.4,321.2h151.2 M398.9,290.2v2.2 M398.9,292.4v1.2 M398.9,289.1v1.1
	 M398.9,286.8v2.3 M398.9,285.5v1.2 M398.9,283.1v2.5 M398.9,278.2v1.2 M398.9,279.5v2.3 M394.5,279.5v-1.2 M394.5,281.8v-2.3
	 M394.5,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8c-1.2,1.2-1.8,2.6-1.8,4.4v4.7
	 M411.3,279.5v-1.2 M411.3,278.2v-4.7c0-1.6-0.5-3.2-1.8-4.4c-1.2-1.2-2.6-1.8-4.4-1.8c-1.7,0-3.2,0.5-4.4,1.8
	c-1.2,1.2-1.8,2.6-1.8,4.4v4.7 M411.3,281.8v-2.3 M415.4,278.2v1.2 M415.4,279.5v2.3 M421.1,266.1l0.7,0.1 M421.8,266.3
	c-0.1-0.1-0.1-0.1-0.3-0.1h-0.5 M421.8,266.3c1.2,0.3,2.2,0.8,3.2,1.8c1.2,1.2,1.8,2.8,1.8,4.5v5.8 M415.4,278.2v-5.8
	c0-1.8,0.5-3.3,1.8-4.5c1-1,1.9-1.5,3.2-1.8 M421.1,266.1l-0.8,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.3,266.3c0.1-0.1,0.1-0.1,0.3-0.1h0.5 M415.2,255.8h2.5 M417.6,255.8
	h6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M320,269.3c4-8.9,13.1-13.6,27.5-14.2c22-1.2,45.7-1.9,70.8-1.8h5.5
	c25.2-0.1,48.8,0.6,70.8,1.8c14.3,0.4,23.5,5.2,27.5,14.2 M415.2,255.8h2.5 M487.8,236.7l-2.1-0.1c-21.7-1.1-42.8-1.5-63.3-1.5h-2.8
	c-20.5-0.1-41.5,0.4-63.4,1.5l-2.1,0.1 M470.9,239.6c-16.6-0.7-32.2-1-46.8-1h-6.1c-14.6-0.1-30.1,0.3-46.8,1 M389.7,230.8v-1
	 M389.7,229.8h-0.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.3-0.1,0.3v0.1c0,0.1,0,0.3,0.1,0.3l0.3,0.1h0.1 M389.7,229.5v0.1
	 M424.5,255.8h2.5 M429.1,243.4c0.1-0.8,0-1.5-0.1-1.9c-0.1-0.3-0.3-0.4-0.5-0.3h-4.5l-0.1,0.3c-0.1,0.1-0.4,0.3-0.7,0.3h-4
	c-0.3,0-0.5-0.1-0.7-0.3l-0.1-0.3h-4.5c-0.3,0-0.5,0.1-0.5,0.3c-0.1,0.4-0.1,1-0.1,1.9l-0.1,0.4l-0.3,0.1l-0.4-0.1l-0.1-0.3
	c-0.1-1.1,0-1.9,0.3-2.3c0.3-0.7,0.8-1,1.5-1h4.4l0.1-0.1c0.1-0.1,0.4-0.3,0.7-0.3h4c0.3,0,0.5,0.1,0.7,0.3l0.1,0.1h4.4
	c0.7,0,1.2,0.3,1.5,1c0.3,0.6,0.3,1.2,0.3,2.3l-0.1,0.3l-0.4,0.1l-0.3-0.1C429.2,243.7,429.1,243.6,429.1,243.4L429.1,243.4z
	 M452.4,229.8v1.1 M389.7,230.8c0,0.3,0.1,0.6,0.5,0.6H452c0.3,0,0.5-0.1,0.5-0.6 M452.4,230.8h0.1c0.1,0,0.3,0,0.3-0.1l0.1-0.3
	v-0.1l-0.1-0.3c-0.1-0.1-0.3-0.1-0.3-0.1h-0.1 M452.4,229.5c0-0.3-0.1-0.4-0.5-0.4h-61.8c-0.3,0-0.4,0.1-0.5,0.4 M452.4,229.8v-0.3
	 M433.5,156.4h-24.9 M396.6,156.4h12 M408.6,156.4c-1.5-0.3-2.3-0.7-2.6-1.5h-6.6c-0.3,1-1.1,1.5-2.6,1.6 M433.5,156.4h12
	 M445.4,156.4c-1.4-0.3-2.3-0.8-2.6-1.6h-6.6c-0.4,1-1.2,1.5-2.6,1.5 M516.3,158.7l-10.9-0.6 M536.1,217.3l-5.9-41.7
	c-1.1-4.4-3.3-6.6-6.5-6.5c-30-1.6-59.4-2.6-88.5-2.9H407c-29,0.3-58.5,1.2-88.5,2.9c-3.2-0.1-5.4,2.1-6.5,6.5l-5.9,41.7
	 M411.3,285.5v-2.5 M543.5,219.9l-6.2-48.4c-0.3-1-0.5-1.6-0.8-2.5 M415.4,283.1v2.5 M415.4,285.5v1.2 M394.5,296v-2.3 M398.9,293.6
	v2.3 M398.9,304.4v1.1c0,0.3,0.1,0.5,0.3,1 M398.9,303.3v1.1 M549.8,246.2l-10-70.4l-0.8,1l5.9,45.7 M539.1,166.7l-2.6,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M536.5,169c-1.4-2.9-3.9-4.4-7.4-4.7c-34.7-2.2-68.9-3.3-102.8-3.3h-5.9
	c-35.5,0-71.3,1.1-107.4,3.3c-3.6,0.3-6.1,1.8-7.4,4.7 M550.7,247l-11.3-79.1l-0.3-1.2 M505.4,158.1l-25.6-1 M398.9,300.9v2.3
	 M398.9,299.7v1.2 M398.9,297.1v2.6 M398.9,296v1.1 M394.4,306.4c0.1-0.3,0.1-0.5,0.1-1v-1.1 M394.5,304.4v-1.1 M394.5,300.9h-12.2
	 M394.5,303.3v-2.3 M479.8,157.2l-10.6-0.3 M469.2,156.9c1.1-0.1,1.9-0.6,2.3-1.5l6.2,0.1c0.4,0.8,1.1,1.4,2.1,1.6 M539.1,166.7
	c-1-4.3-2.6-6.5-4.7-6.5l-6.7-1l-11.4-0.7 M505.4,158.1c1-0.3,1.6-0.8,1.9-1.5l7.2,0.4c0.3,0.8,0.8,1.4,1.8,1.6"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M307.9,226.9
		c0,0-2.6,0.3-2.9-1.7l7-49.5c0,0,1.5-6.2,5.9-6.5c0,0,42.4-2.9,103.2-2.9c0,0,53.2-0.3,103.4,2.9c0,0,4.1,0,5.6,6.5l7,49.1
		c0,0,0.5,2.1-2.2,2.1c0,0-67.4-2.3-113.8-1.5C421.1,225.4,368.3,225,307.9,226.9z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M294.2,260.9
		c0,0,2.8-14.4,11.3-18.8c0,0,1.7-1.1,4.8-1.7c0,0,4.8-1.4,23.8-2.6c0,0-0.8,1.7,0.8,1.9l18.8-1c0,0,1.4-0.4,0.5-1.9
		c0,0,37.8-1.9,66.7-1.7c0,0,35.2-0.3,66.7,1.7c0,0-1,1.4,0.6,1.9l19,1c0,0,1.5-0.1,0.7-1.9l13.5,1.1c0,0,8.9,1.1,11.8,1.9
		c2.9,0.8,8.4,3.2,12.2,12.5c0,0,1.7,4,2.3,7.6c0,0-7,1.4-11.6,2.9s-9.5,3.3-16.4,6.5c0,0-3.9-11.6-22.7-12.5c0,0-33.8-2.1-76.2-1.9
		c0,0-31.4-0.6-73.5,1.8c0,0-7.7,0-14,2.5c0,0-7.8,2.6-11.3,10.2l-2.3-1c0,0-5.5-2.8-10.5-4.3C309.5,264.9,302.1,262.3,294.2,260.9z
		"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M520.1,270.8
		c-2.3-6.7-9.5-9.9-9.5-9.9c-5.8-2.9-13.5-3.2-13.5-3.2c-41-2.3-76.2-1.9-76.2-1.9c-28.8-0.7-73.5,1.8-73.5,1.8
		c-11.6,0.5-16,3.4-16,3.4c-7.4,3.7-9.6,9.8-9.6,9.8c-0.5,6.6-0.4,17.1-0.4,17.1l0.3,1.8c1.2,7.7,1.5,13.5,1.5,13.5l0.3,2.5
		c1.2,4.8,3,7.4,3,7.4c3,5,6.5,5.8,6.5,5.8c4.4,1.5,16.5,1.4,16.5,1.4h4.3h67.1h74l5.1-0.1c7.7-0.1,10.2-1.8,10.2-1.8
		c6.1-2.9,8.1-12.7,8.1-12.7l0.3-2.5c0.8-9.9,1.7-14,1.7-14C521,281.1,520.1,270.8,520.1,270.8z M342.3,297c-9.9,0-18-8-18-18
		c0-9.9,8-18,18-18c9.9,0,18,8,18,18C360.3,289,352.2,297,342.3,297z M499.9,297c-9.9,0-18-8.1-18-18s8.1-18,18-18s18,8.1,18,18
		S509.8,297,499.9,297z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="342.3"
              cy="279.1"
              r="18"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="499.9"
              cy="278.9"
              r="18"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M274.7,359
		c0,0-1.8-3.3-2.8-10.9l-0.4-17.6c0,0-0.3-2.5,2.6-2.6l37.3-1.6c0,0,3-2.2,5.8-2.2h33.6c0,0,2.6-0.1,5.9,2.2h128.8
		c0,0,2.9-2.2,5.9-2.2h33.3c0,0,2.5-0.1,6.1,2.2l37.3,1.6c0,0,2.8-0.3,2.6,2.6l-0.4,17.5c0,0-0.5,6.3-2.8,10.9c0,0-1.2,1.1-3.6,1.2
		l-30.3,2.6l-5.1,0.5c0,0-2.1,0.3-5.2,1.8l-31-0.6c0,0-2.8,0.3-4.1-0.1c0,0-1.4-0.3-2.5-1l-1.6-0.7h-63h-63l-1.6,0.7
		c0,0-1.2,0.7-2.5,1l-2.9,0.3l-32.2,0.4c0,0-2.8-1.4-5.2-1.8l-5.1-0.5l-28.6-2.5C279.9,360.4,276.6,360.5,274.7,359z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv2DrFront);
