import { FETCH_API } from '../../middleware';

export const PRINT_POST_ITEM_REQUEST = 'PRINT_POST_ITEM_REQUEST';
export const PRINT_POST_ITEM_SUCCESS = 'PRINT_POST_ITEM_SUCCESS';
export const PRINT_POST_ITEM_FAILURE = 'PRINT_POST_ITEM_FAILURE';

const fetchPrintPostItem = (body, apiKey, url) => ({
  [FETCH_API]: {
    types: [
      PRINT_POST_ITEM_REQUEST,
      PRINT_POST_ITEM_SUCCESS,
      PRINT_POST_ITEM_FAILURE,
    ],
    endpoint: `${url}/api/services/post-item?apiKey=${apiKey}`,
    init: {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    core: true,
  },
});

export const loadPrintPostItem = (body, apiKey, serviceUrl) => {
  return dispatch => dispatch(fetchPrintPostItem(body, apiKey, serviceUrl));
};

export const PRINT_RESET_POST_ITEM = 'PRINT_RESET_POST_ITEM';

export const resetPrintPostItem = () => ({
  type: PRINT_RESET_POST_ITEM,
});

export const DELETE_POST_ITEM_REQUEST = 'DELETE_POST_ITEM_REQUEST';
export const DELETE_POST_ITEM_SUCCESS = 'DELETE_POST_ITEM_SUCCESS';
export const DELETE_POST_ITEM_FAILURE = 'DELETE_POST_ITEM_FAILURE';

const fetchDeletePostItem = (body, apiKey) => ({
  [FETCH_API]: {
    types: [
      DELETE_POST_ITEM_REQUEST,
      DELETE_POST_ITEM_SUCCESS,
      DELETE_POST_ITEM_FAILURE,
    ],
    endpoint: `post-item?apiKey=${apiKey}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },

    body: JSON.stringify(body),
    core: true,
  },
});

export const loadDeletePostItem = (body, apiKey) => {
  return dispatch => dispatch(fetchDeletePostItem(body, apiKey));
};

export const DELETE_RESET_POST_ITEM = 'DELETE_RESET_POST_ITEM';

export const resetDeletePostItem = () => ({
  type: DELETE_RESET_POST_ITEM,
});

export const POST_ITEM_REQUEST = 'POST_ITEM_REQUEST';
export const POST_ITEM_SUCCESS = 'POST_ITEM_SUCCESS';
export const POST_ITEM_FAILURE = 'POST_ITEM_FAILURE';

const fetchPostItem = (body, apiKey) => ({
  [FETCH_API]: {
    types: [POST_ITEM_REQUEST, POST_ITEM_SUCCESS, POST_ITEM_FAILURE],
    endpoint: `post-item?apiKey=${apiKey}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    core: true,
  },
});

export const loadPostItem = (body, apiKey) => {
  return dispatch => dispatch(fetchPostItem(body, apiKey));
};

export const RESET_POST_ITEM = 'RESET_POST_ITEM';

export const resetPostItem = () => ({
  type: RESET_POST_ITEM,
});

export const SET_IS_VERIFIED = 'SET_IS_VERIFIED';

const handleIsVerified = data => {
  return {
    type: SET_IS_VERIFIED,
    response: data,
  };
};

export const setIsVerified = data => dispatch => {
  dispatch(handleIsVerified(data));
};
