import React, { Component } from 'react';
import { BLACK_02, GREY_06 } from '../../../constants/colors';
import Icon from '../../ui/icons';
import Accordion from './Accordion';
import Sidebar from './SideBar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleSideBarStatus } from '../../../../../actions';

class SideMenu extends Component {
  renderProgress() {
    const { isVerified } = this.props;

    return (
      <div className="progressbar-and-button-wrapper">
        <Icon name={isVerified ? 'CompleteStatus' : 'IncompleteStatus'} />
        <div className="status-wrapper-sidebar">
          <span style={{ fontWeight: '600', marginRight: 2 }}>
            Status&nbsp;:
          </span>
          <span>{isVerified ? 'Complete' : 'Incomplete'}</span>
        </div>
      </div>
    );
  }

  renderYMM() {
    const { item } = this.props;
    const ymm = `${item.year} ${item.make} ${item.model}`;

    return (
      <div style={{ paddingLeft: 20 }}>
        <div style={{ color: BLACK_02, fontSize: '1.2em', fontWeight: '600' }}>
          {ymm}
        </div>
        <div style={{ color: GREY_06 }}>{item.vin}</div>
      </div>
    );
  }

  render() {
    const { showSideBar, handleSideBarStatus } = this.props;

    return (
      <div className={`${showSideBar ? 'h-100 ' : ''}screen-container `}>
        <div className="progress-wrapper px-4">
          {false && this.renderProgress()}
          {true && this.renderYMM()}

          <div
            className="mobile-sidebar-hamberger mr-1"
            onClick={() => handleSideBarStatus(!showSideBar)}
          >
            {showSideBar ? (
              <Icon name="Cross" color={GREY_06} size={32} />
            ) : (
              <Icon name="HambergerMenu" />
            )}
          </div>
        </div>

        <div className="sidebar-container">
          <Sidebar />
        </div>
        {showSideBar && (
          <div className="accordian-container">
            <Accordion navigate={this.props.navigate} />
          </div>
        )}
      </div>
    );
  }
}

SideMenu.propTypes = {
  handleSideBarStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { item, showSideBar, isVerified } = state.core;
  return { item, showSideBar, isVerified };
};

export default connect(
  mapStateToProps,
  { handleSideBarStatus }
)(SideMenu);
