import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../../templates/forms/Layout';
import SelectMenu from '../../ui/selectMenu';
import { updateItem } from '../../../../../actions';
import { radioTypes } from '../../../constants/common';
import InputField from '../../ui/inputField';
import FormFooter from '../../templates/forms/formFooter';
import { showRow } from '../../../utils/helpers';

class Equipment extends Component {
  constructor(props) {
    super(props);
    const decodedItem = props.vin.decodedItems[props.variant.index];
    this.state = {
      source: {
        standardEquipments:
          decodedItem &&
          decodedItem.standardEquipments.map(a => ({
            value: a.toUpperCase(),
            label: a.toUpperCase(),
          })),
        optionalEquipments:
          decodedItem &&
          decodedItem.optionalEquipments.map(a => ({
            value: a.toUpperCase(),
            label: a.toUpperCase(),
          })),
        radio: radioTypes.map(radio => ({
          value: radio,
          label: radio,
        })),
      },
      standardEquipmentOpen: false,
      optionalEquipmentOpen: false,
    };
  }

  getOptions(entity, sourceEntity) {
    const selected = this.props.item[entity];
    const sourceList = this.state.source[sourceEntity];

    if (!sourceList || !selected) return [];

    const options = [
      selected.length === sourceList.length
        ? { label: '-- DESELECT ALL --', value: 'DESELECT ALL' }
        : { label: '-- SELECT ALL --', value: 'SELECT ALL' },
      ...sourceList,
    ];

    return options;
  }

  getDefaultValue(entity) {
    const existingValue = this.props.item && this.props.item[entity];
    return existingValue && existingValue.length > 0
      ? { value: existingValue, label: existingValue }
      : null;
  }

  getMultiValues(entity) {
    const existingValue = (this.props.item && this.props.item[entity]) || [];
    return existingValue.map(item => ({ label: item, value: item }));
  }

  handleValue(value, entity) {
    this.props.updateItem({ [entity]: value });
  }

  handleMutiSelectValues(value, entity, sourceEntity) {
    const selectedItem = value.at(-1);

    // menu stays open when selecting, unless the user chooses SELECT ALL or DESELECT ALL
    if (!selectedItem || selectedItem.value === 'DESELECT ALL') {
      this.props.updateItem({ [entity]: [] });
      this.setState({
        standardEquipmentOpen: false,
        optionalEquipmentOpen: false,
      });
    } else if (selectedItem.value === 'SELECT ALL') {
      this.props.updateItem({
        [entity]: this.state.source[sourceEntity].map(a => a.value),
      });
      this.setState({
        standardEquipmentOpen: false,
        optionalEquipmentOpen: false,
      });
    } else {
      this.props.updateItem({ [entity]: value.map(a => a.value) });
    }
  }

  render() {
    const { source } = this.state;
    const { item, coreSections } = this.props;

    const multiSelectStyles = {
      option: (base, state) => {
        const isSpecial = ['SELECT ALL', 'DESELECT ALL'].includes(state.value);
        return { ...base, fontWeight: isSpecial ? 'bold' : 'normal' };
      },
    };

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Equipment</div>
            </div>
            <div className="form-body-container">
              <div className="form-body">
                {showRow('s3:r1', coreSections) && (
                  <div>
                    {item && item.nonStandardVin ? (
                      <InputField
                        title="Standard"
                        placeholder="Enter standard equipments"
                        onChangeHandler={e =>
                          this.handleValue(e.target.value, 'standardEquipment')
                        }
                        value={item && item.standardEquipment}
                      />
                    ) : (
                      <SelectMenu
                        styles={multiSelectStyles}
                        title="Standard"
                        value={this.getMultiValues('standardEquipment')}
                        options={this.getOptions(
                          'standardEquipment',
                          'standardEquipments'
                        )}
                        onChangeHandler={value =>
                          this.handleMutiSelectValues(
                            value,
                            'standardEquipment',
                            'standardEquipments'
                          )
                        }
                        hideIndicatorSeparator={true}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        menuIsOpen={this.state.standardEquipmentOpen}
                        onMenuOpen={() =>
                          this.setState({ standardEquipmentOpen: true })
                        }
                        onMenuClose={() =>
                          this.setState({ standardEquipmentOpen: false })
                        }
                      />
                    )}
                  </div>
                )}
                {showRow('s3:r2', coreSections) && (
                  <div>
                    {item && item.nonStandardVin ? (
                      <InputField
                        title="Optional"
                        value={item && item.optionalEquipment}
                        placeholder="Enter optional equipments"
                        onChangeHandler={e =>
                          this.handleValue(e.target.value, 'optionalEquipment')
                        }
                      />
                    ) : (
                      <SelectMenu
                        styles={multiSelectStyles}
                        title="Optional"
                        value={this.getMultiValues('optionalEquipment')}
                        options={this.getOptions(
                          'optionalEquipment',
                          'optionalEquipments'
                        )}
                        onChangeHandler={value =>
                          this.handleMutiSelectValues(
                            value,
                            'optionalEquipment',
                            'optionalEquipments'
                          )
                        }
                        hideIndicatorSeparator={true}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        menuIsOpen={this.state.optionalEquipmentOpen}
                        onMenuOpen={() =>
                          this.setState({ optionalEquipmentOpen: true })
                        }
                        onMenuClose={() =>
                          this.setState({ optionalEquipmentOpen: false })
                        }
                      />
                    )}
                  </div>
                )}
                {showRow('s3:r3', coreSections) && (
                  <div>
                    <SelectMenu
                      title="Radio"
                      options={source.radio}
                      onChangeHandler={e => this.handleValue(e.value, 'radio')}
                      defaultValue={this.getDefaultValue('radio')}
                      hideIndicatorSeparator={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <FormFooter currentSection="s3" />
          </div>
        </div>
      </Layout>
    );
  }
}

Equipment.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { vin, item, variant, coreSections } = state.core;
  return { vin, item, variant, coreSections };
};

export default connect(
  mapStateToProps,
  {
    updateItem,
  }
)(Equipment);
