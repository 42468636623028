import React, { Component } from 'react';
import { connect } from 'react-redux';

class CrewCabPickup4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M796.5,336.3c-0.7-2.3-2.2-3.5-4.6-3.6h-13.4l3.8-24.8c0.4-8.3,0.7-15.7,0.4-22.3
	c-0.2-9.8-1.2-17.8-2.7-24.3c-0.5-3.6-2-5.7-4.1-6c-0.4-0.2-1-0.2-1.5,0l-247.6,1c-5.8,0.1-9.4,1.2-10.8,3.3
	c-1.2-22.3-4.4-41.3-9.4-56.9c-0.1-5.2-2.3-9-6.4-11.5c-6-2.9-16.8-4.1-32.1-3.8h-66.6c-4.9-0.2-8.7,0.2-11.5,1.1l-30.2,0.5
	c-36.2,0.5-58.5,4.1-66.9,10.8l-1.3,0.6l9.4,4.9c-19.4,11.1-38.5,25.7-57.2,43.5c-6.9,6-16.8,9-29.8,9.1h-0.3
	c-1.7,0.1-3.6-0.3-5.3-1.1c-0.2-0.9-0.5-1.5-0.8-2.1c-0.2-0.3-0.4-0.7-0.7-0.9c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.1-0.7-0.2-0.9-0.2
	c-4.1-1.1-8.8-1.9-13.9-2.2c-25.3-2-58.3,0.8-98.9,8.2c-9.2,1.6-16.1,3.3-20.6,4.8c-5.9,2.2-9.8,6.2-11.9,12v0.1
	c-2.2,0.4-3.4,2.2-3.7,5c-1.3,0.7-1.3,1.3,0,2l-0.9,7.5h-1.3c-0.2,1.1-0.3,1.7-0.3,2c-0.5,3.6-0.9,7.2-0.7,10.7v0.2
	c-0.8,0.5-0.8,1.2,0.1,1.7h0.7l1.6,13.5c-1,0.5-1.1,5.1-0.7,6.6c-0.2,0.5-0.4,1.2-0.5,2c-3.8,0.9-6.4,1.7-7.7,2.7
	c-1,0.7-1.4,2.6-1.4,6.1c0.2,1,1.1,1.4,2.4,1.4l0.9,12.8c-1.3,0.4-1.8,1.4-1.5,3l0.2,2.3c-1.5,1-2,2.2-1.5,3.4l1.2,1.3
	c1,6.5,3.4,13.1,7.2,19.6c1.1,2.1,3.6,3.3,7.4,3.7c3.3,0.1,6.5-0.5,9.9-2c7.7-1.6,19.4-2.6,34.7-3.2c0.1-1.9,0.2-3.6,0.4-5.4
	c0.1-1.6,0.2-3.3,0.4-4.8c2-20.3,5-35.4,9.4-45c0.5-2.1,1.3-4,2.1-5.8c0.5-1.1,1.1-2.2,1.6-3.2c3.7-6.3,9.1-10.1,16.1-11.2
	c13.2-2.3,29.6-3.4,49.6-3.3h2.7c14.1,0.7,23.1,4.9,26.6,12.6c0.2,0.5,0.5,1.1,0.8,1.7c4.5,10.7,8.1,27.2,10.9,49.6
	c0.1,0.8,0.2,1.5,0.3,2.4c0.1,1,0.2,2,0.3,3h359.7c1.7,0,2.9-0.9,3.6-2.5c0.8-22.4,3.7-38.7,8.9-49.1c0.4-0.8,0.8-1.5,1.2-2.3
	c2.7-5.3,7.6-9.1,14.4-11.3c2.4-0.8,5-1.4,7.9-1.7c0.8-0.1,1.4-0.2,2.2-0.2c14.1-1.5,31.2-1.5,51.3,0c1.4,0.2,2.7,0.5,4,0.9
	c11.2,2.9,18.7,10.8,22.5,23.5c3,9.5,4.6,23.2,4.5,40.9v2.5h4.9l1.5-2.5c13.7,0.1,26.4-0.5,38.2-2.1l3.5-2.7l12.6-2l0.8,1
	c2.5-0.2,3.9-0.8,4.4-1.6h3.5c1.6-0.3,3.3,0.5,4.6,2.5l1.6-0.8v2.7l1,1.8l6.6-4.2c1.7-0.8,3.3-3.3,4.5-7.7c0.2-0.5,0.3-0.9,0.3-1.3
	c0.2-1.3-0.2-2-1.4-1.9l2.3-12.1c0-0.5,0-1.1-0.1-1.6C796.8,337.2,796.6,336.8,796.5,336.3L796.5,336.3z"
          />
          <path
            fill="#5E5E5E"
            d="M58.1,278c-0.7,0.9-1.1,2.1-1.2,3.6c-1.3,0.7-1.3,1.3,0,2L56,291h-1.3c-0.8,4.2-1.2,8.3-1,12.6v0.2
	c-0.8,0.5-0.8,1.2,0.1,1.7h0.7l1.6,13.5c-0.4,0.2-0.8,1.5-0.9,2.9s0,2.9,0.2,3.7v0.1c0.2-0.5,0.5-1,0.9-1.3c0.2-0.1,0.3-0.3,0.5-0.4
	s0.5-0.3,0.8-0.4c2.6-1.3,7.1-2.4,13.2-3.3V318c-0.7-6.2-0.7-12.2-0.1-18.3c0.1-0.8,0.2-1.4,0.2-2.1c0.7-4.9,1.7-9.8,3.3-14.7v-0.5
	c0.2-2.5-0.4-4.1-2.2-5.1c-1-0.5-2.3-0.9-3.9-0.9h-7.5v0.1C59.5,276.8,58.6,277.2,58.1,278L58.1,278z M53.8,303.6
	C53.3,303.6,55.8,284.3,53.8,303.6z M57.8,278.3l0.1-0.1L57.8,278.3z"
          />
          <path
            fill="#EAEAEA"
            d="M779.3,271.6l-13.5,2.4c-4.8,1.2-8,2.9-9.9,5.3c-1.3,1.7-1.8,3.7-1.5,6.1l-1,27c-0.1,4.9,2,7.3,6.2,7.2
	l18.4,0.3C779.5,298.5,779.8,282.4,779.3,271.6L779.3,271.6z M342.8,205.6c-4.1,0.3-7.9,0.9-11.5,1.5c-10.5,1.9-19.1,4.9-25.6,9
	c-11.1,6.7-21.4,14.6-31,23.3c0.8-0.2,1.5-0.3,2.4-0.3h4.1c2.2,0.3,3.3,1.5,3.4,3.6v1l0.1,18.1c-0.2,1.3-0.7,2.3-1.5,3l84.7-1.7
	c2.6,0,4.7-0.4,5.9-1.3c1-0.8,1.4-1.7,1.3-3v-0.1l-1-44.8c-0.3-6.1-3.6-9.4-9.6-9.7L342.8,205.6L342.8,205.6z M466.8,202.4h-60.9
	c-6.6,0.3-10,3.6-10,9.6l2.5,43.4c0.1,2.4,0.8,4.2,2,5.3c1.2,1.1,3,1.4,5.3,1.2h0.1l68.6-1.2c8.9-1.1,13.5-5.3,13.9-12.7
	c0.5-9.4-1.1-21.3-5.1-35.7C480.8,205.7,475.3,202.5,466.8,202.4L466.8,202.4z"
          />
          <path
            fill="#646464"
            d="M703.8,326.8c-3.9-12.6-11.4-20.4-22.5-23.5c-1.3-0.3-2.6-0.7-4-0.9c-20-1.5-37.1-1.5-51.3,0
	c-0.8,0.1-1.5,0.2-2.2,0.2c-2.9,0.4-5.5,1-7.9,1.7c-6.9,2.2-11.6,6-14.4,11.3c-0.4,0.8-0.9,1.4-1.2,2.3c-5.2,10.3-8.2,26.6-8.9,49.1
	h8.6c0,1.9,0.2,3.7,0.4,5.4c0.4,3.5,1.3,6.9,2.5,10c2.4,6.3,6.1,12.1,11.3,17.3c1,1,2.1,2,3.2,2.9c8.9,7.6,19.4,11.4,31.3,11.4
	c13.5,0,25-4.8,34.5-14.2c7.8-7.8,12.4-17,13.8-27.3c0.1-0.5,0.1-1,0.2-1.5c0.1-1.1,0.2-2.2,0.2-3.2l10.8-0.2
	C708.3,350,706.8,336.3,703.8,326.8L703.8,326.8z M648.7,333.2c8.9,0,16.5,3.2,22.7,9.5c6.3,6.3,9.5,13.9,9.5,22.7
	c0,2-0.1,3.8-0.4,5.7c-0.1,0.5-0.2,1-0.3,1.5c-1.3,5.9-4.1,11.1-8.7,15.6c-0.9,0.9-1.7,1.6-2.7,2.5c-5.8,4.7-12.4,7-20,7
	c-8.9,0-16.5-3.2-22.7-9.5c-4.6-4.6-7.4-9.7-8.7-15.6c-0.1-0.5-0.2-1-0.2-1.5c-0.3-1.9-0.4-3.7-0.4-5.7c0-8.9,3.2-16.5,9.5-22.7
	c0.8-0.8,1.6-1.5,2.5-2.2C634.1,335.6,640.9,333.2,648.7,333.2L648.7,333.2z"
          />
          <path
            fill="#EAEAEA"
            d="M659.8,362.9c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6v0.2h10.7l1.7-0.9c1.1-0.5,1.8-0.4,2.3,0.5v0.3
	h4.1c0-8.9-3.2-16.5-9.5-22.7c-6.3-6.3-13.9-9.5-22.7-9.5c-7.7,0-14.6,2.4-20.3,7.2c-0.9,0.6-1.6,1.4-2.5,2.2
	c-6.3,6.3-9.5,13.9-9.5,22.7h4.2c0.3-1.3,1.1-1.6,2.4-0.9l1.7,0.9h10.9V365c0-0.6,0.2-1.2,0.7-1.6s1-0.7,1.6-0.7s1.2,0.2,1.6,0.7
	c0.4,0.4,0.7,1,0.7,1.6v0.2h17.2V365c0-0.6,0.2-1.2,0.7-1.6C658.6,363.1,659.2,362.9,659.8,362.9L659.8,362.9z M668.7,344.1l0.7,0.7
	l0.1,0.1l0.5,0.5c0.7,0.6,1.2,1.3,1.8,2.1l-1.7-2c-0.2-0.2-0.4-0.4-0.7-0.7C669.3,344.6,669.1,344.4,668.7,344.1
	c-1.5-1.4-3.2-2.6-4.8-3.7c-2.5-1.5-5.1-2.6-7.9-3.3h-0.1c-2.3-0.5-4.7-0.9-7.1-0.9c-2.5,0-4.9,0.2-7.2,0.9h-0.1l-0.1-0.1
	c2.3-0.5,4.7-0.9,7.3-0.9c5.8,0,10.9,1.4,15.3,4.2C665.7,341.5,667.3,342.7,668.7,344.1L668.7,344.1z M668.5,345.6
	c0.1,0.1,0.2,0.2,0.2,0.2c1.2,1.2,2.2,2.5,3.2,3.8l0.1,0.1c0.4,1.3,0,2-1.4,2.2l-10.5,1.3c-1.3-0.2-2.1-0.7-2.4-1.2
	c-0.2-0.5-0.4-0.9-0.4-0.9l-1.8-10.3v-0.4c-0.1-1.3,0.8-1.9,2.5-1.6c1.3,0.4,2.6,1,3.8,1.6C664,341.7,666.3,343.4,668.5,345.6
	L668.5,345.6z M651.2,354.3c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6
	c0.4-0.4,1-0.7,1.6-0.7s1.2,0.2,1.6,0.7C651,353.1,651.2,353.7,651.2,354.3z M655,355.9c0.4-0.4,1-0.6,1.6-0.6s1.2,0.2,1.6,0.6
	c0.4,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6S654.6,356.4,655,355.9L655,355.9z
	 M677.5,359.7l-0.2-0.8c-0.1-0.3-0.2-0.8-0.3-1.1l0.1,0.1C677.3,358.7,677.4,359.2,677.5,359.7L677.5,359.7z M642,340.4
	c0,0.1,0,0.2-0.1,0.4l-1.8,10.4c-0.7,1.1-1.5,1.7-2.6,2L627,352c-1.5-0.2-2-1-1.3-2.5c1-1.3,2-2.6,3.3-3.8c2.2-2.2,4.5-3.8,7-5.2
	c1.3-0.8,2.7-1.3,4.2-1.7C641.4,338.6,642.1,339.2,642,340.4L642,340.4z M641.2,355.2c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6
	s-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6
	C640,355.4,640.6,355.2,641.2,355.2L641.2,355.2z M636.3,366.8c-0.4-0.4-0.7-0.9-0.7-1.4h-10.9l7.6,3.9c0.7,0.5,1,1.2,1.1,1.9
	c0,0.3,0,0.7-0.1,1.1l-4.6,9.6c-0.7,1.3-1.4,1.5-2.5,0.7c-2.4-3-3.9-6.3-4.9-9.9c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.6-0.5-3.4-0.5-5.2
	v-0.2c0-0.1,0-0.1,0.1-0.2h-4.2c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.3,1.5c1.3,5.9,4.1,11.1,8.7,15.6c6.3,6.3,13.9,9.5,22.7,9.5
	c7.6,0,14.4-2.3,20-7c0.9-0.8,1.8-1.6,2.7-2.5c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.3-1.5c0.3-1.8,0.4-3.7,0.4-5.7h-4.1
	c0,2-0.2,3.9-0.5,5.7c-0.1,0.5-0.2,1-0.3,1.5c-1,3.7-2.6,7.1-5.1,10.2c-0.9,0.4-1.5,0.1-2-0.8c0-0.1-0.1-0.1-0.1-0.2l-4.7-9.6
	c0-0.4,0-0.8,0.1-1.1c0.1-0.8,0.4-1.3,0.9-1.6l7.8-4h-10.7c0,0.5-0.2,1-0.7,1.4c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7
	s-0.7-0.9-0.7-1.4h-17.2c0,0.5-0.2,1-0.7,1.4c-0.4,0.4-1,0.7-1.6,0.7C637.3,367.5,636.7,367.3,636.3,366.8L636.3,366.8z
	 M642.8,371.3c0.3,0.3,0.7,0.9,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6
	v-0.3c0-0.5,0.3-1,0.7-1.3c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.9-0.5,1.4-0.5s1.1,0.2,1.4,0.5C642.7,371.2,642.7,371.3,642.8,371.3
	L642.8,371.3z M647.3,377.7c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7s1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6
	s-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7C648.3,378.5,647.7,378.2,647.3,377.7z M647.3,381.8c0.3-0.2,0.8-0.3,1.2-0.4
	s1,0.1,1.6,0.4l7.7,7.4c1.1,1.1,1,2-0.4,2.8c-2.7,0.9-5.7,1.3-8.8,1.3c-3,0-6-0.4-8.6-1.3c-1.4-0.9-1.6-1.9-0.4-2.9L647.3,381.8
	L647.3,381.8z M620,371.2c0.1,0.5,0.2,1,0.3,1.5v0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.1-0.5-0.2-0.8V371.2
	C620,371.1,620,371.1,620,371.2L620,371.2z M658.2,371.3c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7
	c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6v-0.3c0.1-0.5,0.3-1,0.7-1.3c0,0,0.1-0.1,0.2-0.1c0.4-0.3,0.9-0.5,1.4-0.5
	s1.1,0.2,1.4,0.5C658.1,371.2,658.2,371.3,658.2,371.3z"
          />
          <path
            fill="#646464"
            d="M642,340.8c0-0.1,0-0.2,0.1-0.4c0.1-1.2-0.5-1.7-2.1-1.7c-1.4,0.4-2.8,1.1-4.2,1.7c-2.5,1.3-4.8,3-7,5.2
	c-1.2,1.2-2.3,2.5-3.3,3.8c-0.7,1.5-0.2,2.4,1.3,2.5l10.5,1.2c1.1-0.2,2-0.9,2.6-2L642,340.8L642,340.8z M657.7,338.8
	c-1.7-0.2-2.6,0.3-2.5,1.6v0.4l1.8,10.3c0,0,0.1,0.3,0.4,0.9c0.2,0.5,1.1,1,2.4,1.2l10.5-1.3c1.4-0.1,2-0.9,1.4-2.2l-0.1-0.1
	c-0.9-1.3-2-2.5-3.2-3.8l-0.2-0.2c-2.2-2.2-4.5-3.8-6.9-5.2C660.3,339.8,659,339.3,657.7,338.8L657.7,338.8z M664.9,369.4
	c-0.4,0.4-0.8,1-0.9,1.6c-0.1,0.3-0.1,0.7-0.1,1.1l4.7,9.6c0,0.1,0.1,0.1,0.1,0.2c0.5,1,1.2,1.2,2,0.8c2.5-3,4.1-6.4,5.1-10.2
	c0.1-0.5,0.2-1,0.3-1.5c0.3-1.9,0.5-3.7,0.5-5.7V365c-0.4-1-1.1-1.1-2.3-0.5L664.9,369.4L664.9,369.4z M650.2,381.8
	c-0.7-0.3-1.2-0.5-1.6-0.4s-0.9,0.2-1.2,0.4l-7.7,7.4c-1.1,1.1-1,2.1,0.4,2.9c2.7,0.9,5.5,1.3,8.6,1.3c3.2,0,6.1-0.4,8.8-1.3
	c1.4-0.9,1.5-1.8,0.4-2.8L650.2,381.8L650.2,381.8z M633.5,371.2c-0.1-0.7-0.4-1.2-1.1-1.9l-9.4-4.8c-1.3-0.7-2.1-0.3-2.4,1.1v0.2
	c0,1.9,0.2,3.6,0.5,5.2c0.1,0.5,0.2,1,0.3,1.5c0.9,3.6,2.5,6.9,4.9,9.9c1.1,0.9,1.8,0.7,2.5-0.6l4.6-9.6L633.5,371.2L633.5,371.2z"
          />
          <path
            fill="#EAEAEA"
            d="M301.1,205.3l-9.4-4.9c-20.5,10.6-48.5,28.6-84.2,54.4c0.3,0.5,0.5,1.2,0.8,2.1c1.7,0.9,3.6,1.2,5.3,1.1h0.3
	c13.1-0.2,22.9-3.3,29.8-9.1C262.6,231,281.6,216.5,301.1,205.3L301.1,205.3z"
          />
          <path
            fill="#646464"
            d="M187.1,300.2h-0.7c-19.9-0.1-36.4,1-49.6,3.3c-7,1.1-12.4,4.8-16.1,11.2c-0.5,1-1.1,2.1-1.6,3.2
	c-0.8,1.7-1.5,3.7-2.1,5.8c-4.4,9.6-7.4,24.7-9.4,45c-0.1,1.5-0.3,3.2-0.4,4.8l13.9-1.1v0.3c0.4,3.5,1.3,6.9,2.5,10
	c2.4,6.3,6.1,12.1,11.3,17.3c1,1,2.1,2,3.2,2.9c8.9,7.6,19.4,11.4,31.3,11.4c13.5,0,25-4.8,34.5-14.2c7.8-7.8,12.4-17,13.8-27.3
	c0.1-0.5,0.1-1,0.2-1.5c0.1-1.4,0.2-2.7,0.2-4.1l9.4-0.3c-0.1-0.8-0.2-1.6-0.3-2.4c-2.8-22.4-6.4-38.9-10.9-49.6
	c-0.2-0.5-0.5-1.2-0.8-1.7c-3.6-7.7-12.5-12-26.6-12.6C188.4,300.3,187.7,300.3,187.1,300.2L187.1,300.2z M169.6,333.2
	c8.9,0,16.4,3.2,22.7,9.5s9.5,13.9,9.5,22.7c0,2-0.1,3.8-0.4,5.7c-0.1,0.5-0.2,1-0.3,1.5c-1.3,5.9-4.1,11.1-8.7,15.6
	c-0.9,0.9-1.7,1.6-2.7,2.5c-5.8,4.7-12.4,7-20,7c-8.9,0-16.4-3.2-22.8-9.5c-4.6-4.6-7.4-9.7-8.7-15.6c-0.1-0.5-0.2-1-0.2-1.5
	c-0.3-1.9-0.4-3.7-0.4-5.7c0-8.9,3.2-16.5,9.5-22.7c0.8-0.8,1.6-1.5,2.5-2.2C155,335.6,161.7,333.2,169.6,333.2L169.6,333.2z"
          />
          <path
            fill="#EAEAEA"
            d="M179.7,363.1c0.3-0.1,0.5-0.2,0.9-0.2c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6v0.2h10.7l1.7-0.9
	c1.1-0.5,1.9-0.4,2.3,0.5v0.3h4.1c0-8.9-3.2-16.5-9.5-22.8s-13.9-9.5-22.7-9.5c-7.7,0-14.6,2.4-20.3,7.2c-0.9,0.6-1.6,1.4-2.5,2.2
	c-6.3,6.3-9.5,13.9-9.5,22.8h4.2c0.3-1.3,1.1-1.5,2.4-0.9l1.7,0.9h10.9V365c0-0.6,0.2-1.2,0.7-1.6s1-0.7,1.6-0.7
	c0.3,0,0.7,0,0.9,0.2c0.3,0.1,0.5,0.2,0.8,0.5c0.4,0.4,0.7,1,0.7,1.6v0.2h17.2V365c0-0.7,0.2-1.2,0.7-1.6
	C179.1,363.3,179.5,363.2,179.7,363.1L179.7,363.1z M190.4,344.8c-0.2-0.2-0.5-0.5-0.8-0.8c-1.5-1.4-3.2-2.7-4.8-3.7
	c-2.5-1.5-5.1-2.6-7.9-3.3h-0.1c-2.3-0.5-4.7-0.9-7.1-0.9c-2.5,0-4.9,0.2-7.2,0.9h-0.1l-0.1-0.1c2.3-0.5,4.7-0.9,7.3-0.9
	c5.8,0,10.9,1.4,15.3,4.2c1.6,1.1,3.3,2.3,4.7,3.7l0.6,0.7L190.4,344.8l0.5,0.5c0.7,0.7,1.3,1.4,1.9,2.1l-1.7-2
	C190.8,345.3,190.6,345.1,190.4,344.8L190.4,344.8z M189.3,345.6c0.1,0.1,0.2,0.2,0.2,0.2c1.2,1.2,2.2,2.5,3.2,3.8l0.1,0.1
	c0.4,1.3,0,2-1.4,2.2l-10.6,1.3c-1.3-0.2-2.1-0.7-2.4-1.2c-0.2-0.5-0.4-0.9-0.4-0.9l-1.9-10.3v-0.4c-0.1-1.3,0.8-1.9,2.5-1.6
	c1.3,0.4,2.6,1,3.8,1.6C184.9,341.7,187.2,343.4,189.3,345.6L189.3,345.6z M178.4,355.4c0.2,0.1,0.5,0.2,0.8,0.5
	c0.4,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6c-0.2,0.2-0.4,0.3-0.8,0.5c-0.2,0.1-0.5,0.2-0.9,0.2c-0.7,0-1.2-0.2-1.6-0.7
	c-0.4-0.4-0.7-1-0.7-1.6c0-0.3,0-0.5,0.2-0.9c0.1-0.2,0.2-0.5,0.5-0.8c0.4-0.4,1-0.7,1.6-0.7C177.7,355.2,178.1,355.3,178.4,355.4
	L178.4,355.4z M171.3,355.9c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.2-0.2-0.3-0.4-0.5-0.8c-0.1-0.2-0.2-0.5-0.2-0.9
	c0-0.7,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7c0.2,0.2,0.4,0.4,0.5,0.8c0.1,0.2,0.1,0.5,0.1,0.9
	s0,0.7-0.1,0.9C171.8,355.5,171.5,355.7,171.3,355.9z M198.3,359.7c-0.1-0.7-0.2-1.2-0.4-1.8l0.1,0.1
	C198.1,358.7,198.2,359.2,198.3,359.7L198.3,359.7z M162.8,340.4c0,0.1,0,0.2-0.1,0.4l-1.9,10.4c-0.6,1.1-1.5,1.7-2.6,2l-10.6-1.2
	c-1.5-0.2-2-1-1.3-2.5c1-1.3,2-2.6,3.3-3.8c2.2-2.2,4.5-3.8,7-5.2c1.4-0.8,2.7-1.3,4.2-1.7C162.3,338.6,162.9,339.2,162.8,340.4
	L162.8,340.4z M163.6,355.9c0.2,0.2,0.4,0.4,0.5,0.8c0.1,0.2,0.1,0.5,0.1,0.9c0,0.7-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7
	c-0.3,0-0.5,0-0.9-0.1c-0.2-0.1-0.5-0.3-0.8-0.5c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.2-0.2,0.4-0.4,0.8-0.5
	c0.3-0.1,0.5-0.2,0.9-0.2C162.6,355.2,163.2,355.4,163.6,355.9L163.6,355.9z M157.2,366.8c-0.4-0.4-0.7-0.9-0.7-1.4h-10.9l7.6,3.9
	c0.6,0.5,1,1.2,1.1,1.9c0,0.3,0,0.7-0.1,1.1l-4.6,9.6c-0.7,1.3-1.4,1.5-2.5,0.7c-2.4-3-3.9-6.3-4.9-9.9c-0.1-0.5-0.2-1-0.3-1.5
	c-0.3-1.6-0.5-3.4-0.5-5.2v-0.2c0-0.1,0-0.1,0.1-0.2h-4.2c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.2,1.5c1.3,5.9,4.1,11.1,8.7,15.6
	c6.3,6.3,13.9,9.5,22.8,9.5c7.6,0,14.4-2.3,20-7c0.9-0.8,1.8-1.6,2.7-2.5c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.3-1.5
	c0.3-1.8,0.4-3.7,0.4-5.7h-4.1c0,2-0.2,3.9-0.5,5.7c-0.1,0.5-0.2,1-0.3,1.5c-1,3.7-2.6,7.1-5.1,10.2c-0.9,0.4-1.5,0.1-2-0.8
	c0-0.1-0.1-0.1-0.1-0.2l-4.7-9.6c0-0.4,0-0.8,0.1-1.1c0.1-0.8,0.4-1.3,0.9-1.6l7.8-4H183c0,0.5-0.2,1-0.6,1.4s-1,0.7-1.6,0.7
	c-0.3,0-0.7,0-0.9-0.1s-0.5-0.3-0.8-0.5c-0.4-0.4-0.7-0.9-0.7-1.4h-17.2c0,0.5-0.2,1-0.7,1.4c-0.2,0.2-0.4,0.4-0.8,0.5
	c-0.2,0.1-0.5,0.1-0.9,0.1C158.2,367.5,157.6,367.3,157.2,366.8L157.2,366.8z M163.6,371.3c0.3,0.3,0.7,0.9,0.7,1.3v0.3
	c0,0.3,0,0.7-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6v-0.3
	c0-0.5,0.3-1,0.7-1.3l0.2-0.2c0.2-0.2,0.4-0.3,0.7-0.3c0.2-0.1,0.5-0.1,0.8-0.1c0.5,0,1.1,0.2,1.4,0.5
	C163.5,371.2,163.6,371.3,163.6,371.3L163.6,371.3z M168.1,377.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.3,0-0.7,0.2-0.9
	c0.1-0.2,0.2-0.5,0.5-0.8c0.4-0.4,1-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7c0.2,0.2,0.4,0.4,0.5,0.8c0.1,0.2,0.1,0.5,0.1,0.9
	c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7C169,378.5,168.5,378.2,168.1,377.7L168.1,377.7z M168.2,381.8
	c0.3-0.2,0.8-0.3,1.2-0.4s1,0.1,1.6,0.4l7.6,7.4c1.1,1.1,1,2-0.4,2.8c-2.7,0.9-5.7,1.3-8.8,1.3c-3,0-6-0.4-8.6-1.3
	c-1.4-0.9-1.6-1.9-0.4-2.9L168.2,381.8L168.2,381.8z M140.8,371.2c0.1,0.5,0.2,1,0.3,1.5v0.3c0-0.1,0-0.2-0.1-0.3
	c-0.1-0.4-0.2-0.9-0.3-1.4l-0.1-0.9C140.8,370.6,140.8,371,140.8,371.2L140.8,371.2z M148.4,385.2l-0.1,0.3l-0.8-0.9
	C147.7,384.9,148,385.1,148.4,385.2L148.4,385.2z M179,371.3c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.2-0.2-0.4-0.4-0.5-0.8c-0.1-0.2-0.2-0.5-0.2-0.9v-0.3c0.1-0.5,0.3-1,0.7-1.3
	c0,0,0.1-0.1,0.2-0.1c0.4-0.3,0.9-0.5,1.4-0.5c0.2,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.7,0.3C178.9,371.2,178.9,371.3,179,371.3
	L179,371.3z"
          />
          <path
            fill="#646464"
            d="M162.8,340.4c0.1-1.2-0.5-1.7-2.1-1.7c-1.4,0.4-2.8,1.1-4.2,1.7c-2.5,1.3-4.8,3-7,5.2
	c-1.2,1.2-2.3,2.5-3.3,3.8c-0.7,1.5-0.2,2.4,1.3,2.5l10.6,1.2c1.1-0.2,2-0.9,2.6-2l1.9-10.4C162.7,340.7,162.8,340.6,162.8,340.4
	L162.8,340.4z M178.6,338.8c-1.7-0.2-2.6,0.3-2.5,1.6v0.4l1.9,10.3c0,0,0.1,0.3,0.4,0.9c0.2,0.5,1.1,1,2.4,1.2l10.6-1.3
	c1.4-0.1,2-0.9,1.4-2.2l-0.1-0.1c-0.9-1.3-2-2.5-3.2-3.8l-0.2-0.2c-2.2-2.2-4.5-3.8-6.9-5.2C181.2,339.8,179.9,339.3,178.6,338.8
	L178.6,338.8z M185.8,369.4c-0.4,0.4-0.8,1-0.9,1.6c-0.1,0.3-0.1,0.7-0.1,1.1l4.7,9.6c0,0.1,0.1,0.1,0.1,0.2c0.5,1,1.2,1.2,2,0.8
	c2.5-3,4.1-6.4,5.1-10.2c0.1-0.5,0.2-1,0.3-1.5c0.3-1.9,0.5-3.7,0.5-5.7V365c-0.4-1-1.1-1.1-2.3-0.5L185.8,369.4L185.8,369.4z
	 M169.4,381.4c-0.4,0.1-0.9,0.2-1.2,0.4l-7.7,7.4c-1.1,1.1-1,2.1,0.4,2.9c2.7,0.9,5.5,1.3,8.6,1.3c3.2,0,6.1-0.4,8.8-1.3
	c1.4-0.9,1.5-1.8,0.4-2.8l-7.6-7.4C170.3,381.5,169.8,381.4,169.4,381.4L169.4,381.4z M141.4,366c0,1.9,0.2,3.6,0.5,5.2
	c0.1,0.5,0.2,1,0.3,1.5c0.9,3.6,2.5,6.9,4.9,9.9c1.1,0.9,1.9,0.7,2.5-0.7l4.6-9.6c0.1-0.3,0.1-0.8,0.1-1.1c-0.1-0.7-0.4-1.2-1.1-1.9
	l-9.4-4.8c-1.3-0.7-2.1-0.3-2.4,1.1C141.4,365.7,141.4,365.8,141.4,366L141.4,366z"
          />
          <path
            fill="#FFFFFF"
            d="M668.7,344.2c0.2,0.2,0.5,0.4,0.8,0.8l0.7,0.7l1.7,2c-0.5-0.8-1.2-1.4-1.8-2.1l-0.5-0.5l-0.1-0.1
	C669.2,344.5,668.9,344.3,668.7,344.2c-1.5-1.4-3-2.7-4.7-3.7c-4.6-2.8-9.7-4.2-15.3-4.2c-2.5,0-5,0.3-7.3,0.9l0.1,0.1h0.1
	c2.3-0.5,4.7-0.9,7.2-0.9s4.8,0.2,7.1,0.9h0.1c2.8,0.7,5.5,1.7,7.9,3.3C665.7,341.5,667.2,342.7,668.7,344.2L668.7,344.2z
	 M677.2,358.1l-0.1-0.1c0.1,0.3,0.2,0.8,0.3,1.1l0.2,0.8C677.4,359.2,677.3,358.7,677.2,358.1L677.2,358.1z M661.4,366.8
	c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6
	c0.4,0.4,1,0.7,1.6,0.7S661,367.3,661.4,366.8L661.4,366.8z M658.2,359.1c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6
	c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	C657.2,359.9,657.7,359.5,658.2,359.1L658.2,359.1z M650.6,352.7c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6
	s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6S651,353.1,650.6,352.7z M639.6,355.8
	c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6
	c-0.4-0.4-1-0.7-1.6-0.7S640,355.4,639.6,355.8L639.6,355.8z M642.6,371.2c-0.4-0.3-0.9-0.5-1.4-0.5s-1,0.2-1.4,0.5
	c-0.1,0-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.9-0.7,1.3v0.3c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6v-0.3c-0.1-0.5-0.3-1-0.7-1.3C642.7,371.3,642.7,371.2,642.6,371.2L642.6,371.2z M654.3,372.9
	c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-0.3c-0.1-0.5-0.3-1-0.7-1.3l-0.2-0.2
	c-0.4-0.3-0.9-0.5-1.4-0.5s-1.1,0.2-1.4,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.3-0.5,0.9-0.7,1.3L654.3,372.9L654.3,372.9z
	 M648.9,373.8c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6C650.1,374,649.6,373.8,648.9,373.8z M636.3,363.6c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6
	c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7S636.7,363.1,636.3,363.6
	L636.3,363.6z M620,371.2C620,371.1,620,371.1,620,371.2z M620.2,372.6c0,0.1,0,0.2,0.1,0.3C620.3,372.8,620.3,372.7,620.2,372.6
	c-0.1-0.4-0.2-1-0.2-1.4l0.1,0.6C620.1,372.1,620.2,372.4,620.2,372.6L620.2,372.6z M189.6,344.2c0.2,0.2,0.5,0.4,0.8,0.8
	c0.2,0.2,0.4,0.4,0.7,0.7l1.7,2c-0.5-0.8-1.2-1.4-1.9-2.1l-0.5-0.5l-0.1-0.1C190,344.5,189.8,344.3,189.6,344.2
	c-1.5-1.4-3-2.7-4.7-3.7c-4.6-2.8-9.7-4.2-15.3-4.2c-2.5,0-5,0.3-7.3,0.9l0.1,0.1h0.1c2.3-0.5,4.7-0.9,7.2-0.9s4.8,0.2,7.1,0.9h0.1
	c2.8,0.7,5.4,1.7,7.9,3.3C186.4,341.5,188.1,342.7,189.6,344.2L189.6,344.2z M198,357.9c0.1,0.5,0.2,1.2,0.4,1.8
	c-0.1-0.5-0.2-1.1-0.3-1.6L198,357.9L198,357.9z M180.6,367.5c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6
	c-0.4-0.4-1-0.7-1.6-0.7c-0.3,0-0.7,0-0.9,0.2c-0.2,0.1-0.5,0.2-0.8,0.5c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6
	c0.2,0.2,0.4,0.4,0.8,0.5L180.6,367.5L180.6,367.5z M171.9,353.4c-0.1-0.2-0.3-0.5-0.5-0.8c-0.4-0.4-1-0.7-1.6-0.7
	c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6c0,0.3,0,0.7,0.2,0.9c0.1,0.3,0.2,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7
	c0.7,0,1.2-0.2,1.6-0.7c0.2-0.2,0.4-0.4,0.5-0.8c0.1-0.2,0.1-0.5,0.1-0.9C172,354,172,353.7,171.9,353.4L171.9,353.4z M178.4,359.6
	c0.2-0.1,0.5-0.3,0.8-0.5c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.2-0.2-0.4-0.3-0.8-0.5c-0.2-0.1-0.5-0.2-0.9-0.2
	c-0.7,0-1.2,0.2-1.6,0.7c-0.2,0.2-0.4,0.4-0.5,0.8c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	C177.7,359.9,178.1,359.7,178.4,359.6L178.4,359.6z M161.1,355.4c-0.2,0.1-0.5,0.2-0.8,0.5c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6
	c0.2,0.2,0.4,0.4,0.8,0.5c0.3,0.1,0.5,0.1,0.9,0.1c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6c0-0.3,0-0.5-0.1-0.9
	c-0.1-0.2-0.3-0.5-0.5-0.8c-0.4-0.4-1-0.6-1.6-0.6C161.6,355.2,161.4,355.3,161.1,355.4L161.1,355.4z M158.8,362.9
	c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.3,0,0.7,0,0.9-0.1
	c0.3-0.1,0.5-0.3,0.8-0.5c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.2-0.2-0.4-0.3-0.8-0.5C159.5,362.9,159.1,362.9,158.8,362.9
	L158.8,362.9z M163.5,371.2c-0.4-0.3-0.9-0.5-1.4-0.5c-0.2,0-0.5,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.7,0.3c-0.1,0-0.1,0.1-0.2,0.2
	c-0.3,0.3-0.5,0.9-0.7,1.3v0.3c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.2-0.2,0.4-0.4,0.5-0.8
	c0.1-0.2,0.2-0.5,0.2-0.9v-0.3c-0.1-0.5-0.3-1-0.6-1.3C163.6,371.3,163.5,371.2,163.5,371.2L163.5,371.2z M175.1,372.9
	c0,0.3,0,0.7,0.2,0.9c0.1,0.3,0.3,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-0.3
	c-0.1-0.5-0.3-1-0.7-1.3l-0.2-0.2c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2-0.1-0.5-0.1-0.8-0.1c-0.5,0-1.1,0.2-1.4,0.5
	c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.3-0.5,0.9-0.7,1.3C175.1,372.7,175.1,372.8,175.1,372.9z M171.3,374.4c-0.4-0.4-1-0.7-1.6-0.7
	c-0.7,0-1.2,0.2-1.6,0.7c-0.2,0.2-0.3,0.4-0.5,0.8c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-0.3,0-0.7-0.1-0.9C171.7,375,171.5,374.6,171.3,374.4L171.3,374.4z M141.2,372.9
	v-0.3c-0.1-0.5-0.2-1-0.3-1.5c0-0.2-0.1-0.4-0.1-0.8l0.1,0.9c0.1,0.4,0.2,0.9,0.3,1.4C141.1,372.7,141.1,372.8,141.2,372.9
	L141.2,372.9z M147.4,384.7l0.8,0.9l0.1-0.3C148,385.1,147.7,384.9,147.4,384.7L147.4,384.7z"
          />
          <path
            fill="#EAEBEB"
            d="M80.8,281.9l-6.6,0.4v0.5c-1.5,4.8-2.6,9.7-3.3,14.7c-0.1,0.7-0.2,1.4-0.2,2.1c-0.7,6-0.5,12.1,0.1,18.3
	l38-1.7l0.1-1.5c0.2-5.5,0.2-10.6,0.1-15l-0.1-1.2l-0.1-2.5c-0.2-2.7-0.4-5.2-0.9-7.6c-0.2-1.5-0.5-3-0.9-4.5c-0.3-2-1.3-3-2.8-3.5
	c-0.8-0.2-1.6-0.2-2.6,0l-7.5,0.5L80.8,281.9L80.8,281.9z"
          />
          <path
            fill="#5E5E5E"
            d="M54.8,327.7c-3.8,0.9-6.4,1.7-7.7,2.7c4.9-0.3,7.4-0.9,7.6-1.6C54.7,328.3,54.7,328,54.8,327.7L54.8,327.7z"
          />
          <path
            fill="#EAEAEA"
            d="M58.7,348.1c-0.1,0.3,0,0.8,0.1,1.3C58.7,349,58.7,348.5,58.7,348.1L58.7,348.1z"
          />
          <path
            fill="#5E5E5E"
            d="M75.9,344.8l-14.1-0.2c-2,0-3,1.1-3,3.5c0,0.4,0,0.9,0.1,1.3v0.4l1.2,7.4c0.5,3,1.8,4.7,4.1,4.7h13.2
	c2.2,0,3.3-1.5,3-4.7l-0.5-7.3C79.6,346.6,78.3,344.8,75.9,344.8L75.9,344.8z M66,350.6c-0.2-2.1,0-3.3,0.8-3.8
	c0.1-0.1,0.3-0.2,0.5-0.2h0.4l8.1,0.1c1.3,0,2.1,1.4,2.3,4.1l0.3,5.9v1.2c0,0.9-0.2,1.4-0.4,1.8c-0.3,0.4-0.8,0.7-1.3,0.7h-7.6
	c-1.2-0.1-2-1.4-2.3-3.8L66,350.6L66,350.6z"
          />
          <path
            fill="#5E5E5E"
            d="M86.4,319.1c-1.3,0-2.5,0.1-3.8,0.1c-1.4,0.1-2.8,0.2-4.2,0.3l-3.9,0.3c-1.3,0.1-2.5,0.2-3.6,0.4
	c-6.2,0.9-10.5,2-13.2,3.3c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.3-0.7,0.9-0.9,1.3c-0.2,0.5-0.4,1.2-0.5,2
	c0,0.3-0.1,0.6-0.1,1.1c-0.2,0.8-2.7,1.3-7.6,1.6c-1,0.7-1.4,2.6-1.4,6.1c0.2,1,1.1,1.4,2.4,1.4l0.9,12.8c-1.3,0.4-1.8,1.4-1.5,3
	l0.3,2.1c-1.5,1-2,2.2-1.5,3.4l1.2,1.3c1,6.5,3.4,13.1,7.2,19.6c1.1,2.1,3.6,3.3,7.4,3.7c3.3,0.1,6.5-0.5,9.9-2
	c7.7-1.6,19.4-2.6,34.7-3.2c0.1-1.9,0.2-3.6,0.4-5.4c0.1-1.6,0.2-3.3,0.4-4.8c2-20.3,5-35.4,9.4-45c0.5-2.1,1.3-4,2.1-5.8l-13.3,0.5
	c-5.8,0.2-10.8,0.4-15.2,0.5L86.4,319.1L86.4,319.1z"
          />
          <path
            fill="#EAEAEA"
            d="M75.7,346.7l-8.1-0.1h-0.4c-0.2,0-0.3,0.1-0.5,0.2c-0.7,0.5-1,1.7-0.8,3.8l0.7,6c0.2,2.4,1,3.7,2.3,3.8h7.6
	c0.5,0,1-0.2,1.3-0.7c0.2-0.4,0.4-1.1,0.4-1.9v-1.2l-0.3-5.9C77.8,348,77,346.7,75.7,346.7L75.7,346.7z"
          />
          <path
            fill="#5E5E5E"
            d="M302.6,204.4l-9.7-4.7l-1.3,0.7l9.4,4.9L302.6,204.4z M304.5,214c-15.9,9.8-30.3,21.6-43.4,35.4
	c-3.7,3.9-4.9,7.7-3.6,11.4c0.4,1.2,1.1,2.5,2.2,3.7c0,0.1,0.1,0.1,0.2,0.2l0.1,0.1h0.1c2.1,1.9,5.3,2.7,9.9,2.8l98.1-2
	c3.3,0,5.7-0.6,7.3-1.6c1.9-1.2,2.6-3,2.4-5.3l-1-44.8c-0.4-7.6-4.5-11.6-12.1-12.1h-0.1l-22,1.3c-4,0.3-7.7,0.9-11.3,1.4
	C320.2,206.5,311.2,209.6,304.5,214L304.5,214z M259.9,260c-0.9-2.8,0.1-5.8,3-8.9c2.5-2.6,5-5.2,7.6-7.6c0.1-0.5,0.3-1.1,0.4-1.5
	c0.5-1.2,1.8-2,3.8-2.4c9.6-8.7,19.9-16.5,31-23.4c6.5-4.1,15-7.2,25.6-9c3.6-0.7,7.4-1.1,11.5-1.5l21.6-1.5
	c6.1,0.3,9.2,3.6,9.6,9.7l1,44.8v0.1c0.1,1.3-0.3,2.4-1.3,3c-1.3,0.9-3.3,1.2-5.9,1.3l-84.7,1.7c-0.8,0.8-1.6,1.3-2.8,1.6
	c-0.8,0.2-1.6,0.3-2.6,0.4c-1.1,0.1-2.4,0.1-3.7,0c-1.6-0.4-2.9-1.1-3.9-1.9h-0.3c-3.8-0.1-6.5-0.8-8.3-2.2c-0.1,0-0.1-0.1-0.1-0.1
	C260.7,261.9,260.2,260.9,259.9,260L259.9,260z"
          />
          <path
            fill="#FFFFFF"
            d="M270.5,243.5c-2.6,2.5-5.1,5-7.6,7.6c-2.9,3-3.9,6.1-3,8.9c0.3,1,0.9,1.8,1.6,2.8l0.1,0.1
	c1.7,1.4,4.5,2.2,8.3,2.2h0.3c1,0.8,2.3,1.4,3.9,1.9c1.4,0.1,2.6,0.1,3.7,0c0.9-0.1,1.7-0.2,2.6-0.4c1.2-0.3,2.2-1,2.8-1.6
	c0.8-0.9,1.3-1.9,1.5-3l-0.1-18.1v-1c-0.1-2.1-1.3-3.3-3.4-3.6h-4.1c-0.9,0.1-1.6,0.2-2.4,0.3c-2,0.4-3.3,1.3-3.8,2.4
	C270.7,242.4,270.6,242.9,270.5,243.5L270.5,243.5z M366.8,280.2c-7.7-0.1-15.2,0.2-22.6,1.1c-0.5,0-1,0.2-1.4,0.5
	c-0.3,0.4-0.5,0.9-0.5,1.4v1.1c0,0.5,0.2,1,0.5,1.4c0.4,0.4,0.9,0.5,1.4,0.5c2,0.1,3.9,0.3,5.9,0.3c6.2,0.3,11.9,0.5,17.3,0.4h2.4
	c2.2-0.1,4.4-0.1,6.4-0.2c0.7,0,1.3-0.2,1.7-0.8c0.5-0.4,0.8-1.1,0.8-1.7V283c0-0.7-0.2-1.3-0.8-1.7c-0.4-0.4-1.1-0.8-1.7-0.8
	c-2.2-0.1-4.4-0.2-6.4-0.3C368.7,280.2,367.8,280.2,366.8,280.2L366.8,280.2z M460.1,278.8c-0.5,0-1,0.2-1.4,0.5
	c-0.3,0.4-0.5,0.9-0.5,1.4v1.1c0,0.5,0.2,1,0.5,1.4c0.4,0.4,0.9,0.5,1.4,0.5c2,0.1,3.9,0.2,5.9,0.3c6.2,0.3,11.9,0.5,17.3,0.4h2.4
	c2.2-0.1,4.4-0.1,6.4-0.2c0.7,0,1.3-0.2,1.7-0.8c0.5-0.4,0.8-1.1,0.8-1.7v-1.4c0-0.7-0.2-1.3-0.8-1.7c-0.4-0.4-1.1-0.8-1.7-0.8
	c-2.2-0.1-4.4-0.2-6.4-0.3h-2.9C475.1,277.6,467.5,278,460.1,278.8L460.1,278.8z"
          />
          <path
            fill="#5E5E5E"
            d="M396,255.5c0.1,3.3,1.1,5.5,2.7,7.1c1.6,1.5,4,2.1,7.2,1.9l68.6-1.2h0.1c10.5-1.2,15.9-6.3,16.2-15.1
	c0.5-9.6-1.2-21.9-5.2-36.5v-0.1c-2.8-7.6-9-11.4-18.7-11.5H406c-8.4,0.4-12.5,4.5-12.4,12.2L396,255.5L396,255.5z M405.8,261.9
	c-2.4,0.2-4.1-0.2-5.3-1.2c-1.2-1.1-1.9-2.9-2-5.3L396,212c0-6.1,3.3-9.2,10-9.6h61c8.5,0,13.9,3.3,16.4,9.9
	c4,14.4,5.7,26.2,5.1,35.7c-0.3,7.4-5,11.6-13.9,12.7L405.8,261.9L405.8,261.9z M755.7,340.2v16.6c-0.1,3,2.9,4.1,8.9,3.5h12.6
	c1.6-0.3,3.3,0.5,4.6,2.5l1.6-0.8v2.7l1,1.9l6.6-4.2c1.7-0.8,3.3-3.3,4.5-7.7c0.2-0.5,0.3-0.9,0.3-1.3c0.2-1.3-0.2-2-1.4-1.9
	l2.3-12.1c0-0.5,0-1.1-0.1-1.6s-0.2-1.1-0.3-1.5c-0.7-2.3-2.2-3.5-4.6-3.6h-24.8C760.2,332.8,756.4,335.3,755.7,340.2L755.7,340.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M671.9,347.5c-0.5-0.8-1.2-1.4-1.8-2.1 M670.1,345.5l1.7,2 M539.7,369.6
	c2-29.9,4.6-51.1,8-63.4c0.9-3.3,1.8-5.9,2.9-7.9c0.4-0.8,0.8-1.5,1.2-2.3c2.7-5.3,7.6-9.1,14.4-11.3c2.4-0.8,5-1.4,7.9-1.7
	c0.8-0.1,1.4-0.2,2.2-0.3c0.3,0,0.7-0.1,1-0.1l47.5-2.6c26-1.2,54.2-1.6,84.5-1.4c15,0.1,30.6,0.4,46.7,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M529.8,369.6c2.5-38.6,5.5-64.2,9-76.5c0.9-3.3,1.8-5.9,2.9-7.9
	c0.4-0.8,0.8-1.5,1.2-2.3c2.7-5.3,7.6-9.1,14.4-11.3c2.4-0.8,5-1.4,7.9-1.7c0.8-0.1,1.4-0.2,2.2-0.3c0.3,0,0.7-0.1,1-0.1l47.5-2.6
	c26-1.2,54.2-1.6,84.5-1.4c15,0.1,41.3,2.2,78.9,6.1 M766.6,360.3h-1.8 M766.6,360.3h7.3 M793.9,355.5l0.8-3.9 M773.9,360.3h3.5
	c1.6-0.3,3.3,0.5,4.6,2.5l1.6-0.8v2.7l1,1.9l6.6-4.2c1.7-0.8,3.3-3.3,4.5-7.7c0.2-0.5,0.3-0.9,0.3-1.3c0.2-1.3-0.2-2-1.4-1.9
	 M671.3,384.3c-0.5,0.7-1.2,1.3-1.7,2l-0.8,0.8c-2.8,2.6-5.9,4.6-9.1,5.9 M637.9,392.8c3.4,1.3,7,2,10.9,2s7.5-0.7,10.8-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M648.6,381.4c0.4-0.1,1,0.1,1.6,0.4l7.7,7.4c1.1,1.1,1,2-0.4,2.8
	c-2.7,0.9-5.7,1.3-8.8,1.3c-3,0-6-0.4-8.6-1.3c-1.4-0.9-1.6-1.9-0.4-2.9l7.7-7.4C647.7,381.6,648.1,381.5,648.6,381.4L648.6,381.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M659.6,392.9c1.2-0.7,1.6-1.4,1.5-2.2c-0.1-0.4-0.4-0.9-0.9-1.4l-9.6-9.4
	c-0.9-0.4-1.5-0.7-2.1-0.5c-0.5,0.1-1.1,0.3-1.5,0.5l-9.7,9.2c-0.5,0.5-0.9,1-0.9,1.4c-0.1,0.8,0.4,1.4,1.5,2.1 M646.6,376.1
	c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7
	s-1.2,0.2-1.6,0.7S646.6,375.5,646.6,376.1L646.6,376.1z M655.1,371.2c0.4-0.3,0.9-0.5,1.4-0.5s1.1,0.2,1.4,0.5l0.2,0.2
	c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.6-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6v-0.3
	c0.1-0.5,0.3-1,0.7-1.3C655,371.3,655.1,371.2,655.1,371.2L655.1,371.2z M641.2,370.6c-0.5,0-1,0.2-1.4,0.5c-0.1,0-0.1,0.1-0.2,0.2
	c-0.3,0.3-0.5,0.9-0.7,1.3v0.3c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-0.3
	c-0.1-0.5-0.3-1-0.7-1.3l-0.1-0.1C642.3,370.8,641.8,370.6,641.2,370.6L641.2,370.6z M645.2,359c-0.7,0.3-1.2,0.8-1.7,1.3
	c-1.4,1.4-2.2,3.2-2.2,5.2s0.8,3.8,2.2,5.2c0.2,0.2,0.3,0.3,0.5,0.5c0.9,0.8,2,1.3,3,1.5c0.5,0.1,1,0.1,1.5,0.1s1.1,0,1.5-0.1
	c1.1-0.2,2.2-0.8,3-1.5c0.2-0.1,0.3-0.3,0.5-0.5c1.4-1.4,2.2-3.2,2.2-5.2s-0.8-3.7-2.2-5.2c-0.5-0.5-1.1-1-1.7-1.3
	c-1.1-0.5-2.2-0.9-3.5-0.9C647.4,358.1,646.3,358.4,645.2,359L645.2,359z M643.5,357.5c0,0.7-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7s1.2,0.2,1.6,0.7
	C643.3,356.4,643.5,356.9,643.5,357.5L643.5,357.5z M647.3,352.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7S647.7,352.2,647.3,352.7z M654.9,359.1
	c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7
	c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6S654.5,358.7,654.9,359.1L654.9,359.1z M677.5,359.7c-0.1-0.5-0.2-1.1-0.3-1.6
	 M677.4,359l0.1,0.8 M671.3,384.3c3-3.5,5-7.4,6-11.7c0.1-0.5,0.2-1,0.3-1.5c0.3-1.7,0.4-3.6,0.4-5.5V365v-1v-0.2 M677.5,359.7
	c0.2,1.3,0.4,2.6,0.5,4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664.9,369.4l9.6-4.9c1.1-0.5,1.8-0.4,2.3,0.5v0.3c0,2-0.2,3.9-0.5,5.7
	c-0.1,0.5-0.2,1-0.3,1.5c-1,3.7-2.6,7.1-5.1,10.2c-0.9,0.4-1.5,0.1-2-0.8c0-0.1-0.1-0.1-0.1-0.2l-4.7-9.6c0-0.4,0-0.8,0.1-1.1
	C664.2,370.4,664.5,369.9,664.9,369.4L664.9,369.4z M661.4,363.6c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6
	s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6S661.9,364,661.4,363.6L661.4,363.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M678.1,363.8c-0.2-1.2-0.8-1.9-1.5-2c-0.4,0-1,0.1-1.5,0.4l-12,6.2
	c-0.8,0.7-1.1,1.5-1.2,2.7v0.8l5.9,12c0.2,0.5,0.5,1,1,1.2c0,0,0.1,0,0.1,0.1c0.7,0.3,1.5,0.1,2.5-0.8 M673.9,350.5
	c1.4,2.3,2.5,4.8,3.2,7.4 M677.1,357.9c0.1,0.3,0.2,0.8,0.2,1.1 M677.2,358.1l-0.1-0.2 M677.4,359c-0.1-0.3-0.1-0.7-0.2-1
	 M673.9,350.5c0-0.1-0.1-0.2-0.1-0.2c-0.7-1-1.3-2-2-2.8 M697.4,367.9l10.8-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M599.9,367.1c0,1.9,0.2,3.7,0.4,5.4c0.4,3.5,1.3,6.9,2.5,10
	c2.4,6.3,6.1,12.1,11.3,17.3c1,1,2.1,2,3.2,2.9c8.9,7.6,19.4,11.4,31.3,11.4c13.5,0,25-4.8,34.5-14.2c7.8-7.8,12.4-17,13.8-27.3
	c0.1-0.5,0.1-1,0.2-1.5c0.1-1.1,0.2-2.2,0.2-3.2 M708.2,367.7v2.5h4.9l1.5-2.5c13.7,0.1,26.4-0.5,38.2-2.1l3.5-2.7l12.6-2l0.8,1
	c2.5-0.2,3.9-0.8,4.4-1.6 M708.2,367.7c0.1-17.7-1.4-31.4-4.5-40.9c-3.9-12.6-11.4-20.4-22.5-23.5c-1.3-0.3-2.6-0.7-4-0.9
	c-20-1.5-37.1-1.5-51.3,0c-0.8,0.1-1.5,0.2-2.2,0.2c-2.9,0.4-5.5,1-7.9,1.7c-6.9,2.2-11.6,6-14.4,11.3c-0.4,0.8-0.9,1.4-1.2,2.3
	c-5.2,10.3-8.2,26.6-8.9,49.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M697.4,367.9v-2.5c0-13.5-4.8-25-14.2-34.5c-1.6-1.6-3.3-3-5-4.4
	c-8.5-6.6-18.4-9.9-29.6-9.9s-21.1,3.3-29.6,9.9c-1.7,1.3-3.4,2.7-4.9,4.4c-1,1-2,2.1-2.9,3.2c-1.7,2.1-3.3,4.1-4.7,6.3
	c-1.5,2.5-2.8,5.1-3.8,7.8c-2,5.3-2.9,11.1-2.9,17.2v1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M648.7,333.2c-7.7,0-14.6,2.4-20.3,7.2c-0.9,0.7-1.6,1.4-2.5,2.2
	c-6.3,6.3-9.5,13.9-9.5,22.8c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.2,1.5c1.3,5.9,4.1,11.1,8.7,15.6c6.3,6.3,13.9,9.5,22.7,9.5
	c7.6,0,14.4-2.3,20-7c0.9-0.8,1.8-1.6,2.7-2.5c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.3-1.5c0.3-1.8,0.4-3.7,0.4-5.7
	c0-8.9-3.2-16.5-9.5-22.8C665.1,336.4,657.6,333.2,648.7,333.2L648.7,333.2z M670,345.4C670,345.4,670.1,345.5,670,345.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M655.3,340.4c-0.1-1.3,0.8-1.9,2.5-1.6c1.3,0.4,2.6,1,3.8,1.6
	c2.5,1.3,4.8,3,6.9,5.2l0.2,0.2c1.2,1.2,2.2,2.5,3.2,3.8l0.1,0.1c0.4,1.3,0,2-1.4,2.2l-10.5,1.3c-1.3-0.2-2.1-0.7-2.4-1.2
	c-0.2-0.5-0.4-0.9-0.4-0.9l-1.8-10.3L655.3,340.4L655.3,340.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M655.9,337.1c-1.3-0.2-2.1,0-2.4,0.7c-0.2,0.4-0.3,1-0.2,1.9l2.3,12.9
	c0,0,0.2,0.4,0.5,1.1s1.3,1.2,2.9,1.5l13.3-1.6c0.8-0.1,1.2-0.2,1.5-0.5c0.5-0.5,0.5-1.3,0.1-2.4 M670.1,345.5
	c-0.2-0.2-0.4-0.4-0.7-0.7l-0.8-0.8c-1.5-1.4-3.2-2.7-4.8-3.7c-2.5-1.5-5.1-2.6-7.9-3.3h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M641.4,337c2.3-0.5,4.7-0.9,7.3-0.9c5.8,0,10.9,1.4,15.3,4.2
	c1.6,1.1,3.3,2.3,4.7,3.7l0.7,0.7l0.1,0.1l0.5,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M655.9,337.1c-2.3-0.5-4.7-0.9-7.1-0.9c-2.5,0-4.9,0.3-7.2,0.9
	 M641.5,337.1l-0.1-0.1 M641.5,337.1C641.5,337.1,641.6,337.1,641.5,337.1 M641.6,337.1C641.6,337.1,641.5,337.1,641.6,337.1
	 M642,340.4c0.1-1.2-0.5-1.7-2.1-1.7c-1.4,0.4-2.8,1.1-4.2,1.7c-2.5,1.3-4.8,3-7,5.2c-1.2,1.2-2.3,2.5-3.3,3.8
	c-0.7,1.5-0.2,2.4,1.3,2.5l10.5,1.2c1.1-0.2,2-0.9,2.6-2l1.8-10.4C642,340.7,642,340.6,642,340.4L642,340.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M623.6,350.3c-0.5,1.1-0.5,2,0,2.5c0.3,0.3,0.9,0.5,1.6,0.7l13.3,1.5
	c1.3-0.2,2.4-1.1,3.3-2.4l2.3-12.9c0.1-0.8,0.1-1.4-0.1-1.7c-0.3-0.5-1.1-0.8-2.2-0.7 M778.2,319.9c1.3-21.4,1.6-37.5,1.2-48.3
	 M777.4,332.4c0.3-4.4,0.5-8.6,0.9-12.5 M794.7,351.6l2.3-12.1c0-0.5,0-1.1-0.1-1.6 M796.9,337.8c-0.1-0.5-0.2-1.1-0.3-1.5
	 M796.5,336.3c-0.7-2.3-2.2-3.5-4.6-3.6h-13.4 M778.6,332.8h-11.4c-7.1,0.1-10.9,2.5-11.4,7.4v16.6c-0.1,3,2.9,4.1,8.9,3.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.8,360.3l0.3-20.2v-0.3c0-1.5,0.5-2.6,1.5-3.3c0.9-0.7,2.4-1,4.2-1
	l25.7,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M796.9,337.8l-26-0.9c-1.5,0-2.7,0.2-3.5,0.7c-0.7,0.4-0.9,1.1-0.9,2v20.6
	 M756.1,279.4c-1.3,1.7-1.8,3.7-1.5,6.1l-1,27c-0.1,4.9,2,7.3,6.2,7.2l18.4,0.3 M779.3,271.6l-13.5,2.4c-4.8,1.2-8,2.9-9.9,5.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M778.6,332.8l3.8-24.8c1.2-20.3,0.4-35.8-2.3-46.6c-0.5-3.6-2-5.7-4.1-6
	 M774.3,255.4c0.5-0.2,1.1-0.2,1.5,0 M776,255.4c-0.4-0.1-1-0.1-1.5,0 M779.3,271.6c-0.4-9.7-1.6-15.1-3.5-16.2 M342.6,203.1
	l21.9-1.5h0.1c7.6,0.4,11.6,4.5,12.1,12.1l1,44.8c0.2,2.4-0.5,4.1-2.4,5.3c-1.5,1.1-3.9,1.6-7.3,1.6l-98.1,2c-4.6-0.1-7.8-1-9.9-2.8
	h-0.1l-0.1-0.1l-0.2-0.2c-1-1.2-1.7-2.5-2.2-3.7c-1.3-3.6-0.1-7.4,3.6-11.4c13.1-13.7,27.4-25.6,43.4-35.4
	c6.9-4.4,15.8-7.5,26.9-9.4C334.9,204,338.6,203.6,342.6,203.1L342.6,203.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.2,264.8l84.7-1.7c2.6,0,4.7-0.4,5.9-1.3c1-0.8,1.4-1.7,1.3-3v-0.1
	l-1-44.8c-0.3-6.1-3.6-9.4-9.6-9.7l-21.6,1.5c-4.1,0.3-7.9,0.9-11.5,1.5c-10.5,1.9-19.1,4.9-25.6,9c-11.2,6.7-21.5,14.6-31.1,23.3
	 M393.5,212.1c-0.1-7.7,4-11.7,12.4-12.2h60.9c9.7,0,15.9,3.8,18.7,11.5v0.1c4.1,14.7,5.9,26.9,5.2,36.5
	c-0.3,8.8-5.7,13.8-16.2,15.1h-0.1l-68.6,1.2c-3.2,0.3-5.5-0.3-7.2-1.9c-1.7-1.4-2.6-3.8-2.7-7.1L393.5,212.1L393.5,212.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M506.7,202.8c-0.1-5.2-2.3-9-6.4-11.5c-6-2.9-16.8-4.1-32.1-3.8h-66.6
	c-4.9-0.2-8.7,0.2-11.5,1.1l-30.2,0.5c-36.2,0.5-58.5,4.1-66.9,10.8 M405.9,202.4c-6.6,0.3-10,3.6-10,9.6l2.5,43.4
	c0.1,2.4,0.8,4.2,2,5.3c1.2,1.1,3,1.4,5.3,1.2h0.1l68.6-1.2c8.9-1.1,13.5-5.3,13.9-12.7c0.5-9.4-1.1-21.3-5.1-35.7
	c-2.5-6.5-7.9-9.9-16.4-9.9H405.9L405.9,202.4z M506.7,202.8c-2.5-7.2-7.9-10.8-16.4-11 M490.1,191.7L383.4,193 M274.8,239.4
	c-2,0.4-3.3,1.3-3.8,2.4c-0.2,0.5-0.3,1-0.4,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268.6,254.5c0.3-4.1,1-7.7,2-11v-0.1 M274.8,239.4
	c0.8-0.2,1.5-0.3,2.4-0.3h4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M281.3,239.1c-2.5,0.4-4.1,1.3-4.7,2.6c-0.2,0.5-0.3,1.1-0.4,1.6
	c-1,4.4-1.5,9.4-1.4,15.2 M383.4,193c-38.4,0.4-64.8,4-79.3,10.7c-0.4,0.3-1,0.5-1.4,0.9 M302.6,204.4c-0.5,0.3-1.1,0.5-1.6,0.9
	 M283.2,264.8c0.8-0.9,1.3-1.8,1.5-3l-0.1-18.1v-1c-0.1-2.1-1.3-3.3-3.4-3.6 M301.1,205.3l-9.4-4.8 M292.9,199.9l-1.3,0.7
	 M302.6,204.4l-9.7-4.6 M301.1,205.3c-19.4,11.1-38.5,25.7-57.2,43.5 M280.4,266.5c1.2-0.3,2.2-1,2.8-1.6 M268.4,262.7h-4.8
	c-0.7,0-1-0.3-1-1v-6.3c0-0.7,0.3-1,1-1h4.9 M268.2,258.8c0.1-1.5,0.1-2.9,0.2-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M270.5,243.5c-2.6,2.5-5.1,5-7.6,7.6c-2.9,3-3.9,6.1-3,8.9
	c0.3,1,0.9,1.8,1.6,2.8l0.1,0.1c1.7,1.4,4.5,2.2,8.3,2.2h0.3 M270.2,265.1c-0.9-0.7-1.4-1.4-1.7-2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268.4,262.7c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.2-0.1-0.4-0.1-0.5
	 M270.2,265.1c1,0.8,2.3,1.4,3.9,1.9c1.4,0.1,2.6,0.1,3.7,0c0.9-0.1,1.7-0.2,2.6-0.4 M274.8,261.5c0,0.2,0.1,0.4,0.2,0.7
	c0.3,1.1,1.1,2.1,2.2,2.8c0.9,0.7,2,1.2,3.3,1.5 M268.1,261.7c0-1,0-2,0.1-2.9 M274.8,258.6c-0.2,1.1-0.1,2,0.1,2.9 M268.1,261.7
	c-0.1-0.9-0.1-2,0.1-2.9 M274.8,261.5c0-1-0.1-2-0.1-2.9 M213.6,258c-1.7,0.1-3.6-0.3-5.3-1.1c-0.2-0.9-0.5-1.5-0.8-2.1
	 M207.5,254.9c-0.2-0.3-0.4-0.7-0.7-0.9c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.1-0.7-0.2-0.9-0.2c-4.1-1.1-8.8-1.9-13.9-2.2
	c-25.3-2-58.3,0.8-98.9,8.2c-9.2,1.6-16.1,3.3-20.6,4.8c-5.9,2.2-9.8,6.2-11.9,12 M243.9,248.9c-6.9,6-16.8,9-29.8,9.1h-0.3 M94,281
	c3.4-2.8,7.9-4.7,13.7-5.7c34.3-5.2,60.7-8,79.5-8.4c2.5-0.1,4.9-0.1,7.2-0.1h2.7c14.1,0.7,23.1,4.9,26.6,12.6
	c0.2,0.5,0.5,1.1,0.8,1.7c0.4,1,0.8,2,1.2,2.9c1.3,3.6,2.6,7.7,3.7,12.4c0.1,0.5,0.2,1.2,0.4,1.9c0.3,1.9,0.8,3.8,1.1,5.8
	c1.7,10.1,3.2,22.8,4.1,38.2c-5.8-26.1-10.1-43-12.9-50.6c-0.3-1-0.8-2-1.2-2.9c-0.2-0.5-0.5-1.2-0.8-1.7
	c-3.6-7.7-12.5-12-26.6-12.6h-2.7c-2.3,0-4.7,0-7.2,0.1c-18.8,0.3-45.2,2.3-79.5,6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M227.3,364.3c-2.8-22.4-6.4-38.9-10.9-49.6c-0.2-0.5-0.5-1.2-0.8-1.7
	c-3.6-7.7-12.5-12-26.6-12.6h-2.7c-19.9-0.1-36.4,1-49.6,3.3c-7,1.1-12.4,4.8-16.1,11.2c-0.5,1-1.1,2.1-1.6,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M144.4,350.3c-0.5,1.1-0.5,2,0,2.5c0.3,0.3,0.9,0.5,1.6,0.7l13.3,1.5
	c0.4-0.1,0.9-0.2,1.3-0.4c0.8-0.4,1.4-1.1,2-2l2.3-12.9c0.1-0.8,0.1-1.4-0.1-1.7c-0.3-0.5-1.1-0.8-2.2-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M160.8,338.6c-1.4,0.4-2.8,1.1-4.2,1.7c-2.5,1.3-4.8,3-7,5.2
	c-1.2,1.2-2.3,2.5-3.3,3.8c-0.7,1.5-0.2,2.4,1.3,2.5l10.6,1.2c1.1-0.2,2-0.9,2.6-2l1.9-10.4c0-0.1,0-0.2,0.1-0.4
	C162.9,339.2,162.3,338.6,160.8,338.6L160.8,338.6z M162.5,337.1C162.4,337.1,162.4,337.1,162.5,337.1h-0.1 M162.5,337.1h-0.1
	 M162.4,337.1l-0.1-0.1 M176.6,337.1c-2.3-0.5-4.7-0.9-7.1-0.9c-2.5,0-4.9,0.3-7.2,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M162.3,337c2.3-0.5,4.7-0.9,7.3-0.9c5.8,0,10.9,1.4,15.3,4.2
	c1.6,1.1,3.3,2.3,4.7,3.7l0.6,0.7l0.1,0.1l0.5,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M191,345.5c-0.2-0.2-0.4-0.4-0.7-0.7c-0.2-0.2-0.5-0.5-0.8-0.8
	c-1.5-1.4-3.2-2.7-4.8-3.7c-2.5-1.5-5.1-2.6-7.9-3.3h-0.1 M176.6,337.1c-1.3-0.2-2.1,0-2.4,0.7c-0.2,0.4-0.3,1-0.2,1.9l2.3,12.9
	c0,0,0.2,0.4,0.5,1.1c0.2,0.4,0.8,0.9,1.6,1.2c0.3,0.1,0.8,0.2,1.3,0.3l13.3-1.6c0.8-0.1,1.2-0.2,1.5-0.5c0.5-0.5,0.5-1.3,0.1-2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M176.1,340.4c-0.1-1.3,0.8-1.9,2.5-1.6c1.3,0.4,2.6,1,3.8,1.6
	c2.5,1.3,4.8,3,6.9,5.2l0.2,0.2c1.2,1.2,2.2,2.5,3.2,3.8l0.1,0.1c0.4,1.3,0,2-1.4,2.2l-10.6,1.3c-1.3-0.2-2.1-0.7-2.4-1.2
	c-0.2-0.5-0.4-0.9-0.4-0.9l-1.9-10.3L176.1,340.4L176.1,340.4z M190.8,345.4l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M169.6,333.2c-7.7,0-14.6,2.4-20.3,7.2c-0.9,0.7-1.6,1.4-2.5,2.2
	c-6.3,6.3-9.5,13.9-9.5,22.8c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.3,1.5c1.3,5.9,4.1,11.1,8.7,15.6c6.3,6.3,13.9,9.5,22.7,9.5
	c7.6,0,14.4-2.3,20-7c0.9-0.8,1.8-1.6,2.7-2.5c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.3-1.5c0.3-1.8,0.4-3.7,0.4-5.7
	c0-8.9-3.2-16.5-9.5-22.8C186,336.4,178.4,333.2,169.6,333.2L169.6,333.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M218.3,366.9v-1.5c0-13.5-4.8-25-14.2-34.5c-1.6-1.6-3.3-3-4.9-4.4
	c-8.5-6.6-18.4-9.9-29.6-9.9c-11.2,0-21.1,3.3-29.6,9.9c-1.7,1.3-3.4,2.7-4.9,4.4c-1,1-2,2.1-2.9,3.2c-1.7,2.1-3.3,4.1-4.7,6.3
	c-1.5,2.5-2.8,5.1-3.8,7.8c-2,5.3-2.9,11.1-2.9,17.2c0,2.4,0.1,4.7,0.4,6.9 M227.7,366.6c0.1,1,0.2,2,0.3,3h281 M227.7,366.6
	c-0.1-0.8-0.2-1.6-0.3-2.4 M121.2,372.4v0.3c0.4,3.5,1.3,6.9,2.5,10c2.4,6.3,6.1,12.1,11.3,17.3c1,1,2.1,2,3.2,2.9
	c8.9,7.6,19.4,11.4,31.3,11.4c13.5,0,25-4.8,34.5-14.2c7.8-7.8,12.4-17,13.8-27.3c0.1-0.5,0.1-1,0.2-1.5c0.1-1.4,0.2-2.7,0.2-4.1
	 M218.3,366.9l9.4-0.3 M198,358.1v-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M194.8,350.5c0-0.1-0.1-0.2-0.1-0.2c-0.7-1-1.3-2-2-2.8 M194.8,350.5
	c1.4,2.3,2.5,4.8,3.2,7.4 M198.8,363.8c-0.2-1.2-0.8-1.9-1.5-2c-0.4,0-1,0.1-1.5,0.4l-12,6.2c-0.8,0.7-1.1,1.5-1.2,2.7v0.8l5.9,12
	c0.2,0.5,0.5,1,1,1.2c0,0,0.1,0,0.1,0.1c0.6,0.3,1.5,0.1,2.5-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M185.8,369.4l9.6-4.9c1.1-0.5,1.9-0.4,2.3,0.5v0.3c0,2-0.2,3.9-0.5,5.7
	c-0.1,0.5-0.2,1-0.3,1.5c-1,3.7-2.6,7.1-5.1,10.2c-0.9,0.4-1.5,0.1-2-0.8c0-0.1-0.1-0.1-0.1-0.2l-4.7-9.6c0-0.4,0-0.8,0.1-1.1
	C185,370.4,185.3,369.9,185.8,369.4L185.8,369.4z M182.2,366.8c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7
	c-0.3,0-0.7,0-0.9,0.2c-0.2,0.1-0.5,0.2-0.8,0.5c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.2,0.2,0.4,0.4,0.8,0.5
	c0.3,0.1,0.5,0.1,0.9,0.1C181.2,367.5,181.8,367.3,182.2,366.8L182.2,366.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M192.1,384.3c3-3.5,5-7.4,6-11.7c0.1-0.5,0.2-1,0.3-1.5
	c0.3-1.7,0.4-3.6,0.4-5.5V365v-1v-0.2 M198,358.1c0.4,1.9,0.8,3.7,0.9,5.7 M198,357.9c0.1,0.5,0.2,1.2,0.4,1.8
	c-0.1-0.5-0.2-1.1-0.3-1.6 M172,354.3c0-0.3,0-0.7-0.1-0.9c-0.1-0.2-0.3-0.5-0.5-0.8c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7
	c-0.4,0.4-0.7,1-0.7,1.6c0,0.3,0,0.7,0.2,0.9c0.1,0.3,0.2,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7
	c0.2-0.2,0.4-0.4,0.5-0.8L172,354.3L172,354.3z M175.8,355.9c-0.2,0.2-0.4,0.4-0.5,0.8c-0.1,0.2-0.2,0.5-0.2,0.9
	c0,0.7,0.2,1.2,0.7,1.6s1,0.7,1.6,0.7c0.3,0,0.7,0,0.9-0.2c0.2-0.1,0.5-0.3,0.8-0.5c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6
	c-0.2-0.2-0.4-0.3-0.8-0.5c-0.2-0.1-0.5-0.2-0.9-0.2C176.9,355.2,176.3,355.4,175.8,355.9L175.8,355.9z M162,355.2
	c0.7,0,1.2,0.2,1.6,0.7c0.2,0.2,0.4,0.4,0.5,0.8c0.1,0.2,0.1,0.5,0.1,0.9c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7
	c-0.3,0-0.5,0-0.9-0.1c-0.2-0.1-0.5-0.3-0.8-0.5c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.2-0.2,0.4-0.4,0.8-0.5
	C161.4,355.3,161.6,355.2,162,355.2L162,355.2z M166.1,359c-0.5,0.3-1.1,0.7-1.5,1.2c-0.1,0-0.1,0.1-0.2,0.1c-1.4,1.4-2.2,3-2.2,5
	v0.1c0,2.1,0.8,3.8,2.2,5.2l0.5,0.5c0.9,0.8,2,1.3,3,1.5c0.5,0.1,1,0.1,1.5,0.1h0.1c0.4,0,1-0.1,1.4-0.1c1.1-0.2,2.2-0.8,3-1.5
	c0.2-0.1,0.3-0.3,0.5-0.5l0.1-0.1c1.3-1.4,2-3,2-5v-0.1c0-2-0.8-3.7-2.2-5.1c-0.5-0.5-1.1-1-1.7-1.3c-1-0.5-2.2-0.9-3.4-0.9h-0.1
	C168.3,358.1,167.1,358.4,166.1,359L166.1,359z M160.4,363.6c-0.2-0.2-0.4-0.3-0.8-0.5c-0.2-0.1-0.5-0.2-0.9-0.2
	c-0.7,0-1.2,0.2-1.6,0.7s-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.3,0,0.7,0,0.9-0.1s0.5-0.3,0.8-0.5
	c0.4-0.4,0.7-1,0.7-1.6C161.1,364.5,160.9,364,160.4,363.6L160.4,363.6z M160.5,371.2c-0.1,0-0.1,0.1-0.2,0.2
	c-0.3,0.3-0.5,0.9-0.7,1.3v0.3c0,0.6,0.2,1.2,0.7,1.6s1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.2-0.2,0.4-0.4,0.5-0.8
	c0.1-0.2,0.2-0.5,0.2-0.9v-0.3c-0.1-0.5-0.3-1-0.7-1.3l-0.1-0.1c-0.4-0.3-0.9-0.5-1.4-0.5c-0.2,0-0.5,0-0.8,0.1
	C161,370.8,160.8,371,160.5,371.2L160.5,371.2z M179,371.3c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.2-0.2-0.4-0.4-0.5-0.8c-0.1-0.2-0.2-0.5-0.2-0.9v-0.3c0.1-0.5,0.3-1,0.7-1.3
	c0,0,0.1-0.1,0.2-0.1c0.4-0.3,0.9-0.5,1.4-0.5c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.7,0.3C178.9,371.2,178.9,371.3,179,371.3
	L179,371.3z M171.9,375.2c-0.1-0.2-0.3-0.5-0.5-0.8c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7c-0.2,0.2-0.3,0.4-0.5,0.8
	c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6
	C172,375.7,172,375.5,171.9,375.2L171.9,375.2z M180.5,392.9c1.2-0.7,1.6-1.4,1.5-2.2c-0.1-0.4-0.4-0.9-0.9-1.4l-9.6-9.4
	c-0.7-0.3-1.3-0.5-1.7-0.5h-0.3c-0.5,0.1-1.1,0.3-1.5,0.5l-9.7,9.2c-0.5,0.5-0.9,1-0.9,1.4c-0.1,0.8,0.4,1.4,1.5,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M169.4,381.4c0.4-0.1,1,0.1,1.6,0.4l7.7,7.4c1.1,1.1,1,2-0.4,2.8
	c-2.7,0.9-5.7,1.3-8.8,1.3c-3,0-6-0.4-8.6-1.3c-1.4-0.9-1.6-1.9-0.4-2.9l7.7-7.4C168.5,381.6,168.9,381.5,169.4,381.4L169.4,381.4z
	 M192.1,384.3c-0.5,0.7-1.2,1.3-1.7,2l-0.8,0.8c-2.8,2.6-5.9,4.6-9.1,5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M158.7,392.8c3.4,1.3,7,2,10.9,2s7.5-0.7,10.8-2 M243.9,364.3h-16.5
	 M191,345.5l1.6,2 M192.6,347.5c-0.5-0.8-1.2-1.4-1.9-2.1 M94,281l-13.1,0.9 M80.9,281.9L80.9,281.9l-6.7,0.5 M108.8,298.4l-0.1-2.5
	c-0.2-2.7-0.4-5.2-0.9-7.6c-0.2-1.5-0.5-3-0.9-4.5c-0.3-2-1.3-3-2.8-3.5 M106.9,299.2v0.4c0.2,4.4,0.1,9.2-0.2,14.7
	c-11.1,1.3-22.5,1.8-34.2,1.7c-0.3-5.7-0.2-11.1,0.2-16.4c0-0.3,0.1-0.8,0.1-1.1c0.5-4.9,1.4-9.7,2.7-14.4l24.5-1.7
	c0.1,0,0.2,0,0.4-0.1c2.6-0.2,4.1,0.8,4.6,3.2c0.2,1.3,0.5,2.6,0.8,4c0.3,2.1,0.5,4.2,0.7,6.6 M104.1,280.5c-0.8-0.2-1.6-0.2-2.6,0
	L94,281"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M213.6,258c-49.9,3-85.7,6.7-107.4,11.2c-13.6,2.5-22.1,6.6-25.5,12.6
	 M106.9,299.2v-0.5 M106.9,298.6v0.5 M108.9,299.6l-0.1-1.2 M108.9,299.6v-1.2 M108.7,316.1c0-0.5,0-1,0.1-1.5
	c0.2-5.5,0.2-10.6,0.1-15 M119.5,316.1h-10.9 M119.1,317.9c-4.8,0.2-9.1,0.3-13.3,0.5c-5.8,0.2-10.8,0.4-15.2,0.5
	c-1.4,0-2.8,0.1-4.2,0.1c-1.3,0-2.5,0.1-3.8,0.1c-1.4,0.1-2.8,0.2-4.2,0.3c-1.3,0.1-2.6,0.2-3.9,0.3s-2.5,0.2-3.6,0.4 M119.1,317.9
	c-0.8,1.7-1.5,3.7-2.1,5.8c-4.3,9.6-7.4,24.7-9.4,45 M162.3,337c-2.8,0.7-5.5,1.9-8.1,3.4c-2,1.2-3.7,2.6-5.3,4.4
	c-1.7,1.7-3.2,3.6-4.4,5.5 M106.9,298.6l-0.2-2.5 M56.8,283.5l-0.9,7.5 M55.9,291h-1.4c-0.2,1.1-0.3,1.7-0.3,2 M54.9,293.1h-0.8
	 M54.5,297.5l0.4-4.5 M56.8,281.5c-1.3,0.7-1.3,1.3,0,2 M74.2,282.4v0.5 M74.2,282.4v0.5 M74.2,282.4c0.2-2.5-0.4-4.1-2.2-5.1
	c-1-0.5-2.3-0.9-3.9-0.9h-7.5 M60.6,276.4v0.1c-2.2,0.4-3.4,2.2-3.7,5 M56.8,283.5H61c0.8,0,1.3,0.1,1.6,0.3
	c0.2,0.2,0.3,0.4,0.3,0.8l-0.4,5c0,0.5-0.1,1-0.4,1.2c-0.3,0.2-0.9,0.2-1.5,0.2h-4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M56.8,281.5H61c1.3,0,2.3,0.2,2.9,0.8c0.8,0.5,1.2,1.3,1.2,2.4l-0.4,5
	c0,1.3-0.4,2.2-1.2,2.7c-0.7,0.5-1.7,0.8-3,0.5H55 M106.7,296.1c-10,0.9-20.2,1.3-30.7,1.2c-0.3,0-0.7,0.1-1,0.3
	c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.7,0.3,0.9s0.4,0.3,0.8,0.3h0.2c10.6,0.1,20.8-0.3,30.9-1.2 M74.2,283
	c-1.5,4.8-2.6,9.7-3.3,14.7 M70.9,297.5H54.5 M56.1,321.1c0.2-0.1,0.4-0.1,0.7-0.1c1.4,0,2.5-0.3,3.4-1c1.1-0.8,1.7-2.1,2-3.9
	L63,307v-0.1c0.1-2-1.2-3-3.8-3.3H54"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M54.3,305.7h4.6c1.2,0.1,1.8,0.5,1.8,1.2l-0.9,9c-0.1,1.2-0.5,2.1-1.2,2.6
	c-0.5,0.3-1.4,0.5-2.8,0.5 M70.7,320.4v-2.5 M70.7,299.7c-0.7,6-0.5,12.1,0.1,18.3 M53.5,303.6h0.3 M53.8,303.6
	c-0.1,0.1-0.2,0.1-0.3,0.2 M53.8,303.6l0.4-3.9 M53.5,303.6v0.2 M53.5,303.8c-0.8,0.5-0.8,1.2,0.1,1.7h0.7 M56,319.1
	c-1.3,0.7-1.2,1.4,0.2,2.1 M54.3,305.7l1.7,13.4 M47,330.3c4.9-0.3,7.4-0.9,7.7-1.6c0-0.3,0-0.8,0.1-1.1 M55.4,325.7
	c0.2-0.5,0.5-1,0.9-1.3c0.2-0.1,0.3-0.3,0.5-0.4s0.5-0.3,0.8-0.4c2.6-1.3,7.1-2.4,13.2-3.3 M55.4,325.7c-0.2,0.5-0.4,1.2-0.5,2
	 M47,330.3c-1,0.7-1.4,2.6-1.4,6.1c0.2,1,1.1,1.4,2.4,1.4l0.9,12.8c-1.3,0.4-1.8,1.4-1.5,3l0.5,2.2 M54.8,327.7
	c-3.8,0.9-6.4,1.7-7.7,2.7 M61.7,344.7c-2,0-3,1.1-3,3.5c0,0.4,0,0.9,0.1,1.3v0.4l1.2,7.4c0.5,3,1.8,4.7,4.1,4.7h13.2
	c2.2,0,3.3-1.5,3-4.7l-0.5-7.3c-0.2-3.5-1.6-5.2-4-5.2L61.7,344.7L61.7,344.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M67.7,346.6h-0.4c-0.2,0-0.3,0.1-0.5,0.2c-0.7,0.5-1,1.7-0.8,3.8l0.7,6
	c0.2,2.4,1,3.7,2.3,3.8h7.6c0.5,0,1-0.2,1.3-0.7c0.2-0.4,0.4-1.1,0.4-1.8v-1.2l-0.3-5.9c-0.2-2.7-0.9-4.1-2.3-4.1L67.7,346.6
	L67.7,346.6z M56.1,321.1c-1.4,0.4-1.6,2-0.9,4.6 M54.3,299.7h16.4 M54.2,293.1c-0.5,3.6-0.9,7.2-0.7,10.7 M70.9,297.5
	c-0.1,0.7-0.2,1.4-0.2,2.1 M54.3,299.7l0.2-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M107.7,368.6c-0.7,1.3-1.6,1.7-3,1.4H63.8c-2.6,0-4.2-1.2-5-3.7L55,355.8
	h-7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M47.9,355.8c-1.5,1-2,2.2-1.5,3.4l1.2,1.3c1,6.5,3.4,13.1,7.2,19.6
	c1.1,2.1,3.6,3.3,7.4,3.7c3.3,0.1,6.5-0.5,9.9-2c7.7-1.6,19.4-2.6,34.7-3.2c0.1-1.9,0.2-3.6,0.4-5.4 M140.3,364v2l0.1,1.5
	c0,1,0.1,2,0.3,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M147.4,384.7c0.3,0.2,0.7,0.4,1,0.5c0.4,0.2,0.9,0.1,1.2,0
	c0.4-0.2,0.8-0.6,1.1-1.3l5.8-12.1c0.1-0.2,0.1-0.4,0.1-0.7c0.1-1-0.2-1.9-1.1-2.6c-0.1-0.1-0.2-0.1-0.2-0.2l-11.7-6
	c-0.7-0.3-1.2-0.5-1.7-0.3c-0.8,0.1-1.2,0.9-1.4,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M141.4,366c0,1.9,0.2,3.6,0.5,5.2c0.1,0.5,0.2,1,0.3,1.5
	c0.9,3.6,2.5,6.9,4.9,9.9c1.1,0.9,1.9,0.7,2.5-0.7l4.6-9.6c0.1-0.3,0.1-0.8,0.1-1.1c-0.1-0.7-0.4-1.2-1.1-1.9l-9.4-4.8
	c-1.3-0.7-2.1-0.3-2.4,1.1C141.4,365.7,141.4,365.8,141.4,366L141.4,366z M140.6,370.4l0.1,0.9 M140.6,370.4c0,0.2,0.1,0.5,0.1,0.8
	c0.1,0.5,0.2,1,0.3,1.5 M140.3,364c0.2-5.1,1.6-9.7,4.1-13.8 M141.2,372.9c1,3.8,2.7,7.4,5.3,10.7l0.9,1.1 M141.1,372.6v0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M140.8,371.3c0.1,0.4,0.2,0.9,0.3,1.4c0,0.1,0,0.2,0.1,0.3 M158.7,392.8
	c-3.6-1.4-6.9-3.6-9.8-6.5c-0.2-0.2-0.5-0.5-0.8-0.8l-0.8-0.9 M140.8,371.3c0.1,0.4,0.2,0.9,0.3,1.4 M107.7,368.6
	c-0.1,1.5-0.3,3.2-0.4,4.8 M107.2,373.3l13.9-1 M70.7,317.9l38-1.7 M641.4,337c-2.8,0.7-5.5,1.9-8,3.4c-2,1.2-3.7,2.6-5.3,4.4
	c-1.7,1.7-3.2,3.6-4.3,5.5 M508.9,369.6c2.8-0.1,4.1-1.8,3.7-5.2c2.4-28.5,3.6-60,3.7-94.6c-0.1-3.5-0.2-6.9-0.4-10.1 M485.7,277.7
	c2.2,0.1,4.4,0.2,6.4,0.3c0.7,0,1.3,0.2,1.7,0.8c0.5,0.5,0.8,1.1,0.8,1.7v1.4c0,0.7-0.2,1.3-0.8,1.7c-0.4,0.5-1.1,0.8-1.7,0.8
	c-2.1,0.1-4.2,0.2-6.4,0.2 M485.7,284.7h-2.4 M482.7,277.7h2.9 M485.7,284.7v-7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M386.4,364.3H500c1-11.1,1.6-23.7,2-37.7c0-1.4,0-2.8,0.1-4.2v-0.2
	c2.3-55.7-1.7-99.2-11.9-130.4 M774.3,255.4l-247.6,1c-5.8,0.1-9.4,1.2-10.8,3.3 M529.8,369.6h9.9 M508.9,369.6h20.9 M639.6,363.6
	c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6C640.3,364.5,640.1,364,639.6,363.6L639.6,363.6z M632.4,369.3l-9.4-4.8c-1.3-0.7-2.1-0.3-2.4,1.1v0.2
	c0,1.9,0.2,3.6,0.5,5.2c0.1,0.5,0.2,1,0.3,1.5c0.9,3.6,2.5,6.9,4.9,9.9c1.1,0.9,1.8,0.7,2.5-0.7l4.6-9.6c0.1-0.3,0.1-0.8,0.1-1.1
	C633.4,370.5,633,369.9,632.4,369.3L632.4,369.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626.6,384.7c0.9,0.7,1.5,0.9,2.2,0.5c0.4-0.2,0.8-0.6,1.1-1.3l5.8-12.1
	c0.1-0.2,0.1-0.4,0.1-0.7c0.1-1-0.3-2-1.3-2.8l-11.7-6c-0.7-0.3-1.2-0.5-1.7-0.3c-0.8,0.1-1.2,0.9-1.4,2.1 M619.5,364v2l0.1,1.5
	c0,1,0.1,2,0.3,2.9l0.1,0.7 M620,371.1c0.1,0.5,0.2,1.1,0.3,1.5 M620.3,372.6c-0.1-0.2-0.1-0.5-0.2-0.8 M620.3,372.9
	c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.1-0.5-0.2-0.8 M620.3,372.6v0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M637.9,392.8c-3.6-1.4-6.9-3.6-9.8-6.5l-0.8-0.8l-0.8-0.9 M620.3,372.9
	c1,3.8,2.7,7.4,5.3,10.7l0.9,1.1 M619.5,364c0.2-5.1,1.6-9.7,4.1-13.8 M620.1,371.9L620,371 M539.7,369.6h48.1
	c1.7,0,2.9-0.9,3.6-2.5 M591.3,367.3l8.6-0.1 M466,284.3c-2-0.1-3.8-0.2-5.9-0.3c-0.5,0-1-0.2-1.4-0.5c-0.3-0.3-0.5-0.9-0.5-1.4V281
	c0-0.5,0.2-1,0.5-1.4c0.4-0.3,0.9-0.5,1.4-0.5c7.4-0.9,15-1.2,22.6-1.1 M482.7,277.7c-0.4-2.1-2.1-3.4-4.9-3.7
	c-2.3-0.3-4.7-0.4-7.2-0.1c-2.7,0.3-4.2,1.6-4.6,4 M483.3,284.7c-0.8,2.6-3,3.9-6.7,4.1h-4.2c-3.4-0.4-5.5-2-6.2-4.6 M369.7,280.2
	c2.2,0.1,4.4,0.2,6.4,0.3c0.7,0,1.3,0.2,1.7,0.8c0.5,0.5,0.8,1.1,0.8,1.7v1.4c0,0.6-0.2,1.3-0.8,1.7c-0.4,0.5-1.1,0.8-1.7,0.8
	c-2.1,0.1-4.2,0.2-6.4,0.2 M369.7,280.2h-2.9 M366.8,280.2c-0.4-2.1-2.1-3.4-4.9-3.7c-2.3-0.3-4.7-0.4-7.2-0.1
	c-2.7,0.3-4.2,1.6-4.6,4 M369.7,287.2h-2.4 M369.7,287.2v-7 M350,286.8c-2-0.1-3.8-0.2-5.9-0.3c-0.5,0-1-0.2-1.4-0.5
	c-0.3-0.3-0.5-0.9-0.5-1.4v-1.1c0-0.5,0.2-1,0.5-1.4c0.4-0.3,0.9-0.5,1.4-0.5c7.4-0.9,15-1.2,22.6-1.1 M367.3,287.2
	c-0.8,2.6-3,3.9-6.7,4.1h-4.2c-3.4-0.4-5.5-2-6.2-4.6 M367.3,287.2c-5.3,0.1-11.1-0.1-17.3-0.4 M483.3,284.7
	c-5.3,0.1-11.1-0.1-17.3-0.4 M291.7,200.5c-20.5,10.6-48.5,28.6-84.2,54.4 M516,259.7c-1.2-22.3-4.4-41.3-9.4-56.9 M243.9,248.9
	c-10.4,29.4-10.4,67.9,0,115.4 M243.9,364.3h142.5 M383.4,193c3.4,49.4,4.4,106.5,2.9,171.3"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M268.6,254.5h-4.9
		c0,0-1-0.1-1,1s0,6.3,0,6.3s0,1,1,1s4.8,0,4.8,0s0.7,1.7,2.5,2.9c0,0,1.4,1,3.6,1.3c0,0,4,0.5,7.3-1.1c0,0,2.6-1.3,2.9-4.1
		l-0.1-18.3c0,0,0.2-1.8-1.1-3.3c0,0-0.7-0.9-2.4-1.1H277c0,0-5.1,0.2-6.2,2.8l-0.7,2.1C270.3,243.9,269.2,247,268.6,254.5z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M283.3,264.8
		l21.5-0.4l24-0.4l23.7-0.4l16.3-0.3c0,0,6.1,0.2,6.2-3.8l-0.3-12.4l-0.3-16.5l-0.2-11.4l-0.1-5.2c0,0,0.1-4.5-3-7.2
		c0,0-2.3-2.3-6.6-2.3l-22.2,1.5l-4.5,0.4c0,0-4.9,0.7-7.2,1.1c0,0-12.2,1.6-22.1,7.2c0,0-6.4,3.6-18.3,12.2c0,0-12.9,10-15.3,12.6
		l2.1-0.2h4.2c0,0,1.7,0.2,2.6,1.3c0,0,1,1,0.8,2.9l0.1,18.6C284.8,262,284.3,263.8,283.3,264.8z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M405.9,261.9
		c0,0-4.1,0.7-6.1-1.8c0,0-1.2-1.1-1.3-3.8L396,212c0,0-0.3-4.7,2.8-7.3c0,0,2.2-2.2,7.5-2.4h37.5h23.9c0,0,5.7,0,9.6,2.4
		c0,0,4,2,6.1,7.4c0,0,3.6,12.3,4.8,23.8c0,0,1.1,10.4-0.1,15.1c0,0-1.1,8.2-13.4,9.5l-31.4,0.5L405.9,261.9z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M119.9,316.1h-11.2
		c0,0,0.4-9.2,0.1-18.2c0,0,0.1-4.9-1.8-14.5c0,0-0.2-2-2.2-2.8c0,0-1.1-0.5-3.2-0.2l-7.5,0.5l-13.1,1c0,0,3.4-6.6,15.2-10.1
		c0,0,6.9-2.5,31.8-6.1c0,0,14.2-2.1,46.1-4.9c0,0,28.1-2.2,39.6-2.7c0,0,19.6,0.5,30.2-9.2c0,0-3.6,9-5.9,23.8c0,0-2.4,14.5-1.8,35
		c0,0,0.9,20.4,3.8,36.3c0,0,2.4,14.4,3.9,20.3h-16.4c0,0-1.6-14.9-5.9-33.6c0,0-1.4-6.9-5.3-16.8c0,0-2-6.5-10.2-10.1
		c0,0-6.3-3.3-18.1-3.5c0,0-11.2-0.2-25.7,0.5c0,0-7.7,0.3-19.3,1.7c0,0-6.4,1-6.9,1.1c-0.4,0.1-5.2,0.8-9.6,4.2
		C126.4,307.9,122.8,310.5,119.9,316.1z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M386.5,265.1l-18.8,0.4
		l-95.9,2l-33.1,0.7c0,0-2.3,12-2.6,28.8v10.2c0,0,0.8,14.6,1.8,22.7c0,0,1.4,10.8,2.9,19.4c0,0,2.2,11.6,3,14.9h58h43.3h41.1
		l0.4-28.1l0.1-23.2l-0.1-24.9L386.5,265.1z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M386.5,265.1l18.7-0.7
		l48.4-1l20.3-0.3l27.8-0.9c0,0,0.8,14.9,0.8,25.1c0,0,0,20.9-0.5,34.7c0,0-0.2,12.3-0.5,18.5c-0.3,6.3-1,17.8-1.5,23.7h-31.1H425
		h-38.7l0.3-22.2l0.2-24.8l-0.1-22.7l-0.2-22.2L386.5,265.1L386.5,265.1z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M508.9,369.6
		c0,0,4.5,0.2,3.7-5.2c0,0,2.7-37.4,3.2-57.5l0.4-22.7l0.1-14.5l-0.4-10.1c0,0,1.6-2.6,7.9-3l3.3-0.1l47.2-0.2l40.7-0.1l48.1-0.2
		l49.1-0.2l44.4-0.2l19.5-0.1c0,0,1.5,0.7,2.3,5.1c0,0,0.7,2.9,1,8.4l0.1,2.7l-12.8,2.3c0,0-4.5,0.8-8.3,3.4c0,0-4.6,2.8-3.7,8.2
		l-1,27c0,0-0.3,3.8,1.7,5.8c0,0,1.3,1.4,4,1.4l18.8,0.3l-0.9,12.8h-10.8c0,0-4.4-0.1-7.5,1.9c0,0-2.9,1.6-3.3,5.5v17.1
		c0,0,0.1,2.7,4.2,3.2c0,0,1.7,0.3,4.7-0.1h0.8l-0.5,1.2l-8.7,1.4l-3.5,2.8c0,0-16.9,2.5-38.2,2.1l-1.5,2.5h-4.9
		c0,0,0.1-11.6-0.3-17.6c0,0-0.5-14.6-4.2-26c0,0-2.7-10.8-11.2-17.5c0,0-5.7-5.1-15.2-6.4c0,0-17.9-1.3-27.4-1.1
		c0,0-11.4-0.2-24.8,1.2c0,0-12.6,0.7-19.9,8.3c0,0-5,4-9,18.5c0,0-1.3,5.1-1.6,6.7c0,0-2,10.9-2.4,17.8l-0.7,10l-0.1,3.3
		c0,0-0.7,2.5-3.6,2.5h-35.1h-12.9h-9.9h-20.7L508.9,369.6L508.9,369.6z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="169.6"
              cy="365.4"
              r="48.8"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="648.7"
              cy="365.3"
              r="48.9"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(CrewCabPickup4DrLeft);
