import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#5E5E5E"
            d="M173.8,394.8c22.6,0,41.3-16.3,45-37.8c0.5-2.6,0.7-5.2,0.7-8c0-25.2-20.4-45.8-45.8-45.8
	c-19.4,0-35.9,12-42.6,29c-2,5.2-3.2,10.8-3.2,16.8C128.1,374.2,148.5,394.8,173.8,394.8L173.8,394.8z M582.6,350
	c0.5,24.9,20.8,44.8,45.8,44.8c22.5,0,41.2-16.2,45-37.6c0.5-2.7,0.8-5.4,0.8-8.2c0-25.2-20.4-45.8-45.8-45.8
	c-25.2,0-45.8,20.4-45.8,45.8V350z"
          />
          <path
            fill="#FFFFFF"
            d="M557.6,340.1c0,0-82.8-1.2-84.5-1.2c-1.2-0.1-1.9-0.3-2.2-1.1H467c-0.4,3.1-1.2,3.4-5,3.4
	c-4.1,0-216.2-0.5-216.1-0.5c0,1-0.4,1.8-1.3,1.9c-1.6,0.2-8.1,0.6-9.8,0.6c-1.7,0.1-2.3-0.2-2.6-2.6c-0.3-2.5-1.6-15.6-4.5-24.4
	c-2.9-8.8-7.2-16.6-14-23.2c-6.8-6.6-21.2-12.7-39.8-12.7c-18.7,0-31,5.5-37.1,10.3c-6,4.9-7.4,7.4-7.9,8.1
	c-0.3,0.4-0.6,0.7-1.2,0.9l-2.8,3.6l0.1,0.1c0.5,0.6-0.8,2-0.8,1.9c0,0-2.1,3.6-3.7,7.7c-1.9,4.9-3.1,8.5-4,13s-1.4,9.5-1.7,13.1
	c-0.2,2.3-0.5,2.9-1.1,3.1c-0.5,0.1-0.8,0.6-0.8,0.6s-0.1,5.2-1,9c-1,4.2-1.2,5.7-2.4,7.9c-1.2,2.1-1.8,3.3-8.1,3.4
	c-6.3,0.1-22.8-0.1-22.8-0.1s-0.3,0-0.3-0.5v-1.8c0,0,0-0.4,0.3-0.7c0.3-0.2,1.2-1,1.2-1s0.5-0.3,0.3-1.2c-0.1-0.9-1.3-8.4-1.7-9.9
	c-0.4-1.3-0.5-1.3-1.6-2.4c-1.1-1-3.3-2.1-3.7-2.4c-0.4-0.3-0.4-0.3-0.4-0.5v-0.3c0,0-2.8,0.2-4.8-1.2c-2-1.4-2.5-2.9-2.5-2.9
	l-1.9-0.3c0,0-0.4,0.1-0.4-0.5s-0.8-18.8-0.8-19.4c0-0.6,0-1,0.4-1c0.4,0,0.6-0.1,0.6-0.1s0.1-1.1,0.1-1.9c0-1.3,1.4-1.7,1.4-1.7
	s0.4-0.9,2.3-1.1c1.9-0.2,4.1-0.7,4.1-0.7s0.6-1.1,0.8-1.7c0.2-0.6,0.5-1.1,0.5-1.1l-0.6-0.5c0,0-0.4-0.2-0.4-0.7v-2.9
	c0-0.4,0.2-0.8,0.5-0.8l4.6-1.1v-1.1c0,0-1.5,0.1-1.5-0.7v-1.9c0-0.9,2.3-1.1,2.3-1.1v-1c0,0-2.6,0-3.7-0.1
	c-1.2-0.1-2.5-0.9-2.6-2.7c-0.1-1.9,0-5.7,0.1-6.5c0.1-0.9,0.1-1.3,0.1-1.3s-0.8-1.3-1-4.2c-0.1-2.9,0-5.6,0.4-7
	c0.4-1.4,1.3-2.9,1.3-2.9s0.1-6,0.3-7.6c0.2-1.5,1.2-2.9,2.5-3c1.2-0.1,3.4-0.3,3.4-0.3v-0.4c0,0-1.3-0.1-1.3-1.1
	c0-1.1,0-2.2,0.7-2.7c0.6-0.5,0.9-0.7,0.9-0.7l-0.1-0.8c0,0-2.6-1-3.3-1.1s-1.1-0.4-0.4-2.2c0.6-1.8,2.1-4.4,6-6.7
	c3.9-2.3,5.4-2.6,5.4-2.6l0.4-0.3c0,0,7.2-2.7,16.7-4.3s23.7-3.7,42.8-5.6c19.1-1.8,33.1-2.7,33.1-2.7s3.2-1.5,7.1-1.8
	c3.9-0.3,20.2-1.1,20.2-1.1s0.4,0.1,1,0.4c0.6,0.3,2.5,1.4,3.1,1.8c0.6,0.4,1,0.9,1,0.9l2.1-1.5c0,0,3.3-0.2,6.5-0.5
	s4.1-0.5,4.1-0.5l0.6-1.2l-0.3-0.3c0,0-0.3-0.4-0.2-0.7c0.2-0.3,1.1-1.2,1.2-1.4c0.2-0.3,0.4-0.5,0.7-0.3l0.4,0.4l0.1,0.3
	c0,0,0.5-0.2,0.9,0c0.4,0.2,0.9,0.8,0.9,0.8l0.5-0.6c0,0,0.3-0.2,0.7-0.2c0.4,0,1.2-0.1,1.2-0.1l0.3-0.4h1.2
	c0,0,17.4-13.1,30.2-22.6c12.9-9.4,33.3-23.3,33.3-23.3s-0.7-0.4,0.2-0.8c0.9-0.4,2.3-0.6,2.3-0.6s3.6-2.2,7.3-3.1
	c3.6-0.9,7.6-1.8,7.6-1.8s0.5-0.8,2.9-1.4c3.2-0.9,3.6,0.1,3.6,0.1s1.6-0.2,3.7-0.5s3.9-0.5,3.9-0.5s0.5-0.4,1.7-0.6
	c1.2-0.3,6.9-1.1,7.8-1.2s1.1-0.2,3.2-0.3c1.9-0.1,18-0.6,20.5-0.6s5.1,0.3,7.8,0.4c2.7,0.1,3.6,0.2,4,0.2s0.6,0.4,0.6,0.4l5.8-0.1
	c0,0,0.2-0.4,0.5-0.5c0.5-0.1,8.6-1,11.8-1.1c3.2-0.1,23.2,0.2,29.2,0.3c5.9,0.1,7.4,0.5,9.8,0.8c2.4,0.3,3.3,0.3,3.7,0.4
	c0.5,0.1,1,0.5,1,0.5s13.1,0.1,17.1,0.3c3.9,0.3,5.6,2.4,5.6,2.4s0.5-0.1,1.3,0.2c0.7,0.2,1,0.2,1.3,0.3c0.4,0.1,1.2,0.4,1.2,1.2
	c0.1,0.9,0,2.2,0,3s0,1-0.5,1.1c-0.4,0.1-0.9,0.3-0.9,0.3l0.2,0.6c0,0,0.8,1.1,1.5,4.1c0.8,3.1,0.8,5.4,0.8,5.4s1.8,10,2.7,17.4
	c0.9,7.7,1.9,14.9,2.1,25.1c0,3.6,0.1,8.3,0.1,13.4h4.7l0.1-1.4l0.1-0.4h0.3l1.3-0.1c0.4-0.7,1-1,1.6-1h79.2l0.4-0.2
	c0,0,186.3-0.3,186.3,0s0.5,0.5,0.5,0.5h10.6c0,0,0.4-0.3,0.9-0.3s9,0.1,9.7,0.1s1,0.2,1.2,0.9c0.3,0.8,0.5,2.1-0.1,2.6
	s-1.2,1.1-1.2,1.5s0,0.8,0,0.8s0.6-0.1,0.7,0.2c0.1,0.3,1.2,6.8,1.2,6.8s1.2,0.4,1.4,0.7c0.4,0.3,0.7,1,0.7,1.4
	c0.1,0.6,0.1,1.2,0.2,1.7c0.1,0.5-0.1,0.7-0.4,0.7h-0.9c0,0,0.4,2.3,0.5,3.2c0.1,1,0,1.4,0,1.4s0.3,2.9,0.3,3.4
	c0.1,0.5-0.4,0.8-0.4,0.8s0.2,2.6,0.3,3.8c0.1,1.2,0,2.7,0,2.7s1.1,1.3,1.2,4.4c0.2,5.4-1.1,6.1-1.1,6.1s-0.4,4.7-0.6,7.3
	s-0.2,3.1-0.3,3.6c-0.1,0.5-0.8,1.1-0.8,1.1s0.6,0.3,0.5,1.3c0,1.2-1.2,8.9-1.4,10c-0.2,1.1-0.2,1.8-0.9,1.8h-1.2l-3.1,28.4
	l-10.8-0.2c-0.2,0.9-0.5,1.2-2,1.2c-2,0-19.1,0.2-26.8,0.5c-7.8,0.3-20.5,1.2-20.5,1.1c0.2,1.2,0.1,2.5-1.2,2.7
	c-1.9,0.3-3.2,0.3-3.2,0.3s-0.5,0.1-0.8-0.6c-0.3-0.7-0.8-1.7-0.8-1.7l-3.3,0.1l-0.6,1.9c0,0-0.7,0.8-1.7,0.8
	c-0.7,0-1.8-0.1-2.3-0.1c0,0-2,0.6-1.1-2.1c1-2.7,1.8-3.3,1.5-7.6c-0.3-4.3-0.7-14.8-4.6-24.8c-3.9-10-9.2-15.5-20.2-21.9
	c-11-6.5-24.8-6.6-32.6-6.5c-7.9,0.1-25.8,1.9-36.7,11c-10.8,9-14.8,20.7-16.3,29.2c-1.7,9.7-2.6,18.5-2.7,19.7
	c-0.2,1.2-0.1,2.5-1.1,2.5c-1,0-2-0.2-2-0.2s-0.3-0.2-0.4-0.6c-0.1-0.4-0.4-1.6-0.4-1.6l-3.2-0.1c0,0-0.7,1.5-0.8,1.8
	c-0.2,0.4-0.3,0.7-1,0.7s-3.6-0.1-3.6-0.1C558.6,342.6,557.4,342.8,557.6,340.1L557.6,340.1z"
          />
          <path
            fill="#FFFFFF"
            d="M200.2,229.9c-5.8,0.2-21.4,1.1-21.4,1.1l-0.2-0.3h0.3c0,0,3.2-1.5,7.1-1.8s20.2-1.1,20.2-1.1s0.4,0.1,1,0.4
	c0.6,0.3,2.5,1.4,3.1,1.8c0.2,0.1,0.4,0.3,0.5,0.4C210.6,230.3,205.9,229.6,200.2,229.9L200.2,229.9z"
          />
          <path
            fill="#5E5E5E"
            d="M70.9,342.3h42.3c-0.2,0.2-0.3,0.4-0.3,0.4s-0.1,5.2-1,9c-1,4.2-1.2,5.7-2.4,7.9c-1.2,2.1-1.8,3.3-8.1,3.4
	c-6.3,0.1-22.8-0.1-22.8-0.1s-0.3,0-0.3-0.5v-1.8c0,0,0-0.4,0.3-0.7c0.3-0.2,1.2-1,1.2-1s0.5-0.3,0.3-1.2c-0.1-0.9-1.3-8.4-1.7-9.9
	c-0.4-1.3-0.5-1.3-1.6-2.4c-1.1-1-3.3-2.1-3.7-2.4c-0.4-0.3-0.4-0.3-0.4-0.5v-0.3C72.9,342.2,72,342.4,70.9,342.3L70.9,342.3z"
          />
          <path
            fill="#5E5E5E"
            d="M72.7,309.2l0.5-0.9l1.3-1.5l1-0.6l1.6-0.6l1.7-0.5l1.5-0.3l1.1-0.3l1.1-0.6l1.3-0.4l2.7-0.4l6.3-0.6
	L125,303l0.1,0.1c0.5,0.6-0.8,2-0.8,1.9c0,0-2.1,3.6-3.7,7.7c-1.9,4.9-3.1,8.5-4,13s-1.4,9.5-1.7,13.1c-0.2,2.3-0.5,2.9-1.1,3.1
	h-0.1l-40.9-0.1v0.4c0,0-2.8,0.2-4.8-1.2c-2-1.4-2.5-2.9-2.5-2.9l-1.9-0.3c0,0-0.4,0.1-0.4-0.5s-0.8-18.8-0.8-19.4s0-1,0.4-1
	s0.6-0.1,0.6-0.1s0.1-1.1,0.1-1.9c0-1.3,1.4-1.7,1.4-1.7s0.4-0.9,2.3-1.1c1.9-0.2,4.1-0.7,4.1-0.7s0.6-1.1,0.8-1.7
	c0.2-0.6,0.5-1.1,0.5-1.1L72.7,309.2L72.7,309.2z"
          />
          <path
            fill="#FFFFFF"
            d="M459.4,177.6c-0.8-2.4-1.6-4.4-2.7-6l0.1-0.1c0,0,0.5-0.1,1.3,0.2c0.7,0.2,1,0.2,1.3,0.3
	c0.4,0.1,1.2,0.4,1.2,1.2c0.1,0.9,0,2.2,0,3s0,1-0.5,1.1C459.9,177.4,459.4,177.6,459.4,177.6L459.4,177.6L459.4,177.6z"
          />
          <path
            fill="#EAEBEB"
            d="M146.5,349c0,15.1,12.2,27.4,27.4,27.4c15.1,0,27.4-12.2,27.4-27.4c0-15.1-12.2-27.4-27.4-27.4
	S146.5,333.9,146.5,349L146.5,349z M628.4,376.4c15.1,0,27.4-12.2,27.4-27.4c0-15.1-12.2-27.4-27.4-27.4
	c-15.1,0-27.4,12.2-27.4,27.4C601,364.1,613.2,376.4,628.4,376.4L628.4,376.4z"
          />
          <path
            fill="#5E5E5E"
            d="M673.4,357.2c1-0.1,3-0.2,5.6-0.4c16.5-1.2,60.1-4,61.5-4.1c1.8-0.2,2.5-0.8,2.5-0.8s0.2,0.1,0.5,0.3
	c1.3,0.7,5.1,2.6,5.6,2.9c0.6,0.4,1.2,0.4,1.2,0.4H761v-2h13.8v-0.8h1.1v0.7h1.8v-8.6h-1.8v0.5h-4l-0.1-0.5l-10.6-0.2v-0.6
	c-0.1-1-1.1-1.2-1.7-1.4c-0.7-0.3-2.7-0.7-5-1.4s-2.6-1.7-3.3-2.7c-0.7-1-0.3-0.9-0.3-0.9v-0.1h-3.2c-0.1,0.8-0.4,1.2-1.4,1.2h-1.1
	l-29.2,0.7c-2.9,0.1-6,0.3-8.8,0.5c-4.2,0.2-7.8,0.5-8.7,0.5c0.1,0.9,0.1,1.6-0.4,2.2c-0.2,0.2-0.5,0.4-1,0.5
	c-1.9,0.3-3.2,0.3-3.2,0.3s-0.5,0.1-0.8-0.6c-0.3-0.7-0.8-1.7-0.8-1.7l-3.3,0.1l-0.6,1.9c0,0-0.7,0.8-1.7,0.8
	c-1.2,0-2.3-0.1-2.3-0.1s-2,0.6-1.1-2.1c1-2.7,1.8-3.3,1.5-7.6c-0.1-1.2-0.1-2.7-0.3-4.5c-0.4-5.2-1.4-12.9-4.3-20.3
	c-3.9-10-9.2-15.5-20.2-21.9c-11-6.5-24.8-6.6-32.6-6.5c-7.9,0.1-25.8,1.9-36.7,11c-5.5,4.6-9.1,9.8-11.7,15
	c-2.5,5.1-3.8,10.1-4.6,14.3c-1.7,9.7-2.6,18.5-2.7,19.7v0.2c-0.1,1.1-0.1,2.2-1.1,2.2c-1,0-2-0.2-2-0.2s-0.3-0.2-0.4-0.6
	s-0.4-1.6-0.4-1.6l-3.2-0.1c0,0-0.7,1.5-0.8,1.8c-0.2,0.4-0.3,0.7-1,0.7s-3.6-0.1-3.6-0.1h-0.2c-0.8-0.1-1.4-0.2-1.7-1.1
	c-0.1-0.4-0.2-0.9-0.1-1.6c-0.2,0-1.5,0-4-0.1c-16.5-0.2-78.9-1.1-80.4-1.2c-1.2-0.1-1.9-0.3-2.2-1.1H467c-0.3,3.1-1.2,3.4-5,3.4
	c-2.6,0-146-0.2-216-0.4c0.5,2-2.7,2.2-6.8,2.2c-1.8,0.1-3.6,0.2-4.4,0.2c-1.7,0.1-2.3-0.2-2.6-2.6c-0.3-2.5-1.6-15.6-4.5-24.4
	c-2.9-8.8-7.2-16.6-14-23.2c-6.8-6.6-21.2-12.7-39.8-12.7c-18.7,0-31,5.5-37.1,10.3c-6,4.9-7.4,7.4-7.9,8.1c0,0.1-0.1,0.1-0.1,0.2
	c-0.3,0.3-0.6,0.6-1.1,0.7l-2.8,3.6c0,0,0.1,0,0.1,0.1c0.5,0.6-0.8,2-0.8,1.9c0,0-2.1,3.6-3.7,7.7c-1.9,4.9-3.1,8.5-4,13
	s-1.4,9.5-1.7,13.1c-0.2,2.4-0.5,3-1.1,3.1h-0.1c-0.3,0.1-0.5,0.3-0.6,0.4l0.1,0.1h0.7c1.2-0.2,1.6-2.3,1.7-3.9
	c0.1-1.6,0.2-4.1,0.2-4.1l7.6-3l7.9,0.7c6.7-17,23.2-29,42.6-29c25.2,0,45.8,20.4,45.8,45.8c0,2.7-0.2,5.4-0.7,8l13.9-9.5h237.9v5
	c0,0.8,0.6,0.7,0.9,0.7c0.3,0,0.2-0.6,0.2-0.6l77.2-0.1c0,0.7,0.5,0.5,0.5,0.5s0.5,0.3,0.5-0.3V348l8.8,0.6l24,1.4v-1
	c0-2.1,0.1-4.1,0.4-6.2c1.8-13.2,9.2-24.6,19.8-31.7c7.3-4.9,16.1-7.9,25.6-7.9c25.2,0,45.8,20.4,45.8,45.8
	C674.1,351.8,673.9,354.5,673.4,357.2L673.4,357.2z"
          />
          <path
            fill="#5E5E5E"
            d="M751,331.9l-0.3-0.2l-0.3,4l0.5,1.6c5.6,0.2,20.7,0.4,20.7,0.4v1.8c-0.1,0.5,0.1,0.5,0.6,0.6
	c0.5,0.2,1.9,0.5,3.1,0.7c1.1,0.2,2.1-0.9,2.5-1.8c0.4-1,0.3-1.2,0.4-4.6c0.1-3.4,0.4-9.5,0.4-12.1s-0.2-4.8-0.2-4.8
	s0.1-0.6,0.2-1.3c0.1-0.8,0.1-1.5,0.1-2.9c-0.1-1.3-0.9-1.4-0.9-1.4h-1.1c0,0-0.4-0.6-1.2-0.7c-0.6,0-13.5-0.2-18.5-0.3
	c-1.2,0.1-2.1,0.5-2.7,1.2c-0.6,1.5-1.1,3-0.7,4.3C752.5,316.9,751.7,325.1,751,331.9L751,331.9z"
          />
          <path
            fill="#FFFFFF"
            d="M762.6,246.4c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.5,0.7-1.1,1.2-1.5c0.6-0.5,0.4-1.9,0.1-2.6
	c-0.3-0.8-0.6-0.9-1.2-0.9c-0.4,0-3.6,0-6.3-0.1l1,1.2c0.2,0,0.3,0.1,0.4,0.1c1.2,0.3,2.2,3.3,2.6,4c0.1,0.2,0.1,0.3,0.1,0.5
	L762.6,246.4L762.6,246.4z M765.4,258.2h0.2v-0.1h0.9c0.3,0,0.5-0.3,0.4-0.7c-0.1-0.5-0.1-1.2-0.2-1.7c-0.1-0.6-0.4-1.2-0.7-1.4
	c-0.3-0.2-1.1-0.6-1.3-0.7C764.7,254.2,765.2,256.5,765.4,258.2L765.4,258.2z"
          />
          <path
            fill="#EAEBEB"
            d="M765.7,291.2c0.1-1.3,0.2-3.4,0.4-4.9c0.1-1.3,0.2-2.4,0.2-2.4s1.2-0.9,1.1-6.1c-0.1-3-1.2-4.4-1.2-4.4
	s0.1-1.4,0-2.7c0-0.4-0.1-1-0.1-1.5c0-0.2-0.3-1.8-0.3-2c-0.3-2-0.6-5.4-1.2-9.4v-0.1c-0.2-0.2-1.2-1.1-1.6-1.3
	c-0.6-0.4-1.2-0.2-1.2-0.2c-1.2,0.5-4.1,1.7-5.5,3.3c-1.5,1.7-2.8,4-3.3,7.7c-0.4,2.8-2,11.7-2.5,16.6c-0.5,4.9-0.3,7.9,0.7,9.2
	c1,1.3,2.8,3.2,5.6,3.2c2.2,0,6.4-0.1,8-0.1l-0.1-0.1c0,0,0.7-0.6,0.8-1.1S765.7,293.8,765.7,291.2
	C765.7,291.3,765.7,291.2,765.7,291.2L765.7,291.2z"
          />
          <path
            fill="#FFFFFF"
            d="M320.1,172.7c0.6-0.1,0.1-0.5-0.2-0.9c-0.3-0.4-0.8-0.6-1-0.7c-0.7-0.4-2.3-0.1-3.8,0.2
	c-1.5,0.4-2,1.1-2.3,1.3c-0.2,0.3-0.4,0.9-0.4,1.1s0.3,0.4,1,0.3C314.1,173.9,319.5,172.7,320.1,172.7L320.1,172.7z M328.1,170.6
	c1.4,0,10-0.2,12.5-0.3c2.6-0.1,29-1.1,30.4-1.2c1,0,1.3-0.3,1.4-0.5c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.3-0.3-0.6-0.3
	c-0.4-0.1-1.3-0.1-4-0.2c-2.7-0.1-5.2-0.4-7.8-0.4c-2.6,0-18.5,0.5-20.5,0.6c-1.9,0-2.3,0.2-3.2,0.3s-6.5,1-7.8,1.2
	c-1.2,0.3-1.7,0.6-1.7,0.6v0.3C327,170.4,327.3,170.6,328.1,170.6L328.1,170.6z M376.5,239.5c0,0-0.1-15.7,0.1-25.8
	c0.2-10,0.3-18.8,0.2-24.5c-0.1-5.7-3.8-7.4-8.4-7.4s-21.5,0.3-29.2,0.5s-16.1,1.1-23,3.5c-6.8,2.3-15.3,8.8-21.6,13.6
	c-6,4.5-15.1,11.9-16,12.8l-0.5,0.1H278c-3.6,0.6-5.3,3.8-5.9,6.1h-0.5l-1,0.6l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-3.8,3l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l0.7,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
	c-2.1,3.5-3.7,11.7-4,13.7v2c0,0.1,0.1,0.1,0.2,0.2l0.1,0.1h0.2c0.1,0.1,0.2,0.1,0.2,0.2l0.1,0.1h0.2c0.1,0,0.2,0.1,0.2,0.1h0.1
	c0.1,0,0.1,0,0.2,0.1h18.9c8.4-0.1,17-0.2,20.2-0.1c1.2,0,2.4,0,3.5-0.1c4-0.4,6-1.4,7.9-2.5c2.3-1.2,6.6-2.9,10.5-3.9
	s11.4-1.2,20.7-1.2s30.6-0.4,34.9-0.5c3-0.1,3.6-1.7,3.7-2.4L376.5,239.5L376.5,239.5z M381.8,169.2c2.5-0.1,49.1,0.3,50.7,0.3
	c0.9,0,1.3-0.1,1.5-0.2c0.2-0.1,0.4-0.3,0.5-0.4h-0.2c0,0-0.5-0.5-1-0.5c-0.5-0.1-1.3-0.1-3.7-0.4c-2.4-0.3-3.9-0.7-9.8-0.8
	c-5.9-0.1-26-0.4-29.2-0.3c-3.2,0.1-11.3,1-11.8,1.1c-0.4,0.1-0.5,0.5-0.5,0.5h-0.1c0,0.2,0,0.3,0.1,0.3
	C378.6,168.8,379.8,169.3,381.8,169.2L381.8,169.2z M383.9,255.1h-10.4c-1.6,0-2.7,1.2-2.7,2.8l0.1,15c0,1.6,1.2,2.9,2.8,2.9h10.2
	c1.6,0,2.8-1.2,2.8-2.9l0.1-15C386.8,256.4,385.5,255.1,383.9,255.1L383.9,255.1z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M231.7,224.5h-0.2c2.3,3.1,6.6,5.5,11.5,7.9c0.3-0.2,0.6-0.5,1-0.8c1.1-0.8,3.6-3,8.5-7.5
		c5-4.4,16.6-14.1,25.1-21c4.3-3.6,10.5-8.1,15.6-11.9c5.1-3.7,9.4-6.8,10.4-7.6c1.9-1.6,1.2-1.9-0.5-2.6c-1.6-0.8-8-2.5-8-2.5h0.1
		c0,0-0.8,0.5-2,1.3c-5.5,3.7-20.8,14.3-31.3,22C249.2,211.3,231.7,224.5,231.7,224.5L231.7,224.5z M394.7,187.1
		c0.1,4.7,0.4,12.8,0.6,18.7c0.2,6.1,0.5,19.8,0.7,26.7c0.2,6.9,5.2,8.4,10.3,8.4c0,0,21.4-0.1,28.8,0c7.5,0.1,8.2-5.8,8.3-8.5
		c0.1-2.8-0.7-16.4-1.2-24.5c-0.5-8.2-1.3-15.7-2-21.8s-4.9-6.7-8.5-6.8s-26.1-0.1-30,0.1C397.7,179.5,394.6,182.5,394.7,187.1
		L394.7,187.1z M293.4,202.6c-5.6,4.3-10.7,8.4-11.5,9.1c2.5-0.2,4.6-0.3,5.8-0.2c1.7,0.1,3.6,0.2,4.2,3.5c0.5,3.2,0.9,5.7,1.5,14
		l0.1,1.2c0.8,9.2,1.1,13,1,14.8c0,1.2-0.3,1.9-1.1,2.5c-0.2,0.2-0.5,0.3-0.8,0.4h0.8c1.2,0,3.1,0,4.9-0.1c2.8-0.1,4.5-0.8,6.7-1.7
		c2.2-1.1,8.2-5.3,21.9-5.5c11.8-0.2,39.7-0.6,47.6-0.7c0-1.4,0.4-16.6,0.4-25.3c0.1-9.2,0.2-21.7,0-25.9c-0.2-4.2-3-5.1-6.6-5.2
		c-3.6-0.2-20.1,0.4-29.7,0.8s-14.2,1.1-20.4,2.9c-6.1,1.7-12.9,6.6-20.4,12.4C296.3,200.3,294.9,201.5,293.4,202.6L293.4,202.6z
		 M76.9,321.6c0.1,2.9,0.1,5,0.2,6.3c0.1,1.3,0.1,4,2.7,4.2c2.6,0.2,9.5,0.1,11,0.1c1.5,0.1,4.1-0.2,4.2-4.5c0-2.2-0.1-6.5-0.3-10.5
		v-0.6c-0.2-0.7-0.9-2.6-3.4-2.7c-3-0.1-7,0.1-8.4,0.1c-1.2,0-2.4,0.2-3.5,1.5C78.5,317.1,76.8,318.8,76.9,321.6L76.9,321.6z
		 M91.8,298.1h10c0,0,0.8,0.3,1.2-1.1c0-0.2,0.1-0.4,0.2-0.7c0.7-2.2,3-8.1,8.9-16c0.6-0.8,1.2-1.5,1.6-2.2c0.4-0.6,0.9-1.1,1.2-1.5
		c4.9-5.9,7.7-7.3,8.7-8.1c1.2-1,1.8-1.3,1.9-3.7l0.3-8.6c-15.5,0.3-28.2,0.5-32.6,0.6v2c0,0-0.4,0.4-0.7,1.5
		c-0.1,0.5-0.3,1.2-0.3,2.1c-0.1,2.8-0.8,10.9-0.8,12.2s0.3,2,0.3,2v1.5c0,0-0.5,0.8-0.6,2.9c-0.1,2.1-0.1,12.1-0.1,13.4
		c0,1.3,0.5,1.7,0.5,1.7L91.8,298.1L91.8,298.1z"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M77.4,297.2v1c0,0-2.3,0.1-2.3,1.1v1.9c0,0.8,1.5,0.7,1.5,0.7v1.1c0,0-4.3,1.1-4.6,1.2
	c-0.4,0.1-0.5,0.4-0.5,0.8v2.9c0,0.5,0.4,0.7,0.4,0.7l0.6,0.5l0.3,0.4c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.5,0.8-1.1,1.3-1.5
	c0.3-0.2,0.6-0.4,1-0.6c0.3-0.1,0.6-0.3,0.9-0.4s0.5-0.2,0.7-0.2l0.9-0.3c0.3-1.1,1-3.6,1.1-5.5c0.1-2.6-0.2-11.3-0.1-18.7
	c0.1-7.4,0.8-20,1-22.8c0.1-2.8-1.3-2.8-1.3-2.8l-4.3-1.3c0,0.5,0.3,0.7,0.7,0.7c0.7,0.1,3.3,1.1,3.3,1.1l0.1,0.8
	c0,0-0.3,0.1-0.9,0.7c-0.6,0.5-0.7,1.7-0.7,2.7c0,1.1,1.3,1.1,1.3,1.1v0.4h-0.3c-0.7,0.1-2.1,0.2-3,0.3c-1.2,0.1-2.2,1.4-2.5,3
	c-0.2,1.5-0.3,7.6-0.3,7.6s-1,1.4-1.3,2.9s-0.5,4.1-0.4,7c0.1,2.9,1,4.2,1,4.2s0,0.5-0.1,1.3c-0.1,0.8-0.1,4.6-0.1,6.5
	c0.1,1.9,1.4,2.6,2.6,2.7c0.7,0.1,1.9,0.1,2.8,0.1C77,297.2,77.4,297.2,77.4,297.2L77.4,297.2z M173.7,327.5c-1.4,0-2.6,0-3.9,0.4
	c-1.3,0.3-1.2,1.5-1.1,2.1s0.9,1.9,1.4,2.7c0.6,0.7,1.4,1.1,2.1,1.1c0,0,1.8,0.1,2.8,0c1,0,1.7-0.4,2.4-1.2c0.7-0.9,0.9-1.2,1.3-2.7
	c0.4-1.4-0.8-1.8-1.4-2C176.6,327.7,175.1,327.5,173.7,327.5L173.7,327.5z M157.1,338.5c-1.2-0.9-2,0.2-2.4,0.8s-1,1.9-1.3,3.3
	c-0.4,1.3-0.8,2.5-0.9,3.8s1.1,1.6,1.7,1.6c0.6,0.1,2.1-0.2,3-0.6c0.9-0.4,1.4-1.1,1.6-1.7l0.9-2.6c0.3-0.9,0.2-1.8-0.4-2.7
	C158.6,339.7,158.3,339.4,157.1,338.5L157.1,338.5z M163.8,360.6c-0.8-0.6-1.6-0.8-2.7-0.5c-1.1,0.3-1.4,0.5-2.6,1.3
	c-1.2,1-0.5,2,0,2.5s1.5,1.5,2.7,2.4c1.2,0.9,2.1,1.4,3.4,2s1.8-0.5,2.1-1.1s0.5-2.1,0.4-3c-0.1-1-0.6-1.7-1.1-2.1
	C166,362.2,164.5,361.1,163.8,360.6L163.8,360.6z M186.2,360.2c-0.9-0.3-1.8,0-2.3,0.4c0,0-1.5,1.1-2.3,1.5
	c-0.8,0.6-1.2,1.3-1.2,2.4c0,1.1,0,1.5,0.5,2.9c0.5,1.4,1.7,1.1,2.4,0.8c0.7-0.3,1.9-1,3.1-1.8c1.1-0.8,2-1.5,3-2.6
	c1-1.1,0.1-1.9-0.4-2.3C188.4,361.2,187.1,360.5,186.2,360.2L186.2,360.2z M190.5,338.4c-0.5,0.3-1.6,1.3-2.1,2.1s-0.6,1.7-0.4,2.3
	c0,0,0.5,1.7,0.8,2.6c0.3,0.9,0.9,1.5,1.9,1.9c1,0.4,1.4,0.5,2.9,0.5c1.5,0,1.5-1.3,1.4-2c-0.1-0.7-0.4-2.1-0.8-3.5
	c-0.4-1.3-0.8-2.4-1.5-3.6C192.1,337.7,190.9,338.1,190.5,338.4L190.5,338.4z"
          />
          <ellipse fill="#5E5E5E" cx="173.8" cy="349" rx="4.5" ry="2.1" />
          <path
            fill="#5E5E5E"
            d="M628.3,327.5c-1.4,0-2.6,0-3.9,0.4c-1.3,0.3-1.2,1.5-1.1,2.1s0.9,1.9,1.4,2.7c0.6,0.7,1.4,1.1,2.1,1.1
	c0,0,1.8,0.1,2.8,0c1,0,1.7-0.4,2.4-1.2c0.7-0.9,0.9-1.2,1.3-2.7c0.4-1.4-0.8-1.8-1.4-2C631.2,327.7,629.6,327.5,628.3,327.5
	L628.3,327.5z M611.6,338.5c-1.2-0.9-2,0.2-2.4,0.8c-0.4,0.6-1,1.9-1.3,3.3c-0.4,1.3-0.7,2.5-0.9,3.8c-0.1,1.3,1.1,1.6,1.7,1.6
	c0.6,0.1,2.1-0.2,3-0.6c0.9-0.4,1.4-1.1,1.6-1.7l0.9-2.6c0.3-0.9,0.2-1.8-0.4-2.7C613.1,339.7,612.8,339.4,611.6,338.5L611.6,338.5z
	 M615.6,360.1c-1,0.3-1.4,0.5-2.6,1.3c-1.2,1-0.5,2,0,2.5s1.5,1.5,2.7,2.4c1.2,0.9,2.1,1.4,3.4,2s1.8-0.5,2.1-1.1
	c0.3-0.6,0.5-2.1,0.4-3c-0.1-1-0.6-1.7-1.1-2.1c0,0-1.4-1.2-2.2-1.6C617.5,360,616.7,359.8,615.6,360.1z M638.5,360.6
	c0,0-1.5,1.1-2.3,1.5c-0.8,0.6-1.2,1.3-1.2,2.4c0,1.1,0,1.5,0.5,2.9c0.5,1.4,1.7,1.1,2.4,0.8c0.7-0.3,1.9-1,3.1-1.8
	c1.2-0.8,2-1.5,3-2.6c1-1.1,0.1-1.9-0.4-2.3s-1.8-1.1-2.7-1.3C639.9,360,639,360.2,638.5,360.6L638.5,360.6z M645,338.4
	c-0.5,0.3-1.6,1.3-2.1,2.1c-0.5,0.8-0.6,1.7-0.4,2.3c0,0,0.5,1.7,0.8,2.6c0.3,0.9,0.9,1.5,1.9,1.9c1,0.4,1.4,0.5,2.9,0.5
	c1.5,0,1.5-1.3,1.4-2s-0.4-2.1-0.8-3.5c-0.4-1.3-0.9-2.4-1.5-3.6C646.6,337.7,645.5,338.1,645,338.4L645,338.4z M623.9,349
	c0,1.2,2,2.1,4.5,2.1s4.5-1,4.5-2.1c0-1.1-2-2.1-4.5-2.1S623.9,347.8,623.9,349L623.9,349z M436.8,211c0,0.9,0.7,1.5,1.5,1.5
	c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5C437.5,209.5,436.8,210.2,436.8,211z"
          />
          <circle fill="#5E5E5E" cx="398.9" cy="194.2" r="1.5" />
          <path
            fill="#5E5E5E"
            d="M398.1,225.6c0,0.9,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5S398.1,224.8,398.1,225.6L398.1,225.6z
	 M231.5,224.5h-1l-0.3,0.4c0,0-0.8,0-1.2,0.1c-0.4,0-0.7,0.2-0.7,0.2l-0.5,0.6c0,0-0.5-0.5-0.9-0.8c-0.4-0.2-0.9,0-0.9,0l-0.1-0.3
	l-0.4-0.4c-0.2-0.2-0.4,0-0.7,0.3c-0.2,0.3-1.1,1.2-1.2,1.4c-0.2,0.3,0.2,0.7,0.2,0.7l0.3,0.3l-0.6,1.2c0,0-0.9,0.2-4.1,0.5
	c-3.3,0.3-6.5,0.5-6.5,0.5l-2.1,1.5l-0.2-0.2v0.4c0.6,0.5,2.5,2,3.9,2.1c1.7,0.1,7.3,0.3,10-0.3c2.8-0.5,3.7-0.8,6.5-0.5
	c2.8,0.3,8.8,0.8,9.9,0.8c0.8,0,1.7,0,2.1,0.3c0.1,0.1,0.2,0.3,0.2,0.6c1.6-0.9,3.5-2.2,6-4.2c7.6-6.3,23.5-20.6,33.6-28.3
	c5.3-4,9.8-7.7,14-10.8l2-1.6c4.7-3.5,7-5.3,8.1-6.4c1.1-1.2,0.6-1.9-0.7-2.4c-1-0.4-6-1.9-8.7-2.8c-0.5,0.1-1.4,0.3-2.1,0.6
	s-0.4,0.6-0.2,0.7c0,0,5.8,1.6,7.7,2.4c0.1,0,0.2,0.1,0.2,0.1c1.6,0.8,2.4,1,0.5,2.6s-17.5,12.5-26,19.5c-8.5,6.9-20.2,16.6-25.1,21
	c-5,4.4-7.5,6.7-8.5,7.5c-0.4,0.3-0.8,0.6-1,0.8C238.3,230,233.9,227.5,231.5,224.5L231.5,224.5z M466.9,243.7
	c0,3.1,0.1,6.3,0.1,9.5c0.1,7,0.1,13.6,0.1,17.8c-0.1,6-0.1,19.6-0.1,28.1v38.7h3.6v-38.4v-46.1c0-0.4,0-0.7,0.1-1
	c0.2-0.5,0.4-0.8,0.4-0.8v-0.1c0.2-0.3,0.3-1.6,0.4-2.5v-5.3L466.9,243.7L466.9,243.7z"
          />
          <path
            fill="#EAEBEB"
            d="M279.3,229.7c0.1,1.2,0.2,3.4,0.4,5.6c0.2,2.6,0.5,4.8,0.6,5.9l7.8-0.9c2-0.2,1.9-0.9,1.9-1.9
	c0-1.2-0.3-5.9-0.5-7.6s-0.8-2-2.1-1.9C286.5,228.8,282.9,229.2,279.3,229.7L279.3,229.7z"
          />
          <path
            fill="#FFFFFF"
            d="M245.7,241.6c-0.9,0.1-4.1,0.2-9,0.5c-6.9,0.4-18.9,1.2-26.8,2.4c-7.5,1.2-12.2,6.6-12.6,7.1l-0.8,1
	l47.6-0.8c0.2-3.1,0.7-5.8,1.3-9.5C245.7,242.1,245.7,241.8,245.7,241.6L245.7,241.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M232.8,347.5h237.9v5c0,0.8,0.6,0.7,0.9,0.7c0.3,0,0.2-0.6,0.2-0.6
	s75.6-0.1,77.2-0.1c0,0.7,0.5,0.5,0.5,0.5s0.5,0.3,0.5-0.3v-4.7l32.6,2c0.6,24.9,20.8,44.8,45.9,44.8c22.5,0,41.2-16.2,45.1-37.6
	c3.4-0.2,65.4-4.4,67.2-4.5c1.8-0.2,2.5-0.8,2.5-0.8s5.5,2.8,6,3.2c0.6,0.4,1.2,0.4,1.2,0.4h10.7v-2H775v-0.8h1.1v0.7h1.8v-8.6H776
	v0.5h-4.2l-0.1-0.5l-10.6-0.2v-0.6c-0.1-1-1.1-1.2-1.7-1.4c-0.7-0.3-2.7-0.7-5-1.4s-2.6-1.7-3.3-2.7c-0.7-1-0.3-0.9-0.3-0.9
	l-0.2-0.6c0,0,0.1,0.1,0.2,0.2c0.4,0.3,0.8,0.4,1.9,0.3c2.1-0.1,18.8,0.5,18.8,0.5v1.8c-0.1,0.5,0.1,0.5,0.6,0.6
	c0.5,0.2,1.9,0.5,3.1,0.7c1.1,0.2,2.1-0.9,2.5-1.8c0.4-1,0.3-1.2,0.4-4.6c0.1-3.4,0.4-9.5,0.4-12.1s-0.2-4.8-0.2-4.8
	s0.1-0.6,0.2-1.3c0.1-0.8,0.1-1.5,0.1-2.9c-0.1-1.3-0.9-1.4-0.9-1.4h-1.1c0,0-0.4-0.6-1.2-0.7c-0.9,0-17.9-0.4-17.9-0.4
	c-2.2,0-3.4,1-3.7,1.7c-0.3,0.8-0.5,1.6-0.6,2.9c-0.1,1,0.2,1.2,0.4,1.2c-0.1,0-0.6,0-0.8,1.2c-0.3,1.9-1.8,14-1.8,14l-1.9-1.5
	c0,0,0.7-6.8,1.1-9.3c0.4-2.6,0.5-6.4,1.2-8.5c0.4-1.2,1.9-3.5,4.4-3.5c1.2,0,6.5-0.1,7.2-0.1c0.7,0.1,0.7-0.8,0.9-1.8
	c0.2-1.1,1.3-8.8,1.4-10s-0.5-1.3-0.5-1.3s0.7-0.6,0.8-1.1c0.1-0.5,0.2-1,0.3-3.6c0.1-2.6,0.6-7.3,0.6-7.3s1.2-0.9,1.1-6.2
	c-0.1-3-1.2-4.4-1.2-4.4s0.1-1.4,0-2.7s-0.3-3.8-0.3-3.8s0.5-0.3,0.4-0.8c0-0.5-0.3-3.4-0.3-3.4v-1.4c-0.1-1-0.5-3.2-0.5-3.2h0.9
	c0.3,0,0.5-0.3,0.4-0.7c-0.1-0.5-0.1-1.2-0.2-1.7c-0.1-0.6-0.4-1.2-0.7-1.4c-0.4-0.3-1.4-0.7-1.4-0.7s-1.2-6.6-1.2-6.8
	c-0.1-0.3-0.7-0.2-0.7-0.2s0.1-0.4,0-0.8c-0.1-0.5,0.7-1.1,1.2-1.5c0.6-0.5,0.4-1.9,0.1-2.6c-0.3-0.8-0.6-0.9-1.2-0.9
	s-9.1-0.1-9.7-0.1s-0.9,0.3-0.9,0.3h-10.6c0,0-0.5-0.2-0.5-0.5s-186.3,0-186.3,0l-0.4,0.2h-79.2c-1.2,0-1.6,1-1.6,1h-1.2
	c-0.3,0-0.6,0.1-0.6,0.9v7.2c0,1.5-0.4,1.8-0.4,1.8s-0.6,0.7-0.6,1.7c0.1,1.1,0,53.5,0,53.5h-3.6c0,0-0.1-26.5,0.1-35.7
	c0.1-9.2-0.2-30.4-0.3-40.7s-1.1-17.5-2.1-25.1c-0.9-7.5-2.7-17.4-2.7-17.4s0-2.4-0.8-5.4c-0.8-3.1-1.5-4.1-1.5-4.1l-0.2-0.6
	c0,0,0.5-0.2,0.9-0.3c0.4-0.1,0.5-0.3,0.5-1.1s0.1-2.1,0-3c0-0.9-0.9-1.2-1.2-1.2s-0.7-0.1-1.3-0.3c-0.9-0.3-1.3-0.2-1.3-0.2
	s-1.6-2.1-5.6-2.4s-17.1-0.3-17.1-0.3s-0.5-0.5-1-0.5c-0.5-0.1-1.3-0.1-3.7-0.4s-3.9-0.7-9.8-0.8s-26-0.4-29.2-0.3s-11.3,1-11.8,1.1
	c-0.4,0.1-0.5,0.5-0.5,0.5l-5.8,0.1c0,0-0.2-0.3-0.6-0.4c-0.4-0.1-1.3-0.1-4.1-0.2c-2.7-0.1-5.2-0.4-7.8-0.4s-18.5,0.5-20.5,0.6
	c-1.9,0-2.3,0.2-3.2,0.3c-0.9,0.1-6.5,1-7.8,1.2c-1.2,0.3-1.7,0.6-1.7,0.6s-1.8,0.2-3.9,0.5s-3.7,0.5-3.7,0.5s-0.5-1-3.6-0.1
	c-2.4,0.7-2.9,1.4-2.9,1.4s-3.8,1-7.6,1.8c-3.6,0.9-7.3,3.1-7.3,3.1s-1.4,0.2-2.3,0.6c-0.9,0.4-0.2,0.8-0.2,0.8
	s-20.6,13.6-33.5,23.1c-12.9,9.4-30.2,22.6-30.2,22.6h-1.1l-0.3,0.4c0,0-0.8,0-1.1,0.1c-0.4,0-0.7,0.2-0.7,0.2l-0.5,0.6
	c0,0-0.5-0.5-0.9-0.8c-0.4-0.2-0.9,0-0.9,0l-0.1-0.3l-0.4-0.4c0,0-0.4,0-0.7,0.3c-0.2,0.3-1.1,1.2-1.2,1.4c-0.2,0.3,0.2,0.7,0.2,0.7
	l0.3,0.3l-0.6,1.2c0,0-0.9,0.2-4.1,0.5c-3.3,0.3-6.5,0.5-6.5,0.5l-2.1,1.5c0,0-0.3-0.5-1-0.9c-0.6-0.4-2.5-1.4-3.1-1.8
	c-0.6-0.3-1-0.4-1-0.4s-16.2,0.8-20.2,1.1s-7.1,1.8-7.1,1.8s-14,0.9-33.1,2.7c-19.1,1.8-33.3,3.9-42.8,5.6
	c-9.5,1.6-16.7,4.3-16.7,4.3v0.4l-0.4-0.1c0,0-1.4,0.3-5.4,2.6c-3.9,2.3-5.4,4.9-6,6.7s-0.3,2,0.4,2.2c0.7,0.1,3.3,1.1,3.3,1.1
	l0.1,0.8c0,0-0.3,0.1-0.9,0.7c-0.6,0.5-0.7,1.7-0.7,2.7c0,1.1,1.3,1.1,1.3,1.1v0.4c0,0-2.1,0.2-3.4,0.3c-1.2,0.1-2.2,1.4-2.5,3
	c-0.2,1.5-0.3,7.6-0.3,7.6s-1,1.4-1.3,2.9s-0.5,4.1-0.4,7s1,4.2,1,4.2s0,0.5-0.1,1.3c-0.1,0.8-0.1,4.6-0.1,6.5
	c0.1,1.9,1.4,2.6,2.6,2.7c1.2,0.1,3.7,0.1,3.7,0.1v1c0,0-2.3,0.1-2.3,1.1v1.9l1.5,0.7v1.1c0,0-4.3,1.1-4.6,1.2
	c-0.4,0.1-0.5,0.4-0.5,0.8v2.9c0,0.5,0.4,0.7,0.4,0.7l0.6,0.5c0,0-0.2,0.4-0.5,1.1c-0.2,0.6-0.8,1.7-0.8,1.7s-2.2,0.4-4.1,0.7
	c-1.9,0.2-2.3,1.1-2.3,1.1s-1.4,0.3-1.4,1.7c0,0.9-0.1,1.9-0.1,1.9s-0.2,0.1-0.6,0.1c-0.3,0-0.4,0.3-0.4,1s0.8,18.8,0.8,19.4
	s0.4,0.5,0.4,0.5l1.9,0.3c0,0,0.6,1.4,2.5,2.9c2,1.4,4.8,1.2,4.8,1.2v0.3c0,0.2,0,0.2,0.4,0.5c0.4,0.3,2.6,1.4,3.6,2.4
	s1.2,1,1.6,2.4c0.5,1.3,1.6,8.9,1.7,9.9c0.1,0.9-0.3,1.2-0.3,1.2s-0.9,0.7-1.2,1c-0.3,0.2-0.3,0.7-0.3,0.7v1.8
	c0,0.5,0.3,0.5,0.3,0.5s16.4,0.1,22.8,0.1c6.3-0.1,6.9-1.1,8.1-3.4c1.2-2.2,1.3-3.6,2.4-7.9c0.9-3.8,1-9,1-9h0.9
	c1.2-0.2,1.6-2.3,1.7-3.9c0.1-1.6,0.2-4.1,0.2-4.1l7.6-3l7.7,0.7c-2,5.2-3.2,10.9-3.2,16.8c0,25.4,20.6,46,46,46
	c22.8,0,41.8-16.6,45.4-38.4L232.8,347.5L232.8,347.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M470.5,337.6h-3.1l-0.2-10.3l3.3-0.6V337.6z M80.2,357.3
	c0,0,7.1,0,13.1,0.1c6,0.1,12.8,2.5,12.8,2.5H78.7 M71.9,342l3,0.1 M751.8,241.1c0,0,4.6,0.7,5.9,1s2.2,3.3,2.6,4
	c0.4,0.8-0.3,0.9-1.2,0.6c-0.9-0.3-3.5-1.2-4.7-1.7c-1.3-0.5-3.4-1.2-7.8-1.2s-269.9-0.1-271.6-0.1c-1.6,0-1.5-1.9-1.5-1.9l-1.9,0.1
	c0,0-0.1,6.1-0.1,6.6s-0.2,2.7-0.4,3s-0.5,1.5-0.5,1.5v83.4c0,0,0.8,2.4,2.4,2.4c1.7,0.1,84.5,1.3,84.5,1.3 M756.2,240.5l1.2,1.3
	 M765.9,267.6c-0.3-1.9-0.6-5.5-1.2-9.9s-2.1-11.3-2.1-11.3l-2.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.5,295.9c0,0-4.9,0.1-7.7,0.1c-2.8,0-4.6-1.8-5.6-3.2
	c-1-1.3-1.2-4.4-0.7-9.2c0.5-4.9,2.1-13.8,2.5-16.6c0.5-3.6,1.7-5.9,3.3-7.7c1.4-1.6,5-3.1,5.8-3.4c0.9-0.3,0.3-1.7-0.2-3.6
	c-0.5-1.9-1.7-5.8-1.7-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M766.1,286.3c0-0.9-0.4-1.5-1-1.5H762c-0.5,0-1,0.7-1,1.5v4.4
	c0,0.9,0.4,1.4,1,1.4h2.8c0.5,0,1-0.7,1-1.4 M698.4,340.1c0,0,12.9-0.8,20.5-1.1c7.8-0.3,24.9-0.5,26.8-0.5s1.9-0.7,2.1-2.2
	c0.2-1.5,1.3-12.4,1.9-18.2c0.6-5.9,1.9-7.2,1.9-7.2 M762.5,254.2c0,0-1.2-1.1-4.7-1.1c-5.4,0-287-0.9-287-0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M765.4,293.8c0,0-4.1,0.2-8.1,0c-3.9-0.2-4.6-3.7-4.6-5.3s-0.1-2.6,0.3-6
	c0.3-2.9,1.7-12.3,3-17.4c1.2-5,4.5-6.3,5.9-6.9c1.3-0.5,2.9-0.6,2.9-0.6s-1.2-1.1-1.7-1.4s-1.2-0.2-1.2-0.2 M765.5,258.3
	c-0.3-2-0.9-4.7-0.9-4.7 M764.8,299.4h-57.1c-9.4,0-9.9-2.2-12.2-5.9c-3.9-6.2-13.8-18.4-25.9-24.7c-12.1-6.3-26.4-8.5-39.2-8.9
	c-12.7-0.4-28.4,2.4-41.2,9.1c-12.8,6.8-21.2,17.6-25.5,23.1c-4.3,5.5-5.6,7.2-11.2,7.2c-5.6,0-81.7,0.1-81.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M563.2,343c0.7,0,0.8-0.4,1-0.7c0.2-0.4,0.8-1.8,0.8-1.8l3.2,0.1
	c0,0,0.3,1.3,0.4,1.6c0.1,0.4,0.4,0.6,0.4,0.6s1.1,0.2,2,0.2c1,0,1-1.3,1.1-2.5c0.2-1.2,1-10,2.7-19.7c1.5-8.5,5.5-20.2,16.3-29.2
	c10.8-9,28.8-10.9,36.7-11c7.9-0.1,21.6,0,32.6,6.5s16.2,11.9,20.2,21.9c3.9,10,4.4,20.4,4.6,24.8c0.3,4.3-0.6,4.9-1.5,7.6
	c-1,2.7,1.1,2.1,1.1,2.1s1.2,0.1,2.3,0.1s1.7-0.8,1.7-0.8l0.6-1.9l3.3-0.1c0,0,0.5,1.1,0.8,1.7s0.8,0.6,0.8,0.6s1.3-0.1,3.2-0.3
	c1.9-0.3,1.2-2.6,1.2-4.2c-0.1-1.6-0.9-20.1-5-31.1c-4.1-11-11.2-22-20.9-28.1c-9.6-6.1-22.5-11.4-41.4-11.8
	c-18.9-0.4-34.4,4-45.2,11.8s-17.9,18.5-21.7,29.2s-6,27.1-6.4,30.7s0.8,3.5,2,3.6C559.8,342.8,562.6,343,563.2,343L563.2,343z
	 M754.4,312h23.2 M778.1,317.5c0,0-0.6-0.6-1.6-0.7c-1.1-0.1-22.9-0.2-22.9-0.2 M750.6,336.8l0.1-5.1 M778.2,322.5
	c-0.2,5.3-2.5,11.6-3.5,14.3c-1,2.7-3.2,3-3.2,3 M747.7,337h2.6 M716.2,339.2c0,0,1.2,0.7,8.2,3.8c7,3.3,19.4,8.8,19.4,8.8
	 M760.8,353.6l0.2-9.6 M775.9,352.6V345 M761.1,345.3l14.8,0.1 M760.8,352.4h15.2 M757.5,346c0-0.3-0.3-0.6-0.6-0.6h-6
	c-0.3,0-0.6,0.3-0.6,0.6v6.1c0,0.3,0.3,0.6,0.6,0.6h6c0.3,0,0.6-0.3,0.6-0.6L757.5,346L757.5,346z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M756.4,347c0-0.3-0.2-0.5-0.5-0.5H752c-0.3,0-0.5,0.2-0.5,0.5v4.1
	c0,0.3,0.2,0.5,0.5,0.5h3.8c0.3,0,0.5-0.2,0.5-0.5V347z M761.3,250.4c0,0-4.5-1-10.8-1c-6.3,0-93.2-0.5-93.2-0.5H471.8 M628.4,394.8
	c25.2,0,45.8-20.4,45.8-45.8c0-25.2-20.4-45.8-45.8-45.8c-25.2,0-45.8,20.4-45.8,45.8C582.6,374.2,603,394.8,628.4,394.8
	L628.4,394.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.4,376.4c15.1,0,27.4-12.2,27.4-27.4c0-15.1-12.2-27.4-27.4-27.4
	c-15.1,0-27.4,12.2-27.4,27.4C601,364.1,613.2,376.4,628.4,376.4L628.4,376.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.4,375.1c14.4,0,26.1-11.7,26.1-26.1c0-14.4-11.7-26.1-26.1-26.1
	c-14.5,0-26.1,11.7-26.1,26.1C602.2,363.5,613.9,375.1,628.4,375.1L628.4,375.1z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="628.4"
            cy="349"
            r="13.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.4,361.1c6.7,0,12.1-5.4,12.1-12.1c0-6.7-5.4-12.1-12.1-12.1
	c-6.7,0-12.1,5.5-12.1,12.1C616.2,355.7,621.7,361.1,628.4,361.1L628.4,361.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.4,355.6c3.6,0,6.6-3,6.6-6.6c0-3.6-3-6.6-6.6-6.6
	c-3.6,0-6.6,3-6.6,6.6C621.8,352.6,624.7,355.6,628.4,355.6L628.4,355.6z M626.9,340.4c0-1.2-1.1-2.3-2.3-2.3s-2.3,1.1-2.3,2.3
	s1.1,2.3,2.3,2.3S626.9,341.6,626.9,340.4 M625.5,342h-1.8l-1-1.6l1-1.6h1.8l1,1.6L625.5,342L625.5,342z M620.8,343.6
	c-1.1-0.7-2.5-0.3-3.1,0.8c-0.7,1.1-0.3,2.5,0.8,3.1c1.1,0.7,2.5,0.3,3.1-0.8C622.2,345.6,621.9,344.2,620.8,343.6 M621.5,345.5
	l-1,1.6h-1.8l-0.9-1.6l1-1.6h1.8L621.5,345.5L621.5,345.5z M620.8,350.7c-1.1-0.6-2.5-0.2-3.1,0.9c-0.6,1.1-0.2,2.5,0.9,3.1
	c1.1,0.6,2.5,0.3,3.1-0.9C622.3,352.7,622,351.3,620.8,350.7 M621.6,352.6l-1,1.6h-1.8l-1-1.6l1-1.6h1.8L621.6,352.6L621.6,352.6z
	 M633.1,342.4c1.1-0.6,1.4-2,0.9-3.1c-0.6-1.1-2-1.4-3.1-0.9c-1.1,0.6-1.4,2-0.9,3.1C630.7,342.6,632,343,633.1,342.4 M631.1,342
	l-0.9-1.6l1-1.6h1.8l0.9,1.6l-1,1.6L631.1,342L631.1,342z M636,347.7c1.1,0.6,2.5,0.2,3.1-0.9s0.2-2.5-0.9-3.1s-2.5-0.2-3.1,0.9
	C634.5,345.6,634.9,347,636,347.7 M635.3,345.6l0.9-1.6h1.8l1,1.6l-0.9,1.6h-1.8L635.3,345.6L635.3,345.6z M635.9,354.8
	c1.1,0.6,2.5,0.2,3.1-0.9s0.2-2.5-0.9-3.1c-1.1-0.6-2.5-0.3-3.1,0.9C634.3,352.7,634.7,354.1,635.9,354.8 M635.1,352.7l1-1.6h1.8
	l1,1.6l-1,1.6h-1.8L635.1,352.7L635.1,352.7z M629.6,357.6c0,1.2,1,2.3,2.2,2.3s2.3-1,2.3-2.2s-1-2.3-2.2-2.3
	S629.6,356.4,629.6,357.6 M631,356.1l1.8,0.1l0.9,1.6l-1,1.5h-1.8l-0.9-1.6L631,356.1L631,356.1z M622.4,357.5c0,1.2,1,2.3,2.2,2.3
	s2.3-1,2.3-2.2s-1-2.3-2.2-2.3S622.4,356.3,622.4,357.5 M623.8,356l1.8,0.1l0.9,1.6l-1,1.5h-1.8l-0.9-1.6L623.8,356L623.8,356z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.4,351.1c2.5,0,4.5-1,4.5-2.1c0-1.1-2-2.1-4.5-2.1s-4.5,1-4.5,2.1
	C623.9,350.1,625.9,351.1,628.4,351.1L628.4,351.1z M624.6,341.6c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2
	C623.4,341,623.9,341.6,624.6,341.6L624.6,341.6z M620.9,352.7c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2
	C620.3,353.9,620.9,353.4,620.9,352.7z M631.1,341.2c0.5,0.5,1.2,0.5,1.7,0.1c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7-0.1
	C630.7,339.9,630.6,340.6,631.1,341.2L631.1,341.2z M631.9,356.5c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2
	C633.2,357.1,632.6,356.5,631.9,356.5L631.9,356.5z M625.6,356.8c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2-0.1,1.7
	c0.5,0.5,1.2,0.5,1.7,0.1C626,358.1,626.1,357.3,625.6,356.8L625.6,356.8z M620.4,346.5c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0
	c-0.5,0.5-0.5,1.2,0,1.7C619.2,347,619.9,347,620.4,346.5L620.4,346.5z M636.1,351.9c-0.5,0.5-0.6,1.2-0.1,1.7
	c0.5,0.5,1.2,0.6,1.7,0.1c0.5-0.5,0.6-1.2,0.1-1.7C637.4,351.5,636.7,351.5,636.1,351.9L636.1,351.9z M636,345.6
	c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2C636.6,344.4,636,345,636,345.6L636,345.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M636.4,325.5c-2.5-0.9-5.2-1.3-8-1.3c-2.8,0-5.4,0.5-7.9,1.2l-0.3,0.1
	c-0.4,0.1-0.6,0.4-0.6,0.7v2.9c0,0.3-0.2,0.8-0.4,1c0,0-1.1,1.1-2.8,2.4c-1.8,1.4-3.5,2.1-3.5,2.1c-0.3,0.1-0.8,0.1-1.1,0l-2.5-1
	c-0.3-0.1-0.7,0-0.9,0.3l-0.2,0.2c-3.1,4.1-4.9,9.3-4.9,14.9v0.6c0,0.3,0.3,0.7,0.6,0.8l2.7,0.9c0.3,0.1,0.7,0.4,0.8,0.7
	c0,0,0.7,1.2,1.4,3.4s1,3.9,1,3.9c0,0.3-0.1,0.8-0.3,1.1l-1.7,2.1c-0.2,0.3-0.2,0.6,0,0.9l0.2,0.2c3.1,4.2,7.4,7.5,12.5,9.2l0.4,0.1
	c0.3,0.1,0.7,0,0.9-0.2l1.6-2.3c0.2-0.3,0.6-0.5,0.9-0.6c0,0,1.4-0.2,3.7-0.3c2.3-0.1,4,0.4,4,0.4c0.3,0.1,0.7,0.4,0.9,0.6l1.4,2.3
	c0.2,0.3,0.5,0.4,0.8,0.2l0.4-0.1c5.2-1.6,9.7-4.9,12.8-9.2l0.1-0.2c0.2-0.3,0.2-0.6,0-0.9l-1.6-2.3c-0.2-0.3-0.3-0.7-0.3-1.1
	c0,0,0.2-1.4,0.9-3.6c0.6-2.2,1.5-3.7,1.5-3.7c0.2-0.3,0.6-0.6,0.9-0.6l2.6-0.7c0.3-0.1,0.6-0.4,0.6-0.7v-0.9c0.1-5.6-2-10.8-5.1-15
	l-0.1-0.1c-0.2-0.3-0.5-0.4-0.8-0.3l-2.7,0.9c-0.3,0.1-0.8,0.1-1.1-0.1c0,0-1.2-0.7-3.2-1.9s-3.1-2.6-3.1-2.6
	c-0.2-0.2-0.4-0.7-0.4-1l0.1-2.7c0-0.3-0.2-0.6-0.5-0.7L636.4,325.5L636.4,325.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M606.4,360.8c0.4,1,1,0.8,1.2,0.5c0.3-0.3,0.5-0.4,1.1-1.2
	c0.5-0.8,0.4-1.2,0.1-2.4s-0.8-2.9-1.5-4.5c-0.8-1.6-2.5-1.4-3-1.4s-0.6,0.4-0.4,1.2c0.1,0.4,0.5,2.3,1.1,4.1
	C605.5,358.7,606.2,360.3,606.4,360.8L606.4,360.8z M639.1,326.5c-0.9-0.5-1.2,0-1.3,0.4c-0.1,0.4-0.2,0.6-0.1,1.5
	c0,1,0.4,1.2,1.2,2c0.9,0.8,2.3,1.8,3.9,2.8c1.6,0.9,2.9-0.3,3.3-0.6c0.4-0.3,0.2-0.6-0.3-1.2c-0.3-0.3-1.7-1.5-3.3-2.7
	C641.2,327.7,639.6,326.8,639.1,326.5L639.1,326.5z M610.4,331.6c-0.8,0.7-0.4,1.2-0.1,1.3c0.4,0.2,0.6,0.4,1.4,0.6
	c0.9,0.3,1.3,0,2.3-0.6c1-0.6,2.5-1.5,3.8-2.8s0.6-2.9,0.5-3.3c-0.2-0.5-0.5-0.4-1.2-0.1c-0.4,0.2-2,1.1-3.6,2.3
	C612.2,330.2,610.8,331.3,610.4,331.6z M653,352.7c0.2-1-0.4-1.2-0.8-1.2c-0.4,0-0.7,0-1.5,0.3c-0.9,0.3-1.1,0.8-1.5,1.8
	c-0.5,1.1-1.2,2.7-1.5,4.5c-0.4,1.8,1.2,2.7,1.5,3c0.4,0.3,0.7,0,1-0.7c0.2-0.4,1-2.1,1.6-3.9C652.5,355,652.9,353.3,653,352.7
	L653,352.7z M632.6,373.6c1.1-0.1,1.1-0.7,0.9-1.1c-0.2-0.4-0.2-0.6-0.8-1.3c-0.6-0.8-1.1-0.8-2.2-0.9s-3-0.2-4.8,0
	c-1.8,0.2-2.2,1.9-2.3,2.4c-0.2,0.5,0.2,0.7,1,0.8c0.4,0.1,2.4,0.2,4.2,0.3C630.4,373.8,632.1,373.6,632.6,373.6L632.6,373.6z
	 M629.6,333.7c1,0,1.7-0.4,2.4-1.2c0.7-0.9,0.9-1.2,1.3-2.7c0.4-1.4-0.8-1.8-1.4-2c-0.7-0.1-2.2-0.3-3.5-0.3c-1.4,0-2.6,0-3.9,0.4
	c-1.3,0.3-1.2,1.5-1.1,2.1s0.9,1.9,1.4,2.7c0.6,0.7,1.4,1.1,2.1,1.1L629.6,333.7L629.6,333.7z M614.2,343.2c0.3-0.9,0.2-1.8-0.4-2.7
	c-0.6-0.9-0.9-1.2-2.1-2c-1.2-0.9-2,0.2-2.4,0.8c-0.4,0.6-1,1.9-1.3,3.3c-0.4,1.3-0.7,2.5-0.9,3.8c-0.1,1.3,1.1,1.6,1.7,1.6
	c0.6,0.1,2.1-0.2,3-0.6c0.9-0.4,1.4-1.1,1.6-1.7C613.3,345.8,613.9,344.1,614.2,343.2L614.2,343.2z M643.3,345.6
	c0.3,0.9,0.9,1.5,1.9,1.9c1,0.4,1.4,0.5,2.9,0.5c1.5,0,1.5-1.3,1.4-2s-0.4-2.1-0.8-3.5c-0.4-1.3-0.9-2.4-1.5-3.6s-1.8-0.7-2.3-0.4
	c-0.5,0.3-1.6,1.3-2.1,2.1s-0.6,1.7-0.4,2.3C642.5,342.9,643,344.7,643.3,345.6L643.3,345.6z M636.2,362.1c-0.8,0.6-1.2,1.3-1.2,2.4
	c0,1.1,0,1.5,0.5,2.9c0.5,1.4,1.7,1.1,2.4,0.8c0.7-0.3,1.9-1,3.1-1.8c1.2-0.8,2-1.5,3-2.6c1-1.1,0.1-1.9-0.4-2.3s-1.8-1.1-2.7-1.3
	c-0.9-0.3-1.8,0-2.3,0.4C638.5,360.6,636.9,361.6,636.2,362.1L636.2,362.1z M618.3,360.6c-0.8-0.6-1.6-0.8-2.7-0.5
	c-1,0.3-1.4,0.5-2.6,1.3c-1.2,1-0.5,2,0,2.5s1.5,1.5,2.7,2.4c1.2,0.9,2.1,1.4,3.4,2s1.8-0.5,2.1-1.1c0.3-0.6,0.5-2.1,0.4-3
	c-0.1-1-0.6-1.7-1.1-2.1C620.5,362.2,619.1,361.1,618.3,360.6z M173.8,394.8c25.2,0,45.8-20.4,45.8-45.8c0-25.2-20.4-45.8-45.8-45.8
	c-25.2,0-45.8,20.4-45.8,45.8C128.1,374.2,148.5,394.8,173.8,394.8L173.8,394.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.8,376.4c15.1,0,27.4-12.2,27.4-27.4c0-15.1-12.2-27.4-27.4-27.4
	c-15.1,0-27.4,12.2-27.4,27.4C146.5,364.1,158.7,376.4,173.8,376.4L173.8,376.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.8,375.1c14.4,0,26.1-11.7,26.1-26.1c0-14.4-11.7-26.1-26.1-26.1
	s-26.1,11.7-26.1,26.1C147.6,363.5,159.4,375.1,173.8,375.1L173.8,375.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.8,362.2c7.3,0,13.2-6,13.2-13.2c0-7.3-6-13.2-13.2-13.2
	s-13.2,6-13.2,13.2C160.6,356.3,166.5,362.2,173.8,362.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.8,361.1c6.7,0,12.1-5.4,12.1-12.1c0-6.7-5.4-12.1-12.1-12.1
	s-12.1,5.5-12.1,12.1C161.7,355.7,167.1,361.1,173.8,361.1L173.8,361.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.8,355.6c3.6,0,6.6-3,6.6-6.6c0-3.6-3-6.6-6.6-6.6s-6.6,3-6.6,6.6
	C167.2,352.6,170.1,355.6,173.8,355.6z M172.3,340.4c0-1.2-1.1-2.3-2.3-2.3s-2.3,1.1-2.3,2.3s1.1,2.3,2.3,2.3
	S172.3,341.6,172.3,340.4 M171,342h-1.8l-1-1.6l1-1.6h1.8l1,1.6L171,342L171,342z M166.2,343.6c-1.1-0.7-2.5-0.3-3.1,0.8
	c-0.7,1.1-0.3,2.5,0.8,3.1c1.1,0.7,2.5,0.3,3.1-0.8C167.6,345.6,167.2,344.2,166.2,343.6 M166.9,345.5l-1,1.6h-1.8l-0.9-1.6l1-1.6
	h1.8L166.9,345.5L166.9,345.5z M166.3,350.7c-1.1-0.6-2.5-0.2-3.1,0.9c-0.6,1.1-0.2,2.5,0.9,3.1s2.5,0.3,3.1-0.9
	C167.7,352.7,167.3,351.3,166.3,350.7 M167,352.6l-1,1.6h-1.8l-1-1.6l0.9-1.6h1.8L167,352.6L167,352.6z M178.6,342.4
	c1.1-0.6,1.4-2,0.9-3.1c-0.6-1.1-2-1.4-3.1-0.9c-1.1,0.6-1.4,2-0.9,3.1C176.1,342.6,177.5,343,178.6,342.4 M176.5,342l-0.9-1.6
	l1-1.6h1.8l0.9,1.6l-1,1.6L176.5,342L176.5,342z M181.4,347.7c1.1,0.6,2.5,0.2,3.1-0.9s0.2-2.5-0.9-3.1c-1.1-0.6-2.5-0.2-3.1,0.9
	C180,345.6,180.4,347,181.4,347.7 M180.8,345.6l0.9-1.6h1.8l1,1.6l-0.9,1.6h-1.8L180.8,345.6L180.8,345.6z M181.2,354.8
	c1.1,0.6,2.5,0.2,3.1-0.9c0.6-1.1,0.2-2.5-0.9-3.1s-2.5-0.3-3.1,0.9C179.8,352.7,180.2,354.1,181.2,354.8 M180.6,352.7l0.9-1.6h1.8
	l1,1.6l-0.9,1.6h-1.8L180.6,352.7L180.6,352.7z M175.1,357.6c0,1.2,1,2.3,2.2,2.3s2.3-1,2.3-2.2s-1-2.3-2.2-2.3
	C176.2,355.4,175.1,356.4,175.1,357.6 M176.4,356.1l1.8,0.1l0.9,1.6l-1,1.5h-1.8l-0.9-1.6L176.4,356.1L176.4,356.1z M167.9,357.5
	c0,1.2,1,2.3,2.2,2.3s2.3-1,2.3-2.2s-1-2.3-2.2-2.3C169,355.3,167.9,356.3,167.9,357.5 M169.2,356l1.8,0.1l0.9,1.6l-1,1.5h-1.8
	l-0.9-1.6L169.2,356L169.2,356z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="173.8"
            cy="349"
            rx="4.5"
            ry="2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M170,341.6c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2
	S169.3,341.6,170,341.6L170,341.6z M166.4,352.7c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2
	C165.8,353.9,166.4,353.4,166.4,352.7z M176.5,341.2c0.5,0.5,1.2,0.5,1.7,0.1c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7-0.1
	C176.1,339.9,176.1,340.6,176.5,341.2L176.5,341.2z M177.4,356.5c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2
	S178.1,356.5,177.4,356.5L177.4,356.5z M171.1,356.8c-0.5-0.5-1.2-0.5-1.7,0s-0.5,1.2-0.1,1.7c0.5,0.5,1.2,0.5,1.7,0.1
	C171.4,358.1,171.4,357.3,171.1,356.8z M165.9,346.5c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0s-0.5,1.2,0,1.7
	C164.6,347,165.4,347,165.9,346.5L165.9,346.5z M181.5,351.9c-0.5,0.5-0.6,1.2-0.1,1.7c0.5,0.5,1.2,0.6,1.7,0.1
	c0.5-0.5,0.6-1.2,0.1-1.7C182.9,351.5,182,351.5,181.5,351.9z M181.3,345.6c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2
	s-0.5-1.2-1.2-1.2C182,344.4,181.4,345,181.3,345.6L181.3,345.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M181.8,325.5c-2.5-0.9-5.2-1.3-8-1.3c-2.8,0-5.4,0.5-7.9,1.2l-0.3,0.1
	c-0.4,0.1-0.6,0.4-0.6,0.7v2.9c0,0.3-0.2,0.8-0.4,1c0,0-1.1,1.1-2.8,2.4c-1.8,1.4-3.5,2.1-3.5,2.1c-0.3,0.1-0.8,0.1-1.1,0l-2.5-1
	c-0.3-0.1-0.7,0-0.9,0.3l-0.2,0.2c-3.1,4.1-4.9,9.3-4.9,14.9v0.6c0,0.3,0.3,0.7,0.6,0.8l2.7,0.9c0.3,0.1,0.7,0.4,0.8,0.7
	c0,0,0.7,1.2,1.4,3.4s1,3.9,1,3.9c0,0.3-0.1,0.8-0.3,1.1l-1.7,2.1c-0.2,0.3-0.2,0.6,0,0.9l0.2,0.2c3.1,4.2,7.4,7.5,12.5,9.2l0.4,0.1
	c0.3,0.1,0.7,0,0.9-0.2l1.6-2.3c0.2-0.3,0.6-0.5,0.9-0.6c0,0,1.4-0.2,3.6-0.3c2.3-0.1,4,0.4,4,0.4c0.3,0.1,0.7,0.4,0.9,0.6l1.4,2.3
	c0.2,0.3,0.5,0.4,0.9,0.2l0.4-0.1c5.2-1.6,9.7-4.9,12.8-9.2l0.1-0.2c0.2-0.3,0.2-0.6,0-0.9l-1.6-2.3c-0.2-0.3-0.3-0.7-0.3-1.1
	c0,0,0.2-1.4,0.9-3.6c0.6-2.2,1.5-3.7,1.5-3.7c0.2-0.3,0.6-0.6,0.9-0.6l2.6-0.7c0.3-0.1,0.6-0.4,0.6-0.7v-0.9c0.2-5.6-2-10.8-5-15
	l-0.1-0.1c-0.2-0.3-0.5-0.4-0.8-0.3l-2.7,0.9c-0.3,0.1-0.8,0.1-1.1-0.1c0,0-1.2-0.7-3.2-1.9s-3.1-2.6-3.1-2.6
	c-0.2-0.2-0.4-0.7-0.4-1l0.1-2.7C182.3,325.8,182.1,325.5,181.8,325.5L181.8,325.5L181.8,325.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M151.9,360.8c0.4,1,1,0.8,1.2,0.5c0.3-0.3,0.5-0.4,1-1.2s0.4-1.2,0.1-2.4
	s-0.8-2.9-1.5-4.5c-0.8-1.6-2.5-1.4-3-1.4s-0.6,0.4-0.4,1.2c0.1,0.4,0.5,2.3,1.1,4.1C150.9,358.7,151.6,360.3,151.9,360.8
	L151.9,360.8z M184.6,326.5c-0.9-0.5-1.2,0-1.3,0.4c-0.1,0.4-0.2,0.6-0.1,1.5c0.1,1,0.4,1.2,1.2,2c0.9,0.8,2.3,1.8,3.9,2.8
	c1.6,0.9,2.9-0.3,3.3-0.6c0.4-0.3,0.2-0.6-0.3-1.2c-0.3-0.3-1.7-1.5-3.3-2.7C186.5,327.7,185.1,326.8,184.6,326.5L184.6,326.5z
	 M155.9,331.6c-0.8,0.7-0.4,1.2-0.1,1.3c0.4,0.2,0.5,0.4,1.4,0.6c0.9,0.3,1.3,0,2.3-0.6s2.5-1.5,3.8-2.8s0.6-2.9,0.5-3.3
	c-0.2-0.5-0.5-0.4-1.2-0.1c-0.4,0.2-2,1.1-3.6,2.3C157.6,330.2,156.3,331.3,155.9,331.6L155.9,331.6z M198.4,352.7
	c0.2-1-0.4-1.2-0.8-1.2s-0.7,0-1.5,0.3c-0.9,0.3-1.1,0.8-1.5,1.8c-0.5,1.1-1.2,2.7-1.5,4.5c-0.4,1.8,1.1,2.7,1.5,3
	c0.4,0.3,0.7,0,1-0.7c0.2-0.4,1-2.1,1.6-3.9C197.8,355,198.3,353.3,198.4,352.7L198.4,352.7z M178.1,373.6c1.1-0.1,1-0.7,0.9-1.1
	c-0.2-0.4-0.2-0.6-0.8-1.3s-1.1-0.8-2.2-0.9c-1.2-0.1-3-0.2-4.8,0c-1.8,0.2-2.2,1.9-2.3,2.4c-0.2,0.5,0.2,0.7,1,0.8
	c0.4,0.1,2.4,0.2,4.2,0.3C175.8,373.8,177.6,373.6,178.1,373.6L178.1,373.6z M175,333.7c1,0,1.7-0.4,2.4-1.2
	c0.7-0.9,0.9-1.2,1.3-2.7c0.4-1.4-0.8-1.8-1.4-2c-0.7-0.1-2.2-0.3-3.6-0.3c-1.4,0-2.6,0-3.9,0.4c-1.3,0.3-1.2,1.5-1.1,2.1
	c0.1,0.6,0.9,1.9,1.4,2.7c0.6,0.7,1.4,1.1,2.1,1.1L175,333.7L175,333.7z M159.5,343.2c0.3-0.9,0.2-1.8-0.4-2.7
	c-0.6-0.9-0.9-1.2-2.1-2c-1.2-0.9-2,0.2-2.4,0.8s-1,1.9-1.3,3.3c-0.4,1.3-0.8,2.5-0.9,3.8s1.1,1.6,1.7,1.6c0.6,0.1,2.1-0.2,3-0.6
	s1.4-1.1,1.6-1.7C158.8,345.8,159.4,344.1,159.5,343.2L159.5,343.2z M188.7,345.6c0.3,0.9,0.9,1.5,1.9,1.9c1,0.4,1.4,0.5,2.9,0.5
	c1.5,0,1.5-1.3,1.4-2c-0.1-0.7-0.4-2.1-0.8-3.5c-0.4-1.3-0.8-2.4-1.5-3.6c-0.7-1.2-1.8-0.7-2.3-0.4s-1.6,1.3-2.1,2.1
	s-0.6,1.7-0.4,2.3C188,342.9,188.4,344.7,188.7,345.6L188.7,345.6z M181.6,362.1c-0.8,0.6-1.2,1.3-1.2,2.4c0,1.1,0,1.5,0.5,2.9
	c0.5,1.4,1.7,1.1,2.4,0.8c0.7-0.3,1.9-1,3.1-1.8c1.1-0.8,2-1.5,3-2.6c1-1.1,0.1-1.9-0.4-2.3s-1.8-1.1-2.7-1.3
	c-0.9-0.3-1.8,0-2.3,0.4C183.9,360.6,182.4,361.6,181.6,362.1L181.6,362.1z M163.8,360.6c-0.8-0.6-1.6-0.8-2.7-0.5
	c-1.1,0.3-1.4,0.5-2.6,1.3c-1.2,1-0.5,2,0,2.5s1.5,1.5,2.7,2.4c1.2,0.9,2.1,1.4,3.4,2s1.8-0.5,2.1-1.1s0.5-2.1,0.4-3
	c-0.1-1-0.6-1.7-1.1-2.1C166,362.2,164.5,361.1,163.8,360.6L163.8,360.6z M245.9,340.6c0,0,212.1,0.5,216.2,0.5c4.1,0,4.7-0.4,5-4.1
	c0.3-3.7,0-30.2,0-30.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242,314.8c-3.6-13-10.8-26.1-21.6-35c-10.8-8.9-29-14.2-43.3-14.2
	c-14.7,0-31.5,3-44.3,10.4c-12.8,7.4-19,20.2-19.8,21.9c-0.8,1.6,0.4,1.7,1.2,1.7c1,0,10.2-0.1,11.9,0s2.3-0.4,2.8-1.1
	c0.5-0.7,1.7-3.3,7.9-8.1c6-4.9,18.4-10.3,37.2-10.3s33,6,39.8,12.7c6.8,6.6,11.1,14.4,14,23.2s4.3,22,4.5,24.4
	c0.3,2.5,0.9,2.7,2.6,2.6c1.7-0.1,8.1-0.4,9.8-0.6c1.6-0.2,1.4-2.5,1.3-3.9C246,338.6,245.7,327.8,242,314.8L242,314.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M86.6,243.2c0,0,1.3,0.8,2.7,1.2c1.3,0.5,1.5,0.8,1.7,1.3
	c0.2,0.5,0.3,1.5,0.5,4.1c0.3,2.6,0.4,4.9,0.4,6.1c0,1.2-1.2,2.3-1.7,3.4c-0.7,1.1-1,2-1.2,3.6c-0.3,1.5-0.2,4.1-0.6,9.7
	c-0.4,5.6-0.5,23.5-0.5,25.5c0.1,1.9,1,1.5,2.9,1.5s35.1-0.1,35.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M467,299.1l-7.5,0.2c0,0-200.7,0.9-209,1c-8.3,0.1-8.4,0.2-13.1-8.5
	c-4.8-8.6-14.7-18.6-24.3-23.7s-20.8-7.6-30.4-8.2c-9.6-0.5-22.2-0.5-34.7,3.1s-22.4,9.8-29.9,18s-11.3,18.4-11.3,18.4 M467.3,253.1
	c0,0-0.7,0.4-13,0.3s-70.4-0.1-98.2,0c-27.8,0.1-129,1.1-158.9,1.7S91.6,257,91.6,257 M245.7,241.6c0,0-2.1,0.1-9,0.5
	c-6.9,0.4-18.9,1.2-26.8,2.4c-7.5,1.2-12.2,6.6-12.6,7.1c-0.4,0.5-0.6,0.8-0.6,0.8s-99.9,2-102.2,2c-1.8,0-2.8,0.8-2.8,0.8
	 M243.5,233.6c0.3-0.6-1.2-0.7-2.2-0.7s-7.1-0.5-9.9-0.8c-2.8-0.3-3.8,0-6.5,0.5c-2.8,0.5-8.4,0.3-10,0.3c-1.7-0.1-4-2.2-4-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M222.4,243c0.3-0.3,0.8-4.6,0.8-5.6c0.1-1-1-1-2.4-1.2s-9.2-0.4-10.3-0.5
	c-1.1-0.1-1.5-1.4-1.5-1.4s-0.5-1.9-1-3.2c-0.5-1.2-0.8-1.3-1.5-1.7c-0.8-0.5-2.5-1.4-2.5-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M210.4,230.2c0,0-5.9-0.6-13.5-0.3c-7.7,0.3-28,1.5-28,1.5 M78,305.4
	c0,0,1-3,1.2-5.6c0.1-2.6-0.2-11.3-0.1-18.7c0.1-7.4,0.8-20,1-22.8c0.1-2.8-1.3-2.8-1.3-2.8l-4-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M76.2,303.3c1-0.1,1.2-0.7,1.2-0.7s0.2-0.5,0.4-1.7
	c0.2-1.2,0.1-1.7,0.2-3.2c0.1-1.4,0-4.4-0.1-10.4c-0.1-5.9,0.5-15.6,0.8-21.4c0.3-5.9,0.3-9.4,0.3-9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M77.9,276.1c0,0-1.9,0-3.9-0.4c-2-0.4-1.7-4-1.7-4 M77.7,282.7
	c0,0-1.8,0.2-3.9,0.7s-2.3,3.3-2.3,3.3 M77.5,302.1l-1.2-0.1 M120.6,312.9c-1.9,4.9-3.1,8.5-4,13s-1.4,9.5-1.7,13.1
	c-0.3,3.6-0.9,3-2.2,3.1h-38c0,0-3-6.1-3.2-8.6s-1-10.1-1.2-14.5s1.7-8.4,2.3-9.6c0.6-1.1,1.3-2.7,3.8-3.5c2.5-0.9,4.9-1.2,4.9-1.2
	s1.2-0.9,2.5-1.1c1.3-0.2,4.9-0.8,8.4-1c3.4-0.2,30.1,0.1,32.2,0.2c1.9,0.1,0.1,2.3,0.1,2.3S122.1,308.9,120.6,312.9L120.6,312.9z
	 M70.2,319.8c-0.1,0-4.2,0.1-5.2,0.1s-1.2-0.9-1.3-1.5c-0.1-0.6-0.1-1.9-0.1-1.9 M66,338.7c-0.4-0.7-0.7-1.4-0.8-2.3
	c-0.2-0.9,0.5-1.2,1.3-1.2s5-0.1,5-0.1 M65.2,335.9c0,0-0.1-3.6-0.2-8.3c-0.2-4.6-0.2-7.6-0.2-7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M70.8,325.7c0,0-2.4,0.1-3.2,0.1s-1.4,0.4-1.4,1.6s0.9,1.5,1.6,1.5h3.2
	 M71.2,312.7l-6.5,1 M82,312.8c-2.8,0-3.7,1-4.2,1.3c-0.5,0.4-1.1,1.2-1.9,2.9c-0.9,1.6-1.2,2.4-1.2,3.3c0,1,0.1,4.8,0.3,8.5
	c0.3,3.7,2.9,4.4,5,4.4s7.4,0.1,10.9,0c3.6,0,4.3-3.1,4.2-5.2c-0.1-2-0.1-6.7-0.3-10.8c-0.2-4.2-2.5-4.3-4.3-4.4
	C90.4,312.9,84.8,312.8,82,312.8L82,312.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M95,327.5c0,4.6-2.7,4.8-4.2,4.8s-8.4,0.1-11-0.1
	c-2.6-0.2-2.6-2.9-2.7-4.2c-0.1-1.3-0.1-3.5-0.2-6.3c-0.1-2.9,1.6-4.5,2.7-5.9c1.1-1.3,2.2-1.5,3.5-1.5s5.3-0.2,8.4-0.1
	c3,0.1,3.4,2.9,3.4,2.9 M104.5,305.1c0,0-21.9,0.2-22.6,0.2s-0.4-0.9-0.4-0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M106.3,302.7c-1.2,0-1.7,2.4-2.1,3.4c-0.3,1-2.1,10.3-3,16.8
	c-0.9,6.5-1.4,17-1.4,18c0,1.1,0.5,1.1,0.5,1.1 M93.8,331.3c0,0-3.3-0.7-3.8-0.8c-0.6-0.2-0.5-0.7-0.5-1.3c0-0.8,0.1-11.2,0-12.6
	s0.4-1.7,0.4-1.7l1.5-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M113.9,341.9c-0.4-0.3-0.8-2.1-0.5-5.8c0.4-3.6,1.5-11.1,3.3-16.8
	s4.8-12.6,5.4-13.6c0.7-1.2-0.1-0.9-0.1-0.9s-13.9-0.1-15.7-0.1c-1.7,0-1.9,0.4-1.9,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M112,314.5c0-0.9-0.5-1.4-1.4-2s-2.2,0.4-2.5,1.9c-0.4,1.5-3.1,18-3.1,18
	c-0.4,2.6,1.2,2.1,2.1,1.6s1.7-1.5,2.2-3.6c0.4-2.2,2.1-10.9,2.6-14.3C111.8,316.2,112,315.5,112,314.5L112,314.5z M89.5,327.7h-1
	c0,0-0.6-0.2-0.9-0.9c-0.2-0.7-0.7-2.2-0.7-3.9c0-1.7,0.5-3.5,0.7-4.2c0.2-0.9,1.1-0.7,1.1-0.7h0.7 M91,258c0.5-0.8,0.4-2.1,0.2-4
	c-0.2-1.8-0.4-6.3-0.8-7.9c-0.4-1.4-1.2-1.4-2.4-1.8c-1.2-0.4-1.8-0.9-1.8-0.9 M212.1,235.8c0,0-12.5-0.1-31.6,1.2
	c-19.3,1.2-53.7,5.5-69.6,8.7c-15,3.1-19,9-19,9 M103.4,299.6c0,0,4-10.1,8-15.8c4-5.7,10.4-11.2,12.9-13.1c2.5-1.7,2.4-2.6,2.5-4.9
	c0.1-2.3,0.4-12.1,0.4-12.1 M242.4,232.8c0,0,0.7-0.5,1.7-1.2c1.1-0.8,3.6-3,8.5-7.5c5-4.4,16.6-14.1,25.1-21
	c8.5-6.9,24.1-17.9,26-19.5s1.2-1.9-0.5-2.6c-1.6-0.8-8-2.6-8-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298.9,189c4.9-3.6,7.1-5.4,8.2-6.5s0.6-1.9-0.8-2.4s-8.4-2.7-8.4-2.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M223.2,237.4c0,0,6.9-0.2,11.7-1.2s7.1-0.4,14.6-6.7
	c7.6-6.3,23.5-20.6,33.6-28.3c10-7.7,17.7-14,24.9-17.9c7.1-3.9,14.6-6.9,31.1-7.6c16.5-0.7,34.8-0.8,49.6-1.1s34.3-0.6,43.5-0.4
	c9.2,0.2,14.7,0.1,14.7,0.1s1.1-0.4,1.3,1.5c0.2,1.9,0.1,5.1,1.4,18.5c2.5,25.4,3.2,44.4,3,60.6c-0.1,13.6,0.3,34.9,0.3,49.7
	c0,17.5,0.2,30,0.1,31.7c-0.1,2.7-1.5,2.6-1.5,2.6l-192.6,0.1c-1.1,0-1.1-0.3-1.3-1.1c-0.4-0.8-0.6-1.1-1.2-2.5
	c-1.1-2.6-5.9-14-7.8-22.2c-1.9-8.2-1.8-10.1-2.9-21.1s-1-16.8-1.4-28s-0.1-14.2,1.3-22c1.4-7.8,3.4-12.2,3.4-12.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M391.3,338.8c0,0,0.1-45.9-0.1-63c-0.3-17.1-0.1-27.7-1.2-54.2
	c-1.1-26.5-1.8-46.9-1.8-46.9 M271.6,218.2c0,0-2.4,2-5,4.1c-3.6,3-7.7,7.3-9.8,10.8c-2.1,3.5-3.7,11.7-4,13.7
	c-0.3,1.9,0.6,3.6,4.3,3.6s32-0.4,38.4-0.4c6.4,0.1,9.1-1.3,11.3-2.6c2.3-1.2,6.6-2.9,10.5-3.9c3.8-1.1,11.4-1.1,20.7-1.1
	s30.6-0.4,34.9-0.5c3.7-0.1,3.8-2.7,3.8-2.7s-0.1-15.7,0.1-25.8c0.2-10,0.3-18.8,0.2-24.5c-0.1-5.7-3.8-7.4-8.4-7.4
	s-21.5,0.3-29.2,0.5s-16.1,1.1-23,3.5c-6.8,2.3-15.3,8.8-21.6,13.6c-6.4,4.8-16.1,12.9-16.1,12.9 M244.1,251.9c0,0-8.4,0.1-18,0.3
	c-9.5,0.2-29.8,0.5-29.8,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.5,239.7c0,0-35.9,0.5-49.7,0.7c-13.7,0.2-19.8,4.4-21.9,5.5
	c-2.2,1.1-3.9,1.6-6.7,1.7s-5.7,0.1-5.7,0.1 M374.5,239.6c0,0,0.4-16,0.4-25.2c0.1-9.2,0.2-21.7,0-25.9c-0.2-4.2-3-5.1-6.6-5.2
	c-3.6-0.2-20.1,0.4-29.7,0.8s-14.2,1.1-20.4,2.9c-6.1,1.7-12.9,6.6-20.4,12.4s-15.9,12.6-15.9,12.6 M459.4,177.7
	c-1.6-5-2.9-6.4-2.9-6.4 M459.6,178.2c-1.3-2-2.8-2.9-5.6-3.5c-2.8-0.7-7-0.4-7-0.4 M461.9,188.4c-1.2-5.9-2.4-8.4-5.5-9.6
	c-3.1-1.2-8.2-0.9-8.2-0.9 M460.4,177.1c-0.3-0.1-0.7-1-1.1-2.5s-0.7-2.8-0.7-2.8 M315.1,171.3c-1.5,0.4-2,1.1-2.3,1.3
	c-0.2,0.3-0.4,0.9-0.4,1.1s0.3,0.4,1,0.3c0.7,0,6.1-1.2,6.7-1.3s0.1-0.5-0.2-0.9c-0.3-0.4-0.8-0.6-1-0.7
	C318.2,170.7,316.6,170.9,315.1,171.3L315.1,171.3z M372.6,168.6c0,0-0.1,0.5-1.5,0.6c-1.3,0.1-27.8,1-30.4,1.2
	c-2.6,0.1-11,0.3-12.5,0.3s-1.2-0.5-1.2-0.5 M434.4,169.1c0,0-0.3,0.3-1.9,0.4c-1.6,0.1-48.2-0.3-50.7-0.3c-2.5,0.1-3.6-0.6-3.6-0.6
	 M386.8,257.9c0-1.6-1.2-2.8-2.9-2.8h-10.4c-1.6,0-2.7,1.2-2.7,2.8l0.1,15c0,1.6,1.2,2.9,2.8,2.9h10.2c1.6,0,2.8-1.2,2.8-2.9
	L386.8,257.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M372,258.9v11.3c0,4.4,1.3,3.8,2.6,3.8c0.7,0,1-0.1,1.2-0.5
	c0.3-0.3,1.2-1.6,1.9-2.9s1.4-1.1,2.1-1.1h3.8c1.8,0,2.1-1.4,2.1-2.6v-8.6c0,0-1.2-2.1-2.2-2.1c-1.1,0-7.1,0.1-9.2,0.1
	C372.4,256.3,372,257.4,372,258.9L372,258.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M380.1,270.8c-1,0-1.1,0.3-1.3,0.6c-0.3,0.3-1.1,1.3-1.5,2.1
	c-0.5,0.8,0.1,1,0.9,1s3.6-0.1,5.3-0.1s1.7-0.7,1.7-1.2v-1.4c0-0.9-0.5-1-1.5-1C383.5,270.8,381.1,270.8,380.1,270.8L380.1,270.8z
	 M384.6,258.5c0-0.6-0.5-1.1-1.1-1.1H379c-0.6,0-1.1,0.5-1.1,1.1v9.1c0,0.6,0.5,1.1,1.1,1.1h4.5c0.6,0,1.1-0.5,1.1-1.1V258.5z
	 M218,249.7c0,0-15,0.3-15.3,0.3s-0.6-0.2-0.1-0.5c0.4-0.3,3.7-3,8.8-3.6s13.2-1.3,19.7-1.6c6.4-0.4,13.9-0.7,13.9-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242.8,249.4c0,0-0.7-1.5-1.2-3s-1.7-1.4-1.7-1.4l-5.3,0.1l0.7,1.5h-0.9
	l-0.6-1.5h-16c0,0-1.2,0.7-1.2,1.2c0.1,0.5,0.4,1.7,0.7,2.5c0.4,0.8,0.9,1.1,1.5,1.1s17-0.2,17-0.2l-0.3-0.6h3.5l0.4,0.9l3.3-0.1
	L242.8,249.4 M261.8,226.9c-0.1,0.3,0.3,4.1,0.5,4.8c0.2,0.7,0.7,1.1,0.7,1.1s0.3,1.1,2.2,0.9c1.9-0.1,6.3-0.9,6.3-0.9
	s0.1,1.1,0.2,2.8c0.1,1.7,0.4,4.4,0.4,4.4s-5.2,0.8-7,1s-2.1,1.7-2.1,1.7l-0.3,0.4l0.5,5l0.5,0.1c0,0,0.1,0.5,0.3,1s0.7,0.7,1.4,0.7
	s0.8-0.2,3.6-0.5s4.4-1.2,4.4-1.2s0.2,0.4,1.1,1s1.9,0.5,6.4,0.6c4.5,0.2,8.4-0.7,10.8-1.4c2.4-0.9,2.8-1.5,2.9-3.4
	c0-1.7-0.3-5.5-1-14.8c-0.8-9.2-1.1-11.8-1.6-15.2s-2.5-3.5-4.2-3.5s-6,0.1-10.3,0.8c-4.2,0.7-5.9,5.2-6,7.3
	c-0.2,2.1-0.1,4.5-0.1,4.5s-0.7-0.6-1.2-0.5c-0.6,0.1-1.1,0.2-3.5,0.4s-3.3,1.5-3.3,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292,248c1.4-0.8,1.2-2.5,1.2-4.1c0.1-1.6-0.2-3.4-0.8-10.1
	s-1.5-15.6-2-19c-0.5-3.5-3.4-3.4-3.4-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M291.5,223.2l-20.3,2.3c0,0-1.1,0.1-2.6,0.3c-1.6,0.2-1.5,1.1-1.5,1.4
	s0,1.5,0.1,2.7c0.1,1.2,0.5,1.1,1.5,1l2.6-0.3c0,0,14.5-1.8,15.7-1.9c1.3-0.1,1.9,0.2,2.1,1.9s0.5,6.4,0.5,7.6s0.1,1.7-1.9,1.9
	c-2,0.2-19.2,2.2-19.2,2.2s-0.6,0.1-0.6,1.1c0.1,1,0.3,2.3,0.4,3s0,1.3,1.2,1.2s23.4-2.9,23.4-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M272.2,242c0,0-0.4-2.6-0.5-5.4c-0.2-2.9-0.3-5.9-0.3-5.9 M271.2,225.4
	c0,0-0.1-1.2-0.1-2.3 M273,247.1c0.2,1.1,0.6,1.3,0.6,1.3 M271.5,231c0,0-5.5,0.6-6.9,0.8s-1.6,0.8-1.6,0.8 M269.8,225.6
	c-1.9,0.2-7.9,1.2-7.9,1.2 M272.1,241.8l-9.4,1.1 M270.2,247.5c-1.8,0.2-6.7,0.6-6.7,0.6 M280.1,241c0,0-0.2-2.2-0.5-5.9
	c-0.3-3.6-0.4-5.6-0.4-5.6 M288.1,231c-0.1-0.6-0.7-1.1-1.3-1.1l-5.8,0.6c-0.7,0.1-1.1,0.6-1.1,1.2l0.1,1.2c0.1,0.7,0.7,1.1,1.3,1.1
	l5.8-0.6c0.7-0.1,1.2-0.6,1.1-1.2L288.1,231L288.1,231z M288.7,236.7c-0.1-0.7-0.7-1.1-1.3-1.1l-5.8,0.6c-0.7,0.1-1.2,0.6-1.1,1.2
	l0.1,1.2c0.1,0.7,0.7,1.1,1.3,1.1l5.8-0.6c0.7-0.1,1.2-0.7,1.1-1.2L288.7,236.7z M435.1,240.9c7.5,0.1,8.2-5.8,8.3-8.5
	c0.1-2.8-0.7-16.4-1.2-24.5c-0.5-8.2-1.3-15.7-2-21.8s-4.9-6.7-8.5-6.8s-26.1-0.1-30,0.1s-6.9,3.3-6.9,8c0.1,4.7,0.4,12.8,0.6,18.7
	c0.2,6.1,0.5,19.8,0.7,26.7s5.2,8.4,10.3,8.4C406.3,240.9,427.6,240.8,435.1,240.9L435.1,240.9z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="398.9"
            cy="194.2"
            r="1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M399.6,227.1c0.9,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5
	S398.8,227.1,399.6,227.1L399.6,227.1z M438.3,212.5c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5
	C436.8,211.8,437.5,212.5,438.3,212.5z M298.7,250.1c0,0,71.7-0.9,89.7-0.9c17.9,0,52.8,0.2,62.6,0.1c9.8-0.1,15.9,0,15.9,0
	 M244.4,250.5h11.9 M78.5,255.6c0,0,3.4-10.6,25.2-15.8c11.1-2.7,52.9-7.3,52.9-7.3 M72.6,309.4l47.8,0.1 M72.9,283.6V275
	 M125.9,254c0,0-0.3,8.6-0.3,11c-0.1,2.4-0.7,2.8-1.9,3.7c-1.2,1-5,2.8-11.7,11.7c-6.7,9-8.7,15.3-9.1,16.6
	c-0.4,1.3-1.2,1.1-1.2,1.1h-10l-0.1-1.7c0,0-0.5-0.5-0.5-1.7c0-1.3-0.1-11.3,0.1-13.4c0.1-2.1,0.6-2.9,0.6-2.9v-1.5
	c0,0-0.3-0.8-0.3-2s0.6-9.4,0.8-12.2c0.1-2.8,1-3.6,1-3.6v-2.1l0.6-2.4 M125.8,258.7L93.3,259 M115,276.8H91.8 M113.8,278.2l-22,0.1
	 M103.2,296.5H91.6 M125.8,260.3l-33.2,0.2"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M745.9,338.4
		c0,0-24.2,0.1-36.7,1.1l-10.8,0.6c0,0,0.4,2.2-1,2.6c0,0-0.8,0.2-3.6,0.4c0,0-0.5,0-0.7-0.5l-0.9-1.8l-3.3,0.1l-0.6,1.9
		c0,0-0.7,0.8-1.8,0.8l-2.2-0.1c0,0-1.2,0.3-1.2-0.9c0,0-0.1-0.5,0.5-1.9c0,0,1.1-2.4,1.2-3.4c0,0,0.3-1,0.1-4c0,0-0.5-21.6-9.8-34
		c0,0-8.5-11.3-23.8-16.1c0,0-20.7-6.6-46.3,1c0,0-19.9,4.7-27.8,26.8c0,0-3.2,5.6-5.6,30.8c0,0-0.1,1.1-1,1.2l-2-0.2
		c0,0-0.3-0.1-0.4-0.8l-0.4-1.4l-3.2-0.1l-0.9,2c0,0-0.2,0.6-1,0.6l-3.5-0.2c0,0-1.5,0-1.8-1c0,0-0.2-0.3-0.2-1.7l-32-0.5l-52.5-0.9
		c0,0-2.4,0.2-2.4-1.9l-0.1-30.2l0.1-29.6l-0.1-23.9c0,0,0-1,0.6-1.7c0,0,0.4-0.7,0.4-2.1v-7.1c0,0,0.1-0.6,0.5-0.6h1.5
		c0,0,0.2-1,1.4-1h44.6h34.8l0.4-0.2l68.1-0.1h90.4l27.6,0.1c0,0,0.3-0.1,0.3,0.2c0,0.2,0.3,0.4,0.4,0.4s10.4,0,10.4,0h0.2v0.2
		l5.2,0.9c0,0,0.8,0,1.2,0.7c0,0,0.4,0.4,1.7,3.4c0,0,0.3,0.5-0.1,0.7c0,0,1.3,4,2.2,7.9c0,0,0.4,1.2-0.4,1.4c0,0-3.3,1.2-5.2,2.8
		s-3.4,4.7-3.8,7.9l-0.2,1c0,0-1.5,8.7-2.3,14.6c0,0-1.2,7.4,0.3,10.3c0,0,2.2,3.6,5.4,3.6l8.4-0.1c0,0,0.5,0.2,0.3,1.7l-1.5,10.4
		c0,0,0,1-0.8,1l-7.3,0.1c0,0-2-0.2-3.6,2l-0.3-0.1c0,0-1.2,1.6-1.7,6.1l-2.1,19.8c0,0,0,1-0.6,1.3
		C747.2,338.1,746.9,338.4,745.9,338.4z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M452.6,249.2h-61.8
		c0,0,0.5,44.1,0.5,50.3V339l60.2-0.1c0,0,1.5,0.1,1.5-3.1v-36.6L452.6,249.2z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M390.8,249.2l-90.9,0.8
		c0,0-2.2,0.3-3.4,0.3l-40.1,0.3l-1.2-0.1l-10.8,0.1c0,0-0.7,2.6,0.1,19.6c0,0,0.1,13.1,1.5,25.2l0.5,5c0,0,0.2,5.4,1.8,11.5
		c0,0,0.5,5.4,8.5,24.7l0.8,1.5l0.3,0.6c0,0,0.2,0.5,1.2,0.5h132.4v-39.6L390.8,249.2z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M443.3,230.7
		c0,0-1.2-29.6-3-42.3l-0.3-3c0,0-0.2-6.5-8.5-6.2h-27.1l-2.9,0.1c0,0-3.4,0-5.6,3.1c0,0-1.3,1.9-1.3,4.8l1.2,44.8
		c0,0-0.2,4.2,2,6.2c0,0,2,2.8,8.3,2.6h20.6h7c0,0,3.2,0.3,5.2-0.7c0,0,2.9-0.9,3.9-4.8C442.9,235.3,443.5,233.8,443.3,230.7z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M374.5,239.8
		l-47.9,0.7c0,0-10-0.2-18,3.6s-5.2,3.4-14.1,3.7h-1.8c0,0,1.8-0.6,1.8-2.6c0,0,0.1-1.2-0.2-5.3l-0.8-10.2l-0.7-7.7
		c0,0-0.4-3.7-1-7.2c0,0-0.3-2.1-1.5-2.8c0,0-0.9-0.7-3.2-0.6c0,0-2.5,0-5.1,0.2l0.2-0.3c0,0,13-10.5,22.4-17.3c0,0,4.1-3,8-5
		c3.7-2,5.8-2.5,12.1-3.8c0,0,2.1-0.5,9.5-1.1c0,0,20.3-0.9,29-1c0,0,5-0.2,6.5,0.1s5,0.6,5.2,4.9v20.8L374.5,239.8"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M292,248
		c0,0-4.7,1.7-10,1.6l-5.5-0.1c0,0-2.2,0.1-3.4-1.4c0,0-1.9,1-5.4,1.2l-0.7,0.1l-1.5,0.2c0,0-1.6,0.3-1.9-0.7l-0.3-1l-0.5-0.1
		l-0.5-5l0.3-0.4c0,0,0.3-1.5,2.1-1.7l7-1c0,0-0.3-1.9-0.5-7.2c0,0-4.4,0.8-6.1,0.9c0,0-1.8,0.2-2.3-0.9c0,0-0.7-0.5-0.8-1.4
		c0,0-0.3-2-0.5-4.4c0,0,1.8-2,3.1-2.6c0,0,0.6-0.3,1.3-0.4l3.8-0.4c0,0,0.7,0,1.2,0.5c0,0-0.1-3.4,0.3-5.7s2.3-4.9,4.2-5.8
		c0,0,1-0.5,3.8-0.8c0,0,3.9-0.5,7.7-0.5c0,0,2.2-0.1,3.3,0.7c0,0,1.1,0.7,1.4,2.7c0,0,0.9,5.4,1.3,12.2l1.2,16.1
		c0,0,0.2,2.5-0.3,3.3C294.1,246.5,293.5,247.6,292,248z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M246.1,340.6l3.5-5
		h6.8c0,0-3.4-8.4-4.1-10.6c0,0-3.3-9.2-4-13.3c-0.8-4-1.2-5.6-1.8-11.8c0,0-1.3-11.7-1.7-21.6l-0.6-18.6c0,0-0.1-5.8,0.1-7.9
		c0.2-2.1,0-0.1,0-0.1s0.2-3.5,1.4-10.2c0,0-22.6,1.2-32.6,2.4l-2.2,0.3c0,0-8.2,0.4-14.1,8.1l-69.6,1.3l-0.4,13.2
		c0,0,0.1,1.9-1.6,3.3c0,0-8.7,6.4-13.6,13.5c0,0-3.6,4.9-8.2,16l22.5,0.1c0,0,2.1,0,2.6-0.5c0.5-0.5,1.2-1.4,1.2-1.4
		s11.4-18,44-17.6c0,0,27.6-1.5,42.7,15.1c0,0,8,7.7,12.6,23.9c0,0,2.3,8.6,3.6,21.4c0,0-0.1,2.4,1.8,2.4c0,0,5.3-0.2,10.3-0.6
		C244.5,342.6,245.9,342.6,246.1,340.6z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="628.4"
              cy="349"
              r="45.8"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="173.8"
              cy="349"
              r="45.8"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup4DrLeft);
