import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import DocumentTitle from 'react-document-title';
import _ from 'lodash';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { confirm } from './common/confirm/CallConfirm';
import {
  loadAllActivity,
  loadSellerOffers,
  loadItemUpdateOffer,
} from '../actions';
import { getConfig, getTemplate, isDST } from '../utils/helpers';
const timezone = jstz.determine().name();

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 16,
    fontWeight: 700,
    color: '#808080',
  },
  time: {
    fontSize: 16,
    fontWeight: 600,
    color: '#808080',
    marginTop: 8,
    marginBottom: 8,
  },
  text: {
    fontSize: 12,
    color: '#808080',
    margin: 0,
  },
  footer: {
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  offerButton: {
    width: 72,
    marginBottom: 2,
    backgroundColor: '#808080',
    borderRadius: 3,
  },
  detailsButton: {
    marginRight: 8,
    marginBottom: 2,
    backgroundColor: '#808080',
    borderRadius: 3,
  },
  alertButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    fontSize: 20,
    backgroundColor: 'transparent',
    color: '#808080',
    borderRadius: 3,
  },
};

export class AllActivity extends Component {
  componentDidMount() {
    this.loadData();
    if (!this.timer) this.setTimer();
  }

  setTimer = () => {
    this.timer = setInterval(() => {
      this.loadData();
    }, 60000);
  };

  loadData() {
    const mpId = getConfig('marketplaceId');
    this.props.loadAllActivity(mpId);
  }

  handleOfferUpdateReject = item => {
    confirm('Are you sure you want to reject this offer?').then(
      () => {
        const mpId = getConfig('marketplaceId');
        const { itemId, offerId, amount } = item;
        const statusId = 7;

        this.props
          .loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
          .then(({ response }) => {
            if (response && response.wsStatus === 'Success') {
              toastr.success('Success', response.wsMessage);
              this.loadData();
            } else {
              throw new Error(response.error || 'Offer Error');
            }
          })
          .catch(error => {
            toastr.error('Error', error.message || 'An unknown error occured');
          });
      },
      () => null
    );
  };

  handleOfferUpdateAccept = item => {
    confirm('Are you sure you want to accept this offer?').then(
      () => {
        const mpId = getConfig('marketplaceId');
        const { itemId, offerId, amount } = item;
        const statusId = 8;

        this.props
          .loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
          .then(({ response }) => {
            if (response && response.wsStatus === 'Success') {
              toastr.success('Success', response.wsMessage);
              this.loadData();
            } else {
              throw new Error(response.error || 'Offer Error');
            }
          })
          .catch(error => {
            toastr.error('Error', error.message || 'An unknown error occured');
          });
      },
      () => null
    );
  };

  renderActivityItems() {
    const { allActivity, template } = this.props;

    const ids = _.chain(allActivity.data || [])
      .map(item => String(item.itemId))
      .uniq()
      .value()
      .join(',');

    const items = (allActivity.data || []).map((item, index) => {
      let heading = item.activityType;
      if (item.activityType === 'Offer Received') {
        if (item.offerStatusId === 1) heading += ` (Pending)`;
        else if (item.offerStatusId === 7) heading += ` (Rejected)`;
        else if (item.offerStatusId === 8) heading += ` (Accepted)`;
      }

      const adj = isDST() ? '07:00' : '08:00';
      const timeFormat = moment(`${item.activityDate}-${adj}`)
        .tz(timezone)
        .format('dddd, MMMM Do');
      const timeDifference = moment(`${item.activityDate}-${adj}`)
        .tz(timezone)
        .fromNow();

      return (
        <li className="in-view" key={index}>
          <div style={styles.container}>
            <section>
              <h4 style={styles.heading}>{heading}</h4>
              <h4 style={styles.time}>{`${timeFormat} (${timeDifference})`}</h4>
              <p style={styles.text}>{item.summary}</p>
              <p style={styles.text}>{`VIN ${item.vin}`}</p>
            </section>

            <section style={styles.footer}>
              <Link
                to="/profile/alerts"
                className="btn btn-primary"
                style={styles.alertButton}
              >
                <span className="fa fa-bell" />
              </Link>

              <Link
                to={`/item/${item.itemId}`}
                className="btn btn-primary"
                style={styles.detailsButton}
                onClick={() => sessionStorage.setItem('navItems', ids)}
              >
                View Details
              </Link>

              {item.activityType === 'Offer Received' &&
                item.offerStatusId === 1 && (
                  <section style={{ marginLeft: 8 }}>
                    <button
                      className="btn btn-primary"
                      style={{
                        ...styles.offerButton,
                        backgroundColor: getTemplate(template, 'misc.green'),
                        marginRight: 8,
                      }}
                      onClick={() => this.handleOfferUpdateAccept(item)}
                    >
                      Accept
                    </button>
                    <button
                      className="btn btn-primary"
                      style={{
                        ...styles.offerButton,
                        backgroundColor: getTemplate(template, 'misc.red'),
                      }}
                      onClick={() => this.handleOfferUpdateReject(item)}
                    >
                      Decline
                    </button>
                  </section>
                )}
            </section>
          </div>
        </li>
      );
    });

    return items;
  }

  render() {
    const title = getConfig('name') || 'Activity Timeline';

    return (
      <DocumentTitle title={title}>
        <section className="timeline">
          <ul>{this.renderActivityItems()}</ul>
        </section>
      </DocumentTitle>
    );
  }
}

AllActivity.propTypes = {
  allActivity: PropTypes.object.isRequired,
  loadAllActivity: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { allActivity, template } = state.entities;
  return { allActivity, template };
};

export default connect(mapStateToProps, {
  loadAllActivity,
  loadItemUpdateOffer,
})(AllActivity);
