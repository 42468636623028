import { find, isString } from 'lodash';
import { HEAVY_TRUCK_BODYSTYLES } from '../../components/core/constants/common';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';

export const loadUpdateItem = data => {
  return {
    type: UPDATE_ITEM,
    response: data,
  };
};

export const updateItem = data => dispatch => dispatch(loadUpdateItem(data));

export const updateVariant = index => ({
  type: UPDATE_VARIANT,
  response: {
    index,
  },
});

// uses combination of existing user entered data and (original options?) to populate item object
export const loadExistingData = (item = {}, decodedItem) => {
  // EQUIPMENT
  const standardEquipments = decodedItem.standardEquipments.map(equipment => {
    return equipment.toUpperCase();
  });
  const selectedStandardEquipment = [];
  const selectedOptionalEquipment = [];
  const itemEquipmnts = item.equipments || [];
  for (let selectedEquipment of itemEquipmnts) {
    const equipment = selectedEquipment.description.toUpperCase();
    if (standardEquipments.includes(equipment))
      selectedStandardEquipment.push(equipment);
    else selectedOptionalEquipment.push(equipment);
  }

  // ANNOUNCEMENTS
  // e.g. 'MNF' ==> ['M','N','F',]) ==> ['MILES OVER 100K', 'NO ARB', 'FRAME DAMAGE']
  const frameAnnouncementCode = decodedItem
    ? decodedItem.frameAnnouncementCode
    : null;
  //need to clarify the API response :item.auction.announcements
  // in native app its a code
  const selectedAnnouncements =
    item.auction && item.auction.announcements.split('');
  const position =
    selectedAnnouncements &&
    selectedAnnouncements.indexOf(frameAnnouncementCode);
  // for frame damage use item.details.frameDamage as the souce of truth in case of conflict
  if (item.details && item.details.frameDamage === 'true' && position === -1) {
    selectedAnnouncements.push(frameAnnouncementCode);
  } else if (
    item.details &&
    item.details.frameDamage !== 'true' &&
    position !== -1
  ) {
    selectedAnnouncements.splice(position, 1);
  }
  const announcements = decodedItem
    ? selectedAnnouncements ||
      []
        .map(selectedAnnouncement => {
          const obj = find(decodedItem.announcements, {
            key: selectedAnnouncement,
          });
          return obj ? obj.value : null;
        })
        .filter(item => item !== null)
    : [];

  const decodedAnnouncements = decodedItem
    ? decodedItem.announcements || null
    : null;

  // WHEELS
  const wheels = (item.wheels || []).map((wheel, index) => {
    const patch = [
      'Left Front',
      'Left Rear',
      'Right Rear',
      'Right Front',
      'Spare',
    ];

    const category =
      wheel.category === 'Wheels' ? patch[index] : wheel.category || null;

    return {
      category,
      size: wheel.size || null,
      manufacturer: wheel.manufacturer || null,
      depth: wheel.depth || null,
    };
  });

  // SPARE WHEEL
  if (wheels.length < 5) {
    wheels.push({ category: 'Spare' });
  }

  // CONDITION
  const condition = (item.conditionItems || []).map((crItem, index) => {
    return {
      area: crItem.areaDescription || '',
      areaCode: crItem.areaCode || '',
      part: crItem.partDescription || '',
      partCode: crItem.partCode || '',
      damage: crItem.conditionDescription || '',
      damageCode: crItem.conditionCode || '',
      severity: crItem.severityDescription || '',
      severityCode: crItem.severityCode || '',
      materialEstimate: crItem.materialEstimate || '',
      laborHours: crItem.laborHours || '',
      laborCode: crItem.laborCode || '',
      paintHours: crItem.paintLaborHours || '',
      paintCode: crItem.paintLaborCode || '',
      sublet: crItem.subletEstimate || '',
      needReview: crItem.needReview || '0',
      notes: crItem.note || '',
      actionCode: crItem.actionCode || '',
      actionDescription: crItem.actionDescription || '',
      hadPhoto: !!crItem.photo,
      conditionKey: `Condition-${index}`,
    };
  });
  // PHOTOS
  const photos = [];

  (item.medias || []).forEach(mediaItem => {
    const photo = {
      type: mediaItem.type,
      descriptionKey: [mediaItem.category, mediaItem.description].join('-'),
      category: mediaItem.category,
      description: mediaItem.description,
      photo: mediaItem.photo,
      name: mediaItem.photo,
    };
    photos.push(photo);
  });

  (item.conditionItems || [])
    .filter(conditionItem => conditionItem.photo)
    .forEach((conditionItem, conditionIndex) => {
      const area = conditionItem.areaDescription;
      const part = conditionItem.partDescription;
      const damage = conditionItem.conditionDescription;
      const severity = conditionItem.severityDescription;
      const photo = {
        category: 'Condition',
        descriptionKey: ['Condition', conditionIndex].join('-'),
        area,
        part,
        damage,
        severity,
        name: conditionItem.photo,
        photo: conditionItem.photo,
      };
      photos.push(photo);
    });

  let existingVehicle = {
    pk: item.pk,
    type: item && item.info && item.info.type,
    vin: item.info && item.info.vin,
    serialNumber: item.info && item.info.type === 'P' ? item.info.vin : '',
    name: `${item.info && item.info.year} ${item.info && item.info.make} ${
      item.info && item.info.model
    }`,
    status: item.auction && item.auction.status,
    printers: item.printers,
    locked: item.locked,

    // VARIANT
    make: item.info && item.info.make,
    model: item.info && item.info.model,
    trim: item.info && item.info.trim,
    year: item.info && item.info.year,
    bodyStyle: (item.info && item.info.body) || null,

    // ENGINE
    cylinder: (item.engine && item.engine.cylinders) || null,
    driveline: (item.engine && item.engine.driveTrain) || null,
    engine: (item.engine && item.engine.description) || null,
    transmission: (item.engine && item.engine.transmission) || null,
    fuelLevel: (item.engine && item.engine.fuelLevel) || null,

    // DETAILS
    vTrackId: (item.details && item.details.vTrackId) || '',
    vTrackVendor: (item.details && item.details.vTrackVendor) || '',
    keys:
      item.details && item.details.keys && item.details.keys <= 3
        ? item.details && item.details.keys
        : item.details && item.details.keys > 3
        ? '3'
        : null, // keys max out at 3 (used to be higher)
    rfid: (item.details && item.details.rfid) || '',

    // INFORMATION
    exteriorColor: (item.info && item.info.exteriorColor) || null,
    interiorColor: (item.info && item.info.interiorColor) || null,
    interiorType: (item.info && item.info.interiorTrim) || null,

    odometer: (item.odometer && item.odometer.reading) || null,
    odometerType: (item.odometer && item.odometer.type) || null,

    // EQUIPMENT
    optionalEquipment: selectedOptionalEquipment,
    standardEquipment: selectedStandardEquipment,

    // QUESTIONS
    frameDamage:
      item.details && item.details.frameDamage === 'true'
        ? true
        : item.details && item.details.frameDamage === 'false'
        ? false
        : null,

    odometerInoperable:
      item.odometer && item.odometer.odometerInoperable === 'true'
        ? true
        : item.odometer && item.odometer.odometerInoperable === 'false'
        ? false
        : null,
    trueMileageUnknown:
      item.odometer && item.odometer.trueMileageUnknown === 'true'
        ? true
        : item.odometer && item.odometer.trueMileageUnknown === 'false'
        ? false
        : null,
    // note reverse logic on drivable/undrivable
    undrivable:
      item.details && item.details.drivable === 'true'
        ? false
        : item.details && item.details.drivable === 'false'
        ? true
        : null,
    inoperable:
      item.details && item.details.inoperable === 'true'
        ? true
        : item.details && item.details.inoperable === 'false'
        ? false
        : null,
    studioDone:
      item.inspection && item.inspection.studioDone === 'true'
        ? true
        : item.inspection && item.inspection.studioDone === 'false'
        ? false
        : null,
    grayMarket:
      item.details && item.details.grayMarket === 'true'
        ? true
        : item.details && item.details.grayMarket === 'false'
        ? false
        : null,
    previousCanadian:
      item.details && item.details.previousCanadian === 'true'
        ? true
        : item.details && item.details.previousCanadian === 'false'
        ? false
        : null,
    titlePresent:
      item.details && item.details.titlePresent === 'true'
        ? true
        : item.details && item.details.titlePresent === 'false'
        ? false
        : null,
    crDone:
      item.inspection && item.inspection.crDone === 'true'
        ? true
        : item.inspection && item.inspection.crDone === 'false'
        ? false
        : null,

    // THESE DON'T WORK ON THE BACKEND SO ARE HIDDEN FROM USER
    keysMissing:
      item.details && item.details.keysMissing === 'true'
        ? true
        : item.details && item.details.keysMissing === 'false'
        ? false
        : null,
    fobsMissing:
      item.details && item.details.fobsMissing === 'true'
        ? true
        : item.details && item.details.fobsMissing === 'false'
        ? false
        : null,
    booksMissing:
      item.details && item.details.booksMissing === 'true'
        ? true
        : item.details && item.details.booksMissing === 'false'
        ? false
        : null,
    floorMatsMissing:
      item.details && item.details.floorMatsMissing === 'true'
        ? true
        : item.details && item.details.floorMatsMissing === 'false'
        ? false
        : null,
    smokedIn:
      item.details && item.details.smokedIn === 'true'
        ? true
        : item.details && item.details.smokedIn === 'false'
        ? false
        : null,
    commercialVehicle:
      item.details && item.details.commercialVehicle === 'true'
        ? true
        : item.details && item.details.commercialVehicle === 'false'
        ? false
        : null,
    policeOrTaxi:
      item.details && item.details.policeOrTaxi === 'true'
        ? true
        : item.details && item.details.policeOrTaxi === 'false'
        ? false
        : null,
    floodDamage:
      item.details && item.details.floodDamage === 'true'
        ? true
        : item.details && item.details.floodDamage === 'false'
        ? false
        : null,
    /////

    // Additional
    announcements,
    decodedAnnouncements,
    blockAnnouncements:
      (item.auction && item.auction.blockAnnouncementNotes) || null,
    doors: (item.details && item.details.doors) || null,
    light: (item.auction && item.auction.lights) || '',
    location: (item.inspection && item.inspection.location) || null,
    plateCount: (item.details && item.details.plateCount) || null,
    plates: (item.details && item.details.plateNumber) || null,
    plateState: (item.details && item.details.plateState) || null,
    privateBlockNotes: (item.auction && item.auction.privateBlockNotes) || null,
    notes: (item.inspection && item.inspection.notes) || null,
    radio: (item.details && item.details.radio) || null,
    seats: (item.details && item.details.seats) || null,
    top: (item.details && item.details.top) || null,
    vehicleGrade: (item.inspection && item.inspection.grade) || null,
    weight: (item.details && item.details.weight) || null,

    // AUCTION
    dealerName: (item.auction && item.auction.accountName) || '',
    accountNumber: (item.auction && item.auction.accountNumber) || '',
    saleDate: (item.auction && item.auction.saleDate) || '',
    saleId: (item.auction && item.auction.saleId) || '',
    eventName: (item.auction && item.auction.eventName) || '',
    lotNumber: (item.auction && item.auction.lotNumber) || '',
    sent_out: (item.auction && item.auction.sendOut) || '',
    buyer_name: (item.auction && item.auction.buyerAccountName) || '',
    buyer_number: (item.auction && item.auction.buyerAccountNumber) || '',
    owner: (item.auction && item.auction.owner) || '',
    stockNumber: (item.auction && item.auction.stockNumber) || '',

    // SALE
    gatepassed: item.gatepassed || false,
    redeemed: item.redeemed || false,

    // PRICING
    startingBid: (item.pricing && item.pricing.startingBid) || null,
    reserve: (item.pricing && item.pricing.reserve) || null,
    buyNow: (item.pricing && item.pricing.buyNow) || null,

    // MEDIAS
    medias: [],
    wheels,
    condition,
    photos,

    // AXLES
    axlesData: item.axles || [], // from the existing item
    axles: [], // what will be submitted

    // CATEGORIES
    categories: item.categories || [],
  };

  // ATTRIBUTES
  // if (item.attributes) {
  //   item.attributes.forEach((obj, index) => {
  //     const { pk, value } = obj;
  //     const { value: val, values: vals } = isJSON(value)
  //       ? JSON.parse(value)
  //       : value;

  //     const lookupKey = `attribute_${pk}`;
  //     existingVehicle[lookupKey] = val || vals;
  //   });
  // }

  // HEAVY TRUCKS
  // if the bodystyle is a heavy truck body style
  // or if the Heavytrk property is truthy
  // then we are saying this is a heavy truck
  const isHeavyTruck = !!(
    HEAVY_TRUCK_BODYSTYLES.includes(item.info && item.info.body) ||
    (item.heavyTrucks && item.heavyTrucks.heavyTrk)
  );

  if (isHeavyTruck) {
    // put all the heavy truck properties directly on the vehicle item
    // to make updateItem actions easier
    for (let key in item.heavyTrucks) {
      let value = item.heavyTrucks[key];
      if (isString(value) && !value.length) value = null;
      existingVehicle[key] = value;
    }

    // there is more heavy truck info on a property called inventoryMeta
    for (let key in item.inventoryMeta) {
      let value = item.inventoryMeta[key];
      if (isString(value) && !value.length) value = null;

      // patch until AMWeb changes model since these should be booleans
      const exceptions = ['liftgate', 'mirrorheat', 'heavytrl', 'heavyeqp'];
      if (exceptions.includes(key)) {
        if (value === 0) value = false;
        if (value === 1) value = true;
      }
      existingVehicle[key] = value;
    }
  }

  // it's redundant but the app heavily relies on "heavyTruckOption"
  // so keep these two in sync
  existingVehicle.heavyTruckOption = isHeavyTruck;
  existingVehicle.Heavytrk = isHeavyTruck;

  console.info('>>ITEM (decode-item):', {
    _1_backend: item,
    _2_frontend: existingVehicle,
  });

  return dispatch => dispatch(updateItem(existingVehicle));
};
