import React, { Component } from 'react';
import { connect } from 'react-redux';

class Crossover4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M412.8,168.1c-0.7,0.4-1,1-1,1.2c0,0.8,1.2,1.4,3.9,1.6h3.7c0-0.7,0.1-1.2,0.5-1.8c0.8-1.1,2.8-1.6,5.6-1.8
	h8.8c2.5,0,4,0.6,4.8,1.7c0.3,0.4,0.5,1.1,0.7,1.6h5.5c2.6-0.4,3.9-1,3.4-1.8l-0.1-0.3c-0.3-1.5-2.2-2.1-5.5-1.8
	c-1.1-2.5-3.9-3.7-8.3-3.7h-4.3c-6.1,0.1-10.6,1.1-13.5,2.8L412.8,168.1L412.8,168.1z M383,208.9l0.5,0.1c16-0.3,31.6-0.4,47-0.4
	h42.9l-0.8-2.8h-42.1c-15.4,0-31,0.3-46.8,0.4c-12.5-5.2-26.1-9.9-41-14.3c-6.7-1.9-13.8-3.9-20.9-5.8l-4.8-1.2l-7.2,0.7
	c27.1,6.5,51.5,14.2,73,23.1L383,208.9L383,208.9z"
          />
          <path
            fill="#FFFFFF"
            d="M383.5,209.1l-0.5-0.1l-0.1-0.1c-3,0.8-6.1,2.5-8.8,4.7c-2.2,1.8-4.4,3.9-6.5,6.5c-1.4,1.6-2.6,3.4-4,5.5
	c-2.5,3.7-4.8,8.3-7.2,13.2c-3,6.7-5.9,14.4-8.7,23.2c-3.7,13.3-5.5,26.5-5.2,39.6c-0.3,13.1,1.5,26.4,5.2,39.8
	c2.8,8.8,5.6,16.6,8.7,23.2c2.3,5.1,4.7,9.5,7.2,13.2c1.2,2.1,2.6,3.9,4,5.5c2.1,2.6,4.3,4.8,6.5,6.5c2.9,2.2,5.8,3.9,8.8,4.7
	l0.1-0.1l0.5-0.1c16,0.3,31.6,0.4,47,0.4h42.9c36.2-0.1,70.8-0.8,104.3-1.9h1.8c3.7,0,6.5,1.1,8,3c25.2-0.5,43.9-3.9,56.1-9.9
	c5.6-2.8,9.9-6.1,12.8-10c-9.4-2.6-14.3-7.4-14.9-14.4c-1.4-9.4-2.3-19.1-3-29.2l-0.5-11.6c-0.3-6.3-0.4-12.8-0.4-19.1
	c0-6.3,0.1-12.7,0.4-19l0.5-11.3c0.7-10,1.7-19.8,3-29.2c0.5-7,5.5-11.8,14.9-14.4c-2.9-3.9-7.2-7.3-12.8-10
	c-12.2-6.1-31-9.2-56.1-9.9c-1.7,1.9-4.3,3-8,3h-1.8c-33.3-1.1-68.1-1.6-104.3-1.9c-14.2-0.1-28.5-0.1-42.9,0
	C415.2,208.6,399.5,208.8,383.5,209.1L383.5,209.1z M391,223.9c0-0.8,0.3-1.6,1-2.2c0.5-0.6,1.4-1,2.2-1l211.3,3.9h11.8
	c0.8,0,1.7,0.3,2.2,1c0.6,0.6,1,1.4,1,2.2s-0.3,1.7-1,2.2c-0.5,0.6-1.4,1-2.2,1h-11.8h5.9l-217.2-3.9c-0.8,0-1.6-0.3-2.2-1l-0.5-0.7
	C391.1,225,391,224.5,391,223.9L391,223.9z M391.9,377.2c0.5-0.5,1.4-1,2.2-1l217.2-3.9h-5.9h11.8c0.8,0,1.7,0.3,2.2,1
	c0.6,0.5,1,1.4,1,2.2s-0.3,1.6-1,2.2c-0.5,0.5-1.4,1-2.2,1h-11.8l-211.3,3.9c-0.8,0-1.6-0.3-2.2-1c-0.5-0.5-1-1.4-1-2.2
	c0-0.6,0.1-1.1,0.4-1.5L391.9,377.2L391.9,377.2z M614.5,297.4c1.4,1.1,2.1,2.3,2.1,3.9s-0.7,2.8-2.1,3.9c-1.4,1.1-3.2,1.7-5.1,1.7
	h-0.3c-3,0-5.5-0.5-7.7-1.7c-2.2-1.1-3.3-2.3-3.3-3.9s1.1-2.9,3.3-3.9c2.2-1.1,4.7-1.5,7.7-1.7h0.3
	C611.3,295.7,613,296.3,614.5,297.4L614.5,297.4z"
          />
          <path
            fill="#FFFFFF"
            d="M391.9,221.7c-0.5,0.6-1,1.4-1,2.2c0,0.6,0.1,1.1,0.4,1.5l0.5,0.7c0.5,0.6,1.4,1,2.2,1l217.2,3.9h5.9
	c0.8,0,1.7-0.3,2.2-1c0.6-0.6,1-1.4,1-2.2s-0.3-1.6-1-2.2s-1.4-1-2.2-1h-11.8l-211.3-3.9C393.3,220.7,392.5,221.2,391.9,221.7
	L391.9,221.7z M394.1,376.2c-0.8,0-1.6,0.3-2.2,1l-0.5,0.7c-0.3,0.4-0.4,1-0.4,1.5c0,0.8,0.3,1.6,1,2.2c0.5,0.6,1.4,1,2.2,1
	l211.3-3.9h11.8c0.8,0,1.7-0.3,2.2-1c0.6-0.5,1-1.4,1-2.2c0-0.8-0.3-1.6-1-2.2c-0.5-0.5-1.4-1-2.2-1h-5.9L394.1,376.2L394.1,376.2z
	 M616.6,301.2c0-1.5-0.7-2.9-2.1-3.9c-1.4-1.1-3.2-1.7-5.1-1.7h-0.3c-3,0-5.5,0.5-7.7,1.6c-2.2,1.1-3.3,2.3-3.3,3.9s1.1,2.8,3.3,3.9
	c2.2,1.1,4.7,1.7,7.7,1.7h0.3c1.9,0,3.7-0.5,5.1-1.7C615.9,304,616.6,302.7,616.6,301.2L616.6,301.2z M613.4,300.4
	c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4s0.4,0.5,0.4,1c0,0.4-0.1,0.7-0.4,1s-0.5,0.4-1,0.4s-0.7-0.1-1-0.4s-0.4-0.5-0.4-1
	C613,300.9,613.1,300.7,613.4,300.4L613.4,300.4z"
          />
          <path
            fill="#FFFFFF"
            d="M614.4,300c-0.4,0-0.7,0.1-1,0.4s-0.4,0.5-0.4,1c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4
	s0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-1S614.8,300,614.4,300L614.4,300z M419.4,170.9h-3.7c-2.6-0.3-4-1-3.9-1.6l-67,0.6l-2.2,5.9
	c-0.3,0.8-1,1.2-1.9,1.2c-1,1.6-2.9,2.8-5.5,3.3c-3.3,0.7-7,0.1-10.9-1.6c-2.1-1.2-2.5-2.6-1.4-4.3l3.2-4.4l-24.8,0.1
	c2.8,4.1,5.5,7.4,8.4,10c2.3,2.1,4.7,3.7,7.3,4.8l5.6-0.3c37.3-1.8,73.3-2.9,107.9-3.2c10.3-0.1,20.6-0.1,30.7-0.1
	c-0.4-2.3-2.9-6.5-7.6-12.4h-5c0.4,0.8-0.8,1.4-3.4,1.8h-5.5c-3.3,0.8-6.3,1.4-9.2,1.6C426.6,172.6,422.9,172.2,419.4,170.9
	L419.4,170.9z"
          />
          <path
            fill="#FFFFFF"
            d="M420,169.3c-0.4,0.6-0.5,1.1-0.5,1.8c3.4,1.1,7.2,1.7,11.1,1.4c2.9-0.1,5.9-0.7,9.2-1.6
	c-0.1-0.7-0.3-1.2-0.7-1.6L420,169.3L420,169.3z M350.1,146.3l-1-0.3c-4.8-0.8-9.2,0.4-13.3,3.9c-2.9,2.5-5.2,5.2-6.9,8.1
	c-1.4,2.3-2.3,4.8-2.9,7.4c-0.4,1.5-0.3,3,0.1,4.3v0.1c0.5,1.4,1.5,2.5,2.9,3.4c0.5,0.4,1.1,0.8,1.8,1.1l6.1,1.8
	c0.8,0.3,1.5,0.4,2.1,0.6L350.1,146.3z M352.2,149.8c0.7-1.7,0-2.8-2.1-3.4l-11,30.5l1.6,0.1c1-0.1,1.7-0.6,1.9-1.2l2.2-5.9
	L352.2,149.8L352.2,149.8z"
          />
          <path fill="#D3D3D3" d="M326.3,170v-0.3l-0.1,0.3" />
          <path
            fill="#FFFFFF"
            d="M326.3,170h-0.1l-3.2,4.4c1.2,1.4,2.6,2.5,4.1,3.3l2.1-4.1C327.8,172.5,326.9,171.2,326.3,170L326.3,170z"
          />
          <path
            fill="#FFFFFF"
            d="M323,174.2c-1.1,1.6-0.7,3.2,1.4,4.3c3.9,1.9,7.6,2.5,10.9,1.6c2.8-0.6,4.5-1.6,5.5-3.3
	c-1.4,1.5-3.6,2.3-6.5,2.5c-2.8,0-5.1-0.7-7.3-1.8C325.6,176.7,324.2,175.6,323,174.2L323,174.2z M322.7,184.7l-5.6,0.3l4.8,1.2
	l15.4-0.7c32.3-1.4,63.4-2.2,93.4-2.5l13.8-0.1h16.8c0.1-0.4,0.3-1,0.1-1.5c-10,0-20.4,0-30.7,0.1C396,181.9,360.1,183,322.7,184.7
	L322.7,184.7z M296.7,174.5c0.3,0.1,0.4,0.3,0.4,0.4c-0.1,2.3,0.8,4.1,2.9,5.5l6.6,5.6l3.3-0.4l7.2-0.7c-2.5-1.1-5-2.8-7.3-4.8
	c-2.9-2.6-5.8-5.9-8.4-10h-6.5c-1.2-0.4-2.5-0.8-3.9-1.1l-10.3-0.6L296.7,174.5L296.7,174.5z"
          />
          <path
            fill="#FFFFFF"
            d="M297,175.1c0-0.1-0.1-0.3-0.4-0.4l-15.8-6.1h-0.4h0.4l10.3,0.6c-8.9-2.5-18.8-3.9-29.4-4.1l2.2,0.1l-2.2-0.1
	h-0.1c-24.9-2.1-53.2-1.2-85.1,2.6c-10.3,1.8-19.3,5.9-26.7,12.4c24.4-8.3,58.3-12.2,101.9-12l10.9,0.1l14.9,0.4l2.6,0.1l-2.6-0.1
	l16,5.6h0.1c1.4,0.6,0.5,1.1-2.6,1.4h-6.1c-1.5-0.3-3-0.6-4.7-1.2l-17.7-6.3l-10.9-0.1c16.4,4.4,29.6,11.6,39.9,21.5
	c2.2-0.6,4.3-1.1,6.5-1.6l1.5-0.3l7-1.2l-6.6-5.6C298,179.2,297,177.4,297,175.1L297,175.1z"
          />
          <path
            fill="#D3D3D3"
            d="M299.5,187.3l-1.5,0.3c-2.2,0.6-4.3,1-6.5,1.6l0.1,0.1C294.4,188.7,297,188,299.5,187.3L299.5,187.3z"
          />
          <path
            fill="#FFFFFF"
            d="M309.9,185.7c-3.4,1.6-6.2,4-8.1,7l2.1,0.4l5.9,1.2c20.8,4.5,42.1,10.9,64.1,19.1c2.9-2.2,5.8-3.9,8.8-4.7
	C361.4,200,337,192.3,309.9,185.7L309.9,185.7z"
          />
          <path
            fill="#FFFFFF"
            d="M309.8,185.7l-3.3,0.4l-7,1.2c-2.6,0.6-5.2,1.2-7.7,2.1c-8.5,3.6-15.3,9.8-20.2,18.3
	c-12.8,20.4-20.8,47-23.8,80.3c-0.3,2.8-0.4,5.5-0.6,8.3c-0.3,7,0,13.9,0.7,20.8c3.2,32.5,11,58.6,23.7,78.5
	c5,8.5,11.7,14.7,20.2,18.3c2.6,0.8,5.1,1.5,7.7,2.1l7,1.2l3.3,0.4c-3.4-1.6-6.1-4-8.1-7l2.1-0.4c-2.9-2.9-5.6-6.1-8.3-9.2
	c-4.1-5.1-7.8-10.3-11.3-16.1l-2.3-4.1c-2.9-5.4-5.6-11.1-8-17.1c-1-2.5-1.8-5-2.6-7.4c-2.1-6.2-3.7-12.5-5.1-19.3
	c-0.7-3.4-1.4-6.9-1.9-10.3c-0.5-3-1-6.2-1.2-9.4l-0.7-7.7c-0.3-2.6-0.3-5.2-0.3-8c0-5,0.4-10,0.8-15c0.4-3.4,0.8-6.7,1.4-10
	c0.5-3.6,1.2-7,1.9-10.3c1.4-6.7,3.2-13.1,5.1-19.3c0.8-2.5,1.8-4.9,2.6-7.4c2.3-5.9,5-11.7,8-17.1c0.8-1.4,1.5-2.8,2.3-4.1
	c3.3-5.6,7.2-11,11.3-16.1c2.6-3.2,5.4-6.3,8.3-9.2l-2.1-0.4C303.7,189.7,306.5,187.3,309.8,185.7L309.8,185.7z"
          />
          <path
            fill="#D3D3D3"
            d="M359,225.6l0.4-1l-2.1,3.3 M359.5,378.7l-0.4-0.8l-1.6-2.5"
          />
          <path
            fill="#FFFFFF"
            d="M339.2,176.9c-0.7-0.1-1.4-0.3-2.1-0.6l-6.1-1.8c-0.7-0.3-1.2-0.7-1.8-1.1l-2.1,4.1c2.2,1.2,4.5,1.8,7.3,1.8
	c2.9-0.1,5-1,6.5-2.5L339.2,176.9L339.2,176.9z M342.9,192l-5.6-6.3l-15.4,0.6C329.2,188.1,336.1,189.9,342.9,192L342.9,192z
	 M291.1,175.5c3-0.3,4-0.7,2.6-1.4h-0.1l-16-5.6l-14.9-0.4l17.7,6.3c1.6,0.6,3.2,1,4.7,1.2h0.3L291.1,175.5L291.1,175.5z M289,190.1
	l2.6-0.8c-10.2-9.9-23.5-17.1-39.9-21.5c-43.6-0.3-77.6,3.7-101.9,12c-6.2,5-11.7,10-16.5,15.3c-4.1,4.4-7.8,8.9-11,13.6l-2.3,3.4
	c-1.1,1.8-2.2,3.6-3.2,5.5c-1,1.8-1.9,3.6-2.8,5.5c-1.1,2.3-1.9,4.8-2.8,7.4c-0.8,2.5-1.4,4.9-1.9,7.4l-0.6,2.5
	c-4,19.3-5.9,39.3-5.8,60.4v0.7c-0.1,13.1,0.6,25.7,1.9,38.1c1,7.8,2.2,15.5,3.7,23.2l0.6,2.5c0.6,2.5,1.2,5,1.9,7.4
	c0.8,2.5,1.7,5,2.8,7.4c0.8,1.8,1.8,3.7,2.8,5.5c1,1.6,1.9,3.4,2.9,5.1l3,4.5c3,4.4,6.6,8.7,10.5,12.9c4.8,5.2,10.3,10.3,16.5,15.3
	c24.4,8.3,58.3,12.2,101.9,12c16.4-4.4,29.6-11.6,39.9-21.5l-2.6-0.8c-8.5-3.6-15.3-9.6-20.2-18.2c-12.7-19.8-20.5-45.8-23.7-78
	c-0.7-6.9-0.8-13.9-0.7-20.8c0.1-2.8,0.3-5.5,0.4-8.3c3-33,11-59.7,23.8-79.8C273.8,199.7,280.5,193.6,289,190.1L289,190.1z
	 M187.1,181c1.2,2.2,0.7,5.2-1.8,9.1c-4.3,5.9-10,11.4-17.3,16.9c-9.1,7.8-16.9,12.7-23.5,14.4c-2.5,0.7-4.4,1-5.8,0.8
	c-1.1-0.1-1.9-0.4-2.5-0.8c-1.8-2.3-2.1-5.1-0.6-8.4c4-8.5,9.1-16.1,15-22.8c6.7-8,14.3-12.2,22.6-12.8h1.8
	C181.4,177.3,185.4,178.5,187.1,181L187.1,181z M236.1,289c0.3,2.5,0.6,4.8,0.7,7l0.1,5.1l-0.3,6.5c-0.1,2.2-0.4,4.4-0.7,6.7
	c-1.2,8.9-5.6,13.9-13.3,14.9c-23.1-1.6-42.1-4-56.8-7c-9.9-3.2-14.7-9.9-14.6-19.9v-2.5c0.3-9.2,5.2-15.4,14.4-18.4
	c14.9-3,33.8-5.4,56.8-7C230.4,275.2,234.8,280.2,236.1,289L236.1,289z M185.3,413.3c2.5,3.9,3,6.9,1.8,9.1
	c-1.6,2.5-5.6,3.7-12.1,3.6h-1.8c-8.3-0.4-15.8-4.7-22.6-12.8c-5.9-6.6-11-14.2-15-22.8c-1.4-3.3-1.2-6.1,0.6-8.4
	c0.6-0.4,1.4-0.7,2.5-0.8c1.5-0.1,3.4,0.1,5.8,0.8c6.6,1.8,14.4,6.6,23.5,14.4C175.3,401.8,181,407.4,185.3,413.3L185.3,413.3z
	 M149.3,190.2c1.6-1.9,6.2-6.5,13.8-13.5C155.4,183.7,150.9,188.3,149.3,190.2c-5.8,6.5-10.6,13.8-14.6,22c-2.3,4.4-4.8,7.2-7.6,8.4
	c-1.8,5.4-3.3,11.3-4.7,17.5l-0.6,2.3c-1.2,6.1-2.3,12.4-3.3,19.3c-2.2,15.5-3,31.1-2.8,46.8c0.3,15.4,1.7,30.8,4.1,46.1
	c1.9,11.1,4.3,21.2,7.2,30.1c2.8,1.2,5.4,4.1,7.6,8.4c4,8.3,8.8,15.5,14.6,22c1.6,1.9,6.2,6.5,13.8,13.5c-7.6-7-12.1-11.6-13.8-13.5
	c-5.8-6.5-10.6-13.8-14.6-22c-2.2-4.4-4.8-7.2-7.6-8.4c-2.9-8.9-5.2-19-7.2-30.1c-2.3-15.3-3.7-30.7-4.1-46.1
	c-0.3-15.7,0.6-31.2,2.8-46.8c1-6.7,2.1-13.2,3.3-19.3l0.6-2.3c1.4-6.3,2.9-12.1,4.7-17.5c2.8-1.2,5.4-4.1,7.6-8.4
	C138.7,204,143.5,196.7,149.3,190.2L149.3,190.2z M124.6,289.5l0.8-0.7v8l0.8,0.8c1,1,1.4,2.2,1.4,3.6c0,1.4-0.4,2.6-1.4,3.6
	l-0.8,0.8v8l-0.8-0.7l-0.8-0.7l-0.7-0.7l-0.7-0.8l-0.8-1l-0.8-1.2l-0.8-1.4v-2.2l-0.1-0.1c-1-1-1.4-2.2-1.4-3.6
	c0-1.4,0.4-2.6,1.4-3.6l0.1-0.1v-2.2l0.8-1.2l0.8-1.2l0.8-1l0.7-0.8l0.7-0.7L124.6,289.5L124.6,289.5z M134.9,230.2
	c28.3-2.5,69.6-9.6,123.9-21.2C204.6,220.6,163.3,227.6,134.9,230.2L134.9,230.2z M134.9,373.2c28.3,2.5,69.6,9.6,123.9,21.2
	C204.6,382.7,163.3,375.6,134.9,373.2z"
          />
          <path
            fill="#D3D3D3"
            d="M185.3,190.1c2.5-3.9,3-6.9,1.8-9.1c-1.6-2.5-5.6-3.7-12.1-3.6h-1.8c-8.3,0.4-15.8,4.7-22.6,12.8
	c-5.9,6.6-11,14.2-15,22.8c-1.4,3.3-1.2,6.1,0.6,8.4c0.6,0.4,1.4,0.7,2.5,0.8c1.5,0.1,3.4-0.1,5.8-0.8c6.6-1.8,14.4-6.6,23.5-14.4
	C175.3,201.5,181,195.8,185.3,190.1L185.3,190.1z M169.6,180.3l1.9-0.1c4.5-0.1,7.2,0.7,8,2.8c0.1,0.6,0.3,1.4,0.1,2.2
	c-0.3,1.5-1.1,3.3-2.5,5.5c-3.7,4.8-8.3,9.6-13.9,14.2c-7,6.5-12.9,10.6-17.9,12.4c-1.5,0.6-2.8,0.8-3.9,1c-1,0.1-1.8,0-2.3-0.4
	c-0.7-0.8-1.1-1.9-1.1-3.2c0-1,0.3-2.1,0.8-3.2c3.3-7,7.4-13.5,12.4-19.3C157.1,185,163.1,181,169.6,180.3L169.6,180.3z"
          />
          <path
            fill="#FFFFFF"
            d="M236.7,296.1c-0.1-2.2-0.4-4.5-0.7-7c-1.2-8.9-5.6-13.9-13.3-14.9c-23.1,1.6-42.1,4-56.8,7
	c-9.4,3-14.2,9.2-14.4,18.4v2.5c-0.1,10.2,4.7,16.8,14.6,19.9c14.9,3,33.8,5.4,56.8,7c7.7-1.1,12.1-6.1,13.3-14.9
	c0.3-2.3,0.6-4.5,0.7-6.7l0.3-6.5L236.7,296.1L236.7,296.1z"
          />
          <path
            fill="#D3D3D3"
            d="M187.1,422.3c1.2-2.2,0.7-5.2-1.8-9.1c-4.3-5.9-10-11.4-17.3-16.9c-9.1-7.8-16.9-12.7-23.5-14.4
	c-2.5-0.7-4.4-1-5.8-0.8c-1.1,0.1-1.9,0.4-2.5,0.8c-1.8,2.3-2.1,5.1-0.6,8.4c4,8.5,9.1,16.1,15,22.8c6.7,8,14.3,12.2,22.6,12.8h1.8
	C181.4,426,185.4,424.8,187.1,422.3L187.1,422.3z M179.7,418.1c0.1,0.8,0,1.5-0.1,2.2c-0.8,2.1-3.6,2.9-8,2.8l-1.9-0.1
	c-6.5-0.7-12.5-4.5-18.3-11.8c-5-5.8-9.1-12.2-12.4-19.3c-0.6-1.1-0.8-2.2-0.8-3.2c0-1.2,0.3-2.2,1.1-3.2c0.6-0.4,1.4-0.5,2.3-0.4
	c1,0.1,2.3,0.4,3.9,1c4.8,1.8,10.7,5.9,17.9,12.4c5.6,4.5,10.3,9.4,13.9,14.2C178.6,414.8,179.4,416.6,179.7,418.1L179.7,418.1z
	 M125.5,288.8l-0.8,0.7v6.7c0.3,0.1,0.6,0.3,0.8,0.4V288.8L125.5,288.8z M123.1,290.9l-0.7,0.8v4.4l0.7-0.1"
          />
          <path fill="#D3D3D3" d="M123.9,290.2l-0.8,0.7v5.1l0.8,0.1" />
          <path
            fill="#D3D3D3"
            d="M123.9,296.1h-1.4l-0.8,0.1l-0.8,0.4c0,0-0.6,0.4-0.8,0.7l-0.1,0.1c-1,1-1.4,2.2-1.4,3.6
	c0,1.4,0.4,2.6,1.4,3.6l0.1,0.1l0.8,0.7l0.8,0.4l0.8,0.1h0.7l0.7-0.1l0.8-0.3l0.8-0.4l0.8-0.8c1-1,1.4-2.2,1.4-3.6s-0.4-2.6-1.4-3.6
	l-0.8-0.8l-0.8-0.4L123.9,296.1L123.9,296.1z M125.9,298.1c0.8,0.8,1.2,1.9,1.2,3.2s-0.4,2.2-1.2,3.2c-0.8,0.8-1.7,1.2-2.9,1.2
	c-1.1,0-2.1-0.4-2.8-1.2c-0.8-0.8-1.1-1.9-1.1-3.2s0.4-2.2,1.1-3.2c0.8-0.8,1.8-1.2,2.8-1.2C124.2,296.7,125.2,297.1,125.9,298.1
	L125.9,298.1z"
          />
          <path
            fill="#D3D3D3"
            d="M127.1,301.1c0-1.2-0.4-2.2-1.2-3.2c-0.8-0.8-1.7-1.2-2.8-1.2s-2.1,0.4-2.9,1.2c-0.8,0.8-1.1,1.9-1.1,3.2
	s0.4,2.3,1.1,3.2c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.8-1.2C126.7,303.4,127.1,302.3,127.1,301.1z M125.7,298.3
	c0.7,0.8,1.1,1.8,1.1,2.9s-0.4,2.1-1.1,2.9c-0.7,0.8-1.5,1.2-2.6,1.2c-1,0-1.8-0.4-2.5-1.2c-0.7-0.8-1.1-1.8-1.1-2.9
	c0-1.1,0.4-2.1,1.1-2.9c0.7-0.8,1.5-1.2,2.5-1.2S124.9,297.5,125.7,298.3L125.7,298.3z"
          />
          <path
            fill="#D3D3D3"
            d="M126.7,301.1c0-1.1-0.4-2.1-1.1-2.9c-0.7-0.8-1.5-1.2-2.6-1.2c-1,0-1.8,0.4-2.5,1.2
	c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.1,1.1,2.9c0.7,0.8,1.5,1.2,2.5,1.2s1.9-0.4,2.6-1.2C126.4,303.1,126.7,302.3,126.7,301.1
	L126.7,301.1z M124.1,297.6v0.5l-1.2,0.7l1.2,0.7v0.5h-1.9v-0.5h1.1l-1.1-0.5v-0.1l1.1-0.6h-1.1v-0.5h1.9V297.6L124.1,297.6z
	 M122.2,301.1v-0.5h1.9v0.5H122.2z M122.2,301.6h1.9v0.4l-1.2,1.1h1.2v0.4h-1.9v-0.4l1.2-1.1h-1.2V301.6z M122.2,304.5V304h1.9v0.5
	L122.2,304.5L122.2,304.5z"
          />
          <path
            fill="#D3D3D3"
            d="M124.1,298.2v-0.5h-1.9v0.5h1.1l-1.1,0.5v0.3l1.1,0.4h-1.1v0.5h1.9v-0.5l-1.2-0.5 M122.2,300.5v0.5h1.9v-0.5
	 M124.1,301.6h-1.9v0.4h1.1l-1.1,1.1v0.4h1.9v-0.4h-1.2l1.2-1.1 M122.2,304v0.5h1.9V304 M121.6,296.3l0.8-0.1v-4.4l-0.8,1"
          />
          <path
            fill="#D3D3D3"
            d="M120.8,296.7l0.8-0.4v-3.6l-0.8,1.2L120.8,296.7L120.8,296.7z"
          />
          <path
            fill="#D3D3D3"
            d="M120.8,296.7v-2.8l-0.8,1.2v2.2C120.2,297.1,120.5,296.8,120.8,296.7L120.8,296.7z M123.9,296.1l0.7,0.1
	v-6.7l-0.7,0.7 M121.6,306l-0.8-0.4v2.8l0.8,1.2"
          />
          <path
            fill="#D3D3D3"
            d="M122.4,306.2l-0.8-0.1v3.6l0.8,1 M123.8,306.2l-0.7,0.1v5.1l0.8,0.7 M123.1,306.3l-0.7-0.1v4.4l0.7,0.8
	 M125.5,305.5l-0.8,0.4v6.9l0.8,0.7V305.5L125.5,305.5z"
          />
          <path
            fill="#D3D3D3"
            d="M124.6,306l-0.8,0.1l0.1,5.9l0.7,0.7 M120.8,305.6l-0.8-0.7v2.2l0.8,1.2"
          />
          <path
            fill="#FFFFFF"
            d="M291.6,189.2l-2.6,0.8c-8.5,3.6-15.3,9.6-20.2,18.2C256,228.4,248,255,245,288.1c-0.3,2.8-0.4,5.5-0.4,8.3
	c-0.3,7,0,13.9,0.7,20.8c3.2,32.2,11,58.2,23.7,78c5,8.5,11.7,14.6,20.2,18.2l2.6,0.8l0.1-0.1c-8.5-3.6-15.3-9.8-20.2-18.3
	c-12.7-19.9-20.5-46.1-23.7-78.5c-0.7-6.9-0.8-13.9-0.7-20.8c0.1-2.8,0.3-5.5,0.6-8.3c3-33.3,11-60,23.8-80.3
	c5-8.5,11.7-14.7,20.2-18.3L291.6,189.2L291.6,189.2z"
          />
          <path
            fill="#FFFFFF"
            d="M261.6,438.4l2.3-0.1L261.6,438.4c10.7-0.3,20.5-1.7,29.6-4.1l-10.3,0.5h-0.4h0.4l15.8-6.1
	c0.3-0.1,0.4-0.3,0.4-0.4c-0.1-2.3,0.8-4.1,2.9-5.5l6.6-5.6l-7-1.2l-1.5-0.3c-2.2-0.5-4.3-1-6.5-1.6c-10.2,9.9-23.5,17.1-39.9,21.5
	l10.9-0.1l17.7-6.3c1.6-0.5,3.2-1,4.7-1.2h6.1c3,0.3,4,0.7,2.6,1.4h-0.1l-16,5.6l2.6-0.1l-2.6,0.1l-14.9,0.4l-10.9,0.1
	c-43.6,0.3-77.6-3.7-101.9-12c7.4,6.5,16.2,10.6,26.7,12.4C208.3,439.6,236.7,440.6,261.6,438.4L261.6,438.4z"
          />
          <path
            fill="#D3D3D3"
            d="M291.8,413.9l-0.1,0.1c2.2,0.5,4.3,1.1,6.5,1.6l1.5,0.3C297,415.4,294.4,414.7,291.8,413.9L291.8,413.9z"
          />
          <path
            fill="#FFFFFF"
            d="M293.6,429.2c1.4-0.5,0.5-1.1-2.6-1.4h-6.1c-1.5,0.3-3,0.5-4.7,1.2l-17.7,6.3l14.9-0.4L293.6,429.2
	L293.6,429.2z"
          />
          <path
            fill="#FFFFFF"
            d="M291.1,434.2l3.9-1.1h6.5c2.8-4.1,5.5-7.6,8.4-10c2.3-2.1,4.7-3.7,7.3-4.8l-7.2-0.7l-3.3-0.4l-6.6,5.6
	c-2.1,1.4-3,3.2-2.9,5.5c0,0.1-0.1,0.3-0.4,0.4l-15.8,6.1L291.1,434.2L291.1,434.2z"
          />
          <path
            fill="#FFFFFF"
            d="M317.1,418.3c-2.5,1.1-5,2.8-7.3,4.8c-2.9,2.6-5.8,5.9-8.4,10l24.8,0.1l-3.2-4.4c-1.1-1.6-0.7-3.2,1.4-4.3
	c3.9-1.9,7.6-2.5,10.9-1.6c2.6,0.5,4.5,1.8,5.5,3.3c1,0.1,1.6,0.6,1.9,1.2l2.2,5.9l67,0.5c0-0.8,1.2-1.4,3.9-1.6h3.7
	c3.4-1.1,7.2-1.6,11.1-1.4c2.9,0.1,5.9,0.7,9.2,1.6h5.5c2.6,0.4,3.9,1,3.4,1.8h5c4.5-5.9,7.2-10,7.6-12.4c-10,0-20.4,0-30.7-0.1
	c-34.7-0.4-70.6-1.5-107.9-3.2L317.1,418.3L317.1,418.3z"
          />
          <path
            fill="#FFFFFF"
            d="M317.1,418.3l4.8-1.2c7.3-1.8,14.2-3.7,20.9-5.8c14.9-4.4,28.5-9.1,41-14.3c15.8,0.3,31.4,0.4,46.8,0.4h42.1
	l0.8-2.8c-14.2,0.1-28.5,0.1-42.9,0c-15.4,0-31.1-0.3-47-0.4l-0.5,0.1l-0.1,0.1c-21.6,8.9-45.8,16.6-73,23.1L317.1,418.3
	L317.1,418.3z"
          />
          <path
            fill="#FFFFFF"
            d="M321.9,417l-4.8,1.2l5.6,0.3c37.3,1.8,73.3,2.9,107.9,3.2c10.3,0.1,20.6,0.1,30.7,0.1
	c0.1-0.7,0.1-1.2-0.1-1.5h-16.8l-13.8-0.1c-30-0.3-61.1-1.1-93.4-2.5L321.9,417L321.9,417z M309.8,408.9l-5.9,1.2l-2.1,0.4
	c1.9,3,4.7,5.4,8.1,7c27.1-6.5,51.5-14.2,73-23.1c-3-0.8-6.1-2.5-8.8-4.7C352,397.9,330.6,404.4,309.8,408.9L309.8,408.9z
	 M324.4,424.6c-2.1,1.2-2.5,2.6-1.4,4.3c1.2-1.4,2.6-2.5,4.1-3.3c2.2-1.2,4.5-1.8,7.3-1.8c2.9,0.1,5,1,6.5,2.5
	c-1-1.6-2.9-2.8-5.5-3.3C331.9,422.3,328.2,422.8,324.4,424.6L324.4,424.6z"
          />
          <path fill="#D3D3D3" d="M326.3,433.3h-0.1l0.1,0.3" />
          <path
            fill="#FFFFFF"
            d="M326.2,433.3h0.1c0.5-1.4,1.5-2.5,2.9-3.4l-2.1-4.1c-1.5,0.8-2.9,1.9-4.1,3.3L326.2,433.3L326.2,433.3z"
          />
          <path
            fill="#FFFFFF"
            d="M326.3,433.3v0.1c-0.4,1.2-0.5,2.8-0.1,4.3c0.5,2.6,1.5,5.1,2.9,7.4c1.6,2.9,4,5.5,6.9,8.1
	c4.1,3.4,8.5,4.8,13.3,3.9l1-0.3l-11-30.5c-0.7,0.1-1.4,0.3-2.1,0.5l-6.1,1.8c-0.7,0.3-1.2,0.7-1.8,1.1
	C327.8,430.9,326.9,432,326.3,433.3L326.3,433.3z"
          />
          <path
            fill="#FFFFFF"
            d="M337,427.1c0.8-0.3,1.5-0.4,2.1-0.5l1.6-0.1c-1.4-1.5-3.6-2.3-6.5-2.5c-2.8,0-5.1,0.7-7.3,1.8l2.1,4.1
	c0.5-0.4,1.1-0.8,1.8-1.1L337,427.1L337,427.1z"
          />
          <path
            fill="#FFFFFF"
            d="M340.7,426.4l-1.6,0.1l11,30.5c2.1-0.7,2.8-1.8,2.1-3.4l-7.3-20.1l-2.2-5.9
	C342.4,426.8,341.8,426.4,340.7,426.4L340.7,426.4z M337.3,417.7l5.6-6.3c-6.7,1.9-13.8,3.9-20.9,5.8L337.3,417.7L337.3,417.7z
	 M419.4,432.3h-3.7c-2.6,0.3-4,1-3.9,1.6c0,0.4,0.3,0.8,1,1.2l4.3,1.9c2.9,1.6,7.4,2.6,13.5,2.8h4.3c4.4,0,7.2-1.2,8.3-3.7
	c3.3,0.3,5.1-0.3,5.5-1.8l0.1-0.3c0.4-0.8-0.8-1.4-3.4-1.8h-5.5c-0.1,0.7-0.3,1.2-0.7,1.6c-0.8,1.1-2.5,1.6-4.8,1.6h-8.8
	c-2.9-0.1-4.8-0.7-5.6-1.8C419.6,433.6,419.4,433,419.4,432.3L419.4,432.3z"
          />
          <path
            fill="#FFFFFF"
            d="M419.4,432.3c0,0.7,0.1,1.2,0.5,1.8l19.3,0.1c0.3-0.4,0.5-1.1,0.7-1.7c-3.3-0.8-6.3-1.4-9.2-1.6
	C426.6,430.7,422.9,431.1,419.4,432.3L419.4,432.3z"
          />
          <path
            fill="#D3D3D3"
            d="M691.9,181.4l0.3-0.1l-2.3-2.2l-3.7,2.5c-1.2,0.8-1.7,2.5-1.4,4.7c0.3,2.3,1.4,4.7,3,7.2l4.8,7.2
	c1.7,2.5,3.6,4.3,5.6,5.4c2.1,1.1,3.7,1.2,4.8,0.4l3.4-2.3l-0.8-1.8l-2.6,1.8c-1,0.7-2.3,0.6-4-0.4c-1.7-1-3.3-2.5-4.7-4.5l-4-5.9
	c-1.4-2.1-2.2-4-2.5-5.9c-0.3-1.9,0.1-3.3,1.1-4L691.9,181.4L691.9,181.4z"
          />
          <path
            fill="#D3D3D3"
            d="M692.2,181.3c1-0.6,2.2-0.4,3.9,0.6s3.3,2.5,4.7,4.4l4,5.9c1.4,2.1,2.2,4,2.5,5.9s-0.1,3.2-1.1,3.9l-0.3,0.3
	l0.8,1.8l0.1-0.1c1.2-0.8,1.7-2.5,1.4-4.7c-0.3-2.3-1.4-4.7-3-7.2l-4.8-7.2c-1.7-2.5-3.6-4.3-5.6-5.4c-1.9-1.1-3.6-1.2-4.7-0.6
	L692.2,181.3L692.2,181.3z"
          />
          <path
            fill="#FFFFFF"
            d="M694.6,179.6c2.1,1.1,3.9,2.9,5.6,5.4l4.8,7.2c1.7,2.5,2.6,4.8,3,7.2c0.3,2.3-0.1,3.9-1.4,4.7l-0.1,0.1
	c1,2.3,1.9,4.7,2.8,7.3c1.2,3.4,2.3,7.2,3.3,11c5.1,20.1,8.1,46.5,8.8,79.2c-0.8,32.9-3.7,59.3-8.8,79.2c-1,3.9-2.1,7.6-3.3,11
	c-0.8,2.6-1.8,5-2.8,7.3l0.1,0.1c1.2,0.8,1.7,2.5,1.4,4.7c-0.3,2.3-1.4,4.7-3,7.2l-4.8,7.1c-1.7,2.5-3.6,4.3-5.6,5.4
	c-1.9,1.1-3.6,1.2-4.7,0.5c-3.2,2.8-6.5,4.8-10,6.3c7.2,0.4,15.1-1.6,23.9-6.1c2.9-2.2,5.4-4.7,7.3-7.4c5-4.8,9.4-11.1,12.9-18.8
	l0.1-0.1c4.5-9.9,8.1-22.3,10.6-37c1.4-8.8,2.5-18.6,3.2-29.2c1-8.8,1.4-18.8,1.2-30.1c0.1-11.4-0.3-21.6-1.2-30.4
	c-0.7-10.6-1.7-20.2-3.2-29.2c-2.5-14.7-5.9-27.1-10.6-37l-0.1-0.1c-3.6-7.8-8-14-12.9-18.8c-1.9-2.8-4.3-5.2-7.3-7.4
	c-8.8-4.5-16.8-6.5-23.9-6.1c3.6,1.5,6.9,3.6,10,6.3C691.1,178.4,692.6,178.6,694.6,179.6L694.6,179.6z"
          />
          <path
            fill="#FFFFFF"
            d="M684.8,186.4c-0.3-2.3,0.1-3.9,1.4-4.7l3.7-2.5c-3.2-2.8-6.5-4.8-10-6.3c-4.3-1.8-8.9-2.9-13.9-3
	c-18.4-2.6-38.8-3.9-60.9-3.9l-6.3,19.9c21.2,0.7,38.7,2.8,52.7,5.9c7.7,1.8,14.6,4.3,20.5,7.4c8.4,4.5,15,10.3,19.7,17.5l17.7-5.5
	c-0.8-2.6-1.8-4.9-2.8-7.3l-3.4,2.3c-1.2,0.8-2.9,0.7-4.8-0.4c-2.1-1.1-3.9-2.9-5.6-5.4l-4.8-7.2C686.2,191,685.2,188.7,684.8,186.4
	L684.8,186.4z"
          />
          <path
            fill="#FFFFFF"
            d="M679.8,172.7c7.2-0.4,15.1,1.6,23.9,6.1c-10.9-8.3-28.8-13.1-53.5-14.6c-14.3-0.8-29.2-1-44.7-0.4l-0.5,1.9
	c22.1,0,42.5,1.2,60.9,3.9C670.9,170,675.6,170.9,679.8,172.7L679.8,172.7z M712.6,222.4c-1-3.9-2.1-7.6-3.3-11l-17.7,5.5
	c3,4.7,5.2,9.9,6.6,15.8c3.3,12.8,5.6,27,6.7,42.5l0.4,7l0.5,20.1l-0.5,19.5l-0.4,6.7c-1.1,15.5-3.3,29.7-6.7,42.5
	c-1.4,5.8-3.6,11.1-6.6,15.8l17.7,5.5c1.2-3.4,2.3-7.1,3.3-11c5.1-20.1,8.1-46.5,8.8-79.2C720.7,268.9,717.8,242.5,712.6,222.4
	L712.6,222.4z M688.4,217.7l3.2-1c-4.7-7.2-11.1-13.1-19.7-17.5l-2.3,2.2C677.4,204.6,683.7,210,688.4,217.7L688.4,217.7z
	 M682.2,281.3l-0.3-2.8c-1.4-12.9-4-24.1-7.8-33.4c-0.5-1.8-1.2-3.3-1.9-4.8c-3.6-7.7-8.9-12-15.7-12.8c-9.4,2.6-14.3,7.4-14.9,14.4
	c-1.4,9.4-2.3,19.1-3,29.2l-0.5,11.3c-0.3,6.3-0.4,12.7-0.4,19c0,6.5,0.1,12.8,0.4,19.1l0.5,11.6c0.7,10,1.7,19.8,3,29.2
	c0.5,7,5.5,11.8,14.9,14.4c6.9-0.8,12.1-5.1,15.7-12.8c0.7-1.5,1.4-3,1.9-4.8c3.9-9.4,6.5-20.5,7.8-33.4l0.3-2.6l0.8-20.4
	L682.2,281.3L682.2,281.3z M724.1,205.3c4.5,9.9,8.1,22.3,10.6,37C736.2,230.8,732.8,218.4,724.1,205.3L724.1,205.3z"
          />
          <path
            fill="#FFFFFF"
            d="M691.5,386.5l-3.2-1c-4.7,7.7-11,13.2-18.8,16.4l2.3,2.2C680.4,399.6,686.9,393.7,691.5,386.5L691.5,386.5z"
          />
          <path
            fill="#FFFFFF"
            d="M692.8,402.7c1.7-2.5,3.6-4.3,5.6-5.4c2.1-1.1,3.7-1.2,4.8-0.4l3.4,2.3c1-2.3,1.9-4.7,2.8-7.3l-17.7-5.5
	c-4.7,7.2-11.1,13.1-19.7,17.5c-5.9,3.2-12.8,5.6-20.5,7.4c-14,3.2-31.6,5.1-52.7,5.9l6.3,19.9c22.2,0,42.5-1.2,60.9-3.9
	c5-0.1,9.6-1.1,13.9-3c3.6-1.5,6.9-3.6,10-6.3l-3.7-2.5c-1.2-0.8-1.7-2.5-1.4-4.7c0.3-2.3,1.4-4.7,3-7.2L692.8,402.7L692.8,402.7z"
          />
          <path
            fill="#D3D3D3"
            d="M698.3,397.4c-2.1,1.1-3.9,2.9-5.6,5.4l-4.8,7c-1.7,2.5-2.6,4.8-3,7.2c-0.3,2.3,0.1,3.9,1.4,4.7l3.7,2.5
	l2.3-2.2l-0.3-0.1l-2.9-2.1c-1-0.7-1.4-2.1-1.1-4s1.1-3.9,2.5-5.9l4-5.9c1.4-2.1,2.9-3.6,4.7-4.5c1.7-1,3-1.1,4-0.4l2.6,1.8l0.8-1.8
	l-3.4-2.3C702,396.1,700.3,396.3,698.3,397.4L698.3,397.4z"
          />
          <path
            fill="#D3D3D3"
            d="M705.8,401l0.3,0.3c1,0.7,1.4,2.1,1.1,3.9c-0.3,1.9-1.1,3.9-2.5,5.9l-4,5.9c-1.4,2.1-2.9,3.6-4.7,4.4
	c-1.7,0.8-2.9,1.1-3.8,0.5l-2.3,2.2c1.2,0.7,2.8,0.5,4.7-0.5c2.1-1.1,3.9-2.9,5.6-5.4l4.8-7.2c1.7-2.5,2.6-4.8,3-7.2
	c0.3-2.3-0.1-3.9-1.4-4.7l-0.1-0.1L705.8,401L705.8,401z"
          />
          <path
            fill="#FFFFFF"
            d="M703.8,424.5c-8.8,4.5-16.8,6.5-23.9,6.1c-4.3,1.8-8.9,2.9-13.9,3c-18.4,2.6-38.8,3.9-60.9,3.9l0.6,1.9
	c15.5,0.7,30.4,0.5,44.7-0.4C675.2,437.5,692.9,432.7,703.8,424.5L703.8,424.5z M734.6,361.1c-2.5,14.7-5.9,27.1-10.6,37
	C732.8,384.9,736.2,372.6,734.6,361.1L734.6,361.1z M667.2,203.5l-4.7-2.3l-15.4,9.1v5.1l-3.4,2.1c5.6,2.8,9.9,6.1,12.8,10l31.8-9.8
	c-4.7-7.7-11-13.2-18.8-16.4L667.2,203.5L667.2,203.5z M587.4,162.7l-3.4-0.1c-8.4,0.1-14.2,1.2-17.7,3.4l-3,1.5l-1.1,0.6
	c-1.9,1.5-0.8,2.5,3,2.9l3.7-0.1c-0.1-2.2,1.8-3.4,5.9-3.7l8.8-0.4c3.4-0.1,5.2,0.8,5.6,3.2l5.5-0.3c2.9-0.6,4-1.2,3.3-2.1
	c-0.1-0.4-0.4-0.8-0.7-1.1L597,166c-0.5-0.6-1.1-1.1-1.9-1.5C593.2,163.7,590.6,163,587.4,162.7L587.4,162.7z"
          />
          <path
            fill="#FFFFFF"
            d="M594.7,169.6l-5.5,0.3c-7.7,2.5-14.6,2.8-20.4,1l-3.7,0.1c-3.8-0.4-5-1.2-3-2.9l1.1-0.6l-19.5,0.8l-89.8,0.7
	c4.5,5.9,7.2,10,7.6,12.4c43.3,0,84.3,1.2,123.3,3.6l10.5,0.7l1.7,0.1l0.3,0.1h1.7L605,166l-8.1,0.1l0.3,0.4
	c0.4,0.3,0.7,0.6,0.7,1.1C598.7,168.3,597.6,169,594.7,169.6L594.7,169.6z"
          />
          <path
            fill="#FFFFFF"
            d="M589.2,169.7c-0.4-2.3-2.2-3.3-5.6-3.2l-8.8,0.4c-4.1,0.3-6.2,1.5-5.9,3.7
	C574.6,172.5,581.5,172.2,589.2,169.7L589.2,169.7z M605,166l0.5-1.9l-10.7,0.6c0.8,0.4,1.5,1,1.9,1.5L605,166L605,166z
	 M579.7,207.8h-1.8c-30.4-1-62-1.6-94.9-1.8h-10.3l0.8,2.8c36.2,0.1,70.8,0.8,104.3,1.9h1.8c3.7,0,6.5-1.1,8-3l0.7-1.1l0.3-0.7v-0.1
	c1.9-5.4,4.7-11,8.3-16.9l1.8-2.9h-1.7l-2.1-0.3l-10.5-0.7l0.6,1.6l4.4,10c-1.4,2.8-2.6,5.5-3.4,8.1v-0.1l-0.3,0.6
	C584.8,206.8,582.7,207.8,579.7,207.8L579.7,207.8z"
          />
          <path fill="#D3D3D3" d="M597,185.8l-0.4-0.1H595" />
          <path
            fill="#FFFFFF"
            d="M585.2,186.6l-0.5-1.6c-38.9-2.3-79.9-3.6-123.3-3.6c0.1,0.7,0.1,1.2-0.1,1.5h14.3
	C513.9,183.3,550.4,184.4,585.2,186.6L585.2,186.6z M434.4,167.5h-8.8c-2.9,0.1-4.8,0.7-5.6,1.8l19.3-0.1
	C438.4,167.9,436.8,167.4,434.4,167.5z M472.8,205.9l-0.3-1c-3.6-9.4-7.4-16.8-11.3-22h-16.8c6.5,6.1,12,13.6,16.6,22.8L472.8,205.9
	L472.8,205.9z"
          />
          <path
            fill="#FFFFFF"
            d="M472.5,204.9l0.3,1h10.3c-1.8-9.8-4.3-17.3-7.4-23h-14.3C465.1,188.3,468.8,195.6,472.5,204.9L472.5,204.9z
	 M472.5,398.3l0.3-1h-11.7c-4.7,9.2-10.2,16.8-16.6,22.8h16.8C465.1,415.1,468.8,407.7,472.5,398.3L472.5,398.3z"
          />
          <path
            fill="#FFFFFF"
            d="M472.8,397.4l-0.3,1c-3.6,9.4-7.4,16.8-11.3,22h14.3c3.2-5.5,5.6-13.2,7.4-23L472.8,397.4L472.8,397.4z"
          />
          <path
            fill="#FFFFFF"
            d="M473.6,394.6l-0.8,2.8h10.3c32.9-0.3,64.5-0.8,94.9-1.8h1.8c3,0,5.1,1,6.2,2.6l0.3,0.5v-0.1
	c1,2.6,2.2,5.4,3.4,8.1l-4.4,10l-0.5,1.6l10.5-0.7l2.1-0.3h1.7l-1.8-2.9c-3.6-5.9-6.3-11.6-8.3-16.9v-0.1l-0.3-0.7l-0.7-1.1
	c-1.7-1.9-4.3-3-8-3h-1.8C544.5,393.9,509.8,394.5,473.6,394.6L473.6,394.6z M425.5,435.9h8.8c2.5,0,4-0.5,4.8-1.6l-19.3-0.1
	C420.8,435.2,422.6,435.8,425.5,435.9L425.5,435.9z"
          />
          <path
            fill="#FFFFFF"
            d="M461.2,420.3c0.1,0.4,0.3,1,0.1,1.5c43.3,0,84.3-1.2,123.3-3.6l0.5-1.6c-34.8,2.1-71.3,3.3-109.6,3.6
	L461.2,420.3L461.2,420.3z"
          />
          <path
            fill="#FFFFFF"
            d="M461.4,421.9c-0.4,2.3-2.9,6.5-7.6,12.4l89.8,0.7l19.5,0.8l-1.1-0.5c-1.9-1.5-0.8-2.5,3-2.9l3.7,0.1
	c5.9-1.8,12.7-1.4,20.4,1l5.5,0.3c2.9,0.6,4,1.2,3.3,2.1c-0.1,0.4-0.4,0.8-0.7,1.1l-0.3,0.4l8.1,0.1l-6.3-19.9h-1.7l-0.3,0.1
	l-1.7,0.1l-10.5,0.7C545.7,420.6,504.6,421.9,461.4,421.9L461.4,421.9z"
          />
          <path
            fill="#FFFFFF"
            d="M561.9,435.3l1.1,0.6l3,1.5c3.4,2.2,9.4,3.3,17.7,3.4l3.4-0.1c3.3-0.3,5.8-1,7.6-1.9c0.8-0.4,1.5-1,1.9-1.5
	l0.3-0.4c0.4-0.3,0.7-0.5,0.7-1.1c0.7-0.8-0.4-1.6-3.3-2.1l-5.5-0.3c-0.4,2.3-2.2,3.3-5.6,3.2l-8.8-0.4c-4.1-0.3-6.2-1.5-5.9-3.7
	l-3.7-0.1C561.1,432.7,560.2,433.7,561.9,435.3L561.9,435.3z"
          />
          <path
            fill="#FFFFFF"
            d="M568.8,432.6c-0.1,2.2,1.8,3.4,5.9,3.7l8.8,0.4c3.4,0.1,5.2-0.8,5.6-3.2
	C581.5,431.2,574.6,430.8,568.8,432.6L568.8,432.6z"
          />
          <path fill="#D3D3D3" d="M596.6,417.6l0.4-0.1l-2.1,0.3" />
          <path
            fill="#FFFFFF"
            d="M605.6,439.3l-0.5-1.9l-8.1-0.1c-0.5,0.6-1.1,1.1-1.9,1.5L605.6,439.3L605.6,439.3z M647.1,388v5.1l15.3,9.1
	l4.7-2.3l2.3,2.2c7.8-3.2,14.2-8.7,18.8-16.4l-31.8-9.8c-2.9,3.9-7.2,7.3-12.8,10L647.1,388L647.1,388z"
          />
          <path
            fill="#EBEBEB"
            d="M309.8,194.5l-5.9-1.2c-2.9,2.9-5.6,6.1-8.3,9.2c-4.1,5.1-7.8,10.3-11.3,16.1c-0.8,1.4-1.6,2.8-2.3,4.1
	c-2.9,5.4-5.6,11.1-8,17.1c-1,2.5-1.8,5-2.8,7.4c-2.1,6.2-3.7,12.5-5.1,19.3c-0.7,3.4-1.4,6.9-1.9,10.3c-0.5,3.3-1,6.6-1.4,10
	c-0.5,5-0.8,9.9-0.8,15c0,2.6,0,5.2,0.3,8l0.7,7.7c0.4,3.2,0.8,6.3,1.2,9.4c0.5,3.6,1.2,7,1.9,10.3c1.4,6.7,3.2,13.1,5.1,19.3
	c0.8,2.5,1.8,5,2.8,7.4c2.3,5.9,5,11.7,8,17.1l2.3,4.1c3.3,5.6,7.2,11,11.3,16.1c2.6,3.2,5.4,6.3,8.3,9.2l5.9-1.2
	c20.8-4.5,42.1-10.9,64.1-19.1c-2.2-1.8-4.4-3.9-6.5-6.5c-1.4-1.6-2.6-3.4-4-5.5c-2.5-3.7-4.8-8.3-7.2-13.2
	c-3-6.7-5.9-14.4-8.7-23.2c-3.7-13.5-5.5-26.7-5.2-39.8c-0.3-13.1,1.5-26.3,5.2-39.6c2.8-8.8,5.6-16.6,8.7-23.2
	c2.3-5.1,4.7-9.5,7.2-13.2c1.2-2.1,2.6-3.9,4-5.5c2.1-2.6,4.3-4.8,6.5-6.5C352,205.3,330.6,199,309.8,194.5L309.8,194.5z
	 M309.8,202.6c3.2-0.6,6.6-0.4,10.2,0.6l32.6,8.3c7.7,2.6,10,7.2,6.9,13.2l-0.5,1l-1.6,2.3c-1.9,3.2-3.9,6.9-5.6,10.9
	c-3,6.7-5.9,14.4-8.7,23.2c-3.7,13.3-5.5,26.5-5.2,39.6c-0.3,13.1,1.5,26.3,5.2,39.6c2.8,8.8,5.6,16.6,8.7,23.2
	c1.8,4,3.7,7.6,5.6,10.9l1.6,2.3l0.5,1c3.2,6.2,0.8,10.6-6.9,13.2l-32.6,8.3c-3.6,0.8-7,1-10.2,0.5c-6.1-1-11.3-4.5-15.5-10.5
	c-1.1-1.8-2.2-3.4-3.3-5.2l-2.3-4.1c-3-5.4-5.6-11.1-8-17.1c-1-2.5-1.8-5-2.6-7.4c-2.1-6.2-3.7-12.5-5.1-19.3
	c-0.7-3.4-1.4-6.9-1.9-10.3c-0.5-3-1-6.2-1.2-9.4c-0.4-3.9-0.7-7.7-1-11.7c-0.3-5.1-0.1-10.2,0.5-15.3l0.4-3.7
	c0.4-3.4,0.8-6.7,1.4-10c0.5-3.6,1.2-7,1.9-10.3c1.4-6.7,3.2-13.1,5.1-19.3c0.8-2.5,1.8-4.9,2.8-7.4c2.3-5.9,5-11.7,8-17.1
	c0.8-1.4,1.5-2.8,2.3-4.1c1.1-1.8,2.2-3.6,3.3-5.2C298.5,207.1,303.7,203.7,309.8,202.6L309.8,202.6z"
          />
          <path
            fill="#EBEBEB"
            d="M320,203.1c-3.6-0.8-7-1-10.2-0.6c-6.1,1-11.3,4.5-15.5,10.5c-1.1,1.8-2.2,3.4-3.3,5.2
	c-0.8,1.4-1.7,2.8-2.3,4.1c-2.9,5.4-5.6,11.1-8,17.1c-1,2.5-1.8,5-2.8,7.4c-2.1,6.2-3.7,12.5-5.1,19.3c-0.7,3.4-1.4,6.9-1.9,10.3
	c-0.5,3.3-1,6.6-1.4,10l-0.4,3.7c-0.5,5.1-0.8,10.2-0.5,15.3c0.3,3.9,0.5,7.8,1,11.7c0.4,3.2,0.8,6.3,1.2,9.4
	c0.5,3.6,1.2,7,1.9,10.3c1.4,6.7,3.2,13.1,5.1,19.3c0.8,2.5,1.8,5,2.6,7.4c2.3,5.9,5,11.7,8,17.1l2.3,4.1c1.1,1.8,2.2,3.6,3.3,5.2
	c4.3,6.1,9.5,9.5,15.5,10.5c3.2,0.5,6.6,0.4,10.2-0.5l32.6-8.3c7.7-2.8,10-7.2,6.9-13.2l-2.1-3.3c-1.9-3.2-3.9-6.9-5.6-10.9
	c-3-6.7-5.9-14.4-8.7-23.2c-3.7-13.3-5.5-26.5-5.2-39.6c-0.3-13.1,1.5-26.3,5.2-39.6c2.8-8.8,5.6-16.6,8.7-23.2
	c1.8-4,3.7-7.6,5.6-10.9l2.1-3.3c3.2-6.2,0.8-10.6-6.9-13.2L320,203.1L320,203.1z M337.3,185.5l5.6,6.3c14.9,4.4,28.5,9.1,41,14.3
	c15.8-0.3,31.4-0.4,46.8-0.4h30.4c-4.7-9.2-10.2-16.8-16.6-22.8l-13.8,0.1C400.7,183.5,369.6,184.3,337.3,185.5L337.3,185.5z
	 M171.4,180.3l-1.9,0.1c-6.5,0.7-12.5,4.5-18.3,11.8c-5,5.8-9.1,12.2-12.4,19.3c-0.6,1.1-0.8,2.2-0.8,3.2c0,1.2,0.3,2.2,1.1,3.2
	c0.6,0.4,1.4,0.6,2.3,0.4c1-0.1,2.3-0.4,3.9-1c4.8-1.8,10.7-5.9,17.9-12.4c5.6-4.5,10.3-9.4,13.9-14.2c1.4-2.2,2.3-4,2.5-5.5
	c0.1-0.8,0-1.5-0.1-2.2C178.6,181,175.9,180,171.4,180.3z M179.5,420.3c0.1-0.5,0.3-1.4,0.1-2.2c-0.3-1.5-1.1-3.3-2.5-5.5
	c-3.7-4.8-8.3-9.6-13.9-14.2c-7-6.5-12.9-10.6-17.9-12.4c-1.5-0.6-2.8-0.8-3.9-1c-1-0.1-1.8,0-2.3,0.4c-0.7,0.8-1.1,1.9-1.1,3.2
	c0,1,0.3,2.1,0.8,3.2c3.3,7,7.4,13.5,12.4,19.3c5.8,7.3,11.8,11.3,18.3,11.8l1.9,0.1C175.9,423.2,178.6,422.3,179.5,420.3
	L179.5,420.3z M342.9,411.3l-5.6,6.3c32.3,1.4,63.4,2.2,93.4,2.5l13.8,0.1c6.5-6.1,12-13.6,16.6-22.8h-30.4c-15.4,0-31-0.3-46.8-0.4
	C371.3,402.2,357.7,407,342.9,411.3L342.9,411.3z M692.2,181.3l-0.3,0.1l-2.9,2.1c-1,0.7-1.4,2.1-1.1,4s1.1,3.9,2.5,5.9l4,5.9
	c1.4,2.1,2.9,3.6,4.7,4.5c1.7,1,3,1.1,4,0.4l2.6-1.8C702,193.5,697.5,186.5,692.2,181.3L692.2,181.3z"
          />
          <path
            fill="#EBEBEB"
            d="M696.1,181.8c-1.7-0.8-2.9-1.1-3.9-0.6c5.2,5.2,9.8,12.2,13.6,20.9l0.3-0.3c1-0.7,1.4-2.1,1.1-3.9
	c-0.3-1.9-1.1-3.9-2.5-5.9l-4-5.9C699.4,184.3,697.7,182.8,696.1,181.8L696.1,181.8z M691.5,216.8l-3.2,1l-31.8,9.8
	c6.9,0.8,12.1,5.1,15.7,12.8l14.4-4.1c3-1.8,5.6,1.5,7.8,9.8c3.2,15.3,4.4,27.1,3.7,35.4v0.1c-2.1,6.9-2.9,13.8-2.3,20.4
	c-0.5,6.6,0.1,13.3,2.3,20.2v0.1c0.7,8.3-0.5,20.1-3.7,35.4c-2.2,8.3-4.8,11.6-7.8,9.8l-14.4-4.1c-3.6,7.7-8.9,12-15.7,12.8
	l31.8,9.8l3.2,1c3-4.7,5.2-9.9,6.6-15.8c3.3-12.8,5.6-27,6.7-42.5l0.4-6.7l0.5-19.5l-0.5-20.1l-0.4-7c-1.1-15.5-3.3-29.7-6.7-42.5
	C696.8,226.8,694.6,221.4,691.5,216.8L691.5,216.8z"
          />
          <path
            fill="#EBEBEB"
            d="M694.6,245.9c-2.2-8.3-4.8-11.6-7.8-9.8l-14.4,4.1c0.7,1.5,1.4,3,1.9,4.8c3.8,9.4,6.5,20.5,7.8,33.4l0.3,2.8
	l0.8,20.4l-0.8,20.4l-0.3,2.6c-1.4,12.9-4,24.1-7.8,33.4c-0.5,1.8-1.2,3.3-1.9,4.8l14.4,4.1c3,1.8,5.6-1.5,7.8-9.8
	c3.2-15.3,4.4-27.1,3.7-35.4v-0.1c-2.1-6.9-2.9-13.6-2.3-20.2c-0.5-6.6,0.1-13.3,2.3-20.4V281C699,272.9,697.7,261.2,694.6,245.9
	L694.6,245.9z M705.8,401l-2.6-1.8c-1-0.7-2.3-0.5-4,0.4s-3.3,2.5-4.7,4.5l-4,5.9c-1.4,2.1-2.2,4-2.5,5.9s0.1,3.3,1.1,4l2.9,2.1
	l0.3,0.1C697.4,416.8,702,409.8,705.8,401L705.8,401z"
          />
          <path
            fill="#EBEBEB"
            d="M706.1,401.2l-0.3-0.3c-3.9,8.8-8.4,15.7-13.6,20.9c1,0.5,2.2,0.4,3.9-0.5s3.3-2.5,4.7-4.4l4-5.9
	c1.4-2.1,2.2-4,2.5-5.9C707.5,403.3,707.2,401.9,706.1,401.2L706.1,401.2z M662.4,201.2l4.7,2.3l2.3-2.2l2.3-2.2
	c-5.9-3.2-12.8-5.6-20.5-7.4c-14-3.2-31.6-5.1-52.7-5.9l-1.8,2.9c-3.6,5.9-6.3,11.6-8.3,16.9v0.1l-0.3,0.7l-0.7,1.1
	c25.2,0.6,43.9,3.9,56.1,9.9l3.4-2.1v-5.1L662.4,201.2L662.4,201.2z M577.9,207.8h1.8c3,0,5.1-1,6.2-2.6l0.3-0.6v0.1
	c1-2.6,2.2-5.4,3.4-8.1l-4.4-10c-34.8-2.1-71.3-3.3-109.6-3.6c3.2,5.5,5.6,13.2,7.4,23C515.9,206.2,547.5,206.7,577.9,207.8
	L577.9,207.8z M483,397.4c-1.8,9.8-4.3,17.3-7.4,23c38.2-0.3,74.8-1.5,109.6-3.6l4.4-10c-1.4-2.8-2.6-5.5-3.4-8.1v0.1l-0.3-0.5
	c-1.1-1.6-3.2-2.6-6.2-2.6h-1.8C547.5,396.5,515.9,397.1,483,397.4L483,397.4z M587.7,395.7l0.7,1.1l0.3,0.7v0.1
	c1.9,5.4,4.7,11,8.3,16.9l1.8,2.9c21.2-0.7,38.7-2.6,52.7-5.9c7.7-1.8,14.6-4.3,20.5-7.4l-2.3-2.2l-2.3-2.2l-4.7,2.3l-15.3-9.1v-5.1
	l-3.4-2.1C631.4,391.9,612.7,395.2,587.7,395.7L587.7,395.7z"
          />
          <path
            fill="none"
            d="M672.3,363l14.4,4.1c3,1.8,5.6-1.5,7.8-9.8c3.2-15.3,4.4-27.1,3.7-35.4v-0.1c-2.1-6.9-2.9-13.6-2.3-20.2
	c-0.5-6.6,0.1-13.3,2.3-20.4v-0.1c0.7-8.4-0.5-20.1-3.7-35.4c-2.2-8.4-4.8-11.6-7.8-9.8l-14.4,4.1 M121.6,309.6V306 M122.4,310.6
	v-4.3 M123.1,311.4v-5.1 M124.6,312.8v-6.9 M123.9,312.1v-5.9 M122.2,304.5h1.9V304h-1.9V304.5L122.2,304.5z M124.1,301.6v0.4
	l-1.2,1.1h1.2v0.4h-1.9v-0.4l1.2-1.1h-1.2v-0.4H124.1z M122.2,301.1h1.9v-0.5h-1.9V301.1z M124.1,297.6h-1.9v0.5h1.1l-1.1,0.5v0.1
	l1.1,0.5h-1.1v0.5h1.9v-0.5l-1.2-0.7l1.2-0.7V297.6L124.1,297.6z M125.9,298.1c-0.8-0.8-1.7-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.8,1.2
	c-0.8,0.8-1.1,1.9-1.1,3.2s0.4,2.2,1.1,3.2c0.8,0.8,1.8,1.2,2.8,1.2c1.1,0,2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.9,1.2-3.2
	C127.1,299.8,126.7,298.9,125.9,298.1L125.9,298.1z M125.7,298.3c-0.7-0.8-1.5-1.2-2.6-1.2c-1,0-1.8,0.4-2.5,1.2
	c-0.7,0.8-1.1,1.8-1.1,2.9s0.4,2.1,1.1,2.9c0.7,0.8,1.5,1.2,2.5,1.2s1.9-0.4,2.6-1.2c0.7-0.8,1.1-1.8,1.1-2.9
	C126.7,300,126.4,299,125.7,298.3L125.7,298.3z M122.4,291.7v4.3 M123.1,290.9v5.1 M123.9,290.2v5.9 M124.6,289.5v6.7 M121.6,292.7
	v3.6 M120.8,293.9v2.8 M357.4,375.4l2.1,3.3 M359.5,378.7l-0.4-0.8l-1.6-2.5 M342.9,411.3l-5.6,6.5 M357.4,227.9l2.1-3.3
	 M357.4,227.9l1.6-2.3l0.4-1 M357.4,375.4c-1.9-3.2-3.9-6.9-5.6-10.9c-3-6.7-5.9-14.4-8.7-23.2c-3.7-13.3-5.5-26.5-5.2-39.6
	c-0.3-13.1,1.5-26.3,5.2-39.6c2.8-8.8,5.6-16.6,8.7-23.2c1.8-4,3.7-7.6,5.6-10.9 M337.3,185.5l5.6,6.5"
          />
          <path
            fill="none"
            d="M359.5,224.6c3.2-6.2,0.8-10.6-6.9-13.2l-32.6-8.3c-3.6-0.8-7-1-10.2-0.6c-6.1,1-11.3,4.5-15.5,10.5
	c-1.1,1.8-2.2,3.4-3.3,5.2c-0.8,1.4-1.7,2.8-2.3,4.1c-2.9,5.4-5.6,11.1-8,17.1c-1,2.5-1.8,5-2.8,7.4c-2.1,6.2-3.7,12.5-5.1,19.3
	c-0.7,3.4-1.4,6.9-1.9,10.3c-0.5,3.3-1,6.6-1.4,10l-0.4,3.7c-0.5,5.1-0.8,10.2-0.5,15.3c0.3,3.9,0.5,7.8,1,11.7
	c0.4,3.2,0.8,6.3,1.2,9.4c0.5,3.6,1.2,7,1.9,10.3c1.4,6.7,3.2,13.1,5.1,19.3c0.8,2.5,1.8,5,2.6,7.4c2.3,5.9,5,11.7,8,17.1l2.3,4.1
	c1.1,1.8,2.2,3.6,3.3,5.2c4.3,6.1,9.5,9.5,15.5,10.5c3.2,0.5,6.6,0.4,10.2-0.5l32.6-8.3c7.7-2.8,10-7.2,6.9-13.2 M120.8,308.4v-2.8
	 M448.9,169l-0.1-0.3c-0.3-1.5-2.2-2.1-5.5-1.8c-1.1-2.5-3.9-3.7-8.3-3.7h-4.3c-6.1,0.1-10.6,1.1-13.5,2.8l-4.3,1.9
	c-0.7,0.4-1,1-1,1.2 M473.6,208.6c-14.2-0.1-28.5-0.1-42.9,0c-15.4,0-31.1,0.3-47,0.4l-0.5-0.1l-0.1-0.1 M411.9,169.3
	c0,0.8,1.2,1.4,3.9,1.6h3.7 M420,169.3c-0.4,0.6-0.5,1.1-0.5,1.8 M350.1,146.3l-1-0.3c-4.8-0.8-9.2,0.4-13.3,3.9
	c-2.9,2.5-5.2,5.2-6.9,8.1c-1.4,2.3-2.3,4.8-2.9,7.4c-0.4,1.5-0.3,3,0.1,4.3 M344.9,169.8l7.3-20.1c0.7-1.6,0-2.8-2.1-3.4
	 M326.3,170v-0.3 M326.2,170h0.1 M326.3,169.7l-0.1,0.3 M326.2,170l-24.8,0.1 M323,174.2c-1.1,1.6-0.7,3.2,1.4,4.3
	c3.9,1.9,7.6,2.5,10.9,1.6c2.8-0.6,4.5-1.6,5.5-3.3 M461.4,181.4c-10,0-20.4,0-30.7,0.1c-34.7,0.4-70.6,1.5-107.9,3.2l-5.6,0.3
	 M317.1,185.1l4.8,1.1 M306.5,186.1l-6.6-5.6c-2.1-1.4-3-3.2-2.9-5.5c0-0.1-0.1-0.3-0.4-0.4l-15.8-6.1 M299.5,187.3l-1.5,0.3
	c-2.2,0.6-4.3,1-6.5,1.6 M303.9,193.2l-2.1-0.4c1.9-3,4.7-5.4,8.1-7 M309.9,185.7h-0.1l-3.3,0.4 M306.5,186.1l-7,1.2 M374,213.4
	c-22-8.3-43.5-14.6-64.1-19.1l-5.9-1.2"
          />
          <path
            fill="none"
            d="M303.9,410.2c-2.9-2.9-5.6-6.1-8.3-9.2c-4.1-5.1-7.8-10.3-11.3-16.1l-2.3-4.1c-2.9-5.4-5.6-11.1-8-17.1
	c-1-2.5-1.8-5-2.8-7.4c-2.1-6.2-3.7-12.5-5.1-19.3c-0.7-3.4-1.4-6.9-1.9-10.3c-0.5-3-1-6.2-1.2-9.4l-0.7-7.7c-0.3-2.6-0.3-5.2-0.3-8
	c0-5,0.4-10,0.8-15c0.4-3.4,0.8-6.7,1.4-10c0.5-3.6,1.2-7,1.9-10.3c1.4-6.7,3.2-13.1,5.1-19.3c0.8-2.5,1.8-4.9,2.8-7.4
	c2.3-5.9,5-11.7,8-17.1c0.8-1.4,1.5-2.8,2.3-4.1c3.3-5.6,7.2-11,11.3-16.1c2.6-3.2,5.4-6.3,8.3-9.2 M301.4,170.1
	c2.8,4.1,5.5,7.6,8.4,10c2.3,2.1,4.7,3.7,7.3,4.8 M309.9,185.7l7.2-0.6 M329.2,173.4c0.5,0.4,1.1,0.8,1.8,1.1l6.1,1.8
	c0.8,0.3,1.5,0.4,2.1,0.6 M339.2,176.9l1.5,0.1 M340.7,177c-1.4,1.5-3.6,2.3-6.5,2.5c-2.8,0-5.1-0.7-7.3-1.8 M329.2,173.4l-2.1,4.1
	 M340.7,177c1-0.1,1.6-0.6,1.9-1.2l2.2-5.9 M350.1,146.3l-10.9,30.5 M321.9,186.2l15.4-0.7c32.3-1.4,63.4-2.2,93.4-2.5l13.8-0.1
	 M326.3,170c0.5,1.4,1.5,2.5,2.9,3.4 M327.1,177.5c-1.5-0.8-2.9-1.9-4.1-3.3 M323,174.2l3.2-4.3 M321.9,186.2
	c7.3,1.8,14.2,3.7,20.9,5.8c14.9,4.4,28.5,9.1,41,14.3c15.8-0.3,31.4-0.4,46.8-0.4H461 M374,213.4c-2.2,1.8-4.4,3.9-6.5,6.5
	c-1.4,1.6-2.6,3.4-4,5.5c-2.5,3.9-4.8,8.3-7.2,13.2c-3,6.7-5.9,14.4-8.7,23.2c-3.7,13.3-5.5,26.5-5.2,39.6
	c-0.3,13.1,1.5,26.4,5.2,39.8c2.8,8.8,5.6,16.6,8.7,23.2c2.3,5.1,4.7,9.5,7.2,13.2c1.2,1.9,2.6,3.9,4,5.5c2.1,2.6,4.3,4.8,6.5,6.5
	 M611.3,230.9l-217.2-3.9c-0.8,0-1.6-0.3-2.2-1l-0.5-0.7c-0.3-0.4-0.4-1-0.4-1.5c0-0.8,0.3-1.6,1-2.2c0.5-0.6,1.4-1,2.2-1l211.3,3.9
	h11.8c0.8,0,1.7,0.3,2.2,1c0.6,0.6,1,1.4,1,2.2s-0.3,1.7-1,2.2c-0.5,0.6-1.4,1-2.2,1L611.3,230.9L611.3,230.9z M382.8,208.9
	c-3,0.8-6.1,2.5-8.8,4.7 M382.8,208.9c-21.6-8.9-45.8-16.6-73-23.1 M411.9,169.3l-67,0.6 M280.8,168.6h-0.4 M301.4,170.1h-6.5
	c-1.2-0.4-2.5-0.8-3.9-1.1 M280.8,168.6l10.3,0.4 M277.5,168.5h2.6 M261.6,164.9c-25-2.1-53.4-1.2-85.2,2.6
	c-10.3,1.8-19.3,5.9-26.7,12.4 M251.7,167.8l10.9,0.1 M262.6,167.9l17.7,6.3c1.6,0.6,3.2,1,4.7,1.2h6.1c3-0.3,4-0.7,2.6-1.4h-0.1
	l-16-5.6"
          />
          <path
            fill="none"
            d="M291.6,189.2l-2.6,0.8c-8.5,3.6-15.3,9.6-20.2,18.2C256,228.4,248,255,245,288.1c-0.3,2.8-0.4,5.5-0.4,8.3
	c-0.3,7,0,13.9,0.7,20.8c3.2,32.2,11,58.2,23.7,78c5,8.5,11.7,14.6,20.2,18.2l2.6,0.8 M291.8,189.4l-0.1-0.1"
          />
          <path
            fill="none"
            d="M291.8,189.4c-8.5,3.6-15.3,9.8-20.2,18.3c-12.8,20.4-20.8,47-23.8,80.3c-0.3,2.8-0.4,5.5-0.6,8.3
	c-0.3,7,0,13.9,0.7,20.8c3.2,32.5,11,58.6,23.7,78.5c5,8.5,11.7,14.7,20.2,18.3 M264,165.2l-2.3-0.3 M277.5,168.5l-14.9-0.6
	 M291.6,189.2c-10.2-9.9-23.5-17.1-39.9-21.5 M291.1,169c-8.9-2.5-18.8-3.9-29.4-4.1 M187.1,181c-1.6-2.5-5.6-3.7-12.1-3.6h-1.8
	c-8.3,0.4-15.8,4.7-22.6,12.8c-5.9,6.6-11,14.2-15,22.8c-1.4,3.3-1.2,6.1,0.6,8.4c0.6,0.4,1.4,0.7,2.5,0.8c1.5,0.1,3.4-0.1,5.8-0.8
	c6.6-1.8,14.4-6.6,23.5-14.4c7.2-5.4,12.9-11,17.3-16.9C187.8,186.2,188.3,183.2,187.1,181L187.1,181z"
          />
          <path
            fill="none"
            d="M169.6,180.3c-6.5,0.7-12.5,4.5-18.3,11.8c-5,5.8-9.1,12.2-12.4,19.3c-0.6,1.1-0.8,2.2-0.8,3.2
	c0,1.2,0.3,2.2,1.1,3.2c0.6,0.4,1.4,0.6,2.3,0.4c1-0.1,2.3-0.4,3.9-1c4.8-1.8,10.7-5.9,17.9-12.4c5.6-4.5,10.3-9.4,13.9-14.2
	c1.4-2.2,2.3-4,2.5-5.5c0.1-0.8,0-1.5-0.1-2.2c-0.8-2.1-3.6-2.9-8-2.8L169.6,180.3L169.6,180.3z M236.7,296.1l0.1,5.1l-0.3,6.5
	c-0.1,2.2-0.4,4.4-0.7,6.7c-1.2,8.9-5.6,13.9-13.3,14.9c-23.1-1.6-42.1-4-56.8-7c-9.9-3.2-14.7-9.9-14.6-19.9v-2.5
	c0.3-9.2,5.2-15.4,14.4-18.4c14.9-3,33.8-5.4,56.8-7c7.7,1.1,12.1,6.1,13.3,14.9C236.5,291.5,236.6,293.8,236.7,296.1L236.7,296.1z
	 M187.1,422.3c-1.6,2.5-5.6,3.7-12.1,3.6h-1.8c-8.3-0.4-15.8-4.7-22.6-12.8c-5.9-6.6-11-14.2-15-22.8c-1.4-3.3-1.2-6.1,0.6-8.4
	c0.6-0.4,1.4-0.7,2.5-0.8c1.5-0.1,3.4,0.1,5.8,0.8c6.6,1.8,14.4,6.6,23.5,14.4c7.2,5.4,12.9,11,17.3,16.9
	C187.8,417,188.3,420.1,187.1,422.3L187.1,422.3z"
          />
          <path
            fill="none"
            d="M179.5,420.3c-0.8,2.1-3.6,2.9-8,2.8l-1.9-0.1c-6.5-0.7-12.5-4.5-18.3-11.8c-5-5.8-9.1-12.2-12.4-19.3
	c-0.6-1.1-0.8-2.2-0.8-3.2c0-1.2,0.3-2.2,1.1-3.2c0.6-0.4,1.4-0.5,2.3-0.4c1,0.1,2.3,0.4,3.9,1c4.8,1.8,10.7,5.9,17.9,12.4
	c5.6,4.5,10.3,9.4,13.9,14.2c1.4,2.2,2.3,4,2.5,5.5C179.7,419,179.7,419.7,179.5,420.3L179.5,420.3z M149.8,423.5
	c7.4,6.5,16.2,10.6,26.7,12.4c31.8,3.9,60.3,4.7,85.2,2.6h0.1 M251.7,435.5l10.9-0.1 M291.8,413.9l-0.1,0.1 M262.6,435.3l17.7-6.3
	c1.6-0.5,3.2-1,4.7-1.2h6.1c3,0.3,4,0.7,2.6,1.4h-0.1l-16,5.6 M280.4,434.8h0.4 M291.1,434.2l3.9-1.1h6.5 M280.8,434.8l10.3-0.5
	 M277.5,434.9l2.6-0.1 M264,438.2l-2.3,0.1 M262.6,435.3l14.9-0.4 M291.6,414c-10.2,9.9-23.5,17.1-39.9,21.5 M261.6,438.4
	c10.6-0.3,20.4-1.7,29.4-4.1 M317.1,418.3l4.8-1.2 M317.1,418.3l5.6,0.3c37.3,1.8,73.3,2.9,107.9,3.2c10.3,0.1,20.6,0.1,30.7,0.1
	 M374,389.8c-22,8.3-43.5,14.6-64.1,19.1l-5.9,1.2 M291.6,414c2.2,0.5,4.3,1.1,6.5,1.6l1.5,0.3 M303.9,410.2l-2.1,0.4
	c1.9,3,4.7,5.4,8.1,7 M309.9,417.6h-0.1l-3.3-0.4 M299.5,415.9l7,1.2"
          />
          <path
            fill="none"
            d="M306.5,417.2l-6.6,5.6c-2.1,1.4-3,3.2-2.9,5.5c0,0.1-0.1,0.3-0.4,0.4l-15.8,6.1 M301.4,433.1
	c2.8-4.1,5.5-7.6,8.4-10c2.3-2.1,4.7-3.7,7.3-4.8 M340.7,426.4c-1-1.6-2.9-2.8-5.5-3.3c-3.3-0.7-7-0.1-10.9,1.6
	c-2.1,1.2-2.5,2.6-1.4,4.3 M326.2,433.3h0.1 M326.3,433.3v0.3 M326.2,433.3l0.1,0.3 M326.3,433.6c-0.4,1.2-0.5,2.8-0.1,4.3
	c0.5,2.6,1.5,5.1,2.9,7.4c1.6,2.9,4,5.5,6.9,8.1c4.1,3.4,8.5,4.8,13.3,3.9l1-0.3 M323,429l3.2,4.3 M309.9,417.6l7.2,0.7
	 M301.4,433.1l24.8,0.1"
          />
          <path
            fill="none"
            d="M339.2,426.4c-0.7,0.1-1.4,0.3-2.1,0.5l-6.1,1.8c-0.7,0.3-1.2,0.7-1.8,1.1 M339.2,426.4h1.5 M329.2,429.8
	l-2.1-4.1 M340.7,426.4c-1.4-1.5-3.6-2.3-6.5-2.5c-2.8,0-5.1,0.7-7.3,1.8 M344.9,433.4l-2.2-5.9c-0.3-0.8-1-1.2-1.9-1.2
	 M329.2,429.8c-1.4,1-2.3,2.2-2.9,3.4 M321.9,417c7.3-1.8,14.2-3.7,20.9-5.8c14.9-4.4,28.5-9.1,41-14.3c15.8,0.3,31.4,0.4,46.8,0.4
	H461 M327.1,425.7c-1.5,0.8-2.9,1.9-4.1,3.3 M321.9,417l15.4,0.7c32.3,1.4,63.4,2.2,93.4,2.5l13.8,0.1 M611.3,372.5l-217.2,3.9
	c-0.8,0-1.6,0.3-2.2,1l-0.5,0.7c-0.3,0.4-0.4,1-0.4,1.5c0,0.8,0.3,1.7,1,2.2c0.5,0.6,1.4,1,2.2,1l211.3-3.9h11.8
	c0.8,0,1.7-0.3,2.2-1c0.6-0.6,1-1.4,1-2.2c0-0.8-0.3-1.6-1-2.2c-0.5-0.5-1.4-1-2.2-1L611.3,372.5L611.3,372.5z M473.6,394.6
	c-14.2,0.1-28.5,0.1-42.9,0c-15.4,0-31.1-0.3-47-0.4l-0.5,0.1l-0.1,0.1 M382.8,394.5c-3-0.8-6.1-2.5-8.8-4.7 M411.9,434
	c0-0.8,1.2-1.4,3.9-1.6h3.7 M411.9,434c0,0.4,0.3,0.8,1,1.2l4.3,1.9c2.9,1.6,7.4,2.6,13.5,2.8h4.3c4.4,0,7.2-1.2,8.3-3.7
	c3.3,0.3,5.1-0.3,5.5-1.8l0.1-0.3 M419.4,432.3c0,0.7,0.1,1.2,0.5,1.8 M382.8,394.5c-21.6,8.9-45.8,16.6-73,23.1 M344.9,433.4
	l67,0.5 M299.5,415.9c-2.6-0.5-5.2-1.4-7.7-2.1 M299.5,187.3c-2.6,0.6-5.2,1.2-7.7,2.1 M149.8,179.7c-6.2,5-11.7,10-16.5,15.3
	c-4.1,4.4-7.8,8.9-11,13.6l-2.3,3.4c-1.1,1.8-2.2,3.6-3.2,5.5c-1,1.8-1.9,3.6-2.8,5.5c-1.1,2.3-1.9,4.8-2.8,7.4
	c-0.8,2.5-1.4,4.9-1.9,7.4l-0.6,2.5c-4,19.3-5.9,39.3-5.8,60.4v0.7c-0.1,13.1,0.6,25.7,1.9,38.1c1,7.8,2.2,15.5,3.7,23.2l0.6,2.5
	c0.6,2.5,1.2,5,1.9,7.4c0.8,2.5,1.7,5,2.8,7.4c0.8,1.8,1.8,3.7,2.8,5.5c1,1.6,1.9,3.4,2.9,5.1l3,4.5c3,4.4,6.6,8.7,10.5,12.9
	c4.8,5.2,10.3,10.3,16.5,15.3"
          />
          <path
            fill="none"
            d="M162.9,176.7c-7.6,7-12.1,11.6-13.8,13.5c-5.8,6.5-10.6,13.8-14.6,22c-2.3,4.4-4.8,7.2-7.6,8.4
	c-1.8,5.4-3.3,11.3-4.7,17.5l-0.6,2.3c-1.2,6.1-2.3,12.4-3.3,19.3c-2.2,15.5-3,31.1-2.8,46.8c0.3,15.4,1.7,30.8,4.1,46.1
	c1.9,11.1,4.3,21.2,7.2,30.1c2.8,1.2,5.4,4.1,7.6,8.4c4,8.3,8.8,15.5,14.6,22c1.6,1.9,6.2,6.5,13.8,13.5"
          />
          <path
            fill="none"
            d="M125.5,296.7v-8l-0.8,0.7l-0.8,0.7l-0.7,0.7l-0.7,0.8l-0.8,1l-0.8,1.2L120,295v2.2 M125.5,296.7l-0.8-0.4
	l-0.8-0.3h-1.4l-0.8,0.1l-0.8,0.4c-0.3,0.1-0.6,0.4-0.8,0.7 M125.5,305.5l0.8-0.8c1-1,1.4-2.2,1.4-3.6c0-1.4-0.4-2.6-1.4-3.6
	l-0.8-0.8"
          />
          <path
            fill="none"
            d="M120,304.9l0.8,0.7l0.8,0.4l0.8,0.1h0.7l0.7-0.1l0.8-0.3c0.3-0.1,0.6-0.3,0.8-0.4"
          />
          <path
            fill="none"
            d="M120,297.4l-0.1,0.1c-1,1-1.4,2.2-1.4,3.6c0,1.4,0.4,2.6,1.4,3.6l0.1,0.1 M120,304.9v2.2l0.8,1.4l0.8,1.2l0.8,1
	l0.7,0.8l0.7,0.7l0.8,0.7l0.8,0.7v-8 M350.1,456.9c2.1-0.7,2.8-1.8,2.1-3.4l-7.3-20.1 M251.7,167.8c-43.6-0.3-77.6,3.7-101.9,12
	 M258.9,209.1c-54.3,11.6-95.6,18.7-123.9,21.2 M258.9,394.2c-54.3-11.6-95.6-18.7-123.9-21.2 M350.1,456.9l-10.9-30.5 M251.7,435.5
	c-43.6,0.3-77.6-3.7-101.9-12 M692.2,181.3l-0.3,0.1l-2.9,2.1c-1,0.7-1.4,2.1-1.1,4s1.1,3.9,2.5,5.9l4,5.9c1.4,2.1,2.9,3.6,4.7,4.5
	c1.7,1,3,1.1,4,0.4l2.6-1.8"
          />
          <path
            fill="none"
            d="M705.8,202.3l0.3-0.3c1-0.7,1.4-2.1,1.1-3.9c-0.3-1.9-1.1-3.9-2.5-5.9l-4-5.9c-1.4-2.1-2.9-3.6-4.7-4.4
	c-1.7-0.8-2.9-1.1-3.8-0.6"
          />
          <path
            fill="none"
            d="M689.9,179.1c1.2-0.7,2.8-0.6,4.7,0.6c2.1,1.1,3.9,2.9,5.6,5.4l4.8,7.2c1.7,2.5,2.6,4.8,3,7.2
	c0.3,2.3-0.1,3.9-1.4,4.7l-0.1,0.1"
          />
          <path
            fill="none"
            d="M706.5,204.1l-3.4,2.3c-1.2,0.8-2.9,0.7-4.8-0.4c-2.1-1.1-3.9-2.9-5.6-5.4l-4.8-7.2c-1.7-2.5-2.6-4.8-3-7.2
	c-0.3-2.3,0.1-3.9,1.4-4.7l3.7-2.5 M689.9,179.1c-3.2-2.8-6.5-4.8-10-6.3 M706.5,204.1l-0.7-1.8 M705.8,202.3
	c-3.9-8.8-8.4-15.7-13.6-20.9 M679.8,172.7c7.2-0.4,15.1,1.6,23.9,6.1 M692.2,181.3l-2.3-2.2 M724.1,205.3l-0.1-0.1
	c-3.6-7.8-8-14-12.9-18.8c-1.9-2.8-4.3-5.2-7.3-7.4 M709.3,392c1.2-3.4,2.3-7.2,3.3-11c5.1-20.1,8.1-46.5,8.8-79.2
	c-0.8-32.7-3.7-59.2-8.8-79.2c-1-3.9-2.1-7.6-3.3-11 M688.4,217.7l3.2-1 M691.5,216.8c3,4.7,5.2,9.9,6.6,15.8
	c3.3,12.8,5.6,27,6.7,42.5l0.4,7l0.5,20.1l-0.5,19.5l-0.4,6.7c-1.1,15.5-3.3,29.7-6.7,42.5c-1.4,5.8-3.6,11.1-6.6,15.8 M656.6,227.5
	c6.9,0.8,12.1,5.1,15.7,12.8c0.7,1.5,1.4,3,1.9,4.8c3.8,9.4,6.5,20.5,7.8,33.4l0.3,2.8l0.8,20.4l-0.8,20.4l-0.3,2.6
	c-1.4,12.9-4,24.1-7.8,33.4c-0.5,1.8-1.2,3.3-1.9,4.8c-3.6,7.7-8.9,12-15.7,12.8 M691.5,216.8l17.7-5.4 M734.6,361.1
	c1.4-8.8,2.5-18.6,3.2-29.2c1-8.8,1.4-18.8,1.2-30.1c0.1-11.4-0.3-21.6-1.2-30.4c-0.7-10.6-1.7-20.2-3.2-29.2 M734.6,242.3
	c-2.5-14.7-5.9-27.1-10.6-37 M709.3,211.4c-0.8-2.6-1.8-5-2.8-7.3 M734.6,242.3c1.7-11.6-1.9-23.9-10.6-37 M703.8,424.5
	c2.9-2.2,5.4-4.7,7.3-7.4c5-4.8,9.4-11.1,12.9-18.8l0.1-0.1 M691.5,386.5l-3.2-1 M706.5,399.3l-3.4-2.3c-1.2-0.8-2.9-0.7-4.8,0.4
	c-2.1,1.1-3.9,2.9-5.6,5.4l-4.8,7c-1.7,2.5-2.6,4.8-3,7.2c-0.3,2.3,0.1,3.9,1.4,4.7l3.7,2.5"
          />
          <path
            fill="none"
            d="M705.8,401l-2.6-1.8c-1-0.7-2.3-0.5-4,0.4s-3.3,2.5-4.7,4.5l-4,5.9c-1.4,2.1-2.2,4-2.5,5.9s0.1,3.3,1.1,4
	l2.9,2.1l0.3,0.1"
          />
          <path
            fill="none"
            d="M692.2,422c1,0.5,2.2,0.4,3.9-0.5s3.3-2.5,4.7-4.4l4-5.9c1.4-2.1,2.2-4,2.5-5.9s-0.1-3.2-1.1-3.9l-0.3-0.3
	 M705.8,401l0.7-1.6"
          />
          <path
            fill="none"
            d="M689.9,424.2c1.2,0.7,2.8,0.5,4.7-0.6c2.1-1.1,3.9-2.9,5.6-5.4l4.8-7.2c1.7-2.5,2.6-4.8,3-7.2
	c0.3-2.3-0.1-3.9-1.4-4.7l-0.1-0.1 M679.8,430.5c3.6-1.5,6.9-3.6,10-6.3 M689.9,424.2l2.3-2.2 M703.8,424.5
	c-8.8,4.5-16.8,6.5-23.9,6.1 M691.5,386.5l17.7,5.5 M692.2,422c5.2-5.2,9.8-12.2,13.6-20.9 M706.5,399.3c1-2.3,1.9-4.7,2.8-7.3
	 M734.6,361.1c-2.5,14.7-5.9,27.1-10.6,37 M724.1,398.1c8.7-13.1,12.2-25.5,10.6-37 M669.5,201.3l-2.3,2.2l-4.8-2.3l-15.3,9.1v5.1
	l-3.4,2.1 M669.5,201.3l2.3-2.1 M598.7,185.9c21.2,0.7,38.7,2.8,52.7,5.9c7.7,1.8,14.6,4.3,20.5,7.4 M594.8,164.5
	c-1.8-1-4.3-1.5-7.6-1.9l-3.4-0.1c-8.4,0.1-14.2,1.2-17.7,3.4l-3,1.5 M596.9,166l0.3,0.4c0.4,0.3,0.7,0.6,0.7,1.1
	c0.7,0.8-0.4,1.7-3.3,2.1l-5.5,0.3 M589.2,169.7c-0.4-2.3-2.2-3.3-5.6-3.2l-8.8,0.4c-4.1,0.3-6.2,1.5-5.9,3.7 M605.6,163.9L605,166
	 M605,166h-8.1 M594.8,164.5c0.8,0.4,1.5,1,1.9,1.5 M605.6,163.9l-10.7,0.6 M568.8,170.7l-3.7,0.1c-3.8-0.4-5-1.2-3-2.9l1.1-0.6
	 M483,205.9c32.9,0.3,64.5,0.8,94.9,1.8h1.8c3,0,5.1-1,6.2-2.6l0.3-0.6v0.1c1-2.6,2.2-5.4,3.4-8.1l-4.4-10 M597,185.8l-0.4-0.1H595
	 M597,185.8l1.7,0.1 M587.7,207.5l0.7-1.1l0.3-0.7v-0.1c1.9-5.4,4.7-11,8.3-16.9l1.8-2.9 M585.2,186.6l-0.7-1.6 M584.5,185l10.5,0.7
	 M595,185.7l2.1,0.1 M605,166l-6.3,19.9 M568.8,170.7c5.9,1.8,12.7,1.4,20.4-1 M611.3,230.9h-5.8"
          />
          <path
            fill="none"
            d="M473.6,208.6c36.2,0.1,70.8,0.8,104.3,1.9h1.8c3.7,0,6.5-1.1,8-3 M656.6,227.5c-9.4,2.6-14.3,7.4-14.9,14.4
	c-1.4,9.4-2.3,19.1-3,29.2l-0.6,11.3c-0.3,6.3-0.4,12.7-0.4,19c0,6.5,0.1,12.8,0.4,19.1l0.5,11.6c0.7,10,1.7,19.8,3,29.2
	c0.5,7,5.5,11.8,14.9,14.4 M643.7,217.4c5.6,2.8,9.9,6.1,12.8,10 M679.8,172.7c-4.3-1.8-8.9-2.9-13.9-3c-18.4-2.6-38.8-3.9-60.9-3.9
	 M587.7,207.5c25.2,0.6,43.9,3.9,56.1,9.9"
          />
          <path
            fill="none"
            d="M703.8,178.9c-10.9-8.3-28.8-13.1-53.5-14.6c-14.3-0.8-29.2-1-44.7-0.4 M439.2,169.2c-0.8-1.1-2.5-1.6-4.8-1.6
	h-8.8c-2.9,0.1-4.8,0.7-5.6,1.8 M439.9,170.8h5.5c2.6-0.4,3.9-1,3.4-1.8 M419.4,170.9c3.4,1.1,7.2,1.6,11.1,1.4
	c2.9-0.1,5.9-0.7,9.2-1.6 M461.4,181.4c0.1,0.7,0.1,1.2-0.1,1.5 M461.2,182.9c3.9,5.2,7.7,12.7,11.3,22l0.3,1 M472.8,205.9l0.8,2.8
	 M472.8,205.9H483 M461.2,182.9h14.3 M483,205.9c-1.8-9.8-4.3-17.3-7.4-23 M461,205.9h11.8 M461,205.9c-4.7-9.2-10.2-16.8-16.6-22.8
	 M461.2,182.9l-16.9,0.1 M453.8,169c4.5,5.9,7.2,10,7.6,12.4 M439.9,170.8c-0.1-0.7-0.3-1.2-0.7-1.6 M453.8,169h-5 M563.2,167.5
	l-19.5,0.8l-89.8,0.7 M472.8,397.4l-0.3,1c-3.6,9.4-7.4,16.8-11.3,22 M472.8,397.4l0.8-2.8 M483,397.4h-10.2 M461,397.5l11.8-0.1
	 M420,434.1c0.8,1.1,2.8,1.6,5.6,1.8h8.8c2.5,0,4-0.6,4.8-1.6 M439.9,432.5h5.5c2.6,0.4,3.9,1,3.4,1.8 M439.2,434.2
	c0.3-0.4,0.5-1.1,0.7-1.7 M419.4,432.3c3.4-1.1,7.2-1.7,11.1-1.4c2.9,0.1,5.9,0.7,9.2,1.6 M461.2,420.3c0.1,0.4,0.3,1,0.1,1.5
	 M475.6,420.3h-14.3 M461.4,421.9c-0.4,2.3-2.9,6.5-7.6,12.4 M483,397.4c-1.8,9.8-4.3,17.3-7.4,23 M461.2,420.3h-16.9 M461,397.5
	c-4.7,9.2-10.2,16.8-16.6,22.8 M448.9,434.2h5 M453.8,434.2l89.8,0.7l19.5,1 M613.4,300.4c-0.3,0.3-0.4,0.5-0.4,1
	c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4s0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-1-0.4S613.7,300.1,613.4,300.4
	L613.4,300.4z M614.5,297.4c-1.4-1.1-3.2-1.6-5.1-1.6h-0.3c-3,0-5.5,0.5-7.7,1.6c-2.2,1.1-3.3,2.3-3.3,3.9s1.1,2.8,3.3,3.9
	c2.2,1.1,4.7,1.7,7.7,1.7h0.3c1.9,0,3.7-0.5,5.1-1.7c1.4-1.1,2.1-2.3,2.1-3.9S615.9,298.3,614.5,297.4L614.5,297.4z M611.3,372.5
	h-5.8 M587.7,395.7l0.7,1.1l0.3,0.7v0.1c1.9,5.4,4.7,11,8.3,16.9l1.8,2.9"
          />
          <path
            fill="none"
            d="M585.2,416.8l4.4-10c-1.4-2.8-2.6-5.5-3.4-8.1v0.1l-0.3-0.5c-1.1-1.6-3.2-2.6-6.2-2.6h-1.8
	c-30.4,1-62,1.6-94.9,1.8"
          />
          <path
            fill="none"
            d="M587.7,395.7c-1.7-1.9-4.3-3-8-3h-1.8c-33.3,1.1-68.1,1.6-104.3,1.9 M568.8,432.6l-3.7-0.1
	c-3.8,0.4-5,1.2-3,2.9l1.1,0.5"
          />
          <path
            fill="none"
            d="M589.2,433.6c-0.4,2.3-2.2,3.3-5.6,3.2l-8.8-0.4c-4.1-0.3-6.2-1.5-5.9-3.7 M563.2,435.9l3,1.5
	c3.4,2.2,9.4,3.3,17.7,3.4l3.4-0.1c3.3-0.3,5.8-1,7.6-1.9 M595,417.7l1.7-0.1l0.4-0.1 M598.7,417.5H597 M584.5,418.3l0.7-1.5
	 M595,417.7l-10.5,0.5 M596.9,437.3l0.3-0.4c0.4-0.3,0.7-0.5,0.7-1.1c0.7-0.8-0.4-1.6-3.3-2.1l-5.5-0.3 M605.6,439.3l-0.5-1.9
	 M605,437.4l-8.1-0.1 M594.8,438.8c0.8-0.4,1.5-1,1.9-1.5 M597,417.5l-2.1,0.3 M605,437.4l-6.3-19.9 M594.8,438.8l10.7,0.6
	 M589.2,433.6c-7.7-2.5-14.6-2.8-20.4-1 M669.5,401.9l-2.3-2.2l-4.8,2.3l-15.3-8.9V388l-3.4-2.2 M598.7,417.5
	c21.2-0.7,38.7-2.6,52.7-5.9c7.7-1.8,14.6-4.3,20.5-7.4 M671.9,404l-2.3-2.1 M656.6,375.8c-2.9,3.9-7.2,7.3-12.8,10 M679.8,430.5
	c-4.3,1.8-8.9,2.9-13.9,3c-18.4,2.6-38.8,3.9-60.9,3.9 M643.7,385.8c-12.2,6.1-31,9.2-56.1,9.9"
          />
          <path
            fill="none"
            d="M605.6,439.3c15.5,0.7,30.4,0.5,44.7-0.4c24.9-1.5,42.6-6.3,53.5-14.6 M461.4,421.9c43.3,0,84.3-1.2,123.3-3.6
	 M585.2,416.8c-34.8,2.1-71.3,3.3-109.6,3.6 M584.5,185c-38.9-2.3-79.9-3.6-123.3-3.6 M475.6,183.1c38.2,0.3,74.8,1.5,109.6,3.6
	 M671.9,199.3c8.4,4.5,15,10.3,19.7,17.5 M688.4,385.5c-4.7,7.7-11,13.2-18.8,16.4 M688.4,217.7c-4.7-7.7-11-13.2-18.8-16.4
	 M671.9,404c8.4-4.5,15-10.3,19.7-17.5 M656.6,227.5l31.8-9.8 M688.4,385.5l-31.8-9.8 M420,169.3l19.3-0.1 M420,434.1l19.3,0.1
	 M412.8,168.1c-0.7,0.4-1,1-1,1.2c0,0.8,1.2,1.4,3.9,1.6h3.7c0-0.7,0.1-1.2,0.5-1.8c0.8-1.1,2.8-1.6,5.6-1.8h8.8
	c2.5,0,4,0.6,4.8,1.7c0.3,0.4,0.5,1.1,0.7,1.6h5.5c2.6-0.4,3.9-1,3.4-1.8l-0.1-0.3c-0.3-1.5-2.2-2.1-5.5-1.8
	c-1.1-2.5-3.9-3.7-8.3-3.7h-4.3c-6.1,0.1-10.6,1.1-13.5,2.8L412.8,168.1L412.8,168.1z M383,208.9l0.5,0.1c16-0.3,31.6-0.4,47-0.4
	h42.9l-0.8-2.8h-42.1c-15.4,0-31,0.3-46.8,0.4c-12.5-5.2-26.1-9.9-41-14.3c-6.7-1.9-13.8-3.9-20.9-5.8l-4.8-1.2l-7.2,0.7
	c27.1,6.5,51.5,14.2,73,23.1L383,208.9L383,208.9z"
          />
          <path
            fill="none"
            d="M383.5,209.1l-0.5-0.1l-0.1-0.1c-3,0.8-6.1,2.5-8.8,4.7c-2.2,1.8-4.4,3.9-6.5,6.5c-1.4,1.6-2.6,3.4-4,5.5
	c-2.5,3.7-4.8,8.3-7.2,13.2c-3,6.7-5.9,14.4-8.7,23.2c-3.7,13.3-5.5,26.5-5.2,39.6c-0.3,13.1,1.5,26.4,5.2,39.8
	c2.8,8.8,5.6,16.6,8.7,23.2c2.3,5.1,4.7,9.5,7.2,13.2c1.2,2.1,2.6,3.9,4,5.5c2.1,2.6,4.3,4.8,6.5,6.5c2.9,2.2,5.8,3.9,8.8,4.7
	l0.1-0.1l0.5-0.1c16,0.3,31.6,0.4,47,0.4h42.9c36.2-0.1,70.8-0.8,104.3-1.9h1.8c3.7,0,6.5,1.1,8,3c25.2-0.5,43.9-3.9,56.1-9.9
	c5.6-2.8,9.9-6.1,12.8-10c-9.4-2.6-14.3-7.4-14.9-14.4c-1.4-9.4-2.3-19.1-3-29.2l-0.5-11.6c-0.3-6.3-0.4-12.8-0.4-19.1
	c0-6.3,0.1-12.7,0.4-19l0.5-11.3c0.7-10,1.7-19.8,3-29.2c0.5-7,5.5-11.8,14.9-14.4c-2.9-3.9-7.2-7.3-12.8-10
	c-12.2-6.1-31-9.2-56.1-9.9c-1.7,1.9-4.3,3-8,3h-1.8c-33.3-1.1-68.1-1.6-104.3-1.9c-14.2-0.1-28.5-0.1-42.9,0
	C415.2,208.6,399.5,208.8,383.5,209.1L383.5,209.1z M391,223.9c0-0.8,0.3-1.6,1-2.2c0.5-0.6,1.4-1,2.2-1l211.3,3.9h11.8
	c0.8,0,1.7,0.3,2.2,1c0.6,0.6,1,1.4,1,2.2s-0.3,1.7-1,2.2c-0.5,0.6-1.4,1-2.2,1h-11.8h5.9l-217.2-3.9c-0.8,0-1.6-0.3-2.2-1l-0.5-0.7
	C391.1,225,391,224.5,391,223.9L391,223.9z M391.9,377.2c0.5-0.5,1.4-1,2.2-1l217.2-3.9h-5.9h11.8c0.8,0,1.7,0.3,2.2,1
	c0.6,0.5,1,1.4,1,2.2s-0.3,1.6-1,2.2c-0.5,0.5-1.4,1-2.2,1h-11.8l-211.3,3.9c-0.8,0-1.6-0.3-2.2-1c-0.5-0.5-1-1.4-1-2.2
	c0-0.6,0.1-1.1,0.4-1.5L391.9,377.2L391.9,377.2z M614.5,297.4c1.4,1.1,2.1,2.3,2.1,3.9s-0.7,2.8-2.1,3.9c-1.4,1.1-3.2,1.7-5.1,1.7
	h-0.3c-3,0-5.5-0.5-7.7-1.7c-2.2-1.1-3.3-2.3-3.3-3.9s1.1-2.9,3.3-3.9c2.2-1.1,4.7-1.5,7.7-1.7h0.3
	C611.3,295.7,613,296.3,614.5,297.4L614.5,297.4z"
          />
          <path
            fill="none"
            d="M391.9,221.7c-0.5,0.6-1,1.4-1,2.2c0,0.6,0.1,1.1,0.4,1.5l0.5,0.7c0.5,0.6,1.4,1,2.2,1l217.2,3.9h5.9
	c0.8,0,1.7-0.3,2.2-1c0.6-0.6,1-1.4,1-2.2s-0.3-1.6-1-2.2s-1.4-1-2.2-1h-11.8l-211.3-3.9C393.3,220.7,392.5,221.2,391.9,221.7
	L391.9,221.7z M394.1,376.2c-0.8,0-1.6,0.3-2.2,1l-0.5,0.7c-0.3,0.4-0.4,1-0.4,1.5c0,0.8,0.3,1.6,1,2.2c0.5,0.6,1.4,1,2.2,1
	l211.3-3.9h11.8c0.8,0,1.7-0.3,2.2-1c0.6-0.5,1-1.4,1-2.2c0-0.8-0.3-1.6-1-2.2c-0.5-0.5-1.4-1-2.2-1h-5.9L394.1,376.2L394.1,376.2z
	 M616.6,301.2c0-1.5-0.7-2.9-2.1-3.9c-1.4-1.1-3.2-1.7-5.1-1.7h-0.3c-3,0-5.5,0.5-7.7,1.6c-2.2,1.1-3.3,2.3-3.3,3.9s1.1,2.8,3.3,3.9
	c2.2,1.1,4.7,1.7,7.7,1.7h0.3c1.9,0,3.7-0.5,5.1-1.7C615.9,304,616.6,302.7,616.6,301.2L616.6,301.2z M613.4,300.4
	c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4s0.4,0.5,0.4,1c0,0.4-0.1,0.7-0.4,1s-0.5,0.4-1,0.4s-0.7-0.1-1-0.4s-0.4-0.5-0.4-1
	C613,300.9,613.1,300.7,613.4,300.4L613.4,300.4z"
          />
          <path
            fill="none"
            d="M614.4,300c-0.4,0-0.7,0.1-1,0.4s-0.4,0.5-0.4,1c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4
	s0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-1S614.8,300,614.4,300L614.4,300z M419.4,170.9h-3.7c-2.6-0.3-4-1-3.9-1.6l-67,0.6l-2.2,5.9
	c-0.3,0.8-1,1.2-1.9,1.2c-1,1.6-2.9,2.8-5.5,3.3c-3.3,0.7-7,0.1-10.9-1.6c-2.1-1.2-2.5-2.6-1.4-4.3l3.2-4.4l-24.8,0.1
	c2.8,4.1,5.5,7.4,8.4,10c2.3,2.1,4.7,3.7,7.3,4.8l5.6-0.3c37.3-1.8,73.3-2.9,107.9-3.2c10.3-0.1,20.6-0.1,30.7-0.1
	c-0.4-2.3-2.9-6.5-7.6-12.4h-5c0.4,0.8-0.8,1.4-3.4,1.8h-5.5c-3.3,0.8-6.3,1.4-9.2,1.6C426.6,172.6,422.9,172.2,419.4,170.9
	L419.4,170.9z"
          />
          <path
            fill="none"
            d="M420,169.3c-0.4,0.6-0.5,1.1-0.5,1.8c3.4,1.1,7.2,1.7,11.1,1.4c2.9-0.1,5.9-0.7,9.2-1.6
	c-0.1-0.7-0.3-1.2-0.7-1.6L420,169.3L420,169.3z M350.1,146.3l-1-0.3c-4.8-0.8-9.2,0.4-13.3,3.9c-2.9,2.5-5.2,5.2-6.9,8.1
	c-1.4,2.3-2.3,4.8-2.9,7.4c-0.4,1.5-0.3,3,0.1,4.3v0.1c0.5,1.4,1.5,2.5,2.9,3.4c0.5,0.4,1.1,0.8,1.8,1.1l6.1,1.8
	c0.8,0.3,1.5,0.4,2.1,0.6L350.1,146.3z M352.2,149.8c0.7-1.7,0-2.8-2.1-3.4l-11,30.5l1.6,0.1c1-0.1,1.7-0.6,1.9-1.2l2.2-5.9
	L352.2,149.8L352.2,149.8z"
          />
          <path fill="none" d="M326.3,170v-0.3l-0.1,0.3" />
          <path
            fill="none"
            d="M326.3,170h-0.1l-3.2,4.4c1.2,1.4,2.6,2.5,4.1,3.3l2.1-4.1C327.8,172.5,326.9,171.2,326.3,170L326.3,170z"
          />
          <path
            fill="none"
            d="M323,174.2c-1.1,1.6-0.7,3.2,1.4,4.3c3.9,1.9,7.6,2.5,10.9,1.6c2.8-0.6,4.5-1.6,5.5-3.3
	c-1.4,1.5-3.6,2.3-6.5,2.5c-2.8,0-5.1-0.7-7.3-1.8C325.6,176.7,324.2,175.6,323,174.2L323,174.2z M322.7,184.7l-5.6,0.3l4.8,1.2
	l15.4-0.7c32.3-1.4,63.4-2.2,93.4-2.5l13.8-0.1h16.8c0.1-0.4,0.3-1,0.1-1.5c-10,0-20.4,0-30.7,0.1C396,181.9,360.1,183,322.7,184.7
	L322.7,184.7z M296.7,174.5c0.3,0.1,0.4,0.3,0.4,0.4c-0.1,2.3,0.8,4.1,2.9,5.5l6.6,5.6l3.3-0.4l7.2-0.7c-2.5-1.1-5-2.8-7.3-4.8
	c-2.9-2.6-5.8-5.9-8.4-10h-6.5c-1.2-0.4-2.5-0.8-3.9-1.1l-10.3-0.6L296.7,174.5L296.7,174.5z"
          />
          <path
            fill="none"
            d="M297,175.1c0-0.1-0.1-0.3-0.4-0.4l-15.8-6.1h-0.4h0.4l10.3,0.6c-8.9-2.5-18.8-3.9-29.4-4.1l2.2,0.1l-2.2-0.1
	h-0.1c-24.9-2.1-53.2-1.2-85.1,2.6c-10.3,1.8-19.3,5.9-26.7,12.4c24.4-8.3,58.3-12.2,101.9-12l10.9,0.1l14.9,0.4l2.6,0.1l-2.6-0.1
	l16,5.6h0.1c1.4,0.6,0.5,1.1-2.6,1.4h-6.1c-1.5-0.3-3-0.6-4.7-1.2l-17.7-6.3l-10.9-0.1c16.4,4.4,29.6,11.6,39.9,21.5
	c2.2-0.6,4.3-1.1,6.5-1.6l1.5-0.3l7-1.2l-6.6-5.6C298,179.2,297,177.4,297,175.1L297,175.1z"
          />
          <path
            fill="none"
            d="M299.5,187.3l-1.5,0.3c-2.2,0.6-4.3,1-6.5,1.6l0.1,0.1C294.4,188.7,297,188,299.5,187.3L299.5,187.3z
	 M309.9,185.7c-3.4,1.6-6.2,4-8.1,7l2.1,0.4l5.9,1.2c20.8,4.5,42.1,10.9,64.1,19.1c2.9-2.2,5.8-3.9,8.8-4.7
	C361.4,200,337,192.3,309.9,185.7L309.9,185.7z"
          />
          <path
            fill="none"
            d="M309.8,185.7l-3.3,0.4l-7,1.2c-2.6,0.6-5.2,1.2-7.7,2.1c-8.5,3.6-15.3,9.8-20.2,18.3
	c-12.8,20.4-20.8,47-23.8,80.3c-0.3,2.8-0.4,5.5-0.6,8.3c-0.3,7,0,13.9,0.7,20.8c3.2,32.5,11,58.6,23.7,78.5
	c5,8.5,11.7,14.7,20.2,18.3c2.6,0.8,5.1,1.5,7.7,2.1l7,1.2l3.3,0.4c-3.4-1.6-6.1-4-8.1-7l2.1-0.4c-2.9-2.9-5.6-6.1-8.3-9.2
	c-4.1-5.1-7.8-10.3-11.3-16.1l-2.3-4.1c-2.9-5.4-5.6-11.1-8-17.1c-1-2.5-1.8-5-2.6-7.4c-2.1-6.2-3.7-12.5-5.1-19.3
	c-0.7-3.4-1.4-6.9-1.9-10.3c-0.5-3-1-6.2-1.2-9.4l-0.7-7.7c-0.3-2.6-0.3-5.2-0.3-8c0-5,0.4-10,0.8-15c0.4-3.4,0.8-6.7,1.4-10
	c0.5-3.6,1.2-7,1.9-10.3c1.4-6.7,3.2-13.1,5.1-19.3c0.8-2.5,1.8-4.9,2.6-7.4c2.3-5.9,5-11.7,8-17.1c0.8-1.4,1.5-2.8,2.3-4.1
	c3.3-5.6,7.2-11,11.3-16.1c2.6-3.2,5.4-6.3,8.3-9.2l-2.1-0.4C303.7,189.7,306.5,187.3,309.8,185.7L309.8,185.7z"
          />
          <path
            fill="none"
            d="M309.8,194.5l-5.9-1.2c-2.9,2.9-5.6,6.1-8.3,9.2c-4.1,5.1-7.8,10.3-11.3,16.1c-0.8,1.4-1.6,2.8-2.3,4.1
	c-2.9,5.4-5.6,11.1-8,17.1c-1,2.5-1.8,5-2.8,7.4c-2.1,6.2-3.7,12.5-5.1,19.3c-0.7,3.4-1.4,6.9-1.9,10.3c-0.5,3.3-1,6.6-1.4,10
	c-0.5,5-0.8,9.9-0.8,15c0,2.6,0,5.2,0.3,8l0.7,7.7c0.4,3.2,0.8,6.3,1.2,9.4c0.5,3.6,1.2,7,1.9,10.3c1.4,6.7,3.2,13.1,5.1,19.3
	c0.8,2.5,1.8,5,2.8,7.4c2.3,5.9,5,11.7,8,17.1l2.3,4.1c3.3,5.6,7.2,11,11.3,16.1c2.6,3.2,5.4,6.3,8.3,9.2l5.9-1.2
	c20.8-4.5,42.1-10.9,64.1-19.1c-2.2-1.8-4.4-3.9-6.5-6.5c-1.4-1.6-2.6-3.4-4-5.5c-2.5-3.7-4.8-8.3-7.2-13.2
	c-3-6.7-5.9-14.4-8.7-23.2c-3.7-13.5-5.5-26.7-5.2-39.8c-0.3-13.1,1.5-26.3,5.2-39.6c2.8-8.8,5.6-16.6,8.7-23.2
	c2.3-5.1,4.7-9.5,7.2-13.2c1.2-2.1,2.6-3.9,4-5.5c2.1-2.6,4.3-4.8,6.5-6.5C352,205.3,330.6,199,309.8,194.5L309.8,194.5z
	 M309.8,202.6c3.2-0.6,6.6-0.4,10.2,0.6l32.6,8.3c7.7,2.6,10,7.2,6.9,13.2l-0.5,1l-1.6,2.3c-1.9,3.2-3.9,6.9-5.6,10.9
	c-3,6.7-5.9,14.4-8.7,23.2c-3.7,13.3-5.5,26.5-5.2,39.6c-0.3,13.1,1.5,26.3,5.2,39.6c2.8,8.8,5.6,16.6,8.7,23.2
	c1.8,4,3.7,7.6,5.6,10.9l1.6,2.3l0.5,1c3.2,6.2,0.8,10.6-6.9,13.2l-32.6,8.3c-3.6,0.8-7,1-10.2,0.5c-6.1-1-11.3-4.5-15.5-10.5
	c-1.1-1.8-2.2-3.4-3.3-5.2l-2.3-4.1c-3-5.4-5.6-11.1-8-17.1c-1-2.5-1.8-5-2.6-7.4c-2.1-6.2-3.7-12.5-5.1-19.3
	c-0.7-3.4-1.4-6.9-1.9-10.3c-0.5-3-1-6.2-1.2-9.4c-0.4-3.9-0.7-7.7-1-11.7c-0.3-5.1-0.1-10.2,0.5-15.3l0.4-3.7
	c0.4-3.4,0.8-6.7,1.4-10c0.5-3.6,1.2-7,1.9-10.3c1.4-6.7,3.2-13.1,5.1-19.3c0.8-2.5,1.8-4.9,2.8-7.4c2.3-5.9,5-11.7,8-17.1
	c0.8-1.4,1.5-2.8,2.3-4.1c1.1-1.8,2.2-3.6,3.3-5.2C298.5,207.1,303.7,203.7,309.8,202.6L309.8,202.6z"
          />
          <path
            fill="none"
            d="M320,203.1c-3.6-0.8-7-1-10.2-0.6c-6.1,1-11.3,4.5-15.5,10.5c-1.1,1.8-2.2,3.4-3.3,5.2
	c-0.8,1.4-1.7,2.8-2.3,4.1c-2.9,5.4-5.6,11.1-8,17.1c-1,2.5-1.8,5-2.8,7.4c-2.1,6.2-3.7,12.5-5.1,19.3c-0.7,3.4-1.4,6.9-1.9,10.3
	c-0.5,3.3-1,6.6-1.4,10l-0.4,3.7c-0.5,5.1-0.8,10.2-0.5,15.3c0.3,3.9,0.5,7.8,1,11.7c0.4,3.2,0.8,6.3,1.2,9.4
	c0.5,3.6,1.2,7,1.9,10.3c1.4,6.7,3.2,13.1,5.1,19.3c0.8,2.5,1.8,5,2.6,7.4c2.3,5.9,5,11.7,8,17.1l2.3,4.1c1.1,1.8,2.2,3.6,3.3,5.2
	c4.3,6.1,9.5,9.5,15.5,10.5c3.2,0.5,6.6,0.4,10.2-0.5l32.6-8.3c7.7-2.8,10-7.2,6.9-13.2l-2.1-3.3c-1.9-3.2-3.9-6.9-5.6-10.9
	c-3-6.7-5.9-14.4-8.7-23.2c-3.7-13.3-5.5-26.5-5.2-39.6c-0.3-13.1,1.5-26.3,5.2-39.6c2.8-8.8,5.6-16.6,8.7-23.2
	c1.8-4,3.7-7.6,5.6-10.9l2.1-3.3c3.2-6.2,0.8-10.6-6.9-13.2L320,203.1L320,203.1z"
          />
          <path
            fill="none"
            d="M359,225.6l0.4-1l-2.1,3.3 M359.5,378.7l-0.4-0.8l-1.6-2.5 M339.2,176.9c-0.7-0.1-1.4-0.3-2.1-0.6l-6.1-1.8
	c-0.7-0.3-1.2-0.7-1.8-1.1l-2.1,4.1c2.2,1.2,4.5,1.8,7.3,1.8c2.9-0.1,5-1,6.5-2.5L339.2,176.9L339.2,176.9z M342.9,192l-5.6-6.3
	l-15.4,0.6C329.2,188.1,336.1,189.9,342.9,192L342.9,192z M337.3,185.5l5.6,6.3c14.9,4.4,28.5,9.1,41,14.3
	c15.8-0.3,31.4-0.4,46.8-0.4h30.4c-4.7-9.2-10.2-16.8-16.6-22.8l-13.8,0.1C400.7,183.5,369.6,184.3,337.3,185.5L337.3,185.5z
	 M291.1,175.5c3-0.3,4-0.7,2.6-1.4h-0.1l-16-5.6l-14.9-0.4l17.7,6.3c1.6,0.6,3.2,1,4.7,1.2h0.3L291.1,175.5L291.1,175.5z M289,190.1
	l2.6-0.8c-10.2-9.9-23.5-17.1-39.9-21.5c-43.6-0.3-77.6,3.7-101.9,12c-6.2,5-11.7,10-16.5,15.3c-4.1,4.4-7.8,8.9-11,13.6l-2.3,3.4
	c-1.1,1.8-2.2,3.6-3.2,5.5c-1,1.8-1.9,3.6-2.8,5.5c-1.1,2.3-1.9,4.8-2.8,7.4c-0.8,2.5-1.4,4.9-1.9,7.4l-0.6,2.5
	c-4,19.3-5.9,39.3-5.8,60.4v0.7c-0.1,13.1,0.6,25.7,1.9,38.1c1,7.8,2.2,15.5,3.7,23.2l0.6,2.5c0.6,2.5,1.2,5,1.9,7.4
	c0.8,2.5,1.7,5,2.8,7.4c0.8,1.8,1.8,3.7,2.8,5.5c1,1.6,1.9,3.4,2.9,5.1l3,4.5c3,4.4,6.6,8.7,10.5,12.9c4.8,5.2,10.3,10.3,16.5,15.3
	c24.4,8.3,58.3,12.2,101.9,12c16.4-4.4,29.6-11.6,39.9-21.5l-2.6-0.8c-8.5-3.6-15.3-9.6-20.2-18.2c-12.7-19.8-20.5-45.8-23.7-78
	c-0.7-6.9-0.8-13.9-0.7-20.8c0.1-2.8,0.3-5.5,0.4-8.3c3-33,11-59.7,23.8-79.8C273.8,199.7,280.5,193.6,289,190.1L289,190.1z
	 M187.1,181c1.2,2.2,0.7,5.2-1.8,9.1c-4.3,5.9-10,11.4-17.3,16.9c-9.1,7.8-16.9,12.7-23.5,14.4c-2.5,0.7-4.4,1-5.8,0.8
	c-1.1-0.1-1.9-0.4-2.5-0.8c-1.8-2.3-2.1-5.1-0.6-8.4c4-8.5,9.1-16.1,15-22.8c6.7-8,14.3-12.2,22.6-12.8h1.8
	C181.4,177.3,185.4,178.5,187.1,181L187.1,181z M236.1,289c0.3,2.5,0.6,4.8,0.7,7l0.1,5.1l-0.3,6.5c-0.1,2.2-0.4,4.4-0.7,6.7
	c-1.2,8.9-5.6,13.9-13.3,14.9c-23.1-1.6-42.1-4-56.8-7c-9.9-3.2-14.7-9.9-14.6-19.9v-2.5c0.3-9.2,5.2-15.4,14.4-18.4
	c14.9-3,33.8-5.4,56.8-7C230.4,275.2,234.8,280.2,236.1,289L236.1,289z M185.3,413.3c2.5,3.9,3,6.9,1.8,9.1
	c-1.6,2.5-5.6,3.7-12.1,3.6h-1.8c-8.3-0.4-15.8-4.7-22.6-12.8c-5.9-6.6-11-14.2-15-22.8c-1.4-3.3-1.2-6.1,0.6-8.4
	c0.6-0.4,1.4-0.7,2.5-0.8c1.5-0.1,3.4,0.1,5.8,0.8c6.6,1.8,14.4,6.6,23.5,14.4C175.3,401.8,181,407.4,185.3,413.3L185.3,413.3z
	 M149.3,190.2c1.6-1.9,6.2-6.5,13.8-13.5C155.4,183.7,150.9,188.3,149.3,190.2c-5.8,6.5-10.6,13.8-14.6,22c-2.3,4.4-4.8,7.2-7.6,8.4
	c-1.8,5.4-3.3,11.3-4.7,17.5l-0.6,2.3c-1.2,6.1-2.3,12.4-3.3,19.3c-2.2,15.5-3,31.1-2.8,46.8c0.3,15.4,1.7,30.8,4.1,46.1
	c1.9,11.1,4.3,21.2,7.2,30.1c2.8,1.2,5.4,4.1,7.6,8.4c4,8.3,8.8,15.5,14.6,22c1.6,1.9,6.2,6.5,13.8,13.5c-7.6-7-12.1-11.6-13.8-13.5
	c-5.8-6.5-10.6-13.8-14.6-22c-2.2-4.4-4.8-7.2-7.6-8.4c-2.9-8.9-5.2-19-7.2-30.1c-2.3-15.3-3.7-30.7-4.1-46.1
	c-0.3-15.7,0.6-31.2,2.8-46.8c1-6.7,2.1-13.2,3.3-19.3l0.6-2.3c1.4-6.3,2.9-12.1,4.7-17.5c2.8-1.2,5.4-4.1,7.6-8.4
	C138.7,204,143.5,196.7,149.3,190.2L149.3,190.2z M124.6,289.5l0.8-0.7v8l0.8,0.8c1,1,1.4,2.2,1.4,3.6c0,1.4-0.4,2.6-1.4,3.6
	l-0.8,0.8v8l-0.8-0.7l-0.8-0.7l-0.7-0.7l-0.7-0.8l-0.8-1l-0.8-1.2l-0.8-1.4v-2.2l-0.1-0.1c-1-1-1.4-2.2-1.4-3.6
	c0-1.4,0.4-2.6,1.4-3.6l0.1-0.1v-2.2l0.8-1.2l0.8-1.2l0.8-1l0.7-0.8l0.7-0.7L124.6,289.5L124.6,289.5z M134.9,230.2
	c28.3-2.5,69.6-9.6,123.9-21.2C204.6,220.6,163.3,227.6,134.9,230.2L134.9,230.2z M134.9,373.2c28.3,2.5,69.6,9.6,123.9,21.2
	C204.6,382.7,163.3,375.6,134.9,373.2z"
          />
          <path
            fill="none"
            d="M185.3,190.1c2.5-3.9,3-6.9,1.8-9.1c-1.6-2.5-5.6-3.7-12.1-3.6h-1.8c-8.3,0.4-15.8,4.7-22.6,12.8
	c-5.9,6.6-11,14.2-15,22.8c-1.4,3.3-1.2,6.1,0.6,8.4c0.6,0.4,1.4,0.7,2.5,0.8c1.5,0.1,3.4-0.1,5.8-0.8c6.6-1.8,14.4-6.6,23.5-14.4
	C175.3,201.5,181,195.8,185.3,190.1L185.3,190.1z M169.6,180.3l1.9-0.1c4.5-0.1,7.2,0.7,8,2.8c0.1,0.6,0.3,1.4,0.1,2.2
	c-0.3,1.5-1.1,3.3-2.5,5.5c-3.7,4.8-8.3,9.6-13.9,14.2c-7,6.5-12.9,10.6-17.9,12.4c-1.5,0.6-2.8,0.8-3.9,1c-1,0.1-1.8,0-2.3-0.4
	c-0.7-0.8-1.1-1.9-1.1-3.2c0-1,0.3-2.1,0.8-3.2c3.3-7,7.4-13.5,12.4-19.3C157.1,185,163.1,181,169.6,180.3L169.6,180.3z"
          />
          <path
            fill="none"
            d="M171.4,180.3l-1.9,0.1c-6.5,0.7-12.5,4.5-18.3,11.8c-5,5.8-9.1,12.2-12.4,19.3c-0.6,1.1-0.8,2.2-0.8,3.2
	c0,1.2,0.3,2.2,1.1,3.2c0.6,0.4,1.4,0.6,2.3,0.4c1-0.1,2.3-0.4,3.9-1c4.8-1.8,10.7-5.9,17.9-12.4c5.6-4.5,10.3-9.4,13.9-14.2
	c1.4-2.2,2.3-4,2.5-5.5c0.1-0.8,0-1.5-0.1-2.2C178.6,181,175.9,180,171.4,180.3z M236.7,296.1c-0.1-2.2-0.4-4.5-0.7-7
	c-1.2-8.9-5.6-13.9-13.3-14.9c-23.1,1.6-42.1,4-56.8,7c-9.4,3-14.2,9.2-14.4,18.4v2.5c-0.1,10.2,4.7,16.8,14.6,19.9
	c14.9,3,33.8,5.4,56.8,7c7.7-1.1,12.1-6.1,13.3-14.9c0.3-2.3,0.6-4.5,0.7-6.7l0.3-6.5L236.7,296.1L236.7,296.1z M187.1,422.3
	c1.2-2.2,0.7-5.2-1.8-9.1c-4.3-5.9-10-11.4-17.3-16.9c-9.1-7.8-16.9-12.7-23.5-14.4c-2.5-0.7-4.4-1-5.8-0.8
	c-1.1,0.1-1.9,0.4-2.5,0.8c-1.8,2.3-2.1,5.1-0.6,8.4c4,8.5,9.1,16.1,15,22.8c6.7,8,14.3,12.2,22.6,12.8h1.8
	C181.4,426,185.4,424.8,187.1,422.3L187.1,422.3z M179.7,418.1c0.1,0.8,0,1.5-0.1,2.2c-0.8,2.1-3.6,2.9-8,2.8l-1.9-0.1
	c-6.5-0.7-12.5-4.5-18.3-11.8c-5-5.8-9.1-12.2-12.4-19.3c-0.6-1.1-0.8-2.2-0.8-3.2c0-1.2,0.3-2.2,1.1-3.2c0.6-0.4,1.4-0.5,2.3-0.4
	c1,0.1,2.3,0.4,3.9,1c4.8,1.8,10.7,5.9,17.9,12.4c5.6,4.5,10.3,9.4,13.9,14.2C178.6,414.8,179.4,416.6,179.7,418.1L179.7,418.1z"
          />
          <path
            fill="none"
            d="M179.5,420.3c0.1-0.5,0.3-1.4,0.1-2.2c-0.3-1.5-1.1-3.3-2.5-5.5c-3.7-4.8-8.3-9.6-13.9-14.2
	c-7-6.5-12.9-10.6-17.9-12.4c-1.5-0.6-2.8-0.8-3.9-1c-1-0.1-1.8,0-2.3,0.4c-0.7,0.8-1.1,1.9-1.1,3.2c0,1,0.3,2.1,0.8,3.2
	c3.3,7,7.4,13.5,12.4,19.3c5.8,7.3,11.8,11.3,18.3,11.8l1.9,0.1C175.9,423.2,178.6,422.3,179.5,420.3L179.5,420.3z M125.5,288.8
	l-0.8,0.7v6.7c0.3,0.1,0.6,0.3,0.8,0.4V288.8L125.5,288.8z M123.1,290.9l-0.7,0.8v4.4l0.7-0.1"
          />
          <path fill="none" d="M123.9,290.2l-0.8,0.7v5.1l0.8,0.1" />
          <path
            fill="none"
            d="M123.9,296.1h-1.4l-0.8,0.1l-0.8,0.4c0,0-0.6,0.4-0.8,0.7l-0.1,0.1c-1,1-1.4,2.2-1.4,3.6c0,1.4,0.4,2.6,1.4,3.6
	l0.1,0.1l0.8,0.7l0.8,0.4l0.8,0.1h0.7l0.7-0.1l0.8-0.3l0.8-0.4l0.8-0.8c1-1,1.4-2.2,1.4-3.6s-0.4-2.6-1.4-3.6l-0.8-0.8l-0.8-0.4
	L123.9,296.1L123.9,296.1z M125.9,298.1c0.8,0.8,1.2,1.9,1.2,3.2s-0.4,2.2-1.2,3.2c-0.8,0.8-1.7,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.8-1.2
	c-0.8-0.8-1.1-1.9-1.1-3.2s0.4-2.2,1.1-3.2c0.8-0.8,1.8-1.2,2.8-1.2C124.2,296.7,125.2,297.1,125.9,298.1L125.9,298.1z"
          />
          <path
            fill="none"
            d="M127.1,301.1c0-1.2-0.4-2.2-1.2-3.2c-0.8-0.8-1.7-1.2-2.8-1.2s-2.1,0.4-2.9,1.2c-0.8,0.8-1.1,1.9-1.1,3.2
	s0.4,2.3,1.1,3.2c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.8-1.2C126.7,303.4,127.1,302.3,127.1,301.1z M125.7,298.3
	c0.7,0.8,1.1,1.8,1.1,2.9s-0.4,2.1-1.1,2.9c-0.7,0.8-1.5,1.2-2.6,1.2c-1,0-1.8-0.4-2.5-1.2c-0.7-0.8-1.1-1.8-1.1-2.9
	c0-1.1,0.4-2.1,1.1-2.9c0.7-0.8,1.5-1.2,2.5-1.2S124.9,297.5,125.7,298.3L125.7,298.3z"
          />
          <path
            fill="none"
            d="M126.7,301.1c0-1.1-0.4-2.1-1.1-2.9c-0.7-0.8-1.5-1.2-2.6-1.2c-1,0-1.8,0.4-2.5,1.2c-0.7,0.8-1.1,1.8-1.1,2.9
	c0,1.1,0.4,2.1,1.1,2.9c0.7,0.8,1.5,1.2,2.5,1.2s1.9-0.4,2.6-1.2C126.4,303.1,126.7,302.3,126.7,301.1L126.7,301.1z M124.1,297.6
	v0.5l-1.2,0.7l1.2,0.7v0.5h-1.9v-0.5h1.1l-1.1-0.5v-0.1l1.1-0.6h-1.1v-0.5h1.9V297.6L124.1,297.6z M122.2,301.1v-0.5h1.9v0.5H122.2z
	 M122.2,301.6h1.9v0.4l-1.2,1.1h1.2v0.4h-1.9v-0.4l1.2-1.1h-1.2V301.6z M122.2,304.5V304h1.9v0.5L122.2,304.5L122.2,304.5z"
          />
          <path
            fill="none"
            d="M124.1,298.2v-0.5h-1.9v0.5h1.1l-1.1,0.5v0.3l1.1,0.4h-1.1v0.5h1.9v-0.5l-1.2-0.5 M122.2,300.5v0.5h1.9v-0.5
	 M124.1,301.6h-1.9v0.4h1.1l-1.1,1.1v0.4h1.9v-0.4h-1.2l1.2-1.1 M122.2,304v0.5h1.9V304 M121.6,296.3l0.8-0.1v-4.4l-0.8,1"
          />
          <path
            fill="none"
            d="M120.8,296.7l0.8-0.4v-3.6l-0.8,1.2L120.8,296.7L120.8,296.7z"
          />
          <path
            fill="none"
            d="M120.8,296.7v-2.8l-0.8,1.2v2.2C120.2,297.1,120.5,296.8,120.8,296.7L120.8,296.7z M123.9,296.1l0.7,0.1v-6.7
	l-0.7,0.7 M121.6,306l-0.8-0.4v2.8l0.8,1.2"
          />
          <path
            fill="none"
            d="M122.4,306.2l-0.8-0.1v3.6l0.8,1 M123.8,306.2l-0.7,0.1v5.1l0.8,0.7 M123.1,306.3l-0.7-0.1v4.4l0.7,0.8
	 M125.5,305.5l-0.8,0.4v6.9l0.8,0.7V305.5L125.5,305.5z"
          />
          <path
            fill="none"
            d="M124.6,306l-0.8,0.1l0.1,5.9l0.7,0.7 M120.8,305.6l-0.8-0.7v2.2l0.8,1.2 M291.6,189.2l-2.6,0.8
	c-8.5,3.6-15.3,9.6-20.2,18.2C256,228.4,248,255,245,288.1c-0.3,2.8-0.4,5.5-0.4,8.3c-0.3,7,0,13.9,0.7,20.8
	c3.2,32.2,11,58.2,23.7,78c5,8.5,11.7,14.6,20.2,18.2l2.6,0.8l0.1-0.1c-8.5-3.6-15.3-9.8-20.2-18.3c-12.7-19.9-20.5-46.1-23.7-78.5
	c-0.7-6.9-0.8-13.9-0.7-20.8c0.1-2.8,0.3-5.5,0.6-8.3c3-33.3,11-60,23.8-80.3c5-8.5,11.7-14.7,20.2-18.3L291.6,189.2L291.6,189.2z"
          />
          <path
            fill="none"
            d="M261.6,438.4l2.3-0.1L261.6,438.4c10.7-0.3,20.5-1.7,29.6-4.1l-10.3,0.5h-0.4h0.4l15.8-6.1
	c0.3-0.1,0.4-0.3,0.4-0.4c-0.1-2.3,0.8-4.1,2.9-5.5l6.6-5.6l-7-1.2l-1.5-0.3c-2.2-0.5-4.3-1-6.5-1.6c-10.2,9.9-23.5,17.1-39.9,21.5
	l10.9-0.1l17.7-6.3c1.6-0.5,3.2-1,4.7-1.2h6.1c3,0.3,4,0.7,2.6,1.4h-0.1l-16,5.6l2.6-0.1l-2.6,0.1l-14.9,0.4l-10.9,0.1
	c-43.6,0.3-77.6-3.7-101.9-12c7.4,6.5,16.2,10.6,26.7,12.4C208.3,439.6,236.7,440.6,261.6,438.4L261.6,438.4z"
          />
          <path
            fill="none"
            d="M291.8,413.9l-0.1,0.1c2.2,0.5,4.3,1.1,6.5,1.6l1.5,0.3C297,415.4,294.4,414.7,291.8,413.9L291.8,413.9z
	 M293.6,429.2c1.4-0.5,0.5-1.1-2.6-1.4h-6.1c-1.5,0.3-3,0.5-4.7,1.2l-17.7,6.3l14.9-0.4L293.6,429.2L293.6,429.2z"
          />
          <path
            fill="none"
            d="M291.1,434.2l3.9-1.1h6.5c2.8-4.1,5.5-7.6,8.4-10c2.3-2.1,4.7-3.7,7.3-4.8l-7.2-0.7l-3.3-0.4l-6.6,5.6
	c-2.1,1.4-3,3.2-2.9,5.5c0,0.1-0.1,0.3-0.4,0.4l-15.8,6.1L291.1,434.2L291.1,434.2z"
          />
          <path
            fill="none"
            d="M317.1,418.3c-2.5,1.1-5,2.8-7.3,4.8c-2.9,2.6-5.8,5.9-8.4,10l24.8,0.1l-3.2-4.4c-1.1-1.6-0.7-3.2,1.4-4.3
	c3.9-1.9,7.6-2.5,10.9-1.6c2.6,0.5,4.5,1.8,5.5,3.3c1,0.1,1.6,0.6,1.9,1.2l2.2,5.9l67,0.5c0-0.8,1.2-1.4,3.9-1.6h3.7
	c3.4-1.1,7.2-1.6,11.1-1.4c2.9,0.1,5.9,0.7,9.2,1.6h5.5c2.6,0.4,3.9,1,3.4,1.8h5c4.5-5.9,7.2-10,7.6-12.4c-10,0-20.4,0-30.7-0.1
	c-34.7-0.4-70.6-1.5-107.9-3.2L317.1,418.3L317.1,418.3z"
          />
          <path
            fill="none"
            d="M317.1,418.3l4.8-1.2c7.3-1.8,14.2-3.7,20.9-5.8c14.9-4.4,28.5-9.1,41-14.3c15.8,0.3,31.4,0.4,46.8,0.4h42.1
	l0.8-2.8c-14.2,0.1-28.5,0.1-42.9,0c-15.4,0-31.1-0.3-47-0.4l-0.5,0.1l-0.1,0.1c-21.6,8.9-45.8,16.6-73,23.1L317.1,418.3
	L317.1,418.3z"
          />
          <path
            fill="none"
            d="M321.9,417l-4.8,1.2l5.6,0.3c37.3,1.8,73.3,2.9,107.9,3.2c10.3,0.1,20.6,0.1,30.7,0.1c0.1-0.7,0.1-1.2-0.1-1.5
	h-16.8l-13.8-0.1c-30-0.3-61.1-1.1-93.4-2.5L321.9,417L321.9,417z M309.8,408.9l-5.9,1.2l-2.1,0.4c1.9,3,4.7,5.4,8.1,7
	c27.1-6.5,51.5-14.2,73-23.1c-3-0.8-6.1-2.5-8.8-4.7C352,397.9,330.6,404.4,309.8,408.9L309.8,408.9z M324.4,424.6
	c-2.1,1.2-2.5,2.6-1.4,4.3c1.2-1.4,2.6-2.5,4.1-3.3c2.2-1.2,4.5-1.8,7.3-1.8c2.9,0.1,5,1,6.5,2.5c-1-1.6-2.9-2.8-5.5-3.3
	C331.9,422.3,328.2,422.8,324.4,424.6L324.4,424.6z M326.3,433.3h-0.1l0.1,0.3"
          />
          <path
            fill="none"
            d="M326.2,433.3h0.1c0.5-1.4,1.5-2.5,2.9-3.4l-2.1-4.1c-1.5,0.8-2.9,1.9-4.1,3.3L326.2,433.3L326.2,433.3z"
          />
          <path
            fill="none"
            d="M326.3,433.3v0.1c-0.4,1.2-0.5,2.8-0.1,4.3c0.5,2.6,1.5,5.1,2.9,7.4c1.6,2.9,4,5.5,6.9,8.1
	c4.1,3.4,8.5,4.8,13.3,3.9l1-0.3l-11-30.5c-0.7,0.1-1.4,0.3-2.1,0.5l-6.1,1.8c-0.7,0.3-1.2,0.7-1.8,1.1
	C327.8,430.9,326.9,432,326.3,433.3L326.3,433.3z"
          />
          <path
            fill="none"
            d="M337,427.1c0.8-0.3,1.5-0.4,2.1-0.5l1.6-0.1c-1.4-1.5-3.6-2.3-6.5-2.5c-2.8,0-5.1,0.7-7.3,1.8l2.1,4.1
	c0.5-0.4,1.1-0.8,1.8-1.1L337,427.1L337,427.1z"
          />
          <path
            fill="none"
            d="M340.7,426.4l-1.6,0.1l11,30.5c2.1-0.7,2.8-1.8,2.1-3.4l-7.3-20.1l-2.2-5.9
	C342.4,426.8,341.8,426.4,340.7,426.4L340.7,426.4z M337.3,417.7l5.6-6.3c-6.7,1.9-13.8,3.9-20.9,5.8L337.3,417.7L337.3,417.7z"
          />
          <path
            fill="none"
            d="M342.9,411.3l-5.6,6.3c32.3,1.4,63.4,2.2,93.4,2.5l13.8,0.1c6.5-6.1,12-13.6,16.6-22.8h-30.4
	c-15.4,0-31-0.3-46.8-0.4C371.3,402.2,357.7,407,342.9,411.3L342.9,411.3z M419.4,432.3h-3.7c-2.6,0.3-4,1-3.9,1.6
	c0,0.4,0.3,0.8,1,1.2l4.3,1.9c2.9,1.6,7.4,2.6,13.5,2.8h4.3c4.4,0,7.2-1.2,8.3-3.7c3.3,0.3,5.1-0.3,5.5-1.8l0.1-0.3
	c0.4-0.8-0.8-1.4-3.4-1.8h-5.5c-0.1,0.7-0.3,1.2-0.7,1.6c-0.8,1.1-2.5,1.6-4.8,1.6h-8.8c-2.9-0.1-4.8-0.7-5.6-1.8
	C419.6,433.6,419.4,433,419.4,432.3L419.4,432.3z"
          />
          <path
            fill="none"
            d="M419.4,432.3c0,0.7,0.1,1.2,0.5,1.8l19.3,0.1c0.3-0.4,0.5-1.1,0.7-1.7c-3.3-0.8-6.3-1.4-9.2-1.6
	C426.6,430.7,422.9,431.1,419.4,432.3L419.4,432.3z M691.9,181.4l0.3-0.1l-2.3-2.2l-3.7,2.5c-1.2,0.8-1.7,2.5-1.4,4.7
	c0.3,2.3,1.4,4.7,3,7.2l4.8,7.2c1.7,2.5,3.6,4.3,5.6,5.4c2.1,1.1,3.7,1.2,4.8,0.4l3.4-2.3l-0.8-1.8l-2.6,1.8c-1,0.7-2.3,0.6-4-0.4
	c-1.7-1-3.3-2.5-4.7-4.5l-4-5.9c-1.4-2.1-2.2-4-2.5-5.9c-0.3-1.9,0.1-3.3,1.1-4L691.9,181.4L691.9,181.4z"
          />
          <path
            fill="none"
            d="M692.2,181.3l-0.3,0.1l-2.9,2.1c-1,0.7-1.4,2.1-1.1,4s1.1,3.9,2.5,5.9l4,5.9c1.4,2.1,2.9,3.6,4.7,4.5
	c1.7,1,3,1.1,4,0.4l2.6-1.8C702,193.5,697.5,186.5,692.2,181.3L692.2,181.3z"
          />
          <path
            fill="none"
            d="M692.2,181.3c1-0.6,2.2-0.4,3.9,0.6s3.3,2.5,4.7,4.4l4,5.9c1.4,2.1,2.2,4,2.5,5.9s-0.1,3.2-1.1,3.9l-0.3,0.3
	l0.8,1.8l0.1-0.1c1.2-0.8,1.7-2.5,1.4-4.7c-0.3-2.3-1.4-4.7-3-7.2l-4.8-7.2c-1.7-2.5-3.6-4.3-5.6-5.4c-1.9-1.1-3.6-1.2-4.7-0.6
	L692.2,181.3L692.2,181.3z"
          />
          <path
            fill="none"
            d="M696.1,181.8c-1.7-0.8-2.9-1.1-3.9-0.6c5.2,5.2,9.8,12.2,13.6,20.9l0.3-0.3c1-0.7,1.4-2.1,1.1-3.9
	c-0.3-1.9-1.1-3.9-2.5-5.9l-4-5.9C699.4,184.3,697.7,182.8,696.1,181.8L696.1,181.8z"
          />
          <path
            fill="none"
            d="M694.6,179.6c2.1,1.1,3.9,2.9,5.6,5.4l4.8,7.2c1.7,2.5,2.6,4.8,3,7.2c0.3,2.3-0.1,3.9-1.4,4.7l-0.1,0.1
	c1,2.3,1.9,4.7,2.8,7.3c1.2,3.4,2.3,7.2,3.3,11c5.1,20.1,8.1,46.5,8.8,79.2c-0.8,32.9-3.7,59.3-8.8,79.2c-1,3.9-2.1,7.6-3.3,11
	c-0.8,2.6-1.8,5-2.8,7.3l0.1,0.1c1.2,0.8,1.7,2.5,1.4,4.7c-0.3,2.3-1.4,4.7-3,7.2l-4.8,7.1c-1.7,2.5-3.6,4.3-5.6,5.4
	c-1.9,1.1-3.6,1.2-4.7,0.5c-3.2,2.8-6.5,4.8-10,6.3c7.2,0.4,15.1-1.6,23.9-6.1c2.9-2.2,5.4-4.7,7.3-7.4c5-4.8,9.4-11.1,12.9-18.8
	l0.1-0.1c4.5-9.9,8.1-22.3,10.6-37c1.4-8.8,2.5-18.6,3.2-29.2c1-8.8,1.4-18.8,1.2-30.1c0.1-11.4-0.3-21.6-1.2-30.4
	c-0.7-10.6-1.7-20.2-3.2-29.2c-2.5-14.7-5.9-27.1-10.6-37l-0.1-0.1c-3.6-7.8-8-14-12.9-18.8c-1.9-2.8-4.3-5.2-7.3-7.4
	c-8.8-4.5-16.8-6.5-23.9-6.1c3.6,1.5,6.9,3.6,10,6.3C691.1,178.4,692.6,178.6,694.6,179.6L694.6,179.6z"
          />
          <path
            fill="none"
            d="M684.8,186.4c-0.3-2.3,0.1-3.9,1.4-4.7l3.7-2.5c-3.2-2.8-6.5-4.8-10-6.3c-4.3-1.8-8.9-2.9-13.9-3
	c-18.4-2.6-38.8-3.9-60.9-3.9l-6.3,19.9c21.2,0.7,38.7,2.8,52.7,5.9c7.7,1.8,14.6,4.3,20.5,7.4c8.4,4.5,15,10.3,19.7,17.5l17.7-5.5
	c-0.8-2.6-1.8-4.9-2.8-7.3l-3.4,2.3c-1.2,0.8-2.9,0.7-4.8-0.4c-2.1-1.1-3.9-2.9-5.6-5.4l-4.8-7.2C686.2,191,685.2,188.7,684.8,186.4
	L684.8,186.4z"
          />
          <path
            fill="none"
            d="M679.8,172.7c7.2-0.4,15.1,1.6,23.9,6.1c-10.9-8.3-28.8-13.1-53.5-14.6c-14.3-0.8-29.2-1-44.7-0.4l-0.5,1.9
	c22.1,0,42.5,1.2,60.9,3.9C670.9,170,675.6,170.9,679.8,172.7L679.8,172.7z M712.6,222.4c-1-3.9-2.1-7.6-3.3-11l-17.7,5.5
	c3,4.7,5.2,9.9,6.6,15.8c3.3,12.8,5.6,27,6.7,42.5l0.4,7l0.5,20.1l-0.5,19.5l-0.4,6.7c-1.1,15.5-3.3,29.7-6.7,42.5
	c-1.4,5.8-3.6,11.1-6.6,15.8l17.7,5.5c1.2-3.4,2.3-7.1,3.3-11c5.1-20.1,8.1-46.5,8.8-79.2C720.7,268.9,717.8,242.5,712.6,222.4
	L712.6,222.4z"
          />
          <path
            fill="none"
            d="M691.5,216.8l-3.2,1l-31.8,9.8c6.9,0.8,12.1,5.1,15.7,12.8l14.4-4.1c3-1.8,5.6,1.5,7.8,9.8
	c3.2,15.3,4.4,27.1,3.7,35.4v0.1c-2.1,6.9-2.9,13.8-2.3,20.4c-0.5,6.6,0.1,13.3,2.3,20.2v0.1c0.7,8.3-0.5,20.1-3.7,35.4
	c-2.2,8.3-4.8,11.6-7.8,9.8l-14.4-4.1c-3.6,7.7-8.9,12-15.7,12.8l31.8,9.8l3.2,1c3-4.7,5.2-9.9,6.6-15.8c3.3-12.8,5.6-27,6.7-42.5
	l0.4-6.7l0.5-19.5l-0.5-20.1l-0.4-7c-1.1-15.5-3.3-29.7-6.7-42.5C696.8,226.8,694.6,221.4,691.5,216.8L691.5,216.8z"
          />
          <path
            fill="none"
            d="M688.4,217.7l3.2-1c-4.7-7.2-11.1-13.1-19.7-17.5l-2.3,2.2C677.4,204.6,683.7,210,688.4,217.7L688.4,217.7z
	 M694.6,245.9c-2.2-8.3-4.8-11.6-7.8-9.8l-14.4,4.1c0.7,1.5,1.4,3,1.9,4.8c3.8,9.4,6.5,20.5,7.8,33.4l0.3,2.8l0.8,20.4l-0.8,20.4
	l-0.3,2.6c-1.4,12.9-4,24.1-7.8,33.4c-0.5,1.8-1.2,3.3-1.9,4.8l14.4,4.1c3,1.8,5.6-1.5,7.8-9.8c3.2-15.3,4.4-27.1,3.7-35.4v-0.1
	c-2.1-6.9-2.9-13.6-2.3-20.2c-0.5-6.6,0.1-13.3,2.3-20.4V281C699,272.9,697.7,261.2,694.6,245.9L694.6,245.9z"
          />
          <path
            fill="none"
            d="M682.2,281.3l-0.3-2.8c-1.4-12.9-4-24.1-7.8-33.4c-0.5-1.8-1.2-3.3-1.9-4.8c-3.6-7.7-8.9-12-15.7-12.8
	c-9.4,2.6-14.3,7.4-14.9,14.4c-1.4,9.4-2.3,19.1-3,29.2l-0.5,11.3c-0.3,6.3-0.4,12.7-0.4,19c0,6.5,0.1,12.8,0.4,19.1l0.5,11.6
	c0.7,10,1.7,19.8,3,29.2c0.5,7,5.5,11.8,14.9,14.4c6.9-0.8,12.1-5.1,15.7-12.8c0.7-1.5,1.4-3,1.9-4.8c3.9-9.4,6.5-20.5,7.8-33.4
	l0.3-2.6l0.8-20.4L682.2,281.3L682.2,281.3z M724.1,205.3c4.5,9.9,8.1,22.3,10.6,37C736.2,230.8,732.8,218.4,724.1,205.3
	L724.1,205.3z M691.5,386.5l-3.2-1c-4.7,7.7-11,13.2-18.8,16.4l2.3,2.2C680.4,399.6,686.9,393.7,691.5,386.5L691.5,386.5z"
          />
          <path
            fill="none"
            d="M692.8,402.7c1.7-2.5,3.6-4.3,5.6-5.4c2.1-1.1,3.7-1.2,4.8-0.4l3.4,2.3c1-2.3,1.9-4.7,2.8-7.3l-17.7-5.5
	c-4.7,7.2-11.1,13.1-19.7,17.5c-5.9,3.2-12.8,5.6-20.5,7.4c-14,3.2-31.6,5.1-52.7,5.9l6.3,19.9c22.2,0,42.5-1.2,60.9-3.9
	c5-0.1,9.6-1.1,13.9-3c3.6-1.5,6.9-3.6,10-6.3l-3.7-2.5c-1.2-0.8-1.7-2.5-1.4-4.7c0.3-2.3,1.4-4.7,3-7.2L692.8,402.7L692.8,402.7z"
          />
          <path
            fill="none"
            d="M698.3,397.4c-2.1,1.1-3.9,2.9-5.6,5.4l-4.8,7c-1.7,2.5-2.6,4.8-3,7.2c-0.3,2.3,0.1,3.9,1.4,4.7l3.7,2.5
	l2.3-2.2l-0.3-0.1l-2.9-2.1c-1-0.7-1.4-2.1-1.1-4s1.1-3.9,2.5-5.9l4-5.9c1.4-2.1,2.9-3.6,4.7-4.5c1.7-1,3-1.1,4-0.4l2.6,1.8l0.8-1.8
	l-3.4-2.3C702,396.1,700.3,396.3,698.3,397.4L698.3,397.4z"
          />
          <path
            fill="none"
            d="M705.8,401l-2.6-1.8c-1-0.7-2.3-0.5-4,0.4s-3.3,2.5-4.7,4.5l-4,5.9c-1.4,2.1-2.2,4-2.5,5.9s0.1,3.3,1.1,4
	l2.9,2.1l0.3,0.1C697.4,416.8,702,409.8,705.8,401L705.8,401z"
          />
          <path
            fill="none"
            d="M705.8,401l0.3,0.3c1,0.7,1.4,2.1,1.1,3.9c-0.3,1.9-1.1,3.9-2.5,5.9l-4,5.9c-1.4,2.1-2.9,3.6-4.7,4.4
	c-1.7,0.8-2.9,1.1-3.8,0.5l-2.3,2.2c1.2,0.7,2.8,0.5,4.7-0.5c2.1-1.1,3.9-2.9,5.6-5.4l4.8-7.2c1.7-2.5,2.6-4.8,3-7.2
	c0.3-2.3-0.1-3.9-1.4-4.7l-0.1-0.1L705.8,401L705.8,401z"
          />
          <path
            fill="none"
            d="M706.1,401.2l-0.3-0.3c-3.9,8.8-8.4,15.7-13.6,20.9c1,0.5,2.2,0.4,3.9-0.5s3.3-2.5,4.7-4.4l4-5.9
	c1.4-2.1,2.2-4,2.5-5.9C707.5,403.3,707.2,401.9,706.1,401.2L706.1,401.2z M703.8,424.5c-8.8,4.5-16.8,6.5-23.9,6.1
	c-4.3,1.8-8.9,2.9-13.9,3c-18.4,2.6-38.8,3.9-60.9,3.9l0.6,1.9c15.5,0.7,30.4,0.5,44.7-0.4C675.2,437.5,692.9,432.7,703.8,424.5
	L703.8,424.5z M734.6,361.1c-2.5,14.7-5.9,27.1-10.6,37C732.8,384.9,736.2,372.6,734.6,361.1L734.6,361.1z M662.4,201.2l4.7,2.3
	l2.3-2.2l2.3-2.2c-5.9-3.2-12.8-5.6-20.5-7.4c-14-3.2-31.6-5.1-52.7-5.9l-1.8,2.9c-3.6,5.9-6.3,11.6-8.3,16.9v0.1l-0.3,0.7l-0.7,1.1
	c25.2,0.6,43.9,3.9,56.1,9.9l3.4-2.1v-5.1L662.4,201.2L662.4,201.2z"
          />
          <path
            fill="none"
            d="M667.2,203.5l-4.7-2.3l-15.4,9.1v5.1l-3.4,2.1c5.6,2.8,9.9,6.1,12.8,10l31.8-9.8c-4.7-7.7-11-13.2-18.8-16.4
	L667.2,203.5L667.2,203.5z M587.4,162.7l-3.4-0.1c-8.4,0.1-14.2,1.2-17.7,3.4l-3,1.5l-1.1,0.6c-1.9,1.5-0.8,2.5,3,2.9l3.7-0.1
	c-0.1-2.2,1.8-3.4,5.9-3.7l8.8-0.4c3.4-0.1,5.2,0.8,5.6,3.2l5.5-0.3c2.9-0.6,4-1.2,3.3-2.1c-0.1-0.4-0.4-0.8-0.7-1.1L597,166
	c-0.5-0.6-1.1-1.1-1.9-1.5C593.2,163.7,590.6,163,587.4,162.7L587.4,162.7z"
          />
          <path
            fill="none"
            d="M594.7,169.6l-5.5,0.3c-7.7,2.5-14.6,2.8-20.4,1l-3.7,0.1c-3.8-0.4-5-1.2-3-2.9l1.1-0.6l-19.5,0.8l-89.8,0.7
	c4.5,5.9,7.2,10,7.6,12.4c43.3,0,84.3,1.2,123.3,3.6l10.5,0.7l1.7,0.1l0.3,0.1h1.7L605,166l-8.1,0.1l0.3,0.4
	c0.4,0.3,0.7,0.6,0.7,1.1C598.7,168.3,597.6,169,594.7,169.6L594.7,169.6z"
          />
          <path
            fill="none"
            d="M589.2,169.7c-0.4-2.3-2.2-3.3-5.6-3.2l-8.8,0.4c-4.1,0.3-6.2,1.5-5.9,3.7C574.6,172.5,581.5,172.2,589.2,169.7
	L589.2,169.7z M605,166l0.5-1.9l-10.7,0.6c0.8,0.4,1.5,1,1.9,1.5L605,166L605,166z M579.7,207.8h-1.8c-30.4-1-62-1.6-94.9-1.8h-10.3
	l0.8,2.8c36.2,0.1,70.8,0.8,104.3,1.9h1.8c3.7,0,6.5-1.1,8-3l0.7-1.1l0.3-0.7v-0.1c1.9-5.4,4.7-11,8.3-16.9l1.8-2.9h-1.7l-2.1-0.3
	l-10.5-0.7l0.6,1.6l4.4,10c-1.4,2.8-2.6,5.5-3.4,8.1v-0.1l-0.3,0.6C584.8,206.8,582.7,207.8,579.7,207.8L579.7,207.8z"
          />
          <path
            fill="none"
            d="M577.9,207.8h1.8c3,0,5.1-1,6.2-2.6l0.3-0.6v0.1c1-2.6,2.2-5.4,3.4-8.1l-4.4-10c-34.8-2.1-71.3-3.3-109.6-3.6
	c3.2,5.5,5.6,13.2,7.4,23C515.9,206.2,547.5,206.7,577.9,207.8L577.9,207.8z M597,185.8l-0.4-0.1H595"
          />
          <path
            fill="none"
            d="M585.2,186.6l-0.5-1.6c-38.9-2.3-79.9-3.6-123.3-3.6c0.1,0.7,0.1,1.2-0.1,1.5h14.3
	C513.9,183.3,550.4,184.4,585.2,186.6L585.2,186.6z M434.4,167.5h-8.8c-2.9,0.1-4.8,0.7-5.6,1.8l19.3-0.1
	C438.4,167.9,436.8,167.4,434.4,167.5z M472.8,205.9l-0.3-1c-3.6-9.4-7.4-16.8-11.3-22h-16.8c6.5,6.1,12,13.6,16.6,22.8L472.8,205.9
	L472.8,205.9z"
          />
          <path
            fill="none"
            d="M472.5,204.9l0.3,1h10.3c-1.8-9.8-4.3-17.3-7.4-23h-14.3C465.1,188.3,468.8,195.6,472.5,204.9L472.5,204.9z
	 M472.5,398.3l0.3-1h-11.7c-4.7,9.2-10.2,16.8-16.6,22.8h16.8C465.1,415.1,468.8,407.7,472.5,398.3L472.5,398.3z"
          />
          <path
            fill="none"
            d="M472.8,397.4l-0.3,1c-3.6,9.4-7.4,16.8-11.3,22h14.3c3.2-5.5,5.6-13.2,7.4-23L472.8,397.4L472.8,397.4z"
          />
          <path
            fill="none"
            d="M473.6,394.6l-0.8,2.8h10.3c32.9-0.3,64.5-0.8,94.9-1.8h1.8c3,0,5.1,1,6.2,2.6l0.3,0.5v-0.1
	c1,2.6,2.2,5.4,3.4,8.1l-4.4,10l-0.5,1.6l10.5-0.7l2.1-0.3h1.7l-1.8-2.9c-3.6-5.9-6.3-11.6-8.3-16.9v-0.1l-0.3-0.7l-0.7-1.1
	c-1.7-1.9-4.3-3-8-3h-1.8C544.5,393.9,509.8,394.5,473.6,394.6L473.6,394.6z M425.5,435.9h8.8c2.5,0,4-0.5,4.8-1.6l-19.3-0.1
	C420.8,435.2,422.6,435.8,425.5,435.9L425.5,435.9z"
          />
          <path
            fill="none"
            d="M461.2,420.3c0.1,0.4,0.3,1,0.1,1.5c43.3,0,84.3-1.2,123.3-3.6l0.5-1.6c-34.8,2.1-71.3,3.3-109.6,3.6
	L461.2,420.3L461.2,420.3z"
          />
          <path
            fill="none"
            d="M461.4,421.9c-0.4,2.3-2.9,6.5-7.6,12.4l89.8,0.7l19.5,0.8l-1.1-0.5c-1.9-1.5-0.8-2.5,3-2.9l3.7,0.1
	c5.9-1.8,12.7-1.4,20.4,1l5.5,0.3c2.9,0.6,4,1.2,3.3,2.1c-0.1,0.4-0.4,0.8-0.7,1.1l-0.3,0.4l8.1,0.1l-6.3-19.9h-1.7l-0.3,0.1
	l-1.7,0.1l-10.5,0.7C545.7,420.6,504.6,421.9,461.4,421.9L461.4,421.9z M483,397.4c-1.8,9.8-4.3,17.3-7.4,23
	c38.2-0.3,74.8-1.5,109.6-3.6l4.4-10c-1.4-2.8-2.6-5.5-3.4-8.1v0.1l-0.3-0.5c-1.1-1.6-3.2-2.6-6.2-2.6h-1.8
	C547.5,396.5,515.9,397.1,483,397.4L483,397.4z M587.7,395.7l0.7,1.1l0.3,0.7v0.1c1.9,5.4,4.7,11,8.3,16.9l1.8,2.9
	c21.2-0.7,38.7-2.6,52.7-5.9c7.7-1.8,14.6-4.3,20.5-7.4l-2.3-2.2l-2.3-2.2l-4.7,2.3l-15.3-9.1v-5.1l-3.4-2.1
	C631.4,391.9,612.7,395.2,587.7,395.7L587.7,395.7z"
          />
          <path
            fill="none"
            d="M561.9,435.3l1.1,0.6l3,1.5c3.4,2.2,9.4,3.3,17.7,3.4l3.4-0.1c3.3-0.3,5.8-1,7.6-1.9c0.8-0.4,1.5-1,1.9-1.5
	l0.3-0.4c0.4-0.3,0.7-0.5,0.7-1.1c0.7-0.8-0.4-1.6-3.3-2.1l-5.5-0.3c-0.4,2.3-2.2,3.3-5.6,3.2l-8.8-0.4c-4.1-0.3-6.2-1.5-5.9-3.7
	l-3.7-0.1C561.1,432.7,560.2,433.7,561.9,435.3L561.9,435.3z"
          />
          <path
            fill="none"
            d="M568.8,432.6c-0.1,2.2,1.8,3.4,5.9,3.7l8.8,0.4c3.4,0.1,5.2-0.8,5.6-3.2C581.5,431.2,574.6,430.8,568.8,432.6
	L568.8,432.6z M596.6,417.6l0.4-0.1l-2.1,0.3 M605.6,439.3l-0.5-1.9l-8.1-0.1c-0.5,0.6-1.1,1.1-1.9,1.5L605.6,439.3L605.6,439.3z
	 M647.1,388v5.1l15.3,9.1l4.7-2.3l2.3,2.2c7.8-3.2,14.2-8.7,18.8-16.4l-31.8-9.8c-2.9,3.9-7.2,7.3-12.8,10L647.1,388L647.1,388z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M672.3,363l14.4,4.1c3,1.8,5.6-1.5,7.8-9.8c3.2-15.3,4.4-27.1,3.7-35.4
	v-0.1c-2.1-6.9-2.9-13.6-2.3-20.2c-0.5-6.6,0.1-13.3,2.3-20.4v-0.1c0.7-8.4-0.5-20.1-3.7-35.4c-2.2-8.4-4.8-11.6-7.8-9.8l-14.4,4.1
	 M121.6,309.6V306 M122.4,310.6v-4.3 M123.1,311.4v-5.1 M124.6,312.8v-6.9 M123.9,312.1v-5.9 M122.2,304.5h1.9V304h-1.9V304.5
	L122.2,304.5z M124.1,301.6v0.4l-1.2,1.1h1.2v0.4h-1.9v-0.4l1.2-1.1h-1.2v-0.4H124.1z M122.2,301.1h1.9v-0.5h-1.9V301.1z
	 M124.1,297.6h-1.9v0.5h1.1l-1.1,0.5v0.1l1.1,0.5h-1.1v0.5h1.9v-0.5l-1.2-0.7l1.2-0.7V297.6L124.1,297.6z M125.9,298.1
	c-0.8-0.8-1.7-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.8,1.2c-0.8,0.8-1.1,1.9-1.1,3.2s0.4,2.2,1.1,3.2c0.8,0.8,1.8,1.2,2.8,1.2
	c1.1,0,2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.9,1.2-3.2C127.1,299.8,126.7,298.9,125.9,298.1L125.9,298.1z M125.7,298.3
	c-0.7-0.8-1.5-1.2-2.6-1.2c-1,0-1.8,0.4-2.5,1.2c-0.7,0.8-1.1,1.8-1.1,2.9s0.4,2.1,1.1,2.9c0.7,0.8,1.5,1.2,2.5,1.2s1.9-0.4,2.6-1.2
	c0.7-0.8,1.1-1.8,1.1-2.9C126.7,300,126.4,299,125.7,298.3L125.7,298.3z M122.4,291.7v4.3 M123.1,290.9v5.1 M123.9,290.2v5.9
	 M124.6,289.5v6.7 M121.6,292.7v3.6 M120.8,293.9v2.8 M357.4,375.4l2.1,3.3 M359.5,378.7l-0.4-0.8l-1.6-2.5 M342.9,411.3l-5.6,6.5
	 M357.4,227.9l2.1-3.3 M357.4,227.9l1.6-2.3l0.4-1 M357.4,375.4c-1.9-3.2-3.9-6.9-5.6-10.9c-3-6.7-5.9-14.4-8.7-23.2
	c-3.7-13.3-5.5-26.5-5.2-39.6c-0.3-13.1,1.5-26.3,5.2-39.6c2.8-8.8,5.6-16.6,8.7-23.2c1.8-4,3.7-7.6,5.6-10.9 M337.3,185.5l5.6,6.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M359.5,224.6c3.2-6.2,0.8-10.6-6.9-13.2l-32.6-8.3c-3.6-0.8-7-1-10.2-0.6
	c-6.1,1-11.3,4.5-15.5,10.5c-1.1,1.8-2.2,3.4-3.3,5.2c-0.8,1.4-1.7,2.8-2.3,4.1c-2.9,5.4-5.6,11.1-8,17.1c-1,2.5-1.8,5-2.8,7.4
	c-2.1,6.2-3.7,12.5-5.1,19.3c-0.7,3.4-1.4,6.9-1.9,10.3c-0.5,3.3-1,6.6-1.4,10l-0.4,3.7c-0.5,5.1-0.8,10.2-0.5,15.3
	c0.3,3.9,0.5,7.8,1,11.7c0.4,3.2,0.8,6.3,1.2,9.4c0.5,3.6,1.2,7,1.9,10.3c1.4,6.7,3.2,13.1,5.1,19.3c0.8,2.5,1.8,5,2.6,7.4
	c2.3,5.9,5,11.7,8,17.1l2.3,4.1c1.1,1.8,2.2,3.6,3.3,5.2c4.3,6.1,9.5,9.5,15.5,10.5c3.2,0.5,6.6,0.4,10.2-0.5l32.6-8.3
	c7.7-2.8,10-7.2,6.9-13.2 M120.8,308.4v-2.8 M448.9,169l-0.1-0.3c-0.3-1.5-2.2-2.1-5.5-1.8c-1.1-2.5-3.9-3.7-8.3-3.7h-4.3
	c-6.1,0.1-10.6,1.1-13.5,2.8l-4.3,1.9c-0.7,0.4-1,1-1,1.2 M473.6,208.6c-14.2-0.1-28.5-0.1-42.9,0c-15.4,0-31.1,0.3-47,0.4l-0.5-0.1
	l-0.1-0.1 M411.9,169.3c0,0.8,1.2,1.4,3.9,1.6h3.7 M420,169.3c-0.4,0.6-0.5,1.1-0.5,1.8 M350.1,146.3l-1-0.3
	c-4.8-0.8-9.2,0.4-13.3,3.9c-2.9,2.5-5.2,5.2-6.9,8.1c-1.4,2.3-2.3,4.8-2.9,7.4c-0.4,1.5-0.3,3,0.1,4.3 M344.9,169.8l7.3-20.1
	c0.7-1.6,0-2.8-2.1-3.4 M326.3,170v-0.3 M326.2,170h0.1 M326.3,169.7l-0.1,0.3 M326.2,170l-24.8,0.1 M323,174.2
	c-1.1,1.6-0.7,3.2,1.4,4.3c3.9,1.9,7.6,2.5,10.9,1.6c2.8-0.6,4.5-1.6,5.5-3.3 M461.4,181.4c-10,0-20.4,0-30.7,0.1
	c-34.7,0.4-70.6,1.5-107.9,3.2l-5.6,0.3 M317.1,185.1l4.8,1.1 M306.5,186.1l-6.6-5.6c-2.1-1.4-3-3.2-2.9-5.5c0-0.1-0.1-0.3-0.4-0.4
	l-15.8-6.1 M299.5,187.3l-1.5,0.3c-2.2,0.6-4.3,1-6.5,1.6 M303.9,193.2l-2.1-0.4c1.9-3,4.7-5.4,8.1-7 M309.9,185.7h-0.1l-3.3,0.4
	 M306.5,186.1l-7,1.2 M374,213.4c-22-8.3-43.5-14.6-64.1-19.1l-5.9-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M303.9,410.2c-2.9-2.9-5.6-6.1-8.3-9.2c-4.1-5.1-7.8-10.3-11.3-16.1
	l-2.3-4.1c-2.9-5.4-5.6-11.1-8-17.1c-1-2.5-1.8-5-2.8-7.4c-2.1-6.2-3.7-12.5-5.1-19.3c-0.7-3.4-1.4-6.9-1.9-10.3
	c-0.5-3-1-6.2-1.2-9.4l-0.7-7.7c-0.3-2.6-0.3-5.2-0.3-8c0-5,0.4-10,0.8-15c0.4-3.4,0.8-6.7,1.4-10c0.5-3.6,1.2-7,1.9-10.3
	c1.4-6.7,3.2-13.1,5.1-19.3c0.8-2.5,1.8-4.9,2.8-7.4c2.3-5.9,5-11.7,8-17.1c0.8-1.4,1.5-2.8,2.3-4.1c3.3-5.6,7.2-11,11.3-16.1
	c2.6-3.2,5.4-6.3,8.3-9.2 M301.4,170.1c2.8,4.1,5.5,7.6,8.4,10c2.3,2.1,4.7,3.7,7.3,4.8 M309.9,185.7l7.2-0.6 M329.2,173.4
	c0.5,0.4,1.1,0.8,1.8,1.1l6.1,1.8c0.8,0.3,1.5,0.4,2.1,0.6 M339.2,176.9l1.5,0.1 M340.7,177c-1.4,1.5-3.6,2.3-6.5,2.5
	c-2.8,0-5.1-0.7-7.3-1.8 M329.2,173.4l-2.1,4.1 M340.7,177c1-0.1,1.6-0.6,1.9-1.2l2.2-5.9 M350.1,146.3l-10.9,30.5 M321.9,186.2
	l15.4-0.7c32.3-1.4,63.4-2.2,93.4-2.5l13.8-0.1 M326.3,170c0.5,1.4,1.5,2.5,2.9,3.4 M327.1,177.5c-1.5-0.8-2.9-1.9-4.1-3.3
	 M323,174.2l3.2-4.3 M321.9,186.2c7.3,1.8,14.2,3.7,20.9,5.8c14.9,4.4,28.5,9.1,41,14.3c15.8-0.3,31.4-0.4,46.8-0.4H461 M374,213.4
	c-2.2,1.8-4.4,3.9-6.5,6.5c-1.4,1.6-2.6,3.4-4,5.5c-2.5,3.9-4.8,8.3-7.2,13.2c-3,6.7-5.9,14.4-8.7,23.2c-3.7,13.3-5.5,26.5-5.2,39.6
	c-0.3,13.1,1.5,26.4,5.2,39.8c2.8,8.8,5.6,16.6,8.7,23.2c2.3,5.1,4.7,9.5,7.2,13.2c1.2,1.9,2.6,3.9,4,5.5c2.1,2.6,4.3,4.8,6.5,6.5
	 M611.3,230.9l-217.2-3.9c-0.8,0-1.6-0.3-2.2-1l-0.5-0.7c-0.3-0.4-0.4-1-0.4-1.5c0-0.8,0.3-1.6,1-2.2c0.5-0.6,1.4-1,2.2-1l211.3,3.9
	h11.8c0.8,0,1.7,0.3,2.2,1c0.6,0.6,1,1.4,1,2.2s-0.3,1.7-1,2.2c-0.5,0.6-1.4,1-2.2,1L611.3,230.9L611.3,230.9z M382.8,208.9
	c-3,0.8-6.1,2.5-8.8,4.7 M382.8,208.9c-21.6-8.9-45.8-16.6-73-23.1 M411.9,169.3l-67,0.6 M280.8,168.6h-0.4 M301.4,170.1h-6.5
	c-1.2-0.4-2.5-0.8-3.9-1.1 M280.8,168.6l10.3,0.4 M277.5,168.5h2.6 M261.6,164.9c-25-2.1-53.4-1.2-85.2,2.6
	c-10.3,1.8-19.3,5.9-26.7,12.4 M251.7,167.8l10.9,0.1 M262.6,167.9l17.7,6.3c1.6,0.6,3.2,1,4.7,1.2h6.1c3-0.3,4-0.7,2.6-1.4h-0.1
	l-16-5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M291.6,189.2l-2.6,0.8c-8.5,3.6-15.3,9.6-20.2,18.2
	C256,228.4,248,255,245,288.1c-0.3,2.8-0.4,5.5-0.4,8.3c-0.3,7,0,13.9,0.7,20.8c3.2,32.2,11,58.2,23.7,78c5,8.5,11.7,14.6,20.2,18.2
	l2.6,0.8 M291.8,189.4l-0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M291.8,189.4c-8.5,3.6-15.3,9.8-20.2,18.3c-12.8,20.4-20.8,47-23.8,80.3
	c-0.3,2.8-0.4,5.5-0.6,8.3c-0.3,7,0,13.9,0.7,20.8c3.2,32.5,11,58.6,23.7,78.5c5,8.5,11.7,14.7,20.2,18.3 M264,165.2l-2.3-0.3
	 M277.5,168.5l-14.9-0.6 M291.6,189.2c-10.2-9.9-23.5-17.1-39.9-21.5 M291.1,169c-8.9-2.5-18.8-3.9-29.4-4.1 M187.1,181
	c-1.6-2.5-5.6-3.7-12.1-3.6h-1.8c-8.3,0.4-15.8,4.7-22.6,12.8c-5.9,6.6-11,14.2-15,22.8c-1.4,3.3-1.2,6.1,0.6,8.4
	c0.6,0.4,1.4,0.7,2.5,0.8c1.5,0.1,3.4-0.1,5.8-0.8c6.6-1.8,14.4-6.6,23.5-14.4c7.2-5.4,12.9-11,17.3-16.9
	C187.8,186.2,188.3,183.2,187.1,181L187.1,181z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M169.6,180.3c-6.5,0.7-12.5,4.5-18.3,11.8c-5,5.8-9.1,12.2-12.4,19.3
	c-0.6,1.1-0.8,2.2-0.8,3.2c0,1.2,0.3,2.2,1.1,3.2c0.6,0.4,1.4,0.6,2.3,0.4c1-0.1,2.3-0.4,3.9-1c4.8-1.8,10.7-5.9,17.9-12.4
	c5.6-4.5,10.3-9.4,13.9-14.2c1.4-2.2,2.3-4,2.5-5.5c0.1-0.8,0-1.5-0.1-2.2c-0.8-2.1-3.6-2.9-8-2.8L169.6,180.3L169.6,180.3z
	 M236.7,296.1l0.1,5.1l-0.3,6.5c-0.1,2.2-0.4,4.4-0.7,6.7c-1.2,8.9-5.6,13.9-13.3,14.9c-23.1-1.6-42.1-4-56.8-7
	c-9.9-3.2-14.7-9.9-14.6-19.9v-2.5c0.3-9.2,5.2-15.4,14.4-18.4c14.9-3,33.8-5.4,56.8-7c7.7,1.1,12.1,6.1,13.3,14.9
	C236.5,291.5,236.6,293.8,236.7,296.1L236.7,296.1z M187.1,422.3c-1.6,2.5-5.6,3.7-12.1,3.6h-1.8c-8.3-0.4-15.8-4.7-22.6-12.8
	c-5.9-6.6-11-14.2-15-22.8c-1.4-3.3-1.2-6.1,0.6-8.4c0.6-0.4,1.4-0.7,2.5-0.8c1.5-0.1,3.4,0.1,5.8,0.8c6.6,1.8,14.4,6.6,23.5,14.4
	c7.2,5.4,12.9,11,17.3,16.9C187.8,417,188.3,420.1,187.1,422.3L187.1,422.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M179.5,420.3c-0.8,2.1-3.6,2.9-8,2.8l-1.9-0.1
	c-6.5-0.7-12.5-4.5-18.3-11.8c-5-5.8-9.1-12.2-12.4-19.3c-0.6-1.1-0.8-2.2-0.8-3.2c0-1.2,0.3-2.2,1.1-3.2c0.6-0.4,1.4-0.5,2.3-0.4
	c1,0.1,2.3,0.4,3.9,1c4.8,1.8,10.7,5.9,17.9,12.4c5.6,4.5,10.3,9.4,13.9,14.2c1.4,2.2,2.3,4,2.5,5.5
	C179.7,419,179.7,419.7,179.5,420.3L179.5,420.3z M149.8,423.5c7.4,6.5,16.2,10.6,26.7,12.4c31.8,3.9,60.3,4.7,85.2,2.6h0.1
	 M251.7,435.5l10.9-0.1 M291.8,413.9l-0.1,0.1 M262.6,435.3l17.7-6.3c1.6-0.5,3.2-1,4.7-1.2h6.1c3,0.3,4,0.7,2.6,1.4h-0.1l-16,5.6
	 M280.4,434.8h0.4 M291.1,434.2l3.9-1.1h6.5 M280.8,434.8l10.3-0.5 M277.5,434.9l2.6-0.1 M264,438.2l-2.3,0.1 M262.6,435.3l14.9-0.4
	 M291.6,414c-10.2,9.9-23.5,17.1-39.9,21.5 M261.6,438.4c10.6-0.3,20.4-1.7,29.4-4.1 M317.1,418.3l4.8-1.2 M317.1,418.3l5.6,0.3
	c37.3,1.8,73.3,2.9,107.9,3.2c10.3,0.1,20.6,0.1,30.7,0.1 M374,389.8c-22,8.3-43.5,14.6-64.1,19.1l-5.9,1.2 M291.6,414
	c2.2,0.5,4.3,1.1,6.5,1.6l1.5,0.3 M303.9,410.2l-2.1,0.4c1.9,3,4.7,5.4,8.1,7 M309.9,417.6h-0.1l-3.3-0.4 M299.5,415.9l7,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306.5,417.2l-6.6,5.6c-2.1,1.4-3,3.2-2.9,5.5c0,0.1-0.1,0.3-0.4,0.4
	l-15.8,6.1 M301.4,433.1c2.8-4.1,5.5-7.6,8.4-10c2.3-2.1,4.7-3.7,7.3-4.8 M340.7,426.4c-1-1.6-2.9-2.8-5.5-3.3
	c-3.3-0.7-7-0.1-10.9,1.6c-2.1,1.2-2.5,2.6-1.4,4.3 M326.2,433.3h0.1 M326.3,433.3v0.3 M326.2,433.3l0.1,0.3 M326.3,433.6
	c-0.4,1.2-0.5,2.8-0.1,4.3c0.5,2.6,1.5,5.1,2.9,7.4c1.6,2.9,4,5.5,6.9,8.1c4.1,3.4,8.5,4.8,13.3,3.9l1-0.3 M323,429l3.2,4.3
	 M309.9,417.6l7.2,0.7 M301.4,433.1l24.8,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M339.2,426.4c-0.7,0.1-1.4,0.3-2.1,0.5l-6.1,1.8c-0.7,0.3-1.2,0.7-1.8,1.1
	 M339.2,426.4h1.5 M329.2,429.8l-2.1-4.1 M340.7,426.4c-1.4-1.5-3.6-2.3-6.5-2.5c-2.8,0-5.1,0.7-7.3,1.8 M344.9,433.4l-2.2-5.9
	c-0.3-0.8-1-1.2-1.9-1.2 M329.2,429.8c-1.4,1-2.3,2.2-2.9,3.4 M321.9,417c7.3-1.8,14.2-3.7,20.9-5.8c14.9-4.4,28.5-9.1,41-14.3
	c15.8,0.3,31.4,0.4,46.8,0.4H461 M327.1,425.7c-1.5,0.8-2.9,1.9-4.1,3.3 M321.9,417l15.4,0.7c32.3,1.4,63.4,2.2,93.4,2.5l13.8,0.1
	 M611.3,372.5l-217.2,3.9c-0.8,0-1.6,0.3-2.2,1l-0.5,0.7c-0.3,0.4-0.4,1-0.4,1.5c0,0.8,0.3,1.7,1,2.2c0.5,0.6,1.4,1,2.2,1l211.3-3.9
	h11.8c0.8,0,1.7-0.3,2.2-1c0.6-0.6,1-1.4,1-2.2c0-0.8-0.3-1.6-1-2.2c-0.5-0.5-1.4-1-2.2-1L611.3,372.5L611.3,372.5z M473.6,394.6
	c-14.2,0.1-28.5,0.1-42.9,0c-15.4,0-31.1-0.3-47-0.4l-0.5,0.1l-0.1,0.1 M382.8,394.5c-3-0.8-6.1-2.5-8.8-4.7 M411.9,434
	c0-0.8,1.2-1.4,3.9-1.6h3.7 M411.9,434c0,0.4,0.3,0.8,1,1.2l4.3,1.9c2.9,1.6,7.4,2.6,13.5,2.8h4.3c4.4,0,7.2-1.2,8.3-3.7
	c3.3,0.3,5.1-0.3,5.5-1.8l0.1-0.3 M419.4,432.3c0,0.7,0.1,1.2,0.5,1.8 M382.8,394.5c-21.6,8.9-45.8,16.6-73,23.1 M344.9,433.4
	l67,0.5 M299.5,415.9c-2.6-0.5-5.2-1.4-7.7-2.1 M299.5,187.3c-2.6,0.6-5.2,1.2-7.7,2.1 M149.8,179.7c-6.2,5-11.7,10-16.5,15.3
	c-4.1,4.4-7.8,8.9-11,13.6l-2.3,3.4c-1.1,1.8-2.2,3.6-3.2,5.5c-1,1.8-1.9,3.6-2.8,5.5c-1.1,2.3-1.9,4.8-2.8,7.4
	c-0.8,2.5-1.4,4.9-1.9,7.4l-0.6,2.5c-4,19.3-5.9,39.3-5.8,60.4v0.7c-0.1,13.1,0.6,25.7,1.9,38.1c1,7.8,2.2,15.5,3.7,23.2l0.6,2.5
	c0.6,2.5,1.2,5,1.9,7.4c0.8,2.5,1.7,5,2.8,7.4c0.8,1.8,1.8,3.7,2.8,5.5c1,1.6,1.9,3.4,2.9,5.1l3,4.5c3,4.4,6.6,8.7,10.5,12.9
	c4.8,5.2,10.3,10.3,16.5,15.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M162.9,176.7c-7.6,7-12.1,11.6-13.8,13.5c-5.8,6.5-10.6,13.8-14.6,22
	c-2.3,4.4-4.8,7.2-7.6,8.4c-1.8,5.4-3.3,11.3-4.7,17.5l-0.6,2.3c-1.2,6.1-2.3,12.4-3.3,19.3c-2.2,15.5-3,31.1-2.8,46.8
	c0.3,15.4,1.7,30.8,4.1,46.1c1.9,11.1,4.3,21.2,7.2,30.1c2.8,1.2,5.4,4.1,7.6,8.4c4,8.3,8.8,15.5,14.6,22c1.6,1.9,6.2,6.5,13.8,13.5
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M125.5,296.7v-8l-0.8,0.7l-0.8,0.7l-0.7,0.7l-0.7,0.8l-0.8,1l-0.8,1.2
	L120,295v2.2 M125.5,296.7l-0.8-0.4l-0.8-0.3h-1.4l-0.8,0.1l-0.8,0.4c-0.3,0.1-0.6,0.4-0.8,0.7 M125.5,305.5l0.8-0.8
	c1-1,1.4-2.2,1.4-3.6c0-1.4-0.4-2.6-1.4-3.6l-0.8-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M120,304.9l0.8,0.7l0.8,0.4l0.8,0.1h0.7l0.7-0.1l0.8-0.3
	c0.3-0.1,0.6-0.3,0.8-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M120,297.4l-0.1,0.1c-1,1-1.4,2.2-1.4,3.6c0,1.4,0.4,2.6,1.4,3.6l0.1,0.1
	 M120,304.9v2.2l0.8,1.4l0.8,1.2l0.8,1l0.7,0.8l0.7,0.7l0.8,0.7l0.8,0.7v-8 M350.1,456.9c2.1-0.7,2.8-1.8,2.1-3.4l-7.3-20.1
	 M251.7,167.8c-43.6-0.3-77.6,3.7-101.9,12 M258.9,209.1c-54.3,11.6-95.6,18.7-123.9,21.2 M258.9,394.2
	c-54.3-11.6-95.6-18.7-123.9-21.2 M350.1,456.9l-10.9-30.5 M251.7,435.5c-43.6,0.3-77.6-3.7-101.9-12 M692.2,181.3l-0.3,0.1
	l-2.9,2.1c-1,0.7-1.4,2.1-1.1,4s1.1,3.9,2.5,5.9l4,5.9c1.4,2.1,2.9,3.6,4.7,4.5c1.7,1,3,1.1,4,0.4l2.6-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M705.8,202.3l0.3-0.3c1-0.7,1.4-2.1,1.1-3.9c-0.3-1.9-1.1-3.9-2.5-5.9
	l-4-5.9c-1.4-2.1-2.9-3.6-4.7-4.4c-1.7-0.8-2.9-1.1-3.8-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.9,179.1c1.2-0.7,2.8-0.6,4.7,0.6c2.1,1.1,3.9,2.9,5.6,5.4l4.8,7.2
	c1.7,2.5,2.6,4.8,3,7.2c0.3,2.3-0.1,3.9-1.4,4.7l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M706.5,204.1l-3.4,2.3c-1.2,0.8-2.9,0.7-4.8-0.4c-2.1-1.1-3.9-2.9-5.6-5.4
	l-4.8-7.2c-1.7-2.5-2.6-4.8-3-7.2c-0.3-2.3,0.1-3.9,1.4-4.7l3.7-2.5 M689.9,179.1c-3.2-2.8-6.5-4.8-10-6.3 M706.5,204.1l-0.7-1.8
	 M705.8,202.3c-3.9-8.8-8.4-15.7-13.6-20.9 M679.8,172.7c7.2-0.4,15.1,1.6,23.9,6.1 M692.2,181.3l-2.3-2.2 M724.1,205.3l-0.1-0.1
	c-3.6-7.8-8-14-12.9-18.8c-1.9-2.8-4.3-5.2-7.3-7.4 M709.3,392c1.2-3.4,2.3-7.2,3.3-11c5.1-20.1,8.1-46.5,8.8-79.2
	c-0.8-32.7-3.7-59.2-8.8-79.2c-1-3.9-2.1-7.6-3.3-11 M688.4,217.7l3.2-1 M691.5,216.8c3,4.7,5.2,9.9,6.6,15.8
	c3.3,12.8,5.6,27,6.7,42.5l0.4,7l0.5,20.1l-0.5,19.5l-0.4,6.7c-1.1,15.5-3.3,29.7-6.7,42.5c-1.4,5.8-3.6,11.1-6.6,15.8 M656.6,227.5
	c6.9,0.8,12.1,5.1,15.7,12.8c0.7,1.5,1.4,3,1.9,4.8c3.8,9.4,6.5,20.5,7.8,33.4l0.3,2.8l0.8,20.4l-0.8,20.4l-0.3,2.6
	c-1.4,12.9-4,24.1-7.8,33.4c-0.5,1.8-1.2,3.3-1.9,4.8c-3.6,7.7-8.9,12-15.7,12.8 M691.5,216.8l17.7-5.4 M734.6,361.1
	c1.4-8.8,2.5-18.6,3.2-29.2c1-8.8,1.4-18.8,1.2-30.1c0.1-11.4-0.3-21.6-1.2-30.4c-0.7-10.6-1.7-20.2-3.2-29.2 M734.6,242.3
	c-2.5-14.7-5.9-27.1-10.6-37 M709.3,211.4c-0.8-2.6-1.8-5-2.8-7.3 M734.6,242.3c1.7-11.6-1.9-23.9-10.6-37 M703.8,424.5
	c2.9-2.2,5.4-4.7,7.3-7.4c5-4.8,9.4-11.1,12.9-18.8l0.1-0.1 M691.5,386.5l-3.2-1 M706.5,399.3l-3.4-2.3c-1.2-0.8-2.9-0.7-4.8,0.4
	c-2.1,1.1-3.9,2.9-5.6,5.4l-4.8,7c-1.7,2.5-2.6,4.8-3,7.2c-0.3,2.3,0.1,3.9,1.4,4.7l3.7,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M705.8,401l-2.6-1.8c-1-0.7-2.3-0.5-4,0.4s-3.3,2.5-4.7,4.5l-4,5.9
	c-1.4,2.1-2.2,4-2.5,5.9s0.1,3.3,1.1,4l2.9,2.1l0.3,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M692.2,422c1,0.5,2.2,0.4,3.9-0.5s3.3-2.5,4.7-4.4l4-5.9
	c1.4-2.1,2.2-4,2.5-5.9s-0.1-3.2-1.1-3.9l-0.3-0.3 M705.8,401l0.7-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.9,424.2c1.2,0.7,2.8,0.5,4.7-0.6c2.1-1.1,3.9-2.9,5.6-5.4l4.8-7.2
	c1.7-2.5,2.6-4.8,3-7.2c0.3-2.3-0.1-3.9-1.4-4.7l-0.1-0.1 M679.8,430.5c3.6-1.5,6.9-3.6,10-6.3 M689.9,424.2l2.3-2.2 M703.8,424.5
	c-8.8,4.5-16.8,6.5-23.9,6.1 M691.5,386.5l17.7,5.5 M692.2,422c5.2-5.2,9.8-12.2,13.6-20.9 M706.5,399.3c1-2.3,1.9-4.7,2.8-7.3
	 M734.6,361.1c-2.5,14.7-5.9,27.1-10.6,37 M724.1,398.1c8.7-13.1,12.2-25.5,10.6-37 M669.5,201.3l-2.3,2.2l-4.8-2.3l-15.3,9.1v5.1
	l-3.4,2.1 M669.5,201.3l2.3-2.1 M598.7,185.9c21.2,0.7,38.7,2.8,52.7,5.9c7.7,1.8,14.6,4.3,20.5,7.4 M594.8,164.5
	c-1.8-1-4.3-1.5-7.6-1.9l-3.4-0.1c-8.4,0.1-14.2,1.2-17.7,3.4l-3,1.5 M596.9,166l0.3,0.4c0.4,0.3,0.7,0.6,0.7,1.1
	c0.7,0.8-0.4,1.7-3.3,2.1l-5.5,0.3 M589.2,169.7c-0.4-2.3-2.2-3.3-5.6-3.2l-8.8,0.4c-4.1,0.3-6.2,1.5-5.9,3.7 M605.6,163.9L605,166
	 M605,166h-8.1 M594.8,164.5c0.8,0.4,1.5,1,1.9,1.5 M605.6,163.9l-10.7,0.6 M568.8,170.7l-3.7,0.1c-3.8-0.4-5-1.2-3-2.9l1.1-0.6
	 M483,205.9c32.9,0.3,64.5,0.8,94.9,1.8h1.8c3,0,5.1-1,6.2-2.6l0.3-0.6v0.1c1-2.6,2.2-5.4,3.4-8.1l-4.4-10 M597,185.8l-0.4-0.1H595
	 M597,185.8l1.7,0.1 M587.7,207.5l0.7-1.1l0.3-0.7v-0.1c1.9-5.4,4.7-11,8.3-16.9l1.8-2.9 M585.2,186.6l-0.7-1.6 M584.5,185l10.5,0.7
	 M595,185.7l2.1,0.1 M605,166l-6.3,19.9 M568.8,170.7c5.9,1.8,12.7,1.4,20.4-1 M611.3,230.9h-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M473.6,208.6c36.2,0.1,70.8,0.8,104.3,1.9h1.8c3.7,0,6.5-1.1,8-3
	 M656.6,227.5c-9.4,2.6-14.3,7.4-14.9,14.4c-1.4,9.4-2.3,19.1-3,29.2l-0.6,11.3c-0.3,6.3-0.4,12.7-0.4,19c0,6.5,0.1,12.8,0.4,19.1
	l0.5,11.6c0.7,10,1.7,19.8,3,29.2c0.5,7,5.5,11.8,14.9,14.4 M643.7,217.4c5.6,2.8,9.9,6.1,12.8,10 M679.8,172.7
	c-4.3-1.8-8.9-2.9-13.9-3c-18.4-2.6-38.8-3.9-60.9-3.9 M587.7,207.5c25.2,0.6,43.9,3.9,56.1,9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M703.8,178.9c-10.9-8.3-28.8-13.1-53.5-14.6c-14.3-0.8-29.2-1-44.7-0.4
	 M439.2,169.2c-0.8-1.1-2.5-1.6-4.8-1.6h-8.8c-2.9,0.1-4.8,0.7-5.6,1.8 M439.9,170.8h5.5c2.6-0.4,3.9-1,3.4-1.8 M419.4,170.9
	c3.4,1.1,7.2,1.6,11.1,1.4c2.9-0.1,5.9-0.7,9.2-1.6 M461.4,181.4c0.1,0.7,0.1,1.2-0.1,1.5 M461.2,182.9c3.9,5.2,7.7,12.7,11.3,22
	l0.3,1 M472.8,205.9l0.8,2.8 M472.8,205.9H483 M461.2,182.9h14.3 M483,205.9c-1.8-9.8-4.3-17.3-7.4-23 M461,205.9h11.8 M461,205.9
	c-4.7-9.2-10.2-16.8-16.6-22.8 M461.2,182.9l-16.9,0.1 M453.8,169c4.5,5.9,7.2,10,7.6,12.4 M439.9,170.8c-0.1-0.7-0.3-1.2-0.7-1.6
	 M453.8,169h-5 M563.2,167.5l-19.5,0.8l-89.8,0.7 M472.8,397.4l-0.3,1c-3.6,9.4-7.4,16.8-11.3,22 M472.8,397.4l0.8-2.8 M483,397.4
	h-10.2 M461,397.5l11.8-0.1 M420,434.1c0.8,1.1,2.8,1.6,5.6,1.8h8.8c2.5,0,4-0.6,4.8-1.6 M439.9,432.5h5.5c2.6,0.4,3.9,1,3.4,1.8
	 M439.2,434.2c0.3-0.4,0.5-1.1,0.7-1.7 M419.4,432.3c3.4-1.1,7.2-1.7,11.1-1.4c2.9,0.1,5.9,0.7,9.2,1.6 M461.2,420.3
	c0.1,0.4,0.3,1,0.1,1.5 M475.6,420.3h-14.3 M461.4,421.9c-0.4,2.3-2.9,6.5-7.6,12.4 M483,397.4c-1.8,9.8-4.3,17.3-7.4,23
	 M461.2,420.3h-16.9 M461,397.5c-4.7,9.2-10.2,16.8-16.6,22.8 M448.9,434.2h5 M453.8,434.2l89.8,0.7l19.5,1 M613.4,300.4
	c-0.3,0.3-0.4,0.5-0.4,1c0,0.4,0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4s0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-1-0.4
	S613.7,300.1,613.4,300.4L613.4,300.4z M614.5,297.4c-1.4-1.1-3.2-1.6-5.1-1.6h-0.3c-3,0-5.5,0.5-7.7,1.6c-2.2,1.1-3.3,2.3-3.3,3.9
	s1.1,2.8,3.3,3.9c2.2,1.1,4.7,1.7,7.7,1.7h0.3c1.9,0,3.7-0.5,5.1-1.7c1.4-1.1,2.1-2.3,2.1-3.9S615.9,298.3,614.5,297.4L614.5,297.4z
	 M611.3,372.5h-5.8 M587.7,395.7l0.7,1.1l0.3,0.7v0.1c1.9,5.4,4.7,11,8.3,16.9l1.8,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M585.2,416.8l4.4-10c-1.4-2.8-2.6-5.5-3.4-8.1v0.1l-0.3-0.5
	c-1.1-1.6-3.2-2.6-6.2-2.6h-1.8c-30.4,1-62,1.6-94.9,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M587.7,395.7c-1.7-1.9-4.3-3-8-3h-1.8c-33.3,1.1-68.1,1.6-104.3,1.9
	 M568.8,432.6l-3.7-0.1c-3.8,0.4-5,1.2-3,2.9l1.1,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M589.2,433.6c-0.4,2.3-2.2,3.3-5.6,3.2l-8.8-0.4c-4.1-0.3-6.2-1.5-5.9-3.7
	 M563.2,435.9l3,1.5c3.4,2.2,9.4,3.3,17.7,3.4l3.4-0.1c3.3-0.3,5.8-1,7.6-1.9 M595,417.7l1.7-0.1l0.4-0.1 M598.7,417.5H597
	 M584.5,418.3l0.7-1.5 M595,417.7l-10.5,0.5 M596.9,437.3l0.3-0.4c0.4-0.3,0.7-0.5,0.7-1.1c0.7-0.8-0.4-1.6-3.3-2.1l-5.5-0.3
	 M605.6,439.3l-0.5-1.9 M605,437.4l-8.1-0.1 M594.8,438.8c0.8-0.4,1.5-1,1.9-1.5 M597,417.5l-2.1,0.3 M605,437.4l-6.3-19.9
	 M594.8,438.8l10.7,0.6 M589.2,433.6c-7.7-2.5-14.6-2.8-20.4-1 M669.5,401.9l-2.3-2.2l-4.8,2.3l-15.3-8.9V388l-3.4-2.2 M598.7,417.5
	c21.2-0.7,38.7-2.6,52.7-5.9c7.7-1.8,14.6-4.3,20.5-7.4 M671.9,404l-2.3-2.1 M656.6,375.8c-2.9,3.9-7.2,7.3-12.8,10 M679.8,430.5
	c-4.3,1.8-8.9,2.9-13.9,3c-18.4,2.6-38.8,3.9-60.9,3.9 M643.7,385.8c-12.2,6.1-31,9.2-56.1,9.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M605.6,439.3c15.5,0.7,30.4,0.5,44.7-0.4c24.9-1.5,42.6-6.3,53.5-14.6
	 M461.4,421.9c43.3,0,84.3-1.2,123.3-3.6 M585.2,416.8c-34.8,2.1-71.3,3.3-109.6,3.6 M584.5,185c-38.9-2.3-79.9-3.6-123.3-3.6
	 M475.6,183.1c38.2,0.3,74.8,1.5,109.6,3.6 M671.9,199.3c8.4,4.5,15,10.3,19.7,17.5 M688.4,385.5c-4.7,7.7-11,13.2-18.8,16.4
	 M688.4,217.7c-4.7-7.7-11-13.2-18.8-16.4 M671.9,404c8.4-4.5,15-10.3,19.7-17.5 M656.6,227.5l31.8-9.8 M688.4,385.5l-31.8-9.8
	 M420,169.3l19.3-0.1 M420,434.1l19.3,0.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M291.8,413.9c0,0-5.6,5.8-15,11.4
		c0,0-7.6,4.8-19.5,8.7l-5.4,1.6c0,0-18.4,0.1-28.9-0.6c0,0-18.3-0.7-30.5-2.5c0,0-18.3-2.2-28.5-5.1l-8.5-8.1l0.7-0.8
		c0,0,7.2,6.2,15,7c0,0,10.6,1.2,14.6-1.9c0,0,5-2.5-0.6-10.7c0,0-5-7.4-17.1-16.5c0,0-8.4-7.7-16.6-11.8c0,0-10.5-5.5-15.1-2.8
		c0,0-3.2,3.4-0.4,8.7l-1.1,0.6c0,0-2.8-6.1-7.6-8.4c0,0-5.5-15.8-8.5-41c0,0-2.6-20.4-2.6-40.2c0,0-0.6-18.6,2.8-42
		c0,0,2.6-21.5,8.4-39.1c0,0,3.9-1.5,7.4-8.1l1.1,0.4c0,0-2.5,4.8,0.6,8.4c0,0,3.2,3,14.7-2.5c0,0,8.7-4.4,17.1-12
		c0,0,10.7-7.8,17.3-16.9c0,0,4-5.4,1.8-9.1c0,0-2.1-3.6-10-3.6c0,0-6.9-0.4-12.5,2.1c0,0-5,2.2-8,4.8l-0.6,0.4l-0.8-0.7
		c0,0,8.1-7.8,8.9-8.4c0,0,13.3-3.3,33.2-5.5c0,0,18.3-1.8,30.8-2.1c0,0,16-0.4,23.7-0.3c0,0,9.8,2.3,22.3,8.7
		c0,0,8.1,3.7,17.7,12.9c0,0-12.5,1.8-23,18.8c0,0-11,15.4-18,44.4c0,0-6.5,25.6-6.3,49.1c0,0-0.1,22.7,6.6,50.5
		c0,0,5.1,23,17.7,43.1C268.8,395,277.1,410.6,291.8,413.9z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M303.9,410.2
		c0,0-18.4-17.1-29-44.4c0,0-7.2-17.1-10.5-39.1c0,0-2.6-15.8-2.2-25c0,0-0.1-20.8,6.5-45.4c0,0,5-21.3,17.3-40.4
		c0,0,7-11.6,18-22.6c0,0,16.5,3.2,40,10.2c0,0,12.4,3.7,30,10.2c0,0-10.5,6.7-20.4,32.1c0,0-12,27-11,56c0,0-2.2,26.3,12.9,60.9
		c0,0,7.4,18.6,18.4,27.2c0,0-22.1,8.3-36.7,12.1C337.3,401.9,315.8,408,303.9,410.2z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M383,394.3c0,0-12.4-1.8-23.7-23.8
		c0,0-10.6-20.1-14.9-44c0,0-2.5-13.2-1.9-25c0,0-0.8-15.7,3.9-34.1c0,0,4.4-19.4,14.4-37.6c0,0,8.8-17.7,21.9-21l0.7,0.1
		c0,0,35.1-0.4,56.8-0.4c21.7,0,33.2,0,33.2,0h11.3c0,0,64.8,0.7,93,1.8c0,0,6.9,1,9.8-2.9c0,0,19.9-0.1,39.9,4.4
		c0,0,20.5,3.9,29,15.5c0,0-7.8,1.7-12,6.9c0,0-2.8,2.9-2.9,7.4c0,0-4,23.7-4,60c0,0,0,33.3,4,59.6c0,0,0,6.6,6.5,10.9
		c0,0,2.2,1.8,8.4,3.7c0,0-3.6,5.6-12.7,9.9c0,0-9.9,5.6-30.8,8.1c0,0-14.3,1.8-25.4,1.8c0,0-1.9-3.2-8.7-3c0,0-27.8,0.8-34,1
		c0,0-47.2,1-71.4,1c0,0-50.9,0-67.8-0.1l-22.3-0.3L383,394.3z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M691.5,386.5l-34.9-10.7
		c0,0,10.2-0.3,16-13.2c0,0,1.1-2.8,1.8-4.4c0,0,6.2-13.1,8.1-36.9l0.7-19.7l-0.8-20.4c0,0-1.4-19.8-8-36.2c0,0-4.5-16.4-17.6-17.6
		l34.9-10.7c0,0,5.1,5.8,8.7,24.8c0,0,4.3,18.3,5,40.2l0.6,19.9l-0.6,19.8c0,0-0.8,20.6-4.5,38.2c0,0-1.9,10.2-4.1,16.5
		C696.5,376.2,694.8,381.4,691.5,386.5z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M691.5,386.5
		c0,0,5.8-7,9.4-28.2c0,0,3.2-15,4.4-38.5l0.4-18.3l-0.5-20.2c0,0-0.6-17.5-4.7-38.2c0,0-3.2-19-9.1-26.3l17.7-5.5
		c0,0,5.9,15.4,9.6,47.6c0,0,0.7,6.9,1.7,20.2c0,0,0.8,14.2,1,22.4c0,0-0.4,16.2-1.2,26.7c0,0-1,15.8-3.6,32.6c0,0-2.6,17.5-7.4,31
		L691.5,386.5z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Crossover4DrTop);
