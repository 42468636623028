import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { cloneDeep, findIndex } from 'lodash';
import {
  loadAccountList,
  loadEventList,
  loadExistingData,
  loadVin,
  resetVin,
  updateItem,
  updateVariant,
  updateVin,
} from '../../../../actions';
import { compose } from '../../utils/helpers';
import { Item } from '../../utils/Item';
import ToastContent from '../ui/toastContent';
import VinSearchModal from './VinSearchModal';
import SaleInventoryModal from './SaleInventoryModal';
import VinVerificationModal from './VinVerificationModal';
import SearchLotsModal from './SearchLotsModal';
import VariantModal from './VariantModal';
import VehicleInformationModal from './VehicleInformationModal';
import ExistingVinModal from './ExistingVinModal';
import withRouter from '../../../../hocs/withRouter';
import { getConfig } from '../../../../utils/helpers';
import { VinScanModal } from './VinScanModal';

class CoreModalsContainer extends Component {
  state = {
    vinInput: '',
    isLoading: false,
    showValidationError: false,
    lotsEnabled: false, // keep disabled atm
    disableEdit: false,
    isScanning: false,
    isVinScanValue: false,
    isVinScanModalOpen: false,
    isVinSearchModalOpen: false,
    isSaleInventoryModalOpen: false,
    isVinVerificationModalOpen: false,
    isSearchLotsModalOpen: false,
    isVariantModalOpen: false,
    isVehicleInformationModalOpen: false,
    isExistingVinModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    // the symbol prop kicks off the core modals
    if (this.props.symbol !== prevProps.symbol) {
      if (this.props.existingVin) {
        this.handleEdit();
      } else {
        this.handleAdd();
      }
    }
  }

  handleAdd = () => {
    this.props.resetVin();
    this.closeAllModals();
    this.openVinSearchModal();
  };

  handleEdit = () => {
    this.props.resetVin();
    this.closeAllModals();
    this.setState({ isLoading: true, isVariantModalOpen: true }, () => {
      this.handleSearch(this.props.existingVin);
    });
  };

  // not used atm
  handleDelete = () => {
    const vehicle = Item(this.props.item);
    vehicle.action = 'delete-listing';

    this.props
      .loadDeletePostItem(vehicle)
      .then(({ response, error }) => {
        if (error) {
          toast(
            <ToastContent
              status="error"
              text="Oops. Something went wrong please try again"
            />,
            { className: 'review-toast' }
          );
          return;
        }
        if (response.amStatus === 'Success') {
          this.props.resetDeletePostItem();
          toast(
            <ToastContent status="success" text="Item successfully deleted." />,
            { className: 'review-toast' }
          );
          setTimeout(() => {
            const { entryType, saleDate } = this.props.item;
            const path = `/inventory/${
              entryType === 'Inventory' || !saleDate ? 'unlisted' : 'listed'
            }`;
            this.props.navigate(path, { replace: true });
          }, 3000);
        } else if (response.amStatus === 'Error') {
          this.props.resetDeletePostItem();
          toast(<ToastContent status="error" text={response.amMessage} />, {
            className: 'review-toast',
          });
        }
      })
      .catch(error => console.error(error));
  };

  handleCancel = () => {
    // Anyline.dispose(); // no re-initialize
    // if (Anyline && Anyline.getState() === "scanning") {
    //   Anyline.stopScanning()
    //   .catch(stopScanErr=>console.error(JSON.stringify(stopScanErr,null,2)));
    // }
    
    this.closeAllModals();
    this.setState({ vinInput: '', isVinScanValue: false });
    this.props.resetVin();
  };

  closeAllModals = () => {
    this.setState({
      isVinSearchModalOpen: false,
      isSaleInventoryModalOpen: false,
      isVinVerificationModalOpen: false,
      isSearchLotsModalOpen: false,
      isVariantModalOpen: false,
      isVehicleInformationModalOpen: false,
      isExistingVinModalOpen: false,
      isVinScanModalOpen: false,
    });
  };

  openVinSearchModal = () => {
    this.closeAllModals();
    this.setState({ isVinSearchModalOpen: true });
  };

  openExistingVinModal = () => {
    this.closeAllModals();
    this.setState({ isExistingVinModalOpen: true });
  };

  openSaleInventoryModal = () => {
    this.closeAllModals();
    this.setState({ isSaleInventoryModalOpen: true });
  };

  openVinVerificationModal = () => {
    this.closeAllModals();
    this.setState({ isVinVerificationModalOpen: true });
  };

  openSearchLotsModal = () => {
    this.closeAllModals();
    this.setState({ isSearchLotsModalOpen: true });
  };

  openVariantModal = () => {
    this.closeAllModals();
    this.setState({ isVariantModalOpen: true });
  };

  openVehicleInformationModal = () => {
    this.closeAllModals();
    this.setState({ isVehicleInformationModalOpen: true });
  };

  openVinScanModal = () => {
    this.closeAllModals();
    this.setState({ isVinScanModalOpen: true})
  }

  openCore = () => {
    this.closeAllModals();
    this.setState({ vinInput: '' });
    this.props.navigate('/core/details');
  };

  handleVinInput = e => {
    const input = e.target.value;
    const re = /^[A-Za-z0-9]*$/;
    if (re.test(input)) {
      this.setState(prevState => ({
        vinInput: input,
        showValidationError:
          input.length >= 4 ? false : prevState.showValidationError,
      }));
    }
  };

  handleVinClear = () => {
    this.setState({
      vinInput: '',
      showValidationError: false,
      isVinScanValue: false,
    });
  };

  handleVinScanPress = ()=> {
    this.openVinScanModal();
  }

  handleVinScan = ({image, fullImage, result}) => {
    console.log(JSON.stringify(result))
    const scannedVin = result || '';
    this.setState({
      vinInput: scannedVin,
      isVinScanValue: Boolean(scannedVin),
    },
      ()=>this.openVinSearchModal()
    )
  }

  handleSearch = vin => {
    if (vin.length) {
      if (vin.length >= 4) {
        this.setState({ isLoading: true, showValidationError: false }, () => {
          this.submitVin(vin);
        });
      } else {
        this.setState({ showValidationError: true });
      }
    }
  };

  handleVinVerification = () => {
    this.props.updateItem({ nonStandardVin: true });
    this.openSaleInventoryModal();
  };

  handleNewStandardVin = vin => {
    const { decodedItems, items } = vin;
    const decodedItem = decodedItems[0];
    const existingItem = items[0];
    const vinText = this.state.vinInput || this.props.existingVin;

    this.props.loadExistingData(existingItem, decodedItem);
    this.props.updateItem({
      vin: vinText,
      printers: [],
      photos: [],
    });
    this.addUnknownTrim();
    this.setState({
      isLoading: false,
      isVinSearchModalOpen: false,
      isSaleInventoryModalOpen: true,
    });
  };

  handleNewNonStandardVin = vin => {
    const vinText = this.state.vinInput || this.props.existingVin;

    this.props.updateItem({
      vin: vinText,
      printers: [],
      photos: [],
    });
    this.setState({
      isLoading: false,
      isVinSearchModalOpen: false,
      isVinVerificationModalOpen: true,
    });
  };

  handleExistingStandardVin = vin => {
    const { decodedItems, items } = vin;
    const decodedItem = decodedItems[0];
    const existingItem = items[0];
    const vinText = this.state.vinInput || this.props.existingVin;

    if (items.length > 1) {
      // multiple items (shouldn't happen?)
      console.error(`multiple existing items for vin ${vinText}`);
    }
    // single item
    this.props.loadExistingData(existingItem, decodedItem);
    this.props.updateItem({ vin: vinText, printers: [] });
    this.addUnknownTrim();
    const trim = existingItem.info ? existingItem.info.trim : null;
    const existingVariant = findIndex(decodedItems, { trim });
    if (existingVariant > -1) this.props.updateVariant(existingVariant);

    if (this.state.vinInput) {
      // user clicked "Add New" button but entered an existing vin, confirm this with the ExistingVinModal
      this.setState({
        isLoading: false,
        isVinSearchModalOpen: false,
        isExistingVinModalOpen: true,
        disableEdit: !this.belongsToUser(existingItem),
      });
    } else {
      // user clicked "Edit" button, go straight to Variant modal (should already be open)
      this.setState({
        isLoading: false,
        isVariantModalOpen: true, // already open but that's ok
      });
    }
  };

  handleExistingNonStandardVin = vin => {
    const { decodedItems, items } = vin;
    const existingItem = items[0];
    const decodedItem = decodedItems[0];
    const vinText = this.state.vinInput || this.props.existingVin;

    this.props.loadExistingData(existingItem, decodedItem);
    this.props.updateItem({ vin: vinText, printers: [] });
    this.addUnknownTrim();

    this.setState({
      isLoading: false,
      isVariantModalOpen: false,
      isVehicleInformationModalOpen: true,
      disableEdit: !this.belongsToUser(existingItem),
    });
  };

  detectNonStandardVin = (item = {}) => {
    const vinText = this.state.vinInput || this.props.existingVin;
    return item.nonStandardVin || vinText.length < 17;
  };

  addUnknownTrim() {
    const hasUnknownTrim =
      this.props.vin.decodedItems &&
      this.props.vin.decodedItems.length > 1 &&
      this.props.vin.decodedItems.at(-1).trim === 'Unknown';

    if (!hasUnknownTrim) {
      const decodedItems = cloneDeep(this.props.vin.decodedItems || []).map(
        // adds "Unknown" option to the following fields
        item => {
          [
            'bodyStyles',
            'transmission',
            'engines',
            'drives',
            'exteriorColors',
            'interiorColors',
            'interiorTypes',
          ].forEach(field => {
            if (item[field]) item[field].push('Unknown');
          });

          return item;
        }
      );
      // adds a variant option "Unknown" based on the first decoded item
      const unknowTrim =
        decodedItems && decodedItems.length ? cloneDeep(decodedItems[0]) : null;
      if (unknowTrim) {
        unknowTrim.trim = 'Unknown';
        decodedItems.push(unknowTrim);
        this.props.updateVin({ decodedItems });
      }
    }
  }

  submitVin = vin => {
    this.props.loadEventList();
    this.props.loadAccountList();
    this.props
      .loadVin(vin, false, false)
      .then(({ response }) => {
        if (response.amStatus === 'Success') {
          this.handleVINSuccess(response);
        } else {
          this.handleVINError(response);
        }
      })
      .catch(error => {
        this.handleVINError(error);
      });
  };

  belongsToUser(existingItem) {
    // the item's account numbers
    const { accountNumber, parentAccountNumber } = existingItem.auction;

    // from get-account-list api
    const accountNumbers = (this.props.accountList || [])
      .map(account => account.accountNumber)
      .sort();

    // from user-profile api
    const accountIds =
      this.props.userProfile.user && this.props.userProfile.user.accountList
        ? this.props.userProfile.user.accountList
            .map(account => String(account.accountId))
            .sort()
        : [];

    return (
      accountNumbers.includes(accountNumber) ||
      accountIds.includes(accountNumber) ||
      accountNumbers.includes(parentAccountNumber) ||
      accountIds.includes(parentAccountNumber)
    );
  }

  handleVINSuccess = vin => {
    // NOTE - vin is the response from decode-item
    // state.vinInput in the text string entered by user from the VinSearchModal.
    // props.existingVin is the text string passed from an existing item for editing.
    const { decodedItems, items } = vin;
    const decodedItem = decodedItems[0];
    const existingItem = items[0];

    if (existingItem && decodedItem) {
      const isNonStandardVin = this.detectNonStandardVin(existingItem);
      if (isNonStandardVin) this.handleExistingNonStandardVin(vin);
      else this.handleExistingStandardVin(vin);
    } else if (!existingItem && decodedItem) {
      this.handleNewStandardVin(vin);
    } else if (!existingItem && !decodedItem) {
      this.handleNewNonStandardVin(vin);
    } else if (existingItem && !decodedItem) {
      this.setState({ isLoading: false });
      this.closeAllModals();
      console.error('NO DECODED-ITEM');
    }
  };

  handleVINError = errorResponse => {
    console.error(errorResponse);
    this.setState({ isLoading: false });
  };

  render() {
    const enableBarcodeScanner = Boolean(getConfig('anylineLicenseKey'));
    const {
      disableEdit,
      isExistingVinModalOpen,
      isLoading,
      isSaleInventoryModalOpen,
      isSearchLotsModalOpen,
      isVariantModalOpen,
      isVehicleInformationModalOpen,
      isVinScanModalOpen,
      isVinScanValue,
      isVinSearchModalOpen,
      isVinVerificationModalOpen,
      lotsEnabled,
      showValidationError,
      vinInput,
    } = this.state;
    const { item, sellerActivityList } = this.props;

    return (
      <div id='core-modal-container'>
        <VinSearchModal
          isOpen={isVinSearchModalOpen}
          vinInput={vinInput}
          isLoading={isLoading}
          isScanEnabled={enableBarcodeScanner}
          isVinInputFromScan={isVinScanValue}
          showValidationError={showValidationError}
          handleVinScanPress={this.handleVinScanPress}
          handleVinInput={this.handleVinInput}
          handleVinClear={this.handleVinClear}
          onScanNext={this.openVinScanModal} // *****
          onNext={() => {
            this.handleSearch(vinInput);
          }}
          onClose={this.handleCancel}
          navigate={this.props.navigate}
        />
        {enableBarcodeScanner ?
          (<VinScanModal
            isOpen={isVinScanModalOpen}
            onVinScan={this.handleVinScan}
            onClose={this.handleCancel} 
            onBack={this.openVinSearchModal}
          />
          ) : null
        }
        <SaleInventoryModal
          isOpen={isSaleInventoryModalOpen}
          onClose={this.handleCancel}
          sellerActivityList={sellerActivityList}
          onNext={entryType => {
            this.props.updateItem({ entryType });
            if (lotsEnabled) {
              this.openSearchLotsModal();
            } else {
              const isNonStandardVin = this.detectNonStandardVin(item);
              if (isNonStandardVin) this.openVehicleInformationModal();
              else this.openVariantModal();
            }
          }}
          onBack={this.openVinSearchModal}
          navigate={this.props.navigate}
        />
        <VinVerificationModal
          isOpen={isVinVerificationModalOpen}
          onClose={this.handleCancel}
          vinInput={vinInput}
          onNext={() => {
            this.props.updateItem({ nonStandardVin: true });
            this.openSaleInventoryModal();
          }}
          onBack={this.openVinSearchModal}
          navigate={this.props.navigate}
        />
        <VehicleInformationModal
          isOpen={isVehicleInformationModalOpen}
          vinInput={vinInput}
          onClose={this.handleCancel}
          onNext={updatedItem => {
            const entryType =
              sellerActivityList === 'Unlisted' ? 'Inventory' : 'Sale';
            updatedItem.entryType = entryType;
            if (entryType === 'Inventory') updatedItem.saleDate = '';
            this.props.updateItem(updatedItem);
            this.openCore();
          }}
          onBack={this.openVinVerificationModal}
          disableEdit={disableEdit}
          navigate={this.props.navigate}
        />
        <VariantModal
          isOpen={isVariantModalOpen}
          isLoading={isLoading}
          onClose={this.handleCancel}
          onNext={() => {
            const updatedItem = {};
            const entryType =
              sellerActivityList === 'Unlisted' ? 'Inventory' : 'Sale';
            updatedItem.entryType = entryType;
            if (entryType === 'Inventory') updatedItem.saleDate = '';
            this.props.updateItem(updatedItem);
            this.openCore();
          }}
          onBack={
            lotsEnabled ? this.openSearchLotsModal : this.openSaleInventoryModal
          }
          navigate={this.props.navigate}
        />
        <SearchLotsModal
          isOpen={isSearchLotsModalOpen}
          onClose={this.handleCancel}
          onBack={this.openSaleInventoryModal}
          onNext={() => {
            const isNonStandardVin = this.detectNonStandardVin(item);
            if (isNonStandardVin) this.openVehicleInformationModal();
            else this.openVariantModal();
          }}
          navigate={this.props.navigate}
        />
        <ExistingVinModal
          isOpen={isExistingVinModalOpen}
          vinInput={vinInput}
          onClose={this.handleCancel}
          onBack={this.openVinSearchModal}
          onNext={() => {
            const isNonStandardVin = this.detectNonStandardVin(item);
            if (isNonStandardVin) this.openVehicleInformationModal();
            else this.openVariantModal();
          }}
          disableEdit={disableEdit}
          navigate={this.props.navigate}
        />
        {this.state.isScanning && (
          <div 
            id='anyline-scan-container-window'
            style={{width: '100%', height: '100%', zIndex: 999}}
          />
        )}
          
          <VinScanModal 
            onVinScan={this.handleVinScan}

          />
      </div>
    );
  }
}

CoreModalsContainer.propTypes = {
  coreAction: PropTypes.string,
  existingVin: PropTypes.string,
  loadAccountList: PropTypes.func.isRequired,
  loadEventList: PropTypes.func.isRequired,
  loadVin: PropTypes.func.isRequired,
  sellerActivityList: PropTypes.string.isRequired,
  symbol: PropTypes.symbol.isRequired,
  updateItem: PropTypes.func.isRequired,
  updateVariant: PropTypes.func.isRequired,
  updateVin: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { accountList, item, vin, variant } = state.core;
  const { userProfile } = state.entities;
  return { accountList, item, vin, variant, userProfile };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loadAccountList,
    loadEventList,
    loadExistingData,
    loadVin,
    resetVin,
    updateItem,
    updateVariant,
    updateVin,
  })
)(CoreModalsContainer);
