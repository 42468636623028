export const EventStatus = (statusData, currency) => {
  let title, ask, status, bid, high;

  if (statusData.ls !== 1) {
    ask = 0;
  } else {
    ask =
      statusData.hb > 0
        ? parseInt(statusData.hb, 10) + parseInt(statusData.bi, 10)
        : statusData.sb;
  }

  if (ask > 0) {
    bid = ask;
    title = `Bid ${currency}${bid}`;
  } else {
    bid = "";
    title = "Wait";
  }

  switch (statusData.ls) {
    case 1:
      switch (statusData.go) {
        case 1:
          status = "Going Once";
          break;
        case 2:
          status = "Going Twice";
          break;
        case 3:
          status = "About to Close";
          break;
        default:
          status = "Bidding in Progress";
          break;
      }
      break;
    case 4:
      switch (statusData.ts) {
        case 5:
          status = "If Sale";
          break;
        default:
          status = "Sold";
          break;
      }
      break;
    case 5:
      status = "No Sale";
      break;
    default:
      status = "";
      break;
  }

  high = `${currency}${statusData.hb}`;

  return {
    title: title,
    ask: ask,
    status: status,
    bid: bid,
    high: high,
    bidder: statusData.hn
  };
};
