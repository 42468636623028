import { FETCH_API } from '../../middleware';

export const CORE_META_REQUEST = 'CORE_META_REQUEST';
export const CORE_META_SUCCESS = 'CORE_META_SUCCESS';
export const CORE_META_FAILURE = 'CORE_META_FAILURE';

const fetchCoreMetaDetails = (mpId, apiKey) => ({
  [FETCH_API]: {
    types: [CORE_META_REQUEST, CORE_META_SUCCESS, CORE_META_FAILURE],
    endpoint: `get-config?mpId=${mpId}&apiKey=${apiKey}`,
    core: true,
  },
});

export const getCoreMetaDetails = (mpId, apiKey) => dispatch =>
  dispatch(fetchCoreMetaDetails(mpId, apiKey));
