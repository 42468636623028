import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegularCabPickup2DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M238.9,385.1c3.9-0.4,8.9-3.7,8.5-9.9h303.9c0,0.5,0.1,1.1,0.1,1.5c1.8,7.4,9.8,7.4,9.6,1l0.5-4l0.5-1.1
	c5.6-45.9,33.7-64.8,68.5-64.8c44.3,0,58.2,27.6,69.9,73.2c0,0,0,0.3,0.1,0.7c0.5,1.7,3,3,4.8,2.3l0.5-0.3c3-2.1,4.7-7.2,9.6-10.5
	c1.8-1.2,4-2.2,6.9-2.8c16.1-3.2,42-6.1,60.4-7.2l0.4-0.4h13.2c6.2-0.7,8.3-8.3,8.3-9.6c3.4-7.7,5.2-19.9,6.1-32.7
	c1.2-22.6-0.1-46.8-1.9-50.8c-0.7-4.1-4.8-6.9-12.4-6.9h-17.3l-325.5,0.4h-8.3v-75l-4.8,0.1v21.9c0-7.2,0.3-11.7-0.7-17.3
	c-0.8-2.1-1.1-6.3-2.1-8.1c-1.1-3.2-2.6-14.2-21.2-13.1h-27c-40.9,0-60.3,5.5-74,11.7c-1,0.4-1.8,1-2.8,1.4
	c-22.1,11.7-47.2,34-90,69.7c-4.4-0.6-10.7-1.8-14.7-4.7c-40.2,3.2-105.1,8.1-150.1,16.4c-11.8,2.1-16,10.5-16.6,13.9
	c-1.4,2.8-2.8,5.8,0,7.8l0.7,0.7c-2.1,4.1-2.8,11.6-2.8,28.1v0.3l-0.3,1.2l-0.1,0.1c-2.1,2.1-4,4.1-4.7,6.2
	c-0.7,2.1-2.3,3.9-3.7,4.5l-0.7,1.4v18l2.5,1.4l4.4,13.2c-2.2,0.3-2.8,1.4-2.1,3l5.5,5.9c3.4,2.8,13.2,1.4,17.3,1.4h34.7v-0.7
	c6.5-28.5,16.4-46.2,30.4-56c9.8-6.7,21.5-9.6,35.4-9.6c47.3,0,61.6,16,73.9,64.2h0.3l0.7,3.4c0.3,1.2,0.7,2.6,1,4
	c0.4,1.6,0.8,3.3,1.1,5C235.1,385,236.9,385.4,238.9,385.1L238.9,385.1z"
          />
          <path
            fill="#5E5E5E"
            d="M247.3,255.5c45-37.3,55.3-52.6,77.4-64.9l-11.7-5.5c-21.5,11.6-46.8,34.5-89.8,70.3c1,0.1,1.8,0.1,2.5,0.1
	L247.3,255.5L247.3,255.5z"
          />
          <path
            fill="#EB2227"
            d="M336.9,186.1c-4.8,1.4-8.9,2.9-12.1,4.5C328.2,188.7,332.2,187.2,336.9,186.1z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M324.9,190.9l-11.7-5.5c-2.2,1.2-4.5,2.5-6.9,4c-20.1,12.4-44.4,34-83.1,66c1,0.1,1.8,0.1,2.5,0.1h21.5
		c31.1-25.7,45.7-40.9,59-51.9C312.4,198.7,318,194.7,324.9,190.9L324.9,190.9z M398.7,189.2l-32.9,0.6c-17.3,0-32.6,2.1-59.4,25
		c-5.6,4.8-11.8,10.6-18.6,17.5v5.5l0.3,0.6c3.4-0.8,8.1,0.8,8.1,4v16.8c0,2.8-3,5.4-6.5,5.4h16.6l92.3,0.1c4.8,0,8.3-3.4,8.3-7.6
		v-60.3C406.9,192.7,403.5,189.2,398.7,189.2L398.7,189.2z M810.1,278.2l-0.8-0.5c-12.5,0-15.3,9.6-15.3,23.5v16.5
		c0,8.3,6.2,11.7,11.8,11.7h4.7c0.3-2.8,0.5-5.6,0.7-8.7C812,304.8,811.2,288,810.1,278.2L810.1,278.2z"
            />
          </g>
          <path
            fill="#EB2227"
            d="M223.1,255.5c0.1,0,0.1-0.1,0.3-0.1C223.3,255.4,223.3,255.4,223.1,255.5z"
          />
          <path
            fill="#EAEBEB"
            d="M216.2,302.5c0,1.4,3.4,2.8,7.6,2.8c4.8,0,8.3-1.4,8.3-2.8c0-2.1-3.4-2.8-8.3-2.8
	C219.7,299.7,216.2,300.4,216.2,302.5z M53.7,316.6l22.6-0.1h2.1v-19.4c0-5.5-5.5-11.8-11-11.8H49.4c-3.4,0-5.8,1.1-7.2,4
	c-1.9,4.8-2.5,12.7-2.6,27.2h4.1L53.7,316.6L53.7,316.6z"
          />
          <path
            fill="#5E5E5E"
            d="M42.1,289.2c1.4-2.8,3.7-4,7.2-4c0,0-4.1-4.8-7.6-4.8c-1.4,2.8-2.5,5.8,0.3,7.8L42.1,289.2L42.1,289.2z
	 M159,324.2c-26.3,0-48.7,17.6-56.3,41.3c-1.8,5.6-2.8,11.7-2.8,17.9c0,32.5,26.3,58.7,58.9,58.7c32.5,0,59.3-26.5,59.3-59
	c0-5-0.7-9.9-1.8-14.6C209.8,343,186.5,324.2,159,324.2L159,324.2z"
          />
          <path
            fill="#5E5E5E"
            d="M102.8,365.3c7.6-23.7,30.3-41.3,56.5-41.3c27.5,0,50.6,19.3,57.1,44.6h1.1l15.1,2.3
	c-12.2-48.3-27.2-64.4-74.6-64.4c-13.9,0-25.4,3.3-35.2,10.2c-14,9.8-23.9,27.4-30.4,56c0.7-1.4,0.7-2.8,1.4-4.1v1.4l7.6-4.8
	C101.4,365.2,102.8,365.3,102.8,365.3z M239.5,385c-2.1,0.3-4,0-4.3-1c0,0,1.1,11.7,1.1,18.7c2.8-1.4,3.4-3.4,3.4-6.2l-0.8-11.3
	L239.5,385L239.5,385z"
          />
          <path
            fill="#FFFFFF"
            d="M402.4,288.7c0-2.1-1.4-3.4-3.4-3.4h-18c-2.1,0-3.4,1.4-3.4,3.4v4.1c0,1.4,1.4,2.8,3.4,2.8h18
	c2.1,0,3.4-1.4,3.4-2.8V288.7z M283.8,256.4v-11c0-3.6,1.6-6.1,4.4-6.7l-0.3-0.6v-5.5c-6.2,6.2-12.8,13.6-20.1,21.7
	c-3.4,3.4-5.5,10.3,3.4,10.3l6.5,0.1h12.9c-1.1,0-1.6-0.5-2.6-1c-2.1-1-4.3-2.3-4.3-5.2L283.8,256.4L283.8,256.4z M454.5,265.3h-4.8
	c-1.4,0-2.5,1.5-2.5,2.2c0,1.4,0.4,2.6,1.8,2.6h4.8c3.7,0,4.7-4.1,7.4-4.1h5.4l87.5,0.3l64.2-0.3h159.7c2.1,0,3.4,4.1,6.2,4.1h5.5
	c0.7,0,2.1-1.4,2.1-2.8c0-0.7-1.7-2.1-2.3-2.1H784c-2.8,0-2.8-2.2-5.5-2.2l-319,0.1C457.5,263.2,457.3,265.3,454.5,265.3
	L454.5,265.3z M445.7,263.2v-74.8h-4.8v74.8"
          />
          <path
            fill="#5E5E5E"
            d="M686.7,365.3c-7.6-23.7-29.7-40.7-56-40.7c-28.5,0-52.1,20.1-57.6,46.9c-0.8,3.9-1.7,8-1.7,12
	c0,32.5,26.8,58.5,59.3,58.5s59.2-26,59.2-58.5C689.7,377.3,688.5,371,686.7,365.3L686.7,365.3z"
          />
          <path
            fill="#FFFFFF"
            d="M554.2,266h-6.7c-1.4,0-2.9,0.8-2.9,2.2c0,1.4,0.6,2.6,1.9,2.6h8.3c1.4,0,1.5-1.4,1.5-2.8
	S555.6,266,554.2,266z M616,268.1c0,1.4,0.3,2.8,1.7,2.8h8c1.4,0,2.1-0.7,2.1-2.1c0-1.4-0.7-2.8-2.1-2.8h-6.9
	C617.4,266,616,266.7,616,268.1L616,268.1z M687.4,268.2c0,1.4,1,2.8,2.3,2.8h6.9c1.4,0,2.6-0.8,2.3-2.9c0-1.4-1.4-2.1-2.8-2.1
	l-6.1,0.1C688.8,266.1,687.4,266.8,687.4,268.2L687.4,268.2z M758.5,268.6c0,1.4,1.2,2.2,2.6,2.2h6.9c2.1,0,2.9-0.8,2.9-2.2
	s-0.8-2.6-2.9-2.6h-6.9C759.8,266,758.5,267.2,758.5,268.6z"
          />
          <path fill="#5E5E5E" d="M798.4,368.3h-11v2.8l-0.3,12.2l0.3,1.5h11" />
          <path
            fill="#5E5E5E"
            d="M787.4,371.2c-1.8-2.5-3.6-5.5-4.1-7.7l0.3-0.3c-18.4,1.1-44.7,4.3-60.8,7.6c13.2-2.1,31.9-5.2,48.6-6.6h1.4
	c0.7,5.5,9.2,18.7,14.7,18.7V371.2z M708.2,409.5c0-4.8-2.1-18-3.4-24.9l0.8-0.4c-1.8,0.7-4.3-0.7-4.8-2.3
	c0.7,4.3,4.7,27.6,4.7,33.8C708.2,413.7,708.9,411.7,708.2,409.5L708.2,409.5z"
          />
          <path
            fill="#EAEBEB"
            d="M127.5,366.1c6.1-11.3,17.9-18.7,31.6-18.7c14,0,26.3,8.4,31.9,20.6c2.1,4.7,3.3,9.8,3.3,15.3
	c0,19.4-16,35.2-35.2,35.2c-20.1,0-35.9-16-35.9-35.2C123.3,377,124.8,371.2,127.5,366.1L127.5,366.1z M597.3,370.1
	c5.2-12.9,18.2-22.1,33.4-22.1c13.3,0,25,7.6,31.1,18.6c2.6,5,4.3,10.6,4.3,16.6c0,20.1-16.4,35.4-35.8,35.4
	c-20.1,0-35.2-15.1-35.2-35.2C595,378.8,595.7,374.3,597.3,370.1L597.3,370.1z"
          />
          <path
            fill="#5E5E5E"
            d="M630.7,308c-34.7,0-63,19.3-68.6,65.2l0.1-0.3l9.6-1.4h1.2c5.5-26.8,29.2-46.9,57.6-46.9
	c26.3,0,48.4,17.1,56,40.7l9.6,2.8l0.7,1.4c1.4,3.4,2.1,7.6,3.4,11.7C688.8,335.6,675,308,630.7,308L630.7,308z"
          />
          <path
            fill="#FFFFFF"
            d="M483,266h-6.6c-1.4,0-2.9,0.8-2.9,2.2c0,1.4,0.6,2.6,1.9,2.6h8.3c1.4,0,1.5-1.4,1.5-2.8
	C485,266.7,484.4,266,483,266L483,266z"
          />
          <path
            fill="#5E5E5E"
            d="M148.2,355.3c-3.4,0.7-6.2,2.8-8.9,4.8c-2.1,1.4,1.4,3.4,11-0.7C153,358,150.9,353.9,148.2,355.3
	L148.2,355.3z M133.6,378.8c0.7-3.4-4.1-4.1-4.8,0c0,2.8,0,6.2,0.7,9.6C129.4,391.3,132.9,389.3,133.6,378.8L133.6,378.8z
	 M142.7,402.3c-2.8-1.4-5.5,2.1-2.8,4.1c2.8,2.1,5.5,3.4,8.9,4.8C150.9,412.1,150.9,408.5,142.7,402.3L142.7,402.3z M178.6,405.8
	c2.1-1.4-1.4-3.4-11,1.4c-2.8,0.7-0.7,5.5,2.1,4.1C173.1,409.9,175.8,407.8,178.6,405.8z M188.3,377.4c0-2.1-3.4-0.7-4.1,9.6
	c-0.7,3.4,4.1,4.1,4.8,0.7C189,384.3,189,380.9,188.3,377.4L188.3,377.4z M177.9,359.4c-2.8-2.1-5.5-3.4-8.9-4.8
	c-2.1-0.7-2.1,2.8,6.2,8.9C177.9,365.6,180.6,361.5,177.9,359.4z M159.2,369.2c-1.4,0-2.1,1.4-2.1,2.1c0,1.4,0.7,2.1,2.1,2.1
	c0.7,0,1.4-0.7,1.4-2.1C160.7,370.5,160,369.2,159.2,369.2L159.2,369.2z M149.5,375.4c-0.7-0.7-2.1,0-2.8,0.7c0,1.4,0,2.1,0.7,2.8
	c1.4,0.7,2.8,0,2.8-0.7C150.9,377.4,150.9,376.1,149.5,375.4L149.5,375.4z M150.2,387.7c0-0.7-1.4-1.4-2.8-0.7
	c-0.7,0.7-0.7,2.1-0.7,2.8c0.7,0.7,2.1,1.4,2.8,0.7C150.9,389.9,150.9,389.3,150.2,387.7z M157.1,379.5c-0.7,0.7-0.7,2.8,0,4.1
	c1.4,2.1,2.8,3.4,3.4,2.8c0.7-0.7,0.7-2.1,0-4.1C159.2,380.2,157.8,379.5,157.1,379.5L157.1,379.5z M168.2,375.4
	c-1.4,0.7-1.4,2.1-0.7,2.8c0.7,0.7,1.4,1.4,2.8,0.7c0.7-0.7,0.7-1.4,0.7-2.8C170.3,375.4,168.9,374.7,168.2,375.4z M170.3,387.1
	c-1.4-0.7-2.1,0-2.8,0.7c-0.7,1.4-0.7,2.1,0.7,2.8c0.7,0.7,2.1,0,2.8-0.7C171,389.3,171,387.7,170.3,387.1L170.3,387.1z
	 M159.2,392.7c-1.4,0-2.1,0.7-2.1,2.1c0,1.4,0.7,2.1,2.1,2.1c0.7,0,1.4-0.7,1.4-2.1C160.7,393.4,160,392.7,159.2,392.7L159.2,392.7z
	"
          />
          <path
            fill="#FFFFFF"
            d="M94,331.1H30.1v17.7l0.8,0.5l1.7,0.4l2.5,8.4l1.9,5.1h-0.3l0.1,0.3l-1.4,2.8l4.8,5.5
	c4.1,3.4,13.9,1.4,18,1.4h22.8C83.9,356.2,88.3,342.2,94,331.1L94,331.1z"
          />
          <path
            fill="#5E5E5E"
            d="M36.7,363.5c-0.8,0-1.4,0.1-1.8,0.5c-0.3,0.3-0.4,0.7-0.4,1.1c0.1,0.4,0.3,0.8,0.7,1.1L36.7,363.5
	L36.7,363.5z"
          />
          <path
            fill="#FFFFFF"
            d="M283.8,245.4v12.4c0,1.5,0.3,2.6,1,3.6c0.8,1.1,2.1,1.8,3.3,2.3c0.5,0.1,1.2,0.3,1.9,0.3
	c3.4,0,6.2-2.8,6.2-4.8v-16.8c0-2.1-2.2-3.6-4.7-4c-2.1-0.1-4.5,0.4-6.1,1.9C284.5,241.4,283.8,243.2,283.8,245.4L283.8,245.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M217.4,368.5l14.9,2.8l0.8,3.6c0.7,3.4,1.2,6.1,2.2,9.6
	c0,0,1.4,11.8,0.7,18.7c3.4-2.1,3.4-4.1,3.4-6.2c0-6.2-0.7-11-0.7-11l2.1-0.7c3.4-1.4,5.5-3.4,6.9-8.9l301.7-0.7
	c6.9,9.6,11.8,10.3,11.8,3.4c0-2.1,0.7-3.4,0.7-4.8l0.7-1.4l9.6-1.4"
          />
          <path
            fill="#5E5E5E"
            d="M619.5,355.3c-2.8,1.4-5.5,2.8-8.3,4.8c-2.1,2.1,1.4,3.4,11-0.7C625,358,622.9,353.9,619.5,355.3
	L619.5,355.3z M600.7,378.8c0,3.4,0,6.9,0.7,9.6c0,2.8,2.8,0.7,4.1-9.6C606.2,376.1,601.4,375.4,600.7,378.8z M614.6,403
	c-2.8-2.1-5.5,1.4-2.8,4.1c2.8,2.1,5.5,3.4,8.9,4.1C622.9,412.8,622.9,409.2,614.6,403L614.6,403z M640.9,354.6
	c-2.8-0.7-2.8,2.8,6.2,8.9c2.1,2.1,5.5-1.4,2.8-4.1C647.1,357.3,643.7,356,640.9,354.6L640.9,354.6z M660.3,378.1
	c0-2.8-3.4-1.4-4.1,9.6c-0.7,2.8,4.1,3.4,4.1,0C661,384.3,661,380.9,660.3,378.1L660.3,378.1z M639.5,407.1
	c-2.8,1.4-1.4,5.5,2.1,4.1c3.4-1.4,6.2-2.8,8.9-4.8C651.9,404.4,649.2,403,639.5,407.1L639.5,407.1z M621.5,376.1
	c-0.7-0.7-2.1-0.7-2.8,0.7c0,0.7,0,2.1,0.7,2.1c1.4,0.7,2.1,0.7,2.8-0.7C622.9,377.4,622.2,376.1,621.5,376.1L621.5,376.1z
	 M630.5,369.9c-0.7,0-1.4,0.7-1.4,2.1c0,0.7,0.7,1.4,1.4,1.4c1.4,0,2.1-0.7,2.1-1.4C632.5,370.5,631.8,369.9,630.5,369.9
	L630.5,369.9z M640.2,376.1c-1.4,0-1.4,1.4-0.7,2.1c0,1.4,1.4,1.4,2.1,0.7c1.4,0,1.4-1.4,0.7-2.1
	C642.3,375.4,640.9,375.4,640.2,376.1z M639.5,388.4c-0.7,0.7-0.7,2.1,0.7,2.1c0.7,0.7,2.1,0.7,2.1-0.7c0.7-0.7,0.7-2.1-0.7-2.1
	C640.9,387.1,639.5,387.1,639.5,388.4L639.5,388.4z M630.5,393.4c-0.7,0-1.4,0.7-1.4,1.4c0,1.4,0.7,2.1,1.4,2.1
	c1.4,0,2.1-0.7,2.1-2.1C632.5,394.1,631.8,393.4,630.5,393.4L630.5,393.4z M619.5,387.7c-0.7,0-0.7,1.4-0.7,2.1
	c0.7,1.4,2.1,1.4,2.8,0.7c0.7,0,1.4-1.4,0.7-2.1C621.5,387.1,620.8,387.1,619.5,387.7L619.5,387.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M687.3,364.9l8.9,2.8l1.4,1.4c0.7,4.1,2.1,8.3,2.8,12.5c0,0,4.8,27,4.8,34
	c3.4-1.4,3.4-4.1,3.4-6.2c-0.7-4.1-2.8-18-3.4-24.2l0.7-1.4c4.1-2.8,6.2-10.3,16.6-12.5c13.2-2.8,32.5-5.5,48.4-6.9l2.1,0.7
	c0.7,4.8,8.3,18,14.6,18v1.4h11.1v-16h-11.1l-0.7,2.8c-1.4-2.8-2.8-5.5-4.1-7.6h0.7c5.5-0.7,9.6-0.7,13.2-0.7
	c6.2-0.7,8.3-8.2,8.9-8.9c8.9-22.1,5.5-78.1,3.4-84.5c-1.4-4.1-4.1-6.2-12.5-6.2H445.7v-75h-4.8v22.3l-0.7-1.4
	c0-28.3-4.1-37.4-20.1-37.4h-30.4c-40.2,0-60.3,4.8-74,11.8c-22.1,11-47.7,34-92.7,71.3h2.8c-3.4,0-12.5-1.4-17.3-4.8
	c-39.5,2.8-106.6,8.3-151.6,16.6c-11,2.8-14.6,10.3-15.3,13.9c-1.4,2.8-2.8,6.2,0.7,8.3c-2.1,4.8-2.8,13.2-2.8,29v0.7
	c-2.1,1.4-4.1,4.1-4.8,6.2c-0.7,2.1-2.8,3.4-3.4,4.1l-1.4,2.1v18l2.8,0.7l4.1,13.9l-1.4,2.8l4.8,5.5c4.1,3.4,13.9,1.4,18,1.4h34
	c0.7-1.4,0.7-2.8,1.4-4.1v1.4l8.3-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M217.4,368.5l15,2.8l0.7,3.4c0.7,3.4,1.4,6.1,2.2,9.6
	c0,0,1.4,11.8,0.7,18.7c3.4-2.1,3.4-4.1,3.4-6.2c0-6.2-0.7-11-0.7-11l2.1-0.7c3.4-1.4,5.5-3.4,6.9-8.9l301.7-0.7
	c6.9,9.6,11.8,10.3,11.8,3.4c0-2.1,0.7-3.4,0.7-4.8l0.7-1.4l9.6-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M687.3,364.9l8.9,2.8l1.4,1.4c0.7,4.1,2.1,8.3,2.8,12.5c0,0,4.8,27,4.8,34
	c3.4-1.4,3.4-4.1,3.4-6.2c-0.7-4.1-2.8-18-3.4-24.2l0.7-1.4c4.1-2.8,6.2-10.3,16.6-12.5c13.2-2.8,32.5-5.5,48.4-6.9l2.1,0.7
	c0.7,4.8,8.3,18,14.6,18v1.4h11.1v-16h-11.1l-0.7,2.8c-1.4-2.8-2.8-5.5-4.1-7.6h0.7c5.5-0.7,9.6-0.7,13.2-0.7
	c6.2-0.7,8.3-8.2,8.9-8.9c8.9-22.1,5.5-78.1,3.4-84.5c-1.4-4.1-4.1-6.2-12.5-6.2H445.7v-75h-4.8v22.3l-0.7-1.4
	c0-28.3-4.1-37.4-20.1-37.4h-30.4c-40.2,0-60.3,4.8-74,11.8c-22.1,11-47.7,34-92.7,71.3h2.8c-3.4,0-12.5-1.4-17.3-4.8
	c-39.5,2.8-106.6,8.3-151.6,16.6c-11,2.8-14.6,10.3-15.3,13.9c-1.4,2.8-2.8,6.2,0.7,8.3c-2.1,4.8-2.8,13.2-2.8,29v0.7
	c-2.1,1.4-4.1,4.1-4.8,6.2c-0.7,2.1-2.8,3.4-3.4,4.1l-1.4,2.1v18l2.8,0.7l4.1,13.9l-1.4,2.8l4.8,5.5c4.1,3.4,13.9,1.4,18,1.4h34
	c0.7-1.4,0.7-2.8,1.4-4.1v1.4l8.3-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M42.2,288.8c-2.1,4.8-2.8,11.8-2.8,27.6H79h-0.7v-19.4
	c0-5.5-5.5-11.8-11-11.8h-18C46.4,285.4,43.6,286.1,42.2,288.8 M224.2,304.8c4.1,0,7.6-1.4,7.6-2.8s-3.4-2.8-7.6-2.8
	c-4.8,0-8.3,1.4-8.3,2.8S219.4,304.8,224.2,304.8 M809,329h-3.4c-4.8,0-11.8-3.4-11.8-11.8v-16.6c0-13.2,2.8-22.8,15.3-22.8h1.4
	c0.7,11.8,2.1,32.5,0,51.2 M324.6,190.6l-11-5.5H313c-21.5,11.8-47,34.7-90,69.9h24.9C292.7,217.6,303.2,202.3,324.6,190.6
	 M288.6,231.5c38.8-38.8,57.5-42.2,77.4-42.2h33.2c4.1,0,7.6,3.4,7.6,7.6v60.3c0,4.1-3.4,7.6-7.6,7.6H290.5l-0.7-0.7
	c3.4,0,6.2-2.8,6.2-4.8v-17.3c0-2.8-4.1-4.1-7.6-3.4h-0.7v-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.8,375.4c-13.9-60.9-37.4-83.8-89.3-83.8c-38.1,0-67.1,20.8-77.4,81.7
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.8,375.4c0,9.6-11.8,11.1-12.5,8.9c-13.2-58.9-25.6-77.4-76.8-77.4
	c-34.7,0-54.6,18-66.4,66.4 M716.3,373.3c-12.5-49.1-31.8-80.3-85.8-80.3c-37.4,0-78.8,24.2-78.8,82.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.8,376.1l303.9-0.7 M217.4,368.5l15.3,2.8 M93.5,370.5l8.3-5.5
	 M58.2,373.3h34 M706,383.6c-2.1,1.4-4.8,0-5.5-2.1c-11-46.4-25.6-73.3-69.9-73.3c-36,0-65.1,20.1-69.2,70.6c0,6.9-9.6,4.1-9.6-3.4
	 M245.7,365.6h193.8 M41.5,280.6c0.7-3.4,4.1-11,15.3-13.9c45-8.3,112.1-13.9,151.6-16.6 M208.4,250c4.8,3.4,13.9,4.8,17.3,4.8
	 M41.5,280.6c4.1,0,7.6,4.8,7.6,4.8 M225.6,255h22.1 M39.5,317.9c0-16,0.7-24.2,2.8-29 M41.5,280.6c-1.4,2.8-2.8,6.2,0.7,8.3
	 M49.9,288.1c-4.1,0-6.2,5.5-6.2,28.3 M43.6,316.5h59.6 M49.9,288.1h16.6 M66.4,288.1c4.8,0,9.6,4.8,9.6,9.6v18.7 M49.2,285.4h18
	 M67.1,285.4c5.5,0,11,6.2,11,11.8v19.4 M42.2,288.8c1.4-2.8,4.1-3.4,6.9-3.4 M54.8,306.2h21.5 M58.2,288.1
	c-2.8,4.1-4.1,13.9-4.1,28.3 M85.8,349.8H31.2 M32.6,349.8l4.1,13.8 M82.4,363.5H36.7 M29.9,331.1c0-0.7,0.7-1.4,1.4-2.1
	c0.7-0.7,2.8-2.1,3.4-4.1c0.7-2.1,2.8-4.8,4.8-6.2 M36.7,363.5c-2.1,0-2.8,1.4-1.4,2.8 M58.2,373.3c-4.1,0-13.9,2.1-18-1.4l-4.8-5.5
	 M39.5,318.5c1.4-1.4,2.8-2.1,4.1-2.1 M29.9,331.1v18c0,0,0.7,0.7,1.4,0.7 M49.2,285.4v2.8 M440.2,375.4V263.2 M224.2,304.8
	c4.1,0,7.6-1.4,7.6-2.8s-3.4-2.8-7.6-2.8c-4.8,0-8.3,1.4-8.3,2.8S219.4,304.8,224.2,304.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M222.9,255c45-37.4,70.6-60.3,92.7-71.3c13.9-6.9,33.8-11.8,74-11.8
	 M440.2,263.2v-53.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.8,255c45-37.4,55.3-52.6,76.8-64.4c17.3-9.6,49.1-10.3,90-10.3h10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M440.9,263.2h355.7 M389.7,171.9h30.4c16,0,20.1,8.9,20.1,37.4 M461,266
	h316.9 M701,329h108.7 M227.3,277.8h583.3 M782.7,363.5c1.4,2.1,2.8,4.8,4.1,7.6 M773.1,364.9c0.7,4.8,8.3,18,14.6,18 M787.6,384.3
	h11v-15.8h-11V384.3z M809,269.4c2.1,6.2,5.5,62.3-3.4,84.5c-0.7,0.7-2.8,8.3-8.9,8.9 M706,383.6c4.1-2.8,6.2-10.3,16.6-12.5
	c21.5-4.1,57.5-8.3,74-8.3 M440.9,263.2v-74.8h4.8v74.8 M796.6,263.2c8.3,0,11,2.1,12.5,6.2 M83.1,286.1
	c20.8-2.8,79.7-8.3,161.9-8.3 M377.9,288.1c0,2.1,1.4,3.4,2.8,3.4h18.7 M402.1,288.1c0-1.4-1.4-2.8-2.8-2.8h-18.7
	c-1.4,0-2.8,1.4-2.8,2.8 M399.3,291.6c1.4,0,2.8-1.4,2.8-3.4 M402.1,288.1v4.1 M377.9,288.1v4.1 M377.9,292.3c0,2.1,1.4,3.4,2.8,3.4
	h18.7c1.4,0,2.8-1.4,2.8-3.4 M789.6,270.1h-4.8c-2.8,0-4.1-4.1-6.9-4.1 M789.6,265.3h-4.8c-2.8,0-2.8-2.1-5.5-2.1 M789.6,265.3
	c1.4,0,2.1,1.4,2.1,2.8s-0.7,2.1-2.1,2.1 M449.1,270.1h4.8c3.4,0,4.8-4.1,6.9-4.1 M449.1,265.3h4.8c3.4,0,2.8-2.1,5.5-2.1
	 M449.1,265.3c-1.4,0-2.1,1.4-2.1,2.8s0.7,2.1,2.1,2.1 M764.7,266.7v3.4 M758.5,268.8c0,1.4,1.4,2.1,2.8,2.1h6.9 M771,268.8
	c0-1.4-1.4-2.8-2.8-2.8h-6.9c-1.4,0-2.8,1.4-2.8,2.8 M768.2,270.8c1.4,0,2.8-0.7,2.8-2.1 M693.5,266.7v3.4 M687.3,268.8
	c0,1.4,1.4,2.1,2.8,2.1h6.9 M699,268.8c0-1.4-0.7-2.8-2.1-2.8H690c-1.4,0-2.8,1.4-2.8,2.8 M696.9,270.8c1.4,0,2.1-0.7,2.1-2.1
	 M479,266.7v3.4 M473.4,268.8c0,1.4,0.7,2.1,2.1,2.1h7.6 M485.2,268.8c0-1.4-0.7-2.8-2.1-2.8h-7.6c-1.4,0-2.1,1.4-2.1,2.8
	 M483.1,270.8c1.4,0,2.1-0.7,2.1-2.1 M550.3,266.7v3.4 M544.8,268.8c0,1.4,0.7,2.1,2.1,2.1h7.6 M556.4,268.8c0-1.4-0.7-2.8-2.1-2.8
	h-7.6c-1.4,0-2.1,1.4-2.1,2.8 M554.4,270.8c1.4,0,2.1-0.7,2.1-2.1 M622.2,266.7v3.4 M616,268.8c0,1.4,0.7,2.1,2.1,2.1h7.6
	 M627.7,268.8c0-1.4-0.7-2.8-2.1-2.8h-7.6c-1.4,0-2.1,1.4-2.1,2.8 M625.6,270.8c1.4,0,2.1-0.7,2.1-2.1 M550.3,266.7v3.4
	 M544.8,268.8c0,1.4,0.7,2.1,2.1,2.1h7.6 M556.5,268.8c0-1.4-0.7-2.8-2.1-2.8h-7.6c-1.4,0-2.1,1.4-2.1,2.8 M554.4,270.8
	c1.4,0,2.1-0.7,2.1-2.1 M58.9,285.4c0.7-2.8,2.8-11,20.8-14.6c45.7-7.6,133.6-15.1,143.2-15.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M64.4,285.4c1.4-2.8,8.9-4.8,16.6-6.9c45-11.8,157.1-17.3,166.7-23.5
	 M384.1,365.6v9.6 M256.1,365.6v10.3 M809,277.8c-12.5,0-15.3,9.6-15.3,22.8v16.6c0,8.3,6.9,11.8,11.8,11.8h3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M811.1,315.1h-2.8c-1.4,0-2.1,1.4-2.1,2.8v4.1c0,0.7,0.7,2.1,2.1,2.1h2.1
	 M794.6,302h16.6 M795.3,290.2h15.3 M424.9,180.2c15.3,0,15.3,16,15.3,47 M420.1,179.5c0-1.4-0.7-2.8-2.8-2.8h-16
	c-23.5,0-62.3,5.5-76.8,13.9 M296.3,241.8v17.3c0,2.1-2.8,4.8-6.2,4.8s-6.2-2.1-6.2-6.2v-13.2C283.8,236.3,296.3,237,296.3,241.8
	 M287.9,232.8v5.5 M278.3,263.9v-8.3h5.5 M313.5,185l11,5.5 M267.8,253.6c53.2-59.6,74.7-64.4,98.2-64.4 M413.2,365.6
	c4.1,0,7.6-3.4,7.6-7.6V180.9 M267.8,253.6c-3.4,3.4-5.5,11,3.4,11h128.1c4.1,0,7.6-3.4,7.6-7.6v-60.3c0-4.1-3.4-7.6-7.6-7.6h-33.2
	 M250,252.8c-2.8,2.1-6.2,30.4-6.2,58.2c0.7,26.3,4.8,51.9,8.9,54.6 M696.2,367.8l-8.9-2.8 M562.6,372.6l9.6-1.4 M29.9,331.1h64.4
	 M630.5,419c20.1,0,36-16,36-35.4c0-20.1-16-36-36-36c-19.4,0-35.4,16-35.4,36C595.2,403,611.2,419,630.5,419 M630.5,442.5
	c32.5,0,58.9-27,58.9-59.6c0-32.5-26.3-58.9-58.9-58.9c-32.5,0-58.9,26.3-58.9,58.9C571.7,415.5,598,442.5,630.5,442.5L630.5,442.5z
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M639.5,378.1c0,1.4,1.4,1.4,2.1,0.7c1.4,0,1.4-1.4,0.7-2.1
	c0-1.4-1.4-1.4-2.1-0.7C638.8,376.1,638.8,377.4,639.5,378.1 M639.5,388.4c-0.7,0.7-0.7,2.1,0.7,2.1c0.7,0.7,2.1,0.7,2.1-0.7
	c0.7-0.7,0.7-2.1-0.7-2.1C640.9,387.1,639.5,387.1,639.5,388.4 M630.5,393.4c-0.7,0-1.4,0.7-1.4,1.4c0,1.4,0.7,2.1,1.4,2.1
	c1.4,0,2.1-0.7,2.1-2.1C632.5,394.1,631.8,393.4,630.5,393.4 M622.2,388.4c-0.7-1.4-1.4-1.4-2.8-0.7c-0.7,0-0.7,1.4-0.7,2.1
	c0.7,1.4,2.1,1.4,2.8,0.7C622.2,390.6,622.9,389.3,622.2,388.4 M622.2,378.1c0.7-0.7,0-2.1-0.7-2.1c-0.7-0.7-2.1-0.7-2.8,0.7
	c0,0.7,0,2.1,0.7,2.1C620.8,379.5,621.5,379.5,622.2,378.1 M630.5,373.3c1.4,0,2.1-0.7,2.1-1.4c0-1.4-0.7-2.1-2.1-2.1
	c-0.7,0-1.4,0.7-1.4,2.1C629.1,372.6,629.8,373.3,630.5,373.3 M630.5,390.6c4.1,0,7.6-3.4,7.6-7.6c0-3.4-3.4-6.9-7.6-6.9
	c-3.4,0-6.9,3.4-6.9,6.9C623.6,387.1,627,390.6,630.5,390.6 M611.2,360.1c-2.1,2.1,1.4,3.4,11-0.7c2.8-1.4,0.7-5.5-2.8-4.1
	C616.7,356.7,613.9,358,611.2,360.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M611.2,360.1c-2.1,2.1,1.4,3.4,11-0.7c2.8-1.4,0.7-5.5-2.8-4.1
	C616.7,356.7,613.9,358,611.2,360.1L611.2,360.1z M640.9,354.6c-2.8-0.7-2.8,2.8,6.2,8.9c2.1,2.1,5.5-1.4,2.8-4.1
	C647.1,357.3,643.7,356,640.9,354.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M640.9,354.6c-2.8-0.7-2.8,2.8,6.2,8.9c2.1,2.1,5.5-1.4,2.8-4.1
	C647.1,357.3,643.7,356,640.9,354.6L640.9,354.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M630.5,442.5c32.5,0,58.9-27,58.9-59.6c0-32.5-26.3-58.9-58.9-58.9
	c-32.5,0-58.9,26.3-58.9,58.9C571.7,415.5,598,442.5,630.5,442.5 M159.2,441.8c32.5,0,58.9-26.3,58.9-58.9
	c0-32.5-26.3-58.9-58.9-58.9c-32.5,0-59.6,26.3-59.6,58.9C99.7,415.5,126.7,441.8,159.2,441.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M158.5,419c20.1,0,36-16,36-36c0-19.4-16-35.4-36-35.4
	c-19.4,0-35.4,16-35.4,35.4C123.3,403,139.2,419,158.5,419 M159.2,441.8c32.5,0,58.9-26.3,58.9-58.9c0-32.5-26.3-58.9-58.9-58.9
	c-32.5,0-59.6,26.3-59.6,58.9C99.7,415.5,126.7,441.8,159.2,441.8L159.2,441.8z M632.5,386.4c0.7,0,0.7-2.1,0-4.1
	c-1.4-1.4-2.8-2.8-3.4-2.1c-1.4,0-0.7,2.1,0,4.1C630.5,385.7,631.8,387.1,632.5,386.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M630.5,403c11,0,20.1-8.9,20.1-20.1c0-10.3-8.9-19.4-20.1-19.4
	c-11,0-20.1,8.9-20.1,19.4C610.4,394.1,619.5,403,630.5,403 M167.6,378.1c0.7,0.7,1.4,1.4,2.8,0.7c0.7-0.7,0.7-1.4,0.7-2.8
	c-0.7-0.7-2.1-1.4-2.8-0.7C166.9,376.1,166.9,377.4,167.6,378.1 M167.6,387.7c-0.7,1.4-0.7,2.1,0.7,2.8c0.7,0.7,2.1,0,2.8-0.7
	c0-0.7,0-2.1-0.7-2.8C168.9,386.4,168.2,387.1,167.6,387.7 M159.2,392.7c-1.4,0-2.1,0.7-2.1,2.1c0,1.4,0.7,2.1,2.1,2.1
	c0.7,0,1.4-0.7,1.4-2.1C160.7,393.4,160,392.7,159.2,392.7 M150.2,387.7c0-0.7-1.4-1.4-2.8-0.7c-0.7,0.7-0.7,2.1-0.7,2.8
	c0.7,0.7,2.1,1.4,2.8,0.7C150.9,389.9,150.9,389.3,150.2,387.7 M150.2,378.1c0.7-0.7,0.7-2.1-0.7-2.8c-0.7-0.7-2.1,0-2.8,0.7
	c0,1.4,0,2.1,0.7,2.8C148.8,379.5,150.2,378.8,150.2,378.1 M159.2,373.3c0.7,0,1.4-0.7,1.4-2.1c0-0.7-0.7-2.1-1.4-2.1
	c-1.4,0-2.1,1.4-2.1,2.1C157.1,372.6,157.8,373.3,159.2,373.3 M159.2,390.6c3.4,0,6.9-3.4,6.9-7.6s-3.4-6.9-6.9-6.9
	c-4.1,0-7.6,2.8-7.6,6.9C151.6,387.1,155,390.6,159.2,390.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M160.7,386.4c0.7-0.7,0.7-2.1,0-4.1c-1.4-2.1-2.8-2.8-3.4-2.8
	c-0.7,0.7-0.7,2.8,0,4.1C158.5,385.7,160,387.1,160.7,386.4 M601.4,388.4c0,2.8,2.8,0.7,4.1-9.6c0.7-2.8-4.1-3.4-4.8,0
	C600.7,382.2,600.7,385.7,601.4,388.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601.4,388.4c0,2.8,2.8,0.7,4.1-9.6c0.7-2.8-4.1-3.4-4.8,0
	C600.7,382.2,600.7,385.7,601.4,388.4z M620.8,411.3c2.1,1.4,2.1-2.1-6.2-8.3c-2.8-2.1-5.5,1.4-2.8,4.1
	C614.6,409.2,617.4,410.6,620.8,411.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M620.8,411.3c2.1,1.4,2.1-2.1-6.2-8.3c-2.8-2.1-5.5,1.4-2.8,4.1
	C614.6,409.2,617.4,410.6,620.8,411.3L620.8,411.3z M650.5,406.5c1.4-2.1-1.4-3.4-11,0.7c-2.8,1.4-1.4,5.5,2.1,4.1
	C645,409.9,647.8,408.5,650.5,406.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M650.5,406.5c1.4-2.1-1.4-3.4-11,0.7c-2.8,1.4-1.4,5.5,2.1,4.1
	C645,409.9,647.8,408.5,650.5,406.5L650.5,406.5z M660.3,378.1c0-2.8-3.4-1.4-4.1,9.6c-0.7,2.8,4.1,3.4,4.1,0
	C661,384.3,661,380.9,660.3,378.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M660.3,378.1c0-2.8-3.4-1.4-4.1,9.6c-0.7,2.8,4.1,3.4,4.1,0
	C661,384.3,661,380.9,660.3,378.1L660.3,378.1z M159.2,403c10.3,0,19.4-8.9,19.4-20.1s-8.9-20.1-19.4-20.1c-11,0-20.1,8.9-20.1,20.1
	S148.2,403,159.2,403 M139.2,360.1c-2.1,1.4,1.4,3.4,11-0.7c2.8-1.4,0.7-5.5-2.1-4.1C144.7,356,142,358,139.2,360.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M139.2,360.1c-2.1,1.4,1.4,3.4,11-0.7c2.8-1.4,0.7-5.5-2.1-4.1
	C144.7,356,142,358,139.2,360.1L139.2,360.1z M129.4,388.4c0,2.8,3.4,0.7,4.1-9.6c0.7-3.4-4.1-4.1-4.8,0
	C128.8,381.6,128.8,385,129.4,388.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M129.4,388.4c0,2.8,3.4,0.7,4.1-9.6c0.7-3.4-4.1-4.1-4.8,0
	C128.8,381.6,128.8,385,129.4,388.4z M148.8,411.3c2.1,0.7,2.1-2.8-6.2-8.9c-2.8-1.4-5.5,2.1-2.8,4.1
	C142.7,408.5,145.4,409.9,148.8,411.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M148.8,411.3c2.1,0.7,2.1-2.8-6.2-8.9c-2.8-1.4-5.5,2.1-2.8,4.1
	C142.7,408.5,145.4,409.9,148.8,411.3z M178.6,405.8c2.1-1.4-1.4-3.4-11,1.4c-2.8,0.7-0.7,5.5,2.1,4.1
	C173.1,409.9,175.8,407.8,178.6,405.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.6,405.8c2.1-1.4-1.4-3.4-11,1.4c-2.8,0.7-0.7,5.5,2.1,4.1
	C173.1,409.9,175.8,407.8,178.6,405.8z M188.3,377.4c0-2.1-3.4-0.7-4.1,9.6c-0.7,3.4,4.1,4.1,4.8,0.7
	C189,384.3,189,380.9,188.3,377.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M188.3,377.4c0-2.1-3.4-0.7-4.1,9.6c-0.7,3.4,4.1,4.1,4.8,0.7
	C189,384.3,189,380.9,188.3,377.4L188.3,377.4z M168.9,354.6c-2.1-0.7-2.1,2.8,6.2,8.9c2.8,2.1,5.5-2.1,2.8-4.1
	C175.1,357.3,172.4,356,168.9,354.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M168.9,354.6c-2.1-0.7-2.1,2.8,6.2,8.9c2.8,2.1,5.5-2.1,2.8-4.1
	C175.1,357.3,172.4,356,168.9,354.6L168.9,354.6z M700.3,381.6c0,0,4.8,27,4.8,34c3.4-1.4,3.4-4.1,3.4-6.2c-0.7-4.1-2.8-18-3.4-24.2
	 M235.4,384.3c0,0,1.4,11.8,0.7,18.7c3.4-2.1,3.4-4.1,3.4-6.2c0-6.2-0.7-11-0.7-11"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M78.3,316.5
		l24.8,0.1l11.4,7.6c0,0,13.1-17.3,41.5-17.3c0,0,13.2-0.1,21.7,1.1c0,0,21.2,1.8,33.6,15.7c0,0,12.7,12.5,21,47.6l2.9,13.1
		c0,0,0.8,1.6,5.1,0.4c0,0,5-0.7,6.7-6.2l0.7-2.6v-0.8l-2.3-9.5h7.2c0,0-2.6-1.6-4.8-13.2c0,0-2.9-14.6-3.9-33.4l-0.3-8.3
		c0,0-0.1-13.8,1.5-33c0,0,2.2-21.6,4.1-24.1c0,0-1.1,1.7-7.7,3.2c0,0-9.8,1.9-23.8,3.4l-48.4,5.1c0,0-33.4,3.4-61.1,7.6
		c0,0-40,6.1-43.7,12.2h2.9c0,0,3.3-0.1,7,3.4c0,0,3.9,3.9,3.9,7.8C78.3,296.4,78.3,316.5,78.3,316.5z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M256.1,365.6h-3.4
		c0,0-2.6-1.8-4.4-11.3c0,0-2.6-12.7-3.6-26.1c0,0-1.2-13.5-1-21.6c0,0,0.1-12.5,1.2-25.4c0,0,0.8-9.4,1.8-15h174.3V358
		c0,0,0,7.2-7.6,7.6h-28.3h-41.3h-47.3L256.1,365.6L256.1,365.6z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M283.8,257.7v-13.2
		c0,0-0.4-4.7,4.8-6.2c0,0,2.2-0.6,4.7,0.3c0,0,2.9,1.1,2.9,2.9v18c0,0-0.8,4.1-6.2,4.4C290,263.9,284.2,264.4,283.8,257.7z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M287.9,232.3
		c0,0,21.9-22.4,37.8-31.6c0,0,16.6-11.6,36.9-11.3h37.3c0,0,6.2,0.1,7.2,7.6v60.3c0,0,0.1,6.5-7.2,7.6H290.1v-0.7
		c0,0,5-0.1,6.2-4.4v-18c0,0-0.1-2.1-3.4-3c0,0-1.9-0.7-5,0.1L287.9,232.3L287.9,232.3z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M440.2,375.4
		h111.4c0,0,0.7,5.6,4.8,7.3c0,0,2.3,0.7,3.6-0.4c1.2-1.1,1.2-2.5,1.4-4l0.5-5.5c0,0,2.8-36.3,27.9-52.7c0,0,16-12.5,42.1-11.7
		c0,0,21.7-1.1,38.2,11.6c0,0,13.8,9.4,22.3,34c0,0,4.7,13.2,8.1,27.6c0,0,0.8,2.5,3.7,2.6c0,0,2.2,0.3,4.5-3.2c0,0,4.5-5.9,7.6-7.4
		c0,0,2.1-1.5,5.6-2.2c0,0,33.7-7.4,70-8.4h4.5c0,0,5,0.4,8.9-8.9c0,0,3.9-8.3,4.8-24.9h-7c0,0-8.9-2.3-9.5-11v-18
		c0,0-0.1-11.1,3.2-16c0,0,3.6-5.9,11.1-6.2h-12.7H440.2V375.4z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="159"
              cy="382.9"
              r="58.9"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="630.7"
              cy="383.3"
              r="59.2"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(RegularCabPickup2DrLeft);
