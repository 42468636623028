import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup2DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M749.9,246.3c-1.2-3.2-0.2-5.5,3-7l24.1-13.1l-0.4-3.4H518.5c0.5,7.8,1,15.8,1.3,23.9
	c0.1,6.1,0.2,12.1,0.2,18c-0.1,8.7-0.4,17.4-1,25.8c-0.7,9.5-1.6,18.7-2.9,27.7c-0.8,5.8-1.8,10.6-3,14l-0.7,1.8
	c-0.6,2.3-0.4,3.9,0.7,4.5c0.6,0.4,1.6,0.5,2.8,0.2l51.6,1.5l1.2-10.2l-5.7-1.7c-2.1-0.6-3.4-1.5-3.9-2.8c-0.2-0.7-0.2-1.6-0.1-2.6
	c3.8-17,9.4-31.2,16.9-42.8c8.6-11.7,19.7-18.3,33.2-20.2c11.7-1.9,24.2-3.2,37.4-3.6l2.2-0.1c7-0.2,14.2-0.2,21.7,0
	c3.3,0,6.4,0.2,9.4,0.7l3.3,0.7c7.7,1.8,13.6,5.6,17.9,11.3l1.2,1.7c1,1.6,1.9,3.2,2.9,4.9c6.3,11.3,11.9,26.1,16.9,44.5l0.1,0.5
	c2.3-0.7,4.4-2.2,6-4.4l-12.4-24.4c2.7-4.5,7.7-6.8,14.8-6.8h28.3c-1.7-13.2-4-24.5-7-34L749.9,246.3L749.9,246.3z M600.5,258
	c1.1-0.7,1.6-1.9,1.6-3.8"
          />
          <path
            fill="#FFFFFF"
            d="M648.6,256.1l-2.2,0.1c-13.2,0.5-25.8,1.7-37.4,3.6c-13.5,1.8-24.5,8.5-33.2,20.2
	c-7.5,11.4-13.1,25.8-16.9,42.8c-0.2,1-0.1,1.8,0.1,2.6c3.4-1.6,6.1-6,7.8-12.9c5.3-18.8,13.4-31.7,23.9-38.3
	c7.7-5.1,19.4-8.3,35.6-9.6l22.4-1.3c8.9-0.2,17.3-0.1,25.3,0.5c10.3,0.7,18.3,5.6,24.1,14.7l1,1.6c6.7,11.5,12.8,25.4,18.2,41.4
	l4.6-1l-0.1-0.5c-5-18.3-10.6-33.2-16.9-44.5c-1-1.7-1.9-3.3-2.9-4.9l-1.2-1.7c-4.3-5.6-10.2-9.4-17.9-11.3l-3.3-0.7
	c-2.9-0.5-6-0.7-9.4-0.7C662.8,255.9,655.7,255.9,648.6,256.1L648.6,256.1z"
          />
          <path
            fill="#FFFFFF"
            d="M648.6,263.1l-22.4,1.3c-16,1.3-27.9,4.5-35.6,9.6c-10.6,6.7-18.5,19.4-23.9,38.3
	c-1.7,6.9-4.4,11.3-7.8,12.9c0.5,1.3,1.8,2.3,3.9,2.8l5.7,1.7c4.4-15.7,9.6-28.2,15.7-37.7v-0.1c6.1-8,13.1-13.1,21.1-15.3l1.8-0.5
	c18-3.3,36.3-4.7,55.2-4.6h0.1c6.1-0.2,11.3,0.5,15.7,2.2l2.2,1c0.6,0.2,1.1,0.5,1.6,0.7c1.1,0.6,1.9,1.3,2.8,2.1
	c1.5,1.2,2.7,2.7,3.8,4.3l1.9,3l2.1,3.2c1.1,1.8,2.1,3.6,3.2,5.7l0.1,0.2c1,1.8,1.9,3.9,2.8,6c1.9,4.4,3.8,9.2,5.5,14.3
	c1.1,3,1.9,6,2.6,9l10-2.2c-5.5-16-11.5-29.9-18.2-41.4l-1-1.6c-5.7-9-13.7-13.9-24.1-14.7C665.9,263.1,657.5,262.9,648.6,263.1
	L648.6,263.1z"
          />
          <path
            fill="#656565"
            d="M662.8,271.8c-18.8-0.2-37.3,1.3-55.2,4.6l-1.8,0.5c-8.1,2.2-15.2,7.3-21.1,15.3v0.1
	c-6.2,9.5-11.4,22-15.7,37.7l1.7,0.5l1.6-5.3c1.2-4.3,2.6-8.1,4-11.9c3-7.7,6.3-14.3,9.8-19.9c5.8-7.7,12.5-12.5,20.3-14.7l1.8-0.5
	c17.9-3.3,36.1-4.7,54.8-4.5c5.8-0.2,10.8,0.5,14.9,2.1l3.5,1.7c0.9,0.5,1.7,1.2,2.6,1.8c1.3,1.2,2.4,2.4,3.4,3.9l2.1,3l2.1,3
	c1.1,1.7,2.1,3.5,3.2,5.5l0.1,0.2l2.8,5.8c1.7,3.9,3.3,8.1,4.9,12.6l0.6,1.7c1.6,4.5,2.8,9.1,3.4,13.7c0.2,2.4,0.5,5,0.5,7.4
	c0.2,0,0.4,0.1,0.6,0.4c0.1,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5-0.1,0.6-0.2c0.1-0.2,0.2-0.4,0.2-0.6c-0.1-4.1-0.7-8.3-1.6-12.4
	c-0.7-3-1.6-6-2.5-9c-1.7-5.2-3.5-10-5.5-14.3c-1-2.1-1.8-4-2.8-6l-0.1-0.2c-1.1-2.1-2.1-3.9-3.2-5.7l-2.1-3.2l-1.9-3
	c-1.1-1.6-2.3-3-3.8-4.2c-0.8-0.7-1.8-1.5-2.8-2.1c-0.5-0.2-1.1-0.6-1.6-0.7l-2.2-1C674.1,272.4,668.9,271.6,662.8,271.8
	L662.8,271.8z"
          />
          <path
            fill="#FFFFFF"
            d="M480.1,145.1c-2.6,1-4.4,2.2-5.7,3.6l14.1,0.2l7,1c-1.1-0.2-1.8-0.9-2.2-1.8l-0.2-0.9l0.5-3.4
	c0.1-1.6-0.1-2.4-1.3-3c-0.4,0.4-2.7,1.2-5.1,1.7l-4.4,1.6C481.7,144.4,480.8,144.8,480.1,145.1L480.1,145.1z M491.4,159.5l4.4-0.1
	c-9.2-0.5-23.1-0.7-41.3-1l-30-0.1l-7.2,72.8c34.5-1.1,58.6-3.8,72.3-8.1c4.5-1.9,6.8-5.2,6.9-10v-1.1c-0.2-19.6-1.8-33.2-4.9-40.7
	c0.2,1.1,0.4,2.2,0.5,3.5c1.2,11.8,1.8,22.8,1.7,33.2c0.6,7.2-3,11.3-10.7,12.5c-15.3,2.7-30.7,4.4-46.3,4.9l-0.9,0.1
	c-4.7,0.4-7-1.7-7.2-6.3c0.1-18.3,1-35.5,2.7-51.4c1-4.4,3.2-6.4,6.4-6.2c15.1-0.7,30.4-0.4,45.9,1.1c3.9,1,6.3,3.8,7.5,8.4l0.1,0.4
	c3,7.5,4.7,21.1,4.9,40.7v1.1c-0.1,4.7-2.4,8-6.9,10c-13.7,4.4-37.8,7.2-72.3,8.1c-2.2,22.5-2.9,42.4-2.3,59.8l38.4-0.2l60.1-0.1
	c0.4-6.8,0.6-13.9,0.5-21.1c0.4-37.5-1.7-69.2-6-95.1C505.9,164.1,500.4,159.3,491.4,159.5L491.4,159.5z"
          />
          <path
            fill="#FFFFFF"
            d="M518.5,222.7h-2.1c0.6,7.8,1.1,15.8,1.3,23.9c0.1,6.1,0.2,12.1,0.1,18c-0.1,8.7-0.4,17.4-1,25.8
	c-0.7,9.5-1.6,18.7-2.9,27.7c-0.4,2.3-0.7,4.5-1.1,6.4c-0.7,3.9-1.7,7-2.8,9.4h2.1l0.7-1.8c1.2-3.4,2.2-8,3-14
	c1.2-9,2.2-18.2,2.9-27.7c0.6-8.4,1-17,1-25.8c0-5.8,0-11.9-0.2-18C519.5,238.5,519,230.5,518.5,222.7L518.5,222.7z"
          />
          <path
            fill="#FFFFFF"
            d="M517,290.4h-3.6c-0.7,12.1-2.3,23.4-4.6,34c-2.3,5.7-8.9,8.9-19.7,9.5l21.3,0.1c1.1-2.3,1.9-5.5,2.8-9.4
	c0.4-1.9,0.7-4.1,1.1-6.4C515.5,309.2,516.4,299.9,517,290.4L517,290.4z"
          />
          <path
            fill="#FFFFFF"
            d="M513.4,290.4l-60.1,0.1l-38.4,0.2c0.6,17,2.5,31.7,5.8,43.9l68.3-0.7c10.9-0.5,17.5-3.6,19.7-9.5
	C511.2,313.9,512.7,302.5,513.4,290.4L513.4,290.4z"
          />
          <path
            fill="#FFFFFF"
            d="M505.6,338.9c1.1-0.2,2.1-1,3-2.2l-63.7,0.7l-175.7,1.2c-5.5,0.5-9.5-2.2-12.3-8c-0.4-0.5-0.7-1.1-1.1-1.8
	c-0.5-1-0.7-2.1-1-3.2c-0.1-0.5-0.1-1-0.2-1.6c-2.4-18.3-2.9-37.9-1.6-58.4c0.5-12.1,1.6-22.2,3.4-30.2l-8.1,0.2l8.1-0.2
	c1.1-5,2.4-9.2,3.9-12.6h-29l-10-0.4c-10.1,0-20,0.4-29.9,1.2l-14.6,1.5l-19.8,2.3c-10,1.2-19.6,2.7-28.8,4.1
	c-21.6,5-35.1,9.7-40.3,14.2l9.4-0.7c11.2-0.9,18,0.7,20.4,4.7c28.6-3.5,59.2-5.2,91.8-5.1c16.5,0.4,27,3.5,31.5,9.5
	c-4.5-5.8-14.9-9.1-31.5-9.5c-32.6-0.1-63.2,1.7-91.8,5.1c0.9,1.7,0.9,3.5,0.1,5.3c-0.2,0.7-0.6,1.3-1,1.8l-1.3,1.6l-3.6,3l-2.9,2.1
	c-2.1,1.5-4.9,3-8.3,4.7l-2.9,1.5l12.8,11.1c2.1-2.8,4.3-5.3,6.6-7.7c5.5-5.3,14.7-8.7,27.8-10.4c18.2-2.2,36.9-3,56.1-2.5
	c11.9,0.7,20.4,3.8,25.8,9.2c1.2,1.2,2.3,2.5,3.2,4l4.5,8.3c3.3,6.7,6.6,14.7,9.7,23.9c2.2,6.6,4.2,13.7,6.3,21.5l4.5,15.9l60-1.9
	l23.9-0.7l111.3-0.9l12.3-0.2h2.8l4-0.1h1.6L505.6,338.9L505.6,338.9z"
          />
          <path
            fill="#FFFFFF"
            d="M508.7,336.9c0.6-0.7,1.1-1.7,1.7-2.9l-21.3-0.1l-68.3,0.7l-149.4,2.1c-6.9-0.5-11.7-2.5-14.2-6
	c2.8,5.8,6.8,8.5,12.3,8l175.7-1.2L508.7,336.9z"
          />
          <path
            fill="#656565"
            d="M721.7,320.4l-4.6,1l-10,2.2c1,4.1,1.5,8.3,1.6,12.4c0,0.2-0.1,0.5-0.2,0.6s-0.4,0.2-0.6,0.2
	c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c0.2,1.5,1.2,2.2,3.2,1.9l13.8-3c0-2.2-0.2-4.3-0.4-6.3
	C723.1,325.9,722.5,323.2,721.7,320.4L721.7,320.4z"
          />
          <path
            fill="#FFFFFF"
            d="M779,244.9l-3.8-6.9c0.7,5,1.2,10,1.5,14.8l2.4-2.9C780.2,248.6,780.2,246.9,779,244.9L779,244.9z"
          />
          <path fill="#056DA5" d="M762.2,269l0.7-0.1h-0.7" />
          <path
            fill="#FFFFFF"
            d="M764,288.6h5.2v-2.3l-8.7,0.4c0.7,8.3-0.6,19.9-4,35.1l-0.4,1.8l-2.3,3.6l4.9-0.7v-0.2h-0.5
	c-0.2-0.1-0.4-0.2-0.5-0.5c-0.1-0.2-0.1-0.5,0-0.7c3.5-8.5,5.2-20.4,5.2-35.7c0-0.2,0.1-0.5,0.2-0.6c0.1-0.1,0.4-0.2,0.6-0.2
	L764,288.6L764,288.6z"
          />
          <path
            fill="#FFFFFF"
            d="M769.2,288.6H764l0.4,0.2c0.1,0.1,0.2,0.4,0.2,0.6c0,15.5-1.8,27.7-5.3,36.3c-0.1,0.2-0.2,0.4-0.5,0.5h-0.1
	l4.1-0.5l9.8-1.2l2.3-1l8.4-3.6v-27.7c-1.2-2.7-2.8-3.9-4.9-3.5h-6.7L769.2,288.6L769.2,288.6z"
          />
          <path
            fill="#FFFFFF"
            d="M764,288.6h-0.2c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,15.3-1.7,27.2-5.2,35.7
	c-0.1,0.2-0.1,0.4,0,0.7c0.1,0.2,0.2,0.4,0.5,0.5h0.6c0.2-0.1,0.4-0.2,0.5-0.5c3.5-8.6,5.3-20.8,5.3-36.3c0-0.2-0.1-0.5-0.2-0.6
	L764,288.6L764,288.6z M760.5,286.7l8.7-0.4l2.5-0.1l2.4-2.7l-14.1,0.1"
          />
          <path
            fill="#FFFFFF"
            d="M769.2,286.3v2.3h2.5v-2.4 M783.3,323.9V320l-8.4,3.6l0.7,3.6"
          />
          <path
            fill="#FFFFFF"
            d="M775,323.6l-2.3,1.1l-9.8,1.2l-4.1,0.6l-0.1,3.3l11.7-1.7l5.5-0.9"
          />
          <path fill="#056DA5" d="M758.7,326.4v0.1l4.1-0.6" />
          <path
            fill="#EBEBEB"
            d="M56,248.1c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6v28.7l-0.2,1.9c-0.7,4.3-2.1,8-3.8,11.2l-2.4,1.6
	c-0.2,0.1-0.4,0.4-0.4,0.5c0,0.2,0,0.5,0.1,0.7l0.2,0.2l0.2,0.1l0.7-0.1l2.5-1.7l0.2-0.2c2.2-3.8,3.6-8.5,4.4-14.1v-28.8
	c0-0.1-0.1-0.4-0.2-0.5C56.5,248.2,56.2,248.1,56,248.1L56,248.1z"
          />
          <path
            fill="#FFFFFF"
            d="M58.6,242.4l-1.7,4v31.2c-0.7,5.6-2.2,10.2-4.4,14.1l-0.2,0.2l-2.5,1.7l-0.7,0.1l-0.2-0.1l1.5,3.6l6.8,6.4
	l6-12.1c-3.8-0.1-5.7-0.9-5.7-1.9l2.4-7.8l0.2-0.9c1.5-1.9,3.5-3.4,6.2-4.4l4.5-0.5l-2.4-7.2c-1.5-0.1-2.1-0.7-1.9-2.1v-10.3
	c0-1.1,0.2-1.9,0.7-2.7l0.4-0.5c0.5-0.7,1.1-1.2,1.9-1.7l-0.1-1.2c-3,1-4.9,2.7-5.3,5.2v10.4c-0.1,2.2,0.6,3.4,2.1,3.8H68h-2.2
	c-1.5-0.4-2.1-1.6-2.1-3.8v-10.4c0.5-2.6,2.3-4.3,5.3-5.2l-0.5-14.2L58.6,242.4L58.6,242.4z"
          />
          <path
            fill="#656565"
            d="M55.2,254.6c-1,0.1-1.6,0.9-1.8,2.1l-1.7,18.2c-0.1,1.5,1.1,2.1,3.5,2.1V254.6L55.2,254.6z"
          />
          <path
            fill="#FFFFFF"
            d="M54.9,279.5c-2.1-0.2-3,0.6-3,2.5l-0.7,8.5C52.8,287.6,54.2,283.8,54.9,279.5L54.9,279.5z M68.9,236
	l-0.2,0.2l0.7,14.1c5.3-5.1,13.1-9.1,23.3-12c11.2-2.4,23.1-4.7,35.8-6.7c9.2-1.5,18.8-2.8,28.8-4.1l19.8-2.3l14.6-1.5
	c10-0.9,19.8-1.2,29.9-1.2l10,0.4c-6.3-2.8-18-3.9-35.2-3.2C143.2,222.2,100.7,227.7,68.9,236L68.9,236z"
          />
          <path
            fill="#FFFFFF"
            d="M87.5,247.3l0.5-1.5c-8.4,1-14.6,2.4-18.7,4.5l0.1,1.2c0.6-0.4,1.3-0.6,2.1-0.9
	C76.5,249.2,81.9,248,87.5,247.3L87.5,247.3z M88.1,245.9l-0.5,1.5l10-0.6c8.6-0.4,14.1,0.7,16.6,3.2c0.6,0.6,1.1,1.3,1.3,2.2
	l0.1,1.6v0.1c0,0.5,0,1-0.1,1.5c-0.2,1-0.7,1.8-1.5,2.6l-1.5,1.2l-2.7,1.8c-0.1,0-0.2,0.1-0.2,0.1l-13.6,7.3l2.1,1.7l2.9-1.5
	c3.4-1.7,6.2-3.3,8.3-4.7l2.9-2.1l3.6-3l1.3-1.6c0.4-0.6,0.7-1.2,1-1.8c0.7-1.8,0.7-3.6-0.1-5.3c-2.4-4-9.2-5.6-20.4-4.7L88.1,245.9
	L88.1,245.9z"
          />
          <path
            fill="#FFFFFF"
            d="M96,268.1c-0.7,0.4-1.6,0.7-2.3,1c-3.3,1.3-6.7,2.4-10.3,3.2l-2.9,0.6l-2.7,0.5l-4,2.4l-2.9,0.4l-4.5,0.5
	c-2.7,1-4.7,2.4-6.2,4.4l-0.2,0.9c1.2-1.1,3-1.7,5.7-1.9h6.7c2.1,0.4,3.4,2.2,4,5.3v30.1c0.1,3.8-2.1,6.9-6.7,9.4l-10,4.6l17.9,9.5
	H81l22.6-8.6l1.5-4.5l-14.7-6.9c-4.5-2.4-6.9-6.4-7.5-11.9l-1-15.5c-0.6-8.4,2.9-14.6,10.4-18.7l5.7-2.7L96,268.1L96,268.1z"
          />
          <path
            fill="#FFFFFF"
            d="M74.1,269h-5.7l2.3,7.2l2.9-0.4l4-2.4 M65.5,279.9c-2.5,0.2-4.5,0.9-5.7,1.9l-2.4,7.8h11.1
	c2.3-0.1,3.4,0.4,3.5,1.5v15.7c0.1,2.3-1,4-3.2,5.2l-3.5,1.6l-5.5,3l-3.9,2.7l3.5,10l10-4.6c4.6-2.4,6.8-5.5,6.7-9.4v-30.1
	c-0.5-3.2-1.8-4.9-4-5.3L65.5,279.9L65.5,279.9z"
          />
          <path
            fill="#FFFFFF"
            d="M63,291.7H65l6.8-0.5c-0.1-1-1.3-1.5-3.5-1.5H57.2C57.5,290.8,59.3,291.6,63,291.7z"
          />
          <path
            fill="#FFFFFF"
            d="M65.1,291.7h-1.9l-6,12.1l5-0.4l0.2-6v-0.1C63.1,295.1,63.9,293.3,65.1,291.7L65.1,291.7z"
          />
          <path
            fill="#FFFFFF"
            d="M71.9,291.1l-6.8,0.5c-1.2,1.6-2.1,3.5-2.7,5.7v0.1l-0.2,6l-0.2,3.4v0.4l1.9,5l1.7-0.6l-1.9-4.7l0.4-9
	c0.2-1.1,0.6-1.9,1-2.7l4.9-0.2V306c-0.6,3.4-2.1,5.1-4.4,5.3l-1.7,0.6l0.7,1.8l0.7-0.2l3.5-1.6c2.2-1.2,3.2-2.9,3.2-5.2L71.9,291.1
	L71.9,291.1z M92.2,272.5c-7.5,4-10.9,10.3-10.4,18.7l1,15.5c0.6,5.5,3,9.4,7.5,11.9l14.7,6.9l0.6-1.7l-7.4-2.9
	c-1.7-0.6-2.4-1.6-2.6-2.9c0-0.5,0-1.1,0.2-1.6c3-14.3,7.9-26.1,14.7-35.6l-12.8-11.1L92.2,272.5L92.2,272.5z"
          />
          <path
            fill="#FFFFFF"
            d="M117.2,273.2c-2.3,2.3-4.5,4.9-6.6,7.7c-6.8,9.4-11.8,21.3-14.7,35.6c-0.2,0.6-0.2,1.1-0.2,1.6
	c2.8-1.7,5.3-5.5,7.5-11.5c7.2-17.5,15.4-28.2,24.5-32.2c8.9-3.4,24.4-5.6,46.4-6.7c20.2-0.7,33.4,0.4,39.8,3.2
	c6.9,3.3,13.1,10.6,18.5,21.9c1.8,4.1,3.6,8.5,5.3,13.2c2.8,7.7,5.3,16,7.8,25.1c1.1,4,2.1,8.1,3,12.4l6.4-0.2l-4.5-15.9
	c-2.1-7.8-4.1-14.9-6.3-21.5c-3.2-9.4-6.3-17.4-9.7-23.9l-4.5-8.3c-0.9-1.5-1.9-2.8-3.2-4c-5.3-5.5-14-8.6-25.8-9.2
	c-19.2-0.5-37.9,0.4-56.1,2.5C131.9,264.5,122.7,268,117.2,273.2L117.2,273.2z M69.4,250.4c4.1-2.1,10.3-3.6,18.7-4.5
	c5.3-4.5,18.8-9.2,40.3-14.2c-12.8,2.1-24.7,4.3-35.8,6.7C82.5,241.3,74.7,245.3,69.4,250.4L69.4,250.4z M103.6,331.3l-0.1-1.1
	l-22.6,8.6c0.4,0.7-0.2,2.3-1.8,4.9l7.3,17.4c-1.1,2.5,0.4,5.3,4.6,8.4l10-9.7c2.4-1.6,3.5-5.2,3.4-11.2L103.6,331.3L103.6,331.3z"
          />
          <path
            fill="#FFFFFF"
            d="M98.3,321l7.4,2.9c3.5-10.6,7.2-19.2,10.8-25.8c5.3-9.7,12.9-15.7,22.6-17.7c17.4-2.7,34-3.9,50.1-3.5h0.9
	c17.1,0,28.6,6.4,34.3,19.4c1.3,2.9,2.8,6.1,4,9.5c2.9,7.3,5.6,15.5,8,24.8l0.1,0.4c1.1,4,2.1,8.3,3.2,12.8l9.4-0.2
	c-1-4.3-2.1-8.4-3-12.4c-2.4-9.2-5.1-17.6-7.8-25.1c-1.7-4.7-3.5-9.1-5.3-13.2c-5.3-11.3-11.5-18.6-18.5-21.9
	c-6.4-2.8-19.8-3.9-39.8-3.2c-22.1,1.1-37.5,3.4-46.4,6.7c-9.1,3.9-17.4,14.7-24.5,32.2c-2.2,6-4.7,9.8-7.5,11.5
	C95.7,319.4,96.6,320.4,98.3,321L98.3,321z M65.1,295c-0.4,0.7-0.7,1.6-1,2.7l-0.4,9l1.9,4.7c2.3-0.2,3.8-2.1,4.4-5.3v-11.2
	L65.1,295L65.1,295z M59.8,316.6l5.6-3l-0.7,0.4l-0.7-1.9l-6.8-0.9"
          />
          <path
            fill="#FFFFFF"
            d="M77.4,338.7l-17.9-9.5l-3.5-10l3.9-2.7l-2.7-5.5c-3.3,0.7-6.3,1.6-8.9,2.7l7.2,16.2l15.2,8.7L77.4,338.7
	L77.4,338.7z"
          />
          <path
            fill="#FFFFFF"
            d="M77.4,338.7h-6.9L55.3,330l-7.2-16.2c-1.2,0.5-2.3,1.1-3.4,1.7l-0.7,0.2l5.3,13c0.4,1.3,2.2,2.7,5.3,4.4
	l14.1,8.1l8.6-0.1L77.4,338.7L77.4,338.7z"
          />
          <path
            fill="#FFFFFF"
            d="M80.9,338.7h-3.5l0.5,0.2l-0.5-0.2l0.1,2.4l-1.5,2.7l3-0.2C80.7,341,81.3,339.4,80.9,338.7L80.9,338.7z"
          />
          <path
            fill="#FFFFFF"
            d="M65.2,342.8c2.3,1,6,1.2,10.9,1l1.5-2.7l-8.6,0.1l-14.1-8.1c-3.2-1.6-5-3-5.3-4.4l-5.3-13
	c-1.1,0.6-1.5,1.6-1,2.9l1.7,9c0.2,2.1,1.7,3.6,4.3,4.9L65.2,342.8L65.2,342.8z M424.3,158.3h-0.6c-18.6,0.1-37.4,1.2-56.2,3.2
	c-9.1,1.1-18.5,2.6-28.3,4.6c-8.5,2.2-16,5-22.5,8.4l-0.9,0.5c-5.5,3.2-10.8,6.6-15.8,10c-11.8,8.3-22.1,17.4-31,27.5
	c-3,3.4-5.8,6.9-8.6,10.6h8.7h-8.7c-1.6,3.4-2.9,7.7-3.9,12.6l160.6-4.5L424.3,158.3L424.3,158.3z M412.1,166.2
	c1,2.4,1,6.3,0.1,11.7l-5.7,40v0.1c-0.9,3.5-1.9,6.1-3.5,7.7c-1.2,1.2-2.5,1.8-4,1.8l-117.6,3.3h-0.2c-5-1.2-8.4-2.6-10-4
	c-1-1-1.5-1.8-1.3-2.9c0-1,0.6-1.9,1.6-2.9c1.1-1.6,2.3-3.2,3.4-4.6c-0.2-0.7-0.5-1.6-0.6-2.4c-0.2-3.3,0.7-5.5,3-6.8
	c3.2-2.1,6.7-3.6,10.6-5c6.6-6.3,13.9-12,21.9-17.1l5.8-3.6l1.2-0.6c6.6-3.8,14.1-6.9,22.5-9.5c13.8-3.5,28.4-5.8,43.6-6.8l24.2-1
	c2.2,0.1,3.6,0.6,4.5,1.6C411.7,165.3,412,165.7,412.1,166.2L412.1,166.2z"
          />
          <path
            fill="#FFFFFF"
            d="M412.2,177.9c0.9-5.3,0.7-9.2-0.1-11.7c-0.1-0.5-0.2-0.9-0.6-1.2c-0.9-1-2.3-1.6-4.5-1.6l-24.1,1
	c-15.2,1.1-29.8,3.4-43.6,6.8c-8.4,2.6-15.9,5.7-22.5,9.5l-1.2,0.6l-5.8,3.6c-8,5.2-15.3,10.9-21.9,17.1c1.2-0.4,2.4-0.7,3.6-1.1
	h0.2l3.5-3.2c5.5-4.6,11.3-8.9,17.6-12.9c1.1-0.7,2.3-1.5,3.5-2.1l0.4-0.2c6.6-3.9,14.1-7,22.5-9.6c14-3.5,28.5-5.8,43.9-6.9l24.2-1
	c1.3,0,2.3,0.5,3,1.5c1,2.1,1.1,5.7,0.2,11.1l-5.7,40v-0.1c-1.1,5.1-3.2,7.8-6,8.1l-109,3l-4.5,0.1l-0.4,0.1l-0.9,0.1l-2.8,0.1
	L281,229c-4.3-1-7-2.2-8.5-3.4c-0.5-0.5-0.7-1-0.7-1.6s0.4-1.2,1.1-1.8l0.1-0.1l2.8-4c-0.4-0.6-0.6-1.1-0.9-1.7
	c-1.2,1.6-2.3,3-3.4,4.6c-1,1-1.6,1.9-1.6,2.9c-0.1,1,0.4,1.9,1.3,2.9c1.6,1.5,4.9,2.8,10,4h0.2l117.6-3.3c1.5,0,2.8-0.6,4-1.8
	c1.6-1.6,2.7-4.1,3.5-7.7V218L412.2,177.9L412.2,177.9z"
          />
          <path
            fill="#FFFFFF"
            d="M291.4,200.9c-1.2,0.4-2.4,0.7-3.6,1.1c-3.9,1.3-7.4,3-10.6,5c-2.3,1.3-3.3,3.5-3,6.8
	c0.1,0.9,0.4,1.7,0.6,2.4c0.2,0.6,0.5,1.1,0.9,1.7c1,1.5,2.3,2.8,4.1,3.8c1.7,1.2,3.5,2.1,5.2,2.8c2.2,0.9,4.3,1.3,6.3,1.5
	c2.1,0.1,4.1-0.1,6.2-0.6l1-0.4c2.9-1.7,4.4-4.7,4.3-9.1l-2.1,0.1h-0.7c-6,0.2-11.4,1.1-16.5,2.3c-1.9,0.5-3.8,1-5.6,1.6
	c1.8-0.6,3.6-1.2,5.6-1.6c-1.6-0.9-1-1.9,1.6-3.3c4.5-1.9,9.1-2.7,13.8-2.2c1.1,0.1,1.5,1.2,1.1,3.2h0.7l-1.6-7.8
	c-1.2-4.1-2.9-6.6-5-7.5l-0.9-0.2l-1.9,0.4L291.4,200.9L291.4,200.9z"
          />
          <path
            fill="#FFFFFF"
            d="M299.3,202.6c-1.2-1.6-2.8-2.3-4.9-2.2l-0.1,0.4c2.1,0.9,3.8,3.4,5,7.5l1.6,7.8l2.1-0.1v-1
	C302.1,209.4,300.9,205.3,299.3,202.6L299.3,202.6z"
          />
          <path fill="#056DA5" d="M294.4,200.7l0.1-0.2h-0.9" />
          <path
            fill="#FFFFFF"
            d="M293.6,200.5h0.9l0.7-2.7l-3.5,3.2 M299.2,213c-4.7-0.5-9.4,0.2-13.9,2.2c-2.5,1.2-3,2.3-1.6,3.3
	c5-1.2,10.5-1.9,16.5-2.3C300.6,214.1,300.3,213.1,299.2,213L299.2,213z M285.2,228.9l4.5-0.1v-1.8"
          />
          <path fill="#056DA5" d="M284.9,229.1l0.4-0.2l-1,0.1" />
          <path
            fill="#FFFFFF"
            d="M285.2,228.9l4.5-2.1l1.7-0.7c-2.1-0.1-4.3-0.6-6.3-1.5l-8,2.9l7.2,1.5L285.2,228.9L285.2,228.9z"
          />
          <path
            fill="#FFFFFF"
            d="M271.6,224c0,0.5,0.2,1,0.7,1.6c1.5,1.2,4.2,2.4,8.5,3.4l0.5,0.1l2.9-0.1l-7.2-1.5l8-2.9
	c-1.7-0.7-3.5-1.6-5.2-2.8c-1.8-1-3.2-2.3-4.1-3.8l-2.9,4c0,0-0.1,0-0.1,0.1C272.1,222.8,271.8,223.4,271.6,224L271.6,224z
	 M414.9,290.8c-0.6-17.4,0.1-37.3,2.3-59.8l-160.6,4.5c-1.7,8-2.9,18.1-3.4,30.2c-1.5,20.7-1,40.1,1.6,58.4c0,0.5,0.1,1.1,0.2,1.6
	c0.2,1.1,0.5,2.2,1,3.2l16.8-26.6c4.7-7.7,13.2-11.3,25.8-10.8L414.9,290.8L414.9,290.8z M396,242.6c3,0.2,5,1.1,5.8,2.7l0.2-0.2
	h2.8c2.1,0.1,3.9,0.5,5.7,1.1c1,0.6,1.6,1.5,1.7,2.6v1.5c0,1-0.6,1.6-1.7,1.9c-1.7,0.6-3.5,1-5.6,1.3l-2.4,0.2
	c-1,1.8-3.4,2.8-7.4,3.2h-3.5c-3.5,0.2-6.1-0.7-7.7-2.8c-4.5,0-7.9-0.7-10.1-1.9l-0.6-0.5c-0.7-0.7-0.9-1.6-0.6-2.6l0.1-0.2
	c0.6-1.7,4.1-2.8,10.6-3.6c1.8-1.7,4.3-2.6,7.2-2.6L396,242.6L396,242.6z"
          />
          <path
            fill="#FFFFFF"
            d="M396.4,247.8c1.8-0.1,3.5-1,5.3-2.4c-1.2,0.7-2.5,1.2-4,1.2h-10.4c-2.1,0.1-3.4-0.4-3.9-1.3
	c-6.6,0.7-10.1,1.9-10.6,3.6l-0.1,0.2c4.7-1,11.4-1.3,19.9-1.3L396.4,247.8L396.4,247.8z"
          />
          <path
            fill="#FFFFFF"
            d="M401.9,245.4c-1.8,1.5-3.5,2.3-5.3,2.4h-3.9c-8.5,0-15.2,0.4-19.9,1.3c-0.2,1-0.1,1.8,0.6,2.6
	c5.3-0.9,12.3-1.2,20.8-1.3h3.3c2.1,0.1,3.9,1.3,5.2,3.5l2.4-0.2v-8.4c-1-0.1-1.8-0.1-2.8,0L401.9,245.4L401.9,245.4z"
          />
          <path
            fill="#FFFFFF"
            d="M401.8,245.4c-0.7-1.6-2.7-2.6-5.8-2.7h-5.5c-3,0.1-5.3,1-7.2,2.6c0.6,1.1,1.8,1.5,3.9,1.3h10.4
	C399.3,246.5,400.7,246.1,401.8,245.4L401.8,245.4z"
          />
          <path fill="#056DA5" d="M402,245.2l-0.2,0.2h0.1" />
          <path
            fill="#FFFFFF"
            d="M410.5,246.3c-1.7-0.6-3.6-1-5.7-1.1v8.4c2.1-0.2,3.9-0.7,5.6-1.3c1.1-0.4,1.6-1,1.7-1.9v-1.5
	C412.1,247.7,411.6,246.9,410.5,246.3L410.5,246.3z M406.7,247c0.4-0.4,0.7-0.5,1.2-0.5s0.9,0.1,1.2,0.5c0.4,0.4,0.5,0.7,0.5,1.2
	s-0.1,1-0.5,1.2c-0.4,0.4-0.7,0.5-1.2,0.5s-1-0.2-1.2-0.5c-0.4-0.4-0.5-0.7-0.5-1.2C406.2,247.8,406.4,247.3,406.7,247L406.7,247z"
          />
          <path
            fill="#FFFFFF"
            d="M408.1,246.5c-0.5,0-1,0.1-1.2,0.5c-0.4,0.4-0.5,0.7-0.5,1.2s0.2,1,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5
	s0.9-0.2,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.9-0.5-1.2S408.6,246.5,408.1,246.5L408.1,246.5z M391.5,256.9h3.5
	c4-0.2,6.6-1.3,7.4-3.2c-0.7-1.2-2.5-1.8-5.3-1.9h-8.6c-2.2-0.1-3.6,0.7-4.6,2.2C385.5,256.2,388,257.2,391.5,256.9L391.5,256.9z"
          />
          <path
            fill="#FFFFFF"
            d="M383.9,254.1c0.9-1.6,2.4-2.3,4.6-2.2h8.6c2.8,0.1,4.6,0.7,5.3,1.9c-1.3-2.2-3-3.4-5.2-3.5H394
	c-8.5,0.1-15.4,0.5-20.8,1.3l0.6,0.5C376,253.4,379.4,254,383.9,254.1L383.9,254.1z M414.9,290.8l-116.4,0.7
	c-12.4-0.5-21,3.2-25.8,10.8L256,329c0.2,0.6,0.7,1.2,1.1,1.8c2.5,3.4,7.3,5.3,14.2,6l149.4-2.1
	C417.4,322.4,415.5,307.8,414.9,290.8L414.9,290.8z M730.2,284.6c-7.2,0.1-12.1,2.3-14.8,6.8l12.4,24.4c-1.6,2.2-3.6,3.6-6,4.4
	c0.7,2.8,1.2,5.6,1.6,8.6l-13.1-1.2l44-3.9l0.5-1.9l0.1-0.7c3.5-16,4.9-28.2,3.6-36.3v-0.1L730.2,284.6L730.2,284.6z"
          />
          <path
            fill="#FFFFFF"
            d="M774.2,232.4l2.9-6.3l-24.1,13.1c-3.3,1.6-4.3,3.9-3,7l1.6,4.5c3,9.5,5.3,20.8,7,34h1.2h-1.2v0.1
	c1.1,8.1-0.1,20.3-3.6,36.3l-0.1,0.7l-0.5,1.9l1.8-0.1l0.4-1.8c3.4-15.2,4.7-26.9,4-35.1l-0.4-3c-1.8-14.5-4.6-26.7-8-36.7
	c-0.8-2.7-0.1-4.7,2.2-6.3l18.7-7.3l1,5.3c0.7,4.6,1.1,9.6,1.3,14.9c0.1,3.2,0.1,6.4,0.1,9.8l-0.1,3.4l-0.1,3.2
	c-0.2,4.3-0.5,8.7-1,13.4c2.1-10,2.8-20.2,2.4-30.7c-0.2-4.9-0.7-9.8-1.5-14.8L774.2,232.4L774.2,232.4z"
          />
          <path
            fill="#FFFFFF"
            d="M710.2,332.5l4.6-0.6l32.8-4l6.2-0.7l2.3-3.6l-1.8,0.1l-44,3.9l13.1,1.2c0.2,2.1,0.4,4.1,0.4,6.3
	L710.2,332.5L710.2,332.5z M507.2,153.6l-2.3-0.4c1.8,1.7,3.2,3.9,3.9,6.8l1-1.3C509.3,156.6,508.5,154.9,507.2,153.6L507.2,153.6z"
          />
          <path
            fill="#FFFFFF"
            d="M505.4,157.6c1.9,1.7,3.4,3.9,4.5,6.7l-0.6-2.4l-0.5-1.8c-0.7-2.9-2.1-5.2-3.9-6.8c-1.6-1.3-3.5-2.3-5.8-2.8
	l-3.5-0.4l-7-1l-14.1-0.2l-20-0.2l-17.5,0.4l-43.9,1.7c-19.4,1.5-37.4,4.3-53.7,8.4c-4.1,1.2-8,2.6-11.7,3.9l-4.9,1.9
	c-1.2,0.6-2.3,1.2-3.5,1.8l1.5,1.7l4-1.9l9.4-3.6l5.2-1.6c23.4-5.8,53-9.1,88.7-10l20.3-0.5l-5.3-0.1l5.3,0.1l35.4,1.2l10,1
	c3,0.1,5.7,0.7,8,1.9C502.9,155.7,504.2,156.6,505.4,157.6L505.4,157.6z"
          />
          <path
            fill="#FFFFFF"
            d="M509.9,164.2c-1.1-2.8-2.6-5-4.5-6.7c-1.1-1-2.3-1.8-3.8-2.6c-2.3-1.1-5-1.7-8-1.9l-10-1l-35.4-1.2
	l-20.3,0.5c-35.7,0.7-65.2,4-88.7,10l-5.2,1.6l-9.4,3.6l-4,1.9c-1.3,0.7-2.7,1.5-4,2.2c-7.5,4.1-15.1,8.9-22.5,14.2
	c-10.7,7.8-21.5,17-32.1,27.5c-3.4,3.4-6.8,6.9-10.3,10.6h8.7c2.7-3.6,5.6-7.2,8.6-10.6c8.9-10.1,19.2-19.2,31-27.5
	c5-3.5,10.3-6.8,15.8-10l0.9-0.5c6.6-3.4,14.1-6.2,22.5-8.4c9.7-1.9,19.2-3.5,28.3-4.6c18.8-2.1,37.7-3,56.2-3.2h0.6l30,0.1
	c18.3,0.1,32.1,0.5,41.3,1l-4.4,0.1c9-0.2,14.5,4.6,16.5,14.6c4.4,25.9,6.3,57.7,6,95.1c0.1,7.3-0.1,14.3-0.5,21.1h3.6
	c0.6-8.4,0.8-17,1-25.8c0.1-5.8,0-11.9-0.1-18c-0.4-8.1-0.9-16.2-1.3-23.9l-0.1-1.6l-0.1-1.5c-0.8-11.4-1.9-22.5-3.2-33
	c-0.8-7.2-1.8-14.2-2.9-21L509.9,164.2L509.9,164.2z"
          />
          <path
            fill="#FFFFFF"
            d="M509.3,161.8l0.6,2.4l0.4,1.5c1.1,6.8,2.1,13.9,2.9,21c1.2,10.6,2.3,21.6,3.2,33l0.1,1.5h2.1
	c-1.7-20.2-3.4-35-5.2-44.6C511.5,166.9,510.1,162.1,509.3,161.8L509.3,161.8z M518.5,221.2h-2.2l0.1,1.5h260.2l-0.1-1.5"
          />
          <path
            fill="#656565"
            d="M662.8,273.6c-18.8-0.2-37.1,1.2-54.9,4.5l-1.8,0.5c-7.8,2.2-14.5,7-20.3,14.7c-3.5,5.6-6.8,12.1-9.8,19.9
	l13.8,8.4c2.2-3.2,4.6-6.2,7.5-9c11.7-11.7,25.8-17.5,42.2-17.5c16.5,0,30.5,5.8,42.2,17.5l1.9,1.9l18.8-1
	c-1.6-4.5-3.2-8.7-4.9-12.6l-2.8-5.8l-0.1-0.2c-1.1-1.9-2.1-3.8-3.2-5.5l-2.1-3l-2.1-3c-1-1.5-2.1-2.8-3.4-3.9
	c-0.7-0.7-1.6-1.3-2.5-1.8l-3.5-1.7C673.6,274,668.7,273.3,662.8,273.6L662.8,273.6z"
          />
          <path
            fill="#656565"
            d="M584.1,332c1.5-3.8,3.4-7.3,5.7-10.6l-13.8-8.4c-1.5,3.8-2.8,7.7-4,11.9L584.1,332L584.1,332z"
          />
          <path
            fill="#656565"
            d="M589.8,321.5c-2.3,3.4-4.3,6.9-5.7,10.6c-0.5,1.2-1,2.6-1.3,3.8c-0.6,1.8-1.1,3.6-1.5,5.5l-0.1,0.6
	c-0.2,1.1-0.4,2.1-0.6,3.2l-0.4,3.4c-0.1,1.1-0.2,2.3-0.2,3.5v4.9c0.5,15.5,6.3,28.9,17.5,40c11.7,11.7,25.8,17.5,42.2,17.5
	c16.5,0,30.5-5.8,42.2-17.5c11.7-11.7,17.5-25.6,17.5-42.2c0-1.9-0.1-3.8-0.2-5.6c-0.8-9.5-3.6-18-8.5-25.8c-1.9-3-4.3-6.1-6.8-8.9
	l-1.9-1.9c-11.7-11.7-25.6-17.5-42.2-17.5c-16.5,0-30.5,5.8-42.2,17.5C594.5,315.3,592,318.3,589.8,321.5L589.8,321.5z M612.9,381.1
	c-7.3-7.3-10.9-16.2-10.9-26.5s3.6-19.2,10.9-26.6c0.9-0.9,1.7-1.7,2.7-2.4c6.8-5.7,14.7-8.6,23.9-8.6l3.3,0.1
	c3.3,0.2,6.4,0.9,9.4,1.9c5.1,1.8,9.7,4.7,13.8,8.9c3.2,3.2,5.6,6.6,7.4,10.3c1.3,2.9,2.4,5.8,2.9,9.1c0.5,2.3,0.6,4.7,0.6,7.2
	c0,7.8-2.1,14.7-6.2,20.8c-1.3,2.1-3,3.9-4.9,5.7c-7.3,7.3-16.2,11.1-26.6,11.1c-3.3,0-6.4-0.4-9.5-1.1l-1.3-0.4
	c-1.8-0.5-3.6-1.2-5.3-2.1C619.6,386.8,616,384.4,612.9,381.1L612.9,381.1z"
          />
          <path
            fill="#656565"
            d="M582.8,335.9c0.4-1.3,0.8-2.5,1.3-3.8l-12.2-7l-1.6,5.3l-1.7-0.5l-1.2,10.2l-51.6-1.5
	c-1.2,0.2-2.2,0.1-2.8-0.2c-1.1-0.6-1.3-2.2-0.7-4.5h-2.1c-0.5,1.2-1.1,2.1-1.7,2.9c-1,1.2-1.9,1.9-3,2.2l-34.5,0.2l-7.3,4.7
	l94.3,3.5c9.8-0.7,12.4-1.6,14.7-2.5c2.5-1,5-2.1,7.3-3.2l1.1-0.6C581.7,339.4,582.2,337.6,582.8,335.9L582.8,335.9z"
          />
          <path
            fill="#656565"
            d="M572.8,345c-2.4,1-4.9,1.8-7.4,2.5l14.7,0.9l0.4-3.4L572.8,345L572.8,345z M516.8,350.6l-3.8,0.7
	c-3.4,0.7-6.9,1.3-10.7,1.8l77.5,3.6v-4.9L516.8,350.6L516.8,350.6z"
          />
          <path
            fill="#656565"
            d="M513,351.3l3.8-0.7l-45.7-2.8v7.9l12.5-0.1c8-0.4,8.3-1,15.3-1.8l3.4-0.5C506.1,352.7,509.6,352.1,513,351.3
	L513,351.3z M581.2,341.3l-1.1,0.6c-2.3,1.1-4.7,2.2-7.3,3.2h7.8c0.1-1.1,0.4-2.2,0.6-3.2L581.2,341.3L581.2,341.3z"
          />
          <path
            fill="#656565"
            d="M580,351.9c0-1.2,0.1-2.3,0.2-3.5l-22-0.9L464,344h6.6l-2.1-0.1c-1,1.1-1.2,2.2-1,3.3l0.1,0.5l2.4,0.1h1.2
	l45.7,2.8L580,351.9L580,351.9z"
          />
          <path
            fill="#656565"
            d="M450.6,339.8h0.6v9.6c8.3,0,14.5,2.2,18.6,6.3h1.5v-7.9H470l-0.4,1.1c-0.6,1.3-1.2,1.9-1.9,1.9h-0.1
	c-0.2,0-0.5-0.1-0.7-0.2l-1.8-0.9l-0.4-0.1c-0.5-0.2-1-0.9-1.3-1.8c-0.5-1.3-0.7-2.9-0.7-4.7c0-1,0.1-1.8,0.2-2.7l0.1-0.9
	L450.6,339.8L450.6,339.8z"
          />
          <path
            fill="#656565"
            d="M451.1,339.8h-0.5l-111.3,0.7l-23.9,0.7l5.2,1.8l18.7,6.6l3.5,1v-1h-0.2h0.2v-8.5v14.5h108.2v-6.3
	 M465.7,339.4h-2.8l-0.1,0.9c-0.1,0.9-0.2,1.7-0.2,2.7c0,1.8,0.2,3.4,0.7,4.7c0.4,1,0.9,1.6,1.3,1.8l0.4,0.1l1.8,0.9
	c-0.2-0.2-0.6-0.6-0.9-1.1l-0.4-0.6c-0.5-1.3-0.7-2.9-0.7-4.9c0-1.3,0.1-2.5,0.5-3.6l0.2-0.6L465.7,339.4L465.7,339.4z"
          />
          <path
            fill="#656565"
            d="M469.7,339.3l-4,0.1l-0.1,0.2l-0.2,0.6c-0.2,1.1-0.5,2.3-0.5,3.6c0,1.8,0.2,3.5,0.7,4.9l0.4,0.6
	c0.2,0.5,0.5,0.9,0.9,1.1c0.2,0.1,0.5,0.2,0.7,0.2h0.1c0.7,0,1.5-0.7,1.9-1.9l0.4-1.1l-2.4-0.1l-0.1-0.5c-0.2-1.1,0.1-2.2,1-3.3
	l2.1,0.1c0-1.3-0.1-2.5-0.5-3.6L469.7,339.3L469.7,339.3z"
          />
          <path
            fill="#656565"
            d="M469.9,340.3c0.2,1.1,0.5,2.3,0.5,3.6h0.7v-4.7h-1.6L469.9,340.3L469.9,340.3z M451.1,349.3v6.3h18.6
	C465.7,351.5,459.5,349.4,451.1,349.3L451.1,349.3z M702.9,315.1l-0.6-1.7l-18.8,1c2.5,2.8,4.9,5.8,6.8,8.9l15.9,5.6
	C705.7,324.2,704.6,319.6,702.9,315.1L702.9,315.1z M706.2,349c0.7,1.8,2.8,2.8,6.1,2.9h11.7c3.9-0.2,6.1-1.2,6.3-2.9L706.2,349
	L706.2,349z M783.3,331.2v-7.3l-7.7,3.4l-5.5,0.9l-1,6.2h10.8C782.4,333.8,783.6,332.9,783.3,331.2L783.3,331.2z M734.4,332.6
	c4.6-0.1,9-1.6,13.2-4.6l-32.8,4L734.4,332.6L734.4,332.6z M105,325.6l-1.5,4.5l0.1,1.1l10.8-0.1c2.9-6.8,7.2-13,12.9-18.7
	c11.7-11.7,25.8-17.5,42.2-17.5c16.5,0,30.5,5.8,42.2,17.5c4.7,4.7,8.6,10,11.4,15.5l1.3,2.8l11.9-0.1c-2.6-9.2-5.2-17.5-8-24.8
	c-1.3-3.4-2.7-6.6-4-9.5c-5.7-13-17.1-19.4-34.3-19.4h-0.9c-16-0.4-32.7,0.7-50.1,3.5c-9.7,2.1-17.3,7.9-22.6,17.7
	c-3.5,6.6-7.2,15.2-10.8,25.8L105,325.6L105,325.6z"
          />
          <path
            fill="#656565"
            d="M127.3,312.5c-5.7,5.7-10,11.9-12.9,18.7c-3,7.2-4.6,15.1-4.6,23.4c0,16.5,5.8,30.5,17.5,42.2
	c11.7,11.7,25.8,17.5,42.2,17.5c16.5,0,30.5-5.8,42.2-17.5c11.1-11.1,16.9-24.3,17.5-39.7v-4.4c-0.1-1.7-0.2-3.5-0.4-5.1l-0.4-3
	c-0.7-4.7-2.1-9.1-3.9-13.4l-0.1-0.2l-1.3-2.8c-2.8-5.6-6.7-10.8-11.4-15.5c-11.7-11.7-25.6-17.5-42.2-17.5
	C152.9,295,138.9,300.8,127.3,312.5L127.3,312.5z M172.8,317.2c3.3,0.2,6.4,0.9,9.5,1.9c5.1,1.8,9.7,4.7,13.9,8.9
	c3.2,3.2,5.6,6.6,7.4,10.3c1.3,2.9,2.4,5.8,2.9,9.1c0.4,2.3,0.6,4.7,0.6,7.2c0,7.8-2.1,14.7-6.2,20.8c-1.5,2.1-3,3.9-4.9,5.7
	c-7.3,7.3-16.2,11.1-26.6,11.1c-10.3,0-19.2-3.6-26.6-11.1c-7.3-7.3-10.9-16.2-10.9-26.5s3.6-19.2,10.9-26.6
	c0.9-0.9,1.7-1.7,2.7-2.4c6.8-5.7,14.7-8.6,23.8-8.6L172.8,317.2L172.8,317.2z"
          />
          <path
            fill="#656565"
            d="M224.4,330.8l0.1,0.2c1.8,4.3,3.2,8.6,3.9,13.4l11.2-0.6c-1.1-4.4-2.1-8.6-3.2-12.8l-0.1-0.4L224.4,330.8
	L224.4,330.8z M229.1,357l9.4-3.6l-9.4-0.7"
          />
          <path
            fill="#656565"
            d="M239.6,343.7l-11.3,0.7l0.5,3l19.1-0.9l18.3-0.9l18-0.9l36.4-1.8l-5.2-1.8l-60.1,1.9l-6.3,0.2 M229.1,352.7
	l9.4,0.7l9.5,0.7v-7.5l-19.2,0.9C228.9,349.2,229,350.9,229.1,352.7L229.1,352.7z"
          />
          <path
            fill="#656565"
            d="M247.8,351.3v2.8l35.7,0.2C278.3,351.2,266.4,350.2,247.8,351.3L247.8,351.3z"
          />
          <path
            fill="#656565"
            d="M247.8,346.6v4.7c18.5-1,30.4,0,35.7,3l0.4-5.3c-4-1.8-10-2.9-17.9-3.3L247.8,346.6L247.8,346.6z
	 M307.1,354.4c0.4,2.8,2.5,3.9,6.6,3.5h9.5c3.5,0.2,6.1-0.9,7.4-3.4L307.1,354.4L307.1,354.4z"
          />
          <path
            fill="#656565"
            d="M284.2,344.9l-0.2,4.1l-0.2,5.2l23.4,0.1l23.4,0.1l12.3,0.1v-4l-3.5-1l-18.7-6.4"
          />
          <path
            fill="#656565"
            d="M283.9,349l0.2-4.1l-18.1,0.9C273.9,346.1,279.9,347.2,283.9,349L283.9,349z M714.9,332l-4.6,0.6l13.5,2.8
	l-13.8,3c-1.8,0.1-2.9-0.5-3.2-1.9c0-2.5-0.2-5-0.5-7.4l-15.9-5.6c4.9,7.8,7.8,16.3,8.5,25.8h49.9c5.7,0.4,8.7-2.4,9.1-8.5l0.6-10.6
	l0.1-3.3l-4.9,0.7l-6.2,0.7c-4.3,2.9-8.7,4.5-13.2,4.6L714.9,332L714.9,332z"
          />
          <path
            fill="#EBEBEB"
            d="M493.7,207.9c0.1-10.3-0.5-21.4-1.7-33.2c-0.1-1.2-0.2-2.4-0.5-3.5l-0.1-0.4c-1.1-4.6-3.6-7.4-7.5-8.4
	c-15.4-1.5-30.7-1.8-45.9-1.1c-3.3-0.2-5.5,1.8-6.4,6.2c-1.7,15.9-2.7,32.9-2.7,51.4c0,4.6,2.4,6.8,7.2,6.3l0.9-0.1
	c15.7-0.5,31.1-2.1,46.3-4.9C490.7,219.2,494.2,215,493.7,207.9L493.7,207.9z M602,354.6c0,10.3,3.6,19.2,10.9,26.5
	c3.2,3.2,6.7,5.7,10.5,7.5c1.7,0.9,3.5,1.6,5.3,2.1l1.3,0.4c3,0.7,6.2,1.1,9.5,1.1c10.3,0,19.2-3.6,26.6-11.1
	c1.8-1.8,3.4-3.8,4.9-5.7c4.1-6.1,6.2-13,6.2-20.8c0-2.5-0.2-4.9-0.6-7.2c-0.6-3.2-1.6-6.2-2.9-9.1c-1.8-3.6-4.3-7.2-7.4-10.3
	c-4.1-4.1-8.7-7-13.8-8.9c-3-1.1-6.1-1.7-9.4-1.9l-3.3-0.1c-9.1,0-17.1,2.9-23.9,8.6c-1,0.7-1.8,1.6-2.7,2.4
	C605.6,335.4,602,344.3,602,354.6L602,354.6z M607.9,337c0.7-1.2,1.5-2.3,2.2-3.4c1.1-1.6,2.4-3,3.9-4.5l1.8-1.7
	c1.1-1.1,2.3-1.9,3.5-2.8c5.8-3.9,12.4-6,19.7-6l4.5,0.2c2.5,0.2,5,0.7,7.3,1.6c1.5,0.5,2.8,1,4.2,1.7c3.5,1.7,6.8,4,9.8,7
	c2.2,2.2,3.9,4.4,5.5,6.8c0.7,1.2,1.5,2.5,2.1,4c1,2.2,1.7,4.5,2.3,6.9c0.2,1.5,0.5,3,0.6,4.5v0.1l0.1,3.3c0,7.8-2.2,14.7-6.6,20.8
	c-1.2,1.6-2.6,3.2-4,4.7l-1.1,1.1c0.1,0.5,0.2,1.1,0.1,1.6c0-0.5,0-1-0.1-1.6l-3.3,2.7l-0.1,0.1c-6.1,4.5-13.1,6.7-21,6.7l-3.2-0.1
	c-2.7-0.2-5.3-0.7-7.8-1.5c-1.3-0.4-2.5-0.9-3.8-1.5h-0.1c-3.9-1.8-7.4-4.3-10.7-7.5c-2.1-2.1-3.9-4.4-5.3-6.7
	c-0.7-1.1-1.3-2.3-1.9-3.5c-1.2-2.4-2.1-5.1-2.5-7.9c-0.5-2.4-0.7-4.9-0.7-7.4C603.4,348.1,604.9,342.2,607.9,337L607.9,337z"
          />
          <path
            fill="#EBEBEB"
            d="M610.1,333.6c-0.9,1.1-1.6,2.2-2.2,3.4c0.8-0.9,2.3-0.7,4.4,0.4l2.8,1.7l10.1,8.8l-1.1,6.9l-12.6,4.3
	c-1.5,0.4-2.7,0.5-3.8,0.5c-2.1,0.1-3.5-0.5-4.1-1.6l3.8-1.8v-1.5c0-5.2,1.1-10,3.3-14.2l-2.7-3.4c-3,5.2-4.5,11.2-4.5,17.6
	c0,2.5,0.2,5,0.7,7.4l4,0.1c1.7-0.2,3.8-1,6.2-1.9c0.2-0.1,0.5-0.2,0.7-0.2l13.1-5.1l-0.9-0.6c-0.6-0.5-0.8-1.2-0.8-2.1
	c0-0.7,0.2-1.5,0.8-2.1c0.5-0.5,1.1-0.7,1.8-0.7l-10.4-10c-1.8-1.8-3.5-3.2-5-4.3C612.4,334.3,611.2,333.7,610.1,333.6L610.1,333.6z
	"
          />
          <path
            fill="#EBEBEB"
            d="M612.3,337.4c-2.1-1.1-3.5-1.2-4.4-0.4l15.9,11.3l1.5-0.5l-10.1-8.7L612.3,337.4L612.3,337.4z"
          />
          <path
            fill="#EBEBEB"
            d="M610.6,340.4l0.7,1l4.5,3l6,3.9h2.1L607.9,337"
          />
          <path
            fill="#656565"
            d="M611.3,341.4l-0.7-1c-2.2,4.3-3.3,9-3.3,14.2v1.5l1.5-0.7l5-1c0-3.5,0.7-6.9,2.1-10L611.3,341.4L611.3,341.4
	z"
          />
          <path
            fill="#EBEBEB"
            d="M608.2,362.1l-4-0.1c0.5,2.8,1.3,5.3,2.5,7.9c0.6-1,1.6-1.8,3.2-2.5C609.1,365.7,608.5,364,608.2,362.1
	L608.2,362.1z M607.7,359.5c1.1,0,2.3-0.2,3.8-0.5l12.6-4.3l-1.1-1.1l-19.4,4.3C604.3,359,605.6,359.6,607.7,359.5L607.7,359.5z"
          />
          <path
            fill="#EBEBEB"
            d="M608.8,355.3l-1.3,0.7l-3.9,1.8l19.4-4.2l-1.9-0.7l-7.3,1.5 M606.7,369.8c0.6,1.2,1.2,2.3,1.9,3.5
	c-0.1-1.2,0.7-2.4,2.4-3.6c0.9-0.6,1.9-1.3,3.4-1.9l12.5-4.5l5.2,4.7l-2.5,13.1c-0.5,1.5-1,2.7-1.5,3.6c-1,1.8-2.1,2.8-3.3,2.9
	c1.2,0.5,2.4,1,3.8,1.5c0.9-0.7,1.6-1.8,2.1-3.5c0.6-1.6,1.1-3.8,1.5-6.3l2.2-14.8c-0.5,0.5-1.2,0.7-1.9,0.7c-0.7,0-1.5-0.2-2.1-0.9
	s-0.8-1.2-0.8-1.9c0-0.5,0.1-0.9,0.2-1.2l0.2-0.5l0.1-0.1l-14.2,4.4c-2.5,0.7-4.5,1.5-6.1,2.3C608.3,368.1,607.3,369,606.7,369.8
	L606.7,369.8z"
          />
          <path
            fill="#EBEBEB"
            d="M610.9,369.7c-1.7,1.2-2.5,2.4-2.4,3.6l18.1-8.5l0.2-1.6l-12.5,4.5C613,368.3,611.9,369.1,610.9,369.7
	L610.9,369.7z M608.6,373.3l4.1-0.9l1.7-0.2l4.4-2.2l6.8-3.4l1.1-1.8"
          />
          <path
            fill="#EBEBEB"
            d="M612.8,372.5l-4.1,0.9c1.5,2.4,3.3,4.6,5.3,6.7c3.3,3.3,6.8,5.8,10.7,7.5l0.4-4.2c-3-1.5-5.7-3.5-8.3-6.1
	C615.2,375.8,613.9,374.2,612.8,372.5L612.8,372.5z"
          />
          <path
            fill="#656565"
            d="M608.2,362.1c0.4,1.8,1,3.5,1.7,5.1c1.5-0.9,3.5-1.6,6.1-2.3c-0.7-1.6-1.2-3.2-1.6-4.7
	C611.9,361.2,609.9,361.8,608.2,362.1L608.2,362.1z"
          />
          <path
            fill="#EBEBEB"
            d="M655.2,321.9l-0.1,4.4c2.5,1.5,5,3.3,7.2,5.5c1.5,1.5,2.8,3,3.9,4.6l4.4-0.7c-1.5-2.4-3.3-4.6-5.5-6.8
	C662,326.1,658.7,323.6,655.2,321.9L655.2,321.9z"
          />
          <path
            fill="#EBEBEB"
            d="M655,326.4l0.1-4.5l-6.1,18l1.7-1.3l2.2-6l1.9-5.7"
          />
          <path
            fill="#EBEBEB"
            d="M651.5,324.7c1.1-2.3,2.3-3.3,3.5-2.7c-1.3-0.7-2.8-1.2-4.3-1.7c-0.7,0.9-1.2,2.1-1.8,3.6
	c-0.5,1.6-1,3.5-1.3,6v0.4l-2.6,13.9c0.4-0.2,0.9-0.4,1.3-0.4c0.8,0,1.5,0.2,2.1,0.9s0.8,1.2,0.8,2.1c0,0.5-0.1,0.9-0.2,1.2
	l13.7-3.9h0.1c2.4-0.6,4.4-1.2,6.1-1.9c1.6-0.7,2.7-1.3,3.4-2.2c-0.6-1.3-1.3-2.7-2.2-4c0.5,1.2-0.2,2.5-2.4,3.8
	c-0.7,0.5-1.6,1-2.7,1.5l-12.6,4.1l-5.1-4.9l2.9-13C650.9,326.3,651.2,325.5,651.5,324.7L651.5,324.7z"
          />
          <path
            fill="#EBEBEB"
            d="M655.2,321.9c-1.3-0.5-2.5,0.4-3.5,2.7c-0.4,0.7-0.7,1.6-1.1,2.6l-2.9,13l1.5-0.5L655.2,321.9L655.2,321.9z"
          />
          <path
            fill="#656565"
            d="M655,326.4v0.5l-2.1,5.8c1.7,1.1,3.3,2.3,4.7,3.8c0.9,0.9,1.7,1.7,2.4,2.7l5.3-2.5l0.7-0.1
	c-1.2-1.6-2.4-3.2-3.9-4.6C660,329.7,657.6,327.8,655,326.4z"
          />
          <path
            fill="#EBEBEB"
            d="M653,332.6l-2.2,6l3.3,3.3l6-2.8c-0.7-1-1.6-1.8-2.4-2.7C656.1,334.9,654.6,333.7,653,332.6L653,332.6z
	 M665.4,336.6l-5.3,2.4l-6,2.8l-1.1,1.8l17.4-7.8l-4.3,0.6"
          />
          <path
            fill="#EBEBEB"
            d="M668,339.7c2.1-1.3,2.9-2.5,2.4-3.8l-17.5,7.8l-0.2,1.6l12.6-4.1C666.5,340.6,667.3,340.2,668,339.7
	L668,339.7z"
          />
          <path
            fill="#656565"
            d="M623,326.9l0.7,0.5l3.6,4.4c2.9-1.6,6.1-2.5,9.5-2.8l0.5-5.6l0.4-0.9C632.2,322.8,627.3,324.2,623,326.9
	L623,326.9z"
          />
          <path
            fill="#EBEBEB"
            d="M623.7,327.4l-0.7-0.5l-3.5-2.4l12.4,14.7l-0.1-2.2l-4.4-5.1"
          />
          <path
            fill="#EBEBEB"
            d="M619.5,324.5c-1.2,0.9-2.4,1.8-3.5,2.8c0.4,1,1.1,2.1,2.1,3.4c1.2,1.3,2.7,2.8,4.7,4.4l0.1,0.1l10.9,9.1
	c0-0.7,0.4-1.3,0.8-1.9c0.6-0.5,1.2-0.9,2.1-0.9c0.8,0,1.5,0.2,2.1,0.9l0.4,0.5l3.6-13.7c0.7-2.4,1.2-4.6,1.5-6.3
	c0.1-1.6,0.1-3-0.2-4l-4.5-0.2c1.3,0.2,2.1,1.6,2.2,4c0,0.9,0,1.9-0.1,3.2l-2.8,13l-6.8,1.8l-9.7-9.1c-0.7-0.9-1.5-1.7-1.9-2.4
	C618.6,327,618.5,325.5,619.5,324.5L619.5,324.5z"
          />
          <path
            fill="#EBEBEB"
            d="M619.9,329.1c0.5,0.7,1.1,1.6,1.9,2.4l9.7,9.1l0.4-1.5l-12.5-14.6C618.5,325.5,618.6,327,619.9,329.1
	L619.9,329.1z"
          />
          <path
            fill="#656565"
            d="M616.8,331.9c-1.1,1.1-2.1,2.2-2.9,3.4c1.5,1,3.2,2.4,5,4.3c0.7-1.1,1.6-2.1,2.5-3l1.5-1.3l-0.1-0.1
	c-1.9-1.6-3.5-3-4.7-4.4L616.8,331.9L616.8,331.9z"
          />
          <path
            fill="#EBEBEB"
            d="M613.7,335.2c0.8-1.1,1.8-2.3,2.9-3.4l1.2-1.1c-1.1-1.2-1.7-2.4-2.1-3.4L614,329c-1.5,1.5-2.7,2.9-3.9,4.5
	C611.2,333.7,612.4,334.3,613.7,335.2L613.7,335.2z M621.3,336.4c-1,1-1.8,1.9-2.5,3l10.4,10h0.2c0.6,0,1.2,0.2,1.7,0.6
	c0.5-0.7,1-1.5,1.6-2.2c0.5-0.5,1.1-1,1.7-1.3c-0.5-0.5-0.9-1.2-0.9-1.9v-0.1l-10.9-9.1L621.3,336.4L621.3,336.4z M627.3,331.9
	l4.4,5.1l4.5-1.2l0.6-6.8C633.4,329.3,630.3,330.3,627.3,331.9L627.3,331.9z M619.5,324.5l3.5,2.4c4.4-2.7,9.4-4.1,14.7-4.4l1.5-4
	C631.8,318.5,625.3,320.6,619.5,324.5L619.5,324.5z M643.9,322.8c1.8,0.2,3.5,0.6,5.2,1.1c0.5-1.6,1.1-2.8,1.8-3.6
	c-2.3-0.7-4.7-1.3-7.3-1.6C644,319.8,644.1,321.1,643.9,322.8L643.9,322.8z"
          />
          <path
            fill="#656565"
            d="M649.2,323.9c-1.7-0.5-3.4-1-5.2-1.1c-0.2,1.7-0.7,3.9-1.5,6.3c1.8,0.2,3.6,0.6,5.3,1.1v-0.4
	C648.2,327.5,648.6,325.5,649.2,323.9L649.2,323.9z"
          />
          <path
            fill="#EBEBEB"
            d="M639.1,318.5l-1.5,4l-0.4,0.9l-0.5,5.7l-0.6,6.8l1,1.8"
          />
          <path
            fill="#EBEBEB"
            d="M637.2,337.7l-1-1.8l-4.5,1.1l0.1,2.2l-0.2,1.5l6.8-1.9"
          />
          <path
            fill="#EBEBEB"
            d="M637.2,337.7l1.2,1.1l2.8-13c0.1-1.2,0.1-2.3,0.1-3.2c-0.1-2.4-0.8-3.8-2.2-4L637.2,337.7z M647.9,330.2
	c-1.7-0.6-3.5-1-5.3-1.1l-3.6,13.7c0.2,0.5,0.5,1,0.5,1.6v0.5h0.2c1.6,0,3,0.4,4.4,1.1c0.1-0.6,0.4-1.1,0.7-1.5l0.6-0.5L647.9,330.2
	L647.9,330.2z M649.1,339.9l-1.5,0.4l5,5l0.4-1.6l1.1-1.8l-3.3-3.3"
          />
          <path
            fill="#EBEBEB"
            d="M648.7,344.5c-0.5-0.6-1.2-0.9-2.1-0.9c-0.5,0-1,0.1-1.3,0.4l-0.6,0.5c-0.4,0.4-0.7,0.9-0.7,1.5l-0.1,0.6
	c0,0.7,0.2,1.5,0.8,2.1s1.2,0.9,2.1,0.9l0.9-0.1c0.4-0.1,0.7-0.4,1.1-0.7l0.6-0.7c0.2-0.4,0.2-0.7,0.2-1.2
	C649.6,345.7,649.2,345.1,648.7,344.5L648.7,344.5z"
          />
          <path
            fill="#EBEBEB"
            d="M644.7,348.5c-0.6-0.6-0.9-1.2-0.9-2.1l0.1-0.6c-1.3-0.7-2.8-1.1-4.4-1.1h-0.2c-0.1,0.6-0.4,1.1-0.8,1.5
	c-0.5,0.6-1.2,0.9-2.1,0.9c-0.7,0-1.5-0.2-2.1-0.9c-0.6,0.4-1.2,0.9-1.7,1.3c-0.6,0.7-1.2,1.3-1.7,2.2l0.4,0.2
	c0.6,0.6,0.8,1.2,0.8,2.1c0,0.9-0.2,1.5-0.8,2.1c-0.5,0.5-1,0.7-1.5,0.9c0.1,1.8,0.6,3.4,1.5,4.7l1-0.2c0.7,0,1.5,0.2,1.9,0.9
	c0.6,0.6,0.9,1.2,0.9,2.1l-0.1,0.7c1.3,0.7,2.8,1.1,4.5,1.1h0.7c0.1-0.6,0.4-1.1,0.7-1.5c0.6-0.6,1.2-0.9,2.1-0.9
	c0.7,0,1.3,0.2,1.8,0.7l1.5-1.2c0.8-0.9,1.6-1.8,2.1-2.9l-0.1-0.1c-0.6-0.6-0.9-1.2-0.9-1.9s0.2-1.5,0.9-2.1c0.2-0.2,0.6-0.5,1-0.6
	c-0.1-1.6-0.6-3.2-1.6-4.5l-0.9,0.1C645.9,349.4,645.2,349.1,644.7,348.5L644.7,348.5z M641.3,349.5c0.7,0.2,1.5,0.7,1.9,1.3
	c0.5,0.5,0.9,1,1.1,1.5c0.2,0.4,0.4,0.9,0.4,1.2l0.1,1.1c0,1.1-0.2,2.1-0.8,3l-0.7,0.9c-1.1,1.1-2.3,1.6-3.8,1.6
	c-1.5,0-2.8-0.5-3.9-1.6s-1.6-2.3-1.6-3.8s0.5-2.8,1.6-3.9l0.4-0.4c1-0.9,2.2-1.2,3.5-1.2h0.5L641.3,349.5L641.3,349.5z"
          />
          <path
            fill="#EBEBEB"
            d="M643.4,350.7c-0.6-0.6-1.2-1.1-1.9-1.3l-1.3-0.2h-0.5c-1.3,0-2.4,0.4-3.5,1.2l-0.4,0.4
	c-1.1,1.1-1.6,2.3-1.6,3.9c0,1.6,0.5,2.8,1.6,3.8c1.1,1.1,2.3,1.6,3.9,1.6c1.5,0,2.8-0.5,3.8-1.6l0.7-0.9c0.6-0.9,0.8-1.8,0.8-3
	l-0.1-1.1c-0.1-0.5-0.2-0.9-0.4-1.2C644.1,351.7,643.7,351.2,643.4,350.7L643.4,350.7z M649.2,347.8l-0.6,0.7
	c-0.4,0.4-0.7,0.6-1.1,0.7c0.9,1.3,1.5,2.8,1.6,4.5c0.4-0.1,0.7-0.2,1.1-0.2l1.1,0.2l13.4-4.7c-0.4-1.7-1-3.4-1.7-5L649.2,347.8
	L649.2,347.8z M638.9,342.8l-0.4-0.5c-0.5-0.5-1.2-0.9-2.1-0.9c-0.7,0-1.5,0.2-2.1,0.9c-0.5,0.5-0.7,1.2-0.9,1.9v0.1
	c0,0.7,0.2,1.5,0.9,1.9c0.6,0.6,1.2,0.9,2.1,0.9c0.8,0,1.5-0.2,2.1-0.9c0.5-0.4,0.7-1,0.8-1.5v-0.5
	C639.3,343.9,639.1,343.3,638.9,342.8L638.9,342.8z M634.3,360.5c-0.6-0.6-1.2-0.9-1.9-0.9l-1,0.2c-0.4,0.1-0.7,0.4-1.1,0.6
	l-0.1,0.2l-0.1,0.1l-0.2,0.5c-0.2,0.4-0.2,0.7-0.2,1.2c0,0.7,0.2,1.5,0.8,1.9c0.6,0.6,1.2,0.9,2.1,0.9c0.7,0,1.3-0.2,1.9-0.7
	l0.1-0.1c0.4-0.4,0.6-0.9,0.7-1.3l0.1-0.7C635.1,361.7,634.9,361.1,634.3,360.5L634.3,360.5z"
          />
          <path
            fill="#EBEBEB"
            d="M650.2,353.5c-0.4,0-0.7,0.1-1.1,0.2c-0.4,0.1-0.6,0.4-1,0.6c-0.6,0.6-0.8,1.2-0.8,2.1
	c0,0.7,0.2,1.5,0.8,1.9l0.1,0.1c0.5,0.5,1.1,0.7,1.8,0.7c0.8,0,1.5-0.2,2.1-0.9s0.8-1.2,0.8-1.9s-0.2-1.5-0.8-2.1
	c-0.2-0.2-0.6-0.5-1-0.6L650.2,353.5L650.2,353.5z M644.8,362.6c-0.5-0.5-1.1-0.7-1.8-0.7s-1.5,0.2-2.1,0.9c-0.4,0.5-0.7,1-0.7,1.5
	v0.6c0,0.4,0.1,0.7,0.2,1.1l0.6,1c0.6,0.6,1.2,0.9,2.1,0.9c0.7,0,1.5-0.2,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9v-0.2
	C645.8,363.9,645.4,363.2,644.8,362.6L644.8,362.6z"
          />
          <path
            fill="#EBEBEB"
            d="M650.2,359.2c-0.7,0-1.3-0.2-1.8-0.7c-0.5,1.1-1.1,1.9-2.1,2.9l-1.5,1.2l0.1,0.1c0.5,0.5,0.7,1.1,0.9,1.7
	l10.9,8.9l0.2,0.1l0.7-0.7c1.1-1.1,2.2-2.3,3-3.5L650.2,359.2L650.2,359.2z M629.2,349.4c-0.7,0-1.2,0.2-1.8,0.7
	c-0.6,0.6-0.8,1.2-0.8,2.1c0,0.9,0.2,1.5,0.8,2.1l0.9,0.6c0.4,0.1,0.7,0.2,1.2,0.2h0.5c0.6-0.1,1.1-0.4,1.5-0.9
	c0.6-0.5,0.8-1.2,0.8-2.1c0-0.7-0.2-1.5-0.8-2.1l-0.4-0.2c-0.5-0.4-1-0.6-1.7-0.6L629.2,349.4L629.2,349.4z M625.3,347.8l-1.5,0.5
	h-2.1l-0.7,4.6l1.9,0.7l1.2,1.1"
          />
          <path
            fill="#EBEBEB"
            d="M621.8,348.3l-5.8-3.9c-1.3,3-2.1,6.4-2.1,10l7.3-1.5L621.8,348.3L621.8,348.3z M615.1,360
	c-0.2,0.1-0.5,0.2-0.7,0.2c0.4,1.6,0.8,3.3,1.6,4.7l14.2-4.4l0.1-0.2c0.4-0.2,0.7-0.5,1.1-0.6c-0.9-1.5-1.5-3-1.5-4.7h-0.5
	c-0.5,0-0.8-0.1-1.2-0.2L615.1,360L615.1,360z M626.9,363.2l-0.1,1.6l-1.1,1.8l3.4,3.2l1.6-1.2l1.5-0.5"
          />
          <path
            fill="#EBEBEB"
            d="M630.6,368.6l-1.6,1.2l-2.3,7.2l-1.6,4.7l-0.1,1.7l-0.4,4.3"
          />
          <path
            fill="#EBEBEB"
            d="M629,369.8l-3.4-3.2l-6.8,3.4c0.7,1,1.6,1.9,2.4,2.8c1.7,1.7,3.4,3,5.3,4.2L629,369.8L629,369.8z"
          />
          <path
            fill="#656565"
            d="M621.3,372.7c-0.8-0.9-1.7-1.8-2.4-2.8l-4.5,2.2l-1.7,0.4c1.2,1.7,2.5,3.3,4,4.7c2.5,2.5,5.2,4.6,8.3,6.1
	l0.1-1.7l1.6-4.7C624.8,375.9,623,374.4,621.3,372.7L621.3,372.7z"
          />
          <path
            fill="#EBEBEB"
            d="M628.1,384.7c0.5-1,1-2.2,1.5-3.6l2.5-13.1l-1.5,0.5l-6,19.1h0.1C626,387.5,627.1,386.6,628.1,384.7
	L628.1,384.7z M647.4,369.4l0.2-1.5L641,370l1.2,1l1,1.8l4.5-1.2"
          />
          <path
            fill="#EBEBEB"
            d="M647.6,368l-0.2,1.5l13.1,14.5h0.1c0.7-1.1,0.4-2.5-0.8-4.4c-0.5-0.9-1.2-1.7-2.2-2.7L647.6,368L647.6,368z"
          />
          <path
            fill="#EBEBEB"
            d="M647.4,369.4l0.2,2.2l4.7,5.3l3.6,4l1,0.6l3.5,2.3"
          />
          <path
            fill="#EBEBEB"
            d="M647.6,371.6l-4.5,1.2l-0.5,7.3c3.5-0.4,6.7-1.5,9.7-3.3L647.6,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M643.1,372.8l-1-1.8l-1.7,19.6l1.5-3.9l0.5-1.3l0.2-5.2"
          />
          <path
            fill="#EBEBEB"
            d="M645.8,364.5v0.2c0,0.7-0.2,1.5-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9s-1.5-0.2-2.1-0.9l-0.6-1l-3.4,14.3
	c-0.7,2.4-1.1,4.6-1.1,6.3c-0.1,1.7,0,3,0.5,4l3.2,0.1c7.9,0,14.9-2.2,21-6.7l-3.5-2.3c-4.5,3-9.5,4.6-15.1,5l-1.5,4
	c-1.2-0.4-1.8-1.7-2.1-3.9c-0.1-1-0.1-2.2,0-3.5l2.5-13.1l6.7-2.1l10,8.9c0.8,1,1.6,1.8,2.2,2.7c1.2,1.8,1.5,3.3,0.8,4.4l3.3-2.7
	c-0.2-1.1-1-2.2-2.1-3.4c-1.2-1.3-2.8-2.8-4.7-4.3l-0.2-0.1L645.8,364.5L645.8,364.5z"
          />
          <path
            fill="#EBEBEB"
            d="M640.2,364.2h-0.7c-1.6,0-3.2-0.4-4.5-1.1c-0.1,0.5-0.4,1-0.7,1.3l-0.1,0.1l-2.2,14.8
	c1.6,0.5,3.2,0.7,4.9,0.9l3.4-14.3c-0.1-0.4-0.2-0.7-0.2-1.1L640.2,364.2L640.2,364.2z"
          />
          <path
            fill="#656565"
            d="M636.9,380.2c-1.7-0.1-3.4-0.5-4.9-0.9c-0.4,2.5-0.8,4.6-1.5,6.3c1.7,0.5,3.4,0.7,5.2,1
	C636,384.7,636.3,382.7,636.9,380.2L636.9,380.2z M642.7,380.1l-0.4,5.2l-0.5,1.3c5.6-0.4,10.6-2.1,15.1-5l-1-0.7l-3.5-4
	C649.5,378.7,646.2,379.8,642.7,380.1L642.7,380.1z"
          />
          <path
            fill="#EBEBEB"
            d="M642.2,371l-1.2-1l-2.6,13.1c-0.1,1.3-0.1,2.6,0,3.5c0.2,2.2,0.9,3.5,2.1,3.9L642.2,371L642.2,371z
	 M674.8,346.7c-0.5-2.4-1.2-4.7-2.3-6.9c-0.7,0.7-1.9,1.5-3.4,2.2c0.7,1.6,1.2,3.3,1.7,5L674.8,346.7L674.8,346.7z M675.5,351.2
	c-0.1-1.5-0.4-3-0.6-4.5l-4,0.2l-6.2,1.9l-13.4,4.7c0.4,0.1,0.7,0.4,1,0.6c0.6,0.6,0.9,1.2,0.9,2.1c0,0.7-0.2,1.5-0.9,1.9
	c-0.5,0.6-1.2,0.9-2.1,0.9l10.4,10.1c1.7,1.8,3.4,3.3,4.9,4.3c1.3,1,2.5,1.6,3.6,1.8c4.4-6.1,6.6-13,6.6-20.8L675.5,351.2l-3.9,1.9
	v1.5c0,5-1,9.5-2.9,13.6l2.7,3.4c-0.9,1-2.4,0.9-4.5-0.2c-0.8-0.4-1.7-1-2.7-1.7l-10-9l1.3-6.9l12.8-3.9l2.9-0.4
	C673.8,349.4,675.1,349.9,675.5,351.2L675.5,351.2z"
          />
          <path
            fill="#EBEBEB"
            d="M675.5,351.2c-0.4-1.3-1.7-1.9-4.3-1.7l-2.9,0.4l-12.8,3.9l1.1,1.1L675.5,351.2L675.5,351.2z M671,353.5
	l0.6-0.4l3.9-1.8l-18.8,3.5l1.9,0.9l6.7-1.2"
          />
          <path
            fill="#656565"
            d="M671.6,353.2l-0.6,0.4l-5.7,1v0.1c0,3.4-0.6,6.7-1.8,9.6l4.6,3.3l0.6,0.7c1.9-4.1,2.9-8.6,2.9-13.6
	L671.6,353.2L671.6,353.2z M663.1,343.9c0.6,1.6,1.1,3.3,1.6,5l6.2-1.9c-0.4-1.7-1-3.4-1.7-5C667.6,342.7,665.5,343.3,663.1,343.9
	L663.1,343.9z"
          />
          <path
            fill="#EBEBEB"
            d="M656.6,354.9l-1.1-1.1l-1.2,7l1.5-0.5h2.1l0.7-4.6"
          />
          <path
            fill="#EBEBEB"
            d="M657.8,360.3h-2.1l15.5,11.3l-2.7-3.4l-0.6-0.7l-4.6-3.3"
          />
          <path
            fill="#EBEBEB"
            d="M655.8,360.3l-1.5,0.5l10,9c1,0.7,1.9,1.3,2.7,1.7c2.1,1.1,3.5,1.1,4.5,0.2L655.8,360.3L655.8,360.3z
	 M658.6,355.7l-0.7,4.6l5.6,3.9c1.2-2.9,1.8-6.2,1.8-9.6v-0.1L658.6,355.7L658.6,355.7z"
          />
          <path
            fill="#656565"
            d="M665.5,373.6c-1.5-1-3-2.4-4.9-4.3c-0.8,1.2-1.8,2.3-3,3.5l-0.7,0.7c2.1,1.6,3.6,3,4.7,4.3l0.5-0.5
	C663.4,376.1,664.4,374.9,665.5,373.6L665.5,373.6z"
          />
          <path
            fill="#EBEBEB"
            d="M662.2,377.3l-0.5,0.5c1.1,1.2,1.8,2.4,2.1,3.4l1.1-1.1c1.5-1.5,2.9-3,4-4.7c-1.1-0.2-2.3-0.9-3.6-1.8
	C664.4,374.9,663.4,376.1,662.2,377.3L662.2,377.3z M660.5,383.9v0.1l0.1-0.1 M636.3,390.6c-0.4-1-0.6-2.3-0.5-4
	c-1.8-0.2-3.5-0.5-5.2-1c-0.6,1.6-1.3,2.8-2.1,3.5C631,389.8,633.5,390.3,636.3,390.6L636.3,390.6z M624.7,387.7h0.1H624.7z
	 M773.4,244.1l-0.7-6.6c-0.4-0.2-0.8-0.2-1.3-0.4c-2.1,0.1-3.2,2.6-3.5,7.3l0.1,17.5v2.8l1.3,2.2l6.1-0.1c-1.9-0.1-2.8-0.7-2.7-2.1
	v-17.9C772.5,245,772.8,244.1,773.4,244.1L773.4,244.1z"
          />
          <path
            fill="#EBEBEB"
            d="M772.6,237.6l0.7,6.6c-0.6,0-0.7,1-0.7,2.9v11.3c0.4,1.6,1.1,2.4,2.2,2.5c-1.1-0.1-1.7-1-2.2-2.5v6.6
	c-0.2,1.2,0.7,1.9,2.7,2.1l0.1-3.4c0-3.4,0-6.7-0.1-9.8c-0.2-5.3-0.6-10.3-1.3-14.9C773.7,238.2,773.2,237.9,772.6,237.6z"
          />
          <path
            fill="#EBEBEB"
            d="M771.4,237.1c0.5,0.1,1,0.2,1.3,0.4c0.6,0.2,1,0.6,1.2,1.2l-1-5.3l-18.7,7.3c-2.4,1.6-3.2,3.6-2.2,6.3
	c3.5,10,6.2,22.2,8,36.7l14.1-0.1c0.5-4.6,0.7-9.1,1-13.4l-2.5,1.1l-1.6,10.3h-6.9l-2.3-11.2l0.4-1.5l-2.4,0.2l-1.1-4.5h9.2v-2.5
	l-4.5-0.5c-2.2-0.1-3.4-0.7-3.4-2.1l-3.9-12.4c-0.4-2.1,0.5-2.9,2.6-2.6h9.1C768.1,239.7,769.4,237.3,771.4,237.1L771.4,237.1z"
          />
          <path
            fill="#EBEBEB"
            d="M758.7,244.6c-2.2-0.2-3,0.6-2.6,2.6l3.9,12.4c0.1,1.2,1.2,1.9,3.4,2.1l-0.2-17L758.7,244.6L758.7,244.6z"
          />
          <path
            fill="#EBEBEB"
            d="M767.8,244.6h-4.6l0.2,17l4.5,0.5 M758.7,264.6l1.1,4.5l2.4-0.1v-0.1h9.7l0.7,2.4l2.5-1.1l0.1-3.3l-6.1,0.2
	l-1.3-2.2v-0.4"
          />
          <path
            fill="#EBEBEB"
            d="M762.9,268.8l-0.7,0.1l-0.4,1.5l2.3,11.1h6.9l1.6-10.2l-0.7-2.4 M88.8,248.9l0.7-0.1c5.2-1,7.7,0.7,7.4,5
	l1-0.4l17.7,0.1l-0.1-1.6H97.2h18.2c-0.2-0.9-0.7-1.6-1.3-2.2c-2.6-2.4-8-3.5-16.6-3.2l-10,0.6c-5.6,0.7-10.9,1.8-16,3.5
	c-0.7,0.2-1.5,0.5-2.1,0.9c-0.8,0.5-1.5,1.1-1.9,1.7c6.9-2.1,14.1-3.5,21.1-4.5L88.8,248.9L88.8,248.9z"
          />
          <path
            fill="#EBEBEB"
            d="M88.9,248.9h-0.2c-7.2,0.9-14.2,2.4-21.1,4.5l-0.4,0.5c0.1,0.9,0.7,1.2,1.9,1l1.3-0.4c0.1,0,0.1-0.1,0.2-0.1
	h0.1c4.5-1.7,10.1-2.9,16.8-3.5c1.2,0.1,1.9,0.9,2.2,2.2l0.1,1.5c0.1,1.7,0.1,3.4,0,5c-0.1,1.7-0.5,3.4-0.8,4.9
	c-0.4,0.7-0.7,1.2-1,1.6c-0.6,0.6-1.2,1-1.9,1l-5.1,1v1.7l7.5-0.9c2.3-1.5,3.4-4,3.5-7.4v-8.7C91.8,250,90.9,248.7,88.9,248.9
	L88.9,248.9z"
          />
          <path
            fill="#EBEBEB"
            d="M89.5,248.8l-0.6,0.1c1.9-0.1,2.9,1.1,3,3.8v8.7c0,3.4-1.2,6-3.5,7.4l-7.5,0.9l2.3,2.7
	c3.6-0.9,7-1.9,10.3-3.2c1.8-2.1,2.8-5.1,2.9-8.9l-1.9-1.1V255l2.4-1C97.2,249.5,94.8,247.8,89.5,248.8L89.5,248.8z"
          />
          <path
            fill="#EBEBEB"
            d="M89.8,253.1c-0.2-1.3-1-2.1-2.2-2.2c-6.7,0.7-12.3,1.8-16.8,3.5h-0.1c-0.1,0-0.1,0-0.2,0.1l-1.5,0.4h6
	l1.9,0.4l6.7-1.2v-0.5l2.2,0.1L89.8,253.1L89.8,253.1z"
          />
          <path
            fill="#EBEBEB"
            d="M89.9,259.5c0.1-1.6,0.1-3.3,0-5l-0.1-1.5l-4,0.7C87.5,254.4,88.9,256.2,89.9,259.5L89.9,259.5z M94.5,254.9
	v4.3l1.9,1.1l0.8,0.5h12.5l2.7-1.8l1.5-1.2c0.7-0.7,1.2-1.6,1.5-2.6c0.1-0.5,0.2-1,0.1-1.5v-0.1l-17.7-0.1l-1,0.4L94.5,254.9
	L94.5,254.9z"
          />
          <path
            fill="#EBEBEB"
            d="M83.7,253.7v0.5l0.6,12.1l3.8-0.2c0.4-0.4,0.7-1,1-1.6c0.4-1.6,0.7-3.2,0.8-4.9c-1.1-3.3-2.4-5.1-4.1-5.7
	L83.7,253.7L83.7,253.7z"
          />
          <path
            fill="#EBEBEB"
            d="M80.9,269.7V268l5.1-1c0.7,0,1.3-0.2,1.9-1l-3.8,0.2l-3.8,0.2h-0.1l-5.1,0.4c2.2,0.4,3.9,2.4,5,6l2.9-0.6
	L80.9,269.7L80.9,269.7z"
          />
          <path
            fill="#EBEBEB"
            d="M80.4,266.5c2.6-1.5,3.4-3.5,2.6-6.2c-1-2.5-3-4.3-6.1-5.1l-1.9-0.4l-0.4,11.9l0.7,0.1L80.4,266.5
	L80.4,266.5z"
          />
          <path
            fill="#EBEBEB"
            d="M83.1,260.3c0.8,2.5,0,4.6-2.6,6.2l3.8-0.2l-0.6-12.1l-6.7,1.2C80.1,256.2,82.1,257.9,83.1,260.3L83.1,260.3
	z M93.7,269.1c0.7-0.4,1.6-0.6,2.3-1l13.6-7.3c0.1-0.1,0.1-0.1,0.2-0.1H97.3l-0.8-0.5C96.3,264,95.5,267,93.7,269.1L93.7,269.1z
	 M75.1,254.9h-6c-1.2,0.2-1.8-0.1-1.9-1c-0.5,0.7-0.7,1.7-0.7,2.7v10.3l8.3-0.1L75.1,254.9L75.1,254.9z"
          />
          <path
            fill="#EBEBEB"
            d="M66.3,266.9c-0.1,1.2,0.6,1.9,1.9,2.1h5.8l3.6,4.4l2.7-0.5c-1.2-3.5-2.8-5.6-5-6l-0.7-0.1L66.3,266.9
	L66.3,266.9z M182.1,319.1c-3-1.1-6.2-1.7-9.5-1.9l-3.3-0.1c-9.1,0-17.1,2.9-23.8,8.6c-1,0.7-1.8,1.6-2.7,2.4
	c-7.3,7.4-10.9,16.3-10.9,26.6s3.6,19.2,10.9,26.5s16.2,11.1,26.6,11.1s19.3-3.6,26.6-11.1c1.8-1.8,3.4-3.8,4.9-5.7
	c4.1-6.1,6.2-13,6.2-20.8c0-2.5-0.2-4.9-0.6-7.2c-0.6-3.2-1.6-6.2-2.9-9.1c-1.8-3.6-4.3-7.2-7.4-10.3
	C191.8,323.9,187.2,321,182.1,319.1L182.1,319.1z M133.5,357.9c-0.1-1.1-0.1-2.2-0.1-3.3c0-6.6,1.5-12.4,4.5-17.6
	c0.7-1.2,1.5-2.3,2.2-3.4c1.1-1.6,2.4-3,3.9-4.5l1.8-1.7c1.2-1.1,2.3-1.9,3.5-2.8c5.8-3.9,12.3-6,19.7-6l4.5,0.2
	c2.6,0.2,5,0.7,7.3,1.6c1.5,0.5,2.9,1,4.3,1.7c3.5,1.7,6.8,4,9.8,7c2.2,2.2,3.9,4.4,5.5,6.8c0.7,1.2,1.5,2.5,2.1,4
	c1,2.2,1.7,4.5,2.3,6.9c0.2,1.5,0.5,3,0.6,4.5v0.1l0.1,3.3c0,7.8-2.2,14.7-6.6,20.8c-1.2,1.6-2.6,3.2-4.1,4.7l-1.1,1.1
	c0.1,0.5,0.2,1.1,0.1,1.6c0-0.5,0-1-0.1-1.6l-3.3,2.7l-0.1,0.1c-6.1,4.5-13.1,6.7-21,6.7l-3.2-0.1c-2.7-0.2-5.3-0.7-7.8-1.5
	c-1.3-0.4-2.6-0.9-3.8-1.5h-0.1c-3.9-1.8-7.4-4.3-10.7-7.5c-2.1-2.1-3.9-4.4-5.3-6.7c-0.7-1.1-1.3-2.3-1.9-3.5
	c-1.2-2.4-2.1-5.1-2.6-7.9C133.9,360.7,133.6,359.4,133.5,357.9L133.5,357.9z"
          />
          <path
            fill="#EBEBEB"
            d="M133.4,354.6c0,1.1,0,2.2,0.1,3.3l3.8-1.8v-1.5c0-5.2,1.1-10,3.2-14.2l-2.7-3.4
	C134.8,342.2,133.4,348.1,133.4,354.6L133.4,354.6z M185.1,321.9c-1.3-0.7-2.8-1.2-4.3-1.7c-0.7,0.9-1.2,2.1-1.8,3.6
	c-0.5,1.6-1,3.5-1.2,6v0.4l-2.6,13.8c0.4-0.2,0.9-0.4,1.3-0.4c0.7,0,1.5,0.2,2.1,0.9s0.9,1.2,0.9,2.1c0,0.5-0.1,0.9-0.2,1.2
	l13.7-3.9h0.1c2.4-0.6,4.5-1.2,6.1-1.9c1.6-0.7,2.7-1.3,3.4-2.2c-0.6-1.3-1.3-2.7-2.1-4c0.5,1.2-0.2,2.5-2.4,3.8l-2.7,1.5l-12.8,4.1
	l-5-4.9l2.9-13c0.2-1,0.6-1.8,1-2.5C182.6,322.4,183.8,321.5,185.1,321.9L185.1,321.9z"
          />
          <path
            fill="#656565"
            d="M184.9,326.4v0.5l-2.1,5.8c1.7,1.1,3.3,2.3,4.7,3.8c0.9,0.9,1.6,1.7,2.3,2.7l5.3-2.5l0.7-0.1
	c-1.1-1.6-2.4-3.2-3.9-4.6C189.9,329.7,187.5,327.8,184.9,326.4L184.9,326.4z"
          />
          <path
            fill="#EBEBEB"
            d="M184.9,326.9v-0.5l0.2-4.5l-6.2,18l1.7-1.3l2.2-6"
          />
          <path
            fill="#EBEBEB"
            d="M185.1,321.9l-0.1,4.4c2.6,1.5,5,3.3,7.2,5.5c1.5,1.5,2.8,3,3.9,4.6l4.4-0.7c-1.5-2.4-3.3-4.6-5.5-6.8
	C191.9,326.1,188.7,323.6,185.1,321.9L185.1,321.9z"
          />
          <path
            fill="#EBEBEB"
            d="M185.1,321.9c-1.3-0.5-2.6,0.4-3.6,2.7c-0.4,0.7-0.7,1.6-1,2.6l-2.9,13l1.5-0.5L185.1,321.9L185.1,321.9z"
          />
          <path
            fill="#656565"
            d="M179.1,323.9c-1.7-0.5-3.4-1-5.2-1.1c-0.2,1.7-0.7,3.9-1.5,6.3c1.8,0.2,3.6,0.6,5.3,1.1v-0.4
	C178.2,327.5,178.6,325.5,179.1,323.9z"
          />
          <path
            fill="#EBEBEB"
            d="M173.8,322.8c1.8,0.2,3.5,0.6,5.2,1.1c0.5-1.6,1.1-2.8,1.8-3.6c-2.3-0.7-4.7-1.3-7.3-1.6
	C174,319.8,174,321.1,173.8,322.8L173.8,322.8z"
          />
          <path
            fill="#EBEBEB"
            d="M173.8,322.8c0.1-1.6,0.1-3-0.2-4l-4.5-0.2c1.3,0.2,2.1,1.6,2.2,4c0,0.9,0,1.9-0.1,3.2l-2.8,13l-6.8,1.8
	l-9.7-9.1c-0.7-0.9-1.3-1.7-1.8-2.4c-1.3-2.1-1.5-3.5-0.5-4.5c-1.2,0.9-2.4,1.8-3.5,2.8c0.4,1,1.1,2.1,2.1,3.4
	c1.1,1.3,2.7,2.8,4.7,4.4v0.1l10.9,9.1c0-0.7,0.4-1.3,0.9-1.9c0.6-0.5,1.2-0.9,2.1-0.9c0.7,0,1.5,0.2,2.1,0.9l0.4,0.5l3.6-13.7
	C173.2,326.6,173.6,324.5,173.8,322.8L173.8,322.8z"
          />
          <path
            fill="#EBEBEB"
            d="M177.7,330.2c-1.7-0.6-3.5-1-5.3-1.1l-3.6,13.7c0.2,0.5,0.5,1,0.5,1.6v0.5h0.2c1.6,0,3,0.4,4.4,1.1
	c0.1-0.6,0.4-1.1,0.7-1.5c0.2-0.2,0.4-0.4,0.6-0.5L177.7,330.2L177.7,330.2z M190,339.1c-0.7-1-1.5-1.8-2.3-2.7
	c-1.5-1.5-3-2.8-4.7-3.8l-2.2,6l3.3,3.3L190,339.1L190,339.1z"
          />
          <path
            fill="#EBEBEB"
            d="M196.1,336.5l-0.7,0.1l-5.3,2.4l-6.1,2.8l-1.1,1.8l17.5-7.8 M198,339.7c2.1-1.3,2.9-2.5,2.4-3.8l-17.5,7.8
	l-0.2,1.6l12.8-4.1L198,339.7L198,339.7z M140.1,333.6c-0.9,1.1-1.6,2.2-2.2,3.4c0.9-0.9,2.3-0.7,4.4,0.4c0.9,0.4,1.8,1,2.8,1.7
	l10.1,8.8l-1.1,6.9l-12.6,4.3c-1.5,0.4-2.7,0.5-3.8,0.5c-2.1,0.1-3.5-0.5-4.1-1.6c0.1,1.3,0.2,2.8,0.6,4l4,0.1
	c1.7-0.2,3.8-1,6.2-1.9l13.7-5.5c-0.2-0.1-0.6-0.4-0.9-0.6c-0.6-0.5-0.9-1.2-0.9-2.1c0-0.7,0.2-1.5,0.9-2.1c0.5-0.5,1.1-0.7,1.8-0.7
	l-10.3-9.8l-0.1-0.1c-1.8-1.8-3.5-3.2-4.9-4.3C142.4,334.3,141.2,333.7,140.1,333.6L140.1,333.6z"
          />
          <path
            fill="#EBEBEB"
            d="M142.3,337.4c-2.1-1.1-3.5-1.2-4.4-0.4l15.9,11.3l1.5-0.5l-10.1-8.7C144.1,338.3,143.1,337.7,142.3,337.4
	L142.3,337.4z M149.9,329.1c0.5,0.7,1.1,1.6,1.8,2.4l9.7,9.1l0.4-1.5l-12.5-14.6C148.3,325.5,148.6,327,149.9,329.1L149.9,329.1z
	 M148.7,339.4l10.4,10h0.2c0.6,0,1.2,0.2,1.7,0.6c0.5-0.7,1-1.5,1.6-2.2c0.5-0.5,1.1-1,1.7-1.3c-0.5-0.5-0.7-1.2-0.7-1.9v-0.1
	l-10.9-9.1l-1.5,1.3C150.3,337.4,149.4,338.5,148.7,339.4L148.7,339.4z"
          />
          <path
            fill="#656565"
            d="M148.7,339.4c0.7-1.1,1.6-2.1,2.6-3l1.5-1.3v-0.1c-1.9-1.6-3.5-3-4.7-4.4l-1.2,1.1c-1.1,1.1-2.1,2.2-2.9,3.4
	C145.2,336.3,146.9,337.6,148.7,339.4L148.7,339.4z"
          />
          <path
            fill="#EBEBEB"
            d="M146.8,331.9l1.2-1.1c-1.1-1.2-1.7-2.4-2.1-3.4l-1.8,1.7c-1.5,1.5-2.7,2.9-3.9,4.5c1.1,0.2,2.3,0.7,3.6,1.7
	C144.7,334.1,145.7,333,146.8,331.9L146.8,331.9z"
          />
          <path
            fill="#656565"
            d="M167.3,323.4l0.4-0.9c-5.5,0.2-10.3,1.7-14.7,4.4l0.7,0.5l3.8,4.4c2.9-1.6,6.1-2.5,9.5-2.8L167.3,323.4
	L167.3,323.4z"
          />
          <path
            fill="#EBEBEB"
            d="M167.6,322.5l-0.4,0.9l-0.5,5.7l-0.7,6.8l1.1,1.8l1.9-19.2"
          />
          <path
            fill="#EBEBEB"
            d="M171.3,322.5c-0.1-2.4-0.9-3.8-2.2-4l-1.9,19.2l1.2,1.1l2.8-13C171.3,324.5,171.3,323.4,171.3,322.5
	L171.3,322.5z"
          />
          <path
            fill="#EBEBEB"
            d="M167.6,322.5l1.5-4c-7.4,0.1-14,2.1-19.7,6l3.5,2.4C157.3,324.2,162.2,322.8,167.6,322.5L167.6,322.5z
	 M153.6,327.4l-0.7-0.5l-3.4-2.4l12.4,14.7l-0.2-2.2l-4.3-5.1"
          />
          <path
            fill="#EBEBEB"
            d="M161.6,337l0.2,2.2l-0.4,1.5l6.8-1.9l-1.1-1l-1.1-1.8"
          />
          <path
            fill="#EBEBEB"
            d="M157.3,331.9l4.4,5.1l4.5-1.2l0.6-6.8C163.3,329.3,160.2,330.3,157.3,331.9L157.3,331.9z M169.5,344.9h-0.2
	c-0.1,0.6-0.4,1.1-0.9,1.5c-0.6,0.6-1.2,0.9-2.1,0.9c-0.7,0-1.5-0.2-2.1-0.9c-0.6,0.4-1.2,0.9-1.7,1.3c-0.7,0.7-1.2,1.3-1.6,2.2
	l0.4,0.2c0.6,0.6,0.9,1.2,0.9,2.1c0,0.9-0.2,1.5-0.9,2.1c-0.5,0.5-1,0.7-1.6,0.9c0.1,1.8,0.6,3.4,1.5,4.7l1-0.2
	c0.7,0,1.5,0.2,1.9,0.9c0.6,0.6,0.9,1.2,0.9,2.1l-0.1,0.7c1.3,0.7,2.8,1.1,4.4,1.1h0.7c0.1-0.6,0.4-1.1,0.7-1.5
	c0.6-0.6,1.2-0.9,2.1-0.9c0.7,0,1.3,0.2,1.9,0.7l1.5-1.2c0.9-0.9,1.6-1.8,2.1-2.9l-0.1-0.1c-0.6-0.6-0.9-1.2-0.9-1.9
	s0.2-1.5,0.9-2.1c0.2-0.2,0.6-0.5,1-0.6c-0.1-1.6-0.6-3.2-1.6-4.5l-0.9,0.1c-0.7,0-1.5-0.2-2.1-0.9s-0.9-1.2-0.9-2.1l0.1-0.6
	C172.5,345.3,171.1,344.9,169.5,344.9L169.5,344.9z M165.6,350.7l0.4-0.4c1-0.9,2.2-1.2,3.5-1.2h0.5l1.3,0.2
	c0.7,0.2,1.5,0.7,1.9,1.3c0.5,0.5,0.9,1,1.1,1.5c0.2,0.4,0.4,0.9,0.5,1.2l0.1,1.1c0,1.1-0.2,2.1-0.9,3l-0.7,0.9
	c-1.1,1.1-2.3,1.6-3.8,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.3-1.6-3.8C164,353.2,164.6,351.8,165.6,350.7L165.6,350.7z"
          />
          <path
            fill="#EBEBEB"
            d="M165.9,350.5l-0.4,0.4c-1.1,1.1-1.6,2.3-1.6,3.9s0.5,2.8,1.6,3.8c1.1,1.1,2.3,1.6,3.9,1.6
	c1.5,0,2.8-0.5,3.8-1.6l0.7-0.9c0.6-0.9,0.9-1.8,0.9-3l-0.1-1.1c-0.1-0.5-0.2-0.9-0.5-1.2c-0.2-0.6-0.6-1.1-1.1-1.5
	c-0.6-0.6-1.2-1.1-1.9-1.3l-1.3-0.2h-0.5C168.1,349.2,167,349.6,165.9,350.5L165.9,350.5z M168.7,342.8l-0.4-0.5
	c-0.6-0.5-1.2-0.9-2.1-0.9c-0.7,0-1.5,0.2-2.1,0.9c-0.5,0.5-0.7,1.2-0.9,1.9v0.1c0,0.7,0.2,1.5,0.7,1.9c0.6,0.6,1.2,0.9,2.1,0.9
	c0.7,0,1.5-0.2,2.1-0.9c0.5-0.4,0.7-1,0.9-1.5v-0.5C169.2,343.9,169.1,343.3,168.7,342.8L168.7,342.8z M161,349.9
	c-0.5-0.4-1-0.6-1.7-0.6H159c-0.7,0-1.2,0.2-1.8,0.7c-0.6,0.6-0.9,1.2-0.9,2.1c0,0.9,0.2,1.5,0.9,2.1c0.2,0.2,0.5,0.5,0.9,0.6
	l1.2,0.2h0.5c0.6-0.1,1.1-0.4,1.6-0.9c0.6-0.5,0.9-1.2,0.9-2.1c0-0.7-0.2-1.5-0.9-2.1L161,349.9L161,349.9z M155.3,347.8l-1.5,0.5
	h-2.2l-0.6,4.6l1.9,0.7l1.2,1.1"
          />
          <path
            fill="#EBEBEB"
            d="M159.8,355h-0.5l-1.2-0.2l-13.7,5.5c0.4,1.6,0.9,3.3,1.6,4.7l14.2-4.4l0.1-0.2c0.4-0.2,0.7-0.5,1.1-0.6
	C160.4,358.4,159.9,356.8,159.8,355L159.8,355z M154.2,354.7l-1.2-1.1l-19.4,4.3c0.6,1.1,2.1,1.6,4.1,1.6c1.1,0,2.3-0.2,3.8-0.5
	L154.2,354.7L154.2,354.7z M159.6,361.3l0.2-0.5l0.1-0.1l-14.2,4.4c-2.6,0.7-4.5,1.5-6.1,2.3c-1.6,0.7-2.6,1.6-3.2,2.5
	c0.6,1.2,1.2,2.3,1.9,3.5c-0.1-1.2,0.7-2.4,2.4-3.6c0.9-0.6,1.9-1.3,3.3-1.9l12.6-4.5l5.2,4.7l-2.6,13.1c-0.5,1.5-1,2.7-1.5,3.6
	c-1,1.8-2.1,2.8-3.3,2.9c1.2,0.5,2.4,1,3.8,1.5c0.9-0.7,1.6-1.8,2.1-3.5c0.6-1.6,1.1-3.8,1.3-6.3l2.2-14.8c-0.5,0.5-1.2,0.7-1.9,0.7
	s-1.5-0.2-2.1-0.9c-0.5-0.6-0.9-1.2-0.9-1.9C159.4,362,159.5,361.7,159.6,361.3L159.6,361.3z"
          />
          <path
            fill="#EBEBEB"
            d="M159.9,360.8l-0.2,0.5c-0.2,0.4-0.2,0.7-0.2,1.2c0,0.7,0.2,1.5,0.9,1.9c0.6,0.6,1.2,0.9,2.1,0.9
	c0.7,0,1.3-0.2,1.9-0.7l0.1-0.1c0.4-0.4,0.6-0.9,0.7-1.3l0.1-0.7c0-0.7-0.2-1.5-0.9-2.1s-1.2-0.9-1.9-0.9l-1,0.2
	c-0.4,0.1-0.7,0.4-1.1,0.6l-0.1,0.2L159.9,360.8L159.9,360.8z M145.8,344.4l5.8,3.9h2.2L137.9,337l2.7,3.4l0.7,1"
          />
          <path
            fill="#EBEBEB"
            d="M151.6,348.3l-5.8-3.9c-1.3,3-2.1,6.4-2.1,10l7.3-1.5L151.6,348.3L151.6,348.3z"
          />
          <path
            fill="#656565"
            d="M141.3,341.4l-0.7-1c-2.2,4.3-3.2,9-3.2,14.2v1.5l1.5-0.7l5-1c0-3.5,0.7-6.9,2.1-10L141.3,341.4L141.3,341.4
	z"
          />
          <path
            fill="#EBEBEB"
            d="M138.7,355.3l-1.5,0.7l-3.8,1.8l19.4-4.2l-1.9-0.7l-7.3,1.5 M138.1,362.1l-4-0.1c0.5,2.8,1.3,5.3,2.6,7.9
	c0.6-1,1.6-1.8,3.2-2.5C139.1,365.7,138.5,364,138.1,362.1L138.1,362.1z"
          />
          <path
            fill="#656565"
            d="M144.3,360.2c-2.4,1-4.5,1.6-6.2,1.9c0.4,1.8,1,3.5,1.7,5.1c1.6-0.9,3.5-1.6,6.1-2.3
	C145.2,363.5,144.7,361.9,144.3,360.2L144.3,360.2z"
          />
          <path
            fill="#EBEBEB"
            d="M144.4,372.2l4.4-2.2l6.8-3.4l1-1.8l-18.1,8.5l4.2-0.9"
          />
          <path
            fill="#656565"
            d="M148.8,370l-4.5,2.2l-1.7,0.4c1.1,1.7,2.4,3.3,4,4.7c2.6,2.6,5.2,4.6,8.3,6.1l0.1-1.7l1.6-4.7
	c-1.9-1.1-3.6-2.5-5.3-4.3C150.4,371.9,149.5,371,148.8,370L148.8,370z"
          />
          <path
            fill="#EBEBEB"
            d="M148.8,370c0.7,1,1.6,1.9,2.4,2.8c1.7,1.7,3.5,3,5.3,4.2l2.3-7.2l-3.4-3.2L148.8,370L148.8,370z
	 M140.9,369.7c-1.7,1.2-2.6,2.4-2.4,3.6l18.1-8.5l0.2-1.6l-12.6,4.5C143,368.3,141.8,369.1,140.9,369.7L140.9,369.7z M142.7,372.5
	l-4.1,0.9c1.5,2.4,3.3,4.6,5.3,6.7c3.3,3.3,6.8,5.8,10.7,7.5l0.4-4.2c-3-1.5-5.7-3.5-8.3-6.1C145.2,375.8,143.8,374.2,142.7,372.5
	L142.7,372.5z M170.1,364.7v-0.6h-0.7c-1.6,0-3-0.4-4.4-1.1c-0.1,0.5-0.4,1-0.7,1.3l-0.1,0.1l-2.2,14.8c1.6,0.5,3.2,0.7,4.9,0.9
	l3.4-14.3C170.2,365.4,170.1,365.1,170.1,364.7L170.1,364.7z M170.2,364.2v0.6c0,0.4,0.1,0.7,0.2,1.1c0.1,0.4,0.4,0.6,0.6,1
	c0.6,0.6,1.2,0.9,2.1,0.9c0.7,0,1.5-0.2,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9v-0.2c-0.1-0.7-0.4-1.2-0.9-1.7l-0.1-0.1
	c-0.5-0.5-1.2-0.7-1.9-0.7c-0.7,0-1.5,0.2-2.1,0.9C170.6,363.1,170.3,363.6,170.2,364.2L170.2,364.2z"
          />
          <path
            fill="#EBEBEB"
            d="M170.9,366.8c-0.2-0.2-0.5-0.6-0.6-1l-3.4,14.3c-0.7,2.4-1.1,4.6-1.1,6.3c-0.1,1.7,0,3,0.5,4l3.2,0.1
	c7.9,0,14.9-2.2,21-6.7l-3.5-2.3c-4.5,3-9.5,4.6-15.1,5l-1.5,4c-1.2-0.4-1.8-1.7-2.1-3.9c-0.1-1,0-2.2,0.1-3.5l2.4-13.1l6.8-2.1
	l10,8.9c0.9,1,1.6,1.8,2.2,2.7c1.2,1.8,1.5,3.3,0.9,4.4l3.3-2.7c-0.4-1.1-1.1-2.2-2.1-3.4c-1.2-1.3-2.8-2.8-4.7-4.3l-0.2-0.1
	l-10.9-8.9v0.2c0,0.7-0.2,1.5-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9C172.1,367.6,171.5,367.3,170.9,366.8L170.9,366.8z M156.6,364.8
	l-1,1.8l3.3,3.2l1.7-1.2l1.5-0.5l-5.2-4.9"
          />
          <path
            fill="#EBEBEB"
            d="M160.6,368.6l-1.7,1.2l-2.3,7.2l-1.6,4.7l-0.1,1.7l-0.2,4.3"
          />
          <path
            fill="#EBEBEB"
            d="M158.1,384.7c0.5-1,1-2.2,1.5-3.6l2.6-13.1l-1.5,0.5l-6,19.1h0.1C156,387.5,157.1,386.6,158.1,384.7
	L158.1,384.7z"
          />
          <path
            fill="#656565"
            d="M166.9,380.2c-1.7-0.1-3.4-0.5-4.9-0.9c-0.4,2.5-0.9,4.6-1.3,6.3c1.7,0.5,3.4,0.7,5.2,1
	C165.8,384.7,166.2,382.7,166.9,380.2L166.9,380.2z"
          />
          <path
            fill="#EBEBEB"
            d="M170.9,370l-2.4,13.1c-0.1,1.3-0.1,2.6-0.1,3.5c0.2,2.2,0.9,3.5,2.1,3.9l1.7-19.6L170.9,370L170.9,370z
	 M201.2,349.5c2.4-0.1,3.9,0.4,4.2,1.7c-0.1-1.5-0.4-3-0.6-4.5l-4,0.2l-6.2,1.9l-13.4,4.7c0.4,0.1,0.6,0.4,1,0.6
	c0.6,0.6,0.9,1.2,0.9,2.1c0,0.7-0.2,1.5-0.9,1.9c-0.6,0.6-1.2,0.9-2.1,0.9l10.4,10.1c1.7,1.8,3.4,3.3,4.9,4.3c1.3,1,2.6,1.6,3.6,1.8
	c4.4-6.1,6.6-13,6.6-20.8l-0.1-3.3l-3.9,1.9v1.5c0,5-1,9.5-2.9,13.6l2.7,3.4c-0.9,1-2.3,0.9-4.5-0.2c-0.9-0.4-1.7-1-2.8-1.7l-9.8-9
	l1.2-6.9l12.8-3.9L201.2,349.5L201.2,349.5z"
          />
          <path
            fill="#EBEBEB"
            d="M205.4,351.2c-0.4-1.3-1.7-1.9-4.2-1.7l-2.9,0.4l-12.8,3.9l1.1,1.1L205.4,351.2L205.4,351.2z M204.8,346.7
	c-0.5-2.4-1.3-4.7-2.3-6.9c-0.7,0.7-1.9,1.5-3.4,2.2c0.7,1.6,1.2,3.3,1.6,5L204.8,346.7L204.8,346.7z"
          />
          <path
            fill="#656565"
            d="M192.9,343.9c0.7,1.6,1.2,3.3,1.6,5l6.2-1.9c-0.4-1.7-1-3.4-1.6-5C197.4,342.7,195.5,343.3,192.9,343.9z"
          />
          <path
            fill="#EBEBEB"
            d="M194.6,348.9c-0.4-1.7-1-3.4-1.6-5l-13.7,3.9l-0.6,0.7c-0.4,0.4-0.7,0.6-1.1,0.7c0.9,1.3,1.5,2.8,1.6,4.5
	c0.4-0.1,0.7-0.2,1.1-0.2l1.1,0.2L194.6,348.9L194.6,348.9z"
          />
          <path
            fill="#656565"
            d="M195.1,354.5c0,3.5-0.6,6.7-1.8,9.7l4.6,3.3l0.6,0.7c1.9-4.1,2.9-8.6,2.9-13.6v-1.5l-0.6,0.4L195.1,354.5
	L195.1,354.5z"
          />
          <path
            fill="#EBEBEB"
            d="M195.1,354.6l-6.6,1.1l-0.7,4.6l5.6,3.9C194.5,361.3,195.1,358,195.1,354.6z M201.5,353.2l3.9-1.8l-18.8,3.5
	l1.9,0.9l6.6-1.2l5.7-1 M182.8,343.7l1.1-1.8l-3.3-3.3l-1.7,1.3l-1.5,0.4l5.1,5 M178.6,344.5c-0.6-0.6-1.2-0.9-2.1-0.9
	c-0.5,0-1,0.1-1.3,0.4c-0.2,0.1-0.5,0.2-0.6,0.5c-0.4,0.4-0.7,0.9-0.7,1.5l-0.1,0.6c0,0.7,0.2,1.5,0.9,2.1s1.2,0.9,2.1,0.9l0.9-0.1
	c0.4-0.1,0.7-0.4,1.1-0.7l0.6-0.7c0.2-0.4,0.2-0.7,0.2-1.2C179.4,345.7,179.2,345.1,178.6,344.5L178.6,344.5z M180.2,353.5
	c-0.4,0-0.7,0.1-1.1,0.2c-0.4,0.1-0.6,0.4-1,0.6c-0.6,0.6-0.9,1.2-0.9,2.1c0,0.7,0.2,1.5,0.9,1.9l0.1,0.1c0.5,0.5,1.2,0.7,1.8,0.7
	c0.7,0,1.5-0.2,2.1-0.9s0.9-1.2,0.9-1.9s-0.2-1.5-0.9-2.1c-0.2-0.2-0.6-0.5-1-0.6L180.2,353.5L180.2,353.5z"
          />
          <path
            fill="#EBEBEB"
            d="M178.3,358.5c-0.5,1.1-1.1,1.9-2.1,2.9l-1.5,1.2l0.1,0.1c0.5,0.5,0.7,1.1,0.9,1.7l10.9,8.9l0.2,0.1l0.7-0.7
	c1.1-1.1,2.1-2.3,3-3.5l-10.4-10.1C179.4,359.2,178.8,359,178.3,358.5L178.3,358.5z M188.5,355.7l-1.9-0.9l-1.1-1.1l-1.3,7l1.5-0.5
	h2.2"
          />
          <path
            fill="#EBEBEB"
            d="M187.8,360.3h-2.2l15.7,11.3l-2.7-3.4l-0.6-0.7l-4.7-3.3"
          />
          <path
            fill="#EBEBEB"
            d="M185.6,360.3l-1.5,0.5l9.8,9c1,0.7,1.9,1.3,2.8,1.7c2.1,1.1,3.5,1.1,4.5,0.2L185.6,360.3L185.6,360.3z"
          />
          <path
            fill="#656565"
            d="M187.6,372.7l-0.7,0.7c2.1,1.6,3.6,3,4.7,4.2l0.5-0.5c1.2-1.2,2.3-2.4,3.3-3.8c-1.5-1-3-2.4-4.9-4.3
	C189.8,370.5,188.7,371.6,187.6,372.7L187.6,372.7z"
          />
          <path
            fill="#EBEBEB"
            d="M177.4,369.4l0.2,2.2l4.7,5.3l3.5,4l1.1,0.6l3.5,2.3"
          />
          <path
            fill="#EBEBEB"
            d="M177.6,371.6l-0.2-2.2l0.2-1.5l-6.7,2.1l1.1,1l1.1,1.8 M177.6,371.6l-4.5,1.2l-0.5,7.3
	c3.5-0.4,6.8-1.5,9.7-3.3L177.6,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M173.1,372.8L172,371l-1.6,19.6l1.3-3.9l0.5-1.3l0.4-5.2 M177.6,368l-0.2,1.5l13.1,14.5h0.1
	c0.7-1.1,0.4-2.5-0.9-4.4c-0.5-0.9-1.2-1.7-2.2-2.7L177.6,368L177.6,368z"
          />
          <path
            fill="#656565"
            d="M172.6,380.1l-0.4,5.2l-0.5,1.3c5.6-0.4,10.6-2.1,15.1-5l-1.1-0.7l-3.5-4
	C179.4,378.7,176.2,379.8,172.6,380.1L172.6,380.1z"
          />
          <path
            fill="#EBEBEB"
            d="M192.2,377.3l-0.5,0.5c1.1,1.2,1.8,2.4,2.1,3.4l1.1-1.1c1.6-1.5,2.9-3,4.1-4.7c-1.1-0.2-2.3-0.9-3.6-1.8
	C194.4,374.9,193.3,376.1,192.2,377.3L192.2,377.3z M190.5,384l0.1-0.1h-0.1 M166.2,390.6c-0.4-1-0.6-2.3-0.5-4
	c-1.8-0.2-3.5-0.5-5.2-1c-0.6,1.6-1.3,2.8-2.1,3.5C161,389.8,163.5,390.3,166.2,390.6L166.2,390.6z M154.6,387.7h0.1H154.6z
	 M410.3,166.6c-0.7-1-1.7-1.5-3-1.5l-24.2,1c-15.3,1.1-29.9,3.4-43.9,6.9c-8.5,2.6-15.9,5.8-22.5,9.6l-0.4,0.2
	c-1.2,0.6-2.3,1.3-3.5,2.1c-6.3,3.9-12.3,8.3-17.6,12.9l-0.7,2.7c2.1,0,3.6,0.7,4.9,2.2c1.6,2.7,2.8,6.8,3.5,12.3v1
	c0,4.4-1.3,7.4-4.2,9.1l-1,0.4c-2.1,0.6-4.1,0.7-6.2,0.6l-1.7,0.7v1.9l109-3c2.8-0.2,4.9-3,6-8.1v0.1l5.7-40
	C411.4,172.3,411.2,168.6,410.3,166.6L410.3,166.6z M319.3,166.8c-0.9,0.5-1.7,0.9-2.5,1.3c-9.1,5-18,10.6-26.7,16.8
	c-11.3,8-22.1,17-32.8,26.8c-3.8,3.5-7.7,7.3-11.4,11.2h6c3.4-3.6,6.8-7.2,10.3-10.6c10.7-10.4,21.4-19.7,32.1-27.5
	c7.4-5.3,14.9-10.2,22.5-14.2c1.3-0.7,2.7-1.5,4-2.2L319.3,166.8L319.3,166.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M97.2,252h18.3 M115.7,253.5l-17.7-0.1l-1,0.4 M109.8,260.6l-12.5,0.1
	l-0.8-0.5 M83.2,272.4l-2.3-2.7 M75.3,266.9c2.2,0.4,3.9,2.4,5,6 M83.7,254.1l-6.7,1.2 M74.7,266.9h-8.4 M75.1,254.9l-0.4,12
	 M69,254.9c-1.2,0.2-1.8-0.1-1.9-1 M75.3,266.9h-0.6 M69,254.9h6.1 M77,255.4l-1.9-0.5 M88.1,266.1l-3.8,0.2 M89.9,259.5
	c-1.1-3.3-2.4-5.1-4.1-5.7 M83.7,254.1l0.6,12.2 M89.8,253.1l-3.9,0.7 M96.5,260.2c-0.1,3.9-1.1,6.8-2.9,8.9 M88.1,266.1
	c0.4-0.4,0.7-1,1-1.6c0.4-1.6,0.7-3.2,0.8-4.9 M84.3,266.3l-3.8,0.2 M77,255.4c3,0.9,5.1,2.5,6.1,5.1c0.8,2.5,0,4.6-2.6,6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M80.5,266.5h-0.1l-5.1,0.4 M80.9,269.7V268l5.1-1c0.7,0,1.3-0.2,1.9-1
	 M85.9,253.8l-2.2-0.1v0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M88.9,248.9c1.9-0.1,2.9,1.1,3,3.8v8.7c0,3.4-1.2,6-3.5,7.4l-7.5,0.9
	 M96.5,260.2l-1.9-1.1v-4.4l2.4-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M89.9,259.5c0.1-1.6,0.1-3.3,0-5l-0.1-1.5 M89.8,253.1
	c-0.2-1.3-1-2.1-2.2-2.2c-6.7,0.7-12.3,1.8-16.8,3.5h-0.1c-0.1,0-0.1,0-0.2,0.1l-1.5,0.4 M96.9,253.8c0.2-4.3-2.2-6-7.4-5l-0.6,0.1
	 M88.9,248.9h-0.2c-7.2,0.9-14.2,2.4-21.1,4.5 M763.4,261.6l-0.2-17 M767.9,262l-0.1-17.5 M772.6,271.3l-0.7-2.4h-9 M772.6,271.3
	l2.5-1.1 M774.8,261c-1.1-0.1-1.7-1-2.2-2.5 M763.4,261.6l4.5,0.5 M762.2,269l-0.4,1.5l2.3,11.1h6.9l1.6-10.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M772.6,258.4v6.6c-0.2,1.2,0.7,1.9,2.7,2.1l-6.1,0.1l-1.3-2.2v-0.2
	 M767.9,264.6V262 M762.9,268.8h-0.7v0.1 M762.9,268.8l-0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M767.9,264.6h-9.2l1.1,4.5l2.4-0.1 M767.8,244.6h-4.6 M763.2,244.6h-4.5
	c-2.2-0.2-3,0.6-2.6,2.6l3.9,12.4c0.1,1.2,1.2,1.9,3.4,2.1 M774,238.7c-0.2-0.5-0.6-1-1.2-1.2 M767.8,244.6c0.4-4.9,1.6-7.2,3.5-7.3
	c0.5,0.1,1,0.2,1.3,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M772.6,237.6l0.7,6.6c-0.6,0-0.7,1-0.7,2.9v11.3 M721.7,320.4l-0.1-0.5
	c-5-18.3-10.6-33.2-16.9-44.5c-1-1.7-1.9-3.3-2.9-4.9l-1.2-1.7c-4.3-5.6-10.2-9.4-17.9-11.3l-3.3-0.7c-2.9-0.5-6-0.7-9.4-0.7
	c-7.4-0.2-14.7-0.2-21.7,0l-2.2,0.1c-13.2,0.5-25.8,1.7-37.4,3.6c-13.5,1.8-24.5,8.5-33.2,20.2c-7.5,11.4-13.1,25.8-16.9,42.8
	c-0.2,1-0.1,1.8,0.1,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M559.1,325.3c3.4-1.6,6.1-6,7.8-12.9c5.3-18.8,13.4-31.7,23.9-38.3
	c7.7-5.1,19.4-8.3,35.6-9.6l22.4-1.3c8.9-0.2,17.2-0.1,25.3,0.5c10.3,0.7,18.3,5.6,24.1,14.7l1,1.6c6.7,11.5,12.8,25.4,18.2,41.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M707.2,323.5c-0.7-3-1.6-6-2.6-9c-1.7-5.2-3.5-10-5.5-14.3
	c-1-2.1-1.8-4-2.8-6l-0.1-0.2c-1.1-2.1-2.1-3.9-3.2-5.7l-2.1-3.2l-1.9-3c-1.1-1.6-2.3-3-3.8-4.3c-0.9-0.7-1.8-1.5-2.8-2.1
	c-0.5-0.2-1.1-0.6-1.6-0.7l-2.2-1c-4.3-1.7-9.5-2.4-15.7-2.2h-0.1c-18.8-0.2-37.2,1.3-55.2,4.6L606,277c-8.1,2.2-15.2,7.3-21.1,15.3
	v0.1c-6.2,9.5-11.4,22-15.7,37.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M702.4,313.4c-1.6-4.5-3.2-8.7-4.9-12.6l-2.8-5.8l-0.1-0.2
	c-1.1-1.9-2.1-3.8-3.2-5.5l-2.1-3l-2.1-3c-1-1.5-2.1-2.8-3.4-3.9c-0.7-0.7-1.6-1.3-2.5-1.8l-3.5-1.7c-4.1-1.6-9.1-2.3-14.9-2.1
	c-18.7-0.2-37.1,1.2-54.8,4.5l-1.8,0.5c-7.8,2.2-14.5,7-20.3,14.7c-3.5,5.6-6.8,12.1-9.8,19.9 M758.5,284.6h0.1
	c-7.2,0.1-12.1,2.3-14.8,6.8L727.7,316c-1.6,2.2-3.6,3.6-6,4.4 M723.9,259c-8.5-10.4-22.1-16.5-40.6-18.3c-21.3-2.1-45.3-1.6-72,1.5
	l-9.2,1.5 M508.8,160l1-1.3c-0.5-2.1-1.2-3.8-2.5-5.1l-2.3-0.4 M509.9,164.2c-1.1-2.8-2.6-5-4.5-6.7c-1.1-1-2.3-1.8-3.8-2.6
	c-2.3-1.1-5-1.7-8-1.9l-10-1l-35.4-1h-7.2l-13.1,0.4c-35.7,0.7-65.2,4-88.7,10l-5.2,1.6l-9.4,3.6l-4,1.9 M509.3,161.8l-0.5-1.8
	 M509.9,164.2l-0.6-2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M516.3,221.2l-0.1-1.5c-0.8-11.4-1.9-22.5-3.2-33
	c-0.8-7.2-1.8-14.2-2.9-21l-0.4-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M518.5,221.2c-1.7-20.2-3.4-35-5.2-44.6c-1.8-9.6-3.2-14.6-4-14.7
	 M504.9,153.1c1.8,1.7,3.2,3.9,3.9,6.8 M495.5,150c-1.1-0.2-1.8-0.9-2.2-1.8l-0.2-0.9l0.5-3.4c0.1-1.6-0.4-2.6-1.7-3.2
	c0,0-2.6,1.2-5.2,2.1l-4,1.5c-0.9,0.2-1.7,0.6-2.6,0.9c-2.6,1-4.4,2.2-5.7,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M474.4,148.8l14.1,0.2l7,1 M504.9,153.1c-1.6-1.3-3.5-2.3-5.8-2.8
	l-3.5-0.4 M474.4,148.8l-20-0.2l-17.5,0.4l-43.9,1.7c-19.4,1.5-37.4,4.3-53.7,8.4c-4.1,1.2-8,2.6-11.7,3.9l-4.9,1.9
	c-1.2,0.6-2.3,1.2-3.5,1.8 M424.3,158.3l30,0.1c18.3,0.1,27.1,0.6,36.4,1.1h0.6c9-0.2,14.5,4.6,16.5,14.6c4.4,25.9,6.3,57.7,6,95.1
	c0.1,7.3-0.1,14.3-0.5,21.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M491.4,171.2l-0.1-0.4c-1.1-4.6-3.6-7.4-7.5-8.4
	c-15.4-1.5-30.7-1.8-45.9-1.1c-3.3-0.2-5.5,1.8-6.4,6.2c-1.7,15.9-2.7,32.9-2.7,51.4c0,4.6,2.4,6.8,7.2,6.3l0.9-0.1
	c15.7-0.5,31.1-2.1,46.3-4.9c7.7-1.2,11.2-5.3,10.7-12.5c0.1-10.3-0.5-21.4-1.7-33.2C491.9,173.4,491.6,172.3,491.4,171.2
	L491.4,171.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.2,231.1c34.5-1.1,58.6-3.8,72.3-8.1c4.5-1.9,6.8-5.2,6.9-10v-1.1
	c-0.2-19.6-1.8-33.2-4.9-40.7 M518.5,221.2h-2.2 M516.3,221.2l0.1,1.5 M518.5,222.7h-2.1 M512.3,334l0.7-1.8c1.2-3.4,2.2-8,3-14
	c1.2-9,2.2-18.2,2.9-27.7c0.6-8.4,1-17,1-25.8c0-5.8,0-11.9-0.2-18c-0.4-8.1-0.9-16.2-1.3-23.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M516.4,222.7c0.6,7.8,1.1,15.8,1.3,23.9c0.1,6.1,0.2,12.1,0.1,18
	c-0.1,8.7-0.4,17.4-1,25.8 M571.8,255.4c-2.8,2.1-5.2,4.3-7.4,6.7 M517,290.4h-3.6 M571.8,255.4c7.5-5.5,17.6-9.5,30.2-11.9
	 M513.4,290.4l-60,0.1l-38.5,0.4 M589.8,321.5c-2.3,3.4-4.3,6.9-5.7,10.6 M584.1,332c-0.5,1.2-1,2.6-1.3,3.8
	c-0.6,1.8-1.1,3.6-1.5,5.5 M610.1,333.6c-0.9,1.1-1.6,2.2-2.2,3.4 M607.9,337c0.9-0.9,2.3-0.7,4.4,0.4l2.8,1.7l10.1,8.8 M576,313.1
	l13.8,8.4 M683.6,314.4l-1.9-1.9C670,300.8,656,295,639.5,295s-30.5,5.8-42.2,17.5c-2.9,2.9-5.3,5.8-7.5,9 M510.4,334
	c1.1-2.3,1.9-5.5,2.8-9.4c0.4-1.9,0.7-4.1,1.1-6.4c1.2-9,2.2-18.2,2.9-27.7 M471.1,339.3l34.5-0.2c1.1-0.2,2.1-1,3-2.2 M568.8,329.8
	l-1.2,10.2l-51.6-1.5c-1.2,0.2-2.2,0.1-2.8-0.2c-1.1-0.6-1.3-2.2-0.7-4.5 M512.3,334h-1.9 M508.7,336.9c0.6-0.7,1.1-1.7,1.7-2.9
	 M559.1,325.3c0.5,1.3,1.8,2.3,3.9,2.8l5.7,1.7 M572,325l-1.6,5.3l-1.6-0.5 M572.8,345c-2.4,1-4.9,1.8-7.4,2.5 M502.5,353.2
	c3.6-0.5,7.3-1.1,10.7-1.8l3.8-0.7 M615.8,344.4l-4.5-3l-0.7-1 M604.1,362l4,0.1 M603.5,357.9c0.6,1.1,1.9,1.6,4.1,1.6
	c1.1,0,2.3-0.2,3.8-0.5l12.6-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M610.6,340.4c-2.2,4.3-3.3,9-3.3,14.2v1.5 M613.7,354.4l-5,1l-1.3,0.7
	 M607.9,337c-2.9,5.2-4.5,11.2-4.5,17.6c0,2.5,0.2,5,0.7,7.4 M607.4,356.1l-3.9,1.8 M581.2,341.3l-1.1,0.6c-2.3,1.1-4.7,2.2-7.3,3.2
	 M580.2,348.4c-0.1,1.1-0.2,2.3-0.2,3.5 M581.2,341.3l-0.1,0.6c-0.2,1.1-0.4,2.1-0.6,3.2 M580.6,345l-0.4,3.4 M580,351.9v4.9
	 M606.7,369.8c0.6,1.2,1.2,2.3,1.9,3.5 M609.9,367.2c-1.6,0.7-2.5,1.6-3.2,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626.9,363.2l-12.5,4.5c-1.3,0.6-2.4,1.3-3.4,1.9
	c-1.7,1.2-2.5,2.4-2.4,3.6 M612.8,372.5l-4.1,0.9 M604.1,362c0.5,2.8,1.3,5.3,2.5,7.9 M608.2,362.1c0.4,1.8,1,3.5,1.7,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M612.9,381.1c3.2,3.2,6.7,5.7,10.5,7.5c1.7,0.9,3.5,1.6,5.3,2.1l1.3,0.4
	c3,0.7,6.2,1.1,9.5,1.1c10.3,0,19.2-3.6,26.6-11.1c1.8-1.8,3.4-3.8,4.9-5.7c4.1-6.1,6.2-13,6.2-20.8c0-2.5-0.2-4.9-0.6-7.2
	c-0.6-3.2-1.6-6.2-2.9-9.1c-1.8-3.6-4.3-7.2-7.4-10.3c-4.1-4.1-8.7-7-13.8-8.9c-3-1.1-6.1-1.7-9.4-1.9l-3.3-0.1
	c-9.1,0-17.1,2.9-23.9,8.6c-1,0.7-1.8,1.6-2.7,2.4c-7.3,7.4-10.9,16.3-10.9,26.6C602,364.9,605.6,373.8,612.9,381.1L612.9,381.1z
	 M576,313.1c-1.5,3.8-2.8,7.7-4,11.9 M584.1,332l-12.2-7 M610.6,340.4l-2.7-3.4 M516.8,350.6l63.2,1.3 M580,356.8l-77.5-3.6
	 M565.4,347.6l14.8,0.9 M580.6,345h-7.8 M451.1,349.3v-9.5h-0.5 M462.9,339.4h2.8 M465.7,339.4l4-0.1 M466.7,350.5
	c-0.2-0.2-0.6-0.6-0.9-1.1l-0.4-0.6c-0.5-1.3-0.7-2.9-0.7-4.9c0-1.3,0.1-2.5,0.5-3.6l0.2-0.6l0.1-0.2 M450.6,339.8l12.3-0.4
	 M470.4,344c0-1.5-0.1-2.7-0.5-3.8l-0.2-1 M470.4,344l-2.1-0.1c-1,1.1-1.2,2.2-1,3.3l0.1,0.5l2.4,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M462.9,339.4l-0.1,0.9c-0.1,0.9-0.2,1.7-0.2,2.7c0,1.8,0.2,3.4,0.7,4.7
	c0.4,1,0.9,1.6,1.3,1.8l0.4,0.1l1.8,0.9 M466.7,350.5c0.2,0.1,0.5,0.2,0.7,0.2h0.1c0.7,0,1.5-0.7,1.9-1.9l0.4-1.1 M451.1,355.6v-6.3
	 M469.8,355.6c-4.1-4.1-10.3-6.3-18.6-6.3 M469.8,355.6h-18.7 M471.1,347.8v7.8 M471.1,355.6l12.5-0.1c8-0.4,8.3-1,15.3-1.8l3.4-0.5
	 M471.1,344v-4.7 M469.7,339.3h1.5 M471.1,344h-0.7 M471.1,355.6h-1.3 M469.9,347.7l1.2,0.1 M513.4,290.4c-0.7,12.1-2.3,23.4-4.6,34
	c-2.3,5.7-8.9,8.9-19.7,9.5 M558.1,347.6l-94.3-3.5 M698.9,349c0.1,1.8,0.2,3.8,0.2,5.6c0,16.5-5.8,30.5-17.5,42.2
	c-11.7,11.7-25.6,17.5-42.2,17.5s-30.5-5.8-42.2-17.5c-11.2-11.2-17-24.4-17.5-40 M516.8,350.6l-45.7-2.8 M257.1,330.8
	c2.8,5.8,6.8,8.5,12.3,8l175.7-1.2l63.7-0.7 M510.4,334l-21.3-0.1 M714.9,332l-4.7,0.5l13.6,2.8 M706.3,328.9
	c-0.6-4.6-1.7-9.2-3.4-13.7l-0.6-1.7 M706.3,328.9l-15.9-5.6 M721.7,320.4l-4.6,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M706.8,336.3c0.2,1.5,1.2,2.2,3.2,1.9l13.8-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M707.2,323.5c1,4.1,1.5,8.3,1.6,12.4c0,0.2-0.1,0.5-0.2,0.6
	s-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.6-0.4 M723.8,335.3c0-2.2-0.2-4.3-0.4-6.3 M717.1,321.3l-10,2.2
	 M723.4,329c-0.4-3-0.9-5.8-1.6-8.6 M706.8,336.3c0-2.6-0.2-5-0.5-7.4 M698.9,349h7.3 M706.2,349c0.7,1.8,2.8,2.8,6.1,2.9h11.7
	c3.9-0.2,6.1-1.2,6.3-2.9 M690.4,323.3c4.9,7.8,7.8,16.3,8.5,25.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M723.4,329l-13.1-1.2l44-4 M730.2,349h-24.1 M655,326.4l0.1-4.5
	 M655.2,321.9c-1.3-0.5-2.5,0.4-3.5,2.7c-0.4,0.7-0.7,1.6-1.1,2.6l-2.9,13 M653,332.6l1.9-5.7l0.1-0.5 M655.2,321.9
	c-1.3-0.7-2.8-1.2-4.3-1.7 M650.8,338.6l2.2-6 M660,339.1c-0.7-1-1.6-1.8-2.4-2.7c-1.5-1.5-3-2.8-4.7-3.8 M666.1,336.5l-0.7,0.1
	l-5.3,2.4 M655,326.4c2.5,1.5,5,3.3,7.2,5.5c1.5,1.5,2.8,3,3.9,4.6 M670.5,335.9c-1.5-2.4-3.3-4.6-5.5-6.8c-3-3-6.3-5.3-9.8-7
	 M670.4,335.9h0.1 M652.6,345.3l12.6-4.1c1.1-0.5,1.9-1,2.7-1.5c2.1-1.3,2.9-2.5,2.4-3.8 M670.4,335.9l-4.3,0.6 M627.3,331.9
	l-3.6-4.5l-0.7-0.5 M619.5,324.5c-1.2,0.9-2.4,1.8-3.5,2.8 M619.5,324.5c-1,1-0.8,2.5,0.5,4.5c0.5,0.7,1.1,1.6,1.9,2.4l9.7,9.1
	 M613.7,335.2c0.8-1.1,1.8-2.3,2.9-3.4l1.2-1.1 M618.7,339.4c-1.8-1.8-3.5-3.2-5-4.2 M622.7,335.2l-1.5,1.3c-1,1-1.8,1.9-2.5,3
	 M631.7,337l-4.4-5.1 M618,330.7c1.2,1.3,2.7,2.8,4.7,4.4l0.1,0.1 M615.8,327.4l-1.8,1.7c-1.5,1.5-2.7,2.9-3.9,4.5 M623,326.9
	l-3.5-2.4 M618,330.7c-1.1-1.2-1.7-2.4-2.1-3.4 M649.2,323.9c-1.7-0.5-3.4-1-5.2-1.1 M643.6,318.8c0.2,1.1,0.4,2.4,0.2,4
	 M639.1,318.5l-1.5,4 M637.7,322.5l-0.4,0.9l-0.5,5.7 M636.2,335.9l1,1.8 M638.4,338.7l-1.2-1 M636.8,329.1l-0.6,6.8 M642.5,329.1
	c1.8,0.2,3.6,0.6,5.3,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M647.9,330.2v-0.4c0.4-2.3,0.7-4.4,1.3-6 M638.4,338.7l2.8-13
	c0.1-1.2,0.1-2.3,0.1-3.2c-0.1-2.4-0.8-3.8-2.2-4 M643.9,322.8c-0.2,1.7-0.7,3.9-1.5,6.3 M637.2,337.7l1.9-19.2 M643.6,318.8
	l-4.5-0.2 M639.1,318.5c-7.3,0.1-13.8,2.1-19.7,6 M636.8,329.1c-3.4,0.2-6.6,1.2-9.5,2.8 M623,326.9c4.4-2.7,9.4-4.1,14.7-4.4
	 M636.2,335.9l-4.5,1.1 M619.5,324.5l12.4,14.7 M631.8,339.2l-0.1-2.2 M647.6,340.3l1.5-0.4 M649.2,347.8c0.2-0.4,0.2-0.7,0.2-1.2
	c0-0.7-0.2-1.5-0.9-2.1c-0.5-0.6-1.2-0.9-2.1-0.9c-0.5,0-1,0.1-1.3,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M645.3,344l-0.6,0.5c-0.4,0.4-0.7,0.9-0.7,1.5 M641.3,349.5l-1.3-0.2h-0.5
	c-1.3,0-2.4,0.4-3.5,1.2l-0.4,0.4c-1.1,1.1-1.6,2.3-1.6,3.9s0.5,2.8,1.6,3.8c1.1,1.1,2.3,1.6,3.9,1.6c1.5,0,2.8-0.5,3.8-1.6l0.7-0.9
	c0.6-0.9,0.8-1.8,0.8-3l-0.1-1.1c-0.1-0.5-0.2-0.9-0.4-1.2c-0.2-0.6-0.6-1.1-1.1-1.5C642.8,350.1,642,349.8,641.3,349.5L641.3,349.5
	z M643.9,346l-0.1,0.6c0,0.7,0.2,1.5,0.9,2.1s1.2,0.9,2.1,0.9l0.8-0.1 M647.6,349.3c0.4-0.1,0.7-0.4,1.1-0.7l0.6-0.7 M643.9,346
	c-1.3-0.7-2.8-1.1-4.4-1.1h-0.2 M638.9,342.8l-0.4-0.5c-0.5-0.5-1.2-0.9-2.1-0.9c-0.7,0-1.5,0.2-2.1,0.9c-0.5,0.5-0.7,1.2-0.9,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M639.3,344.9v-0.5c0-0.6-0.1-1.1-0.5-1.6 M633.7,344.3v0.1
	c0,0.7,0.2,1.5,0.8,1.9 M634.4,346.4c-0.6,0.4-1.2,0.9-1.7,1.3c-0.6,0.7-1.2,1.3-1.7,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M634.4,346.4c0.6,0.6,1.2,0.9,2.1,0.9c0.9,0,1.5-0.2,2.1-0.9
	c0.5-0.4,0.7-1,0.8-1.5 M635,363.1l0.1-0.7c0-0.7-0.2-1.5-0.8-2.1s-1.2-0.9-1.9-0.9l-1,0.2 M651.3,353.8l-1.1-0.2
	c-0.4,0-0.7,0.1-1.1,0.2 M649.1,353.8c-0.4,0.1-0.6,0.4-1,0.6c-0.6,0.6-0.8,1.2-0.8,2.1c0,0.7,0.2,1.5,0.8,1.9l0.1,0.1 M644.8,362.6
	c-0.5-0.5-1.1-0.7-1.8-0.7s-1.5,0.2-2.1,0.9c-0.4,0.5-0.7,1-0.7,1.5 M648.4,358.5c0.5,0.5,1.2,0.7,1.8,0.7 M648.4,358.5
	c-0.5,1.1-1.1,1.9-2.1,2.9l-1.5,1.2 M647.6,349.3c0.8,1.3,1.5,2.8,1.6,4.5 M629.2,349.4c-0.7,0-1.2,0.2-1.8,0.7
	c-0.6,0.6-0.8,1.2-0.8,2.1c0,0.9,0.2,1.5,0.8,2.1l0.9,0.6 M625.3,347.8l-1.5,0.5 M631,349.9c-0.5-0.4-1-0.6-1.7-0.6H629"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.9,355c0.6-0.1,1.1-0.4,1.5-0.9c0.6-0.5,0.8-1.2,0.8-2.1
	c0-0.7-0.2-1.5-0.8-2.1l-0.4-0.2 M621.8,348.3l-6-3.9 M621,352.9l-7.3,1.5 M628.2,354.7l-13.1,5.1c-0.2,0.1-0.5,0.2-0.7,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M628.2,354.7c0.4,0.1,0.7,0.2,1.2,0.2h0.5 M624.2,354.7l-1.2-1.1
	 M630.1,360.7l-0.1,0.1l-0.2,0.5c-0.2,0.4-0.2,0.7-0.2,1.2c0,0.7,0.2,1.5,0.8,1.9c0.6,0.6,1.2,0.9,2.1,0.9c0.7,0,1.3-0.2,1.9-0.7
	 M631.4,359.8c-0.4,0.1-0.7,0.4-1.1,0.6l-0.1,0.2 M631.4,359.8c-0.9-1.5-1.5-3-1.5-4.7 M615.8,344.4c-1.3,3-2.1,6.4-2.1,10
	 M624.2,354.7l1.1-6.9 M623.8,348.3h-2.1 M621.8,348.3l-0.7,4.6 M623,353.6l-1.9-0.7 M626.7,364.8l0.1-1.6 M630.6,368.6l-1.6,1.2
	 M629,369.8l-3.4-3.2 M626.7,364.8l-1.1,1.8 M618.8,370c0.7,1,1.6,1.9,2.4,2.8c1.7,1.7,3.4,3,5.3,4.2 M618.8,370l-4.4,2.2l-1.7,0.2
	 M624.8,387.7c1.2-0.1,2.3-1.1,3.3-2.9c0.5-1,1-2.2,1.5-3.6l2.5-13.1 M626.7,377l-1.6,4.7l-0.1,1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M625,383.4c-3-1.5-5.7-3.5-8.3-6.1c-1.5-1.5-2.8-3-4-4.7 M625.6,366.6
	l-6.8,3.4 M629,369.8l-2.3,7.2 M645.8,364.5c-0.1-0.7-0.4-1.2-0.9-1.7l-0.1-0.1 M647.6,368l-0.2,1.5 M647.4,369.4l0.2,2.2
	 M647.6,371.6l-4.5,1.2 M643.1,372.8l-1-1.8 M640.3,365.8l0.6,1c0.6,0.6,1.2,0.9,2.1,0.9c0.7,0,1.5-0.2,1.9-0.9
	c0.6-0.6,0.9-1.2,0.9-1.9v-0.2 M640.2,364.2h-0.7c-1.6,0-3.2-0.4-4.5-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M640.2,364.2v0.6c0,0.4,0.1,0.7,0.2,1.1 M634.2,364.5
	c0.5-0.5,0.7-0.9,0.9-1.3 M636.9,380.2c-1.7-0.1-3.4-0.5-4.9-0.9 M642.7,380.1l-0.2,5.2l-0.5,1.3 M641,370l6.7-2.1 M642.2,371
	l-1.2-1 M641,370l-2.6,13.1c-0.1,1.3-0.1,2.6,0,3.5c0.2,2.2,0.9,3.5,2.1,3.9 M643.1,372.8l-0.5,7.3 M634.2,364.5l-2.1,14.8
	 M636.9,380.2l3.4-14.5 M632.1,368.1l-5.2-4.9 M632.1,368.1l-1.5,0.5 M615.9,364.9c-0.7-1.6-1.2-3.2-1.6-4.7 M615.9,364.9l14.2-4.3
	 M674.8,346.7c-0.5-2.4-1.2-4.7-2.3-6.9 M675.5,351.2c-0.1-1.5-0.4-3-0.6-4.5 M675.5,351.2c-0.4-1.3-1.7-1.9-4.3-1.7l-2.9,0.4
	l-12.8,3.9 M674.8,346.7l-4,0.2 M671.6,353.2l-0.6,0.4l-5.7,1 M669,375.4c4.4-6.1,6.6-13,6.6-20.8l-0.1-3.3 M671.6,353.2l3.9-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M668.7,368.2c1.9-4.1,2.9-8.6,2.9-13.6v-1.5 M675.5,351.3v-0.1 M669.1,342
	c-1.6,0.7-3.6,1.3-6.1,1.9h-0.1 M662.9,343.9c0.7,1.6,1.2,3.3,1.7,5 M654.1,341.9l-1.1,1.8 M652.6,345.3l0.4-1.6 M656.6,354.9
	l-1.1-1.1 M658.6,355.7l-1.9-0.9 M650.2,359.2c0.8,0,1.5-0.2,2.1-0.9s0.9-1.2,0.9-1.9s-0.2-1.5-0.9-2.1c-0.2-0.2-0.6-0.5-1-0.6
	 M655.8,360.3h2.1 M655.5,353.8l-1.2,7 M654.3,360.8l1.5-0.5 M657.8,360.3l0.7-4.6 M663.4,364.2c1.2-2.9,1.8-6.2,1.8-9.6v-0.1
	 M664.6,348.9l-13.4,4.9 M665.2,354.5l-6.7,1.2 M665.5,373.6c-1.5-1-3-2.4-4.9-4.3 M663.4,364.2l4.6,3.3l0.6,0.7 M654.3,360.8l10,9
	c1,0.7,1.9,1.3,2.7,1.7c2.1,1.1,3.5,1.1,4.5,0.2 M660.6,369.3c-0.8,1.2-1.8,2.3-3,3.5l-0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M645.8,364.5l10.9,8.9l0.2,0.1 M652.4,377l3.6,4l1,0.6 M660.6,383.9
	c0.7-1.1,0.4-2.5-0.8-4.4c-0.5-0.9-1.2-1.7-2.2-2.7l-10-8.9 M661.7,377.7l0.5-0.5c1.2-1.2,2.3-2.4,3.3-3.8 M660.5,383.9v0.1l0.1-0.1
	 M660.6,383.9l3.3-2.7 M663.9,381.2c0.1,0.5,0.2,1.1,0.1,1.6 M660.5,383.9h0.1 M661.7,377.7c1.1,1.2,1.8,2.4,2.1,3.4 M663.9,381.2
	l1.1-1.1c1.5-1.5,2.9-3,4-4.7 M657,373.4c2.1,1.6,3.6,3,4.7,4.3 M657,381.6l3.5,2.3 M669,375.4c-1.1-0.2-2.3-0.9-3.6-1.8
	 M672.5,339.8c-0.7,0.7-1.9,1.5-3.4,2.2 M671.3,371.6l-2.7-3.4 M670.8,347c-0.4-1.7-1-3.4-1.7-5 M656.6,354.9l18.8-3.5 M657.8,360.3
	l5.6,3.9 M671.3,371.6l-15.5-11.3 M664.6,348.9l6.2-1.9 M650.9,320.4c-2.3-0.7-4.7-1.3-7.3-1.6 M672.5,339.8c-0.6-1.3-1.3-2.7-2.1-4
	 M647.6,340.3l5,5 M654.1,341.9l-3.3-3.3 M660,339.1l-6,2.8 M653,343.7l17.4-7.8 M622.7,335.2l10.9,9.1 M631.6,340.6l6.8-1.9
	 M642.5,329.1l-3.6,13.7 M650.9,320.4c-0.7,0.9-1.2,2.1-1.8,3.6 M645.3,344l2.5-13.8 M662.9,343.9l-13.7,3.9 M631.8,339.2l-0.2,1.5
	 M649.1,339.9l1.7-1.3 M642.7,380.1c3.5-0.4,6.7-1.5,9.7-3.3 M660.5,383.9l-13.1-14.5 M647.6,371.6l4.7,5.3 M655.2,321.9l-6.1,18
	 M629.2,349.4l-10.4-10 M650.2,359.2l10.4,10.1 M636.3,390.6l3.2,0.1c7.9,0,14.9-2.2,21-6.7 M636.3,390.6c-0.4-1-0.6-2.3-0.5-4
	 M640.5,390.6l1.5-3.9 M630.6,385.6c-0.6,1.6-1.3,2.8-2.1,3.5 M624.7,387.7h0.1 M624.7,387.7h0.1 M624.8,387.7
	c1.2,0.5,2.4,1,3.8,1.5 M628.6,389.1c2.4,0.7,5.1,1.2,7.8,1.5 M635.8,386.6c-1.8-0.2-3.5-0.5-5.2-1 M608.6,373.3
	c1.5,2.4,3.3,4.6,5.3,6.7c3.3,3.3,6.8,5.8,10.7,7.5 M635.8,386.6c0.1-1.8,0.5-3.9,1.1-6.3 M702.4,313.4l-18.8,1 M683.6,314.4
	c2.5,2.8,4.9,5.8,6.8,8.9 M632.1,379.3c-0.4,2.5-0.8,4.6-1.5,6.3 M641.9,386.7c5.6-0.4,10.6-2.1,15.1-5 M625,383.4l-0.4,4.3
	 M624.7,387.7l6-19.1 M640.5,390.6l1.7-19.6 M623.8,348.3L607.9,337 M608.6,373.3l18.1-8.5 M610.1,333.6c1.1,0.2,2.3,0.7,3.6,1.7
	 M614.3,360.2c-2.4,1-4.5,1.6-6.2,1.9 M609.9,367.2c1.5-0.9,3.5-1.6,6.1-2.3 M603.5,357.9l19.4-4.3 M776.7,222.7l-0.1-1.5h-258
	 M776.7,222.7l0.5,3.4 M775.2,238l-1-5.6l2.9-6.3 M776.7,252.8l2.4-2.9c1.2-1.5,1.2-3,0-5l-3.8-6.9 M760.1,283.7
	c-1.8-14.5-4.6-26.7-8-36.7c-0.9-2.7-0.1-4.7,2.2-6.3l18.7-7.3l1,5.3c0.7,4.6,1.1,9.6,1.3,14.9c0.1,3.2,0.1,6.4,0.1,9.8l-0.1,3.4
	l-0.1,3.2c-0.2,4.3-0.5,8.7-1,13.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M777.1,226.1l-24.1,13.1c-3.3,1.6-4.3,3.9-3,7l1.6,4.5
	c3,9.5,5.3,20.8,7,34 M758.5,284.6h1.1 M754.3,323.8l0.5-1.9l0.1-0.7c3.5-16,4.9-28.2,3.6-36.3v-0.1 M769.2,288.6H764 M764,288.6
	h-0.2c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,15.3-1.7,27.2-5.2,35.7c-0.1,0.2-0.1,0.4,0,0.7c0.1,0.2,0.2,0.4,0.5,0.5
	h0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764,288.6l0.4,0.2c0.1,0.1,0.2,0.4,0.2,0.6c0,15.5-1.8,27.7-5.3,36.3
	c-0.1,0.2-0.2,0.4-0.5,0.5h-0.1 M760.5,286.7l8.7-0.4 M769.2,286.3v2.3 M760.1,283.7l0.4,3 M771.8,286.2l2.4-2.7 M771.8,288.6v-2.4
	 M783.3,320v-27.7c-1.2-2.7-2.8-3.9-4.9-3.5h-6.7 M774.2,283.5c2.1-10,2.8-20.2,2.4-30.7 M774.2,283.5l-14.1,0.1 M771.8,288.6h-2.5
	 M769.2,286.3l2.5-0.1 M776.7,252.8c-0.2-4.9-0.7-9.8-1.5-14.8 M783.3,323.9V320 M770.2,328.1l-1,6.2h10.8c2.4-0.4,3.5-1.5,3.4-3.2
	v-7.3 M775,323.6l-2.3,1.1l-9.8,1.2 M783.3,320l-8.4,3.6 M783.3,323.9l-7.7,3.4 M775.7,327.3l-5.5,0.9 M775,323.6l0.7,3.6
	 M714.9,332l19.4,0.6c4.6-0.1,9-1.6,13.2-4.6 M758.7,326.4v0.1 M756.1,323.6l-2.3,3.6 M754.3,323.8l1.8-0.1 M753.8,327.3l4.9-0.7
	 M760.5,286.7c0.7,8.3-0.6,19.9-4,35.1l-0.4,1.8 M758.7,326.6l-0.1,3.3 M762.8,325.9l-4.1,0.5 M758.7,326.6l4.1-0.6 M753.8,327.3
	l-6.2,0.7 M730.2,349h18.6c5.7,0.4,8.7-2.4,9.1-8.5l0.6-10.6 M758.5,329.8l11.7-1.7 M518.5,222.7h258.2 M747.6,328l-32.7,4
	 M56.9,248.8c0-0.1-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6v5.7 M68.6,236.3l-10.1,6.1
	l-1.7,4v2.4 M55.2,254.6c-1,0.1-1.6,0.9-1.8,2.1l-1.7,18.2c-0.1,1.5,1.1,2.1,3.5,2.1 M54.9,279.5c-2.1-0.2-3,0.6-3,2.5l-0.7,8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M55.2,276.9v0.7l-0.2,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M56.9,248.8v28.8c-0.7,5.6-2.2,10.2-4.4,14.1l-0.2,0.2l-2.5,1.7l-0.7,0.1
	l-0.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M51.1,290.6l-2.4,1.7c-0.2,0.1-0.4,0.4-0.4,0.5c0,0.2,0,0.5,0.1,0.7
	l0.2,0.2 M51.1,290.6c1.7-3.2,3-6.8,3.8-11.2 M55.2,276.9v-22.2 M231.6,222.9c-6.3-2.8-18-3.9-35.2-3.2
	c-53.2,2.4-95.6,7.9-127.4,16.4l-0.2,0.1 M88.1,245.9l-0.6,1.5 M77.6,273.3l2.7-0.5l2.9-0.6c3.6-0.9,7-1.9,10.3-3.2
	c0.7-0.4,1.6-0.6,2.3-1 M69.4,250.5v-0.1 M69.4,251.7v-1.2 M87.5,247.3c-5.6,0.7-10.9,1.8-16,3.5c-0.7,0.2-1.5,0.5-2.1,0.9
	 M68.4,269c-1.5-0.1-2.1-0.7-1.9-2.1v-10.3c0-1.1,0.2-1.9,0.7-2.7l0.4-0.5c0.5-0.7,1.1-1.2,1.9-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M68.2,269.8H66c-1.5-0.4-2.1-1.6-2.1-3.8v-10.3c0.5-2.6,2.3-4.3,5.3-5.2
	 M77.6,273.3l-3.5-4.4h-5.7 M70.7,276.1l2.9-0.4l4-2.4 M59.8,282l0.2-0.9c1.5-1.9,3.5-3.4,6.2-4.4l4.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M59.5,329.2l10-4.6c4.6-2.4,6.8-5.5,6.7-9.4v-30.1c-0.5-3.2-1.8-4.9-4-5.3
	h-6.7c-2.5,0.2-4.5,0.9-5.7,1.9 M65.1,291.7H63"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M57.3,289.7h11.1c2.3-0.1,3.4,0.4,3.5,1.5 M71.9,291.1l-6.8,0.6
	 M97.9,269.8l-5.7,2.7c-7.5,4-10.9,10.3-10.4,18.7l1,15.5c0.6,5.5,3,9.4,7.5,11.9l14.7,6.9 M68.4,269l2.3,7.2 M96,268.1l13.6-7.3
	c0.1-0.1,0.1-0.1,0.2-0.1l2.7-1.8l1.5-1.2c0.7-0.7,1.2-1.6,1.5-2.6c0.1-0.5,0.2-1,0.1-1.5v-0.1l-0.1-1.6c-0.2-0.9-0.7-1.6-1.3-2.2
	c-2.6-2.4-8-3.5-16.6-3.2l-10,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M117.8,249.8c0.8,1.7,0.8,3.5,0.1,5.3c-0.2,0.7-0.6,1.3-1,1.8l-1.3,1.6
	l-3.6,3l-2.9,2.1c-2.1,1.5-4.9,3-8.3,4.7l-2.9,1.5 M110.7,280.9l-12.8-11.1 M255.2,343.2l-4.5-15.9c-2.1-7.8-4.1-14.9-6.3-21.5
	c-3.2-9.4-6.3-17.4-9.7-23.9l-4.5-8.3c-0.9-1.5-1.9-2.8-3.2-4c-5.3-5.5-14-8.6-25.8-9.2c-19.2-0.5-37.9,0.4-56.1,2.5
	c-13.1,1.7-22.5,5.2-27.8,10.5c-2.3,2.3-4.5,4.9-6.6,7.7 M69.4,250.4c4.1-2.1,10.3-3.6,18.7-4.5 M97.9,269.8l-1.9-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M88.1,245.9l9.4-0.7c11.2-0.9,18,0.7,20.4,4.7 M128.4,231.7
	c-21.6,5-35.1,9.7-40.3,14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M69.4,250.4c5.3-5.1,13.1-9.1,23.3-12c11.2-2.4,23.1-4.7,35.8-6.7
	 M68.6,236.3l0.7,14.1 M63,291.7c-3.8-0.1-5.7-0.9-5.7-1.9 M59.8,282l-2.4,7.8 M105,325.6l-1.5,4.6 M103.6,331.3l10.8-0.2
	 M103.5,330.2l0.1,1.1 M105.6,323.9l-7.4-2.9c-1.7-0.6-2.4-1.6-2.6-2.9 M105.6,323.9l-0.6,1.7 M236.3,330.7
	c-2.6-9.2-5.2-17.5-8-24.8c-1.3-3.4-2.7-6.6-4-9.5c-5.7-13-17.1-19.4-34.3-19.4h-0.9c-16-0.4-32.7,0.7-50.1,3.5
	c-9.7,2.1-17.2,7.9-22.6,17.7c-3.5,6.6-7.2,15.2-10.8,25.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M224.4,330.8L223,328c-2.8-5.6-6.7-10.8-11.4-15.5
	C200,300.8,186,295,169.5,295s-30.5,5.8-42.2,17.5c-5.7,5.7-10,11.9-12.9,18.7 M62.1,303.5l0.2-6v-0.1c0.6-2.2,1.5-4.1,2.7-5.7
	 M65.6,311.4c2.3-0.2,3.8-2.1,4.4-5.3v-11.2l-4.9,0.2c-0.4,0.7-0.7,1.6-1,2.7l-0.4,9L65.6,311.4L65.6,311.4z M65.4,313.6l-5.6,3
	 M65.4,313.6l-0.7,0.4l-0.7-1.9 M62.1,303.5l-0.1,3.3v0.2l1.9,5 M65.6,311.4l-1.7,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M71.9,291.1v15.7c0.1,2.3-1,4-3.2,5.2l-3.5,1.6 M77.4,338.7l-17.9-9.5
	 M48.2,313.9l7.2,16l15.1,8.7h6.9 M80.9,338.7h-3.5 M77.9,338.9l-0.5-0.2 M110.7,280.9c-6.8,9.4-11.8,21.3-14.7,35.6
	c-0.2,0.6-0.2,1.1-0.2,1.6 M79.1,343.6l7.3,17.4c-1.1,2.6,0.4,5.3,4.6,8.4l10-9.7c2.4-1.6,3.5-5.2,3.4-11.2l-0.7-17.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M44.1,315.9c-1.1,0.6-1.5,1.6-1,2.9l1.7,9c0.2,2.1,1.7,3.6,4.3,4.9
	L65.2,343c2.3,1,6,1.2,10.9,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M77.5,341.1l-8.6,0.1l-14.1-8.1c-3.2-1.6-5-3-5.3-4.4l-5.3-13 M76.1,343.8
	l1.5-2.7 M76.1,343.8l3-0.2 M137.9,337c-3,5.2-4.5,11.2-4.5,17.6c0,1.1,0,2.2,0.1,3.3 M103.5,330.2l-22.6,8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M114.4,331c-3,7.2-4.6,15.1-4.6,23.4c0,16.5,5.8,30.5,17.5,42.2
	s25.8,17.5,42.2,17.5c16.5,0,30.5-5.8,42.2-17.5c11.1-11.1,16.9-24.3,17.5-39.7 M95.7,318c2.8-1.7,5.3-5.5,7.5-11.5
	c7.2-17.5,15.4-28.2,24.5-32.2c8.9-3.4,24.4-5.6,46.4-6.7c20.2-0.7,33.4,0.4,39.8,3.2c6.9,3.3,13.1,10.6,18.5,21.9
	c1.8,4.1,3.6,8.5,5.3,13.2c2.8,7.7,5.3,16,7.8,25.1c1.1,4,2.1,8.1,3,12.4 M77.4,338.7l0.1,2.4 M79.1,343.6c1.6-2.6,2.2-4.1,1.8-4.9
	 M48.2,313.9c-1.2,0.5-2.3,1.1-3.4,1.7l-0.7,0.2 M57.1,311.1c-3.3,0.7-6.3,1.6-8.9,2.7 M48.7,293.8l1.5,3.6l6.9,6.4 M63.9,312
	l-6.8-0.9 M57.1,303.8l5-0.4 M59.8,316.6l-2.7-5.5 M59.8,316.6l-3.9,2.7l3.6,10 M63,291.7l-6,12.1 M424.3,158.3h-0.6
	c-18.6,0.1-37.4,1.2-56.2,3.2c-9.1,1.1-18.5,2.6-28.3,4.6c-8.5,2.2-16,5-22.5,8.4l-0.9,0.5c-5.5,3.2-10.8,6.6-15.8,10
	c-11.8,8.3-22.1,17.4-31,27.5c-3,3.4-5.8,6.9-8.6,10.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M287.9,202c6.6-6.3,13.8-12,21.9-17.1l5.8-3.6l1.2-0.6
	c6.6-3.8,14.1-6.9,22.5-9.5c13.9-3.5,28.4-5.8,43.6-6.8l24.1-1c2.2,0.1,3.6,0.6,4.5,1.6c0.2,0.4,0.5,0.7,0.6,1.2
	c1,2.4,1,6.3,0.1,11.7l-5.7,40v0.1c-0.9,3.5-1.9,6.1-3.5,7.7c-1.2,1.2-2.5,1.8-4,1.8l-117.6,3.3h-0.2c-5-1.2-8.4-2.6-10-4
	c-1-1-1.5-1.8-1.3-2.9c0-1,0.6-1.9,1.6-2.9c1.1-1.6,2.3-3.2,3.4-4.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M289.7,228.8l109-3c2.8-0.2,4.9-3,6-8.1v0.1l5.7-40
	c0.9-5.3,0.7-9.1-0.2-11.1c-0.7-1-1.7-1.5-3-1.5l-24.2,1c-15.3,1.1-29.9,3.4-43.9,6.9c-8.5,2.6-15.9,5.8-22.5,9.6l-0.4,0.2
	c-1.2,0.6-2.3,1.3-3.5,2.1c-6.3,3.9-12.3,8.3-17.6,12.9 M319.3,166.8c-0.9,0.5-1.7,0.9-2.5,1.3c-9.1,5-18,10.6-26.7,16.8
	c-11.3,8-22.1,17-32.8,26.8c-3.8,3.5-7.7,7.3-11.4,11.2 M320.7,168.5l-1.5-1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M251.8,222.9c3.4-3.6,6.8-7.2,10.3-10.6c10.7-10.4,21.4-19.7,32.1-27.5
	c7.4-5.3,14.9-10.2,22.5-14.2l4-2.2 M291.7,200.9h-0.2c-1.2,0.4-2.4,0.7-3.6,1.1 M294.5,200.5c2.1,0,3.6,0.7,4.9,2.2
	c1.6,2.7,2.8,6.8,3.5,12.3v1 M294.4,200.7l0.1-0.2 M294.5,200.5h-0.9 M295.2,197.8l-0.7,2.7 M293.6,200.5l0.7,0.2 M295.2,197.8
	l-3.5,3.2 M293.6,200.5l-1.9,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.9,216l-1.6-7.8c-1.2-4.1-2.9-6.6-5-7.5 M291.4,226.1
	c2.1,0.1,4.1-0.1,6.2-0.6l1-0.4c2.9-1.7,4.4-4.7,4.3-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.2,216c0.4-1.9,0-3-1.1-3.2c-4.7-0.5-9.4,0.2-13.9,2.2
	c-2.5,1.2-3,2.3-1.6,3.3 M300.9,216h-0.7 M300.2,216c-6,0.2-11.4,1.1-16.5,2.3 M285.2,228.9l4.5-0.1 M284.3,229l0.6,0.1l0.4-0.2
	 M289.7,226.9v1.8 M285.2,228.9l4.5-1.9 M284.3,229l1-0.1 M289.7,226.9l1.7-0.9 M303,216h-2.1 M291.4,226.1
	c-2.1-0.1-4.3-0.6-6.3-1.5 M401.9,245.4c-1.8,1.5-3.5,2.3-5.3,2.4h-3.9c-8.5,0-15.2,0.4-19.9,1.3 M401.8,245.4
	c-0.7-1.6-2.7-2.6-5.8-2.7h-5.5c-3,0.1-5.3,1-7.2,2.6 M402,245.2l-0.2,0.2 M404.9,245.2c-1-0.1-1.8-0.1-2.8,0 M401.9,245.4h-0.1
	 M402,245.2l-0.1,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M383.3,245.3c0.6,1.1,1.8,1.5,4,1.3h10.4c1.5-0.1,2.8-0.5,4-1.2
	 M406.7,247c-0.4,0.4-0.5,0.7-0.5,1.2s0.2,1,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2
	s-0.1-0.9-0.5-1.2c-0.4-0.4-0.7-0.5-1.2-0.5S407.1,246.6,406.7,247L406.7,247z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M404.9,253.5c2.1-0.2,3.9-0.7,5.6-1.3c1.1-0.4,1.6-1,1.7-1.9v-1.5
	c-0.1-1.1-0.6-1.9-1.7-2.6c-1.7-0.6-3.6-1-5.7-1.1 M383.9,254.1c1.7,2.1,4.3,3,7.7,2.8h3.5c4-0.2,6.6-1.3,7.4-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M402.5,253.8c-0.7-1.2-2.6-1.8-5.3-1.9h-8.6c-2.2-0.1-3.6,0.7-4.6,2.2
	 M373.2,251.6l0.6,0.5c2.3,1.2,5.7,1.9,10.1,1.9 M372.6,249.2c-0.2,1-0.1,1.8,0.6,2.6 M383.3,245.3c-6.6,0.7-10.1,1.9-10.6,3.6
	l-0.1,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M373.2,251.6c5.3-0.9,12.3-1.2,20.8-1.3h3.3c2.1,0.1,3.9,1.3,5.2,3.5
	 M402.5,253.8l2.4-0.2 M414.9,290.8c-0.6-17.4,0.1-37.3,2.3-59.8 M404.9,253.5v-8.4 M414.9,290.8l-116.4,0.7
	c-12.4-0.5-21,3.2-25.8,10.8L256,329 M417.2,231.1l7.2-72.8 M231.6,222.9l-10-0.4c-10.1,0-20,0.4-29.9,1.2l-14.6,1.5l-19.8,2.3
	c-10,1.2-19.6,2.7-28.8,4.1 M287.9,202c-3.9,1.3-7.4,3-10.6,5c-2.3,1.3-3.3,3.5-3,6.8c0.1,0.9,0.4,1.7,0.6,2.4 M275.8,218.1l-2.9,4
	c0,0-0.1,0-0.1,0.1c-0.7,0.6-1.1,1.2-1.1,1.8c0,0.5,0.2,1,0.7,1.6c1.5,1.2,4.2,2.4,8.5,3.4l0.5,0.1l2.9-0.1 M274.9,216.4
	c0.2,0.6,0.5,1.1,0.9,1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,218.1c1,1.5,2.3,2.8,4.1,3.8c1.7,1.2,3.5,2.1,5.2,2.8 M260.6,222.9
	h-8.7 M248.4,235.8l8.3-0.2 M260.6,222.9h8.7 M256.7,235.6c1.1-5,2.4-9.2,3.9-12.6 M245.9,222.9h-14.3 M256,329
	c-0.5-1-0.7-2.1-1-3.2c-0.1-0.5-0.1-1-0.2-1.6c-2.4-18.3-2.9-37.9-1.6-58.4c0.5-12.1,1.6-22.2,3.4-30.2 M117.8,249.8
	c28.6-3.5,59.2-5.2,91.8-5.1c16.5,0.4,27,3.5,31.5,9.5 M251.8,222.9h-6 M256,329c0.2,0.6,0.7,1.2,1.1,1.8 M420.7,334.7l-149.4,2.1
	c-6.9-0.5-11.7-2.5-14.2-6 M228.3,344.4c-0.7-4.7-2.1-9.1-3.9-13.4l-0.1-0.2 M239.6,343.7c-1.1-4.4-2.1-8.6-3.2-12.8l-0.1-0.4
	 M236.3,330.7l-11.9,0.1 M229.1,357l9.4-3.6 M239.6,343.7l-11.3,0.7 M229.1,352.7l9.4,0.7 M229.1,352.7c-0.1-1.7-0.2-3.5-0.4-5.1
	 M228.8,347.5l-0.5-3 M229.1,357v-4.4 M255.2,343.2l-6.3,0.2 M247.8,351.3v2.7 M247.8,346.6v4.7 M266.2,345.7l-18.3,0.9
	 M238.5,353.4l9.4,0.6 M248.9,343.4l-9.4,0.2 M228.8,347.5l19.1-0.9 M185.1,321.9c-1.3-0.7-2.8-1.2-4.3-1.7 M182.8,332.6l2.1-5.7
	v-0.5 M184.9,326.4l0.2-4.5 M185.1,321.9c-1.3-0.5-2.6,0.4-3.6,2.7c-0.4,0.7-0.7,1.6-1,2.6l-2.9,13 M173.8,322.8
	c1.8,0.2,3.5,0.6,5.2,1.1 M173.6,318.8c0.2,1.1,0.4,2.4,0.2,4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M179.1,323.9c-0.5,1.6-1,3.5-1.2,6v0.4 M177.7,330.2
	c-1.7-0.6-3.5-1-5.3-1.1 M190,339.1c-0.7-1-1.5-1.8-2.3-2.7c-1.5-1.5-3-2.8-4.7-3.8 M182.8,332.6l-2.2,6 M180.9,320.4
	c-2.3-0.7-4.7-1.3-7.3-1.6 M173.8,322.8c-0.2,1.7-0.7,3.9-1.5,6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M172.8,317.2l-3.3-0.1c-9.1,0-17.1,2.9-23.8,8.6c-1,0.7-1.8,1.6-2.7,2.4
	c-7.3,7.4-10.9,16.3-10.9,26.6s3.6,19.2,10.9,26.5s16.2,11.1,26.6,11.1s19.3-3.6,26.6-11.1c1.8-1.8,3.4-3.8,4.9-5.7
	c4.1-6.1,6.2-13,6.2-20.8c0-2.5-0.2-4.9-0.6-7.2c-0.6-3.2-1.6-6.2-2.9-9.1c-1.8-3.6-4.3-7.2-7.4-10.3c-4.1-4.1-8.7-7-13.9-8.9
	C179.2,318.1,176,317.4,172.8,317.2L172.8,317.2z M179.1,323.9c0.5-1.6,1.1-2.8,1.8-3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M200.3,335.9c-1.5-2.4-3.3-4.6-5.5-6.8c-3-3-6.3-5.3-9.8-7 M196.1,336.5
	l-0.7,0.1l-5.3,2.4 M184.9,326.4c2.6,1.5,5,3.3,7.2,5.5c1.5,1.5,2.8,3,3.9,4.6 M182.6,345.3l12.8-4.1l2.7-1.5
	c2.1-1.3,2.9-2.5,2.4-3.8 M200.3,335.9l-4.3,0.6 M149.4,324.5c-1.2,0.9-2.4,1.8-3.5,2.8 M140.1,333.6c-0.9,1.1-1.6,2.2-2.2,3.4
	 M137.9,337c0.9-0.9,2.3-0.7,4.4,0.4c0.9,0.4,1.8,1,2.8,1.7l10.1,8.8 M149.4,324.5c-1,1-0.9,2.5,0.5,4.5c0.5,0.7,1.1,1.6,1.8,2.4
	l9.7,9.1 M159.1,349.4l-10.3-9.8l-0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M152.7,335.2l-1.5,1.3c-1,1-1.8,1.9-2.6,3 M148.7,339.4
	c-1.8-1.8-3.5-3.2-4.9-4.2 M147.8,330.7l-1.2,1.1c-1.1,1.1-2.1,2.2-2.9,3.4 M143.7,335.2c-1.3-1-2.6-1.5-3.6-1.7 M147.8,330.7
	c-1.1-1.2-1.7-2.4-2.1-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M145.8,327.4l-1.8,1.7c-1.5,1.5-2.7,2.9-3.9,4.5 M167.6,322.5l-0.4,0.9
	l-0.5,5.7 M168.3,338.7l2.8-13c0.1-1.2,0.2-2.3,0.1-3.2c-0.1-2.4-0.9-3.8-2.2-4 M169.1,318.5l-1.5,4 M157.3,331.9l-3.8-4.5l-0.7-0.5
	 M161.8,339.2l-0.2-2.2 M161.6,337l-4.3-5.1 M152.7,335.2c-2.1-1.6-3.6-3-4.7-4.4 M168.3,338.7l-1.1-1 M166.8,329.1l-0.7,6.8
	 M166.1,335.9l1.1,1.8 M167.2,337.7l1.9-19.2 M167.6,322.5c-5.5,0.2-10.3,1.7-14.7,4.4 M166.8,329.1c-3.4,0.2-6.6,1.2-9.5,2.8
	 M166.1,335.9l-4.5,1.1 M169.1,318.5c-7.4,0.1-14,2.1-19.7,6 M152.8,326.9l-3.4-2.4 M149.4,324.5l12.4,14.7 M169.2,344.9h0.2
	c1.6,0,3,0.4,4.4,1.1 M168.7,342.8l-0.4-0.5c-0.6-0.5-1.2-0.9-2.1-0.9c-0.7,0-1.5,0.2-2.1,0.9c-0.5,0.5-0.7,1.2-0.9,1.9
	 M169.2,344.9v-0.5c0-0.6-0.1-1.1-0.5-1.6 M163.5,344.3v0.1c0,0.7,0.2,1.5,0.7,1.9 M164.4,346.4c-0.6,0.4-1.2,0.9-1.7,1.3
	c-0.7,0.7-1.2,1.3-1.6,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M164.4,346.4c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.2,2.1-0.9
	c0.5-0.4,0.7-1,0.9-1.5 M165.6,350.7c-1.1,1.1-1.6,2.3-1.6,3.9c0,1.6,0.5,2.8,1.6,3.8c1.1,1.1,2.3,1.6,3.9,1.6
	c1.5,0,2.8-0.5,3.8-1.6l0.7-0.9c0.6-0.9,0.9-1.8,0.9-3l-0.1-1.1c-0.1-0.5-0.2-0.9-0.5-1.2c-0.2-0.6-0.6-1.1-1.1-1.5
	c-0.6-0.6-1.2-1.1-1.9-1.3l-1.3-0.2h-0.5c-1.3,0-2.4,0.4-3.5,1.2L165.6,350.7L165.6,350.7z M161,349.9c-0.5-0.4-1-0.6-1.7-0.6H159
	 M155.3,347.8l-1.5,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M159.8,355c0.6-0.1,1.1-0.4,1.6-0.9c0.6-0.5,0.9-1.2,0.9-2.1
	c0-0.7-0.2-1.5-0.9-2.1l-0.4-0.2 M159.1,349.4c-0.7,0-1.2,0.2-1.8,0.7c-0.6,0.6-0.9,1.2-0.9,2.1c0,0.9,0.2,1.5,0.9,2.1
	c0.2,0.2,0.5,0.5,0.9,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M158.1,354.7l1.2,0.2h0.5 M154.2,354.7l-1.2-1.1 M160.1,360.7l-0.1,0.1
	l-0.2,0.5c-0.2,0.4-0.2,0.7-0.2,1.2c0,0.7,0.2,1.5,0.9,1.9c0.6,0.6,1.2,0.9,2.1,0.9c0.7,0,1.3-0.2,1.9-0.7 M161.2,359.8
	c-0.4,0.1-0.7,0.4-1.1,0.6l-0.1,0.2 M161.2,359.8c-0.9-1.5-1.3-3-1.5-4.7 M165,363.1l0.1-0.7c0-0.7-0.2-1.5-0.9-2.1
	s-1.2-0.9-1.9-0.9l-1,0.2 M155.3,347.8l-1.1,6.9 M151.6,348.3l-5.8-3.9 M145.8,344.4l-4.5-3l-0.7-1 M143.7,354.4l-5,1l-1.5,0.7
	 M138.1,362.1l-4-0.1 M133.5,357.9c0.6,1.1,2.1,1.6,4.1,1.6c1.1,0,2.3-0.2,3.8-0.5l12.6-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M140.6,340.4c-2.2,4.3-3.2,9-3.2,14.2v1.5 M151,352.9l-7.3,1.5 M151,352.9
	l0.6-4.6 M144.3,360.2c-2.4,1-4.5,1.6-6.2,1.9 M145.8,344.4c-1.3,3-2.1,6.4-2.1,10 M148.8,370l-4.4,2.2l-1.7,0.2 M156.6,377
	c-1.9-1.1-3.6-2.5-5.3-4.3c-0.9-0.9-1.7-1.8-2.4-2.8 M156.8,363.2l-12.6,4.5c-1.3,0.6-2.4,1.3-3.3,1.9c-1.7,1.2-2.6,2.4-2.4,3.6
	 M136.7,369.8c0.6,1.2,1.2,2.3,1.9,3.5 M136.7,369.8c0.6-1,1.6-1.8,3.2-2.5 M142.7,372.5l-4.3,0.9 M139.8,367.2
	c1.6-0.9,3.5-1.6,6.1-2.3 M154.9,383.4c-3-1.5-5.7-3.5-8.3-6.1c-1.6-1.5-2.9-3-4-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M138.5,373.3c1.5,2.4,3.3,4.6,5.3,6.7c3.3,3.3,6.8,5.8,10.7,7.5
	 M170.2,364.2v0.6c0,0.4,0.1,0.7,0.2,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M170.3,365.8c0.1,0.4,0.4,0.6,0.6,1c0.6,0.6,1.2,0.9,2.1,0.9
	c0.7,0,1.5-0.2,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9v-0.2 M170.2,364.2h-0.7c-1.6,0-3-0.4-4.4-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M174.8,362.6c-0.5-0.5-1.2-0.7-1.9-0.7c-0.7,0-1.5,0.2-2.1,0.9
	c-0.4,0.5-0.7,1-0.7,1.5 M164.1,364.5c0.5-0.5,0.7-0.9,0.9-1.3 M156.6,364.8l-1,1.8 M155.6,366.6l3.3,3.2 M160.6,368.6l-1.7,1.2
	 M156.6,364.8l0.2-1.6 M154.8,387.7c1.2-0.1,2.3-1.1,3.3-2.9c0.5-1,1-2.2,1.5-3.6l2.6-13.1 M156.6,377l-1.6,4.7l-0.1,1.7
	 M166.9,380.2c-1.7-0.1-3.4-0.5-4.9-0.9 M158.9,369.8l-2.3,7.2 M162.1,368.1l-5.2-4.9 M162.1,368.1l-1.5,0.5 M170.9,370l-2.4,13.1
	c-0.1,1.3-0.1,2.6-0.1,3.5c0.2,2.2,0.9,3.5,2.1,3.9 M166.9,380.2l3.4-14.5 M164.1,364.5l-2.2,14.8 M134.1,362
	c0.5,2.8,1.3,5.3,2.6,7.9 M153.8,348.3h-2.2 M152.9,353.6l-1.9-0.7 M155.6,366.6l-6.8,3.4 M138.5,373.3l18.1-8.5 M145.8,364.9
	c-0.7-1.6-1.2-3.2-1.6-4.7 M138.1,362.1c0.4,1.8,1,3.5,1.7,5.1 M158.1,354.7l-13.7,5.5 M145.8,364.9l14.3-4.3 M205.4,351.2
	c-0.4-1.3-1.7-1.9-4.2-1.7l-2.9,0.4l-12.8,3.9 M204.8,346.7c-0.5-2.4-1.3-4.7-2.3-6.9 M205.4,351.2c-0.1-1.5-0.4-3-0.6-4.5
	 M200.7,347l4.1-0.2 M194.6,348.9c-0.4-1.7-1-3.4-1.6-5 M192.9,343.9c2.6-0.6,4.5-1.2,6.2-1.9 M193.3,364.2c1.2-2.9,1.8-6.2,1.8-9.6
	v-0.1 M198.6,368.2c1.9-4.1,2.9-8.6,2.9-13.6v-1.5 M201.5,353.2l3.9-1.8 M199,375.4c4.4-6.1,6.6-13,6.6-20.8l-0.1-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.1,354.5l5.7-1l0.7-0.4 M194.6,348.9l6.1-1.9 M205.4,351.3v-0.1
	 M199.1,342c0.7,1.6,1.2,3.3,1.6,5 M202.5,339.8c-0.7,0.7-1.9,1.5-3.4,2.2 M183.9,341.9l-1.1,1.8 M182.6,345.3l0.2-1.6 M177.5,340.3
	l1.5-0.4 M179.2,347.8c0.2-0.4,0.2-0.7,0.2-1.2c0-0.7-0.2-1.5-0.9-2.1s-1.2-0.9-2.1-0.9c-0.5,0-1,0.1-1.3,0.4 M175.2,344
	c-0.2,0.1-0.5,0.2-0.6,0.5c-0.4,0.4-0.7,0.9-0.7,1.5 M173.8,346l-0.1,0.6c0,0.7,0.2,1.5,0.9,2.1s1.2,0.9,2.1,0.9l0.9-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M177.5,349.3c0.4-0.1,0.7-0.4,1.1-0.7l0.6-0.7 M181.3,353.8l-1.1-0.2
	c-0.4,0-0.7,0.1-1.1,0.2 M179.1,353.8c-0.4,0.1-0.6,0.4-1,0.6c-0.6,0.6-0.9,1.2-0.9,2.1c0,0.7,0.2,1.5,0.9,1.9l0.1,0.1 M178.3,358.5
	c-0.5,1.1-1.1,1.9-2.1,2.9l-1.5,1.2 M178.3,358.5c0.5,0.5,1.2,0.7,1.8,0.7 M188.5,355.7l-1.9-0.9 M186.6,354.9l-1.1-1.1
	 M180.2,359.2c0.7,0,1.5-0.2,2.1-0.9s0.9-1.2,0.9-1.9s-0.2-1.5-0.9-2.1c-0.2-0.2-0.6-0.5-1-0.6 M185.6,360.3h2.2 M185.5,353.8
	l-1.3,7 M187.8,360.3l0.7-4.6 M184.2,360.8l1.5-0.5 M177.5,340.3l5.1,5 M177.5,349.3c0.9,1.3,1.5,2.8,1.6,4.5 M190.6,369.3
	c-0.9,1.2-1.8,2.3-3,3.5l-0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M175.8,364.5l10.9,8.9l0.2,0.1 M177.6,371.6l-0.2-2.2 M177.6,371.6
	l-4.5,1.2 M173.1,372.8L172,371 M177.6,368l-0.2,1.5 M175.8,364.5c-0.1-0.7-0.4-1.2-0.9-1.7l-0.1-0.1 M172.6,380.1l-0.4,5.2
	l-0.5,1.3 M190.6,383.9c0.7-1.1,0.4-2.5-0.9-4.4c-0.5-0.9-1.2-1.7-2.2-2.7l-10-8.9 M187,381.6l-1.1-0.6l-3.5-4 M182.4,377l-4.7-5.3
	 M173.1,372.8l-0.5,7.3 M172.6,380.1c3.5-0.4,6.8-1.5,9.7-3.3 M198.6,368.2l-0.6-0.7l-4.7-3.3 M184.2,360.8l9.8,9
	c1,0.7,1.9,1.3,2.8,1.7c2.1,1.1,3.5,1.1,4.5,0.2 M195.3,373.6c-1.5-1-3-2.4-4.9-4.3 M191.7,377.7l0.5-0.5c1.2-1.2,2.3-2.4,3.3-3.8
	 M190.6,383.9l3.2-2.7 M190.5,383.9v0.1l0.1-0.1 M190.5,383.9h0.1 M193.8,381.2c0.1,0.5,0.2,1.1,0.1,1.6 M191.7,377.7
	c1.1,1.2,1.8,2.4,2.1,3.4 M193.8,381.2l1.1-1.1c1.6-1.5,2.9-3,4.1-4.7 M199,375.4c-1.1-0.2-2.3-0.9-3.6-1.8 M201.3,371.6l-2.7-3.4
	 M187,373.4c2.1,1.6,3.6,3,4.7,4.3 M187,381.6l3.5,2.3 M177.4,369.4l13.1,14.5 M181.3,353.8l13.4-4.9 M192.9,343.9l-13.7,3.9
	 M186.6,354.9l18.8-3.5 M195.1,354.5l-6.6,1.2 M187.8,360.3l5.5,3.9 M201.3,371.6l-15.7-11.3 M190.6,369.3l-10.4-10.1 M202.5,339.8
	c-0.6-1.3-1.3-2.7-2.1-4 M178.9,339.9l6.2-18 M180.6,338.6l-1.7,1.3 M163.5,344.3l-10.8-9.1 M172.4,329.1l-3.6,13.7 M182.8,343.7
	l17.5-7.8 M153.8,348.3L137.9,337 M140.6,340.4l-2.7-3.4 M161.5,340.6l6.8-1.9 M161.5,340.6l0.4-1.5 M177.6,368l-6.7,2.1
	 M180.6,338.6l3.3,3.3 M175.2,344l2.6-13.8 M172,371l-1.1-1 M190,339.1l-6.1,2.8 M173.6,318.8l-4.5-0.2 M166.2,390.6l3.2,0.1
	c7.9,0,14.9-2.2,21-6.7 M166.2,390.6c-0.4-1-0.6-2.3-0.5-4 M154.8,387.7c1.2,0.5,2.4,1,3.8,1.5 M154.6,387.7h0.1 M160.6,385.6
	c-0.6,1.6-1.3,2.8-2.1,3.5 M154.6,387.7h0.1 M158.4,389.1c2.4,0.7,5.1,1.2,7.8,1.5 M160.6,385.6c1.7,0.5,3.4,0.7,5.2,1 M170.4,390.6
	l1.3-3.9 M165.8,386.6c0.1-1.8,0.5-3.9,1.1-6.3 M161.9,379.3c-0.4,2.5-0.9,4.6-1.3,6.3 M171.8,386.7c5.6-0.4,10.6-2.1,15.1-5
	 M154.9,383.4l-0.2,4.3 M170.4,390.6L172,371 M154.6,387.7l6-19.1 M320.6,343.1l-5.2-1.8 M307.1,354.4c0.4,2.8,2.5,3.9,6.6,3.5h9.5
	c3.5,0.2,6.1-0.9,7.4-3.4 M283.9,349l0.2-4.1 M283.7,354.2l0.2-5.2 M283.7,354.2l23.4,0.1 M320.6,343.1l-36.4,1.8 M342.8,349.6v1
	 M342.6,349.6h0.2 M342.8,354.6v1h108.2 M342.8,349.6v-8.5 M342.8,354.6v-4 M342.8,350.6l-3.5-1l-18.7-6.4 M330.6,354.5l12.3,0.1
	 M315.4,341.3l23.9-0.7l111.3-0.7 M330.6,354.5l-23.4-0.1 M256.7,235.6l160.5-4.5 M420.7,334.7c-3.3-12.1-5.2-26.7-5.8-43.9
	 M315.4,341.3l-60.1,1.9 M247.8,354l35.8,0.2 M285.1,224.6l-8,2.9l7.2,1.5 M283.8,218.3c-1.9,0.5-3.8,1-5.6,1.6 M284.2,344.9
	l-18,0.9 M247.8,351.3c18.5-1,30.4,0,35.7,3 M266.2,345.7c7.9,0.4,13.9,1.5,17.9,3.3 M133.5,357.9c0.1,1.3,0.2,2.8,0.6,4
	 M137.3,356.1l-3.8,1.8 M133.5,357.9l19.4-4.3 M489.1,333.8l-68.4,0.9"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M110.7,280.9
		l-12.8-11.1c0,0,10.1-5,14.5-8.6c0,0,5.1-3.6,5.8-6.7c0,0,1.9-5.1-4.4-7.9c0,0-4.6-2.6-16.8-1.5l-8.9,0.7c0,0,3.9-3.6,16.2-7.7
		c0,0,12.8-4,21.9-6.1c0,0,4.3-1.1,27.6-4.3c0,0,29.5-3.8,47.7-4.9c0,0,9.4-0.5,21.3-0.6l8.5,0.4h29.5c0,0-2.2,4.7-3.9,12.6
		c0,0-2.3,8.7-3.3,29.2c0,0-0.7,12.5-0.9,19c0,0-0.2,13.8,1,29c0,0,0.6,7,1.3,12.4c0,0,0.2,3.6,2.4,6.2c0,0,3,8.5,12,7.7v4
		l-14.1,0.5l-6.2-21.9c0,0-6.6-25.3-16.2-42.9l-2.7-4.9c0,0-5.6-12.3-28.5-13.2c0,0-19.4-1.1-48,1.6c0,0-21.5,0.9-32.9,8.5
		C120.9,270.4,117.1,272.2,110.7,280.9z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M275.8,218.1l-2.9,4
		c0,0-1.5,1.1-1.2,2.2c0,0,0,1.5,3.8,2.9l6,1.8l3.6-0.1l6.3-2.8c0,0,3.6,0.2,6.2-0.6c0,0,5.7-1.3,5.2-10.6c0,0-0.9-7.9-3.5-12.3
		c0,0-1.3-2.6-5.8-2.2c0,0-8,1.5-15.2,6l-1.8,1.2c0,0-2.4,1.6-2.3,5.1C274.3,212.7,274.1,215.3,275.8,218.1z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M294.5,200.5
		l0.7-2.7c0,0,19.6-17.7,42.9-24.4c0,0,14.5-4.7,44.6-7.3l23.8-1c0,0,2.3-0.4,3.8,1.5c0,0,1.5,2.8,0.5,9.4l-6,41.2
		c0,0-1.2,7.9-6.1,8.6l-70,1.9l-39,1.1v-1.9l1.7-0.7c0,0,5.5,0.5,8.1-1.7c0,0,3.8-2.4,3.3-9.5c0,0-1-8.4-3.6-12.5
		C299.2,202.4,297.8,200.3,294.5,200.5z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M428.8,218.9
		c0,0-0.2-20.9,2.7-51.4c0,0,0.9-6.6,6.4-6.2c0,0,23-1.2,45.9,1.1c0,0,6.3,1,7.7,9.2c0,0,1.9,13.2,2.2,36.6c0,0,0.5,4.1-1.7,7.3
		c0,0-1.8,3.3-7.4,4.5c0,0-21.5,4.4-48,5.1c0,0-4.1,0.9-6.3-1.3C430.3,223.8,428.7,222.3,428.8,218.9z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M256.7,235.6l160.6-4.5
		c0,0-3.5,30.5-2.3,59.8c0,0,0,20.7,5.8,43.9l-149.4,2.1c0,0-9.6-0.2-14-5.6c0,0-1.9-2.1-2.4-6.2c0,0-1.8-11.9-2.4-30.7
		c0,0-0.5-10.9,1.2-36.6C253.8,257.7,254.9,242.1,256.7,235.6z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M567.6,340.2
		l1.2-10.2l-7-2.2c0,0-2.2-0.7-2.7-2.6c0,0-0.5-0.7-0.1-2.4c0,0,4-20.2,13-36.3c0,0,10.6-23.1,37.1-26.6c0,0,27.6-4.9,61.3-3.6
		c0,0,15.3-0.7,25.4,7.5c0,0,5.1,3,12.3,17.9c0,0,6.8,12.4,13.8,38.9c0,0,3.9-1.1,6.1-4.6l15.7-23.9c0,0,3.4-7.3,14.9-7.2
		s0.1,0,0.1,0s-2.8-22.6-8.7-38.4c0,0-2.3-4.9,3.3-7.2l23.9-13l-0.4-3.4H633.7H518.5c0,0,1.8,23.4,1.6,38.8c0,0,0,23.2-2.6,45.7
		c0,0-2.1,20.8-5.2,26.8c0,0-1,2.8,0.2,4.1c0,0,0.8,1.2,3.3,0.6L567.6,340.2z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="
		M417.2,231.1c0,0-3.5,36.4-2.3,59.8c0,0,0,19.6,5.8,43.9l70.5-1c0,0,14.3-0.7,17.6-9.2c0,0,3.4-14,4.6-34c0,0,1.6-11.5-0.4-69.2
		l-17.3-5.1c0,0-1.1,4.5-6.1,6.6c0,0-6.6,2.8-28.5,5.5C461.3,228.2,439.4,230.6,417.2,231.1z"
              id="Left Rear - Quarter Panel 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="639.5"
              cy="354.5"
              r="59.6"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <ellipse
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="169.5"
              cy="354.6"
              rx="59.9"
              ry="60"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup2DrLeft);
