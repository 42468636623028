import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadBuyerPurchased } from '../../actions';
import { CommafyCurrency } from '../../components/Commafy';
import PrintButton from '../../components/PrintButton';
import { getConfig, isDST } from '../../utils/helpers';
import _ from 'lodash';
import BackButton from './../BackButton';
import moment from 'moment-timezone';
import jstz from 'jstz';
const timezone = jstz.determine().name();

const loadData = props => {
  props.loadBuyerPurchased(getConfig('marketplaceId'));
};

export class PurchaseSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '7',
    };

    this.getRangeFilter = this.getRangeFilter.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    loadData(this.props);
  }

  handleClick(e) {
    e.preventDefault();
    let filter = e.currentTarget.getAttribute('data-id');
    this.setState({ filter: filter });
  }

  getRangeFilter(data, filter) {
    var startDate;
    if (filter === '7') {
      startDate = moment()
        .startOf('day')
        .subtract(1, 'week');
    } else if (filter === '14') {
      startDate = moment()
        .startOf('day')
        .subtract(2, 'week');
    } else if (filter === '30') {
      startDate = moment()
        .startOf('day')
        .subtract(1, 'month');
    }

    var yesterdayEndOfRange = moment()
      .endOf('day')
      .subtract(1, 'day');
    var filteredObjects = _.filter(data, function(each) {
      const adj = isDST() ? '07:00' : '08:00';

      if (
        moment
          .tz(`${each.dateCreated}-${adj}`, timezone)
          .isBetween(startDate, yesterdayEndOfRange)
      ) {
        return each;
      }
    });
    return filteredObjects;
  }

  render() {
    if (!this.props.buyerPurchased.data) {
      return null;
    }

    return (
      <div className="container-fluid" style={{ backgroundColor: '#fff' }}>
        <div className="row">
          <div className="col-md-12">
            <BackButton />
            <h3 className="pull-left">Purchase Summary</h3>
            <PrintButton />
            <div
              className="btn-group pull-right"
              style={{ marginRight: '10px' }}
              role="group"
              aria-label="..."
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={this.handleClick}
                data-id="7"
              >
                7 Days
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.handleClick}
                data-id="14"
              >
                14 Days
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.handleClick}
                data-id="30"
              >
                30 Days
              </button>
            </div>
            <table className="table table-striped">
              <tbody>
                <tr className="events-heading">
                  <td className="style3">Lot Number</td>
                  <td className="style3">Year</td>
                  <td className="style3">Make</td>
                  <td className="style3">Model</td>
                  <td className="style3">VIN</td>
                  <td className="style3">Mileage</td>
                  <td className="style3">Color</td>
                  <td className="style3">Seller Name</td>
                  <td className="style3">Amount</td>
                  <td className="style3">Fees</td>
                  <td className="style3">Total</td>
                </tr>
                {this.loadRows()}
              </tbody>
            </table>
            <hr />
            <p>
              * For information that is not available, contact your Marketplace
              Administrator.
            </p>
          </div>
        </div>
      </div>
    );
  }

  loadRows(items) {
    if (this.props.buyerPurchased.data) {
      const filteredData = this.getRangeFilter(
        this.props.buyerPurchased.data,
        this.state.filter
      );
      if (filteredData.length > 0) {
        const rows = filteredData.map((row, index) => {
          let feeAmount, total;
          if (row.buyFee !== '' || row.buyFee !== 0) {
            feeAmount = row.buyFee;
            total = feeAmount + row.amount;
          } else {
            feeAmount = '*Not Available';
            total = '*Not Available';
          }
          return (
            <tr className="events-heading">
              <td className="style3">{row['lotNumber']}</td>
              <td className="style3">{row['vehicleYear']}</td>
              <td className="style3">{row['make']}</td>
              <td className="style3">{row['model']}</td>
              <td className="style3">{row['vin']}</td>
              <td className="style3">{row['mileage']}</td>
              <td className="style3">{row['exteriorColor']}</td>
              <td className="style3">{row['sellerName']}</td>
              <td className="style3">{CommafyCurrency(row['amount'])}</td>
              <td className="style3">
                {feeAmount !== '*Not Available'
                  ? CommafyCurrency(feeAmount)
                  : feeAmount}
              </td>
              <td className="style3">
                {total !== '*Not Available' ? CommafyCurrency(total) : total}
              </td>
            </tr>
          );
        });
        return rows;
      } else {
        return (
          <p style={{ marginTop: '10px' }}>
            You have not purchased any vehicles in the last {this.state.filter}{' '}
            days.
          </p>
        );
      }
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  const {
    entities: { buyerPurchased },
  } = state;

  return { buyerPurchased };
};

export default connect(
  mapStateToProps,
  { loadBuyerPurchased }
)(PurchaseSummary);
