import { FETCH_API } from '../../middleware';

export const VIN_REQUEST = 'VIN_REQUEST';
export const VIN_SUCCESS = 'VIN_SUCCESS';
export const VIN_FAILURE = 'VIN_FAILURE';

const fetchVin = (vin, gatepass = false, pickup = false, apiKey) => ({
  [FETCH_API]: {
    types: [VIN_REQUEST, VIN_SUCCESS, VIN_FAILURE],
    endpoint:
      `decode-item` +
      `?searchType=VIN` +
      `&searchText=${vin}` +
      `&apiKey=${apiKey}` +
      `&accountNumber=` +
      `&includeOffsite=${gatepass}` +
      `&includePickup=${pickup}`,
    core: true,
  },
});

export const loadVin = (vin, gatepass = false, pickup = false, apiKey) => {
  return dispatch => dispatch(fetchVin(vin, gatepass, pickup, apiKey));
};

export const UPDATE_VIN = 'UPDATE_VIN';

export const updateVin = data => ({
  type: UPDATE_VIN,
  response: data,
});

export const RESET_VIN = 'RESET_VIN';

export const resetVin = () => ({
  type: RESET_VIN,
});
