import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, uniqBy } from 'lodash';
import { loadConditionDamageActions } from '../../../../../../actions';
import Icon from '../../../ui/icons';
import Loader from '../../../ui/loader';
import Layout from '../../../templates/forms/Layout';
import ConditionFooter from '../../../templates/forms/condition/splats/footer';
import { CYAN_01, GREY_07 } from '../../../../constants/colors';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import '../../../../styles/form/condition.css';
class Severity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: !!props.location.state.edit,
      area: '',
      part: '',
      damage: '',
      loaded: false,
      loadingDataSource: [{ severityDescription: 'Loading...' }],
      menuItems: ['Standard'],
      selectedMenuItem: 0,
      searchText: '',
      openErrorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems() {
    const area = this.props.location.state.condition.area;
    const part = this.props.location.state.condition.part;
    const damage = this.props.location.state.condition.damage;
    const crTypes = this.props.crTypes.values;

    let menuItems = [];
    if (crTypes) {
      menuItems =
        crTypes.length > 0
          ? crTypes.map(obj => {
              return obj.name;
            })
          : [];
    }

    this.setState(
      {
        loaded: false,
        area,
        part,
        damage,
        menuItems: [...menuItems, 'Standard'],
      },
      () => {
        this.props
          .loadConditionDamageActions(
            area,
            part,
            damage,
            this.state.menuItems[this.state.selectedMenuItem]
          )
          .then(({ response }) => {
            if (response.amStatus === 'Success') {
              this.setState({ loaded: true });
            } else if (response.amStatus === 'Error') {
              this.setState({
                message: `Unable to load data. Error: ${response.amMessage}`,
                openErrorModal: true,
              });
            }
          })
          .catch(error => console.error(error));
      }
    );
  }

  handleClick(item) {
    const { isEdit } = this.state;
    const edit = this.props.location.state.edit;
    this.props.navigate(
      isEdit ? '/core/conditionReview/info' : '/core/condition/info',
      {
        state: {
          condition: {
            area: this.props.location.state.condition.area,
            part: this.props.location.state.condition.part,
            damage: this.props.location.state.condition.damage,
            severity: item.severityDescription,
            selectedRow: this.props.location.state.condition.selectedRow,
          },
          edit,
          selectedCrType: this.props.location.state.selectedCrType,
          prevPage: 'severity',
        },
      }
    );
  }

  handleMenuItemsClick(index) {
    this.setState(
      {
        selectedMenuItem: index,
        loaded: false,
      },
      this.loadItems()
    );
  }

  filterList(searchText, list) {
    const text = searchText.toLowerCase();
    return filter(list, v => {
      const val = v.severityDescription.toLowerCase();
      return val.search(text) !== -1;
    });
  }

  render() {
    const {
      menuItems,
      selectedMenuItem,
      searchText,
      loaded,
      isEdit,
    } = this.state;
    const { area, part, selectedRow } = this.props.location.state.condition;
    const { selectedCrType } = this.props.location.state;

    let severity = null;
    if (severity == null || !this.state.loaded) {
      severity = this.state.loadingDataSource;
    }
    if (this.props.condition && this.props.condition.severity) {
      severity = uniqBy(
        this.props.condition.severity && this.props.condition.severity.values,
        e => e.severityDescription
      );
    }
    if (searchText !== '') {
      severity = this.filterList(searchText, severity);
    }

    const { edit, checked } = this.props.location.state;

    return (
      <Layout>
        <ConfirmationModal
          open={this.state.openErrorModal}
          close={() =>
            this.setState({
              openErrorModal: false,
              area: '',
              part: '',
              damage: '',
            })
          }
          action={() => this.loadItems()}
          title="Error"
          body={this.state.message}
          confirmBtnTxt="Try Again"
          iconName="CircledExclamationmark"
        />
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div>
              <div>
                <div className="form-header form-sub-section">
                  <div
                    onClick={() =>
                      this.props.navigate(
                        isEdit
                          ? '/core/conditionReview/damage'
                          : '/core/condition/damage',
                        {
                          state: {
                            condition: {
                              area: area,
                              part: part,
                              selectedRow: selectedRow,
                            },
                            edit,
                            selectedCrType: selectedCrType,
                          },
                        }
                      )
                    }
                  >
                    <Icon name="LeftArrow" size={20} color={CYAN_01} />
                    Damage
                  </div>
                </div>
                <div className="form-body-container form-sub-section">
                  <div className="form-body form-sub-section">
                    <div className="form-sub-section-title">
                      <div>Severity</div>
                    </div>
                    <div
                      className="search-container"
                      style={{ borderRadius: '10px' }}
                    >
                      <input
                        type="text"
                        value={searchText}
                        onChange={e =>
                          this.setState({ searchText: e.target.value })
                        }
                        className="search-box"
                        placeholder="Search"
                      />
                    </div>
                    {!loaded ? (
                      <Loader className="conditions-loader flex justify-center pt-4" />
                    ) : null}
                    {menuItems.length > 1 &&
                      loaded && (
                        <div>
                          <div className="mb-3">CR Types:</div>
                          <div className="splats">
                            {menuItems.map((item, index) => {
                              return (
                                <div
                                  key={'crType-' + index}
                                  className={`splat-type ${selectedMenuItem ===
                                    index && 'splat-type-selected'}`}
                                  onClick={() =>
                                    this.handleMenuItemsClick(index)
                                  }
                                >
                                  {/* Check this */}
                                  {item}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {loaded &&
                      severity.map((item, index) => {
                        return (
                          <div
                            key={'severity-' + index}
                            className="form-section-navigation-card"
                            onClick={() => this.handleClick(item)}
                          >
                            <span>{item.severityDescription}</span>
                            {edit &&
                            item.severityDescription === edit.severity &&
                            checked ? (
                              <Icon name="Checkmark" size={17} />
                            ) : (
                              <Icon
                                name="ChevronRight"
                                size={20}
                                color={GREY_07}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <ConditionFooter isSaveDisabled={true} isEdit={isEdit} />
          </div>
        </div>
      </Layout>
    );
  }
}

Severity.propTypes = {
  loadConditionDamageActions: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { condition, crTypes, item } = state.core;
  const { loadingBar } = state;
  return { condition, crTypes, loadingBar, item };
};

export default connect(
  mapStateToProps,
  {
    loadConditionDamageActions,
  }
)(Severity);
