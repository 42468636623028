import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M207.4,211.5c0,0,0.4-8.3,1.5-11.7c1.1-3.4,5-6.7,10.2-6.9c5.1-0.1,16.2,0.1,18.8,0.4
	c2.8,0.3,5.5,1.4,5.5,5.8c0,4.3-0.1,11.8-0.1,12.1c0,0,1.8,0.7,2.5,0.8c0.7,0.3,0.7,1.4,0.7,1.4l4.1,0.1c0,0,0.3-1.9,3-2.1
	c2.7-0.1,12.9-0.4,15.1-0.6c2.2-0.1,3.7-0.1,5-4.1c1.2-4.1,7.4-21.3,9.9-27.2c2.5-5.8,9.5-20.8,12.7-26.3s6.1-8.5,6.7-9.2
	s1.6-0.7,1.6-0.7s0.1-0.4,1-0.8c0.8-0.4,4.8-1.8,8.5-2.5c3.7-0.7,5.6-1.1,6.7-1.2c1.5-0.3,2.2,1.2,2.2,1.2l2.9-0.1
	c0,0,0.8-1.9,3.3-2.2c2.5-0.3,20.9-1.8,35.8-2.5c14.9-0.7,46.1-1.4,57.2-1.2c11.3,0,36,0.6,49.5,1.1s36,2.3,38.8,2.5
	c4.4,0.3,4.8,2.5,4.8,2.5h2.8c0,0,0.8-1.2,2.3-1.1c1.4,0.1,11.8,2.3,13.9,2.9c2.1,0.6,2.3,1.4,2.3,1.4s0.8,0,1.5,0.7
	c0.7,0.6,2.3,1.9,6.2,8c3.9,6.1,8,14.6,11.3,22.3s7.7,19.7,9.9,26s2.1,6.1,2.6,7.4c0.5,1.5,1.7,3.4,4.4,3.4c2.8,0,13.3,0.6,15.4,0.7
	c2.8,0.1,3,2.1,3,2.1l4.3-0.1c0,0,0-0.7,0.4-1c0.1-0.1,0.1-0.1,0.3-0.1c0.7-0.1,2.3-0.7,2.3-0.7s0.1-10,0.1-13.2
	c0-3,1.5-4.7,5.2-4.8c3.7-0.3,16.1-0.6,19.7-0.4s7.8,2.1,9.4,6.7s1.8,11.7,1.8,11.7s0.3,0.3,0.4,0.7c0,0.4,0.1,5.1,0.3,5.6
	c0,0.6-0.3,1-0.3,1s0.3,5,0.4,8.5c0.1,3.7,0.1,7.6,0.1,7.6s0.5,0.3,0.7,1.2v5.1c0,0.8-0.4,1.2-0.4,1.2s-0.4,1.9-1.8,2.3
	s-4.4,1.2-12.4,2.1s-14.7,1.1-17.6,1c-3.7-0.1-4.4-3.3-4.4-3.3s-0.7,0-1.4,0.1h-1c-0.5,0-1.7-0.8-1.7-0.8h-4.3v1.2
	c0,0.7-0.1,1.6-2.3,1.9c-2.2,0.3-7.6,1.1-7.6,1.1s0.3,0.7,0.3,1.8c0,1-0.4,1.8-0.4,1.9c0.1,0.1,1.9,2.2,2.3,4
	c0.4,1.8,1.4,4.3,1.7,6.3c0.1,1.4,0.3,2.6,0.3,3.3v0.4c0,0,4.5,4.8,5,14.2c0.5,9.4,0.4,22-0.3,30.8c-0.5,8.8-0.8,11-0.8,11h-0.3
	l-0.3,8.1c0,0,2.3,1.4,2.3,5.8c-0.1,4.4-0.7,10.6-1,12.9c-0.3,2.3-0.3,3.2-0.3,3.2s-0.8,13.1-6.5,27.2c-1.7,4-5.9,6.6-10.2,6.6
	c-2.6,12.7,1.7,28.8-17.3,31.1c-2.1,0.3-4.5,0.1-7.7,0.3l-8.5,0.1l0.1,0.5c0,0-17.9-0.3-42.1-0.1c-24.2,0.1-89.8,0.4-123.3,0.3
	c-33.4-0.1-72.6-0.5-72.6-0.5l-8.4-0.7c-15.4,0.4-23.7-0.8-25.2-31.1c-1.4-0.3-6.9-0.3-10.3-7.3c-4.7-13.3-6.2-26.1-6.2-26.1
	s-1.6-13.9-1.2-17.1c0.3-3.2,2.3-5.4,2.3-5.4l-0.3-7.8c0,0-0.4,0-0.4-0.7c-0.1-0.7-1.4-16.1-1.4-23.4c0-7.4,0-18.2,0.8-22.4
	c0.8-4.3,4.3-9.5,4.3-9.5s0.1-3.2,1.1-6.6c1-3.4,1.2-4.7,1.9-5.6c0.7-1,1.4-1.8,1.4-1.8s-0.8-0.8-0.5-2.1c0.3-1.2,0.4-1.8,0.4-1.8
	s-5-0.4-7.2-0.8s-2.3-1.1-2.6-1.9l-0.4-1.5h-3.9c0,0-0.8,1-2.3,1h-1.8c0,0-0.8,3.3-5.4,3.2c-4.4-0.1-22.4-1.4-27.1-2.2
	c-3.6-0.6-3.3-3-3.3-3s-0.7-0.1-0.7-1.4c0-1.1-0.1-4.5,0-5.2c0-0.3,0-0.4,0.1-0.6c0.3-0.8,0.6-0.8,0.6-0.8l0.6-16.4v-0.1
	c0,0-0.4,0-0.4-0.6s0.3-5,0.3-5.5C206.9,211.8,207.5,211.5,207.4,211.5L207.4,211.5z M243.2,221.3l-0.3,13.1h0.1
	c0.4,0.1,1.9,0.4,2.6,0.6c0.8,0.1,0.8,1.4,0.8,1.4l4.1,0.1c0.1-0.6,0.4-1,0.8-1.4c0.3-0.3,0.6-0.4,0.8-0.6c0.4-0.1,0.7-0.3,1.1-0.3
	l7-0.1h1.2c1.4,0,1.9-0.4,2.2-1.8l1.2-4.5v-0.1c0.3-1.1,0.1-1.9-0.5-2.3c-0.4-0.3-0.8-0.4-1.4-0.6l-10.6-1.4h-0.3
	c-1.1-0.1-1.4-0.6-1.5-1.5v-1.7h-4.3c0,0-0.3,0.4-1.4,0.7C243.9,221.6,243.2,221.3,243.2,221.3L243.2,221.3z M579,224.9
	c-2.1,0.3-2.3,2.2-1.9,3.2c0.3,1,0.5,2.6,1.2,4.5c0.7,1.9,2.1,1.8,2.1,1.8s6.1,0.1,8.5,0.4c2.5,0.3,2.6,1.9,2.6,1.9h4.3
	c0,0,0-0.7,0.4-1.1c0.1-0.1,0.3-0.1,0.4-0.3l2.5-0.7l-0.4-13.1c0,0-0.8,0.1-1.8,0s-1.2-0.8-1.2-0.8l-4.1,0.3c0,0-0.1,0.7-0.1,1.2
	v0.1c0,0.7-0.4,1.2-1.8,1.4C588.2,223.8,581.1,224.5,579,224.9L579,224.9z"
          />
          <path
            fill="#5E5E5E"
            d="M573.4,385.8c-2.5,12.5,1.5,28.3-17.3,30.7c-2.1,0.3-4.5,0.1-7.7,0.3l-8.5,0.1l0.1,0.5
	c0,0-17.9-0.3-42.1-0.1c-24.2,0.1-89.8,0.4-123.3,0.3c-33.4-0.1-72.6-0.5-72.6-0.5l-8.4-0.7c-15.4,0.4-23.7-0.8-25.2-31
	C370,386.4,471.7,386.4,573.4,385.8L573.4,385.8z"
          />
          <path
            fill="#FFFFFF"
            d="M207.4,211.5c0,0,0.4-8.3,1.5-11.7c1.1-3.4,5-6.7,10.2-6.9c5.1-0.1,16.2,0.1,18.8,0.4
	c2.8,0.3,5.5,1.4,5.5,5.8c0,4.3-0.1,11.8-0.1,12.1c0,0,1.8,0.7,2.5,0.8c0.7,0.3,0.7,1.4,0.7,1.4l4.1,0.1c0,0,0.3-1.9,3-2.1
	c2.7-0.1,12.9-0.4,15.1-0.6c2.2-0.1,3.7-0.1,5-4.1c0.1-0.4,0.4-1.1,0.5-1.8v2.2l-4.7,14.4l-1.6,5.6l-1.1,4.1l-1.5,5.9l-2.1,3.6
	l-1.1,3.2l-1.2,4l-0.3,3.4c0,0-0.8-0.8-0.5-2.1c0.3-1.2,0.4-1.8,0.4-1.8s-5-0.4-7.2-0.8c-2.2-0.4-2.3-1.1-2.6-1.9l-0.4-1.5h-3.9
	c0,0-0.8,1-2.3,1h-1.8c0,0-0.8,3.3-5.4,3.2c-4.4-0.1-22.4-1.4-27.1-2.2c-3.6-0.6-3.3-3-3.3-3s-0.7-0.1-0.7-1.4c0-1.1-0.1-4.5,0-5.2
	c0-0.3,0-0.4,0.1-0.6c0-0.1,0.3-0.1,0.3-0.1l0.6-16.4v-0.1c0,0-0.4,0-0.4-0.6s0.3-4.9,0.3-5.5C206.9,211.8,207.4,211.5,207.4,211.5
	L207.4,211.5z M243.2,221.3l-0.3,13.1h0.1c0.4,0.1,1.9,0.4,2.6,0.6c0.8,0.1,0.8,1.4,0.8,1.4l4.1,0.1c0.1-0.6,0.4-1,0.8-1.4
	c0.3-0.3,0.6-0.4,0.8-0.6c0.4-0.1,0.7-0.3,1.1-0.3l7-0.1h1.2c1.4,0,1.9-0.4,2.2-1.8l1.2-4.5v-0.1c0.3-1.1,0.1-1.9-0.5-2.3
	c-0.4-0.3-0.8-0.4-1.4-0.6l-10.6-1.4h-0.3c-1.1-0.1-1.4-0.6-1.5-1.5v-1.7h-4.3c0,0-0.3,0.4-1.4,0.7
	C243.9,221.6,243.2,221.3,243.2,221.3L243.2,221.3z"
          />
          <path
            fill="#EAEBEB"
            d="M318.6,218.1c0,0,46.6-3.6,103.3-3.6c59.4,0,102.8,3.4,102.8,3.4s15.1,0.7,22.7,1c10.3,0.3,9.8-4.1,9.2-8.4
	c-0.5-4.3-1.2-6.9-6.5-23c-5.2-16.1-8.8-20.9-11.6-26.7s-6.2-6.2-12-6.9c-5.8-0.8-10.9-1.2-33-2.6c-22-1.4-53.8-1.4-72.2-1.5
	c-18.6,0-35.8,0.4-61.6,1s-39.1,2.3-46.8,3.6c-7.7,1.2-9.5,6.7-15.7,20.6c-6.2,13.8-9.4,27.6-11.3,35.1c-1.9,7.4,3.4,9.4,6.2,9.5
	C298.7,219.8,318.6,218.1,318.6,218.1L318.6,218.1z M550.3,347.4c-1.2,1-4.1,4-5.1,5c-0.8,1-1.4,2.1-1.4,3.6
	c-0.1,1.4-0.3,7.6-0.3,11s3.7,3.3,3.7,3.3c1.9,0,15.8-0.1,19.1,0c3.3,0,4.7-2.3,5.2-5.8c0.5-3.3,1.4-13.6,1.4-16s-1.5-3-3-3
	c0,0-12.8,0.1-15,0.1C552.9,345.6,551.5,346.5,550.3,347.4L550.3,347.4z M287.2,345.6c-2.2-0.1-15-0.1-15-0.1c-1.4,0-3,0.7-3,3
	s0.8,12.5,1.4,16c0.5,3.3,1.9,5.8,5.2,5.8c3.3-0.1,17.2,0,19.1,0c0,0,3.7,0.1,3.7-3.3s-0.1-9.6-0.3-11c0-1.4-0.5-2.6-1.4-3.6
	s-3.9-4-5.1-5C290.8,346.5,289.3,345.6,287.2,345.6L287.2,345.6z M539.9,259.3c4.3,0,29.3,0.1,35.6,0.1c6.9,0,7.7,5.6,7.7,5.6
	c0.4,3.4,0.7,7.6,1.1,13.2c0.4,9.1,0,20.6-0.7,30.3s-0.7,9.5-1,10.9c-0.3,1.4-0.1,2.3-1.4,2.5c-1.1,0.1-33.8,0.3-41.8,0.3
	c0.1-0.1,0.3-0.7,0.4-1.6c0.1-1.2,0.1-5.4,0.5-16.6c0.4-11.1,0-32.2,0-39.8C540.3,262.2,540.1,260.5,539.9,259.3L539.9,259.3z
	 M302.5,259.3c-4.3,0-29.3,0.1-35.6,0.1c-6.9,0-7.7,5.6-7.7,5.6c-0.4,3.4-0.7,7.6-1.1,13.2c-0.4,9.1,0,20.6,0.7,30.3s0.7,9.5,1,10.9
	c0.3,1.4,0.1,2.3,1.4,2.5c1.1,0.1,33.8,0.3,41.8,0.3c-0.1-0.1-0.3-0.7-0.4-1.6c-0.1-1.2-0.1-5.4-0.5-16.6c-0.4-11.1,0-32.2,0-39.8
	C302.1,262.2,302.2,260.5,302.5,259.3L302.5,259.3z"
          />
          <path
            fill="#5E5E5E"
            d="M421.2,216.2c0.1,0-32.2,0.4-49.5,1s-30.4,1.2-31.9,1.4c-1,0-1.5,0.3-1.4,0.7c0.8,0.8,2.6,2.2,4.3,3.3
	c2.2,1.4,4,0.8,11.3,0.4c3.7-0.1,13.8-0.6,22.7-0.8v-0.4c0-0.3,0.3-0.6,0.5-0.6h1c0.3,0,0.5,0.3,0.5,0.6v0.4
	c6.7-0.1,13.3-0.4,18.3-0.4c12.4-0.3,24.5-0.4,24.3-0.4s12,0.1,24.4,0.4c5,0.1,11.7,0.3,18.3,0.4v-0.4c0-0.3,0.3-0.6,0.5-0.6h1
	c0.3,0,0.5,0.3,0.5,0.6v0.4c8.9,0.3,18.8,0.6,22.7,0.8c7.3,0.4,9.1,1,11.3-0.4c1.5-1,3.3-2.5,4.3-3.3c0-0.4-0.6-0.6-1.4-0.7
	c-1.5-0.1-14.6-0.8-31.9-1.4C453.4,216.6,421.1,216.2,421.2,216.2L421.2,216.2z"
          />
          <path
            fill="#FFFFFF"
            d="M421.2,216.2c0.1,0-32.2,0.4-49.5,1s-30.4,1.2-31.9,1.4c-1,0-1.5,0.3-1.4,0.7c0.8,0.8,2.6,2.2,4.3,3.3
	c2.2,1.4,4,0.8,11.3,0.4c3.7-0.1,13.8-0.6,22.7-0.8h2.1c6.7-0.1,13.3-0.4,18.3-0.4c12.4-0.3,24.5-0.4,24.3-0.4s12,0.1,24.4,0.4
	c5,0.1,11.7,0.3,18.3,0.4h2.1c8.9,0.3,18.8,0.6,22.7,0.8c7.3,0.4,9.1,1,11.3-0.4c1.5-1,3.3-2.5,4.3-3.3c0-0.4-0.5-0.6-1.4-0.7
	c-1.5-0.1-14.6-0.8-31.9-1.4C453.4,216.6,421.1,216.2,421.2,216.2L421.2,216.2z"
          />
          <path
            fill="#5E5E5E"
            d="M463.9,222.1l0.1,0.8c0,0.3,0.3,0.6,0.5,0.6h0.8c0.3,0,0.5-0.3,0.5-0.6v-0.8l-1.1-3.3L463.9,222.1
	L463.9,222.1z M378.6,222.1l-0.1,0.8c0,0.3-0.3,0.6-0.5,0.6h-0.8c-0.3,0-0.5-0.3-0.5-0.6v-0.8l1.1-3.3L378.6,222.1L378.6,222.1z"
          />
          <path
            fill="#FFFFFF"
            d="M634.6,211.5c0,0-0.4-8.3-1.5-11.7c-1.1-3.4-5-6.7-10.2-6.9c-5.1-0.1-16.2,0.1-18.8,0.4
	c-2.8,0.3-5.5,1.4-5.5,5.8c0,4.3,0.1,11.8,0.1,12.1c0,0-1.8,0.7-2.5,0.8c-0.7,0.3-0.7,1.4-0.7,1.4l-4.1,0.1c0,0-0.3-1.9-3-2.1
	c-2.8-0.1-12.9-0.4-15.1-0.6s-3.7-0.1-5-4.1c-0.1-0.4-0.4-1.1-0.5-1.8v2.2l4.8,14.9l1.7,5.6l1.1,4.1l1.5,5.9l2.1,3.6l1.1,3.2l1.2,4
	l0.3,3.4c0,0,0.8-0.8,0.5-2.1c-0.3-1.2-0.4-1.8-0.4-1.8s5-0.4,7.2-0.8c2.2-0.4,2.3-1.1,2.6-1.9l0.4-1.5h3.9c0,0,0.8,1,2.3,1h1.8
	c0,0,0.8,3.3,5.4,3.2c4.4-0.1,22.4-1.4,27.1-2.2c3.6-0.6,3.3-3,3.3-3s0.7-0.1,0.7-1.4c0-1.1,0.1-4.5,0-5.2c0-0.3-0.1-0.4-0.1-0.6
	c-0.1-0.3-0.4-0.4-0.4-0.4l-0.5-16.4v-0.1c0,0,0.4,0,0.4-0.6s-0.3-5-0.3-5.5C635.1,211.8,634.6,211.5,634.6,211.5L634.6,211.5z
	 M598.8,221.3l0.3,13.1H599c-0.4,0.1-1.9,0.4-2.6,0.6c-0.8,0.1-0.8,1.4-0.8,1.4l-4.1,0.1c-0.1-0.6-0.4-1-0.8-1.4
	c-0.3-0.3-0.5-0.4-0.8-0.6c-0.4-0.1-0.7-0.3-1.1-0.3l-7-0.1h-1.2c-1.4,0-1.9-0.4-2.2-1.8l-1.2-4.5v-0.1c-0.3-1.1-0.1-1.9,0.5-2.3
	c0.4-0.3,0.8-0.4,1.4-0.6l10.6-1.4h0.3c1.1-0.1,1.4-0.6,1.5-1.5v-1.7h4.3c0,0,0.3,0.4,1.4,0.7C598.1,221.6,598.8,221.3,598.8,221.3
	L598.8,221.3z"
          />
          <path
            fill="#5E5E5E"
            d="M304.2,259.3L304,271l-0.1,20.8l0.1,16.1l0.3,10.7l0.3,2.5l0.3,1.1l0.4,1.5l1.8,2.6l2.8,3.6l3.3,4l3,2.6
	l2.1,1.1l3,0.7l-0.1,1.5l-2.2-0.3l-2.3-0.8l-2.8-1.5l-2.1-1.5l-2.6-2.3l-1.5-1.5l-1.6-1.6l-1.6-1.2l-1.9-0.8l-1.2-0.4l-1.1-0.1v-0.3
	l-0.4-0.4l-0.7-0.1l-41.5-0.3l-0.8,0.1l-1.1,0.5l-1,0.7l-0.6,0.7l0.1,0.7l-1.1,0.7c-0.6,0.7-1.9,2.6-2.2,5.1
	c-0.3,3.2,1.2,17.1,1.2,17.1v0.1c0.1,1.2,1.8,13.3,6.2,26c3.4,6.9,8.9,7,10.3,7.3c3.6,0,7.3,0.1,10.9,0.1
	c94.4,0.8,188.7,0.8,283.2,0.4h10.9c0-0.1,0-0.3,0.1-0.4c4.4,0,8.5-2.6,10.2-6.6c5.6-14.2,6.5-27.2,6.5-27.2s0-0.7,0.1-2.5
	c0-0.3,0-0.5,0.1-0.8c0.3-2.3,0.8-8.7,1-12.9c0.1-4.4-2.3-5.8-2.3-5.8v-0.3l-0.5-0.3l0.1-0.7l-0.5-0.7l-1-0.7l-1.1-0.5l-0.8-0.1
	l-41.5,0.3l-0.7,0.1l-0.4,0.4v0.3l-1.1,0.1l-1.2,0.4l-1.9,0.8l-1.7,1.2l-1.7,1.6l-1.5,1.5l-2.6,2.3l-2.1,1.5l-2.8,1.5l-2.3,0.8
	l-2.2,0.3l-0.3-1.5l3-0.7l2.1-1.1l3-2.6l3.3-4l2.8-3.6l1.8-2.6l0.4-1.5l0.3-1.1l0.3-2.5l0.3-10.7l0.1-16.1l-0.1-20.8l-0.1-11.7
	l-0.3-3.7l-0.4-1.9l-1.1-2.5l-1.7-2.2l-3.2-2.9l-2.3-1.9l-2.3-1.8l-2.9-1.8l-2.2-1l-2.9-0.8l-2.6-0.3l-5-0.3l-15-0.6l-11.4-0.4
	L469,237l-15.3-0.3l-15.1-0.1l-17.2-0.1l-17.2,0.1l-15.1,0.1l-15.3,0.3l-16.2,0.3l-11.4,0.4l-15,0.6l-5,0.3l-2.6,0.3l-2.9,0.8
	l-2.2,1l-2.9,1.8l-2.3,1.8l-2.3,1.9l-3.2,2.9l-1.6,2.2l-1.1,2.5l-0.4,1.9L304.2,259.3L304.2,259.3z M545.2,352.4c0.8-1,3.9-4,5.1-5
	s2.6-1.7,4.8-1.8s15-0.1,15-0.1c1.4,0,3,0.7,3,3s-0.8,12.5-1.4,16c-0.5,3.3-1.9,5.8-5.2,5.8c-3.3-0.1-17.2,0-19.1,0
	c0,0-3.7,0.1-3.7-3.3s0.1-9.6,0.3-11C543.8,354.5,544.3,353.4,545.2,352.4L545.2,352.4z M272.2,345.5c0,0,12.8,0.1,15,0.1
	s3.6,0.8,4.8,1.8c1.2,1,4.1,4,5.1,5c0.8,1,1.4,2.1,1.4,3.6c0,1.4,0.3,7.6,0.3,11s-3.7,3.3-3.7,3.3c-1.9,0-15.8-0.1-19.1,0
	c-3.3,0-4.7-2.3-5.2-5.8c-0.5-3.3-1.4-13.6-1.4-16C269.2,346.2,270.7,345.5,272.2,345.5L272.2,345.5z M525.6,218
	c0.3,0.1,0.4,0.3,0.4,0.3c4.1,0.3,6.3,1.5,8.9,2.5c0.8,0.3,1.9,0.8,3.2,1.4l1.8-0.8c0,0,1.7-0.3,2.3,0c0.7,0.3,2.9,0.8,2.9,0.8h18.3
	c0.5,0,1,0,1.4,0.1v-0.7c-0.1-1.5-0.7-6.1-2.1-11.6c-1.8-6.7-4.8-16.1-9.4-28.5c-4.5-12.4-7.2-18.3-10.5-24.8
	c-3.3-6.6-8.7-7.7-17.1-8.8s-28.3-2.9-50.2-3.4s-43.1-0.8-54.2-0.8c-11.1,0-21-0.1-46.4,0.7c-25.3,0.7-51.9,3-60.5,3.9
	c-8.7,0.8-12.4,4.7-13.8,7c-1.4,2.2-6.1,13.3-10.7,25.3c-4.7,12-7.8,21.9-9.6,29.2c-1.5,6.1-2.2,11-2.3,12.5c0.5,0,1.1-0.1,1.6-0.1
	c4.5,0,18.2,0.3,18.2,0.3s2.1-0.7,3-1.1c0.8-0.4,1.2-0.1,1.9,0c0.4,0,1.8,0.1,3.2,0.4c0.7-0.3,1.4-0.6,1.9-0.8
	c1.8-0.7,3.7-1.8,6.3-2.3c-6.3,0.4-17.2,1.1-21.7,1c-2.8-0.1-8.1-2.1-6.2-9.5c1.9-7.4,5.1-21.3,11.3-35.1c6.2-13.8,8-19.4,15.7-20.6
	s20.9-3,46.8-3.6s43.1-1,61.6-1c18.6,0.1,50.2,0.1,72.2,1.5c22,1.4,27.2,1.8,33,2.6c5.8,0.8,9.2,1.1,12,6.9
	c2.8,5.9,6.3,10.6,11.6,26.7c5.2,16.1,5.9,18.8,6.5,23c0.5,4.3,1.1,8.7-9.2,8.4C540.9,218.7,529.2,218.1,525.6,218L525.6,218z"
          />
          <path
            fill="#5E5E5E"
            d="M251.9,348.8l50.2,67c0,0-0.1,25.2-0.8,31.6c-0.7,6.5-2.2,11.1-7,11.1c-5,0-33,0.1-36.2,0
	c-3.2-0.1-6.1-4-5.9-16S251.9,348.8,251.9,348.8L251.9,348.8z M590.4,348.8l-50.2,67c0,0,0.1,25.2,0.8,31.6c0.4,8.4,2.2,11.1,7,11.1
	c5,0,31.6,0.1,34.8,0c3.2-0.1,6.3-3.3,6.9-15.8C589.6,430.7,590.4,348.8,590.4,348.8L590.4,348.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M518.5,139.9c0,0,0.8-1.2,2.3-1.1c1.4,0.1,11.8,2.3,13.9,2.9
	c2.1,0.6,2.3,1.4,2.3,1.4s0.8,0,1.5,0.7c0.7,0.6,2.3,1.9,6.2,8s8,14.6,11.3,22.3c3.3,7.7,7.7,19.7,9.9,26c2.2,6.3,2.1,6.1,2.6,7.4
	c0.5,1.5,1.7,3.4,4.4,3.4s13.3,0.6,15.4,0.7c2.8,0.1,3,2.1,3,2.1l4.3-0.1c0,0,0-1,0.7-1.2c0.7-0.1,2.3-0.7,2.3-0.7s0.1-10,0.1-13.2
	c0-3,1.5-4.7,5.2-4.8c3.7-0.3,16.1-0.6,19.7-0.4c3.6,0.1,7.8,2.1,9.4,6.7s1.8,11.7,1.8,11.7s0.3,0.3,0.4,0.7c0,0.4,0.1,5.1,0.3,5.6
	c0,0.6-0.3,1-0.3,1s0.3,5,0.4,8.5c0.1,3.7,0.1,7.6,0.1,7.6s0.5,0.3,0.7,1.2v5.1c0,0.8-0.4,1.2-0.4,1.2s-0.4,1.9-1.8,2.3
	c-1.4,0.4-4.4,1.2-12.4,2.1c-8,0.8-14.7,1.1-17.6,1c-3.7-0.1-4.4-3.3-4.4-3.3s-1.8,0.1-2.3,0c-0.5,0-1.7-0.8-1.7-0.8h-4.3v1.2
	c0,0.7-0.1,1.6-2.3,1.9c-2.2,0.3-7.6,1.1-7.6,1.1s0.3,0.7,0.3,1.8c0,1-0.4,2.1-0.4,1.9s1.9,2.2,2.3,4c0.4,1.8,1.4,4.3,1.7,6.3
	s0.3,3.7,0.3,3.7s4.5,4.8,5,14.2c0.6,9.4,0.4,22-0.3,30.8c-0.5,8.8-0.8,11-0.8,11h-0.3l-0.3,8.1c0,0,2.3,1.4,2.3,5.8
	c-0.1,4.4-0.7,10.6-1,12.9c-0.3,2.3-0.3,3.2-0.3,3.2s-0.1,83.1-0.3,91.2c-0.1,8-2.1,16-6.3,16c-3.9,0-34.3-0.3-37.3-0.3
	c-5.6,0-5.4-9.1-5.6-13.6c-0.1-4.5-0.5-27.4-0.5-27.4s-17.9-0.3-42.1-0.1c-24.2,0.1-89.8,0.4-123.3,0.3c-33.4-0.1-72.6-0.5-72.6-0.5
	s-0.1,23.9-0.8,30.5c-0.7,6.5-2.2,11.1-7,11.1c-5,0-33,0.1-36.2,0s-6.1-4-5.9-16c0.1-12-0.1-90.8-0.1-90.8s-1.6-13.9-1.2-17.1
	c0.3-3.2,2.3-5.4,2.3-5.4l-0.3-7.8c0,0-0.4,0-0.4-0.7c-0.1-0.7-1.4-16.1-1.4-23.4c0-7.4,0-18.2,0.8-22.4c0.8-4.3,4.3-9.5,4.3-9.5
	s0.1-3.2,1.1-6.6c1-3.4,1.2-4.7,1.9-5.6c0.7-1,1.4-1.8,1.4-1.8s-0.8-0.8-0.5-2.1c0.3-1.2,0.4-1.8,0.4-1.8s-5-0.4-7.2-0.8
	c-2.2-0.4-2.3-1.1-2.6-1.9c-0.1-0.7-0.4-1.5-0.4-1.5h-3.9c0,0-0.8,1-2.3,1h-1.8c0,0-0.8,3.3-5.4,3.2c-4.4-0.1-22.4-1.4-27.1-2.2
	c-3.6-0.6-3.3-3-3.3-3s-0.7-0.1-0.7-1.4c0-1.1-0.1-4.5,0-5.2c0-0.7,0.6-0.8,0.6-0.8l0.6-16.5c0,0-0.4,0-0.4-0.6s0.3-5,0.3-5.5
	c0-0.6,0.6-1,0.6-1s0.4-8.3,1.5-11.7c1.1-3.4,5-6.7,10.2-6.9c5.1-0.1,16.2,0.1,18.8,0.4c2.8,0.3,5.5,1.4,5.5,5.8
	c0,4.3-0.1,12.1-0.1,12.1s1.8,0.7,2.5,0.8c0.7,0.3,0.7,1.4,0.7,1.4l4.1,0.1c0,0,0.3-1.9,3-2.1c2.8-0.1,12.9-0.4,15.1-0.6
	c2.2-0.1,3.7-0.1,5-4.1c1.2-4.1,7.4-21.3,9.9-27.2c2.5-5.8,9.5-20.8,12.7-26.3c3.2-5.5,6.1-8.5,6.7-9.2c0.7-0.7,1.7-0.7,1.7-0.7
	s0.1-0.4,1-0.8c0.8-0.4,4.8-1.8,8.5-2.5c3.7-0.7,5.6-1.1,6.7-1.2c1.5-0.3,2.2,1.2,2.2,1.2l2.9-0.1c0,0,0.8-1.9,3.3-2.2
	c2.5-0.3,20.9-1.8,35.8-2.5s46.1-1.4,57.2-1.2c11.3,0,36,0.6,49.5,1.1s36,2.3,38.8,2.5c4.4,0.3,4.8,2.5,4.8,2.5L518.5,139.9
	L518.5,139.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.5,236.6l-4.1-0.1c0,0,0-1.2-0.8-1.4c-0.8-0.1-2.8-0.6-2.8-0.6
	l0.3-13.1c0,0,0.7,0.1,1.8-0.1c1.1-0.4,1.4-0.7,1.4-0.7h4.3v1.2c0,0-0.1,1.5,1.8,1.8c1.9,0.1,8.9,1.2,10.3,1.4
	c1.4,0.1,2.8,0.7,2.3,2.6c-0.4,1.8-1.2,5.1-1.2,5.1s-0.1,1.8-1.9,1.8c-1.8,0-7.2,0.3-8.8,0.3C251.2,234.5,250.5,236.6,250.5,236.6
	L250.5,236.6z M591.5,220.7l4.1-0.3c0,0,0.4,0.7,1.2,0.8c1,0.1,1.8,0,1.8,0l0.4,13.1c0,0-1.7,0.4-2.5,0.7c-1,0.3-0.8,1.4-0.8,1.4
	h-4.3c0,0,0-1.6-2.6-1.9c-2.5-0.3-8.5-0.4-8.5-0.4s-1.4,0.1-2.1-1.8s-1-3.6-1.2-4.5s0-2.8,1.9-3.2c2.1-0.3,9.2-1.1,10.6-1.2
	c1.4-0.3,1.7-0.7,1.8-1.4C591.4,221.4,591.5,220.7,591.5,220.7L591.5,220.7z M250.8,213.6v7.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.8,214.6h11.3c1.2,0,1.4,0,1.4,1.1c0,1.2-0.4,4.8-0.4,5.5
	c0,0.8-0.4,1.1-1.4,1.1s-11.1-0.3-11.1-0.3 M246.1,212.4c0,0-1.4,0.4-2.1,0.7c-0.7,0.3-1.1,0.7-1.1,1.8c0,1-0.3,5.2-0.3,5.2
	s0,1.4,0.6,1.5 M243.4,211.1c0,0-1.5,0-3.2,0.4s-2.8,1.2-7.2,1.2c-4.3,0-23-1.1-24.1-1.1c-1.1,0-1.5-0.4-1.5-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.9,218.7c0,0,0.7,0.1,1.9,0.3c1.1,0.1,21.2,1.5,24.5,1.6
	c3.3,0,9.9,0.8,9.9,0.8 M210.2,219.1l-0.6,16 M205.9,235.5c0.1,0,0.7-0.3,1.4-0.3c0.7,0,20.2,1,23,1.1c2.6,0.1,4.3,0,6.3-0.6
	c3.7-0.8,6.5-1.2,6.5-1.2 M250.6,236.7c0,0-0.3,6.6-0.1,7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.5,237.1c0,0,0,0.6,1.4,0.6h8.4c0,0,0.7-0.3,0.7,0.8s-0.3,5.1-0.4,5.6
	c0,0.7,0,1.1-1,1.1s-7.3,0.1-8,0c-0.8-0.1-1.1-0.6-1.1-0.6 M245.8,235.3c0,0-1.4,0.6-2.1,0.7c-0.7,0.3-1.2,0.6-1.2,1.5
	s-0.1,4.5,0,5.4c0,0.7,0.4,1.6,1.2,1.6 M242.9,244.4c0,0-5.8-0.4-15.3-1s-21.2-1.1-21.2-1.1 M591.2,213.6v7.3 M591.2,214.6H580
	c0,0-1.4,0-1.4,1.1s0.4,4.8,0.4,5.5c0,0.8,0.4,1.1,1.4,1.1s11.1-0.3,11.1-0.3 M595.9,212.4c0,0,1.4,0.4,2.1,0.7
	c0.7,0.3,1.1,0.7,1.1,1.6s0.3,5.2,0.3,5.2s0,1.4-0.5,1.5 M598.7,211.1c0,0,1.5,0,3.2,0.4s2.8,1.2,7.2,1.2c4.4,0,23-1.1,24.1-1.2
	c1.1,0,1.5-0.4,1.5-0.4 M635.1,218.7c0,0-0.7,0.1-1.9,0.3c-1.1,0.1-21.2,1.5-24.5,1.6c-3.3,0-9.9,0.8-9.9,0.8 M631.8,219.1l0.5,16
	 M636.1,235.5c-0.1,0-0.7-0.3-1.4-0.3s-20.2,1-23,1.1c-2.6,0.1-4.3,0-6.3-0.6c-3.7-0.8-6.5-1.2-6.5-1.2 M591.4,236.7
	c0,0,0.3,6.6,0.1,7.7 M591.4,237.1c0,0,0,0.6-1.4,0.6h-8.4c0,0-0.7-0.3-0.7,0.8s0.3,5.1,0.4,5.6c0,0.7,0,1.1,1,1.1s7.3,0.1,8,0
	c0.8-0.1,1.2-0.6,1.2-0.6 M596.2,235.3c0,0,1.4,0.6,2.1,0.7c0.7,0.3,1.2,0.6,1.1,1.5c0,1,0.1,4.5,0,5.4c0,0.7-0.4,1.6-1.2,1.6
	 M599,244.4c0,0,5.8-0.4,15.3-1s21.2-1.1,21.2-1.1 M567.6,205.9c0,0,5.1,15.3,6.5,19.7c1.2,4.4,3.2,12,3.2,12s1.8,2.3,2.8,5.2
	c1,2.9,1.7,5.6,1.7,5.6 M585.7,265.2c0,0-0.8,0.1-1,2.2c-0.1,1.9,0.4,18.4-0.3,32.5c-0.7,14-1.1,19.1-1.4,20.4s-0.3,1.5-0.3,1.5h6.5
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M579.3,253.3c0,0,2.2,1.2,3,5.9c1,4.7,1.5,9.9,1.9,19
	c0.4,9.1,0,20.6-0.7,30.3s-0.7,9.5-1,10.9c-0.3,1.4-0.1,2.3-1.4,2.5c-1.2,0.1-43.6,0.3-43.6,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M539.2,322.3c0,0,0.4-0.5,0.4-1.8c0.1-1.2,0.1-5.4,0.5-16.6
	c0.4-11.1,0-32.2,0-39.8s-2.3-9.8-2.3-9.8 M525.9,218.3c4.3,0.1,6.5,1.5,9.1,2.5c2.5,1,7.4,3.3,9.4,4.1c1.9,1,3.7,1.6,7.2,1.8
	c3.4,0.1,10,0.7,14,1.4s10,2.3,11.1,6.6 M421.1,216.2c0,0,32.5,0.4,49.8,1c17.3,0.6,30.4,1.2,31.9,1.4c1.5,0.1,1.9,0.4,0.8,1.7
	c-1,1.2-4.3,9.1-5.2,12c-1,2.9-1.2,4.3-8.5,4c-7.4-0.3-30.5-0.7-46.8-1s-21.9-0.1-21.9-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M465.9,222.1c9.5,0.3,18.6,0.6,22.7,0.8c7.3,0.4,9.1,1,11.3-0.4
	c2.2-1.4,4.3-3.3,4.3-3.3 M421.1,221.3c0,0,12.1,0.1,24.5,0.4c5,0.1,11.7,0.3,18.3,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M465.8,222.9c0,0.3-0.3,0.6-0.5,0.6h-0.8c-0.3,0-0.5-0.3-0.5-0.6l-0.1-1.2
	c0-0.3,0.3-0.6,0.5-0.6h1c0.3,0,0.5,0.3,0.5,0.6V222.9z M421.1,236.3c0,0,38.5,0.4,55.2,0.7c16.6,0.3,33.6,1.1,40,1.4
	c6.5,0.4,9.8,3.2,14.3,6.9c4.5,3.6,7,5.9,7.4,11.3c0.5,5.4,0.3,19.4,0.4,32.1c0.1,12.7-0.3,26.7-0.4,29.9c-0.1,3.2-0.4,4.8-1.5,6.1
	c-1.1,1.4-5.8,7.8-8.3,10.2c-2.5,2.3-4.7,3.6-9.9,3.7c-5.2,0-33.8,1.2-54.2,1.4c-20.5,0.1-42.9,0.3-42.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.1,248.3c0,0,25.2-0.1,44.3,0c19,0.3,34.7,0.8,39.9,1
	c0,0,8.4,0.1,9.8,0.3c1.5,0.1,2.9,0.3,4.4,1.5c1.5,1.2,5.9,5.1,6.9,6.5c1,1.4,1.9,2.8,1.9,6.9s0.1,11,0.1,25.5
	c0,14.4-0.3,25.7-0.4,28.3c0,2.6,0.1,4.3-1.7,6.5c-1.9,2.1-5.5,5.5-7.4,7c-1.9,1.6-2.9,1.8-6.2,1.9c-3.3,0.1-29.6,0.7-45.7,0.8
	s-45.9,0.3-45.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.1,253.6c0,0,28.3,0.1,44.8,0.3c16.4,0.1,45.1,1.2,47.5,1.4
	c2.3,0,2.3,0.3,3.4,1s3.7,3.2,4.5,4c0.7,0.8,1,1.9,1,3.7c0.1,1.8,0.3,17.7,0.1,27.2c0,9.5-0.5,25.2-0.5,27.2c0,1.5-0.3,2.1-1,2.9
	c-0.7,0.8-3.3,3.4-4.4,4.3c-1.1,0.7-1.9,1.6-4.7,1.6s-34.5,1.2-52.1,1.4c-17.6,0.1-38.5,0.1-38.5,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M467.4,326.9l29.6-0.3v-1.1c0,0-5.9,0-6.3,0.1c-0.4,0-0.4-0.5-0.4-1v-4.7
	c0-0.5,0.7-0.5,1.5-0.4c0.8,0,19.1-0.3,19.7-0.3c0.5,0,1.1-0.3,1.5-0.4c0.3-0.1,0.8-0.7,1.1-1.1c0.3-0.3,0.4-0.4,0.4-1.2
	c0-1,0.5-24.5,0.5-31c0-6.5-0.3-18.4-0.3-19.5s-0.4-1.5-0.7-1.8c-0.3-0.3-0.4-0.4-0.8-0.7c-0.3-0.3-1.1-0.1-1.1-0.1
	s-20.5-0.1-21-0.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.3-0.1-4.4-0.1-5c0-0.5,0.4-0.5,0.4-0.5h6.2V255 M464,326.9l-26.5,0.1 M434.2,327.1
	h-13.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M449.7,297.2c3.9,0,49.9-0.3,53-0.3c3.2,0,5.6,0.3,6.9,0.7
	c1.4,0.6,2.6,1.1,2.6,3.2c0,2.2-0.1,11-0.1,12.2c0,1.2-1,2.8-2.5,3c-1.5,0.4-4.7,1-8.9,1.1c-4.3,0.1-5,0.1-13.3,0.3
	c-8.4,0.1-25.2,0.5-36.2,0.5h-30.3 M449.6,285.5l1.6,1.2c0.5,0.4,0.4,1.5,0.4,1.5s0.7,0.4,0.8,1.1c0.1,0.7-0.3,1-0.3,1
	s0.6,0.3,0.6,1s-0.7,1.1-0.7,1.1s0.5,0.4,0.3,1.1c-0.1,0.7-0.8,1-0.8,1s0.3,0.8-0.3,1.4c-0.5,0.5-1.6,1.4-1.6,1.4 M421.1,264.4
	c0,0,30.3,0.3,41.5,0.3c11.1,0,27.1,0.3,32.9,0.4c5.8,0.1,9.1,0.4,10.5,0.5c1.4,0,3,0.5,4.1,1c1.1,0.4,2.1,1.2,2.1,2.9
	c0,1.8,0.1,10.7,0.1,12.1c0,1.2-0.7,3.4-2.2,3.7c-1.5,0.3-5.1,0.7-7.3,0.7s-49-0.3-53-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M437.5,318.1v9.4l-0.7,0.4H435l-0.7-0.4V318 M467.3,318v9.2l-0.7,0.4h-1.8
	l-0.7-0.4V318 M464.6,285.7v11.4 M466.9,285.7V297 M466.8,264.6v-9.9h-2.3v9.9 M434.7,264.5v-10h2.3v9.9 M493.9,285.7v11.4
	 M496.2,285.7V297 M490.4,256.9c0,0,4.3-0.1,4.3,1.4v3.4c0,1.2-4,1.4-4,1.4 M490.8,319.5c0,0,4-0.1,4,1.4v3.4c0,1.2-4,1.4-4,1.4
	 M581.6,251.3c0,0-0.7,2.1-4,2.2c-2.9,0.1-35.5,0-37.4,0s-2.9-0.8-3.6-2.1 M573.5,385.4c0,0-0.4,10.9-2.8,19
	c-2.3,8.1-6.9,11.7-14.2,12.1c-7.2,0.3-18,0.4-18,0.4 M268.5,385.3c0,0,0.4,10.7,2.8,18.8c2.3,8.1,6.9,11.6,14.2,11.8
	c7.2,0.3,16.6,0.4,16.6,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.9,413.2c0,0,45.3,0.3,72.6,0c27.4-0.3,59.7-1,59.7-1
	s-15.4-2.1-39.5-1.9c-24.2,0.1-92.7,0.4-92.7,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,413.2c0,0-45.3,0.3-72.6,0c-27.4-0.3-59.7-1-59.7-1
	s15.4-2.1,39.5-1.9c24.1,0.1,92.7,0.4,92.7,0.4 M507.6,285.4h-10c-1.5,0-2.6-1.2-2.6-2.8v-14.3c0-1.5,1.2-2.8,2.6-2.8h10
	 M506.8,316.8h-9.5c-1.4,0-2.6-1.2-2.6-2.8v-14.3c0-1.5,1.2-2.8,2.6-2.8h9.5 M451.2,297.2v-0.7h2.2v0.7 M455.7,297.2v-0.8h2.3v0.8
	 M460.3,297.2v-0.8h2.2v0.8 M464.6,297.2v-0.8h2.2v0.8 M469.1,297.1v-0.7h2.3v0.7 M473.6,297.1v-0.7h2.3v0.7 M478,297.1v-0.8h2.3
	v0.8 M482.6,297v-0.7h2.3v0.7 M487.1,297v-0.8h2.2v0.8 M491.4,297v-0.7h2.3v0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M496.3,297v-0.8h2.2v0.8 M500.5,297v-0.8h2.2v0.8 M451.2,285.5v0.8h2.2
	v-0.8 M455.7,285.7v0.7h2.3v-0.7 M460.3,285.7v0.7h2.2v-0.7 M464.6,285.7v0.7h2.2v-0.7 M469.1,285.7v0.7h2.3v-0.7 M473.6,285.7v0.7
	h2.3v-0.7 M478,285.7v0.8h2.3v-0.8 M482.6,285.8v0.7h2.2v-0.7 M487.1,285.8v0.7h2.2v-0.7 M491.4,285.8v0.7h2.3v-0.7 M496.2,285.8
	v0.7h2.3v-0.7 M500.5,285.8v0.8h2.2v-0.8 M421.2,276.7c12.8,0,23.8,3.6,28.5,8.8 M449.6,297.2c-4.8,5.1-15.7,8.8-28.5,8.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.1,279.5c16.2,0,29.3,5.4,29.3,11.8c0,6.6-13.1,11.8-29.3,11.8
	 M496.6,263.2v1.8 M496.9,319.5v-2.2 M496.6,256.9l15.8,0.4c0,0,1.7-0.1,3,0.7c1.2,0.8,3,2.6,3.9,3.4c0.8,0.8,1,1.6,1,2.9
	c0,1.1,0.1,18.2,0.1,25.3s-0.4,27.1-0.4,28.1s-0.1,1.9-0.8,2.8c-0.7,0.8-2.6,2.9-3.6,3.7c-1.1,0.8-2.2,1.2-4.3,1.2
	c-1.9,0-15,0.4-15,0.4 M569.2,229.1c0,0-0.5-3.6-1.1-4.8c-0.4-1.2-1.5-2.1-4.8-2.1H545c0,0-2.2-0.6-2.9-0.8c-0.7-0.3-2.3,0-2.3,0
	l-1.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M567.5,223.1c0,0-1.2-7.8-3.8-16.2c-2.6-8.4-6.6-21.9-11-32.1
	s-8.3-19-10.5-23.2c-2.1-4-4-6.7-5.2-8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M566.8,219c0,0,0.7-0.6,1.2-0.1c0.5,0.4,2.1,2.1,3.7,4.4s3,4.8,3.4,6.3
	 M496.3,288h18.3 M496.3,286.6h18.3 M509.7,285.1h5 M502.9,296.8h11.6 M512.3,282.4h2.5 M512.2,299.7h2.6 M512.2,301.1h2.6
	 M512.3,302.6h2.3 M512.2,303.8h2.3 M512.2,305.3h2.3 M512.2,306.9h2.3 M512.2,308.4h2.3 M512.2,309.7h2.3 M512.2,311.3h2.3
	 M512.2,312.5h2.3 M512,314.1h2.3 M510.9,315.7h3.4 M496.3,289.5h18.3 M496.3,290.9h18.3 M496.3,292.4h18.3 M496.3,293.9h18.3
	 M496.3,295.3h18.3 M511.7,283.7h2.9 M510.8,298.2h3.9 M512.3,281h2.5 M512.3,279.5h2.5 M512.3,278h2.3 M512.3,273.6h2.3
	 M512.3,276.6h2.3 M512.3,275.1h2.3 M512.3,272.2h2.3 M511.7,267.8h2.9 M512.3,270.8h2.3 M512.3,269.3h2.3 M510.1,266.4h4.5
	 M346.1,288h-18.3 M346.1,286.6h-18.3 M332.8,285.1h-5 M339.6,296.8H328 M330.2,282.4h-2.5 M330.4,299.7h-2.8 M330.4,301.1h-2.8
	 M330.2,302.6H328 M330.3,303.8H328 M330.3,305.3H328 M330.3,306.9H328 M330.3,308.4H328 M330.3,309.7H328 M330.3,311.3H328
	 M330.3,312.5H328 M330.4,314.1h-2.3 M331.7,315.7h-3.6 M346.1,289.5h-18.3 M346.1,290.9h-18.3 M346.1,292.4h-18.3 M346.1,293.9
	h-18.3 M346.1,295.3h-18.3 M330.7,283.7h-2.9 M331.7,298.2H328 M330.2,281h-2.5 M330.2,279.5h-2.5 M330.2,278h-2.3 M330.2,273.6
	h-2.3 M330.2,276.6H328 M330.2,275.1H328 M330.2,272.2H328 M330.8,267.8h-3 M330.2,270.8H328 M330.2,269.3H328 M332.5,266.4H328
	 M259.3,264.6c0,0,0.8-5.2,7.6-5.2c6.9,0,35.4-0.1,35.4-0.1 M583.1,264.6c0,0-0.8-5.2-7.6-5.2c-6.9,0-35.4-0.1-35.4-0.1
	 M601.7,193.8c0,0,6.1,2.8,6.6,10.2c0.4,7.4,0.7,8.9,0.7,8.9 M609.1,220.6l0.3,15.5 M239.9,193.8c0,0-6.1,2.8-6.6,10.2
	c-0.4,7.4-0.7,8.9-0.7,8.9 M232.6,220.6l-0.4,15.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M295.5,262.1c0.5,0,1.4,0.1,2.2,0.5c0.7,0.4,1.4,1.4,1.4,2.6
	c0,1.9-0.3,19.1-0.3,20.4c0,0.7-0.1,1.5-0.7,2.2c-0.5,0.7-1.4,1.1-2.5,1.1c-1.8,0-31.4-0.1-32.6-0.1c-0.8,0-1.6-0.3-2.2-1
	c-0.5-0.7-0.7-1.5-0.7-2.6c0-2.1,0-10.3,1.1-14.2c0.8-2.9,3.4-8.7,8.8-8.9L295.5,262.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M295.5,263.8c0,0-20.8,0-25.5,0.1c-4,0-6.6,5-7.3,7.7
	c-1,3.4-1,11.7-1,13.6c0.1,1.8,0.4,1.9,1.2,1.9h32.6c0.7,0,1-0.1,1.1-0.4c0.1-0.3,0.3-0.7,0.3-1.2c0-1.2,0.1-18.4,0.1-20.4
	c0-0.7-0.3-1-0.5-1.1C296.4,263.9,295.9,263.8,295.5,263.8 M296.7,317.3c0.4-0.3,0.5-0.5,0.5-1.4c0-2.1-0.3-20.5-0.3-21.9
	c0-0.5-0.1-1.1-0.3-1.4c-0.3-0.3-0.4-0.4-1.1-0.5c-1.8,0-31.6,0.1-32.9,0.1c-0.4,0-0.5,0.1-0.8,0.4c-0.1,0.3-0.4,1-0.4,1.8v5.6
	c0,3.2,0.1,6.6,0.7,8.1c0.4,1.4,1.8,3.7,3.6,5.8c1.8,2.1,4,3.6,6.2,3.6h23.7C295.8,317.6,296.3,317.4,296.7,317.3L296.7,317.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M295.5,319.4h-23.7c-3,0-5.5-1.9-7.6-4.1c-1.9-2.2-3.4-4.8-4-6.3
	c-0.7-1.9-0.7-5.4-0.7-8.7v-5.6c0-1.1,0.1-2.1,0.7-2.9c0.5-0.8,1.4-1.1,2.2-1.1c1.2,0,31.1-0.1,32.9-0.1c1.1,0,2.1,0.5,2.6,1.2
	c0.5,0.7,0.7,1.6,0.7,2.5c0,1.4,0.3,19.8,0.3,21.9c0,1.4-0.5,2.3-1.4,2.8C296.9,319.2,296,319.4,295.5,319.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M282.3,307c0,0.4-0.4,0.8-0.8,0.8h-5.1c-0.4,0-0.8-0.4-0.8-0.8v-4.1
	c0-0.4,0.4-0.8,0.8-0.8h5.1c0.4,0,0.8,0.4,0.8,0.8L282.3,307L282.3,307z M282.3,278.8c0,0.4-0.4,0.8-0.8,0.8h-5.1
	c-0.4,0-0.8-0.4-0.8-0.8v-4.1c0-0.4,0.4-0.8,0.8-0.8h5.1c0.4,0,0.8,0.4,0.8,0.8L282.3,278.8L282.3,278.8z M546,262.1
	c-0.5,0-1.4,0.1-2.1,0.5c-0.8,0.4-1.4,1.4-1.4,2.6c0,1.9,0.3,19.1,0.3,20.4c0,0.7,0.1,1.5,0.7,2.2c0.5,0.7,1.4,1.1,2.5,1.1
	c1.8,0,31.4-0.1,32.6-0.1c0.8,0,1.7-0.3,2.2-1c0.5-0.7,0.7-1.5,0.7-2.6c0-2.1,0-10.3-1.1-14.2c-0.8-2.9-3.4-8.7-8.8-8.9L546,262.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M546,263.8c0,0,20.8,0,25.5,0.1c4,0,6.6,5,7.3,7.7c1,3.4,1,11.7,1,13.6
	c-0.1,1.8-0.4,1.9-1.2,1.9h-32.6c-0.7,0-1-0.1-1.2-0.4c-0.1-0.3-0.3-0.7-0.3-1.2c0-1.2-0.3-18.4-0.3-20.4c0-0.7,0.3-1,0.5-1.1
	C545,263.9,545.6,263.8,546,263.8 M544.8,317.3c0.4,0.3,0.8,0.4,1.2,0.4h23.7c2.1,0,4.4-1.5,6.2-3.6c1.8-2.1,3.2-4.4,3.6-5.8
	c0.5-1.5,0.7-5,0.7-8.1v-5.6c0-1-0.1-1.5-0.4-1.8c-0.3-0.3-0.4-0.4-0.8-0.4c-1.2,0-31.1-0.1-32.9-0.1c-0.7,0-1,0.1-1.1,0.5
	c-0.3,0.3-0.3,0.8-0.3,1.4c0,1.4-0.3,19.8-0.3,21.9C544.2,316.8,544.5,317,544.8,317.3L544.8,317.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M546,319.4c-0.5,0-1.4-0.1-2.2-0.7c-0.8-0.5-1.4-1.5-1.4-2.8
	c0-2.1,0.3-20.5,0.3-21.9c0-0.8,0.1-1.6,0.7-2.5c0.5-0.8,1.5-1.2,2.6-1.2c1.8,0,31.6,0.1,32.9,0.1c0.8,0,1.8,0.4,2.2,1.1
	c0.5,0.7,0.7,1.7,0.7,2.9v5.6c0,3.3-0.1,6.6-0.7,8.7c-0.5,1.6-1.9,4.1-4,6.3c-1.9,2.2-4.5,4.1-7.6,4.1
	C564.7,319.4,546,319.4,546,319.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M559.2,307c0,0.4,0.4,0.8,0.8,0.8h5.1c0.4,0,0.8-0.4,0.8-0.8v-4.1
	c0-0.4-0.4-0.8-0.8-0.8H560c-0.4,0-0.8,0.4-0.8,0.8L559.2,307L559.2,307z M559.2,278.8c0,0.4,0.4,0.8,0.8,0.8h5.1
	c0.4,0,0.8-0.4,0.8-0.8v-4.1c0-0.4-0.4-0.8-0.8-0.8H560c-0.4,0-0.8,0.4-0.8,0.8L559.2,278.8L559.2,278.8z M368.1,352.4v23.1
	 M365.1,352.5v23 M323,352.5l0.1,22.8 M473.5,352.4v23.1 M476.7,352.5v23 M518.6,352.5l-0.1,22.8 M318.6,218.1
	c0,0,46.6-3.6,103.3-3.6c59.4,0,102.8,3.4,102.8,3.4s15.1,0.7,22.7,1c10.3,0.3,9.8-4.1,9.2-8.4c-0.5-4.3-1.2-6.9-6.5-23
	c-5.2-16.1-8.8-20.9-11.6-26.7s-6.2-6.2-12-6.9c-5.8-0.8-10.9-1.2-33-2.6c-22-1.4-53.8-1.4-72.2-1.5c-18.6,0-35.8,0.4-61.6,1
	s-39.1,2.3-46.8,3.6c-7.7,1.2-9.5,6.7-15.7,20.6c-6.2,13.8-9.4,27.6-11.3,35.1c-1.9,7.4,3.4,9.4,6.2,9.5
	C298.7,219.8,318.6,218.1,318.6,218.1 M274.9,205.9c0,0-5.1,15.3-6.5,19.7c-1.2,4.4-3.2,12-3.2,12s-1.8,2.3-2.8,5.2
	c-1,2.9-1.7,5.6-1.7,5.6 M256.7,265.2c0,0,0.8,0.1,1,2.2c0.1,1.9-0.4,18.4,0.3,32.5c0.7,14,1.1,19.1,1.4,20.4
	c0.3,1.2,0.3,1.5,0.3,1.5h-6.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M263.2,253.3c0,0-2.2,1.2-3,5.9c-1,4.7-1.5,9.9-1.9,19s0,20.6,0.7,30.3
	c0.7,9.6,0.7,9.5,1,10.9c0.3,1.4,0.1,2.3,1.4,2.5c1.2,0.1,43.5,0.3,43.5,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M303.1,322.3c0,0-0.4-0.5-0.4-1.8c-0.1-1.2-0.1-5.4-0.5-16.6
	c-0.4-11.1,0-32.2,0-39.8s2.5-9.8,2.5-9.8 M265.8,234.8c1.1-4.3,7.2-5.9,11.1-6.6s10.6-1.2,14-1.4c3.4-0.1,5.4-0.8,7.2-1.8
	c1.9-1,6.7-3.2,9.4-4.1c2.5-1,4.8-2.3,9.1-2.5 M421.4,216.2c0,0-32.5,0.4-49.8,1s-30.4,1.2-31.9,1.4s-1.8,0.4-0.8,1.7
	c1,1.2,4.3,9.1,5.2,12c1,2.9,1.2,4.3,8.5,4c7.4-0.3,30.5-0.7,46.8-1s21.7-0.1,21.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.5,222.1c-9.5,0.3-18.6,0.6-22.7,0.8c-7.3,0.4-9.1,1-11.3-0.4
	c-2.2-1.4-4.3-3.3-4.3-3.3 M421.4,221.3c0,0-12.1,0.1-24.5,0.4c-5,0.1-11.6,0.3-18.3,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.5,222.9c0,0.3,0.3,0.6,0.5,0.6h0.8c0.3,0,0.5-0.3,0.5-0.6l0.1-1.2
	c0-0.3-0.3-0.6-0.5-0.6h-1c-0.3,0-0.5,0.3-0.5,0.6V222.9z M421.2,236.3c0,0-38.5,0.4-55.2,0.7c-16.6,0.3-33.6,1.1-40,1.4
	c-6.5,0.4-9.8,3.3-14.3,6.9c-4.4,3.6-7,5.9-7.4,11.3c-0.5,5.4-0.3,19.4-0.4,32.1c-0.1,12.7,0.3,26.7,0.4,29.9
	c0.1,3.2,0.4,4.8,1.5,6.1s5.8,7.7,8.3,10.2c2.5,2.3,4.7,3.6,9.9,3.7c5.2,0,33.8,1.2,54.2,1.4c20.5,0.1,42.9,0.3,42.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,248.3c0,0-25.2-0.1-44.2,0c-19,0.3-34.7,0.8-39.9,1
	c0,0-8.4,0.1-9.8,0.3c-1.5,0.1-2.9,0.3-4.4,1.5c-1.5,1.2-5.9,5.1-6.9,6.5c-1,1.4-1.9,2.8-1.9,6.9s-0.1,11-0.1,25.5
	c0,14.4,0.3,25.7,0.4,28.3c0,2.6-0.1,4.3,1.6,6.5c1.9,2.1,5.5,5.5,7.4,7.2s2.9,1.8,6.2,1.9c3.3,0.1,29.6,0.7,45.7,0.8
	c16.2,0.1,45.9,0.3,45.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,253.6c0,0-28.3,0.1-44.8,0.3c-16.4,0.1-45.1,1.2-47.5,1.2
	c-2.3,0-2.3,0.3-3.4,1c-1.1,0.7-3.7,3.2-4.5,4c-0.7,0.8-1,1.9-1,3.7c0,1.8-0.3,17.7-0.1,27.2c0,9.5,0.5,25.2,0.5,27.2
	c0,1.5,0.3,2.1,1,2.9c0.7,0.8,3.3,3.4,4.4,4.3c1.1,0.7,1.9,1.6,4.7,1.6c2.8,0,34.5,1.2,52.1,1.4c17.6,0.1,38.5,0.1,38.5,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375,326.9l-29.6-0.3v-1.1c0,0,5.9,0,6.3,0.1c0.4,0,0.4-0.5,0.4-1v-4.7
	c0-0.5-0.7-0.5-1.5-0.4c-0.8,0-19.1-0.3-19.7-0.3s-1.1-0.3-1.4-0.4c-0.3-0.1-0.8-0.7-1.1-1.1c-0.3-0.3-0.4-0.4-0.4-1.2
	c0-1-0.5-24.5-0.5-31c0-6.5,0.3-18.4,0.3-19.5s0.4-1.5,0.7-1.8c0.3-0.3,0.4-0.4,0.8-0.7c0.3-0.3,1.1-0.1,1.1-0.1s20.5-0.1,21-0.1
	s0.8-0.4,0.8-0.8c0-0.3,0.1-4.4,0.1-5c0-0.5-0.4-0.5-0.4-0.5h-6.2V255 M378.3,326.9l26.7,0.1 M408.1,327.1h13.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M392.6,297.2c-3.9,0-49.9-0.3-53-0.3c-3.2,0-5.6,0.3-6.9,0.7
	c-1.2,0.6-2.6,1.1-2.6,3.2c0,2.2,0.1,11,0.1,12.2c0,1.2,1,2.8,2.5,3c1.5,0.4,4.7,1,8.9,1.1c4.3,0.1,5,0.1,13.3,0.3
	c8.4,0.1,25.2,0.5,36.2,0.5h30.1 M392.9,285.5l-1.6,1.2c-0.5,0.4-0.4,1.5-0.4,1.5s-0.7,0.4-0.8,1.1c-0.1,0.7,0.3,1,0.3,1
	s-0.5,0.3-0.5,1s0.7,1.1,0.7,1.1s-0.5,0.4-0.3,1.1c0.1,0.7,0.8,1,0.8,1s-0.3,0.8,0.3,1.4c0.5,0.5,1.6,1.4,1.6,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.4,264.4c0,0-30.3,0.3-41.5,0.3c-11.1,0-27.1,0.3-32.9,0.4
	s-9.1,0.4-10.5,0.5c-1.4,0-3,0.5-4.1,1c-1.1,0.4-2.1,1.2-2.1,2.9c0,1.8-0.1,10.7-0.1,12.1c0,1.2,0.7,3.4,2.2,3.7
	c1.5,0.3,5.1,0.7,7.3,0.7c2.2,0,49-0.3,53.1-0.3 M405,318.1v9.4l0.7,0.4h1.8l0.7-0.4V318 M375.1,318v9.2l0.7,0.4h1.6l0.7-0.4V318
	 M377.9,285.7v11.4 M375.5,285.7V297"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375.7,264.6v-9.9h2.3v9.9 M407.7,264.5v-10h-2.3v9.9 M348.6,285.7v11.4
	 M346.2,285.7V297 M352,256.9c0,0-4.3-0.1-4.3,1.4v3.4c0,1.2,4,1.4,4,1.4 M351.6,319.5c0,0-4-0.1-4,1.4v3.4c0,1.2,4,1.4,4,1.4
	 M260.8,251.3c0,0,0.7,2.1,4,2.2c2.9,0.1,35.6,0,37.4,0c1.9,0,2.9-0.8,3.6-2.1 M334.7,285.4h10c1.5,0,2.8-1.2,2.8-2.8v-14.3
	c0-1.5-1.2-2.8-2.8-2.8h-10 M335.5,316.8h9.5c1.4,0,2.6-1.2,2.6-2.8v-14.3c0-1.5-1.1-2.8-2.6-2.8h-9.5 M391.2,297.2v-0.7h-2.3v0.7
	 M386.6,297.2v-0.8h-2.2v0.8 M382.2,297.2v-0.8H380v0.8 M377.9,297.2v-0.8h-2.3v0.8 M373.2,297.1v-0.7H371v0.7 M368.8,297.1v-0.7
	h-2.3v0.7 M364.4,297.1v-0.8h-2.3v0.8 M359.9,297v-0.7h-2.3v0.7 M355.3,297v-0.8h-2.2v0.8 M350.9,297v-0.7h-2.2v0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M346.1,297v-0.8h-2.2v0.8 M342,297v-0.8h-2.3v0.8 M391.2,285.5v0.8h-2.3
	v-0.8 M386.6,285.7v0.7h-2.2v-0.7 M382.2,285.7v0.7H380v-0.7 M377.9,285.7v0.7h-2.3v-0.7 M373.2,285.7v0.7H371v-0.7 M368.8,285.7
	v0.7h-2.3v-0.7 M364.4,285.7v0.8h-2.3v-0.8 M359.9,285.8v0.7h-2.3v-0.7 M355.3,285.8v0.7h-2.2v-0.7 M350.9,285.8v0.7h-2.2v-0.7
	 M346.1,285.8v0.7h-2.2v-0.7 M342,285.8v0.8h-2.3v-0.8 M421.2,276.7c-12.8,0-23.8,3.7-28.5,8.8 M392.9,297.2
	c4.8,5.1,15.7,8.8,28.3,8.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,279.5c-16.1,0-29.3,5.4-29.3,11.8c0,6.6,13.1,11.8,29.3,11.8
	 M345.8,263.2v1.8 M345.4,319.5v-2.2 M345.8,256.9l-15.8,0.4c0,0-1.6-0.1-3,0.7c-1.2,0.8-3,2.6-3.9,3.4c-0.8,0.8-1,1.6-1,2.9
	c0,1.1-0.1,18.2-0.1,25.3s0.4,27.1,0.4,28.1s0.1,1.9,0.8,2.8c0.7,0.8,2.6,2.9,3.6,3.7c1.1,0.8,2.2,1.2,4.3,1.2c1.9,0,15,0.4,15,0.4
	 M273.5,228.9c0,0,0.5-3.4,1-4.5s0.5-2.2,5-2.2c4.5,0,18.2,0.3,18.2,0.3s2.1-0.7,2.9-1.1c0.8-0.4,1.2-0.1,1.9,0c0.8,0,3,0.4,3,0.4
	 M305,143.7c0,0-3,3.3-8.7,16.8c-5.8,13.6-11.3,28.1-15.1,39.8c-5.1,15.7-5.9,22.7-5.9,22.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M276,218.8c0,0-1-0.6-1.4,0.1c-0.5,0.7-2.9,2.8-4.3,5.2
	c-1.4,2.5-3.6,7.4-3.6,7.4 M277.7,222.1c0,0,0.5-5,2.3-12.4c1.8-7.3,5-17.2,9.6-29.2c4.7-12,9.4-23,10.7-25.3
	c1.4-2.2,5.1-6.2,13.8-7c8.7-0.8,35.4-3.2,60.5-3.9c25.3-0.7,35.2-0.7,46.4-0.7s32.5,0.4,54.2,0.8c21.9,0.6,41.8,2.3,50.2,3.4
	c8.4,1.1,13.8,2.2,17.1,8.8c3.3,6.6,5.9,12.5,10.5,24.8c4.5,12.4,7.6,21.7,9.4,28.5c1.8,6.7,2.1,12.1,2.1,12.1 M521.2,339.7
	c0,0.5,0.3,1.8-1.8,1.8c-2.1,0-31.4,0.8-39.1,0.8s-36,0.4-42.9,0.4h-16.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.1,341.1h1.9l0.7,1.1h2.3l0.8-1.1h4.1l0.8,1.1h2.1l0.8-1.1h4.1
	l0.8,1.1h2.3l0.8-1.1h3.9l1,1.1h2.6l0.8-1.1h3.9l0.8,1h2.3l0.7-1h4l0.8,0.8h2.5l0.8-1l4-0.1l0.5,1.1h2.6l0.7-1.1h4.3l0.7,1h2.3
	l0.8-1c0,0,4.3-0.1,4.1,0c0,0.1,0.5,1,0.5,1h2.5l0.8-1.2h4l0.7,1.1h2.5l0.8-1.1h4l0.8,0.8h2.3l0.8-1.1h4.1l0.8,1l2.3-0.1l0.7-1.1
	l3.9-0.1c0,0,0.8,0,1.2,0.4s0.4,1,0.4,1 M521.2,339.7c0,0,2.5,0,4.3-1c1.9-0.8,5.4-2.8,9.2-7c3.7-4,7.6-4.1,7.6-4.1 M587.9,329.1
	c0,0,0.5,0.3,1.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M545.7,330.2c-1.9,0-3.3-0.5-3.4-1.5c-0.1-1-0.1-2.1,1.4-2.1
	c1.7,0,39.8-0.1,41.1-0.3c1.5,0,3.3,1.4,3.3,2.1c0,0.5,0,1.4-1.7,1.2C584.8,329.7,545.7,330.2,545.7,330.2z M590.4,348.1
	c0,0.1-0.7,10.3-2.8,18.7c-2.1,8.4-4,12.2-5.4,14.2c-1.4,1.9-4,4.3-10.3,4.4c0,0-33.4,0.5-50.6,0.7c-17.2,0.1-47.5,0.1-68,0.1h-32.3
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M436.4,386.1c0,0-1,3.4-1.2,4.3c-0.3,0.8-0.4,1.6-3.3,1.6h-10.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M436.4,386.2c0,0-1,1.2-3,1.2h-12.2 M477.5,386.2c0,0.1-1,3.2-1.2,3.9
	s-0.3,1.9-3.9,1.9h-21.2c0,0-2.6-0.7-3.3-2.3c-0.6-1.6-1-3.4-1-3.4s0.5,1.1,2.8,1.2h25C476.5,387.3,477.5,386.2,477.5,386.2
	L477.5,386.2z M519,386.2c0,0.1-1,3.2-1.2,3.9c-0.3,0.7-0.4,1.9-3.9,1.9h-21.2c0,0-2.6-0.7-3.3-2.3c-0.5-1.6-1-3.4-1-3.4
	s0.5,1.1,2.8,1.2h25C518.2,387.3,519,386.2,519,386.2L519,386.2z M421.1,375.9l94.2-0.3h2.5c2.6,0,5.1-2.1,5.1-4.7l0.5-13.6
	c0-2.6-2.2-4.7-4.8-4.7h-2.2l-95.2,0.1 M569.4,343.3c4.5,0.1,5.5,3.2,5.4,6.1c-0.1,2.9-0.4,10.7-1.4,14.9s-2.5,6.7-7.8,6.7h-19.1
	c-3.7,0-4.7-2.9-4.5-5.2c0,0,0.1-9.4,0.3-11.1c0.1-1.8,0.8-3.4,2.2-5c1.4-1.4,3.3-3.3,4.7-4.4c1.4-1.1,2.6-1.9,5.6-1.9
	C557.7,343.5,569.4,343.3,569.4,343.3L569.4,343.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M508.4,370.4c0,0.7-0.5,1.4-1.4,1.4h-21.5c-0.7,0-1.4-0.5-1.4-1.4v-12
	c0-0.7,0.5-1.4,1.4-1.4h21.5c0.7,0,1.4,0.5,1.4,1.4L508.4,370.4L508.4,370.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M506.4,363.8c0,1.2-1.1,2.3-2.3,2.3h-14.6c-1.2,0-2.3-1.1-2.3-2.3
	s1.1-2.3,2.3-2.3H504C505.3,361.5,506.4,362.6,506.4,363.8L506.4,363.8z M321.1,339.7c0,0.5-0.3,1.8,1.8,1.8s31.4,0.8,39.1,0.8
	s36,0.4,42.9,0.4h16.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.2,341.1h-1.9l-0.8,1.1h-2.3l-0.8-1.1h-4.1l-0.8,1.1h-2.1l-0.8-1.1
	h-4.1l-0.8,1.1h-2.3l-0.8-1.1h-3.9l-1,1.1h-2.6l-0.8-1.1h-3.9l-0.8,1h-2.3l-0.7-1h-4l-0.8,0.8h-2.5l-0.8-1l-4-0.1l-0.5,1.1h-2.6
	l-0.7-1.1h-4.3l-0.7,1h-2.3l-0.8-1c0,0-4.3-0.1-4.1,0c0,0.1-0.5,1-0.5,1h-2.5l-0.8-1.2h-4l-0.7,1.1h-2.5l-0.8-1.1h-4.1l-0.8,0.8
	h-2.3l-0.8-1.1h-4l-0.8,1l-2.3-0.1l-0.7-1.1l-3.9-0.1c0,0-0.8,0-1.2,0.4s-0.4,1-0.4,1 M321.1,339.7c0,0-2.5,0-4.3-1
	c-1.9-0.8-5.4-2.8-9.2-7c-3.7-4-7.6-4.1-7.6-4.1 M254.1,329.1c0,0-0.6,0.3-1.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M296.6,330.2c1.9,0,3.3-0.5,3.4-1.5s0.1-2.1-1.4-2.1
	c-1.6,0-40-0.1-41.4-0.3c-1.5,0-3.3,1.4-3.3,2.1c0,0.5,0,1.4,1.6,1.2C257.2,329.7,296.6,330.2,296.6,330.2L296.6,330.2z
	 M251.6,348.1c0,0.1,0.8,10.3,2.9,18.7c2.1,8.4,4,12.2,5.4,14.2c1.4,1.9,4,4.3,10.3,4.4c0,0,33.4,0.5,50.6,0.7
	c17.2,0.1,47.5,0.1,68,0.1h32.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M405.8,386.1c0,0,1,3.4,1.2,4.3c0.3,0.8,0.4,1.6,3.3,1.6h10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M406,386.2c0,0,1,1.2,3,1.2h12.2 M364.8,386.2c0,0.1,1,3.2,1.2,3.9
	s0.3,1.9,3.9,1.9h21.2c1.6,0,2.6-0.7,3.3-2.3c0.5-1.6,1-3.4,1-3.4s-0.5,1.1-2.8,1.2h-25C365.6,387.3,364.8,386.2,364.8,386.2z
	 M323.3,386.2c0,0.1,1,3.2,1.2,3.9s0.3,1.9,3.9,1.9h21.2c0,0,2.6-0.7,3.3-2.3c0.5-1.6,1-3.4,1-3.4s-0.5,1.1-2.8,1.2h-25
	C324.1,387.3,323.3,386.2,323.3,386.2z M421.1,375.9l-94.2-0.3h-2.5c-2.6,0-5.1-2.1-5.1-4.7l-0.5-13.6c0-2.6,2.2-4.7,4.8-4.7h2.2
	l95.2,0.1 M272.8,343.3c-4.5,0.1-5.5,3.2-5.4,6.1s0.4,10.7,1.4,14.9s2.5,6.7,7.8,6.7h19.1c3.7,0,4.7-2.9,4.5-5.2
	c0,0-0.1-9.4-0.3-11.1s-0.8-3.4-2.2-5c-1.4-1.4-3.3-3.3-4.7-4.4c-1.4-1.1-2.5-1.9-5.6-1.9C284.6,343.5,272.8,343.3,272.8,343.3
	L272.8,343.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M333.7,370.4c0,0.7,0.5,1.4,1.4,1.4h21.5c0.7,0,1.4-0.5,1.4-1.4v-12
	c0-0.7-0.5-1.4-1.4-1.4h-21.5c-0.7,0-1.4,0.5-1.4,1.4L333.7,370.4L333.7,370.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M335.9,363.8c0,1.2,1.1,2.3,2.3,2.3h14.6c1.2,0,2.3-1.1,2.3-2.3
	s-1.1-2.3-2.3-2.3h-14.6C336.9,361.5,335.9,362.6,335.9,363.8z M350.5,243.4l-3.6,0.1v0.6c0,0,0.3,1.2,1.8,1.2s8-0.3,9.1-0.3
	s2.8-0.6,2.8-1.4v-1c0,0-0.1-1-1.5-1c-1.4,0-7.4,0.1-7.7,0.1c-0.3,0-0.4,0-0.4-0.1c0-0.1,0.1-0.3,0.3-0.3c0.3,0,5-0.1,5.1-0.1
	c0.4,0,0.4,0.3,0.4,0.3h3.9V241c0,0-0.3-0.8-1.5-1c-1.4,0-8.5,0.3-9.6,0.3c-1,0-2.3,0.7-2.3,1.2v0.6c0,0-0.1,1.1,1.6,1.1
	c1.6,0,7.7-0.1,7.8-0.1s0.3,0.1,0.3,0.3s0,0.3-0.3,0.3s-5.4,0.1-5.6,0.1C350.5,243.7,350.5,243.4,350.5,243.4L350.5,243.4z
	 M367,239.9h-3.6v3.4c0,0.7,0.8,1.4,1.9,1.4s7.3-0.1,8.1-0.1c1,0,2.2-0.6,2.2-1.7v-3.4h-3.3v2.8c0,0.4-0.1,0.7-0.7,0.7
	s-3.6,0.1-4.1,0.1c-0.4,0-0.8-0.1-0.8-0.8C366.9,241.9,367,239.9,367,239.9L367,239.9z M383.3,243.2v1.4h-3.4v-5c0,0,8.8-0.1,10-0.1
	s1.6,1,1.6,1.2v1.2c0,0.4-1,1.2-2.2,1.2L383.3,243.2L383.3,243.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M383.1,241v0.7h4.8l0.5-0.3c0-0.1-0.1-0.4-0.5-0.4
	C387.7,240.8,383.1,241,383.1,241L383.1,241z M394.8,239.3v5h11v-1.6h-7.3l-0.1-0.3l6.9-0.1v-1.4h-6.7l-0.1-0.3h7.4v-1.4
	L394.8,239.3z M409.9,244.3v-5.1h10.6c1,0,1.5,0.8,1.5,1.2v1c0,0.8-1.6,1-1.6,1s1.5,0,1.5,0.7v1.2h-3.4v-1c0,0,0-0.4-0.4-0.4h-4.5
	v1.2L409.9,244.3L409.9,244.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M413.2,241.4v-0.8h4.8c0.5,0,0.4,0.4,0.4,0.4s0.1,0.4-0.4,0.4
	C417.6,241.5,413.2,241.4,413.2,241.4L413.2,241.4z M434.6,239l11.6,0.1c1,0,2.1,0.7,2.1,1.4v1.9c0,0.7-0.5,1.6-2.8,1.6
	s-10.7-0.1-10.7-0.1L434.6,239L434.6,239z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M438.1,241v1.5h5.8c0.4,0,0.5-0.1,0.5-0.6v-0.7c0-0.3-0.1-0.4-0.4-0.4
	C443.8,241,438.1,241,438.1,241L438.1,241z M451.3,239.3h3.6v2.8c0,0,0.1,0.7,0.7,0.7h4.3c0.4,0,0.7-0.3,0.5-0.7v-2.8h3.7v3.3
	c0,0.7-0.5,1.8-2.2,1.8s-6.3-0.1-8-0.1s-2.5-0.8-2.5-1.6C451.3,241.9,451.3,239.3,451.3,239.3L451.3,239.3z M467.3,239.6v1.6h4.1
	l0.1,3.4h3.4v-3.3h4.1v-1.6L467.3,239.6z M481.2,240.1l3.2-0.1l3.4,1.9l2.5-1.5h4.1l-4.5,2.5v2.2h-3.6V243L481.2,240.1z
	 M433.3,293.5l0.1,0.1v0.1c-0.1,0.4-0.4,0.7-0.7,1.1c-0.3,0.4-0.5,0.5-0.8,0.8c-0.4,0.4-1.1,0.8-1.6,0.5c-0.3-0.1-0.4-0.4-0.4-0.7
	c0-0.8,0.4-1.5,1-2.3c0.8-1,1.6-1.4,2.2-1.2C433.7,292.3,433.6,293,433.3,293.5L433.3,293.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M439.5,287.6C439.5,287.5,439.5,287.5,439.5,287.6
	c-0.5-0.1-1.2-0.1-1.6-0.1c-0.1,0-0.1,0-0.3,0.1c-0.1,0.4-2.5,3.7-2.9,4.4c0,0.1-0.1,0.1-0.1,0c0-0.4-0.4-0.8-1-1.1
	c-0.4-0.1-0.8-0.1-1.2-0.1c-0.7,0.1-1.4,0.5-1.9,1c-0.8,0.7-1.6,1.5-2.6,2.2c-0.5,0.3-1.2,0.7-1.8,0.1c-0.4-0.4-0.4-1.4,0.3-2.1
	c0-0.1,0.1,0,0.1,0c0,0.3,0,0.7,0.3,0.8c0.3,0.3,0.7,0.3,1.1,0.1c0.4-0.3,0.7-0.7,0.7-1.1c0.1-0.7-0.4-1.4-1.1-1.4
	c-0.5,0-1.1,0.1-1.6,0.5l-0.7,0.7c0,0.1-0.1,0.1-0.1,0c0-1-0.4-1.4-1.1-1.5c-0.5,0-1.2,0.3-1.6,0.7c-0.5,0.4-1,1-1.5,1.5
	c0,0-0.1,0-0.1-0.1c0-0.5-0.1-1.2-0.4-1.6c-0.1-0.1-0.3-0.3-0.5-0.1c-0.1,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
	c0.3,1,0.3,1.9-0.1,2.9c-0.3,0.8-1,1.5-1.8,1.8c-0.5,0.1-1.1,0.1-1.4-0.4c-0.4-0.7-0.3-1.6,0.4-2.6c0.5-0.8,1.5-1.2,2.3-1.6
	c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.3-0.1-0.4c0-0.3-0.3-0.3-0.5-0.3c-0.7,0.1-1.2,0.3-1.8,0.5c-1.5,0.8-2.2,2.3-2.5,3
	c-0.1,0.4-0.3,0.5-0.4,0.8l-1,1c0,0-0.1,0.1,0,0.3c0,0.1,0.3,0.5,0.4,0.5h0.3c0.4-0.3,0.8-0.7,1-1c0.1-0.1,0.1,0,0.1,0.1
	c0.3,0.7,0.7,1.2,1.5,1.5c1.4,0.4,2.8-0.1,3.7-1.4c0.7-0.7,0.8-1.2,1.1-1.5c0.4-0.5,1.2-1.6,2.3-2.5c0.4-0.3,0.8-0.5,1-0.4
	c0.1,0.1,0.3,0.4,0,1c-1.1,1.9-2.6,4.1-2.9,4.7c0,0.1,0,0.1,0.1,0.1h1.5c0.1,0,0.1,0,0.1-0.1c0.8-1.2,1.5-2.5,2.3-3.6h0.1
	c0,0.3,0.1,0.7,0.3,1c0.3,0.4,0.7,0.7,1.2,0.7c0.4,0,0.5,0,1-0.3l0.8-0.4c0.1-0.1,0.1,0,0.1,0c-0.1,1,0,2.2,1,2.6
	c1.2,0.5,2.5-0.3,3.2-1c0.1-0.1,0.1,0,0.1,0.1c0,0.3,0.1,0.5,0.4,0.8c0.7,0.5,1.9,0.4,3.2-0.5c0.8-0.7,1.6-1.5,2.3-2.5v-0.1
	l-0.4-0.4H438c-0.8,0.7-1.5,1.7-2.5,2.3c-0.3,0.3-0.8,0.4-1.1,0.1c-0.1-0.1-0.1-0.3,0-0.5C434.6,295.2,439.2,288,439.5,287.6
	L439.5,287.6z M417.5,288.4C417.5,288.6,417.5,288.6,417.5,288.4c0.4,0,0.5-0.1,0.8-0.4c0.1-0.3,0.3-0.5,0-0.5
	c-0.1-0.1-0.3-0.1-0.4,0C417.5,287.7,417.5,288.1,417.5,288.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M405.5,297.6c-1.8,0-3.3-1.1-3.4-2.9c-0.1-1.1,0.1-1.9,0.8-2.8
	c0.5-0.7,1.4-1.2,2.2-1.4c0.3,0,0.5,0,0.7,0.1c0.3,0.3,0.1,0.5,0,0.7c-0.5,0.3-1.2,0.8-1.7,1.5c-0.3,0.5-0.3,1.2,0.1,1.8
	c0.7,1.1,2.6,1.1,4.4-0.5l1.2-1.2c0.8-1,1.1-1.2,2.1-2.8c-0.7,0-1.4,0.3-1.9,0.7c-0.1,0.1-0.4,0.1-0.5,0c-0.1-0.1,0-0.4,0.1-0.5
	c1-0.7,2.1-0.8,3.2-1l0.1-0.1l1.2-1.6l1.5-1.5c0.7-0.5,1.1-0.7,1.5-1c0.1,0,0-0.1,0-0.1c-2.3-0.4-4.8-1-7.3-0.1
	c-1.6,0.5-2.3,1.9-2.1,2.8c0.3,0.7,1.1,0.7,1.8,0.4c0.7-0.3,1.2-0.8,1.6-1.5c0.3-0.4,0.7-0.1,0.4,0.5c-0.7,1.6-2.1,2.9-3.6,3
	c-1.4,0-2.2-1-2.2-2.2c0-2.5,2.8-4.1,5.6-4.3c3.4-0.3,6.7,0.7,10.2,1.1c2.6,0.3,5.2,0.3,7.8-0.3c0.3-0.1,0.5,0.1,0.5,0.4
	c0,0.4-0.4,1-1.2,1.4c-1,0.5-2.1,0.7-3.2,0.7c-2.6,0-5.1-1-7.6-1.5v0.3c-1.4,1-2.6,2.1-3.6,3.6c0,0,0,0.1,0,0c0.7,0,1.4,0,2.1-0.1
	c0.1,0,0.1,0,0.1-0.1c0-0.5,0.1-1.1,0.5-1.5s1-0.4,1.4-0.1c0.4,0.3,0.4,1,0.1,1.4c-0.3,0.4-0.7,0.7-1,0.8c0,0-0.1,0-0.1,0.1
	c0,0.1,0.5,0.4,0.5,0.4v0.3c0,0-0.1,0.1-0.3,0.1c-0.1,0-0.1,0-0.3-0.1c-0.3-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.1-0.1-0.3-0.1
	c-0.7,0-1.9,0.1-2.8,0.3c-0.1,0-0.3,0-0.3,0.1c-1.2,2.1-2.6,4.3-4.1,5.8C408.3,297.2,406.9,297.6,405.5,297.6L405.5,297.6z
	 M272.2,345.5c-1.4,0-3,0.7-3,3s0.8,12.5,1.4,16c0.5,3.3,1.9,5.8,5.2,5.8c3.3-0.1,17.2,0,19.1,0c0,0,3.7,0.1,3.7-3.3
	s-0.1-9.6-0.3-11c0-1.4-0.5-2.6-1.4-3.6s-3.9-4-5.1-5c-1.2-1-2.6-1.7-4.8-1.8C285,345.6,272.2,345.5,272.2,345.5L272.2,345.5z
	 M570.1,345.5c1.4,0,3,0.7,3,3s-0.8,12.5-1.4,16c-0.5,3.3-1.9,5.8-5.2,5.8c-3.3-0.1-17.2,0-19.1,0c0,0-3.7,0.1-3.7-3.3
	s0.1-9.6,0.3-11c0-1.4,0.5-2.6,1.4-3.6s3.9-4,5.1-5s2.6-1.7,4.8-1.8C557.3,345.6,570.1,345.5,570.1,345.5L570.1,345.5z M571.2,353.5
	h-23.8 M571,355.7h-24.8 M570.8,357.9H546 M570.6,360.1h-24.8 M570.2,362.2h-24.5 M271.1,353.5h23.8 M271.3,355.7h24.9 M271.5,357.9
	h24.8 M271.7,360.1h24.8 M272.1,362.2h24.5"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M260.8,251.3c0,0,1.1,2.3,4.4,2.2
		h37.1c0,0,2.2,0.3,3.6-2.1c0,0,1.4-2.3,3.6-4.1c0,0,3.7-3.2,7-5.5c0,0,3.6-2.9,8.8-3.2c0,0,10.6-0.7,22.7-1c0,0,18.3-0.6,37.4-0.8
		l35.6-0.4l31.6,0.4l34.1,0.6c0,0,19.3,0.6,28.5,1.1c0,0,5,0,8.4,1.9c0,0,2.9,1.5,9.4,6.9c0,0,2.8,2.3,3.9,4.5c0,0,0.8,1.5,3.3,1.5
		h32.3h4.7c0,0,2.5,0.3,4.4-1.9c0,0,1-1.5,0.1-3.7l-0.3-0.3c0,0-1.5-5.1-1.8-5.9c-0.4-0.8-0.8-2.1-2.3-4.3l-0.7-2.6
		c0,0-0.3-2.3-3.3-4c0,0-2.5-1.8-8.4-2.6c0,0-4.5-0.7-10.7-1.1l-3.4-0.3c0,0-3.7-0.1-6.2-1.5c0,0-5.1-2.5-11-5c0,0-3.9-1.8-6.5-1.9
		l-1-0.3l-2.8-0.1c0,0-17.7-1.4-35.4-1.9c0,0-36.7-1.4-67.1-1.4c0,0-23.5,0-53.6,1c0,0-27.6,1-51.6,2.8c0,0-1.5,0.1-3,0.4
		c-1.5,0.3-7.4,3-7.4,3l-7,3.2c0,0-3.2,1.6-6.9,1.8l-2.5,0.1c0,0-5.9,0.1-13.3,1.5c0,0-8.5,1.2-9.8,6.6l-0.5,2.3c0,0-1.8,2.1-2.6,5
		l-1.7,5.5h-0.5l-0.4,1.8c0,0-0.3,1,0.5,2.1L260.8,251.3z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M305.5,221.7l-3-0.4
		c0,0-1.4-0.6-2.1,0.1l-2.9,1c0,0-14.6-0.4-19.8-0.3c0,0-0.3-6.9,9.9-36.2c0,0,4.1-11.6,11.6-28.3c0,0,1.5-4.8,7.2-7.4
		c0,0,3-1.7,8.4-2.1l1.1-0.1c0,0,32.9-3.2,59.7-3.7c0,0,20.9-0.6,45.4-0.6c0,0,11.7,0,50.9,0.8c0,0,19.7,0.4,38.9,2.1
		c0,0,12.4,1,19.5,2.2c0,0,5.6,0.8,8.7,3.4c0,0,2.3,1.8,3.9,5c0,0,4.4,8,11.1,26.7c0,0,10.7,28.3,10.7,38.7c0,0-0.7-0.1-1.8-0.1
		c0,0-17.6-0.1-17.9,0l-2.6-0.7c0,0-1.1-0.6-2.3-0.1l-2.2,0.7c0,0-6.6-2.9-8.1-3.3c-1.7-0.4-2.3-0.6-2.3-0.6l-1.2-0.3
		c0,0-28.5-2.1-52.5-2.6c0,0-41.7-1-52.7-1c0,0-17.2,0.1-46.4,0.8c0,0-22.1,0.4-58.9,3c0,0-2.1,0-4.1,0.8L305.5,221.7z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M302.5,259.3
		l-35.9,0.1c0,0-6.2-0.3-7.4,5.6c0,0-1.5,10-1.1,27.4c0,0,0.7,18.4,1.9,27.9c0,0,0,1,0.5,1.4c0,0,0.1,0.3,0.7,0.3l41.8,0.3
		c0,0-0.4-0.8-0.4-1.6c0,0-0.8-16.2-0.8-32.2l0.3-25.4C302.1,262.8,302.1,260.9,302.5,259.3z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M540.1,259.3
		l35.5,0.1c0,0,3.3-0.1,5.4,1.7c0,0,1.7,1.2,2.2,3.6c0,0,1.9,14.2,1.1,28.6c0,0-0.7,23.7-1.9,26.8c0,0,0,1.6-1.7,1.6l-41.4,0.3
		c0,0,0.4-0.6,0.6-2.3c0,0,0.8-16.6,0.7-28.1l-0.3-28.5C540.3,263.2,540.5,261.9,540.1,259.3z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M268.5,385.4
		c0,0-7.4,0-10.5-7.8c0,0-4.4-9.8-6.3-27.5c0,0-1.4-13.8-1-15.4c0,0,0.7-4.4,3.3-5.5l-0.1-0.7c0,0,0-0.4,0.7-1s1.5-1.1,2.3-1.1
		c0.8,0,41.3,0.1,41.3,0.1s1.5-0.1,1.8,0.8c0,0,4.5,0.4,8.4,5.1c0,0,6.9,7,12.7,7c0,0-0.1,1.5,1,1.6c0,0,51.9,2.3,99.2,1.5
		c0,0,64.1-0.1,98.9-1.4c0,0,1.4-0.3,1.2-1.6c0,0,4.1,0.7,10.7-5.4c0,0,1.1-1.1,2.6-2.5c0,0,3.4-4,7.6-4.3c0,0,0-1,1.5-1l41.4-0.3
		c0,0,0.8,0,1.8,0.7s1.4,1.1,1.4,2.1c0,0,2.1,1.1,2.8,3.3c0,0,0.4,1.4,0.4,2.9c0,0,0,4.8-1.1,16.2c0,0-1.7,20.4-8.3,30
		c0,0-2.3,3.3-8.4,3.7c0,0-0.1,18.8-6.3,26.4c0,0-2.9,4.4-10.6,4.7l-17.6,0.4l-0.1,0.6H420.9l-118.9-0.4l-0.1-0.6h-1l-14.4-0.3
		c0,0-10.9,0.8-14.4-9.4C272.1,406.7,269.1,399.8,268.5,385.4z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M308.8,247.8c0,0,3.3-3,7.7-6.2
		c0,0,3.9-3.2,9.6-3.2c0,0,50.2-2.2,94.9-2.2c0,0,69.1,0.6,94.9,2.2c0,0,4.4,0.1,7.4,1.8c0,0,6.1,3.6,11.4,8.8c0,0,1.8,1.7,2.6,4.3
		c0.8,2.6,0.8,6.3,0.8,6.3s0.3,8.9,0.3,26.3c0,0,0.4,17.9-0.4,33.3c0,0-0.1,3.4-1,4.5c0,0-6.3,8.8-9.4,11.3c0,0-2.9,3.2-8.5,3.2
		l-46.8,1.2c0,0-28.8,0.4-51.4,0.4l-50.5-0.4l-47.5-1.2c0,0-5.6,0.3-9.1-3.7c0,0-4.3-4.7-8-9.9c0,0-1.5-1.4-1.7-5
		c0,0-0.7-12.5-0.5-33.6L304,263c0,0,0-7.2,1-9.8C305,253.3,305.9,250.3,308.8,247.8z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup4DrFront);
