import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GREY_04, WHITE_01 } from '../../../../../../constants/colors';
import ConfirmationModal from '../../../../../modals/ConfirmationModal';
import Button from '../../../../../ui/buttons';
import { compose } from '../../../../../../utils/helpers';
import withRouter from '../../../../../../../../hocs/withRouter';

class ConditionFooter extends Component {
  state = {
    open: false,
    title: '',
    message: '',
    btnText: '',
    action: null,
    closeBtnVisible: true,
  };

  handleNext = () => {
    const path = this.props.nextPath;
    this.props.navigate(path);
  };

  handleCancelConfirmation = () => {
    this.props.navigate('/core/condition');
  };

  handleCancel = () => {
    this.setState({
      open: true,
      title: 'Alert',
      message: 'Are you sure you want to cancel the Condition review?',
      btnText: 'Yes',
      action: this.handleCancelConfirmation,
      closeBtnVisible: true,
    });
  };

  render() {
    const { isSaveDisabled = false, handleFinish } = this.props;

    return (
      <div>
        <ConfirmationModal
          open={this.state.open}
          title={this.state.title}
          body={this.state.message}
          iconName="CircledExclamationmark"
          close={() => this.setState({ open: false })}
          action={this.state.action}
          confirmBtnTxt={this.state.btnText}
          closeBtnVisible={this.state.closeBtnVisible}
        />
        <div className="form-footer">
          <Button
            btnText="Cancel"
            height="42px"
            outlined={true}
            onClickHandler={this.handleCancel}
            iconName="Cross"
            iconSize={26}
            marginRight="10px"
          />
          <Button
            btnText="Save"
            height="42px"
            disabled={isSaveDisabled}
            outlined={isSaveDisabled}
            outlinedTextColor={WHITE_01}
            outlinedBgColor={GREY_04}
            outlinedBorderColor={GREY_04}
            outlinedIconColor={WHITE_01}
            iconColor={WHITE_01}
            onClickHandler={handleFinish}
            iconName="Save"
            iconSize={22}
            iconOnRight
            marginLeft="18px"
          />
        </div>
      </div>
    );
  }
}

ConditionFooter.propTypes = {
  isEdit: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  handleFinish: PropTypes.func,
};

export default compose(withRouter)(ConditionFooter);
