import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { find } from 'lodash';

class StatusNav extends Component {
  renderTabs() {
    return (
      <ul className="nav navbar-nav status-links subnav-desktop">
        {this.props.tabs.map((item, index) => {
          const isActive =
            this.props.tab && this.props.tab.section === item.section;

          return (
            <li key={index} className={isActive ? 'active' : ''}>
              <Link
                key={index}
                to={'/status/' + item.section}
                onClick={e => {
                  this.props.handleTabSelect(e, item);
                }}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }

  renderSelect() {
    return (
      <select
        className="subnav-mobile"
        value={this.props.tab ? this.props.tab.section : ''}
        onChange={e => {
          const section = e.target.value;
          const tab = find(this.props.tabs, { section });
          if (tab) this.props.handleTabSelect(e, tab);
        }}
      >
        {this.props.tabs.map((item, index) => (
          <option key={index} value={item.section}>
            {item.name}
          </option>
        ))}
      </select>
    );
  }

  render() {
    return (
      <div className="status-nav">
        <div className="container-fluid">
          <div className="status-title">
            <h4>Buyer Activity</h4>
          </div>
          {this.renderTabs()}
          {this.renderSelect()}
        </div>
      </div>
    );
  }
}

StatusNav.propTypes = {
  tabs: PropTypes.array,
  tab: PropTypes.object,
};

export default StatusNav;
