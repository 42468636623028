import React, { Component } from "react";

export default class ConsignorsNav extends Component {
  render() {
    return (
      <div className="search-nav">
        <div className="container-fluid">
          <div className="search-title">
            <h4>Consignors</h4>
          </div>
        </div>
      </div>
    );
  }
}
