import React, { Component } from 'react';
import PropTypes from 'prop-types';

let styles = {};

class TableTextFilter extends Component {
  render() {
    return (
      <input
        type="text"
        style={styles.input}
        placeholder={this.props.placeholder || ''}
        value={this.props.filter ? this.props.filter.value : ''}
        onChange={event => this.props.onChange(event.target.value)}
      />
    );
  }
}

styles = {
  input: {
    width: '100%',
    paddingTop: 'auto',
    paddingRight: 5,
    paddingBottom: 'auto',
    paddingLeft: 5,
    height: 28,
    borderRadius: 4,
    fontStyle: 'italic',
  },
};

TableTextFilter.defaultProps = {
  updateCurrentData: () => false,
};

TableTextFilter.propTypes = {
  filter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  updateCurrentData: PropTypes.func,
};

export default TableTextFilter;
