import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe2DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M590,217.8c-3.5,0-5.5,2.2-5.7,6.5l-0.8,7c0,2.2,0.7,3.9,2.2,5.2L608,235c0.2-1.5,3.5-2.5,9.7-3
	c0-1.7-0.5-3.9-1.5-6.4c-1.5-4.7-4.7-7-9.4-6.9L590,217.8L590,217.8z M584.1,236.8l-5.7,1.3l-4.2,1c0.2,1.5,0.7,2.5,1.7,3.4
	c0.8,0.7,2,1.2,3.7,1.3l2.7-0.7l2.7-0.8l6-1.8l23.3-2.7c1.7-0.5,2.7-1.7,3.2-3.2l-9.6,0.7l-22.3,1.5H584.1L584.1,236.8z"
          />
          <path
            fill="#FFFFFF"
            d="M571.9,236.9l-6.7,0.5h-45.6c-3.9-0.2-7.7-0.8-11.4-2.2c-10.1,4.9-19.5,11.6-28.2,20.3
	c5.5-9.4,11.2-17.1,17.3-23.5l-8.4,0.5l-4.9-0.2c-15.8-0.8-31.5-1.2-47.3-1.3h-32.4c-15.6,0.2-31.4,0.5-47.1,1.3l-4.9,0.2l-8.4-0.5
	c6,6.4,11.9,14.3,17.3,23.5c-8.7-8.7-18.1-15.4-28.2-20.3c-3.7,1.3-7.5,2-11.4,2.2H276l-6.7-0.5c-2.4,0-4.5,0.3-6.5,1l4.2,1
	c16.1,6.9,33.7,18.4,53.3,34.5l2.2,1.8l1-0.3l4.2-1.2l4.7-0.7c24.8-2.7,52.7-4.2,83.5-4.5h8.9c31,0.3,58.9,2,83.7,4.5l6.4,1.2
	l3.5,1.2l2.2-1.8c19.5-16.1,37.2-27.7,53.3-34.5l4.2-1C576.6,237.3,574.4,236.9,571.9,236.9L571.9,236.9z M503.8,250.3l4.5-4.5
	c2-2.2,4.7-3.4,8.4-3.4h20.1c5.4,0.5,6.9,1.2,4.4,2.3l-12.9,6.5c-3.4,2.2-8.2,3-14.9,2.7C504.6,253.9,501.3,252.5,503.8,250.3
	L503.8,250.3z M300.4,244.8c-2.5-1-1.2-1.8,4.4-2.3H325c3.5,0,6.4,1,8.4,3.4l4.5,4.5c2.5,2.2-0.8,3.5-9.6,3.9
	c-6.5,0.3-11.6-0.7-14.9-2.7L300.4,244.8L300.4,244.8z"
          />
          <path
            fill="#FFFFFF"
            d="M508.3,245.8l-4.5,4.5c-2.5,2.2,0.8,3.5,9.6,3.9c6.5,0.3,11.6-0.7,14.9-2.7l12.9-6.5c2.5-1,1.2-1.8-4.4-2.4
	h-20.1C513,242.5,510.3,243.6,508.3,245.8L508.3,245.8z M305.1,242.5c-5.4,0.5-6.9,1.2-4.4,2.3l12.9,6.5c3.4,2.2,8.2,3,14.9,2.7
	c8.9-0.3,12.1-1.5,9.6-3.9l-4.5-4.5c-2-2.2-4.7-3.4-8.4-3.2L305.1,242.5L305.1,242.5z M565.2,237.4l6.7-0.5
	c-6.2-17.4-12.7-33.5-20-48c-5.4-9.9-10.6-16.4-15.9-20c-2.5-1.3-3.7-1.3-3.9,0c0,0.3,0,0.8,0.2,1.5l3.5,9.6
	c4.5,1.5,8.9,6.2,12.9,14.3C555.1,206.6,560.5,221,565.2,237.4L565.2,237.4z"
          />
          <path
            fill="#FFFFFF"
            d="M419.6,304h1.7l1-1c0.2-0.2,0.5-0.3,0.7-0.3l-0.7,0.7L422,304h0.3l0.2-0.3v-0.2c0.2-0.3,0.3-0.5,0.7-0.8
	l1-0.5h0.2l0.2-0.2h-0.8l-0.5,0.2l-1,0.5c0,0-0.5,0.3-0.7,0.7l-0.2,0.2h-0.3C420.4,303.7,419.9,303.8,419.6,304L419.6,304z
	 M407.5,300h-0.3c-0.2,0.2-0.5,0.5-0.5,1h-1v-0.5c0-0.2,0.2-0.2,0.5-0.2h-0.8v0.2l0.3,0.8l0.3,0.2h1l0.2-0.2l0.7-1L407.5,300
	L407.5,300z M426.5,300H426l-0.5,0.8l-0.5,0.5l-0.3,0.3l-0.8,0.3h0.8l0.2-0.2l0.3-0.2l0.5-0.5 M423.6,301.2l0.3-0.2v0.3l0.5-0.3
	l0.5-0.7v-0.2h-3.5l-0.2,0.3v1.2l-0.2,0.2h1.3L423.6,301.2L423.6,301.2z M414.7,295.1v0.2l0.2,0.2v-0.2l0.2-0.3h-0.2"
          />
          <path
            fill="#FFFFFF"
            d="M414.9,295l0.7-0.3v-0.2l-1.2,0.2l-1,0.5h-0.2l-1.2,0.2c0-0.2,0.2-0.2,0.3-0.3l0.3-0.3l-1,0.2l-0.3,0.2h-0.2
	l-0.3,0.3l2.9,0.2l0.5,0.2h0.2l-0.2-0.2c0,0-0.2-0.2,0-0.2h-0.3L414.9,295L414.9,295z M411.6,295l-0.3,0.2L411.6,295z M418.4,301.2
	l-2,0.3v0.7h0.2V302l0.2-0.2h0.8l0.5,0.2v0.2l-0.3,0.3l-0.2,0.2l-0.2,0.2v0.3l-0.2,0.2l-0.2,0.2h0.3l0.2-0.2l0.2-0.2
	c0.2-0.2,0.3-0.2,0.7-0.3h0.7l0.5-0.2l0.2-0.2l0.2-0.2l0.2-0.3l0.2-0.7v-0.5l-0.2-0.3L418.4,301.2L418.4,301.2z M417.4,303.3
	l0.3-0.2"
          />
          <path
            fill="#FFFFFF"
            d="M416.8,302.2l0.2-0.2h-0.2 M417.1,302.5h0.3l0.2-0.2l-0.3-0.2C417.1,302.2,417.1,302.2,417.1,302.5z
	 M417.1,303l0.2-0.2 M416.6,302.8v0.2V302.8 M418.9,303.3l0.5-0.2h-0.3l-0.7,0.2c-0.3,0.2-0.5,0.3-0.8,0.5v0.2h0.3l0.2-0.2
	c0.2,0,0.3-0.2,0.5-0.2L418.9,303.3L418.9,303.3z M418.6,305l0.2-0.2l0.2-0.2h-0.2l-0.3,0.2l-0.2,0.2l-0.2,0.2h0.2l0.3,0.2l0.3,0.3
	l0.7-0.2l0.3-0.5l-0.2-0.2h-0.2l0.2,0.2v0.2l-0.3,0.2h-0.3v-0.2h-0.2L418.6,305 M418.8,304.7h0.2 M418.1,305.2h0.2H418.1z
	 M419.4,304.9h-0.2H419.4 M409.4,296h-0.2H409.4z M409.4,296H409v0.2l0.2-0.2 M408.4,295.3v0.3h0.2v0.7l0.3-0.2l0.2-0.2h-0.3v-0.7
	 M408.5,295.3v-0.2 M409.4,295.6l0.7-0.2l0.2-0.2l0.5-0.5c-0.5,0.2-0.8,0.3-1,0.5L409.4,295.6L409.4,295.6z M408,296.5v-0.3
	c0-0.2,0-0.2-0.2-0.2C408,296,408,296.1,408,296.5h-0.2c-0.3,0.2-0.5,0.3-0.7,0.5c-0.3,0.2-0.5,0.5-0.5,1l0.3-0.3v-0.3
	C407.2,296.8,407.5,296.6,408,296.5L408,296.5z M405.5,299.7c0,0.2-0.2,0.3-0.2,0.3l0.5-0.7l0.2-0.2l0.3-0.5v0.2L405.5,299.7
	L405.5,299.7z M413.9,302h-0.5c-0.2,0-0.2,0.3-0.2,0.7h1L413.9,302L413.9,302z M412.6,301.3v-0.2l-0.2,0.7v-0.2"
          />
          <path
            fill="#FFFFFF"
            d="M414.2,303.3h0.8l-0.2-0.3l-0.2-0.2l-0.7,0.2h-0.7l-0.2-0.3l0.2-0.5l0.2-0.5l-0.8-0.8v0.7l-0.2,0.3v0.2
	l-0.2,0.8l0.2,0.7v0.2L414.2,303.3L414.2,303.3z M412.7,301.3l0.5,0.3v0.5h-0.3v-0.3L412.7,301.3L412.7,301.3z M412.4,303l0.2-0.2
	l0.5,0.2v0.2l-0.2,0.2h-0.2L412.4,303L412.4,303z"
          />
          <path
            fill="#FFFFFF"
            d="M412.9,301.3h-0.2v0.8h0.3v-0.3l0.2-0.2 M412.4,302.8v0.2l0.3,0.2l0.2-0.3v-0.2L412.4,302.8l0.2-0.1v0.2
	 M423,302.7c-0.2,0-0.5,0-0.7,0.3l-1,1h-1l0.8,0.2h0.8V304l0.3-0.7L423,302.7L423,302.7z M421.8,304l-0.3-0.2l0.3-0.2h0.2L421.8,304
	L421.8,304z"
          />
          <path
            fill="#FFFFFF"
            d="M421.8,304l0.2-0.3h-0.2l-0.3,0.2 M416.6,305l0.2-0.3l0.3-0.2l0.7-0.3l0.2-0.2l0.7-0.3l0.5-0.2h0.2l-0.2,0.2
	H419c-0.2,0.2-0.3,0.2-0.5,0.3h-0.2l-0.3,0.3l-0.3,0.2l-0.5,0.3L416.6,305L416.6,305z M406,300v0.2V300z M407.5,297.8h0.5v0.5
	l-0.2,0.2l-1,0.5v0.2l-0.2,0.3l-0.2,0.2l-0.3,0.2l-0.2,0.3h-0.2l1.2-1.5l0.2-0.3L407.5,297.8L407.5,297.8z M406.9,299.3l0.3-0.3h0.3
	l-0.8,0.7C406.7,299.5,406.9,299.3,406.9,299.3L406.9,299.3z"
          />
          <path
            fill="#FFFFFF"
            d="M416.6,304.9l0.2,0.2l0.5-0.2l0.5-0.3l0.3-0.2l0.3-0.3h0.2c0.2,0,0.3-0.2,0.5-0.3h0.2l0.2-0.2h-0.2l-0.5,0.2
	l-0.7,0.3l-0.2,0.2l-0.7,0.3l-0.3,0.2L416.6,304.9L416.6,304.9z M416.9,304.7l0.3-0.2L416.9,304.7z M419.6,303.5h0.2 M406,300
	l-0.2,0.2l0.2,0.2v-0.2 M407.5,297.8l-0.2,0.3l-0.2,0.3L406,300h0.2l0.2-0.3l0.3-0.2v-0.2l0.3-0.3v-0.2l0.8-0.3l0.2-0.2l0.2-0.3
	v-0.3H408l-0.2,0.2"
          />
          <path
            fill="#FFFFFF"
            d="M407.5,297.8l-0.2,0.3l-0.2,0.3l0.2-0.3 M407,299.1c-0.3,0.2-0.3,0.3-0.3,0.3l0.8-0.7h-0.3L407,299.1
	L407,299.1z M322.3,344.3l-0.5-0.8c-3-4.9-6.4-7-10.1-6.9H305c2.3,0.8,4.4,3,5.9,6.2l0.2,0.3l0.5,1.2L322.3,344.3L322.3,344.3z
	 M313.1,347.8l1.7-0.2h3.5l2.7,0.7l1.2,0.5l0.5,0.3c0.7,0.3,1.2,0.7,1.7,1.2l0.8,0.8l0.8,1.2l0.2,0.3l7.2,12.6
	c0.3,0.7,0.8,1.2,1.3,1.7l0.7,0.7l0.3,0.3h0.2c1.3,0.8,2.9,1.5,4.7,2c2,0.5,4.4,0.8,7.2,1H494c2.7-0.2,5-0.5,7-1s3.5-1.2,4.9-2.2
	h0.2l1.2-1l1.5-1.7l0.3-0.5l0.5-1l7-12.2l0.3-0.5l0.8-0.8c0.5-0.5,1-0.8,1.7-1.2l1.5-0.7l2.5-0.5c1-0.2,2.2-0.2,3.5,0l1.5,0.2
	l1.5-3.5H311.6L313.1,347.8L313.1,347.8z M330.9,346.6c0.3-0.5,1.2-0.8,2.2-0.8h175.3c1,0,1.8,0.3,2.2,0.8c0.2,0.2,0.5,0.5,0.5,0.8
	v1.2l-0.8,2.2l-1.3,3l-1.5,2.5l-3,3.9l-1.2,1.2l-1.7,1.2l-0.5,0.2c-1,0.3-2,0.7-3.4,0.8l-38.2,1.5l-62.9,0.2l-53-1.7
	c-1.2-0.2-2.3-0.5-3.2-0.8l-0.5-0.2l-1-0.5l-1.2-1l-2.7-3.4l-2.3-3.9l-0.2-0.3l-2-5V348l0.2-0.7C330.5,347,330.7,346.8,330.9,346.6
	L330.9,346.6z"
          />
          <path
            fill="#FFFFFF"
            d="M333.1,345.8c-1.2,0-1.8,0.3-2.2,0.8c-0.2,0.2-0.5,0.5-0.5,0.8l-0.2,0.7v0.5l2,5l0.2,0.3l2.3,3.9l2.7,3.4
	l1.2,1l1,0.5l0.5,0.2c1,0.3,2,0.7,3.2,0.8l53,1.7l62.9-0.2l38.2-1.5c1.2-0.2,2.4-0.5,3.4-0.8l0.5-0.2l1.7-1.2l1.2-1.2l3-3.9l1.5-2.5
	l1.3-3l0.8-2.2v-1.2c0-0.3-0.3-0.5-0.5-0.8c-0.3-0.5-1.2-0.8-2.2-0.8L333.1,345.8L333.1,345.8z M334.2,348.5
	c0.2-0.2,0.3-0.3,0.5-0.5l0.3-0.2h169.4l0.3,0.2l0.5,0.5c0.2,0.3,0.2,1,0,1.7l-0.2,0.5v0.2l-0.2,0.5l-0.2,0.5l-0.3,0.7l-0.5,0.8
	l-1,1.7l-0.8,1.2l-0.3,0.5l-0.8,1l-0.3,0.5l-0.7,0.8l-0.3,0.3h-0.2l-0.7,0.5l-0.8,0.5h-0.2l-0.8,0.3l-1.2,0.3l-2.2,0.3h-1.7
	l-2.7,0.2h-0.3l-2.7,0.2h-1.7l-3.7,0.2h-15.6h1h-97.6l-2.9-0.2h-6.9l-3.5-0.2h-1.7l-2.9-0.2h-0.3l-2.7-0.2h-1.7l-2.2-0.3l-1-0.3
	l-1-0.3h-0.2l-0.7-0.3l-0.8-0.7h-0.2l-0.3-0.3l-0.8-0.8l-0.3-0.5l-0.8-1l-0.3-0.5l-0.8-1.2l-1-1.7l-0.5-0.8l-0.3-0.7l-0.2-0.5
	l-0.2-0.5v-0.2l-0.2-0.5C334.1,349.5,334.1,348.8,334.2,348.5L334.2,348.5z M281.9,343.8c0.3-0.3,0.7-0.7,1-1H279l-10.9-0.2
	c-2.2,5.9-3.9,12.9-5,21.1l22.6,0.8c-1.3-0.7-2.7-1.5-3.9-2.7c-2.5-2.5-3.9-5.5-3.9-9.1S279.4,346.3,281.9,343.8L281.9,343.8z
	 M290.8,390.6v1.8l74.8-0.3l-0.8-1.5 M268.5,384.7l2.7-0.2l13.4-1.8c11.6-1.3,24.3-2,38.6-2.5c7.9-0.5,14.8-1.8,20.5-4.2l-87.7,4
	c0.7,1,2,2,4.4,2.7l3.9,1L268.5,384.7L268.5,384.7z M529.8,336.7c-3.7-0.3-7,2-10.1,6.7l-0.5,1H530l0.5-1.2l0.2-0.3
	c1.7-3.2,3.7-5.2,6-6.2H529.8L529.8,336.7z M550.6,392.4v-1.8h-73.8l-1,1.5 M300.4,344.1l1.2,1.5l1.2,2.2l0.2,0.7l0.5,1.7l0.2,2v1.8
	l-0.5,2.9l-0.7,1.7l-1.3,2l-2.5,2.5c-0.8,0.7-1.5,1-2.3,1.5l11.7-0.8l0.8-0.2l3.7-1.2l0.5-0.2l1.7-0.8l1-0.8l0.3-0.5l0.5-1l0.2-0.5
	c0.2-0.5,0.2-1.2,0-1.7l-0.2-0.8l-3.7-8.2l-1.5-3.4l-0.5-1.2l-10.7-0.2h-1L300.4,344.1L300.4,344.1z"
          />
          <path
            fill="#FFFFFF"
            d="M542.2,343.1h-1l-10.7,0.2l-0.5,1.2l-1.5,3.5l-3.7,8.2l-0.2,0.3l-0.2,0.5c-0.2,0.7-0.2,1.2,0,1.7l0.2,0.5
	l0.5,1l0.5,0.5l0.8,0.7l0.8,0.5l1.3,0.7c0.8,0.3,2,0.7,3.2,1l0.5,0.2l1.2,0.2l11.6,0.8c-0.8-0.3-1.7-0.8-2.3-1.5l-1.5-1.3l-1.2-1.2
	l-1-1.5l-0.7-1.5l-0.5-1.8l-0.2-1.7v-2l0.2-1.8l0.5-1.7l0.5-1.2c0.3-0.5,0.5-1.2,1-1.7l0.3-0.5l1.2-1.3L542.2,343.1L542.2,343.1z"
          />
          <path
            fill="#656565"
            d="M597,289.6c-0.5,1.8-0.8,5.4-1.3,10.6c-1,24.5-4.2,50.8-9.9,78.8c0,0.5-0.2,0.8-0.3,1.3c-0.7,1-2,2-4.4,2.7
	l-3.9,1l-4.4,0.8l-2.7-0.2c-4.2-0.7-8.7-1.3-13.4-1.8v7.7h-6.2v21.8c-0.5,6,1.8,9.2,7.2,9.4h32.5c5.4-0.2,7.7-3.4,7.2-9.4V289.6
	v22.6l1.3-21.3C598.4,287.6,597.7,287.1,597,289.6L597,289.6z M244.3,289.6c-0.7-2.5-1.3-2-1.8,1.3l1.3,21.3v-22.6v22.6l0.2,2
	l-0.2-2v100.1c-0.5,6,1.8,9.2,7.2,9.4h32.5c5.4-0.2,7.9-3.4,7.2-9.4v-21.8h-6.2v-7.7l-13.4,1.8l-2.7,0.2L264,384l-3.9-1
	c-2.3-0.7-3.9-1.7-4.4-2.7c-0.3-0.3-0.3-0.8-0.3-1.3c-5.5-28-8.9-54.3-9.9-78.8C245.4,295,244.9,291.4,244.3,289.6L244.3,289.6z
	 M366.4,393.6h13.8l3.9-3h-19.3l0.8,1.5 M456.8,398.9l4.5-5.4l-4-3H384l-3.9,3l4.5,5.4 M457.3,390.5l4,3h13.6l0.8-1.5l1-1.5"
          />
          <path
            fill="none"
            d="M500.8,307l0.8,1.2l0.2,0.2l-0.2,0.2l-0.8,1.2h2h-0.2l-0.8-1.2v-0.3l0.8-1.2h0.2h-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M284.6,382.8c11.6-1.3,24.3-2,38.6-2.5c7.9-0.5,14.8-1.8,20.5-4.2
	 M365.6,392.1l-0.8-1.5 M365.6,392.1l0.8,1.5h13.8 M243.8,351.5v60.9c-0.5,6,1.8,9.2,7.2,9.4h32.5c5.4-0.2,7.9-3.4,7.2-9.4v-20"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M284.6,382.8v7.7h6.2 M290.8,390.5v1.8 M243.8,312.2v39.2 M279.1,342.8
	l-10.9-0.2 M268.2,342.6c-2.2,5.9-3.9,12.9-5,21.1l22.6,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M278.1,334.2c-4.2,0.3-7,2-8.6,5.2l-1.3,3.2 M282.9,342.8H279
	 M279.1,342.8l-1-8.6 M285.8,364.7c-1.3-0.7-2.7-1.5-3.9-2.7c-2.5-2.5-3.9-5.5-3.9-9.1s1.3-6.5,3.9-9.1l1-1 M282.9,342.8l4,0.2
	 M243.8,312.2v-22.6 M244,314.2l-0.2-2 M255.6,379c-5.5-28-8.9-54.3-9.9-78.8c-0.5-5.2-1-8.7-1.3-10.6c-0.7-2.5-1.3-2-1.8,1.3
	l1.3,21.3 M290.8,392.4l74.8-0.3 M296.3,364.6c-3.2,0.2-6.7,0.2-10.6,0 M343.8,376.1l-87.7,4.2 M277.9,278.7l-5.4-1l-3.9-0.7
	l-10.7-1.8c-1.7-0.5-2.9-0.5-3.7-0.3c-1.2,0.3-1.7,1.3-1.5,3 M364.8,390.6h-74 M295.3,342.9h-8.4 M527.3,280.2l-1.7-1.2l-3.5-2.2
	l-1-0.5v-0.2l-2.2-0.8 M574.2,238.9c-15.9,6.9-33.7,18.4-53.3,34.5l-2.2,1.8 M311.9,282.2l-27.5-2.3l-3.9-0.7l-2.7-0.5 M322.3,344.3
	h-10.7 M335.6,367.9l-13.2,4c-11.4,3.4-26,4.5-43.8,3.5h-0.8c-8.4-0.8-15.6,0.5-22.1,3.7 M506,367.9c-1.3,1-3,1.7-5,2.2
	s-4.4,0.8-7,1H347.7c-2.7-0.2-5.2-0.5-7.2-1c-1.8-0.5-3.5-1.2-4.7-2h-0.2 M334.7,348l0.3-0.2h169.4l0.3,0.2l0.5,0.5
	c0.2,0.3,0.2,1,0,1.7l-0.2,0.5v0.2l-0.2,0.5l-0.2,0.5l-0.3,0.7l-0.5,0.8l-1,1.7l-0.8,1.2l-0.3,0.5l-0.8,1l-0.3,0.5l-0.7,0.8
	l-0.3,0.3h-0.2l-0.7,0.5l-0.8,0.5h-0.2l-0.8,0.3l-1.2,0.3l-2.2,0.3h-1.7l-2.7,0.2h-0.3l-2.7,0.2h-1.7l-3.7,0.2h-15.6h1h-97.6
	l-2.9-0.2h-6.9l-3.5-0.2h-1.7l-2.9-0.2h-0.3l-2.7-0.2h-1.7l-2.2-0.3l-1-0.3l-1-0.3h-0.2l-0.7-0.3l-0.8-0.7h-0.2l-0.3-0.3l-0.8-0.8
	l-0.3-0.5l-0.8-1l-0.3-0.5l-0.8-1.2l-1-1.7l-0.5-0.8l-0.3-0.7l-0.2-0.5l-0.2-0.5v-0.2l-0.2-0.5c-0.2-0.7-0.2-1.3,0-1.7
	C334.4,348.3,334.6,348.1,334.7,348L334.7,348z M296.3,364.6l11.7-0.8l0.8-0.2l3.7-1.2l0.5-0.2l1.7-0.8l1-0.8l0.3-0.5l0.5-1l0.2-0.5
	c0.2-0.5,0.2-1.2,0-1.7l-0.2-0.8l-3.7-8.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298.2,350.6l-0.2-0.5l-0.7-1.5l-1-1.2c-1.5-1.5-3.2-2.2-5.4-2.2
	c-2,0-3.9,0.7-5.4,2.2s-2.2,3.2-2.2,5.4c0,2,0.7,3.9,2.2,5.4s3.2,2.2,5.4,2.2c2,0,3.9-0.7,5.4-2.2c0.7-0.7,1-1.2,1.3-1.8l0.3-0.5
	c0.2-0.5,0.3-1.2,0.5-1.7V353C298.5,352.1,298.3,351.3,298.2,350.6L298.2,350.6z M313.1,347.8l-1.5-3.5 M335.6,367.9l-0.3-0.3
	l-0.7-0.7c-0.5-0.5-1-1-1.3-1.7l-7.2-12.6l-0.2-0.3l-0.8-1.2l-0.8-0.8c-0.5-0.5-1-0.8-1.7-1.2l-0.5-0.3l-1.2-0.5l-2.7-0.7
	c-1-0.2-2.2-0.2-3.5,0l-1.7,0.2 M300.4,343.1l10.7,0.2 M311.1,343.3l-0.2-0.3c-1.7-3.2-3.7-5.2-5.9-6.2 M311.6,344.3l-0.5-1
	 M298,336.2l2.3,6.9 M300.4,343.1h-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M299.4,343.1l1.2,1l1.2,1.5l1.2,2.2l0.2,0.7l0.5,1.7l0.2,2v1.8l-0.5,2.9
	l-0.7,1.7l-1.3,2l-2.5,2.5c-0.8,0.7-1.5,1-2.3,1.5 M295.3,342.9l4,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M286.9,342.9c-1.3,0.5-2.5,1.3-3.7,2.4c-2,2.2-3.2,4.7-3.2,7.5
	c0,3,1,5.5,3.2,7.5c2.2,2.2,4.7,3.2,7.5,3.2c2.3,0,4.4-0.7,6-1.8l1.5-1.3l1.2-1.3l0.3-0.5l0.8-1.7l0.2-0.5l0.3-1l0.2-1.7l-0.2-2.2
	V351l-0.7-2l-0.3-0.8l-1-1.5l-1-1.2l-0.7-0.5l-1.5-1l-1-0.5 M305.1,336.7c-0.5-0.2-1-0.3-1.7-0.5l-24-2h-1.3 M322.3,344.3l-0.5-0.8
	c-3-4.9-6.4-7-10.1-6.9H305 M333.1,345.8h175.3c1,0,1.8,0.3,2.2,0.8c0.2,0.2,0.5,0.5,0.5,0.8v1.2l-0.8,2.2l-1.3,3l-1.5,2.5l-3,3.9
	l-1.2,1.2l-1.7,1.2l-0.5,0.2c-1,0.3-2,0.7-3.2,0.8l-38.2,1.5l-62.9,0.2l-53-1.7c-1.2-0.2-2.3-0.5-3.2-0.8l-0.5-0.2l-1-0.5l-1.2-1
	l-2.7-3.4l-2.3-3.9l-0.2-0.3l-2-5V348l0.2-0.7c0-0.3,0.3-0.5,0.5-0.8C331.2,346.1,332.1,345.8,333.1,345.8L333.1,345.8z M327,297.5
	l-2.9,3 M252.6,278.2l1.3,16.9c0.5,5.4,3.9,8.7,10.1,9.9c10.6,2.3,23.1,4.2,37.6,5.4l10.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M311.4,310.9v1.7l0.2,0.5c0.2,0.7,0.3,1.2,0.7,1.7l0.3,0.5l0.3,0.5
	c0.5,0.7,1,1.2,1.7,1.7l0.7,0.5l1.3,0.7l2.2,0.8l8.2,1.7l17.4,1.5l155.8-0.2l4.2-0.3l9.1-0.8l7.7-1.3l3.9-1.3l0.3-0.2l0.8-0.5
	l0.7-0.5l1.7-1.7l0.7-1.2c0.3-0.5,0.5-1.2,0.7-1.7l0.2-0.5l0.2-0.8v-0.8 M265.6,242.5c13.8,8.4,27.5,18.8,41.4,31l7.4,6.7
	 M527.3,280.2l2.2,2 M311.9,282.2l2.3-2 M252.6,278.2l3.9,13.6c0.5,3.4,3.5,5.4,8.9,6.4l27.3,3.2c2.7,0.5,5.9-0.3,9.2-2.7l3.5-2.9
	l7.7-8.4l0.7-1c0.5-0.7,0.7-1.3,0.8-1.8v-0.5c0-0.8-0.8-1.3-2-1.7l-0.7-0.2 M514.4,297.5l-1.2-1.2l-0.5-0.5l-0.5-0.5l-1.7-1.7
	l-0.3-0.5l-0.5-0.5l-1.7-1.7l-0.3-0.3l-0.2-0.2l-0.5-0.5l-1.8-1.3l-0.5-0.3l-1.2-0.7l-1.3-0.5l-0.8-0.3l-0.5-0.2
	c-1-0.3-2.2-0.5-3.4-0.7h-1.7l-2.9-0.2H491l-3.7-0.3l-1.8-0.2h-0.7l-1.7-0.2 M517.2,300.5l-1.7-1.8l-1.2-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M314.3,280.2l5.2-3.4l3.2-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M322.7,275.3l-2.2-1.8c-19.5-16.1-37.2-27.7-53.3-34.5 M518.9,275.3
	l-3.5-1.2L509,273c-24.8-2.7-52.7-4.2-83.7-4.5h-8.9c-31,0.3-58.9,2-83.5,4.5l-4.7,0.7l-4.2,1.2l-1,0.3 M324.2,300.5l-0.3,0.3
	l-0.5,0.5l-0.3,0.3l-0.3,0.3c-0.3,0.5-0.7,1-1,1.5l-0.3,0.5l-0.3,1c-0.2,0.3-0.2,0.7-0.2,1v1.2c0,0.7,0.3,1.2,0.5,1.7l0.3,0.5
	l0.2,0.3l0.3,0.5c0.3,0.5,0.8,1,1.5,1.3l1.2,0.5l3.2,0.5h185.3l3.7-0.5l0.5-0.2c0.5-0.2,0.8-0.3,1.2-0.5l1.2-0.8l0.3-0.5l0.5-1
	l0.3-0.5c0.3-0.5,0.5-1.2,0.5-1.7v-1l-0.3-1.3l-0.2-0.5l-0.2-0.3l-0.3-0.5l-1.2-1.7l-0.5-0.5l-0.5-0.5l-0.3-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M324.2,300.5h1.7l0.8,0.2h4.2l1,0.2h3.9l1.3,0.2h3.7l1.5,0.2h3.5l1.7,0.2
	h3l2.9,0.2h1.7l3.5,0.2h1.5l3.7,0.2h1.3l1.7,0.2h3.9l2.9,0.2h2.2l3.2,0.2h1.7l3.5,0.2h1.5l3.7,0.2h1.2l3.9,0.2h0.8 M514.4,297.5
	h-52.2l-3.4,0.2h-12.9l-0.3-0.5l-0.5-0.8l-1.2-1.8l-1-1.7l-0.7-1l-0.3-0.5l-0.8-1.3l-0.3-0.3h-39.9l-0.5,0.2c-0.2,0-0.3,0.2-0.3,0.3
	l-1,1.7l-1,1.8l-0.7,1l-0.3,0.5l-0.8,1.3l-0.3,0.5l-0.2,0.3l-0.3,0.5H327"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M370.8,317.6l53,0.5l91.4-1l3.7-0.3l1.7-0.5l0.5-0.3l0.8-0.5l0.7-0.7
	l0.3-0.5l0.8-1.7l0.2-0.5l0.3-1.2l0.2-1v-0.5c0-0.5-0.2-1.2-0.3-1.7l-0.5-1.5l-0.7-1.3l-1-1.3l-12.6-14.4l-1.8-1.5l-1-0.7l-0.5-0.2
	c-1-0.5-1.8-0.8-3-1.2l-4.4-1l-3.2-0.3h-0.2l-6-0.5H489l-28.7-1.8l-23.6-0.7l-42.9,0.3l-9.7,0.3l-33.2,2l-7.9,0.7l-4.4,1l-0.5,0.2
	c-1,0.3-2,0.8-2.9,1.3l-1.7,1.2c-0.5,0.3-0.8,0.7-1.2,1l-0.3,0.3l-12.2,13.9l-1,1.3c-0.3,0.5-0.7,1.2-1,1.7l-0.3,1
	c-0.2,0.5-0.3,1.2-0.3,1.7v0.7l0.2,0.8c0,0.5,0.2,0.8,0.3,1.3l0.2,0.5l0.8,1.5l1,1.2l1,0.7l1.2,0.5c1,0.3,2.2,0.5,3.5,0.5l45.1,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M327,297.5l0.8-0.8l0.3-0.3l0.5-0.5l0.5-0.5l1.3-1.3l0.3-0.3l0.5-0.5
	l0.5-0.5l1.2-1.3l0.3-0.3l0.7-0.7l0.5-0.5c0.3-0.3,0.8-0.7,1.2-1l0.5-0.3l0.8-0.5c0.2-0.2,0.5-0.3,0.8-0.5h0.2
	c0.5-0.2,1-0.3,1.5-0.7l0.5-0.2c1-0.3,2-0.7,3.4-0.8l0.5-0.2h0.3l0.7-0.2l3-0.2h0.7l1-0.2l3.7-0.3l1.7-0.2l1.7-0.2h1l3.4-0.2h1.5
	l2.5-0.2h3l3-0.2h2l3.4-0.2h1.5l3.7-0.2h1.3l3.9-0.2h1.3l3.7-0.2h57.9l3.5,0.2h1.3l3.9,0.2h1.3l3.7,0.2h1.5l3.4,0.2h1.8l3,0.2h3.7
	l1.8,0.2h1.3l3.5,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M311.4,310.9l0.2-1.7c0.2-0.5,0.3-1.2,0.5-1.7l0.3-1
	c0.2-0.5,0.5-1.2,0.8-1.7l0.3-0.8l6.4-8l6.4-8.2l1.8-1.5c0.2-0.2,0.3-0.2,0.3-0.3l3.5-2.2c0.8-0.3,1.7-0.8,2.5-1.2l3.2-1.2l7.4-1.5
	l31.9-2.3h0.2l14.8-0.5l65.6,0.2l20.3,1l19.8,1.7l4,0.7l3.9,1.2l4,1.8l1.8,1l3,2.2l0.7,0.7l10.7,13.8l2,2.5l1.3,2.7l0.8,2.7l0.2,1.5
	v0.2 M333.4,235.4c10.1,4.9,19.5,11.6,28.2,20.3c-5.5-9.4-11.2-17.1-17.3-23.5 M300.4,244.8l12.9,6.5c3.4,2.2,8.2,3,14.9,2.7
	c8.9-0.3,12.1-1.5,9.6-3.9l-4.5-4.5c-2-2.2-4.7-3.4-8.4-3.4h-20.3C299.2,243,297.7,243.8,300.4,244.8L300.4,244.8z M344.1,232.2
	c-3,0.2-5.5,0.8-7.9,2l-3,1.2 M276.2,237.4h45.6c3.9-0.2,7.7-0.8,11.4-2.2 M305.6,180.1l3.5-9.6c0.2-0.7,0.2-1.2,0.2-1.5
	 M255.9,236.6l-22.3-1.5 M276.2,237.4c4.7-16.4,10.2-30.9,16.4-43.3c4-7.9,8.4-12.6,12.9-14.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M309.3,169c-0.2-1.3-1.5-1.3-3.9,0c-5.4,3.5-10.6,10.1-15.9,20
	c-7.2,14.4-13.8,30.5-20,48 M267.2,238.9c-0.2,1.5-0.7,2.7-1.7,3.4 M269.5,236.9l6.7,0.5 M265.6,242.5c-0.8,0.7-2,1.2-3.7,1.3
	l-2.7-0.7 M256.8,242.3l2.5,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M259.3,243c-0.7,1.2,0.7,3,4,5.7l1,1c3.9,3.7,7.9,12.1,12.2,25.3l1.2,3.9
	 M256.8,242.3c-0.7,0.8-1.5,1.8-2,2.9l-15.3,26.5c-1.5,2-2.2,7.2-2,15.6l6.4,64.2 M267.2,238.9l-4.2-0.8 M269.5,236.9
	c-2.4,0-4.5,0.3-6.5,1 M263,238.1l-5.5-1.3l-1.5-0.2 M223.9,232.2c6.2,0.5,9.6,1.5,9.7,3 M233.6,235.1l-9.4-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M255.9,236.6c1.3-1.3,2-3.2,2.2-5.2l-0.8-7c-0.2-4.4-2-6.5-5.7-6.5
	l-16.8,1.3c-4.7-0.2-7.7,2.2-9.4,6.9c-1,2.5-1.3,4.5-1.5,6.4 M223.9,232.2c0,0.8,0,1.7,0.3,2.3 M224.2,234.4
	c0.5,1.7,1.5,2.7,3.2,3.2l23.3,2.7l6,1.8 M508.2,235.4c3.7,1.3,7.5,2,11.4,2.2h45.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M497.4,232.2c-6,6.4-11.9,14.3-17.3,23.5c8.7-8.7,18.1-15.4,28.2-20.3
	 M508.3,245.8c2-2.2,4.7-3.4,8.4-3.4h20.1c5.4,0.5,6.9,1.2,4.4,2.3l-12.9,6.5c-3.4,2.2-8.2,3-14.9,2.7c-8.9-0.3-12.1-1.5-9.6-3.9
	L508.3,245.8L508.3,245.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M497.4,232.2c3,0.2,5.5,0.8,7.9,2l3,1.2 M535.8,180.1
	c4.5,1.5,8.9,6.2,12.9,14.3c6.4,12.4,11.7,26.8,16.4,43.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M571.9,236.9c-6.2-17.4-12.7-33.5-20-48c-5.4-9.9-10.6-16.4-15.9-20
	c-2.5-1.3-3.7-1.3-3.9,0 M535.8,180.1l-3.5-9.6c-0.2-0.7-0.2-1.2-0.2-1.5 M344.1,232.2l8.4,0.5l4.9-0.2c15.8-0.8,31.4-1.2,47.1-1.3
	h32.4c15.8,0.2,31.5,0.5,47.3,1.3l4.9,0.2l8.4-0.5 M305.6,180.1c18.8-4.5,54.7-7,107.7-7.7h15.1c53,0.7,88.7,3.2,107.5,7.7
	 M532.1,169c-13.6-4.4-30.5-7.2-50.6-8.6c-3.9,0-7.7,0.3-11.1,0.7l-19-1l-30.2-0.7l-31.2,0.7l-19,1c-3.5-0.5-7.2-0.7-11.2-0.7
	c-20.3,1.3-37.1,4.2-50.6,8.6 M550.6,392.4v20c-0.5,6,1.8,9.2,7.2,9.4h32.5c5.4-0.2,7.7-3.4,7.2-9.4v-60.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M550.6,390.5h6.2v-7.7 M585.8,379c-6.5-3.2-13.8-4.4-22.1-3.7h-0.8
	c-17.8,1-32.4-0.2-43.8-3.5l-13.2-4 M556.8,382.8c4.7,0.5,9.1,1.2,13.4,1.8l2.7,0.2l4.4-0.8l3.9-1c2.3-0.7,3.9-1.7,4.4-2.7
	 M585.5,380.3c0.2-0.3,0.3-0.8,0.3-1.3 M597.7,351.5l-0.2-39.2 M555.6,364.7l22.6-0.8c-1-8.2-2.7-15.3-5-21.1 M555.8,358.2
	c-1.5,1.5-3.2,2.2-5.4,2.2c-2,0-3.9-0.7-5.4-2.2s-2.2-3.2-2.2-5.4c0-2,0.7-3.9,2.2-5.4s3.2-2.2,5.4-2.2c2,0,3.9,0.7,5.4,2.2
	s2.2,3.2,2.2,5.4S557.3,356.7,555.8,358.2L555.8,358.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M546.2,342.9c-1.2,0.5-2.3,1.3-3.4,2.4l-0.8,1c-0.3,0.3-0.5,0.7-0.8,1
	l-0.3,0.5c-0.5,0.8-0.8,1.8-1,2.9l-0.2,0.5l-0.2,1.7v0.7l0.2,1.8l0.5,1.5c0.2,0.7,0.5,1.2,0.8,1.7l0.3,0.5l1.2,1.3
	c2,2.2,4.7,3.2,7.5,3.2c3,0,5.5-1,7.5-3.2c2-2,3.2-4.5,3.2-7.5s-1-5.5-3.2-7.5c-1.2-1-2.3-1.8-3.7-2.3 M380.2,393.6l4.5,5.4h72.1
	l4.5-5.4 M457.3,390.6l4,3 M384,390.6h73.3 M550.6,392.4v-1.8 M461.4,393.6H475l0.8-1.5 M476.8,390.6l-1,1.5 M475.8,392.1l74.8,0.3
	 M556.8,382.8c-11.6-1.3-24.3-2-38.7-2.5c-7.9-0.5-14.8-1.8-20.5-4.2 M550.6,390.6h-73.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M555.6,364.7c1.3-0.7,2.7-1.5,3.9-2.7c2.5-2.5,3.7-5.5,3.7-9.1
	s-1.2-6.5-3.7-9.1l-1-1 M558.6,342.8h3.7 M562.3,342.8l1.2-8.6 M573.4,342.6l-11.1,0.2 M457.3,390.5h19.5 M573.4,342.6l-1.3-3.2
	c-1.3-3.2-4.2-4.9-8.4-5.2 M563.5,334.2h-1.3l-24,2c-0.5,0-1.2,0.2-1.7,0.5 M597.5,312.2v-22.6 M597.5,312.2l1.3-21.3
	c-0.7-3.4-1.3-3.9-1.8-1.3c-0.5,1.8-0.8,5.4-1.3,10.6c-1,24.5-4.2,50.8-9.9,78.8 M508.2,300.8h2.3l2.9-0.2h1.5l0.7-0.2h1.7
	 M530,310.9l14.1-1c12.7-1.2,24-2.9,33.5-5c6.2-1.2,9.6-4.4,10.1-9.9l1.3-17 M575.9,242.5c-13.8,8.4-27.5,18.8-41.4,31l-7.4,6.7
	 M446.4,302.8h4.4l0.7-0.2h4.5l0.7-0.2h3.5l0.7-0.2h4.4l0.7-0.2h4.2l0.7-0.2h4.4l0.5-0.2h1.3l3.7-0.2h1.5l3.5-0.2h1.7l3.2-0.2
	 M529.4,282.2l27.5-2.3l4-0.7l2.7-0.5 M490.5,301.5l1.3-0.2h3l3.5-0.2h1.5l3.7-0.2h1.3l2.9-0.2 M506,367.9l1.2-1l1.5-1.7l0.3-0.5
	l0.5-1l7-12.2l0.3-0.5l0.8-0.8c0.5-0.5,1-0.8,1.7-1.2l1.5-0.7l2.5-0.5c1-0.2,2.2-0.2,3.5,0l1.5,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M588.8,278.2l-3.9,13.6c-0.5,3.4-3.5,5.4-8.9,6.4l-27.3,3.2
	c-1.5,0.2-3,0.2-4.7-0.3l-0.5-0.2c-1-0.3-1.8-0.8-2.9-1.3l-1.2-0.8l-3.5-2.9l-7.4-7.9l-0.5-0.5l-1-1.5c-0.2-0.5-0.3-0.8-0.5-1.2
	v-0.5c0-0.7,0.7-1.3,1.7-1.5l1.2-0.2 M519.2,344.3H322.3 M542.2,343.1h-1 M545,364.6c-0.8-0.3-1.7-0.8-2.3-1.5l-1.5-1.3l-1.2-1.2
	l-1-1.5l-0.7-1.5l-0.5-1.8l-0.2-1.7v-2l0.2-1.8l0.5-1.7l0.5-1.2c0.3-0.5,0.5-1.2,1-1.7l0.3-0.5l1.2-1.3l0.7-0.7 M588.8,278.2
	c0.2-1.7-0.3-2.7-1.5-3c-0.8-0.3-2.2-0.2-3.7,0.3l-10.7,1.8L569,278l-5.4,1 M607.9,235.1l-22.3,1.5 M497.8,376.1h-154 M364.8,390.5
	H384 M380.2,393.6l3.9-3 M607.9,235.1l9.4-0.7 M545,364.6c3.2,0.2,6.7,0.2,10.6,0 M554.4,342.9l4.2-0.2 M585.5,380.3l-87.7-4.2
	 M543.4,336.2l-2.2,6.9 M617.3,234.4c0.2-0.7,0.3-1.5,0.3-2.3 M617.7,232.2c-6.2,0.5-9.6,1.5-9.7,3 M575.9,242.5
	c-1-0.8-1.5-1.8-1.7-3.4 M574.2,238.9l4.2-0.8 M578.4,238.1c-2-0.7-4.2-1-6.5-1 M563.7,278.7l1.2-3.9c4.4-13.2,8.4-21.6,12.2-25.3
	l0.7-0.7c0.2-0.2,0.3-0.2,0.3-0.3c3.4-2.7,4.7-4.5,4-5.7 M546.2,342.9h8.2 M542.2,343.1l4-0.2 M536.5,336.7h-6.7
	c-3.7-0.3-7,2-10.1,6.7l-0.5,1 M519.2,344.3H530 M530.5,343.3l0.2-0.3c1.7-3.2,3.7-5.2,6-6.2 M530,344.3l0.5-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M528.3,347.8l-3.7,8.2l-0.2,0.3l-0.2,0.5c-0.2,0.7-0.2,1.2,0,1.7l0.2,0.5
	l0.5,1l0.5,0.5l0.8,0.7l0.8,0.5l1.3,0.7c0.8,0.3,2,0.7,3.2,1l0.5,0.2l1.2,0.2l11.6,0.8 M541.2,343.1l-10.7,0.2 M528.3,347.8l1.7-3.5
	 M585.6,236.6c-1.3-1.3-2.2-3.2-2.2-5.2l0.8-7c0.2-4.4,2-6.5,5.7-6.5l16.8,1.3c4.7-0.2,7.7,2.2,9.4,6.9c1,2.5,1.3,4.5,1.5,6.4
	 M442.1,295.8l3,5l-0.3,0.5v0.2l-1.8,5.2h-44.4l-2-5.9l4.7-7.7v-0.3h39.1L442.1,295.8L442.1,295.8z M446.4,302.8h-0.3 M584.8,242.3
	l-2.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M395.1,302.8l0.2,0.3l0.3,1l0.2,0.5l0.5,1.7l0.2,0.7l0.2,0.5l0.2,0.5
	l0.3,0.5l0.7,0.2h45.5c0.2,0,0.5,0,0.7-0.2l0.3-0.5l0.2-0.3l0.2-0.7v-0.2l0.2-0.5l0.7-1.7l0.2-0.5l0.3-0.7l0.2-0.5 M582.1,243
	l-2.7,0.7c-1.5-0.2-2.7-0.7-3.7-1.3 M597.7,351.5l6.4-64.2c0.2-8.6-0.5-13.8-2-15.6L587,245.1c-0.7-1-1.3-2-2-2.9 M584.8,242.3
	l6-1.8l23.3-2.7c1.7-0.5,2.7-1.7,3.2-3.2 M565.2,237.4l6.7-0.5 M585.6,236.6l-1.5,0.2l-5.7,1.3 M395.3,302.8h-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M344.3,322.8l-17.4-1.5l-8.2-1.7
	c0,0-12.1-2.7-4.9-15.8l12.7-16.3c0,0,5.2-6,18.8-7.7c0,0,38.7-3.2,46.8-2.9c0,0,64.6,0.2,65.6,0.2c0,0,20.3,0.7,39.7,2.9
	c0,0,10.6,1,17.8,7.7l12.7,16.3c0,0,8.2,12.9-6.7,16.3c0,0-7.4,1.7-21,2.5H344.3L344.3,322.8z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M276.2,237.4h46.5
		c0,0,5.7-0.3,11.9-2.7c0,0,5.9-3,10.6-2.7s7.9,0.5,9.6,0.3c1.7,0,70.3-3.4,132.2,0c0,0,1.7,0.2,4.5-0.2c2.9-0.3,7.4-1,12.4,1
		s7.7,3.4,15.8,4h45.6l6.7-0.5c0,0,4.2-0.2,6.2,1.2l-3.9,0.8c0,0,0,2.5,1.7,3.4c0,0,1.5,1,3.7,1.3l2.7-0.7c0,0,1.2,1.8-4,5.7
		c0,0-5.9,2.9-14.6,30c0,0-7.9,1.5-34,3.5c0,0-6-6.2-14.3-8.2l-6.7-1.2c0,0-87.7-10.6-180.6,0.7l-4.2,1.2l-4.4,1.8l-5.2,3.5l-2.3,2
		l-27.2-2.3l-6.9-1.2c0,0-5.4-20.6-13.2-28.8c0,0-6.2-4.9-5.5-6.7l2.9,0.7c0,0,5-0.2,5.4-4.9l-4.2-1c0,0,4-1.5,7.9-1L276.2,237.4z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M276.2,237.4H323
		c0,0,7.7-1,11.6-2.7c0,0,7.2-3.9,12.9-2.5l5.5,0.3c0,0,71.9-3.2,136,0l2.7-0.2c0,0,6.2-1.5,12.2,1c0,0,6.7,3.7,15.8,4h45.4
		c0,0-14.6-53.7-29.3-57.4c0,0-34.9-7.7-113-7.7c-78.2,0-112.7,6.4-117.2,7.7c0,0-4.5,1.2-9.4,8.2
		C291.5,195.2,282.8,215.3,276.2,237.4z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M244.2,288.9l2.2-5.9
		l6.2-4.9l1.3,17.8c0,0,0.3,6.4,8.7,8.7c8.4,2.3,34.4,5.7,48.8,6.5c0,0-0.5,6.2,7.5,8.6l7.9,1.7l17.3,1.5l155.5-0.2
		c0,0,14.8-0.8,23-3c0,0,7.9-1.7,7.5-8.9c0,0,33.7-2.2,47.1-5.9c0,0,11.1-0.8,10.7-11.9l1.2-15.1l5.5,4.7l2.7,6.9
		c0,0-1.2,5-1.3,11.4c-0.3,6.4-1.7,36.9-9.9,78c0,0,0.2,2.3-3.9,3.7s-9.1,2-9.1,2l-2.7-0.2c0,0-24.8-3.7-44.4-4c0,0-17.9-0.2-27-4.4
		H343.3c0,0-2,2.3-19.1,4.2c0,0-25.7,0-52.5,4.2l-2.7,0.2c0,0-10.6-1.2-12.6-4.5c0,0-0.3-0.8-0.3-1.5c0,0-8.4-41.8-9.9-78.7
		C245.7,300.2,245,292.9,244.2,288.9z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M253.1,279.5
		c0,0-1.2-3.4,0.7-4.4c0,0,1-1,4.2,0l26.8,4.5l27.2,2.3c0,0,2.7,0,2.7,1.8c0.2,1.7-1.5,3.4-1.5,3.4l-7.9,8.4l-3.7,2.9l-3,1.7
		c0,0-2,1.5-7.2,0.8s-25.3-3-25.3-3s-8.9-0.7-9.9-6.2L253.1,279.5z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M536.2,295.8
		l-7.7-8.4c0,0-2-2.2-1.3-4c0,0,0.3-0.8,2.5-1.2s12.1-1,12.1-1l15.3-1.3l26.8-4.5c0,0,2.9-1.2,4.5,0.2c1.7,1.3,0,5.2,0,5.2l-3,11.1
		c0,0-0.3,2-1.8,3.5c-1.7,1.5-4.9,2.3-6.4,2.7l-27.8,3.4c0,0-3.7,0.8-8.4-2.2L536.2,295.8z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M344.3,322.6l-17.4-1.5l-8.2-1.7
		c0,0-12.1-2.7-4.9-15.8l12.7-16.3c0,0,5.2-6,18.8-7.7c0,0,38.7-3.2,46.8-2.9c0,0,64.6,0.2,65.6,0.2c0,0,20.3,0.7,39.7,2.9
		c0,0,10.6,1,17.8,7.7l12.7,16.3c0,0,8.2,12.9-6.7,16.3c0,0-7.4,1.7-21,2.5H344.3L344.3,322.6z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe2DrFront);
