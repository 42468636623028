const styles = {
  buttonGroup: {
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
  },
  counterButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    width: '100%',
  },
  raiseButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    width: '100%',
  },
  button: {
    backgroundColor: 'gray',
    border: 'none',
    borderRadius: 2,
    boxShadow: 'none',
    color: 'white',
    margin: 2,
    padding: 2,
    width: '100%',
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `sans-serif, 'Open Sans', helvetica`,
    fontSize: '1em',
    fontWeight: 'normal',
  },
};

export default styles;
