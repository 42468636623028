import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Select from 'react-select';
import { loadAuthorizedSellerEvents,loadEventList, loadEvents, loadListItem, loadSellerInventory } from '../../actions';
import { getConfig } from '../../utils/helpers';
import { find } from 'lodash';

const styles = {
  customStyles: {
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      overflow: 'visible', // allows dropdown to show up
      width: 650,
      minHeight: 300,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'auto',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  dropdownStyle: {
    control: base => ({
      ...base,
      border: '1px solid #E8E8E8',
      boxShadow: 0,
      '&:hover': {
        border: '1px solid #E8E8E8',
      },
    }),
    singleValue: base => ({
      ...base,
      color: '#787878',
    }),
    option: base => ({
      ...base,
      cursor: 'pointer',
      color: '#787878',
      borderBottom: '1px solid #EAEAEA',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
      padding: 10,
    }),
  },
};

class ListVehicleModal extends Component {
  state = {
    eventId: null,
    rerun: null,
    accountId: null,
  };
  /* Developer Note:  these Core modals 'mount' in React on app load.  State lifecyle should be set/unset in onOpen/onClose methods */
  onOpen = () => {
    const {accountList=[], accountId: userAccountId} = this.props.userProfile.user;
    /* TODO - cleanup
       accountId will be included in AWG response within the same scheduled Release.  
       Adding fallback value just in case. 
    */
    const {accountId, sellerName, sellerNumber} = this.props.item;
    const itemAccountId = accountList.find((acct) => {
      return (sellerName === acct.accountName && sellerNumber === acct.accountNumber)
    });
    const id = accountId || itemAccountId || userAccountId || '';
    this.props.loadEventList();
    this.props.loadEvents(getConfig('marketplaceId'));
    this.props.loadAuthorizedSellerEvents(id)
  };

  onClose = () => {
    this.setState(
      {
        eventId: null,
        rerun: null,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  listVehicleAction = () => {
    this.props
      .loadListItem(
        getConfig('marketplaceId'),
        this.props.item.itemId,
        this.state.eventId,
        this.state.rerun || 0
      )
      .then(response => {
        if (response.error || response.response.wsStatus === 'Error') {
          throw new Error(response.error || response.response.wsMessage);
        }

        toastr.success('Success', response.response.wsMessage);
        this.props.loadData();
        this.onClose();
      })
      .catch(err => {
        toastr.error('Error', err.message);
      });
  };

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>List Vehicle</span>
        </div>
        <button type="button" className="close" onClick={this.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderYMM() {
    const { item } = this.props;
    const YMM = `${item.vehicleYear} ${item.make} ${item.model}`;
    return (
      <div>
        <div style={{ fontSize: 18 }}>{YMM}</div>
        <div>{item.vin}</div>
      </div>
    );
  }

  renderEventSelect() {
    const { authorizedSellerEvents, eventList, events, unauthorizedEventIds } = this.props;
    /* TODO - cleanup
      2024.08.08 - MP-1337 -- replacing with core API 'eventList' data 
      2024.08.20 - MP-1455 -- replace core API call with new backend call
    */
    /* 
      const options = (eventList || [])
      .filter(event => {
        const eventListEvent = find(events.eventList, { eventId: event.id });
        const statusText = eventListEvent ? eventListEvent.statusText : '';
        const statusOk = ['Event in Progress', 'Upcoming Event'].includes(
          statusText
        );
        const isAuth = !unauthorizedEventIds.includes(event.id);
        return isAuth && statusOk;
      })
      .map(event => {
        return { label: event.type, value: event.id };
      }); */
    const authorizedSellerOptions = authorizedSellerEvents.events?.map(evt => {return {label: evt.name, value: evt.eventId}} );
    return (
      <div style={{ width: '100%', paddingTop: 10 }}>
        <h4 style={{ marginBottom: 5 }}>Event</h4>
        <Select
          styles={styles.dropdownStyle}
          placeholder="-- Select an Event --"
          options={authorizedSellerOptions || []}
          onChange={option => {
            this.setState({ eventId: option.value });
          }}
          captureMenuScroll
        />
      </div>
    );
  }

  renderRerunSelect() {
    const options = [
      { label: 'Do not Rerun', value: 0 },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '8', value: 9 },
      { label: '10', value: 10 },
      { label: 'Until it sells', value: 99 },
    ];

    return (
      <div style={{ width: '100%', paddingTop: 10 }}>
        <h4 style={{ marginBottom: 5 }}>Rerun</h4>
        <Select
          styles={styles.dropdownStyle}
          placeholder="-- Select Times to Rerun --"
          options={options}
          onChange={option => {
            this.setState({ rerun: option.value });
          }}
          captureMenuScroll
        />
      </div>
    );
  }

  renderFooter() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          className="btn btn-primary button-cancel"
          style={{ minWidth: 100 }}
          data-dismiss="modal"
          onClick={this.onClose}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary button-action"
          style={{ marginLeft: 10, minWidth: 100 }}
          type="button"
          disabled={this.state.eventId === null || this.state.rerun === null}
          onClick={this.listVehicleAction}
        >
          Create
        </button>
      </div>
    );
  }

  render() {
    if (!this.props.item || !this.props.events) return null;

    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="List Vehicle Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div style={{ ...styles.container, height: 'auto' }}>
          {this.renderHeader()}
          {this.renderDivider()}
          {this.renderYMM()}
          {this.renderEventSelect()}
          {this.renderRerunSelect()}
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

ListVehicleModal.propTypes = {
  item: object,
  loadData: func,
};

const mapStateToProps = state => {
  const {eventList} = state.core;
  const { authorizedSellerEvents, events, unauthorizedEventIds, userProfile, } = state.entities;
  return { authorizedSellerEvents, eventList, events, unauthorizedEventIds, userProfile, };
};

export default connect(mapStateToProps, {
  loadAuthorizedSellerEvents,
  loadEventList,
  loadEvents,
  loadListItem,
  loadSellerInventory,
})(ListVehicleModal);
