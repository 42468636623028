import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import AccordionItem from './AccordionItem';
import '../../../styles/form/accordion.css';
// import { compose } from '../../../utils/helpers';
// import withRouter from '../../../../../hocs/withRouter';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

/* class Accordion extends Component {
  render() {
    return (
      <div>
        {this.props.coreSections.map((section, index) => (
          <AccordionItem key={section.section + index} section={section} />
        ))}
      </div>
    );
  }
} */

const Accordion = (props) => {
  const navigate = useNavigate();
  const {item, coreSections} = useSelector((state) => state.core)
  return (
    <div>
      {coreSections.map((section, index) => (
        <AccordionItem navigate={navigate} key={section.section + index} section={section} />
      ))}
    </div>
  )
}

/* Accordion.propTypes = {
  item: PropTypes.object.isRequired,
}; */

const mapStateToProps = state => {
  const { item, coreSections } = state.core;
  return { item, coreSections };
};

export default Accordion;

// export default connect(mapStateToProps)(Accordion);

/* export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
)(Accordion)); */