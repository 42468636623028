import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as momentTZ from 'moment-timezone';
import jstz from 'jstz';
import { find } from 'lodash';
import { Button, Icon } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { toastr } from 'react-redux-toastr';
import {
  loadRemoveWishlistItem,
  loadUpdateWishlistItem,
  loadUserSelectedDistance,
  loadUserSelectedLocation,
  loadWishlistItems,
  loadWishlistSearch,
} from '../../actions';
import { confirm } from '../common/confirm/CallConfirm';
import {
  commafy,
  findDistanceUnits,
  getConfig,
  makeTitle,
  removeParamFromURL,
} from '../../utils/helpers';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 250,
    width: 500,
    margin: 10,
    borderBottom: '2px solid #ced6db',
    borderRadius: 3,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 18,
    padding: 5,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    margin: 5,
    width: 30,
    height: 30,
  },
  icon: {
    color: 'white',
    margin: 0,
    opacity: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    padding: '5px 10px',
    height: '100%',
    overflow: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 10px',
  },
};

class WishlistCard extends Component {
  state = {
    currentCount: null,
  };

  componentDidMount() {
    this.loadCurrentSearchResults();
  }

  loadCurrentSearchResults = () => {
    const { geo, postFilter } = this.props.data.value || {};
    const { userSelectedDistance } = geo || {};
    const { lat, lng } = geo?.userSelectedLocation?.location || {};
    const hasEnoughDataForFetch = !!(geo && userSelectedDistance && lat && lng);

    if (hasEnoughDataForFetch) {
      const units = findDistanceUnits(this.props.marketplaceFeatures);
      const filter = [
        {
          geo_distance: {
            distance: userSelectedDistance + units,
            coordinates: { lat, lon: lng },
          },
        },
      ];

      this.props
        .loadWishlistSearch(
          this.props.listingStatusIds,
          this.props.unauthorizedEventIds,
          postFilter,
          filter
        )
        .then(({ response }) => {
          const currentCount = response?.hits?.total?.value;
          if (currentCount || currentCount === 0) {
            this.setState({ currentCount });
            this.renderFooter();
          }
        });
    }
  };

  handleCardClick = e => {
    // 1. Create a path for Search & Wishlist Modal with the filters + wishlistId in the query string  (does not need location/distance info)
    // - Search needs the filters
    // - Wishlist Modal needs the wishlistId to pre-populate the Name and for the "hidden upate" or whatever I am calling it.
    // 2. Get location/distance info and put it in redux.
    // - Search needs the location/distance info for the Distance filter
    // - Wishlist Modal needs the location/distance info for the "hidden upate" or whatever I am calling it
    const { data } = this.props;
    const { query, geo } = data.value;

    // path
    let path = `/search?${decodeURIComponent(query)}`;
    path = removeParamFromURL('distance', path); // we don't want distance here we want it in redux
    path = removeParamFromURL('wishlistId', path);
    path += path.includes('?')
      ? `&wishlistId=${data.id}`
      : `?wishlistId=${data.id}`;

    // location/distance
    if (geo) {
      // new data structure
      const { userSelectedDistance, userSelectedLocation } = geo;
      this.props.loadUserSelectedLocation({
        key: 'wishlist',
        ...userSelectedLocation,
      });
      this.props.loadUserSelectedDistance(userSelectedDistance);
      this.props.navigate(path);
    } else {
      // legacy data structure (we are not honoring location/distance info)
      this.props.loadUserSelectedLocation({});
      this.props.loadUserSelectedDistance('');
      this.props.navigate(path);
    }
  };

  handleDelete = e => {
    e.preventDefault();
    confirm('Are you sure you want to delete this saved search?').then(
      () => {
        const mpId = getConfig('marketplaceId');
        const { data } = this.props;

        this.props
          .loadRemoveWishlistItem(mpId, data.id)
          .then(({ response }) => {
            if (response.wsStatus === 'Success') {
              toastr.success('Success', 'Search Deleted');
              this.props.loadWishlistItems(mpId);
            } else {
              toastr.error('Error', 'Error deleting saved search');
            }
          })
          .catch(error => console.error('handleDelete error', error));
      },
      () => null
    );
  };

  handleRenew = e => {
    e.preventDefault();
    confirm('Renew this search?').then(
      () => {
        const { data } = this.props;

        const _value = JSON.stringify({
          ...data.value,
          query: encodeURIComponent(decodeURIComponent(data.value.query)),
        });

        const mpId = getConfig('marketplaceId');
        this.props
          .loadUpdateWishlistItem(mpId, data.id, data.name, _value)
          .then(({ response }) => {
            if (response.wsStatus === 'Success') {
              toastr.success('Success', 'Search Renewed');
              this.props.loadWishlistItems(mpId);
            } else {
              toastr.error('Error', 'Error renewing saved search');
            }
          })
          .catch(error => console.error('handleRenew error', error));
      },
      () => null
    );
  };

  makeFiltersMap = data => {
    const { query, geo } = data.value;
    const parameters = decodeURIComponent(query).split('&');
    const splitters = ['[min]=', '[max]=', 'distance=', ']=', '='];

    const paramFilters = parameters.reduce((obj, item, index) => {
      const splitter = find(splitters, splitter => item.includes(splitter));
      const [label, val] = item.split(splitter);

      switch (splitter) {
        case '[min]=': {
          return { ...obj, [label]: val };
        }
        case '[max]=': {
          const updated = { ...obj };
          if (val === updated[label]) {
            delete updated[label];
            updated[val] = val;
          } else {
            updated[label] = `${makeTitle(label)}: ${updated[label]} - ${val}`;
          }
          return updated;
        }
        case ']=': {
          return { ...obj, [val]: val };
        }
        case '=': {
          return { ...obj, [label]: `${makeTitle(label)}` };
        }
        default: {
          return obj;
        }
      }
    }, {});

    const geoFilters = {};
    if (geo) {
      const { userSelectedDistance, userSelectedLocation } = geo;
      const units = findDistanceUnits(this.props.marketplaceFeatures);
      const distance = userSelectedDistance
        ? `${commafy(userSelectedDistance)} ${units}`
        : '';
      const { city, state, address } = userSelectedLocation;
      const location = city && state ? `${city} ${state}` : address || '';

      geoFilters.distance =
        distance && location ? `${distance} from ${location}` : '';
    }

    return { ...paramFilters, ...geoFilters };
  };

  determineExpiration = data => {
    const localTimezone = jstz.determine().name();
    const expires = momentTZ
      .tz(data.expires, 'America/Los_Angeles') // server localTimezone
      .tz(localTimezone);

    return expires;
  };

  expiresSoon(data) {
    const localTimezone = jstz.determine().name();
    const expires = this.determineExpiration(data);
    const duration = momentTZ.duration(
      expires.diff(momentTZ.tz(localTimezone))
    );

    const expiresSoon =
      duration.years() <= 0 && duration.months() <= 0 && duration.days() <= 7;

    return expiresSoon;
  }

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    const { data } = this.props;
    const expiresSoon = this.expiresSoon(data);

    return (
      <div style={styles.header}>
        <div style={styles.title}>{data.name}</div>
        <div style={styles.buttons}>
          {expiresSoon && (
            <Button
              data-tip="React-tooltip"
              data-for={`renew-${data.id}`}
              style={{
                ...styles.button,
                backgroundColor: 'green',
              }}
              onClick={this.handleRenew}
            >
              <Icon name="clock" style={styles.icon} />
              <ReactTooltip
                id={`renew-${data.id}`}
                type="dark"
                effect="solid"
                delayShow={1000}
              >
                <span>Renew</span>
              </ReactTooltip>
            </Button>
          )}

          <Button
            data-tip="React-tooltip"
            data-for={`search-${data.id}`}
            style={{
              ...styles.button,
              backgroundColor: 'white',
              border: '1px solid black',
            }}
            onClick={this.handleCardClick}
          >
            <Icon
              name="search"
              style={{
                ...styles.icon,
                color: 'black',
              }}
            />
            <ReactTooltip
              id={`search-${data.id}`}
              type="dark"
              effect="solid"
              delayShow={1000}
            >
              <span>Search</span>
            </ReactTooltip>
          </Button>

          <Button
            data-tip="React-tooltip"
            data-for={`delete-${data.id}`}
            style={{
              ...styles.button,
              backgroundColor: '#fc3f47',
            }}
            onClick={this.handleDelete}
          >
            <Icon name="trash" style={styles.icon} />
            <ReactTooltip
              id={`delete-${data.id}`}
              type="dark"
              effect="solid"
              delayShow={1000}
            >
              <span>Delete</span>
            </ReactTooltip>
          </Button>
        </div>
      </div>
    );
  }

  renderContent() {
    const { data } = this.props;
    const filtersMap = this.makeFiltersMap(data);

    return (
      <div
        className="hide-scroll"
        style={styles.content}
        onClick={this.handleCardClick}
      >
        <div style={{ color: '#888', paddingBottom: 5 }}>
          Filters applied in this search:
        </div>
        <div style={{ minHeight: 60 }}>
          {Object.values(filtersMap).map((value, index) => {
            return (
              <span key={index} className="wishlist badge">
                {decodeURIComponent(value.replace(/_/g, ' '))}
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  renderFooter() {
    const { currentCount } = this.state;
    const { data } = this.props;
    const expiresSoon = this.expiresSoon(data);
    const expirationText = this.determineExpiration(data).format('lll');
    const lastViewed = momentTZ.unix(data.value.lastViewed / 1000);
    const lastViewedText = lastViewed.isValid()
      ? lastViewed.format('lll z')
      : '';

    const lastCount = data.value?.lastCount;
    const lastCountText =
      lastCount || lastCount === 0
        ? `Last Search Results: ${commafy(lastCount)}`
        : '';
    const currentCountText =
      currentCount || currentCount === 0
        ? `Current Search Results ${commafy(currentCount)}`
        : '';

    const hasMore =
      (lastCount || lastCount === 0) &&
      (currentCount || currentCount === 0) &&
      currentCount > lastCount;

    return (
      <div style={styles.footer}>
        <div>
          <div style={{ textAlign: 'left' }}>{lastCountText}</div>
          <div
            style={{ textAlign: 'left', color: hasMore ? 'green' : 'inherit' }}
          >
            {currentCountText}
          </div>
        </div>
        <div>
          {lastViewedText && (
            <div
              style={{
                textAlign: 'right',
                color: expiresSoon ? '#fc3f47' : '#888',
              }}
            >{`Last Viewed: ${lastViewedText}`}</div>
          )}

          <div
            style={{
              textAlign: 'right',
              color: expiresSoon ? '#fc3f47' : '#888',
            }}
          >{`Expires: ${expirationText}`}</div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.data) return null;

    return (
      <div style={styles.container}>
        {this.renderHeader()}
        {this.renderDivider()}
        {this.renderContent()}
        {this.renderDivider()}
        {this.renderFooter()}
      </div>
    );
  }
}

WishlistCard.propTypes = {
  data: PropTypes.object.isRequired, // parsed!
  loadRemoveWishlistItem: PropTypes.func.isRequired,
  loadUpdateWishlistItem: PropTypes.func.isRequired,
  loadUserSelectedLocation: PropTypes.func.isRequired,
  loadWishlistItems: PropTypes.func.isRequired,
  marketplaceFeatures: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    listingStatusIds,
    marketplaceFeatures,
    unauthorizedEventIds,
    userSelectedDistance,
    userSelectedLocation,
  } = state.entities;
  return {
    listingStatusIds,
    marketplaceFeatures,
    unauthorizedEventIds,
    userSelectedDistance,
    userSelectedLocation,
  };
};

export default connect(mapStateToProps, {
  loadRemoveWishlistItem,
  loadUpdateWishlistItem,
  loadUserSelectedDistance,
  loadUserSelectedLocation,
  loadWishlistItems,
  loadWishlistSearch,
})(WishlistCard);
