import React, { Component } from 'react';
import { connect } from 'react-redux';

class CrewCabPickup4DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M616,220.5c-0.1-0.7-0.4-1.4-0.6-2.3c-0.3-0.9-0.6-1.7-1-2.4c-1-1.9-2.3-3.6-4-5c-3.1-2.6-7.5-4.1-12.9-4.8
	c-4-0.6-8.7-0.6-14.1,0h-0.1c-0.7,0.1-1.3,0.3-2,0.4c-5.5,1.4-8.4,5.7-8.4,12.5c0,1,0,2,0.1,3h-8.8c-0.6-1.8-1.1-3.7-1.7-5.4
	c-1.6-4.8-3-9.5-4.5-13.9c-7.5-22.2-14.5-37.7-20.9-46.5c-1-1.4-2-2.8-3.3-4c-0.7-0.7-1.4-1.3-2.3-1.8c-0.6-0.3-1.1-0.7-1.7-0.9
	c-0.3-0.1-0.6-0.1-0.9-0.3c-0.4-0.1-0.9-0.3-1.3-0.3c-1.6-0.4-3.1-0.7-4.7-1c-2.8-0.4-6.3-0.9-10.4-1.3c-0.4,0-0.9-0.1-1.4-0.1
	c-0.1-0.3-0.3-0.4-0.4-0.7c-0.4-0.6-1.1-0.9-1.8-0.9l-1.6-0.1c-0.7,0-1.3,0.1-1.8,0.7l-0.4,0.4c-8.4-0.7-18.8-1.1-30.8-1.6
	c-10.4-0.4-22-0.7-35-0.9c-4-0.1-8-0.1-12.2-0.1h-18.5l-5.7,0.1h-1.8l-17.8,0.4h-0.4c-5.5,0.1-10.8,0.3-15.8,0.4
	c-11.8,0.4-21.7,1-30,1.6h-0.9l-0.4-0.4c-0.6-0.4-1.1-0.7-1.8-0.7l-1.6,0.1c-0.7,0-1.3,0.3-1.8,0.9c-0.1,0.1-0.3,0.4-0.4,0.7
	c-4.5,0.4-8.5,0.9-11.7,1.4c-1.6,0.3-3.1,0.6-4.7,1c-0.4,0.1-0.9,0.3-1.3,0.3c-0.3,0-0.6,0.1-0.9,0.3c-0.6,0.3-1.1,0.6-1.7,0.9
	c-0.9,0.6-1.6,1.1-2.3,1.8c-1.3,1.1-2.4,2.4-3.3,4c-6.4,8.8-13.4,24.3-20.8,46.5c-1.4,4.4-3,9-4.5,13.9c-0.6,1.8-1.1,3.6-1.7,5.4
	h-8.8c0.1-1.1,0.1-2.1,0.1-3c0-6.8-2.8-11.1-8.4-12.5c-0.6-0.1-1.3-0.3-2-0.4h-0.1c-5.4-0.4-10.1-0.4-14.1,0
	c-5.4,0.7-9.8,2.3-12.9,4.8c-1.7,1.4-3,3-4,5c-0.4,0.7-0.7,1.6-1,2.4c-0.3,0.7-0.4,1.4-0.6,2.3c-0.7,3.3-0.4,6.3,0.6,8.5
	c0.4,1,1,1.7,1.6,2.3c0.4,0.4,1,0.9,1.6,1.1c0.7,0.4,1.3,0.7,2.1,1l1.3,0.4c3,0.9,6.8,1.6,11.4,2c3.1,0.3,6.7,0.4,10.5,0.6l15.6,2.1
	c-5.5,8.7-9.4,16.5-11.1,23.6c-0.9,3.3-1.3,6.3-1.3,9.1l-0.4,6.8c0,1-0.1,2-0.1,3c-0.3,5.1-0.4,10.4-0.3,15.6l0.9,18.6
	c1.9,18.5,6,37.5,12.1,57.1c4.1,3.4,9,6.3,14.4,8.2l7.7-13.8l47.8,2.8c-0.3,2.4,1.6,3.6,5.5,3.7l30.1,0.9c0.7,1.3,2,1.8,3.8,2h3.7
	c1.4,0.1,2.3-0.3,2.6-1.6h7.1l-1.3-6.3h58.7l-1.3,6.3h7.1c0.3,1.3,1.1,1.8,2.6,1.6h3.7c1.8,0,3.1-0.7,3.8-2l30.1-0.9
	c4-0.1,5.8-1.4,5.5-3.7l47.8-2.8l7.7,13.8c5.4-2.1,10.1-4.8,14.4-8.2c6.1-19.8,10.2-38.8,12.1-57.1l0.9-18.6
	c0.1-5.3,0-10.4-0.3-15.6c0-1-0.1-2-0.1-3l-0.4-6.8c0-2.8-0.4-6-1.3-9.1c-1.8-7-5.5-14.8-11.1-23.6l15.6-2.1c4,0,7.4-0.3,10.5-0.6
	c4.7-0.4,8.4-1.1,11.4-2c0.4-0.1,0.9-0.3,1.3-0.4c0.7-0.3,1.4-0.7,2.1-1c0.6-0.3,1.1-0.7,1.6-1.1c0.7-0.6,1.1-1.4,1.6-2.3
	C616.5,226.8,616.6,223.9,616,220.5L616,220.5z M266.7,222.1c-0.7,5.4-3.7,9.2-9.2,11.7c-3.1,1.1-6.8,1.4-11.4,0.9
	c-0.4,0-1-0.1-1.4-0.1c-4.1-0.3-7.5-1-10.2-1.7c-0.3-0.1-0.7-0.3-1.1-0.3c-0.7-0.3-1.3-0.6-1.8-1c-0.6-0.3-1-0.6-1.4-1
	c-0.6-0.6-1.1-1.3-1.4-2.1c-1-2.1-1.1-4.7-0.6-7.7c0.1-0.7,0.3-1.3,0.6-2l0.9-2.1c0.9-1.7,2-3.3,3.6-4.4c2.8-2.3,6.7-3.7,11.7-4.3
	c3.7-0.4,7.8-0.4,12.7,0h0.1c0.6,0.1,1.1,0.1,1.7,0.4c5,1.3,7.5,5.1,7.5,11.2C266.8,220.2,266.8,221.1,266.7,222.1L266.7,222.1z
	 M311.1,167.2c1.1-2.3,3.1-3.8,6.4-4.8c1.3-0.4,2.7-0.7,4.3-0.9c1.1-0.1,2.4-0.1,3.7-0.1c25.4-1.8,54.2-2.8,86-3h12.9
	c34.1,0.1,64.8,1.1,92,3.1c1.3,0,2.6,0,3.7,0.1c1.6,0.1,3.1,0.4,4.3,0.9c3.1,1,5.3,2.6,6.4,4.8c5,10.9,9.7,22.7,13.9,35.5
	c3,8.7,5.8,17.9,8.4,27.4H288.7c2.7-9.7,5.4-18.8,8.4-27.4C301.5,190.1,306.2,178.2,311.1,167.2L311.1,167.2z M269.4,313.3
	c-2.4-0.1-3.7-1.7-3.7-4.5l1.3-37.7c0.1-3.4,0.6-6.4,1.3-9c1.8-6.7,6-10.4,11.9-11.1h12.5c-0.1,3.6-0.1,7.2-0.1,11.1
	c-0.1,15.1,0,32,0.6,50.7L269.4,313.3L269.4,313.3z M573.7,262c0.7,2.6,1.1,5.5,1.3,9l1.3,37.7c0,2.8-1.3,4.4-3.7,4.5l-23.7-0.6
	c0.6-18.8,0.7-35.7,0.6-50.7c0-3.8-0.1-7.5-0.3-11.1h12.5C567.7,251.7,571.7,255.4,573.7,262L573.7,262z M613.2,228.3
	c-0.4,0.9-0.9,1.6-1.4,2.1c-0.4,0.4-0.9,0.7-1.4,1s-1.1,0.7-1.8,1c-0.4,0.1-0.7,0.3-1.1,0.3c-2.7,0.9-6.1,1.4-10.2,1.7
	c-0.4,0-1,0.1-1.4,0.1c-4.4,0.6-8.2,0.3-11.4-0.9c-5.4-2.4-8.5-6.3-9.2-11.7c-0.1-1-0.1-1.8-0.1-2.7c0-6.3,2.6-9.9,7.5-11.2l1.7-0.4
	h0.1c4.8-0.4,9-0.4,12.7,0c5,0.6,8.8,2,11.7,4.3c1.6,1.1,2.7,2.7,3.6,4.4c0.3,0.7,0.7,1.4,0.9,2.1c0.1,0.7,0.4,1.3,0.6,2
	C614.3,223.7,614.2,226.2,613.2,228.3L613.2,228.3z"
          />
          <path
            fill="#EAEAEA"
            d="M257.4,207.6c-4.8-0.4-9-0.4-12.6,0c-5,0.6-8.8,2-11.7,4.3c-1.6,1.1-2.7,2.7-3.6,4.4
	c-0.3,0.7-0.7,1.4-0.9,2.1c-0.1,0.7-0.4,1.3-0.6,2c-0.6,3-0.4,5.5,0.6,7.7c0.4,0.9,0.9,1.6,1.4,2.1c0.4,0.4,0.9,0.7,1.4,1
	c0.6,0.3,1.1,0.7,1.8,1c0.3,0.1,0.7,0.3,1.1,0.3c2.7,0.9,6.1,1.4,10.2,1.7c0.4,0,1,0.1,1.4,0.1c4.4,0.6,8.2,0.3,11.4-0.9
	c5.4-2.4,8.5-6.3,9.2-11.7c0.1-1,0.1-1.9,0.1-2.7c0-6.3-2.6-9.9-7.5-11.2c-0.6-0.1-1.1-0.3-1.7-0.4V207.6L257.4,207.6z M280.2,250.9
	c-8.4,1-12.8,7.5-13.2,20l-1.3,37.7c0,2.8,1.3,4.4,3.7,4.5l23.7-0.6c-0.7-23.6-0.7-44.1-0.3-61.8h-12.6L280.2,250.9L280.2,250.9z
	 M311.1,167.2c-8.4,18.5-15.8,39.5-22.3,63h264.4c-6.5-23.5-13.9-44.5-22.3-63c-1.1-2.3-3.1-3.8-6.4-4.8c-1.3-0.4-2.7-0.7-4.3-0.9
	c-1.1-0.1-2.4-0.1-3.7-0.1c-27.1-1.8-57.7-3-92-3.1h-12.9c-31.8,0.3-60.4,1.3-86,3c-1.3,0-2.6,0-3.7,0.1c-1.6,0.1-3.1,0.4-4.3,0.9
	C314.3,163.4,312.1,165,311.1,167.2L311.1,167.2z M597.1,207.7c-3.7-0.4-7.8-0.4-12.7,0h-0.1c-0.6,0.1-1.1,0.1-1.7,0.4
	c-5,1.3-7.5,5.1-7.5,11.2c0,0.9,0,1.8,0.1,2.7c0.7,5.4,3.7,9.2,9.2,11.7c3.1,1.1,6.8,1.4,11.4,0.9c0.4,0,1-0.1,1.4-0.1
	c4.1-0.3,7.5-1,10.2-1.7c0.3-0.1,0.7-0.3,1.1-0.3c0.7-0.3,1.3-0.6,1.8-1c0.6-0.3,1-0.6,1.4-1c0.6-0.6,1.1-1.3,1.4-2.1
	c1-2.1,1.1-4.7,0.6-7.7c-0.1-0.7-0.3-1.3-0.6-2l-0.9-2.1c-0.9-1.7-2-3.3-3.6-4.4C605.9,209.7,602.1,208.3,597.1,207.7L597.1,207.7z
	 M575,271c-0.4-12.5-5-19-13.2-20h-12.5c0.4,17.6,0.3,38.2-0.3,61.8l23.7,0.6c2.4-0.1,3.7-1.7,3.7-4.5L575,271L575,271z"
          />
          <path
            fill="#636363"
            d="M256.3,320.7v2.8c0,2,0.1,4.1,0.3,6.1c0,1,0,2,0.1,2.8v96.8c0,6.4,3.3,9.7,9.7,9.7H291
	c6.4,0,9.7-3.3,9.7-9.7v-35c2.6,0.3,5.1,0.4,7.7,0.6c2.3,0.1,4.5,0.3,7,0.6c1,0,2,0.1,3,0.1c7.4,0.4,14.8,0.9,22.5,1.1
	c4.8,0.1,9.8,0.3,14.9,0.6c1.6,0,3.3,0,4.8,0.1h9.4c4.4,0.3,8.8,0.4,13.2,0.6c2.1,0,4.4,0.1,6.5,0.1c3,0,6.1,0.1,9.1,0.1h7.8
	c4.3,0,8.5,0,12.6-0.1h1.7c2.7,0,5.4,0.1,8,0.1c13.9,0.1,28.3,0,42.9-0.7h9.2c1.7,0,3.3,0,4.8-0.1c5-0.1,10-0.3,14.9-0.6
	c7.5-0.3,15.1-0.7,22.5-1.1c1,0,2-0.1,3-0.1c2.4-0.1,4.7-0.3,7-0.6c2.6-0.1,5.1-0.4,7.7-0.6v35c0,6.4,3.3,9.7,9.7,9.7h24.6
	c6.5,0,9.7-3.3,9.7-9.7v-96.8c0-1,0.1-2,0.1-2.8c0.1-2,0.1-4.1,0.3-6.1v-2.8c-0.4-2.3-1-4.1-1.7-5.5c-1.8,18.5-6,37.5-12.1,57.1
	c-4.1,3.4-9,6.3-14.4,8.2l-7.7-13.8l-47.8,2.8c0.3,2.4-1.6,3.6-5.5,3.7l-30.1,0.9c-0.7,1.3-2,1.8-3.8,2h-3.7
	c-1.4,0.1-2.3-0.3-2.6-1.6h-7.1l1.3-6.3h-58.7l1.3,6.3h-7.1c-0.3,1.3-1.1,1.8-2.6,1.6h-3.7c-1.8,0-3.1-0.7-3.8-2l-30-1
	c-4-0.1-5.8-1.4-5.5-3.7l-47.8-2.8l-7.7,13.8c-5.4-2.1-10.1-4.8-14.4-8.2c-6.1-19.8-10.2-38.8-12.1-57.1
	C257.3,316.6,256.7,318.5,256.3,320.7L256.3,320.7z"
          />
          <path
            fill="#5D5D5D"
            d="M332.7,145.2c-0.7,0-1.3,0.3-1.8,0.9c-0.1,0.1-0.3,0.4-0.4,0.7l6-0.6c-0.1-0.1-0.3-0.3-0.4-0.4
	c-0.6-0.4-1.1-0.7-1.8-0.7L332.7,145.2L332.7,145.2z M314.1,149c1.4,1.8,2.6,6.4,3.4,13.4c1.3-0.4,2.7-0.7,4.3-0.9
	c-0.6-5.4-1.4-9.9-3-13.5C317.3,148.3,315.7,148.7,314.1,149L314.1,149z"
          />
          <path
            fill="#EAEBEB"
            d="M420.9,145.1l-26.7,0.6c-5.4,0-7.5,1.4-6.3,4c1.4,3.1,4.1,5,8.2,5.4h49.6c4.1-0.4,6.8-2.1,8.2-5.4
	c1.1-2.7-0.9-4-6.3-4L420.9,145.1L420.9,145.1z"
          />
          <path
            fill="#5D5D5D"
            d="M507.6,145.1c-0.7,0-1.3,0.1-1.8,0.7c-0.1,0.1-0.3,0.3-0.4,0.4l6,0.6c-0.1-0.3-0.3-0.4-0.4-0.7
	c-0.4-0.6-1.1-0.9-1.8-0.9L507.6,145.1L507.6,145.1z M523.1,148c-1.4,3.6-2.4,8.1-3,13.5c1.6,0.1,3.1,0.4,4.3,0.9
	c0.7-7,1.8-11.5,3.4-13.4C526.2,148.7,524.6,148.3,523.1,148L523.1,148z M403.4,246.4c-4.7,0.1-6.5,1.7-5.7,4.8l1.3,9.7
	c0.6,4,3.4,6,8.5,6h26.7c5.1,0,8-2,8.5-6l1.3-9.7c0.9-3.1-1-4.7-5.7-4.8H403.4L403.4,246.4z"
          />
          <path
            fill="#FFFFFF"
            d="M266.7,222.1c-0.7,5.4-3.7,9.2-9.2,11.7c-3.1,1.1-6.8,1.4-11.4,0.9c-0.4,0-1-0.1-1.4-0.1
	c-4.1-0.3-7.5-1-10.2-1.7c-0.3-0.1-0.7-0.3-1.1-0.3c-0.7-0.3-1.3-0.6-1.8-1c-0.6-0.3-1-0.6-1.4-1c-0.6-0.6-1.1-1.3-1.4-2.1
	c-1-2.1-1.1-4.7-0.6-7.7c0.1-0.7,0.3-1.3,0.6-2l0.9-2.1c0.9-1.7,2-3.3,3.6-4.4c2.8-2.3,6.7-3.7,11.7-4.3c3.7-0.4,7.8-0.4,12.7,0h0.1
	c0.6,0.1,1.1,0.1,1.7,0.4c5,1.3,7.5,5.1,7.5,11.2C266.8,220.2,266.8,221.1,266.7,222.1L266.7,222.1z M254.9,236.4l15.6,2.1l1.1-2.6
	c0.4-1.6,1.1-2.8,2.3-3.7l3.6-9.9v-0.1h-8.8c0.1-1.1,0.1-2.1,0.1-3c0-6.8-2.8-11.1-8.4-12.5c-0.6-0.1-1.3-0.3-2-0.4h-0.1
	c-5.4-0.4-10.1-0.4-14.1,0c-5.4,0.7-9.8,2.3-12.9,4.8c-1.7,1.4-3,3-4,5c-0.4,0.7-0.7,1.6-1,2.4c-0.3,0.7-0.4,1.4-0.6,2.3
	c-0.7,3.3-0.4,6.3,0.6,8.5c0.4,1,1,1.7,1.6,2.3c0.4,0.4,1,0.9,1.6,1.1c0.7,0.4,1.3,0.7,2.1,1l1.3,0.4c3,0.9,6.8,1.6,11.4,2
	C247.5,236.2,250.9,236.3,254.9,236.4L254.9,236.4z M575.2,222.1c0.7,5.4,3.7,9.2,9.2,11.7c3.1,1.1,6.8,1.4,11.4,0.9
	c0.4,0,1-0.1,1.4-0.1c4.1-0.3,7.5-1,10.2-1.7c0.3-0.1,0.7-0.3,1.1-0.3c0.7-0.3,1.3-0.6,1.8-1c0.6-0.3,1-0.6,1.4-1
	c0.6-0.6,1.1-1.3,1.4-2.1c1-2.1,1.1-4.7,0.6-7.7c-0.1-0.7-0.3-1.3-0.6-2l-0.9-2.1c-0.9-1.7-2-3.3-3.6-4.4c-2.8-2.3-6.7-3.7-11.7-4.3
	c-3.7-0.4-7.8-0.4-12.7,0h-0.1c-0.6,0.1-1.1,0.1-1.7,0.4c-5,1.3-7.5,5.1-7.5,11.2C575,220.2,575.1,221.1,575.2,222.1L575.2,222.1z
	 M587,236.4l-15.6,2.1l-1.1-2.6c-0.4-1.6-1.1-2.8-2.3-3.7l-3.6-9.9v-0.1h8.8c-0.1-1.1-0.1-2.1-0.1-3c0-6.8,2.8-11.1,8.4-12.5
	c0.6-0.1,1.3-0.3,2-0.4h0.1c5.4-0.4,10.1-0.4,14.1,0c5.4,0.7,9.8,2.3,12.9,4.8c1.7,1.4,3,3,4,5c0.4,0.7,0.7,1.6,1,2.4
	c0.3,0.7,0.4,1.4,0.6,2.3c0.7,3.3,0.4,6.3-0.6,8.5c-0.4,1-1,1.7-1.6,2.3c-0.4,0.4-1,0.9-1.6,1.1c-0.7,0.4-1.3,0.7-2.1,1l-1.3,0.4
	c-3,0.9-6.8,1.6-11.4,2C594.4,236.2,590.9,236.3,587,236.4L587,236.4z"
          />
          <path
            fill="#5D5D5D"
            d="M271.9,330.3c-6.4-0.7-9.1,1.1-8.1,5.8c1.6,9.2,3.7,18.3,6.3,27.4c1.3,2.1,3.7,3.4,7.4,3.8l3.8-1.1l10.7,0.6
	l47.2,2.7h0.6c-0.3,2.4,1.6,3.6,5.5,3.7l30.1,0.9c0.7,1.3,2,1.8,3.8,2h3.7c1.4,0.1,2.3-0.3,2.6-1.6h7.1l-1.3-6.3H450l-1.3,6.3h7.1
	c0.3,1.3,1.1,1.8,2.6,1.6h3.7c1.8,0,3.1-0.7,3.8-2l30.1-0.9c4-0.1,5.8-1.4,5.5-3.7h0.6l47.2-2.7l10.7-0.6l3.8,1.1
	c3.7-0.6,6.3-1.9,7.4-3.8c2.7-9,4.7-18,6.3-27.4c1-4.7-1.7-6.5-8.1-5.8h-25.7l-1.3-1.4H298.6l-1.3,1.4H271.9L271.9,330.3z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M317.4,162.4c-3.1,1-5.3,2.6-6.4,4.8c-8.4,18.5-15.8,39.5-22.3,63
	 M293.1,312.8c-0.7-23.6-0.7-44.1-0.3-61.8 M299.3,261l2.8,28.7c1.4,9.2,6.7,13.9,15.9,14.2h205.4c9.2-0.3,14.6-5,15.9-14.2
	l2.8-28.7 M254.9,236.4l15.6,2.1 M338.7,328.8h-39.8l-1.3,1.4h-25.7c-6.4-0.7-9.1,1.1-8.1,5.8c1.6,9.2,3.7,18.3,6.3,27.4
	c1.3,2.1,3.7,3.4,7.4,3.8l3.8-1.1l10.7,0.6 M293.7,329.8c0-1.7-0.1-3.4-0.1-5.1 M293.5,324.7l-0.4-11.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M258,315.2c-0.7,1.4-1.3,3.3-1.7,5.5v2.8c0,2,0.1,4.1,0.3,6.1
	c0,1,0,2,0.1,2.8v96.8c0,6.4,3.3,9.7,9.7,9.7H291c6.5,0,9.7-3.3,9.7-9.7v-35"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M270.5,238.6c-8.2,12.6-12.4,23.6-12.5,32.7l-0.4,6.8c0,1-0.1,2-0.1,3
	c-0.3,5.1-0.4,10.4-0.3,15.6l0.9,18.6 M254.9,236.4c1-0.3,1.8-0.6,2.7-0.9c6.7-2.7,10.5-7.1,11.2-13.4 M293.7,230.2h-4.8
	 M292.8,250.9c0.1-7.4,0.4-14.4,0.9-20.8 M293.1,312.8l-23.7,0.6c-2.4-0.1-3.7-1.7-3.7-4.5l1.3-37.7c0.4-12.5,5-19,13.4-20h12.5
	 M288.8,230.2h-6.1c-1.6,0-3.3,0.1-4.8,0.3c-0.4,0-0.7,0.1-1.1,0.3c-1.1,0.3-2,0.9-2.7,1.4 M273.9,232.2c-1.1,1-1.8,2.1-2.3,3.7
	l-1.1,2.6 M277.6,222.1h-8.8 M273.9,232.2l3.6-9.9v-0.1 M545.5,231.9c-0.6,4.1-2.6,6.4-5.7,6.7H302c-3.1-0.3-5-2.6-5.7-6.7
	 M293.7,230.2h2.6 M296.2,230.2l0.1,1.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M296.4,231.9l0.1,1.1v0.4l2.8,27.6 M314.1,149c-0.4,0.1-0.9,0.3-1.3,0.3
	c-0.3,0-0.6,0.1-0.9,0.3l-1.7,0.9c-0.9,0.6-1.6,1.1-2.3,1.8c-1.3,1.1-2.4,2.4-3.3,4c-7.7,10.5-16.1,30.7-25.3,60.4
	c-0.6,1.8-1.1,3.6-1.7,5.4 M268.8,222.1c0.1-1.1,0.1-2.1,0.1-3c0-6.8-2.8-11.1-8.4-12.5c-0.6-0.1-1.3-0.3-2-0.4h-0.1
	c-5.4-0.4-10.1-0.4-14.1,0c-5.4,0.7-9.8,2.3-12.9,4.8c-1.7,1.4-3,3-4,5c-0.4,0.7-0.7,1.6-1,2.4c-0.3,0.7-0.4,1.4-0.6,2.3
	c-0.7,3.3-0.4,6.3,0.6,8.5c0.4,1,1,1.7,1.6,2.3c0.4,0.4,1,0.9,1.6,1.1c0.7,0.4,1.3,0.7,2.1,1l1.3,0.4c3,0.9,6.8,1.6,11.4,2
	c3.1,0.3,6.7,0.4,10.5,0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M244.8,207.7c-5,0.6-8.8,2-11.7,4.3c-1.6,1.1-2.7,2.7-3.6,4.4
	c-0.3,0.7-0.7,1.4-0.9,2.1c-0.1,0.7-0.4,1.3-0.6,2c-0.6,3-0.4,5.5,0.6,7.7c0.4,0.9,0.9,1.6,1.4,2.1c0.4,0.4,0.9,0.7,1.4,1
	c0.6,0.3,1.1,0.7,1.8,1c0.4,0.1,0.7,0.3,1.1,0.3c2.7,0.9,6.1,1.4,10.2,1.7c0.4,0,1,0.1,1.4,0.1c4.4,0.6,8.2,0.3,11.4-0.9
	c5.4-2.4,8.5-6.3,9.2-11.7c0.1-1,0.1-1.8,0.1-2.7c0-6.3-2.6-9.9-7.5-11.2l-1.7-0.4h-0.1C252.6,207.2,248.3,207.2,244.8,207.7
	L244.8,207.7z M511.3,146.6c-0.1-0.3-0.3-0.4-0.4-0.7c-0.4-0.6-1.1-0.9-1.8-0.9l-1.6-0.1c-0.7,0-1.3,0.1-1.8,0.7l-0.4,0.4
	 M505.4,146.2c-8.4-0.7-18.8-1.1-30.8-1.6c-10.4-0.4-22-0.7-35-0.9c-4-0.1-8-0.1-12.2-0.1h-18.5l-5.7,0.1 M454,149.6
	c1.1-2.7-0.9-4-6.3-4L421,145l-26.7,0.6c-5.4,0-7.5,1.4-6.3,4c1.4,3.1,4.1,5,8.2,5.4h49.6C449.9,154.6,452.6,152.9,454,149.6
	L454,149.6z M321.8,161.7c1.1-0.1,2.4-0.1,3.7-0.1c25.4-1.7,54.2-2.8,86-3h12.9c34.1,0.1,64.8,1.1,92,3.1c1.3,0,2.6,0,3.7,0.1
	 M520.1,161.7c0.6-5.4,1.4-9.9,3-13.5 M527.8,149c-1.6-0.4-3.1-0.7-4.7-1 M511.4,146.6l-6-0.4 M512.7,146.8l-1.3-0.1 M512.7,146.8
	c-0.4,0-0.9-0.1-1.4-0.1 M523.1,148c-2.8-0.4-6.3-0.9-10.4-1.3 M524.3,162.4c-1.3-0.4-2.7-0.7-4.3-0.9 M401.3,143.6l-17.8,0.4h-0.4
	c-5.5,0.1-10.8,0.3-15.8,0.4c-11.8,0.4-21.7,1-30,1.6h-0.9 M403.1,143.6h-1.8 M403.1,143.6h-1.8 M336.4,146.2
	c-0.1-0.1-0.3-0.3-0.4-0.4c-0.6-0.4-1.1-0.7-1.8-0.7l-1.6,0.1c-0.7,0-1.3,0.3-1.8,0.9c-0.1,0.1-0.3,0.4-0.4,0.7 M330.5,146.6
	c-4.5,0.4-8.5,0.9-11.7,1.4 M317.4,162.4c1.3-0.4,2.7-0.7,4.3-0.9 M318.8,148c-1.6,0.3-3.1,0.6-4.7,1 M321.8,161.7
	c-0.6-5.4-1.4-9.9-3-13.5 M336.4,146.2l-6,0.4 M405.7,257.1l0.3,2.3c0.4,2.4,2.1,3.6,5.1,3.6h19.6c3.1,0,4.8-1.1,5.1-3.6l0.3-2.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M399,261l-1.3-9.7c-0.9-3.1,1-4.7,5.7-4.8h35.1c4.7,0.1,6.5,1.7,5.7,4.8
	l-1.3,9.7 M442.8,261c-0.6,4-3.4,6-8.5,6h-26.7c-5.1,0-8-2-8.5-6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M405.2,251.9c-0.1,0.4-0.1,1,0,1.6l0.4,3.6 M436.7,251.9
	c-0.4-0.9-1.6-1.3-3.4-1.3h-24.7c-1.8,0-3,0.4-3.4,1.3 M349.9,328.8h-11.2 M436.2,257.1l0.4-3.6c0.1-0.6,0.1-1.1,0-1.6 M503,328.8
	h-11.1 M491.9,328.8h-142 M405.2,251.9h31.4 M405.7,257.1h30.6 M564.3,222.1c-0.6-1.8-1.1-3.7-1.7-5.4
	c-9.2-29.7-17.6-49.9-25.3-60.4c-1-1.4-2-2.8-3.3-4c-0.7-0.7-1.4-1.3-2.3-1.8c-0.6-0.4-1.1-0.7-1.7-0.9c-0.3-0.1-0.6-0.1-0.9-0.3
	c-0.4-0.1-0.9-0.3-1.3-0.3 M587,236.4c4,0,7.4-0.3,10.5-0.6c4.7-0.4,8.4-1.1,11.4-2c0.4-0.1,0.9-0.3,1.3-0.4c0.7-0.3,1.4-0.7,2.1-1
	c0.6-0.3,1.1-0.7,1.6-1.1c0.7-0.6,1.1-1.4,1.6-2.3c1.1-2.4,1.3-5.3,0.6-8.5c-0.1-0.7-0.4-1.4-0.6-2.3c-0.3-0.9-0.6-1.7-1-2.4
	c-1-1.8-2.3-3.6-4-5c-3.1-2.6-7.5-4.1-12.9-4.8c-4-0.6-8.7-0.6-14.1,0h-0.1c-0.7,0.1-1.3,0.3-2,0.4c-5.5,1.4-8.4,5.7-8.4,12.5
	c0,1,0,2,0.1,3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M597.1,207.7c5,0.6,8.8,2,11.7,4.3c1.6,1.1,2.7,2.7,3.6,4.4
	c0.3,0.7,0.7,1.4,0.9,2.1c0.1,0.7,0.4,1.3,0.6,2c0.6,3,0.4,5.5-0.6,7.7c-0.4,0.9-0.9,1.6-1.4,2.1c-0.4,0.4-0.9,0.7-1.4,1
	c-0.6,0.3-1.1,0.7-1.8,1c-0.3,0.1-0.7,0.3-1.1,0.3c-2.7,0.9-6.1,1.4-10.2,1.7c-0.4,0-1,0.1-1.4,0.1c-4.4,0.6-8.2,0.3-11.4-0.9
	c-5.4-2.4-8.5-6.3-9.2-11.7c-0.1-1-0.1-1.8-0.1-2.7c0-6.3,2.6-9.9,7.5-11.2l1.7-0.4h0.1C589.3,207.2,593.4,207.2,597.1,207.7
	L597.1,207.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M587,236.4c-1-0.3-1.8-0.6-2.7-0.9c-6.7-2.7-10.5-7.1-11.2-13.4
	 M567.8,232.2l-3.6-9.9v-0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M553.1,230.2h6.1c1.6,0,3.3,0.1,4.8,0.3c0.4,0,0.7,0.1,1.1,0.3
	c1.1,0.3,2,0.9,2.7,1.4 M571.3,238.6l-1.1-2.6c-0.4-1.6-1.1-2.8-2.3-3.7 M573.1,222.1h-8.8 M545.5,231.9l0.1-1.7 M542.5,261
	l2.8-27.6V233l0.1-1.1 M545.7,230.2h2.6 M548.8,312.8l23.7,0.6c2.4-0.1,3.7-1.7,3.7-4.5l-1.3-37.7c-0.4-12.5-5-19-13.2-20h-12.5
	 M548.2,230.2c0.4,6.4,0.7,13.4,0.9,20.8 M548.2,230.2h4.8 M548.8,312.8c-0.1,3.8-0.3,7.8-0.4,11.9 M549.8,366.9l10.7-0.6l3.8,1.1
	c3.7-0.6,6.3-1.8,7.4-3.8c2.7-9,4.7-18,6.3-27.4c1-4.7-1.7-6.5-8.1-5.8h-25.7l-1.3-1.4H503 M548.2,329.8c0-1.7,0.1-3.4,0.1-5.1
	 M583.9,315.2l0.9-18.6c0.1-5.3,0-10.4-0.3-15.6c0-1-0.1-2-0.1-3l-0.4-6.8c-0.1-9.1-4.3-20-12.5-32.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M541.1,394.5v35c0,6.4,3.3,9.7,9.7,9.7h24.6c6.4,0,9.7-3.3,9.7-9.7v-96.8
	c0-1,0.1-2,0.1-2.8c0.1-2,0.1-4.1,0.3-6.1V321c-0.4-2.3-1-4.1-1.7-5.5 M571.3,238.6l15.8-2.1 M549.1,250.9
	c0.4,17.6,0.3,38.2-0.3,61.8 M524.3,162.4c3.1,1,5.3,2.6,6.4,4.8c8.4,18.5,15.8,39.5,22.3,63 M527.8,149c-1.4,1.8-2.6,6.4-3.4,13.4
	 M542.5,261h-99.8 M548.4,324.7H293.5 M314.1,149c1.4,1.8,2.6,6.4,3.4,13.4 M299.3,261H399 M545.7,230.2H296.2 M527.6,344.3
	c-0.6-0.6-0.9-1.3-0.9-2.1s0.3-1.6,0.9-2.1s1.3-0.9,2.1-0.9c0.9,0,1.6,0.3,2.1,0.9s0.9,1.3,0.9,2.1s-0.3,1.6-0.9,2.1
	s-1.3,0.9-2.1,0.9C528.9,345.2,528.2,344.9,527.6,344.3L527.6,344.3z M541.1,367.4l8.7-0.4 M300.8,394.5c2.6,0.3,5.1,0.4,7.7,0.6
	c2.3,0.1,4.7,0.3,7,0.6c1,0,2,0.1,3,0.1c7.4,0.4,14.8,0.9,22.5,1.1c4.8,0.1,9.8,0.3,14.9,0.6c1.6,0,3.3,0,4.8,0.1h9.4
	c4.4,0.3,8.8,0.4,13.2,0.6c2.1,0,4.4,0.1,6.5,0.1c3,0,6.1,0.1,9.1,0.1h7.8c4.3,0,8.5,0,12.6-0.1h1.7c2.7,0,5.4,0.1,8,0.1
	c13.9,0.1,28.3,0,42.9-0.7h9.2c1.7,0,3.3,0,4.8-0.1c5-0.1,10-0.3,14.9-0.6c7.5-0.3,15.1-0.7,22.5-1.1c1,0,2-0.1,3-0.1
	c2.4-0.1,4.7-0.4,7-0.6c2.6-0.1,5.1-0.4,7.7-0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M583.9,315.2c-1.8,18.5-6,37.5-12.1,57.1c-4.1,3.4-9,6.3-14.4,8.2
	l-7.7-13.8 M541.1,394.5v-27.1 M339.3,369.6h0.6c-0.3,2.4,1.6,3.6,5.5,3.7l30.1,0.9c0.7,1.3,2,1.9,3.8,2h3.7
	c1.4,0.1,2.3-0.3,2.6-1.6h7.1l-1.3-6.3h58.7l-1.3,6.3h7.1c0.3,1.3,1.1,1.8,2.6,1.6h3.7c1.8,0,3.1-0.7,3.8-2l30.1-0.9
	c4-0.1,5.8-1.4,5.5-3.7h0.6 M502.5,369.6c-1.6-0.1-2.1-1.7-1.7-4.7l2.3-36.1 M349.9,328.8l1.4,21.9c0.1,4.8,4.1,7.2,11.9,7.2h115.1
	c7.8,0,11.8-2.4,11.9-7.2l1.4-21.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M458.3,364c-0.4,0-0.7,0.3-0.7,0.7v6.5c0,0.4,0.3,0.7,0.7,0.7h6.5
	c0.4,0,0.7-0.3,0.7-0.7v-6.5c0-0.4-0.3-0.7-0.7-0.7H458.3L458.3,364z M439.6,352.6h-37.4v-13.4h37.4V352.6z M383.5,364
	c0.4,0,0.7,0.3,0.7,0.7v6.5c0,0.4-0.3,0.7-0.7,0.7H377c-0.4,0-0.7-0.3-0.7-0.7v-6.5c0-0.4,0.3-0.7,0.7-0.7H383.5L383.5,364z
	 M339.3,369.6c1.6-0.1,2.1-1.7,1.7-4.7l-2.3-36.1 M502.5,369.6l38.7-2.3 M314.3,339.9c-0.6-0.6-1.3-0.9-2.1-0.9
	c-0.9,0-1.6,0.3-2.1,0.9c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.9,0.3,1.6,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.6-0.3,2.1-0.9
	s0.9-1.3,0.9-2.1C315.1,341.4,314.8,340.6,314.3,339.9L314.3,339.9z M300.8,367.4l-8.7-0.4 M292.1,366.9l-7.7,13.8
	c-5.4-2.1-10.1-4.8-14.4-8.2c-6.1-19.8-10.2-38.8-12.1-57.1 M300.8,394.5v-27.1 M300.8,367.4l38.5,2.3"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M288.8,230.2
		c0,0,10.2-38.1,22.6-63.4c0,0,1.7-5.4,14.1-5.4c0,0,30.3-2,53.4-2.6c0,0,22.9-0.7,41.9-0.6c0,0,27-0.1,48.3,0.7
		c0,0,36.7,1.4,47.2,2.3c0,0,5.5-0.1,8.8,1.3c0,0,4,1.1,5.4,4.4c0,0,12.4,26.3,22.5,63.1h-53.3h-53H421h-25.7h-37.5h-47.2h-21.7
		L288.8,230.2L288.8,230.2z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M293.7,230.2h2.7
		h51h47.2h26.3h37.4H499h46.6h2.6c0,0,1.6,25.6,1.1,50.5c0,0-0.3,25-1,43.9h-48.8h-61.4h-17.3h-25.4h-47.2h-35.4h-19.3
		c0,0-0.9-22.7-0.9-37.8c0,0-0.1-14.4,0.1-36C292.8,250.9,293.1,239.9,293.7,230.2z"
              id="Liftgate and Tailgate"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Liftgate and Tailgate')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('liftgateTailgateColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M293.1,312.8l-22.6,0.6
		h-1c0,0-4,0-3.8-4.5l1.3-38.7c0,0,0.1-8.2,3.6-13.4c0,0,2.8-5.3,9.7-5.8h12.5c0,0-0.6,16.3-0.3,32.4
		C292.5,283.4,292.7,305.1,293.1,312.8z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M548.8,312.8l23.7,0.6
		c0,0,3.6,0.3,3.7-4.5L575,270c0,0-0.1-8.1-3.6-13.1c0,0-2.8-5.1-9.7-5.8h-12.5c0,0,0.4,16.1,0.3,31.1
		C549.4,282.2,549.2,291.2,548.8,312.8z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M542.8,328.8l1.3,1.4h25.7
		c0,0,5.1-0.9,7.1,1.1c0,0,1.7,1.1,1,4.7c0,0-1.4,10.7-6.3,27.4c0,0-1.3,3-7.4,3.8l-3.8-1.1l-58.4,3.4c0,0,1,3.7-5.5,3.7l-30.1,0.9
		c0,0-1,2-3.8,2h-3.7c0,0-2.1,0.4-2.6-1.6h-7.1l1.3-6.3H421h-29.4l1.3,6.3h-7.1c0,0-0.4,2-2.6,1.6h-3.7c0,0-2.7,0-3.8-2l-29.7-0.9
		c0,0-3.4,0.1-5.1-1.3c0,0-1-0.6-0.9-2.4h-0.6l-58-3.4l-3.8,1.1c0,0-5.3-0.4-7.4-3.8c0,0-4.7-16.8-6.4-28.1c0,0-0.9-3.8,2.8-4.8
		c0,0,1.1-0.7,5.4-0.3h25.7l1.3-1.4h39.8h11.1h44.3h26.6h31.6h39.5H503L542.8,328.8L542.8,328.8z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(CrewCabPickup4DrRear);
