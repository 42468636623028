import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex, has } from 'lodash';

let styles = {};

class TableRowCheckbox extends Component {
  // this determined whether box is checked
  hasBeenSelected() {
    const { row, selectedData, allSelected } = this.props;

    if (allSelected) {
      return true;
    }

    // no row indicates header.  only time it should be checked is when allSelected
    if (!row) {
      return false;
    }

    // offers pages use offerId because there can be multiple offers on same item
    if (has(row, 'original.offerId')) {
      return (
        findIndex(selectedData, {
          offerId: row.original.offerId,
        }) > -1
      );
    }

    // all others are selected by itemId
    if (has(row, 'original.itemId')) {
      return findIndex(selectedData, { itemId: row.original.itemId }) > -1;
    }
    if (has(row, 'original.id')) {
      return findIndex(selectedData, { id: row.original.id }) > -1;
    }

    // except buyer arbs
    if (!has(row, 'original.itemId') && has(row, 'original.vin')) {
      return findIndex(selectedData, { vin: row.original.vin }) > -1;
    }
    if (!has(row, 'original.id') && has(row, 'original.vIN')) {
      return findIndex(selectedData, { vIN: row.original.vIN }) > -1;
    }

    return false;
  }

  render() {
    return (
      <div style={styles.container}>
        <input
          style={styles.input}
          type="checkbox"
          name="checkbox"
          onClick={this.props.onClick}
          onChange={e => e.preventDefault}
          checked={this.hasBeenSelected()}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

styles = {
  container: {
    marginTop: 8,
    display: 'inline-block',
  },
  input: {
    marginTop: 8,
    display: 'block',
    position: 'relative',
    textAlign: 'center',
    margin: 'auto',
  },
};

TableRowCheckbox.propTypes = {
  row: PropTypes.object,
  selectedData: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
};

export default TableRowCheckbox;
