import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SettingsNav extends Component {
  state = {
    active: 'profile',
  };

  handleSection = e => {
    e.preventDefault();
    const section = e.currentTarget.getAttribute('href').slice(1);
    this.setState({ active: section });
    this.props.updateSection(section);
  };

  render() {
    return (
      <div className="status-nav">
        <div className="container-fluid">
          <div className="status-title">
            <h4>Account Settings</h4>
          </div>
          <ul className="nav navbar-nav status-links">
            <li className={this.isActive('profile')}>
              <Link to="/profile" onClick={this.handleSection}>
                Profile
              </Link>
            </li>
            <li className={this.isActive('accounts')}>
              <Link to="/accounts" onClick={this.handleSection}>
                Accounts
              </Link>
            </li>
            <li className={this.isActive('alerts')}>
              <Link to="/alerts" onClick={this.handleSection}>
                Alerts
              </Link>
            </li>
            <li className={this.isActive('password')}>
              <Link to="/password" onClick={this.handleSection}>
                Update Password
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  isActive(section) {
    return this.state.active === section ? 'active' : '';
  }
}
