export const BODY_STYLES = [
  'CONVERTIBLE 2-DR',
  'COUPE',
  'COUPE 2-DR',
  'HARDTOP',
  'HARDTOP 2-DR',
  'SEDAN 2-DR',
  'COUPE 4-DR',
  'CREW CAB PICKUP 4-DR',
  'CROSSOVER 4-DR',
  'MPV',
  'CLUB CAB PICKUP',
  'CLUB CAB PICKUP 2-DR',
  'EXTENDED CAB PICKUP',
  'EXTENDED CAB PICKUP 2-DR',
  'EXTENDED CAB PICKUP 3-DR',
  'EXTENDED CAB PICKUP 4-DR',
  'HATCHBACK',
  'HATCHBACK 2-DR',
  'HATCHBACK 3-DR',
  'LIFTBACK 3-DR',
  'HATCHBACK 4-DR',
  'HATCHBACK 5-DR',
  'LIFTBACK 5-DR',
  'CREW CAB PICKUP 2-DR',
  'PICKUP',
  'REGULAR CAB PICKUP 2-DR',
  'TRUCK',
  'SEDAN',
  'SEDAN 4-DR',
  'SPORT UTILITY 2-DR',
  'SPORT UTILITY 2-DR HARD-TOP',
  'SPORT UTILITY 2-DR SOFT-TOP',
  'SPORT UTILITY 4-DR',
  'SPORT UTILITY TRUCK 4-DR',
  'SUV',
  'CAMPER VAN',
  'CARGO CONVERSION VAN',
  'PASSENGER VAN',
  'SPORTS VAN',
  'STEP VAN',
  'VAN',
  'WINDOW VAN',
  'EXTENDED SPORTS VAN',
  'EXTENDED WINDOW VAN',
  'WAGON',
  'WAGON 4-DR',
  'WAGON 5-DR',
  'CONVENTIONAL CAB',
  'CAB & CHASSIS',
  'BUS',
  'DAY CAB',
  'CONVENTIONAL CAB',
  'TRACTOR TRUCK',
  'CAB-OVER-ENGINE',
  'CAB-BEHIND-ENGINE ',
  'TILT CAB',
];

export const HEAVY_TRUCK_BODYSTYLES = [
  'BUS',
  'CONVENTIONAL CAB',
  'CAB & CHASSIS',
  'DAY CAB',
  'CONVENTIONAL CAB',
  'TRACTOR TRUCK',
  'CAB-OVER-ENGINE',
  'CAB-BEHIND-ENGINE ',
  'TILT CAB',
];

export const HEAVY_TRUCK_PROPERTIES = [
  'Engmake',
  'Engmodel',
  'Enghp',
  'Tranmake',
  'Tranmodel',
  'Transpeed',
  'Slpcabtype',
  'Slpcabsize',
  'Axles',
  'Faxlewt',
  'Raxlewt',
  'Susp',
  'Fifwhltype',
  'Exhtype',
  'Fueltank',
  'Wheelbase',
  'Gvwr',
  'Fuelcap',
  'Fuelcapl',
  'Fuelcapr',
  'Engbrake',
  'Aeropack',
  'Axleratio',
  'Trailertyp',
  'Trlrdoortp',
  'Enginehrs',
  'Trailerhrs',
  'Heavytrk',
  'Ecm',
];

export const INVENTORY_META_PROPERTIES = [
  'suspother',
  'mirrorpwr',
  'mirrorheat',
  'windowpwr',
  'boxwidth',
  'boxlength',
  'boxheight',
  'liftgate',
  'reardrtype',
  'lftdrtype',
  'rgtdrtype',
  'heavytrl',
  'heavyeqp',
  'seattyped',
  'seattypep',
  'trlmat',
  'trlmatoth',
  'trlaxltyp',
  'trlaxloth',
  'drtypeoth',
  'trltypoth',
  'matt',
];

export const informationSidebarOptions = [
  {
    title: 'Information',
    path: '/core/information',
  },
  {
    title: 'Wheels',
    path: '/core/information/wheels',
  },
];

export const conditionSidebarOptions = [
  {
    title: 'Conditions',
    path: '/core/condition',
  },
  {
    title: 'Other',
    path: '/core/condition/other',
  },
  {
    title: 'Items',
    path: '/core/condition/items',
  },
];

export const cylinders = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
export const odometerTypes = ['Miles', 'Kilometers', 'Unknown'];

export const exteriorColors = [
  'Beige',
  'Black',
  'Blue',
  'Brown',
  'Burgundy',
  'Bronze',
  'Cream',
  'Gold',
  'Green',
  'Grey',
  'Maroon',
  'Orange',
  'Pink',
  'Purple',
  'Red',
  'Rose',
  'Silver',
  'Tan',
  'Teal',
  'White',
  'Yellow',
  'Unknown',
];

export const interiorColors = [
  'Beige',
  'Black',
  'Blue',
  'Brown',
  'Burgundy',
  'Green',
  'Gray',
  'Orange',
  'Red',
  'Tan',
  'White',
  'Yellow',
  'Unknown',
];

export const interiorTypes = [
  'Cloth',
  'Cloth & Leather',
  'Leather',
  'Leatherette',
  'Velour',
  'Vinyl',
  'Unknown',
];

export const fuelLevels = ['Empty', '1/8', '1/4', '1/2', '3/4', 'Full Tank'];

export const keys = ['0', '1', '2', '3'];

export const WHEEL_STATIC_ITEMS = {
  size: [
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    'Other',
    'Unknown',
  ],
  manufacturer: [
    'BFGoodrich',
    'Bridgestone',
    'Continental',
    'Dunlop',
    'Goodyear',
    'Kelly-Springfield',
    'Kumho',
    'Michelin',
    'Nitto',
    'Pirelli',
    'Yokohama',
    'Other',
    'Unknown',
  ],
  depth: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    'Other',
    'Unknown',
  ],
};

export const AXLE_TYPES = [
  { title: 'Front Axle', type: 'axle' },
  { title: 'Axle 1', type: 'axle' },
  { title: 'Axle 2', type: 'axle' },
  { title: 'Axle 3', type: 'axle' },
  { title: 'Axle 4', type: 'axle' },
];

export const AxleProperties = {
  lotread: 'Lo Tread',
  litread: 'Li Tread',
  rotread: 'Ro Tread',
  ritread: 'Ri Tread',
  lotype: 'Lo Type',
  litype: 'Li Type',
  rotype: 'Ro Type',
  ritype: 'Ri Type',
  losize: 'Lo Size',
  lisize: 'Li Size',
  rosize: 'Ro Size',
  risize: 'Ri Size',
  notes: 'Notes',
};

export const radioTypes = [
  'CD Player',
  'Cassette Player',
  'AM Radio',
  'FM Radio',
  'None',
];

export const topTypes = ['Hardtop', 'Sunroof', 'Convertible'];

export const Platestates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const Aeropackages = [
  'Half Aero Package',
  'Aero Package',
  'Full Aero Package',
  'Half Aero Cab Package',
  'Side Aero Package',
  'None',
];

export const SuspOther = [
  'Air',
  'Camel Back',
  'Chalmers',
  'Hard Block',
  'Henderson Block',
  'Hendrickson Block',
  'HMX460',
  'Hutch',
  'Mack Walking Beam',
  'Solid',
  'Spring Hard Block Combo',
  'Stationary',
  'Torsion',
  'Tuf Trac',
  'Walking Beam',
  'Walking Block',
  'None',
];

export const TrailerDoorType = [
  '18x101',
  `39' of Flat`,
  'Air Lid Fill',
  'Coil Well',
  'Curtin Side',
  'Detach Neck',
  'Lid',
  'Manual Lids',
  'None',
  'Roll Up',
  'Swing',
];

export const FuelTankTypes = [
  'Single Steel',
  'Dual Alum',
  'Dual Steel',
  'Single Alum',
];

export const MirrorPowerTypes = ['Driver Only', 'Dual Power', 'Passenger Only'];

export const PowerWindow = ['Driver Only', 'Dual Power', 'Passenger Only'];

export const Doortype = ['Swing', 'Roll'];

export const TrailerMatOther = ['STEEL', 'SOFTSIDE', 'PLASTIC', 'FIBERGLASS'];

export const TrailerMat = ['STEEL', 'FRP', 'ALUM', 'COMBO', 'OTHER'];

export const TrailerAxlOther = ['STEEL', 'SOFTSIDE', 'PLASTIC', 'FIBERGLASS'];

export const FifthWheelType = [
  'Air Slide',
  'Manual',
  'Sliding',
  'Spread',
  'Stationary',
  'None',
];

export const MEDIA_TYPES = [
  {
    category: 'Medias',
    description: 'Exterior Left Front',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Left',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Left Rear',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Rear',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Right Rear',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Right',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Right Front',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Exterior Front',
    area: 'Exterior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Interior Front',
    area: 'Interior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Interior Rear',
    area: 'Interior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Interior Dash',
    area: 'Interior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Interior Odometer',
    area: 'Interior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Interior Books',
    area: 'Interior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Interior VIN Plate',
    area: 'Interior',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 1',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 2',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 3',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 4',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 5',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 6',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 7',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 8',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 9',
    area: 'Misc',
    cloudPicker: false,
  },
  {
    category: 'Medias',
    description: 'Misc 10',
    area: 'Misc',
    cloudPicker: false,
  },
];

export const WHEEL_MEDIA_TYPES = [
  {
    category: 'Wheels',
    description: 'Left Front',
    cloudPicker: false,
  },
  {
    category: 'Wheels',
    description: 'Left Rear',
    cloudPicker: false,
  },
  {
    category: 'Wheels',
    description: 'Right Rear',
    cloudPicker: false,
  },
  {
    category: 'Wheels',
    description: 'Right Front',
    cloudPicker: false,
  },
  {
    category: 'Wheels',
    description: 'Spare',
    cloudPicker: false,
  },
];

export const QUESTION_TYPES = [
  {
    rowSection: 's4:r1',
    title: 'Does it have Frame Damage?',
    entity: 'frameDamage',
  },
  {
    rowSection: 's4:r2',
    title: 'Is the Odometer Inoperable?',
    entity: 'odometerInoperable',
  },
  {
    rowSection: 's4:r3',
    title: 'Is the True Mileage Unknown?',
    entity: 'trueMileageUnknown',
  },
  {
    rowSection: 's4:r4',
    title: 'Is it Undrivable?',
    entity: 'undrivable',
  },
  {
    rowSection: 's4:r5',
    title: 'Is it Inoperable?',
    entity: 'inoperable',
  },
  {
    rowSection: 's4:r6',
    title: 'Are the Keys Missing?',
    entity: 'keysMissing',
  },
  {
    rowSection: 's4:r7',
    title: 'Are the Fobs Missing?',
    entity: 'fobsMissing',
  },
  {
    rowSection: 's4:r8',
    title: 'Are the Books Missing?',
    entity: 'booksMissing',
  },
  {
    rowSection: 's4:r9',
    title: 'Are the Floor Mats Missing?',
    entity: 'floorMatsMissing',
  },
  {
    rowSection: 's4:r10',
    title: 'Has it been Smoked In?',
    entity: 'smokedIn',
  },
  {
    rowSection: 's4:r11',
    title: 'Is it a Commercial Vehicle?',
    entity: 'commercialVehicle',
  },
  {
    rowSection: 's4:r12',
    title: 'Is it a Police Car or Taxi?',
    entity: 'policeOrTaxi',
  },
  {
    rowSection: 's4:r13',
    title: 'Does it have Flood Damage?',
    entity: 'floodDamage',
  },
  {
    rowSection: 's4:r14',
    title: 'Is it Studio Done?',
    entity: 'studioDone',
  },
  {
    rowSection: 's4:r15',
    title: 'Previous Canadian?',
    entity: 'previousCanadian',
  },
  {
    rowSection: 's4:r16',
    title: 'Gray Market?',
    entity: 'grayMarket',
  },
  {
    rowSection: 's4:r16',
    title: 'Is Title Present?',
    entity: 'titlePresent',
  },
];

export const areas = {
  grille: 'Grille',
  frontBumper: 'Front Bumper',
  leftFrontHeadlights: 'Left Front - Headlights',
  rightFrontHeadlights: 'Right Front - Headlights',
  windshield: 'Windshield',
  leftFrontFender: 'Left Front - Fender',
  leftFrontDoor: 'Left Front Door',
  leftFrontDoorGlass: 'Left Front Door Glass',
  leftQtrGlass: 'Left Qtr Glass',
  leftRearQuarterPanel: 'Left Rear - Quarter Panel',
  leftRearDoor: 'Left Rear Door',
  leftRearDoorGlass: 'Left Rear Door Glass',
  leftSideMirror: 'Left Side Mirror',
  wheelsAndTiresLeftFront: 'Wheels And Tires - Left Front',
  wheelsAndTiresLeftRear: 'Wheels And Tires - Left Rear',
  deckLid: 'Deck Lid',
  liftgateAndTailgate: 'Liftgate and Tailgate',
  rearBumper: 'Rear Bumper',
  rearGlass: 'Rear Glass',
  leftTailLight: 'Left Tail Light',
  rightTailLight: 'Right Tail Light',
  rightFrontFender: 'Right Front - Fender',
  rightFrontDoor: 'Right Front Door',
  rightFrontDoorGlass: 'Right Front Door Glass',
  rightQtrGlass: 'Right Qtr Glass',
  rightRearQuarterPanel: 'Right Rear - Quarter Panel',
  rightRearDoor: 'Right Rear Door',
  rightRearDoorGlass: 'Right Rear Door Glass',
  rightSideMirror: 'Right Side Mirror',
  wheelsAndTiresRightFront: 'Wheels And Tires - Right Front',
  wheelsAndTiresRightRear: 'Wheels And Tires - Right Rear',
  hood: 'Hood',
  roof: 'Roof',
  truckBed: 'Truck Bed',
};

export const CORE_SECTIONS = [
  {
    section: 's1',
    title: 'Details',
    path: '/core/details',
    icon: 'Details',
  },
  {
    section: 's2',
    title: 'Information',
    path: '/core/information',
    icon: 'Information',
  },
  {
    section: 's10',
    title: 'Wheels',
    path: '/core/wheels',
    icon: 'Wheel',
    subSections: [
      {
        title: 'Axles',
        path: '/core/wheels/axles',
      },
    ],
  },
  {
    section: 's9',
    title: 'Heavy Truck',
    path: '/core/heavyTruck',
    icon: 'HeavyTruck',
  },
  {
    section: 's3',
    title: 'Equipment',
    path: '/core/equipment',
    icon: 'Equipment',
  },
  {
    section: 's4',
    title: 'Questions',
    path: '/core/question',
    icon: 'Question',
  },
  {
    section: 's5',
    title: 'Media',
    path: '/core/media',
    icon: 'Media',
  },
  {
    section: 's6',
    title: 'Condition',
    path: '/core/condition',
    icon: 'Condition',
    subSections: [
      {
        title: 'Parts',
        path: '/core/condition/parts',
      },
      {
        title: 'Damage',
        path: '/core/condition/damage',
      },
      {
        title: 'Severity',
        path: '/core/condition/severity',
      },
      {
        title: 'Info',
        path: '/core/condition/info',
      },
    ],
  },
  {
    section: 's11',
    title: 'Condition Review',
    path: '/core/conditionReview',
    icon: 'ConditionItems',
    subSections: [
      {
        title: 'Parts',
        path: '/core/conditionReview/parts',
      },
      {
        title: 'Damage',
        path: '/core/conditionReview/damage',
      },
      {
        title: 'Severity',
        path: '/core/conditionReview/severity',
      },
      {
        title: 'Info',
        path: '/core/conditionReview/info',
      },
      {
        title: 'Quick Review',
        path: '/core/conditionReview/view',
      },
    ],
  },
  {
    section: 's7',
    title: 'Additional',
    path: '/core/additional',
    icon: 'Additional',
  },
  {
    section: 's8',
    title: 'Pricing',
    path: '/core/pricing',
    icon: 'Pricing',
  },
  {
    section: 's99',
    title: 'Vehicle Review',
    path: '/core/review',
    icon: 'ReviewIcon',
  },
];
