import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { loadBuyerIfs, resetBuyerIfs } from '../../actions';
import { getConfig, compose } from '../../utils/helpers';

class BuyerIfs extends Component {
  state = {
    title: 'buyer ifs',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetBuyerIfs();
    this.props.loadBuyerIfs(getConfig('marketplaceId'));
  };

  render() {
    const loading = isEmpty(this.props.buyerIfs);
    const data = this.props.buyerIfs.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'seller',
      'details',
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>If Sales</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { buyerIfs } = state.entities;
  return { buyerIfs };
};

export default compose(
  withTable,
  connect(
    mapStateToProps,
    { loadBuyerIfs, resetBuyerIfs }
  )
)(BuyerIfs);
