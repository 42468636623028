import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import { setIsVerified, updateItem } from '../../../../../../actions';
import Layout from '../../../templates/forms/Layout';
import {
  BODY_STYLES,
  cylinders,
  HEAVY_TRUCK_BODYSTYLES,
} from '../../../../constants/common';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import CustomInputs from './CustomInputs';
import SelectDetails from './SelectDetails';
import FormFooter from '../../../templates/forms/formFooter';
import { showRow } from '../../../../utils/helpers';

class Details extends Component {
  state = {
    source: {},
    isVerified: false,
    openVerifyModal: false,
    verifyModalTitle: '',
    verifyModalMessage: '',
    verifyModalBtnText: '',
    verifyModalAction: null,
  };

  componentDidMount() {
    this.loadData();
    this.setState({ isVerified: this.props.isVerified });
  }

  loadData() {
    let sourceBodyStyles;
    const item = this.props.vin.decodedItems[this.props.variant.index];
    const original = ((item && item['bodyStyles']) || []).filter(bodyStyle => {
      return !['Unknown', 'UNKNOWN'].includes(bodyStyle);
    });
    const added = BODY_STYLES;
    sourceBodyStyles = uniq([...original, ...added, 'UNKNOWN']);
    this.setState({
      source: {
        bodyStyles: sourceBodyStyles,
        transmissions: item && item['transmissions'],
        engines: item && item['engines'],
        drives: item && item['drives'],
        cylinders: cylinders,
      },
    });
  }

  getDefaultValue(property) {
    const { item } = this.props;
    const existingValue = item && item[property];
    return existingValue
      ? { value: existingValue, label: existingValue }
      : null;
  }

  handleBodyStyle(e) {
    const { updateItem } = this.props;
    const isHeavyTruck = HEAVY_TRUCK_BODYSTYLES.includes(e.value);
    updateItem({
      bodyStyle: e.value,
      heavyTruckOption: isHeavyTruck,
      Heavytrk: isHeavyTruck,
    });
  }

  handleHeavyTruckButton(status) {
    this.props.updateItem({
      heavyTruckOption: status,
      Heavytrk: status,
    });
  }

  handleVerifyIgnore = () => {
    this.setState({ isVerified: true }, () => this.props.setIsVerified(true));
  };

  handleVerify() {
    const isVerified = this.state.isVerified;
    const { bodyStyle, transmission, engine, driveline, cylinder } =
      this.props.item;
    if (isVerified) {
      this.setState({ isVerified: false }, () =>
        this.props.setIsVerified(false)
      );
    } else {
      let incomplete = [];
      if (!bodyStyle) {
        incomplete.push('Body');
      }
      if (!transmission) {
        incomplete.push('Transmission');
      }
      if (!engine) {
        incomplete.push('Engine');
      }
      if (!driveline) {
        incomplete.push('Driveline');
      }
      if (!cylinder) {
        incomplete.push('Cylinder');
      }
      if (incomplete.length > 0) {
        this.setState({
          openVerifyModal: true,
          verifyModalTitle: 'Must Complete',
          verifyModalMessage: incomplete.join(', '),
          verifyModalAction: this.handleVerifyIgnore,
          verifyModalBtnText: 'Ignore',
        });
      } else {
        this.setState({ isVerified: true }, () =>
          this.props.setIsVerified(true)
        );
      }
    }
  }

  handleValue(entity, value) {
    this.props.updateItem({ [entity]: value });
  }

  handleSectionVerified = () => {
    if (this.state.isVerified) {
      this.setState({
        openVerifyModal: true,
        verifyModalTitle: 'Section Verified',
        verifyModalMessage:
          'This section is currently locked. Press the verified text in section header to unlock and make changes',
        verifyModalAction: null,
      });
    }
  };

  render() {
    const { source, isVerified } = this.state;
    const { item, coreSections } = this.props;
    const isVerifyEnabled = false; //temporarily disabling verify

    return (
      <Layout isDetailsVerified={isVerified}>
        <ConfirmationModal
          open={this.state.openVerifyModal}
          title={this.state.verifyModalTitle}
          body={this.state.verifyModalMessage}
          iconName="CircledExclamationmark"
          close={() => this.setState({ openVerifyModal: false })}
          action={this.state.verifyModalAction}
          confirmBtnTxt={this.state.verifyModalBtnText}
        />

        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Details</div>
              {isVerifyEnabled && (
                <span onClick={() => this.handleVerify()}>
                  {isVerified ? 'Verified' : 'Verify?'}
                </span>
              )}
            </div>
            <div className="form-body-container">
              {item && item.nonStandardVin ? (
                <CustomInputs
                  handleSectionVerified={this.handleSectionVerified}
                  handleValue={(property, value) =>
                    this.handleValue(property, value)
                  }
                  isVerified={isVerified}
                />
              ) : (
                <SelectDetails
                  handleSectionVerified={this.handleSectionVerified}
                  handleValue={(property, value) =>
                    this.handleValue(property, value)
                  }
                  handleBodyStyle={value => this.handleBodyStyle(value)}
                  isVerified={isVerified}
                  source={source}
                  getDefaultValue={property => this.getDefaultValue(property)}
                />
              )}

              {showRow('s1:r6', coreSections) && (
                <div className="form-checkbox-container">
                  <span>Heavy Trucks?</span>
                  <div className="h-100" onClick={this.handleSectionVerified}>
                    <label className="form-checkbox-label mr-4">
                      Yes
                      <input
                        type="radio"
                        name="heavyTruck"
                        defaultChecked={item.heavyTruckOption}
                        onChange={() => this.handleHeavyTruckButton(true)}
                        disabled={isVerified}
                      />
                      <span className="form-checkbox-checkmark" />
                    </label>
                    <label className="form-checkbox-label mr-4">
                      No
                      <input
                        type="radio"
                        name="heavyTruck"
                        defaultChecked={!item.heavyTruckOption}
                        onChange={() => this.handleHeavyTruckButton(false)}
                        disabled={isVerified}
                      />
                      <span className="form-checkbox-checkmark" />
                    </label>
                  </div>
                </div>
              )}
            </div>
            <FormFooter currentSection="s1" />
          </div>
        </div>
      </Layout>
    );
  }
}

Details.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { variant, vin, item, isVerified, coreSections } = state.core;
  return { variant, vin, item, isVerified, coreSections };
};

export default connect(mapStateToProps, {
  updateItem,
  setIsVerified,
})(Details);
