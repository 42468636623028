import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const ResetFiltersDisplayCustom = props => {
  const { searchkit, hasFilters, resetFilters, bemBlock, translate } = props;

  const resetData = () => {
    let sortFailureCheck = null;

    if (searchkit.error) {
      sortFailureCheck =
        get(searchkit, 'error.data.error.root_cause[0].reason') || null;
    }

    if (hasFilters) {
      resetFilters();
    }

    if (searchkit.state.sort && sortFailureCheck) {
      searchkit.setQueryProcessor(query => {
        let sortName = null;
        // let sortOrder = null;
        let sortSplit = null;
        if (searchkit.state.sort && sortFailureCheck) {
          sortSplit = searchkit.state.sort.split('_');
          sortName = sortSplit.length === 3 ? '_' + sortSplit[1] : sortSplit[0];
          // sortOrder = sortSplit.length === 3 ? sortSplit[2] : sortSplit[1];
        }

        if (searchkit.state.sort && sortFailureCheck) {
          let sortFailureCheck = null;
          if (searchkit.error) {
            sortFailureCheck = searchkit.error.data.error.root_cause[0].reason;
          }
          if (
            searchkit.error &&
            sortFailureCheck &&
            sortFailureCheck.includes(sortName)
          ) {
            query.sort = [];
            query.sort.push({ lotNumber: 'asc' });
            searchkit.state.sort = 'lotNumber_asc';
            searchkit.accessors.accessors[21].resultsState.value = 'lotNumber';
          }
        }
        return query;
      });

      searchkit.reloadSearch();
    }
  };

  let sortFailureCheck = null;
  if (searchkit.error) {
    sortFailureCheck = searchkit.error.data.error.root_cause[0].reason;
  }

  let setDisable = sortFailureCheck ? false : !hasFilters;

  return (
    <div
      onClick={() => {
        props.resetDistanceAndLocation();
        resetData.bind(this)();
      }}
      className={bemBlock().state({ disabled: setDisable })}
      style={{ width: 200 }}
    >
      <div
        className={bemBlock('reset')}
        style={{
          cursor: 'pointer',
          color:
            props.userSelectedDistance !== '100000' ? '#0088CC' : 'inherit',
        }}
      >
        {translate('reset.clear_all')}
      </div>
    </div>
  );
};

ResetFiltersDisplayCustom.defaultProps = {
  resetDistanceAndLocation: () => {},
  userSelectedDistance: '100000',
};

ResetFiltersDisplayCustom.propTypes = {
  resetDistanceAndLocation: PropTypes.func,
  userSelectedDistance: PropTypes.string,
};

export default ResetFiltersDisplayCustom;
