import React, { Component } from 'react';
import { connect } from 'react-redux';

class Crossover4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M622.6,191.4c-0.1,0.3-0.3,0.4-0.5,0.6l-0.7,0.1l-0.7-0.3c-2.9-0.7-6.1-1.2-9.6-1.8
	c-16.5-1.9-31.4-3.2-45-3.7c-31.5-1.6-64.2-2.1-98.1-1.1c-21.5,0.3-39.3,1.1-53.8,2.3c-10.5,1-19.1,2.2-26,3.6l-0.7,0.3l-0.7-0.1
	c-0.3-0.1-0.4-0.3-0.5-0.6c-0.1-0.3,0-0.6,0.1-0.7c0.1-0.3,0.3-0.4,0.5-0.6c2.3-0.7,5.1-1.4,8.1-1.9c2.8-0.6,5.8-1,8.9-1.4
	c10.3-1.6,24.6-2.9,42.8-3.9c-19,0.4-36.2,1.2-51.6,2.6l-18.7,1.9c-15.7,2.6-27.5,7-35.4,13.5h43.1c27.1-1.8,56.5-2.8,88.3-3
	c27-0.1,55.6,0.1,85.8,1c14.6,0,24.1,2.6,28.3,7.8l58.6-2.5l-0.1-0.1l0.1,0.1l11.8-0.6c-0.8-2.6-3.4-4.5-7.8-5.5
	c-6.3-2.1-9.5-4.7-9.4-7.7c-6.3-1-13.2-1.9-20.5-2.8l-19.7-1.6l-7.4-0.6c7.6,0.8,14.7,1.9,21.5,3.3c3,0.6,5.8,1.2,8.1,1.9
	c0.3,0.1,0.4,0.3,0.5,0.6C622.6,191,622.8,191.2,622.6,191.4L622.6,191.4z"
          />
          <path
            fill="#FFFFFF"
            d="M622.1,192c0.3-0.1,0.4-0.3,0.5-0.6c0.1-0.3,0-0.6-0.1-0.7c-0.1-0.3-0.3-0.4-0.5-0.6
	c-2.3-0.7-5.1-1.4-8.1-1.9c-6.7-1.4-13.9-2.5-21.5-3.3l-16-1.1c-23.9-1.4-51.5-2.1-82.4-2.2c-9.4,0-18.6,0-27.2,0.1l-19.7,0.6
	c-18.3,1-32.5,2.2-42.8,3.9c-3.3,0.4-6.3,1-8.9,1.4c-3,0.6-5.8,1.2-8.1,1.9c-0.3,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.1,0.6-0.1,0.7
	c0.1,0.3,0.3,0.4,0.5,0.6l0.7,0.1l0.7-0.3c6.9-1.5,15.5-2.8,26-3.6c14.4-1.4,32.5-2.1,53.8-2.3c33.8-1,66.6-0.6,98.1,1.1
	c13.5,0.6,28.5,1.6,45,3.7c3.6,0.6,6.7,1.1,9.6,1.8l0.7,0.3L622.1,192L622.1,192z M586.7,211.9l2.8,12.1l-3.9,27.9l12.9-0.6
	l-9.2-40.2c-0.4-1.8-1.2-3.4-2.3-4.9c-4.3-5.2-13.8-8-28.3-7.8c-30.3-0.8-58.9-1.2-85.8-1l-0.5,2.8h10.3c24.1,0,49.4,0.3,76.1,1.1
	C575.4,201.2,584.8,204.8,586.7,211.9L586.7,211.9z M598,254.2l-137.4,5.6c-3.6,10.3-5.9,22.4-6.7,36.5c-2.2,24.6-1.9,49.7,0.4,74.7
	l90.5-1.7c0.8-10.9,3.3-21.3,7.6-31.5c3.7-8.3,9.4-15.8,16.6-22.7c10.3-9.5,22-15.7,35.1-18.7l-0.4-26l-4.4-16.2L598,254.2
	L598,254.2L598,254.2z M564.6,281.1c0-0.5,0.5-1.1,1.7-1.5c1.1-0.4,2.5-0.7,4.1-0.7h0.5c0.3-1,1-1.8,1.7-2.6c1.4-1.4,3-2.1,5.1-2.1
	h3.7c1.9,0,3.6,0.7,5,2.1c0.5,0.5,1,1.1,1.4,1.6l1.8-0.1c0.5-0.7,1.1-1,1.9-1h0.1c2.1,0,3.9,0.4,5.4,1c1.7,0.7,2.5,1.4,2.5,2.3
	s-0.8,1.6-2.5,2.3c-1.5,0.5-3.3,1-5.4,1h-0.1c-0.7,0-1.2-0.3-1.8-0.8h-1.2c-0.3,1.2-1,2.3-1.9,3.2c-1.4,1.4-3,2.1-5,2.1h-3.7
	c-1.9,0-3.6-0.7-5.1-2.1c-0.8-0.8-1.4-1.6-1.7-2.6h-0.5c-1.7,0-3-0.3-4.1-0.7C565.1,282.2,564.6,281.7,564.6,281.1L564.6,281.1z"
          />
          <path
            fill="#FFFFFF"
            d="M566.2,279.5c-1.1,0.4-1.7,1-1.7,1.5s0.5,1.1,1.7,1.5c1.1,0.4,2.5,0.7,4.1,0.7h0.5l17.3-0.5h1.2
	c0.5,0.5,1.1,0.8,1.8,0.8h0.1c2.1,0,3.9-0.4,5.4-1c1.7-0.7,2.5-1.4,2.5-2.3s-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1
	c-0.7,0-1.4,0.3-1.9,1l-1.8,0.1l-16.8,0.8h-0.5C568.7,278.9,567.3,279.1,566.2,279.5L566.2,279.5z"
          />
          <path
            fill="#FFFFFF"
            d="M572.7,276.3c-0.8,0.8-1.4,1.6-1.7,2.6l16.8-0.8c-0.3-0.5-0.8-1.2-1.4-1.6c-1.4-1.4-3-2.1-5-2.1h-3.7
	C575.7,274.3,574.1,274.9,572.7,276.3L572.7,276.3z M570.9,283.3c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7
	c1.9,0,3.6-0.7,5-2.1c1-1,1.7-2.1,1.9-3.2L570.9,283.3L570.9,283.3z M598.5,251.4l0.7,2.8l92.7-3.9l-3.6-2.6l-16.1,0.7 M681.8,197.9
	c0.8-0.7,1-1.5,0.3-2.5l-0.1-0.1c-1-1.4-3.4-2.3-7.4-2.9l-34.4-2.2c0,3,3,5.5,9.4,7.7c4.4,1,7,2.8,7.8,5.5l8.7-0.4
	C673.9,202.3,679.2,200.7,681.8,197.9L681.8,197.9z M666.1,203l-8.7,0.4c10,11.8,20.4,26.6,31,44.3l14-0.6
	C689.7,229.4,677.6,214.7,666.1,203L666.1,203z"
          />
          <path
            fill="#FFFFFF"
            d="M650.4,210.6l-1.5,2.2l15.8,27.5l3.6,1.1l4.1,7l16.1-0.7c-10.6-17.7-20.9-32.6-31-44.3l-11.8,0.6
	L650.4,210.6L650.4,210.6z"
          />
          <path
            fill="#FFFFFF"
            d="M688.4,247.7l3.6,2.6l10.7-0.4c0.4,0,0.7-0.1,1-0.4l0.1-0.1c0.1-0.3,0.3-0.6,0.3-0.8c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.7-0.4-1-0.4L688.4,247.7L688.4,247.7z"
          />
          <path
            fill="#D3D3D3"
            d="M694.1,261.3h4.8c2.2,0.1,3.9,0.8,5.1,2.2l-0.7-1.4c-1.4-1.5-3.4-2.3-6.2-2.5h-5.9c-3.3,0-5.4,1.8-6.1,5.2
	c-0.7,4.8-0.5,11.8,0.4,20.8c0.1,10.2,1.9,17.5,5.2,21.6l0.3,0.4c1.5,1.4,4,2.1,7.7,2.3c4,0.1,6.9-0.7,8.4-2.8
	c0.6-0.7,1-1.5,1.2-2.3l0.1-0.8c0.3-2.9,0.4-6.7,0.1-11.6c0.1,4.1,0,7.3-0.3,9.6c-0.8,3.3-3.6,4.8-8,4.5c-3-0.3-5.1-1-6.3-2.1
	c-2.9-3.6-4.4-10.2-4.5-19.8c-0.7-8.1-0.8-14.3-0.3-18.7C689.7,263,691.5,261.5,694.1,261.3L694.1,261.3z"
          />
          <path
            fill="#FFFFFF"
            d="M700.1,257.1c-2.3-2.9-5-5.1-8.1-6.7l-92.7,3.9l4.4,16.2l0.4,26c6.2-1.4,12.7-2.2,19.4-2.2
	c21.3,0,39.5,7,54.5,20.9l0.1,0.1h30.4V304l-0.1,0.8c-0.3,1-0.7,1.7-1.2,2.3c-1.7,2.1-4.4,2.9-8.4,2.8c-3.6-0.3-6.2-1.1-7.7-2.3
	l-0.3-0.4c-3.3-4.1-5-11.3-5.2-21.6c-1-8.9-1.1-16-0.4-20.8c0.8-3.4,2.8-5.2,6.1-5.2h5.9c2.6,0.1,4.7,1,6.2,2.5
	c-0.7-1.2-1.4-2.3-2.2-3.4L700.1,257.1L700.1,257.1z M639.3,266.7c2.5,2.5,3.8,5.6,3.8,9.2s-1.2,6.6-3.8,9.1
	c-2.5,2.5-5.5,3.9-9.1,3.9s-6.6-1.2-9.2-3.9c-2.5-2.5-3.7-5.5-3.7-9.1s1.2-6.6,3.7-9.2c2.5-2.5,5.6-3.7,9.2-3.7
	S636.8,264.2,639.3,266.7L639.3,266.7z"
          />
          <path
            fill="#FFFFFF"
            d="M643.1,275.9c0-3.6-1.2-6.6-3.8-9.2c-2.5-2.5-5.5-3.7-9.1-3.7s-6.6,1.2-9.2,3.7c-2.5,2.5-3.7,5.6-3.7,9.2
	s1.2,6.6,3.7,9.1s5.6,3.9,9.2,3.9s6.6-1.2,9.1-3.9C641.9,282.5,643.1,279.5,643.1,275.9L643.1,275.9z M691.9,250.3
	c3,1.6,5.8,4,8.1,6.7l1.2,1.5c0.8,1.1,1.5,2.2,2.2,3.4c0.7,0.7,1.1,1.5,1.5,2.5v0.1c1.5,4,2.6,11.6,3.4,22.7l0.3,5.1
	c0.3,4.8,0.3,8.7-0.1,11.6v11.3l10.7,8.7c5.5-23,5.5-42.8-0.3-59c-0.5-1.1-1.1-2.1-1.8-3l-2.2-3.2c-3.2-4.3-7-7.4-11.4-9.5l-0.1,0.1
	c-0.3,0.3-0.5,0.4-1,0.4L691.9,250.3L691.9,250.3z M569.1,315.2c-7.4,6.9-12.9,14.4-16.6,22.7c-4.3,10.2-6.7,20.6-7.6,31.5l18.4-0.4
	l-18.4,0.4l-90.5,1.6l-148.4,2.8l-5.5,0.1l-15.4,0.3v16.1l217.2-1.7l61.1-0.4c-0.1-4.7,0-9.6,0.1-14.9c0-1.4,0.1-2.8,0.3-4
	c0.3-2.9,0.7-5.9,1.4-8.7c0.6-2.8,1.4-5.4,2.3-8c0.8-2.3,1.8-4.7,2.9-6.9c2.8-5.5,6.3-10.6,10.9-15.4c7-7.4,14.7-12.7,23.4-15.8
	l-0.3-18C591,299.4,579.3,305.8,569.1,315.2L569.1,315.2z"
          />
          <path
            fill="#FFFFFF"
            d="M454.2,371.1c-2.3-25.2-2.5-50.1-0.4-74.7c1-13.9,3.2-26.1,6.7-36.5l-109.5,4.5l-1.2,2.1
	c-1.6,2.2-3.7,3.3-6.2,3.4c0.7,4.7-1,8-5,9.6c-5.1,1.6-10.3,1.6-15.7,0c-3.3-1.8-4.1-4-2.3-6.6l3.6-4.7l0.5-1.2l0.5-1.4l-11.1,0.4
	h-0.5c-8.7,4.4-13.1,20.8-13.1,49.1v0.3l0.1,1c1.1,23.5,2.8,42.6,5.4,57.6L454.2,371.1L454.2,371.1z M451.5,284.2
	c0,1-0.8,1.6-2.5,2.3c-1.5,0.5-3.3,1-5.4,1h-0.1c-0.7,0-1.2-0.3-1.8-0.8h-1.2c-0.3,1.2-1,2.3-1.9,3.2c-1.4,1.4-3,2.1-5,2.1h-3.7
	c-1.9,0-3.6-0.7-5.1-2.1c-0.8-0.8-1.4-1.6-1.6-2.6h-0.5c-1.6,0-3-0.3-4.1-0.7s-1.7-1-1.7-1.5s0.5-1.1,1.6-1.5s2.5-0.7,4.1-0.7h0.5
	c0.3-1,1-1.8,1.6-2.6c1.4-1.4,3-2.1,5.1-2.1h3.7c1.9,0,3.6,0.7,5,2.1c0.5,0.5,1,1.1,1.4,1.6l1.8-0.1c0.5-0.7,1.1-1,1.9-1h0.1
	c2.1,0,3.9,0.4,5.4,1C450.7,282.4,451.5,283.2,451.5,284.2L451.5,284.2z"
          />
          <path
            fill="#FFFFFF"
            d="M449,286.5c1.6-0.7,2.5-1.4,2.5-2.3c0-1-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1c-0.7,0-1.4,0.3-1.9,1
	l-1.8,0.1l-16.8,0.8h-0.5c-1.6,0-3,0.3-4.1,0.7s-1.6,1-1.6,1.5s0.5,1.1,1.6,1.5s2.5,0.7,4.1,0.7h0.5l17.3-0.5h1.2
	c0.5,0.5,1.1,0.8,1.8,0.8h0.1C445.7,287.3,447.5,287.1,449,286.5L449,286.5z"
          />
          <path
            fill="#FFFFFF"
            d="M438.6,280.2c-1.4-1.4-3-2.1-5-2.1h-3.7c-1.9,0-3.6,0.7-5.1,2.1c-0.8,0.8-1.4,1.6-1.6,2.6l16.8-0.8
	C439.5,281.3,439.1,280.7,438.6,280.2L438.6,280.2z M423,287.2c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7c1.9,0,3.6-0.7,5-2.1
	c1-1,1.6-2.1,1.9-3.2L423,287.2L423,287.2z M657.1,321.6c3.4,2.5,6.6,5.2,9.6,8.5c3.4,3.7,6.3,7.6,8.8,11.6h20.8
	c-1.8-4.5-4-8.8-6.6-12.8c-2.6-4-5.8-7.8-9.5-11.4l-2.2-2.1l-0.1-0.1c-15-13.9-33.2-20.9-54.5-20.9c-6.7,0-13.3,0.7-19.4,2.2l0.3,18
	c6.1-2.2,12.7-3.3,19.5-3.3C636.4,311.1,647.5,314.6,657.1,321.6L657.1,321.6z"
          />
          <path
            fill="#FFFFFF"
            d="M678.2,315.4l2.2,2.1c3.6,3.6,6.7,7.4,9.5,11.4c2.6,4.1,5,8.4,6.6,12.8c1.4,3.7,2.5,7.6,3.3,11.6
	c1.8,6.1,5.1,9.2,9.8,9.5h12.5c2.3,0,3.7-1.4,4.3-3.9c2.5-5.9,4.8-7.8,6.9-5.9l1.4,5.2c1-3.9,1.5-9.8,1.4-17.6c0-2.2,1-3.6,2.9-4.1
	l-1.1-5.1c-0.4-1.4-1.8-2.1-4-1.9c-6.2,0.1-11.1-1.6-14.6-5.4l-10.7-8.7L678.2,315.4L678.2,315.4z"
          />
          <path
            fill="#FFFFFF"
            d="M699.8,353.4c-0.8-4-1.9-7.8-3.3-11.6h-20.8l0.5,0.8c5.4,9.4,8.3,19.8,8.5,31.5v9.1
	c16.6-1,28.6-3.6,35.8-7.7c4.1-2.3,6.7-5.2,7.6-8.7c0.1-0.5,0.3-1.2,0.4-1.9c1.9-5.6,4-7.8,6.3-6.7l-1.4-5.2c-2.2-1.9-4.4,0-6.9,5.9
	c-0.5,2.5-2.1,3.9-4.3,3.9h-12.5C704.9,362.6,701.7,359.4,699.8,353.4L699.8,353.4z"
          />
          <path
            fill="#D3D3D3"
            d="M704.9,264.5c-0.4-1-0.8-1.8-1.5-2.5l0.7,1.4l0.8,1.2V264.5L704.9,264.5z"
          />
          <path
            fill="#FFFFFF"
            d="M169.2,315.2c-1.1,1-2.1,1.9-3,2.9c-6.9,7.2-11.8,15-15,23.7c-1.2,3.4-2.2,7-2.9,10.6h16.6
	c0.8-2.3,1.9-4.7,3-6.9c2.9-5.5,6.5-10.6,11-15.4c12.1-12.7,26.7-19,43.9-19c12.7,0,24.1,3.4,34,10.5c3.4,2.5,6.7,5.2,9.9,8.5
	c3.7,3.9,6.9,8.1,9.5,12.5c5.5,9.4,8.4,19.8,8.7,31.5l15.4-0.3c-0.5-5.6-1.4-12-2.5-19c-1.6-9.5-5-18.2-10-26
	c-2.6-4-5.6-7.8-9.2-11.4l-2.3-2.2c-4.3-4-8.8-7.6-13.6-10.5c-4.8-2.9-10-5.2-15.4-6.9c-7.6-2.5-15.7-3.6-24.4-3.6
	C201.8,294.3,184.1,301.2,169.2,315.2L169.2,315.2z"
          />
          <path
            fill="#FFFFFF"
            d="M114,327.5c0.8,4-0.4,6.3-3.7,7c-3.9,0-6.1,2.2-6.3,6.3v12.5c0,2.1,1,3.4,2.6,4.1c0.6,0.3,1.4,0.4,2.1,0.6
	h3.2c2.3,0.3,3.4,1.8,3.6,4.7v13.2c0,1.9-0.8,3.3-2.8,4.3l-1,0.4l-1.9,0.5l-0.4,0.1l1.7-0.3l1.9-0.5l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-5
	c0.1-1.9,1-3,2.5-3.3l21.9-1.9c4.3,0,6.5-1.9,6.5-5.6c0.1-2.5,0.6-5,1-7.4c0.7-3.7,1.7-7.2,2.9-10.6c3.2-8.5,8.1-16.5,15-23.7
	l-39.9,3.7v2.5c0.1,1.1,0,1.9-0.4,2.5c-0.4,0.4-1,0.7-1.7,0.7c-0.6-0.4-1-1-1.2-1.6c-0.4-1.5-1.4-2.2-2.6-2.2h-4.3
	C114.2,323.8,113.5,325,114,327.5L114,327.5z"
          />
          <path
            fill="#FFFFFF"
            d="M123,325.8c0.3,0.7,0.7,1.2,1.2,1.6c0.7,0,1.2-0.3,1.7-0.7l-1.1-2.5c-0.8-1.8-2.3-2.6-4.3-2.6
	c-2.3,0.1-3.3,0.8-2.9,1.9h2.9C121.7,323.6,122.6,324.5,123,325.8L123,325.8z"
          />
          <path
            fill="#FFFFFF"
            d="M126.4,324.3v-2.5c0-5.1-0.8-11.3-2.3-18.3c-1-3.6-2.6-5.6-5.1-6.2c-0.6,9.9,0,18,1.5,24.3
	c2.1-0.1,3.4,0.7,4.3,2.6l1.1,2.5C126.3,326.3,126.6,325.4,126.4,324.3L126.4,324.3z M111.8,358h-3.2c-0.3,3.4-0.3,6.9,0.1,10.3
	c0.4,4,1.4,8.1,2.8,12.4l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-13.2C115.4,359.8,114.2,358.3,111.8,358L111.8,358z"
          />
          <path
            fill="#FFFFFF"
            d="M108.8,358c-0.8-0.1-1.5-0.3-2.1-0.5c-0.8,7.7,0.1,15.7,3,23.8l1.9-0.6c-1.4-4.1-2.3-8.3-2.9-12.4
	C108.4,364.9,108.4,361.5,108.8,358L108.8,358z"
          />
          <path
            fill="#FFFFFF"
            d="M116.5,370.8v5c0,1.9-0.8,3.3-2.8,4.3l-1,0.4l-1.9,0.5l-1.7,0.3l-2.3,0.3c-1.2,0.5-1,1.9,0.8,4.3
	c1.7,1.6,5.2,2.9,10.9,3.7c7.2,1,16.1,1.6,26.7,1.9c4.8,0.1,10,0.3,15.7,0.3c-0.3-5.6-0.3-12,0-18.6c0.3-7.4,1.6-14.3,4-20.8h-16.6
	c-0.4,2.5-0.8,4.8-1,7.4c0,3.9-2.2,5.8-6.5,5.6l-21.9,1.9C117.6,367.8,116.8,368.9,116.5,370.8L116.5,370.8z M158.2,402.6
	c2.3,0.4,3.6,0.1,3.6-1v-0.3c-0.3-3-0.6-6.2-0.7-9.5c-5.6,0-10.9-0.1-15.7-0.3c0.3,4.4,1.9,7.2,5.2,8.5L158.2,402.6L158.2,402.6z
	 M335.1,248c-1,0.4-1.9,1-2.9,1.5l-0.5,0.4c-2.6,1.8-4.1,3.9-4.7,6.3c-0.1,0.7-0.3,1.2-0.3,1.9c0,0.8,0.1,1.5,0.3,2.3
	c0.1,0.7,0.5,1.5,1,2.2c0.5,1,1.2,1.8,2.2,2.6l2.1,1.5c1.8,1.1,3.6,1.9,5.8,2.5l2.8,0.4l1.2,0.1c2.2-0.3,4-1.4,5.5-3.4
	c0.4-0.5,0.8-1.2,1.2-1.9c0.4-0.8,0.8-1.8,1.1-2.8c0.3-1.1,0.4-2.3,0.4-3.6c0-3.2-0.8-5.9-2.6-8.3c-1.5-2.1-3.4-3.2-5.5-3.3
	l-1.2,0.1C338.7,246.9,336.9,247.3,335.1,248L335.1,248z"
          />
          <path
            fill="#D3D3D3"
            d="M326.9,258.2c0-0.7,0.1-1.4,0.3-1.9l-6.5,6.7l5.6-0.3l0.8-2.2C326.9,259.7,326.9,259,326.9,258.2
	L326.9,258.2z"
          />
          <path
            fill="#FFFFFF"
            d="M343.4,246.5h-1.5c2.2,0.3,4,1.4,5.5,3.3c1.8,2.3,2.6,5.1,2.6,8.3c0,1.2-0.1,2.5-0.4,3.6
	c-0.3,1-0.5,1.9-1.1,2.8c-0.4,0.7-0.8,1.4-1.2,1.9c-1.5,2.1-3.4,3.2-5.5,3.4h1.8c2.5,0,4.4-1.2,6.2-3.4c0.5-0.7,1-1.4,1.2-2.1
	c0.4-0.8,0.8-1.8,1-2.8c0.3-1.1,0.4-2.2,0.4-3.4c0-3.2-0.8-5.9-2.6-8.3C348,247.7,346,246.5,343.4,246.5L343.4,246.5z M297.4,278.4
	c0.5-0.5,0.8-1,1-1.4v-0.4c-0.1-0.5-1.1-0.8-2.9-0.8l-9.4,0.3c-2.9,0-5.2,1-7.3,2.6l-17.1,14.9c-2.5,2.1-2.2,3.2,0.8,3.3l10,0.4
	c3.3,0.1,6.2-1,8.5-3.2L297.4,278.4L297.4,278.4z M291.6,277.8c1.2-0.3,2.1-0.1,2.3,0.3c0.3,0.4,0,1-0.8,1.8l-14.4,12.8
	c-0.8,0.7-1.9,1.4-3.3,1.9c-1.4,0.5-2.5,0.7-3.4,0.7s-1.5-0.3-1.6-0.8c0-0.5,0.3-1.1,1.1-1.8l13.1-11.7c0.8-0.7,1.8-1.4,3.2-1.9
	C289,278.4,290.4,278,291.6,277.8L291.6,277.8z"
          />
          <path
            fill="#FFFFFF"
            d="M294,278.1c-0.3-0.4-1.1-0.5-2.3-0.3c-1.2,0.1-2.5,0.5-3.9,1.2c-1.4,0.5-2.5,1.2-3.2,1.9l-13.1,11.7
	c-0.8,0.7-1.1,1.2-1.1,1.8c0.1,0.5,0.7,0.8,1.6,0.8s2.1-0.3,3.4-0.7c1.4-0.5,2.5-1.2,3.3-1.9l14.4-12.8
	C294,279.1,294.2,278.5,294,278.1L294,278.1z M340.7,269.7l-2.8-0.4c-2.1-0.5-4-1.4-5.8-2.5l-2.1-1.5c-1-0.8-1.6-1.8-2.2-2.6
	c-0.4-0.7-0.7-1.4-1-2.2l-0.8,2.2l-1.1,2.8l-0.5,1.4c0.7,3.9,6.3,4.8,17.2,3L340.7,269.7L340.7,269.7z"
          />
          <path
            fill="#FFFFFF"
            d="M343.6,269.9h-1.8c-10.9,1.8-16.6,0.8-17.2-3l-0.5,1.2l-3.6,4.7c-1.8,2.6-1,4.8,2.3,6.6
	c5.4,1.6,10.6,1.6,15.7,0C342.7,277.7,344.3,274.5,343.6,269.9L343.6,269.9z M597.8,254.3l0.1-0.1h1.2l-0.7-2.8l-12.9,0.6
	l-109.8,4.5l-14.6,0.5l-0.5,2.8 M472.1,200.2l0.5-2.8c-31.8,0.3-61.2,1.2-88.3,3c-0.3,0-0.5,0.1-0.8,0.3
	c-22,18.2-42.8,37.1-61.9,57.1l-5,5.2l-3.2,2.8h0.5l11.1-0.4l1.1-2.8l-5.6,0.3l6.5-6.7c0.5-2.3,2.1-4.5,4.7-6.3l0.5-0.4
	c1-0.6,1.9-1.1,2.9-1.5c15.7-15.5,32.3-30.4,49.8-44.8c23.9-1.5,49.7-2.5,77.2-2.9L472.1,200.2L472.1,200.2L472.1,200.2z"
          />
          <path fill="#FFFFFF" d="M472.1,200.2h-10l-15.8,57.5l15-0.7" />
          <path
            fill="#FFFFFF"
            d="M460.7,259.8l0.6-2.8l-15,0.7l-94.1,3.9c-0.3,1-0.5,1.9-1,2.8L460.7,259.8L460.7,259.8z M461.2,257.1
	l14.7-0.5l6.6-56.4h-10.3L461.2,257.1L461.2,257.1z M166.3,318.1c1-1,1.9-2.1,3-2.9c14.7-13.9,32.6-20.9,53.5-20.9
	c8.7,0,16.8,1.2,24.4,3.6c12.8-12.2,28.2-23.2,46.2-33l-48.1-2.5c-12.4,0-24.2,0.1-35.4,0.5c-33.7,2.6-59,8.3-76.1,16.9
	c-9.5,5.1-15.1,9.5-16.9,13.1c-1.1,2.2-0.7,3.7,1.2,4.4l0.8,0.1c2.5,0.5,4.1,2.6,5.1,6.2c1.5,7,2.3,13.1,2.3,18.3L166.3,318.1
	L166.3,318.1z M177.9,275.4l1.5-0.3c15-2.6,29.6-4.3,43.6-5.1c-14,0.8-28.5,2.5-43.6,5.1c4.7-0.7,7.8,0.1,9.2,2.5
	c1.6,2.9,0.6,6.5-3.3,10.7c-6.3,7.6-14.9,15.5-25.6,23.7c-3.9,2.9-8.8,4.3-15,4.1c-4,0-6.2-1.5-6.5-4.5c-0.8-3.6-0.6-8.5,1-14.9
	c1.2-4.1,3.6-7.4,6.9-9.8C152.1,282.8,162.7,278.9,177.9,275.4L177.9,275.4z"
          />
          <path
            fill="#D3D3D3"
            d="M179.5,275.1l-1.5,0.3c-15.3,3.6-25.9,7.4-31.6,11.6c-3.3,2.3-5.6,5.6-6.9,9.8c-1.5,6.5-1.8,11.4-1,14.9
	c0.3,3,2.5,4.5,6.5,4.5c6.2,0.1,11.1-1.2,15-4.1c10.7-8.3,19.1-16.1,25.6-23.7c3.9-4.3,5-7.8,3.3-10.7
	C187.4,275.2,184.2,274.4,179.5,275.1L179.5,275.1z M172.5,278.2c4.1-0.8,6.9-0.3,8.4,1.5c1.9,2.1,1.5,5-1.1,8.3
	c-5,6.5-11.4,12.9-19.7,19.4c-4,3.4-8.1,5.4-12.2,5.9c-4.1,0.8-6.7,0.3-7.8-1.8c-0.1-0.4-0.3-0.8-0.3-1.2c-0.3-1-0.4-2.2-0.4-3.7
	v-0.3c0-2.9,0.6-6.1,1.5-9.8c1.2-3.9,3.4-6.7,6.6-8.9C152.4,284.3,160.7,281.1,172.5,278.2L172.5,278.2z"
          />
          <path
            fill="#FFFFFF"
            d="M321.6,257.9c19.3-19.9,39.9-38.9,61.9-57.1c0.3-0.1,0.5-0.3,0.8-0.3h-11.3l-70.2,62.6h13.8L321.6,257.9
	L321.6,257.9z"
          />
          <path
            fill="#FFFFFF"
            d="M313.4,265.9l3.2-2.8h-13.8l-29-0.1c-6.9-0.1-13.5-0.3-19.9-0.4l-8.7-0.1l48.1,2.5l14,0.7L313.4,265.9
	L313.4,265.9z"
          />
          <path
            fill="#FFFFFF"
            d="M313.4,265.9l-6.1-0.3l-7,4.8c-1.1,1-1.6,1.9-1.9,3.2v4.1c-2.3,4-8.7,10.5-19,19.4c-1.8,1.1-3.4,1.8-5.1,1.9
	h-0.1c-2.8-0.4-4.8-0.1-6.3,1l-0.3,0.3l-5.1,4.5c4.8,2.9,9.4,6.3,13.6,10.5l2.3,2.2c3.6,3.6,6.7,7.4,9.2,11.4
	c5.1,7.8,8.5,16.5,10,26c1.2,7,2.1,13.2,2.5,19l5.5-0.1c-2.6-15-4.4-34.3-5.4-57.6l-0.1-1v-0.3C300.4,286.6,304.7,270.3,313.4,265.9
	L313.4,265.9z"
          />
          <path
            fill="#FFFFFF"
            d="M298.4,277c-0.1,0.4-0.4,0.8-1,1.4l-16.2,15.7c-2.3,2.2-5.2,3.3-8.5,3.2l-10-0.4c-3-0.1-3.3-1.2-0.8-3.3
	l17.1-14.9c1.9-1.8,4.4-2.6,7.3-2.6l9.4-0.3c1.8,0,2.8,0.1,2.9,0.8v-3c0.3-1.2,1-2.2,1.9-3.2l7-4.8l-14-0.7
	c-18,9.8-33.4,20.8-46.2,33c5.4,1.6,10.5,4,15.4,6.9l5.1-4.5l0.3-0.3c1.5-1.1,3.6-1.5,6.3-1h0.1c1.6-0.1,3.4-0.8,5.1-1.9
	c10.5-8.9,16.8-15.4,19-19.4L298.4,277L298.4,277z M625.5,167.8c-0.3,0.1-0.4,0.3-0.4,0.4l-9.9,13.1v0.1c-0.4,1.1,0.4,1.8,2.2,1.9
	l9.2-14.7v-0.6c-0.1-0.3-0.3-0.4-0.4-0.4L625.5,167.8L625.5,167.8z"
          />
          <path
            fill="#FFFFFF"
            d="M617.4,183.3c-1.9-0.1-2.6-0.8-2.2-1.9v-0.1c-1.8-0.8-5-1-9.5-0.7c-3.2,0-5.1,1.6-5.9,5l19.8,1.9
	C619.2,184.7,618.3,183.3,617.4,183.3L617.4,183.3z"
          />
          <path
            fill="#656565"
            d="M570.2,345.5c-1.1,2.2-2.1,4.5-2.9,6.9c-1,2.6-1.7,5.2-2.3,8s-1.1,5.6-1.4,8.7c-0.1,1.4-0.3,2.8-0.3,4
	c-0.3,5.4-0.3,10.3-0.1,14.9v1.2l0.7-0.1c0.5,0,1.1,0.3,1.7,0.7c0.4,0.4,0.7,1,0.7,1.6v1.9l11.4-0.1c-2.8-6.3-4.3-13.2-4.3-20.6
	c0-12.4,3.9-23.2,11.7-32.3c1-1.1,2.1-2.3,3-3.3c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1s20.6,3,29.2,9.1
	c2.3,1.6,4.5,3.6,6.6,5.6c2.6,2.6,4.8,5.2,6.6,8c5.5,8.1,8.1,17.3,8.1,27.6c0,5.2-0.7,10.2-2.1,14.7l12.5-0.8v-12.5
	c-0.3-11.7-3-22.1-8.5-31.5l-0.5-0.8c-2.5-4.1-5.4-8-8.8-11.6c-3-3.3-6.3-6.1-9.6-8.5c-9.8-7-20.8-10.5-33.3-10.5
	c-6.9,0-13.5,1.1-19.5,3.3c-8.7,3-16.5,8.4-23.4,15.8C576.5,334.9,573,340,570.2,345.5L570.2,345.5z"
          />
          <path
            fill="#656565"
            d="M563.2,389.4v-1.2l-61.1,0.4c1.8,2.1,5.5,3,11,3.3l48.4-0.1v-0.1c0-0.5,0.3-1.1,0.7-1.6
	C562.5,389.7,562.8,389.4,563.2,389.4L563.2,389.4z"
          />
          <path
            fill="#656565"
            d="M566.1,391.5c0-0.5-0.3-1.1-0.7-1.6c-0.4-0.4-1-0.7-1.7-0.7l-0.7,0.1c-0.3,0.1-0.7,0.3-1,0.5
	c-0.4,0.4-0.7,1-0.7,1.6v5.6c0,0.5,0.3,1.1,0.7,1.6c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.7-0.7c0.4-0.4,0.7-1,0.7-1.6L566.1,391.5
	L566.1,391.5z M659.3,336.8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6
	c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3c0,7.4,1.4,14.3,4.3,20.6c1.9,4.1,4.4,8.1,7.4,11.7c1,1.1,2.1,2.2,3,3.3
	c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c2.6-2.6,4.8-5.2,6.6-8
	c2.8-4,4.7-8.4,6.1-12.9c1.4-4.7,2.1-9.5,2.1-14.7c0-10.3-2.8-19.5-8.1-27.6C664.2,342.1,662,339.5,659.3,336.8L659.3,336.8z
	 M610.9,333.1h0.5H610.9z M588.2,372.6c0-10,3.6-18.6,10.7-25.7c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6
	c7.2,7.2,10.7,15.7,10.7,25.7c0,10-3.6,18.6-10.7,25.7c-7.2,7.2-15.7,10.6-25.7,10.6c-10,0-18.6-3.6-25.7-10.6
	C591.8,391.2,588.2,382.7,588.2,372.6L588.2,372.6z"
          />
          <path
            fill="#656565"
            d="M625.1,358l3.2,0.6c1.1,0.3,2.1-0.7,2.6-2.9l1.7-11.6c-2.5-0.7-5-1-7.7-1l-1.4,11.4
	C623.3,356.8,624,358,625.1,358L625.1,358z"
          />
          <path
            fill="#656565"
            d="M618.9,358.6c-0.8-4.1-1.2-9.1-1.1-14.7c-5,1.1-9.4,3.4-13.3,7.2c4.8,3,8.8,6.1,11.8,8.9
	c1.9,1.9,3.2,4,3.9,5.9l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.5,1.6-1.1,2.3l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1
	c-1.9,0.7-4.3,1-7,0.8c-4.3-0.5-9.1-1.6-14.4-3.6l-0.1,2.3c0,4.5,1,8.8,2.8,12.5c4.4-3.7,8.5-6.6,12.2-8.5c2.5-1.2,4.7-1.8,6.9-1.8
	l0.7-0.1c0.8-0.1,1.8,0.1,2.6,0.6c0.8,0.4,1.4,1,1.8,1.8l0.1,0.4c0.4,0.8,0.6,1.5,0.4,2.3l-0.1,0.7c0.1,2.1-0.4,4.4-1.5,6.9
	c-1.8,3.9-4.4,8.1-7.8,12.7c3.4,1.4,7.3,2.2,11.3,2.2l3.7-0.1c-2.2-5.2-3.6-9.9-4.3-14.2c-0.4-2.8-0.3-5.1,0.4-7v-0.5
	c0.1-0.8,0.7-1.6,1.4-2.3c0.5-0.5,1.4-1,2.2-1.2h0.4c0.8-0.1,1.7,0,2.3,0.3c0.3,0,0.4,0.1,0.5,0.3c2.1,0.5,4,1.8,6.1,3.4
	c3,2.9,6.3,6.6,9.5,11.3c3.4-4,5.6-8.5,6.6-13.6c-5.6,0.4-10.6,0.3-14.9-0.3c-2.6-0.5-4.8-1.4-6.6-2.6l-0.5-0.3
	c-0.8-0.4-1.4-1.1-1.8-2.1c-0.4-0.7-0.5-1.5-0.4-2.5v-0.4c0.1-0.8,0.5-1.5,1-2.1l0.4-0.4c1.2-1.8,2.9-3.3,5.2-4.7
	c3.7-2.1,8.3-3.9,13.8-5.6c-1.2-1.9-2.6-3.7-4.4-5.4c-2.1-2.1-4.1-3.7-6.5-5c-1.4,5.5-3,10.2-4.8,14c-1.2,2.3-2.8,4.1-4.4,5.5
	l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.7,0-2.5-0.3l-0.4-0.1c-0.7-0.4-1.4-1-1.7-1.7V365C620.6,363.4,619.6,361.2,618.9,358.6
	L618.9,358.6z M627.3,370c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1.1,0-1.9-0.4-2.6-1.1
	c-0.7-0.7-1.1-1.5-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.5-1.1,2.6-1.1C625.6,368.9,626.5,369.3,627.3,370L627.3,370z"
          />
          <path
            fill="#656565"
            d="M611.1,367.7l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1c-1.5,2.2-2.6,4.5-3.4,7l10.6,5
	C609.4,369,610.6,368.8,611.1,367.7L611.1,367.7z M601.7,391c0.7,0.8,1.4,1.6,2.2,2.3c1.1,1.1,2.2,2.1,3.4,3l8-8.5
	c1.4-1.9,1.7-3.2,0.7-3.9l-2.2-2.2c-0.7-1-2.1-0.7-3.9,0.8L601.7,391L601.7,391z M636.4,386.4c-1.4-1.9-2.5-2.5-3.4-1.8l-2.9,1.5
	c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3c2.5-1,4.7-2.2,6.9-3.7L636.4,386.4L636.4,386.4z M641.5,365.7c-2.2,0.7-3.2,1.6-2.8,2.8l0.5,3.2
	c0.1,1.1,1.2,1.6,3.6,1.5l11.4-1.9c-0.1-2.8-0.5-5.2-1.4-7.7L641.5,365.7L641.5,365.7z M684.7,383.2v3.4l7.4-0.4l44-8.4
	c2.6-0.6,4-1.4,3.9-2.3c-0.4-1.5-2.3-1.9-6.2-1.5l-1.4,0.1l-2.9,0.4l-9.2,1.2C713.3,379.6,701.3,382.1,684.7,383.2L684.7,383.2z"
          />
          <path
            fill="#656565"
            d="M736.2,363.8c1.8-1.4,1.2-3.3-1.5-5.5c-2.3-1.1-4.4,1.1-6.3,6.7c0,0.7-0.1,1.2-0.4,1.9
	c1.2,0.8,1.8,3.3,1.5,7.6l2.9-0.4C733.1,368.1,734.3,364.6,736.2,363.8L736.2,363.8z"
          />
          <path
            fill="#656565"
            d="M720.4,375.5l9.2-1.2c0.1-4.3-0.4-6.7-1.5-7.6C727.2,370.3,724.5,373.2,720.4,375.5L720.4,375.5z
	 M186.7,336.8c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1s20.6,3,29.2,9.1c2.3,1.6,4.5,3.6,6.6,5.6c2.6,2.6,4.8,5.2,6.6,8
	c5.5,8.1,8.1,17.3,8.1,27.6c0,6.3-1.1,12.4-3,17.9l15-0.1v-16.1c-0.3-11.7-3.2-22.1-8.7-31.5c-2.6-4.4-5.8-8.5-9.5-12.5
	c-3.2-3.3-6.5-6.1-9.9-8.5c-9.9-7-21.3-10.5-34-10.5c-17.2,0-31.8,6.3-43.9,19c-4.5,4.8-8.3,9.9-11,15.4c-1.1,2.2-2.2,4.5-3,6.9
	c-2.3,6.5-3.7,13.3-4,20.8c-0.3,6.7-0.3,12.9,0,18.6l14.9,0.4c-2.5-6.1-3.9-12.5-3.9-19.7c0-12.4,3.9-23.2,11.7-32.3
	C184.6,339,185.6,337.9,186.7,336.8L186.7,336.8z"
          />
          <path
            fill="#656565"
            d="M193.3,331.2c-2.3,1.6-4.5,3.6-6.6,5.6c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3
	c0,7,1.2,13.6,3.9,19.7c1.9,4.5,4.5,8.8,7.8,12.8c1,1.1,2.1,2.2,3,3.3c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9
	c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c2.6-2.6,4.8-5.2,6.6-8c2.1-3.2,3.7-6.5,5.1-9.9c2.1-5.5,3-11.4,3-17.9
	c0-10.3-2.8-19.5-8.1-27.6c-1.9-2.8-4.1-5.5-6.6-8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1S201.8,325.2,193.3,331.2
	L193.3,331.2z M223.4,408.9c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.6,10.7-25.7
	c7.2-7.2,15.7-10.6,25.7-10.6s18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7c0,10-3.6,18.6-10.7,25.7
	C242.1,405.4,233.4,408.9,223.4,408.9L223.4,408.9z"
          />
          <path
            fill="#656565"
            d="M248.7,357.1c-1.2-1.9-2.8-3.7-4.4-5.4c-2.1-2.1-4.1-3.7-6.5-5c-1.4,5.5-3,10.2-4.8,14
	c-1.2,2.3-2.8,4.1-4.4,5.5l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.6,0-2.5-0.3l-0.4-0.1c-0.7-0.4-1.4-1-1.6-1.6l-0.3-0.5
	c-1.4-1.6-2.3-3.9-2.9-6.5c-0.8-4.1-1.2-9.1-1.1-14.7c-5,1.1-9.4,3.4-13.3,7.2c4.8,3,8.8,6.1,11.8,8.9c1.9,1.9,3.2,4,3.9,5.9
	l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.6,1.7-1.1,2.3l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1c-1.9,0.7-4.3,1-7,0.8
	c-4.3-0.5-9.1-1.6-14.4-3.6l-0.1,2.3c0,4.5,1,8.8,2.8,12.5c4.4-3.7,8.5-6.6,12.2-8.5c2.5-1.2,4.7-1.8,6.9-1.8l0.7-0.1
	c0.8-0.1,1.8,0.1,2.6,0.5c0.8,0.4,1.4,1,1.8,1.8l0.1,0.4c0.4,0.8,0.6,1.5,0.4,2.3l-0.1,0.7c0.1,2.1-0.4,4.4-1.5,6.9
	c-1.8,3.9-4.4,8.1-7.8,12.7c3.4,1.4,7.3,2.2,11.3,2.2l3.7-0.1c-2.2-5.2-3.6-9.9-4.3-14.2c-0.4-2.8-0.3-5.1,0.4-7v-0.5
	c0.1-0.8,0.7-1.6,1.4-2.3c0.6-0.5,1.4-1,2.2-1.2h0.4c0.8-0.1,1.6,0,2.3,0.3c0.3,0,0.4,0.1,0.6,0.3c2.1,0.5,4,1.8,6.1,3.4
	c3,2.9,6.3,6.6,9.5,11.3c3.4-4,5.6-8.5,6.6-13.6c-5.6,0.4-10.6,0.3-14.9-0.3c-2.6-0.5-4.8-1.4-6.6-2.6l-0.6-0.3
	c-0.8-0.4-1.4-1.1-1.8-2.1c-0.4-0.7-0.6-1.5-0.4-2.5v-0.4c0.1-0.8,0.6-1.5,1-2.1l0.4-0.4c1.2-1.8,2.9-3.3,5.2-4.7
	C238.7,360.6,243.2,358.9,248.7,357.1L248.7,357.1z M226,370c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.5-1.1,2.6-1.1S225.3,369.3,226,370L226,370z"
          />
          <path
            fill="#656565"
            d="M227,358.6c1.1,0.3,2.1-0.7,2.6-2.9l1.6-11.6c-2.5-0.7-5-1-7.7-1l-1.4,11.4c0,2.3,0.6,3.4,1.8,3.4L227,358.6
	L227,358.6z M240.2,365.7c-2.2,0.7-3.2,1.6-2.8,2.8l0.6,3.2c0.1,1.1,1.2,1.6,3.6,1.5l11.4-1.9c-0.1-2.8-0.6-5.2-1.4-7.7L240.2,365.7
	L240.2,365.7z M195.3,363.4l10.6,5c2.2,0.7,3.4,0.6,3.9-0.5l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1
	C197.3,358.6,196.2,360.8,195.3,363.4L195.3,363.4z M214.1,387.9c1.4-1.9,1.6-3.2,0.7-3.9l-2.2-2.2c-0.7-1-2.1-0.7-3.9,0.8l-8.1,8.4
	l2.2,2.3c1.1,1.1,2.2,2.1,3.4,3L214.1,387.9L214.1,387.9z M231.7,384.4l-2.9,1.5c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3
	c2.5-1,4.7-2.2,6.9-3.7l-5.6-10C233.9,384.4,232.6,383.9,231.7,384.4L231.7,384.4z"
          />
          <path
            fill="#EBEBEB"
            d="M587,206.3c1.2,1.4,1.9,3,2.3,5l9.2,40.2l73.7-3l-4.1-7l-3.6-1.1l-15.8-27.5l1.5-2.2l-4.8-6.7L587,206.3
	L587,206.3z M699.1,261.3h-4.8c-2.8,0-4.4,1.6-5.1,4.8c-0.5,4.4-0.4,10.6,0.3,18.7c0.1,9.5,1.7,16.1,4.5,19.8
	c1.2,1.2,3.3,1.9,6.3,2.1c4.4,0.3,7-1.4,8-4.5c0.3-2.3,0.4-5.6,0.3-9.6l-0.3-5.1c-0.8-11.1-1.9-18.7-3.4-22.7l-0.8-1.2
	C703,262.1,701.3,261.5,699.1,261.3L699.1,261.3z M599,346.9c-7.2,7.2-10.7,15.7-10.7,25.7c0,10,3.6,18.6,10.7,25.7
	c7.2,7.2,15.7,10.6,25.7,10.6c10,0,18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.6-10.7-25.7
	c-7.2-7.2-15.7-10.6-25.7-10.6C614.6,336.2,606.1,339.7,599,346.9z M623.3,337.7l0.5-0.1l0.4,0.3l0.1,0.3v0.1l0.3,1.6h0.3
	c3.2,0,6.2,0.4,9.1,1.2l0.5-1.5l0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.3l0.1,0.1c1.1,1.1,1.9,2.1,2.5,3
	c3.6,1.7,6.9,3.9,9.9,6.9c2.6,2.6,4.7,5.5,6.2,8.5c1,0.5,1.9,1.4,3.2,2.3h0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1
	h-0.1L656,362c1,2.9,1.5,5.9,1.7,9.2l1.7,0.1l0.3,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1
	c-0.7,1.4-1.5,2.5-2.1,3.3c-0.7,7.6-3.8,14.2-9.5,19.8l-0.1,0.1c-0.3,1.1-0.7,2.3-1.4,3.9v0.3l-0.3,0.4h-0.4l-0.3-0.1V400h-0.1
	l-1.1-1.1c-2.6,1.9-5.2,3.4-8.3,4.5l0.3,1.5v0.3l-0.3,0.3l-0.4,0.1c-0.1,0-0.1-0.1-0.3-0.1c-1.5-0.3-2.6-0.5-3.7-1
	c-2.3,0.4-4.7,0.7-7,0.7c-5.6,0-10.7-1.2-15.3-3.7c-1,0-2.2,0-3.7-0.1h-0.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-0.4
	l0.7-1.4c-1.5-1.1-2.9-2.2-4.1-3.6c-1-1-1.8-1.9-2.6-2.9l-1.4,0.8h-0.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.3-0.3-0.4v-0.3
	c-0.1-1.4-0.3-2.6-0.3-3.6c-3-5-4.4-10.5-4.4-16.6c0-1.9,0.1-3.7,0.4-5.5c-0.4-1-0.8-2.2-1.1-3.7L591,363v-0.4
	c0.1-0.1,0.1-0.3,0.3-0.3h0.4l1.5,0.3c1-3,2.3-5.8,4.1-8.4l-1.1-1l-0.1-0.3v-0.4c0-0.1,0.1-0.3,0.3-0.3h0.3c1.4-0.7,2.6-1.1,3.6-1.5
	l1.2-1.2c5.2-5.2,11.3-8.3,18.3-9.2c0.8-0.7,1.9-1.5,3.3-2.3L623.3,337.7L623.3,337.7z"
          />
          <path
            fill="#EBEBEB"
            d="M623.7,337.5l-0.5,0.1l-0.1,0.1l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2
	l0.3,0.5c0.4,0.7,1,1.2,1.7,1.6l0.4,0.1c0.8,0.4,1.7,0.4,2.5,0.3c1-0.1,1.8-0.5,2.5-1.1l0.4-0.4c0.5-0.8,1.5-3,2.6-6.7
	c1-3,1.8-7.8,2.6-14.6l0.5-5.1v-0.3l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0-0.4,0.1l-0.1,0.3v0.1l-0.5,4.8
	c-0.8,6.7-1.8,11.7-2.6,14.6c-1.1,3.6-1.9,5.6-2.5,6.3c-0.4,0.5-1.1,1-1.8,1.1l-0.4,0.1c-0.6,0-1.2,0-1.8-0.3
	c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.6-3.2-0.7-6.7c-0.1-3,0.3-8,1.2-14.7l0.7-4.8v-0.1l-0.1-0.3L623.7,337.5L623.7,337.5z"
          />
          <path
            fill="#EBEBEB"
            d="M623,338.1l0.1-0.3c-1.4,0.8-2.5,1.6-3.3,2.3c-1.4,1.1-1.9,2.1-1.9,2.9v0.8c-0.1,5.6,0.3,10.6,1.1,14.7
	c0.5,2.6,1.5,4.8,2.9,6.5c-0.4-1-0.7-3.3-0.8-7.2c-0.1-3,0.3-8,1.2-14.6L623,338.1L623,338.1z"
          />
          <path
            fill="#EBEBEB"
            d="M624.3,338.2l-0.7,5c-1,6.7-1.4,11.6-1.2,14.7c0.1,3.7,0.3,5.9,0.7,6.7c0.3,0.8,1,1.4,1.8,1.8
	c0.5,0.3,1.2,0.4,1.8,0.3l0.4-0.1c0.7-0.1,1.4-0.6,1.8-1.1c0.5-0.7,1.4-2.9,2.5-6.3c1-3,1.8-7.8,2.6-14.6l0.5-4.8v-0.1l-0.5,1.5
	l-1.1,2.6v0.4l-1.7,11.6c-0.5,2.2-1.4,3.2-2.6,2.9l-3.2-0.6c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4v-0.5l-0.4-2.6L624.3,338.2L624.3,338.2
	z M627.6,361.1c0.5,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.5,1.9c-0.4,0.6-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4c-0.5-0.4-1-1-1.1-1.8
	c-0.1-0.7,0-1.4,0.4-1.9c0.4-0.5,1-1,1.8-1.1C626.3,360.5,627,360.6,627.6,361.1L627.6,361.1z"
          />
          <path
            fill="#EBEBEB"
            d="M628.7,362.8c-0.1-0.7-0.5-1.4-1.1-1.8c-0.5-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1
	c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.5,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1
	C628.5,364.2,628.8,363.5,628.7,362.8L628.7,362.8z M627.2,361.7c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.5,0,1-0.4,1.5
	c-0.3,0.4-0.7,0.7-1.2,0.7c-0.5,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8
	C626.2,361.3,626.7,361.5,627.2,361.7L627.2,361.7z"
          />
          <path
            fill="#EBEBEB"
            d="M627.8,363c-0.1-0.5-0.3-1-0.8-1.2c-0.4-0.3-0.8-0.4-1.4-0.4c-0.5,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4
	c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3c0.5-0.1,1-0.3,1.2-0.7S628,363.5,627.8,363L627.8,363z M635.4,339.6v0.3l-0.5,5.1
	c-0.8,6.7-1.7,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7c1.7-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14l0.1-0.8
	c0.3-0.8-0.1-1.9-1.2-3.4C637.3,341.7,636.5,340.7,635.4,339.6L635.4,339.6z M632.5,344v-0.4l1.1-2.6c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3
	l0.4,2.6v0.5C627.4,343,630,343.4,632.5,344L632.5,344z M617.8,343.9V343c0-0.8,0.7-1.8,1.9-2.9c-7,1-13.1,4-18.3,9.2l-1.2,1.2
	c1.8-0.5,2.9-0.5,3.6-0.1l0.7,0.4C608.4,347.3,612.8,345,617.8,343.9L617.8,343.9z M628.3,372.6c0-1-0.4-1.9-1.1-2.6
	c-0.7-0.7-1.5-1.1-2.6-1.1c-1.1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1
	c1,0,1.9-0.4,2.6-1.1C628,374.4,628.3,373.6,628.3,372.6L628.3,372.6z M614.5,361.3c-2.5-1.8-6.9-4.1-13.1-7l-4.5-2.2h-0.5
	c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.3h0.1l4.4,1.9c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3
	c0.4,0.5,0.5,1.2,0.5,2.1v0.4c-0.1,0.5-0.4,1.1-0.8,1.6c-0.5,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8
	l-4.3-2.2h-0.4c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1l0.1,0.1l4.5,2.2c6.2,3,10.7,5,13.6,5.8c3.7,1.1,6.2,1.5,7.2,1.5
	l0.7-0.1c0.7-0.1,1.4-0.5,2.1-1.1l0.3-0.3c0.5-0.7,1-1.4,1.1-2.3c0.1-1,0.1-1.9-0.3-2.8l-0.3-0.5C619.6,365,617.8,363.5,614.5,361.3
	L614.5,361.3z"
          />
          <path
            fill="#EBEBEB"
            d="M614.5,361.3c3.2,2.2,5.1,3.7,5.6,4.5c-0.7-2.1-1.9-4-3.9-5.9c-3-2.9-6.9-5.9-11.8-8.9l-0.7-0.4
	c-0.7-0.5-1.8-0.4-3.6,0.1c-1,0.3-2.2,0.8-3.6,1.5h0.3l4.5,2.2C607.8,357.2,612,359.5,614.5,361.3L614.5,361.3z"
          />
          <path
            fill="#EBEBEB"
            d="M612.6,364.8l-1.5,2.9c-0.4,1.1-1.7,1.4-3.9,0.5l-10.6-5l-0.4-0.3l-2.8-0.4l-1.5-0.3l4.3,2.2
	c6.2,3,10.7,5,13.6,5.8c3.4,1.1,5.8,1.5,6.6,1.4c0.8-0.1,1.7-0.4,2.2-1.1c0.4-0.5,0.7-1,0.8-1.6v-0.4c0-0.8-0.1-1.4-0.5-2.1
	c-0.5-0.7-2.3-2.2-5.4-4.3c-2.5-1.8-6.9-4.1-13.1-7l-4.4-1.9h-0.1l1.1,1l2.2,1.9l0.4,0.1l10.5,5.1
	C612.4,362.7,613.1,363.8,612.6,364.8L612.6,364.8z M614.8,366.3c0.5-0.4,1.2-0.5,1.9-0.5c0.7,0.1,1.2,0.4,1.7,1.1
	c0.4,0.5,0.5,1.2,0.5,1.9c-0.1,0.7-0.4,1.4-1.1,1.8c-0.5,0.4-1.2,0.5-1.9,0.4c-0.7-0.1-1.4-0.4-1.8-1.1c-0.4-0.5-0.5-1.2-0.4-1.9
	S614.2,366.7,614.8,366.3L614.8,366.3z"
          />
          <path
            fill="#EBEBEB"
            d="M616.7,365.9c-0.7-0.1-1.4,0.1-1.9,0.5c-0.5,0.4-1,1-1.1,1.8c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1
	c0.7,0.1,1.4,0,1.9-0.4s1-1,1.1-1.8c0.1-0.7,0-1.4-0.5-1.9C618.1,366.3,617.5,365.9,616.7,365.9L616.7,365.9z M614.4,368.1
	c0.1-0.5,0.4-1,0.8-1.2c0.4-0.3,1-0.4,1.4-0.4c0.5,0.1,1,0.4,1.2,0.8s0.4,0.8,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2
	c-0.4,0.3-0.8,0.4-1.4,0.3s-1-0.3-1.2-0.7C614.5,369.2,614.4,368.6,614.4,368.1L614.4,368.1z"
          />
          <path
            fill="#EBEBEB"
            d="M615.2,366.8c-0.4,0.3-0.7,0.7-0.8,1.2c-0.1,0.6,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.5,0.1,1,0,1.4-0.3
	c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8C616.1,366.4,615.6,366.6,615.2,366.8L615.2,366.8z
	 M617.7,374.7l-0.7,0.1c-1,0.3-3,1.5-6.1,3.9c-2.5,1.8-6.1,5.2-10.7,10.3l-3.3,3.7l-0.1,0.1v0.3c0,0.1,0.1,0.3,0.3,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h0.3l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3
	c0.5,0.3,1,0.7,1.2,1.2c0.4,0.8,0.5,1.6,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-5,6.3-9.6,11.1l-3.3,3.4v0.4c0,0.1,0,0.3,0.1,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h0.3l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1c2.2-3.2,3.4-5.4,3.6-6.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3
	l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8C619.5,374.7,618.5,374.5,617.7,374.7L617.7,374.7z"
          />
          <path
            fill="#EBEBEB"
            d="M619.9,376.3l-0.4-0.3c-0.7-0.3-1.4-0.3-2.1-0.1c-0.8,0.3-2.8,1.5-5.6,3.7c-2.5,1.8-5.9,5.2-10.7,10.3
	l-3.3,3.6l1.4-0.8l2.5-1.5l0.3-0.3l8.1-8.4c1.8-1.5,3-1.8,3.9-0.8l2.2,2.2c1,0.7,0.7,2.1-0.7,3.9l-8,8.5l-0.4,0.4l-1.2,2.5l-0.7,1.4
	l3.3-3.4c4.7-4.8,8-8.7,9.6-11.1c2.1-3,3.2-5,3.4-5.9c0.1-0.8,0-1.6-0.4-2.5C620.7,377,620.3,376.6,619.9,376.3L619.9,376.3z
	 M615.9,378.1c0.3-0.7,0.8-1.1,1.5-1.4s1.4-0.1,2.1,0.1s1.1,0.8,1.4,1.5c0.3,0.7,0.1,1.4-0.1,2.1c-0.4,0.7-0.8,1.1-1.5,1.4
	c-0.7,0.3-1.4,0.1-2.1-0.1s-1.1-0.8-1.4-1.5C615.5,379.4,615.6,378.7,615.9,378.1L615.9,378.1z"
          />
          <path
            fill="#EBEBEB"
            d="M617.4,376.7c-0.7,0.3-1.2,0.7-1.5,1.4s-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1
	c0.7-0.3,1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5S618.1,376.5,617.4,376.7L617.4,376.7z M617.7,377.4
	c0.5-0.1,1-0.1,1.4,0.1c0.5,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.4c-0.3,0.6-0.5,0.8-1.1,1c-0.5,0.1-1,0.1-1.5-0.1c-0.4-0.3-0.8-0.5-1-1.1
	c-0.1-0.5-0.1-1,0.1-1.5C616.8,378,617.1,377.6,617.7,377.4L617.7,377.4z"
          />
          <path
            fill="#EBEBEB"
            d="M619,377.6c-0.4-0.3-1-0.3-1.4-0.1c-0.5,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5c0.1,0.5,0.5,0.8,1,1.1
	c0.5,0.3,1,0.3,1.5,0.1c0.5-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4C619.9,378.1,619.6,377.8,619,377.6L619,377.6z M617,374.8
	c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5l-0.5,0.4c-0.7,0.5-1,1.8-1,3.7c0,1,0.1,2.2,0.3,3.6l0.1-0.1l3.3-3.7
	c4.7-5.1,8.3-8.5,10.7-10.3C613.9,376.3,616,375.1,617,374.8L617,374.8z M609.7,373.7c2.6,0.3,5,0,7-0.8c-1,0.1-3.3-0.4-7.2-1.5
	c-2.9-0.8-7.4-2.8-13.6-5.8l-4.5-2.2l-0.1-0.1c0.4,1.5,0.7,2.8,1.1,3.7c0.7,1.6,1.4,2.6,2.2,2.9l0.7,0.3
	C600.6,372.1,605.4,373.2,609.7,373.7L609.7,373.7z M637.6,364.5c-3.6,1.4-5.8,2.3-6.6,3l-0.4,0.4c-0.5,0.5-0.8,1.2-1,2.1v0.4
	c-0.1,1,0.1,1.8,0.4,2.5c0.4,1,1.1,1.6,1.8,2.1l0.5,0.3c1,0.4,3.4,0.5,7.3,0.4c3,0,7.8-0.7,14.6-2.1l5.1-1l0.3-0.1l0.1-0.1
	c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.3-0.3-0.4l-0.3-0.1l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4
	c-0.7-0.3-1.2-0.7-1.7-1.4l-0.1-0.4c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2s2.8-1.5,6.2-2.8c2.9-1.1,7.7-2.2,14.4-3.3
	l4.8-0.8h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.3-0.3-0.4h-0.4l-5.1,0.8C645.3,362.3,640.5,363.4,637.6,364.5
	L637.6,364.5z"
          />
          <path
            fill="#EBEBEB"
            d="M631,367.4c0.8-0.7,3-1.6,6.6-3c3-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3c-1.2-1-2.2-1.8-3.2-2.3
	c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3c-5.5,1.6-10,3.6-13.8,5.6C633.9,364.1,632.1,365.7,631,367.4L631,367.4z M628.1,376.3
	c-0.8,0.1-1.7,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5c0,1,0.5,3.3,1.8,7c1,2.9,3.2,7.4,6.5,13.3l2.5,4.4l0.1,0.1
	c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9
	l0.3-0.3c0.4-0.4,1-0.7,1.7-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4h0.1v0.1l0.3,0.1
	h0.4l0.3-0.4v-0.6l-2.3-4.5c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4c-0.1-0.1-0.4-0.1-0.5-0.3c-0.7-0.3-1.5-0.4-2.3-0.3
	L628.1,376.3L628.1,376.3z"
          />
          <path
            fill="#EBEBEB"
            d="M630.9,372.3l0.1,0.4c0.4,0.7,1,1.1,1.7,1.4c0.8,0.3,3.2,0.4,6.7,0.4c3,0,8-0.7,14.6-2.1l5-1l-1.7-0.1
	l-2.8-0.3l-0.6,0.1l-11.4,1.9c-2.3,0.1-3.6-0.4-3.6-1.5l-0.5-3.2c-0.3-1.1,0.5-2.1,2.8-2.8l11.4-2.2l0.5-0.1l2.5-1.2l1.4-0.7
	l-4.8,0.8c-6.7,1.2-11.6,2.3-14.4,3.3c-3.4,1.2-5.5,2.2-6.2,2.8c-0.5,0.5-1,1.2-1.1,2.2C630.6,371.2,630.7,371.8,630.9,372.3
	L630.9,372.3z M636.5,371.6c-0.3,0.7-0.7,1.2-1.4,1.5c-0.7,0.3-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4c-0.3-0.7-0.4-1.4-0.1-2.1
	s0.7-1.2,1.4-1.5s1.4-0.4,2.1-0.1s1.2,0.7,1.5,1.4C636.6,370.3,636.6,371,636.5,371.6L636.5,371.6z"
          />
          <path
            fill="#EBEBEB"
            d="M635.1,373.3c0.7-0.3,1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4s-1.4-0.1-2.1,0.1
	s-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1s0.8,1.1,1.5,1.4C633.8,373.6,634.4,373.6,635.1,373.3L635.1,373.3z M635.8,371.5
	c-0.1,0.6-0.4,0.8-1,1.1l-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.5c0.1-0.5,0.4-0.8,1-1.1s1-0.3,1.5-0.1
	c0.6,0.1,1,0.5,1.1,1C635.8,370.5,636,371,635.8,371.5L635.8,371.5z"
          />
          <path
            fill="#EBEBEB"
            d="M634.9,372.6c0.4-0.3,0.8-0.7,1-1.1c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.4-0.7-0.8-1.1-1c-0.5-0.1-1-0.1-1.5,0.1
	c-0.4,0.3-0.8,0.5-1,1.1s-0.1,1,0.1,1.5c0.3,0.5,0.6,0.8,1.1,1L634.9,372.6L634.9,372.6z M630.9,378c-0.7-0.4-1.5-0.5-2.5-0.4
	c-0.5,0.1-1.1,0.4-1.7,0.8l-0.3,0.3c-0.5,0.5-0.7,1.2-0.7,1.9c0,0.8,0.5,3,1.8,6.6c1,2.9,3.2,7.3,6.5,13.3l2.3,4.3l-0.3-1.5
	l-0.7-2.9l-0.1-0.4l-5.5-10.3c-0.8-2.2-0.7-3.4,0.4-3.9l2.9-1.5c1-0.7,2.1,0,3.4,1.8l5.6,10l0.3,0.5l1.9,1.9l1.1,1.1l-2.2-4.4
	c-3.2-6.1-5.8-10.3-7.6-12.7C633.1,380,631.6,378.4,630.9,378L630.9,378z M631,378.9c0.5,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.9
	c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9c0.6-0.5,1.1-0.8,1.9-0.8
	C629.9,378.1,630.5,378.4,631,378.9L631,378.9z"
          />
          <path
            fill="#EBEBEB"
            d="M631.8,380.7c0-0.7-0.3-1.4-0.8-1.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8
	c-0.5,0.5-0.8,1.2-0.8,1.9s0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8C631.6,382.1,631.8,381.6,631.8,380.7z
	 M631,380.7c0,0.5-0.1,1-0.5,1.4c-0.3,0.4-0.8,0.5-1.4,0.5c-0.6,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4
	s0.8-0.5,1.4-0.5c0.5,0,1,0.1,1.4,0.5S631,380.2,631,380.7L631,380.7z"
          />
          <path
            fill="#EBEBEB"
            d="M630.5,382.1c0.4-0.4,0.5-0.8,0.5-1.4c0-0.5-0.1-1-0.5-1.4s-0.8-0.5-1.4-0.5c-0.5,0-1,0.1-1.4,0.5
	s-0.5,0.8-0.5,1.4c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5C629.6,382.7,630.2,382.5,630.5,382.1z M600.1,356.4l-0.4-0.1
	l-2.2-1.9c-1.8,2.6-3.2,5.4-4.1,8.4l2.8,0.4l0.4,0.3C597.4,360.8,598.5,358.6,600.1,356.4L600.1,356.4z M595.2,370.1l-0.7-0.3
	c-0.8-0.3-1.5-1.2-2.2-2.9c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6c0-1.9,0.3-3.2,1-3.7l0.5-0.4c-1.8-3.9-2.8-8-2.8-12.5
	L595.2,370.1L595.2,370.1z M603.8,393.4c-0.8-0.8-1.5-1.5-2.2-2.3l-0.3,0.3l-2.5,1.5c0.8,1,1.7,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6
	l1.2-2.5l0.4-0.4C606.1,395.5,604.9,394.5,603.8,393.4L603.8,393.4z M605.8,401.4l-0.3,0.1c1.5,0.1,2.8,0.1,3.7,0.1
	c1.9-0.1,3.2-0.6,3.6-1.2l0.4-0.5c3.4-4.5,6.1-8.8,7.8-12.7c1.1-2.5,1.5-4.8,1.5-6.9c-0.3,1-1.5,3-3.6,6.3c-1.8,2.6-5,6.3-9.6,11.1
	L605.8,401.4L605.8,401.4z M626.3,387.6c-1.2-3.7-1.9-6.1-1.8-7c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2l0.3,0.7
	c0.3,0.8,1.2,1.5,3,2.1c1,0.3,2.2,0.7,3.7,1l-0.1-0.1l-2.5-4.4C629.5,394.9,627.3,390.5,626.3,387.6L626.3,387.6z"
          />
          <path
            fill="#EBEBEB"
            d="M613,400.4c-0.4,0.7-1.7,1.1-3.6,1.2c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7c-1.8-0.5-2.9-1.2-3-2.1
	l-0.3-0.7l-3.7,0.1c-4.1,0-7.8-0.7-11.3-2.2L613,400.4L613,400.4z M635.1,400.1l0.1,0.4l0.7,2.9c2.9-1.1,5.6-2.6,8.3-4.5l-1.9-1.9
	l-0.3-0.5C639.8,398.1,637.6,399.3,635.1,400.1L635.1,400.1z M631.4,376.9c0.8,0.5,2.5,2.3,4.8,5.4c1.9,2.5,4.4,6.6,7.7,12.5
	l2.3,4.5v0.3c0.5-1.5,1.1-2.8,1.4-3.9c0.4-1.6,0.4-2.9-0.1-3.4l-0.5-0.7c-3.3-4.7-6.5-8.4-9.5-11.3
	C635.5,378.7,633.5,377.4,631.4,376.9L631.4,376.9z M639.1,345.9l-0.1,0.8c2.3,1.4,4.5,3,6.5,5c1.7,1.6,3.2,3.6,4.4,5.4l0.8-0.3
	c0.7-0.3,1.9,0,3.4,1c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9C639,344,639.4,345.2,639.1,345.9L639.1,345.9z M653.3,363.4
	l-0.5,0.1c0.8,2.5,1.2,5,1.4,7.7l0.5-0.1l2.8,0.3c-0.1-3.2-0.7-6.3-1.7-9.2L653.3,363.4L653.3,363.4z M659.3,372.6l-0.3,0.1l-5.1,1
	c-6.7,1.2-11.6,1.9-14.6,2.1c-3.9,0.1-6.3,0-7.3-0.4c1.8,1.2,4,2.1,6.6,2.6c4.1,0.5,9.1,0.7,14.9,0.3h0.8c0.8,0,1.8-0.7,2.9-2.1
	C658,375.1,658.7,374,659.3,372.6L659.3,372.6z"
          />
          <path
            fill="#EBEBEB"
            d="M654.4,378h-0.8c-1,5.1-3,9.6-6.6,13.6l0.5,0.7c0.5,0.5,0.5,1.8,0.1,3.4l0.1-0.1c5.6-5.6,8.8-12.2,9.5-19.8
	C656.2,377.3,655.2,378,654.4,378L654.4,378z M197.7,398.3c7.2,7.2,15.7,10.6,25.7,10.6s18.6-3.6,25.7-10.6
	c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.6-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6s-18.6,3.6-25.7,10.6
	c-7.2,7.2-10.7,15.7-10.7,25.7C187.1,382.7,190.7,391.2,197.7,398.3L197.7,398.3z M190.2,362.4h0.4l1.5,0.3c1-3,2.3-5.8,4.1-8.4
	l-1.1-1l-0.1-0.3v-0.4c0,0,0.1-0.3,0.3-0.3h0.3c1.4-0.7,2.6-1.1,3.6-1.5l1.2-1.2c5.2-5.2,11.3-8.3,18.3-9.2c0.8-0.7,1.9-1.5,3.3-2.3
	l0.1-0.1c0.1-0.1,0.3-0.1,0.6-0.1c0.1,0,0.3,0.1,0.4,0.3l0.1,0.3v0.1l0.3,1.7h0.3c3.2,0,6.2,0.4,9.1,1.2l0.6-1.5
	c0-0.1,0.1-0.1,0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1l0.4,0.3l0.1,0.1c1.1,1.1,1.9,2.1,2.5,3c3.6,1.6,6.9,3.9,9.9,6.9
	c2.6,2.6,4.7,5.5,6.2,8.5c1,0.5,1.9,1.4,3.2,2.3h0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1h-0.1l-1.4,0.7
	c1,2.9,1.5,5.9,1.6,9.2l1.7,0.1l0.3,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1c-0.7,1.4-1.5,2.5-2.1,3.3
	c-0.7,7.6-3.9,14.2-9.5,19.8l-0.1,0.1c-0.3,1.1-0.7,2.3-1.4,3.9v0.3c0,0.1-0.1,0.3-0.3,0.4h-0.4l-0.3-0.1v-0.1h-0.1l-1.1-1.1
	c-2.6,1.9-5.2,3.4-8.3,4.5l0.3,1.5v0.3l-0.3,0.3l-0.4,0.1c-0.1,0-0.1-0.1-0.3-0.1c-1.5-0.3-2.8-0.5-3.7-1c-2.2,0.4-4.7,0.7-7,0.7
	c-5.6,0-10.7-1.2-15.3-3.7c-1,0-2.2,0-3.7-0.1h-0.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-0.4l0.7-1.4
	c-1.5-1.1-2.9-2.2-4.1-3.6c-1-1-1.8-1.9-2.6-2.9l-1.4,0.8H196c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.3-0.3-0.4v-0.3
	c-0.1-1.4-0.3-2.6-0.3-3.6c-3-5-4.4-10.5-4.4-16.6c0-1.9,0.1-3.7,0.4-5.5c-0.4-1-0.8-2.2-1.1-3.7l-0.1-0.1v-0.4
	C190,362.6,190.1,362.4,190.2,362.4L190.2,362.4z"
          />
          <path
            fill="#EBEBEB"
            d="M190.7,362.4l4.3,2.2c6.2,3,10.7,5,13.6,5.8c3.4,1.1,5.8,1.5,6.6,1.4c0.8-0.1,1.6-0.4,2.2-1.1
	c0.4-0.5,0.7-1,0.8-1.6v-0.4c0-0.8-0.1-1.4-0.6-2.1c-0.6-0.7-2.3-2.2-5.4-4.3c-2.5-1.8-6.9-4.1-13.1-7l-4.4-1.9h-0.1l1.1,1l2.2,1.9
	l0.4,0.1l10.5,5.1c1.9,1.2,2.6,2.3,2.1,3.3l-1.5,2.9c-0.4,1.1-1.6,1.4-3.9,0.5l-10.6-5l-0.4-0.3l-2.8-0.4L190.7,362.4L190.7,362.4z
	 M212.4,368.1c0.1-0.7,0.4-1.2,1.1-1.8c0.6-0.4,1.2-0.5,1.9-0.5c0.7,0.1,1.2,0.4,1.6,1.1c0.4,0.5,0.6,1.2,0.6,1.9
	c-0.1,0.7-0.4,1.4-1.1,1.8c-0.6,0.4-1.2,0.5-1.9,0.4c-0.7-0.1-1.4-0.4-1.8-1.1C212.5,369.5,212.4,368.8,212.4,368.1L212.4,368.1z"
          />
          <path
            fill="#EBEBEB"
            d="M213.5,366.3c-0.6,0.4-1,1-1.1,1.8c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4
	c0.6-0.4,1-1,1.1-1.8c0.1-0.7,0-1.4-0.6-1.9c-0.4-0.5-1-1-1.6-1.1C214.7,365.7,214,365.9,213.5,366.3z M213.2,368.1
	c0.1-0.5,0.4-1,0.8-1.2c0.4-0.3,1-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8s0.4,0.8,0.3,1.4s-0.3,1-0.8,1.2c-0.4,0.3-0.8,0.4-1.4,0.3
	s-1-0.3-1.2-0.7C213.2,369.2,213.1,368.6,213.2,368.1L213.2,368.1z"
          />
          <path
            fill="#EBEBEB"
            d="M213.9,366.8c-0.4,0.3-0.7,0.7-0.8,1.2c-0.1,0.6,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.6,0.1,1,0,1.4-0.3
	c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8C214.9,366.4,214.3,366.6,213.9,366.8L213.9,366.8z"
          />
          <path
            fill="#EBEBEB"
            d="M190.7,362.4h-0.4c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1l0.1,0.1l4.5,2.2
	c6.2,3,10.7,5,13.6,5.8c3.7,1.1,6.2,1.5,7.2,1.5l0.7-0.1c0.7-0.1,1.4-0.5,2.1-1.1l0.3-0.3c0.6-0.7,1-1.4,1.1-2.3
	c0.1-1,0.1-1.9-0.3-2.8l-0.3-0.5c-0.6-0.8-2.5-2.3-5.6-4.5c-2.5-1.8-6.9-4.1-13.1-7l-4.5-2.2h-0.6c-0.1,0.1-0.3,0.1-0.3,0.3
	c0,0.1-0.1,0.3,0,0.4l0.1,0.3h0.1l4.4,1.9c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3c0.4,0.5,0.6,1.2,0.6,2.1v0.4
	c-0.1,0.6-0.4,1.1-0.8,1.6c-0.6,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8L190.7,362.4L190.7,362.4z"
          />
          <path
            fill="#EBEBEB"
            d="M190.1,363.5l-0.1-0.1c0.4,1.5,0.7,2.8,1.1,3.7c0.7,1.6,1.4,2.6,2.2,2.9l0.7,0.3c5.4,1.9,10.2,3,14.4,3.6
	c2.8,0.3,5,0,7-0.8c-1,0.1-3.3-0.4-7.2-1.5c-2.9-0.8-7.4-2.8-13.6-5.8L190.1,363.5L190.1,363.5z"
          />
          <path
            fill="#EBEBEB"
            d="M193.3,369.9c-0.8-0.3-1.5-1.2-2.2-2.9c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6
	c0-1.9,0.3-3.2,1-3.7l0.6-0.4c-1.8-3.9-2.8-8-2.8-12.5l0.1-2.3L193.3,369.9L193.3,369.9z M222.9,337.8c-0.1-0.1-0.3-0.3-0.4-0.3
	s-0.4,0-0.6,0.1l-0.1,0.1l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2l0.3,0.5c0.4,0.7,1,1.2,1.6,1.6
	l0.4,0.1c0.8,0.4,1.6,0.4,2.5,0.3c1-0.1,1.8-0.5,2.5-1.1l0.4-0.4c0.6-0.8,1.5-3,2.6-6.7c1-3,1.8-7.8,2.6-14.6l0.6-5.1v-0.3l-0.1-0.1
	l-0.4-0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0.1-0.1,0.3v0.1l-0.4,5c-0.8,6.7-1.8,11.7-2.6,14.6c-1.1,3.6-1.9,5.6-2.5,6.3
	c-0.4,0.5-1.1,1-1.8,1.1l-0.4,0.1c-0.6,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.6-3.2-0.7-6.7c-0.1-3,0.3-8,1.2-14.7
	l0.7-4.8L222.9,337.8L222.9,337.8z"
          />
          <path
            fill="#EBEBEB"
            d="M221.8,338.1l0.1-0.3c-1.4,0.8-2.5,1.6-3.3,2.3c-1.4,1.1-1.9,2.1-1.9,2.9v0.8c-0.1,5.6,0.3,10.6,1.1,14.7
	c0.6,2.6,1.5,4.8,2.9,6.5c-0.4-1-0.7-3.3-0.8-7.2c-0.1-3,0.3-8,1.2-14.6L221.8,338.1L221.8,338.1z"
          />
          <path
            fill="#EBEBEB"
            d="M223,338.2l-0.7,5c-1,6.7-1.4,11.6-1.2,14.7c0.1,3.7,0.3,5.9,0.7,6.7c0.3,0.8,1,1.4,1.8,1.8
	c0.6,0.3,1.2,0.4,1.8,0.3l0.4-0.1c0.7-0.1,1.4-0.6,1.8-1.1c0.6-0.7,1.4-2.9,2.5-6.3c1-3,1.8-7.8,2.6-14.6l0.6-4.8v-0.1l-0.6,1.5
	l-1.1,2.6v0.4l-1.6,11.6c-0.6,2.2-1.4,3.2-2.6,2.9l-3.2-0.6c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4v-0.5l-0.4-2.6L223,338.2L223,338.2z
	 M226.3,361.1c0.6,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.6,1.9c-0.4,0.6-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4c-0.6-0.4-1-1-1.1-1.8
	c-0.1-0.7,0-1.4,0.4-1.9c0.4-0.5,1-1,1.8-1.1C225.1,360.5,225.7,360.6,226.3,361.1L226.3,361.1z"
          />
          <path
            fill="#EBEBEB"
            d="M227.4,362.8c-0.1-0.7-0.6-1.4-1.1-1.8c-0.6-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1
	c-0.4,0.5-0.6,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.6,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1
	C227.4,364.2,227.5,363.5,227.4,362.8L227.4,362.8z M225.9,361.7c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.5,0,1-0.4,1.5
	c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8
	C225.1,361.3,225.5,361.5,225.9,361.7L225.9,361.7z"
          />
          <path
            fill="#EBEBEB"
            d="M226.7,363c-0.1-0.5-0.3-1-0.8-1.2c-0.4-0.3-0.8-0.4-1.4-0.4c-0.6,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4
	c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3c0.6-0.1,1-0.3,1.2-0.7C226.6,363.9,226.7,363.5,226.7,363L226.7,363z M244.3,351.7
	c1.6,1.6,3.2,3.6,4.4,5.4l0.8-0.3c0.7-0.3,1.9,0,3.4,1c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9c1.1,1.5,1.5,2.6,1.2,3.4
	l-0.1,0.8C240,348,242.2,349.6,244.3,351.7L244.3,351.7z M227.1,372.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.5-1.1-2.6-1.1
	c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1
	C226.7,374.4,227.1,373.6,227.1,372.6L227.1,372.6z"
          />
          <path
            fill="#EBEBEB"
            d="M252.8,357.9c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3c-5.5,1.6-10,3.6-13.8,5.6c-2.3,1.4-4,3-5.2,4.7
	c0.8-0.7,3-1.6,6.6-3c2.9-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3C254.8,359.3,253.8,358.4,252.8,357.9L252.8,357.9z M234.3,339.6v0.3
	l-0.6,5.1c-0.8,6.7-1.6,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7c1.6-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14l0.1-0.8
	c0.3-0.8-0.1-1.9-1.2-3.4C236.1,341.7,235.2,340.7,234.3,339.6L234.3,339.6z M231.4,343.6l1.1-2.6c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3
	l0.4,2.6v0.5c2.8,0,5.2,0.3,7.7,1L231.4,343.6L231.4,343.6z"
          />
          <path
            fill="#EBEBEB"
            d="M236.7,365.6c-3.4,1.2-5.5,2.2-6.2,2.8c-0.6,0.5-1,1.2-1.1,2.2c0,0.7,0,1.2,0.3,1.8l0.1,0.4
	c0.4,0.7,1,1.1,1.6,1.4c0.8,0.3,3.2,0.4,6.7,0.4c3,0,8-0.7,14.6-2.1l5-1l-1.6-0.1l-2.8-0.3l-0.6,0.1l-11.4,1.9
	c-2.3,0.1-3.6-0.4-3.6-1.5l-0.6-3.2c-0.3-1.1,0.6-2.1,2.8-2.8l11.4-2.2l0.6-0.1l2.5-1.2l1.4-0.7l-4.8,0.8
	C244.5,363.4,239.6,364.5,236.7,365.6L236.7,365.6z M230.1,370c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1
	c0.7,0.3,1.2,0.7,1.5,1.4s0.4,1.4,0.1,2.1s-0.7,1.2-1.4,1.5c-0.7,0.3-1.4,0.4-2.1,0.1c-0.7-0.3-1.2-0.7-1.5-1.4
	C230,371.4,230,370.8,230.1,370L230.1,370z"
          />
          <path
            fill="#EBEBEB"
            d="M231.5,368.5c-0.7,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1c0.3,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1
	c0.7-0.3,1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4C232.8,368.1,232.1,368.2,231.5,368.5L231.5,368.5z M231.8,369.2
	c0.6-0.3,1-0.3,1.5-0.1c0.6,0.1,1,0.5,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.4,0.8-1,1.1l-1.5,0.1c-0.6-0.1-0.8-0.4-1.1-1
	c-0.3-0.4-0.3-1-0.1-1.5C231,369.9,231.4,369.4,231.8,369.2L231.8,369.2z"
          />
          <path
            fill="#EBEBEB"
            d="M233.3,369c-0.6-0.1-1-0.1-1.5,0.1c-0.4,0.3-0.8,0.5-1,1.1c-0.1,0.5-0.1,1,0.1,1.5c0.3,0.5,0.6,0.8,1.1,1
	l1.5-0.1c0.4-0.3,0.8-0.7,1-1.1c0.1-0.5,0.1-1-0.1-1.5C234.1,369.6,233.7,369.2,233.3,369L233.3,369z"
          />
          <path
            fill="#EBEBEB"
            d="M236.7,365.6c2.9-1.1,7.7-2.2,14.4-3.3l4.8-0.8h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4h-0.4
	l-5.1,0.8c-6.6,1.2-11.4,2.3-14.3,3.3c-3.6,1.4-5.8,2.3-6.6,3l-0.4,0.4c-0.6,0.6-0.8,1.2-1,2.1v0.4c-0.1,1,0.1,1.8,0.4,2.5
	c0.4,1,1.1,1.6,1.8,2.1l0.6,0.3c1,0.4,3.4,0.6,7.3,0.4c3,0,7.8-0.7,14.6-2.1l5.1-1l0.3-0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	s-0.1-0.3-0.3-0.4l-0.3-0.1l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4c-0.7-0.3-1.2-0.7-1.7-1.4l-0.1-0.4
	c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2C231.2,367.8,233.3,366.8,236.7,365.6L236.7,365.6z M227.4,376.3H227
	c-0.8,0.1-1.6,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5c0,1,0.6,3.3,1.8,7c1,2.9,3.2,7.4,6.5,13.3l2.5,4.4l0.1,0.1
	c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9
	l0.3-0.3c0.4-0.4,1-0.7,1.6-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4h0.1v0.1l0.3,0.1
	h0.4c0.1-0.1,0.3-0.3,0.3-0.4v-0.6l-2.3-4.5c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4c-0.1-0.1-0.4-0.1-0.6-0.3
	C229,376.3,228.2,376.2,227.4,376.3L227.4,376.3z"
          />
          <path
            fill="#EBEBEB"
            d="M229.6,378c-0.7-0.4-1.5-0.5-2.5-0.4c-0.6,0.1-1.1,0.4-1.6,0.8l-0.3,0.3c-0.6,0.5-0.7,1.2-0.7,1.9
	c0,0.8,0.6,3,1.8,6.6c1,2.9,3.2,7.3,6.5,13.3l2.3,4.3l-0.3-1.5l-0.7-2.9L234,400l-5.5-10.3c-0.8-2.2-0.7-3.4,0.4-3.9l2.9-1.5
	c1-0.7,2.1,0,3.4,1.8l5.6,10l0.3,0.5l1.9,1.9l1.1,1.1l-2.2-4.4c-3.2-6.1-5.8-10.3-7.6-12.7C231.9,380,230.4,378.4,229.6,378
	L229.6,378z M229.7,378.9c0.6,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.9c-0.6,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8
	s-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9c0.6-0.5,1.1-0.8,1.9-0.8C228.6,378.1,229.3,378.4,229.7,378.9L229.7,378.9z"
          />
          <path
            fill="#EBEBEB"
            d="M230.6,380.7c0-0.7-0.3-1.4-0.8-1.9s-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8s-0.8,1.2-0.8,1.9
	s0.3,1.4,0.8,1.9c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8C230.3,382.1,230.6,381.6,230.6,380.7z M227.9,382.7
	c-0.6,0-1-0.1-1.4-0.5c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.5,0.1-1,0.6-1.4s0.8-0.5,1.4-0.5s1,0.1,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4
	c0,0.5-0.1,1-0.6,1.4C228.9,382.5,228.5,382.7,227.9,382.7L227.9,382.7z"
          />
          <path
            fill="#EBEBEB"
            d="M226.6,382.1c0.4,0.4,0.8,0.5,1.4,0.5s1-0.1,1.4-0.5c0.4-0.4,0.6-0.8,0.6-1.4c0-0.5-0.1-1-0.6-1.4
	c-0.4-0.4-0.8-0.5-1.4-0.5s-1,0.1-1.4,0.5s-0.6,0.8-0.6,1.4C226,381.3,226.2,381.7,226.6,382.1z M231.1,375.2c1.8,1.2,4,2.1,6.6,2.6
	c4.1,0.5,9.1,0.7,14.9,0.3h0.8c0.8,0,1.8-0.7,2.9-2.1c0.7-0.8,1.4-1.9,2.1-3.3l-0.3,0.1l-5.1,1c-6.7,1.2-11.6,1.9-14.6,2.1
	C234.4,375.8,232.1,375.6,231.1,375.2L231.1,375.2z M252.2,363.4l-0.6,0.1c0.8,2.5,1.2,5,1.4,7.7l0.6-0.1l2.7,0.3
	c-0.1-3.2-0.7-6.3-1.6-9.2L252.2,363.4L252.2,363.4z"
          />
          <path
            fill="#EBEBEB"
            d="M253.3,378h-0.8c-1,5.1-3,9.6-6.6,13.6l0.6,0.7c0.6,0.5,0.6,1.8,0.1,3.4l0.1-0.1c5.6-5.6,8.8-12.2,9.5-19.8
	C255,377.3,254.1,378,253.3,378L253.3,378z M216.7,343.9V343c0-0.8,0.7-1.8,1.9-2.9c-7,1-13.1,4-18.3,9.2l-1.2,1.2
	c1.8-0.5,2.9-0.5,3.6-0.1l0.7,0.4C207.2,347.3,211.7,345,216.7,343.9L216.7,343.9z"
          />
          <path
            fill="#EBEBEB"
            d="M195.5,352.1h0.3l4.5,2.2c6.2,2.9,10.6,5.2,13.1,7c3.2,2.2,5.1,3.7,5.6,4.5c-0.7-2.1-1.9-4-3.9-5.9
	c-3-2.9-6.9-5.9-11.8-8.9l-0.7-0.4c-0.7-0.5-1.8-0.4-3.6,0.1C198.1,351,196.9,351.4,195.5,352.1L195.5,352.1z M198.8,356.4l-0.4-0.1
	l-2.2-1.9c-1.8,2.6-3.2,5.4-4.1,8.4l2.8,0.4l0.4,0.3C196.2,360.8,197.3,358.6,198.8,356.4L198.8,356.4z M216.4,374.7l-0.7,0.1
	c-1,0.3-3,1.5-6.1,3.9c-2.5,1.8-6.1,5.2-10.7,10.3l-3.3,3.7l-0.1,0.1v0.3c0,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3
	l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3c0.6,0.3,1,0.7,1.2,1.2
	c0.4,0.8,0.6,1.6,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-4.9,6.3-9.6,11.1l-3.3,3.4v0.4c0,0.1,0,0.3,0.1,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h0.3l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1c2.2-3.2,3.4-5.4,3.6-6.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3
	l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8C218.2,374.7,217.2,374.5,216.4,374.7L216.4,374.7z"
          />
          <path
            fill="#EBEBEB"
            d="M218.6,376.3l-0.4-0.3c-0.7-0.3-1.4-0.3-2.1-0.1c-0.8,0.3-2.8,1.5-5.6,3.7c-2.5,1.8-5.9,5.2-10.7,10.3
	l-3.3,3.6l1.4-0.8l2.5-1.5l0.3-0.3l8.1-8.4c1.8-1.5,3-1.8,3.9-0.8l2.2,2.2c1,0.7,0.7,2.1-0.7,3.9l-8,8.5l-0.4,0.4l-1.2,2.5l-0.7,1.4
	l3.3-3.4c4.7-4.8,8-8.7,9.6-11.1c2.1-3,3.2-5,3.4-5.9c0.1-0.8,0-1.6-0.4-2.5C219.6,377,219.1,376.6,218.6,376.3L218.6,376.3z
	 M214.6,378.1c0.3-0.7,0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1c0.7,0.3,1.1,0.8,1.4,1.5c0.3,0.7,0.1,1.4-0.1,2.1
	c-0.4,0.7-0.8,1.1-1.5,1.4s-1.4,0.1-2.1-0.1s-1.1-0.8-1.4-1.5C214.2,379.4,214.3,378.7,214.6,378.1L214.6,378.1z"
          />
          <path
            fill="#EBEBEB"
            d="M216.1,376.7c-0.7,0.3-1.2,0.7-1.5,1.4c-0.3,0.7-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1
	s1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5C217.5,376.6,216.8,376.5,216.1,376.7L216.1,376.7z M216.4,377.4
	c0.6-0.1,1-0.1,1.4,0.1c0.6,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.4c-0.3,0.6-0.6,0.8-1.1,1c-0.6,0.1-1,0.1-1.5-0.1
	c-0.4-0.3-0.8-0.5-1-1.1c-0.1-0.5-0.1-1,0.1-1.5C215.6,378,216,377.6,216.4,377.4L216.4,377.4z"
          />
          <path
            fill="#EBEBEB"
            d="M217.9,377.6c-0.4-0.3-1-0.3-1.4-0.1c-0.6,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5c0.1,0.5,0.6,0.8,1,1.1
	c0.6,0.3,1,0.3,1.5,0.1c0.6-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4C218.7,378.1,218.3,377.8,217.9,377.6L217.9,377.6z
	 M215.7,374.8c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5l-0.6,0.4c-0.7,0.5-1,1.8-1,3.7c0,1,0.1,2.2,0.3,3.6l0.1-0.1
	l3.3-3.7c4.7-5.1,8.3-8.5,10.7-10.3C212.8,376.3,214.7,375.1,215.7,374.8L215.7,374.8z M225.1,387.6c-1.2-3.7-1.9-6.1-1.8-7
	c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1c1,0.3,2.2,0.7,3.7,1l-0.1-0.1l-2.5-4.4
	C228.2,394.9,226.2,390.5,225.1,387.6L225.1,387.6z M221.5,380.3c-0.3,1-1.5,3-3.6,6.3c-1.8,2.6-4.9,6.3-9.6,11.1l-3.6,3.6l-0.3,0.1
	c1.5,0.1,2.8,0.1,3.7,0.1c1.9-0.1,3.2-0.6,3.6-1.2l0.4-0.5c3.4-4.5,6.1-8.8,7.8-12.7C221.1,384.7,221.5,382.4,221.5,380.3
	L221.5,380.3z M200.4,391l-0.3,0.3l-2.5,1.5c0.8,1,1.6,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6l1.2-2.5l0.4-0.4c-1.2-1-2.3-1.9-3.4-3
	L200.4,391L200.4,391z"
          />
          <path
            fill="#EBEBEB"
            d="M212.1,399.9l-0.4,0.5c-0.4,0.7-1.6,1.1-3.6,1.2c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7
	c-1.8-0.5-2.9-1.2-3-2.1l-0.3-0.7l-3.7,0.1C219.4,402.1,215.6,401.2,212.1,399.9L212.1,399.9z M246.5,395.9c0.4-1.6,0.4-2.9-0.1-3.4
	l-0.6-0.7c-3.3-4.7-6.5-8.4-9.5-11.3c-2.1-1.8-4.1-2.9-6.1-3.4c0.8,0.5,2.5,2.3,4.8,5.4c1.9,2.5,4.4,6.6,7.7,12.5l2.3,4.5v0.3
	C245.8,398.2,246.2,396.8,246.5,395.9L246.5,395.9z M241,397l-0.3-0.5c-2.2,1.6-4.4,2.9-6.9,3.7l0.1,0.4l0.7,2.9
	c2.9-1.1,5.6-2.6,8.3-4.5L241,397L241,397z M360.3,209.6c2.8-3,1.5-4.7-4.1-5.2h-20.1l-68.9,52.3l32.5,0.7c6.5,0,13.8-3.9,21.6-11.4
	C339.6,230.6,352.7,218.5,360.3,209.6L360.3,209.6z M342,246.6h1.5c2.5,0,4.7,1.1,6.5,3.4c1.8,2.3,2.6,5.1,2.6,8.3
	c0,1.2-0.1,2.3-0.4,3.4l94.1-3.9l15.8-57.5c-27.5,0.4-53.2,1.2-77.2,2.9c-17.5,14.4-34.1,29.4-49.8,44.8c1.6-0.7,3.6-1.1,5.5-1.4
	L342,246.6L342,246.6z M586.7,211.9c-1.9-7.2-11.3-10.7-28.1-10.7c-26.7-0.8-52-1.1-76.1-1.1l-6.6,56.4l109.8-4.5l3.9-27.9
	L586.7,211.9L586.7,211.9z M180.9,279.6c-1.5-1.8-4.4-2.2-8.4-1.5c-11.8,2.9-20.1,6.1-24.9,9.5c-3.2,2.2-5.4,5.2-6.6,8.9
	c-1,3.7-1.4,7-1.5,9.8v0.3c0,1.5,0.1,2.8,0.4,3.7c0,0.4,0.1,0.8,0.3,1.2c1.1,1.9,3.7,2.5,7.8,1.8c4-0.5,8.1-2.6,12.2-5.9
	c8.3-6.5,14.9-13.1,19.7-19.4C182.4,284.6,182.8,281.7,180.9,279.6L180.9,279.6z"
          />
          <path
            fill="#FFFFFF"
            d="M356.2,204.4c5.6,0.6,7,2.3,4.1,5.2c-7.7,8.9-20.6,21-39.1,36.3c-8,7.6-15.1,11.4-21.6,11.4l-32.5-0.7
	l-13.3,5.8c6.5,0,13.1,0.1,19.9,0.4l29,0.1l70.2-62.6h-31.8l-5.2,4L356.2,204.4L356.2,204.4z"
          />
          <path
            fill="none"
            d="M267.1,256.6l32.5,0.7c6.5,0,13.8-3.9,21.6-11.4c18.3-15.3,31.4-27.4,39.1-36.3c2.8-3,1.5-4.7-4.1-5.2h-20.1
	 M615.2,181.3l9.9-13.1c0-0.3,0.1-0.4,0.4-0.4h0.6l0.4,0.4c0.1,0.1,0.1,0.4,0,0.6l-9.2,14.7"
          />
          <path
            fill="none"
            d="M617.4,183.3c-1.9-0.1-2.6-0.8-2.2-1.9v-0.1 M615.2,181.3c-1.8-0.8-5-1-9.5-0.7c-3.2,0-5.1,1.6-5.9,5
	 M446.8,182.5c-18.3,1-32.5,2.2-42.8,3.9c-3.3,0.4-6.3,1-8.9,1.4c-3,0.6-5.8,1.2-8.1,1.9c-0.3,0.1-0.4,0.3-0.5,0.6
	c-0.1,0.3-0.1,0.6-0.1,0.7c0.1,0.3,0.3,0.4,0.5,0.6l0.7,0.1l0.7-0.3c6.9-1.5,15.5-2.8,26-3.6c14.4-1.4,32.5-2.1,53.8-2.3
	c33.8-1,66.6-0.6,98.1,1.1c13.5,0.6,28.5,1.6,45,3.7c3.6,0.6,6.7,1.1,9.6,1.8l0.7,0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.5-0.6
	c0.1-0.3,0-0.6-0.1-0.7c-0.1-0.3-0.3-0.4-0.5-0.6c-2.3-0.7-5.1-1.4-8.1-1.9c-6.7-1.4-13.9-2.5-21.5-3.3 M599.8,185.5l-7.4-0.6
	 M585.6,252l3.8-27.9l-2.8-12.1c-1.9-7.2-11.3-10.7-28.1-10.7c-26.7-0.8-52-1.1-76.1-1.1 M587,206.3c1.2,1.4,1.9,3,2.3,5l9.2,40.2
	 M599.2,254.2h-1.4l-137.1,5.6 M598.5,251.4l0.7,2.8 M598.5,251.4l-12.9,0.6 M619.6,187.5l-19.8-1.9 M666.1,203
	c7.8-0.7,13.1-2.3,15.7-5.1c0.8-0.7,1-1.5,0.3-2.5l-0.1-0.1c-1-1.4-3.4-2.3-7.4-2.9l-34.4-2.2 M666.1,203l-8.7,0.4"
          />
          <path
            fill="none"
            d="M657.4,203.4c-0.8-2.6-3.4-4.5-7.8-5.5c-6.3-2.1-9.5-4.7-9.4-7.7 M645.5,203.8h0.1l4.8,6.7l-1.7,2.2l16,27.5
	l3.4,1.1l4.1,7"
          />
          <path
            fill="none"
            d="M657.4,203.4l-11.8,0.4l-58.6,2.5 M691.9,250.3l-3.6-2.6 M704,263.5c-1.2-1.4-2.9-2.1-5.1-2.2h-4.8
	c-2.8,0-4.4,1.6-5.1,4.8c-0.5,4.4-0.4,10.6,0.3,18.7c0.1,9.5,1.7,16.1,4.5,19.8c1.2,1.2,3.3,1.9,6.3,2.1c4.4,0.3,7-1.4,8-4.5
	c0.3-2.3,0.4-5.6,0.3-9.6 M691.9,250.3c3,1.6,5.8,4,8.1,6.7l1.2,1.5c0.8,1.1,1.5,2.2,2.2,3.4"
          />
          <path
            fill="none"
            d="M708.6,304.1l-0.1,0.8c-0.3,1-0.7,1.6-1.2,2.3c-1.7,2.1-4.4,2.9-8.4,2.8c-3.6-0.3-6.2-1.1-7.7-2.3l-0.3-0.4
	c-3.3-4.1-5-11.3-5.2-21.6c-1-8.9-1.1-16-0.4-20.8c0.8-3.4,2.8-5.2,6.1-5.2h5.9c2.8,0.1,4.7,1,6.2,2.5 M598.5,251.4l73.7-3l16.1-0.7
	 M688.4,247.7c-10.6-17.7-20.9-32.6-31-44.3 M599.2,254.2l92.7-3.9 M640.2,190.2c-6.3-1-13.2-1.9-20.5-2.8 M617.4,183.3
	c1,0,1.8,1.4,2.3,4.1 M592.3,185l-16-1.1c-23.9-1.4-51.5-2.1-82.4-2.2c-9.4,0-18.6,0-27.2,0.1l-19.7,0.6 M472.1,200.2l0.5-2.8
	 M472.1,200.2h-10 M460.7,259.8l0.5-2.8 M461.2,257.1l14.6-0.5 M475.8,256.5l6.7-56.4 M461.2,257.1l10.9-56.8 M482.6,200.1h-10.3
	 M472.7,197.5c27-0.1,55.6,0.1,85.8,1c14.6,0,24.1,2.6,28.3,7.8 M570.9,278.8h-0.5c-1.7,0-3,0.3-4.1,0.7c-1.1,0.4-1.7,1-1.7,1.5
	s0.5,1.1,1.7,1.5c1.1,0.4,2.5,0.7,4.1,0.7h0.5 M587.8,278c-0.3-0.5-0.8-1.2-1.4-1.7c-1.4-1.4-3-2.1-5-2.1h-3.7
	c-1.9,0-3.6,0.7-5.1,2.1c-0.8,0.8-1.4,1.6-1.7,2.6 M570.9,283.3c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7c1.9,0,3.6-0.7,5-2.1
	c1-1,1.7-2.1,1.9-3.2 M604.2,296.4c-13.1,3-24.8,9.2-35.1,18.7c-7.4,6.9-12.9,14.4-16.6,22.7c-4.3,10.2-6.7,20.6-7.6,31.5
	 M440.5,286.6h1.2c0.5,0.5,1.1,0.8,1.8,0.8h0.1c2.1,0,3.9-0.4,5.4-1c1.6-0.7,2.5-1.4,2.5-2.3c0-1-0.8-1.6-2.5-2.3
	c-1.5-0.5-3.3-1-5.4-1h-0.1c-0.7,0-1.4,0.3-1.9,1l-1.8,0.1 M604.3,314.4c-8.7,3-16.5,8.4-23.4,15.8c-4.4,4.8-8.1,9.9-10.9,15.4
	c-1.1,2.2-2.1,4.5-2.9,6.9c-1,2.6-1.7,5.2-2.3,8c-0.7,2.8-1.1,5.6-1.4,8.7c-0.1,1.4-0.3,2.8-0.3,4c-0.3,5.4-0.3,10.3-0.1,14.9
	 M563.3,369l-18.6,0.4 M563.2,388.2v1.2 M563.2,389.4l0.7-0.1c0.5,0,1.1,0.3,1.7,0.7c0.4,0.4,0.7,1,0.7,1.6v1.9"
          />
          <path
            fill="none"
            d="M561.5,391.7v-0.1c0-0.5,0.3-1.1,0.7-1.6c0.3-0.3,0.5-0.4,1-0.5 M566.1,393.4v3.9c0,0.5-0.3,1.1-0.7,1.6
	c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.7-0.7c-0.4-0.4-0.7-1-0.7-1.6v-5.5"
          />
          <path
            fill="none"
            d="M502.1,388.6c1.8,2.1,5.5,3,11,3.3l48.4-0.1 M563.2,388.2l-61.1,0.4 M544.8,369.5l-90.5,1.6 M454.2,371.1
	c-2.3-25.2-2.5-50.1-0.4-74.7c1-13.9,3.2-26.1,6.7-36.5 M639.3,266.7c-2.5-2.5-5.5-3.7-9.1-3.7s-6.6,1.2-9.2,3.7
	c-2.5,2.5-3.7,5.6-3.7,9.2s1.2,6.6,3.7,9.1s5.6,3.9,9.2,3.9s6.6-1.2,9.1-3.9c2.5-2.5,3.8-5.5,3.8-9.1S641.9,269.3,639.3,266.7
	L639.3,266.7z M675.7,341.8c-2.5-4.1-5.4-8-8.8-11.6c-3-3.3-6.3-6.1-9.6-8.5c-9.8-7-20.8-10.5-33.3-10.5c-6.9,0-13.5,1.1-19.5,3.3"
          />
          <path
            fill="none"
            d="M672.1,387.3c1.4-4.7,2.1-9.5,2.1-14.7c0-10.3-2.8-19.5-8.1-27.6c-1.9-2.8-4.1-5.5-6.6-8
	c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6c-1.1,1.1-2.2,2.2-3,3.3
	c-7.8,9.2-11.7,19.9-11.7,32.3c0,7.4,1.4,14.3,4.3,20.6 M678.2,315.4l2.2,2.1c3.6,3.6,6.7,7.4,9.5,11.4c2.6,4.1,5,8.4,6.6,12.8
	 M678.2,315.4l-0.1-0.1c-15-13.9-33.2-20.9-54.5-20.9c-6.7,0-13.3,0.7-19.4,2.2 M588.4,282.6h1.2c0.5,0.5,1.1,0.8,1.8,0.8h0.1
	c2.1,0,3.9-0.4,5.4-1c1.7-0.7,2.5-1.4,2.5-2.3s-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1c-0.7,0-1.4,0.3-1.9,1l-1.8,0.1
	 M604.2,296.4l-0.6-26l-4.4-16.2"
          />
          <path
            fill="none"
            d="M624.3,338.2v-0.1l-0.1-0.3l-0.4-0.3l-0.4,0.1l-0.1,0.1 M623.2,337.8l-0.1,0.3l-0.7,5.1
	c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2"
          />
          <path
            fill="none"
            d="M634.3,339.6l-0.5,4.8c-0.8,6.7-1.8,11.7-2.6,14.6c-1.1,3.6-1.9,5.6-2.5,6.3c-0.4,0.6-1.1,1-1.8,1.1l-0.4,0.1
	c-0.5,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.5-3.2-0.7-6.7c-0.1-3,0.3-8,1.2-14.7l0.7-4.8"
          />
          <path
            fill="none"
            d="M635.4,339.6l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0-0.4,0.1l-0.1,0.3 M604.2,296.4l0.1,18
	 M635.4,339.6v0.3l-0.5,5.1c-0.8,6.7-1.7,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7 M633.8,341l-1.2,2.6v0.4 M634.3,339.6l-0.5,1.4
	 M624.8,343v-0.5l-0.4-2.6 M633.8,341c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3"
          />
          <path
            fill="none"
            d="M632.5,344l-1.7,11.6c-0.5,2.2-1.4,3.2-2.6,2.9l-3.2-0.6c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4 M632.5,344
	c-2.5-0.7-5-1-7.7-1 M617.8,343.9V343c0-0.8,0.7-1.8,1.9-2.9 M621.8,365c-1.4-1.6-2.3-3.9-2.9-6.5c-0.8-4.1-1.2-9.1-1.1-14.7
	 M596.8,352.1h0.3l4.5,2.2c6.2,2.9,10.6,5.2,13.1,7c3.2,2.2,5.1,3.7,5.6,4.5 M614.8,366.3c-0.5,0.4-1,1-1.1,1.8
	c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.5-0.4,1-1,1.1-1.8c0.1-0.7,0-1.4-0.5-1.9
	c-0.4-0.5-1-1-1.7-1.1C616,365.7,615.3,365.9,614.8,366.3L614.8,366.3z"
          />
          <path
            fill="none"
            d="M614.4,368.1c-0.1,0.5,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.5,0.1,1,0,1.4-0.3c0.4-0.3,0.7-0.7,0.8-1.2
	c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8c-0.6-0.1-1,0-1.4,0.4C614.8,367.2,614.5,367.7,614.4,368.1L614.4,368.1z
	 M620.1,365.9l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.5,1.6-1.1,2.3l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1 M620.1,365.9
	c-0.7-2.1-1.9-4-3.9-5.9c-3-2.9-6.9-5.9-11.8-8.9 M596.6,363.4l10.6,5c2.2,0.7,3.4,0.6,3.9-0.5l1.5-2.9c0.7-1,0-2.1-2.1-3.3
	l-10.5-5.1"
          />
          <path
            fill="none"
            d="M596.3,353.2l4.4,2.1c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3c0.4,0.5,0.5,1.2,0.5,2.1v0.4
	c-0.1,0.5-0.4,1.1-0.8,1.6c-0.5,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8l-4.3-2.2 M622.6,380.3
	l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8c-1-0.6-1.8-0.7-2.6-0.6l-0.7,0.1"
          />
          <path
            fill="none"
            d="M615.9,378.1c-0.3,0.7-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1c0.7-0.3,1.2-0.7,1.5-1.4
	c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5s-1.4-0.4-2.1-0.1S616.1,377.4,615.9,378.1L615.9,378.1z"
          />
          <path
            fill="none"
            d="M597.6,393.5l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3
	c0.5,0.3,1,0.7,1.2,1.2c0.4,0.8,0.5,1.7,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-5,6.3-9.6,11.1l-3.3,3.4"
          />
          <path
            fill="none"
            d="M617.7,377.4c-0.5,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5c0.1,0.5,0.5,0.8,1,1.1c0.5,0.3,1,0.3,1.5,0.1
	c0.5-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4c-0.1-0.5-0.4-0.8-1-1.1C618.6,377.3,618.2,377.3,617.7,377.4L617.7,377.4z M617,374.8
	c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5 M595.2,370.1c5.4,1.9,10.2,3,14.4,3.6c2.6,0.3,5,0,7-0.8"
          />
          <path
            fill="none"
            d="M616.7,373c-1,0.1-3.3-0.4-7.2-1.5c-2.9-0.8-7.4-2.8-13.6-5.8l-4.5-2.2l-0.1-0.1 M631,367.4
	c0.8-0.7,3-1.6,6.6-3c3-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3"
          />
          <path
            fill="none"
            d="M649.8,357.1c-5.5,1.6-10,3.6-13.8,5.6c-2.3,1.4-4,3-5.2,4.7 M631,367.4l-0.4,0.4c-0.5,0.5-0.8,1.2-1,2.1v0.4
	c-0.1,1,0.1,1.8,0.4,2.5c0.4,1,1.1,1.6,1.8,2.1l0.5,0.3 M627.6,361.1c-0.5-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1
	c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.5,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1c0.4-0.5,0.5-1.2,0.5-1.9
	C628.5,362.2,628.1,361.5,627.6,361.1L627.6,361.1z"
          />
          <path
            fill="none"
            d="M627.2,361.7c-0.4-0.3-0.8-0.4-1.4-0.4c-0.5,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4c0.1,0.5,0.3,1,0.8,1.2
	c0.4,0.3,1,0.4,1.4,0.3c0.5-0.1,1-0.3,1.2-0.7s0.4-1,0.4-1.5C627.8,362.4,627.6,362,627.2,361.7L627.2,361.7z M629.6,366.1l-0.4,0.4
	c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.7,0-2.5-0.3l-0.4-0.1c-0.7-0.4-1.4-1-1.7-1.6l-0.4-0.5 M627.3,370c-0.7-0.7-1.5-1.1-2.6-1.1
	c-1.1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1s1.1-1.5,1.1-2.6
	C628.3,371.5,628,370.7,627.3,370L627.3,370z M631.4,376.9c-0.1-0.1-0.4-0.1-0.5-0.3c-0.7-0.3-1.5-0.4-2.3-0.3h-0.4
	c-0.8,0.1-1.7,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5 M635.8,371.5c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.4-0.7-0.8-1.1-1
	c-0.5-0.1-1-0.1-1.5,0.1c-0.4,0.3-0.8,0.5-1,1.1s-0.1,1,0.1,1.5c0.3,0.5,0.6,0.8,1.1,1l1.5-0.1C635.3,372.3,635.5,371.9,635.8,371.5
	L635.8,371.5z"
          />
          <path
            fill="none"
            d="M636.5,371.6c0.3-0.7,0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4s-1.4-0.1-2.1,0.1s-1.1,0.8-1.4,1.5
	s-0.1,1.4,0.1,2.1c0.3,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1C635.8,372.9,636.2,372.3,636.5,371.6L636.5,371.6z"
          />
          <path
            fill="none"
            d="M659.1,371.5l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4c-0.7-0.3-1.2-0.7-1.7-1.4l-0.1-0.4
	c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2c0.7-0.5,2.8-1.5,6.2-2.8c2.9-1.1,7.7-2.2,14.4-3.3l4.8-0.8 M636.4,404.8l-2.3-4.3
	c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9l0.3-0.3c0.4-0.4,1-0.7,1.7-0.8c1-0.1,1.8-0.1,2.5,0.4
	c0.8,0.4,2.3,2.2,4.5,5.1c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4 M629.6,366.1c1.7-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14
	 M596.8,352.1h-0.3c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.3 M596.3,353.2l1.1,1 M604.6,351l-0.7-0.4
	c-0.7-0.5-1.8-0.4-3.6,0.1 M600.1,356.4l-0.4-0.3l-2.2-1.9 M619.9,340.1c-7,1-13.1,4-18.3,9.2l-1.2,1.2 M600.3,350.7
	c-1,0.3-2.2,0.8-3.6,1.5 M593.3,362.7l2.9,0.4l0.4,0.3 M591.9,362.4h-0.4c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1
	 M593.3,362.7l-1.4-0.3 M591.2,363.4c0.4,1.5,0.7,2.8,1.1,3.7 M595.2,370.1l-0.7-0.3c-0.8-0.3-1.5-1.2-2.2-2.9 M598,385.1
	c-1.8-3.9-2.8-8-2.8-12.5l0.1-2.3"
          />
          <path
            fill="none"
            d="M592.3,367c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6 M600.1,356.4c-1.5,2.2-2.6,4.5-3.4,7"
          />
          <path
            fill="none"
            d="M588.2,372.6c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6c10,0,18.6-3.6,25.7-10.6
	c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.6-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6c-10,0-18.6,3.6-25.7,10.6
	C591.8,353.9,588.2,362.6,588.2,372.6z M597.4,354.2c-1.8,2.6-3.2,5.4-4.1,8.4"
          />
          <path
            fill="none"
            d="M598,385.1l-0.5,0.4c-0.7,0.5-1,1.8-1,3.7 M596.6,392.8l0.1-0.1l3.3-3.7c4.7-5.1,8.3-8.5,10.7-10.3
	c3.2-2.3,5.1-3.7,6.1-3.9 M596.3,389.3c0,1,0.1,2.2,0.3,3.6 M596.6,392.8v0.3c0,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3
	 M601.7,391c0.7,0.8,1.4,1.6,2.2,2.3c1.1,1.1,2.2,2.1,3.4,3 M601.7,391l-0.3,0.3l-2.5,1.5 M599,392.8c0.8,1,1.7,1.9,2.6,2.9
	c1.4,1.4,2.8,2.5,4.1,3.6 M597.6,393.5l1.4-0.7"
          />
          <path
            fill="none"
            d="M577.4,393.2c1.9,4.1,4.4,8.1,7.4,11.7c1,1.1,2.1,2.2,3,3.3c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9
	c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c2.6-2.6,4.8-5.2,6.6-8c2.8-4,4.7-8.4,6.1-12.9"
          />
          <path
            fill="none"
            d="M605.7,401.5l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1c2.2-3.2,3.4-5.4,3.6-6.3 M605,400.5
	c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3 M605.7,399.3l-0.7,1.2 M631,380.7c0-0.5-0.1-1-0.5-1.4
	s-0.8-0.5-1.4-0.5c-0.6,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5c0.5,0,1-0.1,1.4-0.5
	C630.9,381.7,631,381.3,631,380.7L631,380.7z"
          />
          <path
            fill="none"
            d="M631,378.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.2-0.8,1.9s0.3,1.4,0.8,1.9
	c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9C631.8,380,631.6,379.4,631,378.9L631,378.9z M642,396.4
	l-5.6-10c-1.4-1.9-2.5-2.5-3.4-1.8l-2.9,1.5c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3 M635.4,405.5l-0.1-0.1l-2.5-4.4
	c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.7-1.9-6.1-1.8-7"
          />
          <path
            fill="none"
            d="M624.5,380.6c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2 M607.3,396.4l8-8.5c1.4-1.9,1.7-3.2,0.7-3.9
	l-2.2-2.2c-0.7-1-2.1-0.7-3.9,0.8l-8.1,8.4 M605.7,399.3l1.2-2.6l0.4-0.3 M609.4,401.6c1.9-0.1,3.2-0.5,3.6-1.2l0.4-0.5
	 M635.1,400.1l0.3,0.4l0.7,2.9 M636.4,404.8l-0.3-1.4 M635.4,405.5c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3 M631.7,404.7
	c1,0.3,2.2,0.7,3.7,1 M628.4,401.8l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1 M628.4,401.8l-3.7,0.1c-4.1,0-7.8-0.7-11.3-2.2 M609.4,401.6
	c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7"
          />
          <path
            fill="none"
            d="M613.4,399.9c3.4-4.5,6.1-8.8,7.8-12.7c1.1-2.5,1.5-4.8,1.5-6.9 M646.4,399.6v-0.3l-2.3-4.5
	c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4"
          />
          <path
            fill="none"
            d="M647.1,391.6c-3.3-4.7-6.5-8.4-9.5-11.3c-2.1-1.8-4.1-2.9-6.1-3.4 M604.6,351c3.9-3.7,8.4-6.1,13.3-7.2
	 M605.7,401.5c1.5,0.1,2.8,0.1,3.7,0.1 M696.5,341.8c1.4,3.7,2.5,7.6,3.3,11.6c1.8,6.1,5.1,9.2,9.8,9.5h12.5c2.3,0,3.7-1.4,4.3-3.9
	c2.5-5.9,4.8-7.8,6.9-5.9l1.4,5.2 M675.7,341.8l0.5,0.8c5.4,9.4,8.3,19.8,8.5,31.5v9.1 M696.5,341.8h-20.8 M639,346.8l0.1-0.8
	c0.3-0.8-0.1-1.9-1.2-3.4 M654.1,357.9c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3 M639,346.8c2.3,1.4,4.5,3,6.5,5c1.7,1.6,3.2,3.6,4.4,5.4
	 M654.1,357.9c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9 M652.9,363.5l0.4-0.1l2.5-1.2 M654.1,371.1l-11.4,1.9
	c-2.3,0.1-3.6-0.4-3.6-1.5l-0.5-3.2c-0.3-1.1,0.5-2.1,2.8-2.8l11.4-2.2 M657.1,361.5h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	s-0.1-0.3-0.3-0.4h-0.1 M655.8,362.2l1.4-0.7 M659.3,372.6l-0.3,0.1l-5.1,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.9,0.1-6.3,0-7.3-0.4
	 M659.3,372.6l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4l-0.3-0.1 M657.4,371.4l-2.8-0.3h-0.6 M653.7,378.1h0.8
	c0.8,0,1.8-0.7,2.9-2.1 M659.1,371.5l-1.7-0.1 M657.3,375.9c0.7-0.8,1.4-1.9,2.1-3.3 M657.4,371.4c-0.1-3.2-0.7-6.3-1.7-9.2
	 M652.9,363.5c0.8,2.5,1.2,5,1.4,7.7 M657.3,360.2c-1.2-1-2.2-1.8-3.2-2.3 M644.2,398.9l-1.9-1.9l-0.3-0.5 M647.8,395.9
	c0.4-1.6,0.4-2.9-0.1-3.4l-0.5-0.7 M647.8,395.9c5.6-5.6,8.8-12.2,9.5-19.9 M645.3,400v0.1l0.3,0.1h0.5l0.3-0.4v-0.3 M644.2,398.9
	l1.1,1.1 M646.4,399.6c0.5-1.5,1.1-2.8,1.4-3.9 M684.7,383.2v3.4 M684.7,386.6l-12.5,0.7 M684.7,386.6l7.4-0.4l44-8.4
	c2.6-0.6,4-1.4,3.9-2.3c-0.4-1.5-2.3-1.9-6.2-1.5l-1.4,0.1"
          />
          <path
            fill="none"
            d="M632.2,375.2c1.8,1.2,4,2.1,6.6,2.6c4.1,0.5,9.1,0.7,14.9,0.3 M653.7,378.1c-1,5.1-3,9.6-6.6,13.6 M635.1,400.1
	c2.5-1,4.7-2.2,6.9-3.7 M636.1,403.4c2.9-1.1,5.6-2.6,8.3-4.5 M637.9,342.5c-0.5-0.8-1.5-1.9-2.5-3 M624.4,339.9l-0.1-1.6
	 M619.9,340.1c0.8-0.7,1.9-1.5,3.3-2.3 M570.9,283.3l17.5-0.7 M587.8,278l-16.9,0.8 M566.1,393.4l11.3-0.1 M585.6,252l-109.8,4.5
	 M703.6,249.2l-0.1,0.1c-0.3,0.3-0.5,0.4-1,0.4l-10.7,0.4 M703.6,249.2c0.1-0.3,0.3-0.6,0.3-0.8c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.7-0.4-1-0.4 M719.3,324.1c5.5-23,5.5-42.8-0.3-59c-0.5-1.1-1.1-2.1-1.8-3l-2.2-3.2c-3.2-4.3-7-7.4-11.4-9.5
	 M703.4,262.1c0.7,0.7,1.1,1.5,1.5,2.5v0.1 M704.9,264.8l-0.8-1.2 M703.4,262.1l0.7,1.4"
          />
          <path
            fill="none"
            d="M708.6,292.4l-0.3-5.1c-0.8-11.1-1.9-18.7-3.4-22.7 M708.6,304.1v11.3 M720,324.6l-0.7-0.5 M719.3,324.1
	l-10.7-8.7 M719.3,324.1c3.4,3.9,8.3,5.6,14.6,5.4c2.2-0.1,3.6,0.5,4,1.9l1.1,5.1c-1.9,0.7-2.9,2.1-2.9,4.1
	c0.1,7.8-0.4,13.8-1.4,17.6 M708.6,292.4c0.3,4.8,0.3,8.7-0.1,11.6 M732.5,374c0.7-5.9,1.9-9.2,3.7-10.2c1.8-1.4,1.2-3.3-1.5-5.5
	 M734.7,358.3c-2.3-1.1-4.4,1.1-6.3,6.7c0,0.7-0.1,1.2-0.4,1.9 M732.5,374l-2.9,0.3 M729.6,374.3l-9.2,1.2 M729.6,374.3
	c0.1-4.3-0.4-6.7-1.5-7.6 M728.1,366.8c-1,3.4-3.4,6.3-7.6,8.7 M720.4,375.5c-7.2,4.1-19.1,6.7-35.8,7.7 M688.4,247.7l14.2-0.6
	 M708.6,315.4h-30.4 M702.5,247.2c-12.8-17.7-24.9-32.5-36.5-44.2 M179.5,275.1l-1.5,0.3c-15.3,3.6-25.9,7.4-31.6,11.6
	c-3.3,2.3-5.6,5.6-6.9,9.8c-1.5,6.5-1.8,11.4-1,14.9c0.3,3,2.5,4.5,6.5,4.5c6.2,0.1,11.1-1.2,15-4.1c10.7-8.3,19.1-16.1,25.6-23.7
	c3.9-4.3,5-7.8,3.3-10.7C187.4,275.2,184.2,274.4,179.5,275.1L179.5,275.1z"
          />
          <path
            fill="none"
            d="M172.5,278.2c-11.8,2.9-20.1,6.1-24.9,9.5c-3.2,2.2-5.4,5.2-6.6,8.9c-1,3.7-1.4,7-1.5,9.8v0.3
	c0,1.5,0.1,2.8,0.4,3.7c0,0.4,0.1,0.8,0.3,1.2c1.1,1.9,3.7,2.5,7.8,1.8c4-0.6,8.1-2.6,12.2-5.9c8.3-6.5,14.9-13.1,19.7-19.4
	c2.6-3.4,3-6.2,1.1-8.3C179.4,277.8,176.5,277.4,172.5,278.2L172.5,278.2z M247.1,297.9c-7.6-2.5-15.7-3.6-24.4-3.6
	c-20.9,0-38.7,7-53.5,20.9c-1.1,1-2.1,1.9-3,2.9 M269.9,390.5c2.1-5.5,3-11.4,3-17.9c0-10.3-2.8-19.5-8.1-27.6
	c-1.9-2.8-4.1-5.5-6.6-8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1s-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6
	c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3c0,7,1.2,13.6,3.9,19.7"
          />
          <path
            fill="none"
            d="M245.1,262.4c-12.4,0-24.2,0.1-35.4,0.5c-33.7,2.6-59,8.3-76.1,16.9c-9.5,5.1-15.1,9.5-16.9,13.1
	c-1.1,2.2-0.7,3.7,1.2,4.4l0.8,0.1 M117.6,323.8h-1.4c-2.1,0-2.8,1.2-2.2,3.7c0.8,4-0.4,6.3-3.7,7c-3.9,0-6.1,2.2-6.3,6.3v12.5
	c0,2.1,1,3.4,2.6,4.1 M117.6,323.8h2.9c1.2,0,2.1,0.7,2.6,2.2c0.3,0.7,0.7,1.2,1.2,1.6c0.7,0,1.2-0.3,1.7-0.7 M126.4,321.9v2.5
	c0.1,1.1,0,1.9-0.4,2.5 M125.9,326.8l-1.1-2.5c-0.8-1.8-2.3-2.6-4.3-2.6 M120.5,321.9c-2.3,0.1-3.3,0.8-2.9,1.9"
          />
          <path
            fill="none"
            d="M126.4,321.9c0-5.1-0.8-11.3-2.3-18.3c-1-3.6-2.6-5.6-5.1-6.2 M119,297.4c-0.6,9.9,0,18,1.5,24.4 M111.7,380.7
	l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-13.2c0-2.9-1.2-4.5-3.6-4.7h-3.2 M108.8,358c-0.8-0.1-1.5-0.3-2.1-0.5"
          />
          <path
            fill="none"
            d="M109.2,381.3l1.7-0.3l1.9-0.5l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-5c0.1-1.9,1-3,2.5-3.3l21.9-1.9
	c4.3,0,6.5-1.9,6.5-5.6c0.1-2.5,0.6-5,1-7.4"
          />
          <path
            fill="none"
            d="M111.7,380.7c-1.4-4.1-2.3-8.3-2.9-12.4c-0.4-3.4-0.4-6.9-0.1-10.3 M109.8,381.3l1.9-0.6 M109.8,381.3h-0.6
	 M109.2,381.3l-2.3,0.3c-1.2,0.5-1,1.9,0.8,4.3c1.7,1.6,5.2,2.9,10.9,3.7c7.2,1,16.1,1.6,26.7,1.9 M106.6,357.5
	c-0.8,7.7,0.1,15.7,3,23.8 M284.9,374.1c-0.3-11.7-3.2-22.1-8.7-31.5c-2.6-4.4-5.8-8.5-9.5-12.5c-3.2-3.3-6.5-6.1-9.9-8.5
	c-9.9-7-21.3-10.5-34-10.5c-17.2,0-31.8,6.3-43.9,19c-4.5,4.8-8.3,9.9-11,15.4c-1.1,2.2-2.2,4.5-3,6.9"
          />
          <path
            fill="none"
            d="M195.1,353.2l4.4,2.1c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3c0.4,0.5,0.6,1.2,0.6,2.1v0.4
	c-0.1,0.5-0.4,1.1-0.8,1.6c-0.6,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8l-4.3-2.2 M190.7,362.4h-0.4
	c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1"
          />
          <path
            fill="none"
            d="M190,363.4l0.1,0.1l4.5,2.2c6.2,3,10.7,5,13.6,5.8c3.7,1.1,6.2,1.5,7.2,1.5 M190.7,362.4l1.5,0.3 M190,363.4
	c0.4,1.5,0.7,2.8,1.1,3.7 M194.1,370.1l-0.7-0.3c-0.8-0.3-1.5-1.2-2.2-2.9"
          />
          <path
            fill="none"
            d="M191.1,367c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6 M166.3,318.1c-6.9,7.2-11.8,15-15,23.7
	c-1.2,3.4-2.2,7-2.9,10.6 M145.3,391.6c0.3,4.4,1.9,7.2,5.2,8.5l7.7,2.6c2.3,0.4,3.6,0.1,3.6-1v-0.3c-0.3-3-0.6-6.2-0.7-9.5
	 M160.8,391.9c-5.6,0-10.9-0.1-15.7-0.3 M175.7,392.3c1.9,4.5,4.5,8.8,7.8,12.8c1,1.1,2.1,2.2,3,3.3c5.5,5.5,11.7,9.5,18.6,12
	c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c2.6-2.6,4.8-5.2,6.6-8c2.1-3.2,3.7-6.5,5.1-9.9
	 M164.9,352.4c-2.3,6.5-3.7,13.3-4,20.8c-0.3,6.7-0.3,12.9,0,18.6 M148.3,352.4h16.6 M160.8,391.9l15,0.4 M166.3,318.1l-39.9,3.7
	 M341.3,200.4l-5.2,4l-68.9,52.3l-13.3,5.9 M335.1,248c-1,0.4-1.9,1-2.9,1.5l-0.5,0.4c-2.6,1.8-4.1,3.9-4.7,6.3 M327,256.2
	c-0.1,0.7-0.3,1.2-0.3,1.9c0,0.8,0.1,1.5,0.3,2.3 M352.2,261.6c0.3-1.1,0.4-2.2,0.4-3.4c0-3.2-0.8-5.9-2.6-8.3
	c-1.8-2.3-3.9-3.4-6.5-3.4H342 M342,246.6l-1.2,0.1c-1.9,0.3-3.9,0.7-5.5,1.4"
          />
          <path
            fill="none"
            d="M342,269.9c2.2-0.3,4-1.4,5.5-3.4c0.4-0.5,0.8-1.2,1.2-1.9c0.4-0.8,0.8-1.8,1.1-2.8c0.3-1.1,0.4-2.3,0.4-3.6
	c0-3.2-0.8-5.9-2.6-8.3c-1.5-2.1-3.4-3.2-5.5-3.3 M373.1,200.4h-31.8 M316.7,263.1l5.1-5.2c19.3-19.9,39.9-38.9,61.9-57.1
	c0.3-0.1,0.5-0.3,0.8-0.3 M446.8,182.5c-19,0.4-36.2,1.2-51.6,2.6l-18.7,1.9c-15.7,2.6-27.5,7-35.4,13.5 M384.4,200.4h-11.3
	 M446.3,257.7l-94.1,3.9 M462.1,200.2c-27.5,0.4-53.2,1.2-77.2,2.9c-17.5,14.4-34.1,29.4-49.8,44.8 M325.2,265.5l-11.1,0.4h-0.7
	 M313.4,265.9l3.3-2.8 M307.3,265.6l6.1,0.3 M294,278.1c0.3,0.4,0,1-0.8,1.8l-14.4,12.8c-0.8,0.7-1.9,1.4-3.3,1.9
	c-1.4,0.5-2.5,0.7-3.4,0.7s-1.5-0.3-1.6-0.8c0-0.5,0.3-1.1,1.1-1.8l13.1-11.7c0.8-0.7,1.8-1.4,3.2-1.9c1.4-0.7,2.8-1.1,3.9-1.2
	C292.9,277.6,293.7,277.7,294,278.1L294,278.1z"
          />
          <path
            fill="none"
            d="M298.4,277c-0.1,0.4-0.4,0.8-1,1.4l-16.2,15.7c-2.3,2.2-5.2,3.3-8.5,3.2l-10-0.4c-3-0.1-3.3-1.2-0.8-3.3
	l17.1-14.9c1.9-1.8,4.4-2.6,7.3-2.6l9.4-0.3c1.8,0,2.8,0.1,2.9,0.8"
          />
          <path
            fill="none"
            d="M262.5,304.8l5.1-4.5l0.3-0.3c1.5-1.1,3.6-1.5,6.3-1h0.1c1.6-0.1,3.4-0.8,5.1-1.9c10.5-8.9,16.8-15.4,19-19.4
	V277 M298.4,277v-0.4 M298.4,276.6v-3c0.3-1.2,1-2.2,1.9-3.2l7-4.8 M293.3,264.9l14,0.7 M316.7,263.1l-13.8-0.1 M262.5,304.8
	c4.8,2.9,9.4,6.3,13.6,10.5l2.3,2.2c3.6,3.6,6.7,7.4,9.2,11.4c5.1,7.8,8.5,16.5,10,26c1.2,7,2.1,13.2,2.5,19 M313.4,265.9
	c-8.7,4.4-13.1,20.8-13.1,49.1v0.3l0.1,1c1.1,23.5,2.8,42.6,5.4,57.6 M302.9,263l-29-0.1c-6.9-0.1-13.5-0.3-19.9-0.4 M253.8,262.6
	l-8.7-0.1 M223,338.2v-0.1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.3s-0.4,0-0.6,0.1l-0.1,0.1"
          />
          <path
            fill="none"
            d="M221.9,337.8l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2"
          />
          <path
            fill="none"
            d="M233,339.6l-0.6,4.8c-0.8,6.7-1.8,11.7-2.6,14.6c-1.1,3.6-1.9,5.6-2.5,6.3c-0.4,0.6-1.1,1-1.8,1.1l-0.4,0.1
	c-0.6,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.6-3.2-0.7-6.7c-0.1-3,0.3-8,1.2-14.7l0.7-4.8 M234.3,339.6l-0.1-0.1
	l-0.4-0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0.1-0.1,0.3 M248.7,357.1c-1.2-1.9-2.8-3.7-4.4-5.4c-2.1-2.1-4.1-3.7-6.5-5 M252.8,357.9
	c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3 M252.8,357.9c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9 M234.3,339.6v0.3l-0.6,5.1
	c-0.8,6.7-1.6,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7 M237.7,346.8l0.1-0.8c0.3-0.8-0.1-1.9-1.2-3.4 M236.7,342.5
	c-0.6-0.8-1.5-1.9-2.5-3"
          />
          <path
            fill="none"
            d="M232.5,341l-1.1,2.6l-0.1,0.4 M233,339.6l-0.6,1.4 M231.2,344l-1.6,11.6c-0.6,2.2-1.4,3.2-2.6,2.9l-3.2-0.6
	c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4 M257.8,371.5l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4c-0.7-0.3-1.2-0.7-1.6-1.4
	l-0.1-0.4c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2c0.7-0.5,2.8-1.5,6.2-2.8c2.9-1.1,7.7-2.2,14.4-3.3l4.8-0.8"
          />
          <path
            fill="none"
            d="M252.8,371.1l-11.4,1.9c-2.3,0.1-3.6-0.4-3.6-1.5l-0.6-3.2c-0.3-1.1,0.6-2.1,2.8-2.8l11.4-2.2 M225.9,361.7
	c-0.4-0.3-0.8-0.4-1.4-0.4c-0.6,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3
	c0.6-0.1,1-0.3,1.2-0.7c0.3-0.4,0.4-1,0.4-1.5C226.6,362.4,226.3,362,225.9,361.7L225.9,361.7z"
          />
          <path
            fill="none"
            d="M226.3,361.1c-0.6-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1c-0.4,0.5-0.6,1.2-0.4,1.9
	c0.1,0.7,0.4,1.2,1.1,1.8c0.6,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1c0.4-0.5,0.6-1.2,0.6-1.9
	C227.3,362.2,226.8,361.5,226.3,361.1L226.3,361.1z"
          />
          <path
            fill="none"
            d="M228.4,366.1l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.6,0-2.5-0.3l-0.4-0.1c-0.7-0.4-1.4-1-1.6-1.6
	l-0.3-0.5 M228.4,366.1c1.6-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14 M231.1,375.2l-0.6-0.3c-0.8-0.4-1.4-1.1-1.8-2.1
	c-0.4-0.7-0.6-1.5-0.4-2.5V370c0.1-0.8,0.6-1.5,1-2.1l0.4-0.4 M231.8,369.2c-0.4,0.3-0.8,0.5-1,1.1c-0.1,0.5-0.1,1,0.1,1.5
	c0.3,0.5,0.6,0.8,1.1,1l1.5-0.1c0.4-0.3,0.8-0.7,1-1.1c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.4-0.7-0.8-1.1-1
	C232.8,368.9,232.3,368.9,231.8,369.2L231.8,369.2z"
          />
          <path
            fill="none"
            d="M230.1,370c-0.3,0.7-0.1,1.4,0.1,2.1c0.3,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1c0.7-0.3,1.1-0.8,1.4-1.5
	s0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4c-0.7-0.3-1.4-0.1-2.1,0.1C230.8,368.9,230.4,369.3,230.1,370L230.1,370z M226,370
	c-0.7-0.7-1.5-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1
	c1,0,1.9-0.4,2.6-1.1s1.1-1.5,1.1-2.6C227.1,371.5,226.7,370.7,226,370L226,370z M230.3,376.9c-0.1-0.1-0.4-0.1-0.6-0.3
	c-0.7-0.3-1.5-0.4-2.3-0.3H227c-0.8,0.1-1.6,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5"
          />
          <path
            fill="none"
            d="M235.1,404.8l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9l0.3-0.3
	c0.4-0.4,1-0.7,1.6-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4 M229.7,367.4
	c1.2-1.8,2.9-3.3,5.2-4.7c3.7-2.1,8.3-3.9,13.8-5.6 M231.1,375.2c1.8,1.2,4,2.1,6.6,2.6c4.1,0.5,9.1,0.7,14.9,0.3"
          />
          <path
            fill="none"
            d="M258.2,372.6l-0.3,0.1l-5.1,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.9,0.1-6.3,0-7.3-0.4 M229.7,367.4
	c0.8-0.7,3-1.6,6.6-3c2.9-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3 M256,361.5h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4H256
	 M254.6,362.2l1.4-0.7 M251.6,363.5l0.6-0.1l2.5-1.2 M256.1,371.4l-2.8-0.3h-0.6 M252.4,378.1h0.8c0.8,0,1.8-0.7,2.9-2.1
	 M251.6,363.5c0.8,2.5,1.2,5,1.4,7.7 M256.1,371.4c-0.1-3.2-0.7-6.3-1.6-9.2 M256,360.2c-1.2-1-2.2-1.8-3.2-2.3 M223.5,343l0.1-0.5
	l-0.4-2.6 M232.5,341c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3 M216.7,343.9V343c0-0.8,0.7-1.8,1.9-2.9 M220.5,365c-1.4-1.6-2.3-3.9-2.9-6.5
	c-0.8-4.1-1.2-9.1-1.1-14.7"
          />
          <path
            fill="none"
            d="M218.6,340.1c-7,1-13.1,4-18.3,9.2l-1.2,1.2 M195.5,352.1h0.3l4.5,2.2c6.2,2.9,10.6,5.2,13.1,7
	c3.2,2.2,5.1,3.7,5.6,4.5 M195.5,352.1h-0.3c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.3 M198.8,356.4l-0.4-0.3l-2.1-1.9
	 M199.1,350.7c-1,0.3-2.2,0.8-3.6,1.5 M196.3,354.2l-1.2-1"
          />
          <path
            fill="none"
            d="M203.3,351l-0.7-0.4c-0.7-0.5-1.8-0.4-3.6,0.1 M192.2,362.7l2.8,0.4l0.4,0.3 M196.7,385.1
	c-1.8-3.9-2.8-8-2.8-12.5l0.1-2.3"
          />
          <path
            fill="none"
            d="M215.4,373c-1.9,0.7-4.3,1-7,0.8c-4.3-0.5-9.1-1.6-14.4-3.6 M195.3,363.4l10.6,5c2.2,0.7,3.4,0.6,3.9-0.5
	l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1 M219,365.9l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.6,1.6-1.1,2.3l-0.3,0.3
	c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1"
          />
          <path
            fill="none"
            d="M212.4,368.1c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.6-0.4,1-1,1.1-1.8
	c0.1-0.7,0-1.4-0.6-1.9c-0.4-0.5-1-1-1.6-1.1c-0.7-0.1-1.4,0.1-1.9,0.5C212.9,366.7,212.5,367.2,212.4,368.1L212.4,368.1z"
          />
          <path
            fill="none"
            d="M213.2,368.1c-0.1,0.5,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.6,0.1,1,0,1.4-0.3c0.4-0.3,0.7-0.7,0.8-1.2
	c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8c-0.6-0.1-1,0-1.4,0.4C213.5,367.2,213.2,367.7,213.2,368.1L213.2,368.1z
	 M214.6,378.1c-0.3,0.7-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1s1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1
	c-0.3-0.7-0.7-1.2-1.4-1.5c-0.7-0.3-1.4-0.4-2.1-0.1C215.4,377,215,377.4,214.6,378.1L214.6,378.1z"
          />
          <path
            fill="none"
            d="M221.5,380.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8c-1-0.6-1.8-0.7-2.6-0.6
	l-0.7,0.1"
          />
          <path
            fill="none"
            d="M196.3,393.5l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3
	c0.6,0.3,1,0.7,1.2,1.2c0.4,0.8,0.6,1.7,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-4.9,6.3-9.6,11.1l-3.3,3.4"
          />
          <path
            fill="none"
            d="M216.4,377.4c-0.6,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5c0.1,0.5,0.6,0.8,1,1.1c0.6,0.3,1,0.3,1.5,0.1
	c0.6-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4c-0.1-0.5-0.4-0.8-1-1.1C217.4,377.3,216.9,377.3,216.4,377.4L216.4,377.4z M219,365.9
	c-0.7-2.1-1.9-4-3.9-5.9c-3-2.9-6.9-5.9-11.8-8.9 M198.8,356.4c-1.5,2.2-2.6,4.5-3.4,7 M216.7,343.9c-5,1.1-9.4,3.4-13.3,7.2
	 M215.7,374.8c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5 M234.1,405.5l-0.1-0.1l-2.5-4.4c-3.3-6.1-5.5-10.5-6.5-13.3
	c-1.2-3.7-1.9-6.1-1.8-7 M204.4,401.5l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1c2.2-3.2,3.4-5.4,3.6-6.3"
          />
          <path
            fill="none"
            d="M212.1,399.9c3.4-4.5,6.1-8.8,7.8-12.7c1.1-2.5,1.5-4.8,1.5-6.9 M223.3,380.6c-0.7,2.1-0.8,4.4-0.4,7
	c0.7,4.3,2.2,8.9,4.3,14.2 M206.1,396.4l8-8.5c1.4-1.9,1.6-3.2,0.7-3.9l-2.2-2.2c-0.7-1-2.1-0.7-3.9,0.8l-8.1,8.4 M196.7,385.1
	l-0.6,0.4c-0.7,0.5-1,1.8-1,3.7 M200.4,391l-0.3,0.3l-2.5,1.5 M195.5,392.8v0.3c0,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3
	 M197.7,392.8c0.8,1,1.6,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6 M195.5,392.8l0.1-0.1l3.3-3.7c4.7-5.1,8.3-8.5,10.7-10.3
	c3.2-2.3,5.1-3.7,6.1-3.9 M196.3,393.5l1.4-0.7 M195.2,389.3c0,1,0.1,2.2,0.3,3.6"
          />
          <path
            fill="none"
            d="M200.4,391l2.2,2.3c1.1,1.1,2.2,2.1,3.4,3 M204.4,399.3l1.2-2.6l0.4-0.3 M203.7,400.5c-0.1,0.1-0.1,0.3-0.1,0.4
	s0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3 M204.4,399.3l-0.7,1.2 M204.4,401.5c1.5,0.1,2.8,0.1,3.7,0.1 M208.1,401.6
	c1.9-0.1,3.2-0.5,3.6-1.2l0.4-0.5 M227.1,401.8l-3.7,0.1c-4.1,0-7.8-0.7-11.3-2.2"
          />
          <path
            fill="none"
            d="M223.4,408.9c10,0,18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.6-10.7-25.7
	c-7.2-7.2-15.7-10.6-25.7-10.6s-18.6,3.6-25.7,10.6c-7.2,7.2-10.7,15.7-10.7,25.7c0,10,3.6,18.6,10.7,25.7
	C204.8,405.4,213.4,408.9,223.4,408.9L223.4,408.9z"
          />
          <path
            fill="none"
            d="M208.1,401.6c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7 M246.5,395.9c0.4-1.6,0.4-2.9-0.1-3.4l-0.6-0.7
	 M246.5,395.9c5.6-5.6,8.8-12.2,9.5-19.9 M229.7,378.9c-0.6-0.5-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8c-0.6,0.5-0.8,1.2-0.8,1.9
	s0.3,1.4,0.8,1.9c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8s0.8-1.1,0.8-1.9C230.6,380,230.3,379.4,229.7,378.9L229.7,378.9z"
          />
          <path
            fill="none"
            d="M227.9,382.7c0.6,0,1-0.1,1.4-0.5c0.4-0.4,0.6-0.8,0.6-1.4c0-0.5-0.1-1-0.6-1.4c-0.4-0.4-0.8-0.5-1.4-0.5
	s-1,0.1-1.4,0.5s-0.6,0.8-0.6,1.4c0,0.5,0.1,1,0.6,1.4C226.8,382.5,227.4,382.7,227.9,382.7L227.9,382.7z M240.7,396.4l-5.6-10
	c-1.4-1.9-2.5-2.5-3.4-1.8l-2.9,1.5c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3 M242.9,398.9L241,397l-0.3-0.5"
          />
          <path
            fill="none"
            d="M234,400.1l0.1,0.4l0.7,2.9 M235.1,404.8l-0.3-1.4 M234.1,405.5c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3
	 M227.1,401.8l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1 M230.6,404.7c1,0.3,2.2,0.7,3.7,1 M245.1,399.6v-0.3l-2.3-4.5
	c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4 M242.9,398.9l1.1,1.1 M244,400l0.1,0.1l0.3,0.1h0.4c0.1-0.1,0.3-0.3,0.3-0.4v-0.3
	 M234,400.1c2.5-1,4.7-2.2,6.9-3.7 M246,391.6c-3.3-4.7-6.5-8.4-9.5-11.3c-2.1-1.8-4.1-2.9-6.1-3.4 M234.8,403.4
	c2.9-1.1,5.6-2.6,8.3-4.5 M245.1,399.6c0.6-1.5,1.1-2.8,1.4-3.9 M252.4,378.1c-1,5.1-3,9.6-6.6,13.6 M231.2,344c-2.5-0.7-5-1-7.7-1
	 M305.8,373.9h-5.5 M258.2,372.6l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4l-0.3-0.1 M269.9,390.5l15-0.3 M284.9,390.2
	v-16.1 M300.3,373.9l-15.4,0.3 M256,375.9c0.7-0.8,1.4-1.9,2.1-3.3 M257.8,371.5l-1.6-0.1 M223.3,339.9l-0.3-1.6 M221.9,337.8
	c-1.4,0.8-2.5,1.6-3.3,2.3 M247.1,297.9c5.4,1.6,10.5,4,15.4,6.9 M245.1,262.4l48.1,2.5 M293.3,264.9c-18,9.8-33.4,20.8-46.2,33
	 M439.9,281.8c-0.3-0.5-0.8-1.2-1.4-1.6c-1.4-1.4-3-2.1-5-2.1h-3.7c-1.9,0-3.6,0.7-5.1,2.1c-0.8,0.8-1.4,1.6-1.6,2.6 M423,282.8
	h-0.5c-1.6,0-3,0.3-4.1,0.7s-1.6,1-1.6,1.5s0.5,1.1,1.6,1.5s2.5,0.7,4.1,0.7h0.5"
          />
          <path
            fill="none"
            d="M423,287.2c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7c1.9,0,3.6-0.7,5-2.1c1-1,1.6-2.1,1.9-3.2 M423,287.2
	l17.5-0.5 M439.9,281.8l-16.9,1 M351.1,264.4l-1.2,2.1c-1.6,2.2-3.7,3.3-6.2,3.4 M327.1,260.5c0.1,0.7,0.5,1.5,1,2.2
	c0.5,1,1.2,1.8,2.2,2.6l2.1,1.5c1.8,1.1,3.6,1.9,5.8,2.5l2.8,0.4l1.2,0.1 M343.6,269.9H342"
          />
          <path
            fill="none"
            d="M324.6,266.8l-0.5,1.2l-3.6,4.7c-1.8,2.6-1,4.8,2.3,6.6c5.4,1.6,10.6,1.6,15.7,0c3.9-1.6,5.5-4.8,5-9.6
	 M325.2,265.5l-0.5,1.4 M327,256.2l-6.5,6.7l5.8-0.3 M326.3,262.7l-1.1,2.8 M342,269.9c-10.9,1.8-16.6,0.8-17.2-3 M373.1,200.4
	L302.9,263 M352.2,261.6c-0.3,1-0.5,1.9-1,2.8 M327.1,260.5l-0.8,2.2 M196.3,354.2c-1.8,2.6-3.2,5.4-4.1,8.4 M179.5,275.1
	c15-2.6,29.6-4.3,43.6-5.1 M454.2,371.1l-148.4,2.8 M460.7,259.8l-109.6,4.5 M446.3,257.7l15-0.7 M462.1,200.2l-15.8,57.5
	 M384.4,200.4c27.1-1.8,56.5-2.8,88.3-3 M284.9,390.2l217.2-1.6"
          />
          <path
            fill="none"
            d="M622.6,191.4c-0.1,0.3-0.3,0.4-0.5,0.6l-0.7,0.1l-0.7-0.3c-2.9-0.7-6.1-1.2-9.6-1.8c-16.5-1.9-31.4-3.2-45-3.7
	c-31.5-1.6-64.2-2.1-98.1-1.1c-21.5,0.3-39.3,1.1-53.8,2.3c-10.5,1-19.1,2.2-26,3.6l-0.7,0.3l-0.7-0.1c-0.3-0.1-0.4-0.3-0.5-0.6
	c-0.1-0.3,0-0.6,0.1-0.7c0.1-0.3,0.3-0.4,0.5-0.6c2.3-0.7,5.1-1.4,8.1-1.9c2.8-0.6,5.8-1,8.9-1.4c10.3-1.6,24.6-2.9,42.8-3.9
	c-19,0.4-36.2,1.2-51.6,2.6l-18.7,1.9c-15.7,2.6-27.5,7-35.4,13.5h43.1c27.1-1.8,56.5-2.8,88.3-3c27-0.1,55.6,0.1,85.8,1
	c14.6,0,24.1,2.6,28.3,7.8l58.6-2.5l-0.1-0.1l0.1,0.1l11.8-0.6c-0.8-2.6-3.4-4.5-7.8-5.5c-6.3-2.1-9.5-4.7-9.4-7.7
	c-6.3-1-13.2-1.9-20.5-2.8l-19.7-1.6l-7.4-0.6c7.6,0.8,14.7,1.9,21.5,3.3c3,0.6,5.8,1.2,8.1,1.9c0.3,0.1,0.4,0.3,0.5,0.6
	C622.6,191,622.8,191.2,622.6,191.4L622.6,191.4z"
          />
          <path
            fill="none"
            d="M622.1,192c0.3-0.1,0.4-0.3,0.5-0.6c0.1-0.3,0-0.6-0.1-0.7c-0.1-0.3-0.3-0.4-0.5-0.6c-2.3-0.7-5.1-1.4-8.1-1.9
	c-6.7-1.4-13.9-2.5-21.5-3.3l-16-1.1c-23.9-1.4-51.5-2.1-82.4-2.2c-9.4,0-18.6,0-27.2,0.1l-19.7,0.6c-18.3,1-32.5,2.2-42.8,3.9
	c-3.3,0.4-6.3,1-8.9,1.4c-3,0.6-5.8,1.2-8.1,1.9c-0.3,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.1,0.6-0.1,0.7c0.1,0.3,0.3,0.4,0.5,0.6
	l0.7,0.1l0.7-0.3c6.9-1.5,15.5-2.8,26-3.6c14.4-1.4,32.5-2.1,53.8-2.3c33.8-1,66.6-0.6,98.1,1.1c13.5,0.6,28.5,1.6,45,3.7
	c3.6,0.6,6.7,1.1,9.6,1.8l0.7,0.3L622.1,192L622.1,192z M586.7,211.9l2.8,12.1l-3.9,27.9l12.9-0.6l-9.2-40.2
	c-0.4-1.8-1.2-3.4-2.3-4.9c-4.3-5.2-13.8-8-28.3-7.8c-30.3-0.8-58.9-1.2-85.8-1l-0.5,2.8h10.3c24.1,0,49.4,0.3,76.1,1.1
	C575.4,201.2,584.8,204.8,586.7,211.9L586.7,211.9z"
          />
          <path
            fill="none"
            d="M587,206.3c1.2,1.4,1.9,3,2.3,5l9.2,40.2l73.7-3l-4.1-7l-3.6-1.1l-15.8-27.5l1.5-2.2l-4.8-6.7L587,206.3
	L587,206.3z M598,254.2l-137.4,5.6c-3.6,10.3-5.9,22.4-6.7,36.5c-2.2,24.6-1.9,49.7,0.4,74.7l90.5-1.7c0.8-10.9,3.3-21.3,7.6-31.5
	c3.7-8.3,9.4-15.8,16.6-22.7c10.3-9.5,22-15.7,35.1-18.7l-0.4-26l-4.4-16.2L598,254.2L598,254.2L598,254.2z M564.6,281.1
	c0-0.5,0.5-1.1,1.7-1.5c1.1-0.4,2.5-0.7,4.1-0.7h0.5c0.3-1,1-1.8,1.7-2.6c1.4-1.4,3-2.1,5.1-2.1h3.7c1.9,0,3.6,0.7,5,2.1
	c0.5,0.5,1,1.1,1.4,1.6l1.8-0.1c0.5-0.7,1.1-1,1.9-1h0.1c2.1,0,3.9,0.4,5.4,1c1.7,0.7,2.5,1.4,2.5,2.3s-0.8,1.6-2.5,2.3
	c-1.5,0.5-3.3,1-5.4,1h-0.1c-0.7,0-1.2-0.3-1.8-0.8h-1.2c-0.3,1.2-1,2.3-1.9,3.2c-1.4,1.4-3,2.1-5,2.1h-3.7c-1.9,0-3.6-0.7-5.1-2.1
	c-0.8-0.8-1.4-1.6-1.7-2.6h-0.5c-1.7,0-3-0.3-4.1-0.7C565.1,282.2,564.6,281.7,564.6,281.1L564.6,281.1z"
          />
          <path
            fill="none"
            d="M566.2,279.5c-1.1,0.4-1.7,1-1.7,1.5s0.5,1.1,1.7,1.5c1.1,0.4,2.5,0.7,4.1,0.7h0.5l17.3-0.5h1.2
	c0.5,0.5,1.1,0.8,1.8,0.8h0.1c2.1,0,3.9-0.4,5.4-1c1.7-0.7,2.5-1.4,2.5-2.3s-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1
	c-0.7,0-1.4,0.3-1.9,1l-1.8,0.1l-16.8,0.8h-0.5C568.7,278.9,567.3,279.1,566.2,279.5L566.2,279.5z"
          />
          <path
            fill="none"
            d="M572.7,276.3c-0.8,0.8-1.4,1.6-1.7,2.6l16.8-0.8c-0.3-0.5-0.8-1.2-1.4-1.6c-1.4-1.4-3-2.1-5-2.1h-3.7
	C575.7,274.3,574.1,274.9,572.7,276.3L572.7,276.3z M570.9,283.3c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7
	c1.9,0,3.6-0.7,5-2.1c1-1,1.7-2.1,1.9-3.2L570.9,283.3L570.9,283.3z M598.5,251.4l0.7,2.8l92.7-3.9l-3.6-2.6l-16.1,0.7 M681.8,197.9
	c0.8-0.7,1-1.5,0.3-2.5l-0.1-0.1c-1-1.4-3.4-2.3-7.4-2.9l-34.4-2.2c0,3,3,5.5,9.4,7.7c4.4,1,7,2.8,7.8,5.5l8.7-0.4
	C673.9,202.3,679.2,200.7,681.8,197.9L681.8,197.9z M666.1,203l-8.7,0.4c10,11.8,20.4,26.6,31,44.3l14-0.6
	C689.7,229.4,677.6,214.7,666.1,203L666.1,203z"
          />
          <path
            fill="none"
            d="M650.4,210.6l-1.5,2.2l15.8,27.5l3.6,1.1l4.1,7l16.1-0.7c-10.6-17.7-20.9-32.6-31-44.3l-11.8,0.6L650.4,210.6
	L650.4,210.6z"
          />
          <path
            fill="none"
            d="M688.4,247.7l3.6,2.6l10.7-0.4c0.4,0,0.7-0.1,1-0.4l0.1-0.1c0.1-0.3,0.3-0.6,0.3-0.8c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.7-0.4-1-0.4L688.4,247.7L688.4,247.7z M694.1,261.3h4.8c2.2,0.1,3.9,0.8,5.1,2.2l-0.7-1.4c-1.4-1.5-3.4-2.3-6.2-2.5
	h-5.9c-3.3,0-5.4,1.8-6.1,5.2c-0.7,4.8-0.5,11.8,0.4,20.8c0.1,10.2,1.9,17.5,5.2,21.6l0.3,0.4c1.5,1.4,4,2.1,7.7,2.3
	c4,0.1,6.9-0.7,8.4-2.8c0.6-0.7,1-1.5,1.2-2.3l0.1-0.8c0.3-2.9,0.4-6.7,0.1-11.6c0.1,4.1,0,7.3-0.3,9.6c-0.8,3.3-3.6,4.8-8,4.5
	c-3-0.3-5.1-1-6.3-2.1c-2.9-3.6-4.4-10.2-4.5-19.8c-0.7-8.1-0.8-14.3-0.3-18.7C689.7,263,691.5,261.5,694.1,261.3L694.1,261.3z"
          />
          <path
            fill="none"
            d="M699.1,261.3h-4.8c-2.8,0-4.4,1.6-5.1,4.8c-0.5,4.4-0.4,10.6,0.3,18.7c0.1,9.5,1.7,16.1,4.5,19.8
	c1.2,1.2,3.3,1.9,6.3,2.1c4.4,0.3,7-1.4,8-4.5c0.3-2.3,0.4-5.6,0.3-9.6l-0.3-5.1c-0.8-11.1-1.9-18.7-3.4-22.7l-0.8-1.2
	C703,262.1,701.3,261.5,699.1,261.3L699.1,261.3z"
          />
          <path
            fill="none"
            d="M700.1,257.1c-2.3-2.9-5-5.1-8.1-6.7l-92.7,3.9l4.4,16.2l0.4,26c6.2-1.4,12.7-2.2,19.4-2.2
	c21.3,0,39.5,7,54.5,20.9l0.1,0.1h30.4V304l-0.1,0.8c-0.3,1-0.7,1.7-1.2,2.3c-1.7,2.1-4.4,2.9-8.4,2.8c-3.6-0.3-6.2-1.1-7.7-2.3
	l-0.3-0.4c-3.3-4.1-5-11.3-5.2-21.6c-1-8.9-1.1-16-0.4-20.8c0.8-3.4,2.8-5.2,6.1-5.2h5.9c2.6,0.1,4.7,1,6.2,2.5
	c-0.7-1.2-1.4-2.3-2.2-3.4L700.1,257.1L700.1,257.1z M639.3,266.7c2.5,2.5,3.8,5.6,3.8,9.2s-1.2,6.6-3.8,9.1
	c-2.5,2.5-5.5,3.9-9.1,3.9s-6.6-1.2-9.2-3.9c-2.5-2.5-3.7-5.5-3.7-9.1s1.2-6.6,3.7-9.2c2.5-2.5,5.6-3.7,9.2-3.7
	S636.8,264.2,639.3,266.7L639.3,266.7z"
          />
          <path
            fill="none"
            d="M643.1,275.9c0-3.6-1.2-6.6-3.8-9.2c-2.5-2.5-5.5-3.7-9.1-3.7s-6.6,1.2-9.2,3.7c-2.5,2.5-3.7,5.6-3.7,9.2
	s1.2,6.6,3.7,9.1s5.6,3.9,9.2,3.9s6.6-1.2,9.1-3.9C641.9,282.5,643.1,279.5,643.1,275.9L643.1,275.9z M691.9,250.3
	c3,1.6,5.8,4,8.1,6.7l1.2,1.5c0.8,1.1,1.5,2.2,2.2,3.4c0.7,0.7,1.1,1.5,1.5,2.5v0.1c1.5,4,2.6,11.6,3.4,22.7l0.3,5.1
	c0.3,4.8,0.3,8.7-0.1,11.6v11.3l10.7,8.7c5.5-23,5.5-42.8-0.3-59c-0.5-1.1-1.1-2.1-1.8-3l-2.2-3.2c-3.2-4.3-7-7.4-11.4-9.5l-0.1,0.1
	c-0.3,0.3-0.5,0.4-1,0.4L691.9,250.3L691.9,250.3z M569.1,315.2c-7.4,6.9-12.9,14.4-16.6,22.7c-4.3,10.2-6.7,20.6-7.6,31.5l18.4-0.4
	l-18.4,0.4l-90.5,1.6l-148.4,2.8l-5.5,0.1l-15.4,0.3v16.1l217.2-1.7l61.1-0.4c-0.1-4.7,0-9.6,0.1-14.9c0-1.4,0.1-2.8,0.3-4
	c0.3-2.9,0.7-5.9,1.4-8.7c0.6-2.8,1.4-5.4,2.3-8c0.8-2.3,1.8-4.7,2.9-6.9c2.8-5.5,6.3-10.6,10.9-15.4c7-7.4,14.7-12.7,23.4-15.8
	l-0.3-18C591,299.4,579.3,305.8,569.1,315.2L569.1,315.2z"
          />
          <path
            fill="none"
            d="M570.2,345.5c-1.1,2.2-2.1,4.5-2.9,6.9c-1,2.6-1.7,5.2-2.3,8s-1.1,5.6-1.4,8.7c-0.1,1.4-0.3,2.8-0.3,4
	c-0.3,5.4-0.3,10.3-0.1,14.9v1.2l0.7-0.1c0.5,0,1.1,0.3,1.7,0.7c0.4,0.4,0.7,1,0.7,1.6v1.9l11.4-0.1c-2.8-6.3-4.3-13.2-4.3-20.6
	c0-12.4,3.9-23.2,11.7-32.3c1-1.1,2.1-2.3,3-3.3c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1s20.6,3,29.2,9.1
	c2.3,1.6,4.5,3.6,6.6,5.6c2.6,2.6,4.8,5.2,6.6,8c5.5,8.1,8.1,17.3,8.1,27.6c0,5.2-0.7,10.2-2.1,14.7l12.5-0.8v-12.5
	c-0.3-11.7-3-22.1-8.5-31.5l-0.5-0.8c-2.5-4.1-5.4-8-8.8-11.6c-3-3.3-6.3-6.1-9.6-8.5c-9.8-7-20.8-10.5-33.3-10.5
	c-6.9,0-13.5,1.1-19.5,3.3c-8.7,3-16.5,8.4-23.4,15.8C576.5,334.9,573,340,570.2,345.5L570.2,345.5z"
          />
          <path
            fill="none"
            d="M563.2,389.4v-1.2l-61.1,0.4c1.8,2.1,5.5,3,11,3.3l48.4-0.1v-0.1c0-0.5,0.3-1.1,0.7-1.6
	C562.5,389.7,562.8,389.4,563.2,389.4L563.2,389.4z"
          />
          <path
            fill="none"
            d="M566.1,391.5c0-0.5-0.3-1.1-0.7-1.6c-0.4-0.4-1-0.7-1.7-0.7l-0.7,0.1c-0.3,0.1-0.7,0.3-1,0.5
	c-0.4,0.4-0.7,1-0.7,1.6v5.6c0,0.5,0.3,1.1,0.7,1.6c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.7-0.7c0.4-0.4,0.7-1,0.7-1.6L566.1,391.5
	L566.1,391.5z M454.2,371.1c-2.3-25.2-2.5-50.1-0.4-74.7c1-13.9,3.2-26.1,6.7-36.5l-109.5,4.5l-1.2,2.1c-1.6,2.2-3.7,3.3-6.2,3.4
	c0.7,4.7-1,8-5,9.6c-5.1,1.6-10.3,1.6-15.7,0c-3.3-1.8-4.1-4-2.3-6.6l3.6-4.7l0.5-1.2l0.5-1.4l-11.1,0.4h-0.5
	c-8.7,4.4-13.1,20.8-13.1,49.1v0.3l0.1,1c1.1,23.5,2.8,42.6,5.4,57.6L454.2,371.1L454.2,371.1z M451.5,284.2c0,1-0.8,1.6-2.5,2.3
	c-1.5,0.5-3.3,1-5.4,1h-0.1c-0.7,0-1.2-0.3-1.8-0.8h-1.2c-0.3,1.2-1,2.3-1.9,3.2c-1.4,1.4-3,2.1-5,2.1h-3.7c-1.9,0-3.6-0.7-5.1-2.1
	c-0.8-0.8-1.4-1.6-1.6-2.6h-0.5c-1.6,0-3-0.3-4.1-0.7s-1.7-1-1.7-1.5s0.5-1.1,1.6-1.5s2.5-0.7,4.1-0.7h0.5c0.3-1,1-1.8,1.6-2.6
	c1.4-1.4,3-2.1,5.1-2.1h3.7c1.9,0,3.6,0.7,5,2.1c0.5,0.5,1,1.1,1.4,1.6l1.8-0.1c0.5-0.7,1.1-1,1.9-1h0.1c2.1,0,3.9,0.4,5.4,1
	C450.7,282.4,451.5,283.2,451.5,284.2L451.5,284.2z"
          />
          <path
            fill="none"
            d="M449,286.5c1.6-0.7,2.5-1.4,2.5-2.3c0-1-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1c-0.7,0-1.4,0.3-1.9,1
	l-1.8,0.1l-16.8,0.8h-0.5c-1.6,0-3,0.3-4.1,0.7s-1.6,1-1.6,1.5s0.5,1.1,1.6,1.5s2.5,0.7,4.1,0.7h0.5l17.3-0.5h1.2
	c0.5,0.5,1.1,0.8,1.8,0.8h0.1C445.7,287.3,447.5,287.1,449,286.5L449,286.5z"
          />
          <path
            fill="none"
            d="M438.6,280.2c-1.4-1.4-3-2.1-5-2.1h-3.7c-1.9,0-3.6,0.7-5.1,2.1c-0.8,0.8-1.4,1.6-1.6,2.6l16.8-0.8
	C439.5,281.3,439.1,280.7,438.6,280.2L438.6,280.2z M423,287.2c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7c1.9,0,3.6-0.7,5-2.1
	c1-1,1.6-2.1,1.9-3.2L423,287.2L423,287.2z M657.1,321.6c3.4,2.5,6.6,5.2,9.6,8.5c3.4,3.7,6.3,7.6,8.8,11.6h20.8
	c-1.8-4.5-4-8.8-6.6-12.8c-2.6-4-5.8-7.8-9.5-11.4l-2.2-2.1l-0.1-0.1c-15-13.9-33.2-20.9-54.5-20.9c-6.7,0-13.3,0.7-19.4,2.2l0.3,18
	c6.1-2.2,12.7-3.3,19.5-3.3C636.4,311.1,647.5,314.6,657.1,321.6L657.1,321.6z"
          />
          <path
            fill="none"
            d="M659.3,336.8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1
	c-2.3,1.6-4.5,3.6-6.6,5.6c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3c0,7.4,1.4,14.3,4.3,20.6c1.9,4.1,4.4,8.1,7.4,11.7
	c1,1.1,2.1,2.2,3,3.3c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12
	c2.6-2.6,4.8-5.2,6.6-8c2.8-4,4.7-8.4,6.1-12.9c1.4-4.7,2.1-9.5,2.1-14.7c0-10.3-2.8-19.5-8.1-27.6
	C664.2,342.1,662,339.5,659.3,336.8L659.3,336.8z M610.9,333.1h0.5H610.9z M588.2,372.6c0-10,3.6-18.6,10.7-25.7
	c7.2-7.2,15.7-10.6,25.7-10.6c10,0,18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7c0,10-3.6,18.6-10.7,25.7
	c-7.2,7.2-15.7,10.6-25.7,10.6c-10,0-18.6-3.6-25.7-10.6C591.8,391.2,588.2,382.7,588.2,372.6L588.2,372.6z"
          />
          <path
            fill="none"
            d="M599,346.9c-7.2,7.2-10.7,15.7-10.7,25.7c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6
	c10,0,18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.6-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6
	C614.6,336.2,606.1,339.7,599,346.9z M623.3,337.7l0.5-0.1l0.4,0.3l0.1,0.3v0.1l0.3,1.6h0.3c3.2,0,6.2,0.4,9.1,1.2l0.5-1.5l0.1-0.3
	c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.3l0.1,0.1c1.1,1.1,1.9,2.1,2.5,3c3.6,1.7,6.9,3.9,9.9,6.9c2.6,2.6,4.7,5.5,6.2,8.5
	c1,0.5,1.9,1.4,3.2,2.3h0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1h-0.1L656,362c1,2.9,1.5,5.9,1.7,9.2l1.7,0.1
	l0.3,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1c-0.7,1.4-1.5,2.5-2.1,3.3c-0.7,7.6-3.8,14.2-9.5,19.8l-0.1,0.1
	c-0.3,1.1-0.7,2.3-1.4,3.9v0.3l-0.3,0.4h-0.4l-0.3-0.1V400h-0.1l-1.1-1.1c-2.6,1.9-5.2,3.4-8.3,4.5l0.3,1.5v0.3l-0.3,0.3l-0.4,0.1
	c-0.1,0-0.1-0.1-0.3-0.1c-1.5-0.3-2.6-0.5-3.7-1c-2.3,0.4-4.7,0.7-7,0.7c-5.6,0-10.7-1.2-15.3-3.7c-1,0-2.2,0-3.7-0.1h-0.3
	c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-0.4l0.7-1.4c-1.5-1.1-2.9-2.2-4.1-3.6c-1-1-1.8-1.9-2.6-2.9l-1.4,0.8h-0.3
	c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.3-0.3-0.4v-0.3c-0.1-1.4-0.3-2.6-0.3-3.6c-3-5-4.4-10.5-4.4-16.6c0-1.9,0.1-3.7,0.4-5.5
	c-0.4-1-0.8-2.2-1.1-3.7L591,363v-0.4c0.1-0.1,0.1-0.3,0.3-0.3h0.4l1.5,0.3c1-3,2.3-5.8,4.1-8.4l-1.1-1l-0.1-0.3v-0.4
	c0-0.1,0.1-0.3,0.3-0.3h0.3c1.4-0.7,2.6-1.1,3.6-1.5l1.2-1.2c5.2-5.2,11.3-8.3,18.3-9.2c0.8-0.7,1.9-1.5,3.3-2.3L623.3,337.7
	L623.3,337.7z"
          />
          <path
            fill="none"
            d="M623.7,337.5l-0.5,0.1l-0.1,0.1l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2l0.3,0.5
	c0.4,0.7,1,1.2,1.7,1.6l0.4,0.1c0.8,0.4,1.7,0.4,2.5,0.3c1-0.1,1.8-0.5,2.5-1.1l0.4-0.4c0.5-0.8,1.5-3,2.6-6.7c1-3,1.8-7.8,2.6-14.6
	l0.5-5.1v-0.3l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0-0.4,0.1l-0.1,0.3v0.1l-0.5,4.8c-0.8,6.7-1.8,11.7-2.6,14.6
	c-1.1,3.6-1.9,5.6-2.5,6.3c-0.4,0.5-1.1,1-1.8,1.1l-0.4,0.1c-0.6,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.6-3.2-0.7-6.7
	c-0.1-3,0.3-8,1.2-14.7l0.7-4.8v-0.1l-0.1-0.3L623.7,337.5L623.7,337.5z"
          />
          <path
            fill="none"
            d="M623,338.1l0.1-0.3c-1.4,0.8-2.5,1.6-3.3,2.3c-1.4,1.1-1.9,2.1-1.9,2.9v0.8c-0.1,5.6,0.3,10.6,1.1,14.7
	c0.5,2.6,1.5,4.8,2.9,6.5c-0.4-1-0.7-3.3-0.8-7.2c-0.1-3,0.3-8,1.2-14.6L623,338.1L623,338.1z"
          />
          <path
            fill="none"
            d="M624.3,338.2l-0.7,5c-1,6.7-1.4,11.6-1.2,14.7c0.1,3.7,0.3,5.9,0.7,6.7c0.3,0.8,1,1.4,1.8,1.8
	c0.5,0.3,1.2,0.4,1.8,0.3l0.4-0.1c0.7-0.1,1.4-0.6,1.8-1.1c0.5-0.7,1.4-2.9,2.5-6.3c1-3,1.8-7.8,2.6-14.6l0.5-4.8v-0.1l-0.5,1.5
	l-1.1,2.6v0.4l-1.7,11.6c-0.5,2.2-1.4,3.2-2.6,2.9l-3.2-0.6c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4v-0.5l-0.4-2.6L624.3,338.2L624.3,338.2
	z M627.6,361.1c0.5,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.5,1.9c-0.4,0.6-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4c-0.5-0.4-1-1-1.1-1.8
	c-0.1-0.7,0-1.4,0.4-1.9c0.4-0.5,1-1,1.8-1.1C626.3,360.5,627,360.6,627.6,361.1L627.6,361.1z"
          />
          <path
            fill="none"
            d="M628.7,362.8c-0.1-0.7-0.5-1.4-1.1-1.8c-0.5-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1
	c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.5,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1
	C628.5,364.2,628.8,363.5,628.7,362.8L628.7,362.8z M627.2,361.7c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.5,0,1-0.4,1.5
	c-0.3,0.4-0.7,0.7-1.2,0.7c-0.5,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8
	C626.2,361.3,626.7,361.5,627.2,361.7L627.2,361.7z"
          />
          <path
            fill="none"
            d="M627.8,363c-0.1-0.5-0.3-1-0.8-1.2c-0.4-0.3-0.8-0.4-1.4-0.4c-0.5,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4
	c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3c0.5-0.1,1-0.3,1.2-0.7S628,363.5,627.8,363L627.8,363z M635.4,339.6v0.3l-0.5,5.1
	c-0.8,6.7-1.7,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7c1.7-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14l0.1-0.8
	c0.3-0.8-0.1-1.9-1.2-3.4C637.3,341.7,636.5,340.7,635.4,339.6L635.4,339.6z M632.5,344v-0.4l1.1-2.6c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3
	l0.4,2.6v0.5C627.4,343,630,343.4,632.5,344L632.5,344z"
          />
          <path
            fill="none"
            d="M625.1,358l3.2,0.6c1.1,0.3,2.1-0.7,2.6-2.9l1.7-11.6c-2.5-0.7-5-1-7.7-1l-1.4,11.4
	C623.3,356.8,624,358,625.1,358L625.1,358z M617.8,343.9V343c0-0.8,0.7-1.8,1.9-2.9c-7,1-13.1,4-18.3,9.2l-1.2,1.2
	c1.8-0.5,2.9-0.5,3.6-0.1l0.7,0.4C608.4,347.3,612.8,345,617.8,343.9L617.8,343.9z"
          />
          <path
            fill="none"
            d="M618.9,358.6c-0.8-4.1-1.2-9.1-1.1-14.7c-5,1.1-9.4,3.4-13.3,7.2c4.8,3,8.8,6.1,11.8,8.9c1.9,1.9,3.2,4,3.9,5.9
	l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.5,1.6-1.1,2.3l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1c-1.9,0.7-4.3,1-7,0.8
	c-4.3-0.5-9.1-1.6-14.4-3.6l-0.1,2.3c0,4.5,1,8.8,2.8,12.5c4.4-3.7,8.5-6.6,12.2-8.5c2.5-1.2,4.7-1.8,6.9-1.8l0.7-0.1
	c0.8-0.1,1.8,0.1,2.6,0.6c0.8,0.4,1.4,1,1.8,1.8l0.1,0.4c0.4,0.8,0.6,1.5,0.4,2.3l-0.1,0.7c0.1,2.1-0.4,4.4-1.5,6.9
	c-1.8,3.9-4.4,8.1-7.8,12.7c3.4,1.4,7.3,2.2,11.3,2.2l3.7-0.1c-2.2-5.2-3.6-9.9-4.3-14.2c-0.4-2.8-0.3-5.1,0.4-7v-0.5
	c0.1-0.8,0.7-1.6,1.4-2.3c0.5-0.5,1.4-1,2.2-1.2h0.4c0.8-0.1,1.7,0,2.3,0.3c0.3,0,0.4,0.1,0.5,0.3c2.1,0.5,4,1.8,6.1,3.4
	c3,2.9,6.3,6.6,9.5,11.3c3.4-4,5.6-8.5,6.6-13.6c-5.6,0.4-10.6,0.3-14.9-0.3c-2.6-0.5-4.8-1.4-6.6-2.6l-0.5-0.3
	c-0.8-0.4-1.4-1.1-1.8-2.1c-0.4-0.7-0.5-1.5-0.4-2.5v-0.4c0.1-0.8,0.5-1.5,1-2.1l0.4-0.4c1.2-1.8,2.9-3.3,5.2-4.7
	c3.7-2.1,8.3-3.9,13.8-5.6c-1.2-1.9-2.6-3.7-4.4-5.4c-2.1-2.1-4.1-3.7-6.5-5c-1.4,5.5-3,10.2-4.8,14c-1.2,2.3-2.8,4.1-4.4,5.5
	l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.7,0-2.5-0.3l-0.4-0.1c-0.7-0.4-1.4-1-1.7-1.7V365C620.6,363.4,619.6,361.2,618.9,358.6
	L618.9,358.6z M627.3,370c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1c-1.1,0-1.9-0.4-2.6-1.1
	c-0.7-0.7-1.1-1.5-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.5-1.1,2.6-1.1C625.6,368.9,626.5,369.3,627.3,370L627.3,370z"
          />
          <path
            fill="none"
            d="M628.3,372.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.5-1.1-2.6-1.1c-1.1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
	s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1C628,374.4,628.3,373.6,628.3,372.6L628.3,372.6z M614.5,361.3
	c-2.5-1.8-6.9-4.1-13.1-7l-4.5-2.2h-0.5c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.3h0.1l4.4,1.9c6.2,2.9,10.6,5.2,13.1,7
	c3,2.1,4.8,3.6,5.4,4.3c0.4,0.5,0.5,1.2,0.5,2.1v0.4c-0.1,0.5-0.4,1.1-0.8,1.6c-0.5,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4
	c-2.9-0.8-7.6-2.8-13.6-5.8l-4.3-2.2h-0.4c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1l0.1,0.1l4.5,2.2
	c6.2,3,10.7,5,13.6,5.8c3.7,1.1,6.2,1.5,7.2,1.5l0.7-0.1c0.7-0.1,1.4-0.5,2.1-1.1l0.3-0.3c0.5-0.7,1-1.4,1.1-2.3
	c0.1-1,0.1-1.9-0.3-2.8l-0.3-0.5C619.6,365,617.8,363.5,614.5,361.3L614.5,361.3z"
          />
          <path
            fill="none"
            d="M614.5,361.3c3.2,2.2,5.1,3.7,5.6,4.5c-0.7-2.1-1.9-4-3.9-5.9c-3-2.9-6.9-5.9-11.8-8.9l-0.7-0.4
	c-0.7-0.5-1.8-0.4-3.6,0.1c-1,0.3-2.2,0.8-3.6,1.5h0.3l4.5,2.2C607.8,357.2,612,359.5,614.5,361.3L614.5,361.3z"
          />
          <path
            fill="none"
            d="M612.6,364.8l-1.5,2.9c-0.4,1.1-1.7,1.4-3.9,0.5l-10.6-5l-0.4-0.3l-2.8-0.4l-1.5-0.3l4.3,2.2
	c6.2,3,10.7,5,13.6,5.8c3.4,1.1,5.8,1.5,6.6,1.4c0.8-0.1,1.7-0.4,2.2-1.1c0.4-0.5,0.7-1,0.8-1.6v-0.4c0-0.8-0.1-1.4-0.5-2.1
	c-0.5-0.7-2.3-2.2-5.4-4.3c-2.5-1.8-6.9-4.1-13.1-7l-4.4-1.9h-0.1l1.1,1l2.2,1.9l0.4,0.1l10.5,5.1
	C612.4,362.7,613.1,363.8,612.6,364.8L612.6,364.8z M614.8,366.3c0.5-0.4,1.2-0.5,1.9-0.5c0.7,0.1,1.2,0.4,1.7,1.1
	c0.4,0.5,0.5,1.2,0.5,1.9c-0.1,0.7-0.4,1.4-1.1,1.8c-0.5,0.4-1.2,0.5-1.9,0.4c-0.7-0.1-1.4-0.4-1.8-1.1c-0.4-0.5-0.5-1.2-0.4-1.9
	S614.2,366.7,614.8,366.3L614.8,366.3z"
          />
          <path
            fill="none"
            d="M616.7,365.9c-0.7-0.1-1.4,0.1-1.9,0.5c-0.5,0.4-1,1-1.1,1.8c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1
	c0.7,0.1,1.4,0,1.9-0.4s1-1,1.1-1.8c0.1-0.7,0-1.4-0.5-1.9C618.1,366.3,617.5,365.9,616.7,365.9L616.7,365.9z M614.4,368.1
	c0.1-0.5,0.4-1,0.8-1.2c0.4-0.3,1-0.4,1.4-0.4c0.5,0.1,1,0.4,1.2,0.8s0.4,0.8,0.3,1.4c-0.1,0.5-0.3,1-0.8,1.2
	c-0.4,0.3-0.8,0.4-1.4,0.3s-1-0.3-1.2-0.7C614.5,369.2,614.4,368.6,614.4,368.1L614.4,368.1z"
          />
          <path
            fill="none"
            d="M615.2,366.8c-0.4,0.3-0.7,0.7-0.8,1.2c-0.1,0.6,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.5,0.1,1,0,1.4-0.3
	c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8C616.1,366.4,615.6,366.6,615.2,366.8L615.2,366.8z
	 M611.1,367.7l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1c-1.5,2.2-2.6,4.5-3.4,7l10.6,5C609.4,369,610.6,368.8,611.1,367.7L611.1,367.7
	z M617.7,374.7l-0.7,0.1c-1,0.3-3,1.5-6.1,3.9c-2.5,1.8-6.1,5.2-10.7,10.3l-3.3,3.7l-0.1,0.1v0.3c0,0.1,0.1,0.3,0.3,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h0.3l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3
	c0.5,0.3,1,0.7,1.2,1.2c0.4,0.8,0.5,1.6,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-5,6.3-9.6,11.1l-3.3,3.4v0.4c0,0.1,0,0.3,0.1,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h0.3l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1c2.2-3.2,3.4-5.4,3.6-6.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3
	l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8C619.5,374.7,618.5,374.5,617.7,374.7L617.7,374.7z"
          />
          <path
            fill="none"
            d="M619.9,376.3l-0.4-0.3c-0.7-0.3-1.4-0.3-2.1-0.1c-0.8,0.3-2.8,1.5-5.6,3.7c-2.5,1.8-5.9,5.2-10.7,10.3l-3.3,3.6
	l1.4-0.8l2.5-1.5l0.3-0.3l8.1-8.4c1.8-1.5,3-1.8,3.9-0.8l2.2,2.2c1,0.7,0.7,2.1-0.7,3.9l-8,8.5l-0.4,0.4l-1.2,2.5l-0.7,1.4l3.3-3.4
	c4.7-4.8,8-8.7,9.6-11.1c2.1-3,3.2-5,3.4-5.9c0.1-0.8,0-1.6-0.4-2.5C620.7,377,620.3,376.6,619.9,376.3L619.9,376.3z M615.9,378.1
	c0.3-0.7,0.8-1.1,1.5-1.4s1.4-0.1,2.1,0.1s1.1,0.8,1.4,1.5c0.3,0.7,0.1,1.4-0.1,2.1c-0.4,0.7-0.8,1.1-1.5,1.4
	c-0.7,0.3-1.4,0.1-2.1-0.1s-1.1-0.8-1.4-1.5C615.5,379.4,615.6,378.7,615.9,378.1L615.9,378.1z"
          />
          <path
            fill="none"
            d="M617.4,376.7c-0.7,0.3-1.2,0.7-1.5,1.4s-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1
	c0.7-0.3,1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5S618.1,376.5,617.4,376.7L617.4,376.7z M617.7,377.4
	c0.5-0.1,1-0.1,1.4,0.1c0.5,0.3,0.8,0.5,1,1.1s0.1,1-0.1,1.4c-0.3,0.6-0.5,0.8-1.1,1c-0.5,0.1-1,0.1-1.5-0.1c-0.4-0.3-0.8-0.5-1-1.1
	c-0.1-0.5-0.1-1,0.1-1.5C616.8,378,617.1,377.6,617.7,377.4L617.7,377.4z"
          />
          <path
            fill="none"
            d="M619,377.6c-0.4-0.3-1-0.3-1.4-0.1c-0.5,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5c0.1,0.5,0.5,0.8,1,1.1
	c0.5,0.3,1,0.3,1.5,0.1c0.5-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4C619.9,378.1,619.6,377.8,619,377.6L619,377.6z M617,374.8
	c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5l-0.5,0.4c-0.7,0.5-1,1.8-1,3.7c0,1,0.1,2.2,0.3,3.6l0.1-0.1l3.3-3.7
	c4.7-5.1,8.3-8.5,10.7-10.3C613.9,376.3,616,375.1,617,374.8L617,374.8z M609.7,373.7c2.6,0.3,5,0,7-0.8c-1,0.1-3.3-0.4-7.2-1.5
	c-2.9-0.8-7.4-2.8-13.6-5.8l-4.5-2.2l-0.1-0.1c0.4,1.5,0.7,2.8,1.1,3.7c0.7,1.6,1.4,2.6,2.2,2.9l0.7,0.3
	C600.6,372.1,605.4,373.2,609.7,373.7L609.7,373.7z M637.6,364.5c-3.6,1.4-5.8,2.3-6.6,3l-0.4,0.4c-0.5,0.5-0.8,1.2-1,2.1v0.4
	c-0.1,1,0.1,1.8,0.4,2.5c0.4,1,1.1,1.6,1.8,2.1l0.5,0.3c1,0.4,3.4,0.5,7.3,0.4c3,0,7.8-0.7,14.6-2.1l5.1-1l0.3-0.1l0.1-0.1
	c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.3-0.3-0.4l-0.3-0.1l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4
	c-0.7-0.3-1.2-0.7-1.7-1.4l-0.1-0.4c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2s2.8-1.5,6.2-2.8c2.9-1.1,7.7-2.2,14.4-3.3
	l4.8-0.8h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1-0.1-0.3-0.3-0.4h-0.4l-5.1,0.8C645.3,362.3,640.5,363.4,637.6,364.5
	L637.6,364.5z"
          />
          <path
            fill="none"
            d="M631,367.4c0.8-0.7,3-1.6,6.6-3c3-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3c-1.2-1-2.2-1.8-3.2-2.3
	c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3c-5.5,1.6-10,3.6-13.8,5.6C633.9,364.1,632.1,365.7,631,367.4L631,367.4z M628.1,376.3
	c-0.8,0.1-1.7,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5c0,1,0.5,3.3,1.8,7c1,2.9,3.2,7.4,6.5,13.3l2.5,4.4l0.1,0.1
	c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9
	l0.3-0.3c0.4-0.4,1-0.7,1.7-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4h0.1v0.1l0.3,0.1
	h0.4l0.3-0.4v-0.6l-2.3-4.5c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4c-0.1-0.1-0.4-0.1-0.5-0.3c-0.7-0.3-1.5-0.4-2.3-0.3
	L628.1,376.3L628.1,376.3z"
          />
          <path
            fill="none"
            d="M630.9,372.3l0.1,0.4c0.4,0.7,1,1.1,1.7,1.4c0.8,0.3,3.2,0.4,6.7,0.4c3,0,8-0.7,14.6-2.1l5-1l-1.7-0.1l-2.8-0.3
	l-0.6,0.1l-11.4,1.9c-2.3,0.1-3.6-0.4-3.6-1.5l-0.5-3.2c-0.3-1.1,0.5-2.1,2.8-2.8l11.4-2.2l0.5-0.1l2.5-1.2l1.4-0.7l-4.8,0.8
	c-6.7,1.2-11.6,2.3-14.4,3.3c-3.4,1.2-5.5,2.2-6.2,2.8c-0.5,0.5-1,1.2-1.1,2.2C630.6,371.2,630.7,371.8,630.9,372.3L630.9,372.3z
	 M636.5,371.6c-0.3,0.7-0.7,1.2-1.4,1.5c-0.7,0.3-1.4,0.4-2.1,0.1s-1.2-0.7-1.5-1.4c-0.3-0.7-0.4-1.4-0.1-2.1s0.7-1.2,1.4-1.5
	s1.4-0.4,2.1-0.1s1.2,0.7,1.5,1.4C636.6,370.3,636.6,371,636.5,371.6L636.5,371.6z"
          />
          <path
            fill="none"
            d="M635.1,373.3c0.7-0.3,1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4s-1.4-0.1-2.1,0.1
	s-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1s0.8,1.1,1.5,1.4C633.8,373.6,634.4,373.6,635.1,373.3L635.1,373.3z M635.8,371.5
	c-0.1,0.6-0.4,0.8-1,1.1l-1.5,0.1c-0.5-0.1-0.8-0.4-1.1-1c-0.3-0.4-0.3-1-0.1-1.5c0.1-0.5,0.4-0.8,1-1.1s1-0.3,1.5-0.1
	c0.6,0.1,1,0.5,1.1,1C635.8,370.5,636,371,635.8,371.5L635.8,371.5z"
          />
          <path
            fill="none"
            d="M634.9,372.6c0.4-0.3,0.8-0.7,1-1.1c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.4-0.7-0.8-1.1-1c-0.5-0.1-1-0.1-1.5,0.1
	c-0.4,0.3-0.8,0.5-1,1.1s-0.1,1,0.1,1.5c0.3,0.5,0.6,0.8,1.1,1L634.9,372.6L634.9,372.6z M630.9,378c-0.7-0.4-1.5-0.5-2.5-0.4
	c-0.5,0.1-1.1,0.4-1.7,0.8l-0.3,0.3c-0.5,0.5-0.7,1.2-0.7,1.9c0,0.8,0.5,3,1.8,6.6c1,2.9,3.2,7.3,6.5,13.3l2.3,4.3l-0.3-1.5
	l-0.7-2.9l-0.1-0.4l-5.5-10.3c-0.8-2.2-0.7-3.4,0.4-3.9l2.9-1.5c1-0.7,2.1,0,3.4,1.8l5.6,10l0.3,0.5l1.9,1.9l1.1,1.1l-2.2-4.4
	c-3.2-6.1-5.8-10.3-7.6-12.7C633.1,380,631.6,378.4,630.9,378L630.9,378z M631,378.9c0.5,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.9
	c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9c0.6-0.5,1.1-0.8,1.9-0.8
	C629.9,378.1,630.5,378.4,631,378.9L631,378.9z"
          />
          <path
            fill="none"
            d="M631.8,380.7c0-0.7-0.3-1.4-0.8-1.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.2-0.8,1.9
	s0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8C631.6,382.1,631.8,381.6,631.8,380.7z M631,380.7
	c0,0.5-0.1,1-0.5,1.4c-0.3,0.4-0.8,0.5-1.4,0.5c-0.6,0-1-0.1-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4s0.8-0.5,1.4-0.5
	c0.5,0,1,0.1,1.4,0.5S631,380.2,631,380.7L631,380.7z"
          />
          <path
            fill="none"
            d="M630.5,382.1c0.4-0.4,0.5-0.8,0.5-1.4c0-0.5-0.1-1-0.5-1.4s-0.8-0.5-1.4-0.5c-0.5,0-1,0.1-1.4,0.5
	s-0.5,0.8-0.5,1.4c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5C629.6,382.7,630.2,382.5,630.5,382.1z M600.1,356.4l-0.4-0.1
	l-2.2-1.9c-1.8,2.6-3.2,5.4-4.1,8.4l2.8,0.4l0.4,0.3C597.4,360.8,598.5,358.6,600.1,356.4L600.1,356.4z M595.2,370.1l-0.7-0.3
	c-0.8-0.3-1.5-1.2-2.2-2.9c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6c0-1.9,0.3-3.2,1-3.7l0.5-0.4c-1.8-3.9-2.8-8-2.8-12.5
	L595.2,370.1L595.2,370.1z M603.8,393.4c-0.8-0.8-1.5-1.5-2.2-2.3l-0.3,0.3l-2.5,1.5c0.8,1,1.7,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6
	l1.2-2.5l0.4-0.4C606.1,395.5,604.9,394.5,603.8,393.4L603.8,393.4z"
          />
          <path
            fill="none"
            d="M601.7,391c0.7,0.8,1.4,1.6,2.2,2.3c1.1,1.1,2.2,2.1,3.4,3l8-8.5c1.4-1.9,1.7-3.2,0.7-3.9l-2.2-2.2
	c-0.7-1-2.1-0.7-3.9,0.8L601.7,391L601.7,391z M605.8,401.4l-0.3,0.1c1.5,0.1,2.8,0.1,3.7,0.1c1.9-0.1,3.2-0.6,3.6-1.2l0.4-0.5
	c3.4-4.5,6.1-8.8,7.8-12.7c1.1-2.5,1.5-4.8,1.5-6.9c-0.3,1-1.5,3-3.6,6.3c-1.8,2.6-5,6.3-9.6,11.1L605.8,401.4L605.8,401.4z
	 M636.4,386.4c-1.4-1.9-2.5-2.5-3.4-1.8l-2.9,1.5c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3c2.5-1,4.7-2.2,6.9-3.7L636.4,386.4L636.4,386.4
	z M626.3,387.6c-1.2-3.7-1.9-6.1-1.8-7c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1
	c1,0.3,2.2,0.7,3.7,1l-0.1-0.1l-2.5-4.4C629.5,394.9,627.3,390.5,626.3,387.6L626.3,387.6z"
          />
          <path
            fill="none"
            d="M613,400.4c-0.4,0.7-1.7,1.1-3.6,1.2c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7c-1.8-0.5-2.9-1.2-3-2.1
	l-0.3-0.7l-3.7,0.1c-4.1,0-7.8-0.7-11.3-2.2L613,400.4L613,400.4z M635.1,400.1l0.1,0.4l0.7,2.9c2.9-1.1,5.6-2.6,8.3-4.5l-1.9-1.9
	l-0.3-0.5C639.8,398.1,637.6,399.3,635.1,400.1L635.1,400.1z M631.4,376.9c0.8,0.5,2.5,2.3,4.8,5.4c1.9,2.5,4.4,6.6,7.7,12.5
	l2.3,4.5v0.3c0.5-1.5,1.1-2.8,1.4-3.9c0.4-1.6,0.4-2.9-0.1-3.4l-0.5-0.7c-3.3-4.7-6.5-8.4-9.5-11.3
	C635.5,378.7,633.5,377.4,631.4,376.9L631.4,376.9z M639.1,345.9l-0.1,0.8c2.3,1.4,4.5,3,6.5,5c1.7,1.6,3.2,3.6,4.4,5.4l0.8-0.3
	c0.7-0.3,1.9,0,3.4,1c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9C639,344,639.4,345.2,639.1,345.9L639.1,345.9z M653.3,363.4
	l-0.5,0.1c0.8,2.5,1.2,5,1.4,7.7l0.5-0.1l2.8,0.3c-0.1-3.2-0.7-6.3-1.7-9.2L653.3,363.4L653.3,363.4z"
          />
          <path
            fill="none"
            d="M641.5,365.7c-2.2,0.7-3.2,1.6-2.8,2.8l0.5,3.2c0.1,1.1,1.2,1.6,3.6,1.5l11.4-1.9c-0.1-2.8-0.5-5.2-1.4-7.7
	L641.5,365.7L641.5,365.7z M659.3,372.6l-0.3,0.1l-5.1,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.9,0.1-6.3,0-7.3-0.4c1.8,1.2,4,2.1,6.6,2.6
	c4.1,0.5,9.1,0.7,14.9,0.3h0.8c0.8,0,1.8-0.7,2.9-2.1C658,375.1,658.7,374,659.3,372.6L659.3,372.6z"
          />
          <path
            fill="none"
            d="M654.4,378h-0.8c-1,5.1-3,9.6-6.6,13.6l0.5,0.7c0.5,0.5,0.5,1.8,0.1,3.4l0.1-0.1c5.6-5.6,8.8-12.2,9.5-19.8
	C656.2,377.3,655.2,378,654.4,378L654.4,378z M678.2,315.4l2.2,2.1c3.6,3.6,6.7,7.4,9.5,11.4c2.6,4.1,5,8.4,6.6,12.8
	c1.4,3.7,2.5,7.6,3.3,11.6c1.8,6.1,5.1,9.2,9.8,9.5h12.5c2.3,0,3.7-1.4,4.3-3.9c2.5-5.9,4.8-7.8,6.9-5.9l1.4,5.2
	c1-3.9,1.5-9.8,1.4-17.6c0-2.2,1-3.6,2.9-4.1l-1.1-5.1c-0.4-1.4-1.8-2.1-4-1.9c-6.2,0.1-11.1-1.6-14.6-5.4l-10.7-8.7L678.2,315.4
	L678.2,315.4z"
          />
          <path
            fill="none"
            d="M699.8,353.4c-0.8-4-1.9-7.8-3.3-11.6h-20.8l0.5,0.8c5.4,9.4,8.3,19.8,8.5,31.5v9.1c16.6-1,28.6-3.6,35.8-7.7
	c4.1-2.3,6.7-5.2,7.6-8.7c0.1-0.5,0.3-1.2,0.4-1.9c1.9-5.6,4-7.8,6.3-6.7l-1.4-5.2c-2.2-1.9-4.4,0-6.9,5.9c-0.5,2.5-2.1,3.9-4.3,3.9
	h-12.5C704.9,362.6,701.7,359.4,699.8,353.4L699.8,353.4z"
          />
          <path
            fill="none"
            d="M684.7,383.2v3.4l7.4-0.4l44-8.4c2.6-0.6,4-1.4,3.9-2.3c-0.4-1.5-2.3-1.9-6.2-1.5l-1.4,0.1l-2.9,0.4l-9.2,1.2
	C713.3,379.6,701.3,382.1,684.7,383.2L684.7,383.2z M704.9,264.5c-0.4-1-0.8-1.8-1.5-2.5l0.7,1.4l0.8,1.2V264.5L704.9,264.5z"
          />
          <path
            fill="none"
            d="M736.2,363.8c1.8-1.4,1.2-3.3-1.5-5.5c-2.3-1.1-4.4,1.1-6.3,6.7c0,0.7-0.1,1.2-0.4,1.9c1.2,0.8,1.8,3.3,1.5,7.6
	l2.9-0.4C733.1,368.1,734.3,364.6,736.2,363.8L736.2,363.8z"
          />
          <path
            fill="none"
            d="M720.4,375.5l9.2-1.2c0.1-4.3-0.4-6.7-1.5-7.6C727.2,370.3,724.5,373.2,720.4,375.5L720.4,375.5z M169.2,315.2
	c-1.1,1-2.1,1.9-3,2.9c-6.9,7.2-11.8,15-15,23.7c-1.2,3.4-2.2,7-2.9,10.6h16.6c0.8-2.3,1.9-4.7,3-6.9c2.9-5.5,6.5-10.6,11-15.4
	c12.1-12.7,26.7-19,43.9-19c12.7,0,24.1,3.4,34,10.5c3.4,2.5,6.7,5.2,9.9,8.5c3.7,3.9,6.9,8.1,9.5,12.5c5.5,9.4,8.4,19.8,8.7,31.5
	l15.4-0.3c-0.5-5.6-1.4-12-2.5-19c-1.6-9.5-5-18.2-10-26c-2.6-4-5.6-7.8-9.2-11.4l-2.3-2.2c-4.3-4-8.8-7.6-13.6-10.5
	c-4.8-2.9-10-5.2-15.4-6.9c-7.6-2.5-15.7-3.6-24.4-3.6C201.8,294.3,184.1,301.2,169.2,315.2L169.2,315.2z"
          />
          <path
            fill="none"
            d="M186.7,336.8c2.1-2.1,4.3-4,6.6-5.6c8.4-6.1,18.2-9.1,29.2-9.1s20.6,3,29.2,9.1c2.3,1.6,4.5,3.6,6.6,5.6
	c2.6,2.6,4.8,5.2,6.6,8c5.5,8.1,8.1,17.3,8.1,27.6c0,6.3-1.1,12.4-3,17.9l15-0.1v-16.1c-0.3-11.7-3.2-22.1-8.7-31.5
	c-2.6-4.4-5.8-8.5-9.5-12.5c-3.2-3.3-6.5-6.1-9.9-8.5c-9.9-7-21.3-10.5-34-10.5c-17.2,0-31.8,6.3-43.9,19c-4.5,4.8-8.3,9.9-11,15.4
	c-1.1,2.2-2.2,4.5-3,6.9c-2.3,6.5-3.7,13.3-4,20.8c-0.3,6.7-0.3,12.9,0,18.6l14.9,0.4c-2.5-6.1-3.9-12.5-3.9-19.7
	c0-12.4,3.9-23.2,11.7-32.3C184.6,339,185.6,337.9,186.7,336.8L186.7,336.8z"
          />
          <path
            fill="none"
            d="M193.3,331.2c-2.3,1.6-4.5,3.6-6.6,5.6c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3
	c0,7,1.2,13.6,3.9,19.7c1.9,4.5,4.5,8.8,7.8,12.8c1,1.1,2.1,2.2,3,3.3c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9
	c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c2.6-2.6,4.8-5.2,6.6-8c2.1-3.2,3.7-6.5,5.1-9.9c2.1-5.5,3-11.4,3-17.9
	c0-10.3-2.8-19.5-8.1-27.6c-1.9-2.8-4.1-5.5-6.6-8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1S201.8,325.2,193.3,331.2
	L193.3,331.2z M223.4,408.9c-10,0-18.6-3.6-25.7-10.6c-7.2-7.2-10.7-15.7-10.7-25.7c0-10,3.6-18.6,10.7-25.7
	c7.2-7.2,15.7-10.6,25.7-10.6s18.6,3.6,25.7,10.6c7.2,7.2,10.7,15.7,10.7,25.7c0,10-3.6,18.6-10.7,25.7
	C242.1,405.4,233.4,408.9,223.4,408.9L223.4,408.9z"
          />
          <path
            fill="none"
            d="M197.7,398.3c7.2,7.2,15.7,10.6,25.7,10.6s18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7
	c0-10-3.6-18.6-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6s-18.6,3.6-25.7,10.6c-7.2,7.2-10.7,15.7-10.7,25.7
	C187.1,382.7,190.7,391.2,197.7,398.3L197.7,398.3z M190.2,362.4h0.4l1.5,0.3c1-3,2.3-5.8,4.1-8.4l-1.1-1l-0.1-0.3v-0.4
	c0,0,0.1-0.3,0.3-0.3h0.3c1.4-0.7,2.6-1.1,3.6-1.5l1.2-1.2c5.2-5.2,11.3-8.3,18.3-9.2c0.8-0.7,1.9-1.5,3.3-2.3l0.1-0.1
	c0.1-0.1,0.3-0.1,0.6-0.1c0.1,0,0.3,0.1,0.4,0.3l0.1,0.3v0.1l0.3,1.7h0.3c3.2,0,6.2,0.4,9.1,1.2l0.6-1.5c0-0.1,0.1-0.1,0.1-0.3
	c0.1-0.1,0.3-0.1,0.4-0.1l0.4,0.3l0.1,0.1c1.1,1.1,1.9,2.1,2.5,3c3.6,1.6,6.9,3.9,9.9,6.9c2.6,2.6,4.7,5.5,6.2,8.5
	c1,0.5,1.9,1.4,3.2,2.3h0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1h-0.1l-1.4,0.7c1,2.9,1.5,5.9,1.6,9.2l1.7,0.1
	l0.3,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4l-0.1,0.1c-0.7,1.4-1.5,2.5-2.1,3.3c-0.7,7.6-3.9,14.2-9.5,19.8l-0.1,0.1
	c-0.3,1.1-0.7,2.3-1.4,3.9v0.3c0,0.1-0.1,0.3-0.3,0.4h-0.4l-0.3-0.1v-0.1h-0.1l-1.1-1.1c-2.6,1.9-5.2,3.4-8.3,4.5l0.3,1.5v0.3
	l-0.3,0.3l-0.4,0.1c-0.1,0-0.1-0.1-0.3-0.1c-1.5-0.3-2.8-0.5-3.7-1c-2.2,0.4-4.7,0.7-7,0.7c-5.6,0-10.7-1.2-15.3-3.7
	c-1,0-2.2,0-3.7-0.1h-0.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-0.4l0.7-1.4c-1.5-1.1-2.9-2.2-4.1-3.6
	c-1-1-1.8-1.9-2.6-2.9l-1.4,0.8H196c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.3-0.3-0.4v-0.3c-0.1-1.4-0.3-2.6-0.3-3.6
	c-3-5-4.4-10.5-4.4-16.6c0-1.9,0.1-3.7,0.4-5.5c-0.4-1-0.8-2.2-1.1-3.7l-0.1-0.1v-0.4C190,362.6,190.1,362.4,190.2,362.4
	L190.2,362.4z"
          />
          <path
            fill="none"
            d="M190.7,362.4l4.3,2.2c6.2,3,10.7,5,13.6,5.8c3.4,1.1,5.8,1.5,6.6,1.4c0.8-0.1,1.6-0.4,2.2-1.1
	c0.4-0.5,0.7-1,0.8-1.6v-0.4c0-0.8-0.1-1.4-0.6-2.1c-0.6-0.7-2.3-2.2-5.4-4.3c-2.5-1.8-6.9-4.1-13.1-7l-4.4-1.9h-0.1l1.1,1l2.2,1.9
	l0.4,0.1l10.5,5.1c1.9,1.2,2.6,2.3,2.1,3.3l-1.5,2.9c-0.4,1.1-1.6,1.4-3.9,0.5l-10.6-5l-0.4-0.3l-2.8-0.4L190.7,362.4L190.7,362.4z
	 M212.4,368.1c0.1-0.7,0.4-1.2,1.1-1.8c0.6-0.4,1.2-0.5,1.9-0.5c0.7,0.1,1.2,0.4,1.6,1.1c0.4,0.5,0.6,1.2,0.6,1.9
	c-0.1,0.7-0.4,1.4-1.1,1.8c-0.6,0.4-1.2,0.5-1.9,0.4c-0.7-0.1-1.4-0.4-1.8-1.1C212.5,369.5,212.4,368.8,212.4,368.1L212.4,368.1z"
          />
          <path
            fill="none"
            d="M213.5,366.3c-0.6,0.4-1,1-1.1,1.8c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4
	c0.6-0.4,1-1,1.1-1.8c0.1-0.7,0-1.4-0.6-1.9c-0.4-0.5-1-1-1.6-1.1C214.7,365.7,214,365.9,213.5,366.3z M213.2,368.1
	c0.1-0.5,0.4-1,0.8-1.2c0.4-0.3,1-0.4,1.4-0.4c0.6,0.1,1,0.4,1.2,0.8s0.4,0.8,0.3,1.4s-0.3,1-0.8,1.2c-0.4,0.3-0.8,0.4-1.4,0.3
	s-1-0.3-1.2-0.7C213.2,369.2,213.1,368.6,213.2,368.1L213.2,368.1z"
          />
          <path
            fill="none"
            d="M213.9,366.8c-0.4,0.3-0.7,0.7-0.8,1.2c-0.1,0.6,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.6,0.1,1,0,1.4-0.3
	c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8C214.9,366.4,214.3,366.6,213.9,366.8L213.9,366.8z"
          />
          <path
            fill="none"
            d="M190.7,362.4h-0.4c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1l0.1,0.1l4.5,2.2c6.2,3,10.7,5,13.6,5.8
	c3.7,1.1,6.2,1.5,7.2,1.5l0.7-0.1c0.7-0.1,1.4-0.5,2.1-1.1l0.3-0.3c0.6-0.7,1-1.4,1.1-2.3c0.1-1,0.1-1.9-0.3-2.8l-0.3-0.5
	c-0.6-0.8-2.5-2.3-5.6-4.5c-2.5-1.8-6.9-4.1-13.1-7l-4.5-2.2h-0.6c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.3h0.1
	l4.4,1.9c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3c0.4,0.5,0.6,1.2,0.6,2.1v0.4c-0.1,0.6-0.4,1.1-0.8,1.6
	c-0.6,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8L190.7,362.4L190.7,362.4z"
          />
          <path
            fill="none"
            d="M190.1,363.5l-0.1-0.1c0.4,1.5,0.7,2.8,1.1,3.7c0.7,1.6,1.4,2.6,2.2,2.9l0.7,0.3c5.4,1.9,10.2,3,14.4,3.6
	c2.8,0.3,5,0,7-0.8c-1,0.1-3.3-0.4-7.2-1.5c-2.9-0.8-7.4-2.8-13.6-5.8L190.1,363.5L190.1,363.5z"
          />
          <path
            fill="none"
            d="M193.3,369.9c-0.8-0.3-1.5-1.2-2.2-2.9c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6c0-1.9,0.3-3.2,1-3.7
	l0.6-0.4c-1.8-3.9-2.8-8-2.8-12.5l0.1-2.3L193.3,369.9L193.3,369.9z M222.9,337.8c-0.1-0.1-0.3-0.3-0.4-0.3s-0.4,0-0.6,0.1l-0.1,0.1
	l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2l0.3,0.5c0.4,0.7,1,1.2,1.6,1.6l0.4,0.1
	c0.8,0.4,1.6,0.4,2.5,0.3c1-0.1,1.8-0.5,2.5-1.1l0.4-0.4c0.6-0.8,1.5-3,2.6-6.7c1-3,1.8-7.8,2.6-14.6l0.6-5.1v-0.3l-0.1-0.1
	l-0.4-0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0.1-0.1,0.3v0.1l-0.4,5c-0.8,6.7-1.8,11.7-2.6,14.6c-1.1,3.6-1.9,5.6-2.5,6.3
	c-0.4,0.5-1.1,1-1.8,1.1l-0.4,0.1c-0.6,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.6-3.2-0.7-6.7c-0.1-3,0.3-8,1.2-14.7
	l0.7-4.8L222.9,337.8L222.9,337.8z"
          />
          <path
            fill="none"
            d="M221.8,338.1l0.1-0.3c-1.4,0.8-2.5,1.6-3.3,2.3c-1.4,1.1-1.9,2.1-1.9,2.9v0.8c-0.1,5.6,0.3,10.6,1.1,14.7
	c0.6,2.6,1.5,4.8,2.9,6.5c-0.4-1-0.7-3.3-0.8-7.2c-0.1-3,0.3-8,1.2-14.6L221.8,338.1L221.8,338.1z"
          />
          <path
            fill="none"
            d="M223,338.2l-0.7,5c-1,6.7-1.4,11.6-1.2,14.7c0.1,3.7,0.3,5.9,0.7,6.7c0.3,0.8,1,1.4,1.8,1.8
	c0.6,0.3,1.2,0.4,1.8,0.3l0.4-0.1c0.7-0.1,1.4-0.6,1.8-1.1c0.6-0.7,1.4-2.9,2.5-6.3c1-3,1.8-7.8,2.6-14.6l0.6-4.8v-0.1l-0.6,1.5
	l-1.1,2.6v0.4l-1.6,11.6c-0.6,2.2-1.4,3.2-2.6,2.9l-3.2-0.6c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4v-0.5l-0.4-2.6L223,338.2L223,338.2z
	 M226.3,361.1c0.6,0.4,1,1,1.1,1.8c0.1,0.7,0,1.4-0.6,1.9c-0.4,0.6-1,1-1.8,1.1c-0.7,0.1-1.4,0-1.9-0.4c-0.6-0.4-1-1-1.1-1.8
	c-0.1-0.7,0-1.4,0.4-1.9c0.4-0.5,1-1,1.8-1.1C225.1,360.5,225.7,360.6,226.3,361.1L226.3,361.1z"
          />
          <path
            fill="none"
            d="M227.4,362.8c-0.1-0.7-0.6-1.4-1.1-1.8c-0.6-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1
	c-0.4,0.5-0.6,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.6,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1
	C227.4,364.2,227.5,363.5,227.4,362.8L227.4,362.8z M225.9,361.7c0.4,0.3,0.7,0.7,0.8,1.2c0.1,0.5,0,1-0.4,1.5
	c-0.3,0.4-0.7,0.7-1.2,0.7c-0.6,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.7-0.8-1.2c-0.1-0.5,0-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.8
	C225.1,361.3,225.5,361.5,225.9,361.7L225.9,361.7z"
          />
          <path
            fill="none"
            d="M226.7,363c-0.1-0.5-0.3-1-0.8-1.2c-0.4-0.3-0.8-0.4-1.4-0.4c-0.6,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4
	c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3c0.6-0.1,1-0.3,1.2-0.7C226.6,363.9,226.7,363.5,226.7,363L226.7,363z M244.3,351.7
	c1.6,1.6,3.2,3.6,4.4,5.4l0.8-0.3c0.7-0.3,1.9,0,3.4,1c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9c1.1,1.5,1.5,2.6,1.2,3.4
	l-0.1,0.8C240,348,242.2,349.6,244.3,351.7L244.3,351.7z"
          />
          <path
            fill="none"
            d="M248.7,357.1c-1.2-1.9-2.8-3.7-4.4-5.4c-2.1-2.1-4.1-3.7-6.5-5c-1.4,5.5-3,10.2-4.8,14
	c-1.2,2.3-2.8,4.1-4.4,5.5l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.6,0-2.5-0.3l-0.4-0.1c-0.7-0.4-1.4-1-1.6-1.6l-0.3-0.5
	c-1.4-1.6-2.3-3.9-2.9-6.5c-0.8-4.1-1.2-9.1-1.1-14.7c-5,1.1-9.4,3.4-13.3,7.2c4.8,3,8.8,6.1,11.8,8.9c1.9,1.9,3.2,4,3.9,5.9
	l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.6,1.7-1.1,2.3l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1c-1.9,0.7-4.3,1-7,0.8
	c-4.3-0.5-9.1-1.6-14.4-3.6l-0.1,2.3c0,4.5,1,8.8,2.8,12.5c4.4-3.7,8.5-6.6,12.2-8.5c2.5-1.2,4.7-1.8,6.9-1.8l0.7-0.1
	c0.8-0.1,1.8,0.1,2.6,0.5c0.8,0.4,1.4,1,1.8,1.8l0.1,0.4c0.4,0.8,0.6,1.5,0.4,2.3l-0.1,0.7c0.1,2.1-0.4,4.4-1.5,6.9
	c-1.8,3.9-4.4,8.1-7.8,12.7c3.4,1.4,7.3,2.2,11.3,2.2l3.7-0.1c-2.2-5.2-3.6-9.9-4.3-14.2c-0.4-2.8-0.3-5.1,0.4-7v-0.5
	c0.1-0.8,0.7-1.6,1.4-2.3c0.6-0.5,1.4-1,2.2-1.2h0.4c0.8-0.1,1.6,0,2.3,0.3c0.3,0,0.4,0.1,0.6,0.3c2.1,0.5,4,1.8,6.1,3.4
	c3,2.9,6.3,6.6,9.5,11.3c3.4-4,5.6-8.5,6.6-13.6c-5.6,0.4-10.6,0.3-14.9-0.3c-2.6-0.5-4.8-1.4-6.6-2.6l-0.6-0.3
	c-0.8-0.4-1.4-1.1-1.8-2.1c-0.4-0.7-0.6-1.5-0.4-2.5v-0.4c0.1-0.8,0.6-1.5,1-2.1l0.4-0.4c1.2-1.8,2.9-3.3,5.2-4.7
	C238.7,360.6,243.2,358.9,248.7,357.1L248.7,357.1z M226,370c0.7,0.7,1.1,1.6,1.1,2.6s-0.4,1.8-1.1,2.6c-0.7,0.7-1.5,1.1-2.6,1.1
	c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.5-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.5-1.1,2.6-1.1S225.3,369.3,226,370L226,370z"
          />
          <path
            fill="none"
            d="M227.1,372.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.5-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
	s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1C226.7,374.4,227.1,373.6,227.1,372.6L227.1,372.6z M252.8,357.9
	c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3c-5.5,1.6-10,3.6-13.8,5.6c-2.3,1.4-4,3-5.2,4.7c0.8-0.7,3-1.6,6.6-3c2.9-1.1,7.7-2.2,14.3-3.3
	l5.1-0.8h0.3C254.8,359.3,253.8,358.4,252.8,357.9L252.8,357.9z M234.3,339.6v0.3l-0.6,5.1c-0.8,6.7-1.6,11.6-2.6,14.6
	c-1.1,3.7-2.1,6.1-2.6,6.7c1.6-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14l0.1-0.8c0.3-0.8-0.1-1.9-1.2-3.4
	C236.1,341.7,235.2,340.7,234.3,339.6L234.3,339.6z M231.4,343.6l1.1-2.6c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3l0.4,2.6v0.5
	c2.8,0,5.2,0.3,7.7,1L231.4,343.6L231.4,343.6z"
          />
          <path
            fill="none"
            d="M227,358.6c1.1,0.3,2.1-0.7,2.6-2.9l1.6-11.6c-2.5-0.7-5-1-7.7-1l-1.4,11.4c0,2.3,0.6,3.4,1.8,3.4L227,358.6
	L227,358.6z M236.7,365.6c-3.4,1.2-5.5,2.2-6.2,2.8c-0.6,0.5-1,1.2-1.1,2.2c0,0.7,0,1.2,0.3,1.8l0.1,0.4c0.4,0.7,1,1.1,1.6,1.4
	c0.8,0.3,3.2,0.4,6.7,0.4c3,0,8-0.7,14.6-2.1l5-1l-1.6-0.1l-2.8-0.3l-0.6,0.1l-11.4,1.9c-2.3,0.1-3.6-0.4-3.6-1.5l-0.6-3.2
	c-0.3-1.1,0.6-2.1,2.8-2.8l11.4-2.2l0.6-0.1l2.5-1.2l1.4-0.7l-4.8,0.8C244.5,363.4,239.6,364.5,236.7,365.6L236.7,365.6z M230.1,370
	c0.3-0.7,0.7-1.2,1.4-1.5c0.7-0.3,1.4-0.4,2.1-0.1c0.7,0.3,1.2,0.7,1.5,1.4s0.4,1.4,0.1,2.1s-0.7,1.2-1.4,1.5
	c-0.7,0.3-1.4,0.4-2.1,0.1c-0.7-0.3-1.2-0.7-1.5-1.4C230,371.4,230,370.8,230.1,370L230.1,370z"
          />
          <path
            fill="none"
            d="M231.5,368.5c-0.7,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.1,1.4,0.1,2.1c0.3,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1
	c0.7-0.3,1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4C232.8,368.1,232.1,368.2,231.5,368.5L231.5,368.5z M231.8,369.2
	c0.6-0.3,1-0.3,1.5-0.1c0.6,0.1,1,0.5,1.1,1c0.3,0.4,0.3,1,0.1,1.5c-0.1,0.6-0.4,0.8-1,1.1l-1.5,0.1c-0.6-0.1-0.8-0.4-1.1-1
	c-0.3-0.4-0.3-1-0.1-1.5C231,369.9,231.4,369.4,231.8,369.2L231.8,369.2z"
          />
          <path
            fill="none"
            d="M233.3,369c-0.6-0.1-1-0.1-1.5,0.1c-0.4,0.3-0.8,0.5-1,1.1c-0.1,0.5-0.1,1,0.1,1.5c0.3,0.5,0.6,0.8,1.1,1
	l1.5-0.1c0.4-0.3,0.8-0.7,1-1.1c0.1-0.5,0.1-1-0.1-1.5C234.1,369.6,233.7,369.2,233.3,369L233.3,369z"
          />
          <path
            fill="none"
            d="M236.7,365.6c2.9-1.1,7.7-2.2,14.4-3.3l4.8-0.8h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4h-0.4
	l-5.1,0.8c-6.6,1.2-11.4,2.3-14.3,3.3c-3.6,1.4-5.8,2.3-6.6,3l-0.4,0.4c-0.6,0.6-0.8,1.2-1,2.1v0.4c-0.1,1,0.1,1.8,0.4,2.5
	c0.4,1,1.1,1.6,1.8,2.1l0.6,0.3c1,0.4,3.4,0.6,7.3,0.4c3,0,7.8-0.7,14.6-2.1l5.1-1l0.3-0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	s-0.1-0.3-0.3-0.4l-0.3-0.1l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4c-0.7-0.3-1.2-0.7-1.7-1.4l-0.1-0.4
	c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2C231.2,367.8,233.3,366.8,236.7,365.6L236.7,365.6z"
          />
          <path
            fill="none"
            d="M240.2,365.7c-2.2,0.7-3.2,1.6-2.8,2.8l0.6,3.2c0.1,1.1,1.2,1.6,3.6,1.5l11.4-1.9c-0.1-2.8-0.6-5.2-1.4-7.7
	L240.2,365.7L240.2,365.7z M227.4,376.3H227c-0.8,0.1-1.6,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5c0,1,0.6,3.3,1.8,7
	c1,2.9,3.2,7.4,6.5,13.3l2.5,4.4l0.1,0.1c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3
	c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9l0.3-0.3c0.4-0.4,1-0.7,1.6-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1
	c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4h0.1v0.1l0.3,0.1h0.4c0.1-0.1,0.3-0.3,0.3-0.4v-0.6l-2.3-4.5c-3.2-5.9-5.8-10.2-7.7-12.5
	c-2.3-3-4-4.8-4.8-5.4c-0.1-0.1-0.4-0.1-0.6-0.3C229,376.3,228.2,376.2,227.4,376.3L227.4,376.3z"
          />
          <path
            fill="none"
            d="M229.6,378c-0.7-0.4-1.5-0.5-2.5-0.4c-0.6,0.1-1.1,0.4-1.6,0.8l-0.3,0.3c-0.6,0.5-0.7,1.2-0.7,1.9
	c0,0.8,0.6,3,1.8,6.6c1,2.9,3.2,7.3,6.5,13.3l2.3,4.3l-0.3-1.5l-0.7-2.9L234,400l-5.5-10.3c-0.8-2.2-0.7-3.4,0.4-3.9l2.9-1.5
	c1-0.7,2.1,0,3.4,1.8l5.6,10l0.3,0.5l1.9,1.9l1.1,1.1l-2.2-4.4c-3.2-6.1-5.8-10.3-7.6-12.7C231.9,380,230.4,378.4,229.6,378
	L229.6,378z M229.7,378.9c0.6,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.9c-0.6,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8
	s-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9c0.6-0.5,1.1-0.8,1.9-0.8C228.6,378.1,229.3,378.4,229.7,378.9L229.7,378.9z"
          />
          <path
            fill="none"
            d="M230.6,380.7c0-0.7-0.3-1.4-0.8-1.9s-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8s-0.8,1.2-0.8,1.9s0.3,1.4,0.8,1.9
	c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8C230.3,382.1,230.6,381.6,230.6,380.7z M227.9,382.7c-0.6,0-1-0.1-1.4-0.5
	c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.5,0.1-1,0.6-1.4s0.8-0.5,1.4-0.5s1,0.1,1.4,0.5c0.4,0.4,0.6,0.8,0.6,1.4c0,0.5-0.1,1-0.6,1.4
	C228.9,382.5,228.5,382.7,227.9,382.7L227.9,382.7z"
          />
          <path
            fill="none"
            d="M226.6,382.1c0.4,0.4,0.8,0.5,1.4,0.5s1-0.1,1.4-0.5c0.4-0.4,0.6-0.8,0.6-1.4c0-0.5-0.1-1-0.6-1.4
	c-0.4-0.4-0.8-0.5-1.4-0.5s-1,0.1-1.4,0.5s-0.6,0.8-0.6,1.4C226,381.3,226.2,381.7,226.6,382.1z M231.1,375.2c1.8,1.2,4,2.1,6.6,2.6
	c4.1,0.5,9.1,0.7,14.9,0.3h0.8c0.8,0,1.8-0.7,2.9-2.1c0.7-0.8,1.4-1.9,2.1-3.3l-0.3,0.1l-5.1,1c-6.7,1.2-11.6,1.9-14.6,2.1
	C234.4,375.8,232.1,375.6,231.1,375.2L231.1,375.2z M252.2,363.4l-0.6,0.1c0.8,2.5,1.2,5,1.4,7.7l0.6-0.1l2.7,0.3
	c-0.1-3.2-0.7-6.3-1.6-9.2L252.2,363.4L252.2,363.4z"
          />
          <path
            fill="none"
            d="M253.3,378h-0.8c-1,5.1-3,9.6-6.6,13.6l0.6,0.7c0.6,0.5,0.6,1.8,0.1,3.4l0.1-0.1c5.6-5.6,8.8-12.2,9.5-19.8
	C255,377.3,254.1,378,253.3,378L253.3,378z M216.7,343.9V343c0-0.8,0.7-1.8,1.9-2.9c-7,1-13.1,4-18.3,9.2l-1.2,1.2
	c1.8-0.5,2.9-0.5,3.6-0.1l0.7,0.4C207.2,347.3,211.7,345,216.7,343.9L216.7,343.9z"
          />
          <path
            fill="none"
            d="M195.5,352.1h0.3l4.5,2.2c6.2,2.9,10.6,5.2,13.1,7c3.2,2.2,5.1,3.7,5.6,4.5c-0.7-2.1-1.9-4-3.9-5.9
	c-3-2.9-6.9-5.9-11.8-8.9l-0.7-0.4c-0.7-0.5-1.8-0.4-3.6,0.1C198.1,351,196.9,351.4,195.5,352.1L195.5,352.1z M198.8,356.4l-0.4-0.1
	l-2.2-1.9c-1.8,2.6-3.2,5.4-4.1,8.4l2.8,0.4l0.4,0.3C196.2,360.8,197.3,358.6,198.8,356.4L198.8,356.4z"
          />
          <path
            fill="none"
            d="M195.3,363.4l10.6,5c2.2,0.7,3.4,0.6,3.9-0.5l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1
	C197.3,358.6,196.2,360.8,195.3,363.4L195.3,363.4z M216.4,374.7l-0.7,0.1c-1,0.3-3,1.5-6.1,3.9c-2.5,1.8-6.1,5.2-10.7,10.3
	l-3.3,3.7l-0.1,0.1v0.3c0,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3
	c2.9-2.2,4.8-3.4,5.6-3.7c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3c0.6,0.3,1,0.7,1.2,1.2c0.4,0.8,0.6,1.6,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9
	c-1.8,2.6-4.9,6.3-9.6,11.1l-3.3,3.4v0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1
	c2.2-3.2,3.4-5.4,3.6-6.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8C218.2,374.7,217.2,374.5,216.4,374.7
	L216.4,374.7z"
          />
          <path
            fill="none"
            d="M218.6,376.3l-0.4-0.3c-0.7-0.3-1.4-0.3-2.1-0.1c-0.8,0.3-2.8,1.5-5.6,3.7c-2.5,1.8-5.9,5.2-10.7,10.3l-3.3,3.6
	l1.4-0.8l2.5-1.5l0.3-0.3l8.1-8.4c1.8-1.5,3-1.8,3.9-0.8l2.2,2.2c1,0.7,0.7,2.1-0.7,3.9l-8,8.5l-0.4,0.4l-1.2,2.5l-0.7,1.4l3.3-3.4
	c4.7-4.8,8-8.7,9.6-11.1c2.1-3,3.2-5,3.4-5.9c0.1-0.8,0-1.6-0.4-2.5C219.6,377,219.1,376.6,218.6,376.3L218.6,376.3z M214.6,378.1
	c0.3-0.7,0.8-1.1,1.5-1.4c0.7-0.3,1.4-0.1,2.1,0.1c0.7,0.3,1.1,0.8,1.4,1.5c0.3,0.7,0.1,1.4-0.1,2.1c-0.4,0.7-0.8,1.1-1.5,1.4
	s-1.4,0.1-2.1-0.1s-1.1-0.8-1.4-1.5C214.2,379.4,214.3,378.7,214.6,378.1L214.6,378.1z"
          />
          <path
            fill="none"
            d="M216.1,376.7c-0.7,0.3-1.2,0.7-1.5,1.4c-0.3,0.7-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1
	s1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5C217.5,376.6,216.8,376.5,216.1,376.7L216.1,376.7z M216.4,377.4
	c0.6-0.1,1-0.1,1.4,0.1c0.6,0.3,0.8,0.5,1,1.1c0.1,0.5,0.1,1-0.1,1.4c-0.3,0.6-0.6,0.8-1.1,1c-0.6,0.1-1,0.1-1.5-0.1
	c-0.4-0.3-0.8-0.5-1-1.1c-0.1-0.5-0.1-1,0.1-1.5C215.6,378,216,377.6,216.4,377.4L216.4,377.4z"
          />
          <path
            fill="none"
            d="M217.9,377.6c-0.4-0.3-1-0.3-1.4-0.1c-0.6,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5c0.1,0.5,0.6,0.8,1,1.1
	c0.6,0.3,1,0.3,1.5,0.1c0.6-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4C218.7,378.1,218.3,377.8,217.9,377.6L217.9,377.6z
	 M215.7,374.8c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5l-0.6,0.4c-0.7,0.5-1,1.8-1,3.7c0,1,0.1,2.2,0.3,3.6l0.1-0.1
	l3.3-3.7c4.7-5.1,8.3-8.5,10.7-10.3C212.8,376.3,214.7,375.1,215.7,374.8L215.7,374.8z M225.1,387.6c-1.2-3.7-1.9-6.1-1.8-7
	c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1c1,0.3,2.2,0.7,3.7,1l-0.1-0.1l-2.5-4.4
	C228.2,394.9,226.2,390.5,225.1,387.6L225.1,387.6z M221.5,380.3c-0.3,1-1.5,3-3.6,6.3c-1.8,2.6-4.9,6.3-9.6,11.1l-3.6,3.6l-0.3,0.1
	c1.5,0.1,2.8,0.1,3.7,0.1c1.9-0.1,3.2-0.6,3.6-1.2l0.4-0.5c3.4-4.5,6.1-8.8,7.8-12.7C221.1,384.7,221.5,382.4,221.5,380.3
	L221.5,380.3z M214.1,387.9c1.4-1.9,1.6-3.2,0.7-3.9l-2.2-2.2c-0.7-1-2.1-0.7-3.9,0.8l-8.1,8.4l2.2,2.3c1.1,1.1,2.2,2.1,3.4,3
	L214.1,387.9L214.1,387.9z"
          />
          <path
            fill="none"
            d="M200.4,391l-0.3,0.3l-2.5,1.5c0.8,1,1.6,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6l1.2-2.5l0.4-0.4
	c-1.2-1-2.3-1.9-3.4-3L200.4,391L200.4,391z M212.1,399.9l-0.4,0.5c-0.4,0.7-1.6,1.1-3.6,1.2c4.5,2.5,9.6,3.7,15.3,3.7
	c2.5,0,4.8-0.3,7-0.7c-1.8-0.5-2.9-1.2-3-2.1l-0.3-0.7l-3.7,0.1C219.4,402.1,215.6,401.2,212.1,399.9L212.1,399.9z M246.5,395.9
	c0.4-1.6,0.4-2.9-0.1-3.4l-0.6-0.7c-3.3-4.7-6.5-8.4-9.5-11.3c-2.1-1.8-4.1-2.9-6.1-3.4c0.8,0.5,2.5,2.3,4.8,5.4
	c1.9,2.5,4.4,6.6,7.7,12.5l2.3,4.5v0.3C245.8,398.2,246.2,396.8,246.5,395.9L246.5,395.9z M231.7,384.4l-2.9,1.5
	c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3c2.5-1,4.7-2.2,6.9-3.7l-5.6-10C233.9,384.4,232.6,383.9,231.7,384.4L231.7,384.4z"
          />
          <path
            fill="none"
            d="M241,397l-0.3-0.5c-2.2,1.6-4.4,2.9-6.9,3.7l0.1,0.4l0.7,2.9c2.9-1.1,5.6-2.6,8.3-4.5L241,397L241,397z
	 M114,327.5c0.8,4-0.4,6.3-3.7,7c-3.9,0-6.1,2.2-6.3,6.3v12.5c0,2.1,1,3.4,2.6,4.1c0.6,0.3,1.4,0.4,2.1,0.6h3.2
	c2.3,0.3,3.4,1.8,3.6,4.7v13.2c0,1.9-0.8,3.3-2.8,4.3l-1,0.4l-1.9,0.5l-0.4,0.1l1.7-0.3l1.9-0.5l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-5
	c0.1-1.9,1-3,2.5-3.3l21.9-1.9c4.3,0,6.5-1.9,6.5-5.6c0.1-2.5,0.6-5,1-7.4c0.7-3.7,1.7-7.2,2.9-10.6c3.2-8.5,8.1-16.5,15-23.7
	l-39.9,3.7v2.5c0.1,1.1,0,1.9-0.4,2.5c-0.4,0.4-1,0.7-1.7,0.7c-0.6-0.4-1-1-1.2-1.6c-0.4-1.5-1.4-2.2-2.6-2.2h-4.3
	C114.2,323.8,113.5,325,114,327.5L114,327.5z"
          />
          <path
            fill="none"
            d="M123,325.8c0.3,0.7,0.7,1.2,1.2,1.6c0.7,0,1.2-0.3,1.7-0.7l-1.1-2.5c-0.8-1.8-2.3-2.6-4.3-2.6
	c-2.3,0.1-3.3,0.8-2.9,1.9h2.9C121.7,323.6,122.6,324.5,123,325.8L123,325.8z"
          />
          <path
            fill="none"
            d="M126.4,324.3v-2.5c0-5.1-0.8-11.3-2.3-18.3c-1-3.6-2.6-5.6-5.1-6.2c-0.6,9.9,0,18,1.5,24.3
	c2.1-0.1,3.4,0.7,4.3,2.6l1.1,2.5C126.3,326.3,126.6,325.4,126.4,324.3L126.4,324.3z M111.8,358h-3.2c-0.3,3.4-0.3,6.9,0.1,10.3
	c0.4,4,1.4,8.1,2.8,12.4l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-13.2C115.4,359.8,114.2,358.3,111.8,358L111.8,358z"
          />
          <path
            fill="none"
            d="M108.8,358c-0.8-0.1-1.5-0.3-2.1-0.5c-0.8,7.7,0.1,15.7,3,23.8l1.9-0.6c-1.4-4.1-2.3-8.3-2.9-12.4
	C108.4,364.9,108.4,361.5,108.8,358L108.8,358z"
          />
          <path
            fill="none"
            d="M116.5,370.8v5c0,1.9-0.8,3.3-2.8,4.3l-1,0.4l-1.9,0.5l-1.7,0.3l-2.3,0.3c-1.2,0.5-1,1.9,0.8,4.3
	c1.7,1.6,5.2,2.9,10.9,3.7c7.2,1,16.1,1.6,26.7,1.9c4.8,0.1,10,0.3,15.7,0.3c-0.3-5.6-0.3-12,0-18.6c0.3-7.4,1.6-14.3,4-20.8h-16.6
	c-0.4,2.5-0.8,4.8-1,7.4c0,3.9-2.2,5.8-6.5,5.6l-21.9,1.9C117.6,367.8,116.8,368.9,116.5,370.8L116.5,370.8z M158.2,402.6
	c2.3,0.4,3.6,0.1,3.6-1v-0.3c-0.3-3-0.6-6.2-0.7-9.5c-5.6,0-10.9-0.1-15.7-0.3c0.3,4.4,1.9,7.2,5.2,8.5L158.2,402.6L158.2,402.6z
	 M360.3,209.6c2.8-3,1.5-4.7-4.1-5.2h-20.1l-68.9,52.3l32.5,0.7c6.5,0,13.8-3.9,21.6-11.4C339.6,230.6,352.7,218.5,360.3,209.6
	L360.3,209.6z M335.1,248c-1,0.4-1.9,1-2.9,1.5l-0.5,0.4c-2.6,1.8-4.1,3.9-4.7,6.3c-0.1,0.7-0.3,1.2-0.3,1.9c0,0.8,0.1,1.5,0.3,2.3
	c0.1,0.7,0.5,1.5,1,2.2c0.5,1,1.2,1.8,2.2,2.6l2.1,1.5c1.8,1.1,3.6,1.9,5.8,2.5l2.8,0.4l1.2,0.1c2.2-0.3,4-1.4,5.5-3.4
	c0.4-0.5,0.8-1.2,1.2-1.9c0.4-0.8,0.8-1.8,1.1-2.8c0.3-1.1,0.4-2.3,0.4-3.6c0-3.2-0.8-5.9-2.6-8.3c-1.5-2.1-3.4-3.2-5.5-3.3
	l-1.2,0.1C338.7,246.9,336.9,247.3,335.1,248L335.1,248z"
          />
          <path
            fill="none"
            d="M326.9,258.2c0-0.7,0.1-1.4,0.3-1.9l-6.5,6.7l5.6-0.3l0.8-2.2C326.9,259.7,326.9,259,326.9,258.2L326.9,258.2z
	 M342,246.6h1.5c2.5,0,4.7,1.1,6.5,3.4c1.8,2.3,2.6,5.1,2.6,8.3c0,1.2-0.1,2.3-0.4,3.4l94.1-3.9l15.8-57.5
	c-27.5,0.4-53.2,1.2-77.2,2.9c-17.5,14.4-34.1,29.4-49.8,44.8c1.6-0.7,3.6-1.1,5.5-1.4L342,246.6L342,246.6z"
          />
          <path
            fill="none"
            d="M343.4,246.5h-1.5c2.2,0.3,4,1.4,5.5,3.3c1.8,2.3,2.6,5.1,2.6,8.3c0,1.2-0.1,2.5-0.4,3.6
	c-0.3,1-0.5,1.9-1.1,2.8c-0.4,0.7-0.8,1.4-1.2,1.9c-1.5,2.1-3.4,3.2-5.5,3.4h1.8c2.5,0,4.4-1.2,6.2-3.4c0.5-0.7,1-1.4,1.2-2.1
	c0.4-0.8,0.8-1.8,1-2.8c0.3-1.1,0.4-2.2,0.4-3.4c0-3.2-0.8-5.9-2.6-8.3C348,247.7,346,246.5,343.4,246.5L343.4,246.5z M297.4,278.4
	c0.5-0.5,0.8-1,1-1.4v-0.4c-0.1-0.5-1.1-0.8-2.9-0.8l-9.4,0.3c-2.9,0-5.2,1-7.3,2.6l-17.1,14.9c-2.5,2.1-2.2,3.2,0.8,3.3l10,0.4
	c3.3,0.1,6.2-1,8.5-3.2L297.4,278.4L297.4,278.4z M291.6,277.8c1.2-0.3,2.1-0.1,2.3,0.3c0.3,0.4,0,1-0.8,1.8l-14.4,12.8
	c-0.8,0.7-1.9,1.4-3.3,1.9c-1.4,0.5-2.5,0.7-3.4,0.7s-1.5-0.3-1.6-0.8c0-0.5,0.3-1.1,1.1-1.8l13.1-11.7c0.8-0.7,1.8-1.4,3.2-1.9
	C289,278.4,290.4,278,291.6,277.8L291.6,277.8z"
          />
          <path
            fill="none"
            d="M294,278.1c-0.3-0.4-1.1-0.5-2.3-0.3c-1.2,0.1-2.5,0.5-3.9,1.2c-1.4,0.5-2.5,1.2-3.2,1.9l-13.1,11.7
	c-0.8,0.7-1.1,1.2-1.1,1.8c0.1,0.5,0.7,0.8,1.6,0.8s2.1-0.3,3.4-0.7c1.4-0.5,2.5-1.2,3.3-1.9l14.4-12.8
	C294,279.1,294.2,278.5,294,278.1L294,278.1z M340.7,269.7l-2.8-0.4c-2.1-0.5-4-1.4-5.8-2.5l-2.1-1.5c-1-0.8-1.6-1.8-2.2-2.6
	c-0.4-0.7-0.7-1.4-1-2.2l-0.8,2.2l-1.1,2.8l-0.5,1.4c0.7,3.9,6.3,4.8,17.2,3L340.7,269.7L340.7,269.7z"
          />
          <path
            fill="none"
            d="M343.6,269.9h-1.8c-10.9,1.8-16.6,0.8-17.2-3l-0.5,1.2l-3.6,4.7c-1.8,2.6-1,4.8,2.3,6.6
	c5.4,1.6,10.6,1.6,15.7,0C342.7,277.7,344.3,274.5,343.6,269.9L343.6,269.9z M586.7,211.9c-1.9-7.2-11.3-10.7-28.1-10.7
	c-26.7-0.8-52-1.1-76.1-1.1l-6.6,56.4l109.8-4.5l3.9-27.9L586.7,211.9L586.7,211.9z"
          />
          <path
            fill="none"
            d="M597.8,254.3l0.1-0.1h1.2l-0.7-2.8l-12.9,0.6l-109.8,4.5l-14.6,0.5l-0.5,2.8 M472.1,200.2l0.5-2.8
	c-31.8,0.3-61.2,1.2-88.3,3c-0.3,0-0.5,0.1-0.8,0.3c-22,18.2-42.8,37.1-61.9,57.1l-5,5.2l-3.2,2.8h0.5l11.1-0.4l1.1-2.8l-5.6,0.3
	l6.5-6.7c0.5-2.3,2.1-4.5,4.7-6.3l0.5-0.4c1-0.6,1.9-1.1,2.9-1.5c15.7-15.5,32.3-30.4,49.8-44.8c23.9-1.5,49.7-2.5,77.2-2.9
	L472.1,200.2L472.1,200.2L472.1,200.2z"
          />
          <path fill="none" d="M472.1,200.2h-10l-15.8,57.5l15-0.7" />
          <path
            fill="none"
            d="M460.7,259.8l0.6-2.8l-15,0.7l-94.1,3.9c-0.3,1-0.5,1.9-1,2.8L460.7,259.8L460.7,259.8z M461.2,257.1l14.7-0.5
	l6.6-56.4h-10.3L461.2,257.1L461.2,257.1z M166.3,318.1c1-1,1.9-2.1,3-2.9c14.7-13.9,32.6-20.9,53.5-20.9c8.7,0,16.8,1.2,24.4,3.6
	c12.8-12.2,28.2-23.2,46.2-33l-48.1-2.5c-12.4,0-24.2,0.1-35.4,0.5c-33.7,2.6-59,8.3-76.1,16.9c-9.5,5.1-15.1,9.5-16.9,13.1
	c-1.1,2.2-0.7,3.7,1.2,4.4l0.8,0.1c2.5,0.5,4.1,2.6,5.1,6.2c1.5,7,2.3,13.1,2.3,18.3L166.3,318.1L166.3,318.1z M177.9,275.4l1.5-0.3
	c15-2.6,29.6-4.3,43.6-5.1c-14,0.8-28.5,2.5-43.6,5.1c4.7-0.7,7.8,0.1,9.2,2.5c1.6,2.9,0.6,6.5-3.3,10.7
	c-6.3,7.6-14.9,15.5-25.6,23.7c-3.9,2.9-8.8,4.3-15,4.1c-4,0-6.2-1.5-6.5-4.5c-0.8-3.6-0.6-8.5,1-14.9c1.2-4.1,3.6-7.4,6.9-9.8
	C152.1,282.8,162.7,278.9,177.9,275.4L177.9,275.4z"
          />
          <path
            fill="none"
            d="M179.5,275.1l-1.5,0.3c-15.3,3.6-25.9,7.4-31.6,11.6c-3.3,2.3-5.6,5.6-6.9,9.8c-1.5,6.5-1.8,11.4-1,14.9
	c0.3,3,2.5,4.5,6.5,4.5c6.2,0.1,11.1-1.2,15-4.1c10.7-8.3,19.1-16.1,25.6-23.7c3.9-4.3,5-7.8,3.3-10.7
	C187.4,275.2,184.2,274.4,179.5,275.1L179.5,275.1z M172.5,278.2c4.1-0.8,6.9-0.3,8.4,1.5c1.9,2.1,1.5,5-1.1,8.3
	c-5,6.5-11.4,12.9-19.7,19.4c-4,3.4-8.1,5.4-12.2,5.9c-4.1,0.8-6.7,0.3-7.8-1.8c-0.1-0.4-0.3-0.8-0.3-1.2c-0.3-1-0.4-2.2-0.4-3.7
	v-0.3c0-2.9,0.6-6.1,1.5-9.8c1.2-3.9,3.4-6.7,6.6-8.9C152.4,284.3,160.7,281.1,172.5,278.2L172.5,278.2z"
          />
          <path
            fill="none"
            d="M180.9,279.6c-1.5-1.8-4.4-2.2-8.4-1.5c-11.8,2.9-20.1,6.1-24.9,9.5c-3.2,2.2-5.4,5.2-6.6,8.9
	c-1,3.7-1.4,7-1.5,9.8v0.3c0,1.5,0.1,2.8,0.4,3.7c0,0.4,0.1,0.8,0.3,1.2c1.1,1.9,3.7,2.5,7.8,1.8c4-0.5,8.1-2.6,12.2-5.9
	c8.3-6.5,14.9-13.1,19.7-19.4C182.4,284.6,182.8,281.7,180.9,279.6L180.9,279.6z M356.2,204.4c5.6,0.6,7,2.3,4.1,5.2
	c-7.7,8.9-20.6,21-39.1,36.3c-8,7.6-15.1,11.4-21.6,11.4l-32.5-0.7l-13.3,5.8c6.5,0,13.1,0.1,19.9,0.4l29,0.1l70.2-62.6h-31.8
	l-5.2,4L356.2,204.4L356.2,204.4z"
          />
          <path
            fill="none"
            d="M321.6,257.9c19.3-19.9,39.9-38.9,61.9-57.1c0.3-0.1,0.5-0.3,0.8-0.3h-11.3l-70.2,62.6h13.8L321.6,257.9
	L321.6,257.9z"
          />
          <path
            fill="none"
            d="M313.4,265.9l3.2-2.8h-13.8l-29-0.1c-6.9-0.1-13.5-0.3-19.9-0.4l-8.7-0.1l48.1,2.5l14,0.7L313.4,265.9
	L313.4,265.9z"
          />
          <path
            fill="none"
            d="M313.4,265.9l-6.1-0.3l-7,4.8c-1.1,1-1.6,1.9-1.9,3.2v4.1c-2.3,4-8.7,10.5-19,19.4c-1.8,1.1-3.4,1.8-5.1,1.9
	h-0.1c-2.8-0.4-4.8-0.1-6.3,1l-0.3,0.3l-5.1,4.5c4.8,2.9,9.4,6.3,13.6,10.5l2.3,2.2c3.6,3.6,6.7,7.4,9.2,11.4
	c5.1,7.8,8.5,16.5,10,26c1.2,7,2.1,13.2,2.5,19l5.5-0.1c-2.6-15-4.4-34.3-5.4-57.6l-0.1-1v-0.3C300.4,286.6,304.7,270.3,313.4,265.9
	L313.4,265.9z"
          />
          <path
            fill="none"
            d="M298.4,277c-0.1,0.4-0.4,0.8-1,1.4l-16.2,15.7c-2.3,2.2-5.2,3.3-8.5,3.2l-10-0.4c-3-0.1-3.3-1.2-0.8-3.3
	l17.1-14.9c1.9-1.8,4.4-2.6,7.3-2.6l9.4-0.3c1.8,0,2.8,0.1,2.9,0.8v-3c0.3-1.2,1-2.2,1.9-3.2l7-4.8l-14-0.7
	c-18,9.8-33.4,20.8-46.2,33c5.4,1.6,10.5,4,15.4,6.9l5.1-4.5l0.3-0.3c1.5-1.1,3.6-1.5,6.3-1h0.1c1.6-0.1,3.4-0.8,5.1-1.9
	c10.5-8.9,16.8-15.4,19-19.4L298.4,277L298.4,277z M625.5,167.8c-0.3,0.1-0.4,0.3-0.4,0.4l-9.9,13.1v0.1c-0.4,1.1,0.4,1.8,2.2,1.9
	l9.2-14.7v-0.6c-0.1-0.3-0.3-0.4-0.4-0.4L625.5,167.8L625.5,167.8z"
          />
          <path
            fill="none"
            d="M617.4,183.3c-1.9-0.1-2.6-0.8-2.2-1.9v-0.1c-1.8-0.8-5-1-9.5-0.7c-3.2,0-5.1,1.6-5.9,5l19.8,1.9
	C619.2,184.7,618.3,183.3,617.4,183.3L617.4,183.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M267.1,256.6l32.5,0.7c6.5,0,13.8-3.9,21.6-11.4
	c18.3-15.3,31.4-27.4,39.1-36.3c2.8-3,1.5-4.7-4.1-5.2h-20.1 M615.2,181.3l9.9-13.1c0-0.3,0.1-0.4,0.4-0.4h0.6l0.4,0.4
	c0.1,0.1,0.1,0.4,0,0.6l-9.2,14.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617.4,183.3c-1.9-0.1-2.6-0.8-2.2-1.9v-0.1 M615.2,181.3
	c-1.8-0.8-5-1-9.5-0.7c-3.2,0-5.1,1.6-5.9,5 M446.8,182.5c-18.3,1-32.5,2.2-42.8,3.9c-3.3,0.4-6.3,1-8.9,1.4c-3,0.6-5.8,1.2-8.1,1.9
	c-0.3,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.1,0.6-0.1,0.7c0.1,0.3,0.3,0.4,0.5,0.6l0.7,0.1l0.7-0.3c6.9-1.5,15.5-2.8,26-3.6
	c14.4-1.4,32.5-2.1,53.8-2.3c33.8-1,66.6-0.6,98.1,1.1c13.5,0.6,28.5,1.6,45,3.7c3.6,0.6,6.7,1.1,9.6,1.8l0.7,0.3l0.7-0.1
	c0.3-0.1,0.4-0.3,0.5-0.6c0.1-0.3,0-0.6-0.1-0.7c-0.1-0.3-0.3-0.4-0.5-0.6c-2.3-0.7-5.1-1.4-8.1-1.9c-6.7-1.4-13.9-2.5-21.5-3.3
	 M599.8,185.5l-7.4-0.6 M585.6,252l3.8-27.9l-2.8-12.1c-1.9-7.2-11.3-10.7-28.1-10.7c-26.7-0.8-52-1.1-76.1-1.1 M587,206.3
	c1.2,1.4,1.9,3,2.3,5l9.2,40.2 M599.2,254.2h-1.4l-137.1,5.6 M598.5,251.4l0.7,2.8 M598.5,251.4l-12.9,0.6 M619.6,187.5l-19.8-1.9
	 M666.1,203c7.8-0.7,13.1-2.3,15.7-5.1c0.8-0.7,1-1.5,0.3-2.5l-0.1-0.1c-1-1.4-3.4-2.3-7.4-2.9l-34.4-2.2 M666.1,203l-8.7,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M657.4,203.4c-0.8-2.6-3.4-4.5-7.8-5.5c-6.3-2.1-9.5-4.7-9.4-7.7
	 M645.5,203.8h0.1l4.8,6.7l-1.7,2.2l16,27.5l3.4,1.1l4.1,7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M657.4,203.4l-11.8,0.4l-58.6,2.5 M691.9,250.3l-3.6-2.6 M704,263.5
	c-1.2-1.4-2.9-2.1-5.1-2.2h-4.8c-2.8,0-4.4,1.6-5.1,4.8c-0.5,4.4-0.4,10.6,0.3,18.7c0.1,9.5,1.7,16.1,4.5,19.8
	c1.2,1.2,3.3,1.9,6.3,2.1c4.4,0.3,7-1.4,8-4.5c0.3-2.3,0.4-5.6,0.3-9.6 M691.9,250.3c3,1.6,5.8,4,8.1,6.7l1.2,1.5
	c0.8,1.1,1.5,2.2,2.2,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M708.6,304.1l-0.1,0.8c-0.3,1-0.7,1.6-1.2,2.3c-1.7,2.1-4.4,2.9-8.4,2.8
	c-3.6-0.3-6.2-1.1-7.7-2.3l-0.3-0.4c-3.3-4.1-5-11.3-5.2-21.6c-1-8.9-1.1-16-0.4-20.8c0.8-3.4,2.8-5.2,6.1-5.2h5.9
	c2.8,0.1,4.7,1,6.2,2.5 M598.5,251.4l73.7-3l16.1-0.7 M688.4,247.7c-10.6-17.7-20.9-32.6-31-44.3 M599.2,254.2l92.7-3.9
	 M640.2,190.2c-6.3-1-13.2-1.9-20.5-2.8 M617.4,183.3c1,0,1.8,1.4,2.3,4.1 M592.3,185l-16-1.1c-23.9-1.4-51.5-2.1-82.4-2.2
	c-9.4,0-18.6,0-27.2,0.1l-19.7,0.6 M472.1,200.2l0.5-2.8 M472.1,200.2h-10 M460.7,259.8l0.5-2.8 M461.2,257.1l14.6-0.5 M475.8,256.5
	l6.7-56.4 M461.2,257.1l10.9-56.8 M482.6,200.1h-10.3 M472.7,197.5c27-0.1,55.6,0.1,85.8,1c14.6,0,24.1,2.6,28.3,7.8 M570.9,278.8
	h-0.5c-1.7,0-3,0.3-4.1,0.7c-1.1,0.4-1.7,1-1.7,1.5s0.5,1.1,1.7,1.5c1.1,0.4,2.5,0.7,4.1,0.7h0.5 M587.8,278
	c-0.3-0.5-0.8-1.2-1.4-1.7c-1.4-1.4-3-2.1-5-2.1h-3.7c-1.9,0-3.6,0.7-5.1,2.1c-0.8,0.8-1.4,1.6-1.7,2.6 M570.9,283.3
	c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7c1.9,0,3.6-0.7,5-2.1c1-1,1.7-2.1,1.9-3.2 M604.2,296.4c-13.1,3-24.8,9.2-35.1,18.7
	c-7.4,6.9-12.9,14.4-16.6,22.7c-4.3,10.2-6.7,20.6-7.6,31.5 M440.5,286.6h1.2c0.5,0.5,1.1,0.8,1.8,0.8h0.1c2.1,0,3.9-0.4,5.4-1
	c1.6-0.7,2.5-1.4,2.5-2.3c0-1-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1c-0.7,0-1.4,0.3-1.9,1l-1.8,0.1 M604.3,314.4
	c-8.7,3-16.5,8.4-23.4,15.8c-4.4,4.8-8.1,9.9-10.9,15.4c-1.1,2.2-2.1,4.5-2.9,6.9c-1,2.6-1.7,5.2-2.3,8c-0.7,2.8-1.1,5.6-1.4,8.7
	c-0.1,1.4-0.3,2.8-0.3,4c-0.3,5.4-0.3,10.3-0.1,14.9 M563.3,369l-18.6,0.4 M563.2,388.2v1.2 M563.2,389.4l0.7-0.1
	c0.5,0,1.1,0.3,1.7,0.7c0.4,0.4,0.7,1,0.7,1.6v1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M561.5,391.7v-0.1c0-0.5,0.3-1.1,0.7-1.6c0.3-0.3,0.5-0.4,1-0.5
	 M566.1,393.4v3.9c0,0.5-0.3,1.1-0.7,1.6c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.7-0.7c-0.4-0.4-0.7-1-0.7-1.6v-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M502.1,388.6c1.8,2.1,5.5,3,11,3.3l48.4-0.1 M563.2,388.2l-61.1,0.4
	 M544.8,369.5l-90.5,1.6 M454.2,371.1c-2.3-25.2-2.5-50.1-0.4-74.7c1-13.9,3.2-26.1,6.7-36.5 M639.3,266.7c-2.5-2.5-5.5-3.7-9.1-3.7
	s-6.6,1.2-9.2,3.7c-2.5,2.5-3.7,5.6-3.7,9.2s1.2,6.6,3.7,9.1s5.6,3.9,9.2,3.9s6.6-1.2,9.1-3.9c2.5-2.5,3.8-5.5,3.8-9.1
	S641.9,269.3,639.3,266.7L639.3,266.7z M675.7,341.8c-2.5-4.1-5.4-8-8.8-11.6c-3-3.3-6.3-6.1-9.6-8.5c-9.8-7-20.8-10.5-33.3-10.5
	c-6.9,0-13.5,1.1-19.5,3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M672.1,387.3c1.4-4.7,2.1-9.5,2.1-14.7c0-10.3-2.8-19.5-8.1-27.6
	c-1.9-2.8-4.1-5.5-6.6-8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1c-11,0-20.6,3-29.2,9.1c-2.3,1.6-4.5,3.6-6.6,5.6
	c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3c0,7.4,1.4,14.3,4.3,20.6 M678.2,315.4l2.2,2.1c3.6,3.6,6.7,7.4,9.5,11.4
	c2.6,4.1,5,8.4,6.6,12.8 M678.2,315.4l-0.1-0.1c-15-13.9-33.2-20.9-54.5-20.9c-6.7,0-13.3,0.7-19.4,2.2 M588.4,282.6h1.2
	c0.5,0.5,1.1,0.8,1.8,0.8h0.1c2.1,0,3.9-0.4,5.4-1c1.7-0.7,2.5-1.4,2.5-2.3s-0.8-1.6-2.5-2.3c-1.5-0.5-3.3-1-5.4-1h-0.1
	c-0.7,0-1.4,0.3-1.9,1l-1.8,0.1 M604.2,296.4l-0.6-26l-4.4-16.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M624.3,338.2v-0.1l-0.1-0.3l-0.4-0.3l-0.4,0.1l-0.1,0.1 M623.2,337.8
	l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6c0.1,3.9,0.4,6.3,0.8,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M634.3,339.6l-0.5,4.8c-0.8,6.7-1.8,11.7-2.6,14.6
	c-1.1,3.6-1.9,5.6-2.5,6.3c-0.4,0.6-1.1,1-1.8,1.1l-0.4,0.1c-0.5,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.5-3.2-0.7-6.7
	c-0.1-3,0.3-8,1.2-14.7l0.7-4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M635.4,339.6l-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0-0.4,0.1
	l-0.1,0.3 M604.2,296.4l0.1,18 M635.4,339.6v0.3l-0.5,5.1c-0.8,6.7-1.7,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7 M633.8,341l-1.2,2.6
	v0.4 M634.3,339.6l-0.5,1.4 M624.8,343v-0.5l-0.4-2.6 M633.8,341c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.5,344l-1.7,11.6c-0.5,2.2-1.4,3.2-2.6,2.9l-3.2-0.6
	c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4 M632.5,344c-2.5-0.7-5-1-7.7-1 M617.8,343.9V343c0-0.8,0.7-1.8,1.9-2.9 M621.8,365
	c-1.4-1.6-2.3-3.9-2.9-6.5c-0.8-4.1-1.2-9.1-1.1-14.7 M596.8,352.1h0.3l4.5,2.2c6.2,2.9,10.6,5.2,13.1,7c3.2,2.2,5.1,3.7,5.6,4.5
	 M614.8,366.3c-0.5,0.4-1,1-1.1,1.8c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1c0.7,0.1,1.4,0,1.9-0.4c0.5-0.4,1-1,1.1-1.8
	c0.1-0.7,0-1.4-0.5-1.9c-0.4-0.5-1-1-1.7-1.1C616,365.7,615.3,365.9,614.8,366.3L614.8,366.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.4,368.1c-0.1,0.5,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7
	c0.5,0.1,1,0,1.4-0.3c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8c-0.6-0.1-1,0-1.4,0.4
	C614.8,367.2,614.5,367.7,614.4,368.1L614.4,368.1z M620.1,365.9l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8c-0.1,0.8-0.5,1.6-1.1,2.3
	l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1 M620.1,365.9c-0.7-2.1-1.9-4-3.9-5.9c-3-2.9-6.9-5.9-11.8-8.9 M596.6,363.4l10.6,5
	c2.2,0.7,3.4,0.6,3.9-0.5l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M596.3,353.2l4.4,2.1c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3
	c0.4,0.5,0.5,1.2,0.5,2.1v0.4c-0.1,0.5-0.4,1.1-0.8,1.6c-0.5,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8
	l-4.3-2.2 M622.6,380.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3l-0.1-0.4c-0.4-0.8-1.1-1.4-1.8-1.8c-1-0.6-1.8-0.7-2.6-0.6l-0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M615.9,378.1c-0.3,0.7-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5
	s1.4,0.4,2.1,0.1c0.7-0.3,1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5s-1.4-0.4-2.1-0.1
	S616.1,377.4,615.9,378.1L615.9,378.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M597.6,393.5l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7
	c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3c0.5,0.3,1,0.7,1.2,1.2c0.4,0.8,0.5,1.7,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-5,6.3-9.6,11.1
	l-3.3,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617.7,377.4c-0.5,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5
	c0.1,0.5,0.5,0.8,1,1.1c0.5,0.3,1,0.3,1.5,0.1c0.5-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4c-0.1-0.5-0.4-0.8-1-1.1
	C618.6,377.3,618.2,377.3,617.7,377.4L617.7,377.4z M617,374.8c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5 M595.2,370.1
	c5.4,1.9,10.2,3,14.4,3.6c2.6,0.3,5,0,7-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M616.7,373c-1,0.1-3.3-0.4-7.2-1.5c-2.9-0.8-7.4-2.8-13.6-5.8l-4.5-2.2
	l-0.1-0.1 M631,367.4c0.8-0.7,3-1.6,6.6-3c3-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M649.8,357.1c-5.5,1.6-10,3.6-13.8,5.6c-2.3,1.4-4,3-5.2,4.7 M631,367.4
	l-0.4,0.4c-0.5,0.5-0.8,1.2-1,2.1v0.4c-0.1,1,0.1,1.8,0.4,2.5c0.4,1,1.1,1.6,1.8,2.1l0.5,0.3 M627.6,361.1c-0.5-0.4-1.2-0.5-1.9-0.4
	c-0.7,0.1-1.4,0.4-1.8,1.1c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.5,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1
	c0.4-0.5,0.5-1.2,0.5-1.9C628.5,362.2,628.1,361.5,627.6,361.1L627.6,361.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.2,361.7c-0.4-0.3-0.8-0.4-1.4-0.4c-0.5,0.1-1,0.4-1.2,0.8
	s-0.4,1-0.3,1.4c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3c0.5-0.1,1-0.3,1.2-0.7s0.4-1,0.4-1.5
	C627.8,362.4,627.6,362,627.2,361.7L627.2,361.7z M629.6,366.1l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.7,0-2.5-0.3l-0.4-0.1
	c-0.7-0.4-1.4-1-1.7-1.6l-0.4-0.5 M627.3,370c-0.7-0.7-1.5-1.1-2.6-1.1c-1.1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
	s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1s1.1-1.5,1.1-2.6C628.3,371.5,628,370.7,627.3,370L627.3,370z
	 M631.4,376.9c-0.1-0.1-0.4-0.1-0.5-0.3c-0.7-0.3-1.5-0.4-2.3-0.3h-0.4c-0.8,0.1-1.7,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5
	 M635.8,371.5c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.4-0.7-0.8-1.1-1c-0.5-0.1-1-0.1-1.5,0.1c-0.4,0.3-0.8,0.5-1,1.1s-0.1,1,0.1,1.5
	c0.3,0.5,0.6,0.8,1.1,1l1.5-0.1C635.3,372.3,635.5,371.9,635.8,371.5L635.8,371.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M636.5,371.6c0.3-0.7,0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4
	s-1.4-0.1-2.1,0.1s-1.1,0.8-1.4,1.5s-0.1,1.4,0.1,2.1c0.3,0.7,0.8,1.1,1.5,1.4s1.4,0.1,2.1-0.1C635.8,372.9,636.2,372.3,636.5,371.6
	L636.5,371.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M659.1,371.5l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1c-3.6,0.1-5.9,0-6.7-0.4
	c-0.7-0.3-1.2-0.7-1.7-1.4l-0.1-0.4c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2c0.7-0.5,2.8-1.5,6.2-2.8
	c2.9-1.1,7.7-2.2,14.4-3.3l4.8-0.8 M636.4,404.8l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.6-1.8-5.8-1.8-6.6
	c0-0.7,0.3-1.4,0.7-1.9l0.3-0.3c0.4-0.4,1-0.7,1.7-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1c1.9,2.3,4.4,6.6,7.6,12.7
	l2.2,4.4 M629.6,366.1c1.7-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14 M596.8,352.1h-0.3c-0.1,0.1-0.3,0.1-0.3,0.3
	c0,0.1-0.1,0.3,0,0.4l0.1,0.3 M596.3,353.2l1.1,1 M604.6,351l-0.7-0.4c-0.7-0.5-1.8-0.4-3.6,0.1 M600.1,356.4l-0.4-0.3l-2.2-1.9
	 M619.9,340.1c-7,1-13.1,4-18.3,9.2l-1.2,1.2 M600.3,350.7c-1,0.3-2.2,0.8-3.6,1.5 M593.3,362.7l2.9,0.4l0.4,0.3 M591.9,362.4h-0.4
	c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1 M593.3,362.7l-1.4-0.3 M591.2,363.4c0.4,1.5,0.7,2.8,1.1,3.7 M595.2,370.1
	l-0.7-0.3c-0.8-0.3-1.5-1.2-2.2-2.9 M598,385.1c-1.8-3.9-2.8-8-2.8-12.5l0.1-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M592.3,367c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6
	 M600.1,356.4c-1.5,2.2-2.6,4.5-3.4,7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M588.2,372.6c0,10,3.6,18.6,10.7,25.7c7.2,7.2,15.7,10.6,25.7,10.6
	c10,0,18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7c0-10-3.6-18.6-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6
	c-10,0-18.6,3.6-25.7,10.6C591.8,353.9,588.2,362.6,588.2,372.6z M597.4,354.2c-1.8,2.6-3.2,5.4-4.1,8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M598,385.1l-0.5,0.4c-0.7,0.5-1,1.8-1,3.7 M596.6,392.8l0.1-0.1l3.3-3.7
	c4.7-5.1,8.3-8.5,10.7-10.3c3.2-2.3,5.1-3.7,6.1-3.9 M596.3,389.3c0,1,0.1,2.2,0.3,3.6 M596.6,392.8v0.3c0,0.1,0.1,0.3,0.3,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h0.3 M601.7,391c0.7,0.8,1.4,1.6,2.2,2.3c1.1,1.1,2.2,2.1,3.4,3 M601.7,391l-0.3,0.3l-2.5,1.5 M599,392.8
	c0.8,1,1.7,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6 M597.6,393.5l1.4-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.4,393.2c1.9,4.1,4.4,8.1,7.4,11.7c1,1.1,2.1,2.2,3,3.3
	c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12c2.6-2.6,4.8-5.2,6.6-8
	c2.8-4,4.7-8.4,6.1-12.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M605.7,401.5l0.3-0.1l3.6-3.6c4.7-5,8-8.7,9.6-11.1
	c2.2-3.2,3.4-5.4,3.6-6.3 M605,400.5c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3 M605.7,399.3l-0.7,1.2
	 M631,380.7c0-0.5-0.1-1-0.5-1.4s-0.8-0.5-1.4-0.5c-0.6,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4c0,0.5,0.1,1,0.5,1.4
	c0.4,0.4,0.8,0.5,1.4,0.5c0.5,0,1-0.1,1.4-0.5C630.9,381.7,631,381.3,631,380.7L631,380.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M631,378.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8
	c-0.5,0.5-0.8,1.2-0.8,1.9s0.3,1.4,0.8,1.9c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9
	C631.8,380,631.6,379.4,631,378.9L631,378.9z M642,396.4l-5.6-10c-1.4-1.9-2.5-2.5-3.4-1.8l-2.9,1.5c-1.1,0.4-1.2,1.8-0.4,3.9
	l5.5,10.3 M635.4,405.5l-0.1-0.1l-2.5-4.4c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.7-1.9-6.1-1.8-7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M624.5,380.6c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2
	 M607.3,396.4l8-8.5c1.4-1.9,1.7-3.2,0.7-3.9l-2.2-2.2c-0.7-1-2.1-0.7-3.9,0.8l-8.1,8.4 M605.7,399.3l1.2-2.6l0.4-0.3 M609.4,401.6
	c1.9-0.1,3.2-0.5,3.6-1.2l0.4-0.5 M635.1,400.1l0.3,0.4l0.7,2.9 M636.4,404.8l-0.3-1.4 M635.4,405.5c0,0,0.1,0.1,0.3,0.1l0.4-0.1
	l0.3-0.3v-0.3 M631.7,404.7c1,0.3,2.2,0.7,3.7,1 M628.4,401.8l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1 M628.4,401.8l-3.7,0.1
	c-4.1,0-7.8-0.7-11.3-2.2 M609.4,401.6c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M613.4,399.9c3.4-4.5,6.1-8.8,7.8-12.7c1.1-2.5,1.5-4.8,1.5-6.9
	 M646.4,399.6v-0.3l-2.3-4.5c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M647.1,391.6c-3.3-4.7-6.5-8.4-9.5-11.3c-2.1-1.8-4.1-2.9-6.1-3.4
	 M604.6,351c3.9-3.7,8.4-6.1,13.3-7.2 M605.7,401.5c1.5,0.1,2.8,0.1,3.7,0.1 M696.5,341.8c1.4,3.7,2.5,7.6,3.3,11.6
	c1.8,6.1,5.1,9.2,9.8,9.5h12.5c2.3,0,3.7-1.4,4.3-3.9c2.5-5.9,4.8-7.8,6.9-5.9l1.4,5.2 M675.7,341.8l0.5,0.8
	c5.4,9.4,8.3,19.8,8.5,31.5v9.1 M696.5,341.8h-20.8 M639,346.8l0.1-0.8c0.3-0.8-0.1-1.9-1.2-3.4 M654.1,357.9c-1.5-1-2.6-1.2-3.4-1
	l-0.8,0.3 M639,346.8c2.3,1.4,4.5,3,6.5,5c1.7,1.6,3.2,3.6,4.4,5.4 M654.1,357.9c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9
	 M652.9,363.5l0.4-0.1l2.5-1.2 M654.1,371.1l-11.4,1.9c-2.3,0.1-3.6-0.4-3.6-1.5l-0.5-3.2c-0.3-1.1,0.5-2.1,2.8-2.8l11.4-2.2
	 M657.1,361.5h0.1l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4h-0.1 M655.8,362.2l1.4-0.7 M659.3,372.6l-0.3,0.1l-5.1,1
	c-6.7,1.2-11.6,1.9-14.6,2.1c-3.9,0.1-6.3,0-7.3-0.4 M659.3,372.6l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4l-0.3-0.1
	 M657.4,371.4l-2.8-0.3h-0.6 M653.7,378.1h0.8c0.8,0,1.8-0.7,2.9-2.1 M659.1,371.5l-1.7-0.1 M657.3,375.9c0.7-0.8,1.4-1.9,2.1-3.3
	 M657.4,371.4c-0.1-3.2-0.7-6.3-1.7-9.2 M652.9,363.5c0.8,2.5,1.2,5,1.4,7.7 M657.3,360.2c-1.2-1-2.2-1.8-3.2-2.3 M644.2,398.9
	l-1.9-1.9l-0.3-0.5 M647.8,395.9c0.4-1.6,0.4-2.9-0.1-3.4l-0.5-0.7 M647.8,395.9c5.6-5.6,8.8-12.2,9.5-19.9 M645.3,400v0.1l0.3,0.1
	h0.5l0.3-0.4v-0.3 M644.2,398.9l1.1,1.1 M646.4,399.6c0.5-1.5,1.1-2.8,1.4-3.9 M684.7,383.2v3.4 M684.7,386.6l-12.5,0.7
	 M684.7,386.6l7.4-0.4l44-8.4c2.6-0.6,4-1.4,3.9-2.3c-0.4-1.5-2.3-1.9-6.2-1.5l-1.4,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.2,375.2c1.8,1.2,4,2.1,6.6,2.6c4.1,0.5,9.1,0.7,14.9,0.3
	 M653.7,378.1c-1,5.1-3,9.6-6.6,13.6 M635.1,400.1c2.5-1,4.7-2.2,6.9-3.7 M636.1,403.4c2.9-1.1,5.6-2.6,8.3-4.5 M637.9,342.5
	c-0.5-0.8-1.5-1.9-2.5-3 M624.4,339.9l-0.1-1.6 M619.9,340.1c0.8-0.7,1.9-1.5,3.3-2.3 M570.9,283.3l17.5-0.7 M587.8,278l-16.9,0.8
	 M566.1,393.4l11.3-0.1 M585.6,252l-109.8,4.5 M703.6,249.2l-0.1,0.1c-0.3,0.3-0.5,0.4-1,0.4l-10.7,0.4 M703.6,249.2
	c0.1-0.3,0.3-0.6,0.3-0.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.7-0.4-1-0.4 M719.3,324.1c5.5-23,5.5-42.8-0.3-59c-0.5-1.1-1.1-2.1-1.8-3
	l-2.2-3.2c-3.2-4.3-7-7.4-11.4-9.5 M703.4,262.1c0.7,0.7,1.1,1.5,1.5,2.5v0.1 M704.9,264.8l-0.8-1.2 M703.4,262.1l0.7,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M708.6,292.4l-0.3-5.1c-0.8-11.1-1.9-18.7-3.4-22.7 M708.6,304.1v11.3
	 M720,324.6l-0.7-0.5 M719.3,324.1l-10.7-8.7 M719.3,324.1c3.4,3.9,8.3,5.6,14.6,5.4c2.2-0.1,3.6,0.5,4,1.9l1.1,5.1
	c-1.9,0.7-2.9,2.1-2.9,4.1c0.1,7.8-0.4,13.8-1.4,17.6 M708.6,292.4c0.3,4.8,0.3,8.7-0.1,11.6 M732.5,374c0.7-5.9,1.9-9.2,3.7-10.2
	c1.8-1.4,1.2-3.3-1.5-5.5 M734.7,358.3c-2.3-1.1-4.4,1.1-6.3,6.7c0,0.7-0.1,1.2-0.4,1.9 M732.5,374l-2.9,0.3 M729.6,374.3l-9.2,1.2
	 M729.6,374.3c0.1-4.3-0.4-6.7-1.5-7.6 M728.1,366.8c-1,3.4-3.4,6.3-7.6,8.7 M720.4,375.5c-7.2,4.1-19.1,6.7-35.8,7.7 M688.4,247.7
	l14.2-0.6 M708.6,315.4h-30.4 M702.5,247.2c-12.8-17.7-24.9-32.5-36.5-44.2 M179.5,275.1l-1.5,0.3c-15.3,3.6-25.9,7.4-31.6,11.6
	c-3.3,2.3-5.6,5.6-6.9,9.8c-1.5,6.5-1.8,11.4-1,14.9c0.3,3,2.5,4.5,6.5,4.5c6.2,0.1,11.1-1.2,15-4.1c10.7-8.3,19.1-16.1,25.6-23.7
	c3.9-4.3,5-7.8,3.3-10.7C187.4,275.2,184.2,274.4,179.5,275.1L179.5,275.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M172.5,278.2c-11.8,2.9-20.1,6.1-24.9,9.5c-3.2,2.2-5.4,5.2-6.6,8.9
	c-1,3.7-1.4,7-1.5,9.8v0.3c0,1.5,0.1,2.8,0.4,3.7c0,0.4,0.1,0.8,0.3,1.2c1.1,1.9,3.7,2.5,7.8,1.8c4-0.6,8.1-2.6,12.2-5.9
	c8.3-6.5,14.9-13.1,19.7-19.4c2.6-3.4,3-6.2,1.1-8.3C179.4,277.8,176.5,277.4,172.5,278.2L172.5,278.2z M247.1,297.9
	c-7.6-2.5-15.7-3.6-24.4-3.6c-20.9,0-38.7,7-53.5,20.9c-1.1,1-2.1,1.9-3,2.9 M269.9,390.5c2.1-5.5,3-11.4,3-17.9
	c0-10.3-2.8-19.5-8.1-27.6c-1.9-2.8-4.1-5.5-6.6-8c-2.1-2.1-4.3-4-6.6-5.6c-8.4-6.1-18.2-9.1-29.2-9.1s-20.6,3-29.2,9.1
	c-2.3,1.6-4.5,3.6-6.6,5.6c-1.1,1.1-2.2,2.2-3,3.3c-7.8,9.2-11.7,19.9-11.7,32.3c0,7,1.2,13.6,3.9,19.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M245.1,262.4c-12.4,0-24.2,0.1-35.4,0.5c-33.7,2.6-59,8.3-76.1,16.9
	c-9.5,5.1-15.1,9.5-16.9,13.1c-1.1,2.2-0.7,3.7,1.2,4.4l0.8,0.1 M117.6,323.8h-1.4c-2.1,0-2.8,1.2-2.2,3.7c0.8,4-0.4,6.3-3.7,7
	c-3.9,0-6.1,2.2-6.3,6.3v12.5c0,2.1,1,3.4,2.6,4.1 M117.6,323.8h2.9c1.2,0,2.1,0.7,2.6,2.2c0.3,0.7,0.7,1.2,1.2,1.6
	c0.7,0,1.2-0.3,1.7-0.7 M126.4,321.9v2.5c0.1,1.1,0,1.9-0.4,2.5 M125.9,326.8l-1.1-2.5c-0.8-1.8-2.3-2.6-4.3-2.6 M120.5,321.9
	c-2.3,0.1-3.3,0.8-2.9,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M126.4,321.9c0-5.1-0.8-11.3-2.3-18.3c-1-3.6-2.6-5.6-5.1-6.2 M119,297.4
	c-0.6,9.9,0,18,1.5,24.4 M111.7,380.7l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-13.2c0-2.9-1.2-4.5-3.6-4.7h-3.2 M108.8,358
	c-0.8-0.1-1.5-0.3-2.1-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M109.2,381.3l1.7-0.3l1.9-0.5l1-0.4c1.9-1,2.8-2.5,2.8-4.3v-5
	c0.1-1.9,1-3,2.5-3.3l21.9-1.9c4.3,0,6.5-1.9,6.5-5.6c0.1-2.5,0.6-5,1-7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M111.7,380.7c-1.4-4.1-2.3-8.3-2.9-12.4c-0.4-3.4-0.4-6.9-0.1-10.3
	 M109.8,381.3l1.9-0.6 M109.8,381.3h-0.6 M109.2,381.3l-2.3,0.3c-1.2,0.5-1,1.9,0.8,4.3c1.7,1.6,5.2,2.9,10.9,3.7
	c7.2,1,16.1,1.6,26.7,1.9 M106.6,357.5c-0.8,7.7,0.1,15.7,3,23.8 M284.9,374.1c-0.3-11.7-3.2-22.1-8.7-31.5
	c-2.6-4.4-5.8-8.5-9.5-12.5c-3.2-3.3-6.5-6.1-9.9-8.5c-9.9-7-21.3-10.5-34-10.5c-17.2,0-31.8,6.3-43.9,19c-4.5,4.8-8.3,9.9-11,15.4
	c-1.1,2.2-2.2,4.5-3,6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.1,353.2l4.4,2.1c6.2,2.9,10.6,5.2,13.1,7c3,2.1,4.8,3.6,5.4,4.3
	c0.4,0.5,0.6,1.2,0.6,2.1v0.4c-0.1,0.5-0.4,1.1-0.8,1.6c-0.6,0.7-1.4,1.1-2.2,1.1c-1,0.1-3.2-0.4-6.6-1.4c-2.9-0.8-7.6-2.8-13.6-5.8
	l-4.3-2.2 M190.7,362.4h-0.4c-0.1,0-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M190,363.4l0.1,0.1l4.5,2.2c6.2,3,10.7,5,13.6,5.8
	c3.7,1.1,6.2,1.5,7.2,1.5 M190.7,362.4l1.5,0.3 M190,363.4c0.4,1.5,0.7,2.8,1.1,3.7 M194.1,370.1l-0.7-0.3c-0.8-0.3-1.5-1.2-2.2-2.9
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M191.1,367c-0.3,1.8-0.4,3.6-0.4,5.5c0,6.2,1.5,11.7,4.4,16.6
	 M166.3,318.1c-6.9,7.2-11.8,15-15,23.7c-1.2,3.4-2.2,7-2.9,10.6 M145.3,391.6c0.3,4.4,1.9,7.2,5.2,8.5l7.7,2.6
	c2.3,0.4,3.6,0.1,3.6-1v-0.3c-0.3-3-0.6-6.2-0.7-9.5 M160.8,391.9c-5.6,0-10.9-0.1-15.7-0.3 M175.7,392.3c1.9,4.5,4.5,8.8,7.8,12.8
	c1,1.1,2.1,2.2,3,3.3c5.5,5.5,11.7,9.5,18.6,12c5.4,1.9,11,2.9,17.2,2.9c6.1,0,11.8-1,17.2-2.9c6.9-2.5,13.1-6.5,18.6-12
	c2.6-2.6,4.8-5.2,6.6-8c2.1-3.2,3.7-6.5,5.1-9.9 M164.9,352.4c-2.3,6.5-3.7,13.3-4,20.8c-0.3,6.7-0.3,12.9,0,18.6 M148.3,352.4h16.6
	 M160.8,391.9l15,0.4 M166.3,318.1l-39.9,3.7 M341.3,200.4l-5.2,4l-68.9,52.3l-13.3,5.9 M335.1,248c-1,0.4-1.9,1-2.9,1.5l-0.5,0.4
	c-2.6,1.8-4.1,3.9-4.7,6.3 M327,256.2c-0.1,0.7-0.3,1.2-0.3,1.9c0,0.8,0.1,1.5,0.3,2.3 M352.2,261.6c0.3-1.1,0.4-2.2,0.4-3.4
	c0-3.2-0.8-5.9-2.6-8.3c-1.8-2.3-3.9-3.4-6.5-3.4H342 M342,246.6l-1.2,0.1c-1.9,0.3-3.9,0.7-5.5,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M342,269.9c2.2-0.3,4-1.4,5.5-3.4c0.4-0.5,0.8-1.2,1.2-1.9
	c0.4-0.8,0.8-1.8,1.1-2.8c0.3-1.1,0.4-2.3,0.4-3.6c0-3.2-0.8-5.9-2.6-8.3c-1.5-2.1-3.4-3.2-5.5-3.3 M373.1,200.4h-31.8 M316.7,263.1
	l5.1-5.2c19.3-19.9,39.9-38.9,61.9-57.1c0.3-0.1,0.5-0.3,0.8-0.3 M446.8,182.5c-19,0.4-36.2,1.2-51.6,2.6l-18.7,1.9
	c-15.7,2.6-27.5,7-35.4,13.5 M384.4,200.4h-11.3 M446.3,257.7l-94.1,3.9 M462.1,200.2c-27.5,0.4-53.2,1.2-77.2,2.9
	c-17.5,14.4-34.1,29.4-49.8,44.8 M325.2,265.5l-11.1,0.4h-0.7 M313.4,265.9l3.3-2.8 M307.3,265.6l6.1,0.3 M294,278.1
	c0.3,0.4,0,1-0.8,1.8l-14.4,12.8c-0.8,0.7-1.9,1.4-3.3,1.9c-1.4,0.5-2.5,0.7-3.4,0.7s-1.5-0.3-1.6-0.8c0-0.5,0.3-1.1,1.1-1.8
	l13.1-11.7c0.8-0.7,1.8-1.4,3.2-1.9c1.4-0.7,2.8-1.1,3.9-1.2C292.9,277.6,293.7,277.7,294,278.1L294,278.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298.4,277c-0.1,0.4-0.4,0.8-1,1.4l-16.2,15.7c-2.3,2.2-5.2,3.3-8.5,3.2
	l-10-0.4c-3-0.1-3.3-1.2-0.8-3.3l17.1-14.9c1.9-1.8,4.4-2.6,7.3-2.6l9.4-0.3c1.8,0,2.8,0.1,2.9,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.5,304.8l5.1-4.5l0.3-0.3c1.5-1.1,3.6-1.5,6.3-1h0.1
	c1.6-0.1,3.4-0.8,5.1-1.9c10.5-8.9,16.8-15.4,19-19.4V277 M298.4,277v-0.4 M298.4,276.6v-3c0.3-1.2,1-2.2,1.9-3.2l7-4.8
	 M293.3,264.9l14,0.7 M316.7,263.1l-13.8-0.1 M262.5,304.8c4.8,2.9,9.4,6.3,13.6,10.5l2.3,2.2c3.6,3.6,6.7,7.4,9.2,11.4
	c5.1,7.8,8.5,16.5,10,26c1.2,7,2.1,13.2,2.5,19 M313.4,265.9c-8.7,4.4-13.1,20.8-13.1,49.1v0.3l0.1,1c1.1,23.5,2.8,42.6,5.4,57.6
	 M302.9,263l-29-0.1c-6.9-0.1-13.5-0.3-19.9-0.4 M253.8,262.6l-8.7-0.1 M223,338.2v-0.1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.3
	s-0.4,0-0.6,0.1l-0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M221.9,337.8l-0.1,0.3l-0.7,5.1c-1,6.7-1.4,11.6-1.2,14.6
	c0.1,3.9,0.4,6.3,0.8,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M233,339.6l-0.6,4.8c-0.8,6.7-1.8,11.7-2.6,14.6c-1.1,3.6-1.9,5.6-2.5,6.3
	c-0.4,0.6-1.1,1-1.8,1.1l-0.4,0.1c-0.6,0-1.2,0-1.8-0.3c-0.8-0.4-1.5-1-1.8-1.8c-0.4-0.8-0.6-3.2-0.7-6.7c-0.1-3,0.3-8,1.2-14.7
	l0.7-4.8 M234.3,339.6l-0.1-0.1l-0.4-0.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0.1-0.1,0.3 M248.7,357.1c-1.2-1.9-2.8-3.7-4.4-5.4
	c-2.1-2.1-4.1-3.7-6.5-5 M252.8,357.9c-1.5-1-2.6-1.2-3.4-1l-0.8,0.3 M252.8,357.9c-1.5-3-3.6-5.9-6.2-8.5c-3-3-6.3-5.2-9.9-6.9
	 M234.3,339.6v0.3l-0.6,5.1c-0.8,6.7-1.6,11.6-2.6,14.6c-1.1,3.7-2.1,6.1-2.6,6.7 M237.7,346.8l0.1-0.8c0.3-0.8-0.1-1.9-1.2-3.4
	 M236.7,342.5c-0.6-0.8-1.5-1.9-2.5-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M232.5,341l-1.1,2.6l-0.1,0.4 M233,339.6l-0.6,1.4 M231.2,344l-1.6,11.6
	c-0.6,2.2-1.4,3.2-2.6,2.9l-3.2-0.6c-1.1,0-1.8-1.2-1.8-3.4l1.4-11.4 M257.8,371.5l-5,1c-6.7,1.2-11.6,1.9-14.6,2.1
	c-3.6,0.1-5.9,0-6.7-0.4c-0.7-0.3-1.2-0.7-1.6-1.4l-0.1-0.4c-0.3-0.5-0.3-1.1-0.3-1.8c0.1-1,0.4-1.6,1.1-2.2
	c0.7-0.5,2.8-1.5,6.2-2.8c2.9-1.1,7.7-2.2,14.4-3.3l4.8-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M252.8,371.1l-11.4,1.9c-2.3,0.1-3.6-0.4-3.6-1.5l-0.6-3.2
	c-0.3-1.1,0.6-2.1,2.8-2.8l11.4-2.2 M225.9,361.7c-0.4-0.3-0.8-0.4-1.4-0.4c-0.6,0.1-1,0.4-1.2,0.8s-0.4,1-0.3,1.4
	c0.1,0.5,0.3,1,0.8,1.2c0.4,0.3,1,0.4,1.4,0.3c0.6-0.1,1-0.3,1.2-0.7c0.3-0.4,0.4-1,0.4-1.5C226.6,362.4,226.3,362,225.9,361.7
	L225.9,361.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226.3,361.1c-0.6-0.4-1.2-0.5-1.9-0.4c-0.7,0.1-1.4,0.4-1.8,1.1
	c-0.4,0.5-0.6,1.2-0.4,1.9c0.1,0.7,0.4,1.2,1.1,1.8c0.6,0.4,1.2,0.5,1.9,0.4c0.7-0.1,1.4-0.4,1.8-1.1c0.4-0.5,0.6-1.2,0.6-1.9
	C227.3,362.2,226.8,361.5,226.3,361.1L226.3,361.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M228.4,366.1l-0.4,0.4c-0.7,0.5-1.5,1-2.5,1.1c-0.8,0.1-1.6,0-2.5-0.3
	l-0.4-0.1c-0.7-0.4-1.4-1-1.6-1.6l-0.3-0.5 M228.4,366.1c1.6-1.2,3.2-3,4.4-5.5c1.9-3.7,3.4-8.4,4.8-14 M231.1,375.2l-0.6-0.3
	c-0.8-0.4-1.4-1.1-1.8-2.1c-0.4-0.7-0.6-1.5-0.4-2.5V370c0.1-0.8,0.6-1.5,1-2.1l0.4-0.4 M231.8,369.2c-0.4,0.3-0.8,0.5-1,1.1
	c-0.1,0.5-0.1,1,0.1,1.5c0.3,0.5,0.6,0.8,1.1,1l1.5-0.1c0.4-0.3,0.8-0.7,1-1.1c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.4-0.7-0.8-1.1-1
	C232.8,368.9,232.3,368.9,231.8,369.2L231.8,369.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.1,370c-0.3,0.7-0.1,1.4,0.1,2.1c0.3,0.7,0.8,1.1,1.5,1.4
	s1.4,0.1,2.1-0.1c0.7-0.3,1.1-0.8,1.4-1.5s0.1-1.4-0.1-2.1c-0.3-0.7-0.8-1.1-1.5-1.4c-0.7-0.3-1.4-0.1-2.1,0.1
	C230.8,368.9,230.4,369.3,230.1,370L230.1,370z M226,370c-0.7-0.7-1.5-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
	s0.4,1.8,1.1,2.6c0.7,0.7,1.5,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1s1.1-1.5,1.1-2.6C227.1,371.5,226.7,370.7,226,370L226,370z
	 M230.3,376.9c-0.1-0.1-0.4-0.1-0.6-0.3c-0.7-0.3-1.5-0.4-2.3-0.3H227c-0.8,0.1-1.6,0.5-2.2,1.2c-0.8,0.7-1.2,1.5-1.4,2.3v0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235.1,404.8l-2.3-4.3c-3.3-6.1-5.5-10.5-6.5-13.3
	c-1.2-3.6-1.8-5.8-1.8-6.6c0-0.7,0.3-1.4,0.7-1.9l0.3-0.3c0.4-0.4,1-0.7,1.6-0.8c1-0.1,1.8-0.1,2.5,0.4c0.8,0.4,2.3,2.2,4.5,5.1
	c1.9,2.3,4.4,6.6,7.6,12.7l2.2,4.4 M229.7,367.4c1.2-1.8,2.9-3.3,5.2-4.7c3.7-2.1,8.3-3.9,13.8-5.6 M231.1,375.2
	c1.8,1.2,4,2.1,6.6,2.6c4.1,0.5,9.1,0.7,14.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M258.2,372.6l-0.3,0.1l-5.1,1c-6.7,1.2-11.6,1.9-14.6,2.1
	c-3.9,0.1-6.3,0-7.3-0.4 M229.7,367.4c0.8-0.7,3-1.6,6.6-3c2.9-1.1,7.7-2.2,14.3-3.3l5.1-0.8h0.3 M256,361.5h0.1l0.1-0.1
	c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4H256 M254.6,362.2l1.4-0.7 M251.6,363.5l0.6-0.1l2.5-1.2 M256.1,371.4l-2.8-0.3h-0.6
	 M252.4,378.1h0.8c0.8,0,1.8-0.7,2.9-2.1 M251.6,363.5c0.8,2.5,1.2,5,1.4,7.7 M256.1,371.4c-0.1-3.2-0.7-6.3-1.6-9.2 M256,360.2
	c-1.2-1-2.2-1.8-3.2-2.3 M223.5,343l0.1-0.5l-0.4-2.6 M232.5,341c-2.9-0.8-5.9-1.2-9.1-1.2h-0.3 M216.7,343.9V343
	c0-0.8,0.7-1.8,1.9-2.9 M220.5,365c-1.4-1.6-2.3-3.9-2.9-6.5c-0.8-4.1-1.2-9.1-1.1-14.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M218.6,340.1c-7,1-13.1,4-18.3,9.2l-1.2,1.2 M195.5,352.1h0.3l4.5,2.2
	c6.2,2.9,10.6,5.2,13.1,7c3.2,2.2,5.1,3.7,5.6,4.5 M195.5,352.1h-0.3c-0.1,0.1-0.3,0.1-0.3,0.3c0,0.1-0.1,0.3,0,0.4l0.1,0.3
	 M198.8,356.4l-0.4-0.3l-2.1-1.9 M199.1,350.7c-1,0.3-2.2,0.8-3.6,1.5 M196.3,354.2l-1.2-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M203.3,351l-0.7-0.4c-0.7-0.5-1.8-0.4-3.6,0.1 M192.2,362.7l2.8,0.4
	l0.4,0.3 M196.7,385.1c-1.8-3.9-2.8-8-2.8-12.5l0.1-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M215.4,373c-1.9,0.7-4.3,1-7,0.8c-4.3-0.5-9.1-1.6-14.4-3.6 M195.3,363.4
	l10.6,5c2.2,0.7,3.4,0.6,3.9-0.5l1.5-2.9c0.7-1,0-2.1-2.1-3.3l-10.5-5.1 M219,365.9l0.3,0.5c0.4,0.8,0.6,1.6,0.3,2.8
	c-0.1,0.8-0.6,1.6-1.1,2.3l-0.3,0.3c-0.7,0.5-1.4,1-2.1,1.1l-0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.4,368.1c-0.1,0.7,0,1.4,0.4,1.9c0.4,0.5,1,1,1.8,1.1
	c0.7,0.1,1.4,0,1.9-0.4c0.6-0.4,1-1,1.1-1.8c0.1-0.7,0-1.4-0.6-1.9c-0.4-0.5-1-1-1.6-1.1c-0.7-0.1-1.4,0.1-1.9,0.5
	C212.9,366.7,212.5,367.2,212.4,368.1L212.4,368.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M213.2,368.1c-0.1,0.5,0,1,0.4,1.5c0.3,0.4,0.7,0.7,1.2,0.7
	c0.6,0.1,1,0,1.4-0.3c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4c-0.3-0.4-0.7-0.7-1.2-0.8c-0.6-0.1-1,0-1.4,0.4
	C213.5,367.2,213.2,367.7,213.2,368.1L213.2,368.1z M214.6,378.1c-0.3,0.7-0.4,1.4-0.1,2.1c0.3,0.7,0.7,1.2,1.4,1.5s1.4,0.4,2.1,0.1
	s1.2-0.7,1.5-1.4c0.3-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1.2-1.4-1.5c-0.7-0.3-1.4-0.4-2.1-0.1C215.4,377,215,377.4,214.6,378.1
	L214.6,378.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M221.5,380.3l0.1-0.7c0.1-0.7-0.1-1.5-0.4-2.3l-0.1-0.4
	c-0.4-0.8-1.1-1.4-1.8-1.8c-1-0.6-1.8-0.7-2.6-0.6l-0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M196.3,393.5l3.3-3.6c4.7-5.1,8.3-8.5,10.7-10.3c2.9-2.2,4.8-3.4,5.6-3.7
	c0.7-0.1,1.4-0.1,2.1,0.1l0.4,0.3c0.6,0.3,1,0.7,1.2,1.2c0.4,0.8,0.6,1.7,0.4,2.5c-0.1,1-1.4,2.9-3.4,5.9c-1.8,2.6-4.9,6.3-9.6,11.1
	l-3.3,3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M216.4,377.4c-0.6,0.1-0.8,0.4-1.1,1c-0.3,0.4-0.3,1-0.1,1.5
	c0.1,0.5,0.6,0.8,1,1.1c0.6,0.3,1,0.3,1.5,0.1c0.6-0.1,0.8-0.4,1.1-1c0.3-0.4,0.3-1,0.1-1.4c-0.1-0.5-0.4-0.8-1-1.1
	C217.4,377.3,216.9,377.3,216.4,377.4L216.4,377.4z M219,365.9c-0.7-2.1-1.9-4-3.9-5.9c-3-2.9-6.9-5.9-11.8-8.9 M198.8,356.4
	c-1.5,2.2-2.6,4.5-3.4,7 M216.7,343.9c-5,1.1-9.4,3.4-13.3,7.2 M215.7,374.8c-2.1,0-4.4,0.7-6.9,1.8c-3.7,1.9-7.8,4.8-12.2,8.5
	 M234.1,405.5l-0.1-0.1l-2.5-4.4c-3.3-6.1-5.5-10.5-6.5-13.3c-1.2-3.7-1.9-6.1-1.8-7 M204.4,401.5l0.3-0.1l3.6-3.6
	c4.7-5,8-8.7,9.6-11.1c2.2-3.2,3.4-5.4,3.6-6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.1,399.9c3.4-4.5,6.1-8.8,7.8-12.7c1.1-2.5,1.5-4.8,1.5-6.9
	 M223.3,380.6c-0.7,2.1-0.8,4.4-0.4,7c0.7,4.3,2.2,8.9,4.3,14.2 M206.1,396.4l8-8.5c1.4-1.9,1.6-3.2,0.7-3.9l-2.2-2.2
	c-0.7-1-2.1-0.7-3.9,0.8l-8.1,8.4 M196.7,385.1l-0.6,0.4c-0.7,0.5-1,1.8-1,3.7 M200.4,391l-0.3,0.3l-2.5,1.5 M195.5,392.8v0.3
	c0,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3 M197.7,392.8c0.8,1,1.6,1.9,2.6,2.9c1.4,1.4,2.8,2.5,4.1,3.6 M195.5,392.8
	l0.1-0.1l3.3-3.7c4.7-5.1,8.3-8.5,10.7-10.3c3.2-2.3,5.1-3.7,6.1-3.9 M196.3,393.5l1.4-0.7 M195.2,389.3c0,1,0.1,2.2,0.3,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M200.4,391l2.2,2.3c1.1,1.1,2.2,2.1,3.4,3 M204.4,399.3l1.2-2.6l0.4-0.3
	 M203.7,400.5c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.3 M204.4,399.3l-0.7,1.2 M204.4,401.5
	c1.5,0.1,2.8,0.1,3.7,0.1 M208.1,401.6c1.9-0.1,3.2-0.5,3.6-1.2l0.4-0.5 M227.1,401.8l-3.7,0.1c-4.1,0-7.8-0.7-11.3-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M223.4,408.9c10,0,18.6-3.6,25.7-10.6c7.2-7.2,10.7-15.7,10.7-25.7
	c0-10-3.6-18.6-10.7-25.7c-7.2-7.2-15.7-10.6-25.7-10.6s-18.6,3.6-25.7,10.6c-7.2,7.2-10.7,15.7-10.7,25.7c0,10,3.6,18.6,10.7,25.7
	C204.8,405.4,213.4,408.9,223.4,408.9L223.4,408.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M208.1,401.6c4.5,2.5,9.6,3.7,15.3,3.7c2.5,0,4.8-0.3,7-0.7 M246.5,395.9
	c0.4-1.6,0.4-2.9-0.1-3.4l-0.6-0.7 M246.5,395.9c5.6-5.6,8.8-12.2,9.5-19.9 M229.7,378.9c-0.6-0.5-1.1-0.8-1.9-0.8
	c-0.8,0-1.4,0.3-1.9,0.8c-0.6,0.5-0.8,1.2-0.8,1.9s0.3,1.4,0.8,1.9c0.6,0.5,1.1,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8s0.8-1.1,0.8-1.9
	C230.6,380,230.3,379.4,229.7,378.9L229.7,378.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M227.9,382.7c0.6,0,1-0.1,1.4-0.5c0.4-0.4,0.6-0.8,0.6-1.4
	c0-0.5-0.1-1-0.6-1.4c-0.4-0.4-0.8-0.5-1.4-0.5s-1,0.1-1.4,0.5s-0.6,0.8-0.6,1.4c0,0.5,0.1,1,0.6,1.4
	C226.8,382.5,227.4,382.7,227.9,382.7L227.9,382.7z M240.7,396.4l-5.6-10c-1.4-1.9-2.5-2.5-3.4-1.8l-2.9,1.5
	c-1.1,0.4-1.2,1.8-0.4,3.9l5.5,10.3 M242.9,398.9L241,397l-0.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M234,400.1l0.1,0.4l0.7,2.9 M235.1,404.8l-0.3-1.4 M234.1,405.5
	c0,0,0.1,0.1,0.3,0.1l0.4-0.1l0.3-0.3v-0.3 M227.1,401.8l0.3,0.7c0.3,0.8,1.2,1.5,3,2.1 M230.6,404.7c1,0.3,2.2,0.7,3.7,1
	 M245.1,399.6v-0.3l-2.3-4.5c-3.2-5.9-5.8-10.2-7.7-12.5c-2.3-3-4-4.8-4.8-5.4 M242.9,398.9l1.1,1.1 M244,400l0.1,0.1l0.3,0.1h0.4
	c0.1-0.1,0.3-0.3,0.3-0.4v-0.3 M234,400.1c2.5-1,4.7-2.2,6.9-3.7 M246,391.6c-3.3-4.7-6.5-8.4-9.5-11.3c-2.1-1.8-4.1-2.9-6.1-3.4
	 M234.8,403.4c2.9-1.1,5.6-2.6,8.3-4.5 M245.1,399.6c0.6-1.5,1.1-2.8,1.4-3.9 M252.4,378.1c-1,5.1-3,9.6-6.6,13.6 M231.2,344
	c-2.5-0.7-5-1-7.7-1 M305.8,373.9h-5.5 M258.2,372.6l0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4s-0.1-0.3-0.3-0.4l-0.3-0.1 M269.9,390.5
	l15-0.3 M284.9,390.2v-16.1 M300.3,373.9l-15.4,0.3 M256,375.9c0.7-0.8,1.4-1.9,2.1-3.3 M257.8,371.5l-1.6-0.1 M223.3,339.9
	l-0.3-1.6 M221.9,337.8c-1.4,0.8-2.5,1.6-3.3,2.3 M247.1,297.9c5.4,1.6,10.5,4,15.4,6.9 M245.1,262.4l48.1,2.5 M293.3,264.9
	c-18,9.8-33.4,20.8-46.2,33 M439.9,281.8c-0.3-0.5-0.8-1.2-1.4-1.6c-1.4-1.4-3-2.1-5-2.1h-3.7c-1.9,0-3.6,0.7-5.1,2.1
	c-0.8,0.8-1.4,1.6-1.6,2.6 M423,282.8h-0.5c-1.6,0-3,0.3-4.1,0.7s-1.6,1-1.6,1.5s0.5,1.1,1.6,1.5s2.5,0.7,4.1,0.7h0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M423,287.2c0.3,1,1,1.8,1.7,2.6c1.4,1.4,3,2.1,5.1,2.1h3.7
	c1.9,0,3.6-0.7,5-2.1c1-1,1.6-2.1,1.9-3.2 M423,287.2l17.5-0.5 M439.9,281.8l-16.9,1 M351.1,264.4l-1.2,2.1
	c-1.6,2.2-3.7,3.3-6.2,3.4 M327.1,260.5c0.1,0.7,0.5,1.5,1,2.2c0.5,1,1.2,1.8,2.2,2.6l2.1,1.5c1.8,1.1,3.6,1.9,5.8,2.5l2.8,0.4
	l1.2,0.1 M343.6,269.9H342"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M324.6,266.8l-0.5,1.2l-3.6,4.7c-1.8,2.6-1,4.8,2.3,6.6
	c5.4,1.6,10.6,1.6,15.7,0c3.9-1.6,5.5-4.8,5-9.6 M325.2,265.5l-0.5,1.4 M327,256.2l-6.5,6.7l5.8-0.3 M326.3,262.7l-1.1,2.8
	 M342,269.9c-10.9,1.8-16.6,0.8-17.2-3 M373.1,200.4L302.9,263 M352.2,261.6c-0.3,1-0.5,1.9-1,2.8 M327.1,260.5l-0.8,2.2
	 M196.3,354.2c-1.8,2.6-3.2,5.4-4.1,8.4 M179.5,275.1c15-2.6,29.6-4.3,43.6-5.1 M454.2,371.1l-148.4,2.8 M460.7,259.8l-109.6,4.5
	 M446.3,257.7l15-0.7 M462.1,200.2l-15.8,57.5 M384.4,200.4c27.1-1.8,56.5-2.8,88.3-3 M284.9,390.2l217.2-1.6"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M327.1,260.5l-2.9,7.6
		l-3.2,4.3c0,0-2.3,2.3-0.8,4.7c0,0,0.8,1.4,2.9,2.5c0,0,4,1.5,10.3,1.1c0,0,5.5-0.3,8-2.8c0,0,3.2-2.1,2.3-8c0,0,3.3,0.1,6.1-3.3
		c0,0,2.3-2.6,2.8-7c0,0,0.7-4.8-1.9-8.8c0,0-2.3-4.1-6.1-4.3c0,0-3.7-0.4-8.8,1.4c0,0-5.6,2.1-7.8,6.5
		C327.7,254.3,326.2,256.8,327.1,260.5L327.1,260.5z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M148.3,352.4h16.6
		c0,0,8.4-26,35.6-37.1c0,0,25-11.6,51.7,3.3c0,0,22.7,12,30,38.1c0,0,2.6,8.5,2.6,17.3l20.9-0.4c0,0-2.8-16.8-3.9-32.2
		c0,0-1.1-13.5-1.5-25.4l-0.1-1c0,0-1.1-42.1,13.1-49.2l-20.1-1c0,0-9.6,5-24.8,15.4c0,0-8.4,5.6-21.5,17.6c0,0-10.2-4.1-27.6-3.6
		c0,0-16.5-0.4-34.4,9.2c0,0-14.2,7.2-25.6,22.7C159.6,326.1,150.9,337.4,148.3,352.4z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M305.8,373.9
		c0,0-2.9-18.8-3.7-31.1c0,0-1.2-16-1.7-26.6l-0.1-1c0,0-1.4-41.8,13.1-49.2l11.8-0.4l-1,2.6l-3.6,4.7c0,0-1.9,2.3-0.3,4.5
		c0,0,0.8,1.2,2.6,2.1c0,0,4.3,1.5,9.9,1.2c0,0,2.9-0.1,5.8-1.2c0,0,3.6-1.2,4.7-4.8c0,0,0.7-1.7,0.3-4.7c0,0,2.6,0.1,5.1-2.2
		c0,0,1.4-1.4,2.3-3.2l59.2-2.5l6.6-0.3l43.7-1.8c0,0-4.1,9.9-5.9,27.1c0,0-2.2,18.7-2.3,43.5c0,0-0.1,15.8,1.9,40.7l-50.1,1
		l-56.5,1.1L305.8,373.9z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M352.2,261.6
		l49.1-2.1l44.8-1.8l8-28.8l8-28.8c0,0-22.7,0.1-50.2,1.4c0,0-19.5,1-27,1.5c0,0-15.1,12.4-27.4,23.7c0,0-18.2,16.6-22.4,21.2
		c0,0,6.1-2.3,10.6-1.2c0,0,3.7,1,5.8,6.3C351.6,253.1,353.1,256.9,352.2,261.6z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M454.2,371.1
		c0,0-1.9-21.3-1.9-43.5c0,0-0.1-18.7,2.9-45.3c0,0,2.1-14.7,5.4-22.4l74.7-3l64-2.6l4.4,16.2l0.4,26l0.3,18c0,0-15.3,4.1-28.9,22.4
		c0,0-10.7,13.8-12,32.3l-45.9,0.8l-46.4,0.8L454.2,371.1z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M598.5,251.4l-9.2-40.2
		c0,0-0.8-3.4-2.3-5l13.6-0.6l45-1.9l4.8,6.7l-1.5,2.2l15.8,27.5l3.6,1.1l4.1,7C672.3,248.4,599,251.4,598.5,251.4z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M604.5,314.4
		l-0.7-44l-4.4-16.2l50.6-2.1l42.1-1.8c0,0,7.3,3.6,11.4,11.8c0,0-1.9-2.3-5.5-2.5h-6.5c0,0-4.8-0.4-6.1,5.2c0,0-0.7,5.2-0.3,10.7
		c0,0,0.1,4.3,0.7,10c0,0-0.1,8.1,1.9,14.9c0,0,1.2,4.4,3.6,7c0,0,2.5,2.5,8.8,2.3c0,0,6.9,0.4,8.4-5.1l0.1,10.5h-30.4
		c0,0,12.5,10.9,18.3,26.4h-20.8c0,0-10.6-18.8-29.6-26.4c0,0-9.2-4.4-23.1-4.1C622.9,311.1,613.3,311,604.5,314.4z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="222.3"
              cy="372.6"
              r="50.6"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="623.7"
              cy="372.8"
              r="50.5"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M475.8,256.5
		l55.3-2.3l40.7-1.7l13.9-0.6l3.9-27.9l-2.8-12.1c0,0-0.7-5.1-8.5-8.1c0,0-5.1-2.3-17.5-2.6l-15.1-0.3l-23.5-0.6
		c0,0-20.1-0.4-39.5-0.3l-2.6,22.4l-2.8,21.2L475.8,256.5z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Crossover4DrLeft);
