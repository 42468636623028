import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback2DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M758,328.3c0.1-1.6,0.8-4.3,0.8-4.3v-4.7c0-1.2-1.2-4-6.6-4.5s-7-0.5-7-0.5c1.2-5.9,0.8-16,0.8-16
	s-5.4-21.9-6.5-27.2c-0.8-5.4,2.2-4.7,2.2-4.7l3.6-0.3c0,0-2.5-9.8-3.6-12.2c-0.8-2.3-2.3-3.7-2.3-3.7
	c-34.1-23.1-73.7-39.9-93.8-45.4l-0.1-0.7l14.2-2.3l1-6.6l-31.6-1.4c-48.4-10-73.6-12.7-100.3-13.8c-14.7-0.8-98.1-2.9-138.1,8.1
	c-33.8,9.2-96.6,40.7-151.9,72.8l-0.7,0.3c-1.4,0-2.5-0.1-3.4-0.3c-3.3-0.5-11-2.1-11-2.1c-13.8,2.1-55.7,8.3-87.5,16.8
	c-32.2,8.9-42.1,22.7-42.1,22.7l2.2,1.5H96c-4,3.9-6.5,9.5-6.9,14.3c0,0-0.1,1.4-1.2,1.8c-1,0.5-5,4-5,4l0.1,24.1
	c0,0,5.1-0.8,5.8,2.1c0.3,2.1,1.8,7.3,1.8,8.5c0,1.4-0.6,1.8-1.8,1.8h-5.4c0,0-1.4,1.2-1.4,1.6c0,0.7,0.1,0.7,0.1,1.8
	c0.1,1,0.1,2.9,2.1,2.9h5.4c1,0,2.8,0,3.7,1.2c0.8,1,2.1,2.9,3.4,5.2c0.8,1.4,1.7,2.8-0.8,2.8c-2.3,0-5.8-0.1-6.5-0.1
	c-0.6,0-1.4,0-1.4,1.2c0,1,0.1,1.4,2.9,1.5c2.5,0.1,67.5,5.5,67.5,5.5c0-2.2,0.1-4.5,0.3-7c0.6-6.6,117.1-13.5,118.3-3.7
	c0.7,4.8,1,10,1,15.8h290.1c0-5.9,0.6-11.3,1-16.2c1.4-9.6,117.8-8.1,118.6,0.7c0.1,2.8,0.3,5.1,0.3,7.4l7-0.8
	c0,0,14.7-5.8,27.4-8.9c8.4-2.2,24.9-5.1,27-5.2c1.8-0.3,2.3-1,3.9-2.9c1.7-1.8,3.7-8.3,4.7-14.2c0.3-1.8,0.7-4.5,0.7-4.5
	c0.1-1.6-1-4-1-5.4C758,333.7,757.8,329.8,758,328.3L758,328.3z"
          />
          <path
            fill="#656565"
            d="M629.1,311.8c-27.9,0-50.5,22.7-50.5,50.8c0,27.8,22.6,50.5,50.5,50.5c27.9,0,50.8-22.7,50.8-50.5
	C679.8,334.5,657.1,311.8,629.1,311.8z M629.1,397.5c-19.4,0-35.1-15.8-35.1-35.1c0-19.4,15.7-35.1,35.1-35.1
	c19.4,0,35.1,15.7,35.1,35.1C664.2,381.7,648.5,397.5,629.1,397.5L629.1,397.5z M217.5,311.8c-27.9,0-50.5,22.7-50.5,50.8
	c0,27.8,22.6,50.5,50.5,50.5c27.9,0,50.8-22.7,50.8-50.5C268.2,334.5,245.4,311.8,217.5,311.8L217.5,311.8z M218,397.4
	c-19.4,0-35.1-15.7-35.1-35.1c0-19.3,15.7-34.9,35.1-34.9s35.1,15.7,35.1,34.9C253,381.7,237.4,397.4,218,397.4z"
          />
          <path
            fill="#EBEBEB"
            d="M269.8,254.6c42.6-24.6,76.3-43.2,106.3-54.2h0.3c-3.2-1.2-7.4-2.5-11.7-3l-1,0.1
	c-32.9,12.9-76.9,35.5-117.8,59l-0.6,0.3c3.3,0.3,9.6,0.7,16,0.1c1.8-0.1,3.9-0.5,6.1-1.2 M569.2,206.4l-2.3-0.7
	c-9.9-2.3-22.4-5.4-39.6-6.6c-3-0.1-6.6,0-11.3-0.1c0,0-5.9,0.6-5.9,6.5l-2.9,40.7c0,0,0.1,4.8,4.4,4.7l94.9-4.7h-0.3
	c13.5-0.3,20.5-2.9,23-3.9c1.2-0.3,2.8-1,4.4-1.6c12.2-4.5,0.5-8.9,0.5-8.9C617.1,223.6,591.5,212.5,569.2,206.4 M155.7,289.5
	c-10.3,0.1-36.9,3.6-44.7,7.7c-7.6,4-10.6,8.5-12.1,14c-0.8,3,0.3,4.7,1.5,5c0,0,23.4-0.1,25-0.1c1.5,0,3,0,4.5-3
	c3-6.5,7.6-18.4,29-24.2 M478.4,257.2l10.2-60.7l-0.1-0.7c-30.3-0.1-69.5,1.2-92,7.4c-33,9.1-60.5,26-72.1,33.2l-1.8,0.7l-6.2,29.7
	h-0.5C362.6,263.9,455.3,258.3,478.4,257.2 M722.5,250.5c-5.1,0.1,17.2,1.6,17.2,1.6l1-1c-0.5-0.7-0.8-0.8-0.8-0.8
	c-34.3-23-73.7-39.6-93.8-45.3l-0.5-0.6c-1.7,1.2-3.2,2.8-5,4.3l-0.7-0.1c34,13.2,63.1,27,66.9,29.4c2.8,1.6,10,5.9,15.7,12.8"
          />
          <path
            fill="#EBEBEB"
            d="M722.5,250.7c-5.5-6.9-12.9-11.1-15.7-12.8c-4.3-2.8-39.8-19.7-78.5-34c-2.8-1-5.5-2.1-8.4-2.9
	c0,0-5.9,5.1,0.3,8c2.2,0.8,4.4,1.6,6.6,2.8c25,10.3,48.4,21.5,61.5,27.8c0,0,20.4,11,33.7,10.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M645.6,204.8c20.1,5.5,59.7,22.3,93.8,45.1c0,0,1.5,1.4,2.3,3.7
	c1,2.5,3.6,12.2,3.6,12.2l-3.6,0.3c0,0-3-0.7-2.2,4.7c1,5.4,6.5,27.2,6.5,27.2s0.3,10.2-0.8,16c0,0,1.7,0,7,0.5s6.6,3.3,6.6,4.5v4.7
	c0,0-0.7,2.5-0.8,4.3c-0.1,1.5,0,5.4,0,6.9c0,1.4,1.2,3.7,1,5.4c0,0-0.3,2.8-0.7,4.5c-1,5.9-3,12.2-4.7,14.2
	c-1.5,1.8-2.1,2.5-3.9,2.9c-2.1,0.1-18.6,3-27,5.2c-12.7,3.2-27.4,8.9-27.4,8.9l-7,0.8c0-2.3-0.1-4.7-0.3-7.4
	c-0.8-8.8-117.2-10.3-118.6-0.7c-0.5,5-1,10.3-1,16.2H278.3c0-5.8-0.3-11-1-15.8c-1.2-9.8-117.8-2.9-118.3,3.7c-0.1,2.5-0.3,5-0.3,7
	c0,0-65.1-5.4-67.5-5.5c-2.8-0.1-2.9-0.5-2.9-1.5c0-1.2,0.8-1.2,1.4-1.2c0.7,0,4,0.1,6.5,0.1c2.5,0,1.7-1.4,0.8-2.8
	c-1.4-2.3-2.5-4.3-3.4-5.2c-1-1.2-2.8-1.2-3.7-1.2h-5.4c-1.8,0-1.8-1.8-2.1-2.9c0-1.2-0.1-1.2-0.1-1.8c0-0.5,0.3-1.6,1.4-1.6H89
	c1.4,0,1.8-0.5,1.8-1.8c0-1.4-1.5-6.6-1.8-8.5c-0.7-2.9-5.8-2.1-5.8-2.1l-0.1-24.1c0,0,3.9-3.6,5-4c1-0.5,1.2-1.8,1.2-1.8
	c0.6-5,2.9-10.5,6.9-14.3h0.1l-2.2-1.5c0,0,9.9-13.6,42.2-22.6c31.6-8.5,73.6-14.9,87.5-16.8c0,0,7.6,1.5,11,2.1
	c0.8,0.1,2.1,0.3,3.4,0.3l0.7-0.3c55.3-32.1,117.9-63.6,151.9-72.8c40.2-11,123.5-8.9,138.1-8.1c26.7,1.2,51.9,3.7,100.3,13.8
	l31.6,1.4l-1,6.6l-14.2,2.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M478.4,257.2l10.2-60.7l-0.1-0.7c-30.3-0.1-69.5,1.2-92,7.4
	c-33,9.1-60.5,26-72.1,33.2l-1.8,0.7l-6.2,29.7h-0.5C362.6,263.9,455.3,258.3,478.4,257.2 M155.7,289.5c-10.3,0.1-36.9,3.6-44.7,7.7
	c-7.6,4-10.6,8.5-12.1,14c-0.8,3,0.3,4.7,1.5,5c0,0,23.4-0.1,25-0.1c1.5,0,3,0,4.5-3c3-6.5,7.6-18.4,29-24.2 M722.5,250.7
	c-5.5-6.9-12.9-11.1-15.7-12.8c-4.3-2.8-39.8-19.7-78.5-34c-2.8-1-5.5-2.1-8.4-2.9c0,0-5.9,5.1,0.3,8c2.2,0.8,4.4,1.6,6.6,2.8
	c25,10.3,48.4,21.5,61.5,27.8c0,0,20.4,11,33.7,10.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M722.5,250.5c-5.1,0.1,17.2,1.6,17.2,1.6l1-1c-0.5-0.7-0.8-0.8-0.8-0.8
	c-34.3-23-73.7-39.6-93.8-45.3l-0.5-0.6c-1.7,1.2-3.2,2.8-5,4.3l-0.7-0.1c34,13.2,63.1,27,66.9,29.4c2.8,1.6,10,5.9,15.7,12.8
	 M269.8,254.6c42.6-24.6,76.3-43.2,106.3-54.2h0.3c-3.2-1.2-7.4-2.5-11.7-3l-1,0.1c-32.9,12.9-76.9,35.5-117.8,59l-0.6,0.3
	c3.3,0.3,9.6,0.7,16,0.1c1.8-0.1,3.9-0.5,6.1-1.2 M745.6,266.1c0,0,3,1,3.7,8.5h-8.8l-0.8-6.7l1.7-1.6 M217.5,413.1
	c27.9,0,50.8-22.7,50.8-50.5c0-27.9-22.7-50.8-50.8-50.8c-27.9,0-50.5,22.7-50.5,50.8C167,390.2,189.6,413.1,217.5,413.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M217.5,413.1c27.9,0,50.8-22.7,50.8-50.5c0-27.9-22.7-50.8-50.8-50.8
	c-27.9,0-50.5,22.7-50.5,50.8C167,390.2,189.6,413.1,217.5,413.1z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M217.5,397.5c19.4,0,35.1-15.8,35.1-35.1c0-19.4-15.7-35.1-35.1-35.1
	s-35.1,15.7-35.1,35.1C182.4,381.7,198.1,397.5,217.5,397.5 M291.5,344c-8.3-33.2-38.2-57.8-74-57.8c-39.9,0-72.8,31-75.9,70.3
	 M99.7,355.6h16.5c1.2,0,3.4-1,3.7-3.3c0.3-1.9,1-15,1-17.5c0-2.8-3.4-2.5-3.4-2.5H92.7c0,0-3.9,0.3-3.2,3.2
	c0.7,2.3,3.7,12.7,5.1,15.7C95.9,354,97.8,355.6,99.7,355.6 M82.1,360.1l77.7-0.1 M83.9,350.9h5.8 M83.1,344.1l4.4,12.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M568.4,385.4H278.2c0.1-17.1-3.3-30.7-6.2-39.9
	c-7.3-23.1-28.8-39.9-54.5-39.9c-24.8,0-45.9,15.8-53.8,38.1c-3.6,10.5-4.9,26.3-5.2,36.7 M100.6,315.9c-1-0.1-2.2-2.1-1.4-5.1
	c1.4-5.4,4.5-9.9,12.1-14c7.6-4.3,34.4-7.6,44.7-7.7 M132.5,308.4h31l19.3,8.9 M100.6,315.9c0,0,23.5-0.1,25-0.1
	c1.7,0,3-0.1,4.5-3.2c3.2-6.5,7.7-18.4,29.2-24.1c0,0,11.1-6.1,69.5-13.2c0,0,7.6-0.5,10.5-4c4-5.1,7-8.3,9.8-9.8 M95.9,300
	c-4,3.7-6.5,9.5-7,14.3c0,0-0.1,1.4-1,1.8c-1,0.5-5,4-5,4l0.1,23.9c0,0,5.1-0.7,5.5,2.2c0.6,2.1,2.1,7,2.1,8.5
	c0,1.4-0.6,1.8-2.1,1.8h-5.2c-1,0-1.5,1-1.5,1.6s0.1,0.7,0.3,1.7c0.1,1.2,0.1,3,1.8,3h5.5c0,0,2.8,0,3.6,1.2c1,1,2.2,2.8,3.6,5.1
	c0.8,1.5,1.7,2.9-0.8,2.9s-5.8-0.1-6.5-0.1s-1.4,0-1.4,1.2c0,1,0.1,1.4,2.8,1.5c2.8,0.1,67.7,5.5,67.7,5.5 M101.9,304l-8.1-5.8
	c0,0,9.9-13.6,42.2-22.4c31.6-8.8,73.6-14.9,87.5-17.1 M85,363.3l4.4,8.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M304.2,369.9v15.7 M629.1,298.6v0.1c-19.5,0-37,8.8-48.7,22.6
	c-5.1,5.9-10,14.3-12.7,22c-3,9.1-5.9,30.8-6.5,40.7 M153.8,359.3c0.3-2.5,0.8-7.3,0.8-7.3c4.8-30.3,31.2-53.4,62.9-53.4v0.1
	c19.5,0,37,8.8,48.7,22.6c5.1,5.9,10.2,14.3,12.7,22c3,9.1,5.9,30.8,6.5,40.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M629.1,413.1c-27.9,0-50.5-22.7-50.5-50.5c0-27.9,22.6-50.8,50.5-50.8
	c27.9,0,50.8,22.7,50.8,50.8C679.8,390.2,657.1,413.1,629.1,413.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M629.1,413.1c-27.9,0-50.5-22.7-50.5-50.5c0-27.9,22.6-50.8,50.5-50.8
	c27.9,0,50.8,22.7,50.8,50.8C679.8,390.2,657.1,413.1,629.1,413.1z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M606.9,255.3c0,0-111,5.2-147.5,7c-27.5,1.4-119.1,6.9-162,9.5
	 M696.1,376.1c-0.5-9.9-2.5-23.5-5.5-32.7c-2.5-7.7-7.6-16.2-12.7-22c-11.7-13.8-29.2-22.6-48.7-22.6 M629.1,397.5
	c-19.4,0-35.1-15.8-35.1-35.1c0-19.4,15.7-35.1,35.1-35.1c19.4,0,35.1,15.7,35.1,35.1C664.2,381.7,648.5,397.5,629.1,397.5
	 M552.6,369.6c-0.3-2.3-0.3-4.7-0.3-7c0-20.5,8-39.1,20.9-53c14.2-14.9,34-24.1,56-24.1c39.8,0,72.5,30.1,76.5,68.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M571,199.4c22.7,6.2,50.9,18.2,74.4,29.9c3.7,1.8,9.9,5,4.7,8.8
	c-5.2,3.7-14.2,7.4-17.9,8.9c-3.9,1.4-11.7,3.9-25.5,4.3 M316.1,268.1l8-36.7 M297.4,271.8l7.3-28.3 M474.6,369.9H304
	c-9.5,0-11.1-13.8-12.5-25.2c-1.4-10.3-3-36-2.9-44.4c0.3-12,1.2-19.5,2.9-27.9 M606.9,251.1l-149,7.3c-23.1,1.2-95.2,5.4-141.8,8.1
	 M297.4,271.8c-2.2,0.1-4.3,0.3-6.2,0.5c-10,0.5-16.2,0.8-16.2,0.8s-13.6,1.5-2.9-7.7c6.9-6.1,43.3-29.4,49.9-33.4 M324.5,236.3
	c11.8-7.3,39.1-24.1,72.2-33.2c25-6.9,70.7-7.7,101.9-7.4 M650.1,242.2c-5.2,3.7-14.2,7.6-17.9,8.9 M632.4,251.1
	c-3.9,1.5-11.7,4-25.5,4.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M569.2,206.4l-2.3-0.7c-9.9-2.3-22.4-5.4-39.6-6.6c-3-0.1-6.6,0-11.3-0.1
	c0,0-5.9,0.6-5.9,6.5l-2.9,40.7c0,0,0.1,4.8,4.4,4.7l94.9-4.7h-0.3c13.5-0.3,20.5-2.9,23-3.9c1.2-0.3,2.8-1,4.4-1.6
	c12.2-4.5,0.5-8.9,0.5-8.9C617.1,223.6,591.5,212.5,569.2,206.4"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M569.2,206.4l-2.3-0.7c-9.9-2.3-22.4-5.4-39.6-6.6c-3-0.1-6.6,0-11.3-0.1
		c0,0-5.9,0.6-5.9,6.5l-2.9,40.7c0,0,0.1,4.8,4.4,4.7l94.9-4.7h-0.3c13.5-0.3,20.5-2.9,23-3.9c1.2-0.3,2.8-1,4.4-1.6
		c12.2-4.5,0.5-8.9,0.5-8.9C617.1,223.6,591.5,212.5,569.2,206.4"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M645.6,204.8c20.1,5.8,59.7,22.4,93.8,45.3c0,0,1.5,1.2,2.5,3.7
		c0.8,2.5,3.3,12.2,3.3,12.2l-3.3,0.1c0,0-3.2-0.5-2.2,4.7c0.8,5.4,6.2,27.2,6.2,27.2s0.5,10.3-0.8,16.2 M238.5,261
		c55.3-32.2,118.2-63.6,151.9-72.8c40.3-11.1,123.7-8.9,138.4-8.3c26.4,1.4,51.9,3.9,100.3,13.8l31.6,1.4l-1,6.6l-14.2,2.3
		 M571,199.4c-10.3-2.5-23.9-6.2-43.2-7.4c-19-1.2-96.6-3.4-132.5,6.5c-33.7,9.2-61.1,26.1-73.5,33.6 M528.2,187.9
		c19.9,1.5,55.6,7,91.6,20.9"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M619.9,208.8c2.2,0.8,4.4,1.6,6.6,2.8c25.2,10.3,48.4,21.5,61.5,27.8
		 M619.9,201.2c-23.2-8.3-62-12.9-91.5-14.7c-18.7-0.8-95.1-1.5-134,8 M270.7,254.4c51-29.7,89.3-50.3,123.7-60 M688,239.3
		c6.7,3.3,10.7,5.4,10.7,5.4s11.8,7.6,13.2,20.8c1.2,13.2,1.2,31.4,0.7,37.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M743.5,350.5c7.7-0.7,14.2-1.2,14.2-1.2 M686.6,357.8l7.4-1.2
		c0,0,5.1-1,7.4-1.4c2.3-0.5,12.9-2.3,16.5-2.8c1.5-0.1,5.4-0.5,10.2-0.8 M664.7,317.4l17.1-11.3l30.8-3.2h15.7 M246.5,256.5
		c3.2,0.5,9.6,0.7,16,0.1c1.8,0,3.9-0.5,6.1-1 M223.5,258.8c0,0,7.6,1.6,11,2.2c3.2,0.5,9.6,0.7,16,0.1c6.2-0.5,15.1-4,20.2-6.7
		 M488.8,196.4l-9.6,60.3 M499.1,191.2l-4.5,65.1 M499.1,191.2l-4.5,65.1c0,0,4.7,9.9,4.7,17.3c0,7.6,0,51-5.2,73.5
		c-3.7,15.5-3.4,22-19.4,23 M731,320.2l26.7-0.1 M724.3,339.9l34-0.5"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M552.6,324.9l-254.9,5.4c-1,0-1.5-0.5-1.5-1v-3.9c0-1,1.5-1,1.5-1
	l258.8-5.4c0,0,1.7,0,1.2,1.4C557.1,321.7,556.2,324.6,552.6,324.9 M469.9,288.6c3.2-0.1,5.8-2.8,5.8-5.8v-3.6
	c-0.1-3.2-2.8-5.5-5.9-5.5l-6.9,0.1c-3.2,0-5.8,2.5-5.8,5.5v3.7c0.1,3,2.8,5.5,5.9,5.4h6.9 M90.7,338.9h29.9 M92.3,343.3h13.6
	 M95.5,352.3h12.2 M93.8,347.9h12.9 M105.8,339.9c0,2.8,0.6,11.1,3.8,14.9 M114,339.2c2.8,0,4.8,3.6,4.8,7.7c0,4.3-2.2,7.7-4.8,7.7
	c-2.8,0-5-3.6-5-7.7S111.3,339.2,114,339.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M92.3,304H89c0,0-1.7,1-1.9,3.3c-0.1,2.5,0,2.3,0,3.4c0,1,0.1,2.2,0.7,2.3
	H89 M114,339.2c2.8,0,4.8,3.6,4.8,7.7c0,4.3-2.2,7.7-4.8,7.7c-2.8,0-5-3.6-5-7.7S111.3,339.2,114,339.2z M305.7,254.9
	c0,0,1.8-1.2,2.3-2.2s0.5-1.2,0.8-2.3c0.3-1.2,1.5-3,3.2-4.3c1.4-1.2,4-2.2,5.9-2.3h8c0.8,0,5.5,0.8,5.8,5.2c0,4.5,0.1,9.1,0,10
	c0,0.8-2.1,3.2-5,3.6c-2.9,0.3-9.2,0.5-10.2,0.5s-4.8-0.8-5.9-1.4c-1.2-0.5-4.4-0.5-5.1-0.5"
          />
          <path
            fill="#FFFFFF"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M305.7,254.9c-1.2,2.1-3,7.3-3,9.1c-0.1,1.8-1.4,4.5,1.2,4.7
	c1.8,0,8.8-0.3,10-0.3c1.4,0,1.7-0.8,2.2-1.9c0.3-0.8,1-3.3,2.1-3.3 M305.7,254.9c0,0,1.8-1.2,2.3-2.2s0.5-1.2,0.8-2.3
	c0.3-1.2,1.5-3,3.2-4.3c1.4-1.2,4-2.2,5.9-2.3h8c0.8,0,5.5,0.8,5.8,5.2c0,4.5,0.1,9.1,0,10c0,0.8-2.1,3.2-5,3.6
	c-2.9,0.3-9.2,0.5-10.2,0.5s-4.8-0.8-5.9-1.4c-1.2-0.5-4.4-0.5-5.1-0.5v-6.3H305.7L305.7,254.9z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M312.1,260.6c2.2-0.8,5.8-2.5,8.5-2.5h8.8 M688,239.3
	c0,0,20.4,11,33.7,10.7 M276.5,261.7l-5.1-6.7 M616.1,191.3c-2.2,1.4-6.6,4.7-8.3,6.5 M628.4,193.9c-2.2,1.2-7.4,5.2-8.9,6.9
	 M660.6,195.2c-7.4,3.2-11.4,5.8-15.7,9.1 M644.9,204.2c-1.5,1.2-3,2.8-5,4.3 M728.5,346.3l15.7-1.2c0,0,1.5,0,1,1.4
	c-1,2.2-4,8.4-5.2,10c-0.7,0.8-1.5,2.5-2.9,2.8c-1.2,0-4.8,0.3-5.5,0.3s-1.4-0.3-1.8-2.1c-0.3-1.6-1.8-7.3-2.1-8.4
	C727.6,347.8,727.2,346.6,728.5,346.3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M728.5,346.3l15.7-1.2c0,0,1.5,0,1,1.4c-1,2.2-4,8.4-5.2,10
		c-0.7,0.8-1.5,2.5-2.9,2.8c-1.2,0-4.8,0.3-5.5,0.3s-1.4-0.3-1.8-2.1c-0.3-1.6-1.8-7.3-2.1-8.4C727.6,347.8,727.2,346.6,728.5,346.3
		L728.5,346.3z M726.3,339.2c8.8-3,24.5-5.2,30.8-5.2"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M277.5,292.7c1.5,0,2.5-1.2,2.5-2.8c0-1.4-1.2-2.5-2.5-2.5l-7,0.1
	c-1.5,0-2.8,1.2-2.8,2.8c0.1,1.4,1.4,2.5,2.8,2.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M758.8,324.1l-26.5,0.3c-1.5,0-3.6-1.5-5.1-3.2c-0.7-0.5-2.5-2.3-4.7-4.5
	 M568.4,385.4c0-17.1,3.3-30.7,6.5-39.8c7.3-23.2,28.8-40.2,54.2-40.2c24.9,0,45.4,16.4,53.9,38.2c3.6,9.5,5.1,23.4,5.5,33.6l6.9-1
	c0,0,14.7-5.5,27.4-8.9c8.4-2.2,25-5,27-5.1c1.8-0.3,2.3-1,3.9-2.9c1.7-1.8,3.9-8.3,4.7-14.2c0.3-1.8,0.7-4.5,0.7-4.5
	c0.1-1.6-0.8-4-0.8-5.5c0-1.4-0.1-5.2,0-6.9c0.1-1.5,0.7-4.3,0.7-4.3 M745.3,266.1c0,0,3.2,0.8,3.7,8.4h-8.8 M277.5,292.7
	c1.5,0,2.5-1.2,2.5-2.8c0-1.4-1.2-2.5-2.5-2.5l-7,0.1c-1.5,0-2.8,1.2-2.8,2.8c0.1,1.4,1.4,2.5,2.8,2.5L277.5,292.7L277.5,292.7z
	 M722.5,316.6l-9.2-9.2c-0.8-1-0.7-4.4-0.7-4.4 M104.8,324.1h3c0,0,1.4,0.5,2.1-1.2c0.7-1.6,1.7-3.9,1.8-4.7c0.3-0.8,0-1.4-1.4-1.4
	h-2.8c-1,0-1.4,0.6-1.7,1.2c-0.3,0.7-1.7,4-2.1,4.7C103.6,323.2,103.6,324.1,104.8,324.1 M129.7,313.6c-2.9,6.5-4.4,10.7-5.4,24.1
	c-0.1,4.7-1,14-0.7,17.5 M99.2,302.2c6.7-6.5,15.7-12.9,40.6-19.7c34.4-9.2,91.2-17.1,105-19 M371.6,194.6c4.3,0.6,8.4,1.8,11.4,3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M365.8,197.2c4.3,0.3,8.4,1.8,11.7,3 M619.9,208.8c-6.2-2.9-0.3-8-0.3-8
		c2.9,0.8,5.5,1.8,8.4,2.9c38.8,14.3,74.3,31.4,78.5,34c2.8,1.6,10.3,5.9,15.7,12.8c3,3.7,5.5,8.4,6.1,14
		c1.5,13.2,1.5,31.8,0.7,39.2c0,0-0.1,1.4,1.4,2.8c1.5,1.5,8.3,7.7,8.3,7.7 M758.8,324.1v-4.7c0-1.2-1.2-3.9-6.6-4.4
		c-5.4-0.5-7-0.5-7-0.5s-2.3,0-5.2-0.1h-8.5c-3.9,0.1-10.5,1.2-8.3,2.9 M722.1,250.3c-5.1,0.1,17.2,1.6,17.2,1.6"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M452.7,284.3c-1.4,0-2.8-1.4-2.8-2.8c0-1.4,1.4-2.8,2.8-2.9
	c3.7-0.5,12.2-1,20.1-1h12c1.6,0,2.9,1.4,2.9,2.9c0,1.5-1.2,2.9-2.9,2.9h-12C465,283.5,456.6,283.7,452.7,284.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M452.7,284.3c-1.4,0-2.8-1.4-2.8-2.8c0-1.4,1.4-2.8,2.8-2.9
	c3.7-0.5,12.2-1,20.1-1h12c1.6,0,2.9,1.4,2.9,2.9c0,1.5-1.2,2.9-2.9,2.9h-12C465,283.5,456.6,283.7,452.7,284.3L452.7,284.3z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M478.3,277.8l0.1,5.5 M481.5,282.8c1.2,0,2.2-1,2.2-2.2
	c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2S480.4,282.8,481.5,282.8 M707.4,364.6c-0.3-3.4-0.8-6.9-1.5-10.3 M670.8,289.1
	c7.3,0,13.2-5.9,13.2-13.3 M683.8,275.9c0-7.3-5.9-13.2-13.2-13.2 M670.8,262.7c-7.4,0-13.3,5.9-13.3,13.2 M657.4,275.9
	c0,7.4,5.9,13.3,13.3,13.3 M276.8,368.9l-8.8-3.6 M158.9,373.6l7-2.2 M263.2,273c-5,0.3-12.8,0.8-20.9,1.4 M687.5,370l-8.8-3.3
	 M569.8,369l7-2.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M218,397.4c19.4,0,35.1-15.7,35.1-35.1c0-19.3-15.7-34.9-35.1-34.9
	S183,343,183,362.3C183,381.7,198.6,397.4,218,397.4 M491.1,275.6c83.9-3.2,168.1-5.5,216.7-5.2 M283.2,285.9
	c39.5-2.5,99.6-5.5,163.6-8.4 M242.4,289.1c4.8-0.7,12.2-1.2,22-2.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M182.5,317.3
		l-19.3-8.9h-31.2c0,0,5.9-14.7,26.8-19.7c0,0,4.4-2.9,32.2-7.8c0,0,26.3-4.3,38.2-5.5c0,0,6.5-0.5,9.9-4c0,0,6.5-8.7,10.3-10
		c0,0,7.4,0.1,21.5-7l5.6,7.6c0,0-8.5,5.6-8.7,9.4c0,0,0.3,2.3,6.5,1.9l17.3-0.8c0,0-2.6,11.3-2.9,28.8c0,0,0,4,0.3,9.9
		c0,0,0.3,5.9,0.4,8c0.1,2.1,1.2,18.6,2.1,24.5c0,0,1,10.9,3.2,17.5c0,0,1.8,5.4,4.4,7.3c0,0,2.3,1.9,5.1,1.6v15.4h-25.7
		c0,0-0.4-14.2-2.1-22.3c0,0-3.2-16.4-6.2-22.6c0,0-7.6-21.5-31.5-30.8C238.5,309.5,211,296.7,182.5,317.3z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M291.6,272.3
		c0,0-3,12.1-2.9,29.6c0,0,0.5,12.1,0.7,16c0,0,1.5,21.6,1.9,24.2c0,0,1.5,15.7,4.1,20.9c0,0,2.8,6.9,8.7,6.9h170.6
		c0,0,10.6,0,14.2-5.6c0,0,1.8-2.3,3-7.7l2.5-10.7c0,0,1.5-7.8,1.8-10.3l1.1-9.4c0,0,1.2-14.6,1.5-24.1l0.6-26.5
		c0,0,0.3-2.8-0.7-7.7c0,0-1-4.1-2.2-7.2L291.6,272.3z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M302.2,265.9
		c0,0-0.8,2.3,1.4,2.8l10.3-0.3c0,0,1.2,0.3,2.1-1.5c0,0,1.2-3.6,2.2-3.7c0,0,6.3-0.1,9.1-0.5c0,0,2.9-0.7,4.3-3l0.1-0.5l-0.1-9.9
		c0,0,0.3-4.1-5.4-5.2h-8.3c0,0-3.7,0.1-7,3.2c0,0-1.8,1.8-2.2,3.6c0,0-0.4,2.8-3.2,4.3c0,0-1.9,3.7-2.6,6.9c0,0-0.4,1.4-0.4,2.1
		C302.6,264.5,302.2,265.9,302.2,265.9z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M322.4,237.1
		l1.8-0.7c0,0,34-22.4,69.7-32.5c0,0,18.8-6.5,59.4-7.7l34.3-0.6c0,0,1.5-0.1,1,1.8l-10.2,59.7l-162.6,9.6c0,0,1.1-3,1.7-3.3
		c0,0,0-0.3,1.8-0.4c1.8,0,6.7-0.4,7.8-0.5s3.7-1.5,4.4-3.4v-10c0,0,0.3-4.1-5.4-5.2h-5.1L322.4,237.1z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M512.6,250.7
		c0,0-5.2,1.1-5.2-5.2l2.9-41.3c0,0,0.4-4.4,5.8-5.2c0,0,5.5,0.3,8.4,0.1c0,0,1.8,0,4.1,0.1c0,0,20.1,1,42.8,7.7
		c0,0,23.8,6.3,62.7,24.6c0,0,4.3,1.5,5.1,4.1c0,0,0.6,1.7-2.3,3.3l-7.6,3.2c0,0-4,1.9-12.8,3.2c0,0-3.7,0.7-13.3,1l-14.6,0.7
		L512.6,250.7z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M664,317.9
		l17.6-11.7l30.8-3.2c0,0,0.3-4.1,0.3-9.5s-0.1-19.1-1.1-28.9c0,0-0.7-11.6-13.3-20.2l-28.3-13.8l-19.7,11.6c0,0-18,11.8-34.8,12.7
		l-119,5.6c0,0,2.8,7,2.9,12.2c0,0,0.4,17.6-1.7,47.5c0,0-0.8,13.1-2.6,21.3c0,0-2.1,10.7-3.2,14.6l15.8,12.1l45,1.2l15.8,15.7
		c0,0,0-20.2,5-34.9c0,0,4.1-22.3,24.8-35.6c0,0,12.7-9.4,31-9.2C629.1,305.5,646.8,304.2,664,317.9z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="217.6"
              cy="362.4"
              r="50.6"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="629.2"
              cy="362.4"
              r="50.6"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback2DrLeft);
