import React, { Component } from 'react';
import Select from 'react-select';

class SelectMenu extends Component {
  state = {
    searchText: '',
  };

  render() {
    const {
      title = '',
      isRequired = false,
      placeholder = 'Select',
      options,
      isDisabled = false,
      isLoading = false,
      isClearable = true,
      isMulti = false,
      closeMenuOnSelect = true,
      isSearchable = false,
      onChangeHandler = () => null,
      formikError,
      formikTouch,
      formatOptionLabel,
      hideIndicator = false,
      hideIndicatorSeparator = false,
      onInputChangeHandler = () => null,
      input = '',
      styles = {},
      defaultValue,
      value,
      menuIsOpen,
      onMenuOpen,
      onMenuClose,
    } = this.props;

    const customStyles = {
      control: base => ({
        ...base,
        minHeight: 46,
        borderRadius: '8px',
        border: '1px solid rgba(133, 140, 148, 0.5)',
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid rgba(133, 140, 148, 0.5)',
        },
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 6,
        color: 'rgba(133, 140, 148, 0.5)',
        display: hideIndicator ? 'none' : '',
      }),
      indicatorSeparator: base => ({
        ...base,
        backgroundColor: 'rgba(133, 140, 148, 0.5)',
        display: hideIndicatorSeparator || hideIndicator ? 'none' : '',
      }),
      valueContainer: base => ({
        ...base,
        minHeight: 46,
        padding: '0px 8px',
        fontSize: '16px',
        color: '#09101D',
        '@media screen and (max-width: 767px)': {
          fontSize: '16px',
        },
      }),
      input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        color: '#09101D',
      }),
      option: base => {
        return {
          ...base,
          cursor: 'pointer',
          borderBottom: '1px solid #EAEAEA',
          backgroundColor: '#fff',
          color: '#09101D',
          '&:hover': {
            backgroundColor: '#dcebef',
          },
        };
      },
      ...styles,
    };

    const clearableOnChangeHandler = e => {
      if (!e) e = { target: {}, value: '' }; // allows clearable to set value to ""
      onChangeHandler(e);
    };

    return (
      <div className="select-menu-input-field-container">
        <div>
          {title}
          {isRequired && <span className="text-red-500">*</span>}
        </div>
        <Select
          styles={customStyles}
          placeholder={placeholder}
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          onChange={clearableOnChangeHandler}
          formatOptionLabel={formatOptionLabel}
          onInputChange={onInputChangeHandler}
          inputValue={input}
          isSearchable={isSearchable}
          hideIndicatorSeparator={hideIndicatorSeparator}
          defaultValue={defaultValue}
          value={value}
          menuIsOpen={menuIsOpen}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
        {formikError &&
          formikTouch && (
            <div className="text-red-500 text-xs mt-1">{formikError}</div>
          )}
      </div>
    );
  }
}

export default SelectMenu;
