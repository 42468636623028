import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { find } from 'lodash';
import ToastContent from '../../ui/toastContent';
import Icon from '../../ui/icons';
import { CYAN_01 } from '../../../constants/colors';
import { loadVisited } from '../../../../../actions';

class MenuItem extends Component {
  render() {
    const { coreValidationError, section, visited } = this.props;
    const currentPath = window.location.pathname;
    const isActive = !!(
      currentPath === section.path ||
      (section.subSections && find(section.subSections, { path: currentPath }))
    );

    return (
      <div
        className={`sidebar-item ${isActive && 'selected-menu'} `}
        onClick={() => {
          if (coreValidationError) {
            toast(<ToastContent status="error" text={coreValidationError} />, {
              className: 'review-toast',
              toastId: coreValidationError,
            });
          } else {
            // this.props.loadVisited(section.section) / only on Next click
            toast.dismiss();
            this.props.navigate(section.path);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className="flex items-center">
            <Icon
              name={section.icon}
              isActive={isActive}
              color={`${isActive ? 'white' : CYAN_01}`}
            />
            <span className="mx-4.5">{section.title}</span>
          </div>
          {visited.includes(section.section) && (
            <div style={{ marginRight: 10 }}>
              <Icon
                name="Checkmark"
                color={`${isActive ? 'white' : CYAN_01}`}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { item, coreSections, coreValidationError, visited } = state.core;
  return { item, coreSections, coreValidationError, visited };
};

export default connect(mapStateToProps, { loadVisited })(MenuItem);
