import React, { Component } from 'react';
import { connect } from 'react-redux';

class Sedan4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M783.6,307.4l-0.8-0.5c0,0,2.1-0.1,2.3-0.1s0.3-0.3,0-0.7c-0.3-0.4-1.4-2.9-2.5-8.8
	c-1.1-5.9-2.8-13.3-4.1-17.7v-0.1c0.1,0,0.3-0.1,0.4-0.1c2.8-1.4,4.5-2.5,4.7-2.6c0.1,0,0.1-0.1,0.3-0.3c0,0,0-0.1,0.1-0.1
	c0,0,0.1,0.1,0.4,0c0.4-0.1,3.2-0.5,3.6-0.7c0.5-0.1,0.3-0.1,0.4-0.5c0-0.4-0.1-1.8-0.1-2.2c0-0.4,0-0.7-0.1-1.2s-1.1-5.8-3.6-10.5
	c-2.5-4.7-3.9-7.4-4.7-9.1c-0.7-1.6-0.1-6.2-0.1-6.6s0.1-1-0.3-1.4c-0.3-0.6-0.7-1.1-1-1.2c-0.1-0.3-0.4-0.4-1.1-0.4
	c-0.4,0-4.1,0-8-0.3c-3.2-0.1-6.6-0.6-8.8-0.8c-5-0.6-36.5-4.3-45.9-5.1c-0.8-0.1-1.9,0.1-2.5,0.3c-0.1,0-0.8,0.1-1.8,0.4
	c-53.9-25.4-80.3-34.7-105.8-42.8h-0.1v-0.1c0,0,0.1-0.4-0.4-0.6c-7.7-2.2-16.4-4.4-27.2-6.3l-0.3-0.1l-0.5-2.8
	c-0.1-0.7,0.3-1.6,0.5-2.3s1.1-2.6,1.2-3.2c0.3-0.6-0.1-1.1-0.8-1c-0.8,0.1-1.9-0.1-3.2,1.4c-1.2,1.4-4,3.7-4.8,3.7
	c-0.8,0-1.8,0.1-2.6,0.1c-0.8,0.1-1.8,0.7-1.9,1.2c-0.3,0.6-0.5,0.8-0.5,0.8h-0.4c-32.3-4.5-69.6-6.1-104.3-5.1
	c-60,1.6-87.2,8-106.2,14c-0.3,0.3-0.1,0.7-0.4,0.8c-44.6,17.5-90.1,45.9-108.9,57.6h-0.3c-10.2-1-18-1.8-18-1.8s-1.1,0.1-3.3,0.3
	c-22.6,1.6-44.7,3-68.4,6.1c-19.4,2.5-36.6,5.5-50.8,8.7c-32.6,7.2-50.5,18.2-57.8,25.3c-0.4,0.4-0.3,0.7,0,0.8
	c0.3,0.3,0.3,0.3,1,0.8l-0.3-0.1c0,0-1-0.3-1.1,0.1c-0.3,0.3-0.8,0.7-1,1s-0.3,0.5,0,0.8c0.3,0.3,0.8,0.3,1,0.5
	c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.7,0.1-0.1,0,0.5v0.3h-0.3c-3.6,0.5-7.6,0.8-7.8,1c-0.3,0.1-0.3,0.3-0.3,0.3s-1.1,0.4-1.2,0.8
	c-0.1,0.4-1.8,5.2-2.3,8c-0.5,2.9-1.5,4.7-1.5,5c-0.1,0.3,0.1,0.6,0.1,0.6l0.8,0.5c-0.1,0.1,0,0.4,0.3,0.4c0,0,1,0,1.9,0.5
	c0.1,0.1,0,0.4,0.1,0.6c0.1,0-2.1,0.3-2.1,0.4c0.1,0.1-1.2,1.2-1.1,1.4c0.3,0.1,0.8,0.1,0.8,0.4c0,1.9,0.1,3.7,1.1,4.8
	c-0.5,0-2.1,0.7-2.5,0.8c-0.3,0-0.5,1.2,0.3,1.5h0.1l0.3,0.3h-1.1c-0.3,0-0.4,0.3-0.4,0.5c0,0.3,0.3,0.4,0.3,0.4l-0.4,16l0.7,2.9
	c-0.4,0-0.7,1,0,1.1c0.4,0.1,3.4,0.3,3.4,0.3v0.4c-0.1,2.2,0,2.3,0,4l-0.7,0.3c0,0.1-0.1,0.3-0.3,0.3c-0.4,0.1-1.2,0.3-1.9,0.3
	c-0.4,0-0.7,1.2,0,1.4c0.3,0,1.4,0.1,2.2,0.3h0.1l0.3,0.4c0,1.4,0,3.6-0.1,4.1c0,0.7-1.9,1.7-2.2,1.7c-0.1,0-0.3,0.1-0.3,0.3
	c0,0.1,0.1,1.2,0.1,1.5c0,0.3,0.3,0.3,0.3,0.3l0.3,0.1c-0.4,0.1-0.4,0.1-0.4,0.4c0,0.6,0.7,0.6,1,1.6c0,0.4,1.8,0.8,1.8,1.2
	c0,0.3,0.3,2.2,0.1,2.3c-1.2,0.3-2.1,1.5-2.2,1.8c-0.5,0.5-3.2,1.2-2.9,1.5c-0.3,0.7,1.2,0.7,1.4,1c0.1,0.3,2.9,0.5,3.2,0.5
	c0.4,0.1,4.7,1.2,7.6,2.1c2.9,0.7,8.8,1.1,14,1.6c2.6,0.3,13.5,1.4,25.5,2.3c11.1,1,18.6,1.4,25.4,2.1c0.7,0,2.9,0.3,3.4,0.3h1.5
	v-0.3v0.3V377c0-0.8,0.1-19.3,1.2-29.3c2.9-27.9,27.2-54.8,62-54.6c23.5,0.1,41,12.2,50.8,25.7c10.9,15.4,12.1,32.7,12.4,44.2
	c0.3,10.2,0.1,10.5,0.1,12.7v5.4c0,0,0.7,1,1.6,1s292.7-3,323.3-2.9c0.6,0,1-0.7,1.4-1.2c0.4-0.7,0.6-1.2,0.6-1.2s0.4-0.8,0.4-1.4
	c0-0.5-0.1-7.4-0.1-13.5c0.1-15.8,4.3-31,13.1-42.6c11.8-15.5,31.1-25.2,50.1-25.2c21.7,0.1,38.1,9.9,48.6,23
	c5,6.1,8.4,12.9,10.9,19.7c4.7,12.8,4.1,26.4,4,34.4c0,0.5,0.1,0.8,0.7,0.7c0,0,10.9-1.4,25.3-3l1.2-0.4c9.6-1.1,20.5-2.5,28.6-3.4
	c0,0,11.8-1.8,13.3-2.1c0.1,0,0.4-0.1,0.7-0.3l0.4-0.3c0,0-0.3,1.1,1.2,1.1c3.4,0,10-0.7,15.7-1.8c5.5-1.1,9.9-2.3,13.6-2.9
	c0.3,0,0.8-0.4,1.1-0.8c0.3-0.3,0.3-0.7,0.4-1c0.1-0.3,0.3-0.5,0.1-0.8c-0.3-0.7-1.5-2.1-1-3c1.5-2.6,4.1-5.8,4.4-6.9
	c0.4-1.6-1.1-3.9-1-4.4c0.1-0.5-0.1-0.7-0.1-0.7l0.8-0.1c0,0,0.7-0.1,0.8-0.4c0,0,0-3.2-1.1-5.5c0.5-2.1,1.1-6.1,1.1-8.1
	c0-7.4-0.7-10.9-1.1-12.1c-0.4-1.2-0.7-1.7-1.5-2.2c-0.5-0.4-1.8-0.7-2.8-1.2c-1.7-1-3.2-1.9-3.6-2.3c-0.5-0.5-1.4-0.5-1.9-0.5
	C786.6,307.8,783.6,307.4,783.6,307.4L783.6,307.4z M239.6,381.4l325.2-6.1c-0.1,0.3-0.3,0.5-0.3,0.5s-0.3,0.7-0.5,1.2
	c-0.4,0.7-0.8,1.2-1.4,1.2C532.6,378.4,249.7,381.4,239.6,381.4L239.6,381.4z"
          />
          <path
            fill="#5F605F"
            d="M760.7,362c0.1,0,0.4-0.1,0.7-0.3l0.4-0.3c0,0-0.3,1.1,1.2,1.1c3.4,0,10-0.7,15.7-1.8c1.8-0.3,3.4-0.7,5-1.1
	c0.4-0.1,0.4-0.8,0.4-0.8l0.8-1.2l0.8-1.8l0.8-2.3l0.8-2.3l0.5-1.5v-0.3l-0.1-0.4l-0.6-0.3h-0.8l-2.3,0.4l-2.8,0.4l-2.3,0.7
	l-2.1,1.1l-1.9,1.5l-3.6,3.4l-2.6,2.5l-1.5,1.1l-2.2,0.8l-1.7,0.3l-1.5,0.5L760.7,362L760.7,362z M45.8,292.4l-0.3-0.1
	c0,0-1-0.3-1.1,0.1c-0.3,0.3-0.8,0.7-1,1s-0.3,0.5,0,0.8c0.3,0.3,0.8,0.3,1,0.5c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.7,0.1-0.1,0,0.5v0.3
	h-0.3c-3.6,0.5-7.6,0.8-7.8,1c-0.3,0.1-0.3,0.3-0.3,0.3s-1.1,0.4-1.2,0.8c-0.1,0.4-1.8,5.2-2.3,8c-0.5,2.9-1.5,4.7-1.5,5
	c-0.1,0.3,0.1,0.5,0.1,0.5l0.8,0.5c-0.1,0.1,0,0.4,0.3,0.4c0,0,1,0,1.9,0.5c0.1,0.1,0,0.4,0.1,0.5c0.1,0-2.1,0.3-2.1,0.4
	c0.1,0.1-1.2,1.2-1.1,1.4s0.8,0.1,0.8,0.4c0,1.9,0.1,3.7,1.1,4.8c-0.5,0-2.1,0.7-2.5,0.8c-0.3,0-0.5,1.2,0.3,1.5h0.1l0.3,0.3h-1.1
	c-0.3,0-0.4,0.3-0.4,0.6c0,0.3,0.3,0.4,0.3,0.4l-0.4,16l0.7,2.9c-0.4,0-0.7,1,0,1.1c0.4,0.1,3.4,0.3,3.4,0.3v0.4c-0.1,2.2,0,2.3,0,4
	l-0.7,0.3c0,0.1-0.1,0.3-0.3,0.3c-0.4,0.1-1.2,0.3-1.9,0.3c-0.4,0-0.7,1.2,0,1.4c0.3,0,1.4,0.1,2.1,0.3h0.1l0.3,0.4
	c0,1.4,0,3.6-0.1,4.1c0,0.7-1.9,1.6-2.2,1.6c-0.1,0-0.3,0.1-0.3,0.3s0.1,1.2,0.1,1.5c0,0.3,0.3,0.3,0.3,0.3h0.1l0.8-0.4l0.7-0.1
	l0.8-0.6l0.1-1.5l0.1-2.8l0.1-3.3l0.3-5.9l0.3-5.1l0.4-4.4l0.5-5.4l1-6.3l1-5.1l1.1-5.2l1.4-4.5l1.1-3l1.4-3.4l1.9-4.1l0.4-0.8
	l0.5-0.5l0.5-0.3l0.7-0.4l0.8-1L46,296l-1,0.1v-0.4l0.5-1l0.3-1.2L45.8,292.4L45.8,292.4z M629.1,300.7c-28.6-0.1-52,23-52.1,51.7
	c0,8.4,1.9,16.4,5.5,23.5c8.5,16.9,26,28.6,46.2,28.6c20.5,0.1,38.4-11.8,46.9-29.2c3.3-6.9,5.2-14.4,5.2-22.6
	C680.8,324.1,657.7,300.8,629.1,300.7z M174.7,300.5c-28.6-0.1-52,23-52.1,51.7c0,10.5,3,20.4,8.4,28.5
	c9.2,14.2,25.2,23.5,43.3,23.7c18.3,0.1,34.4-9.4,43.7-23.5c5.2-8.1,8.4-17.7,8.4-28.1C226.4,324.1,203.3,300.7,174.7,300.5
	L174.7,300.5z"
          />
          <path
            fill="#E9EAE9"
            d="M586.7,352.3c0.1-22.4,18.7-40.6,41.5-40.4c22.8,0.1,41.3,18.4,41.3,40.9c-0.1,22.4-18.7,40.6-41.5,40.4
	C605,393,586.6,374.8,586.7,352.3z M133.9,352.3c0.1-22.4,18.4-40.6,40.9-40.4c22.4,0.1,40.6,18.3,40.4,40.9
	c-0.1,22.4-18.4,40.6-40.9,40.4C151.9,393,133.7,374.7,133.9,352.3L133.9,352.3z"
          />
          <path
            fill="#5F605F"
            d="M783.2,259.4c0.7,1.4,1.4,2.9,2.3,5c0.8,1.8,1.5,3.6,1.9,5.1l0.3-0.8c0,0-0.1-2.3-1.5-5.2
	c-1.4-2.9-2.3-4-2.2-4L783.2,259.4L783.2,259.4z"
          />
          <path
            fill="#E9EAE9"
            d="M80.8,287.6c-12.5,1.5-24.8,4.1-32.3,7.2c-0.3,0.1-0.3,0.4-0.4,1c0,0-1.7,5.4-1.7,7.7s0.5,3,1.7,3
	c1.1-0.1,8.4-0.8,10.5-1c1.2-0.1,2.3-0.3,3.4,0.3c1.1,0.5,7.2,3.2,8,3.6c1,0.4,1.9,0.5,3.3,0.5c1.4,0,14.6-0.8,18.4-1.4
	c4.5-0.7,6.9-2.2,10.2-4.8c3.2-2.6,12.4-10.7,14-12.1c1.9-1.6,2.8-4.4-1.5-4.7C109.8,286.6,94.8,285.9,80.8,287.6L80.8,287.6z"
          />
          <path
            fill="#5F605F"
            d="M60.3,340.4c-3.3,0.3-8.4,0.7-13.1,1.1l-2.6,0.3l0.1,0.8l0.5,0.5l-1.7,5.6l-2.6,0.5l-1.7,0.3l-0.7,0.4
	l0.1,0.8l0.5,0.3l3.6,0.1l-1.2,4.8l-1.2,0.3l-1.2,0.3l-0.7,0.1l-0.3,0.5l0.3,0.5l0.5,0.1H41l-0.5,1.2l-0.8,0.7l-0.7,0.3l-6.6,1.4
	l1.1,1l1,0.7l0.1,1.4l3,0.7l2.8-0.1l3.7-0.3l5-0.3c4.3-0.3,9.6-0.5,14.4-0.8c1.8-0.1,3.4-0.3,5-0.3c4.1-0.3,7.2-0.4,8.1-0.5
	c2.3-0.1,2.6-0.3,3.9-1.8c0-0.1,0.1-0.4,0.1-0.6c2.2-6.3,2.3-16.4,1.9-18.6c-0.3-2.2-2.9-2.3-3.6-2.3
	C78.4,339.5,66.4,340.1,60.3,340.4L60.3,340.4z"
          />
          <path
            fill="#E9EAE9"
            d="M63,346.5c-1.4,0.5-3,2.2-2.9,5.4c0.3,4.4,3.2,5.9,4.4,6.2h0.4c0.7,0,2.5-0.5,3-3.3c0.1-0.7,0.3-1.7,0.3-2.6
	c-0.1-5.2-2.6-6.2-4.3-6.1C63.8,346.2,63.4,346.3,63,346.5L63,346.5z"
          />
          <path
            fill="#5F605F"
            d="M78.7,375.8c-8.9-0.8-16.9-1.5-21-1.9v3.4c0,0,0,0.3,0.3,0.3l19.9,0.3c0.3,0,0.6-0.1,0.6-0.4
	C78.5,377.2,78.5,376.5,78.7,375.8L78.7,375.8z M174.4,292.5c-34.9-0.1-59.3,26.7-62,54.6c-1.1,10-1.2,28.5-1.2,29.3v1.1l0.1,0.1
	v2.9l19.5,0.1h0.3c0,0,0-0.1-0.1-0.1c-5.4-8.3-8.4-18-8.4-28.5c0.1-28.6,23.4-51.9,52.1-51.7c28.6,0.1,51.7,23.5,51.6,52.1
	c0,10.3-3.2,20.1-8.4,28.1c-0.1,0.1-0.1,0.3-0.3,0.4h0.1l21,0.1c-0.7-0.1-1-0.6-1-1v-5.4c0-2.2,0.1-2.5-0.1-12.7
	c-0.4-11.4-1.5-28.8-12.4-44.2C215.6,304.8,198.1,292.7,174.4,292.5L174.4,292.5z"
          />
          <path
            fill="#E9EAE9"
            d="M306.1,248.7c1.1-0.1,2.2-0.3,3-0.4c3-0.3,9.2-0.8,20.5-0.4c0-0.6,0.1-1,0.1-1.5
	c-11.4-0.3-17.6,0.3-20.6,0.4c-1.2,0.1-3.2,0.4-4.7,0.7c0,0.1-0.1,0.3,0,0.3c0.1,0.1,0.7,0.6,1,0.8
	C305.7,248.8,305.8,248.7,306.1,248.7L306.1,248.7z"
          />
          <path
            fill="#5F605F"
            d="M397.1,272.9c0.1,0.7,0.3,1.6,0.4,2.1c0.1,0.4,0.5,0.8,1.1,1c0.3,0,2.5,0.3,5.2,0.4h0.7c0.3,0,0.4,0,0.5-0.1
	s0.5-0.7,2.6-1c2.5-0.3,9.8-0.5,12.2-0.3c1.1,0.1,2.2,0.3,2.6,1c0.1,0.3,0.4,0.4,0.8,0.3c0.4-0.1,3.6-0.3,5.9-0.8
	c0.7-0.1,1.5-0.7,1.8-1.4c0.1-0.5,0.3-1.8,0.3-2.5c0.1-0.7-0.3-1.2-1.2-1.5c-0.5-0.1-2.6-0.4-5.6-0.5h-2.1c-2.2,0-4.7,0-7.7,0.1
	c-4,0.1-7.7,0.4-10.3,0.8c-3.4,0.4-5.4,1-6.1,1.2C397.3,272.1,397,272.2,397.1,272.9L397.1,272.9z M547.6,269.9
	c0.1,0.7,0.3,1.6,0.4,2.1c0.1,0.4,0.5,0.8,1.1,1c0.3,0,2.5,0.3,5.2,0.4h0.7c0.3,0,0.4,0,0.5-0.1s0.5-0.7,2.6-1
	c2.5-0.3,9.8-0.5,12.2-0.3c1.1,0.1,2.2,0.3,2.6,1c0.1,0.3,0.4,0.4,0.8,0.3s3.6-0.3,5.9-0.8c0.7-0.1,1.5-0.7,1.8-1.4
	c0.1-0.5,0.3-1.8,0.3-2.5c0.1-0.7-0.3-1.2-1.2-1.5c-0.5-0.1-2.6-0.4-5.6-0.5h-1.9c-2.2,0-4.7,0-7.7,0.1c-4,0.1-7.7,0.4-10.3,0.8
	c-3.4,0.4-5.4,1-6.1,1.2C547.8,268.9,547.5,269.2,547.6,269.9L547.6,269.9z M300.2,252.4c-0.8-1-1.9-2.5-2.3-3
	c-0.7-1.1-0.5-1.8-0.5-2.3s0.4-1.1,1.1-1.4c0.4-0.3,1.4-0.6,1.9-0.8c1.1-1.2,5.1-5.5,7.3-7.7c0.1-0.1,0.1-0.1,0.3-0.3
	c-8.7,5.9-17.7,12.8-25,18.6c5.9-0.3,12.9-0.7,20.4-1C302,254,300.9,253.2,300.2,252.4L300.2,252.4z M563.2,184.8
	c3.2,0.4,6.3,1,9.4,1.4c1.2,0.3,2.5,0.4,3.7,0.7c-0.1-0.6-0.3-1.8-0.4-2.5v-0.3c-0.1-0.7,0.3-1.6,0.6-2.3s1.1-2.6,1.2-3.2
	c0.3-0.6-0.1-1.1-0.8-1c-0.8,0-1.9-0.1-3.2,1.4c-1.2,1.4-4,3.7-4.8,3.7l-2.6,0.1c-0.8,0.1-1.8,0.7-2.1,1.2c-0.3,0.6-0.5,0.8-0.5,0.8
	L563.2,184.8L563.2,184.8z"
          />
          <path
            fill="#E9EAE9"
            d="M747.5,282.5c-1.9-8.3-4.4-16.1-4.8-17.2c-0.5-1.6-0.4-3.2,2.3-3.9c2.8-0.7,9.5-2.3,21.5-2.9
	c7.6-0.4,10.9,0.5,11.6,0.7c0.7,0.1,1.9,0.8,2.9,2.8c1.2,2.2,2.6,8.7,2.8,9.9c0.1,0.8,0.1,1.1,0.1,1.5v2.1c0,0.1-0.1,0.4-0.1,0.5
	c-0.1,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-1.9,1.1-4.7,2.6l-0.4,0.1h-0.1c-0.7,0.3-1.4,0.7-2.2,1c0,0-1.2,0.3-2.1,0.4
	C767.9,282.1,758.4,282.4,747.5,282.5L747.5,282.5z"
          />
          <path
            fill="#5F605F"
            d="M628.1,293.4c-19-0.1-38.2,9.5-50.1,25.2c-8.8,11.7-13.1,26.8-13.1,42.6c0,6.1,0.1,12.9,0.1,13.5
	c0,0.3-0.1,0.5-0.1,0.7l17.3,0.1c-3.4-7-5.4-14.9-5.4-23.1c0.1-28.6,23.4-51.7,52.1-51.7c28.6,0.1,51.7,23.4,51.7,52.1
	c0,8-1.8,15.4-5.1,22.1l15.7-1l26.4-6.1l0.4-0.3l-0.7,0.3c-14.4,1.6-25.3,3-25.3,3c-0.5,0.1-0.7-0.1-0.7-0.7c0-8,0.7-21.6-4-34.4
	c-2.5-6.7-5.9-13.6-10.9-19.7C666.1,303.3,649.7,293.4,628.1,293.4L628.1,293.4z"
          />
          <path
            fill="#E9EAE9"
            d="M608.2,197.1c-1.2,0.4-5,2.2-5.5,2.5c-0.5,0.3-1.9,1,0.7,1.8c2.6,0.8,19,6.5,44.3,16.9
	c22.1,9.1,40.4,17.3,42.6,18.3c1.7,0.7,3.2,1,5.4,0.6c1.6-0.3,8.1-1.4,11.1-1.8c-44.4-20.8-69.9-30.4-92-37.8
	c-0.3,0.3-0.8,0.7-1.8,0.4c-1.2-0.4-2.5-0.8-3-1C609.5,196.8,609.3,196.7,608.2,197.1L608.2,197.1z M596.3,242.1
	c0.4,0.8,0.8,1.1,1.4,1.1s35.4-0.1,37.6,0c2.3,0,4.5-1.9,0.7-4.7c-2.6-1.9-15.1-8.4-31.1-15.7c-7.3-3.3-15.5-6.9-23.5-10.2
	c-1-0.4-1.6,0-1.1,1C580.8,214.4,595.9,241.2,596.3,242.1L596.3,242.1z M470.9,211.8c-2.1,9.4-5.8,34.3-6.2,36.7
	c5.6-0.1,10.7-0.3,15.4-0.3c22.4-0.4,52.6-1,85.8-1.2c0.3-2.2,2.6-16.8,2.8-17.9c0.1-1-0.1-2.2-0.6-3.3s-4.3-8.3-6.9-12.4
	c-3-5.1-5.9-7.2-13.2-8.7c-7.2-1.4-46.9-6.6-57.5-6.9C480,197.8,473.1,201.9,470.9,211.8L470.9,211.8z M329.6,243.6
	c0,0.6,0,1.5-0.1,2.5c0,0.6-0.1,1-0.1,1.5c-0.1,0.8-0.1,1.6-0.3,2.3c-0.3,1.2-0.7,2.2-1.5,2.9c30.7-1.2,68.1-2.5,100.6-3.4
	c1.5-4.3,11.6-32.1,12.8-34.8c2.3-5.4,2.6-9.1,1.5-11.6c-1.6-3.4-5.8-5-9.4-4.7c-8.9,0.4-30.1,1.6-50.2,6.7
	c-8.3,2.1-18.7,5.6-29.6,10.7c-13.8,6.5-27.5,14.4-30.5,16.2c0.5,0.1,1.1,0.4,1.8,0.7C326.9,234.1,329.6,237,329.6,243.6
	L329.6,243.6z M263.3,253.2c34.9-26.7,75.2-47.3,99.3-56.3c-1.4-0.4-6.2-1.9-9.4-2.9c0,0.1-0.1,0.1-0.3,0.3
	c-44.3,17.3-89.8,45.8-109.1,57.6c5.2,0.6,11,1.1,16.5,1.6c0.4,0,1.4,0.3,1.4,0.3c0.4,0,0.7,0.1,1.1,0.1
	C262.9,253.8,263,253.3,263.3,253.2L263.3,253.2z"
          />
          <path fill="#5F605F" d="M572.8,225.1l-3,21.6h14.4" />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M469.8,250.5l0.5,1 M31.8,359.7c0,0-0.5,0.1-0.3,0.6c0.1,0.5,0.4,1,0.4,1
	s0.1,0.4,0.8,0.3c-0.7,0.1,0,0.5,0.1,0.7c0.1,0.1,1.4,0.5,1.4,0.5v2.2c-0.8,0.4-2.6,1.2-2.2,1.9c-0.8,0.3-2.9,1-3,1.5
	c-0.1,0.4,0.4,0.6,0.8,0.7c0.4,0.1,9.2,2.1,12.1,2.8c3.7,0.7,8.1,1.2,13.3,1.6c2.6,0.3,13.5,1.4,25.5,2.3c11.1,1,21,1.6,27.9,2.3
	c0,0,0.8,0.1,1.1,0.1h1.5v-1.6c0-0.8,0.1-19.3,1.2-29.3c2.9-27.8,27.2-54.6,62-54.5c23.5,0.1,41,12.2,50.8,25.7
	c10.9,15.4,12,32.7,12.4,44.2c0.3,10.2,0.1,10.5,0.1,12.7v5.4c0,0.6,0.7,1,1.6,1s292.7-3,323.3-2.9c0.5,0,1-0.7,1.4-1.2
	c0.4-0.7,0.5-1.2,0.5-1.2s0.4-0.8,0.4-1.4s-0.1-7.4-0.1-13.5c0.1-15.8,4.3-31,13.1-42.6c11.8-15.5,31-25.2,50.1-25
	c21.7,0.1,38.1,9.9,48.6,23c5,6.1,8.4,12.9,10.9,19.7c4.7,12.8,4.1,26.3,4.1,34.3c0,0.5,0.1,0.8,0.7,0.7c0,0,25.9-3,36.6-4.7
	c6.6-0.8,13.2-1.6,18.6-2.2c2.9-0.4,11.1-1.8,12.5-1.9c1.4-0.3,2.2-1,3.6-1.2c1.4-0.3,3.6-0.5,5.2-2.2c1.7-1.6,5-4.8,6.1-5.9
	c1.1-1,3-2.6,6.3-3.2c3.3-0.5,4.7-0.8,5.4-0.8c0.4,0,0.8,0,1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M559.5,372.6c0-2.9-0.1-7.3-0.1-11.3c0.1-17.2,4.7-33.6,14.3-46.2
	c12.8-16.9,33.7-27.4,54.3-27.2c23.5,0.1,41.3,10.7,52.8,24.9c5.4,6.6,9.1,14,11.8,21.3c2.8,7.7,3.9,15.4,4.3,22.3 M241.8,349.9
	c-1.2-10.6-4.4-23-12.5-34.4c-10.5-14.6-29.3-27.6-54.8-27.8c-37.7-0.1-64,28.8-67.1,58.9c-0.1,0.8-0.1,1.8-0.3,2.9
	c0,1.6-0.4,2.8-1.8,2.9c0,0-16.6,0.4-18.6,0.4 M111.3,369.6c-11.3,0-59.4-1.1-81.9-1.2 M272.6,256.2c0,0,0.1-0.6-0.4-0.6
	c-3.9-0.4-7.4-1.1-11-1.4c-0.5,0-1.5,0.6-1.5,0.6c-8.7,3.2-11.3,5.5-29,6.7c-19,1.2-47.2,3.6-73,6.3c-25.9,2.8-60.5,9.8-78.1,19.4
	c-0.3,0.1-1.1,0.5-2.1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M45.7,292.4c-0.7-0.7-0.5-0.7-1-0.8c-0.3-0.3-0.4-0.4-0.1-0.8
	c7.3-7.2,25.3-17.9,57.9-25.2c14-3.2,31.2-6.2,50.6-8.7c23.7-3,45.8-4.4,68.4-6.1c2.2-0.1,3.3-0.3,3.3-0.3s18.2,1.6,34.9,3.4
	c0.4,0,0.4,0.4,0.4,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M59.2,285.3c10.3-5.9,33.2-15.1,72.9-20.5c39.8-5.4,83.9-8.7,126.8-10.5
	 M743.1,261.7c-14.6-0.7-29-1.5-43.9-1.9c-81.4-2.1-166.5-1.4-219-0.3c-62.3,1.2-159.4,3.6-213.4,6.1c-52.8,2.3-140.7,6.7-174.4,21
	 M604.5,194.2c25.5,8.1,51.9,17.5,105.8,42.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M710.1,237c1.1-0.3,1.8-0.4,2.1-0.4c0.5-0.1,1.7-0.3,2.5-0.3
	c9.5,1,41,4.5,45.8,5.1c2.2,0.3,5.5,0.6,8.8,0.8c3.9,0.3,7.7,0.3,8,0.3c0.7,0,1,0.1,1.1,0.4c0.1,0.3,0.7,0.8,1,1.2
	c0.3,0.6,0.3,1.1,0.3,1.4c0,0.4-0.5,5,0.1,6.6s2.1,4.3,4.7,8.9c2.5,4.7,3.4,9.9,3.6,10.5c0.1,0.5,0.1,0.8,0.1,1.2
	c0,0.4,0.1,1.9,0.1,2.2c0,0.4,0.1,0.4-0.4,0.5c-0.5,0.1-3.2,0.7-3.6,0.7c-0.4,0.1-0.4-0.1-0.4-0.1 M697.9,239.7
	c0,0,8.3-1.8,12.2-2.8 M779.3,245c-5.2,2.3-18.8,9.5-27.2,14.9 M777.6,242.5c-5.5,2.1-18.4,9.4-33.2,18.6 M778.3,279.5
	c1.2,4.4,3,12,4.1,17.7c1.1,5.9,2.1,8.4,2.5,8.8c0.3,0.4,0.3,0.7,0,0.7h-5.1 M688.8,324.5c0,0,48.3-5.1,67.1-7.4
	c18.7-2.3,25.6-2.9,40.3-4 M691.8,359l57.2-6.5c7.3-0.8-22.8,0.7-51.6,3.7c-1.8,0.3-5.9,0.7-5.9,0.7 M556.2,336.6c0,0,5-0.7,5.9-0.8
	c1-0.1,1.4-0.1,2.2-0.1h4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M676.8,316.4c0,0,4.4-0.7,5-0.8c1.2-0.3,2.9-0.4,2.9-0.4
	s38.7-3.9,52.3-5.6c13.5-1.6,14.2-3.4,13.1-13.2c-0.3-2.9-1-7.2-1.9-11.1c-0.1-0.8-0.4-1.8-0.6-2.6c-1.9-8.3-4.4-16.1-4.8-17.1
	c-0.5-1.7-0.4-3.2,2.3-3.9c2.8-0.7,9.5-2.3,21.5-2.9c7.6-0.4,10.9,0.5,11.6,0.7s1.9,0.8,2.9,2.8c1.2,2.2,2.6,8.7,2.8,9.9
	c0.1,0.8,0.1,1.1,0.1,1.5v2.1c0,0.3-0.3,1-0.5,1.2c-0.1,0.1-3,1.8-7.2,3.7c0,0-1.2,0.3-2.1,0.4c-6.3,1.2-15.8,1.5-26.7,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M766.2,258.7c0,0,0.1-0.4,0-0.5c-0.1-0.1-0.4-0.5-1.1-1.8
	c-0.7-1.2-0.7-3-0.7-3.3c0-0.3,0.1-0.7-0.1-1c-0.3-0.4-1.4-1.5-1.4-1.5c-0.3-0.3-0.5-0.4-1-0.4c-2.9-0.4-33.6-4.8-49.4-7
	c-5.6-0.8-11.4-2.2-16.2-3.6c-5.9-1.8-14.2-5.6-14.2-5.6c-20.8-8.9-45.4-20.5-88.5-34.7c-12.5-4.1-30.5-7.8-51.7-10.6
	c-23.8-3-51.3-4.8-79.1-4.5c-35.9,0.4-72.8,4.3-99.7,12.9c-23.9,8.7-64.8,29.6-100.4,56.7c-0.4,0.3-0.3,0.7-0.3,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M363.4,196.8c0,0.7-0.4,0.8-1.5,1.4c-24.2,9.1-61.9,29.3-96.4,55.6
	c-0.4,0.3-0.5,0.8-0.5,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M604.3,194.1c0-0.1,0.1-0.6-0.4-0.7c-8.7-2.5-18.4-4.8-31.2-6.9
	c-34.4-5.6-75.5-7.6-113.6-6.6c-60,1.6-87.2,8-106.2,14c-0.3,0.3-0.1,0.7-0.4,0.8c-44.6,17.5-90,45.8-108.9,57.5 M604.5,194.2
	c0,0-3.3,1.2-11,4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M462.4,189.7l-15.7,59.3c0,0-0.1,0.3-0.3,0.8c-0.1,0.6-0.4,1.8-0.4,1.8
	c-0.7,1.2-3.6,5-4.8,8.7c0.1,0.4-0.3,2.3-0.5,3.6c-2.1,8.8-5.8,18.6-5.9,49.1c-0.1,17.1,0.4,27.4,0.6,28.9c0.1,1.5,2.2,19,2.3,19.5
	c0.1,0.5,0.3,1.1,0.3,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M591,246.7c0,0,1.5,1.9,2.1,2.8c1.5,2.2,2.8,3.4,4.3,6.3
	c0.7,1.4,1.1,2.2,1.2,3.4c0.1,1.6-0.5,3.7-1.7,7c-1.8,5.4-15,27.9-27.2,48c-8,13.1-15.5,25.2-18.8,30.3
	c-8.9,13.2-15.8,16.5-34.5,17.3c-31.2,1.5-130.5,3.6-278.2,5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M552.3,342.2c-8.9,13.2-17.3,17.3-36,18.2c-31.2,1.4-130.5,3.6-278.2,5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M264.9,365.5c-1.1-7.6-2.1-14.3-2.2-15.1c-0.1-0.8-0.3-2.2-0.3-2.2
	c-0.3-1.6-1.2-13.1-1.1-34.9c0.1-21.9,2.8-38.7,4.1-44.6c0.1-0.8,0.4-2.6,0.8-3.4c0.7-1.2,1-2.5,4.8-7 M352.7,193.9
	c5.2,1.6,10,3,10,3 M352.6,194.6c3.7,1.1,9.1,2.8,9.1,2.8 M429.3,275.6c0.7-0.1,1.5-0.7,1.8-1.4c0.1-0.5,0.3-1.8,0.3-2.5
	c0.1-0.7-0.3-1.2-1.2-1.5c-0.5-0.1-2.6-0.4-5.6-0.5c-2.5-0.1-5.6-0.1-9.8,0.1c-4,0.1-7.7,0.4-10.3,0.8c-3.4,0.4-5.4,1-6.1,1.2
	c-1.1,0.4-1.4,0.7-1.2,1.2c0.1,0.7,0.3,1.6,0.4,2.1c0.1,0.4,0.5,0.8,1.1,1c0.3,0,2.5,0.3,5.2,0.4c0.8,0,1.1,0,1.2-0.1
	c0.1-0.1,0.5-0.7,2.6-1c2.5-0.3,9.8-0.4,12.2-0.3c1.1,0.1,2.2,0.3,2.6,1c0.1,0.3,0.4,0.4,0.8,0.3C423.8,276.3,427,276.2,429.3,275.6
	L429.3,275.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M404.7,276.5c0.7,1.4,1.2,3,5,3.3c2.8,0.1,4.7,0.3,7.8,0.1s5-1.9,5.5-3.4
	 M398.7,275.4c1.6-0.7,4.8-0.8,11.1-1c6.3-0.1,18.2-0.7,20.2,0.8 M421.8,271.4c-0.1,0-0.3,0.1-0.3,0.3v0.8c0,0.1,0.1,0.3,0.3,0.3
	h1.5c0.1,0,0.3-0.1,0.3-0.3v-0.8c0-0.1-0.1-0.3-0.3-0.3H421.8L421.8,271.4z M404.9,270.5c0.4-1.4,1.8-2.9,4.8-3.2c3-0.1,7-0.1,9.1,0
	c2.1,0.1,3.4,1.1,3.9,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M424.7,269.9c0.1,0.1,0.4,0.3,0.4,0.7v3.3c0,0.4-0.1,0.8-0.1,1.1
	c0,0.4-0.3,1.2-0.3,1.2"
          />
          <ellipse
            transform="matrix(0.982 -0.1887 0.1887 0.982 -43.7062 85.6828)"
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="428.1"
            cy="272.4"
            rx="1.2"
            ry="1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M579.7,272.5c0.7-0.1,1.5-0.7,1.8-1.4c0.1-0.5,0.3-1.8,0.4-2.5
	c0.1-0.7-0.3-1.2-1.2-1.5c-0.5-0.1-2.6-0.4-5.5-0.5c-2.5-0.1-5.6-0.1-9.8,0.1c-4,0.1-7.7,0.4-10.3,0.8c-3.4,0.4-5.4,1-6.1,1.2
	c-1.1,0.4-1.4,0.7-1.2,1.2c0.1,0.7,0.3,1.7,0.4,2.1c0.1,0.4,0.6,0.8,1.1,1c0.3,0,2.5,0.3,5.2,0.4c0.8,0,1.1,0,1.2-0.1
	c0.1-0.1,0.5-0.7,2.6-1c2.5-0.3,9.8-0.4,12.2-0.3c1.1,0.1,2.2,0.3,2.6,1c0.1,0.3,0.4,0.4,0.8,0.3C574.3,273.2,577.5,273,579.7,272.5
	L579.7,272.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M555.1,273.4c0.7,1.4,1.2,3,5,3.3c2.8,0.1,4.7,0.3,7.8,0.1
	c3.2-0.1,5-1.9,5.5-3.4 M549,272.3c1.7-0.7,4.8-0.8,11.1-1c6.3-0.1,18.2-0.7,20.2,0.8 M572.1,268.3c-0.1,0-0.3,0-0.3,0.3v0.8
	c0,0,0.1,0.3,0.3,0.3h1.5c0.1,0,0.3,0,0.3-0.3v-0.8c0-0.1-0.1-0.3-0.3-0.3L572.1,268.3L572.1,268.3z M555.3,267.4
	c0.4-1.4,1.8-2.9,4.7-3.2c3-0.3,7-0.1,9.1,0c2.1,0.1,3.4,1.1,3.9,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M575.2,266.7c0.1,0.1,0.4,0.3,0.4,0.7v3.3c0,0.4-0.1,0.8-0.1,1.1
	c0,0.4-0.3,1.2-0.3,1.2"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="64.4"
            cy="336.7"
            rx="0.8"
            ry="1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237.8,375.2c46.4,0.1,250.9-1,327.3-0.5 M345,197.8
	c1.1,0.4,2.8-0.7,4.3-0.3c1.5,0.4,6.2,2.3,6.2,2.3 M250.1,248.3l17.7,1.4 M614.6,197.6c0,0-0.4,0.8-1.7,0.4s-2.5-0.8-3-1
	c-0.5-0.3-0.8-0.4-2.1,0.1c-1.2,0.4-5,2.2-5.5,2.5c-0.5,0.3-1.9,1,0.7,1.8c2.6,0.8,18.8,6.5,44.3,16.9c22.1,9.1,40.3,17.3,42.6,18.3
	c1.7,0.7,3.2,1,5.4,0.6c2.2-0.3,11.3-1.6,11.3-1.6 M772.6,281.5c0,0,2.8,12.5,4.4,19.4c1.5,6.9,5.1,7.3,5.5,7.4
	c1.7,0.3,2.6,0.3,6.3-0.1 M783.3,259.4l0.8,0.1c0,0,1,1.2,2.2,4.1c1.2,2.9,1.4,5.2,1.4,5.2l-0.1,0.8 M44.6,296c-3.6,0.3-7.8,1-8.1,1
	s-0.3,0.1-0.3,0.1s-1.1,0.5-1.4,1c-0.1,0.4-1.1,3.4-1.8,6.2c-0.7,2.8-1.8,6.2-1.9,6.6c-0.1,0.3,0.1,0.5,0.1,0.5l0.8,0.5
	c-0.1,0.1,0,0.4,0.1,0.6c0.3,0.1,1.5,0.4,1.5,0.4c0.1,0,0.3,0.1,0.3,0.3v0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M36.2,297.1c0.3,0,0.5,1.2-1.2,7.3c-2.2,7.7-2.8,7.4-2.8,7.4 M31.5,323.1
	h-1.1c-0.3,0-0.4,0.3-0.4,0.4c0,0.3,0.3,0.4,0.3,0.4s-0.4,15.4-0.4,16c0,0.3,0.1,0.7,0.1,1c0.1,0.3,0.4,1.4,0.4,1.4s0.1,0.4,0,0.5
	c-0.1,0.1-0.4,0.8,0,0.8c0.4,0.1,3.7,0.7,3.7,0.7 M30.1,324.1h1.9 M669.4,352.7c-0.1,22.4-18.4,40.6-40.9,40.4
	c-22.4-0.1-40.6-18.4-40.4-40.9c0.1-22.4,18.3-40.6,40.9-40.4C651.4,311.9,669.5,330.2,669.4,352.7L669.4,352.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M680.7,352.7c-0.1,28.6-23.4,51.9-52.1,51.7
	c-28.6-0.1-51.7-23.5-51.7-52.1c0.1-28.6,23.4-51.7,52.1-51.7C657.7,300.8,680.8,324.1,680.7,352.7L680.7,352.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.5,349.4c-1.5-2.2-4.5-2.6-6.7-1.1c-2.2,1.5-2.6,4.5-1.1,6.7
	c1.5,2.2,4.5,2.6,6.7,1.1C633.6,354.6,634.2,351.6,632.5,349.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.3,352.3c0-0.7-0.5-1.4-1.4-1.4c-0.7,0-1.4,0.5-1.4,1.4
	c0,0.8,0.5,1.4,1.4,1.4C626.7,353.6,627.3,352.9,627.3,352.3L627.3,352.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.8,353.6c0.7,0,1.4-0.5,1.4-1.4c0-0.7-0.5-1.4-1.4-1.4
	s-1.4,0.5-1.4,1.4C626.5,352.9,627,353.6,627.8,353.6L627.8,353.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.6,353.6c0.7,0,1.4-0.5,1.4-1.4c0-0.7-0.5-1.4-1.4-1.4
	c-0.7,0-1.4,0.5-1.4,1.4C628.3,352.9,628.8,353.6,629.6,353.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.8,352.3c0-0.7-0.5-1.4-1.4-1.4c-0.7,0-1.4,0.5-1.4,1.4
	c0,0.7,0.5,1.4,1.4,1.4S632.8,353.1,632.8,352.3z M625.9,345.8c0.1-0.5,0-1.2-0.4-1.8c-0.7-1-2.1-1.2-3-0.5c-1,0.7-1.2,2.1-0.5,3
	c0.4,0.5,1.1,0.8,1.7,1 M621.7,353.1c-0.5-0.3-1.2-0.4-1.8-0.3c-1.1,0.4-1.8,1.7-1.4,2.8c0.4,1.1,1.7,1.8,2.8,1.4
	c0.7-0.3,1.1-0.7,1.4-1.4 M631.7,345.8c-0.1-0.5,0-1.2,0.4-1.8c0.7-1,2.1-1.2,3-0.4c1,0.7,1.2,2.1,0.4,3c-0.4,0.5-1.1,0.8-1.7,1
	 M627.4,359.3c-0.7,0.5-1,1.5-0.7,2.3c0.4,1.1,1.6,1.8,2.8,1.4c1.1-0.4,1.8-1.6,1.4-2.8c-0.1-0.4-0.4-0.8-0.7-1.1 M635.7,353.2
	c0.7-0.5,1.8-0.5,2.5,0c1,0.7,1.2,2.1,0.4,3c-0.7,1-2.1,1.2-3,0.6c-0.4-0.3-0.7-0.7-0.8-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M618.3,354.5l4.5-2.1c0-1.4,0.4-2.8,1.2-3.9l-2.3-4.1 M627.7,362.8
	l-0.4-5.2c-1.2-0.3-2.3-1.1-3.2-2.2l-0.1-0.1l-5,1.1 M638.4,356.5l-5.1-1.1c-0.4,0.5-0.8,1-1.4,1.4c-0.5,0.4-1.1,0.7-1.8,0.8
	l-0.5,5.2 M635.8,344.4l-2.6,4.3l0.1,0.1c0.8,1.1,1.1,2.5,1.1,3.7l4.8,2.1 M634,343.2l-3.4,3.9c-1.2-0.5-2.8-0.5-4,0l-3.2-3.7
	 M111.2,377.8v2.9l19.5,0.1 M217.8,381.1l21.7,0.3 M565,375.2H582 M675.7,374.8l15.7-1c0,0,19.9-5.2,26.4-6.1 M583.8,218.3
	c0,0,35.5,16.2,38,17.3c2.5,1.1,14.9,4.5,15.8,6.5 M621.7,235.6c0,0-12,3.3-17.7,4.4c-5.9,1.1-7.6,2.2-7.6,2.2 M576.3,186.9
	l-0.5-2.8c-0.1-0.7,0.3-1.6,0.5-2.3s1.1-2.6,1.2-3.2c0.3-0.6-0.1-1.1-0.8-1c-0.8,0-1.9-0.1-3.2,1.4c-1.2,1.4-3.9,3.7-4.8,3.7
	c-0.8,0-1.8,0.1-2.6,0.1c-0.8,0.1-1.8,0.7-1.9,1.2c-0.3,0.6-0.5,0.8-0.5,0.8 M728.8,366.3c6.3-1.2,14.6-2.9,15.7-2.9
	c1.1-0.1,1.7-0.7,2.6-1.1c1-0.5,15.1-10,19-12.4c4-2.3,9.5-5.6,14.4-6.6c5-1,15.8-2.6,16.4-2.6s1.1-0.5,1.1-1.2s-0.5-3-0.8-3.9
	c-0.3-0.8-0.1-1.1,0-1.6c0.1-0.5,0.8-4.8,0.8-10c0-5.2-1.1-9.4-1.4-10.5c-0.1-0.6-0.4-0.8-1.4-1.2s-4.4-1.6-6.5-3.7l-4.5-0.5
	c0,0-0.7,0.1-1.5-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M762,361.3v1c0,0,0,0.5,0.5,0.4c0.6-0.1,10.6-1.1,15-1.8
	c4.5-0.7,13.8-2.6,14.4-2.8c0.7-0.1,1.1-0.4,1.4-0.8l0.5-1.1c0,0,0.3-0.5-0.3-1.2c-0.5-0.5-1.2-1.8-0.4-2.9c1-1.1,2.6-3.3,3.2-4.4
	c0.7-1.1,1.4-1.6,0.7-3.9c-0.5-2.2-0.7-2.8-1-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M778.9,350.2h7.2c0,0,1.5,0,1.1,1.2c-0.5,1.4-1.1,4.1-2.6,6.9
	c-0.3,0.7-0.8,1.2-2.2,1.5c-2.1,0.4-11.4,1-18.6,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M787.6,348.8c0.3,0.1,0.3,0.5,0.1,1.1c-0.1,0.3-0.3,0.8-0.5,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M772.7,359.4c-0.1-0.3-0.1-0.7,0.1-1c0.4-0.7,3.6-4.7,3.8-5.1
	c0.3-0.3,1.1-1,2.1-1.2c1-0.3,5.8-1.1,6.9-1.4c1-0.1,1.8-0.1,1.5,0.5 M782.5,359.8c-2.1,0.1-7.2,0.3-8.5,0.3c-0.7,0-1.1-0.3-1.2-0.5
	 M784.7,359.5c0.3,0,0.7-1.1,1-1.7c0.1-0.4,2.2-5.5,3.2-8.7c0.1-0.4,0.4-0.8,1-1c0.5-0.1,4.7-0.5,7.2-1.1 M749.3,349.8
	c11.8-5.9,20.4-10.6,29.4-12.4c8.7-1.8,13.6-2.5,18.2-3 M777.8,303.6c1.2,0,4.8-0.3,5.9-0.5 M626.1,334.9v-15.1c0-0.8-0.5-0.8-1-0.8
	c-4.7,0.5-9.1,2.1-12.9,4.3c-0.5,0.4-0.5,0.8-0.3,1.4l8.8,12.1c0.5,0.7,1.7,2.1,3.6,1.2S626.1,335.5,626.1,334.9L626.1,334.9z
	 M616.3,339.9l-8.9-12.2c-0.4-0.7-1-0.4-1.2-0.1c-3.4,3.2-6.2,7-8,11c-0.3,0.7,0,1,0.5,1.2l14.2,4.5c1,0.3,2.5,0.7,3.7-1.1
	C617.7,341.5,616.6,340.3,616.3,339.9L616.3,339.9z M611.3,349.6L597,345c-0.8-0.3-1,0.3-1.1,0.7c-1,4.7-0.8,9.2,0,13.6
	c0.1,0.7,0.7,0.8,1.1,0.7l14.2-4.5c0.8-0.3,2.5-1,2.3-3C613.4,350.2,611.7,349.9,611.3,349.6L611.3,349.6z M613,360.5l-14.3,4.7
	c-0.8,0.3-0.7,0.7-0.5,1.1c1.9,4.3,4.7,8,8.1,11c0.5,0.4,1,0.3,1.4-0.1l8.8-12.1c0.5-0.8,1.5-2.2,0.1-3.9
	C614.9,359.7,613.4,360.4,613,360.5L613,360.5z M620.5,368.3l-8.9,12.2c-0.4,0.7-0.1,1,0.3,1.2c4.1,2.3,8.5,3.7,13.1,4.3
	c0.7,0.1,1-0.3,1-1v-14.9c0-1-0.1-2.6-2.2-3.2C621.6,366.4,620.8,367.9,620.5,368.3L620.5,368.3z M631.4,370.1v15.1
	c0,0.8,0.5,0.8,1,0.8c4.7-0.5,9.1-2.1,12.9-4.3c0.5-0.3,0.5-0.8,0.3-1.4l-8.8-12.1c-0.5-0.7-1.7-2.1-3.6-1.2
	C631.1,367.9,631.4,369.6,631.4,370.1L631.4,370.1z M641.3,365.2l8.9,12.2c0.4,0.7,1,0.4,1.2,0.1c3.4-3.2,6.2-7,8-11
	c0.3-0.7,0-1-0.5-1.2l-14.2-4.5c-0.8-0.3-2.5-0.7-3.7,1.1C639.9,363.5,641,364.8,641.3,365.2L641.3,365.2z M646.4,355.4l14.3,4.7
	c0.8,0.3,1-0.1,1.1-0.7c1-4.7,0.8-9.2,0-13.6c-0.1-0.7-0.7-0.8-1.1-0.7l-14.2,4.5c-0.8,0.3-2.5,1-2.3,3
	C644.2,355,645.9,355.3,646.4,355.4L646.4,355.4z M644.6,344.4l14.3-4.7c0.8-0.3,0.7-0.7,0.6-1.1c-1.9-4.3-4.7-8-8.1-11
	c-0.5-0.4-1-0.3-1.4,0.1l-8.8,12.1c-0.5,0.8-1.5,2.2-0.1,3.9C642.6,345.2,644.1,344.6,644.6,344.4L644.6,344.4z M636.8,336.6
	l8.9-12.2c0.4-0.7,0.1-1-0.3-1.2c-4.1-2.3-8.5-3.7-13.1-4.3c-0.7-0.1-1,0.3-1,1v15c0,1,0.1,2.6,2.2,3.2
	C635.7,338.5,636.5,337,636.8,336.6L636.8,336.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M634.6,350.6c0.5,1.8,1.1,1.6,1.7,1.5l27.2-8.7c0.7-0.1,1.1,0.1,1.4,0.8
	c1.2,5.4,1.4,10.3,0,16.6c-0.1,0.6-0.5,1.2-1.5,1l-21.5-6.7c-0.7-0.1-1.7-0.4-2.1,1.1c-0.4,1.5,0.4,1.9,1.1,2.1l21.6,7.2
	c0.7,0.3,0.8,0.7,0.5,1.5c-2.1,5.1-5,9.1-9.8,13.5c-0.4,0.4-1.2,0.7-1.8-0.1l-16.9-23.2c-0.3-0.4-0.7-0.8-2.2,0.3
	c-1.4,1.1-1.1,1.5-1,1.9l16.8,23.2c0.4,0.6,0.3,1.1-0.4,1.5c-4.7,2.9-9.4,4.5-15.8,5.2c-0.6,0-1.4-0.1-1.4-1.2l-0.3-22.4
	c0-0.7-0.1-1.6-1.7-1.6c-1.7,0-1.7,1-1.7,1.6l-0.1,22.7c0,0.7-0.4,1.1-1.2,1c-5.5-0.4-10.2-1.9-15.8-5.1c-0.5-0.3-1.1-1-0.4-1.8
	l16.9-23.2c0.3-0.4,0.5-1-0.8-1.9c-1.4-1-1.8-0.5-2.1-0.3L606.5,380c-0.4,0.5-1,0.5-1.5,0.1c-4.1-3.6-7.2-7.6-9.8-13.5
	c-0.3-0.5-0.3-1.4,0.7-1.6l21.3-7.1c0.7-0.3,1.5-0.6,1-2.1c-0.5-1.5-1.4-1.2-2.1-1.1l-21.6,6.9c-0.7,0.1-1.1-0.1-1.4-0.8
	c-1.2-5.4-1.4-10.3,0-16.6c0.1-0.5,0.5-1.2,1.5-1l27.1,8.8c0.4,0.1,1.1,0.3,1.5-1.4c0.5-1.7,0-1.9-0.4-2.1l-27.1-8.9
	c-0.7-0.3-0.8-0.7-0.5-1.5c2.1-5.1,5-9.1,9.8-13.5c0.4-0.4,1.2-0.7,1.8,0.1l13.3,18c0.4,0.6,1.1,1.2,2.3,0.3s0.8-1.8,0.4-2.3
	l-13.3-18.4c-0.4-0.5-0.3-1.1,0.4-1.5c4.7-2.9,9.4-4.4,15.8-5.2c0.5,0,1.4,0.1,1.4,1.1v28.5c0,0.4,0,1.1,1.8,1.1s1.8-0.6,1.8-1.1
	l0.1-28.6c0-0.7,0.4-1.1,1.2-1c5.5,0.4,10.2,1.9,15.8,5.1c0.5,0.3,1.1,1,0.4,1.8l-12.9,18.3c-0.4,0.5-1,1.4,0.4,2.3
	c1.4,1,1.9,0.1,2.3-0.3l13.5-18.3c0.4-0.5,1-0.5,1.5-0.1c4.1,3.6,7.2,7.6,9.8,13.5c0.3,0.6,0.3,1.4-0.7,1.7l-27.1,8.8
	C634.6,348.5,634,348.8,634.6,350.6L634.6,350.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M667,341.9c-0.1-0.5-0.4-0.5-0.7-0.4l-31.1,10l-0.7-2.1l31.1-10
	c0.3-0.1,0.5-0.3,0.4-0.8c-2.5-6.7-6.9-12.7-12.4-17.2c-0.4-0.3-0.7-0.1-1,0.1L637.2,343l-1.8-1.2l15.5-21.5c0.1-0.3,0.3-0.7-0.1-1
	c-5.9-3.9-12.8-6.3-20.4-6.6c-0.5,0-0.7,0.3-0.7,0.5v32.7h-2.2v-32.7c0-0.3-0.1-0.5-0.7-0.5c-7.4,0.3-14.3,2.6-20.1,6.5
	c-0.4,0.3-0.4,0.7-0.1,0.8l15.5,21.5l-1.8,1.2l-15.5-21.5c-0.1-0.3-0.5-0.4-1-0.1c-5.6,4.4-10,10.5-12.5,17.2
	c-0.1,0.4,0.1,0.7,0.4,0.8l31.1,10l-0.7,2.1l-31.1-10c-0.3-0.1-0.7,0-0.8,0.4c-0.8,3.3-1.4,6.9-1.4,10.5c0,3.7,0.4,7.3,1.4,10.6
	c0.1,0.5,0.5,0.5,0.8,0.4l25.2-8.3l0.7,2.1l-25.2,8.3c-0.3,0.1-0.5,0.4-0.4,0.8c2.5,6.9,6.9,12.9,12.5,17.3c0.4,0.3,0.7,0.1,0.8-0.1
	l19.3-26.4l1.8,1.2l-19.3,26.4c-0.1,0.3-0.3,0.5,0.3,0.8c5.8,3.7,12.5,6.2,19.8,6.6c0.5,0,0.7-0.3,0.7-0.5v-26.5h2.2v26.5
	c0,0.3,0.1,0.5,0.7,0.5c7.6-0.3,14.4-2.6,20.4-6.5c0.4-0.3,0.4-0.6,0.1-0.8l-19.3-26.4l1.8-1.2l19.3,26.4c0.1,0.3,0.4,0.4,0.8,0.1
	c5.5-4.5,9.9-10.2,12.5-16.9c0.1-0.4,0-0.7-0.4-0.8l-25.2-8.3l0.7-2.1l25.2,8.3c0.3,0.1,0.5,0,0.7-0.4c1-3.4,1.5-7,1.5-10.7
	C668.6,349,668,345.4,667,341.9 M215.1,352.5c-0.1,22.4-18.4,40.6-40.9,40.4c-22.4-0.1-40.6-18.4-40.4-40.9
	c0.1-22.4,18.3-40.6,40.9-40.4C197,311.8,215.1,330.1,215.1,352.5L215.1,352.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226.3,352.7c-0.1,28.6-23.4,51.9-52.1,51.7
	c-28.6-0.1-51.7-23.5-51.7-52.1c0.1-28.6,23.4-51.7,52.1-51.7C203.3,300.7,226.4,324.1,226.3,352.7L226.3,352.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.4,349.2c-1.5-2.2-4.5-2.6-6.7-1.1c-2.2,1.5-2.6,4.5-1.1,6.7
	c1.5,2.2,4.5,2.6,6.7,1.1C179.4,354.5,179.9,351.4,178.4,349.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.2,352.1c0-0.7-0.6-1.4-1.4-1.4c-0.7,0-1.4,0.5-1.4,1.4
	c0,0.7,0.6,1.4,1.4,1.4C172.5,353.5,173.2,352.9,173.2,352.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.6,353.5c0.7,0,1.4-0.5,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
	c-0.8,0-1.4,0.5-1.4,1.4C172.2,352.9,172.8,353.5,173.6,353.5L173.6,353.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M175.4,353.5c0.7,0,1.4-0.5,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
	c-0.7,0-1.4,0.5-1.4,1.4C174,352.9,174.7,353.5,175.4,353.5L175.4,353.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M178.7,352.1c0-0.7-0.6-1.4-1.4-1.4s-1.4,0.5-1.4,1.4
	c0,0.8,0.6,1.4,1.4,1.4C178,353.5,178.7,352.9,178.7,352.1L178.7,352.1z M171.7,345.8c0.1-0.5,0-1.2-0.4-1.8c-0.7-1-2.1-1.2-3-0.5
	c-1,0.7-1.2,2.1-0.6,3c0.4,0.5,1.1,0.8,1.7,1 M167.4,352.9c-0.6-0.3-1.2-0.4-1.8-0.3c-1.1,0.4-1.8,1.6-1.4,2.8
	c0.4,1.1,1.7,1.8,2.8,1.4c0.7-0.3,1.1-0.7,1.4-1.4 M177.6,345.8c-0.1-0.5,0-1.2,0.4-1.8c0.7-1,2.1-1.2,3-0.4c1,0.7,1.2,2.1,0.4,3
	c-0.4,0.5-1.1,0.8-1.6,1 M173.2,359.1c-0.7,0.5-1,1.5-0.7,2.3c0.4,1.1,1.6,1.8,2.8,1.4s1.8-1.6,1.4-2.8c-0.1-0.4-0.4-0.8-0.7-1.1
	 M181.6,353.2c0.7-0.5,1.8-0.5,2.5,0c1,0.7,1.2,2.1,0.4,3c-0.7,1-2.1,1.2-3,0.4c-0.4-0.3-0.7-0.7-0.8-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M164.2,354.5l4.7-1.9c0-1.4,0.4-2.8,1.2-3.9l-2.5-4.3 M173.6,362.7
	l-0.4-5.2c-1.2-0.3-2.3-1.1-3.2-2.2l-0.1-0.1l-5,1.1 M184.2,356.5l-5.1-1.1c-0.4,0.5-0.8,1-1.4,1.4s-1.1,0.7-1.8,0.8l-0.6,5.2
	 M181.6,344.4l-2.6,4.3l0.1,0.1c0.8,1.1,1.1,2.5,1.1,3.7l4.8,2.1 M179.9,343l-3.4,3.9c-1.2-0.6-2.8-0.6-4,0l-3.2-3.7 M171.7,334.9
	v-15.1c0-0.8-0.6-0.8-1-0.8c-4.7,0.5-9.1,2.1-12.9,4.3c-0.6,0.4-0.6,0.8-0.3,1.4l8.8,12.1c0.6,0.7,1.6,2.1,3.7,1.2
	C171.8,337,171.7,335.3,171.7,334.9L171.7,334.9z M161.9,339.7l-8.9-12.2c-0.4-0.7-1-0.4-1.2-0.1c-3.4,3.2-6.2,7-8,11
	c-0.3,0.7,0,1,0.6,1.2l14.2,4.5c1,0.3,2.5,0.7,3.7-1.1C163.3,341.4,162.2,340.1,161.9,339.7L161.9,339.7z M157,349.6l-14.3-4.7
	c-0.8-0.3-1,0.1-1.1,0.7c-1,4.7-0.8,9.2,0,13.6c0.1,0.7,0.7,0.8,1.1,0.7l14.2-4.5c0.8-0.3,2.5-1,2.3-3
	C159,350.1,157.4,349.8,157,349.6L157,349.6z M158.6,360.5l-14.3,4.7c-0.8,0.3-0.7,0.7-0.6,1.1c1.9,4.3,4.7,8,8,11
	c0.6,0.4,1,0.3,1.4-0.1l8.8-12.1c0.6-0.8,1.5-2.2,0.1-3.9C160.5,359.5,159,360.2,158.6,360.5L158.6,360.5z M166.2,368.2l-8.9,12.2
	c-0.4,0.7-0.1,1,0.3,1.2c4.1,2.3,8.5,3.7,13.1,4.3c0.7,0.1,1-0.3,1-1v-14.9c0-1-0.1-2.6-2.2-3.2
	C167.3,366.4,166.6,367.8,166.2,368.2L166.2,368.2z M177,370v15.1c0,0.8,0.6,0.8,1,0.8c4.7-0.5,9.1-2.1,12.9-4.3
	c0.6-0.3,0.6-0.8,0.3-1.4l-8.8-12.1c-0.6-0.7-1.6-2.1-3.7-1.2C176.9,367.8,177,369.5,177,370L177,370z M186.9,365l8.9,12.2
	c0.4,0.7,1,0.4,1.2,0.1c3.4-3.2,6.2-7,8-11c0.3-0.7,0-1-0.6-1.2l-14.2-4.5c-0.8-0.3-2.5-0.7-3.7,1.1
	C185.6,363.5,186.7,364.8,186.9,365L186.9,365z M192,355.4l14.3,4.7c0.8,0.3,1-0.3,1.1-0.7c1-4.7,0.8-9.2,0-13.6
	c-0.1-0.7-0.7-0.8-1.1-0.7l-14.2,4.5c-0.8,0.3-2.5,1-2.3,3C189.8,354.9,191.5,355.3,192,355.4L192,355.4z M190.2,344.3l14.3-4.7
	c0.8-0.3,0.7-0.7,0.6-1.1c-1.9-4.3-4.7-8-8.1-11c-0.6-0.4-1-0.3-1.4,0.1l-8.8,12.1c-0.6,0.8-1.5,2.2-0.1,3.9
	C188.2,345.2,189.7,344.4,190.2,344.3L190.2,344.3z M182.5,336.6l8.9-12.2c0.4-0.7,0.1-1-0.3-1.2c-4.1-2.3-8.5-3.7-13.1-4.3
	c-0.7-0.1-1,0.3-1,1v14.9c0,1,0.1,2.6,2.2,3.2C181.4,338.4,182.3,337,182.5,336.6L182.5,336.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M180.2,350.5c0.6,1.8,1.1,1.6,1.6,1.5l27.2-8.7c0.7-0.1,1.1,0.1,1.4,0.8
	c1.2,5.4,1.4,10.3,0,16.6c-0.1,0.5-0.6,1.2-1.5,1l-21.5-6.7c-0.7-0.1-1.6-0.4-2.1,1.1c-0.4,1.5,0.4,1.9,1.1,2.1l21.6,7.2
	c0.7,0.3,0.8,0.7,0.6,1.5c-2.1,5.1-5,9.1-9.8,13.5c-0.4,0.4-1.2,0.7-1.8-0.1l-16.9-23.2c-0.3-0.4-0.7-0.8-2.2,0.3
	c-1.4,1.1-1.2,1.5-1,1.9l16.6,23.2c0.4,0.5,0.3,1.1-0.4,1.5c-4.7,2.9-9.4,4.5-15.8,5.2c-0.6,0-1.4-0.1-1.4-1.2l-0.3-22.4
	c0-0.7-0.1-1.6-1.6-1.6c-1.6,0-1.6,1-1.6,1.6l-0.1,22.7c0,0.7-0.4,1.1-1.2,1c-5.5-0.4-10.2-1.9-15.8-5.1c-0.6-0.3-1.1-1-0.4-1.8
	l16.9-23.1c0.3-0.4,0.6-1-0.8-1.9s-1.8-0.5-2.1-0.3l-17.1,23.2c-0.4,0.5-1,0.5-1.5,0.1c-4.1-3.6-7.2-7.6-9.8-13.5
	c-0.3-0.5-0.3-1.4,0.7-1.7l21.3-7.1c0.7-0.3,1.5-0.6,1-2.1c-0.6-1.5-1.4-1.2-2.1-1.1l-21.6,6.9c-0.7,0.1-1.1-0.1-1.4-0.8
	c-1.2-5.4-1.4-10.3,0-16.6c0.1-0.6,0.6-1.2,1.5-1l27.1,8.8c0.4,0.1,1.1,0.3,1.5-1.4c0.6-1.6,0-1.9-0.4-2.1l-27.1-8.9
	c-0.7-0.3-0.8-0.7-0.6-1.5c2.1-5.1,5-9.1,9.8-13.5c0.4-0.4,1.2-0.7,1.8,0.1l13.3,18c0.4,0.6,1.1,1.2,2.3,0.3s0.8-1.8,0.4-2.3
	l-13.3-18.4c-0.4-0.6-0.3-1.1,0.4-1.5c4.7-2.9,9.4-4.4,15.8-5.2c0.6,0,1.4,0.1,1.4,1.2v28.5c0,0.4,0,1.1,1.8,1.1
	c1.8,0,1.8-0.5,1.8-1.1l0.1-28.6c0-0.7,0.4-1.1,1.2-1c5.5,0.4,10.2,1.9,15.8,5.1c0.6,0.3,1.1,1,0.4,1.8L180.8,341
	c-0.4,0.6-1,1.4,0.4,2.3c1.4,1,1.9,0.1,2.3-0.3l13.5-18.3c0.4-0.5,1-0.5,1.5-0.1c4.1,3.6,7.2,7.6,9.8,13.5c0.3,0.5,0.3,1.4-0.7,1.6
	l-27.1,8.8C180.2,348.5,179.7,348.7,180.2,350.5L180.2,350.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.8,341.8c-0.1-0.5-0.4-0.5-0.8-0.4l-31.1,10l-0.7-2.1l31.1-10
	c0.3-0.1,0.6-0.3,0.4-0.8c-2.5-6.7-6.9-12.7-12.4-17.2c-0.4-0.3-0.7-0.1-1,0.1l-15.5,21.5l-1.8-1.2l15.5-21.5c0.1-0.3,0.3-0.7-0.1-1
	c-5.9-3.9-12.8-6.3-20.4-6.6c-0.6,0-0.7,0.3-0.7,0.5v32.7h-2.1v-32.7c0-0.3-0.1-0.5-0.7-0.5c-7.4,0.3-14.3,2.6-20.1,6.5
	c-0.4,0.3-0.4,0.7-0.1,0.8l15.5,21.5l-1.8,1.2l-15.5-21.5c-0.1-0.3-0.6-0.4-1-0.1c-5.6,4.4-10,10.5-12.5,17.2
	c-0.1,0.4,0.1,0.7,0.4,0.8l31.1,10l-0.7,2.1l-31.1-10c-0.3-0.1-0.7,0-0.8,0.4c-0.8,3.3-1.4,6.9-1.4,10.5c0,3.7,0.4,7.3,1.4,10.6
	c0.1,0.5,0.6,0.5,0.8,0.4l25.2-8.3l0.7,2.1l-25.2,8.3c-0.3,0.1-0.6,0.4-0.4,0.8c2.5,6.9,6.9,12.8,12.5,17.3c0.4,0.3,0.7,0.1,0.8-0.1
	l19.3-26.4l1.8,1.2l-19.3,26.4c-0.1,0.3-0.3,0.5,0.3,0.8c5.8,3.7,12.5,6.2,19.8,6.6c0.6,0,0.7-0.3,0.7-0.5v-26.5h2.2v26.5
	c0,0.3,0.1,0.5,0.7,0.5c7.6-0.3,14.4-2.6,20.4-6.5c0.4-0.3,0.4-0.5,0.1-0.8L177.2,358l1.8-1.2l19.3,26.4c0.1,0.3,0.6,0.4,0.8,0.1
	c5.5-4.5,9.9-10.2,12.5-16.9c0.1-0.4,0-0.7-0.4-0.8l-25.2-8.3l0.7-2.1l25.2,8.3c0.3,0.1,0.7,0,0.8-0.4c1-3.4,1.5-7,1.5-10.7
	C214.2,348.8,213.6,345.2,212.8,341.8 M57.6,373.9v3.3c0,0,0,0.3,0.3,0.3c0.4,0,19.8,0.3,19.9,0.3c0.3,0,0.6-0.1,0.6-0.4
	c0-0.3,0.1-1.4,0.1-1.4 M31.8,359.1c-0.3,0.1-0.5-0.3-0.5-0.3s-0.3-0.6-0.4-1c-0.1-0.5,0.3-0.5,0.3-0.5s0.5-0.1,1.5-0.3
	c0.3-0.1,1-0.3,1-1c0-1.8,0.1-5.8,0.5-13.1c0.4-9.8,1.8-17.7,3-24.5c1.2-6.7,3-11.3,4-13.6s3.3-8,3.4-8.5c0.1-0.3,0.4-1,0-1.2
	s-1.1-0.8-1.1-0.8s-0.4-0.3,0-0.8c0.4-0.4,1.1-1,1.1-1s0.5-0.5,1.2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M47.5,295.2c-0.3,0.3-0.4,0.5-0.4,0.7c-0.1,0.1-0.3,0.4-0.5,0.5
	c-0.3,0.1-1.1,0.3-1.2,0.8c-0.3,0.5-2.5,5.2-3.4,7.7c-1,2.3-2.6,6.7-3.8,13.6c-1.2,6.7-2.6,14.7-3,24.5c-0.4,9.8-0.5,13.6-0.5,14.3
	c0,0.7-0.1,1.4-1,1.6c-0.8,0.3-1.5,0.3-1.5,0.3 M35.4,336.7c18.2-1.9,48.1-4.1,75.5-4.4 M38.8,314.6c8.8-1.4,21.6-3.3,34.4-4.7
	 M43.2,299.2l-4.1,0.5c0,0-0.7,0.1-1.2,0.5c-0.3,0.3-1.1,1.2-0.3,1.4c0.8,0.1,3.3,0.5,4.3,0.7 M37.4,300.7l5.2-0.1 M38.5,301.6h3.7
	 M30.4,322l2.9-0.1c0.8,0,2.5-0.3,3.2-0.5 M40.3,306.2l-4.7,0.5c0,0-0.7,0.1-1.2,0.5c-0.3,0.3-1.1,1.2-0.3,1.4
	c0.8,0.1,3.3,0.5,4.4,0.8h0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M34,307.8l2.3-0.1c0.8,0,2.8-0.3,3.6-0.5 M35.1,308.8h4.4 M31.2,314.8
	l2.3-0.1c0.8,0,3.4-0.3,4.3-0.5 M32.3,315.8l5.2,0.1 M31.4,322.8v0.3 M31.9,315.8c0,0.7-0.1,3,1.2,5 M31.9,315.8
	c1.1,0.1,3,0.5,3.9,0.7h1.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M34.1,313.6l-1.2,0.1c0,0-0.7,0.1-1.2,0.5c-0.3,0.3-1.1,1.2-0.3,1.4
	c0.1,0,0.3,0,0.5,0.1 M38.2,313.2l-4.1,0.4 M35.9,316.5v3.9 M38.5,309.5c-0.1,0.7-0.7,2.5-0.8,3.7 M39.6,306.3
	c0.4-1,1.4-3.2,1.9-4.1 M31.5,322.8c1.1,0.1,3,0.1,4.8,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M33.3,320.8l-1.1,0.1c0,0-0.7,0.1-1.2,0.6c-0.3,0.3-1.1,1.2-0.3,1.4
	c0.1,0,0.4,0,0.8,0.1 M36.7,320.2l-3.4,0.5 M35.5,327.6l-1.4,0.1c0,0-0.7,0.1-1.2,0.5c-0.3,0.3-1.1,1.2-0.3,1.4
	c0.4,0.1,1.4,0.1,2.5,0.1 M32.5,328.9h2.9 M34.5,334.8l-0.7,0.1c0,0-0.7,0.1-1.2,0.5c-0.3,0.3-1.1,1.2-0.3,1.4
	c0.4,0,1.1,0.1,1.9,0.1 M32.3,336l2.2-0.1 M30.3,342.9l3.7-0.1 M30.8,343.9H34"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M33.6,336.8v4.7c0,0,0,0.3-0.3,0.4c-0.3,0.1-0.4,0.3-0.8,0.6
	c0,0-0.5-1.8-0.5-1.9v-0.7c0,0,0.4-16.4,0.4-16.5c0-0.1-0.3-0.3-0.3-0.3h-0.5 M33.6,329.8v5.1 M33.4,323c0.1,0.3,0.3,0.7,0.3,1.4
	v3.7 M33.7,348.8l-1.7,0.1c0,0-0.7,0.1-1.2,0.6c-0.3,0.3-1.1,1.2-0.3,1.4c0.5,0.1,1.9,0.3,3,0.5 M30.5,350.1h3.2 M31.5,351h2.2
	 M32.1,367.7l3.2-0.1c1,0,2.5-0.1,4.1-0.3l1.9-0.1c3.2-0.3,13.1-0.8,21.9-1.4c6.3-0.4,12-0.7,13.1-0.8c3.2-0.3,4.3-0.8,5.4-4.3
	c2.3-6.6,2.3-16.9,2.1-19.1c-0.3-2.5-2.6-3.3-4.7-3.3c-0.7,0-12.8,0.7-19,1c-3.7,0.3-9.5,0.7-14.4,1.1c-3.4,0.3-6.9,0.6-8.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M80.5,360.9c-1.2,1.5-1.5,1.6-3.9,1.8c-1.1,0.1-6.7,0.4-13.1,0.8
	c-8.8,0.5-19.1,1.1-22.3,1.4c-0.8,0-3.6,0.1-4.5,0.1h-2.5 M82.7,342.2c-0.1-1.2-1.8-1.4-2.5-1.4c-0.6,0-12.4,0.5-18.8,1
	c-3.7,0.3-10.7,0.8-15.8,1.2c-0.3,0-0.7,0-0.8-0.3c-0.3-0.1-0.4-0.5-0.3-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M36.7,342.4c5.4-0.4,17.2-1.5,23.5-1.9c6.2-0.4,18.2-1,18.8-1
	s3.3,0.1,3.6,2.3c0.3,2.2,0.1,12.2-1.9,18.6c-1,2.9-1.8,3.3-4.4,3.4c-2.8,0.1-29.4,1.8-34.9,2.2c-2.5,0.1-4.7,0.4-5.9,0.4
	c-1.2,0-3.2,0.1-3.2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M55.7,350.3c0,0,3.2-3.3,4.1-4c1-0.7,2.1-1,3-1.1c1-0.1,5.9-0.7,10.5-1
	c0.8,0,1.7,0,1.7,1.1c0,1.1-1,11.7-1.1,12.5c-0.1,1-0.4,2.3-1.8,2.5c-1.5,0.1-9.5,0.4-10.7,0.6c-1.1,0-2.1,0.3-4.3-0.7
	c-2.2-1-6.2-3.2-6.7-3.4c-0.6-0.3-0.8-0.4-1.8-0.4c-0.8,0-10,0.7-10,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M45,343.5l3.4,0.4l-1.2,4c0,0-0.1,0.3-0.4,0.4c-0.3,0.1-7.4,1.5-7.4,1.5
	s-1,0.3-1,0.8c0,0.5,0.5,0.5,1,0.5c0.4,0,9.1,0.3,13.8-0.3l2.6-0.5 M40.3,360.5l1.9,3.3 M44.6,357.9c0,0-0.5,1.4-0.8,1.9
	c0,0-1.5,0.7-3.4,0.5v0.4 M43.9,359.8l4.3,3.6 M36.7,364.9c0.7-0.1,2.5-0.5,2.9-0.7c0.4-0.1,1-0.1,1.2-0.1s6.9-0.4,10-0.5
	c3.3-0.1,10.5-0.1,14.4-0.1 M41.4,356c-1.1,0.3-2.2,0.5-2.3,0.5c-0.5,0.1-1,0.6-1,0.8c0,0.3,0.1,0.5,1,0.5c0.7,0,12.4-0.1,12.4-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M46.2,351.2l-1,3.7c0,0-0.1,0.3-0.5,0.4c-0.3,0-1.8,0.4-3.3,0.7
	 M43.3,348.8c0.8-2.8,1.5-5.2,1.8-5.9 M41.5,356c0.3-1.2,0.8-3,1.2-4.8 M32.7,361.1c0.7-0.1,5-0.7,6.1-1c0.8-0.1,1.5-0.4,2.1-2.1
	 M48.4,343.9l5.9-0.4c0,0,4.4-1,5.6-1.4 M49.7,356.8c0.3,0,0.4,0.1,0.7,0.3c0.7,0.5,6.7,3.9,8.3,4.5c1.5,0.8,3,1.2,4.4,1.4
	c1.4,0,5.5,0.1,5.5,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M38.7,357.8l10.2-1c0,0,0.4-0.1,0.8,0 M49.5,349.2
	c0.1-0.1,0.4-0.1,0.5-0.3c0.5-0.4,4.3-4,10.6-7 M38.8,350.1l9.9-0.7c0,0,0.4,0,1-0.3 M45.3,355.3l9.4-0.4c0,0,0.3,0,1,0.4
	c0.4,0.3,5.2,3.7,5.5,4c0.3,0.1,0.7,0.4,1.5,0.4c0.8,0,8.1,0,8.9-0.1c0.8,0,2.3-0.4,2.5-2.1 M55.7,350.3l-0.5,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M38.7,350.9l10.5-0.8c0,0,0.7-0.1,1.2-0.7c0.5-0.5,3.9-3.2,6.6-4.7
	c0.7-0.4,2.1-1,3.4-1c1.2,0,12.4-0.7,13.1-0.7s2.2-0.1,2.1,2.1c-0.1,2.1-0.6,9.2-1,12.9c-0.3,2.1-1.2,2.6-2.6,2.8
	c-1.5,0.1-10.7,0.7-11.3,0.7s-2.6-0.1-4.4-1.1 M34.3,362.3c1.2-0.1,5-0.7,6.3-1.1 M37.1,360.5c0,0.1,1.2,1,2.6,1 M40,361.3l-0.1,2.8
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M61.8,345.6c0,0,5.6-0.3,6.3-0.4c0.7,0,1.2,0.4,1.1,1.1
	c0,0.7-0.7,11.6-0.7,11.6s-0.1,0.7-0.7,0.8c-0.7,0.1-5.8,0.4-5.8,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M64.7,358.2c-1.4-0.3-4.1-1.8-4.4-6.2c-0.3-3.3,1.5-4.8,2.9-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M63,346.5c0.4-0.3,0.8-0.3,1.1-0.3c1.7-0.1,4.1,0.8,4.3,6.1
	c0.1,5.2-2.3,5.9-3.3,6.1h-0.4 M67.7,348.5c-0.6,0.8-2.2,3.7,0.6,6.3 M37.4,364.4v-2.5 M36.3,364.4h1.1 M36,364.4v-2.2 M35.2,364.4
	H36 M34.9,364.4v-2.2 M34.5,364.4h0.4 M48.4,294.8c-0.3,0.1-0.3,0.4-0.4,1.1c0,0-1.7,5.4-1.7,7.7c0,2.3,0.5,3,1.7,3
	c1.1-0.1,8.4-0.8,10.5-1c1.2-0.1,2.3-0.3,3.4,0.3c1.1,0.5,7.2,3.2,8,3.6c1,0.4,1.9,0.5,3.3,0.5c1.4,0,14.6-0.8,18.4-1.4
	c4.5-0.7,6.9-2.2,10.2-4.8c3.2-2.6,12.4-10.7,14-12.1c1.9-1.6,2.8-4.4-1.5-4.7c-4.5-0.3-19.4-1-33.6,0.7c-14,1.6-27.8,4.8-34.9,8.3
	c-0.7,0.3-0.8-0.1-0.4-0.7c0.3-0.4,1.1-2.1,0.3-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M50.5,295.2c7.6-2.6,19.1-5.2,30.5-6.5c14.2-1.6,29-0.8,33.3-0.5
	c1.4,0.1,1.8,0.4,1.9,0.8c0.3,0.7-0.1,1.1-1,1.9c-0.7,0.5-2.6,2.3-5,4.3c-3.2,2.8-7.2,6.2-8.9,7.7c-3.2,2.6-5.4,4-9.6,4.5
	c-3.7,0.5-15.1,1.4-16.9,1.4c-1.1,0-1.9-0.1-2.8-0.4c-1-0.4-6.9-3-8-3.6c-1.5-0.7-2.9-0.5-4.1-0.4c-2.1,0.3-8.9,0.8-10,1
	c-1,0.1-1.2-0.7-1.2-1.5C48.8,303,49.2,298.7,50.5,295.2c0.1-0.4,0.3-0.8,0.5-0.8c0.3-0.1,4.8-1.7,5.4-1.8s0.7-0.4,0.7-0.5
	 M96.4,307.4c0,0-2.2,4.8-2.5,5.6c-0.3,0.8-0.3,1.6,1,1.6c1.2,0,22-1.4,32.7-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M52.8,294.3c-1,0.3-1.4,1.2-1.5,1.9c-0.1,0.7-0.5,3-1.1,6.6
	c0,0.7-0.1,1.5,1.5,1.4c1.7-0.1,22.3-2.1,31.1-2.5c1.9-0.1,3.8,0.1,5.5,1.2c1.5,1.1,3,2.3,3,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M99,304.7c-1,0.7-1.8,0.8-2.3,1.1c-1.7,0.5-6.7,0.7-9.4,1
	c-2.6,0.3-8.4,0.7-10,0.7c-1.8,0-2.6,0-4-0.6c-1.4-0.6-5.2-2.3-6.7-3c-0.4-0.3-0.8-0.4-1.2-0.5c1,0,2.5,0.1,3.4,0.3
	c1,0.1,1.2,0.3,2.6,0.1c1.4-0.1,11.1-0.7,12.2-0.8c2.1-0.1,3.4,0.1,4.4,0.7c1,0.7,2.8,1.9,2.8,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M70,303.7c1.2,0.5,4.5,1.8,5.4,2.1c0.7,0.3,1.5,0.4,2.6,0.4
	s11.6-0.5,13.9-0.7c0,0,0.6,0,1-0.3c0.4-0.1,1.4-0.4,1.4-0.4c0.8,0,2.1,0,3-0.1c0.8-0.1,2.6-0.6,3.6-1.4 M51.5,296
	c4-1.1,10.3-3,23.5-5.1c13.1-1.8,36.5-1.5,41.4-1.5 M90.5,302c0.8,0.5,2.6,1.9,3.6,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M53.8,295.4c-0.5,0.1-0.8,0.7-0.8,1.1c-0.1,0.4-0.5,3-0.8,5.5
	c0,0.7-0.3,1.6,1,1.6c1.2-0.1,14.2-1.2,20.1-1.6s13.2-0.7,14.3-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M53,302c0,0.4,0.3,0.8,1,0.8c1.2-0.1,13.3-1.2,19.3-1.6
	c5.9-0.4,15.4-1,16.4-1c1.1,0,2.5,0.1,3.2,0.7c0.8,0.6,3.7,2.9,4.7,3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M87.4,301.2c2.5-0.1,5,0,6.6,1.2s2.6,2.2,2.6,2.2 M54.3,295.2h3.7
	c0,0,0.7,0,1.1-0.1c0.4-0.1,8.7-2.5,19.3-3.7c16.9-2.1,35.6-1.5,37.7-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M112.8,289.7c-2.5,1.4-16.1,8.4-18.3,9.4c-0.3,0.1-0.6,0.3-0.3,0.6
	c0.3,0.3,4.8,4.5,4.8,4.5 M54.1,295.9c0.4-0.1,1.1-0.3,1.9-0.3h5.1c0,0,0.3,0,0.3-0.1v-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M54.9,295.7c-0.4,1.5-0.7,3.3-0.8,4.7c-0.1,1.4,0.3,1.6,1.1,1.6
	s12.9-1.5,18.4-1.8c5.5-0.4,14.6-1.1,16.1-1.1c1.7-0.1,2.6-0.4,4.1-1s16.8-8.3,16.8-8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M107.4,289.7c0,0-12.4,6.1-13.9,6.7c-1.5,0.5-2.5,1-4.1,1
	c-1.7,0.1-9.9,0.7-16.1,1.4c-5.5,0.5-16.6,1.9-17.5,1.9c-0.7,0-0.7-0.3-0.7-0.7c0.1-1,0.5-3.3,0.7-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M61.1,295.4v3.6c0,0.4,0,1,0.5,1 M75.8,303.3c0,0.1,0.6,0.7,1.4,1
	c0.8,0.3,5.1,1.6,5.4,1.6 M61.4,294.9h1.9l1.1,1.5v3.3 M64.4,297h0.6l3.2,2.3 M98.4,289.8c0.6,0.7,1.7,1.8,2.5,3 M96.7,289.9
	c0.6,0.4,2.1,2.1,2.2,3.9 M97.5,290.8c0,0,0.4-0.4,1.1-0.5 M88.7,290.4c-0.4,1-0.6,4.7,0.7,7 M86.4,290.5c-0.6,0.5-1.9,3.7,1.2,7
	 M81.2,290.9c0.7,0.7,3.9,3.3,4.7,6.7 M78.7,291.2c0.7,0.7,3.2,3.3,4,6.6 M77.4,298.3c-1-2.6-2.9-4.7-4-5.6c0,0-0.1-0.1,0-0.3
	c0-0.1,0-0.3,0.6-0.3c0.4,0,1.8-0.3,3.4-0.4c0.3,0,0.6,0,0.6,0.1c1.1,1,3,3.3,3.9,6.1 M73.5,298.6c-1-2.3-2.8-4.3-3.9-5.2
	c0,0-0.1-0.1,0-0.3c0-0.1,0-0.3,0.6-0.3c1-0.1,2.1,0,2.1,0c0.3,0,0.6,0,0.6,0.1c1,1,3.2,2.9,4.1,5.4 M78.3,292.3H79 M72.9,293.2H74"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M70,293.9l-4.1,0.8c0,0-0.4,0.1-0.1,0.4c0.3,0.3,1.1,1.6,1.4,1.9
	c0.1,0.3,1,0.8,1.4,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M66,294.9l1.5,1.6c0,0,0.3,0.3,1.4,0.7c0.8,0.3,1.9,0.5,3,1.5 M71.1,294.9
	l-2.6,0.5c0,0-0.3,0.1,0,0.3c0.3,0.3,1.7,0.8,2.3,1.2s1.7,0.8,2.2,1.6 M84.2,302.9c-0.3,0.4-0.6,1.8-0.6,3 M769.9,261.6l0.7,1.4
	 M770.6,263c4.7,0.1,8.4,0.3,11,0.7 M769.8,262.8h0.7 M769.3,261.6c-4.1,0-9.2,0-15,0.4c-0.4,0-1.1,0.1-1.1,1.4
	c6.1-0.3,12-0.4,16.8-0.3 M769.9,261.7h-0.7 M781,262.3c-2.6-0.4-6.3-0.7-11.1-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M780.7,263.5c1,2.6,2.1,7.6,2.2,8.7l0.1,0.7v2.6c0,0.1-0.1,0.4-0.3,0.5
	c-0.4,0.3-3.2,1.9-6.9,3.6c-0.1,0-2.1,0.4-2.1,0.4c-6.2,1.2-16.1,1.5-25.9,1.6c-1.9-8.1-4.3-15.7-4.7-16.5c-0.1-0.4-0.3-0.8-0.3-1.1
	c0-0.3,0-0.4,0.1-0.7c0.3-0.4,0.8-0.8,1.9-1.1l0.4-0.1c3-0.7,9.5-2.2,20.8-2.8c3.3-0.1,7.8-0.1,11.1,0.7h0.1c0.3,0,1.2,0.4,2.2,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755.6,263.7c0,0,2.9,3,3.3,3.4s1.4,1.2,2.6,1.2c1.4,0,15.3-0.3,15.3-0.3
	s0.5,0,1.1-0.1c0.4-0.1,1-0.3,1-0.8c0-0.4-0.4-3.2-0.4-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M758.4,263.5c0,0,2.8,2.9,3.2,3.2c0.4,0.4,1.4,1.2,2.6,1.1
	c1.2,0,10.9-0.3,14.2-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M759.2,263.5l2.5,2.5c0.4,0.4,1.4,1.2,2.6,1.2s11.3-0.3,14.6-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M762.7,263.4c0.3,0.5,0.8,1.2,1,1.5c0.1,0.3,0.4,0.4,0.8,0.4h14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.9,263.4c0.3,0.5,0.8,1.2,1,1.5c0.1,0.3,0.4,0.4,0.8,0.4 M769.3,261.6
	l1.9,3.9 M769,263l1.2,2.5 M770.2,263.4c4.5,0.1,8.3,0.3,10.7,0.7 M743.3,264.2c9.4-0.7,18.6-1,25.9-0.8 M770.1,267.1l0.3,0.7
	 M770.8,267.1l0.4,0.7 M772.4,267l-0.8-1.5 M773.2,267l-0.7-1.5 M774.2,267l-0.7-1.5 M775.2,267l-0.7-1.5 M776.1,267l-0.5-1.5
	 M777.2,267l-0.7-1.5 M778.2,266.8l-0.7-1.4 M771.2,267.1l-0.8-1.6 M770.1,267.1l-1.1-1.6 M769.3,267.1l-1.1-1.6 M768,267.1
	l-1.2-1.6 M766.6,267.1l-1.2-1.6 M767.1,267.1l-1.2-1.8 M766,267.1l-1.2-1.6 M762.8,265.2l-1.5-1.8 M763.6,265.2l-1.5-1.8
	 M764.6,267.1l-1.7-1.8 M765.4,267.1l-1.7-1.8 M762.2,265.2l-1.7-1.6 M764,267.1l-1.7-1.8 M763.6,265.2h0.3 M762.2,265.2h1.2
	 M760.9,265.2h1.1 M763.8,265.3h0.4 M762.4,265.3h1.2 M761,265.3h1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M743.8,264.2c0,0.3,0.1,0.4,0.1,0.7c0.3,0.8,2.6,8.1,4.5,16.2
	c9.1-0.1,19.1-0.4,25.3-1.5c0,0,1.7-0.3,1.9-0.4c3.4-1.6,6.2-3.2,6.9-3.6c0,0,0.1-0.1,0.1-0.3v-1.9v-0.5l-0.1-0.7
	c-0.1-1-1.1-5.5-2.1-8.1 M772.4,275.1l1.8,4.4 M771.3,275.2l1.9,4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M774.1,279.1c0.3,0,0.7-0.1,1-0.1l0.8-0.3c3.2-1.5,5.6-2.9,6.2-3.3
	c0,0,0.1-0.1,0.1-0.3v-0.7v-0.5c0-0.3-0.3-0.3-0.4-0.1c-4.3,1-12.7,1.8-17.5,2.1c-1,0-1.8,0.5-1.9,1.4c-0.1,0.8-0.4,2.1-0.5,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M761.6,280c0.1-0.8,0.5-2.6,0.7-3.6c0.1-0.8,1-1.4,1.9-1.4
	c4.7-0.3,13.1-1.1,17.5-2.1c0.1,0,0.4-0.1,0.4-0.5c0-0.3-0.1-3.7-1.7-8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M761.8,279.9c3.6-0.1,7-0.4,9.9-0.8l1.4,0.3c-6.2,0.8-14.7,1-22.4,1.1
	c-1.9-8.3-4.5-15.3-4.8-16.2 M771.7,279.1l-0.5-3.9 M773.7,278.4h0.3l0.8-0.3c3.2-1.5,5.6-2.9,6.2-3.3c0,0,0.1-0.1,0.1-0.3v-0.7
	 M754.5,263.7c0,0,5,5.4,5.5,5.9c0.5,0.5,1.2,1.5,2.9,1.5c1.7,0,13.1-0.1,17.5-0.5c0.4,0,0.8-0.1,0.8-0.5s-0.7-4.3-1.1-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755.4,263.7c1.5,1.5,4.3,4.5,4.7,5c0.5,0.5,1.2,1.5,2.9,1.5
	c1.7,0,12.2-0.1,16.5-0.4c0.4,0,0.8-0.1,0.8-0.5c0-0.3-0.5-3.3-1-5.2 M749,274.5c1.7,0,6.3-0.1,7.2-0.1c0.8,0,1.7-0.1,2.1-0.3
	c0.5-0.1,1.2-0.1,2.3-0.3c1.1,0,14.6-0.5,20.8-1.5c0.3,0,0.5-0.1,0.5-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M748.5,272.7c1.1,0,7.2-0.1,7.7-0.3h2.2c0,0,1.7,0.1,2.3,0.1
	c1.1,0,14-0.4,20.4-1.2c0.3,0,0.5-0.1,0.5-0.5c0-1.1-0.4-3.4-1.2-6.9 M752.3,263.8l6.9,8.8 M750.7,263.8l6.5,8.7 M750.1,263.9
	l6.5,8.7 M759.6,273.8l-0.1,6.3 M757.8,274.1l-0.1,6.1 M757.3,274.3v5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M766.6,259c0,0,2.3,1.5,2.8,1.6c0.4,0.3,0.8,0.7,1.1,1.1
	c0.3,0.4,5,11.1,5.1,11.6c0.1,0.5,0.1,1.5,0.1,1.8s-0.1,4.7-0.1,5c0,0.3-0.1,0.5-0.1,0.5 M659.9,248.9c-67-0.3-137.4,1.1-179.7,1.9
	c-53.4,1-176.5,5.2-208.5,7.3c-0.3,0-0.4-0.1,0-0.5s0.7-0.8,1-1.1c0.3-0.3,0.7-0.4,0.7-0.4c-0.1,0,0.1-0.1,0.1-0.1
	c13.1-10.5,28.6-22,47.6-33.3c15.8-9.4,33.8-18.6,51.7-23.8c21.9-6.5,56.4-10.5,90.9-10.3c13.9,0,27.9,0.4,41,1.4
	c15,1.1,28.9,3,40.3,5.1c16.6,3,32.5,8.3,47.7,14.3c27.5,11.1,51.7,24.5,67.1,34.1C663.1,245.2,665.8,248.8,659.9,248.9L659.9,248.9
	z M273.2,256.1c8.1-0.4,22.3-1.1,36.7-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.7,256c12.7-10,27.8-21.3,45.7-31.9c15.8-9.5,33.6-18.8,51.5-24.1
	c21.9-6.5,56.1-10.6,90.7-10.6c13.8,0,28.1,0.6,41.1,1.5c15,1.1,29,3.2,40.6,5.2c16.6,3,32.5,8.3,47.7,14.3
	c26.4,10.7,49.4,23.9,65.2,33.3c0.5,0.4,1.2,1.1,1.4,1.9c0,0.7-0.7,1.4-1.7,1.4c-66.6-0.3-135.6,0.8-177.6,1.6
	c-35.9,0.7-103.6,2.9-153.7,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M314.7,232.6c2.2-1.4,4.5-3,6.9-4.4c15.8-9.4,33.3-19,51.2-24.2
	c21.9-6.5,54.3-11.1,88.9-11c19.7,0,40.4,1.1,58.6,3.2c9.2,1.1,17.3,2.5,24.6,3.9c5.1,1,10.3,2.1,15.3,3.4c0.4,0.1,1,0.7,1,0.7
	s15,26.4,23.1,42.8 M282.7,255.5c7.4-5.9,16.2-12.5,25.3-18.6 M563.9,200.5c0,0,5.5,9.2,12.1,21.5c6.6,12.1,13.1,24.6,13.1,24.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M648.8,242.5c3.6,2.6,2.5,4-0.3,4c-2.8,0-57.1,0.3-57.1,0.3
	c-43.2,0.3-83.4,1-111.3,1.5c-35.6,0.7-102.5,2.8-152.4,4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M594.3,246.6c0,0-6.7-13.1-12.8-23.9c-4.7-8.4-9.1-15.3-9.4-15.7
	 M581.3,212.6c-1-0.4-1.7,0-1.1,1c0.5,1,15.5,27.6,16,28.5c0.4,0.8,0.8,1.1,1.4,1.1c0.5,0,35.2-0.1,37.6,0c2.3,0,4.5-1.9,0.7-4.7
	c-2.6-1.9-15.1-8.3-31.1-15.5C597.6,219.5,589.3,215.9,581.3,212.6L581.3,212.6z M320.1,229.3c0.4-0.3,0.8,0,0.7,0.4
	c-0.3,0.8-0.7,2.3-0.7,2.3 M319.4,255c4.8-0.4,8.9-0.6,9.8-4.8c0.3-1.9,0.5-4.8,0.5-6.2c0-9.6-5.8-11.6-8.3-11.7
	c-2.5-0.3-5.1,0-5.1,0c-1.8,0.1-6.2,2.8-8.8,5.2c-2.6,2.5-7.2,7.6-7.6,8s-0.8,0.8-0.7,1.5c0.1,0.4,0.3,1.1,0.5,1.5
	c0.3,0.4,1,1.2,2.2,2.1c1.1,0.8,3.3,2.3,5.9,3.2c1.5,0.4,2.5,1,5.1,1.1C314.7,255,318.3,255,319.4,255L319.4,255z M328,242.9
	c-0.3-8.7-5.6-10.6-8.1-10.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M319.7,254.9c4.1-0.4,7.4-1,8.1-4.8c0.1-0.7,0.3-1.5,0.3-2.3 M300.4,248.3
	c0,0,5.6-1.2,8.7-1.5s8.9-0.8,20.5-0.4 M300.4,245.9c0,0,5.6-1.2,8.7-1.5s8.9-0.8,20.5-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M329.3,247.7c-11.6-0.4-17.3,0.1-20.4,0.4c-0.8,0.1-1.9,0.3-2.9,0.4
	c-0.3,0-0.4,0.1-0.7,0c-0.3-0.1-0.8-0.7-1-0.8c-0.1-0.1,0-0.3,0-0.3 M572.8,225.1l-3,21.6 M300,245c0,0-1,0.3-1.6,0.8
	c-0.5,0.4-1.1,0.8-1.1,1.4c0,0.6,0,1.4,0.5,2.3c0.3,0.6,1.5,2.1,2.3,3c0.8,1,1.6,1.6,3,1.8 M453,193.2l-21,56 M469.9,193.1
	l-9.4,55.4 M324.5,233c5.4-3,15.5-8.9,28.2-14.6c7-3.2,15-6.2,22.7-8.4c22.1-6.6,50.5-8.3,57.8-8.7c7.2-0.4,9.5,3.3,9.5,3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M428.1,249.2c0,0,11.4-31.5,12.8-34.5c2.3-5.4,2.6-9.1,1.5-11.6
	c-1.6-3.4-5.8-4.8-9.4-4.7c-8.9,0.4-30,1.6-50.1,6.7c-8.3,2.1-18.7,5.6-29.6,10.7c-15.5,7.3-30.7,16.2-30.7,16.2 M464.8,248.1
	c0,0,4.1-26.4,6.2-36.3c2.1-9.9,8.9-14,19.7-13.8c10.6,0.3,50.4,5.4,57.5,6.7c7.2,1.4,10,3.4,13.1,8.5c2.5,4.1,6.3,11.3,6.7,12.4
	c0.4,1.1,0.7,2.3,0.5,3.3c-0.1,1.2-2.6,17.5-2.6,17.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M471.2,211.4c1.5-6.1,7.8-11.3,19.5-10.7c5.8,0.3,16.6,1.4,27.6,2.6
	c13.1,1.5,26.3,3.2,29.3,3.9c5.8,1.2,9.8,4.1,11.6,6.5c1.8,2.5,9.4,15.8,9.4,15.8 M560,203c3,0.7,4.8,1.4,4.8,1.4 M564.7,202
	c0,0,0.1-0.1,1.4,0.1c1.1,0.3,15.5,4.5,31.5,11.6c7.3,3.3,17.3,7.7,27.8,13.1c12.4,6.5,24.3,13.2,33.6,19c0,0.8-1.5,1.1-3.2,1
	c0,0-9.1-5.9-19.4-11.4c-10-5.4-20.2-11-43.7-21.2c-10.9-4.7-25.2-9.2-25.3-9.2c-0.5-0.1-1-0.1-1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M462.9,190.5c12.5,0.1,36.5,0.4,58.1,3c21.6,2.5,41.5,7.7,41.5,7.7
	c0.3,0.1,0.5,0.1,0.8,0.6c0.1,0.3,5.5,8.8,12.2,21.3c5.2,9.6,12.2,23.8,12.2,23.8 M461.7,190.5c-6.7,0-15.7,0.1-35.6,1.5
	c-19.9,1.4-46.1,5.2-67.4,14.2c-8.5,3.6-26,12-40.6,21c-21.7,13.6-39.9,28.3-39.9,28.3 M174.6,352.4v9.4 M174.6,364.8v4.7
	 M628.7,352.4v9.4 M628.7,364.8v4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M718.4,367.8c4-0.5,7.8-1.1,10.6-1.5
	c6.6-0.8,13.2-1.6,18.6-2.2c2.9-0.4,11.1-1.8,12.5-1.9c0.5-0.1,1-0.3,1.4-0.4 M238.3,381.1c0.3,0.1,0.7,0.3,1.2,0.3
	c1,0,292.7-3,323.3-2.9c0.6,0,1-0.7,1.4-1.2c0.4-0.7,0.6-1.2,0.6-1.2s0.1-0.3,0.3-0.7 M79,375.8c0.6,0,1.1,0.1,1.8,0.1
	c11.1,1,21,1.6,27.9,2.3c0,0,0.8,0.1,1.1,0.1h1.5 M31.8,359.7c0,0-0.5,0.1-0.3,0.6c0.1,0.5,0.4,1,0.4,1s0.1,0.4,0.8,0.3
	c-0.7,0.1,0,0.5,0.1,0.7c0.1,0.1,1.4,0.5,1.4,0.5v2.2c-0.8,0.4-2.6,1.2-2.2,1.9c-0.8,0.3-2.9,1-3,1.5c-0.1,0.4,0.4,0.6,0.8,0.7
	c0.4,0.1,9.2,2.1,12.1,2.8c3.7,0.7,8.1,1.2,13.3,1.6c0.4,0,1.2,0.1,2.1,0.3 M45.7,292.4c-0.7-0.7-0.5-0.7-1-0.8
	c-0.3-0.3-0.4-0.4-0.1-0.8c7.3-7.2,25.3-17.9,57.9-25.2c14-3.2,31.2-6.2,50.8-8.7c23.7-3,45.8-4.4,68.4-6.1c2.2-0.1,3.3-0.3,3.3-0.3
	s8,0.7,18.2,1.8 M604.5,194.2c25.5,8.1,51.9,17.5,105.8,42.8 M787.3,269.3c0.3,1.2,0.5,2.1,0.5,2.3c0.1,0.5,0.1,0.8,0.1,1.2
	s0.1,1.9,0.1,2.2c0,0.4,0.1,0.4-0.4,0.5c-0.5,0.1-3.2,0.7-3.6,0.7c-0.4,0.1-0.4-0.1-0.4-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M710.1,237c1.1-0.3,1.8-0.4,2.1-0.4
	c0.5-0.1,1.7-0.3,2.5-0.3c9.5,1,41,4.5,45.8,5.1c2.2,0.3,5.5,0.6,8.8,0.8c3.9,0.3,7.7,0.3,8,0.3c0.7,0,1,0.1,1.1,0.4
	c0.1,0.3,0.7,0.8,1,1.2c0.3,0.6,0.3,1.1,0.3,1.4c0,0.4-0.5,5,0.1,6.6c0.7,1.4,1.7,3.4,3.6,7 M778.3,279.5c1.2,4.4,3,12,4.1,17.7
	c1.1,5.9,2.1,8.4,2.5,8.8c0.3,0.4,0.3,0.7,0,0.7 M783.7,276.3c-0.1,0.1-0.3,0.4-0.3,0.4c-0.1,0.1-2.1,1.2-5.1,2.8 M563.2,185
	c-32.3-4.5-69.6-6.1-104.3-5.1c-60,1.6-87.2,8-106.2,14c-0.3,0.3-0.1,0.7-0.5,0.8c-44.6,17.5-90,45.8-108.9,57.5 M604.3,194.1
	c0-0.1,0.1-0.6-0.4-0.7c-7.7-2.2-16.4-4.4-27.2-6.3 M783.3,259.4l0.8,0.1c0,0,1,1.2,2.2,4.1c1.2,2.9,1.4,5.2,1.4,5.2l-0.1,0.8
	 M44.6,296c-3.6,0.3-7.8,1-8.1,1s-0.3,0.1-0.3,0.1s-1.1,0.5-1.4,1c-0.1,0.4-1.1,3.4-1.8,6.2c-0.7,2.8-1.8,6.2-1.9,6.6
	c-0.1,0.3,0.1,0.5,0.1,0.5l0.8,0.5c-0.1,0.1,0,0.4,0.1,0.6c0.3,0.1,1.5,0.4,1.5,0.4c0.1,0,0.3,0.1,0.3,0.3v0.6 M31.5,323.1h-1.1
	c-0.3,0-0.5,0.3-0.5,0.4c0,0.3,0.3,0.4,0.3,0.4s-0.4,15.4-0.4,16c0,0.3,0.1,0.7,0.1,1c0.1,0.3,0.4,1.4,0.4,1.4s0.1,0.4,0,0.5
	c-0.1,0.1-0.4,0.8,0,0.8c0.4,0.1,3.7,0.7,3.7,0.7 M675.6,375.1c-8.4,17.5-26.3,29.4-46.9,29.3c-20.4-0.1-38-11.8-46.4-29
	 M111.2,378.3v2.5l19.5,0.1 M217.8,381.1l21.7,0.3 M565,375.2H582 M675.7,374.8l15.7-1c0,0,19.9-5.2,26.4-6.1 M576.3,186.9l-0.5-2.8
	c-0.1-0.7,0.3-1.6,0.5-2.3s1.1-2.6,1.2-3.2c0.3-0.6-0.1-1.1-0.8-1c-0.8,0-1.9-0.1-3.2,1.4c-1.2,1.4-3.9,3.7-4.8,3.7
	c-0.8,0-1.8,0.1-2.6,0.1c-0.8,0.1-1.8,0.7-1.9,1.1c-0.3,0.6-0.5,0.8-0.5,0.8 M797,340.6c0.5,0,1.1-0.5,1.1-1.2c0-0.7-0.5-3-0.8-3.9
	c-0.3-0.8-0.1-1.1,0-1.6c0.1-0.5,0.8-4.8,0.8-10c0-5.2-1.1-9.4-1.4-10.5c-0.1-0.5-0.4-0.8-1.4-1.2c-1-0.4-4.4-1.6-6.5-3.7l-4.5-0.5
	 M762,361.3v1c0,0,0,0.5,0.5,0.4c0.6-0.1,10.6-1.1,15-1.8c4.5-0.7,13.8-2.6,14.4-2.8c0.7-0.1,1.1-0.4,1.4-0.8l0.5-1.1
	c0,0,0.3-0.5-0.3-1.2c-0.5-0.5-1.2-1.8-0.4-2.9c1-1.1,2.6-3.3,3.2-4.4c0.7-1.1,1.4-1.6,0.7-3.9c-0.5-2.2-0.7-2.8-1-3.2 M217.6,381.1
	c-9.4,14-25.3,23.2-43.5,23.2c-18.2-0.1-34.1-9.5-43.3-23.7 M57.6,373.9v3.3c0,0,0,0.3,0.3,0.3c0.4,0,19.8,0.3,19.9,0.3
	c0.3,0,0.6-0.1,0.6-0.4c0-0.3,0.1-1.4,0.1-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M44.4,296.3c0.1-0.3,0.4-1,0-1.2
	s-1.1-0.8-1.1-0.8s-0.4-0.3,0-0.8c0.4-0.4,1.1-1,1.1-1s0.5-0.5,1.2,0.1 M33.8,349.1c0-1.4,0.1-2.9,0.1-4.5 M31.8,359.1
	c-0.3,0.1-0.5-0.3-0.5-0.3s-0.3-0.6-0.4-1c-0.1-0.5,0.3-0.5,0.3-0.5s0.5-0.1,1.5-0.3c0.3-0.1,1-0.3,1-1c0-1,0-2.5,0.1-4.8
	 M31.9,315.8c0,0.7-0.1,3,1.2,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M34.1,313.6l-1.2,0.1c0,0-0.7,0.1-1.2,0.5
	c-0.3,0.3-1.1,1.2-0.3,1.4c0.1,0,0.3,0,0.5,0.1 M33.3,320.8l-1.1,0.1c0,0-0.7,0.1-1.2,0.6c-0.3,0.3-1.1,1.2-0.3,1.4
	c0.1,0,0.4,0,0.8,0.1 M33.7,348.8l-1.7,0.1c0,0-0.7,0.1-1.2,0.6c-0.3,0.3-1.1,1.2-0.3,1.4c0.5,0.1,1.9,0.3,3,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M309,254.2l-5.8,0.4c0,0-1.5-0.4-2.2-1.2c-0.7-0.7-3.3-3.7-3.6-4.8
	c-0.3-1.1-0.4-2.1,0.4-2.5c0.8-0.6,2.6-1.4,2.6-1.4s8.9-10.9,15.7-12.7c0,0,4.5-0.7,7.8,0.7c3.2,1.4,5.5,5.1,5.6,8.3s0,7.8-0.7,10.3
	c-0.7,2.5-4.7,3.6-9.2,3.7C319.6,255,314.2,255.4,309,254.2L309,254.2z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M327.8,252.9l100-3.4l13.3-35.6
		c0,0,4.3-8.5-0.4-12.8c0,0-2.2-2.2-6.3-2.6c0,0-29.3,0.6-56.4,8.1c0,0-20.4,5.1-55.4,25.6c0,0,5.2,0.7,6.7,7.7c0,0,0.3,2.2,0.1,6.6
		C329.6,246.6,329.3,252,327.8,252.9z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M464.7,248.5l101.1-1.7l2.6-16.5
		c0,0,0.5-2.8-0.7-5.4c-1.4-2.5-7.3-13.6-9.4-15.4c-2.1-1.8-4.7-4.1-13.9-5.4c0,0-39.2-5.5-52.8-6.1c0,0-16.8-2.2-20.4,12.4
		C471.3,210.4,468.3,225.6,464.7,248.5z"
              id="Left Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M595.9,241.5l-15.7-27.9c0,0-0.8-1.7,1.1-1
		c1.8,0.8,37.4,15.7,52.8,24.8c0,0,4.3,2.1,3.9,4.4c0,0-0.8,1.2-1.8,1.4l-38.5,0.1C597.7,243.2,596.8,243.2,595.9,241.5z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M556.2,336.6l7-1h5.8
		c0,0,12.9-42.4,60.1-42.4c0,0,27.8-1.4,47.6,23l6.1-1c0,0,56.4-5.6,60.5-7c4.1-1.4,8-1.9,6.9-9.9c-1.1-8.1-1.2-13.2-7.4-33
		c0,0-1.1-2.9,1.1-3.4l-1.4-0.3c0,0-42.5-3.2-144-3.2c0,0,1.7,3.9-8.4,21.3c0,0-30.1,50.9-33.8,56.4l-0.4,0.7L556.2,336.6z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M271.3,258c0,0,151.3-6.5,174.7-6.5
		c0,0-4.4,6.3-4.8,8.7c0,0-0.3,3.2-1.9,8.9c0,0-4.4,13.1-4.5,39.8c0,0-0.3,25.7,0.6,32.9l2.5,20.6c0,0-95.7,2.1-172.8,3l-2.3-16.1
		c0,0-4.7-45,3.4-83C266,266.4,266.7,263.2,271.3,258z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M446,251.7c0,0,114.9-2.8,147.1-2.2
		c0,0,4.5,5.6,5.4,9.1c0,0,1.2,2.8-3.2,11.3c0,0-15.7,28.6-39.5,67c0,0-7,13.5-16.6,18.6c0,0-6.5,4.4-22.8,5.1
		c0,0-56.7,1.6-78.5,2.1l-2.5-20.6c0,0-3.3-52.5,4.8-75.2c0,0,1.2-5.2,1.2-6.3C441.2,260.4,440.9,259,446,251.7z"
              id="Left Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              cx="628.7"
              cy="352.4"
              r="52"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              cx="174.6"
              cy="352.5"
              r="52"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M127.8,313.2c0,0-16.1,0.4-32.5,1.5
		c0,0-2.1,0.3-1.4-1.7c0.8-1.9,2.6-5.9,2.6-5.9s2.6-1.2,4.7-3c2.1-1.8,14.9-12.9,14.9-12.9s1.9-1.8,1-3c0,0-0.7-1-2.6-1.1
		c-1.9-0.1-8.9-0.7-22.3-0.4c0,0,34.9-16.6,174-21c0,0-8,27.4-3.9,81.3l0.3,2.9l2.2,15.7l-27.2,0.6c0,0,1.2-28.9-12.4-47.5
		c-13.6-18.7-34-25.9-50.8-25.7C157.9,292.8,143.5,297.2,127.8,313.2z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M303.3,254.6l6.5-0.4c0,0,1.9,0.8,8.1,0.9
		c0,0,6.9,0,9.6-2c0,0,1.8-0.7,2-5.3v-6.2c0,0,0.1-4.3-3-7.1c0,0-2.5-3.2-10.2-2.4c0,0-3.9,0.9-8,4.9c0,0-3.5,2.7-7.7,7.8l-2.5,1.3
		c0,0-0.9,0.2-0.7,1.9c0,0,0.1,0.9,0.7,1.8l2.7,3.5c0,0,1.3,1.1,2.2,1.2L303.3,254.6z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Sedan4DrLeft);
