import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe2DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M239.8,245l5.4-1.9c19-6.2,39.1-11.3,60.1-15.3c26-5.4,52.5-8.4,79.8-9.1c24.4,0.1,46.2,4.3,65.5,12.2
	c8.8,3.7,17.6,7.8,26.4,12.5c13.1,6.9,26,15,38.9,24.1l0.3,0.3l9.2,7h1.9l56.5,1.1c36.5,0.3,70.8,2.2,103,6.2l18.4,2.5
	c27.7,5.4,47.3,12.2,59.3,20.5c2.2-3.6,4.5-6.5,6.9-8.7c-35.8-14.4-77.9-22.8-126.3-25.3l-77-2.8l-7.6,0.3l-31.2,1.1
	c-11.4-8.5-24.9-17.3-40.3-26.1c-1.8-1-3.6-2.1-5.4-3c-12.2-6.7-24.6-13.1-37.4-19c-19.7-6.9-43.5-10.3-71.5-10.2
	c-30.7,1.1-61.1,5-91.1,11.8l-1.4,0.3l-0.4,0.1c-24.9,5.5-50.6,12.1-77.2,19.9c-12.1,3.4-24.5,7.3-36.9,11.3
	c-8.3,2.1-16.5,2.5-24.9,1.4c-3.4-1.5-8.9-0.6-16.5,2.9c-5.5,2.3-12.4,4.3-20.8,5.4c-5.9,1.2-13.6,1.5-23.1,0.5l12.4,3
	c12.8,3.2,26.7,4,41.8,2.6c53.9-3.9,106.5-2.8,157.8,3.6c-51.3-6.3-104-7.4-157.8-3.6c-15,1.4-28.9,0.5-41.8-2.6l-12.4-3
	c-2.9-0.5-5.1-0.5-6.5,0l-0.8,0.5c-1,0.7-1.8,1.6-2.3,2.8c-1.7,3.2-1.5,5,0.3,5.5l18.8,4.7c1.9,0.5,3.3,1.6,4.1,3.2l1.1,2.2l0.8,1.8
	l1,1.8c1.1,3.2,0,6.1-3.2,8.9l23,21.2c1.5,1.4,3.2,2.5,5.1,3.3c2.9,1.2,6.1,1.5,9.5,1.1l35.9,2.1l3-3.2l3-3
	c13.5-12.4,29.9-18.4,49-18.4c19.1,0,35.5,6.2,49,18.4c1.1,1,2.1,1.9,3,3c3.4,3.6,6.3,7.3,8.8,11.1c3.6,5.8,6.1,12.1,7.3,18.8
	c1.8,9.9,2.9,22.1,3,36.6h1.2c15.1-3.2,30.1-4.7,45.3-4.4l202.2,3.6l10.3,0.5l8.4,0.8l15.3,2.2c-0.5-15.5,0-28.6,1.5-38.9
	c0.5-3,1.2-6.1,2.2-8.9c2.5-7.4,6.5-14.2,12.1-20.4l0.8-1c1.4-1.5,2.9-2.9,4.4-4.3c13.5-12.4,29.9-18.4,49-18.4
	c14.2,0,26.7,3.3,37.8,10l10.5-7.7c0.4-1.1,2.2-1.7,5.2-1.8c9.2,0.4,18.6,1.1,27.8,2.1l12.1,1.2c-12.7-8.3-31-14.9-54.6-19.9
	c-32.2-3.9-66.6-5.9-103-6.2l-56.5-1.1c7.6,5.5,11.8,13.5,12.8,23.9c0.3,6.6,0,14-0.8,22.1c-0.8,8-2.1,16.9-3.9,26.4l-0.4,2.5
	c-0.1,1.1-0.1,2.2,0,3.2c12.7,1.5,22.3,3.6,28.8,6.3c6.6,3,3.4,4.4-9.5,4l-19.4-0.5c-0.3,1.4-0.7,2.8-1.1,4.3l-3.4,11.1
	c-0.7,0.7-1.7,1.2-2.8,1.5h0.8h-0.8c-1.2,0.3-2.8,0.3-4.5,0l-147.7-2.1c-13.3-0.1-22.7-4.8-28.2-14l-3.4-5.9l-21.9-1
	c-11.1,0-14.7-1.1-10.6-3.2c7.8-3.2,16.6-5.5,26.5-7c-4-8.3-7.6-16.9-10.5-25.9c-2.8-8.1-5-16.5-6.9-25.2c-1.2-8.7,1-17.3,6.5-26
	l1.9-2.9c-22.3-1.1-44.4-2.8-66.4-5.1c-6.9-0.4-12-1.6-15.3-4c-2.6-2.1-5.5-4.8-8.4-8.3C237.2,247.6,237.4,245.9,239.8,245
	L239.8,245z"
          />
          <path
            fill="#FFFFFF"
            d="M224.2,299.6c-19.1,0-35.5,6.2-49,18.4l-3,3l-3,3.2l11.8,0.7c1.4-1.4,2.9-2.8,4.3-3.9
	c11.1-9.2,24.2-13.8,39.1-13.8c15,0,28.1,4.5,39.1,13.8c1.5,1.2,2.9,2.5,4.3,3.9c9.2,9.1,14.9,19.7,17.1,31.6v0.4
	c1.5,8.9,2.2,19.1,2.2,30.8h8.1c-0.1-14.6-1.2-26.7-3-36.6c-1.2-6.7-3.7-12.9-7.3-18.8c-2.3-3.9-5.4-7.6-8.8-11.1c-1-1-1.9-2.1-3-3
	C259.6,305.6,243.3,299.6,224.2,299.6L224.2,299.6z M296.9,232.2l12.1,31.9l20.5,1.1l-14.9-36.5l-8.9,1.7h-0.1 M283.7,223.2
	l-1.4,0.3 M320.5,295.7c1.8,8.7,4.1,17.1,6.9,25.2c3,8.9,6.5,17.5,10.5,25.9c6.9-1.1,14.3-1.6,22.3-1.8l157,6.6
	c6.5,0.4,12.4,1,17.6,1.5v-3.2l0.4-2.5c1.8-9.6,3.2-18.4,3.9-26.4c0.8-8.1,1.1-15.5,0.8-22.1c-1-10.3-5.1-18.3-12.8-23.9h-1.9
	l-47.2-0.8L327,270C321.5,278.4,319.3,287,320.5,295.7L320.5,295.7z M348.9,296c-4-0.1-7.6-0.7-10.6-1.4c-1.5-0.4-2.8-0.8-3.7-1.2
	c-0.8-0.4-1.5-1-1.8-1.4c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.3-0.5,1-1,1.8-1.4c1-0.4,2.2-1,3.7-1.2
	c3-0.8,6.5-1.2,10.5-1.4l3.6-0.1l7.7,0.4c2.2,0.3,4.4,0.5,6.2,1.1c1.6,0.4,2.9,0.8,3.7,1.4c0.7,0.3,1.1,0.7,1.5,1
	c0.4,0.4,0.5,0.8,0.5,1.2s-0.1,0.8-0.5,1.2c-0.3,0.3-0.8,0.7-1.5,1c-1,0.4-2.2,1-3.7,1.2c-1.9,0.4-3.9,0.8-6.2,1.1l-7.8,0.4
	L348.9,296L348.9,296z"
          />
          <path
            fill="#FFFFFF"
            d="M352.3,296.1l7.8-0.4c1-0.8,1.6-1.6,2.1-2.3h-16c0.5,0.8,1.5,1.8,2.6,2.6L352.3,296.1L352.3,296.1z"
          />
          <path
            fill="#FFFFFF"
            d="M366.3,294.6c1.5-0.4,2.8-0.8,3.7-1.2h-7.8c-0.4,0.8-1.1,1.5-2.1,2.3C362.3,295.4,364.4,295.2,366.3,294.6z
	 M360,286.4c1,0.8,1.6,1.5,2.2,2.3h7.8c-1-0.5-2.2-1-3.7-1.4C364.4,287,362.3,286.6,360,286.4L360,286.4z"
          />
          <path
            fill="#FFFFFF"
            d="M352.3,285.9l-3.6,0.1c-1.4,0.8-2.3,1.8-2.9,2.6h16.4c-0.4-0.8-1.2-1.7-2.2-2.3L352.3,285.9L352.3,285.9z
	 M371.6,292.3c0.4-0.4,0.5-0.8,0.5-1.2s-0.1-0.8-0.5-1.2c-0.3-0.4-0.8-0.7-1.5-1h-35.5c-0.8,0.4-1.5,1-1.8,1.4
	c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.5,1,1,1.8,1.4h35.4C370.7,293,371.1,292.7,371.6,292.3L371.6,292.3z
	 M334.6,293.4c1,0.4,2.2,0.8,3.7,1.2c3,0.8,6.5,1.2,10.6,1.4c-1.1-1-2.1-1.8-2.6-2.6L334.6,293.4L334.6,293.4z"
          />
          <path
            fill="#FFFFFF"
            d="M348.7,286.1c-4,0.1-7.4,0.5-10.5,1.4c-1.5,0.4-2.8,0.8-3.7,1.2h11.3C346.4,287.9,347.3,286.9,348.7,286.1
	L348.7,286.1z M322.7,208.9c-16.5,2.2-32.2,4.8-47,8l8,6.3c30-6.9,60.4-10.9,91.1-11.8c28.1-0.1,51.9,3.3,71.5,10.2
	c-1.9-1-1.9-2.2-0.3-3.4l4.8-3.6c-13.1-5-29.2-8.1-48-9.6C375.1,203.8,348.4,205.2,322.7,208.9L322.7,208.9z M346.9,363.4
	c5.4,9.2,14.7,13.9,28.1,14l147.7,2.1h4.5c1.2-0.3,2.1-0.7,2.8-1.5l3.4-11.1c0.5-1.5,0.8-2.9,1.1-4.3l-191.2-5.4L346.9,363.4
	L346.9,363.4z M337.9,346.8c-9.8,1.5-18.7,3.7-26.5,7c-4.1,2.1-0.7,3,10.6,3.2l21.6,0.5C341.4,353.9,339.6,350.5,337.9,346.8
	L337.9,346.8z"
          />
          <path
            fill="#FFFFFF"
            d="M343.5,357.5l191.2,5.4c0.4-2.1,0.5-4,0.4-5.8c0-1.2-0.1-2.3-0.3-3.4v-0.4c-5.4-0.7-11.3-1.1-17.6-1.6
	l-157-6.6c-7.8,0.1-15.3,0.8-22.3,1.8C339.6,350.5,341.4,353.9,343.5,357.5L343.5,357.5z M287.2,387.6v2.5l298.8,1.5v-1.2h-8
	l-15.3-2.2l-8.4-0.8l-10.3-0.5l-202.2-3.6c-15.1-0.3-30.1,1.2-45.3,4.4L287.2,387.6L287.2,387.6z"
          />
          <path
            fill="#FFFFFF"
            d="M320.4,394.9h203l42.1,2.2l6.3-1.9h13.2l1.1-0.1v-3.6l-298.9-1.5l0.1,5 M165.6,349.5
	c2.9-9.1,8-17.3,15.4-24.5l-11.8-0.7c-5.1,5.9-8.9,12.4-11.3,19.4c-1,2.9-1.7,5.9-2.2,8.9c-1.2,8.5-1.9,18.7-1.8,30.7l8.1,0.5
	c0-9.9,0.6-18.6,1.5-25.9C164.1,355,164.8,352.3,165.6,349.5L165.6,349.5z"
          />
          <path
            fill="#FFFFFF"
            d="M158.1,343.6c2.3-7,6.1-13.5,11.3-19.4l-35.9-2.1c-3.4,0.4-6.6,0-9.5-1.1c-1.8-0.8-3.4-1.8-5.1-3.3l-23-21.2
	l-10.9,10h-0.1c-1,0.8-3,1.2-6.1,1.2l-17.6,0.4l-3.7,0.1c-1.1,1.2-1.9,2.3-2.8,3.3c-1,1.4-1.7,2.5-1.9,3.6c-0.4,1.2,0.1,2.2,1.9,2.8
	l1.4,0.4l3.2,10L56,340.1c-0.5,2.5-2.1,4.7-4.3,6.3l-0.5,4.1c0,1.1,0.4,1.8,1.4,1.9h16.2c4.3,0.5,7.3,1.9,8.9,4
	c4.3,5,8.4,8.9,12.4,11.7c4.7,3.3,9.1,5.5,13.1,6.6c18.7,2.2,33.6,5,44.8,8.1l5.8,0.4c-0.1-12,0.4-22.1,1.8-30.7
	C156.3,349.5,157,346.5,158.1,343.6L158.1,343.6z M139.1,355c-1,1.4-2.5,2.1-4.7,2.1l-20.6-1.4c-1.2,0-2.6-0.7-4.1-2.1
	c-1.9-1.5-1.9-2.3,0-2.5l27.7,1.6C139.5,353.1,140.2,353.8,139.1,355L139.1,355z"
          />
          <path
            fill="#FFFFFF"
            d="M137.3,352.8l-27.7-1.5c-1.9,0.1-1.9,1,0,2.5c1.5,1.4,2.9,2.1,4.1,2.1l20.6,1.4c2.1,0,3.7-0.7,4.7-2.1
	C140.2,353.8,139.5,353.1,137.3,352.8L137.3,352.8z M73.9,276.2l17.1,3.7c1.8,0.4,3.3,1.2,4.5,2.2c0.7,0.5,1.2,1.1,1.7,1.8l-1.1-2.2
	c-0.8-1.5-2.2-2.6-4.1-3.2L73,273.8C72.8,274.4,73,275.2,73.9,276.2L73.9,276.2z"
          />
          <path
            fill="#FFFFFF"
            d="M78,282.4l4.5,1l0.7-0.8l1.1-2.5l4.4,1.9l0.3-0.8l5.2,2.6l1.1-1.5c-1.2-1-2.8-1.6-4.5-2.2l-17.1-3.7l1.1,1
	L78,282.4L78,282.4z M78.3,282.8l-2.2,3.4L64.9,303l-5,3c-1.9,1-1.5,1.8,1.2,2.5l17.6-0.4c3,0,5.1-0.4,6.1-1.2H85l10.9-10
	c3.2-2.9,4.3-5.9,3.2-8.9l-1-1.8l-12.5,17.1c-3.2,1.6-5.1,1.8-5.9,0.3c-2.6,1.2-4.7,1.6-6.2,1.2l-0.6-0.1c-1.1,1.1-2.8,1.4-4.8,1.2
	c-1.5-0.5-1.7-1.4-0.8-2.6l15.1-19.7l-4.5-1L78.3,282.8z"
          />
          <path
            fill="#FFFFFF"
            d="M72.6,286.8L63.6,300c-1.1,0.8-0.7,1.8,1.4,3l11.1-16.8C74.7,285.3,73.5,285.4,72.6,286.8L72.6,286.8z
	 M779.7,310.2c1.1-3.4,3.4-5.8,7-6.9c2.2-1.2,2.3-2.6,0.4-4.1c-3.6-2.2-7.3-4.1-11.1-6.1c-1.5,0.8-3,1.9-4.5,3.3
	c-2.3,2.2-4.7,5.1-6.9,8.7l-6.3,12.2c-1.2,2.2-4,3.2-8.4,2.9l-27.1-3.6c-4.3-0.8-8.3-2.3-12.1-4.8l-14.4-11.3l-10.5,7.7
	c3.9,2.3,7.6,5.2,11.1,8.4c1.5,1.4,3,2.8,4.3,4.3c2.9,3.2,5.4,6.5,7.6,9.9c3.6,5.8,6.1,12.1,7.3,18.8c1.8,9.6,2.6,20.9,2.6,33.6
	l9.6-1.2c2.8-0.4,5.6-0.7,8.4-0.8l36.5-0.8c2.6,0.1,4.3-0.4,5.2-1.6l-47.5-0.8c-3.2-0.1-3.2-2.1,0-5.9l10.6-13.6l2.9-3.7l2.8-3.6
	c1.8-2.1,5.2-3.2,10-3.2c2.3,0,4.5-0.1,6.6,0c5.1,0.1,9.6,0.5,14,1.5c-4.3-0.8-8.9-1.4-14-1.5c2.6,0.3,3.9,1.6,3.9,3.9
	c0.4,2.2-0.1,3.4-1.9,3.7l10.6,0.3c2.8,0,4.1-0.5,4.1-1.7c0-8-1-14.7-2.9-20.2l-0.8-2.1l-1.1-2.3c-0.5-0.8-0.8-1.8-1.1-2.6
	c-0.4-1.8-0.3-3.3,0.5-5l2.6-7.4L779.7,310.2L779.7,310.2z"
          />
          <path
            fill="#FFFFFF"
            d="M776,293.1c-10.9-5.4-23-10-36.5-13.8c-22.3-6.2-51.9-10.7-88.9-13.9c-30.8-2.1-59.4-2.8-85.7-2.2l3,5.1
	l77,2.8c48.4,2.5,90.5,10.9,126.3,25.3C773,294.9,774.5,293.9,776,293.1L776,293.1z"
          />
          <path
            fill="#FFFFFF"
            d="M764.6,305.1c-12-8.3-31.8-15.1-59.3-20.5l-18.4-2.5c23.7,5.1,42,11.7,54.6,19.9
	C749.2,302.9,756.9,303.8,764.6,305.1L764.6,305.1z M708.9,355.6c1.4,7.8,2.1,16.6,2.2,26.5v1.5l7.7-0.4c0-12.7-0.8-23.9-2.6-33.6
	c-1.2-6.7-3.7-12.9-7.3-18.8c-2.1-3.4-4.7-6.7-7.6-9.9c-1.4-1.5-2.8-2.9-4.3-4.3c-3.6-3.2-7.3-6.1-11.1-8.4l-7.2,5.4
	c3.6,2.1,7,4.5,10.3,7.4l2.8,2.6c9.2,9.1,14.9,19.7,17.1,31.6L708.9,355.6L708.9,355.6z M774.9,326.8l1.4-1l1.9-11.4l-2.6,7.4
	C774.8,323.4,774.5,325,774.9,326.8L774.9,326.8z M764.2,347.9h-6.6c-5,0-8.3,1.1-10,3.2l-2.8,3.6l10.9,0.4l3.3,0.1l6.1,0.3h1.4
	c1.7-0.3,2.3-1.5,1.9-3.7C768,349.4,766.8,348.1,764.2,347.9L764.2,347.9z"
          />
          <path
            fill="#FFFFFF"
            d="M764.9,355.4l0.3,1l10.5,1.1c0.8,0.1,1.5,0.5,2.1,1.1l9.6-1.8c2.3-0.4,3.6-1.2,3.7-2.6
	c1.1-5.9,1.7-11,1.7-15.4c0.3-1.4-1.1-2.5-4.1-3.2l-5.8-1l-5-0.8c1.9,5.5,2.9,12.2,2.9,20.2c0,1.1-1.2,1.7-4.1,1.7l-10.3-0.3
	L764.9,355.4L764.9,355.4L764.9,355.4z"
          />
          <path
            fill="#FFFFFF"
            d="M758.8,355.1c1.5,3.3,2.2,6.7,2.1,10.5c0,3.4-1.1,5.1-3.6,5.2h-4.5c-3.2,0.7-5.6,0.1-7.4-1.5
	c-2.1-1.9-2.5-4.8-1-8.7c0.4-1,0.8-1.8,1.4-2.5l-4,0.1l-10.6,13.6l31.5,0.5c2.9,0,4.3-1.5,4.3-4.7c0-3.9-0.5-7.7-1.7-11.6l-0.4-0.8
	L758.8,355.1L758.8,355.1z"
          />
          <path
            fill="#FFFFFF"
            d="M765.1,356.4c1.1,3.9,1.7,7.7,1.7,11.6c0,3.2-1.4,4.7-4.3,4.7l-31.5-0.5c-3.2,3.9-3.2,5.8,0,5.9l47.5,0.8
	c0.7-0.8,1-2.1,0.8-3.6c1.8,1.8,4.4,2.9,8,3.3l2.6,0.4c0.4-1.6-1.2-3-4.8-4.3c-2.1-0.3-3.2-1.1-3.3-2.5l-2.2-9.4
	c-0.1-1.9-0.8-3.3-1.9-4.3c-0.5-0.5-1.2-0.8-2.1-1.1L765.1,356.4L765.1,356.4z M744.5,354.6l-2.9,3.7l4-0.1c2.1-2.8,5.2-3.7,9.8-2.9
	V355L744.5,354.6L744.5,354.6z"
          />
          <path
            fill="#FFFFFF"
            d="M745.6,358.2c-0.5,0.7-1,1.5-1.4,2.5c-1.4,3.9-1.1,6.7,1,8.7c1.7,1.7,4.1,2.2,7.4,1.5l1.2-0.3l1.4-15.3
	C750.8,354.6,747.7,355.6,745.6,358.2L745.6,358.2z M711.1,383.6v5.1l9.8-0.4l13.3-0.7l33.8-2.8l12.2-1c5.6-0.4,10-1.1,13.1-2.1
	c1.7-1.1,1.2-2.1-1.4-2.6h-0.3l-1.9-0.3l-2.6-0.4c-3.4-0.4-6.1-1.5-8-3.3c0.1,1.5-0.1,2.8-0.8,3.6c-1,1.2-2.8,1.8-5.2,1.6l-36.5,0.8
	c-2.8,0.1-5.6,0.4-8.4,0.8l-9.6,1.2L711.1,383.6L711.1,383.6z M777.9,333.8l5,0.8l-5.8-2.9 M560.6,268.5l7.6-0.3l-3-5.1h-14
	C554.1,264.9,557.3,266.7,560.6,268.5L560.6,268.5z M685.9,308.2c-11-6.7-23.7-10-37.8-10c-19.1,0-35.5,6.2-49,18.4
	c-1.5,1.4-3,2.8-4.4,4.3l-0.8,1c-5.5,6.2-9.6,13.1-12.1,20.4c-1,2.9-1.7,5.9-2.2,8.9c-1.7,10.3-2.1,23.4-1.5,38.9h8
	c-0.3-13.3,0.1-24.5,1.5-33.6c0.4-2.9,1.1-5.8,1.9-8.5c2.9-9.1,8-17.3,15.4-24.5l2.8-2.6c11.4-10,25-15.1,40.7-15.1
	c11.3,0,21.3,2.6,30.4,7.7L685.9,308.2L685.9,308.2z M585.1,395.2h1.2l-0.1-0.1 M534.8,353.5c0.1,1.1,0.1,2.3,0.3,3.4
	c0.1,1.9,0,3.9-0.4,5.8l19.4,0.5c12.9,0.4,16.1-1,9.5-4c-6.5-2.8-16.1-4.8-28.8-6.3L534.8,353.5L534.8,353.5z M527.3,379.8h-4.5
	C524.5,379.9,526,379.9,527.3,379.8L527.3,379.8z M465.5,264.2h2.1c-0.1-5.9,1.1-9.4,3.7-10.3c-2.9,1-4.5,2.8-5,5.4L465.5,264.2
	L465.5,264.2z M327,269.9l150.9,4.1l47.2,0.8l-9.2-7l0.1,0.3c0.1,0.3,0.1,0.4,0,0.7c0,0.3-0.1,0.4-0.4,0.5l-0.7,0.1l-10.9-0.1
	c1.8,0.5,2.9,1.5,3.3,2.9c-0.8,0.4-1.7,0.7-2.6,1c-1.8,0.5-4,0.7-6.5,0.7h-10.6l-3-2.8c-7.3,0.1-13.1-0.5-17.1-1.8
	c-0.3-0.1-0.4-0.1-0.5-0.3l-84.9-0.7c-17.9-0.1-35.6-0.7-53.4-1.5L327,269.9L327,269.9z"
          />
          <path
            fill="#FFFFFF"
            d="M505,273.3c-4.3-1.8-10.9-3.2-20.1-4c-5.4-0.5-11.6-0.8-18.6-1.1c0.3,0.4,0.5,0.7,1,0.8
	c0.1,0.1,0.4,0.3,0.5,0.3c4,1.2,9.8,1.9,17.1,1.8l3,2.8h10.6C501,274.1,503.2,273.8,505,273.3z"
          />
          <path
            fill="#FFFFFF"
            d="M329.1,267c17.6,0.8,35.5,1.4,53.4,1.5l84.9,0.7c-0.4-0.3-0.7-0.5-1-0.8c-0.1-0.3-0.3-0.5-0.4-0.8l-83.5-0.7
	c-17.7-0.1-35.4-0.5-52.8-1.5L329.1,267L329.1,267z"
          />
          <path
            fill="#FFFFFF"
            d="M504.3,269.4c-0.4-0.1-1-0.3-1.5-0.4c-1.5-0.3-3.2-0.5-4.8-0.8c-1.5-0.3-3-0.4-4.5-0.5
	c-4.5-0.4-9.6-0.7-15.1-0.8h-12.4c0,0.1,0.1,0.4,0.1,0.5l0.4,0.8c7,0.1,13.2,0.5,18.6,1.1c9.1,0.8,15.8,2.2,20.1,4
	c1-0.3,1.8-0.5,2.6-1C507.1,271.1,506,270.1,504.3,269.4L504.3,269.4z"
          />
          <path
            fill="#FFFFFF"
            d="M497.8,268.2l0.5-0.5h-5.1C494.8,267.8,496.5,267.9,497.8,268.2z M498.4,267.6c0.5-0.7,1-1.2,1-1.9
	c-8.7-1.2-19.3-1.8-31.9-1.5h-2.1c0,1,0,1.9,0.3,2.6H478c5.5,0,10.6,0.3,15.1,0.8L498.4,267.6L498.4,267.6z"
          />
          <path
            fill="#FFFFFF"
            d="M238.8,249.6c2.9,3.4,5.6,6.2,8.4,8.3c3.3,2.3,8.5,3.6,15.3,4c22,2.3,44.2,4,66.4,5.1l0.5-1.8l-20.5-1.1
	c-15.4-1-30.8-2.3-46.1-3.9l-0.3-0.1c-3.7-0.5-7-1.9-9.9-4.3c-2.2-1.5-4.4-3.6-6.7-6.1c-1.9-1.9-1.7-3.4,0.7-4.5l5.6-1.8
	c14.3-4.4,29-8.3,44.4-11.4l8.8-1.6l9.1-1.8c23-4.3,46.4-6.6,70.3-7.3c23.9,0.1,45.5,4.1,64.7,12c7.3,3,14.4,6.3,21.6,10
	c5.8,3,11.6,6.2,17.3,9.5c7.7,4.5,15.4,9.5,23,14.7l-13.2-0.1c-0.1,0.1-0.4,0.4-0.6,0.5c1.7,0.3,3.3,0.5,4.8,0.8
	c0.5,0.1,1.1,0.3,1.5,0.4l10.9,0.1l0.7-0.1c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.3,0.1-0.4,0-0.7l-0.1-0.3l-0.3-0.3
	c-12.9-9.1-25.9-17.1-38.9-24.1c-8.8-4.7-17.6-8.8-26.4-12.5c-19.4-8-41.1-12-65.5-12.2c-27.2,0.7-53.8,3.7-79.8,9.1
	c-21,4-41.1,9.1-60.1,15.3l-5.4,1.9C237.4,245.9,237.2,247.6,238.8,249.6L238.8,249.6z"
          />
          <path
            fill="#FFFFFF"
            d="M499.4,265.7c-0.1,0.7-0.4,1.2-1,1.9l13.2,0.1c-7.7-5.4-15.3-10.2-23-14.7v2.2c4.3,2.8,7.4,5.4,9.6,7.7
	C499.1,263.9,499.5,264.8,499.4,265.7L499.4,265.7z"
          />
          <path
            fill="#FFFFFF"
            d="M498.3,263c-2.2-2.3-5.4-5-9.6-7.7c-0.3-0.1-0.5-0.4-1-0.6c-3.7-1.6-7.7-2.1-11.8-1.5
	c-1.8,0.1-3.3,0.4-4.5,0.8c-2.6,1-3.9,4.4-3.7,10.3c12.5-0.1,23.1,0.3,31.9,1.5C499.5,264.8,499.1,263.9,498.3,263L498.3,263z"
          />
          <path
            fill="#656565"
            d="M284.9,356.5c-2.1-12-7.8-22.6-17.1-31.6c-1.4-1.4-2.9-2.8-4.3-3.9c-11.1-9.2-24.2-13.8-39.1-13.8
	c-15,0-28.1,4.5-39.1,13.8c-1.5,1.2-2.9,2.5-4.3,3.9c-7.4,7.3-12.5,15.4-15.3,24.5c-0.8,2.8-1.5,5.6-1.9,8.5c-1,7.3-1.5,16-1.5,25.9
	v4.8l14.7,4.5c-5.2-8.4-7.8-17.7-7.8-28.3c0-7.6,1.4-14.7,4.1-21.2c2.6-6.3,6.6-12.1,11.8-17.2c2.1-2.1,4.3-4,6.5-5.6
	c9.2-6.9,19.9-10.3,31.9-10.3s22.7,3.4,31.9,10.3c2.2,1.6,4.4,3.6,6.5,5.6c10.6,10.6,16,23.4,16,38.4c0,5.8-0.8,11.3-2.3,16.4
	c-1.5,4.8-3.6,9.2-6.5,13.5h18.4l-0.1-4.8v-2.5c0-11.6-0.7-21.9-2.2-30.8L284.9,356.5L284.9,356.5L284.9,356.5z M287.4,394.9h-6.9
	l0.3,5.2h39.6v-5.2"
          />
          <path
            fill="#656565"
            d="M185,326.5c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2c0,10.5,2.6,19.9,7.8,28.3
	c2.2,3.6,5,6.9,8.1,10.2c10.6,10.6,23.4,16,38.4,16c15,0,27.8-5.2,38.4-16c2.8-2.8,5.1-5.5,7.2-8.5c2.9-4.3,5-8.7,6.5-13.5
	c1.7-5.1,2.3-10.6,2.3-16.4c0-15-5.2-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3s-22.7,3.4-31.9,10.3
	C189.3,322.7,187.1,324.5,185,326.5L185,326.5z M183.6,365c0-11,3.9-20.4,11.7-28.2c7.8-7.8,17.2-11.7,28.2-11.7
	c11,0,20.4,3.9,28.2,11.7c7.8,7.8,11.7,17.2,11.7,28.2s-3.9,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7c-11,0-20.4-3.9-28.2-11.7
	C187.5,385.4,183.6,376.1,183.6,365z"
          />
          <path
            fill="#656565"
            d="M196.7,341l9.1,6.1c1.8-1.8,3.7-3.2,5.8-4.3l-2.9-10.6c-3.9,1.8-7.4,4.3-10.6,7.4L196.7,341L196.7,341z
	 M195.5,342.5c-5.4,6.5-8,13.9-8,22.6v1.9h10.7V365c0-6.2,1.9-11.7,5.9-16.2L195.5,342.5L195.5,342.5z M202.1,378.3l-8.8,6.3
	c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4l3.3-10.3c-4.4-1.1-8.4-3.3-11.8-6.7C204.3,381.3,203,379.8,202.1,378.3L202.1,378.3z
	 M198.6,369.3l-10.9-0.5c0.6,5.2,2.1,9.9,4.5,14.2l8.5-6.9C199.9,374,199.1,371.8,198.6,369.3L198.6,369.3z M210.5,331.5l3.3,10.3
	c3-1.2,6.2-1.9,9.8-1.9c3.4,0,6.5,0.5,9.4,1.8l3.3-10.3c-4-1.5-8.3-2.2-12.8-2.2S214.5,329.8,210.5,331.5L210.5,331.5z M240.9,346.9
	l9.2-6.1l-1.2-1.2c-3.3-3.3-6.9-5.8-10.9-7.6l-2.9,10.6C237.2,343.7,239.1,345.1,240.9,346.9L240.9,346.9z M242.5,348.5
	c4.1,4.7,6.2,10,6.2,16.5v1.8h10.7V365c0-8.7-2.8-16.4-8.1-22.8L242.5,348.5L242.5,348.5z M246.1,376.1l8.5,6.9
	c2.6-4.4,4.1-9.1,4.5-14.3l-10.9,0.5C247.9,371.5,247.2,373.9,246.1,376.1L246.1,376.1z M245,378.1c-1.1,1.6-2.2,3.2-3.7,4.7
	c-3.4,3.4-7.4,5.6-11.8,6.7l3.4,10.3c6.1-1.5,11.4-4.7,16.1-9.4c1.9-1.9,3.4-3.9,4.8-5.9L245,378.1L245,378.1z M227.1,389.9
	l-3.7,0.3l-3.6-0.3l-3.9,10.3c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7L227.1,389.9L227.1,389.9z M154.1,383.2l-5.8-0.4
	c-11.1-3.2-26.1-5.9-44.8-8.1c-4.1-1.1-8.5-3.3-13.1-6.6c-4-2.8-8.1-6.7-12.4-11.7c-1.7-2.1-4.7-3.4-9.1-4H52.5
	c-1.9,0.4-2.6,1.1-2.1,1.9l14.2,0.1c3.3,0.3,5.9,1.1,8.1,2.5c0.8,0.6,1.7,1.2,2.2,1.9l1.4,1.6l5.6,7.1l7.4,9.4
	c0.6,0.5,1.2,1.1,1.9,1.5c1.4,0.8,3,1.7,5,2.2c1.7,0.4,3.4,0.8,5.4,1.1l60.4,6.7v-4.8L154.1,383.2L154.1,383.2z"
          />
          <path
            fill="#656565"
            d="M63.4,360.6l18.8,18l-2.5-3c-1.4-1.9-2.5-3.6-3.3-5.2c-0.8-1.5-1-2.5-0.6-2.8h1.9l-0.6-1.2
	c-0.8-1.6-1.1-2.8-0.7-3h0.6c0.6,0.1,1.2,0.7,2.2,1.5l2.2,2.3l0.3,0.3H82l-5.6-7.2h-7.4c-1,0.1-1.7-0.1-2.2-0.5l-1.1-1.4
	c-1.2-1.1-1.2-1.9,0-2.2l-12.4-0.8l-0.1,2.3c2.2,0,4.1,0.1,5.6,0.5C60.9,358.7,62.5,359.5,63.4,360.6L63.4,360.6z"
          />
          <path
            fill="#656565"
            d="M58.9,358.3l-1.1,2.2c-0.7,1.8,0,3.2,2.2,4.1l6.7,4l12.9,12c3,1,5.9,1.6,8.8,2.3l0.4-1.1l-2.6-0.4h-0.1
	c-0.4,0-1.1-0.5-2.1-1.4l-1.5-1.5l-18.8-18C62.5,359.5,60.9,358.7,58.9,358.3L58.9,358.3z"
          />
          <path
            fill="#656565"
            d="M58.9,358.3c-1.5-0.4-3.4-0.5-5.6-0.5c3.7,4.1,6.1,9.4,7.3,15.8c6.6,2.9,12.9,5.2,19,7l-12.9-12l-6.7-4
	c-2.2-1.1-2.9-2.5-2.2-4.1L58.9,358.3L58.9,358.3z M51.6,355.4l1.8,0.1l12.4,0.8l7,0.5c-2.1-1.4-4.8-2.2-8.1-2.5l-14.2-0.1
	L51.6,355.4L51.6,355.4z M76.8,363.4c-0.4,0.4-0.3,1.4,0.6,3l0.6,1.2h3.9l-0.3-0.3l-2.2-2.3c-1-1-1.7-1.4-2.2-1.5
	C77,363.4,76.9,363.4,76.8,363.4L76.8,363.4z"
          />
          <path
            fill="#656565"
            d="M75,358.9c-0.7-0.7-1.4-1.4-2.2-1.9l-7-0.5c-1.2,0.3-1.2,1.1,0,2.2l1.1,1.4c0.6,0.4,1.2,0.7,2.2,0.5h7.4
	L75,358.9L75,358.9z M75.9,367.7c-0.4,0.3-0.1,1.1,0.6,2.8c0.7,1.5,1.8,3.3,3.3,5.2l2.5,3l1.5,1.5c1,0.8,1.7,1.4,2.1,1.4h0.3
	c0.4-0.3,0.1-1.1-0.6-2.8c-0.4-1-1.1-1.9-1.8-3l-1.5-2.1c-0.7-1-1.4-1.6-1.9-2.5l-2.1-2.2c-1-0.8-1.5-1.2-2.1-1.4
	C76.2,367.7,75.9,367.7,75.9,367.7z"
          />
          <path
            fill="#656565"
            d="M80.5,371.2c0.7,0.7,1.2,1.5,1.9,2.5l1.5,2.1c0.7,1.1,1.4,2.2,1.8,3c0.7,1.5,1,2.5,0.6,2.8h-0.1l2.6,0.4
	l4.3,0.5c0.6-1.1,0.1-2.5-1.7-4c-0.7-0.4-1.4-1-1.9-1.5l-7.4-9.4h-5.5c0.4,0.1,1.1,0.5,2.1,1.4L80.5,371.2L80.5,371.2z"
          />
          <path
            fill="#656565"
            d="M93,382.5l3.4-1.8c-1.9-0.5-3.7-1.4-5-2.2C93.1,380,93.7,381.4,93,382.5L93,382.5z M708.9,355.1
	c-2.1-12-7.8-22.6-17.1-31.6l-2.8-2.6c-3.2-2.9-6.6-5.4-10.3-7.4c-9.1-5.2-19.1-7.7-30.4-7.7c-15.7,0-29.2,5.1-40.7,15.1l-2.8,2.6
	c-7.4,7.3-12.5,15.4-15.4,24.5c-0.8,2.8-1.5,5.6-1.9,8.5c-1.2,9.1-1.8,20.2-1.5,33.6v1.2l0.1,3.4l5.1-0.7l10.6-1.4
	c-5.1-8.3-7.6-17.5-7.6-27.9c0-7.6,1.4-14.7,4.1-21.2c2.6-6.3,6.6-12.1,11.8-17.2c2.1-2.1,4.3-4,6.5-5.6
	c9.2-6.9,19.9-10.3,31.9-10.3c12.1,0,22.7,3.4,31.9,10.3c2.2,1.6,4.4,3.6,6.5,5.6c10.6,10.6,16,23.4,16,38.4
	c0,5.8-0.8,11.3-2.3,16.4l-0.8,2.5l11.3-1.8c-0.1-9.9-0.8-18.7-2.2-26.5L708.9,355.1L708.9,355.1z M768.2,384.9l-33.8,2.8l-13.3,0.7
	v5.9h16.9c13.1-0.3,23.2-1.5,30.3-3.7V384.9L768.2,384.9z"
          />
          <path
            fill="#656565"
            d="M610.2,326.5c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2c0,10.3,2.5,19.7,7.6,27.9
	c2.3,3.7,5.1,7.3,8.4,10.6c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c5.8-5.8,10-12.4,12.7-19.5l0.8-2.5
	c1.7-5.1,2.3-10.6,2.3-16.4c0-15-5.4-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3
	C614.5,322.7,612.3,324.5,610.2,326.5L610.2,326.5z M608.7,365c0-11,3.9-20.4,11.7-28.2c7.8-7.8,17.2-11.7,28.2-11.7
	s20.4,3.9,28.2,11.7c7.8,7.8,11.7,17.2,11.7,28.2s-3.9,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7s-20.4-3.9-28.2-11.7
	C612.7,385.4,608.7,376.1,608.7,365L608.7,365z"
          />
          <path
            fill="#656565"
            d="M621.9,341l9.1,6.1c1.8-1.8,3.7-3.2,5.8-4.3l-2.9-10.6c-3.8,1.8-7.4,4.3-10.6,7.4L621.9,341L621.9,341z
	 M620.7,342.5c-5.4,6.5-8,13.9-8,22.6v1.9h10.7V365c0-6.2,1.9-11.7,5.9-16.2L620.7,342.5L620.7,342.5z M627.2,378.3l-8.8,6.3
	c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4l3.3-10.3c-4.4-1.1-8.4-3.3-11.8-6.7C629.5,381.3,628.3,379.8,627.2,378.3L627.2,378.3
	z M623.8,369.3l-10.9-0.5c0.5,5.2,2.1,9.9,4.5,14.2l8.5-6.9C625,374,624.3,371.8,623.8,369.3L623.8,369.3z M635.5,331.5l3.3,10.3
	c3-1.2,6.2-1.9,9.8-1.9c3.4,0,6.5,0.5,9.4,1.8l3.3-10.3c-4-1.5-8.3-2.2-12.8-2.2C643.9,329.1,639.7,329.8,635.5,331.5L635.5,331.5z
	 M666.1,346.9l9.2-6.1l-1.2-1.2c-3.3-3.3-6.9-5.8-10.9-7.6l-2.9,10.6C662.2,343.7,664.3,345.1,666.1,346.9L666.1,346.9z
	 M673.8,366.7h10.7v-1.8c0-8.7-2.8-16.4-8.1-22.8l-8.8,6.3c4.1,4.7,6.2,10,6.2,16.5V366.7z M673.5,369c-0.4,2.5-1.1,4.8-2.2,7
	l8.5,6.9c2.6-4.4,4.1-9.1,4.5-14.3L673.5,369L673.5,369z M670.2,378.1c-1.1,1.6-2.2,3.2-3.7,4.7c-3.4,3.4-7.4,5.6-11.8,6.7l3.4,10.3
	c6.1-1.5,11.4-4.7,16.1-9.4c1.9-1.9,3.4-3.9,4.8-5.9L670.2,378.1L670.2,378.1z M652.3,389.9l-3.7,0.3l-3.6-0.3l-3.9,10.3
	c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7L652.3,389.9L652.3,389.9z M586.2,395l0.1,0.1h-14.4l-6.3,1.9l-42.1-2.2l3.6,5.2h62.2
	l2.2-5.8"
          />
          <path
            fill="#EBEBEB"
            d="M236.6,226.9c-18.7,5.1-37.3,10.6-56,16.5c-12.5,4-25,8.3-37.7,12.7c8.3,1.1,16.5,0.7,24.9-1.4
	c12.5-4,24.8-7.8,36.9-11.3c26.6-7.7,52.3-14.4,77.2-19.9l0.4-0.1l1.2-0.3l-8-6.3C261.9,219.8,248.9,223.1,236.6,226.9L236.6,226.9z
	 M246.7,245.4c-2.3,1.1-2.6,2.5-0.7,4.5c2.3,2.5,4.7,4.5,6.7,6.1c2.9,2.2,6.2,3.7,9.9,4.3l0.3,0.1c15.3,1.7,30.7,2.9,46.1,3.9
	l-12.1-31.9c-15.4,3.2-30.1,6.9-44.4,11.4L246.7,245.4L246.7,245.4z M195.2,393.2c7.8,7.8,17.2,11.7,28.2,11.7
	c11,0,20.4-3.9,28.2-11.7c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7c-11,0-20.4,3.9-28.2,11.7
	c-7.8,7.8-11.7,17.2-11.7,28.2C183.6,376.1,187.5,385.4,195.2,393.2L195.2,393.2z M185.8,365c0-10.5,3.7-19.3,11-26.7
	c7.4-7.4,16.2-11,26.7-11c10.5,0,19.3,3.7,26.7,11c7.3,7.4,11,16.2,11,26.7s-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11
	s-19.3-3.7-26.7-11C189.4,384.3,185.8,375.4,185.8,365L185.8,365z"
          />
          <path
            fill="#EBEBEB"
            d="M196.9,391.6c7.4,7.4,16.2,11,26.7,11c10.5,0,19.3-3.7,26.7-11c7.3-7.4,11-16.2,11-26.7
	c0-10.5-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11c-7.3,7.4-11,16.2-11,26.7
	C185.8,375.4,189.4,384.3,196.9,391.6L196.9,391.6z M198.1,339.6c3.2-3.2,6.7-5.8,10.6-7.4l1.6-0.7c4-1.5,8.4-2.3,13.1-2.3
	c4.5,0,8.8,0.7,12.8,2.2l1.6,0.7c4,1.8,7.6,4.3,10.9,7.6l1.2,1.2l1.2,1.4c5.4,6.5,8.1,14,8.1,22.8v1.8c0,0.5,0,1.2-0.1,1.8
	c-0.4,5.2-1.9,10-4.5,14.3l-1,1.5c-1.4,2.1-3,4-4.8,5.9c-4.7,4.7-10,7.8-16.1,9.4l-1.8,0.4c-2.3,0.5-4.9,0.7-7.6,0.7
	s-5.1-0.3-7.4-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4c-1.8-1.8-3.4-3.7-4.8-5.8l-1-1.5c-2.6-4.3-4.1-9.1-4.5-14.2l-0.1-1.8v-1.9
	c0-8.7,2.6-16.1,8-22.6l1.2-1.4L198.1,339.6L198.1,339.6z"
          />
          <path
            fill="#EBEBEB"
            d="M204.3,348.8l9.4,6.7l0.7,0.5c2.9,2.2,4.1,4.7,3.6,7.6c-1.4,2.2-3.7,3.3-7,3.3h-23.4l0.1,1.8l10.9,0.5
	l2.1,0.1c1.4,0.1,2.2,0.5,2.8,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.6,1.4l-8.5,6.9l1,1.5l8.8-6.3l9.4-6.9
	l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1c1.6,1.9,2.1,4.5,1,7.7l-0.3,1l-3.6,11.1l-3.3,10.3l1.8,0.4l3.9-10.3l0.8-2.1
	c0.4-1.2,1.2-1.9,2.1-2.2h1.5c1,0.3,1.6,1,2.1,2.2l0.8,2.1l3.9,10.3l1.8-0.4l-3.4-10.3l-3.6-11.1l-0.3-1c-1.1-3.4-0.6-6.2,1.5-8.3
	c2.3-1,5-0.5,7.7,1.4l1,0.7l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7
	c0.6-0.8,1.4-1.2,2.6-1.4l2.2-0.1l10.9-0.5c0-0.6,0.1-1.2,0.1-1.8h-23.4c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5
	l9.5-6.9l8.8-6.3l-1.2-1.4l-9.2,6.1l-1.8,1.2c-1.1,0.7-2.1,0.8-3,0.5l-0.7-0.4l-0.6-0.6c-0.6-0.7-0.8-1.6-0.4-3l0.6-2.1l2.9-10.6
	l-1.7-0.7l-3.3,10.3l-3.6,11.1l-0.3,1c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1l-3.6-11.1l-3.3-10.3l-1.6,0.7
	l2.9,10.6l0.6,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.5c-0.3,0.1-0.4,0.3-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.6-1.1l-9.1-6.1l-1.2,1.4
	L204.3,348.8L204.3,348.8z M223.5,360.1c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4c0,1.4-0.6,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4
	s-2.5-0.5-3.4-1.4c-1-1-1.4-2.1-1.4-3.4c0-1.4,0.4-2.5,1.4-3.4C221.1,360.6,222.2,360.1,223.5,360.1L223.5,360.1z"
          />
          <path
            fill="#EBEBEB"
            d="M220.1,361.6c-1,1-1.4,2.1-1.4,3.4s0.4,2.5,1.4,3.4s2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4
	s-0.6-2.5-1.4-3.4c-1-1-2.1-1.4-3.4-1.4C222.2,360.1,221.1,360.6,220.1,361.6z M198.4,367h11.7l-0.1-1.9c0-3.7,1.2-6.7,3.7-9.4
	l-9.4-6.9c-4,4.5-5.9,10-5.9,16.2V367z M203.9,371.4l-0.3-0.7c-0.4-0.8-1.4-1.2-2.8-1.4l-2.1-0.1c0.4,2.5,1.1,4.7,2.2,6.9l1.7-1.4
	c1-0.8,1.5-1.8,1.4-2.6C203.9,371.9,203.9,371.7,203.9,371.4L203.9,371.4z M205.7,382.8c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1
	c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2l-9.4,6.9C203,379.8,204.3,381.3,205.7,382.8L205.7,382.8z M213.8,341.7l3.6,11.1
	c1.9-1,3.9-1.4,6.2-1.4c2.1,0,4.1,0.4,5.8,1.2l3.6-11.1c-2.9-1.1-6.1-1.8-9.4-1.8C220,339.9,216.8,340.4,213.8,341.7L213.8,341.7z
	 M236.2,348.5c0.8,0.4,1.9,0.3,3-0.6l1.8-1.2c-1.8-1.8-3.7-3.2-5.9-4.3l-0.6,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5L236.2,348.5
	L236.2,348.5z M232.2,356c-2.6,2.1-3.9,4.3-3.7,6.9c1.2,2.6,3.7,3.9,7.3,4h1.1l0.1-1.8c0-3.7-1.4-6.9-4-9.6L232.2,356L232.2,356z
	 M230.4,360.6l1.9,0.3l0.8,1.8l-1.2,1.6l-1.9-0.3l-0.8-1.8L230.4,360.6L230.4,360.6z"
          />
          <path
            fill="#EBEBEB"
            d="M230.1,364.1l1.9,0.3l1.2-1.7l-0.8-1.8l-2.1-0.3l-1.1,1.6 M229.3,352.7c-1.8-0.8-3.7-1.2-5.8-1.2
	c-2.2,0-4.3,0.5-6.2,1.4l0.3,1c1.1,3.2,2.9,5.1,5.4,5.6c2.8-0.4,4.8-2.3,5.9-5.8L229.3,352.7L229.3,352.7z M225.5,356.5l-0.8,1.8
	l-2.1,0.3l-1.1-1.6l0.8-1.8l1.9-0.1L225.5,356.5L225.5,356.5z"
          />
          <path
            fill="#EBEBEB"
            d="M222.3,355.1l-0.8,1.8l1.2,1.7l1.9-0.3l0.8-1.8l-1.1-1.6 M210.5,348.7c0.3-0.1,0.4-0.3,0.7-0.4l0.6-0.5
	c0.6-0.7,0.8-1.6,0.4-3l-0.6-2.1c-2.1,1.1-4,2.6-5.8,4.3l1.6,1.1C208.7,348.9,209.6,349.1,210.5,348.7L210.5,348.7z M213.6,355.6
	c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9h1c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6L213.6,355.6L213.6,355.6z M217.5,361.7l-0.4,1.9
	l-1.9,0.5l-1.5-1.4l0.4-1.9l1.9-0.7L217.5,361.7L217.5,361.7z"
          />
          <path
            fill="#EBEBEB"
            d="M214.2,360.9l-0.4,1.9l1.4,1.4l1.9-0.5l0.4-1.9l-1.5-1.4 M243.1,371.2l-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6
	l1.8,1.5c1.1-2.2,1.8-4.5,2.2-7l-2.2,0.1c-1.2,0.1-2.2,0.5-2.8,1.4L243.1,371.2z M248.6,365c0-6.3-2.1-11.8-6.2-16.5l-9.5,6.9
	c2.6,2.6,4,5.8,4,9.6l-0.1,1.8h11.6C248.6,366.1,248.6,365.6,248.6,365z M211.4,371.4c0.7,1.1,1.5,2.2,2.5,3.2
	c2.1,2.1,4.5,3.3,7.3,3.9l0.3-1c1-3.2,0.6-5.8-1.1-7.7c-2.5-1.4-5.2-1-8.3,1.1L211.4,371.4L211.4,371.4z M220.2,370.5v2.1l-1.8,1
	l-1.6-1v-2.1l1.6-1L220.2,370.5z"
          />
          <path
            fill="#EBEBEB"
            d="M216.8,370.5v1.9l1.6,1.1l1.8-1.1v-1.9l-1.8-1 M234.7,370.5c-2.8-1.9-5.4-2.3-7.7-1.4
	c-2.1,2.1-2.5,4.7-1.5,8.3l0.3,1c2.8-0.4,5.2-1.6,7.3-3.9c1.1-1.1,1.9-2.2,2.5-3.3L234.7,370.5L234.7,370.5z M228.6,373.6l-1.9-0.5
	l-0.4-1.9l1.5-1.4l1.9,0.5l0.4,1.9L228.6,373.6L228.6,373.6z"
          />
          <path
            fill="#EBEBEB"
            d="M229.6,370.3l-1.8-0.5l-1.5,1.4l0.4,1.9l1.9,0.5l1.4-1.2 M233,374.5c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7l-9.4-6.9C235,372.5,234.1,373.6,233,374.5L233,374.5z M224.2,385.7h-1.5
	c-1,0.3-1.6,1-2.1,2.2l-0.8,2.1l3.6,0.3l3.7-0.3l-0.8-2.1C225.9,386.6,225.2,385.8,224.2,385.7L224.2,385.7z M88.7,282l-4.4-1.9
	l-1.1,2.5l-0.7,0.8L67.4,303c-1,1.2-0.7,2.2,0.8,2.6c2.1,0.3,3.7-0.1,4.8-1.2c-0.6-0.4-0.3-1.1,0.6-2.3l15-19.4L88.7,282L88.7,282z"
          />
          <path
            fill="#EBEBEB"
            d="M88.5,282.8l-15,19.4c-0.8,1.1-1,1.9-0.6,2.3l0.6,0.1c1.5,0.4,3.6,0,6.2-1.2l14.7-19.8l-5.2-2.6l-0.3,0.8
	L88.5,282.8L88.5,282.8z M94.4,283.6l-14.7,19.8c0.8,1.5,2.8,1.5,5.9-0.3l12.5-17.5l-0.8-1.8c-0.6-0.7-1.1-1.2-1.7-1.8L94.4,283.6
	L94.4,283.6z M758.2,317.3l6.3-12.2c-7.7-1.1-15.4-2.1-23.1-3l-12.1-1.2l-0.5,12.8l4,2.8l-9.6-1.2c-2.9-0.3-5.9-1.5-9.1-3.9
	l-0.8-0.5l-8.1-5.9c-1.5-1.2-2.2-2.3-2.1-3c0-0.7,0.3-1.2,1.1-1.5l1.8-0.1l16.8,1.5c0.1,0,0.4,0.1,0.5,0.1l0.4,0.4
	c0,0.1,0.1,0.4,0,0.5c-0.4,3,1.1,6.1,4.4,9.1c0.1,0.1,0.3,0.4,0.3,0.5v0.5l0.7,0.4l0.5-12.8c-9.4-0.8-18.6-1.5-27.8-2.1
	c-3,0.1-4.8,0.7-5.2,1.8l14.4,11.3c3.9,2.5,7.8,4,12.1,4.8l27.1,3.6C754.3,320.5,757,319.5,758.2,317.3L758.2,317.3z M757.6,305.9
	l-6.2,11.7c-2.1-0.1-2.8-0.5-2.2-1.4l4.3-8.8C754.4,305.9,755.8,305.3,757.6,305.9L757.6,305.9z M742.3,315.7l4.3-8.8
	c1-1.6,2.2-2.2,4.1-1.6l-6.2,11.7C742.6,316.9,741.7,316.4,742.3,315.7L742.3,315.7z M736,315.4l4.3-8.8c1-1.6,2.2-2.2,4.1-1.6
	l-6.2,11.7C736.1,316.5,735.4,316.1,736,315.4L736,315.4z"
          />
          <path
            fill="#EBEBEB"
            d="M753.6,307.5l-4.3,8.8c-0.5,0.7,0.3,1.1,2.2,1.4l6.2-11.7C755.8,305.3,754.4,305.9,753.6,307.5L753.6,307.5z
	 M744.5,317l6.2-11.7c-1.8-0.5-3.2,0-4.1,1.6l-4.3,8.8C741.7,316.3,742.6,316.9,744.5,317L744.5,317z M738.2,316.8l6.2-11.7
	c-1.8-0.5-3.2,0-4.1,1.7l-4.3,8.8C735.4,316.1,736.1,316.5,738.2,316.8L738.2,316.8z M723.4,302.3l-0.4-0.4
	c-0.1-0.1-0.4-0.1-0.5-0.1l-16.8-1.5l-1.8,0.1c-0.8,0.3-1.2,0.7-1.1,1.5c-0.1,0.7,0.5,1.7,2.1,3l8.1,5.9l0.8,0.5l2.5,1.2
	c4.5,1.9,8.3,2.3,11,1.2c0.3-0.1,0.4-0.3,0.5-0.4v-0.1c0.1-0.1,0.1-0.4,0-0.5c0-0.3-0.1-0.4-0.3-0.5c-3.3-3-4.8-5.9-4.4-9.1
	C723.6,302.7,723.4,302.5,723.4,302.3L723.4,302.3z M714.1,309.3l-8.3-6.2l-1-0.8h0.1l16,1.5c-0.1,3,1.1,5.9,3.9,8.8
	c-2.1,0.3-4.8-0.3-7.8-1.6L714.1,309.3L714.1,309.3z"
          />
          <path
            fill="#EBEBEB"
            d="M717.1,311.1c3,1.2,5.6,1.8,7.8,1.7c-2.6-2.9-4-5.8-3.8-8.8l-16-1.5H705l1,0.8l8.3,6.2L717.1,311.1
	L717.1,311.1z"
          />
          <path
            fill="#EBEBEB"
            d="M727.6,313.7c-2.8,1.1-6.5,0.7-11-1.2l-2.5-1.2c3.2,2.3,6.2,3.6,9.1,3.9l9.6,1.2l-4-2.8l-0.7-0.4v0.1
	C728,313.5,727.8,313.6,727.6,313.7L727.6,313.7z M755.4,355.4l-1.4,15.3l-1.2,0.3h4.5c2.5-0.1,3.7-1.9,3.6-5.2
	c0.1-3.7-0.5-7.3-2.1-10.5l-3.3-0.1L755.4,355.4L755.4,355.4z M446,218.1c-1.8,1.2-1.6,2.3,0.3,3.4c12.8,5.9,25.3,12.2,37.4,19
	c1.8,1.1,3.6,2.1,5.4,3c15.4,8.8,28.9,17.5,40.3,26.1l31.2-1.1c-3.3-1.8-6.5-3.6-9.6-5.4c-13.3-7.4-25.4-13.9-36.6-19.7
	c-29.3-15.3-50.5-24.9-63.6-28.9L446,218.1L446,218.1z M620.4,393.2c7.8,7.8,17.2,11.7,28.2,11.7s20.4-3.9,28.2-11.7
	c7.8-7.8,11.7-17.2,11.7-28.2s-3.8-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7s-20.4,3.9-28.2,11.7c-7.8,7.8-11.7,17.2-11.7,28.2
	S612.7,385.4,620.4,393.2L620.4,393.2z M610.9,365c0-10.5,3.7-19.3,11-26.7c7.4-7.4,16.2-11,26.7-11s19.3,3.7,26.7,11
	c7.4,7.4,11,16.2,11,26.7s-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11s-19.3-3.7-26.7-11C614.6,384.3,610.9,375.4,610.9,365
	L610.9,365z"
          />
          <path
            fill="#EBEBEB"
            d="M621.9,391.6c7.4,7.4,16.2,11,26.7,11s19.3-3.7,26.7-11c7.4-7.4,11-16.2,11-26.7c0-10.5-3.7-19.3-11-26.7
	c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11c-7.3,7.4-11,16.2-11,26.7C610.9,375.4,614.6,384.3,621.9,391.6L621.9,391.6z
	 M623.3,339.6c3.2-3.2,6.7-5.8,10.6-7.4l1.7-0.7c4-1.5,8.4-2.3,13.1-2.3c4.5,0,8.8,0.7,12.8,2.2l1.7,0.7c4,1.8,7.6,4.3,10.9,7.6
	l1.2,1.2l1.2,1.4c5.4,6.5,8.1,14,8.1,22.8v1.8c0,0.5,0,1.2-0.1,1.8c-0.4,5.2-1.9,10-4.5,14.3l-1,1.5c-1.4,2.1-3,4-4.8,5.9
	c-4.7,4.7-10,7.8-16.1,9.4l-1.8,0.4c-2.3,0.5-5,0.7-7.6,0.7s-5.1-0.3-7.4-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4
	c-1.8-1.8-3.4-3.7-4.8-5.8l-1-1.5c-2.6-4.3-4.1-9.1-4.5-14.2l-0.1-1.8v-1.9c0-8.7,2.6-16.1,8-22.6l1.2-1.4L623.3,339.6L623.3,339.6z
	"
          />
          <path
            fill="#EBEBEB"
            d="M631,347l-9.1-6.1l-1.2,1.4l8.8,6.3l9.4,6.9l0.7,0.5c2.9,2.2,4.1,4.7,3.6,7.6c-1.4,2.2-3.7,3.3-7,3.3h-23.4
	l0.1,1.8l10.9,0.5l2.1,0.1c1.4,0.1,2.2,0.5,2.8,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.7,1.4l-8.5,6.9l1,1.5
	l8.8-6.3l9.4-6.9l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1c1.7,1.9,2.1,4.5,1.1,7.7l-0.3,1l-3.6,11.1l-3.3,10.3l1.8,0.4l3.8-10.3l0.8-2.1
	c0.5-1.2,1.2-1.9,2.1-2.2h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1l3.8,10.3l1.8-0.4l-3.4-10.3l-3.6-11.1l-0.4-1c-1.1-3.4-0.5-6.2,1.5-8.3
	c2.3-1,5-0.5,7.7,1.4l1,0.7l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7
	c0.5-0.8,1.4-1.2,2.8-1.4l2.2-0.1l10.9-0.5c0-0.6,0.1-1.2,0.1-1.8h-23.4c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5
	l9.5-6.9l8.8-6.3l-1.2-1.4l-9.2,6.1l-1.8,1.2c-1.1,0.7-2.1,0.8-3,0.5c-0.3-0.1-0.4-0.3-0.7-0.4l-0.5-0.6c-0.5-0.7-0.8-1.6-0.4-3
	l0.5-2.1l2.9-10.6l-1.7-0.7l-3.3,10.3l-3.6,11.1l-0.3,1c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1l-3.6-11.1
	l-3.3-10.3l-1.7,0.7l2.9,10.6l0.5,2.1c0.3,1.2,0.1,2.2-0.4,3c-0.1,0.1-0.4,0.4-0.6,0.5l-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5L631,347
	L631,347z M648.6,360.1c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4s-2.5-0.5-3.4-1.4
	c-1-1-1.4-2.1-1.4-3.4c0-1.4,0.5-2.5,1.4-3.4C646.1,360.6,647.4,360.1,648.6,360.1L648.6,360.1z"
          />
          <path
            fill="#EBEBEB"
            d="M645.2,361.6c-1,1-1.4,2.1-1.4,3.4s0.5,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4
	c1-0.8,1.4-2.1,1.4-3.4s-0.5-2.5-1.4-3.4c-0.8-1-2.1-1.4-3.4-1.4C647.4,360.1,646.1,360.6,645.2,361.6L645.2,361.6z M632.8,348.1
	c1.1,0.7,2.1,0.8,3,0.5l0.7-0.4c0.3-0.1,0.4-0.3,0.5-0.5c0.5-0.7,0.8-1.6,0.4-3l-0.5-2.1c-2.1,1.1-4,2.6-5.8,4.3L632.8,348.1
	L632.8,348.1z M623.6,367h11.7l-0.1-1.9c0-3.7,1.2-6.7,3.7-9.4l-9.4-6.9c-4,4.5-5.9,10-5.9,16.2L623.6,367L623.6,367z M628.7,370.7
	c-0.4-0.8-1.4-1.2-2.6-1.4l-2.1-0.1c0.4,2.5,1.1,4.8,2.2,6.9l1.7-1.4c1-0.8,1.5-1.8,1.4-2.6c0-0.3-0.1-0.5-0.1-0.7L628.7,370.7
	L628.7,370.7z M630.9,382.8c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2l-9.4,6.9
	C628.3,379.8,629.5,381.3,630.9,382.8L630.9,382.8z M639,341.7l3.6,11.1c1.9-1,3.9-1.4,6.2-1.4c2.1,0,4.1,0.4,5.8,1.2l3.6-11.1
	c-2.9-1.1-6.1-1.8-9.4-1.8C645.2,339.9,641.9,340.4,639,341.7L639,341.7z M659.6,344.7c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5
	c0.3,0.1,0.4,0.3,0.7,0.4c0.8,0.4,1.9,0.3,3-0.5l1.8-1.2c-1.8-1.8-3.7-3.2-5.9-4.3L659.6,344.7L659.6,344.7z M657.4,356
	c-2.6,2.1-3.9,4.3-3.7,6.9c1.2,2.6,3.7,3.9,7.3,4h1.1l0.1-1.8c0-3.7-1.4-6.9-4-9.6L657.4,356L657.4,356z M657.6,360.9l0.8,1.8
	l-1.2,1.6l-1.9-0.3l-0.8-1.8l1.2-1.6L657.6,360.9L657.6,360.9z"
          />
          <path
            fill="#EBEBEB"
            d="M654.4,362.3l0.8,1.8l2.1,0.3l1.1-1.7l-0.8-1.8l-1.9-0.3 M658.2,355.4c2.6,2.6,4,5.8,4,9.6l-0.1,1.8h11.6
	V365c0-6.3-2.1-11.8-6.2-16.5L658.2,355.4L658.2,355.4z M654.5,352.7c-1.8-0.8-3.7-1.2-5.8-1.2c-2.2,0-4.3,0.5-6.2,1.4l0.3,1
	c1.1,3.2,2.9,5.1,5.4,5.6c2.8-0.4,4.8-2.3,5.9-5.8L654.5,352.7L654.5,352.7z M647.9,358.6l-1.1-1.7l0.8-1.8l1.9-0.1l1.1,1.7
	l-0.8,1.8L647.9,358.6L647.9,358.6z"
          />
          <path
            fill="#EBEBEB"
            d="M650.7,356.5l-1.2-1.6l-1.9,0.3l-0.8,1.8l1.2,1.7l1.9-0.3 M638.8,355.6c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9h1
	c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6L638.8,355.6L638.8,355.6z M641.2,360.4l1.5,1.4l-0.4,1.9l-1.9,0.5l-1.5-1.4l0.4-1.9
	L641.2,360.4L641.2,360.4z"
          />
          <path
            fill="#EBEBEB"
            d="M638.8,362.8l1.5,1.4l1.9-0.5l0.4-1.9l-1.5-1.4l-1.9,0.5 M671.3,369.2c-1.2,0.1-2.2,0.5-2.8,1.4l-0.3,0.7
	l-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6l1.8,1.5c1.1-2.2,1.8-4.5,2.2-7L671.3,369.2L671.3,369.2z M636.6,371.4c0.7,1.1,1.5,2.2,2.5,3.2
	c2.1,2.1,4.5,3.3,7.3,3.9l0.3-1c1-3.2,0.5-5.8-1.1-7.7c-2.5-1.4-5.4-1-8.3,1.1L636.6,371.4L636.6,371.4z M641.9,370.5l1.7-1l1.8,1
	v2.1l-1.8,1l-1.7-1L641.9,370.5L641.9,370.5z"
          />
          <path
            fill="#EBEBEB"
            d="M643.7,373.6l1.8-1.1v-1.9l-1.8-1l-1.8,1v1.9 M651,378.4c2.8-0.4,5.2-1.6,7.3-3.9c1.1-1.1,1.9-2.2,2.5-3.3
	l-1-0.7c-2.8-1.9-5.4-2.3-7.7-1.4c-2.1,2.1-2.5,4.7-1.5,8.3L651,378.4L651,378.4z M654.8,370.3l0.4,1.9l-1.5,1.4l-1.9-0.5l-0.4-1.9
	l1.5-1.4L654.8,370.3L654.8,370.3z"
          />
          <path
            fill="#EBEBEB"
            d="M651.5,371.1l0.4,1.9l1.8,0.5l1.5-1.2l-0.4-2.1l-1.9-0.5 M658.2,374.5c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7l-9.4-6.9C660.2,372.5,659.3,373.6,658.2,374.5L658.2,374.5z M651.5,387.9
	c-0.4-1.2-1.1-1.9-2.1-2.2h-1.5c-1,0.3-1.7,1-2.1,2.2l-0.8,2.1l3.6,0.3l3.7-0.3L651.5,387.9L651.5,387.9z M384.9,221.4
	c-23.9,0.6-47.3,3-70.3,7.3l14.9,36.6c17.5,0.8,35.1,1.4,52.8,1.5l83.5,0.7c0-0.1-0.1-0.4-0.1-0.5c-0.1-0.7-0.3-1.6-0.3-2.6l0.8-5
	c0.4-2.6,2.1-4.4,5-5.4c1.2-0.4,2.8-0.7,4.5-0.8c4.1-0.6,8,0,11.8,1.5c0.3,0.1,0.5,0.4,1,0.6v-2.2c-5.8-3.4-11.6-6.6-17.3-9.5
	c-7.2-3.7-14.4-7-21.6-10C430.6,225.6,409,221.6,384.9,221.4L384.9,221.4z"
          />
          <path
            fill="none"
            d="M755.4,355v0.4 M714.1,309.3l3.2,1.6c3,1.2,5.6,1.8,7.8,1.6c-2.6-2.9-4-5.8-3.9-8.8l-16-1.5h-0.1l1,0.8
	L714.1,309.3L714.1,309.3z M729.4,300.8l-0.5,12.8"
          />
          <path
            fill="none"
            d="M714,311.1c3.2,2.3,6.2,3.6,9.1,3.9l9.6,1.2l-4-2.8 M728.8,313.6l-0.7-0.4"
          />
          <path
            fill="none"
            d="M728.1,313.2c-0.1,0.3-0.4,0.4-0.5,0.5c-2.8,1.1-6.5,0.7-11-1.2l-2.5-1.2 M738.2,316.8
	c-2.1-0.1-2.8-0.5-2.2-1.4l4.3-8.8c1-1.6,2.2-2.2,4.1-1.6L738.2,316.8L738.2,316.8z"
          />
          <path
            fill="none"
            d="M728.1,313.2c0.1-0.1,0.1-0.4,0-0.5c0-0.3-0.1-0.4-0.3-0.6c-3.3-3-4.8-5.9-4.4-9.1v-0.5L723,302
	c-0.1-0.1-0.4-0.1-0.5-0.1l-16.8-1.5l-1.8,0.1c-0.8,0.3-1.2,0.7-1.1,1.5c-0.1,0.7,0.5,1.6,2.1,3l8.1,5.9l0.8,0.5 M744.5,317
	c-2.1-0.1-2.8-0.5-2.2-1.4l4.3-8.8c1-1.6,2.2-2.2,4.1-1.6L744.5,317L744.5,317z M757.6,305.9c-1.8-0.5-3.2,0-4.1,1.6l-4.3,8.8
	c-0.5,0.7,0.3,1.1,2.2,1.4L757.6,305.9L757.6,305.9z M275.7,216.9c-13.8,2.9-26.8,6.3-39.1,10c-18.7,5.1-37.3,10.6-56,16.5
	c-12.5,4-25,8.3-37.7,12.7 M516,267.8l-0.3-0.3c-12.9-9.1-25.9-17.1-38.9-24.1c-8.8-4.7-17.6-8.8-26.4-12.5
	c-19.4-8-41.1-12-65.5-12.2c-27.2,0.7-53.8,3.7-79.8,9.1c-21,4-41.1,8.9-60.1,15.3l-5.4,1.9c-2.3,1.1-2.6,2.6-0.8,4.7
	c2.9,3.4,5.6,6.2,8.4,8.3c3.3,2.3,8.5,3.6,15.3,4c22,2.3,44.2,4,66.4,5.1"
          />
          <path
            fill="none"
            d="M296.9,232.2c-15.4,3.2-30.1,6.9-44.4,11.4l-5.6,1.8c-2.3,1.1-2.6,2.5-0.7,4.5c2.3,2.5,4.7,4.5,6.7,6.1
	c2.9,2.2,6.2,3.7,9.9,4.3l0.3,0.1c15.3,1.5,30.7,2.9,46.1,3.9 M169.2,324.2l3-3.2l3-3c13.5-12.4,29.9-18.4,49-18.4
	c19.1,0,35.5,6.2,49,18.4c1.1,1,2.1,1.9,3,3c3.4,3.6,6.3,7.3,8.8,11.1c3.6,5.8,6.1,12.1,7.3,18.8c1.8,9.9,2.9,22.1,3,36.6
	 M282.3,223.5l-0.4,0.1c-24.9,5.5-50.6,12.1-77.2,19.9c-12.1,3.4-24.5,7.3-36.9,11.3c-8.3,2.1-16.5,2.5-24.9,1.4 M314.6,228.7
	l-8.9,1.7h-0.1l-8.7,1.8 M283.7,223.2l-1.4,0.3 M283.7,223.2l-1.4,0.3 M488.6,253.1c-5.8-3.4-11.6-6.6-17.3-9.5
	c-7.2-3.7-14.4-7-21.6-10c-19.1-7.8-40.6-11.8-64.7-12c-23.9,0.6-47.3,3-70.3,7.3 M348.9,296l3.4,0.1l7.8-0.4 M360.1,295.7
	c2.2-0.3,4.3-0.5,6.2-1.1c1.5-0.4,2.8-0.8,3.7-1.2 M370,288.8c-1-0.5-2.2-1-3.7-1.4c-1.9-0.5-4-0.8-6.2-1.1 M360,286.4l-7.7-0.4
	l-3.6,0.1 M360,286.4c1,0.8,1.6,1.5,2.2,2.3 M362.3,293.4c-0.4,0.8-1.1,1.5-2.1,2.3"
          />
          <path
            fill="none"
            d="M370,293.4c0.7-0.3,1.1-0.7,1.5-1c0.4-0.4,0.5-0.8,0.5-1.2s-0.1-0.8-0.5-1.2c-0.3-0.4-0.8-0.7-1.5-1 M370,293.4
	h-7.7 M362.2,288.7h7.3l0.5,0.1 M329.1,267l-2.1,2.9 M329.5,265.2l-0.4,1.8 M346.2,293.4h-11.7 M334.6,288.8c-0.8,0.4-1.5,1-1.8,1.4
	c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.5,1,1,1.8,1.4 M334.6,293.4c1,0.4,2.2,0.8,3.7,1.2c3,0.8,6.5,1.2,10.6,1.4
	 M345.8,288.7c0.5-1,1.5-1.8,2.9-2.6 M334.6,288.8l11.3-0.1 M348.7,286.1c-4,0.1-7.4,0.5-10.5,1.4c-1.5,0.4-2.8,0.8-3.7,1.2
	 M327,269.9c-5.5,8.7-7.7,17.3-6.5,26c1.8,8.7,4.1,17.1,6.9,25.2c3,8.9,6.5,17.5,10.5,25.9 M309,264.1l20.5,1.1 M450.8,214.6
	c-13.1-5-29.2-8.1-48-9.6c-27.6-1.1-54.3,0.1-80.2,3.9c-16.5,2.2-32.2,4.8-47,8 M329.1,267c17.6,0.8,35.5,1.4,53.4,1.5l84.9,0.7
	 M296.9,232.2l12.1,31.9 M329.5,265.2c17.5,0.8,35.1,1.4,52.8,1.5l83.5,0.7 M314.6,228.7l14.9,36.5 M362.2,288.7h-16.4 M348.9,296
	c-1.1-1-2.1-1.8-2.6-2.6 M346.2,293.4h16.1"
          />
          <path
            fill="none"
            d="M446.3,221.6c-19.7-6.9-43.5-10.3-71.5-10.2c-30.7,1.1-61.1,5-91.1,11.8 M287.2,387.6c0-11.6-0.7-21.9-2.2-30.8
	v-0.4c-2.1-12-7.8-22.6-17.1-31.6c-1.4-1.4-2.9-2.8-4.3-3.9c-11.1-9.2-24.2-13.8-39.1-13.8c-15,0-28.1,4.5-39.1,13.8
	c-1.5,1.2-2.9,2.5-4.3,3.9 M343.5,357.5l3.4,5.9c5.4,9.2,14.9,13.9,28.1,14l147.7,2.1 M337.9,346.8c1.8,3.6,3.6,7.2,5.6,10.7"
          />
          <path
            fill="none"
            d="M337.9,346.8c-9.8,1.5-18.7,3.7-26.5,7c-4.1,2.1-0.7,3,10.6,3.2l21.6,0.5 M280.5,394.9l0.3,5.2h39.6v-5.2
	 M295.4,387.6h-8.1 M287.4,394.9h-6.9 M287.2,387.6v2.3 M287.2,389.9l0.1,5 M295.4,387.6h1.2c15.1-3.2,30.1-4.7,45.3-4.4l202.2,3.6
	l10.3,0.6l8.4,0.8l15.3,2.2 M287.4,394.9h33 M534.7,353.1c-5.4-0.7-11.3-1.1-17.6-1.6l-157-6.6c-7.8,0.1-15.3,0.8-22.3,1.8
	 M181,324.9c-7.4,7.3-12.5,15.4-15.4,24.6c-0.8,2.8-1.5,5.6-1.9,8.5c-1,7.3-1.5,16-1.5,25.9 M154.1,383.2c-0.1-12,0.4-22.1,1.8-30.7
	c0.6-3,1.2-6.1,2.2-8.9c2.3-7,6.1-13.5,11.3-19.4 M139.1,355c1.1-1.2,0.4-1.9-1.9-2.1l-27.5-1.6c-1.9,0.1-1.9,1,0,2.5
	c1.5,1.4,2.9,2.1,4.1,2.1l20.6,1.4C136.6,357.1,138.1,356.4,139.1,355L139.1,355z M208.7,332.2c-3.9,1.8-7.4,4.3-10.6,7.4l-1.4,1.4
	 M204.3,348.8l-8.8-6.3 M196.7,341l-1.2,1.5 M196.7,341l9.1,6.1"
          />
          <path
            fill="none"
            d="M269.1,394.9c2.9-4.1,5-8.7,6.5-13.5c1.6-5.1,2.3-10.6,2.3-16.4c0-15-5.2-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6
	c-9.2-6.9-19.9-10.3-31.9-10.3s-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2
	c0,10.5,2.6,19.9,7.8,28.3"
          />
          <path
            fill="none"
            d="M195.5,342.5c-5.4,6.5-8,13.9-8,22.6v1.9 M187.6,367l0.1,1.8 M204.3,348.8c-4,4.5-5.9,10-5.9,16.2v1.9
	 M198.6,369.3l2.1,0.1c1.4,0.1,2.2,0.5,2.6,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.6,1.4 M202.1,378.3
	c1,1.6,2.2,3.2,3.7,4.5c3.4,3.4,7.4,5.6,11.8,6.7 M192.3,383.1l1,1.5 M200.8,376.2c-1.1-2.2-1.8-4.4-2.2-6.9 M193.3,384.6l8.8-6.3
	 M200.8,376.2l-8.5,6.9 M176.9,393.2c2.2,3.6,5,6.9,8.1,10.2c10.6,10.6,23.4,16,38.4,16c15,0,27.8-5.2,38.4-16
	c2.8-2.8,5.1-5.5,7.2-8.5 M187.8,368.8c0.6,5.2,2.1,9.9,4.5,14.2"
          />
          <path
            fill="none"
            d="M193.3,384.6c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4 M187.6,367h10.7 M187.8,368.8l10.9,0.5"
          />
          <path
            fill="none"
            d="M185.9,365c0,10.5,3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11c10.5,0,19.3-3.7,26.7-11c7.3-7.4,11-16.2,11-26.7
	s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11C189.4,345.6,185.8,354.6,185.9,365L185.9,365z"
          />
          <path
            fill="none"
            d="M183.6,365c0,11,3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7c11,0,20.4-3.9,28.2-11.7
	c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7c-11,0-20.4,3.9-28.2,11.7
	C187.5,344.5,183.6,354,183.6,365L183.6,365z M162.2,383.9l-8.1-0.7 M162.2,388.7v-4.8 M154.1,383.2l-5.8-0.4
	c-11.1-3.2-26.1-5.9-44.8-8.1c-4.1-1.1-8.5-3.3-13.1-6.6c-4-2.8-8.1-6.7-12.4-11.7c-1.7-2.1-4.7-3.4-8.9-4H52.5 M162.2,388.7
	l14.7,4.5 M181,324.9l-11.8-0.7"
          />
          <path
            fill="none"
            d="M236.2,331.3c-4-1.5-8.3-2.2-12.8-2.2c-4.7,0-9.1,0.8-13.1,2.3 M210.5,331.5l-1.8,0.7 M232.9,341.7
	c-2.9-1.1-6.1-1.8-9.4-1.8c-3.4,0-6.7,0.5-9.8,1.9 M213.8,341.7l-3.3-10.2 M238,332l-1.8-0.7 M236.2,331.3l-3.3,10.3 M240.9,346.9
	l-1.8,1.2c-1.1,0.7-2.1,0.8-3,0.5l-0.7-0.4l-0.6-0.5c-0.6-0.7-0.8-1.6-0.4-3l0.6-2.1 M235,342.6c2.1,1.1,4.1,2.5,5.9,4.3
	 M225.5,356.5l-1.1-1.6l-2.1,0.3l-0.8,1.8l1.2,1.7l1.9-0.3 M230.4,360.6l-1.1,1.6l0.8,1.8l1.9,0.3l1.2-1.7l-0.8-1.8"
          />
          <path
            fill="none"
            d="M237,366.7h-1.1c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5 M229.3,352.7l-0.3,1
	c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1 M205.8,347l1.6,1.1c1.1,0.7,2.1,0.8,3,0.5c0.3-0.1,0.4-0.3,0.7-0.4
	l0.6-0.5c0.6-0.7,0.8-1.6,0.4-3l-0.6-2.1 M223.5,360.1c-1.4,0-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4c0,1.4,0.4,2.5,1.4,3.4
	s2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4c0-1.4-0.6-2.5-1.4-3.4C226,360.6,224.8,360.1,223.5,360.1L223.5,360.1z
	 M217.5,361.7l-1.5-1.4l-1.8,0.5l-0.4,1.9l1.4,1.4l1.9-0.5"
          />
          <path
            fill="none"
            d="M210.1,367h1c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6l-0.7-0.5 M217.4,352.8c1.9-1,3.9-1.4,6.2-1.4
	c2.1,0,4.1,0.4,5.8,1.2 M211.7,342.8l-3-10.6 M217.4,352.8l-3.6-11.1 M235,342.6l3-10.6 M232.9,341.7l-3.6,11 M250.1,340.8l-1.2-1.2
	c-3.3-3.3-7-5.8-10.9-7.6 M242.5,348.5l8.7-6.3 M251.2,342.2l-1.1-1.4 M246.1,376.1l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7
	l0.3-0.7c0.6-0.8,1.4-1.2,2.8-1.4l2.2-0.1 M242.5,348.5c4.1,4.7,6.2,10,6.2,16.5v1.8 M259.3,368.6c0.1-0.5,0.1-1.2,0.1-1.8
	 M259.3,366.7v-1.8c0-8.7-2.8-16.4-8.1-22.8 M253.7,384.4l1.1-1.5 M248.3,369c-0.4,2.5-1.1,4.8-2.2,7 M254.8,382.9
	c2.6-4.4,4.1-9.1,4.5-14.3 M259.3,366.7h-10.7 M254.8,382.9l-8.7-6.9 M245,378.1l8.7,6.3 M248.3,369l11-0.4 M232.9,399.7
	c6.1-1.5,11.4-4.7,16.1-9.4c1.9-1.9,3.4-3.9,4.8-5.9 M211.4,371.4l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1c1.6,1.9,2.1,4.5,1.1,7.7l-0.3,1
	 M213.6,355.6c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9"
          />
          <path
            fill="none"
            d="M221.2,378.4c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2 M220.2,370.5l-1.8-1l-1.6,1v1.9l1.6,1.1l1.8-1.1
	 M228.6,373.6l1.4-1.2l-0.4-2.1l-1.8-0.5l-1.5,1.4l0.4,1.9 M233,355.4c2.6,2.6,4,5.8,4,9.6l-0.1,1.8"
          />
          <path
            fill="none"
            d="M225.9,378.4l-0.3-1c-1.1-3.4-0.6-6.2,1.5-8.3c2.3-1,5-0.5,7.7,1.4l1,0.7"
          />
          <path
            fill="none"
            d="M235.6,371.2c-0.7,1.2-1.5,2.3-2.5,3.3c-2.1,2.1-4.5,3.3-7.3,3.9 M231,400.1l1.9-0.4 M232.9,399.7l-3.4-10.2
	 M227.1,389.9l3.9,10.2 M219.8,389.9l0.8-2.1c0.4-1.2,1.2-1.9,2.1-2.2h1.5c1,0.3,1.6,1,2.1,2.2l0.8,2.1 M214.2,399.9l1.8,0.3
	 M216,400.1c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7 M217.6,389.5l-3.4,10.3 M216,400.1l3.9-10.2 M219.8,389.9l3.7,0.1l3.6-0.1
	 M229.5,389.5c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7 M221.2,378.4l-3.6,11.1 M229.5,389.5l-3.6-11.1 M233,355.4l9.5-6.9
	 M248.6,366.7H237 M235.6,371.2l9.4,6.9 M250.1,340.8l-9.2,6.1 M198.4,367h11.7 M213.6,355.6l-9.4-6.7 M211.7,342.8
	c-2.1,1.1-4,2.6-5.8,4.3 M202.1,378.3l9.4-6.9 M269.1,394.9h11.4 M275.7,216.9l8,6.3 M294.7,274.1c-51.3-6.3-104-7.4-157.8-3.6
	c-15,1.4-28.9,0.5-41.8-2.6l-12.4-3 M82.5,264.9c-2.9-0.5-5.1-0.5-6.5,0l-0.8,0.5c-1,0.7-1.8,1.6-2.3,2.8c-1.7,3.2-1.5,5,0.3,5.5
	 M73.2,273.7c-0.4,0.7-0.1,1.5,0.8,2.5"
          />
          <path
            fill="none"
            d="M142.9,256.2c-3.4-1.5-8.9-0.6-16.5,2.9c-5.5,2.3-12.4,4.3-20.8,5.4c-5.9,1.2-13.6,1.5-23.1,0.5 M73.9,276.2
	l1.1,1.1l3,5.1 M78,282.4l0.3,0.4l-2.2,3.4 M76.1,286.2c-1.4-1-2.5-0.8-3.4,0.5L63.6,300c-1.1,0.8-0.7,1.8,1.4,3 M88.7,282l-0.3,0.8
	l-15,19.4c-0.8,1.1-1,1.9-0.6,2.3 M88.7,282l-4.4-1.9l-1.1,2.5l-0.7,0.8 M94.4,283.6l-5.2-2.5l-0.4,0.8 M95.5,282.1l-1.1,1.5
	 M97.1,283.9l-1.1-2.2c-0.8-1.5-2.2-2.6-4.1-3.2L73,273.8 M97.1,283.9c-0.6-0.7-1.1-1.2-1.7-1.8"
          />
          <path
            fill="none"
            d="M95.5,282.1c-1.2-1-2.8-1.6-4.5-2.2l-17.1-3.7 M95.7,296.5c3.2-2.9,4.3-5.9,3.2-9.1l-1-1.8 M98.1,285.7l-1-1.8
	 M95.7,296.5l-10.9,10h-0.1c-1,0.8-3,1.2-6.1,1.2l-17.6,0.4"
          />
          <path
            fill="none"
            d="M73,304.5c-1.1,1.1-2.8,1.4-4.8,1.2c-1.5-0.5-1.7-1.4-0.8-2.6l15.1-19.7 M73,304.5l0.6,0.1
	c1.5,0.4,3.6,0,6.2-1.2 M82.5,283.3l-4.5-1"
          />
          <path
            fill="none"
            d="M79.7,303.4c0.8,1.5,2.8,1.5,5.9-0.3l12.5-17.5 M76.1,286.2L64.9,303 M94.4,283.6l-14.7,19.8 M169.2,324.2
	l-35.9-2.1c-3.4,0.4-6.6,0-9.5-1.1c-1.8-0.8-3.4-1.8-5.1-3.3l-23-21.2 M64.9,303l-5,3c-1.9,1-1.5,1.8,1.2,2.5"
          />
          <path
            fill="none"
            d="M61.1,308.4l-3.7,0.1c-1.1,1.2-1.9,2.3-2.8,3.3c-1,1.4-1.7,2.5-1.9,3.6c-0.4,1.2,0.1,2.2,1.9,2.8l1.4,0.4
	l3.2,10l-3.3,11.7c-0.5,2.5-2.1,4.7-4.3,6.3l-0.5,4.1c0,1.1,0.4,1.8,1.4,1.9 M82.3,378.7l-18.8-18c-1.1-1.1-2.6-1.9-4.5-2.3"
          />
          <path
            fill="none"
            d="M58.9,358.3l-1.1,2.2c-0.7,1.8,0,3.2,2.2,4.1l6.7,4l12.9,12 M50.5,354.3l1.1,1.1l1.8,0.1 M53.4,355.6l-0.1,2.2
	 M53.2,357.8c2.2,0,4.1,0.1,5.6,0.5 M52.5,352.4c-1.9,0.4-2.6,1.1-2.1,1.9"
          />
          <path
            fill="none"
            d="M53.2,357.8c3.7,4.1,6.1,9.4,7.4,15.8c6.6,2.9,12.9,5.2,19,7 M81.8,367.7l-0.3-0.3l-2.2-2.3
	c-1-1-1.7-1.4-2.2-1.5h-0.6c-0.4,0.3-0.1,1.2,0.7,3l0.6,1.2 M76.3,360.5l-1.4-1.6c-0.7-0.7-1.4-1.4-2.2-1.9 M72.8,356.8l-7-0.4
	 M72.8,356.8c-2.1-1.4-4.8-2.2-8.1-2.5l-14.2-0.1"
          />
          <path
            fill="none"
            d="M65.8,356.4c-1.2,0.3-1.2,1.1,0,2.2l1.1,1.4c0.6,0.4,1.2,0.7,2.2,0.5h7.4 M76.5,367.7h-0.4
	c-0.4,0.3-0.1,1.1,0.6,2.8c0.7,1.5,1.8,3.3,3.3,5.2l2.5,3"
          />
          <path
            fill="none"
            d="M86.1,381.7h0.1c0.4-0.3,0.1-1.1-0.6-2.8c-0.4-1-1.1-1.9-1.8-3l-1.5-2.1c-0.7-1-1.4-1.6-1.9-2.5l-2.1-2.2
	c-1-0.8-1.5-1.2-2.1-1.4 M78,367.7h-1.5 M82,367.7h-0.1 M91.5,378.7c1.7,1.5,2.2,2.8,1.7,4 M96.4,380.7c-1.9-0.5-3.7-1.4-5-2.2
	 M93,382.5l3.4-1.8 M82.3,378.7l1.5,1.5c1,0.8,1.7,1.4,2.1,1.4H86"
          />
          <path
            fill="none"
            d="M79.7,380.7c3,1,5.9,1.6,8.8,2.3l0.4-1.1 M88.9,382l-2.8-0.3 M88.9,382l4.1,0.5 M91.5,378.7
	c-0.7-0.4-1.4-1-1.9-1.5l-7.4-9.4 M81.8,367.7H78 M96.4,380.7c1.7,0.4,3.4,0.8,5.4,1.1l60.4,6.7 M76.3,360.5l5.6,7.2 M53.4,355.4
	l12.4,1 M778.2,314.4l1.5-4.3c1.1-3.4,3.4-5.8,7-6.9c2.1-1.2,2.3-2.6,0.4-4.1c-3.6-2.2-7.3-4.1-11.1-6.1 M776,293.1
	c-1.5,0.8-3,1.9-4.5,3.3 M764.6,305.1l-6.3,12.2c-1.2,2.2-4,3.2-8.4,2.9l-27.1-3.6c-4.3-0.8-8.3-2.3-12.1-4.8l-14.4-11.3 M741.5,302
	c7.7,0.8,15.4,1.8,23.1,3 M771.5,296.3c-2.3,2.2-4.7,5.1-6.9,8.7 M776,293.1c-10.9-5.4-23-10-36.5-13.8
	c-22.3-6.2-51.9-10.7-88.9-13.9c-30.8-2.1-59.4-2.8-85.7-2.2"
          />
          <path
            fill="none"
            d="M764.6,305.1c-12-8.3-31.8-15.1-59.3-20.5l-18.4-2.5 M696.2,300.5c0.4-1.1,2.2-1.6,5.2-1.8
	c9.2,0.4,18.6,1.1,27.8,2.1l12.1,1.2 M678.7,313.6c3.6,2.1,7,4.5,10.3,7.4l2.8,2.6c9.2,9.1,14.9,19.7,17.1,31.6v0.4
	c1.4,7.8,2.1,16.6,2.2,26.5"
          />
          <path
            fill="none"
            d="M685.9,308.2c3.9,2.3,7.6,5.2,11.1,8.4c1.5,1.4,3,2.8,4.3,4.3c2.9,3.2,5.4,6.5,7.6,9.9
	c3.6,5.8,6.1,12.1,7.3,18.8c1.8,9.6,2.6,20.9,2.6,33.6 M777.1,331.8l-1.1-2.3c-0.5-0.8-0.8-1.8-1.1-2.6 M778.2,314.4l-2.6,7.4
	c-0.8,1.5-1,3.2-0.5,5 M764.2,347.9c2.6,0.3,3.9,1.6,3.9,3.9c0.4,2.2-0.1,3.4-1.9,3.7 M766.2,355.4h-1.4 M764.9,355.4l-6.1-0.3
	 M777.6,358.6c-0.5-0.5-1.2-0.8-2.1-1.1l-10.5-1.1 M765.1,356.4l-0.3-1 M758.8,355.1l-3.4-0.1l-10.9-0.4 M744.5,354.6l2.8-3.6
	c1.8-2.1,5.2-3.2,10-3.2c2.3,0,4.5-0.1,6.6,0 M752.6,371l1.2-0.3l1.4-15.3c-4.5-0.8-7.7,0.1-9.8,2.9 M745.6,358.2l-3.8,0.1
	 M741.7,358.3l2.8-3.7"
          />
          <path
            fill="none"
            d="M745.6,358.2c-0.5,0.7-1,1.5-1.4,2.5c-1.4,3.9-1.1,6.7,1,8.7c1.7,1.7,4.1,2.2,7.4,1.5 M752.6,371h4.5
	c2.5-0.1,3.7-1.9,3.6-5.2c0.1-3.7-0.5-7.3-2.1-10.5"
          />
          <path
            fill="none"
            d="M765.1,356.4c1.1,3.9,1.7,7.7,1.7,11.6c0,3.2-1.4,4.7-4.3,4.7l-31.5-0.5 M720.8,388.3v5.9h16.9
	c13.1-0.3,23.2-1.5,30.3-3.7v-5.6 M711.1,382.1v1.5 M718.8,383.2l-7.7,0.4 M718.8,383.2l9.6-1.2c2.8-0.4,5.6-0.7,8.4-0.8l36.5-0.8
	c2.6,0.1,4.3-0.4,5.2-1.6"
          />
          <path
            fill="none"
            d="M731.2,371.9c-3.2,3.9-3.2,5.8,0,5.9l47.5,0.8 M711.1,383.6v5.1l9.8-0.4"
          />
          <path
            fill="none"
            d="M720.8,388.3l13.5-0.7l33.8-2.8 M731.2,371.9l10.6-13.6 M777.6,358.6l9.6-1.8c2.3-0.4,3.6-1.2,3.7-2.6
	c1.1-5.9,1.7-11,1.7-15.4c0.3-1.4-1.1-2.5-4.1-3.2l-5.8-1 M782.9,334.6l-5.8-2.9 M782.9,334.6l-5-0.8 M777.9,333.8l-0.8-2.1"
          />
          <path
            fill="none"
            d="M789.9,378.8c0.4-1.6-1.2-3-4.8-4.3c-2.1-0.3-3.2-1.1-3.3-2.5l-2.2-9.4c-0.1-1.9-0.8-3.3-1.9-4.3 M777.9,333.8
	c1.9,5.5,2.9,12.2,2.9,20.2c0,1.1-1.2,1.7-4.1,1.7l-10.5-0.3 M768.2,384.9l12.2-1c5.6-0.4,10-1.1,13.1-2.1c1.7-1.1,1.2-2.1-1.4-2.6
	h-0.3l-1.9-0.3"
          />
          <path
            fill="none"
            d="M778.5,378.8c0.7-0.8,1-2.1,0.8-3.6c1.8,1.8,4.4,2.9,8,3.3l2.6,0.4 M774.9,326.8l1.4-1l1.9-11.4 M778.2,349.2
	c-4.3-0.8-8.9-1.4-14-1.5 M450.8,214.6l-4.8,3.6c-1.8,1.2-1.6,2.3,0.3,3.4 M525.2,274.8l-9.2-7 M504.3,269.4l10.9,0.1l0.7-0.1
	c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.3,0.1-0.4,0-0.7l-0.1-0.3 M499.4,265.7c0.1-1-0.3-1.9-1.1-2.9c-2.2-2.3-5.4-5-9.6-7.7"
          />
          <path
            fill="none"
            d="M505,273.3c1-0.3,1.8-0.5,2.6-1c-0.5-1.2-1.6-2.2-3.3-2.9 M504.3,269.4c-0.4-0.1-1-0.3-1.5-0.4
	c-1.5-0.3-3.2-0.5-4.8-0.8 M497.8,268.2c-1.5-0.3-3-0.4-4.5-0.5 M497.8,268.2l0.5-0.5 M493.3,267.6h5.1 M467.3,269.2
	c0.1,0.1,0.4,0.3,0.5,0.3c4,1.2,9.8,1.9,17.1,1.8l3,2.8h10.6c2.5,0,4.7-0.1,6.5-0.7 M498.4,267.6c0.5-0.7,1-1.2,1-1.9"
          />
          <path
            fill="none"
            d="M498.4,267.6l13.2,0.1c-7.7-5.4-15.3-10.2-23-14.7 M560.6,268.5c-3.3-1.8-6.5-3.6-9.6-5.4 M446.3,221.6
	c12.8,5.9,25.3,12.2,37.4,19c1.8,1.1,3.6,2.1,5.4,3c15.4,8.8,28.9,17.5,40.3,26.1l31.2-1.1 M550.9,263.1
	c-13.3-7.4-25.5-13.9-36.6-19.7c-29.3-15.3-50.5-24.9-63.6-28.9 M527.3,274.9l-2.1-0.1 M527.1,274.9c7.6,5.5,11.8,13.5,12.8,23.9
	c0.3,6.6,0,14-0.8,22.1c-0.8,8-2.1,16.9-3.9,26.4l-0.4,2.5c-0.1,1.1-0.1,2.2,0,3.2 M488.6,255.3c-0.3-0.1-0.5-0.4-1-0.6
	c-3.7-1.6-7.7-2.1-11.8-1.5c-1.8,0.1-3.3,0.4-4.5,0.8 M471.3,253.9c-2.9,1-4.5,2.8-5,5.4l-0.8,5 M465.5,264.2c0,1,0,1.9,0.3,2.6
	 M465.9,267.4c0.1,0.3,0.3,0.5,0.4,0.8 M465.8,266.8c0,0.1,0.1,0.4,0.1,0.5 M466.3,268.3c0.3,0.4,0.5,0.7,1,0.8 M467.6,264.2h-2.1
	 M488.6,253.1v2.2 M505,273.3c-4.3-1.8-10.9-3.2-20.1-4c-5.4-0.5-11.6-0.8-18.6-1.1 M471.3,253.9c-2.6,1-3.9,4.4-3.7,10.3
	 M493.3,267.6c-4.5-0.4-9.6-0.7-15.1-0.8h-12.4 M499.4,265.7c-8.7-1.2-19.3-1.8-31.9-1.5 M525.2,274.8L478,274l-151-4.1
	 M678.7,313.6l7.2-5.4 M696.2,300.5l-10.3,7.7 M586.2,390.2c-0.3-13.3,0.1-24.5,1.5-33.6c0.4-2.9,1.1-5.8,1.9-8.5
	c2.9-9.1,8-17.3,15.4-24.5l2.8-2.6c11.4-10,25-15.1,40.7-15.1c11.3,0,21.3,2.6,30.4,7.7"
          />
          <path
            fill="none"
            d="M685.9,308.2c-11-6.7-23.7-10-37.8-10c-19.1,0-35.5,6.2-49,18.4c-1.5,1.4-3,2.8-4.4,4.3l-0.8,1
	c-5.5,6.2-9.6,13.1-12.1,20.4c-1,2.9-1.7,5.9-2.2,8.9c-1.7,10.3-2.1,23.4-1.5,38.9 M568.1,268.2l-3.2-5.1 M686.9,282.1
	c-32.2-3.9-66.6-5.9-103-6.2l-56.5-1.1"
          />
          <path
            fill="none"
            d="M771.5,296.3c-35.8-14.4-77.9-22.8-126.3-25.3l-77-2.8 M633.9,332.2c-3.9,1.8-7.4,4.3-10.6,7.4l-1.4,1.4
	 M631,347l1.7,1.1c1.1,0.7,2.1,0.8,3,0.5l0.7-0.4c0.3-0.1,0.4-0.3,0.5-0.5c0.6-0.7,0.8-1.6,0.4-3l-0.5-2.1 M629.5,348.8l-8.8-6.3
	 M621.9,341l-1.2,1.5 M621.9,341l9.1,6.1"
          />
          <path
            fill="none"
            d="M699.8,383.9l0.8-2.5c1.7-5.1,2.3-10.6,2.3-16.4c0-15-5.4-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6
	c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6c-5.2,5.2-9.2,11-11.8,17.2
	c-2.8,6.5-4.1,13.6-4.1,21.2c0,10.3,2.5,19.7,7.6,27.9"
          />
          <path
            fill="none"
            d="M620.7,342.5c-5.4,6.5-8,13.9-8,22.6v1.9 M612.8,367l0.1,1.8 M629.5,348.8c-4,4.5-5.9,10-5.9,16.2v1.9
	 M623.8,369.3l2.1,0.1c1.4,0.1,2.2,0.5,2.8,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.7,1.4 M627.2,378.3
	c1,1.6,2.2,3.2,3.7,4.5c3.4,3.4,7.4,5.6,11.8,6.7 M617.5,383.1l1,1.5 M626.1,376.2c-1.1-2.2-1.8-4.4-2.2-6.9 M626.1,376.2l-8.5,6.9
	 M618.5,384.6l8.7-6.3"
          />
          <path
            fill="none"
            d="M601.8,392.8c2.3,3.7,5.1,7.3,8.4,10.6c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c5.8-5.8,10-12.4,12.7-19.5
	 M613,368.8c0.5,5.2,2.1,9.9,4.5,14.2"
          />
          <path
            fill="none"
            d="M618.5,384.6c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4 M612.8,367h10.7"
          />
          <path
            fill="none"
            d="M608.7,365c0,11,3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7s20.4-3.9,28.2-11.7
	c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7s-20.4,3.9-28.2,11.7
	C612.7,344.5,608.7,354,608.7,365L608.7,365z"
          />
          <path
            fill="none"
            d="M610.9,365c0,10.5,3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11s19.3-3.7,26.7-11c7.4-7.4,11-16.2,11-26.7
	s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11C614.6,345.6,610.9,354.6,610.9,365L610.9,365z M613,368.8l10.9,0.5
	 M523.4,394.9l3.6,5.2h62.2l2.2-5.8 M586.2,390.2v1.2 M591.4,394.3l-5.2,0.7 M586.2,395v-3.6"
          />
          <path fill="none" d="M586.2,395l0.1,0.1h-1.2 M585.1,395.2l1.1-0.1" />
          <path
            fill="none"
            d="M523.4,394.9l42.1,2.2l6.3-1.9h13.2 M578.2,390.2h8 M591.4,394.3l10.5-1.5 M661.4,331.3
	c-4-1.5-8.3-2.2-12.8-2.2c-4.7,0-9.1,0.8-13.1,2.3 M635.5,331.5l-1.7,0.7 M658.1,341.7c-2.9-1.1-6.1-1.8-9.4-1.8
	c-3.4,0-6.7,0.5-9.8,1.9 M639,341.7l-3.4-10.2 M663.2,332l-1.8-0.7 M661.4,331.3l-3.3,10.3 M666.1,346.9l-1.8,1.2
	c-1.1,0.7-2.1,0.8-3,0.5c-0.3-0.1-0.4-0.3-0.7-0.4l-0.5-0.5c-0.5-0.7-0.8-1.6-0.4-3l0.5-2.1 M657.6,360.9l-1.9-0.3l-1.2,1.6l0.8,1.8
	l2.1,0.3l1.1-1.7"
          />
          <path
            fill="none"
            d="M662.1,366.7H661c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5 M660.2,342.6c2.1,1.1,4.1,2.5,5.9,4.3
	 M658.2,355.4l9.5-6.9 M654.5,352.7l-0.3,1c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1"
          />
          <path
            fill="none"
            d="M647.9,358.6l1.9-0.3l0.8-1.8l-1.2-1.6l-1.9,0.3l-0.8,1.8 M648.6,360.1c-1.4,0-2.5,0.5-3.4,1.4
	c-1,1-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4c0-1.4-0.5-2.5-1.4-3.4
	C651.1,360.6,650,360.1,648.6,360.1L648.6,360.1z M635.3,367h1c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6l-0.7-0.5"
          />
          <path
            fill="none"
            d="M641.2,360.4l-1.9,0.5l-0.4,1.9l1.5,1.4l1.9-0.5l0.4-1.9 M642.6,352.8c1.9-1,3.9-1.4,6.2-1.4
	c2.1,0,4.1,0.4,5.8,1.2 M636.8,342.8l-2.9-10.6 M642.6,352.8l-3.6-11.1 M658.1,341.7l-3.6,11 M660.2,342.6l3-10.6 M675.3,340.8
	l-1.2-1.2c-3.3-3.3-6.9-5.8-10.9-7.6 M676.4,342.2l-1.1-1.4 M667.7,348.5c4.1,4.7,6.2,10,6.2,16.5v1.8 M671.3,376.1l-1.8-1.5
	c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7c0.5-0.8,1.4-1.2,2.6-1.4l2.2-0.1 M684.4,368.6c0-0.5,0.1-1.2,0.1-1.8 M684.5,366.7v-1.8
	c0-8.7-2.8-16.4-8.1-22.8 M678.9,384.4l1-1.5 M673.5,369l10.9-0.4 M673.5,369c-0.4,2.5-1.1,4.8-2.2,7 M679.8,382.9
	c2.6-4.4,4.1-9.1,4.5-14.3 M679.8,382.9l-8.5-6.9 M684.5,366.7h-10.7 M670.2,378.1l8.7,6.3 M658,399.7c6.1-1.5,11.4-4.7,16.1-9.4
	c1.9-1.9,3.4-3.9,4.8-5.9 M638.8,355.6c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9 M636.6,371.4l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1
	c1.7,1.9,2.1,4.5,1.1,7.7l-0.3,1"
          />
          <path
            fill="none"
            d="M646.4,378.4c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2 M641.9,370.5v1.9l1.8,1.1l1.8-1.1v-1.9l-1.8-1
	 M651,378.4l-0.3-1c-1.1-3.4-0.5-6.2,1.5-8.3c2.3-1,5-0.5,7.7,1.4l1,0.7"
          />
          <path
            fill="none"
            d="M654.8,370.3l-1.9-0.5l-1.4,1.4l0.4,1.9l1.8,0.5l1.5-1.2 M658.2,355.4c2.6,2.6,4,5.8,4,9.6l-0.1,1.8
	 M660.7,371.2c-0.7,1.2-1.5,2.3-2.5,3.3c-2.1,2.1-4.5,3.3-7.3,3.9 M656.2,400.1l1.8-0.4 M658,399.7l-3.3-10.2 M652.3,389.9l3.8,10.2
	 M645,389.9l0.8-2.1c0.6-1.2,1.2-1.9,2.1-2.2h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1 M641.2,400.1l3.9-10.2 M642.7,389.5l-3.3,10.3
	 M641.2,400.1c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7 M639.4,399.9l1.8,0.3 M645,389.9l3.6,0.1l3.7-0.1 M654.7,389.5
	c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7 M654.7,389.5l-3.7-11.1 M646.4,378.4l-3.7,11.1 M673.8,366.7h-11.7 M660.7,371.2
	l9.5,6.9 M667.7,348.5l8.7-6.3 M675.3,340.8l-9.2,6.1 M623.6,367h11.7 M638.8,355.6l-9.4-6.7 M631,347c1.8-1.8,3.7-3.2,5.8-4.3
	 M627.2,378.3l9.5-6.9 M534.7,353.1v0.4c0.1,1.1,0.1,2.3,0.3,3.4c0.1,1.9,0,3.9-0.4,5.8"
          />
          <path
            fill="none"
            d="M534.7,362.8l19.4,0.5c12.9,0.4,16.1-1,9.5-4c-6.5-2.8-16.1-4.8-28.8-6.3 M527.3,379.8h0.8 M534.7,362.8
	c-0.3,1.4-0.7,2.8-1.1,4.3l-3.4,11.1c-0.7,0.7-1.7,1.2-2.8,1.5 M522.7,379.6c1.8,0.3,3.3,0.3,4.5,0 M527.3,379.8l-4.5-0.1
	 M565,263.1h-14 M560.6,268.5l7.6-0.3 M699.8,383.9l11.3-1.8 M741.5,302c-12.7-8.3-31-14.9-54.6-19.9 M320.4,394.9h203 M343.5,357.5
	l191.2,5.4 M586.2,391.5l-298.9-1.5 M236.6,226.9c-18.7,5.1-37.3,10.6-56,16.5c-12.5,4-25,8.3-37.7,12.7c8.3,1.1,16.5,0.7,24.9-1.4
	c12.5-4,24.8-7.8,36.9-11.3c26.6-7.7,52.3-14.4,77.2-19.9l0.4-0.1l1.2-0.3l-8-6.3C261.9,219.8,248.9,223.1,236.6,226.9L236.6,226.9z
	"
          />
          <path
            fill="none"
            d="M239.8,245l5.4-1.9c19-6.2,39.1-11.3,60.1-15.3c26-5.4,52.5-8.4,79.8-9.1c24.4,0.1,46.2,4.3,65.5,12.2
	c8.8,3.7,17.6,7.8,26.4,12.5c13.1,6.9,26,15,38.9,24.1l0.3,0.3l9.2,7h1.9l56.5,1.1c36.5,0.3,70.8,2.2,103,6.2l18.4,2.5
	c27.7,5.4,47.3,12.2,59.3,20.5c2.2-3.6,4.5-6.5,6.9-8.7c-35.8-14.4-77.9-22.8-126.3-25.3l-77-2.8l-7.6,0.3l-31.2,1.1
	c-11.4-8.5-24.9-17.3-40.3-26.1c-1.8-1-3.6-2.1-5.4-3c-12.2-6.7-24.6-13.1-37.4-19c-19.7-6.9-43.5-10.3-71.5-10.2
	c-30.7,1.1-61.1,5-91.1,11.8l-1.4,0.3l-0.4,0.1c-24.9,5.5-50.6,12.1-77.2,19.9c-12.1,3.4-24.5,7.3-36.9,11.3
	c-8.3,2.1-16.5,2.5-24.9,1.4c-3.4-1.5-8.9-0.6-16.5,2.9c-5.5,2.3-12.4,4.3-20.8,5.4c-5.9,1.2-13.6,1.5-23.1,0.5l12.4,3
	c12.8,3.2,26.7,4,41.8,2.6c53.9-3.9,106.5-2.8,157.8,3.6c-51.3-6.3-104-7.4-157.8-3.6c-15,1.4-28.9,0.5-41.8-2.6l-12.4-3
	c-2.9-0.5-5.1-0.5-6.5,0l-0.8,0.5c-1,0.7-1.8,1.6-2.3,2.8c-1.7,3.2-1.5,5,0.3,5.5l18.8,4.7c1.9,0.5,3.3,1.6,4.1,3.2l1.1,2.2l0.8,1.8
	l1,1.8c1.1,3.2,0,6.1-3.2,8.9l23,21.2c1.5,1.4,3.2,2.5,5.1,3.3c2.9,1.2,6.1,1.5,9.5,1.1l35.9,2.1l3-3.2l3-3
	c13.5-12.4,29.9-18.4,49-18.4c19.1,0,35.5,6.2,49,18.4c1.1,1,2.1,1.9,3,3c3.4,3.6,6.3,7.3,8.8,11.1c3.6,5.8,6.1,12.1,7.3,18.8
	c1.8,9.9,2.9,22.1,3,36.6h1.2c15.1-3.2,30.1-4.7,45.3-4.4l202.2,3.6l10.3,0.5l8.4,0.8l15.3,2.2c-0.5-15.5,0-28.6,1.5-38.9
	c0.5-3,1.2-6.1,2.2-8.9c2.5-7.4,6.5-14.2,12.1-20.4l0.8-1c1.4-1.5,2.9-2.9,4.4-4.3c13.5-12.4,29.9-18.4,49-18.4
	c14.2,0,26.7,3.3,37.8,10l10.5-7.7c0.4-1.1,2.2-1.7,5.2-1.8c9.2,0.4,18.6,1.1,27.8,2.1l12.1,1.2c-12.7-8.3-31-14.9-54.6-19.9
	c-32.2-3.9-66.6-5.9-103-6.2l-56.5-1.1c7.6,5.5,11.8,13.5,12.8,23.9c0.3,6.6,0,14-0.8,22.1c-0.8,8-2.1,16.9-3.9,26.4l-0.4,2.5
	c-0.1,1.1-0.1,2.2,0,3.2c12.7,1.5,22.3,3.6,28.8,6.3c6.6,3,3.4,4.4-9.5,4l-19.4-0.5c-0.3,1.4-0.7,2.8-1.1,4.3l-3.4,11.1
	c-0.7,0.7-1.7,1.2-2.8,1.5h0.8h-0.8c-1.2,0.3-2.8,0.3-4.5,0l-147.7-2.1c-13.3-0.1-22.7-4.8-28.2-14l-3.4-5.9l-21.9-1
	c-11.1,0-14.7-1.1-10.6-3.2c7.8-3.2,16.6-5.5,26.5-7c-4-8.3-7.6-16.9-10.5-25.9c-2.8-8.1-5-16.5-6.9-25.2c-1.2-8.7,1-17.3,6.5-26
	l1.9-2.9c-22.3-1.1-44.4-2.8-66.4-5.1c-6.9-0.4-12-1.6-15.3-4c-2.6-2.1-5.5-4.8-8.4-8.3C237.2,247.6,237.4,245.9,239.8,245
	L239.8,245z"
          />
          <path
            fill="none"
            d="M246.7,245.4c-2.3,1.1-2.6,2.5-0.7,4.5c2.3,2.5,4.7,4.5,6.7,6.1c2.9,2.2,6.2,3.7,9.9,4.3l0.3,0.1
	c15.3,1.7,30.7,2.9,46.1,3.9l-12.1-31.9c-15.4,3.2-30.1,6.9-44.4,11.4L246.7,245.4L246.7,245.4z M224.2,299.6
	c-19.1,0-35.5,6.2-49,18.4l-3,3l-3,3.2l11.8,0.7c1.4-1.4,2.9-2.8,4.3-3.9c11.1-9.2,24.2-13.8,39.1-13.8c15,0,28.1,4.5,39.1,13.8
	c1.5,1.2,2.9,2.5,4.3,3.9c9.2,9.1,14.9,19.7,17.1,31.6v0.4c1.5,8.9,2.2,19.1,2.2,30.8h8.1c-0.1-14.6-1.2-26.7-3-36.6
	c-1.2-6.7-3.7-12.9-7.3-18.8c-2.3-3.9-5.4-7.6-8.8-11.1c-1-1-1.9-2.1-3-3C259.6,305.6,243.3,299.6,224.2,299.6L224.2,299.6z
	 M296.9,232.2l12.1,31.9l20.5,1.1l-14.9-36.5l-8.9,1.7h-0.1 M283.7,223.2l-1.4,0.3 M320.5,295.7c1.8,8.7,4.1,17.1,6.9,25.2
	c3,8.9,6.5,17.5,10.5,25.9c6.9-1.1,14.3-1.6,22.3-1.8l157,6.6c6.5,0.4,12.4,1,17.6,1.5v-3.2l0.4-2.5c1.8-9.6,3.2-18.4,3.9-26.4
	c0.8-8.1,1.1-15.5,0.8-22.1c-1-10.3-5.1-18.3-12.8-23.9h-1.9l-47.2-0.8L327,270C321.5,278.4,319.3,287,320.5,295.7L320.5,295.7z
	 M348.9,296c-4-0.1-7.6-0.7-10.6-1.4c-1.5-0.4-2.8-0.8-3.7-1.2c-0.8-0.4-1.5-1-1.8-1.4c-0.1-0.3-0.3-0.5-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.8c0.3-0.5,1-1,1.8-1.4c1-0.4,2.2-1,3.7-1.2c3-0.8,6.5-1.2,10.5-1.4l3.6-0.1l7.7,0.4c2.2,0.3,4.4,0.5,6.2,1.1
	c1.6,0.4,2.9,0.8,3.7,1.4c0.7,0.3,1.1,0.7,1.5,1c0.4,0.4,0.5,0.8,0.5,1.2s-0.1,0.8-0.5,1.2c-0.3,0.3-0.8,0.7-1.5,1
	c-1,0.4-2.2,1-3.7,1.2c-1.9,0.4-3.9,0.8-6.2,1.1l-7.8,0.4L348.9,296L348.9,296z"
          />
          <path
            fill="none"
            d="M352.3,296.1l7.8-0.4c1-0.8,1.6-1.6,2.1-2.3h-16c0.5,0.8,1.5,1.8,2.6,2.6L352.3,296.1L352.3,296.1z"
          />
          <path
            fill="none"
            d="M366.3,294.6c1.5-0.4,2.8-0.8,3.7-1.2h-7.8c-0.4,0.8-1.1,1.5-2.1,2.3C362.3,295.4,364.4,295.2,366.3,294.6z
	 M360,286.4c1,0.8,1.6,1.5,2.2,2.3h7.8c-1-0.5-2.2-1-3.7-1.4C364.4,287,362.3,286.6,360,286.4L360,286.4z"
          />
          <path
            fill="none"
            d="M352.3,285.9l-3.6,0.1c-1.4,0.8-2.3,1.8-2.9,2.6h16.4c-0.4-0.8-1.2-1.7-2.2-2.3L352.3,285.9L352.3,285.9z
	 M371.6,292.3c0.4-0.4,0.5-0.8,0.5-1.2s-0.1-0.8-0.5-1.2c-0.3-0.4-0.8-0.7-1.5-1h-35.5c-0.8,0.4-1.5,1-1.8,1.4
	c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.5,1,1,1.8,1.4h35.4C370.7,293,371.1,292.7,371.6,292.3L371.6,292.3z
	 M334.6,293.4c1,0.4,2.2,0.8,3.7,1.2c3,0.8,6.5,1.2,10.6,1.4c-1.1-1-2.1-1.8-2.6-2.6L334.6,293.4L334.6,293.4z"
          />
          <path
            fill="none"
            d="M348.7,286.1c-4,0.1-7.4,0.5-10.5,1.4c-1.5,0.4-2.8,0.8-3.7,1.2h11.3C346.4,287.9,347.3,286.9,348.7,286.1
	L348.7,286.1z M322.7,208.9c-16.5,2.2-32.2,4.8-47,8l8,6.3c30-6.9,60.4-10.9,91.1-11.8c28.1-0.1,51.9,3.3,71.5,10.2
	c-1.9-1-1.9-2.2-0.3-3.4l4.8-3.6c-13.1-5-29.2-8.1-48-9.6C375.1,203.8,348.4,205.2,322.7,208.9L322.7,208.9z M284.9,356.5
	c-2.1-12-7.8-22.6-17.1-31.6c-1.4-1.4-2.9-2.8-4.3-3.9c-11.1-9.2-24.2-13.8-39.1-13.8c-15,0-28.1,4.5-39.1,13.8
	c-1.5,1.2-2.9,2.5-4.3,3.9c-7.4,7.3-12.5,15.4-15.3,24.5c-0.8,2.8-1.5,5.6-1.9,8.5c-1,7.3-1.5,16-1.5,25.9v4.8l14.7,4.5
	c-5.2-8.4-7.8-17.7-7.8-28.3c0-7.6,1.4-14.7,4.1-21.2c2.6-6.3,6.6-12.1,11.8-17.2c2.1-2.1,4.3-4,6.5-5.6
	c9.2-6.9,19.9-10.3,31.9-10.3s22.7,3.4,31.9,10.3c2.2,1.6,4.4,3.6,6.5,5.6c10.6,10.6,16,23.4,16,38.4c0,5.8-0.8,11.3-2.3,16.4
	c-1.5,4.8-3.6,9.2-6.5,13.5h18.4l-0.1-4.8v-2.5c0-11.6-0.7-21.9-2.2-30.8L284.9,356.5L284.9,356.5L284.9,356.5z M346.9,363.4
	c5.4,9.2,14.7,13.9,28.1,14l147.7,2.1h4.5c1.2-0.3,2.1-0.7,2.8-1.5l3.4-11.1c0.5-1.5,0.8-2.9,1.1-4.3l-191.2-5.4L346.9,363.4
	L346.9,363.4z M337.9,346.8c-9.8,1.5-18.7,3.7-26.5,7c-4.1,2.1-0.7,3,10.6,3.2l21.6,0.5C341.4,353.9,339.6,350.5,337.9,346.8
	L337.9,346.8z"
          />
          <path
            fill="none"
            d="M343.5,357.5l191.2,5.4c0.4-2.1,0.5-4,0.4-5.8c0-1.2-0.1-2.3-0.3-3.4v-0.4c-5.4-0.7-11.3-1.1-17.6-1.6l-157-6.6
	c-7.8,0.1-15.3,0.8-22.3,1.8C339.6,350.5,341.4,353.9,343.5,357.5L343.5,357.5z M287.4,394.9h-6.9l0.3,5.2h39.6v-5.2 M287.2,387.6
	v2.5l298.8,1.5v-1.2h-8l-15.3-2.2l-8.4-0.8l-10.3-0.5l-202.2-3.6c-15.1-0.3-30.1,1.2-45.3,4.4L287.2,387.6L287.2,387.6z"
          />
          <path
            fill="none"
            d="M320.4,394.9h203l42.1,2.2l6.3-1.9h13.2l1.1-0.1v-3.6l-298.9-1.5l0.1,5 M165.6,349.5c2.9-9.1,8-17.3,15.4-24.5
	l-11.8-0.7c-5.1,5.9-8.9,12.4-11.3,19.4c-1,2.9-1.7,5.9-2.2,8.9c-1.2,8.5-1.9,18.7-1.8,30.7l8.1,0.5c0-9.9,0.6-18.6,1.5-25.9
	C164.1,355,164.8,352.3,165.6,349.5L165.6,349.5z"
          />
          <path
            fill="none"
            d="M158.1,343.6c2.3-7,6.1-13.5,11.3-19.4l-35.9-2.1c-3.4,0.4-6.6,0-9.5-1.1c-1.8-0.8-3.4-1.8-5.1-3.3l-23-21.2
	l-10.9,10h-0.1c-1,0.8-3,1.2-6.1,1.2l-17.6,0.4l-3.7,0.1c-1.1,1.2-1.9,2.3-2.8,3.3c-1,1.4-1.7,2.5-1.9,3.6c-0.4,1.2,0.1,2.2,1.9,2.8
	l1.4,0.4l3.2,10L56,340.1c-0.5,2.5-2.1,4.7-4.3,6.3l-0.5,4.1c0,1.1,0.4,1.8,1.4,1.9h16.2c4.3,0.5,7.3,1.9,8.9,4
	c4.3,5,8.4,8.9,12.4,11.7c4.7,3.3,9.1,5.5,13.1,6.6c18.7,2.2,33.6,5,44.8,8.1l5.8,0.4c-0.1-12,0.4-22.1,1.8-30.7
	C156.3,349.5,157,346.5,158.1,343.6L158.1,343.6z M139.1,355c-1,1.4-2.5,2.1-4.7,2.1l-20.6-1.4c-1.2,0-2.6-0.7-4.1-2.1
	c-1.9-1.5-1.9-2.3,0-2.5l27.7,1.6C139.5,353.1,140.2,353.8,139.1,355L139.1,355z"
          />
          <path
            fill="none"
            d="M137.3,352.8l-27.7-1.5c-1.9,0.1-1.9,1,0,2.5c1.5,1.4,2.9,2.1,4.1,2.1l20.6,1.4c2.1,0,3.7-0.7,4.7-2.1
	C140.2,353.8,139.5,353.1,137.3,352.8L137.3,352.8z M185,326.5c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2
	c0,10.5,2.6,19.9,7.8,28.3c2.2,3.6,5,6.9,8.1,10.2c10.6,10.6,23.4,16,38.4,16c15,0,27.8-5.2,38.4-16c2.8-2.8,5.1-5.5,7.2-8.5
	c2.9-4.3,5-8.7,6.5-13.5c1.7-5.1,2.3-10.6,2.3-16.4c0-15-5.2-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3
	s-22.7,3.4-31.9,10.3C189.3,322.7,187.1,324.5,185,326.5L185,326.5z M183.6,365c0-11,3.9-20.4,11.7-28.2
	c7.8-7.8,17.2-11.7,28.2-11.7c11,0,20.4,3.9,28.2,11.7c7.8,7.8,11.7,17.2,11.7,28.2s-3.9,20.4-11.7,28.2
	c-7.8,7.8-17.2,11.7-28.2,11.7c-11,0-20.4-3.9-28.2-11.7C187.5,385.4,183.6,376.1,183.6,365z"
          />
          <path
            fill="none"
            d="M195.2,393.2c7.8,7.8,17.2,11.7,28.2,11.7c11,0,20.4-3.9,28.2-11.7c7.8-7.8,11.7-17.2,11.7-28.2
	s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7c-11,0-20.4,3.9-28.2,11.7c-7.8,7.8-11.7,17.2-11.7,28.2
	C183.6,376.1,187.5,385.4,195.2,393.2L195.2,393.2z M185.8,365c0-10.5,3.7-19.3,11-26.7c7.4-7.4,16.2-11,26.7-11
	c10.5,0,19.3,3.7,26.7,11c7.3,7.4,11,16.2,11,26.7s-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11s-19.3-3.7-26.7-11
	C189.4,384.3,185.8,375.4,185.8,365L185.8,365z"
          />
          <path
            fill="none"
            d="M196.9,391.6c7.4,7.4,16.2,11,26.7,11c10.5,0,19.3-3.7,26.7-11c7.3-7.4,11-16.2,11-26.7
	c0-10.5-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11c-7.3,7.4-11,16.2-11,26.7
	C185.8,375.4,189.4,384.3,196.9,391.6L196.9,391.6z M198.1,339.6c3.2-3.2,6.7-5.8,10.6-7.4l1.6-0.7c4-1.5,8.4-2.3,13.1-2.3
	c4.5,0,8.8,0.7,12.8,2.2l1.6,0.7c4,1.8,7.6,4.3,10.9,7.6l1.2,1.2l1.2,1.4c5.4,6.5,8.1,14,8.1,22.8v1.8c0,0.5,0,1.2-0.1,1.8
	c-0.4,5.2-1.9,10-4.5,14.3l-1,1.5c-1.4,2.1-3,4-4.8,5.9c-4.7,4.7-10,7.8-16.1,9.4l-1.8,0.4c-2.3,0.5-4.9,0.7-7.6,0.7
	s-5.1-0.3-7.4-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4c-1.8-1.8-3.4-3.7-4.8-5.8l-1-1.5c-2.6-4.3-4.1-9.1-4.5-14.2l-0.1-1.8v-1.9
	c0-8.7,2.6-16.1,8-22.6l1.2-1.4L198.1,339.6L198.1,339.6z"
          />
          <path
            fill="none"
            d="M196.7,341l9.1,6.1c1.8-1.8,3.7-3.2,5.8-4.3l-2.9-10.6c-3.9,1.8-7.4,4.3-10.6,7.4L196.7,341L196.7,341z"
          />
          <path
            fill="none"
            d="M204.3,348.8l9.4,6.7l0.7,0.5c2.9,2.2,4.1,4.7,3.6,7.6c-1.4,2.2-3.7,3.3-7,3.3h-23.4l0.1,1.8l10.9,0.5l2.1,0.1
	c1.4,0.1,2.2,0.5,2.8,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.6,1.4l-8.5,6.9l1,1.5l8.8-6.3l9.4-6.9l0.8-0.5
	c3-2.1,5.8-2.5,8.3-1.1c1.6,1.9,2.1,4.5,1,7.7l-0.3,1l-3.6,11.1l-3.3,10.3l1.8,0.4l3.9-10.3l0.8-2.1c0.4-1.2,1.2-1.9,2.1-2.2h1.5
	c1,0.3,1.6,1,2.1,2.2l0.8,2.1l3.9,10.3l1.8-0.4l-3.4-10.3l-3.6-11.1l-0.3-1c-1.1-3.4-0.6-6.2,1.5-8.3c2.3-1,5-0.5,7.7,1.4l1,0.7
	l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7c0.6-0.8,1.4-1.2,2.6-1.4l2.2-0.1l10.9-0.5
	c0-0.6,0.1-1.2,0.1-1.8h-23.4c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5l9.5-6.9l8.8-6.3l-1.2-1.4l-9.2,6.1l-1.8,1.2
	c-1.1,0.7-2.1,0.8-3,0.5l-0.7-0.4l-0.6-0.6c-0.6-0.7-0.8-1.6-0.4-3l0.6-2.1l2.9-10.6l-1.7-0.7l-3.3,10.3l-3.6,11.1l-0.3,1
	c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1l-3.6-11.1l-3.3-10.3l-1.6,0.7l2.9,10.6l0.6,2.1c0.3,1.2,0.1,2.2-0.4,3
	l-0.6,0.5c-0.3,0.1-0.4,0.3-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.6-1.1l-9.1-6.1l-1.2,1.4L204.3,348.8L204.3,348.8z M223.5,360.1
	c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4c0,1.4-0.6,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4s-2.5-0.5-3.4-1.4c-1-1-1.4-2.1-1.4-3.4
	c0-1.4,0.4-2.5,1.4-3.4C221.1,360.6,222.2,360.1,223.5,360.1L223.5,360.1z"
          />
          <path
            fill="none"
            d="M220.1,361.6c-1,1-1.4,2.1-1.4,3.4s0.4,2.5,1.4,3.4s2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4
	s-0.6-2.5-1.4-3.4c-1-1-2.1-1.4-3.4-1.4C222.2,360.1,221.1,360.6,220.1,361.6z M195.5,342.5c-5.4,6.5-8,13.9-8,22.6v1.9h10.7V365
	c0-6.2,1.9-11.7,5.9-16.2L195.5,342.5L195.5,342.5z"
          />
          <path
            fill="none"
            d="M198.4,367h11.7l-0.1-1.9c0-3.7,1.2-6.7,3.7-9.4l-9.4-6.9c-4,4.5-5.9,10-5.9,16.2V367z M203.9,371.4l-0.3-0.7
	c-0.4-0.8-1.4-1.2-2.8-1.4l-2.1-0.1c0.4,2.5,1.1,4.7,2.2,6.9l1.7-1.4c1-0.8,1.5-1.8,1.4-2.6C203.9,371.9,203.9,371.7,203.9,371.4
	L203.9,371.4z M202.1,378.3l-8.8,6.3c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4l3.3-10.3c-4.4-1.1-8.4-3.3-11.8-6.7
	C204.3,381.3,203,379.8,202.1,378.3L202.1,378.3z"
          />
          <path
            fill="none"
            d="M205.7,382.8c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2l-9.4,6.9
	C203,379.8,204.3,381.3,205.7,382.8L205.7,382.8z M198.6,369.3l-10.9-0.5c0.6,5.2,2.1,9.9,4.5,14.2l8.5-6.9
	C199.9,374,199.1,371.8,198.6,369.3L198.6,369.3z M210.5,331.5l3.3,10.3c3-1.2,6.2-1.9,9.8-1.9c3.4,0,6.5,0.5,9.4,1.8l3.3-10.3
	c-4-1.5-8.3-2.2-12.8-2.2S214.5,329.8,210.5,331.5L210.5,331.5z"
          />
          <path
            fill="none"
            d="M213.8,341.7l3.6,11.1c1.9-1,3.9-1.4,6.2-1.4c2.1,0,4.1,0.4,5.8,1.2l3.6-11.1c-2.9-1.1-6.1-1.8-9.4-1.8
	C220,339.9,216.8,340.4,213.8,341.7L213.8,341.7z M236.2,348.5c0.8,0.4,1.9,0.3,3-0.6l1.8-1.2c-1.8-1.8-3.7-3.2-5.9-4.3l-0.6,2.1
	c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5L236.2,348.5L236.2,348.5z"
          />
          <path
            fill="none"
            d="M240.9,346.9l9.2-6.1l-1.2-1.2c-3.3-3.3-6.9-5.8-10.9-7.6l-2.9,10.6C237.2,343.7,239.1,345.1,240.9,346.9
	L240.9,346.9z M232.2,356c-2.6,2.1-3.9,4.3-3.7,6.9c1.2,2.6,3.7,3.9,7.3,4h1.1l0.1-1.8c0-3.7-1.4-6.9-4-9.6L232.2,356L232.2,356z
	 M230.4,360.6l1.9,0.3l0.8,1.8l-1.2,1.6l-1.9-0.3l-0.8-1.8L230.4,360.6L230.4,360.6z"
          />
          <path
            fill="none"
            d="M230.1,364.1l1.9,0.3l1.2-1.7l-0.8-1.8l-2.1-0.3l-1.1,1.6 M229.3,352.7c-1.8-0.8-3.7-1.2-5.8-1.2
	c-2.2,0-4.3,0.5-6.2,1.4l0.3,1c1.1,3.2,2.9,5.1,5.4,5.6c2.8-0.4,4.8-2.3,5.9-5.8L229.3,352.7L229.3,352.7z M225.5,356.5l-0.8,1.8
	l-2.1,0.3l-1.1-1.6l0.8-1.8l1.9-0.1L225.5,356.5L225.5,356.5z"
          />
          <path
            fill="none"
            d="M222.3,355.1l-0.8,1.8l1.2,1.7l1.9-0.3l0.8-1.8l-1.1-1.6 M210.5,348.7c0.3-0.1,0.4-0.3,0.7-0.4l0.6-0.5
	c0.6-0.7,0.8-1.6,0.4-3l-0.6-2.1c-2.1,1.1-4,2.6-5.8,4.3l1.6,1.1C208.7,348.9,209.6,349.1,210.5,348.7L210.5,348.7z M213.6,355.6
	c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9h1c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6L213.6,355.6L213.6,355.6z M217.5,361.7l-0.4,1.9
	l-1.9,0.5l-1.5-1.4l0.4-1.9l1.9-0.7L217.5,361.7L217.5,361.7z"
          />
          <path
            fill="none"
            d="M214.2,360.9l-0.4,1.9l1.4,1.4l1.9-0.5l0.4-1.9l-1.5-1.4 M242.5,348.5c4.1,4.7,6.2,10,6.2,16.5v1.8h10.7V365
	c0-8.7-2.8-16.4-8.1-22.8L242.5,348.5L242.5,348.5z M243.1,371.2l-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6l1.8,1.5c1.1-2.2,1.8-4.5,2.2-7
	l-2.2,0.1c-1.2,0.1-2.2,0.5-2.8,1.4L243.1,371.2z"
          />
          <path
            fill="none"
            d="M248.6,365c0-6.3-2.1-11.8-6.2-16.5l-9.5,6.9c2.6,2.6,4,5.8,4,9.6l-0.1,1.8h11.6
	C248.6,366.1,248.6,365.6,248.6,365z M246.1,376.1l8.5,6.9c2.6-4.4,4.1-9.1,4.5-14.3l-10.9,0.5C247.9,371.5,247.2,373.9,246.1,376.1
	L246.1,376.1z M245,378.1c-1.1,1.6-2.2,3.2-3.7,4.7c-3.4,3.4-7.4,5.6-11.8,6.7l3.4,10.3c6.1-1.5,11.4-4.7,16.1-9.4
	c1.9-1.9,3.4-3.9,4.8-5.9L245,378.1L245,378.1z M211.4,371.4c0.7,1.1,1.5,2.2,2.5,3.2c2.1,2.1,4.5,3.3,7.3,3.9l0.3-1
	c1-3.2,0.6-5.8-1.1-7.7c-2.5-1.4-5.2-1-8.3,1.1L211.4,371.4L211.4,371.4z M220.2,370.5v2.1l-1.8,1l-1.6-1v-2.1l1.6-1L220.2,370.5z"
          />
          <path
            fill="none"
            d="M216.8,370.5v1.9l1.6,1.1l1.8-1.1v-1.9l-1.8-1 M234.7,370.5c-2.8-1.9-5.4-2.3-7.7-1.4c-2.1,2.1-2.5,4.7-1.5,8.3
	l0.3,1c2.8-0.4,5.2-1.6,7.3-3.9c1.1-1.1,1.9-2.2,2.5-3.3L234.7,370.5L234.7,370.5z M228.6,373.6l-1.9-0.5l-0.4-1.9l1.5-1.4l1.9,0.5
	l0.4,1.9L228.6,373.6L228.6,373.6z"
          />
          <path
            fill="none"
            d="M229.6,370.3l-1.8-0.5l-1.5,1.4l0.4,1.9l1.9,0.5l1.4-1.2 M233,374.5c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7l-9.4-6.9C235,372.5,234.1,373.6,233,374.5L233,374.5z M227.1,389.9l-3.7,0.3
	l-3.6-0.3l-3.9,10.3c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7L227.1,389.9L227.1,389.9z"
          />
          <path
            fill="none"
            d="M224.2,385.7h-1.5c-1,0.3-1.6,1-2.1,2.2l-0.8,2.1l3.6,0.3l3.7-0.3l-0.8-2.1
	C225.9,386.6,225.2,385.8,224.2,385.7L224.2,385.7z M154.1,383.2l-5.8-0.4c-11.1-3.2-26.1-5.9-44.8-8.1c-4.1-1.1-8.5-3.3-13.1-6.6
	c-4-2.8-8.1-6.7-12.4-11.7c-1.7-2.1-4.7-3.4-9.1-4H52.5c-1.9,0.4-2.6,1.1-2.1,1.9l14.2,0.1c3.3,0.3,5.9,1.1,8.1,2.5
	c0.8,0.6,1.7,1.2,2.2,1.9l1.4,1.6l5.6,7.1l7.4,9.4c0.6,0.5,1.2,1.1,1.9,1.5c1.4,0.8,3,1.7,5,2.2c1.7,0.4,3.4,0.8,5.4,1.1l60.4,6.7
	v-4.8L154.1,383.2L154.1,383.2z M73.9,276.2l17.1,3.7c1.8,0.4,3.3,1.2,4.5,2.2c0.7,0.5,1.2,1.1,1.7,1.8l-1.1-2.2
	c-0.8-1.5-2.2-2.6-4.1-3.2L73,273.8C72.8,274.4,73,275.2,73.9,276.2L73.9,276.2z"
          />
          <path
            fill="none"
            d="M78,282.4l4.5,1l0.7-0.8l1.1-2.5l4.4,1.9l0.3-0.8l5.2,2.6l1.1-1.5c-1.2-1-2.8-1.6-4.5-2.2l-17.1-3.7l1.1,1
	L78,282.4L78,282.4z M78.3,282.8l-2.2,3.4L64.9,303l-5,3c-1.9,1-1.5,1.8,1.2,2.5l17.6-0.4c3,0,5.1-0.4,6.1-1.2H85l10.9-10
	c3.2-2.9,4.3-5.9,3.2-8.9l-1-1.8l-12.5,17.1c-3.2,1.6-5.1,1.8-5.9,0.3c-2.6,1.2-4.7,1.6-6.2,1.2l-0.6-0.1c-1.1,1.1-2.8,1.4-4.8,1.2
	c-1.5-0.5-1.7-1.4-0.8-2.6l15.1-19.7l-4.5-1L78.3,282.8z"
          />
          <path
            fill="none"
            d="M72.6,286.8L63.6,300c-1.1,0.8-0.7,1.8,1.4,3l11.1-16.8C74.7,285.3,73.5,285.4,72.6,286.8L72.6,286.8z
	 M88.7,282l-4.4-1.9l-1.1,2.5l-0.7,0.8L67.4,303c-1,1.2-0.7,2.2,0.8,2.6c2.1,0.3,3.7-0.1,4.8-1.2c-0.6-0.4-0.3-1.1,0.6-2.3l15-19.4
	L88.7,282L88.7,282z"
          />
          <path
            fill="none"
            d="M88.5,282.8l-15,19.4c-0.8,1.1-1,1.9-0.6,2.3l0.6,0.1c1.5,0.4,3.6,0,6.2-1.2l14.7-19.8l-5.2-2.6l-0.3,0.8
	L88.5,282.8L88.5,282.8z M94.4,283.6l-14.7,19.8c0.8,1.5,2.8,1.5,5.9-0.3l12.5-17.5l-0.8-1.8c-0.6-0.7-1.1-1.2-1.7-1.8L94.4,283.6
	L94.4,283.6z M63.4,360.6l18.8,18l-2.5-3c-1.4-1.9-2.5-3.6-3.3-5.2c-0.8-1.5-1-2.5-0.6-2.8h1.9l-0.6-1.2c-0.8-1.6-1.1-2.8-0.7-3h0.6
	c0.6,0.1,1.2,0.7,2.2,1.5l2.2,2.3l0.3,0.3H82l-5.6-7.2h-7.4c-1,0.1-1.7-0.1-2.2-0.5l-1.1-1.4c-1.2-1.1-1.2-1.9,0-2.2l-12.4-0.8
	l-0.1,2.3c2.2,0,4.1,0.1,5.6,0.5C60.9,358.7,62.5,359.5,63.4,360.6L63.4,360.6z"
          />
          <path
            fill="none"
            d="M58.9,358.3l-1.1,2.2c-0.7,1.8,0,3.2,2.2,4.1l6.7,4l12.9,12c3,1,5.9,1.6,8.8,2.3l0.4-1.1l-2.6-0.4h-0.1
	c-0.4,0-1.1-0.5-2.1-1.4l-1.5-1.5l-18.8-18C62.5,359.5,60.9,358.7,58.9,358.3L58.9,358.3z"
          />
          <path
            fill="none"
            d="M58.9,358.3c-1.5-0.4-3.4-0.5-5.6-0.5c3.7,4.1,6.1,9.4,7.3,15.8c6.6,2.9,12.9,5.2,19,7l-12.9-12l-6.7-4
	c-2.2-1.1-2.9-2.5-2.2-4.1L58.9,358.3L58.9,358.3z M51.6,355.4l1.8,0.1l12.4,0.8l7,0.5c-2.1-1.4-4.8-2.2-8.1-2.5l-14.2-0.1
	L51.6,355.4L51.6,355.4z M76.8,363.4c-0.4,0.4-0.3,1.4,0.6,3l0.6,1.2h3.9l-0.3-0.3l-2.2-2.3c-1-1-1.7-1.4-2.2-1.5
	C77,363.4,76.9,363.4,76.8,363.4L76.8,363.4z"
          />
          <path
            fill="none"
            d="M75,358.9c-0.7-0.7-1.4-1.4-2.2-1.9l-7-0.5c-1.2,0.3-1.2,1.1,0,2.2l1.1,1.4c0.6,0.4,1.2,0.7,2.2,0.5h7.4
	L75,358.9L75,358.9z M75.9,367.7c-0.4,0.3-0.1,1.1,0.6,2.8c0.7,1.5,1.8,3.3,3.3,5.2l2.5,3l1.5,1.5c1,0.8,1.7,1.4,2.1,1.4h0.3
	c0.4-0.3,0.1-1.1-0.6-2.8c-0.4-1-1.1-1.9-1.8-3l-1.5-2.1c-0.7-1-1.4-1.6-1.9-2.5l-2.1-2.2c-1-0.8-1.5-1.2-2.1-1.4
	C76.2,367.7,75.9,367.7,75.9,367.7z"
          />
          <path
            fill="none"
            d="M80.5,371.2c0.7,0.7,1.2,1.5,1.9,2.5l1.5,2.1c0.7,1.1,1.4,2.2,1.8,3c0.7,1.5,1,2.5,0.6,2.8h-0.1l2.6,0.4
	l4.3,0.5c0.6-1.1,0.1-2.5-1.7-4c-0.7-0.4-1.4-1-1.9-1.5l-7.4-9.4h-5.5c0.4,0.1,1.1,0.5,2.1,1.4L80.5,371.2L80.5,371.2z"
          />
          <path
            fill="none"
            d="M93,382.5l3.4-1.8c-1.9-0.5-3.7-1.4-5-2.2C93.1,380,93.7,381.4,93,382.5L93,382.5z M779.7,310.2
	c1.1-3.4,3.4-5.8,7-6.9c2.2-1.2,2.3-2.6,0.4-4.1c-3.6-2.2-7.3-4.1-11.1-6.1c-1.5,0.8-3,1.9-4.5,3.3c-2.3,2.2-4.7,5.1-6.9,8.7
	l-6.3,12.2c-1.2,2.2-4,3.2-8.4,2.9l-27.1-3.6c-4.3-0.8-8.3-2.3-12.1-4.8l-14.4-11.3l-10.5,7.7c3.9,2.3,7.6,5.2,11.1,8.4
	c1.5,1.4,3,2.8,4.3,4.3c2.9,3.2,5.4,6.5,7.6,9.9c3.6,5.8,6.1,12.1,7.3,18.8c1.8,9.6,2.6,20.9,2.6,33.6l9.6-1.2
	c2.8-0.4,5.6-0.7,8.4-0.8l36.5-0.8c2.6,0.1,4.3-0.4,5.2-1.6l-47.5-0.8c-3.2-0.1-3.2-2.1,0-5.9l10.6-13.6l2.9-3.7l2.8-3.6
	c1.8-2.1,5.2-3.2,10-3.2c2.3,0,4.5-0.1,6.6,0c5.1,0.1,9.6,0.5,14,1.5c-4.3-0.8-8.9-1.4-14-1.5c2.6,0.3,3.9,1.6,3.9,3.9
	c0.4,2.2-0.1,3.4-1.9,3.7l10.6,0.3c2.8,0,4.1-0.5,4.1-1.7c0-8-1-14.7-2.9-20.2l-0.8-2.1l-1.1-2.3c-0.5-0.8-0.8-1.8-1.1-2.6
	c-0.4-1.8-0.3-3.3,0.5-5l2.6-7.4L779.7,310.2L779.7,310.2z"
          />
          <path
            fill="none"
            d="M776,293.1c-10.9-5.4-23-10-36.5-13.8c-22.3-6.2-51.9-10.7-88.9-13.9c-30.8-2.1-59.4-2.8-85.7-2.2l3,5.1l77,2.8
	c48.4,2.5,90.5,10.9,126.3,25.3C773,294.9,774.5,293.9,776,293.1L776,293.1z M758.2,317.3l6.3-12.2c-7.7-1.1-15.4-2.1-23.1-3
	l-12.1-1.2l-0.5,12.8l4,2.8l-9.6-1.2c-2.9-0.3-5.9-1.5-9.1-3.9l-0.8-0.5l-8.1-5.9c-1.5-1.2-2.2-2.3-2.1-3c0-0.7,0.3-1.2,1.1-1.5
	l1.8-0.1l16.8,1.5c0.1,0,0.4,0.1,0.5,0.1l0.4,0.4c0,0.1,0.1,0.4,0,0.5c-0.4,3,1.1,6.1,4.4,9.1c0.1,0.1,0.3,0.4,0.3,0.5v0.5l0.7,0.4
	l0.5-12.8c-9.4-0.8-18.6-1.5-27.8-2.1c-3,0.1-4.8,0.7-5.2,1.8l14.4,11.3c3.9,2.5,7.8,4,12.1,4.8l27.1,3.6
	C754.3,320.5,757,319.5,758.2,317.3L758.2,317.3z M757.6,305.9l-6.2,11.7c-2.1-0.1-2.8-0.5-2.2-1.4l4.3-8.8
	C754.4,305.9,755.8,305.3,757.6,305.9L757.6,305.9z M742.3,315.7l4.3-8.8c1-1.6,2.2-2.2,4.1-1.6l-6.2,11.7
	C742.6,316.9,741.7,316.4,742.3,315.7L742.3,315.7z M736,315.4l4.3-8.8c1-1.6,2.2-2.2,4.1-1.6l-6.2,11.7
	C736.1,316.5,735.4,316.1,736,315.4L736,315.4z"
          />
          <path
            fill="none"
            d="M753.6,307.5l-4.3,8.8c-0.5,0.7,0.3,1.1,2.2,1.4l6.2-11.7C755.8,305.3,754.4,305.9,753.6,307.5L753.6,307.5z
	 M744.5,317l6.2-11.7c-1.8-0.5-3.2,0-4.1,1.6l-4.3,8.8C741.7,316.3,742.6,316.9,744.5,317L744.5,317z M738.2,316.8l6.2-11.7
	c-1.8-0.5-3.2,0-4.1,1.7l-4.3,8.8C735.4,316.1,736.1,316.5,738.2,316.8L738.2,316.8z M764.6,305.1c-12-8.3-31.8-15.1-59.3-20.5
	l-18.4-2.5c23.7,5.1,42,11.7,54.6,19.9C749.2,302.9,756.9,303.8,764.6,305.1L764.6,305.1z M723.4,302.3l-0.4-0.4
	c-0.1-0.1-0.4-0.1-0.5-0.1l-16.8-1.5l-1.8,0.1c-0.8,0.3-1.2,0.7-1.1,1.5c-0.1,0.7,0.5,1.7,2.1,3l8.1,5.9l0.8,0.5l2.5,1.2
	c4.5,1.9,8.3,2.3,11,1.2c0.3-0.1,0.4-0.3,0.5-0.4v-0.1c0.1-0.1,0.1-0.4,0-0.5c0-0.3-0.1-0.4-0.3-0.5c-3.3-3-4.8-5.9-4.4-9.1
	C723.6,302.7,723.4,302.5,723.4,302.3L723.4,302.3z M714.1,309.3l-8.3-6.2l-1-0.8h0.1l16,1.5c-0.1,3,1.1,5.9,3.9,8.8
	c-2.1,0.3-4.8-0.3-7.8-1.6L714.1,309.3L714.1,309.3z"
          />
          <path
            fill="none"
            d="M717.1,311.1c3,1.2,5.6,1.8,7.8,1.7c-2.6-2.9-4-5.8-3.8-8.8l-16-1.5H705l1,0.8l8.3,6.2L717.1,311.1L717.1,311.1
	z"
          />
          <path
            fill="none"
            d="M727.6,313.7c-2.8,1.1-6.5,0.7-11-1.2l-2.5-1.2c3.2,2.3,6.2,3.6,9.1,3.9l9.6,1.2l-4-2.8l-0.7-0.4v0.1
	C728,313.5,727.8,313.6,727.6,313.7L727.6,313.7z M708.9,355.1c-2.1-12-7.8-22.6-17.1-31.6l-2.8-2.6c-3.2-2.9-6.6-5.4-10.3-7.4
	c-9.1-5.2-19.1-7.7-30.4-7.7c-15.7,0-29.2,5.1-40.7,15.1l-2.8,2.6c-7.4,7.3-12.5,15.4-15.4,24.5c-0.8,2.8-1.5,5.6-1.9,8.5
	c-1.2,9.1-1.8,20.2-1.5,33.6v1.2l0.1,3.4l5.1-0.7l10.6-1.4c-5.1-8.3-7.6-17.5-7.6-27.9c0-7.6,1.4-14.7,4.1-21.2
	c2.6-6.3,6.6-12.1,11.8-17.2c2.1-2.1,4.3-4,6.5-5.6c9.2-6.9,19.9-10.3,31.9-10.3c12.1,0,22.7,3.4,31.9,10.3c2.2,1.6,4.4,3.6,6.5,5.6
	c10.6,10.6,16,23.4,16,38.4c0,5.8-0.8,11.3-2.3,16.4l-0.8,2.5l11.3-1.8c-0.1-9.9-0.8-18.7-2.2-26.5L708.9,355.1L708.9,355.1z"
          />
          <path
            fill="none"
            d="M708.9,355.6c1.4,7.8,2.1,16.6,2.2,26.5v1.5l7.7-0.4c0-12.7-0.8-23.9-2.6-33.6c-1.2-6.7-3.7-12.9-7.3-18.8
	c-2.1-3.4-4.7-6.7-7.6-9.9c-1.4-1.5-2.8-2.9-4.3-4.3c-3.6-3.2-7.3-6.1-11.1-8.4l-7.2,5.4c3.6,2.1,7,4.5,10.3,7.4l2.8,2.6
	c9.2,9.1,14.9,19.7,17.1,31.6L708.9,355.6L708.9,355.6z M774.9,326.8l1.4-1l1.9-11.4l-2.6,7.4C774.8,323.4,774.5,325,774.9,326.8
	L774.9,326.8z M764.2,347.9h-6.6c-5,0-8.3,1.1-10,3.2l-2.8,3.6l10.9,0.4l3.3,0.1l6.1,0.3h1.4c1.7-0.3,2.3-1.5,1.9-3.7
	C768,349.4,766.8,348.1,764.2,347.9L764.2,347.9z"
          />
          <path
            fill="none"
            d="M764.9,355.4l0.3,1l10.5,1.1c0.8,0.1,1.5,0.5,2.1,1.1l9.6-1.8c2.3-0.4,3.6-1.2,3.7-2.6c1.1-5.9,1.7-11,1.7-15.4
	c0.3-1.4-1.1-2.5-4.1-3.2l-5.8-1l-5-0.8c1.9,5.5,2.9,12.2,2.9,20.2c0,1.1-1.2,1.7-4.1,1.7l-10.3-0.3L764.9,355.4L764.9,355.4
	L764.9,355.4z"
          />
          <path
            fill="none"
            d="M758.8,355.1c1.5,3.3,2.2,6.7,2.1,10.5c0,3.4-1.1,5.1-3.6,5.2h-4.5c-3.2,0.7-5.6,0.1-7.4-1.5
	c-2.1-1.9-2.5-4.8-1-8.7c0.4-1,0.8-1.8,1.4-2.5l-4,0.1l-10.6,13.6l31.5,0.5c2.9,0,4.3-1.5,4.3-4.7c0-3.9-0.5-7.7-1.7-11.6l-0.4-0.8
	L758.8,355.1L758.8,355.1z"
          />
          <path
            fill="none"
            d="M765.1,356.4c1.1,3.9,1.7,7.7,1.7,11.6c0,3.2-1.4,4.7-4.3,4.7l-31.5-0.5c-3.2,3.9-3.2,5.8,0,5.9l47.5,0.8
	c0.7-0.8,1-2.1,0.8-3.6c1.8,1.8,4.4,2.9,8,3.3l2.6,0.4c0.4-1.6-1.2-3-4.8-4.3c-2.1-0.3-3.2-1.1-3.3-2.5l-2.2-9.4
	c-0.1-1.9-0.8-3.3-1.9-4.3c-0.5-0.5-1.2-0.8-2.1-1.1L765.1,356.4L765.1,356.4z M744.5,354.6l-2.9,3.7l4-0.1c2.1-2.8,5.2-3.7,9.8-2.9
	V355L744.5,354.6L744.5,354.6z"
          />
          <path
            fill="none"
            d="M755.4,355.4l-1.4,15.3l-1.2,0.3h4.5c2.5-0.1,3.7-1.9,3.6-5.2c0.1-3.7-0.5-7.3-2.1-10.5l-3.3-0.1L755.4,355.4
	L755.4,355.4z"
          />
          <path
            fill="none"
            d="M745.6,358.2c-0.5,0.7-1,1.5-1.4,2.5c-1.4,3.9-1.1,6.7,1,8.7c1.7,1.7,4.1,2.2,7.4,1.5l1.2-0.3l1.4-15.3
	C750.8,354.6,747.7,355.6,745.6,358.2L745.6,358.2z M768.2,384.9l-33.8,2.8l-13.3,0.7v5.9h16.9c13.1-0.3,23.2-1.5,30.3-3.7V384.9
	L768.2,384.9z"
          />
          <path
            fill="none"
            d="M711.1,383.6v5.1l9.8-0.4l13.3-0.7l33.8-2.8l12.2-1c5.6-0.4,10-1.1,13.1-2.1c1.7-1.1,1.2-2.1-1.4-2.6h-0.3
	l-1.9-0.3l-2.6-0.4c-3.4-0.4-6.1-1.5-8-3.3c0.1,1.5-0.1,2.8-0.8,3.6c-1,1.2-2.8,1.8-5.2,1.6l-36.5,0.8c-2.8,0.1-5.6,0.4-8.4,0.8
	l-9.6,1.2L711.1,383.6L711.1,383.6z M777.9,333.8l5,0.8l-5.8-2.9 M446,218.1c-1.8,1.2-1.6,2.3,0.3,3.4c12.8,5.9,25.3,12.2,37.4,19
	c1.8,1.1,3.6,2.1,5.4,3c15.4,8.8,28.9,17.5,40.3,26.1l31.2-1.1c-3.3-1.8-6.5-3.6-9.6-5.4c-13.3-7.4-25.4-13.9-36.6-19.7
	c-29.3-15.3-50.5-24.9-63.6-28.9L446,218.1L446,218.1z M560.6,268.5l7.6-0.3l-3-5.1h-14C554.1,264.9,557.3,266.7,560.6,268.5
	L560.6,268.5z M685.9,308.2c-11-6.7-23.7-10-37.8-10c-19.1,0-35.5,6.2-49,18.4c-1.5,1.4-3,2.8-4.4,4.3l-0.8,1
	c-5.5,6.2-9.6,13.1-12.1,20.4c-1,2.9-1.7,5.9-2.2,8.9c-1.7,10.3-2.1,23.4-1.5,38.9h8c-0.3-13.3,0.1-24.5,1.5-33.6
	c0.4-2.9,1.1-5.8,1.9-8.5c2.9-9.1,8-17.3,15.4-24.5l2.8-2.6c11.4-10,25-15.1,40.7-15.1c11.3,0,21.3,2.6,30.4,7.7L685.9,308.2
	L685.9,308.2z"
          />
          <path
            fill="none"
            d="M610.2,326.5c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2c0,10.3,2.5,19.7,7.6,27.9
	c2.3,3.7,5.1,7.3,8.4,10.6c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c5.8-5.8,10-12.4,12.7-19.5l0.8-2.5
	c1.7-5.1,2.3-10.6,2.3-16.4c0-15-5.4-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3
	C614.5,322.7,612.3,324.5,610.2,326.5L610.2,326.5z M608.7,365c0-11,3.9-20.4,11.7-28.2c7.8-7.8,17.2-11.7,28.2-11.7
	s20.4,3.9,28.2,11.7c7.8,7.8,11.7,17.2,11.7,28.2s-3.9,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7s-20.4-3.9-28.2-11.7
	C612.7,385.4,608.7,376.1,608.7,365L608.7,365z"
          />
          <path
            fill="none"
            d="M620.4,393.2c7.8,7.8,17.2,11.7,28.2,11.7s20.4-3.9,28.2-11.7c7.8-7.8,11.7-17.2,11.7-28.2s-3.8-20.4-11.7-28.2
	c-7.8-7.8-17.2-11.7-28.2-11.7s-20.4,3.9-28.2,11.7c-7.8,7.8-11.7,17.2-11.7,28.2S612.7,385.4,620.4,393.2L620.4,393.2z M610.9,365
	c0-10.5,3.7-19.3,11-26.7c7.4-7.4,16.2-11,26.7-11s19.3,3.7,26.7,11c7.4,7.4,11,16.2,11,26.7s-3.7,19.3-11,26.7
	c-7.4,7.4-16.2,11-26.7,11s-19.3-3.7-26.7-11C614.6,384.3,610.9,375.4,610.9,365L610.9,365z"
          />
          <path
            fill="none"
            d="M621.9,391.6c7.4,7.4,16.2,11,26.7,11s19.3-3.7,26.7-11c7.4-7.4,11-16.2,11-26.7c0-10.5-3.7-19.3-11-26.7
	c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11c-7.3,7.4-11,16.2-11,26.7C610.9,375.4,614.6,384.3,621.9,391.6L621.9,391.6z
	 M623.3,339.6c3.2-3.2,6.7-5.8,10.6-7.4l1.7-0.7c4-1.5,8.4-2.3,13.1-2.3c4.5,0,8.8,0.7,12.8,2.2l1.7,0.7c4,1.8,7.6,4.3,10.9,7.6
	l1.2,1.2l1.2,1.4c5.4,6.5,8.1,14,8.1,22.8v1.8c0,0.5,0,1.2-0.1,1.8c-0.4,5.2-1.9,10-4.5,14.3l-1,1.5c-1.4,2.1-3,4-4.8,5.9
	c-4.7,4.7-10,7.8-16.1,9.4l-1.8,0.4c-2.3,0.5-5,0.7-7.6,0.7s-5.1-0.3-7.4-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4
	c-1.8-1.8-3.4-3.7-4.8-5.8l-1-1.5c-2.6-4.3-4.1-9.1-4.5-14.2l-0.1-1.8v-1.9c0-8.7,2.6-16.1,8-22.6l1.2-1.4L623.3,339.6L623.3,339.6z
	"
          />
          <path
            fill="none"
            d="M621.9,341l9.1,6.1c1.8-1.8,3.7-3.2,5.8-4.3l-2.9-10.6c-3.8,1.8-7.4,4.3-10.6,7.4L621.9,341L621.9,341z"
          />
          <path
            fill="none"
            d="M631,347l-9.1-6.1l-1.2,1.4l8.8,6.3l9.4,6.9l0.7,0.5c2.9,2.2,4.1,4.7,3.6,7.6c-1.4,2.2-3.7,3.3-7,3.3h-23.4
	l0.1,1.8l10.9,0.5l2.1,0.1c1.4,0.1,2.2,0.5,2.8,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.7,1.4l-8.5,6.9l1,1.5
	l8.8-6.3l9.4-6.9l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1c1.7,1.9,2.1,4.5,1.1,7.7l-0.3,1l-3.6,11.1l-3.3,10.3l1.8,0.4l3.8-10.3l0.8-2.1
	c0.5-1.2,1.2-1.9,2.1-2.2h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1l3.8,10.3l1.8-0.4l-3.4-10.3l-3.6-11.1l-0.4-1c-1.1-3.4-0.5-6.2,1.5-8.3
	c2.3-1,5-0.5,7.7,1.4l1,0.7l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7
	c0.5-0.8,1.4-1.2,2.8-1.4l2.2-0.1l10.9-0.5c0-0.6,0.1-1.2,0.1-1.8h-23.4c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5
	l9.5-6.9l8.8-6.3l-1.2-1.4l-9.2,6.1l-1.8,1.2c-1.1,0.7-2.1,0.8-3,0.5c-0.3-0.1-0.4-0.3-0.7-0.4l-0.5-0.6c-0.5-0.7-0.8-1.6-0.4-3
	l0.5-2.1l2.9-10.6l-1.7-0.7l-3.3,10.3l-3.6,11.1l-0.3,1c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1l-3.6-11.1
	l-3.3-10.3l-1.7,0.7l2.9,10.6l0.5,2.1c0.3,1.2,0.1,2.2-0.4,3c-0.1,0.1-0.4,0.4-0.6,0.5l-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5L631,347
	L631,347z M648.6,360.1c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4s-2.5-0.5-3.4-1.4
	c-1-1-1.4-2.1-1.4-3.4c0-1.4,0.5-2.5,1.4-3.4C646.1,360.6,647.4,360.1,648.6,360.1L648.6,360.1z"
          />
          <path
            fill="none"
            d="M645.2,361.6c-1,1-1.4,2.1-1.4,3.4s0.5,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4c1-0.8,1.4-2.1,1.4-3.4
	s-0.5-2.5-1.4-3.4c-0.8-1-2.1-1.4-3.4-1.4C647.4,360.1,646.1,360.6,645.2,361.6L645.2,361.6z M632.8,348.1c1.1,0.7,2.1,0.8,3,0.5
	l0.7-0.4c0.3-0.1,0.4-0.3,0.5-0.5c0.5-0.7,0.8-1.6,0.4-3l-0.5-2.1c-2.1,1.1-4,2.6-5.8,4.3L632.8,348.1L632.8,348.1z M620.7,342.5
	c-5.4,6.5-8,13.9-8,22.6v1.9h10.7V365c0-6.2,1.9-11.7,5.9-16.2L620.7,342.5L620.7,342.5z"
          />
          <path
            fill="none"
            d="M623.6,367h11.7l-0.1-1.9c0-3.7,1.2-6.7,3.7-9.4l-9.4-6.9c-4,4.5-5.9,10-5.9,16.2L623.6,367L623.6,367z
	 M628.7,370.7c-0.4-0.8-1.4-1.2-2.6-1.4l-2.1-0.1c0.4,2.5,1.1,4.8,2.2,6.9l1.7-1.4c1-0.8,1.5-1.8,1.4-2.6c0-0.3-0.1-0.5-0.1-0.7
	L628.7,370.7L628.7,370.7z M627.2,378.3l-8.8,6.3c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4l3.3-10.3c-4.4-1.1-8.4-3.3-11.8-6.7
	C629.5,381.3,628.3,379.8,627.2,378.3L627.2,378.3z"
          />
          <path
            fill="none"
            d="M630.9,382.8c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2l-9.4,6.9
	C628.3,379.8,629.5,381.3,630.9,382.8L630.9,382.8z M623.8,369.3l-10.9-0.5c0.5,5.2,2.1,9.9,4.5,14.2l8.5-6.9
	C625,374,624.3,371.8,623.8,369.3L623.8,369.3z M635.5,331.5l3.3,10.3c3-1.2,6.2-1.9,9.8-1.9c3.4,0,6.5,0.5,9.4,1.8l3.3-10.3
	c-4-1.5-8.3-2.2-12.8-2.2C643.9,329.1,639.7,329.8,635.5,331.5L635.5,331.5z"
          />
          <path
            fill="none"
            d="M639,341.7l3.6,11.1c1.9-1,3.9-1.4,6.2-1.4c2.1,0,4.1,0.4,5.8,1.2l3.6-11.1c-2.9-1.1-6.1-1.8-9.4-1.8
	C645.2,339.9,641.9,340.4,639,341.7L639,341.7z M659.6,344.7c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5c0.3,0.1,0.4,0.3,0.7,0.4
	c0.8,0.4,1.9,0.3,3-0.5l1.8-1.2c-1.8-1.8-3.7-3.2-5.9-4.3L659.6,344.7L659.6,344.7z M657.4,356c-2.6,2.1-3.9,4.3-3.7,6.9
	c1.2,2.6,3.7,3.9,7.3,4h1.1l0.1-1.8c0-3.7-1.4-6.9-4-9.6L657.4,356L657.4,356z M657.6,360.9l0.8,1.8l-1.2,1.6l-1.9-0.3l-0.8-1.8
	l1.2-1.6L657.6,360.9L657.6,360.9z"
          />
          <path
            fill="none"
            d="M654.4,362.3l0.8,1.8l2.1,0.3l1.1-1.7l-0.8-1.8l-1.9-0.3 M666.1,346.9l9.2-6.1l-1.2-1.2
	c-3.3-3.3-6.9-5.8-10.9-7.6l-2.9,10.6C662.2,343.7,664.3,345.1,666.1,346.9L666.1,346.9z M658.2,355.4c2.6,2.6,4,5.8,4,9.6l-0.1,1.8
	h11.6V365c0-6.3-2.1-11.8-6.2-16.5L658.2,355.4L658.2,355.4z M654.5,352.7c-1.8-0.8-3.7-1.2-5.8-1.2c-2.2,0-4.3,0.5-6.2,1.4l0.3,1
	c1.1,3.2,2.9,5.1,5.4,5.6c2.8-0.4,4.8-2.3,5.9-5.8L654.5,352.7L654.5,352.7z M647.9,358.6l-1.1-1.7l0.8-1.8l1.9-0.1l1.1,1.7
	l-0.8,1.8L647.9,358.6L647.9,358.6z"
          />
          <path
            fill="none"
            d="M650.7,356.5l-1.2-1.6l-1.9,0.3l-0.8,1.8l1.2,1.7l1.9-0.3 M638.8,355.6c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9h1
	c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6L638.8,355.6L638.8,355.6z M641.2,360.4l1.5,1.4l-0.4,1.9l-1.9,0.5l-1.5-1.4l0.4-1.9
	L641.2,360.4L641.2,360.4z"
          />
          <path
            fill="none"
            d="M638.8,362.8l1.5,1.4l1.9-0.5l0.4-1.9l-1.5-1.4l-1.9,0.5 M673.8,366.7h10.7v-1.8c0-8.7-2.8-16.4-8.1-22.8
	l-8.8,6.3c4.1,4.7,6.2,10,6.2,16.5V366.7z M671.3,369.2c-1.2,0.1-2.2,0.5-2.8,1.4l-0.3,0.7l-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6l1.8,1.5
	c1.1-2.2,1.8-4.5,2.2-7L671.3,369.2L671.3,369.2z"
          />
          <path
            fill="none"
            d="M673.5,369c-0.4,2.5-1.1,4.8-2.2,7l8.5,6.9c2.6-4.4,4.1-9.1,4.5-14.3L673.5,369L673.5,369z M670.2,378.1
	c-1.1,1.6-2.2,3.2-3.7,4.7c-3.4,3.4-7.4,5.6-11.8,6.7l3.4,10.3c6.1-1.5,11.4-4.7,16.1-9.4c1.9-1.9,3.4-3.9,4.8-5.9L670.2,378.1
	L670.2,378.1z M636.6,371.4c0.7,1.1,1.5,2.2,2.5,3.2c2.1,2.1,4.5,3.3,7.3,3.9l0.3-1c1-3.2,0.5-5.8-1.1-7.7c-2.5-1.4-5.4-1-8.3,1.1
	L636.6,371.4L636.6,371.4z M641.9,370.5l1.7-1l1.8,1v2.1l-1.8,1l-1.7-1L641.9,370.5L641.9,370.5z"
          />
          <path
            fill="none"
            d="M643.7,373.6l1.8-1.1v-1.9l-1.8-1l-1.8,1v1.9 M651,378.4c2.8-0.4,5.2-1.6,7.3-3.9c1.1-1.1,1.9-2.2,2.5-3.3
	l-1-0.7c-2.8-1.9-5.4-2.3-7.7-1.4c-2.1,2.1-2.5,4.7-1.5,8.3L651,378.4L651,378.4z M654.8,370.3l0.4,1.9l-1.5,1.4l-1.9-0.5l-0.4-1.9
	l1.5-1.4L654.8,370.3L654.8,370.3z"
          />
          <path
            fill="none"
            d="M651.5,371.1l0.4,1.9l1.8,0.5l1.5-1.2l-0.4-2.1l-1.9-0.5 M658.2,374.5c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7l-9.4-6.9C660.2,372.5,659.3,373.6,658.2,374.5L658.2,374.5z M652.3,389.9l-3.7,0.3
	l-3.6-0.3l-3.9,10.3c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7L652.3,389.9L652.3,389.9z"
          />
          <path
            fill="none"
            d="M651.5,387.9c-0.4-1.2-1.1-1.9-2.1-2.2h-1.5c-1,0.3-1.7,1-2.1,2.2l-0.8,2.1l3.6,0.3l3.7-0.3L651.5,387.9
	L651.5,387.9z M586.2,395l0.1,0.1h-14.4l-6.3,1.9l-42.1-2.2l3.6,5.2h62.2l2.2-5.8"
          />
          <path
            fill="none"
            d="M585.1,395.2h1.2l-0.1-0.1 M534.8,353.5c0.1,1.1,0.1,2.3,0.3,3.4c0.1,1.9,0,3.9-0.4,5.8l19.4,0.5
	c12.9,0.4,16.1-1,9.5-4c-6.5-2.8-16.1-4.8-28.8-6.3L534.8,353.5L534.8,353.5z M527.3,379.8h-4.5C524.5,379.9,526,379.9,527.3,379.8
	L527.3,379.8z M465.5,264.2h2.1c-0.1-5.9,1.1-9.4,3.7-10.3c-2.9,1-4.5,2.8-5,5.4L465.5,264.2L465.5,264.2z M327,269.9l150.9,4.1
	l47.2,0.8l-9.2-7l0.1,0.3c0.1,0.3,0.1,0.4,0,0.7c0,0.3-0.1,0.4-0.4,0.5l-0.7,0.1l-10.9-0.1c1.8,0.5,2.9,1.5,3.3,2.9
	c-0.8,0.4-1.7,0.7-2.6,1c-1.8,0.5-4,0.7-6.5,0.7h-10.6l-3-2.8c-7.3,0.1-13.1-0.5-17.1-1.8c-0.3-0.1-0.4-0.1-0.5-0.3l-84.9-0.7
	c-17.9-0.1-35.6-0.7-53.4-1.5L327,269.9L327,269.9z"
          />
          <path
            fill="none"
            d="M505,273.3c-4.3-1.8-10.9-3.2-20.1-4c-5.4-0.5-11.6-0.8-18.6-1.1c0.3,0.4,0.5,0.7,1,0.8
	c0.1,0.1,0.4,0.3,0.5,0.3c4,1.2,9.8,1.9,17.1,1.8l3,2.8h10.6C501,274.1,503.2,273.8,505,273.3z"
          />
          <path
            fill="none"
            d="M329.1,267c17.6,0.8,35.5,1.4,53.4,1.5l84.9,0.7c-0.4-0.3-0.7-0.5-1-0.8c-0.1-0.3-0.3-0.5-0.4-0.8l-83.5-0.7
	c-17.7-0.1-35.4-0.5-52.8-1.5L329.1,267L329.1,267z"
          />
          <path
            fill="none"
            d="M504.3,269.4c-0.4-0.1-1-0.3-1.5-0.4c-1.5-0.3-3.2-0.5-4.8-0.8c-1.5-0.3-3-0.4-4.5-0.5
	c-4.5-0.4-9.6-0.7-15.1-0.8h-12.4c0,0.1,0.1,0.4,0.1,0.5l0.4,0.8c7,0.1,13.2,0.5,18.6,1.1c9.1,0.8,15.8,2.2,20.1,4
	c1-0.3,1.8-0.5,2.6-1C507.1,271.1,506,270.1,504.3,269.4L504.3,269.4z"
          />
          <path
            fill="none"
            d="M497.8,268.2l0.5-0.5h-5.1C494.8,267.8,496.5,267.9,497.8,268.2z M498.4,267.6c0.5-0.7,1-1.2,1-1.9
	c-8.7-1.2-19.3-1.8-31.9-1.5h-2.1c0,1,0,1.9,0.3,2.6H478c5.5,0,10.6,0.3,15.1,0.8L498.4,267.6L498.4,267.6z"
          />
          <path
            fill="none"
            d="M238.8,249.6c2.9,3.4,5.6,6.2,8.4,8.3c3.3,2.3,8.5,3.6,15.3,4c22,2.3,44.2,4,66.4,5.1l0.5-1.8l-20.5-1.1
	c-15.4-1-30.8-2.3-46.1-3.9l-0.3-0.1c-3.7-0.5-7-1.9-9.9-4.3c-2.2-1.5-4.4-3.6-6.7-6.1c-1.9-1.9-1.7-3.4,0.7-4.5l5.6-1.8
	c14.3-4.4,29-8.3,44.4-11.4l8.8-1.6l9.1-1.8c23-4.3,46.4-6.6,70.3-7.3c23.9,0.1,45.5,4.1,64.7,12c7.3,3,14.4,6.3,21.6,10
	c5.8,3,11.6,6.2,17.3,9.5c7.7,4.5,15.4,9.5,23,14.7l-13.2-0.1c-0.1,0.1-0.4,0.4-0.6,0.5c1.7,0.3,3.3,0.5,4.8,0.8
	c0.5,0.1,1.1,0.3,1.5,0.4l10.9,0.1l0.7-0.1c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.3,0.1-0.4,0-0.7l-0.1-0.3l-0.3-0.3
	c-12.9-9.1-25.9-17.1-38.9-24.1c-8.8-4.7-17.6-8.8-26.4-12.5c-19.4-8-41.1-12-65.5-12.2c-27.2,0.7-53.8,3.7-79.8,9.1
	c-21,4-41.1,9.1-60.1,15.3l-5.4,1.9C237.4,245.9,237.2,247.6,238.8,249.6L238.8,249.6z"
          />
          <path
            fill="none"
            d="M384.9,221.4c-23.9,0.6-47.3,3-70.3,7.3l14.9,36.6c17.5,0.8,35.1,1.4,52.8,1.5l83.5,0.7c0-0.1-0.1-0.4-0.1-0.5
	c-0.1-0.7-0.3-1.6-0.3-2.6l0.8-5c0.4-2.6,2.1-4.4,5-5.4c1.2-0.4,2.8-0.7,4.5-0.8c4.1-0.6,8,0,11.8,1.5c0.3,0.1,0.5,0.4,1,0.6v-2.2
	c-5.8-3.4-11.6-6.6-17.3-9.5c-7.2-3.7-14.4-7-21.6-10C430.6,225.6,409,221.6,384.9,221.4L384.9,221.4z M499.4,265.7
	c-0.1,0.7-0.4,1.2-1,1.9l13.2,0.1c-7.7-5.4-15.3-10.2-23-14.7v2.2c4.3,2.8,7.4,5.4,9.6,7.7C499.1,263.9,499.5,264.8,499.4,265.7
	L499.4,265.7z"
          />
          <path
            fill="none"
            d="M498.3,263c-2.2-2.3-5.4-5-9.6-7.7c-0.3-0.1-0.5-0.4-1-0.6c-3.7-1.6-7.7-2.1-11.8-1.5c-1.8,0.1-3.3,0.4-4.5,0.8
	c-2.6,1-3.9,4.4-3.7,10.3c12.5-0.1,23.1,0.3,31.9,1.5C499.5,264.8,499.1,263.9,498.3,263L498.3,263z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M755.4,355v0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M714.1,309.3l3.2,1.6c3,1.2,5.6,1.8,7.8,1.6c-2.6-2.9-4-5.8-3.9-8.8l-16-1.5h-0.1l1,0.8L714.1,309.3L714.1,309.3z M729.4,300.8
	l-0.5,12.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M714,311.1c3.2,2.3,6.2,3.6,9.1,3.9l9.6,1.2l-4-2.8 M728.8,313.6l-0.7-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M728.1,313.2c-0.1,0.3-0.4,0.4-0.5,0.5c-2.8,1.1-6.5,0.7-11-1.2l-2.5-1.2 M738.2,316.8c-2.1-0.1-2.8-0.5-2.2-1.4l4.3-8.8
	c1-1.6,2.2-2.2,4.1-1.6L738.2,316.8L738.2,316.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M728.1,313.2c0.1-0.1,0.1-0.4,0-0.5c0-0.3-0.1-0.4-0.3-0.6c-3.3-3-4.8-5.9-4.4-9.1v-0.5L723,302c-0.1-0.1-0.4-0.1-0.5-0.1l-16.8-1.5
	l-1.8,0.1c-0.8,0.3-1.2,0.7-1.1,1.5c-0.1,0.7,0.5,1.6,2.1,3l8.1,5.9l0.8,0.5 M744.5,317c-2.1-0.1-2.8-0.5-2.2-1.4l4.3-8.8
	c1-1.6,2.2-2.2,4.1-1.6L744.5,317L744.5,317z M757.6,305.9c-1.8-0.5-3.2,0-4.1,1.6l-4.3,8.8c-0.5,0.7,0.3,1.1,2.2,1.4L757.6,305.9
	L757.6,305.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.7,216.9c-13.8,2.9-26.8,6.3-39.1,10c-18.7,5.1-37.3,10.6-56,16.5
	c-12.5,4-25,8.3-37.7,12.7 M516,267.8l-0.3-0.3c-12.9-9.1-25.9-17.1-38.9-24.1c-8.8-4.7-17.6-8.8-26.4-12.5
	c-19.4-8-41.1-12-65.5-12.2c-27.2,0.7-53.8,3.7-79.8,9.1c-21,4-41.1,8.9-60.1,15.3l-5.4,1.9c-2.3,1.1-2.6,2.6-0.8,4.7
	c2.9,3.4,5.6,6.2,8.4,8.3c3.3,2.3,8.5,3.6,15.3,4c22,2.3,44.2,4,66.4,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M296.9,232.2c-15.4,3.2-30.1,6.9-44.4,11.4l-5.6,1.8
	c-2.3,1.1-2.6,2.5-0.7,4.5c2.3,2.5,4.7,4.5,6.7,6.1c2.9,2.2,6.2,3.7,9.9,4.3l0.3,0.1c15.3,1.5,30.7,2.9,46.1,3.9 M169.2,324.2l3-3.2
	l3-3c13.5-12.4,29.9-18.4,49-18.4c19.1,0,35.5,6.2,49,18.4c1.1,1,2.1,1.9,3,3c3.4,3.6,6.3,7.3,8.8,11.1c3.6,5.8,6.1,12.1,7.3,18.8
	c1.8,9.9,2.9,22.1,3,36.6 M282.3,223.5l-0.4,0.1c-24.9,5.5-50.6,12.1-77.2,19.9c-12.1,3.4-24.5,7.3-36.9,11.3
	c-8.3,2.1-16.5,2.5-24.9,1.4 M314.6,228.7l-8.9,1.7h-0.1l-8.7,1.8 M283.7,223.2l-1.4,0.3 M283.7,223.2l-1.4,0.3 M488.6,253.1
	c-5.8-3.4-11.6-6.6-17.3-9.5c-7.2-3.7-14.4-7-21.6-10c-19.1-7.8-40.6-11.8-64.7-12c-23.9,0.6-47.3,3-70.3,7.3 M348.9,296l3.4,0.1
	l7.8-0.4 M360.1,295.7c2.2-0.3,4.3-0.5,6.2-1.1c1.5-0.4,2.8-0.8,3.7-1.2 M370,288.8c-1-0.5-2.2-1-3.7-1.4c-1.9-0.5-4-0.8-6.2-1.1
	 M360,286.4l-7.7-0.4l-3.6,0.1 M360,286.4c1,0.8,1.6,1.5,2.2,2.3 M362.3,293.4c-0.4,0.8-1.1,1.5-2.1,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M370,293.4c0.7-0.3,1.1-0.7,1.5-1c0.4-0.4,0.5-0.8,0.5-1.2
	s-0.1-0.8-0.5-1.2c-0.3-0.4-0.8-0.7-1.5-1 M370,293.4h-7.7 M362.2,288.7h7.3l0.5,0.1 M329.1,267l-2.1,2.9 M329.5,265.2l-0.4,1.8
	 M346.2,293.4h-11.7 M334.6,288.8c-0.8,0.4-1.5,1-1.8,1.4c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.5,1,1,1.8,1.4
	 M334.6,293.4c1,0.4,2.2,0.8,3.7,1.2c3,0.8,6.5,1.2,10.6,1.4 M345.8,288.7c0.5-1,1.5-1.8,2.9-2.6 M334.6,288.8l11.3-0.1
	 M348.7,286.1c-4,0.1-7.4,0.5-10.5,1.4c-1.5,0.4-2.8,0.8-3.7,1.2 M327,269.9c-5.5,8.7-7.7,17.3-6.5,26c1.8,8.7,4.1,17.1,6.9,25.2
	c3,8.9,6.5,17.5,10.5,25.9 M309,264.1l20.5,1.1 M450.8,214.6c-13.1-5-29.2-8.1-48-9.6c-27.6-1.1-54.3,0.1-80.2,3.9
	c-16.5,2.2-32.2,4.8-47,8 M329.1,267c17.6,0.8,35.5,1.4,53.4,1.5l84.9,0.7 M296.9,232.2l12.1,31.9 M329.5,265.2
	c17.5,0.8,35.1,1.4,52.8,1.5l83.5,0.7 M314.6,228.7l14.9,36.5 M362.2,288.7h-16.4 M348.9,296c-1.1-1-2.1-1.8-2.6-2.6 M346.2,293.4
	h16.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M446.3,221.6c-19.7-6.9-43.5-10.3-71.5-10.2c-30.7,1.1-61.1,5-91.1,11.8
	 M287.2,387.6c0-11.6-0.7-21.9-2.2-30.8v-0.4c-2.1-12-7.8-22.6-17.1-31.6c-1.4-1.4-2.9-2.8-4.3-3.9c-11.1-9.2-24.2-13.8-39.1-13.8
	c-15,0-28.1,4.5-39.1,13.8c-1.5,1.2-2.9,2.5-4.3,3.9 M343.5,357.5l3.4,5.9c5.4,9.2,14.9,13.9,28.1,14l147.7,2.1 M337.9,346.8
	c1.8,3.6,3.6,7.2,5.6,10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M337.9,346.8c-9.8,1.5-18.7,3.7-26.5,7c-4.1,2.1-0.7,3,10.6,3.2l21.6,0.5
	 M280.5,394.9l0.3,5.2h39.6v-5.2 M295.4,387.6h-8.1 M287.4,394.9h-6.9 M287.2,387.6v2.3 M287.2,389.9l0.1,5 M295.4,387.6h1.2
	c15.1-3.2,30.1-4.7,45.3-4.4l202.2,3.6l10.3,0.6l8.4,0.8l15.3,2.2 M287.4,394.9h33 M534.7,353.1c-5.4-0.7-11.3-1.1-17.6-1.6
	l-157-6.6c-7.8,0.1-15.3,0.8-22.3,1.8 M181,324.9c-7.4,7.3-12.5,15.4-15.4,24.6c-0.8,2.8-1.5,5.6-1.9,8.5c-1,7.3-1.5,16-1.5,25.9
	 M154.1,383.2c-0.1-12,0.4-22.1,1.8-30.7c0.6-3,1.2-6.1,2.2-8.9c2.3-7,6.1-13.5,11.3-19.4 M139.1,355c1.1-1.2,0.4-1.9-1.9-2.1
	l-27.5-1.6c-1.9,0.1-1.9,1,0,2.5c1.5,1.4,2.9,2.1,4.1,2.1l20.6,1.4C136.6,357.1,138.1,356.4,139.1,355L139.1,355z M208.7,332.2
	c-3.9,1.8-7.4,4.3-10.6,7.4l-1.4,1.4 M204.3,348.8l-8.8-6.3 M196.7,341l-1.2,1.5 M196.7,341l9.1,6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M269.1,394.9c2.9-4.1,5-8.7,6.5-13.5c1.6-5.1,2.3-10.6,2.3-16.4
	c0-15-5.2-27.8-16-38.4c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3s-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6
	c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2c0,10.5,2.6,19.9,7.8,28.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.5,342.5c-5.4,6.5-8,13.9-8,22.6v1.9 M187.6,367l0.1,1.8 M204.3,348.8
	c-4,4.5-5.9,10-5.9,16.2v1.9 M198.6,369.3l2.1,0.1c1.4,0.1,2.2,0.5,2.6,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6
	l-1.6,1.4 M202.1,378.3c1,1.6,2.2,3.2,3.7,4.5c3.4,3.4,7.4,5.6,11.8,6.7 M192.3,383.1l1,1.5 M200.8,376.2c-1.1-2.2-1.8-4.4-2.2-6.9
	 M193.3,384.6l8.8-6.3 M200.8,376.2l-8.5,6.9 M176.9,393.2c2.2,3.6,5,6.9,8.1,10.2c10.6,10.6,23.4,16,38.4,16c15,0,27.8-5.2,38.4-16
	c2.8-2.8,5.1-5.5,7.2-8.5 M187.8,368.8c0.6,5.2,2.1,9.9,4.5,14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M193.3,384.6c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4 M187.6,367
	h10.7 M187.8,368.8l10.9,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M185.9,365c0,10.5,3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11
	c10.5,0,19.3-3.7,26.7-11c7.3-7.4,11-16.2,11-26.7s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11
	C189.4,345.6,185.8,354.6,185.9,365L185.9,365z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M183.6,365c0,11,3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7
	c11,0,20.4-3.9,28.2-11.7c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7c-11,0-20.4,3.9-28.2,11.7
	C187.5,344.5,183.6,354,183.6,365L183.6,365z M162.2,383.9l-8.1-0.7 M162.2,388.7v-4.8 M154.1,383.2l-5.8-0.4
	c-11.1-3.2-26.1-5.9-44.8-8.1c-4.1-1.1-8.5-3.3-13.1-6.6c-4-2.8-8.1-6.7-12.4-11.7c-1.7-2.1-4.7-3.4-8.9-4H52.5 M162.2,388.7
	l14.7,4.5 M181,324.9l-11.8-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.2,331.3c-4-1.5-8.3-2.2-12.8-2.2c-4.7,0-9.1,0.8-13.1,2.3
	 M210.5,331.5l-1.8,0.7 M232.9,341.7c-2.9-1.1-6.1-1.8-9.4-1.8c-3.4,0-6.7,0.5-9.8,1.9 M213.8,341.7l-3.3-10.2 M238,332l-1.8-0.7
	 M236.2,331.3l-3.3,10.3 M240.9,346.9l-1.8,1.2c-1.1,0.7-2.1,0.8-3,0.5l-0.7-0.4l-0.6-0.5c-0.6-0.7-0.8-1.6-0.4-3l0.6-2.1
	 M235,342.6c2.1,1.1,4.1,2.5,5.9,4.3 M225.5,356.5l-1.1-1.6l-2.1,0.3l-0.8,1.8l1.2,1.7l1.9-0.3L225.5,356.5z M230.4,360.6l-1.1,1.6
	l0.8,1.8l1.9,0.3l1.2-1.7l-0.8-1.8L230.4,360.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237,366.7h-1.1c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5
	 M229.3,352.7l-0.3,1c-1.1,3.4-3.2,5.4-5.9,5.8c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1 M205.8,347l1.6,1.1c1.1,0.7,2.1,0.8,3,0.5
	c0.3-0.1,0.4-0.3,0.7-0.4l0.6-0.5c0.6-0.7,0.8-1.6,0.4-3l-0.6-2.1 M223.5,360.1c-1.4,0-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4
	c0,1.4,0.4,2.5,1.4,3.4s2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4c0-1.4-0.6-2.5-1.4-3.4
	C226,360.6,224.8,360.1,223.5,360.1L223.5,360.1z M217.5,361.7l-1.5-1.4l-1.8,0.5l-0.4,1.9l1.4,1.4l1.9-0.5L217.5,361.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M210.1,367h1c3.3,0,5.6-1.1,7-3.3c0.4-2.8-0.7-5.4-3.6-7.6l-0.7-0.5
	 M217.4,352.8c1.9-1,3.9-1.4,6.2-1.4c2.1,0,4.1,0.4,5.8,1.2 M211.7,342.8l-3-10.6 M217.4,352.8l-3.6-11.1 M235,342.6l3-10.6
	 M232.9,341.7l-3.6,11 M250.1,340.8l-1.2-1.2c-3.3-3.3-7-5.8-10.9-7.6 M242.5,348.5l8.7-6.3 M251.2,342.2l-1.1-1.4 M246.1,376.1
	l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7c0.6-0.8,1.4-1.2,2.8-1.4l2.2-0.1 M242.5,348.5c4.1,4.7,6.2,10,6.2,16.5v1.8
	 M259.3,368.6c0.1-0.5,0.1-1.2,0.1-1.8 M259.3,366.7v-1.8c0-8.7-2.8-16.4-8.1-22.8 M253.7,384.4l1.1-1.5 M248.3,369
	c-0.4,2.5-1.1,4.8-2.2,7 M254.8,382.9c2.6-4.4,4.1-9.1,4.5-14.3 M259.3,366.7h-10.7 M254.8,382.9l-8.7-6.9 M245,378.1l8.7,6.3
	 M248.3,369l11-0.4 M232.9,399.7c6.1-1.5,11.4-4.7,16.1-9.4c1.9-1.9,3.4-3.9,4.8-5.9 M211.4,371.4l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1
	c1.6,1.9,2.1,4.5,1.1,7.7l-0.3,1 M213.6,355.6c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M221.2,378.4c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2 M220.2,370.5
	l-1.8-1l-1.6,1v1.9l1.6,1.1l1.8-1.1V370.5z M228.6,373.6l1.4-1.2l-0.4-2.1l-1.8-0.5l-1.5,1.4l0.4,1.9L228.6,373.6z M233,355.4
	c2.6,2.6,4,5.8,4,9.6l-0.1,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.9,378.4l-0.3-1c-1.1-3.4-0.6-6.2,1.5-8.3c2.3-1,5-0.5,7.7,1.4l1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235.6,371.2c-0.7,1.2-1.5,2.3-2.5,3.3c-2.1,2.1-4.5,3.3-7.3,3.9
	 M231,400.1l1.9-0.4 M232.9,399.7l-3.4-10.2 M227.1,389.9l3.9,10.2 M219.8,389.9l0.8-2.1c0.4-1.2,1.2-1.9,2.1-2.2h1.5
	c1,0.3,1.6,1,2.1,2.2l0.8,2.1 M214.2,399.9l1.8,0.3 M216,400.1c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7 M217.6,389.5l-3.4,10.3
	 M216,400.1l3.9-10.2 M219.8,389.9l3.7,0.1l3.6-0.1 M229.5,389.5c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7 M221.2,378.4
	l-3.6,11.1 M229.5,389.5l-3.6-11.1 M233,355.4l9.5-6.9 M248.6,366.7H237 M235.6,371.2l9.4,6.9 M250.1,340.8l-9.2,6.1 M198.4,367
	h11.7 M213.6,355.6l-9.4-6.7 M211.7,342.8c-2.1,1.1-4,2.6-5.8,4.3 M202.1,378.3l9.4-6.9 M269.1,394.9h11.4 M275.7,216.9l8,6.3
	 M294.7,274.1c-51.3-6.3-104-7.4-157.8-3.6c-15,1.4-28.9,0.5-41.8-2.6l-12.4-3 M82.5,264.9c-2.9-0.5-5.1-0.5-6.5,0l-0.8,0.5
	c-1,0.7-1.8,1.6-2.3,2.8c-1.7,3.2-1.5,5,0.3,5.5 M73.2,273.7c-0.4,0.7-0.1,1.5,0.8,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M142.9,256.2c-3.4-1.5-8.9-0.6-16.5,2.9c-5.5,2.3-12.4,4.3-20.8,5.4
	c-5.9,1.2-13.6,1.5-23.1,0.5 M73.9,276.2l1.1,1.1l3,5.1 M78,282.4l0.3,0.4l-2.2,3.4 M76.1,286.2c-1.4-1-2.5-0.8-3.4,0.5L63.6,300
	c-1.1,0.8-0.7,1.8,1.4,3 M88.7,282l-0.3,0.8l-15,19.4c-0.8,1.1-1,1.9-0.6,2.3 M88.7,282l-4.4-1.9l-1.1,2.5l-0.7,0.8 M94.4,283.6
	l-5.2-2.5l-0.4,0.8 M95.5,282.1l-1.1,1.5 M97.1,283.9l-1.1-2.2c-0.8-1.5-2.2-2.6-4.1-3.2L73,273.8 M97.1,283.9
	c-0.6-0.7-1.1-1.2-1.7-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M95.5,282.1c-1.2-1-2.8-1.6-4.5-2.2l-17.1-3.7 M95.7,296.5
	c3.2-2.9,4.3-5.9,3.2-9.1l-1-1.8 M98.1,285.7l-1-1.8 M95.7,296.5l-10.9,10h-0.1c-1,0.8-3,1.2-6.1,1.2l-17.6,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M73,304.5c-1.1,1.1-2.8,1.4-4.8,1.2c-1.5-0.5-1.7-1.4-0.8-2.6l15.1-19.7
	 M73,304.5l0.6,0.1c1.5,0.4,3.6,0,6.2-1.2 M82.5,283.3l-4.5-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M79.7,303.4c0.8,1.5,2.8,1.5,5.9-0.3l12.5-17.5 M76.1,286.2L64.9,303
	 M94.4,283.6l-14.7,19.8 M169.2,324.2l-35.9-2.1c-3.4,0.4-6.6,0-9.5-1.1c-1.8-0.8-3.4-1.8-5.1-3.3l-23-21.2 M64.9,303l-5,3
	c-1.9,1-1.5,1.8,1.2,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M61.1,308.4l-3.7,0.1c-1.1,1.2-1.9,2.3-2.8,3.3c-1,1.4-1.7,2.5-1.9,3.6
	c-0.4,1.2,0.1,2.2,1.9,2.8l1.4,0.4l3.2,10l-3.3,11.7c-0.5,2.5-2.1,4.7-4.3,6.3l-0.5,4.1c0,1.1,0.4,1.8,1.4,1.9 M82.3,378.7l-18.8-18
	c-1.1-1.1-2.6-1.9-4.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M58.9,358.3l-1.1,2.2c-0.7,1.8,0,3.2,2.2,4.1l6.7,4l12.9,12 M50.5,354.3
	l1.1,1.1l1.8,0.1 M53.4,355.6l-0.1,2.2 M53.2,357.8c2.2,0,4.1,0.1,5.6,0.5 M52.5,352.4c-1.9,0.4-2.6,1.1-2.1,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M53.2,357.8c3.7,4.1,6.1,9.4,7.4,15.8c6.6,2.9,12.9,5.2,19,7 M81.8,367.7
	l-0.3-0.3l-2.2-2.3c-1-1-1.7-1.4-2.2-1.5h-0.6c-0.4,0.3-0.1,1.2,0.7,3l0.6,1.2 M76.3,360.5l-1.4-1.6c-0.7-0.7-1.4-1.4-2.2-1.9
	 M72.8,356.8l-7-0.4 M72.8,356.8c-2.1-1.4-4.8-2.2-8.1-2.5l-14.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M65.8,356.4c-1.2,0.3-1.2,1.1,0,2.2l1.1,1.4c0.6,0.4,1.2,0.7,2.2,0.5h7.4
	 M76.5,367.7h-0.4c-0.4,0.3-0.1,1.1,0.6,2.8c0.7,1.5,1.8,3.3,3.3,5.2l2.5,3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M86.1,381.7h0.1c0.4-0.3,0.1-1.1-0.6-2.8c-0.4-1-1.1-1.9-1.8-3l-1.5-2.1
	c-0.7-1-1.4-1.6-1.9-2.5l-2.1-2.2c-1-0.8-1.5-1.2-2.1-1.4 M78,367.7h-1.5 M82,367.7h-0.1 M91.5,378.7c1.7,1.5,2.2,2.8,1.7,4
	 M96.4,380.7c-1.9-0.5-3.7-1.4-5-2.2 M93,382.5l3.4-1.8 M82.3,378.7l1.5,1.5c1,0.8,1.7,1.4,2.1,1.4H86"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M79.7,380.7c3,1,5.9,1.6,8.8,2.3l0.4-1.1 M88.9,382l-2.8-0.3 M88.9,382
	l4.1,0.5 M91.5,378.7c-0.7-0.4-1.4-1-1.9-1.5l-7.4-9.4 M81.8,367.7H78 M96.4,380.7c1.7,0.4,3.4,0.8,5.4,1.1l60.4,6.7 M76.3,360.5
	l5.6,7.2 M53.4,355.4l12.4,1 M778.2,314.4l1.5-4.3c1.1-3.4,3.4-5.8,7-6.9c2.1-1.2,2.3-2.6,0.4-4.1c-3.6-2.2-7.3-4.1-11.1-6.1
	 M776,293.1c-1.5,0.8-3,1.9-4.5,3.3 M764.6,305.1l-6.3,12.2c-1.2,2.2-4,3.2-8.4,2.9l-27.1-3.6c-4.3-0.8-8.3-2.3-12.1-4.8l-14.4-11.3
	 M741.5,302c7.7,0.8,15.4,1.8,23.1,3 M771.5,296.3c-2.3,2.2-4.7,5.1-6.9,8.7 M776,293.1c-10.9-5.4-23-10-36.5-13.8
	c-22.3-6.2-51.9-10.7-88.9-13.9c-30.8-2.1-59.4-2.8-85.7-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.6,305.1c-12-8.3-31.8-15.1-59.3-20.5l-18.4-2.5 M696.2,300.5
	c0.4-1.1,2.2-1.6,5.2-1.8c9.2,0.4,18.6,1.1,27.8,2.1l12.1,1.2 M678.7,313.6c3.6,2.1,7,4.5,10.3,7.4l2.8,2.6
	c9.2,9.1,14.9,19.7,17.1,31.6v0.4c1.4,7.8,2.1,16.6,2.2,26.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M685.9,308.2c3.9,2.3,7.6,5.2,11.1,8.4c1.5,1.4,3,2.8,4.3,4.3
	c2.9,3.2,5.4,6.5,7.6,9.9c3.6,5.8,6.1,12.1,7.3,18.8c1.8,9.6,2.6,20.9,2.6,33.6 M777.1,331.8l-1.1-2.3c-0.5-0.8-0.8-1.8-1.1-2.6
	 M778.2,314.4l-2.6,7.4c-0.8,1.5-1,3.2-0.5,5 M764.2,347.9c2.6,0.3,3.9,1.6,3.9,3.9c0.4,2.2-0.1,3.4-1.9,3.7 M766.2,355.4h-1.4
	 M764.9,355.4l-6.1-0.3 M777.6,358.6c-0.5-0.5-1.2-0.8-2.1-1.1l-10.5-1.1 M765.1,356.4l-0.3-1 M758.8,355.1l-3.4-0.1l-10.9-0.4
	 M744.5,354.6l2.8-3.6c1.8-2.1,5.2-3.2,10-3.2c2.3,0,4.5-0.1,6.6,0 M752.6,371l1.2-0.3l1.4-15.3c-4.5-0.8-7.7,0.1-9.8,2.9
	 M745.6,358.2l-3.8,0.1 M741.7,358.3l2.8-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M745.6,358.2c-0.5,0.7-1,1.5-1.4,2.5c-1.4,3.9-1.1,6.7,1,8.7
	c1.7,1.7,4.1,2.2,7.4,1.5 M752.6,371h4.5c2.5-0.1,3.7-1.9,3.6-5.2c0.1-3.7-0.5-7.3-2.1-10.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M765.1,356.4c1.1,3.9,1.7,7.7,1.7,11.6c0,3.2-1.4,4.7-4.3,4.7l-31.5-0.5
	 M720.8,388.3v5.9h16.9c13.1-0.3,23.2-1.5,30.3-3.7v-5.6 M711.1,382.1v1.5 M718.8,383.2l-7.7,0.4 M718.8,383.2l9.6-1.2
	c2.8-0.4,5.6-0.7,8.4-0.8l36.5-0.8c2.6,0.1,4.3-0.4,5.2-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M731.2,371.9c-3.2,3.9-3.2,5.8,0,5.9l47.5,0.8 M711.1,383.6v5.1l9.8-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M720.8,388.3l13.5-0.7l33.8-2.8 M731.2,371.9l10.6-13.6 M777.6,358.6
	l9.6-1.8c2.3-0.4,3.6-1.2,3.7-2.6c1.1-5.9,1.7-11,1.7-15.4c0.3-1.4-1.1-2.5-4.1-3.2l-5.8-1 M782.9,334.6l-5.8-2.9 M782.9,334.6
	l-5-0.8 M777.9,333.8l-0.8-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M789.9,378.8c0.4-1.6-1.2-3-4.8-4.3c-2.1-0.3-3.2-1.1-3.3-2.5l-2.2-9.4
	c-0.1-1.9-0.8-3.3-1.9-4.3 M777.9,333.8c1.9,5.5,2.9,12.2,2.9,20.2c0,1.1-1.2,1.7-4.1,1.7l-10.5-0.3 M768.2,384.9l12.2-1
	c5.6-0.4,10-1.1,13.1-2.1c1.7-1.1,1.2-2.1-1.4-2.6h-0.3l-1.9-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M778.5,378.8c0.7-0.8,1-2.1,0.8-3.6c1.8,1.8,4.4,2.9,8,3.3l2.6,0.4
	 M774.9,326.8l1.4-1l1.9-11.4 M778.2,349.2c-4.3-0.8-8.9-1.4-14-1.5 M450.8,214.6l-4.8,3.6c-1.8,1.2-1.6,2.3,0.3,3.4 M525.2,274.8
	l-9.2-7 M504.3,269.4l10.9,0.1l0.7-0.1c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.3,0.1-0.4,0-0.7l-0.1-0.3 M499.4,265.7c0.1-1-0.3-1.9-1.1-2.9
	c-2.2-2.3-5.4-5-9.6-7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M505,273.3c1-0.3,1.8-0.5,2.6-1c-0.5-1.2-1.6-2.2-3.3-2.9 M504.3,269.4
	c-0.4-0.1-1-0.3-1.5-0.4c-1.5-0.3-3.2-0.5-4.8-0.8 M497.8,268.2c-1.5-0.3-3-0.4-4.5-0.5 M497.8,268.2l0.5-0.5 M493.3,267.6h5.1
	 M467.3,269.2c0.1,0.1,0.4,0.3,0.5,0.3c4,1.2,9.8,1.9,17.1,1.8l3,2.8h10.6c2.5,0,4.7-0.1,6.5-0.7 M498.4,267.6c0.5-0.7,1-1.2,1-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M498.4,267.6l13.2,0.1c-7.7-5.4-15.3-10.2-23-14.7 M560.6,268.5
	c-3.3-1.8-6.5-3.6-9.6-5.4 M446.3,221.6c12.8,5.9,25.3,12.2,37.4,19c1.8,1.1,3.6,2.1,5.4,3c15.4,8.8,28.9,17.5,40.3,26.1l31.2-1.1
	 M550.9,263.1c-13.3-7.4-25.5-13.9-36.6-19.7c-29.3-15.3-50.5-24.9-63.6-28.9 M527.3,274.9l-2.1-0.1 M527.1,274.9
	c7.6,5.5,11.8,13.5,12.8,23.9c0.3,6.6,0,14-0.8,22.1c-0.8,8-2.1,16.9-3.9,26.4l-0.4,2.5c-0.1,1.1-0.1,2.2,0,3.2 M488.6,255.3
	c-0.3-0.1-0.5-0.4-1-0.6c-3.7-1.6-7.7-2.1-11.8-1.5c-1.8,0.1-3.3,0.4-4.5,0.8 M471.3,253.9c-2.9,1-4.5,2.8-5,5.4l-0.8,5
	 M465.5,264.2c0,1,0,1.9,0.3,2.6 M465.9,267.4c0.1,0.3,0.3,0.5,0.4,0.8 M465.8,266.8c0,0.1,0.1,0.4,0.1,0.5 M466.3,268.3
	c0.3,0.4,0.5,0.7,1,0.8 M467.6,264.2h-2.1 M488.6,253.1v2.2 M505,273.3c-4.3-1.8-10.9-3.2-20.1-4c-5.4-0.5-11.6-0.8-18.6-1.1
	 M471.3,253.9c-2.6,1-3.9,4.4-3.7,10.3 M493.3,267.6c-4.5-0.4-9.6-0.7-15.1-0.8h-12.4 M499.4,265.7c-8.7-1.2-19.3-1.8-31.9-1.5
	 M525.2,274.8L478,274l-151-4.1 M678.7,313.6l7.2-5.4 M696.2,300.5l-10.3,7.7 M586.2,390.2c-0.3-13.3,0.1-24.5,1.5-33.6
	c0.4-2.9,1.1-5.8,1.9-8.5c2.9-9.1,8-17.3,15.4-24.5l2.8-2.6c11.4-10,25-15.1,40.7-15.1c11.3,0,21.3,2.6,30.4,7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M685.9,308.2c-11-6.7-23.7-10-37.8-10c-19.1,0-35.5,6.2-49,18.4
	c-1.5,1.4-3,2.8-4.4,4.3l-0.8,1c-5.5,6.2-9.6,13.1-12.1,20.4c-1,2.9-1.7,5.9-2.2,8.9c-1.7,10.3-2.1,23.4-1.5,38.9 M568.1,268.2
	l-3.2-5.1 M686.9,282.1c-32.2-3.9-66.6-5.9-103-6.2l-56.5-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M771.5,296.3c-35.8-14.4-77.9-22.8-126.3-25.3l-77-2.8 M633.9,332.2
	c-3.9,1.8-7.4,4.3-10.6,7.4l-1.4,1.4 M631,347l1.7,1.1c1.1,0.7,2.1,0.8,3,0.5l0.7-0.4c0.3-0.1,0.4-0.3,0.5-0.5
	c0.6-0.7,0.8-1.6,0.4-3l-0.5-2.1 M629.5,348.8l-8.8-6.3 M621.9,341l-1.2,1.5 M621.9,341l9.1,6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M699.8,383.9l0.8-2.5c1.7-5.1,2.3-10.6,2.3-16.4c0-15-5.4-27.8-16-38.4
	c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6
	c-5.2,5.2-9.2,11-11.8,17.2c-2.8,6.5-4.1,13.6-4.1,21.2c0,10.3,2.5,19.7,7.6,27.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M620.7,342.5c-5.4,6.5-8,13.9-8,22.6v1.9 M612.8,367l0.1,1.8 M629.5,348.8
	c-4,4.5-5.9,10-5.9,16.2v1.9 M623.8,369.3l2.1,0.1c1.4,0.1,2.2,0.5,2.8,1.4l0.3,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,1-0.4,1.8-1.4,2.6
	l-1.7,1.4 M627.2,378.3c1,1.6,2.2,3.2,3.7,4.5c3.4,3.4,7.4,5.6,11.8,6.7 M617.5,383.1l1,1.5 M626.1,376.2c-1.1-2.2-1.8-4.4-2.2-6.9
	 M626.1,376.2l-8.5,6.9 M618.5,384.6l8.7-6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601.8,392.8c2.3,3.7,5.1,7.3,8.4,10.6c10.6,10.6,23.4,16,38.4,16
	s27.8-5.2,38.4-16c5.8-5.8,10-12.4,12.7-19.5 M613,368.8c0.5,5.2,2.1,9.9,4.5,14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M618.5,384.6c1.4,2.1,3,4,4.8,5.8c4.7,4.7,10,7.8,16.1,9.4 M612.8,367
	h10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M608.7,365c0,11,3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7
	s20.4-3.9,28.2-11.7c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7s-20.4,3.9-28.2,11.7
	C612.7,344.5,608.7,354,608.7,365L608.7,365z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M610.9,365c0,10.5,3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11
	s19.3-3.7,26.7-11c7.4-7.4,11-16.2,11-26.7s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11
	C614.6,345.6,610.9,354.6,610.9,365L610.9,365z M613,368.8l10.9,0.5 M523.4,394.9l3.6,5.2h62.2l2.2-5.8 M586.2,390.2v1.2
	 M591.4,394.3l-5.2,0.7 M586.2,395v-3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M586.2,395l0.1,0.1h-1.2 M585.1,395.2l1.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M523.4,394.9l42.1,2.2l6.3-1.9h13.2 M578.2,390.2h8 M591.4,394.3l10.5-1.5
	 M661.4,331.3c-4-1.5-8.3-2.2-12.8-2.2c-4.7,0-9.1,0.8-13.1,2.3 M635.5,331.5l-1.7,0.7 M658.1,341.7c-2.9-1.1-6.1-1.8-9.4-1.8
	c-3.4,0-6.7,0.5-9.8,1.9 M639,341.7l-3.4-10.2 M663.2,332l-1.8-0.7 M661.4,331.3l-3.3,10.3 M666.1,346.9l-1.8,1.2
	c-1.1,0.7-2.1,0.8-3,0.5c-0.3-0.1-0.4-0.3-0.7-0.4l-0.5-0.5c-0.5-0.7-0.8-1.6-0.4-3l0.5-2.1 M657.6,360.9l-1.9-0.3l-1.2,1.6l0.8,1.8
	l2.1,0.3l1.1-1.7L657.6,360.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M662.1,366.7H661c-3.6,0-6.1-1.4-7.3-4c-0.1-2.6,1.1-4.8,3.7-6.9l0.8-0.5
	 M660.2,342.6c2.1,1.1,4.1,2.5,5.9,4.3 M658.2,355.4l9.5-6.9 M654.5,352.7l-0.3,1c-1.1,3.4-3.2,5.4-5.9,5.8
	c-2.5-0.5-4.3-2.5-5.4-5.6l-0.3-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M647.9,358.6l1.9-0.3l0.8-1.8l-1.2-1.6l-1.9,0.3l-0.8,1.8L647.9,358.6z
	 M648.6,360.1c-1.4,0-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4
	c1-1,1.4-2.1,1.4-3.4c0-1.4-0.5-2.5-1.4-3.4C651.1,360.6,650,360.1,648.6,360.1L648.6,360.1z M635.3,367h1c3.3,0,5.6-1.1,7-3.3
	c0.4-2.8-0.7-5.4-3.6-7.6l-0.7-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M641.2,360.4l-1.9,0.5l-0.4,1.9l1.5,1.4l1.9-0.5l0.4-1.9L641.2,360.4z
	 M642.6,352.8c1.9-1,3.9-1.4,6.2-1.4c2.1,0,4.1,0.4,5.8,1.2 M636.8,342.8l-2.9-10.6 M642.6,352.8l-3.6-11.1 M658.1,341.7l-3.6,11
	 M660.2,342.6l3-10.6 M675.3,340.8l-1.2-1.2c-3.3-3.3-6.9-5.8-10.9-7.6 M676.4,342.2l-1.1-1.4 M667.7,348.5c4.1,4.7,6.2,10,6.2,16.5
	v1.8 M671.3,376.1l-1.8-1.5c-1-0.8-1.5-1.6-1.4-2.6l0.1-0.7l0.3-0.7c0.5-0.8,1.4-1.2,2.6-1.4l2.2-0.1 M684.4,368.6
	c0-0.5,0.1-1.2,0.1-1.8 M684.5,366.7v-1.8c0-8.7-2.8-16.4-8.1-22.8 M678.9,384.4l1-1.5 M673.5,369l10.9-0.4 M673.5,369
	c-0.4,2.5-1.1,4.8-2.2,7 M679.8,382.9c2.6-4.4,4.1-9.1,4.5-14.3 M679.8,382.9l-8.5-6.9 M684.5,366.7h-10.7 M670.2,378.1l8.7,6.3
	 M658,399.7c6.1-1.5,11.4-4.7,16.1-9.4c1.9-1.9,3.4-3.9,4.8-5.9 M638.8,355.6c-2.5,2.6-3.7,5.8-3.7,9.4l0.1,1.9 M636.6,371.4
	l0.8-0.5c3-2.1,5.8-2.5,8.3-1.1c1.7,1.9,2.1,4.5,1.1,7.7l-0.3,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.4,378.4c-2.8-0.4-5.2-1.6-7.3-3.9c-1-1-1.8-2.1-2.5-3.2 M641.9,370.5
	v1.9l1.8,1.1l1.8-1.1v-1.9l-1.8-1L641.9,370.5z M651,378.4l-0.3-1c-1.1-3.4-0.5-6.2,1.5-8.3c2.3-1,5-0.5,7.7,1.4l1,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M654.8,370.3l-1.9-0.5l-1.4,1.4l0.4,1.9l1.8,0.5l1.5-1.2L654.8,370.3z
	 M658.2,355.4c2.6,2.6,4,5.8,4,9.6l-0.1,1.8 M660.7,371.2c-0.7,1.2-1.5,2.3-2.5,3.3c-2.1,2.1-4.5,3.3-7.3,3.9 M656.2,400.1l1.8-0.4
	 M658,399.7l-3.3-10.2 M652.3,389.9l3.8,10.2 M645,389.9l0.8-2.1c0.6-1.2,1.2-1.9,2.1-2.2h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1
	 M641.2,400.1l3.9-10.2 M642.7,389.5l-3.3,10.3 M641.2,400.1c2.3,0.5,5,0.7,7.4,0.7c2.6,0,5.1-0.3,7.6-0.7 M639.4,399.9l1.8,0.3
	 M645,389.9l3.6,0.1l3.7-0.1 M654.7,389.5c4.4-1.1,8.4-3.3,11.8-6.7c1.5-1.5,2.8-3,3.7-4.7 M654.7,389.5l-3.7-11.1 M646.4,378.4
	l-3.7,11.1 M673.8,366.7h-11.7 M660.7,371.2l9.5,6.9 M667.7,348.5l8.7-6.3 M675.3,340.8l-9.2,6.1 M623.6,367h11.7 M638.8,355.6
	l-9.4-6.7 M631,347c1.8-1.8,3.7-3.2,5.8-4.3 M627.2,378.3l9.5-6.9 M534.7,353.1v0.4c0.1,1.1,0.1,2.3,0.3,3.4c0.1,1.9,0,3.9-0.4,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M534.7,362.8l19.4,0.5c12.9,0.4,16.1-1,9.5-4c-6.5-2.8-16.1-4.8-28.8-6.3
	 M527.3,379.8h0.8 M534.7,362.8c-0.3,1.4-0.7,2.8-1.1,4.3l-3.4,11.1c-0.7,0.7-1.7,1.2-2.8,1.5 M522.7,379.6c1.8,0.3,3.3,0.3,4.5,0
	 M527.3,379.8l-4.5-0.1 M565,263.1h-14 M560.6,268.5l7.6-0.3 M699.8,383.9l11.3-1.8 M741.5,302c-12.7-8.3-31-14.9-54.6-19.9
	 M320.4,394.9h203 M343.5,357.5l191.2,5.4 M586.2,391.5l-298.9-1.5"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M678.7,313.6
		l7.2-5.4l10.5-7.7c0,0,0.4-1.6,5.2-1.8c0,0,28.9,1.7,39.6,3.2c0,0-16.2-12.5-54.3-19.9c0,0-42.1-5.1-81.7-5.8l-15.8-0.3l-62-1.1
		c0,0,10.9,6.3,12.8,23.2c0,0,1.5,15-4.8,49.2l-0.4,2.1c0,0-0.3,1.9-0.1,3.7c0.1,1.8,0.4,3,0.3,5.2c-0.1,2.2,0.1,3.4-1,7l-4,12.9
		l10,8.5c0,0,11.6-0.7,38.1,3.6h8c0,0-0.4-31.5,2.9-40.4c0,0,1.9-6.3,3.6-9.2c1.5-2.9,16-34.1,55.7-34.7
		C648.3,305.9,664.6,304.9,678.7,313.6z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M527.3,274.9
		c0,0,12,8,12.9,23.7c0,0,1,13.6-3.7,42.4l-1.5,8.5c0,0-0.3,1.6-0.1,3.9c0.3,2.2,0.8,4.4-0.1,9.9l-4.5,15c0,0-1.4,1.5-3.9,1.5
		l-151-2.2c0,0-11.8,0.6-20.8-5.9c0,0-4-2.9-7.2-7.7l-3.7-6.5l-5.6-10.7c0,0-12.4-25-17.3-51c0,0-2.8-12.2,6.5-26L527.3,274.9z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M181,324.9
		l-47.7-2.8c0,0-8.5,1.5-14.9-4.7l-22.6-20.9c0,0,5.1-4.3,3.2-9.1l-2.9-5.8c0,0-1.2-2.5-4.1-3.2l-18.4-4.7c0,0-2.3-0.3-1.7-2.9
		c0,0,1.2-4.8,4.7-6.1c0,0,2.1-0.8,6.7,0.3l3.3,0.8c0,0,17.9,5.5,35.5,5.4c0,0,6.9,0.1,18.2-0.8c0,0,35.8-2.6,62.9-2.1
		c0,0,67.8,0.4,119.7,9.9c0,0-3.4,6.7-2.2,16.9l0.5,3c0,0,2.2,11.1,8.5,29c0,0,4.5,12.5,8.3,19.5c0,0,6.3,12,7.7,14.3l-32.5,23.7
		c0,0-6.2,0.6-16.5,2.8h-9.4c0,0-0.1-20.2-2.8-33.4c-2.6-13.3-10.5-21.9-14.2-26.8c-3.9-5-21.3-20.6-45.4-20.1
		C224.8,307.1,199.9,305.5,181,324.9z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M488.6,255.3
		v-2.2c0,0-30.4-18.8-57.2-25.7c0,0-20.6-6.1-46.9-5.8c0,0-19-0.8-69.7,7.2l14.9,36.6c0,0,30.5,1.2,55.3,1.5c24.9,0.3,81,0.7,81,0.7
		s-0.7-1.5-0.3-3.9l0.7-4.3c0,0,0.1-3.6,4.4-5.2c4.3-1.8,13.6-1.5,16.9,0.6L488.6,255.3z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M309,264.1l-12.1-31.9
		c0,0-31.4,6.2-49.4,12.9c0,0-3.9,1.1-2.2,3.7c0,0,7,9.8,17.5,11.3l0.3,0.1C262.9,260.2,302.2,263.9,309,264.1z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="648.8"
              cy="365"
              r="54.3"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="223.5"
              cy="365"
              r="54.3"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M487.9,274.1
		c0,0,15.4,1.2,19.8-1.5c0,0-0.7-3.6-9.5-4.1c0,0,2.6-2.8,0.3-5.1c-2.3-2.3-6.7-6.2-10.7-8.5c0,0-5.9-3.3-15.7-1c0,0-4.4,0.8-5.6,5
		l-0.8,5.9c0,0-0.4,3.7,2.3,5c0,0,7,2.3,17.1,1.8L487.9,274.1z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe2DrRight);
