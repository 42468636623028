import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import { cloneDeep, find, findIndex, partition } from 'lodash';
import {
  updateItem,
  updatePhotos,
  updateSelectedCondition,
} from '../../../../../../actions';
import Layout from '../../../templates/forms/Layout';
import FormFooter from '../../../templates/forms/formFooter';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import TextArea from '../../../ui/textArea';
import { showRow } from '../../../../utils/helpers';

let styles = {};

class ConditionReview extends Component {
  state = {
    openDeleteModal: false,
    message: null,
    item: null,
    lightBoxOpen: false,
    lightBoxIndex: 0,
    lightBoxImages: [],
  };

  componentDidMount() {
    this.makeLightBoxImages();
  }

  makeLightBoxImages = () => {
    const lightBoxImages = (this.props.item.condition || [])
      .map(item => {
        const existingPhoto = this.findExistingPhoto(item);
        return existingPhoto && existingPhoto.name !== '(null)'
          ? existingPhoto.name
          : null;
      })
      .filter(item => item);

    this.setState({ lightBoxImages });
  };

  findExistingPhoto = item => {
    const descriptionKey = item.conditionKey;
    const existingPhoto = find(this.props.item.photos, { descriptionKey });
    return existingPhoto;
  };

  openLightbox = item => {
    const existingPhoto = this.findExistingPhoto(item);

    if (existingPhoto) {
      const lightBoxIndex = this.state.lightBoxImages.indexOf(
        existingPhoto.name
      );
      if (lightBoxIndex > -1) {
        this.setState({ lightBoxOpen: true, lightBoxIndex });
      }
    }
  };

  closeLightbox = () => {
    this.setState({ lightBoxOpen: false, lightBoxIndex: 0 });
  };

  moveNext = () => {
    this.setState({
      lightBoxIndex:
        (this.state.lightBoxIndex + 1) % this.state.lightBoxImages.length,
    });
  };

  movePrev = () => {
    this.setState({
      lightBoxIndex:
        (this.state.lightBoxIndex + this.state.lightBoxImages.length - 1) %
        this.state.lightBoxImages.length,
    });
  };

  handleNeedReviewChange = item => {
    const index = findIndex(this.props.item.condition, {
      conditionKey: item.conditionKey,
    });
    this.props.updateItem({
      condition: [
        ...this.props.item.condition.slice(0, index),
        {
          ...this.props.item.condition[index],
          needReview: item.needReview === '1' ? '0' : '1',
        },
        ...this.props.item.condition.slice(index + 1),
      ],
    });
  };

  handleView = item => {
    this.props.navigate('/core/conditionReview/view', { state: { item } });
  };

  handleDelete = item => {
    let values = [];
    const index = findIndex(this.props.item.condition, {
      conditionKey: item.conditionKey,
    });
    const items = this.props.item.condition;
    const area = items[index].area;

    if (items && items.length) {
      values = items;
    }

    values.splice(index, 1);

    this.props.updateItem({
      condition: values,
    });

    const updatedItems = this.props.item.condition;
    let numberOfDamages = 0;
    updatedItems.forEach(value => {
      if (value.area === area) numberOfDamages += 1;
    });
    if (numberOfDamages === 0) {
      this.updateConditionColors(this.props.selectedCondition, area);
    }

    // remove the photo associated with it too
    const photoIndex = findIndex(this.props.item.photos, {
      descriptionKey: item.conditionKey,
    });

    if (photoIndex > -1) {
      const photos = cloneDeep(this.props.item.photos);
      photos.splice(photoIndex, 1);
      this.props.updatePhotos(photos);
    }

    // redirect to condition page if no items left
    if (this.props.item.condition.length === 0) {
      this.props.navigate('/core/condition');
    }
  };

  handleEdit = item => {
    const index = findIndex(this.props.item.condition, {
      conditionKey: item.conditionKey,
    });
    this.props.navigate('/core/conditionReview/parts', {
      state: {
        edit: {
          area: item.area,
          part: item.part,
          damage: item.damage,
          severity: item.severity,
          index,
        },
        condition: {
          area: item.area,
        },
      },
    });
  };

  updateConditionColors(selectedItems, area) {
    let selectedValues = {};

    if (selectedItems) {
      if (Object.keys(selectedItems).length > 0) {
        selectedValues = selectedItems.area;
      }
    }

    if (area === 'Grille') {
      selectedValues.grilleColor = false;
    }

    if (area === 'Front Bumper') {
      selectedValues.frontBumperColor = false;
    }

    if (area === 'Left Front - Headlights') {
      selectedValues.leftFrontHeadlightsColor = false;
    }

    if (area === 'Right Front - Headlights') {
      selectedValues.rightFrontHeadlightsColor = false;
    }

    if (area === 'Windshield') {
      selectedValues.windshieldColor = false;
    }

    if (area === 'Left Front - Fender') {
      selectedValues.leftFrontFenderColor = false;
    }

    if (area === 'Left Front Door') {
      selectedValues.leftFrontDoorColor = false;
    }

    if (area === 'Left Front Door Glass') {
      selectedValues.leftFrontDoorGlassColor = false;
    }

    if (area === 'Left Qtr Glass') {
      selectedValues.leftQtrGlassColor = false;
    }

    if (area === 'Left Rear - Quarter Panel') {
      selectedValues.leftRearQuarterPanelColor = false;
    }

    if (area === 'Left Rear Door') {
      selectedValues.leftRearDoorColor = false;
    }

    if (area === 'Left Rear Door Glass') {
      selectedValues.leftRearDoorGlassColor = false;
    }

    if (area === 'Left Side Mirror') {
      selectedValues.leftSideMirrorColor = false;
    }

    if (area === 'Wheels And Tires - Left Front') {
      selectedValues.tiresLeftFrontColor = false;
    }

    if (area === 'Wheels And Tires - Left Rear') {
      selectedValues.tiresLeftRearColor = false;
    }

    if (area === 'Deck Lid') {
      selectedValues.deckLidColor = false;
    }

    if (area === 'Liftgate and Tailgate') {
      selectedValues.liftgateTailgateColor = false;
    }

    if (area === 'Rear Bumper') {
      selectedValues.rearBumperColor = false;
    }

    if (area === 'Rear Glass') {
      selectedValues.rearGlassColor = false;
    }

    if (area === 'Left Tail Light') {
      selectedValues.leftTailLightColor = false;
    }

    if (area === 'Right Tail Light') {
      selectedValues.rightTailLightColor = false;
    }

    if (area === 'Right Front - Fender') {
      selectedValues.rightFrontFenderColor = false;
    }

    if (area === 'Right Front Door') {
      selectedValues.rightFrontDoorColor = false;
    }

    if (area === 'Right Front Door Glass') {
      selectedValues.rightFrontDoorGlassColor = false;
    }

    if (area === 'Right Qtr Glass') {
      selectedValues.rightQtrGlassColor = false;
    }

    if (area === 'Right Rear - Quarter Panel') {
      selectedValues.rightRearQuarterPanelColor = false;
    }

    if (area === 'Right Rear Door') {
      selectedValues.rightRearDoorColor = false;
    }

    if (area === 'Right Rear Door Glass') {
      selectedValues.rightRearDoorGlassColor = false;
    }

    if (area === 'Right Side Mirror') {
      selectedValues.rightSideMirrorColor = false;
    }

    if (area === 'Wheels And Tires - Right Front') {
      selectedValues.tiresRightFrontColor = false;
    }

    if (area === 'Wheels And Tires - Right Rear') {
      selectedValues.tiresRightRearColor = false;
    }

    if (area === 'Hood') {
      selectedValues.hoodColor = false;
    }

    if (area === 'Roof') {
      selectedValues.roofColor = false;
    }

    if (area === 'Truck Bed') {
      selectedValues.truckBedColor = false;
    }

    this.props.updateSelectedCondition({
      area: selectedValues,
    });
  }

  renderLightBox() {
    const { lightBoxOpen, lightBoxImages, lightBoxIndex } = this.state;

    return (
      lightBoxOpen && (
        <Lightbox
          mainSrc={lightBoxImages[lightBoxIndex]}
          nextSrc={lightBoxImages[(lightBoxIndex + 1) % lightBoxImages.length]}
          prevSrc={
            lightBoxImages[
              (lightBoxIndex + lightBoxImages.length - 1) %
                lightBoxImages.length
            ]
          }
          onCloseRequest={this.closeLightbox}
          onMovePrevRequest={this.movePrev}
          onMoveNextRequest={this.moveNext}
        />
      )
    );
  }

  renderConditionItems() {
    const items = this.props.item.condition;

    if (!items || items.length === 0) {
      return null;
    }

    const [needReviewItems, noReviewItems] = partition(items, {
      needReview: '1',
    });

    return (
      <div style={{ marginBottom: 40 }}>
        {this.renderLightBox()}
        {noReviewItems.length > 0 && (
          <div className="review-card-title" style={{ borderRadius: '4px' }}>
            Reviewed
          </div>
        )}
        {noReviewItems.map((item, index) => {
          const conditionPhoto = this.findExistingPhoto(item);

          return (
            <div className="condition-items-card" key={index}>
              {conditionPhoto ? (
                <div onClick={() => this.openLightbox(item)}>
                  <img
                    style={styles.image}
                    src={
                      conditionPhoto.name instanceof File
                        ? URL.createObjectURL(conditionPhoto.name)
                        : conditionPhoto.name
                    }
                    alt={'Selected image for ' + this.props.title}
                  />
                  <span className="title mb-1">{item.area}</span>
                </div>
              ) : (
                <span className="title mb-1">{item.area}</span>
              )}
              <div className="details">
                {item.part} - {item.damage} - {item.severity}
              </div>
              <div className="options">
                <div>
                  <span onClick={() => this.handleNeedReviewChange(item)}>
                    Needs Review
                  </span>
                </div>
                <div>
                  <span onClick={() => this.handleView(item)}>View</span>
                </div>
                <div>
                  <span onClick={() => this.handleEdit(item)}>Edit</span>
                </div>
                <div>
                  <span
                    onClick={() =>
                      this.setState({
                        openDeleteModal: true,
                        message: item.area,
                        item,
                      })
                    }
                  >
                    Delete
                  </span>
                </div>
              </div>
            </div>
          );
        })}

        {needReviewItems.length > 0 && (
          <div
            className="review-card-title needs-review"
            style={{ borderRadius: '4px' }}
          >
            Needs Review
          </div>
        )}
        {needReviewItems.map((item, index) => {
          const conditionPhoto = this.findExistingPhoto(item);

          return (
            <div className="condition-items-card" key={index}>
              {conditionPhoto ? (
                <div onClick={() => this.openLightbox(item)}>
                  <img
                    style={styles.image}
                    src={
                      conditionPhoto.name instanceof File
                        ? URL.createObjectURL(conditionPhoto.name)
                        : conditionPhoto.name
                    }
                    alt={'Selected image for ' + this.props.title}
                  />
                  <span className="title mb-1">{item.area}</span>
                </div>
              ) : (
                <span className="title mb-1">{item.area}</span>
              )}
              <div className="details">
                {item.part} - {item.damage} - {item.severity}
              </div>
              <div className="options">
                <div>
                  <span onClick={() => this.handleNeedReviewChange(item)}>
                    Reviewed
                  </span>
                </div>
                <div>
                  <span onClick={() => this.handleView(item)}>View</span>
                </div>
                <div>
                  <span onClick={() => this.handleEdit(item)}>Edit</span>
                </div>
                <div>
                  <span
                    onClick={() =>
                      this.setState({
                        openDeleteModal: true,
                        message: item.area,
                        item,
                      })
                    }
                  >
                    Delete
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderOther() {
    const { coreSections } = this.props;

    return (
      <div className="form-body-container">
        {showRow('s6:r2', coreSections) &&
          showRow('s11:r2', coreSections) && (
            <div className="form-body form-body-vertical">
              <TextArea
                title="Notes"
                placeholder="Enter notes"
                onChangeHandler={e =>
                  this.props.updateItem({ notes: e.target.value })
                }
                value={this.props.item.notes || ''}
              />
            </div>
          )}

        {showRow('s6:r3', coreSections) &&
          showRow('s11:r3', coreSections) && (
            <div className="form-checkbox-container">
              <span>Is CR Done?</span>
              <div className="h-100">
                <label className="form-checkbox-label mr-4">
                  Yes
                  <input
                    type="radio"
                    name="crDone"
                    onChange={() => this.props.updateItem({ crDone: true })}
                    defaultChecked={
                      this.props.item.crDone === true ? true : false
                    }
                  />
                  <span className="form-checkbox-checkmark" />
                </label>
                <label className="form-checkbox-label mr-4">
                  No
                  <input
                    type="radio"
                    name="crDone"
                    onChange={() => this.props.updateItem({ crDone: false })}
                    defaultChecked={
                      this.props.item.crDone === false ? true : false
                    }
                  />
                  <span className="form-checkbox-checkmark" />
                </label>
                <label className="form-checkbox-label mr-4">
                  Unknown
                  <input
                    type="radio"
                    name="crDone"
                    defaultChecked={
                      this.props.item.crDone === undefined ||
                      this.props.item.crDone === null ||
                      this.props.item.crDone === 'Unknown'
                        ? true
                        : false
                    }
                    onChange={() =>
                      this.props.updateItem({ crDone: 'Unknown' })
                    }
                  />
                  <span className="form-checkbox-checkmark" />
                </label>
              </div>
            </div>
          )}
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <ConfirmationModal
          open={this.state.openDeleteModal}
          close={() => this.setState({ openDeleteModal: false })}
          action={() => this.handleDelete(this.state.item)}
          title="Are you sure you want to delete condition item?"
          body={this.state.message}
        />
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Condition Review</div>
            </div>
            {this.renderConditionItems()}
            {this.renderOther()}
            <FormFooter currentSection="s11" />
          </div>
        </div>
      </Layout>
    );
  }
}

styles = {
  image: {
    width: 30,
    height: 30,
    objectFit: 'contain',
    objectPosition: 'center',
    display: 'inline-block',
    marginRight: 10,
    cursor: 'pointer',
  },
};

ConditionReview.propTypes = {
  item: PropTypes.object.isRequired,
  selectedCondition: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  updatePhotos: PropTypes.func.isRequired,
  updateSelectedCondition: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { item, selectedCondition, coreSections } = state.core;
  return { item, selectedCondition, coreSections };
};

export default connect(
  mapStateToProps,
  { updateItem, updatePhotos, updateSelectedCondition }
)(ConditionReview);
