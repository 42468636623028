import { FETCH_API } from '../../middleware';

export const GET_ITEM_AUTOGRADE_REQUEST = 'GET_ITEM_AUTOGRADE_REQUEST';
export const GET_ITEM_AUTOGRADE_SUCCESS = 'GET_ITEM_AUTOGRADE_SUCCESS';
export const GET_ITEM_AUTOGRADE_FAILURE = 'GET_ITEM_AUTOGRADE_FAILURE';

const fetchGetAutoGrade = (pk, apiKey) => ({
  [FETCH_API]: {
    types: [
      GET_ITEM_AUTOGRADE_REQUEST,
      GET_ITEM_AUTOGRADE_SUCCESS,
      GET_ITEM_AUTOGRADE_FAILURE,
    ],
    endpoint: `get-item-autoGrade?apiKey=${apiKey}&inventPk=${pk}`,
    core: true,
  },
});

export const loadGetAutoGrade = pk => dispatch =>
  dispatch(fetchGetAutoGrade(pk));
