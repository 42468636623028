import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegularCabPickup2DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M700.9,166.7c-3.9-5-8.7-7.3-13.5-7.3H579.7c-6.2,0-11.1,3.7-13.6,7.4c-87.4,0-173.9,0.1-275,0l7.4-21
	c0.3-4.3-4.5-4.5-6.7-2.2c-1.4,1.6-7.3,9.1-9.6,23.2l-38.8-0.1h-10.5c-0.7-5.5-8.4-9.9-16.1-9.9c-20.9-0.1-43.6,0.1-64.5,0
	c-15.5,0-30-0.6-42.5,0c-8.8,0.4-20.5,4.1-23,11.1c-13.3,1-23,3.4-29.4,7.8C40.9,190.3,31,238.4,30,288.1
	c-1.1,56.7,9.1,115.7,26.4,131c9.9,6.7,15.5,7.3,30.4,8.5c5,8.8,16.9,12.5,25.6,12.4c32.3-0.7,70.6,0.5,105.4,0
	c6.7-0.1,14.3-5.4,15.1-9.9l10.6-0.1l39.2,0.3c1.2,7.3,3.9,13.9,7.4,19.8c0.5,1.5,2.1,3,4,3.6c2.9,0.7,5.5-1.7,4.5-3.6l-7-19.8
	c46.1,0.1,91.9,0.4,137.2,0.4c0.1,0-0.1,0.1,0,0.1h137.4c2.5,3.7,7.3,7,13.5,7h107.7c4.8,0,9.8-2.3,13.3-7.2c30.3,0,60.8,0,91.3-0.3
	c9.2-0.1,20.4-9.1,20.4-21.6V188.4c0-12.5-8.7-21.6-19.8-21.6C761.1,166.8,729.9,167,700.9,166.7L700.9,166.7z"
          />
          <path
            fill="#FFFFFF"
            d="M407.5,167h-22.3v1.6c0.3,1.1,1.4,1.9,2.5,1.9H405c1.1,0,2.2-1.1,2.5-2.2L407.5,167L407.5,167z M289.3,176.4
	c-0.1-0.3-0.7-1.4-1.1-2.1h-14.4c-2.5,0-5,3.7-1.2,5c6.1,1.5,11.7,3.2,17.2,4.7c0.1-0.6,0.3-1.5,0.4-1.8
	C290.1,180.7,290.3,178.2,289.3,176.4L289.3,176.4z M797.6,178H432.4v3.7h365.1c5,0,6.2,3.7,6.2,7.4v217.6c0,3.7-1.2,7.4-6.2,7.4
	H432.4v3.7h365.2c6.2,0,12.4-2.5,12.4-11.1V189.1C810,181.7,803.8,178,797.6,178L797.6,178z M407.5,428.9h-22.3v-1.4
	c0.3-0.8,1.4-1.6,2.5-1.6H405c1.1,0,2.2,0.8,2.5,1.8L407.5,428.9L407.5,428.9z M289.4,419.9c-0.1,0.3-0.7,1.4-1.1,2.1h-14.4
	c-2.5,0-5-3.7-1.2-5c6.1-1.5,11.7-3.2,17.2-4.7c0.1,0.5,0.3,1.5,0.4,1.8C290.4,415.7,290.5,418.1,289.4,419.9L289.4,419.9z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M288.3,174.7c0.4,0.7,1,1.9,1.1,2.1c1.1,1.8,1,4.3,0.7,5.6c0,0.4-0.3,1.2-0.4,1.8
		c24.5,6.9,43.3,12.7,64.7,12.7h47c8.7,0,7.8-6.1,7.8-8.5l1-12.1l-1.4-1.4L288.3,174.7L288.3,174.7z M325.3,389.8
		c-6.1-14.9-11.8-73.5-11.8-91.5c0-17.9,5.8-76.3,11.8-91.3c0.1-0.3,0.5-1.4,1-2.2c-20.4-4.8-44.2-12-66-17.7
		c-3.7,0-12.4-2.5-16.1,2.5c-11.1,18.6-19.8,61.9-19.8,108.8c0,47,8.7,90.2,19.8,108.8c3.7,6.2,12.4,2.5,16.1,2.5
		c21.7-5.8,45.5-12.8,65.9-17.7C325.8,391.2,325.5,390.2,325.3,389.8L325.3,389.8z M430,202c0,2.5-5,3.6-5,9.8v174.3
		c0,5,6.2,6.6,6.2,9.1l1.2,1.9v-198L430,202L430,202z M401.4,401h-47c-21.3,0-40.2,5.5-64.7,11.8c0.1,0.6,0.3,1.4,0.4,1.8
		c0.1,1.2,0.4,3.6-0.7,5.2c-0.1,0.3-0.7,1.4-1.1,1.9h121.7l-1.2-12.8C408.8,406.9,410.1,400.9,401.4,401L401.4,401z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M294.9,142.3c-5,0-11.1,14.9-12.4,23.5c-1.1,4.4-1.2,7.7,0.4,8.5h4.7c1.2-1.2,2.3-3.7,3.6-7.3V167h-0.3
	l7.4-21c0.1-1.8-0.7-2.9-1.8-3.3C296,142.3,295.5,142.3,294.9,142.3L294.9,142.3z M287.5,422h-3.7c-2.5,0-2.5,3.7-1.2,7.4
	c0.4,3.6,1.5,7.8,3,11.8c1.2,3,2.8,6.1,4.4,8.8c0,0.1,0.1,0.3,0.1,0.3c0.7,1,1.2,1.6,1.9,2.3c0.5,0.4,1.2,0.7,1.8,0.8
	c2.2,0.5,4.4-0.8,4.7-2.3v-0.5c0-0.1,0-0.4-0.1-0.6c-1.2-2.6-4.5-11.6-7-19.7l-0.1-0.4c-0.1-0.3-0.1-0.5-0.3-0.8
	C290,425.7,288.7,423.2,287.5,422L287.5,422z M86.7,168.2c-11.1,1.2-27.2,1.2-35.9,14.9c-9.9,16.1-21,61.9-21,115.1
	s11.1,99,21,115.1c8.7,13.6,24.8,13.6,35.9,14.9c-14.9-3.7-22.3-8.7-24.8-13.6c-0.5-0.8-1.2-1.8-1.8-3
	c-6.9-12.9-19.3-51.3-19.3-113.2c0-62,12.4-100.4,19.3-113.2c0.7-1.2,1.2-2.3,1.8-3C64.4,176.9,71.8,171.9,86.7,168.2L86.7,168.2z"
          />
          <path
            fill="#5E5E5E"
            d="M231.7,189.2c-16.1,17.3-22.3,90.4-22.3,108.9s6.2,91.6,22.3,109c4.8,4.8,9.8,8.9,19.9,8.1l1.1-4.4v-0.1
	c-3.3,0.3-6.7-0.1-8.7-3.6c-11.1-18.6-19.8-61.9-19.8-109c0-47,8.7-90.4,19.8-108.9c2.1-2.6,5.5-3.2,8.8-3l-0.1-0.7l-1.1-4.4
	C241.3,180.3,236.5,184.4,231.7,189.2L231.7,189.2z"
          />
          <path
            fill="#FFFFFF"
            d="M163.4,433c-33.4,0-59.4-1.2-76.8-5c2.5,7.4,16.1,12.4,24.8,12.4h105.2c6.2,0,14.9-5,16.1-9.9
	C229.2,431.8,200.7,433,163.4,433z M163.4,163.7c-33.4,0-59.4,1.1-76.8,4.4c2.5-6.6,16.1-10.9,24.8-10.9h105.2
	c6.2,0,14.9,4.4,16.1,8.8c-0.6-0.1,1.1,1.1-0.4,1c-1.4-0.1-5.8-1.6-7.8-1.8C212.9,164.2,190.5,163.7,163.4,163.7L163.4,163.7z
	 M687.4,159.5H579.7c-6.2,0-11.1,3.7-13.6,7.4h135l-0.1-0.1C697,161.9,692.2,159.5,687.4,159.5z M698.5,430.7H566.1
	c2.5,3.7,7.4,7.4,13.6,7.4h107.7c4.8,0,9.8-2.3,13.3-7.2l0.3-0.3L698.5,430.7L698.5,430.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292.5,430.7l136.2,0.1 M233,430.7h48.3 M30,298.2
	c0,53.2,11.1,100.3,21,115.1c8.7,13.6,24.8,13.6,35.9,14.9 M431.1,394.8c0-2.5-6.2-3.7-6.2-8.7v-87.9 M432.4,298.2v132.6h133.7
	 M566.1,430.8h132.5 M812.5,298.2v109 M698.5,430.8h94.1 M810,298.2v109 M792.6,430.8c8.7-1.2,19.8-7.6,19.8-23.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.9,414.6v-12.4c0-9.9-8.7-17.3-17.3-17.3H571
	c-9.9,0-17.3,7.4-17.3,17.3v12.4 M797.6,414.6c5,0,6.2-3.7,6.2-7.4h8.7 M803.8,298.2v109 M432.4,418.3h365.2 M432.4,414.6h365.2
	 M797.6,418.3c6.2,0,12.4-2.5,12.4-11.1 M86.9,428.2c2.5,7.4,16.1,12.4,24.8,12.4h105.2c6.2,0,14.9-5,16.1-9.9 M566.1,430.8
	c2.5,3.7,7.4,7.4,13.6,7.4h107.7c5,0,9.9-2.5,13.6-7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.8,422c-2.5,0-2.5,3.7-1.2,7.4 M290,413.3c0,0,1.2,5-1.2,8.7
	 M272.7,417c-3.7,1.2-1.2,5,1.2,5l136.5-0.1 M401.4,400.9c8.7,0,7.4,5,7.4,7.4l1.2,13.5 M422.5,393.4l1.2,37.4 M354.4,401h47
	 M260.3,409.6c37.1-9.9,80.5-23.5,101.5-23.5h63.1 M346.9,393.5h83.6 M272.7,417c33.4-8.7,55.7-16.1,81.7-16.1 M209.5,298.2
	c0,18.6,6.2,91.6,22.3,109c6.2,6.2,12.4,11.1,29.7,6.2c37.1-8.7,68.1-19.8,85.4-19.8 M59.7,409.6c26,1.2,121.3,0,146.1,0
	c3.7,0,26-2.5,26-2.5 M59.7,410.9c3.7,7.4,118.9,8.7,189.4,8.7 M251.6,415.8l-6.2,14.9c-8.7,0-104-5-121.3-5
	c-12.4-1.2-26,3.7-26,3.7 M83.2,422c0,0,29.7,2.5,40.9,3.7 M48.6,372.5c19.8,6.2,97.8,7.4,156,7.4 M37.4,298.2
	c0,68.1,14.9,107.7,21,116.4 M58.5,414.6c1.2,3.7,9.9,11.1,28.5,13.6 M41.1,298.2c0,68.1,14.9,107.7,21,116.4 M62.2,414.6
	c2.5,5,9.9,9.9,24.8,13.6c17.3,3.7,43.3,5,76.8,5c37.1,0,65.6-1.2,69.3-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M282.6,429.4c1.2,9.9,7.4,24.8,12.4,24.8c1.2,0,3.7,0,3.7-3.7 M287.5,422
	c1.2,1.2,2.5,3.7,3.7,7.4 M291.2,429.4c2.5,8.7,6.2,18.6,7.4,21 M313.5,298.2c0,18.6,6.2,80.5,12.4,92.9 M428.6,430.8h3.7
	 M407.6,429.6c0-1.2-1.2-2.5-2.5-2.5h-17.3c-1.2,0-2.5,1.2-2.5,2.5 M252.8,410.9l-1.2,5 M219.4,298.2c0,47,9.9,90.4,19.8,108.9
	c2.5,2.5,7.4,3.7,13.6,3.7 M224.4,298.2c0,47,8.7,90.4,19.8,109c3.7,6.2,12.4,3.7,16.1,2.5 M292.5,167.1h136.2 M233,167.1h48.3
	 M30,298.2c0-53.2,11.1-99,21-115.1c8.7-13.6,24.8-13.6,35.9-14.9 M429.9,201.8c0,2.5-5,3.7-5,9.9v86.7 M432.4,298.2V167.1h134.9
	 M567.3,167.1h131.2 M812.5,298.2V189.4 M698.5,167.1h94.1 M810,298.2V189.4 M792.6,167.1c8.7,1.2,19.8,6.2,19.8,22.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.9,181.9v13.6c0,8.7-8.7,17.3-17.3,17.3H571
	c-9.9,0-17.3-8.7-17.3-17.3v-13.6 M797.6,181.9c5,0,6.2,3.7,6.2,7.4h8.7 M803.8,298.2V189.4 M432.4,178.2h365.2 M432.4,182h365.2
	 M797.6,178.2c6.2,0,12.4,3.7,12.4,11.1 M86.9,168.3c2.5-7.4,16.1-11.1,24.8-11.1h105.2c6.2,0,14.9,3.7,16.1,9.9 M566.1,167.1
	c2.5-3.7,7.4-7.4,13.6-7.4h107.7c5,0,9.9,3.7,13.6,7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.8,174.5c-2.5,0-2.5-3.7-1.2-8.7 M290,183.2c0,0,1.2-5-1.2-7.4
	 M401.4,196.8c8.7,0,7.4-6.2,7.4-8.7l1.2-12.4l-1.2-1.2h-135c-2.5,0-5,3.7-1.2,5 M422.5,203.1l1.2-36 M354.4,196.8h47 M260.3,186.9
	c37.1,9.9,80.5,23.5,101.5,23.5h63.1 M346.9,203h82.4 M272.6,179.5c33.4,8.7,55.7,17.3,81.7,17.3 M209.5,298.2
	c0-18.6,6.2-91.6,22.3-108.9c6.2-5,12.4-9.9,29.7-6.2c37.1,8.7,68.1,19.8,85.4,19.8 M59.7,188.1c26-2.5,121.3-1.2,146.1-1.2
	c3.7,0,26,2.5,26,2.5 M59.7,185.7c3.7-7.4,118.9-8.7,189.4-8.7 M251.6,180.7l-6.2-13.6c-8.7,0-104,3.7-121.3,3.7
	c-12.4,1.2-26-3.7-26-3.7 M83.2,174.5c0,0,29.7-2.5,40.9-3.7 M48.6,224c19.8-6.2,97.8-7.4,156-7.4 M37.4,298.2
	c0-68.1,14.9-107.7,21-116.4 M58.5,181.9c1.2-3.7,9.9-11.1,28.5-13.6 M41.1,298.2c0-68.1,14.9-107.7,21-116.4 M62.2,181.9
	c2.5-3.7,9.9-9.9,24.8-13.6c17.3-3.7,43.3-5,76.8-5c37.1,0,65.6,1.2,69.3,3.7 M282.6,165.9c1.2-8.7,7.4-23.5,12.4-23.5
	c1.2,0,3.7,0,3.7,3.7 M287.5,174.5c1.2-1.2,2.5-3.7,3.7-7.4 M291.2,167.1c2.5-7.4,6.2-18.6,7.4-21 M313.5,298.2
	c0-18.6,6.2-80.5,12.4-92.9 M428.6,167.1h3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M407.6,168.3c0,1.2-1.2,2.5-2.5,2.5h-17.3c-1.2,0-2.5-1.2-2.5-2.5
	 M252.8,185.7l-1.2-5 M219.4,298.2c0-47,9.9-90.4,19.8-108.9c2.5-2.5,7.4-3.7,13.6-3.7 M224.4,298.2c0-47,8.7-90.4,19.8-108.9
	c3.7-5,12.4-2.5,16.1-2.5"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M252,415.3l-1.9,4.3h-1.9
		c0,0-67.4-0.4-79.5-0.5c0,0-46.6-0.7-74-2.5c0,0-28.9-1.5-34.3-5c0,0-19.1-35.6-19.1-113.4c0,0-1-71.9,19.4-113.8
		c0,0,3.2-1,4.7-1.2c1.5-0.3,16.6-3.6,59.8-4.7c0,0,43.2-1.2,72.9-1.5l51.2-0.3h0.8l1.7,3.7l0.3,1.2c0,0-11.3-1.1-20.1,7.4
		c0,0-9.6,7.3-16.6,50.5c0,0-5,26.3-5.6,58.3c0,0-0.4,16.2,4.7,52.4c0,0,5.2,42.5,17.6,56.5C231.8,407.1,239.5,417,252,415.3z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M326,392c0,0-3.2-4.5-7.7-37
		c0,0-4.4-31.8-5-56.8c0,0-0.3-14.3,3.7-47.6c0,0,4.7-39.6,8.9-45.8l-65.9-18c0,0-0.7,0-1.9-0.1c-1.4-0.1-5.9-0.7-8.5-0.3
		c0,0-3.6,0.4-5.5,2.8c0,0-7.3,9.9-13.9,41.8c0,0-6.3,30-6.1,67.3c0,0-0.4,28.2,4.5,58.5c0,0,4.3,29.4,13.3,46.9
		c0,0,2.6,6.3,6.1,6.9c0,0,2.6,0.8,4.7,0.5c0,0,4-0.1,7.2-1.5C260.3,409.6,314.3,394.6,326,392z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M326,392c0,0-2.8-4.5-6.2-26.7
		c0,0-3.7-22.3-5.6-49.8c0,0-0.8-12.5-0.8-17.3c0-4.8,0-6.3,0.8-17.1c0,0,1.5-25,5.9-51.7c0,0,2.9-19.9,6.1-24.6
		c0,0,23.7,5.5,35.6,5.5h63.1v0.8v175h-60c0,0-6.2-0.3-12.5,0.7C352.4,386.8,339.9,388.4,326,392z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M432.4,394.8h-1.2
		c0,0-0.1-1.1-1.2-1.9c-1.1-0.8-5-2.8-5-6.7V210.4c0,0-0.1-3.2,3.9-6.6c0,0,1.1-1.1,1.1-2.1h2.5L432.4,394.8L432.4,394.8z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M432.4,414.6V181.7l365.2,0.1
		c0,0,6.2-0.6,6.2,7.3v218c0,0,0.3,7.4-5.9,7.4H432.4z"
              id="Truck Bed"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Truck Bed')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('truckBedColor')
                  : '#83be97'
              }
            />
            <rect
              x="803.8"
              y="189.4"
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              width="8.7"
              height="217.9"
              id="Liftgate and Tailgate"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Liftgate and Tailgate')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('liftgateTailgateColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(RegularCabPickup2DrTop);
