import {
  cloneDeep,
  find,
  findLastIndex,
  has,
  isArray,
  isEmpty,
  uniq,
} from 'lodash';
import {
  HEAVY_TRUCK_PROPERTIES,
  INVENTORY_META_PROPERTIES,
  MEDIA_TYPES,
  WHEEL_MEDIA_TYPES,
} from '../constants/common';
import { hasInfo } from '../utils/HasInfo';

const Item = (item, lot, printer = true) => {
  const vehicle = {
    auction: {},
    conditionItems: [],
    details: {},
    engine: {},
    equipments: [],
    info: {},
    inspection: {},
    medias: [],
    odometer: {},
    pricing: {},
    wheels: [],
    heavyTrucks: {},
    axles: [],
  };

  const getPrintType = item => {
    switch (item) {
      case 'Lot Tag':
        return 'lot-tag';
      case 'Condition Report':
        return 'condition-report';
      case 'Window Sticker':
        return 'window-sticker';
      case 'Window Stock':
        return 'window-stock';
      default:
        return '';
    }
  };

  vehicle.action = item.turnRerun === true ? 'turn-and-rerun' : 'save'; // note action not auction
  if (item.pk) vehicle.pk = item.pk;
  vehicle.locked = item.locked;

  const defaultSellerAccountNumber = item.defaultSellerAccountNumber || 'A0001';
  vehicle.auction.accountNumber =
    item.accountNumber || defaultSellerAccountNumber;
  vehicle.auction.stockNumber = item.stockNumber || '';
  vehicle.auction.dealerName = item.dealerName || '';

  // trim note from lotNumber
  let lotNumber = item.lotNumber || '';
  let noteIndex = lotNumber.indexOf(':');
  if (noteIndex > -1) lotNumber = lotNumber.slice(0, noteIndex);
  vehicle.auction.lotNumber = lotNumber;
  vehicle.auction.saleDate =
    item.saleDate && item.entryType !== 'Inventory' ? item.saleDate : '';
  vehicle.auction.saleId =
    item.saleId && item.entryType !== 'Inventory' ? item.saleId : '';
  vehicle.auction.eventName = item.eventName || '';
  vehicle.auction.type = item.saleType || '';
  vehicle.auction.blockAnnouncementNotes = item.blockAnnouncements || '';
  vehicle.auction.privateBlockNotes = item.privateBlockNotes || '';
  vehicle.auction.lights = item.light || '';
  vehicle.auction.owner = item.owner || '';

  const locations = {
    'Main Lot': 'MN',
    'Intake Lot': 'IN',
    Satellite: 'SA',
    Offsite: 'OS',
  };

  vehicle.auction.location = locations[item.location]
    ? locations[item.location]
    : item.location || '';

  vehicle.info.vin = item.vin
    ? item.vin.toUpperCase()
    : item.serialNumber
    ? item.serialNumber.toUpperCase()
    : '';
  vehicle.info.year = item.year || '';
  vehicle.info.make = item.make || '';
  vehicle.info.model = item.model || '';
  vehicle.info.trim = item.trim || '';
  vehicle.info.body = item.bodyStyle || '';
  vehicle.info.exteriorColor = item.exteriorColor || '';
  vehicle.info.interiorColor = item.interiorColor || '';

  const interiorType = item.interiorType || '';
  vehicle.info.interiorTrim = interiorType === 'Unknown' ? '' : interiorType;
  vehicle.info.type = item.type || 'V';

  vehicle.inspection.location = item.location;
  vehicle.inspection.crDone =
    item.crDone === true ? 'true' : item.crDone === false ? 'false' : '';

  vehicle.inspection.grade =
    item.vehicleGrade === 'None' ? '' : item.vehicleGrade || '';

  vehicle.inspection.notes = item.notes || '';
  vehicle.inspection.studioDone = item.studioDone || '';
  vehicle.pricing.startingBid = item.startingBid || '';
  vehicle.pricing.reserve = item.reserve || '';
  vehicle.pricing.buyNow = item.buyNow || '';

  const transmission = item.transmission || '';
  vehicle.engine.transmission = transmission === 'Unknown' ? '' : transmission;

  const engine = item.engine || '';
  vehicle.engine.description = engine === 'Unknown' ? '' : engine;

  const drivetrain = item.driveline || '';
  vehicle.engine.drivetrain = drivetrain === 'Unknown' ? '' : drivetrain;

  vehicle.engine.cylinders = item.cylinder || '';
  vehicle.engine.fuelLevel = item.fuelLevel || '';
  const fuelLevelCodes = {
    Empty: '0',
    '1/8': '8',
    '1/4': '1',
    '1/2': '2',
    '3/4': '3',
    'Full Tank': '4',
    undefined: null,
  };
  vehicle.engine.fuelLevelCode = fuelLevelCodes[item.fuelLevel];

  vehicle.odometer.reading = item.odometer || '';

  const odometerTypes = ['Miles', 'Kilometers', 'Unknown'];
  vehicle.odometer.locale = odometerTypes[item.odometerType] || '';
  vehicle.odometer.type = item.odometerType || '';

  vehicle.odometer.odometerInoperable =
    item.odometerInoperable === true
      ? 'true'
      : item.odometerInoperable === false
      ? 'false'
      : '';

  vehicle.odometer.trueMileageUnknown =
    item.trueMileageUnknown === true
      ? 'true'
      : item.trueMileageUnknown === false
      ? 'false'
      : '';

  let standard = item.standardEquipment || [];
  if (typeof standard === 'string') standard = [standard];
  vehicle.equipments = vehicle.equipments.concat(
    standard.map(equip => ({
      category: 'standard',
      description: equip.toUpperCase(),
    }))
  );

  let optional = item.optionalEquipment || [];
  if (typeof optional === 'string') optional = [optional];
  vehicle.equipments = vehicle.equipments.concat(
    optional.map(equip => ({
      category: 'optional',
      description: equip.toUpperCase(),
    }))
  );
  // NOTE: true is 'true', false is 'false', 'Unknown' is ''
  vehicle.details.frameDamage =
    item.frameDamage === true
      ? 'true'
      : item.frameDamage === false
      ? 'false'
      : '';
  vehicle.details.drivable =
    item.undrivable === true
      ? 'false'
      : item.undrivable === false
      ? 'true'
      : '';
  vehicle.details.inoperable =
    item.inoperable === true
      ? 'true'
      : item.inoperable === false
      ? 'false'
      : '';
  vehicle.details.grayMarket =
    item.grayMarket === true
      ? 'true'
      : item.grayMarket === false
      ? 'false'
      : '';
  vehicle.details.previousCanadian =
    item.previousCanadian === true
      ? 'true'
      : item.previousCanadian === false
      ? 'false'
      : '';
  vehicle.details.titlePresent =
    item.titlePresent === true
      ? 'true'
      : item.titlePresent === false
      ? 'false'
      : '';
  vehicle.details.keysMissing =
    item.keysMissing === true
      ? 'true'
      : item.keysMissing === false
      ? 'false'
      : '';
  vehicle.details.fobsMissing =
    item.fobsMissing === true
      ? 'true'
      : item.fobsMissing === false
      ? 'false'
      : '';
  vehicle.details.booksMissing =
    item.booksMissing === true
      ? 'true'
      : item.booksMissing === false
      ? 'false'
      : '';
  vehicle.details.floorMatsMissing =
    item.floorMatsMissing === true
      ? 'true'
      : item.floorMatsMissing === false
      ? 'false'
      : '';
  vehicle.details.smokedIn =
    item.smokedIn === true ? 'true' : item.smokedIn === false ? 'false' : '';
  vehicle.details.commercialVehicle =
    item.commercialVehicle === true
      ? 'true'
      : item.commercialVehicle === false
      ? 'false'
      : '';
  vehicle.details.policeOrTaxi =
    item.policeOrTaxi === true
      ? 'true'
      : item.policeOrTaxi === false
      ? 'false'
      : '';
  vehicle.details.floodDamage =
    item.floodDamage === true
      ? 'true'
      : item.floodDamage === false
      ? 'false'
      : '';

  vehicle.details.plateCount = item.plateCount || '';
  vehicle.details.plateNumber = item.plates || '';
  vehicle.details.plateState = item.plateState || '';
  vehicle.details.doors = item.doors || '';
  vehicle.details.seats = item.seats || '';
  vehicle.details.top = item.top || '';
  vehicle.details.weight = item.weight || '';
  vehicle.details.radio = item.radio || '';
  vehicle.details.vTrackVendor = item.vTrackVendor || '';
  vehicle.details.vTrackId = item.vTrackId || '';
  vehicle.spare = item.spareTire || '';
  vehicle.details.keys = item.keys || '';
  vehicle.details.rfid = item.rfid || '';

  // ANNOUNCEMENTS
  vehicle.auction.announcements =
    uniq(item.announcements)
      .map(announcmentValue => {
        const obj = find(item.decodedAnnouncements, {
          value: announcmentValue,
        });
        return obj ? obj.key : null;
      })
      .filter(item => item !== null)
      .join('') || '';

  // WHEELS
  // don't do wheels photos here
  vehicle.wheels = (item.wheels || [])
    .filter(wheel => wheel.category !== 'Spare')
    .map(wheel => ({
      category: wheel.category,
      depth: wheel.depth,
      manufacturer: wheel.manufacturer,
      size: wheel.size,
    }));

  // MEDIAS
  vehicle.medias = cloneDeep(item.photos || [])
    .filter(photo => {
      const isCorrectFolder = ['Medias', 'Wheels'].includes(photo.category);
      const isUploaded =
        photo.uploadStatus === 'Success' || !has(photo, 'uploadStatus'); // existing veh photos don't have an uploadStatus
      return isCorrectFolder && isUploaded;
    })
    .map(photo => ({
      type: photo.type || 'Image',
      category: photo.category, // i.e. Medias or Wheels
      description: photo.description, // e.g. Exterior Left Front, Left Front, etc
      photo: photo.name,
    }))
    .sort((a, b) => {
      // sorts according to the sortList
      const sortList = [...MEDIA_TYPES, ...WHEEL_MEDIA_TYPES];
      const callback = (sortItem, image) => {
        return (
          sortItem.category === image.category &&
          (sortItem.description === image.description ||
            sortItem.description + ' ' + 'Wheel' === image.description)
        );
      };

      const aIndex = sortList.findIndex(sortItem => callback(sortItem, a));
      const bIndex = sortList.findIndex(sortItem => callback(sortItem, b));
      if (aIndex === -1 || bIndex === -1) return 1;
      else if (aIndex - bIndex < 0) return -1;
      else if (aIndex - bIndex > 0) return 1;
      else return 0;
    });

  vehicle.conditionItems = (item.condition || []).map(conditionItem => {
    const obj =
      find(item.photos, { descriptionKey: conditionItem.conditionKey }) || {};

    // if a photo exists it will on obj.name so use that.
    // if we don't have a photo we need to pass '(null)' or '', depending:
    //    - pass '(null)' if the condition item had a server photo (established after decode-item)
    //    - pass '' in all other cases
    const photo = obj.name || (conditionItem.hadPhoto ? '(null)' : '');

    return {
      areaDescription: conditionItem.area,
      partDescription: conditionItem.part,
      conditionDescription: conditionItem.damage,
      severityDescription: conditionItem.severity,
      areaCode: conditionItem.areaCode,
      partCode: conditionItem.partCode,
      conditionCode: conditionItem.damageCode,
      severityCode: conditionItem.severityCode,
      materialEstimate: conditionItem.materialEstimate,
      laborHours: conditionItem.laborHours,
      laborCode: conditionItem.laborCode,
      paintLaborHours: conditionItem.paintHours,
      paintLaborCode: conditionItem.paintCode,
      subletEstimate: conditionItem.sublet,
      note: conditionItem.notes,
      actionCode: conditionItem.actionCode,
      actionDescription: conditionItem.actionDescription,
      crType: conditionItem.crType,
      needReview: conditionItem.needReview || '0',
      photo,
    };
  });

  // HEAVY TRUCKS
  // pulls all the loose heavy truck properties and puts them back into an object
  if (item.heavyTruckOption) {
    vehicle.heavyTrucks = HEAVY_TRUCK_PROPERTIES.reduce((obj, key) => {
      const value = item[key] || '';
      return { ...obj, [key]: value };
    }, {});
  }

  // more heavy truck data
  if (item.heavyTruckOption) {
    vehicle.inventoryMeta = INVENTORY_META_PROPERTIES.reduce((obj, key) => {
      let value = item[key];
      if (value === null) value = '';

      // patch until AMWeb changes model since these should be booleans
      const exceptions = ['liftgate', 'mirrorheat', 'heavytrl', 'heavyeqp'];
      if (exceptions.includes(key)) {
        if (value === false) value = 0;
        if (value === true) value = 1;
      }
      return { ...obj, [key]: value };
    }, {});
  }

  // AXLES
  // only send axle data if we have any
  const axlesHaveData =
    item.axles && item.axles.some(axle => hasInfo(axle.data));
  // if we do have axle data
  // send the axle data for that axle, and for the ones that don't send an empty object
  // we can't just filter out axles with no info because we have to preserve the order
  // but we can slice off trailing empty object
  if (axlesHaveData) {
    let axlesData = item.axles.map(axle =>
      hasInfo(axle.data) ? axle.data : {}
    );
    const lastAxleWithInfoIndex = findLastIndex(
      axlesData,
      axle => !isEmpty(axle)
    );
    axlesData = axlesData.slice(0, lastAxleWithInfoIndex + 1);
    vehicle.axles = axlesData;
  }

  if (printer) {
    vehicle.printer = item.printerName || '';
    vehicle.printDocuments = getPrintType(item.printerType) || '';
    vehicle.printCopies = item.printerCopies || '';
  }

  if (item.type === 'P') {
    // handle categories
    vehicle.categories = item.categories;

    // handle attributes
    vehicle.attributes = (item.decodedAttributes || []).map(
      decodedAttribute => {
        const { pk } = decodedAttribute;
        const lookupKey = `attribute_${pk}`;
        const val = item[lookupKey];

        return {
          pk,
          value: isArray(val)
            ? JSON.stringify({ values: val })
            : JSON.stringify({ value: val }),
        };
      }
    );
  }

  console.info('>>ITEM (post-item):', {
    _3_frontend: item,
    _4_backend: vehicle,
  });
  console.info(JSON.stringify(vehicle));
  return vehicle;
};

export { Item };
