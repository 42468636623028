import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { stripHtml } from '../../utils/helpers';

class Announcements extends Component {
  render() {
    const arr = [
      this.props.description,
      this.props.announcements,
      this.props.blockAnnouncements,
    ]
      .filter(text => text)
      .map((text, index) => <p key={index}>{stripHtml(text)}</p>);

    return <div>{arr.length ? arr : <p>No Announcements</p>}</div>;
  }
}

Announcements.propTypes = {
  description: Proptypes.string,
  announcements: Proptypes.string,
  blockAnnouncements: Proptypes.string,
};

export default Announcements;
