import React, { Component } from 'react';

class LeftArrowIcon extends Component {
  render() {
    const { size = 20, color = '#fff' } = this.props;
    return (
      <svg
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill={color}
      >
        <path d="M13.6663 6.16658H3.52467L8.18301 1.50825L6.99967 0.333252L0.333008 6.99992L6.99967 13.6666L8.17467 12.4916L3.52467 7.83325H13.6663V6.16658Z" />
      </svg>
    );
  }
}

export default LeftArrowIcon;
