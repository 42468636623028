import React from 'react';
import DocumentTitle from 'react-document-title';
import { faqs } from './index';

const styles = {
  header: {
    fontSize: 24,
    margin: 40,
    textAlign: 'center',
  },
  listContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  section: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 40,
  },
  question: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  answer: {},
};

const Faq = () => (
  <DocumentTitle title="FAQ">
    <div className="container">
      <h2 style={styles.header}>Frequently Asked Questions</h2>

      {(faqs || []).map((item, index) => (
        <div key={index} style={styles.listContainer}>
          {item.hide ? null : item.section ? (
            <div style={styles.section}>{item.section}</div>
          ) : (
            <>
              <div style={styles.question}>{item.question}</div>
              <div style={styles.answer}>
                {Array.isArray(item.answer)
                  ? item.answer.map((sub, index) => (
                      <span key={index}>{sub}</span>
                    ))
                  : item.answer}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  </DocumentTitle>
);

export default Faq;
