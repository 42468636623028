import React from 'react';
import { makeCompanyCopyright } from '../utils/helpers';
import { urlsMap } from '../utils/constants';

const styles = {
  container: {
    marginTop: 'auto',
    paddingBottom: 8,
    textAlign: 'center',
  },
  divider: {
    borderColor: 'transparent',
  },
  text: {
    fontSize: 12,
    padding: 5,
  },
  link: {
    color: '#4183c4',
  },
};

const Footer = () => {
  return (
    <div style={styles.container}>
      <hr style={styles.divider} />
      <p style={styles.text}>
        {makeCompanyCopyright()}
        {` | `}
        <a
          href={urlsMap.get('PLATFORM_TERMS_OF_USE')}
          target="_blank"
          rel="noreferrer"
          style={styles.link}
        >
          Platform Terms of Use
        </a>
        {` and `}
        <a
          href={urlsMap.get('PLATFORM_PRIVACY_POLICY')}
          target="_blank"
          rel="noreferrer"
          style={styles.link}
        >
          Privacy Policy
        </a>
      </p>
    </div>
  );
};

export default Footer;
