import React, { Component } from 'react';

class EquipmentIcon extends Component {
  render() {
    const { size = 20, color = '#fff' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.5451 3.61336C18.5334 3.59227 18.517 3.57117 18.4982 3.55477C18.381 3.43758 18.1912 3.43758 18.074 3.55477L14.0357 7.59305L12.4443 6.00164L16.4849 1.96102C16.5037 1.94227 16.5178 1.92352 16.5318 1.90242C16.6162 1.75945 16.5693 1.57664 16.4264 1.49227C14.1248 0.13289 11.1107 0.444609 9.13026 2.42273C7.55995 3.99305 7.03964 6.21961 7.57167 8.22117L0.704485 15.0884C0.634172 15.1587 0.638859 15.2735 0.711516 15.3462L4.68886 19.3235C4.76151 19.3962 4.8787 19.4009 4.94667 19.3306L11.8115 12.4657C13.8154 13.0001 16.042 12.4821 17.6146 10.9095C19.5928 8.92899 19.9045 5.91492 18.5451 3.61336ZM16.4217 9.71414C15.0599 11.0759 13.0162 11.3384 11.392 10.5016L11.1857 10.7079L11.1834 10.7055L4.76151 17.1298L2.90527 15.2735L8.29823 9.88055C8.29823 9.88055 8.29823 9.88289 8.30058 9.88289L9.53808 8.64539C8.70136 7.02117 8.96386 4.97742 10.3256 3.6157C10.7648 3.17613 11.2936 2.83654 11.8761 2.62013C12.4586 2.40373 13.0809 2.31561 13.7006 2.3618L10.5857 5.4743C10.445 5.61515 10.366 5.80608 10.366 6.00516C10.366 6.20423 10.445 6.39517 10.5857 6.53602L13.5037 9.45399C13.6445 9.59468 13.8355 9.6737 14.0346 9.6737C14.2336 9.6737 14.4246 9.59468 14.5654 9.45399L17.6779 6.34149C17.7646 7.55555 17.3404 8.79774 16.4217 9.71414Z" />
      </svg>
    );
  }
}

export default EquipmentIcon;
