import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import { urlsMap } from '../../utils/constants';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};

// this page is here to provide a web link to the privacy policy pdf to comply with Google Play Store requirements
const Privacy = props => {
  const url = urlsMap.get('PLATFORM_PRIVACY_POLICY');

  return (
    <div style={styles.container}>
      <Document file={{ url: url }}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};

export default Privacy;
