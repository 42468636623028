import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv4DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M321.1,165.4h-0.3c-1,0.1-1.9,0.3-2.7,0.6l-0.1,0.1l-0.3,0.1v3.5l3.5-0.6L321.1,165.4L321.1,165.4z"
          />
          <path
            fill="#FFFFFF"
            d="M317.7,169.6v-3.5c-1.2,0.4-2.2,0.9-3,1.6c-8.5,9.2-15.7,21.1-21.8,35.2c-5.3,12.6-10.8,27.1-16.2,44
	l-1.6,6.5h20.6c3.8-27.6,9.5-51.6,17.2-71.8h-0.4c2.2-4.8,4.2-7.6,6.3-8.2C318.2,172.6,317.9,171.3,317.7,169.6L317.7,169.6z"
          />
          <path
            fill="#FFFFFF"
            d="M321.1,169.2l-3.5,0.6c0.1,1.7,0.6,2.9,1.2,3.8c0.6,0.6,1.2,1,2,0.9c17.5-0.4,34.5-0.9,51.5-1
	c14.6-0.1,28.9-0.1,43.1,0h11c14.1-0.1,28.4-0.1,43,0c16.9,0.1,34,0.4,51.5,1c0.9,0,1.4-0.3,2-0.9c0.6-0.7,1-2,1.2-3.8l-3.5-0.6
	c-4-0.6-9.2-0.9-15.3-1l-22.9-0.4l-8.5-0.1c-9.7-0.1-19.2-0.1-28.6-0.1h-48.8c-9.4,0-18.9,0-28.6,0.1l-8.5,0.1l-22.9,0.4
	L321.1,169.2L321.1,169.2z"
          />
          <path
            fill="#FFFFFF"
            d="M321,174.2c-0.9,0-1.4-0.3-2-0.9c-2.2,0.7-4.2,3.5-6.3,8.2l0.7-0.7c3.2-2.7,5.8-4.2,7.9-4.3l62-0.9
	c10.8-0.1,21.6,0,32.2,0h11c10.5-0.1,21.2-0.1,32,0l62,0.9c2.2,0.1,4.9,1.6,7.9,4.3l0.7,0.7c-2.2-4.8-4.2-7.6-6.3-8.2
	c-0.6,0.6-1.2,1-2,0.9c-17.5-0.4-34.6-0.9-51.5-1c-14.4-0.1-28.9-0.1-43,0h-10.8c-14.3-0.1-28.6-0.1-43.1,0
	C355.5,173.5,338.3,173.8,321,174.2L321,174.2z M335.7,158.2c-0.3-0.3-0.7-0.4-1-0.3h-1.3c-0.4,0.1-0.7,0.4-1,1l-1.4,4v3.9l6.3-0.6
	v-2.5l1.2-1.6l-0.3-0.4l-1.6-2.6L335.7,158.2L335.7,158.2z"
          />
          <path
            fill="#FFFFFF"
            d="M336.6,159.2l1.6,2.6c0-0.4,1.3-0.7,3.9-0.9c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9
	c2.6,0.1,3.9,0.4,3.9,0.9l1.6-2.6c-0.9-0.9-2.7-1.3-5.5-1.4c-22.9-1.7-45.3-2.7-67.5-2.9l-13.7-0.1l-8.4,0.1
	c-22.4,0.1-45.2,1.2-68.4,2.9C339.1,157.9,337.4,158.4,336.6,159.2L336.6,159.2z M340.2,159.2c0.3-0.3,1-0.4,1.9-0.4
	c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9c0.9,0,1.6,0.1,1.9,0.4c0.3,0.6-0.4,0.7-1.9,0.4
	c-22.6-1.7-44.9-2.7-66.8-2.9h-22.6c-22.4,0.1-45.2,1.2-68.4,2.9C340.6,159.9,339.9,159.8,340.2,159.2z"
          />
          <path
            fill="#FFFFFF"
            d="M342,158.8c-0.9,0-1.6,0.1-1.9,0.4c-0.3,0.6,0.4,0.7,1.9,0.4c23.1-1.7,45.9-2.7,68.4-2.9h22.6
	c21.9,0.3,44.1,1.2,66.8,2.9c1.4,0.1,2,0,1.9-0.4c-0.3-0.3-0.9-0.4-1.9-0.4c-22.6-1.7-44.9-2.7-66.8-2.9h-22.6
	C387.9,156.1,365.1,157.1,342,158.8L342,158.8z"
          />
          <path
            fill="#FFFFFF"
            d="M336.6,159.2l-0.9-1L336.6,159.2c0.9-0.9,2.7-1.3,5.5-1.4c23.1-1.7,45.9-2.7,68.4-2.9l8.4-0.1h1.7l-10,0.1
	c-22.4,0.1-45.2,1.2-68.4,2.9C339.1,157.9,337.4,158.4,336.6,159.2z M257.6,235c-5.8-0.4-11.4,0.3-17.2,1.9
	c-7.2,1.7-11.1,5.6-11.7,11.8v0.3c-0.7,4.6,1,7.6,5.2,9.4c5.5,1.4,13.1,2.3,22.8,2.7l2.2,0.1l1.2-1.2c1.6-1.6,2.9-3.2,4-4.8
	c1.9-2.6,3.5-5.1,4.6-7.6C268.8,240,265,235.8,257.6,235L257.6,235z M260.5,257.2c-1.6,1.7-4.3,2.6-8.1,2.5
	c-7.1-0.4-12.7-1.3-16.9-2.3c-3.8-1.4-5.3-4.2-4.6-8.4v-0.3c0.6-5.6,4-9.1,10.5-10.7c5.1-1.6,10.2-2.2,15.4-1.7
	c5.8,0.7,9.1,3.6,9.8,8.9c0.1,1.9-0.4,3.9-1.4,5.8C264,253.1,262.4,255.2,260.5,257.2L260.5,257.2z"
          />
          <path
            fill="#EBEBEB"
            d="M252.5,259.6c3.8,0.1,6.5-0.7,8.1-2.5h0.1c1.7-2,3.3-4.2,4.5-6.2c1-1.9,1.6-3.9,1.4-5.8
	c-0.7-5.3-4-8.2-9.8-8.9c-5.2-0.3-10.2,0.3-15.4,1.7c-6.5,1.6-10,5.1-10.5,10.7v0.3c-0.7,4.2,0.9,6.9,4.6,8.4
	C239.8,258.5,245.4,259.2,252.5,259.6L252.5,259.6z"
          />
          <path
            fill="#FFFFFF"
            d="M260.1,260.1l-1.2,1.2l14.3,0.3l1-4l1-4l1.6-6.5l-4,0.3l1.2,1.6l-2.7,6.2l-6.9,0.3
	C263,256.9,261.7,258.5,260.1,260.1L260.1,260.1z M312.5,181.6h0.6l0.3-0.6"
          />
          <path
            fill="#FFFFFF"
            d="M313.3,181l-0.3,0.7l68.8,0.1l4,3h70l4.2-3l68.8-0.1l-0.3-0.7c-3.2-2.7-5.8-4.2-7.9-4.3l-62-0.9
	c1,0.3,0.7,1-0.6,2.6l-2.6,2.9c-0.6,0.6-1.9,0.9-3.8,0.7h-61.6c-1.9,0.1-3.2-0.1-3.8-0.7l-2.6-2.9c-1.3-1.6-1.4-2.3-0.4-2.6l-62,0.9
	C319.1,176.8,316.4,178.3,313.3,181L313.3,181z M295.4,256.6l0.4-3.2h-20.6l-1,4l8.5-0.3l0.1-0.3l-0.1,0.3"
          />
          <path
            fill="#FFFFFF"
            d="M295.9,253.4l-0.4,3c-0.9,6.5-1.4,13.9-2,21.8c-0.3,4.2-0.4,8.7-0.7,13.4c-0.3,6.5-0.4,13.6-0.4,20.9v13.3
	h80.2c-3.2-0.1-5.2-2.3-5.9-6.6l-0.4-3c-1.2-8.4-2-18.6-2.5-30.4l-0.3-7.2c0-4.6,2.2-7.2,6.2-7.8c14.4-1.6,30.9-2.5,49.5-2.6h3.5
	c18.6,0,35.1,0.9,49.5,2.6c4.2,0.6,6.2,3,6.2,7.8l-0.3,7.2c-0.4,11.8-1.3,22.1-2.5,30.4l-0.4,3c-0.9,4.3-2.7,6.6-5.9,6.6h80.2v-13.3
	c0-7.4-0.3-14.3-0.4-20.9l-0.7-13.4c-0.4-7.9-1.2-15.1-2-21.8l-0.4-3L295.9,253.4L295.9,253.4z M264.3,255.3l6.9-0.3l2.7-6.2
	l-1.2-1.6l-4,0.3C267.7,250.1,266.2,252.7,264.3,255.3L264.3,255.3z"
          />
          <path
            fill="#D1D3D4"
            d="M282.7,257.2l-8.5,0.4l-1,4l-2.2,9.2l-1.2,7.4h8.2c-3.2-0.7-3.9-4-2.3-10C277,261.8,279.3,258,282.7,257.2
	L282.7,257.2z"
          />
          <path
            fill="#FFFFFF"
            d="M264.7,279.2c-1,0-1.7,0.9-1.9,2.6v6.5c-0.1,1.3,0.9,2,2.9,2.3l3.3-11.4L264.7,279.2L264.7,279.2z"
          />
          <path
            fill="#E6E7E8"
            d="M273.8,286c0.7-4,2.2-6.6,4.2-7.8h-8.2c-0.6,4.2-1.2,8.7-1.4,13.4h8.5c-2.5-0.3-3.5-1.7-3.2-4.6L273.8,286z"
          />
          <path
            fill="#EBEBEB"
            d="M278.1,278.2c-2,1-3.5,3.6-4.2,7.8l-0.1,1c-0.3,2.9,0.9,4.3,3.2,4.6h15.9c0.1-4.6,0.4-9.1,0.7-13.4H278.1z
	 M279.9,287.8c-0.6-0.6-0.9-1.2-0.9-1.9c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9S280.4,288.3,279.9,287.8L279.9,287.8z"
          />
          <path
            fill="#D1D3D4"
            d="M279.1,285.9c0,0.7,0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9c0.7,0,1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9
	c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9c-0.7,0-1.4,0.3-1.9,0.9C279.4,284.4,279.1,285.2,279.1,285.9z"
          />
          <path
            fill="#FFFFFF"
            d="M271.1,270.9l-1.9,8.4l-3.3,11.4c-1.4,4.5-3,8.7-4.8,12.4l0.7,22.8h5.9c-0.1-4.6-0.1-9.1-0.1-13.3
	c0-7.4,0.3-14.4,0.9-20.9c0.3-4.8,0.9-9.2,1.4-13.4L271.1,270.9z"
          />
          <path
            fill="#D1D3D4"
            d="M275.7,268.3c-1.6,5.9-0.9,9.2,2.3,10h15.4c0.4-7.9,1.2-15.1,2-21.8l-12.7,0.6
	C279.3,258,277,261.8,275.7,268.3L275.7,268.3z M282.9,270.3c-0.7,0-1.4-0.3-1.9-0.9c-0.4-0.6-0.9-1.2-0.9-1.9
	c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9c0.7,0,1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9c0,0.7-0.3,1.4-0.9,1.9
	C284.2,270,283.6,270.3,282.9,270.3z"
          />
          <path
            fill="#E6E7E8"
            d="M280.9,269.6c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9s-0.3-1.4-0.9-1.9
	c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9c-0.6,0.6-0.9,1.2-0.9,1.9C280.1,268.3,280.4,269,280.9,269.6z"
          />
          <path
            fill="#EBEBEB"
            d="M313,181.6c-7.6,20.2-13.3,44.1-17.2,71.8H546c-3.8-27.6-9.5-51.6-17.2-71.8l-68.8,0.1l-4,3h-70l-4-3
	L313,181.6L313,181.6z"
          />
          <path
            fill="#D1D3D4"
            d="M458,178.4c1.3-1.6,1.4-2.3,0.6-2.6c-10.8-0.1-21.5,0-32,0h-11.1c-10.7-0.1-21.4-0.1-32.2,0
	c-1,0.3-0.9,1,0.4,2.6l2.6,2.9c0.6,0.6,1.9,0.9,3.8,0.7h61.6c1.9,0.1,3.2-0.1,3.8-0.7L458,178.4L458,178.4z"
          />
          <path
            fill="#FFFFFF"
            d="M445.3,167.4v-6.2l-7.9-0.1v5.8v-5.8h-33v5.8v-5.8l-7.8,0.1v6.2 M445.3,161.2v6.2c9.4,0,18.9,0,28.6,0.1
	v-5.5C464.7,161.7,455.1,161.4,445.3,161.2L445.3,161.2z"
          />
          <path
            fill="#FFFFFF"
            d="M473.9,167.6l8.5,0.1v-5.3l-8.5-0.4 M482.4,162.4v5.2l22.9,0.4c6.2,0.1,11.3,0.4,15.3,1v-3.6l-9.7-1v2.6
	l-6.3-0.6V164l-1.2-0.1l-11.7-0.9h-0.1L482.4,162.4L482.4,162.4z"
          />
          <path
            fill="#FFFFFF"
            d="M505.3,159.2l-1.6,2.6l-0.3,0.4l1.2,1.6v2.5l6.3,0.6V163l-1.4-4c-0.3-0.6-0.6-1-1-1h-1.3c-0.3,0-0.7,0-1,0.3
	C505.9,158.5,505.6,158.8,505.3,159.2L505.3,159.2z"
          />
          <path
            fill="#FFFFFF"
            d="M503.5,162.4v1.4l1.2,0.1 M505.3,159.2c-0.9-0.9-2.7-1.3-5.5-1.4c-21.4-1.6-42.4-2.6-63.2-2.9h-4.2
	c22.1,0.3,44.6,1.2,67.5,2.9C502.6,157.9,504.5,158.4,505.3,159.2c0.3-0.3,0.6-0.7,0.7-1C505.9,158.5,505.6,158.8,505.3,159.2
	L505.3,159.2z M523.8,166.1c-1-0.3-1.9-0.4-2.9-0.6h-0.3v3.6l3.5,0.6v-3.5L523.8,166.1L523.8,166.1z"
          />
          <path
            fill="#FFFFFF"
            d="M524.1,166.3v3.5c-0.1,1.7-0.6,2.9-1.2,3.8c2.2,0.7,4.2,3.5,6.3,8.2h-0.4c7.6,20.2,13.3,44.1,17.2,71.8h20.6
	l-1.6-6.5c-5.3-16.7-10.8-31.4-16.2-44c-5.9-14.1-13.3-25.8-21.8-35.2C526.3,167.2,525.2,166.6,524.1,166.3L524.1,166.3z M368,162
	v5.6c9.7-0.1,19.2-0.1,28.6-0.1v-6.2C386.6,161.4,377.1,161.7,368,162L368,162z M433.1,154.9l-12.7-0.1h-1.7l13.7,0.1 M363.5,278.5
	l0.3,7.2h7.8c-0.6-6.5,1.3-10,5.9-10.4c12.7-1.2,26.5-1.7,41.8-1.7h3.5c15.1,0,29.1,0.6,41.8,1.7c4.6,0.3,6.5,3.8,5.9,10.4h7.8
	l0.3-7.2c0-4.6-2.2-7.2-6.2-7.8c-14.4-1.6-30.9-2.5-49.5-2.6h-3.5c-18.6,0-35.1,0.9-49.5,2.6C365.6,271.3,363.5,273.9,363.5,278.5
	L363.5,278.5z M371.5,285.7h-7.8c0.4,11.8,1.3,22.1,2.5,30.4h109.5c1.2-8.4,2-18.6,2.5-30.4L371.5,285.7L371.5,285.7z M441.9,289.3
	c1,0,1.6,0.6,1.6,1.6v19.3c0,1-0.6,1.6-1.6,1.6H400c-1,0-1.6-0.6-1.6-1.6v-19.3c0-1,0.6-1.6,1.6-1.6L441.9,289.3L441.9,289.3z"
          />
          <path
            fill="#FFFFFF"
            d="M443.3,290.9c0-1-0.6-1.6-1.6-1.6h-41.8c-1,0-1.6,0.6-1.6,1.6v19.3c0,1,0.6,1.6,1.6,1.6h41.8
	c1,0,1.6-0.6,1.6-1.6V290.9z M377.4,275.5c-4.6,0.3-6.5,3.8-5.9,10.4h99c0.6-6.5-1.3-10-5.9-10.4c-12.7-1.2-26.5-1.7-41.8-1.7h-3.5
	C403.9,273.6,390.1,274.3,377.4,275.5L377.4,275.5z M415.9,277.5c0.3,0,0.3-0.1,0.3-0.3v-0.9l0.1-0.3l0.1-0.1h8.4
	c0.1,0,0.3,0,0.4,0.1l0.1,0.4v1h7.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0,0.4l-0.3,0.4l-0.4,0.7l-1.7,2.7l-0.3,0.3h-4.8
	c-0.1,0-0.3,0.1-0.3,0.3v0.9l-0.1,0.3l-0.3,0.1l-0.4,0.1h-8.4c-0.1,0-0.3-0.1-0.3-0.1l-0.1-0.3v-1h-7.4l-0.4-0.1l-0.1-0.3l0.1-0.3
	l2.3-3.9c0.1-0.3,0.3-0.3,0.6-0.3L415.9,277.5L415.9,277.5z"
          />
          <path
            fill="#CCCCCC"
            d="M416.2,277.2c0,0.1-0.1,0.3-0.3,0.3h-4.8c-0.3,0-0.4,0.1-0.6,0.3l-2.3,3.9l-0.1,0.3l0.1,0.3l0.4,0.1h7.4v1
	l0.1,0.3c0,0.1,0.1,0.1,0.3,0.1h8.4l0.4-0.1l0.3-0.1l0.1-0.3v-0.9c0-0.1,0.1-0.3,0.3-0.3h4.8l0.3-0.3l1.7-2.7l0.4-0.7l0.3-0.4
	c0.1-0.1,0.1-0.3,0-0.4s-0.3-0.1-0.4-0.1h-7.5v-1l-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-8.4c-0.1,0-0.1,0-0.1,0.1l-0.1,0.3
	L416.2,277.2L416.2,277.2z M410.8,281h-0.1l-0.1-0.1v-0.1l1-1.7c0.1-0.1,0.3-0.3,0.6-0.3h5.6v-1.4h5.9l0.3,0.1v1.3l0.4,0.1h6.5v0.3
	l-1,1.7c-0.1,0.1-0.1,0.1-0.3,0.1h-5.2l-0.4,0.3v0.9l-0.1,0.3h-6.2v-1.3l-0.3-0.3L410.8,281L410.8,281z"
          />
          <path
            fill="#FFFFFF"
            d="M410.6,281h6.9l0.3,0.3v1.3h6.2l0.1-0.3v-0.9l0.1-0.3h5.3l0.4-0.1l1-1.7v-0.1l-0.4-0.3h-6.4l-0.1-0.1v-1.3
	l-0.3-0.1h-5.9v1.4h-5.6c-0.3,0-0.4,0-0.6,0.3l-1,1.7v0.1L410.6,281L410.6,281z M411,281h-0.4h0.3"
          />
          <path
            fill="#FFFFFF"
            d="M424.1,278.7h0.1l0.1,0.1h0.1 M424.3,281.1l-0.1,0.1l0.4-0.3h-0.1 M430.8,278.8l0.4,0.1v-0.1 M429.6,281
	c0.3,0,0.4,0,0.4-0.1L429.6,281L429.6,281z M528.6,181l0.3,0.6h0.4 M558.9,256.9l0.3,0.3l8.5,0.3l-1-4H546l0.4,3.2l12.7,0.6
	 M573,247.7l-4-0.3l-1.2,1.6l2.7,6.2l6.9,0.3C575.6,252.7,574.1,250.1,573,247.7L573,247.7z"
          />
          <path
            fill="#FFFFFF"
            d="M573,247.5c1,2.6,2.6,5.2,4.5,7.8c1.2,1.6,2.6,3.2,4,4.8l1.2,1.2l2.2-0.1c9.7-0.4,17.3-1.4,22.8-2.7
	c4.2-1.6,5.9-4.8,5.2-9.4v-0.3c-0.6-6.2-4.6-10.1-11.7-11.8c-5.8-1.7-11.4-2.3-17.2-1.9C576.7,235.8,573.1,240,573,247.5L573,247.5z
	 M610.9,248.7v0.3c0.7,4.2-0.9,6.9-4.6,8.4c-4.2,1-9.8,1.9-16.9,2.3c-3.8,0.1-6.5-0.7-8.1-2.5h-0.1c-1.7-2-3.3-4.2-4.5-6.2
	c-1-1.9-1.6-3.9-1.4-5.8c0.7-5.3,4-8.2,9.8-8.9c5.2-0.3,10.2,0.3,15.4,1.7C606.9,239.6,610.4,243.2,610.9,248.7L610.9,248.7z"
          />
          <path
            fill="#EBEBEB"
            d="M610.9,249v-0.3c-0.6-5.6-4-9.1-10.5-10.7c-5.2-1.6-10.2-2.2-15.4-1.7c-5.8,0.7-9.1,3.6-9.8,8.9
	c-0.1,1.9,0.4,3.9,1.4,5.8c1.2,2,2.7,4.2,4.5,6.2h0.1c1.6,1.7,4.3,2.6,8.1,2.5c7.1-0.4,12.7-1.3,16.9-2.3
	C610.1,255.9,611.7,253.1,610.9,249L610.9,249z"
          />
          <path
            fill="#FFFFFF"
            d="M566.6,253.4l1,4l1,4.2l14.3-0.3l-1.2-1.2c-1.6-1.6-2.9-3.2-4-4.8l-6.9-0.3l-2.7-6.2l1.2-1.6l-4-0.3
	L566.6,253.4L566.6,253.4z"
          />
          <path
            fill="#D1D3D4"
            d="M567.7,257.5l-8.5-0.4c3.5,0.9,5.8,4.6,6.9,11.1c1.6,5.9,0.9,9.2-2.3,10h8.2l-1.2-7.4l-2.2-9.2L567.7,257.5
	L567.7,257.5z"
          />
          <path
            fill="#E6E7E8"
            d="M572,278.2h-8.2c2,1,3.5,3.6,4.2,7.8l0.1,1c0.3,2.9-0.9,4.3-3.2,4.6h8.5C573.1,286.9,572.6,282.4,572,278.2
	L572,278.2z"
          />
          <path
            fill="#EBEBEB"
            d="M567.9,286c-0.7-4-2.2-6.6-4.2-7.8h-15.4l0.7,13.4h15.9c2.5-0.3,3.5-1.7,3.2-4.6L567.9,286L567.9,286z
	 M558.1,287.8c-0.6-0.6-0.7-1.2-0.7-1.9c0-0.7,0.3-1.4,0.7-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9S558.6,288.3,558.1,287.8L558.1,287.8z"
          />
          <path
            fill="#D1D3D4"
            d="M557.3,285.9c0,0.7,0.3,1.4,0.7,1.9c0.6,0.6,1.2,0.9,1.9,0.9c0.7,0,1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9
	c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9c-0.7,0-1.4,0.3-1.9,0.9C557.6,284.4,557.3,285.2,557.3,285.9z"
          />
          <path
            fill="#FFFFFF"
            d="M572.7,279.2l-1.9-8.4l1.2,7.4c0.6,4.2,1.2,8.7,1.4,13.4c0.4,6.5,0.7,13.4,0.9,20.9c0,4.3,0,8.8-0.1,13.3
	h5.9l0.7-22.8c-1.7-3.9-3.3-7.9-4.8-12.4L572.7,279.2L572.7,279.2z"
          />
          <path
            fill="#D1D3D4"
            d="M563.8,278.2c3.2-0.7,3.9-4,2.3-10c-1.3-6.5-3.6-10.2-6.9-11.1l-12.7-0.6c0.9,6.5,1.4,13.9,2,21.8h15.3
	L563.8,278.2L563.8,278.2z M556.3,267.6c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9s-1.4-0.3-1.9-0.9C556.5,269,556.3,268.3,556.3,267.6L556.3,267.6z"
          />
          <path
            fill="#E6E7E8"
            d="M557.1,265.7c-0.6,0.6-0.9,1.2-0.9,1.9c0,0.7,0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9
	c0.6-0.6,0.9-1.2,0.9-1.9c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9C558.3,265,557.6,265.3,557.1,265.7L557.1,265.7z"
          />
          <path
            fill="#FFFFFF"
            d="M579.1,281.8c-0.3-1.7-0.9-2.6-1.9-2.6h-4.3l3.3,11.4c2.2-0.3,3.2-1,2.9-2.3L579.1,281.8L579.1,281.8z
	 M580.1,326v-0.1h-30.6v9.1l29.4-0.3"
          />
          <path
            fill="#646464"
            d="M580.1,326l-1.2,8.8c-2,14-4.3,25.1-6.8,33.2c-1.2,3.6-2.3,6.8-3.6,9.2c-4.5,8.1-9.1,14.7-13.8,19.5l2.6,0.4
	h0.1c0.6,0.1,0.7,0.4,0.4,1.2c-0.3,0.9-1,1.7-2.2,2.9c-1.2,1.2-2.3,2-3.6,2.9s-2.5,1.2-3.2,1.2l-0.6-0.1l-4.2-0.4
	c-1.3,0.6-2.6,1.2-3.9,1.6c-0.7,0.4-1.6,0.9-2.3,0.9v40.8c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.7-3,8.8-8.7L580.1,326L580.1,326z"
          />
          <path
            fill="#E6E7E8"
            d="M574.1,325.8c0.1-4.6,0.1-9.1,0.1-13.3h-24.7v13.3H574.1L574.1,325.8z"
          />
          <path
            fill="#D1D3D4"
            d="M573.4,291.7h-8.5c2.2,0.7,3.5,4.3,3.5,10.8c0.4,6.5-1.3,9.8-5.2,10.1h11C574.3,305.1,574,298.1,573.4,291.7
	L573.4,291.7z"
          />
          <path
            fill="#D1D3D4"
            d="M563.3,312.6c3.9-0.3,5.5-3.6,5.2-10.1c-0.1-6.5-1.3-10.1-3.5-10.8h-15.9c0.3,6.5,0.4,13.6,0.4,20.9H563.3z
	 M563,299.7c0.6,0.6,0.7,1.2,0.7,1.9s-0.3,1.4-0.7,1.9c-0.6,0.6-1.2,0.9-1.9,0.9s-1.4-0.3-1.9-0.9c-0.6-0.6-0.9-1.2-0.9-1.9
	s0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9C561.7,298.9,562.5,299.2,563,299.7L563,299.7z"
          />
          <path
            fill="#E6E7E8"
            d="M563.8,301.6c0-0.7-0.3-1.4-0.7-1.9c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9c-0.6,0.6-0.9,1.2-0.9,1.9
	s0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9C563.5,302.9,563.8,302.3,563.8,301.6L563.8,301.6z"
          />
          <path
            fill="#FFFFFF"
            d="M549.5,334.9v-9.1H292.3l0.1,9.1l0.9,13.9h255.3"
          />
          <path
            fill="#FFFFFF"
            d="M548.6,348.8l-0.3,4l-1.2,14.9H572c2.6-8.1,4.8-19.2,6.8-33.2l-29.4,0.3L548.6,348.8L548.6,348.8z"
          />
          <path
            fill="#FFFFFF"
            d="M548.3,353l0.3-4.2H293.3l0.1,4.2 M547.2,367.8l1.2-14.9H293.4l1.2,14.9h92.6H269.8c1.2,3.6,2.3,6.8,3.6,9.2
	c4.2,7.5,8.4,13.6,12.7,18.3l-2.2-4.8c-0.9-2.6-0.1-4,2.2-4.2H367l2.6,3.9H472l2.6-3.9h77.3c2.7,0.1,3.6,1.7,2.6,5.2
	c-0.7,1.7-1.6,3.3-2.6,4.6l2.5,0.4c4.8-4.9,9.4-11.4,13.8-19.5c1.3-2.5,2.5-5.6,3.6-9.2L547.2,367.8l-92.6,0L547.2,367.8
	L547.2,367.8z M448.2,358.3c3.6,0.3,5.6,2.2,5.9,5.6v14.9h-66.5v-14.9c0.3-3.5,2.3-5.3,5.9-5.6H448.2z"
          />
          <path
            fill="#FFFFFF"
            d="M454.1,363.9c-0.3-3.5-2.3-5.3-5.9-5.6h-54.7c-3.6,0.3-5.6,2.2-5.9,5.6v14.9h66.5L454.1,363.9L454.1,363.9z"
          />
          <path
            fill="#646464"
            d="M542,392.1c0.7,0.9,1.2,1.9,1.6,2.9l8.7,1.3c1-1.4,1.9-2.9,2.6-4.6c1-3.3,0.1-5-2.6-5.2h-24.1l-0.1,2.7
	l8.4,0.4h0.6C538.9,389.5,540.5,390.3,542,392.1z"
          />
          <path
            fill="#EBEBEB"
            d="M543.4,394.9c-0.3-1-0.9-2-1.6-2.9c-1.4-1.7-3-2.6-5-2.6h-0.6c-1.7,0.1-3.2,1-4.3,2.6c-1.4,1.7-2,3.9-2,6.3
	c0,2.5,0.7,4.5,2,6.2c1.3,1.6,2.7,2.5,4.5,2.6h0.1l1.4-0.1c0.9-0.1,1.6-0.4,2.3-0.9c0.6-0.4,1.2-1,1.6-1.6l0.1-0.3l-3.8-0.3h-0.4
	c-1.3,0-2.3-0.4-3.2-1.4s-1.3-2.2-1.3-3.5c0-1.4,0.4-2.6,1.3-3.5c0.9-1,1.9-1.4,3.2-1.4L543.4,394.9L543.4,394.9z"
          />
          <path
            fill="#EBEBEB"
            d="M537.9,394.1c-1.3,0-2.3,0.4-3.2,1.4c-0.9,1-1.3,2.2-1.3,3.5c0,1.3,0.4,2.5,1.3,3.5c0.9,1,1.9,1.4,3.2,1.4
	h0.4l3.8,0.3l2,0.3l4.2,0.4c-0.3-0.1-0.3-0.6-0.1-1c0.3-0.9,1-1.7,2.2-2.9c1.2-1.2,2.5-2,3.8-2.9c1.3-0.9,2.5-1.2,3.2-1.2l-2.6-0.4
	l-2.5-0.4l-8.7-1.3L537.9,394.1L537.9,394.1z"
          />
          <path
            fill="#EBEBEB"
            d="M531.9,392.1c1.3-1.6,2.7-2.3,4.3-2.6l-8.4-0.4l-0.1,2l-0.1,0.3l-0.3,0.1c-0.1,0-0.3-0.1-0.3-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-2l-11.3-0.6l-0.1,1.7l-0.1,0.3l-0.4,0.1c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-1.7
	l-1.6-0.1l-14.1-0.7h-0.4c-2.2,0-3.9,0.9-5.5,2.6c-1.6,1.7-2.3,3.9-2.3,6.3c0,1.2,0.1,2.2,0.4,3.2v0.1c0.3,1,0.9,2,1.7,3
	c1.4,1.7,3.3,2.6,5.5,2.6l13.7,0.6l15.7,0.7l3,0.1l5.3,0.3c-1.7-0.1-3.2-1-4.5-2.6c-1.4-1.7-2-3.8-2-6.2
	C529.9,395.8,530.6,393.8,531.9,392.1L531.9,392.1z"
          />
          <path
            fill="#646464"
            d="M557.4,397h-0.1c-0.7,0-1.7,0.4-3.2,1.2c-1.3,0.9-2.6,1.7-3.8,2.9c-1.2,1.2-1.9,2-2.2,2.9
	c-0.1,0.6-0.1,0.9,0.1,1l0.6,0.1c0.7,0,1.7-0.4,3.2-1.2c1.3-0.9,2.6-1.7,3.6-2.9c1.2-1.2,1.9-2,2.2-2.9
	C558.1,397.5,558,397.1,557.4,397L557.4,397z"
          />
          <path
            fill="#FFFFFF"
            d="M544.1,404.6l-2-0.3l-0.1,0.3c-0.6,0.6-1,1.2-1.6,1.6C541.5,405.8,542.8,405.2,544.1,404.6z M531.2,408.2
	v-1.2l-0.1,1.2 M531.2,407.1v1.2c2.5,0.1,4.8,0,6.8-0.4v-0.7l-1.4,0.1h-0.1L531.2,407.1L531.2,407.1z"
          />
          <path
            fill="#646464"
            d="M531.2,407.1l-3-0.3v1.9l-10-0.7c-3.6-0.3-5.5-1-5.8-1.9l-13.7-0.6c-2.2,0-3.9-0.9-5.5-2.6
	c-0.9-0.9-1.3-1.9-1.7-3c-1.7-0.6-2.7-1.6-3.3-2.9c-0.3-1.6,0.1-2.3,1.3-2.3l-2-5.2h-4.6V400h4.3v8.1c1.9,0.9,3.9,1.6,6.2,2.3
	c8.8,2.6,21.5,3.5,37.8,2.6v-4.6H531L531.2,407.1L531.2,407.1z"
          />
          <path
            fill="#646464"
            d="M537.9,407.8c-2.2,0.4-4.5,0.6-6.8,0.4v4.6c-16.3,0.9-29,0-37.8-2.6l-57.3,1.2c-2.9,1-7.5,1.6-14.1,1.6h-2
	c-6.6,0-11.3-0.6-14.1-1.6l-57.3-1.2c-4.2,1.2-9.1,2-14.9,2.5c-5.8,0.4-12.4,0.6-19.9,0.3l-3-0.1v-3.9l-6.8-0.6v5l10,0.6
	c28,1.6,61.9,2.3,101.7,2.3H429c43.6,0,79.9-1,109.1-2.9v-5.6L537.9,407.8L537.9,407.8z"
          />
          <path
            fill="#646464"
            d="M537.9,423.8v-10.4c-29.1,1.9-65.5,2.9-109.1,2.9h-13.4c-39.8,0-73.7-0.7-101.7-2.3l-10-0.6v10.4H537.9z"
          />
          <path
            fill="#FFFFFF"
            d="M475.2,319.2l0.4-3H366.1l0.4,3c0.9,4.3,2.7,6.6,5.9,6.6h24.4l5.2-3.3h37.7l5.2,3.3h24.4
	C472.4,325.8,474.5,323.5,475.2,319.2z"
          />
          <path
            fill="#646464"
            d="M474.8,386.4l-2.6,3.9H369.7l-2.6-3.9h-7.9v14.4c-0.3,3.5,0.9,5.9,3.6,7.2c1.7,0.9,4,1.2,6.9,1h102.4
	c2.9,0.1,5.2-0.3,6.9-1c2.7-1.3,3.9-3.6,3.6-7.2v-14.4H474.8L474.8,386.4z"
          />
          <path fill="#646464" d="M482.7,386.4v2.9h4.3v-0.9l-0.7-2" />
          <path
            fill="#EBEBEB"
            d="M490.8,396.5c0-2.5,0.7-4.6,2.3-6.4c1.4-1.7,3.3-2.6,5.5-2.6h0.4l-0.4-1.3h-12.3l0.7,2.2l0.3,0.9l2,5.2
	l1.9,5C490.9,398.7,490.8,397.7,490.8,396.5L490.8,396.5z"
          />
          <path
            fill="#FFFFFF"
            d="M489.3,394.5c-1,0-1.4,0.7-1.3,2.3c0.4,1.4,1.6,2.3,3.3,2.9v-0.1L489.3,394.5L489.3,394.5z M487,389.3h0.4
	l-0.4-0.9"
          />
          <path fill="#646464" d="M498.6,386.4l0.4,1.2l14.1,0.7v-1.9" />
          <path fill="#FFFFFF" d="M514.7,386.4h-1.6v1.9l1.4,0.1" />
          <path
            fill="#FFFFFF"
            d="M514.6,388.5l-0.1,1.7c0,0.1,0,0.3,0.1,0.4s0.3,0.1,0.3,0.1l0.4-0.1l0.1-0.3l0.1-1.7l0.1-2.2h-1L514.6,388.5
	L514.6,388.5z"
          />
          <path fill="#646464" d="M515.7,386.4l-0.1,2l11.3,0.6l0.1-2.6" />
          <path
            fill="#FFFFFF"
            d="M528.1,386.4h-1L527,389l-0.1,2c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.3,0.1l0.3-0.1l0.1-0.3l0.1-2
	L528.1,386.4L528.1,386.4z M402,322.5l-5,3.3h47.9l-5.2-3.3"
          />
          <path
            fill="#646464"
            d="M359.2,386.4H356l-1.3,1.6v1h4.5 M353.9,389l0.9-1.2v-1.6h-6.3l0.1,1l2.9,0.1c1.6,0.3,2.3,0.7,2.3,1.3
	L353.9,389L353.9,389z"
          />
          <path fill="#FFFFFF" d="M354.7,388l-0.9,1h0.9" />
          <path
            fill="#646464"
            d="M353.7,389.3l0.1-0.3v-0.1c0-0.6-0.9-1-2.3-1.3l-2.9-0.1l0.1,3.9c1.2-0.1,2.2-0.4,3-0.9
	c0.9-0.3,1.6-0.7,1.9-1L353.7,389.3L353.7,389.3z"
          />
          <path fill="#FFFFFF" d="M353.7,389v0.3l0.1-0.3" />
          <path
            fill="#646464"
            d="M353.7,389.3l-0.1,0.1c-0.3,0.3-1,0.7-1.9,1c-0.9,0.3-1.9,0.6-3,0.9l0.3,3.5l2.2-0.6
	c1.4-0.3,2.3,0.3,2.6,1.7c0.3,1.6-1.3,2.7-4.5,3.6l0.1,2.5h3l0.3-0.1l2.3,0.1v-13h-0.9L353.7,389.3L353.7,389.3z"
          />
          <path fill="#FFFFFF" d="M354.7,386.4v1.6l1.3-1.6" />
          <path
            fill="#646464"
            d="M353.6,396.1c-0.3-1.6-1.2-2.2-2.6-1.7l-2.2,0.6l0.3,4.8C352.4,398.8,353.9,397.5,353.6,396.1L353.6,396.1z
	 M359.2,389h-4.3v18.8h7.9c-2.7-1.3-3.9-3.6-3.6-7.2L359.2,389L359.2,389z M422,413.1c6.6,0,11.3-0.6,14.1-1.6l2.7-2.5h-35.8
	l2.7,2.5c2.9,1,7.5,1.6,14.1,1.6L422,413.1L422,413.1z"
          />
          <path
            fill="#646464"
            d="M438.8,408.9l-2.7,2.5l57.3-1.2c-2.3-0.7-4.3-1.4-6.2-2.3h-7.9c-1.7,0.9-4,1.2-6.9,1L438.8,408.9
	L438.8,408.9z M405.8,411.5l-2.7-2.5h-33.3c-2.9,0.1-5.2-0.3-6.9-1h-7.9c-1.9,0.9-3.9,1.6-6.2,2.3L405.8,411.5L405.8,411.5z
	 M354.7,403.9v-1.7h-2.5l-3,1.4l0.1,1.7l2.9-1.4"
          />
          <path fill="#646464" d="M354.7,407.9v-4h-2.5l-2.9,1.4l-0.4,3.5" />
          <path fill="#FFFFFF" d="M352.4,402l-0.1,0.1h2.5" />
          <path
            fill="#646464"
            d="M528,408.8v-1.9l-15.7-0.7c0.3,1,2.2,1.6,5.8,1.9L528,408.8L528,408.8z M487,407.9v-8.1h-4.3v0.9
	c0.3,3.5-0.9,5.9-3.6,7.2H487z"
          />
          <path
            fill="#D1D3D4"
            d="M292.4,312.6c0-7.4,0.1-14.3,0.4-20.9H277c-2.3,0.7-3.5,4.3-3.5,10.8c-0.4,6.5,1.3,9.8,5.2,10.1L292.4,312.6
	L292.4,312.6L292.4,312.6z M278.9,299.7c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9s-0.3,1.4-0.9,1.9
	c-0.6,0.6-1.2,0.9-1.9,0.9s-1.3-0.3-1.9-0.9c-0.6-0.6-0.9-1.2-0.9-1.9C278.1,300.9,278.4,300.2,278.9,299.7L278.9,299.7z"
          />
          <path
            fill="#E6E7E8"
            d="M280.7,298.9c-0.7,0-1.3,0.3-1.9,0.9s-0.9,1.2-0.9,1.9s0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9
	s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9s-0.3-1.4-0.9-1.9C282.2,299.2,281.5,298.9,280.7,298.9L280.7,298.9z"
          />
          <path
            fill="#D1D3D4"
            d="M278.6,312.6c-3.9-0.3-5.5-3.6-5.2-10.1c0.1-6.5,1.3-10.1,3.5-10.8h-8.5c-0.4,6.5-0.7,13.4-0.9,20.9H278.6
	L278.6,312.6z"
          />
          <path
            fill="#E6E7E8"
            d="M278.6,312.6h-11c0,4.3,0,8.8,0.1,13.3h24.5v-13.3H278.6z"
          />
          <path
            fill="#FFFFFF"
            d="M261.8,325.8v0.1l1.2,8.7l29.4,0.3l-0.1-9.1h-24.5"
          />
          <path
            fill="#646464"
            d="M263,334.6l-1.2-8.8l0.1,121.9c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.8-3,8.8-8.7v-48.5l-13.7-1h-2.7l-1.4-3
	c-4.3-4.8-8.7-10.8-12.7-18.3c-1.3-2.5-2.5-5.6-3.6-9.2C267.2,359.7,265,348.6,263,334.6L263,334.6z"
          />
          <path
            fill="#FFFFFF"
            d="M292.4,334.9l-29.4-0.3c2,14,4.3,25.1,6.8,33.2h24.8l-1.2-14.9l-0.3-4L292.4,334.9L292.4,334.9z
	 M287.1,391.5c-1-3.3-0.1-5,2.7-5.2h-3.6c-2.3,0.3-3,1.6-2.2,4.2l2.2,4.8l1.4,3h2.7L287.1,391.5L287.1,391.5z"
          />
          <path
            fill="#646464"
            d="M289.7,386.4c-2.7,0.1-3.8,1.7-2.7,5.2l3.2,6.9l13.7,1l1.6,0.1l5.2,0.4l18.3,1.3v-15L289.7,386.4
	L289.7,386.4z M329.1,386.4v2.7h9.1c-0.4-1.7,0.6-2.6,3-2.7H329.1L329.1,386.4z"
          />
          <path
            fill="#646464"
            d="M338.1,389h-9.1v12.3l-18.3-1.3v3.9l3-0.1l7.6-0.1V402h23.7l-0.1-1.6l-3.5,0.3c-1.7-0.4-2.3-1.2-1.6-2.5
	c0.9-1.3,2.5-2.2,4.9-2.5l-0.3-4.2l-3.2-0.3c-1.4-0.3-2.2-0.7-2.3-1.2L338.1,389L338.1,389z"
          />
          <path
            fill="#646464"
            d="M338.1,389.2l0.9,1c0-0.4,0.3-0.9,1-1.2c0.3-0.1,0.6-0.3,1-0.4c0.9-0.3,2-0.6,3.2-0.9l-0.1-1.4h-2.9
	C338.7,386.4,337.7,387.3,338.1,389.2L338.1,389.2z"
          />
          <path
            fill="#646464"
            d="M348.4,387.4l-0.1-1h-4.3l0.1,1.4l0.3,3.9l0.3,4.2l0.3,4.6l0.1,1.6l0.3,5.2v0.4c0.3,1.4,1.6,1.7,3.5,1
	l0.4-3.5l-0.1-1.7v-1.4l-0.1-2.5l-0.3-4.8l-0.3-3.5L348.4,387.4L348.4,387.4z"
          />
          <path
            fill="#646464"
            d="M344.2,387.9c-1.2,0.1-2.3,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1,0.4c-0.7,0.4-1,0.9-1,1.2c0,0.6,0.9,1,2.3,1.2
	l3.2,0.3L344.2,387.9z"
          />
          <path fill="#FFFFFF" d="M349.2,402.2v1.4l3-1.4" />
          <path
            fill="#646464"
            d="M339.9,398.4c-0.6,1.3-0.1,2,1.6,2.5l3.5-0.3l-0.3-4.6C342.3,396.2,340.7,397.1,339.9,398.4L339.9,398.4z
	 M349,408.8c-2,0.7-3.2,0.4-3.5-1v-0.4c-5-0.3-8.9-2-11.7-5.2v10.7c5.8-0.4,10.7-1.3,14.9-2.5c2.3-0.7,4.3-1.4,6.2-2.3L349,408.8
	L349,408.8z"
          />
          <path
            fill="#646464"
            d="M345.5,407.4l-0.3-5.2h-11.4C336.6,405.3,340.4,407.1,345.5,407.4L345.5,407.4z"
          />
          <path
            fill="#646464"
            d="M333.8,402.2h-3.9v4.9c-3,1.2-8.4,1.9-16,2v4c7.5,0.3,14.1,0.1,19.9-0.3V402.2L333.8,402.2z"
          />
          <path
            fill="#646464"
            d="M329.9,407.1v-4.9H326v4.2h-12.3v2.7C321.6,408.9,326.9,408.2,329.9,407.1z"
          />
          <path
            fill="#646464"
            d="M321.4,402.2v1.4l-7.6,0.3v2.3H326v-4 M313.8,406.2v-2.3h-3v8.9l3,0.1v-3.9"
          />
          <path
            fill="#646464"
            d="M310.7,409.1v-2.9H304v2.2 M310.7,406.2V400l-5.2-0.4v6.6 M304,406.2h1.6v-6.6l-1.6-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M321.1,165.4v3.8l15.3-1l23.1-0.4v-5.3l-21.1,1.4l-1.2,0.1v2.5l-6.3,0.6v-2.6h-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M337.3,164l1.2-0.1v-1.4 M359.5,167.7l8.5-0.1V162l-8.5,0.4"
          />
          <path
            fill="none"
            d="M295.4,256.6l-12.7,0.6 M278.1,278.2h15.3 M282.7,257.2c-3.5,0.9-5.8,4.6-6.9,11.1c-1.6,5.9-0.9,9.2,2.3,10
	 M269.9,278.2h8.2 M279.1,285.9c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9c0.7,0,1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9c-0.7,0-1.4-0.3-1.9-0.9C279.4,287.3,279.1,286.6,279.1,285.9z"
          />
          <path
            fill="none"
            d="M278.1,278.2c-2,1-3.5,3.6-4.2,7.8l-0.1,1c-0.3,2.9,0.9,4.3,3.2,4.6 M280.9,269.6c-0.6-0.6-0.9-1.2-0.9-1.9
	s0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9s-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9
	C282,270.3,281.4,270,280.9,269.6z M282.9,256.9l-0.1,0.3 M282.7,257.2l-8.5,0.4 M559.1,257.2l-0.3-0.3 M567.7,257.5l-8.5-0.3
	 M556.3,267.6c0,0.7,0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9c0-0.7-0.3-1.4-0.9-1.9
	c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9C556.5,266.3,556.3,266.8,556.3,267.6L556.3,267.6z M558.1,287.8
	c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9
	s-1.4,0.3-1.9,0.9c-0.6,0.6-0.7,1.2-0.7,1.9C557.3,286.6,557.6,287.3,558.1,287.8L558.1,287.8z M563.8,278.2h8.2 M564.9,291.7
	c2.5-0.3,3.5-1.7,3.2-4.6l-0.1-1c-0.7-4-2.2-6.6-4.2-7.8"
          />
          <path
            fill="none"
            d="M563.8,278.2c3.2-0.7,3.9-4,2.3-10c-1.3-6.5-3.6-10.2-6.9-11.1 M548.3,278.2h15.4 M559.1,257.2l-12.7-0.6
	 M292.8,291.7h-16 M573.4,291.7h-8.5 M563,299.7c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9c-0.6,0.6-0.9,1.2-0.9,1.9
	s0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.7-1.2,0.7-1.9S563.5,300.2,563,299.7L563,299.7z M563.3,312.6
	c3.9-0.3,5.5-3.6,5.2-10.1c-0.1-6.5-1.3-10.1-3.5-10.8 M549.5,312.6h13.8 M564.9,291.7H549 M574.3,312.6h-11 M280.7,298.9
	c0.7,0,1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9s-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9s-1.3-0.3-1.9-0.9
	c-0.6-0.6-0.9-1.2-0.9-1.9s0.3-1.4,0.9-1.9C279.4,299.2,280,298.9,280.7,298.9L280.7,298.9z M278.6,312.6c-3.9-0.3-5.5-3.6-5.2-10.1
	c0.1-6.5,1.3-10.1,3.5-10.8 M276.8,291.7h-8.5 M278.6,312.6h-11 M292.4,312.6h-13.8 M258.9,261.4l14.3,0.3 M313.3,181
	c3.2-2.7,5.8-4.2,7.9-4.3l62-0.9 M319,173.4c-2.2,0.7-4.2,3.5-6.3,8.2 M313,181.6c-7.6,20.2-13.3,44.1-17.2,71.8 M275.2,253.4h20.6
	 M273.2,261.6l-2.2,9.2 M267.7,325.8c-0.1-4.6-0.1-9.1-0.1-13.3c0-7.4,0.3-14.4,0.9-20.9c0.3-4.8,0.9-9.1,1.4-13.4l1.2-7.4
	 M271.1,270.9l-1.9,8.4 M269.2,279.2h-4.3c-1,0-1.7,0.9-1.9,2.6v6.5c-0.1,1.3,0.9,2,2.9,2.3 M275.2,253.4l1.6-6.5 M272.8,247.2
	l-4,0.4 M276.8,246.9l-4,0.3 M273.2,261.6l1-4.2l1-4 M268.8,247.7c-1.2,2.6-2.6,5.1-4.6,7.6 M264.3,255.3l6.9-0.3l2.7-6.2l-1.2-1.6
	 M295.9,253.4l-0.4,3c-0.9,6.5-1.4,13.9-2,21.8c-0.3,4.2-0.4,8.7-0.7,13.4c-0.3,6.5-0.4,13.6-0.4,20.9v13.3 M312.5,181.6l0.9-0.6
	 M313.3,181l-0.3,0.6 M312.5,181.6h0.6 M264.3,255.3c-1.2,1.6-2.6,3.2-4,4.8l-1.2,1.2 M252.5,259.6c-7.1-0.4-12.7-1.3-16.9-2.3
	c-3.8-1.4-5.3-4.2-4.6-8.4v-0.3c0.6-5.6,4-9.1,10.5-10.7c5.1-1.6,10.2-2.2,15.4-1.7c5.8,0.7,9.1,3.6,9.8,8.9
	c0.1,1.9-0.4,3.9-1.4,5.8c-1.2,2-2.7,4.2-4.5,6.2h-0.1C258.9,259,256.2,259.8,252.5,259.6L252.5,259.6z"
          />
          <path
            fill="none"
            d="M268.8,247.7c0-7.6-3.8-11.8-11.1-12.6c-5.8-0.4-11.4,0.3-17.2,1.9c-7.2,1.7-11.1,5.6-11.7,11.8v0.3
	c-0.7,4.6,1,7.6,5.2,9.4c5.5,1.4,13.1,2.3,22.8,2.7l2.2,0.1 M321.1,169.2l15.3-1l23.1-0.4 M338.1,161.8c0-0.4,1.3-0.7,3.9-0.9
	c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9c2.6,0.1,3.9,0.4,3.9,0.9 M418.6,154.8l-8.4,0.1
	c-22.4,0.1-45.2,1.2-68.4,2.9c-2.7,0.1-4.6,0.6-5.5,1.4 M330.9,164.4v2.6l6.3-0.6V164 M335.7,158.2l0.9,1"
          />
          <path
            fill="none"
            d="M420.4,154.8l-10,0.1c-22.4,0.1-45.2,1.2-68.4,2.9c-2.7,0.1-4.6,0.6-5.5,1.4l-0.9-1 M338.4,163.8v-1.4
	 M336.6,159.2l1.6,2.6 M338.1,161.8l0.3,0.6 M338.4,162.4l-1.2,1.6 M337.3,164l1.2-0.1 M330.9,164.4h-0.1l-9.7,1 M335.7,158.2
	c-0.3-0.3-0.7-0.4-1-0.3h-1.3c-0.4,0.1-0.7,0.4-1,1l-1.4,4v1.3"
          />
          <path
            fill="none"
            d="M342,158.8c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9c0.9,0,1.6,0.1,1.9,0.4
	c0.3,0.6-0.4,0.7-1.9,0.4c-22.6-1.7-44.9-2.7-66.8-2.9h-22.6c-22.4,0.1-45.2,1.2-68.4,2.9c-1.4,0.1-2,0-1.9-0.4
	C340.5,159.1,341,158.9,342,158.8L342,158.8z M317.7,166.3c-1.2,0.4-2.2,0.9-3,1.6c-8.5,9.2-15.7,21.1-21.8,35.2
	c-5.3,12.6-10.8,27.1-16.2,44 M319,173.4c-0.6-0.7-1-2-1.2-3.8"
          />
          <path
            fill="none"
            d="M522.9,173.4c-0.6,0.6-1.2,1-2,0.9c-17.5-0.4-34.6-0.9-51.5-1c-14.4-0.1-28.9-0.1-43,0h-11
	c-14.3-0.1-28.6-0.1-43.1,0c-16.9,0.1-34,0.4-51.5,1c-0.9,0-1.4-0.3-2-0.9 M321.1,169.2l-3.5,0.4 M317.7,169.6v-3.3 M321.1,165.4
	v3.8 M317.7,166.3l0.3-0.1l0.1-0.1c0.9-0.3,1.7-0.4,2.7-0.6h0.3 M383.3,175.8c-1,0.3-0.9,1,0.4,2.6l2.6,2.9c0.6,0.6,1.9,0.9,3.8,0.7
	h61.6c1.9,0.1,3.2-0.1,3.8-0.7l2.6-2.9c1.3-1.6,1.4-2.3,0.6-2.6 M445.3,161.2v6.2 M473.9,167.6l8.5,0.1 M482.4,167.7v-5.3
	 M473.9,167.6V162 M482.4,162.4l-8.5-0.4 M445.3,167.4c9.4,0,18.9,0,28.6,0.1 M473.9,162c-9.2-0.4-18.8-0.7-28.6-0.9 M505.3,159.2
	l-1.6,2.6 M503.8,161.8l-0.3,0.6 M503.5,162.4v1.4 M504.6,164l-1.2-1.6 M503.5,163.8l1.2,0.1 M506.1,158.2c-0.3,0.1-0.6,0.6-0.7,1
	c-0.9-0.9-2.7-1.3-5.5-1.4c-21.4-1.6-42.4-2.6-63.2-2.9h-3.6"
          />
          <path
            fill="none"
            d="M432.3,154.9c22.1,0.3,44.6,1.2,67.5,2.9c2.7,0.1,4.6,0.6,5.5,1.4 M505.3,159.2c0.3-0.4,0.6-0.7,0.7-1
	 M503.5,163.8l-11.7-0.9l-9.4-0.6 M511,164.4v2.6l-6.3-0.6V164 M511,164.4v-1.3l-1.4-4c-0.3-0.6-0.6-1-1-1h-1.3c-0.3,0-0.7,0-1,0.3
	 M520.6,165.4l-9.5-1H511 M524.1,166.3l-0.3-0.1l-0.1-0.1c-0.9-0.3-1.7-0.4-2.7-0.6h-0.3 M524.1,169.6v-3.3 M565.1,246.9
	c-5.3-16.7-10.8-31.4-16.2-44c-5.9-14.1-13.3-25.8-21.8-35.2c-0.9-0.7-1.9-1.2-3-1.6 M520.6,169.2v-3.8 M524.1,169.6
	c-0.1,1.7-0.6,2.9-1.2,3.8 M520.6,169.2l3.5,0.4 M482.4,167.7l22.9,0.4c6.2,0.1,11.3,0.4,15.3,1 M458.6,175.8l62,0.9
	c2.2,0.1,4.9,1.6,7.9,4.3 M359.5,167.7l8.5-0.1 M359.5,162.4v5.3 M368,162l-8.5,0.4 M368,167.6V162 M404.4,161.1v5.8 M396.6,167.4
	v-6.2 M404.4,161.1l-7.8,0.1 M437.4,166.9v-5.8 M433.1,154.9l-12.7-0.1 M418.6,154.8l13.7,0.1 M420.4,154.8h-1.7 M433.1,154.9h-0.7
	 M437.4,161.1h-33 M458.6,175.8c-10.8-0.1-21.5,0-32,0h-11.1c-10.7-0.1-21.4-0.1-32.2,0 M368,167.6c9.7-0.1,19.2-0.1,28.6-0.1
	 M396.6,161.2c-10,0.1-19.5,0.4-28.6,0.9 M528.8,181.6l-68.8,0.1l-4,3h-70.1l-4-3l-68.8-0.1 M478.1,285.7l0.3-7.2
	c0-4.6-2.2-7.2-6.2-7.8c-14.4-1.6-30.9-2.5-49.5-2.6h-3.5c-18.6,0-35.1,0.9-49.5,2.6c-4,0.6-6.2,3-6.2,7.8l0.3,7.2 M371.5,285.7
	h-7.8"
          />
          <path
            fill="none"
            d="M470.4,285.7c0.6-6.5-1.3-10-5.9-10.4c-12.7-1.2-26.5-1.7-41.8-1.7h-3.5c-15.1,0-29.1,0.6-41.8,1.7
	c-4.6,0.3-6.5,3.8-5.9,10.4"
          />
          <path
            fill="none"
            d="M415.9,277.5h-4.8c-0.3,0-0.4,0.1-0.6,0.3l-2.3,3.9l-0.1,0.3l0.1,0.3l0.4,0.1h7.4v1l0.1,0.3
	c0,0.1,0.1,0.1,0.3,0.1h8.4l0.4-0.1l0.3-0.1l0.1-0.3v-0.9c0-0.1,0.1-0.3,0.3-0.3h4.8l0.3-0.3l1.7-2.7l0.4-0.7l0.3-0.4
	c0.1-0.1,0.1-0.3,0-0.4s-0.3-0.1-0.4-0.1h-7.5v-1l-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-8.4c-0.1,0-0.1,0-0.1,0.1l-0.1,0.3v0.9
	C416.3,277.4,416.2,277.5,415.9,277.5L415.9,277.5z"
          />
          <path
            fill="none"
            d="M410.8,281h6.8l0.3,0.3v1.3h6.2l0.1-0.3v-0.9l0.1-0.3h5.5c0.1,0,0.3,0,0.3-0.1l1-1.7V279h-6.8l-0.3-0.3v-1.3
	l-0.3-0.1h-5.9v1.4h-5.6c-0.3,0-0.4,0-0.6,0.3l-1,1.7v0.1L410.8,281L410.8,281z M528.6,181l0.3,0.6 M478.1,285.7h-7.6 M437.4,161.1
	l7.9,0.1 M371.5,285.7h99 M396.6,167.4h48.8 M573,247.7l-4-0.4 M582.8,261.4l2.2-0.1c9.7-0.4,17.3-1.4,22.8-2.7
	c4.2-1.6,5.9-4.8,5.2-9.4v-0.3c-0.6-6.2-4.6-10.1-11.7-11.8c-5.8-1.7-11.4-2.3-17.2-1.9c-7.4,0.9-11.1,5.1-11.1,12.6 M566.6,253.4
	l1,4l1,4.2 M577.6,255.3l-7.1-0.3l-2.6-6.2l1-1.6 M566.6,253.4l-1.6-6.5 M569,247.2l-3.9-0.3 M546,253.4l0.4,3
	c0.9,6.5,1.4,13.9,2,21.8l0.7,13.4c0.3,6.5,0.4,13.6,0.4,20.9v13.3 M570.8,270.9l-2.2-9.2 M572.7,279.2l-1.9-8.4 M570.8,270.9
	l1.2,7.4c0.6,4.2,1.2,8.7,1.4,13.4c0.4,6.5,0.7,13.4,0.9,20.9c0,4.3,0,8.8-0.1,13.3 M546,253.4h20.6"
          />
          <path
            fill="none"
            d="M610.9,248.7c-0.6-5.6-4-9.1-10.5-10.7c-5.2-1.6-10.2-2.2-15.4-1.7c-5.8,0.7-9.1,3.6-9.8,8.9
	c-0.1,1.9,0.4,3.9,1.4,5.8c1.2,2,2.7,4.2,4.5,6.2h0.1c1.6,1.7,4.3,2.6,8.1,2.5c7.1-0.4,12.7-1.3,16.9-2.3c3.8-1.4,5.3-4.2,4.6-8.4
	L610.9,248.7L610.9,248.7z M582.8,261.4l-1.2-1.2c-1.6-1.6-2.9-3.2-4-4.8 M576,290.6c2.2-0.3,3.2-1,2.9-2.3v-6.5
	c-0.3-1.7-0.9-2.6-1.9-2.6h-4.3 M573,247.7c1.2,2.6,2.6,5.1,4.6,7.6 M568.7,261.6l14.1-0.3 M528.8,181.6
	c7.6,20.2,13.3,44.1,17.2,71.8 M529.3,181.6h-0.4 M528.6,181l0.7,0.6 M522.9,173.4c2.2,0.7,4.2,3.5,6.3,8.2 M338.4,163.8l21.1-1.4
	 M295.9,253.4H546 M580.1,325.8l0.7-22.8c-1.7-3.9-3.3-7.9-4.8-12.4 M580.1,326v-0.1 M578.9,334.6l1.2-8.7 M580.1,325.8h-5.9
	 M549.5,334.9v-9.1 M548.3,353l0.3-4.2 M547.2,367.8l1.2-14.9 M543.4,394.9c-0.3-1-0.9-2-1.6-2.9c-1.4-1.7-3-2.6-5-2.6h-0.6
	 M543.4,394.9l-5.6-0.9c-1.3,0-2.3,0.4-3.2,1.4s-1.3,2.2-1.3,3.5c0,1.3,0.4,2.5,1.3,3.5s1.9,1.4,3.2,1.4h0.4l3.8,0.3"
          />
          <path
            fill="none"
            d="M536.3,389.5c-1.7,0.1-3.2,1-4.3,2.6c-1.4,1.7-2,3.9-2,6.3c0,2.5,0.7,4.5,2,6.2c1.3,1.6,2.7,2.5,4.5,2.6
	 M554.7,396.5c4.8-4.9,9.4-11.4,13.8-19.5c1.3-2.5,2.5-5.6,3.6-9.2 M557.1,397l-2.5-0.4 M548.3,404.9l0.6,0.1c0.7,0,1.7-0.4,3.2-1.2
	c1.3-0.9,2.6-1.7,3.6-2.9c1.2-1.2,1.9-2,2.2-2.9c0.3-0.7,0.1-1-0.4-1.2h-0.1"
          />
          <path
            fill="none"
            d="M528.1,386.4h24.1c2.7,0.1,3.6,1.7,2.6,5.2c-0.7,1.7-1.6,3.3-2.6,4.6 M554.7,396.5l-2.5-0.3 M572.1,367.8h-25
	 M552.2,396.2l-8.8-1.3 M572.1,367.8c2.6-8.1,4.8-19.2,6.8-33.2 M549.5,325.8h24.7 M578.9,334.6l-29.4,0.3 M548.6,348.8l0.9-13.9
	 M557.1,397c-0.7,0-1.7,0.4-3.2,1.2c-1.3,0.9-2.6,1.7-3.8,2.9s-1.9,2-2.2,2.9c-0.1,0.6-0.1,0.9,0.1,1 M542.1,404.3l2,0.3
	 M544.1,404.6l4.2,0.3 M542.1,404.3l-0.1,0.3c-0.6,0.6-1,1.2-1.6,1.6 M531.2,407.1v1.2 M531.2,406.9v0.1 M531.2,407.1l-0.1,1.2h0.1
	 M537.9,407.1c0.9-0.1,1.6-0.4,2.3-1 M536.3,407.2h0.1l1.4-0.1 M537.9,407.8v-0.7 M536.3,407.2l-5.2-0.3 M537.9,407.8
	c-2.2,0.4-4.5,0.6-6.8,0.4 M537.9,423.8v-10.4 M537.9,413.4v-5.6 M540.2,406.2c1.3-0.4,2.6-1,3.9-1.6 M531.2,408.2v4.6
	c-16.3,0.9-29,0-37.8-2.6"
          />
          <path
            fill="none"
            d="M537.9,423.8v24.1c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.7-3,8.8-8.7l0.1-121.9 M443.3,290.9v19.3
	c0,1-0.6,1.6-1.6,1.6h-41.8c-1,0-1.6-0.6-1.6-1.6v-19.3c0-1,0.6-1.6,1.6-1.6h41.8C442.9,289.3,443.3,289.9,443.3,290.9L443.3,290.9z
	 M469.3,325.8c3.2-0.1,5.2-2.3,5.9-6.6l0.4-3 M469.3,325.8h-24.4 M448.2,358.3h-54.7c-3.6,0.3-5.6,2.2-5.9,5.6v14.9h66.5v-14.9
	C453.8,360.5,451.8,358.6,448.2,358.3z M482.7,386.4h-7.9l-2.6,3.8H369.7l-2.6-3.8h-7.9 M482.7,389.3v-2.9 M491.3,399.7
	c-0.3-1-0.4-2-0.4-3.2c0-2.5,0.7-4.6,2.3-6.4c1.4-1.7,3.3-2.6,5.5-2.6h0.4"
          />
          <path
            fill="none"
            d="M489.3,394.5c-1,0-1.4,0.7-1.3,2.3c0.4,1.4,1.6,2.3,3.3,2.9 M487.4,389.3l1.9,5.2 M487,389.3h0.4 M487,388.5
	l0.4,0.9 M487,389.3v-0.9 M486.3,386.4l0.7,2 M498.6,386.4l0.4,1.2 M498.6,386.4h-12.3 M514.7,386.4h-1.6 M513.1,388.3l1.4,0.1
	 M514.6,388.5l-0.1,1.7c0,0.1,0,0.3,0.1,0.4s0.3,0.1,0.3,0.1l0.4-0.1l0.1-0.3l0.1-1.7 M515.7,386.4l-0.1,2 M514.6,388.5l0.1-2
	 M515.7,386.4h-1 M513.1,386.4v1.9 M528.1,386.4H527 M527.8,389l0.3-2.6 M526.8,389l0.1-2.6 M527.8,389l-0.1,2l-0.1,0.3l-0.3,0.1
	c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-2 M527,386.4h-11.3 M515.6,388.5l11.3,0.6 M513.1,386.4h-14.6 M499,387.6
	l14.1,0.7 M482.7,389.3h4.3 M486.3,386.4h-3.6 M444.9,325.8l-5.2-3.3H402l-5,3.3 M366.1,316.2l0.4,3c0.9,4.3,2.7,6.6,5.9,6.6
	 M359.2,386.4H356 M359.2,386.4v2.6 M354.7,388l-0.9,1 M354.7,389v-1 M353.7,389v0.3 M348.4,387.4l2.9,0.1c1.6,0.3,2.3,0.7,2.3,1.3
	v0.1 M353.7,389.3l-0.1,0.1c-0.3,0.3-1,0.7-1.9,1c-0.9,0.3-1.9,0.6-3,0.9 M353.9,389l-0.1,0.3 M353.7,389h0.1 M353.9,389h0.9
	 M354.7,386.4v1.6 M349.1,399.7c3.3-0.9,4.8-2,4.5-3.6c-0.3-1.6-1.2-2.2-2.6-1.7l-2.2,0.6 M359.2,389h-4.5 M354.7,388l1.3-1.6
	 M356,386.4h-1.3 M397,325.8h-24.5 M405.8,411.5c2.9,1,7.5,1.6,14.1,1.6h2c6.6,0,11.3-0.6,14.1-1.6 M436.1,411.5l2.7-2.6
	 M405.8,411.5l-2.7-2.6 M403.1,408.9h35.8 M537.9,413.4c-29.1,1.9-65.5,2.9-109.1,2.9h-13.4c-39.8,0-73.7-0.7-101.7-2.3l-10-0.6
	 M403.1,408.9h-33.3c-2.9,0.1-5.2-0.3-6.9-1 M354.7,403.9v-1.7 M354.7,407.9v-4 M354.7,402.2l-2.3-0.1l-0.1,0.1 M352.3,402.2h2.5
	 M354.7,403.9h-2.5l-2.9,1.4 M362.7,407.9h-7.9 M359.2,389v11.7c-0.3,3.5,0.9,5.9,3.6,7.2 M512.3,406c0.3,1,2.2,1.6,5.8,1.9l10,0.7
	v-1.9 M528,406.8l-15.7-0.7 M491.3,399.8c0.3,1,0.9,2,1.7,3c1.4,1.7,3.3,2.6,5.5,2.6l13.7,0.6 M487,407.9v-8.1h-4.3 M493.4,410.2
	c-2.3-0.7-4.3-1.4-6.2-2.3 M491.3,399.8v-0.1 M438.8,408.9h33.3c2.9,0.1,5.2-0.3,6.9-1"
          />
          <path
            fill="none"
            d="M479.1,407.9c2.7-1.3,3.9-3.6,3.6-7.2v-0.9 M479.1,407.9h7.9 M482.7,399.8v-10.5 M354.7,402.2V389 M436.1,411.5
	l57.3-1.3 M366.1,316.2h109.5 M489.3,394.5l2,5.2 M444.9,325.8H397 M549.5,325.8h-80.2 M536.3,389.5l-8.5-0.4 M528,406.8l3.2,0.1
	 M547.2,367.8h-92.5 M265.7,290.6c-1.4,4.5-3,8.7-4.8,12.4l0.7,22.8 M261.8,325.8v0.1 M263,334.6l-1.2-8.7 M261.8,325.8h5.9
	 M292.4,334.9l-0.1-9.1 M292.3,325.8h-24.5 M292.4,334.9l-29.4-0.3 M294.6,367.8l-1.2-14.9 M293.4,353l-0.1-4.2 M289.7,386.4
	c-2.7,0.1-3.8,1.7-2.7,5.2l3.2,6.9"
          />
          <path
            fill="none"
            d="M286.1,395.4l-2.2-4.8c-0.9-2.6-0.1-4,2.2-4.2h3.6 M269.8,367.8c1.2,3.6,2.3,6.8,3.6,9.2
	c4.2,7.5,8.4,13.6,12.7,18.3 M294.6,367.8h-24.8 M329.1,389v-2.6 M329.1,389h9.1 M338.1,389v0.1l0.9,1.2 M348.4,387.4v-1
	 M341.2,386.4h2.9 M344.1,386.4l0.1,1.4 M344.2,387.9c-1.2,0.1-2.3,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1,0.4c-0.7,0.4-1,0.9-1,1.2
	 M339,390.3c0,0.6,0.9,1,2.3,1.2l3.2,0.3 M344.5,391.8l0.3,4.2 M348.8,394.9l-0.1-3.6 M348.7,391.3l-0.3-3.9 M344.5,391.8l-0.3-3.9
	 M348.4,386.4h-4.3 M341.2,386.4c-2.5,0-3.5,1-3,2.7 M329.1,386.4h12.1 M263,334.6c2,14,4.3,25.1,6.8,33.2 M289.7,386.4h39.4
	 M293.3,348.8l-0.9-13.9 M349.2,402.2l-0.1-2.5 M345.1,400.6l0.1,1.6 M349.2,403.6v-1.4 M344.8,396c-2.5,0.3-4,1.2-4.9,2.5
	c-0.6,1.3-0.1,2,1.6,2.5l3.5-0.3 M349,408.8c-2,0.7-3.2,0.4-3.5-1v-0.4 M349.4,405.3l-0.4,3.5 M345.5,407.4l-0.3-5.2 M349.4,405.3
	l-0.1-1.7 M333.8,402.2h-3.9 M329.9,402.2v4.9c-3,1.2-8.4,1.9-16,2 M345.2,402.2h-11.4 M333.8,412.7c5.8-0.4,10.7-1.3,14.9-2.5
	 M333.8,402.2v10.5 M345.5,407.4c-5-0.3-8.9-2-11.7-5.2 M326,402.2h-4.6v1.4l-7.6,0.3"
          />
          <path
            fill="none"
            d="M313.8,406.2H326v-4 M313.8,403.9v2.3 M310.7,409.1v-2.9 M310.7,406.2v-2.3 M313.8,409.1v-2.9 M310.7,403.9V400
	 M313.8,403.9h-3 M310.7,409.1v3.8l3,0.1 M313.8,409.1v3.9 M313.8,413c7.5,0.3,14.1,0.1,19.9-0.3 M310.7,400l18.3,1.3V389
	 M329.9,402.2H326 M304,406.2h1.6 M304,408.4v-2.2 M305.5,399.6v6.6 M304,399.4l1.6,0.1 M304,406.2v-6.8 M290.2,398.4h-2.7l-1.4-3
	 M304,413.4v10.4 M304,413.4v-5 M304,399.4l-13.7-1"
          />
          <path
            fill="none"
            d="M261.8,326l0.1,121.9c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.8-3,8.8-8.7v-24.1 M304,408.4l6.8,0.7 M305.5,406.2
	h5.2 M310.7,400l-5.2-0.4 M345.1,400.6l-0.3-4.6 M349.1,399.7l-0.3-4.8 M304,423.8h234 M372.5,325.8h-80.2 M348.4,386.4h6.3
	 M352.3,402.2h-3 M349.2,403.6l3-1.4 M348.5,410.2l57.3,1.3 M548.6,348.8H293.3 M293.4,353h254.9 M387.2,367.8h-92.6 M354.7,407.9
	c-1.9,0.9-3.9,1.6-6.2,2.3 M349,408.8l5.8-0.9 M363.7,285.7c0.4,11.8,1.3,22.1,2.5,30.4 M475.6,316.2c1.2-8.4,2-18.6,2.5-30.4
	 M269.2,279.2l-3.5,11.4 M572.7,279.2l3.3,11.4 M321.1,165.4h-0.3c-1,0.1-1.9,0.3-2.7,0.6l-0.1,0.1l-0.3,0.1v3.5l3.5-0.6
	L321.1,165.4L321.1,165.4z"
          />
          <path
            fill="none"
            d="M317.7,169.6v-3.5c-1.2,0.4-2.2,0.9-3,1.6c-8.5,9.2-15.7,21.1-21.8,35.2c-5.3,12.6-10.8,27.1-16.2,44l-1.6,6.5
	h20.6c3.8-27.6,9.5-51.6,17.2-71.8h-0.4c2.2-4.8,4.2-7.6,6.3-8.2C318.2,172.6,317.9,171.3,317.7,169.6L317.7,169.6z"
          />
          <path
            fill="none"
            d="M321.1,169.2l-3.5,0.6c0.1,1.7,0.6,2.9,1.2,3.8c0.6,0.6,1.2,1,2,0.9c17.5-0.4,34.5-0.9,51.5-1
	c14.6-0.1,28.9-0.1,43.1,0h11c14.1-0.1,28.4-0.1,43,0c16.9,0.1,34,0.4,51.5,1c0.9,0,1.4-0.3,2-0.9c0.6-0.7,1-2,1.2-3.8l-3.5-0.6
	c-4-0.6-9.2-0.9-15.3-1l-22.9-0.4l-8.5-0.1c-9.7-0.1-19.2-0.1-28.6-0.1h-48.8c-9.4,0-18.9,0-28.6,0.1l-8.5,0.1l-22.9,0.4
	L321.1,169.2L321.1,169.2z"
          />
          <path
            fill="none"
            d="M321,174.2c-0.9,0-1.4-0.3-2-0.9c-2.2,0.7-4.2,3.5-6.3,8.2l0.7-0.7c3.2-2.7,5.8-4.2,7.9-4.3l62-0.9
	c10.8-0.1,21.6,0,32.2,0h11c10.5-0.1,21.2-0.1,32,0l62,0.9c2.2,0.1,4.9,1.6,7.9,4.3l0.7,0.7c-2.2-4.8-4.2-7.6-6.3-8.2
	c-0.6,0.6-1.2,1-2,0.9c-17.5-0.4-34.6-0.9-51.5-1c-14.4-0.1-28.9-0.1-43,0h-10.8c-14.3-0.1-28.6-0.1-43.1,0
	C355.5,173.5,338.3,173.8,321,174.2L321,174.2z M335.7,158.2c-0.3-0.3-0.7-0.4-1-0.3h-1.3c-0.4,0.1-0.7,0.4-1,1l-1.4,4v3.9l6.3-0.6
	v-2.5l1.2-1.6l-0.3-0.4l-1.6-2.6L335.7,158.2L335.7,158.2z"
          />
          <path
            fill="none"
            d="M336.6,159.2l1.6,2.6c0-0.4,1.3-0.7,3.9-0.9c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9
	c2.6,0.1,3.9,0.4,3.9,0.9l1.6-2.6c-0.9-0.9-2.7-1.3-5.5-1.4c-22.9-1.7-45.3-2.7-67.5-2.9l-13.7-0.1l-8.4,0.1
	c-22.4,0.1-45.2,1.2-68.4,2.9C339.1,157.9,337.4,158.4,336.6,159.2L336.6,159.2z M340.2,159.2c0.3-0.3,1-0.4,1.9-0.4
	c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9c0.9,0,1.6,0.1,1.9,0.4c0.3,0.6-0.4,0.7-1.9,0.4
	c-22.6-1.7-44.9-2.7-66.8-2.9h-22.6c-22.4,0.1-45.2,1.2-68.4,2.9C340.6,159.9,339.9,159.8,340.2,159.2z"
          />
          <path
            fill="none"
            d="M342,158.8c-0.9,0-1.6,0.1-1.9,0.4c-0.3,0.6,0.4,0.7,1.9,0.4c23.1-1.7,45.9-2.7,68.4-2.9h22.6
	c21.9,0.3,44.1,1.2,66.8,2.9c1.4,0.1,2,0,1.9-0.4c-0.3-0.3-0.9-0.4-1.9-0.4c-22.6-1.7-44.9-2.7-66.8-2.9h-22.6
	C387.9,156.1,365.1,157.1,342,158.8L342,158.8z"
          />
          <path
            fill="none"
            d="M336.6,159.2l-0.9-1L336.6,159.2c0.9-0.9,2.7-1.3,5.5-1.4c23.1-1.7,45.9-2.7,68.4-2.9l8.4-0.1h1.7l-10,0.1
	c-22.4,0.1-45.2,1.2-68.4,2.9C339.1,157.9,337.4,158.4,336.6,159.2z M257.6,235c-5.8-0.4-11.4,0.3-17.2,1.9
	c-7.2,1.7-11.1,5.6-11.7,11.8v0.3c-0.7,4.6,1,7.6,5.2,9.4c5.5,1.4,13.1,2.3,22.8,2.7l2.2,0.1l1.2-1.2c1.6-1.6,2.9-3.2,4-4.8
	c1.9-2.6,3.5-5.1,4.6-7.6C268.8,240,265,235.8,257.6,235L257.6,235z M260.5,257.2c-1.6,1.7-4.3,2.6-8.1,2.5
	c-7.1-0.4-12.7-1.3-16.9-2.3c-3.8-1.4-5.3-4.2-4.6-8.4v-0.3c0.6-5.6,4-9.1,10.5-10.7c5.1-1.6,10.2-2.2,15.4-1.7
	c5.8,0.7,9.1,3.6,9.8,8.9c0.1,1.9-0.4,3.9-1.4,5.8C264,253.1,262.4,255.2,260.5,257.2L260.5,257.2z"
          />
          <path
            fill="none"
            d="M252.5,259.6c3.8,0.1,6.5-0.7,8.1-2.5h0.1c1.7-2,3.3-4.2,4.5-6.2c1-1.9,1.6-3.9,1.4-5.8c-0.7-5.3-4-8.2-9.8-8.9
	c-5.2-0.3-10.2,0.3-15.4,1.7c-6.5,1.6-10,5.1-10.5,10.7v0.3c-0.7,4.2,0.9,6.9,4.6,8.4C239.8,258.5,245.4,259.2,252.5,259.6
	L252.5,259.6z M260.1,260.1l-1.2,1.2l14.3,0.3l1-4l1-4l1.6-6.5l-4,0.3l1.2,1.6l-2.7,6.2l-6.9,0.3C263,256.9,261.7,258.5,260.1,260.1
	L260.1,260.1z M312.5,181.6h0.6l0.3-0.6"
          />
          <path
            fill="none"
            d="M313.3,181l-0.3,0.7l68.8,0.1l4,3h70l4.2-3l68.8-0.1l-0.3-0.7c-3.2-2.7-5.8-4.2-7.9-4.3l-62-0.9
	c1,0.3,0.7,1-0.6,2.6l-2.6,2.9c-0.6,0.6-1.9,0.9-3.8,0.7h-61.6c-1.9,0.1-3.2-0.1-3.8-0.7l-2.6-2.9c-1.3-1.6-1.4-2.3-0.4-2.6l-62,0.9
	C319.1,176.8,316.4,178.3,313.3,181L313.3,181z M295.4,256.6l0.4-3.2h-20.6l-1,4l8.5-0.3l0.1-0.3l-0.1,0.3"
          />
          <path
            fill="none"
            d="M295.9,253.4l-0.4,3c-0.9,6.5-1.4,13.9-2,21.8c-0.3,4.2-0.4,8.7-0.7,13.4c-0.3,6.5-0.4,13.6-0.4,20.9v13.3h80.2
	c-3.2-0.1-5.2-2.3-5.9-6.6l-0.4-3c-1.2-8.4-2-18.6-2.5-30.4l-0.3-7.2c0-4.6,2.2-7.2,6.2-7.8c14.4-1.6,30.9-2.5,49.5-2.6h3.5
	c18.6,0,35.1,0.9,49.5,2.6c4.2,0.6,6.2,3,6.2,7.8l-0.3,7.2c-0.4,11.8-1.3,22.1-2.5,30.4l-0.4,3c-0.9,4.3-2.7,6.6-5.9,6.6h80.2v-13.3
	c0-7.4-0.3-14.3-0.4-20.9l-0.7-13.4c-0.4-7.9-1.2-15.1-2-21.8l-0.4-3L295.9,253.4L295.9,253.4z M264.3,255.3l6.9-0.3l2.7-6.2
	l-1.2-1.6l-4,0.3C267.7,250.1,266.2,252.7,264.3,255.3L264.3,255.3z M282.7,257.2l-8.5,0.4l-1,4l-2.2,9.2l-1.2,7.4h8.2
	c-3.2-0.7-3.9-4-2.3-10C277,261.8,279.3,258,282.7,257.2L282.7,257.2z M264.7,279.2c-1,0-1.7,0.9-1.9,2.6v6.5
	c-0.1,1.3,0.9,2,2.9,2.3l3.3-11.4L264.7,279.2L264.7,279.2z"
          />
          <path
            fill="none"
            d="M273.8,286c0.7-4,2.2-6.6,4.2-7.8h-8.2c-0.6,4.2-1.2,8.7-1.4,13.4h8.5c-2.5-0.3-3.5-1.7-3.2-4.6L273.8,286z"
          />
          <path
            fill="none"
            d="M278.1,278.2c-2,1-3.5,3.6-4.2,7.8l-0.1,1c-0.3,2.9,0.9,4.3,3.2,4.6h15.9c0.1-4.6,0.4-9.1,0.7-13.4H278.1z
	 M279.9,287.8c-0.6-0.6-0.9-1.2-0.9-1.9c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9S280.4,288.3,279.9,287.8L279.9,287.8z"
          />
          <path
            fill="none"
            d="M279.1,285.9c0,0.7,0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9c0.7,0,1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9
	c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9c-0.7,0-1.4,0.3-1.9,0.9C279.4,284.4,279.1,285.2,279.1,285.9z M271.1,270.9
	l-1.9,8.4l-3.3,11.4c-1.4,4.5-3,8.7-4.8,12.4l0.7,22.8h5.9c-0.1-4.6-0.1-9.1-0.1-13.3c0-7.4,0.3-14.4,0.9-20.9
	c0.3-4.8,0.9-9.2,1.4-13.4L271.1,270.9z M275.7,268.3c-1.6,5.9-0.9,9.2,2.3,10h15.4c0.4-7.9,1.2-15.1,2-21.8l-12.7,0.6
	C279.3,258,277,261.8,275.7,268.3L275.7,268.3z M282.9,270.3c-0.7,0-1.4-0.3-1.9-0.9c-0.4-0.6-0.9-1.2-0.9-1.9
	c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9c0.7,0,1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9c0,0.7-0.3,1.4-0.9,1.9
	C284.2,270,283.6,270.3,282.9,270.3z"
          />
          <path
            fill="none"
            d="M280.9,269.6c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9s-0.3-1.4-0.9-1.9
	c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9c-0.6,0.6-0.9,1.2-0.9,1.9C280.1,268.3,280.4,269,280.9,269.6z M313,181.6
	c-7.6,20.2-13.3,44.1-17.2,71.8H546c-3.8-27.6-9.5-51.6-17.2-71.8l-68.8,0.1l-4,3h-70l-4-3L313,181.6L313,181.6z"
          />
          <path
            fill="none"
            d="M458,178.4c1.3-1.6,1.4-2.3,0.6-2.6c-10.8-0.1-21.5,0-32,0h-11.1c-10.7-0.1-21.4-0.1-32.2,0
	c-1,0.3-0.9,1,0.4,2.6l2.6,2.9c0.6,0.6,1.9,0.9,3.8,0.7h61.6c1.9,0.1,3.2-0.1,3.8-0.7L458,178.4L458,178.4z M445.3,167.4v-6.2
	l-7.9-0.1v5.8v-5.8h-33v5.8v-5.8l-7.8,0.1v6.2 M445.3,161.2v6.2c9.4,0,18.9,0,28.6,0.1v-5.5C464.7,161.7,455.1,161.4,445.3,161.2
	L445.3,161.2z"
          />
          <path
            fill="none"
            d="M473.9,167.6l8.5,0.1v-5.3l-8.5-0.4 M482.4,162.4v5.2l22.9,0.4c6.2,0.1,11.3,0.4,15.3,1v-3.6l-9.7-1v2.6
	l-6.3-0.6V164l-1.2-0.1l-11.7-0.9h-0.1L482.4,162.4L482.4,162.4z"
          />
          <path
            fill="none"
            d="M505.3,159.2l-1.6,2.6l-0.3,0.4l1.2,1.6v2.5l6.3,0.6V163l-1.4-4c-0.3-0.6-0.6-1-1-1h-1.3c-0.3,0-0.7,0-1,0.3
	C505.9,158.5,505.6,158.8,505.3,159.2L505.3,159.2z"
          />
          <path
            fill="none"
            d="M503.5,162.4v1.4l1.2,0.1 M505.3,159.2c-0.9-0.9-2.7-1.3-5.5-1.4c-21.4-1.6-42.4-2.6-63.2-2.9h-4.2
	c22.1,0.3,44.6,1.2,67.5,2.9C502.6,157.9,504.5,158.4,505.3,159.2c0.3-0.3,0.6-0.7,0.7-1C505.9,158.5,505.6,158.8,505.3,159.2
	L505.3,159.2z M523.8,166.1c-1-0.3-1.9-0.4-2.9-0.6h-0.3v3.6l3.5,0.6v-3.5L523.8,166.1L523.8,166.1z"
          />
          <path
            fill="none"
            d="M524.1,166.3v3.5c-0.1,1.7-0.6,2.9-1.2,3.8c2.2,0.7,4.2,3.5,6.3,8.2h-0.4c7.6,20.2,13.3,44.1,17.2,71.8h20.6
	l-1.6-6.5c-5.3-16.7-10.8-31.4-16.2-44c-5.9-14.1-13.3-25.8-21.8-35.2C526.3,167.2,525.2,166.6,524.1,166.3L524.1,166.3z M368,162
	v5.6c9.7-0.1,19.2-0.1,28.6-0.1v-6.2C386.6,161.4,377.1,161.7,368,162L368,162z M433.1,154.9l-12.7-0.1h-1.7l13.7,0.1 M363.5,278.5
	l0.3,7.2h7.8c-0.6-6.5,1.3-10,5.9-10.4c12.7-1.2,26.5-1.7,41.8-1.7h3.5c15.1,0,29.1,0.6,41.8,1.7c4.6,0.3,6.5,3.8,5.9,10.4h7.8
	l0.3-7.2c0-4.6-2.2-7.2-6.2-7.8c-14.4-1.6-30.9-2.5-49.5-2.6h-3.5c-18.6,0-35.1,0.9-49.5,2.6C365.6,271.3,363.5,273.9,363.5,278.5
	L363.5,278.5z M371.5,285.7h-7.8c0.4,11.8,1.3,22.1,2.5,30.4h109.5c1.2-8.4,2-18.6,2.5-30.4L371.5,285.7L371.5,285.7z M441.9,289.3
	c1,0,1.6,0.6,1.6,1.6v19.3c0,1-0.6,1.6-1.6,1.6H400c-1,0-1.6-0.6-1.6-1.6v-19.3c0-1,0.6-1.6,1.6-1.6L441.9,289.3L441.9,289.3z"
          />
          <path
            fill="none"
            d="M443.3,290.9c0-1-0.6-1.6-1.6-1.6h-41.8c-1,0-1.6,0.6-1.6,1.6v19.3c0,1,0.6,1.6,1.6,1.6h41.8
	c1,0,1.6-0.6,1.6-1.6V290.9z M377.4,275.5c-4.6,0.3-6.5,3.8-5.9,10.4h99c0.6-6.5-1.3-10-5.9-10.4c-12.7-1.2-26.5-1.7-41.8-1.7h-3.5
	C403.9,273.6,390.1,274.3,377.4,275.5L377.4,275.5z M415.9,277.5c0.3,0,0.3-0.1,0.3-0.3v-0.9l0.1-0.3l0.1-0.1h8.4
	c0.1,0,0.3,0,0.4,0.1l0.1,0.4v1h7.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0,0.4l-0.3,0.4l-0.4,0.7l-1.7,2.7l-0.3,0.3h-4.8
	c-0.1,0-0.3,0.1-0.3,0.3v0.9l-0.1,0.3l-0.3,0.1l-0.4,0.1h-8.4c-0.1,0-0.3-0.1-0.3-0.1l-0.1-0.3v-1h-7.4l-0.4-0.1l-0.1-0.3l0.1-0.3
	l2.3-3.9c0.1-0.3,0.3-0.3,0.6-0.3L415.9,277.5L415.9,277.5z"
          />
          <path
            fill="none"
            d="M416.2,277.2c0,0.1-0.1,0.3-0.3,0.3h-4.8c-0.3,0-0.4,0.1-0.6,0.3l-2.3,3.9l-0.1,0.3l0.1,0.3l0.4,0.1h7.4v1
	l0.1,0.3c0,0.1,0.1,0.1,0.3,0.1h8.4l0.4-0.1l0.3-0.1l0.1-0.3v-0.9c0-0.1,0.1-0.3,0.3-0.3h4.8l0.3-0.3l1.7-2.7l0.4-0.7l0.3-0.4
	c0.1-0.1,0.1-0.3,0-0.4s-0.3-0.1-0.4-0.1h-7.5v-1l-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-8.4c-0.1,0-0.1,0-0.1,0.1l-0.1,0.3
	L416.2,277.2L416.2,277.2z M410.8,281h-0.1l-0.1-0.1v-0.1l1-1.7c0.1-0.1,0.3-0.3,0.6-0.3h5.6v-1.4h5.9l0.3,0.1v1.3l0.4,0.1h6.5v0.3
	l-1,1.7c-0.1,0.1-0.1,0.1-0.3,0.1h-5.2l-0.4,0.3v0.9l-0.1,0.3h-6.2v-1.3l-0.3-0.3L410.8,281L410.8,281z"
          />
          <path
            fill="none"
            d="M410.6,281h6.9l0.3,0.3v1.3h6.2l0.1-0.3v-0.9l0.1-0.3h5.3l0.4-0.1l1-1.7v-0.1l-0.4-0.3h-6.4l-0.1-0.1v-1.3
	l-0.3-0.1h-5.9v1.4h-5.6c-0.3,0-0.4,0-0.6,0.3l-1,1.7v0.1L410.6,281L410.6,281z M411,281h-0.4h0.3"
          />
          <path
            fill="none"
            d="M424.1,278.7h0.1l0.1,0.1h0.1 M424.3,281.1l-0.1,0.1l0.4-0.3h-0.1 M430.8,278.8l0.4,0.1v-0.1 M429.6,281
	c0.3,0,0.4,0,0.4-0.1L429.6,281L429.6,281z M528.6,181l0.3,0.6h0.4 M558.9,256.9l0.3,0.3l8.5,0.3l-1-4H546l0.4,3.2l12.7,0.6
	 M573,247.7l-4-0.3l-1.2,1.6l2.7,6.2l6.9,0.3C575.6,252.7,574.1,250.1,573,247.7L573,247.7z"
          />
          <path
            fill="none"
            d="M573,247.5c1,2.6,2.6,5.2,4.5,7.8c1.2,1.6,2.6,3.2,4,4.8l1.2,1.2l2.2-0.1c9.7-0.4,17.3-1.4,22.8-2.7
	c4.2-1.6,5.9-4.8,5.2-9.4v-0.3c-0.6-6.2-4.6-10.1-11.7-11.8c-5.8-1.7-11.4-2.3-17.2-1.9C576.7,235.8,573.1,240,573,247.5L573,247.5z
	 M610.9,248.7v0.3c0.7,4.2-0.9,6.9-4.6,8.4c-4.2,1-9.8,1.9-16.9,2.3c-3.8,0.1-6.5-0.7-8.1-2.5h-0.1c-1.7-2-3.3-4.2-4.5-6.2
	c-1-1.9-1.6-3.9-1.4-5.8c0.7-5.3,4-8.2,9.8-8.9c5.2-0.3,10.2,0.3,15.4,1.7C606.9,239.6,610.4,243.2,610.9,248.7L610.9,248.7z"
          />
          <path
            fill="none"
            d="M610.9,249v-0.3c-0.6-5.6-4-9.1-10.5-10.7c-5.2-1.6-10.2-2.2-15.4-1.7c-5.8,0.7-9.1,3.6-9.8,8.9
	c-0.1,1.9,0.4,3.9,1.4,5.8c1.2,2,2.7,4.2,4.5,6.2h0.1c1.6,1.7,4.3,2.6,8.1,2.5c7.1-0.4,12.7-1.3,16.9-2.3
	C610.1,255.9,611.7,253.1,610.9,249L610.9,249z M566.6,253.4l1,4l1,4.2l14.3-0.3l-1.2-1.2c-1.6-1.6-2.9-3.2-4-4.8l-6.9-0.3l-2.7-6.2
	l1.2-1.6l-4-0.3L566.6,253.4L566.6,253.4z"
          />
          <path
            fill="none"
            d="M567.7,257.5l-8.5-0.4c3.5,0.9,5.8,4.6,6.9,11.1c1.6,5.9,0.9,9.2-2.3,10h8.2l-1.2-7.4l-2.2-9.2L567.7,257.5
	L567.7,257.5z M572,278.2h-8.2c2,1,3.5,3.6,4.2,7.8l0.1,1c0.3,2.9-0.9,4.3-3.2,4.6h8.5C573.1,286.9,572.6,282.4,572,278.2L572,278.2
	z"
          />
          <path
            fill="none"
            d="M567.9,286c-0.7-4-2.2-6.6-4.2-7.8h-15.4l0.7,13.4h15.9c2.5-0.3,3.5-1.7,3.2-4.6L567.9,286L567.9,286z
	 M558.1,287.8c-0.6-0.6-0.7-1.2-0.7-1.9c0-0.7,0.3-1.4,0.7-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9S558.6,288.3,558.1,287.8L558.1,287.8z"
          />
          <path
            fill="none"
            d="M557.3,285.9c0,0.7,0.3,1.4,0.7,1.9c0.6,0.6,1.2,0.9,1.9,0.9c0.7,0,1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9
	c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9c-0.7,0-1.4,0.3-1.9,0.9C557.6,284.4,557.3,285.2,557.3,285.9z M572.7,279.2
	l-1.9-8.4l1.2,7.4c0.6,4.2,1.2,8.7,1.4,13.4c0.4,6.5,0.7,13.4,0.9,20.9c0,4.3,0,8.8-0.1,13.3h5.9l0.7-22.8
	c-1.7-3.9-3.3-7.9-4.8-12.4L572.7,279.2L572.7,279.2z M563.8,278.2c3.2-0.7,3.9-4,2.3-10c-1.3-6.5-3.6-10.2-6.9-11.1l-12.7-0.6
	c0.9,6.5,1.4,13.9,2,21.8h15.3L563.8,278.2L563.8,278.2z M556.3,267.6c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9
	s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9s-1.4-0.3-1.9-0.9
	C556.5,269,556.3,268.3,556.3,267.6L556.3,267.6z"
          />
          <path
            fill="none"
            d="M557.1,265.7c-0.6,0.6-0.9,1.2-0.9,1.9c0,0.7,0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9
	c0.6-0.6,0.9-1.2,0.9-1.9c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9C558.3,265,557.6,265.3,557.1,265.7L557.1,265.7z
	 M579.1,281.8c-0.3-1.7-0.9-2.6-1.9-2.6h-4.3l3.3,11.4c2.2-0.3,3.2-1,2.9-2.3L579.1,281.8L579.1,281.8z M580.1,326v-0.1h-30.6v9.1
	l29.4-0.3"
          />
          <path
            fill="none"
            d="M580.1,326l-1.2,8.8c-2,14-4.3,25.1-6.8,33.2c-1.2,3.6-2.3,6.8-3.6,9.2c-4.5,8.1-9.1,14.7-13.8,19.5l2.6,0.4
	h0.1c0.6,0.1,0.7,0.4,0.4,1.2c-0.3,0.9-1,1.7-2.2,2.9c-1.2,1.2-2.3,2-3.6,2.9s-2.5,1.2-3.2,1.2l-0.6-0.1l-4.2-0.4
	c-1.3,0.6-2.6,1.2-3.9,1.6c-0.7,0.4-1.6,0.9-2.3,0.9v40.8c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.7-3,8.8-8.7L580.1,326L580.1,326z
	 M574.1,325.8c0.1-4.6,0.1-9.1,0.1-13.3h-24.7v13.3H574.1L574.1,325.8z M573.4,291.7h-8.5c2.2,0.7,3.5,4.3,3.5,10.8
	c0.4,6.5-1.3,9.8-5.2,10.1h11C574.3,305.1,574,298.1,573.4,291.7L573.4,291.7z"
          />
          <path
            fill="none"
            d="M563.3,312.6c3.9-0.3,5.5-3.6,5.2-10.1c-0.1-6.5-1.3-10.1-3.5-10.8h-15.9c0.3,6.5,0.4,13.6,0.4,20.9H563.3z
	 M563,299.7c0.6,0.6,0.7,1.2,0.7,1.9s-0.3,1.4-0.7,1.9c-0.6,0.6-1.2,0.9-1.9,0.9s-1.4-0.3-1.9-0.9c-0.6-0.6-0.9-1.2-0.9-1.9
	s0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9C561.7,298.9,562.5,299.2,563,299.7L563,299.7z"
          />
          <path
            fill="none"
            d="M563.8,301.6c0-0.7-0.3-1.4-0.7-1.9c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9c-0.6,0.6-0.9,1.2-0.9,1.9
	s0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9C563.5,302.9,563.8,302.3,563.8,301.6L563.8,301.6z M549.5,334.9v-9.1
	H292.3l0.1,9.1l0.9,13.9h255.3"
          />
          <path
            fill="none"
            d="M548.6,348.8l-0.3,4l-1.2,14.9H572c2.6-8.1,4.8-19.2,6.8-33.2l-29.4,0.3L548.6,348.8L548.6,348.8z"
          />
          <path
            fill="none"
            d="M548.3,353l0.3-4.2H293.3l0.1,4.2 M547.2,367.8l1.2-14.9H293.4l1.2,14.9h92.6H269.8c1.2,3.6,2.3,6.8,3.6,9.2
	c4.2,7.5,8.4,13.6,12.7,18.3l-2.2-4.8c-0.9-2.6-0.1-4,2.2-4.2H367l2.6,3.9H472l2.6-3.9h77.3c2.7,0.1,3.6,1.7,2.6,5.2
	c-0.7,1.7-1.6,3.3-2.6,4.6l2.5,0.4c4.8-4.9,9.4-11.4,13.8-19.5c1.3-2.5,2.5-5.6,3.6-9.2L547.2,367.8l-92.6,0L547.2,367.8
	L547.2,367.8z M448.2,358.3c3.6,0.3,5.6,2.2,5.9,5.6v14.9h-66.5v-14.9c0.3-3.5,2.3-5.3,5.9-5.6H448.2z"
          />
          <path
            fill="none"
            d="M454.1,363.9c-0.3-3.5-2.3-5.3-5.9-5.6h-54.7c-3.6,0.3-5.6,2.2-5.9,5.6v14.9h66.5L454.1,363.9L454.1,363.9z
	 M542,392.1c0.7,0.9,1.2,1.9,1.6,2.9l8.7,1.3c1-1.4,1.9-2.9,2.6-4.6c1-3.3,0.1-5-2.6-5.2h-24.1l-0.1,2.7l8.4,0.4h0.6
	C538.9,389.5,540.5,390.3,542,392.1z"
          />
          <path
            fill="none"
            d="M543.4,394.9c-0.3-1-0.9-2-1.6-2.9c-1.4-1.7-3-2.6-5-2.6h-0.6c-1.7,0.1-3.2,1-4.3,2.6c-1.4,1.7-2,3.9-2,6.3
	c0,2.5,0.7,4.5,2,6.2c1.3,1.6,2.7,2.5,4.5,2.6h0.1l1.4-0.1c0.9-0.1,1.6-0.4,2.3-0.9c0.6-0.4,1.2-1,1.6-1.6l0.1-0.3l-3.8-0.3h-0.4
	c-1.3,0-2.3-0.4-3.2-1.4s-1.3-2.2-1.3-3.5c0-1.4,0.4-2.6,1.3-3.5c0.9-1,1.9-1.4,3.2-1.4L543.4,394.9L543.4,394.9z"
          />
          <path
            fill="none"
            d="M537.9,394.1c-1.3,0-2.3,0.4-3.2,1.4c-0.9,1-1.3,2.2-1.3,3.5c0,1.3,0.4,2.5,1.3,3.5c0.9,1,1.9,1.4,3.2,1.4h0.4
	l3.8,0.3l2,0.3l4.2,0.4c-0.3-0.1-0.3-0.6-0.1-1c0.3-0.9,1-1.7,2.2-2.9c1.2-1.2,2.5-2,3.8-2.9c1.3-0.9,2.5-1.2,3.2-1.2l-2.6-0.4
	l-2.5-0.4l-8.7-1.3L537.9,394.1L537.9,394.1z"
          />
          <path
            fill="none"
            d="M531.9,392.1c1.3-1.6,2.7-2.3,4.3-2.6l-8.4-0.4l-0.1,2l-0.1,0.3l-0.3,0.1c-0.1,0-0.3-0.1-0.3-0.1
	c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-2l-11.3-0.6l-0.1,1.7l-0.1,0.3l-0.4,0.1c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-1.7
	l-1.6-0.1l-14.1-0.7h-0.4c-2.2,0-3.9,0.9-5.5,2.6c-1.6,1.7-2.3,3.9-2.3,6.3c0,1.2,0.1,2.2,0.4,3.2v0.1c0.3,1,0.9,2,1.7,3
	c1.4,1.7,3.3,2.6,5.5,2.6l13.7,0.6l15.7,0.7l3,0.1l5.3,0.3c-1.7-0.1-3.2-1-4.5-2.6c-1.4-1.7-2-3.8-2-6.2
	C529.9,395.8,530.6,393.8,531.9,392.1L531.9,392.1z M557.4,397h-0.1c-0.7,0-1.7,0.4-3.2,1.2c-1.3,0.9-2.6,1.7-3.8,2.9
	c-1.2,1.2-1.9,2-2.2,2.9c-0.1,0.6-0.1,0.9,0.1,1l0.6,0.1c0.7,0,1.7-0.4,3.2-1.2c1.3-0.9,2.6-1.7,3.6-2.9c1.2-1.2,1.9-2,2.2-2.9
	C558.1,397.5,558,397.1,557.4,397L557.4,397z M544.1,404.6l-2-0.3l-0.1,0.3c-0.6,0.6-1,1.2-1.6,1.6
	C541.5,405.8,542.8,405.2,544.1,404.6z M531.2,408.2v-1.2l-0.1,1.2 M531.2,407.1v1.2c2.5,0.1,4.8,0,6.8-0.4v-0.7l-1.4,0.1h-0.1
	L531.2,407.1L531.2,407.1z"
          />
          <path
            fill="none"
            d="M531.2,407.1l-3-0.3v1.9l-10-0.7c-3.6-0.3-5.5-1-5.8-1.9l-13.7-0.6c-2.2,0-3.9-0.9-5.5-2.6
	c-0.9-0.9-1.3-1.9-1.7-3c-1.7-0.6-2.7-1.6-3.3-2.9c-0.3-1.6,0.1-2.3,1.3-2.3l-2-5.2h-4.6V400h4.3v8.1c1.9,0.9,3.9,1.6,6.2,2.3
	c8.8,2.6,21.5,3.5,37.8,2.6v-4.6H531L531.2,407.1L531.2,407.1z"
          />
          <path
            fill="none"
            d="M537.9,407.8c-2.2,0.4-4.5,0.6-6.8,0.4v4.6c-16.3,0.9-29,0-37.8-2.6l-57.3,1.2c-2.9,1-7.5,1.6-14.1,1.6h-2
	c-6.6,0-11.3-0.6-14.1-1.6l-57.3-1.2c-4.2,1.2-9.1,2-14.9,2.5c-5.8,0.4-12.4,0.6-19.9,0.3l-3-0.1v-3.9l-6.8-0.6v5l10,0.6
	c28,1.6,61.9,2.3,101.7,2.3H429c43.6,0,79.9-1,109.1-2.9v-5.6L537.9,407.8L537.9,407.8z"
          />
          <path
            fill="none"
            d="M537.9,423.8v-10.4c-29.1,1.9-65.5,2.9-109.1,2.9h-13.4c-39.8,0-73.7-0.7-101.7-2.3l-10-0.6v10.4H537.9z
	 M475.2,319.2l0.4-3H366.1l0.4,3c0.9,4.3,2.7,6.6,5.9,6.6h24.4l5.2-3.3h37.7l5.2,3.3h24.4C472.4,325.8,474.5,323.5,475.2,319.2z
	 M474.8,386.4l-2.6,3.9H369.7l-2.6-3.9h-7.9v14.4c-0.3,3.5,0.9,5.9,3.6,7.2c1.7,0.9,4,1.2,6.9,1h102.4c2.9,0.1,5.2-0.3,6.9-1
	c2.7-1.3,3.9-3.6,3.6-7.2v-14.4H474.8L474.8,386.4z"
          />
          <path fill="none" d="M482.7,386.4v2.9h4.3v-0.9l-0.7-2" />
          <path
            fill="none"
            d="M490.8,396.5c0-2.5,0.7-4.6,2.3-6.4c1.4-1.7,3.3-2.6,5.5-2.6h0.4l-0.4-1.3h-12.3l0.7,2.2l0.3,0.9l2,5.2l1.9,5
	C490.9,398.7,490.8,397.7,490.8,396.5L490.8,396.5z"
          />
          <path
            fill="none"
            d="M489.3,394.5c-1,0-1.4,0.7-1.3,2.3c0.4,1.4,1.6,2.3,3.3,2.9v-0.1L489.3,394.5L489.3,394.5z M487,389.3h0.4
	l-0.4-0.9 M498.6,386.4l0.4,1.2l14.1,0.7v-1.9 M514.7,386.4h-1.6v1.9l1.4,0.1"
          />
          <path
            fill="none"
            d="M514.6,388.5l-0.1,1.7c0,0.1,0,0.3,0.1,0.4s0.3,0.1,0.3,0.1l0.4-0.1l0.1-0.3l0.1-1.7l0.1-2.2h-1L514.6,388.5
	L514.6,388.5z"
          />
          <path
            fill="none"
            d="M515.7,386.4l-0.1,2l11.3,0.6l0.1-2.6 M528.1,386.4h-1L527,389l-0.1,2c0,0.1,0,0.3,0.1,0.3
	c0.1,0.1,0.3,0.1,0.3,0.1l0.3-0.1l0.1-0.3l0.1-2L528.1,386.4L528.1,386.4z M402,322.5l-5,3.3h47.9l-5.2-3.3 M359.2,386.4H356
	l-1.3,1.6v1h4.5 M353.9,389l0.9-1.2v-1.6h-6.3l0.1,1l2.9,0.1c1.6,0.3,2.3,0.7,2.3,1.3L353.9,389L353.9,389z"
          />
          <path
            fill="none"
            d="M354.7,388l-0.9,1h0.9 M353.7,389.3l0.1-0.3v-0.1c0-0.6-0.9-1-2.3-1.3l-2.9-0.1l0.1,3.9c1.2-0.1,2.2-0.4,3-0.9
	c0.9-0.3,1.6-0.7,1.9-1L353.7,389.3L353.7,389.3z"
          />
          <path fill="none" d="M353.7,389v0.3l0.1-0.3" />
          <path
            fill="none"
            d="M353.7,389.3l-0.1,0.1c-0.3,0.3-1,0.7-1.9,1c-0.9,0.3-1.9,0.6-3,0.9l0.3,3.5l2.2-0.6c1.4-0.3,2.3,0.3,2.6,1.7
	c0.3,1.6-1.3,2.7-4.5,3.6l0.1,2.5h3l0.3-0.1l2.3,0.1v-13h-0.9L353.7,389.3L353.7,389.3z M354.7,386.4v1.6l1.3-1.6"
          />
          <path
            fill="none"
            d="M353.6,396.1c-0.3-1.6-1.2-2.2-2.6-1.7l-2.2,0.6l0.3,4.8C352.4,398.8,353.9,397.5,353.6,396.1L353.6,396.1z
	 M359.2,389h-4.3v18.8h7.9c-2.7-1.3-3.9-3.6-3.6-7.2L359.2,389L359.2,389z M422,413.1c6.6,0,11.3-0.6,14.1-1.6l2.7-2.5h-35.8
	l2.7,2.5c2.9,1,7.5,1.6,14.1,1.6L422,413.1L422,413.1z"
          />
          <path
            fill="none"
            d="M438.8,408.9l-2.7,2.5l57.3-1.2c-2.3-0.7-4.3-1.4-6.2-2.3h-7.9c-1.7,0.9-4,1.2-6.9,1L438.8,408.9L438.8,408.9z
	 M405.8,411.5l-2.7-2.5h-33.3c-2.9,0.1-5.2-0.3-6.9-1h-7.9c-1.9,0.9-3.9,1.6-6.2,2.3L405.8,411.5L405.8,411.5z M354.7,403.9v-1.7
	h-2.5l-3,1.4l0.1,1.7l2.9-1.4"
          />
          <path
            fill="none"
            d="M354.7,407.9v-4h-2.5l-2.9,1.4l-0.4,3.5 M352.4,402l-0.1,0.1h2.5 M528,408.8v-1.9l-15.7-0.7
	c0.3,1,2.2,1.6,5.8,1.9L528,408.8L528,408.8z M487,407.9v-8.1h-4.3v0.9c0.3,3.5-0.9,5.9-3.6,7.2H487z M292.4,312.6
	c0-7.4,0.1-14.3,0.4-20.9H277c-2.3,0.7-3.5,4.3-3.5,10.8c-0.4,6.5,1.3,9.8,5.2,10.1L292.4,312.6L292.4,312.6L292.4,312.6z
	 M278.9,299.7c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9s-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9
	s-1.3-0.3-1.9-0.9c-0.6-0.6-0.9-1.2-0.9-1.9C278.1,300.9,278.4,300.2,278.9,299.7L278.9,299.7z"
          />
          <path
            fill="none"
            d="M280.7,298.9c-0.7,0-1.3,0.3-1.9,0.9s-0.9,1.2-0.9,1.9s0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9
	s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9s-0.3-1.4-0.9-1.9C282.2,299.2,281.5,298.9,280.7,298.9L280.7,298.9z M278.6,312.6
	c-3.9-0.3-5.5-3.6-5.2-10.1c0.1-6.5,1.3-10.1,3.5-10.8h-8.5c-0.4,6.5-0.7,13.4-0.9,20.9H278.6L278.6,312.6z M278.6,312.6h-11
	c0,4.3,0,8.8,0.1,13.3h24.5v-13.3H278.6z M261.8,325.8v0.1l1.2,8.7l29.4,0.3l-0.1-9.1h-24.5"
          />
          <path
            fill="none"
            d="M263,334.6l-1.2-8.8l0.1,121.9c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.8-3,8.8-8.7v-48.5l-13.7-1h-2.7l-1.4-3
	c-4.3-4.8-8.7-10.8-12.7-18.3c-1.3-2.5-2.5-5.6-3.6-9.2C267.2,359.7,265,348.6,263,334.6L263,334.6z"
          />
          <path
            fill="none"
            d="M292.4,334.9l-29.4-0.3c2,14,4.3,25.1,6.8,33.2h24.8l-1.2-14.9l-0.3-4L292.4,334.9L292.4,334.9z M287.1,391.5
	c-1-3.3-0.1-5,2.7-5.2h-3.6c-2.3,0.3-3,1.6-2.2,4.2l2.2,4.8l1.4,3h2.7L287.1,391.5L287.1,391.5z"
          />
          <path
            fill="none"
            d="M289.7,386.4c-2.7,0.1-3.8,1.7-2.7,5.2l3.2,6.9l13.7,1l1.6,0.1l5.2,0.4l18.3,1.3v-15L289.7,386.4L289.7,386.4z
	 M329.1,386.4v2.7h9.1c-0.4-1.7,0.6-2.6,3-2.7H329.1L329.1,386.4z"
          />
          <path
            fill="none"
            d="M338.1,389h-9.1v12.3l-18.3-1.3v3.9l3-0.1l7.6-0.1V402h23.7l-0.1-1.6l-3.5,0.3c-1.7-0.4-2.3-1.2-1.6-2.5
	c0.9-1.3,2.5-2.2,4.9-2.5l-0.3-4.2l-3.2-0.3c-1.4-0.3-2.2-0.7-2.3-1.2L338.1,389L338.1,389z"
          />
          <path
            fill="none"
            d="M338.1,389.2l0.9,1c0-0.4,0.3-0.9,1-1.2c0.3-0.1,0.6-0.3,1-0.4c0.9-0.3,2-0.6,3.2-0.9l-0.1-1.4h-2.9
	C338.7,386.4,337.7,387.3,338.1,389.2L338.1,389.2z"
          />
          <path
            fill="none"
            d="M348.4,387.4l-0.1-1h-4.3l0.1,1.4l0.3,3.9l0.3,4.2l0.3,4.6l0.1,1.6l0.3,5.2v0.4c0.3,1.4,1.6,1.7,3.5,1l0.4-3.5
	l-0.1-1.7v-1.4l-0.1-2.5l-0.3-4.8l-0.3-3.5L348.4,387.4L348.4,387.4z"
          />
          <path
            fill="none"
            d="M344.2,387.9c-1.2,0.1-2.3,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1,0.4c-0.7,0.4-1,0.9-1,1.2c0,0.6,0.9,1,2.3,1.2
	l3.2,0.3L344.2,387.9z M349.2,402.2v1.4l3-1.4 M339.9,398.4c-0.6,1.3-0.1,2,1.6,2.5l3.5-0.3l-0.3-4.6
	C342.3,396.2,340.7,397.1,339.9,398.4L339.9,398.4z M349,408.8c-2,0.7-3.2,0.4-3.5-1v-0.4c-5-0.3-8.9-2-11.7-5.2v10.7
	c5.8-0.4,10.7-1.3,14.9-2.5c2.3-0.7,4.3-1.4,6.2-2.3L349,408.8L349,408.8z"
          />
          <path
            fill="none"
            d="M345.5,407.4l-0.3-5.2h-11.4C336.6,405.3,340.4,407.1,345.5,407.4L345.5,407.4z"
          />
          <path
            fill="none"
            d="M333.8,402.2h-3.9v4.9c-3,1.2-8.4,1.9-16,2v4c7.5,0.3,14.1,0.1,19.9-0.3V402.2L333.8,402.2z"
          />
          <path
            fill="none"
            d="M329.9,407.1v-4.9H326v4.2h-12.3v2.7C321.6,408.9,326.9,408.2,329.9,407.1z"
          />
          <path
            fill="none"
            d="M321.4,402.2v1.4l-7.6,0.3v2.3H326v-4 M313.8,406.2v-2.3h-3v8.9l3,0.1v-3.9"
          />
          <path
            fill="none"
            d="M310.7,409.1v-2.9H304v2.2 M310.7,406.2V400l-5.2-0.4v6.6 M304,406.2h1.6v-6.6l-1.6-0.1 M321.1,165.4v3.8
	l15.3-1l23.1-0.4v-5.3l-21.1,1.4l-1.2,0.1v2.5l-6.3,0.6v-2.6h-0.1"
          />
          <path
            fill="none"
            d="M337.3,164l1.2-0.1v-1.4 M359.5,167.7l8.5-0.1V162l-8.5,0.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M295.4,256.6l-12.7,0.6 M278.1,278.2h15.3 M282.7,257.2
	c-3.5,0.9-5.8,4.6-6.9,11.1c-1.6,5.9-0.9,9.2,2.3,10 M269.9,278.2h8.2 M279.1,285.9c0-0.7,0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9
	c0.7,0,1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9c0,0.7-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9c-0.7,0-1.4-0.3-1.9-0.9
	C279.4,287.3,279.1,286.6,279.1,285.9z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M278.1,278.2c-2,1-3.5,3.6-4.2,7.8l-0.1,1c-0.3,2.9,0.9,4.3,3.2,4.6
	 M280.9,269.6c-0.6-0.6-0.9-1.2-0.9-1.9s0.3-1.4,0.9-1.9c0.6-0.6,1.2-0.9,1.9-0.9s1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9
	s-0.3,1.4-0.9,1.9c-0.6,0.6-1.2,0.9-1.9,0.9C282,270.3,281.4,270,280.9,269.6z M282.9,256.9l-0.1,0.3 M282.7,257.2l-8.5,0.4
	 M559.1,257.2l-0.3-0.3 M567.7,257.5l-8.5-0.3 M556.3,267.6c0,0.7,0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9
	c0.6-0.6,0.9-1.2,0.9-1.9c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9C556.5,266.3,556.3,266.8,556.3,267.6
	L556.3,267.6z M558.1,287.8c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.9-1.2,0.9-1.9c0-0.7-0.3-1.4-0.9-1.9
	c-0.6-0.6-1.2-0.9-1.9-0.9s-1.4,0.3-1.9,0.9c-0.6,0.6-0.7,1.2-0.7,1.9C557.3,286.6,557.6,287.3,558.1,287.8L558.1,287.8z
	 M563.8,278.2h8.2 M564.9,291.7c2.5-0.3,3.5-1.7,3.2-4.6l-0.1-1c-0.7-4-2.2-6.6-4.2-7.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M563.8,278.2c3.2-0.7,3.9-4,2.3-10c-1.3-6.5-3.6-10.2-6.9-11.1
	 M548.3,278.2h15.4 M559.1,257.2l-12.7-0.6 M292.8,291.7h-16 M573.4,291.7h-8.5 M563,299.7c-0.6-0.6-1.2-0.9-1.9-0.9
	s-1.4,0.3-1.9,0.9c-0.6,0.6-0.9,1.2-0.9,1.9s0.3,1.4,0.9,1.9c0.6,0.6,1.2,0.9,1.9,0.9s1.4-0.3,1.9-0.9c0.6-0.6,0.7-1.2,0.7-1.9
	S563.5,300.2,563,299.7L563,299.7z M563.3,312.6c3.9-0.3,5.5-3.6,5.2-10.1c-0.1-6.5-1.3-10.1-3.5-10.8 M549.5,312.6h13.8
	 M564.9,291.7H549 M574.3,312.6h-11 M280.7,298.9c0.7,0,1.4,0.3,1.9,0.9c0.6,0.6,0.9,1.2,0.9,1.9s-0.3,1.4-0.9,1.9
	c-0.6,0.6-1.2,0.9-1.9,0.9s-1.3-0.3-1.9-0.9c-0.6-0.6-0.9-1.2-0.9-1.9s0.3-1.4,0.9-1.9C279.4,299.2,280,298.9,280.7,298.9
	L280.7,298.9z M278.6,312.6c-3.9-0.3-5.5-3.6-5.2-10.1c0.1-6.5,1.3-10.1,3.5-10.8 M276.8,291.7h-8.5 M278.6,312.6h-11 M292.4,312.6
	h-13.8 M258.9,261.4l14.3,0.3 M313.3,181c3.2-2.7,5.8-4.2,7.9-4.3l62-0.9 M319,173.4c-2.2,0.7-4.2,3.5-6.3,8.2 M313,181.6
	c-7.6,20.2-13.3,44.1-17.2,71.8 M275.2,253.4h20.6 M273.2,261.6l-2.2,9.2 M267.7,325.8c-0.1-4.6-0.1-9.1-0.1-13.3
	c0-7.4,0.3-14.4,0.9-20.9c0.3-4.8,0.9-9.1,1.4-13.4l1.2-7.4 M271.1,270.9l-1.9,8.4 M269.2,279.2h-4.3c-1,0-1.7,0.9-1.9,2.6v6.5
	c-0.1,1.3,0.9,2,2.9,2.3 M275.2,253.4l1.6-6.5 M272.8,247.2l-4,0.4 M276.8,246.9l-4,0.3 M273.2,261.6l1-4.2l1-4 M268.8,247.7
	c-1.2,2.6-2.6,5.1-4.6,7.6 M264.3,255.3l6.9-0.3l2.7-6.2l-1.2-1.6 M295.9,253.4l-0.4,3c-0.9,6.5-1.4,13.9-2,21.8
	c-0.3,4.2-0.4,8.7-0.7,13.4c-0.3,6.5-0.4,13.6-0.4,20.9v13.3 M312.5,181.6l0.9-0.6 M313.3,181l-0.3,0.6 M312.5,181.6h0.6
	 M264.3,255.3c-1.2,1.6-2.6,3.2-4,4.8l-1.2,1.2 M252.5,259.6c-7.1-0.4-12.7-1.3-16.9-2.3c-3.8-1.4-5.3-4.2-4.6-8.4v-0.3
	c0.6-5.6,4-9.1,10.5-10.7c5.1-1.6,10.2-2.2,15.4-1.7c5.8,0.7,9.1,3.6,9.8,8.9c0.1,1.9-0.4,3.9-1.4,5.8c-1.2,2-2.7,4.2-4.5,6.2h-0.1
	C258.9,259,256.2,259.8,252.5,259.6L252.5,259.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M268.8,247.7c0-7.6-3.8-11.8-11.1-12.6c-5.8-0.4-11.4,0.3-17.2,1.9
	c-7.2,1.7-11.1,5.6-11.7,11.8v0.3c-0.7,4.6,1,7.6,5.2,9.4c5.5,1.4,13.1,2.3,22.8,2.7l2.2,0.1 M321.1,169.2l15.3-1l23.1-0.4
	 M338.1,161.8c0-0.4,1.3-0.7,3.9-0.9c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9c2.6,0.1,3.9,0.4,3.9,0.9
	 M418.6,154.8l-8.4,0.1c-22.4,0.1-45.2,1.2-68.4,2.9c-2.7,0.1-4.6,0.6-5.5,1.4 M330.9,164.4v2.6l6.3-0.6V164 M335.7,158.2l0.9,1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M420.4,154.8l-10,0.1c-22.4,0.1-45.2,1.2-68.4,2.9
	c-2.7,0.1-4.6,0.6-5.5,1.4l-0.9-1 M338.4,163.8v-1.4 M336.6,159.2l1.6,2.6 M338.1,161.8l0.3,0.6 M338.4,162.4l-1.2,1.6 M337.3,164
	l1.2-0.1 M330.9,164.4h-0.1l-9.7,1 M335.7,158.2c-0.3-0.3-0.7-0.4-1-0.3h-1.3c-0.4,0.1-0.7,0.4-1,1l-1.4,4v1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M342,158.8c23.1-1.7,45.9-2.7,68.4-2.9h22.6c21.9,0.3,44.1,1.2,66.8,2.9
	c0.9,0,1.6,0.1,1.9,0.4c0.3,0.6-0.4,0.7-1.9,0.4c-22.6-1.7-44.9-2.7-66.8-2.9h-22.6c-22.4,0.1-45.2,1.2-68.4,2.9
	c-1.4,0.1-2,0-1.9-0.4C340.5,159.1,341,158.9,342,158.8L342,158.8z M317.7,166.3c-1.2,0.4-2.2,0.9-3,1.6
	c-8.5,9.2-15.7,21.1-21.8,35.2c-5.3,12.6-10.8,27.1-16.2,44 M319,173.4c-0.6-0.7-1-2-1.2-3.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M522.9,173.4c-0.6,0.6-1.2,1-2,0.9c-17.5-0.4-34.6-0.9-51.5-1
	c-14.4-0.1-28.9-0.1-43,0h-11c-14.3-0.1-28.6-0.1-43.1,0c-16.9,0.1-34,0.4-51.5,1c-0.9,0-1.4-0.3-2-0.9 M321.1,169.2l-3.5,0.4
	 M317.7,169.6v-3.3 M321.1,165.4v3.8 M317.7,166.3l0.3-0.1l0.1-0.1c0.9-0.3,1.7-0.4,2.7-0.6h0.3 M383.3,175.8c-1,0.3-0.9,1,0.4,2.6
	l2.6,2.9c0.6,0.6,1.9,0.9,3.8,0.7h61.6c1.9,0.1,3.2-0.1,3.8-0.7l2.6-2.9c1.3-1.6,1.4-2.3,0.6-2.6 M445.3,161.2v6.2 M473.9,167.6
	l8.5,0.1 M482.4,167.7v-5.3 M473.9,167.6V162 M482.4,162.4l-8.5-0.4 M445.3,167.4c9.4,0,18.9,0,28.6,0.1 M473.9,162
	c-9.2-0.4-18.8-0.7-28.6-0.9 M505.3,159.2l-1.6,2.6 M503.8,161.8l-0.3,0.6 M503.5,162.4v1.4 M504.6,164l-1.2-1.6 M503.5,163.8
	l1.2,0.1 M506.1,158.2c-0.3,0.1-0.6,0.6-0.7,1c-0.9-0.9-2.7-1.3-5.5-1.4c-21.4-1.6-42.4-2.6-63.2-2.9h-3.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M432.3,154.9c22.1,0.3,44.6,1.2,67.5,2.9c2.7,0.1,4.6,0.6,5.5,1.4
	 M505.3,159.2c0.3-0.4,0.6-0.7,0.7-1 M503.5,163.8l-11.7-0.9l-9.4-0.6 M511,164.4v2.6l-6.3-0.6V164 M511,164.4v-1.3l-1.4-4
	c-0.3-0.6-0.6-1-1-1h-1.3c-0.3,0-0.7,0-1,0.3 M520.6,165.4l-9.5-1H511 M524.1,166.3l-0.3-0.1l-0.1-0.1c-0.9-0.3-1.7-0.4-2.7-0.6
	h-0.3 M524.1,169.6v-3.3 M565.1,246.9c-5.3-16.7-10.8-31.4-16.2-44c-5.9-14.1-13.3-25.8-21.8-35.2c-0.9-0.7-1.9-1.2-3-1.6
	 M520.6,169.2v-3.8 M524.1,169.6c-0.1,1.7-0.6,2.9-1.2,3.8 M520.6,169.2l3.5,0.4 M482.4,167.7l22.9,0.4c6.2,0.1,11.3,0.4,15.3,1
	 M458.6,175.8l62,0.9c2.2,0.1,4.9,1.6,7.9,4.3 M359.5,167.7l8.5-0.1 M359.5,162.4v5.3 M368,162l-8.5,0.4 M368,167.6V162
	 M404.4,161.1v5.8 M396.6,167.4v-6.2 M404.4,161.1l-7.8,0.1 M437.4,166.9v-5.8 M433.1,154.9l-12.7-0.1 M418.6,154.8l13.7,0.1
	 M420.4,154.8h-1.7 M433.1,154.9h-0.7 M437.4,161.1h-33 M458.6,175.8c-10.8-0.1-21.5,0-32,0h-11.1c-10.7-0.1-21.4-0.1-32.2,0
	 M368,167.6c9.7-0.1,19.2-0.1,28.6-0.1 M396.6,161.2c-10,0.1-19.5,0.4-28.6,0.9 M528.8,181.6l-68.8,0.1l-4,3h-70.1l-4-3l-68.8-0.1
	 M478.1,285.7l0.3-7.2c0-4.6-2.2-7.2-6.2-7.8c-14.4-1.6-30.9-2.5-49.5-2.6h-3.5c-18.6,0-35.1,0.9-49.5,2.6c-4,0.6-6.2,3-6.2,7.8
	l0.3,7.2 M371.5,285.7h-7.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M470.4,285.7c0.6-6.5-1.3-10-5.9-10.4c-12.7-1.2-26.5-1.7-41.8-1.7h-3.5
	c-15.1,0-29.1,0.6-41.8,1.7c-4.6,0.3-6.5,3.8-5.9,10.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M415.9,277.5h-4.8c-0.3,0-0.4,0.1-0.6,0.3l-2.3,3.9l-0.1,0.3l0.1,0.3
	l0.4,0.1h7.4v1l0.1,0.3c0,0.1,0.1,0.1,0.3,0.1h8.4l0.4-0.1l0.3-0.1l0.1-0.3v-0.9c0-0.1,0.1-0.3,0.3-0.3h4.8l0.3-0.3l1.7-2.7l0.4-0.7
	l0.3-0.4c0.1-0.1,0.1-0.3,0-0.4s-0.3-0.1-0.4-0.1h-7.5v-1l-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1h-8.4c-0.1,0-0.1,0-0.1,0.1l-0.1,0.3
	v0.9C416.3,277.4,416.2,277.5,415.9,277.5L415.9,277.5z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M410.8,281h6.8l0.3,0.3v1.3h6.2l0.1-0.3v-0.9l0.1-0.3h5.5
	c0.1,0,0.3,0,0.3-0.1l1-1.7V279h-6.8l-0.3-0.3v-1.3l-0.3-0.1h-5.9v1.4h-5.6c-0.3,0-0.4,0-0.6,0.3l-1,1.7v0.1L410.8,281L410.8,281z
	 M528.6,181l0.3,0.6 M478.1,285.7h-7.6 M437.4,161.1l7.9,0.1 M371.5,285.7h99 M396.6,167.4h48.8 M573,247.7l-4-0.4 M582.8,261.4
	l2.2-0.1c9.7-0.4,17.3-1.4,22.8-2.7c4.2-1.6,5.9-4.8,5.2-9.4v-0.3c-0.6-6.2-4.6-10.1-11.7-11.8c-5.8-1.7-11.4-2.3-17.2-1.9
	c-7.4,0.9-11.1,5.1-11.1,12.6 M566.6,253.4l1,4l1,4.2 M577.6,255.3l-7.1-0.3l-2.6-6.2l1-1.6 M566.6,253.4l-1.6-6.5 M569,247.2
	l-3.9-0.3 M546,253.4l0.4,3c0.9,6.5,1.4,13.9,2,21.8l0.7,13.4c0.3,6.5,0.4,13.6,0.4,20.9v13.3 M570.8,270.9l-2.2-9.2 M572.7,279.2
	l-1.9-8.4 M570.8,270.9l1.2,7.4c0.6,4.2,1.2,8.7,1.4,13.4c0.4,6.5,0.7,13.4,0.9,20.9c0,4.3,0,8.8-0.1,13.3 M546,253.4h20.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M610.9,248.7c-0.6-5.6-4-9.1-10.5-10.7c-5.2-1.6-10.2-2.2-15.4-1.7
	c-5.8,0.7-9.1,3.6-9.8,8.9c-0.1,1.9,0.4,3.9,1.4,5.8c1.2,2,2.7,4.2,4.5,6.2h0.1c1.6,1.7,4.3,2.6,8.1,2.5c7.1-0.4,12.7-1.3,16.9-2.3
	c3.8-1.4,5.3-4.2,4.6-8.4L610.9,248.7L610.9,248.7z M582.8,261.4l-1.2-1.2c-1.6-1.6-2.9-3.2-4-4.8 M576,290.6c2.2-0.3,3.2-1,2.9-2.3
	v-6.5c-0.3-1.7-0.9-2.6-1.9-2.6h-4.3 M573,247.7c1.2,2.6,2.6,5.1,4.6,7.6 M568.7,261.6l14.1-0.3 M528.8,181.6
	c7.6,20.2,13.3,44.1,17.2,71.8 M529.3,181.6h-0.4 M528.6,181l0.7,0.6 M522.9,173.4c2.2,0.7,4.2,3.5,6.3,8.2 M338.4,163.8l21.1-1.4
	 M295.9,253.4H546 M580.1,325.8l0.7-22.8c-1.7-3.9-3.3-7.9-4.8-12.4 M580.1,326v-0.1 M578.9,334.6l1.2-8.7 M580.1,325.8h-5.9
	 M549.5,334.9v-9.1 M548.3,353l0.3-4.2 M547.2,367.8l1.2-14.9 M543.4,394.9c-0.3-1-0.9-2-1.6-2.9c-1.4-1.7-3-2.6-5-2.6h-0.6
	 M543.4,394.9l-5.6-0.9c-1.3,0-2.3,0.4-3.2,1.4s-1.3,2.2-1.3,3.5c0,1.3,0.4,2.5,1.3,3.5s1.9,1.4,3.2,1.4h0.4l3.8,0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M536.3,389.5c-1.7,0.1-3.2,1-4.3,2.6c-1.4,1.7-2,3.9-2,6.3
	c0,2.5,0.7,4.5,2,6.2c1.3,1.6,2.7,2.5,4.5,2.6 M554.7,396.5c4.8-4.9,9.4-11.4,13.8-19.5c1.3-2.5,2.5-5.6,3.6-9.2 M557.1,397
	l-2.5-0.4 M548.3,404.9l0.6,0.1c0.7,0,1.7-0.4,3.2-1.2c1.3-0.9,2.6-1.7,3.6-2.9c1.2-1.2,1.9-2,2.2-2.9c0.3-0.7,0.1-1-0.4-1.2h-0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M528.1,386.4h24.1c2.7,0.1,3.6,1.7,2.6,5.2c-0.7,1.7-1.6,3.3-2.6,4.6
	 M554.7,396.5l-2.5-0.3 M572.1,367.8h-25 M552.2,396.2l-8.8-1.3 M572.1,367.8c2.6-8.1,4.8-19.2,6.8-33.2 M549.5,325.8h24.7
	 M578.9,334.6l-29.4,0.3 M548.6,348.8l0.9-13.9 M557.1,397c-0.7,0-1.7,0.4-3.2,1.2c-1.3,0.9-2.6,1.7-3.8,2.9s-1.9,2-2.2,2.9
	c-0.1,0.6-0.1,0.9,0.1,1 M542.1,404.3l2,0.3 M544.1,404.6l4.2,0.3 M542.1,404.3l-0.1,0.3c-0.6,0.6-1,1.2-1.6,1.6 M531.2,407.1v1.2
	 M531.2,406.9v0.1 M531.2,407.1l-0.1,1.2h0.1 M537.9,407.1c0.9-0.1,1.6-0.4,2.3-1 M536.3,407.2h0.1l1.4-0.1 M537.9,407.8v-0.7
	 M536.3,407.2l-5.2-0.3 M537.9,407.8c-2.2,0.4-4.5,0.6-6.8,0.4 M537.9,423.8v-10.4 M537.9,413.4v-5.6 M540.2,406.2
	c1.3-0.4,2.6-1,3.9-1.6 M531.2,408.2v4.6c-16.3,0.9-29,0-37.8-2.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M537.9,423.8v24.1c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.7-3,8.8-8.7
	l0.1-121.9 M443.3,290.9v19.3c0,1-0.6,1.6-1.6,1.6h-41.8c-1,0-1.6-0.6-1.6-1.6v-19.3c0-1,0.6-1.6,1.6-1.6h41.8
	C442.9,289.3,443.3,289.9,443.3,290.9L443.3,290.9z M469.3,325.8c3.2-0.1,5.2-2.3,5.9-6.6l0.4-3 M469.3,325.8h-24.4 M448.2,358.3
	h-54.7c-3.6,0.3-5.6,2.2-5.9,5.6v14.9h66.5v-14.9C453.8,360.5,451.8,358.6,448.2,358.3z M482.7,386.4h-7.9l-2.6,3.8H369.7l-2.6-3.8
	h-7.9 M482.7,389.3v-2.9 M491.3,399.7c-0.3-1-0.4-2-0.4-3.2c0-2.5,0.7-4.6,2.3-6.4c1.4-1.7,3.3-2.6,5.5-2.6h0.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M489.3,394.5c-1,0-1.4,0.7-1.3,2.3c0.4,1.4,1.6,2.3,3.3,2.9 M487.4,389.3
	l1.9,5.2 M487,389.3h0.4 M487,388.5l0.4,0.9 M487,389.3v-0.9 M486.3,386.4l0.7,2 M498.6,386.4l0.4,1.2 M498.6,386.4h-12.3
	 M514.7,386.4h-1.6 M513.1,388.3l1.4,0.1 M514.6,388.5l-0.1,1.7c0,0.1,0,0.3,0.1,0.4s0.3,0.1,0.3,0.1l0.4-0.1l0.1-0.3l0.1-1.7
	 M515.7,386.4l-0.1,2 M514.6,388.5l0.1-2 M515.7,386.4h-1 M513.1,386.4v1.9 M528.1,386.4H527 M527.8,389l0.3-2.6 M526.8,389l0.1-2.6
	 M527.8,389l-0.1,2l-0.1,0.3l-0.3,0.1c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.3-0.1-0.3l0.1-2 M527,386.4h-11.3 M515.6,388.5
	l11.3,0.6 M513.1,386.4h-14.6 M499,387.6l14.1,0.7 M482.7,389.3h4.3 M486.3,386.4h-3.6 M444.9,325.8l-5.2-3.3H402l-5,3.3
	 M366.1,316.2l0.4,3c0.9,4.3,2.7,6.6,5.9,6.6 M359.2,386.4H356 M359.2,386.4v2.6 M354.7,388l-0.9,1 M354.7,389v-1 M353.7,389v0.3
	 M348.4,387.4l2.9,0.1c1.6,0.3,2.3,0.7,2.3,1.3v0.1 M353.7,389.3l-0.1,0.1c-0.3,0.3-1,0.7-1.9,1c-0.9,0.3-1.9,0.6-3,0.9 M353.9,389
	l-0.1,0.3 M353.7,389h0.1 M353.9,389h0.9 M354.7,386.4v1.6 M349.1,399.7c3.3-0.9,4.8-2,4.5-3.6c-0.3-1.6-1.2-2.2-2.6-1.7l-2.2,0.6
	 M359.2,389h-4.5 M354.7,388l1.3-1.6 M356,386.4h-1.3 M397,325.8h-24.5 M405.8,411.5c2.9,1,7.5,1.6,14.1,1.6h2
	c6.6,0,11.3-0.6,14.1-1.6 M436.1,411.5l2.7-2.6 M405.8,411.5l-2.7-2.6 M403.1,408.9h35.8 M537.9,413.4c-29.1,1.9-65.5,2.9-109.1,2.9
	h-13.4c-39.8,0-73.7-0.7-101.7-2.3l-10-0.6 M403.1,408.9h-33.3c-2.9,0.1-5.2-0.3-6.9-1 M354.7,403.9v-1.7 M354.7,407.9v-4
	 M354.7,402.2l-2.3-0.1l-0.1,0.1 M352.3,402.2h2.5 M354.7,403.9h-2.5l-2.9,1.4 M362.7,407.9h-7.9 M359.2,389v11.7
	c-0.3,3.5,0.9,5.9,3.6,7.2 M512.3,406c0.3,1,2.2,1.6,5.8,1.9l10,0.7v-1.9 M528,406.8l-15.7-0.7 M491.3,399.8c0.3,1,0.9,2,1.7,3
	c1.4,1.7,3.3,2.6,5.5,2.6l13.7,0.6 M487,407.9v-8.1h-4.3 M493.4,410.2c-2.3-0.7-4.3-1.4-6.2-2.3 M491.3,399.8v-0.1 M438.8,408.9
	h33.3c2.9,0.1,5.2-0.3,6.9-1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M479.1,407.9c2.7-1.3,3.9-3.6,3.6-7.2v-0.9 M479.1,407.9h7.9 M482.7,399.8
	v-10.5 M354.7,402.2V389 M436.1,411.5l57.3-1.3 M366.1,316.2h109.5 M489.3,394.5l2,5.2 M444.9,325.8H397 M549.5,325.8h-80.2
	 M536.3,389.5l-8.5-0.4 M528,406.8l3.2,0.1 M547.2,367.8h-92.5 M265.7,290.6c-1.4,4.5-3,8.7-4.8,12.4l0.7,22.8 M261.8,325.8v0.1
	 M263,334.6l-1.2-8.7 M261.8,325.8h5.9 M292.4,334.9l-0.1-9.1 M292.3,325.8h-24.5 M292.4,334.9l-29.4-0.3 M294.6,367.8l-1.2-14.9
	 M293.4,353l-0.1-4.2 M289.7,386.4c-2.7,0.1-3.8,1.7-2.7,5.2l3.2,6.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M286.1,395.4l-2.2-4.8c-0.9-2.6-0.1-4,2.2-4.2h3.6 M269.8,367.8
	c1.2,3.6,2.3,6.8,3.6,9.2c4.2,7.5,8.4,13.6,12.7,18.3 M294.6,367.8h-24.8 M329.1,389v-2.6 M329.1,389h9.1 M338.1,389v0.1l0.9,1.2
	 M348.4,387.4v-1 M341.2,386.4h2.9 M344.1,386.4l0.1,1.4 M344.2,387.9c-1.2,0.1-2.3,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1,0.4
	c-0.7,0.4-1,0.9-1,1.2 M339,390.3c0,0.6,0.9,1,2.3,1.2l3.2,0.3 M344.5,391.8l0.3,4.2 M348.8,394.9l-0.1-3.6 M348.7,391.3l-0.3-3.9
	 M344.5,391.8l-0.3-3.9 M348.4,386.4h-4.3 M341.2,386.4c-2.5,0-3.5,1-3,2.7 M329.1,386.4h12.1 M263,334.6c2,14,4.3,25.1,6.8,33.2
	 M289.7,386.4h39.4 M293.3,348.8l-0.9-13.9 M349.2,402.2l-0.1-2.5 M345.1,400.6l0.1,1.6 M349.2,403.6v-1.4 M344.8,396
	c-2.5,0.3-4,1.2-4.9,2.5c-0.6,1.3-0.1,2,1.6,2.5l3.5-0.3 M349,408.8c-2,0.7-3.2,0.4-3.5-1v-0.4 M349.4,405.3l-0.4,3.5 M345.5,407.4
	l-0.3-5.2 M349.4,405.3l-0.1-1.7 M333.8,402.2h-3.9 M329.9,402.2v4.9c-3,1.2-8.4,1.9-16,2 M345.2,402.2h-11.4 M333.8,412.7
	c5.8-0.4,10.7-1.3,14.9-2.5 M333.8,402.2v10.5 M345.5,407.4c-5-0.3-8.9-2-11.7-5.2 M326,402.2h-4.6v1.4l-7.6,0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M313.8,406.2H326v-4 M313.8,403.9v2.3 M310.7,409.1v-2.9 M310.7,406.2
	v-2.3 M313.8,409.1v-2.9 M310.7,403.9V400 M313.8,403.9h-3 M310.7,409.1v3.8l3,0.1 M313.8,409.1v3.9 M313.8,413
	c7.5,0.3,14.1,0.1,19.9-0.3 M310.7,400l18.3,1.3V389 M329.9,402.2H326 M304,406.2h1.6 M304,408.4v-2.2 M305.5,399.6v6.6 M304,399.4
	l1.6,0.1 M304,406.2v-6.8 M290.2,398.4h-2.7l-1.4-3 M304,413.4v10.4 M304,413.4v-5 M304,399.4l-13.7-1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M261.8,326l0.1,121.9c0,5.5,2.9,8.4,8.8,8.7h24.4c5.8-0.1,8.8-3,8.8-8.7
	v-24.1 M304,408.4l6.8,0.7 M305.5,406.2h5.2 M310.7,400l-5.2-0.4 M345.1,400.6l-0.3-4.6 M349.1,399.7l-0.3-4.8 M304,423.8h234
	 M372.5,325.8h-80.2 M348.4,386.4h6.3 M352.3,402.2h-3 M349.2,403.6l3-1.4 M348.5,410.2l57.3,1.3 M548.6,348.8H293.3 M293.4,353
	h254.9 M387.2,367.8h-92.6 M354.7,407.9c-1.9,0.9-3.9,1.6-6.2,2.3 M349,408.8l5.8-0.9 M363.7,285.7c0.4,11.8,1.3,22.1,2.5,30.4
	 M475.6,316.2c1.2-8.4,2-18.6,2.5-30.4 M269.2,279.2l-3.5,11.4 M572.7,279.2l3.3,11.4"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M295.9,253.4
		c0,0,5.5-42,17.2-71.8l68.8,0.1l4,3h35.1H456l4.2-3l68.8-0.1c0,0,11.7,28.7,17.2,71.8H421.1H295.9z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M267.7,325.8h24.5
		c0,0-0.7-35.8,3.2-69.2l-21.2,1C274.2,257.5,266,284.9,267.7,325.8z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M295.9,253.4
		c0,0-4.3,33.3-3.5,72.4l0.1,9.1l0.9,13.8h127.7h127.8l0.9-13.8l0.1-9.1c0,0,0.4-42.7-3.6-72.4H421.2L295.9,253.4L295.9,253.4z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M567.7,257.5l-21.2-1
		c0,0,3.6,31.2,3.2,69.4h24.5C574.1,325.8,576.6,286.9,567.7,257.5z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M261.8,325.8h30.6l0.9,22.9
		l0.3,4h127.4h127.5l1-18l0.1-9.1h30.6c0,0-2.5,24.7-7.9,42c0,0-2.3,12.3-17.5,28.7l-2.5-0.4c0,0,3-4.2,3-6.6c0,0,0.4-3.2-3-3.2
		h-77.5l-2.6,3.9h-51.2h-51.2l-2.6-3.9h-77.9c0,0-3.9,0.1-2.2,5.2l3.2,6.9h-2.7l-1.4-3.3c0,0-11.3-11.4-16.3-27.4
		C269.8,367.8,265.1,354.5,261.8,325.8z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv4DrRear);
