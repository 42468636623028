import React, { Component } from 'react';

class HambergerMenu extends Component {
  render() {
    const { size = 30, color = 'black' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M26.7333 14.6667H5.26667C4.56711 14.6667 4 15.2338 4 15.9333V16.0667C4 16.7662 4.56711 17.3333 5.26667 17.3333H26.7333C27.4329 17.3333 28 16.7662 28 16.0667V15.9333C28 15.2338 27.4329 14.6667 26.7333 14.6667Z" />
        <path d="M26.7333 21.3333H5.26667C4.56711 21.3333 4 21.9005 4 22.6V22.7333C4 23.4329 4.56711 24 5.26667 24H26.7333C27.4329 24 28 23.4329 28 22.7333V22.6C28 21.9005 27.4329 21.3333 26.7333 21.3333Z" />
        <path d="M26.7333 8H5.26667C4.56711 8 4 8.56711 4 9.26667V9.4C4 10.0996 4.56711 10.6667 5.26667 10.6667H26.7333C27.4329 10.6667 28 10.0996 28 9.4V9.26667C28 8.56711 27.4329 8 26.7333 8Z" />
      </svg>
    );
  }
}

export default HambergerMenu;
