import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import WishlistCard from './WishlistCard';
import {
  loadRemoveWishlistItem,
  loadUpdateWishlistItem,
  loadWishlistItems,
} from '../../actions';
import { getConfig, isJSON } from '../../utils/helpers';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '20px 30px',
    minHeight: '80vh',
  },
};

class Wishlist extends Component {
  componentDidMount() {
    this.props.loadWishlistItems(getConfig('marketplaceId'));
  }

  renderWishlistItems() {
    const wishlistItems = this.props.wishlistItems.items;

    if (!wishlistItems) {
      return null;
    }

    if (!wishlistItems.length) {
      return (
        <div style={{ margin: 20, fontSize: 16 }}>
          You currently have no saved searches.
        </div>
      );
    }

    return wishlistItems.map(item => {
      const data = {
        ...item,
        value: isJSON(item.value)
          ? JSON.parse(item.value)
          : { query: item.value },
      };

      return (
        <WishlistCard
          key={item.id}
          data={data}
          navigate={this.props.navigate}
        />
      );
    });
  }

  render() {
    const title = getConfig('name')
      ? getConfig('name') + ' | Saved Searches'
      : 'Saved Searches';

    return (
      <DocumentTitle title={title}>
        <div style={styles.container}>{this.renderWishlistItems()}</div>
      </DocumentTitle>
    );
  }
}

Wishlist.propTypes = {
  loadingBar: PropTypes.object,
  loadWishlistItems: PropTypes.func.isRequired,
  wishlistItems: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { loadingBar, wishlistItems } = state.entities;
  return { loadingBar, wishlistItems };
};

export default connect(mapStateToProps, {
  loadWishlistItems,
  loadRemoveWishlistItem,
  loadUpdateWishlistItem,
})(Wishlist);
