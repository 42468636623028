import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import { updateItem } from '../../../../../actions';
import Layout from '../../templates/forms/Layout';
import FormFooter from '../../templates/forms/formFooter';
import '../../../styles/form/question.css';
import { showRow } from '../../../utils/helpers';
import { QUESTION_TYPES } from '../../../constants/common';

class Question extends Component {
  onChangeValue(entity, value) {
    if (
      ['grayMarket', 'previousCanadian', 'titlePresent'].includes(entity) &&
      !isArray(this.props.item.blockAnnouncements) // never seen it but code suggests it can be an array
    ) {
      // NOTE - add this to block announcements if true remove if false
      const blockAnnouncements = (this.props.item.blockAnnouncements || '')
        .split(',')
        .map(el => el.trim())
        .filter(el => el);
      const legend = {
        grayMarket: 'Gray Market',
        previousCanadian: 'Previous Canadian',
        titlePresent: 'Title Present',
      };
      const text = legend[entity];
      const position = blockAnnouncements.indexOf(text);

      if (value === true && position === -1) {
        blockAnnouncements.push(text);
        this.props.updateItem({
          blockAnnouncements: blockAnnouncements.join(', '),
        });
      } else if (value !== true && position !== -1) {
        blockAnnouncements.splice(position, 1);
        this.props.updateItem({
          blockAnnouncements: blockAnnouncements.join(', '),
        });
      }
    }

    this.props.updateItem({ [entity]: value });
  }

  renderQuestion(question, entity, rowSection) {
    const { item, coreSections } = this.props;

    if (!showRow(rowSection, coreSections)) return null;

    return (
      <div key={question} className="form-checkbox-container">
        <span>{question}</span>
        <div className="h-100">
          <label className="form-checkbox-label mr-8">
            Yes
            <input
              type="radio"
              name={`question-${entity}`}
              defaultChecked={item[entity] === true}
              onChange={() => this.onChangeValue(entity, true)}
            />
            <span className="form-checkbox-checkmark" />
          </label>
          <label className="form-checkbox-label mr-8">
            No
            <input
              type="radio"
              name={`question-${entity}`}
              defaultChecked={item[entity] === false}
              onChange={() => this.onChangeValue(entity, false)}
            />
            <span className="form-checkbox-checkmark" />
          </label>
          <label className="form-checkbox-label">
            Unknown
            <input
              type="radio"
              name={`question-${entity}`}
              defaultChecked={item[entity] !== true && item[entity] !== false}
              onChange={() => this.onChangeValue(entity, 'Unknown')}
            />
            <span className="form-checkbox-checkmark" />
          </label>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Questions</div>
            </div>
            <div className="form-body-container">
              <div className="form-body form-body-vertical questions-form-body">
                {QUESTION_TYPES.map(({ title, entity, rowSection }) =>
                  this.renderQuestion(title, entity, rowSection)
                )}
              </div>
            </div>
            <FormFooter currentSection="s4" />
          </div>
        </div>
      </Layout>
    );
  }
}

Question.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { vin, item, variant, coreSections } = state.core;
  return { vin, item, variant, coreSections };
};

export default connect(
  mapStateToProps,
  { updateItem }
)(Question);
