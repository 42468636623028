import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import { commafyCurrency } from '../utils/helpers';

export default class ConditionReport extends Component {
  renderRowsFromCR(cr = {}) {
    const items = values(cr).map((item, index) => {
      const hasCost = Number(item.$.E) > 0;
      return (
        <tr key={index}>
          <td>{item.$.T}</td>
          <td>{item.$.D}</td>
          <td>{hasCost ? commafyCurrency(item.$.E) : ''}</td>
        </tr>
      );
    });

    return items;
  }

  renderRowsFromDetails(details = {}) {
    const items = values(details).map((item, index) => {
      const hasCost = Number(item.e);
      return (
        <tr>
          <td>{item.t}</td>
          <td>{item.d}</td>
          <td>{hasCost ? commafyCurrency(item.e) : ''}</td>
        </tr>
      );
    });

    return items;
  }

  render() {
    const { cr, details } = this.props;
    const content = cr
      ? this.renderRowsFromCR(cr)
      : details
        ? this.renderRowsFromDetails(details)
        : null;

    if (!content) return null;

    return (
      <table className="table table-striped">
        <tbody>
          <tr>
            <th>Area</th>
            <th>Description</th>
            <th>Cost</th>
          </tr>
          {content}
        </tbody>
      </table>
    );
  }
}

ConditionReport.propTypes = {
  cr: PropTypes.array,
  details: PropTypes.object,
};
