import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, uniqBy } from 'lodash';
import { loadConditionDamages } from '../../../../../../actions';
import Icon from '../../../ui/icons';
import Loader from '../../../ui/loader';
import { CYAN_01, GREY_07 } from '../../../../constants/colors';
import Layout from '../../../templates/forms/Layout';
import ConditionFooter from '../../../templates/forms/condition/splats/footer';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import '../../../../styles/form/condition.css';

class Damage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: !!props.location.state.edit,
      area: '',
      part: '',
      loaded: false,
      loadingDataSource: [{ description: 'Loading...' }],
      menuItems: ['Standard'],
      selectedMenuItem: 0,
      searchText: '',
      openErrorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems() {
    const area = this.props.location.state.condition.area;
    const part = this.props.location.state.condition.part;
    const crTypes = this.props.crTypes.values;

    let menuItems = [];
    if (crTypes) {
      menuItems =
        crTypes.length > 0
          ? crTypes.map(obj => {
              return obj.name;
            })
          : [];
    }

    this.setState(
      {
        loaded: false,
        area,
        part,
        menuItems: [...menuItems, 'Standard'],
      },
      () => {
        this.props
          .loadConditionDamages(
            area,
            part,
            this.state.menuItems[this.state.selectedMenuItem]
          )
          .then(({ response }) => {
            if (response.amStatus === 'Success') {
              this.setState({ loaded: true });
            } else if (response.amStatus === 'Error') {
              this.setState({
                message: `Unable to load data. Error: ${response.amMessage}`,
                openErrorModal: true,
              });
            }
          })
          .catch(error => console.error(error));
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.loaded &&
      nextProps.condition.damages.amStatus === 'Success'
    ) {
      this.loadItems();
    }
  }

  handleClick = item => {
    const { isEdit } = this.state;
    const {
      edit,
      condition,
      selectedCrType,
      checked,
    } = this.props.location.state;

    if (item.severityDescription !== '') {
      this.props.navigate(
        isEdit ? '/core/conditionReview/severity' : '/core/condition/severity',
        {
          state: {
            condition: {
              area: condition.area,
              part: condition.part,
              damage: item.description,
              selectedRow: condition.selectedRow,
            },
            edit,
            checked: checked && item.description === edit.damage ? true : false,
            selectedCrType: selectedCrType,
            prevPage: 'severity',
          },
        }
      );
    } else {
      this.props.navigate(
        isEdit ? '/core/conditionReview/info' : '/core/condition/info',
        {
          state: {
            condition: {
              area: condition.area,
              part: condition.part,
              damage: item.description,
              severity: [],
              selectedRow: condition.selectedRow,
            },
            edit,
            selectedCrType: selectedCrType,
            prevPage: 'damage',
          },
        }
      );
    }
  };

  filterList(searchText, list) {
    const text = searchText.toLowerCase();
    return filter(list, v => {
      const val = v.description.toLowerCase();
      return val.search(text) !== -1;
    });
  }

  render() {
    const { condition } = this.props;
    const { loaded, loadingDataSource, searchText, isEdit } = this.state;

    let damages = null;
    if (damages == null || !loaded) {
      damages = loadingDataSource;
    }
    if (condition && condition.damages) {
      damages = uniqBy(
        condition.damages && condition.damages.values,
        e => e.description
      ).sort((a, b) => (a.description < b.description ? -1 : 1));
    }

    let { area, selectedRow } = this.props.location.state.condition;

    if (searchText !== '') {
      damages = this.filterList(searchText, damages);
    }

    const { edit, checked } = this.props.location.state;

    return (
      <Layout>
        <ConfirmationModal
          open={this.state.openErrorModal}
          close={() =>
            this.setState({ openErrorModal: false, area: '', part: '' })
          }
          action={() => this.loadItems()}
          title="Error"
          body={this.state.message}
          confirmBtnTxt="Try Again"
          iconName="CircledExclamationmark"
        />
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div>
              <div>
                <div className="form-header form-sub-section">
                  <div
                    onClick={() =>
                      this.props.navigate(
                        isEdit
                          ? ['Interior', 'Mechanical', 'Frame'].includes(area)
                            ? '/core/conditionReview'
                            : '/core/conditionReview/parts'
                          : ['Interior', 'Mechanical', 'Frame'].includes(area)
                            ? '/core/condition'
                            : '/core/condition/parts',
                        {
                          state: {
                            condition: {
                              area: area,
                              selectedRow: selectedRow,
                            },
                            edit,
                          },
                        }
                      )
                    }
                  >
                    <Icon name="LeftArrow" size={20} color={CYAN_01} />
                    {['Interior', 'Mechanical', 'Frame'].includes(area)
                      ? 'Condition'
                      : 'Parts'}
                  </div>
                </div>
                <div className="form-body-container form-sub-section">
                  <div className="form-body form-sub-section">
                    <div className="form-sub-section-title">
                      <div>Damage</div>
                    </div>
                    <div
                      className="search-container"
                      style={{ borderRadius: '10px' }}
                    >
                      <input
                        type="text"
                        value={searchText}
                        onChange={e =>
                          this.setState({ searchText: e.target.value })
                        }
                        className="search-box"
                        placeholder="Search"
                      />
                    </div>
                    {!loaded ? (
                      <Loader className="conditions-loader flex justify-center pt-4" />
                    ) : null}
                    {loaded &&
                      damages.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="form-section-navigation-card"
                            onClick={() => this.handleClick(item)}
                          >
                            <span>{item.description}</span>
                            {edit &&
                            item.description === edit.damage &&
                            checked ? (
                              <Icon name="Checkmark" size={17} />
                            ) : (
                              <Icon
                                name="ChevronRight"
                                size={20}
                                color={GREY_07}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <ConditionFooter isSaveDisabled={true} isEdit={isEdit} />
          </div>
        </div>
      </Layout>
    );
  }
}

Damage.propTypes = {
  loadConditionDamages: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { condition, crTypes, item } = state.core;
  const { loadingBar } = state;
  return { condition, crTypes, loadingBar, item };
};

export default connect(
  mapStateToProps,
  {
    loadConditionDamages,
  }
)(Damage);
