import React, { Component } from "react";

export default class PrintButton extends Component {
  handlePrint = () => {
    if (this.props.printHandler) {
      this.props.printHandler();
    } else {
      window.print();
    }
  };

  render() {
    return (
      <button
        style={{ marginBottom: 10 }}
        onClick={this.handlePrint}
        className="pull-right btn btn-primary"
      >
        <span className="fa fa-print" /> Print
      </button>
    );
  }
}
