import React, { Component } from 'react';
import { localization } from '../utils/localization';
import { getConfig } from '../utils/helpers';

export default class Commafy extends Component {
  render() {
    const num = this.props.number
      ? this.props.number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '';

    return <span style={this.props.style}>{num}</span>;
  }
}
export const CommafyCurrency = number => {
  if (!number) return number;
  const num = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${localization[getConfig('localization')].currency}` + num;
};

export const AddComma = number => {
  if (!number) return number;
  const num = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num;
};

// exports.CommafyCurrency = CommafyCurrency;
// exports.AddComma = AddComma;
