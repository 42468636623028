import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe2DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M570.4,187.7l-3.3-15c-2.1-11.1-2.7-17-1.8-17.7l0.4-0.6c0-0.2,0-0.4-0.2-0.6l-0.6-0.4l-0.8,0.2
	c-1.9,1.6-0.6,13.1,4.3,34.5c0.6,5.5,2.9,10.7,6.8,16l9.4,12.7l2,1.9l2.1,1.6c2.1,1.6,5.7,3.3,10.5,5.5l2,0.2l-4.5-3.5
	c-1.8-0.6-3.5-1.4-5.3-2.3l-2.7-1.9c-1-0.8-1.9-1.8-2.7-2.5l-2.1-2.7l-7-9.7C573.1,197.9,571,192.8,570.4,187.7L570.4,187.7z
	 M561.1,150.1c-1.6-1.6-3.1-2.7-4.7-3.7c-1.4-0.8-2.5-1.2-3.3-1.2c-0.8,0-1.2,0.4-1.4,1.2c3.1,7.8,2.5,28.8-1.9,62.9
	c1.6,0.8,2.9,1.8,4.5,2.9l2,1.9c3.7,4.1,7.4,7.8,11.5,11.3C562.8,190.3,560.7,165.1,561.1,150.1L561.1,150.1z"
          />
          <path
            fill="#FFFFFF"
            d="M551.7,146.4l-2.5-0.8c-0.2,6-0.6,12.3-1.4,18.7c-1.4,13.4-3.9,27.7-7.6,42.3c3.5,0.2,6.6,1,9.7,2.5
	C554.4,175.1,555,154.2,551.7,146.4L551.7,146.4z M564.4,153.6l-3.3-3.5c-0.4,15,1.8,40.1,6.6,75.4l0.6,1.2
	c11.7,2.7,20.5,8.4,25.9,17c6,9.4,8.8,18.5,7.8,27.1c4.1,6.2,7.2,15.2,9.5,26.5c1,3.5,3.7,5.3,8.2,5.3l13.4,2.1l2.1-20.9
	c0.2-9.9-0.6-16-2.3-18.1l-17.7-30.8c-0.6-1-1.4-1.9-2.1-2.9l-0.4-0.4c-1.2-1.4-2.3-2.3-3.3-3.1c-1.4-1.2-2.7-1.8-3.9-1.8
	c-1.4-0.4-2.7-0.8-4.1-1l-2-0.2c-4.9-1.9-8.4-3.9-10.5-5.5l-2.1-1.6l-2-1.9l-9.4-12.7c-4.1-5.3-6.4-10.5-6.8-16
	C563.8,166.7,562.4,155.2,564.4,153.6L564.4,153.6z"
          />
          <path
            fill="#FFFFFF"
            d="M547.8,164.3c0.8-6.4,1.2-12.7,1.4-18.7c-15.2-4.7-33.9-7.6-56.1-9.2l-13.1,0.8l-30.8-1.4l-20.5-0.2
	l-33.9,0.4l-30,1.4l-13.1-0.8c-22.2,1.6-40.9,4.5-56.1,9.2c0.2,6,0.6,12.3,1.4,18.7c21.8-6.2,59.6-9.9,113.4-10.7h-13.3h32.5
	C486,154.2,525.4,157.7,547.8,164.3L547.8,164.3z M556.4,214.2l-2-1.9c-1.4-1.2-2.9-2.1-4.5-2.9c-3.1-1.6-6.2-2.5-9.7-2.5
	c-5.3,3.5-23.6,4.5-55.2,2.5l-55.5-1.9l-32,0.2l-38,1.6c-31.6,1.8-50.1,1-55.2-2.5c-3.5,0.2-6.6,1-9.7,2.5c-1.6,0.8-3.1,1.8-4.5,2.9
	l-2,1.9c-3.7,4.1-7.6,7.8-11.5,11.3l-0.6,1.2c40.7-4.7,89.4-6.8,146-6.2c56.7-0.6,105.4,1.4,146.2,6.2l-0.6-1.2
	C563.8,222.2,559.9,218.3,556.4,214.2L556.4,214.2z M519.4,242.9l5.5,0.2l23.6,1.6h0.2l4.1,0.4l2,0.2l15.8,1.6h0.6l4.1,0.2
	c1.8,0.2,3.3,0.6,4.9,1.2c2,0.8,3.7,1.8,5.3,2.9c2.9,1.8,4.3,4.3,4.5,7.4l-1.2,13.3c-0.6,3.5-3.1,6-7.2,7.6l-1.4,0.4l-2.5,0.2
	l-4.5-0.2l-2.3-0.2l-14-0.8H555l-4.7-0.2l-2.7-0.4l-13.2,0.8h-0.2l-2.1,0.2l-4.5-0.2l-3.3-0.4c1.4-0.6,2.1-2.1,2.5-4.9l1.4-18.9
	c0.2-5.5-0.6-9.2-2.7-11.3l-0.2-0.2l-0.4-0.4L519.4,242.9c-0.6,1.6-1.2,3.5-1.6,5.5l-1.6,26.7c0.4,3.5-0.8,6-3.5,7.4H578
	c10.5-0.4,16-4.5,16.6-12.3c0.8-8-1-15-5.3-21c-2.5-4.9-9.4-8.2-20.3-9.7c-13.8-1.4-28.6-2.5-44.4-3.5
	C522.3,237.4,520.5,239.8,519.4,242.9L519.4,242.9z"
          />
          <path
            fill="#FFFFFF"
            d="M519.4,242.9c1.4-3.1,2.9-5.5,5.3-7c-28.5-1.6-60.2-2.3-95.1-2.1h-7.2c-7.8,0-15.6,0-23.2,0.2
	c-28.6,0-55,0.6-78.9,1.9c2.1,1.6,3.9,3.9,5.3,7c24.2-1.2,51.5-1.8,81.9-1.6h30.4C467.9,241.1,495.2,241.7,519.4,242.9L519.4,242.9z
	"
          />
          <path
            fill="#FFFFFF"
            d="M517.8,248.3c0.4-1.9,1-3.9,1.6-5.5c-24.2-1.2-51.4-1.8-81.7-1.6h-30.4c-30.4,0-57.7,0.4-81.9,1.6
	c0.6,1.6,1.2,3.5,1.6,5.5l1.6,26.7c-0.4,3.5,0.8,6,3.3,7.4h180.8c2.5-1.4,3.7-3.7,3.5-7.4L517.8,248.3L517.8,248.3z M407.9,260.4
	c0-2.7,1.4-5.3,3.9-7.2c2.7-1.9,5.8-2.9,9.5-2.9c3.7,0,7,1,9.7,2.9c2.7,1.9,4.1,4.5,4.1,7.2v1.6c0,2.9-1.4,5.3-4.1,7.2
	c-2.7,2-5.8,2.9-9.7,2.9c-3.7,0-7-1-9.5-2.9c-2.7-2-3.9-4.5-3.9-7.2V260.4L407.9,260.4z"
          />
          <path
            fill="#FFFFFF"
            d="M412,253.2c-2.7,1.9-3.9,4.5-3.9,7.2v0.8c0.2,2.5,1.6,4.7,3.9,6.4c2.7,2,5.8,2.9,9.5,2.9c3.7,0,7-1,9.7-2.9
	c2.5-1.8,3.7-3.9,4.1-6.4v-0.8c0-2.7-1.4-5.3-4.1-7.2s-5.8-2.9-9.7-2.9C417.8,250.3,414.5,251.3,412,253.2L412,253.2z M411.4,255.2
	l1.4-1.4c2.3-1.8,5.3-2.7,8.8-2.7c3.5,0,6.4,1,8.8,2.7l1.4,1.2c1.6,1.6,2.3,3.3,2.3,5.3c0,2.5-1.2,4.7-3.7,6.6
	c-2.3,1.8-5.3,2.7-8.8,2.7c-3.5,0-6.4-1-8.8-2.7c-2.3-1.8-3.7-4.1-3.7-6.6C409.1,258.5,409.8,256.7,411.4,255.2L411.4,255.2z"
          />
          <path
            fill="#FFFFFF"
            d="M412.8,254l-1.4,1.4c-1.6,1.6-2.3,3.3-2.3,5.3c0,2.5,1.2,4.7,3.7,6.6c2.3,1.8,5.3,2.7,8.8,2.7
	c3.5,0,6.4-1,8.8-2.7c2.3-1.8,3.7-4.1,3.7-6.6c0-2-0.8-3.7-2.3-5.3l-1.4-1.2c-2.3-1.8-5.3-2.7-8.8-2.7
	C418.2,251.3,415.3,252,412.8,254L412.8,254z M412.4,257.7l0.6-0.2h17.9v1h-4.1v3.1h-1.6v-3.1h-11.7v2.1h6V260h-3.1v-0.4h3.7v1
	l-0.2,0.2h-7.2l-0.2-0.4v-2.3c0-0.2,0.2-0.2,0.4-0.2h12.5v3.5h0.4v-3.5h4.7v-0.2h-17.3l-0.6,0.2c-0.2,0-0.2,0.2-0.2,0.4v2.3
	c0,0.2,0,0.4,0.2,0.4l0.6,0.2h6.6l0.4-0.2l0.2-0.2v-1.2c0-0.2-0.2-0.4-0.6-0.4h-3.7v-0.4h4.1c0.4,0,0.6,0.2,0.6,0.4v1.4l-0.4,0.6
	l-0.8,0.2h-7.2c-0.4,0-0.8-0.2-1-0.4v-3.1C411.6,257.9,411.8,257.7,412.4,257.7L412.4,257.7z"
          />
          <path
            fill="#FFFFFF"
            d="M413,257.5l-0.6,0.2c-0.4,0-0.8,0.2-0.8,0.6v3.1c0.2,0.2,0.4,0.4,1,0.4h7.2l0.8-0.2l0.4-0.6v-1.4
	c0-0.2-0.2-0.4-0.6-0.4h-4.1v0.4h3.7c0.2,0,0.4,0,0.6,0.4v1.2l-0.2,0.2l-0.4,0.2h-6.8l-0.6-0.2c-0.2,0-0.2-0.2-0.2-0.4v-2.3
	c0-0.2,0-0.4,0.2-0.4l0.6-0.2h17.7v-0.4H413L413,257.5L413,257.5z"
          />
          <path
            fill="#FFFFFF"
            d="M412.8,258.5v2.3l0.2,0.4h6.8l0.2-0.2v-1h-3.7v0.4h3.1v0.6h-6v-2.1H425v3.1h0.6v-3.5h-12.5
	C413,258.3,412.8,258.3,412.8,258.5L412.8,258.5z M430.9,258.7v-0.4H426v3.3h0.8v-2.9"
          />
          <path
            fill="#FFFFFF"
            d="M435.2,261.2c-0.2,2.5-1.6,4.7-4.1,6.4c-2.7,2-5.9,2.9-9.7,2.9c-3.7,0-7-1-9.5-2.9c-2.3-1.8-3.7-3.9-3.9-6.4
	v0.8c0,2.9,1.4,5.3,3.9,7.2c2.7,2,5.8,2.9,9.5,2.9c3.7,0,7-1,9.7-2.9c2.7-2,4.1-4.5,4.1-7.2L435.2,261.2L435.2,261.2z M548.2,244.6
	h0.2 M613.9,224.9h-1.8l-6.4,1.6c1.2,0,2.5,0.6,3.9,1.8c1,0.8,2.1,1.9,3.3,3.1l7-2.1h0.8C618.4,229.4,616,228.1,613.9,224.9
	L613.9,224.9z"
          />
          <path
            fill="#FFFFFF"
            d="M611.5,218.9c0,2.5,0.8,4.5,2.5,6c2.1,2.9,4.3,4.5,7,4.5l26.3-3.1c1.9-0.6,3.1-1.9,3.7-3.7
	c0.2-0.8,0.4-1.8,0.4-2.7c0-1.9-0.6-4.5-1.6-7.4c-1.8-5.5-5.5-8.2-10.9-8l-19.5-1.6c-4.1,0-6.4,2.5-6.6,7.6L611.5,218.9L611.5,218.9
	z M615.6,224.2c-1.6-1.6-2.1-3.3-2.1-5.5l1-7.4c0.2-4.5,2.1-6.8,6-6.8l17.5,1.4c4.9-0.2,8.2,2.1,9.7,7.2c1,2.5,1.6,4.9,1.6,6.6
	c0,1,0,1.8-0.2,2.3c-0.6,1.6-1.6,2.7-3.3,3.3l-23.6,2.9h-0.6C619.1,227.9,617.4,226.5,615.6,224.2L615.6,224.2z M569.1,228.7
	l-0.6-1.8c-40.9-4.9-89.6-6.8-146.2-6.2c-56.5-0.6-105.2,1.4-146,6.2l-0.6,1.8v10.5c13.8-1.4,28.6-2.5,44.4-3.5
	c24.2-1.4,50.5-1.9,78.9-1.9c7.8,0,15.4-0.2,23.2-0.2h7.2c34.7-0.2,66.5,0.6,95.1,2.1c15.8,0.8,30.6,1.9,44.4,3.5L569.1,228.7
	L569.1,228.7z"
          />
          <path
            fill="#FFFFFF"
            d="M568.5,226.9l0.6,1.8v10.5c10.9,1.6,17.7,4.9,20.3,9.7c4.3,6,6,13.1,5.3,21l7.6,0.8c1-8.6-1.6-17.5-7.8-27.1
	C588.9,235.3,580.2,229.6,568.5,226.9L568.5,226.9z M319.2,243.1l6-0.2c-1.4-3.1-2.9-5.5-5.3-7c-15.8,0.8-30.6,1.9-44.4,3.5
	c-10.9,1.6-17.7,4.9-20.3,9.7c-4.3,6-6,13.1-5.3,21c0.4,7.8,6,11.9,16.4,12.3h65.3c-2.5-1.4-3.7-3.7-3.3-7.4l-1.6-26.7
	c-0.4-1.9-1-3.9-1.6-5.5L319.2,243.1l-0.2,0.4c-1.8,2.1-2.5,6-2.3,11.5l1.4,18.9c0.2,2.7,1.2,4.3,2.5,4.9l-3.3,0.4l-4.5,0.2
	l-1.6-0.2l-13.2-0.8l-3.3,0.4l-4.5,0.2l-2-0.2l-12.5,0.6l-3.3,0.4l-4.7,0.2l-2.5-0.2l-1.2-0.4c-4.3-1.6-6.6-4.1-7.4-7.6l-1.2-13.3
	c0-3.1,1.6-5.6,4.5-7.4c1.6-1.4,3.3-2.1,5.3-2.9c1.6-0.6,3.1-1,4.9-1.2l4.1-0.2h0.6l15.8-1.6l2-0.2l4.1-0.4h0.2L319.2,243.1
	L319.2,243.1z M380.4,413.8l-5.3-6.2"
          />
          <path
            fill="#FFFFFF"
            d="M512.7,282.4H266.6c-10.5-0.4-16-4.5-16.4-12.3l-7.6,0.8c0.2,1.8,0.6,3.7,1.2,5.5c1.6,9.4,8,14.8,19.5,16.2
	c31.4,1.2,64.9,1.4,100.9,0.8H481c36.1,0.6,69.6,0.4,100.9-0.8c11.3-1.4,17.7-6.8,19.5-16.2c0.6-1.8,1-3.5,1.2-5.5l-7.6-0.8
	c-0.6,7.8-6,11.9-16.4,12.3L512.7,282.4L512.7,282.4z"
          />
          <path
            fill="#FFFFFF"
            d="M480.8,293.2H364c-36.1,0.6-69.6,0.4-100.9-0.8c-11.3-1.4-17.7-6.8-19.5-16.2c-0.6-1.8-0.8-3.5-1.2-5.5
	c-4.1,6.2-7.2,15.2-9.5,26.5c-1,3.5-3.7,5.3-8.2,5.3l-13.4,2.1l5.3,53.2c1.6,10.7,4.5,22,8.4,33.7c5.8-5.5,13.4-9.7,22.4-13.1
	c2.9-8.4,6.6-15.8,11.3-22v-0.2c1.2-1.4,2.3-2.3,3.7-3.5l0.6-0.4c4.7-3.7,17.1-5.7,37.6-6.2h243.4c20.3,0.4,32.9,2.5,37.6,6.2
	l0.6,0.4c1.4,1,2.5,2.1,3.7,3.5l0.2,0.2c4.7,6.2,8.6,13.6,11.3,22c9.2,3.3,16.6,7.6,22.4,13.1c4.1-11.7,6.8-23,8.4-33.7l5.3-53.2
	l-13.4-2.1c-4.5,0-7.2-1.8-8.2-5.3c-2.3-11.3-5.5-20.3-9.5-26.5c-0.2,1.8-0.6,3.7-1.2,5.5c-1.6,9.4-8,14.8-19.5,16.2
	C550.3,293.5,516.6,293.7,480.8,293.2L480.8,293.2z M473.6,295.3c5.6,0.2,9.7,2.1,12.7,5.5l16.4,23h86.5
	c17.7,2.1,28.6,8.8,32.5,19.7c-15.2-3.5-32.4-5.1-51.5-4.7H274c-19.1-0.4-36.2,1.2-51.4,4.7c4.1-10.9,14.8-17.5,32.5-19.7h86.5
	l16.4-23c2.9-3.5,7.2-5.3,12.7-5.5H473.6L473.6,295.3z M620.1,346.8l-0.8,5.3l-6.2-0.8c-2.7-1.6-4.1-3.7-3.9-7L620.1,346.8
	L620.1,346.8z M235.4,344c0.2,3.3-1,5.6-3.7,7l-6.2,0.8l-0.8-5.3L235.4,344L235.4,344z"
          />
          <path
            fill="#FFFFFF"
            d="M486.2,301c-2.9-3.5-7-5.3-12.7-5.5H371.1c-5.7,0.2-9.7,2.1-12.7,5.5l-16.4,23l17-18.7
	c4.5-5.1,10.3-7.4,17.3-6.4h92.2c7-0.8,12.9,1.4,17.3,6.4l17,18.7L486.2,301L486.2,301z"
          />
          <path
            fill="#FFFFFF"
            d="M485.6,305c-4.5-5.1-10.3-7.4-17.3-6.4h-17.9c2.3,0,4.1,1.8,5.3,5.3v22.6c0,2.3-1.6,3.3-4.5,2.7h-57.9
	c-2.9,0.6-4.5-0.4-4.5-2.7v-22.6c1-3.5,2.7-5.3,5.3-5.3h-17.9c-7-0.8-12.9,1.4-17.3,6.4l-17,18.7h-86.5
	c-17.7,2.1-28.6,8.8-32.5,19.7c15.2-3.5,32.4-5.1,51.4-4.7h296.2c19.1-0.4,36.2,1.2,51.5,4.7c-4.1-10.9-14.8-17.5-32.5-19.7h-86.5
	L485.6,305L485.6,305z"
          />
          <path
            fill="#FFFFFF"
            d="M455.6,303.9c-1-3.5-2.7-5.3-5.3-5.3h-56.3c-2.3,0-4.1,1.8-5.3,5.3v22.6c0,2.3,1.6,3.3,4.5,2.7h57.9
	c2.9,0.6,4.5-0.4,4.5-2.7L455.6,303.9L455.6,303.9z M619.3,352l0.8-5.3l-10.9-2.5c-0.2,3.3,1,5.7,3.9,7L619.3,352L619.3,352z
	 M231.5,351c2.7-1.4,4.1-3.7,3.7-7l-10.9,2.5l0.8,5.3L231.5,351L231.5,351z M525.8,349.9h18.1c19.3,0.4,31,2.3,35.5,5.5l0.2,0.2
	l1.9,1.8l1.2,1.4c4.9,6.6,8.8,14.6,11.7,23.8l0.8,2.3c1.4-0.2,2.3-0.6,2.9-1c0.2-0.4,0.2-0.8,0-1.2l-1.2-4.1
	c-2.9-8.4-6.6-15.8-11.3-22c-1.2-1.4-2.3-2.3-3.7-3.5l-0.6-0.4c-4.7-3.7-17.2-5.7-37.6-6.2h-243c-20.3,0.4-32.9,2.5-37.6,6.2
	l-0.6,0.4c-1.4,1-2.5,2.1-3.7,3.5c-4.7,6.2-8.6,13.6-11.3,22l-1.2,4.1c-0.2,0.4-0.2,0.8,0,1.2c0.4,0.4,1.4,0.8,2.9,1l0.8-2.3
	c2.7-9.2,6.6-17.1,11.7-23.8l1.2-1.4l1.9-1.8c0,0,0-0.2,0.2-0.2c4.5-3.1,16.4-5.1,35.5-5.5L525.8,349.9L525.8,349.9z"
          />
          <path
            fill="#FFFFFF"
            d="M598.3,382.6c0.2,0.4,0.2,0.8,0,1.2l21.2,7.8c-5.8-5.5-13.4-9.7-22.4-13.1L598.3,382.6L598.3,382.6z
	 M193.9,222.6c0.6,1.8,1.8,3.1,3.7,3.7l26.3,3.1c2.5,0,4.9-1.4,7-4.5c1.6-1.6,2.5-3.7,2.5-6l-1-8.2c-0.2-5.1-2.3-7.6-6.6-7.6
	l-19.5,1.6c-5.5-0.2-9,2.3-10.9,8c-1.2,2.9-1.6,5.5-1.8,7.4C193.5,220.9,193.5,221.8,193.9,222.6L193.9,222.6z M197,212.9
	c1.6-4.9,4.9-7.2,9.7-7.2l17.5-1.4c3.7,0,5.6,2.3,5.8,6.8l1,7.4c0,2.1-0.8,4.1-2.1,5.5c-1.8,2.5-3.5,3.7-5.7,3.9h-0.8l-23.6-2.9
	c-1.8-0.6-2.9-1.8-3.3-3.3c-0.2-0.8-0.4-1.6-0.4-2.3C195.5,217.7,196.1,215.6,197,212.9L197,212.9z M292.9,146.4
	c-0.2-0.8-0.6-1.2-1.4-1.2s-2,0.4-3.3,1.2c-1.6,1-3.1,2.3-4.7,3.7c0.4,15-1.8,40.1-6.6,75.4c4.1-3.5,7.8-7.4,11.5-11.3l2-1.9
	c1.4-1.2,2.9-2.1,4.5-2.9C290.4,175.1,289.6,154.2,292.9,146.4z"
          />
          <path
            fill="#FFFFFF"
            d="M295.4,145.6l-2.5,0.8c-3.1,7.8-2.5,28.8,2,62.9c2.9-1.6,6.2-2.3,9.7-2.5c-3.7-14.8-6.2-28.8-7.6-42.3
	C296,157.9,295.6,151.7,295.4,145.6L295.4,145.6z M278.9,154.4c0,0.2,0.2,0.4,0.4,0.6c1,0.8,0.4,6.6-2,17.7l-3.1,15
	c-0.6,5.1-2.7,10.1-6.6,15.2l-7,9.7l-2.1,2.7l-2.7,2.5l-2.7,1.9c-1.8,1-3.5,1.8-5.3,2.3l-4.5,3.5l1.9-0.2c4.9-1.9,8.4-3.9,10.5-5.5
	l2.1-1.6l2-1.9l9.4-12.7c4.1-5.3,6.4-10.5,6.8-16c4.9-21.4,6.2-32.9,4.3-34.5H280l-0.6-0.2l-0.6,0.4L278.9,154.4L278.9,154.4z"
          />
          <path
            fill="#FFFFFF"
            d="M283.6,150.1c-1,1-2.1,2.1-3.1,3.5c2,1.6,0.6,13.1-4.3,34.5c-0.6,5.5-2.9,10.7-6.8,16l-9.4,12.7l-2,1.9
	l-2.1,1.6c-2.1,1.6-5.8,3.3-10.5,5.5l-1.9,0.2c-1.6,0.2-2.9,0.6-4.1,1c-1.2,0-2.5,0.6-3.9,1.8c-1,0.8-2.1,1.9-3.3,3.1l-0.4,0.4
	c-0.8,0.8-1.4,1.8-2.1,2.9L212,265.9c-1.8,2.3-2.5,8.4-2.3,18.1l2.1,20.9l13.4-2.1c4.5,0,7.2-1.8,8.2-5.3
	c2.3-11.5,5.5-20.3,9.5-26.5c-1-8.6,1.6-17.5,7.6-27.1c5.5-8.6,14.2-14.2,25.9-17l0.6-1.2C281.8,190.3,283.9,165.1,283.6,150.1
	L283.6,150.1z"
          />
          <path
            fill="#FFFFFF"
            d="M223.7,229.4h0.8l7.2,2.1c1.2-1.4,2.3-2.3,3.3-3.1c1.4-1.2,2.7-1.8,3.9-1.8l-6.4-1.6l-1.8-0.2
	C228.6,228.1,226.5,229.4,223.7,229.4z M275.6,228.7l0.6-1.8c-11.9,2.7-20.5,8.4-25.9,17c-6,9.4-8.8,18.5-7.6,27.1l7.6-0.8
	c-0.8-8,1-15,5.3-21c2.5-4.9,9.4-8.2,20.3-9.7v-10.7L275.6,228.7L275.6,228.7z M296.2,244.6h0.2H296.2z M246.3,382.6l1.2-4.1
	c-9.2,3.3-16.6,7.6-22.4,13.1l21.2-7.8C246.3,383.4,246.1,383,246.3,382.6L246.3,382.6z"
          />
          <path
            fill="#656565"
            d="M449.8,374l5.3-5.8c3.3-4.7,9-7,16.4-7H503c10.1,0.2,17.2,3.3,21.4,8.8l11.5,17.9h5.3l-14.6-22.8
	c-3.9-6-10.9-9.4-20.9-9.9h-36.1c-6.8-0.2-12.3,2.9-16.2,9.2l-5.3,6.8c-1.6,2-5.5,2.9-11.7,3.3h-28.1c-6.2-0.2-10.1-1.4-11.7-3.3
	l-5.3-6.8c-3.9-6.2-9.2-9.4-16.2-9.2h-36.2c-9.9,0.6-17,3.9-20.9,9.9l-14.6,22.8h5.3l11.5-17.9c4.3-5.6,11.3-8.6,21.4-8.8h31.4
	c7.6,0,13.1,2.3,16.4,7l5.3,5.8c2.5,1.9,7.4,2.9,14.6,2.7H435C442.4,376.8,447.3,376,449.8,374L449.8,374z"
          />
          <path
            fill="#656565"
            d="M455,368.2l-5.3,5.8c-2.5,1.9-7.4,2.9-14.6,2.7h-25.5c-7.4,0.2-12.3-0.6-14.6-2.7l-5.3-5.8
	c-3.3-4.7-9-7-16.4-7h-5.1c-3.9,0.2-5.8,2.1-6,5.9v16.2h14.6c7.4,0.4,12.9,1.6,16.8,3.5c4.9,1.4,11.7,2,20.3,2.1h15.8
	c9.4,0,16.6-0.6,21.6-2.1c3.7-1.9,9.4-3.1,16.8-3.5h14.6V367c-0.2-3.7-2.3-5.7-6-5.9h-5.1C464,361.2,458.6,363.5,455,368.2
	L455,368.2z"
          />
          <path
            fill="#656565"
            d="M448,370.7l5.3-6.8h-62l5.3,6.8c1.6,2,5.5,2.9,11.7,3.3h28.1C442.6,373.8,446.5,372.7,448,370.7L448,370.7z
	 M482.7,383h-14.6c-7.4,0.4-13.1,1.6-16.8,3.5c-5.1,1.4-12.3,2.1-21.6,2.1h-15.8c-8.8,0-15.4-0.8-20.3-2.1
	c-3.7-1.9-9.4-3.1-16.8-3.5h-14.6c-1.6,4.3-2.9,6.4-4.7,6.4c-2.9,0.2-4.9-2-5.8-6.4h-13.8c0,2.5-0.4,4.7-1.6,6.6
	c-1.8,3.9-8.2,6.4-19.3,7.6h211.2c-11.1-1.4-17.5-3.9-19.3-7.6c-1.2-2-1.6-4.1-1.6-6.6h-13.8c-1,4.7-2.9,6.8-5.8,6.4
	C485.6,389.4,484.1,387.3,482.7,383L482.7,383z"
          />
          <path
            fill="#656565"
            d="M476.7,361c3.9,0.2,5.8,2.1,6,5.8V383c1.6,4.3,3.1,6.4,4.7,6.4c2.9,0.2,4.9-2,5.8-6.4v-13.3
	c0-4.5,1.8-7.4,5.7-8.8H476.7z"
          />
          <path
            fill="#656565"
            d="M503,361h-4.1c-3.9,1.4-5.7,4.3-5.7,8.8V383h15.4c7.4,0.2,13.8,1.8,18.9,4.7h8.2l-11.5-17.9
	C520.1,364.3,512.9,361.4,503,361L503,361z"
          />
          <path
            fill="#656565"
            d="M508.6,383.2h-1.6c0,2.5,0.6,4.7,1.6,6.6c1.8,3.9,8.2,6.4,19.3,7.6h0.4l1.2,0.2l7.2,0.6
	c18.7-0.2,30.2-2.5,34.7-6.8c1.6-2,2.5-3.9,2.9-6c0-0.6,0.2-1.4,0-2.1v-6.6c0,2-0.4,3.7-1.2,5.3c-2.1,6-14.4,9.4-36.6,9.5
	C520.1,390.6,511,387.9,508.6,383.2L508.6,383.2z"
          />
          <path
            fill="#656565"
            d="M469.5,354.9h36.1c9.9,0.6,17,3.9,20.9,9.9l14.6,22.8h6.2c-1.4-2.9-2.1-6-2.5-9.4c0-0.6-0.2-1.2-0.2-1.8
	v-0.4c0-1.6,0.2-3.1,0.8-4.7c0.8-2.1,1.9-3.9,3.7-5.6c2.9-2.9,6.2-4.3,10.3-4.3c4.1,0,7.6,1.4,10.5,4.3c2.9,2.9,4.3,6.2,4.3,10.1
	v9.2h13.3l7.8-0.2l-0.8-2.3c-2.7-9.2-6.6-17.1-11.7-23.8l-1.2-1.4h-44.2c-4.7-0.2-8-1.6-9.9-3.7H316.9c-2,2.3-5.3,3.5-9.9,3.7h-44.2
	l-1.2,1.4c-4.9,6.6-8.8,14.6-11.7,23.8l-0.8,2.3l7.8,0.2h13.3c0-0.6-0.2-1.4,0-2.1v-7c0-3.9,1.6-7.2,4.3-10.1
	c2.9-2.9,6.4-4.3,10.5-4.3c4.1,0,7.6,1.4,10.5,4.3c1.8,1.8,2.9,3.5,3.7,5.6c0.4,1.4,0.8,2.9,0.8,4.7l-0.2,2.1
	c-0.4,3.5-1.4,6.6-2.5,9.4h6.2l14.6-22.8c3.9-6,10.9-9.4,20.9-9.9H375c6.8-0.2,12.3,2.9,16.2,9.2h62
	C457.2,357.7,462.6,354.7,469.5,354.9L469.5,354.9z"
          />
          <path
            fill="#656565"
            d="M337.5,383H336c-2.1,4.7-11.5,7.6-27.9,8.4c-22.2-0.2-34.5-3.5-36.6-9.5c-0.8-1.6-1.2-3.3-1.2-5.3v8.6
	c0.2,2.1,1.2,4.1,2.9,6c4.5,4.3,16.2,6.6,34.7,6.8l7.2-0.6l1.2-0.2h0.4c11.1-1.4,17.5-3.9,19.3-7.6
	C337.1,387.9,337.7,385.5,337.5,383L337.5,383z"
          />
          <path
            fill="#656565"
            d="M336,383.2h15.4v-13.3c0-4.5-1.8-7.4-5.6-8.8h-4.1c-10.1,0.2-17.1,3.3-21.4,8.8l-11.5,17.9h8.2
	C322.1,384.9,328.6,383.4,336,383.2L336,383.2z"
          />
          <path
            fill="#656565"
            d="M345.7,361c3.9,1.4,5.8,4.3,5.7,8.8V383c1,4.7,2.9,6.8,5.8,6.4c1.6-0.2,3.1-2.3,4.7-6.4v-16.2
	c0.2-3.7,2.3-5.7,6-5.8H345.7z M316.9,353.8c1-1,1.6-2.3,2-3.7h-18.1c-19.3,0.4-31,2.3-35.5,5.5c0,0-0.2,0-0.2,0.2l-2,1.8h44.2
	C311.6,357.3,314.9,355.9,316.9,353.8L316.9,353.8z"
          />
          <path
            fill="#656565"
            d="M318.8,349.9c-0.4,1.6-1,2.7-2,3.7h210.7c-0.8-1-1.6-2.3-1.8-3.7L318.8,349.9L318.8,349.9z M403,411.3
	l-2.3,2.5h43.3l-2.3-2.5l-14-9.7h-10.3"
          />
          <path
            fill="#656565"
            d="M400.7,413.8l2.3-2.5l-11.9-8l-6.2,4.3h-9.7l5.3,6.2"
          />
          <path
            fill="#656565"
            d="M391.1,403.3l11.9,8l14.2-9.7h-86.9l-13.6-4.1h-0.4l-1.2,0.2l8,6l35.1,2.1l1,1.8h25.7"
          />
          <path fill="#656565" d="M316.7,397.4l13.6,4.1h184.2l13.4-4.1" />
          <path
            fill="#656565"
            d="M358.2,405.8l-35.1-2.1l-8-6l-7.2,0.6c-18.7-0.2-30.2-2.5-34.7-6.8c-0.8-0.8-1.6-1.8-1.8-2.7v17.5
	L358.2,405.8L358.2,405.8z M485.5,407.6l1-1.8l35.1-2.1l8-6l-1.2-0.2h-0.4l-13.4,4.1h-86.9l14,9.7l12.1-8l6,4.3L485.5,407.6"
          />
          <path
            fill="#656565"
            d="M464.2,413.8l5.3-6.2h-9.7l-6-4.3l-12.1,8l2.3,2.5 M336,383.2c-7.4,0.2-13.6,1.8-18.9,4.7h-19.7
	c1.4-2.9,2.1-6,2.5-9.4l0.2-2.1c0-1.6-0.2-3.1-0.8-4.7c-0.8-2.1-2-3.9-3.7-5.6c-2.9-2.9-6.4-4.3-10.5-4.3s-7.6,1.4-10.5,4.3
	c-2.9,2.9-4.3,6.2-4.3,10.1v0.6c0,2,0.4,3.7,1.2,5.3c2.1,6,14.4,9.4,36.6,9.5C324.5,390.6,333.8,387.9,336,383.2L336,383.2z
	 M276.7,379.7c-0.4-1-0.4-2-0.4-2.9c0-2.5,1-4.7,2.7-6.6c1.8-1.8,4.1-2.7,6.6-2.7c2.1,0,4.1,0.8,5.8,2.1l0.8,0.6
	c1.8,1.8,2.7,4.1,2.7,6.6c0,2.5-1,4.7-2.7,6.4s-3.9,2.7-6.4,2.7c-2.5,0-4.7-1-6.6-2.7C277.9,382.2,277.1,381.1,276.7,379.7
	L276.7,379.7z"
          />
          <path
            fill="#656565"
            d="M276.1,376.8c0,1,0.2,2,0.4,2.9c0.4,1.4,1.2,2.5,2.1,3.5c1.8,1.8,4.1,2.7,6.6,2.7c2.5,0,4.7-1,6.4-2.7
	s2.7-3.9,2.7-6.4c0-2.5-1-4.7-2.7-6.6l-0.8-0.6c-1.6-1.4-3.7-2.1-5.8-2.1c-2.5,0-4.7,1-6.6,2.7C277.1,372.1,276.1,374.2,276.1,376.8
	L276.1,376.8z M279.7,381.4c-1.2-1.4-1.6-2.9-1.6-4.7c0-1.9,0.8-3.7,2.1-5.3c1.6-1.6,3.1-2.1,5.3-2.1c2,0,3.7,0.8,5.3,2.1l1,1.4
	c0.8,1.2,1.2,2.5,1.2,3.9c0,2-0.8,3.7-2.1,5.3c-1.4,1.4-3.1,2.1-5.3,2.1c-2,0-3.7-0.8-5.3-2.1L279.7,381.4L279.7,381.4z"
          />
          <path
            fill="#656565"
            d="M278.1,376.8c0,1.8,0.6,3.3,1.6,4.7l0.6,0.6c1.6,1.4,3.1,2.1,5.3,2.1c2,0,3.7-0.8,5.3-2.1
	c1.6-1.6,2.1-3.1,2.1-5.3c0-1.6-0.4-2.7-1.2-3.9l-1-1.4c-1.4-1.6-3.1-2.1-5.3-2.1c-2,0-3.7,0.8-5.3,2.1
	C278.9,373.1,278.1,374.8,278.1,376.8L278.1,376.8z M527.7,353.8c2,2.3,5.3,3.5,9.9,3.7h44.2l-2-1.8l-0.2-0.2
	c-4.5-3.1-16.4-5.1-35.5-5.5h-18.1C526.2,351.4,526.8,352.6,527.7,353.8L527.7,353.8z M544.7,376.2v0.4c0,0.6,0,1.2,0.2,1.8
	c0.4,3.5,1.2,6.6,2.5,9.4h-19.7c-5.3-2.9-11.5-4.5-18.9-4.7c2.1,4.7,11.5,7.6,27.9,8.4c22.2-0.2,34.3-3.5,36.6-9.5
	c0.8-1.6,1.2-3.3,1.2-5.3V376c0-3.9-1.6-7.2-4.3-10.1c-2.9-2.9-6.4-4.3-10.5-4.3s-7.6,1.4-10.3,4.3c-1.8,1.8-2.9,3.5-3.7,5.6
	C544.9,373.1,544.7,374.6,544.7,376.2L544.7,376.2z M552.7,370.1l0.8-0.6c1.6-1.4,3.7-2.1,5.8-2.1c2.5,0,4.7,1,6.6,2.7
	c1.8,1.8,2.7,4.1,2.7,6.6c0,1-0.2,2-0.4,2.9c-0.4,1.4-1.2,2.5-2.1,3.5c-1.8,1.8-4.1,2.7-6.6,2.7s-4.7-1-6.6-2.7
	c-1.8-1.8-2.7-3.9-2.7-6.4C550,374.2,550.7,372.1,552.7,370.1L552.7,370.1z"
          />
          <path
            fill="#656565"
            d="M553.3,369.6l-0.8,0.6c-1.8,1.8-2.7,4.1-2.7,6.6c0,2.5,1,4.7,2.7,6.4s3.9,2.7,6.6,2.7c2.5,0,4.7-1,6.6-2.7
	c1-1,1.8-2.3,2.3-3.5c0.4-1,0.4-2,0.4-2.9c0-2.5-1-4.7-2.7-6.6c-1.8-1.8-4.1-2.7-6.6-2.7C557,367.6,555,368.2,553.3,369.6
	L553.3,369.6z M552.9,372.9l1-1.4c1.6-1.6,3.1-2.1,5.3-2.1c1.9,0,3.7,0.8,5.3,2.1c1.4,1.6,2.1,3.1,2.1,5.3c0,1.8-0.6,3.3-1.6,4.7
	l-0.6,0.6c-1.6,1.4-3.1,2.1-5.3,2.1c-2,0-3.7-0.8-5.3-2.1c-1.6-1.6-2.1-3.1-2.1-5.3C551.7,375.4,552.1,374,552.9,372.9L552.9,372.9z
	"
          />
          <path
            fill="#656565"
            d="M553.9,371.5l-1,1.4c-0.8,1.2-1.2,2.5-1.2,3.9c0,2,0.8,3.7,2.1,5.3c1.6,1.4,3.1,2.1,5.3,2.1
	c1.9,0,3.7-0.8,5.3-2.1l0.6-0.6c1.2-1.4,1.6-2.9,1.6-4.7c0-1.9-0.8-3.7-2.1-5.3c-1.6-1.6-3.1-2.1-5.3-2.1
	C557.2,369.4,555.4,370.1,553.9,371.5L553.9,371.5z M574.1,385.1c-0.2,2.1-1.2,4.1-2.9,6c0.8-0.8,1.6-1.8,1.8-2.7v40.7
	c-0.4,3.5,0.2,6.2,1.6,8.2c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2v-71.3
	c-1.6,10.7-4.5,22-8.4,33.7l-21.2-7.8c-0.4,0.4-1.4,0.8-2.9,1l-7.8,0.2h-13.1V385.1L574.1,385.1z"
          />
          <path
            fill="#656565"
            d="M573.1,388.5c-0.4,1-1,2-1.8,2.7c-4.5,4.3-16.2,6.6-34.7,6.8l-7.2-0.6l-8,6l-35.1,2.1l86.7,0.4L573.1,388.5
	L573.1,388.5L573.1,388.5z M273.2,391.2c-1.6-2-2.5-3.9-2.9-6h-13.1l-7.8-0.2c-1.4-0.2-2.3-0.6-2.9-1l-21.2,7.8
	c-4.1-11.7-6.8-23-8.4-33.7v71.1c-0.4,3.5,0.2,6.2,1.6,8.2c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2
	v-40.7C271.9,389.4,272.4,390.4,273.2,391.2L273.2,391.2z"
          />
          <path
            fill="#EBEBEB"
            d="M570.4,187.7c0.6,5.3,2.7,10.3,6.6,15.2l7,9.7l2.1,2.7c0.8,1,1.8,1.8,2.7,2.5l2.7,1.9
	c-8.2-19.1-16.4-34.9-24.6-47.2L570.4,187.7L570.4,187.7z"
          />
          <path
            fill="#EBEBEB"
            d="M565.5,155c-1,0.8-0.4,6.6,1.8,17.7c8.2,12.3,16.4,27.9,24.6,47.2c1.8,1,3.5,1.8,5.3,2.3
	c-6.8-19.1-14-36.6-22-52.4C571.8,163.8,568.7,158.9,565.5,155L565.5,155z M529.9,243.7c1.8,0.2,3.3,0.8,4.9,1.4
	c2,0.8,3.7,1.8,5.3,3.1c2.9,1.9,4.3,4.7,4.5,8l-1.2,14.4c-0.6,3.9-3.1,6.6-7.4,8.2l-1.6,0.4l13.3-0.8h-0.8c1.4-0.6,2.1-2.1,2.5-4.7
	l1.4-18.1c0.2-5.3-0.6-9-2.3-11.1h-0.2l-23.6-1.6l0.4,0.4L529.9,243.7L529.9,243.7z"
          />
          <path
            fill="#EBEBEB"
            d="M534.8,245c-1.6-0.6-3.1-1-4.9-1.4l-2.3,35.5l4.5,0.2L534.8,245L534.8,245z"
          />
          <path
            fill="#EBEBEB"
            d="M525.2,243.5l0.2,0.2c2,2.1,2.9,6,2.7,11.3l-1.4,18.9c-0.2,2.7-1.2,4.3-2.5,4.9l3.3,0.4l2.3-35.5
	L525.2,243.5L525.2,243.5z"
          />
          <path
            fill="#EBEBEB"
            d="M525.4,243.7l-0.2-0.2H525 M540.2,248.1c-1.6-1.4-3.3-2.3-5.3-3.1l-2.5,34.5l2.1-0.2h0.2l1.6-0.4
	c4.3-1.6,6.6-4.5,7.4-8.2l1.2-14.4C544.5,252.8,542.9,250.3,540.2,248.1L540.2,248.1z M552.7,245l-4.1-0.4c1.8,1.9,2.5,5.6,2.3,11.1
	l-1.4,18.1c-0.2,2.5-1.2,4.1-2.5,4.7h0.8l2.7,0.4L552.7,245L552.7,245z"
          />
          <path
            fill="#EBEBEB"
            d="M554.4,245.2l-2-0.2l-2.3,33.7l4.5,0.2l2.5-32.9C556.6,245.8,555.6,245.4,554.4,245.2L554.4,245.2z
	 M562.8,249.1c-1.6-1.4-3.5-2.3-5.3-3.1l-2.5,32.9h1.6l2.3-0.4c4.3-1.6,6.6-4.3,7.4-7.8l1.2-13.6
	C567.1,253.6,565.7,251.1,562.8,249.1L562.8,249.1z"
          />
          <path
            fill="#EBEBEB"
            d="M557.6,246c2,0.8,3.7,1.8,5.3,3.1c2.9,1.9,4.3,4.5,4.5,7.6l-1.2,13.6c-0.6,3.7-3.1,6.2-7.4,7.8l-2.3,0.4
	l14,0.8h-1c1.4-0.6,2.1-2,2.5-4.5l1.4-17.5c0.2-5.3-0.6-8.8-2.3-10.7h-0.6l-15.8-1.6C555.6,245.4,556.6,245.8,557.6,246L557.6,246z
	 M613.5,218.7c0,2.1,0.8,4.1,2.1,5.5c1.8,2.5,3.5,3.7,5.7,3.9h0.6l23.6-2.9c1.8-0.6,2.9-1.8,3.3-3.3c0.2-0.8,0.2-1.6,0.2-2.3
	c0-1.8-0.6-4.1-1.6-6.6c-1.6-4.9-4.9-7.2-9.7-7.2l-17.5-1.4c-3.7,0-5.7,2.3-6,6.8L613.5,218.7L613.5,218.7z"
          />
          <path
            fill="#EBEBEB"
            d="M575.1,247l-4.1-0.2c1.8,1.9,2.5,5.7,2.3,10.7L572,275c-0.2,2.5-1.2,3.9-2.5,4.5h1l2.3,0.2L575.1,247
	L575.1,247z"
          />
          <path
            fill="#EBEBEB"
            d="M580,248.1c-1.6-0.6-3.1-1-4.9-1.2l-2.3,32.9l4.5,0.2L580,248.1L580,248.1z"
          />
          <path
            fill="#EBEBEB"
            d="M585.2,251.1c-1.6-1.4-3.5-2.3-5.3-2.9l-2.5,32l2.5-0.2l1.4-0.4c4.3-1.6,6.6-4.1,7.2-7.6l1.2-13.3
	C589.7,255.4,588.2,252.8,585.2,251.1L585.2,251.1z M540.2,206.6c3.7-14.8,6.2-28.8,7.6-42.3c-22.4-6.4-62-10.1-118.5-10.7H410
	c-53.8,0.8-91.6,4.5-113.4,10.7c1.4,13.4,3.9,27.7,7.6,42.3c5.3,3.5,23.6,4.5,55.1,2.5l38-1.6l31.8-0.4l55.5,1.9
	C516.6,211.1,535,210.1,540.2,206.6L540.2,206.6z M319,243.5l0.2-0.4l-22.8,1.6h-0.2c-1.8,1.9-2.5,5.6-2.3,11.1l1.4,18.1
	c0.2,2.5,1.2,4.1,2.5,4.7l13.2,0.8l-1.2-0.2l-1.4-0.4c-4.3-1.6-6.6-4.5-7.2-8.2l-1.2-14.4c0-3.3,1.6-6,4.5-8
	c1.6-1.4,3.5-2.3,5.3-3.1c1.6-0.6,3.1-1,4.9-1.4L319,243.5L319,243.5z"
          />
          <path
            fill="#EBEBEB"
            d="M316.7,255.2c-0.2-5.6,0.6-9.6,2.3-11.5l-4.1,0.2l2.3,35.5l3.3-0.4c-1.4-0.6-2.1-2.1-2.5-4.9L316.7,255.2
	L316.7,255.2z M206.8,205.9c-4.9-0.2-8.2,2.1-9.7,7.2c-1,2.5-1.6,4.9-1.6,6.6c0,1,0,1.8,0.4,2.3c0.4,1.6,1.6,2.7,3.3,3.3l23.6,2.9
	h0.8c1.9-0.2,3.9-1.6,5.7-3.9c1.4-1.6,2.1-3.3,2.1-5.5l-1-7.4c-0.2-4.5-2.1-6.8-5.8-6.8L206.8,205.9L206.8,205.9z M279.3,155
	c-3.3,3.9-6.4,8.8-9.5,14.6c-7.8,15.8-15.2,33.3-22,52.4c1.8-0.6,3.5-1.4,5.3-2.3c8.2-19.1,16.4-34.9,24.6-47.2
	C279.7,161.6,280.2,155.8,279.3,155L279.3,155z"
          />
          <path
            fill="#EBEBEB"
            d="M274.2,187.7l3.1-15c-8.2,12.3-16.4,27.9-24.6,47.2l2.7-1.9l2.7-2.5l2.1-2.7l7-9.7
	C271.5,197.9,273.6,192.8,274.2,187.7L274.2,187.7z M259.4,251.1c-2.9,1.8-4.3,4.3-4.5,7.4l1.2,13.3c0.6,3.5,3.1,6,7.4,7.6l1.2,0.4
	l2.5,0.2l-2.5-32C262.7,248.9,260.9,249.7,259.4,251.1L259.4,251.1z M273.8,246.8l-4.1,0.2l2.3,32.9l3.3-0.4c-1.4-0.6-2.1-2-2.5-4.5
	l-1.4-17.5C271.1,252.2,271.9,248.7,273.8,246.8L273.8,246.8z"
          />
          <path
            fill="#EBEBEB"
            d="M274.4,246.8h-0.6c-1.8,1.9-2.5,5.7-2.3,10.7l1.4,17.5c0.4,2.5,1.2,3.9,2.5,4.5l12.5-0.6h-0.6l-1.2-0.4
	c-4.3-1.6-6.6-4.3-7.4-7.8l-1.2-13.6c0-3.3,1.6-5.8,4.5-7.6c1.6-1.4,3.3-2.3,5.3-3.1c1-0.4,1.9-0.8,2.9-1L274.4,246.8L274.4,246.8z"
          />
          <path
            fill="#EBEBEB"
            d="M281.8,249.1c-2.9,1.9-4.3,4.5-4.5,7.6l1.2,13.6c0.6,3.7,3.1,6.2,7.4,7.8l1.2,0.4h0.6l2,0.2l-2.5-32.7
	C285.1,246.8,283.4,247.8,281.8,249.1L281.8,249.1z M290.2,245.2c-1,0.2-2.1,0.6-2.9,1l2.5,32.9l4.5-0.2l-2.1-33.7L290.2,245.2
	L290.2,245.2z M293.9,255.7c-0.2-5.3,0.6-9,2.3-11.1l-4.1,0.4l2.1,33.7l3.3-0.4c-1.4-0.6-2.1-2.1-2.5-4.7L293.9,255.7L293.9,255.7z
	 M309.9,245c-2,0.8-3.7,1.8-5.3,3.1c-2.9,1.9-4.3,4.7-4.5,8l1.2,14.4c0.6,3.9,3.1,6.6,7.2,8.2l1.4,0.4l1,0.2l1.6,0.2L309.9,245
	L309.9,245z"
          />
          <path
            fill="#EBEBEB"
            d="M314.7,243.7c-1.8,0.2-3.3,0.8-4.9,1.4l2.5,34.5l4.5-0.2L314.7,243.7L314.7,243.7z M269.5,247
	c-1.8,0.2-3.3,0.6-4.9,1.2l2.5,32l4.7-0.2L269.5,247L269.5,247z"
          />
          <path
            fill="none"
            d="M425.6,261.6h0.4 M426,261.6v-3.3h4.9 M430.9,258.3v-0.4"
          />
          <path fill="none" d="M426,261.6h0.8v-2.9h4.1v-0.4" />
          <path
            fill="none"
            d="M412.8,258.5c0-0.2,0.2-0.2,0.4-0.2h12.5v3.5H425v-3.1h-11.7v2.1h6v-0.6h-3.1v-0.4h3.7v1l-0.2,0.2h-7l-0.2-0.4
	L412.8,258.5L412.8,258.5z M413,257.5h17.9v0.4h-17.7l-0.6,0.2c-0.2,0-0.2,0.2-0.2,0.4v2.3c0,0.2,0,0.4,0.2,0.4l0.6,0.2h6.6l0.4-0.2
	l0.2-0.2v-1.2c0-0.2-0.2-0.4-0.6-0.4h-3.7v-0.4h4.1c0.4,0,0.6,0.2,0.6,0.4v1.4l-0.4,0.6l-0.8,0.2h-7.2c-0.4,0-0.8-0.2-1-0.4v-3.1
	c0-0.2,0.4-0.4,0.8-0.6L413,257.5L413,257.5z"
          />
          <path
            fill="none"
            d="M411.4,255.2c-1.6,1.6-2.3,3.3-2.3,5.3c0,2.5,1.2,4.7,3.7,6.6c2.3,1.8,5.3,2.7,8.8,2.7c3.5,0,6.4-1,8.8-2.7
	c2.3-1.8,3.7-4.1,3.7-6.6c0-2-0.8-3.7-2.3-5.3l-1.4-1.2c-2.3-1.8-5.5-2.7-8.8-2.7c-3.5,0-6.4,1-8.8,2.7L411.4,255.2L411.4,255.2z
	 M591.9,219.7l-2.7-1.9c-1-0.8-1.9-1.8-2.7-2.5l-2.1-2.7l-7-9.7c-3.9-4.9-6-9.9-6.4-15.2l-3.1-15 M561.1,150.1
	c-1.6-1.6-3.1-2.7-4.7-3.7c-1.4-0.8-2.5-1.2-3.3-1.2c-0.8,0-1.2,0.4-1.4,1.2 M551.7,146.4l-2.5-0.8 M565.5,155
	c-1,0.8-0.4,6.6,1.8,17.7 M564.4,153.6l0.8-0.2l0.6,0.4c0.2,0.2,0.2,0.4,0.2,0.6l-0.4,0.6 M564.4,153.6l-3.3-3.5 M547.8,164.3
	c0.8-6.4,1.2-12.7,1.4-18.7 M550,209.2c1.6,0.8,2.9,1.8,4.5,2.9l1.9,1.9c3.7,4.1,7.4,7.8,11.5,11.3 M534.8,245
	c-1.6-0.6-3.1-1-4.9-1.4 M524.8,243.1l-5.5-0.2 M519.4,242.9c1.4-3.1,2.9-5.5,5.3-7 M525.2,243.5l-0.4-0.4 M525.4,243.7l-0.2-0.2
	 M527.5,279.3l-3.3-0.4c1.4-0.6,2.1-2.1,2.5-4.9l1.4-18.9c0.2-5.5-0.6-9.2-2.7-11.3 M525.4,243.7l-0.4-0.2h0.2 M534.4,279.3l1.6-0.4
	c4.3-1.6,6.6-4.5,7.4-8.2l1.2-14.4c0-3.3-1.6-6-4.3-8c-1.6-1.4-3.3-2.3-5.3-3.1 M529.9,243.7l-4.7-0.2 M512.7,282.4
	c2.5-1.4,3.7-3.7,3.5-7.4l1.6-26.7c0.4-1.9,1-3.9,1.6-5.5 M548.2,244.6h0.2 M548.4,244.6h-0.2 M552.7,245l-4.3-0.4 M554.4,245.2
	l-1.8-0.2 M557.6,246c2,0.8,3.7,1.8,5.3,3.1c2.9,1.9,4.3,4.5,4.5,7.6l-1.2,13.6c-0.6,3.7-3.1,6.2-7.4,7.8l-2.3,0.4 M554.4,245.2
	c1,0.2,1.9,0.6,2.9,1 M548.4,244.6c1.8,1.9,2.5,5.6,2.3,11.1l-1.4,18.1c-0.2,2.5-1.2,4.1-2.5,4.7h0.8 M550,209.2
	c-3.1-1.6-6.2-2.5-9.7-2.5 M524.8,243.1l23.4,1.6"
          />
          <path
            fill="none"
            d="M601.4,225.5l-2-0.2c-4.9-1.9-8.4-3.9-10.5-5.5l-2.1-1.6l-1.9-1.9l-9.4-12.7c-4.1-5.3-6.4-10.5-6.8-16
	c-4.9-21.4-6.2-32.9-4.3-34.5 M596.9,222l4.5,3.5 M613.9,224.9l-1.8,0.2l-6.4,1.6 M613.5,218.7l1-7.4c0.2-4.5,2.1-6.8,6-6.8
	l17.5,1.4c4.9-0.2,8.2,2.1,9.7,7.2c1,2.5,1.6,4.9,1.6,6.6c0,1,0,1.8-0.2,2.3c-0.6,1.6-1.6,2.7-3.3,3.3l-23.6,2.9h-0.6
	c-2-0.2-3.9-1.6-5.7-3.9C614.3,222.6,613.5,220.9,613.5,218.7L613.5,218.7z M613.1,231.6c-1.2-1.4-2.3-2.3-3.3-3.1
	c-1.4-1.2-2.7-1.8-3.9-1.8"
          />
          <path
            fill="none"
            d="M620.9,229.4l26.3-3.1c1.9-0.6,3.1-1.9,3.7-3.7c0.2-0.8,0.4-1.8,0.4-2.7c0-1.9-0.6-4.5-1.6-7.4
	c-1.8-5.5-5.5-8.2-10.9-8l-19.5-1.6c-4.1,0-6.4,2.5-6.6,7.6l-1,8.2c0,2.5,0.8,4.5,2.5,6 M605.7,226.7c-1.4-0.6-2.7-1-4.3-1
	 M568.5,226.9l0.6,1.8v10.5 M567.9,225.7l0.6,1.2 M569.1,239.2c10.9,1.6,17.7,4.9,20.3,9.7c4.3,6,6,13.1,5.3,21 M571,246.8h-0.8
	l-15.8-1.6 M575.1,247l-4.1-0.2 M580,248.1c-1.6-0.6-3.1-1-4.9-1.2"
          />
          <path
            fill="none"
            d="M577.4,279.9l2.5-0.2l1.4-0.4c4.3-1.6,6.6-4.1,7.2-7.6l1.2-13.3c0-3.1-1.6-5.6-4.5-7.4
	c-1.6-1.4-3.5-2.3-5.3-2.9 M571,246.8c1.8,1.9,2.5,5.7,2.3,10.7L572,275c-0.2,2.5-1.2,3.9-2.5,4.5h1 M633.4,304.7l2.1-20.9
	c0.2-9.9-0.6-16-2.3-18.1l-17.7-30.8c-0.6-1-1.4-1.9-2.1-2.9l-0.4-0.4 M591.9,219.7c1.8,1,3.5,1.8,5.3,2.3 M602.2,270.7
	c1-8.6-1.6-17.5-7.8-27.1c-5.5-8.6-14.2-14.2-25.9-17 M567.3,172.7c8.2,12.3,16.4,27.9,24.6,47.2 M540.2,206.6
	c3.7-14.8,6.2-28.8,7.6-42.3 M551.7,146.4c3.1,7.8,2.5,28.8-2,62.9 M524.4,235.9c15.8,0.8,30.6,1.9,44.4,3.5"
          />
          <path
            fill="none"
            d="M596.9,222c-6.8-19.1-14-36.6-22-52.4c-3.1-5.8-6.2-10.7-9.5-14.6 M567.9,225.7c-4.9-35.5-7.2-60.6-6.6-75.4
	 M613.1,231.6l7-1.9l0.8-0.2 M620.9,229.4c-2.5,0-4.9-1.4-7-4.5 M549.2,145.6c-15.2-4.7-33.9-7.6-56.1-9.2l-13.1,0.8l-30.8-1.4
	l-20.5-0.2l-33.9,0.4l-30,1.4l-13.1-0.8c-22.2,1.6-40.9,4.5-56.1,9.2 M397,153.4h13.3 M325.3,242.9l-6,0.2 M320.2,235.9
	c2.1,1.6,3.9,3.9,5.3,7 M325.3,242.9c0.6,1.6,1.2,3.5,1.6,5.5l1.6,26.7c-0.4,3.5,0.8,6,3.3,7.4 M319,243.5l0.2-0.4 M317.1,279.3
	l3.3-0.4c-1.4-0.6-2.1-2.1-2.5-4.9l-1.4-18.9c-0.2-5.6,0.6-9.6,2.3-11.5 M435.2,261.2v-0.8c0-2.7-1.4-5.3-4.1-7.2
	c-2.7-1.9-5.9-2.9-9.7-2.9c-3.7,0-7,1-9.5,2.9c-2.7,1.9-3.9,4.5-3.9,7.2v0.8 M519.4,242.9c-24.2-1.2-51.4-1.8-81.7-1.6h-30.4
	c-30.4,0-57.7,0.4-81.9,1.6 M524.4,235.9c-28.5-1.6-60.2-2.3-95.1-2.1h-7.2c-7.8,0-15.6,0-23.2,0.2c-28.6,0-55,0.6-78.9,1.9
	 M304.4,206.6c5.3,3.5,23.6,4.5,55.2,2.5l38-1.6l31.8-0.4l55.5,1.9c31.6,1.8,50.1,1,55.1-2.5 M410.2,153.6h19.3
	c56.5,0.6,95.9,4.3,118.5,10.7 M502.6,323.8l-16.4-23c-2.9-3.5-7-5.3-12.7-5.5H371.1c-5.7,0.2-9.7,2.1-12.7,5.5l-16.4,23"
          />
          <path
            fill="none"
            d="M450.6,298.6h17.9c7-0.8,12.9,1.4,17.3,6.4l17,18.7 M435.2,261.2v0.8c0,2.9-1.4,5.3-4.1,7.2
	c-2.7,2-5.9,2.9-9.7,2.9c-3.7,0-7-1-9.5-2.9c-2.7-2-3.9-4.5-3.9-7.2v-0.8c0.2,2.5,1.6,4.7,3.9,6.4c2.7,2,5.8,2.9,9.5,2.9
	c3.7,0,7-1,9.7-2.9C433.6,265.9,435,263.7,435.2,261.2L435.2,261.2z"
          />
          <path
            fill="none"
            d="M394.2,298.6c-2.3,0-4.1,1.8-5.3,5.3v22.6c0,2.3,1.6,3.3,4.5,2.7h57.9c2.9,0.6,4.5-0.4,4.5-2.7v-22.6
	c-1-3.5-2.7-5.3-5.3-5.3 M476.7,361h-5.1c-7.6,0-13.1,2.3-16.4,7l-5.3,5.8c-2.5,2-7.4,2.9-14.6,2.7h-25.5
	c-7.4,0.2-12.3-0.6-14.6-2.7L390,368c-3.3-4.7-9-7-16.4-7h-5.1"
          />
          <path
            fill="none"
            d="M391.3,364.1l5.3,6.8c1.6,2,5.5,2.9,11.7,3.3h28.1c6.2-0.2,10.1-1.4,11.7-3.3l5.3-6.8 M361.9,383h14.6
	c7.4,0.4,12.9,1.6,16.8,3.5c4.9,1.4,11.7,2,20.3,2.1h15.8c9.4,0,16.6-0.6,21.6-2.1c3.7-1.9,9.4-3.1,16.8-3.5h14.6 M482.7,383v-16.2
	c-0.2-3.7-2.3-5.7-6-5.8 M535.9,387.9l-11.5-17.9c-4.3-5.6-11.5-8.6-21.4-8.8h-4.1 M493.2,383h13.8 M498.9,361
	c-3.9,1.4-5.7,4.3-5.7,8.8V383 M508.6,383.2l-1.6-0.2 M498.9,361h-22.2"
          />
          <path
            fill="none"
            d="M453.3,364.1c3.9-6.2,9.4-9.4,16.2-9.2h36.1c9.9,0.6,17,3.9,20.9,9.9l14.6,22.8 M342,323.8l17-18.7
	c4.5-5.1,10.3-7.4,17.3-6.4h17.9 M303.4,387.9l14.6-22.8c3.9-6,10.9-9.4,20.9-9.9H375c6.8-0.2,12.3,2.9,16.2,9.2 M336,383.2l1.6-0.2
	"
          />
          <path
            fill="none"
            d="M345.7,361h-4.1c-10.1,0.2-17.1,3.3-21.4,8.8l-11.5,17.9 M351.4,383v-13.3c0-4.5-1.8-7.4-5.6-8.8 M368.1,361
	c-3.9,0.2-5.8,2.1-6,5.8V383 M337.5,383h13.8 M318.8,349.9c-0.4,1.6-1,2.7-2,3.7 M368.1,361h-22.4 M400.7,413.8l2.3-2.5 M403,411.3
	l-11.9-8l-6.2,4.3h-9.7 M375.1,407.6l5.3,6.2 M375.1,407.6l5.3,6.2 M380.4,413.8h20.3 M358.2,405.8l1,1.8h16 M316.7,397.4
	c11.1-1.4,17.5-3.9,19.3-7.6c1.2-2,1.8-4.1,1.6-6.6 M316.7,397.4h-0.4l-1.2,0.2 M417.2,401.5h-86.9l-13.6-4.1"
          />
          <path
            fill="none"
            d="M358.2,405.8l-35.1-2.1l-8-6 M469.5,407.6h16l1-1.8 M443.9,413.8h20.3l5.3-6.2 M469.5,407.6h-9.7l-6-4.3
	l-12.1,8 M441.6,411.3l2.3,2.5 M427.6,401.5h-10.3 M441.6,411.3l-14-9.7 M527.9,397.4l-13.4,4.1h-86.9 M400.7,413.8h43.3 M351.4,383
	c1,4.7,2.9,6.8,5.8,6.4c1.6-0.2,3.1-2.3,4.7-6.4 M507.1,383c0,2.5,0.6,4.7,1.6,6.6c1.8,3.9,8.2,6.4,19.3,7.6 M482.7,383
	c1.6,4.3,3.1,6.4,4.7,6.4c2.9,0.2,4.9-2,5.8-6.4 M403,411.3l14.2-9.7 M331.9,282.4h180.9 M336,383.2c-7.4,0.2-13.6,1.8-18.9,4.7
	h-8.2 M391.3,364.1h62 M394.2,298.6h56.3 M602.2,270.7c-0.2,1.8-0.6,3.7-1.2,5.5c-1.6,9.4-8,14.8-19.5,16.2
	c-31.4,1.2-64.9,1.4-100.9,0.8H363.8c-36.1,0.6-69.6,0.4-101-0.8c-11.3-1.4-17.7-6.8-19.5-16.2c-0.6-1.8-0.8-3.5-1.2-5.5"
          />
          <path
            fill="none"
            d="M633.4,304.7l-13.4-2.1c-4.5,0-7.2-1.8-8.2-5.3c-2.3-11.5-5.5-20.3-9.5-26.5 M619.3,352l-6.2-0.8
	c-2.7-1.4-4.1-3.7-3.9-7l10.9,2.5L619.3,352L619.3,352z M627.9,357.9l5.5-53.2 M594.6,270l7.6,0.8 M572.8,279.9h4.7 M570.4,279.5
	l2.3,0.4 M594.6,270c-0.6,7.8-6,11.9-16.6,12.3h-65.3 M554.8,278.9h1.6 M550.3,278.7l4.5,0.2 M547.6,278.5l2.7,0.2"
          />
          <path
            fill="none"
            d="M532.2,279.5l2-0.2h0.2 M527.5,279.3l4.7,0.2 M547.6,278.5l-13.3,0.8 M581.7,357.5h-44.2
	c-4.7-0.2-8-1.6-9.9-3.7 M553.9,371.5c1.6-1.6,3.1-2.1,5.3-2.1c1.9,0,3.7,0.8,5.3,2.1c1.4,1.6,2.1,3.1,2.1,5.3
	c0,1.8-0.6,3.3-1.6,4.7l-0.6,0.6c-1.6,1.4-3.1,2.1-5.3,2.1c-2,0-3.7-0.8-5.3-2.1c-1.6-1.6-2.1-3.1-2.1-5.3c0-1.6,0.4-2.7,1.2-3.9
	L553.9,371.5L553.9,371.5z"
          />
          <path
            fill="none"
            d="M574.3,376.6V376c0-3.9-1.6-7.2-4.3-10.1c-2.9-2.9-6.4-4.3-10.5-4.3c-4.1,0-7.6,1.4-10.3,4.3
	c-1.8,1.8-2.9,3.5-3.7,5.6c-0.4,1.4-0.8,2.9-0.8,4.7v0.4c0,0.6,0,1.2,0.2,1.8c0.4,3.5,1.2,6.6,2.5,9.4h-6.2 M527.7,353.8
	c-0.8-1-1.6-2.3-1.8-3.7 M525.8,349.9h18.1c19.3,0.4,31,2.3,35.5,5.5l0.2,0.2l1.9,1.8"
          />
          <path
            fill="none"
            d="M247.7,378.5c2.9-8.4,6.6-15.8,11.3-22c1.2-1.4,2.3-2.3,3.7-3.5l0.6-0.4c4.7-3.7,17.1-5.6,37.6-6.2h243.4
	c20.3,0.4,32.9,2.5,37.6,6.2l0.6,0.4c1.4,1,2.5,2.1,3.7,3.5h0.2c4.7,6.2,8.6,13.6,11.3,22"
          />
          <path
            fill="none"
            d="M595.4,384.9l-0.8-2.3c-2.7-9.2-6.6-17.1-11.7-23.8l-1.2-1.4 M574.3,376.6v8.6 M508.6,383.2
	c2.1,4.7,11.5,7.6,27.9,8.4c22.2-0.2,34.3-3.5,36.6-9.5c0.8-1.6,1.2-3.3,1.2-5.3 M596.9,378.5l1.2,4.1c0.2,0.4,0.2,0.8,0,1.2
	 M556.4,278.9l14,0.6 M574.1,385.1c-0.2,2.1-1.2,4.1-2.9,6"
          />
          <path
            fill="none"
            d="M595.4,384.9l-7.8,0.2h-13.4 M573.1,406.2v-17.5c-0.4,1-1,2-1.8,2.7 M573.1,406.2v23c-0.4,3.5,0.2,6.2,1.6,8.2
	c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2v-71.3 M541.2,387.9h-5.3 M535.9,387.9h-8.2
	c-5.3-2.9-11.5-4.5-18.9-4.7 M529.5,397.6l-1.2-0.2h-0.4 M571.4,391.2c-4.5,4.3-16.2,6.6-34.7,6.8l-7.2-0.6 M529.5,397.6l-8,6
	l-35.1,2.1 M342,323.8h-86.7c-17.7,2.1-28.6,8.8-32.5,19.7c15.2-3.5,32.4-5.1,51.5-4.7h296.2c19.1-0.4,36.2,1.2,51.5,4.7
	c-4.1-10.9-14.8-17.5-32.5-19.7h-86.5 M598.3,383.8c-0.4,0.4-1.4,0.8-2.9,1 M619.5,391.6c-5.8-5.5-13.4-9.7-22.4-13.1 M627.9,357.9
	c-1.6,10.7-4.5,22-8.4,33.7 M619.5,391.6l-21.2-7.8 M568.5,226.9c-40.9-4.9-89.6-6.8-146.2-6.2c-56.5-0.6-105.2,1.4-146,6.2
	 M529.9,243.7l-2.3,35.7 M554.8,278.9l2.7-32.9 M486.4,405.8l86.7,0.4 M527.9,397.4H316.7 M316.9,353.8h210.9 M525.8,349.9h-207
	 M532.2,279.5l2.5-34.5 M572.8,279.9l2.3-32.9 M577.4,279.9l2.5-31.8 M550.3,278.7l2.3-33.7 M230.7,224.9c1.6-1.6,2.5-3.7,2.5-6
	l-1-8.2c-0.2-5.1-2.3-7.6-6.6-7.6l-19.5,1.6c-5.5-0.2-9,2.3-10.9,8c-1.2,2.9-1.6,5.3-1.8,7.4c0,1,0,1.9,0.4,2.7
	c0.6,1.8,1.8,3.1,3.7,3.7l26.3,3.1"
          />
          <path
            fill="none"
            d="M197,212.9c-1,2.5-1.6,4.9-1.6,6.6c0,1,0,1.8,0.4,2.3c0.4,1.6,1.6,2.7,3.3,3.3l23.6,2.9h0.8
	c1.9-0.2,3.9-1.6,5.6-3.9c1.4-1.6,2.1-3.3,2.1-5.5l-1-7.4c-0.2-4.5-2.1-6.8-5.8-6.8l-17.5,1.4C201.9,205.7,198.6,208,197,212.9
	L197,212.9z M292.9,146.4c-0.2-0.8-0.6-1.2-1.4-1.2s-2,0.4-3.3,1.2c-1.6,1-3.1,2.3-4.7,3.7 M295.4,145.6l-2.5,0.8 M295.4,145.6
	c0.2,6,0.6,12.3,1.4,18.7 M280.2,153.6l-0.8-0.2l-0.6,0.4c-0.2,0.2-0.2,0.4-0.2,0.6s0.2,0.4,0.4,0.6 M283.6,150.1
	c-1,1-2.1,2.1-3.1,3.5 M277.3,172.7c2.1-11.1,2.9-17,2-17.7 M252.8,219.7l2.7-1.9l2.7-2.5l2.1-2.7l7-9.7c3.9-4.9,6-9.9,6.6-15.2
	l3.1-15 M279.1,155c-3.1,3.9-6.4,8.8-9.5,14.6c-7.8,15.8-15.2,33.3-22,52.4"
          />
          <path
            fill="none"
            d="M243.2,225.5l1.9-0.2c4.9-1.9,8.4-3.9,10.5-5.5l2.1-1.6l2-1.9l9.4-12.7c4.1-5.3,6.4-10.5,6.8-16
	c4.9-21.4,6.2-32.9,4.3-34.5 M247.7,222l-4.5,3.5 M247.7,222c1.8-0.6,3.5-1.4,5.3-2.3 M223.7,229.4l0.8,0.2l7.2,1.9 M243.2,225.5
	c-1.6,0.2-2.9,0.6-4.1,1 M238.9,226.7c-1.2,0-2.5,0.6-3.9,1.8c-1,0.8-2.1,1.9-3.3,3.1 M238.9,226.7l-6.4-1.6l-1.8-0.2 M223.7,229.4
	c2.5,0,4.9-1.4,7-4.5"
          />
          <path
            fill="none"
            d="M231.7,231.6l-0.4,0.4c-0.8,0.8-1.4,1.8-2.1,2.9l-17.5,30.8c-1.8,2.3-2.5,8.4-2.3,18.1l2.1,20.9 M264.6,248.1
	c-2,0.8-3.7,1.8-5.3,2.9c-2.9,1.8-4.3,4.3-4.5,7.4l1.2,13.3c0.6,3.5,3.1,6,7.4,7.6l1.2,0.4l2.5,0.2 M294.9,209.2
	c2.9-1.6,6.2-2.3,9.7-2.5 M276.7,225.7c4.1-3.5,7.8-7.4,11.5-11.3l2-1.9c1.4-1.2,2.9-2.1,4.5-2.9 M276.1,226.9l0.6-1.2 M275.6,239.2
	v-10.5l0.6-1.8 M273.8,246.8l-4.3,0.2 M290.2,245.2l-15.8,1.6h-0.6 M287.1,246c-1.9,0.8-3.7,1.8-5.3,3.1c-2.9,1.9-4.3,4.5-4.5,7.6
	l1.2,13.6c0.6,3.7,3.1,6.2,7.4,7.8l1.2,0.4h0.6 M290.2,245.2c-1,0.2-2.1,0.6-2.9,1 M275.2,279.5c-1.4-0.6-2.1-2-2.5-4.5l-1.4-17.5
	c-0.2-5.3,0.6-8.8,2.3-10.7 M297.6,278.3c-1.4-0.6-2.1-2.1-2.5-4.7l-1.4-18.1c-0.2-5.3,0.6-9,2.3-11.1 M309.9,245
	c-2,0.8-3.7,1.8-5.3,3.1c-2.9,1.9-4.3,4.7-4.5,8l1.2,14.4c0.6,3.9,3.1,6.6,7.2,8.2l1.4,0.4l1,0.2 M296.2,244.6h0.2 M296.4,244.6
	h-0.2 M296.2,244.6l-4.1,0.4 M314.7,243.7c-1.8,0.2-3.3,0.8-4.9,1.4 M292.1,245l-2,0.2"
          />
          <path
            fill="none"
            d="M250,270c-0.8-8,1-15,5.3-21c2.5-4.9,9.4-8.2,20.3-9.7 M269.5,247c-1.8,0.2-3.3,0.6-4.9,1.2 M296.8,164.3
	c1.4,13.4,3.9,27.7,7.6,42.3 M252.8,219.7c8.2-19.1,16.4-34.9,24.6-47.2"
          />
          <path
            fill="none"
            d="M242.4,270.7c-1-8.6,1.6-17.5,7.6-27.1c5.5-8.6,14.2-14.2,25.9-17 M294.9,209.2c-4.5-34.1-5.1-55-2-62.9
	 M276.7,225.7c4.9-35.5,7.2-60.6,6.6-75.4 M297.6,278.3l13.3,1 M294.3,278.7l3.3-0.4 M310.8,279.3l1.6,0.2 M287.8,278.9h2
	 M275.2,279.5l12.7-0.6 M271.9,279.9l3.3-0.4 M267.2,279.9h4.7 M289.8,278.9l4.5-0.2 M242.4,270.7l7.6-0.8 M211.3,304.7l13.4-2.1
	c4.5,0,7.2-1.8,8.2-5.3c2.3-11.5,5.5-20.3,9.6-26.5 M235.4,344l-10.9,2.5l0.8,5.3l6.2-0.8C234.4,349.7,235.6,347.3,235.4,344
	L235.4,344z M262.9,357.5l2-1.8c0,0,0-0.2,0.2-0.2c4.5-3.1,16.4-5.1,35.5-5.5h18.1 M249.3,384.9l0.8-2.3c2.7-9.2,6.6-17.1,11.7-23.8
	l1.2-1.4 M246.5,383.8c-0.2-0.4-0.2-0.8,0-1.2l1.2-4.1 M270.3,376.6V376c0-3.9,1.6-7.2,4.3-10.1c2.9-2.9,6.4-4.3,10.5-4.3
	c4.1,0,7.6,1.4,10.5,4.3c1.8,1.8,2.9,3.5,3.7,5.6c0.4,1.4,0.8,2.9,0.8,4.7l-0.2,2.1c-0.4,3.5-1.4,6.6-2.5,9.4h6.2"
          />
          <path
            fill="none"
            d="M270.3,376.6c0,2,0.4,3.7,1.2,5.3c2.1,6,14.4,9.4,36.6,9.5c16.4-0.8,25.7-3.5,27.9-8.4"
          />
          <path
            fill="none"
            d="M270.5,385.1c0-0.6-0.2-1.4,0-2.1v-6.4 M276.7,379.7c0.4,1.4,1.2,2.5,2.1,3.5c1.8,1.8,4.1,2.7,6.6,2.7
	s4.7-1,6.4-2.7s2.7-3.9,2.7-6.4c0-2.5-1-4.7-2.7-6.6l-0.8-0.6c-1.6-1.4-3.7-2.1-5.8-2.1c-2.5,0-4.7,1-6.6,2.7
	c-1.8,1.8-2.7,4.1-2.7,6.6C276.1,377.7,276.3,378.9,276.7,379.7L276.7,379.7z"
          />
          <path
            fill="none"
            d="M279.7,381.4l0.6,0.6c1.6,1.4,3.1,2.1,5.3,2.1c2,0,3.7-0.8,5.3-2.1c1.6-1.6,2.1-3.1,2.1-5.3
	c0-1.6-0.4-2.7-1.2-3.9l-1-1.4c-1.4-1.6-3.1-2.1-5.3-2.1c-2,0-3.7,0.8-5.3,2.1c-1.6,1.6-2.1,3.1-2.1,5.3
	C278.1,378.5,278.7,380.1,279.7,381.4L279.7,381.4z M331.9,282.4h-65.3c-10.5-0.4-16-4.5-16.4-12.3 M316.9,353.8
	c-2,2.3-5.3,3.5-9.9,3.7h-44.2 M308.7,387.9h-5.3 M315.1,397.6l-7.2,0.6c-18.7-0.2-30.2-2.5-34.7-6.8 M273.2,391.2
	c-1.6-2-2.5-3.9-2.9-6"
          />
          <path
            fill="none"
            d="M273.2,391.2c-0.8-0.8-1.6-1.8-1.8-2.7V406 M216.7,357.9v71.3c-0.4,3.5,0.2,6.2,1.6,8.2
	c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2V406 M270.5,385.1h-13.3l-8-0.2 M211.3,304.7l5.5,53.2
	 M249.3,384.9c-1.4-0.2-2.3-0.6-2.9-1 M247.7,378.5c-9.2,3.3-16.6,7.6-22.4,13.1 M246.5,383.8l-21.4,7.8 M225.1,391.6
	c-4.1-11.7-6.8-23-8.4-33.7 M312.4,279.5l-2.5-34.5 M294.3,278.7l-2.1-33.7 M289.8,278.9l-2.7-32.9 M271.9,279.9l-2.3-32.9
	 M267.2,279.9l-2.5-31.8 M271.5,406.2l86.7-0.4 M275.6,239.2c13.8-1.4,28.6-2.5,44.4-3.5 M312.4,279.5l4.7-0.2 M296.8,164.3
	c21.8-6.2,59.6-9.9,113.4-10.7 M319,243.5l-4.3,0.2 M317.1,279.3l-2.3-35.7 M319.2,243.1l-22.8,1.6 M553.3,369.6
	c1.6-1.4,3.7-2.1,5.8-2.1c2.5,0,4.7,1,6.6,2.7c1.8,1.8,2.7,4.1,2.7,6.6c0,1-0.2,2-0.4,2.9c-0.4,1.4-1.2,2.5-2.3,3.5
	c-1.8,1.8-4.1,2.7-6.6,2.7s-4.7-1-6.6-2.7c-1.8-1.8-2.7-3.9-2.7-6.4c0-2.5,1-4.7,2.7-6.6L553.3,369.6L553.3,369.6z M570.4,187.7
	c0.6,5.3,2.7,10.3,6.6,15.2l7,9.7l2.1,2.7c0.8,1,1.8,1.8,2.7,2.5l2.7,1.9c-8.2-19.1-16.4-34.9-24.6-47.2L570.4,187.7L570.4,187.7z"
          />
          <path
            fill="none"
            d="M570.4,187.7l-3.3-15c-2.1-11.1-2.7-17-1.8-17.7l0.4-0.6c0-0.2,0-0.4-0.2-0.6l-0.6-0.4l-0.8,0.2
	c-1.9,1.6-0.6,13.1,4.3,34.5c0.6,5.5,2.9,10.7,6.8,16l9.4,12.7l2,1.9l2.1,1.6c2.1,1.6,5.7,3.3,10.5,5.5l2,0.2l-4.5-3.5
	c-1.8-0.6-3.5-1.4-5.3-2.3l-2.7-1.9c-1-0.8-1.9-1.8-2.7-2.5l-2.1-2.7l-7-9.7C573.1,197.9,571,192.8,570.4,187.7L570.4,187.7z
	 M561.1,150.1c-1.6-1.6-3.1-2.7-4.7-3.7c-1.4-0.8-2.5-1.2-3.3-1.2c-0.8,0-1.2,0.4-1.4,1.2c3.1,7.8,2.5,28.8-1.9,62.9
	c1.6,0.8,2.9,1.8,4.5,2.9l2,1.9c3.7,4.1,7.4,7.8,11.5,11.3C562.8,190.3,560.7,165.1,561.1,150.1L561.1,150.1z"
          />
          <path
            fill="none"
            d="M551.7,146.4l-2.5-0.8c-0.2,6-0.6,12.3-1.4,18.7c-1.4,13.4-3.9,27.7-7.6,42.3c3.5,0.2,6.6,1,9.7,2.5
	C554.4,175.1,555,154.2,551.7,146.4L551.7,146.4z M565.5,155c-1,0.8-0.4,6.6,1.8,17.7c8.2,12.3,16.4,27.9,24.6,47.2
	c1.8,1,3.5,1.8,5.3,2.3c-6.8-19.1-14-36.6-22-52.4C571.8,163.8,568.7,158.9,565.5,155L565.5,155z"
          />
          <path
            fill="none"
            d="M564.4,153.6l-3.3-3.5c-0.4,15,1.8,40.1,6.6,75.4l0.6,1.2c11.7,2.7,20.5,8.4,25.9,17c6,9.4,8.8,18.5,7.8,27.1
	c4.1,6.2,7.2,15.2,9.5,26.5c1,3.5,3.7,5.3,8.2,5.3l13.4,2.1l2.1-20.9c0.2-9.9-0.6-16-2.3-18.1l-17.7-30.8c-0.6-1-1.4-1.9-2.1-2.9
	l-0.4-0.4c-1.2-1.4-2.3-2.3-3.3-3.1c-1.4-1.2-2.7-1.8-3.9-1.8c-1.4-0.4-2.7-0.8-4.1-1l-2-0.2c-4.9-1.9-8.4-3.9-10.5-5.5l-2.1-1.6
	l-2-1.9l-9.4-12.7c-4.1-5.3-6.4-10.5-6.8-16C563.8,166.7,562.4,155.2,564.4,153.6L564.4,153.6z M547.8,164.3
	c0.8-6.4,1.2-12.7,1.4-18.7c-15.2-4.7-33.9-7.6-56.1-9.2l-13.1,0.8l-30.8-1.4l-20.5-0.2l-33.9,0.4l-30,1.4l-13.1-0.8
	c-22.2,1.6-40.9,4.5-56.1,9.2c0.2,6,0.6,12.3,1.4,18.7c21.8-6.2,59.6-9.9,113.4-10.7h-13.3h32.5C486,154.2,525.4,157.7,547.8,164.3
	L547.8,164.3z"
          />
          <path
            fill="none"
            d="M556.4,214.2l-2-1.9c-1.4-1.2-2.9-2.1-4.5-2.9c-3.1-1.6-6.2-2.5-9.7-2.5c-5.3,3.5-23.6,4.5-55.2,2.5l-55.5-1.9
	l-32,0.2l-38,1.6c-31.6,1.8-50.1,1-55.2-2.5c-3.5,0.2-6.6,1-9.7,2.5c-1.6,0.8-3.1,1.8-4.5,2.9l-2,1.9c-3.7,4.1-7.6,7.8-11.5,11.3
	l-0.6,1.2c40.7-4.7,89.4-6.8,146-6.2c56.7-0.6,105.4,1.4,146.2,6.2l-0.6-1.2C563.8,222.2,559.9,218.3,556.4,214.2L556.4,214.2z
	 M529.9,243.7c1.8,0.2,3.3,0.8,4.9,1.4c2,0.8,3.7,1.8,5.3,3.1c2.9,1.9,4.3,4.7,4.5,8l-1.2,14.4c-0.6,3.9-3.1,6.6-7.4,8.2l-1.6,0.4
	l13.3-0.8h-0.8c1.4-0.6,2.1-2.1,2.5-4.7l1.4-18.1c0.2-5.3-0.6-9-2.3-11.1h-0.2l-23.6-1.6l0.4,0.4L529.9,243.7L529.9,243.7z"
          />
          <path
            fill="none"
            d="M534.8,245c-1.6-0.6-3.1-1-4.9-1.4l-2.3,35.5l4.5,0.2L534.8,245L534.8,245z"
          />
          <path
            fill="none"
            d="M519.4,242.9l5.5,0.2l23.6,1.6h0.2l4.1,0.4l2,0.2l15.8,1.6h0.6l4.1,0.2c1.8,0.2,3.3,0.6,4.9,1.2
	c2,0.8,3.7,1.8,5.3,2.9c2.9,1.8,4.3,4.3,4.5,7.4l-1.2,13.3c-0.6,3.5-3.1,6-7.2,7.6l-1.4,0.4l-2.5,0.2l-4.5-0.2l-2.3-0.2l-14-0.8H555
	l-4.7-0.2l-2.7-0.4l-13.2,0.8h-0.2l-2.1,0.2l-4.5-0.2l-3.3-0.4c1.4-0.6,2.1-2.1,2.5-4.9l1.4-18.9c0.2-5.5-0.6-9.2-2.7-11.3l-0.2-0.2
	l-0.4-0.4L519.4,242.9c-0.6,1.6-1.2,3.5-1.6,5.5l-1.6,26.7c0.4,3.5-0.8,6-3.5,7.4H578c10.5-0.4,16-4.5,16.6-12.3c0.8-8-1-15-5.3-21
	c-2.5-4.9-9.4-8.2-20.3-9.7c-13.8-1.4-28.6-2.5-44.4-3.5C522.3,237.4,520.5,239.8,519.4,242.9L519.4,242.9z"
          />
          <path
            fill="none"
            d="M519.4,242.9c1.4-3.1,2.9-5.5,5.3-7c-28.5-1.6-60.2-2.3-95.1-2.1h-7.2c-7.8,0-15.6,0-23.2,0.2
	c-28.6,0-55,0.6-78.9,1.9c2.1,1.6,3.9,3.9,5.3,7c24.2-1.2,51.5-1.8,81.9-1.6h30.4C467.9,241.1,495.2,241.7,519.4,242.9L519.4,242.9z
	 M525.2,243.5l0.2,0.2c2,2.1,2.9,6,2.7,11.3l-1.4,18.9c-0.2,2.7-1.2,4.3-2.5,4.9l3.3,0.4l2.3-35.5L525.2,243.5L525.2,243.5z"
          />
          <path
            fill="none"
            d="M525.4,243.7l-0.2-0.2H525 M540.2,248.1c-1.6-1.4-3.3-2.3-5.3-3.1l-2.5,34.5l2.1-0.2h0.2l1.6-0.4
	c4.3-1.6,6.6-4.5,7.4-8.2l1.2-14.4C544.5,252.8,542.9,250.3,540.2,248.1L540.2,248.1z M517.8,248.3c0.4-1.9,1-3.9,1.6-5.5
	c-24.2-1.2-51.4-1.8-81.7-1.6h-30.4c-30.4,0-57.7,0.4-81.9,1.6c0.6,1.6,1.2,3.5,1.6,5.5l1.6,26.7c-0.4,3.5,0.8,6,3.3,7.4h180.8
	c2.5-1.4,3.7-3.7,3.5-7.4L517.8,248.3L517.8,248.3z M407.9,260.4c0-2.7,1.4-5.3,3.9-7.2c2.7-1.9,5.8-2.9,9.5-2.9c3.7,0,7,1,9.7,2.9
	c2.7,1.9,4.1,4.5,4.1,7.2v1.6c0,2.9-1.4,5.3-4.1,7.2c-2.7,2-5.8,2.9-9.7,2.9c-3.7,0-7-1-9.5-2.9c-2.7-2-3.9-4.5-3.9-7.2V260.4
	L407.9,260.4z"
          />
          <path
            fill="none"
            d="M412,253.2c-2.7,1.9-3.9,4.5-3.9,7.2v0.8c0.2,2.5,1.6,4.7,3.9,6.4c2.7,2,5.8,2.9,9.5,2.9c3.7,0,7-1,9.7-2.9
	c2.5-1.8,3.7-3.9,4.1-6.4v-0.8c0-2.7-1.4-5.3-4.1-7.2s-5.8-2.9-9.7-2.9C417.8,250.3,414.5,251.3,412,253.2L412,253.2z M411.4,255.2
	l1.4-1.4c2.3-1.8,5.3-2.7,8.8-2.7c3.5,0,6.4,1,8.8,2.7l1.4,1.2c1.6,1.6,2.3,3.3,2.3,5.3c0,2.5-1.2,4.7-3.7,6.6
	c-2.3,1.8-5.3,2.7-8.8,2.7c-3.5,0-6.4-1-8.8-2.7c-2.3-1.8-3.7-4.1-3.7-6.6C409.1,258.5,409.8,256.7,411.4,255.2L411.4,255.2z"
          />
          <path
            fill="none"
            d="M412.8,254l-1.4,1.4c-1.6,1.6-2.3,3.3-2.3,5.3c0,2.5,1.2,4.7,3.7,6.6c2.3,1.8,5.3,2.7,8.8,2.7
	c3.5,0,6.4-1,8.8-2.7c2.3-1.8,3.7-4.1,3.7-6.6c0-2-0.8-3.7-2.3-5.3l-1.4-1.2c-2.3-1.8-5.3-2.7-8.8-2.7
	C418.2,251.3,415.3,252,412.8,254L412.8,254z M412.4,257.7l0.6-0.2h17.9v1h-4.1v3.1h-1.6v-3.1h-11.7v2.1h6V260h-3.1v-0.4h3.7v1
	l-0.2,0.2h-7.2l-0.2-0.4v-2.3c0-0.2,0.2-0.2,0.4-0.2h12.5v3.5h0.4v-3.5h4.7v-0.2h-17.3l-0.6,0.2c-0.2,0-0.2,0.2-0.2,0.4v2.3
	c0,0.2,0,0.4,0.2,0.4l0.6,0.2h6.6l0.4-0.2l0.2-0.2v-1.2c0-0.2-0.2-0.4-0.6-0.4h-3.7v-0.4h4.1c0.4,0,0.6,0.2,0.6,0.4v1.4l-0.4,0.6
	l-0.8,0.2h-7.2c-0.4,0-0.8-0.2-1-0.4v-3.1C411.6,257.9,411.8,257.7,412.4,257.7L412.4,257.7z"
          />
          <path
            fill="none"
            d="M413,257.5l-0.6,0.2c-0.4,0-0.8,0.2-0.8,0.6v3.1c0.2,0.2,0.4,0.4,1,0.4h7.2l0.8-0.2l0.4-0.6v-1.4
	c0-0.2-0.2-0.4-0.6-0.4h-4.1v0.4h3.7c0.2,0,0.4,0,0.6,0.4v1.2l-0.2,0.2l-0.4,0.2h-6.8l-0.6-0.2c-0.2,0-0.2-0.2-0.2-0.4v-2.3
	c0-0.2,0-0.4,0.2-0.4l0.6-0.2h17.7v-0.4H413L413,257.5L413,257.5z"
          />
          <path
            fill="none"
            d="M412.8,258.5v2.3l0.2,0.4h6.8l0.2-0.2v-1h-3.7v0.4h3.1v0.6h-6v-2.1H425v3.1h0.6v-3.5h-12.5
	C413,258.3,412.8,258.3,412.8,258.5L412.8,258.5z M430.9,258.7v-0.4H426v3.3h0.8v-2.9"
          />
          <path
            fill="none"
            d="M435.2,261.2c-0.2,2.5-1.6,4.7-4.1,6.4c-2.7,2-5.9,2.9-9.7,2.9c-3.7,0-7-1-9.5-2.9c-2.3-1.8-3.7-3.9-3.9-6.4
	v0.8c0,2.9,1.4,5.3,3.9,7.2c2.7,2,5.8,2.9,9.5,2.9c3.7,0,7-1,9.7-2.9c2.7-2,4.1-4.5,4.1-7.2L435.2,261.2L435.2,261.2z M548.2,244.6
	h0.2 M552.7,245l-4.1-0.4c1.8,1.9,2.5,5.6,2.3,11.1l-1.4,18.1c-0.2,2.5-1.2,4.1-2.5,4.7h0.8l2.7,0.4L552.7,245L552.7,245z"
          />
          <path
            fill="none"
            d="M554.4,245.2l-2-0.2l-2.3,33.7l4.5,0.2l2.5-32.9C556.6,245.8,555.6,245.4,554.4,245.2L554.4,245.2z
	 M562.8,249.1c-1.6-1.4-3.5-2.3-5.3-3.1l-2.5,32.9h1.6l2.3-0.4c4.3-1.6,6.6-4.3,7.4-7.8l1.2-13.6
	C567.1,253.6,565.7,251.1,562.8,249.1L562.8,249.1z"
          />
          <path
            fill="none"
            d="M557.6,246c2,0.8,3.7,1.8,5.3,3.1c2.9,1.9,4.3,4.5,4.5,7.6l-1.2,13.6c-0.6,3.7-3.1,6.2-7.4,7.8l-2.3,0.4l14,0.8
	h-1c1.4-0.6,2.1-2,2.5-4.5l1.4-17.5c0.2-5.3-0.6-8.8-2.3-10.7h-0.6l-15.8-1.6C555.6,245.4,556.6,245.8,557.6,246L557.6,246z
	 M613.9,224.9h-1.8l-6.4,1.6c1.2,0,2.5,0.6,3.9,1.8c1,0.8,2.1,1.9,3.3,3.1l7-2.1h0.8C618.4,229.4,616,228.1,613.9,224.9L613.9,224.9
	z"
          />
          <path
            fill="none"
            d="M611.5,218.9c0,2.5,0.8,4.5,2.5,6c2.1,2.9,4.3,4.5,7,4.5l26.3-3.1c1.9-0.6,3.1-1.9,3.7-3.7
	c0.2-0.8,0.4-1.8,0.4-2.7c0-1.9-0.6-4.5-1.6-7.4c-1.8-5.5-5.5-8.2-10.9-8l-19.5-1.6c-4.1,0-6.4,2.5-6.6,7.6L611.5,218.9L611.5,218.9
	z M615.6,224.2c-1.6-1.6-2.1-3.3-2.1-5.5l1-7.4c0.2-4.5,2.1-6.8,6-6.8l17.5,1.4c4.9-0.2,8.2,2.1,9.7,7.2c1,2.5,1.6,4.9,1.6,6.6
	c0,1,0,1.8-0.2,2.3c-0.6,1.6-1.6,2.7-3.3,3.3l-23.6,2.9h-0.6C619.1,227.9,617.4,226.5,615.6,224.2L615.6,224.2z"
          />
          <path
            fill="none"
            d="M613.5,218.7c0,2.1,0.8,4.1,2.1,5.5c1.8,2.5,3.5,3.7,5.7,3.9h0.6l23.6-2.9c1.8-0.6,2.9-1.8,3.3-3.3
	c0.2-0.8,0.2-1.6,0.2-2.3c0-1.8-0.6-4.1-1.6-6.6c-1.6-4.9-4.9-7.2-9.7-7.2l-17.5-1.4c-3.7,0-5.7,2.3-6,6.8L613.5,218.7L613.5,218.7z
	 M569.1,228.7l-0.6-1.8c-40.9-4.9-89.6-6.8-146.2-6.2c-56.5-0.6-105.2,1.4-146,6.2l-0.6,1.8v10.5c13.8-1.4,28.6-2.5,44.4-3.5
	c24.2-1.4,50.5-1.9,78.9-1.9c7.8,0,15.4-0.2,23.2-0.2h7.2c34.7-0.2,66.5,0.6,95.1,2.1c15.8,0.8,30.6,1.9,44.4,3.5L569.1,228.7
	L569.1,228.7z"
          />
          <path
            fill="none"
            d="M568.5,226.9l0.6,1.8v10.5c10.9,1.6,17.7,4.9,20.3,9.7c4.3,6,6,13.1,5.3,21l7.6,0.8c1-8.6-1.6-17.5-7.8-27.1
	C588.9,235.3,580.2,229.6,568.5,226.9L568.5,226.9z M575.1,247l-4.1-0.2c1.8,1.9,2.5,5.7,2.3,10.7L572,275c-0.2,2.5-1.2,3.9-2.5,4.5
	h1l2.3,0.2L575.1,247L575.1,247z"
          />
          <path
            fill="none"
            d="M580,248.1c-1.6-0.6-3.1-1-4.9-1.2l-2.3,32.9l4.5,0.2L580,248.1L580,248.1z"
          />
          <path
            fill="none"
            d="M585.2,251.1c-1.6-1.4-3.5-2.3-5.3-2.9l-2.5,32l2.5-0.2l1.4-0.4c4.3-1.6,6.6-4.1,7.2-7.6l1.2-13.3
	C589.7,255.4,588.2,252.8,585.2,251.1L585.2,251.1z M540.2,206.6c3.7-14.8,6.2-28.8,7.6-42.3c-22.4-6.4-62-10.1-118.5-10.7H410
	c-53.8,0.8-91.6,4.5-113.4,10.7c1.4,13.4,3.9,27.7,7.6,42.3c5.3,3.5,23.6,4.5,55.1,2.5l38-1.6l31.8-0.4l55.5,1.9
	C516.6,211.1,535,210.1,540.2,206.6L540.2,206.6z M319.2,243.1l6-0.2c-1.4-3.1-2.9-5.5-5.3-7c-15.8,0.8-30.6,1.9-44.4,3.5
	c-10.9,1.6-17.7,4.9-20.3,9.7c-4.3,6-6,13.1-5.3,21c0.4,7.8,6,11.9,16.4,12.3h65.3c-2.5-1.4-3.7-3.7-3.3-7.4l-1.6-26.7
	c-0.4-1.9-1-3.9-1.6-5.5L319.2,243.1l-0.2,0.4c-1.8,2.1-2.5,6-2.3,11.5l1.4,18.9c0.2,2.7,1.2,4.3,2.5,4.9l-3.3,0.4l-4.5,0.2
	l-1.6-0.2l-13.2-0.8l-3.3,0.4l-4.5,0.2l-2-0.2l-12.5,0.6l-3.3,0.4l-4.7,0.2l-2.5-0.2l-1.2-0.4c-4.3-1.6-6.6-4.1-7.4-7.6l-1.2-13.3
	c0-3.1,1.6-5.6,4.5-7.4c1.6-1.4,3.3-2.1,5.3-2.9c1.6-0.6,3.1-1,4.9-1.2l4.1-0.2h0.6l15.8-1.6l2-0.2l4.1-0.4h0.2L319.2,243.1
	L319.2,243.1z"
          />
          <path
            fill="none"
            d="M319,243.5l0.2-0.4l-22.8,1.6h-0.2c-1.8,1.9-2.5,5.6-2.3,11.1l1.4,18.1c0.2,2.5,1.2,4.1,2.5,4.7l13.2,0.8
	l-1.2-0.2l-1.4-0.4c-4.3-1.6-6.6-4.5-7.2-8.2l-1.2-14.4c0-3.3,1.6-6,4.5-8c1.6-1.4,3.5-2.3,5.3-3.1c1.6-0.6,3.1-1,4.9-1.4L319,243.5
	L319,243.5z"
          />
          <path
            fill="none"
            d="M316.7,255.2c-0.2-5.6,0.6-9.6,2.3-11.5l-4.1,0.2l2.3,35.5l3.3-0.4c-1.4-0.6-2.1-2.1-2.5-4.9L316.7,255.2
	L316.7,255.2z M449.8,374l5.3-5.8c3.3-4.7,9-7,16.4-7H503c10.1,0.2,17.2,3.3,21.4,8.8l11.5,17.9h5.3l-14.6-22.8
	c-3.9-6-10.9-9.4-20.9-9.9h-36.1c-6.8-0.2-12.3,2.9-16.2,9.2l-5.3,6.8c-1.6,2-5.5,2.9-11.7,3.3h-28.1c-6.2-0.2-10.1-1.4-11.7-3.3
	l-5.3-6.8c-3.9-6.2-9.2-9.4-16.2-9.2h-36.2c-9.9,0.6-17,3.9-20.9,9.9l-14.6,22.8h5.3l11.5-17.9c4.3-5.6,11.3-8.6,21.4-8.8h31.4
	c7.6,0,13.1,2.3,16.4,7l5.3,5.8c2.5,1.9,7.4,2.9,14.6,2.7H435C442.4,376.8,447.3,376,449.8,374L449.8,374z"
          />
          <path
            fill="none"
            d="M455,368.2l-5.3,5.8c-2.5,1.9-7.4,2.9-14.6,2.7h-25.5c-7.4,0.2-12.3-0.6-14.6-2.7l-5.3-5.8c-3.3-4.7-9-7-16.4-7
	h-5.1c-3.9,0.2-5.8,2.1-6,5.9v16.2h14.6c7.4,0.4,12.9,1.6,16.8,3.5c4.9,1.4,11.7,2,20.3,2.1h15.8c9.4,0,16.6-0.6,21.6-2.1
	c3.7-1.9,9.4-3.1,16.8-3.5h14.6V367c-0.2-3.7-2.3-5.7-6-5.9h-5.1C464,361.2,458.6,363.5,455,368.2L455,368.2z"
          />
          <path
            fill="none"
            d="M448,370.7l5.3-6.8h-62l5.3,6.8c1.6,2,5.5,2.9,11.7,3.3h28.1C442.6,373.8,446.5,372.7,448,370.7L448,370.7z
	 M482.7,383h-14.6c-7.4,0.4-13.1,1.6-16.8,3.5c-5.1,1.4-12.3,2.1-21.6,2.1h-15.8c-8.8,0-15.4-0.8-20.3-2.1
	c-3.7-1.9-9.4-3.1-16.8-3.5h-14.6c-1.6,4.3-2.9,6.4-4.7,6.4c-2.9,0.2-4.9-2-5.8-6.4h-13.8c0,2.5-0.4,4.7-1.6,6.6
	c-1.8,3.9-8.2,6.4-19.3,7.6h211.2c-11.1-1.4-17.5-3.9-19.3-7.6c-1.2-2-1.6-4.1-1.6-6.6h-13.8c-1,4.7-2.9,6.8-5.8,6.4
	C485.6,389.4,484.1,387.3,482.7,383L482.7,383z"
          />
          <path
            fill="none"
            d="M476.7,361c3.9,0.2,5.8,2.1,6,5.8V383c1.6,4.3,3.1,6.4,4.7,6.4c2.9,0.2,4.9-2,5.8-6.4v-13.3
	c0-4.5,1.8-7.4,5.7-8.8H476.7z"
          />
          <path
            fill="none"
            d="M503,361h-4.1c-3.9,1.4-5.7,4.3-5.7,8.8V383h15.4c7.4,0.2,13.8,1.8,18.9,4.7h8.2l-11.5-17.9
	C520.1,364.3,512.9,361.4,503,361L503,361z"
          />
          <path
            fill="none"
            d="M508.6,383.2h-1.6c0,2.5,0.6,4.7,1.6,6.6c1.8,3.9,8.2,6.4,19.3,7.6h0.4l1.2,0.2l7.2,0.6
	c18.7-0.2,30.2-2.5,34.7-6.8c1.6-2,2.5-3.9,2.9-6c0-0.6,0.2-1.4,0-2.1v-6.6c0,2-0.4,3.7-1.2,5.3c-2.1,6-14.4,9.4-36.6,9.5
	C520.1,390.6,511,387.9,508.6,383.2L508.6,383.2z"
          />
          <path
            fill="none"
            d="M469.5,354.9h36.1c9.9,0.6,17,3.9,20.9,9.9l14.6,22.8h6.2c-1.4-2.9-2.1-6-2.5-9.4c0-0.6-0.2-1.2-0.2-1.8v-0.4
	c0-1.6,0.2-3.1,0.8-4.7c0.8-2.1,1.9-3.9,3.7-5.6c2.9-2.9,6.2-4.3,10.3-4.3c4.1,0,7.6,1.4,10.5,4.3c2.9,2.9,4.3,6.2,4.3,10.1v9.2
	h13.3l7.8-0.2l-0.8-2.3c-2.7-9.2-6.6-17.1-11.7-23.8l-1.2-1.4h-44.2c-4.7-0.2-8-1.6-9.9-3.7H316.9c-2,2.3-5.3,3.5-9.9,3.7h-44.2
	l-1.2,1.4c-4.9,6.6-8.8,14.6-11.7,23.8l-0.8,2.3l7.8,0.2h13.3c0-0.6-0.2-1.4,0-2.1v-7c0-3.9,1.6-7.2,4.3-10.1
	c2.9-2.9,6.4-4.3,10.5-4.3c4.1,0,7.6,1.4,10.5,4.3c1.8,1.8,2.9,3.5,3.7,5.6c0.4,1.4,0.8,2.9,0.8,4.7l-0.2,2.1
	c-0.4,3.5-1.4,6.6-2.5,9.4h6.2l14.6-22.8c3.9-6,10.9-9.4,20.9-9.9H375c6.8-0.2,12.3,2.9,16.2,9.2h62
	C457.2,357.7,462.6,354.7,469.5,354.9L469.5,354.9z"
          />
          <path
            fill="none"
            d="M337.5,383H336c-2.1,4.7-11.5,7.6-27.9,8.4c-22.2-0.2-34.5-3.5-36.6-9.5c-0.8-1.6-1.2-3.3-1.2-5.3v8.6
	c0.2,2.1,1.2,4.1,2.9,6c4.5,4.3,16.2,6.6,34.7,6.8l7.2-0.6l1.2-0.2h0.4c11.1-1.4,17.5-3.9,19.3-7.6
	C337.1,387.9,337.7,385.5,337.5,383L337.5,383z"
          />
          <path
            fill="none"
            d="M336,383.2h15.4v-13.3c0-4.5-1.8-7.4-5.6-8.8h-4.1c-10.1,0.2-17.1,3.3-21.4,8.8l-11.5,17.9h8.2
	C322.1,384.9,328.6,383.4,336,383.2L336,383.2z"
          />
          <path
            fill="none"
            d="M345.7,361c3.9,1.4,5.8,4.3,5.7,8.8V383c1,4.7,2.9,6.8,5.8,6.4c1.6-0.2,3.1-2.3,4.7-6.4v-16.2
	c0.2-3.7,2.3-5.7,6-5.8H345.7z M316.9,353.8c1-1,1.6-2.3,2-3.7h-18.1c-19.3,0.4-31,2.3-35.5,5.5c0,0-0.2,0-0.2,0.2l-2,1.8h44.2
	C311.6,357.3,314.9,355.9,316.9,353.8L316.9,353.8z"
          />
          <path
            fill="none"
            d="M318.8,349.9c-0.4,1.6-1,2.7-2,3.7h210.7c-0.8-1-1.6-2.3-1.8-3.7L318.8,349.9L318.8,349.9z M403,411.3l-2.3,2.5
	h43.3l-2.3-2.5l-14-9.7h-10.3"
          />
          <path
            fill="none"
            d="M400.7,413.8l2.3-2.5l-11.9-8l-6.2,4.3h-9.7l5.3,6.2"
          />
          <path
            fill="none"
            d="M391.1,403.3l11.9,8l14.2-9.7h-86.9l-13.6-4.1h-0.4l-1.2,0.2l8,6l35.1,2.1l1,1.8h25.7 M380.4,413.8l-5.3-6.2"
          />
          <path fill="none" d="M316.7,397.4l13.6,4.1h184.2l13.4-4.1" />
          <path
            fill="none"
            d="M358.2,405.8l-35.1-2.1l-8-6l-7.2,0.6c-18.7-0.2-30.2-2.5-34.7-6.8c-0.8-0.8-1.6-1.8-1.8-2.7v17.5L358.2,405.8
	L358.2,405.8z M485.5,407.6l1-1.8l35.1-2.1l8-6l-1.2-0.2h-0.4l-13.4,4.1h-86.9l14,9.7l12.1-8l6,4.3L485.5,407.6"
          />
          <path
            fill="none"
            d="M464.2,413.8l5.3-6.2h-9.7l-6-4.3l-12.1,8l2.3,2.5 M512.7,282.4H266.6c-10.5-0.4-16-4.5-16.4-12.3l-7.6,0.8
	c0.2,1.8,0.6,3.7,1.2,5.5c1.6,9.4,8,14.8,19.5,16.2c31.4,1.2,64.9,1.4,100.9,0.8H481c36.1,0.6,69.6,0.4,100.9-0.8
	c11.3-1.4,17.7-6.8,19.5-16.2c0.6-1.8,1-3.5,1.2-5.5l-7.6-0.8c-0.6,7.8-6,11.9-16.4,12.3L512.7,282.4L512.7,282.4z M336,383.2
	c-7.4,0.2-13.6,1.8-18.9,4.7h-19.7c1.4-2.9,2.1-6,2.5-9.4l0.2-2.1c0-1.6-0.2-3.1-0.8-4.7c-0.8-2.1-2-3.9-3.7-5.6
	c-2.9-2.9-6.4-4.3-10.5-4.3s-7.6,1.4-10.5,4.3c-2.9,2.9-4.3,6.2-4.3,10.1v0.6c0,2,0.4,3.7,1.2,5.3c2.1,6,14.4,9.4,36.6,9.5
	C324.5,390.6,333.8,387.9,336,383.2L336,383.2z M276.7,379.7c-0.4-1-0.4-2-0.4-2.9c0-2.5,1-4.7,2.7-6.6c1.8-1.8,4.1-2.7,6.6-2.7
	c2.1,0,4.1,0.8,5.8,2.1l0.8,0.6c1.8,1.8,2.7,4.1,2.7,6.6c0,2.5-1,4.7-2.7,6.4s-3.9,2.7-6.4,2.7c-2.5,0-4.7-1-6.6-2.7
	C277.9,382.2,277.1,381.1,276.7,379.7L276.7,379.7z"
          />
          <path
            fill="none"
            d="M276.1,376.8c0,1,0.2,2,0.4,2.9c0.4,1.4,1.2,2.5,2.1,3.5c1.8,1.8,4.1,2.7,6.6,2.7c2.5,0,4.7-1,6.4-2.7
	s2.7-3.9,2.7-6.4c0-2.5-1-4.7-2.7-6.6l-0.8-0.6c-1.6-1.4-3.7-2.1-5.8-2.1c-2.5,0-4.7,1-6.6,2.7C277.1,372.1,276.1,374.2,276.1,376.8
	L276.1,376.8z M279.7,381.4c-1.2-1.4-1.6-2.9-1.6-4.7c0-1.9,0.8-3.7,2.1-5.3c1.6-1.6,3.1-2.1,5.3-2.1c2,0,3.7,0.8,5.3,2.1l1,1.4
	c0.8,1.2,1.2,2.5,1.2,3.9c0,2-0.8,3.7-2.1,5.3c-1.4,1.4-3.1,2.1-5.3,2.1c-2,0-3.7-0.8-5.3-2.1L279.7,381.4L279.7,381.4z"
          />
          <path
            fill="none"
            d="M278.1,376.8c0,1.8,0.6,3.3,1.6,4.7l0.6,0.6c1.6,1.4,3.1,2.1,5.3,2.1c2,0,3.7-0.8,5.3-2.1
	c1.6-1.6,2.1-3.1,2.1-5.3c0-1.6-0.4-2.7-1.2-3.9l-1-1.4c-1.4-1.6-3.1-2.1-5.3-2.1c-2,0-3.7,0.8-5.3,2.1
	C278.9,373.1,278.1,374.8,278.1,376.8L278.1,376.8z"
          />
          <path
            fill="none"
            d="M480.8,293.2H364c-36.1,0.6-69.6,0.4-100.9-0.8c-11.3-1.4-17.7-6.8-19.5-16.2c-0.6-1.8-0.8-3.5-1.2-5.5
	c-4.1,6.2-7.2,15.2-9.5,26.5c-1,3.5-3.7,5.3-8.2,5.3l-13.4,2.1l5.3,53.2c1.6,10.7,4.5,22,8.4,33.7c5.8-5.5,13.4-9.7,22.4-13.1
	c2.9-8.4,6.6-15.8,11.3-22v-0.2c1.2-1.4,2.3-2.3,3.7-3.5l0.6-0.4c4.7-3.7,17.1-5.7,37.6-6.2h243.4c20.3,0.4,32.9,2.5,37.6,6.2
	l0.6,0.4c1.4,1,2.5,2.1,3.7,3.5l0.2,0.2c4.7,6.2,8.6,13.6,11.3,22c9.2,3.3,16.6,7.6,22.4,13.1c4.1-11.7,6.8-23,8.4-33.7l5.3-53.2
	l-13.4-2.1c-4.5,0-7.2-1.8-8.2-5.3c-2.3-11.3-5.5-20.3-9.5-26.5c-0.2,1.8-0.6,3.7-1.2,5.5c-1.6,9.4-8,14.8-19.5,16.2
	C550.3,293.5,516.6,293.7,480.8,293.2L480.8,293.2z M473.6,295.3c5.6,0.2,9.7,2.1,12.7,5.5l16.4,23h86.5
	c17.7,2.1,28.6,8.8,32.5,19.7c-15.2-3.5-32.4-5.1-51.5-4.7H274c-19.1-0.4-36.2,1.2-51.4,4.7c4.1-10.9,14.8-17.5,32.5-19.7h86.5
	l16.4-23c2.9-3.5,7.2-5.3,12.7-5.5H473.6L473.6,295.3z M620.1,346.8l-0.8,5.3l-6.2-0.8c-2.7-1.6-4.1-3.7-3.9-7L620.1,346.8
	L620.1,346.8z M235.4,344c0.2,3.3-1,5.6-3.7,7l-6.2,0.8l-0.8-5.3L235.4,344L235.4,344z"
          />
          <path
            fill="none"
            d="M486.2,301c-2.9-3.5-7-5.3-12.7-5.5H371.1c-5.7,0.2-9.7,2.1-12.7,5.5l-16.4,23l17-18.7
	c4.5-5.1,10.3-7.4,17.3-6.4h92.2c7-0.8,12.9,1.4,17.3,6.4l17,18.7L486.2,301L486.2,301z"
          />
          <path
            fill="none"
            d="M485.6,305c-4.5-5.1-10.3-7.4-17.3-6.4h-17.9c2.3,0,4.1,1.8,5.3,5.3v22.6c0,2.3-1.6,3.3-4.5,2.7h-57.9
	c-2.9,0.6-4.5-0.4-4.5-2.7v-22.6c1-3.5,2.7-5.3,5.3-5.3h-17.9c-7-0.8-12.9,1.4-17.3,6.4l-17,18.7h-86.5
	c-17.7,2.1-28.6,8.8-32.5,19.7c15.2-3.5,32.4-5.1,51.4-4.7h296.2c19.1-0.4,36.2,1.2,51.5,4.7c-4.1-10.9-14.8-17.5-32.5-19.7h-86.5
	L485.6,305L485.6,305z"
          />
          <path
            fill="none"
            d="M455.6,303.9c-1-3.5-2.7-5.3-5.3-5.3h-56.3c-2.3,0-4.1,1.8-5.3,5.3v22.6c0,2.3,1.6,3.3,4.5,2.7h57.9
	c2.9,0.6,4.5-0.4,4.5-2.7L455.6,303.9L455.6,303.9z M619.3,352l0.8-5.3l-10.9-2.5c-0.2,3.3,1,5.7,3.9,7L619.3,352L619.3,352z
	 M231.5,351c2.7-1.4,4.1-3.7,3.7-7l-10.9,2.5l0.8,5.3L231.5,351L231.5,351z M527.7,353.8c2,2.3,5.3,3.5,9.9,3.7h44.2l-2-1.8
	l-0.2-0.2c-4.5-3.1-16.4-5.1-35.5-5.5h-18.1C526.2,351.4,526.8,352.6,527.7,353.8L527.7,353.8z M544.7,376.2v0.4
	c0,0.6,0,1.2,0.2,1.8c0.4,3.5,1.2,6.6,2.5,9.4h-19.7c-5.3-2.9-11.5-4.5-18.9-4.7c2.1,4.7,11.5,7.6,27.9,8.4
	c22.2-0.2,34.3-3.5,36.6-9.5c0.8-1.6,1.2-3.3,1.2-5.3V376c0-3.9-1.6-7.2-4.3-10.1c-2.9-2.9-6.4-4.3-10.5-4.3s-7.6,1.4-10.3,4.3
	c-1.8,1.8-2.9,3.5-3.7,5.6C544.9,373.1,544.7,374.6,544.7,376.2L544.7,376.2z M552.7,370.1l0.8-0.6c1.6-1.4,3.7-2.1,5.8-2.1
	c2.5,0,4.7,1,6.6,2.7c1.8,1.8,2.7,4.1,2.7,6.6c0,1-0.2,2-0.4,2.9c-0.4,1.4-1.2,2.5-2.1,3.5c-1.8,1.8-4.1,2.7-6.6,2.7s-4.7-1-6.6-2.7
	c-1.8-1.8-2.7-3.9-2.7-6.4C550,374.2,550.7,372.1,552.7,370.1L552.7,370.1z"
          />
          <path
            fill="none"
            d="M553.3,369.6l-0.8,0.6c-1.8,1.8-2.7,4.1-2.7,6.6c0,2.5,1,4.7,2.7,6.4s3.9,2.7,6.6,2.7c2.5,0,4.7-1,6.6-2.7
	c1-1,1.8-2.3,2.3-3.5c0.4-1,0.4-2,0.4-2.9c0-2.5-1-4.7-2.7-6.6c-1.8-1.8-4.1-2.7-6.6-2.7C557,367.6,555,368.2,553.3,369.6
	L553.3,369.6z M552.9,372.9l1-1.4c1.6-1.6,3.1-2.1,5.3-2.1c1.9,0,3.7,0.8,5.3,2.1c1.4,1.6,2.1,3.1,2.1,5.3c0,1.8-0.6,3.3-1.6,4.7
	l-0.6,0.6c-1.6,1.4-3.1,2.1-5.3,2.1c-2,0-3.7-0.8-5.3-2.1c-1.6-1.6-2.1-3.1-2.1-5.3C551.7,375.4,552.1,374,552.9,372.9L552.9,372.9z
	"
          />
          <path
            fill="none"
            d="M553.9,371.5l-1,1.4c-0.8,1.2-1.2,2.5-1.2,3.9c0,2,0.8,3.7,2.1,5.3c1.6,1.4,3.1,2.1,5.3,2.1
	c1.9,0,3.7-0.8,5.3-2.1l0.6-0.6c1.2-1.4,1.6-2.9,1.6-4.7c0-1.9-0.8-3.7-2.1-5.3c-1.6-1.6-3.1-2.1-5.3-2.1
	C557.2,369.4,555.4,370.1,553.9,371.5L553.9,371.5z"
          />
          <path
            fill="none"
            d="M525.8,349.9h18.1c19.3,0.4,31,2.3,35.5,5.5l0.2,0.2l1.9,1.8l1.2,1.4c4.9,6.6,8.8,14.6,11.7,23.8l0.8,2.3
	c1.4-0.2,2.3-0.6,2.9-1c0.2-0.4,0.2-0.8,0-1.2l-1.2-4.1c-2.9-8.4-6.6-15.8-11.3-22c-1.2-1.4-2.3-2.3-3.7-3.5l-0.6-0.4
	c-4.7-3.7-17.2-5.7-37.6-6.2h-243c-20.3,0.4-32.9,2.5-37.6,6.2l-0.6,0.4c-1.4,1-2.5,2.1-3.7,3.5c-4.7,6.2-8.6,13.6-11.3,22l-1.2,4.1
	c-0.2,0.4-0.2,0.8,0,1.2c0.4,0.4,1.4,0.8,2.9,1l0.8-2.3c2.7-9.2,6.6-17.1,11.7-23.8l1.2-1.4l1.9-1.8c0,0,0-0.2,0.2-0.2
	c4.5-3.1,16.4-5.1,35.5-5.5L525.8,349.9L525.8,349.9z"
          />
          <path
            fill="none"
            d="M598.3,382.6c0.2,0.4,0.2,0.8,0,1.2l21.2,7.8c-5.8-5.5-13.4-9.7-22.4-13.1L598.3,382.6L598.3,382.6z"
          />
          <path
            fill="none"
            d="M574.1,385.1c-0.2,2.1-1.2,4.1-2.9,6c0.8-0.8,1.6-1.8,1.8-2.7v40.7c-0.4,3.5,0.2,6.2,1.6,8.2
	c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2v-71.3c-1.6,10.7-4.5,22-8.4,33.7l-21.2-7.8
	c-0.4,0.4-1.4,0.8-2.9,1l-7.8,0.2h-13.1V385.1L574.1,385.1z"
          />
          <path
            fill="none"
            d="M573.1,388.5c-0.4,1-1,2-1.8,2.7c-4.5,4.3-16.2,6.6-34.7,6.8l-7.2-0.6l-8,6l-35.1,2.1l86.7,0.4L573.1,388.5
	L573.1,388.5L573.1,388.5z M193.9,222.6c0.6,1.8,1.8,3.1,3.7,3.7l26.3,3.1c2.5,0,4.9-1.4,7-4.5c1.6-1.6,2.5-3.7,2.5-6l-1-8.2
	c-0.2-5.1-2.3-7.6-6.6-7.6l-19.5,1.6c-5.5-0.2-9,2.3-10.9,8c-1.2,2.9-1.6,5.5-1.8,7.4C193.5,220.9,193.5,221.8,193.9,222.6
	L193.9,222.6z M197,212.9c1.6-4.9,4.9-7.2,9.7-7.2l17.5-1.4c3.7,0,5.6,2.3,5.8,6.8l1,7.4c0,2.1-0.8,4.1-2.1,5.5
	c-1.8,2.5-3.5,3.7-5.7,3.9h-0.8l-23.6-2.9c-1.8-0.6-2.9-1.8-3.3-3.3c-0.2-0.8-0.4-1.6-0.4-2.3C195.5,217.7,196.1,215.6,197,212.9
	L197,212.9z"
          />
          <path
            fill="none"
            d="M206.8,205.9c-4.9-0.2-8.2,2.1-9.7,7.2c-1,2.5-1.6,4.9-1.6,6.6c0,1,0,1.8,0.4,2.3c0.4,1.6,1.6,2.7,3.3,3.3
	l23.6,2.9h0.8c1.9-0.2,3.9-1.6,5.7-3.9c1.4-1.6,2.1-3.3,2.1-5.5l-1-7.4c-0.2-4.5-2.1-6.8-5.8-6.8L206.8,205.9L206.8,205.9z
	 M292.9,146.4c-0.2-0.8-0.6-1.2-1.4-1.2s-2,0.4-3.3,1.2c-1.6,1-3.1,2.3-4.7,3.7c0.4,15-1.8,40.1-6.6,75.4c4.1-3.5,7.8-7.4,11.5-11.3
	l2-1.9c1.4-1.2,2.9-2.1,4.5-2.9C290.4,175.1,289.6,154.2,292.9,146.4z"
          />
          <path
            fill="none"
            d="M295.4,145.6l-2.5,0.8c-3.1,7.8-2.5,28.8,2,62.9c2.9-1.6,6.2-2.3,9.7-2.5c-3.7-14.8-6.2-28.8-7.6-42.3
	C296,157.9,295.6,151.7,295.4,145.6L295.4,145.6z M278.9,154.4c0,0.2,0.2,0.4,0.4,0.6c1,0.8,0.4,6.6-2,17.7l-3.1,15
	c-0.6,5.1-2.7,10.1-6.6,15.2l-7,9.7l-2.1,2.7l-2.7,2.5l-2.7,1.9c-1.8,1-3.5,1.8-5.3,2.3l-4.5,3.5l1.9-0.2c4.9-1.9,8.4-3.9,10.5-5.5
	l2.1-1.6l2-1.9l9.4-12.7c4.1-5.3,6.4-10.5,6.8-16c4.9-21.4,6.2-32.9,4.3-34.5H280l-0.6-0.2l-0.6,0.4L278.9,154.4L278.9,154.4z"
          />
          <path
            fill="none"
            d="M283.6,150.1c-1,1-2.1,2.1-3.1,3.5c2,1.6,0.6,13.1-4.3,34.5c-0.6,5.5-2.9,10.7-6.8,16l-9.4,12.7l-2,1.9
	l-2.1,1.6c-2.1,1.6-5.8,3.3-10.5,5.5l-1.9,0.2c-1.6,0.2-2.9,0.6-4.1,1c-1.2,0-2.5,0.6-3.9,1.8c-1,0.8-2.1,1.9-3.3,3.1l-0.4,0.4
	c-0.8,0.8-1.4,1.8-2.1,2.9L212,265.9c-1.8,2.3-2.5,8.4-2.3,18.1l2.1,20.9l13.4-2.1c4.5,0,7.2-1.8,8.2-5.3
	c2.3-11.5,5.5-20.3,9.5-26.5c-1-8.6,1.6-17.5,7.6-27.1c5.5-8.6,14.2-14.2,25.9-17l0.6-1.2C281.8,190.3,283.9,165.1,283.6,150.1
	L283.6,150.1z"
          />
          <path
            fill="none"
            d="M279.3,155c-3.3,3.9-6.4,8.8-9.5,14.6c-7.8,15.8-15.2,33.3-22,52.4c1.8-0.6,3.5-1.4,5.3-2.3
	c8.2-19.1,16.4-34.9,24.6-47.2C279.7,161.6,280.2,155.8,279.3,155L279.3,155z"
          />
          <path
            fill="none"
            d="M274.2,187.7l3.1-15c-8.2,12.3-16.4,27.9-24.6,47.2l2.7-1.9l2.7-2.5l2.1-2.7l7-9.7
	C271.5,197.9,273.6,192.8,274.2,187.7L274.2,187.7z M223.7,229.4h0.8l7.2,2.1c1.2-1.4,2.3-2.3,3.3-3.1c1.4-1.2,2.7-1.8,3.9-1.8
	l-6.4-1.6l-1.8-0.2C228.6,228.1,226.5,229.4,223.7,229.4z M259.4,251.1c-2.9,1.8-4.3,4.3-4.5,7.4l1.2,13.3c0.6,3.5,3.1,6,7.4,7.6
	l1.2,0.4l2.5,0.2l-2.5-32C262.7,248.9,260.9,249.7,259.4,251.1L259.4,251.1z"
          />
          <path
            fill="none"
            d="M275.6,228.7l0.6-1.8c-11.9,2.7-20.5,8.4-25.9,17c-6,9.4-8.8,18.5-7.6,27.1l7.6-0.8c-0.8-8,1-15,5.3-21
	c2.5-4.9,9.4-8.2,20.3-9.7v-10.7L275.6,228.7L275.6,228.7z M273.8,246.8l-4.1,0.2l2.3,32.9l3.3-0.4c-1.4-0.6-2.1-2-2.5-4.5
	l-1.4-17.5C271.1,252.2,271.9,248.7,273.8,246.8L273.8,246.8z"
          />
          <path
            fill="none"
            d="M274.4,246.8h-0.6c-1.8,1.9-2.5,5.7-2.3,10.7l1.4,17.5c0.4,2.5,1.2,3.9,2.5,4.5l12.5-0.6h-0.6l-1.2-0.4
	c-4.3-1.6-6.6-4.3-7.4-7.8l-1.2-13.6c0-3.3,1.6-5.8,4.5-7.6c1.6-1.4,3.3-2.3,5.3-3.1c1-0.4,1.9-0.8,2.9-1L274.4,246.8L274.4,246.8z"
          />
          <path
            fill="none"
            d="M281.8,249.1c-2.9,1.9-4.3,4.5-4.5,7.6l1.2,13.6c0.6,3.7,3.1,6.2,7.4,7.8l1.2,0.4h0.6l2,0.2l-2.5-32.7
	C285.1,246.8,283.4,247.8,281.8,249.1L281.8,249.1z M290.2,245.2c-1,0.2-2.1,0.6-2.9,1l2.5,32.9l4.5-0.2l-2.1-33.7L290.2,245.2
	L290.2,245.2z M293.9,255.7c-0.2-5.3,0.6-9,2.3-11.1l-4.1,0.4l2.1,33.7l3.3-0.4c-1.4-0.6-2.1-2.1-2.5-4.7L293.9,255.7L293.9,255.7z
	 M309.9,245c-2,0.8-3.7,1.8-5.3,3.1c-2.9,1.9-4.3,4.7-4.5,8l1.2,14.4c0.6,3.9,3.1,6.6,7.2,8.2l1.4,0.4l1,0.2l1.6,0.2L309.9,245
	L309.9,245z M296.2,244.6h0.2H296.2z"
          />
          <path
            fill="none"
            d="M314.7,243.7c-1.8,0.2-3.3,0.8-4.9,1.4l2.5,34.5l4.5-0.2L314.7,243.7L314.7,243.7z M269.5,247
	c-1.8,0.2-3.3,0.6-4.9,1.2l2.5,32l4.7-0.2L269.5,247L269.5,247z M246.3,382.6l1.2-4.1c-9.2,3.3-16.6,7.6-22.4,13.1l21.2-7.8
	C246.3,383.4,246.1,383,246.3,382.6L246.3,382.6z"
          />
          <path
            fill="none"
            d="M273.2,391.2c-1.6-2-2.5-3.9-2.9-6h-13.1l-7.8-0.2c-1.4-0.2-2.3-0.6-2.9-1l-21.2,7.8c-4.1-11.7-6.8-23-8.4-33.7
	v71.1c-0.4,3.5,0.2,6.2,1.6,8.2c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2v-40.7
	C271.9,389.4,272.4,390.4,273.2,391.2L273.2,391.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M425.6,261.6h0.4 M426,261.6v-3.3h4.9 M430.9,258.3v-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M426,261.6h0.8v-2.9h4.1v-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M412.8,258.5c0-0.2,0.2-0.2,0.4-0.2h12.5v3.5H425v-3.1h-11.7v2.1h6v-0.6
	h-3.1v-0.4h3.7v1l-0.2,0.2h-7l-0.2-0.4L412.8,258.5L412.8,258.5z M413,257.5h17.9v0.4h-17.7l-0.6,0.2c-0.2,0-0.2,0.2-0.2,0.4v2.3
	c0,0.2,0,0.4,0.2,0.4l0.6,0.2h6.6l0.4-0.2l0.2-0.2v-1.2c0-0.2-0.2-0.4-0.6-0.4h-3.7v-0.4h4.1c0.4,0,0.6,0.2,0.6,0.4v1.4l-0.4,0.6
	l-0.8,0.2h-7.2c-0.4,0-0.8-0.2-1-0.4v-3.1c0-0.2,0.4-0.4,0.8-0.6L413,257.5L413,257.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M411.4,255.2c-1.6,1.6-2.3,3.3-2.3,5.3
	c0,2.5,1.2,4.7,3.7,6.6c2.3,1.8,5.3,2.7,8.8,2.7c3.5,0,6.4-1,8.8-2.7c2.3-1.8,3.7-4.1,3.7-6.6c0-2-0.8-3.7-2.3-5.3l-1.4-1.2
	c-2.3-1.8-5.5-2.7-8.8-2.7c-3.5,0-6.4,1-8.8,2.7L411.4,255.2L411.4,255.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M591.9,219.7l-2.7-1.9c-1-0.8-1.9-1.8-2.7-2.5l-2.1-2.7l-7-9.7
	c-3.9-4.9-6-9.9-6.4-15.2l-3.1-15 M561.1,150.1c-1.6-1.6-3.1-2.7-4.7-3.7c-1.4-0.8-2.5-1.2-3.3-1.2c-0.8,0-1.2,0.4-1.4,1.2
	 M551.7,146.4l-2.5-0.8 M565.5,155c-1,0.8-0.4,6.6,1.8,17.7 M564.4,153.6l0.8-0.2l0.6,0.4c0.2,0.2,0.2,0.4,0.2,0.6l-0.4,0.6
	 M564.4,153.6l-3.3-3.5 M547.8,164.3c0.8-6.4,1.2-12.7,1.4-18.7 M550,209.2c1.6,0.8,2.9,1.8,4.5,2.9l1.9,1.9
	c3.7,4.1,7.4,7.8,11.5,11.3 M534.8,245c-1.6-0.6-3.1-1-4.9-1.4 M524.8,243.1l-5.5-0.2 M519.4,242.9c1.4-3.1,2.9-5.5,5.3-7
	 M525.2,243.5l-0.4-0.4 M525.4,243.7l-0.2-0.2 M527.5,279.3l-3.3-0.4c1.4-0.6,2.1-2.1,2.5-4.9l1.4-18.9c0.2-5.5-0.6-9.2-2.7-11.3
	 M525.4,243.7l-0.4-0.2h0.2 M534.4,279.3l1.6-0.4c4.3-1.6,6.6-4.5,7.4-8.2l1.2-14.4c0-3.3-1.6-6-4.3-8c-1.6-1.4-3.3-2.3-5.3-3.1
	 M529.9,243.7l-4.7-0.2 M512.7,282.4c2.5-1.4,3.7-3.7,3.5-7.4l1.6-26.7c0.4-1.9,1-3.9,1.6-5.5 M548.2,244.6h0.2 M548.4,244.6h-0.2
	 M552.7,245l-4.3-0.4 M554.4,245.2l-1.8-0.2 M557.6,246c2,0.8,3.7,1.8,5.3,3.1c2.9,1.9,4.3,4.5,4.5,7.6l-1.2,13.6
	c-0.6,3.7-3.1,6.2-7.4,7.8l-2.3,0.4 M554.4,245.2c1,0.2,1.9,0.6,2.9,1 M548.4,244.6c1.8,1.9,2.5,5.6,2.3,11.1l-1.4,18.1
	c-0.2,2.5-1.2,4.1-2.5,4.7h0.8 M550,209.2c-3.1-1.6-6.2-2.5-9.7-2.5 M524.8,243.1l23.4,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601.4,225.5l-2-0.2c-4.9-1.9-8.4-3.9-10.5-5.5l-2.1-1.6l-1.9-1.9
	l-9.4-12.7c-4.1-5.3-6.4-10.5-6.8-16c-4.9-21.4-6.2-32.9-4.3-34.5 M596.9,222l4.5,3.5 M613.9,224.9l-1.8,0.2l-6.4,1.6 M613.5,218.7
	l1-7.4c0.2-4.5,2.1-6.8,6-6.8l17.5,1.4c4.9-0.2,8.2,2.1,9.7,7.2c1,2.5,1.6,4.9,1.6,6.6c0,1,0,1.8-0.2,2.3c-0.6,1.6-1.6,2.7-3.3,3.3
	l-23.6,2.9h-0.6c-2-0.2-3.9-1.6-5.7-3.9C614.3,222.6,613.5,220.9,613.5,218.7L613.5,218.7z M613.1,231.6c-1.2-1.4-2.3-2.3-3.3-3.1
	c-1.4-1.2-2.7-1.8-3.9-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M620.9,229.4l26.3-3.1c1.9-0.6,3.1-1.9,3.7-3.7c0.2-0.8,0.4-1.8,0.4-2.7
	c0-1.9-0.6-4.5-1.6-7.4c-1.8-5.5-5.5-8.2-10.9-8l-19.5-1.6c-4.1,0-6.4,2.5-6.6,7.6l-1,8.2c0,2.5,0.8,4.5,2.5,6 M605.7,226.7
	c-1.4-0.6-2.7-1-4.3-1 M568.5,226.9l0.6,1.8v10.5 M567.9,225.7l0.6,1.2 M569.1,239.2c10.9,1.6,17.7,4.9,20.3,9.7
	c4.3,6,6,13.1,5.3,21 M571,246.8h-0.8l-15.8-1.6 M575.1,247l-4.1-0.2 M580,248.1c-1.6-0.6-3.1-1-4.9-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.4,279.9l2.5-0.2l1.4-0.4c4.3-1.6,6.6-4.1,7.2-7.6l1.2-13.3
	c0-3.1-1.6-5.6-4.5-7.4c-1.6-1.4-3.5-2.3-5.3-2.9 M571,246.8c1.8,1.9,2.5,5.7,2.3,10.7L572,275c-0.2,2.5-1.2,3.9-2.5,4.5h1
	 M633.4,304.7l2.1-20.9c0.2-9.9-0.6-16-2.3-18.1l-17.7-30.8c-0.6-1-1.4-1.9-2.1-2.9l-0.4-0.4 M591.9,219.7c1.8,1,3.5,1.8,5.3,2.3
	 M602.2,270.7c1-8.6-1.6-17.5-7.8-27.1c-5.5-8.6-14.2-14.2-25.9-17 M567.3,172.7c8.2,12.3,16.4,27.9,24.6,47.2 M540.2,206.6
	c3.7-14.8,6.2-28.8,7.6-42.3 M551.7,146.4c3.1,7.8,2.5,28.8-2,62.9 M524.4,235.9c15.8,0.8,30.6,1.9,44.4,3.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M596.9,222c-6.8-19.1-14-36.6-22-52.4c-3.1-5.8-6.2-10.7-9.5-14.6
	 M567.9,225.7c-4.9-35.5-7.2-60.6-6.6-75.4 M613.1,231.6l7-1.9l0.8-0.2 M620.9,229.4c-2.5,0-4.9-1.4-7-4.5 M549.2,145.6
	c-15.2-4.7-33.9-7.6-56.1-9.2l-13.1,0.8l-30.8-1.4l-20.5-0.2l-33.9,0.4l-30,1.4l-13.1-0.8c-22.2,1.6-40.9,4.5-56.1,9.2 M397,153.4
	h13.3 M325.3,242.9l-6,0.2 M320.2,235.9c2.1,1.6,3.9,3.9,5.3,7 M325.3,242.9c0.6,1.6,1.2,3.5,1.6,5.5l1.6,26.7
	c-0.4,3.5,0.8,6,3.3,7.4 M319,243.5l0.2-0.4 M317.1,279.3l3.3-0.4c-1.4-0.6-2.1-2.1-2.5-4.9l-1.4-18.9c-0.2-5.6,0.6-9.6,2.3-11.5
	 M435.2,261.2v-0.8c0-2.7-1.4-5.3-4.1-7.2c-2.7-1.9-5.9-2.9-9.7-2.9c-3.7,0-7,1-9.5,2.9c-2.7,1.9-3.9,4.5-3.9,7.2v0.8 M519.4,242.9
	c-24.2-1.2-51.4-1.8-81.7-1.6h-30.4c-30.4,0-57.7,0.4-81.9,1.6 M524.4,235.9c-28.5-1.6-60.2-2.3-95.1-2.1h-7.2
	c-7.8,0-15.6,0-23.2,0.2c-28.6,0-55,0.6-78.9,1.9 M304.4,206.6c5.3,3.5,23.6,4.5,55.2,2.5l38-1.6l31.8-0.4l55.5,1.9
	c31.6,1.8,50.1,1,55.1-2.5 M410.2,153.6h19.3c56.5,0.6,95.9,4.3,118.5,10.7 M502.6,323.8l-16.4-23c-2.9-3.5-7-5.3-12.7-5.5H371.1
	c-5.7,0.2-9.7,2.1-12.7,5.5l-16.4,23"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M450.6,298.6h17.9c7-0.8,12.9,1.4,17.3,6.4l17,18.7 M435.2,261.2v0.8
	c0,2.9-1.4,5.3-4.1,7.2c-2.7,2-5.9,2.9-9.7,2.9c-3.7,0-7-1-9.5-2.9c-2.7-2-3.9-4.5-3.9-7.2v-0.8c0.2,2.5,1.6,4.7,3.9,6.4
	c2.7,2,5.8,2.9,9.5,2.9c3.7,0,7-1,9.7-2.9C433.6,265.9,435,263.7,435.2,261.2L435.2,261.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M394.2,298.6c-2.3,0-4.1,1.8-5.3,5.3v22.6c0,2.3,1.6,3.3,4.5,2.7h57.9
	c2.9,0.6,4.5-0.4,4.5-2.7v-22.6c-1-3.5-2.7-5.3-5.3-5.3 M476.7,361h-5.1c-7.6,0-13.1,2.3-16.4,7l-5.3,5.8c-2.5,2-7.4,2.9-14.6,2.7
	h-25.5c-7.4,0.2-12.3-0.6-14.6-2.7L390,368c-3.3-4.7-9-7-16.4-7h-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M391.3,364.1l5.3,6.8c1.6,2,5.5,2.9,11.7,3.3h28.1
	c6.2-0.2,10.1-1.4,11.7-3.3l5.3-6.8 M361.9,383h14.6c7.4,0.4,12.9,1.6,16.8,3.5c4.9,1.4,11.7,2,20.3,2.1h15.8
	c9.4,0,16.6-0.6,21.6-2.1c3.7-1.9,9.4-3.1,16.8-3.5h14.6 M482.7,383v-16.2c-0.2-3.7-2.3-5.7-6-5.8 M535.9,387.9l-11.5-17.9
	c-4.3-5.6-11.5-8.6-21.4-8.8h-4.1 M493.2,383h13.8 M498.9,361c-3.9,1.4-5.7,4.3-5.7,8.8V383 M508.6,383.2l-1.6-0.2 M498.9,361h-22.2
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M453.3,364.1c3.9-6.2,9.4-9.4,16.2-9.2h36.1c9.9,0.6,17,3.9,20.9,9.9
	l14.6,22.8 M342,323.8l17-18.7c4.5-5.1,10.3-7.4,17.3-6.4h17.9 M303.4,387.9l14.6-22.8c3.9-6,10.9-9.4,20.9-9.9H375
	c6.8-0.2,12.3,2.9,16.2,9.2 M336,383.2l1.6-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M345.7,361h-4.1c-10.1,0.2-17.1,3.3-21.4,8.8l-11.5,17.9 M351.4,383v-13.3
	c0-4.5-1.8-7.4-5.6-8.8 M368.1,361c-3.9,0.2-5.8,2.1-6,5.8V383 M337.5,383h13.8 M318.8,349.9c-0.4,1.6-1,2.7-2,3.7 M368.1,361h-22.4
	 M400.7,413.8l2.3-2.5 M403,411.3l-11.9-8l-6.2,4.3h-9.7 M375.1,407.6l5.3,6.2 M375.1,407.6l5.3,6.2 M380.4,413.8h20.3 M358.2,405.8
	l1,1.8h16 M316.7,397.4c11.1-1.4,17.5-3.9,19.3-7.6c1.2-2,1.8-4.1,1.6-6.6 M316.7,397.4h-0.4l-1.2,0.2 M417.2,401.5h-86.9l-13.6-4.1
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M358.2,405.8l-35.1-2.1l-8-6 M469.5,407.6h16l1-1.8 M443.9,413.8h20.3
	l5.3-6.2 M469.5,407.6h-9.7l-6-4.3l-12.1,8 M441.6,411.3l2.3,2.5 M427.6,401.5h-10.3 M441.6,411.3l-14-9.7 M527.9,397.4l-13.4,4.1
	h-86.9 M400.7,413.8h43.3 M351.4,383c1,4.7,2.9,6.8,5.8,6.4c1.6-0.2,3.1-2.3,4.7-6.4 M507.1,383c0,2.5,0.6,4.7,1.6,6.6
	c1.8,3.9,8.2,6.4,19.3,7.6 M482.7,383c1.6,4.3,3.1,6.4,4.7,6.4c2.9,0.2,4.9-2,5.8-6.4 M403,411.3l14.2-9.7 M331.9,282.4h180.9
	 M336,383.2c-7.4,0.2-13.6,1.8-18.9,4.7h-8.2 M391.3,364.1h62 M394.2,298.6h56.3 M602.2,270.7c-0.2,1.8-0.6,3.7-1.2,5.5
	c-1.6,9.4-8,14.8-19.5,16.2c-31.4,1.2-64.9,1.4-100.9,0.8H363.8c-36.1,0.6-69.6,0.4-101-0.8c-11.3-1.4-17.7-6.8-19.5-16.2
	c-0.6-1.8-0.8-3.5-1.2-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M633.4,304.7l-13.4-2.1c-4.5,0-7.2-1.8-8.2-5.3
	c-2.3-11.5-5.5-20.3-9.5-26.5 M619.3,352l-6.2-0.8c-2.7-1.4-4.1-3.7-3.9-7l10.9,2.5L619.3,352L619.3,352z M627.9,357.9l5.5-53.2
	 M594.6,270l7.6,0.8 M572.8,279.9h4.7 M570.4,279.5l2.3,0.4 M594.6,270c-0.6,7.8-6,11.9-16.6,12.3h-65.3 M554.8,278.9h1.6
	 M550.3,278.7l4.5,0.2 M547.6,278.5l2.7,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M532.2,279.5l2-0.2h0.2 M527.5,279.3l4.7,0.2 M547.6,278.5l-13.3,0.8
	 M581.7,357.5h-44.2c-4.7-0.2-8-1.6-9.9-3.7 M553.9,371.5c1.6-1.6,3.1-2.1,5.3-2.1c1.9,0,3.7,0.8,5.3,2.1c1.4,1.6,2.1,3.1,2.1,5.3
	c0,1.8-0.6,3.3-1.6,4.7l-0.6,0.6c-1.6,1.4-3.1,2.1-5.3,2.1c-2,0-3.7-0.8-5.3-2.1c-1.6-1.6-2.1-3.1-2.1-5.3c0-1.6,0.4-2.7,1.2-3.9
	L553.9,371.5L553.9,371.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M574.3,376.6V376c0-3.9-1.6-7.2-4.3-10.1c-2.9-2.9-6.4-4.3-10.5-4.3
	c-4.1,0-7.6,1.4-10.3,4.3c-1.8,1.8-2.9,3.5-3.7,5.6c-0.4,1.4-0.8,2.9-0.8,4.7v0.4c0,0.6,0,1.2,0.2,1.8c0.4,3.5,1.2,6.6,2.5,9.4h-6.2
	 M527.7,353.8c-0.8-1-1.6-2.3-1.8-3.7 M525.8,349.9h18.1c19.3,0.4,31,2.3,35.5,5.5l0.2,0.2l1.9,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.7,378.5c2.9-8.4,6.6-15.8,11.3-22c1.2-1.4,2.3-2.3,3.7-3.5l0.6-0.4
	c4.7-3.7,17.1-5.6,37.6-6.2h243.4c20.3,0.4,32.9,2.5,37.6,6.2l0.6,0.4c1.4,1,2.5,2.1,3.7,3.5h0.2c4.7,6.2,8.6,13.6,11.3,22"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M595.4,384.9l-0.8-2.3c-2.7-9.2-6.6-17.1-11.7-23.8l-1.2-1.4 M574.3,376.6
	v8.6 M508.6,383.2c2.1,4.7,11.5,7.6,27.9,8.4c22.2-0.2,34.3-3.5,36.6-9.5c0.8-1.6,1.2-3.3,1.2-5.3 M596.9,378.5l1.2,4.1
	c0.2,0.4,0.2,0.8,0,1.2 M556.4,278.9l14,0.6 M574.1,385.1c-0.2,2.1-1.2,4.1-2.9,6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M595.4,384.9l-7.8,0.2h-13.4 M573.1,406.2v-17.5c-0.4,1-1,2-1.8,2.7
	 M573.1,406.2v23c-0.4,3.5,0.2,6.2,1.6,8.2c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2v-71.3
	 M541.2,387.9h-5.3 M535.9,387.9h-8.2c-5.3-2.9-11.5-4.5-18.9-4.7 M529.5,397.6l-1.2-0.2h-0.4 M571.4,391.2
	c-4.5,4.3-16.2,6.6-34.7,6.8l-7.2-0.6 M529.5,397.6l-8,6l-35.1,2.1 M342,323.8h-86.7c-17.7,2.1-28.6,8.8-32.5,19.7
	c15.2-3.5,32.4-5.1,51.5-4.7h296.2c19.1-0.4,36.2,1.2,51.5,4.7c-4.1-10.9-14.8-17.5-32.5-19.7h-86.5 M598.3,383.8
	c-0.4,0.4-1.4,0.8-2.9,1 M619.5,391.6c-5.8-5.5-13.4-9.7-22.4-13.1 M627.9,357.9c-1.6,10.7-4.5,22-8.4,33.7 M619.5,391.6l-21.2-7.8
	 M568.5,226.9c-40.9-4.9-89.6-6.8-146.2-6.2c-56.5-0.6-105.2,1.4-146,6.2 M529.9,243.7l-2.3,35.7 M554.8,278.9l2.7-32.9
	 M486.4,405.8l86.7,0.4 M527.9,397.4H316.7 M316.9,353.8h210.9 M525.8,349.9h-207 M532.2,279.5l2.5-34.5 M572.8,279.9l2.3-32.9
	 M577.4,279.9l2.5-31.8 M550.3,278.7l2.3-33.7 M230.7,224.9c1.6-1.6,2.5-3.7,2.5-6l-1-8.2c-0.2-5.1-2.3-7.6-6.6-7.6l-19.5,1.6
	c-5.5-0.2-9,2.3-10.9,8c-1.2,2.9-1.6,5.3-1.8,7.4c0,1,0,1.9,0.4,2.7c0.6,1.8,1.8,3.1,3.7,3.7l26.3,3.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M197,212.9c-1,2.5-1.6,4.9-1.6,6.6c0,1,0,1.8,0.4,2.3
	c0.4,1.6,1.6,2.7,3.3,3.3l23.6,2.9h0.8c1.9-0.2,3.9-1.6,5.6-3.9c1.4-1.6,2.1-3.3,2.1-5.5l-1-7.4c-0.2-4.5-2.1-6.8-5.8-6.8l-17.5,1.4
	C201.9,205.7,198.6,208,197,212.9L197,212.9z M292.9,146.4c-0.2-0.8-0.6-1.2-1.4-1.2s-2,0.4-3.3,1.2c-1.6,1-3.1,2.3-4.7,3.7
	 M295.4,145.6l-2.5,0.8 M295.4,145.6c0.2,6,0.6,12.3,1.4,18.7 M280.2,153.6l-0.8-0.2l-0.6,0.4c-0.2,0.2-0.2,0.4-0.2,0.6
	s0.2,0.4,0.4,0.6 M283.6,150.1c-1,1-2.1,2.1-3.1,3.5 M277.3,172.7c2.1-11.1,2.9-17,2-17.7 M252.8,219.7l2.7-1.9l2.7-2.5l2.1-2.7
	l7-9.7c3.9-4.9,6-9.9,6.6-15.2l3.1-15 M279.1,155c-3.1,3.9-6.4,8.8-9.5,14.6c-7.8,15.8-15.2,33.3-22,52.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M243.2,225.5l1.9-0.2c4.9-1.9,8.4-3.9,10.5-5.5l2.1-1.6l2-1.9l9.4-12.7
	c4.1-5.3,6.4-10.5,6.8-16c4.9-21.4,6.2-32.9,4.3-34.5 M247.7,222l-4.5,3.5 M247.7,222c1.8-0.6,3.5-1.4,5.3-2.3 M223.7,229.4l0.8,0.2
	l7.2,1.9 M243.2,225.5c-1.6,0.2-2.9,0.6-4.1,1 M238.9,226.7c-1.2,0-2.5,0.6-3.9,1.8c-1,0.8-2.1,1.9-3.3,3.1 M238.9,226.7l-6.4-1.6
	l-1.8-0.2 M223.7,229.4c2.5,0,4.9-1.4,7-4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231.7,231.6l-0.4,0.4c-0.8,0.8-1.4,1.8-2.1,2.9l-17.5,30.8
	c-1.8,2.3-2.5,8.4-2.3,18.1l2.1,20.9 M264.6,248.1c-2,0.8-3.7,1.8-5.3,2.9c-2.9,1.8-4.3,4.3-4.5,7.4l1.2,13.3c0.6,3.5,3.1,6,7.4,7.6
	l1.2,0.4l2.5,0.2 M294.9,209.2c2.9-1.6,6.2-2.3,9.7-2.5 M276.7,225.7c4.1-3.5,7.8-7.4,11.5-11.3l2-1.9c1.4-1.2,2.9-2.1,4.5-2.9
	 M276.1,226.9l0.6-1.2 M275.6,239.2v-10.5l0.6-1.8 M273.8,246.8l-4.3,0.2 M290.2,245.2l-15.8,1.6h-0.6 M287.1,246
	c-1.9,0.8-3.7,1.8-5.3,3.1c-2.9,1.9-4.3,4.5-4.5,7.6l1.2,13.6c0.6,3.7,3.1,6.2,7.4,7.8l1.2,0.4h0.6 M290.2,245.2
	c-1,0.2-2.1,0.6-2.9,1 M275.2,279.5c-1.4-0.6-2.1-2-2.5-4.5l-1.4-17.5c-0.2-5.3,0.6-8.8,2.3-10.7 M297.6,278.3
	c-1.4-0.6-2.1-2.1-2.5-4.7l-1.4-18.1c-0.2-5.3,0.6-9,2.3-11.1 M309.9,245c-2,0.8-3.7,1.8-5.3,3.1c-2.9,1.9-4.3,4.7-4.5,8l1.2,14.4
	c0.6,3.9,3.1,6.6,7.2,8.2l1.4,0.4l1,0.2 M296.2,244.6h0.2 M296.4,244.6h-0.2 M296.2,244.6l-4.1,0.4 M314.7,243.7
	c-1.8,0.2-3.3,0.8-4.9,1.4 M292.1,245l-2,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250,270c-0.8-8,1-15,5.3-21c2.5-4.9,9.4-8.2,20.3-9.7 M269.5,247
	c-1.8,0.2-3.3,0.6-4.9,1.2 M296.8,164.3c1.4,13.4,3.9,27.7,7.6,42.3 M252.8,219.7c8.2-19.1,16.4-34.9,24.6-47.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242.4,270.7c-1-8.6,1.6-17.5,7.6-27.1c5.5-8.6,14.2-14.2,25.9-17
	 M294.9,209.2c-4.5-34.1-5.1-55-2-62.9 M276.7,225.7c4.9-35.5,7.2-60.6,6.6-75.4 M297.6,278.3l13.3,1 M294.3,278.7l3.3-0.4
	 M310.8,279.3l1.6,0.2 M287.8,278.9h2 M275.2,279.5l12.7-0.6 M271.9,279.9l3.3-0.4 M267.2,279.9h4.7 M289.8,278.9l4.5-0.2
	 M242.4,270.7l7.6-0.8 M211.3,304.7l13.4-2.1c4.5,0,7.2-1.8,8.2-5.3c2.3-11.5,5.5-20.3,9.6-26.5 M235.4,344l-10.9,2.5l0.8,5.3
	l6.2-0.8C234.4,349.7,235.6,347.3,235.4,344L235.4,344z M262.9,357.5l2-1.8c0,0,0-0.2,0.2-0.2c4.5-3.1,16.4-5.1,35.5-5.5h18.1
	 M249.3,384.9l0.8-2.3c2.7-9.2,6.6-17.1,11.7-23.8l1.2-1.4 M246.5,383.8c-0.2-0.4-0.2-0.8,0-1.2l1.2-4.1 M270.3,376.6V376
	c0-3.9,1.6-7.2,4.3-10.1c2.9-2.9,6.4-4.3,10.5-4.3c4.1,0,7.6,1.4,10.5,4.3c1.8,1.8,2.9,3.5,3.7,5.6c0.4,1.4,0.8,2.9,0.8,4.7
	l-0.2,2.1c-0.4,3.5-1.4,6.6-2.5,9.4h6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M270.3,376.6c0,2,0.4,3.7,1.2,5.3c2.1,6,14.4,9.4,36.6,9.5
	c16.4-0.8,25.7-3.5,27.9-8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M270.5,385.1c0-0.6-0.2-1.4,0-2.1v-6.4 M276.7,379.7
	c0.4,1.4,1.2,2.5,2.1,3.5c1.8,1.8,4.1,2.7,6.6,2.7s4.7-1,6.4-2.7s2.7-3.9,2.7-6.4c0-2.5-1-4.7-2.7-6.6l-0.8-0.6
	c-1.6-1.4-3.7-2.1-5.8-2.1c-2.5,0-4.7,1-6.6,2.7c-1.8,1.8-2.7,4.1-2.7,6.6C276.1,377.7,276.3,378.9,276.7,379.7L276.7,379.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M279.7,381.4l0.6,0.6c1.6,1.4,3.1,2.1,5.3,2.1c2,0,3.7-0.8,5.3-2.1
	c1.6-1.6,2.1-3.1,2.1-5.3c0-1.6-0.4-2.7-1.2-3.9l-1-1.4c-1.4-1.6-3.1-2.1-5.3-2.1c-2,0-3.7,0.8-5.3,2.1c-1.6,1.6-2.1,3.1-2.1,5.3
	C278.1,378.5,278.7,380.1,279.7,381.4L279.7,381.4z M331.9,282.4h-65.3c-10.5-0.4-16-4.5-16.4-12.3 M316.9,353.8
	c-2,2.3-5.3,3.5-9.9,3.7h-44.2 M308.7,387.9h-5.3 M315.1,397.6l-7.2,0.6c-18.7-0.2-30.2-2.5-34.7-6.8 M273.2,391.2
	c-1.6-2-2.5-3.9-2.9-6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M273.2,391.2c-0.8-0.8-1.6-1.8-1.8-2.7V406 M216.7,357.9v71.3
	c-0.4,3.5,0.2,6.2,1.6,8.2c1.4,1.8,3.7,2.7,6.8,2.9h37.8c3.1,0,5.5-1,6.8-2.9c1.4-1.8,2-4.5,1.6-8.2V406 M270.5,385.1h-13.3l-8-0.2
	 M211.3,304.7l5.5,53.2 M249.3,384.9c-1.4-0.2-2.3-0.6-2.9-1 M247.7,378.5c-9.2,3.3-16.6,7.6-22.4,13.1 M246.5,383.8l-21.4,7.8
	 M225.1,391.6c-4.1-11.7-6.8-23-8.4-33.7 M312.4,279.5l-2.5-34.5 M294.3,278.7l-2.1-33.7 M289.8,278.9l-2.7-32.9 M271.9,279.9
	l-2.3-32.9 M267.2,279.9l-2.5-31.8 M271.5,406.2l86.7-0.4 M275.6,239.2c13.8-1.4,28.6-2.5,44.4-3.5 M312.4,279.5l4.7-0.2
	 M296.8,164.3c21.8-6.2,59.6-9.9,113.4-10.7 M319,243.5l-4.3,0.2 M317.1,279.3l-2.3-35.7 M319.2,243.1l-22.8,1.6 M553.3,369.6
	c1.6-1.4,3.7-2.1,5.8-2.1c2.5,0,4.7,1,6.6,2.7c1.8,1.8,2.7,4.1,2.7,6.6c0,1-0.2,2-0.4,2.9c-0.4,1.4-1.2,2.5-2.3,3.5
	c-1.8,1.8-4.1,2.7-6.6,2.7s-4.7-1-6.6-2.7c-1.8-1.8-2.7-3.9-2.7-6.4c0-2.5,1-4.7,2.7-6.6L553.3,369.6L553.3,369.6z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M304.4,206.6
		c0,0-5.7-22.4-7.6-42.3c0,0,25.7-10.7,125.7-10.7c100,0,125.5,10.7,125.5,10.7s-2.1,22.6-7.6,42.3c0,0-6,5.7-53.8,2.7l-55.2-1.9
		l-19.1,0.2l-13.6,0.2l-43.5,1.8C355.1,209.6,315.1,212.3,304.4,206.6z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M275.6,239.2v-10.5
		c0,0,0.4-2.3,2.1-3.9c0,0,5.3-4.7,12.5-12.7c0,0,5.7-5.5,14.2-5.7c0,0,7.6,4.9,43.1,3.3l37.4-1.8l12.7-0.6l32-0.2h3.1l56.5,1.9
		c0,0,41.7,2.9,51.1-2.9c0,0,8,0,14,5.7c0,0,10.5,11.5,13.4,13.1l1.2,3.3v10.5c0,0-22-2.3-44.4-3.5c0,0-4.9,2.7-6.8,12.5l-1.6,26.3
		c0,0,0.8,6.2-3.5,7.8H331.7c0,0-4.1-1.8-3.3-7.4l-1.6-26.7c0,0-1.2-8.8-6.8-12.5C320.2,235.9,292.3,237.4,275.6,239.2z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M319.2,243.1
		c0,0-3.3,2.9-2.5,12.3l1.2,17.7c0,0,0,4.1,2.3,5.8l-7.6,0.6l-14.8-1l-3.3,0.4l-4.7,0.2l-2.5-0.2l-12.1,0.6l-3.1,0.4l-4.7,0.2
		c0,0-9.2,0.2-11.1-8.2l-1.2-13.3c0,0-0.2-4.9,4.5-7.4c0,0,5.3-3.9,10.3-4.1l4.7-0.2C274.4,246.8,296.8,244,319.2,243.1z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M524.8,243.1
		c0,0,3.9,2.9,3.3,11.7l-1.4,19.3c0,0-0.2,3.5-2.5,4.9l3.3,0.4l4.5,0.2l2.5-0.2l12.9-0.8l2.7,0.4l4.5,0.2h3.3l12.3,0.8l2.3,0.2
		l4.5,0.2c0,0,9.4,0.2,11.1-8.2l1.2-13.6c0,0,0.2-4.5-4.3-7c0,0-3.9-3.5-10.3-4.1H571C571.4,247,551.5,244.2,524.8,243.1z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M242.4,270.7l7.6-0.8
		c0,0-0.6,11.7,15.8,12.3H578c0,0,16.2,0.4,16.6-12.3l7.6,0.8c0,0,5.8,8,9.5,26.5c0,0,1,5.7,8.2,5.3l13.4,2.1l-4.7,47.2
		c0,0-1,16.6-9.2,39.6l-21.2-7.8c0,0-1,1-2.9,1c-1.9,0-7.4,0.2-7.4,0.2h-13.8v-8.6c0,0,0.6-5.5-3.7-9.9s-8-5.1-11.5-5.1
		c0,0-8.2-0.6-13.1,8.2c0,0-2.5,3.9-1,10.9c0,0,1,5.1,2.1,7h-19.7c0,0-7-4.7-20.7-4.7h-13.8c0,0-1.2,6.4-5.3,6.6
		c0,0-2.7,1.2-5.3-6.6h-14.6c0,0-10.5,0-16.8,3.5c0,0-5.8,2.1-18.3,2.1h-17.5c0,0-11.5,0.6-21.8-2.1c0,0-5.7-3.1-16.6-3.5h-15
		c0,0-2.1,6.8-5.3,6.6c-3.1-0.4-4.3-2.7-5.3-6.6h-15.6c0,0-11.3,0-18.9,4.7H297c0,0,3.7-7.6,2.3-15.2c-1.4-7.6-9.5-11.1-14.4-10.9
		c-5.1,0.4-12.5,2.3-14.6,12.7v1.2l0.2,9.6h-13.3l-7.8-0.2c0,0-2.1-0.4-2.9-1l-21.2,7.8c0,0-6-17.5-8.4-33.7l-5.3-53.2l13.4-2.1
		c0,0,6.6,0.6,8.2-5.3C233.1,297.4,236,280.5,242.4,270.7z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe2DrRear);
