import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { confirmable } from 'react-confirm';

class ConfirmDialog extends Component {
  render() {
    const {
      okLabel = 'OK',
      cancelLabel = 'Cancel',
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      enableEscape = true,
      message = '',
      hideCancel = false,
      hideOk = false,
    } = this.props;

    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={dismiss}
          backdrop={enableEscape ? true : 'static'}
          keyboard={enableEscape}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <div style={{ padding: 15 }}>
            <Modal.Body style={{ padding: 0 }}>{confirmation}</Modal.Body>
            {message && (
              <Modal.Body style={{ padding: 0 }}>{message}</Modal.Body>
            )}
          </div>
          <Modal.Footer>
            {!hideCancel && <Button onClick={cancel}>{cancelLabel}</Button>}
            {!hideOk && (
              <Button className="button-l" bsStyle="primary" onClick={proceed}>
                {okLabel}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  cancel: PropTypes.func, // called when cancel button is clicked.
  dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
  enableEscape: PropTypes.bool,
};

export default confirmable(ConfirmDialog);
