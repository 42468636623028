import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsVerified, updateItem } from '../../../../../../actions';
import SelectMenu from '../../../ui/selectMenu';
import { showRow } from '../../../../utils/helpers';
class SelectDetails extends Component {
  state = {
    source: {},
    isVerified: false,
    openVerifyModal: false,
    verifyModalTitle: '',
    verifyModalMessage: '',
    verifyModalBtnText: '',
    verifyModalAction: null,
  };

  render() {
    const {
      handleSectionVerified,
      handleValue,
      isVerified,
      getDefaultValue,
      handleBodyStyle,
      source,
      coreSections,
    } = this.props;

    return (
      <div className="form-body">
        {showRow('s1:r1', coreSections) && (
          <div onClick={handleSectionVerified}>
            <SelectMenu
              title="Body"
              defaultValue={getDefaultValue('bodyStyle')}
              options={
                source.bodyStyles &&
                source.bodyStyles.map(bodyStyles => ({
                  value: bodyStyles,
                  label: bodyStyles,
                }))
              }
              onChangeHandler={e => handleBodyStyle(e)}
              hideIndicatorSeparator={true}
              isDisabled={isVerified}
            />
          </div>
        )}
        {showRow('s1:r2', coreSections) && (
          <div onClick={handleSectionVerified}>
            <SelectMenu
              title="Transmission"
              defaultValue={getDefaultValue('transmission')}
              options={
                source.transmissions &&
                source.transmissions.map(transmission => ({
                  value: transmission,
                  label: transmission,
                }))
              }
              onChangeHandler={e => {
                handleValue('transmission', e.value);
              }}
              hideIndicatorSeparator={true}
              isDisabled={isVerified}
            />
          </div>
        )}
        {showRow('s1:r3', coreSections) && (
          <div onClick={handleSectionVerified}>
            <SelectMenu
              title="Engine"
              defaultValue={getDefaultValue('engine')}
              options={
                source.engines &&
                source.engines.map(engine => ({
                  value: engine,
                  label: engine,
                }))
              }
              onChangeHandler={e => {
                handleValue('engine', e.value);
              }}
              hideIndicatorSeparator={true}
              isDisabled={isVerified}
            />
          </div>
        )}
        {showRow('s1:r4', coreSections) && (
          <div onClick={handleSectionVerified}>
            <SelectMenu
              title="Driveline"
              defaultValue={getDefaultValue('driveline')}
              options={
                source.drives &&
                source.drives.map(drive => ({
                  value: drive,
                  label: drive,
                }))
              }
              onChangeHandler={e => {
                handleValue('driveline', e.value);
              }}
              hideIndicatorSeparator={true}
              isDisabled={isVerified}
            />
          </div>
        )}
        {showRow('s1:r5', coreSections) && (
          <div onClick={handleSectionVerified}>
            <SelectMenu
              title="Cylinders"
              defaultValue={getDefaultValue('cylinder')}
              options={
                source.cylinders &&
                source.cylinders.map(cylinder => ({
                  value: cylinder,
                  label: cylinder,
                }))
              }
              onChangeHandler={e => {
                handleValue('cylinder', e.value);
              }}
              hideIndicatorSeparator={true}
              isDisabled={isVerified}
            />
          </div>
        )}
      </div>
    );
  }
}

SelectDetails.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { variant, vin, item, isVerified, coreSections } = state.core;
  return { variant, vin, item, isVerified, coreSections };
};

export default connect(
  mapStateToProps,
  {
    updateItem,
    setIsVerified,
  }
)(SelectDetails);
