import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { loadLots, updateItem } from '../../../../actions';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import { connect } from 'react-redux';
import InputField from '../ui/inputField';
import SelectMenu from '../ui/selectMenu';
import {
  CYAN_01,
  BLACK_02,
  GREY_05,
  BLACK_03,
  RED_01,
} from '../../constants/colors';
import { BODY_STYLES, HEAVY_TRUCK_BODYSTYLES } from '../../constants/common';

let styles = {};

const defaultState = {
  year: '',
  make: '',
  model: '',
  trim: '',
  bodyStyle: '',
  heavyTruckOption: false,
  Heavytrk: false,
};
class VehicleInformationModal extends Component {
  state = defaultState;

  componentDidUpdate(prevProps) {
    // on open
    if (!prevProps.isOpen && this.props.isOpen) {
      const { item } = this.props;
      const bodyStyle =
        BODY_STYLES.find(bodyStyle => bodyStyle === item.bodyStyle) || '';
      const isHeavyTruck = HEAVY_TRUCK_BODYSTYLES.includes(bodyStyle);

      this.setState({
        year: item.year || '',
        make: item.make || '',
        model: item.model || '',
        trim: item.trim || '',
        bodyStyle,
        heavyTruckOption: item.heavyTruckOption || isHeavyTruck,
        Heavytrk: item.Heavytrk || isHeavyTruck,
      });
    }

    // on close
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(defaultState);
    }
  }

  handleYearChange = e => {
    const input = e.target.value;
    const re = /^\d*(\.\d{0,2})?$/;
    if (re.test(input)) {
      this.setState({ year: input });
    }
  };

  handleModelChange = e => {
    this.setState({ model: e.target.value });
  };

  handleTrimChange = e => {
    this.setState({ trim: e.target.value });
  };

  handleMakeChange = e => {
    this.setState({ make: e.target.value });
  };

  onChangeBodyStyle = option => {
    const isHeavyTruck = HEAVY_TRUCK_BODYSTYLES.includes(option.value);

    this.setState({
      bodyStyle: option.value,
      heavyTruckOption: isHeavyTruck,
      Heavytrk: isHeavyTruck,
    });
  };

  formatOptionLabel = ({ label }) => (
    <div className="flex flex-col p-1\/2 ">
      <div className="fs-14">{label}</div>
    </div>
  );

  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-10">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>
        <div
          className="pl-4 fs-20 font-semibold"
          style={{ color: this.props.disableEdit ? RED_01 : BLACK_02 }}
        >
          Vehicle Information
        </div>

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderButtons() {
    if (this.props.disableEdit) {
      return null;
    }

    const { make, model, year, trim, bodyStyle, heavyTruckOption, Heavytrk } =
      this.state;
    const updatedItem = {
      year,
      make,
      model,
      trim,
      bodyStyle,
      heavyTruckOption,
      Heavytrk,
      nonStandardVin: true,
    };

    return (
      <div>
        <div className="modal-mt">
          <Button
            btnText="Next"
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={() => this.props.onNext(updatedItem)}
          />
        </div>
        <div className="modal-mt">
          <Button
            btnText="Cancel"
            outlined={true}
            outlinedTextColor={BLACK_03}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onClose}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isOpen } = this.props;
    const { make, model, year, trim, bodyStyle } = this.state;

    return (
      <div>
        <Modal
          isOpen={isOpen}
          shouldCloseOnEsc
          style={styles.customStyles}
          contentLabel={'vehicleInformation'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            {this.renderHeader()}
            <div className="">
              <label
                className="justify-start ml-4 fs-16 text-base font-medium"
                style={{ color: BLACK_03 }}
              >
                Enter Year
              </label>
              <div className="mx-2">
                <InputField
                  placeholder="Enter Year"
                  onChangeHandler={this.handleYearChange}
                  value={year}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                className="justify-start ml-4 fs-16 text-base font-medium"
                style={{ color: BLACK_03 }}
              >
                Enter Make
              </label>
              <div className="mx-2">
                <InputField
                  placeholder="Enter Make"
                  onChangeHandler={this.handleMakeChange}
                  value={make}
                />
              </div>
            </div>

            <div className="mt-4">
              <label
                className="justify-start ml-4 fs-16 text-base font-medium"
                style={{ color: BLACK_03 }}
              >
                Enter Model
              </label>
              <div className="mx-2">
                <InputField
                  placeholder="Enter Model"
                  onChangeHandler={this.handleModelChange}
                  value={model}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                className="justify-start ml-4 fs-16 text-base font-medium"
                style={{ color: BLACK_03 }}
              >
                Enter Trim
              </label>
              <div className="mx-2">
                <InputField
                  placeholder="Enter Trim"
                  onChangeHandler={this.handleTrimChange}
                  value={trim}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                className="justify-start ml-4 fs-16 text-base font-medium"
                style={{ color: BLACK_03 }}
              >
                Body Style
              </label>
              <div className="mx-2">
                <SelectMenu
                  options={BODY_STYLES.map(event => ({
                    label: event,
                    value: event,
                  }))}
                  hideIndicatorSeparator={true}
                  onChangeHandler={this.onChangeBodyStyle}
                  formatOptionLabel={this.formatOptionLabel}
                  defaultValue={{ label: bodyStyle, value: bodyStyle }}
                />
              </div>
            </div>
            {this.renderButtons()}
          </div>
        </Modal>
      </div>
    );
  }
}

styles = {
  customStyles: {
    content: {
      display: 'block',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      zIndex: 99,
    },
  },
};

VehicleInformationModal.propTypes = {
  disableEdit: PropTypes.bool.isRequired,
  loadLots: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  vinInput: PropTypes.string,
};

const mapStateToProps = state => {
  const { item } = state.core;
  return { item };
};

export default connect(mapStateToProps, {
  loadLots,
  updateItem,
})(VehicleInformationModal);
