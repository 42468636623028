import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { makeMailLink } from './index';

export default class HolmanContactUs extends Component {
  render() {
    const contactStyle = { border: 'none' };

    return (
      <DocumentTitle title="Contact Us">
        <div className="container">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2 style={{ marginTop: 60, marginBottom: 40 }}>
              Holman Equipment Direct Sales Contact Page
            </h2>
            <table className="contact-table">
              <tr>
                <td>Physical Operations</td>
                <td style={contactStyle}>
                  <div>4001 Leadenhall Road</div>
                  <div>Mount Laurel, NJ 08054</div>
                </td>
              </tr>
              <tr>
                <td>Auction Technical Issues</td>
                <td style={contactStyle}>
                  <div>Brian McBrearty</div>
                  <div>856-778-6381</div>
                  <div>{makeMailLink('EquipmentDirectSales@holman.com')}</div>
                </td>
              </tr>
              <tr>
                <td>General and Seller Inquiries</td>
                <td style={contactStyle}>
                  <div>Brian McBrearty</div>
                  <div>856-778-6381</div>
                  <div>{makeMailLink('EquipmentDirectSales@holman.com')}</div>
                </td>
              </tr>
              <tr>
                <td>Payment Inquiries</td>
                <td style={contactStyle}>
                  <div>Michele Harris</div>
                  <div>856-778-6387</div>
                  <div>
                    {makeMailLink('EquipmentDirectPayments@holman.com')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Retrieval Inquiries</td>
                <td style={contactStyle}>
                  <div>Brian McBrearty</div>
                  <div>856-778-6381</div>
                  <div>
                    {makeMailLink('EquipmentDirectReleases@holman.com')}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Hours</td>
                <td style={contactStyle}>
                  Monday - Friday, 8:30am to 5:00pm ET
                </td>
              </tr>
            </table>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}
