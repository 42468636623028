import React, { Component } from 'react';
// import DocumentTitle from 'react-document-title';
// import ChildMarketplaceCard from '../components/ChildMarketplaceCard';
import { connect } from 'react-redux';
// import { getConfig } from '../utils/helpers';
// import _ from 'lodash';
// import SegmentedControl from 'react-segmented-control';
// import ReactStreetview from 'react-streetview';

// var Modal = require('react-modal');

// const ModalStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     'z-index': 999,
//   },
// };

// var StreetViewGMap = React.createClass({
//   getInitialState: function() {
//     return { modalIsOpen: false };
//   },

//   openModal: function() {
//     this.setState({ modalIsOpen: true });
//   },

//   afterOpenModal: function() {
//     // references are now sync'd and can be accessed.
//     this.refs.subtitle.style.color = '#f00';
//   },

//   closeModal: function() {
//     this.setState({ modalIsOpen: false });
//   },
//   render: function() {
//     return (
//       <div>
//         <i
//           onClick={this.openModal}
//           className="fas fa-street-view"
//           style={{ fontSize: 26, margin: 20, cursor: 'pointer' }}
//         />

//         <Modal
//           isOpen={this.state.modalIsOpen}
//           onAfterOpen={this.afterOpenModal}
//           onRequestClose={this.closeModal}
//           style={ModalStyles}
//           contentLabel="Street View"
//         >
//           <div className="modal-header">
//             <button
//               type="button"
//               className="close"
//               data-dismiss="modal"
//               aria-label="Close"
//             >
//               <span aria-hidden="true" onClick={this.closeModal}>
//                 ×
//               </span>
//             </button>
//             <h3 className="modal-title">Street View</h3>
//           </div>
//           <div>
//             <div
//               style={{
//                 width: '500px',
//                 height: '250px',
//                 backgroundColor: '#eeeeee',
//               }}
//             >
//               {/* <ReactStreetview
//                 apiKey={'AIzaSyAuzBy_g_kfx4FUTAYQZYce6BnWJXbrp7M'}
//                 streetViewPanoramaOptions={{
//                   position: { lat: this.props.lat, lng: this.props.lng },
//                   pov: { heading: 100, pitch: 0 },
//                   zoom: 1,
//                 }}
//                 onPositionChanged={position =>
//                   this.setState({ position: position })
//                 }
//                 onPovChanged={pov => this.setState({ pov: pov })}
//               /> */}
//             </div>
//           </div>
//         </Modal>
//       </div>
//     );
//   },
// });

// import {
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from 'react-google-maps';
// import { default as MarkerClusterer } from 'react-google-maps/lib/addons/MarkerClusterer';
import { loadMarketplaces } from '../actions';

// const GettingStartedGoogleMap = withGoogleMap(props => {
//   const {
//     mapConfig,
//     markers,
//     onMarkerClick,
//     onMarkerMouseOver,
//     onMarkerMouseOut,
//     onMarkerClose,
//   } = props;

//   return (
//     <GoogleMap
//       defaultZoom={mapConfig.zoom}
//       defaultCenter={{ lat: mapConfig.lat, lng: mapConfig.lng }}
//       options={{ scrollwheel: false }}
//     >
//       <MarkerClusterer
//         averageCenter
//         enableRetinaIcons
//         gridSize={60}
//         imagePath="/assets/images/map/m" /////// here ?
//       >
//         {markers.map((marker, index) => (
//           <Marker
//             key={index}
//             position={marker.position}
//             onClick={() => onMarkerClick(marker)}
//             onMouseOver={() => onMarkerMouseOver(marker)}
//             onMouseOut={() => onMarkerMouseOut(marker)}
//           >
//             {/*
//          Show info window only if the 'showInfo' key of the marker is true.
//          That is, when the Marker pin has been clicked and 'onCloseClick' has been
//          Successfully fired.
//          */}
//             {marker.showInfo && (
//               <InfoWindow onCloseClick={() => onMarkerClose(marker)}>
//                 <div>{marker.infoContent}</div>
//               </InfoWindow>
//             )}
//           </Marker>
//         ))}
//       </MarkerClusterer>
//     </GoogleMap>
//   );
// });

class ChildMarketplaces extends Component {
  render() {
    return null;
  }
  // state = {
  //   markers: [],
  //   mapState:
  //     localStorage.getItem('marketplaceMapState') === null
  //       ? 'ON'
  //       : localStorage.getItem('marketplaceMapState'),
  //   filteredList: '',
  //   marketplaceList: [],
  // };

  // componentDidMount() {
  //   const { loadMarketplaces } = this.props;

  //   loadMarketplaces(getConfig('marketplaceId'));

  //   window.addEventListener('resize', this.onResize);

  //   localStorage.setItem(
  //     'bcCrumbs',
  //     JSON.stringify({
  //       path: '/marketplaces',
  //       crumbs: [{ text: 'Marketplaces', link: '/marketplaces' }],
  //     })
  //   );
  // }

  // componentWillReceiveProps(nextProps) {
  //   const marketplaceList = nextProps.marketplaces.marketplaceList.filter(
  //     item => {
  //       // if the marketplace is the master/super/parent and has zero items filter it out
  //       return !(item.isParent && item.itemCount === 0);
  //     }
  //   );

  //   const mpMaps = _.map(marketplaceList, data => {
  //     return {
  //       position: { lat: data.latitude, lng: data.longitude },
  //       showInfo: false,
  //       key: data.marketplaceId,
  //       defaultAnimation: 1,
  //       infoContent: (
  //         <div>
  //           <table>
  //             <tr>
  //               <td>
  //                 <img src={data.logoUrl} width={120} alt="logo" />
  //               </td>
  //               <td>
  //                 <strong>{data.marketplaceName}</strong>
  //                 <br />
  //                 <span>{data.address1}</span>
  //                 <br />
  //                 <span>{`${data.city}, ${data.state} ${data.zipcode} `}</span>
  //                 <StreetViewGMap lat={data.latitude} lng={data.longitude} />
  //               </td>
  //             </tr>
  //           </table>
  //         </div>
  //       ),
  //     };
  //   });

  //   this.setState({
  //     markers: mpMaps,
  //     marketplaceList,
  //   });
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.onResize);
  // }

  // getFilteredItems(filterValue, marketplaceList) {
  //   return filterValue !== ''
  //     ? marketplaceList.filter(item => {
  //         return (
  //           String(item.address1)
  //             .toLowerCase()
  //             .includes(filterValue.toLowerCase()) ||
  //           String(item.city)
  //             .toLowerCase()
  //             .includes(filterValue.toLowerCase()) ||
  //           String(item.marketplaceName)
  //             .toLowerCase()
  //             .includes(filterValue.toLowerCase()) ||
  //           String(item.state)
  //             .toLowerCase()
  //             .includes(filterValue.toLowerCase()) ||
  //           String(item.phone)
  //             .toLowerCase()
  //             .includes(filterValue.toLowerCase())
  //         );
  //       })
  //     : '';
  // }

  // searchItemList = event => {
  //   const { marketplaceList } = this.state;
  //   const searchStr = event.target.value;

  //   this.setState({
  //     filteredList: this.getFilteredItems(searchStr, marketplaceList),
  //   });
  // };

  // onResize = () => {
  //   // ??
  //   if (this.state.mapState === 'ON') {
  //     this.setState({ mapState: 'OFF' });
  //     this.setState({ mapState: 'ON' });
  //   }
  // };

  // handleMapState = value => {
  //   this.setState({ mapState: value });
  //   localStorage.setItem('marketplaceMapState', value);
  // };

  // handleMarkerClick = targetMarker => {
  // //   browserHistory.push('/marketplace/' + targetMarker.key);
  // this.props.navigate('/marketplace/' + targetMarker.key)
  //   //window.location = '/marketplace/' + targetMarker.key;
  // };

  // handleMarkerClose = targetMarker => {};

  // handleMarkerMouseOver = targetMarker => {
  //   const { markers } = this.state;

  //   this.setState({
  //     markers: markers.map(marker => {
  //       return marker === targetMarker
  //         ? { ...marker, showInfo: true }
  //         : { ...marker, showInfo: false };
  //     }),
  //   });
  // };

  // handleMarkerMouseOut = targetMarker => {
  //   const { markers } = this.state;

  //   this.setState({
  //     markers: markers.map(marker => {
  //       return marker === targetMarker
  //         ? {
  //             ...marker,
  //             //showInfo: false,
  //           }
  //         : {
  //             ...marker,
  //             //showInfo: false,
  //           };
  //     }),
  //   });
  // };

  // createMarketplaces() {
  //   const { filteredList, marketplaceList } = this.state;

  //   const itemList = filteredList !== '' ? filteredList : marketplaceList;

  //   const items = (itemList || []).map((item, index) => {
  //     return (
  //       <div key={index}>
  //         <ChildMarketplaceCard marketplaceData={item} />
  //       </div>
  //     );
  //   });

  //   return items;
  // }

  // renderPlaceholderSection() {
  //   return (
  //     <div className="row">
  //       <div className="col-md-12">
  //         <div
  //           className="full-screen-section"
  //           style={{
  //             backgroundColor: '#fff',
  //             padding: 15,
  //             marginTop: 15,
  //             borderRadius: 5,
  //           }}
  //         >
  //           <h3 className="category-title">
  //             Child marketplaces are not enabled.
  //           </h3>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // render() {
  //   const { mapState, markers, marketplaceList } = this.state;

  //   if (!marketplaceList) return null;

  //   let mapConfig = getConfig('mapConfig');
  //   if (mapConfig == null)
  //     mapConfig = { zoom: 9, lat: 33.902736, lng: -118.069007 };

  //   const title = getConfig('name')
  //     ? getConfig('name') + ' | Marketplaces'
  //     : 'Marketplaces';

  //   return (
  //     <DocumentTitle title={title}>
  //       <div>
  //         <div className="nav-container">
  //           <div className="container-fluid">
  //             <div style={{ float: 'left' }}>
  //               <h3>{getConfig('childMarketplaceLabel')}</h3>
  //             </div>
  //             <div className="col-md-4 pull-left filter-selector">
  //               <input
  //                 className="form-control"
  //                 onChange={this.searchItemList}
  //                 placeholder="Search here..."
  //               />
  //             </div>
  //             <div
  //               id="mapSwitch"
  //               style={{ float: 'right', marginTop: '-28px' }}
  //             >
  //               {/* <SegmentedControl
  //                 onChange={this.handleMapState}
  //                 value={mapState}
  //                 name="mapState"
  //               >
  //                 <span value="ON">Map ON</span>
  //                 <span value="OFF">Map OFF</span>
  //               </SegmentedControl> */}
  //             </div>
  //           </div>
  //         </div>
  //         {mapState === 'ON' && (
  //           <div
  //             style={{
  //               height: `500px`,
  //               float: 'left',
  //               width: '100%',
  //               borderTop: '1px solid #ccc',
  //               borderBottom: '1px solid #ccc',
  //             }}
  //           >
  //             {/* <GettingStartedGoogleMap
  //               containerElement={<div style={{ height: `100%` }} />}
  //               mapElement={<div style={{ height: `100%` }} />}
  //               markers={markers}
  //               onMarkerMouseOver={this.handleMarkerMouseOver}
  //               onMarkerMouseOut={this.handleMarkerMouseOut}
  //               onMarkerClick={this.handleMarkerClick}
  //               onMarkerClose={this.handleMarkerClose}
  //               mapConfig={mapConfig}
  //             /> */}
  //           </div>
  //         )}
  //         <div className="container-fluid">{this.createMarketplaces()}</div>
  //       </div>
  //     </DocumentTitle>
  //   );
  // }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { marketplaces },
  } = state;

  return {
    marketplaces,
  };
};

export default connect(mapStateToProps, {
  loadMarketplaces,
})(ChildMarketplaces);
