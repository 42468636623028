import React, { Component } from 'react';
import { getConfig } from '../utils/helpers';
import Vehicle from '../components/Vehicle';
import VehicleListItem from '../components/VehicleListItem';
import { connect } from 'react-redux';
import { forceCheck } from 'react-lazyload';
import { extend } from 'lodash';
import {
  ActionBar,
  ActionBarRow,
  BoolMust,
  CheckboxFilter,
  FilteredQuery,
  HitsStats,
  InitialLoader,
  Layout,
  LayoutBody,
  LayoutResults,
  MenuFilter,
  NoHits,
  NumericRefinementListFilter,
  Pagination,
  RangeFilter,
  DynamicRangeFilter,
  RefinementListFilter,
  ResetFilters,
  SearchBox,
  SearchkitManager,
  SearchkitProvider,
  Select,
  SelectedFilters,
  SideBar,
  SortingSelector,
  TermQuery,
  TermsQuery,
  ViewSwitcherHits,
  ViewSwitcherToggle,
} from 'searchkit';
import { loadMarketplaces } from '../actions';

const url = getConfig('searchRoot');
const path = 'listing/';
const searchkit = new SearchkitManager(url + path);

searchkit.addResultsListener(results => {
  forceCheck();
});

export class MarketplaceSearch extends Component {
  state = {
    currentItem: {},
  };

  componentDidMount() {
    const mpId = this.props.params.marketplaceId || getConfig('marketplaceId');

    this.props.loadMarketplaces(mpId);
    searchkit.addDefaultQuery(query => {
      return query.addQuery(
        FilteredQuery({
          filter: BoolMust([
            TermQuery('marketplaceId', mpId),
            TermsQuery('listingStatusId', [1]),
          ]),
        })
      );
    });
  }

  handleModal = (type, currentItem) => {
    this.setState({
      currentItem,
    });

    if (type === 'buy') {
      //$('#buyModal').modal('show')
    } else if ('bid') {
      //$('#bidModal').modal('show')
    }
  };

  render() {
    if (!this.props.marketplaces.marketplaceList) {
      return null;
    }

    const GridItem = props => {
      const { bemBlocks, result } = props;
      const source = extend({}, result._source, result.highlight);
      const itemData = {
        ...source,
        ...this.props.bidMessagesRealTime[source._id],
      };

      return (
        <div
          className={bemBlocks.item().mix(bemBlocks.container('item'))}
          data-qa="hit"
        >
          <Vehicle
            key={source._id}
            vehicleData={itemData}
            category="marketplaceSearch"
          />
        </div>
      );
    };

    const ListItem = props => {
      const { bemBlocks, result } = props;
      const source = extend({}, result._source, result.highlight);
      const itemData = {
        ...source,
        ...this.props.bidMessagesRealTime[source._id],
      };
      return (
        <div
          className={bemBlocks.item().mix(bemBlocks.container('item'))}
          data-qa="hit"
        >
          <VehicleListItem
            key={source._id}
            vehicleData={itemData}
            category="marketplaceSearch"
          />
        </div>
      );
    };

    return (
      <div>
        <SearchkitProvider searchkit={searchkit}>
          <Layout size="l">
            <LayoutBody>
              <div className="search-wrapper">
                <div className="search-sidebar">
                  <SideBar>
                    <MenuFilter
                      id="eventName"
                      title=""
                      field="eventName"
                      operator="OR"
                      size={5000}
                      translations={{ All: 'All Events' }}
                      listComponent={Select}
                    />
                    <MenuFilter
                      id="make"
                      title=""
                      field="make"
                      operator="OR"
                      size={5000}
                      translations={{ All: 'All Makes' }}
                      listComponent={Select}
                    />
                    <MenuFilter
                      id="model"
                      title=""
                      field="model"
                      operator="OR"
                      size={5000}
                      translations={{ All: 'All Models' }}
                      listComponent={Select}
                    />
                    <CheckboxFilter
                      id="hasImage"
                      title="Image"
                      label="Has Image"
                      filter={TermQuery('hasImage', 'true')}
                    />
                    <CheckboxFilter
                      id="hasCR"
                      title="CR"
                      label="Has CR"
                      filter={TermQuery('hasCR', 'true')}
                    />

                    <RangeFilter
                      min={1996}
                      max={2017}
                      field="year"
                      id="year"
                      title="Year"
                      showHistogram={true}
                    />
                    <DynamicRangeFilter
                      field="outrightPrice"
                      id="price"
                      title="Price"
                      showHistogram={true}
                    />
                    <NumericRefinementListFilter
                      id="mileage"
                      title=""
                      field="mileage"
                      translations={{ All: `All Mileage` }}
                      options={[
                        { title: 'All' },
                        { title: 'up to 5K', from: 0, to: 5000 },
                        { title: '5K to 25K', from: 5001, to: 25000 },
                        { title: '25K to 50K', from: 25001, to: 50000 },
                        { title: '50K to 100K', from: 50001, to: 100000 },
                        { title: '100K or more', from: 100001, to: 1000000 },
                      ]}
                    />
                    <RefinementListFilter
                      id="drive"
                      title="Drive"
                      field="drive"
                      operator="OR"
                      size={5}
                      translations={{ '': 'Not Specified' }}
                    />
                    <RefinementListFilter
                      id="exteriorColor"
                      title="Exterior Color"
                      field="exteriorColor"
                      operator="OR"
                      size={5}
                      translations={{ '': 'Not Specified' }}
                    />
                    <RefinementListFilter
                      id="options"
                      title="Options"
                      field="options"
                      operator="OR"
                      size={5}
                      translations={{ '': 'Not Specified' }}
                    />
                    <RefinementListFilter
                      id="engine"
                      title="Engine"
                      field="engine"
                      operator="OR"
                      size={5}
                      translations={{ '': 'Not Specified' }}
                    />
                    <RefinementListFilter
                      id="seller"
                      title="Seller"
                      field="sellerName"
                      operator="OR"
                      size={5}
                      translations={{ '': 'Not Specified' }}
                    />
                  </SideBar>
                </div>
                <div className="search-content">
                  <LayoutResults>
                    <div className="event-title">
                      <h3 className="pull-left">
                        <span className="fa fa-bank" />{' '}
                        {
                          this.props.marketplaces.marketplaceList[0]
                            .marketplaceName
                        }
                      </h3>
                    </div>
                    <ActionBar>
                      <ActionBarRow>
                        <div className="embedded-search">
                          <SearchBox
                            translations={{
                              'searchbox.placeholder': 'search vehicles',
                            }}
                            queryOptions={{ minimum_should_match: '70%' }}
                            autofocus={true}
                            searchOnChange={true}
                            queryFields={[
                              'name^10',
                              'exteriorColor^5',
                              'drive^5',
                              'engine^5',
                              'transmission^5',
                              'vIN^5',
                            ]}
                          />
                        </div>
                        <HitsStats
                          translations={{
                            'hitstats.results_found':
                              '{hitCount} results found',
                          }}
                        />
                        <ViewSwitcherToggle />
                        <SortingSelector
                          className='sort-selector_options'
                          options={[
                            {
                              label: 'Relevance',
                              field: '_score',
                              order: 'desc',
                              defaultOption: true,
                            },
                            {
                              label: 'Year - Latest First',
                              field: 'year',
                              order: 'desc',
                            },
                            {
                              label: 'Year - Oldest First',
                              field: 'year',
                              order: 'asc',
                            },
                            {
                              label: `Odometer - Lowest First`,
                              field: 'mileage',
                              order: 'asc',
                            },
                            {
                              label: `Odometer - Highest First`,
                              field: 'mileage',
                              order: 'desc',
                            },
                            {
                              label: 'Auction Name',
                              field: 'marketplaceName',
                              order: 'asc',
                            },
                            {
                              label: 'Event Name',
                              field: 'eventName',
                              order: 'asc',
                            },
                            {
                              label: 'Seller Name',
                              field: 'sellerName',
                              order: 'asc',
                            },
                            {
                              label: 'Recently Added',
                              field: 'dateCreated',
                              order: 'desc',
                            },
                          ]}
                        />
                      </ActionBarRow>
                      <ActionBarRow>
                        <SelectedFilters />
                        <ResetFilters />
                      </ActionBarRow>
                    </ActionBar>

                    <ViewSwitcherHits
                      hitsPerPage={48}
                      highlightFields={[]}
                      sourceFilter={[
                        'aged',
                        'bidIncrement',
                        'bodyStyle',
                        'coordinates',
                        'description',
                        'drive',
                        'endDate',
                        'engine',
                        'eventFeatures',
                        'eventId',
                        'eventName',
                        'eventTypeId',
                        'exteriorColor',
                        'fuelType',
                        'hasCR',
                        'highBid',
                        'id',
                        'images',
                        'isPrivateEvent',
                        'latitude',
                        'latLon',
                        'licenseNumber',
                        'lights',
                        'listingStatus',
                        'listingStatusId',
                        'listingTypeId',
                        'liveStartTime',
                        'longitude',
                        'lotNumber',
                        'mainImage',
                        'make',
                        'marketplaceFeatures',
                        'marketplaceId',
                        'marketplaceId',
                        'marketplaceName',
                        'marketplaceType',
                        'mileage',
                        'model',
                        'name',
                        'notes',
                        'options',
                        'outrightPrice',
                        'preAged',
                        'privateEvent',
                        'rating',
                        'retailBookValue',
                        'sellerName',
                        'startDate',
                        'startingBid',
                        'stockNumber',
                        'transmission',
                        'vIN',
                        'wholesaleBookValue',
                        'year',
                      ]}
                      hitComponents={[
                        {
                          key: 'grid',
                          title: 'Grid',
                          itemComponent: GridItem,
                          defaultOption: true,
                        },
                        { key: 'list', title: 'List', itemComponent: ListItem },
                      ]}
                      scrollTo="body"
                    />

                    <NoHits suggestionsField={'make'} />
                    <InitialLoader />
                    <Pagination showNumbers={true} />
                  </LayoutResults>
                </div>
              </div>
            </LayoutBody>
          </Layout>
        </SearchkitProvider>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userProfile, marketplaces, loadingBar, bidMessagesRealTime } =
    state.entities;
  return {
    userProfile,
    marketplaces,
    loadingBar,
    bidMessagesRealTime,
  };
};

export default connect(mapStateToProps, { loadMarketplaces })(
  MarketplaceSearch
);
