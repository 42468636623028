import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { find } from 'lodash';
import {
  loadAccountList,
  loadAutoAnnouncements,
  loadAuthorizedSellerEvents,
  loadCrTypes,
  updateItem,
} from '../../../../actions';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import SelectMenu from '../ui/selectMenu';
import {
  GREY_03,
  WHITE_01,
  GREY_04,
  GREY_05,
  BLACK_03,
  CYAN_01,
  BLACK_02,
} from '../../constants/colors';

let styles = {};

class SaleInventoryModal extends Component {
  state = {
    selectedTab: 'Sale', // 'Sale' | 'Inventory'
    dealerSearchText: '',
    dealerAccount: '',
    eventSearchText: '',
    event: '',
    eventOptions: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.onOpen();
    }
  }

  onOpen = () => {
    this.setState({
      selectedTab:
        this.props.sellerActivityList === 'Unlisted' ? 'Inventory' : 'Sale',
    });
  };

  handleDealerInputChange = input => {
    this.setState({ dealerSearchText: input });
  };

  handleEventInputChange = input => {
    this.setState({ eventSearchText: input });
  };

  handleDealerSelect = option => {
    const finalizeDealerSelection = (eventOptions) => {
      const newState = {dealerSearchText: '', dealerAccount: option.value}
      if (eventOptions && eventOptions.length) {
        newState.eventOptions = eventOptions
      }
      this.setState({
        ...newState 
       }, 
       () => {
         this.props.updateItem({
           defaultSellerAccountNumber: option.value,
           dealerName: option.label,
           accountNumber: option.value,
         });
         this.props.loadCrTypes(option.value);
         this.props.loadAutoAnnouncements(option.value);
     });
    }
    // 'Sale' tab - load authorized seller events for selected account
    if (this.state.selectedTab === 'Sale') {
      this.props.loadAuthorizedSellerEvents(option.value)
      .then(({response}) => {
        const eventOptions = response?.events?.map(evt => {return {label: evt.name, value: evt.eventId}}) || [];
        finalizeDealerSelection(eventOptions)
      })
    // 'Inventory' tab - Unlisted listing; no Event state change needed
    } else if (this.state.selectedTab === 'Inventory') {
      finalizeDealerSelection();
    }
  };

  handleEventSelect = option => {
    const event = this.props.eventList.find(event => event.id === option.value);

    this.setState({ eventSearchText: '', event: option.value }, () => {
      this.props.updateItem({
        saleId: event.id,
        saleDate: event.date,
        eventName: event.type,
      });
    });
  };

  getDefaultValue(property) {
    const existingValue = this.props.item[property];
    return existingValue
      ? { value: existingValue, label: existingValue }
      : null;
  }

  formatOptionLabel = ({ value, label }, { context }) => {
    if (context === 'value') {
      return <div className="fs-14">{label}</div>;
    } else if (context === 'menu') {
      return (
        <div className="flex flex-col p-1\/2 ">
          <div className="fs-14">{label}</div>
          <div className="text-xs">{value}</div>
        </div>
      );
    }
  };

  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-10">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>

        <div className="pl-4 fs-20 font-semibold" style={{ color: BLACK_02 }} />

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderTabs() {
    return (
      <div className="modal-tab-button-group mb-10">
        <Button
          btnText="Sale"
          outlined={this.state.selectedTab === 'Inventory' ? true : false}
          outlinedTextColor={BLACK_03}
          outlinedBgColor={GREY_03}
          outlinedBorderColor={GREY_03}
          fontSize="16px"
          onClickHandler={() => this.setState({ selectedTab: 'Sale' })}
        />
        <Button
          btnText="Inventory"
          outlined={this.state.selectedTab === 'Inventory' ? false : true}
          outlinedTextColor={BLACK_03}
          outlinedBgColor={GREY_03}
          outlinedBorderColor={GREY_03}
          fontSize="16px"
          onClickHandler={() => this.setState({ selectedTab: 'Inventory' })}
        />
      </div>
    );
  }

  renderButtons() {
    const { selectedTab, dealerAccount, event } = this.state;
    const { item } = this.props;

    const hasDealerAccount = dealerAccount || (item && item.dealerName);
    const hasEvent = event || (item && item.saleDate);

    const nextDisabled =
      (selectedTab === 'Sale' && (!hasDealerAccount || !hasEvent)) ||
      (selectedTab === 'Inventory' && !hasDealerAccount);

    const entryType = selectedTab === 'Inventory' ? 'Inventory' : 'Sale';

    return (
      <div>
        <div className="modal-mt">
          <Button
            btnText="Next"
            outlined={nextDisabled}
            disabled={nextDisabled}
            outlinedTextColor={WHITE_01}
            outlinedBgColor={GREY_04}
            outlinedBorderColor={GREY_04}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={() => this.props.onNext(entryType)}
          />
        </div>
        <div className="modal-mt">
          <Button
            btnText="Cancel"
            outlined={true}
            outlinedTextColor={BLACK_03}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onClose}
          />
        </div>
      </div>
    );
  }

  renderEventOptions = () => {
    const { eventSearchText } = this.state;
    const { authorizedSellerEvents, eventList, events, unauthorizedEventIds } = this.props;
    if (!authorizedSellerEvents || !authorizedSellerEvents?.length) {
      return (eventList || [])
        .filter(event => {
          const eventListEvent = find(events.eventList, { eventId: event.id });
          const statusText = eventListEvent ? eventListEvent.statusText : '';
          const statusOk = ['Event in Progress', 'Upcoming Event'].includes(
            statusText
          );
          const isAuth = !unauthorizedEventIds.includes(event.id);
          const textFiltered = !!(
            eventSearchText &&
            !event.name.toLowerCase().includes(eventSearchText.toLowerCase())
          );
  
          return statusOk && isAuth && !textFiltered;
        })
        .map(event => ({ label: event.type, value: event.id }));
    } else {
      return authorizedSellerEvents;
    }
  };

  render() {
    const { isOpen, item, vin } = this.props;
    const { dealerSearchText, eventSearchText, selectedTab } = this.state;

    return (
      <div>
        <Modal
          isOpen={isOpen}
          shouldCloseOnEsc
          style={styles.customStyles}
          contentLabel={'saleInventory'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            {this.renderHeader()}
            {this.renderTabs()}

            <div className="modal-mt">
              <div className="mx-2">
                {((item && item.dealerName) ||
                  (vin.items && vin.items.length === 0) ||
                  (item && item.dealerName === '')) && (
                  <SelectMenu
                    title="Dealer"
                    isSearchable={true}
                    placeholder="Search"
                    defaultValue={this.getDefaultValue('dealerName')}
                    options={(this.props.accountList || [])
                      .filter((account, index) => {
                        const isPersonalAccount = index === 0;
                        const isFilteredOut = !!(
                          dealerSearchText &&
                          !account.name
                            .toLowerCase()
                            .includes(dealerSearchText.toLowerCase())
                        );
                        return !isPersonalAccount && !isFilteredOut;
                      })
                      .map(account => ({
                        label: account.name,
                        value: account.accountNumber,
                      }))}
                    formatOptionLabel={this.formatOptionLabel}
                    hideIndicator={false}
                    isClearable={false}
                    input={dealerSearchText}
                    onInputChangeHandler={this.handleDealerInputChange}
                    onChangeHandler={this.handleDealerSelect}
                  />
                )}
              </div>
            </div>

            {selectedTab === 'Sale' && (
              <div className="modal-mt">
                <div className="mx-2">
                  {((item && item.saleDate) ||
                    (vin.items && vin.items.length === 0) ||
                    (item && item.saleDate === '')) && (
                    <SelectMenu
                      title="Event"
                      isSearchable={true}
                      placeholder="Search"
                      defaultValue={this.getDefaultValue('eventName')}
                      // options={this.renderEventOptions()}
                      options={this.state.eventOptions}
                      hideIndicator={false}
                      isClearable={false}
                      formatOptionLabel={this.formatOptionLabel}
                      input={eventSearchText}
                      onInputChangeHandler={this.handleEventInputChange}
                      onChangeHandler={this.handleEventSelect}
                    />
                  )}
                </div>
              </div>
            )}
            {this.renderButtons()}
          </div>
        </Modal>
      </div>
    );
  }
}

styles = {
  customStyles: {
    content: {
      display: 'block',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
};

SaleInventoryModal.propTypes = {
  loadAccountList: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  loadCrTypes: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  loadAutoAnnouncements: PropTypes.func.isRequired,
  sellerActivityList: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { accountList, accountListLoading, eventList, item, vin } = state.core;
  const { authorizedSellerEvents, events, unauthorizedEventIds } = state.entities;
  return {
    accountList,
    accountListLoading,
    authorizedSellerEvents,
    eventList,
    item,
    vin,
    events,
    unauthorizedEventIds,
  };
};

export default connect(mapStateToProps, {
  loadAccountList,
  loadAuthorizedSellerEvents,
  loadAutoAnnouncements,
  loadCrTypes,
  updateItem,
})(SaleInventoryModal);
