import React, { Component } from 'react';
import Search from '../../templates/landing/Search';
import Vin from '../../templates/landing/Vin';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../ui/buttons';
import { BLUE_01, CYAN_01 } from '../../../constants/colors';
import { resetVin } from '../../../../../actions';
import Icon from '../../ui/icons';

class LandingPage extends Component {
  state = {
    selectedTab: 'vin',
  };

  componentDidMount() {
    this.props.resetVin();
  }

  render() {
    const { selectedTab } = this.state;

    return (
      <div className="container-fluid h-screen flex flex-col ">
        <div
          className={'core-back-button back-button'}
          style={{ width: 'fit-content' }}
          onClick={() => {
            const { entryType, saleDate } = this.props.item;
            const path = `/inventory/${
              entryType === 'Inventory' || !saleDate ? 'unlisted' : 'listed'
            }`;
            this.props.navigate(path);
          }}
        >
          <Icon name="LeftArrow" size={17} color={CYAN_01} marginRight={12} />
          <span style={{ color: CYAN_01 }}>Back</span>
        </div>
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="tab-button-group">
            <Button
              btnText="VIN"
              outlined={selectedTab === 'vin' ? false : true}
              outlinedTextColor={BLUE_01}
              onClickHandler={() => this.setState({ selectedTab: 'vin' })}
            />
            {false && (
              <Button
                btnText="Search"
                outlined={selectedTab === 'search' ? false : true}
                outlinedTextColor={BLUE_01}
                onClickHandler={() => this.setState({ selectedTab: 'search' })}
              />
            )}
          </div>
          <div className="landing-page-text">
            Check &amp; Decode Any VIN For Free
          </div>
          <div className="landing-page-sub-text">
            Comprehensive Vehicle History, Market Value, Specifications, Car
            Title Check and More
          </div>
          {selectedTab === 'vin' && <Vin />}
          {selectedTab === 'search' && <Search />}
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  resetVin: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { item } = state.core;
  return { item };
};

export default connect(
  mapStateToProps,
  { resetVin }
)(LandingPage);
