import { FETCH_API } from '../../middleware';

export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAILURE = 'EVENT_LIST_FAILURE';

const fetchEventList = apiKey => ({
  [FETCH_API]: {
    types: [EVENT_LIST_REQUEST, EVENT_LIST_SUCCESS, EVENT_LIST_FAILURE],
    endpoint: `get-event-list?apiKey=${apiKey}`,
    core: true,
  },
});

export const loadEventList = apiKey => dispatch =>
  dispatch(fetchEventList(apiKey));

export const RESET_EVENT_LIST = 'RESET_EVENT_LIST';

export const resetEventList = () => ({
  type: RESET_EVENT_LIST,
});
