import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv2DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M370.8,187.3c-0.4,0.7-0.9,1.2-1.8,1.4l-21.7,0.8c-0.8-0.2-1.4-0.7-1.6-1.3l-6.1,0.4
	c-0.2,0.7-0.7,1.2-1.5,1.4l-9.7,0.6l-5.7,0.8c-1.8,0.1-3.1,1.9-4,5.5l-0.2,1l-9.6,67c-0.2,0.2-0.4,0.6-0.6,0.9l-0.1,7.7v0.7l0.5-0.5
	h1.3l9.3-71.9l0.6-4.9c0.1-1.5,1-2.8,2.6-3.6c2-1.1,4.3-1.6,7.3-1.9l18.9-0.8c18.7-0.7,38-1.3,57.9-1.6h24.1l76.8,2.4
	c2.9,0.1,5.4,0.8,7.3,1.9c0.9,0.6,1.6,1.2,2.1,2c0.2,0.5,0.5,1.1,0.5,1.6l0.6,4.9l9.3,71.9h1.3l0.5,0.5v-0.8l-0.1-7.7l-0.5-0.7
	l-0.1-0.1l-0.5-3.5l-9.1-63.5l-0.2-1.1c-0.5-2-1-3.4-1.7-4.3c-0.6-0.8-1.4-1.2-2.2-1.2l-5.7-0.8l-9.7-0.6c-0.8-0.2-1.3-0.7-1.5-1.4
	l-5.9-0.2c-0.2,0.6-0.8,1.1-1.6,1.3l-21.7-0.9c-0.8-0.2-1.4-0.7-1.7-1.4l-5.2-0.1c-0.4,0.7-1,1.2-2,1.3c-6.8-0.2-13.4-0.4-20-0.4
	c-1.2-0.2-1.9-0.7-2.2-1.3h-5.7c-0.4,0.8-1,1.3-2.1,1.3h-21.2c-1.3-0.2-2-0.6-2.2-1.3h-5.7c-0.2,0.6-1,1.1-2.2,1.3l-20,0.4
	c-0.9-0.1-1.6-0.6-2-1.3L370.8,187.3L370.8,187.3z M304.4,241.4c0.1-2.7-1-4.2-3.5-4.4c-7.9-0.2-15.2-0.2-22,0.2l-2.3,0.1
	c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9c-0.1,3,1.5,4.5,4.8,4.4h1c-2.6-0.2-3.8-1.8-3.8-4.4v-9.9
	c0-3.3,0.1-5.8,0.5-7.6c0.5-1.5,1.5-2.2,3.4-2.2l2-0.1l-2,0.1c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9
	c-0.1,2.7,1.2,4.2,3.8,4.4h21c3.3-0.2,4.9-1.6,4.8-4.1v-16H304.4z M299.1,238.8c2,0.2,2.9,1.4,2.9,3.7v13.8c0.1,2.1-1.3,3.3-4,3.5
	h-17.7c-2.2-0.2-3.3-1.5-3.3-3.8v-8.4c0-2.8,0.1-4.9,0.5-6.5c0.4-1.3,1.3-2,2.8-2h0.2l1.4-0.2l-1.4,0.1l1.4-0.1
	C287.4,238.7,293.1,238.6,299.1,238.8L299.1,238.8z M291.9,266.5h6.2v-4.8h-6.2 M296.4,267.2h-5v5.6l12.1,1.7v-7.3"
          />
          <path
            fill="#FFFFFF"
            d="M293.5,267.2h2.9v-0.7h-2.9 M308.5,265.8c-0.2,0.5-0.4,0.9-0.5,1.4h-4.4v7.3l1,0.1v-1.2h3.8V265.8
	L308.5,265.8z M285,302.7v29.5l24,0.7l-0.5-9.3c-0.1-11.5-0.4-20.4-0.9-26.3c-0.4-3.5-0.7-6.1-1.2-7.7l-0.1-0.2l-13.9,2.2
	c-2.2,0.2-3.7,1.3-4.8,3.4l-2.8,5.7v2.1H285L285,302.7z"
          />
          <path
            fill="#FFFFFF"
            d="M307.3,276.8c0.2-1.2,0.6-2,1-2.7v-0.7h-3.8v6.6h2.4C306.9,278.9,307.1,277.8,307.3,276.8L307.3,276.8z"
          />
          <path
            fill="#646464"
            d="M328.9,317.9c0-0.5-0.2-0.7-0.7-0.7h-9.7c-0.5,0-0.7,0.2-0.7,0.7c-0.2,1.9-0.2,3.7,0,5.7
	c0,0.5,0.2,0.7,0.7,0.7h9.7c0.5,0,0.7-0.2,0.7-0.7C329.1,321.6,329.1,319.7,328.9,317.9L328.9,317.9z M380.7,251.2l-1.3,1.3
	c-1.4,1.5-2.7,3-3.8,4.5c-0.8,1-1.5,2.1-2.3,3.3c-1.9,2.8-3.4,5.8-4.7,9l-0.4,0.9l-0.5,1.3c-0.2,0.8-0.5,1.5-0.7,2.3
	c-1.4,4.9-2.1,10-2.1,15.5c0,10.8,2.8,20.5,8.3,29l2.3,3.3c1.5,2,3.3,4,5.1,5.8c3,3,6.2,5.6,9.6,7.7c8.4,5.4,18,8,28.6,8
	c10.6,0,20.2-2.7,28.6-8c3.1-2,6.2-4.4,9.1-7.2l0.9-0.9l1.4-1.5c1.2-1.3,2.3-2.6,3.3-4l0.6-0.7l1.2-1.6l0.6-0.9
	c5.5-8.4,8.3-17.8,8.4-28.4v-2.5l-0.4-4.7l-0.2-1.6l-0.1-0.9c-0.4-2.2-0.8-4.4-1.4-6.5l-0.7-2.3l-0.5-1.3l-0.7-1.6
	c-1.6-4-3.7-7.7-6.4-11.2l-0.2-0.2c-1.2-1.5-2.5-3-3.8-4.5l-1.3-1.3c-8.6-8.6-18.8-13.8-30.3-15.3l-7.8-0.5l-7.8,0.5
	C399.4,237.4,389.4,242.6,380.7,251.2L380.7,251.2z M373.3,265.5c1.3-2.4,2.8-4.7,4.4-6.9c1.2-1.5,2.3-2.9,3.7-4.3l2.2-2.2
	c9.8-9.3,21.6-14,35.2-14s25.4,4.7,35.2,14l2.2,2.2c1.3,1.4,2.6,2.8,3.7,4.3c0.8,1,1.6,2.2,2.3,3.4c1.6,2.7,3.1,5.4,4.2,8.3l1.3,3.6
	c1.5,4.8,2.2,9.8,2.2,15.2v0.4c0,10.1-2.6,19.2-7.7,27.3l-3,4.2c-1.3,1.6-2.7,3.1-4.2,4.7c-4,4-8.3,7.2-12.9,9.6
	c-7,3.6-14.8,5.4-23.2,5.4c-8.5,0-16.2-1.9-23.2-5.4c-4.7-2.5-9-5.6-13.1-9.6c-1.8-1.8-3.4-3.6-4.9-5.6c-1.6-2.2-3.1-4.5-4.4-6.9
	c-3.7-7.1-5.7-15-5.7-23.8c0-5.5,0.7-10.6,2.2-15.5l1.3-3.6C371.7,268.6,372.4,267,373.3,265.5L373.3,265.5z"
          />
          <path
            fill="#646464"
            d="M377.7,258.6c-1.6,2.2-3.1,4.5-4.4,6.9c-0.8,1.5-1.5,3-2.2,4.7l-1.3,3.6c-1.5,4.9-2.2,10-2.2,15.5
	c0,8.7,1.9,16.7,5.7,23.8c1.3,2.5,2.8,4.7,4.4,6.9c1.5,2,3.1,3.7,4.9,5.6c4,4,8.3,7.2,13.1,9.6c7,3.6,14.8,5.4,23.2,5.4
	c8.5,0,16.2-1.7,23.2-5.4c4.7-2.5,9-5.6,12.9-9.6c1.5-1.5,2.9-3,4.2-4.7l3-4.2c5.1-8,7.7-17.1,7.7-27.3v-0.4
	c0-5.4-0.8-10.5-2.2-15.2l-1.3-3.6c-1.2-2.8-2.6-5.6-4.2-8.3c-0.7-1.2-1.5-2.2-2.3-3.4c-1.2-1.5-2.3-2.9-3.7-4.3l-2.2-2.2
	c-9.8-9.3-21.6-14-35.2-14c-13.6,0-25.4,4.7-35.2,14l-2.2,2.2C380,255.7,378.7,257.1,377.7,258.6L377.7,258.6z M384.8,289.3
	c0-5.7,1.2-10.8,3.6-15.5c0.4-0.8,0.8-1.5,1.3-2.3c0.2-0.5,0.6-0.8,0.8-1.3c1.2-1.8,2.6-3.4,4.2-5c6.6-6.6,14.7-10,24.1-10
	c9.4,0,17.5,3.4,24.1,10c1.6,1.6,3,3.3,4.2,5l2.2,3.6c2.5,4.7,3.6,9.8,3.6,15.5c0,5.2-1,10-3,14.5c-1.6,3.5-4,6.8-6.9,9.7
	c-3.5,3.5-7.3,6.1-11.7,7.8c-3.8,1.5-7.9,2.2-12.4,2.2s-8.6-0.7-12.4-2.2c-4.3-1.6-8.2-4.2-11.7-7.8c-2.4-2.4-4.4-5-5.9-7.8
	C386.2,300.7,384.8,295.3,384.8,289.3L384.8,289.3z"
          />
          <path
            fill="#646464"
            d="M422.1,286c-0.9-0.9-2-1.3-3.1-1.3c-1.3,0-2.3,0.5-3.1,1.3c-0.8,0.9-1.3,2-1.3,3.1c0,1.3,0.5,2.3,1.3,3.3
	c0.9,0.8,2,1.3,3.1,1.3c1.3,0,2.3-0.5,3.1-1.3c0.9-0.9,1.4-2,1.4-3.3C423.4,288,422.9,287,422.1,286L422.1,286z M416.5,288.3h0.6
	v1.2l-0.1,0.2c-0.1,0.1-0.2,0.2-0.5,0.2h-0.2l-0.6-0.1c-0.2-0.1-0.4-0.2-0.4-0.4V289h0.5v0.3l0.2,0.1h0.2l0.1-0.2L416.5,288.3
	L416.5,288.3z M418.1,288.8h0.1c0.2,0,0.4,0.1,0.5,0.4l0.1,0.2v0.2h-1v0.2c0,0.1,0.1,0.1,0.1,0.1h0.1l0.1-0.1h0.5l-0.1,0.1l-0.2,0.1
	l-0.5,0.1h-0.2c-0.2-0.1-0.4-0.1-0.5-0.4l-0.1-0.4l0.1-0.2v-0.1c0.1-0.1,0.1-0.2,0.2-0.2l0.4-0.1L418.1,288.8L418.1,288.8z
	 M422.1,289.1l0.1,0.2v0.5l-0.1,0.2l-0.4,0.1h-0.4l-0.1-0.1v0.5h-0.5v-1.8h0.5v0.1l0.1-0.1l0.2-0.1h0.5L422.1,289.1L422.1,289.1z
	 M420.4,289.8l-0.1,0.1l-0.4,0.2l-0.4,0.1h-0.2c-0.2-0.1-0.4-0.2-0.5-0.4v-0.2c0-0.1,0-0.4,0.1-0.5l0.2-0.2l0.4-0.1h0.4
	c0.2,0,0.4,0.1,0.5,0.4l0.1,0.2v0.2h-1v0.1c0,0.1,0.1,0.1,0.2,0.1h0.1l0.1-0.1L420.4,289.8L420.4,289.8z"
          />
          <path
            fill="#646464"
            d="M418,289.1h-0.1c-0.1,0-0.1,0-0.1,0.1v0.1h0.5v-0.1L418,289.1z M421.4,289.2c-0.2,0-0.2,0-0.4,0.1l-0.1,0.2
	v0.2l0.1,0.1h0.1l0.2-0.1l0.1-0.1v-0.5H421.4L421.4,289.2z M419.9,289.3l-0.2-0.2h-0.1c-0.1,0-0.1,0-0.1,0.1v0.1H419.9L419.9,289.3z
	 M354,284.4c-0.8,0-1.4,0.2-2,0.8s-0.8,1.2-0.8,2l0.1,0.6c0.1,0.5,0.4,1,0.8,1.4c0.6,0.6,1.2,0.8,2,0.8c0.8,0,1.4-0.2,2-0.8
	c0.5-0.5,0.7-1,0.8-1.6v-0.4c0-0.8-0.2-1.4-0.8-2C355.4,284.6,354.8,284.4,354,284.4L354,284.4z M352.6,288.6
	c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.5,0.2-0.9,0.6-1.4c0.4-0.4,0.8-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4
	c0,0.6-0.2,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6C353.4,289.2,353.1,289,352.6,288.6z"
          />
          <path
            fill="#646464"
            d="M352,287.2c0,0.6,0.2,1,0.6,1.4c0.4,0.4,0.8,0.6,1.4,0.6c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.8,0.6-1.4
	c0-0.5-0.2-0.9-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6c-0.5,0-0.9,0.2-1.4,0.6C352.3,286.3,352,286.6,352,287.2L352,287.2z"
          />
          <path
            fill="#646464"
            d="M351.9,291.5v16.9c0,0.5,0.2,0.9,0.6,1.3s0.8,0.6,1.3,0.6h0.1c0.5,0,0.9-0.2,1.3-0.6s0.6-0.8,0.6-1.3v-16.6
	c0.4-0.4,0.6-0.9,0.7-1.8c0.1-0.7,0.2-1.5,0.2-2.6c-0.1,0.6-0.4,1.2-0.8,1.6c-0.6,0.6-1.2,0.8-2,0.8s-1.4-0.2-2-0.8
	c-0.5-0.4-0.7-0.8-0.8-1.4c-0.1,0.8,0,1.6,0.1,2.3C351.6,290.6,351.7,291,351.9,291.5L351.9,291.5z M355,291.6
	c0.2,0.2,0.5,0.5,0.5,0.7v15.9c0,0.4-0.1,0.7-0.5,1c-0.2,0.2-0.6,0.5-1,0.5c-0.4,0-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1v-15.9
	c0-0.2,0.1-0.5,0.5-0.7c0.2-0.2,0.7-0.2,1-0.2C354.4,291.3,354.6,291.4,355,291.6L355,291.6z"
          />
          <path
            fill="#646464"
            d="M355.4,292.3c0-0.2-0.1-0.5-0.5-0.7c-0.2-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0.1-1,0.2c-0.2,0.2-0.5,0.5-0.5,0.7
	v15.9c0,0.4,0.1,0.7,0.5,1c0.2,0.2,0.7,0.5,1,0.5c0.4,0,0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1L355.4,292.3L355.4,292.3z"
          />
          <path
            fill="#646464"
            d="M351.9,308.4v-2.1l-0.5-0.1c-0.1,0.2-0.1,0.5-0.1,0.8v2c0,0.7,0.2,1.3,0.7,1.8c0.5,0.5,1,0.7,1.8,0.7
	s1.3-0.2,1.8-0.7c0.5-0.5,0.7-1,0.7-1.8v-2l-0.1-0.8l-0.5,0.1v2.2c0,0.5-0.2,0.9-0.6,1.3s-0.8,0.6-1.3,0.6h-0.1
	c-0.5,0-0.9-0.2-1.3-0.6C352.2,309.4,351.9,308.9,351.9,308.4L351.9,308.4z M355.8,318.5c0.5-0.5,0.7-1,0.7-1.6
	c0-0.7-0.2-1.2-0.7-1.6c-0.5-0.5-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7c-0.5,0.5-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6
	s0.9,0.7,1.6,0.7C354.7,319,355.3,318.8,355.8,318.5L355.8,318.5z M354.1,316c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.2,0.6
	c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.5,0.2-0.6
	C353.7,316,353.9,316,354.1,316L354.1,316z"
          />
          <path
            fill="#646464"
            d="M354.6,316.2c-0.1-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
	c0.1,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6C354.8,316.6,354.8,316.3,354.6,316.2L354.6,316.2z
	 M418.4,230.9l-7.9-0.1l0.6,5.1l7.8-0.6l7.8,0.6l0.5-5.1"
          />
          <path
            fill="#FFFFFF"
            d="M564.9,247.3c0-3.3-0.1-5.8-0.5-7.6c-0.5-1.5-1.5-2.2-3.4-2.2l-2.3-0.1c-6.8-0.5-14.1-0.5-22-0.2
	c-2.5,0.2-3.5,1.8-3.5,4.4v16.1c-0.1,2.4,1.5,3.8,4.8,4.1h21c2.6-0.2,3.8-1.8,3.8-4.4v-9.9c0-3.3-0.1-5.8-0.5-7.6
	c-0.5-1.5-1.5-2.2-3.4-2.2l-2-0.1l2,0.1c1.9,0,2.9,0.7,3.4,2.2c0.3,1.9,0.5,4.4,0.5,7.6v9.9c0.1,2.7-1.2,4.2-3.8,4.4h1
	c3.3,0.1,4.9-1.4,4.8-4.4L564.9,247.3L564.9,247.3z M560,241.2c0.3,1.6,0.5,3.7,0.5,6.5v8.4c0.1,2.3-1,3.6-3.3,3.8h-17.6
	c-2.8-0.2-4.1-1.4-4-3.5v-13.9c0-2.3,0.9-3.5,2.9-3.7c6.1-0.2,11.8-0.1,17.1,0.1l1.4,0.1l-1.4-0.1l1.4,0.2h0.2
	C558.9,239.2,559.7,239.8,560,241.2L560,241.2z M529.2,265.8v7.7h3.8v1.3l1-0.2v-7.3h-4.4"
          />
          <path
            fill="#FFFFFF"
            d="M539.5,261.7v4.8h6.3v-4.8 M544,266.5h-2.9v0.7h2.9"
          />
          <path
            fill="#FFFFFF"
            d="M546.1,267.2h-12v7.3l12-1.7 M533.1,274.7v-1.2h-3.8v0.7c0.5,0.6,0.8,1.5,1,2.7c0.2,0.9,0.3,2,0.3,3.3h2.4
	L533.1,274.7L533.1,274.7z M531.2,289.4l-0.1,0.2c-0.5,1.6-0.9,4.1-1.2,7.7c-0.5,5.9-0.8,14.8-0.9,26.3l-0.5,9.3l24-0.7v-31.5
	l-2.8-5.7c-0.9-2-2.6-3.1-4.8-3.4L531.2,289.4L531.2,289.4z"
          />
          <path
            fill="#646464"
            d="M555,324.2l-2.4-2.6v0.1v-0.1v10.6l-24,0.7l-0.1,2.7h18.2c3.7-0.2,5.6,0.9,5.8,3.5l-2.2,13.6l-0.5,1.8
	c-0.3,0.9-0.8,1.7-1.3,2.6l-2.5,4.2c-0.8,0.9-3.1,1.3-6.8,1.3H519c-0.8,0-1.4,0.1-2,0.4c-0.6,0.2-1,0.6-1.3,1c0,0.2-0.1,0.5-0.2,0.6
	l-0.6,0.1h-0.8v56.9c0.3,3.3,2.2,5.5,5.6,6.6c4.1,0.7,8.9,1,14.5,0.9h1.9c5.5,0.1,10.4-0.2,14.3-0.9c3.4-1.2,5.2-3.4,5.6-6.6v-93.8
	C555.8,325.8,555.6,324.6,555,324.2L555,324.2z"
          />
          <path
            fill="#646464"
            d="M528.4,335.6h-9.2c-0.8,0-1.6-0.1-2.2-0.4c-0.5-0.2-0.8-0.5-1-0.8h-0.2v29.5c0.2-0.5,0.7-0.8,1.3-1
	c0.6-0.2,1.2-0.4,2-0.4h20.3c3.7,0,5.9-0.5,6.8-1.3l2.5-4.2c0.5-0.8,0.9-1.6,1.3-2.6l0.5-1.8l2.2-13.6c-0.1-2.6-2.1-3.7-5.8-3.5
	L528.4,335.6L528.4,335.6z M540,339c0.6,0,0.8,0.2,0.8,0.8v12.5c0,0.6-0.2,0.8-0.8,0.8h-21.1c-0.6,0-0.8-0.2-0.8-0.8v-12.5
	c0-0.6,0.2-0.8,0.8-0.8L540,339L540,339z"
          />
          <path
            fill="#646464"
            d="M540.9,339.8c0-0.6-0.2-0.8-0.8-0.8H519c-0.6,0-0.8,0.2-0.8,0.8v12.5c0,0.6,0.2,0.8,0.8,0.8h21.1
	c0.6,0,0.8-0.2,0.8-0.8V339.8z M538.6,340.2c0.6,0,0.8,0.2,0.8,0.8v10c0,0.6-0.2,0.8-0.8,0.8h-10.8l-1.2,0.1l-1.2-0.1h-5.1
	c-0.6,0-0.8-0.2-0.8-0.8v-10c0-0.6,0.2-0.8,0.8-0.8L538.6,340.2L538.6,340.2z M515.7,334.4v-0.4c0-0.6-0.2-0.8-0.8-0.8h-19.2
	l-0.4,0.1c-0.4,0.1-0.5,0.4-0.5,0.8v29.7c0,0.5,0.1,0.7,0.5,0.8h19.6l0.6-0.1c0.1-0.1,0.2-0.4,0.2-0.6L515.7,334.4L515.7,334.4z
	 M494.4,335.1c-0.5,0.7-1.2,1-2.2,1.3c-8.4,0.4-19.9,0.5-34.9,0.4c-0.8,0.1-1.3,0.5-1.5,1v0.7l0.1,22.1c0.1,0.7,0.4,1.2,0.9,1.3
	h35.4c1.4,0.1,2.2,0.6,2.5,1.4v-29.3C494.7,334.5,494.6,334.8,494.4,335.1L494.4,335.1z"
          />
          <path
            fill="#646464"
            d="M475.5,361.9c1.4,0.5,2.4,1.4,3,2.7h16.8c-0.4-0.1-0.5-0.4-0.5-0.8v-0.5c-0.2-0.8-1-1.3-2.4-1.4H475.5z
	 M479.2,367.3c0,0.7,0,1.4-0.1,2.1l-0.1,1.2h35.1v-3.3L479.2,367.3L479.2,367.3z"
          />
          <path
            fill="#646464"
            d="M478.1,374.4l-0.2,0.8c-0.1,0.5-0.1,0.8-0.4,1.3l-0.1,0.4c-0.2,0.5-0.6,0.8-0.9,1.3l-1,0.8h38.7v-8.4H479
	L478.1,374.4L478.1,374.4z"
          />
          <path
            fill="#646464"
            d="M477.1,374.4c0-1-0.5-2-1.2-2.8c-0.6-0.6-1.4-1-2.2-1.2h-1.5c-0.8,0.1-1.5,0.5-2.2,1.2
	c-0.8,0.8-1.2,1.8-1.2,2.8v0.1c0,0.8,0.2,1.6,0.7,2.2l0.5,0.7c0.8,0.8,1.7,1.2,2.9,1.2s2.1-0.4,2.9-1.2l0.6-0.7
	c0.5-0.7,0.7-1.4,0.7-2.2L477.1,374.4L477.1,374.4z M478.5,364.6c0.4,0.7,0.6,1.6,0.7,2.6h34.9v-2.6L478.5,364.6L478.5,364.6z"
          />
          <path
            fill="#646464"
            d="M457,370.6v2.8c0,0.5-0.2,0.9-0.7,1l-0.7,0.1h-70.9c-0.2,0-0.2-0.1-0.4-0.1h-31.5c-0.2,0-0.5-0.1-0.5-0.5
	v-9.3h-28.9v2.6h28.8h-28.8v3.3h28.8h-28.8v8.4h147.1l-1-0.8c-0.4-0.4-0.7-0.8-0.9-1.3c-0.4-0.7-0.6-1.4-0.6-2.2v-0.1
	c0-0.5,0.1-0.9,0.2-1.3V373l0.7-2.6h-12L457,370.6L457,370.6z"
          />
          <path
            fill="#646464"
            d="M457,373.4v-11.4c-0.6-0.1-0.8-0.6-0.9-1.3l-0.1-22.1v-0.7h-8c-8.6,3.8-17.5,5.9-26.6,6.4l-3.4,0.1
	c-9.7-0.2-19.1-2.4-28.2-6.4h-8l-0.1,23c-0.1,0.9-0.6,1.4-1.5,1.3h-27.6v12c0,0.2,0.1,0.5,0.5,0.5h31.5l-0.1-0.2v-17.1
	c0-0.2,0.1-0.5,0.5-0.5h11l-0.4-0.4c-0.4-0.4-0.6-0.9-0.6-1.4v-0.1h-4.5c-0.2,0-0.5-0.1-0.5-0.5v-9.1c0-0.2,0.1-0.5,0.5-0.5h57.2
	c0.2,0,0.5,0.1,0.5,0.5v9.1c0,0.2-0.1,0.5-0.5,0.5h-4.5v0.1c0,0.6-0.2,1-0.6,1.4l-0.4,0.4h13.5c0.2,0,0.5,0.1,0.5,0.5v17.1l-0.1,0.2
	l-0.2,0.1l0.7-0.1C456.7,374.3,457,373.8,457,373.4L457,373.4z"
          />
          <path
            fill="#646464"
            d="M437.4,350.3v3h7.1v-3c0-0.2-0.1-0.5-0.5-0.5h-6.2C437.5,349.8,437.4,349.9,437.4,350.3z"
          />
          <path
            fill="#646464"
            d="M437.4,353.3v-3c0-0.2,0.1-0.5,0.5-0.5h6.2c0.2,0,0.5,0.1,0.5,0.5v4.4h3c0.2,0,0.5-0.1,0.5-0.5v-9.1
	c0-0.2-0.1-0.5-0.5-0.5h-57.2c-0.2,0-0.5,0.1-0.5,0.5v9.1c0,0.2,0.1,0.5,0.5,0.5h3v-4.4c0-0.2,0.1-0.5,0.5-0.5h6.2
	c0.2,0,0.5,0.1,0.5,0.5v4.4h37.1L437.4,353.3L437.4,353.3z M445.4,349.5c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5,0.1,0.6,0.2
	c0.1,0.1,0.2,0.4,0.2,0.6c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2s-0.2-0.4-0.2-0.6
	C445.2,349.8,445.3,349.6,445.4,349.5L445.4,349.5z M391,349.5c0.1-0.1,0.4-0.2,0.6-0.2s0.5,0.1,0.6,0.2s0.2,0.4,0.2,0.6
	c0,0.2-0.1,0.5-0.2,0.6s-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.4-0.2-0.6C390.8,349.8,390.9,349.6,391,349.5
	L391,349.5z"
          />
          <path
            fill="#646464"
            d="M446,349.2c-0.2,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.6c0,0.2,0.1,0.5,0.2,0.6s0.4,0.2,0.6,0.2
	c0.2,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6C446.5,349.3,446.2,349.2,446,349.2L446,349.2z M391.6,349.2
	c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.6
	c0-0.2-0.1-0.5-0.2-0.6S391.8,349.2,391.6,349.2L391.6,349.2z M437.4,353.3v1.4h7v-1.4"
          />
          <path
            fill="#646464"
            d="M438.8,354.7h-39.9v0.1c0,0.6-0.2,1-0.6,1.4l-0.4,0.4h41.9l-0.4-0.4c-0.5-0.4-0.6-0.9-0.6-1.4v-0.1H438.8
	L438.8,354.7z"
          />
          <path
            fill="#646464"
            d="M438.8,354.7v0.1c0,0.6,0.2,1,0.6,1.4l0.4,0.4c0.4,0.2,0.7,0.4,1.2,0.4s0.8-0.1,1.2-0.4l0.4-0.4
	c0.4-0.4,0.6-0.9,0.6-1.4v-0.1H438.8L438.8,354.7z M393.2,353.3v1.4h7.1v-1.4"
          />
          <path
            fill="#646464"
            d="M394.7,354.7v0.1c0,0.6,0.2,1,0.6,1.4l0.4,0.4c0.4,0.2,0.7,0.4,1.2,0.4c0.5,0,0.8-0.1,1.2-0.4l0.4-0.4
	c0.5-0.4,0.6-0.9,0.6-1.4v-0.1H394.7L394.7,354.7z M393.7,349.8c-0.2,0-0.5,0.1-0.5,0.5v3h7.1v-3c0-0.2-0.1-0.5-0.5-0.5H393.7z
	 M381.8,337.7c-0.1-0.6-0.7-0.9-1.5-1c-12.9,0.1-23.4,0-31.5-0.2l-3.4-0.1c-1-0.1-1.8-0.6-2.2-1.3c-0.2-0.2-0.4-0.6-0.4-1v29.3
	c0.2-0.8,1-1.3,2.4-1.4H380c0.9,0.1,1.4-0.4,1.5-1.3L381.8,337.7L381.8,337.7z"
          />
          <path
            fill="#646464"
            d="M342.8,334.1c0-0.5-0.1-0.7-0.5-0.8l-0.4-0.1h-19.2c-0.6,0-0.8,0.2-0.8,0.8v29.8c0,0.2,0.1,0.5,0.2,0.6
	l0.2,0.1h19.9c0.4-0.1,0.5-0.4,0.5-0.8L342.8,334.1L342.8,334.1z"
          />
          <path
            fill="#646464"
            d="M352.5,364.6v-2.7h-7.2c-1.4,0.1-2.2,0.6-2.5,1.4v0.5c0,0.5-0.1,0.7-0.5,0.8H352.5L352.5,364.6z
	 M323.5,370.6v-5.8H322h0.4l-0.2-0.1c-0.1-0.1-0.2-0.4-0.2-0.6c-0.6-0.9-1.6-1.4-3.4-1.4h-20.3c-3.7,0-5.9-0.5-6.8-1.3l-2.5-4.2
	c-0.5-0.8-0.9-1.6-1.3-2.6c-0.2-0.6-0.4-1.2-0.5-1.7l-2.2-13.6c0.1-2.6,2.1-3.7,5.8-3.5H309l-0.1-2.7l-24-0.7v-10.6v0.1v-0.1
	l-2.4,2.6c-0.5,0.4-0.8,1.6-1,3.6v93.8c0.4,3.3,2.2,5.5,5.6,6.6c4.1,0.7,8.9,1,14.3,0.9h1.9c5.5,0.1,10.4-0.2,14.5-0.9
	c3.4-1.2,5.2-3.4,5.6-6.6v-42.7L323.5,370.6L323.5,370.6z"
          />
          <path
            fill="#646464"
            d="M321.9,334.4h-0.2c-0.7,0.8-1.8,1.2-3.3,1.2H291c-3.7-0.2-5.6,0.9-5.8,3.5l2.2,13.6c0.1,0.6,0.2,1.2,0.5,1.8
	c0.4,0.9,0.8,1.7,1.3,2.6l2.4,4.2c0.8,0.9,3.1,1.3,6.8,1.3h20.3c1.6,0,2.8,0.5,3.4,1.4v-29.5L321.9,334.4L321.9,334.4z M297.6,353.1
	c-0.6,0-0.8-0.2-0.8-0.8v-12.5c0-0.6,0.2-0.8,0.8-0.8h21.1c0.6,0,0.8,0.2,0.8,0.8v12.5c0,0.6-0.2,0.8-0.8,0.8L297.6,353.1
	L297.6,353.1z"
          />
          <path
            fill="#646464"
            d="M296.8,352.3c0,0.6,0.2,0.8,0.8,0.8h21.1c0.6,0,0.8-0.2,0.8-0.8v-12.5c0-0.6-0.2-0.8-0.8-0.8h-21.1
	c-0.6,0-0.8,0.2-0.8,0.8L296.8,352.3L296.8,352.3z M298.3,341.1c0-0.6,0.2-0.8,0.8-0.8h18.3c0.6,0,0.8,0.2,0.8,0.8v10
	c0,0.6-0.2,0.8-0.8,0.8h-5.1l-1.2,0.1l-1.2-0.1h-10.8c-0.6,0-0.8-0.2-0.8-0.8L298.3,341.1L298.3,341.1z M366.6,199.8
	c0-0.6-0.2-0.8-0.8-0.8h-5.9c-0.6,0-0.8,0.2-0.8,0.8v7.3l1.4,6.9c0.1,0.5,0.6,0.7,1.3,0.7l1.9,0.1c0.9-0.1,1.4-0.5,1.4-1.1l1.5-6.5
	v-7.5L366.6,199.8L366.6,199.8z M478.5,199.8c0-0.6-0.2-0.8-0.8-0.8h-5.9c-0.6,0-0.8,0.2-0.8,0.8v7.3l1.5,6.5c0.1,0.6,0.6,0.9,1.4,1
	l1.9-0.1c0.7,0,1.2-0.2,1.3-0.7l1.4-6.9v-7.2L478.5,199.8L478.5,199.8z M405,229.3c-0.1,0.5-0.1,0.8,0.1,1.1
	c0.1,0.2,0.5,0.5,0.9,0.5l4.5,0.1l7.9,0.1l8.7-0.1l4.5-0.1c0.5,0,0.8-0.1,0.9-0.5c0.2-0.2,0.2-0.6,0.1-1.1l-1.4-5
	c-0.1-0.7-0.6-1.1-1.4-1.1h-22.1c-0.7,0-1.2,0.4-1.4,1.1L405,229.3L405,229.3z M405.7,229.9v-0.4l1.4-5c0.1-0.2,0.2-0.5,0.6-0.4
	h22.1c0.4,0,0.5,0.1,0.6,0.4l1.4,5v0.4h-0.2l-13.3,0.1l-12.5-0.1H405.7L405.7,229.9z"
          />
          <path
            fill="#FFFFFF"
            d="M330,191.5c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.4,2.1-2.6,3.6l-0.6,4.9c0.6-1.8,1.6-3.1,3.1-4
	c2-1.1,4.3-1.6,7.3-1.9l18.9-0.8c18.7-0.7,38-1.3,57.9-1.6H431l76.8,2.4c2.9,0.1,5.4,0.8,7.3,1.9c0.8,0.5,1.5,1.1,2.1,1.9
	c0.5,0.7,0.8,1.4,1.2,2.2l-0.6-4.9c-0.1-0.6-0.2-1.2-0.5-1.6c-0.5-0.8-1.2-1.4-2.1-2c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4H407
	c-19.9,0.4-39.3,0.9-57.9,1.6L330,191.5L330,191.5z"
          />
          <path
            fill="#FFFFFF"
            d="M330,195.9c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.7,2.2-3.1,4l-9.3,71.9h30.5l2.4-3.6h-7.8
	c-1.9-0.2-2.8-1.5-2.8-4c0.6-16.7,1.5-31.8,2.7-45.6l0.7-7.3c0.1-4.7,2.7-7.3,7.7-7.8l14.2-0.7v-5.5c0-0.6,0.4-0.8,1-0.8h7.5
	c0.6,0,0.9,0.2,1,0.7v5.4c5.4-0.1,11-0.1,16.9-0.1h68.9c5.9-0.1,11.5,0,16.9,0.1v-5.4c0.1-0.5,0.5-0.7,1-0.7h7.5c0.7,0,1,0.2,1,0.8
	v5.5l14.3,0.8c4.9,0.5,7.5,3.1,7.7,7.8l0.7,7.3c1.2,13.8,2.1,28.9,2.7,45.6c0.1,2.4-0.8,3.7-2.8,4h-7.8l2.5,3.6h30.5l-9.3-71.9
	c-0.2-0.8-0.7-1.5-1.2-2.2c-0.6-0.7-1.3-1.3-2.1-1.9c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4h-24.1c-19.9,0.4-39.3,0.9-57.9,1.6L330,195.9
	L330,195.9z"
          />
          <path
            fill="#FFFFFF"
            d="M336.4,220.5l0.6-6.5c0.1-4.7,2.7-7.3,7.7-7.8l13.2-0.8v-0.7l-14.3,0.8c-4.9,0.5-7.5,3.1-7.7,7.8l-0.7,7.3
	c-1.2,13.8-2.1,28.9-2.7,45.6c-0.1,2.4,0.8,3.7,2.8,4h7.8l1.8-1h-7.7c-2.2,0-3.4-1-3.4-3C334.3,249.4,335.3,234.3,336.4,220.5
	L336.4,220.5z M367.4,204.3v0.8c5.7-0.1,11.8-0.1,18.1-0.1h66.6c6.3-0.1,12.4,0,18.1,0.1v-0.8c-5.4-0.1-11-0.1-16.9-0.1h-68.9
	C378.5,204.2,372.8,204.2,367.4,204.3z M307.3,276.8c0.1,0,0.2,0.2,0.2,0.6c0.1-0.4,0.2-0.6,0.4-0.6c0.2,0,0.5,1,0.7,3
	c0.2,2,0.4,4.4,0.5,7.2c0,2.8,0,5.2-0.2,7.2c-0.1,2-0.4,3-0.6,3c-0.1,0-0.2-0.2-0.4-0.6l-0.2,0.6c0.5,5.9,0.8,14.8,0.9,26.3l0.5,9.3
	l0.1,2.7h0.7v-1.2l-0.5-27.6v-28.1c-0.1-2.8,0.1-4.4,0.6-4.9h-1.3l-0.5,0.5C307.9,274.8,307.5,275.8,307.3,276.8L307.3,276.8z"
          />
          <path
            fill="#FFFFFF"
            d="M307.6,277.4c-0.1-0.4-0.2-0.6-0.2-0.6c-0.2,0.9-0.4,2-0.4,3.3v4.3c-0.4,0.1-0.5,0.6-0.5,1.2v2.1l0.1,2
	c0.5,1.6,0.8,4.1,1.2,7.7l0.2-0.6c-0.1-0.5-0.2-1.3-0.5-2.4c-0.2-2-0.4-4.4-0.4-7.2c-0.1-2.8,0-5.2,0.1-7.2
	C307.4,278.7,307.4,278,307.6,277.4L307.6,277.4z"
          />
          <path
            fill="#FFFFFF"
            d="M307.9,276.8c-0.1,0-0.2,0.2-0.4,0.6l0.4,2.3c0.2,2,0.4,4.4,0.5,7.2c0,2.8,0,5.2-0.2,7.2
	c-0.1,1-0.1,1.9-0.2,2.4c0.1,0.4,0.2,0.6,0.4,0.6c0.2,0,0.5-1,0.6-3c0.1-2,0.2-4.4,0.2-7.2c-0.1-2.8-0.2-5.2-0.5-7.2
	C308.3,277.9,308.2,276.8,307.9,276.8L307.9,276.8z M310.1,273.8c-0.6,0.5-0.8,2.1-0.6,4.9v28.1l0.5,27.6h11.8l0.2-0.4
	c0-0.6,0.2-0.8,0.8-0.8H342l0.4,0.1l0.2-59.6h-0.7l1.4-2.3l0.8-1.3l0.7-1l-1.8,1l-2.4,3.6h1L310.1,273.8L310.1,273.8z M314.3,278.1
	h14.2c1.9,0,2.8,0.9,2.8,2.8v22.8c0,1.9-0.9,2.8-2.8,2.8h-14.2c-1.9,0-2.8-0.9-2.8-2.8v-22.8C311.5,279,312.4,278.1,314.3,278.1
	L314.3,278.1z M329.4,317.5c0.4,2.1,0.4,4.2,0,6.3c0,0.6-0.2,0.8-0.8,0.8h-10.7c-0.6,0-0.8-0.2-0.8-0.8c-0.2-2.2-0.2-4.3,0-6.3
	c0-0.6,0.2-0.8,0.8-0.8h10.7C329.2,316.7,329.4,316.9,329.4,317.5L329.4,317.5z"
          />
          <path
            fill="#FFFFFF"
            d="M329.9,280.9c0-0.9-0.5-1.4-1.4-1.4h-2.3l0.8,0.7c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2
	c-0.6,0.7-1.3,1.2-2,1.5h5v-11.4L329.9,280.9L329.9,280.9z M329.4,323.8c0.4-2.1,0.4-4.2,0-6.3c0-0.6-0.2-0.8-0.8-0.8h-10.7
	c-0.6,0-0.8,0.2-0.8,0.8c-0.2,2-0.2,4.1,0,6.3c0,0.6,0.2,0.8,0.8,0.8h10.7C329.2,324.6,329.4,324.4,329.4,323.8L329.4,323.8z
	 M328,317c0.5,0,0.7,0.2,0.7,0.7c0.2,1.9,0.2,3.7,0,5.7c0,0.5-0.2,0.7-0.7,0.7h-9.7c-0.5,0-0.7-0.2-0.7-0.7c-0.2-2-0.2-3.8,0-5.7
	c0-0.5,0.2-0.7,0.7-0.7H328L328,317z M307.6,277.4c-0.1,0.5-0.2,1.3-0.4,2.3c-0.1,2-0.2,4.4-0.1,7.2c0,2.8,0.1,5.2,0.4,7.2
	c0.1,1,0.2,1.9,0.5,2.4c0.1-0.5,0.2-1.3,0.2-2.4c0.1-2,0.2-4.4,0.2-7.2c-0.1-2.8-0.2-5.2-0.5-7.2L307.6,277.4L307.6,277.4z"
          />
          <path fill="#5E9FF1" d="M306.5,287.7v2" />
          <path
            fill="#FFFFFF"
            d="M388.4,273.8c-2.4,4.7-3.6,9.8-3.6,15.5c0,6.1,1.4,11.4,4.1,16.3c1.5,2.8,3.5,5.4,5.9,7.8
	c3.5,3.5,7.3,6.1,11.7,7.8c3.8,1.5,7.9,2.2,12.4,2.2s8.6-0.7,12.4-2.2c4.3-1.6,8.2-4.2,11.7-7.8c2.9-2.9,5.2-6.2,6.9-9.7
	c2.1-4.4,3-9.2,3-14.5c0-5.7-1.2-10.8-3.6-15.5l-2.2-3.6c-1.3-1.8-2.6-3.4-4.2-5c-6.6-6.6-14.7-10-24.1-10c-9.4,0-17.5,3.4-24.1,10
	c-1.6,1.6-2.9,3.3-4.2,5c-0.4,0.5-0.6,0.8-0.8,1.3C389.2,272.2,388.9,273,388.4,273.8L388.4,273.8z M388.9,278.8l0.5-1.2
	c0.5-1.3,1.2-2.6,1.9-3.8c0.5-0.8,0.9-1.5,1.5-2.3l0.9-1.3c0.9-1.2,1.9-2.2,2.9-3.3c5-5,10.8-7.9,17.5-9l4.9-0.4
	c8.7,0,16.2,3.1,22.4,9.3c1,1,2,2.2,2.9,3.3l2.4,3.6c0.7,1.3,1.3,2.6,1.9,3.8c0.6,1.5,1.1,3,1.4,4.7c0.5,2.2,0.7,4.5,0.7,7
	s-0.2,4.8-0.7,7c-1.3,5.8-4.1,11-8.5,15.4l-0.1,0.1c-6.2,6.1-13.6,9.2-22.4,9.2c-8.7,0-16.2-3.1-22.4-9.3c-3.5-3.5-6.1-7.5-7.6-11.9
	c-1.2-3.3-1.8-6.8-1.8-10.6c0-1.7,0.1-3.4,0.4-5v-0.1C387.8,282.3,388.3,280.6,388.9,278.8L388.9,278.8z"
          />
          <path
            fill="#FFFFFF"
            d="M390.4,287.6c-0.7-0.4-1.2-0.8-1.5-1.2c-0.7-0.7-1-1.4-1.3-2.1c-0.2,1.6-0.4,3.3-0.4,5h3.1
	C390.3,288.7,390.3,288.1,390.4,287.6L390.4,287.6z M439,269.1c-3.7-3.8-8-6.3-12.9-7.5l0.8,7.7l1.7,0.8c0.7,0.4,1.4,0.8,2.1,1.3
	l0.7,0.5l10.4,0.5L439,269.1L439,269.1z"
          />
          <path
            fill="#FFFFFF"
            d="M431.6,271.9l-0.7-0.5c-0.7-0.5-1.4-0.9-2.1-1.3l-1.7-0.8l0.5,4.4c0.2,1,0.6,1.8,1.3,2.1
	c0.7,0.6,1.6,0.7,2.9,0.5l3.7-0.8l-1.6-1.8L431.6,271.9L431.6,271.9z"
          />
          <path
            fill="#FFFFFF"
            d="M441.9,272.4l-10.4-0.5l2.1,1.9l1.7,1.8l7.6-1.5l-0.1-0.2"
          />
          <path
            fill="#5E9FF1"
            d="M418.7,273.7l-0.6,0.1h1.3 M417.1,288.3h-0.6v1.3l-0.1,0.2h-0.2l-0.2-0.1v-0.4h-0.5v0.5l0.4,0.4l0.6,0.1h0.2
	c0.2-0.1,0.4-0.1,0.5-0.2l0.1-0.2L417.1,288.3L417.1,288.3z M418.3,288.8h-0.4l-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2v0.1l-0.1,0.2
	l0.1,0.4c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.5-0.1l0.2-0.1l0.1-0.1h-0.5l-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1-0.1v-0.2h1v-0.2l-0.1-0.2
	C418.6,289,418.5,288.8,418.3,288.8L418.3,288.8z M417.9,289.1h0.1l0.2,0.1v0.1h-0.5v-0.1C417.8,289.1,417.9,289.1,417.9,289.1z
	 M422.1,289.3l-0.1-0.2l-0.2-0.2h-0.5l-0.2,0.1l-0.1,0.1V289h-0.5v1.8h0.5v-0.5l0.1,0.1h0.4l0.4-0.1l0.1-0.2L422.1,289.3
	L422.1,289.3z M421.4,289.2l0.2,0.1v0.5l-0.1,0.1l-0.2,0.1h-0.1l-0.1-0.1v-0.2l0.1-0.2C421.2,289.2,421.3,289.2,421.4,289.2
	L421.4,289.2z"
          />
          <path
            fill="#5E9FF1"
            d="M420.4,289.8h-0.5l-0.1,0.1h-0.1c-0.1,0-0.2-0.1-0.2-0.1v-0.1h1v-0.2l-0.1-0.2c-0.1-0.2-0.2-0.2-0.5-0.4
	h-0.4l-0.4,0.1l-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.5v0.2c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.4-0.1l0.4-0.2L420.4,289.8L420.4,289.8z
	 M419.9,289.2l-0.5,0.1v-0.1c0-0.1,0.1-0.1,0.1-0.1h0.1L419.9,289.2z"
          />
          <path
            fill="#FFFFFF"
            d="M418.7,306H418c-0.7,0.1-1.3,0.6-2,1.3l-7.8,8.6c3.3,1.3,6.8,2,10.6,2c3.7,0,7.2-0.6,10.4-1.9l-7.9-8.7
	c-0.7-0.7-1.3-1.2-2-1.3L418.7,306L418.7,306z"
          />
          <path fill="#5E9FF1" d="M387.5,284.2v0.1" />
          <path
            fill="#FFFFFF"
            d="M397.4,270.5l-0.8,0.9c-0.6,0.7-1.2,1.5-1.7,2.3v0.1h0.1l7.6,1.6l1.5-1.6c0.9-0.8,1.9-1.6,2.8-2.3l0.8-0.5
	L397.4,270.5L397.4,270.5z"
          />
          <path
            fill="#FFFFFF"
            d="M398.7,269.1l-1.3,1.4l10.3,0.5c0.5-0.2,0.9-0.6,1.3-0.8c0.6-0.4,1.3-0.6,1.9-0.9l0.8-7.7
	C406.8,262.7,402.5,265.3,398.7,269.1L398.7,269.1z"
          />
          <path fill="#5E9FF1" d="M414,257.9" />
          <path
            fill="#FFFFFF"
            d="M407.7,271l-0.8,0.5c-0.9,0.7-2,1.4-2.8,2.3l-1.5,1.6l3.7,0.8c1.3,0.2,2.2,0.1,2.9-0.5c0.6-0.4,1-1,1.3-2
	l0.6-4.4c-0.7,0.2-1.3,0.6-1.9,0.9C408.6,270.4,408.1,270.7,407.7,271L407.7,271z M390.4,287.6c0,0.6-0.1,1.2-0.1,1.8
	c0,6.9,2.1,12.9,6.4,18.1l5.7-10.1c0.6-1.2,0.8-2.1,0.5-2.9c-0.2-0.8-0.9-1.5-2.1-2.1L390.4,287.6z M447.3,287.8l-5.7,2.6
	c0,2.3-0.2,3.8-1,4.7l-5.5-1.4c-0.2,0.4-0.5,0.6-0.5,0.9c-0.4,0.8-0.2,1.8,0.5,2.9l5.7,10c4.3-5.1,6.5-11.2,6.5-18.2L447.3,287.8
	L447.3,287.8z"
          />
          <path
            fill="#FFFFFF"
            d="M436.7,292.6c-0.7,0.4-1.3,0.7-1.6,1.2l5.5,1.4c0.7-0.8,1-2.3,1-4.7L436.7,292.6L436.7,292.6z"
          />
          <path fill="#5E9FF1" d="M441.2,311.8" />
          <path
            fill="#FFFFFF"
            d="M367.8,271.5l0.5-1.3h-24l-0.8,1.3 M342,273.8h25.1c0.2-0.8,0.5-1.5,0.7-2.3h-24.4L342,273.8L342,273.8z
	 M364.9,289.3c0-5.5,0.7-10.6,2.1-15.5h-24.2l-0.2,59.6c0.4,0.1,0.5,0.4,0.5,0.8l0.4,1h47c-3.4-2.1-6.5-4.7-9.6-7.7
	c-1.9-1.9-3.6-3.8-5.1-5.8l-2.3-3.3C367.7,309.8,364.9,300.1,364.9,289.3L364.9,289.3z M352,285.2c0.6-0.6,1.2-0.8,2-0.8
	s1.4,0.2,2,0.8s0.8,1.2,0.8,2v0.4c0,1-0.1,1.9-0.2,2.6c-0.2,0.8-0.5,1.4-0.7,1.8v0.5c1.3,0.4,2.4,0.9,3.4,1.9
	c1.5,1.4,2.2,3.1,2.2,5.1c0,2-0.7,3.6-2.2,5c-0.9,0.8-1.9,1.4-3,1.7l0.1,0.8v2c0,0.7-0.2,1.3-0.7,1.7s-1,0.7-1.7,0.7
	s-1.3-0.2-1.8-0.7s-0.7-1-0.7-1.7v-2c0-0.2,0-0.6,0.1-0.8c-0.9-0.4-1.9-0.8-2.7-1.4l-0.5-0.4c-1.5-1.4-2.2-3-2.2-5
	c0-2,0.7-3.6,2.2-5.1l0.5-0.4c0.9-0.8,2-1.3,3.1-1.5v-0.8c-0.2-0.4-0.5-0.8-0.6-1.4c-0.1-0.7-0.2-1.4-0.1-2.3l-0.1-0.6
	C351.2,286.5,351.5,285.8,352,285.2L352,285.2z M356.4,316.8c0,0.6-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7
	s-0.7-1-0.7-1.6c0-0.7,0.2-1.2,0.7-1.6c0.5-0.5,0.9-0.7,1.6-0.7c0.6,0,1.2,0.2,1.6,0.7C356.1,315.5,356.4,316.1,356.4,316.8
	L356.4,316.8z M356.5,332.3l0.8,1h-2l-0.9-1l-0.4-0.2l-0.5-0.1h-0.1v1.4h-1.9v-3.6l4.2,0.1c0.4,0.1,0.6,0.2,0.7,0.4
	c0.2,0.1,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.4,0.2-0.6,0.4l-0.6,0.1l0.5,0.1L356.5,332.3z M357.9,333.4v-3.6h2.7
	l1.3,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.2,0.1,0.5,0.1,0.7c0,0.4-0.1,0.7-0.2,0.9l-0.6,0.6
	c-0.2,0.1-0.5,0.2-0.8,0.2l-1,0.1L357.9,333.4L357.9,333.4z M346.6,333.3c-0.4-0.1-0.7-0.4-1-0.6c-0.2-0.2-0.5-0.7-0.5-1
	c0-0.6,0.2-1,0.8-1.4c0.5-0.4,1.3-0.5,2.2-0.5h0.8l0.9,0.2c0.5,0.2,0.7,0.5,0.9,0.8l-1.6,0.2l-0.1-0.2l-0.1-0.1l-0.4-0.1l-0.5-0.1
	c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.1-0.2,0.4-0.2,0.7s0.1,0.7,0.2,0.8c0.2,0.1,0.5,0.2,0.8,0.2s0.6-0.1,0.8-0.1l0.4-0.5l1.5,0.4
	c-0.1,0.2-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5l-0.6,0.1h-0.7L346.6,333.3L346.6,333.3z"
          />
          <path
            fill="#FFFFFF"
            d="M348.8,293.9l-0.5,0.4c-1.5,1.4-2.2,3.1-2.2,5.1c0,2,0.7,3.6,2.2,5l0.5,0.4c0.8,0.7,1.6,1.2,2.7,1.4
	c0.1-0.4,0.2-0.6,0.5-0.9v-12.9C350.8,292.6,349.7,293.2,348.8,293.9z"
          />
          <path
            fill="#5E9FF1"
            d="M351.9,306.2v-1c-0.2,0.2-0.4,0.6-0.5,0.9L351.9,306.2L351.9,306.2z"
          />
          <path
            fill="#FFFFFF"
            d="M361.5,299.3c0-2-0.7-3.6-2.2-5c-1-0.9-2.1-1.5-3.4-1.9v12.9c0.2,0.2,0.4,0.5,0.5,0.8c1-0.4,2.1-0.9,3-1.7
	C360.8,302.9,361.5,301.3,361.5,299.3L361.5,299.3z"
          />
          <path
            fill="#5E9FF1"
            d="M355.9,305.3v0.9l0.5-0.1C356.1,305.9,356,305.5,355.9,305.3L355.9,305.3z"
          />
          <path
            fill="#FFFFFF"
            d="M357.3,333.4l-0.8-1l-0.6-0.4l-0.5-0.1l0.6-0.1c0.2-0.1,0.5-0.2,0.6-0.4s0.2-0.4,0.2-0.5
	c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.1-0.5-0.2-0.7-0.4l-4.2-0.1v3.6h1.9v-1.4h0.1l0.5,0.1l0.4,0.2l0.9,1h1.9L357.3,333.4L357.3,333.4z
	 M355,331l-0.4,0.1l-0.5,0.1h-0.7v-0.7h0.8l0.7,0.1c0.1,0.1,0.2,0.1,0.2,0.2L355,331L355,331z"
          />
          <path
            fill="#FFFFFF"
            d="M354.6,331.2l0.4-0.1l0.1-0.2c0-0.1-0.1-0.2-0.2-0.2l-0.7-0.1h-0.8v0.7L354.6,331.2L354.6,331.2z
	 M357.9,329.8v3.6h2.7l1-0.1c0.2,0,0.6-0.1,0.8-0.2l0.6-0.6c0.1-0.2,0.2-0.5,0.2-0.9c0-0.2,0-0.5-0.1-0.7s-0.2-0.4-0.5-0.6
	c-0.2-0.1-0.5-0.2-0.8-0.4l-1.3-0.1L357.9,329.8L357.9,329.8z M359.7,332.6v-2h0.5c0.5,0,0.8,0.1,0.9,0.2c0.2,0.1,0.4,0.4,0.4,0.8
	c0,0.2,0,0.5-0.1,0.6l-0.4,0.2l-0.8,0.1L359.7,332.6L359.7,332.6z"
          />
          <path
            fill="#FFFFFF"
            d="M359.7,330.6v2h0.5l0.8-0.1l0.4-0.2c0.1-0.1,0.1-0.4,0.1-0.6c0-0.4-0.1-0.7-0.4-0.8
	c-0.2-0.1-0.5-0.2-0.9-0.2L359.7,330.6L359.7,330.6z M345.5,332.7c0.2,0.2,0.7,0.5,1,0.6l1.5,0.1h0.7l0.6-0.1
	c0.4-0.1,0.6-0.2,0.8-0.5s0.4-0.4,0.5-0.7l-1.5-0.4l-0.4,0.5c-0.2,0.1-0.5,0.1-0.8,0.1s-0.7-0.1-0.8-0.2c-0.2-0.1-0.2-0.5-0.2-0.8
	s0.1-0.6,0.2-0.7c0.2-0.2,0.5-0.4,0.9-0.4l0.5,0.1l0.2,0.1l0.1,0.1l0.1,0.2l1.6-0.2c-0.2-0.4-0.5-0.7-0.9-0.8l-0.9-0.2H348
	c-0.9,0-1.6,0.1-2.2,0.5c-0.5,0.4-0.8,0.8-0.8,1.4C345.1,332,345.2,332.4,345.5,332.7L345.5,332.7z M479.7,204.7v0.9l13.2,0.8
	c4.9,0.5,7.5,3.1,7.7,7.8l0.6,6.5c1.2,13.8,2.1,28.9,2.7,45.6c-0.1,2-1.2,3-3.4,3h-7.7l1.7,1h7.8c1.9-0.2,2.8-1.5,2.8-4
	c-0.6-16.7-1.5-31.8-2.7-45.6l-0.7-7.3c-0.1-4.7-2.7-7.3-7.7-7.8L479.7,204.7L479.7,204.7z"
          />
          <path
            fill="#5E9FF1"
            d="M405,186.6h1c0.2,0.7,0.9,1.2,2.2,1.3c-1-0.1-1.9-0.5-2.1-1.3H405z"
          />
          <path
            fill="#FFFFFF"
            d="M492.8,269.1l0.7,1l0.8,1.3l1.4,2.3H495v6.5h0.6v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h8.9
	c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-8.9c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5v-1.5h-0.5l0.1,28.4h0.4v-1.6
	c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h9c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-8.9c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5v-1.5
	h-0.4v5.5l0.4-0.1h19.2c0.6,0,0.8,0.2,0.8,0.8l0.2,0.4H528l0.5-27.6v-28.1c0.1-2.8-0.1-4.4-0.6-4.9h-31.6h1l-2.5-3.6L492.8,269.1
	L492.8,269.1z M516.8,278.1h6.5c1.9,0,2.8,0.9,2.8,2.8v22.8c0,1.9-0.9,2.8-2.8,2.8h-14.2c-1.9,0-2.8-0.9-2.8-2.8v-22.8
	c0-1.9,0.9-2.8,2.8-2.8L516.8,278.1L516.8,278.1z"
          />
          <path
            fill="#FFFFFF"
            d="M494.3,271.5l-0.8-1.3h-24l0.5,1.3 M472.4,282.8l0.4,4.7h17.4c0.6,0,0.8-0.2,0.8-0.8v-3
	c0-0.6-0.2-0.8-0.8-0.8H472.4L472.4,282.8z"
          />
          <path
            fill="#FFFFFF"
            d="M472.7,287.4v1.5H495v-7.7h-22.7l0.2,1.6h17.6c0.6,0,0.8,0.2,0.8,0.8v3c0,0.6-0.2,0.8-0.8,0.8h-17.4
	L472.7,287.4L472.7,287.4z M472.7,289v0.9h22.4V289 M472.1,280.3l0.1,0.9H495v-0.9"
          />
          <path
            fill="#FFFFFF"
            d="M470.6,273.8c0.6,2.1,1,4.3,1.4,6.5h23v-6.5H470.6z M502.3,280.3c0.6,0,0.8,0.2,0.8,0.8v7.9
	c0,0.6-0.2,0.8-0.8,0.8h-5.7v1.5v-1.5h-1v1.5l0.1,0.5c0.1,0.2,0.4,0.4,0.7,0.4h1c-0.4,0-0.6-0.1-0.7-0.4c0.1,0.2,0.4,0.4,0.7,0.4
	h7.8c0.6,0,0.8-0.2,0.8-0.8v-12.7c0-0.6-0.2-0.8-0.8-0.8h-7.8l-0.4,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6L502.3,280.3L502.3,280.3z"
          />
          <path
            fill="#FFFFFF"
            d="M503.1,281.1c0-0.6-0.2-0.8-0.8-0.8H495v0.9h6.4c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.3v0.9
	h7.1c0.6,0,0.8-0.2,0.8-0.8v-8L503.1,281.1L503.1,281.1z"
          />
          <path
            fill="#FFFFFF"
            d="M502.2,282c0-0.5-0.2-0.7-0.8-0.7H495v7.7h6.3c0.6,0,0.8-0.2,0.8-0.7V282L502.2,282L502.2,282z M496.4,277.9
	l-0.5,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6h1v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.4-0.1L496.4,277.9L496.4,277.9z M495,273.8h0.7l-1.4-2.3
	h-24.4l0.7,2.3 M495.1,289.9h-22.3c-0.1,10.6-2.9,20-8.4,28.4h30.8L495.1,289.9L495.1,289.9z"
          />
          <path
            fill="#5E9FF1"
            d="M528.8,180.8c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.4v0.9c0,0.1,0,0.2,0.1,0.4
	h0.1v78.8l0.5,3.5l0.1,0.1v-82.3c0.1-0.1,0.1-0.2,0.1-0.4v-0.9C529,181,528.9,180.9,528.8,180.8L528.8,180.8z"
          />
          <path
            fill="#FFFFFF"
            d="M530.3,276.8c-0.2-1.2-0.6-2-1-2.7l-0.5-0.5h-1.3c0.6,0.5,0.8,2.1,0.6,4.9v28.1l-0.5,27.6v1.2h0.7l0.1-2.7
	l0.5-9.3c0.1-11.5,0.3-20.4,0.9-26.3c0.3-3.5,0.7-6.1,1.2-7.7l0.1-2v-2.1c0-0.7-0.2-1-0.5-1.2v-4.3
	C530.6,278.9,530.5,277.8,530.3,276.8L530.3,276.8z"
          />
          <path
            fill="#5E9FF1"
            d="M531.2,287.7l-0.1,2l0.1-0.2 M552.6,321.6v0.1"
          />
          <path
            fill="#FFFFFF"
            d="M528.9,340.2h-4.7c-0.8,0.4-1.5,0.8-2.2,1.4c-1.3,1.3-1.9,2.8-1.9,4.5s0.6,3.3,1.9,4.5
	c0.5,0.5,0.9,0.8,1.5,1.2h1.9c-1-0.2-2.1-0.7-2.9-1.6c-1.2-1.2-1.6-2.4-1.6-4.1s0.6-2.9,1.6-4.1c1.2-1.2,2.4-1.6,4.1-1.6
	c1.6,0,2.9,0.6,4.1,1.6c1.2,1.2,1.6,2.4,1.6,4.1s-0.6,2.9-1.6,4.1c-0.8,0.8-1.9,1.4-2.9,1.6h1.9c0.6-0.2,1-0.7,1.5-1.2
	c1.3-1.3,1.9-2.8,1.9-4.5s-0.6-3.3-1.9-4.5C530.4,341.1,529.7,340.6,528.9,340.2z"
          />
          <path fill="#5E9FF1" d="M526.6,352l1.2-0.1h-2.3" />
          <path
            fill="#FFFFFF"
            d="M527.7,335.6v-1.2H516c0.2,0.4,0.7,0.6,1,0.8c0.6,0.2,1.3,0.4,2.2,0.4L527.7,335.6L527.7,335.6z"
          />
          <path fill="#5E9FF1" d="M515.7,334.1v0.4h0.2" />
          <path
            fill="#FFFFFF"
            d="M502.2,320c0-0.5-0.2-0.7-0.8-0.7h-6.2v7.7h6.2c0.6,0,0.8-0.2,0.8-0.7L502.2,320L502.2,320z"
          />
          <path
            fill="#FFFFFF"
            d="M501.4,319.3c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.2v0.9h7c0.6,0,0.8-0.2,0.8-0.8v-7.9
	c0-0.6-0.2-0.8-0.8-0.8h-7v0.9h6.2L501.4,319.3L501.4,319.3z"
          />
          <path
            fill="#FFFFFF"
            d="M506,316.7c0-0.6-0.2-0.8-0.8-0.8h-7.9l-0.4,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6h5.6c0.6,0,0.8,0.2,0.8,0.8
	v7.9c0,0.6-0.2,0.8-0.8,0.8h-5.5l0.1,2c0.1,0.2,0.4,0.4,0.7,0.4h7.8c0.6,0,0.8-0.2,0.8-0.8L506,316.7L506,316.7L506,316.7z"
          />
          <path
            fill="#FFFFFF"
            d="M497.1,315.9l0.4-0.1h-1l-0.5,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6h1v-1.6C496.6,316.2,496.7,316,497.1,315.9z
	 M495.2,319.3v-0.9h-30.8l-0.6,0.9 M495.2,327.9V327h-37.8l-0.9,0.9 M496.6,327.9h-1v1.5l0.1,0.5c0.1,0.2,0.4,0.4,0.7,0.4h1
	c-0.4,0-0.6-0.1-0.7-0.4L496.6,327.9L496.6,327.9z"
          />
          <path
            fill="#FFFFFF"
            d="M494.7,334.1c0-0.5,0.1-0.7,0.5-0.8v-5.5h-38.7c-2.8,2.8-5.8,5.2-9.1,7.2h47
	C494.6,334.8,494.7,334.5,494.7,334.1L494.7,334.1z M495.2,327v-7.7h-31.4l-1.2,1.6h27.4c0.6,0,0.8,0.2,0.8,0.8v3
	c0,0.6-0.2,0.8-0.8,0.8h-31.2l-1.4,1.5L495.2,327L495.2,327z"
          />
          <path
            fill="#FFFFFF"
            d="M490.9,324.6v-3c0-0.6-0.2-0.8-0.8-0.8h-27.4l-0.6,0.7c-1,1.4-2.1,2.7-3.3,4h31.2
	C490.7,325.4,490.9,325.2,490.9,324.6L490.9,324.6z M492.2,336.4c1-0.1,1.8-0.6,2.2-1.3h-47c-8.4,5.4-18,8-28.6,8s-20.2-2.7-28.6-8
	h-47c0.5,0.7,1.2,1,2.2,1.3l3.4,0.1c8,0.2,18.4,0.4,31.5,0.2c0.8,0.1,1.3,0.5,1.5,1h8c9.2,4.1,18.6,6.2,28.2,6.4l3.4-0.1
	c9.1-0.5,18-2.6,26.6-6.4h8c0.1-0.6,0.7-0.9,1.5-1C472.2,336.9,483.9,336.7,492.2,336.4L492.2,336.4z"
          />
          <path fill="#5E9FF1" d="M477.8,375.3l0.2-0.9h-0.1" />
          <path
            fill="#FFFFFF"
            d="M477.8,375.3v-0.8c0-1.3-0.5-2.3-1.4-3.3l-0.7-0.6c-0.8-0.6-1.7-0.8-2.8-0.8c-1,0-2,0.2-2.8,0.8
	c-0.2,0.2-0.5,0.4-0.7,0.6c-0.6,0.6-0.9,1.2-1.2,2v0.1c-0.1,0.4-0.2,0.8-0.2,1.3v0.1c0,0.8,0.2,1.5,0.6,2.2c0.2,0.5,0.5,0.8,0.9,1.3
	l1,0.8c0.7,0.4,1.5,0.6,2.3,0.6c0.9,0,1.6-0.2,2.4-0.6l1-0.8c0.4-0.4,0.7-0.8,0.9-1.3l0.1-0.4C477.7,376.2,477.8,375.7,477.8,375.3
	L477.8,375.3z M476,371.7c0.8,0.8,1.2,1.7,1.2,2.8v0.1c0,0.8-0.2,1.6-0.7,2.2l-0.6,0.7c-0.8,0.8-1.8,1.2-2.9,1.2
	c-1.2,0-2.1-0.4-2.9-1.2l-0.5-0.7c-0.5-0.7-0.7-1.4-0.7-2.2v-0.1c0-1,0.5-2,1.2-2.8c0.6-0.6,1.4-1,2.2-1.2h1.5
	C474.6,370.7,475.3,371,476,371.7L476,371.7z M469,370.6l0.2-0.8l-12.2,0.8"
          />
          <path fill="#5E9FF1" d="M321.9,334.1l-0.2,0.4h0.2" />
          <path
            fill="#FFFFFF"
            d="M307.1,342.2c1.2-1.2,2.4-1.6,4.1-1.6s2.9,0.6,4.1,1.6c1.2,1.2,1.6,2.4,1.6,4.1c0,1.6-0.6,2.9-1.6,4.1
	c-0.8,0.8-1.9,1.4-2.9,1.6h1.9c0.5-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5c0-1.8-0.6-3.3-1.9-4.5c-0.7-0.7-1.4-1.2-2.2-1.4h-4.7
	c-0.8,0.4-1.5,0.8-2.2,1.4c-1.3,1.3-1.9,2.8-1.9,4.5c0,1.7,0.6,3.3,1.9,4.5c0.5,0.5,0.9,0.8,1.5,1.2h1.9c-1-0.2-2.1-0.7-2.9-1.6
	c-1.2-1.2-1.6-2.5-1.6-4.1C305.3,344.7,305.9,343.3,307.1,342.2L307.1,342.2z"
          />
          <path fill="#5E9FF1" d="M311,352l1.2-0.1H310" />
          <path
            fill="#FFFFFF"
            d="M321.6,334.4h-11.8v1.2h8.5C319.9,335.6,321,335.2,321.6,334.4L321.6,334.4z"
          />
          <path fill="#5E9FF1" d="M285,321.6v0.1V321.6z" />
          <path
            fill="#EBEBEB"
            d="M302,242.6c0-2.3-0.9-3.5-2.9-3.7c-6.1-0.2-11.8-0.1-17.1,0.1l-1.4,0.2h-0.2c-1.5,0-2.4,0.6-2.8,2
	c-0.4,1.6-0.5,3.7-0.5,6.5v8.4c-0.1,2.3,1,3.6,3.3,3.8h17.7c2.8-0.2,4.1-1.4,4-3.5L302,242.6L302,242.6z M318.4,292.3h-5.5v11.4
	c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4L318.4,292.3L318.4,292.3z M319.8,292.8l1.8-0.2c0.7,0,1.3,0.1,1.9,0.2
	c0.9,0.2,1.8,0.8,2.4,1.5c1.3,1.3,1.9,2.7,1.9,4.4c0,1.7-0.6,3.1-1.9,4.4c-1.2,1.3-2.7,1.9-4.4,1.9s-3.1-0.6-4.4-1.9
	c-1.2-1.2-1.9-2.7-1.9-4.4c0-1.8,0.6-3.1,1.9-4.4C317.9,293.6,318.7,293.2,319.8,292.8L319.8,292.8z"
          />
          <path
            fill="#EBEBEB"
            d="M321.5,292.6l-1.8,0.2c-1,0.2-1.9,0.8-2.7,1.6c-1.2,1.3-1.9,2.7-1.9,4.4c0,1.7,0.6,3.1,1.9,4.4
	c1.3,1.3,2.7,1.9,4.4,1.9s3.1-0.6,4.4-1.9c1.3-1.2,1.9-2.7,1.9-4.4c0-1.8-0.6-3.1-1.9-4.4c-0.7-0.7-1.5-1.3-2.4-1.5
	C322.8,292.7,322.2,292.6,321.5,292.6L321.5,292.6z M317.5,302.8c-1-1-1.6-2.3-1.6-4c0-1.5,0.6-2.9,1.6-4c1-1,2.4-1.6,4-1.6
	c1.5,0,2.9,0.6,4,1.6c1,1,1.6,2.5,1.6,4c0,1.5-0.6,2.9-1.6,4c-1,1-2.4,1.6-4,1.6C319.9,304.5,318.6,303.9,317.5,302.8L317.5,302.8z"
          />
          <path
            fill="#EBEBEB"
            d="M315.9,298.9c0,1.5,0.6,2.9,1.6,4c1,1,2.4,1.6,4,1.6c1.5,0,2.9-0.6,4-1.6c1-1,1.6-2.3,1.6-4
	c0-1.5-0.6-2.9-1.6-4c-1-1-2.4-1.6-4-1.6c-1.5,0-2.9,0.6-4,1.6C316.4,296,315.9,297.2,315.9,298.9L315.9,298.9z M321.5,300.3
	c-0.4,0-0.7-0.1-0.9-0.5c-0.2-0.2-0.5-0.6-0.5-1c0-0.4,0.1-0.7,0.5-0.9c0.2-0.2,0.6-0.5,0.9-0.5s0.7,0.1,1,0.5
	c0.2,0.2,0.5,0.6,0.5,0.9s-0.1,0.7-0.5,1C322.2,300.1,321.9,300.3,321.5,300.3L321.5,300.3z"
          />
          <path
            fill="#EBEBEB"
            d="M320.5,299.8c0.2,0.2,0.6,0.5,0.9,0.5s0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1c0-0.4-0.1-0.7-0.5-0.9
	c-0.2-0.2-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-0.9,0.5c-0.2,0.2-0.5,0.6-0.5,0.9C320.1,299.2,320.2,299.6,320.5,299.8L320.5,299.8z
	 M444.3,270.2c-0.9-1.2-1.9-2.2-2.9-3.3c-6.2-6.2-13.6-9.3-22.4-9.3l-4.9,0.4c-0.9,0.8-1.4,2-1.5,3.5l-1.3,13.5
	c0,0.7-0.1,1.3-0.4,1.8c-0.4,0.5-0.7,0.8-1.2,0.9l-0.2,0.1l-1.4-0.1l-13.6-3.1c-1.4-0.4-2.7-0.2-3.7,0.4c1-0.8,2.3-1.2,4.2-1v-0.1
	c0.5-0.8,1-1.5,1.7-2.3l0.8-0.9l1.3-1.4c3.8-3.8,8.2-6.3,13.1-7.6c0.5-1.8,1.2-2.9,2.2-3.6c-6.6,0.9-12.6,4-17.5,9
	c-1,1-2,2.2-2.9,3.3l-0.9,1.3c-0.6,0.7-1,1.5-1.5,2.3c-0.7,1.3-1.3,2.6-1.9,3.8l-0.5,1.2c-0.6,1.8-1,3.6-1.3,5.5
	c0.2,0.6,0.7,1.2,1.3,1.6c0.5,0.4,0.9,0.6,1.5,0.8l6.2,2.6H408c-1.3-0.5-2.3-1.2-3.1-2c-0.8-0.9-1-1.9-0.8-2.8
	c0.2-0.9,1-1.5,2.2-1.8c1.2-0.2,2.6-0.2,4,0.2l0.8,0.2c0.6,0.4,1,0.8,1.4,1.5c0.5,0.8,0.5,1.6,0.2,2.6c-0.2,0.9-0.8,1.5-1.6,2h3.4
	c0-1.3,0.5-2.3,1.3-3.1c0.9-0.9,2-1.3,3.1-1.3c1.3,0,2.3,0.5,3.1,1.3c0.9,0.9,1.4,2,1.4,3.1h3.4c-0.1,0-0.1-0.1-0.2-0.1
	c-0.8-0.5-1.4-1-1.6-2c-0.2-0.9-0.2-1.8,0.2-2.6c0.4-0.7,0.8-1.2,1.5-1.5l0.8-0.2c1.4-0.5,2.8-0.6,4-0.2c1.3,0.2,2,0.9,2.2,1.8
	c0.2,0.9,0,1.9-0.8,2.8s-1.9,1.6-3.4,2.1h12.1l5.6-2.3h0.1c1.2-0.5,2-1.2,2.6-2l0.2-0.5l-0.2,0.6c-0.5,0.9-1.3,1.9-2.4,2.7v1.6h3.1
	c0-2.4-0.2-4.8-0.7-7c-0.4-1.6-0.8-3.1-1.4-4.7c-0.5-1.3-1.2-2.6-1.9-3.8L444.3,270.2L444.3,270.2z M425.4,261.6v-0.1
	c-0.1-1.4-0.6-2.6-1.5-3.4c1,0.7,1.7,1.9,2.2,3.6c4.9,1.2,9.1,3.7,12.9,7.5l2.9,3.4l0.9,1.3l0.1,0.2c1.9-0.1,3.1,0.2,4.2,1
	c-1-0.6-2.2-0.7-3.7-0.4l-13.3,3c-0.6,0.2-1.2,0.2-1.7,0.2l-0.6-0.2c-0.4-0.2-0.7-0.5-0.8-0.8c-0.2-0.5-0.4-1-0.4-1.6L425.4,261.6
	L425.4,261.6z M419.4,273.8c0.7,0.2,1.2,0.7,1.6,1.5c0.5,0.7,0.7,1.5,0.8,2.3l0.1,2.3c-0.1,0.7-0.5,1.3-0.9,1.9
	c-0.7,0.7-1.4,0.9-2.3,0.9s-1.8-0.4-2.5-0.9c-0.5-0.6-0.8-1.2-0.9-1.9v-0.8c0-0.6,0-1,0.1-1.5c0.1-0.9,0.5-1.6,0.8-2.3
	c0.5-0.8,1-1.3,1.8-1.5l0.7-0.1L419.4,273.8L419.4,273.8z"
          />
          <path
            fill="#EBEBEB"
            d="M414.4,289.3H411c-0.7,0.4-1.3,0.5-2.1,0.4l-0.8-0.2c-0.1,0-0.2-0.1-0.2-0.1h-11.4l6.4,2.8
	c0.7,0.2,1.2,0.5,1.5,0.8c0.5,0.6,0.6,1.2,0.6,1.7c-0.1,0.5-0.4,1-0.7,1.6l-7,11.7c-0.8,1.3-1,2.5-0.8,3.7c-0.5-1.2-0.4-2.7,0.4-4.3
	c-4.3-5.1-6.4-11.2-6.4-18.1h-3.1c0,3.7,0.6,7.2,1.8,10.6c1.5,4.3,4.1,8.3,7.6,11.9c6.2,6.2,13.6,9.3,22.4,9.3
	c8.7,0,16.2-3,22.4-9.2c0.1-1.2-0.1-2.3-0.9-3.6l-7-11.8c-0.4-0.5-0.7-1-0.7-1.6c0-0.7,0.1-1.3,0.6-1.7c0.4-0.4,0.8-0.7,1.5-0.9l7-3
	h-12.1l-0.8,0.2c-0.7,0.1-1.3,0-1.9-0.2h-3.4c0,1.3-0.5,2.3-1.4,3.3c-0.9,0.8-2,1.3-3.1,1.3c-1.3,0-2.3-0.5-3.1-1.3
	C414.8,291.6,414.4,290.6,414.4,289.3L414.4,289.3z M410.7,294.9c0.5-0.5,1.2-0.8,1.9-0.9c0.9-0.1,1.8,0,2.4,0.6
	c0.7,0.6,1.2,1.3,1.3,2.2c0.1,0.7,0,1.4-0.4,2l-0.5,0.7c-0.9,1.3-1.9,2.1-3,2.6s-2.1,0.5-2.8-0.1c-0.7-0.6-1-1.4-0.9-2.7
	c0.1-1.3,0.6-2.5,1.5-3.7L410.7,294.9L410.7,294.9z M416.5,305.3c0.4-0.6,0.8-0.9,1.3-1.2c0.7-0.2,1.3-0.2,1.9,0
	c0.5,0.2,0.9,0.6,1.3,1.2l9.1,10.4c0.9,1,2,1.6,3.1,1.9c-1.3,0.1-2.6-0.5-4-1.5c-3.1,1.3-6.6,1.9-10.4,1.9c-3.8,0-7.3-0.7-10.6-2
	c-1.4,1.2-2.8,1.6-4,1.6c1.2-0.1,2.3-0.8,3.3-2L416.5,305.3L416.5,305.3z M421.3,296.6c0.1-0.9,0.6-1.6,1.4-2.2
	c0.7-0.6,1.5-0.7,2.5-0.6c0.7,0.1,1.3,0.5,1.9,0.9l0.6,0.7c0.9,1.2,1.4,2.4,1.5,3.7c0.1,1.3-0.2,2.1-0.9,2.7
	c-0.7,0.6-1.6,0.6-2.8,0.1s-2.2-1.3-3-2.6l-0.5-0.7C421.3,298,421.2,297.3,421.3,296.6L421.3,296.6z"
          />
          <path
            fill="#EBEBEB"
            d="M450.6,289.3h-3.1c0,7-2.2,13.1-6.5,18.2c0.7,1.6,0.8,3,0.4,4.2l0.1-0.1c4.4-4.4,7.3-9.6,8.5-15.4
	C450.3,294.1,450.6,291.8,450.6,289.3L450.6,289.3z M427,269.4l-0.8-7.7c-0.5-1.6-1.3-2.9-2.2-3.5c0.8,0.8,1.4,2,1.5,3.4v0.1
	l1.3,13.6c0,0.7,0.1,1.3,0.4,1.6c0.2,0.4,0.5,0.6,0.8,0.8l0.6,0.2c0.5,0.1,1,0,1.7-0.2l13.3-3c1.5-0.4,2.7-0.2,3.7,0.4
	c-0.9-0.8-2.3-1.2-4.2-1l-7.6,1.6l-3.7,0.8c-1.3,0.2-2.2,0.1-2.9-0.5c-0.7-0.4-1-1-1.3-2.1L427,269.4L427,269.4z M422,277.6
	c-0.1-0.9-0.5-1.6-0.8-2.3c-0.5-0.8-1-1.3-1.6-1.5h-1.4c-0.7,0.2-1.3,0.7-1.7,1.5c-0.5,0.7-0.7,1.5-0.8,2.3
	c-0.1,0.5-0.1,0.9-0.1,1.5v0.8c0.1,0.7,0.5,1.3,0.9,1.9c0.7,0.7,1.5,0.9,2.4,0.9s1.8-0.4,2.3-0.9c0.5-0.6,0.8-1.2,0.9-1.9L422,277.6
	L422,277.6z M420.8,277.4l0.2,0.2c0.5,0.5,0.7,1.2,0.7,1.9c0,0.8-0.2,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2,0.9c-0.8,0-1.5-0.2-2.1-0.9
	c-0.6-0.6-0.8-1.3-0.8-2.1c0-0.7,0.2-1.3,0.7-1.9l0.2-0.2c0.6-0.6,1.3-0.9,2.1-0.9C419.7,276.5,420.2,276.8,420.8,277.4L420.8,277.4
	z"
          />
          <path
            fill="#EBEBEB"
            d="M421.1,277.6l-0.2-0.2c-0.6-0.6-1.3-0.9-2-0.9c-0.8,0-1.5,0.2-2.1,0.9l-0.2,0.2c-0.5,0.5-0.7,1.2-0.7,1.9
	c0,0.8,0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.2,2-0.9c0.6-0.6,0.9-1.3,0.9-2.1C421.8,278.8,421.5,278.1,421.1,277.6
	L421.1,277.6z M411,283.4l-0.8-0.2c-1.4-0.5-2.8-0.6-4-0.2c-1.3,0.2-2,0.9-2.2,1.8c-0.4,0.9,0,1.9,0.8,2.8s2,1.6,3.4,2.1l0.8,0.2
	c0.7,0.1,1.4,0,2.1-0.4c0.8-0.5,1.4-1,1.6-2c0.2-0.9,0.2-1.8-0.2-2.6C412.1,284.2,411.6,283.6,411,283.4L411,283.4z M412.1,285
	c0.4,0.7,0.5,1.4,0.2,2.2c-0.2,0.8-0.7,1.3-1.5,1.8c-0.7,0.4-1.5,0.5-2.2,0.1c-0.8-0.2-1.3-0.7-1.8-1.4c-0.4-0.7-0.5-1.5-0.2-2.2
	c0.2-0.7,0.7-1.3,1.4-1.8c0.7-0.4,1.5-0.5,2.2-0.2C411.2,283.7,411.7,284.2,412.1,285L412.1,285z"
          />
          <path
            fill="#EBEBEB"
            d="M412.2,287.2c0.2-0.8,0.2-1.5-0.2-2.2c-0.4-0.7-0.9-1.3-1.6-1.5c-0.8-0.2-1.5-0.2-2.2,0.2
	c-0.7,0.4-1.2,0.9-1.4,1.7c-0.2,0.7-0.2,1.5,0.2,2.2c0.4,0.7,0.9,1.2,1.8,1.4c0.7,0.2,1.5,0.2,2.2-0.1
	C411.5,288.5,412,287.9,412.2,287.2L412.2,287.2z M422.7,294.5c-0.7,0.5-1.2,1.3-1.4,2.2c-0.1,0.7,0,1.4,0.4,2.1l0.5,0.7
	c0.9,1.3,1.9,2.1,3,2.6s2.1,0.5,2.8-0.1c0.8-0.6,1-1.4,0.9-2.7c-0.1-1.3-0.6-2.6-1.5-3.7l-0.6-0.7c-0.5-0.5-1.2-0.8-1.9-0.9
	C424.2,293.7,423.4,294,422.7,294.5L422.7,294.5z M421.8,296.8c0.1-0.8,0.5-1.4,1.2-1.9c0.7-0.5,1.4-0.7,2.2-0.5
	c0.8,0.1,1.4,0.5,1.9,1.2c0.5,0.7,0.7,1.4,0.5,2.2c-0.1,0.8-0.5,1.4-1.2,1.9c-0.7,0.5-1.4,0.7-2.2,0.5c-0.8-0.1-1.4-0.5-1.9-1.2
	C421.8,298.3,421.6,297.6,421.8,296.8L421.8,296.8z"
          />
          <path
            fill="#EBEBEB"
            d="M422.9,294.9c-0.7,0.5-1,1-1.2,1.9c-0.1,0.8,0,1.5,0.5,2.2c0.5,0.7,1,1,1.9,1.2c0.8,0.1,1.5,0,2.2-0.5
	c0.7-0.5,1-1.2,1.2-1.9c0.1-0.8,0-1.5-0.5-2.2c-0.5-0.7-1.2-1-1.9-1.2C424.2,294.2,423.5,294.4,422.9,294.9L422.9,294.9z M416,298.7
	c0.4-0.7,0.5-1.3,0.4-2c-0.1-0.9-0.6-1.6-1.3-2.2c-0.7-0.6-1.6-0.7-2.4-0.6c-0.7,0.1-1.4,0.5-1.9,0.9l-0.5,0.7
	c-0.9,1.3-1.4,2.4-1.5,3.7c-0.1,1.3,0.2,2.1,0.9,2.7c0.7,0.6,1.6,0.6,2.8,0.1c1.2-0.5,2.2-1.3,3-2.6L416,298.7L416,298.7z
	 M414.8,294.9c0.7,0.5,1,1.2,1.2,2s0,1.5-0.5,2.2s-1,1-1.9,1.2c-0.8,0.1-1.5-0.1-2.2-0.6c-0.7-0.5-1-1-1.2-1.9
	c-0.1-0.8,0-1.5,0.5-2.2c0.5-0.7,1-1,1.9-1.2C413.4,294.2,414.1,294.4,414.8,294.9L414.8,294.9z"
          />
          <path
            fill="#EBEBEB"
            d="M415.9,296.8c-0.1-0.8-0.5-1.4-1.2-2c-0.7-0.5-1.4-0.7-2.2-0.5c-0.8,0.1-1.4,0.5-1.9,1.2s-0.7,1.4-0.5,2.2
	c0.1,0.8,0.5,1.4,1.2,1.9c0.7,0.5,1.4,0.7,2.2,0.6c0.8-0.1,1.4-0.6,1.9-1.2C415.9,298.3,416.1,297.6,415.9,296.8L415.9,296.8z
	 M418,306h0.7l0.7,0.1c0.7,0.1,1.3,0.6,2,1.3l7.9,8.7c1.4,1,2.7,1.6,4,1.5c-1.2-0.1-2.2-0.7-3.1-1.9l-9.1-10.4
	c-0.4-0.6-0.8-0.9-1.3-1.2c-0.6-0.2-1.2-0.2-1.9,0c-0.5,0.2-0.9,0.6-1.3,1.2l-9,10.3c-0.9,1.2-2.1,1.8-3.3,2c1.3,0.1,2.6-0.5,4-1.6
	l7.8-8.6C416.7,306.6,417.3,306.2,418,306z M427.5,282.9l-0.8,0.4c-0.7,0.4-1.2,0.8-1.5,1.5c-0.5,0.8-0.5,1.6-0.2,2.6
	c0.2,0.8,0.8,1.5,1.6,2c0.7,0.4,1.3,0.5,2.1,0.4l0.8-0.2c1.5-0.5,2.6-1.2,3.4-2.1s1-1.9,0.8-2.8c-0.2-0.9-1-1.5-2.2-1.8
	C430.2,282.4,428.9,282.4,427.5,282.9L427.5,282.9z M429.5,283.6c0.7,0.4,1.2,0.9,1.4,1.7c0.2,0.7,0.2,1.5-0.1,2.2
	c-0.4,0.7-0.9,1.3-1.8,1.5c-0.8,0.2-1.5,0.2-2.2-0.2c-0.7-0.4-1.3-0.9-1.5-1.6c-0.2-0.8-0.2-1.5,0.2-2.2c0.4-0.7,0.9-1.2,1.8-1.4
	C428.1,283.2,428.8,283.2,429.5,283.6L429.5,283.6z"
          />
          <path
            fill="#EBEBEB"
            d="M431,285.3c-0.2-0.8-0.7-1.4-1.4-1.8c-0.7-0.4-1.5-0.5-2.2-0.1c-0.8,0.2-1.3,0.7-1.8,1.4
	c-0.4,0.7-0.5,1.5-0.2,2.2c0.2,0.8,0.7,1.3,1.5,1.6c0.7,0.4,1.4,0.5,2.2,0.2s1.4-0.7,1.7-1.5C431.1,286.9,431.2,286,431,285.3
	L431,285.3z M387.5,284.3c0.2,0.8,0.7,1.5,1.3,2.1c0.4,0.4,0.8,0.7,1.4,1h0.1l10.5,4.8c1.2,0.5,1.9,1.2,2.1,2.1
	c0.4,0.8,0.2,1.8-0.5,2.9l-5.7,10.1c-0.7,1.6-0.8,3.1-0.4,4.3c-0.2-1.2,0.1-2.4,0.8-3.7l7-11.7c0.4-0.5,0.7-1,0.7-1.6
	c0.1-0.6-0.1-1.2-0.6-1.8c-0.4-0.4-0.8-0.7-1.5-0.8l-12.6-5.4c-0.6-0.2-1-0.5-1.5-0.8C388.3,285.5,387.8,284.9,387.5,284.3
	L387.5,284.3z M411.7,261.6l-0.8,7.7l-0.6,4.4c-0.2,0.9-0.6,1.5-1.3,2c-0.7,0.6-1.6,0.7-2.9,0.5l-3.7-0.8l-7.6-1.6h-0.1
	c-1.8-0.1-3.1,0.2-4.2,1c1-0.6,2.3-0.7,3.7-0.4l13.6,3.1l1.4,0.1l0.2-0.1c0.5-0.1,0.9-0.5,1.2-0.9c0.2-0.5,0.4-1,0.4-1.8l1.3-13.5
	c0.1-1.5,0.6-2.7,1.5-3.5C412.9,258.6,412.2,259.9,411.7,261.6L411.7,261.6z M441.6,290.4l5.7-2.6h0.1c1.3-0.8,2.1-1.6,2.4-2.7
	l0.2-0.6l-0.2,0.5c-0.6,0.8-1.4,1.5-2.6,2h-0.1l-12.6,5.4c-0.7,0.2-1.2,0.5-1.5,0.8c-0.4,0.5-0.6,1-0.6,1.8c0.1,0.5,0.4,1,0.7,1.6
	l7,11.8c0.8,1.3,1,2.5,0.9,3.6c0.4-1.2,0.2-2.6-0.4-4.2l-5.7-10c-0.6-1.2-0.8-2.1-0.5-2.9c0.1-0.4,0.2-0.7,0.5-0.9
	c0.4-0.5,0.8-0.8,1.6-1.2L441.6,290.4L441.6,290.4z M512.7,292.3h-5v11.4c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4
	L512.7,292.3L512.7,292.3z M517.1,292.7l0.8,0.1c1,0.2,1.9,0.8,2.7,1.6c1.2,1.3,1.9,2.7,1.9,4.4c0,1.7-0.6,3.1-1.9,4.4
	c-1,1-2.2,1.6-3.5,1.7l-0.9,0.1c-1.7,0-3.1-0.6-4.4-1.9c-1.3-1.2-1.9-2.7-1.9-4.4c0-1.8,0.6-3.1,1.9-4.4c0.7-0.7,1.5-1.3,2.4-1.5
	c0.6-0.2,1.3-0.2,1.9-0.2L517.1,292.7L517.1,292.7z"
          />
          <path
            fill="#EBEBEB"
            d="M517.9,292.8l-0.8-0.1l-0.9-0.1c-0.7,0-1.3,0.1-1.9,0.2c-0.9,0.2-1.7,0.8-2.4,1.5c-1.3,1.3-1.9,2.7-1.9,4.4
	c0,1.7,0.6,3.1,1.9,4.4c1.2,1.3,2.7,1.9,4.4,1.9l0.9-0.1c1.4-0.2,2.6-0.8,3.5-1.7c1.2-1.2,1.9-2.7,1.9-4.4c0-1.8-0.6-3.1-1.9-4.4
	C519.8,293.6,518.9,293.2,517.9,292.8L517.9,292.8z M516.2,293.3l0.9,0.1c1.2,0.1,2.2,0.7,3,1.5c1,1,1.6,2.4,1.6,4s-0.6,2.9-1.6,4
	c-0.8,0.8-1.9,1.4-3,1.6l-0.9,0.1c-1.5,0-2.9-0.6-4-1.6c-1-1-1.6-2.3-1.6-4c0-1.5,0.6-2.9,1.6-4
	C513.3,293.7,514.7,293.3,516.2,293.3L516.2,293.3z"
          />
          <path
            fill="#EBEBEB"
            d="M517.1,293.3l-0.9-0.1c-1.5,0-2.9,0.6-4,1.6c-1,1-1.6,2.4-1.6,4s0.6,2.9,1.6,4c1,1,2.4,1.6,4,1.6l0.9-0.1
	c1.2-0.2,2.2-0.7,3-1.6c1-1.1,1.6-2.3,1.6-4c0-1.5-0.6-2.9-1.6-4C519.2,294,518.3,293.5,517.1,293.3L517.1,293.3z M517.1,297.8
	c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.7-0.5,1L517,300c-0.2,0.2-0.6,0.4-0.9,0.4s-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1
	c0-0.4,0.1-0.7,0.5-0.9c0.2-0.2,0.6-0.5,1-0.5S516.8,297.6,517.1,297.8L517.1,297.8z"
          />
          <path
            fill="#EBEBEB"
            d="M517.1,297.8c-0.3-0.4-0.6-0.5-0.9-0.5s-0.7,0.1-1,0.5c-0.2,0.2-0.5,0.6-0.5,0.9s0.1,0.7,0.5,1
	c0.2,0.2,0.6,0.5,1,0.5c0.3,0,0.7-0.1,0.9-0.4l0.1-0.1c0.2-0.2,0.5-0.6,0.5-1C517.6,298.4,517.5,298.2,517.1,297.8L517.1,297.8z
	 M560.5,247.6c0-2.8-0.1-4.9-0.5-6.5c-0.3-1.3-1.3-2-2.8-2H557l-1.4-0.2c-5.4-0.2-11-0.4-17.1-0.1c-2,0.2-2.9,1.4-2.9,3.7v13.8
	c-0.1,2.1,1.3,3.3,4,3.5h17.6c2.2-0.2,3.3-1.5,3.3-3.8L560.5,247.6L560.5,247.6z M478.5,364.6c-0.6-1.4-1.6-2.2-3-2.7H457v8.6
	l12.2-0.7c0.4-1.6,0.4-2.8,0.1-3.5c0.2,0.7,0.2,1.9-0.1,3.5l-0.1,0.7l-0.7,2.6c0.2-0.7,0.6-1.4,1.2-2c0.2-0.2,0.5-0.5,0.7-0.6
	c0.8-0.6,1.7-0.8,2.8-0.8s2,0.2,2.8,0.8l0.7,0.6c0.9,0.9,1.4,2,1.4,3.3h0.2l0.9-3.8l0.1-1.2c0.1-0.7,0.1-1.4,0.1-2.1
	C479.1,366.2,478.9,365.4,478.5,364.6L478.5,364.6z M397.9,356.7c-0.4,0.2-0.7,0.4-1.2,0.4s-0.8-0.1-1.2-0.4h-10.8
	c-0.2,0-0.5,0.1-0.5,0.5v17.1l0.1,0.2c0.1,0.1,0.1,0.1,0.4,0.1h70.9l0.2-0.1l0.1-0.2v-17.1c0-0.2-0.1-0.5-0.5-0.5h-13.5
	c-0.4,0.2-0.7,0.4-1.2,0.4c-0.5,0-0.8-0.1-1.2-0.4H397.9L397.9,356.7z M337,214.1l-0.6,6.5c-1.2,13.8-2.1,28.9-2.7,45.6
	c0.1,2,1.2,3,3.4,3h7.7l-0.7,1h24l0.4-0.9c1.3-3.1,2.8-6.1,4.7-9c0.7-1,1.5-2.2,2.3-3.3c1.2-1.5,2.5-3.1,3.8-4.5l1.3-1.3
	c8.6-8.6,18.8-13.8,30.3-15.3l-0.5-5.1l-4.5-0.1c-0.5,0-0.8-0.1-0.9-0.5c-0.2-0.2-0.2-0.6-0.1-1.1l1.4-5c0.1-0.7,0.6-1.1,1.4-1.1
	h22.1c0.7,0,1.2,0.4,1.4,1.1l1.4,5c0.1,0.5,0.1,0.8-0.1,1.1c-0.1,0.2-0.5,0.5-0.9,0.5l-4.5,0.1l-0.5,5.1
	c11.5,1.5,21.7,6.6,30.3,15.3l1.3,1.3c1.4,1.5,2.7,3,3.8,4.5l0.2,0.2c2.7,3.5,4.8,7.2,6.4,11.2l0.7,1.6h24l-0.7-1h7.7
	c2.2,0,3.4-1,3.4-3c-0.6-16.7-1.5-31.8-2.7-45.6l-0.6-6.5c-0.1-4.7-2.7-7.3-7.7-7.8l-13.2-0.8v-6.2c0-0.6-0.4-0.8-1-0.8h-7.5
	c-0.6,0-0.9,0.2-1,0.7v6.2c-5.7-0.1-11.8-0.1-18.1-0.1h-66.6c-6.3-0.1-12.4,0-18.1,0.1v-6.2c-0.1-0.5-0.5-0.7-1-0.7h-7.5
	c-0.7,0-1,0.2-1,0.8v6.4l-13.2,0.8C339.7,206.8,337.2,209.5,337,214.1L337,214.1z M365.8,199c0.6,0,0.8,0.2,0.8,0.8v7.3l-1.5,6.5
	c-0.1,0.6-0.6,0.9-1.4,1l-1.9-0.1c-0.7,0-1.2-0.2-1.3-0.7l-1.4-6.9v-7.3c0-0.6,0.2-0.8,0.8-0.8L365.8,199L365.8,199z M477.7,199
	c0.6,0,0.8,0.2,0.8,0.8v7.3l-1.4,6.9c-0.1,0.5-0.6,0.7-1.3,0.7l-1.9,0.1c-0.8-0.1-1.4-0.5-1.4-1.1l-1.5-6.5v-7.3
	c0-0.6,0.2-0.8,0.8-0.8h5.8V199L477.7,199z M317.2,279.5l-0.8,0.7c-1.4,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.8,2.2,5.2
	c0.6,0.7,1.3,1.2,2.1,1.5h6.5c0.7-0.4,1.4-0.8,2-1.5c1.5-1.4,2.2-3.3,2.2-5.2c0-2.1-0.7-3.8-2.2-5.4l-0.8-0.7h-1.4
	c0.6,0.4,1,0.7,1.6,1.2c1.3,1.3,2,2.9,2,4.8c0,1.9-0.7,3.5-2,4.8s-2.9,2-4.8,2s-3.5-0.7-4.8-2s-2-2.9-2-4.8s0.7-3.5,2-4.8
	c0.5-0.5,1-0.9,1.6-1.2L317.2,279.5L317.2,279.5z"
          />
          <path
            fill="#EBEBEB"
            d="M318.5,279.5c-0.6,0.4-1.2,0.7-1.6,1.2c-1.3,1.3-2,2.9-2,4.8c0,1.9,0.7,3.5,2,4.8s2.9,2,4.8,2
	c1.9,0,3.5-0.7,4.8-2s2-2.9,2-4.8s-0.7-3.5-2-4.8c-0.5-0.5-1-0.9-1.6-1.2L318.5,279.5L318.5,279.5z M320,285.6
	c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5s0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.5-1.2,0.5
	s-0.8-0.1-1.2-0.5C320.1,286.4,320,286,320,285.6z"
          />
          <path
            fill="#EBEBEB"
            d="M320.5,284.4c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5S320.8,284.1,320.5,284.4z M520.5,279.5h-1.4c0.6,0.4,1.2,0.7,1.6,1.2
	c1.3,1.3,2,2.9,2,4.8c0,1.9-0.7,3.5-2,4.8c-1,1-2.3,1.6-3.7,1.9l-1,0.1c-1.9,0-3.5-0.7-4.8-2c-1.3-1.3-2-2.9-2-4.8
	c0-1.9,0.7-3.5,2-4.8c0.5-0.5,1.1-0.9,1.6-1.2h-1.4c-0.2,0.2-0.5,0.5-0.8,0.7c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.8,2.2,5.2
	c0.6,0.7,1.3,1.2,2,1.5h6.5c0.7-0.4,1.4-0.8,2.1-1.5c1.4-1.4,2.2-3.3,2.2-5.2c0-2.1-0.7-3.8-2.2-5.4L520.5,279.5L520.5,279.5z"
          />
          <path
            fill="#EBEBEB"
            d="M520.7,280.8c-0.5-0.5-1-0.9-1.6-1.2h-6.3c-0.6,0.4-1,0.7-1.6,1.2c-1.3,1.3-2,2.9-2,4.8s0.7,3.5,2,4.8
	c1.3,1.3,2.9,2,4.8,2l1-0.1c1.4-0.2,2.7-0.8,3.7-1.9c1.3-1.3,2-2.9,2-4.8S522.1,282.1,520.7,280.8L520.7,280.8z M514.8,286.7
	c-0.3-0.4-0.5-0.7-0.5-1.2s0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1,0.5l0.1,0.1c0.3,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2
	L517,287c-0.3,0.4-0.7,0.5-1,0.5C515.5,287.3,515.1,287.1,514.8,286.7L514.8,286.7z"
          />
          <path
            fill="#EBEBEB"
            d="M514.3,285.6c0,0.5,0.1,0.8,0.5,1.2c0.3,0.4,0.7,0.5,1.2,0.5c0.5,0,0.8-0.1,1-0.5l0.1-0.1
	c0.3-0.4,0.5-0.7,0.5-1.2c0-0.5-0.1-0.8-0.5-1.2l-0.1-0.1c-0.3-0.2-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1.2,0.5
	C514.4,284.8,514.3,285.1,514.3,285.6L514.3,285.6z M530.6,342.2c-1.2-1.2-2.5-1.6-4.1-1.6c-1.6,0-2.9,0.6-4.1,1.6
	c-1.2,1.2-1.6,2.4-1.6,4.1c0,1.6,0.6,2.9,1.6,4.1c0.8,0.8,1.9,1.4,2.9,1.6h2.3c1-0.2,2.1-0.7,2.9-1.6c1.2-1.2,1.6-2.5,1.6-4.1
	C532.3,344.7,531.8,343.3,530.6,342.2L530.6,342.2z M526.6,344.7c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1
	c-0.3,0.4-0.7,0.5-1,0.5s-0.8-0.1-1-0.5c-0.3-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1C525.7,344.8,526.1,344.7,526.6,344.7L526.6,344.7z"
          />
          <path
            fill="#EBEBEB"
            d="M527.7,345.1c-0.3-0.4-0.7-0.5-1-0.5s-0.8,0.1-1,0.5c-0.3,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1
	c0.3,0.4,0.7,0.5,1,0.5s0.8-0.1,1-0.5c0.3-0.4,0.5-0.7,0.5-1S528,345.5,527.7,345.1L527.7,345.1z M311,340.5c-1.6,0-2.9,0.6-4.1,1.6
	c-1.2,1.2-1.6,2.4-1.6,4.1s0.6,2.9,1.6,4.1c0.8,0.8,1.9,1.4,2.9,1.6h2.3c1-0.2,2.1-0.7,2.9-1.6c1.2-1.2,1.6-2.4,1.6-4.1
	s-0.6-2.9-1.6-4.1C314,341.1,312.6,340.5,311,340.5L311,340.5z M310,345.1c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5
	c0.4,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.4,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5c-0.4-0.4-0.5-0.7-0.5-1S309.6,345.5,310,345.1
	L310,345.1z"
          />
          <path
            fill="#EBEBEB"
            d="M311,344.7c-0.5,0-0.8,0.1-1,0.5c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5
	c0.5,0,0.8-0.1,1-0.5c0.4-0.4,0.5-0.7,0.5-1s-0.1-0.8-0.5-1C311.8,344.8,311.5,344.7,311,344.7L311,344.7z M405.7,229.5v0.4h0.2
	l12.5,0.1l13.3-0.1h0.2v-0.4l-1.4-5c-0.1-0.2-0.2-0.5-0.6-0.4h-22.1c-0.4,0-0.5,0.1-0.6,0.4L405.7,229.5L405.7,229.5z M411,226.8
	c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5s0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5s-0.8-0.1-1.2-0.5
	C411.3,227.6,411,227.2,411,226.8L411,226.8z M420.8,225.2c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2
	s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.1-1.2-0.5s-0.5-0.7-0.5-1.2s0.1-0.8,0.5-1.2C419.9,225.3,420.4,225.2,420.8,225.2L420.8,225.2z
	 M415.2,226.8c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5s0.8,0.1,1.2,0.5c0.4,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5
	s-0.8-0.1-1.2-0.5C415.5,227.6,415.2,227.2,415.2,226.8z M423.3,226.8c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1.2,0.5
	s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.1-1.2-0.5C423.5,227.6,423.3,227.2,423.3,226.8L423.3,226.8z"
          />
          <path
            fill="#EBEBEB"
            d="M411.6,225.7c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2c-0.4-0.4-0.7-0.5-1.2-0.5S411.9,225.3,411.6,225.7z M421.9,225.7
	c-0.4-0.4-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5s-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5c0.5,0,0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2S422.2,225.9,421.9,225.7L421.9,225.7z M415.7,225.7c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2
	s0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5S416,225.3,415.7,225.7z M423.7,225.7
	c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5c0.5,0,0.8-0.1,1.2-0.5s0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2
	s-0.7-0.5-1.2-0.5C424.4,225.2,424.1,225.3,423.7,225.7L423.7,225.7z"
          />
          <path
            fill="#646464"
            d="M320.8,278.1h-6.5c-1.9,0-2.8,0.9-2.8,2.8v22.8c0,1.9,0.9,2.8,2.8,2.8h14.2c1.9,0,2.8-0.9,2.8-2.8v-22.8
	c0-1.9-0.9-2.8-2.8-2.8L320.8,278.1L320.8,278.1z M314.3,279.5h14.2c0.9,0,1.4,0.5,1.4,1.4v22.8c0,0.9-0.5,1.4-1.4,1.4h-14.2
	c-0.9,0-1.4-0.5-1.4-1.4v-22.8C313,280,313.5,279.5,314.3,279.5L314.3,279.5z"
          />
          <path
            fill="#646464"
            d="M317.2,279.5h-2.8c-0.9,0-1.4,0.5-1.4,1.4v11.4h5.5c-0.7-0.4-1.4-0.8-2.1-1.5c-1.4-1.4-2.2-3.3-2.2-5.2
	c0-2.1,0.7-3.8,2.2-5.4L317.2,279.5L317.2,279.5z M523.3,278.1h-14.2c-1.9,0-2.8,0.9-2.8,2.8v22.8c0,1.9,0.9,2.8,2.8,2.8h14.2
	c1.9,0,2.8-0.9,2.8-2.8v-22.8C526.1,279,525.2,278.1,523.3,278.1L523.3,278.1z M520.5,279.5h2.8c0.9,0,1.4,0.5,1.4,1.4v22.8
	c0,0.9-0.5,1.4-1.4,1.4h-14.2c-0.9,0-1.4-0.5-1.4-1.4v-22.8c0-0.9,0.5-1.4,1.4-1.4L520.5,279.5L520.5,279.5z"
          />
          <path
            fill="#646464"
            d="M523.3,279.5h-2.8l0.8,0.7c1.4,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2.1,1.5h5.5
	v-11.4C524.7,280,524.2,279.5,523.3,279.5L523.3,279.5z M511.4,279.5h-2.3c-0.9,0-1.4,0.5-1.4,1.4v11.4h5c-0.7-0.4-1.4-0.8-2-1.5
	c-1.5-1.4-2.2-3.3-2.2-5.2c0-2.1,0.7-3.8,2.2-5.4C510.9,280,511.2,279.7,511.4,279.5L511.4,279.5z M539.4,341.1
	c0-0.6-0.2-0.8-0.8-0.8h-9.7c0.8,0.4,1.5,0.8,2.2,1.4c1.3,1.3,1.9,2.8,1.9,4.5s-0.6,3.3-1.9,4.5c-0.5,0.5-0.9,0.8-1.5,1.2h9
	c0.6,0,0.8-0.2,0.8-0.8L539.4,341.1L539.4,341.1z M534.5,344c-0.3-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5
	c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5C535.1,344.4,534.7,344.3,534.5,344L534.5,344z
	 M534,349.5c0-0.5,0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1
	c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5C534.1,350.2,534,349.8,534,349.5L534,349.5z"
          />
          <path
            fill="#646464"
            d="M534,342.9c0,0.5,0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.3-0.4,0.5-0.7,0.5-1
	c0-0.5-0.1-0.8-0.5-1c-0.3-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5C534.1,342.1,534,342.5,534,342.9L534,342.9z M534.5,348.3
	c-0.3,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.3-0.4,0.5-0.7,0.5-1s-0.1-0.8-0.5-1
	c-0.3-0.4-0.7-0.5-1-0.5C535.1,347.8,534.7,348.1,534.5,348.3L534.5,348.3z M520.3,340.2c-0.6,0-0.8,0.2-0.8,0.8v10
	c0,0.6,0.2,0.8,0.8,0.8h3.3c-0.5-0.2-1-0.7-1.5-1.2c-1.3-1.3-1.9-2.8-1.9-4.5s0.6-3.3,1.9-4.5c0.7-0.7,1.4-1.2,2.2-1.4L520.3,340.2
	L520.3,340.2z M299.1,340.2c-0.6,0-0.8,0.2-0.8,0.8v10c0,0.6,0.2,0.8,0.8,0.8h9c-0.6-0.2-1-0.7-1.5-1.2c-1.3-1.3-1.9-2.8-1.9-4.5
	s0.6-3.3,1.9-4.5c0.7-0.7,1.4-1.2,2.2-1.4L299.1,340.2L299.1,340.2z M300.5,342.9c0-0.5,0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5
	c0.5,0,0.8,0.1,1,0.5c0.4,0.4,0.5,0.7,0.5,1c0,0.5-0.1,0.8-0.5,1c-0.4,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5
	C300.6,343.7,300.5,343.4,300.5,342.9L300.5,342.9z M303.2,348.3c0.4,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.4,0.4-0.7,0.5-1,0.5
	c-0.5,0-0.8-0.1-1-0.5c-0.4-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5C302.5,347.8,302.9,348.1,303.2,348.3
	L303.2,348.3z"
          />
          <path
            fill="#646464"
            d="M301,341.9c-0.4,0.4-0.5,0.7-0.5,1c0,0.5,0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5
	c0.4-0.4,0.5-0.7,0.5-1c0-0.5-0.1-0.8-0.5-1c-0.4-0.4-0.7-0.5-1-0.5S301.3,341.5,301,341.9z M303.7,349.5c0-0.5-0.1-0.8-0.5-1
	c-0.4-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5
	S303.7,349.8,303.7,349.5z M318.2,341.1c0-0.6-0.2-0.8-0.8-0.8h-4c0.8,0.4,1.5,0.8,2.2,1.4c1.3,1.3,1.9,2.8,1.9,4.5
	s-0.6,3.3-1.9,4.5c-0.5,0.5-0.9,0.8-1.5,1.2h3.3c0.6,0,0.8-0.2,0.8-0.8V341.1L318.2,341.1z"
          />
          <path
            fill="none"
            d="M354.6,331.2l-0.5,0.1h-0.7v-0.7h0.8l0.7,0.1c0.1,0.1,0.2,0.1,0.2,0.2l-0.1,0.2L354.6,331.2L354.6,331.2z
	 M346.6,333.3l1.5,0.1h0.7l0.6-0.1c0.4-0.1,0.6-0.2,0.8-0.5s0.4-0.4,0.5-0.7l-1.5-0.2l-0.4,0.5c-0.2,0.1-0.5,0.1-0.8,0.1
	s-0.7-0.1-0.8-0.2c-0.2-0.1-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.7c0.2-0.2,0.5-0.4,0.9-0.4l0.5,0.1l0.2,0.1l0.1,0.1l0.1,0.2l1.6-0.2
	c-0.2-0.4-0.5-0.7-0.9-0.8l-0.9-0.2H348c-0.9,0-1.6,0.1-2.2,0.5c-0.5,0.4-0.8,0.8-0.8,1.4c0,0.5,0.1,0.8,0.5,1
	C345.8,332.9,346.1,333.1,346.6,333.3L346.6,333.3z M357.9,333.4h2.7l1-0.1c0.2,0,0.6-0.1,0.8-0.2l0.6-0.6c0.1-0.2,0.2-0.5,0.2-0.9
	c0-0.2,0-0.5-0.1-0.7s-0.2-0.4-0.5-0.6c-0.2-0.1-0.5-0.2-0.8-0.4l-1.3-0.1h-2.7L357.9,333.4L357.9,333.4z M356.5,332.3l-0.6-0.4
	l-0.5-0.1l0.6-0.1c0.2-0.1,0.5-0.2,0.6-0.4s0.2-0.4,0.2-0.5c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.1-0.5-0.2-0.7-0.4l-4.2-0.1v3.6h1.9v-1.4
	h0.1l0.5,0.1l0.4,0.2l0.9,1h2L356.5,332.3L356.5,332.3z M359.7,330.6h0.5c0.5,0,0.8,0.1,0.9,0.2c0.2,0.1,0.4,0.4,0.4,0.8
	c0,0.2,0,0.5-0.1,0.6l-0.4,0.2l-0.8,0.1h-0.5V330.6L359.7,330.6z M420.4,289.8h-0.5l-0.1,0.1h-0.1c-0.1,0-0.2-0.1-0.2-0.1v-0.1h1
	v-0.2l-0.1-0.2c-0.1-0.2-0.2-0.2-0.5-0.4h-0.4l-0.4,0.1l-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.5v0.2c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.4-0.1
	l0.4-0.2L420.4,289.8L420.4,289.8z"
          />
          <path
            fill="none"
            d="M419.9,289.3h-0.5v-0.1c0-0.1,0.1-0.1,0.1-0.1h0.1L419.9,289.3z M421.4,289.2l0.1,0.1v0.5l-0.1,0.1l-0.2,0.1
	h-0.1l-0.1-0.1v-0.2l0.1-0.2C421.2,289.2,421.3,289.2,421.4,289.2L421.4,289.2z"
          />
          <path
            fill="none"
            d="M422.1,289.1l-0.4-0.2h-0.4l-0.2,0.1h-0.1v-0.1h-0.6v1.7h0.6V290l0.2,0.1h0.4l0.4-0.1l0.1-0.2v-0.5 M418,289.1
	l0.2,0.1v0.1h-0.5v-0.1c0-0.1,0.1-0.1,0.1-0.1H418z"
          />
          <path
            fill="none"
            d="M418.1,288.8h-0.2l-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2v0.1l-0.1,0.2l0.1,0.4c0.1,0.2,0.2,0.4,0.5,0.4h0.2
	l0.5-0.1l0.2-0.1l0.1-0.1h-0.5l-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1-0.1v-0.2h1v-0.2l-0.1-0.2c-0.1-0.2-0.2-0.2-0.5-0.4L418.1,288.8
	L418.1,288.8z M416.5,288.3v1.3l-0.1,0.2h-0.2l-0.2-0.1v-0.4h-0.5v0.5l0.4,0.4l0.6,0.1h0.2c0.2-0.1,0.4-0.1,0.5-0.2l0.1-0.2v-1.2
	h-0.7L416.5,288.3L416.5,288.3z M314.2,351.9c0.5-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5s-0.6-3.3-1.9-4.5
	c-0.7-0.7-1.4-1.2-2.2-1.4"
          />
          <path
            fill="none"
            d="M307.1,342.2c-1.2,1.2-1.6,2.4-1.6,4.1c0,1.6,0.6,2.9,1.6,4.1c0.8,0.8,1.9,1.4,2.9,1.6l1.2,0.1l1.2-0.1
	c1-0.2,2.1-0.7,2.9-1.6c1.2-1.2,1.6-2.5,1.6-4.1c0-1.6-0.6-2.9-1.6-4.1c-1.2-1.2-2.4-1.6-4.1-1.6
	C309.5,340.5,308.1,341.1,307.1,342.2L307.1,342.2z"
          />
          <path
            fill="none"
            d="M310,345.1c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5
	c0.4-0.4,0.5-0.7,0.5-1s-0.1-0.8-0.5-1c-0.4-0.4-0.7-0.5-1-0.5S310.3,344.8,310,345.1L310,345.1z M303.2,348.3
	c-0.4-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5
	c0.4-0.4,0.5-0.7,0.5-1S303.4,348.6,303.2,348.3L303.2,348.3z M300.5,342.9c0,0.5,0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5
	c0.5,0,0.8-0.1,1-0.5c0.4-0.4,0.5-0.7,0.5-1c0-0.5-0.1-0.8-0.5-1c-0.4-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5
	C300.6,342.1,300.5,342.5,300.5,342.9z M328.9,317.9c0.2,1.9,0.2,3.7,0,5.7c0,0.5-0.2,0.7-0.7,0.7h-9.7c-0.5,0-0.7-0.2-0.7-0.7
	c-0.2-2-0.2-3.8,0-5.7c0-0.5,0.2-0.7,0.7-0.7h9.7C328.6,317,328.9,317.3,328.9,317.9L328.9,317.9z"
          />
          <path
            fill="none"
            d="M329.4,323.8c0,0.6-0.2,0.8-0.8,0.8h-10.7c-0.6,0-0.8-0.2-0.8-0.8c-0.2-2.2-0.2-4.3,0-6.3
	c0-0.6,0.2-0.8,0.8-0.8h10.7c0.6,0,0.8,0.2,0.8,0.8C329.8,319.6,329.8,321.7,329.4,323.8L329.4,323.8z M523.5,351.9
	c-0.5-0.2-1-0.7-1.5-1.2c-1.3-1.3-1.9-2.8-1.9-4.5s0.6-3.3,1.9-4.5c0.7-0.7,1.4-1.2,2.2-1.4 M534.5,348.3c0.4-0.4,0.7-0.5,1-0.5
	c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5c-0.3-0.4-0.5-0.7-0.5-1
	S534.1,348.6,534.5,348.3L534.5,348.3z M527.7,345.1c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5s-0.8-0.1-1-0.5
	c-0.3-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1c0.3-0.4,0.7-0.5,1-0.5S527.4,344.8,527.7,345.1L527.7,345.1z"
          />
          <path
            fill="none"
            d="M530.6,342.2c1.2,1.2,1.6,2.4,1.6,4.1c0,1.6-0.6,2.9-1.6,4.1c-0.8,0.8-1.9,1.4-2.9,1.6l-1.2,0.1l-1.2-0.1
	c-1-0.2-2.1-0.7-2.9-1.6c-1.2-1.2-1.6-2.5-1.6-4.1c0-1.6,0.6-2.9,1.6-4.1c1.2-1.2,2.4-1.6,4.1-1.6
	C528.2,340.5,529.5,341.1,530.6,342.2L530.6,342.2z"
          />
          <path
            fill="none"
            d="M529.6,351.9c0.6-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5s-0.6-3.3-1.9-4.5c-0.7-0.7-1.4-1.2-2.2-1.4
	 M534,342.9c0-0.5,0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1c0,0.5-0.1,0.8-0.5,1
	c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5C534.1,343.7,534,343.4,534,342.9L534,342.9z M308.8,340.2c-0.8,0.4-1.5,0.8-2.2,1.4
	c-1.3,1.3-1.9,2.8-1.9,4.5s0.6,3.3,1.9,4.5c0.5,0.5,0.9,0.8,1.5,1.2 M517.1,297.8c0.2,0.2,0.5,0.6,0.5,0.9s-0.1,0.7-0.5,1l-0.1,0.1
	c-0.2,0.2-0.6,0.4-0.9,0.4s-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1c0-0.4,0.1-0.7,0.5-0.9c0.2-0.2,0.6-0.5,1-0.5
	C516.5,297.5,516.8,297.6,517.1,297.8L517.1,297.8z M515.1,278.1h1.6"
          />
          <path
            fill="none"
            d="M517.1,293.3c1.2,0.1,2.2,0.7,3,1.5c1,1,1.6,2.4,1.6,4s-0.6,2.9-1.6,4c-0.8,0.8-1.9,1.4-3,1.6l-0.9,0.1
	c-1.5,0-2.9-0.6-4-1.6c-1-1-1.6-2.3-1.6-4c0-1.5,0.6-2.9,1.6-4c1-1,2.4-1.6,4-1.6L517.1,293.3L517.1,293.3z"
          />
          <path
            fill="none"
            d="M517.9,292.8c1,0.2,1.9,0.8,2.7,1.6c1.2,1.3,1.9,2.7,1.9,4.4c0,1.7-0.6,3.1-1.9,4.4c-1,1-2.2,1.6-3.5,1.7
	l-0.9,0.1c-1.7,0-3.1-0.6-4.4-1.9c-1.3-1.2-1.9-2.7-1.9-4.4c0-1.8,0.6-3.1,1.9-4.4c0.7-0.7,1.5-1.3,2.4-1.5c0.6-0.2,1.3-0.2,1.9-0.2
	l0.9,0.1L517.9,292.8L517.9,292.8z M514.3,285.6c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1,0.5l0.1,0.1
	c0.3,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2L517,287c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1.2-0.5
	C514.4,286.4,514.3,286,514.3,285.6L514.3,285.6z M512.7,292.3c-0.7-0.4-1.4-0.8-2-1.5c-1.5-1.4-2.2-3.3-2.2-5.2
	c0-2.1,0.7-3.8,2.2-5.4c0.2-0.2,0.5-0.5,0.8-0.7"
          />
          <path
            fill="none"
            d="M519.1,279.5c0.6,0.4,1.2,0.7,1.6,1.2c1.3,1.3,2,2.9,2,4.8c0,1.9-0.7,3.5-2,4.8c-1,1-2.3,1.6-3.7,1.9l-1,0.1
	c-1.9,0-3.5-0.7-4.8-2c-1.3-1.3-2-2.9-2-4.8c0-1.9,0.7-3.5,2-4.8c0.5-0.5,1.1-0.9,1.6-1.2"
          />
          <path
            fill="none"
            d="M520.5,279.5l0.8,0.7c1.4,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2.1,1.5 M423.7,225.7
	c0.4-0.4,0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.1-1.2-0.5
	s-0.5-0.7-0.5-1.2S423.5,225.9,423.7,225.7L423.7,225.7z M415.2,226.8c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5S415.2,226.4,415.2,226.8z M421.9,225.7
	c0.4,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.5-1.2,0.5s-0.8-0.1-1.2-0.5s-0.5-0.7-0.5-1.2s0.1-0.8,0.5-1.2
	s0.7-0.5,1.2-0.5C421.2,225.2,421.6,225.3,421.9,225.7L421.9,225.7z M411,226.8c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5
	s0.8-0.1,1.2-0.5s0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5C411.3,225.9,411,226.4,411,226.8z
	 M320.8,278.1h1.7 M326.2,279.5l0.8,0.7c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2,1.5 M321.5,300.3
	c0.4,0,0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1c0-0.4-0.1-0.7-0.5-0.9c-0.2-0.2-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-0.9,0.5
	c-0.2,0.2-0.5,0.6-0.5,0.9s0.1,0.7,0.5,1C320.8,300.1,321,300.3,321.5,300.3L321.5,300.3z"
          />
          <path
            fill="none"
            d="M317.5,302.8c1,1,2.4,1.6,4,1.6c1.5,0,2.9-0.6,4-1.6c1-1,1.6-2.3,1.6-4c0-1.5-0.6-2.9-1.6-4c-1-1-2.4-1.6-4-1.6
	c-1.5,0-2.9,0.6-4,1.6c-1,1-1.6,2.5-1.6,4C315.9,300.4,316.4,301.7,317.5,302.8L317.5,302.8z"
          />
          <path
            fill="none"
            d="M319.8,292.8c-1,0.2-1.9,0.8-2.7,1.6c-1.2,1.3-1.9,2.7-1.9,4.4c0,1.7,0.6,3.1,1.9,4.4c1.3,1.3,2.7,1.9,4.4,1.9
	s3.1-0.6,4.4-1.9c1.3-1.2,1.9-2.7,1.9-4.4c0-1.8-0.6-3.1-1.9-4.4c-0.7-0.7-1.5-1.3-2.4-1.5c-0.6-0.2-1.2-0.2-1.9-0.2L319.8,292.8
	L319.8,292.8z M320,285.6c0,0.5,0.1,0.8,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2
	s-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5S320,285.1,320,285.6z"
          />
          <path
            fill="none"
            d="M318.5,279.5c-0.6,0.4-1.2,0.7-1.6,1.2c-1.3,1.3-2,2.9-2,4.8c0,1.9,0.7,3.5,2,4.8s2.9,2,4.8,2
	c1.9,0,3.5-0.7,4.8-2s2-2.9,2-4.8s-0.7-3.5-2-4.8c-0.5-0.5-1-0.9-1.6-1.2"
          />
          <path
            fill="none"
            d="M318.4,292.3c-0.7-0.4-1.4-0.8-2.1-1.5c-1.4-1.4-2.2-3.3-2.2-5.2c0-2.1,0.7-3.8,2.2-5.4l0.8-0.7 M308.5,265.8
	c0.1-0.4,0.4-0.7,0.6-0.9l9.6-67l0.2-1c0.8-3.6,2.2-5.5,4-5.5l5.7-0.8l9.7-0.6c0.8-0.2,1.3-0.7,1.5-1.4l6.1-0.4
	c0.2,0.6,0.8,1.1,1.6,1.3l21.7-0.8c0.8-0.2,1.4-0.7,1.8-1.4l5.2-0.1c0.4,0.7,1,1.2,2,1.3c6.8-0.2,13.4-0.4,20.1-0.4
	c1.2-0.2,1.9-0.7,2.2-1.3h4.7"
          />
          <path
            fill="none"
            d="M518.2,201.8l-0.6-4.9c-0.1-0.6-0.2-1.2-0.5-1.6c-0.5-0.8-1.2-1.4-2.1-2c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4
	h-24.1c-19.9,0.4-39.3,0.9-57.9,1.6l-18.9,0.8c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.4,2.1-2.6,3.6l-0.6,4.9"
          />
          <path
            fill="none"
            d="M518.2,201.8c-0.2-0.8-0.7-1.5-1.2-2.2c-0.6-0.7-1.3-1.3-2.1-1.9c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4h-24.1
	c-19.9,0.4-39.3,0.9-57.9,1.6l-18.9,0.8c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.7,2.2-3.1,4 M357.9,204.7v0.9l-13.2,0.8
	c-4.9,0.5-7.5,3.1-7.7,7.8l-0.6,6.5c-1.2,13.8-2.1,28.9-2.7,45.6c0.1,2,1.2,3,3.4,3h7.7"
          />
          <path
            fill="none"
            d="M343.1,270.1h-7.8c-1.9-0.2-2.8-1.5-2.8-4c0.6-16.7,1.5-31.8,2.7-45.6l0.7-7.3c0.1-4.7,2.7-7.3,7.7-7.8
	l14.3-0.7 M366.6,199.8v7.3l-1.5,6.5c-0.1,0.6-0.6,0.9-1.4,1l-1.9-0.1c-0.7,0-1.2-0.2-1.3-0.7l-1.4-6.9v-7.3c0-0.6,0.2-0.8,0.8-0.8
	h5.9C366.4,199,366.6,199.2,366.6,199.8L366.6,199.8z"
          />
          <path
            fill="none"
            d="M367.4,204.3V199c-0.1-0.5-0.5-0.7-1-0.7h-7.5c-0.7,0-1,0.2-1,0.8v5.6 M367.4,204.3v0.8
	c5.7-0.1,11.8-0.1,18.1-0.1h66.6c6.3-0.1,12.4,0,18.1,0.1v-0.8 M470.1,204.3c-5.4-0.1-11-0.1-16.9-0.1h-68.9
	c-5.9-0.1-11.5,0-16.9,0.1 M282,238.9l-1.4,0.2h-0.2c-1.5,0-2.4,0.6-2.8,2c-0.4,1.6-0.5,3.7-0.5,6.5v8.4c-0.1,2.3,1,3.6,3.3,3.8
	h17.7c2.8-0.2,4.1-1.4,4-3.5v-13.9c0-2.3-0.9-3.5-2.9-3.7C293.1,238.6,287.4,238.7,282,238.9z"
          />
          <path
            fill="none"
            d="M298.1,261.7h1.4c3.3-0.2,4.9-1.6,4.8-4.1v-16.2c0.1-2.7-1-4.2-3.5-4.4c-7.9-0.2-15.2-0.2-22,0.2l-2.3,0.1
	c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9c-0.1,3,1.5,4.5,4.8,4.4h1 M291.9,261.7v4.8h1.6 M293.5,267.2h2.9
	 M296.4,266.5h-2.9 M296.4,267.2v-0.7 M293.5,267.2h-2.1v5.6l12.1,1.7 M293.5,266.5v0.7 M296.4,266.5h1.6v-4.8 M303.6,267.2h-7.1
	 M298.1,261.7h-6.2 M308.5,265.8c-0.2,0.5-0.4,0.9-0.5,1.4h-4.4 M280.6,239.1l1.4-0.1"
          />
          <path
            fill="none"
            d="M280.5,237.3l-2,0.1c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9c-0.1,2.7,1.2,4.2,3.8,4.4
	 M306.5,289.4l-13.9,2.2c-2.2,0.2-3.7,1.3-4.8,3.4l-2.8,5.7v20.9 M320.8,278.1h-6.5c-1.9,0-2.8,0.9-2.8,2.8v22.8
	c0,1.9,0.9,2.8,2.8,2.8h14.2c1.9,0,2.8-0.9,2.8-2.8v-22.8c0-1.9-0.9-2.8-2.8-2.8h-6.1"
          />
          <path
            fill="none"
            d="M313,292.3v-11.4c0-0.9,0.5-1.4,1.4-1.4h14.2c0.9,0,1.4,0.5,1.4,1.4v11.4 M307.6,277.4
	c-0.1-0.4-0.2-0.6-0.2-0.6 M308.3,274.3c-0.5,0.6-0.8,1.5-1,2.7 M308.3,274.3v-0.8 M310.1,273.8h-1.3l-0.5,0.5"
          />
          <path
            fill="none"
            d="M307.9,296.8c0.1,0.4,0.2,0.6,0.4,0.6c0.2,0,0.5-1,0.6-3s0.2-4.4,0.2-7.2c-0.1-2.8-0.2-5.2-0.5-7.2
	c-0.2-2-0.5-3-0.7-3c-0.1,0-0.2,0.2-0.4,0.6 M310.1,273.8c-0.6,0.5-0.8,2.1-0.6,4.9v28.1l0.5,27.6"
          />
          <path
            fill="none"
            d="M307.6,277.4c-0.1,0.5-0.2,1.3-0.4,2.3c-0.1,2-0.2,4.4-0.1,7.2c0,2.8,0.1,5.2,0.4,7.2c0.1,1,0.2,1.9,0.5,2.4"
          />
          <path
            fill="none"
            d="M307.9,296.8c0.1-0.5,0.2-1.3,0.2-2.4c0.1-2,0.2-4.4,0.2-7.2c-0.1-2.8-0.2-5.2-0.5-7.2l-0.4-2.3 M307.3,276.8
	c-0.2,0.9-0.4,2-0.4,3.3 M306.9,280.2v4.3c-0.4,0.1-0.5,0.6-0.5,1.2v2.1 M306.5,287.7v1.8 M306.5,289.7v-0.2 M306.5,287.7v2
	 M329.9,292.3H313"
          />
          <path
            fill="none"
            d="M304.5,274.7v5.5h2.5 M303.6,274.5l0.9,0.2 M308.3,273.5h-3.8v1.3 M307.6,297.4l0.2-0.6 M313,292.3v11.4
	c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4 M307.6,297.4c-0.4-3.5-0.7-6.1-1.2-7.7 M308.3,273.5l0.1-7.7 M303.6,267.2
	v7.3 M278.5,261.7h13.4 M411,235.9c-11.5,1.5-21.7,6.6-30.3,15.3l-1.3,1.3c-1.4,1.5-2.7,3-3.8,4.5c-0.8,1-1.5,2.1-2.3,3.3
	c-1.9,2.8-3.4,5.8-4.7,9l-0.4,0.9 M368.3,270.2l-0.5,1.3"
          />
          <path
            fill="none"
            d="M373.3,265.5c-0.8,1.5-1.5,3-2.2,4.7l-1.3,3.6c-1.5,4.9-2.2,10-2.2,15.5c0,8.7,1.9,16.7,5.7,23.8
	c1.3,2.5,2.8,4.7,4.4,6.9c1.5,2,3.1,3.7,4.9,5.6c4,4,8.3,7.2,13.1,9.6c7,3.6,14.8,5.4,23.2,5.4c8.5,0,16.2-1.7,23.2-5.4
	c4.7-2.5,9-5.6,12.9-9.6c1.5-1.5,2.9-3,4.2-4.7l3-4.2c5.1-8,7.7-17.1,7.7-27.3v-0.4c0-5.4-0.8-10.5-2.2-15.2l-1.3-3.6
	c-1.2-2.8-2.6-5.6-4.2-8.3c-0.7-1.2-1.5-2.2-2.3-3.4c-1.2-1.5-2.3-2.9-3.7-4.3l-2.2-2.2c-9.8-9.3-21.6-14-35.2-14
	c-13.6,0-25.4,4.7-35.2,14l-2.2,2.2c-1.3,1.4-2.6,2.9-3.7,4.3C375.9,260.9,374.5,263.1,373.3,265.5L373.3,265.5z M344.8,269.1
	l-1.8,0.9 M343.4,271.5l0.8-1.3 M344.2,270.2l0.6-1 M342.7,273.8H342l1.4-2.3 M341.7,273.8h-1 M352.6,288.6c0.4,0.4,0.8,0.6,1.4,0.6
	c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.8,0.6-1.4c0-0.5-0.2-0.9-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6c-0.5,0-0.9,0.2-1.4,0.6
	c-0.4,0.4-0.6,0.8-0.6,1.4C352,287.8,352.3,288.3,352.6,288.6z"
          />
          <path
            fill="none"
            d="M356.8,287.6v-0.4c0-0.8-0.2-1.4-0.8-2s-1.2-0.8-2-0.8s-1.4,0.2-2,0.8c-0.6,0.6-0.8,1.2-0.8,2l0.1,0.6
	 M351.4,287.8c-0.1,0.9,0,1.6,0.1,2.3c0.1,0.5,0.2,0.9,0.6,1.4v0.8 M355.4,292.3v15.9c0,0.4-0.1,0.7-0.5,1c-0.2,0.2-0.6,0.5-1,0.5
	c-0.4,0-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1v-15.9c0-0.2,0.1-0.5,0.5-0.7c0.2-0.2,0.7-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2
	C355.2,291.8,355.4,292,355.4,292.3L355.4,292.3z M351.4,287.8c0.1,0.5,0.4,1,0.8,1.4c0.6,0.6,1.2,0.8,2,0.8c0.8,0,1.4-0.2,2-0.8
	c0.5-0.5,0.7-1,0.8-1.6 M351.9,292.3c-1.2,0.2-2.2,0.7-3.1,1.5l-0.5,0.4c-1.5,1.4-2.2,3.1-2.2,5.1c0,2,0.7,3.6,2.2,5l0.5,0.4
	c0.8,0.7,1.6,1.2,2.7,1.4 M351.9,306.2v-0.9"
          />
          <path
            fill="none"
            d="M355.9,306.2v2.2c0,0.5-0.2,0.9-0.6,1.3c-0.4,0.4-0.8,0.6-1.3,0.6h-0.1c-0.5,0-0.9-0.2-1.3-0.6
	c-0.4-0.4-0.6-0.8-0.6-1.3v-2.1"
          />
          <path
            fill="none"
            d="M351.5,306.1c-0.1,0.2-0.1,0.5-0.1,0.8v2c0,0.7,0.2,1.3,0.7,1.7s1,0.7,1.8,0.7c0.7,0,1.3-0.2,1.7-0.7
	s0.7-1,0.7-1.7v-2l-0.1-0.8 M351.5,306.1l0.5,0.1 M351.9,305.3c-0.2,0.2-0.4,0.6-0.5,0.9 M351.9,305.3v-12.9 M414,257.9
	c-6.6,0.9-12.6,4-17.5,9c-1,1-2,2.2-2.9,3.3l-0.9,1.3c-0.6,0.7-1,1.5-1.5,2.3c-0.7,1.3-1.3,2.6-1.9,3.8l-0.5,1.2
	c-0.6,1.8-1,3.6-1.3,5.5 M387.5,284.3c-0.2,1.6-0.4,3.3-0.4,5c0,3.7,0.6,7.2,1.8,10.6c1.5,4.3,4.1,8.3,7.6,11.9
	c6.2,6.2,13.6,9.3,22.4,9.3c8.7,0,16.2-3,22.4-9.2 M387.5,284.3v-0.1V284.3"
          />
          <path
            fill="none"
            d="M396.7,307.3c-0.7,1.6-0.8,3.1-0.4,4.3c-0.2-1.2,0.1-2.4,0.8-3.7l7-11.7c0.4-0.5,0.7-1,0.7-1.6
	c0.1-0.6-0.1-1.2-0.6-1.8c-0.4-0.4-0.8-0.7-1.5-0.8l-12.6-5.4c-0.6-0.2-1-0.5-1.5-0.8c-0.6-0.5-1-1-1.3-1.6"
          />
          <path
            fill="none"
            d="M387.5,284.3c0.2,0.7,0.7,1.4,1.3,2.1c0.4,0.4,0.8,0.7,1.4,1h0.1"
          />
          <path
            fill="none"
            d="M384.8,289.3c0,6.1,1.4,11.4,4.1,16.3c1.5,2.8,3.5,5.4,5.9,7.8c3.5,3.5,7.3,6.1,11.7,7.8
	c3.8,1.5,7.9,2.2,12.4,2.2s8.6-0.7,12.4-2.2c4.3-1.6,8.2-4.2,11.7-7.8c2.9-2.9,5.2-6.2,6.9-9.7c2.1-4.4,3-9.2,3-14.5
	c0-5.7-1.2-10.8-3.6-15.5l-2.2-3.6c-1.3-1.8-2.6-3.4-4.2-5c-6.6-6.6-14.7-10-24.1-10c-9.4,0-17.5,3.4-24.1,10
	c-1.6,1.6-2.9,3.3-4.2,5c-0.4,0.5-0.6,0.8-0.8,1.3c-0.5,0.7-0.9,1.5-1.3,2.3C386,278.5,384.8,283.6,384.8,289.3L384.8,289.3z
	 M390.2,335.1c-3.4-2.1-6.5-4.7-9.6-7.7c-1.9-1.9-3.6-3.8-5.1-5.8l-2.3-3.3c-5.6-8.5-8.3-18.2-8.3-29c0-5.5,0.7-10.6,2.1-15.5
	 M355.9,292.3v-0.5c0.4-0.4,0.6-0.9,0.7-1.8c0.1-0.7,0.2-1.5,0.2-2.6 M356.2,306.1c1-0.4,2.1-0.9,3-1.7c1.5-1.4,2.2-3,2.2-5
	c0-2-0.7-3.6-2.2-5c-1-0.9-2.1-1.5-3.4-1.9 M355.9,305.3v0.9 M356.2,306.1l-0.4,0.1 M355.9,305.3c0.2,0.2,0.4,0.5,0.5,0.8
	 M355.9,292.3v12.9 M343.1,270.1l-2.4,3.7 M344.2,270.2h24 M367.8,271.5h-24.4 M367.1,273.8c0.2-0.8,0.5-1.5,0.7-2.3 M367.1,273.8
	h-24.4 M319.4,201.8l-9.3,72 M340.6,273.8h-30.5 M408.2,188h21.2c1-0.1,1.9-0.5,2.1-1.3h5.7c0.2,0.6,1,1.1,2.2,1.3
	c6.6,0.1,13.3,0.2,20,0.4c0.9-0.1,1.6-0.6,2-1.3l5.2,0.1c0.4,0.7,0.9,1.2,1.8,1.4l21.7,0.8c0.8-0.2,1.4-0.7,1.6-1.3l6.1,0.4
	c0.2,0.7,0.7,1.2,1.5,1.4l9.7,0.6l5.7,0.8c0.8,0,1.5,0.5,2.2,1.2c0.7,0.9,1.3,2.3,1.7,4.3l0.2,1.1l9.1,63.5"
          />
          <path
            fill="none"
            d="M479.7,204.7v-5.5c0-0.6-0.4-0.8-1-0.8h-7.5c-0.6,0-0.9,0.2-1,0.7v5.4 M479.7,204.7v0.9l13.2,0.8
	c4.9,0.5,7.5,3.1,7.7,7.8l0.6,6.5c1.2,13.8,2.1,28.9,2.7,45.6c-0.1,2-1.2,3-3.4,3h-7.7"
          />
          <path
            fill="none"
            d="M477.7,199h-5.9c-0.6,0-0.8,0.2-0.8,0.8v7.3l1.5,6.5c0.1,0.6,0.6,0.9,1.4,1l1.9-0.1c0.7,0,1.2-0.2,1.3-0.7
	l1.4-6.9v-7.3C478.5,199.2,478.3,199,477.7,199L477.7,199z M494.5,270.1h7.8c1.9-0.2,2.8-1.5,2.8-4c-0.6-16.7-1.5-31.8-2.7-45.6
	l-0.7-7.3c-0.1-4.7-2.7-7.3-7.7-7.8l-14.3-0.7 M405,186.6h1c0.2,0.7,0.9,1.2,2.2,1.3"
          />
          <path
            fill="none"
            d="M408.2,188c-1-0.1-1.9-0.6-2.1-1.4h-1 M427.1,230.8l4.5-0.1c0.5,0,0.8-0.1,0.9-0.5c0.2-0.2,0.2-0.6,0.1-1.1
	l-1.4-5c-0.1-0.7-0.6-1.1-1.4-1.1h-22.1c-0.7,0-1.2,0.4-1.4,1.1l-1.4,5c-0.1,0.5-0.1,0.8,0.1,1.1c0.1,0.2,0.5,0.5,0.9,0.5l4.5,0.1"
          />
          <path
            fill="none"
            d="M405.7,229.9h0.2l12.5,0.1l13.3-0.1h0.2v-0.4l-1.4-5c-0.1-0.2-0.2-0.5-0.6-0.4h-22.1c-0.4,0-0.5,0.1-0.6,0.4
	l-1.4,5L405.7,229.9L405.7,229.9z M427.1,230.8l-8.7,0.1l-7.9-0.1 M426.1,261.6c4.9,1.2,9.1,3.7,12.9,7.5l2.9,3.4 M431.6,271.9
	l-0.7-0.5c-0.7-0.5-1.4-0.9-2.1-1.3l-1.7-0.8 M426.1,261.6l0.9,7.8"
          />
          <path
            fill="none"
            d="M443,274c1.9-0.1,3.1,0.2,4.2,1c-1-0.6-2.2-0.7-3.7-0.4l-13.3,3c-0.6,0.2-1.2,0.2-1.7,0.2l-0.6-0.2
	c-0.4-0.2-0.7-0.5-0.8-0.8c-0.2-0.5-0.4-1-0.4-1.6l-1.3-13.6v-0.1c-0.1-1.4-0.6-2.6-1.5-3.4c1,0.7,1.7,1.9,2.2,3.6"
          />
          <path
            fill="none"
            d="M441.2,311.8c4.5-4.5,7.3-9.7,8.6-15.5c0.5-2.2,0.7-4.5,0.7-7s-0.2-4.8-0.7-7c-0.4-1.6-0.8-3.1-1.4-4.7
	c-0.5-1.3-1.2-2.6-1.9-3.8l-2.3-3.6c-0.9-1.2-1.9-2.2-2.9-3.3c-6.2-6.2-13.6-9.3-22.4-9.3l-4.9,0.4 M441.9,272.4l-10.4-0.5
	 M426.7,235.9l-7.8-0.6l-7.8,0.6 M397.4,270.5l-0.8,0.9c-0.6,0.7-1.2,1.5-1.7,2.3v0.1"
          />
          <path
            fill="none"
            d="M411.7,261.6c-4.9,1.2-9.2,3.7-13.1,7.6l-1.3,1.4 M411.7,261.6l-0.8,7.7"
          />
          <path
            fill="none"
            d="M394.8,273.8c-1.8-0.1-3.1,0.2-4.2,1c1-0.6,2.3-0.7,3.7-0.4l13.6,3.1l1.4,0.1l0.2-0.1c0.5-0.1,0.9-0.5,1.2-0.9
	c0.2-0.5,0.4-1,0.4-1.8l1.3-13.5c0.1-1.5,0.6-2.7,1.5-3.5 M414,257.9c-1,0.7-1.7,2-2.2,3.6"
          />
          <path
            fill="none"
            d="M407.7,271l-0.8,0.5c-0.9,0.7-2,1.4-2.8,2.3l-1.5,1.6 M410.9,269.3c-0.7,0.2-1.3,0.6-1.9,0.9
	c-0.5,0.2-0.9,0.5-1.3,0.8 M407.7,271l-10.3-0.5 M418.1,273.8c-0.7,0.2-1.3,0.7-1.8,1.5c-0.5,0.7-0.7,1.5-0.8,2.3
	c-0.1,0.5-0.1,0.9-0.1,1.5v0.8c0.1,0.7,0.5,1.3,0.9,1.9c0.7,0.7,1.5,0.9,2.4,0.9s1.8-0.4,2.3-0.9c0.5-0.6,0.8-1.2,0.9-1.9l-0.1-2.3
	c-0.1-0.9-0.5-1.6-0.8-2.3c-0.5-0.8-1-1.3-1.6-1.5"
          />
          <path
            fill="none"
            d="M420.8,277.4c-0.6-0.6-1.3-0.9-2-0.9c-0.8,0-1.5,0.2-2.1,0.9l-0.2,0.2c-0.5,0.5-0.7,1.2-0.7,1.9
	c0,0.8,0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.2,2-0.9c0.6-0.6,0.9-1.3,0.9-2.1c0-0.7-0.2-1.3-0.7-1.9L420.8,277.4
	L420.8,277.4z M419.4,273.8l-0.7-0.1l-0.6,0.1 M419.4,273.8h-1.3 M402.5,275.4l3.7,0.8c1.3,0.2,2.2,0.1,2.9-0.5c0.6-0.4,1-1,1.3-2
	l0.6-4.4 M410.2,283c-1.4-0.5-2.8-0.6-4-0.2c-1.3,0.2-2,0.9-2.2,1.8c-0.4,0.9,0,1.9,0.8,2.8s2,1.6,3.4,2.1l0.8,0.2
	c0.7,0.1,1.4,0,2.1-0.4c0.8-0.5,1.4-1,1.6-2c0.2-0.9,0.2-1.8-0.2-2.6c-0.4-0.7-0.8-1.2-1.4-1.5L410.2,283L410.2,283z"
          />
          <path
            fill="none"
            d="M412.1,285c-0.4-0.7-0.9-1.3-1.6-1.5c-0.8-0.2-1.5-0.2-2.2,0.2c-0.7,0.4-1.2,0.9-1.4,1.7
	c-0.2,0.7-0.2,1.5,0.2,2.2c0.4,0.7,0.9,1.2,1.8,1.4c0.7,0.2,1.5,0.2,2.2-0.1c0.7-0.4,1.3-0.9,1.5-1.8
	C412.6,286.4,412.4,285.7,412.1,285L412.1,285z M418.8,284.8c-1.3,0-2.3,0.5-3.1,1.3c-0.8,0.9-1.3,2-1.3,3.1c0,1.3,0.5,2.3,1.3,3.3
	c0.9,0.8,2,1.3,3.1,1.3c1.3,0,2.3-0.5,3.1-1.3c0.9-0.9,1.4-2,1.4-3.3s-0.5-2.3-1.4-3.1C421.2,285.2,420.1,284.8,418.8,284.8
	L418.8,284.8z M394.8,273.8l7.7,1.6 M390.4,287.6c0,0.6-0.1,1.2-0.1,1.8c0,6.9,2.1,12.9,6.4,18.1 M390.4,287.6l10.5,4.8
	c1.2,0.5,1.9,1.2,2.1,2.1c0.4,0.8,0.2,1.8-0.5,2.9l-5.7,10.1 M421.3,296.6c-0.1,0.7,0,1.4,0.4,2.1l0.5,0.7c0.9,1.3,1.9,2.1,3,2.6
	s2.1,0.5,2.8-0.1c0.8-0.6,1-1.4,0.9-2.7c-0.1-1.3-0.6-2.6-1.5-3.7l-0.6-0.7c-0.5-0.5-1.2-0.8-1.9-0.9c-0.9-0.1-1.7,0-2.4,0.6
	C421.9,295,421.4,295.7,421.3,296.6L421.3,296.6z"
          />
          <path
            fill="none"
            d="M421.8,296.8c-0.1,0.8,0,1.5,0.5,2.2c0.5,0.7,1,1,1.9,1.2c0.8,0.1,1.5,0,2.2-0.5c0.7-0.5,1-1.2,1.2-1.9
	c0.1-0.8,0-1.5-0.5-2.2c-0.5-0.7-1.2-1-1.9-1.2c-0.8-0.1-1.5,0-2.2,0.5C422.2,295.4,421.9,295.9,421.8,296.8L421.8,296.8z
	 M416.4,296.8c-0.1-0.9-0.6-1.6-1.3-2.2c-0.7-0.6-1.6-0.7-2.4-0.6c-0.7,0.1-1.4,0.5-1.9,0.9l-0.5,0.7c-0.9,1.3-1.4,2.4-1.5,3.7
	s0.2,2.1,0.9,2.7c0.7,0.6,1.6,0.6,2.8,0.1c1.2-0.5,2.2-1.3,3-2.6l0.5-0.7C416.4,298.2,416.5,297.5,416.4,296.8L416.4,296.8z"
          />
          <path
            fill="none"
            d="M414.8,294.9c-0.7-0.5-1.4-0.7-2.2-0.5c-0.8,0.1-1.4,0.5-1.9,1.2s-0.7,1.4-0.5,2.2c0.1,0.8,0.5,1.4,1.2,1.9
	c0.7,0.5,1.4,0.7,2.2,0.6c0.8-0.1,1.4-0.6,1.9-1.2c0.5-0.7,0.7-1.4,0.5-2.2C415.8,295.9,415.5,295.4,414.8,294.9L414.8,294.9z
	 M429.2,316l-7.9-8.7c-0.7-0.7-1.3-1.2-2-1.3l-0.7-0.1h-0.7c-0.7,0.1-1.3,0.6-2,1.3l-7.8,8.6"
          />
          <path
            fill="none"
            d="M408.2,315.9c-1.4,1.2-2.8,1.6-4,1.6c1.2-0.1,2.3-0.8,3.3-2l9-10.3c0.4-0.6,0.8-0.9,1.3-1.2
	c0.7-0.2,1.3-0.2,1.9,0c0.5,0.2,0.9,0.6,1.3,1.2l9.1,10.4c0.9,1,2,1.6,3.1,1.9c-1.3,0.1-2.6-0.5-4-1.5 M435.4,275.5l7.6-1.5
	 M443,274l-0.1-0.2l-0.9-1.4 M447.4,287.7l-0.1,0.1l-5.7,2.6"
          />
          <path fill="none" d="M440.9,307.5c4.3-5.1,6.5-11.2,6.5-18.2v-1.6" />
          <path
            fill="none"
            d="M441.2,311.8c0.1-1.2-0.1-2.3-0.9-3.6l-7-11.8c-0.4-0.5-0.7-1-0.7-1.6c0-0.7,0.1-1.3,0.6-1.8
	c0.4-0.4,0.8-0.7,1.5-0.9l12.6-5.4h0.1c1.2-0.5,2-1.2,2.6-2.1l0.2-0.5l-0.2,0.6c-0.5,0.9-1.3,1.9-2.5,2.7 M435.4,275.5l-1.7-1.8
	l-2.1-1.9 M427,269.4l0.5,4.4c0.2,1,0.6,1.8,1.3,2.1c0.7,0.6,1.6,0.7,2.9,0.5l3.7-0.8 M426.7,283.2c-0.7,0.4-1.2,0.8-1.5,1.5
	c-0.5,0.8-0.5,1.6-0.2,2.6c0.2,0.8,0.8,1.5,1.6,2c0.7,0.4,1.3,0.5,2.1,0.4l0.8-0.2c1.5-0.5,2.6-1.2,3.4-2.1s1-1.9,0.8-2.8
	c-0.2-0.9-1-1.5-2.2-1.8c-1.3-0.2-2.6-0.2-4,0.2L426.7,283.2L426.7,283.2z"
          />
          <path
            fill="none"
            d="M429.5,283.6c-0.7-0.4-1.5-0.5-2.2-0.1c-0.8,0.2-1.3,0.7-1.7,1.4c-0.4,0.7-0.5,1.5-0.2,2.2
	c0.2,0.8,0.7,1.3,1.5,1.6c0.7,0.4,1.4,0.5,2.2,0.2c0.8-0.2,1.4-0.7,1.8-1.5c0.4-0.7,0.5-1.4,0.1-2.2
	C430.7,284.5,430.3,283.9,429.5,283.6L429.5,283.6z M441.6,290.4l-4.9,2.2c-0.7,0.4-1.3,0.7-1.6,1.2"
          />
          <path
            fill="none"
            d="M435.2,293.6c-0.2,0.4-0.5,0.6-0.5,0.9c-0.4,0.8-0.2,1.8,0.5,2.9l5.7,10 M440.9,307.5c0.7,1.6,0.8,3,0.4,4.3"
          />
          <path
            fill="none"
            d="M441.6,290.4c0,2.3-0.2,3.8-1,4.7l-5.5-1.4 M492.8,269.1l0.7,1 M492.8,269.1l1.7,0.9 M493.5,270.2l0.8,1.3
	 M469.4,270.2l-0.7-1.6c-1.6-4-3.7-7.7-6.4-11.2l-0.2-0.2c-1.2-1.5-2.4-3-3.8-4.5l-1.3-1.3c-8.6-8.6-18.8-13.8-30.3-15.3
	 M469.4,270.2l0.5,1.3 M472.4,282.8l0.4,4.7 M472.7,287.4v1.5 M472.7,289v0.9 M472.1,280.3l0.1,0.9 M472.2,281.3l0.1,1.5
	 M470.6,273.8c0.6,2.1,1,4.3,1.4,6.5 M472.7,287.4h17.4c0.6,0,0.8-0.2,0.8-0.8v-3c0-0.6-0.2-0.8-0.8-0.8h-17.6 M524.7,292.3v-11.4
	c0-0.9-0.5-1.4-1.4-1.4h-14.2c-0.9,0-1.4,0.5-1.4,1.4v11.4"
          />
          <path
            fill="none"
            d="M516.8,278.1h6.5c1.9,0,2.8,0.9,2.8,2.8v22.8c0,1.9-0.9,2.8-2.8,2.8h-14.2c-1.9,0-2.8-0.9-2.8-2.8v-22.8
	c0-1.9,0.9-2.8,2.8-2.8h6.1 M524.7,292.3h-16.9 M496.6,289.9h5.6c0.6,0,0.8-0.2,0.8-0.8v-7.9c0-0.6-0.2-0.8-0.8-0.8h-5.6"
          />
          <path
            fill="none"
            d="M497.4,277.9h7.8c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-7.8"
          />
          <path
            fill="none"
            d="M495,281.3h6.4c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.3 M495.9,273.8h1.2 M495.6,280.3v-1.6
	c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h1"
          />
          <path
            fill="none"
            d="M496.6,280.3v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.4-0.1 M494.3,271.5l1.4,2.3H495 M496.6,280.3h-1 M495,281.3v-0.9
	 M495,280.3h0.6 M495,273.8v6.5 M495.1,289v0.9 M495.6,289.9h-0.5 M496.6,289.9h-1 M497.4,292.2h-1c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5
	v-1.5 M497.4,292.2c-0.4,0-0.6-0.1-0.7-0.4 M496.6,291.4v-1.5 M495.1,289l-0.1-7.7 M507.8,292.3v11.4c0,0.9,0.5,1.4,1.4,1.4h14.2
	c0.9,0,1.4-0.5,1.4-1.4v-11.4 M497.1,273.8l-2.6-3.7 M493.5,270.2h-24 M494.3,271.5h-24.4 M495,273.8h-24.4 M472.2,281.3H495
	 M495,280.3h-22.8 M495.1,289.9h-22.4 M472.7,289h22.4 M469.9,271.5l0.7,2.3 M410.5,230.8l0.6,5.1 M426.7,235.9l0.5-5.1
	 M528.8,265.1v-82.5c0.1-0.1,0.1-0.2,0.1-0.4v-0.9c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1
	c-0.1,0.1-0.1,0.2-0.1,0.4v0.9c0,0.1,0,0.2,0.1,0.4h0.1v78.9 M559.1,261.7h1c3.3,0.1,4.9-1.4,4.8-4.4v-9.9c0-3.3-0.1-5.8-0.5-7.6
	c-0.5-1.5-1.5-2.2-3.4-2.2l-2.3-0.1c-6.8-0.5-14.1-0.5-22-0.2c-2.5,0.2-3.5,1.8-3.5,4.4v16.1c-0.1,2.4,1.5,3.8,4.8,4.1h1.4"
          />
          <path
            fill="none"
            d="M557.1,237.3l2,0.1c1.9,0,2.9,0.7,3.4,2.2c0.3,1.9,0.5,4.4,0.5,7.6v9.9c0.1,2.7-1.2,4.2-3.8,4.4"
          />
          <path
            fill="none"
            d="M555.6,238.9l1.4,0.2h0.2c1.5,0,2.5,0.6,2.8,2c0.3,1.6,0.5,3.7,0.5,6.5v8.4c0.1,2.3-1,3.6-3.3,3.8h-17.6
	c-2.8-0.2-4.1-1.4-4-3.5v-13.9c0-2.3,0.9-3.5,2.9-3.7C544.6,238.6,550.3,238.7,555.6,238.9L555.6,238.9z M555.6,238.9l1.4,0.1
	 M528.8,265.1l0.5,0.7 M528.2,261.3l0.5,3.5l0.1,0.2 M534.1,267.2h-4.4l-0.5-1.4 M539.5,261.7v4.8h1.6 M541.1,266.5h2.9
	 M541.1,266.5v0.7 M544,266.5h1.7v-4.8 M544,267.2v-0.7 M534.1,274.5l12-1.7v-5.6H544 M544,267.2h-2.9 M541.1,267.2h-7 M539.5,261.7
	h6.3 M529.2,273.5h3.8v1.3"
          />
          <path fill="none" d="M530.6,280.2h2.4v-5.5 M533.1,274.7l1-0.2" />
          <path
            fill="none"
            d="M529.2,274.3c0.5,0.6,0.8,1.5,1,2.7c0.2,0.9,0.3,2,0.3,3.3 M529.2,274.3l-0.3-0.5h-1.4 M529.2,273.5v0.8"
          />
          <path
            fill="none"
            d="M527.7,334.4l0.5-27.6v-28.1c0.1-2.8-0.1-4.4-0.6-4.9 M530.6,280.2v4.3c0.3,0.1,0.5,0.6,0.5,1.2v2.1
	 M531.2,287.7l-0.1,2 M531.2,289.4v-1.8 M531.1,289.7l0.1-0.2 M552.6,321.6v-20.9l-2.8-5.7c-0.9-2-2.6-3.1-4.8-3.4l-13.9-2.2
	 M531.1,289.7c-0.5,1.6-0.9,4.1-1.2,7.7c-0.5,5.9-0.8,14.8-0.9,26.3l-0.5,9.3 M529.2,265.8v7.7 M534.1,267.2v7.3 M545.8,261.7h13.3
	 M527.5,273.8l-9.3-72 M527.5,273.8h-30.4 M514.1,379v42.7c0.3,3.3,2.2,5.5,5.6,6.6c4.1,0.7,8.9,1,14.5,0.9h1.9
	c5.5,0.1,10.4-0.2,14.3-0.9c3.4-1.2,5.2-3.4,5.6-6.6v-93.8c-0.1-2-0.5-3.1-1-3.6l-2.4-2.6"
          />
          <path
            fill="none"
            d="M552.6,321.6v10.7l-24,0.7 M552.6,321.6v0.1V321.6 M540,339h-21.1c-0.6,0-0.8,0.2-0.8,0.8v12.5
	c0,0.6,0.2,0.8,0.8,0.8H540c0.6,0,0.8-0.2,0.8-0.8v-12.5C540.9,339.2,540.5,339,540,339z"
          />
          <path
            fill="none"
            d="M538.6,340.2h-18.3c-0.6,0-0.8,0.2-0.8,0.8v10c0,0.6,0.2,0.8,0.8,0.8h18.3c0.6,0,0.8-0.2,0.8-0.8v-10
	C539.4,340.6,539,340.2,538.6,340.2L538.6,340.2z M528.4,335.6h-0.7"
          />
          <path
            fill="none"
            d="M528.4,335.6h18.2c3.7-0.2,5.6,0.9,5.8,3.5l-2.2,13.6l-0.5,1.8c-0.3,0.9-0.8,1.7-1.3,2.6l-2.5,4.2
	c-0.8,0.9-3.1,1.3-6.8,1.3H519c-0.8,0-1.4,0.1-2,0.4c-0.6,0.2-1,0.6-1.3,1 M527.7,335.6v-1.2 M528.5,333l-0.1,2.6 M515.7,334.1v0.4
	 M495.2,333.4l0.4-0.1h19.2c0.6,0,0.8,0.2,0.8,0.8 M515.7,334.1l0.2,0.4 M516,334.4h-0.2 M495.2,319.3h6.2c0.6,0,0.8,0.2,0.8,0.7
	v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.2"
          />
          <path
            fill="none"
            d="M497.4,315.9h7.9c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-7.8"
          />
          <path
            fill="none"
            d="M496.6,327.9h5.6c0.6,0,0.8-0.2,0.8-0.8v-7.9c0-0.6-0.2-0.8-0.8-0.8h-5.6 M496.6,318.3v-1.6
	c0-0.4,0.1-0.7,0.5-0.7l0.4-0.1"
          />
          <path
            fill="none"
            d="M495.6,318.3v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h1 M495.2,319.3v-0.9 M495.2,318.3h0.4 M496.6,318.3h-1
	 M495.2,327v0.9 M495.6,327.9h-0.4 M496.6,327.9h-1 M497.4,330.2h-1c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5v-1.5 M494.7,334.1
	c0-0.5,0.1-0.7,0.5-0.8"
          />
          <path
            fill="none"
            d="M497.4,330.2c-0.4,0-0.6-0.1-0.7-0.4l-0.1-2 M495.2,327.9v5.5 M495.2,327v-7.7 M462.7,320.8h27.4
	c0.6,0,0.8,0.2,0.8,0.8v3c0,0.6-0.2,0.8-0.8,0.8h-31.2 M455.9,337.7c0.1-0.6,0.7-0.9,1.5-1c14.8,0.1,26.5,0,34.9-0.4
	c1-0.1,1.8-0.6,2.2-1.3 M516,334.4c0.2,0.4,0.7,0.6,1,0.8c0.6,0.2,1.3,0.4,2.2,0.4h8.5 M494.4,335.1c0.2-0.2,0.4-0.6,0.4-1"
          />
          <path
            fill="none"
            d="M462.7,320.8l-0.6,0.7c-1,1.4-2.1,2.7-3.3,4 M458.8,325.4l-1.4,1.5 M463.8,319.3l-1.2,1.5 M464.4,318.3
	l-0.6,0.9 M478.5,364.6c-0.6-1.4-1.6-2.2-3-2.7 M479,370.6l0.1-1.2c0.1-0.7,0.1-1.4,0.1-2.1 M476,371.7c-0.6-0.6-1.4-1-2.2-1.2h-1.5
	c-0.8,0.1-1.5,0.5-2.2,1.2c-0.8,0.8-1.2,1.7-1.2,2.8v0.1c0,0.8,0.2,1.6,0.7,2.2l0.5,0.7c0.8,0.8,1.7,1.2,2.9,1.2s2.1-0.4,2.9-1.2
	l0.6-0.7c0.5-0.7,0.7-1.4,0.7-2.2v-0.1C477.1,373.4,476.7,372.4,476,371.7L476,371.7z M478.1,374.4l-0.2,0.9 M478,374.4h0.1
	 M477.8,375.3l0.1-0.9"
          />
          <path
            fill="none"
            d="M478,374.4c0-1.3-0.5-2.3-1.4-3.3l-0.7-0.6c-0.8-0.6-1.8-0.8-2.8-0.8s-2,0.2-2.8,0.8c-0.2,0.2-0.5,0.4-0.7,0.6
	c-0.6,0.6-0.9,1.2-1.2,2 M478.1,374.4l0.9-3.8 M479.2,367.3c-0.1-1-0.4-1.9-0.7-2.6 M475.5,361.9H457 M455.6,374.6l0.7-0.1
	c0.5-0.2,0.7-0.6,0.7-1v-2.8 M469,370.6l0.2-0.8 M468.4,373.1c-0.1,0.5-0.2,0.9-0.2,1.4v0.1c0,0.8,0.2,1.5,0.6,2.2
	c0.2,0.5,0.5,0.8,0.9,1.3l1,0.8 M469.2,369.7c0.4-1.6,0.4-2.8,0.1-3.5 M468.4,373.1l0.6-2.6 M469,370.6h-12 M457,370.6v-8.6
	 M469.2,369.7l-12.2,0.8"
          />
          <path
            fill="none"
            d="M470.6,379c0.7,0.4,1.5,0.6,2.3,0.6c0.9,0,1.6-0.2,2.4-0.6 M475.4,379l1-0.8c0.4-0.4,0.7-0.8,0.9-1.3l0.1-0.4
	c0.1-0.4,0.2-0.8,0.4-1.3 M515.7,363.9c0,0.2-0.1,0.5-0.2,0.6l-0.6,0.1h-0.8 M514.1,367.3v3.3 M514.1,364.7v2.6 M495.3,364.6
	c-0.4-0.1-0.5-0.4-0.5-0.8v-0.5 M514.1,364.7h-18.4l-0.4-0.1 M494.7,363.3c-0.2-0.8-1-1.3-2.5-1.4h-16.9 M514.1,370.6v8.4
	 M475.4,379h38.7 M494.7,363.3v-29.3 M463.8,319.3h31.4 M457.4,327h37.8 M495.3,364.6h-16.8 M479.2,367.3h34.9 M514.1,370.6H479
	 M464.4,318.3h30.8 M515.7,363.9v-29.5 M437.4,353.3v-3c0-0.2,0.1-0.5,0.5-0.5h6.2c0.2,0,0.5,0.1,0.5,0.5v3 M437.4,354.7v-1.4
	 M438.8,354.7h-1.4 M439.7,356.7l-0.4-0.4c-0.5-0.4-0.6-0.9-0.6-1.4v-0.1 M457,361.9c-0.6-0.1-0.8-0.6-0.9-1.3l-0.1-22.1v-0.7"
          />
          <path
            fill="none"
            d="M444.4,354.7h3c0.2,0,0.5-0.1,0.5-0.5v-9.1c0-0.2-0.1-0.5-0.5-0.5h-57.2c-0.2,0-0.5,0.1-0.5,0.5v9.1
	c0,0.2,0.1,0.5,0.5,0.5h3"
          />
          <path
            fill="none"
            d="M446,349.2c0.2,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.2,0.6c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2
	c-0.2,0-0.5-0.1-0.6-0.2s-0.2-0.4-0.2-0.6c0-0.2,0.1-0.5,0.2-0.6S445.8,349.2,446,349.2L446,349.2z M442.9,354.7v0.1
	c0,0.6-0.2,1-0.6,1.4l-0.4,0.4 M444.4,353.3v1.4 M444.4,354.7h-1.5 M455.9,337.7h-8c-8.6,3.8-17.5,5.9-26.6,6.4l-3.4,0.1
	c-9.7-0.2-19.1-2.4-28.2-6.4h-8 M456.5,327.9c-2.8,2.8-5.8,5.2-9.1,7.2 M444.4,353.3h-7 M442.9,354.7h-4.1 M408.2,315.9
	c3.3,1.3,6.8,2,10.6,2c3.7,0,7.2-0.6,10.4-1.9 M391,349.5c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2
	s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6s-0.4-0.2-0.6-0.2C391.3,349.2,391.2,349.3,391,349.5L391,349.5z
	 M393.2,354.7v-1.4 M394.7,354.7v0.1c0,0.6,0.2,1,0.6,1.4l0.4,0.4 M393.2,354.7h1.5 M393.2,353.3v-3c0-0.2,0.1-0.5,0.5-0.5h6.2
	c0.2,0,0.5,0.1,0.5,0.5v3 M400.3,354.7h-1.4 M400.3,353.3v1.4 M397.9,356.7l0.4-0.4c0.5-0.4,0.6-0.9,0.6-1.4v-0.1 M398.9,354.7h-4.2
	 M393.2,353.3h7.1"
          />
          <path
            fill="none"
            d="M447.4,335.1c-8.4,5.4-18,8-28.6,8c-10.6,0-20.2-2.7-28.6-8 M395.6,356.7c0.4,0.2,0.7,0.4,1.2,0.4
	c0.5,0,0.8-0.1,1.2-0.4 M441.9,356.7h13.5c0.2,0,0.5,0.1,0.5,0.5v17.1l-0.1,0.2l-0.2,0.1 M441.9,356.7c-0.4,0.2-0.7,0.4-1.2,0.4
	c-0.5,0-0.8-0.1-1.2-0.4 M439.7,356.7h-41.9 M437.4,354.7h-37.1 M495.2,327.9h-38.7 M494.4,335.1h-47 M457.4,327l-0.9,0.9
	 M527.7,334.4H516 M356.4,316.8c0-0.7-0.2-1.2-0.7-1.6c-0.5-0.5-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7c-0.5,0.5-0.7,1-0.7,1.6
	c0,0.6,0.2,1.2,0.7,1.6s0.9,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7S356.4,317.4,356.4,316.8L356.4,316.8z M381.8,337.7
	c-0.1-0.6-0.7-0.9-1.5-1c-12.9,0.1-23.4,0-31.5-0.2l-3.4-0.1c-1-0.1-1.8-0.6-2.2-1.3"
          />
          <path
            fill="none"
            d="M354.1,316c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2
	s0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6C354.5,316,354.3,316,354.1,316L354.1,316z M342.4,333.4
	c0.4,0.1,0.5,0.4,0.5,0.8 M321.9,334.1c0-0.6,0.2-0.8,0.8-0.8h19.2l0.4,0.1 M343.2,335.1c-0.2-0.2-0.4-0.6-0.4-1 M352.5,364.6v-2.7
	 M323.5,364.7H342l0.4-0.1 M342.8,363.3c0.2-0.8,1-1.3,2.5-1.4h7.2 M342.4,364.6c0.4-0.1,0.5-0.4,0.5-0.8v-0.5 M352.5,364.6v9.3
	c0,0.2,0.1,0.5,0.5,0.5h31.5 M342.4,364.6h10.1 M323.5,367.3v3.3 M323.5,364.7v2.6 M322.4,364.6l0.2,0.1h0.8 M322.1,364.6h0.4
	 M322.4,364.6l-0.2-0.1c-0.1-0.1-0.2-0.4-0.2-0.6 M323.5,370.6v8.4 M352.4,367.3h-28.9 M352.4,370.6h-28.9 M384.3,374.4
	c0.1,0.1,0.1,0.1,0.4,0.1h70.9"
          />
          <path
            fill="none"
            d="M384.3,374.4l-0.1-0.2V357c0-0.2,0.1-0.5,0.5-0.5h11 M352.5,361.9H380c0.9,0.1,1.4-0.4,1.5-1.3l0.1-23
	 M342.8,334.1v29.3 M321.6,334.4l0.2-0.4 M321.9,334.4v-0.4 M321.9,334.4h-0.2 M309,333l-0.5-9.3c-0.1-11.5-0.4-20.4-0.9-26.3
	 M321.6,334.4h-11.8 M299.1,340.2h18.3c0.6,0,0.8,0.2,0.8,0.8v10c0,0.6-0.2,0.8-0.8,0.8h-18.3c-0.6,0-0.8-0.2-0.8-0.8v-10
	C298.3,340.6,298.5,340.2,299.1,340.2L299.1,340.2z"
          />
          <path
            fill="none"
            d="M296.8,352.3v-12.5c0-0.6,0.2-0.8,0.8-0.8h21.1c0.6,0,0.8,0.2,0.8,0.8v12.5c0,0.6-0.2,0.8-0.8,0.8h-21.1
	C297,353.1,296.8,352.8,296.8,352.3L296.8,352.3z M310,335.6h-0.8 M310,335.6h8.5c1.5,0,2.6-0.4,3.3-1.2 M309.1,335.6L309,333
	 M309.9,334.4l0.1,1.2"
          />
          <path
            fill="none"
            d="M309.1,335.6H291c-3.7-0.2-5.6,0.9-5.8,3.5l2.2,13.6c0.1,0.6,0.2,1.2,0.5,1.8c0.4,0.9,0.8,1.7,1.3,2.6l2.4,4.2
	c0.8,0.9,3.1,1.3,6.8,1.3h20.3c1.6,0,2.8,0.5,3.4,1.4"
          />
          <path
            fill="none"
            d="M285,321.6l-2.4,2.6c-0.5,0.4-0.8,1.6-1,3.6v93.8c0.4,3.3,2.2,5.5,5.6,6.6c4.1,0.7,8.9,1,14.3,0.9h1.9
	c5.5,0.1,10.4-0.2,14.5-0.9c3.4-1.2,5.2-3.4,5.6-6.6V379"
          />
          <path
            fill="none"
            d="M309,333l-24-0.7v-10.7 M285,321.6v0.1V321.6 M321.9,363.9v-29.5 M323.5,379h147.1 M390.2,335.1h-47
	 M472.7,289.9c-0.1,10.6-2.9,20-8.4,28.4 M342.4,333.4l0.4-59.6 M495.1,289.9l0.1,28.4 M370.8,187.3c-0.4,0.7-0.9,1.2-1.8,1.4
	l-21.7,0.8c-0.8-0.2-1.4-0.7-1.6-1.3l-6.1,0.4c-0.2,0.7-0.7,1.2-1.5,1.4l-9.7,0.6l-5.7,0.8c-1.8,0.1-3.1,1.9-4,5.5l-0.2,1l-9.6,67
	c-0.2,0.2-0.4,0.6-0.6,0.9l-0.1,7.7v0.7l0.5-0.5h1.3l9.3-71.9l0.6-4.9c0.1-1.5,1-2.8,2.6-3.6c2-1.1,4.3-1.6,7.3-1.9l18.9-0.8
	c18.7-0.7,38-1.3,57.9-1.6h24.1l76.8,2.4c2.9,0.1,5.4,0.8,7.3,1.9c0.9,0.6,1.6,1.2,2.1,2c0.2,0.5,0.5,1.1,0.5,1.6l0.6,4.9l9.3,71.9
	h1.3l0.5,0.5v-0.8l-0.1-7.7l-0.5-0.7l-0.1-0.1l-0.5-3.5l-9.1-63.5l-0.2-1.1c-0.5-2-1-3.4-1.7-4.3c-0.6-0.8-1.4-1.2-2.2-1.2l-5.7-0.8
	l-9.7-0.6c-0.8-0.2-1.3-0.7-1.5-1.4l-5.9-0.2c-0.2,0.6-0.8,1.1-1.6,1.3l-21.7-0.9c-0.8-0.2-1.4-0.7-1.7-1.4l-5.2-0.1
	c-0.4,0.7-1,1.2-2,1.3c-6.8-0.2-13.4-0.4-20-0.4c-1.2-0.2-1.9-0.7-2.2-1.3h-5.7c-0.4,0.8-1,1.3-2.1,1.3h-21.2
	c-1.3-0.2-2-0.6-2.2-1.3h-5.7c-0.2,0.6-1,1.1-2.2,1.3l-20,0.4c-0.9-0.1-1.6-0.6-2-1.3L370.8,187.3L370.8,187.3z"
          />
          <path
            fill="none"
            d="M330,191.5c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.4,2.1-2.6,3.6l-0.6,4.9c0.6-1.8,1.6-3.1,3.1-4
	c2-1.1,4.3-1.6,7.3-1.9l18.9-0.8c18.7-0.7,38-1.3,57.9-1.6H431l76.8,2.4c2.9,0.1,5.4,0.8,7.3,1.9c0.8,0.5,1.5,1.1,2.1,1.9
	c0.5,0.7,0.8,1.4,1.2,2.2l-0.6-4.9c-0.1-0.6-0.2-1.2-0.5-1.6c-0.5-0.8-1.2-1.4-2.1-2c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4H407
	c-19.9,0.4-39.3,0.9-57.9,1.6L330,191.5L330,191.5z"
          />
          <path
            fill="none"
            d="M330,195.9c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.7,2.2-3.1,4l-9.3,71.9h30.5l2.4-3.6h-7.8
	c-1.9-0.2-2.8-1.5-2.8-4c0.6-16.7,1.5-31.8,2.7-45.6l0.7-7.3c0.1-4.7,2.7-7.3,7.7-7.8l14.2-0.7v-5.5c0-0.6,0.4-0.8,1-0.8h7.5
	c0.6,0,0.9,0.2,1,0.7v5.4c5.4-0.1,11-0.1,16.9-0.1h68.9c5.9-0.1,11.5,0,16.9,0.1v-5.4c0.1-0.5,0.5-0.7,1-0.7h7.5c0.7,0,1,0.2,1,0.8
	v5.5l14.3,0.8c4.9,0.5,7.5,3.1,7.7,7.8l0.7,7.3c1.2,13.8,2.1,28.9,2.7,45.6c0.1,2.4-0.8,3.7-2.8,4h-7.8l2.5,3.6h30.5l-9.3-71.9
	c-0.2-0.8-0.7-1.5-1.2-2.2c-0.6-0.7-1.3-1.3-2.1-1.9c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4h-24.1c-19.9,0.4-39.3,0.9-57.9,1.6L330,195.9
	L330,195.9z"
          />
          <path
            fill="none"
            d="M336.4,220.5l0.6-6.5c0.1-4.7,2.7-7.3,7.7-7.8l13.2-0.8v-0.7l-14.3,0.8c-4.9,0.5-7.5,3.1-7.7,7.8l-0.7,7.3
	c-1.2,13.8-2.1,28.9-2.7,45.6c-0.1,2.4,0.8,3.7,2.8,4h7.8l1.8-1h-7.7c-2.2,0-3.4-1-3.4-3C334.3,249.4,335.3,234.3,336.4,220.5
	L336.4,220.5z M367.4,204.3v0.8c5.7-0.1,11.8-0.1,18.1-0.1h66.6c6.3-0.1,12.4,0,18.1,0.1v-0.8c-5.4-0.1-11-0.1-16.9-0.1h-68.9
	C378.5,204.2,372.8,204.2,367.4,204.3z M304.4,241.4c0.1-2.7-1-4.2-3.5-4.4c-7.9-0.2-15.2-0.2-22,0.2l-2.3,0.1
	c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9c-0.1,3,1.5,4.5,4.8,4.4h1c-2.6-0.2-3.8-1.8-3.8-4.4v-9.9
	c0-3.3,0.1-5.8,0.5-7.6c0.5-1.5,1.5-2.2,3.4-2.2l2-0.1l-2,0.1c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9
	c-0.1,2.7,1.2,4.2,3.8,4.4h21c3.3-0.2,4.9-1.6,4.8-4.1v-16H304.4z M299.1,238.8c2,0.2,2.9,1.4,2.9,3.7v13.8c0.1,2.1-1.3,3.3-4,3.5
	h-17.7c-2.2-0.2-3.3-1.5-3.3-3.8v-8.4c0-2.8,0.1-4.9,0.5-6.5c0.4-1.3,1.3-2,2.8-2h0.2l1.4-0.2l-1.4,0.1l1.4-0.1
	C287.4,238.7,293.1,238.6,299.1,238.8L299.1,238.8z"
          />
          <path
            fill="none"
            d="M302,242.6c0-2.3-0.9-3.5-2.9-3.7c-6.1-0.2-11.8-0.1-17.1,0.1l-1.4,0.2h-0.2c-1.5,0-2.4,0.6-2.8,2
	c-0.4,1.6-0.5,3.7-0.5,6.5v8.4c-0.1,2.3,1,3.6,3.3,3.8h17.7c2.8-0.2,4.1-1.4,4-3.5L302,242.6L302,242.6z M291.9,266.5h6.2v-4.8h-6.2
	 M296.4,267.2h-5v5.6l12.1,1.7v-7.3"
          />
          <path
            fill="none"
            d="M293.5,267.2h2.9v-0.7h-2.9 M308.5,265.8c-0.2,0.5-0.4,0.9-0.5,1.4h-4.4v7.3l1,0.1v-1.2h3.8V265.8L308.5,265.8z
	 M285,302.7v29.5l24,0.7l-0.5-9.3c-0.1-11.5-0.4-20.4-0.9-26.3c-0.4-3.5-0.7-6.1-1.2-7.7l-0.1-0.2l-13.9,2.2
	c-2.2,0.2-3.7,1.3-4.8,3.4l-2.8,5.7v2.1H285L285,302.7z"
          />
          <path
            fill="none"
            d="M307.3,276.8c0.1,0,0.2,0.2,0.2,0.6c0.1-0.4,0.2-0.6,0.4-0.6c0.2,0,0.5,1,0.7,3c0.2,2,0.4,4.4,0.5,7.2
	c0,2.8,0,5.2-0.2,7.2c-0.1,2-0.4,3-0.6,3c-0.1,0-0.2-0.2-0.4-0.6l-0.2,0.6c0.5,5.9,0.8,14.8,0.9,26.3l0.5,9.3l0.1,2.7h0.7v-1.2
	l-0.5-27.6v-28.1c-0.1-2.8,0.1-4.4,0.6-4.9h-1.3l-0.5,0.5C307.9,274.8,307.5,275.8,307.3,276.8L307.3,276.8z"
          />
          <path
            fill="none"
            d="M307.6,277.4c-0.1-0.4-0.2-0.6-0.2-0.6c-0.2,0.9-0.4,2-0.4,3.3v4.3c-0.4,0.1-0.5,0.6-0.5,1.2v2.1l0.1,2
	c0.5,1.6,0.8,4.1,1.2,7.7l0.2-0.6c-0.1-0.5-0.2-1.3-0.5-2.4c-0.2-2-0.4-4.4-0.4-7.2c-0.1-2.8,0-5.2,0.1-7.2
	C307.4,278.7,307.4,278,307.6,277.4L307.6,277.4z"
          />
          <path
            fill="none"
            d="M307.3,276.8c0.2-1.2,0.6-2,1-2.7v-0.7h-3.8v6.6h2.4C306.9,278.9,307.1,277.8,307.3,276.8L307.3,276.8z"
          />
          <path
            fill="none"
            d="M307.9,276.8c-0.1,0-0.2,0.2-0.4,0.6l0.4,2.3c0.2,2,0.4,4.4,0.5,7.2c0,2.8,0,5.2-0.2,7.2
	c-0.1,1-0.1,1.9-0.2,2.4c0.1,0.4,0.2,0.6,0.4,0.6c0.2,0,0.5-1,0.6-3c0.1-2,0.2-4.4,0.2-7.2c-0.1-2.8-0.2-5.2-0.5-7.2
	C308.3,277.9,308.2,276.8,307.9,276.8L307.9,276.8z M310.1,273.8c-0.6,0.5-0.8,2.1-0.6,4.9v28.1l0.5,27.6h11.8l0.2-0.4
	c0-0.6,0.2-0.8,0.8-0.8H342l0.4,0.1l0.2-59.6h-0.7l1.4-2.3l0.8-1.3l0.7-1l-1.8,1l-2.4,3.6h1L310.1,273.8L310.1,273.8z M314.3,278.1
	h14.2c1.9,0,2.8,0.9,2.8,2.8v22.8c0,1.9-0.9,2.8-2.8,2.8h-14.2c-1.9,0-2.8-0.9-2.8-2.8v-22.8C311.5,279,312.4,278.1,314.3,278.1
	L314.3,278.1z M329.4,317.5c0.4,2.1,0.4,4.2,0,6.3c0,0.6-0.2,0.8-0.8,0.8h-10.7c-0.6,0-0.8-0.2-0.8-0.8c-0.2-2.2-0.2-4.3,0-6.3
	c0-0.6,0.2-0.8,0.8-0.8h10.7C329.2,316.7,329.4,316.9,329.4,317.5L329.4,317.5z"
          />
          <path
            fill="none"
            d="M320.8,278.1h-6.5c-1.9,0-2.8,0.9-2.8,2.8v22.8c0,1.9,0.9,2.8,2.8,2.8h14.2c1.9,0,2.8-0.9,2.8-2.8v-22.8
	c0-1.9-0.9-2.8-2.8-2.8L320.8,278.1L320.8,278.1z M314.3,279.5h14.2c0.9,0,1.4,0.5,1.4,1.4v22.8c0,0.9-0.5,1.4-1.4,1.4h-14.2
	c-0.9,0-1.4-0.5-1.4-1.4v-22.8C313,280,313.5,279.5,314.3,279.5L314.3,279.5z"
          />
          <path
            fill="none"
            d="M317.2,279.5h-2.8c-0.9,0-1.4,0.5-1.4,1.4v11.4h5.5c-0.7-0.4-1.4-0.8-2.1-1.5c-1.4-1.4-2.2-3.3-2.2-5.2
	c0-2.1,0.7-3.8,2.2-5.4L317.2,279.5L317.2,279.5z"
          />
          <path
            fill="none"
            d="M317.2,279.5l-0.8,0.7c-1.4,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.8,2.2,5.2c0.6,0.7,1.3,1.2,2.1,1.5h6.5
	c0.7-0.4,1.4-0.8,2-1.5c1.5-1.4,2.2-3.3,2.2-5.2c0-2.1-0.7-3.8-2.2-5.4l-0.8-0.7h-1.4c0.6,0.4,1,0.7,1.6,1.2c1.3,1.3,2,2.9,2,4.8
	c0,1.9-0.7,3.5-2,4.8s-2.9,2-4.8,2s-3.5-0.7-4.8-2s-2-2.9-2-4.8s0.7-3.5,2-4.8c0.5-0.5,1-0.9,1.6-1.2L317.2,279.5L317.2,279.5z"
          />
          <path
            fill="none"
            d="M318.5,279.5c-0.6,0.4-1.2,0.7-1.6,1.2c-1.3,1.3-2,2.9-2,4.8c0,1.9,0.7,3.5,2,4.8s2.9,2,4.8,2
	c1.9,0,3.5-0.7,4.8-2s2-2.9,2-4.8s-0.7-3.5-2-4.8c-0.5-0.5-1-0.9-1.6-1.2L318.5,279.5L318.5,279.5z M320,285.6
	c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5s0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.5-1.2,0.5
	s-0.8-0.1-1.2-0.5C320.1,286.4,320,286,320,285.6z"
          />
          <path
            fill="none"
            d="M320.5,284.4c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5S320.8,284.1,320.5,284.4z M318.4,292.3h-5.5v11.4
	c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4L318.4,292.3L318.4,292.3z M319.8,292.8l1.8-0.2c0.7,0,1.3,0.1,1.9,0.2
	c0.9,0.2,1.8,0.8,2.4,1.5c1.3,1.3,1.9,2.7,1.9,4.4c0,1.7-0.6,3.1-1.9,4.4c-1.2,1.3-2.7,1.9-4.4,1.9s-3.1-0.6-4.4-1.9
	c-1.2-1.2-1.9-2.7-1.9-4.4c0-1.8,0.6-3.1,1.9-4.4C317.9,293.6,318.7,293.2,319.8,292.8L319.8,292.8z"
          />
          <path
            fill="none"
            d="M321.5,292.6l-1.8,0.2c-1,0.2-1.9,0.8-2.7,1.6c-1.2,1.3-1.9,2.7-1.9,4.4c0,1.7,0.6,3.1,1.9,4.4
	c1.3,1.3,2.7,1.9,4.4,1.9s3.1-0.6,4.4-1.9c1.3-1.2,1.9-2.7,1.9-4.4c0-1.8-0.6-3.1-1.9-4.4c-0.7-0.7-1.5-1.3-2.4-1.5
	C322.8,292.7,322.2,292.6,321.5,292.6L321.5,292.6z M317.5,302.8c-1-1-1.6-2.3-1.6-4c0-1.5,0.6-2.9,1.6-4c1-1,2.4-1.6,4-1.6
	c1.5,0,2.9,0.6,4,1.6c1,1,1.6,2.5,1.6,4c0,1.5-0.6,2.9-1.6,4c-1,1-2.4,1.6-4,1.6C319.9,304.5,318.6,303.9,317.5,302.8L317.5,302.8z"
          />
          <path
            fill="none"
            d="M315.9,298.9c0,1.5,0.6,2.9,1.6,4c1,1,2.4,1.6,4,1.6c1.5,0,2.9-0.6,4-1.6c1-1,1.6-2.3,1.6-4
	c0-1.5-0.6-2.9-1.6-4c-1-1-2.4-1.6-4-1.6c-1.5,0-2.9,0.6-4,1.6C316.4,296,315.9,297.2,315.9,298.9L315.9,298.9z M321.5,300.3
	c-0.4,0-0.7-0.1-0.9-0.5c-0.2-0.2-0.5-0.6-0.5-1c0-0.4,0.1-0.7,0.5-0.9c0.2-0.2,0.6-0.5,0.9-0.5s0.7,0.1,1,0.5
	c0.2,0.2,0.5,0.6,0.5,0.9s-0.1,0.7-0.5,1C322.2,300.1,321.9,300.3,321.5,300.3L321.5,300.3z"
          />
          <path
            fill="none"
            d="M320.5,299.8c0.2,0.2,0.6,0.5,0.9,0.5s0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1c0-0.4-0.1-0.7-0.5-0.9
	c-0.2-0.2-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-0.9,0.5c-0.2,0.2-0.5,0.6-0.5,0.9C320.1,299.2,320.2,299.6,320.5,299.8L320.5,299.8z
	 M329.9,280.9c0-0.9-0.5-1.4-1.4-1.4h-2.3l0.8,0.7c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2,1.5h5v-11.4
	L329.9,280.9L329.9,280.9z M329.4,323.8c0.4-2.1,0.4-4.2,0-6.3c0-0.6-0.2-0.8-0.8-0.8h-10.7c-0.6,0-0.8,0.2-0.8,0.8
	c-0.2,2-0.2,4.1,0,6.3c0,0.6,0.2,0.8,0.8,0.8h10.7C329.2,324.6,329.4,324.4,329.4,323.8L329.4,323.8z M328,317
	c0.5,0,0.7,0.2,0.7,0.7c0.2,1.9,0.2,3.7,0,5.7c0,0.5-0.2,0.7-0.7,0.7h-9.7c-0.5,0-0.7-0.2-0.7-0.7c-0.2-2-0.2-3.8,0-5.7
	c0-0.5,0.2-0.7,0.7-0.7H328L328,317z"
          />
          <path
            fill="none"
            d="M328.9,317.9c0-0.5-0.2-0.7-0.7-0.7h-9.7c-0.5,0-0.7,0.2-0.7,0.7c-0.2,1.9-0.2,3.7,0,5.7c0,0.5,0.2,0.7,0.7,0.7
	h9.7c0.5,0,0.7-0.2,0.7-0.7C329.1,321.6,329.1,319.7,328.9,317.9L328.9,317.9z M307.6,277.4c-0.1,0.5-0.2,1.3-0.4,2.3
	c-0.1,2-0.2,4.4-0.1,7.2c0,2.8,0.1,5.2,0.4,7.2c0.1,1,0.2,1.9,0.5,2.4c0.1-0.5,0.2-1.3,0.2-2.4c0.1-2,0.2-4.4,0.2-7.2
	c-0.1-2.8-0.2-5.2-0.5-7.2L307.6,277.4L307.6,277.4z M306.5,287.7v2 M380.7,251.2l-1.3,1.3c-1.4,1.5-2.7,3-3.8,4.5
	c-0.8,1-1.5,2.1-2.3,3.3c-1.9,2.8-3.4,5.8-4.7,9l-0.4,0.9l-0.5,1.3c-0.2,0.8-0.5,1.5-0.7,2.3c-1.4,4.9-2.1,10-2.1,15.5
	c0,10.8,2.8,20.5,8.3,29l2.3,3.3c1.5,2,3.3,4,5.1,5.8c3,3,6.2,5.6,9.6,7.7c8.4,5.4,18,8,28.6,8c10.6,0,20.2-2.7,28.6-8
	c3.1-2,6.2-4.4,9.1-7.2l0.9-0.9l1.4-1.5c1.2-1.3,2.3-2.6,3.3-4l0.6-0.7l1.2-1.6l0.6-0.9c5.5-8.4,8.3-17.8,8.4-28.4v-2.5l-0.4-4.7
	l-0.2-1.6l-0.1-0.9c-0.4-2.2-0.8-4.4-1.4-6.5l-0.7-2.3l-0.5-1.3l-0.7-1.6c-1.6-4-3.7-7.7-6.4-11.2l-0.2-0.2c-1.2-1.5-2.5-3-3.8-4.5
	l-1.3-1.3c-8.6-8.6-18.8-13.8-30.3-15.3l-7.8-0.5l-7.8,0.5C399.4,237.4,389.4,242.6,380.7,251.2L380.7,251.2z M373.3,265.5
	c1.3-2.4,2.8-4.7,4.4-6.9c1.2-1.5,2.3-2.9,3.7-4.3l2.2-2.2c9.8-9.3,21.6-14,35.2-14s25.4,4.7,35.2,14l2.2,2.2
	c1.3,1.4,2.6,2.8,3.7,4.3c0.8,1,1.6,2.2,2.3,3.4c1.6,2.7,3.1,5.4,4.2,8.3l1.3,3.6c1.5,4.8,2.2,9.8,2.2,15.2v0.4
	c0,10.1-2.6,19.2-7.7,27.3l-3,4.2c-1.3,1.6-2.7,3.1-4.2,4.7c-4,4-8.3,7.2-12.9,9.6c-7,3.6-14.8,5.4-23.2,5.4
	c-8.5,0-16.2-1.9-23.2-5.4c-4.7-2.5-9-5.6-13.1-9.6c-1.8-1.8-3.4-3.6-4.9-5.6c-1.6-2.2-3.1-4.5-4.4-6.9c-3.7-7.1-5.7-15-5.7-23.8
	c0-5.5,0.7-10.6,2.2-15.5l1.3-3.6C371.7,268.6,372.4,267,373.3,265.5L373.3,265.5z"
          />
          <path
            fill="none"
            d="M377.7,258.6c-1.6,2.2-3.1,4.5-4.4,6.9c-0.8,1.5-1.5,3-2.2,4.7l-1.3,3.6c-1.5,4.9-2.2,10-2.2,15.5
	c0,8.7,1.9,16.7,5.7,23.8c1.3,2.5,2.8,4.7,4.4,6.9c1.5,2,3.1,3.7,4.9,5.6c4,4,8.3,7.2,13.1,9.6c7,3.6,14.8,5.4,23.2,5.4
	c8.5,0,16.2-1.7,23.2-5.4c4.7-2.5,9-5.6,12.9-9.6c1.5-1.5,2.9-3,4.2-4.7l3-4.2c5.1-8,7.7-17.1,7.7-27.3v-0.4
	c0-5.4-0.8-10.5-2.2-15.2l-1.3-3.6c-1.2-2.8-2.6-5.6-4.2-8.3c-0.7-1.2-1.5-2.2-2.3-3.4c-1.2-1.5-2.3-2.9-3.7-4.3l-2.2-2.2
	c-9.8-9.3-21.6-14-35.2-14c-13.6,0-25.4,4.7-35.2,14l-2.2,2.2C380,255.7,378.7,257.1,377.7,258.6L377.7,258.6z M384.8,289.3
	c0-5.7,1.2-10.8,3.6-15.5c0.4-0.8,0.8-1.5,1.3-2.3c0.2-0.5,0.6-0.8,0.8-1.3c1.2-1.8,2.6-3.4,4.2-5c6.6-6.6,14.7-10,24.1-10
	c9.4,0,17.5,3.4,24.1,10c1.6,1.6,3,3.3,4.2,5l2.2,3.6c2.5,4.7,3.6,9.8,3.6,15.5c0,5.2-1,10-3,14.5c-1.6,3.5-4,6.8-6.9,9.7
	c-3.5,3.5-7.3,6.1-11.7,7.8c-3.8,1.5-7.9,2.2-12.4,2.2s-8.6-0.7-12.4-2.2c-4.3-1.6-8.2-4.2-11.7-7.8c-2.4-2.4-4.4-5-5.9-7.8
	C386.2,300.7,384.8,295.3,384.8,289.3L384.8,289.3z"
          />
          <path
            fill="none"
            d="M388.4,273.8c-2.4,4.7-3.6,9.8-3.6,15.5c0,6.1,1.4,11.4,4.1,16.3c1.5,2.8,3.5,5.4,5.9,7.8
	c3.5,3.5,7.3,6.1,11.7,7.8c3.8,1.5,7.9,2.2,12.4,2.2s8.6-0.7,12.4-2.2c4.3-1.6,8.2-4.2,11.7-7.8c2.9-2.9,5.2-6.2,6.9-9.7
	c2.1-4.4,3-9.2,3-14.5c0-5.7-1.2-10.8-3.6-15.5l-2.2-3.6c-1.3-1.8-2.6-3.4-4.2-5c-6.6-6.6-14.7-10-24.1-10c-9.4,0-17.5,3.4-24.1,10
	c-1.6,1.6-2.9,3.3-4.2,5c-0.4,0.5-0.6,0.8-0.8,1.3C389.2,272.2,388.9,273,388.4,273.8L388.4,273.8z M388.9,278.8l0.5-1.2
	c0.5-1.3,1.2-2.6,1.9-3.8c0.5-0.8,0.9-1.5,1.5-2.3l0.9-1.3c0.9-1.2,1.9-2.2,2.9-3.3c5-5,10.8-7.9,17.5-9l4.9-0.4
	c8.7,0,16.2,3.1,22.4,9.3c1,1,2,2.2,2.9,3.3l2.4,3.6c0.7,1.3,1.3,2.6,1.9,3.8c0.6,1.5,1.1,3,1.4,4.7c0.5,2.2,0.7,4.5,0.7,7
	s-0.2,4.8-0.7,7c-1.3,5.8-4.1,11-8.5,15.4l-0.1,0.1c-6.2,6.1-13.6,9.2-22.4,9.2c-8.7,0-16.2-3.1-22.4-9.3c-3.5-3.5-6.1-7.5-7.6-11.9
	c-1.2-3.3-1.8-6.8-1.8-10.6c0-1.7,0.1-3.4,0.4-5v-0.1C387.8,282.3,388.3,280.6,388.9,278.8L388.9,278.8z"
          />
          <path
            fill="none"
            d="M444.3,270.2c-0.9-1.2-1.9-2.2-2.9-3.3c-6.2-6.2-13.6-9.3-22.4-9.3l-4.9,0.4c-0.9,0.8-1.4,2-1.5,3.5l-1.3,13.5
	c0,0.7-0.1,1.3-0.4,1.8c-0.4,0.5-0.7,0.8-1.2,0.9l-0.2,0.1l-1.4-0.1l-13.6-3.1c-1.4-0.4-2.7-0.2-3.7,0.4c1-0.8,2.3-1.2,4.2-1v-0.1
	c0.5-0.8,1-1.5,1.7-2.3l0.8-0.9l1.3-1.4c3.8-3.8,8.2-6.3,13.1-7.6c0.5-1.8,1.2-2.9,2.2-3.6c-6.6,0.9-12.6,4-17.5,9
	c-1,1-2,2.2-2.9,3.3l-0.9,1.3c-0.6,0.7-1,1.5-1.5,2.3c-0.7,1.3-1.3,2.6-1.9,3.8l-0.5,1.2c-0.6,1.8-1,3.6-1.3,5.5
	c0.2,0.6,0.7,1.2,1.3,1.6c0.5,0.4,0.9,0.6,1.5,0.8l6.2,2.6H408c-1.3-0.5-2.3-1.2-3.1-2c-0.8-0.9-1-1.9-0.8-2.8
	c0.2-0.9,1-1.5,2.2-1.8c1.2-0.2,2.6-0.2,4,0.2l0.8,0.2c0.6,0.4,1,0.8,1.4,1.5c0.5,0.8,0.5,1.6,0.2,2.6c-0.2,0.9-0.8,1.5-1.6,2h3.4
	c0-1.3,0.5-2.3,1.3-3.1c0.9-0.9,2-1.3,3.1-1.3c1.3,0,2.3,0.5,3.1,1.3c0.9,0.9,1.4,2,1.4,3.1h3.4c-0.1,0-0.1-0.1-0.2-0.1
	c-0.8-0.5-1.4-1-1.6-2c-0.2-0.9-0.2-1.8,0.2-2.6c0.4-0.7,0.8-1.2,1.5-1.5l0.8-0.2c1.4-0.5,2.8-0.6,4-0.2c1.3,0.2,2,0.9,2.2,1.8
	c0.2,0.9,0,1.9-0.8,2.8s-1.9,1.6-3.4,2.1h12.1l5.6-2.3h0.1c1.2-0.5,2-1.2,2.6-2l0.2-0.5l-0.2,0.6c-0.5,0.9-1.3,1.9-2.4,2.7v1.6h3.1
	c0-2.4-0.2-4.8-0.7-7c-0.4-1.6-0.8-3.1-1.4-4.7c-0.5-1.3-1.2-2.6-1.9-3.8L444.3,270.2L444.3,270.2z M425.4,261.6v-0.1
	c-0.1-1.4-0.6-2.6-1.5-3.4c1,0.7,1.7,1.9,2.2,3.6c4.9,1.2,9.1,3.7,12.9,7.5l2.9,3.4l0.9,1.3l0.1,0.2c1.9-0.1,3.1,0.2,4.2,1
	c-1-0.6-2.2-0.7-3.7-0.4l-13.3,3c-0.6,0.2-1.2,0.2-1.7,0.2l-0.6-0.2c-0.4-0.2-0.7-0.5-0.8-0.8c-0.2-0.5-0.4-1-0.4-1.6L425.4,261.6
	L425.4,261.6z M419.4,273.8c0.7,0.2,1.2,0.7,1.6,1.5c0.5,0.7,0.7,1.5,0.8,2.3l0.1,2.3c-0.1,0.7-0.5,1.3-0.9,1.9
	c-0.7,0.7-1.4,0.9-2.3,0.9s-1.8-0.4-2.5-0.9c-0.5-0.6-0.8-1.2-0.9-1.9v-0.8c0-0.6,0-1,0.1-1.5c0.1-0.9,0.5-1.6,0.8-2.3
	c0.5-0.8,1-1.3,1.8-1.5l0.7-0.1L419.4,273.8L419.4,273.8z"
          />
          <path
            fill="none"
            d="M390.4,287.6c-0.7-0.4-1.2-0.8-1.5-1.2c-0.7-0.7-1-1.4-1.3-2.1c-0.2,1.6-0.4,3.3-0.4,5h3.1
	C390.3,288.7,390.3,288.1,390.4,287.6L390.4,287.6z"
          />
          <path
            fill="none"
            d="M414.4,289.3H411c-0.7,0.4-1.3,0.5-2.1,0.4l-0.8-0.2c-0.1,0-0.2-0.1-0.2-0.1h-11.4l6.4,2.8
	c0.7,0.2,1.2,0.5,1.5,0.8c0.5,0.6,0.6,1.2,0.6,1.7c-0.1,0.5-0.4,1-0.7,1.6l-7,11.7c-0.8,1.3-1,2.5-0.8,3.7c-0.5-1.2-0.4-2.7,0.4-4.3
	c-4.3-5.1-6.4-11.2-6.4-18.1h-3.1c0,3.7,0.6,7.2,1.8,10.6c1.5,4.3,4.1,8.3,7.6,11.9c6.2,6.2,13.6,9.3,22.4,9.3
	c8.7,0,16.2-3,22.4-9.2c0.1-1.2-0.1-2.3-0.9-3.6l-7-11.8c-0.4-0.5-0.7-1-0.7-1.6c0-0.7,0.1-1.3,0.6-1.7c0.4-0.4,0.8-0.7,1.5-0.9l7-3
	h-12.1l-0.8,0.2c-0.7,0.1-1.3,0-1.9-0.2h-3.4c0,1.3-0.5,2.3-1.4,3.3c-0.9,0.8-2,1.3-3.1,1.3c-1.3,0-2.3-0.5-3.1-1.3
	C414.8,291.6,414.4,290.6,414.4,289.3L414.4,289.3z M410.7,294.9c0.5-0.5,1.2-0.8,1.9-0.9c0.9-0.1,1.8,0,2.4,0.6
	c0.7,0.6,1.2,1.3,1.3,2.2c0.1,0.7,0,1.4-0.4,2l-0.5,0.7c-0.9,1.3-1.9,2.1-3,2.6s-2.1,0.5-2.8-0.1c-0.7-0.6-1-1.4-0.9-2.7
	c0.1-1.3,0.6-2.5,1.5-3.7L410.7,294.9L410.7,294.9z M416.5,305.3c0.4-0.6,0.8-0.9,1.3-1.2c0.7-0.2,1.3-0.2,1.9,0
	c0.5,0.2,0.9,0.6,1.3,1.2l9.1,10.4c0.9,1,2,1.6,3.1,1.9c-1.3,0.1-2.6-0.5-4-1.5c-3.1,1.3-6.6,1.9-10.4,1.9c-3.8,0-7.3-0.7-10.6-2
	c-1.4,1.2-2.8,1.6-4,1.6c1.2-0.1,2.3-0.8,3.3-2L416.5,305.3L416.5,305.3z M421.3,296.6c0.1-0.9,0.6-1.6,1.4-2.2
	c0.7-0.6,1.5-0.7,2.5-0.6c0.7,0.1,1.3,0.5,1.9,0.9l0.6,0.7c0.9,1.2,1.4,2.4,1.5,3.7c0.1,1.3-0.2,2.1-0.9,2.7
	c-0.7,0.6-1.6,0.6-2.8,0.1s-2.2-1.3-3-2.6l-0.5-0.7C421.3,298,421.2,297.3,421.3,296.6L421.3,296.6z"
          />
          <path
            fill="none"
            d="M450.6,289.3h-3.1c0,7-2.2,13.1-6.5,18.2c0.7,1.6,0.8,3,0.4,4.2l0.1-0.1c4.4-4.4,7.3-9.6,8.5-15.4
	C450.3,294.1,450.6,291.8,450.6,289.3L450.6,289.3z M439,269.1c-3.7-3.8-8-6.3-12.9-7.5l0.8,7.7l1.7,0.8c0.7,0.4,1.4,0.8,2.1,1.3
	l0.7,0.5l10.4,0.5L439,269.1L439,269.1z"
          />
          <path
            fill="none"
            d="M431.6,271.9l-0.7-0.5c-0.7-0.5-1.4-0.9-2.1-1.3l-1.7-0.8l0.5,4.4c0.2,1,0.6,1.8,1.3,2.1
	c0.7,0.6,1.6,0.7,2.9,0.5l3.7-0.8l-1.6-1.8L431.6,271.9L431.6,271.9z"
          />
          <path
            fill="none"
            d="M427,269.4l-0.8-7.7c-0.5-1.6-1.3-2.9-2.2-3.5c0.8,0.8,1.4,2,1.5,3.4v0.1l1.3,13.6c0,0.7,0.1,1.3,0.4,1.6
	c0.2,0.4,0.5,0.6,0.8,0.8l0.6,0.2c0.5,0.1,1,0,1.7-0.2l13.3-3c1.5-0.4,2.7-0.2,3.7,0.4c-0.9-0.8-2.3-1.2-4.2-1l-7.6,1.6l-3.7,0.8
	c-1.3,0.2-2.2,0.1-2.9-0.5c-0.7-0.4-1-1-1.3-2.1L427,269.4L427,269.4z"
          />
          <path
            fill="none"
            d="M441.9,272.4l-10.4-0.5l2.1,1.9l1.7,1.8l7.6-1.5l-0.1-0.2 M422,277.6c-0.1-0.9-0.5-1.6-0.8-2.3
	c-0.5-0.8-1-1.3-1.6-1.5h-1.4c-0.7,0.2-1.3,0.7-1.7,1.5c-0.5,0.7-0.7,1.5-0.8,2.3c-0.1,0.5-0.1,0.9-0.1,1.5v0.8
	c0.1,0.7,0.5,1.3,0.9,1.9c0.7,0.7,1.5,0.9,2.4,0.9s1.8-0.4,2.3-0.9c0.5-0.6,0.8-1.2,0.9-1.9L422,277.6L422,277.6z M420.8,277.4
	l0.2,0.2c0.5,0.5,0.7,1.2,0.7,1.9c0,0.8-0.2,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2,0.9c-0.8,0-1.5-0.2-2.1-0.9c-0.6-0.6-0.8-1.3-0.8-2.1
	c0-0.7,0.2-1.3,0.7-1.9l0.2-0.2c0.6-0.6,1.3-0.9,2.1-0.9C419.7,276.5,420.2,276.8,420.8,277.4L420.8,277.4z"
          />
          <path
            fill="none"
            d="M421.1,277.6l-0.2-0.2c-0.6-0.6-1.3-0.9-2-0.9c-0.8,0-1.5,0.2-2.1,0.9l-0.2,0.2c-0.5,0.5-0.7,1.2-0.7,1.9
	c0,0.8,0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.2,2-0.9c0.6-0.6,0.9-1.3,0.9-2.1C421.8,278.8,421.5,278.1,421.1,277.6
	L421.1,277.6z M418.7,273.7l-0.6,0.1h1.3 M411,283.4l-0.8-0.2c-1.4-0.5-2.8-0.6-4-0.2c-1.3,0.2-2,0.9-2.2,1.8
	c-0.4,0.9,0,1.9,0.8,2.8s2,1.6,3.4,2.1l0.8,0.2c0.7,0.1,1.4,0,2.1-0.4c0.8-0.5,1.4-1,1.6-2c0.2-0.9,0.2-1.8-0.2-2.6
	C412.1,284.2,411.6,283.6,411,283.4L411,283.4z M412.1,285c0.4,0.7,0.5,1.4,0.2,2.2c-0.2,0.8-0.7,1.3-1.5,1.8
	c-0.7,0.4-1.5,0.5-2.2,0.1c-0.8-0.2-1.3-0.7-1.8-1.4c-0.4-0.7-0.5-1.5-0.2-2.2c0.2-0.7,0.7-1.3,1.4-1.8c0.7-0.4,1.5-0.5,2.2-0.2
	C411.2,283.7,411.7,284.2,412.1,285L412.1,285z"
          />
          <path
            fill="none"
            d="M412.2,287.2c0.2-0.8,0.2-1.5-0.2-2.2c-0.4-0.7-0.9-1.3-1.6-1.5c-0.8-0.2-1.5-0.2-2.2,0.2
	c-0.7,0.4-1.2,0.9-1.4,1.7c-0.2,0.7-0.2,1.5,0.2,2.2c0.4,0.7,0.9,1.2,1.8,1.4c0.7,0.2,1.5,0.2,2.2-0.1
	C411.5,288.5,412,287.9,412.2,287.2L412.2,287.2z M422.1,286c-0.9-0.9-2-1.3-3.1-1.3c-1.3,0-2.3,0.5-3.1,1.3c-0.8,0.9-1.3,2-1.3,3.1
	c0,1.3,0.5,2.3,1.3,3.3c0.9,0.8,2,1.3,3.1,1.3c1.3,0,2.3-0.5,3.1-1.3c0.9-0.9,1.4-2,1.4-3.3C423.4,288,422.9,287,422.1,286
	L422.1,286z M416.5,288.3h0.6v1.2l-0.1,0.2c-0.1,0.1-0.2,0.2-0.5,0.2h-0.2l-0.6-0.1c-0.2-0.1-0.4-0.2-0.4-0.4V289h0.5v0.3l0.2,0.1
	h0.2l0.1-0.2L416.5,288.3L416.5,288.3z M418.1,288.8h0.1c0.2,0,0.4,0.1,0.5,0.4l0.1,0.2v0.2h-1v0.2c0,0.1,0.1,0.1,0.1,0.1h0.1
	l0.1-0.1h0.5l-0.1,0.1l-0.2,0.1l-0.5,0.1h-0.2c-0.2-0.1-0.4-0.1-0.5-0.4l-0.1-0.4l0.1-0.2v-0.1c0.1-0.1,0.1-0.2,0.2-0.2l0.4-0.1
	L418.1,288.8L418.1,288.8z M422.1,289.1l0.1,0.2v0.5l-0.1,0.2l-0.4,0.1h-0.4l-0.1-0.1v0.5h-0.5v-1.8h0.5v0.1l0.1-0.1l0.2-0.1h0.5
	L422.1,289.1L422.1,289.1z M420.4,289.8l-0.1,0.1l-0.4,0.2l-0.4,0.1h-0.2c-0.2-0.1-0.4-0.2-0.5-0.4v-0.2c0-0.1,0-0.4,0.1-0.5
	l0.2-0.2l0.4-0.1h0.4c0.2,0,0.4,0.1,0.5,0.4l0.1,0.2v0.2h-1v0.1c0,0.1,0.1,0.1,0.2,0.1h0.1l0.1-0.1L420.4,289.8L420.4,289.8z"
          />
          <path
            fill="none"
            d="M417.1,288.3h-0.6v1.3l-0.1,0.2h-0.2l-0.2-0.1v-0.4h-0.5v0.5l0.4,0.4l0.6,0.1h0.2c0.2-0.1,0.4-0.1,0.5-0.2
	l0.1-0.2L417.1,288.3L417.1,288.3z M418.3,288.8h-0.4l-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2v0.1l-0.1,0.2l0.1,0.4
	c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.5-0.1l0.2-0.1l0.1-0.1h-0.5l-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1-0.1v-0.2h1v-0.2l-0.1-0.2
	C418.6,289,418.5,288.8,418.3,288.8L418.3,288.8z M417.9,289.1h0.1l0.2,0.1v0.1h-0.5v-0.1C417.8,289.1,417.9,289.1,417.9,289.1z"
          />
          <path
            fill="none"
            d="M418,289.1h-0.1c-0.1,0-0.1,0-0.1,0.1v0.1h0.5v-0.1L418,289.1z M422.1,289.3l-0.1-0.2l-0.2-0.2h-0.5l-0.2,0.1
	l-0.1,0.1V289h-0.5v1.8h0.5v-0.5l0.1,0.1h0.4l0.4-0.1l0.1-0.2L422.1,289.3L422.1,289.3z M421.4,289.2l0.2,0.1v0.5l-0.1,0.1l-0.2,0.1
	h-0.1l-0.1-0.1v-0.2l0.1-0.2C421.2,289.2,421.3,289.2,421.4,289.2L421.4,289.2z"
          />
          <path
            fill="none"
            d="M421.4,289.2c-0.2,0-0.2,0-0.4,0.1l-0.1,0.2v0.2l0.1,0.1h0.1l0.2-0.1l0.1-0.1v-0.5H421.4L421.4,289.2z
	 M420.4,289.8h-0.5l-0.1,0.1h-0.1c-0.1,0-0.2-0.1-0.2-0.1v-0.1h1v-0.2l-0.1-0.2c-0.1-0.2-0.2-0.2-0.5-0.4h-0.4l-0.4,0.1l-0.2,0.2
	c-0.1,0.1-0.1,0.2-0.1,0.5v0.2c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.4-0.1l0.4-0.2L420.4,289.8L420.4,289.8z M419.9,289.2l-0.5,0.1v-0.1
	c0-0.1,0.1-0.1,0.1-0.1h0.1L419.9,289.2z"
          />
          <path
            fill="none"
            d="M419.9,289.3l-0.2-0.2h-0.1c-0.1,0-0.1,0-0.1,0.1v0.1H419.9L419.9,289.3z M422.7,294.5
	c-0.7,0.5-1.2,1.3-1.4,2.2c-0.1,0.7,0,1.4,0.4,2.1l0.5,0.7c0.9,1.3,1.9,2.1,3,2.6s2.1,0.5,2.8-0.1c0.8-0.6,1-1.4,0.9-2.7
	c-0.1-1.3-0.6-2.6-1.5-3.7l-0.6-0.7c-0.5-0.5-1.2-0.8-1.9-0.9C424.2,293.7,423.4,294,422.7,294.5L422.7,294.5z M421.8,296.8
	c0.1-0.8,0.5-1.4,1.2-1.9c0.7-0.5,1.4-0.7,2.2-0.5c0.8,0.1,1.4,0.5,1.9,1.2c0.5,0.7,0.7,1.4,0.5,2.2c-0.1,0.8-0.5,1.4-1.2,1.9
	c-0.7,0.5-1.4,0.7-2.2,0.5c-0.8-0.1-1.4-0.5-1.9-1.2C421.8,298.3,421.6,297.6,421.8,296.8L421.8,296.8z"
          />
          <path
            fill="none"
            d="M422.9,294.9c-0.7,0.5-1,1-1.2,1.9c-0.1,0.8,0,1.5,0.5,2.2c0.5,0.7,1,1,1.9,1.2c0.8,0.1,1.5,0,2.2-0.5
	c0.7-0.5,1-1.2,1.2-1.9c0.1-0.8,0-1.5-0.5-2.2c-0.5-0.7-1.2-1-1.9-1.2C424.2,294.2,423.5,294.4,422.9,294.9L422.9,294.9z M416,298.7
	c0.4-0.7,0.5-1.3,0.4-2c-0.1-0.9-0.6-1.6-1.3-2.2c-0.7-0.6-1.6-0.7-2.4-0.6c-0.7,0.1-1.4,0.5-1.9,0.9l-0.5,0.7
	c-0.9,1.3-1.4,2.4-1.5,3.7c-0.1,1.3,0.2,2.1,0.9,2.7c0.7,0.6,1.6,0.6,2.8,0.1c1.2-0.5,2.2-1.3,3-2.6L416,298.7L416,298.7z
	 M414.8,294.9c0.7,0.5,1,1.2,1.2,2s0,1.5-0.5,2.2s-1,1-1.9,1.2c-0.8,0.1-1.5-0.1-2.2-0.6c-0.7-0.5-1-1-1.2-1.9
	c-0.1-0.8,0-1.5,0.5-2.2c0.5-0.7,1-1,1.9-1.2C413.4,294.2,414.1,294.4,414.8,294.9L414.8,294.9z"
          />
          <path
            fill="none"
            d="M415.9,296.8c-0.1-0.8-0.5-1.4-1.2-2c-0.7-0.5-1.4-0.7-2.2-0.5c-0.8,0.1-1.4,0.5-1.9,1.2s-0.7,1.4-0.5,2.2
	c0.1,0.8,0.5,1.4,1.2,1.9c0.7,0.5,1.4,0.7,2.2,0.6c0.8-0.1,1.4-0.6,1.9-1.2C415.9,298.3,416.1,297.6,415.9,296.8L415.9,296.8z
	 M418,306h0.7l0.7,0.1c0.7,0.1,1.3,0.6,2,1.3l7.9,8.7c1.4,1,2.7,1.6,4,1.5c-1.2-0.1-2.2-0.7-3.1-1.9l-9.1-10.4
	c-0.4-0.6-0.8-0.9-1.3-1.2c-0.6-0.2-1.2-0.2-1.9,0c-0.5,0.2-0.9,0.6-1.3,1.2l-9,10.3c-0.9,1.2-2.1,1.8-3.3,2c1.3,0.1,2.6-0.5,4-1.6
	l7.8-8.6C416.7,306.6,417.3,306.2,418,306z"
          />
          <path
            fill="none"
            d="M418.7,306H418c-0.7,0.1-1.3,0.6-2,1.3l-7.8,8.6c3.3,1.3,6.8,2,10.6,2c3.7,0,7.2-0.6,10.4-1.9l-7.9-8.7
	c-0.7-0.7-1.3-1.2-2-1.3L418.7,306L418.7,306z M427.5,282.9l-0.8,0.4c-0.7,0.4-1.2,0.8-1.5,1.5c-0.5,0.8-0.5,1.6-0.2,2.6
	c0.2,0.8,0.8,1.5,1.6,2c0.7,0.4,1.3,0.5,2.1,0.4l0.8-0.2c1.5-0.5,2.6-1.2,3.4-2.1s1-1.9,0.8-2.8c-0.2-0.9-1-1.5-2.2-1.8
	C430.2,282.4,428.9,282.4,427.5,282.9L427.5,282.9z M429.5,283.6c0.7,0.4,1.2,0.9,1.4,1.7c0.2,0.7,0.2,1.5-0.1,2.2
	c-0.4,0.7-0.9,1.3-1.8,1.5c-0.8,0.2-1.5,0.2-2.2-0.2c-0.7-0.4-1.3-0.9-1.5-1.6c-0.2-0.8-0.2-1.5,0.2-2.2c0.4-0.7,0.9-1.2,1.8-1.4
	C428.1,283.2,428.8,283.2,429.5,283.6L429.5,283.6z"
          />
          <path
            fill="none"
            d="M431,285.3c-0.2-0.8-0.7-1.4-1.4-1.8c-0.7-0.4-1.5-0.5-2.2-0.1c-0.8,0.2-1.3,0.7-1.8,1.4
	c-0.4,0.7-0.5,1.5-0.2,2.2c0.2,0.8,0.7,1.3,1.5,1.6c0.7,0.4,1.4,0.5,2.2,0.2s1.4-0.7,1.7-1.5C431.1,286.9,431.2,286,431,285.3
	L431,285.3z M387.5,284.3c0.2,0.8,0.7,1.5,1.3,2.1c0.4,0.4,0.8,0.7,1.4,1h0.1l10.5,4.8c1.2,0.5,1.9,1.2,2.1,2.1
	c0.4,0.8,0.2,1.8-0.5,2.9l-5.7,10.1c-0.7,1.6-0.8,3.1-0.4,4.3c-0.2-1.2,0.1-2.4,0.8-3.7l7-11.7c0.4-0.5,0.7-1,0.7-1.6
	c0.1-0.6-0.1-1.2-0.6-1.8c-0.4-0.4-0.8-0.7-1.5-0.8l-12.6-5.4c-0.6-0.2-1-0.5-1.5-0.8C388.3,285.5,387.8,284.9,387.5,284.3
	L387.5,284.3z M387.5,284.2v0.1 M397.4,270.5l-0.8,0.9c-0.6,0.7-1.2,1.5-1.7,2.3v0.1h0.1l7.6,1.6l1.5-1.6c0.9-0.8,1.9-1.6,2.8-2.3
	l0.8-0.5L397.4,270.5L397.4,270.5z"
          />
          <path
            fill="none"
            d="M398.7,269.1l-1.3,1.4l10.3,0.5c0.5-0.2,0.9-0.6,1.3-0.8c0.6-0.4,1.3-0.6,1.9-0.9l0.8-7.7
	C406.8,262.7,402.5,265.3,398.7,269.1L398.7,269.1z"
          />
          <path
            fill="none"
            d="M411.7,261.6l-0.8,7.7l-0.6,4.4c-0.2,0.9-0.6,1.5-1.3,2c-0.7,0.6-1.6,0.7-2.9,0.5l-3.7-0.8l-7.6-1.6h-0.1
	c-1.8-0.1-3.1,0.2-4.2,1c1-0.6,2.3-0.7,3.7-0.4l13.6,3.1l1.4,0.1l0.2-0.1c0.5-0.1,0.9-0.5,1.2-0.9c0.2-0.5,0.4-1,0.4-1.8l1.3-13.5
	c0.1-1.5,0.6-2.7,1.5-3.5C412.9,258.6,412.2,259.9,411.7,261.6L411.7,261.6z"
          />
          <path
            fill="none"
            d="M407.7,271l-0.8,0.5c-0.9,0.7-2,1.4-2.8,2.3l-1.5,1.6l3.7,0.8c1.3,0.2,2.2,0.1,2.9-0.5c0.6-0.4,1-1,1.3-2
	l0.6-4.4c-0.7,0.2-1.3,0.6-1.9,0.9C408.6,270.4,408.1,270.7,407.7,271L407.7,271z M390.4,287.6c0,0.6-0.1,1.2-0.1,1.8
	c0,6.9,2.1,12.9,6.4,18.1l5.7-10.1c0.6-1.2,0.8-2.1,0.5-2.9c-0.2-0.8-0.9-1.5-2.1-2.1L390.4,287.6z M441.6,290.4l5.7-2.6h0.1
	c1.3-0.8,2.1-1.6,2.4-2.7l0.2-0.6l-0.2,0.5c-0.6,0.8-1.4,1.5-2.6,2h-0.1l-12.6,5.4c-0.7,0.2-1.2,0.5-1.5,0.8c-0.4,0.5-0.6,1-0.6,1.8
	c0.1,0.5,0.4,1,0.7,1.6l7,11.8c0.8,1.3,1,2.5,0.9,3.6c0.4-1.2,0.2-2.6-0.4-4.2l-5.7-10c-0.6-1.2-0.8-2.1-0.5-2.9
	c0.1-0.4,0.2-0.7,0.5-0.9c0.4-0.5,0.8-0.8,1.6-1.2L441.6,290.4L441.6,290.4z"
          />
          <path
            fill="none"
            d="M447.3,287.8l-5.7,2.6c0,2.3-0.2,3.8-1,4.7l-5.5-1.4c-0.2,0.4-0.5,0.6-0.5,0.9c-0.4,0.8-0.2,1.8,0.5,2.9l5.7,10
	c4.3-5.1,6.5-11.2,6.5-18.2L447.3,287.8L447.3,287.8z"
          />
          <path
            fill="none"
            d="M436.7,292.6c-0.7,0.4-1.3,0.7-1.6,1.2l5.5,1.4c0.7-0.8,1-2.3,1-4.7L436.7,292.6L436.7,292.6z M367.8,271.5
	l0.5-1.3h-24l-0.8,1.3 M342,273.8h25.1c0.2-0.8,0.5-1.5,0.7-2.3h-24.4L342,273.8L342,273.8z M364.9,289.3c0-5.5,0.7-10.6,2.1-15.5
	h-24.2l-0.2,59.6c0.4,0.1,0.5,0.4,0.5,0.8l0.4,1h47c-3.4-2.1-6.5-4.7-9.6-7.7c-1.9-1.9-3.6-3.8-5.1-5.8l-2.3-3.3
	C367.7,309.8,364.9,300.1,364.9,289.3L364.9,289.3z M352,285.2c0.6-0.6,1.2-0.8,2-0.8s1.4,0.2,2,0.8s0.8,1.2,0.8,2v0.4
	c0,1-0.1,1.9-0.2,2.6c-0.2,0.8-0.5,1.4-0.7,1.8v0.5c1.3,0.4,2.4,0.9,3.4,1.9c1.5,1.4,2.2,3.1,2.2,5.1c0,2-0.7,3.6-2.2,5
	c-0.9,0.8-1.9,1.4-3,1.7l0.1,0.8v2c0,0.7-0.2,1.3-0.7,1.7s-1,0.7-1.7,0.7s-1.3-0.2-1.8-0.7s-0.7-1-0.7-1.7v-2c0-0.2,0-0.6,0.1-0.8
	c-0.9-0.4-1.9-0.8-2.7-1.4l-0.5-0.4c-1.5-1.4-2.2-3-2.2-5c0-2,0.7-3.6,2.2-5.1l0.5-0.4c0.9-0.8,2-1.3,3.1-1.5v-0.8
	c-0.2-0.4-0.5-0.8-0.6-1.4c-0.1-0.7-0.2-1.4-0.1-2.3l-0.1-0.6C351.2,286.5,351.5,285.8,352,285.2L352,285.2z M356.4,316.8
	c0,0.6-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6c0-0.7,0.2-1.2,0.7-1.6c0.5-0.5,0.9-0.7,1.6-0.7
	c0.6,0,1.2,0.2,1.6,0.7C356.1,315.5,356.4,316.1,356.4,316.8L356.4,316.8z M356.5,332.3l0.8,1h-2l-0.9-1l-0.4-0.2l-0.5-0.1h-0.1v1.4
	h-1.9v-3.6l4.2,0.1c0.4,0.1,0.6,0.2,0.7,0.4c0.2,0.1,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.4,0.2-0.6,0.4l-0.6,0.1
	l0.5,0.1L356.5,332.3z M357.9,333.4v-3.6h2.7l1.3,0.1c0.4,0.1,0.6,0.2,0.8,0.4c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.2,0.1,0.5,0.1,0.7
	c0,0.4-0.1,0.7-0.2,0.9l-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.2l-1,0.1L357.9,333.4L357.9,333.4z M346.6,333.3c-0.4-0.1-0.7-0.4-1-0.6
	c-0.2-0.2-0.5-0.7-0.5-1c0-0.6,0.2-1,0.8-1.4c0.5-0.4,1.3-0.5,2.2-0.5h0.8l0.9,0.2c0.5,0.2,0.7,0.5,0.9,0.8l-1.6,0.2l-0.1-0.2
	l-0.1-0.1l-0.4-0.1l-0.5-0.1c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.1-0.2,0.4-0.2,0.7s0.1,0.7,0.2,0.8c0.2,0.1,0.5,0.2,0.8,0.2
	s0.6-0.1,0.8-0.1l0.4-0.5l1.5,0.4c-0.1,0.2-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5l-0.6,0.1h-0.7L346.6,333.3L346.6,333.3z"
          />
          <path
            fill="none"
            d="M354,284.4c-0.8,0-1.4,0.2-2,0.8s-0.8,1.2-0.8,2l0.1,0.6c0.1,0.5,0.4,1,0.8,1.4c0.6,0.6,1.2,0.8,2,0.8
	c0.8,0,1.4-0.2,2-0.8c0.5-0.5,0.7-1,0.8-1.6v-0.4c0-0.8-0.2-1.4-0.8-2C355.4,284.6,354.8,284.4,354,284.4L354,284.4z M352.6,288.6
	c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.5,0.2-0.9,0.6-1.4c0.4-0.4,0.8-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4
	c0,0.6-0.2,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.4,0.6C353.4,289.2,353.1,289,352.6,288.6z"
          />
          <path
            fill="none"
            d="M352,287.2c0,0.6,0.2,1,0.6,1.4c0.4,0.4,0.8,0.6,1.4,0.6c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.8,0.6-1.4
	c0-0.5-0.2-0.9-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6c-0.5,0-0.9,0.2-1.4,0.6C352.3,286.3,352,286.6,352,287.2L352,287.2z"
          />
          <path
            fill="none"
            d="M351.9,291.5v16.9c0,0.5,0.2,0.9,0.6,1.3s0.8,0.6,1.3,0.6h0.1c0.5,0,0.9-0.2,1.3-0.6s0.6-0.8,0.6-1.3v-16.6
	c0.4-0.4,0.6-0.9,0.7-1.8c0.1-0.7,0.2-1.5,0.2-2.6c-0.1,0.6-0.4,1.2-0.8,1.6c-0.6,0.6-1.2,0.8-2,0.8s-1.4-0.2-2-0.8
	c-0.5-0.4-0.7-0.8-0.8-1.4c-0.1,0.8,0,1.6,0.1,2.3C351.6,290.6,351.7,291,351.9,291.5L351.9,291.5z M355,291.6
	c0.2,0.2,0.5,0.5,0.5,0.7v15.9c0,0.4-0.1,0.7-0.5,1c-0.2,0.2-0.6,0.5-1,0.5c-0.4,0-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1v-15.9
	c0-0.2,0.1-0.5,0.5-0.7c0.2-0.2,0.7-0.2,1-0.2C354.4,291.3,354.6,291.4,355,291.6L355,291.6z"
          />
          <path
            fill="none"
            d="M355.4,292.3c0-0.2-0.1-0.5-0.5-0.7c-0.2-0.2-0.6-0.2-1-0.2c-0.4,0-0.7,0.1-1,0.2c-0.2,0.2-0.5,0.5-0.5,0.7
	v15.9c0,0.4,0.1,0.7,0.5,1c0.2,0.2,0.7,0.5,1,0.5c0.4,0,0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1L355.4,292.3L355.4,292.3z M348.8,293.9
	l-0.5,0.4c-1.5,1.4-2.2,3.1-2.2,5.1c0,2,0.7,3.6,2.2,5l0.5,0.4c0.8,0.7,1.6,1.2,2.7,1.4c0.1-0.4,0.2-0.6,0.5-0.9v-12.9
	C350.8,292.6,349.7,293.2,348.8,293.9z"
          />
          <path
            fill="none"
            d="M351.9,306.2v-1c-0.2,0.2-0.4,0.6-0.5,0.9L351.9,306.2L351.9,306.2z"
          />
          <path
            fill="none"
            d="M351.9,308.4v-2.1l-0.5-0.1c-0.1,0.2-0.1,0.5-0.1,0.8v2c0,0.7,0.2,1.3,0.7,1.8c0.5,0.5,1,0.7,1.8,0.7
	s1.3-0.2,1.8-0.7c0.5-0.5,0.7-1,0.7-1.8v-2l-0.1-0.8l-0.5,0.1v2.2c0,0.5-0.2,0.9-0.6,1.3s-0.8,0.6-1.3,0.6h-0.1
	c-0.5,0-0.9-0.2-1.3-0.6C352.2,309.4,351.9,308.9,351.9,308.4L351.9,308.4z M361.5,299.3c0-2-0.7-3.6-2.2-5c-1-0.9-2.1-1.5-3.4-1.9
	v12.9c0.2,0.2,0.4,0.5,0.5,0.8c1-0.4,2.1-0.9,3-1.7C360.8,302.9,361.5,301.3,361.5,299.3L361.5,299.3z"
          />
          <path
            fill="none"
            d="M355.9,305.3v0.9l0.5-0.1C356.1,305.9,356,305.5,355.9,305.3L355.9,305.3z M355.8,318.5c0.5-0.5,0.7-1,0.7-1.6
	c0-0.7-0.2-1.2-0.7-1.6c-0.5-0.5-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7c-0.5,0.5-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6
	s0.9,0.7,1.6,0.7C354.7,319,355.3,318.8,355.8,318.5L355.8,318.5z M354.1,316c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.2,0.6
	c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.5,0.2-0.6
	C353.7,316,353.9,316,354.1,316L354.1,316z"
          />
          <path
            fill="none"
            d="M354.6,316.2c-0.1-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
	c0.1,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6C354.8,316.6,354.8,316.3,354.6,316.2L354.6,316.2z
	 M357.3,333.4l-0.8-1l-0.6-0.4l-0.5-0.1l0.6-0.1c0.2-0.1,0.5-0.2,0.6-0.4s0.2-0.4,0.2-0.5c0-0.2-0.1-0.5-0.2-0.6
	c-0.2-0.1-0.5-0.2-0.7-0.4l-4.2-0.1v3.6h1.9v-1.4h0.1l0.5,0.1l0.4,0.2l0.9,1h1.9L357.3,333.4L357.3,333.4z M355,331l-0.4,0.1
	l-0.5,0.1h-0.7v-0.7h0.8l0.7,0.1c0.1,0.1,0.2,0.1,0.2,0.2L355,331L355,331z"
          />
          <path
            fill="none"
            d="M354.6,331.2l0.4-0.1l0.1-0.2c0-0.1-0.1-0.2-0.2-0.2l-0.7-0.1h-0.8v0.7L354.6,331.2L354.6,331.2z M357.9,329.8
	v3.6h2.7l1-0.1c0.2,0,0.6-0.1,0.8-0.2l0.6-0.6c0.1-0.2,0.2-0.5,0.2-0.9c0-0.2,0-0.5-0.1-0.7s-0.2-0.4-0.5-0.6
	c-0.2-0.1-0.5-0.2-0.8-0.4l-1.3-0.1L357.9,329.8L357.9,329.8z M359.7,332.6v-2h0.5c0.5,0,0.8,0.1,0.9,0.2c0.2,0.1,0.4,0.4,0.4,0.8
	c0,0.2,0,0.5-0.1,0.6l-0.4,0.2l-0.8,0.1L359.7,332.6L359.7,332.6z"
          />
          <path
            fill="none"
            d="M359.7,330.6v2h0.5l0.8-0.1l0.4-0.2c0.1-0.1,0.1-0.4,0.1-0.6c0-0.4-0.1-0.7-0.4-0.8c-0.2-0.1-0.5-0.2-0.9-0.2
	L359.7,330.6L359.7,330.6z M345.5,332.7c0.2,0.2,0.7,0.5,1,0.6l1.5,0.1h0.7l0.6-0.1c0.4-0.1,0.6-0.2,0.8-0.5s0.4-0.4,0.5-0.7
	l-1.5-0.4l-0.4,0.5c-0.2,0.1-0.5,0.1-0.8,0.1s-0.7-0.1-0.8-0.2c-0.2-0.1-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.7c0.2-0.2,0.5-0.4,0.9-0.4
	l0.5,0.1l0.2,0.1l0.1,0.1l0.1,0.2l1.6-0.2c-0.2-0.4-0.5-0.7-0.9-0.8l-0.9-0.2H348c-0.9,0-1.6,0.1-2.2,0.5c-0.5,0.4-0.8,0.8-0.8,1.4
	C345.1,332,345.2,332.4,345.5,332.7L345.5,332.7z M479.7,204.7v0.9l13.2,0.8c4.9,0.5,7.5,3.1,7.7,7.8l0.6,6.5
	c1.2,13.8,2.1,28.9,2.7,45.6c-0.1,2-1.2,3-3.4,3h-7.7l1.7,1h7.8c1.9-0.2,2.8-1.5,2.8-4c-0.6-16.7-1.5-31.8-2.7-45.6l-0.7-7.3
	c-0.1-4.7-2.7-7.3-7.7-7.8L479.7,204.7L479.7,204.7z M405,186.6h1c0.2,0.7,0.9,1.2,2.2,1.3c-1-0.1-1.9-0.5-2.1-1.3H405z
	 M418.4,230.9l-7.9-0.1l0.6,5.1l7.8-0.6l7.8,0.6l0.5-5.1"
          />
          <path
            fill="none"
            d="M492.8,269.1l0.7,1l0.8,1.3l1.4,2.3H495v6.5h0.6v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h8.9c0.6,0,0.8,0.2,0.8,0.8
	v12.7c0,0.6-0.2,0.8-0.8,0.8h-8.9c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5v-1.5h-0.5l0.1,28.4h0.4v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h9
	c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-8.9c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5v-1.5h-0.4v5.5l0.4-0.1h19.2
	c0.6,0,0.8,0.2,0.8,0.8l0.2,0.4H528l0.5-27.6v-28.1c0.1-2.8-0.1-4.4-0.6-4.9h-31.6h1l-2.5-3.6L492.8,269.1L492.8,269.1z
	 M516.8,278.1h6.5c1.9,0,2.8,0.9,2.8,2.8v22.8c0,1.9-0.9,2.8-2.8,2.8h-14.2c-1.9,0-2.8-0.9-2.8-2.8v-22.8c0-1.9,0.9-2.8,2.8-2.8
	L516.8,278.1L516.8,278.1z"
          />
          <path
            fill="none"
            d="M523.3,278.1h-14.2c-1.9,0-2.8,0.9-2.8,2.8v22.8c0,1.9,0.9,2.8,2.8,2.8h14.2c1.9,0,2.8-0.9,2.8-2.8v-22.8
	C526.1,279,525.2,278.1,523.3,278.1L523.3,278.1z M520.5,279.5h2.8c0.9,0,1.4,0.5,1.4,1.4v22.8c0,0.9-0.5,1.4-1.4,1.4h-14.2
	c-0.9,0-1.4-0.5-1.4-1.4v-22.8c0-0.9,0.5-1.4,1.4-1.4L520.5,279.5L520.5,279.5z"
          />
          <path
            fill="none"
            d="M523.3,279.5h-2.8l0.8,0.7c1.4,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2.1,1.5h5.5v-11.4
	C524.7,280,524.2,279.5,523.3,279.5L523.3,279.5z"
          />
          <path
            fill="none"
            d="M520.5,279.5h-1.4c0.6,0.4,1.2,0.7,1.6,1.2c1.3,1.3,2,2.9,2,4.8c0,1.9-0.7,3.5-2,4.8c-1,1-2.3,1.6-3.7,1.9
	l-1,0.1c-1.9,0-3.5-0.7-4.8-2c-1.3-1.3-2-2.9-2-4.8c0-1.9,0.7-3.5,2-4.8c0.5-0.5,1.1-0.9,1.6-1.2h-1.4c-0.2,0.2-0.5,0.5-0.8,0.7
	c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.8,2.2,5.2c0.6,0.7,1.3,1.2,2,1.5h6.5c0.7-0.4,1.4-0.8,2.1-1.5c1.4-1.4,2.2-3.3,2.2-5.2
	c0-2.1-0.7-3.8-2.2-5.4L520.5,279.5L520.5,279.5z"
          />
          <path
            fill="none"
            d="M520.7,280.8c-0.5-0.5-1-0.9-1.6-1.2h-6.3c-0.6,0.4-1,0.7-1.6,1.2c-1.3,1.3-2,2.9-2,4.8s0.7,3.5,2,4.8
	c1.3,1.3,2.9,2,4.8,2l1-0.1c1.4-0.2,2.7-0.8,3.7-1.9c1.3-1.3,2-2.9,2-4.8S522.1,282.1,520.7,280.8L520.7,280.8z M514.8,286.7
	c-0.3-0.4-0.5-0.7-0.5-1.2s0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1,0.5l0.1,0.1c0.3,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2
	L517,287c-0.3,0.4-0.7,0.5-1,0.5C515.5,287.3,515.1,287.1,514.8,286.7L514.8,286.7z"
          />
          <path
            fill="none"
            d="M514.3,285.6c0,0.5,0.1,0.8,0.5,1.2c0.3,0.4,0.7,0.5,1.2,0.5c0.5,0,0.8-0.1,1-0.5l0.1-0.1
	c0.3-0.4,0.5-0.7,0.5-1.2c0-0.5-0.1-0.8-0.5-1.2l-0.1-0.1c-0.3-0.2-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1.2,0.5
	C514.4,284.8,514.3,285.1,514.3,285.6L514.3,285.6z M511.4,279.5h-2.3c-0.9,0-1.4,0.5-1.4,1.4v11.4h5c-0.7-0.4-1.4-0.8-2-1.5
	c-1.5-1.4-2.2-3.3-2.2-5.2c0-2.1,0.7-3.8,2.2-5.4C510.9,280,511.2,279.7,511.4,279.5L511.4,279.5z"
          />
          <path
            fill="none"
            d="M512.7,292.3h-5v11.4c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4L512.7,292.3L512.7,292.3z
	 M517.1,292.7l0.8,0.1c1,0.2,1.9,0.8,2.7,1.6c1.2,1.3,1.9,2.7,1.9,4.4c0,1.7-0.6,3.1-1.9,4.4c-1,1-2.2,1.6-3.5,1.7l-0.9,0.1
	c-1.7,0-3.1-0.6-4.4-1.9c-1.3-1.2-1.9-2.7-1.9-4.4c0-1.8,0.6-3.1,1.9-4.4c0.7-0.7,1.5-1.3,2.4-1.5c0.6-0.2,1.3-0.2,1.9-0.2
	L517.1,292.7L517.1,292.7z"
          />
          <path
            fill="none"
            d="M517.9,292.8l-0.8-0.1l-0.9-0.1c-0.7,0-1.3,0.1-1.9,0.2c-0.9,0.2-1.7,0.8-2.4,1.5c-1.3,1.3-1.9,2.7-1.9,4.4
	c0,1.7,0.6,3.1,1.9,4.4c1.2,1.3,2.7,1.9,4.4,1.9l0.9-0.1c1.4-0.2,2.6-0.8,3.5-1.7c1.2-1.2,1.9-2.7,1.9-4.4c0-1.8-0.6-3.1-1.9-4.4
	C519.8,293.6,518.9,293.2,517.9,292.8L517.9,292.8z M516.2,293.3l0.9,0.1c1.2,0.1,2.2,0.7,3,1.5c1,1,1.6,2.4,1.6,4s-0.6,2.9-1.6,4
	c-0.8,0.8-1.9,1.4-3,1.6l-0.9,0.1c-1.5,0-2.9-0.6-4-1.6c-1-1-1.6-2.3-1.6-4c0-1.5,0.6-2.9,1.6-4
	C513.3,293.7,514.7,293.3,516.2,293.3L516.2,293.3z"
          />
          <path
            fill="none"
            d="M517.1,293.3l-0.9-0.1c-1.5,0-2.9,0.6-4,1.6c-1,1-1.6,2.4-1.6,4s0.6,2.9,1.6,4c1,1,2.4,1.6,4,1.6l0.9-0.1
	c1.2-0.2,2.2-0.7,3-1.6c1-1.1,1.6-2.3,1.6-4c0-1.5-0.6-2.9-1.6-4C519.2,294,518.3,293.5,517.1,293.3L517.1,293.3z M517.1,297.8
	c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.7-0.5,1L517,300c-0.2,0.2-0.6,0.4-0.9,0.4s-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1
	c0-0.4,0.1-0.7,0.5-0.9c0.2-0.2,0.6-0.5,1-0.5S516.8,297.6,517.1,297.8L517.1,297.8z"
          />
          <path
            fill="none"
            d="M517.1,297.8c-0.3-0.4-0.6-0.5-0.9-0.5s-0.7,0.1-1,0.5c-0.2,0.2-0.5,0.6-0.5,0.9s0.1,0.7,0.5,1
	c0.2,0.2,0.6,0.5,1,0.5c0.3,0,0.7-0.1,0.9-0.4l0.1-0.1c0.2-0.2,0.5-0.6,0.5-1C517.6,298.4,517.5,298.2,517.1,297.8L517.1,297.8z
	 M494.3,271.5l-0.8-1.3h-24l0.5,1.3 M472.4,282.8l0.4,4.7h17.4c0.6,0,0.8-0.2,0.8-0.8v-3c0-0.6-0.2-0.8-0.8-0.8H472.4L472.4,282.8z"
          />
          <path
            fill="none"
            d="M472.7,287.4v1.5H495v-7.7h-22.7l0.2,1.6h17.6c0.6,0,0.8,0.2,0.8,0.8v3c0,0.6-0.2,0.8-0.8,0.8h-17.4
	L472.7,287.4L472.7,287.4z M472.7,289v0.9h22.4V289 M472.1,280.3l0.1,0.9H495v-0.9"
          />
          <path
            fill="none"
            d="M470.6,273.8c0.6,2.1,1,4.3,1.4,6.5h23v-6.5H470.6z M502.3,280.3c0.6,0,0.8,0.2,0.8,0.8v7.9
	c0,0.6-0.2,0.8-0.8,0.8h-5.7v1.5v-1.5h-1v1.5l0.1,0.5c0.1,0.2,0.4,0.4,0.7,0.4h1c-0.4,0-0.6-0.1-0.7-0.4c0.1,0.2,0.4,0.4,0.7,0.4
	h7.8c0.6,0,0.8-0.2,0.8-0.8v-12.7c0-0.6-0.2-0.8-0.8-0.8h-7.8l-0.4,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6L502.3,280.3L502.3,280.3z"
          />
          <path
            fill="none"
            d="M503.1,281.1c0-0.6-0.2-0.8-0.8-0.8H495v0.9h6.4c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.3v0.9h7.1
	c0.6,0,0.8-0.2,0.8-0.8v-8L503.1,281.1L503.1,281.1z"
          />
          <path
            fill="none"
            d="M502.2,282c0-0.5-0.2-0.7-0.8-0.7H495v7.7h6.3c0.6,0,0.8-0.2,0.8-0.7V282L502.2,282L502.2,282z M496.4,277.9
	l-0.5,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6h1v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.4-0.1L496.4,277.9L496.4,277.9z M495,273.8h0.7l-1.4-2.3
	h-24.4l0.7,2.3 M495.1,289.9h-22.3c-0.1,10.6-2.9,20-8.4,28.4h30.8L495.1,289.9L495.1,289.9z M528.8,180.8c-0.1-0.1-0.2-0.1-0.3-0.1
	s-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.4v0.9c0,0.1,0,0.2,0.1,0.4h0.1v78.8l0.5,3.5l0.1,0.1v-82.3c0.1-0.1,0.1-0.2,0.1-0.4v-0.9
	C529,181,528.9,180.9,528.8,180.8L528.8,180.8z M564.9,247.3c0-3.3-0.1-5.8-0.5-7.6c-0.5-1.5-1.5-2.2-3.4-2.2l-2.3-0.1
	c-6.8-0.5-14.1-0.5-22-0.2c-2.5,0.2-3.5,1.8-3.5,4.4v16.1c-0.1,2.4,1.5,3.8,4.8,4.1h21c2.6-0.2,3.8-1.8,3.8-4.4v-9.9
	c0-3.3-0.1-5.8-0.5-7.6c-0.5-1.5-1.5-2.2-3.4-2.2l-2-0.1l2,0.1c1.9,0,2.9,0.7,3.4,2.2c0.3,1.9,0.5,4.4,0.5,7.6v9.9
	c0.1,2.7-1.2,4.2-3.8,4.4h1c3.3,0.1,4.9-1.4,4.8-4.4L564.9,247.3L564.9,247.3z M560,241.2c0.3,1.6,0.5,3.7,0.5,6.5v8.4
	c0.1,2.3-1,3.6-3.3,3.8h-17.6c-2.8-0.2-4.1-1.4-4-3.5v-13.9c0-2.3,0.9-3.5,2.9-3.7c6.1-0.2,11.8-0.1,17.1,0.1l1.4,0.1l-1.4-0.1
	l1.4,0.2h0.2C558.9,239.2,559.7,239.8,560,241.2L560,241.2z"
          />
          <path
            fill="none"
            d="M560.5,247.6c0-2.8-0.1-4.9-0.5-6.5c-0.3-1.3-1.3-2-2.8-2H557l-1.4-0.2c-5.4-0.2-11-0.4-17.1-0.1
	c-2,0.2-2.9,1.4-2.9,3.7v13.8c-0.1,2.1,1.3,3.3,4,3.5h17.6c2.2-0.2,3.3-1.5,3.3-3.8L560.5,247.6L560.5,247.6z M529.2,265.8v7.7h3.8
	v1.3l1-0.2v-7.3h-4.4 M539.5,261.7v4.8h6.3v-4.8 M544,266.5h-2.9v0.7h2.9"
          />
          <path
            fill="none"
            d="M546.1,267.2h-12v7.3l12-1.7 M533.1,274.7v-1.2h-3.8v0.7c0.5,0.6,0.8,1.5,1,2.7c0.2,0.9,0.3,2,0.3,3.3h2.4
	L533.1,274.7L533.1,274.7z"
          />
          <path
            fill="none"
            d="M530.3,276.8c-0.2-1.2-0.6-2-1-2.7l-0.5-0.5h-1.3c0.6,0.5,0.8,2.1,0.6,4.9v28.1l-0.5,27.6v1.2h0.7l0.1-2.7
	l0.5-9.3c0.1-11.5,0.3-20.4,0.9-26.3c0.3-3.5,0.7-6.1,1.2-7.7l0.1-2v-2.1c0-0.7-0.2-1-0.5-1.2v-4.3
	C530.6,278.9,530.5,277.8,530.3,276.8L530.3,276.8z M531.2,287.7l-0.1,2l0.1-0.2"
          />
          <path
            fill="none"
            d="M531.2,289.4l-0.1,0.2c-0.5,1.6-0.9,4.1-1.2,7.7c-0.5,5.9-0.8,14.8-0.9,26.3l-0.5,9.3l24-0.7v-31.5l-2.8-5.7
	c-0.9-2-2.6-3.1-4.8-3.4L531.2,289.4L531.2,289.4z M552.6,321.6v0.1"
          />
          <path
            fill="none"
            d="M555,324.2l-2.4-2.6v0.1v-0.1v10.6l-24,0.7l-0.1,2.7h18.2c3.7-0.2,5.6,0.9,5.8,3.5l-2.2,13.6l-0.5,1.8
	c-0.3,0.9-0.8,1.7-1.3,2.6l-2.5,4.2c-0.8,0.9-3.1,1.3-6.8,1.3H519c-0.8,0-1.4,0.1-2,0.4c-0.6,0.2-1,0.6-1.3,1c0,0.2-0.1,0.5-0.2,0.6
	l-0.6,0.1h-0.8v56.9c0.3,3.3,2.2,5.5,5.6,6.6c4.1,0.7,8.9,1,14.5,0.9h1.9c5.5,0.1,10.4-0.2,14.3-0.9c3.4-1.2,5.2-3.4,5.6-6.6v-93.8
	C555.8,325.8,555.6,324.6,555,324.2L555,324.2z"
          />
          <path
            fill="none"
            d="M528.4,335.6h-9.2c-0.8,0-1.6-0.1-2.2-0.4c-0.5-0.2-0.8-0.5-1-0.8h-0.2v29.5c0.2-0.5,0.7-0.8,1.3-1
	c0.6-0.2,1.2-0.4,2-0.4h20.3c3.7,0,5.9-0.5,6.8-1.3l2.5-4.2c0.5-0.8,0.9-1.6,1.3-2.6l0.5-1.8l2.2-13.6c-0.1-2.6-2.1-3.7-5.8-3.5
	L528.4,335.6L528.4,335.6z M540,339c0.6,0,0.8,0.2,0.8,0.8v12.5c0,0.6-0.2,0.8-0.8,0.8h-21.1c-0.6,0-0.8-0.2-0.8-0.8v-12.5
	c0-0.6,0.2-0.8,0.8-0.8L540,339L540,339z"
          />
          <path
            fill="none"
            d="M540.9,339.8c0-0.6-0.2-0.8-0.8-0.8H519c-0.6,0-0.8,0.2-0.8,0.8v12.5c0,0.6,0.2,0.8,0.8,0.8h21.1
	c0.6,0,0.8-0.2,0.8-0.8V339.8z M538.6,340.2c0.6,0,0.8,0.2,0.8,0.8v10c0,0.6-0.2,0.8-0.8,0.8h-10.8l-1.2,0.1l-1.2-0.1h-5.1
	c-0.6,0-0.8-0.2-0.8-0.8v-10c0-0.6,0.2-0.8,0.8-0.8L538.6,340.2L538.6,340.2z"
          />
          <path
            fill="none"
            d="M539.4,341.1c0-0.6-0.2-0.8-0.8-0.8h-9.7c0.8,0.4,1.5,0.8,2.2,1.4c1.3,1.3,1.9,2.8,1.9,4.5s-0.6,3.3-1.9,4.5
	c-0.5,0.5-0.9,0.8-1.5,1.2h9c0.6,0,0.8-0.2,0.8-0.8L539.4,341.1L539.4,341.1z M534.5,344c-0.3-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1
	c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5
	C535.1,344.4,534.7,344.3,534.5,344L534.5,344z M534,349.5c0-0.5,0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5
	c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5C534.1,350.2,534,349.8,534,349.5L534,349.5z"
          />
          <path
            fill="none"
            d="M534,342.9c0,0.5,0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.3-0.4,0.5-0.7,0.5-1
	c0-0.5-0.1-0.8-0.5-1c-0.3-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5C534.1,342.1,534,342.5,534,342.9L534,342.9z M534.5,348.3
	c-0.3,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.3-0.4,0.5-0.7,0.5-1s-0.1-0.8-0.5-1
	c-0.3-0.4-0.7-0.5-1-0.5C535.1,347.8,534.7,348.1,534.5,348.3L534.5,348.3z M528.9,340.2h-4.7c-0.8,0.4-1.5,0.8-2.2,1.4
	c-1.3,1.3-1.9,2.8-1.9,4.5s0.6,3.3,1.9,4.5c0.5,0.5,0.9,0.8,1.5,1.2h1.9c-1-0.2-2.1-0.7-2.9-1.6c-1.2-1.2-1.6-2.4-1.6-4.1
	s0.6-2.9,1.6-4.1c1.2-1.2,2.4-1.6,4.1-1.6c1.6,0,2.9,0.6,4.1,1.6c1.2,1.2,1.6,2.4,1.6,4.1s-0.6,2.9-1.6,4.1
	c-0.8,0.8-1.9,1.4-2.9,1.6h1.9c0.6-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5s-0.6-3.3-1.9-4.5
	C530.4,341.1,529.7,340.6,528.9,340.2z"
          />
          <path
            fill="none"
            d="M530.6,342.2c-1.2-1.2-2.5-1.6-4.1-1.6c-1.6,0-2.9,0.6-4.1,1.6c-1.2,1.2-1.6,2.4-1.6,4.1c0,1.6,0.6,2.9,1.6,4.1
	c0.8,0.8,1.9,1.4,2.9,1.6h2.3c1-0.2,2.1-0.7,2.9-1.6c1.2-1.2,1.6-2.5,1.6-4.1C532.3,344.7,531.8,343.3,530.6,342.2L530.6,342.2z
	 M526.6,344.7c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5s-0.8-0.1-1-0.5
	c-0.3-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1C525.7,344.8,526.1,344.7,526.6,344.7L526.6,344.7z"
          />
          <path
            fill="none"
            d="M527.7,345.1c-0.3-0.4-0.7-0.5-1-0.5s-0.8,0.1-1,0.5c-0.3,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1
	c0.3,0.4,0.7,0.5,1,0.5s0.8-0.1,1-0.5c0.3-0.4,0.5-0.7,0.5-1S528,345.5,527.7,345.1L527.7,345.1z M526.6,352l1.2-0.1h-2.3
	 M520.3,340.2c-0.6,0-0.8,0.2-0.8,0.8v10c0,0.6,0.2,0.8,0.8,0.8h3.3c-0.5-0.2-1-0.7-1.5-1.2c-1.3-1.3-1.9-2.8-1.9-4.5
	s0.6-3.3,1.9-4.5c0.7-0.7,1.4-1.2,2.2-1.4L520.3,340.2L520.3,340.2z M527.7,335.6v-1.2H516c0.2,0.4,0.7,0.6,1,0.8
	c0.6,0.2,1.3,0.4,2.2,0.4L527.7,335.6L527.7,335.6z M515.7,334.4v-0.4c0-0.6-0.2-0.8-0.8-0.8h-19.2l-0.4,0.1
	c-0.4,0.1-0.5,0.4-0.5,0.8v29.7c0,0.5,0.1,0.7,0.5,0.8h19.6l0.6-0.1c0.1-0.1,0.2-0.4,0.2-0.6L515.7,334.4L515.7,334.4z M515.7,334.1
	v0.4h0.2 M502.2,320c0-0.5-0.2-0.7-0.8-0.7h-6.2v7.7h6.2c0.6,0,0.8-0.2,0.8-0.7L502.2,320L502.2,320z"
          />
          <path
            fill="none"
            d="M501.4,319.3c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.2v0.9h7c0.6,0,0.8-0.2,0.8-0.8v-7.9
	c0-0.6-0.2-0.8-0.8-0.8h-7v0.9h6.2L501.4,319.3L501.4,319.3z"
          />
          <path
            fill="none"
            d="M506,316.7c0-0.6-0.2-0.8-0.8-0.8h-7.9l-0.4,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6h5.6c0.6,0,0.8,0.2,0.8,0.8v7.9
	c0,0.6-0.2,0.8-0.8,0.8h-5.5l0.1,2c0.1,0.2,0.4,0.4,0.7,0.4h7.8c0.6,0,0.8-0.2,0.8-0.8L506,316.7L506,316.7L506,316.7z"
          />
          <path
            fill="none"
            d="M497.1,315.9l0.4-0.1h-1l-0.5,0.1c-0.2,0.1-0.5,0.4-0.5,0.7v1.6h1v-1.6C496.6,316.2,496.7,316,497.1,315.9z
	 M495.2,319.3v-0.9h-30.8l-0.6,0.9 M495.2,327.9V327h-37.8l-0.9,0.9 M496.6,327.9h-1v1.5l0.1,0.5c0.1,0.2,0.4,0.4,0.7,0.4h1
	c-0.4,0-0.6-0.1-0.7-0.4L496.6,327.9L496.6,327.9z"
          />
          <path
            fill="none"
            d="M494.7,334.1c0-0.5,0.1-0.7,0.5-0.8v-5.5h-38.7c-2.8,2.8-5.8,5.2-9.1,7.2h47
	C494.6,334.8,494.7,334.5,494.7,334.1L494.7,334.1z M495.2,327v-7.7h-31.4l-1.2,1.6h27.4c0.6,0,0.8,0.2,0.8,0.8v3
	c0,0.6-0.2,0.8-0.8,0.8h-31.2l-1.4,1.5L495.2,327L495.2,327z"
          />
          <path
            fill="none"
            d="M490.9,324.6v-3c0-0.6-0.2-0.8-0.8-0.8h-27.4l-0.6,0.7c-1,1.4-2.1,2.7-3.3,4h31.2
	C490.7,325.4,490.9,325.2,490.9,324.6L490.9,324.6z M494.4,335.1c-0.5,0.7-1.2,1-2.2,1.3c-8.4,0.4-19.9,0.5-34.9,0.4
	c-0.8,0.1-1.3,0.5-1.5,1v0.7l0.1,22.1c0.1,0.7,0.4,1.2,0.9,1.3h35.4c1.4,0.1,2.2,0.6,2.5,1.4v-29.3
	C494.7,334.5,494.6,334.8,494.4,335.1L494.4,335.1z"
          />
          <path
            fill="none"
            d="M492.2,336.4c1-0.1,1.8-0.6,2.2-1.3h-47c-8.4,5.4-18,8-28.6,8s-20.2-2.7-28.6-8h-47c0.5,0.7,1.2,1,2.2,1.3
	l3.4,0.1c8,0.2,18.4,0.4,31.5,0.2c0.8,0.1,1.3,0.5,1.5,1h8c9.2,4.1,18.6,6.2,28.2,6.4l3.4-0.1c9.1-0.5,18-2.6,26.6-6.4h8
	c0.1-0.6,0.7-0.9,1.5-1C472.2,336.9,483.9,336.7,492.2,336.4L492.2,336.4z M475.5,361.9c1.4,0.5,2.4,1.4,3,2.7h16.8
	c-0.4-0.1-0.5-0.4-0.5-0.8v-0.5c-0.2-0.8-1-1.3-2.4-1.4H475.5z"
          />
          <path
            fill="none"
            d="M478.5,364.6c-0.6-1.4-1.6-2.2-3-2.7H457v8.6l12.2-0.7c0.4-1.6,0.4-2.8,0.1-3.5c0.2,0.7,0.2,1.9-0.1,3.5
	l-0.1,0.7l-0.7,2.6c0.2-0.7,0.6-1.4,1.2-2c0.2-0.2,0.5-0.5,0.7-0.6c0.8-0.6,1.7-0.8,2.8-0.8s2,0.2,2.8,0.8l0.7,0.6
	c0.9,0.9,1.4,2,1.4,3.3h0.2l0.9-3.8l0.1-1.2c0.1-0.7,0.1-1.4,0.1-2.1C479.1,366.2,478.9,365.4,478.5,364.6L478.5,364.6z"
          />
          <path
            fill="none"
            d="M479.2,367.3c0,0.7,0,1.4-0.1,2.1l-0.1,1.2h35.1v-3.3L479.2,367.3L479.2,367.3z M477.8,375.3l0.2-0.9h-0.1"
          />
          <path
            fill="none"
            d="M478.1,374.4l-0.2,0.8c-0.1,0.5-0.1,0.8-0.4,1.3l-0.1,0.4c-0.2,0.5-0.6,0.8-0.9,1.3l-1,0.8h38.7v-8.4H479
	L478.1,374.4L478.1,374.4z"
          />
          <path
            fill="none"
            d="M477.8,375.3v-0.8c0-1.3-0.5-2.3-1.4-3.3l-0.7-0.6c-0.8-0.6-1.7-0.8-2.8-0.8c-1,0-2,0.2-2.8,0.8
	c-0.2,0.2-0.5,0.4-0.7,0.6c-0.6,0.6-0.9,1.2-1.2,2v0.1c-0.1,0.4-0.2,0.8-0.2,1.3v0.1c0,0.8,0.2,1.5,0.6,2.2c0.2,0.5,0.5,0.8,0.9,1.3
	l1,0.8c0.7,0.4,1.5,0.6,2.3,0.6c0.9,0,1.6-0.2,2.4-0.6l1-0.8c0.4-0.4,0.7-0.8,0.9-1.3l0.1-0.4C477.7,376.2,477.8,375.7,477.8,375.3
	L477.8,375.3z M476,371.7c0.8,0.8,1.2,1.7,1.2,2.8v0.1c0,0.8-0.2,1.6-0.7,2.2l-0.6,0.7c-0.8,0.8-1.8,1.2-2.9,1.2
	c-1.2,0-2.1-0.4-2.9-1.2l-0.5-0.7c-0.5-0.7-0.7-1.4-0.7-2.2v-0.1c0-1,0.5-2,1.2-2.8c0.6-0.6,1.4-1,2.2-1.2h1.5
	C474.6,370.7,475.3,371,476,371.7L476,371.7z"
          />
          <path
            fill="none"
            d="M477.1,374.4c0-1-0.5-2-1.2-2.8c-0.6-0.6-1.4-1-2.2-1.2h-1.5c-0.8,0.1-1.5,0.5-2.2,1.2
	c-0.8,0.8-1.2,1.8-1.2,2.8v0.1c0,0.8,0.2,1.6,0.7,2.2l0.5,0.7c0.8,0.8,1.7,1.2,2.9,1.2s2.1-0.4,2.9-1.2l0.6-0.7
	c0.5-0.7,0.7-1.4,0.7-2.2L477.1,374.4L477.1,374.4z M478.5,364.6c0.4,0.7,0.6,1.6,0.7,2.6h34.9v-2.6L478.5,364.6L478.5,364.6z"
          />
          <path
            fill="none"
            d="M457,370.6v2.8c0,0.5-0.2,0.9-0.7,1l-0.7,0.1h-70.9c-0.2,0-0.2-0.1-0.4-0.1h-31.5c-0.2,0-0.5-0.1-0.5-0.5v-9.3
	h-28.9v2.6h28.8h-28.8v3.3h28.8h-28.8v8.4h147.1l-1-0.8c-0.4-0.4-0.7-0.8-0.9-1.3c-0.4-0.7-0.6-1.4-0.6-2.2v-0.1
	c0-0.5,0.1-0.9,0.2-1.3V373l0.7-2.6h-12L457,370.6L457,370.6z"
          />
          <path
            fill="none"
            d="M457,373.4v-11.4c-0.6-0.1-0.8-0.6-0.9-1.3l-0.1-22.1v-0.7h-8c-8.6,3.8-17.5,5.9-26.6,6.4l-3.4,0.1
	c-9.7-0.2-19.1-2.4-28.2-6.4h-8l-0.1,23c-0.1,0.9-0.6,1.4-1.5,1.3h-27.6v12c0,0.2,0.1,0.5,0.5,0.5h31.5l-0.1-0.2v-17.1
	c0-0.2,0.1-0.5,0.5-0.5h11l-0.4-0.4c-0.4-0.4-0.6-0.9-0.6-1.4v-0.1h-4.5c-0.2,0-0.5-0.1-0.5-0.5v-9.1c0-0.2,0.1-0.5,0.5-0.5h57.2
	c0.2,0,0.5,0.1,0.5,0.5v9.1c0,0.2-0.1,0.5-0.5,0.5h-4.5v0.1c0,0.6-0.2,1-0.6,1.4l-0.4,0.4h13.5c0.2,0,0.5,0.1,0.5,0.5v17.1l-0.1,0.2
	l-0.2,0.1l0.7-0.1C456.7,374.3,457,373.8,457,373.4L457,373.4z"
          />
          <path
            fill="none"
            d="M469,370.6l0.2-0.8l-12.2,0.8 M437.4,350.3v3h7.1v-3c0-0.2-0.1-0.5-0.5-0.5h-6.2
	C437.5,349.8,437.4,349.9,437.4,350.3z"
          />
          <path
            fill="none"
            d="M437.4,353.3v-3c0-0.2,0.1-0.5,0.5-0.5h6.2c0.2,0,0.5,0.1,0.5,0.5v4.4h3c0.2,0,0.5-0.1,0.5-0.5v-9.1
	c0-0.2-0.1-0.5-0.5-0.5h-57.2c-0.2,0-0.5,0.1-0.5,0.5v9.1c0,0.2,0.1,0.5,0.5,0.5h3v-4.4c0-0.2,0.1-0.5,0.5-0.5h6.2
	c0.2,0,0.5,0.1,0.5,0.5v4.4h37.1L437.4,353.3L437.4,353.3z M445.4,349.5c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5,0.1,0.6,0.2
	c0.1,0.1,0.2,0.4,0.2,0.6c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2s-0.2-0.4-0.2-0.6
	C445.2,349.8,445.3,349.6,445.4,349.5L445.4,349.5z M391,349.5c0.1-0.1,0.4-0.2,0.6-0.2s0.5,0.1,0.6,0.2s0.2,0.4,0.2,0.6
	c0,0.2-0.1,0.5-0.2,0.6s-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.4-0.2-0.6C390.8,349.8,390.9,349.6,391,349.5
	L391,349.5z"
          />
          <path
            fill="none"
            d="M446,349.2c-0.2,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.6c0,0.2,0.1,0.5,0.2,0.6s0.4,0.2,0.6,0.2
	c0.2,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6C446.5,349.3,446.2,349.2,446,349.2L446,349.2z M391.6,349.2
	c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.6
	c0-0.2-0.1-0.5-0.2-0.6S391.8,349.2,391.6,349.2L391.6,349.2z M437.4,353.3v1.4h7v-1.4"
          />
          <path
            fill="none"
            d="M438.8,354.7h-39.9v0.1c0,0.6-0.2,1-0.6,1.4l-0.4,0.4h41.9l-0.4-0.4c-0.5-0.4-0.6-0.9-0.6-1.4v-0.1H438.8
	L438.8,354.7z"
          />
          <path
            fill="none"
            d="M438.8,354.7v0.1c0,0.6,0.2,1,0.6,1.4l0.4,0.4c0.4,0.2,0.7,0.4,1.2,0.4s0.8-0.1,1.2-0.4l0.4-0.4
	c0.4-0.4,0.6-0.9,0.6-1.4v-0.1H438.8L438.8,354.7z M393.2,353.3v1.4h7.1v-1.4"
          />
          <path
            fill="none"
            d="M394.7,354.7v0.1c0,0.6,0.2,1,0.6,1.4l0.4,0.4c0.4,0.2,0.7,0.4,1.2,0.4c0.5,0,0.8-0.1,1.2-0.4l0.4-0.4
	c0.5-0.4,0.6-0.9,0.6-1.4v-0.1H394.7L394.7,354.7z M393.7,349.8c-0.2,0-0.5,0.1-0.5,0.5v3h7.1v-3c0-0.2-0.1-0.5-0.5-0.5H393.7z"
          />
          <path
            fill="none"
            d="M397.9,356.7c-0.4,0.2-0.7,0.4-1.2,0.4s-0.8-0.1-1.2-0.4h-10.8c-0.2,0-0.5,0.1-0.5,0.5v17.1l0.1,0.2
	c0.1,0.1,0.1,0.1,0.4,0.1h70.9l0.2-0.1l0.1-0.2v-17.1c0-0.2-0.1-0.5-0.5-0.5h-13.5c-0.4,0.2-0.7,0.4-1.2,0.4c-0.5,0-0.8-0.1-1.2-0.4
	H397.9L397.9,356.7z M381.8,337.7c-0.1-0.6-0.7-0.9-1.5-1c-12.9,0.1-23.4,0-31.5-0.2l-3.4-0.1c-1-0.1-1.8-0.6-2.2-1.3
	c-0.2-0.2-0.4-0.6-0.4-1v29.3c0.2-0.8,1-1.3,2.4-1.4H380c0.9,0.1,1.4-0.4,1.5-1.3L381.8,337.7L381.8,337.7z"
          />
          <path
            fill="none"
            d="M342.8,334.1c0-0.5-0.1-0.7-0.5-0.8l-0.4-0.1h-19.2c-0.6,0-0.8,0.2-0.8,0.8v29.8c0,0.2,0.1,0.5,0.2,0.6l0.2,0.1
	h19.9c0.4-0.1,0.5-0.4,0.5-0.8L342.8,334.1L342.8,334.1z"
          />
          <path
            fill="none"
            d="M352.5,364.6v-2.7h-7.2c-1.4,0.1-2.2,0.6-2.5,1.4v0.5c0,0.5-0.1,0.7-0.5,0.8H352.5L352.5,364.6z M323.5,370.6
	v-5.8H322h0.4l-0.2-0.1c-0.1-0.1-0.2-0.4-0.2-0.6c-0.6-0.9-1.6-1.4-3.4-1.4h-20.3c-3.7,0-5.9-0.5-6.8-1.3l-2.5-4.2
	c-0.5-0.8-0.9-1.6-1.3-2.6c-0.2-0.6-0.4-1.2-0.5-1.7l-2.2-13.6c0.1-2.6,2.1-3.7,5.8-3.5H309l-0.1-2.7l-24-0.7v-10.6v0.1v-0.1
	l-2.4,2.6c-0.5,0.4-0.8,1.6-1,3.6v93.8c0.4,3.3,2.2,5.5,5.6,6.6c4.1,0.7,8.9,1,14.3,0.9h1.9c5.5,0.1,10.4-0.2,14.5-0.9
	c3.4-1.2,5.2-3.4,5.6-6.6v-42.7L323.5,370.6L323.5,370.6z M321.9,334.1l-0.2,0.4h0.2"
          />
          <path
            fill="none"
            d="M321.9,334.4h-0.2c-0.7,0.8-1.8,1.2-3.3,1.2H291c-3.7-0.2-5.6,0.9-5.8,3.5l2.2,13.6c0.1,0.6,0.2,1.2,0.5,1.8
	c0.4,0.9,0.8,1.7,1.3,2.6l2.4,4.2c0.8,0.9,3.1,1.3,6.8,1.3h20.3c1.6,0,2.8,0.5,3.4,1.4v-29.5L321.9,334.4L321.9,334.4z M297.6,353.1
	c-0.6,0-0.8-0.2-0.8-0.8v-12.5c0-0.6,0.2-0.8,0.8-0.8h21.1c0.6,0,0.8,0.2,0.8,0.8v12.5c0,0.6-0.2,0.8-0.8,0.8L297.6,353.1
	L297.6,353.1z"
          />
          <path
            fill="none"
            d="M296.8,352.3c0,0.6,0.2,0.8,0.8,0.8h21.1c0.6,0,0.8-0.2,0.8-0.8v-12.5c0-0.6-0.2-0.8-0.8-0.8h-21.1
	c-0.6,0-0.8,0.2-0.8,0.8L296.8,352.3L296.8,352.3z M298.3,341.1c0-0.6,0.2-0.8,0.8-0.8h18.3c0.6,0,0.8,0.2,0.8,0.8v10
	c0,0.6-0.2,0.8-0.8,0.8h-5.1l-1.2,0.1l-1.2-0.1h-10.8c-0.6,0-0.8-0.2-0.8-0.8L298.3,341.1L298.3,341.1z"
          />
          <path
            fill="none"
            d="M299.1,340.2c-0.6,0-0.8,0.2-0.8,0.8v10c0,0.6,0.2,0.8,0.8,0.8h9c-0.6-0.2-1-0.7-1.5-1.2
	c-1.3-1.3-1.9-2.8-1.9-4.5s0.6-3.3,1.9-4.5c0.7-0.7,1.4-1.2,2.2-1.4L299.1,340.2L299.1,340.2z M300.5,342.9c0-0.5,0.1-0.8,0.5-1
	c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.4,0.4,0.5,0.7,0.5,1c0,0.5-0.1,0.8-0.5,1c-0.4,0.4-0.7,0.5-1,0.5
	c-0.5,0-0.8-0.1-1-0.5C300.6,343.7,300.5,343.4,300.5,342.9L300.5,342.9z M303.2,348.3c0.4,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1
	c-0.4,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5c-0.4-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1c0.4-0.4,0.7-0.5,1-0.5
	C302.5,347.8,302.9,348.1,303.2,348.3L303.2,348.3z"
          />
          <path
            fill="none"
            d="M301,341.9c-0.4,0.4-0.5,0.7-0.5,1c0,0.5,0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5
	c0.4-0.4,0.5-0.7,0.5-1c0-0.5-0.1-0.8-0.5-1c-0.4-0.4-0.7-0.5-1-0.5S301.3,341.5,301,341.9z M303.7,349.5c0-0.5-0.1-0.8-0.5-1
	c-0.4-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5
	S303.7,349.8,303.7,349.5z M318.2,341.1c0-0.6-0.2-0.8-0.8-0.8h-4c0.8,0.4,1.5,0.8,2.2,1.4c1.3,1.3,1.9,2.8,1.9,4.5
	s-0.6,3.3-1.9,4.5c-0.5,0.5-0.9,0.8-1.5,1.2h3.3c0.6,0,0.8-0.2,0.8-0.8V341.1L318.2,341.1z"
          />
          <path
            fill="none"
            d="M307.1,342.2c1.2-1.2,2.4-1.6,4.1-1.6s2.9,0.6,4.1,1.6c1.2,1.2,1.6,2.4,1.6,4.1c0,1.6-0.6,2.9-1.6,4.1
	c-0.8,0.8-1.9,1.4-2.9,1.6h1.9c0.5-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5c0-1.8-0.6-3.3-1.9-4.5c-0.7-0.7-1.4-1.2-2.2-1.4h-4.7
	c-0.8,0.4-1.5,0.8-2.2,1.4c-1.3,1.3-1.9,2.8-1.9,4.5c0,1.7,0.6,3.3,1.9,4.5c0.5,0.5,0.9,0.8,1.5,1.2h1.9c-1-0.2-2.1-0.7-2.9-1.6
	c-1.2-1.2-1.6-2.5-1.6-4.1C305.3,344.7,305.9,343.3,307.1,342.2L307.1,342.2z"
          />
          <path
            fill="none"
            d="M311,340.5c-1.6,0-2.9,0.6-4.1,1.6c-1.2,1.2-1.6,2.4-1.6,4.1s0.6,2.9,1.6,4.1c0.8,0.8,1.9,1.4,2.9,1.6h2.3
	c1-0.2,2.1-0.7,2.9-1.6c1.2-1.2,1.6-2.4,1.6-4.1s-0.6-2.9-1.6-4.1C314,341.1,312.6,340.5,311,340.5L311,340.5z M310,345.1
	c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.4,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.4,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5
	c-0.4-0.4-0.5-0.7-0.5-1S309.6,345.5,310,345.1L310,345.1z"
          />
          <path
            fill="none"
            d="M311,344.7c-0.5,0-0.8,0.1-1,0.5c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5
	c0.5,0,0.8-0.1,1-0.5c0.4-0.4,0.5-0.7,0.5-1s-0.1-0.8-0.5-1C311.8,344.8,311.5,344.7,311,344.7L311,344.7z M311,352l1.2-0.1H310
	 M321.6,334.4h-11.8v1.2h8.5C319.9,335.6,321,335.2,321.6,334.4L321.6,334.4z M285,321.6v0.1V321.6 M337,214.1l-0.6,6.5
	c-1.2,13.8-2.1,28.9-2.7,45.6c0.1,2,1.2,3,3.4,3h7.7l-0.7,1h24l0.4-0.9c1.3-3.1,2.8-6.1,4.7-9c0.7-1,1.5-2.2,2.3-3.3
	c1.2-1.5,2.5-3.1,3.8-4.5l1.3-1.3c8.6-8.6,18.8-13.8,30.3-15.3l-0.5-5.1l-4.5-0.1c-0.5,0-0.8-0.1-0.9-0.5c-0.2-0.2-0.2-0.6-0.1-1.1
	l1.4-5c0.1-0.7,0.6-1.1,1.4-1.1h22.1c0.7,0,1.2,0.4,1.4,1.1l1.4,5c0.1,0.5,0.1,0.8-0.1,1.1c-0.1,0.2-0.5,0.5-0.9,0.5l-4.5,0.1
	l-0.5,5.1c11.5,1.5,21.7,6.6,30.3,15.3l1.3,1.3c1.4,1.5,2.7,3,3.8,4.5l0.2,0.2c2.7,3.5,4.8,7.2,6.4,11.2l0.7,1.6h24l-0.7-1h7.7
	c2.2,0,3.4-1,3.4-3c-0.6-16.7-1.5-31.8-2.7-45.6l-0.6-6.5c-0.1-4.7-2.7-7.3-7.7-7.8l-13.2-0.8v-6.2c0-0.6-0.4-0.8-1-0.8h-7.5
	c-0.6,0-0.9,0.2-1,0.7v6.2c-5.7-0.1-11.8-0.1-18.1-0.1h-66.6c-6.3-0.1-12.4,0-18.1,0.1v-6.2c-0.1-0.5-0.5-0.7-1-0.7h-7.5
	c-0.7,0-1,0.2-1,0.8v6.4l-13.2,0.8C339.7,206.8,337.2,209.5,337,214.1L337,214.1z M365.8,199c0.6,0,0.8,0.2,0.8,0.8v7.3l-1.5,6.5
	c-0.1,0.6-0.6,0.9-1.4,1l-1.9-0.1c-0.7,0-1.2-0.2-1.3-0.7l-1.4-6.9v-7.3c0-0.6,0.2-0.8,0.8-0.8L365.8,199L365.8,199z M477.7,199
	c0.6,0,0.8,0.2,0.8,0.8v7.3l-1.4,6.9c-0.1,0.5-0.6,0.7-1.3,0.7l-1.9,0.1c-0.8-0.1-1.4-0.5-1.4-1.1l-1.5-6.5v-7.3
	c0-0.6,0.2-0.8,0.8-0.8h5.8V199L477.7,199z"
          />
          <path
            fill="none"
            d="M366.6,199.8c0-0.6-0.2-0.8-0.8-0.8h-5.9c-0.6,0-0.8,0.2-0.8,0.8v7.3l1.4,6.9c0.1,0.5,0.6,0.7,1.3,0.7l1.9,0.1
	c0.9-0.1,1.4-0.5,1.4-1.1l1.5-6.5v-7.5L366.6,199.8L366.6,199.8z M478.5,199.8c0-0.6-0.2-0.8-0.8-0.8h-5.9c-0.6,0-0.8,0.2-0.8,0.8
	v7.3l1.5,6.5c0.1,0.6,0.6,0.9,1.4,1l1.9-0.1c0.7,0,1.2-0.2,1.3-0.7l1.4-6.9v-7.2L478.5,199.8L478.5,199.8z M405,229.3
	c-0.1,0.5-0.1,0.8,0.1,1.1c0.1,0.2,0.5,0.5,0.9,0.5l4.5,0.1l7.9,0.1l8.7-0.1l4.5-0.1c0.5,0,0.8-0.1,0.9-0.5c0.2-0.2,0.2-0.6,0.1-1.1
	l-1.4-5c-0.1-0.7-0.6-1.1-1.4-1.1h-22.1c-0.7,0-1.2,0.4-1.4,1.1L405,229.3L405,229.3z M405.7,229.9v-0.4l1.4-5
	c0.1-0.2,0.2-0.5,0.6-0.4h22.1c0.4,0,0.5,0.1,0.6,0.4l1.4,5v0.4h-0.2l-13.3,0.1l-12.5-0.1H405.7L405.7,229.9z"
          />
          <path
            fill="none"
            d="M405.7,229.5v0.4h0.2l12.5,0.1l13.3-0.1h0.2v-0.4l-1.4-5c-0.1-0.2-0.2-0.5-0.6-0.4h-22.1
	c-0.4,0-0.5,0.1-0.6,0.4L405.7,229.5L405.7,229.5z M411,226.8c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5s0.8,0.1,1.2,0.5
	s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5s-0.8-0.1-1.2-0.5C411.3,227.6,411,227.2,411,226.8L411,226.8z M420.8,225.2
	c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.1-1.2-0.5s-0.5-0.7-0.5-1.2
	s0.1-0.8,0.5-1.2C419.9,225.3,420.4,225.2,420.8,225.2L420.8,225.2z M415.2,226.8c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5
	s0.8,0.1,1.2,0.5c0.4,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5s-0.8-0.1-1.2-0.5C415.5,227.6,415.2,227.2,415.2,226.8
	z M423.3,226.8c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5
	c-0.5,0-0.8-0.1-1.2-0.5C423.5,227.6,423.3,227.2,423.3,226.8L423.3,226.8z"
          />
          <path
            fill="none"
            d="M411.6,225.7c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2c-0.4-0.4-0.7-0.5-1.2-0.5S411.9,225.3,411.6,225.7z M421.9,225.7
	c-0.4-0.4-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5s-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5c0.5,0,0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2S422.2,225.9,421.9,225.7L421.9,225.7z M415.7,225.7c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2
	s0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5S416,225.3,415.7,225.7z M423.7,225.7
	c-0.4,0.4-0.5,0.7-0.5,1.2s0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5c0.5,0,0.8-0.1,1.2-0.5s0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2
	s-0.7-0.5-1.2-0.5C424.4,225.2,424.1,225.3,423.7,225.7L423.7,225.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M354.6,331.2l-0.5,0.1h-0.7v-0.7h0.8l0.7,0.1c0.1,0.1,0.2,0.1,0.2,0.2
	l-0.1,0.2L354.6,331.2L354.6,331.2z M346.6,333.3l1.5,0.1h0.7l0.6-0.1c0.4-0.1,0.6-0.2,0.8-0.5s0.4-0.4,0.5-0.7l-1.5-0.2l-0.4,0.5
	c-0.2,0.1-0.5,0.1-0.8,0.1s-0.7-0.1-0.8-0.2c-0.2-0.1-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.7c0.2-0.2,0.5-0.4,0.9-0.4l0.5,0.1l0.2,0.1
	l0.1,0.1l0.1,0.2l1.6-0.2c-0.2-0.4-0.5-0.7-0.9-0.8l-0.9-0.2H348c-0.9,0-1.6,0.1-2.2,0.5c-0.5,0.4-0.8,0.8-0.8,1.4
	c0,0.5,0.1,0.8,0.5,1C345.8,332.9,346.1,333.1,346.6,333.3L346.6,333.3z M357.9,333.4h2.7l1-0.1c0.2,0,0.6-0.1,0.8-0.2l0.6-0.6
	c0.1-0.2,0.2-0.5,0.2-0.9c0-0.2,0-0.5-0.1-0.7s-0.2-0.4-0.5-0.6c-0.2-0.1-0.5-0.2-0.8-0.4l-1.3-0.1h-2.7L357.9,333.4L357.9,333.4z
	 M356.5,332.3l-0.6-0.4l-0.5-0.1l0.6-0.1c0.2-0.1,0.5-0.2,0.6-0.4s0.2-0.4,0.2-0.5c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.1-0.5-0.2-0.7-0.4
	l-4.2-0.1v3.6h1.9v-1.4h0.1l0.5,0.1l0.4,0.2l0.9,1h2L356.5,332.3L356.5,332.3z M359.7,330.6h0.5c0.5,0,0.8,0.1,0.9,0.2
	c0.2,0.1,0.4,0.4,0.4,0.8c0,0.2,0,0.5-0.1,0.6l-0.4,0.2l-0.8,0.1h-0.5V330.6L359.7,330.6z M420.4,289.8h-0.5l-0.1,0.1h-0.1
	c-0.1,0-0.2-0.1-0.2-0.1v-0.1h1v-0.2l-0.1-0.2c-0.1-0.2-0.2-0.2-0.5-0.4h-0.4l-0.4,0.1l-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.5v0.2
	c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.4-0.1l0.4-0.2L420.4,289.8L420.4,289.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M419.9,289.3h-0.5v-0.1c0-0.1,0.1-0.1,0.1-0.1h0.1L419.9,289.3z
	 M421.4,289.2l0.1,0.1v0.5l-0.1,0.1l-0.2,0.1h-0.1l-0.1-0.1v-0.2l0.1-0.2C421.2,289.2,421.3,289.2,421.4,289.2L421.4,289.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M422.1,289.1l-0.4-0.2h-0.4l-0.2,0.1h-0.1v-0.1h-0.6v1.7h0.6V290l0.2,0.1
	h0.4l0.4-0.1l0.1-0.2v-0.5V289.1z M418,289.1l0.2,0.1v0.1h-0.5v-0.1c0-0.1,0.1-0.1,0.1-0.1H418z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M418.1,288.8h-0.2l-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2v0.1l-0.1,0.2
	l0.1,0.4c0.1,0.2,0.2,0.4,0.5,0.4h0.2l0.5-0.1l0.2-0.1l0.1-0.1h-0.5l-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1-0.1v-0.2h1v-0.2l-0.1-0.2
	c-0.1-0.2-0.2-0.2-0.5-0.4L418.1,288.8L418.1,288.8z M416.5,288.3v1.3l-0.1,0.2h-0.2l-0.2-0.1v-0.4h-0.5v0.5l0.4,0.4l0.6,0.1h0.2
	c0.2-0.1,0.4-0.1,0.5-0.2l0.1-0.2v-1.2h-0.7L416.5,288.3L416.5,288.3z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M314.2,351.9
	c0.5-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5s-0.6-3.3-1.9-4.5c-0.7-0.7-1.4-1.2-2.2-1.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M307.1,342.2
	c-1.2,1.2-1.6,2.4-1.6,4.1c0,1.6,0.6,2.9,1.6,4.1c0.8,0.8,1.9,1.4,2.9,1.6l1.2,0.1l1.2-0.1c1-0.2,2.1-0.7,2.9-1.6
	c1.2-1.2,1.6-2.5,1.6-4.1c0-1.6-0.6-2.9-1.6-4.1c-1.2-1.2-2.4-1.6-4.1-1.6C309.5,340.5,308.1,341.1,307.1,342.2L307.1,342.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M310,345.1
	c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.4-0.4,0.5-0.7,0.5-1s-0.1-0.8-0.5-1
	c-0.4-0.4-0.7-0.5-1-0.5S310.3,344.8,310,345.1L310,345.1z M303.2,348.3c-0.4-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5
	c-0.4,0.4-0.5,0.7-0.5,1s0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.4-0.4,0.5-0.7,0.5-1S303.4,348.6,303.2,348.3
	L303.2,348.3z M300.5,342.9c0,0.5,0.1,0.8,0.5,1c0.4,0.4,0.7,0.5,1,0.5c0.5,0,0.8-0.1,1-0.5c0.4-0.4,0.5-0.7,0.5-1
	c0-0.5-0.1-0.8-0.5-1c-0.4-0.4-0.7-0.5-1-0.5c-0.5,0-0.8,0.1-1,0.5C300.6,342.1,300.5,342.5,300.5,342.9z M328.9,317.9
	c0.2,1.9,0.2,3.7,0,5.7c0,0.5-0.2,0.7-0.7,0.7h-9.7c-0.5,0-0.7-0.2-0.7-0.7c-0.2-2-0.2-3.8,0-5.7c0-0.5,0.2-0.7,0.7-0.7h9.7
	C328.6,317,328.9,317.3,328.9,317.9L328.9,317.9z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M329.4,323.8
	c0,0.6-0.2,0.8-0.8,0.8h-10.7c-0.6,0-0.8-0.2-0.8-0.8c-0.2-2.2-0.2-4.3,0-6.3c0-0.6,0.2-0.8,0.8-0.8h10.7c0.6,0,0.8,0.2,0.8,0.8
	C329.8,319.6,329.8,321.7,329.4,323.8L329.4,323.8z M523.5,351.9c-0.5-0.2-1-0.7-1.5-1.2c-1.3-1.3-1.9-2.8-1.9-4.5s0.6-3.3,1.9-4.5
	c0.7-0.7,1.4-1.2,2.2-1.4 M534.5,348.3c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1
	c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1-0.5c-0.3-0.4-0.5-0.7-0.5-1S534.1,348.6,534.5,348.3L534.5,348.3z M527.7,345.1
	c0.3,0.4,0.5,0.7,0.5,1s-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5s-0.8-0.1-1-0.5c-0.3-0.4-0.5-0.7-0.5-1s0.1-0.8,0.5-1
	c0.3-0.4,0.7-0.5,1-0.5S527.4,344.8,527.7,345.1L527.7,345.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M530.6,342.2
	c1.2,1.2,1.6,2.4,1.6,4.1c0,1.6-0.6,2.9-1.6,4.1c-0.8,0.8-1.9,1.4-2.9,1.6l-1.2,0.1l-1.2-0.1c-1-0.2-2.1-0.7-2.9-1.6
	c-1.2-1.2-1.6-2.5-1.6-4.1c0-1.6,0.6-2.9,1.6-4.1c1.2-1.2,2.4-1.6,4.1-1.6C528.2,340.5,529.5,341.1,530.6,342.2L530.6,342.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M529.6,351.9
	c0.6-0.2,1-0.7,1.5-1.2c1.3-1.3,1.9-2.8,1.9-4.5s-0.6-3.3-1.9-4.5c-0.7-0.7-1.4-1.2-2.2-1.4 M534,342.9c0-0.5,0.1-0.8,0.5-1
	c0.4-0.4,0.7-0.5,1-0.5c0.5,0,0.8,0.1,1,0.5c0.3,0.4,0.5,0.7,0.5,1c0,0.5-0.1,0.8-0.5,1c-0.3,0.4-0.7,0.5-1,0.5
	c-0.5,0-0.8-0.1-1-0.5C534.1,343.7,534,343.4,534,342.9L534,342.9z M308.8,340.2c-0.8,0.4-1.5,0.8-2.2,1.4c-1.3,1.3-1.9,2.8-1.9,4.5
	s0.6,3.3,1.9,4.5c0.5,0.5,0.9,0.8,1.5,1.2 M517.1,297.8c0.2,0.2,0.5,0.6,0.5,0.9s-0.1,0.7-0.5,1l-0.1,0.1c-0.2,0.2-0.6,0.4-0.9,0.4
	s-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1c0-0.4,0.1-0.7,0.5-0.9c0.2-0.2,0.6-0.5,1-0.5C516.5,297.5,516.8,297.6,517.1,297.8
	L517.1,297.8z M515.1,278.1h1.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M517.1,293.3
	c1.2,0.1,2.2,0.7,3,1.5c1,1,1.6,2.4,1.6,4s-0.6,2.9-1.6,4c-0.8,0.8-1.9,1.4-3,1.6l-0.9,0.1c-1.5,0-2.9-0.6-4-1.6c-1-1-1.6-2.3-1.6-4
	c0-1.5,0.6-2.9,1.6-4c1-1,2.4-1.6,4-1.6L517.1,293.3L517.1,293.3z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M517.9,292.8
	c1,0.2,1.9,0.8,2.7,1.6c1.2,1.3,1.9,2.7,1.9,4.4c0,1.7-0.6,3.1-1.9,4.4c-1,1-2.2,1.6-3.5,1.7l-0.9,0.1c-1.7,0-3.1-0.6-4.4-1.9
	c-1.3-1.2-1.9-2.7-1.9-4.4c0-1.8,0.6-3.1,1.9-4.4c0.7-0.7,1.5-1.3,2.4-1.5c0.6-0.2,1.3-0.2,1.9-0.2l0.9,0.1L517.9,292.8L517.9,292.8
	z M514.3,285.6c0-0.5,0.1-0.8,0.5-1.2s0.7-0.5,1.2-0.5c0.5,0,0.8,0.1,1,0.5l0.1,0.1c0.3,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2
	L517,287c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-0.8-0.1-1.2-0.5C514.4,286.4,514.3,286,514.3,285.6L514.3,285.6z M512.7,292.3
	c-0.7-0.4-1.4-0.8-2-1.5c-1.5-1.4-2.2-3.3-2.2-5.2c0-2.1,0.7-3.8,2.2-5.4c0.2-0.2,0.5-0.5,0.8-0.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M519.1,279.5
	c0.6,0.4,1.2,0.7,1.6,1.2c1.3,1.3,2,2.9,2,4.8c0,1.9-0.7,3.5-2,4.8c-1,1-2.3,1.6-3.7,1.9l-1,0.1c-1.9,0-3.5-0.7-4.8-2
	c-1.3-1.3-2-2.9-2-4.8c0-1.9,0.7-3.5,2-4.8c0.5-0.5,1.1-0.9,1.6-1.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M520.5,279.5l0.8,0.7
	c1.4,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2.1,1.5 M423.7,225.7c0.4-0.4,0.7-0.5,1.2-0.5
	c0.5,0,0.8,0.1,1.2,0.5s0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2s-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.1-1.2-0.5s-0.5-0.7-0.5-1.2
	S423.5,225.9,423.7,225.7L423.7,225.7z M415.2,226.8c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5
	c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5S415.2,226.4,415.2,226.8z M421.9,225.7
	c0.4,0.4,0.5,0.7,0.5,1.2s-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.5-1.2,0.5s-0.8-0.1-1.2-0.5s-0.5-0.7-0.5-1.2s0.1-0.8,0.5-1.2
	s0.7-0.5,1.2-0.5C421.2,225.2,421.6,225.3,421.9,225.7L421.9,225.7z M411,226.8c0,0.5,0.1,0.8,0.5,1.2s0.7,0.5,1.2,0.5
	s0.8-0.1,1.2-0.5s0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2s-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5C411.3,225.9,411,226.4,411,226.8z
	 M320.8,278.1h1.7 M326.2,279.5l0.8,0.7c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.8-2.2,5.2c-0.6,0.7-1.3,1.2-2,1.5 M321.5,300.3
	c0.4,0,0.7-0.1,1-0.5c0.2-0.2,0.5-0.6,0.5-1c0-0.4-0.1-0.7-0.5-0.9c-0.2-0.2-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-0.9,0.5
	c-0.2,0.2-0.5,0.6-0.5,0.9s0.1,0.7,0.5,1C320.8,300.1,321,300.3,321.5,300.3L321.5,300.3z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M317.5,302.8
	c1,1,2.4,1.6,4,1.6c1.5,0,2.9-0.6,4-1.6c1-1,1.6-2.3,1.6-4c0-1.5-0.6-2.9-1.6-4c-1-1-2.4-1.6-4-1.6c-1.5,0-2.9,0.6-4,1.6
	c-1,1-1.6,2.5-1.6,4C315.9,300.4,316.4,301.7,317.5,302.8L317.5,302.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M319.8,292.8
	c-1,0.2-1.9,0.8-2.7,1.6c-1.2,1.3-1.9,2.7-1.9,4.4c0,1.7,0.6,3.1,1.9,4.4c1.3,1.3,2.7,1.9,4.4,1.9s3.1-0.6,4.4-1.9
	c1.3-1.2,1.9-2.7,1.9-4.4c0-1.8-0.6-3.1-1.9-4.4c-0.7-0.7-1.5-1.3-2.4-1.5c-0.6-0.2-1.2-0.2-1.9-0.2L319.8,292.8L319.8,292.8z
	 M320,285.6c0,0.5,0.1,0.8,0.5,1.2c0.4,0.4,0.7,0.5,1.2,0.5s0.8-0.1,1.2-0.5c0.4-0.4,0.5-0.7,0.5-1.2s-0.1-0.8-0.5-1.2
	s-0.7-0.5-1.2-0.5s-0.8,0.1-1.2,0.5S320,285.1,320,285.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M318.5,279.5
	c-0.6,0.4-1.2,0.7-1.6,1.2c-1.3,1.3-2,2.9-2,4.8c0,1.9,0.7,3.5,2,4.8s2.9,2,4.8,2c1.9,0,3.5-0.7,4.8-2s2-2.9,2-4.8s-0.7-3.5-2-4.8
	c-0.5-0.5-1-0.9-1.6-1.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M318.4,292.3
	c-0.7-0.4-1.4-0.8-2.1-1.5c-1.4-1.4-2.2-3.3-2.2-5.2c0-2.1,0.7-3.8,2.2-5.4l0.8-0.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M308.5,265.8c0.1-0.4,0.4-0.7,0.6-0.9l9.6-67l0.2-1c0.8-3.6,2.2-5.5,4-5.5
	l5.7-0.8l9.7-0.6c0.8-0.2,1.3-0.7,1.5-1.4l6.1-0.4c0.2,0.6,0.8,1.1,1.6,1.3l21.7-0.8c0.8-0.2,1.4-0.7,1.8-1.4l5.2-0.1
	c0.4,0.7,1,1.2,2,1.3c6.8-0.2,13.4-0.4,20.1-0.4c1.2-0.2,1.9-0.7,2.2-1.3h4.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M518.2,201.8l-0.6-4.9c-0.1-0.6-0.2-1.2-0.5-1.6c-0.5-0.8-1.2-1.4-2.1-2
	c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4h-24.1c-19.9,0.4-39.3,0.9-57.9,1.6l-18.9,0.8c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.4,2.1-2.6,3.6
	l-0.6,4.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M518.2,201.8c-0.2-0.8-0.7-1.5-1.2-2.2c-0.6-0.7-1.3-1.3-2.1-1.9
	c-2-1.1-4.3-1.6-7.3-1.9l-76.8-2.4h-24.1c-19.9,0.4-39.3,0.9-57.9,1.6l-18.9,0.8c-2.9,0.1-5.4,0.8-7.3,1.9c-1.6,0.9-2.7,2.2-3.1,4
	 M357.9,204.7v0.9l-13.2,0.8c-4.9,0.5-7.5,3.1-7.7,7.8l-0.6,6.5c-1.2,13.8-2.1,28.9-2.7,45.6c0.1,2,1.2,3,3.4,3h7.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M343.1,270.1h-7.8c-1.9-0.2-2.8-1.5-2.8-4c0.6-16.7,1.5-31.8,2.7-45.6
	l0.7-7.3c0.1-4.7,2.7-7.3,7.7-7.8l14.3-0.7 M366.6,199.8v7.3l-1.5,6.5c-0.1,0.6-0.6,0.9-1.4,1l-1.9-0.1c-0.7,0-1.2-0.2-1.3-0.7
	l-1.4-6.9v-7.3c0-0.6,0.2-0.8,0.8-0.8h5.9C366.4,199,366.6,199.2,366.6,199.8L366.6,199.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M367.4,204.3V199c-0.1-0.5-0.5-0.7-1-0.7h-7.5c-0.7,0-1,0.2-1,0.8v5.6
	 M367.4,204.3v0.8c5.7-0.1,11.8-0.1,18.1-0.1h66.6c6.3-0.1,12.4,0,18.1,0.1v-0.8 M470.1,204.3c-5.4-0.1-11-0.1-16.9-0.1h-68.9
	c-5.9-0.1-11.5,0-16.9,0.1 M282,238.9l-1.4,0.2h-0.2c-1.5,0-2.4,0.6-2.8,2c-0.4,1.6-0.5,3.7-0.5,6.5v8.4c-0.1,2.3,1,3.6,3.3,3.8
	h17.7c2.8-0.2,4.1-1.4,4-3.5v-13.9c0-2.3-0.9-3.5-2.9-3.7C293.1,238.6,287.4,238.7,282,238.9z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M298.1,261.7h1.4c3.3-0.2,4.9-1.6,4.8-4.1v-16.2c0.1-2.7-1-4.2-3.5-4.4
	c-7.9-0.2-15.2-0.2-22,0.2l-2.3,0.1c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9c-0.1,3,1.5,4.5,4.8,4.4h1 M291.9,261.7
	v4.8h1.6 M293.5,267.2h2.9 M296.4,266.5h-2.9 M296.4,267.2v-0.7 M293.5,267.2h-2.1v5.6l12.1,1.7 M293.5,266.5v0.7 M296.4,266.5h1.6
	v-4.8 M303.6,267.2h-7.1 M298.1,261.7h-6.2 M308.5,265.8c-0.2,0.5-0.4,0.9-0.5,1.4h-4.4 M280.6,239.1l1.4-0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M280.5,237.3l-2,0.1c-1.9,0-2.9,0.7-3.4,2.2c-0.4,1.9-0.5,4.4-0.5,7.6v9.9
	c-0.1,2.7,1.2,4.2,3.8,4.4 M306.5,289.4l-13.9,2.2c-2.2,0.2-3.7,1.3-4.8,3.4l-2.8,5.7v20.9 M320.8,278.1h-6.5
	c-1.9,0-2.8,0.9-2.8,2.8v22.8c0,1.9,0.9,2.8,2.8,2.8h14.2c1.9,0,2.8-0.9,2.8-2.8v-22.8c0-1.9-0.9-2.8-2.8-2.8h-6.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M313,292.3v-11.4c0-0.9,0.5-1.4,1.4-1.4h14.2c0.9,0,1.4,0.5,1.4,1.4v11.4
	 M307.6,277.4c-0.1-0.4-0.2-0.6-0.2-0.6 M308.3,274.3c-0.5,0.6-0.8,1.5-1,2.7 M308.3,274.3v-0.8 M310.1,273.8h-1.3l-0.5,0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M307.9,296.8c0.1,0.4,0.2,0.6,0.4,0.6c0.2,0,0.5-1,0.6-3s0.2-4.4,0.2-7.2
	c-0.1-2.8-0.2-5.2-0.5-7.2c-0.2-2-0.5-3-0.7-3c-0.1,0-0.2,0.2-0.4,0.6 M310.1,273.8c-0.6,0.5-0.8,2.1-0.6,4.9v28.1l0.5,27.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M307.6,277.4c-0.1,0.5-0.2,1.3-0.4,2.3c-0.1,2-0.2,4.4-0.1,7.2
	c0,2.8,0.1,5.2,0.4,7.2c0.1,1,0.2,1.9,0.5,2.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M307.9,296.8c0.1-0.5,0.2-1.3,0.2-2.4c0.1-2,0.2-4.4,0.2-7.2
	c-0.1-2.8-0.2-5.2-0.5-7.2l-0.4-2.3 M307.3,276.8c-0.2,0.9-0.4,2-0.4,3.3 M306.9,280.2v4.3c-0.4,0.1-0.5,0.6-0.5,1.2v2.1
	 M306.5,287.7v1.8 M306.5,289.7v-0.2 M306.5,287.7v2 M329.9,292.3H313"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M304.5,274.7v5.5h2.5 M303.6,274.5l0.9,0.2 M308.3,273.5h-3.8v1.3
	 M307.6,297.4l0.2-0.6 M313,292.3v11.4c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4 M307.6,297.4
	c-0.4-3.5-0.7-6.1-1.2-7.7 M308.3,273.5l0.1-7.7 M303.6,267.2v7.3 M278.5,261.7h13.4 M411,235.9c-11.5,1.5-21.7,6.6-30.3,15.3
	l-1.3,1.3c-1.4,1.5-2.7,3-3.8,4.5c-0.8,1-1.5,2.1-2.3,3.3c-1.9,2.8-3.4,5.8-4.7,9l-0.4,0.9 M368.3,270.2l-0.5,1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M373.3,265.5c-0.8,1.5-1.5,3-2.2,4.7l-1.3,3.6c-1.5,4.9-2.2,10-2.2,15.5
	c0,8.7,1.9,16.7,5.7,23.8c1.3,2.5,2.8,4.7,4.4,6.9c1.5,2,3.1,3.7,4.9,5.6c4,4,8.3,7.2,13.1,9.6c7,3.6,14.8,5.4,23.2,5.4
	c8.5,0,16.2-1.7,23.2-5.4c4.7-2.5,9-5.6,12.9-9.6c1.5-1.5,2.9-3,4.2-4.7l3-4.2c5.1-8,7.7-17.1,7.7-27.3v-0.4
	c0-5.4-0.8-10.5-2.2-15.2l-1.3-3.6c-1.2-2.8-2.6-5.6-4.2-8.3c-0.7-1.2-1.5-2.2-2.3-3.4c-1.2-1.5-2.3-2.9-3.7-4.3l-2.2-2.2
	c-9.8-9.3-21.6-14-35.2-14c-13.6,0-25.4,4.7-35.2,14l-2.2,2.2c-1.3,1.4-2.6,2.9-3.7,4.3C375.9,260.9,374.5,263.1,373.3,265.5
	L373.3,265.5z M344.8,269.1l-1.8,0.9 M343.4,271.5l0.8-1.3 M344.2,270.2l0.6-1 M342.7,273.8H342l1.4-2.3 M341.7,273.8h-1
	 M352.6,288.6c0.4,0.4,0.8,0.6,1.4,0.6c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.8,0.6-1.4c0-0.5-0.2-0.9-0.6-1.4
	c-0.4-0.4-0.8-0.6-1.4-0.6c-0.5,0-0.9,0.2-1.4,0.6c-0.4,0.4-0.6,0.8-0.6,1.4C352,287.8,352.3,288.3,352.6,288.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M356.8,287.6v-0.4c0-0.8-0.2-1.4-0.8-2s-1.2-0.8-2-0.8s-1.4,0.2-2,0.8
	c-0.6,0.6-0.8,1.2-0.8,2l0.1,0.6 M351.4,287.8c-0.1,0.9,0,1.6,0.1,2.3c0.1,0.5,0.2,0.9,0.6,1.4v0.8 M355.4,292.3v15.9
	c0,0.4-0.1,0.7-0.5,1c-0.2,0.2-0.6,0.5-1,0.5c-0.4,0-0.7-0.1-1-0.5c-0.2-0.2-0.5-0.6-0.5-1v-15.9c0-0.2,0.1-0.5,0.5-0.7
	c0.2-0.2,0.7-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2C355.2,291.8,355.4,292,355.4,292.3L355.4,292.3z M351.4,287.8c0.1,0.5,0.4,1,0.8,1.4
	c0.6,0.6,1.2,0.8,2,0.8c0.8,0,1.4-0.2,2-0.8c0.5-0.5,0.7-1,0.8-1.6 M351.9,292.3c-1.2,0.2-2.2,0.7-3.1,1.5l-0.5,0.4
	c-1.5,1.4-2.2,3.1-2.2,5.1c0,2,0.7,3.6,2.2,5l0.5,0.4c0.8,0.7,1.6,1.2,2.7,1.4 M351.9,306.2v-0.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M355.9,306.2v2.2c0,0.5-0.2,0.9-0.6,1.3c-0.4,0.4-0.8,0.6-1.3,0.6h-0.1
	c-0.5,0-0.9-0.2-1.3-0.6c-0.4-0.4-0.6-0.8-0.6-1.3v-2.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M351.5,306.1c-0.1,0.2-0.1,0.5-0.1,0.8v2c0,0.7,0.2,1.3,0.7,1.7
	s1,0.7,1.8,0.7c0.7,0,1.3-0.2,1.7-0.7s0.7-1,0.7-1.7v-2l-0.1-0.8 M351.5,306.1l0.5,0.1 M351.9,305.3c-0.2,0.2-0.4,0.6-0.5,0.9
	 M351.9,305.3v-12.9 M414,257.9c-6.6,0.9-12.6,4-17.5,9c-1,1-2,2.2-2.9,3.3l-0.9,1.3c-0.6,0.7-1,1.5-1.5,2.3
	c-0.7,1.3-1.3,2.6-1.9,3.8l-0.5,1.2c-0.6,1.8-1,3.6-1.3,5.5 M387.5,284.3c-0.2,1.6-0.4,3.3-0.4,5c0,3.7,0.6,7.2,1.8,10.6
	c1.5,4.3,4.1,8.3,7.6,11.9c6.2,6.2,13.6,9.3,22.4,9.3c8.7,0,16.2-3,22.4-9.2 M387.5,284.3v-0.1V284.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M396.7,307.3c-0.7,1.6-0.8,3.1-0.4,4.3c-0.2-1.2,0.1-2.4,0.8-3.7l7-11.7
	c0.4-0.5,0.7-1,0.7-1.6c0.1-0.6-0.1-1.2-0.6-1.8c-0.4-0.4-0.8-0.7-1.5-0.8l-12.6-5.4c-0.6-0.2-1-0.5-1.5-0.8c-0.6-0.5-1-1-1.3-1.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M387.5,284.3c0.2,0.7,0.7,1.4,1.3,2.1c0.4,0.4,0.8,0.7,1.4,1h0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M384.8,289.3c0,6.1,1.4,11.4,4.1,16.3c1.5,2.8,3.5,5.4,5.9,7.8
	c3.5,3.5,7.3,6.1,11.7,7.8c3.8,1.5,7.9,2.2,12.4,2.2s8.6-0.7,12.4-2.2c4.3-1.6,8.2-4.2,11.7-7.8c2.9-2.9,5.2-6.2,6.9-9.7
	c2.1-4.4,3-9.2,3-14.5c0-5.7-1.2-10.8-3.6-15.5l-2.2-3.6c-1.3-1.8-2.6-3.4-4.2-5c-6.6-6.6-14.7-10-24.1-10c-9.4,0-17.5,3.4-24.1,10
	c-1.6,1.6-2.9,3.3-4.2,5c-0.4,0.5-0.6,0.8-0.8,1.3c-0.5,0.7-0.9,1.5-1.3,2.3C386,278.5,384.8,283.6,384.8,289.3L384.8,289.3z
	 M390.2,335.1c-3.4-2.1-6.5-4.7-9.6-7.7c-1.9-1.9-3.6-3.8-5.1-5.8l-2.3-3.3c-5.6-8.5-8.3-18.2-8.3-29c0-5.5,0.7-10.6,2.1-15.5
	 M355.9,292.3v-0.5c0.4-0.4,0.6-0.9,0.7-1.8c0.1-0.7,0.2-1.5,0.2-2.6 M356.2,306.1c1-0.4,2.1-0.9,3-1.7c1.5-1.4,2.2-3,2.2-5
	c0-2-0.7-3.6-2.2-5c-1-0.9-2.1-1.5-3.4-1.9 M355.9,305.3v0.9 M356.2,306.1l-0.4,0.1 M355.9,305.3c0.2,0.2,0.4,0.5,0.5,0.8
	 M355.9,292.3v12.9 M343.1,270.1l-2.4,3.7 M344.2,270.2h24 M367.8,271.5h-24.4 M367.1,273.8c0.2-0.8,0.5-1.5,0.7-2.3 M367.1,273.8
	h-24.4 M319.4,201.8l-9.3,72 M340.6,273.8h-30.5 M408.2,188h21.2c1-0.1,1.9-0.5,2.1-1.3h5.7c0.2,0.6,1,1.1,2.2,1.3
	c6.6,0.1,13.3,0.2,20,0.4c0.9-0.1,1.6-0.6,2-1.3l5.2,0.1c0.4,0.7,0.9,1.2,1.8,1.4l21.7,0.8c0.8-0.2,1.4-0.7,1.6-1.3l6.1,0.4
	c0.2,0.7,0.7,1.2,1.5,1.4l9.7,0.6l5.7,0.8c0.8,0,1.5,0.5,2.2,1.2c0.7,0.9,1.3,2.3,1.7,4.3l0.2,1.1l9.1,63.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M479.7,204.7v-5.5c0-0.6-0.4-0.8-1-0.8h-7.5c-0.6,0-0.9,0.2-1,0.7v5.4
	 M479.7,204.7v0.9l13.2,0.8c4.9,0.5,7.5,3.1,7.7,7.8l0.6,6.5c1.2,13.8,2.1,28.9,2.7,45.6c-0.1,2-1.2,3-3.4,3h-7.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M477.7,199h-5.9c-0.6,0-0.8,0.2-0.8,0.8v7.3l1.5,6.5
	c0.1,0.6,0.6,0.9,1.4,1l1.9-0.1c0.7,0,1.2-0.2,1.3-0.7l1.4-6.9v-7.3C478.5,199.2,478.3,199,477.7,199L477.7,199z M494.5,270.1h7.8
	c1.9-0.2,2.8-1.5,2.8-4c-0.6-16.7-1.5-31.8-2.7-45.6l-0.7-7.3c-0.1-4.7-2.7-7.3-7.7-7.8l-14.3-0.7 M405,186.6h1
	c0.2,0.7,0.9,1.2,2.2,1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M408.2,188c-1-0.1-1.9-0.6-2.1-1.4h-1 M427.1,230.8l4.5-0.1
	c0.5,0,0.8-0.1,0.9-0.5c0.2-0.2,0.2-0.6,0.1-1.1l-1.4-5c-0.1-0.7-0.6-1.1-1.4-1.1h-22.1c-0.7,0-1.2,0.4-1.4,1.1l-1.4,5
	c-0.1,0.5-0.1,0.8,0.1,1.1c0.1,0.2,0.5,0.5,0.9,0.5l4.5,0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M405.7,229.9h0.2l12.5,0.1l13.3-0.1h0.2v-0.4l-1.4-5
	c-0.1-0.2-0.2-0.5-0.6-0.4h-22.1c-0.4,0-0.5,0.1-0.6,0.4l-1.4,5L405.7,229.9L405.7,229.9z M427.1,230.8l-8.7,0.1l-7.9-0.1
	 M426.1,261.6c4.9,1.2,9.1,3.7,12.9,7.5l2.9,3.4 M431.6,271.9l-0.7-0.5c-0.7-0.5-1.4-0.9-2.1-1.3l-1.7-0.8 M426.1,261.6l0.9,7.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M443,274c1.9-0.1,3.1,0.2,4.2,1c-1-0.6-2.2-0.7-3.7-0.4l-13.3,3
	c-0.6,0.2-1.2,0.2-1.7,0.2l-0.6-0.2c-0.4-0.2-0.7-0.5-0.8-0.8c-0.2-0.5-0.4-1-0.4-1.6l-1.3-13.6v-0.1c-0.1-1.4-0.6-2.6-1.5-3.4
	c1,0.7,1.7,1.9,2.2,3.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M441.2,311.8c4.5-4.5,7.3-9.7,8.6-15.5c0.5-2.2,0.7-4.5,0.7-7
	s-0.2-4.8-0.7-7c-0.4-1.6-0.8-3.1-1.4-4.7c-0.5-1.3-1.2-2.6-1.9-3.8l-2.3-3.6c-0.9-1.2-1.9-2.2-2.9-3.3c-6.2-6.2-13.6-9.3-22.4-9.3
	l-4.9,0.4 M441.9,272.4l-10.4-0.5 M426.7,235.9l-7.8-0.6l-7.8,0.6 M397.4,270.5l-0.8,0.9c-0.6,0.7-1.2,1.5-1.7,2.3v0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M411.7,261.6c-4.9,1.2-9.2,3.7-13.1,7.6l-1.3,1.4 M411.7,261.6l-0.8,7.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M394.8,273.8c-1.8-0.1-3.1,0.2-4.2,1c1-0.6,2.3-0.7,3.7-0.4l13.6,3.1
	l1.4,0.1l0.2-0.1c0.5-0.1,0.9-0.5,1.2-0.9c0.2-0.5,0.4-1,0.4-1.8l1.3-13.5c0.1-1.5,0.6-2.7,1.5-3.5 M414,257.9c-1,0.7-1.7,2-2.2,3.6
	"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M407.7,271l-0.8,0.5c-0.9,0.7-2,1.4-2.8,2.3l-1.5,1.6 M410.9,269.3
	c-0.7,0.2-1.3,0.6-1.9,0.9c-0.5,0.2-0.9,0.5-1.3,0.8 M407.7,271l-10.3-0.5 M418.1,273.8c-0.7,0.2-1.3,0.7-1.8,1.5
	c-0.5,0.7-0.7,1.5-0.8,2.3c-0.1,0.5-0.1,0.9-0.1,1.5v0.8c0.1,0.7,0.5,1.3,0.9,1.9c0.7,0.7,1.5,0.9,2.4,0.9s1.8-0.4,2.3-0.9
	c0.5-0.6,0.8-1.2,0.9-1.9l-0.1-2.3c-0.1-0.9-0.5-1.6-0.8-2.3c-0.5-0.8-1-1.3-1.6-1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M420.8,277.4c-0.6-0.6-1.3-0.9-2-0.9c-0.8,0-1.5,0.2-2.1,0.9l-0.2,0.2
	c-0.5,0.5-0.7,1.2-0.7,1.9c0,0.8,0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.9,2.1,0.9s1.5-0.2,2-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.7-0.2-1.3-0.7-1.9L420.8,277.4L420.8,277.4z M419.4,273.8l-0.7-0.1l-0.6,0.1 M419.4,273.8h-1.3 M402.5,275.4l3.7,0.8
	c1.3,0.2,2.2,0.1,2.9-0.5c0.6-0.4,1-1,1.3-2l0.6-4.4 M410.2,283c-1.4-0.5-2.8-0.6-4-0.2c-1.3,0.2-2,0.9-2.2,1.8
	c-0.4,0.9,0,1.9,0.8,2.8s2,1.6,3.4,2.1l0.8,0.2c0.7,0.1,1.4,0,2.1-0.4c0.8-0.5,1.4-1,1.6-2c0.2-0.9,0.2-1.8-0.2-2.6
	c-0.4-0.7-0.8-1.2-1.4-1.5L410.2,283L410.2,283z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M412.1,285c-0.4-0.7-0.9-1.3-1.6-1.5c-0.8-0.2-1.5-0.2-2.2,0.2
	c-0.7,0.4-1.2,0.9-1.4,1.7c-0.2,0.7-0.2,1.5,0.2,2.2c0.4,0.7,0.9,1.2,1.8,1.4c0.7,0.2,1.5,0.2,2.2-0.1c0.7-0.4,1.3-0.9,1.5-1.8
	C412.6,286.4,412.4,285.7,412.1,285L412.1,285z M418.8,284.8c-1.3,0-2.3,0.5-3.1,1.3c-0.8,0.9-1.3,2-1.3,3.1c0,1.3,0.5,2.3,1.3,3.3
	c0.9,0.8,2,1.3,3.1,1.3c1.3,0,2.3-0.5,3.1-1.3c0.9-0.9,1.4-2,1.4-3.3s-0.5-2.3-1.4-3.1C421.2,285.2,420.1,284.8,418.8,284.8
	L418.8,284.8z M394.8,273.8l7.7,1.6 M390.4,287.6c0,0.6-0.1,1.2-0.1,1.8c0,6.9,2.1,12.9,6.4,18.1 M390.4,287.6l10.5,4.8
	c1.2,0.5,1.9,1.2,2.1,2.1c0.4,0.8,0.2,1.8-0.5,2.9l-5.7,10.1 M421.3,296.6c-0.1,0.7,0,1.4,0.4,2.1l0.5,0.7c0.9,1.3,1.9,2.1,3,2.6
	s2.1,0.5,2.8-0.1c0.8-0.6,1-1.4,0.9-2.7c-0.1-1.3-0.6-2.6-1.5-3.7l-0.6-0.7c-0.5-0.5-1.2-0.8-1.9-0.9c-0.9-0.1-1.7,0-2.4,0.6
	C421.9,295,421.4,295.7,421.3,296.6L421.3,296.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M421.8,296.8c-0.1,0.8,0,1.5,0.5,2.2c0.5,0.7,1,1,1.9,1.2
	c0.8,0.1,1.5,0,2.2-0.5c0.7-0.5,1-1.2,1.2-1.9c0.1-0.8,0-1.5-0.5-2.2c-0.5-0.7-1.2-1-1.9-1.2c-0.8-0.1-1.5,0-2.2,0.5
	C422.2,295.4,421.9,295.9,421.8,296.8L421.8,296.8z M416.4,296.8c-0.1-0.9-0.6-1.6-1.3-2.2c-0.7-0.6-1.6-0.7-2.4-0.6
	c-0.7,0.1-1.4,0.5-1.9,0.9l-0.5,0.7c-0.9,1.3-1.4,2.4-1.5,3.7s0.2,2.1,0.9,2.7c0.7,0.6,1.6,0.6,2.8,0.1c1.2-0.5,2.2-1.3,3-2.6
	l0.5-0.7C416.4,298.2,416.5,297.5,416.4,296.8L416.4,296.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M414.8,294.9c-0.7-0.5-1.4-0.7-2.2-0.5c-0.8,0.1-1.4,0.5-1.9,1.2
	s-0.7,1.4-0.5,2.2c0.1,0.8,0.5,1.4,1.2,1.9c0.7,0.5,1.4,0.7,2.2,0.6c0.8-0.1,1.4-0.6,1.9-1.2c0.5-0.7,0.7-1.4,0.5-2.2
	C415.8,295.9,415.5,295.4,414.8,294.9L414.8,294.9z M429.2,316l-7.9-8.7c-0.7-0.7-1.3-1.2-2-1.3l-0.7-0.1h-0.7
	c-0.7,0.1-1.3,0.6-2,1.3l-7.8,8.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M408.2,315.9c-1.4,1.2-2.8,1.6-4,1.6c1.2-0.1,2.3-0.8,3.3-2l9-10.3
	c0.4-0.6,0.8-0.9,1.3-1.2c0.7-0.2,1.3-0.2,1.9,0c0.5,0.2,0.9,0.6,1.3,1.2l9.1,10.4c0.9,1,2,1.6,3.1,1.9c-1.3,0.1-2.6-0.5-4-1.5
	 M435.4,275.5l7.6-1.5 M443,274l-0.1-0.2l-0.9-1.4 M447.4,287.7l-0.1,0.1l-5.7,2.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M440.9,307.5c4.3-5.1,6.5-11.2,6.5-18.2v-1.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M441.2,311.8c0.1-1.2-0.1-2.3-0.9-3.6l-7-11.8c-0.4-0.5-0.7-1-0.7-1.6
	c0-0.7,0.1-1.3,0.6-1.8c0.4-0.4,0.8-0.7,1.5-0.9l12.6-5.4h0.1c1.2-0.5,2-1.2,2.6-2.1l0.2-0.5l-0.2,0.6c-0.5,0.9-1.3,1.9-2.5,2.7
	 M435.4,275.5l-1.7-1.8l-2.1-1.9 M427,269.4l0.5,4.4c0.2,1,0.6,1.8,1.3,2.1c0.7,0.6,1.6,0.7,2.9,0.5l3.7-0.8 M426.7,283.2
	c-0.7,0.4-1.2,0.8-1.5,1.5c-0.5,0.8-0.5,1.6-0.2,2.6c0.2,0.8,0.8,1.5,1.6,2c0.7,0.4,1.3,0.5,2.1,0.4l0.8-0.2
	c1.5-0.5,2.6-1.2,3.4-2.1s1-1.9,0.8-2.8c-0.2-0.9-1-1.5-2.2-1.8c-1.3-0.2-2.6-0.2-4,0.2L426.7,283.2L426.7,283.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M429.5,283.6c-0.7-0.4-1.5-0.5-2.2-0.1c-0.8,0.2-1.3,0.7-1.7,1.4
	c-0.4,0.7-0.5,1.5-0.2,2.2c0.2,0.8,0.7,1.3,1.5,1.6c0.7,0.4,1.4,0.5,2.2,0.2c0.8-0.2,1.4-0.7,1.8-1.5c0.4-0.7,0.5-1.4,0.1-2.2
	C430.7,284.5,430.3,283.9,429.5,283.6L429.5,283.6z M441.6,290.4l-4.9,2.2c-0.7,0.4-1.3,0.7-1.6,1.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M435.2,293.6c-0.2,0.4-0.5,0.6-0.5,0.9c-0.4,0.8-0.2,1.8,0.5,2.9l5.7,10
	 M440.9,307.5c0.7,1.6,0.8,3,0.4,4.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M441.6,290.4c0,2.3-0.2,3.8-1,4.7l-5.5-1.4 M492.8,269.1l0.7,1
	 M492.8,269.1l1.7,0.9 M493.5,270.2l0.8,1.3 M469.4,270.2l-0.7-1.6c-1.6-4-3.7-7.7-6.4-11.2l-0.2-0.2c-1.2-1.5-2.4-3-3.8-4.5
	l-1.3-1.3c-8.6-8.6-18.8-13.8-30.3-15.3 M469.4,270.2l0.5,1.3 M472.4,282.8l0.4,4.7 M472.7,287.4v1.5 M472.7,289v0.9 M472.1,280.3
	l0.1,0.9 M472.2,281.3l0.1,1.5 M470.6,273.8c0.6,2.1,1,4.3,1.4,6.5 M472.7,287.4h17.4c0.6,0,0.8-0.2,0.8-0.8v-3
	c0-0.6-0.2-0.8-0.8-0.8h-17.6 M524.7,292.3v-11.4c0-0.9-0.5-1.4-1.4-1.4h-14.2c-0.9,0-1.4,0.5-1.4,1.4v11.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M516.8,278.1h6.5c1.9,0,2.8,0.9,2.8,2.8v22.8c0,1.9-0.9,2.8-2.8,2.8h-14.2
	c-1.9,0-2.8-0.9-2.8-2.8v-22.8c0-1.9,0.9-2.8,2.8-2.8h6.1 M524.7,292.3h-16.9 M496.6,289.9h5.6c0.6,0,0.8-0.2,0.8-0.8v-7.9
	c0-0.6-0.2-0.8-0.8-0.8h-5.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M497.4,277.9h7.8c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-7.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M495,281.3h6.4c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.3
	 M495.9,273.8h1.2 M495.6,280.3v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M496.6,280.3v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.4-0.1 M494.3,271.5l1.4,2.3
	H495 M496.6,280.3h-1 M495,281.3v-0.9 M495,280.3h0.6 M495,273.8v6.5 M495.1,289v0.9 M495.6,289.9h-0.5 M496.6,289.9h-1
	 M497.4,292.2h-1c-0.4,0-0.6-0.1-0.7-0.4l-0.1-0.5v-1.5 M497.4,292.2c-0.4,0-0.6-0.1-0.7-0.4 M496.6,291.4v-1.5 M495.1,289l-0.1-7.7
	 M507.8,292.3v11.4c0,0.9,0.5,1.4,1.4,1.4h14.2c0.9,0,1.4-0.5,1.4-1.4v-11.4 M497.1,273.8l-2.6-3.7 M493.5,270.2h-24 M494.3,271.5
	h-24.4 M495,273.8h-24.4 M472.2,281.3H495 M495,280.3h-22.8 M495.1,289.9h-22.4 M472.7,289h22.4 M469.9,271.5l0.7,2.3 M410.5,230.8
	l0.6,5.1 M426.7,235.9l0.5-5.1"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            strokeWidth="0.5"
            d="M528.8,265.1v-82.5c0.1-0.1,0.1-0.2,0.1-0.4v-0.9
	c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.4v0.9c0,0.1,0,0.2,0.1,0.4h0.1v78.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M559.1,261.7h1c3.3,0.1,4.9-1.4,4.8-4.4v-9.9c0-3.3-0.1-5.8-0.5-7.6
	c-0.5-1.5-1.5-2.2-3.4-2.2l-2.3-0.1c-6.8-0.5-14.1-0.5-22-0.2c-2.5,0.2-3.5,1.8-3.5,4.4v16.1c-0.1,2.4,1.5,3.8,4.8,4.1h1.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M557.1,237.3l2,0.1c1.9,0,2.9,0.7,3.4,2.2c0.3,1.9,0.5,4.4,0.5,7.6v9.9
	c0.1,2.7-1.2,4.2-3.8,4.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M555.6,238.9l1.4,0.2h0.2c1.5,0,2.5,0.6,2.8,2c0.3,1.6,0.5,3.7,0.5,6.5
	v8.4c0.1,2.3-1,3.6-3.3,3.8h-17.6c-2.8-0.2-4.1-1.4-4-3.5v-13.9c0-2.3,0.9-3.5,2.9-3.7C544.6,238.6,550.3,238.7,555.6,238.9
	L555.6,238.9z M555.6,238.9l1.4,0.1 M528.8,265.1l0.5,0.7 M528.2,261.3l0.5,3.5l0.1,0.2 M534.1,267.2h-4.4l-0.5-1.4 M539.5,261.7
	v4.8h1.6 M541.1,266.5h2.9 M541.1,266.5v0.7 M544,266.5h1.7v-4.8 M544,267.2v-0.7 M534.1,274.5l12-1.7v-5.6H544 M544,267.2h-2.9
	 M541.1,267.2h-7 M539.5,261.7h6.3 M529.2,273.5h3.8v1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M530.6,280.2h2.4v-5.5 M533.1,274.7l1-0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M529.2,274.3c0.5,0.6,0.8,1.5,1,2.7c0.2,0.9,0.3,2,0.3,3.3 M529.2,274.3
	l-0.3-0.5h-1.4 M529.2,273.5v0.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M527.7,334.4l0.5-27.6v-28.1c0.1-2.8-0.1-4.4-0.6-4.9 M530.6,280.2v4.3
	c0.3,0.1,0.5,0.6,0.5,1.2v2.1 M531.2,287.7l-0.1,2 M531.2,289.4v-1.8 M531.1,289.7l0.1-0.2 M552.6,321.6v-20.9l-2.8-5.7
	c-0.9-2-2.6-3.1-4.8-3.4l-13.9-2.2 M531.1,289.7c-0.5,1.6-0.9,4.1-1.2,7.7c-0.5,5.9-0.8,14.8-0.9,26.3l-0.5,9.3 M529.2,265.8v7.7
	 M534.1,267.2v7.3 M545.8,261.7h13.3 M527.5,273.8l-9.3-72 M527.5,273.8h-30.4 M514.1,379v42.7c0.3,3.3,2.2,5.5,5.6,6.6
	c4.1,0.7,8.9,1,14.5,0.9h1.9c5.5,0.1,10.4-0.2,14.3-0.9c3.4-1.2,5.2-3.4,5.6-6.6v-93.8c-0.1-2-0.5-3.1-1-3.6l-2.4-2.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M552.6,321.6v10.7l-24,0.7 M552.6,321.6v0.1V321.6 M540,339h-21.1
	c-0.6,0-0.8,0.2-0.8,0.8v12.5c0,0.6,0.2,0.8,0.8,0.8H540c0.6,0,0.8-0.2,0.8-0.8v-12.5C540.9,339.2,540.5,339,540,339z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M538.6,340.2h-18.3c-0.6,0-0.8,0.2-0.8,0.8v10c0,0.6,0.2,0.8,0.8,0.8h18.3
	c0.6,0,0.8-0.2,0.8-0.8v-10C539.4,340.6,539,340.2,538.6,340.2L538.6,340.2z M528.4,335.6h-0.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M528.4,335.6h18.2c3.7-0.2,5.6,0.9,5.8,3.5l-2.2,13.6l-0.5,1.8
	c-0.3,0.9-0.8,1.7-1.3,2.6l-2.5,4.2c-0.8,0.9-3.1,1.3-6.8,1.3H519c-0.8,0-1.4,0.1-2,0.4c-0.6,0.2-1,0.6-1.3,1 M527.7,335.6v-1.2
	 M528.5,333l-0.1,2.6 M515.7,334.1v0.4 M495.2,333.4l0.4-0.1h19.2c0.6,0,0.8,0.2,0.8,0.8 M515.7,334.1l0.2,0.4 M516,334.4h-0.2
	 M495.2,319.3h6.2c0.6,0,0.8,0.2,0.8,0.7v6.4c0,0.5-0.2,0.7-0.8,0.7h-6.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M497.4,315.9h7.9c0.6,0,0.8,0.2,0.8,0.8v12.7c0,0.6-0.2,0.8-0.8,0.8h-7.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M496.6,327.9h5.6c0.6,0,0.8-0.2,0.8-0.8v-7.9c0-0.6-0.2-0.8-0.8-0.8h-5.6
	 M496.6,318.3v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.4-0.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M495.6,318.3v-1.6c0-0.4,0.1-0.7,0.5-0.7l0.5-0.1h1 M495.2,319.3v-0.9
	 M495.2,318.3h0.4 M496.6,318.3h-1 M495.2,327v0.9 M495.6,327.9h-0.4 M496.6,327.9h-1 M497.4,330.2h-1c-0.4,0-0.6-0.1-0.7-0.4
	l-0.1-0.5v-1.5 M494.7,334.1c0-0.5,0.1-0.7,0.5-0.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M497.4,330.2c-0.4,0-0.6-0.1-0.7-0.4l-0.1-2 M495.2,327.9v5.5 M495.2,327
	v-7.7 M462.7,320.8h27.4c0.6,0,0.8,0.2,0.8,0.8v3c0,0.6-0.2,0.8-0.8,0.8h-31.2 M455.9,337.7c0.1-0.6,0.7-0.9,1.5-1
	c14.8,0.1,26.5,0,34.9-0.4c1-0.1,1.8-0.6,2.2-1.3 M516,334.4c0.2,0.4,0.7,0.6,1,0.8c0.6,0.2,1.3,0.4,2.2,0.4h8.5 M494.4,335.1
	c0.2-0.2,0.4-0.6,0.4-1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M462.7,320.8l-0.6,0.7c-1,1.4-2.1,2.7-3.3,4 M458.8,325.4l-1.4,1.5
	 M463.8,319.3l-1.2,1.5 M464.4,318.3l-0.6,0.9 M478.5,364.6c-0.6-1.4-1.6-2.2-3-2.7 M479,370.6l0.1-1.2c0.1-0.7,0.1-1.4,0.1-2.1
	 M476,371.7c-0.6-0.6-1.4-1-2.2-1.2h-1.5c-0.8,0.1-1.5,0.5-2.2,1.2c-0.8,0.8-1.2,1.7-1.2,2.8v0.1c0,0.8,0.2,1.6,0.7,2.2l0.5,0.7
	c0.8,0.8,1.7,1.2,2.9,1.2s2.1-0.4,2.9-1.2l0.6-0.7c0.5-0.7,0.7-1.4,0.7-2.2v-0.1C477.1,373.4,476.7,372.4,476,371.7L476,371.7z
	 M478.1,374.4l-0.2,0.9 M478,374.4h0.1 M477.8,375.3l0.1-0.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M478,374.4c0-1.3-0.5-2.3-1.4-3.3l-0.7-0.6c-0.8-0.6-1.8-0.8-2.8-0.8
	s-2,0.2-2.8,0.8c-0.2,0.2-0.5,0.4-0.7,0.6c-0.6,0.6-0.9,1.2-1.2,2 M478.1,374.4l0.9-3.8 M479.2,367.3c-0.1-1-0.4-1.9-0.7-2.6
	 M475.5,361.9H457 M455.6,374.6l0.7-0.1c0.5-0.2,0.7-0.6,0.7-1v-2.8 M469,370.6l0.2-0.8 M468.4,373.1c-0.1,0.5-0.2,0.9-0.2,1.4v0.1
	c0,0.8,0.2,1.5,0.6,2.2c0.2,0.5,0.5,0.8,0.9,1.3l1,0.8 M469.2,369.7c0.4-1.6,0.4-2.8,0.1-3.5 M468.4,373.1l0.6-2.6 M469,370.6h-12
	 M457,370.6v-8.6 M469.2,369.7l-12.2,0.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M470.6,379c0.7,0.4,1.5,0.6,2.3,0.6c0.9,0,1.6-0.2,2.4-0.6 M475.4,379
	l1-0.8c0.4-0.4,0.7-0.8,0.9-1.3l0.1-0.4c0.1-0.4,0.2-0.8,0.4-1.3 M515.7,363.9c0,0.2-0.1,0.5-0.2,0.6l-0.6,0.1h-0.8 M514.1,367.3
	v3.3 M514.1,364.7v2.6 M495.3,364.6c-0.4-0.1-0.5-0.4-0.5-0.8v-0.5 M514.1,364.7h-18.4l-0.4-0.1 M494.7,363.3
	c-0.2-0.8-1-1.3-2.5-1.4h-16.9 M514.1,370.6v8.4 M475.4,379h38.7 M494.7,363.3v-29.3 M463.8,319.3h31.4 M457.4,327h37.8
	 M495.3,364.6h-16.8 M479.2,367.3h34.9 M514.1,370.6H479 M464.4,318.3h30.8 M515.7,363.9v-29.5 M437.4,353.3v-3
	c0-0.2,0.1-0.5,0.5-0.5h6.2c0.2,0,0.5,0.1,0.5,0.5v3 M437.4,354.7v-1.4 M438.8,354.7h-1.4 M439.7,356.7l-0.4-0.4
	c-0.5-0.4-0.6-0.9-0.6-1.4v-0.1 M457,361.9c-0.6-0.1-0.8-0.6-0.9-1.3l-0.1-22.1v-0.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M444.4,354.7h3c0.2,0,0.5-0.1,0.5-0.5v-9.1c0-0.2-0.1-0.5-0.5-0.5h-57.2
	c-0.2,0-0.5,0.1-0.5,0.5v9.1c0,0.2,0.1,0.5,0.5,0.5h3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M446,349.2c0.2,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.2,0.6
	c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2s-0.2-0.4-0.2-0.6c0-0.2,0.1-0.5,0.2-0.6
	S445.8,349.2,446,349.2L446,349.2z M442.9,354.7v0.1c0,0.6-0.2,1-0.6,1.4l-0.4,0.4 M444.4,353.3v1.4 M444.4,354.7h-1.5 M455.9,337.7
	h-8c-8.6,3.8-17.5,5.9-26.6,6.4l-3.4,0.1c-9.7-0.2-19.1-2.4-28.2-6.4h-8 M456.5,327.9c-2.8,2.8-5.8,5.2-9.1,7.2 M444.4,353.3h-7
	 M442.9,354.7h-4.1 M408.2,315.9c3.3,1.3,6.8,2,10.6,2c3.7,0,7.2-0.6,10.4-1.9 M391,349.5c-0.1,0.1-0.2,0.4-0.2,0.6
	c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6s-0.4-0.2-0.6-0.2
	C391.3,349.2,391.2,349.3,391,349.5L391,349.5z M393.2,354.7v-1.4 M394.7,354.7v0.1c0,0.6,0.2,1,0.6,1.4l0.4,0.4 M393.2,354.7h1.5
	 M393.2,353.3v-3c0-0.2,0.1-0.5,0.5-0.5h6.2c0.2,0,0.5,0.1,0.5,0.5v3 M400.3,354.7h-1.4 M400.3,353.3v1.4 M397.9,356.7l0.4-0.4
	c0.5-0.4,0.6-0.9,0.6-1.4v-0.1 M398.9,354.7h-4.2 M393.2,353.3h7.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M447.4,335.1c-8.4,5.4-18,8-28.6,8c-10.6,0-20.2-2.7-28.6-8 M395.6,356.7
	c0.4,0.2,0.7,0.4,1.2,0.4c0.5,0,0.8-0.1,1.2-0.4 M441.9,356.7h13.5c0.2,0,0.5,0.1,0.5,0.5v17.1l-0.1,0.2l-0.2,0.1 M441.9,356.7
	c-0.4,0.2-0.7,0.4-1.2,0.4c-0.5,0-0.8-0.1-1.2-0.4 M439.7,356.7h-41.9 M437.4,354.7h-37.1 M495.2,327.9h-38.7 M494.4,335.1h-47
	 M457.4,327l-0.9,0.9 M527.7,334.4H516 M356.4,316.8c0-0.7-0.2-1.2-0.7-1.6c-0.5-0.5-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.6,0.7
	c-0.5,0.5-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6s0.9,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7S356.4,317.4,356.4,316.8L356.4,316.8z
	 M381.8,337.7c-0.1-0.6-0.7-0.9-1.5-1c-12.9,0.1-23.4,0-31.5-0.2l-3.4-0.1c-1-0.1-1.8-0.6-2.2-1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M354.1,316c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6
	c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6
	C354.5,316,354.3,316,354.1,316L354.1,316z M342.4,333.4c0.4,0.1,0.5,0.4,0.5,0.8 M321.9,334.1c0-0.6,0.2-0.8,0.8-0.8h19.2l0.4,0.1
	 M343.2,335.1c-0.2-0.2-0.4-0.6-0.4-1 M352.5,364.6v-2.7 M323.5,364.7H342l0.4-0.1 M342.8,363.3c0.2-0.8,1-1.3,2.5-1.4h7.2
	 M342.4,364.6c0.4-0.1,0.5-0.4,0.5-0.8v-0.5 M352.5,364.6v9.3c0,0.2,0.1,0.5,0.5,0.5h31.5 M342.4,364.6h10.1 M323.5,367.3v3.3
	 M323.5,364.7v2.6 M322.4,364.6l0.2,0.1h0.8 M322.1,364.6h0.4 M322.4,364.6l-0.2-0.1c-0.1-0.1-0.2-0.4-0.2-0.6 M323.5,370.6v8.4
	 M352.4,367.3h-28.9 M352.4,370.6h-28.9 M384.3,374.4c0.1,0.1,0.1,0.1,0.4,0.1h70.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M384.3,374.4l-0.1-0.2V357c0-0.2,0.1-0.5,0.5-0.5h11 M352.5,361.9H380
	c0.9,0.1,1.4-0.4,1.5-1.3l0.1-23 M342.8,334.1v29.3 M321.6,334.4l0.2-0.4 M321.9,334.4v-0.4 M321.9,334.4h-0.2 M309,333l-0.5-9.3
	c-0.1-11.5-0.4-20.4-0.9-26.3 M321.6,334.4h-11.8 M299.1,340.2h18.3c0.6,0,0.8,0.2,0.8,0.8v10c0,0.6-0.2,0.8-0.8,0.8h-18.3
	c-0.6,0-0.8-0.2-0.8-0.8v-10C298.3,340.6,298.5,340.2,299.1,340.2L299.1,340.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M296.8,352.3v-12.5c0-0.6,0.2-0.8,0.8-0.8h21.1c0.6,0,0.8,0.2,0.8,0.8
	v12.5c0,0.6-0.2,0.8-0.8,0.8h-21.1C297,353.1,296.8,352.8,296.8,352.3L296.8,352.3z M310,335.6h-0.8 M310,335.6h8.5
	c1.5,0,2.6-0.4,3.3-1.2 M309.1,335.6L309,333 M309.9,334.4l0.1,1.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M309.1,335.6H291c-3.7-0.2-5.6,0.9-5.8,3.5l2.2,13.6
	c0.1,0.6,0.2,1.2,0.5,1.8c0.4,0.9,0.8,1.7,1.3,2.6l2.4,4.2c0.8,0.9,3.1,1.3,6.8,1.3h20.3c1.6,0,2.8,0.5,3.4,1.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M285,321.6l-2.4,2.6c-0.5,0.4-0.8,1.6-1,3.6v93.8c0.4,3.3,2.2,5.5,5.6,6.6
	c4.1,0.7,8.9,1,14.3,0.9h1.9c5.5,0.1,10.4-0.2,14.5-0.9c3.4-1.2,5.2-3.4,5.6-6.6V379"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M309,333l-24-0.7v-10.7 M285,321.6v0.1V321.6 M321.9,363.9v-29.5
	 M323.5,379h147.1 M390.2,335.1h-47 M472.7,289.9c-0.1,10.6-2.9,20-8.4,28.4 M342.4,333.4l0.4-59.6 M495.1,289.9l0.1,28.4"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M368.3,270.2h-24l0.7-1h-7.7
		c0,0-3.3,0.2-3.4-3c0,0,1.5-33.8,3.3-52.5c0,0,0-7,7.6-7.3l13.2-0.8v-6.4c0,0,0-0.8,1-0.8l0.5,0.8c0,0-0.2,0.1-0.2,0.7v7.3l1.4,6.9
		c0,0,0,0.6,0.8,0.6c0.8,0.1,2.4,0.1,2.4,0.1s1.2-0.1,1.4-1l1.5-6.5V200c0,0,0.1-0.8-0.4-1l0.8-0.6c0,0,0.4,0.1,0.4,0.5
		c0,0.5,0,6.2,0,6.2s8.4-0.1,18.5-0.1h35h31.7c0,0,12.9-0.1,17.5,0.1v-6.2c0,0,0.1-0.5,0.5-0.6l0.6,0.7c0,0-0.2,0.1-0.2,0.7v7.3
		l1.5,6.5c0,0,0.1,0.8,1.4,1l2.2-0.1c0,0,0.9,0,1-0.7l1.4-6.8v-7.5c0,0,0-0.4-0.2-0.6l0.8-0.8c0,0,0.6,0.2,0.6,0.6v6.5l12.9,0.8
		c0,0,7.6-0.1,7.8,7.7c0,0,2.9,33.8,3.3,52.1c0,0,0.1,2.9-3,3h-8l0.6,1l-24,0.1c0,0-9.9-29.4-42.8-34.3l0.5-5l4.5-0.1
		c0,0,1.4,0,1-1.4l-1.5-5.2c0,0-0.2-0.7-1.3-0.7h-22.3c0,0-0.9-0.2-1.3,1c-0.4,1.2-1.4,5-1.4,5s-0.5,1.4,1,1.4l4.5,0.1l0.5,5.1
		C411,235.9,381.5,238.1,368.3,270.2z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M367.1,273.8h-24.4l-0.2,59.6
		c0,0,0.5,0.1,0.5,0.6c0,0,0.1,2.1,2.8,2.4c0,0,7.2,0.4,14.7,0.4h19.9c0,0,1,0,1.5,1.1h8c0,0,13.4,6.4,28.7,6.4
		c0,0,14.2,0.6,29.5-6.4h8c0,0,0.2-1.1,1.5-1.1c0,0,21.6,0.2,34.5-0.2c0,0,2.4-0.1,2.8-2.3c0,0,0.1-0.7,0.5-0.8l-0.1-15l-0.2-44.5
		h-24.4c0,0,2.1,6.3,2.1,15.2c0,0,1.2,15.2-8.4,29.4c0,0-5.9,9.8-16.9,16.8c0,0-11.1,8.2-29,8c0,0-15.4,0.6-28.6-8.3
		c0,0-15.4-9.6-21.7-26.7c0,0-2.9-7-3.3-17.3C365,290.9,364.3,283.9,367.1,273.8z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M314.3,306.6
		c0,0-2.8,0.1-2.8-2.4c0-2.6,0-23.2,0-23.2s0-2.8,2.6-2.8c2.6,0,14.5,0,14.5,0s2.8-0.1,2.8,2.4s0,23.5,0,23.5s0,2.4-2.8,2.4
		C325.8,306.6,314.3,306.6,314.3,306.6z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M509.1,306.6
		c0,0-2.8,0.2-2.8-2.4c0-2.7,0-23.2,0-23.2s-0.2-2.8,2.8-2.8c3,0,14.2,0,14.2,0s2.8-0.2,2.8,2.8s0,22.8,0,22.8s0.2,2.8-2.8,2.8
		S509.1,306.6,509.1,306.6z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M322.7,333.3h19.2
		c0,0,0.8-0.1,0.8,0.8c0,0,0.2,2.1,2.9,2.2l12.7,0.4c0,0,6.2,0.1,21.6,0c0,0,1.3,0,1.7,1h8c0,0,7.7,3.4,15.9,5c0,0,7.6,1.6,12.9,1.4
		c0,0,7.2,0.1,15.7-1.9c0,0,6.4-1.4,13.8-4.7h8c0,0,0.1-1,1.5-1c1.3,0,16.1,0.1,26.2-0.1l8.5-0.2c0,0,1.8-0.2,2.6-1.6l0.2-0.7
		c0,0-0.1-0.8,0.8-0.8H515c0,0,1-0.1,1.2,1.1c0,0,0.9,1.3,3,1.3h27.9c0,0,5.6-0.8,5.8,3.5l-2.7,13.9l-0.6,2c0,0-0.8,2-3.5,6.5
		c0,0-0.7,1.2-5.8,1.3h-20.6c0,0-2.9-0.2-4,1.4c0,0,0,0.8-0.9,0.8h-19.2c0,0-0.8,0.1-0.8-0.8v-0.5c0,0-0.2-1.2-2.4-1.4h-35.4
		c0,0-0.9-0.1-0.9-1.4v-3.4c0,0,0-0.5-0.8-0.5h-13.3c0,0-0.4,0.4-1.2,0.4s-1.2-0.4-1.2-0.4h-18.8h-23c0,0-0.2,0.4-1.2,0.4
		s-1.2-0.4-1.2-0.4h-10.7h-3.1v3.5c0,0,0.2,1.9-1.3,1.9h-35.1c0,0-2.4,0-2.4,1.9c0,0,0.2,0.8-0.9,0.8h-19.1c0,0-0.9,0-0.9-0.8
		c0,0-0.7-1.4-3.4-1.4h-18.1H299c0,0-6.4,0.2-7.8-1.3l-2.8-4.8c0,0-1-1.9-1.3-3l-2.3-14.2c0,0-0.4-4,5.8-3.5h28c0,0,2.1,0,3-1.5
		C321.9,334.1,321.9,333.3,322.7,333.3z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv2DrRear);
