import React, { Component } from 'react';
import { connect } from 'react-redux';

class Convertible2DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }
    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M597.4,369.4c-0.2-3.1-1.7-9.6-1.3-11.7c0.4-2.3,3.4-24.1,3.6-30.4c0-1.9,0.6-6.9,1.1-13
	c1.3-14.2,3.6-34.2,3.6-34.2s-2.1-5.2-8.4-11.5c-1.7-1.7-3.8-3.1-5.9-4c1.7-1.9,8.4-2.9,18.4-3.6c10.1-0.8,9-13.8,3.4-18.4
	s-13.8-6.7-18.2-6.7c-4.6,0-10.7,1.5-12.8,5.7s-3.6,14.9-4.4,20.5c-1.1,0-2.3,0-3.8-0.2c-0.4-0.8-1-1.9-1.3-2.5
	c-3.8-8.4-12.2-31.4-15.7-39c-3.8-8.2-13.8-26.6-16.1-29.7c-2.3-3.1-5-5.7-9-7.1s-24.1-4.6-39-6.5c-14.9-1.9-37.5-4.6-69.8-4.6h-1.5
	c-32.3,0-54.9,2.7-69.8,4.6c-14.9,1.9-35,5-39,6.5c-4,1.3-6.5,4-9,7.1c-2.3,3.1-12.2,21.4-16.1,29.7c-3.6,7.8-12.6,32.5-16.1,40
	c-0.2,0.2-0.6,1-0.8,1.5c-1.5,0-2.7,0.2-3.8,0.2c-0.8-5.4-2.1-16.1-4.4-20.5c-2.1-4.2-8.2-5.7-12.8-5.7s-12.6,2.1-18.2,6.7
	s-6.7,17.6,3.4,18.4c9.9,0.8,16.6,1.7,18.4,3.6c-2.1,1-4,2.3-5.9,4c-6.3,6.3-8.4,11.5-8.4,11.5s2.3,20.1,3.6,34.2
	c0.6,6.1,1.1,11.1,1.1,13c0.2,6.3,3.1,28.1,3.6,30.4c0.4,2.1-1.1,8.8-1.3,11.7v0.4c0,2.7,4.2,9.2,10.9,14.7
	c6.5,5.4,22,15.1,34.8,15.1H326c4.8,0,55.5,5,65.8,5H450c10.3,0,61-5,65.8-5h35.6c12.8,0,28.3-9.8,34.8-15.1s10.9-11.9,10.9-14.7
	C597.4,369.8,597.4,369.6,597.4,369.4L597.4,369.4z"
          />
          <path
            fill="#EBEBEB"
            d="M279.5,260.7c9.6-1.3,51.5-6.9,66.6-7.7c16.1-1,41.9-2.7,74.6-2.7h1.1c32.7,0,58.5,1.7,74.6,2.7
	c14.9,0.8,56.8,6.5,66.6,7.7c-1-2.7-6.7-16.6-13.6-31.8c-7.7-16.6-19.9-37.1-19.9-37.1s-24.1-5.2-40.8-7.3
	c-16.6-2.1-47.5-4.6-67-4.6h-1.1c-19.3,0-50.3,2.5-67,4.6c-16.6,2.1-40.8,7.3-40.8,7.3s-12.2,20.5-19.9,37.1
	C286.1,244.1,280.5,258,279.5,260.7L279.5,260.7z"
          />
          <path
            fill="#656565"
            d="M290.6,399.6c-12.8,0-28.3-9.8-34.8-15.1c-6.5-5.4-10.9-11.9-10.9-14.7v-0.4c0.2-3.1,1.7-9.6,1.3-11.7
	c-0.4-2.3-3.4-24.1-3.6-30.4c0-1.9-0.6-6.9-1.1-13v126.1c0,2.3,1.5,6.1,3.6,6.1h42.1c2.5,0,4.6-1.7,4.6-4.6v-42.3H290.6z
	 M551.6,399.6c12.8,0,28.3-9.8,34.8-15.1s10.9-11.9,10.9-14.7v-0.4c-0.2-3.1-1.7-9.6-1.3-11.7c0.4-2.3,3.4-24.1,3.6-30.4
	c0-1.9,0.6-6.9,1.1-13v126.1c0,2.3-1.5,6.1-3.6,6.1h-42.1c-2.5,0-4.6-1.7-4.6-4.6v-42.3H551.6L551.6,399.6z M421.9,386.8
	c16.5,0,58.2-3.4,71.9-7.7c13.8-4.2,22.8-7.8,22.8-12.4c0-4.6-1-5.2-4.8-5.2H464v1.3h-85.9v-1.3h-47.8c-3.8,0-4.8,0.8-4.8,5.2
	c0,4.6,9,8,22.8,12.4c13.8,4.2,55.5,7.7,71.9,7.7H421.9L421.9,386.8z"
          />
          <path
            fill="#EBEBEB"
            d="M323.9,301.9c-4.8-2.7-9.6-5.2-13-6.7c-6.3-2.9-16.8-5.5-25.8-6.5c-0.8,0-1.5-0.2-2.3-0.2
	c-9.4-0.8-26.8,1.7-26.8,16.1c0,4.6,0.8,8,2.3,10.5c3.1,5.5,9,7.3,15.3,8c7.8,1,18.9,0.2,26.8,0.4c1.3,0,2.7,0,3.8,0.2
	c6.3,0.6,10.9,0.8,14.5,0.8c0.6,0.2,1.1,0.2,1.7,0.2c5.7,0,10.5-6.1,10.5-12.1c0-4.2-2.5-7.8-6.1-9.6L323.9,301.9L323.9,301.9z
	 M518.1,301.9c4.8-2.7,9.8-5.2,13-6.7c6.3-2.9,16.8-5.5,25.8-6.5c0.8,0,1.5-0.2,2.3-0.2c9.4-0.8,26.8,1.7,26.8,16.1
	c0,4.6-0.8,8-2.3,10.5c-3.1,5.5-9,7.3-15.3,8c-7.8,1-18.9,0.2-26.8,0.4c-1.3,0-2.7,0-3.8,0.2c-6.3,0.6-10.9,0.8-14.7,0.8
	c-0.6,0.2-1.1,0.2-1.7,0.2c-5.7,0-10.5-6.1-10.5-12.1c0-4.2,2.5-7.8,6.1-9.6L518.1,301.9L518.1,301.9z M261,343.6
	c0-2.7-2.1-4.8-4.8-4.8h-3.8c-2.7,0-4.8,2.1-4.8,4.8c0,2.7,2.1,4.8,4.8,4.8h3.8C258.9,348.3,261,346.2,261,343.6L261,343.6z
	 M585.9,338.8c-2.7,0-4.8,2.1-4.8,4.8c0,2.7,2.1,4.8,4.8,4.8h3.8c2.7,0,4.8-2.1,4.8-4.8c0-2.7-2.1-4.8-4.8-4.8L585.9,338.8
	L585.9,338.8z"
          />
          <path
            fill="#656565"
            d="M316.5,255.9c11.7-1.3,23.2-2.7,29.5-3.1c8.2-0.4,19.3-1.1,32.3-1.7c-0.4-0.6-0.8-1-1-1.3
	c-1.9-2.7-6.7-10.3-6.7-16.5c0-7.5-0.4-9.8-0.4-14s-5.4-9.4-9.4-9.4h-25.1c-3.4,0-8.8,6.1-9.4,10.7c-0.8,4.8-0.2,14.9-1.1,17.8
	c-0.6,2.1-5,10.1-7.5,14.4C317.4,254,316.9,255.2,316.5,255.9L316.5,255.9z M526,255.9c-11.7-1.3-23.2-2.7-29.5-3.1
	c-8.2-0.4-19.3-1.1-32.3-1.7c0.4-0.6,0.8-1,1-1.3c1.9-2.7,6.7-10.3,6.7-16.5c0-7.5,0.4-9.8,0.4-14s5.4-9.4,9.4-9.4h25.1
	c3.4,0,8.8,6.1,9.4,10.7c0.8,4.8,0.2,14.9,1.1,17.8c0.6,2.1,5.2,10.1,7.5,14.4C525,254,525.4,255.2,526,255.9L526,255.9z"
          />
          <path
            fill="#FFFFFF"
            d="M458.8,258.8c-14.4-5.2-24.3-5.6-36.9-5.6h-1.5c-12.6,0-22.6,0.4-36.9,5.6c0,0,16.1,10.3,36.7,10.3h2.1
	C442.8,269.3,458.8,258.8,458.8,258.8L458.8,258.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M498.1,304.9c0,0,7.3-12.8,15.7-21.2c8.6-8.4,26.4-20.3,51.7-22.4
	 M556.8,288.8l12.6-27.2 M596,357.7h-29.3c-6.3,0-35.6,3.1-35.6,8.4c0,5.2,5.2,3.4,10.5,3.4h55.7 M464.2,331.9
	c0,0,53.4-1.5,76.5-2.3s58.7-2.3,58.7-2.3 M594.5,343.6c0,2.7-2.1,4.8-4.8,4.8h-3.8c-2.7,0-4.8-2.1-4.8-4.8c0-2.7,2.1-4.8,4.8-4.8
	h3.8C592.4,338.8,594.5,340.9,594.5,343.6L594.5,343.6z M583.2,315.1h17.2 M576.5,261.9c0.8-5.4,2.1-16.3,4.4-20.5
	c2.1-4.2,8.2-5.7,12.8-5.7c4.6,0,12.6,2.1,18.2,6.7s6.7,17.6-3.4,18.4c-10.1,0.8-16.6,1.7-18.4,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M579.4,328.3c0,0-60.8,0.4-70,0c-9.2-0.4-12.6-12.2-5.5-17.6
	c7.1-5.2,20.5-12.2,27.2-15.5s18.8-6.1,28.1-6.7s26.8,1.7,26.8,16.1c0,14.4-8.2,17.4-17.6,18.6c-9.4,1.1-22.8-0.2-30.8,0.6
	c-8,0.8-13,0.8-17.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M531.7,312.6c0,5.7-4.6,12.1-10.5,12.1c-5.7,0-10.5-6.1-10.5-12.1
	c0-5.7,4.6-10.5,10.5-10.5S531.7,306.8,531.7,312.6L531.7,312.6z M542.1,323.5c0,0-10.1-3.3-10.1-12.2c0-9.2,7.7-14.9,12.2-17.8
	c4.8-2.9,13-4.6,24.1-4.6 M464,251c0,0,7.5-9.9,7.5-17.4s0.4-9.8,0.4-14c0-4.2,5.4-9.4,9.4-9.4h25.1c3.4,0,8.8,6.1,9.4,10.9
	c0.8,4.8,0.2,14.9,1.1,17.8c1,2.9,8.8,16.8,8.8,16.8 M550.5,400.2v41.9c0,2.7,2.1,4.6,4.6,4.6h42.1c2.1,0,3.6-3.8,3.6-6.1v-128
	 M333.1,307.4h176 M344.2,304.9c0,0-7.3-12.8-15.7-21.2c-8.6-8.4-26.4-20.3-51.7-22.4 M285.5,288.8l-12.6-27.2 M420.4,253.2
	c-12.6,0-22.6,0.4-36.9,5.5c0,0,16.1,10.3,36.7,10.3h2.1c20.7,0,36.7-10.3,36.7-10.3c-14.4-5.2-24.3-5.6-36.9-5.6L420.4,253.2
	L420.4,253.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.6,250.4c-32.7,0-58.5,1.7-74.6,2.7c-16.1,1-62.8,7.3-67.9,7.8
	c-5.2,0.8-10.5,1-13.2,1c-2.9,0-12.2,0.4-18.6,6.7s-8.4,11.5-8.4,11.5s4.6,40.9,4.8,47.3c0.2,6.3,3.1,28.1,3.6,30.4
	c0.4,2.3-1.3,9.4-1.3,12.2c0,2.7,4.2,9.2,10.7,14.7c6.5,5.4,22,15.1,34.8,15.1H326c4.8,0,55.5,5,65.8,5H450c0,0,61-5,65.8-5h35.6
	c12.8,0,28.3-9.8,34.8-15.1s10.9-11.9,10.9-14.7c0-2.7-1.7-10-1.3-12.2c0.4-2.3,3.4-24.1,3.6-30.4c0.2-6.3,4.8-47.3,4.8-47.3
	s-2.1-5.2-8.4-11.5c-6.3-6.3-15.7-6.7-18.6-6.7s-8-0.2-13.2-1c-5.2-0.8-52-6.9-68.1-7.8c-16.1-1-41.9-2.7-74.6-2.7L420.6,250.4
	L420.6,250.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M419.8,362.9h-41.5v-38.3H464v38.3h-41.7H419.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M377.7,357h-50.5c-5.4,0-12.2,13.6-1.5,19.5c10.5,6.1,39.6,16.6,94.3,16.6
	h2.1c54.7,0,83.8-10.5,94.3-16.6c10.5-6.1,3.8-19.5-1.5-19.5h-50.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M378.3,361.5h-47.8c-3.8,0-4.8,0.8-4.8,5.2c0,4.6,9,8,22.8,12.4
	c13.8,4.2,55.5,7.7,71.9,7.7h1.5c16.5,0,58.2-3.4,71.9-7.7c13.8-4.2,22.8-7.8,22.8-12.4c0-4.6-1-5.2-4.8-5.2H464 M246.3,357.7h29.3
	c6.3,0,35.6,3.1,35.6,8.4c0,5.2-5.2,3.4-10.5,3.4h-55.7 M377.9,331.9c0,0-53.4-1.5-76.5-2.3c-23.2-0.8-58.7-2.3-58.7-2.3
	 M247.8,343.6c0,2.7,2.1,4.8,4.8,4.8h3.8c2.7,0,4.8-2.1,4.8-4.8c0-2.7-2.1-4.8-4.8-4.8h-3.8C249.9,338.8,247.8,340.9,247.8,343.6
	L247.8,343.6z M259.1,315.1h-17.4 M269.8,261.3c3.1-5.6,12.8-32.7,16.6-41.1c3.8-8.2,13.8-26.6,16.1-29.7c2.3-3.1,5-5.7,9-7.1
	s24.1-4.6,39-6.5c14.9-1.9,37.5-4.6,69.8-4.6h1.5c32.3,0,54.9,2.7,69.8,4.6c14.9,1.9,35,5,39,6.5c4,1.3,6.5,4,9,7.1
	c2.3,3.1,12.2,21.4,16.1,29.7c3.8,8.2,13.8,35.4,16.6,41.1 M265.8,261.9c-0.8-5.4-2.1-16.3-4.4-20.5c-2.1-4.2-8.2-5.7-12.8-5.7
	s-12.6,2.1-18.2,6.7s-6.7,17.6,3.4,18.4c10.1,0.8,16.6,1.7,18.4,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.7,328.3c0,0,60.8,0.4,70,0c9.2-0.4,12.6-12.2,5.5-17.6
	c-7.1-5.2-20.5-12.2-27.2-15.5c-6.7-3.3-18.8-6.1-28.1-6.7c-9.4-0.6-26.8,1.7-26.8,16.1c0,14.4,8.2,17.4,17.6,18.6
	c9.4,1.1,22.8-0.2,30.8,0.6c7.8,0.8,13,0.8,17.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M310.4,312.6c0,5.7,4.6,12.1,10.5,12.1c5.7,0,10.5-6.1,10.5-12.1
	c0-5.7-4.6-10.5-10.5-10.5C315.1,302,310.4,306.8,310.4,312.6L310.4,312.6z M300,323.5c0,0,10.1-3.3,10.1-12.2
	c0-9.2-7.7-14.9-12.2-17.8c-4.8-2.9-13-4.6-24.1-4.6 M378.3,251c0,0-7.5-9.9-7.5-17.4s-0.4-9.8-0.4-14c0-4.2-5.4-9.4-9.4-9.4H336
	c-3.4,0-8.8,6.1-9.4,10.9c-0.8,4.8-0.2,14.9-1.1,17.8c-1,2.9-8.8,16.8-8.8,16.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M279.4,261.1c0,0,6.1-15.5,13.8-32.1c7.7-16.6,19.9-37.1,19.9-37.1
	s24.1-5.2,40.8-7.3c16.6-2.1,47.5-4.6,67-4.6h1.1c19.3,0,50.1,2.5,67,4.6c16.6,2.1,40.8,7.3,40.8,7.3s11.9,20.5,19.5,37.1
	c7.7,16.6,13.8,32.1,13.8,32.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M276.9,261.3c0,0,7.3-19.5,14.9-36.4c7.7-16.6,19.1-36.5,19.1-36.5
	s24.1-5,40.8-6.9c16.6-2.1,49.6-4.2,68.9-4.2h1.1c19.3,0,52.2,2.3,68.9,4.2c16.6,2.1,40.8,6.9,40.8,6.9s11.5,19.9,19.1,36.5
	c7.7,16.6,14.9,36.4,14.9,36.4 M291.8,400.2v41.9c0,2.7-2.1,4.6-4.6,4.6h-42.1c-2.1,0-3.6-3.8-3.6-6.1v-128"
          />
          <path
            fill="#929497"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M283.9,328.6l48.8-0.4c0,0,5.5,0.4,8.6-6.3
	c0,0,2.5-5.2-1.9-10.1c0,0-2.9-2.9-6.3-4.4H509c0,0-10,4.2-8.8,11.7c1,7.3,6.7,9.2,8.4,9.2c1.7,0.2,49.6,0.6,49.6,0.6l-137.4,4.6
	L283.9,328.6L283.9,328.6z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M242.6,327.3l178.1,5.9
		l178.9-5.9c0,0-1.1,17-3.6,30.4c0,0-0.2,1.1,0,2.9c0.2,1.9,1.1,7.8,1.1,7.8s0.4,1.7-0.4,3.3c-0.8,1.5-8,18-37.3,26.8
		c0,0-3.4,1-7.5,1.1c-4,0-36.2,0-36.2,0s-58.6,5-63,5s-59.5,0-59.5,0s-3.6,0-8.6-0.4c-5-0.4-56.8-4.6-56.8-4.6s-1.5,0-2.5,0
		c-0.8,0-35.2,0-35.2,0s-9.4,0.8-24.5-8c0,0-15.5-7.8-20.9-20.1c0,0-0.6-1.3-0.2-3.3c0.2-1.7,1.3-7.5,1.3-9c0,0,0-1.3-0.4-3.3
		C245.7,354.3,242.6,333.2,242.6,327.3z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M272.8,261.7l12.4,27
		c0,0,20.7,0.6,47.8,18.8H509c0,0,24.9-17.2,47.8-18.8l12.4-27l-7.3-1c0,0-141.2-22.4-282.6,0L272.8,261.7z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M279.5,260.7
		c0,0,17.4-45.2,33.5-68.9c0,0,49-11.7,107.7-11.7c58.7,0,108.9,11.7,108.9,11.7s18.2,28.9,33.5,68.9c0,0-66.2-9.9-142-9.9
		C344.8,250.8,279.5,260.7,279.5,260.7z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M521.4,324.6
		c0,0-7.3,0.2-10.1-9c0,0-2.3-7.1,5-12.6c0,0,26.2-17.2,51.7-14.2c0,0,16.6,1.3,18.2,14.2c0,0,1.3,12.1-6.7,16.8c0,0-5.9,4-18.8,3.8
		c-13-0.2-21.4-0.4-25.3,0.2L521.4,324.6z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M259.1,316.2
		c0,0,4.2,8.4,23.7,7.5l12.6-0.2c0,0,5.5-0.4,17.8,0.8c0,0,9.2,0.8,11.3-0.6c2.1-1.3,5.4-3.1,6.7-10.3c0,0,1.1-6.9-7.7-11.7
		c-8.8-4.8-23.3-12.8-43.1-13.4c0,0-12.4-1.3-20.3,5.9C251.6,302.6,259.1,316.2,259.1,316.2z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Convertible2DrFront);
