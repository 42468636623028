import React, { Component } from 'react';
import SideMenu from '../sideMenu/index';
import { connect } from 'react-redux';
import Accordion from '../sideMenu/Accordion';
class Layout extends Component {
  render() {
    const { showSideBar } = this.props;
    return (
      <div className="container-fluid h-screen bg-light">
        <div
          className={`${showSideBar ? 'h-100 ' : ''}row form-layout-container relative`}
        >
          <div
            className={`${showSideBar ? 'sidebar-height ' : ''}col-lg-2 pr-3  bg-white p-0`}
          >
            <SideMenu />
          </div>
          <div
            className={`col-md-10 p-0 h-100 w-100 ${showSideBar ? 'hide-form' : ''}`}
          >
            <div className="h-100">{this.props.children}</div>
          </div>
          {showSideBar ? (
            <div className="tablet-hamberger-menu">
              <Accordion />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { showSideBar } = state.core;
  return { showSideBar };
};

export default connect(mapStateToProps)(Layout);
