import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback2DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M268.7,348.5v67.8c0,2,1.7,3.7,3.4,3.7h30.3c2,0,3.4-1.7,3.4-3.7v-32.6 M573.8,348.5v67.8
	c0,2-1.7,3.7-3.4,3.7h-30.3c-1.8,0-3.4-1.7-3.4-3.7v-32.6"
          />
          <path
            fill="#656565"
            d="M573.8,348.5v67.8c0,2-1.7,3.7-3.4,3.7h-30.3c-1.8,0-3.4-1.7-3.4-3.7v-32.6 M268.7,348.5v67.8
	c0,2,1.7,3.7,3.4,3.7h30.3c2,0,3.4-1.7,3.4-3.7v-32.6"
          />
          <path
            fill="#FFFFFF"
            d="M250.9,251.7c3.1,0.8,19.7,2.8,21,3.2c0.3,0.2,0.8,0.3,1.4,0.5v0.2c-3.2,8.1-6.3,16.3-6.9,26.7
	c-0.8,10.3-1.2,15.1-0.3,25.2c0.9,8.9,2.6,36,3.2,41c2.9,20.9,9.2,32.6,17.7,35.8c3.2,1.4,5.2,0.6,7.1,0.2c5.7-0.8,45-4.1,59.9-4.1
	c15.1,0,67.1-0.6,67.1-0.6h0.8c0,0,52.1,0.6,67.1,0.6c14.9,0,54.2,3.4,59.9,4.1c1.8,0.3,3.8,1.2,7.1-0.2
	c8.3-3.2,14.7-14.9,17.7-35.8c0.8-5.1,2.3-32.1,3.1-41c1.1-10.1,0.6-14.9-0.2-25.2c-0.8-10.3-3.7-18.4-6.9-26.7l-0.2-0.2
	c0.3-0.2,0.8-0.3,0.9-0.3c1.4-0.6,18-2.6,21-3.2c3.1-0.8,3.1-3.2,3.1-5.1c0-2-2.8-8.8-4.1-10.6c-1.7-2.1-5.2-2.3-9.2-3.2
	c-2-0.3-10.6-0.8-15.2-0.6c-4.8,0.2-4.6,4.6-4.9,7.4c-0.2,1.4-2,2.2-2,2.2l-0.9-0.2c-9.2-32.1-26.6-62.1-32.1-70.7
	c-1.1-1.8-4.3-2.9-4.3-2.9c-16-4.1-83.6-6.6-98-6.6h-4.9c-14.4,0-82,2.5-98,6.6c0,0-3.2,1.1-4.3,2.9c-5.7,8.6-22.9,38.6-32.1,70.7
	l-0.6,0.2c0,0-1.8-0.8-2-2.1c-0.2-2.9-0.2-7.2-4.8-7.4c-4.8-0.2-13.2,0.2-15.4,0.6c-4,0.9-7.7,1.1-9.2,3.2
	c-1.2,1.8-4.1,8.6-4.1,10.6C247.7,248.5,247.8,250.9,250.9,251.7L250.9,251.7z"
          />
          <path
            fill="#EBEBEB"
            d="M554,283.8c-0.8,7.1-2.6,18-2.6,23c0,4-1.4,5.1-6,5.1h-49c0,0-4.1-0.6-2.9-3.1c2-4.1,4.8-8.3,6.8-11.2
	c2-2.9,9.4-7.8,14.7-9.2c9.8-2.8,26.9-5.2,34.6-6C552.1,282.1,554.1,281.8,554,283.8L554,283.8z M296.2,251.7
	c3.8-0.9,120-1.2,124.7-1.2h0.2c4.8,0,120.9,0.3,124.7,1.2c5.1-0.3,2-9.2,2-9.2c-8-23.7-15.8-43.2-23.2-57c-1.2-2.8-3.1-3.7-5.8-4.3
	c-3.8-0.9-93.1-2.3-97.9-2.3h0.2c-4.8,0-94,1.4-97.9,2.3c-2.8,0.8-4.6,1.7-5.8,4.3c-7.4,13.8-15.2,33.3-23.2,57
	C294.2,242.5,291.1,251.2,296.2,251.7L296.2,251.7z M288.1,283.8c0.8,7.1,2.6,18,2.6,23c0,4,1.4,5.1,6,5.1h49c2.8,0,4.1-0.6,2.9-3.1
	c-1.8-4.1-4.8-8.3-6.8-11.2c-2-2.9-9.4-7.8-14.8-9.2c-9.8-2.8-26.9-5.2-34.6-6C289.7,282.1,287.7,281.8,288.1,283.8L288.1,283.8z"
          />
          <path
            fill="#EBEBEB"
            d="M498.1,300.9c-3.4-1.1-7.2-2.1-11.4-2.8c-0.8,0-1.2-0.2-1.8-0.2c-25.7-2.3-59.6-3.4-63.8-3.4
	c-4.3,0-38.3,1.1-63.9,3.4c-0.6,0-1.1,0.2-1.8,0.2h-0.8c-2.8,0.3-1.7,1.8-1.7,1.8s6,10.9,7.1,13.2c1.1,2.3,3.8,3.8,6,4
	c12.1,1.2,46.2,1.8,54.2,1.8h1.2c8,0,42.1-0.6,54.2-1.8c2.2-0.2,4.9-1.7,6-4c1.1-2.3,7.1-13.2,7.1-13.2s1.1-1.4-1.7-1.8
	 M479.3,345.7c0,0,4.1-0.2,5.7,1.2c1.4,1.4,5.2,7.7,6.6,9.4c1.1,1.7,1.4,4-1.2,4H352c-2.8,0-2.3-2.3-1.1-4c1.1-1.8,4.9-8,6.3-9.4
	s5.7-1.2,5.7-1.2H479.3 M536.3,355.6c4.6,0,8.3-3.7,8.3-8.1c0-4.8-3.8-8.3-8.3-8.3c-4.6,0-8.3,3.7-8.3,8.3
	C528,351.9,531.9,355.6,536.3,355.6z M305.6,355.6c-4.6,0-8.3-3.7-8.3-8.1c0-4.8,3.8-8.3,8.3-8.3c4.6,0,8.3,3.7,8.3,8.3
	C314,351.9,310.2,355.6,305.6,355.6L305.6,355.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M286.2,241.3l-2.9,0.6c0,0-1.8-0.8-2-2.1c-0.2-2.9-0.2-7.2-4.8-7.4
	c-4.8-0.2-13.2,0.2-15.4,0.6c-4,0.9-7.7,1.1-9.2,3.2c-1.2,1.8-4.1,8.6-4.1,10.6c0,1.8,0.2,4.3,3.2,5.1c3.1,0.8,19.7,2.8,21,3.2
	c1.4,0.6,6.3,2,7.4,3.2c0,0,1.8,1.7,2,0.3C283.3,247.1,287.3,240.8,286.2,241.3 M268.7,348.5v67.8c0,2,1.7,3.7,3.4,3.7h30.3
	c2,0,3.4-1.7,3.4-3.7v-32.6 M573.8,348.5v67.8c0,2-1.7,3.7-3.4,3.7h-30.3c-1.8,0-3.4-1.7-3.4-3.7v-32.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M569.7,255.5c3.2,8.1,6.1,16.3,6.9,26.7c0.8,10.3,1.2,15.1,0.2,25.2
	c-0.8,8.9-2.3,36-3.1,41c-2.9,20.9-9.2,32.6-17.7,35.8c-3.2,1.4-5.2,0.6-7.1,0.2c-5.7-0.8-45-4.1-59.9-4.1
	c-15.1,0-67.1-0.6-67.1-0.6h-0.8c0,0-52.1,0.6-67.1,0.6c-14.9,0-54.2,3.4-59.9,4.1c-1.8,0.3-3.8,1.2-7.1-0.2
	c-8.3-3.2-14.8-14.9-17.7-35.8c-0.8-5.1-2.3-32.1-3.2-41c-0.9-10.1-0.3-14.9,0.3-25.2c0.6-10.3,3.7-18.4,6.9-26.7l10.8-13.8
	c9.2-32.1,26.6-62.1,32.1-70.7c1.1-1.8,4.3-2.9,4.3-2.9c17.1-4.3,92.8-6.9,100.2-6.6h0.6c7.4-0.3,83.1,2.1,100.2,6.6
	c0,0,3.2,1.1,4.3,2.9c5.7,8.6,22.9,38.6,32.1,70.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M556.1,241.3l2.8,0.6c0,0,1.8-0.8,2-2.1c0.3-2.9,0.2-7.2,4.9-7.4
	c4.8-0.2,13.2,0.2,15.2,0.6c4,0.9,7.7,1.1,9.2,3.2c1.4,1.8,4.1,8.6,4.1,10.6c0,1.8,0,4.3-3.1,5.1c-3.1,0.8-19.7,2.8-21,3.2
	c-1.4,0.6-6.3,2-7.7,3.2c0,0-1.7,1.7-1.8,0.3C558.9,247.1,554.9,240.8,556.1,241.3 M421.1,345.7h44.4v-19.7h-89.1v19.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.1,345.7h44.4v-19.7h-89.1v19.7H421.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M324.5,180.9c0.9-1.8,4-8.8,6.9-14.6 M316.5,186.9c0,0,5.8-12.9,9.4-19.8
	 M286.7,241.1c7.8-22.6,26.6-61.9,26.7-65.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M299.6,252c-3.1,0.2-6.1,0.9-7.1,1.4c-0.9,0.3-2,2.9-2.6,4.6
	c-0.8,1.8-4.9,8.3-6.1,9.2 M280.5,258.9c0,0,1.4,4,3.1,9.2 M283.6,268.1c1.8,5.2,3.7,11.7,4.3,15.7 M292.8,254
	c9.2,4.6,41.2,21.2,59,44.9 M288.1,283.8c0.8,7.1,2.6,18,2.6,23c0,4,1.4,5.1,6,5.1h49c2.8,0,4.1-0.6,2.9-3.1
	c-1.8-4.1-4.8-8.3-6.8-11.2c-2-2.9-9.4-7.8-14.8-9.2c-9.8-2.8-26.9-5.2-34.6-6C289.7,282.1,287.7,281.8,288.1,283.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M344,300.9c3.4-1.1,7.2-2.1,11.4-2.8 M288.1,283.8
	c0.8,7.1,2.6,18,2.6,23c0,4,1.4,5.1,6,5.1h49c2.8,0,4.1-0.6,2.9-3.1c-1.8-4.1-4.8-8.3-6.8-11.2c-2-2.9-9.4-7.8-14.8-9.2
	c-9.8-2.8-26.9-5.2-34.6-6C289.7,282.1,287.7,281.8,288.1,283.8L288.1,283.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M470,345.4v-20.6c0-0.3-0.2-0.6-0.3-0.6H373c-0.3,0-0.6,0.2-0.6,0.6v20.6
	 M421.1,345.9V360 M373.5,345.9v14.6 M286.2,241.3l-2.9,0.6c0,0-1.8-0.8-2-2.1c-0.2-2.9-0.2-7.2-4.8-7.4c-4.8-0.2-13.2,0.2-15.4,0.6
	c-4,0.9-7.7,1.1-9.2,3.2c-1.2,1.8-4.1,8.6-4.1,10.6c0,1.8,0.2,4.3,3.2,5.1c3.1,0.8,19.7,2.8,21,3.2c1.4,0.6,6.3,2,7.4,3.2
	c0,0,1.8,1.7,2,0.3C283.3,247.1,287.3,240.8,286.2,241.3 M290.4,304.7h-14.1c0,0-2.3,0.3-3.1,1.2c-0.8,0.9-3.7,4.1-4,4.9
	c-1.1,1.4-2.9,4.1-2.6,6 M421.4,363.9h-76.2c-9.7-0.2-65-1.1-71.7-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.3,241.9c-0.9,0.2-1.4,0.3-2,0.8c-0.3,0.3-1.1,1.1-2,1.7
	c-0.9,0.6-1.2,1.7-1.7,2.6c-0.3,0.8-1.1,2.1-1.2,2.8c-0.2,0.6-0.6,1.2-1.8,1.2h-7.4c0,0-1.4,0-1.4,1.1c0,0.9,0,1.8,1.2,2.1
	 M317.2,322.8c1.4,0,2.6-1.1,2.6-2.6V317c0-1.2-1.1-2.3-2.6-2.3h-6.6c-1.2,0-2.3,1.1-2.3,2.3v3.2c0,1.4,1.1,2.6,2.3,2.6L317.2,322.8
	 M421.1,345.9V360"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M558,241.7c-9.2-32.1-26.6-62.1-32.1-70.7c-1.1-1.8-4.3-2.9-4.3-2.9
	c-17.1-4.3-92.8-6.9-100.2-6.6h-0.6c-7.4-0.3-83.1,2.1-100.2,6.6c0,0-3.2,1.1-4.3,2.9c-5.7,8.6-22.9,38.6-32.1,70.7 M555.2,241.1
	c-10.4-29.5-26.4-61.9-26.7-65.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M296.2,251.7c-5.1-0.3-2-9.2-2-9.2c8-23.7,15.8-43.2,23.2-57
	c1.2-2.8,3.1-3.7,5.8-4.3c3.8-0.9,93.1-2.3,97.9-2.3h-0.2c4.8,0,94,1.4,97.9,2.3c2.8,0.8,4.6,1.7,5.8,4.3
	c7.4,13.8,15.2,33.3,23.2,57c0,0,3.1,8.9-2,9.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M545.8,251.7c-3.8-0.9-120-1.2-124.7-1.2h-0.2c-4.8,0-120.9,0.3-124.7,1.2
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M549.1,254c-9.2,4.6-41.2,21.2-59,44.9 M517.4,180.9
	c-0.9-1.8-4-8.8-6.9-14.6 M525.4,186.9c0,0-5.7-12.9-9.4-19.8 M554,283.8c-0.8,7.1-2.6,18-2.6,23c0,4-1.4,5.1-6,5.1h-49
	c0,0-4.1-0.6-2.9-3.1c2-4.1,4.8-8.3,6.8-11.2c2-2.9,9.4-7.8,14.7-9.2c9.8-2.8,26.9-5.2,34.6-6C552.1,282.1,554.1,281.8,554,283.8"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M490.2,354.8H351.7 M487.9,351.4H354 M479.3,345.7H362.6
	c0,0-4.1-0.2-5.7,1.2c-1.4,1.4-5.2,7.7-6.3,9.4c-1.2,1.7-1.7,4,1.1,4h69.3 M421.1,360.5h69.1c2.8,0,2.3-2.3,1.2-4
	c-1.2-1.8-5.1-8-6.6-9.4c-1.4-1.4-5.7-1.2-5.7-1.2 M487.3,298.1c2.8,0.3,1.7,1.8,1.7,1.8s-6,10.9-7.1,13.2c-1.1,2.3-3.8,3.8-6,4
	c-12.1,1.2-46.2,1.8-54.2,1.8h-1.2c-8,0-42.1-0.6-54.2-1.8c-2.1-0.2-4.9-1.7-6-4c-1.1-2.3-7.1-13.2-7.1-13.2s-1.1-1.4,1.7-1.8
	 M498.1,300.9c-3.4-1.1-7.2-2.1-11.4-2.8c-0.8,0-1.2-0.2-1.8-0.2c-25.7-2.3-59.6-3.4-63.8-3.4c-4.3,0-38.3,1.1-63.9,3.4
	c-0.6,0-1.1,0.2-1.8,0.2 M554,283.8c-0.8,7.1-2.6,18-2.6,23c0,4-1.4,5.1-6,5.1h-49c0,0-4.1-0.6-2.9-3.1c2-4.1,4.8-8.3,6.8-11.2
	c2-2.9,9.4-7.8,14.7-9.2c9.8-2.8,26.9-5.2,34.6-6C552.1,282.1,554.1,281.8,554,283.8L554,283.8z M288.1,283.8c0.8,7.1,2.6,18,2.6,23
	c0,4,1.4,5.1,6,5.1h49c2.8,0,4.1-0.6,2.9-3.1c-1.8-4.1-4.8-8.3-6.8-11.2c-2-2.9-9.4-7.8-14.8-9.2c-9.8-2.8-26.9-5.2-34.6-6
	C289.7,282.1,287.7,281.8,288.1,283.8L288.1,283.8z M554,283.8c-0.8,7.1-2.6,18-2.6,23c0,4-1.4,5.1-6,5.1h-49c0,0-4.1-0.6-2.9-3.1
	c2-4.1,4.8-8.3,6.8-11.2c2-2.9,9.4-7.8,14.7-9.2c9.8-2.8,26.9-5.2,34.6-6C552.1,282.1,554.1,281.8,554,283.8L554,283.8z
	 M288.1,283.8c0.8,7.1,2.6,18,2.6,23c0,4,1.4,5.1,6,5.1h49c2.8,0,4.1-0.6,2.9-3.1c-1.8-4.1-4.8-8.3-6.8-11.2
	c-2-2.9-9.4-7.8-14.8-9.2c-9.8-2.8-26.9-5.2-34.6-6C289.7,282.1,287.7,281.8,288.1,283.8L288.1,283.8z M288.1,283.8
	c0.8,7.1,2.6,18,2.6,23c0,4,1.4,5.1,6,5.1h49c2.8,0,4.1-0.6,2.9-3.1c-1.8-4.1-4.8-8.3-6.8-11.2c-2-2.9-9.4-7.8-14.8-9.2
	c-9.8-2.8-26.9-5.2-34.6-6C289.7,282.1,287.7,281.8,288.1,283.8L288.1,283.8z M554,283.8c-0.8,7.1-2.6,18-2.6,23c0,4-1.4,5.1-6,5.1
	h-49c0,0-4.1-0.6-2.9-3.1c2-4.1,4.8-8.3,6.8-11.2c2-2.9,9.4-7.8,14.7-9.2c9.8-2.8,26.9-5.2,34.6-6
	C552.1,282.1,554.1,281.8,554,283.8L554,283.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.1,345.9V360 M468.4,345.9v14.6 M491.5,367.9c3.2,0,4.1-0.2,5.1,0.9
	s0.8,0.6,2,2.6c1.2,1.4,0.2,4.3-2.6,4.3H345.8c-2.8,0-2.9-2.8-1.7-4.1c2-2.3,1.4-1.7,2.3-2.8c0.9-1.1,1.7-0.9,5.1-0.9L491.5,367.9
	 M420.5,363.9h76.2c9.7-0.2,65-1.1,71.7-1.8 M558.3,307.5c-0.9,16.3-0.3,35-0.8,44.9 M524.8,322.8c-1.4,0-2.6-1.1-2.6-2.6V317
	c0-1.2,1.1-2.3,2.6-2.3h6.5c1.2,0,2.3,1.1,2.3,2.3v3.2c0,1.4-1.1,2.6-2.3,2.6L524.8,322.8 M421.1,345.9V360"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M569.7,255.5c3.2,8.1,6.1,16.3,6.9,26.7c0.8,10.3,1.2,15.1,0.2,25.2
	c-0.8,8.9-2.3,36-3.1,41c-2.9,20.9-9.2,32.6-17.7,35.8c-3.2,1.4-5.2,0.6-7.1,0.2c-5.7-0.8-45-4.1-59.9-4.1
	c-15.1,0-67.1-0.6-67.1-0.6h-0.8c0,0-52.1,0.6-67.1,0.6c-14.9,0-54.2,3.4-59.9,4.1c-1.8,0.3-3.8,1.2-7.1-0.2
	c-8.3-3.2-14.8-14.9-17.7-35.8c-0.8-5.1-2.3-32.1-3.2-41c-0.9-10.1-0.3-14.9,0.3-25.2c0.6-10.3,3.7-18.4,6.9-26.7 M573.8,348.5v67.8
	c0,2-1.7,3.7-3.4,3.7h-30.3c-1.8,0-3.4-1.7-3.4-3.7v-32.6 M268.7,348.5v67.8c0,2,1.7,3.7,3.4,3.7h30.3c2,0,3.4-1.7,3.4-3.7v-32.6
	 M367.5,297.5l-12.3,6.3 M487,303l-29.2,14.9 M462.9,296.6l-43.2,22.1 M389.6,295.8L358.9,311 M411,294.9l-44.9,22.3 M356.6,306.5
	l22.3,11.2 M358.6,298.1l40.1,20.3 M375,297l43.2,21.7 M391.9,295.9l44.9,22.7 M409.3,295.5l44.9,22.9 M468.7,297.2l16.9,8.3
	 M447.1,295.8l34.7,16.9 M426.9,294.9l44.2,22.3 M429.4,294.9l-46.5,23 M446.4,295.5l-45,23 M479.2,297.6l-41.2,21"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M407.6,305.9c-0.2,3.7,8.3,4.9,12.9,4.9c5.8,0,13.4-1.7,13.4-5.1
	c-0.2-3.7-7.8-5.1-12.8-5.1C416.3,300.7,407.9,301.9,407.6,305.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M407.6,305.9c-0.2,3.7,8.3,4.9,12.9,4.9c5.8,0,13.4-1.7,13.4-5.1
	c-0.2-3.7-7.8-5.1-12.8-5.1C416.3,300.7,407.9,301.9,407.6,305.9L407.6,305.9z M274.7,303.8c0,8.6,1.4,29.6,4.1,47.3
	c0,0,1.7,4.1-6.5,10.6 M274.7,303v-23.4c0-6.3-7.8-0.3-8.6,5.7 M496.1,338l53.8-0.2 M545.4,329c0,0,4.9-0.3,4.9,2.3
	c0,3.8,0.2,13.4,0.2,16.1s-2.8,8.3-7.8,8.3c-5.1,0-34.6,0.3-34.6,0.3s-2-0.8-3.2-3.2c-1.4-2.6-7.4-11.4-10-15.7
	c-1.4-2-2.8-3.4-2.6-4.3c0.3-0.8,0.6-3.2,3.7-3.8 M496.1,329.1l49.3-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M536.3,355.6c4.6,0,8.3-3.7,8.3-8.1c0-4.8-3.8-8.3-8.3-8.3
	c-4.6,0-8.3,3.7-8.3,8.3C528,351.9,531.9,355.6,536.3,355.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M536.3,355.6c4.6,0,8.3-3.7,8.3-8.1c0-4.8-3.8-8.3-8.3-8.3
	c-4.6,0-8.3,3.7-8.3,8.3C528,351.9,531.9,355.6,536.3,355.6z M524.8,339c-1.1,1.8-1.7,4.1-1.7,6.6c0,3.7,1.4,7.1,3.8,9.4
	 M503.3,349.9l19.7-0.2 M499.9,344.5h23 M346,338l-53.8-0.2 M296.7,329c0,0-5.1-0.3-5.1,2.3c0,3.8-0.2,13.4-0.2,16.1
	s2.8,8.3,7.8,8.3c5.1,0,34.6,0.3,34.6,0.3s2-0.8,3.2-3.2c1.4-2.6,7.4-11.4,10.1-15.7c1.2-2,2.6-3.4,2.3-4.3
	c-0.3-0.8-0.6-3.2-3.7-3.8 M346,329.1l-49.2-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M305.6,355.6c-4.6,0-8.3-3.7-8.3-8.1c0-4.8,3.8-8.3,8.3-8.3
	c4.6,0,8.3,3.7,8.3,8.3C314,351.9,310.2,355.6,305.6,355.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M305.6,355.6c-4.6,0-8.3-3.7-8.3-8.1c0-4.8,3.8-8.3,8.3-8.3
	c4.6,0,8.3,3.7,8.3,8.3C314,351.9,310.2,355.6,305.6,355.6L305.6,355.6z M281.8,258.8c0.2-5.4,8.1-13.7,8.1-13.7l0.2-0.2
	c0.8-0.9,3.2-0.9,3.2-0.9 M317.2,339c1.1,1.8,1.7,4.1,1.7,6.6c0,3.7-1.4,7.1-3.8,9.4 M338.6,349.9l-19.7-0.2 M342.1,344.5h-23
	 M542.5,252c3.1,0.2,6.1,0.9,7.1,1.4c0.9,0.3,2,2.9,2.8,4.6c0.8,1.8,4.9,8.3,6.1,9.2 M561.8,258.9c0,0-1.4,4-3.2,9.2 M558.4,268.1
	c-1.7,5.2-3.7,11.7-4.1,15.7 M279.1,275.7c0.9,3.1,1.7,6,2,8.1h0.3c0.8,7.1,2.6,18,2.6,23 M284.2,307.5c0.9,16.3,0.3,35,0.9,44.9
	 M563.8,274.4c-1.1,3.4-2,6.9-2.3,9.4h-0.3c-0.8,7.1-2.6,18-2.6,23"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M556.1,241.3l2.8,0.6c0,0,1.8-0.8,2-2.1c0.3-2.9,0.2-7.2,4.9-7.4
	c4.8-0.2,13.2,0.2,15.2,0.6c4,0.9,7.7,1.1,9.2,3.2c1.4,1.8,4.1,8.6,4.1,10.6c0,1.8,0,4.3-3.1,5.1c-3.1,0.8-19.7,2.8-21,3.2
	c-1.4,0.6-6.3,2-7.7,3.2c0,0-1.7,1.7-1.8,0.3C558.9,247.1,554.9,240.8,556.1,241.3 M551.8,304.7h14.3c0,0,2.2,0.3,2.9,1.2
	c0.8,0.9,3.7,4.1,4.1,4.9c1.1,1.4,2.9,4.1,2.6,6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M560.4,258.8c-0.2-5.4-8.1-13.7-8.1-13.7l-0.3-0.2
	c-0.6-0.9-3.2-0.9-3.2-0.9 M567.7,303.8c0,8.6-1.4,29.6-4.1,47.3c0,0-1.7,4.1,6.3,10.6 M567.4,303v-23.4c0-6.3,8-0.3,8.6,5.7
	 M558.9,241.9c0.9,0.2,1.7,0.3,2,0.8c0.6,0.3,1.2,1.1,2.2,1.7c0.9,0.6,1.2,1.7,1.7,2.6c0.3,0.8,0.9,2.1,1.2,2.8
	c0.2,0.6,0.6,1.2,1.8,1.2h7.4c0,0,1.4,0,1.4,1.1c0,0.9,0,1.8-1.2,2.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M283.4,267.5c0,0,2.9-2.6,6.5-9.2
		c0,0,1.5-4.5,2.6-4.9c1.1-0.5,3.8-1.4,15.1-2.3c0,0,103.9-0.9,113.4-0.8c0,0,92.6-0.2,115.7,0.9c0,0,10.3,0.8,13.1,2.2
		c0,0,0.9,0.5,2.8,4.6c0,0,4.5,8.1,6.5,9.5c0,0-2.6,8-4.5,15.7l-0.3,0.9c0,0,0.3-1.8-1.2-2c-1.7,0-24.4,2.3-38.7,6.5
		c0,0-7.7,2.6-13.2,8.1l-2.8,4c0,0-5.1-1.7-10.8-2.8c0,0-49.3-4.1-66.2-3.7c0,0-28.1,0.5-66.5,3.7c0,0-6.1,1.2-10.8,2.8
		c0,0-1.7-2.9-2.6-3.8s-4.1-4.6-12.6-8.1c0,0-6.1-2.6-33.2-6.1c0,0-4.6-0.5-5.5-0.5c0,0-2.1-0.2-2,1.5
		C288.1,283.8,287.4,279.2,283.4,267.5z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M353,299.9c0,0,4.5,8,6.9,12.8c0,0,1.5,4.1,6.6,4.5
		c0,0,30.9,1.8,54.5,1.8c0,0,25.7,0,50.5-1.5l4.5-0.3c0,0,4.1-0.3,6-4.5l6.9-12.8c0,0,1.2-1.2-1.8-1.8l-23.4-1.8
		c0,0-32.1-2.2-42.7-1.8c0,0-19.5,0.5-38.7,1.7l-25.2,1.8l-2.8,0.3C354.3,298.3,352.1,298.3,353,299.9z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M266.7,315.3
		c0,0,1.1-2.9,3.4-5.7c2.3-2.8,3.1-4,3.8-4.3s2.3-0.6,2.3-0.6h14.1l0.2,1.8c0,0-0.9,5.8,5.4,5.4h50.2c0,0,4,0.3,2.5-2.8
		c-1.4-2.9,0-0.2,0-0.2s-1.7-4-4.6-8.3c0,0,6.3-2,9.5-2.5c0,0-1.1,0.3-0.5,1.5l6.5,12c0,0,1.2,3.1,3.2,4.1c0,0,1.5,1.2,4.6,1.2
		c0,0,16.7,1.2,30.6,1.4l23.2,0.3c0,0,17.4-0.2,26.7-0.5c0,0,19.7-0.6,23.8-1.1l4-0.3c0,0,4.1-0.2,6.1-3.8c2-3.8,0-0.2,0-0.2
		l7.1-13.2c0,0,0.8-0.9-0.6-1.5l2.2,0.5c0,0,5.4,1.2,7.5,2c0,0-2.9,4.6-4.6,8c0,0-1.7,2.6,1.4,3.1l1.2,0.2h49.5c0,0,4.3,0.3,5.1-1.8
		c0,0,0.8-0.6,0.6-4v-1.4h14h0.6c0,0,2.2,0.3,3.1,1.4l2.9,3.5c0,0,2.3,2.3,3.4,5.4l0.5,1.4l-2,27.7c0,0-0.2,9.2-4.6,22.6
		c0,0-5.4,17.4-16.4,18.4c0,0-1.8,0.2-3.8-0.5c0,0-14.4-1.5-29.3-2.6c0,0-12.8-0.9-26.9-1.5l-71.9-0.6l-70.7,0.6c0,0-7.1,0-25.2,1.4
		c0,0-16.3,1.1-30.7,2.6c0,0-0.3,0-1.7,0.3c-1.4,0.3-3.2,0.8-5.4-0.2s-5.8-2.2-10.4-9.7c0,0-2.6-4.3-4.9-12.4c0,0-2.5-8.1-3.2-17.7
		L266.7,315.3z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M290.5,305.8
		c0,0-0.2-3.2-1.2-12.1l-1.1-8.9c0,0-0.8-2.6,1.8-2.5c2.8,0.2,22.3,2.3,36.7,6c0,0,7.5,1.8,14.6,8.8l1.7,2.6c0,0,4.5,6.8,5.5,9.5
		c0,0,1.2,2.5-1.2,2.8h-1.1H296c0,0-2.9,0.3-4.3-1.4c0,0-1.2-1.7-1.1-4L290.5,305.8z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M551.7,303.5
		l2.2-18l0.2-2.2c0,0-0.2-1.2-2-1.1c-1.8,0.2-12.8,1.2-19.7,2.5c0,0-8.4,1.2-16.6,3.4c0,0-4.5,0.9-10.4,5.1c0,0-3.7,2.6-4.9,4.3
		c0,0-3.1,4.3-4.3,6.5l-2.6,4.8c0,0-1.1,1.8,0.2,2.6c0,0,0.5,0.6,3.2,0.6h49.5c0,0,3.1,0,4.3-1.5c0,0,0.8-0.9,0.8-3.1l0.2-2.8
		L551.7,303.5z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M297.9,251.6
		c0,0-2.8,0.6-3.8-0.9c0,0-1.5-1.5-0.3-6.5l1.1-3.5c0,0,6.3-18.7,13.4-35.5c0,0,4.6-11.2,8.1-17.5c0,0,2-6.3,7.2-6.6
		c0,0,41.9-2,97.6-2.3c0,0,62.8,0.8,96.5,2.2c0,0,3.4,0.2,5.5,2.3c0,0,1.1,0.8,2.5,3.7c0,0,12.3,24.1,21.2,52.2l0.9,2.6
		c0,0,1.5,3.7,1.1,7.1c0,0,0,2.6-2.9,3.1l-6.1-0.5c0,0-68.4-0.9-118.8-0.9S297.9,251.6,297.9,251.6z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback2DrFront);
