import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback3DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M430.3,193.4h-18.4c-23.1,0.3-44.6,1.4-64.2,3.3c-1.5,0.3-2.3,0.6-2.5,1.1c-1.4,1.5-2.9,4.8-4.7,9.9
	c12.4-1.2,26.3-2.1,41.5-2.6c13.6-0.6,26.7-0.7,39.1-0.6c12.4-0.1,25.5,0.1,39.1,0.6c15.3,0.6,29.2,1.5,41.5,2.6
	c-1.8-5.1-3.3-8.4-4.7-9.9c-0.3-0.6-1.1-0.8-2.5-1.1C474.7,194.7,453.4,193.6,430.3,193.4L430.3,193.4z"
          />
          <path
            fill="#FFFFFF"
            d="M421.1,204.4c-12.4-0.1-25.5,0.1-39.1,0.6c-15.3,0.6-29.2,1.5-41.5,2.6v1.8c12.4-1.2,26.3-2.1,41.5-2.6
	c13.6-0.6,26.7-0.7,39.1-0.6c12.4-0.1,25.5,0,39.1,0.6c15.3,0.6,29.2,1.5,41.5,2.6v-1.8c-12.4-1.2-26.3-2.1-41.5-2.6
	C446.5,204.5,433.5,204.2,421.1,204.4L421.1,204.4z"
          />
          <path
            fill="#656565"
            d="M421.1,206.3c-12.4-0.1-25.5,0-39.1,0.6c-15.3,0.6-29.2,1.5-41.5,2.6V209l-3.3,0.6L318.3,259l3.6-0.7
	l11.4-0.5c-5.4-0.1-7.8-1.8-7.2-5c3.9-13.8,7.7-25.4,11.7-34.8c1.5-3.6,3.9-5.6,6.7-6.1c11.3-1,23.8-1.8,37.4-2.3
	c13.6-0.6,26.7-0.7,39.1-0.6c12.4-0.1,25.5,0,39.1,0.6c13.6,0.6,26.1,1.2,37.4,2.3c2.9,0.6,5.2,2.5,6.7,6.1
	c3.9,9.4,7.7,20.9,11.7,34.8c0.7,3.2-1.8,4.8-7.3,5l11.6,0.5l3.6,0.7L505,209.6l-3.3-0.6v0.4c-12.4-1.2-26.3-2.1-41.5-2.6
	C446.5,206.3,433.5,206.2,421.1,206.3L421.1,206.3z"
          />
          <path
            fill="#EBEBEB"
            d="M421.1,208.9c-12.4-0.1-25.5,0-39.1,0.6c-13.6,0.6-26.1,1.2-37.4,2.3c-2.9,0.6-5.2,2.5-6.7,6.1
	c-3.9,9.4-7.7,20.9-11.7,34.8c-0.7,3.2,1.8,4.8,7.2,5h0.1l20.8-1.1l22.1-0.8h86.4l24.9,0.8l20.8,1.1c5.5-0.1,8-1.8,7.3-5
	c-3.9-13.8-7.7-25.4-11.7-34.8c-1.5-3.6-3.9-5.6-6.7-6.1c-11.3-1-23.8-1.8-37.4-2.3C446.5,208.9,433.5,208.8,421.1,208.9
	L421.1,208.9z"
          />
          <path
            fill="#F2F2F2"
            d="M498.7,197.8l-1.6-0.1c1.4,1.5,2.9,4.8,4.7,9.9l3.4,0.6C502.5,201.1,500.3,197.6,498.7,197.8L498.7,197.8z"
          />
          <path
            fill="#FFFFFF"
            d="M499.2,197.4l-0.5,0.4c1.6-0.3,3.9,3.2,6.5,10.3c2.3-0.3,5.2,0,8.7,0.6l1,0.1c-1.1-3-3.2-5.8-6.1-8.3
	C506.9,199,503.8,197.9,499.2,197.4L499.2,197.4z"
          />
          <path
            fill="#656565"
            d="M501.7,207.7v1.4l3.3,0.6c-0.3-0.7-0.3-1.2,0.1-1.4L501.7,207.7L501.7,207.7z"
          />
          <path
            fill="#F2F2F2"
            d="M505.1,208.1c-0.4,0.3-0.4,0.7-0.1,1.4l18.9,49.4l10.6,1.9l-9.2-23.4l-11.4-28.9
	C510.4,208.1,507.5,207.9,505.1,208.1L505.1,208.1z"
          />
          <path
            fill="#EBEBEB"
            d="M514.8,209.2v-0.3l-1-0.3l11.3,28.9l6.2,7.2l-12.9-27.8"
          />
          <path
            fill="#FFFFFF"
            d="M518.3,216.9l12.9,27.9l2.9,6.3c0.8,1.1,2.1,1.4,3.9,1.2L518.3,216.9L518.3,216.9z M557.7,238.9h-3.2
	c-5.1,0.3-8,1.5-8.9,3.9l-3.2,7.7l-0.7,0.8c0.4,4,2.9,5.6,7.2,5.1l28.2-2.6l0.1-0.6c0.3-1.2,0.3-2.3,0.3-3.2c-1,0.4-2.8,0.6-5.4,0.6
	c-2.6,0.1-4-0.4-3.9-1.8c0.1-1.2,1-1.9,2.6-2.2l6.3-0.8l-0.1-0.4v-0.1c-1.1-3.3-3-5.2-5.8-5.6h-0.1
	C568.4,239,563.9,238.8,557.7,238.9L557.7,238.9z"
          />
          <path
            fill="#EBEBEB"
            d="M571.2,246.5c-1.7,0.3-2.6,1-2.6,2.2c-0.1,1.2,1.2,1.8,3.9,1.8s4.4-0.1,5.4-0.6c0.8-0.3,1.2-0.8,1.2-1.6
	v-1.4c-0.1-0.7-0.5-1.1-1.5-1.1L571.2,246.5L571.2,246.5z M263.3,246.9v1.4c0,0.8,0.4,1.4,1.2,1.6c1,0.4,2.8,0.6,5.4,0.6
	c2.6,0.1,4-0.4,3.9-1.8c-0.1-1.2-1-1.9-2.6-2.2l-6.3-0.8C263.8,245.8,263.3,246.1,263.3,246.9L263.3,246.9z"
          />
          <path
            fill="#FFFFFF"
            d="M264.8,245.2l-0.1,0.4l6.3,0.8c1.6,0.3,2.6,1,2.6,2.2c0.1,1.2-1.2,1.8-3.9,1.8s-4.4-0.1-5.4-0.6
	c0,1,0.1,1.9,0.4,3.2l0.1,0.6l28.2,2.6c4.4,0.7,6.7-1.1,7.2-5.1c-0.3-0.3-0.5-0.6-0.7-0.8l-3.2-7.7c-1-2.5-4.1-3.7-9.5-3.9h-2.6
	c-6.3-0.1-10.9,0.1-13.8,0.7h-0.1C267.8,240,265.9,241.9,264.8,245.2L264.8,245.2z M343.5,197.8l-0.5-0.4c-4.5,0.6-7.7,1.6-9.4,3.2
	c-2.9,2.5-5,5.2-6.1,8.3l1-0.1c3.4-0.6,6.3-0.8,8.7-0.6C339.6,201.1,341.8,197.6,343.5,197.8L343.5,197.8z"
          />
          <path
            fill="#F2F2F2"
            d="M345.1,197.6l-1.6,0.1c-1.7-0.3-3.9,3.2-6.5,10.3l3.4-0.6C342.3,202.6,343.8,199.3,345.1,197.6L345.1,197.6z
	"
          />
          <path
            fill="#656565"
            d="M314.7,192.5c-0.1,0-0.1,0-0.3,0.1c0,0-0.1,0.1-0.1,0.3v4l0.1,0.3l-0.1,36.5l0.5-1v-35.5l0.1-0.3v-4
	l-0.1-0.3L314.7,192.5L314.7,192.5z"
          />
          <path
            fill="#FFFFFF"
            d="M314.9,232.6l-0.5,1L304,252.2c1.8,0.3,3.2-0.1,3.9-1.2l2.9-6.3l12.9-27.9L314.9,232.6L314.9,232.6z"
          />
          <path
            fill="#EBEBEB"
            d="M310.8,244.7l6.3-7.2l11.3-28.9l-1,0.3l-0.1,0.3l-3.6,7.7"
          />
          <path
            fill="#F2F2F2"
            d="M317.1,237.5l-6.2,7.2L308,251c-0.8,1.1-2.1,1.4-3.9,1.2l-0.7-0.1c-1,2.9-2.1,4.5-3.2,5
	c-4,0.4-8.4,0.4-13.2,0c-1.5-0.1-2.6,0-3.2,0.1c-0.4,0.1-0.5,0.3-0.7,0.5l17.5,1c1.9,0.4,3.2,1.2,3.9,2.3c0.3,0.3,0.7,0.3,1.1,0.3
	c0.5,0,1.4-0.3,2.5-0.7L317.1,237.5L317.1,237.5z"
          />
          <path
            fill="#656565"
            d="M340.5,209.1v-1.4l-3.4,0.6c0.4,0.3,0.4,0.7,0.1,1.4L340.5,209.1L340.5,209.1z"
          />
          <path
            fill="#F2F2F2"
            d="M337.2,209.6c0.3-0.7,0.3-1.2-0.1-1.4c-2.3-0.3-5.2,0-8.7,0.6l-11.4,28.9l-9.2,23.4l10.6-1.9L337.2,209.6
	L337.2,209.6z"
          />
          <path
            fill="#FFFFFF"
            d="M333.6,257.6h-0.1l-11.4,0.5c12.7,6.7,24.6,16.8,35.8,30.3c-11.1-13.5-23.1-23.5-35.8-30.3l-3.6,0.7
	c-5,3.9-6.9,7.3-5.8,10.2c1.5,4.3,2.5,9.9,2.8,16.8c2.9,2.6,6.6,4.5,11.3,5.9c9.4,2.5,18.7,4.1,27.8,5l14.7,0.7l14.9,0.3
	c5.8,0,11.7,0,17.7-0.3h38.7c6.1,0.1,12,0.3,17.7,0.3l14.9-0.3l14.7-0.7c9.1-0.8,18.3-2.5,27.8-5c4.5-1.4,8.3-3.4,11.3-5.9
	c0.3-6.9,1.2-12.4,2.8-16.8c1.1-2.9-0.8-6.2-5.8-10.2l-3.6-0.7c-12.7,6.7-24.6,16.8-35.8,30.3c11.1-13.5,23.1-23.5,35.8-30.3
	l-11.6-0.5l-20.9-1.2l-24.9-0.8h-86.4l-22.3,0.8L333.6,257.6L333.6,257.6z"
          />
          <path
            fill="#EBEBEB"
            d="M327.1,293.5l-1.6-0.3c2.6,2.3,3.4,5.5,2.6,9.4l1.5-0.7C330,298.6,329.3,295.7,327.1,293.5L327.1,293.5z"
          />
          <path
            fill="#EBEBEB"
            d="M325.5,293.2l1.6,0.3l12.4,2.1l14.4,1.8c2.3,1.8,4.1,3.6,5.4,5.6c0.4,0.7-0.3,1-1.9,0.8l-17.9-1.2l-10-0.7
	l-1.5,0.7C328.9,298.7,328,295.6,325.5,293.2l-3.4-0.7l0.7,0.7c1.6,1.6,2.5,3.7,2.5,5.9c0,2.3-0.8,4.3-2.5,5.9
	c-1.5,1.5-3.2,2.3-5.2,2.5l9.8,2.2c4.3,0.8,8.1,1.4,11.4,1.8l-7.7-8.7l32.5,2.9l1.1-0.4l-9.6-8.1l-0.5-0.5c-9.1-0.8-18.3-2.5-27.8-5
	c-4.5-1.4-8.3-3.4-11.3-5.9l-14.9-2.3c2.5,1.6,6.7,3,12.8,4.1l-0.4,2.9l2.2,0.5l7,1.6L325.5,293.2L325.5,293.2z"
          />
          <path
            fill="#FFFFFF"
            d="M305.4,261.5c-0.5,0-1,0-1.1-0.3c-0.5-1.1-1.8-1.9-3.9-2.3c-1.5,0.4-2.8,3-3.9,7.6
	c-0.7,4.4-1.8,8.1-3.3,11.1l1.4-0.1c0.7-4.1,2.9-8.5,6.6-13.3C302.5,262.7,303.9,261.7,305.4,261.5L305.4,261.5z"
          />
          <path
            fill="#FFFFFF"
            d="M318.3,258.8l-10.6,1.9c-1,0.4-1.8,0.7-2.5,0.7c-1.6,0.3-3,1.1-4.1,2.6c-3.7,4.8-5.9,9.4-6.6,13.3
	c-1,2.5-1.9,4.1-2.9,5l-0.7,0.4c-1.1,3.6-2.8,6.6-5.1,9.1c0,5.1,0.1,10.9,0.4,17.6l11-7c-0.7-2.5-1-5.6-0.8-9.8
	c0.3-7,1.6-10.2,4-9.4l14.9,2.3c-0.3-6.9-1.2-12.4-2.8-16.8C311.4,266.1,313.4,262.8,318.3,258.8L318.3,258.8z"
          />
          <path
            fill="#F2F2F2"
            d="M303.3,252.1c-1.4-0.1-2.3-0.4-3-0.8c-0.4,4-2.9,5.6-7.2,5.1l-28.2-2.8c0.7,1.5,2.5,2.5,5.2,2.9l12.8,1.4
	c0.1-0.3,0.3-0.4,0.7-0.5c0.7-0.3,1.8-0.3,3.2-0.1c4.8,0.4,9.2,0.4,13.2,0C301.4,256.6,302.4,255,303.3,252.1L303.3,252.1z"
          />
          <path
            fill="#EBEBEB"
            d="M300.4,283.5c-0.3,0-0.7,0.1-1,0.3c0.4,1.1,1.4,2.1,3,3c0.7,0.4,1.4,0.8,2.1,1.1l5.5,1.7l2.6,0.7l0.4-2.9
	C307.3,286.5,303.1,285.1,300.4,283.5L300.4,283.5z"
          />
          <path
            fill="#FFFFFF"
            d="M299.6,283.7c0.3-0.1,0.5-0.3,1-0.3c-2.3-0.8-3.6,2.2-4,9.4c-0.1,4.1,0.1,7.3,0.8,9.8h3l-0.3-0.8
	c-0.7-2.3-1.2-5.2-1.4-8.9c-0.1-1.9-0.1-4-0.1-6.3C298.7,285,298.9,284.2,299.6,283.7L299.6,283.7z M291.2,282
	c0.1,0.3,0.3,0.4,0.5,0.4c1.1-0.8,2.1-2.5,2.9-5l-1.4,0.1c-1.4,0.3-2.1,1-2.1,1.9C291.1,280.7,291.1,281.5,291.2,282L291.2,282z"
          />
          <path
            fill="#EE302D"
            d="M291.8,282.4c-0.1,0-0.4-0.1-0.5-0.4l-0.3,0.8L291.8,282.4L291.8,282.4z"
          />
          <path
            fill="#EBEBEB"
            d="M298.6,292.7l2.2,1.6l1.4,1c-0.4-3.4-0.3-6.2,0.4-8.4c-1.6-1.1-2.6-2.1-3-3c-0.7,0.4-1,1.4-1,2.6
	C298.5,288.7,298.7,290.8,298.6,292.7L298.6,292.7z"
          />
          <path
            fill="#EBEBEB"
            d="M301,294.2l-2.2-1.6c0.3,3.7,0.7,6.6,1.4,8.9l0.3,0.8c0.7,2.5,2.3,4,5,4.5c6.9,1.8,14.3,3.3,21.9,4.5
	l11.4,1.8c0.8,0.3,1.1,0.1,0.7-0.7l-12.1-2.1c-7.3-1.4-14-2.9-20.2-4.4c-2.6-0.7-4.1-1.8-4.5-3.2L301,294.2L301,294.2z"
          />
          <path
            fill="#EBEBEB"
            d="M310.2,289.7l-5.5-1.6c-0.8-0.4-1.5-0.7-2.1-1.1c-0.7,2.2-0.8,5-0.4,8.4l4,2.9c0.3-1.2,0.7-2.3,1.1-3.4
	l0.4-1C308.4,291.9,309.2,290.5,310.2,289.7L310.2,289.7z"
          />
          <path
            fill="#EBEBEB"
            d="M312.8,290.4l-2.6-0.7c-1,1-1.8,2.3-2.5,4.1l-0.4,1c-0.4,1-0.7,2.2-1.1,3.4c-0.4,3.6,1.5,6.2,5.8,8.1h0.1
	c-0.4-0.3-0.8-0.7-1.2-1.1c-1.6-1.6-2.5-3.6-2.5-5.9c0-2.3,0.8-4.3,2.5-5.9c1.2-1.2,2.6-1.9,4.3-2.3L312.8,290.4L312.8,290.4z"
          />
          <path
            fill="#EBEBEB"
            d="M322,292.5l-7-1.6c-1.6,0.3-3,1.1-4.3,2.3c-1.6,1.6-2.5,3.7-2.5,5.9c0,2.3,0.8,4.3,2.5,5.9
	c0.4,0.4,0.8,0.7,1.2,1.1l5.5,1.5c2.1-0.1,3.7-1,5.2-2.5c1.6-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9L322,292.5L322,292.5z
	 M316.8,291.6c2.1,0,3.9,0.7,5.4,2.2c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4c-1.5,1.5-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2
	c-1.5-1.5-2.2-3.3-2.2-5.4c0-2.1,0.8-3.9,2.2-5.4C313,292.3,314.7,291.6,316.8,291.6L316.8,291.6z"
          />
          <path
            fill="#EBEBEB"
            d="M322.2,293.8c-1.5-1.5-3.3-2.2-5.4-2.2s-3.9,0.7-5.4,2.2c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.8,3.9,2.2,5.4
	c1.5,1.5,3.3,2.2,5.4,2.2s3.9-0.7,5.4-2.2c1.5-1.5,2.2-3.3,2.2-5.4C324.4,297.1,323.7,295.3,322.2,293.8L322.2,293.8z M316.8,292.4
	c1.9,0,3.4,0.7,4.8,1.9c1.4,1.4,2.1,2.9,2.1,4.8s-0.7,3.4-2.1,4.8c-1.4,1.4-3,2.1-4.8,2.1c-1.9,0-3.4-0.7-4.8-2.1
	c-1.4-1.4-2.1-2.9-2.1-4.8c0-1.9,0.7-3.6,2.1-4.8C313.4,293,314.9,292.4,316.8,292.4L316.8,292.4z"
          />
          <path
            fill="#EBEBEB"
            d="M321.6,294.3c-1.4-1.4-3-1.9-4.8-1.9c-1.9,0-3.4,0.7-4.8,1.9c-1.4,1.4-2.1,2.9-2.1,4.8
	c0,1.9,0.7,3.4,2.1,4.8c1.4,1.4,3,2.1,4.8,2.1c1.9,0,3.4-0.7,4.8-2.1c1.4-1.4,2.1-2.9,2.1-4.8C323.7,297.2,323,295.7,321.6,294.3
	L321.6,294.3z"
          />
          <path
            fill="#EBEBEB"
            d="M312,306.2c-4.3-1.9-6.1-4.5-5.8-8.1l-4-2.9l-1.4-1l1.5,8.9c0.5,1.4,2.1,2.5,4.5,3.2
	c6.1,1.5,12.8,3,20.2,4.4l12.1,2.1l-0.7-1.4c-3.4-0.4-7.2-1-11.4-1.8l-9.8-2.2L312,306.2L312,306.2z"
          />
          <path
            fill="#FFFFFF"
            d="M544.8,302.5h-3c-0.7,2.5-2.3,4-5,4.5c-6.9,1.8-14.3,3.3-21.9,4.5l-11.4,1.8c-0.8,0.3-1.1,0.1-0.7-0.7
	c0.1-0.3,0.4-0.8,0.7-1.4l7.7-8.7l-32.5,2.9l-1.1-0.4l9.6-8.1l-0.3,0.1l-16.8,12.8l16.8-12.8l-13.3,0.5c-3.4,2.2-6.1,3.9-8,5
	l-1.2,0.7c-1.6,0.8-3.9,1.4-6.7,1.5l-6.6,0.3l-16,0.1c-1,0-1.8-0.6-2.3-1.6c-1.5-2.9-4-4.8-7.3-5.6l-0.3-0.1h-8.4l-0.3,0.1
	c-3.3,0.8-5.8,2.8-7.3,5.6c-0.5,1.1-1.2,1.6-2.3,1.6l-16-0.1l-6.6-0.3c-2.9-0.1-5.1-0.5-6.7-1.5l-1.2-0.7c-1.9-1.1-4.5-2.8-8-5
	l-13.3-0.5l16.8,12.8l-16.8-12.8l-0.3-0.1l9.6,8.1l-1.1,0.4l-32.5-2.9l7.7,8.7l0.7,1.4c0.3,0.8,0.1,1-0.7,0.7l-11.4-1.8
	c-7.7-1.4-15-2.9-21.9-4.5c-2.6-0.5-4.3-2.1-5-4.5h-3c0.7,2.3,1.6,3.9,2.9,4.8c2.3,2.1,7.2,3.4,14.3,4.5l26.8,4.7l-26.8-4.7
	c-7.2-1-12-2.5-14.3-4.5c-1.4-0.8-2.3-2.5-2.9-4.8l-11,7c0.3,6.9,0.8,14.4,1.4,23v0.1h8.5l-0.3-10c-0.3-2.6,0.3-3.7,1.5-3
	c1.4,0,2.3,0.5,3,1.6l0.3,0.7l0.1,0.3l2.6,10.3h40.7l1.8-2.3c1.4-1.6,2.9-3.2,4.5-4.8s3.4-3.3,5.5-5c1.1-1,2.2-1.8,3.4-2.8
	c2.2-2.1,6.5-3,13.1-3h96.8c6.5,0,10.9,1,13.1,3l3.4,2.8c1.9,1.6,3.7,3.3,5.5,5c1.7,1.6,3.2,3.2,4.5,4.8l1.8,2.3h40.7l2.6-10.3
	l0.1-0.3l0.3-0.7c0.7-1.1,1.7-1.6,3-1.6c1.2-0.7,1.8,0.4,1.5,3l-0.3,10h8.5v-0.1c0.7-8.5,1.1-16.2,1.4-23L544.8,302.5
	c-0.7,2.3-1.6,3.8-2.9,4.8c-2.3,2.1-7.2,3.4-14.3,4.5l-26.8,4.7l26.8-4.7c7.2-1,12-2.5,14.3-4.5
	C543.2,306.4,544.2,304.8,544.8,302.5L544.8,302.5z M428.4,300.4l1.1,0.8c0.3,0.4,0.7,0.7,0.8,1.1c0.3,0.4,0.4,1,0.4,1.4
	c0.1,0.5,0,1.1-0.1,1.6l-0.7,1.2l-1,1l-1.1,0.8c-0.8,0.5-1.8,1-2.8,1.2c-1.1,0.3-2.2,0.5-3.3,0.5l-3.6-0.3c-1.1-0.3-1.9-0.5-2.9-1
	c-1-0.4-1.6-1-2.2-1.6c-0.3-0.3-0.7-0.7-0.8-1.1c-0.3-0.4-0.4-0.8-0.5-1.4c-0.1-0.5-0.1-1.1,0.1-1.6c0.1-0.4,0.3-0.8,0.7-1.4
	c0.3-0.3,0.5-0.7,1-1c0.3-0.3,0.7-0.5,1.1-0.8c1.2-0.8,2.6-1.2,4.3-1.5l1.8-0.1c2.3-0.1,4.5,0.3,6.6,1.2
	C427.5,299.8,428,300.1,428.4,300.4L428.4,300.4z M479.1,313.5c-1.4-0.4-2.8-0.5-4.1-0.5H367.2c-1.9-0.1-4,0.3-5.9,1.1
	c-1.5,0.7-3,1.5-4.5,2.8c1.5-1.2,3-2.2,4.5-2.8c1.9-0.8,4-1.2,5.9-1.1H475C476.4,312.9,477.8,313.2,479.1,313.5l1.4,0.5
	c1.6,0.7,3.3,1.6,4.8,2.9c-1.6-1.2-3.2-2.2-4.8-2.9L479.1,313.5L479.1,313.5z M342.4,335.3l2.1-2.6h-40.7l1.8,6.9l1.4,1.8
	c1,1.4,1.6,3,2.2,5l0.1,0.6c0.4,1.6,0.4,4,0.1,7c-0.8,4-3.2,5.9-7.3,5.6c-2.6-0.3-4.3-1.4-4.8-2.9c0.4,2.5,2.1,4,5.2,4.5
	c7.3,1.2,15.7,2.1,24.9,2.3l3.9,0.1l-3.9-0.1c-9.4-0.3-17.6-1.1-24.9-2.3c-3-0.5-4.8-2.1-5.2-4.5c-0.1-0.3-0.1-0.5-0.3-0.8v-0.3
	l-0.1-3.4v-8.9l-0.3-10.5h-8.5l1.8,24.4l0.7,9.4c9.9,1.1,21.6,1.6,35.1,1.8h20.6c-3.3-4.7-5.6-8.8-7-12.5l-0.4-1.1
	c-0.4-1.1-0.7-2.2-1-3.3l-0.3-1.6c-0.3-1.8-0.1-3.3,0.3-4.8l0.1-0.5l0.1-0.8l0.1-0.1l2.1-4.3C340.7,337.8,341.6,336.6,342.4,335.3
	L342.4,335.3z M554.4,332.7h-8.5l-0.3,10.5v8.9l-0.1,3.4v0.3l-0.3,0.8c-0.4,2.5-2.1,4-5.2,4.5c-7.3,1.2-15.7,2.1-24.9,2.3l-3.9,0.1
	l3.9-0.1c9.4-0.3,17.6-1.1,24.9-2.3c3-0.5,4.8-2.1,5.2-4.5c-0.6,1.6-2.2,2.6-4.8,2.9c-4.1,0.3-6.5-1.7-7.3-5.6
	c-0.3-3.2-0.3-5.5,0.1-7l0.1-0.5c0.5-1.9,1.2-3.6,2.2-5c0.4-0.7,0.8-1.2,1.4-1.8l1.8-6.9H498l2.1,2.6c1,1.2,1.6,2.6,2.5,3.9l2.1,4.3
	l0.1,0.1l0.1,0.8l0.1,0.5c0.4,1.5,0.4,3,0.3,4.8l-0.4,2.1l-0.8,2.9c-1.4,3.9-3.9,8.4-7.4,13.6h20.6c13.3-0.1,25-0.8,34.9-1.8l0.1-1
	l0.7-8.4L554.4,332.7L554.4,332.7z"
          />
          <path
            fill="#EBEBEB"
            d="M301.1,322.4l-0.1-0.3l-1.5-0.4c-1,0-1.2,0.1-1.1,0.7c-0.1,4.5,0,9.6,0.3,15c0.1,1.5,0.8,2.3,2.1,2.2h4.7
	L301.1,322.4L301.1,322.4z"
          />
          <path
            fill="#656565"
            d="M301,322l-0.4-0.7l-2.5-0.5c-1-0.3-1.4,0.4-1.2,1.6l0.4,15.5c0,1.5,1,2.3,2.8,2.6l6.7,0.7l-1.4-1.8h-4.7
	c-1.4,0.1-2.1-0.7-2.1-2.2c-0.4-5.5-0.5-10.5-0.3-15c-0.3-0.5,0.1-0.8,1.1-0.7L301,322L301,322z"
          />
          <path
            fill="#656565"
            d="M297,322.4c-0.1-1.4,0.4-1.9,1.2-1.6l2.5,0.5c-0.7-1.1-1.6-1.6-3-1.6c-1.2-0.7-1.8,0.4-1.5,3l0.4,20.4
	l1.2,0.1l8.5,1c1,0,1.5,0.5,1.5,1.2l-10-1l-1.2-0.1v2.1l1.2,0.1l9.2,1c1,0,1.4,0.4,1.5,1.4l-10.7-0.8l-1.2-0.1v2.5l1.2,0.1l9.2,0.8
	c0.8,0,1.2,0.4,1.2,1c-0.1,0.4-0.7,0.4-1.8,0.3l-8.7-0.7l-1.4-0.1l0.1,3.4l1.4,0.1v-1.4l-1-0.1l1,0.1l8.4,1c0.8,0,1.2,0.4,1.1,1
	c-0.1,0.4-0.7,0.5-1.6,0.4l-7.7-1l-1.4-0.1v0.3c0,0.3,0.1,0.5,0.3,0.8c0.5,1.6,2.2,2.6,4.8,2.9c4.1,0.3,6.5-1.6,7.3-5.6
	c0.3-3.2,0.3-5.5-0.1-7l-0.1-0.5c-0.5-1.9-1.2-3.6-2.2-5l-6.7-0.7c-1.8-0.3-2.8-1.2-2.8-2.6L297,322.4z"
          />
          <path
            fill="#656565"
            d="M307.9,345.5c0-0.8-0.5-1.2-1.5-1.2l-8.5-1v1.4L307.9,345.5L307.9,345.5z M308.7,349c-0.1-1-0.5-1.4-1.5-1.4
	l-9.2-1l0.1,1.5L308.7,349L308.7,349z M307.7,356.2c0.1-0.5-0.3-1-1.1-1l-8.4-1v1.4l7.7,1C307,356.8,307.6,356.7,307.7,356.2
	L307.7,356.2z M306.8,352.8c1.1,0.1,1.6,0,1.8-0.3c0-0.7-0.4-1-1.2-1l-9.2-0.8v1.5L306.8,352.8L306.8,352.8z M297.8,343.2l-1.2-0.1
	v1.4l1.2,0.1 M297.8,346.6l-1.1-0.1v1.6h1.2"
          />
          <path fill="#656565" d="M298.1,350.7l-1.4-0.1l0.1,1.5l1.2,0.1" />
          <path
            fill="#EBEBEB"
            d="M542.7,321.6l-1.5,0.4l-0.1,0.3l-4.4,17.2h4.7c1.4,0.1,2.1-0.7,2.1-2.2c0.4-5.5,0.5-10.5,0.3-15
	C543.9,321.9,543.5,321.6,542.7,321.6L542.7,321.6z"
          />
          <path
            fill="#656565"
            d="M541,322l1.5-0.4c1,0,1.2,0.1,1.1,0.7c0.1,4.5,0,9.6-0.4,15c-0.1,1.5-0.8,2.3-2.1,2.2h-4.7
	c-0.5,0.5-1,1.1-1.4,1.8l6.7-0.7c1.8-0.3,2.8-1.2,2.8-2.6l0.4-15.5c0.1-1.4-0.4-1.9-1.2-1.6l-2.5,0.5L541,322L541,322z"
          />
          <path
            fill="#656565"
            d="M544.5,319.6c-1.4,0-2.3,0.5-3,1.6l2.5-0.5c1-0.3,1.4,0.4,1.2,1.6l-0.4,15.5c0,1.5-1,2.3-2.8,2.6l-6.7,0.7
	c-1,1.4-1.7,3-2.2,5l-0.1,0.5c-0.4,1.6-0.4,4-0.1,7c0.8,4,3.2,5.9,7.3,5.6c2.6-0.3,4.3-1.4,4.8-2.9l0.3-0.8v-0.3l-1.4,0.1l-7.7,1
	c-1,0.3-1.5,0.1-1.7-0.4c-0.1-0.5,0.3-1,1.1-1l8.4-1l1-0.1l-1,0.1v1.4l1.4-0.1l0.1-3.4l-1.4,0.1l-8.7,0.7c-1.1,0.1-1.7,0-1.8-0.3
	c0-0.7,0.4-1,1.2-1l9.2-0.8l1.2-0.1v-2.5l-1.2,0.1l-10.7,0.8c0.1-1,0.5-1.4,1.5-1.4l9.2-1l1.2-0.1v-2.1l-1.2,0.1l-10,1
	c0-0.8,0.5-1.2,1.5-1.2l8.5-1l1.2-0.1l0.4-20.4C546.3,320.1,545.7,319,544.5,319.6L544.5,319.6z"
          />
          <path
            fill="#656565"
            d="M544.3,343.2v1.4l1.1-0.1l0.1-1.4 M544.3,344.5v-1.4l-8.5,1c-1,0-1.5,0.5-1.5,1.2L544.3,344.5L544.3,344.5z
	 M545.4,346.5l-1.2,0.1v1.5h1.2 M545.3,350.6l-1.2,0.1v1.5l1.2-0.1 M544.2,348.1v-1.5l-9.2,1c-1,0-1.4,0.4-1.5,1.4L544.2,348.1
	L544.2,348.1z M533.5,352.5c0.1,0.4,0.7,0.4,1.8,0.3l8.7-0.7v-1.5l-9.2,0.8C534,351.6,533.6,352,533.5,352.5L533.5,352.5z
	 M534.4,356.2c0.1,0.4,0.7,0.5,1.7,0.4l7.7-1v-1.4l-8.4,1C534.6,355.3,534.3,355.7,534.4,356.2L534.4,356.2z"
          />
          <path
            fill="#C8C8C8"
            d="M428.4,300.4c-0.4-0.3-0.8-0.5-1.2-0.7c-1.9-1-4.1-1.4-6.6-1.2l-1.8,0.1c-1.6,0.3-3,0.8-4.3,1.5
	c-0.4,0.3-0.8,0.5-1.1,0.8l-1,1c-0.3,0.4-0.5,0.8-0.7,1.4c-0.1,0.5-0.1,1.1-0.1,1.6c0.1,0.4,0.3,1,0.5,1.4c0.3,0.4,0.5,0.7,0.8,1.1
	c0.5,0.7,1.4,1.1,2.2,1.6c1,0.4,1.8,0.8,2.9,1l3.6,0.3c1.2,0,2.3-0.3,3.3-0.5s1.8-0.7,2.8-1.2l1.1-0.8l1-1l0.7-1.2
	c0.3-0.5,0.3-1.1,0.1-1.7c0-0.4-0.3-1-0.4-1.4c-0.3-0.4-0.5-0.7-0.8-1.1L428.4,300.4L428.4,300.4z M428.5,303.4
	c0.1,0,0.3,0.1,0.4,0.3v1.1c-0.1,0.1-0.3,0.1-0.4,0.1v-1.1H426v1.1h-0.6v-1.4L428.5,303.4L428.5,303.4z M424.9,303.4l0.1,0.3v0.8
	l-0.1,0.3l-0.7,0.1H422l-0.3-0.3v-1l0.1-0.1h2.3L424.9,303.4L424.9,303.4z M426,300c1.4,0.7,2.3,1.8,2.8,3h-6.9l-0.3-0.3l-0.3-0.4
	c-0.1-0.4-0.3-1-0.3-1.5s0-1.1,0.1-1.8l2.8,0.3C424.7,299.4,425.5,299.7,426,300L426,300z M420.9,299c-0.5,0.7-1,1.2-1.4,1.8
	c-0.4,0.7-0.7,1.4-0.8,2.2h-5.2c0.3-0.7,0.5-1.2,1.1-1.6c0.4-0.4,1-0.8,1.6-1.2C417.6,299.4,419.2,299,420.9,299L420.9,299z
	 M416.7,303.4v0.3h-2.9v0.3h2.9v0.4l-0.1,0.3l-0.4,0.1h-2.8l-0.1-0.3h2.8c0.3,0,0.3-0.1,0.1-0.3h-2.9v-0.5c0-0.1,0.1-0.3,0.4-0.3
	L416.7,303.4L416.7,303.4z M417.1,303.6c0-0.1,0.1-0.3,0.3-0.3h3v0.3h-2.9c-0.1,0-0.1,0.1,0,0.4v0.4h2.9v0.3h-3
	c-0.3,0-0.3-0.1-0.4-0.3L417.1,303.6L417.1,303.6z M419.4,305.2h0.3c0.1,0,0.3,0.1,0.4,0.3l0.4,0.4c0.3,0.4,0.4,1,0.5,1.6
	c0,0.7,0,1.4-0.1,1.9c-1.8,0-3.4-0.4-4.8-1.2c-1.4-0.8-2.3-1.8-2.8-3L419.4,305.2L419.4,305.2z M420.8,303.4h0.5v1.4h-0.5V303.4z
	 M423,306.4l0.4-1.2h5.5c-0.4,1.4-1.2,2.5-2.8,3.2c-0.7,0.4-1.5,0.5-2.3,0.8l-2.8,0.3l0.1-0.1l0.1-0.1l1.2-1.6L423,306.4L423,306.4z
	"
          />
          <path
            fill="#C8C8C8"
            d="M428.9,303.6c0-0.1-0.1-0.1-0.4-0.3h-3v1.4h0.5v-1.1h2.5v1.1c0.3,0,0.4,0,0.4-0.1L428.9,303.6L428.9,303.6z
	 M425.1,303.7l-0.1-0.3l-0.7-0.1h-2.3l-0.1,0.1v1l0.3,0.3h2.2l0.7-0.1l0.1-0.3V303.7L425.1,303.7L425.1,303.7z M424.1,303.7h0.5v0.8
	h-2.3v-0.4c0-0.1,0-0.3,0.1-0.4L424.1,303.7L424.1,303.7z"
          />
          <path
            fill="#C8C8C8"
            d="M424.5,303.7h-2.3c0,0.1-0.1,0.1-0.1,0.4v0.4h2.3v-0.4L424.5,303.7L424.5,303.7z"
          />
          <path
            fill="#828282"
            d="M428.9,303c-0.4-1.2-1.4-2.2-2.8-3c-0.7-0.4-1.4-0.5-2.2-0.8l-2.8-0.3c-0.1,0.5-0.1,1.1-0.1,1.8
	s0.1,1.2,0.3,1.5l0.3,0.4l0.3,0.3h7L428.9,303L428.9,303z M420.9,299.2l0.1-0.1c-1.8,0-3.4,0.4-4.8,1.2c-0.7,0.3-1.1,0.7-1.6,1.2
	s-0.8,1.1-1.1,1.6h5.2c0.1-0.8,0.4-1.5,0.8-2.2C420,300.3,420.4,299.7,420.9,299.2L420.9,299.2z"
          />
          <path
            fill="#C8C8C8"
            d="M416.7,303.7v-0.3h-3c-0.1,0-0.3,0.1-0.4,0.3v0.5h2.9c0,0.1,0,0.3-0.1,0.3h-2.8l0.1,0.3h2.8l0.3-0.1l0.1-0.3
	V304h-2.9v-0.3L416.7,303.7L416.7,303.7z M417.5,303.4c-0.1,0-0.3,0.1-0.3,0.3v1c0,0.1,0.1,0.3,0.4,0.3h3v-0.4h-2.9v-0.4v-0.4h2.9
	v-0.3L417.5,303.4L417.5,303.4z"
          />
          <path
            fill="#828282"
            d="M419.8,305.2h-6.3c0.4,1.2,1.4,2.2,2.8,3c1.4,0.8,3,1.2,4.8,1.2c0.1-0.7,0.1-1.2,0.1-1.9
	c-0.1-0.7-0.3-1.2-0.5-1.6l-0.4-0.4C420.1,305.3,420,305.2,419.8,305.2L419.8,305.2z"
          />
          <path fill="#C8C8C8" d="M421.4,303.4h-0.5v1.4h0.5" />
          <path
            fill="#828282"
            d="M423.3,305.2l-0.4,1.2l-0.5,1.1l-1.2,1.6l-0.1,0.1l-0.1,0.1l2.8-0.3c0.8-0.1,1.6-0.4,2.3-0.8
	c1.5-0.8,2.5-1.8,2.8-3.2L423.3,305.2L423.3,305.2z"
          />
          <path
            fill="#EE302D"
            d="M354.2,296.7l0.5,0.5l0.3,0.1l13.3,0.5l15.4,0.3c5.8,0,11.7-0.1,17.7-0.3h38.7c6.1,0.1,12,0.3,17.7,0.3
	l15.4-0.3l13.3-0.5l0.3-0.1l0.5-0.5l-14.7,0.7l-14.9,0.3c-5.8,0-11.7,0-17.7-0.3h-38.5c-6.1,0.1-12,0.3-17.7,0.3l-14.9-0.3
	L354.2,296.7L354.2,296.7z"
          />
          <path
            fill="#EBEBEB"
            d="M359.2,303.1c-1.4-2.1-3.2-3.9-5.4-5.6l-14.4-1.8v7l17.9,1.2C359,304.1,359.6,303.8,359.2,303.1L359.2,303.1
	z M350.1,300c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.5-0.1-0.8-0.4c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8
	c0.3-0.1,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3C350,299.4,350.1,299.7,350.1,300L350.1,300z"
          />
          <path
            fill="#EBEBEB"
            d="M349.7,300.8c0.3-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.1-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3C349.3,301.1,349.6,300.9,349.7,300.8L349.7,300.8z M339.4,302.7
	v-7l-12.4-2.1c2.2,2.2,3,5,2.3,8.4L339.4,302.7L339.4,302.7z M334.4,299.8c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.5-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.3,0.1,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8
	C335,299.7,334.7,299.8,334.4,299.8L334.4,299.8z"
          />
          <path
            fill="#EBEBEB"
            d="M333.6,299.4c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8s-0.1-0.5-0.3-0.8
	c-0.1-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.5-0.3,0.8C333.3,299,333.5,299.3,333.6,299.4L333.6,299.4z"
          />
          <path
            fill="#656565"
            d="M356.3,320.6c-1.9,1.6-3.7,3.3-5.5,5c-1.6,1.6-3.2,3.2-4.5,4.8l-3.9,5c-1,1.2-1.6,2.6-2.5,3.9l-2.1,4.3
	l-0.3,1l-0.1,0.5c-0.3,1.8-0.3,3.4,0.3,4.8c0.1,0.5,0.4,1.1,0.7,1.6c0.3,0.4,0.7,1,1,1.4c0.7,0.7,1.5,1.4,2.6,1.9l0.8,0.4
	c4.3,2.3,8.1,3.9,11.6,4.7h0.3c1.5,0.4,2.9,0.5,4.3,0.5l54.5,0.4l69.5-0.4c1.4,0,2.8-0.3,4.3-0.5h0.4c3.4-0.8,7.3-2.3,11.6-4.7
	l0.8-0.4c1-0.5,1.9-1.2,2.6-1.9l1-1.4c0.3-0.5,0.5-1.1,0.8-1.6c0.4-1.4,0.5-3,0.1-4.8l-0.1-0.5l-0.3-1l-2.1-4.3
	c-0.7-1.4-1.5-2.6-2.5-3.9l-3.9-5c-1.4-1.6-2.9-3.2-4.5-4.8c-1.7-1.6-3.4-3.3-5.5-5l-3.4-2.8c-2.2-2.1-6.5-3-13.1-3h-96.8
	c-6.5,0-10.9,1-13.1,3C358.5,318.7,357.4,319.6,356.3,320.6L356.3,320.6z M352.6,325.4l2.1-1.9l1.5-1.4l1.9-1.6l2.3-1.8
	c2.1-1.9,6.1-2.8,12.1-2.8h97c6.1,0,10.2,0.8,12.1,2.8l2.3,1.8l1.9,1.6l1.5,1.4l2.1,1.9c1.7,1.7,3.2,3.2,4.7,4.8l1.5,1.8l1.1,1.4
	l1.4,1.9c1.1,1.5,2.1,3,2.9,4.5v0.1l0.7,1.4l0.3,0.4l0.7,1.4l0.4,1l0.3,1c0.3,1.8,0.3,3.4-0.3,4.8l-1,1.4l-1.4,1.4
	c-0.5,0.5-1.4,1-2.1,1.2l-1.2,0.7c-3.7,1.9-7.2,3.2-10.2,4h-0.4c-1.4,0.3-2.6,0.4-3.9,0.5l-18.8,0.1h-0.3l-23.1,0.1h-0.4l-19.1,0.1
	h-0.3l-19.1-0.1h-0.4l-23.1-0.1H378l-18.8-0.1c-1.2,0-2.5-0.3-3.9-0.5h-0.4c-3-0.7-6.5-2.1-10.2-4l-1.2-0.7
	c-0.8-0.4-1.5-0.8-2.1-1.2s-1-0.8-1.4-1.4c-0.4-0.5-0.7-1.1-1-1.6c-0.5-1.4-0.5-3-0.3-4.8l0.3-1l0.4-1l0.7-1.4l0.3-0.4l0.7-1.4v-0.1
	c0.8-1.5,1.8-3,2.9-4.5l1.4-1.9l1.1-1.4l1.5-1.8C349.4,328.6,350.9,327.1,352.6,325.4L352.6,325.4z"
          />
          <path
            fill="#656565"
            d="M354.6,323.5l-2.1,1.9h137l-2.1-1.9h-12.8c0.1,0.7-0.7,1.1-2.3,1.2H369.9c-1.7-0.1-2.5-0.6-2.3-1.2
	L354.6,323.5L354.6,323.5z"
          />
          <path
            fill="#828282"
            d="M356.2,322.3l-1.5,1.4h12.8c-0.1,0.7,0.7,1.1,2.3,1.2l1.5-3.6c-1.8,0-3,0.4-3.7,1.1h-11.4L356.2,322.3
	L356.2,322.3z"
          />
          <path
            fill="#656565"
            d="M358.2,320.6l-1.9,1.6h11.4c0.7-0.7,1.9-1,3.7-1.1H471c1.8,0,3,0.4,3.7,1.1h11.4l-1.9-1.6H358.2z"
          />
          <path
            fill="#828282"
            d="M344,335.3c-1.1,1.5-2.1,3-2.9,4.5v0.1h22l2.1-4.5L344,335.3L344,335.3z"
          />
          <path
            fill="#656565"
            d="M347.9,330.2l-1.5,1.8h16.9c0.7-0.7,1.9-1,3.6-1.1h108.3c1.7,0,2.9,0.4,3.6,1.1h16.9l-1.5-1.8L347.9,330.2
	L347.9,330.2z"
          />
          <path
            fill="#828282"
            d="M346.5,332l-1.1,1.4h17.9c-0.1,0.7,0.7,1.1,2.2,1.2l1.5-3.6c-1.7,0-2.9,0.4-3.6,1.1h-16.9L346.5,332
	L346.5,332z"
          />
          <path
            fill="#656565"
            d="M345.4,333.4l-1.4,1.9h154.3l-1.4-1.9h-17.9c0.1,0.7-0.7,1.1-2.2,1.2H365.5c-1.6-0.1-2.3-0.5-2.2-1.2H345.4
	L345.4,333.4z"
          />
          <path
            fill="#828282"
            d="M352.6,325.4c-1.6,1.7-3.2,3.2-4.7,4.8h19.4l2.1-4.8H352.6L352.6,325.4z"
          />
          <path fill="#656565" d="M358.9,350.3h-0.4l-1.5,3.6h0.4" />
          <path
            fill="#656565"
            d="M358.5,350.3h126.3c1.8,0,3,0.4,3.9,1.1H502l1-1.6H339.1c0.3,0.5,0.5,1.1,1,1.6h13.3c0.7-0.7,2.1-1,3.9-1.1
	H358.5L358.5,350.3z M358.8,349.8h0.4l2.1-4.8H361"
          />
          <path
            fill="#828282"
            d="M358.5,350.3h-1.4c-1.8,0-3,0.4-3.9,1.1h-13.3c0.4,0.5,0.8,1,1.4,1.4h11.8c-0.1,0.7,1,1.1,3.2,1.2h0.7
	L358.5,350.3L358.5,350.3z"
          />
          <path
            fill="#656565"
            d="M343.5,353.9l1.2,0.7h153l1.2-0.7c0.8-0.4,1.5-0.8,2.1-1.2h-11.8c0.1,0.7-1,1.1-3.2,1.2H356.3
	c-2.2,0-3.3-0.5-3.2-1.2h-11.8C342,353.2,342.7,353.6,343.5,353.9L343.5,353.9z"
          />
          <path fill="#656565" d="M357,354.6h-0.3l-1.8,4h0.4" />
          <path
            fill="#828282"
            d="M355.3,358.6c1.4,0.3,2.6,0.4,3.9,0.5l18.8,0.1l1-4.7h-21.9L355.3,358.6L355.3,358.6z M354.9,358.6l1.8-4
	h-12C348.4,356.5,351.9,357.9,354.9,358.6z M358.9,350.3l-1.5,3.6h19.5l0.4-0.4c0.4-0.4,1.1-0.7,1.6-0.7h0.3l0.4-1.5
	c-0.5,0-1.1-0.3-1.5-0.7l-0.4-0.4h-18.8L358.9,350.3L358.9,350.3z M340.1,341.7l-0.7,1.4h19.3c-0.1,0.7,0.8,1.1,2.6,1.2l1.5-3.6
	h-0.1c-1.8,0-3,0.3-3.9,1L340.1,341.7L340.1,341.7z"
          />
          <path
            fill="#656565"
            d="M340.3,341.2l-0.3,0.4h18.8c0.7-0.7,1.9-1,3.9-1h116.4c1.8,0,3,0.3,3.9,1h19.1l-0.3-0.4l-0.7-1.4H363h0.1
	H341L340.3,341.2L340.3,341.2z M339,344l-0.3,1h164.9l-0.3-1l-0.4-1h-19.3c0.1,0.7-0.8,1.1-2.6,1.2H361.4c-1.8-0.1-2.8-0.5-2.6-1.2
	h-19.3L339,344z"
          />
          <path
            fill="#828282"
            d="M338.8,345c-0.3,1.8-0.1,3.4,0.3,4.8h19.7l2.1-4.8H338.8L338.8,345z"
          />
          <path fill="#656565" d="M454.8,316.1h-0.4l1.1,4.5h0.3" />
          <path
            fill="#828282"
            d="M469.5,316.1h-1l1.9,4.5h13.5l-2.3-1.8C479.5,316.9,475.6,315.9,469.5,316.1L469.5,316.1z"
          />
          <path fill="#656565" d="M468.5,316.1h-0.4l2.1,4.5h0.3" />
          <path fill="#828282" d="M468.1,316.1h-13.3l1,4.5h14.4" />
          <path fill="#656565" d="M438.1,316.1h-0.4l0.3,4.5h0.4" />
          <path
            fill="#828282"
            d="M440.5,321.2l-0.5,0.5c-0.4,0.4-1,0.5-1.5,0.7l0.1,1.2h0.1c0.7,0,1.2,0.3,1.6,0.7l0.5,0.5h13.5l0.3-0.4
	c0.4-0.4,0.8-0.5,1.4-0.5l-0.3-1.5h-0.1c-0.7,0-1.2-0.3-1.6-0.7l-0.4-0.4L440.5,321.2L440.5,321.2z"
          />
          <path
            fill="#656565"
            d="M440.1,321.7l0.5-0.5h-4.4l0.4,0.5c0.4,0.4,1,0.5,1.5,0.7h0.3C439.1,322.3,439.7,322.1,440.1,321.7
	L440.1,321.7z M438.8,323.6h-0.5c-0.4,0-0.8,0.3-1.2,0.5c-0.3,0.1-0.4,0.3-0.4,0.5h4.3l-0.5-0.5
	C439.9,323.8,439.4,323.6,438.8,323.6z"
          />
          <path
            fill="#828282"
            d="M438.1,322.4c-0.5,0-1.1-0.3-1.5-0.7l-0.4-0.5h-12.9l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7v1.2
	c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5h13.3c0.1-0.1,0.3-0.4,0.4-0.5c0.3-0.3,0.8-0.5,1.2-0.5L438.1,322.4z"
          />
          <path
            fill="#656565"
            d="M438.6,322.4h-0.4l0.1,1.2h0.3 M438.7,325.4h-0.3l0.3,4.8h0.3 M439.1,331.9c0.5,0,1.1-0.3,1.5-0.7l0.3-0.4
	h-4.1l0.3,0.4c0.4,0.4,1,0.5,1.5,0.7H439.1L439.1,331.9z"
          />
          <path
            fill="#828282"
            d="M440.6,331.3c-0.4,0.4-1,0.5-1.5,0.7l0.1,1.6h0.1c0.7,0,1.2,0.3,1.6,0.7l0.3,0.3h15.1
	c0.1-0.3,0.3-0.5,0.5-0.7c0.4-0.3,0.7-0.5,1.2-0.5l-0.3-1.2c-0.7,0-1.2-0.3-1.6-0.7l-0.4-0.4H441L440.6,331.3z"
          />
          <path fill="#656565" d="M439.1,331.9h-0.4l0.1,1.8h0.4" />
          <path
            fill="#656565"
            d="M439.4,333.7h-0.5c-0.4,0-0.8,0.3-1.2,0.6l-0.3,0.3h4l-0.3-0.3C440.6,333.8,440.1,333.7,439.4,333.7
	L439.4,333.7z"
          />
          <path
            fill="#828282"
            d="M438.8,333.7l-0.1-1.8c-0.5,0-1.1-0.3-1.5-0.7l-0.3-0.4h-13.6l-0.4,0.5c-0.4,0.4-1,0.6-1.5,0.7v1.2
	c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5h14.2l0.3-0.3C438,334,438.4,333.8,438.8,333.7L438.8,333.7z"
          />
          <path fill="#656565" d="M439.2,339.9h0.3l-0.1-4.5H439" />
          <path
            fill="#828282"
            d="M439.4,335.3l0.1,4.5h20.1l-1-4.5 M439.2,339.9l-0.3-4.5h-17.7v4.5"
          />
          <path
            fill="#656565"
            d="M441.7,340.6h-4.1l0.4,0.4c0.4,0.3,0.8,0.5,1.4,0.7h0.4c0.7,0,1.2-0.1,1.6-0.7L441.7,340.6L441.7,340.6z"
          />
          <path
            fill="#828282"
            d="M438.7,325.4l0.3,4.8h18.6l-1.1-4.8 M438.7,330.2l-0.3-4.8h-17.2v4.8"
          />
          <path fill="#656565" d="M470.7,321.2h-0.3l1.5,3.6h0.3" />
          <path
            fill="#828282"
            d="M457.8,321.2l-0.4,0.4c-0.3,0.3-0.8,0.5-1.2,0.5l0.3,1.5c0.7,0,1.2,0.3,1.6,0.5l0.4,0.4H472l-1.5-3.6h-12.7
	L457.8,321.2L457.8,321.2z"
          />
          <path
            fill="#656565"
            d="M457.4,321.6l0.4-0.4h-4.1l0.4,0.4c0.4,0.4,1.1,0.7,1.6,0.7h0.4C456.6,322.1,457,322,457.4,321.6z"
          />
          <path
            fill="#656565"
            d="M456.2,322.3h-0.4l0.4,1.5h0.3 M456.9,325.4h-0.4l1.1,4.8h0.4 M456.4,323.8H456c-0.5,0-1,0.3-1.4,0.5
	l-0.3,0.4h4.1l-0.4-0.4C457.7,323.9,457.1,323.8,456.4,323.8L456.4,323.8z"
          />
          <path
            fill="#828282"
            d="M460,330.9l-0.4,0.4c-0.4,0.4-0.8,0.5-1.4,0.7l0.3,1.2h0.1c0.7,0,1.2,0.3,1.6,0.7c0.3,0.3,0.4,0.4,0.5,0.7
	h15.3l-1.5-3.6L460,330.9L460,330.9z"
          />
          <path
            fill="#656565"
            d="M459.6,331.5l0.4-0.4h-4.1l0.4,0.4c0.5,0.4,1.1,0.7,1.6,0.7h0.4C458.8,332,459.2,331.8,459.6,331.5
	L459.6,331.5z"
          />
          <path
            fill="#656565"
            d="M458.2,332h-0.3l0.3,1.2h0.3 M458.8,333.3h-0.6c-0.4,0-0.8,0.3-1.2,0.5c-0.3,0.3-0.4,0.4-0.5,0.7h4.5
	c-0.1-0.3-0.3-0.5-0.5-0.7C459.9,333.4,459.3,333.3,458.8,333.3L458.8,333.3z M459.6,339.9h0.4l-1.1-4.5h-0.3"
          />
          <path fill="#828282" d="M458.9,335.3l1.1,4.5h18.6l-1.9-4.5" />
          <path
            fill="#656565"
            d="M462.1,340.6h-4.3l0.4,0.5c0.5,0.4,1.1,0.7,1.6,0.7h0.5c0.6-0.1,1-0.3,1.2-0.6L462.1,340.6L462.1,340.6z"
          />
          <path
            fill="#828282"
            d="M456.9,325.4l1.1,4.8h16.5l-2.2-4.8 M454.4,316.1h-16.2l0.3,4.5h17.1 M457.8,340.6h-16l-0.4,0.4
	c-0.4,0.4-1,0.7-1.6,0.7l0.1,1.7h0.1c0.7,0,1.2,0.1,1.6,0.7l0.3,0.1h16.8l0.4-0.5c0.3-0.3,0.7-0.5,1.2-0.5l-0.3-1.4H460
	c-0.7,0-1.2-0.3-1.6-0.7L457.8,340.6L457.8,340.6z M438,320.6l-0.3-4.5h-16.5v4.5 M487.4,323.5l-1.5-1.4h-11.4
	c-0.7-0.7-1.9-1-3.7-1.1l1.5,3.6c1.7-0.1,2.5-0.5,2.3-1.2L487.4,323.5L487.4,323.5z"
          />
          <path fill="#656565" d="M472.7,325.4h-0.4l2.2,4.8h0.3" />
          <path
            fill="#828282"
            d="M476.7,334.5c1.6-0.1,2.3-0.5,2.2-1.2h17.9l-1.1-1.4h-16.9c-0.7-0.7-1.9-1-3.6-1.1L476.7,334.5L476.7,334.5z
	"
          />
          <path
            fill="#656565"
            d="M476.2,334.5h0.4l-1.5-3.6h-0.4 M476.7,335.3l1.9,4.5h0.4l-2.1-4.5"
          />
          <path
            fill="#828282"
            d="M476.9,335.3l2.1,4.5h22.1v-0.1c-0.8-1.5-1.8-3-2.9-4.5L476.9,335.3L476.9,335.3z"
          />
          <path fill="#656565" d="M479.3,340.6H479l1.5,3.6h0.4" />
          <path
            fill="#828282"
            d="M479.4,340.6h-0.1l1.5,3.6c1.8-0.1,2.8-0.5,2.6-1.2h19.3l-0.7-1.4h-18.8C482.6,341,481.3,340.7,479.4,340.6
	L479.4,340.6z M489.6,325.4h-16.8l2.1,4.8h19.4C492.8,328.6,491.2,327.1,489.6,325.4L489.6,325.4z M479,340.6h-16.8l-0.5,0.5
	c-0.3,0.3-0.8,0.5-1.2,0.5l0.3,1.4h0.1c0.7,0,1.2,0.1,1.7,0.7l0.4,0.5h17.5L479,340.6L479,340.6z"
          />
          <path
            fill="#656565"
            d="M387.7,316.1h-0.3l-1,4.5h0.3 M373.9,316.1h-0.3l-2.1,4.5h0.4"
          />
          <path
            fill="#828282"
            d="M373.6,316.1h-1c-6.1,0-10.2,0.8-12.1,2.8l-2.3,1.8h13.5L373.6,316.1L373.6,316.1z"
          />
          <path
            fill="#656565"
            d="M371.7,321.2h-0.4l-1.5,3.6h0.4 M369.8,325.4h-0.3l-2.2,4.8h0.4"
          />
          <path
            fill="#828282"
            d="M371.7,321.2l-1.5,3.6h13.5l0.4-0.4c0.4-0.4,1-0.5,1.6-0.5l0.4-1.5c-0.4,0-0.8-0.3-1.2-0.5l-0.4-0.4h-12.8
	L371.7,321.2L371.7,321.2z"
          />
          <path fill="#656565" d="M367,330.9l-1.5,3.6h0.3l1.6-3.6" />
          <path
            fill="#828282"
            d="M367.4,330.9l-1.5,3.6h15.3c0.1-0.3,0.3-0.5,0.5-0.7c0.5-0.4,1.1-0.7,1.6-0.7h0.1l0.3-1.2
	c-0.5,0-1-0.3-1.4-0.7l-0.4-0.4L367.4,330.9L367.4,330.9z"
          />
          <path
            fill="#656565"
            d="M363.2,340.6h-0.4l-1.5,3.6h0.4 M363.2,339.9h0.3l2.1-4.5h-0.4"
          />
          <path
            fill="#828282"
            d="M365.5,335.3l-2.1,4.5h18.7l1-4.5 M369.8,325.4l-2.1,4.8h16.5l1.1-4.8"
          />
          <path
            fill="#656565"
            d="M388.2,321.6l0.4-0.4h-4.1l0.4,0.4c0.3,0.3,0.8,0.5,1.2,0.5h0.4C387.1,322.3,387.7,322,388.2,321.6
	L388.2,321.6z"
          />
          <path
            fill="#828282"
            d="M388.6,321.2l-0.4,0.4c-0.5,0.4-1.1,0.7-1.6,0.7h-0.1l-0.3,1.5c0.5,0,1,0.3,1.4,0.5l0.4,0.4h13.5l0.4-0.5
	c0.4-0.4,1-0.7,1.6-0.7h0.1l0.1-1.2c-0.5,0-1.1-0.3-1.5-0.7l-0.5-0.5L388.6,321.2L388.6,321.2z"
          />
          <path
            fill="#656565"
            d="M387.8,324.7l-0.4-0.4c-0.4-0.4-0.8-0.5-1.4-0.5h-0.4c-0.7,0-1.2,0.3-1.6,0.5l-0.4,0.4H387.8L387.8,324.7z
	 M386.4,322.3H386l-0.4,1.5h0.4 M385.6,325.4h-0.3l-1.1,4.8h0.4 M385.9,331.5l0.4-0.4h-4.1l0.4,0.4c0.4,0.4,0.8,0.5,1.4,0.7h0.4
	C384.9,332,385.5,331.9,385.9,331.5L385.9,331.5z"
          />
          <path
            fill="#828282"
            d="M386.3,330.9l-0.4,0.4c-0.5,0.4-1.1,0.7-1.6,0.7l-0.3,1.2c0.4,0,0.8,0.3,1.2,0.5c0.3,0.3,0.4,0.4,0.5,0.7
	h15.1l0.3-0.3c0.4-0.4,1.1-0.7,1.6-0.7h0.1l0.1-1.6c-0.5,0-1.1-0.3-1.5-0.7l-0.3-0.4L386.3,330.9L386.3,330.9z"
          />
          <path fill="#656565" d="M384.2,332h-0.4l-0.3,1.2h0.4" />
          <path
            fill="#656565"
            d="M383.9,333.3h-0.5c-0.7,0-1.2,0.3-1.6,0.7c-0.3,0.3-0.4,0.4-0.5,0.7h4.5c-0.1-0.3-0.3-0.5-0.5-0.7
	C384.8,333.5,384.4,333.4,383.9,333.3L383.9,333.3z M382.2,339.9h0.4l1-4.5h-0.4 M384.4,340.6h-4.3l0.5,0.5c0.3,0.3,0.8,0.5,1.2,0.5
	h0.5c0.7,0,1.2-0.3,1.6-0.7L384.4,340.6L384.4,340.6z"
          />
          <path
            fill="#828282"
            d="M383.5,335.3l-1,4.5h19.9l0.3-4.5 M385.6,325.4l-1,4.8h18.4l0.3-4.8 M387.4,316.1h-13.5l-1.9,4.5h14.4
	 M380,340.6h-16.8l-1.5,3.6h17.5l0.4-0.5c0.4-0.4,1.1-0.7,1.6-0.7h0.1l0.3-1.4c-0.5-0.1-1-0.3-1.2-0.5L380,340.6L380,340.6z
	 M387.7,316.1l-1,4.5h17.1l0.3-4.5"
          />
          <path
            fill="#656565"
            d="M421.2,316.1h-0.3v4.5h0.3 M404.3,316.1H404l-0.3,4.5h0.3 M403.6,322.4l-0.1,1.2h0.4v-1.2 M402.1,321.7
	c0.4,0.4,1,0.5,1.5,0.7h0.3c0.7,0,1.1-0.3,1.5-0.7l0.4-0.5h-4.4L402.1,321.7z M403.5,323.6h-0.1c-0.7,0-1.2,0.3-1.6,0.7l-0.4,0.5
	h4.3l-0.4-0.5c-0.4-0.3-0.8-0.5-1.2-0.5L403.5,323.6L403.5,323.6z"
          />
          <path
            fill="#828282"
            d="M406,321.2l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7v1.2c0.4,0,0.8,0.3,1.2,0.5l0.4,0.5H419c0.1-0.3,0.3-0.4,0.5-0.5
	c0.4-0.3,0.8-0.5,1.4-0.7h0.1v-1.1h-0.1c-0.5-0.1-1-0.3-1.4-0.7c-0.3-0.1-0.4-0.3-0.5-0.5L406,321.2L406,321.2z"
          />
          <path
            fill="#656565"
            d="M403.8,325.4h-0.4l-0.3,4.8h0.4 M404.9,331.3l0.3-0.4H401l0.3,0.4c0.4,0.4,1,0.5,1.5,0.7h0.4
	C404,331.9,404.4,331.8,404.9,331.3L404.9,331.3z"
          />
          <path
            fill="#828282"
            d="M405.3,330.9l-0.3,0.4c-0.4,0.4-1,0.5-1.5,0.7l-0.1,1.8c0.4,0,0.8,0.3,1.2,0.5l0.3,0.3H419
	c0.1-0.3,0.3-0.4,0.5-0.5c0.4-0.3,0.8-0.5,1.4-0.7h0.1v-1.2h-0.1c-0.5,0-1-0.3-1.4-0.7l-0.5-0.5L405.3,330.9L405.3,330.9z"
          />
          <path fill="#656565" d="M403.3,331.9h-0.3l-0.1,1.8h0.4" />
          <path
            fill="#656565"
            d="M403.3,333.7h-0.5c-0.7,0-1.2,0.3-1.6,0.7l-0.3,0.3h4l-0.3-0.3C404.2,334,403.8,333.8,403.3,333.7
	L403.3,333.7z M402.5,339.9h0.4l0.3-4.5h-0.4"
          />
          <path fill="#828282" d="M403.2,335.3l-0.3,4.5h18v-4.5" />
          <path
            fill="#656565"
            d="M404.6,340.6h-4.1l0.4,0.4c0.4,0.4,1,0.7,1.7,0.7h0.4c0.5,0,1-0.3,1.4-0.7L404.6,340.6L404.6,340.6z"
          />
          <path fill="#828282" d="M403.8,325.4l-0.3,4.8h17.5v-4.8" />
          <path
            fill="#656565"
            d="M420.8,322.4h0.4c0.5,0,1.1-0.3,1.5-0.7l0.4-0.5h-4.4c0.1,0.3,0.3,0.4,0.5,0.5
	C419.7,322.1,420.3,322.3,420.8,322.4L420.8,322.4z M420.9,323.6c-0.7,0-1.1,0.3-1.5,0.7c-0.3,0.1-0.4,0.3-0.5,0.5h4.4l-0.4-0.5
	c-0.4-0.4-1-0.5-1.5-0.7H420.9z M420.9,322.4v1.2h0.3v-1.2 M421.2,325.4h-0.3v4.8h0.3 M422.9,331.6l0.4-0.6h-4.4l0.5,0.5
	c0.4,0.3,0.8,0.6,1.4,0.7h0.6C421.9,332.2,422.3,331.9,422.9,331.6L422.9,331.6z"
          />
          <path
            fill="#656565"
            d="M420.9,333.4h0.3v-1.2h-0.3 M421.2,333.4h-0.4c-0.5,0-1,0.3-1.4,0.7c-0.3,0.1-0.4,0.3-0.5,0.5h4.4l-0.4-0.5
	C422.3,333.7,421.9,333.4,421.2,333.4L421.2,333.4z M421.2,335.3h-0.3v4.5h0.3 M423.3,340.6H419l0.4,0.5c0.4,0.4,0.8,0.5,1.4,0.7
	h0.6c0.5,0,1.1-0.3,1.5-0.7L423.3,340.6L423.3,340.6z"
          />
          <path
            fill="#828282"
            d="M420.9,316.1h-16.6l-0.3,4.5h16.9 M418.9,340.6h-14.4L404,341c-0.4,0.3-0.8,0.5-1.4,0.7l-0.1,1.8
	c0.4,0,0.8,0.3,1.2,0.5l0.3,0.1h14.9c0.1-0.3,0.3-0.4,0.5-0.5c0.4-0.4,0.8-0.5,1.4-0.7h0.1v-1.2h-0.1c-0.5-0.1-1-0.3-1.4-0.7
	L418.9,340.6L418.9,340.6z"
          />
          <path
            fill="#828282"
            d="M400.3,340.6h-16l-0.4,0.5c-0.5,0.4-1.1,0.7-1.6,0.7h-0.1l-0.3,1.4c0.4,0,0.8,0.3,1.2,0.5l0.4,0.6h16.8
	l0.3-0.1c0.4-0.4,1.1-0.7,1.6-0.7h0.3l0.1-1.6c-0.7,0-1.2-0.1-1.7-0.7L400.3,340.6L400.3,340.6z"
          />
          <path
            fill="#656565"
            d="M420.9,342.9c-0.7,0-1.1,0.3-1.5,0.7c-0.3,0.1-0.4,0.3-0.5,0.5h4.4l-0.4-0.5c-0.4-0.4-1-0.5-1.5-0.7H420.9z
	 M420.9,342.9h0.3v-1.1h-0.3"
          />
          <path
            fill="#828282"
            d="M421.2,341.8v1.2c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5H438l0.3-0.1c0.3-0.3,0.7-0.5,1.2-0.5l-0.1-1.8
	c-0.5,0-1-0.3-1.4-0.7l-0.4-0.4h-14.3l-0.4,0.5C422.3,341.5,421.9,341.7,421.2,341.8z"
          />
          <path
            fill="#656565"
            d="M421.2,345h-0.3v4.8h0.3 M418.9,350.3l0.4,0.5c0.4,0.3,0.8,0.5,1.4,0.7h0.5c0.5,0,1.1-0.3,1.5-0.7l0.4-0.5
	L418.9,350.3L418.9,350.3z"
          />
          <path
            fill="#828282"
            d="M419.4,350.9l-0.4-0.5h-15l-0.4,0.5c-0.4,0.4-0.8,0.5-1.4,0.7l-0.1,1.6c0.4,0.1,0.7,0.3,1.1,0.5l0.3,0.3
	h15.4c0.1-0.3,0.3-0.4,0.5-0.5c0.4-0.3,0.8-0.5,1.4-0.7h0.1v-1.2h-0.1C420.3,351.4,419.7,351.2,419.4,350.9L419.4,350.9z
	 M422.9,350.9c-0.4,0.4-1,0.5-1.5,0.7v1.2c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5h15.4l0.3-0.3c0.3-0.3,0.7-0.4,1.1-0.5l-0.1-1.6
	c-0.6,0-1-0.3-1.4-0.7c-0.3-0.1-0.3-0.3-0.4-0.5h-14.9L422.9,350.9L422.9,350.9z"
          />
          <path fill="#656565" d="M421.2,351.4h-0.3v1.2h0.3" />
          <path
            fill="#828282"
            d="M420.9,349.8V345h-18.3l-0.3,4.8 M421.2,345v4.8h18.6l-0.3-4.8"
          />
          <path
            fill="#656565"
            d="M421.2,352.7h-0.4c-0.5,0-1,0.3-1.4,0.7c-0.3,0.1-0.4,0.4-0.5,0.5h4.4l-0.4-0.5
	C422.3,352.9,421.9,352.7,421.2,352.7z M421.2,354.6h-0.3v5h0.3"
          />
          <path
            fill="#828282"
            d="M421.2,354.6v5l19.1-0.1l-0.3-4.8 M420.9,359.5v-5h-18.8l-0.4,4.8"
          />
          <path
            fill="#656565"
            d="M402.4,343.3h-0.3c-0.7,0-1.2,0.1-1.7,0.7l-0.3,0.1h3.9l-0.3-0.1c-0.3-0.3-0.7-0.5-1.2-0.5L402.4,343.3
	L402.4,343.3z"
          />
          <path
            fill="#656565"
            d="M402.6,343.5l0.1-1.8h-0.4v1.7 M402.6,345h-0.4l-0.3,4.8h0.4 M399.6,350.3l0.5,0.5c0.4,0.4,1.1,0.7,1.6,0.7
	h0.4c0.5,0,1-0.3,1.4-0.7l0.4-0.5L399.6,350.3L399.6,350.3z"
          />
          <path
            fill="#828282"
            d="M400.2,350.9l-0.5-0.5h-17.7l-0.4,0.4c-0.4,0.4-1,0.5-1.5,0.7l-0.4,1.6c0.4,0,0.8,0.3,1.1,0.5l0.3,0.4h18.3
	l0.3-0.3c0.4-0.4,1.1-0.7,1.6-0.7h0.3l0.1-1.5C401.1,351.4,400.6,351.3,400.2,350.9L400.2,350.9z"
          />
          <path fill="#656565" d="M402.2,351.4h-0.4v1.5l0.3,0.1" />
          <path fill="#828282" d="M402,349.8l0.3-4.8h-20.8l-1.1,4.8" />
          <path
            fill="#656565"
            d="M402.1,354.6h-0.4l-0.3,4.8h0.3 M401.8,352.9h-0.4c-0.7,0-1.2,0.3-1.6,0.7l-0.3,0.3h4l-0.3-0.3
	c-0.3-0.3-0.7-0.4-1.1-0.6L401.8,352.9L401.8,352.9z"
          />
          <path fill="#828282" d="M401.4,359.4l0.3-4.8h-22.4l-1,4.7" />
          <path
            fill="#656565"
            d="M383.4,344.1l-0.4-0.5c-0.3-0.3-0.7-0.5-1.2-0.5h-0.4c-0.7,0-1.2,0.3-1.6,0.7l-0.4,0.5L383.4,344.1
	L383.4,344.1z"
          />
          <path
            fill="#656565"
            d="M381.7,343.2l0.4-1.4h-0.4l-0.3,1.2 M381.5,345h-0.4l-1.1,4.8h0.4 M380,351.4c0.5,0,1.1-0.3,1.5-0.7l0.4-0.4
	h-4.1l0.4,0.4c0.4,0.4,1,0.5,1.5,0.7L380,351.4L380,351.4z M380,351.4h-0.3l-0.4,1.5h0.4"
          />
          <path fill="#828282" d="M380,349.8l1.1-4.8h-19.8l-2.1,4.8" />
          <path
            fill="#656565"
            d="M379.3,354.6H379l-1.1,4.7h0.4 M379.7,352.9h-0.6c-0.7,0-1.2,0.3-1.6,0.7l-0.4,0.4h4.1l-0.3-0.4
	C380.4,353.2,380.1,353.1,379.7,352.9L379.7,352.9z"
          />
          <path
            fill="#828282"
            d="M503.1,349.8c0.5-1.4,0.7-3,0.3-4.8h-22.1l2.1,4.8H503.1L503.1,349.8z M500.7,352.7l1.4-1.4h-13.3
	c-0.7-0.7-2.1-1-3.9-1.1h-1.4l1.5,3.6h0.7c2.2,0,3.3-0.5,3.2-1.2h11.8L500.7,352.7L500.7,352.7z"
          />
          <path
            fill="#656565"
            d="M481.2,345h-0.4l2.2,4.8h0.3 M483.5,350.3h-0.3l1.5,3.6h0.4"
          />
          <path fill="#828282" d="M483,349.8l-2.2-4.8h-19.7l1,4.8" />
          <path fill="#656565" d="M486.8,358.6h0.4l-1.8-4h-0.4" />
          <path
            fill="#828282"
            d="M483.1,359.1c1.2,0,2.5-0.3,3.9-0.5l-1.8-4h-21.9l1,4.7L483.1,359.1z M485.5,354.6l1.8,4
	c3-0.7,6.5-2.1,10.2-4H485.5L485.5,354.6z M484.8,353.9l-1.5-3.6h-18.8l-0.4,0.4c-0.4,0.4-1,0.5-1.5,0.7l0.4,1.5h0.3
	c0.7,0,1.2,0.3,1.6,0.7l0.4,0.4h19.5L484.8,353.9L484.8,353.9z"
          />
          <path
            fill="#656565"
            d="M462.5,351.4h-0.4l0.4,1.5h0.3 M462.1,351.4h0.4c0.5,0,1.1-0.3,1.5-0.7l0.4-0.4h-4.1l0.4,0.4
	C461,351.1,461.5,351.3,462.1,351.4L462.1,351.4z M463,344.1l-0.4-0.5c-0.4-0.4-1.1-0.7-1.6-0.7h-0.4c-0.4,0-0.8,0.3-1.2,0.6
	l-0.4,0.6L463,344.1L463,344.1z"
          />
          <path
            fill="#656565"
            d="M460.7,343l-0.3-1.2H460l0.3,1.4 M461.1,345h-0.4l1.1,4.8h0.3"
          />
          <path
            fill="#828282"
            d="M460.6,350.7l-0.4-0.4h-17.7l-0.5,0.5c-0.4,0.4-1.1,0.7-1.6,0.7l0.1,1.5h0.4c0.7,0,1.2,0.3,1.6,0.7l0.3,0.3
	H461l0.3-0.4c0.3-0.3,0.7-0.4,1.1-0.6l-0.4-1.6C461.5,351.3,461,351.2,460.6,350.7L460.6,350.7z M461.8,349.8l-1.1-4.8h-20.8
	l0.3,4.8"
          />
          <path
            fill="#656565"
            d="M461.4,353.5l-0.3,0.4h4.1l-0.4-0.4c-0.4-0.4-1.1-0.7-1.6-0.7h-0.5C462.1,353.1,461.7,353.2,461.4,353.5
	L461.4,353.5z M463.9,359.3h0.3l-1-4.7h-0.4"
          />
          <path fill="#828282" d="M463.9,359.3l-1.1-4.7h-22.3l0.3,4.8" />
          <path fill="#656565" d="M439.4,341.7v1.8l0.4-0.1l-0.1-1.7" />
          <path
            fill="#656565"
            d="M439.9,343.3h-0.6c-0.4,0-0.8,0.3-1.2,0.5l-0.3,0.1h3.9l-0.3-0.1C441.2,343.6,440.6,343.3,439.9,343.3
	L439.9,343.3z M439.9,345h-0.4l0.3,4.8h0.4 M438.1,350.3c0.1,0.3,0.3,0.4,0.4,0.5c0.4,0.3,0.8,0.5,1.4,0.7h0.4
	c0.7,0,1.2-0.3,1.6-0.7l0.6-0.5L438.1,350.3L438.1,350.3z"
          />
          <path fill="#656565" d="M440.2,351.4h-0.3l0.1,1.6l0.3-0.1" />
          <path
            fill="#656565"
            d="M440.3,352.9h-0.3c-0.4,0.1-0.7,0.3-1.1,0.6l-0.3,0.3h4l-0.3-0.3c-0.4-0.4-1.1-0.7-1.6-0.7L440.3,352.9
	L440.3,352.9z M440.5,354.6h-0.4l0.3,4.8h0.4"
          />
          <path
            fill="#FFFFFF"
            d="M342.9,355l-0.8-0.4c-1-0.5-1.9-1.2-2.6-1.9c-0.4-0.4-0.7-0.8-1-1.4c-0.3-0.5-0.5-1.1-0.7-1.6h-0.5l0.3,1.6
	c0.3,1.1,0.5,2.1,1,3.3l0.4,1.1c1.5,3.7,3.9,7.8,7,12.5h97.8c14.7-0.1,32.2-0.1,52.3,0c3.6-5.1,6.1-9.6,7.4-13.6l0.8-2.9l0.4-2.1
	h-0.4c-0.3,0.5-0.4,1.1-0.8,1.6l-1,1.4c-0.7,0.7-1.5,1.4-2.6,1.9l-0.8,0.4c-4.3,2.3-8.1,3.9-11.6,4.7h-0.4c-1.5,0.4-2.9,0.6-4.3,0.6
	l-69.5,0.4l-54.5-0.4c-1.4,0-2.8-0.3-4.3-0.5h-0.3C351.1,358.9,347.2,357.3,342.9,355L342.9,355z"
          />
          <path
            fill="#656565"
            d="M337.9,343.3v0.3l-0.3,0.7 M337.6,345l0.1-0.5L337.6,345c-0.4,1.5-0.5,3-0.3,4.8h0.5
	C337.3,348.3,337.2,346.8,337.6,345L337.6,345z M325.3,373.6v-1.5h-0.7c-4.5-0.1-12.9,1-25.2,3.3c-2.1,0.4-3.2,0.8-3.4,1.1
	c-1.8-0.3-3-0.8-3.9-1.5c-0.4-0.4-0.7-0.8-0.7-1.4l-1.2-7.2l-0.7-9.4v45.3c0.3,2.9,3.2,4.7,8.5,5.4l7.2,0.6h4.1l7.4-0.5
	c5.4-0.7,8.3-2.5,8.5-5.4L325.3,373.6L325.3,373.6z"
          />
          <path fill="#FFFFFF" d="M325.3,372.1v1.5h21l-1.6-2.2" />
          <path
            fill="#FFFFFF"
            d="M324.5,372.1h0.7l19.4-0.7l64.8-0.1h23.1l64.8,0.1l19.4,0.7h0.7c4.5-0.1,12.9,1,25.2,3.3
	c2.1,0.4,3.2,0.8,3.4,1.1c1.8-0.3,3-0.8,3.8-1.5c0.3-0.8-1-1.4-3.8-1.6c-4-0.1-8-0.5-11.7-1.2c-5.4-1.4-9.8-1.9-13.1-1.8h-26.8
	l-8.5-0.3c-14.6-0.3-28.6-0.4-42.2-0.4h-45.5c-13.6,0-27.8,0-42.2,0.4l-8.5,0.3h-26.8c-3.3-0.1-7.7,0.5-13.1,1.8
	c-3.7,0.8-7.6,1.2-11.7,1.2c-2.9,0.3-4.3,0.8-3.9,1.6c0.8,0.7,2.1,1.2,3.9,1.5c0.3-0.4,1.4-0.7,3.4-1.1
	C311.7,373.2,320.1,372.1,324.5,372.1L324.5,372.1z"
          />
          <path
            fill="#FFFFFF"
            d="M344.7,371.4l1.6,2.2h149.4l1.6-2.2l-64.8-0.1h-23.1 M347.5,370.3l8.5-0.3c14.6-0.3,28.6-0.4,42.2-0.4h45.5
	c13.6,0,27.8,0,42.2,0.4l8.5,0.3l1.5-2.1h-97.8c-14.7-0.1-32.2-0.1-52.3,0L347.5,370.3L347.5,370.3z"
          />
          <path
            fill="#FFFFFF"
            d="M347.5,370.3l-1.5-2.1h-20.6c-13.5-0.1-25-0.8-35.1-1.8l1.1,7.3c0.1,0.5,0.3,1,0.7,1.4
	c-0.4-0.8,1-1.4,3.9-1.7c4-0.1,8-0.5,11.7-1.2c5.4-1.4,9.8-1.9,13.1-1.8L347.5,370.3L347.5,370.3z M550.8,279.5
	c0-1.1-0.7-1.6-2.1-1.9l-1.4-0.1c1,2.5,1.9,4.1,2.9,5c0.1,0,0.4-0.1,0.4-0.4C551.1,281.5,551.1,280.7,550.8,279.5L550.8,279.5z"
          />
          <path
            fill="#EE302D"
            d="M551.1,282.8l-0.3-0.8c-0.1,0.3-0.3,0.4-0.5,0.4L551.1,282.8L551.1,282.8z"
          />
          <path
            fill="#FFFFFF"
            d="M556.2,291.9c-2.2-2.5-3.9-5.5-5.1-9.1l-0.7-0.4c-1.1-0.8-2.1-2.5-2.9-5c-0.7-4.1-2.9-8.5-6.6-13.3
	c-1.1-1.5-2.5-2.3-4.1-2.6c-0.7,0-1.4-0.3-2.5-0.7l-10.6-1.9c5,3.9,6.9,7.3,5.8,10.2c-1.5,4.3-2.5,9.9-2.8,16.8l14.9-2.3
	c2.3-0.8,3.6,2.2,4,9.4c0.1,4.1-0.1,7.3-0.8,9.8l11,7C556,302.9,556.2,297,556.2,291.9L556.2,291.9z"
          />
          <path
            fill="#EBEBEB"
            d="M519.3,293.2l0.7-0.7l-3.4,0.7c-2.6,2.3-3.4,5.5-2.6,9.4l-1.5-0.7l-10,0.7l-17.9,1.2
	c-1.6,0.1-2.3-0.1-1.9-0.8c1.4-2.1,3.2-3.9,5.4-5.6l14.4-1.8l12.4-2.1l1.7-0.3l3.4-0.7l7-1.6l2.2-0.5l-0.4-2.9
	c6.1-1.1,10.3-2.3,12.8-4.1l-14.9,2.3c-2.9,2.6-6.6,4.5-11.3,5.9c-9.4,2.5-18.7,4.1-27.8,5l-0.5,0.5l-9.6,8.1l1.1,0.4l32.5-2.9
	l-7.7,8.7c3.4-0.4,7.1-1,11.4-1.8c3-0.6,6.2-1.4,9.8-2.2c-2.1-0.1-3.7-1-5.2-2.5c-1.7-1.6-2.5-3.6-2.5-5.9
	C516.8,296.8,517.6,294.9,519.3,293.2L519.3,293.2z"
          />
          <path
            fill="#EBEBEB"
            d="M520.1,292.5l-0.7,0.7c-1.7,1.6-2.5,3.7-2.5,5.9c0,2.3,0.8,4.3,2.5,5.9c1.5,1.5,3.2,2.3,5.2,2.5l5.5-1.5
	l1.2-1.1c1.7-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9c-1.2-1.2-2.6-1.9-4.3-2.3L520.1,292.5L520.1,292.5z M520,293.8
	c1.5-1.5,3.3-2.2,5.4-2.2c2.1,0,3.9,0.7,5.4,2.2c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.8,3.9-2.2,5.4c-1.5,1.5-3.3,2.2-5.4,2.2
	c-2.1,0-3.9-0.7-5.4-2.2c-1.5-1.5-2.2-3.3-2.2-5.4C517.8,297.1,518.5,295.3,520,293.8L520,293.8z"
          />
          <path
            fill="#EBEBEB"
            d="M525.4,291.6c-2.1,0-3.9,0.7-5.4,2.2c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4
	c1.5,1.5,3.3,2.2,5.4,2.2c2.1,0,3.9-0.7,5.4-2.2c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.8-3.9-2.2-5.4
	C529.2,292.3,527.4,291.6,525.4,291.6z M520.4,294.3c1.4-1.4,3-1.9,4.8-1.9c1.9,0,3.4,0.7,4.8,1.9c1.4,1.4,2.1,2.9,2.1,4.8
	c0,1.9-0.7,3.4-2.1,4.8s-3,2.1-4.8,2.1c-1.9,0-3.4-0.7-4.8-2.1c-1.4-1.4-2.1-2.9-2.1-4.8C518.5,297.2,519.2,295.7,520.4,294.3
	L520.4,294.3z"
          />
          <path
            fill="#EBEBEB"
            d="M525.4,292.4c-1.9,0-3.4,0.7-4.8,1.9c-1.4,1.4-2.1,2.9-2.1,4.8s0.7,3.4,2.1,4.8c1.4,1.4,3,2.1,4.8,2.1
	c1.9,0,3.4-0.7,4.8-2.1c1.4-1.4,2.1-2.9,2.1-4.8c0-1.9-0.7-3.6-2.1-4.8C528.8,293,527.1,292.4,525.4,292.4L525.4,292.4z
	 M516.7,293.2l-1.7,0.3c-2.2,2.2-3,5-2.3,8.4l1.5,0.7C513.2,298.7,514.1,295.6,516.7,293.2L516.7,293.2z"
          />
          <path
            fill="#FFFFFF"
            d="M541.6,283.5c0.3,0,0.7,0.1,1,0.3c0.7,0.4,1,1.4,1,2.6c0,2.3,0,4.4-0.1,6.3c-0.3,3.7-0.7,6.6-1.4,8.9
	l-0.3,0.8h3c0.7-2.5,1-5.6,0.8-9.8C545.3,285.7,543.9,282.6,541.6,283.5L541.6,283.5z"
          />
          <path
            fill="#EBEBEB"
            d="M542.5,283.7c-0.3-0.1-0.5-0.3-1-0.3c-2.5,1.7-6.7,3-12.8,4.1l0.4,2.9l2.6-0.7l5.5-1.6
	c0.8-0.4,1.5-0.7,2.1-1.1C541.2,285.8,542.1,284.7,542.5,283.7L542.5,283.7z"
          />
          <path
            fill="#EBEBEB"
            d="M527,290.9c1.7,0.3,3,1.1,4.3,2.3c1.7,1.6,2.5,3.7,2.5,5.9c0,2.3-0.8,4.3-2.5,5.9l-1.2,1.1h0.1
	c4.3-1.9,6.1-4.5,5.8-8.1l-1.4-4.3c-0.8-1.8-1.7-3.2-2.5-4.1l-2.6,0.7L527,290.9L527,290.9z"
          />
          <path
            fill="#EBEBEB"
            d="M532,289.7c1,1,1.8,2.3,2.5,4.1l1.4,4.3l4-2.9c0.4-3.4,0.3-6.2-0.4-8.4c-0.7,0.4-1.4,0.8-2.1,1.1L532,289.7
	L532,289.7z"
          />
          <path
            fill="#EBEBEB"
            d="M543.4,292.7l-2.2,1.6l-1.5,8.9c-0.5,1.4-2.1,2.5-4.5,3.2c-6.1,1.5-12.8,3-20.2,4.4l-12.1,2.1
	c-0.3,0.8-0.1,1,0.7,0.7l11.4-1.8c7.7-1.4,15-2.9,21.9-4.5c2.6-0.5,4.3-2.1,5-4.5l0.3-0.8C542.7,299.3,543.2,296.4,543.4,292.7
	L543.4,292.7z"
          />
          <path
            fill="#EBEBEB"
            d="M541.2,294.2l2.2-1.6c0.1-1.9,0.1-4,0.1-6.3c0-1.4-0.4-2.2-1-2.6c-0.4,1.1-1.4,2.1-3,3
	c0.7,2.2,0.8,5,0.4,8.4L541.2,294.2L541.2,294.2z"
          />
          <path
            fill="#EBEBEB"
            d="M535.8,298.1c0.4,3.6-1.5,6.2-5.8,8.1h-0.1l-5.5,1.5c-3.4,0.8-6.7,1.5-9.8,2.2c-4.3,0.8-8.1,1.4-11.4,1.8
	c-0.3,0.5-0.5,1-0.7,1.4l12.1-2.1c7.3-1.4,14-2.9,20.2-4.4c2.6-0.7,4.1-1.8,4.5-3.2l1.5-8.9l-1.4,1L535.8,298.1L535.8,298.1z
	 M512.7,301.9c-0.7-3.4,0.1-6.2,2.3-8.4l-12.4,2.1v7L512.7,301.9L512.7,301.9z M508.8,298.6c0,0.3-0.1,0.5-0.3,0.8
	c-0.1,0.3-0.5,0.3-0.8,0.3s-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3
	C508.7,298.1,508.8,298.3,508.8,298.6L508.8,298.6z"
          />
          <path
            fill="#EBEBEB"
            d="M508.6,299.4c0.1-0.3,0.3-0.6,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3C508,299.8,508.3,299.7,508.6,299.4L508.6,299.4z"
          />
          <path fill="#EE302D" d="M551.9,365.5l-0.1,1" />
          <path
            fill="#FFFFFF"
            d="M546.1,373.3c2.9,0.3,4.3,0.8,3.9,1.6c0.4-0.4,0.7-0.8,0.7-1.4l1.1-7.3c-9.9,1.1-21.6,1.6-34.9,1.8h-20.6
	l-1.5,2.1h26.7c3.3-0.1,7.7,0.5,13.1,1.8C538.1,372.9,542,373.3,546.1,373.3L546.1,373.3z"
          />
          <path
            fill="#656565"
            d="M550.7,373.7c-0.1,0.5-0.3,1-0.7,1.4c-0.7,0.7-2.1,1.2-3.9,1.5c-0.3-0.4-1.4-0.7-3.4-1.1
	c-12.2-2.3-20.6-3.3-25.2-3.3h-0.7v30.3c0.3,2.9,3.2,4.7,8.5,5.4l7.4,0.5h4.1l7.2-0.5c5.4-0.7,8.3-2.5,8.5-5.4v-45.3l-0.7,8.4
	l-0.1,1L550.7,373.7L550.7,373.7z"
          />
          <path fill="#FFFFFF" d="M516.8,373.6v-1.5l-19.4-0.7l-1.6,2.2" />
          <path
            fill="#828282"
            d="M465.7,302.9c1.9-1.1,4.7-2.8,8.1-5l-15.4,0.3c-5.8,0-11.7-0.1-17.7-0.3h-13.5c0.5,0.1,1.1,0.4,1.5,0.7
	l9.6,0.1h19l12.1-0.1c1.5-0.1,1.9,0,1.2,0.7c-1.5,1.1-3.2,2.2-5,3.2l-1.2,0.7c-1.6,0.8-3.7,1.2-6.5,1.4h-1.4l-17.3,0.3h-3.7
	c-0.8,0-1.4-0.5-1.8-1.2c-1.1-1.9-2.5-3.4-4.1-4.4l-0.7-0.4c-0.4-0.3-1-0.4-1.5-0.7h-1.6l0.3,0.1c3.3,0.8,5.8,2.8,7.3,5.6
	c0.6,1.1,1.2,1.6,2.3,1.6l16-0.1l6.6-0.3c2.9-0.1,5.1-0.5,6.7-1.5L465.7,302.9L465.7,302.9z"
          />
          <path
            fill="#656565"
            d="M464.1,303l1.2-0.7c1.8-1,3.4-2.1,5-3.2c0.8-0.5,0.4-0.8-1.2-0.7l-12.1,0.1v5.8h0.5
	C460.3,304.2,462.5,303.8,464.1,303L464.1,303z"
          />
          <path fill="#828282" d="M456.2,304.4h0.8v-5.8h-0.8" />
          <path fill="#656565" d="M456.2,304.4v-5.8h-17.3v6.1" />
          <path fill="#828282" d="M438.8,304.7v-6.1H438v6.1" />
          <path
            fill="#656565"
            d="M438,304.7v-6.1l-9.6-0.1l0.7,0.4c1.8,1.1,3.2,2.5,4.1,4.4c0.4,0.8,1,1.2,1.8,1.2h3L438,304.7L438,304.7z"
          />
          <path
            fill="#EBEBEB"
            d="M502.7,302.7v-7l-14.4,1.8c-2.3,1.8-4.1,3.6-5.4,5.6c-0.4,0.7,0.3,1,1.9,0.8L502.7,302.7z M494,300.8
	c-0.3,0.3-0.5,0.3-0.8,0.3s-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.1,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3
	c0.1,0.3,0.3,0.5,0.3,0.8S494.3,300.5,494,300.8L494,300.8z"
          />
          <path
            fill="#EBEBEB"
            d="M493.2,301.1c0.3,0,0.5-0.1,0.8-0.3c0.1-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.1-0.5-0.3-0.8-0.3
	s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8C492.6,300.9,492.9,301.1,493.2,301.1L493.2,301.1z"
          />
          <path
            fill="#828282"
            d="M384.5,304.9l6.6,0.3l16,0.1c1,0,1.8-0.5,2.3-1.6c1.5-2.9,4-4.8,7.3-5.6l0.3-0.1h-1.6
	c-0.5,0.1-1.1,0.4-1.5,0.7l-0.7,0.4c-1.8,1.1-3.2,2.5-4.1,4.4c-0.4,0.8-1,1.2-1.8,1.2h-3.7l-17.3-0.3h-1.4c-2.8-0.1-5-0.5-6.5-1.4
	l-1.2-0.7c-1.8-1-3.4-2.1-5-3.2c-0.8-0.5-0.4-0.8,1.2-0.7l12.1,0.1h19l9.6-0.1c0.5-0.3,1-0.4,1.5-0.7h-13.9
	c-6.1,0.1-12,0.3-17.7,0.3l-15.4-0.3c3.4,2.2,6.1,3.9,8,5l1.2,0.7C379.4,304.4,381.6,304.9,384.5,304.9L384.5,304.9z"
          />
          <path fill="#828282" d="M385.9,298.6H385v5.8h0.8" />
          <path
            fill="#656565"
            d="M385.9,298.6v5.8l17.3,0.3v-6.1 M384.5,304.4h0.5v-5.8l-12.1-0.1c-1.5-0.1-1.9,0-1.2,0.7
	c1.5,1.1,3.2,2.2,5,3.2l1.2,0.7C379.5,303.8,381.7,304.2,384.5,304.4L384.5,304.4z M413,298.9l0.7-0.4l-9.6,0.1v6.1h2.9
	c0.8,0,1.4-0.5,1.8-1.2C409.8,301.4,411.2,300,413,298.9z"
          />
          <path fill="#828282" d="M403.2,298.6v6.1h0.8v-6.1" />
          <path
            fill="#656565"
            d="M504.3,343.6l-0.1-0.3l0.3,1 M504.6,345l-0.1-0.5L504.6,345c0.3,1.8,0.1,3.4-0.3,4.8h0.4
	C505.1,348,505,346.3,504.6,345z"
          />
          <path
            fill="#F2F2F2"
            d="M531.3,244.7l-6.2-7.2l9.2,23.4c1,0.4,1.8,0.7,2.5,0.7c0.5,0,1,0,1.1-0.3c0.5-1.1,1.8-1.9,3.9-2.3l17.5-1
	c-0.3-0.7-1.5-1-3.8-0.8h-0.6c-4.5,0.3-8.8,0.3-12.7,0c-1.2-0.4-2.2-2.1-3.2-5l-0.7,0.1c-1.8,0.3-3.2-0.1-3.9-1.2L531.3,244.7
	L531.3,244.7z"
          />
          <path
            fill="#F2F2F2"
            d="M571.9,256.5c2.8-0.4,4.5-1.4,5.2-2.9l-28.1,2.7c-4.4,0.7-6.7-1.1-7.2-5.1c-0.7,0.6-1.7,0.8-3,0.8
	c1,2.9,2.1,4.5,3.2,5c3.9,0.4,8,0.4,12.7,0h0.5c2.3-0.3,3.7,0,3.9,0.8L571.9,256.5L571.9,256.5z"
          />
          <path
            fill="#FFFFFF"
            d="M547.5,277.6l1.4,0.1c-1.5-3-2.6-6.7-3.3-11.1c-1.2-4.5-2.5-7-3.9-7.6c-1.9,0.4-3.2,1.2-3.9,2.3
	c-0.3,0.3-0.7,0.3-1.1,0.3c1.7,0.3,3,1.1,4.1,2.6C544.5,268.9,546.8,273.4,547.5,277.6L547.5,277.6z"
          />
          <path
            fill="none"
            d="M423,306.4l-0.5,1.1l-1.2,1.6l-0.1,0.1l-0.1,0.1l2.8-0.3c0.8-0.1,1.6-0.4,2.3-0.8c1.5-0.8,2.5-1.8,2.8-3.2h-5.5
	L423,306.4L423,306.4z M424.5,303.7v0.8h-2.3v-0.4c0-0.1,0-0.3,0.1-0.4L424.5,303.7L424.5,303.7z"
          />
          <path
            fill="none"
            d="M419.4,305.2h-6.1c0.4,1.2,1.4,2.2,2.8,3c1.4,0.8,3,1.2,4.8,1.2c0.1-0.7,0.1-1.2,0.1-1.9
	c-0.1-0.7-0.3-1.2-0.5-1.6l-0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3L419.4,305.2L419.4,305.2z M417.1,303.6v1c0,0.1,0.1,0.3,0.4,0.3h3
	v-0.3h-2.9v-0.4v-0.4h2.9v-0.3h-3C417.2,303.4,417.1,303.4,417.1,303.6L417.1,303.6z M416.7,303.4h-3c-0.1,0-0.3,0.1-0.4,0.3v0.5
	h2.9c0,0.1,0,0.3-0.1,0.3h-2.8l0.1,0.3h2.8l0.3-0.1l0.1-0.3V304h-2.9v-0.3h2.9L416.7,303.4L416.7,303.4z M420.9,299
	c-1.8,0-3.4,0.4-4.8,1.1c-0.7,0.3-1.1,0.7-1.6,1.2c-0.4,0.5-0.8,1.1-1.1,1.6h5.2c0.1-0.8,0.4-1.5,0.8-2.2
	C420,300.3,420.4,299.7,420.9,299L420.9,299z M426,300c-0.7-0.4-1.4-0.5-2.2-0.8l-2.8-0.3c-0.1,0.5-0.1,1.1-0.1,1.8s0.1,1.2,0.3,1.5
	l0.3,0.4l0.3,0.3h6.9C428.4,301.8,427.5,300.8,426,300L426,300z M424.9,303.4h-3l-0.1,0.1l-0.1,0.5l0.1,0.4l0.3,0.3h2.9l0.1-0.3
	v-0.8 M428.5,303.4h-3v1.4h0.5v-1.1h2.5v1.1c0.3,0,0.4,0,0.4-0.1v-1.1C428.8,303.4,428.6,303.4,428.5,303.4L428.5,303.4z"
          />
          <path
            fill="none"
            d="M427.1,299.7c-1.9-1-4.1-1.4-6.6-1.2l-1.8,0.1c-1.6,0.3-3,0.8-4.3,1.5c-0.4,0.3-0.8,0.5-1.1,0.8
	c-0.4,0.3-0.7,0.7-1,1c-0.3,0.4-0.5,0.8-0.7,1.4c-0.1,0.5-0.1,1.1-0.1,1.6c0.1,0.4,0.3,1,0.5,1.4c0.3,0.4,0.5,0.7,0.8,1.1
	c0.5,0.7,1.4,1.1,2.2,1.6c1,0.4,1.8,0.8,2.9,1l3.6,0.3c1.2,0,2.3-0.3,3.3-0.5s1.8-0.7,2.8-1.2l1.1-0.8l1-1l0.7-1.2
	c0.3-0.5,0.3-1.1,0.1-1.7c0-0.4-0.3-1-0.4-1.4c-0.3-0.4-0.5-0.7-0.8-1.1l-1.1-0.8C428,300.1,427.5,299.8,427.1,299.7L427.1,299.7z"
          />
          <path
            fill="none"
            d="M420.8,303.4v1.4h0.5v-1.4 M301,294.2l-2.3-1.5 M302.6,286.8c-1.6-1.1-2.6-2.1-3-3 M310.2,289.7l-5.5-1.6
	c-0.8-0.4-1.5-0.7-2.1-1.1 M312.8,290.4l-2.6-0.7"
          />
          <path
            fill="none"
            d="M306.2,298.1c0.3-1.2,0.7-2.3,1.1-3.4l0.4-1c0.8-1.8,1.7-3.2,2.5-4.1 M321.6,294.3c1.4,1.4,2.1,2.9,2.1,4.8
	c0,1.9-0.7,3.4-2.1,4.8c-1.4,1.4-3,2.1-4.8,2.1c-1.9,0-3.4-0.7-4.8-2.1c-1.4-1.4-2.1-2.9-2.1-4.8c0-1.9,0.7-3.6,2.1-4.8
	c1.4-1.4,3-1.9,4.8-1.9C318.7,292.4,320.2,293,321.6,294.3L321.6,294.3z"
          />
          <path
            fill="none"
            d="M322.2,293.8c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4c-1.5,1.5-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2
	c-1.5-1.5-2.2-3.3-2.2-5.4c0-2.1,0.8-3.9,2.2-5.4c1.5-1.5,3.3-2.2,5.4-2.2S320.7,292.3,322.2,293.8L322.2,293.8z M322,292.5l-7-1.6"
          />
          <path
            fill="none"
            d="M315,290.9c-1.6,0.3-3,1.1-4.3,2.3c-1.6,1.6-2.5,3.7-2.5,5.9c0,2.3,0.8,4.3,2.5,5.9c0.4,0.4,0.8,0.7,1.2,1.1
	 M315,290.9l-2.2-0.5 M312.8,290.4l0.4-2.9c-6.1-1.1-10.3-2.3-12.8-4.1 M312.1,306.2c-4.3-1.9-6.2-4.5-5.8-8.1 M312.1,306.2l5.4,1.4
	"
          />
          <path
            fill="none"
            d="M301,294.2l1.5,8.9c0.5,1.4,2.1,2.5,4.5,3.2c6.1,1.5,12.8,3,20.2,4.4l12.1,2.1 M302.2,295.2l4,2.9 M339.4,295.7
	l14.4,1.8c2.3,1.8,4.1,3.6,5.4,5.6c0.4,0.7-0.3,1-1.9,0.8l-17.9-1.2"
          />
          <path
            fill="none"
            d="M349.7,300.8c-0.3,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8
	c0.3-0.1,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.3,0.3,0.3,0.5,0.3,0.8C350.1,300.3,350,300.5,349.7,300.8L349.7,300.8z
	 M333.6,299.4c-0.1-0.3-0.3-0.6-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.3,0.1,0.3,0.5,0.3,0.8
	s-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3C334.1,299.8,333.9,299.7,333.6,299.4L333.6,299.4z M339.4,302.7v-7 M317.5,307.5
	c2.1-0.1,3.7-1,5.2-2.5c1.6-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9l-0.7-0.7 M329.5,301.9l-1.5,0.7c1-3.9,0-7-2.6-9.4
	 M338.7,311.5c-3.4-0.4-7.2-1-11.4-1.8l-9.8-2.2 M339.4,302.7l-9.9-0.8 M327.1,293.5c2.2,2.2,3,5,2.3,8.4 M322,292.5l3.4,0.7
	 M302.2,295.2l-1.2-1 M302.6,286.8c-0.7,2.2-0.8,5-0.4,8.4 M327.1,293.5l12.2,2.2 M358.5,350.3h0.4 M359.2,349.8h-0.4 M340.1,351.3
	h13.3c0.7-0.7,2.1-1,3.9-1.1h1.4 M357,353.9h-0.7c-2.2,0-3.3-0.5-3.2-1.2h-11.8 M357,354.6h-0.3 M357,353.9h0.4 M354.9,358.6l1.8-4
	 M357,354.6l-1.6,4 M358.5,350.3l-1.5,3.6 M356.7,354.6h-12 M358.9,350.3l-1.5,3.6 M337.3,349.8h0.4 M339.1,349.8h19.7 M362.8,340.6
	h-0.1c-1.8,0-3,0.3-3.9,1h-18.8 M361.2,344.1c-1.8-0.1-2.8-0.5-2.6-1.2h-19.3 M325.5,293.2l1.6,0.3 M314.9,232.6v-35.5l0.1-0.3v-4
	l-0.1-0.3l-0.3-0.1c-0.1,0-0.1,0-0.3,0.1c0,0-0.1,0.1-0.1,0.3v4l0.1,0.3l-0.1,36.5 M347.9,330.2h19.4 M358.2,320.6h13.3 M361,345
	h-22.1 M359.2,349.8h20.8 M377.8,350.3h-18.8 M358.8,349.8l2.2-4.8 M361.2,345l-2.1,4.8 M357.4,353.9h19.5 M379,354.6h-22
	 M344,335.3h21 M363.2,339.9H341 M369.5,325.4h-16.9 M515,293.5l-12.4,2.2 M502.7,302.7l10-0.8 M520.1,292.5l-0.7,0.7
	c-1.7,1.6-2.5,3.7-2.5,5.9c0,2.3,0.8,4.3,2.5,5.9c1.5,1.5,3.2,2.3,5.2,2.5 M516.7,293.2l3.4-0.7 M516.7,293.2l-1.7,0.3"
          />
          <path
            fill="none"
            d="M520.4,294.3c-1.4,1.4-2.1,2.9-2.1,4.8c0,1.9,0.7,3.4,2.1,4.8c1.4,1.4,3,2.1,4.8,2.1c1.9,0,3.4-0.7,4.8-2.1
	s2.1-2.9,2.1-4.8c0-1.9-0.7-3.6-2.1-4.8c-1.4-1.4-3-1.9-4.8-1.9S521.8,293,520.4,294.3z"
          />
          <path
            fill="none"
            d="M520,293.8c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4c1.5,1.5,3.3,2.2,5.4,2.2c2.1,0,3.9-0.7,5.4-2.2
	c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.8-3.9-2.2-5.4c-1.5-1.5-3.3-2.2-5.4-2.2C523.2,291.6,521.5,292.3,520,293.8L520,293.8z
	 M529.2,290.4l-0.4-2.9c6.1-1.1,10.3-2.3,12.8-4.1"
          />
          <path
            fill="none"
            d="M530,306.2l1.2-1.1c1.7-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9c-1.2-1.2-2.6-1.9-4.3-2.3 M532,289.7
	c1,1,1.8,2.3,2.5,4.1l1.4,4.3 M529.2,290.4l2.8-0.7 M527,290.9l2.2-0.5 M532,289.7l5.5-1.6c0.8-0.4,1.5-0.7,2.1-1.1 M541.2,294.2
	l2.2-1.5 M542.5,283.7c-0.4,1.1-1.4,2.1-3,3 M520.1,292.5l6.9-1.6"
          />
          <path fill="none" d="M535.8,298.1c0.4,3.6-1.5,6.2-5.8,8.1h-0.1" />
          <path
            fill="none"
            d="M502.7,312.9l12.1-2.1c7.3-1.4,14-2.9,20.2-4.4c2.6-0.7,4.1-1.8,4.5-3.2l1.5-8.9 M539.9,295.2l-4.1,2.9
	 M508.8,298.6c0-0.3-0.1-0.5-0.3-0.8c-0.1-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3C508.7,299.3,508.8,299,508.8,298.6L508.8,298.6z M512.7,301.9l1.5,0.7c-1-3.9,0-7,2.6-9.4
	"
          />
          <path
            fill="none"
            d="M524.5,307.5c-3.4,0.8-6.7,1.5-9.8,2.2c-4.3,0.8-8.1,1.4-11.4,1.8 M524.5,307.5l5.5-1.4 M539.5,286.8
	c0.7,2.2,0.8,5,0.4,8.4 M541.2,294.2l-1.2,1 M512.7,301.9c-0.7-3.4,0.1-6.2,2.3-8.4 M480.5,344.1l-1.5-3.6 M480.9,344.1l-1.7-3.6
	 M363.2,340.6l-1.5,3.6 M423.3,344.1H438 M439.5,345h-18.3 M439,335.3h-17.7 M437.3,334.5h-14 M423.3,330.9h13.6 M421.2,330.2h17.5
	 M421.2,320.6H438 M457,304.4h0.5c2.8-0.1,5-0.5,6.5-1.4l1.2-0.7c1.8-1,3.4-2.1,5-3.2c0.8-0.5,0.4-0.8-1.2-0.7l-12.1,0.1
	 M456.2,304.4h0.8 M456.2,304.4v-5.8 M457,304.4v-5.8 M457,298.6h-0.8 M438.8,304.7v-6.1 M438,304.7v-6.1 M438,298.6l-9.6-0.1
	l0.7,0.4c1.8,1.1,3.2,2.5,4.1,4.4c0.4,0.8,1,1.2,1.8,1.2h2.9 M438,304.7h0.8 M438.8,298.6H438 M438.6,322.4c0.5,0,1.1-0.3,1.5-0.7
	l0.5-0.5 M440.9,324.7l-0.5-0.5c-0.4-0.4-1-0.7-1.6-0.7h-0.1 M436.2,321.2l0.4,0.5c0.4,0.4,1,0.5,1.5,0.7 M438,320.6h0.4
	 M438.1,322.4h0.4 M438.3,323.6c-0.4,0-0.8,0.3-1.2,0.5c-0.3,0.1-0.4,0.3-0.4,0.5 M438.7,325.4h-0.4 M436.6,324.7h4.3 M438.6,323.6
	l-0.1-1.2 M438.3,323.6v-1.2 M438.6,323.6h-0.4 M440.5,321.2h-4.4 M438.7,330.2h0.4 M441,330.9l-0.3,0.4c-0.4,0.4-1,0.5-1.5,0.7
	 M438.7,331.9h0.4 M441.4,334.5l-0.3-0.3c-0.4-0.4-1.1-0.7-1.7-0.7h-0.1 M439.2,333.7h-0.4 M438.8,333.7l-0.1-1.8 M439.1,331.9
	l0.1,1.6 M438.7,331.9c-0.5,0-1.1-0.3-1.5-0.7l-0.3-0.4 M438.8,333.7c-0.4,0-0.8,0.3-1.2,0.5l-0.3,0.3 M439.2,339.9h0.3
	 M439.4,335.3l0.3,4.5 M439,335.3h0.4 M439.2,339.9l-0.3-4.5 M441.7,340.6h-4.1 M436.9,330.9h4.1 M437.3,334.5h4 M439,330.2
	l-0.3-4.8 M438.7,330.2l-0.3-4.8 M470.7,321.2h-0.3 M470.2,320.6h0.4 M456.2,322.3c0.4,0,0.8-0.3,1.2-0.5l0.4-0.4 M455.5,320.6h0.4
	 M453.5,321.2l0.4,0.4c0.4,0.4,1.1,0.7,1.6,0.7h0.1 M455.7,322.3h0.4 M456.8,325.4h-0.4 M456.4,323.8H456 M456.2,323.8
	c-0.6,0-1,0.3-1.4,0.5l-0.4,0.4 M458.5,324.7l-0.4-0.4c-0.4-0.4-1-0.5-1.6-0.5 M454.4,324.7h4.1 M455.7,322.3l0.3,1.5 M456.4,323.8
	l-0.3-1.5 M457.8,321.2h-4.1 M458.2,332c0.5,0,1-0.3,1.4-0.7l0.4-0.4 M457.9,332h0.4 M457.5,330.2h0.4 M461,334.5
	c-0.1-0.3-0.3-0.5-0.5-0.7c-0.6-0.4-1.1-0.7-1.6-0.7h-0.1 M458.5,333.3h-0.4 M457.9,332l0.3,1.2 M458.5,333.3l-0.3-1.2 M455.9,330.9
	l0.4,0.4c0.5,0.4,1.1,0.7,1.6,0.7 M458.2,333.3c-0.4,0-0.8,0.3-1.2,0.5c-0.3,0.3-0.4,0.4-0.5,0.7 M459.6,339.9h0.4 M460,339.9
	l-1-4.5 M459.6,339.9l-1-4.5 M458.9,335.3h-0.4 M460,330.9h-4.1 M456.4,334.5h4.5 M462.1,340.6h-4.3 M456.4,325.4l1.1,4.8
	 M455.7,320.6h14.3 M457.8,321.2h12.7 M457.9,330.2l-1.1-4.8 M423.3,321.2h12.9 M362.8,340.6l-1.5,3.6 M456.4,325.4h-17.7
	 M440.9,324.7h13.5 M457.8,340.6h-16 M439.5,339.9h20.1 M438.8,298.6h17.3 M440.5,321.2h13.1 M456.4,334.5h-15.1 M441,330.9h14.9
	 M457.5,330.3H439 M438.4,320.6h16.9 M438.1,316.1l0.3,4.5 M455.7,320.6l-1-4.5 M455.5,320.6l-1-4.5 M470.2,320.6l-1.9-4.5
	 M470.5,320.6l-1.9-4.5 M438,320.6l-0.3-4.5 M458.6,335.3h-19.3 M438.8,304.7l17.3-0.3 M502.7,302.7v-7 M494,300.8
	c0.1-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.1-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8
	c0.3,0.3,0.5,0.3,0.8,0.3S493.7,300.9,494,300.8L494,300.8z M421.2,339.9h18 M437.6,340.6h-14.3 M502.7,295.7l-14.4,1.8
	c-2.3,1.8-4.1,3.6-5.4,5.6c-0.4,0.7,0.3,1,1.9,0.8l17.9-1.2 M421.2,349.8h18.6 M438.1,350.3h-14.9 M472.7,325.4h-0.4 M472.3,324.7
	c1.7-0.1,2.5-0.5,2.3-1.2h12.8 M472,324.7h0.4 M476.7,334.5c1.6-0.1,2.3-0.5,2.2-1.2h17.9 M476.2,334.5h0.4 M475.1,330.9h-0.4
	 M474.5,330.3h0.4 M476.7,335.3l2.1,4.5 M476.9,335.3h-0.4 M479,339.9l-2.1-4.5 M479.3,340.6h-0.4 M502.1,341.7h-18.8
	c-0.7-0.7-1.9-1-3.9-1h-0.1 M478.6,339.9h0.4 M474.7,330.9l1.5,3.6 M495.6,332h-16.9c-0.7-0.7-1.9-1-3.6-1.1 M476.7,334.5l-1.5-3.6
	 M489.6,325.4h-16.8 M485.9,322.3h-11.4c-0.7-0.7-1.9-1-3.7-1.1 M472.3,325.4l2.1,4.8 M474.7,330.3l-2.1-4.8 M479,339.9h22.1
	 M474.7,330.3h19.4 M476.9,335.3h21.2 M472.3,325.4h-15.4 M479,340.6h-16.8 M460,339.9h18.7 M470.5,321.2l1.5,3.6 M460,330.9h14.7
	 M458.9,335.3h17.6 M474.5,330.3H458 M484,320.6h-13.5 M423.3,353.9h15.4 M472.3,324.8l-1.5-3.6 M458.5,324.7H472 M476.2,334.5H461
	 M440.1,354.6h-18.8 M385,298.6h0.8 M385.9,298.6v5.8 M385.9,304.4H385 M385,304.4h-0.5c-2.8-0.1-5-0.5-6.5-1.4l-1.2-0.7
	c-1.8-1-3.4-2.1-5-3.2c-0.8-0.5-0.4-0.8,1.2-0.7l12.1,0.1 M385,298.6v5.8 M371.7,321.2h-0.4 M371.6,320.6h0.4 M369.8,324.7h0.4
	 M369.8,325.4h-0.4 M371.3,321.2l-1.5,3.6 M371.7,321.2l-1.5,3.6 M367,330.9l-1.5,3.6 M367.4,330.9H367 M367.3,330.2h0.4
	 M365.8,334.5l1.5-3.6 M365.5,334.5h0.4 M363.2,340.6h-0.4 M363.2,339.9h0.4 M365.5,335.3h-0.4 M365.1,335.3l-2.1,4.5 M345.4,333.4
	h17.9c-0.1,0.7,0.7,1.1,2.2,1.2 M346.5,332h16.9c0.7-0.7,1.9-1,3.6-1.1 M365.5,335.3l-2.1,4.5 M354.6,323.5h12.8
	c-0.1,0.7,0.7,1.1,2.3,1.2 M356.2,322.3h11.4c0.7-0.7,1.9-1,3.7-1.1 M367.3,330.2l2.1-4.8 M369.8,325.4l-2.1,4.8 M388.6,321.2
	l-0.4,0.4c-0.5,0.4-1.1,0.7-1.6,0.7h-0.1 M386,323.8c0.5,0,1,0.3,1.4,0.5l0.4,0.4 M386,322.3c-0.4,0-0.8-0.3-1.2-0.5l-0.4-0.4
	 M386.4,320.6h0.4 M386.4,322.3h-0.3 M385.6,325.4h-0.4 M385.6,323.8h0.4 M383.7,324.7l0.4-0.4c0.4-0.4,1-0.5,1.6-0.5 M386,323.8
	l0.3-1.5 M385.6,323.8l0.3-1.5 M386.3,330.9l-0.4,0.4c-0.5,0.4-1.1,0.7-1.6,0.7 M383.8,332h0.4 M384.2,330.2h0.4 M383.9,333.3h-0.4
	 M383.5,333.3h-0.1c-0.7,0-1.2,0.3-1.6,0.7c-0.3,0.3-0.4,0.4-0.5,0.7 M383.9,333.3c0.4,0,0.8,0.3,1.2,0.5c0.3,0.3,0.4,0.4,0.5,0.7
	 M384.2,332l-0.3,1.2 M383.8,332l-0.3,1.2 M382.2,330.9l0.4,0.4c0.4,0.4,0.8,0.5,1.4,0.7 M382.2,339.9h0.4 M383.5,335.3h-0.4
	 M384.4,340.6h-4.3 M382.2,330.9h4.1 M382.6,339.9l1-4.5 M383.1,335.3l-1,4.5 M381.2,334.5h4.5 M388.6,321.2h-4.1 M383.7,324.7h4.1
	 M385.3,325.4l-1.1,4.8 M384.6,330.2l1.1-4.8 M385.3,325.4h-15.4 M380,340.6h-16.8 M363.4,339.9h18.7 M372,320.6h14.3 M367.7,330.2
	h16.5 M384.4,321.2h-12.7 M367.4,330.9h14.7 M383.1,335.3h-17.6 M373.9,316.1l-1.9,4.5 M386.7,320.6l1-4.5 M386.4,320.6l1-4.5
	 M371.6,320.6l1.9-4.5 M423.3,324.7h13.3 M370.2,324.7h13.5 M381.2,334.5h-15.3 M438.4,325.4h-17.2 M463,344.1h17.5 M333.6,257.6
	h-0.1c-5.4-0.1-7.8-1.8-7.2-5c3.9-13.8,7.7-25.4,11.7-34.8c1.5-3.6,3.9-5.6,6.7-6.1c11.3-1,23.8-1.8,37.4-2.3s26.7-0.7,39.1-0.6
	c12.4-0.1,25.5,0,39.1,0.6s26.1,1.2,37.4,2.3c2.9,0.6,5.2,2.5,6.7,6.1c3.9,9.4,7.7,20.9,11.7,34.8c0.6,3.2-1.8,4.8-7.3,5 M480.8,345
	h-19.7 M462.1,349.8H483 M404,298.6l9.6-0.1l-0.7,0.4c-1.8,1.1-3.2,2.5-4.1,4.4c-0.4,0.8-1,1.2-1.8,1.2h-2.9 M403.2,298.6v6.1
	 M404,304.7v-6.1 M403.2,298.6h0.8 M404,304.7h-0.8 M403.8,320.6h0.3 M403.5,323.6l0.1-1.2 M401.5,321.2l0.5,0.5
	c0.4,0.4,1,0.5,1.5,0.7 M401.1,324.7l0.4-0.5c0.4-0.4,1-0.7,1.6-0.7h0.1 M406,321.2l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7 M403.6,322.4
	h0.3 M406,321.2h-4.4 M403.8,325.4h-0.4 M403.9,323.6c0.4,0,0.8,0.3,1.2,0.5l0.4,0.5 M403.9,322.4v1.2 M403.9,323.6h-0.4
	 M401.1,324.7h4.4 M405.3,330.9l-0.3,0.4c-0.4,0.4-1,0.5-1.5,0.7 M403.1,330.2h0.4 M403.1,331.9c-0.5,0-1.1-0.3-1.5-0.7l-0.4-0.4
	 M403.3,331.9h-0.3 M402.9,333.7h0.4 M400.7,334.5l0.3-0.3c0.4-0.4,1.1-0.7,1.6-0.7h0.1 M404.7,334.5l-0.3-0.3
	c-0.4-0.3-0.7-0.5-1.2-0.5 M403.3,333.7v-1.8 M402.9,333.7l0.1-1.8 M402.5,339.9h0.4 M403.2,335.3h-0.4 M402.9,339.9l0.3-4.5
	 M402.8,335.3l-0.3,4.5 M401.1,330.9h4.1 M404.6,340.6h-4.3 M404.7,334.5h-4 M403.5,330.2l0.3-4.8 M403.3,325.4l-0.3,4.8
	 M420.9,320.6h0.3 M420.9,322.4c-0.7-0.1-1.1-0.3-1.5-0.7c-0.3-0.1-0.4-0.3-0.5-0.5 M420.9,323.6c-0.7,0-1.1,0.3-1.5,0.7
	c-0.3,0.1-0.4,0.3-0.5,0.5 M420.9,323.6v-1.2 M421.2,323.6v-1.2 M421.2,323.6h-0.3 M421.2,322.4h-0.3 M421.2,322.4
	c0.5,0,1.1-0.3,1.5-0.7l0.4-0.5 M421.2,325.4h-0.3 M423.3,324.7l-0.4-0.5c-0.4-0.4-1-0.5-1.5-0.7 M423.3,321.2h-4.4 M418.9,324.7
	h4.4 M421.2,332.2c0.5,0,1.1-0.3,1.5-0.7l0.4-0.5 M420.9,330.2h0.3 M421.2,333.4h-0.3 M420.9,332.2h0.3 M420.9,333.4
	c-0.7,0-1.1,0.3-1.5,0.7c-0.3,0.1-0.4,0.3-0.5,0.5 M420.9,332.2v1.2 M418.9,330.9l0.5,0.5c0.4,0.3,0.8,0.5,1.4,0.7h0.1 M421.2,333.4
	v-1.2 M423.3,334.5l-0.4-0.5c-0.4-0.4-1-0.6-1.5-0.7 M421.2,335.3h-0.3 M420.9,339.9h0.3 M421.2,339.9v-4.5 M420.9,335.3v4.5
	 M423.3,340.6h-4.4 M423.3,330.9h-4.4 M418.9,334.5h4.4 M420.9,330.2v-4.8 M421.2,330.2v-4.8 M483.3,350.3h-18.8 M420.9,325.4h-17.2
	 M405.5,324.7h13.3 M418.9,340.6h-14.3 M402.9,339.9h18 M404,320.6h16.9 M403.5,330.2h17.5 M418.9,321.2H406 M420.9,335.3h-17.7
	 M418.9,334.5h-14.2 M405.3,330.9h13.6 M404,320.6l0.3-4.5 M403.8,320.6l0.3-4.5 M420.9,320.6v-4.5 M421.2,320.6v-4.5 M403.3,325.4
	h-17.7 M387.8,324.7h13.3 M400.3,340.6h-16 M382.6,339.9h19.9 M386.7,320.6h17.1 M384.6,330.2h18.4 M385.9,298.6h17.3 M401.5,321.2
	h-12.9 M402.8,335.3h-19.3 M385.6,334.5h15.1 M401.1,330.9h-14.9 M403.2,304.7l-17.3-0.3 M420.9,341.8c-0.7-0.1-1.1-0.3-1.5-0.7
	l-0.4-0.5 M420.9,342.9c-0.7,0-1.1,0.3-1.5,0.7c-0.3,0.1-0.4,0.3-0.5,0.5 M421.2,342.9h-0.3 M421.2,341.8h-0.3 M421.2,341.8v1.1
	 M420.9,342.9v-1.1 M421.2,345h-0.3 M423.3,344.1l-0.4-0.5c-0.4-0.4-1-0.5-1.5-0.7 M423.3,340.6l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7
	 M420.9,351.4c-0.7-0.1-1.1-0.3-1.5-0.7l-0.4-0.6 M420.9,349.8h0.3 M423.3,350.3l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7 M421.2,351.4h-0.3
	 M423.3,350.3h-4.4 M418.9,344.1h4.4 M420.9,349.8V345 M421.2,349.8V345 M421.2,352.7h-0.3 M421.2,354.6h-0.3 M420.9,352.7
	c-0.7,0-1.1,0.3-1.5,0.7c-0.3,0.1-0.4,0.4-0.5,0.5 M423.3,353.9l-0.4-0.5c-0.4-0.4-1-0.6-1.5-0.7 M418.9,353.9h4.4 M421.2,359.5v-5
	 M420.9,359.5v-5 M420.9,351.4v1.2 M421.2,352.7v-1.2 M400.2,344.1l0.3-0.1c0.4-0.4,1.1-0.7,1.6-0.7h0.3 M402.6,343.5l0.1-1.8
	 M402.6,343.5l-0.3-0.1 M402.4,343.3v-1.7 M402.8,341.7h-0.4 M402.6,345h-0.4 M404,344.1l-0.3-0.1c-0.3-0.3-0.7-0.5-1.2-0.5
	 M402.8,341.7c0.5,0,1-0.3,1.4-0.7l0.4-0.4 M401.8,351.4c-0.7,0-1.2-0.3-1.8-0.7l-0.5-0.6 M402,349.8h0.4 M402.2,351.4h-0.4
	 M404,350.3l-0.4,0.5c-0.4,0.3-0.8,0.5-1.4,0.7 M404,350.3h-4.4 M404,344.1h-3.9 M402.4,341.7c-0.7,0-1.2-0.1-1.6-0.7l-0.4-0.4
	 M402.6,345l-0.3,4.8 M402,349.8l0.3-4.8 M402.1,354.6h-0.4 M399.3,353.9l0.3-0.3c0.4-0.4,1.1-0.7,1.6-0.7h0.4 M401.8,352.9l0.3,0.1
	 M403.5,353.9l-0.3-0.3c-0.3-0.3-0.7-0.4-1.1-0.6 M399.3,353.9h4.1 M401.4,359.4l0.3-4.8 M401.7,359.4l0.4-4.8 M401.8,351.4v1.5
	 M402.1,353.1l0.1-1.6 M465.2,353.9h19.5 M420.9,354.6h-18.8 M403.5,353.9h15.4 M418.9,350.3H404 M402.4,349.8h18.6 M418.9,344.1
	H404 M420.9,345h-18.3 M485,354.6h-21.9 M381.7,343.2c0.4,0,0.8,0.3,1.2,0.5l0.4,0.6 M382.2,341.8l-0.4,1.4 M381.5,343l0.3,0.1
	 M379.1,344.1l0.4-0.5c0.4-0.4,1.1-0.7,1.6-0.7h0.1 M381.7,341.8h0.4 M381.5,343l0.3-1.2 M384.4,340.6l-0.4,0.5
	c-0.5,0.4-1.1,0.7-1.6,0.7h-0.1 M381.7,341.8c-0.5-0.1-1-0.3-1.2-0.5l-0.5-0.6 M381.5,345h-0.4 M380,349.8h0.4 M381.9,350.3
	l-0.4,0.4c-0.4,0.4-1,0.5-1.5,0.7 M379.7,351.4c-0.5,0-1.1-0.3-1.5-0.7l-0.4-0.4 M380,351.4h-0.3 M383.4,344.1h-4.3 M380.4,349.8
	l1.1-4.8 M381,345l-1.1,4.8 M379.3,354.6H379 M379.7,352.9h-0.4 M376.9,353.9l0.4-0.4c0.4-0.4,1.1-0.7,1.6-0.7h0.3 M379.7,352.9
	c0.4,0,0.8,0.3,1.1,0.6l0.3,0.4 M379.3,354.6l-1,4.7 M379.3,352.9l0.4-1.5 M380,351.4l-0.3,1.5 M361,345h0.3 M361.2,344.1h0.4
	 M376.9,353.9h4.1 M379,354.6l-1.1,4.7 M381.9,350.3h-4.1 M361.2,345h19.8 M361.7,344.1h17.5 M401.7,354.6h-22.4 M381,353.9h18.3
	 M399.6,350.3h-17.7 M380.4,349.8H402 M400.2,344.1h-16.8 M402.2,345h-20.9 M441.9,344.1h16.8 M460.7,345h-20.8 M440.2,349.8h21.6
	 M504.4,349.8h0.5 M480.5,344.1h0.4 M480.9,344.1c1.8-0.1,2.8-0.6,2.6-1.2h19.3 M481.2,345h-0.4 M483,349.8h0.4 M483.5,350.3h-0.4
	 M502.1,351.3h-13.3c-0.7-0.7-2.1-1-3.9-1.1h-1.4 M483.3,349.8l-2.1-4.8 M483,349.8l-2.1-4.8 M485.2,353.9h0.7
	c2.2,0,3.3-0.5,3.2-1.2h11.8 M486.8,358.6l-1.8-4 M487.3,358.6l-1.8-4 M484.8,353.9h0.4 M485.5,354.6h-0.4 M484.8,353.9l-1.5-3.6
	 M485.2,353.9l-1.5-3.6 M460.3,350.3h-17.7 M442.7,353.9H461 M497.4,354.6h-12 M483.3,349.8h19.7 M503.3,345h-22.1 M462.1,351.4h0.4
	 M463,344.1l-0.4-0.6c-0.4-0.4-1.1-0.7-1.6-0.7h-0.1 M462.5,351.4c0.5,0,1.1-0.3,1.5-0.7l0.4-0.4 M460.4,341.8
	c0.4-0.1,1-0.3,1.2-0.5l0.5-0.5 M460.7,343l-0.3-1.4 M460.7,343h-0.3 M460,341.8h0.4 M457.8,340.6l0.4,0.5c0.5,0.4,1.1,0.7,1.6,0.7
	h0.1 M460,341.8l0.3,1.4 M460.3,343.2c-0.4,0-0.8,0.3-1.2,0.5l-0.4,0.6 M461.1,345h-0.4 M460.3,350.3l0.4,0.4c0.4,0.4,1,0.5,1.5,0.7
	 M461.8,349.8l-1.1-4.8 M462.5,352.9c-0.4,0-0.8,0.3-1.1,0.6l-0.3,0.4 M463.2,354.6h-0.4 M465.2,353.9l-0.4-0.4
	c-0.4-0.4-1.1-0.7-1.6-0.7h-0.3 M462.8,352.9h-0.3 M464.1,359.3l-1-4.7 M463.9,359.3l-1-4.7 M458.8,344.1h4.3 M461.8,349.8h0.4
	 M464.4,350.3h-4.1 M461,353.9h4.1 M462.8,352.9l-0.3-1.5 M462.5,352.9l-0.4-1.6 M462.1,349.8L461,345 M439.4,341.7l0.1,1.8
	 M439.8,343.3l-0.1-1.7 M441.9,344.1l-0.3-0.1c-0.4-0.4-1.1-0.7-1.6-0.7h-0.3 M439.8,343.3h-0.4 M439.4,341.7h0.4 M439.9,345h-0.4
	 M439.7,341.7c0.7,0,1.2-0.1,1.6-0.7l0.4-0.4 M439.4,343.5c-0.4,0-0.8,0.3-1.2,0.5l-0.3,0.1 M438.1,350.3c0.1,0.3,0.3,0.4,0.4,0.5
	c0.4,0.3,0.8,0.5,1.4,0.7 M439.8,349.8h0.3 M439.9,351.4h0.4 M440.2,351.4c0.7,0,1.2-0.3,1.8-0.7l0.5-0.6 M437.6,340.6l0.4,0.4
	c0.4,0.3,0.8,0.5,1.4,0.7 M442.4,350.3h-4.3 M438,344.1h3.9 M440.2,349.8l-0.3-4.8 M439.5,345l0.3,4.8 M440.3,352.9h-0.3
	 M438.7,353.9h4 M440.5,354.6h-0.4 M440.1,353.1c-0.4,0.1-0.7,0.3-1.1,0.5l-0.3,0.3 M442.7,353.9l-0.3-0.3c-0.4-0.4-1.1-0.7-1.6-0.7
	h-0.3 M440.1,354.6l0.3,4.8 M440.8,359.4l-0.3-4.8 M439.9,351.4l0.1,1.6 M440.3,352.9l-0.1-1.5 M462.8,354.6h-22.4"
          />
          <path
            fill="none"
            d="M494.7,370.3l1.5-2.1 M495.8,373.6l1.6-2.2 M504.2,343.3l0.3,1 M496.2,368.2c3.6-5.1,6.1-9.6,7.4-13.6l0.8-2.9
	l0.4-2.1c0.3-1.8,0.1-3.3-0.3-4.8l-0.1-0.5 M504.4,344.3l-0.1-0.8l-0.1-0.1 M432.6,371.2h-23.1 M408.7,373.6h24.8"
          />
          <path
            fill="none"
            d="M496.2,368.2h-97.8c-14.7-0.1-32.2-0.1-52.3,0 M416.8,297.8h-1.6 M415.2,297.8c-0.5,0.1-1.1,0.4-1.5,0.7
	 M425.2,297.8h-8.4 M427,297.8c0.5,0.1,1.1,0.4,1.5,0.7 M433.5,373.6h62.3 M427,297.8h-1.8 M415.2,297.8h-13.5
	c-6.1,0.1-12,0.3-17.7,0.3l-15.4-0.3"
          />
          <path
            fill="none"
            d="M368.4,297.8c3.4,2.2,6.1,3.9,8,5l1.2,0.7c1.6,0.8,3.9,1.4,6.7,1.5l6.6,0.3l16,0.1c1,0,1.8-0.5,2.3-1.6
	c1.5-2.9,4-4.8,7.3-5.6l0.3-0.1 M470.3,310.2l16.8-12.9 M487.1,297.2l-13.5,0.5 M356.7,316.9c1.5-1.2,3-2.2,4.5-2.8
	c1.9-0.8,4-1.2,5.9-1.1H475c1.4,0,2.8,0.1,4.1,0.5l1.4,0.6c1.6,0.7,3.3,1.6,4.8,2.9 M487.9,296.7l-0.7,0.4 M487.2,297.1l-0.1,0.1
	 M354.2,296.7l14.7,0.7l14.9,0.3c5.8,0,11.7,0,17.7-0.3h38.7c6.1,0.1,12,0.3,17.7,0.3l14.9-0.3l14.7-0.7 M473.6,297.8l-15.4,0.3
	c-5.8,0-11.7-0.1-17.7-0.3H427"
          />
          <path
            fill="none"
            d="M473.6,297.8c-3.4,2.2-6.1,3.9-8,5l-1.2,0.7c-1.6,0.8-3.9,1.4-6.7,1.5l-6.6,0.3l-16,0.1c-1,0-1.8-0.5-2.3-1.6
	c-1.5-2.9-4-4.8-7.3-5.6l-0.3-0.1 M535.3,341.4c0.4-0.7,0.8-1.2,1.4-1.8 M551.8,366.4c-9.9,1.1-21.6,1.6-34.9,1.8h-20.6"
          />
          <path
            fill="none"
            d="M516.8,373.6v28.8c0.3,2.9,3.2,4.7,8.5,5.4l7.4,0.6h4.1l7.2-0.5c5.4-0.7,8.3-2.5,8.5-5.4v-45.3 M516.8,372.1
	v1.5 M544.1,352.3v-1.5 M545.3,352.1v-1.5 M497.4,371.4l-64.8-0.1 M511,363.5l3.9-0.1c9.4-0.3,17.6-1.1,24.9-2.3
	c3-0.5,4.8-2.1,5.2-4.5 M543.9,355.7v-1.4 M544.9,354.3h-1 M543.9,355.7l1.2-0.1 M544.1,352.3l1.2-0.1 M545.2,355.6l0.1-3.4
	 M545,356.5l0.1-0.8v-0.1 M543.9,354.3l-8.4,1c-0.8,0-1.2,0.4-1.1,1c0.1,0.4,0.7,0.5,1.7,0.4l7.7-1 M544.1,350.7l-9.2,0.8
	c-0.8,0-1.2,0.4-1.2,1c0.1,0.4,0.7,0.4,1.8,0.3l8.7-0.7 M544.2,346.6l-9.2,1c-1,0-1.4,0.4-1.5,1.4l10.7-0.8"
          />
          <path
            fill="none"
            d="M545,356.5c-0.5,1.6-2.2,2.6-4.8,2.9c-4.1,0.3-6.5-1.6-7.3-5.6c-0.4-3.2-0.3-5.5,0.1-7l0.1-0.5
	c0.5-1.9,1.2-3.6,2.2-5"
          />
          <path
            fill="none"
            d="M544.3,343.2l-8.5,1c-1,0-1.5,0.5-1.5,1.2l10-1 M545.4,346.5v-2.1 M545.4,348.1h-1.2 M544.2,348.1v-1.5
	 M545.3,350.6l0.1-2.5 M545.4,348.1v-1.6 M545.3,350.6l-1.2,0.1 M544.2,346.6l1.2-0.1 M545.4,344.4l0.1-1.4 M544.3,344.5l1.1-0.1
	 M545.6,343l-1.2,0.1 M544.3,344.5v-1.4 M552.5,357.1l-0.5,8.4 M550,375c-0.7,0.7-2.1,1.2-3.9,1.5c-0.3-0.4-1.4-0.7-3.4-1.1
	c-12.2-2.3-20.6-3.3-25.2-3.3h-0.7 M551.9,365.5l-0.1,1 M551.8,366.4l-1.1,7.3c-0.1,0.5-0.3,1-0.7,1.4"
          />
          <path
            fill="none"
            d="M550,375c0.3-0.8-1-1.4-3.9-1.6c-4-0.1-8-0.5-11.7-1.2c-5.4-1.4-9.8-1.9-13.1-1.8h-26.8 M551.9,365.5l-0.1,1
	 M555.8,309.5l-11-7 M541.6,259l17.5-1.1 M552.5,357.1l1.8-24.5c0.7-8.5,1.1-16.2,1.4-23 M536.6,339.6l4.3-17.2l0.1-0.4"
          />
          <path
            fill="none"
            d="M541.5,321.3l2.5-0.5c1-0.3,1.4,0.4,1.2,1.6l-0.4,15.5c0,1.5-1,2.3-2.8,2.6l-6.7,0.7"
          />
          <path
            fill="none"
            d="M545.6,343l0.4-20.4c0.3-2.6-0.3-3.7-1.5-3c-1.4,0-2.3,0.6-3,1.6 M541.5,321.3L541,322"
          />
          <path
            fill="none"
            d="M541,322l1.5-0.4c1,0,1.2,0.1,1.1,0.7c0.1,4.5,0,9.6-0.4,15c-0.1,1.5-0.8,2.3-2.1,2.2h-4.7 M541.7,302.5
	c-0.7,2.5-2.3,4-5,4.5c-6.9,1.8-14.3,3.3-21.9,4.5l-11.4,1.8c-0.8,0.3-1.1,0.1-0.7-0.7c0.1-0.3,0.4-0.8,0.7-1.4l7.7-8.7l-32.5,2.9
	l-1.1-0.4l9.6-8.1"
          />
          <path
            fill="none"
            d="M500.7,316.5l26.8-4.7c7.2-1,12-2.5,14.3-4.5c1.4-0.8,2.3-2.5,2.9-4.8 M544.8,302.5h-3 M541.6,283.5l-14.7,2.3
	 M534.3,260.9l-10.6-2.1 M523.7,258.8c5,3.9,6.9,7.3,5.8,10.2c-1.5,4.3-2.5,9.9-2.8,16.8 M544.8,302.5c0.7-2.5,1-5.6,0.8-9.8
	c-0.3-7-1.7-10.2-4-9.4"
          />
          <path
            fill="none"
            d="M541.7,302.5l0.3-0.8c0.7-2.3,1.2-5.2,1.4-8.9c0.1-1.9,0.1-4,0.1-6.3c0-1.4-0.4-2.2-1-2.6
	c-0.3-0.1-0.5-0.3-1-0.3 M526.9,285.8c-2.9,2.6-6.6,4.5-11.3,5.9c-9.4,2.5-18.7,4.1-27.8,5 M523.7,258.8l-3.6-0.7 M536.6,261.5
	c-0.7,0-1.4-0.3-2.5-0.7 M548.7,277.6c-1.5-3-2.6-6.7-3.3-11.1c-1.2-4.5-2.5-7-3.9-7.6 M531.3,244.7l2.9,6.3
	c0.8,1.1,2.1,1.4,3.9,1.2 M495.8,373.6h21 M547.5,277.6c-0.7-4.1-2.9-8.5-6.6-13.3c-1.1-1.5-2.5-2.3-4.1-2.6 M541.6,259
	c-1.9,0.4-3.2,1.2-3.9,2.3c-0.3,0.3-0.7,0.3-1.1,0.3 M497.4,371.4l19.4,0.7 M484.4,288.4c11.1-13.5,23.1-23.5,35.8-30.3
	 M538.2,252.2l-19.8-35.4 M346.4,373.6h62.3 M534.3,260.9l-9.2-23.4 M368.4,297.8l-13.3-0.5 M541.7,251.3c-0.7,0.6-1.7,0.8-3,0.8
	 M538.7,252.1l-0.5,0.1 M577.1,253.6l-28.1,2.8c-4.4,0.7-6.7-1.1-7.2-5.1 M550.4,282.4c-1.1-0.8-2.1-2.5-2.9-5 M547.5,277.6h1.2
	 M551.1,282.8l-0.7-0.4 M550.4,282.4c0.3,0,0.4-0.1,0.5-0.4 M555.8,309.5c0.3-6.6,0.4-12.5,0.4-17.6c-2.2-2.5-3.9-5.5-5.1-9.1
	 M551.1,282.8l-0.1-0.8 M550.9,282c0.1-0.4,0.1-1.2,0-2.5c0-1.1-0.7-1.6-2.1-1.9 M344.7,371.4l64.8-0.1"
          />
          <path
            fill="none"
            d="M538.7,252.1c1,2.9,2.1,4.5,3.2,5c3.9,0.4,8,0.4,12.7,0h0.5c2.3-0.3,3.7,0,3.9,0.8 M559.1,257.9l12.8-1.4
	c2.8-0.4,4.5-1.4,5.2-2.9 M371.8,310.2l-16.8-12.9 M317.1,237.5l-9.2,23.4 M505,209.6l18.7,49.2 M318.3,258.8l18.8-49.2
	 M577.1,253.6l0.3-0.6c0.3-1.2,0.3-2.3,0.4-3.2 M283.1,257.9l17.3,1.1 M305.5,339.6l1.4,1.8 M289.6,357.1v45.3
	c0.3,2.9,3.2,4.7,8.5,5.4l7.2,0.6h4.1l7.4-0.5c5.4-0.7,8.3-2.5,8.5-5.4v-28.8 M289.6,357.1l0.7,9.4"
          />
          <path
            fill="none"
            d="M292.2,375c-0.4-0.8,1-1.4,3.9-1.6c4-0.1,8-0.5,11.7-1.2c5.4-1.4,9.8-1.9,13.1-1.8h26.8 M290.3,366.4l1.1,7.3
	c0.1,0.5,0.3,1,0.7,1.4 M298.1,352.3v-1.5 M296.7,350.6l0.1,1.5 M298.2,355.7v-1.4 M298.1,352.3l-1.2-0.1 M296.9,355.6l1.4,0.1
	 M298.2,354.3l-1-0.1 M296.9,355.6v0.3c0,0.3,0.1,0.5,0.3,0.8 M296.9,352.1l0.1,3.4 M296.6,344.4v2.1 M296.7,350.6l1.2,0.1
	 M298,348.1l-1.2-0.1 M296.7,346.5v1.6 M296.7,348.1v2.5 M298,348.1l-0.1-1.5 M296.7,346.5l1.2,0.1 M296.6,343v1.4 M297.8,344.5
	v-1.4 M297.8,344.5l-1.2-0.1 M296.6,343l1.2,0.1 M298.1,350.7l9.2,0.8c0.8,0,1.2,0.4,1.2,1c-0.1,0.4-0.7,0.4-1.8,0.3l-8.7-0.7
	 M298.2,355.7l7.7,1c1,0.3,1.5,0.1,1.7-0.4c0.1-0.5-0.1-1-1.1-1l-8.4-1 M297.8,346.6l9.2,1c1,0,1.4,0.4,1.5,1.4l-10.7-0.8
	 M297.8,343.2l8.5,1c1,0,1.5,0.5,1.5,1.2l-10-1"
          />
          <path
            fill="none"
            d="M306.9,341.4c1,1.4,1.6,3,2.2,5l0.1,0.6c0.4,1.6,0.4,4,0.1,7c-0.8,4-3.2,5.9-7.3,5.6c-2.6-0.3-4.3-1.4-4.8-2.9
	 M346,368.2h-20.6c-13.5-0.1-25-0.8-34.9-1.8 M325.3,372.1l19.4-0.7"
          />
          <path
            fill="none"
            d="M297.1,356.5c0.4,2.5,2.1,4,5.2,4.5c7.3,1.2,15.7,2.1,24.9,2.3l3.9,0.1 M325.3,373.6h21 M347.5,370.3l-1.5-2.1
	 M494.7,370.3l-8.5-0.3c-14.6-0.3-28.6-0.4-42.2-0.4h-45.5c-13.6,0-27.8,0-42.2,0.4l-8.5,0.3 M346.4,373.6l-1.6-2.2 M292.2,375
	c0.8,0.7,2.1,1.2,3.9,1.5c0.3-0.4,1.4-0.7,3.4-1.1c12.2-2.3,20.6-3.3,25.2-3.3h0.7 M325.3,372.1v1.5 M337.9,343.3l-0.3,1
	 M337.6,344.3v0.1 M337.6,344.4l-0.1,0.5c-0.4,1.5-0.4,3-0.3,4.8l0.3,1.6c0.3,1.1,0.5,2.1,1,3.3l0.4,1.1c1.5,3.7,3.9,7.8,7,12.5
	 M337.9,343.3v0.3l-0.3,0.7"
          />
          <path
            fill="none"
            d="M337.6,344.4l-0.1,0.5c-0.3,1.8-0.3,3.4,0.3,4.8c0.1,0.5,0.4,1.1,0.7,1.6c0.3,0.4,0.7,1,1,1.4
	c0.7,0.7,1.5,1.4,2.6,1.9l0.8,0.4c4.3,2.3,8.1,3.9,11.6,4.7h0.3c1.5,0.4,2.9,0.5,4.3,0.5l54.5,0.4l69.5-0.4c1.4,0,2.8-0.3,4.3-0.5
	h0.3c3.4-0.8,7.3-2.3,11.6-4.7l0.8-0.4c1-0.5,1.9-1.2,2.6-1.9l1-1.4l0.8-1.6c0.4-1.4,0.5-3,0.3-4.8l-0.1-0.5 M264.4,249.9
	c0,1,0.1,1.9,0.4,3.2l0.1,0.6 M264.9,253.6c0.7,1.5,2.5,2.5,5.2,2.9l12.8,1.4 M357.7,288.4c-11.1-13.5-23.1-23.5-35.8-30.3
	 M315.3,285.8c2.9,2.6,6.6,4.5,11.3,5.9c9.4,2.5,18.7,4.1,27.8,5 M333.5,257.6l-11.6,0.5"
          />
          <path
            fill="none"
            d="M354.6,323.5l1.5-1.4l1.9-1.6l2.3-1.8c2.1-1.9,6.1-2.8,12.1-2.8h97c6.1,0,10.2,0.8,12.1,2.8l2.3,1.8l1.9,1.6
	l1.5,1.4l2.1,1.9c1.7,1.7,3.2,3.2,4.7,4.8l1.5,1.8l1.1,1.4l1.4,1.9c1.1,1.5,2.1,3,2.9,4.5v0.1l0.7,1.4l0.3,0.4l0.7,1.4l0.4,1l0.3,1
	c0.3,1.8,0.3,3.4-0.3,4.8l-1,1.4l-1.4,1.4c-0.5,0.5-1.4,1-2.1,1.2l-1.2,0.7c-3.7,1.9-7.2,3.2-10.2,4h-0.4c-1.4,0.3-2.6,0.4-3.9,0.5
	l-18.8,0.1h-0.3l-23.1,0.1h-0.4l-19.1,0.1h-0.3l-19.1-0.1h-0.4l-23.1-0.1H378l-18.8-0.1c-1.2,0-2.5-0.3-3.9-0.5h-0.3
	c-3-0.7-6.5-2.1-10.2-4l-1.2-0.7c-0.8-0.4-1.5-0.8-2.1-1.2s-1-0.8-1.4-1.4c-0.4-0.5-0.7-1.1-1-1.6c-0.5-1.4-0.5-3-0.3-4.8l0.1-1
	l0.4-1l0.7-1.4l0.3-0.4l0.7-1.4v-0.1c0.8-1.5,1.8-3,2.9-4.5l1.4-1.9l1.1-1.4l1.5-1.8c1.5-1.7,3-3.2,4.7-4.8L354.6,323.5L354.6,323.5
	z"
          />
          <path
            fill="none"
            d="M504.2,343.3l-2.1-4.3c-0.7-1.4-1.5-2.6-2.5-3.9l-3.9-5c-1.4-1.6-2.9-3.2-4.5-4.8c-1.6-1.6-3.4-3.3-5.5-5
	l-3.4-2.8c-2.2-2.1-6.5-3-13.1-3h-96.8c-6.5,0-10.9,1-13.1,3c-1.2,1-2.3,1.8-3.4,2.8c-1.9,1.6-3.7,3.3-5.5,5
	c-1.6,1.7-3.2,3.2-4.5,4.8l-3.9,5c-1,1.2-1.6,2.6-2.5,3.9l-2.1,4.3 M355.1,297.2l-0.3-0.1 M354.2,296.7l0.5,0.4 M354.8,297.1
	l9.6,8.1l-1.1,0.4l-32.5-2.9l7.7,8.7l0.7,1.4c0.3,0.8,0.1,1-0.7,0.7l-11.4-1.8c-7.7-1.4-15-2.9-21.9-4.5c-2.6-0.5-4.3-2.1-5-4.5
	 M286.4,309.5c0.3,6.9,0.8,14.4,1.4,23l1.8,24.5 M300.7,321.3c-0.7-1.1-1.6-1.6-3-1.6c-1.2-0.7-1.8,0.4-1.5,3l0.4,20.4"
          />
          <path
            fill="none"
            d="M306.9,341.4l-6.7-0.7c-1.8-0.3-2.8-1.2-2.8-2.6l-0.4-15.5c-0.1-1.4,0.3-1.9,1.2-1.6l2.5,0.5"
          />
          <path
            fill="none"
            d="M301,322l-1.5-0.4c-1,0-1.2,0.1-1.1,0.7c-0.1,4.5,0,9.6,0.3,15c0.1,1.5,0.8,2.3,2.1,2.2h4.7 M300.7,321.3
	l0.3,0.7 M301,322l0.1,0.4l4.4,17.2 M341.3,316.5l-26.8-4.7c-7.2-1-12-2.5-14.3-4.5c-1.4-0.8-2.3-2.5-2.9-4.8 M297.3,302.5h3
	 M297.3,302.5l-10.9,7 M300.4,283.5l14.9,2.3 M294.7,277.6c0.7-4.1,2.9-8.5,6.6-13.3c1.1-1.5,2.5-2.3,4.1-2.6 M304,252.2l-0.7-0.1
	 M294.7,277.6h-1.4"
          />
          <path
            fill="none"
            d="M300.4,283.5c-2.3-0.8-3.6,2.2-4,9.4c-0.1,4.1,0.1,7.3,0.8,9.8 M290.9,282.8c-1.1,3.6-2.8,6.6-5.1,9.1
	c0,5.1,0.1,10.9,0.4,17.6 M291.8,282.4c1.1-0.8,2.1-2.5,2.9-5 M290.9,282.8l0.8-0.4 M293.3,277.6c-1.4,0.3-2.1,1-2.1,1.9
	c-0.1,1.2-0.3,2.1,0,2.5 M291.2,282l-0.3,0.8 M291.8,282.4c-0.1,0-0.4-0.1-0.5-0.4"
          />
          <path
            fill="none"
            d="M300.3,302.5l-0.3-0.8c-0.7-2.3-1.2-5.2-1.4-8.9c-0.1-1.9-0.1-4-0.1-6.3c0-1.4,0.4-2.2,1-2.6
	c0.3-0.1,0.5-0.3,1-0.3 M264.9,253.6l28.2,2.6c4.4,0.7,6.7-1.1,7.2-5.1"
          />
          <path
            fill="none"
            d="M303.3,252.1c-1,2.9-2.1,4.5-3.2,5c-4,0.4-8.4,0.4-13.2,0c-1.5-0.1-2.6,0-3.2,0.1c-0.4,0.1-0.5,0.3-0.7,0.5
	 M303.3,252.1c-1.4-0.1-2.3-0.4-3-0.8 M300.4,259c-1.5,0.4-2.8,3-3.9,7.6c-0.7,4.4-1.8,8.1-3.3,11.1 M318.3,258.8
	c-5,3.9-6.9,7.3-5.8,10.2c1.5,4.3,2.5,9.9,2.8,16.8 M305.4,261.5c0.7,0,1.4-0.3,2.5-0.7 M307.9,260.9l10.5-2.1 M300.4,259
	c1.9,0.4,3.2,1.2,3.9,2.3c0.3,0.3,0.7,0.3,1.1,0.3 M304,252.2c1.8,0.3,3.2-0.1,3.9-1.2l2.9-6.3 M321.9,258.2l-3.6,0.7 M520.1,258.2
	l-11.6-0.5l-20.8-1.2l-24.9-0.7h-86.4l-22.3,0.7l-20.6,1.2 M310.8,244.7l12.9-27.8 M343.5,197.8c-1.6-0.3-3.9,3.2-6.5,10.3
	 M328.4,208.6l-11.3,28.9 M340.5,207.7c1.8-5.1,3.3-8.4,4.7-9.9 M328.4,208.6c3.4-0.6,6.3-0.8,8.7-0.6 M327.4,208.9l1-0.3
	 M327.4,208.9l-0.1,0.3l-3.6,7.7 M337.2,209.6c0.3-0.7,0.3-1.2-0.1-1.4 M337,208.1l3.4-0.4 M337.2,209.6l3.3-0.6 M340.5,209.1v-1.4
	 M317.1,237.5l-6.3,7.2"
          />
          <path
            fill="none"
            d="M323.7,216.9l-8.8,15.7l-0.4,1L304,252.2 M345.1,197.6l-1.6,0.1 M343.5,197.8l-0.5-0.4
	c-4.5,0.6-7.7,1.6-9.4,3.2c-2.9,2.5-5,5.2-6.1,8.3 M264.7,245.8l6.3,0.8c1.6,0.3,2.6,1,2.6,2.2c0.1,1.2-1.2,1.8-3.9,1.8
	s-4.4-0.1-5.4-0.6"
          />
          <path
            fill="none"
            d="M300.3,251.3c-0.3-0.3-0.5-0.6-0.7-0.8l-3.2-7.7c-1-2.5-4.1-3.7-9.5-3.9h-2.6c-6.3-0.1-10.9,0.1-13.8,0.7h-0.1
	c-2.9,0.4-4.8,2.3-5.8,5.6v0.1l-0.1,0.4"
          />
          <path
            fill="none"
            d="M264.7,245.8c-0.8,0-1.4,0.4-1.5,1.1v1.4c0,0.8,0.4,1.4,1.2,1.6 M505.1,208.1c2.3-0.3,5.2,0,8.7,0.6
	 M577.6,249.9c0.8-0.3,1.2-0.8,1.2-1.6v-1.4c-0.1-0.7-0.5-1.1-1.5-1.1"
          />
          <path
            fill="none"
            d="M577.5,245.8l-6.3,0.8c-1.7,0.3-2.6,1-2.6,2.2c-0.1,1.2,1.2,1.8,3.9,1.8s4.4-0.1,5.4-0.6"
          />
          <path
            fill="none"
            d="M577.5,245.8l-0.1-0.4v-0.1c-1.1-3.3-3-5.2-5.8-5.6h-0.1c-3-0.6-7.6-0.8-13.8-0.7h-3.2c-5.1,0.3-8,1.5-8.9,3.9
	l-3.2,7.7l-0.7,0.8 M525.1,237.5l6.2,7.2 M518.3,216.9l12.9,27.8 M513.8,208.6l11.3,28.9 M514.8,208.9l-1-0.3 M518.3,216.9l-3.6-7.7
	v-0.3 M498.7,197.8c1.7-0.3,3.9,3.2,6.5,10.3 M497,197.6c1.4,1.5,2.9,4.8,4.7,9.9 M505,209.6c-0.3-0.7-0.3-1.2,0.1-1.4 M501.7,209.1
	l3.3,0.6 M505.1,208.1l-3.4-0.4 M501.7,207.7v1.4"
          />
          <path
            fill="none"
            d="M514.8,208.9c-1.1-3-3.2-5.8-6.1-8.3c-1.6-1.5-4.8-2.6-9.4-3.2l-0.5,0.4 M498.7,197.8l-1.6-0.1 M501.7,209.1
	v0.4c-12.4-1.2-26.3-2.1-41.5-2.6c-13.6-0.6-26.7-0.7-39.1-0.6c-12.4-0.1-25.5,0-39.1,0.6c-15.3,0.6-29.2,1.5-41.5,2.6V209"
          />
          <path
            fill="none"
            d="M501.7,207.7c-12.4-1.2-26.3-2.1-41.5-2.6c-13.6-0.6-26.7-0.7-39.1-0.6c-12.4-0.1-25.5,0.1-39.1,0.6
	c-15.3,0.6-29.2,1.5-41.5,2.6 M497,197.6c-0.3-0.6-1.1-0.8-2.5-1.1c-19.7-1.9-41.1-3-64.2-3.3h-18.4c-23.1,0.3-44.6,1.4-64.2,3.3
	c-1.5,0.3-2.3,0.6-2.5,1.1"
          />
          <path
            fill="none"
            d="M430.3,193.4h-18.4c-23.1,0.3-44.6,1.4-64.2,3.3c-1.5,0.3-2.3,0.6-2.5,1.1c-1.4,1.5-2.9,4.8-4.7,9.9
	c12.4-1.2,26.3-2.1,41.5-2.6c13.6-0.6,26.7-0.7,39.1-0.6c12.4-0.1,25.5,0.1,39.1,0.6c15.3,0.6,29.2,1.5,41.5,2.6
	c-1.8-5.1-3.3-8.4-4.7-9.9c-0.3-0.6-1.1-0.8-2.5-1.1C474.7,194.7,453.4,193.6,430.3,193.4L430.3,193.4z"
          />
          <path
            fill="none"
            d="M421.1,204.4c-12.4-0.1-25.5,0.1-39.1,0.6c-15.3,0.6-29.2,1.5-41.5,2.6v1.8c12.4-1.2,26.3-2.1,41.5-2.6
	c13.6-0.6,26.7-0.7,39.1-0.6c12.4-0.1,25.5,0,39.1,0.6c15.3,0.6,29.2,1.5,41.5,2.6v-1.8c-12.4-1.2-26.3-2.1-41.5-2.6
	C446.5,204.5,433.5,204.2,421.1,204.4L421.1,204.4z"
          />
          <path
            fill="none"
            d="M421.1,206.3c-12.4-0.1-25.5,0-39.1,0.6c-15.3,0.6-29.2,1.5-41.5,2.6V209l-3.3,0.6L318.3,259l3.6-0.7l11.4-0.5
	c-5.4-0.1-7.8-1.8-7.2-5c3.9-13.8,7.7-25.4,11.7-34.8c1.5-3.6,3.9-5.6,6.7-6.1c11.3-1,23.8-1.8,37.4-2.3c13.6-0.6,26.7-0.7,39.1-0.6
	c12.4-0.1,25.5,0,39.1,0.6c13.6,0.6,26.1,1.2,37.4,2.3c2.9,0.6,5.2,2.5,6.7,6.1c3.9,9.4,7.7,20.9,11.7,34.8c0.7,3.2-1.8,4.8-7.3,5
	l11.6,0.5l3.6,0.7L505,209.6l-3.3-0.6v0.4c-12.4-1.2-26.3-2.1-41.5-2.6C446.5,206.3,433.5,206.2,421.1,206.3L421.1,206.3z"
          />
          <path
            fill="none"
            d="M421.1,208.9c-12.4-0.1-25.5,0-39.1,0.6c-13.6,0.6-26.1,1.2-37.4,2.3c-2.9,0.6-5.2,2.5-6.7,6.1
	c-3.9,9.4-7.7,20.9-11.7,34.8c-0.7,3.2,1.8,4.8,7.2,5h0.1l20.8-1.1l22.1-0.8h86.4l24.9,0.8l20.8,1.1c5.5-0.1,8-1.8,7.3-5
	c-3.9-13.8-7.7-25.4-11.7-34.8c-1.5-3.6-3.9-5.6-6.7-6.1c-11.3-1-23.8-1.8-37.4-2.3C446.5,208.9,433.5,208.8,421.1,208.9
	L421.1,208.9z M498.7,197.8l-1.6-0.1c1.4,1.5,2.9,4.8,4.7,9.9l3.4,0.6C502.5,201.1,500.3,197.6,498.7,197.8L498.7,197.8z"
          />
          <path
            fill="none"
            d="M499.2,197.4l-0.5,0.4c1.6-0.3,3.9,3.2,6.5,10.3c2.3-0.3,5.2,0,8.7,0.6l1,0.1c-1.1-3-3.2-5.8-6.1-8.3
	C506.9,199,503.8,197.9,499.2,197.4L499.2,197.4z M501.7,207.7v1.4l3.3,0.6c-0.3-0.7-0.3-1.2,0.1-1.4L501.7,207.7L501.7,207.7z"
          />
          <path
            fill="none"
            d="M505.1,208.1c-0.4,0.3-0.4,0.7-0.1,1.4l18.9,49.4l10.6,1.9l-9.2-23.4l-11.4-28.9
	C510.4,208.1,507.5,207.9,505.1,208.1L505.1,208.1z"
          />
          <path
            fill="none"
            d="M514.8,209.2v-0.3l-1-0.3l11.3,28.9l6.2,7.2l-12.9-27.8"
          />
          <path
            fill="none"
            d="M518.3,216.9l12.9,27.9l2.9,6.3c0.8,1.1,2.1,1.4,3.9,1.2L518.3,216.9L518.3,216.9z M557.7,238.9h-3.2
	c-5.1,0.3-8,1.5-8.9,3.9l-3.2,7.7l-0.7,0.8c0.4,4,2.9,5.6,7.2,5.1l28.2-2.6l0.1-0.6c0.3-1.2,0.3-2.3,0.3-3.2c-1,0.4-2.8,0.6-5.4,0.6
	c-2.6,0.1-4-0.4-3.9-1.8c0.1-1.2,1-1.9,2.6-2.2l6.3-0.8l-0.1-0.4v-0.1c-1.1-3.3-3-5.2-5.8-5.6h-0.1
	C568.4,239,563.9,238.8,557.7,238.9L557.7,238.9z"
          />
          <path
            fill="none"
            d="M571.2,246.5c-1.7,0.3-2.6,1-2.6,2.2c-0.1,1.2,1.2,1.8,3.9,1.8s4.4-0.1,5.4-0.6c0.8-0.3,1.2-0.8,1.2-1.6v-1.4
	c-0.1-0.7-0.5-1.1-1.5-1.1L571.2,246.5L571.2,246.5z M263.3,246.9v1.4c0,0.8,0.4,1.4,1.2,1.6c1,0.4,2.8,0.6,5.4,0.6
	c2.6,0.1,4-0.4,3.9-1.8c-0.1-1.2-1-1.9-2.6-2.2l-6.3-0.8C263.8,245.8,263.3,246.1,263.3,246.9L263.3,246.9z"
          />
          <path
            fill="none"
            d="M264.8,245.2l-0.1,0.4l6.3,0.8c1.6,0.3,2.6,1,2.6,2.2c0.1,1.2-1.2,1.8-3.9,1.8s-4.4-0.1-5.4-0.6
	c0,1,0.1,1.9,0.4,3.2l0.1,0.6l28.2,2.6c4.4,0.7,6.7-1.1,7.2-5.1c-0.3-0.3-0.5-0.6-0.7-0.8l-3.2-7.7c-1-2.5-4.1-3.7-9.5-3.9h-2.6
	c-6.3-0.1-10.9,0.1-13.8,0.7h-0.1C267.8,240,265.9,241.9,264.8,245.2L264.8,245.2z M343.5,197.8l-0.5-0.4c-4.5,0.6-7.7,1.6-9.4,3.2
	c-2.9,2.5-5,5.2-6.1,8.3l1-0.1c3.4-0.6,6.3-0.8,8.7-0.6C339.6,201.1,341.8,197.6,343.5,197.8L343.5,197.8z"
          />
          <path
            fill="none"
            d="M345.1,197.6l-1.6,0.1c-1.7-0.3-3.9,3.2-6.5,10.3l3.4-0.6C342.3,202.6,343.8,199.3,345.1,197.6L345.1,197.6z
	 M314.7,192.5c-0.1,0-0.1,0-0.3,0.1c0,0-0.1,0.1-0.1,0.3v4l0.1,0.3l-0.1,36.5l0.5-1v-35.5l0.1-0.3v-4l-0.1-0.3L314.7,192.5
	L314.7,192.5z"
          />
          <path
            fill="none"
            d="M314.9,232.6l-0.5,1L304,252.2c1.8,0.3,3.2-0.1,3.9-1.2l2.9-6.3l12.9-27.9L314.9,232.6L314.9,232.6z"
          />
          <path
            fill="none"
            d="M310.8,244.7l6.3-7.2l11.3-28.9l-1,0.3l-0.1,0.3l-3.6,7.7"
          />
          <path
            fill="none"
            d="M317.1,237.5l-6.2,7.2L308,251c-0.8,1.1-2.1,1.4-3.9,1.2l-0.7-0.1c-1,2.9-2.1,4.5-3.2,5c-4,0.4-8.4,0.4-13.2,0
	c-1.5-0.1-2.6,0-3.2,0.1c-0.4,0.1-0.5,0.3-0.7,0.5l17.5,1c1.9,0.4,3.2,1.2,3.9,2.3c0.3,0.3,0.7,0.3,1.1,0.3c0.5,0,1.4-0.3,2.5-0.7
	L317.1,237.5L317.1,237.5z M340.5,209.1v-1.4l-3.4,0.6c0.4,0.3,0.4,0.7,0.1,1.4L340.5,209.1L340.5,209.1z"
          />
          <path
            fill="none"
            d="M337.2,209.6c0.3-0.7,0.3-1.2-0.1-1.4c-2.3-0.3-5.2,0-8.7,0.6l-11.4,28.9l-9.2,23.4l10.6-1.9L337.2,209.6
	L337.2,209.6z"
          />
          <path
            fill="none"
            d="M333.6,257.6h-0.1l-11.4,0.5c12.7,6.7,24.6,16.8,35.8,30.3c-11.1-13.5-23.1-23.5-35.8-30.3l-3.6,0.7
	c-5,3.9-6.9,7.3-5.8,10.2c1.5,4.3,2.5,9.9,2.8,16.8c2.9,2.6,6.6,4.5,11.3,5.9c9.4,2.5,18.7,4.1,27.8,5l14.7,0.7l14.9,0.3
	c5.8,0,11.7,0,17.7-0.3h38.7c6.1,0.1,12,0.3,17.7,0.3l14.9-0.3l14.7-0.7c9.1-0.8,18.3-2.5,27.8-5c4.5-1.4,8.3-3.4,11.3-5.9
	c0.3-6.9,1.2-12.4,2.8-16.8c1.1-2.9-0.8-6.2-5.8-10.2l-3.6-0.7c-12.7,6.7-24.6,16.8-35.8,30.3c11.1-13.5,23.1-23.5,35.8-30.3
	l-11.6-0.5l-20.9-1.2l-24.9-0.8h-86.4l-22.3,0.8L333.6,257.6L333.6,257.6z M327.1,293.5l-1.6-0.3c2.6,2.3,3.4,5.5,2.6,9.4l1.5-0.7
	C330,298.6,329.3,295.7,327.1,293.5L327.1,293.5z"
          />
          <path
            fill="none"
            d="M325.5,293.2l1.6,0.3l12.4,2.1l14.4,1.8c2.3,1.8,4.1,3.6,5.4,5.6c0.4,0.7-0.3,1-1.9,0.8l-17.9-1.2l-10-0.7
	l-1.5,0.7C328.9,298.7,328,295.6,325.5,293.2l-3.4-0.7l0.7,0.7c1.6,1.6,2.5,3.7,2.5,5.9c0,2.3-0.8,4.3-2.5,5.9
	c-1.5,1.5-3.2,2.3-5.2,2.5l9.8,2.2c4.3,0.8,8.1,1.4,11.4,1.8l-7.7-8.7l32.5,2.9l1.1-0.4l-9.6-8.1l-0.5-0.5c-9.1-0.8-18.3-2.5-27.8-5
	c-4.5-1.4-8.3-3.4-11.3-5.9l-14.9-2.3c2.5,1.6,6.7,3,12.8,4.1l-0.4,2.9l2.2,0.5l7,1.6L325.5,293.2L325.5,293.2z M305.4,261.5
	c-0.5,0-1,0-1.1-0.3c-0.5-1.1-1.8-1.9-3.9-2.3c-1.5,0.4-2.8,3-3.9,7.6c-0.7,4.4-1.8,8.1-3.3,11.1l1.4-0.1c0.7-4.1,2.9-8.5,6.6-13.3
	C302.5,262.7,303.9,261.7,305.4,261.5L305.4,261.5z"
          />
          <path
            fill="none"
            d="M318.3,258.8l-10.6,1.9c-1,0.4-1.8,0.7-2.5,0.7c-1.6,0.3-3,1.1-4.1,2.6c-3.7,4.8-5.9,9.4-6.6,13.3
	c-1,2.5-1.9,4.1-2.9,5l-0.7,0.4c-1.1,3.6-2.8,6.6-5.1,9.1c0,5.1,0.1,10.9,0.4,17.6l11-7c-0.7-2.5-1-5.6-0.8-9.8
	c0.3-7,1.6-10.2,4-9.4l14.9,2.3c-0.3-6.9-1.2-12.4-2.8-16.8C311.4,266.1,313.4,262.8,318.3,258.8L318.3,258.8z M303.3,252.1
	c-1.4-0.1-2.3-0.4-3-0.8c-0.4,4-2.9,5.6-7.2,5.1l-28.2-2.8c0.7,1.5,2.5,2.5,5.2,2.9l12.8,1.4c0.1-0.3,0.3-0.4,0.7-0.5
	c0.7-0.3,1.8-0.3,3.2-0.1c4.8,0.4,9.2,0.4,13.2,0C301.4,256.6,302.4,255,303.3,252.1L303.3,252.1z"
          />
          <path
            fill="none"
            d="M300.4,283.5c-0.3,0-0.7,0.1-1,0.3c0.4,1.1,1.4,2.1,3,3c0.7,0.4,1.4,0.8,2.1,1.1l5.5,1.7l2.6,0.7l0.4-2.9
	C307.3,286.5,303.1,285.1,300.4,283.5L300.4,283.5z"
          />
          <path
            fill="none"
            d="M299.6,283.7c0.3-0.1,0.5-0.3,1-0.3c-2.3-0.8-3.6,2.2-4,9.4c-0.1,4.1,0.1,7.3,0.8,9.8h3l-0.3-0.8
	c-0.7-2.3-1.2-5.2-1.4-8.9c-0.1-1.9-0.1-4-0.1-6.3C298.7,285,298.9,284.2,299.6,283.7L299.6,283.7z M291.2,282
	c0.1,0.3,0.3,0.4,0.5,0.4c1.1-0.8,2.1-2.5,2.9-5l-1.4,0.1c-1.4,0.3-2.1,1-2.1,1.9C291.1,280.7,291.1,281.5,291.2,282L291.2,282z"
          />
          <path
            fill="none"
            d="M291.8,282.4c-0.1,0-0.4-0.1-0.5-0.4l-0.3,0.8L291.8,282.4L291.8,282.4z M298.6,292.7l2.2,1.6l1.4,1
	c-0.4-3.4-0.3-6.2,0.4-8.4c-1.6-1.1-2.6-2.1-3-3c-0.7,0.4-1,1.4-1,2.6C298.5,288.7,298.7,290.8,298.6,292.7L298.6,292.7z"
          />
          <path
            fill="none"
            d="M301,294.2l-2.2-1.6c0.3,3.7,0.7,6.6,1.4,8.9l0.3,0.8c0.7,2.5,2.3,4,5,4.5c6.9,1.8,14.3,3.3,21.9,4.5l11.4,1.8
	c0.8,0.3,1.1,0.1,0.7-0.7l-12.1-2.1c-7.3-1.4-14-2.9-20.2-4.4c-2.6-0.7-4.1-1.8-4.5-3.2L301,294.2L301,294.2z"
          />
          <path
            fill="none"
            d="M310.2,289.7l-5.5-1.6c-0.8-0.4-1.5-0.7-2.1-1.1c-0.7,2.2-0.8,5-0.4,8.4l4,2.9c0.3-1.2,0.7-2.3,1.1-3.4l0.4-1
	C308.4,291.9,309.2,290.5,310.2,289.7L310.2,289.7z"
          />
          <path
            fill="none"
            d="M312.8,290.4l-2.6-0.7c-1,1-1.8,2.3-2.5,4.1l-0.4,1c-0.4,1-0.7,2.2-1.1,3.4c-0.4,3.6,1.5,6.2,5.8,8.1h0.1
	c-0.4-0.3-0.8-0.7-1.2-1.1c-1.6-1.6-2.5-3.6-2.5-5.9c0-2.3,0.8-4.3,2.5-5.9c1.2-1.2,2.6-1.9,4.3-2.3L312.8,290.4L312.8,290.4z"
          />
          <path
            fill="none"
            d="M322,292.5l-7-1.6c-1.6,0.3-3,1.1-4.3,2.3c-1.6,1.6-2.5,3.7-2.5,5.9c0,2.3,0.8,4.3,2.5,5.9
	c0.4,0.4,0.8,0.7,1.2,1.1l5.5,1.5c2.1-0.1,3.7-1,5.2-2.5c1.6-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9L322,292.5L322,292.5z
	 M316.8,291.6c2.1,0,3.9,0.7,5.4,2.2c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4c-1.5,1.5-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2
	c-1.5-1.5-2.2-3.3-2.2-5.4c0-2.1,0.8-3.9,2.2-5.4C313,292.3,314.7,291.6,316.8,291.6L316.8,291.6z"
          />
          <path
            fill="none"
            d="M322.2,293.8c-1.5-1.5-3.3-2.2-5.4-2.2s-3.9,0.7-5.4,2.2c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.8,3.9,2.2,5.4
	c1.5,1.5,3.3,2.2,5.4,2.2s3.9-0.7,5.4-2.2c1.5-1.5,2.2-3.3,2.2-5.4C324.4,297.1,323.7,295.3,322.2,293.8L322.2,293.8z M316.8,292.4
	c1.9,0,3.4,0.7,4.8,1.9c1.4,1.4,2.1,2.9,2.1,4.8s-0.7,3.4-2.1,4.8c-1.4,1.4-3,2.1-4.8,2.1c-1.9,0-3.4-0.7-4.8-2.1
	c-1.4-1.4-2.1-2.9-2.1-4.8c0-1.9,0.7-3.6,2.1-4.8C313.4,293,314.9,292.4,316.8,292.4L316.8,292.4z"
          />
          <path
            fill="none"
            d="M321.6,294.3c-1.4-1.4-3-1.9-4.8-1.9c-1.9,0-3.4,0.7-4.8,1.9c-1.4,1.4-2.1,2.9-2.1,4.8c0,1.9,0.7,3.4,2.1,4.8
	c1.4,1.4,3,2.1,4.8,2.1c1.9,0,3.4-0.7,4.8-2.1c1.4-1.4,2.1-2.9,2.1-4.8C323.7,297.2,323,295.7,321.6,294.3L321.6,294.3z"
          />
          <path
            fill="none"
            d="M312,306.2c-4.3-1.9-6.1-4.5-5.8-8.1l-4-2.9l-1.4-1l1.5,8.9c0.5,1.4,2.1,2.5,4.5,3.2c6.1,1.5,12.8,3,20.2,4.4
	l12.1,2.1l-0.7-1.4c-3.4-0.4-7.2-1-11.4-1.8l-9.8-2.2L312,306.2L312,306.2z"
          />
          <path
            fill="none"
            d="M544.8,302.5h-3c-0.7,2.5-2.3,4-5,4.5c-6.9,1.8-14.3,3.3-21.9,4.5l-11.4,1.8c-0.8,0.3-1.1,0.1-0.7-0.7
	c0.1-0.3,0.4-0.8,0.7-1.4l7.7-8.7l-32.5,2.9l-1.1-0.4l9.6-8.1l-0.3,0.1l-16.8,12.8l16.8-12.8l-13.3,0.5c-3.4,2.2-6.1,3.9-8,5
	l-1.2,0.7c-1.6,0.8-3.9,1.4-6.7,1.5l-6.6,0.3l-16,0.1c-1,0-1.8-0.6-2.3-1.6c-1.5-2.9-4-4.8-7.3-5.6l-0.3-0.1h-8.4l-0.3,0.1
	c-3.3,0.8-5.8,2.8-7.3,5.6c-0.5,1.1-1.2,1.6-2.3,1.6l-16-0.1l-6.6-0.3c-2.9-0.1-5.1-0.5-6.7-1.5l-1.2-0.7c-1.9-1.1-4.5-2.8-8-5
	l-13.3-0.5l16.8,12.8l-16.8-12.8l-0.3-0.1l9.6,8.1l-1.1,0.4l-32.5-2.9l7.7,8.7l0.7,1.4c0.3,0.8,0.1,1-0.7,0.7l-11.4-1.8
	c-7.7-1.4-15-2.9-21.9-4.5c-2.6-0.5-4.3-2.1-5-4.5h-3c0.7,2.3,1.6,3.9,2.9,4.8c2.3,2.1,7.2,3.4,14.3,4.5l26.8,4.7l-26.8-4.7
	c-7.2-1-12-2.5-14.3-4.5c-1.4-0.8-2.3-2.5-2.9-4.8l-11,7c0.3,6.9,0.8,14.4,1.4,23v0.1h8.5l-0.3-10c-0.3-2.6,0.3-3.7,1.5-3
	c1.4,0,2.3,0.5,3,1.6l0.3,0.7l0.1,0.3l2.6,10.3h40.7l1.8-2.3c1.4-1.6,2.9-3.2,4.5-4.8s3.4-3.3,5.5-5c1.1-1,2.2-1.8,3.4-2.8
	c2.2-2.1,6.5-3,13.1-3h96.8c6.5,0,10.9,1,13.1,3l3.4,2.8c1.9,1.6,3.7,3.3,5.5,5c1.7,1.6,3.2,3.2,4.5,4.8l1.8,2.3h40.7l2.6-10.3
	l0.1-0.3l0.3-0.7c0.7-1.1,1.7-1.6,3-1.6c1.2-0.7,1.8,0.4,1.5,3l-0.3,10h8.5v-0.1c0.7-8.5,1.1-16.2,1.4-23L544.8,302.5
	c-0.7,2.3-1.6,3.8-2.9,4.8c-2.3,2.1-7.2,3.4-14.3,4.5l-26.8,4.7l26.8-4.7c7.2-1,12-2.5,14.3-4.5
	C543.2,306.4,544.2,304.8,544.8,302.5L544.8,302.5z M428.4,300.4l1.1,0.8c0.3,0.4,0.7,0.7,0.8,1.1c0.3,0.4,0.4,1,0.4,1.4
	c0.1,0.5,0,1.1-0.1,1.6l-0.7,1.2l-1,1l-1.1,0.8c-0.8,0.5-1.8,1-2.8,1.2c-1.1,0.3-2.2,0.5-3.3,0.5l-3.6-0.3c-1.1-0.3-1.9-0.5-2.9-1
	c-1-0.4-1.6-1-2.2-1.6c-0.3-0.3-0.7-0.7-0.8-1.1c-0.3-0.4-0.4-0.8-0.5-1.4c-0.1-0.5-0.1-1.1,0.1-1.6c0.1-0.4,0.3-0.8,0.7-1.4
	c0.3-0.3,0.5-0.7,1-1c0.3-0.3,0.7-0.5,1.1-0.8c1.2-0.8,2.6-1.2,4.3-1.5l1.8-0.1c2.3-0.1,4.5,0.3,6.6,1.2
	C427.5,299.8,428,300.1,428.4,300.4L428.4,300.4z M479.1,313.5c-1.4-0.4-2.8-0.5-4.1-0.5H367.2c-1.9-0.1-4,0.3-5.9,1.1
	c-1.5,0.7-3,1.5-4.5,2.8c1.5-1.2,3-2.2,4.5-2.8c1.9-0.8,4-1.2,5.9-1.1H475C476.4,312.9,477.8,313.2,479.1,313.5l1.4,0.5
	c1.6,0.7,3.3,1.6,4.8,2.9c-1.6-1.2-3.2-2.2-4.8-2.9L479.1,313.5L479.1,313.5z M342.4,335.3l2.1-2.6h-40.7l1.8,6.9l1.4,1.8
	c1,1.4,1.6,3,2.2,5l0.1,0.6c0.4,1.6,0.4,4,0.1,7c-0.8,4-3.2,5.9-7.3,5.6c-2.6-0.3-4.3-1.4-4.8-2.9c0.4,2.5,2.1,4,5.2,4.5
	c7.3,1.2,15.7,2.1,24.9,2.3l3.9,0.1l-3.9-0.1c-9.4-0.3-17.6-1.1-24.9-2.3c-3-0.5-4.8-2.1-5.2-4.5c-0.1-0.3-0.1-0.5-0.3-0.8v-0.3
	l-0.1-3.4v-8.9l-0.3-10.5h-8.5l1.8,24.4l0.7,9.4c9.9,1.1,21.6,1.6,35.1,1.8h20.6c-3.3-4.7-5.6-8.8-7-12.5l-0.4-1.1
	c-0.4-1.1-0.7-2.2-1-3.3l-0.3-1.6c-0.3-1.8-0.1-3.3,0.3-4.8l0.1-0.5l0.1-0.8l0.1-0.1l2.1-4.3C340.7,337.8,341.6,336.6,342.4,335.3
	L342.4,335.3z M554.4,332.7h-8.5l-0.3,10.5v8.9l-0.1,3.4v0.3l-0.3,0.8c-0.4,2.5-2.1,4-5.2,4.5c-7.3,1.2-15.7,2.1-24.9,2.3l-3.9,0.1
	l3.9-0.1c9.4-0.3,17.6-1.1,24.9-2.3c3-0.5,4.8-2.1,5.2-4.5c-0.6,1.6-2.2,2.6-4.8,2.9c-4.1,0.3-6.5-1.7-7.3-5.6
	c-0.3-3.2-0.3-5.5,0.1-7l0.1-0.5c0.5-1.9,1.2-3.6,2.2-5c0.4-0.7,0.8-1.2,1.4-1.8l1.8-6.9H498l2.1,2.6c1,1.2,1.6,2.6,2.5,3.9l2.1,4.3
	l0.1,0.1l0.1,0.8l0.1,0.5c0.4,1.5,0.4,3,0.3,4.8l-0.4,2.1l-0.8,2.9c-1.4,3.9-3.9,8.4-7.4,13.6h20.6c13.3-0.1,25-0.8,34.9-1.8l0.1-1
	l0.7-8.4L554.4,332.7L554.4,332.7z"
          />
          <path
            fill="none"
            d="M301.1,322.4l-0.1-0.3l-1.5-0.4c-1,0-1.2,0.1-1.1,0.7c-0.1,4.5,0,9.6,0.3,15c0.1,1.5,0.8,2.3,2.1,2.2h4.7
	L301.1,322.4L301.1,322.4z"
          />
          <path
            fill="none"
            d="M301,322l-0.4-0.7l-2.5-0.5c-1-0.3-1.4,0.4-1.2,1.6l0.4,15.5c0,1.5,1,2.3,2.8,2.6l6.7,0.7l-1.4-1.8h-4.7
	c-1.4,0.1-2.1-0.7-2.1-2.2c-0.4-5.5-0.5-10.5-0.3-15c-0.3-0.5,0.1-0.8,1.1-0.7L301,322L301,322z"
          />
          <path
            fill="none"
            d="M297,322.4c-0.1-1.4,0.4-1.9,1.2-1.6l2.5,0.5c-0.7-1.1-1.6-1.6-3-1.6c-1.2-0.7-1.8,0.4-1.5,3l0.4,20.4l1.2,0.1
	l8.5,1c1,0,1.5,0.5,1.5,1.2l-10-1l-1.2-0.1v2.1l1.2,0.1l9.2,1c1,0,1.4,0.4,1.5,1.4l-10.7-0.8l-1.2-0.1v2.5l1.2,0.1l9.2,0.8
	c0.8,0,1.2,0.4,1.2,1c-0.1,0.4-0.7,0.4-1.8,0.3l-8.7-0.7l-1.4-0.1l0.1,3.4l1.4,0.1v-1.4l-1-0.1l1,0.1l8.4,1c0.8,0,1.2,0.4,1.1,1
	c-0.1,0.4-0.7,0.5-1.6,0.4l-7.7-1l-1.4-0.1v0.3c0,0.3,0.1,0.5,0.3,0.8c0.5,1.6,2.2,2.6,4.8,2.9c4.1,0.3,6.5-1.6,7.3-5.6
	c0.3-3.2,0.3-5.5-0.1-7l-0.1-0.5c-0.5-1.9-1.2-3.6-2.2-5l-6.7-0.7c-1.8-0.3-2.8-1.2-2.8-2.6L297,322.4z"
          />
          <path
            fill="none"
            d="M307.9,345.5c0-0.8-0.5-1.2-1.5-1.2l-8.5-1v1.4L307.9,345.5L307.9,345.5z M308.7,349c-0.1-1-0.5-1.4-1.5-1.4
	l-9.2-1l0.1,1.5L308.7,349L308.7,349z M307.7,356.2c0.1-0.5-0.3-1-1.1-1l-8.4-1v1.4l7.7,1C307,356.8,307.6,356.7,307.7,356.2
	L307.7,356.2z M306.8,352.8c1.1,0.1,1.6,0,1.8-0.3c0-0.7-0.4-1-1.2-1l-9.2-0.8v1.5L306.8,352.8L306.8,352.8z M297.8,343.2l-1.2-0.1
	v1.4l1.2,0.1 M297.8,346.6l-1.1-0.1v1.6h1.2"
          />
          <path
            fill="none"
            d="M298.1,350.7l-1.4-0.1l0.1,1.5l1.2,0.1 M542.7,321.6l-1.5,0.4l-0.1,0.3l-4.4,17.2h4.7c1.4,0.1,2.1-0.7,2.1-2.2
	c0.4-5.5,0.5-10.5,0.3-15C543.9,321.9,543.5,321.6,542.7,321.6L542.7,321.6z"
          />
          <path
            fill="none"
            d="M541,322l1.5-0.4c1,0,1.2,0.1,1.1,0.7c0.1,4.5,0,9.6-0.4,15c-0.1,1.5-0.8,2.3-2.1,2.2h-4.7
	c-0.5,0.5-1,1.1-1.4,1.8l6.7-0.7c1.8-0.3,2.8-1.2,2.8-2.6l0.4-15.5c0.1-1.4-0.4-1.9-1.2-1.6l-2.5,0.5L541,322L541,322z"
          />
          <path
            fill="none"
            d="M544.5,319.6c-1.4,0-2.3,0.5-3,1.6l2.5-0.5c1-0.3,1.4,0.4,1.2,1.6l-0.4,15.5c0,1.5-1,2.3-2.8,2.6l-6.7,0.7
	c-1,1.4-1.7,3-2.2,5l-0.1,0.5c-0.4,1.6-0.4,4-0.1,7c0.8,4,3.2,5.9,7.3,5.6c2.6-0.3,4.3-1.4,4.8-2.9l0.3-0.8v-0.3l-1.4,0.1l-7.7,1
	c-1,0.3-1.5,0.1-1.7-0.4c-0.1-0.5,0.3-1,1.1-1l8.4-1l1-0.1l-1,0.1v1.4l1.4-0.1l0.1-3.4l-1.4,0.1l-8.7,0.7c-1.1,0.1-1.7,0-1.8-0.3
	c0-0.7,0.4-1,1.2-1l9.2-0.8l1.2-0.1v-2.5l-1.2,0.1l-10.7,0.8c0.1-1,0.5-1.4,1.5-1.4l9.2-1l1.2-0.1v-2.1l-1.2,0.1l-10,1
	c0-0.8,0.5-1.2,1.5-1.2l8.5-1l1.2-0.1l0.4-20.4C546.3,320.1,545.7,319,544.5,319.6L544.5,319.6z"
          />
          <path
            fill="none"
            d="M544.3,343.2v1.4l1.1-0.1l0.1-1.4 M544.3,344.5v-1.4l-8.5,1c-1,0-1.5,0.5-1.5,1.2L544.3,344.5L544.3,344.5z
	 M545.4,346.5l-1.2,0.1v1.5h1.2 M545.3,350.6l-1.2,0.1v1.5l1.2-0.1 M544.2,348.1v-1.5l-9.2,1c-1,0-1.4,0.4-1.5,1.4L544.2,348.1
	L544.2,348.1z M533.5,352.5c0.1,0.4,0.7,0.4,1.8,0.3l8.7-0.7v-1.5l-9.2,0.8C534,351.6,533.6,352,533.5,352.5L533.5,352.5z
	 M534.4,356.2c0.1,0.4,0.7,0.5,1.7,0.4l7.7-1v-1.4l-8.4,1C534.6,355.3,534.3,355.7,534.4,356.2L534.4,356.2z M428.4,300.4
	c-0.4-0.3-0.8-0.5-1.2-0.7c-1.9-1-4.1-1.4-6.6-1.2l-1.8,0.1c-1.6,0.3-3,0.8-4.3,1.5c-0.4,0.3-0.8,0.5-1.1,0.8l-1,1
	c-0.3,0.4-0.5,0.8-0.7,1.4c-0.1,0.5-0.1,1.1-0.1,1.6c0.1,0.4,0.3,1,0.5,1.4c0.3,0.4,0.5,0.7,0.8,1.1c0.5,0.7,1.4,1.1,2.2,1.6
	c1,0.4,1.8,0.8,2.9,1l3.6,0.3c1.2,0,2.3-0.3,3.3-0.5s1.8-0.7,2.8-1.2l1.1-0.8l1-1l0.7-1.2c0.3-0.5,0.3-1.1,0.1-1.7
	c0-0.4-0.3-1-0.4-1.4c-0.3-0.4-0.5-0.7-0.8-1.1L428.4,300.4L428.4,300.4z M428.5,303.4c0.1,0,0.3,0.1,0.4,0.3v1.1
	c-0.1,0.1-0.3,0.1-0.4,0.1v-1.1H426v1.1h-0.6v-1.4L428.5,303.4L428.5,303.4z M424.9,303.4l0.1,0.3v0.8l-0.1,0.3l-0.7,0.1H422
	l-0.3-0.3v-1l0.1-0.1h2.3L424.9,303.4L424.9,303.4z M426,300c1.4,0.7,2.3,1.8,2.8,3h-6.9l-0.3-0.3l-0.3-0.4c-0.1-0.4-0.3-1-0.3-1.5
	s0-1.1,0.1-1.8l2.8,0.3C424.7,299.4,425.5,299.7,426,300L426,300z M420.9,299c-0.5,0.7-1,1.2-1.4,1.8c-0.4,0.7-0.7,1.4-0.8,2.2h-5.2
	c0.3-0.7,0.5-1.2,1.1-1.6c0.4-0.4,1-0.8,1.6-1.2C417.6,299.4,419.2,299,420.9,299L420.9,299z M416.7,303.4v0.3h-2.9v0.3h2.9v0.4
	l-0.1,0.3l-0.4,0.1h-2.8l-0.1-0.3h2.8c0.3,0,0.3-0.1,0.1-0.3h-2.9v-0.5c0-0.1,0.1-0.3,0.4-0.3L416.7,303.4L416.7,303.4z
	 M417.1,303.6c0-0.1,0.1-0.3,0.3-0.3h3v0.3h-2.9c-0.1,0-0.1,0.1,0,0.4v0.4h2.9v0.3h-3c-0.3,0-0.3-0.1-0.4-0.3L417.1,303.6
	L417.1,303.6z M419.4,305.2h0.3c0.1,0,0.3,0.1,0.4,0.3l0.4,0.4c0.3,0.4,0.4,1,0.5,1.6c0,0.7,0,1.4-0.1,1.9c-1.8,0-3.4-0.4-4.8-1.2
	c-1.4-0.8-2.3-1.8-2.8-3L419.4,305.2L419.4,305.2z M420.8,303.4h0.5v1.4h-0.5V303.4z M423,306.4l0.4-1.2h5.5
	c-0.4,1.4-1.2,2.5-2.8,3.2c-0.7,0.4-1.5,0.5-2.3,0.8l-2.8,0.3l0.1-0.1l0.1-0.1l1.2-1.6L423,306.4L423,306.4z"
          />
          <path
            fill="none"
            d="M428.9,303.6c0-0.1-0.1-0.1-0.4-0.3h-3v1.4h0.5v-1.1h2.5v1.1c0.3,0,0.4,0,0.4-0.1L428.9,303.6L428.9,303.6z
	 M425.1,303.7l-0.1-0.3l-0.7-0.1h-2.3l-0.1,0.1v1l0.3,0.3h2.2l0.7-0.1l0.1-0.3V303.7L425.1,303.7L425.1,303.7z M424.1,303.7h0.5v0.8
	h-2.3v-0.4c0-0.1,0-0.3,0.1-0.4L424.1,303.7L424.1,303.7z"
          />
          <path
            fill="none"
            d="M424.5,303.7h-2.3c0,0.1-0.1,0.1-0.1,0.4v0.4h2.3v-0.4L424.5,303.7L424.5,303.7z M428.9,303
	c-0.4-1.2-1.4-2.2-2.8-3c-0.7-0.4-1.4-0.5-2.2-0.8l-2.8-0.3c-0.1,0.5-0.1,1.1-0.1,1.8s0.1,1.2,0.3,1.5l0.3,0.4l0.3,0.3h7L428.9,303
	L428.9,303z M420.9,299.2l0.1-0.1c-1.8,0-3.4,0.4-4.8,1.2c-0.7,0.3-1.1,0.7-1.6,1.2s-0.8,1.1-1.1,1.6h5.2c0.1-0.8,0.4-1.5,0.8-2.2
	C420,300.3,420.4,299.7,420.9,299.2L420.9,299.2z M416.7,303.7v-0.3h-3c-0.1,0-0.3,0.1-0.4,0.3v0.5h2.9c0,0.1,0,0.3-0.1,0.3h-2.8
	l0.1,0.3h2.8l0.3-0.1l0.1-0.3V304h-2.9v-0.3L416.7,303.7L416.7,303.7z M417.5,303.4c-0.1,0-0.3,0.1-0.3,0.3v1c0,0.1,0.1,0.3,0.4,0.3
	h3v-0.4h-2.9v-0.4v-0.4h2.9v-0.3L417.5,303.4L417.5,303.4z M419.8,305.2h-6.3c0.4,1.2,1.4,2.2,2.8,3c1.4,0.8,3,1.2,4.8,1.2
	c0.1-0.7,0.1-1.2,0.1-1.9c-0.1-0.7-0.3-1.2-0.5-1.6l-0.4-0.4C420.1,305.3,420,305.2,419.8,305.2L419.8,305.2z M421.4,303.4h-0.5v1.4
	h0.5"
          />
          <path
            fill="none"
            d="M423.3,305.2l-0.4,1.2l-0.5,1.1l-1.2,1.6l-0.1,0.1l-0.1,0.1l2.8-0.3c0.8-0.1,1.6-0.4,2.3-0.8
	c1.5-0.8,2.5-1.8,2.8-3.2L423.3,305.2L423.3,305.2z M354.2,296.7l0.5,0.5l0.3,0.1l13.3,0.5l15.4,0.3c5.8,0,11.7-0.1,17.7-0.3h38.7
	c6.1,0.1,12,0.3,17.7,0.3l15.4-0.3l13.3-0.5l0.3-0.1l0.5-0.5l-14.7,0.7l-14.9,0.3c-5.8,0-11.7,0-17.7-0.3h-38.5
	c-6.1,0.1-12,0.3-17.7,0.3l-14.9-0.3L354.2,296.7L354.2,296.7z M359.2,303.1c-1.4-2.1-3.2-3.9-5.4-5.6l-14.4-1.8v7l17.9,1.2
	C359,304.1,359.6,303.8,359.2,303.1L359.2,303.1z M350.1,300c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.4-0.8,0.4
	c-0.3,0-0.5-0.1-0.8-0.4c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.1,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3
	C350,299.4,350.1,299.7,350.1,300L350.1,300z"
          />
          <path
            fill="none"
            d="M349.7,300.8c0.3-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.1-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3C349.3,301.1,349.6,300.9,349.7,300.8L349.7,300.8z M339.4,302.7
	v-7l-12.4-2.1c2.2,2.2,3,5,2.3,8.4L339.4,302.7L339.4,302.7z M334.4,299.8c-0.3,0-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.5-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.3,0.1,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8
	C335,299.7,334.7,299.8,334.4,299.8L334.4,299.8z"
          />
          <path
            fill="none"
            d="M333.6,299.4c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.6,0.3-0.8s-0.1-0.5-0.3-0.8
	c-0.1-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.3-0.3,0.5-0.3,0.8C333.3,299,333.5,299.3,333.6,299.4L333.6,299.4z
	 M356.3,320.6c-1.9,1.6-3.7,3.3-5.5,5c-1.6,1.6-3.2,3.2-4.5,4.8l-3.9,5c-1,1.2-1.6,2.6-2.5,3.9l-2.1,4.3l-0.3,1l-0.1,0.5
	c-0.3,1.8-0.3,3.4,0.3,4.8c0.1,0.5,0.4,1.1,0.7,1.6c0.3,0.4,0.7,1,1,1.4c0.7,0.7,1.5,1.4,2.6,1.9l0.8,0.4c4.3,2.3,8.1,3.9,11.6,4.7
	h0.3c1.5,0.4,2.9,0.5,4.3,0.5l54.5,0.4l69.5-0.4c1.4,0,2.8-0.3,4.3-0.5h0.4c3.4-0.8,7.3-2.3,11.6-4.7l0.8-0.4c1-0.5,1.9-1.2,2.6-1.9
	l1-1.4c0.3-0.5,0.5-1.1,0.8-1.6c0.4-1.4,0.5-3,0.1-4.8l-0.1-0.5l-0.3-1l-2.1-4.3c-0.7-1.4-1.5-2.6-2.5-3.9l-3.9-5
	c-1.4-1.6-2.9-3.2-4.5-4.8c-1.7-1.6-3.4-3.3-5.5-5l-3.4-2.8c-2.2-2.1-6.5-3-13.1-3h-96.8c-6.5,0-10.9,1-13.1,3
	C358.5,318.7,357.4,319.6,356.3,320.6L356.3,320.6z M352.6,325.4l2.1-1.9l1.5-1.4l1.9-1.6l2.3-1.8c2.1-1.9,6.1-2.8,12.1-2.8h97
	c6.1,0,10.2,0.8,12.1,2.8l2.3,1.8l1.9,1.6l1.5,1.4l2.1,1.9c1.7,1.7,3.2,3.2,4.7,4.8l1.5,1.8l1.1,1.4l1.4,1.9c1.1,1.5,2.1,3,2.9,4.5
	v0.1l0.7,1.4l0.3,0.4l0.7,1.4l0.4,1l0.3,1c0.3,1.8,0.3,3.4-0.3,4.8l-1,1.4l-1.4,1.4c-0.5,0.5-1.4,1-2.1,1.2l-1.2,0.7
	c-3.7,1.9-7.2,3.2-10.2,4h-0.4c-1.4,0.3-2.6,0.4-3.9,0.5l-18.8,0.1h-0.3l-23.1,0.1h-0.4l-19.1,0.1h-0.3l-19.1-0.1h-0.4l-23.1-0.1
	H378l-18.8-0.1c-1.2,0-2.5-0.3-3.9-0.5h-0.4c-3-0.7-6.5-2.1-10.2-4l-1.2-0.7c-0.8-0.4-1.5-0.8-2.1-1.2s-1-0.8-1.4-1.4
	c-0.4-0.5-0.7-1.1-1-1.6c-0.5-1.4-0.5-3-0.3-4.8l0.3-1l0.4-1l0.7-1.4l0.3-0.4l0.7-1.4v-0.1c0.8-1.5,1.8-3,2.9-4.5l1.4-1.9l1.1-1.4
	l1.5-1.8C349.4,328.6,350.9,327.1,352.6,325.4L352.6,325.4z"
          />
          <path
            fill="none"
            d="M354.6,323.5l-2.1,1.9h137l-2.1-1.9h-12.8c0.1,0.7-0.7,1.1-2.3,1.2H369.9c-1.7-0.1-2.5-0.6-2.3-1.2L354.6,323.5
	L354.6,323.5z"
          />
          <path
            fill="none"
            d="M356.2,322.3l-1.5,1.4h12.8c-0.1,0.7,0.7,1.1,2.3,1.2l1.5-3.6c-1.8,0-3,0.4-3.7,1.1h-11.4L356.2,322.3
	L356.2,322.3z"
          />
          <path
            fill="none"
            d="M358.2,320.6l-1.9,1.6h11.4c0.7-0.7,1.9-1,3.7-1.1H471c1.8,0,3,0.4,3.7,1.1h11.4l-1.9-1.6H358.2z M344,335.3
	c-1.1,1.5-2.1,3-2.9,4.5v0.1h22l2.1-4.5L344,335.3L344,335.3z M347.9,330.2l-1.5,1.8h16.9c0.7-0.7,1.9-1,3.6-1.1h108.3
	c1.7,0,2.9,0.4,3.6,1.1h16.9l-1.5-1.8L347.9,330.2L347.9,330.2z"
          />
          <path
            fill="none"
            d="M346.5,332l-1.1,1.4h17.9c-0.1,0.7,0.7,1.1,2.2,1.2l1.5-3.6c-1.7,0-2.9,0.4-3.6,1.1h-16.9L346.5,332L346.5,332z
	"
          />
          <path
            fill="none"
            d="M358.5,350.3h126.3c1.8,0,3,0.4,3.9,1.1H502l1-1.6H339.1c0.3,0.5,0.5,1.1,1,1.6h13.3c0.7-0.7,2.1-1,3.9-1.1
	H358.5L358.5,350.3z M358.8,349.8h0.4l2.1-4.8H361"
          />
          <path
            fill="none"
            d="M358.5,350.3h-1.4c-1.8,0-3,0.4-3.9,1.1h-13.3c0.4,0.5,0.8,1,1.4,1.4h11.8c-0.1,0.7,1,1.1,3.2,1.2h0.7
	L358.5,350.3L358.5,350.3z"
          />
          <path
            fill="none"
            d="M343.5,353.9l1.2,0.7h153l1.2-0.7c0.8-0.4,1.5-0.8,2.1-1.2h-11.8c0.1,0.7-1,1.1-3.2,1.2H356.3
	c-2.2,0-3.3-0.5-3.2-1.2h-11.8C342,353.2,342.7,353.6,343.5,353.9L343.5,353.9z"
          />
          <path fill="none" d="M357,354.6h-0.3l-1.8,4h0.4" />
          <path
            fill="none"
            d="M355.3,358.6c1.4,0.3,2.6,0.4,3.9,0.5l18.8,0.1l1-4.7h-21.9L355.3,358.6L355.3,358.6z M354.9,358.6l1.8-4h-12
	C348.4,356.5,351.9,357.9,354.9,358.6z M358.9,350.3l-1.5,3.6h19.5l0.4-0.4c0.4-0.4,1.1-0.7,1.6-0.7h0.3l0.4-1.5
	c-0.5,0-1.1-0.3-1.5-0.7l-0.4-0.4h-18.8L358.9,350.3L358.9,350.3z M340.1,341.7l-0.7,1.4h19.3c-0.1,0.7,0.8,1.1,2.6,1.2l1.5-3.6
	h-0.1c-1.8,0-3,0.3-3.9,1L340.1,341.7L340.1,341.7z"
          />
          <path
            fill="none"
            d="M340.3,341.2l-0.3,0.4h18.8c0.7-0.7,1.9-1,3.9-1h116.4c1.8,0,3,0.3,3.9,1h19.1l-0.3-0.4l-0.7-1.4H363h0.1H341
	L340.3,341.2L340.3,341.2z M339,344l-0.3,1h164.9l-0.3-1l-0.4-1h-19.3c0.1,0.7-0.8,1.1-2.6,1.2H361.4c-1.8-0.1-2.8-0.5-2.6-1.2
	h-19.3L339,344z"
          />
          <path
            fill="none"
            d="M338.8,345c-0.3,1.8-0.1,3.4,0.3,4.8h19.7l2.1-4.8H338.8L338.8,345z M454.8,316.1h-0.4l1.1,4.5h0.3
	 M469.5,316.1h-1l1.9,4.5h13.5l-2.3-1.8C479.5,316.9,475.6,315.9,469.5,316.1L469.5,316.1z"
          />
          <path fill="none" d="M468.5,316.1h-0.4l2.1,4.5h0.3" />
          <path
            fill="none"
            d="M468.1,316.1h-13.3l1,4.5h14.4 M438.1,316.1h-0.4l0.3,4.5h0.4 M440.5,321.2l-0.5,0.5c-0.4,0.4-1,0.5-1.5,0.7
	l0.1,1.2h0.1c0.7,0,1.2,0.3,1.6,0.7l0.5,0.5h13.5l0.3-0.4c0.4-0.4,0.8-0.5,1.4-0.5l-0.3-1.5h-0.1c-0.7,0-1.2-0.3-1.6-0.7l-0.4-0.4
	L440.5,321.2L440.5,321.2z"
          />
          <path
            fill="none"
            d="M440.1,321.7l0.5-0.5h-4.4l0.4,0.5c0.4,0.4,1,0.5,1.5,0.7h0.3C439.1,322.3,439.7,322.1,440.1,321.7L440.1,321.7
	z M438.8,323.6h-0.5c-0.4,0-0.8,0.3-1.2,0.5c-0.3,0.1-0.4,0.3-0.4,0.5h4.3l-0.5-0.5C439.9,323.8,439.4,323.6,438.8,323.6z"
          />
          <path
            fill="none"
            d="M438.1,322.4c-0.5,0-1.1-0.3-1.5-0.7l-0.4-0.5h-12.9l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7v1.2
	c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5h13.3c0.1-0.1,0.3-0.4,0.4-0.5c0.3-0.3,0.8-0.5,1.2-0.5L438.1,322.4z M438.6,322.4h-0.4l0.1,1.2h0.3
	 M438.7,325.4h-0.3l0.3,4.8h0.3 M439.1,331.9c0.5,0,1.1-0.3,1.5-0.7l0.3-0.4h-4.1l0.3,0.4c0.4,0.4,1,0.5,1.5,0.7H439.1L439.1,331.9z
	"
          />
          <path
            fill="none"
            d="M440.6,331.3c-0.4,0.4-1,0.5-1.5,0.7l0.1,1.6h0.1c0.7,0,1.2,0.3,1.6,0.7l0.3,0.3h15.1c0.1-0.3,0.3-0.5,0.5-0.7
	c0.4-0.3,0.7-0.5,1.2-0.5l-0.3-1.2c-0.7,0-1.2-0.3-1.6-0.7l-0.4-0.4H441L440.6,331.3z"
          />
          <path fill="none" d="M439.1,331.9h-0.4l0.1,1.8h0.4" />
          <path
            fill="none"
            d="M439.4,333.7h-0.5c-0.4,0-0.8,0.3-1.2,0.6l-0.3,0.3h4l-0.3-0.3C440.6,333.8,440.1,333.7,439.4,333.7
	L439.4,333.7z"
          />
          <path
            fill="none"
            d="M438.8,333.7l-0.1-1.8c-0.5,0-1.1-0.3-1.5-0.7l-0.3-0.4h-13.6l-0.4,0.5c-0.4,0.4-1,0.6-1.5,0.7v1.2
	c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5h14.2l0.3-0.3C438,334,438.4,333.8,438.8,333.7L438.8,333.7z M439.2,339.9h0.3l-0.1-4.5H439"
          />
          <path
            fill="none"
            d="M439.4,335.3l0.1,4.5h20.1l-1-4.5 M439.2,339.9l-0.3-4.5h-17.7v4.5 M441.7,340.6h-4.1l0.4,0.4
	c0.4,0.3,0.8,0.5,1.4,0.7h0.4c0.7,0,1.2-0.1,1.6-0.7L441.7,340.6L441.7,340.6z M438.7,325.4l0.3,4.8h18.6l-1.1-4.8 M438.7,330.2
	l-0.3-4.8h-17.2v4.8 M470.7,321.2h-0.3l1.5,3.6h0.3"
          />
          <path
            fill="none"
            d="M457.8,321.2l-0.4,0.4c-0.3,0.3-0.8,0.5-1.2,0.5l0.3,1.5c0.7,0,1.2,0.3,1.6,0.5l0.4,0.4H472l-1.5-3.6h-12.7
	L457.8,321.2L457.8,321.2z"
          />
          <path
            fill="none"
            d="M457.4,321.6l0.4-0.4h-4.1l0.4,0.4c0.4,0.4,1.1,0.7,1.6,0.7h0.4C456.6,322.1,457,322,457.4,321.6z"
          />
          <path
            fill="none"
            d="M459.6,331.5l0.4-0.4h-4.1l0.4,0.4c0.5,0.4,1.1,0.7,1.6,0.7h0.4C458.8,332,459.2,331.8,459.6,331.5L459.6,331.5
	z"
          />
          <path
            fill="none"
            d="M458.2,332h-0.3l0.3,1.2h0.3 M458.8,333.3h-0.6c-0.4,0-0.8,0.3-1.2,0.5c-0.3,0.3-0.4,0.4-0.5,0.7h4.5
	c-0.1-0.3-0.3-0.5-0.5-0.7C459.9,333.4,459.3,333.3,458.8,333.3L458.8,333.3z M459.6,339.9h0.4l-1.1-4.5h-0.3"
          />
          <path
            fill="none"
            d="M458.9,335.3l1.1,4.5h18.6l-1.9-4.5 M462.1,340.6h-4.3l0.4,0.5c0.5,0.4,1.1,0.7,1.6,0.7h0.5
	c0.6-0.1,1-0.3,1.2-0.6L462.1,340.6L462.1,340.6z M456.9,325.4l1.1,4.8h16.5l-2.2-4.8 M454.4,316.1h-16.2l0.3,4.5h17.1"
          />
          <path
            fill="none"
            d="M476.9,335.3l2.1,4.5h22.1v-0.1c-0.8-1.5-1.8-3-2.9-4.5L476.9,335.3L476.9,335.3z M479.3,340.6H479l1.5,3.6h0.4
	"
          />
          <path
            fill="none"
            d="M479.4,340.6h-0.1l1.5,3.6c1.8-0.1,2.8-0.5,2.6-1.2h19.3l-0.7-1.4h-18.8C482.6,341,481.3,340.7,479.4,340.6
	L479.4,340.6z M489.6,325.4h-16.8l2.1,4.8h19.4C492.8,328.6,491.2,327.1,489.6,325.4L489.6,325.4z M479,340.6h-16.8l-0.5,0.5
	c-0.3,0.3-0.8,0.5-1.2,0.5l0.3,1.4h0.1c0.7,0,1.2,0.1,1.7,0.7l0.4,0.5h17.5L479,340.6L479,340.6z M387.7,316.1h-0.3l-1,4.5h0.3
	 M373.9,316.1h-0.3l-2.1,4.5h0.4"
          />
          <path
            fill="none"
            d="M373.6,316.1h-1c-6.1,0-10.2,0.8-12.1,2.8l-2.3,1.8h13.5L373.6,316.1L373.6,316.1z M371.7,321.2h-0.4l-1.5,3.6
	h0.4 M369.8,325.4h-0.3l-2.2,4.8h0.4"
          />
          <path
            fill="none"
            d="M371.7,321.2l-1.5,3.6h13.5l0.4-0.4c0.4-0.4,1-0.5,1.6-0.5l0.4-1.5c-0.4,0-0.8-0.3-1.2-0.5l-0.4-0.4h-12.8
	L371.7,321.2L371.7,321.2z M367,330.9l-1.5,3.6h0.3l1.6-3.6 M367.4,330.9l-1.5,3.6h15.3c0.1-0.3,0.3-0.5,0.5-0.7
	c0.5-0.4,1.1-0.7,1.6-0.7h0.1l0.3-1.2c-0.5,0-1-0.3-1.4-0.7l-0.4-0.4L367.4,330.9L367.4,330.9z M363.2,340.6h-0.4l-1.5,3.6h0.4
	 M363.2,339.9h0.3l2.1-4.5h-0.4 M365.5,335.3l-2.1,4.5h18.7l1-4.5 M369.8,325.4l-2.1,4.8h16.5l1.1-4.8"
          />
          <path
            fill="none"
            d="M388.2,321.6l0.4-0.4h-4.1l0.4,0.4c0.3,0.3,0.8,0.5,1.2,0.5h0.4C387.1,322.3,387.7,322,388.2,321.6L388.2,321.6
	z"
          />
          <path
            fill="none"
            d="M388.6,321.2l-0.4,0.4c-0.5,0.4-1.1,0.7-1.6,0.7h-0.1l-0.3,1.5c0.5,0,1,0.3,1.4,0.5l0.4,0.4h13.5l0.4-0.5
	c0.4-0.4,1-0.7,1.6-0.7h0.1l0.1-1.2c-0.5,0-1.1-0.3-1.5-0.7l-0.5-0.5L388.6,321.2L388.6,321.2z"
          />
          <path
            fill="none"
            d="M387.8,324.7l-0.4-0.4c-0.4-0.4-0.8-0.5-1.4-0.5h-0.4c-0.7,0-1.2,0.3-1.6,0.5l-0.4,0.4H387.8L387.8,324.7z
	 M386.4,322.3H386l-0.4,1.5h0.4 M385.6,325.4h-0.3l-1.1,4.8h0.4 M385.9,331.5l0.4-0.4h-4.1l0.4,0.4c0.4,0.4,0.8,0.5,1.4,0.7h0.4
	C384.9,332,385.5,331.9,385.9,331.5L385.9,331.5z"
          />
          <path
            fill="none"
            d="M386.3,330.9l-0.4,0.4c-0.5,0.4-1.1,0.7-1.6,0.7l-0.3,1.2c0.4,0,0.8,0.3,1.2,0.5c0.3,0.3,0.4,0.4,0.5,0.7h15.1
	l0.3-0.3c0.4-0.4,1.1-0.7,1.6-0.7h0.1l0.1-1.6c-0.5,0-1.1-0.3-1.5-0.7l-0.3-0.4L386.3,330.9L386.3,330.9z M384.2,332h-0.4l-0.3,1.2
	h0.4"
          />
          <path
            fill="none"
            d="M380,340.6h-16.8l-1.5,3.6h17.5l0.4-0.5c0.4-0.4,1.1-0.7,1.6-0.7h0.1l0.3-1.4c-0.5-0.1-1-0.3-1.2-0.5L380,340.6
	L380,340.6z M387.7,316.1l-1,4.5h17.1l0.3-4.5 M421.2,316.1h-0.3v4.5h0.3"
          />
          <path
            fill="none"
            d="M404.3,316.1H404l-0.3,4.5h0.3 M403.6,322.4l-0.1,1.2h0.4v-1.2 M402.1,321.7c0.4,0.4,1,0.5,1.5,0.7h0.3
	c0.7,0,1.1-0.3,1.5-0.7l0.4-0.5h-4.4L402.1,321.7z M403.5,323.6h-0.1c-0.7,0-1.2,0.3-1.6,0.7l-0.4,0.5h4.3l-0.4-0.5
	c-0.4-0.3-0.8-0.5-1.2-0.5L403.5,323.6L403.5,323.6z"
          />
          <path
            fill="none"
            d="M406,321.2l-0.4,0.5c-0.4,0.4-1,0.5-1.5,0.7v1.2c0.4,0,0.8,0.3,1.2,0.5l0.4,0.5H419c0.1-0.3,0.3-0.4,0.5-0.5
	c0.4-0.3,0.8-0.5,1.4-0.7h0.1v-1.1h-0.1c-0.5-0.1-1-0.3-1.4-0.7c-0.3-0.1-0.4-0.3-0.5-0.5L406,321.2L406,321.2z M403.8,325.4h-0.4
	l-0.3,4.8h0.4 M404.9,331.3l0.3-0.4H401l0.3,0.4c0.4,0.4,1,0.5,1.5,0.7h0.4C404,331.9,404.4,331.8,404.9,331.3L404.9,331.3z"
          />
          <path
            fill="none"
            d="M405.3,330.9l-0.3,0.4c-0.4,0.4-1,0.5-1.5,0.7l-0.1,1.8c0.4,0,0.8,0.3,1.2,0.5l0.3,0.3H419
	c0.1-0.3,0.3-0.4,0.5-0.5c0.4-0.3,0.8-0.5,1.4-0.7h0.1v-1.2h-0.1c-0.5,0-1-0.3-1.4-0.7l-0.5-0.5L405.3,330.9L405.3,330.9z
	 M403.3,331.9h-0.3l-0.1,1.8h0.4"
          />
          <path
            fill="none"
            d="M418.9,340.6h-14.4L404,341c-0.4,0.3-0.8,0.5-1.4,0.7l-0.1,1.8c0.4,0,0.8,0.3,1.2,0.5l0.3,0.1h14.9
	c0.1-0.3,0.3-0.4,0.5-0.5c0.4-0.4,0.8-0.5,1.4-0.7h0.1v-1.2h-0.1c-0.5-0.1-1-0.3-1.4-0.7L418.9,340.6L418.9,340.6z"
          />
          <path
            fill="none"
            d="M421.2,341.8v1.2c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5H438l0.3-0.1c0.3-0.3,0.7-0.5,1.2-0.5l-0.1-1.8
	c-0.5,0-1-0.3-1.4-0.7l-0.4-0.4h-14.3l-0.4,0.5C422.3,341.5,421.9,341.7,421.2,341.8z M421.2,345h-0.3v4.8h0.3 M418.9,350.3l0.4,0.5
	c0.4,0.3,0.8,0.5,1.4,0.7h0.5c0.5,0,1.1-0.3,1.5-0.7l0.4-0.5L418.9,350.3L418.9,350.3z"
          />
          <path
            fill="none"
            d="M419.4,350.9l-0.4-0.5h-15l-0.4,0.5c-0.4,0.4-0.8,0.5-1.4,0.7l-0.1,1.6c0.4,0.1,0.7,0.3,1.1,0.5l0.3,0.3h15.4
	c0.1-0.3,0.3-0.4,0.5-0.5c0.4-0.3,0.8-0.5,1.4-0.7h0.1v-1.2h-0.1C420.3,351.4,419.7,351.2,419.4,350.9L419.4,350.9z M422.9,350.9
	c-0.4,0.4-1,0.5-1.5,0.7v1.2c0.5,0,1.1,0.3,1.5,0.7l0.4,0.5h15.4l0.3-0.3c0.3-0.3,0.7-0.4,1.1-0.5l-0.1-1.6c-0.6,0-1-0.3-1.4-0.7
	c-0.3-0.1-0.3-0.3-0.4-0.5h-14.9L422.9,350.9L422.9,350.9z M421.2,351.4h-0.3v1.2h0.3 M420.9,349.8V345h-18.3l-0.3,4.8 M421.2,345
	v4.8h18.6l-0.3-4.8"
          />
          <path
            fill="none"
            d="M421.2,352.7h-0.4c-0.5,0-1,0.3-1.4,0.7c-0.3,0.1-0.4,0.4-0.5,0.5h4.4l-0.4-0.5
	C422.3,352.9,421.9,352.7,421.2,352.7z M421.2,354.6h-0.3v5h0.3 M421.2,354.6v5l19.1-0.1l-0.3-4.8 M420.9,359.5v-5h-18.8l-0.4,4.8
	 M402.4,343.3h-0.3c-0.7,0-1.2,0.1-1.7,0.7l-0.3,0.1h3.9l-0.3-0.1c-0.3-0.3-0.7-0.5-1.2-0.5L402.4,343.3L402.4,343.3z"
          />
          <path
            fill="none"
            d="M402.6,343.5l0.1-1.8h-0.4v1.7 M402.6,345h-0.4l-0.3,4.8h0.4 M399.6,350.3l0.5,0.5c0.4,0.4,1.1,0.7,1.6,0.7h0.4
	c0.5,0,1-0.3,1.4-0.7l0.4-0.5L399.6,350.3L399.6,350.3z"
          />
          <path
            fill="none"
            d="M400.2,350.9l-0.5-0.5h-17.7l-0.4,0.4c-0.4,0.4-1,0.5-1.5,0.7l-0.4,1.6c0.4,0,0.8,0.3,1.1,0.5l0.3,0.4h18.3
	l0.3-0.3c0.4-0.4,1.1-0.7,1.6-0.7h0.3l0.1-1.5C401.1,351.4,400.6,351.3,400.2,350.9L400.2,350.9z M402.2,351.4h-0.4v1.5l0.3,0.1
	 M402,349.8l0.3-4.8h-20.8l-1.1,4.8 M402.1,354.6h-0.4l-0.3,4.8h0.3"
          />
          <path
            fill="none"
            d="M401.8,352.9h-0.4c-0.7,0-1.2,0.3-1.6,0.7l-0.3,0.3h4l-0.3-0.3c-0.3-0.3-0.7-0.4-1.1-0.6L401.8,352.9
	L401.8,352.9z M401.4,359.4l0.3-4.8h-22.4l-1,4.7 M383.4,344.1l-0.4-0.5c-0.3-0.3-0.7-0.5-1.2-0.5h-0.4c-0.7,0-1.2,0.3-1.6,0.7
	l-0.4,0.5L383.4,344.1L383.4,344.1z"
          />
          <path
            fill="none"
            d="M381.7,343.2l0.4-1.4h-0.4l-0.3,1.2 M381.5,345h-0.4l-1.1,4.8h0.4 M380,351.4c0.5,0,1.1-0.3,1.5-0.7l0.4-0.4
	h-4.1l0.4,0.4c0.4,0.4,1,0.5,1.5,0.7L380,351.4L380,351.4z M380,351.4h-0.3l-0.4,1.5h0.4 M380,349.8l1.1-4.8h-19.8l-2.1,4.8
	 M379.3,354.6H379l-1.1,4.7h0.4"
          />
          <path
            fill="none"
            d="M379.7,352.9h-0.6c-0.7,0-1.2,0.3-1.6,0.7l-0.4,0.4h4.1l-0.3-0.4C380.4,353.2,380.1,353.1,379.7,352.9
	L379.7,352.9z M503.1,349.8c0.5-1.4,0.7-3,0.3-4.8h-22.1l2.1,4.8H503.1L503.1,349.8z M500.7,352.7l1.4-1.4h-13.3
	c-0.7-0.7-2.1-1-3.9-1.1h-1.4l1.5,3.6h0.7c2.2,0,3.3-0.5,3.2-1.2h11.8L500.7,352.7L500.7,352.7z"
          />
          <path
            fill="none"
            d="M481.2,345h-0.4l2.2,4.8h0.3 M483.5,350.3h-0.3l1.5,3.6h0.4"
          />
          <path
            fill="none"
            d="M483,349.8l-2.2-4.8h-19.7l1,4.8 M486.8,358.6h0.4l-1.8-4h-0.4"
          />
          <path
            fill="none"
            d="M483.1,359.1c1.2,0,2.5-0.3,3.9-0.5l-1.8-4h-21.9l1,4.7L483.1,359.1z M485.5,354.6l1.8,4c3-0.7,6.5-2.1,10.2-4
	H485.5L485.5,354.6z M484.8,353.9l-1.5-3.6h-18.8l-0.4,0.4c-0.4,0.4-1,0.5-1.5,0.7l0.4,1.5h0.3c0.7,0,1.2,0.3,1.6,0.7l0.4,0.4h19.5
	L484.8,353.9L484.8,353.9z M462.5,351.4h-0.4l0.4,1.5h0.3"
          />
          <path
            fill="none"
            d="M462.1,351.4h0.4c0.5,0,1.1-0.3,1.5-0.7l0.4-0.4h-4.1l0.4,0.4C461,351.1,461.5,351.3,462.1,351.4L462.1,351.4z
	 M463,344.1l-0.4-0.5c-0.4-0.4-1.1-0.7-1.6-0.7h-0.4c-0.4,0-0.8,0.3-1.2,0.6l-0.4,0.6L463,344.1L463,344.1z"
          />
          <path
            fill="none"
            d="M460.7,343l-0.3-1.2H460l0.3,1.4 M461.1,345h-0.4l1.1,4.8h0.3 M460.6,350.7l-0.4-0.4h-17.7l-0.5,0.5
	c-0.4,0.4-1.1,0.7-1.6,0.7l0.1,1.5h0.4c0.7,0,1.2,0.3,1.6,0.7l0.3,0.3H461l0.3-0.4c0.3-0.3,0.7-0.4,1.1-0.6l-0.4-1.6
	C461.5,351.3,461,351.2,460.6,350.7L460.6,350.7z M461.8,349.8l-1.1-4.8h-20.8l0.3,4.8"
          />
          <path
            fill="none"
            d="M461.4,353.5l-0.3,0.4h4.1l-0.4-0.4c-0.4-0.4-1.1-0.7-1.6-0.7h-0.5C462.1,353.1,461.7,353.2,461.4,353.5
	L461.4,353.5z M463.9,359.3h0.3l-1-4.7h-0.4"
          />
          <path
            fill="none"
            d="M463.9,359.3l-1.1-4.7h-22.3l0.3,4.8 M439.4,341.7v1.8l0.4-0.1l-0.1-1.7"
          />
          <path
            fill="none"
            d="M439.9,343.3h-0.6c-0.4,0-0.8,0.3-1.2,0.5l-0.3,0.1h3.9l-0.3-0.1C441.2,343.6,440.6,343.3,439.9,343.3
	L439.9,343.3z M439.9,345h-0.4l0.3,4.8h0.4 M438.1,350.3c0.1,0.3,0.3,0.4,0.4,0.5c0.4,0.3,0.8,0.5,1.4,0.7h0.4
	c0.7,0,1.2-0.3,1.6-0.7l0.6-0.5L438.1,350.3L438.1,350.3z"
          />
          <path fill="none" d="M440.2,351.4h-0.3l0.1,1.6l0.3-0.1" />
          <path
            fill="none"
            d="M440.3,352.9h-0.3c-0.4,0.1-0.7,0.3-1.1,0.6l-0.3,0.3h4l-0.3-0.3c-0.4-0.4-1.1-0.7-1.6-0.7L440.3,352.9
	L440.3,352.9z M440.5,354.6h-0.4l0.3,4.8h0.4"
          />
          <path
            fill="none"
            d="M342.9,355l-0.8-0.4c-1-0.5-1.9-1.2-2.6-1.9c-0.4-0.4-0.7-0.8-1-1.4c-0.3-0.5-0.5-1.1-0.7-1.6h-0.5l0.3,1.6
	c0.3,1.1,0.5,2.1,1,3.3l0.4,1.1c1.5,3.7,3.9,7.8,7,12.5h97.8c14.7-0.1,32.2-0.1,52.3,0c3.6-5.1,6.1-9.6,7.4-13.6l0.8-2.9l0.4-2.1
	h-0.4c-0.3,0.5-0.4,1.1-0.8,1.6l-1,1.4c-0.7,0.7-1.5,1.4-2.6,1.9l-0.8,0.4c-4.3,2.3-8.1,3.9-11.6,4.7h-0.4c-1.5,0.4-2.9,0.6-4.3,0.6
	l-69.5,0.4l-54.5-0.4c-1.4,0-2.8-0.3-4.3-0.5h-0.3C351.1,358.9,347.2,357.3,342.9,355L342.9,355z M337.9,343.3v0.3l-0.3,0.7"
          />
          <path
            fill="none"
            d="M337.6,345l0.1-0.5L337.6,345c-0.4,1.5-0.5,3-0.3,4.8h0.5C337.3,348.3,337.2,346.8,337.6,345L337.6,345z
	 M325.3,373.6v-1.5h-0.7c-4.5-0.1-12.9,1-25.2,3.3c-2.1,0.4-3.2,0.8-3.4,1.1c-1.8-0.3-3-0.8-3.9-1.5c-0.4-0.4-0.7-0.8-0.7-1.4
	l-1.2-7.2l-0.7-9.4v45.3c0.3,2.9,3.2,4.7,8.5,5.4l7.2,0.6h4.1l7.4-0.5c5.4-0.7,8.3-2.5,8.5-5.4L325.3,373.6L325.3,373.6z
	 M325.3,372.1v1.5h21l-1.6-2.2"
          />
          <path
            fill="none"
            d="M324.5,372.1h0.7l19.4-0.7l64.8-0.1h23.1l64.8,0.1l19.4,0.7h0.7c4.5-0.1,12.9,1,25.2,3.3
	c2.1,0.4,3.2,0.8,3.4,1.1c1.8-0.3,3-0.8,3.8-1.5c0.3-0.8-1-1.4-3.8-1.6c-4-0.1-8-0.5-11.7-1.2c-5.4-1.4-9.8-1.9-13.1-1.8h-26.8
	l-8.5-0.3c-14.6-0.3-28.6-0.4-42.2-0.4h-45.5c-13.6,0-27.8,0-42.2,0.4l-8.5,0.3h-26.8c-3.3-0.1-7.7,0.5-13.1,1.8
	c-3.7,0.8-7.6,1.2-11.7,1.2c-2.9,0.3-4.3,0.8-3.9,1.6c0.8,0.7,2.1,1.2,3.9,1.5c0.3-0.4,1.4-0.7,3.4-1.1
	C311.7,373.2,320.1,372.1,324.5,372.1L324.5,372.1z"
          />
          <path
            fill="none"
            d="M344.7,371.4l1.6,2.2h149.4l1.6-2.2l-64.8-0.1h-23.1 M347.5,370.3l8.5-0.3c14.6-0.3,28.6-0.4,42.2-0.4h45.5
	c13.6,0,27.8,0,42.2,0.4l8.5,0.3l1.5-2.1h-97.8c-14.7-0.1-32.2-0.1-52.3,0L347.5,370.3L347.5,370.3z"
          />
          <path
            fill="none"
            d="M347.5,370.3l-1.5-2.1h-20.6c-13.5-0.1-25-0.8-35.1-1.8l1.1,7.3c0.1,0.5,0.3,1,0.7,1.4c-0.4-0.8,1-1.4,3.9-1.7
	c4-0.1,8-0.5,11.7-1.2c5.4-1.4,9.8-1.9,13.1-1.8L347.5,370.3L347.5,370.3z M550.8,279.5c0-1.1-0.7-1.6-2.1-1.9l-1.4-0.1
	c1,2.5,1.9,4.1,2.9,5c0.1,0,0.4-0.1,0.4-0.4C551.1,281.5,551.1,280.7,550.8,279.5L550.8,279.5z"
          />
          <path
            fill="none"
            d="M551.1,282.8l-0.3-0.8c-0.1,0.3-0.3,0.4-0.5,0.4L551.1,282.8L551.1,282.8z"
          />
          <path
            fill="none"
            d="M556.2,291.9c-2.2-2.5-3.9-5.5-5.1-9.1l-0.7-0.4c-1.1-0.8-2.1-2.5-2.9-5c-0.7-4.1-2.9-8.5-6.6-13.3
	c-1.1-1.5-2.5-2.3-4.1-2.6c-0.7,0-1.4-0.3-2.5-0.7l-10.6-1.9c5,3.9,6.9,7.3,5.8,10.2c-1.5,4.3-2.5,9.9-2.8,16.8l14.9-2.3
	c2.3-0.8,3.6,2.2,4,9.4c0.1,4.1-0.1,7.3-0.8,9.8l11,7C556,302.9,556.2,297,556.2,291.9L556.2,291.9z"
          />
          <path
            fill="none"
            d="M519.3,293.2l0.7-0.7l-3.4,0.7c-2.6,2.3-3.4,5.5-2.6,9.4l-1.5-0.7l-10,0.7l-17.9,1.2c-1.6,0.1-2.3-0.1-1.9-0.8
	c1.4-2.1,3.2-3.9,5.4-5.6l14.4-1.8l12.4-2.1l1.7-0.3l3.4-0.7l7-1.6l2.2-0.5l-0.4-2.9c6.1-1.1,10.3-2.3,12.8-4.1l-14.9,2.3
	c-2.9,2.6-6.6,4.5-11.3,5.9c-9.4,2.5-18.7,4.1-27.8,5l-0.5,0.5l-9.6,8.1l1.1,0.4l32.5-2.9l-7.7,8.7c3.4-0.4,7.1-1,11.4-1.8
	c3-0.6,6.2-1.4,9.8-2.2c-2.1-0.1-3.7-1-5.2-2.5c-1.7-1.6-2.5-3.6-2.5-5.9C516.8,296.8,517.6,294.9,519.3,293.2L519.3,293.2z"
          />
          <path
            fill="none"
            d="M520.1,292.5l-0.7,0.7c-1.7,1.6-2.5,3.7-2.5,5.9c0,2.3,0.8,4.3,2.5,5.9c1.5,1.5,3.2,2.3,5.2,2.5l5.5-1.5
	l1.2-1.1c1.7-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9c-1.2-1.2-2.6-1.9-4.3-2.3L520.1,292.5L520.1,292.5z M520,293.8
	c1.5-1.5,3.3-2.2,5.4-2.2c2.1,0,3.9,0.7,5.4,2.2c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.8,3.9-2.2,5.4c-1.5,1.5-3.3,2.2-5.4,2.2
	c-2.1,0-3.9-0.7-5.4-2.2c-1.5-1.5-2.2-3.3-2.2-5.4C517.8,297.1,518.5,295.3,520,293.8L520,293.8z"
          />
          <path
            fill="none"
            d="M525.4,291.6c-2.1,0-3.9,0.7-5.4,2.2c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4c1.5,1.5,3.3,2.2,5.4,2.2
	c2.1,0,3.9-0.7,5.4-2.2c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.8-3.9-2.2-5.4C529.2,292.3,527.4,291.6,525.4,291.6z M520.4,294.3
	c1.4-1.4,3-1.9,4.8-1.9c1.9,0,3.4,0.7,4.8,1.9c1.4,1.4,2.1,2.9,2.1,4.8c0,1.9-0.7,3.4-2.1,4.8s-3,2.1-4.8,2.1
	c-1.9,0-3.4-0.7-4.8-2.1c-1.4-1.4-2.1-2.9-2.1-4.8C518.5,297.2,519.2,295.7,520.4,294.3L520.4,294.3z"
          />
          <path
            fill="none"
            d="M525.4,292.4c-1.9,0-3.4,0.7-4.8,1.9c-1.4,1.4-2.1,2.9-2.1,4.8s0.7,3.4,2.1,4.8c1.4,1.4,3,2.1,4.8,2.1
	c1.9,0,3.4-0.7,4.8-2.1c1.4-1.4,2.1-2.9,2.1-4.8c0-1.9-0.7-3.6-2.1-4.8C528.8,293,527.1,292.4,525.4,292.4L525.4,292.4z
	 M516.7,293.2l-1.7,0.3c-2.2,2.2-3,5-2.3,8.4l1.5,0.7C513.2,298.7,514.1,295.6,516.7,293.2L516.7,293.2z M541.6,283.5
	c0.3,0,0.7,0.1,1,0.3c0.7,0.4,1,1.4,1,2.6c0,2.3,0,4.4-0.1,6.3c-0.3,3.7-0.7,6.6-1.4,8.9l-0.3,0.8h3c0.7-2.5,1-5.6,0.8-9.8
	C545.3,285.7,543.9,282.6,541.6,283.5L541.6,283.5z"
          />
          <path
            fill="none"
            d="M542.5,283.7c-0.3-0.1-0.5-0.3-1-0.3c-2.5,1.7-6.7,3-12.8,4.1l0.4,2.9l2.6-0.7l5.5-1.6c0.8-0.4,1.5-0.7,2.1-1.1
	C541.2,285.8,542.1,284.7,542.5,283.7L542.5,283.7z"
          />
          <path
            fill="none"
            d="M527,290.9c1.7,0.3,3,1.1,4.3,2.3c1.7,1.6,2.5,3.7,2.5,5.9c0,2.3-0.8,4.3-2.5,5.9l-1.2,1.1h0.1
	c4.3-1.9,6.1-4.5,5.8-8.1l-1.4-4.3c-0.8-1.8-1.7-3.2-2.5-4.1l-2.6,0.7L527,290.9L527,290.9z"
          />
          <path
            fill="none"
            d="M532,289.7c1,1,1.8,2.3,2.5,4.1l1.4,4.3l4-2.9c0.4-3.4,0.3-6.2-0.4-8.4c-0.7,0.4-1.4,0.8-2.1,1.1L532,289.7
	L532,289.7z"
          />
          <path
            fill="none"
            d="M543.4,292.7l-2.2,1.6l-1.5,8.9c-0.5,1.4-2.1,2.5-4.5,3.2c-6.1,1.5-12.8,3-20.2,4.4l-12.1,2.1
	c-0.3,0.8-0.1,1,0.7,0.7l11.4-1.8c7.7-1.4,15-2.9,21.9-4.5c2.6-0.5,4.3-2.1,5-4.5l0.3-0.8C542.7,299.3,543.2,296.4,543.4,292.7
	L543.4,292.7z"
          />
          <path
            fill="none"
            d="M541.2,294.2l2.2-1.6c0.1-1.9,0.1-4,0.1-6.3c0-1.4-0.4-2.2-1-2.6c-0.4,1.1-1.4,2.1-3,3c0.7,2.2,0.8,5,0.4,8.4
	L541.2,294.2L541.2,294.2z"
          />
          <path
            fill="none"
            d="M535.8,298.1c0.4,3.6-1.5,6.2-5.8,8.1h-0.1l-5.5,1.5c-3.4,0.8-6.7,1.5-9.8,2.2c-4.3,0.8-8.1,1.4-11.4,1.8
	c-0.3,0.5-0.5,1-0.7,1.4l12.1-2.1c7.3-1.4,14-2.9,20.2-4.4c2.6-0.7,4.1-1.8,4.5-3.2l1.5-8.9l-1.4,1L535.8,298.1L535.8,298.1z
	 M512.7,301.9c-0.7-3.4,0.1-6.2,2.3-8.4l-12.4,2.1v7L512.7,301.9L512.7,301.9z M508.8,298.6c0,0.3-0.1,0.5-0.3,0.8
	c-0.1,0.3-0.5,0.3-0.8,0.3s-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.6-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3
	C508.7,298.1,508.8,298.3,508.8,298.6L508.8,298.6z"
          />
          <path
            fill="none"
            d="M508.6,299.4c0.1-0.3,0.3-0.6,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3C508,299.8,508.3,299.7,508.6,299.4L508.6,299.4z M551.9,365.5
	l-0.1,1 M546.1,373.3c2.9,0.3,4.3,0.8,3.9,1.6c0.4-0.4,0.7-0.8,0.7-1.4l1.1-7.3c-9.9,1.1-21.6,1.6-34.9,1.8h-20.6l-1.5,2.1h26.7
	c3.3-0.1,7.7,0.5,13.1,1.8C538.1,372.9,542,373.3,546.1,373.3L546.1,373.3z"
          />
          <path
            fill="none"
            d="M550.7,373.7c-0.1,0.5-0.3,1-0.7,1.4c-0.7,0.7-2.1,1.2-3.9,1.5c-0.3-0.4-1.4-0.7-3.4-1.1
	c-12.2-2.3-20.6-3.3-25.2-3.3h-0.7v30.3c0.3,2.9,3.2,4.7,8.5,5.4l7.4,0.5h4.1l7.2-0.5c5.4-0.7,8.3-2.5,8.5-5.4v-45.3l-0.7,8.4
	l-0.1,1L550.7,373.7L550.7,373.7z"
          />
          <path
            fill="none"
            d="M516.8,373.6v-1.5l-19.4-0.7l-1.6,2.2 M465.7,302.9c1.9-1.1,4.7-2.8,8.1-5l-15.4,0.3c-5.8,0-11.7-0.1-17.7-0.3
	h-13.5c0.5,0.1,1.1,0.4,1.5,0.7l9.6,0.1h19l12.1-0.1c1.5-0.1,1.9,0,1.2,0.7c-1.5,1.1-3.2,2.2-5,3.2l-1.2,0.7
	c-1.6,0.8-3.7,1.2-6.5,1.4h-1.4l-17.3,0.3h-3.7c-0.8,0-1.4-0.5-1.8-1.2c-1.1-1.9-2.5-3.4-4.1-4.4l-0.7-0.4c-0.4-0.3-1-0.4-1.5-0.7
	h-1.6l0.3,0.1c3.3,0.8,5.8,2.8,7.3,5.6c0.6,1.1,1.2,1.6,2.3,1.6l16-0.1l6.6-0.3c2.9-0.1,5.1-0.5,6.7-1.5L465.7,302.9L465.7,302.9z"
          />
          <path
            fill="none"
            d="M464.1,303l1.2-0.7c1.8-1,3.4-2.1,5-3.2c0.8-0.5,0.4-0.8-1.2-0.7l-12.1,0.1v5.8h0.5
	C460.3,304.2,462.5,303.8,464.1,303L464.1,303z M456.2,304.4h0.8v-5.8h-0.8 M456.2,304.4v-5.8h-17.3v6.1 M438.8,304.7v-6.1H438v6.1
	 M438,304.7v-6.1l-9.6-0.1l0.7,0.4c1.8,1.1,3.2,2.5,4.1,4.4c0.4,0.8,1,1.2,1.8,1.2h3L438,304.7L438,304.7z M502.7,302.7v-7
	l-14.4,1.8c-2.3,1.8-4.1,3.6-5.4,5.6c-0.4,0.7,0.3,1,1.9,0.8L502.7,302.7z M494,300.8c-0.3,0.3-0.5,0.3-0.8,0.3s-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.3-0.1,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3c0.1,0.3,0.3,0.5,0.3,0.8S494.3,300.5,494,300.8
	L494,300.8z"
          />
          <path
            fill="none"
            d="M493.2,301.1c0.3,0,0.5-0.1,0.8-0.3c0.1-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.1-0.5-0.3-0.8-0.3
	s-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8C492.6,300.9,492.9,301.1,493.2,301.1L493.2,301.1z M384.5,304.9
	l6.6,0.3l16,0.1c1,0,1.8-0.5,2.3-1.6c1.5-2.9,4-4.8,7.3-5.6l0.3-0.1h-1.6c-0.5,0.1-1.1,0.4-1.5,0.7l-0.7,0.4
	c-1.8,1.1-3.2,2.5-4.1,4.4c-0.4,0.8-1,1.2-1.8,1.2h-3.7l-17.3-0.3h-1.4c-2.8-0.1-5-0.5-6.5-1.4l-1.2-0.7c-1.8-1-3.4-2.1-5-3.2
	c-0.8-0.5-0.4-0.8,1.2-0.7l12.1,0.1h19l9.6-0.1c0.5-0.3,1-0.4,1.5-0.7h-13.9c-6.1,0.1-12,0.3-17.7,0.3l-15.4-0.3
	c3.4,2.2,6.1,3.9,8,5l1.2,0.7C379.4,304.4,381.6,304.9,384.5,304.9L384.5,304.9z"
          />
          <path fill="none" d="M385.9,298.6H385v5.8h0.8" />
          <path
            fill="none"
            d="M385.9,298.6v5.8l17.3,0.3v-6.1 M384.5,304.4h0.5v-5.8l-12.1-0.1c-1.5-0.1-1.9,0-1.2,0.7c1.5,1.1,3.2,2.2,5,3.2
	l1.2,0.7C379.5,303.8,381.7,304.2,384.5,304.4L384.5,304.4z M413,298.9l0.7-0.4l-9.6,0.1v6.1h2.9c0.8,0,1.4-0.5,1.8-1.2
	C409.8,301.4,411.2,300,413,298.9z M403.2,298.6v6.1h0.8v-6.1 M504.3,343.6l-0.1-0.3l0.3,1 M504.6,345l-0.1-0.5L504.6,345
	c0.3,1.8,0.1,3.4-0.3,4.8h0.4C505.1,348,505,346.3,504.6,345z M531.3,244.7l-6.2-7.2l9.2,23.4c1,0.4,1.8,0.7,2.5,0.7
	c0.5,0,1,0,1.1-0.3c0.5-1.1,1.8-1.9,3.9-2.3l17.5-1c-0.3-0.7-1.5-1-3.8-0.8h-0.6c-4.5,0.3-8.8,0.3-12.7,0c-1.2-0.4-2.2-2.1-3.2-5
	l-0.7,0.1c-1.8,0.3-3.2-0.1-3.9-1.2L531.3,244.7L531.3,244.7z"
          />
          <path
            fill="none"
            d="M571.9,256.5c2.8-0.4,4.5-1.4,5.2-2.9l-28.1,2.7c-4.4,0.7-6.7-1.1-7.2-5.1c-0.7,0.6-1.7,0.8-3,0.8
	c1,2.9,2.1,4.5,3.2,5c3.9,0.4,8,0.4,12.7,0h0.5c2.3-0.3,3.7,0,3.9,0.8L571.9,256.5L571.9,256.5z M547.5,277.6l1.4,0.1
	c-1.5-3-2.6-6.7-3.3-11.1c-1.2-4.5-2.5-7-3.9-7.6c-1.9,0.4-3.2,1.2-3.9,2.3c-0.3,0.3-0.7,0.3-1.1,0.3c1.7,0.3,3,1.1,4.1,2.6
	C544.5,268.9,546.8,273.4,547.5,277.6L547.5,277.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M423,306.4l-0.5,1.1l-1.2,1.6l-0.1,0.1l-0.1,0.1l2.8-0.3
	c0.8-0.1,1.6-0.4,2.3-0.8c1.5-0.8,2.5-1.8,2.8-3.2h-5.5L423,306.4L423,306.4z M424.5,303.7v0.8h-2.3v-0.4c0-0.1,0-0.3,0.1-0.4
	L424.5,303.7L424.5,303.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M419.4,305.2h-6.1c0.4,1.2,1.4,2.2,2.8,3c1.4,0.8,3,1.2,4.8,1.2
	c0.1-0.7,0.1-1.2,0.1-1.9c-0.1-0.7-0.3-1.2-0.5-1.6l-0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3L419.4,305.2L419.4,305.2z M417.1,303.6v1
	c0,0.1,0.1,0.3,0.4,0.3h3v-0.3h-2.9v-0.4v-0.4h2.9v-0.3h-3C417.2,303.4,417.1,303.4,417.1,303.6L417.1,303.6z M416.7,303.4h-3
	c-0.1,0-0.3,0.1-0.4,0.3v0.5h2.9c0,0.1,0,0.3-0.1,0.3h-2.8l0.1,0.3h2.8l0.3-0.1l0.1-0.3V304h-2.9v-0.3h2.9L416.7,303.4L416.7,303.4z
	 M420.9,299c-1.8,0-3.4,0.4-4.8,1.1c-0.7,0.3-1.1,0.7-1.6,1.2c-0.4,0.5-0.8,1.1-1.1,1.6h5.2c0.1-0.8,0.4-1.5,0.8-2.2
	C420,300.3,420.4,299.7,420.9,299L420.9,299z M426,300c-0.7-0.4-1.4-0.5-2.2-0.8l-2.8-0.3c-0.1,0.5-0.1,1.1-0.1,1.8s0.1,1.2,0.3,1.5
	l0.3,0.4l0.3,0.3h6.9C428.4,301.8,427.5,300.8,426,300L426,300z M424.9,303.4h-3l-0.1,0.1l-0.1,0.5l0.1,0.4l0.3,0.3h2.9l0.1-0.3
	v-0.8L424.9,303.4z M428.5,303.4h-3v1.4h0.5v-1.1h2.5v1.1c0.3,0,0.4,0,0.4-0.1v-1.1C428.8,303.4,428.6,303.4,428.5,303.4
	L428.5,303.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M427.1,299.7c-1.9-1-4.1-1.4-6.6-1.2l-1.8,0.1c-1.6,0.3-3,0.8-4.3,1.5
	c-0.4,0.3-0.8,0.5-1.1,0.8c-0.4,0.3-0.7,0.7-1,1c-0.3,0.4-0.5,0.8-0.7,1.4c-0.1,0.5-0.1,1.1-0.1,1.6c0.1,0.4,0.3,1,0.5,1.4
	c0.3,0.4,0.5,0.7,0.8,1.1c0.5,0.7,1.4,1.1,2.2,1.6c1,0.4,1.8,0.8,2.9,1l3.6,0.3c1.2,0,2.3-0.3,3.3-0.5s1.8-0.7,2.8-1.2l1.1-0.8l1-1
	l0.7-1.2c0.3-0.5,0.3-1.1,0.1-1.7c0-0.4-0.3-1-0.4-1.4c-0.3-0.4-0.5-0.7-0.8-1.1l-1.1-0.8C428,300.1,427.5,299.8,427.1,299.7
	L427.1,299.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.8,303.4v1.4h0.5v-1.4H420.8z M301,294.2l-2.3-1.5 M302.6,286.8
	c-1.6-1.1-2.6-2.1-3-3 M310.2,289.7l-5.5-1.6c-0.8-0.4-1.5-0.7-2.1-1.1 M312.8,290.4l-2.6-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306.2,298.1c0.3-1.2,0.7-2.3,1.1-3.4l0.4-1c0.8-1.8,1.7-3.2,2.5-4.1
	 M321.6,294.3c1.4,1.4,2.1,2.9,2.1,4.8c0,1.9-0.7,3.4-2.1,4.8c-1.4,1.4-3,2.1-4.8,2.1c-1.9,0-3.4-0.7-4.8-2.1
	c-1.4-1.4-2.1-2.9-2.1-4.8c0-1.9,0.7-3.6,2.1-4.8c1.4-1.4,3-1.9,4.8-1.9C318.7,292.4,320.2,293,321.6,294.3L321.6,294.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M322.2,293.8c1.5,1.5,2.2,3.3,2.2,5.4c0,2.1-0.7,3.9-2.2,5.4
	c-1.5,1.5-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2c-1.5-1.5-2.2-3.3-2.2-5.4c0-2.1,0.8-3.9,2.2-5.4c1.5-1.5,3.3-2.2,5.4-2.2
	S320.7,292.3,322.2,293.8L322.2,293.8z M322,292.5l-7-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315,290.9c-1.6,0.3-3,1.1-4.3,2.3c-1.6,1.6-2.5,3.7-2.5,5.9
	c0,2.3,0.8,4.3,2.5,5.9c0.4,0.4,0.8,0.7,1.2,1.1 M315,290.9l-2.2-0.5 M312.8,290.4l0.4-2.9c-6.1-1.1-10.3-2.3-12.8-4.1 M312.1,306.2
	c-4.3-1.9-6.2-4.5-5.8-8.1 M312.1,306.2l5.4,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M301,294.2l1.5,8.9c0.5,1.4,2.1,2.5,4.5,3.2c6.1,1.5,12.8,3,20.2,4.4
	l12.1,2.1 M302.2,295.2l4,2.9 M339.4,295.7l14.4,1.8c2.3,1.8,4.1,3.6,5.4,5.6c0.4,0.7-0.3,1-1.9,0.8l-17.9-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M520.4,294.3c-1.4,1.4-2.1,2.9-2.1,4.8c0,1.9,0.7,3.4,2.1,4.8
	c1.4,1.4,3,2.1,4.8,2.1c1.9,0,3.4-0.7,4.8-2.1s2.1-2.9,2.1-4.8c0-1.9-0.7-3.6-2.1-4.8c-1.4-1.4-3-1.9-4.8-1.9S521.8,293,520.4,294.3
	z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M520,293.8c-1.5,1.5-2.2,3.3-2.2,5.4c0,2.1,0.7,3.9,2.2,5.4
	c1.5,1.5,3.3,2.2,5.4,2.2c2.1,0,3.9-0.7,5.4-2.2c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.8-3.9-2.2-5.4c-1.5-1.5-3.3-2.2-5.4-2.2
	C523.2,291.6,521.5,292.3,520,293.8L520,293.8z M529.2,290.4l-0.4-2.9c6.1-1.1,10.3-2.3,12.8-4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M530,306.2l1.2-1.1c1.7-1.6,2.5-3.6,2.5-5.9c0-2.3-0.8-4.3-2.5-5.9
	c-1.2-1.2-2.6-1.9-4.3-2.3 M532,289.7c1,1,1.8,2.3,2.5,4.1l1.4,4.3 M529.2,290.4l2.8-0.7 M527,290.9l2.2-0.5 M532,289.7l5.5-1.6
	c0.8-0.4,1.5-0.7,2.1-1.1 M541.2,294.2l2.2-1.5 M542.5,283.7c-0.4,1.1-1.4,2.1-3,3 M520.1,292.5l6.9-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M535.8,298.1c0.4,3.6-1.5,6.2-5.8,8.1h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M502.7,312.9l12.1-2.1c7.3-1.4,14-2.9,20.2-4.4c2.6-0.7,4.1-1.8,4.5-3.2
	l1.5-8.9 M539.9,295.2l-4.1,2.9 M508.8,298.6c0-0.3-0.1-0.5-0.3-0.8c-0.1-0.3-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.5-0.3,0.8s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3C508.7,299.3,508.8,299,508.8,298.6L508.8,298.6
	z M512.7,301.9l1.5,0.7c-1-3.9,0-7,2.6-9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M494.7,370.3l1.5-2.1 M495.8,373.6l1.6-2.2 M504.2,343.3l0.3,1
	 M496.2,368.2c3.6-5.1,6.1-9.6,7.4-13.6l0.8-2.9l0.4-2.1c0.3-1.8,0.1-3.3-0.3-4.8l-0.1-0.5 M504.4,344.3l-0.1-0.8l-0.1-0.1
	 M432.6,371.2h-23.1 M408.7,373.6h24.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M496.2,368.2h-97.8c-14.7-0.1-32.2-0.1-52.3,0 M416.8,297.8h-1.6
	 M415.2,297.8c-0.5,0.1-1.1,0.4-1.5,0.7 M425.2,297.8h-8.4 M427,297.8c0.5,0.1,1.1,0.4,1.5,0.7 M433.5,373.6h62.3 M427,297.8h-1.8
	 M415.2,297.8h-13.5c-6.1,0.1-12,0.3-17.7,0.3l-15.4-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M368.4,297.8c3.4,2.2,6.1,3.9,8,5l1.2,0.7c1.6,0.8,3.9,1.4,6.7,1.5
	l6.6,0.3l16,0.1c1,0,1.8-0.5,2.3-1.6c1.5-2.9,4-4.8,7.3-5.6l0.3-0.1 M470.3,310.2l16.8-12.9 M487.1,297.2l-13.5,0.5 M356.7,316.9
	c1.5-1.2,3-2.2,4.5-2.8c1.9-0.8,4-1.2,5.9-1.1H475c1.4,0,2.8,0.1,4.1,0.5l1.4,0.6c1.6,0.7,3.3,1.6,4.8,2.9 M487.9,296.7l-0.7,0.4
	 M487.2,297.1l-0.1,0.1 M354.2,296.7l14.7,0.7l14.9,0.3c5.8,0,11.7,0,17.7-0.3h38.7c6.1,0.1,12,0.3,17.7,0.3l14.9-0.3l14.7-0.7
	 M473.6,297.8l-15.4,0.3c-5.8,0-11.7-0.1-17.7-0.3H427"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M473.6,297.8c-3.4,2.2-6.1,3.9-8,5l-1.2,0.7c-1.6,0.8-3.9,1.4-6.7,1.5
	l-6.6,0.3l-16,0.1c-1,0-1.8-0.5-2.3-1.6c-1.5-2.9-4-4.8-7.3-5.6l-0.3-0.1 M535.3,341.4c0.4-0.7,0.8-1.2,1.4-1.8 M551.8,366.4
	c-9.9,1.1-21.6,1.6-34.9,1.8h-20.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M516.8,373.6v28.8c0.3,2.9,3.2,4.7,8.5,5.4l7.4,0.6h4.1l7.2-0.5
	c5.4-0.7,8.3-2.5,8.5-5.4v-45.3 M516.8,372.1v1.5 M544.1,352.3v-1.5 M545.3,352.1v-1.5 M497.4,371.4l-64.8-0.1 M511,363.5l3.9-0.1
	c9.4-0.3,17.6-1.1,24.9-2.3c3-0.5,4.8-2.1,5.2-4.5 M543.9,355.7v-1.4 M544.9,354.3h-1 M543.9,355.7l1.2-0.1 M544.1,352.3l1.2-0.1
	 M545.2,355.6l0.1-3.4 M545,356.5l0.1-0.8v-0.1 M543.9,354.3l-8.4,1c-0.8,0-1.2,0.4-1.1,1c0.1,0.4,0.7,0.5,1.7,0.4l7.7-1
	 M544.1,350.7l-9.2,0.8c-0.8,0-1.2,0.4-1.2,1c0.1,0.4,0.7,0.4,1.8,0.3l8.7-0.7 M544.2,346.6l-9.2,1c-1,0-1.4,0.4-1.5,1.4l10.7-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M545,356.5c-0.5,1.6-2.2,2.6-4.8,2.9c-4.1,0.3-6.5-1.6-7.3-5.6
	c-0.4-3.2-0.3-5.5,0.1-7l0.1-0.5c0.5-1.9,1.2-3.6,2.2-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M544.3,343.2l-8.5,1c-1,0-1.5,0.5-1.5,1.2l10-1 M545.4,346.5v-2.1
	 M545.4,348.1h-1.2 M544.2,348.1v-1.5 M545.3,350.6l0.1-2.5 M545.4,348.1v-1.6 M545.3,350.6l-1.2,0.1 M544.2,346.6l1.2-0.1
	 M545.4,344.4l0.1-1.4 M544.3,344.5l1.1-0.1 M545.6,343l-1.2,0.1 M544.3,344.5v-1.4 M552.5,357.1l-0.5,8.4 M550,375
	c-0.7,0.7-2.1,1.2-3.9,1.5c-0.3-0.4-1.4-0.7-3.4-1.1c-12.2-2.3-20.6-3.3-25.2-3.3h-0.7 M551.9,365.5l-0.1,1 M551.8,366.4l-1.1,7.3
	c-0.1,0.5-0.3,1-0.7,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M550,375c0.3-0.8-1-1.4-3.9-1.6c-4-0.1-8-0.5-11.7-1.2
	c-5.4-1.4-9.8-1.9-13.1-1.8h-26.8 M551.9,365.5l-0.1,1 M555.8,309.5l-11-7 M541.6,259l17.5-1.1 M552.5,357.1l1.8-24.5
	c0.7-8.5,1.1-16.2,1.4-23 M536.6,339.6l4.3-17.2l0.1-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M541.5,321.3l2.5-0.5c1-0.3,1.4,0.4,1.2,1.6l-0.4,15.5
	c0,1.5-1,2.3-2.8,2.6l-6.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M545.6,343l0.4-20.4c0.3-2.6-0.3-3.7-1.5-3c-1.4,0-2.3,0.6-3,1.6
	 M541.5,321.3L541,322"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M541,322l1.5-0.4c1,0,1.2,0.1,1.1,0.7c0.1,4.5,0,9.6-0.4,15
	c-0.1,1.5-0.8,2.3-2.1,2.2h-4.7 M541.7,302.5c-0.7,2.5-2.3,4-5,4.5c-6.9,1.8-14.3,3.3-21.9,4.5l-11.4,1.8c-0.8,0.3-1.1,0.1-0.7-0.7
	c0.1-0.3,0.4-0.8,0.7-1.4l7.7-8.7l-32.5,2.9l-1.1-0.4l9.6-8.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.7,316.5l26.8-4.7c7.2-1,12-2.5,14.3-4.5c1.4-0.8,2.3-2.5,2.9-4.8
	 M544.8,302.5h-3 M541.6,283.5l-14.7,2.3 M534.3,260.9l-10.6-2.1 M523.7,258.8c5,3.9,6.9,7.3,5.8,10.2c-1.5,4.3-2.5,9.9-2.8,16.8
	 M544.8,302.5c0.7-2.5,1-5.6,0.8-9.8c-0.3-7-1.7-10.2-4-9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M541.7,302.5l0.3-0.8c0.7-2.3,1.2-5.2,1.4-8.9c0.1-1.9,0.1-4,0.1-6.3
	c0-1.4-0.4-2.2-1-2.6c-0.3-0.1-0.5-0.3-1-0.3 M526.9,285.8c-2.9,2.6-6.6,4.5-11.3,5.9c-9.4,2.5-18.7,4.1-27.8,5 M523.7,258.8
	l-3.6-0.7 M536.6,261.5c-0.7,0-1.4-0.3-2.5-0.7 M548.7,277.6c-1.5-3-2.6-6.7-3.3-11.1c-1.2-4.5-2.5-7-3.9-7.6 M531.3,244.7l2.9,6.3
	c0.8,1.1,2.1,1.4,3.9,1.2 M495.8,373.6h21 M547.5,277.6c-0.7-4.1-2.9-8.5-6.6-13.3c-1.1-1.5-2.5-2.3-4.1-2.6 M541.6,259
	c-1.9,0.4-3.2,1.2-3.9,2.3c-0.3,0.3-0.7,0.3-1.1,0.3 M497.4,371.4l19.4,0.7 M484.4,288.4c11.1-13.5,23.1-23.5,35.8-30.3
	 M538.2,252.2l-19.8-35.4 M346.4,373.6h62.3 M534.3,260.9l-9.2-23.4 M368.4,297.8l-13.3-0.5 M541.7,251.3c-0.7,0.6-1.7,0.8-3,0.8
	 M538.7,252.1l-0.5,0.1 M577.1,253.6l-28.1,2.8c-4.4,0.7-6.7-1.1-7.2-5.1 M550.4,282.4c-1.1-0.8-2.1-2.5-2.9-5 M547.5,277.6h1.2
	 M551.1,282.8l-0.7-0.4 M550.4,282.4c0.3,0,0.4-0.1,0.5-0.4 M555.8,309.5c0.3-6.6,0.4-12.5,0.4-17.6c-2.2-2.5-3.9-5.5-5.1-9.1
	 M551.1,282.8l-0.1-0.8 M550.9,282c0.1-0.4,0.1-1.2,0-2.5c0-1.1-0.7-1.6-2.1-1.9 M344.7,371.4l64.8-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M538.7,252.1c1,2.9,2.1,4.5,3.2,5c3.9,0.4,8,0.4,12.7,0h0.5
	c2.3-0.3,3.7,0,3.9,0.8 M559.1,257.9l12.8-1.4c2.8-0.4,4.5-1.4,5.2-2.9 M371.8,310.2l-16.8-12.9 M317.1,237.5l-9.2,23.4 M505,209.6
	l18.7,49.2 M318.3,258.8l18.8-49.2 M577.1,253.6l0.3-0.6c0.3-1.2,0.3-2.3,0.4-3.2 M283.1,257.9l17.3,1.1 M305.5,339.6l1.4,1.8
	 M289.6,357.1v45.3c0.3,2.9,3.2,4.7,8.5,5.4l7.2,0.6h4.1l7.4-0.5c5.4-0.7,8.3-2.5,8.5-5.4v-28.8 M289.6,357.1l0.7,9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M292.2,375c-0.4-0.8,1-1.4,3.9-1.6c4-0.1,8-0.5,11.7-1.2
	c5.4-1.4,9.8-1.9,13.1-1.8h26.8 M290.3,366.4l1.1,7.3c0.1,0.5,0.3,1,0.7,1.4 M298.1,352.3v-1.5 M296.7,350.6l0.1,1.5 M298.2,355.7
	v-1.4 M298.1,352.3l-1.2-0.1 M296.9,355.6l1.4,0.1 M298.2,354.3l-1-0.1 M296.9,355.6v0.3c0,0.3,0.1,0.5,0.3,0.8 M296.9,352.1
	l0.1,3.4 M296.6,344.4v2.1 M296.7,350.6l1.2,0.1 M298,348.1l-1.2-0.1 M296.7,346.5v1.6 M296.7,348.1v2.5 M298,348.1l-0.1-1.5
	 M296.7,346.5l1.2,0.1 M296.6,343v1.4 M297.8,344.5v-1.4 M297.8,344.5l-1.2-0.1 M296.6,343l1.2,0.1 M298.1,350.7l9.2,0.8
	c0.8,0,1.2,0.4,1.2,1c-0.1,0.4-0.7,0.4-1.8,0.3l-8.7-0.7 M298.2,355.7l7.7,1c1,0.3,1.5,0.1,1.7-0.4c0.1-0.5-0.1-1-1.1-1l-8.4-1
	 M297.8,346.6l9.2,1c1,0,1.4,0.4,1.5,1.4l-10.7-0.8 M297.8,343.2l8.5,1c1,0,1.5,0.5,1.5,1.2l-10-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306.9,341.4c1,1.4,1.6,3,2.2,5l0.1,0.6c0.4,1.6,0.4,4,0.1,7
	c-0.8,4-3.2,5.9-7.3,5.6c-2.6-0.3-4.3-1.4-4.8-2.9 M346,368.2h-20.6c-13.5-0.1-25-0.8-34.9-1.8 M325.3,372.1l19.4-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M297.1,356.5c0.4,2.5,2.1,4,5.2,4.5c7.3,1.2,15.7,2.1,24.9,2.3l3.9,0.1
	 M325.3,373.6h21 M347.5,370.3l-1.5-2.1 M494.7,370.3l-8.5-0.3c-14.6-0.3-28.6-0.4-42.2-0.4h-45.5c-13.6,0-27.8,0-42.2,0.4l-8.5,0.3
	 M346.4,373.6l-1.6-2.2 M292.2,375c0.8,0.7,2.1,1.2,3.9,1.5c0.3-0.4,1.4-0.7,3.4-1.1c12.2-2.3,20.6-3.3,25.2-3.3h0.7 M325.3,372.1
	v1.5 M337.9,343.3l-0.3,1 M337.6,344.3v0.1 M337.6,344.4l-0.1,0.5c-0.4,1.5-0.4,3-0.3,4.8l0.3,1.6c0.3,1.1,0.5,2.1,1,3.3l0.4,1.1
	c1.5,3.7,3.9,7.8,7,12.5 M337.9,343.3v0.3l-0.3,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M337.6,344.4l-0.1,0.5c-0.3,1.8-0.3,3.4,0.3,4.8c0.1,0.5,0.4,1.1,0.7,1.6
	c0.3,0.4,0.7,1,1,1.4c0.7,0.7,1.5,1.4,2.6,1.9l0.8,0.4c4.3,2.3,8.1,3.9,11.6,4.7h0.3c1.5,0.4,2.9,0.5,4.3,0.5l54.5,0.4l69.5-0.4
	c1.4,0,2.8-0.3,4.3-0.5h0.3c3.4-0.8,7.3-2.3,11.6-4.7l0.8-0.4c1-0.5,1.9-1.2,2.6-1.9l1-1.4l0.8-1.6c0.4-1.4,0.5-3,0.3-4.8l-0.1-0.5
	 M264.4,249.9c0,1,0.1,1.9,0.4,3.2l0.1,0.6 M264.9,253.6c0.7,1.5,2.5,2.5,5.2,2.9l12.8,1.4 M357.7,288.4
	c-11.1-13.5-23.1-23.5-35.8-30.3 M315.3,285.8c2.9,2.6,6.6,4.5,11.3,5.9c9.4,2.5,18.7,4.1,27.8,5 M333.5,257.6l-11.6,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M504.2,343.3l-2.1-4.3c-0.7-1.4-1.5-2.6-2.5-3.9l-3.9-5
	c-1.4-1.6-2.9-3.2-4.5-4.8c-1.6-1.6-3.4-3.3-5.5-5l-3.4-2.8c-2.2-2.1-6.5-3-13.1-3h-96.8c-6.5,0-10.9,1-13.1,3
	c-1.2,1-2.3,1.8-3.4,2.8c-1.9,1.6-3.7,3.3-5.5,5c-1.6,1.7-3.2,3.2-4.5,4.8l-3.9,5c-1,1.2-1.6,2.6-2.5,3.9l-2.1,4.3 M355.1,297.2
	l-0.3-0.1 M354.2,296.7l0.5,0.4 M354.8,297.1l9.6,8.1l-1.1,0.4l-32.5-2.9l7.7,8.7l0.7,1.4c0.3,0.8,0.1,1-0.7,0.7l-11.4-1.8
	c-7.7-1.4-15-2.9-21.9-4.5c-2.6-0.5-4.3-2.1-5-4.5 M286.4,309.5c0.3,6.9,0.8,14.4,1.4,23l1.8,24.5 M300.7,321.3
	c-0.7-1.1-1.6-1.6-3-1.6c-1.2-0.7-1.8,0.4-1.5,3l0.4,20.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306.9,341.4l-6.7-0.7c-1.8-0.3-2.8-1.2-2.8-2.6l-0.4-15.5
	c-0.1-1.4,0.3-1.9,1.2-1.6l2.5,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M301,322l-1.5-0.4c-1,0-1.2,0.1-1.1,0.7c-0.1,4.5,0,9.6,0.3,15
	c0.1,1.5,0.8,2.3,2.1,2.2h4.7 M300.7,321.3l0.3,0.7 M301,322l0.1,0.4l4.4,17.2 M341.3,316.5l-26.8-4.7c-7.2-1-12-2.5-14.3-4.5
	c-1.4-0.8-2.3-2.5-2.9-4.8 M297.3,302.5h3 M297.3,302.5l-10.9,7 M300.4,283.5l14.9,2.3 M294.7,277.6c0.7-4.1,2.9-8.5,6.6-13.3
	c1.1-1.5,2.5-2.3,4.1-2.6 M304,252.2l-0.7-0.1 M294.7,277.6h-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.4,283.5c-2.3-0.8-3.6,2.2-4,9.4c-0.1,4.1,0.1,7.3,0.8,9.8
	 M290.9,282.8c-1.1,3.6-2.8,6.6-5.1,9.1c0,5.1,0.1,10.9,0.4,17.6 M291.8,282.4c1.1-0.8,2.1-2.5,2.9-5 M290.9,282.8l0.8-0.4
	 M293.3,277.6c-1.4,0.3-2.1,1-2.1,1.9c-0.1,1.2-0.3,2.1,0,2.5 M291.2,282l-0.3,0.8 M291.8,282.4c-0.1,0-0.4-0.1-0.5-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.3,302.5l-0.3-0.8c-0.7-2.3-1.2-5.2-1.4-8.9c-0.1-1.9-0.1-4-0.1-6.3
	c0-1.4,0.4-2.2,1-2.6c0.3-0.1,0.5-0.3,1-0.3 M264.9,253.6l28.2,2.6c4.4,0.7,6.7-1.1,7.2-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M303.3,252.1c-1,2.9-2.1,4.5-3.2,5c-4,0.4-8.4,0.4-13.2,0
	c-1.5-0.1-2.6,0-3.2,0.1c-0.4,0.1-0.5,0.3-0.7,0.5 M303.3,252.1c-1.4-0.1-2.3-0.4-3-0.8 M300.4,259c-1.5,0.4-2.8,3-3.9,7.6
	c-0.7,4.4-1.8,8.1-3.3,11.1 M318.3,258.8c-5,3.9-6.9,7.3-5.8,10.2c1.5,4.3,2.5,9.9,2.8,16.8 M305.4,261.5c0.7,0,1.4-0.3,2.5-0.7
	 M307.9,260.9l10.5-2.1 M300.4,259c1.9,0.4,3.2,1.2,3.9,2.3c0.3,0.3,0.7,0.3,1.1,0.3 M304,252.2c1.8,0.3,3.2-0.1,3.9-1.2l2.9-6.3
	 M321.9,258.2l-3.6,0.7 M520.1,258.2l-11.6-0.5l-20.8-1.2l-24.9-0.7h-86.4l-22.3,0.7l-20.6,1.2 M310.8,244.7l12.9-27.8 M343.5,197.8
	c-1.6-0.3-3.9,3.2-6.5,10.3 M328.4,208.6l-11.3,28.9 M340.5,207.7c1.8-5.1,3.3-8.4,4.7-9.9 M328.4,208.6c3.4-0.6,6.3-0.8,8.7-0.6
	 M327.4,208.9l1-0.3 M327.4,208.9l-0.1,0.3l-3.6,7.7 M337.2,209.6c0.3-0.7,0.3-1.2-0.1-1.4 M337,208.1l3.4-0.4 M337.2,209.6l3.3-0.6
	 M340.5,209.1v-1.4 M317.1,237.5l-6.3,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M323.7,216.9l-8.8,15.7l-0.4,1L304,252.2 M345.1,197.6l-1.6,0.1
	 M343.5,197.8l-0.5-0.4c-4.5,0.6-7.7,1.6-9.4,3.2c-2.9,2.5-5,5.2-6.1,8.3 M264.7,245.8l6.3,0.8c1.6,0.3,2.6,1,2.6,2.2
	c0.1,1.2-1.2,1.8-3.9,1.8s-4.4-0.1-5.4-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300.3,251.3c-0.3-0.3-0.5-0.6-0.7-0.8l-3.2-7.7c-1-2.5-4.1-3.7-9.5-3.9
	h-2.6c-6.3-0.1-10.9,0.1-13.8,0.7h-0.1c-2.9,0.4-4.8,2.3-5.8,5.6v0.1l-0.1,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M264.7,245.8c-0.8,0-1.4,0.4-1.5,1.1v1.4c0,0.8,0.4,1.4,1.2,1.6
	 M505.1,208.1c2.3-0.3,5.2,0,8.7,0.6 M577.6,249.9c0.8-0.3,1.2-0.8,1.2-1.6v-1.4c-0.1-0.7-0.5-1.1-1.5-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.5,245.8l-6.3,0.8c-1.7,0.3-2.6,1-2.6,2.2c-0.1,1.2,1.2,1.8,3.9,1.8
	s4.4-0.1,5.4-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.5,245.8l-0.1-0.4v-0.1c-1.1-3.3-3-5.2-5.8-5.6h-0.1
	c-3-0.6-7.6-0.8-13.8-0.7h-3.2c-5.1,0.3-8,1.5-8.9,3.9l-3.2,7.7l-0.7,0.8 M525.1,237.5l6.2,7.2 M518.3,216.9l12.9,27.8 M513.8,208.6
	l11.3,28.9 M514.8,208.9l-1-0.3 M518.3,216.9l-3.6-7.7v-0.3 M498.7,197.8c1.7-0.3,3.9,3.2,6.5,10.3 M497,197.6
	c1.4,1.5,2.9,4.8,4.7,9.9 M505,209.6c-0.3-0.7-0.3-1.2,0.1-1.4 M501.7,209.1l3.3,0.6 M505.1,208.1l-3.4-0.4 M501.7,207.7v1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M514.8,208.9c-1.1-3-3.2-5.8-6.1-8.3c-1.6-1.5-4.8-2.6-9.4-3.2l-0.5,0.4
	 M498.7,197.8l-1.6-0.1 M501.7,209.1v0.4c-12.4-1.2-26.3-2.1-41.5-2.6c-13.6-0.6-26.7-0.7-39.1-0.6c-12.4-0.1-25.5,0-39.1,0.6
	c-15.3,0.6-29.2,1.5-41.5,2.6V209"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M501.7,207.7c-12.4-1.2-26.3-2.1-41.5-2.6c-13.6-0.6-26.7-0.7-39.1-0.6
	c-12.4-0.1-25.5,0.1-39.1,0.6c-15.3,0.6-29.2,1.5-41.5,2.6 M497,197.6c-0.3-0.6-1.1-0.8-2.5-1.1c-19.7-1.9-41.1-3-64.2-3.3h-18.4
	c-23.1,0.3-44.6,1.4-64.2,3.3c-1.5,0.3-2.3,0.6-2.5,1.1"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M318.3,258.8l3.6-0.7
		c0,0,49.8-2.5,54.3-2.5s44.8,0,44.8,0h42.5c0,0,25,0.7,56.5,2.5l3.6,0.7c0,0,6.2,4.4,6.2,8.5c0,0,0,0.8-0.4,1.8
		c0,0-2.3,5.8-2.8,16.5c0,0-4.3,3.9-11.8,6.1c0,0-12.5,3.3-27.1,4.8l-13.9,0.5l-53,0.1l-40.9,0.1c0,0-14.7-0.1-25.9-1
		c0,0-9.4-0.1-27.8-5c0,0-6.1-1.5-11.3-5.9c0,0-0.1-9.9-2.8-16.8c0,0-1.2-2.3,1.4-5.6C313.9,263.4,315.7,260.8,318.3,258.8z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M318.3,258.8l18.8-49.2
		l3.3-0.6v0.4c0,0,27.4-3.2,80.6-3.2c53.2,0,80.6,3.2,80.6,3.2V209l3.3,0.6l18.8,49.4l-3.9-0.7c0,0-31.8-2.5-98.8-2.5
		c-67,0-99,2.5-99,2.5L318.3,258.8z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M368.1,297.5l9.4,5.8
		c0,0,2.2,1.5,7.2,1.6l22.3,0.3c0,0,1.6-0.3,2.2-1.6c0,0,2.3-4.4,7.3-5.5l0.4-0.4h8.4l0.4,0.4c0,0,5.1,0.8,7.3,5.5
		c0,0,0.8,1.6,2.3,1.6h13.2c0,0,10.2,0,12.8-0.6c0,0,2.2-0.4,4-1.5l8.8-5.5l0.1-0.4c0,0-21.7,0.8-32.6,0.1l-20.4-0.1h-19.4
		c0,0-20.1,0.5-33.4-0.1L368.1,297.5z"
              id="Grille 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M286.4,309.5l11-7h3
		c0,0,1,3.9,5,4.5c0,0,7.8,2.1,23,4.8l10.3,1.6c0,0,0.7,0.3,0.8,0.1c0-0.3-0.1-0.8-0.1-0.8l-0.8-1.4l-7.7-8.5l32.5,2.9l1.1-0.6
		l-9.6-8l13.8,0.7l8.8,5.5c0,0,2.9,1.8,8.4,1.8l5,0.1l16.1,0.1c0,0,1.4,0,2.1-1.1c0,0,2.1-4.8,7.6-6.2l0.4-0.4h8.4l0.4,0.4
		c0,0,5.1,0.7,7.3,5.5c0,0,0.8,1.6,2.6,1.6l15.3-0.1c0,0,6.1-0.3,8.3-0.3c0,0,2.8,0.3,5.8-1.6l8.9-5.6c0,0,5.1,0,13.1-0.3l-9.2,8
		l1.1,0.4l32.5-2.9l-7.7,8.7c0,0-1,1.1-0.8,2.1c0,0,0.1,0.3,0.8-0.1l9.8-1.5c0,0,5-0.8,12.7-2.3L537,307c0,0,3.6-0.7,4.7-4.5h3
		l11,6.9c0,0-1.4,26.7-3.2,47.5l-0.7,9.4l-1.1,6.7c0,0,0,1.8-1.5,2.3c0,0-2.1,0.8-3.2,1.1c0,0,0-0.8-6.2-1.6l-14.4-2.2
		c0,0-3.9-0.5-8.3-0.5l-19.7-0.7l-76.3-0.1h-72.1l-4.3,0.1l-19.4,0.7c0,0-2.8-0.1-6.5,0.3c0,0-5.6,0.6-11.4,1.5
		c0,0-10.6,1.4-11.6,2.5c0,0-3.3-0.7-4.3-2.2c0,0-0.1-0.1-0.3-1.2l-1.2-6.3C290.3,366.4,286.8,323.2,286.4,309.5z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M300.3,302.5
		c0,0-1.4-3.7-1.6-9.8c0,0-0.8-8.8,1-8.9c0,0-0.5-0.5,2.6-0.1c3,0.4,13.1,2.2,13.1,2.2s4.4,4.5,13.9,6.6c0,0,12.5,3.3,25.2,4.3
		l10.2,8.5l-1.1,0.6l-32.5-2.9l7.8,8.7l0.7,1.4c0,0,0.4,0.8,0,0.8s-0.7-0.3-0.7-0.3s-13.8-2.1-17.7-2.9l-14.7-3.2
		C306.2,307.3,301.5,306.9,300.3,302.5z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M511.2,302.9
		l-32.5,2.9l-1.1-0.4l10.2-8.7c0,0,7-0.7,14.9-2.1c0,0,6.6-1.2,14-3.3c0,0,5.4-1.5,10.2-5.6l14.9-2.3c0,0,1.4-0.3,1.8,1.7l0.1,2.1
		l-0.1,5.6c0,0-0.5,6.9-1.7,9.6c0,0-0.8,3.6-4.7,4.5l-8.4,1.9c0,0-8.4,1.6-13.2,2.6l-12.1,1.9c0,0-0.6,0.4-0.8,0.1
		c-0.1-0.4,0.6-1.6,0.8-2.1L511.2,302.9z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M354.4,323.5l1.5-1.4l1.9-1.6
		l2.3-1.8c2.1-1.9,6.1-2.8,12.1-2.8h97c6.1,0,10.2,0.8,12.1,2.8l2.3,1.8l1.9,1.6l1.5,1.4l2.1,1.9c1.7,1.7,3.2,3.2,4.7,4.8l1.5,1.8
		l1.1,1.4l1.4,1.9c1.1,1.5,2.1,3,2.9,4.5v0.1l0.7,1.4l0.3,0.4l0.7,1.4l0.4,1l0.3,1c0.3,1.8,0.3,3.4-0.3,4.8l-1,1.4l-1.4,1.4
		c-0.5,0.5-1.4,1-2.1,1.2l-1.2,0.7c-3.7,1.9-7.2,3.2-10.2,4h-0.4c-1.4,0.3-2.6,0.4-3.9,0.5l-18.8,0.1h-0.3l-23.1,0.1h-0.4l-19.1,0.1
		h-0.3l-19.1-0.1h-0.4l-23.1-0.1h-0.3l-18.8-0.1c-1.2,0-2.5-0.3-3.9-0.5h-0.3c-3-0.7-6.5-2.1-10.2-4l-1.2-0.7
		c-0.8-0.4-1.5-0.8-2.1-1.2s-1-0.8-1.4-1.4c-0.4-0.5-0.7-1.1-1-1.6c-0.5-1.4-0.5-3-0.3-4.8l0.1-1l0.4-1l0.7-1.4l0.3-0.4l0.7-1.4
		v-0.1c0.8-1.5,1.8-3,2.9-4.5l1.4-1.9l1.1-1.4l1.5-1.8c1.5-1.7,3-3.2,4.7-4.8L354.4,323.5L354.4,323.5z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback3DrFront);
