import React, { Component } from 'react';
import moment from 'moment';

export default class CustomCountdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeUntilStart: {
        months: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
      timeUntilEnd: {
        months: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
      bidTimeUntilEnd: {
        months: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
    };
    this.timer = null;
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 900);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick = () => {
    const { startTime, endTime, bidStartTime, bidEndTime } = this.props;

    // Begins In
    let eventCardNotStarted = moment().isBefore(startTime);

    let vehicleCardNotStarted = moment().isBefore(startTime);

    if (eventCardNotStarted === true || vehicleCardNotStarted === true) {
      let timeUntilStart = moment.duration(moment(startTime).diff(moment()));

      this.setState({
        timeUntilStart: {
          months: timeUntilStart.months(),
          days: timeUntilStart.days(),
          hours: timeUntilStart.hours(),
          minutes: timeUntilStart.minutes(),
          seconds: timeUntilStart.seconds(),
        },
      });
    }

    // Ends In
    let vehicleCardInProgress = moment().isBetween(startTime, endTime);

    if (vehicleCardInProgress === true) {
      let timeUntilEnd = moment.duration(moment(endTime).diff(moment()));

      this.setState({
        timeUntilEnd: {
          months: timeUntilEnd.months(),
          days: timeUntilEnd.days(),
          hours: timeUntilEnd.hours(),
          minutes: timeUntilEnd.minutes(),
          seconds: timeUntilEnd.seconds(),
        },
      });
    }

    let biddingInProgress = moment().isBetween(bidStartTime, bidEndTime);

    if (biddingInProgress === true) {
      let bidTimeUntilEnd = moment.duration(moment(bidEndTime).diff(moment()));
      this.setState({
        bidTimeUntilEnd: {
          months: bidTimeUntilEnd.months(),
          days: bidTimeUntilEnd.days(),
          hours: bidTimeUntilEnd.hours(),
          minutes: bidTimeUntilEnd.minutes(),
          seconds: bidTimeUntilEnd.seconds(),
        },
      });
    }
  };

  render() {
    const { startTime, endTime, bidStartTime, bidEndTime } = this.props;
    const { timeUntilStart, timeUntilEnd, bidTimeUntilEnd } = this.state;

    let eventCardNotStarted = startTime && moment().isBefore(startTime);

    let eventCardInProgress = moment().isBetween(startTime, endTime);

    let eventCardHasEnded = moment().isAfter(endTime);

    let vehicleCardNotStarted = startTime && moment().isBefore(startTime);

    let vehicleCardInProgress = moment().isBetween(startTime, endTime);

    let vehicleCardHasEnded = moment().isAfter(endTime);

    let biddingInProgress = moment().isBetween(bidStartTime, bidEndTime);

    return (
      <div>
        {(eventCardNotStarted || vehicleCardNotStarted) && (
          <div>
            <strong>Event Begins In:</strong>
            <br />
            {timeUntilStart.months === 1 && (
              <span>{timeUntilStart.months} month </span>
            )}
            {timeUntilStart.months > 1 && (
              <span>{timeUntilStart.months} months </span>
            )}

            {timeUntilStart.days === 1 && (
              <span>{timeUntilStart.days} day </span>
            )}
            {timeUntilStart.days > 1 && (
              <span>{timeUntilStart.days} days </span>
            )}

            {timeUntilStart.hours === 1 && (
              <span>{timeUntilStart.hours} hr </span>
            )}
            {timeUntilStart.hours > 1 && (
              <span>{timeUntilStart.hours} hrs </span>
            )}

            {timeUntilStart.minutes > 0 && (
              <span>{timeUntilStart.minutes} min </span>
            )}

            {timeUntilStart.months <= 0 &&
              timeUntilStart.days <= 0 &&
              timeUntilStart.hours <= 0 && (
                <span>{timeUntilStart.seconds} secs </span>
              )}
          </div>
        )}
        {biddingInProgress && (
          <span>
            {bidTimeUntilEnd.months === 1 && (
              <span>{bidTimeUntilEnd.months} month </span>
            )}
            {bidTimeUntilEnd.months > 1 && (
              <span>{bidTimeUntilEnd.months} months </span>
            )}

            {bidTimeUntilEnd.days === 1 && (
              <span>{bidTimeUntilEnd.days} day </span>
            )}
            {bidTimeUntilEnd.days > 1 && (
              <span>{bidTimeUntilEnd.days} days </span>
            )}

            {bidTimeUntilEnd.hours === 1 && (
              <span>{bidTimeUntilEnd.hours} hr </span>
            )}
            {bidTimeUntilEnd.hours > 1 && (
              <span>{bidTimeUntilEnd.hours} hrs </span>
            )}

            {bidTimeUntilEnd.minutes > 0 && (
              <span>{bidTimeUntilEnd.minutes} mins </span>
            )}

            {bidTimeUntilEnd.months <= 0 &&
              bidTimeUntilEnd.days <= 0 &&
              bidTimeUntilEnd.hours <= 0 && (
                <span>{bidTimeUntilEnd.seconds} secs </span>
              )}
          </span>
        )}
        {(vehicleCardInProgress || eventCardInProgress) && (
          <div>
            <strong>Event In Progress:</strong>
            <br />
            <span>{timeUntilEnd.days} days </span>
            {timeUntilEnd.hours === 1 && <span>{timeUntilEnd.hours} hr </span>}
            {timeUntilEnd.hours > 1 && <span>{timeUntilEnd.hours} hrs </span>}

            {timeUntilEnd.minutes > 0 && (
              <span>{timeUntilEnd.minutes} mins </span>
            )}
            {timeUntilEnd.days <= 0 && timeUntilEnd.hours <= 0 && (
              <span>{timeUntilEnd.seconds} secs </span>
            )}
          </div>
        )}
        {(eventCardHasEnded || vehicleCardHasEnded) && (
          <div>
            <strong>Event has Ended</strong>
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}
