import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values, isEmpty } from 'lodash';

import $ from 'jquery';
import { toastr } from 'react-redux-toastr';
import 'bootstrap';
import { Button } from 'semantic-ui-react';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import {
  loadDealers,
  loadSellerExtOffers,
  loadExtOfferUpdate,
} from '../../actions';
import {
  getConfig,
  compose,
  commafy,
  commafyCurrency,
  makeTitle,
  titleCase,
} from '../../utils/helpers';
import styles from './styles';
import ExtOfferModal from '../ExtOfferModal';
import { dataPlaceholder } from '../../utils/constants';

let refreshInterval;
class SellerCarOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'seller car offers',
      requestUid: '',
      vin: '',
      dealerId: '',
      deeplink: null,
    };
  }

  componentDidMount() {
    this.fetchAllDealers();
  }

  fetchAllDealers = () => {
    this.props.loadDealers(getConfig('marketplaceId'));
  };

  fetchCarOffers = dealerId => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
      refreshInterval = null;
    }
    this.props.loadSellerExtOffers(getConfig('marketplaceId'), dealerId);
    refreshInterval = setInterval(
      () =>
        this.props.loadSellerExtOffers(getConfig('marketplaceId'), dealerId),
      60000
    );
  };

  getDeepLink = row => {
    const url =
      `${getConfig('apiRoot')}ext-offer-deeplink` +
      `?apiKey=${this.props.cookies.get('apiKey')}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&vin=${row.vin}` +
      `&requestUid=${row.requestUid}` +
      `&carOfferEntityId=${row.carOfferEntityId}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        // note: for iframe
        if (result.deepLink.deepLinkUri) {
          this.setState(
            {
              requestUid: row.requestUid,
              vin: row.vin,
              deeplink: result.deepLink.deepLinkUri,
            },
            () => {
              $('#extOfferModal').modal('show');
            }
          );
        } else {
          toastr.error('Error', result.wsMessage);
        }

        // note: for new window
        // window.open(result.deepLink.deepLinkUri);
      });
  };

  handleViewOffer = row => {
    this.getDeepLink(row);
  };

  handleModalCancel = () => {
    const mpId = getConfig('marketplaceId');
    const { requestUid, vin } = this.state;

    this.props.loadExtOfferUpdate(mpId, 0, requestUid, vin);
    this.setState({ requestUid: '', vin: '', deeplink: null }, () => {
      $('#extOfferModal').modal('hide');
    });
  };

  renderDealerList() {
    let dealers = this.props.dealers.dealersWithInventory;
    dealers = values(dealers);

    const noDealers = isEmpty(this.props.dealers.dealersWithInventory);

    const options = dealers.map((dealer, index) => {
      return (
        <option key={index} value={dealer.dealerId}>
          {dealer.name + ' (' + dealer.units + ')'}
        </option>
      );
    });

    return (
      <div>
        <select
          id="first-offer-dealers"
          className="form-control"
          onChange={event =>
            this.setState(
              { dealerId: event.target.value },
              this.fetchCarOffers(event.target.value)
            )
          }
          value={this.state.dealerId}
          style={{ maxWidth: '450px' }}
          disabled={noDealers}
        >
          <option value={''} selected disabled hidden>
            {noDealers ? 'No Dealers' : 'Select Dealer'}
          </option>
          {options}
        </select>
      </div>
    );
  }

  render() {
    const loading = isEmpty(this.props.sellerCarOffers);

    let offers = this.props.sellerCarOffers.inventory;
    offers = values(offers);

    const columns = this.props.makeColumns([
      'checkbox',
      'vin',
      'year',
      'make',
      'model',
      {
        Header: 'Mileage',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'odometer',
        accessor: row => commafy(row.odometer) || dataPlaceholder,
      },
      {
        Header: 'Min Bid',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'startingBid',
        accessor: row =>
          row.startingBid == 0 || row.startingBid == null
            ? dataPlaceholder
            : commafyCurrency(row.startingBid),
      },
      {
        Header: 'Reserve',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'reservePrice',
        accessor: row =>
          row.reservePrice == 0 || row.reservePrice == null
            ? dataPlaceholder
            : commafyCurrency(row.reservePrice),
      },
      {
        Header: 'Buy Now',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'outrightPrice',
        accessor: row =>
          row.outrightPrice == 0 || row.outrightPrice == null
            ? dataPlaceholder
            : commafyCurrency(row.outrightPrice),
      },
      {
        Header: 'Feed Price',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'feedPrice',
        accessor: row =>
          row.feedPrice == 0 || row.feedPrice == null
            ? dataPlaceholder
            : commafyCurrency(row.feedPrice),
      },
      {
        Header: 'Offer Amount',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'offerValue',
        accessor: row =>
          row.offerValue == 0 || row.offerValue == null
            ? dataPlaceholder
            : commafyCurrency(row.offerValue),
      },
      {
        Header: 'Offer Status',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        id: 'requestStatusCode',
        accessor: row =>
          titleCase(makeTitle(row.requestStatusCode)) || dataPlaceholder,
      },
      {
        Header: 'Actions',
        headerStyle: { background: 'none' },
        id: 'buttons',
        sortable: false,
        filterable: false,
        Cell: row => (
          <div style={{ ...styles.buttonGroup, height: 'max-content' }}>
            <Button
              style={styles.button}
              onClick={() => this.handleViewOffer(row.original)}
            >
              View Offer
            </Button>
          </div>
        ),
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>SST First Offer</h4>
          {this.renderDealerList()}
        </div>

        {this.state.dealerId !== '' ? (
          <div>
            <ExtOfferModal
              deeplink={this.state.deeplink}
              onModalCancel={this.handleModalCancel}
            />
            <DataTable
              loading={loading}
              title={this.state.title}
              data={offers}
              columns={columns}
              showCSVButton={true}
              showPrintButton={true}
            />
          </div>
        ) : (
          <p>Select a dealer...</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { dealers, sellerCarOffers } = state.entities;
  return { dealers, sellerCarOffers };
};

export default compose(
  withTable,
  connect(
    mapStateToProps,
    { loadDealers, loadSellerExtOffers, loadExtOfferUpdate }
  )
)(SellerCarOffers);
