import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { findIndex, has } from 'lodash';
import TablePaginationAndButtons from './TablePaginationAndButtons';
import TableDropdownFilter from './TableDropdownFilter';
import { setTableRef } from '../../actions';
import { filterLogicStartsWith, getParamFromUrl } from '../../utils/helpers';
import { dataPlaceholder } from '../../utils/constants';

let styles = {};

class DataTable extends Component {
  componentDidMount() {
    // set this in store so 'withTable' has reference and can access the filtered/sorted data
    // and give it to CSV and Print buttons
    // not sure why, but some tables at some times do not get the ref
    this.props.setTableRef(this.reactTable);
  }

  // this is to decide if the row should be highlighted or not
  hasBeenSelected(row) {
    return false;
    // const { selectedData, allSelected } = this.context;

    // if (row && allSelected) {
    //   return true;
    // }

    // // offers pages use offerId because there can be multiple offers on same item
    // if (has(row, 'original.offerId')) {
    //   return (
    //     findIndex(selectedData, {
    //       offerId: row.original.offerId,
    //     }) > -1
    //   );
    // }

    // // all others are selected by itemId / id
    // if (has(row, 'original.itemId')) {
    //   return findIndex(selectedData, { itemId: row.original.itemId }) > -1;
    // }
    // if (has(row, 'original.id')) {
    //   return findIndex(selectedData, { id: row.original.id }) > -1;
    // }

    // // except buyer arbs
    // if (!has(row, 'original.itemId') && has(row, 'original.vin')) {
    //   return findIndex(selectedData, { vin: row.original.vin }) > -1;
    // }
    // if (!has(row, 'original.id') && has(row, 'original.vIN')) {
    //   return findIndex(selectedData, { vIN: row.original.vIN }) > -1;
    // }

    // return false;
  }

  getPageSizeOptions(data) {
    const len = data.length;
    let options = [10, 25, 50, 100];
    options = [...options, len].sort((a, b) => (a < b ? -1 : 1));
    options = options.slice(0, options.indexOf(len) + 1);
    return options;
  }

  customSortMethod = (a, b, desc) => {
    // dataPlaceholder always has lowest sort priority
    if (a === dataPlaceholder) return -1;

    // force null and undefined to the bottom
    a = a === null || a === undefined ? '' : a;
    b = b === null || b === undefined ? '' : b;

    // force any string values to lowercase
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;

    // cut out all non-alphanumberic characters
    if (typeof a === 'string') a = a.replace(/\W+/g, '');
    if (typeof b === 'string') b = b.replace(/\W+/g, '');

    // function to tell if a string is *really* a number in disguise
    // (i could have used NaN but that is unreliable)
    const isNumberString = val => {
      if (typeof val !== 'string') return false;
      return !/[a-zA-Z]/g.test(val);
    };

    // if it *is* a number make it a number so you you can compare by value not alphabetically
    if (isNumberString(a)) a = Number(a);
    if (isNumberString(b)) b = Number(b);

    // if comparing mixed values numbers always have lower sort priority
    if (typeof a === 'string' && typeof b === 'number') return 1;
    if (typeof b === 'string' && typeof a === 'number') return -1;

    // Return either 1 or -1 to indicate a sort priority
    if (a > b) return 1;
    if (a < b) return -1;

    // returning 0, undefined or any falsey value will use subsequent sorts or
    // the index as a tiebreaker
    return 0;
  };

  render() {
    if (this.props.loading) {
      return <p>Loading...</p>;
    }

    if (!this.props.loading && this.props.data && !this.props.data.length) {
      return (
        <div style={{ margin: 20, fontSize: 16 }}>
          There are currently no items
        </div>
      );
    }

    return (
      <ReactTable
        ref={node => (this.reactTable = node)}
        className="react-table -striped -highlight"
        getProps={() => ({ style: styles.bodyStyle })}
        getTableProps={() => ({ style: styles.tableStyle })}
        getTheadProps={() => ({ style: styles.headerRowStyle })}
        getTheadThProps={() => ({ style: styles.headerCellStyle })}
        getTheadFilterTrProps={() => ({ style: styles.filterRowStyle })}
        getTheadFilterThProps={() => ({ style: styles.filterCellStyle })}
        getTrProps={(state, row, column) =>
          this.hasBeenSelected(row)
            ? {
                style: { ...styles.rowStyle, backgroundColor: '#ccc' },
              }
            : { style: styles.rowStyle }
        }
        getTdProps={(state, row, column) =>
          column.id === 'checkbox' || column.id === 'image'
            ? {
                style: {
                  ...styles.cellStyle,
                  padding: 0,
                  textOverflow: 'initial',
                },
              }
            : { style: styles.cellStyle }
        }
        defaultPageSize={10}
        pageSizeOptions={this.getPageSizeOptions(this.props.data)}
        showPaginationTop={true}
        showPaginationBottom={true}
        PaginationComponent={props => {
          const pageParam = getParamFromUrl('p');
          const manualPage = pageParam ? Number(pageParam - 1) : null;
          return (
            <TablePaginationAndButtons
              {...props}
              page={manualPage || props.page}
              showCSVButton={this.props.showCSVButton}
              showPrintButton={this.props.showPrintButton}
              showWatchlistButton={this.props.showWatchlistButton}
            />
          );
        }}
        resizable={false}
        defaultSortMethod={this.customSortMethod}
        filterable={true}
        defaultFilterMethod={filterLogicStartsWith}
        FilterComponent={props => (
          // if filterable, this is the default filter component
          <TableDropdownFilter
            filter={props.filter}
            column={props.column}
            onChange={props.onChange}
            data={this.props.data}
          />
        )}
        title={this.props.title}
        data={this.props.data}
        selectedData={this.props.selectedData}
        dataForButtonActions={
          // aka smart data
          this.context.selectedData.length // if the user selects rows use those
            ? this.context.selectedData
            : this.context.currentData.length // else if the user has data filtered use the filtered data
            ? this.context.currentData
            : this.props.data // else use all the data
        }
        columns={this.props.columns}
        onSortedChange={this.context.updateCurrentData} // for the CSV and Print buttons to have filtered/sorted data
        onFilteredChange={this.context.updateCurrentData} // for the CSV and Print buttons to have filtered/sorted data
      />
    );
  }
}

styles = {
  bodyStyle: {
    border: 'none',
  },
  tableStyle: {
    borderBottom: '1px solid gray',
    overflow: 'auto hidden',
  },
  headerRowStyle: {
    borderTop: '1px solid gray',
    boxShadow: 'none',
    dropShadow: 'none',
    overflow: 'hidden',
  },
  headerCellStyle: {
    fontWeight: 'bold',
    outline: 'none',
    overflow: 'hidden',
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 12,
    paddingTop: 5,
    textAlign: 'center',
    textOverflow: 'ellipses',
    whiteSpace: 'normal',
    wordBreak: 'normal',
  },
  filterRowStyle: {
    height: 42,
    borderBottom: '1px solid gray',
  },
  filterCellStyle: {
    backgroundImage: 'none',
    fontWeight: 'bold',
    outline: 'none',
    padding: '5px 2px 12px 5px',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  rowStyle: {},
  cellStyle: {
    maxHeight: 120,
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipses',
    whiteSpace: 'normal',
  },
};

DataTable.defaultProps = {
  showCSVButton: false,
  showPrintButton: false,
  showWatchlistButton: false,
  showTransportButton: false
};

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showCSVButton: PropTypes.bool,
  showPrintButton: PropTypes.bool,
  showWatchlistButton: PropTypes.bool,
  showTransportButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

DataTable.contextTypes = {
  allSelected: PropTypes.bool,
  selectedData: PropTypes.array,
  currentData: PropTypes.array,
  updateCurrentData: PropTypes.func,
};

export default connect(null, { setTableRef })(DataTable);
