import React, { Component } from 'react';
import BackButton from '../../ui/buttons/BackButton';
import SearchBox from '../../templates/searchResults/SearchBox';
import Card from '../../templates/searchResults/Card';

class SearchResults extends Component {
  render() {
    // Todo - the below array needs to be removed and made dynamic
    const vehicles = [0, 1];
    return (
      <div className="container-fluid min-h-screen sr-main-container">
        <BackButton style={{ marginTop: 35, marginBottom: 20 }} />
        <div className="sr-title">Inventory Search</div>
        <SearchBox />
        <span className="sr-count">Search results 34</span>
        <div className="sr-container">
          {vehicles.map((item, index) => (
            <Card key={index} identifier={`sr-card-${index}`} vehicle={item} />
          ))}
        </div>
      </div>
    );
  }
}

export default SearchResults;
