import React, { Component } from 'react';
import { connect } from 'react-redux';

class SportsVanRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 140 810 340">
          <path
            fill="#FFFFFF"
            d="M41,293c-1.4,8-2.3,16.4-2.8,25.5c-7.7,2.8-16.8,5.6-16.8,8.7c0,3-1.6,42.8,0,47.2c0.3,0.5,0.7,1.1,1.1,1.5
	c0.5,0.8,1.1,1.5,1.4,1.8l0.1,0.1c-0.1,0.5-0.1,9.8,4.8,11.4c6.6,2.2,46.9,8,81,11.1c0.4-0.8,0.1-1.6-0.1-2.6
	c-0.1-0.3-0.1-0.5-0.3-0.8c-0.3-1.8-1.7-6.7-0.8-17.5c1.1-13.2,4.5-37.4,25.6-51.7c10-6.9,24.2-11.4,44-11.3
	c52,0.1,64.4,36.9,68,61.4c0.8,5.8,1.4,13.2,1.5,16.8c-0.6,1.1-0.3,6.3-0.3,8c0.1,2.6,0.1,3.4,5.4,3.6c4,0.1,263.7,0.4,344.9,0.5
	h23.9c2.5,0,3-1,2.9-4.8c-0.1-2.9-7.4-84.1,70.3-84.3c62.7-0.1,68.1,56.3,67,78.5c-0.1,3.7-1.5,4.8-1.8,6.9c0,0.3-0.1,0.7,0,1.1
	c0,0-0.7,0.1-1.1,0.1c0,0-0.1,6.2,0,7.7c0.1,1.8,0,2.1,2.6,1.9c4.7-0.1,34.5-1.6,37.1-2.5c-0.3-2.9,0-8.3,0-8.3s2.5-0.3,4.4-0.4
	c3-0.3,6.1-0.5,11.3-4.3c1.1-0.7,2.1-2.3,1.9-3.3c-0.1-1.1-0.3-1.1-1.5-1.1c-1.1-0.4-0.8-4.5,0-7.6c1.5-5.4,2.8-12.4,8.7-13.5
	c-0.1-1.8-0.5-12.7-1.1-21.5v-0.1c0.3-1.1,0.3-5.8-0.1-6.1c-0.1-1.8-1.2-5.5-1.5-6.3c-0.5-1.8-2.1-4.8-4.4-8.7
	c-0.1-0.3-0.3-0.4-0.4-0.7c-3.9-6.5-9.4-14.7-14.2-21.5c-5.9-8.3-9.4-10.9-13.5-13.3c-1.4-0.8-2.8-1.6-4.4-2.6
	c-27.9-16.8-102.8-30-117.1-32.9c-4.8,0.1-8-0.4-11.3-1.2c-70.6-47.5-108-67-129.2-74.3c-31.1-10.6-52.4-11.3-65.3-12.1
	c-13.5-5.8-19.3-6.2-34.1-6.7c-1.9-0.1-5.9-0.3-11.4-0.4c-20.2-0.7-61.9-2.1-104.8-2.1c-68.2,0-140.6,2.1-146,2.3
	c-9.8,0.6-15.4,2.5-18.7,5.9c-1.6,0-3.3,0.1-5.1,0.3c1.8-0.1,3.6-0.1,5.2-0.1h-0.4l-0.6,0.6c-6.3,0.1-13.2,0.7-19.5,1.2
	c3.4-0.6,6.7-1,9.9-1.2c-15.7,1.1-31.5,3.6-36.5,5c-5.9,1.6-4,5.1-3.7,8.4c-3,3-13.6,13.6-24.8,31.2c-7,11-14.3,24.9-20.2,41.5
	c-0.1,0.5-0.4,1.1-0.5,1.5l-0.7,2.2c-2.1,6.1-3.9,12.2-5.4,18.8c-0.3,1-0.4,1.9-0.7,2.9c-0.5,2.5-1.1,5.1-1.5,7.7
	C41.3,291.6,41.1,292.3,41,293L41,293z"
          />
          <path
            fill="#FFFFFF"
            d="M782.3,350.7c0.6,1.2,0.7,1,3.2,1c7.6,0.3,35.4,0,36.6,0v-0.1l-0.4-6.1l-38.9-0.1
	C779.8,345.2,779.4,344.5,782.3,350.7L782.3,350.7z M432.9,336.8c-75.9-1.1-151.2-1-162.9-0.8c-2.9,0-2.6-0.1-0.4,5
	c0.5,1.2,0.8,1.1,3.9,1.2c13.8,0.3,86.8,1.6,159.3,2.8h0.3c76.1,1.1,149.3,1.4,161.4,1.5c3.6,0,2.8,0.7,4.1-3.7
	c0.5-1.6-0.5-1.5-1.8-1.5C587.1,340.6,510.2,337.9,432.9,336.8L432.9,336.8z M21.3,339.7c0,0,56.3-0.3,67.5-0.3
	c2.5,0,3.2,0.5,4.1-1.2c3-5.6,3.2-5.1,0.4-5.2c-9.8-0.1-71.8,0.1-71.8,0.1L21.3,339.7z"
          />
          <path
            fill="#EAEBEB"
            d="M787.7,294.8c-4.8-1.5-15.8-2.2-21.3-2.1l-1.7,1.5c0.3,0.8,0.7,1.6,1.1,2.6c2.1,4.7,5.2,11.3,8.5,17.1
	c1.7,1.1,3.3,2.1,4.8,2.9c8.5,5.1,14.9,8.4,19.9,10.5c1.1-0.4,4.3-1.9,5.5-4.8c1.4-3-2.5-12.1-6.3-18.3c-4.1-5.2-7.2-7.3-10.5-9.4
	C787.8,294.9,787.8,294.9,787.7,294.8L787.7,294.8z"
          />
          <path
            fill="#5E5E5E"
            d="M636.4,391.5c0,4.3,0.5,8.5,1.4,12.5c5.8,24.8,27.9,43.2,54.5,43.1c30.8,0,55.7-25,55.7-55.9
	s-25-55.7-55.8-55.6C661.3,335.7,636.4,360.6,636.4,391.5L636.4,391.5z"
          />
          <path
            fill="#EAEBEB"
            d="M654.4,391.5c0,21.2,17.3,38.4,38.5,38.4c21.2,0,38.4-17.2,38.4-38.5c0-21.2-17.3-38.4-38.5-38.4
	C671.6,353.1,654.4,370.3,654.4,391.5L654.4,391.5z"
          />
          <path
            fill="#5E5E5E"
            d="M624.4,402.7l13.5,1.2c-1-4-1.4-8.3-1.4-12.5c0-30.8,24.9-55.9,55.7-55.9s55.9,24.9,55.9,55.6
	c0,4.1-0.4,8.1-1.2,12c6.3-0.1,12.4,0,13.1,0c0.3-2.1,1.7-3.2,1.8-6.9c1.1-22.3-4.3-78.7-67-78.6c-77.7,0.3-70.4,81.4-70.3,84.3
	L624.4,402.7L624.4,402.7z"
          />
          <path
            fill="#EAEBEB"
            d="M527.7,185c5.5,2.3,9.5,4.4,12.4,5.8c45.3,22.1,88.6,52.8,95.6,57.4c5,3.3,10.2,8.3,19,10.6
	c0.1,0,0.7-0.1,1.1-0.3c-0.1,0-0.3,0-0.4-0.1c-69.6-46.8-106.8-66.3-128.2-73.9C527.3,184.7,527.6,185,527.7,185L527.7,185z"
          />
          <path
            fill="#FFFFFF"
            d="M586.7,262.4c-0.1,0-0.3,0-0.4-0.1v4.7c8.8,0.5,16.8,1.1,23.9,1.5c12.8,0.8,22.8,1.6,29.3,2.1
	c-6.3-5.1-17.3-13.6-31.5-23.8c0.3,1.2,2.1,21.3,2.1,21.3l-2.1-21.2c-6.3-4.4-13.2-9.2-20.6-14v5.5c0.1,0,0.4-0.1,0.5-0.1
	c2.9-0.3,5.4,0.4,7.8,1.9c2.5,1.4,5.2,3.3,6.7,4.4c1.8,1.2,3,1.8,4.7,8.7c1.1,4.4,1.4,9.8,0,11c-1.7,1.4-5.1,2.3-7,2.5
	c-0.8,0-6.5,0.3-7-1.1C592.2,263.5,591.1,263,586.7,262.4L586.7,262.4z M610.1,268.6l-0.1-0.3C610.1,268.3,610.1,268.5,610.1,268.6
	L610.1,268.6z"
          />
          <path
            fill="#EAEBEB"
            d="M471.7,185.5c-8.3-1.1-22-1.8-27.4-2.1c0.7,34.1,2.9,73.5,3,76.1c53.5,2.3,102.6,5.1,138.8,7.4v-4.7
	c-3.9-0.7-4.1-3.7-3.9-6.1c0.3-2.5,0.8-9.1,1.9-14.3c0.4-2.5,1.8-3.4,3-3.7v-5.4c-13.9-9.1-29.6-18.6-46.1-27.4
	C531.3,200.2,506.8,189.9,471.7,185.5L471.7,185.5z"
          />
          <path
            fill="#FFFFFF"
            d="M476.5,279.8c-5.2-0.4-12.5-0.8-18.6-1.1c-5.6-0.3-10.3-0.4-11.4-0.4c-2.8,0-3.7,1-3.9,4.1
	c0,3.9,2.3,3.3,5.4,3.4h10.6l1.1-0.5c4.7,0,12.4,0.3,14.3,0.3c0.4,0,1.6,1,1.6,1s0.5,0.1,1.2,0.3c1.2,0.1,3.2,0.4,4.8,0.3
	c2.6-0.1,4-6.1,0-6.6C480.6,280.2,478.7,279.9,476.5,279.8L476.5,279.8z M414.1,276.7c-13.2-0.3,6.2,0-7.7-0.1
	c-6.1-0.1-12.7-0.1-17.5,0c-1.9,0-3.6,0.1-4.7,0.3c-4,0.4-3,6.3-0.3,6.6c1.2,0.1,2.8,0.1,3.9,0c1-0.1,2.2-0.1,2.2-0.1
	s1.2-0.8,1.6-0.8c2.1,0,9.4,0.1,14,0.4c0.3,0.3,1.1,0.7,1.1,0.7s1,0,2.2,0.1c2.9,0.1,7.7,0.5,9.2,0.5c3,0.1,5.5,0.7,5.6-3
	c0.1-3.2-0.8-4.1-3.6-4.4C419.4,276.9,417.1,276.9,414.1,276.7L414.1,276.7z"
          />
          <path
            fill="#EAEBEB"
            d="M274.4,180.4l-2.8,71.9l147.6,6.1v-76.1C371.3,180.6,322.9,180.2,274.4,180.4L274.4,180.4z"
          />
          <path
            fill="#FFFFFF"
            d="M415.2,164.9c0,0.3,0.4,2.9-0.1,5.4L415.2,164.9L415.2,164.9z M310.3,162.8c-68.2,0-140.6,2.1-146,2.3
	c-8.5,0.6-14.3,2.5-17.9,5.2l-0.8,0.8h-0.1l-0.1,0.1h0.4c2.5,0,4.8,0,7.2,0.1c16.4-0.8,56.8-2.5,101.9-3c69.5-1,138.4,1.2,176.4,2.9
	c16.5,0.7,27.1,1.2,29.3,1.4c0.1,0,0.1-0.3,0.3-0.6h-0.1c-13.5-5.8-19.3-6.2-34.1-6.7c-1.9-0.1-5.9-0.3-11.4-0.4
	C394.8,164.1,353.3,162.8,310.3,162.8L310.3,162.8z M182.3,164.6l-0.4,5.1C181.4,168.3,181.6,166.1,182.3,164.6L182.3,164.6z"
          />
          <path
            fill="#EAEBEB"
            d="M252.3,176.2C252.3,176.2,252.2,176.2,252.3,176.2c-4,0-50.6,0-76.5,2.2c-2.2,0.1-4.3,0.4-6.2,0.6
	c-28.6,3-45.5,8.5-63.4,30.5c-11.3,13.9-21.5,37-21.5,37s-0.3,0.8-0.3,1.8c10.3,0.4,50.9,1.5,99.5,2.1c18,0.1,36.7,0.7,57.4,1.2h1.2
	c0.1-0.1,0.1-0.1,0.3-0.1c1.4-35.9,7.6-63,8.7-70.2C251.6,179.5,251.9,177.7,252.3,176.2L252.3,176.2z"
          />
          <path
            fill="#5E5E5E"
            d="M120.9,391.5c0,2.2,0.1,4.5,0.4,6.6c3.3,27.6,27,49,55.4,49c30.8,0,55.7-25,55.7-55.9s-25-55.7-55.9-55.6
	C145.7,335.7,120.8,360.6,120.9,391.5L120.9,391.5z"
          />
          <path
            fill="#EAEBEB"
            d="M138.8,391.5c0,21.2,17.3,38.4,38.5,38.4s38.4-17.2,38.4-38.5c0-21.2-17.2-38.4-38.5-38.4
	C156,353.1,138.8,370.3,138.8,391.5L138.8,391.5z"
          />
          <path
            fill="#5E5E5E"
            d="M134.3,327.9c-21,14.3-24.5,38.5-25.6,51.7c-0.8,10.7,0.6,15.7,0.8,17.5c0.1,0.3,0.1,0.5,0.3,0.8l11.4,0.3
	c-0.3-2.2-0.4-4.4-0.4-6.6c0-30.8,24.9-55.9,55.7-55.9s55.9,24.9,55.9,55.6c0,3.9-0.4,7.6-1.1,11.3h16.1c-0.1-1.6-0.3-6.7,0.3-7.8
	c-0.1-3.7-0.6-11-1.5-16.8c-3.6-24.5-15.8-61.2-68-61.4C158.5,316.6,144.4,321.2,134.3,327.9L134.3,327.9z"
          />
          <path
            fill="#EAEBEB"
            d="M96.2,186.9l-0.7-2.3l-0.3,0.6c-3,3-13.6,13.6-24.8,31.2c-7,11-14.3,24.9-20.2,41.5l-0.5,1.5h2.5
	c6.9-21.9,20.1-43.2,30.3-56.5C90,192.7,96.2,186.9,96.2,186.9L96.2,186.9z"
          />
          <path
            fill="#EAEBEB"
            d="M41.4,291l-0.3,1.9c-1.4,8-2.3,16.4-2.8,25.5h-0.1c0.1,0.1,0.3,0.1,0.3,0.1c2.1,0,14.3,0,22.1-0.3
	c1.1,0,2.2,0,3-0.1c1.4-35.2,7.7-48.6,9.4-53.9s-1-5.5-3.2-5.4c-0.6,0-2.2,0.1-4.4,0.1c-3.9,0-9.5,0.1-13.2,0.1h-2.5
	c-0.3,0.7-0.5,1.4-0.7,2.2c-2.1,6.1-3.9,12.2-5.4,18.8l-0.7,2.9C42.4,285.8,41.8,288.3,41.4,291L41.4,291z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M526,184c-31.1-10.6-52.4-11.3-65.3-12.1c-13.5-5.8-19.3-6.2-34.1-6.7
	c-8.9-0.3-61.6-2.5-116.2-2.5c-68.2,0-140.6,2.1-146,2.3c-9.8,0.6-15.4,2.5-18.7,5.9c-18.4,0.6-40.9,3.9-46.8,5.5
	c-5.9,1.6-4,5.1-3.7,8.4c-5,5-29.7,29.6-45,72.8c-0.1,0.5-0.4,1.1-0.5,1.5c-5.9,17.1-10,35.8-11.3,59c-7.7,2.8-16.8,5.6-16.8,8.7
	c0,3-1.6,42.8,0,47.2c1.1,1.6,2.6,3.6,2.6,3.6c-0.1,0.5-0.1,9.8,4.8,11.4c6.6,2.2,46.9,8,81,11.1c0.6-1.1-0.1-2.2-0.4-3.4
	c-0.3-1.8-1.7-6.7-0.8-17.5c1.7-19.4,8.4-63.1,69.5-63c52,0.1,64.4,36.9,68,61.4c0.8,5.8,1.4,13.2,1.5,16.8c-0.6,1.1-0.3,6.3-0.3,8
	c0.1,2.6,0.1,3.4,5.4,3.6c4.8,0.1,364.7,0.5,368.7,0.5c2.5,0,3-1,2.9-4.8c-0.1-2.9-7.4-84.1,70.3-84.3c62.7-0.1,68.1,56.3,67,78.6
	c-0.1,1.8-0.7,4-1.4,5.5c-0.5,1-0.4,2.6-0.4,2.6s-0.7,0.1-1.1,0.1c0,0-0.1,6.2,0,7.7c0.1,1.8,0,2.1,2.6,1.9
	c4.7-0.1,34.5-1.6,37.1-2.5c-0.3-2.9,0-8.3,0-8.3s2.5-0.3,4.4-0.4c3-0.3,6.1-0.5,11.3-4.3c1.1-0.8,2.1-2.3,1.9-3.3
	c-0.1-1.1-0.3-1.1-1.5-1.1c-1.1-0.4-0.8-4.5,0-7.6c1.5-5.4,2.8-12.4,8.7-13.5c-0.1-1.8-0.5-12.8-1.1-21.5c0.3-0.8,0.4-5.9-0.1-6.2
	c-0.1-1.8-1.2-5.5-1.5-6.3c-1.4-4.8-11.1-19.8-18.8-30.8c-7.8-11-11.6-12.1-18-16c-27.9-16.8-102.8-30-117.1-32.9
	c-4.8,0.1-8-0.4-11.3-1.2C584.6,210.8,547.4,191.3,526,184L526,184z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M432.8,394.6c0.3-94.9,0.8-131,0.8-132.9c0-1.9-0.7-4.8-0.7-4.8l-1.7-85.8
	 M624,396.3c0,0-4.4-0.8-11-0.8c-60.1-0.8-365.5-0.7-365.5-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M597.3,407.1c1.9-2.5,3.9-5.6,3.9-11.7c1.9-5.6,3.7-12.2,5.5-21.6
	c-0.4-1.4,0-4.5,0.8-5.4c3.2-18.7,5.6-40,6.3-52.7c1-20.4-1.1-38.1-2.8-43.5c-0.3-2.5-1-4-1-4s-1.8-20.1-2.1-21.3
	c-0.3-1.2-0.3-6.2-0.3-6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M611.3,371.1c2.3,1.5,6.2,3,6.2,3L254.1,369c0,0,3.3-1.2,6.2-2.6
	c2.5-1.1,4.7-2.3,4.7-2.3l342.7,4.5C607.6,368.6,609.5,369.9,611.3,371.1L611.3,371.1z M644.5,275.5c-3.2,0.1-95.3-8.1-211.2-12.8
	c-64.7-2.6-132.1-5.8-192-7.4c-74.6-2.1-134.1-2.6-150.2-3c-5-0.1-6.3-1-6.6-3.2c-0.1-1.4,0.3-2.5,0.3-2.5s10.2-23.2,21.5-37
	c19.3-23.7,37.1-28.3,69.7-31.1c26-2.3,72.5-2.2,76.5-2.2c95.6,0.1,148.6,0.4,179.1,1.8c27.9,1.2,37.6,1.9,48.4,3.4
	c32.2,4.7,56,15.1,65.6,20.4c56.7,30.7,101.1,68.4,102.5,69.7C649,272.7,648.9,275.4,644.5,275.5L644.5,275.5z M38.1,318.7
	c0,0,17.7,0,25.5-0.4c1.4-35.2,7.7-48.6,9.4-53.9c1.7-5.4-1-5.5-3.2-5.4c-2.3,0.1-19.3,0.3-19.9,0.3 M134.4,327.9
	c0,0-54.5-1.4-63.4-1.4c-9.1,0-7.4-8-7.4-8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M96.4,399c-3.3-14,1-34.1,1-34.1s-2.3-3.7-5.2-5.8
	c5.6-27.2,23.7-63.8,87.1-63.7c59.8,0.1,78.8,38.1,85.6,68.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M103.9,399.9c0,0.3-1.8-7.8-0.8-19.7c1.7-21.3,9.1-69.3,75-69.2
	c56.1,0.1,69.5,40.4,73.2,67.4c0.8,5.9,1.2,12.4,1.4,16.4 M759.6,404.8c0.7,0,38.4-1.9,38.4-1.9 M765.7,404.1c0.4-2.3,0.8-5.5,1-8.5
	c0.7-18.3-1-31.5-7.2-46.6c-7.2-17.6-26.8-37.3-65.3-37.6c-70.4-0.5-75.9,62.3-75.8,83.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M769.7,404c1-16.2-0.1-30.8-0.1-30.8s4.3-2.5,8-3.9
	c-5-31.8-24.3-71.7-84.1-71.8c-39.2,0-64.1,17.2-75.7,42.2c-2.5,5.5-7.3,15.8-10.3,28.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M769.5,373.3c1.8,0.1,30.4,0.4,37.7,0.1c7.3-0.1,13.1-1.2,9.2-1.5
	c-14.9-0.8-39.1-2.8-39.1-2.8 M23.8,377.6c0,0,8,0.4,14.3,0.5c6.5,0.1,6.5-1.6,0.3-2.1c-5-0.4-16.8-1.9-16.8-1.9 M647.8,271.4
	c0,0-98.6-7.7-214.5-12.2c-64.7-2.6-132.1-5.8-192-7.4c-20.8-0.6-39.3-1.1-57.4-1.2c-56-0.6-98.4-1.9-99.5-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M419.4,258.4c0,0,0.1-70.4-0.1-75.9c-27.5-1.1-71.5-1.8-144.9-1.9
	c-1,15.5-2.6,61.6-2.8,72.2 M447.5,259.8c0,0-2.3-41-3-76.2c5.4,0.3,19.1,1,27.4,2.1c34.9,4.4,59.4,14.7,69.5,19.9
	c46.2,24.4,84.5,53.9,98.1,64.9 M587.4,238.1l-0.1-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M607.2,253.1c-1.7-6.9-2.8-7.4-4.7-8.7c-1.5-1.1-4.3-2.9-6.7-4.4
	s-5-2.2-7.8-1.9c-1.4,0.1-3,1-3.6,3.9c-1,5.2-1.7,11.8-1.9,14.3c-0.3,2.5,0,5.6,4.4,6.2s5.5,1,6.3,3c0.5,1.2,6.2,1.1,7,1.1
	c1.9-0.1,5.2-1.1,7-2.5C608.6,263,608.2,257.6,607.2,253.1L607.2,253.1z M213.9,282.5c-0.4,6.5-5.9,11.4-12.2,11
	c-6.5-0.4-11.4-5.9-11-12.2c0.4-6.5,5.9-11.4,12.2-11C209.4,270.5,214.3,276,213.9,282.5L213.9,282.5z M458.6,278.7
	c1.1-1.5,2.8-2.1,4.7-2.1l7.8,0.1c2.3,0,4.1,1,5.2,2.9 M476.9,286.8c-0.7,2.8-3,4.4-5.9,4.4l-7.8-0.1c-3,0-5.5-2.2-6.1-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M481.7,286.9c-2.6,0.1-6.2-0.5-6.2-0.5s-1.1-0.8-1.6-1
	c-1.9-0.1-9.6-0.4-14.3-0.3c-0.3,0.3-1.1,0.5-1.1,0.5h-10.6c-2.9,0-5.4,0.4-5.4-3.4c0-3.2,1.1-4.1,3.9-4.1s28.3,1,35.2,2.1
	C485.7,281,484.5,286.8,481.7,286.9L481.7,286.9z M406.6,276.6c-1-1.8-3-2.5-5.1-2.5h-7.8c-2.3,0-3.9,0.8-4.8,2.5 M387.9,283.6
	c0.5,3.2,3,5.2,6.1,5.1h7.8c3-0.1,5.4-2.1,5.9-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M383.8,283.5c2.8,0.3,6.1-0.1,6.1-0.1s1.2-0.8,1.6-0.8
	c2.1,0,9.4,0.1,14,0.4c0.3,0.3,1.1,0.7,1.1,0.7s9.2,0.7,11.6,0.7c3,0.1,5.5,0.7,5.6-3c0.1-3.2-0.8-4.1-3.6-4.4
	c-2.8-0.1-28.9-0.7-36,0C380.1,277.3,381.1,283.2,383.8,283.5L383.8,283.5z M788,294.9c-4.1-1.8-15.7-2.3-21.6-2.2
	c-1.1,0-1.8,1.1-1.7,1.5c1.8,4.4,5.6,12.7,9.6,19.7c24.2,14.9,32.7,17.1,41.8,16.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M745.3,337.4c0,0,28.5,1.6,34.7,1.4c6.2-0.1,4.7-1.9,3.7-4.5
	s-9.1-20.2-9.1-20.2 M822.1,351.7c0,0-28.9,0.3-36.6,0c-2.3-0.1-2.5,0.3-3.2-1c-0.4-1-1.2-2.6-1.7-3.9c-0.7-1.9-0.1-1.6,2.1-1.6
	c8.5,0,38.9,0.1,38.9,0.1 M171,178.9c-9.4,0.7-17.3,1.2-28.2,3.4c-22.6,4.4-37.6,20.8-47.9,35.1c-4.7,6.5-15.5,24.2-18.4,32.3
	c-2.5,7-2.3,10,2.6,10.2c8.7,0.3,347.6,9.9,354.4,10.2c6.7,0.3,216.1,12.4,280.6,18.3c18.7,1.6,44,4,50.8,5.9 M645.5,275.5
	c93,5.5,120.9,17.3,120.9,17.3 M598.7,342.5c-0.3,0.8-0.5,1.9-0.8,2.8c-0.4,1.2-1.1,1-3.3,1c-12.1,0-85.2-0.4-161.4-1.5
	c-72.8-1-146-2.3-159.7-2.8c-3-0.1-3.4,0-3.9-1.2c-0.4-1-1-2.3-1.4-3.3c-0.7-1.6-0.3-1.6,1.8-1.6c11.7-0.1,87.1-0.1,162.9,0.8
	c77.3,1.1,154.2,3.9,163.8,4.1C598.1,341,599.2,340.8,598.7,342.5L598.7,342.5z M97.5,364.9c0,0-47-3.2-63.3-3.9
	c-10.9-0.4-10.5-1.4-1.5-1.5c5.1-0.1,59.4-0.5,59.4-0.5 M68.5,326.5l-46.8-0.1 M21.3,339.7c0,0,56.3-0.3,67.5-0.3
	c2.5,0,3.2,0.5,4.1-1.2c0.6-1.1,1.4-2.8,2.1-3.9c0.8-1.6,0.1-1.4-1.7-1.4c-9.8-0.1-71.8,0.1-71.8,0.1 M782.2,338.8l38.2,0.4
	 M799,327.2c0,0,4.1-1.1,5.8-4.7c1.7-3.6-3.7-14.6-8.3-20.5 M586.3,266.7v-4.1 M634.4,260.6l7.4-8 M747.5,403.3
	c7.2-0.1,12.4-0.1,12.4-0.1 M121.6,398.2l-11.4-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M254.2,168.6c0,0-1.8,5.6-2.9,12.8s-7.3,34.3-8.7,70.2
	c-1.1,0.7-1.6,2.5-1.5,4c-1.1,1.7-2.8,32.6-2.5,46.9c0,2.9,0.7,7.8,4.8,12c8.7,8.5,18.8,21.2,25,49.7c-0.1,1.6,0.3,3.3,1,5.1
	c1.2,7.2,2.2,15.8,3,25.2 M624.4,403l14.3,1.1 M231.7,402.6h15.5 M41.3,291h14.2l1.1-7.8H42 M40.4,300.7h13.9l0.8-7.7H40.9
	 M96.3,186.8c11.7-11.3,27.2-14.2,39.5-15.1 M243.9,251.6c1.2-36.3,9.9-75.2,9.9-75.2 M779.2,316.9c3.9,0.3,4.3-0.5,4.1-2.3
	c-0.1-1.8-0.8-9.4-1.7-13.5c-0.8-4.5-1.1-5.8-3.2-6.2c-2.8-0.5-9.9-0.7-10.9-0.4c-1.5,0.3-1.8,2.6-1.8,2.6 M815.6,329.7
	c-1.9-1.4-9.1-6.5-10.7-7.7 M415,170.1c0.5-2.5,0.1-5.4,0.1-5.4 M181.9,169.7c-0.4-1.4-0.3-3.6,0.4-5.1 M607.5,263.1
	c-1.8-2.3-4.4-3.9-7.4-4.4c-3.2-0.5-5.2-0.8-11.8-1.8c-2.8-0.4-4-0.8-3.4-5.4c0.3-2.2,1-7.2,1.7-10c0.6-2.2,1.5-3.3,1.5-3.3
	 M232.5,391.3c0,30.8-24.9,55.9-55.7,55.9c-30.8,0-55.9-24.9-55.9-55.6c0-30.8,24.9-55.9,55.7-55.9
	C207.3,335.6,232.3,360.5,232.5,391.3z M449.6,282.1c0,1.2-0.8,2.2-1.9,2.2c-1.1,0-1.9-1-1.9-2.2s0.8-2.2,1.9-2.2
	C448.6,280,449.6,281,449.6,282.1z M452.2,285.5c-0.4-1.2,0-6.1,0.1-7 M413.2,283.9c-0.3-1.8-0.1-7-0.1-7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M145.3,171.8c0,0,50.9-2.6,109.6-3.4c103.4-1.4,206.1,4.3,206.1,4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M253.7,171.2c-46.8,0.7-63.7,0.8-91.6,3.2c-21,1.8-32.7,5.4-41.7,9.6
	c-10.9,5.2-20.4,13.9-32.2,30.7c-15.8,22.3-21.2,39.8-23,44.7c-1.1,3-5.5,18-7.6,31.5c-2.2,14.3-2.2,27-2.2,27 M655.4,258.8
	c-9.2-2.1-14.6-7.3-19.7-10.7c-7-4.5-50.3-35.4-95.6-57.4c-3-1.5-7.4-3.7-13.3-6.2 M790.3,303c0.1-0.5,0.7-1.6,1.9-1.4
	c1.4,0.3,2.5,4.8,2.5,8v0.4 M793.6,317.6c-0.3,0.8-1.1,1.6-1.7,1.6c-1,0-2.1-1.2-2.2-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M793.5,317.7c0.3,1.8,1,2.5,1.8,2.5c1.1,0,2.1-2.3,2.1-5.2
	c0-2.9-0.8-5.5-1.9-5.5c-0.3,0-0.5,0.1-0.8,0.4 M789.9,315.4c-0.5,2.9-1.5,3.4-2.5,3.4c-1.7,0-2.9-5-2.9-11s1.4-11,3-11
	c1.2,0,2.2,1.9,2.6,5.9 M64.2,262.3c0-1-0.7-1.2-3.2-1.2c-4.5,0-12.1,0.4-12.1,0.4 M43.2,280.3h13.2c2.3,0,3.4-1.5,3.4-1.5
	 M59.6,271.1c-1.2,4.4-4.8,7.7-7.7,7.6c-3-0.3-4.3-4-3-8.4c1.2-4.4,4.8-7.7,7.7-7.6C59.6,263,60.8,266.7,59.6,271.1L59.6,271.1z
	 M95.5,184.6l0.7,2.3c0,0-6.1,5.8-13.8,16c-10.2,13.5-23.4,34.3-30.1,56.3 M747.9,391.3c0,30.8-24.9,55.9-55.7,55.9
	s-55.9-24.9-55.9-55.6c0-30.8,24.9-55.9,55.7-55.9C722.9,335.6,747.9,360.5,747.9,391.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M731.3,391.3c0,21.2-17.2,38.4-38.4,38.5c-21.2,0-38.4-17.1-38.5-38.4
	c0-21.2,17.2-38.4,38.4-38.5C714,352.9,731.1,370.1,731.3,391.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M697.6,372.3c-0.1-1.4-0.3-4.4-0.3-10.2c0-1.1,0-1.6,2.1-1.2
	c2.8,0.5,4.8,1.4,7.4,2.6c1.8,1,1.9,1.4,1,2.5c-3.4,4.5-5.6,6.6-6.6,7.6C699,376,698.1,375.9,697.6,372.3L697.6,372.3z M684.2,373.6
	c-1-1-3-3.2-6.7-7.6c-0.7-0.8-1.1-1.2,0.8-2.3c2.5-1.4,4.5-2.1,7.4-2.8c1.9-0.5,2.3-0.1,2.3,1.2c0.3,5.6,0,8.7-0.1,10
	C687.7,375.8,686.9,376.1,684.2,373.6L684.2,373.6z M674.8,383.3c-1.4-0.1-4.4-0.5-10-1.5c-1.1-0.1-1.7-0.3-0.8-2.3
	c1-2.6,2.2-4.5,3.9-6.9c1.2-1.6,1.7-1.6,2.6-0.5c3.9,4.3,5.5,6.6,6.3,7.7C678.7,382.8,678.5,383.5,674.8,383.3L674.8,383.3z
	 M673.8,396.8c-1.1,0.8-3.7,2.5-8.7,5.4c-1,0.5-1.5,1-2.2-1.2c-0.8-2.6-1.2-5-1.4-7.8c-0.1-2.1,0.3-2.3,1.7-2.1
	c5.6,0.7,8.5,1.5,9.8,1.9C676.4,393.8,676.7,394.6,673.8,396.8L673.8,396.8z M681.6,407.8c-0.3,1.4-1.2,4.3-3.2,9.6
	c-0.4,1.1-0.5,1.6-2.5,0.4c-2.3-1.5-4.1-2.9-6.1-5.1c-1.4-1.5-1.2-1.9-0.1-2.8c4.8-3,7.6-4.3,8.8-4.8
	C681.8,403.7,682.5,404.3,681.6,407.8z M694.8,411.1c0.5,1.2,1.8,4,3.7,9.5c0.4,1.1,0.7,1.6-1.5,1.9c-2.8,0.4-5.1,0.4-7.8,0.1
	c-2.1-0.3-2.2-0.7-1.8-2.1c1.8-5.4,3-8.1,3.6-9.4C692.2,408,693.2,407.8,694.8,411.1L694.8,411.1z M706.9,405.2
	c1.2,0.5,4,1.9,8.9,4.8c1,0.5,1.5,0.8,0,2.5c-1.8,2.1-3.6,3.6-6.1,5.1c-1.7,1.1-2.2,1-2.8-0.4c-2.2-5.2-2.9-8.1-3.3-9.5
	C703,404.4,703.6,403.8,706.9,405.2L706.9,405.2z M712.4,392.8c1.2-0.4,4.3-1.1,9.9-2.1c1.1-0.1,1.7-0.4,1.7,1.9
	c-0.1,2.8-0.5,5-1.4,7.8c-0.5,1.9-1.1,2.1-2.3,1.4c-5.1-2.6-7.4-4.4-8.5-5.1C709,394.8,709,393.9,712.4,392.8L712.4,392.8z
	 M708.9,379.9c0.7-1.1,2.6-3.6,6.3-8c0.7-0.8,1.1-1.4,2.5,0.4c1.8,2.2,2.9,4.1,4,6.9c0.8,1.9,0.5,2.3-0.8,2.6
	c-5.5,1.2-8.5,1.4-9.9,1.5C707.4,383.6,706.8,382.9,708.9,379.9L708.9,379.9z M695.2,380.2c0.8,1.4,0.4,3-1,3.9
	c-1.4,0.8-3,0.4-3.9-1c-0.8-1.4-0.4-3,1-3.9C692.6,378.4,694.4,378.8,695.2,380.2L695.2,380.2z M682.9,385.5c1.5-0.4,3,0.5,3.3,2.1
	c0.4,1.5-0.5,3-2.1,3.3c-1.5,0.3-3-0.5-3.3-2.1C680.4,387.5,681.4,386,682.9,385.5L682.9,385.5z M684.2,398.9c0.1-1.5,1.5-2.6,3-2.6
	c1.5,0.1,2.6,1.5,2.6,3c-0.1,1.5-1.5,2.6-3,2.6C685.3,401.8,684.1,400.4,684.2,398.9L684.2,398.9z M697.5,401.6
	c-1.4-0.5-2.1-2.2-1.5-3.6c0.5-1.4,2.2-2.1,3.6-1.5s2.1,2.2,1.5,3.6C700.6,401.6,699,402.3,697.5,401.6z M704.2,390.1
	c-1,1.1-2.8,1.2-4,0.3c-1.1-1-1.2-2.8-0.3-3.9c1-1.2,2.8-1.2,3.9-0.3C705,387.2,705.2,389,704.2,390.1L704.2,390.1z M691.7,386.5
	c2.8-0.7,5.5,1.1,6.1,3.7c0.7,2.8-1.1,5.5-3.7,6.1c-2.8,0.7-5.5-1.1-6.1-3.7C687.3,389.8,688.9,387.1,691.7,386.5L691.7,386.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.1,375.4c-0.1,2.8-1.7,3.3-3.6,1.2c-0.8-0.8-11.7-13.6-12.5-14.9
	c-0.7-1-0.7-1.4,0.3-2.1c4.3-2.9,9.9-4.4,14.2-5.2c1.8-0.3,2.1,0.3,1.9,1.6C689.1,356.8,689.2,371.4,689.1,375.4L689.1,375.4z
	 M679.6,381.6c1.7,2.2,0.8,3.6-1.9,3.3c-1.2-0.1-17.7-2.9-19.1-3.3c-1.2-0.3-1.5-0.5-1.1-1.6c1.4-5,4.8-9.8,7.6-13.1
	c1.1-1.4,1.7-1.1,2.5,0C667.7,367.2,677.2,378.4,679.6,381.6L679.6,381.6z M676.4,392.4c2.8,0.7,3,2.2,0.5,3.7
	c-1,0.7-15.4,9.1-16.8,9.8c-1.1,0.5-1.5,0.5-1.9-0.5c-2.1-4.7-2.6-10.6-2.6-14.9c0-1.8,0.5-1.9,1.9-1.6
	C658.1,389.1,672.5,391.5,676.4,392.4L676.4,392.4z M680.8,402.7c2.5-1.2,3.7-0.3,2.9,2.5c-0.4,1.1-5.9,16.9-6.6,18.3
	c-0.4,1.1-0.8,1.4-1.9,0.8c-4.5-2.3-8.8-6.3-11.6-9.6c-1.1-1.4-0.8-1.8,0.4-2.5C664.7,412,677.4,404.5,680.8,402.7L680.8,402.7z
	 M691,407.8c1.1-2.6,2.6-2.6,3.9,0.1c0.4,1.1,6.3,16.8,6.7,18.3c0.4,1.1,0.3,1.5-0.8,1.8c-5,1.2-10.9,0.8-15,0
	c-1.8-0.3-1.8-1-1.2-2.2C684.5,425.3,689.3,411.4,691,407.8L691,407.8z M701.8,405.2c-0.8-2.6,0.4-3.7,2.9-2.3
	c1.1,0.5,15.5,8.8,16.9,9.6c1,0.7,1.2,1.1,0.5,1.9c-3,4.1-7.8,7.6-11.6,9.6c-1.5,0.8-1.9,0.4-2.3-0.8
	C708.2,422.7,703.1,408.9,701.8,405.2L701.8,405.2z M708.6,396.1c-2.3-1.5-2.1-3,0.7-3.7c1.1-0.3,17.6-3.3,19.1-3.4
	c1.2-0.1,1.5,0,1.7,1.2c0.3,5.1-1.1,10.9-2.6,14.9c-0.5,1.6-1.2,1.6-2.3,0.8C724.7,405.5,711.9,398.3,708.6,396.1L708.6,396.1z
	 M708,384.9c-2.8,0.3-3.6-1.1-1.8-3.3c0.7-1,11.4-13.9,12.4-15c0.8-0.8,1.2-1,2.1-0.1c3.6,3.7,6.1,9.1,7.6,13.1
	c0.6,1.6,0.1,2.1-1.2,2.2C726.3,381.7,711.9,384.4,708,384.9L708,384.9z M700.2,376.6c-1.9,2.1-3.4,1.5-3.6-1.4
	c0-1.2-0.1-17.9-0.1-19.4c0-1.2,0.3-1.5,1.5-1.4c5.1,0.5,10.5,3,14.2,5.1c1.5,0.8,1.4,1.5,0.4,2.5
	C712.2,362.4,702.9,373.7,700.2,376.6L700.2,376.6z M215.7,391.3c0,21.2-17.2,38.4-38.4,38.5c-21.2,0-38.5-17.1-38.5-38.4
	c0-21.2,17.2-38.4,38.4-38.5C198.4,352.9,215.6,370.1,215.7,391.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M182,372.3c-0.1-1.4-0.3-4.4-0.3-10.2c0-1.1-0.1-1.6,2.1-1.2
	c2.8,0.5,4.8,1.4,7.4,2.6c1.8,1,1.9,1.4,1,2.5c-3.4,4.5-5.6,6.6-6.6,7.6C183.4,376,182.5,375.9,182,372.3L182,372.3z M168.7,373.6
	c-1-1-3-3.2-6.7-7.6c-0.7-0.8-1.1-1.2,0.8-2.3c2.5-1.4,4.5-2.1,7.4-2.8c1.9-0.5,2.3-0.1,2.3,1.2c0.3,5.6,0,8.7-0.1,10
	C172.1,375.8,171.3,376.1,168.7,373.6L168.7,373.6z M159.2,383.3c-1.4-0.1-4.4-0.5-10-1.5c-1.1-0.1-1.7-0.3-0.8-2.3
	c1-2.6,2.2-4.5,3.9-6.9c1.2-1.6,1.6-1.6,2.6-0.5c3.9,4.3,5.5,6.6,6.3,7.7C163.1,382.8,162.9,383.5,159.2,383.3L159.2,383.3z
	 M158.2,396.8c-1.1,0.8-3.7,2.5-8.7,5.4c-1,0.5-1.5,1-2.2-1.2c-0.8-2.6-1.2-5-1.4-7.8c-0.1-2.1,0.3-2.3,1.6-2.1
	c5.6,0.7,8.5,1.5,9.8,1.9C160.8,393.8,161.1,394.6,158.2,396.8L158.2,396.8z M166,407.8c-0.3,1.4-1.2,4.3-3.2,9.6
	c-0.4,1.1-0.6,1.6-2.5,0.4c-2.3-1.5-4.1-2.9-6.1-5.1c-1.4-1.5-1.2-1.9-0.1-2.8c4.8-3,7.6-4.3,8.8-4.8
	C166.2,403.7,166.9,404.3,166,407.8L166,407.8z M179.1,411.1c0.6,1.2,1.8,4,3.7,9.5c0.4,1.1,0.7,1.6-1.5,1.9
	c-2.8,0.4-5.1,0.4-7.8,0.1c-2.1-0.3-2.2-0.7-1.8-2.1c1.8-5.4,3-8.1,3.6-9.4C176.6,408,177.6,407.8,179.1,411.1L179.1,411.1z
	 M191.4,405.2c1.2,0.5,4,1.9,8.9,4.8c1,0.5,1.5,0.8,0,2.5c-1.8,2.1-3.6,3.6-6.1,5.1c-1.6,1.1-2.2,1-2.8-0.4
	c-2.2-5.2-2.9-8.1-3.3-9.5C187.4,404.4,188,403.8,191.4,405.2L191.4,405.2z M196.9,392.8c1.4-0.4,4.3-1.1,9.9-2.1
	c1.1-0.1,1.6-0.4,1.6,1.9c-0.1,2.8-0.6,5-1.4,7.8c-0.6,1.9-1.1,2.1-2.3,1.4c-5.1-2.6-7.4-4.4-8.5-5.1
	C193.3,394.8,193.4,393.9,196.9,392.8L196.9,392.8z M193.3,379.9c0.7-1.1,2.6-3.6,6.3-8c0.7-0.8,1.1-1.4,2.5,0.4
	c1.8,2.2,2.9,4.1,4,6.9c0.8,1.9,0.6,2.3-0.8,2.6c-5.6,1.2-8.5,1.4-9.9,1.5C191.8,383.6,191.2,382.9,193.3,379.9L193.3,379.9z
	 M179.7,380.2c0.8,1.4,0.4,3-1,3.9c-1.2,0.8-3,0.4-3.9-1c-0.8-1.4-0.4-3,1-3.9C177,378.4,178.8,378.8,179.7,380.2L179.7,380.2z
	 M167.3,385.5c1.5-0.4,3,0.5,3.3,2.1c0.4,1.5-0.6,3-2.1,3.3s-3-0.5-3.3-2.1C164.8,387.5,165.8,386,167.3,385.5L167.3,385.5z
	 M168.7,398.9c0.1-1.5,1.5-2.6,3-2.6c1.5,0.1,2.6,1.5,2.6,3c-0.1,1.5-1.5,2.6-3,2.6C169.8,401.8,168.5,400.4,168.7,398.9z
	 M181.9,401.6c-1.4-0.5-2.1-2.2-1.5-3.6c0.6-1.4,2.2-2.1,3.7-1.5c1.4,0.5,2.1,2.2,1.5,3.6C184.9,401.6,183.4,402.3,181.9,401.6
	L181.9,401.6z M188.6,390.1c-1,1.1-2.8,1.2-4,0.3c-1.1-1-1.2-2.8-0.3-3.9c1-1.2,2.8-1.2,3.9-0.3C189.4,387.2,189.6,389,188.6,390.1
	L188.6,390.1z M176.1,386.5c2.8-0.7,5.5,1.1,6.1,3.7c0.7,2.8-1,5.5-3.7,6.1c-2.8,0.7-5.5-1.1-6.1-3.7
	C171.7,389.8,173.3,387.1,176.1,386.5L176.1,386.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M173.3,375.4c-0.1,2.8-1.6,3.3-3.6,1.2c-0.8-0.8-11.7-13.6-12.5-14.9
	c-0.7-1-0.8-1.4,0.3-2.1c4.3-2.9,9.9-4.4,14.2-5.2c1.8-0.3,2.1,0.3,1.9,1.6C173.5,356.8,173.6,371.4,173.3,375.4L173.3,375.4z
	 M164,381.6c1.6,2.2,0.8,3.6-1.9,3.3c-1.2-0.1-17.7-2.9-19.1-3.3c-1.2-0.3-1.5-0.5-1.1-1.6c1.4-5,4.8-9.8,7.6-13.1
	c1.1-1.4,1.6-1.1,2.5,0C152.1,367.2,161.6,378.4,164,381.6z M160.8,392.4c2.8,0.7,3,2.2,0.6,3.7c-1,0.7-15.4,9.1-16.8,9.8
	c-1.1,0.5-1.5,0.5-1.9-0.5c-2.1-4.7-2.6-10.6-2.6-14.9c0-1.8,0.6-1.9,1.9-1.6C142.5,389.1,157,391.5,160.8,392.4L160.8,392.4z
	 M165.2,402.7c2.5-1.2,3.7-0.3,2.9,2.5c-0.4,1.1-5.9,16.9-6.6,18.3c-0.4,1.1-0.8,1.4-1.9,0.8c-4.5-2.3-8.8-6.3-11.6-9.6
	c-1.1-1.4-0.8-1.8,0.4-2.5C149.1,412,161.8,404.5,165.2,402.7L165.2,402.7z M175.4,407.8c1.1-2.6,2.6-2.5,3.7,0.1
	c0.4,1.1,6.3,16.8,6.7,18.3c0.4,1.1,0.3,1.5-0.8,1.8c-5,1.2-10.9,0.8-15,0c-1.8-0.3-1.8-1-1.2-2.2
	C168.9,425.3,173.7,411.4,175.4,407.8L175.4,407.8z M186.3,405.2c-0.8-2.6,0.4-3.7,2.9-2.3c1.1,0.5,15.5,8.8,16.9,9.6
	c1,0.7,1.2,1.1,0.6,1.9c-3,4.1-7.8,7.6-11.6,9.6c-1.5,1-1.9,0.4-2.3-0.8C192.6,422.7,187.5,408.9,186.3,405.2L186.3,405.2z
	 M193,396.1c-2.3-1.5-2.1-3,0.7-3.7c1.1-0.3,17.6-3.3,19.1-3.4c1.2-0.1,1.5,0,1.6,1.2c0.3,5.1-1.1,10.9-2.6,14.9
	c-0.6,1.6-1.2,1.6-2.3,0.8C209.1,405.5,196.3,398.3,193,396.1L193,396.1z M192.3,384.9c-2.8,0.3-3.6-1.1-1.8-3.3
	c0.7-1,11.4-13.9,12.4-15c0.8-0.8,1.2-1,2.1-0.1c3.6,3.7,6.1,9.1,7.6,13.1c0.6,1.6,0.1,2.1-1.2,2.2
	C210.7,381.7,196.3,384.4,192.3,384.9L192.3,384.9z M184.6,376.6c-1.9,2.1-3.4,1.5-3.6-1.4c0-1.2-0.1-17.9-0.1-19.4
	c0-1.2,0.3-1.5,1.5-1.4c5.1,0.5,10.5,3,14.2,5.1c1.5,0.8,1.4,1.5,0.4,2.5C196.6,362.4,187.4,373.7,184.6,376.6z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M777,338.9
		l-32.7-1.5c0,0-10.9-14-31.6-17.9c0,0-27.2-6.3-51.4,5.1c0,0-15.5,6.3-25,22.7c0,0-11.7,16-12.1,49c0,0-3.3-1-15.7-1h-6.9
		c0,0,3.7-11.3,5.4-21.3c0,0-0.7-3.4,0.8-5.4c0,0,6.5-37.1,6.6-62.3c0,0,0.4-19.3-2.9-33.2l32.7,2.5c0,0,4,0.3,4.4-2.2
		c0,0,0.3-1.1-1.1-2.3l-12.8-10.5l7.4-7.8c0,0,9.8,6.9,16,6.2c0,0,3.7,1,8.5,0.7c0,0,34.3,6.1,64,13.9c0,0,37.6,8.9,56.5,21
		c0,0-9.4-1.9-20.6-1.7c0,0-1.4,0.1-1.7,1.5c0,0,4.4,10.3,9.6,19.7l0.6,0.8c0,0,6.1,12.7,9.5,21.5c0,0,1,1.8-1.4,2.3
		C783,338.5,781.9,339,777,338.9z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M601.3,395.2
		c0,0,3.9-11.4,5.4-21.3c0,0-1-3.6,0.8-5.4c0,0,6.1-35.1,6.5-59.3c0,0,1.1-17.2-2.8-36.2c0,0-119.4-8.9-177.7-10.3l-0.5,74
		l-0.3,57.9L601.3,395.2z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M432.8,394.6l0.3-57.9
		l0.6-74c0,0-179.7-7.8-192.5-7.3c0,0-1,0.7-1.9,23.2c0,0-0.8,20.1-0.6,24.6c0,0,0,5,3.6,9.6c0,0,1.4,1.5,2.3,2.5
		c0,0,5.6,5.9,8.4,10c0,0,5.4,7,10,19.5c0,0,2.8,6.7,5.4,19c0,0-0.4,1.5,1,5.1c0,0,2.1,12.2,3,25.6L432.8,394.6L432.8,394.6z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M593,265.6
		c0,0-0.6-2.2-3.7-2.6c0,0-1.8-0.3-2.6-0.4c0,0-2.6-0.3-3.6-1.8c0,0-1.1-1.2-0.7-4.3c0,0,0.8-6.6,1.7-13.3c0,0,0.3-4.1,3.2-4.8
		c0,0,2.6-0.7,5.8,0.6c0,0,1.4,0.4,3.7,1.9l6.3,4.1c0,0,1.4,0.7,2.1,2.3c0,0,2.5,5.2,2.9,13.1c0,0,0.1,2.6-0.8,3.7
		c0,0-2.1,2.1-7.2,2.6C600.2,266.7,594,267,593,265.6z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M586.3,267.1
		l0.1-4.7c0,0-4-0.3-4-5c0,0,0.7-7.2,1.9-15.4c0,0,0.6-3.2,3-3.7v-5.5c0,0-36-23.5-55.7-31.8c0,0-28.5-12.8-65.2-16.1
		c0,0-16.2-1.4-22-1.5c0,0,1.4,49,3,76.1l82.7,4.1L586.3,267.1z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M419.4,258.4
		v-42.9l-0.1-33.2c0,0-40.6-1.4-75.5-1.5l-69.3-0.4l-1.7,38.4l-1,33.7l31.1,1.1l64.1,2.6L419.4,258.4z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M247.6,394.8
		l24.8-0.1c0,0-1.4-16.5-3-25.6c0,0-1.2-3-1-5.1c0,0-3.2-16.9-10.6-30.4c0,0-3.4-7.8-14.7-19.7l-1-1.1c0,0-3.3-3.4-3.6-10.6l0.1-9.5
		c0,0,0.7-22.1,1.2-28.5c0,0,0.4-6.9,1.1-8.9c0,0-88.6-2.9-143.6-3c0,0-11.3,0.3-12.2-1.4l-6.9,8.8l-5.4,0.5c0,0,0.6,1.2,0,3
		c0,0,0,0.8-1.7,5.1c0,0-3.2,8-5.4,21.9c0,0-2.2,12.8-2.6,28.3c0,0-0.4,2.6,0.7,4.8c0,0,1.4,3,6.1,3.2h1.5l62.6,1.4
		c0,0,14.4-11.3,42.8-11.3c0,0,22.6-1.2,40,9.8c0,0,17.9,9.2,25.6,35.6C242.7,362,247.2,375.6,247.6,394.8z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M242.7,251.7
		c0,0,0.4-15,4-42.9c0,0,1.5-10.5,4.5-26.1c0,0,0.6-3.9,1.1-6.5c0,0-30.8-0.1-60.7,1.2c0,0-38.2,1.4-54.2,8.3c0,0-8.3,3-15.7,8.5
		c0,0-8.9,5.9-19.5,20.4c0,0-5.6,7.7-10.6,17.5c0,0-4.1,7.8-7.2,14.4c0,0-0.4,0.8-0.1,1.8l1.9,0.1c0,0,32.7,1,48.4,1.2l35.9,0.6
		C170.4,250.2,215.3,250.9,242.7,251.7z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="692.5"
              cy="391.5"
              r="55.7"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="177.2"
              cy="392"
              r="55.7"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(SportsVanRight);
