import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadReportSummary } from '../actions';
import DocumentTitle from 'react-document-title';
import ReportsNav from '../components/ReportsNav';
// import { Doughnut } from "react-chartjs-2";
import { getConfig } from '../utils/helpers';

const loadData = props => {
  props.loadReportSummary(getConfig('marketplaceId'));
};

export class Reports extends Component {
  componentDidMount() {
    loadData(this.props);
  }

  render() {
    const summaryData = {
      labels: ['Sold', 'If Sales', 'No Sales'],
      datasets: [
        {
          data: [43, 20, 20],
          backgroundColor: ['#96D321', '#FFCE56', '#FF6384'],
          hoverBackgroundColor: ['#96D321', '#FFCE56', '#FF6384'],
        },
      ],
    };

    const bidderData = {
      labels: ['Bidders Online', 'Bidders'],
      datasets: [
        {
          data: [43, 20],
          backgroundColor: ['#96D321', '#36A2EB'],
          hoverBackgroundColor: ['#96D321', '#36A2EB'],
        },
      ],
    };

    return (
      <DocumentTitle title={`Reports`}>
        <div>
          <ReportsNav />
          <div className="st-container background-white container-fluid">
            <div className="row">
              <div className="col-md-6 padding">
                <h4>Sales Summary</h4>
                <div style={{ width: 720, marginTop: 40 }}>
                  {/* <Doughnut data={summaryData} /> */}
                </div>
              </div>
              <div className="col-md-6 padding">
                <h4>Top Buyers</h4>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th># Bought</th>
                      <th>Total Amount</th>
                      <th>Average</th>
                    </tr>
                    <tr>
                      <td>Tim Jones</td>
                      <td>6</td>
                      <td>$34,000</td>
                      <td>$5,680</td>
                    </tr>
                    <tr>
                      <td>Andy Gorcly</td>
                      <td>4</td>
                      <td>$26,000</td>
                      <td>$6,500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 padding">
                <h4>Online Bidders</h4>
                <div style={{ width: 720, marginTop: 40 }}>
                  {/* <Doughnut data={bidderData} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const {
    entities: { reportSummary },
  } = state;

  return {
    reportSummary,
  };
};

export default connect(
  mapStateToProps,
  {
    loadReportSummary,
  }
)(Reports);
