import React from 'react';
import { commafy } from '../../utils/helpers';

const HitStatsCustom = ({ bemBlocks, searchkit, hitsCount }) => {
  return (
    <div className={bemBlocks.container()} data-qa="hits-stats">
      <div className={bemBlocks.container('info')} data-qa="info">
        {searchkit.loading
          ? 'Loading...'
          : `${commafy(hitsCount)} results found`}
      </div>
    </div>
  );
};

export default HitStatsCustom;
