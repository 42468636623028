import React, { Component } from 'react';
import { connect } from 'react-redux';

class Convertible2DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }
    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M669.4,173.7c-9.5-3.7-21-7-34.9-8.7c-16.8-1.9-34.7-2.6-51.3-2.6c-3.9,0-8.9,0.1-14.4,0.6
	c-18.2,1-42.1,3-52.3,3h-90.7c2.2-4.5,4.3-8.8,4.4-9.2c0.3-0.7,0.5-2.5-0.4-2.6c-0.8-0.1-3.2-0.7-7.8,0c-4.7,0.7-11.6,4.4-13.5,7.7
	c-0.7,1.1-1.7,2.6-2.8,4.3H353c-23.8,0-50.1-4.4-63.8-4.4c-12.5,0-39.9,1-58.1,2.1c-1.8,0.1-3.4,0.3-5.1,0.3
	c-17.7,1.2-42.9,5-53.7,10c-10.7,5-24.6,13.1-33,24.9c-8.4,11.8-12.1,23.8-16.2,37.1c-4,13.3-7.2,26.5-7.2,26.5h-5v62.2h5
	c0,0,3,13.3,7.2,26.5c4,13.3,7.7,25.3,16.2,37.1c8.4,11.8,22.3,19.9,33,24.9c10.7,5,35.8,8.8,53.7,10c5.2,0.4,11.8,0.7,18.6,1.1
	c16.4,0.8,34.8,1.4,44.6,1.4c13.8,0,40-4.4,63.8-4.4h52.8c1.1,1.6,2.1,3.2,2.8,4.3c1.9,3.3,8.8,7.2,13.5,7.8c4.7,0.7,6.9,0.1,7.8,0
	c0.8-0.1,0.7-1.9,0.4-2.6c-0.1-0.3-2.2-4.7-4.4-9.2h90.5c13.1,0,48.8,3.4,65.9,3.6h0.8c16.6,0,34.5-0.7,51.3-2.6
	c13.9-1.7,25.5-5,34.8-8.7c1.4-0.1,3-0.4,3.7-0.5c12.4-2.2,21.5-5.6,33.3-16c12.9-11.1,18.3-27.4,20.9-44s3.6-38.9,3.6-59.2v-1.6
	c0-20.2-1-42.4-3.6-59.2c-2.6-16.6-8-32.9-20.9-44c-12.1-10.5-21.3-13.9-34.1-16.1C671.7,174,670.5,173.8,669.4,173.7L669.4,173.7z"
          />
          <g>
            <path
              fill="#EBEBEB"
              d="M169.3,177.7c-5.6,3-15.4,12.5-20.2,18.6c-4.8,6.1-8.8,14.7-11,21.2c-0.8,2.5-4.1,10-2.6,12.1
		s7.3-1.5,12.4-6.5s12.2-14.3,17.2-21.6c1.1-1.5,2.1-3.2,3-4.8c3.6-6.1,6.5-12.1,7.8-16C177.7,175.9,174.8,174.7,169.3,177.7
		L169.3,177.7z M686.3,186.4c4.3,3.7,9.4,8.1,13.6,13.6c2.5,3.2,5.6,9.4,7.8,14.7c1.7,3.9,2.9,7.2,3.2,8.9c0.5,3.9,1.7,8.3,3.6,10.2
		c0.1,0.1,0.4,0.4,0.7,0.6c-0.1-0.6-0.1-1.1-0.3-1.6c-1.9-14.9-4.5-27.4-11.1-35.9c-5.4-7-14.2-13.3-22.8-17.9
		c0.1,0.6,0.5,1.4,0.8,1.9C682.5,182.2,683.8,184.3,686.3,186.4L686.3,186.4z M168.1,391.5c-1-1.6-2.1-3.3-3-4.8
		c-5-7.3-12.1-16.6-17.2-21.6s-10.9-8.5-12.4-6.5c-1.5,2.1,1.6,9.8,2.6,12.1c2.2,6.3,6.2,15,11,21.2c4.8,6.1,14.7,15.5,20.2,18.6
		c5.6,3,8.4,1.8,6.7-3C174.7,403.6,171.7,397.5,168.1,391.5L168.1,391.5z M409.3,374.3c1.8-0.7,2.8-1.6,2.2-2.9s-2.2-13.5-3.3-23.1
		c-1-9.8-2.1-36.9-2.1-53.1v-1.9c0-16.2,1.1-43.3,2.1-53.1c1-9.8,2.8-21.9,3.3-23.1c0.5-1.2-0.5-2.2-2.2-2.9
		c-0.7-0.3-9.4-3.6-19.8-7.4c-16.5-6.2-37.3-14-37.8-14.2c-1.9,2.2-3.6,5-5.5,8.5c-3.6,6.7-13.3,25.9-17.5,41
		c-4.3,15-7.2,39.1-7.2,51.3v1.9c0,12.2,2.9,36.3,7.2,51.3s13.9,34.1,17.5,41c1.9,3.6,3.6,6.3,5.5,8.5c0.5-0.1,21.3-8,37.8-14.2
		C399.9,377.7,408.6,374.5,409.3,374.3L409.3,374.3z M710.9,364.6c-0.3,1.8-1.5,5.2-3.2,8.9c-2.3,5.4-5.4,11.4-7.8,14.7
		c-4.3,5.5-9.4,9.9-13.6,13.6c-2.5,2.2-3.8,4.3-4.5,5.6c-0.3,0.5-0.5,1.4-0.8,1.9c8.7-4.5,17.5-10.9,22.8-17.9
		c6.6-8.5,9.2-21,11.1-35.9c0.1-0.5,0.1-1.1,0.3-1.7c-0.3,0.3-0.5,0.4-0.7,0.6C712.6,356.4,711.5,360.8,710.9,364.6L710.9,364.6z
		 M420.1,182.5c-7.7,0.1-20.6,0.8-30.7,1.2c-4.8,0.3-9.1,0.4-11.6,0.7c-3.7,0.3-7.3,0.4-10.7,0.8c0.4,0.1,0.8,0.4,1.1,0.6
		c3.2,1.1,11.7,4.3,21.2,7.7c15.1,5.5,32.6,12,33.6,12.5c1.7,0.8,4.7,1.1,6.9,1.2h1.1l-10.3-23.4L420.1,182.5L420.1,182.5z
		 M423,382.1c-1.1,0.5-18.4,7-33.6,12.5c-9.5,3.4-18,6.6-21.2,7.7c-0.4,0.1-0.8,0.3-1.1,0.5c3.3,0.4,6.9,0.6,10.7,0.8
		c2.5,0.1,6.7,0.4,11.6,0.7c10,0.5,23,1.1,30.7,1.2l0.5-1.2L431,381h-1.1C427.7,381,424.7,381.3,423,382.1L423,382.1z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M197,295c0,8.9,7,20.4,20.1,23.1c13.1,2.8,32.3,4.7,49.9,1.2c17.5-3.4,19.1-22.6,19.1-24.5V293
	c0-1.9-1.6-21-19.1-24.5c-17.5-3.4-36.7-1.5-49.9,1.2C204,272.5,197,284,197,292.8L197,295L197,295z"
          />
          <path
            fill="#656565"
            d="M512,199.1c0,0,18.8-0.6,29,0.7c10.2,1.1,19.4,6.1,19.4,17.1v154.5c0,11-9.2,15.8-19.4,17.1
	c-10.2,1.1-29,0.7-29,0.7v17.3c0,0,29.3,0.3,42.8-2.2c13.5-2.6,25.9-11.3,33.3-22.3c7.4-11,14-37.7,14-86.9v-1.2
	c0-49.2-6.6-75.9-14-86.9c-7.4-11-19.9-19.8-33.3-22.3c-13.5-2.6-42.8-2.2-42.8-2.2L512,199.1L512,199.1z"
          />
          <path
            fill="#656565"
            d="M482.7,278.5h8.5c5.4,0,11.8-7.8,13.5-9.9c1.6-2.1,8.3-5.8,11.1-7.4c2.9-1.6,5.8-5.5,5.8-7.3v-24.8
	c0-2.8-1.5-3.9-3.7-5.5l-0.1-0.1c-2.5-1.8-10-7.6-12.5-8.7s-5.2-5.8-8-8c-2.8-2.1-5.2-2.8-7.6-2.6c-0.8,0-3.6,0.1-7,0.1
	c-6.3,0-15.1-0.1-18.3-0.1c-4.8,0-11.3-1.8-18.2-2.1c-4.5-0.3-10.3,0.7-13.8,1.4c-1.1,0.3-2.3,0.4-3,0.6l1.4,3l1.1,2.5
	c0,0-6.1,24.4-7.3,34.8c-1,8.3-2.1,17.9-2.5,34c0.3,0,0.4,0,0.6,0.1c2.6,0.3,16.6,2.1,23.2,2.1c7.3,0,17.3-2.1,17.3-2.1L482.7,278.5
	L482.7,278.5L482.7,278.5z M482.7,309.7h8.5c5.4,0,11.8,7.8,13.5,9.9c1.6,2.1,8.3,5.8,11.1,7.4c2.9,1.6,5.8,5.5,5.8,7.3v24.8
	c0,2.8-1.5,3.9-3.7,5.5l-0.1,0.1c-2.5,1.8-10,7.6-12.5,8.7c-2.5,1.1-5.2,5.8-8,8c-2.8,2.1-5.2,2.8-7.6,2.6c-0.8,0-3.6-0.1-7-0.1
	c-6.3,0-15.1,0.1-18.3,0.1c-4.8,0-11.3,1.8-18.2,2.1c-4.5,0.3-10.3-0.7-13.8-1.4c-1.1-0.3-2.3-0.4-3-0.5l1.4-3l1.1-2.5
	c0,0-6.1-24.4-7.3-34.8c-1-8.3-2.1-17.9-2.5-34c0.3,0,0.4,0,0.6-0.1c2.6-0.3,16.6-2.1,23.2-2.1c7.3,0,17.3,2.1,17.3,2.1L482.7,309.7
	L482.7,309.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M590.8,210.4c8.9,0,47.3-0.3,64.1,0.1c16.8,0.6,48.1,3.6,48.1,3.6l5.2,0.7
	 M592.5,214.6h20.8 M400.3,174.8c1.9-3.2,6.3-9.4,8.3-12.7s8.8-7.2,13.5-7.8c4.7-0.7,6.9-0.1,7.8,0c0.8,0.1,0.7,1.9,0.4,2.6
	c-0.3,0.7-9.2,19-9.5,19.7c-0.4,0.7-2.5,2.1-3.7,2.2s-3,0.7-3.6,1.8c0,0-2.3,0.8-7.6,0.8C400.7,181.4,397.3,179.7,400.3,174.8
	L400.3,174.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M400.6,180.3c0,0,3.9-3.3,9.2-3.6c5.4-0.3,7.7-0.1,7.7-0.1l1.9,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.4,176.6c0,0,8.7-17.9,9.2-19.1c0.5-1.2,2.3-1.9,3.6-1.2 M432.1,209.7
	h-7.7c-1.2,0-3.6-1.2-6.2-2.2c-2.6-1-55.3-21.7-55.3-21.7s-12.2-3.9-15.5-6.2c-3.3-2.3-8.8-8.1-9.6-13.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367,185.2c0,0,54.1,19.8,56,20.8c1.9,1,5.6,1.2,7.8,1.2 M424.5,192.3
	c0,0,21.7-1.2,34-1.2h53.7 M422.9,188.3c0,0,17.6-1.2,32.2-1.2h56.8 M422,186.2c0,0,14-1,25.3-1h64.8 M176.5,178.8
	c0,0,29.2-5.8,45.9-7.3s34.9-2.6,49.9-2.6c14.9,0,32.6,1.5,44.7,2.8c12.1,1.2,18.6,2.9,36.3,2.9h46.9 M680.9,179.1
	c0,0,1.1,3.6,5.4,7.3c4.3,3.7,9.4,8.1,13.6,13.6c4.3,5.5,10.3,19.4,11,23.7s1.9,9.2,4.3,10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M116.2,263c0,0,3-13.3,7.2-26.6c4-13.3,7.7-25.3,16.2-37.1
	c8.4-11.8,22.3-19.9,33-24.9c10.7-5.1,35.8-8.8,53.6-10c17.7-1.2,49.4-2.5,63.1-2.5s40,4.4,63.8,4.4H406"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.3,185.4c0,0-12.1,10-17.5,20.5c-5.4,10.6-9.2,20.1-9.2,25.9
	s4.5,10.2,15.5-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M138,217.4c2.2-6.3,6.2-15,11-21.2c4.8-6.1,14.7-15.5,20.2-18.6
	c5.6-3,8.4-1.8,6.7,3c-1.6,4.8-5.9,13.5-10.9,20.8c-5,7.3-12.1,16.6-17.2,21.6s-10.9,8.5-12.4,6.5
	C133.9,227.5,137.2,219.9,138,217.4L138,217.4z M429.8,204.2c0,0,9.9-2.2,16.6-1.9c6.7,0.3,13.3,2.1,18.2,2.1s22.8,0.1,25.3,0
	s4.8,0.6,7.6,2.6c2.8,2.1,5.5,6.7,8,8c2.5,1.1,10.3,7.2,12.7,8.8c2.2,1.6,3.7,2.8,3.7,5.5V254c0,1.8-2.9,5.6-5.8,7.3
	s-9.5,5.4-11.1,7.4s-8.1,9.9-13.5,9.9h-27.8c0,0-10,2.1-17.3,2.1s-23.7-2.1-23.7-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M430.7,215.4c0,0,30,3.7,37.8,3.7h22.8c4.4,0,12.9,5.2,12.9,12.1v21.2
	c0,4.8-6.5,10.7-11.7,10.7h-25.3c0,0-36.7,3-44.7,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M464.7,204.4c0,0-3.9,2.1-3.9,5s6.2,8.5,7,9.9v44c0,0-6.7,6.7-6.7,9.9
	c0,3,2.6,5.4,2.6,5.4 M482.7,278.5c0,0,7.3-2.6,8.9-5.4c1.7-2.8,7-7,9.8-8.8c2.8-1.8,10.6-6.5,10.6-10.6v-25.3
	c0-2.5-2.1-5.4-6.1-7.2c-3.9-1.8-7.3-4.3-9.4-6.2c-2.1-1.9-8.9-10.3-14-11 M428.6,267.2V225 M452.6,218.1v46.4 M473.2,219.1v43.7
	 M485.9,219.4V263 M517.9,199.1v24.5 M517.9,259.9v23.4 M286,295c0,1.9-1.6,21-19.1,24.5s-36.7,1.5-49.9-1.2
	c-13.1-2.8-20.1-14.3-20.1-23.1v-1.9c0-8.9,7-20.4,20.1-23.1c13.1-2.8,32.3-4.7,49.9-1.2c17.5,3.4,19.1,22.6,19.1,24.5V295L286,295z
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M602.3,294.8c0,49.2-6.6,75.9-14,86.9c-7.4,11-19.9,19.8-33.3,22.3
	c-13.5,2.6-42.8,2.2-42.8,2.2s-79.5-0.5-90-0.7c-10.6-0.1-35.9-1.5-44.2-1.9c-8.3-0.5-15.5-0.8-21.6-3c-6.1-2.2-13.5-6.7-16.2-12.9
	c-2.8-6.2-11-18-16.8-38.4s-8.5-38.1-8.5-54.5v-1.7c0-16.4,2.8-34,8.5-54.5s14-32.2,16.8-38.4s10.2-10.7,16.2-12.9
	c6.1-2.2,13.3-2.6,21.6-3c8.3-0.6,33.7-1.8,44.2-1.9c10.6-0.1,90-0.7,90-0.7s29.3-0.3,42.8,2.2c13.5,2.6,25.9,11.3,33.3,22.3
	c7.4,11,14,37.7,14,86.9L602.3,294.8L602.3,294.8z M590.8,377.8c8.9,0,47.3,0.3,64.1-0.1c16.8-0.5,48.1-3.6,48.1-3.6l5.2-0.7
	 M592.5,373.6h20.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M612.6,377.8c0,0,4.7-20.2,6.3-41c1.7-20.8,2.6-28.6,2.6-41.8v-1.9
	c0-13.1-1-21-2.6-41.8c-1.7-20.8-6.3-41-6.3-41 M703.4,356.1c0,0-6.6-26.1-6.6-61.2v-1.6c0-35.1,6.6-61.2,6.6-61.2 M717.4,337.9
	c1.9,0,5.8-1,6.5-5.8c0.7-4.8,1.2-19.1,1.2-37.1v-1.9c0-18-0.7-32.2-1.2-37.1c-0.7-4.8-4.5-5.8-6.5-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M669.7,414.6c14.3-2.2,23.8-5.4,36.7-16.5s18.3-27.4,20.9-44
	c2.6-16.6,3.6-38.9,3.6-59.2v-1.6c0-20.2-1-42.4-3.6-59.2c-2.6-16.6-8-32.9-20.9-44c-12.9-11.1-22.6-14.3-36.7-16.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M425.8,422.1h90.7c13.3,0,50.1,3.6,66.7,3.6s34.5-0.7,51.3-2.6
	c16.8-1.9,30.3-6.5,40.6-11s22-12.1,28.6-20.8c6.6-8.5,9.2-21,11.1-35.9c1.9-14.9,3.9-46.6,3.9-60.4v-1.9c0-13.8-1.9-45.5-3.9-60.4
	s-4.5-27.4-11.1-35.9c-6.6-8.5-18.3-16.2-28.6-20.8s-23.8-9.1-40.6-11c-16.8-1.9-34.7-2.6-51.3-2.6s-53.4,3.6-66.7,3.6h-90.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M400.3,413.5c1.9,3.2,6.3,9.4,8.3,12.7c1.9,3.3,8.8,7.2,13.5,7.8
	c4.7,0.7,6.9,0.1,7.8,0c0.8-0.1,0.7-1.9,0.4-2.6c-0.3-0.7-9.2-19-9.5-19.7c-0.4-0.7-2.5-2.1-3.7-2.2c-1.2-0.1-3-0.7-3.6-1.8
	c0,0-2.3-0.8-7.6-0.8C400.7,406.7,397.3,408.4,400.3,413.5L400.3,413.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M400.6,407.8c0,0,3.9,3.3,9.2,3.6c5.4,0.3,7.7,0.1,7.7,0.1l1.9-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.4,411.5c0,0,8.7,17.9,9.2,19.1c0.5,1.2,2.3,1.9,3.6,1.2 M420.1,405.6
	l12-27.1c0,0-6.1-24.3-7.3-34.8c-1.2-10.6-2.6-23.4-2.6-48.6v-1.9c0-25.3,1.2-38.1,2.6-48.6c1.2-10.6,7.3-34.8,7.3-34.8l-12-27.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M432.1,378.5h-7.7c-1.2,0-3.6,1.2-6.2,2.2c-2.6,1-55.3,21.7-55.3,21.7
	s-12.2,3.9-15.5,6.2c-3.3,2.3-8.8,8.1-9.6,13.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367,402.9c0,0,54.1-19.8,56-20.8c1.9-1,5.6-1.2,7.8-1.2 M353.3,397.5
	c0,0,55-20.4,58.9-21.9c3.9-1.5,3.9-3.3,3.4-6.2c-0.5-2.9-5.2-36.7-5.2-74.6V293c0-37.7,4.7-71.5,5.2-74.4c0.5-2.9,0.5-4.7-3.4-6.2
	c-3.9-1.5-58.9-21.9-58.9-21.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M351.6,396c0,0,55.9-21,57.6-21.7c1.8-0.7,2.8-1.6,2.2-2.9
	s-2.2-13.5-3.3-23.1c-1-9.8-2.1-36.9-2.1-53.1v-1.9c0-16.2,1.1-43.3,2.1-53.1c1-9.8,2.8-21.9,3.3-23.1c0.5-1.2-0.5-2.2-2.2-2.9
	c-1.8-0.7-57.6-21.7-57.6-21.7 M516.4,422.1c0,0-0.5-4.3-1.5-7.3c-1-3-2.9-8.5-2.9-8.5V389c0,0,18.8,0.5,29-0.7
	c10.2-1.1,19.4-6.1,19.4-17.1v-77.9v-76.6c0-11-9.2-15.8-19.4-17.1c-10.2-1.1-29-0.7-29-0.7v-17.2c0,0,1.9-5.5,2.9-8.5
	s1.5-7.3,1.5-7.3 M529.9,388.4V199.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M424.5,396c0,0,21.7,1.2,34,1.2h53.7 M422.9,399.9c0,0,17.6,1.2,32.2,1.2
	h56.8 M422,401.9c0,0,14,1,25.3,1h64.8 M176.5,409.3c0,0,29.2,5.8,45.9,7.3s34.9,2.6,49.9,2.6c14.9,0,32.6-1.5,44.7-2.8
	s18.6-2.9,36.3-2.9h46.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M421.5,412.9c0,0,73.7-0.7,93-0.6c19.3,0.1,55.2,1.6,67.8,1.6
	c12.7,0,32.1-1.6,48.3-4c16.2-2.5,32.7-4.8,46.4-13.5c13.6-8.5,23.8-17.2,26.1-22.6c2.2-5.4,6.1-22.3,8-36.7
	c1.9-14.4,3.3-23.8,3.3-42.6v-1.2c0-18.8-1.2-28.2-3.3-42.6c-1.9-14.4-5.6-31.4-8-36.7c-2.3-5.4-12.5-13.9-26.1-22.6
	c-13.6-8.5-30.1-11-46.4-13.5c-16.2-2.5-35.6-4-48.3-4c-12.7,0-48.6,1.5-67.8,1.6c-19.3,0.1-93-0.6-93-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M680.9,409.1c0,0,1.1-3.6,5.4-7.3c4.3-3.7,9.4-8.1,13.6-13.6
	c4.3-5.5,10.3-19.4,11-23.7c0.7-4.3,1.9-9.2,4.3-10.7 M116.2,325.2c0,0,3,13.3,7.2,26.5c4,13.3,7.7,25.3,16.2,37.1
	c8.4,11.8,22.3,19.9,33,24.9c10.7,5,35.8,8.8,53.6,10c17.7,1.2,49.4,2.5,63.1,2.5s40-4.4,63.8-4.4H406"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M156.3,402.7c0,0-12.1-10-17.5-20.6c-5.4-10.6-9.2-19.9-9.2-25.7
	s4.5-10.2,15.5,5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M138.9,354.2c-3.7-13.8-8.1-41.3-8.1-59.4v-1.2c0-18.2,4.4-45.7,8.1-59.4
	 M167.4,391.5c0,0,39.3,3.6,66.4,5.2c27.1,1.7,59.2,2.9,81.4,3.4c22.3,0.5,42.2,1,42.2,1c-5.2-3.3-8-6.7-11.6-13.6
	c-3.6-6.7-13.3-25.9-17.5-41c-4.3-15-7.2-39.1-7.2-51.3v-1.9c0-12.2,2.9-36.3,7.2-51.3c4.3-15,13.9-34.1,17.5-41
	c3.6-6.7,6.3-10.3,11.6-13.6c0,0-19.9,0.6-42.2,1c-22.3,0.6-54.5,1.8-81.4,3.4c-27.1,1.6-66.4,5.2-66.4,5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M138,370.7c2.2,6.3,6.2,15,11,21.2c4.8,6.1,14.7,15.5,20.2,18.6
	c5.6,3,8.4,1.8,6.7-3s-5.9-13.5-10.9-20.8c-5-7.3-12.1-16.6-17.2-21.6s-10.9-8.5-12.4-6.5C133.9,360.6,137.2,368.3,138,370.7
	L138,370.7z M429.8,383.9c0,0,9.9,2.2,16.6,1.9s13.3-2.1,18.2-2.1s22.8-0.1,25.3,0c2.5,0.1,4.8-0.5,7.6-2.6c2.8-2.1,5.5-6.7,8-8
	c2.5-1.1,10.3-7.2,12.7-8.8c2.2-1.6,3.7-2.8,3.7-5.5v-24.8c0-1.8-2.9-5.6-5.8-7.3s-9.5-5.4-11.1-7.4c-1.6-2.1-8.1-9.9-13.5-9.9
	h-27.8c0,0-10-2.1-17.3-2.1s-23.7,2.1-23.7,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M430.7,372.8c0,0,30-3.7,37.8-3.7h22.8c4.4,0,12.9-5.2,12.9-12.1v-21.2
	c0,0-6.5-10.7-11.7-10.7h-25.3c0,0-36.7-3-44.7-5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M464.7,383.8c0,0-3.9-2.1-3.9-5.1c0-2.9,6.2-8.5,7-9.9v-44
	c0,0-6.7-6.7-6.7-9.9c0-3,2.6-5.4,2.6-5.4 M482.7,309.6c0,0,7.3,2.6,8.9,5.4c1.7,2.8,7,7,9.8,8.8c2.8,1.8,10.6,6.5,10.6,10.6v25.3
	c0,0-2.1,5.4-6.1,7.2c-3.9,1.8-7.3,4.3-9.4,6.2c-2.1,1.9-8.9,10.3-14,11 M428.6,321v42.2 M452.6,370v-46.2 M473.2,369v-43.7
	 M485.9,368.8v-43.6 M517.9,389v-24.5 M517.9,328.3v-23.5 M422.3,299.6h32.7l8.8,4.8h62.6v-20.6H422.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M456.7,303c0,1.1-0.8,1.9-1.9,1.9h-30.3c-1.1,0-1.9-0.8-1.9-1.9v-0.8
	c0-1.1,0.8-1.9,1.9-1.9h30.3c1.1,0,1.9,0.8,1.9,1.9V303L456.7,303z M118.3,295.4c0,14.9,3.6,30.3,3.6,30.3h-10.6v-62.2h10.6
	c0,0-3.6,15.4-3.6,30.3V295.4L118.3,295.4z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M357.5,401c0,0-11.4-3.2-16.6-11.8
		l-1-1.9l-1.1-2.1c0,0-8.9-15.8-13.5-29c-4.5-13.2-11-39.6-10.7-62.2c0,0-0.5-19,5.9-45.3c0,0,4.3-19.1,12-34l6.9-12.9l1.1-2.2
		c0,0,2.9-7.7,17.1-12.4c0,0-120.6,2.3-189.4,9.5c0,0-11.6,18.3-22.3,28.2c0,0-0.7,2.6-6.9,9.1c0,0-7.6,28.2-8.1,60
		c0,0-0.8,22.6,8.1,60.3c0,0,5.2,5,6.5,8.8c0,0,9.2,7.7,22.7,28.6C168.1,391.5,231.4,399,357.5,401z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M351.6,396
		c0,0-7.7-8.4-21.2-43.6c0,0-7.8-20.6-8.9-58.5c0,0,0.1-30.8,9.1-58.3c0,0,10.6-29.3,21-43.3l57.6,21.7c0,0,2.8,1.1,2.3,2.6
		l-0.3,0.8c0,0-4.4,15.3-5.2,76.8c0,0,0.4,57.6,5.4,77.3c0,0,0.5,1-0.7,2.1c0,0-0.7,0.6-1.5,0.8L351.6,396z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M612.6,377.8
		c0,0,4.7-19.1,6.7-45.5l1.5-18.2c0,0,0.8-14.9,0.8-20.2c0,0,0.1-9.1-1.1-24.5l-1.5-18.6c0,0-1.1-17.2-6.3-40.4
		c0,0,37.3-0.1,46.1,0.3c8.8,0.6,44.3,3.4,44.3,3.4s5.1,16.5,7,30.3s2.5,17.7,2.5,17.7s1.9,19.8,1.8,31.4
		c-0.1,11.6-0.7,21.9-1.1,25.9c-0.4,4-3,23.7-3,23.7s-4.5,27.2-7.2,31.1c0,0-38.5,3.4-49.1,3.6c-10.7,0.1-16.5,0.3-16.5,0.3
		L612.6,377.8z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Convertible2DrTop);
