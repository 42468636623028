import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe4DrRear extends Component {
  constructor(props) {
    super(props);
  }
  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 160 800 340">
          <g>
            <path
              fill="#5E5E5E"
              d="M293,401.8c-12.5-1.9-18.1,7.5-18.9,9.1l11.5,24.9l-50.8-110.1v142.7c0,0-0.2,5.7,2.1,8.7l0.6,1.5
		c0,0,0.4,1.3,1.3,1.7c0,0,3.6,2.1,21.3,1.9h0.2c17.7,0.2,21.3-1.9,21.3-1.9c0.9-0.4,1.3-1.7,1.3-1.7l0.6-1.5
		c2.5-2.8,2.1-8.7,2.1-8.7v-32.7c1.7,0.4,4.2,0.8,6.2,1.1c0,0,3.6,0.6,3.6-3.6l12.8-8.3l-3-10.2C302.6,405,293,401.8,293,401.8
		L293,401.8z M608.2,468.7V326l-39.3,85.1c-0.8-1.3-6.4-10.8-18.9-9.1c0,0-9.8,3-12.3,12.8l-3,10.2l12.8,8.3c0,4.2,3.6,3.6,3.6,3.6
		c2.1-0.4,4.5-0.8,6.2-1.1v32.7c0,0-0.4,5.9,2.1,8.7l0.6,1.5c0,0,0.4,1.3,1.3,1.7c0,0,3.6,2.1,21.3,1.9h0.2
		c17.7,0.2,21.3-1.9,21.3-1.9c0.9-0.4,1.3-1.7,1.3-1.7l0.6-1.5C608.2,474.3,608.2,468.7,608.2,468.7L608.2,468.7z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M608.2,367.7c-0.9,7.9-2.3,15.9-3.8,23.8c0,0-3.6,19.4-4.7,24c0,0-3.6,13.6-13,18.1l-7.7,2.3
	c0,0-3-10-9.8-24.7c0,0-5.7-11.1-18.9-9.2c0,0-9.8,3-12.3,12.8l-3,10.4l-16.2,1.1c0,0-21.5,1.3-35.9,1.9c0,0-15.3,0.8-28.1,2.5
	l-33,0.2l-33-0.2c-12.8-1.7-28.1-2.5-28.1-2.5c-14.5-0.6-35.9-1.9-35.9-1.9l-16.2-1.1l-3-10.4c-2.5-9.8-12.3-12.8-12.3-12.8
	c-13.4-1.9-18.9,9.2-18.9,9.2c-6.6,14.7-9.8,24.7-9.8,24.7l-7.7-2.3c-9.4-4.5-13-18.1-13-18.1c-1.1-4.5-4.7-24-4.7-24
	c-1.5-7.9-2.8-16-3.8-23.8l-0.2,25.5c-3-21.7-2.8-54-2.8-54l-0.8-12.1c5.9-27,14.2-37.9,16.2-40.2c0.4-0.4,1.5-1.7,1.5-1.7
	c-0.4,0-4.3,0.2-4.9,0.4c-7.9,0.2-17.4-3.6-17.4-3.6c-8.7-3.2-7.2-15.1-7.2-15.1c0.2-5.9,4-6.2,4-6.2c14-2.3,24.9-0.8,24.9-0.8
	c6.6,0.6,7.7,6.8,7.7,6.8c0.2,1.3,0.2,4.7,0.2,4.7h5.1c9.4-29.4,37.9-66.3,37.9-66.3s7.2-7.2,16.2-8.3c0,0,8.9-1.7,25.7-1.7
	l80.4-0.6l80.4,0.6c16.8,0,25.7,1.7,25.7,1.7c8.9,1.1,16,8.3,16,8.3s28.3,36.8,37.9,66.3h5.1c0,0,0-3.4,0.2-4.7c0,0,1.1-6.2,7.7-6.8
	c0,0,11-1.5,24.9,0.8c0,0,4,0.4,4,6.2c0,0,1.5,11.9-7.2,15.1c0,0-9.4,3.8-17.4,3.6c-0.4,0-4.3-0.2-4.9-0.4c0,0,1.1,1.3,1.5,1.7
	c2.1,2.5,10.4,13.2,16.2,40.2l-0.8,12.1c0,0,0.2,32.3-2.8,54L608.2,367.7L608.2,367.7z"
          />
          <path
            fill="#5E5E5E"
            d="M360.2,428.1c-6.4-0.2-14.2-0.6-20.8-0.9v9.4h3v-2.3h156.3v2.3h2.1v-9.2c-6,0.4-12.5,0.8-17.9,0.9
	c0,0-15.3,0.8-28.1,2.5l-33,0.4h-0.4l-33-0.2C375.6,428.8,360.2,428.1,360.2,428.1L360.2,428.1z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M247.7,261.2c0,0-9.8-1.3-22.7,0.6c0,0-3.6,0.4-3.6,5.7c0,0-1.3,10.8,6.6,13.6c0,0,9.4,3.8,17,3.2
		c0,0,9.8-2.5,10-12.5c0,0,0-3.2-0.2-4.3C254.6,267.4,253.5,261.6,247.7,261.2L247.7,261.2z M277.1,265.4c-0.4,0.9-0.9,2.3-1.3,3.4
		c1.7-0.4,3.4-0.9,5.3-1.3c0,0,1.1-0.2,3.4-0.6c1.3-0.2,2.8-0.4,4.9-0.8c8.3-5.3,24-10.4,24-10.4c12.3-3.6,22.3-4.5,22.3-4.5
		c32.8-3.4,85.9-2.3,86.1-2.3c0,0,53.2-1.1,86.1,2.3c0,0,10,0.8,22.3,4.5c0,0,15.7,5.1,24,10.4c2.1,0.2,3.8,0.6,4.9,0.8
		c2.1,0.4,3.2,0.6,3.2,0.6c1.9,0.4,3.6,0.8,5.3,1.3c-0.4-0.8-0.8-1.7-0.9-2.5c-0.9-2.6-3.4-8.5-7.5-15.3
		c-7-11.7-18.5-26.2-35.3-32.3c-2.6-0.9-5.5-1.7-8.5-2.3c0,0-41-5.9-93.4-4.5c-52.3-1.5-93.4,4.5-93.4,4.5c-3,0.6-5.9,1.3-8.5,2.3
		c-16.8,6-28.3,20.6-35.3,32.1C280.7,257.1,278.2,262.5,277.1,265.4L277.1,265.4z M284.3,234.2c1.7-2.6,2.8-4.2,2.8-4.2
		c3.8-4.3,5.1-9.8,5.7-13c0-0.6,0.2-1.5,0.2-2.5c-2.5,3.6-5.5,7.9-8.5,12.6c-8.1,12.6-17.6,29.1-22.5,43.8l0.6,0.8
		C269.4,257.4,279.4,241.6,284.3,234.2L284.3,234.2z"
            />
            <path
              fill="#EAEBEB"
              d="M421.5,255.4c-11.1,0-65.5,2.3-65.5,2.3c-7.2,0.8-25.7,3.4-25.7,3.4c-2.1,0.4-5.3,1.9-8.3,3.4l99.5-2.6
		l99.5,2.6c-3-1.5-6.2-3-8.3-3.4c0,0-18.3-2.6-25.7-3.4C487,257.6,432.6,255.4,421.5,255.4L421.5,255.4z M572.7,286.3
		c0,0-5.5-2.6-13.8-3.6c-3.4-0.4-7.2-0.6-11.1-0.2c0,0-12.8,0.2-24,1.7c0,0-9.6,1.3-12.3,7c0,0-4.7,8.7-9.2,24c0,0-0.6,1.9,0.2,2.3
		h3c24.9,0.4,43-0.2,53.2-0.8c6.4-0.4,9.6-0.8,9.6-0.8c7.2-0.6,10.8-4.5,12.5-7.2C581,306.5,582,292.4,572.7,286.3L572.7,286.3z
		 M550.2,214.4c0,1.1,0,2.1,0.2,2.5c0.6,3.2,1.9,8.9,5.7,13c0,0,0.9,1.5,2.6,4.2c4.7,7.4,14.9,23.2,21.9,37.6l0.6-0.8
		c-4.9-14.7-14.3-31.1-22.5-43.8C555.7,222.1,552.7,218,550.2,214.4L550.2,214.4z M340.7,315c-4.5-15.3-9.2-24-9.2-24
		c-2.6-5.5-12.3-7-12.3-7c-11.1-1.5-24-1.7-24-1.7c-4-0.4-7.7-0.2-11.1,0.2c-8.5,1.1-14,3.6-14,3.6c-9.4,5.9-8.5,20.2-8.3,22.3
		c1.7,2.8,5.1,6.8,12.5,7.2c0,0,3.4,0.4,9.8,0.8c10.2,0.6,28.3,1.1,53.2,0.8h3C341.3,317.1,340.7,315,340.7,315L340.7,315z
		 M621.8,267.4c-0.2-5.3-3.6-5.7-3.6-5.7c-12.8-2.1-22.7-0.6-22.7-0.6c-5.9,0.6-7,6.2-7,6.2c-0.2,1.1-0.2,4.3-0.2,4.3
		c0.2,10,10,12.5,10,12.5c7.5,0.6,17-3.2,17-3.2C623.1,278.2,621.8,267.4,621.8,267.4L621.8,267.4z M435.7,270.3h-28.5
		c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h28.7c1.9,0,3.4-1.5,3.4-3.4C439.1,272,437.6,270.3,435.7,270.3L435.7,270.3z"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M607.1,309.9c0.9,3,2.1,6.4,3,10.2l3.6-2.8l-2.3-6.6L607.1,309.9L607.1,309.9z M236,309.9
	c-0.9,3-2.1,6.4-3,10.2l-3.6-2.8l2.3-6.6L236,309.9L236,309.9z"
          />
          <g>
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.7,430.9l33-0.4c12.8-1.7,28.1-2.5,28.1-2.5
		c14.5-0.6,35.9-1.9,35.9-1.9l16.2-1.1l12.8,8.3c0,4.2,3.6,3.6,3.6,3.6c3.6-0.6,8.1-1.3,8.1-1.3c5.3-0.2,19.3-0.8,19.3-0.8l-1.9-4.5
		c4-2.5,4.5-7.6,4.5-7.6c-0.4-24.7-8.3-27.6-8.3-27.6c-11.7-9.8-28.9-7-28.9-7c-7,0.8-9.4,10.6-9.4,10.6c-1.7,5.5-5.3,17.6-5.3,17.6
		c-15.9,0.8-19.3-0.6-19.3-0.6c-30,0.2-45.3,3-45.3,3c-8.1,0-42.9,0.6-42.9,0.6s-34.7-0.6-42.9-0.6c0,0-15.3-2.8-45.3-3
		c0,0-3.4,1.3-19.3,0.6c0,0-3.6-11.9-5.3-17.6c0,0-2.5-9.8-9.4-10.6c0,0-17.2-2.8-28.9,7c0,0-8.1,2.8-8.3,27.6c0,0,0.6,5.1,4.5,7.5
		l-1.9,4.5c0,0,14,0.6,19.3,0.8c0,0,4.5,0.8,8.1,1.3c0,0,3.6,0.6,3.6-3.6l12.8-8.3l16.2,1.1c0,0,21.3,1.3,35.9,1.9
		c0,0,15.3,0.8,28.1,2.5l33,0.4L421.7,430.9L421.7,430.9z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M308.8,398.8c0,0-2.5-9.8-9.4-10.6c0,0-17.2-2.8-28.9,7
		c0,0-1.7,0.6-3.6,3.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M308.8,398.8c0,0-2.5-9.8-9.4-10.6c0,0-17.2-2.8-28.9,7
		c0,0-1.7,0.6-3.6,3.6H308.8L308.8,398.8z M534.2,398.8c0,0,2.5-9.8,9.4-10.6c0,0,17.2-2.8,28.9,7c0,0,1.7,0.6,3.6,3.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M534.2,398.8c0,0,2.5-9.8,9.4-10.6c0,0,17.2-2.8,28.9,7
		c0,0,1.7,0.6,3.6,3.6H534.2L534.2,398.8z M414.3,289.9c0,0,1.7-2.6,7.9-2.6c6.2,0,7.7,2.6,7.7,2.6l0.9-0.4c0,0-1.7-2.6-8.7-2.6
		s-8.9,2.6-8.9,2.6 M422.3,286.7c7.2,0,8.9,2.8,8.9,2.8s-0.2,0.4-0.8,0.9c-0.4,0.4-1.1,0.8-1.1,0.8s-1.1-3-7.2-3
		c-6,0-7.2,2.8-7.2,2.8s-0.6-0.2-0.9-0.6l-0.9-0.9C413,289.5,414.9,286.7,422.3,286.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M417.7,292.5c-0.6-0.2-2.3-0.8-3.6-1.7c-0.6-0.4-0.9-0.8-0.9-0.8
		s0,0.4,0.2,0.8s0.8,1.1,1.7,1.7c1.1,0.6,2.6,0.9,3.4,1.3l1.1,0.6C419.6,294.4,419.2,293.3,417.7,292.5 M425.1,294.2
		c0.6-0.4,1.1-0.6,2.1-0.9c0.8-0.2,1.7-0.6,2.5-0.9c0.6-0.4,1.1-0.6,1.1-0.6l0.4-1.5l-1.5,1.1c-0.9,0.6-2.1,0.9-2.6,1.1
		c-0.9,0.4-1.5,0.8-2.1,1.3s-0.8,1.1-0.8,1.1S424.7,294.4,425.1,294.2 M423.8,295.2c0,0,0-0.4,0.2-0.6s0.4-0.8,0.4-0.8l-0.6,0.4
		c0,0-0.2,0.2-0.2,0.6c0,0.2-0.2,0.6-0.2,0.6L423.8,295.2L423.8,295.2z M413.2,289.5c0,0,0.2,0.4,0.8,0.8s1.3,0.9,2.1,1.3
		c2.1,0.9,3.4,1.1,4.7,2.5c1.1,1.1,1.5,2.3,1.5,2.3s0.4-0.9,1.3-2.1c0.9-1.1,1.9-1.5,4.2-2.5c2.6-0.9,3.6-2.3,3.6-2.3l-0.4,2.1
		c0,0-0.9,0.8-2.3,1.3c-1.7,0.8-2.8,0.8-4.3,1.9c-1.7,1.1-2.3,2.8-2.3,2.8s-0.4-0.9-1.3-1.9c-1.1-1.3-2.3-1.9-4.5-2.6
		c-1.9-0.6-3-1.5-3-1.5l-0.4-1.5c0,0-0.8,1.1-1.1,2.6c-0.6,1.9-0.4,3.8,0.2,4.7c0.6,0.9,1.1,1.1,1.1,1.1s0.6,1.9,2.3,3
		c1.3,0.9,3.4,1.5,6.8,1.5s5.9-0.8,7.4-2.1c1.9-1.9,2.1-4.5,2.1-5.9c0-2.1-0.6-3.4-0.6-3.4l0.4-2.1c0,0,1.7,2.1,1.7,5.5
		c0,1.9-0.8,4.9-2.6,6.4c-1.3,1.1-3.6,2.3-8.3,2.3c-3.8,0-6.6-0.8-8.3-2.1c-1.7-1.3-2.6-4.5-2.6-6.6
		C411.3,291.8,413.2,289.5,413.2,289.5"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.7,298c-0.6-1.1-1.7-2.1-2.6-2.5s-2.8-0.9-3.6-1.3
		c-0.8-0.2-1.3-0.6-1.3-0.6s-0.6,2.8,0.6,5.1c1.3,3,4.2,3.6,7.5,3.8c2.3,0,5.1-0.6,6.4-1.9c1.1-1.3,1.7-3,1.7-4.9
		c0.2-1.3-0.2-2.1-0.2-2.1s-0.6,0.4-2.8,0.9c-2.1,0.6-3,1.1-4,2.3c-0.8,0.9-1.3,2.1-1.3,2.1S422.1,298.8,421.7,298 M422.3,298
		c0,0,0.6-1.5,1.9-2.6c1.1-1.1,2.3-1.5,4-2.1c1.9-0.6,2.8-1.5,2.8-1.5l-0.6,1.7l0.8-1.5c0,0,0.6,1.5,0.6,3.4c0,1.5-0.4,2.8-1.3,3.8
		c-0.6,0.6-1.1,0.8-1.1,0.8s-0.6,0.9-1.7,1.5c-1.3,0.8-3,1.1-5.3,1.1s-4.3-0.6-5.7-1.3c-1.1-0.8-1.5-1.3-1.5-1.3s-0.8-0.2-1.1-0.8
		c-0.6-0.6-1.5-1.9-1.3-4.1s0.6-3.2,0.6-3.2l0.8,1.7l-0.6-1.7c0,0,0.6,0.6,1.7,0.9c1.1,0.6,2.6,0.9,3.6,1.3c0.9,0.6,1.9,1.3,2.5,2.3
		L422.3,298 M422.8,277.3h13c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4h-28.5c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4H422.8z
		 M246.5,301.4c0,0,2.8-8.9,6.4-15.5c0,0,8.1-11.7,11.7-12.5c0,0,1.7-0.2-0.2,1.9c0,0-8.9,8.9-16.2,25.7
		C248.2,301.2,246.5,303.1,246.5,301.4L246.5,301.4z M608.2,367.3v101.2c0,0,0.2,5.7-2.1,8.7l-0.6,1.5c0,0-0.4,1.3-1.3,1.7
		c0,0-3.6,2.1-21.3,1.9h-0.2c-17.7,0.2-21.3-1.9-21.3-1.9c-0.9-0.4-1.3-1.7-1.3-1.7l-0.6-1.5c-2.5-2.8-2.1-8.7-2.1-8.7v-32.8
		 M339.6,426.9v9.6h2.8v-2.3h156.3v2.3h2.1v-9.4 M234.8,367.3v101.2c0,0-0.2,5.7,2.1,8.7l0.6,1.5c0,0,0.4,1.3,1.3,1.7
		c0,0,3.6,2.1,21.3,1.9h0.2c17.7,0.2,21.3-1.9,21.3-1.9c0.9-0.4,1.3-1.7,1.3-1.7l0.6-1.5c2.5-2.8,2.1-8.7,2.1-8.7v-32.8
		 M234.8,309.9l-3.4,0.6l-2.3,6.8l2.8,2.1 M257.1,271.6h4.3c9.4-29.4,37.9-66.3,37.9-66.3s7.2-7.2,16-8.3c0,0,8.9-1.7,25.7-1.7
		l80.4-0.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.3,261.6l-108.2,2.8c-21.9,0.8-32.3,3-32.3,3
		c-7.7,1.5-13.8,4.5-13.8,4.5c-7-0.4-19.3,14.5-19.3,14.5s-10,9.6-16.8,40.8c0,0,1.3,32.5,7.6,64.4c0,0,3.6,19.4,4.7,24
		c0,0,3.6,13.6,13,18.1l7.7,2.3c0,0,3-10,9.8-24.7c0,0,5.7-11.1,18.9-9.2c0,0,9.8,3,12.3,12.8l3,10.4l16.2,1.1
		c0,0,21.5,1.3,35.9,1.9c0,0,15.3,0.8,28.1,2.5l33,0.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M261.2,307.3c0,0-3-19.6,19.6-30.4c0,0,23.2-11,140.4-11.1"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M303.7,264.8c-10.8,1.1-21.5,4.5-21.5,4.5s-3,0.8-0.4,2.3l5.3,3l3.4,7.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M299.4,205.3l-0.9,10.2c0,0,0,3.4-4.2,8.5c0,0-19.3,23.4-28.5,47.9
		c0,0,12.6,0.4,17,4.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M261.6,273.1c9.4-19.8,25.3-43,25.3-43c5.5-6.2,5.9-15.7,5.9-15.7
		 M315.6,197c0,0,3,8.9,4.2,21.9 M274.3,268.2c0,0,15.7-43,44.5-51.9"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M276.2,267.8c0,0,15.7-45.3,51.9-51.2c0,0,41-5.9,93.4-4.5 M324.9,214.8
		c0,0,19.8-5.5,96.6-5.7 M320,196.3c0,0,2.6,4.2,4.9,20.8 M330,288.8c0,0,42.9-3.2,91.6-4.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M262.2,309c0,0-1.9-16.2,8.1-22.5c0,0,10.6-5.1,25.1-4
		c0,0,12.8,0.2,24,1.7c0,0,9.6,1.3,12.3,7c0,0,4.7,8.7,9.2,24c0,0,0.6,1.9-0.2,2.3h-3c-40.4,0.8-62.9-1.3-62.9-1.3
		c-11.1-0.8-13.6-9.8-13.6-9.8l-11.9,26.4c-1.5,4.2-4.9,4.2-4.9,4.2l-12.3,0.4 M256.9,277.1c0,0-21.9,20-20.8,58.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M256.2,266.9c0,0-1.1-6.2-7.7-6.8c0,0-10.9-1.5-24.9,0.8c0,0-4,0.4-4,6.2
		c0,0-1.5,11.9,7.2,15.1c0,0,10.4,4.2,18.7,3.4c0,0,10.9-2.8,10.9-13.8C256.5,271.8,256.5,268,256.2,266.9L256.2,266.9z
		 M421.5,336.5l-67.6,0.2c0,0-9.4-0.6-7.2,8.3c0,0,3.4,15.3,10.4,28.7c0,0,1.3,5.1,9.2,6l55.3,0.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.5,340.1l-63.8,0.8c0,0-5.1-1.3-3.6,5.1c0,0,1.7,18.1,6.4,27.4
		c0,0-1.9,4,10,5.3 M232,339.4c0,0-0.2,32.3,2.8,54 M421.5,419.4c0,0-34.7-0.6-42.9-0.6c0,0-15.3-2.8-45.3-3c0,0-6.8,1.3-19.1,1.1
		 M421.5,315h-80.8 M264.5,434.7c0,0,14,0.6,19.3,0.8c0,0,4.5,0.8,8.1,1.3c0,0,3.6,0.6,3.6-3.6l12.8-8.3 M608.2,309.9l3.4,0.6
		l2.3,6.8l-2.8,2.1 M585.9,271.6h-4.3c-9.6-29.6-37.9-66.3-37.9-66.3s-7.2-7.2-16.2-8.3c0,0-8.9-1.7-25.7-1.7l-80.2-0.6
		 M421.7,261.6l108.2,2.8c21.9,0.8,32.3,3,32.3,3c7.7,1.5,13.8,4.5,13.8,4.5c7-0.4,19.3,14.5,19.3,14.5s10,9.6,16.8,40.8
		c0,0-1.3,32.5-7.6,64.4c0,0-3.6,19.4-4.7,24c0,0-3.6,13.6-13,18.1l-7.7,2.3c0,0-3-10-9.8-24.7c0,0-5.5-11.1-18.9-9.2
		c0,0-9.8,3-12.3,12.8l-3,10.2l-16.2,1.1c0,0-21.5,1.3-35.9,1.9c0,0-15.3,0.8-28.1,2.5l-33,0.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M581.8,307.3c0,0,3-19.6-19.6-30.4c0,0-23.2-11-140.4-11.1"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M539.3,264.8c10.8,1.1,21.5,4.5,21.5,4.5s3,0.8,0.4,2.3l-5.3,3l-3.4,7.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M543.6,205.3l0.9,10.2c0,0,0,3.4,4.2,8.5c0,0,19.3,23.4,28.5,47.9
		c0,0-12.6,0.4-17,4.2"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M581.4,273.1c-9.2-19.6-25.3-43-25.3-43c-5.5-6.2-5.9-15.7-5.9-15.7
		 M527.6,197c0,0-3,8.9-4.2,21.9 M568.7,268.2c0,0-15.7-43-44.5-51.9"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M566.9,267.8c0,0-15.7-45.3-51.9-51.2c0,0-41-5.9-93.4-4.5 M518.2,214.8
		c0,0-19.8-5.5-96.6-5.7 M523.1,196.3c0,0-2.6,4.2-4.9,20.8 M513.1,288.8c0,0-42.9-3.2-91.5-4.3"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M580.8,309c0,0,1.9-16.2-8.3-22.5c0,0-10.6-5.1-25.1-4
		c0,0-12.8,0.2-24,1.7c0,0-9.6,1.3-12.3,7c0,0-4.7,8.7-9.2,24c0,0-0.6,1.9,0.2,2.3h3c40.4,0.8,62.9-1.3,62.9-1.3
		c11.1-0.8,13.6-9.8,13.6-9.8l11.9,26.4c1.5,4.2,4.7,4.2,4.7,4.2l12.3,0.4 M586.1,277.1c0,0,21.9,20,20.8,58.7"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M586.9,266.9c0,0,1.1-6.2,7.7-6.8c0,0,10.9-1.5,24.9,0.8c0,0,4,0.4,4,6.2
		c0,0,1.5,11.9-7.2,15.1c0,0-10.4,4.2-18.7,3.4c0,0-10.8-2.8-11-13.8C586.5,271.8,586.5,268,586.9,266.9L586.9,266.9z M421.5,336.5
		l67.6,0.2c0,0,9.4-0.6,7.2,8.3c0,0-3.4,15.3-10.4,28.7c0,0-1.3,5.1-9.2,6l-55.3,0.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.5,340.1l63.8,0.8c0,0,5.1-1.3,3.6,5.1c0,0-1.7,18.1-6.4,27.4
		c0,0,1.9,4-10,5.3 M611,339.4c0,0,0.2,32.3-2.8,54 M421.5,419.4c0,0,34.7-0.6,42.9-0.6c0,0,15.3-2.8,45.3-3c0,0,6.6,1.3,18.7,1.1
		 M421.5,315h80.8 M578.6,434.7c0,0-14,0.6-19.3,0.8c0,0-4.5,0.8-8.1,1.3c0,0-3.6,0.6-3.6-3.6l-12.8-8.3 M289.8,426.8
		c5.3,0,9.4-4.2,9.4-9.4c0-5.3-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4C280.3,422.6,284.5,426.8,289.8,426.8"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M289.8,426.8c5.3,0,9.4-4.2,9.4-9.4c0-5.3-4.2-9.4-9.4-9.4
		s-9.4,4.2-9.4,9.4C280.3,422.6,284.5,426.8,289.8,426.8L289.8,426.8z"
            />
            <circle
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              cx="289.8"
              cy="417.3"
              r="11.1"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M553.3,426.8c-5.3,0-9.4-4.2-9.4-9.4c0-5.3,4.2-9.4,9.4-9.4
		s9.4,4.2,9.4,9.4C562.7,422.6,558.6,426.8,553.3,426.8"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M553.3,426.8c-5.3,0-9.4-4.2-9.4-9.4c0-5.3,4.2-9.4,9.4-9.4
		s9.4,4.2,9.4,9.4C562.7,422.6,558.6,426.8,553.3,426.8L553.3,426.8z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M553.3,428.5c-6,0-11.1-4.9-11.1-11.1c0-6,4.9-10.9,11.1-10.9
		c6,0,10.9,4.9,10.9,10.9C564.4,423.4,559.5,428.5,553.3,428.5L553.3,428.5z M421.5,255.4c11.1,0,65.5,2.3,65.5,2.3
		c7.2,0.8,25.7,3.4,25.7,3.4c4.7,0.8,15.1,7.2,15.1,7.2c3.8,2.1,5.3,2.5,5.3,2.5c6,0.6,8.3,1.1,8.3,1.1c4.3,0.6,8.1,1.9,8.1,1.9
		c5.1,0.4,7.7-1.9,7.7-1.9c3.2-2.3-2.3-4.7-2.3-4.7c-8.1-5.5-25.1-11.1-25.1-11.1c-12.3-3.6-22.3-4.5-22.3-4.5
		c-32.8-3.4-86.1-2.3-86.1-2.3s-53.2-1.1-86.1,2.3c0,0-10,0.8-22.3,4.5c0,0-17,5.5-25.1,11.1c0,0-5.5,2.5-2.3,4.7
		c0,0,2.8,2.3,7.7,1.9c0,0,4-1.3,8.1-1.9c0,0,2.3-0.6,8.3-1.1c0,0,1.5-0.4,5.3-2.5c0,0,10.4-6.4,15.1-7.2c0,0,18.3-2.6,25.7-3.4
		C356,257.6,410.4,255.4,421.5,255.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.5,255.4c11.1,0,65.5,2.3,65.5,2.3c7.2,0.8,25.7,3.4,25.7,3.4
		c4.7,0.8,15.1,7.2,15.1,7.2c3.8,2.1,5.3,2.5,5.3,2.5c6,0.6,8.3,1.1,8.3,1.1c4.3,0.6,8.1,1.9,8.1,1.9c5.1,0.4,7.7-1.9,7.7-1.9
		c3.2-2.3-2.3-4.7-2.3-4.7c-8.1-5.5-25.1-11.1-25.1-11.1c-12.3-3.6-22.3-4.5-22.3-4.5c-32.8-3.4-86.1-2.3-86.1-2.3
		s-53.2-1.1-86.1,2.3c0,0-10,0.8-22.3,4.5c0,0-17,5.5-25.1,11.1c0,0-5.5,2.5-2.3,4.7c0,0,2.8,2.3,7.7,1.9c0,0,4-1.3,8.1-1.9
		c0,0,2.3-0.6,8.3-1.1c0,0,1.5-0.4,5.3-2.5c0,0,10.4-6.4,15.1-7.2c0,0,18.3-2.6,25.7-3.4C356,257.6,410.4,255.4,421.5,255.4
		L421.5,255.4z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M538.4,271c0,0-10.2-7.5-15.9-10.4c0,0-16-6.2-30.6-6.8
		c0,0-59.8-2.6-70.6-2.6s-70.6,2.6-70.6,2.6c-14.7,0.6-30.6,6.8-30.6,6.8c-5.5,2.8-15.9,10.4-15.9,10.4 M554.4,309.5
		c4.9,0,9.1-4,9.1-9.1c0-4.9-4-9.1-9.1-9.1c-4.9,0-9.1,4-9.1,9.1C545.5,305.4,549.5,309.5,554.4,309.5L554.4,309.5z M528.2,310.3
		c4.9,0,9.1-4,9.1-9.1c0-4.9-4-9.1-9.1-9.1c-4.9,0-9.1,4-9.1,9.1S523.3,310.3,528.2,310.3z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M505.1,317.5c0,0,4-14.2,7.9-21.7c0,0,2.6-5.7,8.3-7
		c0,0,2.6-1.5,20.4-2.1c0,0,12.3-0.4,19.8,0.4c0,0,4.5,0.6,7.4,2.3c0,0,3.6,2.5,4.5,9.4c0,0,0.9,2.8-0.6,9.1c0,0-0.9,4.2-7.4,8.1"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M505.9,314.8h22.5l26.4-0.8l12.6-0.6c0,0,0.9-0.2,2.3-0.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M528.2,314.8c7.5,0,13.8-5.9,13.8-13.4s-6.2-13.8-13.8-13.8
		s-13.8,6.2-13.8,13.8S520.6,314.8,528.2,314.8z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M541.2,305.4c2.1,4.9,7.2,8.3,13,8.3c8.5,0,14-6.8,14-13.6
		c0-7.7-6.2-13.6-14-13.6c-6.2,0-11.5,3.8-13.4,9.4 M288.6,309.5c-4.9,0-9.1-4-9.1-9.1c0-4.9,4-9.1,9.1-9.1c4.9,0,9.1,4,9.1,9.1
		C297.5,305.4,293.5,309.5,288.6,309.5L288.6,309.5z M314.9,310.3c-4.9,0-9.1-4-9.1-9.1c0-4.9,4-9.1,9.1-9.1s9.1,4,9.1,9.1
		S319.8,310.3,314.9,310.3z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M337.9,317.5c0,0-4-14.2-7.9-21.7c0,0-2.6-5.7-8.3-7
		c0,0-2.6-1.5-20.4-2.1c0,0-12.3-0.4-19.8,0.4c0,0-4.5,0.6-7.4,2.3c0,0-3.6,2.5-4.5,9.4c0,0-0.9,2.8,0.4,9.1c0,0,0.9,4.2,7.4,8.1"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M337.1,314.8h-22.5l-26.4-0.8l-12.6-0.6c0,0-0.9-0.2-2.3-0.6"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M314.9,314.8c-7.6,0-13.8-5.9-13.8-13.4s6.2-13.8,13.8-13.8
		c7.5,0,13.8,6.2,13.8,13.8S322.4,314.8,314.9,314.8z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M301.8,305.4c-2.1,4.9-7.2,8.3-13,8.3c-8.5,0-14-6.8-14-13.6
		c0-7.7,6.2-13.6,14-13.6c6.2,0,11.5,3.8,13.4,9.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.5,332.2c-44.5,0-137.6-0.9-148.2-3.2c-10.6-2.3-12.5-23-12.5-23
		 M421.5,332.2c44.5,0,137.4-0.9,148-3.2c10.6-2.3,12.5-23,12.5-23 M249.5,393.3l-7,17.9c0,0,11.9-5.1,21.7-19.3
		c2.3-3.2,2.1-4.2-0.4-2.8C259.9,390.9,254.1,393.3,249.5,393.3c-10-24.3-12.3-34.9-13.4-57.6 M593.5,393.3l7,17.9
		c0,0-11.9-5.1-21.7-19.3c-2.3-3.2-2.1-4.2,0.4-2.8C583.1,390.9,588.9,393.3,593.5,393.3c10-24.3,12.3-34.9,13.4-57.6 M421.7,383.9
		H301.1c-11,0-18.5,0.2-24.7,3.8c-6.2,3.6-10.4,6.2-12.1,10.8c-2.1,5.3-4.7,23.2-3,26c1.3,2.3,1.9,4,5.3,5.9 M421.7,383.9h120.6
		c11,0,18.5,0.2,24.7,3.8c6.2,3.6,10.4,6.2,12.1,10.8c2.1,5.3,4.7,23.2,3,26c-1.3,2.3-1.9,4-5.3,5.9 M384.5,343.1h74v34.7h-74V343.1
		z"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M318.4,416.2c0,0,1.7-17.2,2.5-23.8c0.8-6.6,4.5-8.5,7.2-8.5H353
		c4,0,10.4,2.3,12.8,33.6 M524,416.6c0,0-1.7-17.4-2.5-24.2c-0.8-6.6-4.5-8.5-7.2-8.5h-24.9c-4,0-10.2,2.3-12.8,33.4"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.3,396.6l-49.1,0.6c0,0-6,0.6-13,5.1l-11.9,14 M421.7,396.6l49.1,0.6
		c0,0,6,0.6,13,5.1l11.9,14"
            />
            <path
              fill="none"
              stroke="#010101"
              strokeWidth="0.5"
              d="M421.3,396.6l-49.1,0.6c0,0-6,0.6-13,5.1l-11.9,14 M421.7,396.6l49.1,0.6
		c0,0,6,0.6,13,5.1l11.9,14"
            />
          </g>
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M276,268.8
		c0,0,5.3-1.7,13.2-2.5c0,0,18.9-11.5,42.9-14.5c0,0,12.8-1.9,35.9-2.3c0,0,25.9-0.9,51.3-0.4h6.4c0,0,23.6-0.4,48.9,0.4
		c25.3,0.8,38.1,2.6,38.1,2.6s19.4,2.1,41.2,14.2c0,0,8.3,0.8,13.4,2.5c0,0-6-16.2-15.9-28.3c0,0-11.9-16.2-27.9-21.5l-5.1-1.5
		c0,0-9.8-2.3-42.5-4.3c0,0-26-1.7-51.7-0.9h-2.5h-2.6c0,0-9.1-0.6-37.6,0.2c0,0-21.7,0.4-53.2,4.3c0,0-4.5,0.6-8.5,2.3
		c0,0-11.7,3.2-23.4,16c0,0-5.9,5.9-11.9,16.2C284.1,251.2,279.6,258.2,276,268.8z"
              id="Rear Glass 1"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass 1')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M322,264.2
		c0,0,59.3-1.5,62.1-1.7c2.8,0,37.9-0.9,37.9-0.9l41,1.1l58,1.5c0,0-4.7-2.8-7.9-3.4c-3-0.4-24.9-3.4-24.9-3.4l-2.5-0.2l-63.2-2.1
		l-66.1,2.3l-26,3.4C330.3,260.8,327.9,261,322,264.2z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M285.6,268.4l-3.6,1.1
		c0,0-1.9,0.4-0.8,1.7l5.9,3.4l3.4,7.7l4.5,0.2c0,0,19.1,0.6,26.2,2.1c7,1.5,9.6,5.1,10,6c0.6,0.9,5.5,9.8,9.4,24.5H502
		c0,0,4-13,6.8-18.9c0,0,2.1-4.7,3-6.2c0,0,2.5-4.3,11.9-5.9c0,0,9.2-1.3,23.8-1.7l5.1-0.2l3.4-7.7l5.3-3c0,0,2.3-1.1,0.4-2.1
		l-4.7-1.3c0,0,2.3,1.5,0.8,2.8c-1.3,1.3-3.4,2.1-7.2,2.6c0,0-11.9-2.5-17.4-2.8c0,0-4-1.9-5.1-2.5s-7.4-4.2-7.4-4.2l-98.7-2.6
		L322,264l-6.8,3.8c0,0-5.5,3-7,3c0,0-9.8,1.3-15.5,2.5c0,0-3.2,0.2-5.9-1.3C286.9,272.3,283.5,271.2,285.6,268.4z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M340.5,317.3l-31.3,0.2
		c0,0-16.6-0.4-33.8-1.5c0,0-9.6,0.6-14-8.9c0,0-0.8-14.7,9.1-20.6c0,0,7.2-3.8,20-4.2c0,0,12.8,0.2,27,1.7c0,0,8.1,0.4,12.6,4.9
		c0,0,1.7,1.1,6.6,13.8c0,0,2.8,7.7,4.2,12.3C340.7,314.8,341.5,317.1,340.5,317.3z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M502.5,317.3
		c0,0,16,0.2,23,0.2c0,0,11.7,0,27-0.6c0,0,19.1-0.6,21.1-2.1c0,0,6-2.1,8.1-7.9c0,0-0.6-15.7-9.2-20.4c0,0-9.4-4.5-23.2-4
		c0,0-13.6,0.4-21.5,1.3c0,0-9.6,0.2-14.9,5.3c0,0-1.3,0.4-5.9,11.5l-4.9,14.5C502.3,315,501.4,316.9,502.5,317.3z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M232,336.9l12.3-0.4
		c0,0,3-0.2,4.7-3.8l12.1-26.8c0,0,2.6,9.6,14.5,10c0,0,22.8,1.5,39.8,1.5l24.9-0.2c0,0,1.1,0,0.6-2.3h161.4c0,0-0.6,2.1,0.4,2.3
		c0,0,19.8,0.4,32.1,0c0,0,22.8-0.4,31.9-1.3c0,0,6.8,0,11.3-3.8c0,0,3.8-3.8,4.2-6.2l11.7,25.9c0,0,1.3,4.5,5.3,4.5l12.1,0.4
		c0,0-0.4,19.1-6.8,54.7c0,0-3.8,21-4.7,24c0,0-3.2,12.8-13,18.1l-7.7,2.3l-2.1-6c0,0-4.3-11.9-7.7-18.9c0,0-5.7-11.1-18.9-9.1
		c0,0-7.9,2.3-11.5,10.6l-3.8,12.6c0,0-68.9,3.6-80.2,5.5l-32.3,0.2l-34-0.2c0,0-9.2-1.3-80.2-5.7l-3.4-11.3c0,0-1.9-7.9-11.9-11.7
		c0,0-11.5-2.6-18.7,8.9l-7.9,19.4l-2.1,5.9l-7.7-2.3c0,0-7.9-2.5-13-17.9c0,0-7.2-35.3-8.7-47.9C234.8,367.7,231.6,342.6,232,336.9
		z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe4DrRear);
