import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { getConfig } from '../../utils/helpers';

let styles = {};

class TableButtonCSV extends Component {
  makeHeaders() {
    return this.props.columns.filter(col => {
      return (
        col.Header &&
        col.id &&
        col.accessor &&
        typeof col.accessor === 'function' &&
        !col.hideCSV &&
        ![
          'checkbox',
          'image',
          'qrCode',
          'itemId',
          'offerId',
          'buttons',
        ].includes(col.id)
      );
    });
  }

  makeData() {
    const headers = this.makeHeaders();
    const headerStrings = headers.map(col => String(col.Header));
    const dataStrings = (this.props.dataForButtonActions || []).map(item =>
      headers.map(col => String(col.accessor(item)))
    );

    return [headerStrings, ...dataStrings];
  }

  render() {
    return (
      <div style={styles.container}>
        <CSVLink
          ref={node => (this.csvLink = node)}
          filename={`${getConfig('name')} ${this.props.title}.csv`}
          data={this.makeData()}
        >
          <button className="btn btn-primary" style={styles.button}>
            CSV
          </button>
        </CSVLink>
      </div>
    );
  }
}

styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: 2,
    marginRight: 12,
    marginBottom: 2,
    marginLeft: 0,
    padding: '2px 5px',
    height: 24,
    width: 60,
  },
};

TableButtonCSV.defaultProps = {};

TableButtonCSV.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  dataForButtonActions: PropTypes.array,
  selectedData: PropTypes.array,
  headers: PropTypes.array,
};

export default TableButtonCSV;
