import React, { Component } from "react";
import _ from "lodash";
import $ from "jquery";
window.$ = window.jQuery = $;

class AddHyperLotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: ""
    };
  }

  createHyperLotAction(e) {}

  onEventNameChanged(e) {
    this.setState({
      eventName: e.currentTarget.value
    });
  }

  renderItems(hyperLot) {
    if (hyperLot.items) {
      const items = hyperLot.items.map((item, index) => {
        return (
          <li key={index}>
            <input
              type="radio"
              name="event_name"
              value={item.eventName}
              checked={this.state.eventName === item.eventName}
              onChange={this.onEventNameChanged}
            />
            {item.eventName}
          </li>
        );
      });

      return items;
    }
  }

  render() {
    return (
      <div className="modal fade" id="addHyperLotModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title">
                <span className="fa fa-table" />
                Add to HyperLot
              </h3>
            </div>
            <ul className="list-unstyled padding">
              {this.renderItems(this.props.hyperLot)}
            </ul>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.createHyperLotAction}
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddHyperLotModal;
