import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import OfferModal from '../OfferModal';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadBuyerOffers,
  loadItemUpdateOffer,
  resetBuyerOffers,
  loadBidHistory,
  resetBidHistory,
} from '../../actions';
import {
  commafyCurrency,
  compose,
  getConfig,
  getTemplate,
} from '../../utils/helpers';
import { dataPlaceholder } from '../../utils/constants';
import styles from './styles';
import $ from 'jquery';
import 'bootstrap';

class BuyerOffers extends Component {
  state = {
    title: 'buyer offers',
    offerType: '',
    offerId: 0,
    offerAmount: 0,
    requiredAmount: 0,
    item: {},
    headers: [],
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetBuyerOffers();
    this.props.loadBuyerOffers(getConfig('marketplaceId'));
  };

  determineModalId = itemId => {
    return `buyerOffers${itemId || this.state.item.itemId}`;
  };

  handleOfferModal = (row, offerType) => {
    const { buyerOffers } = this.props;
    const { itemId, offerId, amount, requiredAmount } = row;
    const item = find(buyerOffers.data, { offerId: Number(offerId) });
    const updatedState = {
      offerType,
      offerId,
      offerAmount: amount,
      item,
    };

    if (offerType === 'Buyer Counter') {
      updatedState.requiredAmount = requiredAmount;
    }

    this.setState(updatedState, () => {
      this.props.resetBidHistory();
      this.props.loadBidHistory(getConfig('marketplaceId'), itemId);
      const modalId = this.determineModalId(itemId);
      $('#offerModal' + modalId).modal('show');
      $('#offerModal' + modalId + '.in').modal('show');
    });
  };

  handleAccept = row => {
    confirm('Are you sure you want to accept this offer?').then(
      () => {
        const mpId = getConfig('marketplaceId');
        const { amount, offerId, itemId } = row;
        const statusId = 12; // buyer accepted
        this.props
          .loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
          .then(({ response }) => {
            if (response) this.loadData();
          });
      },
      () => null
    );
  };

  handleDecline = row => {
    confirm('Are you sure you want to decline this offer?').then(
      () => {
        const mpId = getConfig('marketplaceId');
        const { amount, offerId, itemId } = row;
        const statusId = 11; // buyer rejected
        this.props
          .loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
          .then(({ response }) => {
            if (response) this.loadData();
          });
      },
      () => null
    );
  };

  handleWithdraw = row => {
    confirm('Are you sure you want to cancel this offer?').then(
      () => {
        const mpId = getConfig('marketplaceId');
        const { amount, offerId, itemId } = row;
        const statusId = 2; // buyer rejected
        this.props
          .loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
          .then(({ response }) => {
            if (response) this.loadData();
          });
      },
      () => null
    );
  };

  handleRaise = row => {
    this.handleOfferModal(row, 'Raise Offer');
  };

  handleCounter = row => {
    this.handleOfferModal(row, 'Buyer Counter');
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  sellerWants = (status, requiredAmount) => {
    if (status === 'Buyer Cancelled' || status === 'Seller Rejected') {
      return dataPlaceholder;
    }

    if (requiredAmount) {
      return commafyCurrency(requiredAmount);
    }

    if (!requiredAmount && status === 'Active') {
      return 'Awaiting Response';
    }

    return dataPlaceholder;
  };

  render() {
    const loading = isEmpty(this.props.buyerOffers);
    const data = (this.props.buyerOffers.data || []).map(item => {
      // NOTE - this is a workaround for the backend... if the vehicle has been Sold and the status still shows "Active" fix it
      if (item.listingStatusId === 4 && item.status === 'Active') {
        item.status = item.listingStatus;
        item.bidderName = dataPlaceholder;
      }
      return item;
    });
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'seller',
      'sellerWants',
      'bidder',
      'buyerOffer',
      'status',
      'buyNow',
      'offerDate',
      {
        Header: 'Actions',
        headerStyle: { background: 'none' },
        id: 'buttons',
        sortable: false,
        filterable: false,
        Cell: row => {
          const showAcceptButton = ['Seller Countered'].includes(
            row.original.status
          );
          const showCounterButton = ['Seller Countered'].includes(
            row.original.status
          );
          const showDeclineButton = ['Seller Countered'].includes(
            row.original.status
          );
          const showRaiseButton = ['Active'].includes(row.original.status);
          const showCancelButton = ['Active', 'Buyer Countered'].includes(
            row.original.status
          );

          return (
            <div style={styles.buttonGroup}>
              <Button
                style={{
                  ...styles.button,
                  backgroundColor: getTemplate(
                    this.props.template,
                    'misc.medGray'
                  ),
                }}
                onClick={() =>
                  this.handleDetailsClick(row.original.itemId, data)
                }
              >
                View Details
              </Button>

              <div style={styles.counterButtons}>
                {showAcceptButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.green'
                      ),
                    }}
                    onClick={() => this.handleAccept(row.original)}
                    disabled={!this.props.userProfile.user}
                  >
                    Accept
                  </Button>
                )}

                {showCounterButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.orange'
                      ),
                    }}
                    onClick={() => this.handleCounter(row.original)}
                    disabled={!this.props.userProfile.user}
                  >
                    Counter
                  </Button>
                )}

                {showDeclineButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.red'
                      ),
                    }}
                    onClick={() => this.handleDecline(row.original)}
                    disabled={!this.props.userProfile.user}
                  >
                    Decline
                  </Button>
                )}
              </div>

              <div style={styles.raiseButtons}>
                {showRaiseButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.green'
                      ),
                    }}
                    onClick={() => this.handleRaise(row.original)}
                    disabled={!this.props.userProfile.user}
                  >
                    Raise
                  </Button>
                )}

                {showCancelButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.red'
                      ),
                    }}
                    onClick={() => this.handleWithdraw(row.original)}
                    disabled={!this.props.userProfile.user}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          );
        },
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Offers</h4>
        <OfferModal
          item={this.state.item}
          modalId={this.determineModalId()}
          offerAmount={this.state.offerAmount}
          offerId={this.state.offerId}
          offerType={this.state.offerType}
          requiredAmount={this.state.requiredAmount}
          user={this.props.userProfile.user}
          loadData={this.loadData}
        />
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { buyerOffers, template, userProfile } = state.entities;
  return { buyerOffers, template, userProfile };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadBuyerOffers,
    loadItemUpdateOffer,
    resetBuyerOffers,
    loadBidHistory,
    resetBidHistory,
  })
)(BuyerOffers);
