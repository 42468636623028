import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { values, isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import BidHistoryModal from '../common/BidHistoryModal';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadBidHistory,
  loadSellerBids,
  loadSellNow,
  resetSellerBids,
} from '../../actions';
import {
  commafy,
  compose,
  getConfig,
  makeReadableDate,
  mergeBidHistory,
  sortDate,
} from '../../utils/helpers';
import { dataPlaceholder } from '../../utils/constants';
import styles from './styles';

class SellerBids extends Component {
  state = {
    title: 'seller bids',
    bidHistoryItemId: '',
    bidHistories: {},
    isBidHistoryModalOpen: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerBids();
    this.props.loadSellerBids(getConfig('marketplaceId'));
    // this.props.loadSellerBids(getConfig('marketplaceId')).then(response => {
    //   this.loadBidHistories(response.response.data);
    // });
  };

  /** **TODO** - 4.22.2024 - Remove; bid history fetching moved to modal. Leaving for reference.
   * 
   * @param {object[]} items 
   */
  loadBidHistories = items => {
    axios
      .all(
        items.map(item =>
          this.props.loadBidHistory(getConfig('marketplaceId'), item.itemId)
        )
      )
      .then(responses => {
        if (responses) {
          const bidHistories = responses.reduce(
            (bidHistoryObj, response, index) => {
              const itemId = items[index].itemId;
              const bidHistoryData = response.response.data || [];
              const realtimeBidHistoryData =
                this.props.bidMessageHistoryRealTime || [];

              // mergeBidHistory handles filtering out offers
              const mergedBidHistory = mergeBidHistory(
                itemId,
                bidHistoryData,
                realtimeBidHistoryData
              );

              return { ...bidHistoryObj, [itemId]: mergedBidHistory };
            },
            {}
          );

          this.setState({ bidHistories, bidHistoryItemId: '' });
        }
      })
      .catch(error => console.error(error));
  };

  handleBidHistoryModal = row => {
    this.setState({
      bidHistoryItemId: String(row.itemId),
      isBidHistoryModalOpen: true,
    });
  };

  handleSellNow = row => {
    const mpId = getConfig('marketplaceId');

    confirm('Are you sure you want to sell this vehicle now?').then(
      () => {
        this.props.loadSellNow(mpId, row.itemId, false).then(data => {
          if (
            data.response.wsMessage ===
            'Unable to end sale, the current bids have not met the reserve.'
          ) {
            confirm(
              'High bid does not meet reserve. Would you like to lower reserve and Sell Now?'
            ).then(
              () => {
                this.props.loadSellNow(mpId, row.itemId, true);
                this.loadData();
              },
              () => null
            );
          }
        });
      },
      () => null
    );
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  renderNumberOfBids = row => {
    return commafy(row?.bids || dataPlaceholder);
  };

  renderLatestBidDate = row => {
    return row?.latestBidDate 
      ? makeReadableDate(row.latestBidDate)
      : dataPlaceholder;
  };

  render() {
    const loading = isEmpty(this.props.sellerBids);
    const data = this.props.sellerBids.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'vin',
      'year',
      'make',
      'model',
      'type',
      'reservePrice',
      {
        Header: 'Bids',
        accessor: row => this.renderNumberOfBids(row),
        id: 'bids',
        filterable: false,
      },
      'currentHighBid',
      {
        Header: 'Latest Bid',
        accessor: row => this.renderLatestBidDate(row),
        id: 'latestBid',
        filterable: false,
        sortMethod: sortDate,
      },
      {
        Header: 'Actions',
        id: 'buttons',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        Cell: row => (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() => this.handleDetailsClick(row.original.itemId, data)}
            >
              View Details
            </Button>
            <Button
              style={styles.button}
              onClick={() => this.handleBidHistoryModal(row.original)}
            >
              Bid History
            </Button>
            <Button
              style={styles.button}
              onClick={() => this.handleSellNow(row.original)}
            >
              Sell Now
            </Button>
          </div>
        ),
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Active Bids</h4>

        <div>
        {this.state.isBidHistoryModalOpen
          ? <BidHistoryModal
              itemId={this.state.bidHistoryItemId || ""}
              userProfile={this.props.userProfile}
              isOpen={this.state.isBidHistoryModalOpen}
              onClose={() => this.setState({ isBidHistoryModalOpen: false })}
            />
          : ''
        }

          <DataTable
            loading={loading}
            title={this.state.title}
            data={data}
            columns={columns}
            showCSVButton={true}
            showPrintButton={true}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    bidHistory,
    bidMessageHistoryRealTime,
    sellerBids,
    sellNow,
    userProfile,
  } = state.entities;
  return {
    bidHistory,
    bidMessageHistoryRealTime,
    sellerBids,
    sellNow,
    userProfile,
  };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadBidHistory,
    loadSellerBids,
    loadSellNow,
    resetSellerBids,
  })
)(SellerBids);
