var localization = {
  "en-us": {
    color: "Color",
    tires: "Tires",
    currency: "$"
  },
  "en-uk": {
    color: "Colour",
    tires: "Tyres",
    currency: "\u00A3"
  }
};

module.exports = { localization };
