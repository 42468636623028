import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from './MenuItem';
import Loader from '../../ui/loader';
import { compose } from '../../../utils/helpers';
import withRouter from '../../../../../hocs/withRouter';

class Sidebar extends Component {
  render() {
    const { coreSections } = this.props;

    return (
      <div className="form-template-sidebar">
        {coreSections.length ? (
          coreSections.map((section, index) => (
            <MenuItem
              key={section.section + index}
              section={section}
              {...this.props}
            />
          ))
        ) : (
          <Loader
            className="landing-page-modal-width"
            textVisibility={false}
            strokeWidth={2}
            style={{ width: '100%', textAlign: 'center', marginTop: 20 }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { coreSections } = state.core;
  return { coreSections };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
  )
)(Sidebar);
