import React, { Component } from 'react';
import Button from '../../ui/buttons';
import Icon from '../../ui/icons';
import { GREY_01, GREY_02, CYAN_01 } from '../../../constants/colors';

// NOT USING
class Search extends Component {
  state = {
    selectedTab: 'vehicle',
    searchText: '',
    openModal: false,
  };
  render() {
    const { selectedTab, searchText } = this.state;
    return (
      <div className="search-main-container">
        <div className="search-tabs flex">
          <Button
            btnText="Vehicle"
            height="42px"
            outlinedTextColor={GREY_02}
            outlinedBorderColor={GREY_01}
            outlined={selectedTab === 'vehicle' ? false : true}
            onClickHandler={() => this.setState({ selectedTab: 'vehicle' })}
          />
          <Button
            btnText="Account"
            height="42px"
            outlinedTextColor={GREY_02}
            outlinedBorderColor={GREY_01}
            outlined={selectedTab === 'account' ? false : true}
            onClickHandler={() => this.setState({ selectedTab: 'account' })}
          />
          <Button
            btnText="Lot #"
            height="42px"
            outlinedTextColor={GREY_02}
            outlinedBorderColor={GREY_01}
            outlined={selectedTab === 'lot' ? false : true}
            onClickHandler={() => this.setState({ selectedTab: 'lot' })}
          />
          <Button
            btnText="Stock #"
            height="42px"
            outlinedTextColor={GREY_02}
            outlinedBorderColor={GREY_01}
            outlined={selectedTab === 'stock' ? false : true}
            onClickHandler={() => this.setState({ selectedTab: 'stock' })}
          />
        </div>
        <div className="search-container">
          <div className="search-field-container">
            <input
              type="text"
              value={searchText}
              onChange={e => this.setState({ searchText: e.target.value })}
              className="search-box"
              placeholder="Search"
            />
            <div
              className="flex justify-center items-center mr-4"
              style={{ visibility: searchText !== '' ? 'visible' : 'hidden' }}
              onClick={() => this.setState({ searchText: '' })}
            >
              <Icon name="Cross" size={24} color={CYAN_01} />
            </div>
          </div>
          <div className="flex justify-end items-center">
            <label>
              <input type="checkbox" />
              Gatepassed &amp; Redmeed
            </label>
            <label>
              <input type="checkbox" />
              Pickup Log
            </label>
            <Button
              btnText="Search"
              height="43px"
              borderRadius="10px"
              outlined={true}
              iconName="Search"
              marginRight="12px"
              iconSize={18}
            />
          </div>
        </div>
        <div className="search-toggles items-center">
          <label>
            <input type="checkbox" />
            Gatepassed &amp; Redmeed
          </label>
          <label>
            <input type="checkbox" />
            Pickup Log
          </label>
        </div>
        <div className="search-btn-mob">
          <Button
            btnText="Search"
            height="43px"
            borderRadius="10px"
            outlined={true}
            iconName="Search"
            marginRight="16px"
            iconSize={18}
          />
        </div>
      </div>
    );
  }
}

export default Search;
