import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { findIndex } from 'lodash';
import { loadVisited } from '../../../../../../actions';
import ToastContent from '../../../ui/toastContent';
import Button from '../../../ui/buttons';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import { compose } from '../../../../utils/helpers';
import withRouter from '../../../../../../hocs/withRouter';

class FormFooter extends Component {
  state = {
    open: false,
    title: '',
    message: '',
    btnText: '',
    action: null,
    closeBtnVisible: true,
  };

  handleNext = () => {
    const { nextPath, currentSection, coreSections, coreValidationError } =
      this.props;

    if (coreValidationError) {
      toast(<ToastContent status="error" text={coreValidationError} />, {
        className: 'review-toast',
        toastId: coreValidationError,
      });
    } else {
      let path = '';

      if (nextPath) {
        // explicit
        path = nextPath;
      } else if (currentSection) {
        // implicit
        const currentSectionIndex = findIndex(coreSections, {
          section: currentSection,
        });

        path =
          currentSectionIndex > -1 && coreSections[currentSectionIndex + 1]
            ? coreSections[currentSectionIndex + 1].path
            : '';

        this.props.loadVisited(currentSection);
      }

      toast.dismiss();
      this.props.navigate(path);
    }
  };

  handleCancelConfirmation = () => {
    const { entryType, saleDate } = this.props.item;
    const path = `/inventory/${
      entryType === 'Inventory' || !saleDate ? 'unlisted' : 'listed'
    }`;
    this.props.navigate(path, { replace: true });
  };

  handleCancel = () => {
    this.setState({
      open: true,
      title: 'Alert',
      message: 'Are you sure you want to go back?',
      btnText: 'Ok',
      action: this.handleCancelConfirmation,
      closeBtnVisible: true,
    });
  };

  render() {
    return (
      <div>
        <ConfirmationModal
          open={this.state.open}
          title={this.state.title}
          body={this.state.message}
          iconName="CircledExclamationmark"
          close={() => this.setState({ open: false })}
          action={this.state.action}
          confirmBtnTxt={this.state.btnText}
          closeBtnVisible={this.state.closeBtnVisible}
        />
        <div className="form-footer">
          <Button
            btnText="Cancel"
            height="42px"
            outlined={true}
            onClickHandler={this.handleCancel}
            iconName="Cross"
            iconSize={26}
            marginRight="10px"
          />
          <Button
            btnText="Next"
            height="42px"
            iconName="ChevronRight"
            iconSize={16}
            iconOnRight
            marginLeft="12px"
            onClickHandler={this.handleNext}
          />
        </div>
      </div>
    );
  }
}

FormFooter.propTypes = {
  nextPath: PropTypes.string,
  currentSection: PropTypes.string,
};

const mapStateToProps = state => {
  const { item, coreSections, coreValidationError } = state.core;
  return { item, coreSections, coreValidationError };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { loadVisited })
)(FormFooter);
