import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, sortBy, values } from 'lodash';
import DocumentTitle from 'react-document-title';
import {
  loadBiddingOn,
  loadBuyNow,
  loadBuyerBids,
  loadEvents,
  loadFeaturedES,
  loadNoSalesFromEventsES,
  loadRecentlyAdded,
  loadRecentlyViewed,
  loadRunningToday,
  loadSellingNow,
  loadUserItems,
  loadWatchlistES,
  loadWatchlistUserItems,
} from '../actions';
import { getConfig, makeTitle } from '../utils/helpers';
import Vehicle from './Vehicle';
import VehicleListItem from './VehicleListItem';
import BackButton from './BackButton';
import ItemsPageNav from './ItemsPageNav';
require('bootstrap');

const initialState = {
  filteredList: '',
  filterValue: '',
  sortOption: 'Recently Added',
  view: 'grid',
  itemsList: [],
  loading: false,
  itemsPerPage: 100,
};

class ItemsPage extends Component {
  state = initialState;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadData();
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(initialState, () => {
        this.loadData();
      });
    }

    if (this.state.itemsList !== prevState.itemsList) {
      const itemIds = this.state.itemsList
        .map(item => item.id || item._id)
        .filter(id => id)
        .join(',');

      sessionStorage.setItem('navItems', itemIds);
    }
  }

  loadData() {
    this.handleBreadcrumbs();

    const { pathname, unauthorizedEventIds } = this.props.location;
    const { itemsPerPage } = this.state;

    this.props
      .loadWatchlistUserItems(getConfig('marketplaceId'))
      .then(({ response }) => {
        if (response && response.wsStatus === 'Success') {
          const ids = response.items.map(item => item.itemId);
          this.props
            .loadWatchlistES(ids, itemsPerPage, unauthorizedEventIds)
            .catch(error => console.error(error));
        }
      })
      .catch(error => console.error(error));

    this.setState({ loading: true }, () => {
      switch (pathname) {
        case '/biddingon':
          this.loadBiddingOn();
          break;
        case '/buynow': // currently not a route
          this.loadBuyNow();
          break;
        case '/nosales':
          this.loadNoSales();
          break;
        case '/recentlyadded':
          this.loadRecentlyAdded();
          break;
        case '/recentlyviewed':
          this.loadRecentlyViewed();
          break;
        case '/runningtoday':
          this.loadRunningToday();
          break;
        case '/sellingnow':
          this.loadSellingNow();
          break;
        case '/watchlist':
          this.loadWatchlistData();
          break;
        case '/featured':
          this.loadFeatured();
          break;
        default:
          return null;
      }
    });
  }

  loadWatchlistData() {
    this.props
      .loadWatchlistUserItems(getConfig('marketplaceId'))
      .then(({ response }) => {
        if (response && response.wsStatus === 'Success') {
          const ids = response.items.map(item => item.itemId);
          this.props
            .loadWatchlistES(
              ids,
              this.state.itemsPerPage,
              this.props.unauthorizedEventIds
            )
            .then(({ response }) => {
              if (response) {
                const watchlistES = get(response, 'hits.hits', []);
                this.setState({
                  itemsList: watchlistES,
                  loading: false,
                });
              }
            })
            .catch(error => console.error(error));
        }
      })
      .catch(error => console.error(error));
  }

  loadSellingNow() {
    this.props
      .loadSellingNow(this.state.itemsPerPage, this.props.unauthorizedEventIds)
      .then(({ response }) => {
        if (response) {
          const sellingNow = get(response, 'hits.hits', []);
          this.setState({
            itemsList: sellingNow,
            loading: false,
          });
        }
      })
      .catch(error => console.error(error));
  }

  loadRecentlyAdded() {
    this.props
      .loadRecentlyAdded(
        this.props.listingStatusIds,
        this.state.itemsPerPage,
        this.props.unauthorizedEventIds
      )
      .then(({ response }) => {
        if (response) {
          const recentlyAdded = get(response, 'hits.hits', []);
          this.setState({
            itemsList: recentlyAdded,
            loading: false,
          });
        }
      })
      .catch(error => console.error(error));
  }

  loadRecentlyViewed() {
    this.props
      .loadUserItems(getConfig('marketplaceId'))
      .then(({ response }) => {
        if (response && response.itemList) {
          const userItems = response.itemList;
          this.props
            .loadRecentlyViewed(
              userItems,
              this.state.itemsPerPage,
              this.props.unauthorizedEventIds
            )
            .then(({ response }) => {
              if (response) {
                const recentlyViewed = get(response, 'hits.hits', []);
                const recentlyViewdSorted = recentlyViewed.sort((a, b) => {
                  return (
                    userItems.indexOf(a._source.id) -
                    userItems.indexOf(b._source.id)
                  );
                });

                this.setState({
                  itemsList: recentlyViewdSorted,
                  loading: false,
                });
              }
            })
            .catch(error => console.error(error));
        }
      })
      .catch(error => console.error(error));
  }

  loadNoSales() {
    this.props
      .loadEvents(getConfig('marketplaceId'))
      .then(({ response }) => {
        if (response) {
          const eventIds = response.eventList
            .filter(event => {
              return (
                // must be Live Auction Event  AND marketplace must not have no-sales disabled
                event.eventTypeId === 3 &&
                !(event.marketplace.marketplaceFeaturesArray || []).includes(
                  '106'
                )
              );
            })
            .map(event => event.eventId);

          this.props
            .loadNoSalesFromEventsES(
              eventIds,
              this.state.itemsPerPage,
              this.props.unauthorizedEventIds
            )
            .then(({ response }) => {
              if (response) {
                const noSales = get(response, 'hits.hits', []);
                this.setState({
                  itemsList: noSales,
                  loading: false,
                });
              }
            })
            .catch(error => console.error(error));
        }
      })
      .catch(error => console.error(error));
  }

  loadRunningToday() {
    this.props
      .loadRunningToday(
        this.state.itemsPerPage,
        this.props.unauthorizedEventIds
      )
      .then(({ response }) => {
        if (response) {
          const runningToday = get(response, 'hits.hits', []);
          this.setState({
            itemsList: runningToday,
            loading: false,
          });
        }
      })
      .catch(error => console.error(error));
  }

  loadBuyNow() {
    this.props
      .loadBuyNow(this.state.itemsPerPage, this.props.unauthorizedEventIds)
      .then(({ response }) => {
        if (response) {
          const buyNow = get(response, 'hits.hits', []);
          this.setState({
            itemsList: buyNow,
            loading: false,
          });
        }
      })
      .catch(error => console.error(error));
  }

  loadBiddingOn() {
    this.props
      .loadBuyerBids(getConfig('marketplaceId'))
      .then(({ response }) => {
        if (response && response.wsStatus === 'Success') {
          if (response.data.length) {
            const ids = values(response.data).map(item => item.itemId);
            this.props
              .loadBiddingOn(
                ids,
                this.state.itemsPerPage,
                this.props.unauthorizedEventIds
              )
              .then(({ response }) => {
                if (response) {
                  const biddingOn = get(response, 'hits.hits', []);
                  this.setState({
                    itemsList: biddingOn,
                    loading: false,
                  });
                }
              })
              .catch(error => console.error(error));
          } else {
            this.setState({
              itemsList: [],
              loading: false,
            });
          }
        }
      })
      .catch(error => console.error(error));
  }

  loadFeatured() {
    return this.props
      .loadFeaturedES(this.state.itemsPerPage, this.props.unauthorizedEventIds)
      .then(({ response }) => {
        if (response) {
          const featured = get(response, 'hits.hits', []);
          this.setState({
            itemsList: featured,
            loading: false,
          });
        }
      })
      .catch(error => console.error(error));
  }

  handleBreadcrumbs = () => {
    const { location } = this.props;
    const text =
      get(location, 'state.routeName') || makeTitle(location.pathname);

    const bcCrumbs = {
      path: location.pathname,
      crumbs: [{ text: 'Home', link: '/' }, { link: location.pathname, text }],
    };
    localStorage.setItem('bcCrumbs', JSON.stringify(bcCrumbs));
  };

  updateView = view => {
    this.setState({ view: view });
  };

  changeSort = event => {
    this.setState({ sortOption: event.target.value });
  };

  searchItemList = event => {
    const filterValue = event.target.value;
    this.setState(prevState => ({
      filterValue,
      filteredList: this.getFilteredItems(filterValue, prevState.itemsList),
    }));
  };

  getFilteredItems(filterValue, items) {
    if (filterValue === '') return items;

    const mod = term => {
      return String(term)
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    };

    return items.filter(item => {
      return (
        mod(item._source.make) ||
        mod(item._source.model) ||
        mod(item._source.year) ||
        mod(item._source.sellerName) ||
        mod(item._source.exteriorColor) ||
        mod(item._source.interiorColor)
      );
    });
  }

  getSortedValue(sorting, list) {
    if (!this.state.itemsList || !this.state.itemsList.length) {
      // default sorting
      return list.sort((a, b) => {
        return moment
          .utc(a._source.dateCreated)
          .diff(moment.utc(b._source.dateCreated));
      });
    }

    // sorta special case
    if (sorting === 'Recently Added') {
      return sortBy(list, item => {
        return this.state.itemsList
          .map(item => item.itemId || item._id)
          .indexOf(Number(item._id));
      });
    }

    // the typical sorts
    return list.sort((a, b) => {
      switch (sorting) {
        case 'Make':
          return a._source.make.trim().toUpperCase() <
            b._source.make.trim().toUpperCase()
            ? -1
            : 1;

        case 'Model':
          return a._source.model.trim().toUpperCase() <
            b._source.model.trim().toUpperCase()
            ? -1
            : 1;

        case 'Year - Latest First':
          return b._source.year - a._source.year;

        case 'Year - Oldest First':
          return a._source.year - b._source.year;

        case 'Seller Name':
          return a._source.sellerName.trim().toUpperCase() <
            b._source.sellerName.trim().toUpperCase()
            ? -1
            : 1;

        case 'End Time':
          return b._source.epochEndTime - a._source.epochEndTime;

        case 'Lot Number - Ascending':
          return a._source.lotNumber < b._source.lotNumber ? -1 : 1;

        case 'Lot Number - Descending':
          return b._source.lotNumber < a._source.lotNumber ? -1 : 1;

        case `Odometer - Lowest First`:
          return a._source.mileage - b._source.mileage;

        case `Odometer - Highest First`:
          return b._source.mileage - a._source.mileage;

        case 'Price - Lowest First':
          return a._source.outrightPrice - b._source.outrightPrice;

        case 'Price - Highest First':
          return b._source.outrightPrice - a._source.outrightPrice;

        default:
          return -1;
      }
    });
  }

  renderList() {
    const messageStyle = {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 400,
      fontWeight: 'bold',
    };

    if (this.state.loading) {
      const messages = 'Loading...';
      return <div style={messageStyle}>{messages}</div>;
    }

    if (!this.state.loading && !this.state.itemsList.length) {
      const message = `There are currently no items`;
      return <div style={messageStyle}>{message}</div>;
    }

    const list = this.getSortedValue(
      this.state.sortOption,
      this.state.filteredList || this.state.itemsList
    ).map((item, index, arr) => {
      const itemData = {
        ...item._source,
        ...this.props.bidMessagesRealTime[item._id],
      };
      const navItems = arr.map(item => item.id || item._id).join(',');
      const title =
        get(this.props.location, 'state.routeName') ||
        makeTitle(this.props.location.pathname);

      return this.state.view === 'grid' ? (
        <Vehicle
          key={item._id}
          vehicleData={itemData}
          homePage={this.props.homePage}
          category={title}
          navItems={navItems}
        />
      ) : (
        <VehicleListItem
          key={item._id}
          vehicleData={itemData}
          homePage={this.props.homePage}
          category={title}
          navItems={navItems}
        />
      );
    });

    return list;
  }

  render() {
    const { filterValue, itemsList, view } = this.state;
    const { location } = this.props;
    const title =
      get(location, 'state.routeName') || makeTitle(location.pathname);

    return (
      <DocumentTitle
        id={title}
        title={title}
        ref={node => (this.pageRef = node)}
      >
        <div>
          <ItemsPageNav
            location={location}
            filterValue={filterValue}
            itemsList={itemsList}
            name={title}
            pageRef={this.pageRef}
            searchHandler={this.searchItemList}
            sortHandler={this.changeSort}
            title={title}
            updateView={this.updateView}
            view={view}
          />
          <div className="container run-list-container">
            <BackButton />
            {this.renderList()}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ItemsPage.propTypes = {
  watchlistES: PropTypes.object.isRequired,
  loadBiddingOn: PropTypes.func.isRequired,
  loadBuyNow: PropTypes.func.isRequired,
  loadBuyerBids: PropTypes.func.isRequired,
  loadEvents: PropTypes.func.isRequired,
  loadNoSalesFromEventsES: PropTypes.func.isRequired,
  loadRecentlyAdded: PropTypes.func.isRequired,
  loadRecentlyViewed: PropTypes.func.isRequired,
  loadRunningToday: PropTypes.func.isRequired,
  loadSellingNow: PropTypes.func.isRequired,
  loadUserItems: PropTypes.func.isRequired,
  loadWatchlistES: PropTypes.func.isRequired,
  loadWatchlistUserItems: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    bidMessagesRealTime,
    listingStatusIds,
    unauthorizedEventIds,
    watchlistES,
  } = state.entities;
  return {
    bidMessagesRealTime,
    listingStatusIds,
    unauthorizedEventIds,
    watchlistES,
  };
};

export default connect(
  mapStateToProps,
  {
    loadBiddingOn,
    loadBuyerBids,
    loadBuyNow,
    loadEvents,
    loadFeaturedES,
    loadNoSalesFromEventsES,
    loadRecentlyAdded,
    loadRecentlyViewed,
    loadRunningToday,
    loadSellingNow,
    loadUserItems,
    loadWatchlistES,
    loadWatchlistUserItems,
  }
)(ItemsPage);
