import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import xml2js from 'xml2js';
import ImageGallery from 'react-image-gallery';
import { values } from 'lodash';
import { Link } from 'react-router-dom';
import { loadEventStatus, loadEventCommand } from '../../actions';
import { EventStatus } from './EventStatus';
import ConditionReport from '../ConditionReport';

class Simulcast extends Component {
  state = {
    title: '',
    ask: '',
    bid: '',
    bidder: '',
    status: '',
    updated: '',
    timer: '',
  };

  componentDidMount() {
    this.updateEventStatus();
  }

  placeBid = e => {
    e.preventDefault();

    const status = EventStatus(
      this.props.eventStatus.statusData,
      this.props.eventStatus.eventInfo.marketplace.currency
    );

    if (status.ask !== '' && status.ask !== 0) {
      this.props.loadEventCommand(
        this.props.params.marketplaceId,
        this.props.params.eventId,
        this.props.eventStatus.statusData.cl,
        status.ask,
        localStorage.getItem('userId'),
        '38'
      );
    }
  };

  updateEventStatus() {
    this.props.loadEventStatus(
      this.props.params.marketplaceId,
      this.props.params.eventId
    );

    setTimeout(() => {
      this.updateEventStatus();
    }, 500);
  }

  updateLights(items) {
    let lights = {
      one: '#ccc',
      two: '#ccc',
      three: '#ccc',
    };

    items.forEach((value, index) => {
      switch (index) {
        case 0:
          lights.one = this.getColor(value);
          break;
        case 1:
          lights.two = this.getColor(value);
          break;
        case 2:
          lights.three = this.getColor(value);
          break;
        default:
          break;
      }
    });

    return lights;
  }

  getColor(color) {
    switch (color) {
      case 'Green':
        return '#008040';
      case 'Yellow':
        return '#ffff00';
      case 'Red':
        return '#ff0000';
      case 'Blue':
        return '#004080';
      case 'White':
        return '#ffff';
      case 'Purple':
        return '#800080';
      default:
        return '#ccc';
    }
  }

  loadImages() {
    const imagesArray = this.props.eventStatus.item.imageFullUrls;

    const galleryImages = values(imagesArray).map(image => {
      return {
        original: image ? image.replace(/^http:\/\//i, 'https://') : '',
        thumbnail: image ? image.replace(/^http:\/\//i, 'https://') : '',
      };
    });

    return galleryImages;
  }

  loadOptions() {
    const optionsArray = this.props.eventStatus.item.options
      .replace(/,\s*$/, '')
      .split(',');

    const options = values(optionsArray).map(option => {
      return <li key={option}>{option}</li>;
    });

    return options;
  }

  findMileageType = () => {
    // this logic will change when mileageType comes through in the item api.
    const { marketplaceFeatures } = this.props;
    let units = '';
    if (marketplaceFeatures.features) {
      const features = (marketplaceFeatures.features || '').split(',');
      units = features.includes('389') ? 'Kilometers' : 'Miles';
    }
    return units;
  };

  renderBidButton(title) {
    return (
      <Link to="#" className="vdp-button" onClick={this.placeBid}>
        {title}
      </Link>
    );
  }

  renderCrLink() {
    let crXml = this.props.item.cr.conditionReport;

    if (crXml !== '') {
      let crLink;
      const parser = new xml2js.Parser();
      parser.parseString(crXml, (err, result) => {
        crLink = result;
      });

      if (crLink.CR.$.Type === '4') {
        return (
          <li>
            <Link to={crLink.CR._} target="_blank" className="vdp-button">
              <span className="fa fa-file" /> View CR
            </Link>
          </li>
        );
      }
    }
  }

  renderCr() {
    let crXml = this.props.eventStatus.cr.conditionReport;

    if (crXml !== '') {
      let crLink;
      const parser = new xml2js.Parser();
      parser.parseString(crXml, (err, result) => {
        crLink = result;
      });

      if (crLink.CR.$.Type === '3') {
        return (
          <div className="vdp-section">
            <h4>
              <span className="fa fa-file" /> Condition Report
            </h4>
            <hr />
            <ConditionReport cr={crLink.CR.DM} />
          </div>
        );
      }
    }
  }

  renderImageGallery() {
    const images = this.loadImages();

    return (
      <ImageGallery
        ref={i => (this._imageGallery = i)}
        items={images}
        slideInterval={2500}
      />
    );
  }

  render() {
    const itemData = this.props.eventStatus.item;
    const mileageType = this.findMileageType();

    if (this.props.eventStatus.wsStatus !== 'Success') {
      return (
        <DocumentTitle title={`Simulcast`}>
          <div className="container-fluid" />
        </DocumentTitle>
      );
    }

    const status = EventStatus(
      this.props.eventStatus.statusData,
      this.props.eventStatus.eventInfo.marketplace.currency
    );

    return (
      <DocumentTitle title={`Simulcast`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <ul className="vdp-actions pull-left">
                <li>{this.renderBidButton(status.title)}</li>
              </ul>
            </div>
            <div className="col-md-9 top-margin">
              <div className="background-white shadow vdp-section">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="vehicle-title">
                      {itemData.year} {itemData.make} {itemData.model} \
                      {itemData.bodyType}
                    </h3>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">{this.renderImageGallery()}</div>
                  <div className="col-md-7">
                    <div className="vehicle-info">
                      <h4>
                        <span className="fa fa-car" /> Vehicle Details
                      </h4>
                      <table className="table table-striped pull-left">
                        <tbody>
                          <tr>
                            <td>VIN</td>
                            <td>{itemData.vin}</td>
                          </tr>
                          <tr>
                            <td>{mileageType}</td>
                            <td />
                          </tr>
                          <tr>
                            <td>Color</td>
                            <td>{itemData.extColor}</td>
                          </tr>
                          <tr>
                            <td>Interior Color</td>
                            <td>{itemData.intColor}</td>
                          </tr>
                          <tr>
                            <td>Interior Type</td>
                            <td>{itemData.intType}</td>
                          </tr>
                          <tr>
                            <td>Drivetrain</td>
                            <td>{itemData.driveType}</td>
                          </tr>
                          <tr>
                            <td>Transmission</td>
                            <td>{itemData.transmission}</td>
                          </tr>
                          <tr>
                            <td>Engine</td>
                            <td>{itemData.engine}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="vdp-section">
                      <h4>
                        <span className="fa fa-bullhorn" /> Announcements
                      </h4>
                      <hr />
                      <p>
                        {itemData.description} <br /> {itemData.announcements}
                      </p>
                    </div>
                    <div className="vdp-section">
                      <h4>
                        <span className="fa fa-box" /> Options
                      </h4>
                      <hr />
                      <ul className="vdp-options">{this.loadOptions()}</ul>
                    </div>
                    <div className="image-thumbnails" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Simulcast.propTypes = {
  loadEventStatus: PropTypes.func.isRequired,
  loadEventCommand: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { eventStatus, eventCommand, marketplaceFeatures },
  } = state;

  return { eventStatus, eventCommand, marketplaceFeatures };
};

export default connect(mapStateToProps, {
  loadEventStatus,
  loadEventCommand,
})(Simulcast);
