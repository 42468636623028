import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback2DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#646464"
            d="M565.7,348.6v79.8c0,1.8-1.5,3.3-3.3,3.3H534c-1.8,0-3.3-1.5-3.3-3.3V394 M311,394.1v34.4
	c0,1.8-1.5,3.3-3.3,3.3h-28.5c-1.9,0-3.3-1.5-3.3-3.3v-79.8"
          />
          <path
            fill="#FFFFFF"
            d="M560.8,278.4c0,0-3.3-8-6.7-11.1c-8.5-33.1-17.9-58.6-27-74.6c-5.9-10.4-17.2-8.4-17.2-8.4s-15-3.3-87.5-4.7
	h-3c-72.5,1.5-87.5,4.7-87.5,4.7s-11.4-2.1-17.2,8.4c-9.2,16.2-18.5,41.7-27.1,74.6c-2.8,3.7-6.5,11.1-6.5,11.1
	c-1.8,4.9-3.3,10.8-4.6,17c-1.5,7.6-2.5,20.8-2.5,28.9c0,9.5,4,35.4,7.6,52.3c4,17.8,14.4,17.9,14.4,17.9l16-0.3l0.3-0.1
	c0.7-2.7,2.5-4.4,4.9-5.8c0,0,2.1-6.4,7.9-6.4c4,0,7.4-0.3,9.6,0c2.4,0.3,4.6,1.3,5.6,1.9c1.2,0.7,3.3,1.5,5.5,1.5h162.5
	c0,0,17.2-0.6,19.7,8.9l1.8,0.1l16,0.3c0,0,10.5-0.1,14.4-17.9c3.7-17,7.7-43,7.7-52.3c0-8.2-1-21.3-2.5-28.9
	C564.1,289,562.4,283.3,560.8,278.4L560.8,278.4z"
          />
          <path
            fill="#C8C8C8"
            d="M557.3,262.1v-1.6l-2.7,4.6C556.4,264.9,557.3,262.1,557.3,262.1L557.3,262.1z"
          />
          <path
            fill="#FFFFFF"
            d="M578,256.1c-3.3-0.3-11.3-0.6-16-0.3c-4.3,0.4-4.7,2.5-4.7,4.6c0,0,0.6,1.3,1.6,4.3c0.9,2.5,2.5,4.7,3.9,7
	c1,2.2,3.1,3.9,9.3,3.4c3-0.3,7.6-1,11.9-2.1c5.9-1.3,4.7-2.7,2.7-11.7C585.4,256.3,581.3,256.6,578,256.1L578,256.1z M279.3,271.7
	c1-2.2,2.8-4.4,3.9-7c1-3,1.5-4.3,1.5-4.3c0-2.1-0.4-4.1-4.7-4.6c-4.7-0.3-12.9-0.1-16,0.3c-3.1,0.4-7.3,0.3-8.4,5.3
	c-2.1,9-3.3,10.4,2.7,11.7c4.1,0.9,8.9,1.8,11.9,2.1C276.2,275.4,278.1,273.9,279.3,271.7L279.3,271.7z"
          />
          <path
            fill="#C8C8C8"
            d="M287.3,265l-2.8-4.6v1.6C284.5,262.1,285.5,264.9,287.3,265L287.3,265z"
          />
          <path
            fill="#EBEBEB"
            d="M332.1,207.1l-13,81.2h-11.3c-2.4,0-9.6,1.2-7.3-9.6c2.4-10.8,11.4-49.2,17.3-61.5c4.3-9,9.6-12.3,13.8-13.3
	C332.8,203.5,332.2,205.3,332.1,207.1 M509.8,207.1l12.9,81.2h11.4c2.4,0,9.6,1.2,7.3-9.6c-2.4-10.8-11.4-49.2-17.3-61.5
	c-4.3-9-9.8-12.3-13.9-13.3C508.9,203.5,509.7,205.3,509.8,207.1"
          />
          <path
            fill="#EBEBEB"
            d="M509.8,207.1l9.5,59.3l0.6-0.1h-99.5h1H322h0.6l9.5-59.3l0.1-3.4c19.9-5,81.4-5.5,89.2-5.3h-1
	c7.9-0.3,69.5,0.1,89.2,5.3L509.8,207.1L509.8,207.1z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M398.8,203.5l0.3,1.2c0.3,2.8,1.6,4.4,5.2,4.4h17H421h17
	c3.3,0,4.7-1.5,5-3.9l0.4-1.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M398.8,203.5l0.3,1.2c0.3,2.8,1.6,4.4,5.2,4.4h17H421h17
	c3.3,0,4.7-1.5,5-3.9l0.4-1.5 M381.7,310.5h80.2v-18.1h-80.2V310.5z M421.2,323.9c1.9,0,3.6-1.5,3.6-3.4c0-1.8-1.6-3.3-3.6-3.3
	s-3.6,1.5-3.6,3.3S419.2,323.9,421.2,323.9 M477.1,366.6c2.8,0,5.2-2.2,5.2-5c0-2.7-2.4-4.9-5.2-4.9s-5.2,2.2-5.2,4.9
	C471.9,364.4,474.2,366.6,477.1,366.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M506.7,184.2c-0.9,1.3-1.8,6.4-2.5,10.8 M477.1,366.6c2.8,0,5.2-2.2,5.2-5
	c0-2.7-2.4-4.9-5.2-4.9s-5.2,2.2-5.2,4.9C471.9,364.4,474.2,366.6,477.1,366.6L477.1,366.6z M529.8,394.1l16,0.3
	c0,0,10.5-0.1,14.4-17.9c3.7-17,7.7-43,7.7-52.3c0-8.2-1-21.3-2.5-28.9c-1.2-6.2-2.8-12-4.6-17c0,0-3.3-8-6.7-11.1 M571.9,275.1
	c0,0-7.7,1.5-11.3,3.3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M529.8,394.1c1.2-0.7,12.8-5.6,17.6-20.2c5.3-15.4,7.7-28.9,9.3-41.5
		c0.2-1.5,2.1-14.2,1.9-17c2.2-21.9,7.6-15,8-8.3 M557.3,260.4c0,0,0.6,1.3,1.6,4.3c0.9,2.5,2.5,4.7,3.9,7c1,2.2,3.1,3.9,9.3,3.4
		c3-0.3,7.6-1,11.9-2.1c5.9-1.3,4.7-2.7,2.7-11.7c-1.2-5-5.3-4.9-8.5-5.3c-3.3-0.3-11.3-0.6-16-0.3
		C557.7,256.3,557.3,258.4,557.3,260.4l0,1.6c0,0-0.9,2.7-2.7,3"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M530.1,345.1c2.4,0.6,11.4,1.6,13.9,2.5 M530.7,339.3h17.9v-7.7
	 M480.9,288.3h38.2 M539.9,270.7c3.9,14.7,8.5,35.6,8.7,60.9 M365.6,290.7c-0.3-1.6-0.3-2.7-0.3-3.3c0-2.1,5.2-4.6,5.2-4.6h101
	c0,0,5.3,2.7,5.3,4.6c0,0.3-0.1,1.2-0.3,2.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M476.5,289.8c-0.9,6.1-3.6,21.3-7.1,23.6c-4.4,2.5-4,2.1-6.5,2.1h-42.7
	h-40.9c-2.5,0-2.2,0.3-6.5-2.1c-3.4-2.1-6.2-16.2-7-22.7"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M365.7,289.5h110.4 M330,184.2c0.7,1.5,1.8,7.1,2.4,12 M335.2,184.2
		c0.9,1.5,1.8,6.7,2.5,11.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M312.1,394.1l-16,0.3c0,0-10.5-0.1-14.4-17.9c-3.6-17-7.6-43-7.6-52.3
		c0-8.2,1-21.3,2.5-28.9c1.2-6.2,2.8-12,4.6-17c0,0,3.7-7.4,6.5-11.1c8.6-33.1,17.9-58.6,27.1-74.6c5.8-10.4,17.2-8.4,17.2-8.4
		s15-3.3,87.5-4.7h3c72.3,1.5,87.5,4.7,87.5,4.7s11.4-2.1,17.2,8.4c9,16.2,18.5,41.7,27,74.6 M269.8,275.1c0,0,7.7,1.5,11.3,3.3
		 M332.4,196.4c0.3,3,0.4,5.6,0.1,7.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M311,394.1c-1.2-0.7-11.7-5.6-16.8-20.2c-5.2-15.4-7.6-28.9-9.2-41.5
		c-0.3-1.5-2.1-14.2-1.9-17c-2.2-21.9-7.6-15-8.2-8.3"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M281.5,373.5c0,0,11.4-0.9,12.5-1.5c1.2-0.4,5.2-1.6,9.6-2.1
		c4.3-0.6,15-0.9,15-0.9H523c0,0,10.7,0.3,15,0.9c4.3,0.4,8.5,1.6,9.6,2.1c1,0.6,12.5,1.5,12.5,1.5 M284.5,260.4
		c0,0-0.4,1.3-1.5,4.3c-1,2.5-2.7,4.7-3.9,7s-3.1,3.9-9.3,3.4c-3-0.3-7.7-1-11.9-2.1c-5.9-1.3-4.7-2.7-2.7-11.7c1-5,5.3-4.9,8.4-5.3
		c3.3-0.3,11.3-0.6,16-0.3C284,256.3,284.5,258.4,284.5,260.4v1.6c0,0,1,2.7,2.8,3"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M316.4,380.4c0-1.8,3.9-3.3,4.7-3.3h199.8c0.9,0,4.7,1.5,4.7,3.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M314.4,389.7c0.9-2.1,1.3-10.4,1.3-13.8c0-3.3,5.8-2.5,5.8-2.5h198.7
	c0,0,5.8-0.9,5.8,2.5s0.9,13,1.8,15.3 M311.2,288.7v50.5c0,6.7,7.3,5.3,7.3,5.3h205c0,0,7.3,1.3,7.3-5.3v-50.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M293.2,339.3v6.7c0,1.6,0.9,2.8,3.7,3.7c3,0.9,23.3-0.3,23.3-0.3h201.5
	c0,0,20.3,1,23.1,0.3c3-0.9,3.9-2.1,3.9-3.7v-6.7 M311.6,345.1c-2.2,0.6-11.3,1.6-13.8,2.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M275.4,339.6l9.9-8.3h25.5 M311.2,339.3h-17.9v-7.7 M316.1,331.3h211.4
	 M321.8,266.4h99.5h-1h99.5 M530.9,331.3h25.5l9.9,8.3 M361,288.3h-38.4 M332.1,207.1l-13,81.2h-11.3c-2.4,0-9.6,1.2-7.3-9.6
	c2.4-10.8,11.4-49.2,17.3-61.5c4.3-9,9.6-12.3,13.8-13.3C332.8,203.5,332.2,205.3,332.1,207.1"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M332.1,207.1l-13,81.2h-11.3c-2.4,0-9.6,1.2-7.3-9.6
		c2.4-10.8,11.4-49.2,17.3-61.5c4.3-9,9.6-12.3,13.8-13.3C332.8,203.5,332.2,205.3,332.1,207.1 M511.7,184.2c-0.6,1-1.5,4.9-1.9,8.9
		"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M509.8,193.1c-0.1,0.9-0.3,1.5-0.3,2.4c-0.4,3.3-0.4,6.2-0.3,7.9
	 M509.8,207.1l12.9,81.2h11.4c2.4,0,9.6,1.2,7.3-9.6c-2.4-10.8-11.4-49.2-17.3-61.5c-4.3-9-9.8-12.3-13.9-13.3
	C508.9,203.5,509.7,205.3,509.8,207.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M333.9,208.2c21.6-4.6,79.9-5,87.6-4.9h-1c7.6-0.3,65.8,0.3,87.5,4.9
	 M332.8,196.8c20.6-4.7,80.8-5.3,88.5-5h-1c7.6-0.3,66.6,0.3,88.5,4.9 M330,204.4c17.2-5.6,83.3-6.1,91.3-5.9h-1
	c8.2-0.3,74.3,0.3,91.3,5.9 M509.8,207.1l12.9,81.2h11.4c2.4,0,9.6,1.2,7.3-9.6c-2.4-10.8-11.4-49.2-17.3-61.5
	c-4.3-9-9.8-12.3-13.9-13.3C508.9,203.5,509.7,205.3,509.8,207.1 M420,250.5c-29.6,0-35.6,7.9-35.6,7.9h-33.5c0,0-9.9-6.2-9.8-9.3
	c0.3-3.1,5.8-25.8,7-33.4c0.7-5,10.1-6.7,10.1-6.7h63h-0.4h63c0,0,9.3,1.5,10.1,6.7c1.2,7.6,6.7,30.2,7,33.4s-0.3,9.3-9.8,9.3h-33.5
	c0,0-6.1-7.9-35.6-7.9L420,250.5 M301.8,270.7c-3.9,14.7-8.5,35.6-8.6,60.9 M565.7,348.6v79.8c0,1.8-1.5,3.3-3.3,3.3H534
	c-1.8,0-3.3-1.5-3.3-3.3V394 M311,394.1v34.4c0,1.8-1.5,3.3-3.3,3.3h-28.5c-1.9,0-3.3-1.5-3.3-3.3v-79.8 M407.1,275.1
	c-0.1,3.9,8.7,5,13.6,5c6.1,0,14.2-1.6,14.1-5.2c0-3.7-8.2-5.2-13.3-5C416.3,269.9,407.2,271.3,407.1,275.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M407.1,275.1c-0.1,3.9,8.7,5,13.6,5c6.1,0,14.2-1.6,14.1-5.2
	c0-3.7-8.2-5.2-13.3-5C416.3,269.9,407.2,271.3,407.1,275.1L407.1,275.1z M296.5,263.1c-2.1,0.3-7.9,2.7-9.9,5.3 M314.7,193.6
	c-3.3,7-7.7,18.7-9.6,24.9c-3.7,11.4-11.4,38.1-12.7,45.8 M545.2,263.1c1.9,0.3,7.7,2.7,9.9,5.3 M527,193.6c3.3,7,7.6,18.7,9.5,24.9
	c3.9,11.4,11.4,38.1,12.7,45.8 M317.4,388.8c2.5,2.4,7.3,4,12.6,4c6.2,0,11.6-2.2,13.6-5.5c0,0,0.3-0.6,0.7-1.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M325.3,385.4c-0.3,0.4-0.4,0.9-0.4,1.5c0,1.9,2.4,3.4,5.2,3.4
	c2.8,0,5.2-1.5,5.2-3.4c0-0.4-0.1-0.9-0.4-1.5l-0.1-0.3c-0.7-1.2-2.5-2.1-4.7-2.1S325.8,384.1,325.3,385.4 M317.4,388.8
	c2.5,2.4,7.3,4,12.6,4c6.2,0,11.6-2.2,13.6-5.5c0,0,0.3-0.6,0.7-1.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M528.1,394.1c-2.5-9.5-19.7-8.9-19.7-8.9H346c-2.1,0-4.3-0.7-5.5-1.5
	c-1.2-0.6-3.3-1.6-5.6-1.9c-2.2-0.3-5.5,0-9.6,0c-5.8,0-7.9,6.4-7.9,6.4c-2.4,1.3-4.1,3.3-4.9,5.8"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M332.2,203.8l0.1,1l-0.1,1.2
		l-9.8,60.5l196.9,0.1l-9.8-61.7l0.3-1c0,0-19.4-5.3-88.8-5.3C351.3,198.5,332.2,203.8,332.2,203.8z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M318.9,288.3h-13.3
		c0,0-6.7,0.9-5.6-9c0,0,10.7-45.5,16.2-58.4c0,0,5.2-15.3,16-17.2l0.1,1L318.9,288.3z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M509.7,203.8l-0.3,1
		l13.3,83.5h13.2c0,0,3.6,0.3,5-2.2c0,0,1.5-1.6,0.6-6.2c0,0-3.4-16.6-10.5-42.4c0,0-3.1-12.9-8.3-22.7
		C522.9,214.9,518.6,205.6,509.7,203.8z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M311.2,288.3v51
		c0,0-0.1,6.5,7.3,5.3h205c0,0,7.3,1.2,7.3-5.3v-51h-7.9l-3.6-21.9l-98.4-0.1h-98.4l-3.6,21.9h-7.7L311.2,288.3L311.2,288.3z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M275.4,339.6l9.9-8.3h25.9
		v8.3c0,0-0.1,6.1,7.3,5H421h102.4c0,0,7.3,1,7.3-5v-8.3h25.8l9.9,8.4c0,0-1.6,11.6-2.4,15.9c-0.7,4.3-3.4,18.7-3.4,18.7
		s-1.8,9.8-5.2,14.1c0,0-3.6,5.6-9.6,6.4l-15.7-0.3l-2.1-0.1c0,0-0.9-7.9-16.9-8.7h-1.8H347.6c0,0-3.7,0.3-6.5-1
		c0,0-3.9-2.4-7.7-2.4l-8.6,0.1c0,0-5-0.1-7.4,6.5c0,0-3.6,1.3-5,5.8l-0.9,0.1l-15.3,0.3c0,0-5.9,0-10.7-8c0,0-3-5-3.9-10.7
		l-2.1-10.4C279.4,365.5,276.6,351.3,275.4,339.6z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback2DrRear);
