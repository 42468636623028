import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Routes, Route, Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';
import LoadingBar from 'react-redux-loading-bar';
import {
  loadAuthorizedEvents,
  loadCarfaxCode,
  loadEvents,
  loadUnauthorizedEventIds,
  loadUserProfile,
} from '../actions';
import {
  compose,
  getConfig,
  getParamFromUrl,
  loadApiKey,
  parseValue,
  startOver,
  getEnv,
} from '../utils/helpers';
import withRouter from '../hocs/withRouter';
import packageJSON from '../../package.json';

// Marketplace route imports
import AllActivity from '../components/AllActivity';
import Calendar from '../components/Calendar';
import ChildMarketplaces from '../components/ChildMarketplaces';
import ConditionReportConsolidated from '../components/ConditionReportConsolidated';
import Consignors from '../components/Consignors';
import ContactUs from '../components/info/ContactUs';
import Privacy from '../components/info/Privacy';
import Default from '../components/Default';
import Events from '../components/Events';
import Faq from '../components/info/Faq';
import Footer from '../components/Footer';
import GatePasses from '../components/GatePasses';
import HolmanContactUs from '../components/info/HolmanContactUs';
import Home from '../components/Home';
import Inventory from '../components/Inventory';
import ItemsPage from '../components/ItemsPage';
import LiveBar from '../components/LiveBar';
import Login from '../components/Login';
import MarketplaceSearch from '../components/MarketplaceSearch';
import Notifications from '../components/Notifications';
import Profile from '../components/accountSettings/Profile';
import PurchaseSummary from '../components/buyerActivity/PurchaseSummary';
import Reports from '../components/Reports';
import RunListEvent from '../components/runLists/RunListEvent';
import RunListNoSales from '../components/runLists/RunListNoSales';
import Search from '../components/Search';
import Settings from '../components/Settings';
import Simulcast from '../components/simulcast';
import Status from '../components/Status';
import Test from '../components/Test';
import TopNav from '../components/TopNav';
import VehicleDetails from '../components/VehicleDetails';
import Wishlist from '../components/wishlist';

// CoRe imports
import Core from '../components/core';
import Additional from '../components/core/components/screens/form/Additional';
import Condition from '../components/core/components/screens/form/condition';
import ConditionInfo from '../components/core/components/screens/form/condition/conditionInfo';
import ConditionReview from '../components/core/components/screens/form/condition/ConditionReview';
import Damage from '../components/core/components/screens/form/condition/Damage';
import Details from '../components/core/components/screens/form/details/Details';
import Equipment from '../components/core/components/screens/form/Equipment';
import HeavyTruck from '../components/core/components/screens/form/heavyTruck/HeavyTruck';
import Information from '../components/core/components/screens/form/information';
import LandingPage from '../components/core/components/screens/landing';
import Media from '../components/core/components/screens/form/Media';
import Parts from '../components/core/components/screens/form/condition/Parts';
import Pricing from '../components/core/components/screens/form/Pricing';
import Question from '../components/core/components/screens/form/Question';
import QuickReview from '../components/core/components/screens/form/condition/QuickReview';
import Review from '../components/core/components/screens/form/Review';
import SearchResults from '../components/core/components/screens/SearchResults';
import SelectAxle from '../components/core/components/screens/form/information/wheels/SelectAxle';
import Severity from '../components/core/components/screens/form/condition/Severity';
import VehicleDetailsView from '../components/core/components/screens/vehicleDetailsView';
import Wheels from '../components/core/components/screens/form/information/wheels';

const UNPROTECTED_PATHS = ['/login', '/default', '/info', '/condition'];
const styles = {
  page: { minHeight: '100vh', display: 'flex', flexDirection: 'column' },
  loadingBar: { backgroundColor: '#777777', height: 5, zIndex: 99 },
};

const Router = props => {
  const [hasApiKey, setHasApiKey] = useState(undefined);
  const [hasUser, setHasUser] = useState(false);
  const [hasAuthEvents, setHasAuthEvents] = useState(false);
  const [gaIdLoaded, setGAIDLoaded] = useState(undefined);

  const loadGAID = () => {
    const gaId = getConfig('gaId');
    if (gaId) ReactGA.initialize(gaId);
    setGAIDLoaded(!!gaId);
  };

  const checkRedirects = useCallback(() => {
    const carfaxCode = parseValue(getParamFromUrl('code'));
    const carfaxRedirectPath = localStorage.getItem('carfaxRedirectPath');
    const postLoginRedirect = sessionStorage.getItem('postLoginRedirect');

    if (carfaxCode && carfaxRedirectPath) {
      props.loadCarfaxCode(carfaxCode);
      localStorage.removeItem('carfaxRedirectPath');
      props.navigate(carfaxRedirectPath);
    } else if (postLoginRedirect) {
      sessionStorage.removeItem('postLoginRedirect');
      props.navigate(postLoginRedirect);
    }
  }, [props]);

  const checkApiKey = useCallback(async () => {
    const apiKey = await loadApiKey();
    setHasApiKey(!!apiKey);
  }, []);

  const checkUser = useCallback(async () => {
    await loadApiKey();
    const mpId = getConfig('marketplaceId');
    const user = await props
      .loadUserProfile(mpId)
      .then(({ response }) =>
        response && response.user ? response.user : null
      )
      .catch(err => null);
    setHasUser(!!user);
  }, [props]);

  const checkAuthEvents = useCallback(async () => {
    await loadApiKey();
    const authEvents = await new Promise(async (resolve, reject) => {
      const mpId = getConfig('marketplaceId');
      const [events, authorizedEvents] = await Promise.all([
        props
          .loadEvents(mpId)
          .then(({ response }) => (response ? response.eventList : null))
          .catch(err => null),

        props
          .loadAuthorizedEvents(mpId)
          .then(({ response }) => (response ? response.authorizedEvents : null))
          .catch(err => null),
      ]);

      const unauthorizedEventIds = (events || [])
        .filter(event => {
          const isPrivate = event.isPrivateEvent === true;
          const isUnauthorized = !(authorizedEvents || [])
            .map(event => event.eventId)
            .includes(event.eventId);
          return isPrivate && isUnauthorized;
        })
        .map(event => event.eventId);

      props.loadUnauthorizedEventIds(unauthorizedEventIds);

      const result = {
        events,
        authorizedEvents,
        unauthorizedEventIds,
      };
      resolve(result);
    });
    setHasAuthEvents(Object.values(authEvents).every(val => !!val));
  }, [props]);

  const logVersion = () => {
    const version =
      `>> Marketplace Web v${packageJSON.version}(${packageJSON.build}) ` +
      `${getEnv()}`;

    console.info(version);
  };

  // MOUNT
  useEffect(() => {
    loadGAID();
    logVersion();
  }, []);

  // GOOGLE ANALYTICS
  useEffect(() => {
    if (gaIdLoaded) {
      ReactGA.set({ page: props.location.pathname });
      ReactGA.send(props.location.pathname);
    }
  }, [gaIdLoaded, props.location]);

  // HANDLE AUTH CHECKS ON REDIRECTS
  useEffect(() => {
    const needsAuth = !UNPROTECTED_PATHS.some(path =>
      (props.location.pathname || '').includes(path)
    );

    if (needsAuth) {
      checkApiKey();
      checkUser();
      checkAuthEvents();
    }
  }, [checkApiKey, checkUser, checkAuthEvents, props.location.pathname]);

  // HANDLE ANY POST-AUTH REDIRECTS
  useEffect(() => {
    if (hasApiKey && hasUser && hasAuthEvents) {
      checkRedirects();
    }
  }, [hasApiKey, hasUser, hasAuthEvents, checkRedirects]);

  // HANDLE NO AUTH
  useEffect(() => {
    if (hasApiKey === false && props.location.pathname !== '/login') {
      setHasApiKey(undefined);
      setHasUser(false);
      setHasAuthEvents(false);
      startOver();
    }
  }, [hasApiKey, props.location.pathname]);

  return (
    <Routes>
      {/* MARKETPLACE - UNPROTECTED */}
      <Route path="/login" element={<Login {...props} />} />
      <Route path="/info/contact-us" element={<ContactUs {...props} />} />
      <Route path="/info/faq" element={<Faq {...props} />} />
      <Route path="/info/privacy-policy" element={<Privacy {...props} />} />

      <Route path="/info/holman-faq" element={<Faq {...props} />} />
      <Route
        path="/info/holman-contact-us"
        element={<ContactUs {...props} />}
      />

      <Route
        path="/condition"
        element={<ConditionReportConsolidated {...props} />}
      />
      <Route path="/default" element={<Default {...props} />} />

      {/* CORE - PROTECTED */}
      <Route
        path="/core/*"
        element={
          <>
            <LoadingBar style={styles.loadingBar} />
            <Core {...props}>{hasApiKey && <Outlet {...props} />}</Core>
          </>
        }
      >
        <Route path="home" element={<LandingPage {...props} />} />
        <Route path="details" element={<Details {...props} />} />
        <Route path="information" element={<Information {...props} />} />
        <Route path="wheels" element={<Wheels {...props} />} />
        <Route path="wheels/axles" element={<SelectAxle {...props} />} />
        <Route path="heavyTruck" element={<HeavyTruck {...props} />} />
        <Route path="equipment" element={<Equipment {...props} />} />
        <Route path="question" element={<Question {...props} />} />
        <Route path="media" element={<Media {...props} />} />
        <Route path="condition" element={<Condition {...props} />} />
        <Route path="condition/parts" element={<Parts {...props} />} />
        <Route path="condition/damage" element={<Damage {...props} />} />
        <Route path="condition/severity" element={<Severity {...props} />} />
        <Route path="condition/info" element={<ConditionInfo {...props} />} />
        <Route
          path="conditionReview"
          element={<ConditionReview {...props} />}
        />
        <Route path="conditionReview/parts" element={<Parts {...props} />} />
        <Route path="conditionReview/damage" element={<Damage {...props} />} />
        <Route
          path="conditionReview/severity"
          element={<Severity {...props} />}
        />
        <Route
          path="conditionReview/info"
          element={<ConditionInfo {...props} />}
        />
        <Route
          path="conditionReview/view"
          element={<QuickReview {...props} />}
        />
        <Route path="additional" element={<Additional {...props} />} />
        <Route path="pricing" element={<Pricing {...props} />} />
        <Route path="search" element={<SearchResults {...props} />} />
        <Route path="review" element={<Review {...props} />} />
        <Route
          path="vehicleDetails"
          element={<VehicleDetailsView {...props} />}
        />
      </Route>

      {/* MARKETPLACE - PROTECTED */}
      <Route
        path="/*"
        element={
          <div style={styles.page}>
            <LoadingBar style={styles.loadingBar} />
            {hasApiKey && <TopNav {...props} />}
            {hasApiKey && hasUser && hasAuthEvents && <Outlet {...props} />}
            {hasApiKey && hasUser && hasAuthEvents && <Footer {...props} />}
            {hasApiKey && hasUser && hasAuthEvents && <LiveBar {...props} />}
          </div>
        }
      >
        <Route path="" element={<Home {...props} />} />
        <Route path="home" element={<Home {...props} />} />(
        <Route path="search" element={<Search {...props} />} />)
        <Route path="Runlist/:eventId" element={<RunListEvent {...props} />} />
        <Route path="runlist/nosales" element={<RunListNoSales {...props} />} />
        <Route
          path="marketplace-search/:marketplaceId(/:logoUrl)"
          element={<MarketplaceSearch {...props} />}
        />
        <Route path="events" element={<Events {...props} />} />
        <Route path="consignors" element={<Consignors {...props} />} />
        <Route path="watchlist" element={<ItemsPage {...props} />} />
        <Route path="sellingnow" element={<ItemsPage {...props} />} />
        <Route path="recentlyadded" element={<ItemsPage {...props} />} />
        <Route
          path="recentlyviewed"
          element={<ItemsPage {...props} wtf="yeah stf" />}
        />
        <Route path="featured" element={<ItemsPage {...props} />} />
        <Route path="nosales" element={<ItemsPage {...props} />} />
        <Route path="runningtoday" element={<ItemsPage {...props} />} />
        <Route path="buynow" element={<ItemsPage {...props} />} />
        <Route path="biddingon" element={<ItemsPage {...props} />} />
        <Route path="wishlist" element={<Wishlist {...props} />} />
        <Route path="savedsearches" element={<Wishlist {...props} />} />
        <Route path="status" element={<Status {...props} />} />
        <Route path="status/:tabId" element={<Status {...props} />} />
        <Route path="inventory" element={<Inventory {...props} />} />
        <Route path="inventory/:tabId" element={<Inventory {...props} />} />
        <Route path="settings" element={<Settings {...props} />} />
        <Route path="reports" element={<Reports {...props} />} />
        <Route path="item/:id" element={<VehicleDetails {...props} />} />
        <Route
          path="simulcast/:marketplaceId/:eventId"
          element={<Simulcast {...props} />}
        />
        <Route path="marketplaces" element={<ChildMarketplaces {...props} />} />
        <Route path="profile" element={<Profile {...props} />} />
        <Route path="profile/:tabId" element={<Profile {...props} />} />
        <Route path="myactivity" element={<AllActivity {...props} />} />
        <Route path="gatepass" element={<GatePasses {...props} />} />
        <Route path="notifications" element={<Notifications {...props} />} />
        <Route
          path="purchaseSummary"
          element={<PurchaseSummary {...props} />}
        />
        <Route path="calendar" element={<Calendar {...props} />} />
        <Route
          path="condition"
          element={<ConditionReportConsolidated {...props} />}
        />
        <Route path="test" element={<Test {...props} />} />
      </Route>
    </Routes>
  );
};

Router.propTypes = {
  history: PropTypes.object.isRequired, // from Root
  cookies: PropTypes.object.isRequired, // from Root
  location: PropTypes.object.isRequired, // from withRouter
  params: PropTypes.object.isRequired, // from withRouter
  navigate: PropTypes.func.isRequired, // from withRouter
};

export default compose(
  withRouter,
  connect(null, {
    loadAuthorizedEvents,
    loadCarfaxCode,
    loadEvents,
    loadUnauthorizedEventIds,
    loadUserProfile,
  })
)(Router);
