import React, { Component } from 'react';
import { connect } from 'react-redux';

class CrewCabPickup4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M795.6,244v-6.6c-0.2-1.3-1.2-2.1-2.7-2.3l-2-33c-1-10.1-4-16.1-8.9-17.8l-9.1-3.5c-5.5-2.7-8.8-2.5-9.9,0.4
	l-18.1-5.2c-10.4-2-22.8-2.8-37.3-2.5c-50.9-0.9-96.9-0.9-138.1,0l-8.9,3.3h-45c-0.2,0.1-0.5,0.2-0.8,0.4c-0.8,0.9-1.1,2.5-1,5.2
	c-2.3-4.2-6.1-6.3-11.3-6.2H363.7c-3.6-0.3-6.8-0.3-9.8,0h-76.5v-0.1c0.8-2.9,1.3-5.5,1.8-7.8c1.1-5.4,1.6-9.5,1.4-12
	c-0.2-0.9-0.5-1.5-1-2c-1-1.1-2.3-1.6-4.1-1.4c-0.6,0.1-1.3,0.2-2.1,0.4c0,0-0.1,0-0.1,0.1c-2.9,1.5-5.5,5.3-7.6,11.3
	c-1.1,2.9-2,6.4-2.8,10.4v0.1c0,0.3-0.1,0.8-0.1,1.1h-26.7c-1.1-0.9-2.9-1.6-5.5-2c-10.5-1.4-25.7-2.1-45.6-2
	c-30.3-0.4-52.2,0.4-65.9,2.5c-1.1-1.8-2.8-2.5-5.1-2.2c-27.7,0.3-45.8,10.5-54.6,30.4c-7.9,17.9-12.2,49.3-12.8,94v0.4
	c0.7,44.9,4.9,76.4,12.8,94.4c8.8,19.9,27,30.1,54.6,30.4c2.3,0.3,4-0.4,5.2-2.2c13.7,2.1,35.6,2.8,65.7,2.5c19.9,0.1,35-0.5,45.6-2
	c2.6-0.4,4.4-1.1,5.5-2h26.7c0,0.3,0.1,0.7,0.1,1.1v0.1c0.9,4,1.7,7.5,2.8,10.4c2.1,6,4.7,9.7,7.6,11.3l0.1,0.1
	c0.8,0.2,1.4,0.3,2.1,0.4c1.8,0.2,3.1-0.2,4.1-1.4c0.4-0.5,0.8-1.2,1-2c0.2-2.5-0.2-6.6-1.4-12c-0.5-2.4-1.1-5-1.8-7.8v-0.1h76.5
	c2.9,0.3,6.2,0.3,9.8,0h138.9c5.2,0.1,9-2,11.3-6.2c-0.1,2.7,0.2,4.3,1,5.2c0.2,0.2,0.4,0.3,0.8,0.4h45l8.9,3.3
	c41.2,0.9,87.3,0.9,138.1,0c14.5,0.3,26.9-0.5,37.3-2.5l18.1-5.2c1.1,3,4.3,3.1,9.9,0.4l9.1-3.5c4.9-1.7,7.9-7.7,8.9-17.8l2-33
	c1.5-0.2,2.5-1,2.7-2.3v-6.6c-0.3-1.4-1.1-2.4-2.1-3l1.1-18.2c0.2-5.3,0.4-10.6,0.7-15.8l0.1-15.9v-0.4l-0.1-15.9
	c-0.2-5.4-0.4-11-0.7-16.4l-1.1-18.2C794.5,246.3,795.2,245.4,795.6,244L795.6,244z M397.4,188.4c0.1-0.9,0.8-1.5,2-1.8
	s3-0.5,5.3-0.4h0.1l68.5,0.4c8.9,0.3,13.5,1.8,13.9,4.4c0.5,3.3-1.1,7.4-5.1,12.4c-2.5,2.3-7.9,3.5-16.4,3.5h-60.8
	c-6.6-0.1-10-1.2-10-3.4L397.4,188.4L397.4,188.4z M275.8,185l91.2,0.6c2.6,0,4.7,0.2,5.9,0.4c1,0.2,1.4,0.7,1.3,1.1l-1,15.6
	c-0.3,2.2-3.6,3.3-9.5,3.4l-21.6-0.5c-4.1-0.1-7.9-0.3-11.5-0.5c-10.5-0.6-19.1-1.6-25.5-3.1c-10.3-2.2-20-4.8-29.1-7.6l-0.2-8.1
	L275.8,185L275.8,185z M256,397.7l-0.5,0.1c-3.7,1.3-7.3,2.3-10.5,3c-1.6,0.4-3.3,0.8-4.8,1.1c-0.2,0-0.5,0.1-0.8,0.1
	c-3.6,0.6-7.3,1-10.8,1.1c-13.8-11.9-21-43-21.8-93.2c0-2.1,0-4.1-0.1-6.2v-12.3c0-2.2,0-4.2,0.1-6.3c0.8-50.2,8-81.3,21.8-93.2
	c3.6,0,7.3,0.3,10.9,1.1c0.2,0,0.5,0.1,0.8,0.1c1.5,0.3,3.1,0.6,4.8,1.1c3.3,0.9,6.8,1.8,10.5,3h0.5c15.8,5.6,29.7,9.9,41.9,12.6
	l-0.1,0.4C290.7,222,287,251,287,297.1v0.2c0,46.2,3.6,75.3,10.9,87.2l0.1,0.4C285.9,387.9,271.9,392.1,256,397.7L256,397.7z
	 M374.2,407.9c0.1,0.4-0.3,0.9-1.3,1.1c-1.3,0.2-3.3,0.4-5.9,0.4l-91.2,0.6l-0.2-1.3l0.2-8.1c9-2.8,18.8-5.3,29.1-7.6
	c6.5-1.4,15-2.5,25.5-3.1c3.6-0.2,7.4-0.4,11.5-0.5l21.7-0.5c6.1,0.1,9.2,1.3,9.5,3.4L374.2,407.9L374.2,407.9z M487.1,404.1
	c-0.3,2.6-5,4.1-13.9,4.4l-68.5,0.4h-0.1c-2.4,0.1-4.1,0-5.3-0.4c-1.2-0.3-1.8-1-2-1.8l-2.5-15.1c0-2.1,3.3-3.3,10-3.4h60.8
	c8.5,0,13.9,1.1,16.4,3.5C486,396.7,487.7,400.8,487.1,404.1z M518.6,333l-1.3,3.3v49.8c0,2.9-0.3,4.5-1.1,4.7v0.1
	c0,3.3-0.1,6.3-0.2,9.1c-1.3-4.2-2.9-8.6-5.1-12.8c-1.1-2.3-2.4-4.5-3.8-6.6c-0.2-0.3-0.5-0.8-0.9-1.1c-0.9-1.6-1.3-3.8-1.3-6.6
	c0.9-1.6,1.2-3.7,1.2-6.2V228.4c0-2.5-0.4-4.6-1.2-6.2c0-2.8,0.3-5.1,1.3-6.6c0.2-0.3,0.5-0.8,0.9-1.1c1.5-2.1,2.7-4.2,3.8-6.5
	c2.2-4.2,3.8-8.6,5.1-12.8c0.1,2.8,0.2,5.9,0.2,9.1v0.1c0.8,0.2,1.1,1.7,1.1,4.7v49.8l1.4,3.3V333H518.6L518.6,333z"
          />
          <path
            fill="#EAEAEA"
            d="M255.5,197.2c-3.7-1.3-7.3-2.3-10.5-3c-1.6-0.4-3.3-0.8-4.8-1.1c-0.2,0-0.5-0.1-0.8-0.1
	c-3.6-0.6-7.3-1-10.9-1.1c-13.8,11.9-21,43-21.8,93.2c0,2.1,0,4.2-0.1,6.3v12.3c0,2.1,0,4.1,0.1,6.2c0.8,50.2,8,81.4,21.8,93.2
	c3.6,0,7.3-0.3,10.9-1.1c0.2,0,0.5-0.1,0.8-0.1c1.5-0.3,3.1-0.6,4.8-1.1c3.3-0.9,6.8-1.8,10.5-3l0.5-0.1
	c15.8-5.6,29.7-9.9,41.9-12.6l-0.1-0.4c-7.2-11.8-10.9-41-10.9-87.4c0-46.1,3.6-75.1,10.9-86.9l0.1-0.4c-12-2.7-26-6.8-41.9-12.5
	L255.5,197.2L255.5,197.2z M275.6,186.2l0.2,8.1c9,2.8,18.8,5.3,29.1,7.6c6.5,1.4,15,2.5,25.5,3.1c3.6,0.2,7.4,0.4,11.5,0.5
	l21.6,0.5c6.1-0.1,9.2-1.3,9.5-3.4l1-15.6c0.1-0.4-0.3-0.9-1.3-1.1c-1.3-0.2-3.3-0.4-5.9-0.4l-91.2-0.6L275.6,186.2L275.6,186.2z
	 M404.8,186.1c-2.5-0.1-4.2,0-5.4,0.4c-1.2,0.3-1.8,1-2,1.8l-2.5,15.1c0,2.1,3.3,3.3,10,3.4h60.8c8.5,0,13.9-1.1,16.4-3.5
	c4-5,5.6-9.1,5.1-12.4c-0.3-2.6-5-4-13.9-4.4L404.8,186.1L404.8,186.1z M510.9,207.8c-1.1,2.3-2.4,4.4-3.8,6.5
	c-0.3,0.3-0.5,0.8-0.9,1.1c-0.9,1.6-1.3,3.8-1.3,6.6c0.9,1.6,1.2,3.7,1.2,6.2v138.2c0,2.5-0.4,4.6-1.2,6.2c0,2.8,0.3,5.1,1.3,6.6
	c0.2,0.3,0.5,0.8,0.9,1.1c1.5,2.1,2.7,4.2,3.8,6.6c2.2,4.2,3.8,8.6,5.1,12.8c0.1-2.8,0.2-5.9,0.2-9.1v-0.1c0.8-0.2,1.1-1.7,1.1-4.7
	V336l1.4-3.3v-71.1l-1.4-3.3v-49.6c0-2.9-0.3-4.4-1.1-4.7v-0.1c0-3.3-0.1-6.3-0.2-9.1C514.8,199.2,513.1,203.6,510.9,207.8
	L510.9,207.8z M275.6,408.6l0.2-8.1c9-2.8,18.8-5.3,29.1-7.6c6.5-1.4,15-2.5,25.5-3.1c3.6-0.2,7.4-0.4,11.5-0.5l21.6-0.5
	c6.1,0.1,9.2,1.3,9.5,3.4l1,15.6c0.1,0.4-0.3,0.9-1.3,1.1c-1.3,0.2-3.3,0.4-5.9,0.4l-91.2,0.6L275.6,408.6L275.6,408.6z
	 M404.8,408.8c-2.5,0.1-4.2,0-5.4-0.4c-1.2-0.3-1.8-1-2-1.8l-2.5-15.1c0-2.1,3.3-3.3,10-3.4h60.8c8.5,0,13.9,1.1,16.4,3.5
	c4,5,5.6,9.1,5.1,12.4c-0.3,2.6-5,4-13.9,4.5L404.8,408.8L404.8,408.8z"
          />
          <path
            fill="#5E5E5E"
            d="M113.9,172.8c-27.7,0.3-45.8,10.5-54.6,30.4c-7.9,17.9-12.2,49.3-12.8,94c0.1,4.9,0.2,9.5,0.3,14
	c0.9,25.5,3,46,6.5,61.6c0,0.1,0.1,0.3,0.1,0.4c1.6,7.4,3.6,13.6,5.9,18.8c8.8,19.9,27,30,54.6,30.4c2.3,0.3,4-0.4,5.2-2.2h-0.2
	c-3.8-0.5-6.9-1.2-9.4-2c0.2-2.5-1-4.1-3.4-4.9c-9-2.7-17.4-8.4-25.2-17.1c-2.7-3-5.3-6.3-7.8-10.1c-0.2,0-0.4,0-0.7-0.1
	c-0.4-0.1-1-0.3-1.4-0.7h0c-0.3-0.3-0.8-0.6-1.1-1c-0.5-0.5-1-1.2-1.4-1.8c-0.3-0.4-0.7-0.9-0.9-1.3l-0.7-1
	c-0.1-0.1-0.2-0.3-0.2-0.4c-0.5-0.9-1-1.8-1.4-2.7c-0.1-0.3-0.2-0.5-0.3-0.9c-1.1-2.9-2.1-6.5-2.9-10.6c-0.1-0.5-0.2-1.2-0.3-1.8
	c-0.1-0.5-0.2-1.1-0.2-1.6c-0.4-2.7-0.8-5.3-1.1-8c-0.4-3.3-0.8-6.5-1.1-9.8c-0.1-1.6-0.2-3.3-0.4-5c-0.1-1.3-0.2-2.6-0.2-4
	c0-0.5-0.1-1-0.1-1.5l-0.3-5.5l-0.1-2.2l-0.2-3.4l-0.3-5.5c-0.3-6.6-0.5-13.1-0.7-19.9c0.1-6.4,0.3-12.8,0.7-19.2l0.3-5.5l0.2-3.4
	l0.1-2.2l0.3-5.4c0-0.5,0.1-1.1,0.1-1.5c0.1-1.3,0.2-2.7,0.2-4c0.1-1.6,0.2-3.3,0.4-5c0.3-3.3,0.7-6.5,1.1-9.8
	c0.3-2.7,0.7-5.3,1.1-8c0.1-0.5,0.1-1.1,0.2-1.6c0.1-0.6,0.2-1.2,0.3-1.8c0.8-4.2,1.7-7.7,2.9-10.6c0.1-0.3,0.2-0.5,0.3-0.9
	c0.4-1,0.9-1.8,1.4-2.7c0.1-0.1,0.2-0.3,0.2-0.4c0.2-0.3,0.4-0.6,0.7-1c0.2-0.4,0.5-0.9,0.9-1.3c0.4-0.6,1-1.2,1.4-1.8
	c0.3-0.3,0.7-0.6,1.1-1h0.1c0.4-0.3,1-0.5,1.4-0.6c0.2-0.1,0.4-0.1,0.7-0.1c2.5-3.7,5.2-7.1,7.8-10.1c7.8-8.8,16.3-14.5,25.2-17.1
	c2.4-0.8,3.6-2.4,3.4-4.9c2.5-0.8,5.8-1.4,9.5-2C117.9,173.2,116.2,172.4,113.9,172.8L113.9,172.8z"
          />
          <path
            fill="#5E5E5E"
            d="M74.1,209.2h-0.3h-0.7c4.4-6.6,9.5-12.5,15.2-17.2c5.6-4.8,11.6-8,17.8-9.9c2.4-0.8,3.6-2.4,3.4-4.9
	c2.5-0.8,5.8-1.4,9.5-2c-1.1-1.8-2.8-2.5-5.1-2.2c-27.7,0.3-45.8,10.5-54.6,30.4c-7.9,17.9-12.2,49.3-12.8,94l0.3,14.1
	c0.9,25.5,2.9,46,6.5,61.6l1.3,5.2c0.9,3.3,1.8,6.3,2.8,9.1v0.1c0.7,1.6,1.2,3.3,2,4.8c8.8,19.9,27,30,54.6,30.4
	c2.3,0.3,4-0.4,5.2-2.2H119c-3.8-0.5-6.9-1.2-9.4-2c0.2-2.5-1-4.1-3.4-4.9c-6.2-1.8-12.2-5.1-17.8-9.9c-5.8-4.8-10.7-10.7-15.3-17.4
	h1.1c0.9-0.4,0.7-1.6-0.7-3.6c-0.5-1-1.1-2.1-1.5-3.1c-1.7-3.9-3.1-8.5-4.3-13.5c0-0.1-0.1-0.3-0.1-0.4c-0.2-1.1-0.4-2.3-0.7-3.5
	c-0.5-2.7-1-5.6-1.3-8.5c-0.2-2.3-0.4-4.6-0.7-6.7c-0.2-1.5-0.3-3.1-0.4-4.7c-0.1-0.8-0.1-1.4-0.2-2.2l-0.3-4.6
	c-0.1-0.9-0.1-1.6-0.2-2.5c-0.2-3.1-0.4-6.3-0.7-9.5c-0.1-2-0.2-4-0.3-6.1v-0.6c-0.3-6.3-0.5-12.6-0.5-19c0.1-6.2,0.3-12.3,0.5-18.3
	v-0.6c0.1-2.1,0.2-4,0.3-6.1c0.2-3.1,0.4-6.4,0.7-9.5c0-0.9,0.1-1.6,0.2-2.5l0.3-4.6c0-0.8,0.1-1.4,0.2-2.2c0.1-1.5,0.3-3.1,0.4-4.7
	c0.2-2.3,0.4-4.6,0.7-6.7c0.3-2.9,0.8-5.8,1.3-8.5c0.2-1.2,0.4-2.3,0.7-3.5c0-0.1,0-0.3,0.1-0.4c1.1-5.1,2.6-9.5,4.3-13.5
	c0.4-1.1,1-2.2,1.5-3.1c1.3-2,1.5-3.1,0.7-3.6C74.2,209.3,74.2,209.2,74.1,209.2L74.1,209.2z"
          />
          <path
            fill="#FFFFFF"
            d="M211,195.9l-27.4,0.9c-10.3,0-17.2,2.6-20.7,7.7c-2.9,8-5.2,18.7-6.9,31.8c-0.3,2.8-0.9,6.8-1.6,12.1v0.3
	c-0.6,6.4,2.1,9.2,8,8.6l36.5-2.7c1.6-20.9,4.8-38.1,9.4-51.5C209.2,200.5,210,198.2,211,195.9L211,195.9z M198.9,340.4l-36.5-2.7
	c-6-0.6-8.7,2.2-8,8.6v0.3c0.6,5.3,1.2,9.3,1.6,12.1c1.6,13.1,3.9,23.6,6.9,31.8c3.6,5.1,10.4,7.7,20.7,7.7l27.4,0.9
	c-1-2.3-2-4.7-2.8-7.3C203.6,378.5,200.5,361.3,198.9,340.4L198.9,340.4z"
          />
          <path
            fill="#5E5E5E"
            d="M221.3,192l-8.5,0.1c-0.5,1.2-1.2,2.5-1.7,3.7c-1,2.3-2,4.7-2.8,7.3c-4.7,13.5-7.8,30.6-9.4,51.5
	c-0.1,1.2-0.2,2.4-0.3,3.7c-0.6,9.8-1.1,20.3-1.1,31.7v15.2c0,11.3,0.4,21.9,1.1,31.7c0.1,1.2,0.2,2.5,0.3,3.7
	c1.6,20.9,4.8,38.1,9.4,51.5c0.9,2.5,1.8,5,2.8,7.3c0.5,1.3,1.1,2.5,1.7,3.7l8.5,0.1c1.7,0,3.5,0,5.2,0.1h2.1
	c-13.8-11.9-21-43-21.8-93.2l-0.1-6.2v-12.3l0.1-6.3c0.8-50.2,8-81.3,21.8-93.2h-2.1C224.8,192,223.1,192,221.3,192L221.3,192z
	 M297.9,210.3c-7.2,11.8-10.9,40.8-10.9,86.9v3.8c0.2,42.2,3.5,69.5,9.9,81.9c0.3,0.5,0.6,1.1,1,1.6l0.1,0.4
	c0.6-0.1,1.3-0.3,1.8-0.4c-7.2-11.8-10.9-40.9-10.9-87c0-46.3,3.6-75.5,10.9-87.3c-0.6-0.1-1.3-0.2-1.8-0.4L297.9,210.3L297.9,210.3
	z"
          />
          <path
            fill="#FFFFFF"
            d="M358.5,230.8c-11.3,0-21.7,0.4-31.4,1.4c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2
	s0.8,0.5,1.2,0.5c8.9,1,19.3,1.5,31.1,1.6h100c10.5-0.2,19.9-0.6,27.9-1.6c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2
	s-0.2-0.9-0.5-1.2s-0.8-0.5-1.2-0.5c-8.6-0.9-17.8-1.3-27.7-1.4H358.5L358.5,230.8z M417.9,252.1c-9.9,0.1-19.1,0.5-27.7,1.4
	c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2s0.8,0.5,1.2,0.5c8,0.9,17.4,1.4,27.9,1.6h40
	c10.5-0.2,19.9-0.6,27.9-1.6c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2s-0.2-0.9-0.5-1.2s-0.8-0.5-1.2-0.5
	c-8.6-0.9-17.8-1.3-27.7-1.4H417.9L417.9,252.1z M417.9,280.7c-9.9,0.1-19.1,0.5-27.7,1.4c-0.4,0-0.9,0.2-1.2,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c8,0.9,17.4,1.4,27.9,1.6h40c10.5-0.2,19.9-0.6,27.9-1.6
	c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5c-8.6-0.9-17.8-1.3-27.7-1.4H417.9
	L417.9,280.7z M456.5,307.8h-38.4c-10.5,0.2-19.9,0.7-27.9,1.6c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2
	c0,0.4,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c8.6,0.9,17.8,1.3,27.7,1.4h40.6c9.9-0.1,19.1-0.5,27.7-1.4c0.4,0,0.9-0.2,1.2-0.5
	c0.3-0.3,0.5-0.8,0.5-1.2c0-0.4-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5c-8-0.9-17.4-1.4-27.9-1.6H456.5z M456.5,336.5h-38.4
	c-10.5,0.2-19.9,0.6-27.9,1.6c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5
	c8.6,0.9,17.8,1.3,27.7,1.4h40.6c9.9-0.1,19.1-0.5,27.7-1.4c0.4,0,0.9-0.2,1.2-0.5s0.5-0.8,0.5-1.2s-0.2-0.9-0.5-1.2
	s-0.8-0.5-1.2-0.5c-8-0.9-17.4-1.4-27.9-1.6H456.5L456.5,336.5z M456.5,357.7h-98.4c-11.8,0.1-22.2,0.7-31.1,1.6
	c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c9.7,1,20.1,1.4,31.4,1.4h100
	c9.9-0.1,19.1-0.5,27.7-1.4c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5
	c-8-0.9-17.4-1.4-27.9-1.6H456.5L456.5,357.7z"
          />
          <path
            fill="#EAEBEB"
            d="M499.1,274.8v45.5c0,0.9,0.2,1.5,0.9,2.1c0.5,0.5,1.2,0.9,2.1,0.9s1.5-0.2,2.1-0.9c0.5-0.5,0.9-1.2,0.9-2.1
	v-45.5c0-0.9-0.2-1.5-0.9-2.1c-0.5-0.5-1.2-0.9-2.1-0.9s-1.5,0.2-2.1,0.9C499.4,273.2,499.1,273.9,499.1,274.8L499.1,274.8z"
          />
          <path
            fill="#5E5E5E"
            d="M265.6,184.2c-2.7,0.1-4.9,0.4-6.3,0.9h-0.1h-0.1l-0.2,0.1c-1,0.4-1.7,0.9-2.2,1.3c-1.3,1.3-0.1,2.6,3.6,3.9
	c13,4.8,27.3,8.9,43.3,12.4c6.8,1.5,15.7,2.6,26.9,3.3c3.6,0.2,7.3,0.4,11.3,0.5l21.8,0.5h0.1c7.6-0.1,11.6-1.5,12-4.2l1-15.6
	c0.2-0.9-0.5-1.4-2.4-1.8c-1.5-0.3-3.9-0.5-7.3-0.5l-91.1-0.6l-0.2,0.9l91.2,0.6c2.6,0,4.7,0.2,5.9,0.4c1,0.2,1.4,0.6,1.3,1.1
	l-0.9,15.6c-0.3,2.2-3.6,3.3-9.5,3.4l-21.6-0.5c-4.1-0.1-7.9-0.3-11.5-0.5c-10.5-0.6-19.1-1.6-25.5-3.1c-10.3-2.2-20-4.8-29.1-7.6
	l-0.3-8.1l-0.3,2c0,0.6-0.2,1.7-0.6,3.4l-5.5-1l-2.1-1.5l1.1-4v-0.1c-0.5,0-1-0.1-1.4-0.2C266.2,184.5,265.9,184.4,265.6,184.2
	L265.6,184.2z"
          />
          <path
            fill="#FFFFFF"
            d="M273.3,153.7c-2.9,1.5-5.5,5.3-7.6,11.3c-1.1,2.9-2,6.4-2.8,10.4v0.1l-0.1,1.1l-0.1,0.4
	c-0.3,3.8,0.5,6.2,2.9,7.4c0.3,0.2,0.8,0.3,1.1,0.4c0.4,0.1,0.9,0.2,1.4,0.2v0.1l-1.1,4l2.1,1.5l5.5,1c0.3-1.6,0.5-2.8,0.6-3.4
	l0.3-2l0.2-1.3c0.2-1.3,0.4-2.5,0.6-3.5l0.9-5.1v-0.1c0.8-2.9,1.4-5.5,1.8-7.8c1.1-5.4,1.6-9.5,1.4-12c-0.2-0.9-0.5-1.5-1-2
	c-1-1.1-2.3-1.6-4.1-1.4c-0.7,0.1-1.3,0.2-2.1,0.4C273.4,153.6,273.3,153.6,273.3,153.7L273.3,153.7z M363.7,176.5h-9.8
	c-1.8,0.2-3.5,0.4-5,0.9c-1,0.2-2.5,0.4-4.7,0.5c-0.5,0-1,0-1.4,0.2c-0.3,0.1-0.5,0.3-0.5,0.5v0.4c0,0.2,0.2,0.3,0.5,0.5
	c0.4,0.1,0.9,0.2,1.4,0.2c2,0.1,3.9,0.1,5.9,0.2c0.2,0,0.5-0.1,0.8-0.1c4.9-0.9,10.3-0.9,16.4-0.2c1.4,0.1,2.8,0.3,4.2,0.5h4.8
	c0.6,0,1.3-0.1,1.7-0.2c0.5-0.2,0.8-0.4,0.8-0.6v-0.5c0-0.2-0.2-0.5-0.8-0.6c-0.4-0.2-1.1-0.3-1.7-0.3l-2.4-0.1h-0.8
	c-0.3,0-0.6-0.1-1-0.2C369,177,366.2,176.7,363.7,176.5L363.7,176.5z M473,176.5c-2.9,0.2-5.8,0.5-8.5,1.3H464
	c-1.4,0-2.9,0.1-4.3,0.1c-0.5,0-1,0.1-1.4,0.2c-0.3,0.1-0.5,0.3-0.5,0.5v0.4c0,0.2,0.2,0.3,0.5,0.5c0.4,0.1,0.9,0.2,1.4,0.2
	c2,0.1,3.9,0.1,5.9,0.2c0.2,0,0.5-0.1,0.8-0.1c5.5-0.9,11-1,16.4-0.2c1.4,0.1,2.7,0.3,4.1,0.6h4.8c0.7,0,1.3-0.1,1.7-0.3
	c0.5-0.2,0.8-0.3,0.8-0.6v-0.5c0-0.2-0.2-0.4-0.8-0.6c-0.4-0.2-1.1-0.2-1.7-0.2c-0.9,0-1.7-0.1-2.6-0.1h-1.5
	c-3.3-0.8-6.4-1.1-9.4-1.3L473,176.5L473,176.5z"
          />
          <path
            fill="#5E5E5E"
            d="M397.7,185.8c-1.7,0.5-2.6,1.3-2.7,2.5l-2.5,15.1c-0.1,2.7,4,4.1,12.3,4.2h60.8c9.7,0,15.8-1.3,18.7-4
	c4.1-5.1,5.9-9.3,5.2-12.7c-0.3-3-5.6-4.8-16.2-5.2h-0.1l-68.5-0.4C401.7,185.1,399.4,185.4,397.7,185.8L397.7,185.8z M394.9,203.5
	l2.5-15.1c0.1-0.9,0.8-1.5,2-1.8s3-0.5,5.3-0.4h0.1l68.5,0.4c8.9,0.3,13.5,1.8,13.9,4.4c0.5,3.3-1.1,7.4-5.1,12.4
	c-2.5,2.3-7.9,3.5-16.4,3.5h-60.8C398.3,206.6,394.9,205.5,394.9,203.5L394.9,203.5z M265.6,410.7c-2.7-0.1-4.9-0.4-6.3-0.9h-0.1
	h-0.1l-0.2-0.1c-1-0.4-1.7-0.9-2.2-1.3c-1.3-1.3-0.1-2.6,3.6-3.9c13-4.8,27.3-8.9,43.3-12.4c6.8-1.5,15.7-2.6,26.9-3.3
	c3.6-0.2,7.3-0.4,11.3-0.5l21.8-0.5h0.1c7.6,0.1,11.6,1.5,12,4.2l1,15.6c0.2,0.9-0.5,1.4-2.4,1.8c-1.5,0.3-3.9,0.5-7.3,0.5
	l-91.1,0.6l-0.2-0.9l91.2-0.6c2.6,0,4.7-0.2,5.9-0.4c1-0.2,1.4-0.6,1.3-1.1l-1-15.6c-0.3-2.2-3.6-3.3-9.5-3.4L342,389
	c-4.1,0.1-7.9,0.3-11.5,0.5c-10.5,0.6-19.1,1.6-25.5,3.1c-10.3,2.2-20,4.8-29.1,7.6l-0.3,8.1l-0.3-2c0-0.6-0.2-1.7-0.6-3.4l-5.5,1
	l-2.1,1.5l1.1,4v0.1c-0.5,0-1,0.1-1.4,0.2C266.2,410.4,265.9,410.5,265.6,410.7L265.6,410.7z"
          />
          <path
            fill="#FFFFFF"
            d="M273.3,441.3c-2.9-1.5-5.5-5.3-7.6-11.3c-1.1-2.9-2-6.4-2.8-10.4v-0.1l-0.1-1.1l-0.1-0.4
	c-0.3-3.8,0.5-6.2,2.9-7.4c0.3-0.2,0.8-0.3,1.1-0.4c0.4-0.1,0.9-0.2,1.4-0.2v-0.1l-1.1-4l2.1-1.5l5.5-1c0.3,1.6,0.5,2.8,0.6,3.4
	l0.3,2l0.2,1.3c0.2,1.3,0.4,2.5,0.6,3.5l0.9,5.1v0.1c0.8,2.9,1.4,5.5,1.8,7.8c1.1,5.5,1.6,9.5,1.4,12c-0.2,0.9-0.5,1.5-1,2
	c-1,1.1-2.3,1.6-4.1,1.4c-0.7-0.1-1.3-0.2-2.1-0.4C273.4,441.3,273.3,441.3,273.3,441.3L273.3,441.3z M363.7,418.4h-9.8
	c-1.8-0.2-3.5-0.4-5-0.9c-1-0.2-2.5-0.4-4.7-0.5c-0.5,0-1,0-1.4-0.2c-0.3-0.1-0.5-0.3-0.5-0.5v-0.4c0-0.2,0.2-0.3,0.5-0.5
	c0.4-0.1,0.9-0.2,1.4-0.2c2-0.1,3.9-0.1,5.9-0.2c0.2,0,0.5,0.1,0.8,0.1c4.9,0.9,10.3,0.9,16.4,0.2c1.4-0.1,2.8-0.3,4.2-0.5h4.8
	c0.6,0,1.3,0.1,1.7,0.2c0.5,0.2,0.8,0.4,0.8,0.6v0.5c0,0.2-0.2,0.5-0.8,0.6c-0.4,0.2-1.1,0.3-1.7,0.3l-2.4,0.1h-0.8
	c-0.3,0-0.6,0.1-1,0.2C369,417.8,366.2,418.2,363.7,418.4L363.7,418.4z M473,418.4c-2.9-0.2-5.8-0.5-8.5-1.3H464
	c-1.4,0-2.9-0.1-4.3-0.1c-0.5,0-1-0.1-1.4-0.2c-0.3-0.1-0.5-0.3-0.5-0.5v-0.4c0-0.2,0.2-0.3,0.5-0.5c0.4-0.1,0.9-0.2,1.4-0.2
	c2-0.1,3.9-0.1,5.9-0.2c0.2,0,0.5,0.1,0.8,0.1c5.5,0.9,11,1,16.4,0.2c1.4-0.1,2.7-0.3,4.1-0.7h4.8c0.7,0,1.3,0.1,1.7,0.3
	c0.5,0.2,0.8,0.3,0.8,0.6v0.5c0,0.2-0.2,0.4-0.8,0.6c-0.4,0.2-1.1,0.2-1.7,0.2c-0.9,0-1.7,0.1-2.6,0.1h-1.5
	c-3.3,0.8-6.4,1.1-9.4,1.3L473,418.4L473,418.4z"
          />
          <path
            fill="#5E5E5E"
            d="M397.7,409.1c-1.7-0.5-2.6-1.3-2.7-2.5l-2.5-15.1c-0.1-2.7,4-4.1,12.3-4.2h60.8c9.7,0,15.8,1.3,18.7,4
	c4.1,5.1,5.9,9.3,5.2,12.7c-0.3,3-5.6,4.8-16.2,5.2h-0.1l-68.5,0.4C401.7,409.8,399.4,409.6,397.7,409.1L397.7,409.1z M394.9,391.5
	l2.5,15.1c0.1,0.9,0.8,1.5,2,1.8s3,0.5,5.3,0.4h0.1l68.5-0.4c8.9-0.3,13.5-1.8,13.9-4.5c0.5-3.3-1.1-7.4-5.1-12.4
	c-2.5-2.3-7.9-3.5-16.4-3.5h-60.8C398.3,388.2,394.9,389.3,394.9,391.5L394.9,391.5z M545.8,189.8h-7.6c-0.7,0-1,0.3-1,1v4.4
	c0,0.6,0.3,1,1,1h7.6c0.7,0,1-0.3,1-1v-4.4C546.8,190.1,546.5,189.8,545.8,189.8L545.8,189.8z M748.3,193.3v4.3c0,0.6,0.3,1,1,1h7.1
	c0.7,0,1-0.3,1-1v-4.3c0-0.6-0.3-1-1-1h-7.1C748.6,192.3,748.3,192.6,748.3,193.3L748.3,193.3z M545.8,398.8h-7.6c-0.7,0-1,0.3-1,1
	v4.5c0,0.6,0.3,1,1,1h7.6c0.7,0,1-0.3,1-1v-4.5C546.8,399.1,546.5,398.8,545.8,398.8L545.8,398.8z M756.3,396.4h-7.1
	c-0.7,0-1,0.3-1,1v4.3c0,0.6,0.3,1,1,1h7.1c0.7,0,1-0.3,1-1v-4.3C757.3,396.7,756.9,396.4,756.3,396.4L756.3,396.4z M781.9,184.4
	l-9.1-3.5c-5.5-2.7-8.8-2.5-9.9,0.4l3.7,1.1c3.9,1.4,6.9,3.6,9.1,6.7c2,2.8,3,6.3,3.5,10.6l2.2,7.2v181.3l-2.2,7.2
	c-0.4,4.2-1.6,7.8-3.5,10.6c-2.2,3-5.2,5.3-9.1,6.7l-3.7,1.1c1.1,3,4.3,3.1,9.9,0.4l9.1-3.5c5-1.7,7.9-7.7,8.9-17.8l2-33
	c1.5-0.2,2.5-1,2.7-2.3V351c-0.3-1.4-1.1-2.4-2.1-3l1.1-18.2c0.2-5.3,0.4-10.6,0.7-15.8l0.1-16l-0.1-16.4c-0.2-5.4-0.4-11-0.7-16.4
	l-1.1-18.2c1.1-0.6,1.7-1.6,2.1-3v-6.6c-0.2-1.3-1.2-2.1-2.7-2.3l-2-33C789.8,192,786.9,186,781.9,184.4L781.9,184.4z"
          />
          <path
            fill="#FFFFFF"
            d="M768.9,199.7v195.7h2.9l7.5-0.1l2.2-7.2V206.8l-2.2-7.2h-7.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M768.9,199.7l-69.5-0.4 M781.5,257.7v-50.9l-2.2-7.2 M781.5,257.7
	c3.8-4.8,7.8-8.5,12-10.7 M793.5,247l1.1,18.2c0.2,5.4,0.4,10.9,0.7,16.4l0.1,16.4l-0.1,15.9c-0.2,5.3-0.4,10.6-0.7,15.8l-1.1,18.2
	 M793.5,247l-0.8-11.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M792.7,235.1c1.5,0.2,2.5,1,2.7,2.3v6.6c-0.3,1.3-1.1,2.4-2.1,3
	 M699.3,199.2c0.5,1.6,0.8,3.3,0.8,5.1c0,4.4-1.6,8.2-4.7,11.4c-3.1,3.1-6.9,4.7-11.4,4.7h-71.2c-4.4,0-8.2-1.6-11.4-4.7
	c-3.1-3.1-4.7-6.9-4.7-11.4c0-2.1,0.3-4,1-5.8 M644.5,188.1l39.5,0.1 M684,188.2c-9-0.2-18.1-0.3-27.2-0.3l-12.4,0.2 M762.9,181.3
	l-10.1-2.8 M771.8,199.7c0.2-4.8-3.5-7.4-10.8-7.6c-25.4-2.1-51-3.4-76.8-3.9 M775.7,189.1c2,2.8,3,6.3,3.5,10.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M762.9,181.3c1.1-3,4.3-3.1,9.9-0.4l9.1,3.5c5,1.7,7.9,7.7,8.9,17.8l2,33
	 M771.8,199.7h-2.9 M779.3,199.7h-7.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M775.7,189.1c-2.2-3-5.2-5.3-9.1-6.7l-3.7-1.1 M514.8,177.4
	c0.2-0.2,0.4-0.3,0.8-0.4h45l8.9-3.3c41.2-0.9,87.3-0.9,138.1,0c14.5-0.3,26.9,0.5,37.3,2.5l7.9,2.3 M748.3,197.6v-4.3
	c0-0.6,0.3-1,1-1h7.1c0.7,0,1,0.3,1,1v4.3c0,0.6-0.3,1-1,1h-7.1C748.6,198.6,748.3,198.3,748.3,197.6L748.3,197.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M752.8,178.5c-0.5,0.9,1.1,1.8,4.7,3.1c0.3,0.1,0.7,0.2,1.1,0.3
	c0.9,0.2,1.7,0.4,2.6,0.6c0.4,0.1,1,0.2,1.4,0.3c5.5,1.3,9.9,3.4,13.1,6.3 M80.9,199c7.8-8.8,16.3-14.5,25.2-17.1
	c2.4-0.8,3.6-2.4,3.4-4.9c2.5-0.8,5.8-1.4,9.5-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M119,174.9c-1.1-1.8-2.8-2.5-5.1-2.2c-27.7,0.3-45.8,10.5-54.6,30.4
	c-7.9,17.9-12.2,49.3-12.8,94c0.5,45.1,4.9,76.8,12.8,94.8c8.8,19.9,27,30,54.6,30.4c2.3,0.3,4-0.4,5.2-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.8,192.1c-29.5,0.5-57,1.3-82.1,2.4c-20.1,1-36.7,2.5-49.8,4.6
	 M73,209.1h1.1c0.9,0.4,0.7,1.6-0.7,3.6c-0.5,1-1.1,2.1-1.5,3.1c-1.7,3.9-3.1,8.5-4.3,13.5c0,0.1-0.1,0.3-0.1,0.4
	c-0.2,1.1-0.4,2.3-0.7,3.5c-0.5,2.7-1,5.6-1.3,8.5c-0.2,2.3-0.4,4.6-0.7,6.7c-0.2,1.5-0.3,3.1-0.4,4.7c-0.1,0.8-0.1,1.4-0.2,2.2
	l-0.3,4.6c-0.1,0.9-0.1,1.6-0.2,2.5c-0.2,3.1-0.4,6.3-0.7,9.5c-0.1,2-0.2,4-0.3,6.1v0.6c-0.2,6.1-0.4,12.3-0.5,18.3
	c0.1,6.4,0.3,12.7,0.5,19v0.6c0.1,2.1,0.2,4,0.3,6.1c0.2,3.1,0.4,6.4,0.7,9.5c0,0.9,0.1,1.6,0.2,2.5c0.1,1.5,0.2,3,0.3,4.6
	c0,0.8,0.1,1.4,0.2,2.2c0.1,1.5,0.2,3.1,0.4,4.7c0.2,2.3,0.4,4.6,0.7,6.7c0.3,2.9,0.8,5.8,1.3,8.5c0.2,1.2,0.4,2.3,0.7,3.5
	c0,0.1,0,0.3,0.1,0.4c1.1,5.1,2.6,9.5,4.3,13.5c0.5,1.1,1,2.2,1.5,3.1c1.3,2,1.5,3.1,0.7,3.6h-0.4H73"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M73.4,210.3c-0.2,0-0.4,0-0.8,0.1c-0.3,0.1-0.7,0.2-1,0.4
	s-0.7,0.5-0.9,0.9c-0.5,0.5-1,1.1-1.4,1.7c-0.3,0.4-0.5,0.9-0.9,1.2c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.1-0.2,0.3-0.2,0.4
	c-0.5,0.9-1,1.7-1.4,2.6c-0.1,0.2-0.2,0.5-0.3,0.9c-1.1,2.8-2.1,6.3-2.8,10.4c-0.1,0.5-0.2,1.2-0.3,1.8l-0.3,1.6
	c-0.4,2.6-0.8,5.3-1.1,8c-0.4,3.3-0.8,6.5-1.1,9.8c-0.1,1.6-0.2,3.3-0.4,4.9c-0.1,1.3-0.2,2.7-0.3,4c0,0.5-0.1,1-0.1,1.5
	c-0.1,1.8-0.2,3.7-0.3,5.4c0,0.8-0.1,1.4-0.1,2.2c-0.1,1.1-0.1,2.3-0.2,3.4l-0.3,5.5c-0.3,6.3-0.5,12.7-0.7,19.1
	c0.1,6.6,0.3,13.1,0.7,19.7l0.3,5.5c0.1,1.1,0.1,2.3,0.2,3.4c0,0.8,0.1,1.4,0.1,2.2c0.1,1.8,0.2,3.7,0.3,5.4c0,0.5,0.1,1,0.1,1.5
	c0.1,1.3,0.2,2.6,0.3,4c0.1,1.6,0.2,3.3,0.4,4.9c0.3,3.3,0.7,6.5,1.1,9.8c0.3,2.7,0.7,5.3,1.1,8l0.3,1.6c0.1,0.6,0.2,1.2,0.3,1.8
	c0.8,4.1,1.7,7.6,2.8,10.4c0.1,0.3,0.2,0.5,0.3,0.9c0.4,0.9,0.9,1.8,1.4,2.6c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0.3,0.4,0.5,0.5,0.9
	c0.2,0.4,0.5,0.9,0.9,1.2c0.4,0.5,0.9,1.2,1.4,1.7c0.2,0.3,0.5,0.7,0.9,0.9c0.3,0.2,0.7,0.3,1,0.4c0.2,0.1,0.5,0.1,0.8,0.1h0.1
	c0-0.1-0.3-0.6-1.1-1.7v-0.1c-0.5-1-1.1-2.1-1.6-3.3c-1.7-4-3.3-8.6-4.3-13.7c0-0.2-0.1-0.3-0.1-0.5c-0.2-1.1-0.4-2.3-0.7-3.5
	c-0.5-2.8-1.1-5.6-1.3-8.7c-0.2-2.3-0.4-4.6-0.7-6.7c-0.2-1.6-0.3-3.1-0.4-4.7c-0.1-0.8-0.1-1.4-0.2-2.2l-0.3-4.6
	c-0.1-0.9-0.1-1.6-0.2-2.5c-0.2-3.1-0.4-6.3-0.7-9.5c-0.1-2.1-0.2-4-0.3-6.1v-0.8c-0.3-6.3-0.5-12.7-0.5-19.1
	c0.1-6.2,0.3-12.4,0.5-18.4v-0.8c0.1-2.1,0.2-4,0.3-6.1c0.2-3.1,0.4-6.3,0.7-9.5c0-0.9,0.1-1.6,0.2-2.5l0.3-4.6
	c0-0.8,0.1-1.4,0.2-2.2c0.1-1.5,0.2-3.1,0.4-4.7c0.2-2.3,0.4-4.6,0.6-6.7c0.3-2.9,0.8-5.9,1.3-8.7c0.2-1.2,0.4-2.4,0.7-3.5
	c0-0.2,0.1-0.3,0.1-0.5c1.2-5.2,2.6-9.8,4.3-13.7c0.5-1.1,1.1-2.2,1.6-3.3v-0.1c0.8-1.1,1.1-1.6,1.1-1.7
	C73.5,210.4,73.4,210.4,73.4,210.3L73.4,210.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M73,385.8c-0.2,0-0.4,0-0.7-0.1c-0.4-0.1-1-0.3-1.4-0.6h-0.1
	c-0.3-0.3-0.8-0.6-1.1-1c-0.5-0.5-1-1.2-1.4-1.8c-0.3-0.4-0.7-0.9-0.9-1.3c-0.2-0.3-0.4-0.6-0.7-1c-0.1-0.1-0.2-0.3-0.2-0.4
	c-0.5-0.9-1-1.8-1.4-2.7c-0.1-0.3-0.2-0.5-0.3-0.9c-1.1-2.9-2.1-6.5-2.9-10.6c-0.1-0.5-0.2-1.2-0.3-1.8c-0.1-0.5-0.2-1.1-0.2-1.6
	c-0.4-2.7-0.8-5.3-1.1-8c-0.4-3.3-0.8-6.5-1.1-9.8c-0.1-1.6-0.2-3.3-0.4-5c-0.1-1.3-0.2-2.6-0.2-4c0-0.5-0.1-1-0.1-1.5
	c-0.1-1.8-0.2-3.6-0.3-5.5c0-0.8-0.1-1.4-0.1-2.2c-0.1-1.1-0.1-2.3-0.2-3.4l-0.3-5.5c-0.3-6.6-0.5-13.1-0.7-19.9
	c0.1-6.4,0.3-12.8,0.7-19.2c0.1-1.8,0.2-3.7,0.3-5.5c0.1-1.2,0.1-2.3,0.2-3.4c0-0.8,0.1-1.4,0.1-2.2c0.1-1.8,0.2-3.7,0.3-5.4
	c0-0.5,0.1-1.1,0.1-1.5c0.1-1.3,0.2-2.7,0.2-4c0.1-1.6,0.2-3.3,0.4-5c0.3-3.3,0.7-6.5,1.1-9.8c0.3-2.7,0.7-5.3,1.1-8
	c0.1-0.5,0.1-1.1,0.2-1.6c0.1-0.6,0.2-1.2,0.3-1.8c0.8-4.2,1.7-7.7,2.9-10.6c0.1-0.3,0.2-0.5,0.3-0.9c0.4-1,0.9-1.8,1.4-2.7
	c0.1-0.1,0.2-0.3,0.2-0.4c0.2-0.3,0.4-0.6,0.7-1c0.2-0.4,0.5-0.9,0.9-1.3c0.4-0.6,1-1.2,1.4-1.8c0.3-0.3,0.7-0.6,1.1-1H71
	c0.4-0.3,1-0.5,1.4-0.6c0.2-0.1,0.4-0.1,0.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M70.2,334.3c-1-11.8-1.4-24.1-1.2-36.8c-0.1-12.7,0.2-25,1.2-36.8
	 M70.2,260.8c1-12.6,2.7-24.6,5-36.1c1.5-9.1,7.9-14.2,19.3-15.6c15.1-2.2,37.9-3.7,68.5-4.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M162.8,204.4c-2.9,8-5.2,18.7-6.9,31.8c-0.3,2.8-0.9,6.8-1.6,12.1v0.3
	c-0.6,6.4,2.1,9.2,8,8.6l36.5-2.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M160.1,253.5c-1.7-1.3-2.2-4-1.5-8.2c0.3-3,0.7-6.1,1-8.9
	c1.4-11.5,3.4-21,5.9-28.8c1-2.2,2.4-3.1,4-3 M70.2,260.8c9.3,1.2,28.6,1.6,58,1.4c3.6,0,7.4,0,11.1-0.1c9.8-0.2,19.5-0.9,29.2-1.6
	l30.1-2 M73,209.1c2.5-3.7,5.2-7.1,7.8-10.1 M262.7,176.5c0-0.3,0.1-0.6,0.1-1.1v-0.1c0.9-4,1.7-7.5,2.8-10.4
	c2.1-6,4.7-9.7,7.6-11.3l0.1-0.1c0.8-0.2,1.4-0.3,2.1-0.4c1.8-0.2,3.1,0.3,4.1,1.4 M265.6,184.2c-2.4-1.1-3.3-3.6-2.9-7.4
	c0-0.1,0-0.2,0.1-0.4 M279.6,154.4c0.4,0.5,0.8,1.2,1,2c0.2,2.5-0.2,6.6-1.4,12c-0.5,2.4-1.1,5-1.8,7.8v0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M279.6,154.4c-2.4-0.5-4.8,3.4-7.3,11.9c0,0.1-0.1,0.2-0.1,0.3
	c-0.9,2.7-1.6,6-2.4,9.7c-0.5,2.4-1.1,4.9-1.5,7.7c0,0.2-0.1,0.5-0.1,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.6,184.2c-2.7,0.1-4.9,0.4-6.3,0.9h-0.1h-0.1c-0.1,0-0.1,0-0.2,0.1
	c-1,0.4-1.7,0.9-2.2,1.3c-1.3,1.3-0.1,2.6,3.6,3.9c13,4.8,27.3,8.9,43.3,12.4c6.8,1.5,15.7,2.6,26.8,3.3c3.6,0.2,7.3,0.4,11.3,0.5
	l21.8,0.5h0.1c7.6-0.1,11.6-1.5,12-4.2l1-15.6c0.2-0.9-0.5-1.4-2.4-1.8c-1.5-0.3-3.9-0.5-7.3-0.5l-91.1-0.6 M299.9,210.3
	c-0.7-0.1-1.3-0.2-1.8-0.4c-12.1-2.8-26.1-6.9-42-12.6l-0.5-0.1c-3.7-1.3-7.3-2.3-10.5-3 M276,184.2l-0.2,0.9 M275.8,185
	c0.2-1.3,0.4-2.5,0.6-3.5 M275.8,185l-0.2,1.2 M275.6,186.2l-0.3,2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M267,189c0.8-1.2,1.1-1.7,1.2-1.8c0.3-0.3,0.6-0.5,1-0.6
	c1.6-0.7,3-1.2,3.9-1.5c1-0.3,1.8-0.3,2.7-0.1 M276,184.2l0.4-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.9,194.5l-6.1-2c-2.6-0.9-5.1-1.7-7.6-2.7c-2.9-1.1-3.9-2.2-3-3
	c0.3-0.3,0.9-0.6,1.6-1h0.1c1.5-0.4,4-0.6,7.2-0.8 M268.1,185v-0.2 M268.1,184.8c-0.5,0-1-0.1-1.4-0.2c-0.4-0.1-0.8-0.2-1.1-0.4
	 M268.1,185l-1.1,4 M269.1,190.6L267,189 M275.2,188.2l-6.2,2.4 M275.6,186.2l0.3,8.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M269.1,190.6l5.5,1c0.3-1.6,0.5-2.8,0.7-3.4 M276.4,181.6l1-5.1
	 M275.8,185l91.2,0.6c2.6,0,4.7,0.2,5.9,0.4c1,0.2,1.4,0.7,1.3,1.1l-1,15.6c-0.3,2.2-3.6,3.3-9.5,3.4l-21.6-0.5
	c-4.1-0.1-7.9-0.3-11.5-0.5c-10.5-0.6-19.1-1.6-25.5-3.1c-10.3-2.2-20-4.8-29.1-7.6 M119,174.9c13.7-2.1,35.6-2.8,65.9-2.5
	c19.9-0.1,35,0.5,45.6,2c2.6,0.4,4.4,1.1,5.5,2 M211,195.9c0.5-1.3,1.1-2.5,1.7-3.7 M245,194c-1.6-0.4-3.3-0.8-4.8-1.1
	c-0.2,0-0.5-0.1-0.8-0.1c-3.6-0.6-7.3-1-10.9-1.1 M228.6,191.9h-2.1c-1.7,0-3.5,0.1-5.2,0.1c-2.8,0-5.8,0.1-8.5,0.1 M211,195.9
	l-27.4,0.9c-10.3,0-17.2,2.6-20.7,7.7 M179.1,253.5c-1.7-1.3-2.2-4-1.5-8.2c0.3-3,0.6-6.1,1-8.9c1.4-11.5,3.4-21,5.9-28.8
	c1-2.2,2.4-3.1,4-3 M186.1,253.5c-1.7-1.3-2.2-4-1.5-8.2c0.3-3,0.7-6.1,1-8.9c1.4-11.5,3.4-21,5.9-28.8c1-2.2,2.4-3.1,4-3
	 M166.2,253.5c-1.7-1.3-2.2-4-1.5-8.2c0.3-3,0.7-6.1,1-8.9c1.4-11.5,3.4-21,5.9-28.8c1-2.2,2.4-3.1,4-3 M172.6,253.5
	c-1.7-1.3-2.2-4-1.5-8.2c0.3-3,0.6-6.1,1-8.9c1.4-11.5,3.4-21,5.9-28.8c1-2.2,2.4-3.1,4-3 M192.2,253.5c-1.7-1.3-2.2-4-1.5-8.2
	c0.3-3,0.7-6.1,1-8.9c1.4-11.5,3.4-21,5.9-28.8c1-2.2,2.4-3.1,4-3 M198.9,254.6c-0.1,1.2-0.2,2.4-0.3,3.7 M198.5,258.3
	c-0.7,9.8-1.1,20.3-1.1,31.7v15.2c0,11.3,0.4,21.9,1.1,31.7 M228.6,403.1c-13.8-11.9-21-43-21.8-93.2c0-2.1,0-4.1-0.1-6.2v-12.3
	c0-2.2,0-4.2,0.1-6.3c0.8-50.2,8-81.3,21.8-93.2 M198.9,254.6c1.6-20.9,4.8-38.1,9.4-51.5c0.9-2.5,1.8-5,2.8-7.3 M297.9,210.3
	c-7.2,11.8-10.9,40.8-10.9,86.9c0,46.4,3.6,75.6,10.9,87.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M299.9,210.3c-7.2,11.8-10.7,41-10.9,87.3c0,46.1,3.6,75.2,10.9,87
	 M236,176.5c0.8,6.2,3.8,12,9,17.6 M236,176.5h26.7 M404.8,186.1l68.5,0.4c8.9,0.3,13.5,1.8,13.9,4.4c0.5,3.3-1.1,7.4-5.1,12.4
	c-2.5,2.3-7.9,3.5-16.4,3.5h-60.8c-6.6-0.1-10-1.2-10-3.4l2.5-15.1c0.1-0.9,0.8-1.5,2-1.8C400.7,186.1,402.4,186,404.8,186.1
	L404.8,186.1z M473,176.5h5.2 M466.3,179.8c-0.2,0-0.5,0.1-0.8,0.1c-2,0-3.8-0.1-5.9-0.2c-0.5,0-1-0.1-1.4-0.2
	c-0.3-0.1-0.5-0.3-0.5-0.5v-0.4c0-0.2,0.2-0.4,0.5-0.5c0.4-0.1,0.9-0.2,1.4-0.2c1.4,0,2.8-0.1,4.3-0.1h0.5c2.7-0.6,5.5-1.1,8.5-1.3
	 M482.8,179.6c-0.9,0.9-3,1.4-6.7,1.4h-4.2c-2.6-0.2-4.5-0.5-5.4-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M473.3,185.7l-68.5-0.4c-3.1-0.1-5.5,0.1-7.2,0.6s-2.6,1.3-2.7,2.5
	l-2.5,15.1c-0.1,2.7,4,4.1,12.3,4.2h60.8c9.7,0,15.8-1.3,18.7-4c4.1-5.1,5.9-9.3,5.2-12.7c-0.3-3-5.6-4.8-16.2-5.2
	C473.5,185.7,473.3,185.7,473.3,185.7L473.3,185.7z M353.8,176.5c-1.8,0.2-3.5,0.4-5,0.9c-1,0.2-2.5,0.4-4.7,0.5c-0.5,0-1,0-1.4,0.2
	c-0.3,0.1-0.5,0.3-0.5,0.5v0.4c0,0.2,0.2,0.3,0.5,0.5c0.4,0.1,0.9,0.2,1.4,0.2c2,0.1,3.9,0.1,5.9,0.2c0.2,0,0.5-0.1,0.8-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M350.8,179.8c0.1,0,0.1,0.1,0.2,0.1c1.1,0.5,2.8,0.9,5.2,1h4.2
	c3.5,0,5.6-0.4,6.6-1.3l0.1-0.1 M371.9,177.5c-2.9-0.5-5.8-0.9-8.4-1.1 M371.9,180.1h4.1c0.6,0,1.3-0.1,1.7-0.2
	c0.5-0.2,0.8-0.4,0.8-0.6v-0.5c0-0.2-0.2-0.5-0.8-0.6c-0.4-0.2-1.1-0.3-1.7-0.3c-0.9,0-1.6,0-2.4-0.1h-0.8c-0.3,0-0.6-0.1-1-0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367,179.6c1.4,0.1,2.8,0.3,4.2,0.5h0.7 M363.7,176.5h22.6 M371.9,180.1
	v-2.6 M367,179.6c-6-0.6-11.5-0.6-16.4,0.2 M353.8,176.5h9.9 M363.7,176.5c-3.6-0.3-6.8-0.3-9.8,0 M325.8,232.7
	c0.3-0.3,0.8-0.5,1.2-0.5c9.7-1,20.1-1.4,31.4-1.4h100c9.9,0.1,19.1,0.5,27.7,1.4c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2
	s-0.2,0.9-0.5,1.2s-0.8,0.5-1.2,0.5c-8,0.9-17.4,1.4-27.9,1.6h-100c-11.8-0.1-22.2-0.7-31.1-1.6c-0.4,0-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.2C325.4,233.4,325.5,233,325.8,232.7L325.8,232.7z M389,253.9c0.3-0.3,0.8-0.5,1.2-0.5
	c8.6-0.9,17.8-1.3,27.7-1.4h40.6c9.9,0.1,19.1,0.5,27.7,1.4c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2s-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.8,0.5-1.2,0.5c-8,0.9-17.4,1.4-27.9,1.6h-40c-10.5-0.2-19.9-0.6-27.9-1.6c-0.4,0-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.2C388.6,254.7,388.7,254.2,389,253.9L389,253.9z M389,282.6c0.3-0.3,0.8-0.5,1.2-0.5
	c8.6-0.9,17.8-1.3,27.7-1.4h40.6c9.9,0.1,19.1,0.5,27.7,1.4c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2s-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.8,0.5-1.2,0.5c-8,0.9-17.4,1.4-27.9,1.6h-40c-10.5-0.2-19.9-0.6-27.9-1.6c-0.4,0-0.9-0.2-1.2-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.2C388.6,283.3,388.7,282.9,389,282.6L389,282.6z M383.1,216.4c-19.4-0.5-38.1-1.3-56.1-2.4
	c-7.7-0.2-16.7-1.4-27.1-3.7 M386.2,176.5c-0.2,14.4-1.3,27.7-3.3,39.9 M489.8,216.9h-17.6c-31.2,0.3-61,0.1-89.2-0.6 M386.2,176.5
	H473 M478.2,176.5h24.3 M502.5,176.5v-0.3 M489.1,177.8c0.9,0,1.7,0,2.6,0.1c0.6,0,1.3,0.1,1.7,0.2c0.5,0.2,0.8,0.4,0.8,0.6v0.5
	c0,0.2-0.2,0.4-0.8,0.6c-0.4,0.2-1.1,0.3-1.7,0.3h-2.6 M489.1,180.3h-2.2c-1.4-0.3-2.8-0.5-4.1-0.6 M478.2,176.5
	c3,0.2,6.2,0.5,9.4,1.3h1.5 M489.1,177.8v2.5 M519,197.7c-0.3-3.6-0.9-6.5-1.7-8.8 M516,195c-0.2-3.6-0.5-6.7-1-9.5
	c-0.3-1.1-0.8-2.1-1.2-2.9 M517.3,188.9c-0.4-1.1-0.9-2.1-1.3-2.8c0-5.2-0.4-8.1-1.2-8.7 M513.8,182.6c-2.3-4.2-6.1-6.3-11.3-6.2
	 M514.8,177.4c-0.8,0.9-1.1,2.5-1,5.2 M538.2,189.8h7.6c0.7,0,1,0.3,1,1v4.4c0,0.6-0.3,1-1,1h-7.6c-0.7,0-1-0.3-1-1v-4.4
	C537.2,190.1,537.6,189.8,538.2,189.8L538.2,189.8z M528.3,198.3l-9.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M504.9,222.1c0-2.8,0.3-5.1,1.3-6.6c0.2-0.3,0.5-0.8,0.9-1.1
	c1.5-2.1,2.7-4.2,3.8-6.6c2.2-4.2,3.8-8.6,5.1-12.8 M516,399.9c0.1-2.8,0.2-5.9,0.2-9.1v-0.1c0.8-0.2,1.1-1.7,1.1-4.7v-49.8l1.4-3.3
	v-71.1l-1.4-3.3v-49.8c0-2.9-0.3-4.4-1.1-4.7v-0.1c0-3.3-0.1-6.3-0.2-9.1 M502.5,176.5c-0.8,8.6-3.5,18-8.2,28.2
	c-2.4,4.1-3.9,8.2-4.4,12.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M504.9,222.1c-1.8-3.7-5.8-5.4-11.7-5.2h-3.5 M504.9,372.8
	c0.9-1.6,1.2-3.7,1.2-6.2V228.4c0-2.5-0.4-4.6-1.2-6.2 M504.9,274.8v45.5c0,0.9-0.2,1.5-0.9,2.1c-0.5,0.5-1.2,0.9-2.1,0.9
	s-1.5-0.2-2.1-0.9c-0.5-0.5-0.9-1.2-0.9-2.1v-45.5c0-0.9,0.2-1.5,0.9-2.1c0.5-0.5,1.2-0.9,2.1-0.9s1.5,0.2,2.1,0.9
	C504.6,273.2,504.9,273.9,504.9,274.8L504.9,274.8z M519,197.7c0.2,2.4,0.3,5,0.3,7.8c0.9,22.7,1.1,47.7,0.8,75.1v33.5
	c0.3,27.3,0.1,52.4-0.8,75.1c0,2.9-0.1,5.5-0.3,7.8 M597.9,198.6l-69.5-0.3 M482.8,179.6c-5.4-0.6-11-0.5-16.4,0.2 M277.4,176.5
	h76.4 M699.3,395.7c0.5-1.6,0.8-3.3,0.8-5.1c0-4.4-1.6-8.2-4.7-11.4c-3.1-3.1-6.9-4.7-11.4-4.7h-71.2c-4.4,0-8.2,1.6-11.4,4.7
	c-3.1,3.1-4.7,6.9-4.7,11.4c0,2.1,0.3,4,1,5.8 M487.8,311.2c0-0.4-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5
	c-8-0.9-17.4-1.4-27.9-1.6h-40c-10.5,0.2-19.9,0.7-27.9,1.6c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.9,0.5,1.2
	c0.3,0.3,0.8,0.5,1.2,0.5c8.6,0.9,17.8,1.3,27.7,1.4h40.6c9.9-0.1,19.1-0.5,27.7-1.4c0.4,0,0.9-0.2,1.2-0.5
	C487.7,312.1,487.8,311.6,487.8,311.2L487.8,311.2z M486,338.1c-8-0.9-17.4-1.4-27.9-1.6h-40c-10.5,0.2-19.9,0.6-27.9,1.6
	c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c8.6,0.9,17.8,1.3,27.7,1.4h40.6
	c9.9-0.1,19.1-0.5,27.7-1.4c0.4,0,0.9-0.2,1.2-0.5s0.5-0.8,0.5-1.2s-0.2-0.9-0.5-1.2C487,338.2,486.6,338.1,486,338.1L486,338.1z
	 M487.3,359.8c-0.3-0.3-0.8-0.5-1.2-0.5c-8-0.9-17.4-1.4-27.9-1.6h-100c-11.8,0.1-22.2,0.6-31.1,1.6c-0.4,0-0.9,0.2-1.2,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5c9.7,1,20.1,1.4,31.4,1.4h100c9.9-0.1,19.1-0.5,27.7-1.4
	c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2C487.8,360.6,487.7,360.1,487.3,359.8L487.3,359.8z M489.8,378h3.5
	c6,0.2,9.9-1.5,11.7-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M502.5,418.6c-0.8-8.6-3.5-18-8.2-28.2c-2.4-4.1-3.9-8.2-4.4-12.4
	 M484.4,391.4c4.1,5.1,5.9,9.3,5.2,12.7c-0.3,3-5.6,4.8-16.2,5.2h-0.1l-68.5,0.4c-3.1,0.1-5.5-0.1-7.2-0.6s-2.6-1.3-2.7-2.5
	l-2.5-15.1c-0.1-2.7,4-4.1,12.3-4.2h60.8C475.3,387.4,481.5,388.7,484.4,391.4L484.4,391.4z M516,399.9c-1.3-4.2-2.9-8.6-5.1-12.8
	c-1.1-2.3-2.4-4.5-3.8-6.6c-0.2-0.3-0.5-0.8-0.9-1.1c-0.9-1.6-1.3-3.8-1.3-6.6 M528.3,396.8l-9.3,0.4 M545.8,398.8h-7.6
	c-0.7,0-1,0.3-1,1v4.5c0,0.6,0.3,1,1,1h7.6c0.7,0,1-0.3,1-1v-4.5C546.8,399.1,546.5,398.8,545.8,398.8L545.8,398.8z M517.3,406
	c-0.4,1.1-0.9,2.1-1.3,2.8c0,5.2-0.4,8.1-1.2,8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M513.8,412.4c0.4-0.9,0.9-1.8,1.2-2.9c0.4-2.8,0.8-6,1-9.5 M502.5,418.6
	v0.3 M478.2,418.5h24.3 M514.8,417.6c0.2,0.2,0.4,0.3,0.8,0.4h45l8.9,3.3c41.2,0.9,87.3,0.9,138.1,0c14.5,0.3,26.9-0.5,37.3-2.5
	l7.9-2.3 M514.8,417.6c-0.8-0.9-1.1-2.5-1-5.2 M513.8,412.4c-2.3,4.2-6.1,6.3-11.3,6.2 M487.1,404.1c0.5-3.3-1.1-7.4-5.1-12.4
	c-2.5-2.3-7.9-3.5-16.4-3.5h-60.8c-6.6,0.1-10,1.2-10,3.4l2.5,15.1c0.1,0.9,0.8,1.5,2,1.8s3,0.5,5.3,0.4h0.1l68.5-0.4
	C482.1,408.1,486.8,406.7,487.1,404.1L487.1,404.1z M489.1,417.2v-2.5 M478.2,418.5c3-0.2,6.2-0.5,9.4-1.3h1.5 M489.1,414.7h-2.2
	c-1.4,0.3-2.8,0.5-4.1,0.6 M489.1,417.2c0.9,0,1.7,0,2.6-0.1c0.6,0,1.3-0.1,1.7-0.2c0.5-0.2,0.8-0.4,0.8-0.7v-0.5
	c0-0.2-0.2-0.4-0.8-0.7c-0.4-0.2-1.1-0.3-1.7-0.3h-2.6 M519,397.2c-0.3,3.6-0.9,6.5-1.7,8.8 M597.9,396.4l-69.5,0.4 M265.6,410.8
	c-2.7-0.1-4.9-0.4-6.3-0.9h-0.1h-0.1c-0.1,0-0.1,0-0.2-0.1c-1-0.4-1.7-0.9-2.2-1.3c-1.3-1.3-0.1-2.6,3.6-3.9
	c13-4.8,27.3-8.9,43.3-12.4c6.8-1.5,15.7-2.6,26.8-3.3c3.6-0.2,7.3-0.4,11.3-0.5l21.8-0.5h0.1c7.6,0.1,11.6,1.5,12,4.2l1,15.6
	c0.2,0.9-0.5,1.4-2.4,1.8c-1.5,0.3-3.9,0.5-7.3,0.5l-91.1,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,409.9l91.2-0.7c2.6,0,4.7-0.2,5.9-0.4c1-0.2,1.4-0.6,1.3-1.1
	l-1-15.6c-0.3-2.2-3.6-3.3-9.5-3.4l-21.7,0.5c-4.1,0.1-7.9,0.3-11.5,0.5c-10.5,0.7-19.1,1.6-25.5,3.1c-10.3,2.2-20,4.8-29.1,7.6
	 M383.1,378.7c-19.4,0.5-38.1,1.3-56.1,2.4c-7.7,0.2-16.7,1.4-27.1,3.7 M350.8,415.2c0.1,0,0.1-0.1,0.2-0.1c1.1-0.5,2.8-0.9,5.2-1
	h4.2c3.5,0,5.6,0.4,6.6,1.3l0.1,0.1 M371.9,414.8h4.1c0.6,0,1.3,0.1,1.7,0.2c0.5,0.2,0.8,0.4,0.8,0.7v0.5c0,0.2-0.2,0.5-0.8,0.7
	c-0.4,0.2-1.1,0.3-1.7,0.3c-0.9,0-1.6,0-2.4,0.1h-0.8c-0.3,0-0.6,0.1-1,0.2 M367,415.4c1.4-0.1,2.8-0.3,4.2-0.5h0.7 M371.9,417.4
	v-2.6 M371.9,417.4c-2.9,0.5-5.8,0.9-8.4,1.1 M363.7,418.5h22.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.8,418.5c-1.8-0.2-3.5-0.4-5-0.9c-1-0.2-2.5-0.4-4.7-0.5
	c-0.5,0-1,0-1.4-0.2c-0.3-0.1-0.5-0.3-0.5-0.5V416c0-0.2,0.2-0.3,0.5-0.5c0.4-0.1,0.9-0.2,1.4-0.2c2-0.1,3.9-0.1,5.9-0.2
	c0.2,0,0.5,0.1,0.8,0.1 M353.8,418.5c2.9,0.3,6.2,0.3,9.8,0 M367,415.4c-6,0.6-11.5,0.6-16.4-0.2 M363.7,418.5h-9.9 M466.3,415.1
	c-0.2,0-0.5-0.1-0.8-0.1c-2,0-3.8,0.1-5.9,0.2c-0.5,0-1,0.1-1.4,0.2c-0.3,0.1-0.5,0.3-0.5,0.5v0.4c0,0.2,0.2,0.4,0.5,0.5
	c0.4,0.1,0.9,0.2,1.4,0.2c1.4,0,2.8,0.1,4.3,0.1h0.5c2.7,0.6,5.5,1.1,8.5,1.3 M482.8,415.4c-0.9-0.9-3-1.4-6.7-1.4h-4.2
	c-2.6,0.2-4.5,0.5-5.4,1.1 M473,418.5h5.2 M386.2,418.5H473 M489.8,378h-17.6c-31.2-0.3-61-0.1-89.2,0.6 M386.2,418.5
	c-0.2-14.4-1.3-27.7-3.3-39.9 M482.8,415.4c-5.4,0.6-11,0.5-16.4-0.3 M299.9,384.6c-0.7,0.1-1.3,0.3-1.8,0.4
	c-12.1,2.8-26.1,7.1-41.9,12.6l-0.5,0.1c-3.7,1.3-7.3,2.3-10.5,3 M198.9,340.4c-0.1-1.2-0.2-2.4-0.3-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M198.5,336.7l-30.1-2c-9.7-0.9-19.4-1.4-29.2-1.6
	c-3.8-0.1-7.5-0.1-11.1-0.1c-29.4-0.3-48.8,0.1-58,1.4 M162.8,390.5c-2.9-8-5.2-18.7-6.9-31.8c-0.3-2.8-0.9-6.8-1.6-12.1v-0.1v-0.2
	c-0.6-6.4,2.1-9.2,8-8.6l36.5,2.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M186.1,341.6c-1.7,1.3-2.2,4-1.5,8.2c0.3,3,0.7,6.1,1,8.9
	c1.4,11.5,3.4,21,5.9,28.8c1,2.2,2.4,3.1,4,3 M192.2,341.6c-1.7,1.3-2.2,4-1.5,8.2c0.3,3,0.7,6.1,1,8.9c1.4,11.5,3.4,21,5.9,28.8
	c1,2.2,2.4,3.1,4,3 M166.2,341.6c-1.7,1.3-2.2,4-1.5,8.2c0.3,3,0.7,6.1,1,8.9c1.4,11.5,3.4,21,5.9,28.8c1,2.2,2.4,3.1,4,3
	 M172.6,341.6c-1.7,1.3-2.2,4-1.5,8.2c0.3,3,0.6,6.1,1,8.9c1.4,11.5,3.4,21,5.9,28.8c1,2.2,2.4,3.1,4,3 M179.1,341.6
	c-1.7,1.3-2.2,4-1.5,8.2c0.3,3,0.6,6.1,1,8.9c1.4,11.5,3.4,21,5.9,28.8c1,2.2,2.4,3.1,4,3 M160.1,341.6c-1.7,1.3-2.2,4-1.5,8.2
	c0.3,3,0.7,6.1,1,8.9c1.4,11.5,3.4,21,5.9,28.8c1,2.2,2.4,3.1,4,3 M211,399.2c-1-2.3-2-4.7-2.8-7.3c-4.7-13.5-7.8-30.6-9.4-51.5
	 M119.1,420c13.7,2.1,35.6,2.8,65.7,2.5c19.9,0.1,35-0.5,45.6-2c2.6-0.4,4.4-1.1,5.5-2 M245,400.9c-1.6,0.4-3.3,0.8-4.8,1.1
	c-0.2,0-0.5,0.1-0.8,0.1c-3.6,0.6-7.3,1-10.9,1.1 M228.6,403.1h-2.1c-1.7,0-3.5-0.1-5.2-0.1c-2.8,0-5.8-0.1-8.5-0.1 M212.8,402.9
	c-0.5-1.2-1.2-2.5-1.7-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M211,399.2l-27.4-0.9c-10.3,0-17.2-2.6-20.7-7.7 M275.6,408.7l-0.3-2
	 M275.2,406.8c0-0.7-0.2-1.7-0.6-3.4l-5.5,1 M275.9,400.6l-0.3,8.1 M275.8,409.9c-0.9,0.2-1.7,0.2-2.7-0.1c-1-0.3-2.3-0.8-3.9-1.5
	c-0.3-0.1-0.6-0.3-1-0.6c-0.1-0.1-0.5-0.6-1.2-1.8 M269.1,404.4l-2.1,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.9,400.6l-6.1,2c-2.6,0.9-5.1,1.7-7.6,2.7c-2.9,1.1-3.9,2.2-3,3
	c0.3,0.3,0.9,0.7,1.6,1h0.1c1.5,0.4,4,0.6,7.2,0.8 M268.1,410.1c-0.5,0-1,0.1-1.4,0.2s-0.8,0.2-1.1,0.4 M268.1,410v0.1 M279.6,440.5
	c-2.4,0.5-4.8-3.4-7.3-11.9c0-0.1-0.1-0.2-0.1-0.3c-0.9-2.7-1.6-6-2.4-9.7c-0.5-2.4-1.1-5-1.5-7.7c0-0.2-0.1-0.5-0.1-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.7,418.5c0,0.3,0.1,0.6,0.1,1.1v0.1c0.9,4,1.7,7.5,2.8,10.4
	c2.1,6,4.7,9.7,7.6,11.3l0.1,0.1c0.8,0.2,1.4,0.3,2.1,0.4c1.8,0.2,3.1-0.2,4.1-1.4 M265.6,410.8c-2.4,1.1-3.3,3.6-2.9,7.4
	c0,0.1,0,0.3,0.1,0.4 M279.6,440.5c0.4-0.5,0.8-1.2,1-2c0.2-2.5-0.2-6.6-1.4-12c-0.5-2.4-1.1-5-1.8-7.8v-0.1 M276,410.8l-0.2-0.9
	 M276.4,413.5c-0.2-1.1-0.4-2.3-0.6-3.5 M276,410.8l0.4,2.7 M277.4,418.5l-1-5 M267,405.9l1.1,4.1 M275.6,408.7l0.2,1.2
	 M275.2,406.8l-6.2-2.4 M236,418.5h26.7 M236,418.5c0.8-6.2,3.8-12,9-17.6 M70.2,334.3c1,12.6,2.7,24.6,5,36.1
	c1.5,9.1,7.9,14.2,19.3,15.6c15.1,2.2,37.9,3.7,68.5,4.6 M80.9,395.9c7.8,8.8,16.3,14.5,25.2,17.1c2.4,0.8,3.6,2.4,3.4,4.9
	c2.5,0.8,5.6,1.4,9.4,2h0.2 M73,385.8c2.5,3.7,5.2,7.1,7.8,10.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.8,402.9c-29.5-0.5-57-1.4-82.1-2.4c-20.1-1-36.7-2.5-49.8-4.6
	 M277.4,418.5h76.4 M793.5,348c-4.2-2.4-8.2-6-12-10.7 M749.2,396.4c-0.7,0-1,0.3-1,1v4.3c0,0.6,0.3,1,1,1h7.1c0.7,0,1-0.3,1-1v-4.3
	c0-0.6-0.3-1-1-1H749.2L749.2,396.4z M792.7,359.9c1.5-0.2,2.5-1,2.7-2.3V351c-0.3-1.4-1.1-2.4-2.1-3 M771.8,395.4h-2.9
	 M779.3,395.3l-7.5,0.1 M762.9,413.6c1.1,3,4.3,3.1,9.9,0.4l9.1-3.5c5-1.7,7.9-7.7,8.9-17.8l2-33"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M781.5,337.2v50.9l-2.2,7.2 M792.7,359.9l0.8-11.9 M684,406.9
	c-9,0.2-18.1,0.2-27.2,0.2l-12.4-0.2 M644.5,406.9H684 M775.7,405.9c-2.2,3-5.2,5.3-9.1,6.7l-3.7,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M752.8,416.5c-0.5-0.9,1.1-1.8,4.7-3.1c0.3-0.1,0.7-0.2,1.1-0.3l2.6-0.6
	c0.4-0.1,1-0.2,1.4-0.3c5.5-1.3,9.9-3.4,13.1-6.3 M752.8,416.5l10.1-2.9 M768.9,395.4l-69.5,0.3 M775.7,405.9c2-2.8,3-6.3,3.5-10.6
	 M771.8,395.4c0.2,4.8-3.5,7.4-10.8,7.6c-25.4,2.1-51,3.4-76.8,3.9 M644.5,188.1L517.2,189 M699.3,199.2l-101.4-0.7 M781.5,337.2
	v-79.5 M528.3,396.8V198.3 M768.9,199.7v195.7 M699.3,395.7l-101.4,0.6 M644.5,406.9L517.3,406"
          />
          <path
            fill="#929497"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M768.9,395.4l10.4-0.1l2.2-7.2V206.8l-2.2-7.2
	h-10.4V395.4z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M212.8,402.9c0,0-11.2-20-14-62.5
		c0,0-1.6-18.2-1.4-42.9c0,0-0.1-24.1,1.4-42.9c0,0,1.8-37.5,14-62.5c0,0-63.9,1.4-72.7,1.8c0,0-37.9,1.4-59.2,5.1
		c0,0-5.8,6.6-7.8,10c0,0,1.7-0.1,1.6,1.1c0,0,0,1-1.2,2.7c0,0-5.5,8.1-7.9,29.1c0,0-2.7,23.7-3.4,55.5c0,0,0,23,3.4,56.2
		c0,0,2,18.3,7.9,28.4c0,0,1.6,2.3,1.1,3.3c0,0-0.2,0.4-1.5,0.5c0,0,4.6,6.6,7.8,10.1c0,0,20.8,3.7,57.9,4.9
		C138.8,400.8,193.5,402.8,212.8,402.9z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M228.6,403.1
		c0,0-14-9.4-18.9-49.1c0,0-3-15.9-3-56.5c0,0-0.4-39.4,4.1-63.6c0,0,4.1-30.2,17.8-42.1c0,0,10.1-0.4,27.4,5.4
		c0,0,23.4,8.6,41.9,12.6c0,0-6.1,8.1-8.8,35.9c0,0-2.3,16.7-2.2,51.6c0,0,0.2,32.2,1.6,45.6c0,0,1.4,28.5,9.3,42.1
		c0,0-13.1,2.9-26.8,7.5l-15.1,5.1C256,397.7,240.2,403.3,228.6,403.1z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M299.9,384.6c0,0-6-8.4-8.6-35
		c0,0-2.4-20.8-2.2-52c0,0-0.1-29.7,2-49.1c0,0,1.8-26.4,8.9-38.3c0,0,15.8,3.7,27.1,3.7c0,0,51.1,2.5,56.1,2.4
		c0,0,52.4,1.5,110.2,0.7c0,0,6.5-0.5,9.8,2.6c0,0,3.1,2.5,3.1,7.7v70.2v69.1c0,0,0.4,6.1-3.8,9.3c0,0-2.7,2.5-9.1,2.2
		c0,0-77.5-0.5-110.2,0.6c0,0-19.9,0.3-55.2,2.3C327.9,381,318,380.7,299.9,384.6z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M516,399.9l0.2-9.2
		c0,0,1.1-0.5,1.1-4.7s0-49.8,0-49.8l1.3-3.3v-35.5v-35.5l-1.4-3.3v-50.4c0,0,0-3.6-1.1-4.1l-0.2-9.2c0,0-3.9,13-9.8,20.5
		c0,0-1.4,2.1-1.3,6.6c0,0,1.2,2.4,1.2,5.1v70.2v43.5v26.1c0,0,0,3.4-1.2,5.6c0,0-0.3,3.8,1.3,6.7c0,0,2.8,3.9,4.3,6.9
		C512,389.4,513.4,391.9,516,399.9z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              display="none"
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="
		528.4,396.8 528.4,198.3 768.9,199.7 768.9,395.4 	"
              id="Truck Bed"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Truck Bed')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('truckBedColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(CrewCabPickup4DrTop);
