import React, { Component } from 'react';

class InfoIcon extends Component {
  render() {
    const { size = 20, color = '#fff' } = this.props;
    return (
      <svg
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill={color}
      >
        <path d="M 16,0C 7.164,0,0,7.164,0,16s 7.164,16, 16,16s 16-7.164, 16-16S 24.836,0, 16,0z M 16,30c-7.72,0-14-6.28-14-14s 6.28-14, 14-14 s 14,6.28, 14,14S 23.72,30, 16,30zM 16,12C 14.896,12, 14,12.896, 14,14l0,10 c0,1.104, 0.896,2, 2,2s 2-0.896, 2-2L 18,14 C 18,12.896, 17.104,12, 16,12zM 14,8.032A2,2 1080 1 0 18,8.032A2,2 1080 1 0 14,8.032z" />
      </svg>
    );
  }
}

export default InfoIcon;
