import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateItem } from '../../../../../../actions';
import SelectMenu from '../../../ui/selectMenu';
import InputField from '../../../ui/inputField';
import { showRow } from '../../../../utils/helpers';

class HeavyTruckForm extends Component {
  render() {
    const {
      heavyTrucks,
      optionItems,
      setInputValue,
      getDefaultValue,
      coreSections,
    } = this.props;

    return (
      <div className="form-body">
        {showRow('s9:r1', coreSections) && (
          <InputField
            title="Engine Make"
            placeholder="Enter engine make"
            onChangeHandler={e => setInputValue('Engmake', e.target.value)}
            defaultValue={heavyTrucks.Engmake}
          />
        )}
        {showRow('s9:r2', coreSections) && (
          <InputField
            title="Engine Model"
            placeholder="Enter engine Model"
            onChangeHandler={e => setInputValue('Engmodel', e.target.value)}
            defaultValue={heavyTrucks.Engmodel}
          />
        )}
        {showRow('s9:r3', coreSections) && (
          <InputField
            title="Engine Horsepower"
            placeholder="Enter engine horsepower"
            onChangeHandler={e => setInputValue('Enghp', e.target.value)}
            defaultValue={heavyTrucks.Enghp}
          />
        )}
        {showRow('s9:r4', coreSections) && (
          <InputField
            title="Transmission Make"
            placeholder="Enter transmission make"
            onChangeHandler={e => setInputValue('Tranmake', e.target.value)}
            defaultValue={heavyTrucks.Tranmake}
          />
        )}
        {showRow('s9:r5', coreSections) && (
          <InputField
            title="Transmission Model"
            placeholder="Enter transmission model"
            onChangeHandler={e => setInputValue('Tranmodel', e.target.value)}
            defaultValue={heavyTrucks.Tranmodel}
          />
        )}
        {showRow('s9:r6', coreSections) && (
          <InputField
            title="Transmission Speed"
            placeholder="Enter transmission speed"
            onChangeHandler={e => setInputValue('Transpeed', e.target.value)}
            defaultValue={heavyTrucks.Transpeed}
          />
        )}
        {showRow('s9:r7', coreSections) && (
          <InputField
            title="Sleeper Cab Type"
            placeholder="Enter sleeper cab type"
            onChangeHandler={e => setInputValue('Slpcabtype', e.target.value)}
            defaultValue={heavyTrucks.Slpcabtype}
          />
        )}
        {showRow('s9:r8', coreSections) && (
          <InputField
            title="Sleeper Cab Size"
            placeholder="Enter sleeper cab size"
            onChangeHandler={e => setInputValue('Slpcabsize', e.target.value)}
            defaultValue={heavyTrucks.Slpcabsize}
          />
        )}
        {showRow('s9:r9', coreSections) && (
          <SelectMenu
            title="Axles"
            placeholder="Select number of axles"
            options={optionItems.axles}
            onChangeHandler={e => setInputValue('Axles', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Axles')}
          />
        )}
        {showRow('s9:r10', coreSections) && (
          <InputField
            title="Front Axle Weight"
            placeholder="Enter front axle weight"
            onChangeHandler={e => setInputValue('Faxlewt', e.target.value)}
            defaultValue={heavyTrucks.Faxlewt}
          />
        )}
        {showRow('s9:r11', coreSections) && (
          <InputField
            title="Rear Axle Weight"
            placeholder="Enter axles weight"
            onChangeHandler={e => setInputValue('Raxlewt', e.target.value)}
            defaultValue={heavyTrucks.Raxlewt}
          />
        )}
        {showRow('s9:r12', coreSections) && (
          <InputField
            title="Suspension"
            placeholder="Enter suspension"
            onChangeHandler={e => setInputValue('Susp', e.target.value)}
            defaultValue={heavyTrucks.Susp}
          />
        )}
        {showRow('s9:r13', coreSections) && (
          <SelectMenu
            title="Fifth Wheel Type"
            options={optionItems.fifthWheelType}
            onChangeHandler={e => setInputValue('Fifwhltype', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Fifwhltype')}
          />
        )}
        {showRow('s9:r14', coreSections) && (
          <InputField
            title="Exhaust Type"
            placeholder="Enter exhaust type"
            onChangeHandler={e => setInputValue('Exhtype', e.target.value)}
            defaultValue={heavyTrucks.Exhtype}
          />
        )}
        {showRow('s9:r15', coreSections) && (
          <SelectMenu
            title="Fuel Tank"
            options={optionItems.Fueltank}
            onChangeHandler={e => setInputValue('Fueltank', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Fueltank')}
          />
        )}
        {showRow('s9:r16', coreSections) && (
          <InputField
            title="Wheelbase"
            placeholder="Enter wheelbase"
            onChangeHandler={e => setInputValue('Wheelbase', e.target.value)}
            defaultValue={heavyTrucks.Wheelbase}
          />
        )}
        {showRow('s9:r17', coreSections) && (
          <InputField
            title="Gross Vehicle weight"
            placeholder="Enter gross vehicle weight"
            onChangeHandler={e => setInputValue('Gvwr', e.target.value)}
            defaultValue={heavyTrucks.Gvwr}
          />
        )}
        {showRow('s9:r18', coreSections) && (
          <InputField
            title="Fuel Capacity"
            placeholder="Enter fuel capacity"
            onChangeHandler={e => setInputValue('Fuelcap', e.target.value)}
            defaultValue={heavyTrucks.Fuelcap}
          />
        )}
        {showRow('s9:r19', coreSections) && (
          <InputField
            title="Fuel Capacity Left"
            placeholder="Enter fuel capacity left"
            onChangeHandler={e => setInputValue('Fuelcapl', e.target.value)}
            defaultValue={heavyTrucks.Fuelcapl}
          />
        )}
        {showRow('s9:r20', coreSections) && (
          <InputField
            title="Fuel Capacity Right"
            placeholder="Enter fuel capacity right"
            onChangeHandler={e => setInputValue('Fuelcapr', e.target.value)}
            defaultValue={heavyTrucks.Fuelcapr}
          />
        )}
        {showRow('s9:r21', coreSections) && (
          <SelectMenu
            title="Engine Break"
            options={optionItems.EngineBrake}
            onChangeHandler={e => setInputValue('Engbrake', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Engbrake')}
          />
        )}
        {showRow('s9:r22', coreSections) && (
          <SelectMenu
            title="Aero Package"
            options={optionItems.Aeropack}
            onChangeHandler={e => setInputValue('Aeropack', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Aeropack')}
          />
        )}
        {showRow('s9:r23', coreSections) && (
          <InputField
            title="Axle Ratio"
            placeholder="Enter axle ratio"
            onChangeHandler={e => setInputValue('Axleratio', e.target.value)}
            defaultValue={heavyTrucks.Axleratio}
          />
        )}
        {showRow('s9:r24', coreSections) && (
          <InputField
            title="Trailer Type"
            placeholder="Enter trailer type"
            onChangeHandler={e => setInputValue('Trailertyp', e.target.value)}
            defaultValue={heavyTrucks.Trailertyp}
          />
        )}
        {showRow('s9:r25', coreSections) && (
          <SelectMenu
            title="Trailer Door Type"
            placeholder="Enter trailer door type"
            options={optionItems.Trlrdoortp}
            onChangeHandler={e => setInputValue('Trlrdoortp', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Trlrdoortp')}
          />
        )}
        {showRow('s9:r26', coreSections) && (
          <InputField
            title="Engine Hours"
            placeholder="Enter engine hours"
            onChangeHandler={e => setInputValue('Enginehrs', e.target.value)}
            defaultValue={heavyTrucks.Enginehrs}
          />
        )}
        {showRow('s9:r27', coreSections) && (
          <InputField
            title="Trailer Hours"
            placeholder="Enter trailer hours"
            onChangeHandler={e => setInputValue('Trailerhrs', e.target.value)}
            defaultValue={heavyTrucks.Trailerhrs}
          />
        )}
        {showRow('s9:r28', coreSections) && (
          <InputField
            title="Engine Control Module"
            placeholder="Enter control module"
            onChangeHandler={e => setInputValue('Ecm', e.target.value)}
            defaultValue={heavyTrucks.Ecm}
          />
        )}
        {showRow('s9:r29', coreSections) && (
          <SelectMenu
            title="Heavy Truck"
            options={optionItems.Heavytrk}
            onChangeHandler={e => setInputValue('Heavytrk', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('Heavytrk')}
          />
        )}
        {showRow('s9:r30', coreSections) && (
          <SelectMenu
            title="Suspension Other"
            options={optionItems.suspOther}
            onChangeHandler={e => setInputValue('suspother', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('suspother')}
          />
        )}
        {showRow('s9:r31', coreSections) && (
          <SelectMenu
            title="Power Mirrors"
            options={optionItems.mirrorpwr}
            onChangeHandler={e => setInputValue('mirrorpwr', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('mirrorpwr')}
          />
        )}
        {showRow('s9:r32', coreSections) && (
          <SelectMenu
            title="Heated Mirrors"
            options={optionItems.mirrorheat}
            onChangeHandler={e => setInputValue('mirrorheat', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('mirrorheat')}
          />
        )}
        {showRow('s9:r33', coreSections) && (
          <SelectMenu
            title="Power Windows"
            options={optionItems.windowpwr}
            onChangeHandler={e => setInputValue('windowpwr', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('windowpwr')}
          />
        )}
        {showRow('s9:r34', coreSections) && (
          <InputField
            title="Box Width"
            placeholder="Enter box width"
            onChangeHandler={e => setInputValue('boxwidth', e.target.value)}
            defaultValue={heavyTrucks.boxwidth}
          />
        )}
        {showRow('s9:r35', coreSections) && (
          <InputField
            title="Box Length"
            placeholder="Enter box length"
            onChangeHandler={e => setInputValue('boxlength', e.target.value)}
            defaultValue={heavyTrucks.boxlength}
          />
        )}
        {showRow('s9:r36', coreSections) && (
          <InputField
            title="Box Height"
            placeholder="Enter box height"
            onChangeHandler={e => setInputValue('boxheight', e.target.value)}
            defaultValue={heavyTrucks.boxheight}
          />
        )}
        {showRow('s9:r37', coreSections) && (
          <SelectMenu
            title="Lift Gate"
            options={optionItems.liftgate}
            onChangeHandler={e => setInputValue('liftgate', e.value)}
            hideIndicatorSeparator={true}
            defaultValue={getDefaultValue('liftgate')}
          />
        )}
        {showRow('s9:r38', coreSections) && (
          <SelectMenu
            title="Rear Door Type"
            options={optionItems.reardrtype}
            onChangeHandler={e => setInputValue('reardrtype', e.value)}
            defaultValue={getDefaultValue('reardrtype')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r39', coreSections) && (
          <SelectMenu
            title="Left Door Type"
            options={optionItems.lftdrtype}
            onChangeHandler={e => setInputValue('lftdrtype', e.value)}
            defaultValue={getDefaultValue('lftdrtype')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r40', coreSections) && (
          <SelectMenu
            title="Right Door Type"
            options={optionItems.rgtdrtype}
            onChangeHandler={e => setInputValue('rgtdrtype', e.value)}
            defaultValue={getDefaultValue('rgtdrtype')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r41', coreSections) && (
          <SelectMenu
            title="Heavy Trailer"
            options={optionItems.heavytrl}
            onChangeHandler={e => setInputValue('heavytrl', e.value)}
            defaultValue={getDefaultValue('heavytrl')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r42', coreSections) && (
          <SelectMenu
            title="Heavy Equipment"
            options={optionItems.heavyeqp}
            onChangeHandler={e => setInputValue('heavyeqp', e.value)}
            defaultValue={getDefaultValue('heavyeqp')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r43', coreSections) && (
          <InputField
            title="Seat Type Driver"
            placeholder="Enter seat type driver"
            onChangeHandler={e => setInputValue('seattyped', e.target.value)}
            defaultValue={heavyTrucks.seattyped}
          />
        )}
        {showRow('s9:r44', coreSections) && (
          <InputField
            title="Seat Type Passenger"
            placeholder="Enter seat type passenger"
            onChangeHandler={e => setInputValue('seattypep', e.target.value)}
            defaultValue={heavyTrucks.seattypep}
          />
        )}

        {showRow('s9:r45', coreSections) && (
          <SelectMenu
            title="Trailer Material"
            options={optionItems.trlmat}
            onChangeHandler={e => setInputValue('trlmat', e.value)}
            defaultValue={getDefaultValue('trlmat')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r46', coreSections) && (
          <SelectMenu
            title="Trailer Material Other"
            options={optionItems.trlmatoth}
            onChangeHandler={e => setInputValue('trlmatoth', e.value)}
            defaultValue={getDefaultValue('trlmatoth')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r47', coreSections) && (
          <InputField
            title="Trailer Axle Type"
            placeholder="Enter trailer axle type"
            onChangeHandler={e => setInputValue('trlaxltyp', e.target.value)}
            defaultValue={heavyTrucks.trlaxltyp}
          />
        )}
        {showRow('s9:r48', coreSections) && (
          <SelectMenu
            title="Trailer Axle Other"
            options={optionItems.trlaxloth}
            onChangeHandler={e => setInputValue('trlaxloth', e.value)}
            defaultValue={getDefaultValue('trlaxloth')}
            hideIndicatorSeparator={true}
          />
        )}
        {showRow('s9:r49', coreSections) && (
          <InputField
            title="Door Type Other"
            placeholder="Enter door type other"
            onChangeHandler={e => setInputValue('drtypeoth', e.target.value)}
            defaultValue={heavyTrucks.drtypeoth}
          />
        )}
        {showRow('s9:r50', coreSections) && (
          <InputField
            title="Trailer Type Other"
            placeholder="Enter trailer type other"
            onChangeHandler={e => setInputValue('trltypoth', e.target.value)}
            defaultValue={heavyTrucks.trltypoth}
          />
        )}
      </div>
    );
  }
}

HeavyTruckForm.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { variant, vin, item, coreSections } = state.core;
  return { variant, vin, item, coreSections };
};

export default connect(
  mapStateToProps,
  {
    updateItem,
  }
)(HeavyTruckForm);
