export const CORE_SECTIONS_SUCCESS = 'CORE_SECTIONS_SUCCESS';

export const loadCoreSections = (sections = []) => ({
  type: CORE_SECTIONS_SUCCESS,
  response: sections,
});

export const VISITED_SUCCESS = 'VISITED_SUCCESS';

export const loadVisited = (visited = '') => ({
  type: VISITED_SUCCESS,
  response: visited,
});
