import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './root';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'photoswipe/dist/photoswipe.css';
import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('app-container'));
root.render(<Root />);
