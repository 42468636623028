import React, { Component } from 'react';

class ReviewIcon extends Component {
  render() {
    const { size = 24, color = '#98989E' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 21"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18 0.857422H2C0.9 0.857422 0.00999999 1.75742 0.00999999 2.85742L0 20.8574L4 16.8574H18C19.1 16.8574 20 15.9574 20 14.8574V2.85742C20 1.75742 19.1 0.857422 18 0.857422ZM18.5714 15.4289H3.71429L2.57143 16.286L1.42857 17.4289V2.28599H18.5714V15.4289ZM8.5 12.8574H16V10.8574H10.5L8.5 12.8574ZM12.36 6.98742C12.56 6.78742 12.56 6.47742 12.36 6.27742L10.59 4.50742C10.39 4.30742 10.08 4.30742 9.88 4.50742L4 10.3874V12.8574H6.47L12.36 6.98742Z" />
      </svg>
    );
  }
}

export default ReviewIcon;
