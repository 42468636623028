import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegularCabPickup2DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M607.9,382.4c0.4-1.6,0.8-3.3,1.1-5c0.3-1.4,0.7-2.8,1-4l0.7-3.4h0.3c12.2-48.3,26.5-64.2,73.9-64.2
	c13.9,0,25.6,2.9,35.4,9.6c14,9.8,23.9,27.4,30.4,56v0.7h34.7c4.1,0,13.9,1.4,17.3-1.4l5.5-5.9c0.7-1.6,0.1-2.8-2.1-3l4.4-13.2
	l2.5-1.4v-17.9l-0.7-1.4c-1.4-0.7-3-2.5-3.7-4.5c-0.7-2.1-2.6-4.1-4.7-6.2l-0.1-0.1l-0.3-1.2v-0.3c0-16.6-0.7-23.9-2.8-28.1l0.7-0.7
	c2.8-2.1,1.4-5.1,0-7.8c-0.7-3.4-4.8-11.8-16.6-13.9c-45-8.3-109.9-13.2-150.1-16.4c-4,2.9-10.3,4.1-14.7,4.7
	c-42.8-35.8-67.8-58.1-90-69.7c-1-0.6-1.8-1-2.8-1.4c-13.9-6.2-33.2-11.7-74-11.7h-27c-18.4-1-20.1,10-21.2,13.1
	c-1,1.6-1.4,6.1-2.1,8.1c-1,5.6-0.7,10.3-0.7,17.3v-22.1l-4.8-0.1v75.1h-8.3l-325.9-0.3H45.9c-7.6,0-11.7,2.8-12.4,6.9
	c-1.8,4-3.2,28.2-1.9,50.8c0.7,12.8,2.5,25,6.1,32.7c0,1.4,2.1,8.9,8.3,9.6h13.2l0.4,0.4c18.4,1.1,44.3,3.9,60.4,7.2
	c2.9,0.5,5.1,1.5,6.9,2.8c5.1,3.3,6.6,8.4,9.6,10.5l0.6,0.3c1.8,0.7,4.3-0.7,4.8-2.3c0.1-0.4,0.1-0.7,0.1-0.7
	c11.8-45.5,25.6-73.2,69.9-73.2c34.7,0,62.7,18.8,68.5,64.8l0.5,1.1l0.5,4c-0.1,6.5,7.8,6.5,9.6-1c0.1-0.5,0.1-1,0.1-1.5h303.9
	c-0.4,6.3,4.7,9.5,8.5,9.9C606,383.9,607.6,383.3,607.9,382.4z"
          />
          <path
            fill="#5E5E5E"
            d="M616.8,253.9c0.7,0,1.7,0,2.5-0.1c-42.9-35.6-68.2-58.7-89.7-70.3l-11.7,5.5c22.1,12.4,32.5,27.6,77.5,64.9
	L616.8,253.9L616.8,253.9z"
          />
          <path
            fill="#EB2227"
            d="M517.9,189c-3-1.6-7.2-3.2-12.1-4.5C510.5,185.7,514.5,187.2,517.9,189z"
          />
          <g>
            <path
              fill="#EAEBEB"
              d="M536.4,202.2c13.3,11,27.9,26,59,51.9h21.5c0.7,0,1.7,0,2.5-0.1c-38.5-32.1-62.9-53.5-83.1-66
		c-2.3-1.4-4.5-2.8-6.9-4l-11.7,5.5C524.7,193.2,530.4,197.2,536.4,202.2L536.4,202.2z M435.8,195.3v60.3c0,4.1,3.4,7.6,8.3,7.6
		l92.3-0.1H553c-3.4,0-6.5-2.6-6.5-5.4v-16.8c0-3.2,4.7-4.8,8.1-4l0.3-0.6v-5.5c-6.7-6.9-12.9-12.7-18.6-17.5
		c-26.8-23-42.1-25-59.4-25l-32.9-0.6C439.2,187.7,435.8,191.2,435.8,195.3L435.8,195.3z M31.6,319.2c0.1,2.9,0.4,5.8,0.7,8.7H37
		c5.5,0,11.8-3.4,11.8-11.7v-16.5c0-13.8-2.8-23.5-15.3-23.5l-0.8,0.5C31.5,286.5,30.8,303.3,31.6,319.2L31.6,319.2z"
            />
          </g>
          <path
            fill="#EB2227"
            d="M619.5,253.8c0.1,0,0.1,0.1,0.3,0.1C619.6,253.9,619.4,253.8,619.5,253.8L619.5,253.8z"
          />
          <path
            fill="#EAEBEB"
            d="M618.9,298.2c-4.8,0-8.3,0.7-8.3,2.8c0,1.4,3.4,2.8,8.3,2.8c4.1,0,7.6-1.4,7.6-2.8
	C626.6,298.9,623,298.2,618.9,298.2L618.9,298.2z M799.1,315h4.1c0-14.6-0.7-22.4-2.6-27.2c-1.4-2.8-3.7-4-7.2-4h-17.9
	c-5.5,0-11,6.2-11,11.8V315h2.1l22.6,0.1L799.1,315L799.1,315z"
          />
          <path
            fill="#5E5E5E"
            d="M800.9,286.9c2.8-2.1,1.7-5.1,0.3-7.8c-3.4,0-7.6,4.8-7.6,4.8c3.4,0,5.8,1.1,7.2,4L800.9,286.9L800.9,286.9z
	 M626.5,366.8c-1.2,4.7-1.8,9.5-1.8,14.6c0,32.5,26.7,59,59.3,59c32.5,0,58.9-26.3,58.9-58.7c0-6.2-1-12.2-2.8-17.9
	c-7.6-23.7-30-41.3-56.3-41.3C656.3,322.5,632.9,341.4,626.5,366.8L626.5,366.8z"
          />
          <path
            fill="#5E5E5E"
            d="M741.5,363.8l7.6,4.8v-1.4c0.7,1.4,0.7,2.8,1.4,4.1c-6.5-28.5-16.4-46.2-30.4-56
	c-9.8-6.7-21.3-10.2-35.2-10.2c-47.3,0-62.3,16.1-74.6,64.4l15.1-2.3h1.1c6.5-25.4,29.6-44.6,57.1-44.6c26.3,0,49,17.6,56.5,41.3
	L741.5,363.8L741.5,363.8z M603.9,383.6l-0.8,11.3c0,2.8,0.7,4.8,3.4,6.2c0-6.9,1.1-18.7,1.1-18.7c-0.3,1-2.2,1.2-4.3,1L603.9,383.6
	L603.9,383.6z"
          />
          <path
            fill="#FFFFFF"
            d="M440.5,291.2c0,1.4,1.4,2.8,3.4,2.8h18c2.1,0,3.4-1.4,3.4-2.8V287c0-2.1-1.4-3.4-3.4-3.4h-18
	c-2.1,0-3.4,1.4-3.4,3.4V291.2z M558.9,254.9v2.1c0,2.8-2.2,4.3-4.3,5.2c-1,0.4-1.5,1-2.6,1H565l6.5-0.1c8.9,0,6.9-6.9,3.4-10.3
	c-7.3-8.1-13.9-15.5-20.1-21.7v5.5l-0.3,0.6c2.6,0.7,4.4,3.2,4.4,6.7L558.9,254.9L558.9,254.9z M383.3,261.7l-319-0.1
	c-2.8,0-2.8,2.2-5.5,2.2h-5.5c-0.7,0-2.3,1.4-2.3,2.1c0,1.4,1.4,2.8,2.1,2.8h5.5c2.8,0,4.1-4.1,6.2-4.1h159.7l64.2,0.3l87.5-0.3h5.4
	c2.8,0,3.7,4.1,7.4,4.1h4.8c1.4,0,1.8-1.2,1.8-2.6c0-0.7-1.1-2.2-2.5-2.2h-4.8C385.5,263.8,385.3,261.7,383.3,261.7L383.3,261.7z
	 M401.8,261.7v-74.8H397v74.8"
          />
          <path
            fill="#5E5E5E"
            d="M153,382c0,32.5,26.6,58.5,59.2,58.5c32.5,0,59.3-26,59.3-58.5c0-4-0.8-8.3-1.6-12
	c-5.5-26.8-29.2-46.9-57.6-46.9c-26.3,0-48.4,17.1-56,40.7C154.2,369.4,153,375.6,153,382L153,382z"
          />
          <path
            fill="#FFFFFF"
            d="M286.4,266.5c0,1.4,0,2.8,1.5,2.8h8.3c1.4,0,1.9-1.2,1.9-2.6c0-1.4-1.5-2.2-2.9-2.2h-6.6
	C287.1,264.5,286.4,265.2,286.4,266.5L286.4,266.5z M224,264.5h-6.9c-1.4,0-2.1,1.4-2.1,2.8c0,1.4,0.7,2.1,2.1,2.1h8
	c1.4,0,1.6-1.4,1.6-2.8C226.8,265.2,225.5,264.5,224,264.5L224,264.5z M152.7,264.6l-6.1-0.1c-1.4,0-2.8,0.7-2.8,2.1
	c-0.3,2.1,1,2.9,2.3,2.9h6.9c1.4,0,2.3-1.4,2.3-2.8C155.4,265.3,154.1,264.6,152.7,264.6L152.7,264.6z M81.6,264.5h-6.9
	c-2.1,0-2.9,1.2-2.9,2.6s0.8,2.2,2.9,2.2h6.9c1.4,0,2.6-0.8,2.6-2.2S82.9,264.5,81.6,264.5L81.6,264.5z"
          />
          <path fill="#5E5E5E" d="M44.3,383.3h11l0.4-1.6l-0.4-12.1v-2.8h-11" />
          <path
            fill="#5E5E5E"
            d="M55.3,381.4c5.5,0,14-13.2,14.7-18.7h1.4c16.6,1.4,35.5,4.5,48.6,6.6c-16.1-3.2-42.4-6.5-60.8-7.6l0.3,0.3
	c-0.5,2.2-2.3,5.2-4.1,7.7L55.3,381.4L55.3,381.4z M137.3,414.2c0-6.3,4-29.6,4.7-33.8c-0.6,1.6-3,3-4.8,2.3l0.8,0.4
	c-1.4,6.9-3.4,20.1-3.4,24.9C133.9,410,134.5,412.1,137.3,414.2L137.3,414.2z"
          />
          <path
            fill="#EAEBEB"
            d="M715.2,364.5c2.8,5.1,4.3,11,4.3,17.2c0,19.4-16,35.2-35.9,35.2c-19.4,0-35.2-15.8-35.2-35.2
	c0-5.5,1.2-10.7,3.3-15.3c5.6-12.2,17.9-20.6,31.9-20.6C697.3,345.8,709.1,353.4,715.2,364.5L715.2,364.5z M247.8,382
	c0,20.1-15.3,35.2-35.2,35.2c-19.4,0-35.8-15.4-35.8-35.4c0-6.1,1.5-11.7,4.3-16.6c5.9-11,17.7-18.6,31.1-18.6
	c15.3,0,28.1,9.2,33.4,22.1C247.1,372.8,247.8,377.3,247.8,382z"
          />
          <path
            fill="#5E5E5E"
            d="M142.2,379.6c1.4-4.1,2.1-8.3,3.4-11.7l0.7-1.4l9.6-2.8c7.6-23.7,29.7-40.7,56-40.7
	c28.5,0,52.1,20.1,57.6,46.9h1.2l9.6,1.4l0.1,0.3c-5.6-45.9-34-65.2-68.6-65.2C167.8,306.4,153.9,334.1,142.2,379.6L142.2,379.6z"
          />
          <path
            fill="#FFFFFF"
            d="M357.7,266.5c0,1.4,0,2.8,1.5,2.8h8.3c1.4,0,1.9-1.2,1.9-2.6c0-1.4-1.5-2.2-2.9-2.2h-6.6
	C358.4,264.5,357.7,265.2,357.7,266.5L357.7,266.5z"
          />
          <path
            fill="#5E5E5E"
            d="M692.5,357.9c9.6,4.1,13.2,2.1,11,0.7c-2.8-2.1-5.5-4.1-8.9-4.8C691.8,352.4,689.7,356.5,692.5,357.9
	L692.5,357.9z M713.3,386.9c0.7-3.4,0.7-6.9,0.7-9.6c-0.7-4.1-5.5-3.4-4.8,0C709.8,387.6,713.3,389.7,713.3,386.9L713.3,386.9z
	 M693.9,409.8c3.4-1.4,6.2-2.8,8.9-4.8c2.8-2.1,0-5.5-2.8-4.1C691.8,407,691.8,410.4,693.9,409.8L693.9,409.8z M673.1,409.8
	c2.8,1.4,4.8-3.4,2.1-4.1c-9.6-4.8-13.2-2.8-11-1.4C666.9,406.3,669.7,408.4,673.1,409.8L673.1,409.8z M653.8,386.2
	c0.7,3.4,5.5,2.8,4.8-0.7c-0.7-10.3-4.1-11.8-4.1-9.6C653.7,379.4,653.7,382.8,653.8,386.2L653.8,386.2z M667.6,362
	c8.3-6.2,8.3-9.6,6.2-8.9c-3.4,1.4-6.2,2.8-8.9,4.8C662.1,360,664.8,364.1,667.6,362L667.6,362z M682.2,369.6c0,1.4,0.7,2.1,1.4,2.1
	c1.4,0,2.1-0.7,2.1-2.1c0-0.7-0.7-2.1-2.1-2.1C682.9,367.5,682.2,368.9,682.2,369.6L682.2,369.6z M692.5,376.6
	c0,0.7,1.4,1.4,2.8,0.7c0.7-0.7,0.7-1.4,0.7-2.8c-0.7-0.7-2.1-1.4-2.8-0.7C691.8,374.5,691.8,375.9,692.5,376.6z M693.2,389
	c0.7,0.7,2.1,0,2.8-0.7c0-0.7,0-2.1-0.7-2.8c-1.4-0.7-2.8,0-2.8,0.7C691.8,387.6,691.8,388.3,693.2,389z M682.2,380.7
	c-0.7,2.1-0.7,3.4,0,4.1c0.7,0.7,2.1-0.7,3.4-2.8c0.7-1.4,0.7-3.4,0-4.1C684.9,378,683.6,378.7,682.2,380.7z M671.7,374.5
	c0,1.4,0,2.1,0.7,2.8c1.4,0.7,2.1,0,2.8-0.7c0.7-0.7,0.7-2.1-0.7-2.8C673.8,373.2,672.4,373.9,671.7,374.5z M671.7,388.3
	c0.7,0.7,2.1,1.4,2.8,0.7c1.4-0.7,1.4-1.4,0.7-2.8c-0.7-0.7-1.4-1.4-2.8-0.7C671.7,386.2,671.7,387.6,671.7,388.3z M682.2,393.2
	c0,1.4,0.7,2.1,1.4,2.1c1.4,0,2.1-0.7,2.1-2.1c0-1.4-0.7-2.1-2.1-2.1C682.9,391,682.2,391.7,682.2,393.2L682.2,393.2z"
          />
          <path
            fill="#FFFFFF"
            d="M761.7,371.8h22.8c4.1,0,13.9,2.1,18-1.4l4.8-5.5l-1.4-2.8l0.1-0.3h-0.3l1.9-5.1l2.5-8.4l1.7-0.4l0.8-0.5
	v-17.7h-63.8C754.4,340.7,758.8,354.6,761.7,371.8L761.7,371.8z"
          />
          <path
            fill="#5E5E5E"
            d="M807.4,364.8c0.4-0.4,0.7-0.8,0.7-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.4-0.3-1-0.6-1.8-0.6L807.4,364.8
	L807.4,364.8z"
          />
          <path
            fill="#FFFFFF"
            d="M557.3,238.6c-1.5-1.5-4-2.1-6.1-1.9c-2.5,0.4-4.7,1.9-4.7,4v16.8c0,2.1,2.8,4.8,6.2,4.8
	c0.7,0,1.4-0.1,1.9-0.3c1.2-0.5,2.5-1.2,3.3-2.3c0.5-1,1-2.2,1-3.6v-12.4C558.9,241.7,558.4,239.9,557.3,238.6L557.3,238.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M270.5,369.6l9.6,1.4l0.7,1.4c0,1.4,0.7,2.8,0.7,4.8
	c0,6.9,4.8,6.2,11.8-3.4l301.7,0.7c1.4,5.5,3.4,7.6,6.9,8.9l2.1,0.7c0,0-0.7,4.8-0.7,11c0,2.1,0,4.1,3.4,6.2
	c-0.7-6.9,0.7-18.7,0.7-18.7c1-3.6,1.5-6.2,2.2-9.6l0.8-3.6l14.9-2.8"
          />
          <path
            fill="#5E5E5E"
            d="M220.5,357.9c9.6,4.1,13.2,2.8,11,0.7c-2.8-2.1-5.5-3.4-8.3-4.8C219.8,352.4,217.8,356.5,220.5,357.9
	L220.5,357.9z M237.2,377.3c1.4,10.3,4.1,12.5,4.1,9.6c0.7-2.8,0.7-6.2,0.7-9.6C241.3,373.9,236.5,374.5,237.2,377.3z M221.9,409.8
	c3.4-0.7,6.2-2.1,8.9-4.1c2.8-2.8,0-6.2-2.8-4.1C219.8,407.7,219.8,411.1,221.9,409.8L221.9,409.8z M192.9,357.9
	c-2.8,2.8,0.7,6.2,2.8,4.1c8.9-6.2,8.9-9.6,6.2-8.9C199.1,354.5,195.6,355.8,192.9,357.9L192.9,357.9z M182.5,386.2
	c0,3.4,4.8,2.8,4.1,0c-0.7-11-4.1-12.5-4.1-9.6C181.9,379.4,181.9,382.8,182.5,386.2z M192.2,404.9c2.8,2.1,5.5,3.4,8.9,4.8
	c3.4,1.4,4.8-2.8,2.1-4.1C193.6,401.5,190.8,402.9,192.2,404.9L192.2,404.9z M220.5,376.6c0.7,1.4,1.4,1.4,2.8,0.7
	c0.7,0,0.7-1.4,0.7-2.1c-0.7-1.4-2.1-1.4-2.8-0.7C220.5,374.5,219.8,375.9,220.5,376.6L220.5,376.6z M210.2,370.4
	c0,0.7,0.7,1.4,2.1,1.4c0.7,0,1.4-0.7,1.4-1.4c0-1.4-0.7-2.1-1.4-2.1C210.9,368.2,210.2,368.9,210.2,370.4z M200.4,375.2
	c-0.7,0.7-0.7,2.1,0.7,2.1c0.7,0.7,2.1,0.7,2.1-0.7c0.7-0.7,0.7-2.1-0.7-2.1C201.8,373.8,200.4,373.8,200.4,375.2L200.4,375.2z
	 M201.1,386.2c-1.4,0-1.4,1.4-0.7,2.1c0,1.4,1.4,1.4,2.1,0.7c1.4,0,1.4-1.4,0.7-2.1C203.3,385.5,201.8,385.5,201.1,386.2
	L201.1,386.2z M210.2,393.2c0,1.4,0.7,2.1,2.1,2.1c0.7,0,1.4-0.7,1.4-2.1c0-0.7-0.7-1.4-1.4-1.4
	C210.9,391.7,210.2,392.4,210.2,393.2L210.2,393.2z M220.5,386.9c-0.7,0.7,0,2.1,0.7,2.1c0.7,0.7,2.1,0.7,2.8-0.7
	c0-0.7,0-2.1-0.7-2.1C221.9,385.5,221.2,385.5,220.5,386.9L220.5,386.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M740.9,363.4l8.3,5.5v-1.4c0.7,1.4,0.7,2.8,1.4,4.1h33.8
	c4.1,0,13.9,2.1,18-1.4l4.8-5.5l-1.4-2.8l4.1-13.9l2.8-0.7v-18l-1.4-2.1c-0.7-0.7-2.8-2.1-3.4-4.1c-0.7-2.1-2.8-4.8-4.8-6.2v-0.7
	c0-16-0.7-24.2-2.8-29c3.4-2.1,2.1-5.5,0.7-8.3c-0.7-3.4-4.1-11-15.3-13.9c-45-8.3-112.1-13.9-151.6-16.6
	c-4.8,3.4-13.9,4.8-17.3,4.8h2.8c-45-37.4-70.6-60.3-92.7-71.3c-13.9-6.9-34-11.8-74-11.8h-30.4c-16,0-20.1,8.9-20.1,37.4l-0.7,1.4
	v-22.1h-4.8v74.8H46.2c-8.3,0-11.1,2.1-12.5,6.2c-2.1,6.2-5.5,62.3,3.4,84.5c0.7,0.7,2.8,8.3,8.9,8.9c3.4,0,7.6,0,13.2,0.7H60
	c-1.4,2.1-2.8,4.8-4.1,7.6l-0.7-2.8H44v16h11.1v-1.4c6.2,0,13.9-13.2,14.6-18l2.1-0.7c16,1.4,35.4,4.1,48.4,6.9
	c10.3,2.1,12.5,9.6,16.6,12.5l0.7,1.4c-0.7,6.2-2.8,20.1-3.4,24.2c0,2.1,0,4.8,3.4,6.2c0-6.9,4.8-34,4.8-34
	c0.7-4.1,2.1-8.3,2.8-12.5l1.4-1.4l8.9-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M270.5,369.6l9.6,1.4l0.7,1.4c0,1.4,0.7,2.8,0.7,4.8
	c0,6.9,4.8,6.2,11.8-3.4l301.7,0.7c1.4,5.5,3.4,7.6,6.9,8.9l2.1,0.7c0,0-0.7,4.8-0.7,11c0,2.1,0,4.1,3.4,6.2
	c-0.7-6.9,0.7-18.7,0.7-18.7c0.8-3.6,1.5-6.2,2.2-9.6l0.7-3.4l15-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M740.9,363.4l8.3,5.5v-1.4c0.7,1.4,0.7,2.8,1.4,4.1h33.8
	c4.1,0,13.9,2.1,18-1.4l4.8-5.5l-1.4-2.8l4.1-13.9l2.8-0.7v-18l-1.4-2.1c-0.7-0.7-2.8-2.1-3.4-4.1c-0.7-2.1-2.8-4.8-4.8-6.2v-0.7
	c0-16-0.7-24.2-2.8-29c3.4-2.1,2.1-5.5,0.7-8.3c-0.7-3.4-4.1-11-15.3-13.9c-45-8.3-112.1-13.9-151.6-16.6
	c-4.8,3.4-13.9,4.8-17.3,4.8h2.8c-45-37.4-70.6-60.3-92.7-71.3c-13.9-6.9-34-11.8-74-11.8h-30.4c-16,0-20.1,8.9-20.1,37.4l-0.7,1.4
	v-22.1h-4.8v74.8H46.2c-8.3,0-11.1,2.1-12.5,6.2c-2.1,6.2-5.5,62.3,3.4,84.5c0.7,0.7,2.8,8.3,8.9,8.9c3.4,0,7.6,0,13.2,0.7H60
	c-1.4,2.1-2.8,4.8-4.1,7.6l-0.7-2.8H44v16h11.1v-1.4c6.2,0,13.9-13.2,14.6-18l2.1-0.7c16,1.4,35.4,4.1,48.4,6.9
	c10.3,2.1,12.5,9.6,16.6,12.5l0.7,1.4c-0.7,6.2-2.8,20.1-3.4,24.2c0,2.1,0,4.8,3.4,6.2c0-6.9,4.8-34,4.8-34
	c0.7-4.1,2.1-8.3,2.8-12.5l1.4-1.4l8.9-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M800.5,287.3c-1.4-2.8-4.1-3.4-6.9-3.4h-18c-5.5,0-11,6.2-11,11.8v19.4
	h-0.7h39.5C803.2,299,802.5,292.1,800.5,287.3 M618.5,303.3c4.8,0,8.3-1.4,8.3-2.8s-3.4-2.8-8.3-2.8c-4.1,0-7.6,1.4-7.6,2.8
	S614.4,303.3,618.5,303.3 M32.3,327.5c-2.1-18.7-0.7-39.5,0-51.2h1.4c12.5,0,15.3,9.6,15.3,22.8v16.6c0,8.3-6.9,11.8-11.8,11.8h-3.4
	 M518.2,189c21.5,11.8,31.8,27,76.8,64.4h24.9c-42.9-35.4-68.5-58.2-90-69.9h-0.7L518.2,189 M554.8,231.2v5.5h-0.7
	c-3.4-0.7-7.6,0.7-7.6,3.4v17.3c0,2.1,2.8,4.8,6.2,4.8L552,263H443.4c-4.1,0-7.6-3.4-7.6-7.6v-60.1c0-4.1,3.4-7.6,7.6-7.6h33.2
	c20.1,0,38.8,3.4,77.4,42.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M761.7,371.8c-10.3-60.9-39.5-81.7-77.4-81.7c-51.9,0-75.4,22.8-89.3,83.8
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M750.7,371.8c-11.8-48.4-31.8-66.4-66.4-66.4c-51.2,0-63.7,18.7-76.8,77.5
	c-0.7,2.1-12.5,0.7-12.5-8.9 M291.1,373.9c0-58.2-41.5-82.4-78.8-82.4c-53.9,0-73.3,31.1-85.8,80.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M291.1,373.9l303.7,0.7 M610.2,369.6l15.3-2.8 M740.9,363.4l8.3,5.5
	 M750.7,371.8h33.8 M291.1,373.9c0,7.6-9.6,10.3-9.6,3.4c-4.1-50.5-33.2-70.6-69.2-70.6c-44.3,0-58.9,27-69.9,73.3
	c-0.7,2.1-3.4,3.4-5.5,2.1 M403.2,364.1H597 M634.4,248.5c39.5,2.8,106.6,8.3,151.6,16.6c11,2.8,14.6,10.3,15.3,13.9 M617.1,253.3
	c3.4,0,12.5-1.4,17.3-4.8 M793.6,283.9c0,0,3.4-4.8,7.6-4.8 M595,253.3h22.1 M800.5,287.3c2.1,4.8,2.8,13.2,2.8,29 M800.5,287.3
	c3.4-2.1,2.1-5.5,0.7-8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M799.1,315c0-22.8-2.1-28.3-6.2-28.3 M739.4,315H799 M776.3,286.6h16.6
	 M766.6,315v-18.7c0-4.8,4.8-9.6,9.6-9.6 M775.6,283.9h18 M764.4,315v-19.4c0-5.5,5.5-11.8,11-11.8 M793.6,283.9
	c2.8,0,5.5,0.7,6.9,3.4 M766.6,304.7h21.5 M788.7,315c0-14.6-1.4-24.2-4.1-28.3 M811.6,348.3h-54.8 M806,362l4.1-13.8 M806,362
	h-45.7 M803.2,317c2.1,1.4,4.1,4.1,4.8,6.2c0.7,2.1,2.8,3.4,3.4,4.1c0.7,0.7,1.4,1.4,1.4,2.1 M807.4,364.8c1.4-1.4,0.7-2.8-1.4-2.8
	 M807.4,364.8l-4.8,5.5c-4.1,3.4-13.9,1.4-18,1.4 M799.1,315c1.4,0,2.8,0.7,4.1,2.1 M811.6,348.3c0.7,0,1.4-0.7,1.4-0.7v-18
	 M793.6,286.6v-2.8 M402.5,261.7v112.1 M618.5,303.3c4.8,0,8.3-1.4,8.3-2.8s-3.4-2.8-8.3-2.8c-4.1,0-7.6,1.4-7.6,2.8
	S614.4,303.3,618.5,303.3 M453.1,170.4c40.2,0,60.3,4.8,74,11.8c22.1,11,47.7,34,92.7,71.3 M402.5,207.7v53.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.8,178.6h10.3c40.9,0,72.6,0.7,90,10.3c21.5,11.8,31.8,27,76.8,64.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M46.2,261.7H402 M402.5,207.7c0-28.3,4.1-37.4,20.1-37.4H453 M64.8,264.5
	h316.9 M33,327.5h108.7 M32.3,276.2h583.3 M55.8,369.6c1.4-2.8,2.8-5.5,4.1-7.6 M55.2,381.4c6.2,0,13.9-13.2,14.6-18 M55.2,366.8
	h-11v16h11L55.2,366.8L55.2,366.8z M46.2,361.3c-6.2-0.7-8.3-8.3-8.9-8.9c-8.9-22.1-5.5-78.1-3.4-84.5 M46.2,361.3
	c16.6,0,52.5,4.1,74,8.3c10.3,2.1,12.5,9.6,16.6,12.5 M397,261.7v-74.8h4.8v74.8 M33.7,267.9c1.4-4.1,4.1-6.2,12.5-6.2 M597.7,276.2
	c82.4,0,141.1,5.5,161.9,8.3 M443.4,290.1h18.7c1.4,0,2.8-1.4,2.8-3.4 M464.8,286.6c0-1.4-1.4-2.8-2.8-2.8h-18.7
	c-1.4,0-2.8,1.4-2.8,2.8 M440.6,286.6c0,2.1,1.4,3.4,2.8,3.4 M440.6,290.8v-4.1 M464.8,290.8v-4.1 M440.6,290.8
	c0,2.1,1.4,3.4,2.8,3.4h18.7c1.4,0,2.8-1.4,2.8-3.4 M64.8,264.5c-2.8,0-4.1,4.1-6.9,4.1h-4.8 M63.4,261.7c-2.8,0-2.8,2.1-5.5,2.1
	h-4.8 M53.1,268.6c-1.4,0-2.1-0.7-2.1-2.1s0.7-2.8,2.1-2.8 M381.7,264.5c2.1,0,3.4,4.1,6.9,4.1h4.8 M383.3,261.7
	c2.8,0,2.1,2.1,5.5,2.1h4.8 M393.6,268.6c1.4,0,2.1-0.7,2.1-2.1s-0.7-2.8-2.1-2.8 M78,268.6v-3.4 M74.6,269.3h6.9
	c1.4,0,2.8-0.7,2.8-2.1 M84.2,267.2c0-1.4-1.4-2.8-2.8-2.8h-6.9c-1.4,0-2.8,1.4-2.8,2.8 M71.8,267.2c0,1.4,1.4,2.1,2.8,2.1
	 M149.3,268.6v-3.4 M145.8,269.3h6.9c1.4,0,2.8-0.7,2.8-2.1 M155.4,267.2c0-1.4-1.4-2.8-2.8-2.8h-6.9c-1.4,0-2.1,1.4-2.1,2.8
	 M143.8,267.2c0,1.4,0.7,2.1,2.1,2.1 M363.9,268.6v-3.4 M359.7,269.3h7.6c1.4,0,2.1-0.7,2.1-2.1 M369.4,267.2c0-1.4-0.7-2.8-2.1-2.8
	h-7.6c-1.4,0-2.1,1.4-2.1,2.8 M357.5,267.2c0,1.4,0.7,2.1,2.1,2.1 M292.6,268.6v-3.4 M288.3,269.3h7.6c1.4,0,2.1-0.7,2.1-2.1
	 M298.1,267.2c0-1.4-0.7-2.8-2.1-2.8h-7.6c-1.4,0-2.1,1.4-2.1,2.8 M286.3,267.2c0,1.4,0.7,2.1,2.1,2.1 M220.5,268.6v-3.4
	 M217.1,269.3h7.6c1.4,0,2.1-0.7,2.1-2.1 M226.8,267.2c0-1.4-0.7-2.8-2.1-2.8h-7.6c-1.4,0-2.1,1.4-2.1,2.8 M215,267.2
	c0,1.4,0.7,2.1,2.1,2.1 M292.6,268.6v-3.4 M288.3,269.3h7.6c1.4,0,2.1-0.7,2.1-2.1 M298.1,267.2c0-1.4-0.7-2.8-2.1-2.8h-7.6
	c-1.4,0-2.1,1.4-2.1,2.8 M286.3,267.2c0,1.4,0.7,2.1,2.1,2.1 M619.9,253.3c9.6,0.7,97.5,8.3,143.2,16c18,3.4,20.1,11.8,20.8,14.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M595,253.3c9.6,6.2,121.7,11.8,166.7,23.5c7.6,2.1,15.3,4.1,16.6,6.9
	 M458.6,373.9v-9.6 M586.7,374.5v-10.3 M33.7,327.5h3.4c4.8,0,11.8-3.4,11.8-11.8V299c0-13.2-2.8-22.8-15.3-22.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M32.3,322.5h2.1c1.4,0,2.1-1.4,2.1-2.1v-4.1c0-1.4-0.7-2.8-2.1-2.8h-2.8
	 M31.6,300.4h16.6 M32.3,288.7h15.3 M402.5,225.7c0-31.1,0-47,15.3-47 M518.2,189c-14.6-8.3-53.4-13.8-76.9-13.8h-16
	c-2.1,0-2.8,1.4-2.8,2.8 M546.5,240.3c0-4.8,12.5-5.5,12.5,2.8v13.2c0,4.1-2.8,6.2-6.2,6.2s-6.2-2.8-6.2-4.8v-17.3 M554.8,236.8
	v-5.5 M558.9,254h5.5v8.3 M518.2,189l11-5.5 M476.7,187.6c23.5,0,45,4.8,98.2,64.4 M421.9,179.3v177.2c0,4.1,3.4,7.6,7.6,7.6
	 M476.7,187.6h-33.2c-4.1,0-7.6,3.4-7.6,7.6v60.3c0,4.1,3.4,7.6,7.6,7.6h128.1c8.9,0,6.9-7.6,3.4-11.1 M590.1,364.1
	c4.1-2.8,8.3-28.3,8.9-54.6c0-27.6-3.4-56-6.2-58.2 M155.4,363.4l-8.9,2.8 M270.5,369.6l9.6,1.4 M748.6,329.6H813 M212.3,417.5
	c19.4,0,35.4-16,35.4-35.4c0-20.1-16-36-35.4-36c-20.1,0-36,16-36,36C176.2,401.5,192.2,417.5,212.3,417.5 M271.1,381.4
	c0-32.5-26.3-58.9-58.9-58.9c-32.5,0-58.9,26.3-58.9,58.9c0,32.5,26.3,59.6,58.9,59.6C244.7,441,271.1,413.9,271.1,381.4
	L271.1,381.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M203.3,376.6c0.7-0.7,0.7-2.1-0.7-2.1c-0.7-0.7-2.1-0.7-2.1,0.7
	c-0.7,0.7-0.7,2.1,0.7,2.1C201.8,378,203.3,378,203.3,376.6 M203.3,386.9c0-1.4-1.4-1.4-2.1-0.7c-1.4,0-1.4,1.4-0.7,2.1
	c0,1.4,1.4,1.4,2.1,0.7C204,389,204,387.6,203.3,386.9 M212.3,391.7c-1.4,0-2.1,0.7-2.1,1.4c0,1.4,0.7,2.1,2.1,2.1
	c0.7,0,1.4-0.7,1.4-2.1C213.6,392.4,213,391.7,212.3,391.7 M220.5,386.9c-0.7,0.7,0,2.1,0.7,2.1c0.7,0.7,2.1,0.7,2.8-0.7
	c0-0.7,0-2.1-0.7-2.1C221.9,385.5,221.2,385.5,220.5,386.9 M220.5,376.6c0.7,1.4,1.4,1.4,2.8,0.7c0.7,0,0.7-1.4,0.7-2.1
	c-0.7-1.4-2.1-1.4-2.8-0.7C220.5,374.5,219.8,375.9,220.5,376.6 M212.3,371.8c0.7,0,1.4-0.7,1.4-1.4c0-1.4-0.7-2.1-1.4-2.1
	c-1.4,0-2.1,0.7-2.1,2.1C210.2,371.1,210.9,371.8,212.3,371.8 M212.3,389c3.4,0,6.9-3.4,6.9-7.6c0-3.4-3.4-6.9-6.9-6.9
	c-4.1,0-7.6,3.4-7.6,6.9C204.7,385.5,208.1,389,212.3,389 M231.7,358.6c-2.8-2.1-5.5-3.4-8.3-4.8c-3.4-1.4-5.5,2.8-2.8,4.1
	C230.3,362,233.7,360.6,231.7,358.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M223.3,353.8c-3.4-1.4-5.5,2.8-2.8,4.1c9.6,4.1,13.2,2.8,11,0.7
	C228.9,356.5,226.2,355.1,223.3,353.8L223.3,353.8z M201.8,353.1c-2.8,1.4-6.2,2.8-8.9,4.8c-2.8,2.8,0.7,6.2,2.8,4.1
	C204.7,355.8,204.7,352.4,201.8,353.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M192.9,357.9c-2.8,2.8,0.7,6.2,2.8,4.1c8.9-6.2,8.9-9.6,6.2-8.9
	C199.1,354.5,195.6,355.8,192.9,357.9L192.9,357.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.3,441c32.5,0,58.9-27,58.9-59.6c0-32.5-26.3-58.9-58.9-58.9
	c-32.5,0-58.9,26.3-58.9,58.9C153.4,413.9,179.8,441,212.3,441 M683.6,440.3c32.5,0,59.6-26.3,59.6-58.9c0-32.5-27-58.9-59.6-58.9
	c-32.5,0-58.9,26.3-58.9,58.9C624.7,413.9,651,440.3,683.6,440.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M684.2,417.5c19.4,0,35.4-16,35.4-36c0-19.4-16-35.4-35.4-35.4
	c-20.1,0-36,16-36,35.4C648.2,401.5,664.2,417.5,684.2,417.5 M743,381.4c0-32.5-27-58.9-59.6-58.9c-32.5,0-58.9,26.3-58.9,58.9
	c0,32.5,26.3,58.9,58.9,58.9C716,440.3,743,413.9,743,381.4L743,381.4z M210.2,384.9c0.7,0.7,2.1-0.7,3.4-2.1c0.7-2.1,1.4-4.1,0-4.1
	c-0.7-0.7-2.1,0.7-3.4,2.1C209.5,382.8,209.5,384.9,210.2,384.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.3,401.5c11,0,20.1-8.9,20.1-20.1c0-10.3-8.9-19.4-20.1-19.4
	c-11,0-20.1,8.9-20.1,19.4C192.2,392.4,201.1,401.5,212.3,401.5 M675.2,376.6c0.7-0.7,0.7-2.1-0.7-2.8c-0.7-0.7-2.1,0-2.8,0.7
	c0,1.4,0,2.1,0.7,2.8C673.8,378,674.5,377.3,675.2,376.6 M675.2,386.2c-0.7-0.7-1.4-1.4-2.8-0.7c-0.7,0.7-0.7,2.1-0.7,2.8
	c0.7,0.7,2.1,1.4,2.8,0.7C676,388.3,676,387.6,675.2,386.2 M683.6,391c-0.7,0-1.4,0.7-1.4,2.1c0,1.4,0.7,2.1,1.4,2.1
	c1.4,0,2.1-0.7,2.1-2.1S684.9,391,683.6,391 M692.5,386.2c-0.7,1.4-0.7,2.1,0.7,2.8c0.7,0.7,2.1,0,2.8-0.7c0-0.7,0-2.1-0.7-2.8
	C693.9,384.9,692.5,385.5,692.5,386.2 M692.5,376.6c0,0.7,1.4,1.4,2.8,0.7c0.7-0.7,0.7-1.4,0.7-2.8c-0.7-0.7-2.1-1.4-2.8-0.7
	C691.8,374.5,691.8,375.9,692.5,376.6 M683.6,371.8c1.4,0,2.1-0.7,2.1-2.1c0-0.7-0.7-2.1-2.1-2.1c-0.7,0-1.4,1.4-1.4,2.1
	C682.2,371.1,682.9,371.8,683.6,371.8 M683.6,389c4.1,0,7.6-3.4,7.6-7.6s-3.4-6.9-7.6-6.9c-3.4,0-6.9,2.8-6.9,6.9
	S680.1,389,683.6,389"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M682.2,384.9c0.7,0.7,2.1-0.7,3.4-2.8c0.7-1.4,0.7-3.4,0-4.1
	c-0.7,0-2.1,0.7-3.4,2.8C681.5,382.8,681.5,384.2,682.2,384.9 M241.3,386.9c0.7-2.8,0.7-6.2,0.7-9.6c-0.7-3.4-5.5-2.8-4.8,0
	C238.5,387.6,241.3,389.7,241.3,386.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242,377.3c-0.7-3.4-5.5-2.8-4.8,0c1.4,10.3,4.1,12.5,4.1,9.6
	C242,384.2,242,380.7,242,377.3z M221.9,409.8c3.4-0.7,6.2-2.1,8.9-4.1c2.8-2.8,0-6.2-2.8-4.1C219.8,407.7,219.8,411.1,221.9,409.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231,405.6c2.8-2.8,0-6.2-2.8-4.1c-8.3,6.2-8.3,9.6-6.2,8.3
	C225.5,409.1,228.2,407.7,231,405.6L231,405.6z M192.2,404.9c2.8,2.1,5.5,3.4,8.9,4.8c3.4,1.4,4.8-2.8,2.1-4.1
	C193.6,401.5,190.8,402.9,192.2,404.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M201.1,409.8c3.4,1.4,4.8-2.8,2.1-4.1c-9.6-4.1-12.5-2.8-11-0.7
	C194.9,407,197.7,408.4,201.1,409.8L201.1,409.8z M182.5,376.6c-0.7,2.8-0.7,6.2,0,9.6c0,3.4,4.8,2.8,4.1,0
	C186,375.2,182.5,373.9,182.5,376.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M182.5,386.2c0,3.4,4.8,2.8,4.1,0c-0.7-11-4.1-12.5-4.1-9.6
	C181.9,379.4,181.9,382.8,182.5,386.2z M683.6,401.5c11,0,20.1-8.9,20.1-20.1c0-11-8.9-20.1-20.1-20.1c-10.3,0-19.4,8.9-19.4,20.1
	C664.2,392.4,673.1,401.5,683.6,401.5 M703.6,358.6c-2.8-2.1-5.5-4.1-8.9-4.8c-2.8-1.4-4.8,2.8-2.1,4.1
	C702.3,362,705.7,360,703.6,358.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M694.6,353.8c-2.8-1.4-4.8,2.8-2.1,4.1c9.6,4.1,13.2,2.1,11,0.7
	C700.9,356.5,698,354.5,694.6,353.8L694.6,353.8z M713.3,386.9c0.7-3.4,0.7-6.9,0.7-9.6c-0.7-4.1-5.5-3.4-4.8,0
	C709.8,387.6,713.3,389.7,713.3,386.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M714,377.3c-0.7-4.1-5.5-3.4-4.8,0c0.7,10.3,4.1,12.5,4.1,9.6
	C714,383.5,714,380,714,377.3L714,377.3z M693.9,409.8c3.4-1.4,6.2-2.8,8.9-4.8c2.8-2.1,0-5.5-2.8-4.1
	C691.8,407,691.8,410.4,693.9,409.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M703,404.9c2.8-2.1,0-5.5-2.8-4.1c-8.3,6.2-8.3,9.6-6.2,8.9
	C697.3,408.4,700.2,407,703,404.9L703,404.9z M664.2,404.2c2.8,2.1,5.5,4.1,8.9,5.5c2.8,1.4,4.8-3.4,2.1-4.1
	C665.5,400.8,662.1,402.9,664.2,404.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M673.1,409.8c2.8,1.4,4.8-3.4,2.1-4.1c-9.6-4.8-13.2-2.8-11-1.4
	C666.9,406.3,669.7,408.4,673.1,409.8L673.1,409.8z M654.5,375.9c-0.7,3.4-0.7,6.9-0.7,10.3c0.7,3.4,5.5,2.8,4.8-0.7
	C658,375.2,654.5,373.9,654.5,375.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M653.8,386.2c0.7,3.4,5.5,2.8,4.8-0.7c-0.7-10.3-4.1-11.8-4.1-9.6
	C653.7,379.4,653.7,382.8,653.8,386.2L653.8,386.2z M673.8,353.1c-3.4,1.4-6.2,2.8-8.9,4.8c-2.8,2.1,0,6.2,2.8,4.1
	C676,355.8,676,352.4,673.8,353.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664.8,357.9c-2.8,2.1,0,6.2,2.8,4.1c8.3-6.2,8.3-9.6,6.2-8.9
	C670.3,354.5,667.6,355.8,664.8,357.9L664.8,357.9z M137.6,383.5c-0.7,6.2-2.8,20.1-3.4,24.2c0,2.1,0,4.8,3.4,6.2
	c0-6.9,4.8-34,4.8-34 M603.9,384.2c0,0-0.7,4.8-0.7,11.1c0,2.1,0,4.1,3.4,6.2c-0.7-6.9,0.7-18.7,0.7-18.7"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M764.4,315
		l-24.8,0.1l-11.4,7.6c0,0-13.1-17.3-41.5-17.3c0,0-13.2-0.1-21.7,1.1c0,0-21.2,1.8-33.6,15.7c0,0-12.7,12.5-21,47.6l-2.9,13.1
		c0,0-0.8,1.6-5.1,0.4c0,0-5-0.7-6.7-6.2l-0.7-2.6v-0.8l2.3-9.5h-7.2c0,0,2.6-1.6,4.8-13.2c0,0,2.9-14.6,3.9-33.4l0.3-8.3
		c0,0,0.1-13.8-1.5-33c0,0-2.2-21.6-4.1-24.1c0,0,1.1,1.7,7.7,3.2c0,0,9.8,1.9,23.8,3.4l48.4,5.1c0,0,33.4,3.4,61.1,7.6
		c0,0,40,6.1,43.7,12.2h-3c0,0-3.3-0.1-7,3.4c0,0-3.9,3.9-3.9,7.8V315H764.4z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M586.7,364.1h3.4
		c0,0,2.6-1.8,4.4-11.3c0,0,2.6-12.7,3.6-26.1c0,0,1.2-13.5,1-21.6c0,0-0.1-12.5-1.2-25.4c0,0-0.8-9.4-1.8-15H421.9v91.9
		c0,0,0,7.2,7.6,7.6h28.3h41h47.3L586.7,364.1L586.7,364.1z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M558.9,256.1v-13.2
		c0,0,0.4-4.7-4.8-6.2c0,0-2.2-0.6-4.7,0.3c0,0-2.9,1.1-2.9,2.9v18c0,0,0.8,4.1,6.2,4.4C552.7,262.4,558.6,262.8,558.9,256.1z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M554.9,230.6
		c0,0-21.9-22.4-37.8-31.6c0,0-16.6-11.6-36.9-11.3H443c0,0-6.2,0.1-7.2,7.6v60.3c0,0-0.1,6.5,7.2,7.6h109.8v-0.7c0,0-5-0.1-6.2-4.4
		v-18c0,0,0.1-2.1,3.4-3c0,0,1.9-0.7,5,0.1L554.9,230.6L554.9,230.6z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M402.5,373.9
		H291.1c0,0-0.7,5.6-4.8,7.3c0,0-2.3,0.7-3.6-0.4c-1.2-1.1-1.2-2.5-1.4-4l-0.5-5.5c0,0-2.8-36.3-27.9-52.7c0,0-16-12.5-42.1-11.7
		c0,0-21.7-1.1-38.2,11.6c0,0-13.8,9.4-22.3,34c0,0-4.7,13.2-8.1,27.6c0,0-0.8,2.5-3.7,2.6c0,0-2.2,0.3-4.5-3.2c0,0-4.5-5.9-7.6-7.4
		c0,0-2.1-1.5-5.6-2.2c0,0-33.7-7.4-70-8.4h-4.5c0,0-5,0.4-8.9-8.9c0,0-3.9-8.3-4.8-24.9h7.2c0,0,8.9-2.3,9.5-11v-18
		c0,0,0.1-11.1-3.2-16c0,0-3.6-5.9-11.1-6.2h12.7h355.2L402.5,373.9L402.5,373.9z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="683.7"
              cy="381.4"
              r="58.9"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="212"
              cy="381.7"
              r="59.2"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(RegularCabPickup2DrRight);
