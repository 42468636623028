import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegularCabPickup2DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M420.5,149.5h-1.8c-26.4,0.1-85.6,2.2-95.9,8.1c-3.3,2.1-17.3,33.3-26.8,68.8l-0.3-0.4v-7.8
	c0-2.8-2.1-4.7-5.4-4.7H271c-3.3,0-5.9,2.6-5.9,5.4l-0.5,11.4c0,3.3,1.4,6.1,4.7,6.1l19.5,1.4l1.4,0.1c1.4,0,1.9-0.4,3.3-1v0.7
	c-2.8,10.7-4.7,22.1-6.1,32.2l-0.7,1.4c-8.7,2.6-12.1,6.7-8.1,40.2c2.3,19.3,4.8,29.4,8.7,34.5c2.9,3.9,6.6,5,11.4,5h244.7
	c4.8,0,8.5-1.1,11.4-5c3.9-5.2,6.1-15.4,8.4-34.5c4-33.6,0.7-38-8-40.6l-0.7-0.1c-1.4-10-3.3-22.1-6.1-32.9V237
	c1.4,0.7,2.3,0.8,3.7,0.8l1.4-0.1l19.1-1.2c3.3,0,4.7-2.8,4.7-6.1l-0.4-12.1c0-2.8-2.8-4.7-6.1-4.7l-19.5,0.1
	c-3.3,0-5.5,1.9-5.5,4.5v8.7c-9.4-35.5-22.8-67.3-26.3-69.3c-10.3-5.8-68.9-7.8-95.5-8.1L420.5,149.5L420.5,149.5z"
          />
          <path
            fill="#EAEBEB"
            d="M356,257.5c-7.4,0-10.2,3.3-12.1,3.3l7.4,31.8c1.8,0.3,3.7,0.8,5.4,1.4l8.8,4c3.4,0.7,7.4,1.4,11.4,1.4h88.5
	c4.7,0,8.8-0.7,11.4-1.4l9.5-4c1.8-0.5,3.7-1.1,5.4-1.4l7.4-31.8c-2.8,0-5.4-3.3-12.1-3.3H356L356,257.5z"
          />
          <path
            fill="#EAEBEB"
            d="M312.8,230.8c0,0,63.1-2.8,108.1-2.8c44.3,0,108.1,2.8,108.1,2.8c4,0,5.6-1.5,5-5
	c-5.4-24.1-13.5-50.4-16.8-59c-16.8-4-73.9-6.1-96.2-6.1c-22.8,0-79.9,2.1-96.2,6.1c-4,8.7-11.4,34.8-16.8,59
	C307.7,229.3,308.1,230.8,312.8,230.8z M344,260.8h-36.3c-8.3,0-10.7,5.5-12.8,20.1c-0.4,3.2-0.8,6.7-1.4,10.7v0.7h51.9
	c1.1,0,2.6,0,4.1,0.3c0.5,0.1,1.2,0.1,1.9,0.3L344,260.8L344,260.8z M498.5,260.8l-7.4,32c0.8-0.1,1.6-0.3,2.5-0.4
	c1.1-0.1,1.9-0.1,2.9-0.1h52.4v-0.7c-0.4-4-1-7.6-1.4-10.7c-2.1-14.7-4.4-20.1-12.8-20.1L498.5,260.8L498.5,260.8z"
          />
          <g>
            <path
              fill="#5E5E5E"
              d="M369.5,290.9c0,2.8,2.1,4.8,4.7,4.8h94.1c2.1,0,4.7-2.1,4.7-4.8v-4.1H369.5L369.5,290.9L369.5,290.9z
		 M359.5,262.4h-3.3c-3.3,0-4.7,2.8-1.4,18.3c2.1,11.6,11.4,15.5,11.4,10.9l-2.1-24.5C364.1,264.4,362.2,262.4,359.5,262.4
		L359.5,262.4z M486.6,262.4h-4c-2.1,0-4,2.1-4,4.8l-2.1,24.5c0,4.8,8.8,0.7,11.4-10.9C490.6,265,489.2,262.4,486.6,262.4
		L486.6,262.4z M469.6,262.4h-97.4c-2.1,0-4.7,2.1-4.7,4.8l0.7,4.1h106.2v-4.1C474.5,264.3,472.4,262.4,469.6,262.4L469.6,262.4z
		 M368.3,274.5l0.7,9.5h104.1l0.7-9.5H422"
            />
          </g>
          <path
            fill="#5E5E5E"
            d="M387.7,342.5h-23.5c0,0-0.7,0.7-0.7,1.4v2.1c0,0.7,0.7,1.4,0.7,1.4h23.5c0,0,0.7-0.7,0.7-1.4v-2.1
	C388.3,343.2,387.7,342.5,387.7,342.5L387.7,342.5z M393,343.9v2.1c0,0.7,0.7,1.4,1.4,1.4h22.8c0.7,0,1.4-0.7,1.4-1.4v-2.1
	c0-0.7-0.7-1.4-1.4-1.4h-22.8C393.7,342.5,393,343.2,393,343.9L393,343.9z M424,343.9v2.1c0,0.7,0,1.4,0.7,1.4h22.8
	c0.7,0,1.4-0.7,1.4-1.4v-2.1c0-0.7-0.7-1.4-1.4-1.4h-22.8C424,342.5,424,343.2,424,343.9L424,343.9z M453.5,343.9v2.1
	c0,0.7,0.7,1.4,1.4,1.4h22.8c0.7,0,0.7-0.7,0.7-1.4v-2.1c0-0.7,0-1.4-0.7-1.4h-22.8C454.2,342.5,453.5,343.2,453.5,343.9
	L453.5,343.9z M388.8,328.9h-28.3c0.5,3,1.4,6.3,2.5,8.9c0.1,0.3,0.4,1.1,0.7,1.8h115.1c0.4-1,1-2.3,1.1-3c0.8-2.3,1.5-5.1,1.9-7.7
	H388.8z"
          />
          <path
            fill="#5E5E5E"
            d="M418.7,350.6H298.9c-4.8,0-8.5-1.1-11.4-5v66.7c0,3.3,2.1,6.1,5.4,6.1h23.5c3.3,0,6.1-2.6,6.1-6.1v-45h46.4
	c9.4,0,16.5-6.7,20.5-6.7s13.1,3.4,20.5,3.4h22.1c8.1,0,16.5-3.4,20.5-3.4s11.7,6.9,21,6.9h46.4v45c0,3.3,2.8,6.1,6.1,6.1h22.8
	c3.3,0,6.1-2.6,6.1-6.1v-66.6c-2.9,3.9-6.6,5-11.4,5H420.3L418.7,350.6L418.7,350.6z"
          />
          <path
            fill="#FFFFFF"
            d="M293.7,236.1c1.2-1.1,2.1-2.9,2.1-5v-13.6c0-2.8-2.1-4.7-5.4-4.7h-18.8c-3.4,0-6.1,2.1-6.1,4.7l-0.7,12.2
	c0,3.4,1.4,6.1,4.7,6.1l19.5,1.2l1.4,0.1c1.4,0,2.1-0.7,3.3-1.4L293.7,236.1L293.7,236.1L293.7,236.1z M546,231.5
	c0,2.1,1.1,4,2.8,5.1v-0.3c1.4,0.7,2.1,1.4,3.3,1.4l1.4-0.1l18.8-1.2c3.4,0,5.4-2.8,5.4-6.1l-1.4-12.4c0-2.8-2.8-4.7-6.1-4.7
	l-18.3,0.1h-0.3c-3.3,0.1-5.8,2.1-5.8,4.7v13.5L546,231.5L546,231.5z"
          />
          <path
            fill="#5E5E5E"
            d="M321.6,167.5c-2.8,2.1-10.6,26.1-17.2,58.2c-0.7,4,0.7,5.4,8.8,5.4c-4.7,0-5.4-2.1-4.8-5.4
	c5.2-24.1,12.5-50.2,16.5-58.9l0.1-0.6C323.5,166.7,322.3,167.1,321.6,167.5L321.6,167.5z"
          />
          <path
            fill="#FFFFFF"
            d="M555.8,295.4c-0.3,6.6-1.1,14.6-2.1,22.1H288.6l-2.1-22.1c0-1.1-0.1-2.3-0.1-3.3v-2.8
	c0-12.9,2.3-28.2,5.5-43.5c0.5-3,1.2-6.1,1.9-9.1c-2.6,10.7-4.7,22.1-6.1,32.3l-0.7,1.4c-8.7,2.8-12.1,6.7-8,40.4
	c2.3,19.3,4.8,29.4,8.7,34.7c2.9,3.9,6.5,5,11.4,5h244.2c5.1,0,8.8-0.7,11.6-4.8l-0.1-0.5l1-0.3c3.2-5.1,5.4-14.2,7.4-30.1
	c0.1-1.2,0.3-2.8,0.5-4c4-33.6-0.1-37.6-8.8-40.3c0.8,6.6,1.2,12.9,1.2,18.8v2.8C555.9,293.1,555.9,294.2,555.8,295.4L555.8,295.4z"
          />
          <path
            fill="#5E5E5E"
            d="M520.4,167.5c2.8,2.1,10.6,26.1,17.2,58.2c0.7,4-0.7,5.4-8.8,5.4c4.7,0,5.4-2.1,4.7-5.4
	c-5.2-24.1-12.5-50.2-16.5-58.9l-0.1-0.6C518.5,166.7,519.7,167.1,520.4,167.5L520.4,167.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M546,223.4v-4.7c0-3.3,2.8-5.4,6.1-5.4h18.8c3.3,0,6.1,2.1,6.1,5.4
	l0.7,12.1c0,2.8-2.1,5.4-4.7,5.4l-20.8,1.4c-0.7,0-1.4,0-2.8-0.7h-0.7c2.8,12.1,4.7,23.5,6.1,34.3l0.7-0.7
	c8.8,3.3,12.1,6.7,8.1,40.3c-2.1,16.8-4.7,26.8-7.4,32.9l-1.4,2.1v67.3c0,2.8-2.1,5.4-5.4,5.4h-23.5c-3.3,0-6.1-2.8-6.1-5.4v-45.7
	h-46.4c-9.4,0-16.8-6.7-20.8-6.7c-4,0-12.8,3.3-20.2,3.3h-22.1c-8.1,0-16.8-3.3-20.8-3.3s-11.4,6.7-20.8,6.7h-46.4v45.7
	c0,2.8-2.8,5.4-6.1,5.4h-23.5c-2.8,0-5.4-2.8-5.4-5.4v-67.3l-1.4-2.1c-2.8-6.1-4.7-16.1-6.7-32.9c-4-33.6-1.4-37,8.1-40.3V269
	c1.4-10,4-20.8,6.1-32.3c-0.7,0.7-2.1,0.7-3.3,0.7L270,236c-3.3,0-5.4-2.8-5.4-5.4l0.7-12.1c0-3.3,2.8-5.4,6.1-5.4h18.8
	c3.3,0,6.1,2.1,6.1,5.4v8.3c9.4-34.9,22.8-66.6,26.8-69.2c10.7-6.1,73.9-8.1,98.1-8.1c24.9,0,88,2.1,98.8,8.1
	c3.3,2.8,16.8,34.3,26.3,69.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M420.9,160.9c22.8,0,79.9,1.4,96.2,5.4c4,8.8,11.4,34.9,16.8,59.2
	c0.7,4,0,5.4-4.7,5.4c0,0-63.1-2.8-108.3-2.8c-44.3,0-108.3,2.8-108.3,2.8c-4,0-5.4-1.4-4.7-5.4c5.4-24.2,13.5-50.3,16.8-59.2
	C341.7,162.3,398.8,160.9,420.9,160.9 M498.3,261l-7.4,31.6h0.7c2.1,0,4-0.7,5.4-0.7h51.7c-2.8-23.5-4-31-14.2-31 M343.6,261
	l7.4,31.6h-0.7c-2.1,0-3.3-0.7-4.7-0.7h-52.4c2.8-23.5,4-31,14.2-31 M420.9,350.5H298.6 M420.9,317.5H287.8 M420.9,339.7H300"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M293.3,236.8c-4,18.8-7.4,37-7.4,53.1 M420.9,149.5
	c-24.2,0-87.4,2.1-98.1,8.1c-4,2.8-17.5,34.3-26.8,69.2 M298.7,350.5c-6.7,0-12.8-41-12.8-60.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M300,339.7c-5.4,0-7.3,1.4-6.6,2.8 M304,225.4c-0.7,4-10.7,14.2-12.8,21.5
	 M324.9,166.3c-3.3,8.8-11.4,34.9-16.8,59.2 M420.9,160.9c-24.2,0-90.1,1.4-99.5,6.7c-2.8,1.4-10,26.3-17.5,57.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M287.2,270.4c-9.4,3.3-12.1,6.7-8.1,40.3c4,34.3,8.1,39.6,19.5,39.6
	 M368.5,367.2c9.4,0,16.8-6.7,20.8-6.7s12.8,3.3,20.8,3.3 M410.2,363.8h10.7 M322.2,367.2h46.4 M287.2,345.8v67.3
	c0,2.8,2.8,5.4,5.4,5.4h23.5c3.3,0,6.1-2.8,6.1-5.4v-62.6 M324.9,166.3l3.3-10.7 M320.8,168.3l4-11.4 M289.8,237.5
	c3.3,0,6.1-2.8,6.1-5.4v-13.5c0-3.3-2.8-5.4-6.1-5.4H271c-3.3,0-6.1,2.1-6.1,5.4l-0.7,12.1c0,2.8,2.1,5.4,5.4,5.4L289.8,237.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M343.6,261c2.8,0,5.4-3.3,12.1-3.3 M355.7,257.7h65.2 M307.5,261h36.3
	 M308,225.4c-0.7,4,0.7,5.4,4.7,5.4 M312.8,230.8c0,0,63.8-2.8,108.3-2.8 M295.9,229.4h-4.7c-1.4,0-2.8,1.4-2.8,2.8v5.4 M304,225.4
	c-0.7,4,1.4,6.1,8.7,5.4 M299.3,233.5c0,0,0,27.5,8.1,27.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M312.8,230.8c-2.1,0-4.7,2.1-4.7,4.7v56.4 M496.9,296
	c-2.8,0-7.4,0.7-11.4,2.1l-8.8,4c-3.3,0.7-7.4,1.4-11.4,1.4 M465.4,303.4h-44.4 M556,296h-59.2 M345.7,296c2.8,0,6.7,0.7,10.7,2.1
	l9.4,4c2.8,0.7,6.7,1.4,10.7,1.4 M376.7,303.4h44.3 M285.9,296h59.8 M307.5,261c-10,0-11.4,7.4-14.2,31 M345.7,292
	c2.8,0,6.7,1.4,10.7,2.8l9.4,3.3c2.8,1.4,6.7,1.4,10.7,1.4 M376.7,299.3h44.3 M285.9,292h59.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M289.8,254.3c0,0,6.6,4.7,8,12.7 M294.7,281.1h13.5 M360.6,328.9h60.5
	 M359.2,317.5c0,2.8,1.4,14.9,4,21.6 M365.9,291.3l-2.1-24.2c0-2.1-2.1-4-4-4h-4c-2.8,0-4.7,2.8-1.4,18.2
	C357.1,292,365.9,296.7,365.9,291.3 M343.6,261l7.4,31.6 M420.9,350.5h122.3 M420.9,317.5h133.2 M420.9,339.7h121.6 M548.7,236.8
	c4,18.8,7.4,37,7.4,53.1 M420.9,149.5c24.9,0,88,2.1,98.8,8.1c3.3,2.8,16.8,34.3,26.3,69.2 M543.2,350.5c6.7,0,12.8-41,12.8-60.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M542.7,339.7c4.7,0,6.2,1.4,5.9,2.8 M537.9,225.4
	c0.7,4,11.4,14.2,12.8,21.5 M517.1,166.3c4,8.8,11.4,34.9,16.8,59.2 M420.9,160.9c24.9,0,90.8,1.4,99.5,6.7
	c2.8,1.4,10.7,26.3,17.5,57.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M555.3,270.4c8.8,3.3,12.1,6.7,8.1,40.3c-4,34.3-8.8,39.6-20.2,39.6
	 M473.4,367.2c-9.4,0-16.8-6.7-20.8-6.7c-4,0-12.8,3.3-20.2,3.3 M432.4,363.8h-11.4 M519.7,367.2h-46.4 M554.7,345.8v67.3
	c0,2.8-2.1,5.4-5.4,5.4h-23.5c-3.3,0-6.1-2.8-6.1-5.4v-62.6 M517.1,166.3l-3.3-10.7 M521.1,168.3l-3.3-11.4 M552,237.5
	c-3.3,0-6.1-2.8-6.1-5.4v-13.5c0-3.3,2.8-5.4,6.1-5.4h18.8c3.3,0,6.1,2.1,6.1,5.4l0.7,12.1c0,2.8-2.1,5.4-4.7,5.4L552,237.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M498.3,261c-2.1,0-4.7-3.3-12.1-3.3 M486.1,257.7h-65.2 M534.6,261h-36.3
	 M533.9,225.4c0.7,4,0,5.4-4.7,5.4 M529.2,230.8c0,0-63.1-2.8-108.3-2.8 M546.7,229.4h4c2.1,0,2.8,1.4,2.8,2.8v5.4 M537.9,225.4
	c0.7,4-0.7,6.1-8.8,5.4 M543.2,233.5c0,0-0.7,27.5-8.8,27.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M534.6,261c10,0,11.4,7.4,14.2,31 M496.9,292c-2.8,0-7.4,1.4-11.4,2.8
	l-8.8,3.3c-3.3,1.4-7.4,1.4-11.4,1.4 M465.4,299.3h-44.4 M556,292h-59.2 M529.2,230.8c2.8,0,4.7,2.1,4.7,4.7v56.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M552,254.3c0,0-6.5,4.4-7.8,12.5 M547.4,281.1h-12.8 M453.3,328.9v10.9
	 M389.4,328.9v10.9 M420.9,328.9v10.9 M481.5,328.9h-60.5 M482.8,317.4c0,2.8-1.4,14.9-4,21.6 M476.1,291.3l2.1-24.2
	c0-2.1,2.1-4,4.7-4h3.3c3.3,0,4.7,2.8,1.4,18.2C485.5,292,476.1,296.7,476.1,291.3 M498.3,261l-7.4,31.6 M375.3,308.8h92V329h-92
	V308.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375.3,308.8h92V329h-92V308.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M474,271.1l0.7-4 M367.8,271.1H474 M474.7,267.1c0-2.8-2.8-4.7-4.7-4.7
	h-97.4c-2.8,0-4.7,2.1-4.7,4.7v4 M369.2,287.2v3.3 M472.7,287.2H369.2 M369.2,290.6c0,2.8,2.8,4.7,4.7,4.7H468
	c2.8,0,4.7-2.1,4.7-4.7v-3.3 M473.4,283.9l0.7-9.4 M369.2,283.9h104.1 M368.5,274.5l0.7,9.4 M474,274.5H368.5 M425.1,347
	c-0.7,0-1.4,0-1.4-0.7v-2.8c0-0.7,0.7-1.4,1.4-1.4h22.8c0.7,0,0.7,0.7,0.7,1.4v2.8c0,0.7,0,0.7-0.7,0.7H425.1 M454.6,347
	c-0.7,0-1.4,0-1.4-0.7v-2.8c0-0.7,0.7-1.4,1.4-1.4h22.8c0.7,0,1.4,0.7,1.4,1.4v2.8c0,0.7-0.7,0.7-1.4,0.7H454.6 M394.1,342.3
	c0,0-0.7,0.7-0.7,1.4v2.8c0,0.7,0.7,0.7,0.7,0.7h23.5 M417.6,342.3h-23.5 M417.6,347c0,0,0.7,0,0.7-0.7v-2.8c0-0.7-0.7-1.4-0.7-1.4
	 M387.4,347c0.7,0,1.4,0,1.4-0.7v-2.8c0-0.7-0.7-1.4-1.4-1.4h-22.8c-0.7,0-1.4,0.7-1.4,1.4v2.8c0,0.7,0.7,0.7,1.4,0.7H387.4
	 M375.3,308.8h92V329h-92V308.8z M409.5,279.2c-0.7,3.3,7.4,4.7,11.4,4.7c5.4,0,12.1-1.4,12.1-4.7c0-3.3-6.7-4.7-11.4-4.7
	C417,274.5,409.5,275.8,409.5,279.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M409.5,279.2c-0.7,3.3,7.4,4.7,11.4,4.7c5.4,0,12.1-1.4,12.1-4.7
	c0-3.3-6.7-4.7-11.4-4.7C417,274.5,409.5,275.8,409.5,279.2z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M311.4,230.8
		c0,0-2.3,0-3.2-1.8c0,0-0.7-1.2-0.1-3.4c0,0,6.7-30.4,16.8-59.2c0,0,13.5-3.3,46.6-4.3c0,0,33.4-1.2,49.4-1.1
		c0,0,16.2-0.1,51.3,1.1c0,0,30.5,1.2,44.7,4.3h0.1c0,0,4.4,9.1,10.3,32.3c0,0,5.8,23.1,6.5,26.7c0,0,0.6,3.2-0.1,3.9
		c0,0-0.7,1.5-3.9,1.5c0,0-53.9-2.3-83.2-2.5l-25.6-0.1l-26.4,0.3c0,0-35.2,0.7-69.6,1.9C325.1,230.4,312,230.8,311.4,230.8z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M307,261c0,0-3,0-5-6.1
		c0,0-2.3-5.9-2.8-21.6c0,0,4.1-5.6,4.7-7.4c0,0-1.2,5.8,7.8,4.8c0,0,39.2-2.1,61.4-2.1c0,0,39.9-0.8,47.9-0.7c0,0,31,0.1,50.9,0.7
		l56.7,1.9c0,0,4.3,0.4,6.9-0.3c0,0,3-0.3,2.6-4.3c0,0,2.6,4.7,5.2,7.7c0,0-0.6,11.3-2.1,16.9c0,0-1.1,5-2.6,7.3
		c0,0-1.5,3.2-4.7,2.9h-35.6c0,0-1-0.1-1.8-0.5c0,0-4.1-2.6-9.1-2.8h-1.6h-46.8h-35.4h-36.5H356c0,0-2.9-0.1-5.9,1l-2.5,1.1
		c0,0-2.6,1.4-3.4,1.4h-24.5L307,261L307,261z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M293.3,292
		h52.4c0,0,2.8,0.1,5.4,1v-0.5l-7.3-31.4h-35.6c0,0-6.1-0.8-8.9,3.7c0,0-2.1,2.2-3.7,11.4C295.3,276.3,294.4,282,293.3,292z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M490.8,293
		c0,0,3.2-1.1,6.5-1.1s36.6,0,36.6,0h14.7c0,0-1.1-9.5-1.7-13.2c0,0-1.4-9.9-3.7-13.3c0,0-1.9-4.8-9.4-4.4h-35.6L490.8,293z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M351.1,293v-0.5l-7.3-31.4
		c0,0,0.7,0,1.8-0.4c0,0,3.9-1.8,5.4-2.2c0,0,1.8-0.7,5.1-0.7h27.8h27.9h21.7h29.2h23.7c0,0,3.4-0.3,7.6,1.4l2.9,1.4
		c0,0,1.1,0.5,1.8,0.5l-3.3,14.3l-3.3,14.2l-0.8,3.6c0,0-4.3,1-13.1,4.5c0,0-2.9,1.5-8.4,1.6h-2.1h-23.5h-22.8H400h-24.3
		c0,0-6.6,0.3-9.6-1.4C365.9,298.1,355.6,294.3,351.1,293z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M278,299.3l5.4-2.8l2.5-4.7
		H308h37.7c0,0,2.3,0,5.4,1c0,0,10,3.4,14.7,5.1c0,0,2.2,1.4,8.1,1.4h35.5h24.9h33.6c0,0,5.6,0.1,9.4-1.5c0,0,8-3.4,13.1-4.7
		c0,0,3.6-1.1,6.1-1.1h32.6h27l2.8,4.7l5.9,2.8c0,0-2.6,25.7-5.4,35.8c0,0-1.2,5-3.2,8.5s-5.4,6.7-12.8,6.7h-41.7h-54.8h-25.9h-29.3
		H341h-42.4l-2.1-0.1c0,0-6.9,0.1-10.3-6.7c0,0-2.5-4-4.3-14.2c0,0-1.4-6.2-2.5-16.4C279.4,312.9,278.6,306.4,278,299.3z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(RegularCabPickup2DrFront);
