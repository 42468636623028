import React, { Component } from 'react';
import { getConfig } from '../../utils/helpers';

export default class IDHorizontal extends Component {
  render() {
    if (getConfig('localization') === 'en-uk') {
      return (
        <span>
          <strong>REG:</strong> {this.props.stockNumber}
        </span>
      );
    }

    return (
      <span>
        <strong>VIN:</strong> {this.props.vin}
      </span>
    );
  }
}
