import React, { Component } from 'react';
import { connect } from 'react-redux';

class RegularCabPickup2DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M420.1,179.8h-0.7c-21.8,0.1-71.9,2.6-81,7.8c-2.7,1.8-14.3,31.8-22.6,60v-0.1l-0.2-2.1v-6.2
	c0-2.9-2-4.4-4.9-4.4h-16.1c-2.4,0-4.9,1.5-4.9,4.4l-0.9,10.3c0,2.9,2,4.9,4.4,4.9l17.6,0.9c0.7,0,1.3,0,1.8-0.1
	c0.1,0,0.9-0.5,1.4-0.8l0.1-0.5l-0.4,1.4c-1.4,6.5-3,13.3-4.4,19.8c-0.6,2.6-1,5-1.5,7.5c-2.9,2.9-7.8,2.6-6.3,31.1
	c0.7,14,1.4,24.1,3.4,31c1.9,6.4,5,9.9,10.3,10.7c0.7,0.1,2.7,0.2,3.8,0.2V347c0-3.8,2.4-5.4,4.4-5.4h194.8c2,0,4.4,1.5,4.4,5.4v8.7
	c1.2,0,3.6-0.1,4.3-0.2c5.4-0.8,8.4-4.3,10.3-10.7c2-6.9,2.7-17,3.4-31c1.4-28.7-3.4-28.3-6.3-31.1c-0.5-2.4-0.9-5-1.5-7.5
	c-1.4-6.5-3-13.3-4.4-19.8l-0.3-1.4l0.1,0.5c0.5,0.4,1.3,0.7,1.4,0.8c0.5,0.1,1.2,0.1,1.7,0.1l17.6-0.9c2.4,0,4.4-2,4.4-4.9
	l-0.9-10.3c0-2.9-2.4-4.4-4.9-4.4h-16.1c-2.9,0-4.9,1.5-4.9,4.4v6.2l-0.2,2.1v0.1c-8.3-28.2-19.9-58.3-22.6-60
	c-8.7-5-55.8-7.6-78.7-7.8L420.1,179.8L420.1,179.8z"
          />
          <path
            fill="#EAEBEB"
            d="M370.2,193.4c-0.7,0-1.4,0.1-2,0.1c-13.2,1.1-23.4,2.9-25.3,6.6c-0.5,0.6-0.9,1.4-1.3,2.4
	c-0.7,1.5-1.4,3.3-2.1,5.4c-1.6,4.8-3.1,10.8-4.3,16.1c-0.4,1.8-0.7,3.4-1,4.9c-0.5,2.1-0.8,4-1,5.4c-0.2,1.2-0.4,2-0.4,2.4
	c-0.4,1-0.4,2-0.2,2.9c0.5,3.3,3.5,5.4,6.6,5.4h131.4v0.5l0.9-0.5h31.2c3.9,0,7.8-3.4,6.3-8.3c-0.1-1.2-0.7-4.1-1.5-7.8
	c-0.4-1.5-0.7-3.1-1-4.9c-1.2-5.4-2.8-11.3-4.3-16.1c-0.7-2.1-1.4-3.8-2.1-5.4c-0.5-0.9-0.9-1.9-1.3-2.4c-2-4-13.2-5.8-27.3-6.9
	l-6.9-0.4v-0.1h-2.4c-12.1-0.4-24.8-0.5-34.7-0.5h-10c-10.3,0-25.9,0-39.5,0.5L370.2,193.4L370.2,193.4z"
          />
          <path
            fill="#5D5D5D"
            d="M522.2,347.1c0-3.8-2.5-5.4-4.4-5.4h-33.7v5.4h-40.5c-3.4,0-5.8-2.5-9.2-5.4h-27.9c-2.9,2.9-5.8,5.4-9.2,5.4
	h-40.5v-5.4h-33.7c-2,0-4.4,1.5-4.4,5.4v12.1h78.6c7.2,0,11.1,11,23.4,11.2c11.8-0.2,16.2-11.2,23-11.2h78.6L522.2,347.1
	L522.2,347.1z"
          />
          <g>
            <path
              fill="#FFFFFF"
              d="M372.1,187.4c-0.7,0.4-0.9,1.1-0.9,1.8v68.2h5.8v-68.4c0-0.9-0.9-2-2-2h-0.5l-1.3,0.1
		C372.9,187.2,372.5,187.2,372.1,187.4L372.1,187.4z M422.3,186.2H420c-0.9,0-2,0.9-2,2v69.2h6.3v-69.4
		C424.2,187.1,423.3,186.2,422.3,186.2L422.3,186.2z M466.9,187.1h-0.2c-1.5,0-2,0.9-2,2v68.2h5.8v-68.2c0-0.7-0.5-1.4-1.2-1.8
		c-0.5,0-0.8-0.1-1.3-0.1C467.7,187.2,467.2,187.1,466.9,187.1L466.9,187.1z"
            />
            <path
              fill="#FFFFFF"
              d="M521.3,254c-1.9-11.2-3.8-24.5-8.4-37.7c-7.5-20.8-13.4-29.1-18.1-30.9c-0.4-0.1-2.6-0.7-4.3-1.2
		c-6.3-1-14.1-1.9-22.4-2.4c-15.5-1.3-32.4-1.9-43.6-2h-5.1c-11.1,0-29.5,0.7-46.2,2.1c-8.3,0.7-16.1,1.5-22.4,2.4
		c-0.5,0.1-3.3,0.9-3.5,0.9c-5.1,1.2-10.8,9.2-18.8,31.1c-4.4,12.8-6.9,25.8-8.4,36.7c-0.1,0.9-0.4,2.9-0.5,4.2h5.8
		c0.1-17.4,5-37.2,9.3-47.8c3.8-9.8,6.3-14.6,7.8-15.6c5-3.6,16.3-5.5,29.3-6.6c0.2-0.1,0.6-0.2,1-0.2h1.5c16.6-1.3,35.1-1.2,46-1.2
		h0.5c11.3,0,29.5-0.1,45.8,1.2h1.7c0.2,0,0.6,0.1,0.8,0.2c12.8,1.1,24.2,3,29.4,6.6c1.5,0.9,3.8,5.8,7.8,15.6
		c4.3,10.6,8.9,30.3,8.9,47.8h6.3C521.7,256.4,521.4,254.7,521.3,254L521.3,254z"
            />
          </g>
          <path
            fill="#FFFFFF"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M529.1,311v-36.4c0.3,1,0.7,2.3,1,3.6c0.8,3.5,1.3,7.7,1.3,12.2v0.9
	C531.5,302.1,530.5,308,529.1,311L529.1,311z M529.1,253.1v0.2V253.1L529.1,253.1z M529.1,253.1v0.2c0.5,0.2,1,0.7,1.3,0.7
	c0.5,0.1,1,0.1,1.5,0.1l15.4-0.8c2.1,0,3.8-1.8,3.8-4.3l-0.8-9c0-2.6-2.1-3.8-4.3-3.8h-14.1c-1.2,0-2.1,0.2-2.8,0.8 M312.5,253.1
	v-0.2V253.1L312.5,253.1z M529.1,253.1v-0.2c-0.5-1.9-1-3.7-1.6-5.6v-0.1l0.2-1.9v-5.4c0-1.4,0.6-2.4,1.4-3 M529.1,253.1v-0.2V253.1
	L529.1,253.1z M529.1,274.6c-1.4-4.1-3.3-6.5-5.5-6.5h-10.3v45.4h13.2c0.9,0,1.7-0.7,2.6-2.5V274.6z M312.5,311.4v-36.7
	c1.4-4.1,3.4-6.6,5.5-6.6h10.3v45.3h-13.2C314,313.4,313.2,312.8,312.5,311.4L312.5,311.4z M312.5,236.9c0.9,0.6,1.4,1.6,1.4,3v5.4
	l0.2,1.9v0.1c-0.6,1.9-1,3.7-1.6,5.6v0.2 M338.4,325.6h-8.3v8.7h8.3L338.4,325.6L338.4,325.6z M503.2,325.6v8.7h7.8v-8.7
	L503.2,325.6L503.2,325.6z M334.6,356.3v-6.8h-11.2v6.9h11.2V356.3L334.6,356.3z M312.4,253.3v-0.2V253.3L312.4,253.3z M312.5,253.1
	v0.2c-0.5,0.2-1,0.7-1.3,0.7c-0.5,0.1-1,0.1-1.5,0.1l-15.4-0.8c-2.1,0-3.8-1.8-3.8-4.3l0.8-9c0-2.6,2.1-3.8,4.3-3.8h14.1
	c1.2,0,2.1,0.2,2.8,0.8 M312.5,274.7c-1.4,4-2.2,9.6-2.3,15.9v0.9c0,11.1,0.8,17.1,2.3,19.9V274.7z"
          />
          <path
            fill="#5D5D5D"
            d="M412.1,274.4c-1.5,0-2.9,1.5-2.9,2.9l0.5,6.3c0,1.5,1.5,2.9,2.9,2.9h16.1c2,0,3.4-1.5,3.4-2.9l0.5-6.3
	c0-1.5-1.5-2.9-3.4-2.9L412.1,274.4L412.1,274.4z"
          />
          <g>
            <path
              fill="#5D5D5D"
              d="M318.9,355.8c-1.2,0-3.1-0.1-3.8-0.2c-5.4-0.8-8.4-4.3-10.3-10.7v38.2h35.6v-23.9h-21.5L318.9,355.8
		L318.9,355.8z M526.6,355.6c-0.7,0.1-2.9,0.2-4.2,0.2v3.4h-21.1v23.9h35.6v-38.2C535,351.3,531.9,354.8,526.6,355.6L526.6,355.6z"
            />
          </g>
          <g>
            <path
              fill="#5D5D5D"
              d="M308.2,404.5c0,2.9,2.4,4.9,4.9,4.9H333c2.9,0,4.9-2,4.9-4.9v-21.5h-29.7V404.5z M503.7,383.1v21.5
		c0,2.9,2,4.9,4.9,4.9h19.9c2.4,0,4.9-2,4.9-4.9v-21.5L503.7,383.1L503.7,383.1z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M314,245.4l0.2,1.9v0.1c-0.6,1.9-1,3.7-1.6,5.6v0.3c-0.5,0.4-1,0.7-1.3,0.7c-0.5,0.1-1,0.1-1.5,0.1
	l-15.4-0.8c-2.1,0-3.8-1.8-3.8-4.3l0.8-9c0-2.6,2.1-3.8,4.3-3.8h14.1c1.2,0,2.1,0.2,2.8,0.8c0.9,0.6,1.4,1.6,1.4,3.1L314,245.4
	L314,245.4z M294.7,234.9c-2.5,0-4.9,1.5-4.9,4.4l-0.9,10.3c0,2.9,2,4.9,4.4,4.9l17.6,0.9c0.7,0,1.3,0,1.8-0.1
	c0.1,0,0.4-0.1,0.6-0.4c0.2-0.1,0.6-0.4,0.8-0.5c0-0.1,0.1-0.2,0.1-0.5c0.6-2.1,1.2-4.3,1.9-6.4l-0.2-2.1v-6.2c0-2.9-2-4.4-4.9-4.4
	L294.7,234.9L294.7,234.9z"
          />
          <path
            fill="#FFFFFF"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M531.2,234.9c-2.9,0-4.9,1.5-4.9,4.4v6.2l-0.2,2.1
	c0.6,2.2,1.3,4.3,1.9,6.4l0.1,0.5c0.2,0.1,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.4,0.6,0.4c0.5,0.1,1.2,0.1,1.7,0.1l17.6-0.9
	c2.4,0,4.4-2,4.4-4.9l-0.9-10.3c0-2.9-2.4-4.4-4.9-4.4L531.2,234.9L531.2,234.9z M527.8,239.9c0-1.4,0.6-2.4,1.4-3.1
	c0.7-0.5,1.7-0.8,2.8-0.8h14.1c2.1,0,4.3,1.3,4.3,3.8l0.8,9c0,2.6-1.7,4.3-3.8,4.3l-15.4,0.8c-0.6,0-1.2,0-1.5-0.1
	c-0.1,0-0.8-0.4-1.3-0.7v-0.5c-0.5-1.9-1-3.7-1.6-5.6v-0.1l0.2-1.9L527.8,239.9L527.8,239.9z"
          />
          <path
            fill="#FFFFFF"
            d="M301.5,314c0.7,14,1.4,24.1,3.4,31c1.9,6.4,5,9.9,10.3,10.7h0.1c0.8,0.1,2.6,0.2,3.7,0.2v-8.7h-0.5
	c-4.8,0-10.5-15-12.1-33.2c-0.4-4.3-0.6-8.7-0.5-13.2c0-5.5,0.8-11.7,1.9-18.1C304.8,285.6,300.1,285.3,301.5,314L301.5,314z
	 M536,300.8c0.5,23.4-6.9,46.4-12.7,46.4h-0.9v8.7h0.2c1.3,0,3.4-0.1,4.1-0.2h0.1c5.4-0.8,8.4-4.3,10.3-10.7c2-6.9,2.7-17,3.4-31
	c1.4-28.7-3.4-28.3-6.3-31.2C535.3,289.1,536,295.3,536,300.8L536,300.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M342.8,200.3c-4.4,6.3-9.8,32.6-10.3,36.5c-1.5,4.9,2.4,8.3,6.3,8.3h32.2
	V194l-0.9-0.5C356,194.5,344.8,196.3,342.8,200.3 M418.4,245.1H377v-51.6l0.9-0.5c13.6-0.5,29.3-0.5,39.5-0.5h0.9 M498.8,200.3
	c4.4,6.3,9.8,32.6,10.7,36.5c0.9,4.9-2.9,8.3-6.9,8.3h-31.2l-0.9,0.5V193l0.9,0.5C485.6,194.5,496.8,196.3,498.8,200.3 M464.7,245.1
	h-40.5v-52.6h0.9c10.3,0,24.8,0,38.5,0.5h0.9v52.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M420.8,179.8c-21,0-73.1,2.4-82.3,7.8c-2.9,2-16.6,37.5-24.8,67.7
	 M318.5,347c-5.8,0-13.2-22.8-12.7-46.3 M420.8,341.6h-97.5 M420.8,347H318.5 M420.8,179.8c21,0,73.1,2.4,82.3,7.8
	c2.9,2,16.6,37.5,24.8,67.7c3.4,15.6,7.8,32.2,7.8,45.3 M526,246.9l0.1-7.7c0-2.9,2-4.4,4.9-4.4h16.1c2.4,0,4.9,1.5,4.9,4.4
	l0.9,10.3c0,2.9-2,4.9-4.4,4.9l-17.5,0.9c-0.9,0-2,0-2.4-0.5 M533.9,282.6c2.9,2.9,7.8,2.4,6.3,31.2c-1.5,29.3-2.1,41.9-17.7,41.9
	 M533.4,383v21.5c0,2.9-2.4,4.9-4.9,4.9h-19.9c-2.9,0-4.9-2-4.9-4.9V383 M315.8,247l-0.2-7.8c0-2.9-2-4.4-4.9-4.4h-16.1
	c-2.4,0-4.9,1.5-4.9,4.4l-0.9,10.3c0,2.9,2,4.9,4.4,4.9l17.5,0.9c0.9,0,2.7-0.4,3.1-0.9 M313.7,255.3c-3.4,15.6-7.8,32.2-7.8,45.3
	 M381.9,321.1h78v17h-78V321.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M381.9,321.1h78v17h-78V321.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M381.9,321.1h78v17h-78V321.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M381.9,321.1h78v17h-78V321.1z M319.1,347v12.1h101.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M323.4,349.4h11.2v6.9h-11.2V349.4z M323.4,341.6c-2,0-4.4,1.5-4.4,5.4
	 M397.5,347c3.4,0,6.3-2.4,9.2-5.4 M397.5,359.1c7.3,0,11.2,11.2,23.9,11.2 M372.6,338.2v-19.9 M330.3,334.2h8.3v-8.7h-8.3
	L330.3,334.2L330.3,334.2z M375.5,341.8l-2.9-3.6h-56.1c-1.5,0-2.9,0.5-3.8,2 M312.6,260.2c0,0,2.9-2.9,5.8-2.9h102.4 M315.1,313.4
	c-2.9,0-4.9-5.4-4.9-21.9c0-13.2,3.4-23.4,7.8-23.4h9.8 M318,268h102.8 M306.8,315.7c0,0,1.5-2.4,2.4-2.4 M328.3,310.3h92.6
	 M309.3,313.4h111.6 M420.8,274.3h-8.7c-1.5,0-2.9,1.5-2.9,2.9l0.5,6.3c0,1.5,1.5,2.9,2.9,2.9h8.3 M328.3,317.7h92.6 M328.3,257.8
	V347"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M314.6,309.4h9.8v-7.3h-9.8V309.4z M310.2,290.4h17.5 M311.7,280.7h16.1
	 M338.5,187.6v5.8 M319.9,257.3c1.5-11.7,4-26.8,8.9-40.9c8.3-22.9,14.1-30.7,19.5-31.1 M377,257.3v-68.2c0-0.9-0.9-2-2-2h-2
	c-1.5,0-2,0.9-2,2v68.2 M424.2,257.3v-69.2c0-0.9-0.9-2-2-2h-2.5c-0.9,0-2,0.9-2,2v69.2 M342.4,186.7v1.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M377,245.1h41.4 M342.8,200.3c-4.4,6.3-9.8,32.6-10.7,36.5
	c-0.9,4.9,2.9,8.3,6.9,8.3h30.7 M523.2,347c5.8,0,13.2-22.8,12.7-46.3 M420.4,341.6h97.5 M420.4,347h102.8 M506.6,209.5
	c4.4,10.7,8.7,30.2,8.7,47.8 M471.5,193.4c14.1,0.9,25.3,2.9,27.3,6.8 M424.8,192.5c10.7,0,25.3,0,38.9,0.5 M420.4,186.2
	c21,0,66.2-0.5,78.5,7.8c1.5,0.9,3.8,5.8,7.8,15.6 M517.8,341.6c2,0,4.4,1.5,4.4,5.4 M443.8,347c-3.4,0-5.8-2.4-9.2-5.4
	 M443.8,359.1c-6.9,0-11.2,11.2-23.4,11.2 M522.2,347v12.1H420.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M468.6,338.2v-19.9 M503.2,325.5h7.8v8.7h-7.8V325.5L503.2,325.5z
	 M466.2,341.8l2.4-3.6h56.5c1.5,0,3.7,0.2,4.1,2.2 M529,260.2c0,0-2.9-2.9-5.8-2.9H420.8 M526.6,313.4c2.4,0,4.9-5.4,4.9-21.9
	c0-13.2-3.4-23.4-7.8-23.4h-10.3 M523.6,268H420.8 M534.8,315.7c0,0-1.5-2.4-2.9-2.4 M512.9,310.3h-92.1 M531.9,313.4H420.8
	 M420.8,274.3h8.3c2,0,3.4,1.5,3.4,2.9l-0.5,6.3c0,1.5-1.5,2.9-3.4,2.9h-7.8 M513.4,317.7h-92.6 M513.4,257.8V347"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M517.3,302.1h9.8v7.3h-9.8L517.3,302.1L517.3,302.1z M531.5,290.4H514
	 M529.9,280.7h-16.1 M502.7,187.6v5.8 M521.8,257.3c-2-11.7-4.1-26.8-8.9-40.9c-8.3-22.9-14.6-30.7-19.5-31.1 M464.7,257.3v-68.2
	c0-0.9,0.5-2,2-2h2c0.9,0,2,0.9,2,2v68.2 M420.8,279.2h7.8c0.9,0,2-0.9,2-2v-2.4h-19.9v2.4c0,0.9,0.9,2,2.4,2H420.8 M499.3,186.7
	v1.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M464.2,245.1h-39.5 M498.8,200.3c4.4,6.3,9.8,32.6,10.3,36.5
	c1.5,4.9-2.4,8.3-6.3,8.3h-31.2 M304.8,345.3V383h35.6v-23.9 M307.9,282.6c-2.9,2.9-7.8,2.4-6.3,31.2c1.5,29.3,2.1,41.9,17.7,41.9
	 M308.3,383v21.5c0,2.9,2.5,4.9,4.9,4.9h19.9c2.9,0,4.9-2,4.9-4.9V383 M536.8,345.3V383h-35.6v-23.9 M484.2,341.6v17.5 M494.4,341.6
	v17.5 M357.1,341.6v17.5 M346.8,341.6v17.5 M335.1,209.5c-4.4,10.7-9.2,30.2-9.2,47.8 M369.7,193.4c-13.6,0.9-24.8,2.9-26.8,6.8
	 M417.4,192.5c-10.7,0-25.9,0-40,0.5 M420.8,186.2c-20.5,0-66.2-0.5-78,7.8c-1.5,0.9-3.8,5.8-7.8,15.6"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M371.2,245.2h-32.6
		c0,0-3.1-0.1-5.1-2.9c0,0-1.6-2.3-1.2-5.2c0,0,6.8-33.5,11.3-37.5c0,0,1.6-2.7,12.1-4.4c0,0,8.6-1.7,27.2-2.2l44.6-0.4
		c0,0,26.3-0.9,52.8,1.7c0,0,16.1,1.6,18.3,5.8c0,0,1.9,1.6,6.3,17.7l4.5,19c0,0,0.7,3.1-1.4,5.8c0,0-2.2,2.4-5.2,2.4h-39.5H417
		L371.2,245.2z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="
		328.3,257.3 513.4,257.3 513.5,313.4 420.8,313.4 328.3,313.4 	"
              id="Liftgate and Tailgate"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Liftgate and Tailgate')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('liftgateTailgateColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M328.3,268H318
		c0,0-2-0.1-3.6,2.4c-1.5,2.6-4.2,8.6-4.2,19.9c0,0-0.5,10.6,1.5,19c0,0,1,4,3.3,4h13.2v-22.7V268H328.3z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M513.4,268h10.3
		c0,0,2.2-0.1,4.1,3.3c1.9,3.4,3.7,10.3,3.7,19.1c0,0,0,9.6-0.6,12.9c0,0-0.8,9.4-4.3,10h-13.2v-23L513.4,268L513.4,268z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M306.6,316.1
		c0,0,1.6-2.8,2.7-2.8l19,0.1v4.3h27.3h47h35.8h40.2h35v-4.3l18.5-0.1c0,0,2,0,3.1,3.1c0,0-1.3,15-7.1,26c0,0-2.2,4.1-4.5,4.4h-1.3
		c0,0,0-5.4-4.4-5.4c-4.4,0-4.4,0-4.4,0h-38.8h-40.7h-34h-48.6h-27.7c0,0-4.3,0-4.4,5.4c0,0-1.7,0.5-4-2.4c0,0-3.5-4-6.2-15.7
		C308.8,328.8,307.8,324.8,306.6,316.1z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(RegularCabPickup2DrRear);
