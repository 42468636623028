import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback5DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 110 800 340">
          <path
            fill="#FFFFFF"
            d="M320.1,154c-0.3-2.8-1-4.4-2.3-4.5l-1.5,0.3c-6.3,1.8-11.2,4.5-14.4,8.4c1.5-0.5,1.8,3.1,1.1,10.6
	c-0.5,5.2-2.4,10.9-5.7,16.7l-1.6,2.9l-14.3,25.5l-1.3,2.3c-0.8,1.5-1.6,2.9-2.6,4.2l-2.3,2.9l-3.4,3.6c-3.7,5.8-6.7,11.5-8.4,17.2
	c2-1.3,3.9-2.3,6.2-3.1c0.3-1.3,0.8-2.6,1.3-4.1l0.2-0.6l1-1.9c1.8-3.9,3.7-7.8,6.2-12l18.7-32.8l1.5-2.8
	c6.7-11.2,14-19.1,21.9-24.2v-1.3L320.1,154L320.1,154z"
          />
          <path
            fill="#FFFFFF"
            d="M317.8,149.5c1.1,0.3,1.9,1.8,2.3,4.5l0.2,7.5v1.3l6.2-1.3l-0.2-8.4c-0.3-2.8-1-4.4-2.3-4.7l-1,0.2
	L317.8,149.5L317.8,149.5z M303,168.9c0.6-7.6,0.2-11-1.1-10.6c-1.3,1.5-2.3,3.1-2.9,5l-0.6,1.1c1-0.5,1.5-0.2,1.8,1
	c0.2,1.5,0.3,2.9,0.3,4.4v1.8c-0.2,4.4-1.6,10.1-4.7,17l1.6-2.9C300.6,179.6,302.5,174.1,303,168.9L303,168.9z"
          />
          <path
            fill="#EBEBEB"
            d="M300.4,171.5v-1.8l-0.8,1.5c-6.7,13.1-12.8,26.9-18.7,41.2l-1.8,4.5l2.4-2.9l14.3-25.5
	C298.6,181.8,300.3,176.1,300.4,171.5L300.4,171.5z"
          />
          <path
            fill="#FFFFFF"
            d="M299.6,171.2l0.8-1.5c0-1.5-0.2-2.9-0.3-4.4c-0.2-1.1-0.8-1.5-1.8-1l-3.4,6.8c-6.5,13.1-12.8,26.9-18.7,41.2
	l-5.5,13.8l8.4-9.4l1.8-4.5C286.8,198.1,293,184.4,299.6,171.2L299.6,171.2z M320.7,164.2c-0.3-0.5-0.5-0.8-0.5-1.3
	c-8,5-15.3,13.1-21.9,24.2l-1.5,2.8v6.5l1.5-2.3c2.8-4.1,5.8-7.8,9.6-10.9c4.7-4.5,10.4-8.1,17.2-10.6c5.2-1.8,11-3.2,17.5-4.2
	l5.8-0.8c23.4-2.8,47.1-4.2,70.8-4.2h3.2c23.7,0.2,47.4,1.5,70.8,4.2l5.8,0.8c6.5,1.1,12.3,2.4,17.5,4.2c6.8,2.4,12.5,6,17.2,10.6
	c3.7,3.2,7,6.8,9.6,10.9l1.5,2.3v-6.5l-1.5-2.8c-6.7-11.2-14-19.2-21.9-24.2c-0.2,0.5-0.2,1-0.5,1.3v0.2l-0.8,0.5
	c-2.1,1-6.8,0.8-13.8-0.5c-27.8-4.1-55.8-6.2-84.1-6.3h-3.2c-28.2,0.2-56.3,2.3-84.1,6.3c-7,1.3-11.5,1.5-13.8,0.5L320.7,164.2
	L320.7,164.2z"
          />
          <path
            fill="#FFFFFF"
            d="M320.7,164.1l6-1.3l-0.3-1.3l-6.2,1.3C320.2,163.4,320.4,163.7,320.7,164.1L320.7,164.1z M321.5,164.7
	c2.3,1,6.8,0.8,13.8-0.5c27.8-4.1,55.8-6.2,84.1-6.3h3.2c28.2,0.2,56.3,2.3,84.1,6.3c7,1.3,11.5,1.5,13.8,0.5l0.8-0.5l-6-1.3
	c-0.3,0.8-1,1.1-1.9,1l-6.7-1c-27.8-4.1-55.8-6.2-84.1-6.3h-3.2c-28.2,0.2-56.3,2.3-84.1,6.3l-6.7,1c-1,0.2-1.6-0.2-1.9-1l-6,1.3
	L321.5,164.7L321.5,164.7z"
          />
          <path
            fill="#FFFFFF"
            d="M328.7,163.9l6.7-1c27.8-4.1,55.8-6.2,84.1-6.3h3.2c28.2,0.2,56.3,2.3,84.1,6.3l6.7,1c1,0.2,1.6-0.2,2-1
	l0.3-1.3c-8.9-1.9-16.4-3.2-22.4-4.1h-0.2c-23.4-2.9-46.7-4.4-70.3-4.5h-3.2c-23.5,0.2-47.1,1.6-70.3,4.5h-0.2
	c-6,0.8-13.6,2.3-22.4,4.1l0.3,1.3C327.1,163.7,327.7,164.1,328.7,163.9L328.7,163.9z"
          />
          <path
            fill="#FFFFFF"
            d="M326.2,153l0.2,8.4c8.9-1.9,16.4-3.2,22.4-4.1h0.2c23.2-2.9,46.7-4.4,70.3-4.5h3.2
	c23.5,0.2,47.1,1.6,70.3,4.5h0.2c6,0.8,13.6,2.3,22.4,4.1l0.2-8.4c0.3-2.8,1-4.4,2.3-4.7c-23.9-2.9-47.6-4.9-71.3-5.7
	c-6.7-0.3-13.5-0.3-20.1-0.3h-3.2l0.5,2.9l1.8,0.8c1,0.5,1.5,1.1,1.5,1.9v2.1c-0.3,0.6-1,1-2.1,1.1h-7.6c-1.1-0.2-1.9-0.6-2.1-1.1
	V148c0-0.8,0.6-1.5,1.6-1.9l1.6-0.8l0.3-2.9h-2.9c-6.7,0-13.5,0.2-20.1,0.3c-23.7,0.8-47.4,2.8-71.3,5.7
	C325.3,148.6,325.9,150.3,326.2,153L326.2,153z M342.8,168.3c-6.5,1.1-12.3,2.4-17.5,4.2c-6.8,2.4-12.5,6-17.2,10.6
	c-3.7,3.2-6.8,6.8-9.6,10.9l-1.5,2.3l1.1,1.1l1.9-2.9c2.6-3.7,5.5-7.1,8.9-10.1c4.5-4.4,10.1-7.8,16.7-10.2
	c5.2-1.8,10.9-3.2,17.4-4.2l5.7-0.8c23.4-2.8,46.9-4.2,70.6-4.4h3.2c23.7,0.2,47.2,1.5,70.6,4.4l5.7,0.8c6.5,1.1,12.2,2.4,17.4,4.2
	c6.5,2.3,12,5.7,16.7,10.2c3.4,2.9,6.5,6.3,9.1,10.1l1.9,2.9l1.3-1.1l-1.5-2.3c-2.8-4.1-5.8-7.8-9.6-10.9
	c-4.7-4.5-10.4-8.1-17.2-10.6c-5.2-1.8-11-3.2-17.5-4.2l-5.8-0.8c-23.4-2.8-47.1-4.2-70.8-4.2h-3.2c-23.7,0-47.4,1.5-70.8,4.2
	L342.8,168.3L342.8,168.3z"
          />
          <path
            fill="#FFFFFF"
            d="M348.8,169.3l-5.7,0.8c-6.5,1.1-12.2,2.4-17.4,4.2c-6.5,2.3-12.2,5.7-16.7,10.2c-3.4,2.9-6.5,6.3-8.9,10.1
	c1.3-1.1,2.9-2.1,4.5-3.1l1.8-1c9.6-5.2,23.9-8.9,42.7-11.2c23.4-2.6,46.7-3.9,70.4-3.9h3.2c23.7,0,47.1,1.3,70.4,3.9
	c18.8,2.3,33.1,6,42.7,11.2l1.8,1c1.6,1,3.2,1.9,4.5,3.1c-2.6-3.7-5.5-7.1-9.1-10.1c-4.5-4.4-10.1-7.8-16.7-10.2
	c-5.2-1.8-10.9-3.2-17.4-4.2l-5.7-0.8c-23.4-2.8-46.9-4.2-70.6-4.4h-3.2C395.7,164.9,372.2,166.3,348.8,169.3L348.8,169.3z
	 M383.7,168.8c-0.3-1.1,0.2-1.8,1.1-1.8h71.9c1.1,0,1.5,0.5,1.1,1.8c-0.3,1.6-0.8,3.2-1.5,4.9c-0.3,0.5-1.3,0.8-3.2,0.8h-64.8
	c-1.8,0-2.9-0.2-3.2-0.8C384.7,172,384,170.4,383.7,168.8L383.7,168.8z"
          />
          <path
            fill="#FFFFFF"
            d="M384.8,167c-1.1,0-1.5,0.5-1.1,1.8c0.3,1.6,0.8,3.2,1.5,4.9c0.3,0.5,1.3,0.8,3.2,0.8h-0.8l-0.3-0.2
	c-0.3-0.2-0.6-0.3-0.6-0.6c-0.6-1.6-1.1-3.2-1.5-4.9c-0.3-1.1,0.2-1.8,1-1.8H384.8L384.8,167z"
          />
          <path
            fill="#E6E7E8"
            d="M426.2,169.4c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0.2-0.3,0.2-0.5,0.3-0.6
	c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5
	c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h1.9c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2
	c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h1.3c0.2-0.6,0.3-1.3,0.5-1.9
	c0.3-1.1-0.2-1.8-1-1.8h-69.3c-1,0-1.3,0.6-1,1.8l0.5,1.9h1.3c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.2,0.2,0.3,0.5,0.5,0.6h1.9c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2.1
	c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.3,0.6h1.9c0.2-0.3,0.2-0.5,0.3-0.6
	c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h1.9c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5
	s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0.2-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2
	c0.2-0.3,0.2-0.5,0.3-0.6C425.2,169.6,425.7,169.4,426.2,169.4L426.2,169.4z M419.2,170.7h-2.1c0,0.2,0.2,0.5,0.2,0.6
	c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-2.1
	c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6
	h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
	c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6H396c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3
	c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.3l1,2.9c0.2,0.3,0.3,0.5,0.6,0.6
	l0.3,0.2h66.9c0.3-0.2,0.6-0.3,0.6-0.6c0.3-1,0.6-1.9,1-2.9h-1.3c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-2.1c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-2c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-3.1c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3
	s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6H428c0,0.2,0.2,0.5,0.2,0.6
	c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6
	c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3C419.1,171,419.1,170.9,419.2,170.7L419.2,170.7z"
          />
          <path
            fill="#EBEBEB"
            d="M388.6,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5
	s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C389.5,169.6,389,169.4,388.6,169.4L388.6,169.4z M392.6,169.9c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5
	C393.4,169.4,392.9,169.6,392.6,169.9L392.6,169.9z M401.2,171.2c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5C400.9,172.2,401.2,171.7,401.2,171.2L401.2,171.2z
	 M403.3,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	c-0.3-0.3-0.8-0.5-1.3-0.5S403.7,169.6,403.3,169.9z M414.2,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5
	s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5C415,169.4,414.5,169.6,414.2,169.9L414.2,169.9z
	 M411.3,169.9c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5
	s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3S411.6,170.4,411.3,169.9L411.3,169.9z M420.9,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C421.8,169.6,421.4,169.4,420.9,169.4
	L420.9,169.4z M424.9,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5C425.7,169.4,425.2,169.6,424.9,169.9z M431.6,169.4c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	C432.6,169.6,432.2,169.4,431.6,169.4L431.6,169.4z M438.2,169.9c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3
	C438.9,170.7,438.6,170.4,438.2,169.9L438.2,169.9z M442.3,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C443.3,169.6,442.8,169.4,442.3,169.4L442.3,169.4z
	 M449.6,171.2c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5S449.6,171.7,449.6,171.2L449.6,171.2z M451.7,169.9c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5
	C452.5,169.4,452.2,169.6,451.7,169.9L451.7,169.9z"
          />
          <path
            fill="#FFFFFF"
            d="M457.9,168.8c0.3-1.1-0.2-1.8-1.1-1.8h-1.3c1,0,1.3,0.6,1,1.8c-0.3,1.6-0.8,3.2-1.5,4.9
	c-0.2,0.3-0.3,0.5-0.6,0.6h-0.2l-0.2,0.2h-0.6c1.8,0,2.9-0.2,3.2-0.8C457.1,172,457.5,170.4,457.9,168.8L457.9,168.8z M454.1,174.1
	l-0.2,0.2 M454,174.3h-0.2H454z M387.8,174.3h-0.2H387.8L387.8,174.3z"
          />
          <path
            fill="#646464"
            d="M219.4,242v0.2l0.3,1.9l1.9,4.7l0.6,1.3c3.2,5,9.4,7.8,18.2,8.1h5.4c3.7,0.2,6.2-0.5,7.5-1.9l0.6-1.9
	c-1.8,1.8-4.4,2.6-8.1,2.3H241c-8-0.3-13.3-2.9-16.4-7.5L224,248l-2.6-6.7c-0.5-1.6-0.2-2.8,1-3.2c8.6-4.4,18.3-6.7,29.1-6.8
	c5.2,0.3,7.5,4.2,6.7,11.7l0.5-0.2l0.3-0.2l0.3-0.2l1-0.3l0.2-0.2v-0.3c-0.2-1.9-0.3-3.7-1-5.4c-0.8-3.2-3.4-5-8-5.4
	c-10.7-0.2-20.4,1.6-29.1,5.7C220.3,237.7,219.3,239.5,219.4,242L219.4,242z"
          />
          <path
            fill="#EBEBEB"
            d="M223.7,238.9c-1,0.5-1.3,1.5-0.8,2.9l2.4,6l0.6,1c2.6,4.1,7.6,6.3,14.8,6.7h4.4c3.4,0.2,6-0.3,7.6-1.8
	c1.3-1.5,2.3-3.4,2.9-5.8v-0.5c0-1,0.2-1.6,0.5-2.3l0.2-1.9c0.6-6.8-1.3-10.4-6-10.6C240.2,232.9,231.5,234.8,223.7,238.9
	L223.7,238.9z"
          />
          <path
            fill="#646464"
            d="M222.8,241.6c-0.5-1.5-0.2-2.4,0.8-2.9c7.8-3.9,16.6-6,26.3-6.2c4.7,0.2,6.8,3.7,6,10.6l-0.2,1.9
	c0.5-1,1.3-1.5,2.3-1.9c0.8-7.6-1.5-11.5-6.7-11.7c-10.7,0.2-20.4,2.6-29.1,6.8c-1.1,0.5-1.5,1.6-1,3.2l2.6,6.7l0.6,1.1
	c2.9,4.5,8.4,7,16.4,7.5h4.9c3.7,0.2,6.5-0.5,8.1-2.3c0.5-1.6,1.1-2.6,2.1-3.2l-0.3-0.5l-0.3-0.6l-0.2-1v-1
	c-0.6,2.4-1.5,4.4-2.9,5.8c-1.8,1.3-4.2,1.9-7.6,1.8h-4.4c-7.1-0.3-12-2.6-14.8-6.7l-0.6-1L222.8,241.6L222.8,241.6z"
          />
          <path
            fill="#FFFFFF"
            d="M269.8,241.3l-0.2,0.5c-0.5,1.6-0.6,3.2-0.8,4.9c-1,5.7-1.5,11-1.5,16.1c0,1.6,0,3.2,0.2,4.9l0.3-0.2
	l7.3-15.1c-2.3-1.9-3.9-4.2-4.5-6.7c-0.6-2.6-0.5-5.4,0.6-8.3C270.4,238.7,270.1,240,269.8,241.3L269.8,241.3z"
          />
          <path
            fill="#FFFFFF"
            d="M269.6,241.8l0.2-0.5c-2.3,0.8-4.2,1.8-6.2,3.1l-0.5,0.3l0.5,1C265.4,244.4,267.3,242.9,269.6,241.8
	L269.6,241.8z"
          />
          <path
            fill="#E6E7E8"
            d="M273.5,235.6l-1.1,2.6c-1,2.6-1.8,5-2.1,7.3c0.6,2.4,2.3,4.7,4.5,6.7l2.1,1.6c8.3,4.5,20.6,8.3,37.3,11.4
	l-0.3-0.8c-15.4-2.8-27.1-6.2-35.2-10.2c-4.4-2.4-6.3-7-5.8-13.5l12.5-23c1.3-1.9,3.1-3.1,5.2-3.7l6.2-1.5c1.1-0.3,1.8-0.2,2.1,0.5
	l0.2,0.3v0.2l0.2,0.8c0.3,0.8,0,1.5-0.6,1.6l-9.7,1.9c-1.6,0.5-2.8,1.1-3.1,1.9l-10.9,20.4c1.3,3.6,3.1,6.2,5.4,8
	c2.9,2.4,6,4.1,9.3,5l2.3,0.6c5.4,1.5,11.2,2.6,17.5,3.2l1,2.9l0.8,2.8c0.2,0.5-0.2,0.8-1,0.6c-12.7-2.6-22.6-5.7-29.7-9.1l-0.5-0.2
	c-4.1-2.4-5.8-6.8-5.4-13.1l0.3-0.6l10.9-20.4l0.3-0.6c1-1.9,2.6-3.2,4.7-4.2l8.3-2.1c-0.3-0.5-1-0.6-2.1-0.5l-6.2,1.5
	c-2.1,0.6-3.9,1.9-5.2,3.7l-12.5,23c-0.5,6.5,1.5,10.9,5.8,13.5c8.1,4.1,19.8,7.5,35.2,10.2l-2.6-8.1l-0.2-0.3l-1.1-0.2
	c-7.1-0.6-13.3-1.8-18.7-3.4l-1.8-0.5c-2.3-0.6-4.4-1.5-6.3-2.8c-1.1-0.6-2.1-1.3-3.1-2.3c-2.1-1.6-3.6-3.7-4.7-6.5l-0.2-0.6
	l9.6-18.5c0.6-1.5,1.9-2.4,3.4-2.9l12.7-2.4l-0.2-0.3c-1.1-5.8-2.3-11.9-3.4-18l-1.1-1.1v-3.1l-1,1.8l0.3,1.9l1.1,6.3l0.3,1.5
	l0.3,1.9l0.3,1.8c0.2,1-0.3,1.6-1.1,1.9c-3.4,0.8-6.8,1.8-9.7,2.9l-0.6,0.2l-1.9,0.6l-5.2,9.2C277.4,227.8,275.3,231.7,273.5,235.6
	L273.5,235.6z"
          />
          <path
            fill="#E6E7E8"
            d="M272.5,238.2l1.1-2.6c1.8-3.9,3.7-7.8,6.2-12l5.2-9.3l11-19.3l1-1.8v-3.4l-18.7,32.8
	c-2.3,4.2-4.4,8.1-6.2,12l-1,1.9l-0.2,0.6c-1.1,3.1-1.3,5.8-0.6,8.3C270.7,243.3,271.4,240.8,272.5,238.2L272.5,238.2z"
          />
          <path
            fill="#FFFFFF"
            d="M263.8,239l-0.6,2.3l-1.3,4.4c0.3-0.3,0.8-0.6,1.1-0.8l0.5-0.3c1.8-5.7,4.7-11.4,8.4-17.2l-2.8,2.9
	c-2.3,2.3-5.4,6.2-8.9,11.9v0.2l1.3-0.5C262.1,240.5,262.9,239.7,263.8,239L263.8,239z"
          />
          <path
            fill="#FFFFFF"
            d="M263.1,241.2l0.6-2.3c-0.8,0.6-1.5,1.5-2.1,2.6L263.1,241.2z"
          />
          <path
            fill="#FFFFFF"
            d="M259.2,242.5l-0.3,0.2l-0.2,0.2c-1.1,0.8-1.6,2.1-1.6,3.7v1.3c0,0.5,0.2,0.8,0.2,1.1l0.8-0.3l3.6-1.1v-1.9
	l1.3-4.4l-1.5,0.5l-1.3,0.5H260L259.2,242.5L259.2,242.5z M258.9,242.6l-0.2,0.2"
          />
          <path
            fill="#FFFFFF"
            d="M258.7,242.8l-0.6,0.2c-1,0.5-1.8,1.1-2.3,1.9c-0.3,0.6-0.5,1.5-0.5,2.3v1.5l0.2,1l0.3,0.6l0.5-0.6l0.8-0.6
	l0.2-0.2c-0.2-0.3-0.2-0.6-0.2-1.1v-1.3C257.1,244.9,257.7,243.8,258.7,242.8L258.7,242.8z M260.3,242.1V242l-0.2,0.2 M261.8,247.2
	v1l-0.8,3.1l-0.3,1.9l-0.3,1.1l-0.6,2.9l0.2,0.2l3.7-11.7 M261.8,245.5v1.5l1.8-1.5l-0.5-1C262.6,245.1,262.1,245.2,261.8,245.5z
	 M257.1,249.3l0.3,0.2v-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M257.4,249.4l-0.3-0.2l-0.8,0.6l-0.5,0.6l0.3,0.5l1,1l3.4,2.3l0.2-1.1l-2.4-1.5v-0.5c0.2-0.6,0.5-1,1.1-0.8
	L257.4,249.4L257.4,249.4z"
          />
          <path
            fill="#FFFFFF"
            d="M257.4,249.1v0.3l1.8,1l1.8,0.8l0.8-3.1v-0.5l-3.6,1.1 M259.2,250.4c-0.6-0.2-1,0.2-1.1,0.8v0.5l2.4,1.5
	l0.5-1.9L259.2,250.4L259.2,250.4z M254,254.3l-0.6,1.9l-1.5,4.5l-0.2,0.5l1.5,8.9l1-3.9l2.9-8.8c0.5-1.5,1.1-2.4,1.8-2.9l0.3-0.3
	l-0.3,0.3c-0.6,0.6-1.3,1.6-1.8,2.9l-2.9,8.8c-0.3,1.3-0.6,2.6-1,3.9l-0.5,2.8l-0.2,1.1v1l-0.3,7.3c0.3-1,0.8-1.8,1.1-2.6l0.2-0.6
	c1.5-2.9,2.6-5.8,3.2-9.1l2.4-11l0.3-1.6l0.6-2.9l-3.4-2.3l-1-1C255.3,251.5,254.7,252.7,254,254.3L254,254.3z M259.9,257.4
	l-0.2-0.2l-0.3,1.5"
          />
          <path
            fill="#FFFFFF"
            d="M268.8,246.7c0.2-1.6,0.3-3.2,0.8-4.9c-2.1,1.3-4.2,2.6-6,3.9l-3.7,11.7l-0.5,1.5l-2.4,11l2.3,10.4
	c0.6,3.4,1.5,6.7,2.3,9.7l6-22.1v-0.5c-0.2-1.6-0.2-3.2-0.2-4.9C267.3,257.7,268,252.4,268.8,246.7L268.8,246.7z M266.7,252.4
	c0.5,0,0.6,1.1,0.5,3.6c-0.2,2-0.5,4.2-1,6.7l-1.1,4.5c-0.6,2.3-1.3,4.2-1.9,6c-1,2.4-1.6,3.6-2.1,3.6s-0.6-1.1-0.5-3.6
	c0.2-2.3,0.6-5.2,1.5-8.6c0.8-3.4,1.6-6.3,2.6-8.8C265.4,253.5,266,252.4,266.7,252.4L266.7,252.4z"
          />
          <path
            fill="#FFFFFF"
            d="M267.2,255.9c0.2-2.3,0-3.6-0.5-3.6s-1.3,1.1-2.1,3.6c-1,2.4-1.8,5.4-2.6,8.8c-0.8,3.4-1.3,6.3-1.5,8.6
	c-0.2,2.4,0,3.6,0.5,3.6s1.3-1.1,2.1-3.6c0.6-1.8,1.3-3.7,2-6l-0.3-0.3c-0.5-0.5-0.8-1.1-0.8-1.8s0.3-1.3,0.8-1.8
	c0.3-0.3,0.8-0.6,1.5-0.6C266.7,260,267,257.9,267.2,255.9L267.2,255.9z"
          />
          <path
            fill="#FFFFFF"
            d="M266.2,262.6c-0.5,0.2-1,0.3-1.5,0.6c-0.5,0.5-0.8,1.1-0.8,1.8c0,0.6,0.3,1.3,0.8,1.8l0.3,0.3L266.2,262.6
	L266.2,262.6z"
          />
          <path
            fill="#646464"
            d="M246.1,258h-4.7c0.8,0.2,1.6,0.5,2.4,1l0.6,1l1.6,3.4l2.4-0.5c0.3-0.5,0.8-0.8,1.3-1.1l2.3-1.3l1.5-4.5
	C252.2,257.6,249.8,258.2,246.1,258L246.1,258z"
          />
          <path
            fill="#FFFFFF"
            d="M249.6,261.9c-0.5,0.3-1,0.6-1.3,1.1c-0.6,0.8-1.1,1.8-1.3,2.9v3.4c0,0.5,0.2,0.8,0.3,1.3
	c0.2-0.5,0.5-1,0.8-1.5l-0.2-0.6V266C248.2,264.4,248.7,262.9,249.6,261.9L249.6,261.9z"
          />
          <path
            fill="#646464"
            d="M248.3,263.1l-2.4,0.5l1.3,2.4C247.2,264.9,247.7,263.9,248.3,263.1L248.3,263.1z"
          />
          <path
            fill="#FFFFFF"
            d="M248.7,268.9c0.3-0.3,0.6-0.6,1-0.8l1.9-1.1l-0.5-3.7l0.6-1.9l0.2-0.5l-2.3,1.3c-1,1-1.5,2.4-1.5,4.1v2.6
	l0.2,0.6L248.7,268.9L248.7,268.9z M248.8,264c0.2-0.3,0.3-0.5,0.5-0.5s0.2,0.2,0.3,0.5c0.2,0.3,0.2,0.8,0.2,1.3l-0.2,1.3
	c-0.2,0.3-0.2,0.5-0.3,0.5c-0.2,0-0.3-0.2-0.3-0.5v-1.3L248.8,264L248.8,264z"
          />
          <path
            fill="#FFFFFF"
            d="M249.3,263.6c-0.2,0-0.3,0.2-0.5,0.5l-0.2,1.3v1.3c0.2,0.3,0.2,0.5,0.3,0.5s0.3-0.2,0.3-0.5l0.2-1.3
	c0-0.5,0-1-0.2-1.3C249.5,263.7,249.5,263.6,249.3,263.6L249.3,263.6z M249.6,268.1c-0.3,0.2-0.6,0.5-1,0.8l1.9,1l1.6,0.6l-0.6-3.6
	L249.6,268.1L249.6,268.1z"
          />
          <path
            fill="#FFFFFF"
            d="M248.7,268.9l-0.3,0.3c-0.3,0.5-0.6,1-0.8,1.5c0.5,0.8,1.5,1.5,2.8,1.9l2.3,1.1v-1.3l-1.9-1v-1.8
	L248.7,268.9L248.7,268.9z"
          />
          <path
            fill="#FFFFFF"
            d="M250.6,269.9v1.8l1.9,1c0-0.6-0.2-1.5-0.3-2.1L250.6,269.9L250.6,269.9z M250.4,273.3v0.5
	c0,4.4,0.2,8.8,0.3,13l0.2-1c0.3-1.3,0.6-2.4,1.1-3.6l0.3-7.3v-1.1l-2.3-1.1L250.4,273.3L250.4,273.3z M267.3,267.6v0.3l0.3-0.5"
          />
          <path
            fill="#FFFFFF"
            d="M267.3,267.8l-0.3,0.6c0.3,6,1.3,11.4,2.8,16.2c5.5,13.3,25.8,21.4,61,24c29.4,1,58.8,1.5,88.1,1.5h3.2
	c29.4,0,58.8-0.5,88.1-1.5c35.2-2.8,55.5-10.7,61-24c1.6-4.9,2.6-10.2,2.9-16.2l-7.6-15.7l-2.1,1.6c-8.3,4.5-20.6,8.3-37.3,11.4
	l-3.4,10.1c-1.5,4.1-3.2,7.8-5,11.4c-2.1,4.5-5,8.3-8.8,10.9c-4.9,3.2-13.8,5-27.1,5.2c-20.1,0.5-40.3,0.8-60.4,0.8h-3.2
	c-20.1,0-40.3-0.3-60.4-0.8c-13.3-0.3-22.4-1.9-27.1-5.2c-3.9-2.6-6.8-6.2-8.8-10.9c-1.8-3.6-3.6-7.5-5-11.4
	c-1.1-3.2-2.4-6.7-3.4-10.1c-16.7-3.1-29.2-6.8-37.3-11.4l-2.1-1.6L267.3,267.8L267.3,267.8z M251.7,261.1l-0.6,1.9l0.5,3.7l0.6,3.6
	c0.2,0.6,0.2,1.5,0.3,2.1l0.3,0.2l0.5-2.8L251.7,261.1L251.7,261.1z M252.7,272.8l-0.3-0.2l0.2,1.1v0.2 M252.6,274.6v-0.8
	 M253.5,278.8l-0.2,0.6c-0.5,0.8-0.8,1.6-1.1,2.6c1.1,5.4,2.3,10.4,3.7,15.3c1.9-1.8,3.7-4.2,5.5-7.3c-0.8-3.2-1.5-6.5-2.3-9.7
	l-2.3-10.4C256.1,273,255,276.1,253.5,278.8L253.5,278.8z"
          />
          <path
            fill="#FFFFFF"
            d="M252.2,282.1c-0.5,1.1-0.8,2.4-1.1,3.6l-0.2,1l-2.3,11.7c-0.3,1.3-0.5,2.4-0.6,3.7l1.3,6l1.3,6l5.5-16.7
	C254.5,292.4,253.2,287.4,252.2,282.1L252.2,282.1z"
          />
          <path
            fill="#FFFFFF"
            d="M269.9,284.5c-1.6-4.9-2.6-10.2-2.8-16.2l-6,22.1c1.6,6.2,3.4,12,5.5,17.5c1.8,4.7,5.8,7.8,12.3,8.9
	c9.1,1,18.7,1.3,28.9,0.8l9.6-0.2c0.2,1.6-3.4,2.9-10.9,3.7l-30.4,2.3c-7.6-0.3-12.7-3.4-14.9-9.1c-2.1-5.2-4.1-10.7-5.8-16.6
	l-5.5,16.7l2.4,11.4l9.1,42.8c0.3,1.5,0.8,2.8,1.5,3.9c12.2-3.4,24.7-5.8,37.8-7l0.2-0.3c10.1-16.9,28.2-25.5,54.4-25.8
	c21.1-0.6,42.2-1.1,63.5-1h3.2c21.3,0,42.4,0.3,63.5,1c26.1,0.5,44.3,9.1,54.4,25.8l0.2,0.3c13.1,1.1,25.6,3.6,37.8,7
	c0.6-1.1,1.1-2.4,1.5-3.9l8.9-42.8l2.4-11.4l-5.5-16.7c-1.6,5.8-3.6,11.4-5.8,16.6c-2.3,5.7-7.1,8.8-14.9,9.1l-30.4-2.3
	c-7.5-0.8-11-1.9-10.9-3.7l9.6,0.2c10.2,0.3,20,0,28.9-0.8c6.5-1.1,10.7-4.1,12.3-8.9c2.1-5.5,3.9-11.4,5.5-17.5l-6-22.1
	c-0.3,6-1.3,11.4-2.9,16.2c-5.5,13.3-25.8,21.4-61,24c-29.4,1-58.8,1.5-88.1,1.5h-3.2c-29.4,0-58.8-0.5-88.1-1.5
	C295.7,305.9,275.3,297.8,269.9,284.5L269.9,284.5z M574.1,338.4c0.2,9.6-4.1,13.6-13,12c-9.6-1.3-16.7-5.7-21.6-13.1
	c-1.1-2.1-0.2-2.9,2.9-2.8C554.4,335,565,336.3,574.1,338.4z M298.8,334.5c3.1-0.3,4.1,0.6,2.9,2.8c-4.9,7.5-12,11.8-21.6,13.1
	c-8.9,1.6-13.3-2.4-13-12C276.2,336.3,286.8,335,298.8,334.5L298.8,334.5z"
          />
          <path
            fill="#E6E7E8"
            d="M561.4,350.1c8.9,1.6,13.3-2.4,13-12c-9.1-2.1-19.6-3.4-31.6-3.9c-3.1-0.3-4.1,0.6-2.9,2.8
	C544.7,344.4,552,348.8,561.4,350.1L561.4,350.1z M301.9,336.9c1.1-2.1,0.2-2.9-2.9-2.8c-12,0.3-22.6,1.6-31.6,3.9
	c-0.2,9.6,4.1,13.6,13,12C289.9,348.8,297,344.4,301.9,336.9L301.9,336.9z"
          />
          <path
            fill="#FFFFFF"
            d="M270.7,226.2l-1.5,3.9l2.8-2.9l3.4-3.6l2.3-2.9c1-1.3,1.8-2.8,2.6-4.2l1.3-2.3l-2.4,2.9L270.7,226.2
	L270.7,226.2z M352.5,229c-10.9-0.2-19.6-1.5-26.6-4.1l8.6,15.9h173l8.6-15.9c-7,2.4-15.7,3.7-26.6,4.1c-22.2,1-44.5,1.5-66.9,1.5
	h-3.2C397,230.3,374.8,230,352.5,229L352.5,229z"
          />
          <path
            fill="#646464"
            d="M325.9,224.9c7,2.4,15.9,3.7,26.6,4.1c22.2,1,44.5,1.5,66.9,1.5h3.2c22.2,0,44.6-0.5,66.9-1.5
	c10.9-0.2,19.6-1.5,26.6-4.1c3.4-1.1,6.5-2.6,8.9-4.4c2.9-2.4,5.4-6,7.3-10.4l0.2-0.2c1.9-6.3,3.1-12.8,3.2-19.6
	c-9.6-5.2-23.9-8.9-42.7-11.2c-23.4-2.6-46.7-3.9-70.4-3.9h-3.2c-23.7,0-47.1,1.3-70.4,3.9c-18.8,2.3-33.1,6-42.7,11.2
	c0.2,6.8,1.3,13.3,3.2,19.6l0.2,0.2c1.9,4.4,4.5,8,7.5,10.4C319.4,222.3,322.3,223.8,325.9,224.9L325.9,224.9z M359.2,220.9
	c-26-1.1-38.8-7.6-38.3-19.8c1-11.2,13.6-18,37.8-20.6c20.1-1.9,40.3-2.9,60.5-2.9h3.2c20.3,0,40.6,1,60.5,2.9
	c24.2,2.6,36.8,9.6,37.8,20.6c0.5,12-12.3,18.7-38.3,19.8c-20,1-40.1,1.6-60.1,1.8h-3.2C399.3,222.5,379.3,222,359.2,220.9
	L359.2,220.9z"
          />
          <path
            fill="#EBEBEB"
            d="M320.9,201.2c-0.5,12,12.3,18.7,38.3,19.8c20.1,1,40.1,1.6,60.1,1.8h3.2c20-0.2,40.1-0.6,60.1-1.8
	c26-1.1,38.8-7.6,38.3-19.8c-1-11.2-13.6-18-37.8-20.6c-20.1-1.9-40.3-2.9-60.5-2.9h-3.2c-20.3,0-40.6,1-60.5,2.9
	C334.5,183.2,321.9,190,320.9,201.2L320.9,201.2z"
          />
          <path
            fill="#FFFFFF"
            d="M304.5,191.3c-1.6,1-3.2,1.9-4.5,3.1l-1.9,2.9c1,6.2,2.1,12.2,3.4,18l0.2,0.3c1.8,8.4,3.7,16.7,5.8,24.8
	h27.1l-8.6-15.9c-3.4-1.1-6.5-2.6-8.9-4.4c-2.9-2.4-5.4-6-7.5-10.4l-0.2-0.2C307.2,205,305.6,198.8,304.5,191.3L304.5,191.3z"
          />
          <path
            fill="#FFFFFF"
            d="M306.3,190.4l-1.8,1c1.1,7.5,2.8,13.6,4.9,18.5C307.6,203.7,306.4,197.2,306.3,190.4L306.3,190.4z"
          />
          <path
            fill="#E6E7E8"
            d="M296.2,197l-0.3-1.9l-11,19.3l1.9-0.6l1.6-2.9l-0.2-0.3c0-0.3,0.2-0.6,0.3-1c0-0.2,0.3-0.3,1-0.6l1.1-1.9
	c-0.3,0.2-0.5,0-0.6-0.3c0.2-0.6,0.6-1.3,1.5-1.8l0.3-0.2L296.2,197L296.2,197z"
          />
          <path
            fill="#E6E7E8"
            d="M292.5,204.3c1.3-0.5,2.9-0.8,4.9-1.1l-1.1-6.3l-4.2,7.6L292.5,204.3L292.5,204.3z"
          />
          <path
            fill="#E6E7E8"
            d="M297.3,203.3c-1.9,0.3-3.6,0.6-4.9,1.1l-0.6,0.3l-0.3,0.2c-1,0.5-1.5,1-1.5,1.8c0.2,0.3,0.3,0.5,0.6,0.3
	c0.2,0,0.2-0.2,0.3-0.2l0.3-0.2c1.6-0.8,3.7-1.3,6.3-1.6L297.3,203.3L297.3,203.3z"
          />
          <path
            fill="#E6E7E8"
            d="M291,206.6c-0.2,0.2-0.2,0.2-0.3,0.2l-1.1,1.9l0.6-0.3c1-0.5,3.6-1,7.8-1.8l-0.3-1.9
	c-2.6,0.3-4.7,0.8-6.3,1.6L291,206.6L291,206.6z"
          />
          <path
            fill="#E6E7E8"
            d="M290.2,208.5l-0.6,0.3c-0.6,0.3-1,0.5-1,0.6c-0.2,0.3-0.3,0.6-0.3,1l0.2,0.3l0.3,0.2h0.2
	c2.8-1.3,5.8-1.9,9.2-2.3l-0.3-1.8C293.8,207.6,291.2,208,290.2,208.5L290.2,208.5z"
          />
          <path
            fill="#E6E7E8"
            d="M288.9,210.8l-0.3-0.2l-1.6,2.9l0.3-0.2c3.1-1.1,6.3-2.1,9.7-2.9c1-0.3,1.3-1,1.1-1.9
	C294.7,208.7,291.7,209.5,288.9,210.8L288.9,210.8z M285.7,219.6c0.5-0.8,1.5-1.5,3.1-1.9l9.7-1.9c0.8-0.3,1-0.8,0.6-1.6l-0.2-0.8
	v-0.2l-0.2-0.3l-8.3,2.1c-2.1,0.8-3.7,2.3-4.7,4.2L285.7,219.6L285.7,219.6z"
          />
          <path
            fill="#EBEBEB"
            d="M288.9,218.3c-1.6,0.6-2.8,1.6-3.4,2.9l-9.6,18.5l0.2,0.6h30.2c1.5,5.4,2.9,10.6,4.2,15.3l1.1,0.2
	c-1.5-4.9-2.9-9.9-4.2-15.1c-2.1-8.1-4.1-16.4-5.8-24.8L288.9,218.3L288.9,218.3z M286.1,221.7c0.5-0.8,1.6-1.5,3.2-1.9l8.6-1.6
	c1.3-0.2,2.1,0.6,2.8,2.3l2.4,10.7c1.3,4.7-1.5,7.1-8.3,7.1h-13.8c-2.1,0-2.6-1-1.8-2.9L286.1,221.7L286.1,221.7z"
          />
          <path
            fill="#D1D3D4"
            d="M289.4,219.7c-1.6,0.3-2.8,1-3.2,1.9l-7,13.5c-0.8,1.9-0.2,2.9,1.8,2.9h13.8c6.8,0,9.6-2.3,8.3-7l-2.4-10.7
	c-0.6-1.6-1.5-2.4-2.8-2.3L289.4,219.7L289.4,219.7z M286.3,222.5c0.5-0.8,1.5-1.3,2.9-1.6l7.8-1.5c1.1-0.2,2,0.5,2.4,1.9l2.1,9.4
	c1.1,4.1-0.8,6.2-5.7,6.2h-14.1c-1.8,0-2.4-0.8-1.6-2.6L286.3,222.5L286.3,222.5z"
          />
          <path
            fill="#D1D3D4"
            d="M289.2,220.9c-1.5,0.3-2.4,1-2.9,1.6l-6.2,11.9c-0.6,1.8-0.2,2.6,1.6,2.6h14.1c5,0,6.8-2.1,5.7-6.2l-2.1-9.4
	c-0.5-1.5-1.3-2.1-2.4-1.9L289.2,220.9L289.2,220.9z M286.8,223c0.5-0.6,1.3-1.1,2.6-1.5l7-1.3c1-0.2,1.8,0.5,2.3,1.8l2,8.4
	c1,3.7-0.6,5.5-5.2,5.7h-12.7c-1.6,0-2.1-0.8-1.5-2.4L286.8,223L286.8,223z"
          />
          <path
            fill="#D1D3D4"
            d="M289.4,221.5c-1.3,0.3-2.3,0.8-2.6,1.5l-5.7,10.7c-0.6,1.6-0.2,2.4,1.5,2.4h12.7c4.5,0,6.2-1.9,5.2-5.7
	l-1.9-8.4c-0.5-1.3-1.3-1.9-2.3-1.8L289.4,221.5L289.4,221.5z M287.1,224c0.3-0.5,1.1-1,2.3-1.1l6-1.1c0.8-0.2,1.5,0.3,1.8,1.5
	l1.6,7c1,3.1-0.6,4.5-4.4,4.5h-10.7c-1.5,0-1.8-0.6-1.3-1.9L287.1,224L287.1,224z"
          />
          <path
            fill="#D1D3D4"
            d="M289.2,222.8c-1.1,0.3-1.8,0.6-2.3,1.1l-4.9,8.8c-0.5,1.3-0.2,1.9,1.3,1.9h10.7c3.7,0,5.4-1.6,4.4-4.5
	l-1.6-7c-0.3-1.1-1-1.6-1.8-1.5L289.2,222.8L289.2,222.8z M287.6,224.6c0.3-0.5,1-0.8,1.9-1.1l5-1c0.6-0.2,1.3,0.3,1.6,1.3l1.5,6
	c0.8,2.6-0.5,3.9-3.7,3.9h-9.2c-1.3,0-1.6-0.5-1.1-1.6L287.6,224.6L287.6,224.6z"
          />
          <path
            fill="#D1D3D4"
            d="M289.6,223.6c-1,0.2-1.6,0.6-1.9,1.1l-4.1,7.6c-0.5,1.1-0.2,1.6,1.1,1.6h9.2c3.2,0,4.5-1.3,3.7-3.9l-1.5-6
	c-0.3-1-1-1.5-1.6-1.3L289.6,223.6L289.6,223.6z"
          />
          <path
            fill="#FFFFFF"
            d="M311.1,256.1l0.2,0.3c11,1.6,22.1,2.8,33.1,3.6l-0.8-1.5l-3.6-6.7l-6-11h-27.1
	C308.2,246.2,309.7,251.2,311.1,256.1L311.1,256.1z"
          />
          <path
            fill="#EBEBEB"
            d="M310.3,255.8c-1.5-4.9-2.9-9.9-4.2-15.3h-14.4v12C297,254,303.2,255,310.3,255.8L310.3,255.8z M300.9,245.5
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3s0.8-0.5,1.3-0.5
	C300.1,244.9,300.4,245.2,300.9,245.5L300.9,245.5z"
          />
          <path
            fill="#EBEBEB"
            d="M301.4,246.8c0-0.5-0.2-1-0.5-1.3s-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5C301.2,247.6,301.4,247.3,301.4,246.8L301.4,246.8z M299.6,245.7c0.3,0,0.6,0.2,0.8,0.3
	c0.2,0.3,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3c-0.3-0.2-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8
	C299,245.7,299.3,245.7,299.6,245.7z"
          />
          <path
            fill="#EBEBEB"
            d="M300.4,246c-0.3-0.2-0.5-0.3-0.8-0.3s-0.6,0.2-0.8,0.3c-0.3,0.3-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8
	c0.2,0.3,0.5,0.3,0.8,0.3c0.3,0,0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8S300.6,246.2,300.4,246L300.4,246z M277.9,240.5h-1.9
	c1.1,2.8,2.6,4.9,4.7,6.5c1,0.8,1.9,1.6,3.1,2.3C281.4,246.5,279.5,243.6,277.9,240.5L277.9,240.5z"
          />
          <path
            fill="#E6E7E8"
            d="M274.8,239.9l-0.3,0.6c1.6,4.1,3.4,7,5.7,8.9c2.9,2.4,6,4.2,9.3,5.4l2.3,0.8c5.4,1.6,11.5,3.1,18.5,4.1
	l-1-2.9c-6.3-0.6-12.2-1.6-17.5-3.2l-2.3-0.6c-3.4-1-6.5-2.6-9.3-5C277.9,246,276.1,243.4,274.8,239.9z"
          />
          <path
            fill="#E6E7E8"
            d="M279.8,253.7l0.5,0.2c7.1,3.4,17.2,6.5,29.7,9.1c1,0.2,1.3-0.2,1-0.6l-0.8-2.8c-7-1-13.1-2.4-18.5-4.1
	l-2.3-0.8c-3.4-1.1-6.5-2.9-9.2-5.4c-2.1-1.8-4.1-4.9-5.7-8.9C274,246.8,275.8,251.2,279.8,253.7z"
          />
          <path
            fill="#EBEBEB"
            d="M290,251.9l1.8,0.5v-12H278c1.6,3.1,3.6,6,5.8,8.8C285.7,250.4,287.8,251.2,290,251.9L290,251.9z
	 M284.2,243.8c0.3,0,0.6,0.2,0.8,0.3s0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3
	c-0.2-0.2-0.3-0.5-0.3-0.8s0.2-0.6,0.3-0.8C283.6,243.9,283.9,243.8,284.2,243.8z"
          />
          <path
            fill="#EBEBEB"
            d="M285,244.1c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8
	c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8S285.2,244.4,285,244.1z"
          />
          <path
            fill="#FFFFFF"
            d="M313.9,264.7l0.3,0.8c1.1,3.4,2.3,6.7,3.4,10.1c1.5,4.1,3.2,7.8,5,11.4c2.1,4.5,5,8.3,8.8,10.9
	c4.9,3.2,13.8,5,27.1,5.2c20.1,0.5,40.3,0.8,60.4,0.8h3.2c20.1,0,40.3-0.3,60.4-0.8c13.3-0.3,22.4-1.9,27.1-5.2
	c3.9-2.6,6.8-6.2,8.8-10.9c1.8-3.6,3.6-7.5,5-11.4l3.4-10.1l0.3-0.8h-0.3h0.3l2.6-8.1c-11,1.6-22.1,2.8-33.1,3.6l-11.2,20.6
	c-4.1,7.3-10.7,11-20.1,11.4h-89.8c-9.4-0.3-16.1-4.1-20.1-11.4l-11.2-20.6c-11-0.8-22.1-1.9-33.1-3.6L313.9,264.7l0.3,0
	L313.9,264.7L313.9,264.7z M334.5,240.8l5.8,10.9h161l6-10.9"
          />
          <path
            fill="#FFFFFF"
            d="M340,252l3.7,6.7h153.7l3.6-6.7 M348,260.1l10.5,19.5c3.6,6.7,9.6,10.1,18.2,10.2h87.8
	c8.4-0.2,14.4-3.6,18.2-10.2l10.5-19.5H348L348,260.1z"
          />
          <path
            fill="#FFFFFF"
            d="M358.5,279.6L348,260.1h-3.6l11.2,20.6c4.1,7.3,10.7,11,20.1,11.4h89.8c9.4-0.3,16.1-4.1,20.1-11.4
	l11.2-20.6h-3.6l-10.6,19.5c-3.6,6.7-9.6,10.1-18,10.2h-87.8C368.3,289.5,362.3,286.1,358.5,279.6L358.5,279.6z M344.4,260.1h152.4
	l0.6-1.5H343.8 M521.8,154c0.3-2.8,1-4.4,2.3-4.5l-6.2-1c-1.3,0.3-1.9,1.9-2.3,4.7l-0.2,8.4l6.2,1.3v-1.3L521.8,154z"
          />
          <path
            fill="#FFFFFF"
            d="M524.1,149.5c-1.1,0.3-1.9,1.8-2.3,4.5l-0.2,7.5v1.3c8,5,15.3,13.1,21.9,24.2l1.5,2.8l18.7,32.8
	c2.3,4.2,4.4,8.1,6.2,12l1.1,2.6c0.5,1.3,1,2.8,1.3,4.1c2.3,0.8,4.2,1.8,6.2,3.1c-1.8-5.7-4.7-11.4-8.4-17.2l-3.4-3.6
	c-0.8-1-1.5-1.9-2.3-2.9c-1-1.3-1.8-2.8-2.6-4.2l-1.3-2.3l-14.3-25.5l-1.6-2.9c-3.2-6-5.2-11.5-5.7-16.7c-0.6-7.6-0.2-11,1.1-10.6
	c-3.2-3.9-8.1-6.8-14.4-8.4L524.1,149.5L524.1,149.5z M515.5,161.6l-0.3,1.3l6,1.3v-0.2c0.3-0.3,0.3-0.6,0.5-1.3L515.5,161.6z"
          />
          <path
            fill="#FFFFFF"
            d="M547,171.2l-3.4-6.8c-1-0.5-1.5-0.2-1.8,1c-0.2,1.5-0.3,2.9-0.3,4.4l0.8,1.5c6.5,13.1,12.8,26.9,18.7,41.2
	l1.8,4.5l8.4,9.4l-5.5-13.8C559.6,198.1,553.5,184.4,547,171.2z"
          />
          <path
            fill="#EBEBEB"
            d="M542.3,171.2l-0.8-1.5v1.8c0.2,4.4,1.6,10.1,4.5,17l14.3,25.5l2.4,2.9l-1.8-4.5
	C555.1,198.1,548.8,184.3,542.3,171.2L542.3,171.2z"
          />
          <path
            fill="#FFFFFF"
            d="M541.5,171.5v-1.8c0-1.5,0.2-2.9,0.3-4.4c0.2-1.1,0.8-1.5,1.8-1l-0.6-1.1c-0.8-1.8-1.8-3.4-3.1-5
	c-1.5-0.5-1.8,3.1-1.1,10.6c0.5,5.2,2.4,10.9,5.7,16.7l1.6,2.9C543.1,181.8,541.6,176.1,541.5,171.5L541.5,171.5z M419.9,113.3
	l-0.2,0.6c-0.2,0.3-0.2,0.7-0.2,1.1l0.2,1.6v14c0,1.8-0.3,5-0.8,9.4l-0.3,2.3l-0.3,2.9l-0.2,1.5v0.2c0,0.3,0.3,0.6,0.8,0.8l0.3,0.2
	l1.5,0.2h0.3l1.5-0.2l0.3-0.2c0.6-0.2,0.8-0.5,0.8-0.8v-1.6l-0.3-2.9L423,140c-0.5-4.5-0.8-7.6-0.8-9.4v-14c0.2-0.7,0.2-1.1,0.2-1.6
	l-0.2-1.1l-0.3-0.7c-0.3-0.5-0.6-0.6-1-0.6h-0.2C420.5,112.6,420.2,112.8,419.9,113.3L419.9,113.3z"
          />
          <path
            fill="#FFFFFF"
            d="M414.9,147.8v2.1c0.3,0.6,1,1,2.1,1.1h7.6c1.1-0.2,1.9-0.6,2.1-1.1v-2.1c-0.3,0.6-1,1-2.1,1.1h-7.5
	C415.8,148.8,415.2,148.5,414.9,147.8z M418.6,142.3h-0.2l-0.3,2.9h0.2"
          />
          <path
            fill="#FFFFFF"
            d="M425.4,146l-1.8-0.8l0.2,1.5v0.2c0,0.3-0.3,0.6-0.8,0.8l-0.3,0.2l-1.5,0.2h-0.3l-1.5-0.2l-0.3-0.2
	c-0.6-0.2-0.8-0.5-0.8-0.8v-0.2l0.2-1.5l-0.2,0.2l-1.6,0.8c-1,0.5-1.5,1.1-1.6,1.9c0.3,0.6,1,1,2.1,1.1h7.6c1.1-0.2,1.9-0.6,2.1-1.1
	C426.9,147,426.4,146.5,425.4,146L425.4,146z M537.2,191.3l-1.8-1c-0.2,6.8-1.3,13.3-3.2,19.6C534.5,205,536.3,198.8,537.2,191.3
	L537.2,191.3z"
          />
          <path
            fill="#FFFFFF"
            d="M543.7,197.5l-1.9-2.9c-1.3-1.1-2.9-2.1-4.5-3.1c-1.1,7.5-2.8,13.6-4.9,18.5l-0.2,0.2
	c-1.9,4.4-4.4,8-7.3,10.4c-2.6,1.8-5.5,3.2-8.9,4.4l-8.6,15.9h27.1c2.1-8.1,4.1-16.4,5.8-24.8l0.2-0.3
	C541.6,209.7,542.8,203.7,543.7,197.5L543.7,197.5z"
          />
          <path
            fill="#D1D3D4"
            d="M545.7,197l0.3-1.9l-1-1.8v3.1l-1.3,1.1c-1,6.2-2.1,12.2-3.4,18l-0.2,0.3l12.7,2.4c1.6,0.6,2.8,1.6,3.6,2.9
	l9.6,18.5l-0.2,0.6c-1.1,2.8-2.6,4.9-4.7,6.5l-3.1,2.3c-1.9,1.3-4.1,2.1-6.3,2.8l-1.8,0.5c-5.4,1.5-11.5,2.6-18.7,3.4l-1.1,0.2
	l-0.2,0.3l-2.6,8.1c15.4-2.8,27.1-6.2,35.2-10.2c4.4-2.4,6.3-7,5.8-13.5l-12.5-23c-1.3-1.9-3.1-3.1-5.2-3.7l-6.2-1.5
	c-1.1-0.3-1.8-0.2-2.1,0.5l8.3,2.1c2.1,0.8,3.7,2.3,4.7,4.2l0.3,0.6l10.9,20.4l0.3,0.6c0.5,6.3-1.3,10.7-5.4,13.1l-0.3,0.2
	c-7.3,3.4-17.2,6.5-29.9,9.1c-1,0.2-1.3-0.2-1-0.6l0.8-2.8l1-2.9c6.3-0.6,12.2-1.6,17.5-3.2l2.3-0.6c3.4-1,6.5-2.6,9.3-5
	c2.1-1.8,3.9-4.4,5.4-8l-10.9-20.4c-0.5-0.8-1.5-1.5-3.1-1.9l-9.7-1.9c-0.8-0.3-1-0.8-0.8-1.6l0.2-0.8v-0.2l0.2-0.3
	c0.3-0.5,1-0.6,2.1-0.5l6.2,1.5c2.1,0.6,3.9,1.9,5.2,3.7l12.5,23c0.5,6.5-1.5,10.9-5.8,13.5c-8.1,4.1-19.8,7.5-35.2,10.2l-0.3,0.8
	c16.7-3.1,29.2-6.8,37.3-11.4l2.1-1.6c2.3-1.9,3.9-4.2,4.5-6.7c-0.3-2.3-1-4.9-2.1-7.3l-1.1-2.6c-1.8-3.9-3.7-7.8-6.2-12l-5.2-9.3
	l-1.9-0.6l-0.6-0.2c-3.1-1.1-6.3-2.1-9.7-2.9c-1-0.3-1.3-1-1.1-1.9l0.3-1.8l0.3-1.9l0.3-1.5L545.7,197L545.7,197z"
          />
          <path
            fill="#E6E7E8"
            d="M546,195.1l-0.3,1.9l4.2,7.6l0.3,0.2c1,0.5,1.5,1,1.5,1.8c-0.2,0.3-0.3,0.5-0.6,0.3l1.1,1.9l1,0.6
	c0.2,0.3,0.3,0.6,0.3,1l-0.2,0.3l1.6,2.9l2,0.6L546,195.1L546,195.1z"
          />
          <path
            fill="#D1D3D4"
            d="M545,193.3l1,1.8l11,19.3l5.2,9.2c2.3,4.2,4.4,8.1,6.2,12l1.1,2.6c1,2.6,1.8,5,2.1,7.3
	c0.7-2.6,0.5-5.4-0.7-8.3l-1.1-2.6c-1.8-3.9-3.7-7.8-6.2-12L545,189.9L545,193.3L545,193.3z"
          />
          <path
            fill="#D1D3D4"
            d="M551.2,206.7c0.3,0.2,0.5,0,0.6-0.3c-0.2-0.6-0.6-1.3-1.5-1.8l-0.3-0.2l-0.6-0.3c-1.3-0.5-2.9-0.8-4.9-1.1
	l-0.3,1.5c2.6,0.3,4.7,0.8,6.3,1.6L551.2,206.7L551.2,206.7z"
          />
          <path
            fill="#D1D3D4"
            d="M549.4,204.3l0.6,0.3l-4.2-7.6l-1.3,6.3C546.3,203.5,548,203.8,549.4,204.3L549.4,204.3z M552.8,210.8h0.2
	l0.5-0.2l0.2-0.3c0-0.3-0.2-0.6-0.3-1l-1-0.6l-0.7-0.3c-1-0.5-3.7-1-8-1.8l-0.3,1.8C547,208.7,550.1,209.5,552.8,210.8L552.8,210.8z
	"
          />
          <path
            fill="#D1D3D4"
            d="M553,210.8h-0.2c-2.8-1.3-5.8-1.9-9.3-2.3c-0.2,1,0.3,1.6,1.1,1.9c3.4,0.8,6.8,1.8,9.7,2.9l0.6,0.2l-1.6-2.9
	L553,210.8L553,210.8z M551.7,208.5l0.7,0.3l-1.1-1.9l-0.6-0.3c-1.6-0.8-3.7-1.3-6.3-1.6l-0.3,1.9
	C548.1,207.6,550.7,208,551.7,208.5L551.7,208.5z"
          />
          <path
            fill="#E6E7E8"
            d="M542.6,213.2l-0.2,0.8c-0.3,0.8,0,1.5,0.8,1.6l9.7,1.9c1.6,0.5,2.8,1.1,3.1,1.9l-0.3-0.6
	c-1-1.9-2.6-3.2-4.7-4.2l-8.3-2.1L542.6,213.2L542.6,213.2z"
          />
          <path
            fill="#EBEBEB"
            d="M556.4,221.4c-0.6-1.5-1.9-2.4-3.6-2.9l-12.7-2.4c-1.8,8.4-3.7,16.7-5.8,24.8c-1.3,5-2.8,10.1-4.2,15.1
	l1.1-0.2c1.5-4.9,2.9-9.9,4.4-15.3h29.9l0.2-0.6L556.4,221.4L556.4,221.4z M541.1,220.4c0.6-1.6,1.5-2.4,2.8-2.3l8.6,1.6
	c1.6,0.3,2.8,1,3.2,1.9l7,13.5c0.8,1.9,0.2,2.9-1.8,2.9h-14c-6.8,0-9.6-2.3-8.3-7L541.1,220.4L541.1,220.4z"
          />
          <path
            fill="#D1D3D4"
            d="M543.9,218.1c-1.3-0.2-2.1,0.6-2.8,2.3l-2.4,10.7c-1.3,4.7,1.5,7.1,8.3,7.1h13.8c2.1,0,2.6-1,1.8-2.9
	l-7-13.5c-0.5-0.8-1.6-1.5-3.2-1.9L543.9,218.1z M552.7,220.9c1.5,0.3,2.4,1,2.9,1.6l6.2,11.9c0.6,1.8,0.2,2.6-1.6,2.6H546
	c-5,0-6.8-2.1-5.7-6.2l2.1-9.4c0.5-1.5,1.3-2.1,2.4-1.9L552.7,220.9L552.7,220.9z"
          />
          <path
            fill="#D1D3D4"
            d="M555.6,222.5c-0.5-0.8-1.5-1.3-2.9-1.6l-7.8-1.5c-1.1-0.2-1.9,0.5-2.4,1.9l-2.1,9.4
	c-1.1,4.1,0.8,6.2,5.7,6.2h14.1c1.8,0,2.4-0.8,1.6-2.6L555.6,222.5L555.6,222.5z M545.4,220.2l7,1.3c1.3,0.3,2.3,0.8,2.6,1.5
	l5.7,10.7c0.7,1.6,0.2,2.4-1.5,2.4h-12.7c-4.5,0-6.2-1.9-5.2-5.7l1.9-8.4C543.7,220.7,544.4,220.1,545.4,220.2L545.4,220.2z"
          />
          <path
            fill="#D1D3D4"
            d="M552.3,221.5l-7-1.3c-1-0.2-1.8,0.5-2.3,1.8l-1.9,8.4c-1,3.7,0.6,5.5,5.2,5.7H559c1.6,0,2.1-0.8,1.5-2.4
	l-5.7-10.7C554.6,222.3,553.6,221.8,552.3,221.5L552.3,221.5z M552.5,222.8c1.1,0.3,1.8,0.6,2.3,1.1l4.9,8.8
	c0.5,1.3,0.2,1.9-1.3,1.9h-10.7c-3.7,0-5.4-1.6-4.4-4.5l1.6-7c0.3-1.1,1-1.6,1.9-1.5L552.5,222.8z"
          />
          <path
            fill="#D1D3D4"
            d="M554.8,224c-0.3-0.5-1.1-1-2.3-1.1l-6-1.1c-0.8-0.2-1.5,0.3-1.9,1.5l-1.6,7c-1,3.1,0.6,4.5,4.4,4.5H558
	c1.5,0,1.8-0.6,1.3-1.9L554.8,224L554.8,224z M552.3,223.6c1,0.2,1.6,0.6,1.9,1.1l4.1,7.6c0.5,1.1,0.2,1.6-1.1,1.6H548
	c-3.2,0-4.5-1.3-3.7-3.9l1.3-6c0.3-1,1-1.5,1.6-1.3L552.3,223.6L552.3,223.6z"
          />
          <path
            fill="#D1D3D4"
            d="M554.3,224.6c-0.3-0.5-1-0.8-1.9-1.1l-5-1c-0.6-0.2-1.3,0.3-1.6,1.3l-1.3,6c-0.8,2.6,0.5,3.9,3.7,3.9h9.3
	c1.3,0,1.6-0.5,1.1-1.6L554.3,224.6L554.3,224.6z"
          />
          <path
            fill="#FFFFFF"
            d="M507.4,240.8l-6,11l-3.6,6.7l-0.6,1.5c11-0.8,22.1-1.9,33.1-3.6l0.2-0.3c1.5-4.9,2.9-9.9,4.2-15.1h-27.3
	L507.4,240.8L507.4,240.8z"
          />
          <path
            fill="#EBEBEB"
            d="M550.1,252.4l1.8-0.5c2.3-0.6,4.2-1.5,6.3-2.8c2.3-2.6,4.2-5.5,5.8-8.8h-13.8v12L550.1,252.4L550.1,252.4z
	 M557.7,243.8c0.3,0,0.6,0.2,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.2-0.8-0.3
	c-0.2-0.2-0.3-0.5-0.3-0.8s0.2-0.6,0.3-0.8C557,243.9,557.4,243.8,557.7,243.8L557.7,243.8z"
          />
          <path
            fill="#EBEBEB"
            d="M558.5,244.1c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8
	c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8S558.7,244.4,558.5,244.1L558.5,244.1z"
          />
          <path
            fill="#E6E7E8"
            d="M552.5,252.8l-2.3,0.6c-5.4,1.5-11.2,2.6-17.5,3.2l-1,2.9c7-1,13.1-2.4,18.5-4.1l2.3-0.8
	c3.4-1.1,6.5-2.9,9.3-5.4c2.1-1.8,4.1-4.9,5.7-8.9l-0.3-0.6c-1.3,3.6-3.1,6.2-5.4,8C559,250.2,555.7,251.9,552.5,252.8L552.5,252.8z
	"
          />
          <path
            fill="#E6E7E8"
            d="M552.5,254.8l-2.3,0.8c-5.4,1.6-11.5,3.1-18.5,4.1l-0.8,2.8c-0.2,0.5,0.2,0.8,1,0.6
	c12.7-2.6,22.6-5.7,29.9-9.1l0.5-0.2c4.1-2.4,5.8-6.8,5.4-13.1c-1.6,4.1-3.4,7-5.7,8.9C559,251.9,555.7,253.7,552.5,254.8
	L552.5,254.8z"
          />
          <path
            fill="#EBEBEB"
            d="M550.1,252.4v-12h-14.3c-1.5,5.4-2.9,10.6-4.4,15.3C538.5,255,544.9,254,550.1,252.4z M543.6,245.5
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.5,1.3-0.5C542.8,244.9,543.2,245.2,543.6,245.5L543.6,245.5z"
          />
          <path
            fill="#EBEBEB"
            d="M544.1,246.8c0-0.5-0.2-1-0.5-1.3s-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5C543.9,247.6,544.1,247.3,544.1,246.8L544.1,246.8z M543.4,246.8
	c0,0.3-0.2,0.6-0.3,0.8c-0.2,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.7-0.2-0.8-0.3s-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8c0.3-0.2,0.5-0.3,0.8-0.3
	c0.3,0,0.7,0.2,0.8,0.3C543.4,246.2,543.4,246.5,543.4,246.8L543.4,246.8z"
          />
          <path
            fill="#EBEBEB"
            d="M543.1,247.6c0.3-0.2,0.3-0.5,0.3-0.8s-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3
	c-0.2,0.3-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3C542.6,248,542.9,247.8,543.1,247.6L543.1,247.6z"
          />
          <path
            fill="#FFFFFF"
            d="M564.3,220.7c0.7,1,1.5,1.9,2.3,2.9l3.4,3.6l2.8,2.9l-1.5-3.9l-8.4-9.4l-2.4-2.9l1.3,2.3
	C562.4,217.9,563.4,219.4,564.3,220.7L564.3,220.7z M581.5,242.1l-1.3-0.5l-1.5-0.5l1.3,4.4v1.9l3.6,1.1l0.8,0.3l0.2-1.1v-1.3
	c0-1.6-0.5-2.9-1.6-3.7l-0.2-0.2l-0.3-0.2L581.5,242.1L581.5,242.1z"
          />
          <path
            fill="#FFFFFF"
            d="M580.2,241.6l1.3,0.5V242c-3.6-5.7-6.7-9.6-8.9-11.9l-2.8-2.9c3.9,5.8,6.7,11.5,8.4,17.2l0.5,0.3
	c0.3,0.3,0.8,0.5,1.1,0.8l-1.3-4.4l-0.6-2.3C579,239.7,579.6,240.5,580.2,241.6L580.2,241.6z"
          />
          <path
            fill="#646464"
            d="M582.8,242.6l0.3,0.2l0.5,0.2c-0.8-7.6,1.5-11.5,6.7-11.7c10.7,0.2,20.5,2.6,29.1,6.8c1.1,0.5,1.5,1.6,1,3.2
	l-2.6,6.7l-0.7,1.1c-2.9,4.5-8.4,7-16.4,7.5h-4.9c-3.7,0.2-6.5-0.5-8.1-2.3l0.6,1.9c1.1,1.3,3.6,1.9,7.3,1.9h5.4
	c8.8-0.3,14.8-3.1,18.2-8.1l0.7-1.3l1.9-4.7l0.3-1.9V242c0.2-2.4-0.8-4.2-2.9-5.2c-8.6-4.1-18.3-5.8-29.1-5.7
	c-4.5,0.3-7.1,2.1-8,5.4c-0.5,1.6-0.8,3.4-1,5.4v0.3l0.2,0.2l1,0.3L582.8,242.6L582.8,242.6L582.8,242.6z"
          />
          <path
            fill="#FFFFFF"
            d="M581.5,242v0.2h0.2 M583.7,243.1l-0.5-0.2H583c1.1,0.8,1.6,2.1,1.6,3.7v1.3l-0.2,1.1l0.2,0.2
	c0.3,0.2,0.6,0.3,0.8,0.6l0.5,0.6l0.3-0.6l0.2-1v-1.5c0-1-0.2-1.6-0.5-2.3C585.6,244.1,584.8,243.4,583.7,243.1L583.7,243.1z
	 M583.2,242.8l-0.2-0.2"
          />
          <path
            fill="#646464"
            d="M590.5,231.3c-5.2,0.3-7.5,4.2-6.7,11.7c1.1,0.5,1.8,1.1,2.3,1.9l-0.2-1.9c-0.7-6.8,1.3-10.4,6-10.6
	c9.7,0.2,18.3,2.3,26.3,6.2c1,0.5,1.3,1.5,0.8,2.9l-2.4,6l-0.7,1c-2.8,4.1-7.6,6.3-14.8,6.7H597c-3.4,0.2-6-0.3-7.6-1.8
	c-1.3-1.5-2.3-3.4-2.8-5.8v1l-0.2,1l-0.3,0.6l-0.3,0.5c0.8,0.6,1.5,1.6,2.1,3.2c1.8,1.8,4.4,2.6,8.1,2.3h4.9
	c8-0.3,13.3-2.9,16.4-7.5l0.7-1.1l2.6-6.7c0.5-1.6,0.2-2.8-1-3.2C610.9,233.9,601.2,231.6,590.5,231.3L590.5,231.3z"
          />
          <path
            fill="#EBEBEB"
            d="M585.9,245.1c0.3,0.6,0.5,1.5,0.5,2.3v0.5c0.6,2.4,1.5,4.4,2.8,5.8c1.8,1.3,4.2,1.9,7.6,1.8h4.2
	c7.1-0.3,12-2.6,14.8-6.7l0.7-1l2.4-6c0.5-1.5,0.2-2.4-0.8-2.9c-7.8-3.9-16.6-6-26.3-6.2c-4.7,0.2-6.8,3.7-6,10.6L585.9,245.1
	L585.9,245.1z"
          />
          <path
            fill="#FFFFFF"
            d="M582.5,250.4l-1.8,1l0.5,1.9l2.4-1.5v-0.5C583.5,250.4,583.2,250.1,582.5,250.4z"
          />
          <path
            fill="#FFFFFF"
            d="M580.7,251.2l1.8-0.8l1.9-1v-0.3l-0.8-0.3l-3.6-1.1v0.5 M584.5,249.4l0.2-0.2l-0.2-0.2"
          />
          <path
            fill="#FFFFFF"
            d="M584.6,249.3l-0.3,0.2l-1.8,1c0.6-0.2,1,0.2,1.1,0.8v0.5l-2.4,1.5l0.2,1.1l3.4-2.3l1-1l0.3-0.5l-0.5-0.6
	C585.3,249.6,585,249.4,584.6,249.3L584.6,249.3z"
          />
          <path
            fill="#FFFFFF"
            d="M584.6,257.2c-0.5-1.5-1-2.4-1.8-2.9l-0.2-0.3l0.3,0.3C583.7,255,584.3,255.9,584.6,257.2l2.9,8.8l1,3.9
	l1.5-8.9l-0.2-0.5l-1.5-4.5l-0.6-1.9c-0.5-1.6-1.3-2.6-2.1-3.2l-1,1l-3.4,2.3l0.6,2.9l0.3,1.6l2.4,11c0.6,3.2,1.8,6.2,3.2,9.1
	l1.5,3.2l-0.3-7.5l-0.2-0.6l-0.2-1.1l-0.5-2.8l-1-3.9L584.6,257.2L584.6,257.2z"
          />
          <path
            fill="#FFFFFF"
            d="M582.2,257.2l-0.7-2.9l-0.3-1.1l-0.5-1.9l-0.7-3.1v-1l-1.8-1.5l3.7,11.7 M582.2,257.2l-0.2,0.2l0.5,1.3"
          />
          <path
            fill="#646464"
            d="M600.5,258h-4.7c-3.7,0.2-6.2-0.5-7.3-1.9l1.5,4.5l2.3,1.3c0.5,0.3,1,0.6,1.3,1.1l2.4,0.5l1.6-3.4
	c0.2-0.3,0.3-0.6,0.6-1C598.9,258.5,599.7,258.2,600.5,258L600.5,258z"
          />
          <path
            fill="#FFFFFF"
            d="M578.1,239l0.6,2.3l1.5,0.5C579.6,240.5,579,239.7,578.1,239L578.1,239z M572.1,241.3l0.2,0.5
	c2.3,1.3,4.2,2.6,6,3.9l0.5-1l-0.5-0.3C576.5,243.1,574.4,242.1,572.1,241.3L572.1,241.3z"
          />
          <path
            fill="#FFFFFF"
            d="M572.3,241.8l-0.2-0.5c-0.3-1.3-0.8-2.6-1.3-4.1c1.1,3.1,1.3,5.8,0.7,8.3c-0.7,2.4-2.3,4.7-4.5,6.7l7.6,15.7
	c0.3-6.5-0.2-13.6-1.5-21.4C572.9,245.1,572.6,243.4,572.3,241.8L572.3,241.8z"
          />
          <path
            fill="#EBEBEB"
            d="M565.8,240.5H564c-1.6,3.1-3.6,6-5.8,8.8l3.1-2.3C563.2,245.2,564.8,243.1,565.8,240.5z"
          />
          <path
            fill="#FFFFFF"
            d="M580.1,247.2v-1.5c-0.3-0.3-0.8-0.6-1.1-0.8l-0.5,1L580.1,247.2L580.1,247.2z M578.3,245.7
	c-1.8-1.5-3.7-2.8-6-3.9c0.3,1.6,0.7,3.2,0.8,4.9c1.3,7.8,1.8,14.9,1.5,21.4l6,22.1l4.5-20.1l-2.4-11l-0.5-1.5L578.3,245.7
	L578.3,245.7z"
          />
          <path
            fill="#646464"
            d="M596,263.6l-2.4-0.5c0.7,0.8,1.1,1.8,1.3,2.9L596,263.6L596,263.6z"
          />
          <path
            fill="#FFFFFF"
            d="M593.4,263.1c-0.3-0.5-0.8-0.8-1.3-1.1c1,1,1.5,2.4,1.5,4.1v3.2c0.3,0.5,0.7,1,0.8,1.5
	c0.2-0.5,0.3-0.8,0.3-1.3V266C594.5,264.9,594.2,263.9,593.4,263.1L593.4,263.1z"
          />
          <path
            fill="#FFFFFF"
            d="M592.3,268.1c0.3,0.2,0.7,0.5,1,0.8l0.3,0.3V266c0-1.6-0.5-3.1-1.5-4.1l-2.3-1.3l0.2,0.5l0.7,1.9l-0.5,3.7
	L592.3,268.1L592.3,268.1z"
          />
          <path
            fill="#FFFFFF"
            d="M593.2,268.9c-0.3-0.3-0.6-0.6-1-0.8l-1.9-1.1l-0.6,3.6l1.6-0.6L593.2,268.9L593.2,268.9z"
          />
          <path
            fill="#FFFFFF"
            d="M593.6,269.2l-0.3-0.3l-1.9,1v1.8l-1.9,1v1.3l2.3-1.1c1.5-0.5,2.3-1.3,2.8-1.9
	C594.2,270.2,593.9,269.7,593.6,269.2L593.6,269.2z M590.8,263.1l-0.7-2l-1.5,8.9l0.5,2.8l0.2-0.2c0-0.6,0.2-1.5,0.3-2.1l0.6-3.6
	L590.8,263.1L590.8,263.1z"
          />
          <path
            fill="#FFFFFF"
            d="M591.3,269.9l-1.6,0.6c-0.2,0.6-0.2,1.5-0.3,2.1l1.9-1L591.3,269.9L591.3,269.9z M589.3,272.6l-0.2,0.2
	l0.2,1.1v-0.2"
          />
          <path
            fill="#FFFFFF"
            d="M591.3,273.6v-0.5l0.2-0.5l-2.3,1.1v0.8l0.3,7.5c0.5,1.1,0.8,2.4,1.1,3.6l0.2,1
	C591.3,282.4,591.3,278,591.3,273.6L591.3,273.6z M589.3,273.9v0.6v-0.8"
          />
          <path
            fill="#FFFFFF"
            d="M591,286.8l-0.2-1c-0.3-1.3-0.7-2.4-1.1-3.6c-1.1,5.4-2.3,10.4-3.7,15.3l5.5,16.7l1.3-6l1.3-6
	c-0.2-1.3-0.3-2.4-0.7-3.7L591,286.8L591,286.8z"
          />
          <path
            fill="#FFFFFF"
            d="M589.7,282.1l-1.5-3.2c-1.5-2.9-2.6-5.8-3.2-9.1l-4.5,20.1c1.8,3.1,3.6,5.5,5.5,7.3
	C587.2,292.4,588.5,287.4,589.7,282.1L589.7,282.1z M593.9,306.1c0.2-1.3,0.2-2.6,0.2-4.1l-1.3,6c-0.8,13.8-2.1,26.8-3.6,39.1v20.9
	l3.7-8.9l-1.3-6.5c1.3-9.9,1.8-20,1.9-30.5L593.9,306.1L593.9,306.1z"
          />
          <path
            fill="#FFFFFF"
            d="M592.6,308.2l-1.3,6l-2.4,11.4v21.9C590.6,335.1,591.8,322,592.6,308.2L592.6,308.2z M585.9,297.3
	c-1.9-1.8-3.7-4.2-5.5-7.3c-1.6,6.2-3.4,12-5.5,17.5c-1.8,4.7-5.8,7.8-12.3,8.9c-9.1,1-18.7,1.3-28.9,0.8l-9.6-0.2
	c-0.2,1.6,3.4,2.9,10.9,3.7l30.4,2.3c7.6-0.3,12.7-3.4,14.9-9.1C582.2,308.7,584.1,303.2,585.9,297.3L585.9,297.3z M593.4,363.2
	l-0.6-3.9l-3.7,8.9v6.7l3.7-6C593.7,367.4,593.9,365.5,593.4,363.2L593.4,363.2z"
          />
          <path
            fill="#646464"
            d="M589,368.2v-42.8l-8.9,42.9l-0.5,2.3c-0.5,2.3-1.5,4.2-2.6,6l-7.1,10.1h-7.1l-1-0.8h-14v50.8
	c0,5.5,2.9,8.4,8.6,8.4h24c5.8,0,8.8-2.8,8.8-8.4v-61.5L589,368.2z"
          />
          <path
            fill="#FFFFFF"
            d="M579.6,370.5l0.5-2.3c-0.3,1.5-0.8,2.8-1.5,3.9l-10.2,14.3h1.5l7.1-10.1
	C578.1,374.9,579.1,372.8,579.6,370.5L579.6,370.5z"
          />
          <path
            fill="#646464"
            d="M562.6,386.6h5.7c-5.7-2.4-12.8-4.5-21.1-6H547l-1.9-0.3c-7.6-1.3-16.4-2.1-26.3-2.6l-8.4-0.3
	c-29.2-0.5-58.4-0.6-87.8-0.6h-3.2c-29.2,0-58.4,0.2-87.8,0.6l-8.4,0.3c-9.7,0.5-18.5,1.3-26.3,2.6l-1.9,0.3h-0.2
	c-8.4,1.5-15.6,3.4-21.3,6h5.7l1-0.8h33.3v3.1h-19v8.8l8.4,0.3l16.4,2.9h203.5l16.4-2.9l8.4-0.3v-8.8h-19v-3.1h32.9L562.6,386.6
	L562.6,386.6z"
          />
          <path
            fill="#FFFFFF"
            d="M568.2,386.6l10.2-14.3c-12.2-3.4-24.7-5.8-37.8-7c2.6,4.5,4.9,9.6,6.3,15.4
	C555.4,382.2,562.6,384.1,568.2,386.6z"
          />
          <path
            fill="#FFFFFF"
            d="M540.6,365.3l-0.2-0.3H538c3.7,4.5,6.8,9.7,8.8,15.7h0.2C545.5,374.9,543.4,369.9,540.6,365.3L540.6,365.3z"
          />
          <path
            fill="#FFFFFF"
            d="M537.7,365.3h2.4c-10.1-16.9-28.2-25.5-54.4-25.8c-21.1-0.6-42.2-1.1-63.5-1h-3.2c-21.3,0-42.4,0.3-63.5,1
	c-26.1,0.5-44.3,9.1-54.4,25.8h2.4c10.4-12.2,27.3-18.8,50.6-19.6c21.6-0.6,43.2-1.1,64.8-1h3.2c21.8,0,43.3,0.3,64.8,1
	C510.3,346.5,527.2,353,537.7,365.3L537.7,365.3z"
          />
          <path
            fill="#FFFFFF"
            d="M538,365c-6-0.6-12.3-1.3-19.3-1.6l-6.3-0.3c-30.2-0.5-60.1-0.6-89.9-0.6h-3.2c-29.7,0-59.7,0.3-89.9,0.6
	l-6.3,0.3c-7,0.3-13.5,1-19.3,1.6c-3.7,4.5-6.8,9.7-8.8,15.7l1.9-0.3c7.6-1.3,16.4-2.1,26.3-2.6l8.4-0.3c29.2-0.5,58.4-0.6,87.8-0.6
	h3.2c29.2,0,58.4,0.2,87.8,0.6l8.4,0.3c9.9,0.5,18.7,1.3,26.3,2.6l1.9,0.3C544.7,374.6,541.8,369.4,538,365L538,365z"
          />
          <path
            fill="#FFFFFF"
            d="M512.1,363.4l6.3,0.3c7,0.3,13.5,1,19.3,1.6c-10.4-12.2-27.3-18.8-50.6-19.6c-21.6-0.6-43.2-1.1-64.8-1h-3.2
	c-21.8,0-43.3,0.3-64.8,1c-23.4,0.8-40.3,7.5-50.6,19.6c5.8-0.6,12.3-1.3,19.3-1.6l6.3-0.3c30.2-0.5,60.1-0.6,89.9-0.6h3.2
	C452,362.7,481.9,362.9,512.1,363.4L512.1,363.4z"
          />
          <path fill="#646464" d="M528.6,385.8v3.1h19v-3.1" />
          <path
            fill="#FFFFFF"
            d="M317.8,317.1l-9.6,0.2c-10.2,0.3-20,0-28.9-0.8c-6.5-1.1-10.7-4.1-12.3-8.9c-2.1-5.5-3.9-11.4-5.5-17.5
	c-1.6,3.1-3.6,5.5-5.5,7.3c1.8,5.8,3.6,11.4,5.8,16.6c2.3,5.7,7.1,8.8,14.9,9.1l30.4-2.3C314.4,320,318,318.7,317.8,317.1
	L317.8,317.1z M301.4,365l-0.2,0.3c-2.6,4.5-4.9,9.6-6.3,15.4h0.2c2.1-6,5-11.2,8.8-15.7L301.4,365L301.4,365z"
          />
          <path
            fill="#646464"
            d="M280.3,385.8l-1,0.8h-7.1l-7.1-10.1c-1.3-1.8-2.3-3.7-2.8-6l-0.5-2.3l-9.1-42.9v111c0,5.5,2.9,8.4,8.8,8.4
	h24c5.8,0,8.6-2.8,8.6-8.4v-50.8L280.3,385.8L280.3,385.8z M294.3,385.8v3.1h19v-3.1"
          />
          <path
            fill="#FFFFFF"
            d="M294.6,381.1c1.5-5.7,3.7-10.9,6.3-15.4c-13.1,1.1-25.6,3.6-37.8,7l10.2,14.3
	C279,384.5,286.1,382.5,294.6,381.1L294.6,381.1z M247.5,302.5c-0.2,1.3,0,2.6,0.2,4.1l0.3,16.2c0,10.4,0.6,20.6,1.9,30.5l-1.3,6.5
	l3.7,8.9v-20.9c-1.6-12.3-2.8-25.3-3.6-39.1L247.5,302.5L247.5,302.5z"
          />
          <path
            fill="#FFFFFF"
            d="M250.4,314.2l-1.3-6c0.8,13.8,2.1,26.8,3.6,39.1v-21.9L250.4,314.2z M263.4,372.3c-0.6-1.1-1.1-2.4-1.5-3.9
	l0.5,2.3c0.5,2.3,1.5,4.2,2.8,6l7.1,10.1h1.5L263.4,372.3L263.4,372.3z M248,363.5c-0.5,2.3-0.2,4.2,0.6,5.7l3.7,6v-6.7l-3.7-8.9
	L248,363.5L248,363.5z"
          />
          <path
            fill="none"
            d="M550.1,240.5h14 M567.1,239.9l-11-20.3 M558.2,249.1c2.3-2.6,4.2-5.5,5.8-8.8 M558.2,249.1l3.1-2.3
	c2.1-1.6,3.6-3.7,4.7-6.5 M567.4,240.5l-0.3-0.6 M564,240.5h1.8 M557.7,243.8c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8
	s0.2,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8s-0.2-0.6-0.3-0.8
	C558.3,243.9,558,243.8,557.7,243.8L557.7,243.8z M601,258h0.2 M531.4,255.8c7.1-0.6,13.3-1.8,18.7-3.4"
          />
          <path
            fill="none"
            d="M531.6,259.5l-0.8,2.8c-0.2,0.5,0.2,0.8,1,0.6c12.7-2.6,22.6-5.7,29.9-9.1l0.5-0.2c4.1-2.4,5.8-6.8,5.4-13.1
	 M530.4,255.8h1 M531.4,255.8c1.5-4.9,2.9-9.9,4.4-15.3h14.3 M550.1,240.5v11.8"
          />
          <path
            fill="none"
            d="M567.4,240.5c-1.6,4.1-3.4,7-5.7,8.9c-2.8,2.4-6,4.2-9.3,5.4l-2.3,0.8c-5.4,1.6-11.5,3.1-18.5,4.1"
          />
          <path
            fill="none"
            d="M567.1,239.9c-1.3,3.6-3.1,6.2-5.4,8c-2.8,2.4-6,4.1-9.3,5l-2.3,0.6c-5.4,1.5-11.2,2.6-17.5,3.2l-1,2.9"
          />
          <path
            fill="none"
            d="M550.1,252.4l1.8-0.5c2.3-0.6,4.2-1.5,6.3-2.8 M543.6,245.5c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3S543.9,245.9,543.6,245.5
	L543.6,245.5z"
          />
          <path
            fill="none"
            d="M543.4,246.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.3-0.3,0.5-0.3,0.8
	s0.2,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.7-0.2,0.8-0.3C543.4,247.3,543.4,247.2,543.4,246.8L543.4,246.8z M549.9,204.6
	l-4.2-7.6 M545.7,197l-1.1,6.3 M552.3,221.5c1.3,0.3,2.3,0.8,2.6,1.5l5.7,10.7c0.7,1.6,0.2,2.4-1.5,2.4h-12.7
	c-4.5,0-6.2-1.9-5.2-5.7l1.9-8.4c0.5-1.3,1.1-1.9,2.3-1.8L552.3,221.5L552.3,221.5z"
          />
          <path
            fill="none"
            d="M554.8,224l4.9,8.8c0.5,1.3,0.2,1.9-1.3,1.9h-10.7c-3.7,0-5.4-1.6-4.4-4.5l1.6-7c0.3-1.1,1-1.6,1.9-1.5l6,1.1
	C553.6,223.1,554.4,223.5,554.8,224L554.8,224z"
          />
          <path
            fill="none"
            d="M554.3,224.6l4.1,7.6c0.5,1.1,0.2,1.6-1.1,1.6H548c-3.2,0-4.5-1.3-3.7-3.9l1.3-6c0.3-1,1-1.5,1.6-1.3l5,1
	C553.3,223.8,554,224.1,554.3,224.6L554.3,224.6z"
          />
          <path
            fill="none"
            d="M555.6,222.5l6.2,11.9c0.6,1.8,0.2,2.6-1.6,2.6H546c-5,0-6.8-2.1-5.7-6.2l2.1-9.4c0.5-1.5,1.3-2.1,2.4-1.9
	l7.8,1.5C554.1,221.2,555.1,221.7,555.6,222.5L555.6,222.5z"
          />
          <path
            fill="none"
            d="M565.8,240.5l0.2-0.6l-9.6-18.5c-0.6-1.5-1.9-2.4-3.6-2.9l-12.7-2.4 M556.1,219.6l-0.3-0.6
	c-1-1.9-2.6-3.2-4.7-4.2l-8.3-2.1"
          />
          <path
            fill="none"
            d="M543.9,218.1l8.6,1.6c1.6,0.3,2.8,1,3.2,1.9l7,13.5c0.8,1.9,0.2,2.9-1.8,2.9h-14c-6.8,0-9.6-2.3-8.3-7l2.4-10.7
	C541.8,218.8,542.6,217.9,543.9,218.1L543.9,218.1z M544.5,203.3l-0.3,1.5 M544.2,204.8l-0.3,1.9 M556.1,219.6
	c-0.5-0.8-1.5-1.5-3.1-1.9l-9.7-1.9c-0.8-0.3-1-0.8-0.8-1.6l0.2-0.8v-0.2l0.2-0.3 M543.9,206.7l-0.3,1.8 M551.2,206.7l-0.6-0.3
	c-1.6-0.8-3.7-1.3-6.3-1.6"
          />
          <path
            fill="none"
            d="M542.8,212.8c0.3-0.5,1-0.6,2.1-0.5l6.2,1.5c2.1,0.6,3.9,1.9,5.2,3.7l12.5,23c0.5,6.5-1.5,10.9-5.8,13.5
	c-8.1,4.1-19.8,7.5-35.2,10.2h-0.3 M551.2,206.7l1.1,2.1 M553.5,210.6l1.6,3.1 M543.6,208.5c-0.2,1,0.3,1.6,1.1,1.9
	c3.4,0.8,6.8,1.8,9.7,2.9l0.6,0.2 M555.1,213.7l1.8,0.6 M543.9,206.7c4.2,0.6,6.8,1.3,7.8,1.8l0.7,0.3 M552.3,208.9l1,0.6
	c0.2,0.3,0.3,0.6,0.3,1l-0.2,0.3"
          />
          <path
            fill="none"
            d="M553.5,210.6l-0.5,0.2h-0.2c-2.8-1.3-5.8-1.9-9.3-2.3 M544.5,203.3c1.9,0.3,3.6,0.6,4.9,1.1l0.6,0.3"
          />
          <path
            fill="none"
            d="M549.9,204.6l0.3,0.2c1,0.5,1.5,1,1.5,1.8c-0.2,0.3-0.3,0.5-0.6,0.3 M546,195.1l-0.3,1.9 M274.8,239.9
	l10.9-20.3 M291.7,252.4c5.4,1.5,11.5,2.6,18.7,3.4 M291.7,240.5v11.8 M277.9,240.5c1.6,3.1,3.6,6,5.8,8.8 M291.7,240.5h-13.8"
          />
          <path
            fill="none"
            d="M274.5,240.5c1.6,4.1,3.4,7,5.7,8.9c2.9,2.4,6,4.2,9.3,5.4l2.3,0.8c5.4,1.6,11.5,3.1,18.5,4.1"
          />
          <path fill="none" d="M283.7,249.1c1.9,1.3,4.1,2.1,6.3,2.8l1.8,0.5" />
          <path
            fill="none"
            d="M310.2,259.5l-1-2.9c-6.3-0.6-12.2-1.6-17.5-3.2l-2.3-0.6c-3.4-1-6.5-2.6-9.3-5c-2.1-1.8-3.9-4.4-5.4-8"
          />
          <path
            fill="none"
            d="M275.9,240.5c1.1,2.8,2.6,4.9,4.7,6.5c1,0.8,1.9,1.6,3.1,2.3"
          />
          <path
            fill="none"
            d="M274.5,240.5c-0.5,6.2,1.3,10.7,5.4,13.1l0.5,0.2c7.1,3.4,17.2,6.5,29.7,9.1c1,0.2,1.3-0.2,1-0.6l-0.8-2.8"
          />
          <path
            fill="none"
            d="M285,244.1c0.2,0.2,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3
	c-0.2-0.2-0.3-0.5-0.3-0.8s0.2-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3S284.8,243.9,285,244.1z M274.8,239.9l-0.3,0.6 M277.9,240.5
	h-1.9 M310.3,255.8c-1.5-4.9-2.9-9.9-4.2-15.3h-14.4 M310.3,255.8h1.1"
          />
          <path
            fill="none"
            d="M300.4,246c0.2,0.3,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3
	c-0.3-0.2-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3C299.9,245.7,300.1,245.7,300.4,246L300.4,246z"
          />
          <path
            fill="none"
            d="M301.4,246.8c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	s0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5S301.4,246.3,301.4,246.8L301.4,246.8z M292,204.6l4.2-7.6 M297.3,203.3l-1.1-6.3 M298.3,208.5
	l-0.3-1.8 M298,206.7l-0.3-1.9 M301.4,215.8l-12.5,2.4c-1.6,0.6-2.8,1.6-3.4,2.9l-9.6,18.5l0.2,0.6 M299.1,212.8l-8.3,2.1
	c-2.1,0.8-3.7,2.3-4.7,4.2l-0.3,0.6"
          />
          <path
            fill="none"
            d="M285.7,219.6c0.5-0.8,1.5-1.5,3.1-1.9l9.7-1.9c0.8-0.3,1-0.8,0.6-1.6l-0.2-0.8v-0.2l-0.2-0.3 M286.8,223
	l-5.7,10.7c-0.6,1.6-0.2,2.4,1.5,2.4h12.7c4.5,0,6.2-1.9,5.2-5.7l-1.9-8.4c-0.5-1.3-1.3-1.9-2.3-1.8l-7,1.3
	C288.1,221.8,287.3,222.3,286.8,223L286.8,223z"
          />
          <path
            fill="none"
            d="M287.6,224.6l-4.1,7.6c-0.5,1.1-0.2,1.6,1.1,1.6h9.2c3.2,0,4.5-1.3,3.7-3.9l-1.5-6c-0.3-1-1-1.5-1.6-1.3l-5,1
	C288.6,223.8,287.9,224.1,287.6,224.6z"
          />
          <path
            fill="none"
            d="M287.1,224l-4.9,8.8c-0.5,1.3-0.2,1.9,1.3,1.9h10.7c3.7,0,5.4-1.6,4.4-4.5l-1.6-7c-0.3-1.1-1-1.6-1.8-1.5
	l-6,1.1C288.1,223.1,287.4,223.5,287.1,224L287.1,224z"
          />
          <path
            fill="none"
            d="M286.3,222.5l-6.2,11.9c-0.6,1.8-0.2,2.6,1.6,2.6h14.1c5,0,6.8-2.1,5.7-6.2l-2.1-9.4c-0.5-1.5-1.3-2.1-2.4-1.9
	l-7.8,1.5C287.8,221.2,286.8,221.7,286.3,222.5z"
          />
          <path
            fill="none"
            d="M286.1,221.7l-7,13.5c-0.8,1.9-0.2,2.9,1.8,2.9h13.8c6.8,0,9.6-2.3,8.3-7l-2.4-10.7c-0.6-1.6-1.5-2.4-2.8-2.3
	l-8.6,1.6C287.8,220.2,286.6,220.9,286.1,221.7L286.1,221.7z M290.7,206.7l-1.1,2.1"
          />
          <path
            fill="none"
            d="M299.1,212.8c-0.3-0.5-1-0.6-2.1-0.5l-6.2,1.5c-2.1,0.6-3.9,1.9-5.2,3.7l-12.5,23c-0.5,6.5,1.5,10.9,5.8,13.5
	c8.1,4.1,19.8,7.5,35.2,10.2h0.3 M288.4,210.6l-1.6,3.1 M286.8,213.7l0.5-0.3c3.1-1.1,6.3-2.1,9.7-2.9c1-0.3,1.3-1,1.1-1.9
	 M284.9,214.4l1.9-0.6"
          />
          <path
            fill="none"
            d="M298.3,208.5c-3.4,0.2-6.5,1-9.2,2.3h-0.2l-0.3-0.2 M289.6,208.9c-0.6,0.3-1,0.5-1,0.6c-0.2,0.3-0.3,0.6-0.3,1
	l0.2,0.3 M289.6,208.9l0.6-0.3c1-0.5,3.6-1,7.8-1.8 M297.7,204.8c-2.6,0.3-4.7,0.8-6.3,1.6l-0.3,0.2c-0.2,0.2-0.2,0.2-0.3,0.2"
          />
          <path
            fill="none"
            d="M292,204.6l-0.3,0.2c-1,0.5-1.5,1-1.5,1.8c0.2,0.3,0.3,0.5,0.6,0.3 M297.7,204.8l-0.3-1.5"
          />
          <path
            fill="none"
            d="M297.3,203.3c-1.9,0.3-3.6,0.6-4.9,1.1l-0.6,0.3 M296.2,197l-0.3-1.9 M240.7,258h0.2 M317.8,149.5
	c1.1,0.3,1.9,1.8,2.3,4.5l0.2,7.5v1.3 M317.8,149.5l-1.5,0.3c-6.3,1.8-11.2,4.5-14.4,8.4 M301.9,158.4c1.5-0.5,1.8,3.1,1.1,10.6
	c-0.5,5.2-2.4,10.9-5.7,16.7l-1.6,2.9"
          />
          <path fill="none" d="M295.7,188.6c2.9-7,4.5-12.7,4.7-17v-1.8" />
          <path
            fill="none"
            d="M300.4,169.7l-0.8,1.5c-6.7,13.1-12.8,26.9-18.7,41.2l-1.8,4.5 M298.3,164.4c1-0.5,1.5-0.2,1.8,1
	c0.2,1.5,0.3,2.9,0.3,4.4"
          />
          <path
            fill="none"
            d="M301.9,158.4c-1.3,1.5-2.3,3.1-2.9,5l-0.6,1.1 M320.2,162.9c0.2,0.5,0.3,1,0.5,1.3v0.2 M270.7,226.2l5.5-13.8
	c5.8-14.3,12.2-28.1,18.7-41.2l3.4-6.8 M320.7,164.2l0.8,0.5c2.3,1,6.8,0.8,13.8-0.5c27.8-4.1,55.8-6.2,84.1-6.3h3.2
	c28.2,0.2,56.3,2.3,84.1,6.3c7,1.3,11.5,1.5,13.8,0.5l0.8-0.5"
          />
          <path
            fill="none"
            d="M515.2,162.9c-0.3,0.8-1,1.1-1.9,1l-6.7-1c-27.8-4.1-55.8-6.2-84.1-6.3h-3.2c-28.2,0.2-56.3,2.3-84.1,6.3
	l-6.7,1c-1,0.2-1.6-0.2-1.9-1 M324,148.3c1.3,0.3,1.9,1.9,2.3,4.7l0.2,8.4"
          />
          <path
            fill="none"
            d="M545,196.4l-1.5-2.3c-2.8-4.1-5.8-7.8-9.6-10.9c-4.7-4.5-10.4-8.1-17.2-10.6c-5.2-1.8-11-3.2-17.5-4.2l-5.8-0.8
	c-23.4-2.8-47.1-4.2-70.8-4.2h-3.2c-23.7,0-47.4,1.5-70.8,4.2l-5.8,0.8c-6.5,1.1-12.3,2.4-17.5,4.2c-6.8,2.4-12.5,6-17.2,10.6
	c-3.7,3.2-6.8,6.8-9.6,10.9l-1.5,2.3 M326.7,162.9l-0.3-1.3 M324,148.3l-1,0.2l-5.2,1 M326.4,161.6c8.9-1.9,16.4-3.2,22.4-4.1h0.2
	c23.2-2.9,46.7-4.4,70.3-4.5h3.2c23.5,0.2,47.1,1.6,70.3,4.5h0.2c6,0.8,13.6,2.3,22.4,4.1"
          />
          <path
            fill="none"
            d="M299.9,194.6c2.6-3.7,5.5-7.1,8.9-10.1c4.5-4.4,10.1-7.8,16.7-10.2c5.2-1.8,10.9-3.2,17.4-4.2l5.7-0.8
	c23.4-2.8,46.9-4.2,70.6-4.4h3.2c23.7,0.2,47.2,1.5,70.6,4.4l5.7,0.8c6.5,1.1,12.2,2.4,17.4,4.2c6.5,2.3,12,5.7,16.7,10.2
	c3.4,2.9,6.5,6.3,9.1,10.1 M326.4,161.6l-6.2,1.3 M320.7,164.2l6-1.3 M260.3,242v-0.3c-0.2-1.9-0.3-3.7-1-5.4c-0.8-3.2-3.4-5-8-5.4
	c-10.7-0.2-20.4,1.6-29.1,5.7c-2.1,1.1-3.1,2.8-2.9,5.2v0.2l0.3,1.9l1.9,4.7l0.6,1.3c3.2,5,9.4,7.8,18.2,8.1"
          />
          <path
            fill="none"
            d="M255.5,247.8c-0.6,2.4-1.5,4.4-2.9,5.8c-1.8,1.3-4.2,1.9-7.6,1.8h-4.4c-7.1-0.3-12-2.6-14.8-6.7l-0.6-1l-2.4-6
	c-0.5-1.5-0.2-2.4,0.8-2.9c7.8-3.9,16.6-6,26.3-6.2c4.7,0.2,6.8,3.7,6,10.6l-0.2,1.9"
          />
          <path
            fill="none"
            d="M258.1,243.1c0.8-7.6-1.5-11.5-6.7-11.7c-10.7,0.2-20.4,2.6-29.1,6.8c-1.1,0.5-1.5,1.6-1,3.2l2.6,6.7l0.6,1.1
	c2.9,4.5,8.4,7,16.4,7.5h4.9c3.7,0.2,6.5-0.5,8.1-2.3 M269.6,241.8l0.2-0.5 M269.8,241.3c0.3-1.3,0.8-2.6,1.3-4.1 M270.4,245.7
	c0.3-2.3,1-4.9,2.1-7.3l1.1-2.6c1.8-3.9,3.7-7.8,6.2-12l5.2-9.2l11-19.3l1-1.8 M296.9,189.9l-18.7,32.8c-2.3,4.2-4.4,8.1-6.2,12
	l-1,1.9l-0.2,0.6 M263.1,241.2l0.6-2.3c-0.8,0.6-1.5,1.5-2.1,2.6 M260.2,242.1l-1,0.3l-0.3,0.2 M258.9,242.6l-0.2,0.2 M258.7,242.8
	l0.2-0.2 M258.7,242.8l-0.6,0.3 M261.6,241.6l-1.3,0.5 M260.3,242.1V242 M260.3,242l-0.2,0.2 M260.2,242.1h0.2 M261.8,247.2v0.5
	 M261.8,245.5v1.6 M257.4,249.4l-0.3-0.2 M257.1,249.3l0.3-0.2 M257.4,249.1v0.3"
          />
          <path
            fill="none"
            d="M257.4,249.1c-0.2-0.3-0.2-0.6-0.2-1.1v-1.3c0-1.6,0.5-2.9,1.6-3.7 M261.8,247.6l-3.6,1.1l-0.8,0.3
	 M259.2,250.4l-1.8-1 M259.2,250.4c-0.6-0.2-1,0.2-1.1,0.8v0.5l2.4,1.5 M261.8,247.6v0.5l-0.8,3.1 M261,251.2l-1.8-0.8 M255.8,245.1
	c-0.3,0.6-0.5,1.5-0.5,2.3v0.5 M255.8,250.4l-0.3-0.6v-1.9 M257.1,249.3l-0.8,0.6l-0.5,0.5 M256.1,251.1l-0.3-0.6 M254,254.3
	c0.6-1.6,1.3-2.6,2.1-3.2 M253.4,256.3l0.6-1.9 M259.7,257.2l0.6-2.9 M259.2,254l-0.3,0.3c-0.6,0.6-1.3,1.6-1.8,2.9l-2.9,8.8
	c-0.3,1.3-0.6,2.6-1,3.9 M260.3,254.3l-3.2-2.3l-1-1 M259.9,257.4l-0.2-0.2 M260.7,253.2l-0.3,1.1 M260.7,253.2l0.3-1.9
	 M263.4,244.4l-0.5,0.3 M266.2,262.6c0.5-2.6,0.8-4.7,1-6.7c0.2-2.3,0-3.6-0.5-3.6s-1.3,1.1-2.1,3.6c-1,2.4-1.8,5.4-2.6,8.8
	c-0.8,3.4-1.3,6.3-1.5,8.6c-0.2,2.4,0,3.6,0.5,3.6s1.3-1.1,2.1-3.6c0.6-1.8,1.3-3.7,1.9-6 M262.9,244.7l0.6,1 M269.3,230.1
	c-2.3,2.3-5.4,6.2-8.9,11.9 M261.8,245.5l1.3-4.4 M262.9,244.7c-0.3,0.3-0.8,0.5-1.1,0.8 M258.1,243.1c-1,0.5-1.8,1.1-2.3,1.9
	 M267.3,267.6c-0.2-1.6-0.2-3.2-0.2-4.9c0.2-5,0.6-10.4,1.5-16.1c0.2-1.6,0.3-3.2,0.8-4.9 M263.6,245.7l-3.7,11.7 M261.8,247.2
	l1.8-1.5 M270.4,245.7c-0.6-2.6-0.5-5.4,0.6-8.3 M261.6,241.6l1.5-0.5 M269.8,241.3c-2.3,0.8-4.2,1.8-6.2,3.1 M263.6,245.7
	c1.8-1.5,3.9-2.8,6-3.9 M240.9,258h0.3 M253.4,256.3c-1.1,1.3-3.7,1.9-7.5,1.9h-4.7 M249.6,261.9c-0.5,0.3-1,0.6-1.3,1.1
	 M248.3,263.1l-2.4,0.5 M248.8,264l-0.2,1.3v1.3c0.2,0.3,0.2,0.5,0.3,0.5c0.2,0,0.3-0.2,0.3-0.5l0.2-1.3c0-0.5,0-1-0.2-1.3
	c0-0.3-0.2-0.5-0.3-0.5C249.1,263.6,249,263.7,248.8,264L248.8,264z"
          />
          <path
            fill="none"
            d="M241.2,258c0.8,0.2,1.6,0.5,2.4,1l0.6,1l1.6,3.4 M251.6,267l-1.9,1.1c-0.3,0.2-0.6,0.5-1,0.8 M248.3,269.2
	l-0.2-0.6V266c0-1.6,0.5-3.1,1.5-4.1 M248.7,268.9l-0.3,0.3 M247.2,266v3.4c0,0.5,0.2,0.8,0.3,1.3 M250.6,269.9v1.8l1.8,1
	 M247.5,270.7c0.5,0.8,1.5,1.5,2.8,1.9 M248.7,268.9l1.9,1 M247.5,270.7c0.2-0.5,0.5-1,0.8-1.5 M245.9,263.6l1.3,2.4 M247.2,266
	c0.2-1.1,0.5-2.1,1.3-2.9 M250.3,272.8l0.2,0.5v0.5c0,4.4,0.2,8.8,0.3,13 M266.2,262.6c-0.5,0.2-1,0.3-1.5,0.6
	c-0.5,0.5-0.8,1.1-0.8,1.8c0,0.6,0.3,1.3,0.8,1.8l0.3,0.3 M267.3,267.9v-0.3 M267.6,267.5l-0.3,0.2 M267.6,267.5l-0.3,0.5
	 M265,267.1l1.1-4.5 M251.7,261.1l0.2-0.5 M251.7,261.1l-0.6,2l0.5,3.9 M252.2,270.5l-0.6-3.6 M253.2,270l-0.5,2.8 M252.4,272.6
	c0-0.6-0.2-1.5-0.3-2.1 M252.7,272.8l-0.3-0.2 M259.4,258.7l-2.4,11.2 M253.2,270l-1.5-8.9 M252.2,282.1l0.3-7.1v-0.3 M252.6,274.6
	v-0.6 M252.6,273.8v0.2 M252.6,274.6v-0.8 M256.9,269.9c-0.6,3.2-1.8,6.2-3.2,9.1l-0.2,0.6c-0.5,0.8-0.8,1.6-1.1,2.6 M252.2,282.1
	c-0.5,1.1-0.8,2.4-1.1,3.6l-0.2,1 M252.4,272.6l0.2,1.1 M252.6,273.9l0.2-1.1 M256.9,269.9l2.3,10.4c0.6,3.4,1.5,6.7,2.3,9.7
	 M574.4,267.9c-0.3,6-1.3,11.4-2.9,16.2c-5.5,13.3-25.8,21.4-61,24c-29.4,1-58.8,1.5-88.1,1.5h-3.2c-29.4,0-58.8-0.5-88.1-1.5
	c-35.2-2.8-55.5-10.7-61-24c-1.6-4.9-2.6-10.2-2.8-16.2 M251.9,260.6l1.5-4.4 M251.9,260.6l-2.3,1.3 M252.6,273.8l-2.3-1
	 M259.7,257.2l-0.3,1.5 M250.6,269.9l1.6,0.6 M259.9,257.4l-0.5,1.3 M270.7,226.2l-1.5,3.9 M272,227.2c-3.7,5.8-6.7,11.5-8.4,17.2
	 M269.3,230.1l2.8-2.9 M325.9,224.9c7,2.4,15.9,3.7,26.6,4.1c22.2,1,44.5,1.5,66.9,1.5h3.2c22.2,0,44.6-0.5,66.9-1.5
	c10.9-0.2,19.6-1.5,26.6-4.1 M304.5,191.3c-1.6,1-3.2,1.9-4.5,3.1 M306.3,190.4l-1.8,1 M298,197.5l1.9-2.9 M296.9,193.3v-3.4
	 M296.9,196.4v-3.1 M272,227.2l3.4-3.6l2.3-2.9c1-1.3,1.8-2.8,2.6-4.2l1.3-2.3 M279,217l2.4-2.9"
          />
          <path
            fill="none"
            d="M309.5,210l0.2,0.2c1.9,4.4,4.5,8,7.5,10.4c2.4,1.8,5.5,3.2,8.9,4.4 M307.2,240.8c-2.1-8.1-4.1-16.4-5.8-24.8
	l-0.2-0.3c-1.1-5.8-2.3-11.9-3.4-18 M296.9,189.9l1.5-2.8c6.7-11.2,14-19.1,21.9-24.2 M304.5,191.3c1.1,7.5,2.8,13.6,4.9,18.5
	 M281.4,214.1l14.3-25.5 M296.9,196.4l1.1,1.1 M306.3,190.4c0.2,6.8,1.3,13.3,3.2,19.6 M307.2,240.8c1.3,5,2.8,10.1,4.2,15.1
	l0.2,0.3 M314.6,265.2c-16.7-3.1-29.2-6.8-37.3-11.4l-2.1-1.6 M311.6,256.3l2.6,8.1l0.3,0.8 M314.6,265.2c1.1,3.4,2.3,6.7,3.4,10.1
	c1.5,4.1,3.2,7.8,5,11.4c2.1,4.5,5,8.3,8.8,10.9c4.9,3.2,13.8,5,27.1,5.2c20.1,0.5,40.3,0.8,60.4,0.8h3.2c20.1,0,40.3-0.3,60.4-0.8
	c13.3-0.3,22.4-1.9,27.1-5.2c3.9-2.6,6.8-6.2,8.8-10.9c1.8-3.6,3.6-7.5,5-11.4l3.4-10.1 M340.4,251.7l-5.8-10.9 M344.1,258.4
	l-3.7-6.7"
          />
          <path
            fill="none"
            d="M493.4,259.8l-10.5,19.5c-3.6,6.7-9.6,10.1-18.2,10.2h-87.8c-8.4-0.2-14.4-3.6-18.2-10.2l-10.5-19.5"
          />
          <path
            fill="none"
            d="M344.7,259.8l11.2,20.6c4.1,7.3,10.7,11,20.1,11.4h89.8c9.4-0.3,16.1-4.1,20.1-11.4l11.2-20.6 M348.3,259.8
	h-3.6 M344.7,259.8l-0.6-1.5 M535.6,190.4c-9.6-5.2-23.9-8.9-42.7-11.2c-23.4-2.6-46.7-3.9-70.4-3.9h-3.2c-23.7,0-47.1,1.3-70.4,3.9
	c-18.8,2.3-33.1,6-42.7,11.2 M334.5,240.8l-8.6-15.9 M334.5,240.8h-27.3 M311.6,256.3c11,1.6,22.1,2.8,33.1,3.6"
          />
          <path
            fill="none"
            d="M359.2,220.9c20.1,1,40.1,1.6,60.1,1.8h3.2c20-0.2,40.1-0.6,60.1-1.8c26-1.1,38.8-7.6,38.3-19.8
	c-1-11.2-13.6-18-37.8-20.6c-20.1-1.9-40.3-2.9-60.5-2.9h-3.2c-20.3,0-40.6,1-60.5,2.9c-24.2,2.6-36.8,9.6-37.8,20.6
	C320.4,213.2,333.2,219.9,359.2,220.9L359.2,220.9z M275,252.2c-2.3-1.9-3.9-4.2-4.5-6.7 M275,252.2l-7.3,15.3 M279,217l-8.3,9.3
	 M524.1,149.5c-1.1,0.3-1.9,1.8-2.3,4.5l-0.2,7.5v1.3"
          />
          <path
            fill="none"
            d="M539.8,158.4c-3.2-3.9-8.1-6.8-14.4-8.4l-1.5-0.3 M515.5,161.6l0.2-8.4c0.3-2.8,1-4.4,2.3-4.7 M515.5,161.6
	l-0.3,1.3 M521.2,164.2l-6-1.3 M521.2,164.2c0.3-0.5,0.5-0.8,0.5-1.3 M521.7,162.9l-6.2-1.3 M524.1,149.5l-6.3-1.1 M571.2,226.2
	l-5.5-13.8c-6-14.3-12.2-28.1-18.7-41.2l-3.4-6.8 M562.7,217l-1.8-4.5c-5.8-14.3-12.2-28.1-18.7-41.2l-0.8-1.5"
          />
          <path
            fill="none"
            d="M541.5,169.7v1.8c0.2,4.4,1.6,10.1,4.5,17 M543.6,164.4c-1-0.5-1.5-0.2-1.8,1c-0.2,1.5-0.3,2.9-0.3,4.4"
          />
          <path
            fill="none"
            d="M539.8,158.4c-1.5-0.5-1.8,3.1-1.1,10.6c0.5,5.2,2.4,10.9,5.7,16.7l1.6,2.9 M543.6,164.4l-0.6-1.1
	c-0.8-1.8-1.8-3.4-3.1-5 M423.3,142.3L423,140c-0.5-4.5-0.8-7.6-0.8-9.4v-14c0.2-0.7,0.2-1.1,0.2-1.6l-0.2-1.1l-0.3-0.7
	c-0.3-0.5-0.6-0.7-1-0.7h-0.2c-0.3,0-0.6,0.2-1,0.7h-0.2l-0.2,0.7c-0.2,0.3-0.2,0.6-0.2,1.1l0.2,1.6v14c0,1.8-0.3,5-0.8,9.4
	l-0.3,2.3 M388.6,169.4c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5
	s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3C387.6,169.6,388.1,169.4,388.6,169.4L388.6,169.4z"
          />
          <path
            fill="none"
            d="M386.1,167h-1.3c-1.1,0-1.5,0.5-1.1,1.8c0.3,1.6,0.8,3.2,1.5,4.9c0.3,0.5,1.3,0.8,3.2,0.8"
          />
          <path
            fill="none"
            d="M386.1,167c-1,0-1.3,0.6-1,1.8c0.3,1.6,0.8,3.2,1.5,4.9c0.2,0.3,0.3,0.5,0.6,0.6l0.3,0.2 M414.9,147.8v2.1
	c0.3,0.6,1,1,2.1,1.1h7.6c1.1-0.2,1.9-0.6,2.1-1.1v-2.1 M392.6,169.9c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3S392.3,170.4,392.6,169.9
	L392.6,169.9z M401.2,171.2c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5C400.9,170.4,401.2,170.7,401.2,171.2L401.2,171.2z M403.3,169.9c0.3-0.3,0.8-0.5,1.3-0.5
	s1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
	S403,170.4,403.3,169.9z M414.2,169.9c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3
	c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3C413.6,170.7,413.7,170.4,414.2,169.9L414.2,169.9z M411.3,169.9
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.5,1.3-0.5C410.5,169.4,411,169.6,411.3,169.9L411.3,169.9z M517.8,148.3c-23.9-2.9-47.6-4.9-71.3-5.7
	c-6.7-0.3-13.5-0.3-20.1-0.3h-3.2 M418.4,142.3h-2.9c-6.7,0-13.5,0.2-20.1,0.3c-23.7,0.8-47.4,2.8-71.3,5.7 M418.6,142.3h-0.2
	 M426.9,147.8c0-0.8-0.6-1.5-1.5-1.9l-1.8-0.6"
          />
          <path
            fill="none"
            d="M423.6,145.2l0.2,1.5v0.2c0,0.3-0.3,0.6-0.8,0.8l-0.3,0.2l-1.5,0.2h-0.3l-1.5-0.2l-0.3-0.2
	c-0.6-0.2-0.8-0.5-0.8-0.8v-0.2l0.2-1.5"
          />
          <path
            fill="none"
            d="M426.9,147.8c-0.3,0.6-1,1-2.1,1.1h-7.6c-1.1-0.2-1.9-0.6-2.1-1.1"
          />
          <path
            fill="none"
            d="M418.1,145.2l-1.6,0.8c-1,0.5-1.5,1.1-1.6,1.9 M418.3,145.2h-0.2 M419.6,169.9c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5
	S419.9,169.6,419.6,169.9L419.6,169.9z M424.4,171.2c0,0.5,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5
	c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5C424.6,170.4,424.4,170.7,424.4,171.2
	L424.4,171.2z M430.4,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5C431.1,169.4,430.8,169.6,430.4,169.9L430.4,169.9z M436.9,169.4c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	S437.4,169.4,436.9,169.4L436.9,169.4z M441,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5
	c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5S441.3,169.6,441,169.9z M448.9,169.9c-0.3-0.3-0.8-0.5-1.3-0.5
	s-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3
	C449.6,170.7,449.4,170.4,448.9,169.9L448.9,169.9z M453.2,174.3c1.8,0,2.9-0.2,3.1-0.8c0.6-1.6,1.1-3.2,1.5-4.9
	c0.3-1.1-0.2-1.8-1.1-1.8h-1.3"
          />
          <path
            fill="none"
            d="M451.2,171.2c0,0.5,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5C451.4,170.4,451.2,170.7,451.2,171.2L451.2,171.2z"
          />
          <path
            fill="none"
            d="M454.1,174.1h0.2c0.3-0.2,0.6-0.3,0.6-0.6c0.6-1.6,1.1-3.2,1.5-4.9c0.3-1.1-0.2-1.8-1-1.8 M423.6,145.2
	l-0.3-2.9 M418.4,142.3l-0.3,2.9 M418.6,142.3l-0.3,2.9 M455.4,167h-69.3 M454,174.3l0.2-0.2 M453.8,174.3h0.2 M453.8,174.3h0.2
	 M454,174.3l0.2-0.2 M453.2,174.3h0.6 M387.8,174.3h-0.2H387.8 M387.8,174.3h0.6 M388.4,174.3h64.8 M537.2,191.3l-1.6-1
	 M541.9,194.6l1.8,2.9 M541.9,194.6c-1.3-1.1-2.9-2.1-4.5-3.1 M545,193.3l1,1.8l11,19.3l5.2,9.2c2.3,4.2,4.4,8.1,6.2,12l1.1,2.6
	c1,2.6,1.8,5,2.1,7.3 M545,193.3v3.1 M545,189.9v3.4 M545,196.4l-1.3,1.1 M521.7,162.9c8,5,15.3,13.1,21.9,24.2l1.5,2.8 M532.4,210
	v0.2c-1.9,4.4-4.4,8-7.3,10.4c-2.6,1.8-5.5,3.2-8.9,4.4 M543.7,197.5c-1,6.2-2.1,12.2-3.4,18l-0.2,0.3c-1.8,8.4-3.7,16.7-5.8,24.8
	 M532.4,210c1.9-6.3,3.1-12.8,3.2-19.6 M537.2,191.3c-1.1,7.5-2.8,13.6-4.9,18.5 M507.4,240.8l-6,10.9 M501.4,251.7l-3.6,6.7
	 M497.1,259.8h-3.7 M497.8,258.4l-0.6,1.5 M530.3,256.3l0.2-0.3c1.5-4.9,2.9-9.9,4.2-15.1 M527.3,265.2l0.3-0.8l2.6-8.1 M516,224.9
	l-8.6,15.9 M534.5,240.8h-27.1 M497.1,259.8c11-0.8,22.1-1.9,33.1-3.6 M560.3,214.1l1.3,2.3c0.8,1.5,1.6,2.9,2.6,4.2
	c0.6,1,1.5,1.9,2.3,2.9l3.4,3.6 M571.2,226.2l-8.4-9.3 M560.3,214.1l2.4,2.9 M580.2,241.6l1.3,0.5 M583,242.6h-0.2l-0.2-0.2l-1-0.3"
          />
          <path
            fill="none"
            d="M601.2,258c8.8-0.3,14.8-3.1,18.2-8.1l0.6-1.3l1.9-4.7l0.3-1.9v-0.2c0.2-2.4-0.8-4.2-2.9-5.2
	c-8.6-4.1-18.3-5.8-29.1-5.7c-4.5,0.3-7.1,2.1-8,5.4c-0.5,1.6-0.8,3.4-1,5.4v0.3 M581.5,242.1V242 M581.7,242.1h-0.2 M581.5,242
	l0.2,0.2 M583.7,243.1l-0.5-0.3 M583.2,242.8l-0.2-0.2 M583,242.6l0.2,0.2"
          />
          <path
            fill="none"
            d="M587.9,254.3c1.8,1.8,4.4,2.6,8.1,2.3h4.9c8-0.3,13.3-2.9,16.4-7.5l0.7-1.1l2.6-6.7c0.5-1.6,0.2-2.8-1-3.2
	c-8.6-4.4-18.3-6.7-29.1-6.8c-5.2,0.3-7.5,4.2-6.7,11.7"
          />
          <path
            fill="none"
            d="M586.1,250.4l0.2-0.6l0.2-1v-1 M586.4,247.8v-0.5c0-1-0.2-1.6-0.5-2.3 M580.7,251.2l1.8-0.8 M584.5,249.1
	l-0.8-0.3l-3.6-1.1 M583.2,242.8c1.1,0.8,1.6,2.1,1.6,3.7v1.3l-0.2,1.1 M584.6,249.3l-0.2,0.2 M584.5,249.1v0.3 M584.6,249.3
	l-0.2-0.2 M586.1,250.4l-0.5-0.6c-0.3-0.3-0.5-0.5-0.8-0.6 M584.5,249.4l-1.9,1 M582.5,250.4c0.6-0.2,1,0.2,1.1,0.8v0.5l-2.4,1.5
	 M582.7,254l0.3,0.3c0.6,0.6,1.3,1.6,1.8,2.9l2.9,8.8l1,3.9 M582.2,257.2l-0.7-2.9 M581.5,254.3l3.2-2.3l1-1 M582,257.4l0.2-0.2
	 M581.5,254.3l-0.3-1.1 M588.5,256.3l-0.7-1.9 M587.9,254.3c-0.7-1.6-1.3-2.6-2.1-3.2"
          />
          <path
            fill="none"
            d="M585.9,245.1l-0.2-1.9c-0.7-6.8,1.3-10.4,6-10.6c9.7,0.2,18.3,2.3,26.3,6.2c1,0.5,1.3,1.5,0.8,2.9l-2.4,6
	l-0.7,1c-2.8,4.1-7.6,6.3-14.8,6.7h-4.2c-3.4,0.2-6-0.3-7.6-1.8c-1.3-1.5-2.3-3.4-2.8-5.8 M585.8,251.1l0.3-0.6 M581.2,253.2
	l-0.5-1.9 M600.5,258h-4.7c-3.7,0.2-6.2-0.5-7.3-1.9 M600.5,258h0.5 M583.7,243.1c1.1,0.5,1.8,1.1,2.3,1.9 M570.8,237.3l-1.1-2.6
	c-1.8-3.9-3.7-7.8-6.2-12l-18.7-32.8 M578.6,241.2l-0.6-2.3c0.8,0.6,1.5,1.5,2.1,2.6 M572.3,241.8l-0.2-0.5 M572.1,241.3
	c-0.3-1.3-0.8-2.6-1.3-4.1 M527.3,265.2c16.7-3.1,29.2-6.8,37.3-11.4l2.1-1.6 M580.1,247.2v-1.6 M580.1,247.6v-0.5 M580.1,247.6v0.5
	l0.7,3.1 M578.8,244.7l-0.5,1 M580.1,247.2l-1.8-1.5 M578.8,244.7c0.3,0.3,0.8,0.5,1.1,0.8 M570.8,237.3c1.1,3.1,1.3,5.8,0.7,8.3
	 M578.3,244.4c-1.9-1.3-3.9-2.3-6.2-3.1 M580.1,245.5l-1.5-4.4 M578.3,245.7c-1.8-1.5-3.7-2.8-6-3.9 M566.9,252.2
	c2.4-1.9,3.9-4.2,4.5-6.7 M574.4,267.9c0.3-6.5-0.2-13.6-1.5-21.4c-0.2-1.6-0.3-3.2-0.8-4.9 M596,263.6l-2.6-0.5 M593.4,263.1
	c-0.3-0.5-0.8-0.8-1.3-1.1 M596,263.6l1.6-3.4c0.2-0.3,0.3-0.6,0.6-1c0.7-0.6,1.5-1,2.4-1 M593.2,268.9c-0.3-0.3-0.6-0.6-1-0.8
	l-1.9-1.1 M594.4,270.7c0.2-0.5,0.3-0.8,0.3-1.3V266 M593.6,269.2l-0.3-0.3"
          />
          <path
            fill="none"
            d="M592.3,261.9c1,1,1.5,2.4,1.5,4.1v3.2 M594.4,270.7c-0.2-0.5-0.5-1-0.8-1.5 M594.7,266l1.3-2.4 M593.4,263.1
	c0.7,0.8,1.1,1.8,1.3,2.9 M590.2,261.1l-0.2-0.5 M590.2,267l0.7-3.9l-0.7-2 M585,269.9l-2.4-11.2 M588.7,270l0.5,2.8 M591.3,269.9
	l-1.6,0.6 M589.3,272.6l1.9-1v-1.8 M589.2,272.8l0.2-0.2 M589.3,272.6c0-0.6,0.2-1.5,0.3-2.1 M589.7,270.5l0.5-3.6 M588.7,270
	l1.5-8.9 M591,286.8c0.3-4.4,0.3-8.8,0.3-13v-0.5l0.2-0.5 M589.3,273.9v0.6 M589.3,274.6v-0.8 M589.3,273.8v0.2 M591,286.8l-0.2-1
	c-0.3-1.3-0.7-2.4-1.1-3.6 M589.7,282.1l-1.5-3.2c-1.5-2.9-2.6-5.8-3.2-9.1 M589.3,274.6l0.3,7.5 M591.6,272.8
	c1.5-0.5,2.3-1.3,2.8-1.9 M589.2,272.8l0.2,1.1 M592.3,261.9l-2.3-1.3 M593.2,268.9l-1.9,1 M589.3,273.8v-1.1 M591.6,272.8l-2.3,1
	 M581.5,242c-3.6-5.7-6.7-9.6-8.9-11.9 M566.9,252.2l7.5,15.7 M578.6,241.2l1.6,0.5 M582.5,258.7l-0.3-1.5 M582.5,258.7l-0.5-1.3
	 M590,260.6l-1.5-4.4 M578.3,245.7l3.7,11.7 M572.6,230.1l-1.5-3.9 M569.9,227.2c3.9,5.8,6.7,11.5,8.4,17.2 M572.6,230.1l-2.8-2.9
	 M546.2,188.6l14.1,25.5 M507.4,240.8H334.5 M501.4,251.7h-161 M497.8,258.4H344.1 M348.3,259.8h145.1 M593.9,302.2
	c-0.2-1.3-0.3-2.4-0.7-3.7l-2.3-11.7"
          />
          <path
            fill="none"
            d="M592.7,359.3l-1.3-6.5c1.3-9.9,1.8-20,1.9-30.5l0.3-16.2c0.2-1.3,0.2-2.6,0.2-4.1 M591.5,314.2l-5.5-16.9
	 M592.6,308.2l-1.1,6 M593.9,302.2l-1.3,6 M585.9,297.3c-1.9-1.8-3.7-4.2-5.5-7.3"
          />
          <path
            fill="none"
            d="M580.4,290c-1.6,6.2-3.4,12-5.5,17.5c-1.8,4.7-5.8,7.8-12.3,8.9c-9.1,1-18.7,1.3-28.9,0.8l-9.6-0.2
	c-0.2,1.6,3.4,2.9,10.9,3.7l30.4,2.3c7.6-0.3,12.7-3.4,14.9-9.1c2.1-5.2,4.1-10.7,5.8-16.6 M591.5,314.2l-2.4,11.2 M589,375.1l3.7-6
	c1-1.5,1.1-3.4,0.6-5.7l-0.6-3.9 M589,347.5v20.8 M592.7,359.3l-3.7,8.9 M568.2,386.6h1.5l7.1-10.1c1.3-1.8,2.1-3.7,2.6-6l0.5-2.3
	 M579.9,368.4c-0.3,1.5-0.8,2.8-1.5,3.9 M547.6,385.8h14l1,0.8h5.7 M589,368.2v6.8 M568.2,386.6l10.2-14.3 M589,325.4v22.1
	 M579.9,368.4l9.1-43 M589,347.5c1.6-12.3,2.8-25.3,3.6-39.1 M574.4,338.1c-9.1-2.1-19.6-3.4-31.6-3.9c-3.1-0.3-4.1,0.6-2.9,2.8
	c4.9,7.5,12,11.8,21.6,13.1C570.3,351.7,574.7,347.6,574.4,338.1z"
          />
          <path
            fill="none"
            d="M547.6,397.6v39c0,5.5,2.9,8.4,8.6,8.4h24c5.8,0,8.8-2.8,8.8-8.4v-61.5 M540.6,365.3l-0.2-0.3 M540.5,365H538
	 M538,365c-5.8-0.6-12.3-1.3-19.3-1.6l-6.3-0.3c-30.2-0.5-60.1-0.6-89.9-0.6h-3.2c-29.7,0-59.7,0.3-89.9,0.6l-6.3,0.3
	c-7,0.3-13.5,1-19.3,1.6 M547.6,388.9h-19v-3.1h19 M295.1,380.6l1.9-0.3c7.6-1.3,16.4-2.1,26.3-2.6l8.4-0.3
	c29.2-0.5,58.4-0.6,87.8-0.6h3.2c29.2,0,58.4,0.2,87.8,0.6l8.4,0.3c9.9,0.5,18.7,1.3,26.3,2.6l2,0.3 M294.3,397.6l8.4,0.2l16.6,2.9
	h203.4l16.6-2.9l8.4-0.2 M547.6,388.9v-3.1 M547.6,397.6v-8.8 M546.8,380.6l0.2,0.2 M538,365c3.7,4.5,6.8,9.7,8.8,15.7 M540.6,365.3
	c2.6,4.5,4.9,9.6,6.3,15.4"
          />
          <path
            fill="none"
            d="M540.5,365c-10.1-16.9-28.2-25.5-54.4-25.8c-21.1-0.6-42.2-1.1-63.5-1h-3.2c-21.3,0-42.4,0.3-63.5,1
	c-26.1,0.5-44.3,9.1-54.4,25.8"
          />
          <path
            fill="none"
            d="M538,365c-10.4-12.2-27.3-18.8-50.6-19.6c-21.6-0.6-43.2-1.1-64.8-1h-3.2c-21.8,0-43.3,0.3-64.8,1
	c-23.4,0.8-40.3,7.5-50.6,19.6 M578.5,372.3c-12.2-3.4-24.7-5.8-37.8-7 M547,380.7c8.4,1.5,15.4,3.4,21.1,6 M301.9,336.9
	c-4.9,7.5-12,11.8-21.6,13.1c-8.9,1.6-13.3-2.4-13-12c9.1-2.1,19.6-3.4,31.6-3.9C302.1,334,303,334.8,301.9,336.9L301.9,336.9z
	 M256,297.3c1.8,5.8,3.6,11.4,5.8,16.6c2.3,5.7,7.1,8.8,14.9,9.1l30.4-2.3c7.5-0.8,11-1.9,10.9-3.7l-9.6,0.2
	c-10.2,0.3-20,0-28.9-0.8c-6.5-1.1-10.7-4.1-12.3-8.9c-2.1-5.5-3.9-11.4-5.5-17.5 M301.4,365l-0.2,0.3 M303.8,365h-2.4 M294.9,380.7
	l0.2-0.2 M294.3,385.8h-14l-1,0.8h-5.7 M294.3,397.6v-8.8 M294.3,388.9v-3.1 M294.3,388.9h19v-3.1h-19 M295.1,380.6
	c2.1-6,5-11.2,8.8-15.7 M301.2,365.3c-2.6,4.5-4.9,9.6-6.3,15.4 M261.5,290c-1.6,3.1-3.6,5.5-5.5,7.3 M247.8,302.2
	c-0.2,1.3,0,2.6,0.2,4.1l0.3,16.2c0,10.4,0.6,20.6,1.9,30.5l-1.3,6.5 M250.9,286.8l-2.3,11.7c-0.3,1.3-0.5,2.4-0.6,3.7 M249.1,308.2
	l-1.3-6 M250.4,314.2l-1.3-6 M250.4,314.2l5.5-16.9 M252.9,325.4l-2.4-11.2 M263.4,372.3c-0.6-1.1-1.1-2.4-1.5-3.9"
          />
          <path
            fill="none"
            d="M273.6,386.6h-1.5l-7.1-10.1c-1.3-1.8-2.3-3.7-2.8-6l-0.5-2.3 M252.9,347.5v20.8 M249.1,359.3l-0.6,3.9
	c-0.5,2.3-0.2,4.2,0.6,5.7l3.7,6 M249.1,359.3l3.7,8.9 M252.9,368.2v6.8 M263.4,372.3l10.2,14.3 M261.8,368.4l-8.9-43 M249.1,308.2
	c0.8,13.8,2.1,26.8,3.6,39.1 M252.9,325.4v22.1"
          />
          <path
            fill="none"
            d="M252.9,375.1v61.5c0,5.5,2.9,8.4,8.8,8.4h24c5.8,0,8.6-2.8,8.6-8.4v-39 M301.2,365.3c-13.1,1.1-25.6,3.6-37.8,7
	 M273.6,386.6c5.7-2.4,12.8-4.5,21.3-6 M578.3,244.4l0.5,0.3 M256,297.3c-1.5-4.9-2.8-9.9-3.7-15.3 M580.4,290l-6-22.1 M585.9,297.3
	c1.5-4.9,2.8-9.9,3.7-15.3 M261.5,290l5.8-22.1 M585,269.9l-4.5,20.1 M320.1,154c-0.3-2.8-1-4.4-2.3-4.5l-1.5,0.3
	c-6.3,1.8-11.2,4.5-14.4,8.4c1.5-0.5,1.8,3.1,1.1,10.6c-0.5,5.2-2.4,10.9-5.7,16.7l-1.6,2.9l-14.3,25.5l-1.3,2.3
	c-0.8,1.5-1.6,2.9-2.6,4.2l-2.3,2.9l-3.4,3.6c-3.7,5.8-6.7,11.5-8.4,17.2c2-1.3,3.9-2.3,6.2-3.1c0.3-1.3,0.8-2.6,1.3-4.1l0.2-0.6
	l1-1.9c1.8-3.9,3.7-7.8,6.2-12l18.7-32.8l1.5-2.8c6.7-11.2,14-19.1,21.9-24.2v-1.3L320.1,154L320.1,154z"
          />
          <path
            fill="none"
            d="M317.8,149.5c1.1,0.3,1.9,1.8,2.3,4.5l0.2,7.5v1.3l6.2-1.3l-0.2-8.4c-0.3-2.8-1-4.4-2.3-4.7l-1,0.2L317.8,149.5
	L317.8,149.5z M303,168.9c0.6-7.6,0.2-11-1.1-10.6c-1.3,1.5-2.3,3.1-2.9,5l-0.6,1.1c1-0.5,1.5-0.2,1.8,1c0.2,1.5,0.3,2.9,0.3,4.4
	v1.8c-0.2,4.4-1.6,10.1-4.7,17l1.6-2.9C300.6,179.6,302.5,174.1,303,168.9L303,168.9z"
          />
          <path
            fill="none"
            d="M300.4,171.5v-1.8l-0.8,1.5c-6.7,13.1-12.8,26.9-18.7,41.2l-1.8,4.5l2.4-2.9l14.3-25.5
	C298.6,181.8,300.3,176.1,300.4,171.5L300.4,171.5z"
          />
          <path
            fill="none"
            d="M299.6,171.2l0.8-1.5c0-1.5-0.2-2.9-0.3-4.4c-0.2-1.1-0.8-1.5-1.8-1l-3.4,6.8c-6.5,13.1-12.8,26.9-18.7,41.2
	l-5.5,13.8l8.4-9.4l1.8-4.5C286.8,198.1,293,184.4,299.6,171.2L299.6,171.2z M320.7,164.2c-0.3-0.5-0.5-0.8-0.5-1.3
	c-8,5-15.3,13.1-21.9,24.2l-1.5,2.8v6.5l1.5-2.3c2.8-4.1,5.8-7.8,9.6-10.9c4.7-4.5,10.4-8.1,17.2-10.6c5.2-1.8,11-3.2,17.5-4.2
	l5.8-0.8c23.4-2.8,47.1-4.2,70.8-4.2h3.2c23.7,0.2,47.4,1.5,70.8,4.2l5.8,0.8c6.5,1.1,12.3,2.4,17.5,4.2c6.8,2.4,12.5,6,17.2,10.6
	c3.7,3.2,7,6.8,9.6,10.9l1.5,2.3v-6.5l-1.5-2.8c-6.7-11.2-14-19.2-21.9-24.2c-0.2,0.5-0.2,1-0.5,1.3v0.2l-0.8,0.5
	c-2.1,1-6.8,0.8-13.8-0.5c-27.8-4.1-55.8-6.2-84.1-6.3h-3.2c-28.2,0.2-56.3,2.3-84.1,6.3c-7,1.3-11.5,1.5-13.8,0.5L320.7,164.2
	L320.7,164.2z"
          />
          <path
            fill="none"
            d="M320.7,164.1l6-1.3l-0.3-1.3l-6.2,1.3C320.2,163.4,320.4,163.7,320.7,164.1L320.7,164.1z M321.5,164.7
	c2.3,1,6.8,0.8,13.8-0.5c27.8-4.1,55.8-6.2,84.1-6.3h3.2c28.2,0.2,56.3,2.3,84.1,6.3c7,1.3,11.5,1.5,13.8,0.5l0.8-0.5l-6-1.3
	c-0.3,0.8-1,1.1-1.9,1l-6.7-1c-27.8-4.1-55.8-6.2-84.1-6.3h-3.2c-28.2,0.2-56.3,2.3-84.1,6.3l-6.7,1c-1,0.2-1.6-0.2-1.9-1l-6,1.3
	L321.5,164.7L321.5,164.7z"
          />
          <path
            fill="none"
            d="M328.7,163.9l6.7-1c27.8-4.1,55.8-6.2,84.1-6.3h3.2c28.2,0.2,56.3,2.3,84.1,6.3l6.7,1c1,0.2,1.6-0.2,2-1
	l0.3-1.3c-8.9-1.9-16.4-3.2-22.4-4.1h-0.2c-23.4-2.9-46.7-4.4-70.3-4.5h-3.2c-23.5,0.2-47.1,1.6-70.3,4.5h-0.2
	c-6,0.8-13.6,2.3-22.4,4.1l0.3,1.3C327.1,163.7,327.7,164.1,328.7,163.9L328.7,163.9z"
          />
          <path
            fill="none"
            d="M326.2,153l0.2,8.4c8.9-1.9,16.4-3.2,22.4-4.1h0.2c23.2-2.9,46.7-4.4,70.3-4.5h3.2c23.5,0.2,47.1,1.6,70.3,4.5
	h0.2c6,0.8,13.6,2.3,22.4,4.1l0.2-8.4c0.3-2.8,1-4.4,2.3-4.7c-23.9-2.9-47.6-4.9-71.3-5.7c-6.7-0.3-13.5-0.3-20.1-0.3h-3.2l0.5,2.9
	l1.8,0.8c1,0.5,1.5,1.1,1.5,1.9v2.1c-0.3,0.6-1,1-2.1,1.1h-7.6c-1.1-0.2-1.9-0.6-2.1-1.1V148c0-0.8,0.6-1.5,1.6-1.9l1.6-0.8l0.3-2.9
	h-2.9c-6.7,0-13.5,0.2-20.1,0.3c-23.7,0.8-47.4,2.8-71.3,5.7C325.3,148.6,325.9,150.3,326.2,153L326.2,153z M342.8,168.3
	c-6.5,1.1-12.3,2.4-17.5,4.2c-6.8,2.4-12.5,6-17.2,10.6c-3.7,3.2-6.8,6.8-9.6,10.9l-1.5,2.3l1.1,1.1l1.9-2.9
	c2.6-3.7,5.5-7.1,8.9-10.1c4.5-4.4,10.1-7.8,16.7-10.2c5.2-1.8,10.9-3.2,17.4-4.2l5.7-0.8c23.4-2.8,46.9-4.2,70.6-4.4h3.2
	c23.7,0.2,47.2,1.5,70.6,4.4l5.7,0.8c6.5,1.1,12.2,2.4,17.4,4.2c6.5,2.3,12,5.7,16.7,10.2c3.4,2.9,6.5,6.3,9.1,10.1l1.9,2.9l1.3-1.1
	l-1.5-2.3c-2.8-4.1-5.8-7.8-9.6-10.9c-4.7-4.5-10.4-8.1-17.2-10.6c-5.2-1.8-11-3.2-17.5-4.2l-5.8-0.8c-23.4-2.8-47.1-4.2-70.8-4.2
	h-3.2c-23.7,0-47.4,1.5-70.8,4.2L342.8,168.3L342.8,168.3z"
          />
          <path
            fill="none"
            d="M348.8,169.3l-5.7,0.8c-6.5,1.1-12.2,2.4-17.4,4.2c-6.5,2.3-12.2,5.7-16.7,10.2c-3.4,2.9-6.5,6.3-8.9,10.1
	c1.3-1.1,2.9-2.1,4.5-3.1l1.8-1c9.6-5.2,23.9-8.9,42.7-11.2c23.4-2.6,46.7-3.9,70.4-3.9h3.2c23.7,0,47.1,1.3,70.4,3.9
	c18.8,2.3,33.1,6,42.7,11.2l1.8,1c1.6,1,3.2,1.9,4.5,3.1c-2.6-3.7-5.5-7.1-9.1-10.1c-4.5-4.4-10.1-7.8-16.7-10.2
	c-5.2-1.8-10.9-3.2-17.4-4.2l-5.7-0.8c-23.4-2.8-46.9-4.2-70.6-4.4h-3.2C395.7,164.9,372.2,166.3,348.8,169.3L348.8,169.3z
	 M383.7,168.8c-0.3-1.1,0.2-1.8,1.1-1.8h71.9c1.1,0,1.5,0.5,1.1,1.8c-0.3,1.6-0.8,3.2-1.5,4.9c-0.3,0.5-1.3,0.8-3.2,0.8h-64.8
	c-1.8,0-2.9-0.2-3.2-0.8C384.7,172,384,170.4,383.7,168.8L383.7,168.8z"
          />
          <path
            fill="none"
            d="M384.8,167c-1.1,0-1.5,0.5-1.1,1.8c0.3,1.6,0.8,3.2,1.5,4.9c0.3,0.5,1.3,0.8,3.2,0.8h-0.8l-0.3-0.2
	c-0.3-0.2-0.6-0.3-0.6-0.6c-0.6-1.6-1.1-3.2-1.5-4.9c-0.3-1.1,0.2-1.8,1-1.8H384.8L384.8,167z"
          />
          <path
            fill="none"
            d="M426.2,169.4c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0.2-0.3,0.2-0.5,0.3-0.6
	c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5
	c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h1.9c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2
	c0-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h1.3c0.2-0.6,0.3-1.3,0.5-1.9
	c0.3-1.1-0.2-1.8-1-1.8h-69.3c-1,0-1.3,0.6-1,1.8l0.5,1.9h1.3c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.2,0.2,0.3,0.5,0.5,0.6h1.9c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2.1
	c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.3,0.6h1.9c0.2-0.3,0.2-0.5,0.3-0.6
	c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h1.9c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5
	s1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0.2-0.3,0.2-0.5,0.5-0.6c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.2,0.2,0.3,0.5,0.5,0.6h2.1c0.2-0.3,0.2-0.5,0.3-0.6c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.2,0.2,0.3,0.5,0.5,0.6h2
	c0.2-0.3,0.2-0.5,0.3-0.6C425.2,169.6,425.7,169.4,426.2,169.4L426.2,169.4z M419.2,170.7h-2.1c0,0.2,0.2,0.5,0.2,0.6
	c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-2.1
	c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6
	h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
	c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5
	c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6H396c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3
	c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.3l1,2.9c0.2,0.3,0.3,0.5,0.6,0.6
	l0.3,0.2h66.9c0.3-0.2,0.6-0.3,0.6-0.6c0.3-1,0.6-1.9,1-2.9h-1.3c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-2.1c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-2c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-3.1c0,0.2,0.2,0.5,0.2,0.6c0,0.5-0.2,1-0.5,1.3
	s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6H428c0,0.2,0.2,0.5,0.2,0.6
	c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.2,0-0.3,0.2-0.6h-1.9c0,0.2,0.2,0.5,0.2,0.6
	c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3C419.1,171,419.1,170.9,419.2,170.7L419.2,170.7z"
          />
          <path
            fill="none"
            d="M388.6,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5
	s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C389.5,169.6,389,169.4,388.6,169.4L388.6,169.4z M392.6,169.9c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5
	C393.4,169.4,392.9,169.6,392.6,169.9L392.6,169.9z M401.2,171.2c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5C400.9,172.2,401.2,171.7,401.2,171.2L401.2,171.2z
	 M403.3,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	c-0.3-0.3-0.8-0.5-1.3-0.5S403.7,169.6,403.3,169.9z M414.2,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5
	s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5C415,169.4,414.5,169.6,414.2,169.9L414.2,169.9z
	 M411.3,169.9c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5
	s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3S411.6,170.4,411.3,169.9L411.3,169.9z M420.9,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C421.8,169.6,421.4,169.4,420.9,169.4
	L420.9,169.4z M424.9,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3
	s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5C425.7,169.4,425.2,169.6,424.9,169.9z M431.6,169.4c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	C432.6,169.6,432.2,169.4,431.6,169.4L431.6,169.4z M438.2,169.9c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5
	c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3
	C438.9,170.7,438.6,170.4,438.2,169.9L438.2,169.9z M442.3,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C443.3,169.6,442.8,169.4,442.3,169.4L442.3,169.4z
	 M449.6,171.2c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5S449.6,171.7,449.6,171.2L449.6,171.2z M451.7,169.9c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5
	C452.5,169.4,452.2,169.6,451.7,169.9L451.7,169.9z"
          />
          <path
            fill="none"
            d="M457.9,168.8c0.3-1.1-0.2-1.8-1.1-1.8h-1.3c1,0,1.3,0.6,1,1.8c-0.3,1.6-0.8,3.2-1.5,4.9
	c-0.2,0.3-0.3,0.5-0.6,0.6h-0.2l-0.2,0.2h-0.6c1.8,0,2.9-0.2,3.2-0.8C457.1,172,457.5,170.4,457.9,168.8L457.9,168.8z M454.1,174.1
	l-0.2,0.2 M454,174.3h-0.2H454z M387.8,174.3h-0.2H387.8L387.8,174.3z M219.4,242v0.2l0.3,1.9l1.9,4.7l0.6,1.3
	c3.2,5,9.4,7.8,18.2,8.1h5.4c3.7,0.2,6.2-0.5,7.5-1.9l0.6-1.9c-1.8,1.8-4.4,2.6-8.1,2.3H241c-8-0.3-13.3-2.9-16.4-7.5L224,248
	l-2.6-6.7c-0.5-1.6-0.2-2.8,1-3.2c8.6-4.4,18.3-6.7,29.1-6.8c5.2,0.3,7.5,4.2,6.7,11.7l0.5-0.2l0.3-0.2l0.3-0.2l1-0.3l0.2-0.2v-0.3
	c-0.2-1.9-0.3-3.7-1-5.4c-0.8-3.2-3.4-5-8-5.4c-10.7-0.2-20.4,1.6-29.1,5.7C220.3,237.7,219.3,239.5,219.4,242L219.4,242z"
          />
          <path
            fill="none"
            d="M223.7,238.9c-1,0.5-1.3,1.5-0.8,2.9l2.4,6l0.6,1c2.6,4.1,7.6,6.3,14.8,6.7h4.4c3.4,0.2,6-0.3,7.6-1.8
	c1.3-1.5,2.3-3.4,2.9-5.8v-0.5c0-1,0.2-1.6,0.5-2.3l0.2-1.9c0.6-6.8-1.3-10.4-6-10.6C240.2,232.9,231.5,234.8,223.7,238.9
	L223.7,238.9z"
          />
          <path
            fill="none"
            d="M222.8,241.6c-0.5-1.5-0.2-2.4,0.8-2.9c7.8-3.9,16.6-6,26.3-6.2c4.7,0.2,6.8,3.7,6,10.6l-0.2,1.9
	c0.5-1,1.3-1.5,2.3-1.9c0.8-7.6-1.5-11.5-6.7-11.7c-10.7,0.2-20.4,2.6-29.1,6.8c-1.1,0.5-1.5,1.6-1,3.2l2.6,6.7l0.6,1.1
	c2.9,4.5,8.4,7,16.4,7.5h4.9c3.7,0.2,6.5-0.5,8.1-2.3c0.5-1.6,1.1-2.6,2.1-3.2l-0.3-0.5l-0.3-0.6l-0.2-1v-1
	c-0.6,2.4-1.5,4.4-2.9,5.8c-1.8,1.3-4.2,1.9-7.6,1.8h-4.4c-7.1-0.3-12-2.6-14.8-6.7l-0.6-1L222.8,241.6L222.8,241.6z M269.8,241.3
	l-0.2,0.5c-0.5,1.6-0.6,3.2-0.8,4.9c-1,5.7-1.5,11-1.5,16.1c0,1.6,0,3.2,0.2,4.9l0.3-0.2l7.3-15.1c-2.3-1.9-3.9-4.2-4.5-6.7
	c-0.6-2.6-0.5-5.4,0.6-8.3C270.4,238.7,270.1,240,269.8,241.3L269.8,241.3z"
          />
          <path
            fill="none"
            d="M269.6,241.8l0.2-0.5c-2.3,0.8-4.2,1.8-6.2,3.1l-0.5,0.3l0.5,1C265.4,244.4,267.3,242.9,269.6,241.8
	L269.6,241.8z M273.5,235.6l-1.1,2.6c-1,2.6-1.8,5-2.1,7.3c0.6,2.4,2.3,4.7,4.5,6.7l2.1,1.6c8.3,4.5,20.6,8.3,37.3,11.4l-0.3-0.8
	c-15.4-2.8-27.1-6.2-35.2-10.2c-4.4-2.4-6.3-7-5.8-13.5l12.5-23c1.3-1.9,3.1-3.1,5.2-3.7l6.2-1.5c1.1-0.3,1.8-0.2,2.1,0.5l0.2,0.3
	v0.2l0.2,0.8c0.3,0.8,0,1.5-0.6,1.6l-9.7,1.9c-1.6,0.5-2.8,1.1-3.1,1.9l-10.9,20.4c1.3,3.6,3.1,6.2,5.4,8c2.9,2.4,6,4.1,9.3,5
	l2.3,0.6c5.4,1.5,11.2,2.6,17.5,3.2l1,2.9l0.8,2.8c0.2,0.5-0.2,0.8-1,0.6c-12.7-2.6-22.6-5.7-29.7-9.1l-0.5-0.2
	c-4.1-2.4-5.8-6.8-5.4-13.1l0.3-0.6l10.9-20.4l0.3-0.6c1-1.9,2.6-3.2,4.7-4.2l8.3-2.1c-0.3-0.5-1-0.6-2.1-0.5l-6.2,1.5
	c-2.1,0.6-3.9,1.9-5.2,3.7l-12.5,23c-0.5,6.5,1.5,10.9,5.8,13.5c8.1,4.1,19.8,7.5,35.2,10.2l-2.6-8.1l-0.2-0.3l-1.1-0.2
	c-7.1-0.6-13.3-1.8-18.7-3.4l-1.8-0.5c-2.3-0.6-4.4-1.5-6.3-2.8c-1.1-0.6-2.1-1.3-3.1-2.3c-2.1-1.6-3.6-3.7-4.7-6.5l-0.2-0.6
	l9.6-18.5c0.6-1.5,1.9-2.4,3.4-2.9l12.7-2.4l-0.2-0.3c-1.1-5.8-2.3-11.9-3.4-18l-1.1-1.1v-3.1l-1,1.8l0.3,1.9l1.1,6.3l0.3,1.5
	l0.3,1.9l0.3,1.8c0.2,1-0.3,1.6-1.1,1.9c-3.4,0.8-6.8,1.8-9.7,2.9l-0.6,0.2l-1.9,0.6l-5.2,9.2C277.4,227.8,275.3,231.7,273.5,235.6
	L273.5,235.6z"
          />
          <path
            fill="none"
            d="M272.5,238.2l1.1-2.6c1.8-3.9,3.7-7.8,6.2-12l5.2-9.3l11-19.3l1-1.8v-3.4l-18.7,32.8c-2.3,4.2-4.4,8.1-6.2,12
	l-1,1.9l-0.2,0.6c-1.1,3.1-1.3,5.8-0.6,8.3C270.7,243.3,271.4,240.8,272.5,238.2L272.5,238.2z M263.8,239l-0.6,2.3l-1.3,4.4
	c0.3-0.3,0.8-0.6,1.1-0.8l0.5-0.3c1.8-5.7,4.7-11.4,8.4-17.2l-2.8,2.9c-2.3,2.3-5.4,6.2-8.9,11.9v0.2l1.3-0.5
	C262.1,240.5,262.9,239.7,263.8,239L263.8,239z"
          />
          <path
            fill="none"
            d="M263.1,241.2l0.6-2.3c-0.8,0.6-1.5,1.5-2.1,2.6L263.1,241.2z"
          />
          <path
            fill="none"
            d="M259.2,242.5l-0.3,0.2l-0.2,0.2c-1.1,0.8-1.6,2.1-1.6,3.7v1.3c0,0.5,0.2,0.8,0.2,1.1l0.8-0.3l3.6-1.1v-1.9
	l1.3-4.4l-1.5,0.5l-1.3,0.5H260L259.2,242.5L259.2,242.5z M258.9,242.6l-0.2,0.2"
          />
          <path
            fill="none"
            d="M258.7,242.8l-0.6,0.2c-1,0.5-1.8,1.1-2.3,1.9c-0.3,0.6-0.5,1.5-0.5,2.3v1.5l0.2,1l0.3,0.6l0.5-0.6l0.8-0.6
	l0.2-0.2c-0.2-0.3-0.2-0.6-0.2-1.1v-1.3C257.1,244.9,257.7,243.8,258.7,242.8L258.7,242.8z M260.3,242.1V242l-0.2,0.2 M261.8,247.2
	v1l-0.8,3.1l-0.3,1.9l-0.3,1.1l-0.6,2.9l0.2,0.2l3.7-11.7 M261.8,245.5v1.5l1.8-1.5l-0.5-1C262.6,245.1,262.1,245.2,261.8,245.5z
	 M257.1,249.3l0.3,0.2v-0.3"
          />
          <path
            fill="none"
            d="M257.4,249.4l-0.3-0.2l-0.8,0.6l-0.5,0.6l0.3,0.5l1,1l3.4,2.3l0.2-1.1l-2.4-1.5v-0.5c0.2-0.6,0.5-1,1.1-0.8
	L257.4,249.4L257.4,249.4z"
          />
          <path
            fill="none"
            d="M257.4,249.1v0.3l1.8,1l1.8,0.8l0.8-3.1v-0.5l-3.6,1.1 M259.2,250.4c-0.6-0.2-1,0.2-1.1,0.8v0.5l2.4,1.5
	l0.5-1.9L259.2,250.4L259.2,250.4z M254,254.3l-0.6,1.9l-1.5,4.5l-0.2,0.5l1.5,8.9l1-3.9l2.9-8.8c0.5-1.5,1.1-2.4,1.8-2.9l0.3-0.3
	l-0.3,0.3c-0.6,0.6-1.3,1.6-1.8,2.9l-2.9,8.8c-0.3,1.3-0.6,2.6-1,3.9l-0.5,2.8l-0.2,1.1v1l-0.3,7.3c0.3-1,0.8-1.8,1.1-2.6l0.2-0.6
	c1.5-2.9,2.6-5.8,3.2-9.1l2.4-11l0.3-1.6l0.6-2.9l-3.4-2.3l-1-1C255.3,251.5,254.7,252.7,254,254.3L254,254.3z M259.9,257.4
	l-0.2-0.2l-0.3,1.5"
          />
          <path
            fill="none"
            d="M268.8,246.7c0.2-1.6,0.3-3.2,0.8-4.9c-2.1,1.3-4.2,2.6-6,3.9l-3.7,11.7l-0.5,1.5l-2.4,11l2.3,10.4
	c0.6,3.4,1.5,6.7,2.3,9.7l6-22.1v-0.5c-0.2-1.6-0.2-3.2-0.2-4.9C267.3,257.7,268,252.4,268.8,246.7L268.8,246.7z M266.7,252.4
	c0.5,0,0.6,1.1,0.5,3.6c-0.2,2-0.5,4.2-1,6.7l-1.1,4.5c-0.6,2.3-1.3,4.2-1.9,6c-1,2.4-1.6,3.6-2.1,3.6s-0.6-1.1-0.5-3.6
	c0.2-2.3,0.6-5.2,1.5-8.6c0.8-3.4,1.6-6.3,2.6-8.8C265.4,253.5,266,252.4,266.7,252.4L266.7,252.4z"
          />
          <path
            fill="none"
            d="M267.2,255.9c0.2-2.3,0-3.6-0.5-3.6s-1.3,1.1-2.1,3.6c-1,2.4-1.8,5.4-2.6,8.8c-0.8,3.4-1.3,6.3-1.5,8.6
	c-0.2,2.4,0,3.6,0.5,3.6s1.3-1.1,2.1-3.6c0.6-1.8,1.3-3.7,2-6l-0.3-0.3c-0.5-0.5-0.8-1.1-0.8-1.8s0.3-1.3,0.8-1.8
	c0.3-0.3,0.8-0.6,1.5-0.6C266.7,260,267,257.9,267.2,255.9L267.2,255.9z"
          />
          <path
            fill="none"
            d="M266.2,262.6c-0.5,0.2-1,0.3-1.5,0.6c-0.5,0.5-0.8,1.1-0.8,1.8c0,0.6,0.3,1.3,0.8,1.8l0.3,0.3L266.2,262.6
	L266.2,262.6z M246.1,258h-4.7c0.8,0.2,1.6,0.5,2.4,1l0.6,1l1.6,3.4l2.4-0.5c0.3-0.5,0.8-0.8,1.3-1.1l2.3-1.3l1.5-4.5
	C252.2,257.6,249.8,258.2,246.1,258L246.1,258z"
          />
          <path
            fill="none"
            d="M249.6,261.9c-0.5,0.3-1,0.6-1.3,1.1c-0.6,0.8-1.1,1.8-1.3,2.9v3.4c0,0.5,0.2,0.8,0.3,1.3
	c0.2-0.5,0.5-1,0.8-1.5l-0.2-0.6V266C248.2,264.4,248.7,262.9,249.6,261.9L249.6,261.9z"
          />
          <path
            fill="none"
            d="M248.3,263.1l-2.4,0.5l1.3,2.4C247.2,264.9,247.7,263.9,248.3,263.1L248.3,263.1z M248.7,268.9
	c0.3-0.3,0.6-0.6,1-0.8l1.9-1.1l-0.5-3.7l0.6-1.9l0.2-0.5l-2.3,1.3c-1,1-1.5,2.4-1.5,4.1v2.6l0.2,0.6L248.7,268.9L248.7,268.9z
	 M248.8,264c0.2-0.3,0.3-0.5,0.5-0.5s0.2,0.2,0.3,0.5c0.2,0.3,0.2,0.8,0.2,1.3l-0.2,1.3c-0.2,0.3-0.2,0.5-0.3,0.5
	c-0.2,0-0.3-0.2-0.3-0.5v-1.3L248.8,264L248.8,264z"
          />
          <path
            fill="none"
            d="M249.3,263.6c-0.2,0-0.3,0.2-0.5,0.5l-0.2,1.3v1.3c0.2,0.3,0.2,0.5,0.3,0.5s0.3-0.2,0.3-0.5l0.2-1.3
	c0-0.5,0-1-0.2-1.3C249.5,263.7,249.5,263.6,249.3,263.6L249.3,263.6z M249.6,268.1c-0.3,0.2-0.6,0.5-1,0.8l1.9,1l1.6,0.6l-0.6-3.6
	L249.6,268.1L249.6,268.1z"
          />
          <path
            fill="none"
            d="M248.7,268.9l-0.3,0.3c-0.3,0.5-0.6,1-0.8,1.5c0.5,0.8,1.5,1.5,2.8,1.9l2.3,1.1v-1.3l-1.9-1v-1.8L248.7,268.9
	L248.7,268.9z"
          />
          <path
            fill="none"
            d="M250.6,269.9v1.8l1.9,1c0-0.6-0.2-1.5-0.3-2.1L250.6,269.9L250.6,269.9z M250.4,273.3v0.5c0,4.4,0.2,8.8,0.3,13
	l0.2-1c0.3-1.3,0.6-2.4,1.1-3.6l0.3-7.3v-1.1l-2.3-1.1L250.4,273.3L250.4,273.3z M267.3,267.6v0.3l0.3-0.5"
          />
          <path
            fill="none"
            d="M267.6,267.5l-0.3,0.6c0.3,6,1.3,11.4,2.8,16.2c5.5,13.3,25.8,21.4,61,24c29.4,1,58.8,1.5,88.1,1.5h3.2
	c29.4,0,58.8-0.5,88.1-1.5c35.2-2.8,55.5-10.7,61-24c1.6-4.9,2.6-10.2,2.9-16.2l-7.6-15.7l-2.1,1.6c-8.3,4.5-20.6,8.3-37.3,11.4
	l-3.4,10.1c-1.5,4.1-3.2,7.8-5,11.4c-2.1,4.5-5,8.3-8.8,10.9c-4.9,3.2-13.8,5-27.1,5.2c-20.1,0.5-40.3,0.8-60.4,0.8h-3.2
	c-20.1,0-40.3-0.3-60.4-0.8c-13.3-0.3-22.4-1.9-27.1-5.2c-3.9-2.6-6.8-6.2-8.8-10.9c-1.8-3.6-3.6-7.5-5-11.4
	c-1.1-3.2-2.4-6.7-3.4-10.1c-16.7-3.1-29.2-6.8-37.3-11.4l-2.1-1.6L267.6,267.5L267.6,267.5z M251.7,261.1l-0.6,1.9l0.5,3.7l0.6,3.6
	c0.2,0.6,0.2,1.5,0.3,2.1l0.3,0.2l0.5-2.8L251.7,261.1L251.7,261.1z M252.7,272.8l-0.3-0.2l0.2,1.1v0.2 M252.6,274.6v-0.8
	 M253.5,278.8l-0.2,0.6c-0.5,0.8-0.8,1.6-1.1,2.6c1.1,5.4,2.3,10.4,3.7,15.3c1.9-1.8,3.7-4.2,5.5-7.3c-0.8-3.2-1.5-6.5-2.3-9.7
	l-2.3-10.4C256.1,273,255,276.1,253.5,278.8L253.5,278.8z"
          />
          <path
            fill="none"
            d="M252.2,282.1c-0.5,1.1-0.8,2.4-1.1,3.6l-0.2,1l-2.3,11.7c-0.3,1.3-0.5,2.4-0.6,3.7l1.3,6l1.3,6l5.5-16.7
	C254.5,292.4,253.2,287.4,252.2,282.1L252.2,282.1z"
          />
          <path
            fill="none"
            d="M270.2,284.2c-1.6-4.9-2.6-10.2-2.8-16.2l-6,22.1c1.6,6.2,3.4,12,5.5,17.5c1.8,4.7,5.8,7.8,12.3,8.9
	c9.1,1,18.7,1.3,28.9,0.8l9.6-0.2c0.2,1.6-3.4,2.9-10.9,3.7l-30.4,2.3c-7.6-0.3-12.7-3.4-14.9-9.1c-2.1-5.2-4.1-10.7-5.8-16.6
	l-5.5,16.7l2.4,11.4l9.1,42.9c0.3,1.5,0.8,2.8,1.5,3.9c12.2-3.4,24.7-5.8,37.8-7l0.2-0.3c10.1-16.9,28.2-25.5,54.4-25.8
	c21.1-0.6,42.2-1.1,63.5-1h3.2c21.3,0,42.4,0.3,63.5,1c26.1,0.5,44.3,9.1,54.4,25.8l0.2,0.3c13.1,1.1,25.6,3.6,37.8,7
	c0.6-1.1,1.1-2.4,1.5-3.9l8.9-42.9l2.4-11.4l-5.5-16.7c-1.6,5.8-3.6,11.4-5.8,16.6c-2.3,5.7-7.1,8.8-14.9,9.1l-30.4-2.3
	c-7.5-0.8-11-2-10.9-3.7l9.6,0.2c10.2,0.3,20,0,28.9-0.8c6.5-1.1,10.7-4.1,12.3-8.9c2.1-5.5,3.9-11.4,5.5-17.5l-6-22.1
	c-0.3,6-1.3,11.4-2.9,16.2c-5.5,13.3-25.8,21.4-61,24c-29.4,1-58.8,1.5-88.1,1.5h-3.2c-29.4,0-58.8-0.5-88.1-1.5
	C296,305.6,275.6,297.5,270.2,284.2L270.2,284.2z M574.4,338.1c0.2,9.6-4.1,13.6-13,12c-9.6-1.3-16.7-5.7-21.6-13.1
	c-1.1-2.1-0.2-2.9,2.9-2.8C554.8,334.6,565.3,335.9,574.4,338.1z M299.1,334.2c3.1-0.3,4.1,0.6,2.9,2.8c-4.9,7.5-12,11.8-21.6,13.1
	c-8.9,1.6-13.3-2.4-13-12C276.6,335.9,287.1,334.6,299.1,334.2z"
          />
          <path
            fill="none"
            d="M561.4,350.1c8.9,1.6,13.3-2.4,13-12c-9.1-2.1-19.6-3.4-31.6-3.9c-3.1-0.3-4.1,0.6-2.9,2.8
	C544.7,344.4,552,348.8,561.4,350.1L561.4,350.1z M301.9,336.9c1.1-2.1,0.2-2.9-2.9-2.8c-12,0.3-22.6,1.6-31.6,3.9
	c-0.2,9.6,4.1,13.6,13,12C289.9,348.8,297,344.4,301.9,336.9L301.9,336.9z M270.7,226.2l-1.5,3.9l2.8-2.9l3.4-3.6l2.3-2.9
	c1-1.3,1.8-2.8,2.6-4.2l1.3-2.3l-2.4,2.9L270.7,226.2L270.7,226.2z M352.5,229c-10.9-0.2-19.6-1.5-26.6-4.1l8.6,15.9h173l8.6-15.9
	c-7,2.4-15.7,3.7-26.6,4.1c-22.2,1-44.5,1.5-66.9,1.5h-3.2C397,230.3,374.8,230,352.5,229L352.5,229z"
          />
          <path
            fill="none"
            d="M325.9,224.9c7,2.4,15.9,3.7,26.6,4.1c22.2,1,44.5,1.5,66.9,1.5h3.2c22.2,0,44.6-0.5,66.9-1.5
	c10.9-0.2,19.6-1.5,26.6-4.1c3.4-1.1,6.5-2.6,8.9-4.4c2.9-2.4,5.4-6,7.3-10.4l0.2-0.2c1.9-6.3,3.1-12.8,3.2-19.6
	c-9.6-5.2-23.9-8.9-42.7-11.2c-23.4-2.6-46.7-3.9-70.4-3.9h-3.2c-23.7,0-47.1,1.3-70.4,3.9c-18.8,2.3-33.1,6-42.7,11.2
	c0.2,6.8,1.3,13.3,3.2,19.6l0.2,0.2c1.9,4.4,4.5,8,7.5,10.4C319.4,222.3,322.3,223.8,325.9,224.9L325.9,224.9z M359.2,220.9
	c-26-1.1-38.8-7.6-38.3-19.8c1-11.2,13.6-18,37.8-20.6c20.1-1.9,40.3-2.9,60.5-2.9h3.2c20.3,0,40.6,1,60.5,2.9
	c24.2,2.6,36.8,9.6,37.8,20.6c0.5,12-12.3,18.7-38.3,19.8c-20,1-40.1,1.6-60.1,1.8h-3.2C399.3,222.5,379.3,222,359.2,220.9
	L359.2,220.9z"
          />
          <path
            fill="none"
            d="M320.9,201.2c-0.5,12,12.3,18.7,38.3,19.8c20.1,1,40.1,1.6,60.1,1.8h3.2c20-0.2,40.1-0.6,60.1-1.8
	c26-1.1,38.8-7.6,38.3-19.8c-1-11.2-13.6-18-37.8-20.6c-20.1-1.9-40.3-2.9-60.5-2.9h-3.2c-20.3,0-40.6,1-60.5,2.9
	C334.5,183.2,321.9,190,320.9,201.2L320.9,201.2z M304.5,191.3c-1.6,1-3.2,1.9-4.5,3.1l-1.9,2.9c1,6.2,2.1,12.2,3.4,18l0.2,0.3
	c1.8,8.4,3.7,16.7,5.8,24.8h27.1l-8.6-15.9c-3.4-1.1-6.5-2.6-8.9-4.4c-2.9-2.4-5.4-6-7.5-10.4l-0.2-0.2
	C307.2,205,305.6,198.8,304.5,191.3L304.5,191.3z"
          />
          <path
            fill="none"
            d="M306.3,190.4l-1.8,1c1.1,7.5,2.8,13.6,4.9,18.5C307.6,203.7,306.4,197.2,306.3,190.4L306.3,190.4z M296.2,197
	l-0.3-1.9l-11,19.3l1.9-0.6l1.6-2.9l-0.2-0.3c0-0.3,0.2-0.6,0.3-1c0-0.2,0.3-0.3,1-0.6l1.1-1.9c-0.3,0.2-0.5,0-0.6-0.3
	c0.2-0.6,0.6-1.3,1.5-1.8l0.3-0.2L296.2,197L296.2,197z"
          />
          <path
            fill="none"
            d="M292.5,204.3c1.3-0.5,2.9-0.8,4.9-1.1l-1.1-6.3l-4.2,7.6L292.5,204.3L292.5,204.3z"
          />
          <path
            fill="none"
            d="M297.3,203.3c-1.9,0.3-3.6,0.6-4.9,1.1l-0.6,0.3l-0.3,0.2c-1,0.5-1.5,1-1.5,1.8c0.2,0.3,0.3,0.5,0.6,0.3
	c0.2,0,0.2-0.2,0.3-0.2l0.3-0.2c1.6-0.8,3.7-1.3,6.3-1.6L297.3,203.3L297.3,203.3z"
          />
          <path
            fill="none"
            d="M291,206.6c-0.2,0.2-0.2,0.2-0.3,0.2l-1.1,1.9l0.6-0.3c1-0.5,3.6-1,7.8-1.8l-0.3-1.9c-2.6,0.3-4.7,0.8-6.3,1.6
	L291,206.6L291,206.6z"
          />
          <path
            fill="none"
            d="M290.2,208.5l-0.6,0.3c-0.6,0.3-1,0.5-1,0.6c-0.2,0.3-0.3,0.6-0.3,1l0.2,0.3l0.3,0.2h0.2
	c2.8-1.3,5.8-1.9,9.2-2.3l-0.3-1.8C293.8,207.6,291.2,208,290.2,208.5L290.2,208.5z"
          />
          <path
            fill="none"
            d="M288.9,210.8l-0.3-0.2l-1.6,2.9l0.3-0.2c3.1-1.1,6.3-2.1,9.7-2.9c1-0.3,1.3-1,1.1-1.9
	C294.7,208.7,291.7,209.5,288.9,210.8L288.9,210.8z M285.7,219.6c0.5-0.8,1.5-1.5,3.1-1.9l9.7-1.9c0.8-0.3,1-0.8,0.6-1.6l-0.2-0.8
	v-0.2l-0.2-0.3l-8.3,2.1c-2.1,0.8-3.7,2.3-4.7,4.2L285.7,219.6L285.7,219.6z"
          />
          <path
            fill="none"
            d="M288.9,218.3c-1.6,0.6-2.8,1.6-3.4,2.9l-9.6,18.5l0.2,0.6h30.2c1.5,5.4,2.9,10.6,4.2,15.3l1.1,0.2
	c-1.5-4.9-2.9-9.9-4.2-15.1c-2.1-8.1-4.1-16.4-5.8-24.8L288.9,218.3L288.9,218.3z M286.1,221.7c0.5-0.8,1.6-1.5,3.2-1.9l8.6-1.6
	c1.3-0.2,2.1,0.6,2.8,2.3l2.4,10.7c1.3,4.7-1.5,7.1-8.3,7.1h-13.8c-2.1,0-2.6-1-1.8-2.9L286.1,221.7L286.1,221.7z"
          />
          <path
            fill="none"
            d="M289.4,219.7c-1.6,0.3-2.8,1-3.2,1.9l-7,13.5c-0.8,1.9-0.2,2.9,1.8,2.9h13.8c6.8,0,9.6-2.3,8.3-7l-2.4-10.7
	c-0.6-1.6-1.5-2.4-2.8-2.3L289.4,219.7L289.4,219.7z M286.3,222.5c0.5-0.8,1.5-1.3,2.9-1.6l7.8-1.5c1.1-0.2,2,0.5,2.4,1.9l2.1,9.4
	c1.1,4.1-0.8,6.2-5.7,6.2h-14.1c-1.8,0-2.4-0.8-1.6-2.6L286.3,222.5L286.3,222.5z"
          />
          <path
            fill="none"
            d="M289.2,220.9c-1.5,0.3-2.4,1-2.9,1.6l-6.2,11.9c-0.6,1.8-0.2,2.6,1.6,2.6h14.1c5,0,6.8-2.1,5.7-6.2l-2.1-9.4
	c-0.5-1.5-1.3-2.1-2.4-1.9L289.2,220.9L289.2,220.9z M286.8,223c0.5-0.6,1.3-1.1,2.6-1.5l7-1.3c1-0.2,1.8,0.5,2.3,1.8l2,8.4
	c1,3.7-0.6,5.5-5.2,5.7h-12.7c-1.6,0-2.1-0.8-1.5-2.4L286.8,223L286.8,223z"
          />
          <path
            fill="none"
            d="M289.4,221.5c-1.3,0.3-2.3,0.8-2.6,1.5l-5.7,10.7c-0.6,1.6-0.2,2.4,1.5,2.4h12.7c4.5,0,6.2-1.9,5.2-5.7
	l-1.9-8.4c-0.5-1.3-1.3-1.9-2.3-1.8L289.4,221.5L289.4,221.5z M287.1,224c0.3-0.5,1.1-1,2.3-1.1l6-1.1c0.8-0.2,1.5,0.3,1.8,1.5
	l1.6,7c1,3.1-0.6,4.5-4.4,4.5h-10.7c-1.5,0-1.8-0.6-1.3-1.9L287.1,224L287.1,224z"
          />
          <path
            fill="none"
            d="M289.2,222.8c-1.1,0.3-1.8,0.6-2.3,1.1l-4.9,8.8c-0.5,1.3-0.2,1.9,1.3,1.9h10.7c3.7,0,5.4-1.6,4.4-4.5l-1.6-7
	c-0.3-1.1-1-1.6-1.8-1.5L289.2,222.8L289.2,222.8z M287.6,224.6c0.3-0.5,1-0.8,1.9-1.1l5-1c0.6-0.2,1.3,0.3,1.6,1.3l1.5,6
	c0.8,2.6-0.5,3.9-3.7,3.9h-9.2c-1.3,0-1.6-0.5-1.1-1.6L287.6,224.6L287.6,224.6z"
          />
          <path
            fill="none"
            d="M289.6,223.6c-1,0.2-1.6,0.6-1.9,1.1l-4.1,7.6c-0.5,1.1-0.2,1.6,1.1,1.6h9.2c3.2,0,4.5-1.3,3.7-3.9l-1.5-6
	c-0.3-1-1-1.5-1.6-1.3L289.6,223.6L289.6,223.6z M311.5,255.8l0.2,0.3c11,1.6,22.1,2.8,33.1,3.6l-0.8-1.5l-3.6-6.7l-6-11h-27.1
	C308.5,245.9,310,250.9,311.5,255.8L311.5,255.8z M310.3,255.8c-1.5-4.9-2.9-9.9-4.2-15.3h-14.4v12C297,254,303.2,255,310.3,255.8
	L310.3,255.8z M300.9,245.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
	s0.2-1,0.5-1.3s0.8-0.5,1.3-0.5C300.1,244.9,300.4,245.2,300.9,245.5L300.9,245.5z"
          />
          <path
            fill="none"
            d="M301.4,246.8c0-0.5-0.2-1-0.5-1.3s-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5s-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5C301.2,247.6,301.4,247.3,301.4,246.8L301.4,246.8z M299.6,245.7c0.3,0,0.6,0.2,0.8,0.3
	c0.2,0.3,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.3,0.3-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3c-0.3-0.2-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8
	C299,245.7,299.3,245.7,299.6,245.7z"
          />
          <path
            fill="none"
            d="M300.4,246c-0.3-0.2-0.5-0.3-0.8-0.3s-0.6,0.2-0.8,0.3c-0.3,0.3-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8
	c0.2,0.3,0.5,0.3,0.8,0.3c0.3,0,0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8S300.6,246.2,300.4,246L300.4,246z M277.9,240.5h-1.9
	c1.1,2.8,2.6,4.9,4.7,6.5c1,0.8,1.9,1.6,3.1,2.3C281.4,246.5,279.5,243.6,277.9,240.5L277.9,240.5z"
          />
          <path
            fill="none"
            d="M274.8,239.9l-0.3,0.6c1.6,4.1,3.4,7,5.7,8.9c2.9,2.4,6,4.2,9.3,5.4l2.3,0.8c5.4,1.6,11.5,3.1,18.5,4.1l-1-2.9
	c-6.3-0.6-12.2-1.6-17.5-3.2l-2.3-0.6c-3.4-1-6.5-2.6-9.3-5C277.9,246,276.1,243.4,274.8,239.9z"
          />
          <path
            fill="none"
            d="M279.8,253.7l0.5,0.2c7.1,3.4,17.2,6.5,29.7,9.1c1,0.2,1.3-0.2,1-0.6l-0.8-2.8c-7-1-13.1-2.4-18.5-4.1l-2.3-0.8
	c-3.4-1.1-6.5-2.9-9.2-5.4c-2.1-1.8-4.1-4.9-5.7-8.9C274,246.8,275.8,251.2,279.8,253.7z"
          />
          <path
            fill="none"
            d="M290,251.9l1.8,0.5v-12H278c1.6,3.1,3.6,6,5.8,8.8C285.7,250.4,287.8,251.2,290,251.9L290,251.9z M284.2,243.8
	c0.3,0,0.6,0.2,0.8,0.3s0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	s0.2-0.6,0.3-0.8C283.6,243.9,283.9,243.8,284.2,243.8z"
          />
          <path
            fill="none"
            d="M285,244.1c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8
	c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8S285.2,244.4,285,244.1z M314.2,264.4l0.3,0.8
	c1.1,3.4,2.3,6.7,3.4,10.1c1.5,4.1,3.2,7.8,5,11.4c2.1,4.5,5,8.3,8.8,10.9c4.9,3.2,13.8,5,27.1,5.2c20.1,0.5,40.3,0.8,60.4,0.8h3.2
	c20.1,0,40.3-0.3,60.4-0.8c13.3-0.3,22.4-1.9,27.1-5.2c3.9-2.6,6.8-6.2,8.8-10.9c1.8-3.6,3.6-7.5,5-11.4l3.4-10.1l0.3-0.8h-0.3h0.3
	l2.6-8.1c-11,1.6-22.1,2.8-33.1,3.6l-11.2,20.6c-4.1,7.3-10.7,11-20.1,11.4h-89.8c-9.4-0.3-16.1-4.1-20.1-11.4l-11.2-20.6
	c-11-0.8-22.1-1.9-33.1-3.6L314.2,264.4l0.3,0L314.2,264.4L314.2,264.4z M334.5,240.8l5.8,10.9h161l6-10.9"
          />
          <path
            fill="none"
            d="M340.4,251.7l3.7,6.7h153.7l3.6-6.7 M348.3,259.8l10.5,19.5c3.6,6.7,9.6,10.1,18.2,10.2h87.8
	c8.4-0.2,14.4-3.6,18.2-10.2l10.5-19.5L348.3,259.8L348.3,259.8z"
          />
          <path
            fill="none"
            d="M358.9,279.3l-10.5-19.5h-3.6l11.2,20.6c4.1,7.3,10.7,11,20.1,11.4h89.8c9.4-0.3,16.1-4.1,20.1-11.4l11.2-20.6
	h-3.7l-10.5,19.5c-3.6,6.7-9.6,10.1-18,10.2H377C368.6,289.2,362.6,285.8,358.9,279.3L358.9,279.3z M344.7,259.8h152.4l0.6-1.5
	H344.1 M521.8,154c0.3-2.8,1-4.4,2.3-4.5l-6.2-1c-1.3,0.3-1.9,1.9-2.3,4.7l-0.2,8.4l6.2,1.3v-1.3L521.8,154z"
          />
          <path
            fill="none"
            d="M524.1,149.5c-1.1,0.3-1.9,1.8-2.3,4.5l-0.2,7.5v1.3c8,5,15.3,13.1,21.9,24.2l1.5,2.8l18.7,32.8
	c2.3,4.2,4.4,8.1,6.2,12l1.1,2.6c0.5,1.3,1,2.8,1.3,4.1c2.3,0.8,4.2,1.8,6.2,3.1c-1.8-5.7-4.7-11.4-8.4-17.2l-3.4-3.6
	c-0.8-1-1.5-1.9-2.3-2.9c-1-1.3-1.8-2.8-2.6-4.2l-1.3-2.3l-14.3-25.5l-1.6-2.9c-3.2-6-5.2-11.5-5.7-16.7c-0.6-7.6-0.2-11,1.1-10.6
	c-3.2-3.9-8.1-6.8-14.4-8.4L524.1,149.5L524.1,149.5z M515.5,161.6l-0.3,1.3l6,1.3v-0.2c0.3-0.3,0.3-0.6,0.5-1.3L515.5,161.6z"
          />
          <path
            fill="none"
            d="M547,171.2l-3.4-6.8c-1-0.5-1.5-0.2-1.8,1c-0.2,1.5-0.3,2.9-0.3,4.4l0.8,1.5c6.5,13.1,12.8,26.9,18.7,41.2
	l1.8,4.5l8.4,9.4l-5.5-13.8C559.6,198.1,553.5,184.4,547,171.2z"
          />
          <path
            fill="none"
            d="M542.3,171.2l-0.8-1.5v1.8c0.2,4.4,1.6,10.1,4.5,17l14.3,25.5l2.4,2.9l-1.8-4.5
	C555.1,198.1,548.8,184.3,542.3,171.2L542.3,171.2z"
          />
          <path
            fill="none"
            d="M541.5,171.5v-1.8c0-1.5,0.2-2.9,0.3-4.4c0.2-1.1,0.8-1.5,1.8-1l-0.6-1.1c-0.8-1.8-1.8-3.4-3.1-5
	c-1.5-0.5-1.8,3.1-1.1,10.6c0.5,5.2,2.4,10.9,5.7,16.7l1.6,2.9C543.1,181.8,541.6,176.1,541.5,171.5L541.5,171.5z M419.9,113.3
	l-0.2,0.6c-0.2,0.3-0.2,0.7-0.2,1.1l0.2,1.6v14c0,1.8-0.3,5-0.8,9.4l-0.3,2.3l-0.3,2.9l-0.2,1.5v0.2c0,0.3,0.3,0.6,0.8,0.8l0.3,0.2
	l1.5,0.2h0.3l1.5-0.2l0.3-0.2c0.6-0.2,0.8-0.5,0.8-0.8v-1.6l-0.3-2.9L423,140c-0.5-4.5-0.8-7.6-0.8-9.4v-14c0.2-0.7,0.2-1.1,0.2-1.6
	l-0.2-1.1l-0.3-0.7c-0.3-0.5-0.6-0.6-1-0.6h-0.2C420.5,112.6,420.2,112.8,419.9,113.3L419.9,113.3z"
          />
          <path
            fill="none"
            d="M414.9,147.8v2.1c0.3,0.6,1,1,2.1,1.1h7.6c1.1-0.2,1.9-0.6,2.1-1.1v-2.1c-0.3,0.6-1,1-2.1,1.1h-7.5
	C415.8,148.8,415.2,148.5,414.9,147.8z M418.6,142.3h-0.2l-0.3,2.9h0.2"
          />
          <path
            fill="none"
            d="M425.4,146l-1.8-0.8l0.2,1.5v0.2c0,0.3-0.3,0.6-0.8,0.8l-0.3,0.2l-1.5,0.2h-0.3l-1.5-0.2l-0.3-0.2
	c-0.6-0.2-0.8-0.5-0.8-0.8v-0.2l0.2-1.5l-0.2,0.2l-1.6,0.8c-1,0.5-1.5,1.1-1.6,1.9c0.3,0.6,1,1,2.1,1.1h7.6c1.1-0.2,1.9-0.6,2.1-1.1
	C426.9,147,426.4,146.5,425.4,146L425.4,146z M537.2,191.3l-1.8-1c-0.2,6.8-1.3,13.3-3.2,19.6C534.5,205,536.3,198.8,537.2,191.3
	L537.2,191.3z"
          />
          <path
            fill="none"
            d="M543.7,197.5l-1.9-2.9c-1.3-1.1-2.9-2.1-4.5-3.1c-1.1,7.5-2.8,13.6-4.9,18.5l-0.2,0.2c-1.9,4.4-4.4,8-7.3,10.4
	c-2.6,1.8-5.5,3.2-8.9,4.4l-8.6,15.9h27.1c2.1-8.1,4.1-16.4,5.8-24.8l0.2-0.3C541.6,209.7,542.8,203.7,543.7,197.5L543.7,197.5z"
          />
          <path
            fill="none"
            d="M545.7,197l0.3-1.9l-1-1.8v3.1l-1.3,1.1c-1,6.2-2.1,12.2-3.4,18l-0.2,0.3l12.7,2.4c1.6,0.6,2.8,1.6,3.6,2.9
	l9.6,18.5l-0.2,0.6c-1.1,2.8-2.6,4.9-4.7,6.5l-3.1,2.3c-1.9,1.3-4.1,2.1-6.3,2.8l-1.8,0.5c-5.4,1.5-11.5,2.6-18.7,3.4l-1.1,0.2
	l-0.2,0.3l-2.6,8.1c15.4-2.8,27.1-6.2,35.2-10.2c4.4-2.4,6.3-7,5.8-13.5l-12.5-23c-1.3-1.9-3.1-3.1-5.2-3.7l-6.2-1.5
	c-1.1-0.3-1.8-0.2-2.1,0.5l8.3,2.1c2.1,0.8,3.7,2.3,4.7,4.2l0.3,0.6l10.9,20.4l0.3,0.6c0.5,6.3-1.3,10.7-5.4,13.1l-0.3,0.2
	c-7.3,3.4-17.2,6.5-29.9,9.1c-1,0.2-1.3-0.2-1-0.6l0.8-2.8l1-2.9c6.3-0.6,12.2-1.6,17.5-3.2l2.3-0.6c3.4-1,6.5-2.6,9.3-5
	c2.1-1.8,3.9-4.4,5.4-8l-10.9-20.4c-0.5-0.8-1.5-1.5-3.1-1.9l-9.7-1.9c-0.8-0.3-1-0.8-0.8-1.6l0.2-0.8v-0.2l0.2-0.3
	c0.3-0.5,1-0.6,2.1-0.5l6.2,1.5c2.1,0.6,3.9,1.9,5.2,3.7l12.5,23c0.5,6.5-1.5,10.9-5.8,13.5c-8.1,4.1-19.8,7.5-35.2,10.2l-0.3,0.8
	c16.7-3.1,29.2-6.8,37.3-11.4l2.1-1.6c2.3-1.9,3.9-4.2,4.5-6.7c-0.3-2.3-1-4.9-2.1-7.3l-1.1-2.6c-1.8-3.9-3.7-7.8-6.2-12l-5.2-9.3
	l-1.9-0.6l-0.6-0.2c-3.1-1.1-6.3-2.1-9.7-2.9c-1-0.3-1.3-1-1.1-1.9l0.3-1.8l0.3-1.9l0.3-1.5L545.7,197L545.7,197z"
          />
          <path
            fill="none"
            d="M546,195.1l-0.3,1.9l4.2,7.6l0.3,0.2c1,0.5,1.5,1,1.5,1.8c-0.2,0.3-0.3,0.5-0.6,0.3l1.1,1.9l1,0.6
	c0.2,0.3,0.3,0.6,0.3,1l-0.2,0.3l1.6,2.9l2,0.6L546,195.1L546,195.1z"
          />
          <path
            fill="none"
            d="M545,193.3l1,1.8l11,19.3l5.2,9.2c2.3,4.2,4.4,8.1,6.2,12l1.1,2.6c1,2.6,1.8,5,2.1,7.3c0.7-2.6,0.5-5.4-0.7-8.3
	l-1.1-2.6c-1.8-3.9-3.7-7.8-6.2-12L545,189.9L545,193.3L545,193.3z"
          />
          <path
            fill="none"
            d="M551.2,206.7c0.3,0.2,0.5,0,0.6-0.3c-0.2-0.6-0.6-1.3-1.5-1.8l-0.3-0.2l-0.6-0.3c-1.3-0.5-2.9-0.8-4.9-1.1
	l-0.3,1.5c2.6,0.3,4.7,0.8,6.3,1.6L551.2,206.7L551.2,206.7z"
          />
          <path
            fill="none"
            d="M549.4,204.3l0.6,0.3l-4.2-7.6l-1.3,6.3C546.3,203.5,548,203.8,549.4,204.3L549.4,204.3z M552.8,210.8h0.2
	l0.5-0.2l0.2-0.3c0-0.3-0.2-0.6-0.3-1l-1-0.6l-0.7-0.3c-1-0.5-3.7-1-8-1.8l-0.3,1.8C547,208.7,550.1,209.5,552.8,210.8L552.8,210.8z
	"
          />
          <path
            fill="none"
            d="M553,210.8h-0.2c-2.8-1.3-5.8-1.9-9.3-2.3c-0.2,1,0.3,1.6,1.1,1.9c3.4,0.8,6.8,1.8,9.7,2.9l0.6,0.2l-1.6-2.9
	L553,210.8L553,210.8z M551.7,208.5l0.7,0.3l-1.1-1.9l-0.6-0.3c-1.6-0.8-3.7-1.3-6.3-1.6l-0.3,1.9
	C548.1,207.6,550.7,208,551.7,208.5L551.7,208.5z M542.6,213.2l-0.2,0.8c-0.3,0.8,0,1.5,0.8,1.6l9.7,1.9c1.6,0.5,2.8,1.1,3.1,1.9
	l-0.3-0.6c-1-1.9-2.6-3.2-4.7-4.2l-8.3-2.1L542.6,213.2L542.6,213.2z"
          />
          <path
            fill="none"
            d="M556.4,221.4c-0.6-1.5-1.9-2.4-3.6-2.9l-12.7-2.4c-1.8,8.4-3.7,16.7-5.8,24.8c-1.3,5-2.8,10.1-4.2,15.1l1.1-0.2
	c1.5-4.9,2.9-9.9,4.4-15.3h29.9l0.2-0.6L556.4,221.4L556.4,221.4z M541.1,220.4c0.6-1.6,1.5-2.4,2.8-2.3l8.6,1.6
	c1.6,0.3,2.8,1,3.2,1.9l7,13.5c0.8,1.9,0.2,2.9-1.8,2.9h-14c-6.8,0-9.6-2.3-8.3-7L541.1,220.4L541.1,220.4z"
          />
          <path
            fill="none"
            d="M543.9,218.1c-1.3-0.2-2.1,0.6-2.8,2.3l-2.4,10.7c-1.3,4.7,1.5,7.1,8.3,7.1h13.8c2.1,0,2.6-1,1.8-2.9l-7-13.5
	c-0.5-0.8-1.6-1.5-3.2-1.9L543.9,218.1z M552.7,220.9c1.5,0.3,2.4,1,2.9,1.6l6.2,11.9c0.6,1.8,0.2,2.6-1.6,2.6H546
	c-5,0-6.8-2.1-5.7-6.2l2.1-9.4c0.5-1.5,1.3-2.1,2.4-1.9L552.7,220.9L552.7,220.9z"
          />
          <path
            fill="none"
            d="M555.6,222.5c-0.5-0.8-1.5-1.3-2.9-1.6l-7.8-1.5c-1.1-0.2-1.9,0.5-2.4,1.9l-2.1,9.4c-1.1,4.1,0.8,6.2,5.7,6.2
	h14.1c1.8,0,2.4-0.8,1.6-2.6L555.6,222.5L555.6,222.5z M545.4,220.2l7,1.3c1.3,0.3,2.3,0.8,2.6,1.5l5.7,10.7
	c0.7,1.6,0.2,2.4-1.5,2.4h-12.7c-4.5,0-6.2-1.9-5.2-5.7l1.9-8.4C543.7,220.7,544.4,220.1,545.4,220.2L545.4,220.2z"
          />
          <path
            fill="none"
            d="M552.3,221.5l-7-1.3c-1-0.2-1.8,0.5-2.3,1.8l-1.9,8.4c-1,3.7,0.6,5.5,5.2,5.7H559c1.6,0,2.1-0.8,1.5-2.4
	l-5.7-10.7C554.6,222.3,553.6,221.8,552.3,221.5L552.3,221.5z M552.5,222.8c1.1,0.3,1.8,0.6,2.3,1.1l4.9,8.8
	c0.5,1.3,0.2,1.9-1.3,1.9h-10.7c-3.7,0-5.4-1.6-4.4-4.5l1.6-7c0.3-1.1,1-1.6,1.9-1.5L552.5,222.8z"
          />
          <path
            fill="none"
            d="M554.8,224c-0.3-0.5-1.1-1-2.3-1.1l-6-1.1c-0.8-0.2-1.5,0.3-1.9,1.5l-1.6,7c-1,3.1,0.6,4.5,4.4,4.5H558
	c1.5,0,1.8-0.6,1.3-1.9L554.8,224L554.8,224z M552.3,223.6c1,0.2,1.6,0.6,1.9,1.1l4.1,7.6c0.5,1.1,0.2,1.6-1.1,1.6H548
	c-3.2,0-4.5-1.3-3.7-3.9l1.3-6c0.3-1,1-1.5,1.6-1.3L552.3,223.6L552.3,223.6z"
          />
          <path
            fill="none"
            d="M554.3,224.6c-0.3-0.5-1-0.8-1.9-1.1l-5-1c-0.6-0.2-1.3,0.3-1.6,1.3l-1.3,6c-0.8,2.6,0.5,3.9,3.7,3.9h9.3
	c1.3,0,1.6-0.5,1.1-1.6L554.3,224.6L554.3,224.6z M507.4,240.8l-6,11l-3.6,6.7l-0.6,1.5c11-0.8,22.1-1.9,33.1-3.6l0.2-0.3
	c1.5-4.9,2.9-9.9,4.2-15.1h-27.3L507.4,240.8L507.4,240.8z M550.1,252.4l1.8-0.5c2.3-0.6,4.2-1.5,6.3-2.8c2.3-2.6,4.2-5.5,5.8-8.8
	h-13.8v12L550.1,252.4L550.1,252.4z M557.7,243.8c0.3,0,0.6,0.2,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8
	c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8s0.2-0.6,0.3-0.8C557,243.9,557.4,243.8,557.7,243.8
	L557.7,243.8z"
          />
          <path
            fill="none"
            d="M558.5,244.1c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8
	c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.2,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8S558.7,244.4,558.5,244.1L558.5,244.1z"
          />
          <path
            fill="none"
            d="M552.5,252.8l-2.3,0.6c-5.4,1.5-11.2,2.6-17.5,3.2l-1,2.9c7-1,13.1-2.4,18.5-4.1l2.3-0.8
	c3.4-1.1,6.5-2.9,9.3-5.4c2.1-1.8,4.1-4.9,5.7-8.9l-0.3-0.6c-1.3,3.6-3.1,6.2-5.4,8C559,250.2,555.7,251.9,552.5,252.8L552.5,252.8z
	"
          />
          <path
            fill="none"
            d="M552.5,254.8l-2.3,0.8c-5.4,1.6-11.5,3.1-18.5,4.1l-0.8,2.8c-0.2,0.5,0.2,0.8,1,0.6c12.7-2.6,22.6-5.7,29.9-9.1
	l0.5-0.2c4.1-2.4,5.8-6.8,5.4-13.1c-1.6,4.1-3.4,7-5.7,8.9C559,251.9,555.7,253.7,552.5,254.8L552.5,254.8z"
          />
          <path
            fill="none"
            d="M550.1,252.4v-12h-14.3c-1.5,5.4-2.9,10.6-4.4,15.3C538.5,255,544.9,254,550.1,252.4z M543.6,245.5
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3
	c0.3-0.3,0.8-0.5,1.3-0.5C542.8,244.9,543.2,245.2,543.6,245.5L543.6,245.5z"
          />
          <path
            fill="none"
            d="M544.1,246.8c0-0.5-0.2-1-0.5-1.3s-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3
	s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5C543.9,247.6,544.1,247.3,544.1,246.8L544.1,246.8z M543.4,246.8
	c0,0.3-0.2,0.6-0.3,0.8c-0.2,0.3-0.5,0.3-0.8,0.3c-0.3,0-0.7-0.2-0.8-0.3s-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8c0.3-0.2,0.5-0.3,0.8-0.3
	c0.3,0,0.7,0.2,0.8,0.3C543.4,246.2,543.4,246.5,543.4,246.8L543.4,246.8z"
          />
          <path
            fill="none"
            d="M543.1,247.6c0.3-0.2,0.3-0.5,0.3-0.8s-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.7,0.2-0.8,0.3
	c-0.2,0.3-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3C542.6,248,542.9,247.8,543.1,247.6L543.1,247.6z M564.3,220.7
	c0.7,1,1.5,1.9,2.3,2.9l3.4,3.6l2.8,2.9l-1.5-3.9l-8.4-9.4l-2.4-2.9l1.3,2.3C562.4,217.9,563.4,219.4,564.3,220.7L564.3,220.7z
	 M581.5,242.1l-1.3-0.5l-1.5-0.5l1.3,4.4v1.9l3.6,1.1l0.8,0.3l0.2-1.1v-1.3c0-1.6-0.5-2.9-1.6-3.7l-0.2-0.2l-0.3-0.2L581.5,242.1
	L581.5,242.1z"
          />
          <path
            fill="none"
            d="M580.2,241.6l1.3,0.5V242c-3.6-5.7-6.7-9.6-8.9-11.9l-2.8-2.9c3.9,5.8,6.7,11.5,8.4,17.2l0.5,0.3
	c0.3,0.3,0.8,0.5,1.1,0.8l-1.3-4.4l-0.6-2.3C579,239.7,579.6,240.5,580.2,241.6L580.2,241.6z"
          />
          <path
            fill="none"
            d="M582.8,242.6l0.3,0.2l0.5,0.2c-0.8-7.6,1.5-11.5,6.7-11.7c10.7,0.2,20.5,2.6,29.1,6.8c1.1,0.5,1.5,1.6,1,3.2
	l-2.6,6.7l-0.7,1.1c-2.9,4.5-8.4,7-16.4,7.5h-4.9c-3.7,0.2-6.5-0.5-8.1-2.3l0.6,1.9c1.1,1.3,3.6,1.9,7.3,1.9h5.4
	c8.8-0.3,14.8-3.1,18.2-8.1l0.7-1.3l1.9-4.7l0.3-1.9V242c0.2-2.4-0.8-4.2-2.9-5.2c-8.6-4.1-18.3-5.8-29.1-5.7
	c-4.5,0.3-7.1,2.1-8,5.4c-0.5,1.6-0.8,3.4-1,5.4v0.3l0.2,0.2l1,0.3L582.8,242.6L582.8,242.6L582.8,242.6z"
          />
          <path
            fill="none"
            d="M581.5,242v0.2h0.2 M583.7,243.1l-0.5-0.2H583c1.1,0.8,1.6,2.1,1.6,3.7v1.3l-0.2,1.1l0.2,0.2
	c0.3,0.2,0.6,0.3,0.8,0.6l0.5,0.6l0.3-0.6l0.2-1v-1.5c0-1-0.2-1.6-0.5-2.3C585.6,244.1,584.8,243.4,583.7,243.1L583.7,243.1z
	 M583.2,242.8l-0.2-0.2"
          />
          <path
            fill="none"
            d="M590.5,231.3c-5.2,0.3-7.5,4.2-6.7,11.7c1.1,0.5,1.8,1.1,2.3,1.9l-0.2-1.9c-0.7-6.8,1.3-10.4,6-10.6
	c9.7,0.2,18.3,2.3,26.3,6.2c1,0.5,1.3,1.5,0.8,2.9l-2.4,6l-0.7,1c-2.8,4.1-7.6,6.3-14.8,6.7H597c-3.4,0.2-6-0.3-7.6-1.8
	c-1.3-1.5-2.3-3.4-2.8-5.8v1l-0.2,1l-0.3,0.6l-0.3,0.5c0.8,0.6,1.5,1.6,2.1,3.2c1.8,1.8,4.4,2.6,8.1,2.3h4.9
	c8-0.3,13.3-2.9,16.4-7.5l0.7-1.1l2.6-6.7c0.5-1.6,0.2-2.8-1-3.2C610.9,233.9,601.2,231.6,590.5,231.3L590.5,231.3z"
          />
          <path
            fill="none"
            d="M585.9,245.1c0.3,0.6,0.5,1.5,0.5,2.3v0.5c0.6,2.4,1.5,4.4,2.8,5.8c1.8,1.3,4.2,1.9,7.6,1.8h4.2
	c7.1-0.3,12-2.6,14.8-6.7l0.7-1l2.4-6c0.5-1.5,0.2-2.4-0.8-2.9c-7.8-3.9-16.6-6-26.3-6.2c-4.7,0.2-6.8,3.7-6,10.6L585.9,245.1
	L585.9,245.1z M582.5,250.4l-1.8,1l0.5,1.9l2.4-1.5v-0.5C583.5,250.4,583.2,250.1,582.5,250.4z"
          />
          <path
            fill="none"
            d="M580.7,251.2l1.8-0.8l1.9-1v-0.3l-0.8-0.3l-3.6-1.1v0.5 M584.5,249.4l0.2-0.2l-0.2-0.2"
          />
          <path
            fill="none"
            d="M584.6,249.3l-0.3,0.2l-1.8,1c0.6-0.2,1,0.2,1.1,0.8v0.5l-2.4,1.5l0.2,1.1l3.4-2.3l1-1l0.3-0.5l-0.5-0.6
	C585.3,249.6,585,249.4,584.6,249.3L584.6,249.3z"
          />
          <path
            fill="none"
            d="M584.6,257.2c-0.5-1.5-1-2.4-1.8-2.9l-0.2-0.3l0.3,0.3C583.7,255,584.3,255.9,584.6,257.2l2.9,8.8l1,3.9
	l1.5-8.9l-0.2-0.5l-1.5-4.5l-0.6-1.9c-0.5-1.6-1.3-2.6-2.1-3.2l-1,1l-3.4,2.3l0.6,2.9l0.3,1.6l2.4,11c0.6,3.2,1.8,6.2,3.2,9.1
	l1.5,3.2l-0.3-7.5l-0.2-0.6l-0.2-1.1l-0.5-2.8l-1-3.9L584.6,257.2L584.6,257.2z"
          />
          <path
            fill="none"
            d="M582.2,257.2l-0.7-2.9l-0.3-1.1l-0.5-1.9l-0.7-3.1v-1l-1.8-1.5l3.7,11.7 M582.2,257.2l-0.2,0.2l0.5,1.3
	 M600.5,258h-4.7c-3.7,0.2-6.2-0.5-7.3-1.9l1.5,4.5l2.3,1.3c0.5,0.3,1,0.6,1.3,1.1l2.4,0.5l1.6-3.4c0.2-0.3,0.3-0.6,0.6-1
	C598.9,258.5,599.7,258.2,600.5,258L600.5,258z M578.1,239l0.6,2.3l1.5,0.5C579.6,240.5,579,239.7,578.1,239L578.1,239z
	 M572.1,241.3l0.2,0.5c2.3,1.3,4.2,2.6,6,3.9l0.5-1l-0.5-0.3C576.5,243.1,574.4,242.1,572.1,241.3L572.1,241.3z"
          />
          <path
            fill="none"
            d="M572.3,241.8l-0.2-0.5c-0.3-1.3-0.8-2.6-1.3-4.1c1.1,3.1,1.3,5.8,0.7,8.3c-0.7,2.4-2.3,4.7-4.5,6.7l7.6,15.7
	c0.3-6.5-0.2-13.6-1.5-21.4C572.9,245.1,572.6,243.4,572.3,241.8L572.3,241.8z M565.8,240.5H564c-1.6,3.1-3.6,6-5.8,8.8l3.1-2.3
	C563.2,245.2,564.8,243.1,565.8,240.5z M580.1,247.2v-1.5c-0.3-0.3-0.8-0.6-1.1-0.8l-0.5,1L580.1,247.2L580.1,247.2z"
          />
          <path
            fill="none"
            d="M578.3,245.7c-1.8-1.5-3.7-2.8-6-3.9c0.3,1.6,0.7,3.2,0.8,4.9c1.3,7.8,1.8,14.9,1.5,21.4l6,22.1l4.5-20.1
	l-2.4-11l-0.5-1.5L578.3,245.7L578.3,245.7z M596,263.6l-2.4-0.5c0.7,0.8,1.1,1.8,1.3,2.9L596,263.6L596,263.6z"
          />
          <path
            fill="none"
            d="M593.4,263.1c-0.3-0.5-0.8-0.8-1.3-1.1c1,1,1.5,2.4,1.5,4.1v3.2c0.3,0.5,0.7,1,0.8,1.5c0.2-0.5,0.3-0.8,0.3-1.3
	V266C594.5,264.9,594.2,263.9,593.4,263.1L593.4,263.1z"
          />
          <path
            fill="none"
            d="M592.3,268.1c0.3,0.2,0.7,0.5,1,0.8l0.3,0.3V266c0-1.6-0.5-3.1-1.5-4.1l-2.3-1.3l0.2,0.5l0.7,1.9l-0.5,3.7
	L592.3,268.1L592.3,268.1z"
          />
          <path
            fill="none"
            d="M593.2,268.9c-0.3-0.3-0.6-0.6-1-0.8l-1.9-1.1l-0.6,3.6l1.6-0.6L593.2,268.9L593.2,268.9z"
          />
          <path
            fill="none"
            d="M593.6,269.2l-0.3-0.3l-1.9,1v1.8l-1.9,1v1.3l2.3-1.1c1.5-0.5,2.3-1.3,2.8-1.9
	C594.2,270.2,593.9,269.7,593.6,269.2L593.6,269.2z M590.8,263.1l-0.7-2l-1.5,8.9l0.5,2.8l0.2-0.2c0-0.6,0.2-1.5,0.3-2.1l0.6-3.6
	L590.8,263.1L590.8,263.1z"
          />
          <path
            fill="none"
            d="M591.3,269.9l-1.6,0.6c-0.2,0.6-0.2,1.5-0.3,2.1l1.9-1L591.3,269.9L591.3,269.9z M589.3,272.6l-0.2,0.2l0.2,1.1
	v-0.2"
          />
          <path
            fill="none"
            d="M591.3,273.6v-0.5l0.2-0.5l-2.3,1.1v0.8l0.3,7.5c0.5,1.1,0.8,2.4,1.1,3.6l0.2,1
	C591.3,282.4,591.3,278,591.3,273.6L591.3,273.6z M589.3,273.9v0.6v-0.8"
          />
          <path
            fill="none"
            d="M591,286.8l-0.2-1c-0.3-1.3-0.7-2.4-1.1-3.6c-1.1,5.4-2.3,10.4-3.7,15.3l5.5,16.7l1.3-6l1.3-6
	c-0.2-1.3-0.3-2.4-0.7-3.7L591,286.8L591,286.8z"
          />
          <path
            fill="none"
            d="M589.7,282.1l-1.5-3.2c-1.5-2.9-2.6-5.8-3.2-9.1l-4.5,20.1c1.8,3.1,3.6,5.5,5.5,7.3
	C587.2,292.4,588.5,287.4,589.7,282.1L589.7,282.1z M593.9,306.1c0.2-1.3,0.2-2.6,0.2-4.1l-1.3,6c-0.8,13.8-2.1,26.8-3.6,39.1v20.9
	l3.7-8.9l-1.3-6.5c1.3-9.9,1.8-20,1.9-30.5L593.9,306.1L593.9,306.1z"
          />
          <path
            fill="none"
            d="M592.6,308.2l-1.3,6l-2.4,11.4v21.9C590.6,335.1,591.8,322,592.6,308.2L592.6,308.2z M585.9,297.3
	c-1.9-1.8-3.7-4.2-5.5-7.3c-1.6,6.2-3.4,12-5.5,17.5c-1.8,4.7-5.8,7.8-12.3,8.9c-9.1,1-18.7,1.3-28.9,0.8l-9.6-0.2
	c-0.2,1.6,3.4,2.9,10.9,3.7l30.4,2.3c7.6-0.3,12.7-3.4,14.9-9.1C582.2,308.7,584.1,303.2,585.9,297.3L585.9,297.3z M593.4,363.2
	l-0.6-3.9l-3.7,8.9v6.7l3.7-6C593.7,367.4,593.9,365.5,593.4,363.2L593.4,363.2z"
          />
          <path
            fill="none"
            d="M589,368.2v-42.8l-8.9,42.9l-0.5,2.3c-0.5,2.3-1.5,4.2-2.6,6l-7.1,10.1h-7.1l-1-0.8h-14v50.8
	c0,5.5,2.9,8.4,8.6,8.4h24c5.8,0,8.8-2.8,8.8-8.4v-61.5L589,368.2z"
          />
          <path
            fill="none"
            d="M579.6,370.5l0.5-2.3c-0.3,1.5-0.8,2.8-1.5,3.9l-10.2,14.3h1.5l7.1-10.1C578.1,374.9,579.1,372.8,579.6,370.5
	L579.6,370.5z M562.6,386.6h5.7c-5.7-2.4-12.8-4.5-21.1-6H547l-1.9-0.3c-7.6-1.3-16.4-2.1-26.3-2.6l-8.4-0.3
	c-29.2-0.5-58.4-0.6-87.8-0.6h-3.2c-29.2,0-58.4,0.2-87.8,0.6l-8.4,0.3c-9.7,0.5-18.5,1.3-26.3,2.6l-1.9,0.3h-0.2
	c-8.4,1.5-15.6,3.4-21.3,6h5.7l1-0.8h33.3v3.1h-19v8.8l8.4,0.3l16.4,2.9h203.5l16.4-2.9l8.4-0.3v-8.8h-19v-3.1h32.9L562.6,386.6
	L562.6,386.6z"
          />
          <path
            fill="none"
            d="M568.2,386.6l10.2-14.3c-12.2-3.4-24.7-5.8-37.8-7c2.6,4.5,4.9,9.6,6.3,15.4
	C555.4,382.2,562.6,384.1,568.2,386.6z"
          />
          <path
            fill="none"
            d="M540.6,365.3l-0.2-0.3H538c3.7,4.5,6.8,9.7,8.8,15.7h0.2C545.5,374.9,543.4,369.9,540.6,365.3L540.6,365.3z"
          />
          <path
            fill="none"
            d="M538,365h2.4c-10.1-16.9-28.2-25.5-54.4-25.8c-21.1-0.6-42.2-1.1-63.5-1h-3.2c-21.3,0-42.4,0.3-63.5,1
	c-26.1,0.5-44.3,9.1-54.4,25.8h2.4c10.4-12.2,27.3-18.8,50.6-19.6c21.6-0.6,43.2-1.1,64.8-1h3.2c21.8,0,43.3,0.3,64.8,1
	C510.6,346.2,527.5,352.7,538,365L538,365z"
          />
          <path
            fill="none"
            d="M538,365c-6-0.6-12.3-1.3-19.3-1.6l-6.3-0.3c-30.2-0.5-60.1-0.6-89.9-0.6h-3.2c-29.7,0-59.7,0.3-89.9,0.6
	l-6.3,0.3c-7,0.3-13.5,1-19.3,1.6c-3.7,4.5-6.8,9.7-8.8,15.7l1.9-0.3c7.6-1.3,16.4-2.1,26.3-2.6l8.4-0.3c29.2-0.5,58.4-0.6,87.8-0.6
	h3.2c29.2,0,58.4,0.2,87.8,0.6l8.4,0.3c9.9,0.5,18.7,1.3,26.3,2.6l1.9,0.3C544.7,374.6,541.8,369.4,538,365L538,365z"
          />
          <path
            fill="none"
            d="M512.4,363l6.3,0.3c7,0.3,13.5,1,19.3,1.6c-10.4-12.2-27.3-18.8-50.6-19.6c-21.6-0.6-43.2-1.1-64.8-1h-3.3
	c-21.8,0-43.3,0.3-64.8,1c-23.4,0.8-40.3,7.5-50.6,19.6c5.8-0.6,12.3-1.3,19.3-1.6l6.3-0.3c30.2-0.5,60.1-0.6,89.9-0.6h3.2
	C452.3,362.4,482.2,362.6,512.4,363L512.4,363z M528.6,385.8v3.1h19v-3.1 M317.8,317.1l-9.6,0.2c-10.2,0.3-20,0-28.9-0.8
	c-6.5-1.1-10.7-4.1-12.3-8.9c-2.1-5.5-3.9-11.4-5.5-17.5c-1.6,3.1-3.6,5.5-5.5,7.3c1.8,5.8,3.6,11.4,5.8,16.6
	c2.3,5.7,7.1,8.8,14.9,9.1l30.4-2.3C314.4,320,318,318.7,317.8,317.1L317.8,317.1z M301.4,365l-0.2,0.3c-2.6,4.5-4.9,9.6-6.3,15.4
	h0.2c2.1-6,5-11.2,8.8-15.7L301.4,365L301.4,365z M280.3,385.8l-1,0.8h-7.1l-7.1-10.1c-1.3-1.8-2.3-3.7-2.8-6l-0.5-2.3l-9.1-42.9
	v111c0,5.5,2.9,8.4,8.8,8.4h24c5.8,0,8.6-2.8,8.6-8.4v-50.8L280.3,385.8L280.3,385.8z M294.3,385.8v3.1h19v-3.1"
          />
          <path
            fill="none"
            d="M294.9,380.7c1.5-5.7,3.7-10.9,6.3-15.4c-13.1,1.1-25.6,3.6-37.8,7l10.2,14.3
	C279.3,384.1,286.5,382.2,294.9,380.7z M247.8,302.2c-0.2,1.3,0,2.6,0.2,4.1l0.3,16.2c0,10.4,0.6,20.6,1.9,30.5l-1.3,6.5l3.7,8.9
	v-20.9c-1.6-12.3-2.8-25.3-3.6-39.1L247.8,302.2L247.8,302.2z"
          />
          <path
            fill="none"
            d="M250.4,314.2l-1.3-6c0.8,13.8,2.1,26.8,3.6,39.1v-21.9L250.4,314.2z M263.4,372.3c-0.6-1.1-1.1-2.4-1.5-3.9
	l0.5,2.3c0.5,2.3,1.5,4.2,2.8,6l7.1,10.1h1.5L263.4,372.3L263.4,372.3z M248.3,363.2c-0.5,2.3-0.2,4.2,0.6,5.7l3.7,6v-6.7l-3.7-8.9
	L248.3,363.2L248.3,363.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M550.1,240.5h14 M567.1,239.9l-11-20.3 M558.2,249.1
	c2.3-2.6,4.2-5.5,5.8-8.8 M558.2,249.1l3.1-2.3c2.1-1.6,3.6-3.7,4.7-6.5 M567.4,240.5l-0.3-0.6 M564,240.5h1.8 M557.7,243.8
	c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.2,0.8-0.3
	c0.2-0.2,0.3-0.5,0.3-0.8s-0.2-0.6-0.3-0.8C558.3,243.9,558,243.8,557.7,243.8L557.7,243.8z M601,258h0.2 M531.4,255.8
	c7.1-0.6,13.3-1.8,18.7-3.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M531.6,259.5l-0.8,2.8c-0.2,0.5,0.2,0.8,1,0.6c12.7-2.6,22.6-5.7,29.9-9.1
	l0.5-0.2c4.1-2.4,5.8-6.8,5.4-13.1 M530.4,255.8h1 M531.4,255.8c1.5-4.9,2.9-9.9,4.4-15.3h14.3 M550.1,240.5v11.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M567.4,240.5c-1.6,4.1-3.4,7-5.7,8.9c-2.8,2.4-6,4.2-9.3,5.4l-2.3,0.8
	c-5.4,1.6-11.5,3.1-18.5,4.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M567.1,239.9c-1.3,3.6-3.1,6.2-5.4,8c-2.8,2.4-6,4.1-9.3,5l-2.3,0.6
	c-5.4,1.5-11.2,2.6-17.5,3.2l-1,2.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M550.1,252.4l1.8-0.5c2.3-0.6,4.2-1.5,6.3-2.8 M543.6,245.5
	c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5
	s0.5-0.8,0.5-1.3S543.9,245.9,543.6,245.5L543.6,245.5z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M543.4,246.8c0-0.3-0.2-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
	c-0.3,0-0.7,0.2-0.8,0.3c-0.2,0.3-0.3,0.5-0.3,0.8s0.2,0.6,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3c0.3,0,0.7-0.2,0.8-0.3
	C543.4,247.3,543.4,247.2,543.4,246.8L543.4,246.8z M549.9,204.6l-4.2-7.6 M545.7,197l-1.1,6.3 M552.3,221.5
	c1.3,0.3,2.3,0.8,2.6,1.5l5.7,10.7c0.7,1.6,0.2,2.4-1.5,2.4h-12.7c-4.5,0-6.2-1.9-5.2-5.7l1.9-8.4c0.5-1.3,1.1-1.9,2.3-1.8
	L552.3,221.5L552.3,221.5z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M554.8,224l4.9,8.8c0.5,1.3,0.2,1.9-1.3,1.9h-10.7c-3.7,0-5.4-1.6-4.4-4.5
	l1.6-7c0.3-1.1,1-1.6,1.9-1.5l6,1.1C553.6,223.1,554.4,223.5,554.8,224L554.8,224z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M554.3,224.6l4.1,7.6c0.5,1.1,0.2,1.6-1.1,1.6H548c-3.2,0-4.5-1.3-3.7-3.9
	l1.3-6c0.3-1,1-1.5,1.6-1.3l5,1C553.3,223.8,554,224.1,554.3,224.6L554.3,224.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M555.6,222.5l6.2,11.9c0.6,1.8,0.2,2.6-1.6,2.6H546c-5,0-6.8-2.1-5.7-6.2
	l2.1-9.4c0.5-1.5,1.3-2.1,2.4-1.9l7.8,1.5C554.1,221.2,555.1,221.7,555.6,222.5L555.6,222.5z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M565.8,240.5l0.2-0.6l-9.6-18.5c-0.6-1.5-1.9-2.4-3.6-2.9l-12.7-2.4
	 M556.1,219.6l-0.3-0.6c-1-1.9-2.6-3.2-4.7-4.2l-8.3-2.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M543.9,218.1l8.6,1.6c1.6,0.3,2.8,1,3.2,1.9l7,13.5
	c0.8,1.9,0.2,2.9-1.8,2.9h-14c-6.8,0-9.6-2.3-8.3-7l2.4-10.7C541.8,218.8,542.6,217.9,543.9,218.1L543.9,218.1z M544.5,203.3
	l-0.3,1.5 M544.2,204.8l-0.3,1.9 M556.1,219.6c-0.5-0.8-1.5-1.5-3.1-1.9l-9.7-1.9c-0.8-0.3-1-0.8-0.8-1.6l0.2-0.8v-0.2l0.2-0.3
	 M543.9,206.7l-0.3,1.8 M551.2,206.7l-0.6-0.3c-1.6-0.8-3.7-1.3-6.3-1.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M542.8,212.8c0.3-0.5,1-0.6,2.1-0.5l6.2,1.5c2.1,0.6,3.9,1.9,5.2,3.7
	l12.5,23c0.5,6.5-1.5,10.9-5.8,13.5c-8.1,4.1-19.8,7.5-35.2,10.2h-0.3 M551.2,206.7l1.1,2.1 M553.5,210.6l1.6,3.1 M543.6,208.5
	c-0.2,1,0.3,1.6,1.1,1.9c3.4,0.8,6.8,1.8,9.7,2.9l0.6,0.2 M555.1,213.7l1.8,0.6 M543.9,206.7c4.2,0.6,6.8,1.3,7.8,1.8l0.7,0.3
	 M552.3,208.9l1,0.6c0.2,0.3,0.3,0.6,0.3,1l-0.2,0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M553.5,210.6l-0.5,0.2h-0.2c-2.8-1.3-5.8-1.9-9.3-2.3 M544.5,203.3
	c1.9,0.3,3.6,0.6,4.9,1.1l0.6,0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M549.9,204.6l0.3,0.2c1,0.5,1.5,1,1.5,1.8c-0.2,0.3-0.3,0.5-0.6,0.3
	 M546,195.1l-0.3,1.9 M274.8,239.9l10.9-20.3 M291.7,252.4c5.4,1.5,11.5,2.6,18.7,3.4 M291.7,240.5v11.8 M277.9,240.5
	c1.6,3.1,3.6,6,5.8,8.8 M291.7,240.5h-13.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M274.5,240.5c1.6,4.1,3.4,7,5.7,8.9c2.9,2.4,6,4.2,9.3,5.4l2.3,0.8
	c5.4,1.6,11.5,3.1,18.5,4.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M283.7,249.1c1.9,1.3,4.1,2.1,6.3,2.8l1.8,0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M310.2,259.5l-1-2.9c-6.3-0.6-12.2-1.6-17.5-3.2l-2.3-0.6
	c-3.4-1-6.5-2.6-9.3-5c-2.1-1.8-3.9-4.4-5.4-8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M275.9,240.5c1.1,2.8,2.6,4.9,4.7,6.5c1,0.8,1.9,1.6,3.1,2.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M274.5,240.5c-0.5,6.2,1.3,10.7,5.4,13.1l0.5,0.2
	c7.1,3.4,17.2,6.5,29.7,9.1c1,0.2,1.3-0.2,1-0.6l-0.8-2.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M285,244.1c0.2,0.2,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8
	c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8s0.2-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	S284.8,243.9,285,244.1z M274.8,239.9l-0.3,0.6 M277.9,240.5h-1.9 M310.3,255.8c-1.5-4.9-2.9-9.9-4.2-15.3h-14.4 M310.3,255.8h1.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M300.4,246c0.2,0.3,0.3,0.5,0.3,0.8s-0.2,0.6-0.3,0.8
	c-0.3,0.3-0.5,0.3-0.8,0.3s-0.6-0.2-0.8-0.3c-0.3-0.2-0.3-0.5-0.3-0.8s0.2-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	C299.9,245.7,300.1,245.7,300.4,246L300.4,246z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M301.4,246.8c0,0.5-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5
	s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3s0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5S301.4,246.3,301.4,246.8L301.4,246.8z M292,204.6l4.2-7.6
	 M297.3,203.3l-1.1-6.3 M298.3,208.5l-0.3-1.8 M298,206.7l-0.3-1.9 M301.4,215.8l-12.5,2.4c-1.6,0.6-2.8,1.6-3.4,2.9l-9.6,18.5
	l0.2,0.6 M299.1,212.8l-8.3,2.1c-2.1,0.8-3.7,2.3-4.7,4.2l-0.3,0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M285.7,219.6c0.5-0.8,1.5-1.5,3.1-1.9l9.7-1.9c0.8-0.3,1-0.8,0.6-1.6
	l-0.2-0.8v-0.2l-0.2-0.3 M286.8,223l-5.7,10.7c-0.6,1.6-0.2,2.4,1.5,2.4h12.7c4.5,0,6.2-1.9,5.2-5.7l-1.9-8.4
	c-0.5-1.3-1.3-1.9-2.3-1.8l-7,1.3C288.1,221.8,287.3,222.3,286.8,223L286.8,223z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M287.6,224.6l-4.1,7.6c-0.5,1.1-0.2,1.6,1.1,1.6h9.2
	c3.2,0,4.5-1.3,3.7-3.9l-1.5-6c-0.3-1-1-1.5-1.6-1.3l-5,1C288.6,223.8,287.9,224.1,287.6,224.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M287.1,224l-4.9,8.8c-0.5,1.3-0.2,1.9,1.3,1.9h10.7c3.7,0,5.4-1.6,4.4-4.5
	l-1.6-7c-0.3-1.1-1-1.6-1.8-1.5l-6,1.1C288.1,223.1,287.4,223.5,287.1,224L287.1,224z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M286.3,222.5l-6.2,11.9c-0.6,1.8-0.2,2.6,1.6,2.6h14.1
	c5,0,6.8-2.1,5.7-6.2l-2.1-9.4c-0.5-1.5-1.3-2.1-2.4-1.9l-7.8,1.5C287.8,221.2,286.8,221.7,286.3,222.5z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M286.1,221.7l-7,13.5c-0.8,1.9-0.2,2.9,1.8,2.9h13.8c6.8,0,9.6-2.3,8.3-7
	l-2.4-10.7c-0.6-1.6-1.5-2.4-2.8-2.3l-8.6,1.6C287.8,220.2,286.6,220.9,286.1,221.7L286.1,221.7z M290.7,206.7l-1.1,2.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M299.1,212.8c-0.3-0.5-1-0.6-2.1-0.5l-6.2,1.5c-2.1,0.6-3.9,1.9-5.2,3.7
	l-12.5,23c-0.5,6.5,1.5,10.9,5.8,13.5c8.1,4.1,19.8,7.5,35.2,10.2h0.3 M288.4,210.6l-1.6,3.1 M286.8,213.7l0.5-0.3
	c3.1-1.1,6.3-2.1,9.7-2.9c1-0.3,1.3-1,1.1-1.9 M284.9,214.4l1.9-0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M298.3,208.5c-3.4,0.2-6.5,1-9.2,2.3h-0.2l-0.3-0.2 M289.6,208.9
	c-0.6,0.3-1,0.5-1,0.6c-0.2,0.3-0.3,0.6-0.3,1l0.2,0.3 M289.6,208.9l0.6-0.3c1-0.5,3.6-1,7.8-1.8 M297.7,204.8
	c-2.6,0.3-4.7,0.8-6.3,1.6l-0.3,0.2c-0.2,0.2-0.2,0.2-0.3,0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M292,204.6l-0.3,0.2c-1,0.5-1.5,1-1.5,1.8c0.2,0.3,0.3,0.5,0.6,0.3
	 M297.7,204.8l-0.3-1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M297.3,203.3c-1.9,0.3-3.6,0.6-4.9,1.1l-0.6,0.3 M296.2,197l-0.3-1.9
	 M240.7,258h0.2 M317.8,149.5c1.1,0.3,1.9,1.8,2.3,4.5l0.2,7.5v1.3 M317.8,149.5l-1.5,0.3c-6.3,1.8-11.2,4.5-14.4,8.4 M301.9,158.4
	c1.5-0.5,1.8,3.1,1.1,10.6c-0.5,5.2-2.4,10.9-5.7,16.7l-1.6,2.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M295.7,188.6c2.9-7,4.5-12.7,4.7-17v-1.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M300.4,169.7l-0.8,1.5c-6.7,13.1-12.8,26.9-18.7,41.2l-1.8,4.5
	 M298.3,164.4c1-0.5,1.5-0.2,1.8,1c0.2,1.5,0.3,2.9,0.3,4.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M301.9,158.4c-1.3,1.5-2.3,3.1-2.9,5l-0.6,1.1 M320.2,162.9
	c0.2,0.5,0.3,1,0.5,1.3v0.2 M270.7,226.2l5.5-13.8c5.8-14.3,12.2-28.1,18.7-41.2l3.4-6.8 M320.7,164.2l0.8,0.5
	c2.3,1,6.8,0.8,13.8-0.5c27.8-4.1,55.8-6.2,84.1-6.3h3.2c28.2,0.2,56.3,2.3,84.1,6.3c7,1.3,11.5,1.5,13.8,0.5l0.8-0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M515.2,162.9c-0.3,0.8-1,1.1-1.9,1l-6.7-1c-27.8-4.1-55.8-6.2-84.1-6.3
	h-3.2c-28.2,0.2-56.3,2.3-84.1,6.3l-6.7,1c-1,0.2-1.6-0.2-1.9-1 M324,148.3c1.3,0.3,1.9,1.9,2.3,4.7l0.2,8.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M545,196.4l-1.5-2.3c-2.8-4.1-5.8-7.8-9.6-10.9
	c-4.7-4.5-10.4-8.1-17.2-10.6c-5.2-1.8-11-3.2-17.5-4.2l-5.8-0.8c-23.4-2.8-47.1-4.2-70.8-4.2h-3.2c-23.7,0-47.4,1.5-70.8,4.2
	l-5.8,0.8c-6.5,1.1-12.3,2.4-17.5,4.2c-6.8,2.4-12.5,6-17.2,10.6c-3.7,3.2-6.8,6.8-9.6,10.9l-1.5,2.3 M326.7,162.9l-0.3-1.3
	 M324,148.3l-1,0.2l-5.2,1 M326.4,161.6c8.9-1.9,16.4-3.2,22.4-4.1h0.2c23.2-2.9,46.7-4.4,70.3-4.5h3.2c23.5,0.2,47.1,1.6,70.3,4.5
	h0.2c6,0.8,13.6,2.3,22.4,4.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M299.9,194.6c2.6-3.7,5.5-7.1,8.9-10.1c4.5-4.4,10.1-7.8,16.7-10.2
	c5.2-1.8,10.9-3.2,17.4-4.2l5.7-0.8c23.4-2.8,46.9-4.2,70.6-4.4h3.2c23.7,0.2,47.2,1.5,70.6,4.4l5.7,0.8c6.5,1.1,12.2,2.4,17.4,4.2
	c6.5,2.3,12,5.7,16.7,10.2c3.4,2.9,6.5,6.3,9.1,10.1 M326.4,161.6l-6.2,1.3 M320.7,164.2l6-1.3 M260.3,242v-0.3
	c-0.2-1.9-0.3-3.7-1-5.4c-0.8-3.2-3.4-5-8-5.4c-10.7-0.2-20.4,1.6-29.1,5.7c-2.1,1.1-3.1,2.8-2.9,5.2v0.2l0.3,1.9l1.9,4.7l0.6,1.3
	c3.2,5,9.4,7.8,18.2,8.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M255.5,247.8c-0.6,2.4-1.5,4.4-2.9,5.8c-1.8,1.3-4.2,1.9-7.6,1.8h-4.4
	c-7.1-0.3-12-2.6-14.8-6.7l-0.6-1l-2.4-6c-0.5-1.5-0.2-2.4,0.8-2.9c7.8-3.9,16.6-6,26.3-6.2c4.7,0.2,6.8,3.7,6,10.6l-0.2,1.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M258.1,243.1c0.8-7.6-1.5-11.5-6.7-11.7c-10.7,0.2-20.4,2.6-29.1,6.8
	c-1.1,0.5-1.5,1.6-1,3.2l2.6,6.7l0.6,1.1c2.9,4.5,8.4,7,16.4,7.5h4.9c3.7,0.2,6.5-0.5,8.1-2.3 M269.6,241.8l0.2-0.5 M269.8,241.3
	c0.3-1.3,0.8-2.6,1.3-4.1 M270.4,245.7c0.3-2.3,1-4.9,2.1-7.3l1.1-2.6c1.8-3.9,3.7-7.8,6.2-12l5.2-9.2l11-19.3l1-1.8 M296.9,189.9
	l-18.7,32.8c-2.3,4.2-4.4,8.1-6.2,12l-1,1.9l-0.2,0.6 M263.1,241.2l0.6-2.3c-0.8,0.6-1.5,1.5-2.1,2.6 M260.2,242.1l-1,0.3l-0.3,0.2
	 M258.9,242.6l-0.2,0.2 M258.7,242.8l0.2-0.2 M258.7,242.8l-0.6,0.3 M261.6,241.6l-1.3,0.5 M260.3,242.1V242 M260.3,242l-0.2,0.2
	 M260.2,242.1h0.2 M261.8,247.2v0.5 M261.8,245.5v1.6 M257.4,249.4l-0.3-0.2 M257.1,249.3l0.3-0.2 M257.4,249.1v0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M257.4,249.1c-0.2-0.3-0.2-0.6-0.2-1.1v-1.3c0-1.6,0.5-2.9,1.6-3.7
	 M261.8,247.6l-3.6,1.1l-0.8,0.3 M259.2,250.4l-1.8-1 M259.2,250.4c-0.6-0.2-1,0.2-1.1,0.8v0.5l2.4,1.5 M261.8,247.6v0.5l-0.8,3.1
	 M261,251.2l-1.8-0.8 M255.8,245.1c-0.3,0.6-0.5,1.5-0.5,2.3v0.5 M255.8,250.4l-0.3-0.6v-1.9 M257.1,249.3l-0.8,0.6l-0.5,0.5
	 M256.1,251.1l-0.3-0.6 M254,254.3c0.6-1.6,1.3-2.6,2.1-3.2 M253.4,256.3l0.6-1.9 M259.7,257.2l0.6-2.9 M259.2,254l-0.3,0.3
	c-0.6,0.6-1.3,1.6-1.8,2.9l-2.9,8.8c-0.3,1.3-0.6,2.6-1,3.9 M260.3,254.3l-3.2-2.3l-1-1 M259.9,257.4l-0.2-0.2 M260.7,253.2
	l-0.3,1.1 M260.7,253.2l0.3-1.9 M263.4,244.4l-0.5,0.3 M266.2,262.6c0.5-2.6,0.8-4.7,1-6.7c0.2-2.3,0-3.6-0.5-3.6s-1.3,1.1-2.1,3.6
	c-1,2.4-1.8,5.4-2.6,8.8c-0.8,3.4-1.3,6.3-1.5,8.6c-0.2,2.4,0,3.6,0.5,3.6s1.3-1.1,2.1-3.6c0.6-1.8,1.3-3.7,1.9-6 M262.9,244.7
	l0.6,1 M269.3,230.1c-2.3,2.3-5.4,6.2-8.9,11.9 M261.8,245.5l1.3-4.4 M262.9,244.7c-0.3,0.3-0.8,0.5-1.1,0.8 M258.1,243.1
	c-1,0.5-1.8,1.1-2.3,1.9 M267.3,267.6c-0.2-1.6-0.2-3.2-0.2-4.9c0.2-5,0.6-10.4,1.5-16.1c0.2-1.6,0.3-3.2,0.8-4.9 M263.6,245.7
	l-3.7,11.7 M261.8,247.2l1.8-1.5 M270.4,245.7c-0.6-2.6-0.5-5.4,0.6-8.3 M261.6,241.6l1.5-0.5 M269.8,241.3
	c-2.3,0.8-4.2,1.8-6.2,3.1 M263.6,245.7c1.8-1.5,3.9-2.8,6-3.9 M240.9,258h0.3 M253.4,256.3c-1.1,1.3-3.7,1.9-7.5,1.9h-4.7
	 M249.6,261.9c-0.5,0.3-1,0.6-1.3,1.1 M248.3,263.1l-2.4,0.5 M248.8,264l-0.2,1.3v1.3c0.2,0.3,0.2,0.5,0.3,0.5
	c0.2,0,0.3-0.2,0.3-0.5l0.2-1.3c0-0.5,0-1-0.2-1.3c0-0.3-0.2-0.5-0.3-0.5C249.1,263.6,249,263.7,248.8,264L248.8,264z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M241.2,258c0.8,0.2,1.6,0.5,2.4,1l0.6,1l1.6,3.4 M251.6,267l-1.9,1.1
	c-0.3,0.2-0.6,0.5-1,0.8 M248.3,269.2l-0.2-0.6V266c0-1.6,0.5-3.1,1.5-4.1 M248.7,268.9l-0.3,0.3 M247.2,266v3.4
	c0,0.5,0.2,0.8,0.3,1.3 M250.6,269.9v1.8l1.8,1 M247.5,270.7c0.5,0.8,1.5,1.5,2.8,1.9 M248.7,268.9l1.9,1 M247.5,270.7
	c0.2-0.5,0.5-1,0.8-1.5 M245.9,263.6l1.3,2.4 M247.2,266c0.2-1.1,0.5-2.1,1.3-2.9 M250.3,272.8l0.2,0.5v0.5c0,4.4,0.2,8.8,0.3,13
	 M266.2,262.6c-0.5,0.2-1,0.3-1.5,0.6c-0.5,0.5-0.8,1.1-0.8,1.8c0,0.6,0.3,1.3,0.8,1.8l0.3,0.3 M267.3,267.9v-0.3 M267.6,267.5
	l-0.3,0.2 M267.6,267.5l-0.3,0.5 M265,267.1l1.1-4.5 M251.7,261.1l0.2-0.5 M251.7,261.1l-0.6,2l0.5,3.9 M252.2,270.5l-0.6-3.6
	 M253.2,270l-0.5,2.8 M252.4,272.6c0-0.6-0.2-1.5-0.3-2.1 M252.7,272.8l-0.3-0.2 M259.4,258.7l-2.4,11.2 M253.2,270l-1.5-8.9
	 M252.2,282.1l0.3-7.1v-0.3 M252.6,274.6v-0.6 M252.6,273.8v0.2 M252.6,274.6v-0.8 M256.9,269.9c-0.6,3.2-1.8,6.2-3.2,9.1l-0.2,0.6
	c-0.5,0.8-0.8,1.6-1.1,2.6 M252.2,282.1c-0.5,1.1-0.8,2.4-1.1,3.6l-0.2,1 M252.4,272.6l0.2,1.1 M252.6,273.9l0.2-1.1 M256.9,269.9
	l2.3,10.4c0.6,3.4,1.5,6.7,2.3,9.7 M574.4,267.9c-0.3,6-1.3,11.4-2.9,16.2c-5.5,13.3-25.8,21.4-61,24c-29.4,1-58.8,1.5-88.1,1.5
	h-3.2c-29.4,0-58.8-0.5-88.1-1.5c-35.2-2.8-55.5-10.7-61-24c-1.6-4.9-2.6-10.2-2.8-16.2 M251.9,260.6l1.5-4.4 M251.9,260.6l-2.3,1.3
	 M252.6,273.8l-2.3-1 M259.7,257.2l-0.3,1.5 M250.6,269.9l1.6,0.6 M259.9,257.4l-0.5,1.3 M270.7,226.2l-1.5,3.9 M272,227.2
	c-3.7,5.8-6.7,11.5-8.4,17.2 M269.3,230.1l2.8-2.9 M325.9,224.9c7,2.4,15.9,3.7,26.6,4.1c22.2,1,44.5,1.5,66.9,1.5h3.2
	c22.2,0,44.6-0.5,66.9-1.5c10.9-0.2,19.6-1.5,26.6-4.1 M304.5,191.3c-1.6,1-3.2,1.9-4.5,3.1 M306.3,190.4l-1.8,1 M298,197.5l1.9-2.9
	 M296.9,193.3v-3.4 M296.9,196.4v-3.1 M272,227.2l3.4-3.6l2.3-2.9c1-1.3,1.8-2.8,2.6-4.2l1.3-2.3 M279,217l2.4-2.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M309.5,210l0.2,0.2c1.9,4.4,4.5,8,7.5,10.4c2.4,1.8,5.5,3.2,8.9,4.4
	 M307.2,240.8c-2.1-8.1-4.1-16.4-5.8-24.8l-0.2-0.3c-1.1-5.8-2.3-11.9-3.4-18 M296.9,189.9l1.5-2.8c6.7-11.2,14-19.1,21.9-24.2
	 M304.5,191.3c1.1,7.5,2.8,13.6,4.9,18.5 M281.4,214.1l14.3-25.5 M296.9,196.4l1.1,1.1 M306.3,190.4c0.2,6.8,1.3,13.3,3.2,19.6
	 M307.2,240.8c1.3,5,2.8,10.1,4.2,15.1l0.2,0.3 M314.6,265.2c-16.7-3.1-29.2-6.8-37.3-11.4l-2.1-1.6 M311.6,256.3l2.6,8.1l0.3,0.8
	 M314.6,265.2c1.1,3.4,2.3,6.7,3.4,10.1c1.5,4.1,3.2,7.8,5,11.4c2.1,4.5,5,8.3,8.8,10.9c4.9,3.2,13.8,5,27.1,5.2
	c20.1,0.5,40.3,0.8,60.4,0.8h3.2c20.1,0,40.3-0.3,60.4-0.8c13.3-0.3,22.4-1.9,27.1-5.2c3.9-2.6,6.8-6.2,8.8-10.9
	c1.8-3.6,3.6-7.5,5-11.4l3.4-10.1 M340.4,251.7l-5.8-10.9 M344.1,258.4l-3.7-6.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M493.4,259.8l-10.5,19.5c-3.6,6.7-9.6,10.1-18.2,10.2h-87.8
	c-8.4-0.2-14.4-3.6-18.2-10.2l-10.5-19.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M344.7,259.8l11.2,20.6c4.1,7.3,10.7,11,20.1,11.4h89.8
	c9.4-0.3,16.1-4.1,20.1-11.4l11.2-20.6 M348.3,259.8h-3.6 M344.7,259.8l-0.6-1.5 M535.6,190.4c-9.6-5.2-23.9-8.9-42.7-11.2
	c-23.4-2.6-46.7-3.9-70.4-3.9h-3.2c-23.7,0-47.1,1.3-70.4,3.9c-18.8,2.3-33.1,6-42.7,11.2 M334.5,240.8l-8.6-15.9 M334.5,240.8
	h-27.3 M311.6,256.3c11,1.6,22.1,2.8,33.1,3.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M359.2,220.9c20.1,1,40.1,1.6,60.1,1.8h3.2c20-0.2,40.1-0.6,60.1-1.8
	c26-1.1,38.8-7.6,38.3-19.8c-1-11.2-13.6-18-37.8-20.6c-20.1-1.9-40.3-2.9-60.5-2.9h-3.2c-20.3,0-40.6,1-60.5,2.9
	c-24.2,2.6-36.8,9.6-37.8,20.6C320.4,213.2,333.2,219.9,359.2,220.9L359.2,220.9z M275,252.2c-2.3-1.9-3.9-4.2-4.5-6.7 M275,252.2
	l-7.3,15.3 M279,217l-8.3,9.3 M524.1,149.5c-1.1,0.3-1.9,1.8-2.3,4.5l-0.2,7.5v1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M539.8,158.4c-3.2-3.9-8.1-6.8-14.4-8.4l-1.5-0.3 M515.5,161.6l0.2-8.4
	c0.3-2.8,1-4.4,2.3-4.7 M515.5,161.6l-0.3,1.3 M521.2,164.2l-6-1.3 M521.2,164.2c0.3-0.5,0.5-0.8,0.5-1.3 M521.7,162.9l-6.2-1.3
	 M524.1,149.5l-6.3-1.1 M571.2,226.2l-5.5-13.8c-6-14.3-12.2-28.1-18.7-41.2l-3.4-6.8 M562.7,217l-1.8-4.5
	c-5.8-14.3-12.2-28.1-18.7-41.2l-0.8-1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M541.5,169.7v1.8c0.2,4.4,1.6,10.1,4.5,17 M543.6,164.4
	c-1-0.5-1.5-0.2-1.8,1c-0.2,1.5-0.3,2.9-0.3,4.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M539.8,158.4c-1.5-0.5-1.8,3.1-1.1,10.6c0.5,5.2,2.4,10.9,5.7,16.7
	l1.6,2.9 M543.6,164.4l-0.6-1.1c-0.8-1.8-1.8-3.4-3.1-5 M423.3,142.3L423,140c-0.5-4.5-0.8-7.6-0.8-9.4v-14c0.2-0.7,0.2-1.1,0.2-1.6
	l-0.2-1.1l-0.3-0.7c-0.3-0.5-0.6-0.7-1-0.7h-0.2c-0.3,0-0.6,0.2-1,0.7h-0.2l-0.2,0.7c-0.2,0.3-0.2,0.6-0.2,1.1l0.2,1.6v14
	c0,1.8-0.3,5-0.8,9.4l-0.3,2.3 M388.6,169.4c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3C387.6,169.6,388.1,169.4,388.6,169.4L388.6,169.4z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M386.1,167h-1.3c-1.1,0-1.5,0.5-1.1,1.8c0.3,1.6,0.8,3.2,1.5,4.9
	c0.3,0.5,1.3,0.8,3.2,0.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M386.1,167c-1,0-1.3,0.6-1,1.8c0.3,1.6,0.8,3.2,1.5,4.9
	c0.2,0.3,0.3,0.5,0.6,0.6l0.3,0.2 M414.9,147.8v2.1c0.3,0.6,1,1,2.1,1.1h7.6c1.1-0.2,1.9-0.6,2.1-1.1v-2.1 M392.6,169.9
	c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5
	s-0.5-0.8-0.5-1.3S392.3,170.4,392.6,169.9L392.6,169.9z M401.2,171.2c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5
	s-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5C400.9,170.4,401.2,170.7,401.2,171.2L401.2,171.2z
	 M403.3,169.9c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5
	s-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3S403,170.4,403.3,169.9z M414.2,169.9c0.3-0.3,0.8-0.5,1.3-0.5s1,0.2,1.3,0.5
	c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
	C413.6,170.7,413.7,170.4,414.2,169.9L414.2,169.9z M411.3,169.9c0.3,0.3,0.5,0.8,0.5,1.3s-0.2,1-0.5,1.3s-0.8,0.5-1.3,0.5
	c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3s0.2-1,0.5-1.3c0.3-0.3,0.8-0.5,1.3-0.5C410.5,169.4,411,169.6,411.3,169.9
	L411.3,169.9z M517.8,148.3c-23.9-2.9-47.6-4.9-71.3-5.7c-6.7-0.3-13.5-0.3-20.1-0.3h-3.2 M418.4,142.3h-2.9
	c-6.7,0-13.5,0.2-20.1,0.3c-23.7,0.8-47.4,2.8-71.3,5.7 M418.6,142.3h-0.2 M426.9,147.8c0-0.8-0.6-1.5-1.5-1.9l-1.8-0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M423.6,145.2l0.2,1.5v0.2c0,0.3-0.3,0.6-0.8,0.8l-0.3,0.2l-1.5,0.2h-0.3
	l-1.5-0.2l-0.3-0.2c-0.6-0.2-0.8-0.5-0.8-0.8v-0.2l0.2-1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M426.9,147.8c-0.3,0.6-1,1-2.1,1.1h-7.6c-1.1-0.2-1.9-0.6-2.1-1.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M418.1,145.2l-1.6,0.8c-1,0.5-1.5,1.1-1.6,1.9 M418.3,145.2h-0.2
	 M419.6,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3
	c-0.3-0.3-0.8-0.5-1.3-0.5S419.9,169.6,419.6,169.9L419.6,169.9z M424.4,171.2c0,0.5,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5
	c0.5,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5
	C424.6,170.4,424.4,170.7,424.4,171.2L424.4,171.2z M430.4,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5
	c0.5,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5C431.1,169.4,430.8,169.6,430.4,169.9L430.4,169.9z
	 M436.9,169.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5
	c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3S437.4,169.4,436.9,169.4L436.9,169.4z M441,169.9c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3
	s0.8,0.5,1.3,0.5s1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5S441.3,169.6,441,169.9z
	 M448.9,169.9c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3s0.2,1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5
	s1-0.2,1.3-0.5s0.5-0.8,0.5-1.3C449.6,170.7,449.4,170.4,448.9,169.9L448.9,169.9z M453.2,174.3c1.8,0,2.9-0.2,3.1-0.8
	c0.6-1.6,1.1-3.2,1.5-4.9c0.3-1.1-0.2-1.8-1.1-1.8h-1.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M451.2,171.2c0,0.5,0.2,1,0.5,1.3s0.8,0.5,1.3,0.5c0.5,0,1-0.2,1.3-0.5
	c0.3-0.3,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5s-1,0.2-1.3,0.5C451.4,170.4,451.2,170.7,451.2,171.2L451.2,171.2
	z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M454.1,174.1h0.2c0.3-0.2,0.6-0.3,0.6-0.6c0.6-1.6,1.1-3.2,1.5-4.9
	c0.3-1.1-0.2-1.8-1-1.8 M423.6,145.2l-0.3-2.9 M418.4,142.3l-0.3,2.9 M418.6,142.3l-0.3,2.9 M455.4,167h-69.3 M454,174.3l0.2-0.2
	 M453.8,174.3h0.2 M453.8,174.3h0.2 M454,174.3l0.2-0.2 M453.2,174.3h0.6 M387.8,174.3h-0.2H387.8 M387.8,174.3h0.6 M388.4,174.3
	h64.8 M537.2,191.3l-1.6-1 M541.9,194.6l1.8,2.9 M541.9,194.6c-1.3-1.1-2.9-2.1-4.5-3.1 M545,193.3l1,1.8l11,19.3l5.2,9.2
	c2.3,4.2,4.4,8.1,6.2,12l1.1,2.6c1,2.6,1.8,5,2.1,7.3 M545,193.3v3.1 M545,189.9v3.4 M545,196.4l-1.3,1.1 M521.7,162.9
	c8,5,15.3,13.1,21.9,24.2l1.5,2.8 M532.4,210v0.2c-1.9,4.4-4.4,8-7.3,10.4c-2.6,1.8-5.5,3.2-8.9,4.4 M543.7,197.5
	c-1,6.2-2.1,12.2-3.4,18l-0.2,0.3c-1.8,8.4-3.7,16.7-5.8,24.8 M532.4,210c1.9-6.3,3.1-12.8,3.2-19.6 M537.2,191.3
	c-1.1,7.5-2.8,13.6-4.9,18.5 M507.4,240.8l-6,10.9 M501.4,251.7l-3.6,6.7 M497.1,259.8h-3.7 M497.8,258.4l-0.6,1.5 M530.3,256.3
	l0.2-0.3c1.5-4.9,2.9-9.9,4.2-15.1 M527.3,265.2l0.3-0.8l2.6-8.1 M516,224.9l-8.6,15.9 M534.5,240.8h-27.1 M497.1,259.8
	c11-0.8,22.1-1.9,33.1-3.6 M560.3,214.1l1.3,2.3c0.8,1.5,1.6,2.9,2.6,4.2c0.6,1,1.5,1.9,2.3,2.9l3.4,3.6 M571.2,226.2l-8.4-9.3
	 M560.3,214.1l2.4,2.9 M580.2,241.6l1.3,0.5 M583,242.6h-0.2l-0.2-0.2l-1-0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M601.2,258c8.8-0.3,14.8-3.1,18.2-8.1l0.6-1.3l1.9-4.7l0.3-1.9v-0.2
	c0.2-2.4-0.8-4.2-2.9-5.2c-8.6-4.1-18.3-5.8-29.1-5.7c-4.5,0.3-7.1,2.1-8,5.4c-0.5,1.6-0.8,3.4-1,5.4v0.3 M581.5,242.1V242
	 M581.7,242.1h-0.2 M581.5,242l0.2,0.2 M583.7,243.1l-0.5-0.3 M583.2,242.8l-0.2-0.2 M583,242.6l0.2,0.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M587.9,254.3c1.8,1.8,4.4,2.6,8.1,2.3h4.9c8-0.3,13.3-2.9,16.4-7.5
	l0.7-1.1l2.6-6.7c0.5-1.6,0.2-2.8-1-3.2c-8.6-4.4-18.3-6.7-29.1-6.8c-5.2,0.3-7.5,4.2-6.7,11.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M586.1,250.4l0.2-0.6l0.2-1v-1 M586.4,247.8v-0.5c0-1-0.2-1.6-0.5-2.3
	 M580.7,251.2l1.8-0.8 M584.5,249.1l-0.8-0.3l-3.6-1.1 M583.2,242.8c1.1,0.8,1.6,2.1,1.6,3.7v1.3l-0.2,1.1 M584.6,249.3l-0.2,0.2
	 M584.5,249.1v0.3 M584.6,249.3l-0.2-0.2 M586.1,250.4l-0.5-0.6c-0.3-0.3-0.5-0.5-0.8-0.6 M584.5,249.4l-1.9,1 M582.5,250.4
	c0.6-0.2,1,0.2,1.1,0.8v0.5l-2.4,1.5 M582.7,254l0.3,0.3c0.6,0.6,1.3,1.6,1.8,2.9l2.9,8.8l1,3.9 M582.2,257.2l-0.7-2.9 M581.5,254.3
	l3.2-2.3l1-1 M582,257.4l0.2-0.2 M581.5,254.3l-0.3-1.1 M588.5,256.3l-0.7-1.9 M587.9,254.3c-0.7-1.6-1.3-2.6-2.1-3.2"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M585.9,245.1l-0.2-1.9c-0.7-6.8,1.3-10.4,6-10.6
	c9.7,0.2,18.3,2.3,26.3,6.2c1,0.5,1.3,1.5,0.8,2.9l-2.4,6l-0.7,1c-2.8,4.1-7.6,6.3-14.8,6.7h-4.2c-3.4,0.2-6-0.3-7.6-1.8
	c-1.3-1.5-2.3-3.4-2.8-5.8 M585.8,251.1l0.3-0.6 M581.2,253.2l-0.5-1.9 M600.5,258h-4.7c-3.7,0.2-6.2-0.5-7.3-1.9 M600.5,258h0.5
	 M583.7,243.1c1.1,0.5,1.8,1.1,2.3,1.9 M570.8,237.3l-1.1-2.6c-1.8-3.9-3.7-7.8-6.2-12l-18.7-32.8 M578.6,241.2l-0.6-2.3
	c0.8,0.6,1.5,1.5,2.1,2.6 M572.3,241.8l-0.2-0.5 M572.1,241.3c-0.3-1.3-0.8-2.6-1.3-4.1 M527.3,265.2c16.7-3.1,29.2-6.8,37.3-11.4
	l2.1-1.6 M580.1,247.2v-1.6 M580.1,247.6v-0.5 M580.1,247.6v0.5l0.7,3.1 M578.8,244.7l-0.5,1 M580.1,247.2l-1.8-1.5 M578.8,244.7
	c0.3,0.3,0.8,0.5,1.1,0.8 M570.8,237.3c1.1,3.1,1.3,5.8,0.7,8.3 M578.3,244.4c-1.9-1.3-3.9-2.3-6.2-3.1 M580.1,245.5l-1.5-4.4
	 M578.3,245.7c-1.8-1.5-3.7-2.8-6-3.9 M566.9,252.2c2.4-1.9,3.9-4.2,4.5-6.7 M574.4,267.9c0.3-6.5-0.2-13.6-1.5-21.4
	c-0.2-1.6-0.3-3.2-0.8-4.9 M596,263.6l-2.6-0.5 M593.4,263.1c-0.3-0.5-0.8-0.8-1.3-1.1 M596,263.6l1.6-3.4c0.2-0.3,0.3-0.6,0.6-1
	c0.7-0.6,1.5-1,2.4-1 M593.2,268.9c-0.3-0.3-0.6-0.6-1-0.8l-1.9-1.1 M594.4,270.7c0.2-0.5,0.3-0.8,0.3-1.3V266 M593.6,269.2
	l-0.3-0.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M592.3,261.9c1,1,1.5,2.4,1.5,4.1v3.2 M594.4,270.7
	c-0.2-0.5-0.5-1-0.8-1.5 M594.7,266l1.3-2.4 M593.4,263.1c0.7,0.8,1.1,1.8,1.3,2.9 M590.2,261.1l-0.2-0.5 M590.2,267l0.7-3.9l-0.7-2
	 M585,269.9l-2.4-11.2 M588.7,270l0.5,2.8 M591.3,269.9l-1.6,0.6 M589.3,272.6l1.9-1v-1.8 M589.2,272.8l0.2-0.2 M589.3,272.6
	c0-0.6,0.2-1.5,0.3-2.1 M589.7,270.5l0.5-3.6 M588.7,270l1.5-8.9 M591,286.8c0.3-4.4,0.3-8.8,0.3-13v-0.5l0.2-0.5 M589.3,273.9v0.6
	 M589.3,274.6v-0.8 M589.3,273.8v0.2 M591,286.8l-0.2-1c-0.3-1.3-0.7-2.4-1.1-3.6 M589.7,282.1l-1.5-3.2c-1.5-2.9-2.6-5.8-3.2-9.1
	 M589.3,274.6l0.3,7.5 M591.6,272.8c1.5-0.5,2.3-1.3,2.8-1.9 M589.2,272.8l0.2,1.1 M592.3,261.9l-2.3-1.3 M593.2,268.9l-1.9,1
	 M589.3,273.8v-1.1 M591.6,272.8l-2.3,1 M581.5,242c-3.6-5.7-6.7-9.6-8.9-11.9 M566.9,252.2l7.5,15.7 M578.6,241.2l1.6,0.5
	 M582.5,258.7l-0.3-1.5 M582.5,258.7l-0.5-1.3 M590,260.6l-1.5-4.4 M578.3,245.7l3.7,11.7 M572.6,230.1l-1.5-3.9 M569.9,227.2
	c3.9,5.8,6.7,11.5,8.4,17.2 M572.6,230.1l-2.8-2.9 M546.2,188.6l14.1,25.5 M507.4,240.8H334.5 M501.4,251.7h-161 M497.8,258.4H344.1
	 M348.3,259.8h145.1 M593.9,302.2c-0.2-1.3-0.3-2.4-0.7-3.7l-2.3-11.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M592.7,359.3l-1.3-6.5c1.3-9.9,1.8-20,1.9-30.5l0.3-16.2
	c0.2-1.3,0.2-2.6,0.2-4.1 M591.5,314.2l-5.5-16.9 M592.6,308.2l-1.1,6 M593.9,302.2l-1.3,6 M585.9,297.3c-1.9-1.8-3.7-4.2-5.5-7.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M580.4,290c-1.6,6.2-3.4,12-5.5,17.5c-1.8,4.7-5.8,7.8-12.3,8.9
	c-9.1,1-18.7,1.3-28.9,0.8l-9.6-0.2c-0.2,1.6,3.4,2.9,10.9,3.7l30.4,2.3c7.6-0.3,12.7-3.4,14.9-9.1c2.1-5.2,4.1-10.7,5.8-16.6
	 M591.5,314.2l-2.4,11.2 M589,375.1l3.7-6c1-1.5,1.1-3.4,0.6-5.7l-0.6-3.9 M589,347.5v20.8 M592.7,359.3l-3.7,8.9 M568.2,386.6h1.5
	l7.1-10.1c1.3-1.8,2.1-3.7,2.6-6l0.5-2.3 M579.9,368.4c-0.3,1.5-0.8,2.8-1.5,3.9 M547.6,385.8h14l1,0.8h5.7 M589,368.2v6.8
	 M568.2,386.6l10.2-14.3 M589,325.4v22.1 M579.9,368.4l9.1-43 M589,347.5c1.6-12.3,2.8-25.3,3.6-39.1 M574.4,338.1
	c-9.1-2.1-19.6-3.4-31.6-3.9c-3.1-0.3-4.1,0.6-2.9,2.8c4.9,7.5,12,11.8,21.6,13.1C570.3,351.7,574.7,347.6,574.4,338.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M547.6,397.6v39c0,5.5,2.9,8.4,8.6,8.4h24c5.8,0,8.8-2.8,8.8-8.4v-61.5
	 M540.6,365.3l-0.2-0.3 M540.5,365H538 M538,365c-5.8-0.6-12.3-1.3-19.3-1.6l-6.3-0.3c-30.2-0.5-60.1-0.6-89.9-0.6h-3.2
	c-29.7,0-59.7,0.3-89.9,0.6l-6.3,0.3c-7,0.3-13.5,1-19.3,1.6 M547.6,388.9h-19v-3.1h19 M295.1,380.6l1.9-0.3
	c7.6-1.3,16.4-2.1,26.3-2.6l8.4-0.3c29.2-0.5,58.4-0.6,87.8-0.6h3.2c29.2,0,58.4,0.2,87.8,0.6l8.4,0.3c9.9,0.5,18.7,1.3,26.3,2.6
	l2,0.3 M294.3,397.6l8.4,0.2l16.6,2.9h203.4l16.6-2.9l8.4-0.2 M547.6,388.9v-3.1 M547.6,397.6v-8.8 M546.8,380.6l0.2,0.2 M538,365
	c3.7,4.5,6.8,9.7,8.8,15.7 M540.6,365.3c2.6,4.5,4.9,9.6,6.3,15.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M540.5,365c-10.1-16.9-28.2-25.5-54.4-25.8c-21.1-0.6-42.2-1.1-63.5-1
	h-3.2c-21.3,0-42.4,0.3-63.5,1c-26.1,0.5-44.3,9.1-54.4,25.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M538,365c-10.4-12.2-27.3-18.8-50.6-19.6c-21.6-0.6-43.2-1.1-64.8-1h-3.2
	c-21.8,0-43.3,0.3-64.8,1c-23.4,0.8-40.3,7.5-50.6,19.6 M578.5,372.3c-12.2-3.4-24.7-5.8-37.8-7 M547,380.7c8.4,1.5,15.4,3.4,21.1,6
	 M301.9,336.9c-4.9,7.5-12,11.8-21.6,13.1c-8.9,1.6-13.3-2.4-13-12c9.1-2.1,19.6-3.4,31.6-3.9C302.1,334,303,334.8,301.9,336.9
	L301.9,336.9z M256,297.3c1.8,5.8,3.6,11.4,5.8,16.6c2.3,5.7,7.1,8.8,14.9,9.1l30.4-2.3c7.5-0.8,11-1.9,10.9-3.7l-9.6,0.2
	c-10.2,0.3-20,0-28.9-0.8c-6.5-1.1-10.7-4.1-12.3-8.9c-2.1-5.5-3.9-11.4-5.5-17.5 M301.4,365l-0.2,0.3 M303.8,365h-2.4 M294.9,380.7
	l0.2-0.2 M294.3,385.8h-14l-1,0.8h-5.7 M294.3,397.6v-8.8 M294.3,388.9v-3.1 M294.3,388.9h19v-3.1h-19 M295.1,380.6
	c2.1-6,5-11.2,8.8-15.7 M301.2,365.3c-2.6,4.5-4.9,9.6-6.3,15.4 M261.5,290c-1.6,3.1-3.6,5.5-5.5,7.3 M247.8,302.2
	c-0.2,1.3,0,2.6,0.2,4.1l0.3,16.2c0,10.4,0.6,20.6,1.9,30.5l-1.3,6.5 M250.9,286.8l-2.3,11.7c-0.3,1.3-0.5,2.4-0.6,3.7 M249.1,308.2
	l-1.3-6 M250.4,314.2l-1.3-6 M250.4,314.2l5.5-16.9 M252.9,325.4l-2.4-11.2 M263.4,372.3c-0.6-1.1-1.1-2.4-1.5-3.9"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M273.6,386.6h-1.5l-7.1-10.1c-1.3-1.8-2.3-3.7-2.8-6l-0.5-2.3
	 M252.9,347.5v20.8 M249.1,359.3l-0.6,3.9c-0.5,2.3-0.2,4.2,0.6,5.7l3.7,6 M249.1,359.3l3.7,8.9 M252.9,368.2v6.8 M263.4,372.3
	l10.2,14.3 M261.8,368.4l-8.9-43 M249.1,308.2c0.8,13.8,2.1,26.8,3.6,39.1 M252.9,325.4v22.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            d="M252.9,375.1v61.5c0,5.5,2.9,8.4,8.8,8.4h24c5.8,0,8.6-2.8,8.6-8.4v-39
	 M301.2,365.3c-13.1,1.1-25.6,3.6-37.8,7 M273.6,386.6c5.7-2.4,12.8-4.5,21.3-6 M578.3,244.4l0.5,0.3 M256,297.3
	c-1.5-4.9-2.8-9.9-3.7-15.3 M580.4,290l-6-22.1 M585.9,297.3c1.5-4.9,2.8-9.9,3.7-15.3 M261.5,290l5.8-22.1 M585,269.9l-4.5,20.1"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M306.3,190.4
		c0,0,20.3-15.1,114.6-15.1s114.8,15.1,114.8,15.1s0.2,8.9-3.2,19.8c0,0-2.8,6.5-7.3,10.4c0,0-7.1,6.8-30.7,8.3l-6,0.2
		c0,0-37,1.5-67.4,1.5s-47.1-0.6-47.1-0.6l-17.4-0.6L353,229c0,0-15.7,0.2-27.1-4.1c0,0-4.2-1.1-8.9-4.4c0,0-4.2-3.4-7.5-10.4
		C309.5,210.2,306.8,204.3,306.3,190.4z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M537.2,191.3
		c0,0,3.4,2.1,4.5,3.1l1.9,2.9c0,0-3.2,21.1-9.3,43.3c0,0-2.9,10.9-4.4,15.4c0,0-2.9,9.7-6.3,19c0,0-2.8,7.5-5,11.4
		c0,0-3.2,8.4-11,12.2c0,0-6.3,3.6-23.4,3.9l-18.5,0.5c0,0-32.3,0.5-45.1,0.5c0,0-15.1,0-41.1-0.3l-20.8-0.5c0,0-19.2,0.2-27.4-5.2
		c0,0-5.2-2.9-8.8-10.9c0,0-4.2-7.1-11.4-30.4c0,0-2.1-6.7-4.4-15.4c0,0-5.8-21.4-9.3-43.3l1.9-2.9c0,0,2.1-1.8,4.5-3.1
		c0,0,1.6,12.3,5,18.7c0,0,2.3,6.2,7.5,10.6c0,0,3.7,2.8,9.4,4.5c0,0,8,3.1,22.2,3.7l5.2,0.2l13.6,0.5c0,0,26.5,1.1,53.2,1
		c0,0,37.2,0,61.7-1.1l5.7-0.2c0,0,15.4,0.2,27.6-3.9c0,0,5.5-1.8,9.2-4.7c0,0,4.2-3.4,7.3-10.6
		C532.4,209.8,535.6,202.7,537.2,191.3z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M314.4,264.9
		c0,0-20.8-3.1-35.4-10.1c0,0-9.9-4.5-9.1-12.7c0,0,0-3.4,3.1-9.6c0,0,3.1-6.2,5.5-10.6l18.3-32.3v6.5l1.1,1.1c0,0,3.4,20.3,9.1,43
		C307.2,240.5,312.3,258.7,314.4,264.9z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M527.3,265.2
		c0,0,25.6-4.5,37.3-11.4c0,0,5-2.8,6.7-8c0,0,1.3-2.9,0-7.1c0,0-1-2.8-1.8-4.5c0,0-1.6-4.1-5.8-11.5l-9.7-17.2l-9.1-16.2v6.8
		l-1.1,1.1c0,0-3.1,17.2-4.5,24c0,0-2.1,9.7-5.7,23C533.5,244.6,528.6,262.1,527.3,265.2z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M272.2,386.6l-7.5-10.4
		l-1-1.5c0,0-1-1.8-1.5-4.2L250.4,314l5.5-16.7c0,0,3.1-2.6,5.5-7.3l6-22.1c0,0,0.3,8.8,2.8,16.2c0,0,1.9,6.5,12,12.5
		c0,0,13.6,9.3,49,11.5c0,0,38.3,1.5,89.8,1.5c51.5,0,91.1-1.5,91.1-1.5s19.8-1.1,38.1-7.1c0,0,15.1-5,20.3-14.3l1.3-2.6
		c0,0,2.6-7.3,2.9-16.2l5.8,22.1c0,0,2.4,4.7,5.5,7.3l5.5,16.7l-11.8,55.7c0,0,0,2.4-1.6,5.2l-8.1,11.7h-1.9c0,0-11.4-6.2-38.1-8.1
		c0,0-9.9-1-23.7-1.1l-85-0.6L352,377c0,0-26.5,0-37.3,1.1c0,0-23.2,1-40.9,8.4L272.2,386.6L272.2,386.6z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback5DrRear);
