import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup4DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M270.7,217.6c0,0,4.3-13.1,7.6-21.7c3.3-8.7,10.2-25.3,13.9-32.6c3.6-7.3,7.8-13.5,7.8-13.5s-0.1-1.7,1-2.6
	s2.5-1.9,3.2-2.3c0.7-0.6,1.6-0.1,1.6-0.1s9.2-3,21.6-4.1c12.2-1,34.5-2.6,53.2-3.2c18.8-0.6,39.2-0.7,39.2-0.7s32.2-0.1,61.6,1.4
	c29.4,1.5,44.6,3.6,48.7,4.4c4.1,0.8,6.5,2.2,6.5,2.2s0.8-0.3,1.9,0.3c1,0.7,2.9,2.6,3.4,3.2c0.4,0.7,0.3,1.5,0.3,1.5
	s3.2,3.4,10.9,19.7c7.7,16.2,9.8,22.6,12.7,30.5c2.8,8,6.5,18,6.5,18h8.1c2.5,0,2.3,2.1,2.3,2.1l4.3-0.1c0,0,0-0.7,1.1-1.1
	c1.1-0.6,1.9-1.1,1.9-1.1v-12.4c0,0,2.2-4.5,5.8-4.8c3.6-0.3,15.7-0.6,19.4-0.4c3.7,0.1,8.1,2.3,9.4,10.3c1.2,8,1.7,23.7,1.9,31
	c0.3,7.2-0.1,9.5-5.1,10.2c-5,0.7-20.8,1.6-24.5,1.8c-5.2,0.1-5.8-3.3-5.8-3.3c-2.2,0-1.7-0.1-2.2-0.1c-0.8-0.1-1.2-0.3-1.2-0.3
	h-4.4c0,0,0.3,0.7-0.5,1.4c-0.7,0.7-1.8,1-1.8,1s1.7,3.6,3.3,8.7c1.9,5.6,1.9,10,1.9,10s3.8,4.3,4,19.8c0.1,15.5,0.4,24.4-0.4,36.7
	c-1,12.4-1.7,17.2-1.7,17.2c-3.3,24.9-5.2,32.5-14.9,37.4c-5.6,2.9-12.7,3.3-21,2.3l-0.3-0.7l-12.1,0.4l-43.9,2.1l-4.4,1.4l-6.1,0.4
	l-0.7-0.5l-1.1,0.4l-47.9,0.7c-1.1,0-1.5-0.8-2.1-1.4l-2.5-2.5l-19.3,0.1l-2.2,1.8c-0.8,1.1-2.2,1.5-3.9,1.5l-46.9-0.3l-0.8-0.5
	l-1.2,0.7l-6.3-0.3l-1.2-0.8l-2.8-0.5l-43.9-2.2c-3.9-0.1-7.8-0.5-11.7-0.5c-0.4,0-1,0.7-1.4,0.7c-5.6,0.1-9.9,0.4-13.9-0.4
	c-13.2-2.6-18.6-11.1-21.6-43.2c0,0.3-2.2-23.2-2.2-38.7c-0.1-15.4,1.2-23,2.2-25.6c1-2.5,2.5-5.5,2.5-5.5s0.5-5,1.5-9.5
	s1.1-5.4,1.9-6.5s1.6-2.8,1.6-2.8s-1.9,0.4-2.1-1.7v-0.7l-4.3,0.1c0,0-0.4,0.6-1.9,0.6h-2.1c0,0-0.1,3.3-6.1,3.2
	c-5.8-0.1-19.7-1-23.7-1.6c-3.7-0.6-6.1-2.6-5.8-6.6c0.3-4.1,0.8-24.2,1.7-31.6c0.8-7.4,3.6-13.1,9.4-13.2
	c5.9-0.1,16.2,0.1,19.5,0.3c3.3,0.1,5.5,1.6,5.5,6.1V218c0,0,0.8,0.1,2.1,0.4c1.5,0.4,1.5,1.5,1.5,1.5h4.1c0,0,0.3-1.6,2.2-1.8
	C263.4,217.9,270.7,217.6,270.7,217.6L270.7,217.6z M254.6,240.4c0.6,0.6,0.6,1.4,0.6,1.2c0-0.1,4.3,0,4.3,0v-0.1h-4.1
	C255.2,241.5,255.2,241,254.6,240.4z M253.5,240h0.3c0.4,0.1,0.7,0.3,0.8,0.4C254.4,240.3,254.1,240,253.5,240L253.5,240z
	 M251.5,239.6c0,0.1,1,0.3,2.1,0.4C252.7,239.7,251.7,239.6,251.5,239.6L251.5,239.6z M251.9,227.9l-0.4,11.7c0,0.7-0.3,2.2-0.4,4.3
	c-0.1,2.9-0.3,5.2-0.4,5.9h0.3c0.3,0,1.2,0.1,2.1,0.1h1.4c0.6-0.1,0.8-0.3,1-0.4l3.7-0.1h0.6v1c0.1,1.4,1.5,1.4,2.9,1l2.6-11.7
	c0,0-2.2,0.1-3.9,0.3c-1.1,0-1.6,1-1.8,1.4c0.1-0.4,0.5-1.6,2.1-1.6c1.8,0,2.2-0.3,2.9-0.3l0.5,0.1l2.3-8.9h-0.1
	c-0.7,0-4-0.3-5.2-0.4c-1.5-0.3-2.2-0.8-2.3-1.8c-0.1-0.8-0.1-1.5-0.1-1.5h-4c0,0-0.4,0.8-1.5,1c-0.6,0-1,0.1-1.4,0
	C252.3,227.9,252,227.9,251.9,227.9L251.9,227.9z M578,230.4c-1.2,0.1-2.5,0.3-2.8,0.4l-0.1-0.1H575l2.3,9.2l0.8-0.1
	c0.5,0,1.1,0.1,2.5,0.3h0.7c1.5,0.3,1.8,1.7,1.8,1.7h4.1c0.1-0.3,0.4-1.5,1.4-1.6c0.4,0,0.8-0.1,1.1-0.1c0.4-0.1,0.8-0.1,1.1-0.1
	l-0.4-11.8h-1.5c-0.3,0-0.4-0.1-0.6-0.1c-1.1-0.1-1.2-0.7-1.2-0.7l-4,0.3c0,0-0.1,0.7-0.5,1.4c-0.3,0.7-0.7,1.2-1.8,1.4h-0.3
	C579.6,230.2,578.7,230.4,578,230.4L578,230.4z"
          />
          <path
            fill="#5E5E5E"
            d="M551.8,383.3l-0.1-0.3l-12.1,0.4l-43.9,2.1l-4.4,1.4l-6.1,0.4l-0.7-0.5l-1.1,0.4l-47.9,0.7
	c-1.1,0-1.5-0.8-2.1-1.4L431,384l-19.3,0.1l-2.2,1.8c-0.8,1.1-2.2,1.5-3.9,1.5l-46.9-0.3l-0.8-0.5l-1.2,0.7l-6.3-0.3l-1.2-0.8
	l-2.8-0.5l-43.9-2.2c-3.9-0.1-7.8-0.5-11.7-0.5c-0.1,0-0.4,0.1-0.5,0.3l-0.3-0.3c-11.4-0.5-16.5-0.8-20.8-7.1
	c-1.4-2.1-2.1-4.7-3.7-8.3l-2.9-8.3l-2.9-11l-1-5.6l-0.1-1.2l0.1-1.4l0.4-0.8l1.1-0.5l1.4-0.1h3.9l155.4,0.1l155.4-0.1h3.9l1.4,0.1
	l1.1,0.5l0.4,0.8l0.1,1.4l-0.1,1.2l-1,5.6l-2.9,11l-2.9,8.3c-1.5,3.6-2.3,6.2-3.7,8.3c-4.7,7.7-9.4,6.6-20.8,7.1L551.8,383.3
	L551.8,383.3z"
          />
          <path
            fill="#FFFFFF"
            d="M579.3,217.7h1c2.5,0,2.3,2.1,2.3,2.1l4.3-0.1c0,0,0-0.7,1.1-1.1c1.1-0.6,1.9-1.1,1.9-1.1v-12.4
	c0,0,2.2-4.5,5.8-4.8c3.6-0.3,15.7-0.6,19.4-0.4c3.7,0.1,8.1,2.3,9.4,10.3c1.2,8,1.7,23.7,1.9,31c0.3,7.2-0.1,9.5-5.1,10.2
	c-5,0.7-20.8,1.6-24.5,1.8c-5.2,0.1-5.8-3.3-5.8-3.3c-2.2,0-1.7-0.1-2.3-0.1c-0.8-0.1-1.2-0.3-1.2-0.3h-1l-2.6-7.6h3.2
	c0.1-0.3,0.4-1.5,1.4-1.6c0.4,0,0.8-0.1,1.1-0.1c0.4-0.1,0.8-0.1,1.1-0.1l-0.4-11.8h-1.5c-0.3,0-0.4-0.1-0.5-0.1
	c-1.1-0.1-1.2-0.7-1.2-0.7l-4,0.3c0,0-0.1,0.7-0.6,1.4c-0.1,0.6-0.5,1.1-1.4,1.2L579.3,217.7L579.3,217.7z M251.9,227.9h1.2
	c0,0,0.1,0,0.1-0.1c0.4-0.3,0.6-0.6,0.6-0.6h4c0,0,0.1,0.7,0.1,1.5c0.1,0.8,0.8,1.5,2.3,1.8c1.2,0.3,4.5,0.4,5.2,0.4l0.4-0.4
	l3.4-12.5l-0.5-0.1c-0.5,0-7.3,0.3-8.9,0.3c-1.8,0-2.2,1.8-2.2,1.8h-4.1c0,0,0-1.1-1.5-1.5c-1.1-0.3-1.9-0.4-1.9-0.4s0.1-7,0-11.4
	c0-4.4-2.2-5.9-5.5-6.1c-3.3-0.1-13.6-0.4-19.5-0.3s-8.5,5.9-9.4,13.2c-0.8,7.4-1.5,27.5-1.6,31.6c-0.3,4.1,2.1,6.1,5.8,6.6
	c4.1,0.7,17.9,1.5,23.7,1.6s6.1-3.2,6.1-3.2h2.5c1.1-0.1,1.4-0.4,1.4-0.4l4-0.1c0,0,0.3,0.1,0.4,1.1c0.1,1.4,1.5,1.4,2.9,1l2.6-11.7
	l-0.5-0.1c-0.5,0-1.1,0.3-2.9,0.3c-1.8,0-2.2,1.8-2.2,1.8h-4.1c0,0,0-1-1.2-1.4c-0.1,0-0.3-0.1-0.4-0.1L251.9,227.9L251.9,227.9z"
          />
          <path
            fill="#5E5E5E"
            d="M302.8,408.1l84.3,0.7c6.2,5.6,14,9.8,21.6,10.7h7.8v1.1h9.4v-0.8l6.3-0.1c7.2-0.5,15.4-4.7,23.4-11.1
	l75-0.7l4.3-0.7l2.9-0.4l1.8-1.4v-21.9l-44.7,2.2l-2.6,1l-0.8,0.3l-1.5,0.1l-0.8,0.1l-0.3,6.1l-49.9,0.1v-1.2h-34.9v1.1l-49.5,0.1
	l-1.2-6.1l-1-0.1l-1.8-0.1l-0.4-0.3L348,386l-45.3-2.5L302.8,408.1L302.8,408.1L302.8,408.1z"
          />
          <path
            fill="#5E5E5E"
            d="M539.5,383.5c-0.1,0.1-0.7,61.1-0.1,67.5c0.7,6.5,4.1,10,9.1,10s28.6,0.1,31.8,0c3.2-0.1,7.7-4.4,7.6-16.5
	c-0.1-10.6,0.5-88,0.7-106.6c-0.4,4.3-0.7,6.1-0.7,6.1c-3.3,24.9-5.2,32.5-14.9,37.4c-5.6,2.9-12.7,3.3-21,2.3l-0.3-0.7L539.5,383.5
	L539.5,383.5z M302.6,383.5c0,0,0.7,60.9,0,67.4s-2.5,10.2-7.4,10.2c-5,0-28.5,0.1-31.6,0c-3.2-0.1-9.4-4.5-9.2-16.5
	c0.1-12-0.7-110.5-0.7-110.7c0.3,3.7,0.6,6.2,0.6,6.1c3,32.1,8.4,40.6,21.6,43.2c4,0.8,8.3,0.5,13.9,0.4c0.4,0,1-0.7,1.4-0.7
	C294.8,383.1,298.7,383.5,302.6,383.5L302.6,383.5z"
          />
          <path
            fill="#EAEBEB"
            d="M419.8,155.3c-42.6,0-85,1-91.1,1.2c-6.2,0.3-10.6,1.4-13.8,3c-3.2,1.6-5.2,4-7,7.4
	c-3.6,7-16.6,43.6-18.4,50.6c-0.4,1.6-0.7,3.2-0.7,4.4c0,1.9,0.4,2.9,1.8,3.7c1.4,1,3.9,1.6,8.1,1.5h245.3c4.1,0,6.7-0.7,8.1-1.5
	c1.2-1,1.7-1.9,1.8-3.7c0-1.2-0.3-2.6-0.7-4.4c-1.9-7-15-43.6-18.4-50.6c-1.8-3.4-3.9-5.8-7-7.4c-3.2-1.6-7.6-2.8-13.8-3
	c-6.5-0.3-50.5-1.2-94-1.2L419.8,155.3L419.8,155.3z M400.7,148.4c2.5,0,18.7,0.1,18.7,0.1h3.3c0,0,16.2-0.1,18.7-0.1
	c2.5,0.1,2.6-1,2.6-1.8s0.1-1.4-0.7-2.9c-0.8-1.5-2.2-2.3-4.4-2.6c-2.2-0.3-9.5-0.3-15.7-0.3h-4.7c-6.1,0-13.3,0-15.7,0.3
	c-2.2,0.3-3.7,1.1-4.4,2.6c-0.8,1.5-0.7,2.1-0.7,2.9C398.2,147.3,398.4,148.4,400.7,148.4L400.7,148.4z M592.9,206.3
	c-0.1,2.8-0.1,20.4,0,22.1c0.1,1.8,0.5,3.6,2.8,3.4s21.6-0.8,23-1.1c1.2-0.1,2.3-0.4,2.2-3.6c-0.1-3.3-2.2-21.9-2.2-21.9
	c-0.3-1.8-1.4-3-3.4-3c-2.2,0-15.1,0.1-17.9,0.4C594.4,203,593,203.5,592.9,206.3L592.9,206.3z M249,228.4c0.1-1.8,0.1-19.5,0-22.1
	s-1.5-3.3-4.3-3.6c-2.8-0.3-15.3-0.4-17.5-0.4s-3.2,1.2-3.4,3c-1.9,19.1-1.9,17.7-2.2,21.9c-0.1,3.3,1,3.4,2.2,3.6
	c1.4,0.1,20.4,1,22.6,1.1C248.4,232,248.9,230.2,249,228.4L249,228.4z M246.1,233.7c-1.9-0.1-19.4-0.8-21.7-1
	c-2.3-0.1-3,1.2-3.2,2.9c0,0-0.4,6.6-0.4,9.2c-0.1,2.6,1.1,3.6,3.4,3.9c2.3,0.3,19.1,1.9,21.9,1.9c2.8,0.1,2.9-1.8,3-3.3
	c0.1-1.5,0.4-8.7,0.4-10.5C249.5,234.9,247.9,233.7,246.1,233.7L246.1,233.7z M618.1,232.4c-2.3,0.1-19.9,0.8-21.7,1
	c-1.9,0-3.4,1.4-3.4,3.2c0,1.8,0.4,8.9,0.5,10.5c0.1,1.5,0.3,3.4,3,3.3s19.7-1.8,21.9-1.9c2.3-0.3,3.4-1.2,3.4-3.9s-0.4-9.2-0.4-9.2
	C621.1,233.7,620.4,232.4,618.1,232.4L618.1,232.4z"
          />
          <path
            fill="#FFFFFF"
            d="M590.3,217.3c0,1.2,0,5.1,0.1,9.8c0,0,0.3,0.7,0.1,0.7c-0.4,0-1.1,0.1-1.9,0c-1.1-0.1-1.5-1-1.5-1h-4
	c0,0-0.5,0-0.5,1c-0.1,0.8-0.4,1.9-1.9,2.2c-1.2,0.3-4.5,0.7-5.2,0.7l-0.4-0.4l-3.4-12.5l0.5-0.1c0.5,0,7.3,0.3,8.9,0.3
	c1.8,0,2.2,1.8,2.2,1.8h4.1c0,0,0-1.1,1.5-1.5C589.6,217.9,590,217.3,590.3,217.3L590.3,217.3z M591,239.6c0,1.2,0.8,5.4,0.8,10.2
	h-0.3c-0.4,0-2.2,0.1-2.9,0.1c-1.1-0.1-1.4-0.4-1.4-0.4l-4-0.1c0,0-0.3,0.1-0.4,1.1c-0.1,1.4-1.5,1.4-2.9,1l-3.2-11.6l1.1-0.3
	c0.5,0,1.1,0.3,2.9,0.3c1.8,0,2.2,1.8,2.2,1.8h4.1c0,0,0-1.1,1.5-1.5C589.6,239.7,590.7,239.6,591,239.6L591,239.6z"
          />
          <path
            fill="#5E5E5E"
            d="M434.3,254h-26.1c-2.2,0-4,1.8-4,4.1v8.8c0,2.2,1.8,4.1,4,4.1h26.1c2.2,0,4.1-1.8,4.1-4.1v-8.8
	C438.4,255.8,436.5,254,434.3,254L434.3,254z"
          />
          <ellipse fill="#5E5E5E" cx="421.2" cy="293.1" rx="22.8" ry="9.8" />
          <path
            fill="#EAEBEB"
            d="M281.6,289.8c-0.1-9.5,0-28.2,0-29.4s-0.5-1.1-1.9-1.1s-7.4,0.3-11.4,1.5c-4,1.1-6.3,4.8-7.2,8.1
	c-0.8,3.3-2.1,11.7-2.6,19.9c-0.4,8.3,0.4,20.9,0.5,23.1c0,0.6,0.1,1,0.3,1.4c0.4,1.4,1.1,2.3,1.9,3.2c1.2,1.2,3.2,2.1,5.8,2.3
	c2.6,0.3,11.1,0.7,13.1,0.7s1.8-1.2,1.8-2.3C281.9,317.2,281.7,299.3,281.6,289.8L281.6,289.8z M560.6,289.8c0.1-9.5,0-28.2,0-29.4
	s0.5-1.1,1.9-1.1s7.4,0.3,11.4,1.5c4,1.1,6.3,4.8,7.2,8.1c0.8,3.3,2.1,11.7,2.6,19.9c0.4,8.3-0.4,20.9-0.5,23.1c0,0.6-0.1,1-0.3,1.4
	c-0.4,1.4-1.1,2.3-1.9,3.2c-1.2,1.2-3.2,2.1-5.8,2.3c-2.6,0.3-11.1,0.7-13.1,0.7s-1.8-1.2-1.8-2.3
	C560.3,317.2,560.4,299.3,560.6,289.8L560.6,289.8z"
          />
          <path
            fill="#5E5E5E"
            d="M313.5,156.6c-3.9,2.1-6.5,5.1-8.4,8.9c-3.9,7.7-16.6,43.7-18.7,51.2c-0.5,1.8-0.8,3.6-0.8,5.2
	c0,2.5,1,5.1,3.2,6.6s5.5,2.2,9.9,2.2h245.3c4.5,0,7.7-0.7,9.9-2.2c2.3-1.5,3.3-4,3.2-6.6c0-1.6-0.3-3.4-0.8-5.2
	c-2.1-7.4-14.9-43.5-18.7-51.2c-1.8-3.6-4.1-6.5-7.6-8.4l-0.8-0.4c-3.9-2.1-8.7-3-15.1-3.4c-6.5-0.4-50.3-1.2-94-1.2h-0.3
	c-42.6,0-84.9,1-91.3,1.2C322.2,153.6,317.2,154.6,313.5,156.6L313.5,156.6z M308,167.1c1.8-3.4,3.9-5.8,7-7.4
	c3.2-1.6,7.6-2.8,13.8-3s48.4-1.2,91.1-1.2h0.3c43.5,0,87.5,1,93.7,1.2c6.2,0.3,10.6,1.4,13.8,3s5.2,4,7,7.4
	c3.6,7,16.6,43.6,18.4,50.6c0.4,1.6,0.7,3.2,0.7,4.4c0,1.9-0.4,2.9-1.8,3.7c-1.2,1-3.9,1.6-8.1,1.5H298.6c-4.1,0-6.7-0.7-8.1-1.5
	c-1.2-1-1.6-1.9-1.8-3.7c0-1.2,0.3-2.6,0.7-4.4C291.5,210.7,304.6,174.1,308,167.1L308,167.1z M345.7,326.5c-1.6,0-9.1-0.1-9.5-0.1
	s-0.4-0.3-0.4-0.4c0-0.3,0.1-0.4,0.4-0.4h6.2c0.5,0.1,0.5,0.8,0.5,0.8h4.7V325c0,0-0.3-1.6-1.9-1.8H334c-1.2,0-2.8,1.2-2.8,2.5v1.6
	c0,0-0.1,2.1,1.9,2.1s9.4,0.4,9.5,0.4c0.3,0,0.4,0.1,0.4,0.4c0,0.3,0,0.4-0.4,0.4s-6.5,0.1-6.9,0c-0.4-0.1-0.4-1.1-0.4-1.1H331v1.2
	c0,0,0.3,2.5,2.2,2.5h11c1.4,0,3.4-1,3.4-2.6v-1.9C347.5,328.9,347.3,326.5,345.7,326.5L345.7,326.5z M351.2,323.2v7.7
	c0,0,1,2.8,2.3,2.8h10.2c0,0,2.8-1.1,2.8-3.3v-7h-4v5.9c0,0.7-0.1,1.4-0.8,1.4h-5.2c-0.5,0-1-0.3-1-1.5v-5.8L351.2,323.2
	L351.2,323.2z M370.6,323.4v10.2h4.3v-2.6h7.2c1.5,0,2.6-1.7,2.6-2.5v-2.8c0-0.4-0.5-2.3-1.9-2.3L370.6,323.4L370.6,323.4z
	 M374.7,328.2v-1.9h5.9c0.4,0,0.8,0.7,0.8,1.1c0,0-0.3,1-0.8,1C380.1,328.2,374.7,328.2,374.7,328.2L374.7,328.2z M388.6,323.2v10.3
	l13.3-0.1v-2.8H393v-1.1l8.4-0.1l-0.1-2.3l-8.3,0.1v-1.4l9.1-0.1l-0.1-2.5 M406.2,323.1v10.3h4.1v-2.2h5.6c0.5,0,0.5,0.7,0.5,0.7
	v1.5h4.3v-2.2c0-1.2-1.8-1.4-1.8-1.4s1.9-1,1.9-2.5v-1.9c0-0.5-0.8-2.3-2.1-2.3L406.2,323.1L406.2,323.1z M410.2,327.9v-1.8h5.9
	c0.5,0,0.5,0.7,0.5,0.7s0.1,1.1-0.5,1.1L410.2,327.9L410.2,327.9z M440.6,323.2v10.3h13.1c2.6,0,3.3-2.1,3.3-3.3V326
	c0-1.4-1.2-2.8-2.5-2.8L440.6,323.2L440.6,323.2z M445.3,330.4v-4.1h7c0.4,0,0.5,0.3,0.5,0.7v2.5c0,0.5-0.1,1-0.7,1L445.3,330.4
	L445.3,330.4z M461.1,330.2c0,1.6,1,3.2,3,3.2h9.5c1.9,0,2.6-2.2,2.6-3.3v-7H472v6.3c0,0-0.3,1.2-0.7,1.2h-5.4
	c-0.5,0-0.8-0.7-0.8-1.4v-6.2H461L461.1,330.2L461.1,330.2z M484,326.3v7.2h4.3v-7l5.1-0.1v-3h-14.6v3 M495.1,323.2l6.2,5.9v4.3h4.4
	v-4.5l5.5-5.5l-4.4-0.1l-3.4,3.7l-3.7-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M387.2,409.1c0,0,12.2,10.5,23,10.5c7.2,0,9.9,0.4,21.5,0.1
	s23.8-11,23.8-11l73.6-0.8c4.5-0.1,4.5-0.8,7-0.8c2.6,0,3.3-1.5,3.3-1.5v1.8c0,0,0.3,29.9,0,39.8c-0.3,9.9,4.5,14,9.4,14
	s23.5,0.1,29.2,0.1c5.6,0,9.1-1.5,10-14.3c1-12.8,0-102.9,0-102.9s0.7-4.8,1.7-17.2s0.6-21.2,0.4-36.7c-0.1-15.5-4-19.8-4-19.8
	s0-4.5-1.9-10c-1.7-5-3.3-8.7-3.3-8.7s1.1-0.3,1.8-1c0.7-0.7,0.6-1.4,0.6-1.4h4.4c0,0,0.3,0.3,1.2,0.3c0.7,0.1,0,0.1,2.3,0.1
	c0,0,0.5,3.4,5.8,3.3c3.7,0,19.5-1.1,24.5-1.8c5-0.7,5.4-3,5.1-10.2s-0.7-23-1.9-30.9c-1.2-8-5.6-10.2-9.4-10.3s-15.8,0.1-19.4,0.4
	c-3.6,0.3-5.8,2.6-5.8,4.8v12.4c0,0-0.8,0.6-1.9,1.1c-1.1,0.6-1.1,1.1-1.1,1.1l-4.3,0.1c0,0,0.1-2.1-2.3-2.1h-8.1c0,0-3.6-10-6.5-18
	c-2.8-8-4.8-14.2-12.5-30.5c-7.7-16.2-10.9-19.7-10.9-19.7s0.1-0.8-0.3-1.5c-0.4-0.7-2.3-2.5-3.4-3.2c-1-0.7-1.9-0.3-1.9-0.3
	s-2.3-1.4-6.5-2.2c-4.1-0.8-19.3-2.9-48.7-4.4c-29.4-1.5-61.6-1.4-61.6-1.4s-20.5,0.1-39.2,0.7c-18.8,0.6-41,2.2-53.2,3.2
	c-12.2,1.1-21.6,4.1-21.6,4.1s-0.8-0.4-1.6,0.1c-0.7,0.6-2.1,1.4-3.2,2.3c-1.2,1.1-1.2,2.9-1.2,2.9s-4.3,6.2-7.8,13.5
	c-3.6,7.3-10.6,23.9-13.9,32.6c-3.3,8.8-7.6,21.7-7.6,21.7s-7.2,0.3-9.1,0.3c-1.8,0-2.2,1.8-2.2,1.8h-4.1c0,0,0-1.1-1.5-1.5
	c-1.1-0.3-2.1-0.4-2.1-0.4s0.1-7,0-11.4c0-4.4-2.2-5.9-5.5-6.1s-13.6-0.4-19.5-0.3c-5.9,0.1-8.5,5.9-9.4,13.2
	c-0.8,7.4-1.5,27.5-1.6,31.6c-0.3,4.1,2.1,6.1,5.8,6.6c4.1,0.7,17.9,1.5,23.7,1.6c5.8,0.1,6.1-3.2,6.1-3.2h2.1
	c1.4,0,1.9-0.6,1.9-0.6l4.3-0.1v0.7c0,2.1,2.1,1.6,2.1,1.6s-0.8,1.7-1.6,2.8c-0.8,1.1-1.1,1.9-1.9,6.5c-1,4.5-1.5,9.5-1.5,9.5
	s-1.5,2.9-2.5,5.5c-1,2.5-2.2,10.2-2.2,25.6c0.1,15.4,2.2,38.9,2.2,38.7s0.1,92,0.1,104.7c0,12.5,7,16.5,9.2,16.5h31.6
	c3.7,0,7.4-3.3,7.4-10.2c0-6.9,0.1-42.9,0.1-42.9L387.2,409.1L387.2,409.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M577.2,239.9l-2.3-9.2c0,0,3.3-0.1,5.4-0.6c2.2-0.4,2.5-2.9,2.5-2.9l4-0.3
	c0,0,0.1,0.7,1.4,0.7c1.1,0,2.1,0.1,2.1,0.1l0.4,12c0,0-1.2,0-2.2,0.1c-1.1,0.1-1.4,1.6-1.4,1.6h-4.3c0,0-0.3-1.8-2.1-1.8
	C579,239.7,577.2,239.9,577.2,239.9L577.2,239.9z M267.3,230.8c0,0-4-0.3-5.4-0.4c-1.5-0.3-2.2-0.8-2.3-1.8
	c-0.1-0.8-0.1-1.5-0.1-1.5h-4c0,0-0.4,0.8-1.5,1c-1.1,0.1-1.9,0-1.9,0l-0.4,11.8c0,0,1,0.1,2.1,0.3c1.6,0.1,1.5,1.8,1.5,1.6
	c0-0.1,4.3,0,4.3,0s0.3-1.6,1.8-1.6c1.6-0.1,3.9-0.3,3.9-0.3L267.3,230.8L267.3,230.8z M531.5,399.2c0-4,2.1-7.2,4.5-7.2
	s4.5,3.2,4.5,7.2c0,4-2.1,7.2-4.5,7.2S531.5,403,531.5,399.2z M536,392l-28.3,1.4 M506.5,393.2V385 M510,393.1v-8.3 M523,392.4v-8.3
	 M526.6,392.3V384 M539.5,394.2v-10.7 M504.9,407.4l25.2-0.3 M261.1,353.6c-1.1-4-1.8-8.8-2.2-11.1c-0.4-2.2-0.4-4.1,3.2-4.1
	s20.8,0.1,20.8,0.1h276.5c0,0,17.2-0.1,20.8-0.1c3.6,0,3.6,1.9,3.2,4.1c-0.4,2.2-1.1,7-2.2,11.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M257.1,267.4c0,0,0.1,6.6,0.7,24.1c0.5,17.6,1.6,28.5,1.6,29.7
	c0.1,1.2,0,2.1-0.5,3c-0.5,1.1-1.9,3.7-1.9,9.6c0,5.8,1.9,14.9,2.9,18.7c1.2,4.5,3.7,11.8,5.2,16c1.5,4.1,3.4,8.4,5.8,10.3
	c2.2,1.9,5.8,3.6,9.4,4c3.6,0.4,10.3,0.1,10.3,0.1l0.1,0.5c0,0-12,0.8-17.6-1c-7-2.3-11.4-6.3-13.9-14.4c-2.5-8.1-4.3-23.9-5-30.3
	 M585.3,267.6c0,0,0,6.6-0.5,24.1c-0.6,17.6-1.7,28.5-1.7,29.7c-0.1,1.2,0,2.1,0.5,3.2c0.5,1.1,1.9,3.7,1.9,9.6
	c0,5.8-1.9,14.9-2.9,18.7c-1.2,4.5-3.7,11.8-5.2,16c-1.5,4.1-3.4,8.4-5.8,10.3c-2.2,1.9-5.8,3.6-9.4,4c-3.6,0.4-10.3,0.1-10.3,0.1
	l-0.1,0.5c0,0,12,0.8,17.6-1.1c7-2.3,11.4-6.3,13.9-14.4c2.5-8.1,4.3-23.9,5-30.3 M577.8,242.8c0,0-0.3-6.1-10.3-6.2
	c-10.6-0.1-33.7-0.1-33.7-0.1H308.6c0,0-23.1,0.1-33.7,0.1c-10,0.1-10.3,6.2-10.3,6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M574.3,238.1c0,0,1.8,3.2-0.1,5.4c-1.7,1.9-3,2.1-7.6,2.1H309.2h-33.4
	c-4.5,0-5.9-0.1-7.6-2.1c-1.9-2.3-0.1-5.4-0.1-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M306.8,242.3c0-0.8,50.8-1.5,113.4-1.5c62.6,0,113.2,0.7,113.2,1.5
	L306.8,242.3L306.8,242.3z M352.6,236.3v9.4 M489.3,236.3v9.4 M454,245.6c0,0-4.3,15.7-6.1,22c-1.8,6.3-5.2,7.3-9.1,7.3h-35.6
	c-3.9,0-7.3-1-9.1-7.3c-1.8-6.3-6.1-22-6.1-22"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M438.4,266.8c0,2.2-1.8,4.1-4.1,4.1h-26.1c-2.2,0-4-1.8-4-4.1V258
	c0-2.2,1.8-4.1,4-4.1h26.1c2.2,0,4.1,1.8,4.1,4.1L438.4,266.8L438.4,266.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M408.4,262.6c-0.7,0-0.7-0.7-0.7-1.6v-3.6c0-1,0.7-1.6,1.6-1.6H433
	c1,0,1.6,0.7,1.6,1.6v3.6c0,1,0.1,1.7-0.7,1.7L408.4,262.6L408.4,262.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M412,262.6v3.7c0,1,0.7,1.6,1.6,1.6h15.1c1,0,1.6-0.7,1.6-1.6v-3.7
	 M432.2,265.9c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C432.9,267.4,432.2,266.7,432.2,265.9z"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="421.2"
            cy="293.1"
            rx="22.8"
            ry="9.8"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="421.2"
            cy="293.1"
            rx="20.2"
            ry="8.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M558.6,236.4c0,0,1.2,14.6,1.5,32.7c0.3,18.2,0.1,37.3,0,43.1
	c-0.1,5.8-0.1,6.6-0.1,6.6s0.1,1,0.1,2.1s-0.7,13.5-0.7,15c0,1.5-0.5,2.6-0.5,2.6 M283.7,236.4c0,0-1.2,14.6-1.5,32.7
	s-0.1,37.3,0,43.1s0.1,6.6,0.1,6.6s-0.1,1-0.1,2.1s0.7,13.5,0.7,15c0,1.5,0.5,2.6,0.5,2.6 M543.9,230.6H298.6
	c-4.5,0-7.7-0.7-9.9-2.2c-2.3-1.5-3.3-4-3.2-6.6c0-1.6,0.3-3.4,0.8-5.2c2.1-7.4,14.9-43.5,18.7-51.2c1.9-3.9,4.5-6.9,8.4-8.9
	c3.9-2.1,8.7-3,15.1-3.4c6.3-0.4,48.6-1.2,91.3-1.2h0.3c43.6,0,87.5,1,94,1.2c6.5,0.4,11.3,1.4,15.1,3.4s6.5,5.1,8.4,8.9
	c3.9,7.7,16.6,43.7,18.7,51.2c0.5,1.8,0.8,3.6,0.8,5.2c0,2.5-1,5.1-3.2,6.6C551.6,230.1,548.5,230.6,543.9,230.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.9,227.3c4.1,0,6.7-0.7,8.1-1.5c1.2-1,1.7-1.9,1.8-3.7
	c0-1.2-0.3-2.6-0.7-4.4c-1.9-7-15-43.6-18.4-50.6c-1.8-3.4-3.9-5.8-7-7.4s-7.6-2.8-13.8-3c-6.3-0.4-50.3-1.4-93.8-1.4h-0.3
	c-42.6,0-85,1-91.1,1.2c-6.2,0.3-10.6,1.4-13.8,3c-3.2,1.6-5.2,4-7,7.4c-3.6,7-16.6,43.6-18.4,50.6c-0.4,1.6-0.7,3.2-0.7,4.4
	c0,1.8,0.4,2.9,1.8,3.7c1.4,1,3.9,1.6,8.1,1.5C310.2,227.3,532.4,227.3,543.9,227.3 M536.4,144.7l-6.3,12.5 M541.9,149.6l-6.3,12.2
	 M305.8,144.5l6.2,12.8 M300.2,149.5l6.6,13.1 M419.6,148.4c0,0-16.2-0.1-18.7-0.1c-2.5,0.1-2.6-1-2.6-1.8c0-0.8-0.1-1.4,0.7-2.9
	c0.8-1.5,2.2-2.3,4.4-2.6c2.2-0.3,9.5-0.3,15.7-0.3h4.7c6.2,0,13.3,0,15.7,0.3c2.2,0.3,3.7,1.1,4.4,2.6c0.8,1.5,0.7,2.1,0.7,2.9
	c0,0.8-0.1,1.8-2.6,1.8s-18.7,0.1-18.7,0.1L419.6,148.4L419.6,148.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M399.9,144.7c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1
	S399.9,145.2,399.9,144.7L399.9,144.7z M440.3,144.7c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S440.3,145.2,440.3,144.7L440.3,144.7z
	 M281.7,319.2H560 M281.9,317.2c0,1.1,0,2.3-1.8,2.3c-1.9,0-10.5-0.3-13.1-0.7c-2.6-0.3-4.5-1.1-5.8-2.3s-2.1-2.3-2.2-4.7
	c-0.1-2.2-1.1-14.9-0.5-23.1c0.4-8.3,1.8-16.6,2.6-19.9c0.8-3.3,3.2-6.9,7.2-8.1c4-1.2,10-1.5,11.4-1.5s1.9-0.1,1.9,1.1v29.4
	C281.7,299.3,281.9,317.2,281.9,317.2L281.9,317.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M261.5,267.5c0,0,1.9-5.1,7.2-5.8c5.2-0.7,9.9-0.8,10.3-0.8s0.8,0.3,1,1.1
	c0,0.8,0.4,52.8,0.4,53.6c0,1-0.1,1.5-1,1.5s-8.3,0-12.9-0.5c-4.5-0.7-7.3-1.4-7.6-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.6,276.9c-1.2,0.1-2.2,0.3-2.2-1s0.5-6.9,0.7-8c0.1-1,0.7-2.1,2.3-2.5
	c1.6-0.4,4.1-0.7,6.1-0.8c1.8-0.1,3-0.1,3.7-0.1s1.1,0.3,1.2,1.4s0.1,8.1,0.1,9.1c0,1,0,1.8-1.4,1.6c-1.5,0-3.9,0-6.1,0.1
	C267.8,276.7,265.6,276.9,265.6,276.9L265.6,276.9z M262.2,303.6c0-0.5-0.3-1.9,1-1.8c1.2,0.1,3.2,0.4,6.3,0.4c3,0.1,5,0.1,6.1,0.1
	s2.1,0,2.2,1.5c0.1,1.5,0.1,7.7,0.1,8.9c0,1.2-0.3,2.2-1.9,2.2s-5.2-0.3-7.8-0.5s-4-0.5-4.7-0.7c-0.5-0.1-1-0.3-1-1.4
	C262.2,311.5,262.2,304.2,262.2,303.6L262.2,303.6z M560.4,317.2c0,1.1,0,2.3,1.8,2.3c1.9,0,10.5-0.3,13.1-0.7
	c2.6-0.3,4.5-1.1,5.8-2.3c1.2-1.2,2.1-2.3,2.2-4.7c0.1-2.2,1.1-14.9,0.5-23.1c-0.4-8.3-1.8-16.6-2.6-19.9c-0.8-3.3-3.2-6.9-7.2-8.1
	c-4-1.2-10-1.5-11.4-1.5c-1.4,0-1.9-0.1-1.9,1.1v29.4C560.6,299.3,560.4,317.2,560.4,317.2L560.4,317.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M580.8,267.5c0,0-1.9-5.1-7.2-5.8c-5.2-0.7-9.9-0.8-10.3-0.8
	c-0.4,0-0.8,0.3-1,1.1c0,0.8-0.4,52.8-0.4,53.6c0,0.8,0.1,1.5,1,1.5c0.8,0,8.3,0,12.9-0.5c4.5-0.7,7.3-1.4,7.6-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M576.5,276.9c1.2,0.1,2.2,0.3,2.2-1s-0.5-6.9-0.7-8
	c-0.1-1-0.7-2.1-2.3-2.5c-1.7-0.4-4.1-0.7-6.1-0.8c-1.8-0.1-3-0.1-3.7-0.1s-1.1,0.3-1.2,1.4c0,1.1-0.1,8.1-0.1,9.1
	c0,1,0,1.6,1.4,1.6c1.5,0,3.9,0,6.1,0.1C574.3,276.7,576.5,276.9,576.5,276.9L576.5,276.9z M580.1,303.6c0-0.5,0.3-1.9-1-1.8
	c-1.2,0.1-3.2,0.4-6.3,0.4c-3,0.1-5,0.1-6.1,0.1c-1.1,0-2.1,0-2.2,1.5c-0.1,1.5-0.1,7.7-0.1,8.9c0,1.2,0.3,2.2,1.9,2.2
	s5.2-0.3,7.8-0.5s4.1-0.5,4.7-0.7c0.5-0.1,1-0.3,1-1.4C580.1,311.5,580.1,304.2,580.1,303.6L580.1,303.6z M271.3,217.6l-3.7,13.2
	 M265.2,239.9l-3,11.8 M259.7,220.2v6.7 M259.6,241.8v7.3 M254.8,241.5v7.3 M255.2,220.1c0,0.3,0.1,6.7,0.1,6.7 M251.5,217.3
	c0,0,0.1,5.5,0.1,12c-0.1,6.3-1,19.5-1.1,20.6 M224,205.3c0.3-1.8,1.2-3,3.4-3c2.2,0,14.9,0,17.5,0.4c2.8,0.3,4.1,1,4.3,3.6
	c0.1,2.8,0.1,20.4,0,22.1c-0.1,1.8-0.6,3.6-2.8,3.4s-21.2-0.8-22.6-1.1c-1.2-0.1-2.3-0.4-2.2-3.6C221.9,223.1,221.9,224.5,224,205.3
	L224,205.3z M221.2,235.6c0.1-1.7,0.8-3,3.2-2.9c2.3,0.1,19.8,0.8,21.7,1c1.9,0.1,3.4,1.4,3.4,3.2c0,1.8-0.4,8.9-0.6,10.5
	s-0.3,3.4-3,3.3c-2.8,0-19.5-1.8-21.9-1.9c-2.3-0.3-3.4-1.2-3.4-3.9C220.9,242.2,221.2,235.6,221.2,235.6L221.2,235.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M227.4,200.1c0,0-5,0.4-5.4,5.4c-0.6,5-2.1,19.9-2.3,28.2
	c-0.3,8.3-0.4,9.9-0.3,11.4c0,1.5-0.7,5.2,4.7,5.8 M571.2,217.6l3.7,13.8 M576.7,238.8l3.6,12.9 M582.6,220.2v6.7 M582.9,241.8v7.3
	 M587.3,241.5l0.3,7.3 M586.8,220.1c0,0.3,0.3,5.4,0.3,6.7 M590.3,217.3c-0.1,5.5,0,9.4,0.3,15.1c0.3,6.3,1,16.4,1.1,17.5
	 M618.5,205.3c-0.3-1.8-1.4-3-3.4-3c-2.2,0-15.1,0.1-17.9,0.4s-4.1,1-4.3,3.6c-0.1,2.8-0.1,20.4,0,22.1c0.1,1.8,0.5,3.6,2.8,3.4
	s21.6-0.8,23-1.1c1.2-0.1,2.3-0.4,2.2-3.6C620.5,223.9,618.5,205.3,618.5,205.3L618.5,205.3z M621.2,235.3c-0.1-1.6-0.8-3-3.2-2.9
	c-2.3,0-19.9,0.8-21.7,1c-1.9,0-3.4,1.4-3.4,3.2c0,1.8,0.4,8.9,0.5,10.5c0.1,1.5,0.3,3.4,3,3.3s19.7-1.8,21.9-1.9
	c2.3-0.3,3.4-1.2,3.4-3.9C621.5,241.9,621.2,235.3,621.2,235.3L621.2,235.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M615,200.1c0,0,5,0.4,5.4,5.4c0.6,5,2.1,19.9,2.3,28.2
	c0.3,8.3,0.4,9.9,0.3,11.4c-0.1,1.5,0.7,5.2-4.7,5.8 M550,342.6c0,0-0.1-0.8,1.4-0.8c1.5-0.1,28.2-0.3,28.2-0.3s1.7,0.1,1.7,1.4
	s-0.1,6.6-0.1,6.6l-0.6,0.8c0,0,1.4,0.5,1,2.1s-4.7,17.5-8.4,23.5c-3.7,6.1-7.6,6.6-13.5,6.9c-5.9,0.4-58.3,2.3-61.8,2.6
	s-3.2,0.3-4.3,0.8c-1.1,0.5-2.8,0.8-4.7,1c-1.9,0.1-3.7,0.1-3.7,0.1l-0.7-0.5l-1,0.4c0,0-42.2,0.4-46.1,0.1c-4-0.1-3.9-0.4-4.7-1.5
	c-0.8-1-1.5-1.8-3.2-1.8h-16.8c0,0-1.8,0.4-2.6,1.4c-1,1-1.2,2.1-5.6,2.1s-45.5-0.3-45.5-0.3l-0.8-0.5c0,0-0.5,0.7-1.2,0.7h-5
	c-1.8,0-2.6-1-2.6-1s-0.8-0.4-3.4-0.7c-2.5-0.3-56.4-2.8-62-3c-5.6-0.3-12.5-1.8-15.8-9.5c-3.3-7.6-6.5-17.7-6.9-19.7
	c-0.5-1.9,0.4-2.8,0.4-2.8s-0.3-0.5-0.3-1.5v-6.5c0,0,0.4-1.2,1.4-1.2s26.8,0.1,27.9,0.1s1.6,1,1.6,1s93-0.3,94.9-0.3
	s1.8,1.4,1.8,1.4v2.1h64.2l0.1-2.2c0,0,0.1-1.5,1.6-1.5C456.6,342.1,550,342.6,550,342.6L550,342.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M484.5,386.8c0,0,1.4-23.2,2.2-27.5c0.8-4.3,3.9-7.4,9.5-7.4
	c5.6-0.1,84.3-1.5,84.3-1.5 M357.9,386.6c-0.1-0.3-1.8-20.9-2.5-26.8c-0.7-5.9-4.7-8-9.6-8c-5-0.1-84.5-1.2-84.5-1.2 M396.2,375.8
	c-3.7,0-5.2-3.2-5.4-6.3c-0.1-3-1.9-23.7-1.9-23.7 M453.1,345.9c0,0-1.6,20.5-1.8,23.7c-0.1,3.2-1.7,6.3-5.4,6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M447.4,371.8c0,1.1-1,2.1-2.1,2.1H397c-1.1,0-2.1-1-2.1-2.1v-22
	c0-1.1,1-2.1,2.1-2.1h48.3c1.1,0,2.1,1,2.1,2.1V371.8L447.4,371.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M581.1,343.2c0,0,0.3-0.4-1.4-0.4h-11l-0.8,1.9h-3l-0.4-1.1h-5.8l-0.5,1.1
	h-3.3l-0.5-1.1H549l-0.4,1.1h-4.1l-0.4-1.1h-4.8l-0.5,1.1h-3.6l-0.1-1.2h-5.6l-0.4,1.1h-3.4l-0.4-1.1h-5.4l-0.4,1.1h-3.4l-0.3-1.1
	h-5.5l-0.5,1.1h-3.4l-0.4-1.1H500l-0.3,1.1h-3.6l-0.3-1.1h-5.5l-0.4,1.1h-3.4l-0.4-1.1h-5.6l-0.5,1.1h-3.2l-0.6-1.1h-2.5
	c0,0-1.4,0-1.4,1.5c0,3.4-1.2,24.1-1.4,26.1c-0.1,2.1-0.8,3.7-3.6,3.7h-4l-0.3,1.1h-2.8l-0.4-1.2h-6.5l-0.3,1.1h-64l-0.3-1.1h-6.5
	l-0.4,1.2h-2.8l-0.3-1.1h-4c0,0-3.4-1.6-3.6-3.7c-0.1-2.1-1.4-22.7-1.4-26.1c0-1.5-1.4-1.5-1.4-1.5h-2.5l-0.5,1.1h-3.2l-0.5-1.1
	h-5.6l-0.4,1.1h-3.4l-0.4-1.1h-5.4l-0.3,1.1h-4l-0.3-1.1h-5.6l-0.4,1.1h-3.4l-0.5-1.1h-5.5l-0.3,1.1h-3.6l-0.4-1.1h-5.4l-0.4,1.1
	h-3.4l-0.4-1.1h-5.6l-0.1,1.2h-3.6l-0.5-1.1h-4.8l-0.4,1.1h-4.1l-0.4-1.1h-5.2l-0.5,1.1h-3.3l-0.5-1.1h-5.8l-0.4,1.1h-3l-0.8-1.9
	h-11c-1.5,0-1.4,0.4-1.4,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.6,363.5c0-2.8,1.4-4.8,3.3-4.8c1.8,0,3.3,2.2,3.3,4.8
	c0,2.8-1.5,5-3.3,5C378.2,368.5,376.6,366.3,376.6,363.5L376.6,363.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M378.2,363.5c0-1.8,1.1-3.3,2.5-3.3s2.5,1.5,2.5,3.3
	c0,1.8-1.1,3.3-2.5,3.3S378.2,365.3,378.2,363.5L378.2,363.5z M465.5,363.5c0-2.8-1.5-4.8-3.3-4.8c-1.8,0-3.3,2.2-3.3,4.8
	c0,2.8,1.4,5,3.3,5C464,368.5,465.5,366.3,465.5,363.5L465.5,363.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M464,363.5c0-1.8-1.1-3.3-2.5-3.3c-1.4,0-2.5,1.5-2.5,3.3
	c0,1.8,1.1,3.3,2.5,3.3S464,365.3,464,363.5L464,363.5z M351.5,387.2c0,0-0.5,0.7-0.7-3.4c-0.1-4-1.6-16.9-1.8-19.7s-1.2-7-1.1,0.1
	c0.1,7.2,0.3,15.3,0.4,17.9c0.1,2.6,1,4.1,1,4.1 M491.4,386.9c0,0,0.5,0,0.7-3.4c0.1-4,1.6-16.9,1.8-19.7c0.1-2.9,1.2-7,1.1,0.1
	c-0.1,7.1-0.3,15.3-0.4,17.9c-0.1,2.6-1,4.1-1,4.1 M427.5,403.7c0,1.1-0.8,2.1-2.1,2.1h-8.8c-1.1,0-2.1-1-2.1-2.1v-8.8
	c0-1.1,1-2.1,2.1-2.1h8.8c1.1,0,2.1,1,2.1,2.1V403.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M425.6,402.9c0,0.5-0.4,1-1,1h-7.2c-0.5,0-1-0.4-1-1v-7c0-0.5,0.4-1,1-1
	h7.2c0.5,0,1,0.4,1,1V402.9L425.6,402.9z M373.1,404.1c-1.6,0-2.9-1.9-2.9-4.3s1.2-4.3,2.9-4.3h9.8c1.6,0,2.9,1.9,2.9,4.3
	c0,2.3-1.2,4.3-2.9,4.3L373.1,404.1L373.1,404.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M367,393h19.7v12.7H367V393z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M380.1,395.6v2.1h-1.6v5h1.8v1.5 M370.5,401.9h-1.7v-3.6h1.6 M385.7,398.5
	h1.1 M385.6,401.6h1.2 M339.9,393.2h-5.2v11 M367,393.2h-25.7 M339.9,404.4h-37.6 M367,404.4h-25.6 M415.4,393.2h-28.6 M501,393.2
	h-74.3 M501.8,404.4h5.8v-11.1H502 M427.4,404.4H501 M386.7,404.4h28.1 M340.1,389.7h1.4v17.6h-1.4V389.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.9,389.9h1.2v17.6h-1.2L500.9,389.9z M416.1,405.8h-12.4v-1.5
	 M438.6,404.2v1.5h-12.7 M438.6,393.4v-1.2h-34.8v1.2 M353,387.3l0.8,5.8 M384.6,408.7c0,0,17.7,0.8,35.2,0.5
	c17.5-0.3,35.8-0.8,35.8-0.8 M489.2,387.2l-0.7,5.9 M334.7,400.8H313v3.4 M315.6,384.2v16.6 M318.9,384.4v16.2 M332.1,385.1v15.5
	 M335.1,385.1v8.1 M302.6,409.5v-26 M302.6,399h12.8 M319,399h12.9 M336.6,385.3v8 M341.3,385.5v4.3 M312.7,407.4h28.1 M335.4,329.7
	H331v1.2c0,0,0.3,2.5,2.2,2.5h11c1.4,0,3.4-1,3.4-2.6v-1.9c0,0-0.1-2.3-1.9-2.3c-1.6,0-9.1-0.1-9.5-0.1s-0.4-0.3-0.4-0.4
	c0-0.3,0.1-0.4,0.4-0.4h6.2c0.5,0.1,0.5,0.8,0.5,0.8h4.7V325c0,0-0.3-1.6-1.9-1.8H334c-1.2,0-2.8,1.2-2.8,2.5v1.6
	c0,0-0.1,2.1,1.9,2.1s9.4,0.4,9.5,0.4c0.3,0,0.4,0.1,0.4,0.4c0,0.3,0,0.4-0.4,0.4s-6.5,0.1-6.9,0
	C335.4,330.7,335.4,329.7,335.4,329.7L335.4,329.7z M355.5,323.2h-4.4v7.7c0,0,1,2.8,2.3,2.8h10.2c1.1,0,2.8-1.1,2.8-3.3v-7h-4v5.9
	c0,0.7-0.1,1.4-0.8,1.4h-5.2c-0.5,0-1-0.3-1-1.5C355.6,327.9,355.5,323.2,355.5,323.2L355.5,323.2z M374.9,330.9v2.6h-4.3v-10.2
	h12.1c0,0,1.9,1.8,1.9,2.3v2.8c0,0.8-1.1,2.5-2.6,2.5L374.9,330.9L374.9,330.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M374.7,326.3v1.9h5.9l0.8-1c0-0.4-0.4-1.1-0.8-1.1
	C380.2,326.3,374.7,326.3,374.7,326.3L374.7,326.3z M388.6,323.2v10.3l13.3-0.1v-2.8H393v-1.1l8.4-0.1l-0.1-2.3l-8.3,0.1v-1.4
	l9.1-0.1l-0.1-2.5L388.6,323.2z M406.2,333.4v-10.3h12.5c1.2,0,2.1,1.6,2.1,2.3v1.9c0,1.5-1.9,2.5-1.9,2.5s1.9,0,1.8,1.4v2.2h-4.3
	v-1.5c0,0,0.1-0.7-0.5-0.7h-5.6v2.2L406.2,333.4L406.2,333.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M410.2,327.9v-1.8h5.9c0.5,0,0.5,0.7,0.5,0.7s0.1,1.1-0.5,1.1L410.2,327.9
	L410.2,327.9z M440.6,323.2h14c1.2,0.1,2.5,1.4,2.5,2.8v4.3c0,1.2-0.7,3.3-3.3,3.3h-13.1L440.6,323.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M445.3,326.3v4.1h7c0.4,0,0.7-0.4,0.7-1v-2.5c0-0.5-0.3-0.7-0.5-0.7
	L445.3,326.3L445.3,326.3z M461,323.1h4.1v6.2c0,0.8,0.1,1.4,0.8,1.4h5.4c0.5,0,0.8-0.4,0.7-1.2v-6.3h4.3v7c0,0-0.7,3.3-2.6,3.3
	h-9.5c-2.1,0-3-1.5-3-3.2C461.1,328.6,461,323.1,461,323.1L461,323.1z M478.7,323.2v3h5.2v7.2h4.3v-7l5.1-0.1v-3H478.7z
	 M495.1,323.2h4.5l3.7,3.7l3.4-3.7l4.4,0.1l-5.5,5.5v4.5h-4.4v-4.3L495.1,323.2z M430.2,294.8C430.2,294.8,430.3,294.8,430.2,294.8
	l0.1,0.1c-0.1,0.3-0.3,0.5-0.4,0.7c-0.1,0.3-0.4,0.4-0.5,0.7c-0.3,0.3-0.8,0.5-1.2,0.4c-0.1-0.1-0.3-0.3-0.3-0.5
	c0-0.5,0.3-1.1,0.7-1.6c0.5-0.7,1.1-1.1,1.5-0.8C430.6,293.8,430.4,294.3,430.2,294.8L430.2,294.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M434.6,290.5C434.7,290.3,434.6,290.3,434.6,290.5
	c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0.3-1.8,2.8-2.1,3.2H431c0-0.3-0.3-0.5-0.7-0.8c-0.3-0.1-0.5-0.1-0.8-0.1
	c-0.5,0.1-1,0.4-1.4,0.7c-0.7,0.4-1.1,1.1-1.9,1.5c-0.4,0.3-1,0.4-1.2,0.1c-0.3-0.3-0.3-1,0.3-1.5h0.1c0,0.3,0,0.4,0.1,0.5
	c0.3,0.1,0.5,0.3,0.8,0.1s0.5-0.4,0.5-0.8c0.1-0.5-0.3-1-0.8-1c-0.4,0-0.8,0.1-1.1,0.4c-0.1,0.1-0.3,0.3-0.4,0.5c0,0-0.1,0.1-0.1,0
	c0-0.7-0.3-1.1-0.8-1.1c-0.4,0-0.8,0.3-1.2,0.5c-0.4,0.3-0.7,0.7-1.1,1.1h-0.1c0-0.4-0.1-0.8-0.3-1.1c-0.1-0.1-0.3-0.1-0.4-0.1
	c0,0-0.3,0.1-0.4,0.3c-0.1,0-0.1,0.1-0.1,0.3c0.1,0.7,0.1,1.4-0.1,2.1c-0.3,0.5-0.7,1.1-1.2,1.2c-0.4,0.1-0.7,0-1-0.3
	c-0.3-0.4-0.1-1.2,0.3-1.9c0.4-0.5,1.1-1,1.6-1.1c0.1,0,0.1-0.1,0-0.1c0-0.1-0.1-0.3-0.1-0.3c0-0.1-0.3-0.1-0.4-0.1
	c-0.4,0-0.8,0.3-1.2,0.4c-1.1,0.5-1.5,1.6-1.8,2.2c-0.1,0.3-0.3,0.4-0.3,0.5c-0.1,0.1-0.4,0.4-0.7,0.7v0.1c0,0.1,0.3,0.4,0.3,0.4
	h0.1c0.3-0.1,0.5-0.4,0.7-0.7h0.1c0.1,0.5,0.5,1,1.1,1.1c1,0.3,1.9-0.1,2.8-1c0.4-0.5,0.7-0.8,0.8-1.1c0.3-0.4,1-1.2,1.6-1.8
	c0.3-0.1,0.6-0.4,0.7-0.3c0.1,0.1,0.1,0.3,0,0.7c-0.7,1.4-1.8,2.9-2.1,3.3v0.1h1.1c0,0,0.1,0,0.1-0.1c0.5-0.8,1.1-1.8,1.7-2.6
	c0,0.1,0,0.5,0.1,0.7c0.3,0.3,0.5,0.5,0.8,0.5c0.3,0,0.4,0,0.7-0.1c0.3-0.1,0.4-0.3,0.6-0.3h0.1c-0.1,0.7,0,1.5,0.7,1.9
	c0.8,0.4,1.8-0.1,2.3-0.7c0,0,0.1,0,0.1,0.1s0.1,0.4,0.3,0.5c0.4,0.4,1.4,0.3,2.2-0.4c0.5-0.4,1.2-1.1,1.8-1.8v-0.1
	c-0.1-0.1-0.3-0.1-0.3-0.3h-0.1c-0.5,0.5-1.1,1.1-1.8,1.7c-0.3,0.1-0.7,0.3-0.8,0.1c-0.1-0.1-0.1-0.3,0-0.4
	C431.1,295.8,434.4,290.8,434.6,290.5L434.6,290.5z M418.7,291C418.7,291.2,418.9,291.2,418.7,291c0.3,0,0.4-0.1,0.5-0.3
	c0.1-0.1,0.1-0.4,0-0.4c-0.1-0.1-0.3,0-0.3,0C418.9,290.6,418.7,290.9,418.7,291"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M410.2,297.6c-1.2,0-2.3-0.8-2.5-2.1c-0.1-0.7,0.1-1.4,0.5-1.9s1-1,1.5-1
	c0.1,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0,0.4c-0.4,0.3-1,0.5-1.1,1.1c-0.1,0.4-0.3,0.8,0,1.4c0.5,0.8,1.9,0.8,3.2-0.4l1-1
	c0.5-0.7,0.8-1,1.5-1.9c-0.5,0-1,0.1-1.4,0.5c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1,0-0.3,0.1-0.4c0.7-0.5,1.5-0.5,2.2-0.7h0.1
	c0.3-0.4,0.5-0.8,1-1.2c0.3-0.4,0.8-0.8,1.1-1.1c0.4-0.4,0.8-0.5,1.1-0.7c0.1,0,0-0.1,0-0.1c-1.6-0.3-3.4-0.7-5.2-0.1
	c-1.2,0.4-1.6,1.4-1.5,2.1c0.1,0.4,0.8,0.5,1.4,0.3c0.4-0.3,0.8-0.5,1.1-1.1c0.1-0.3,0.5-0.1,0.3,0.4c-0.5,1.2-1.5,2.1-2.6,2.2
	c-1,0-1.6-0.7-1.6-1.6c0-1.8,1.9-2.9,4-3.2c2.5-0.1,4.8,0.4,7.3,0.8c1.9,0.3,3.7,0.3,5.6-0.1c0.3,0,0.4,0.1,0.4,0.3
	c0,0.3-0.3,0.7-1,1c-0.7,0.4-1.5,0.5-2.2,0.5c-1.9,0-3.6-0.7-5.5-1.1v0.3c-1,0.7-1.9,1.5-2.5,2.6c0.5,0,1,0,1.5-0.1
	c0.1,0,0.1,0,0.1-0.1c0-0.4,0-0.8,0.4-1.1c0.3-0.3,0.7-0.3,1-0.1c0.3,0.3,0.3,0.7,0.1,1c-0.1,0.3-0.4,0.5-0.7,0.5v0.1l0.4,0.3v0.1
	l-0.1,0.1c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.4-0.3-0.5-0.4h-0.1c-0.5,0-1.4,0.1-1.9,0.1c-0.1,0-0.1,0-0.1,0.1c-1,1.5-1.9,3-2.9,4.1
	C412.1,297.4,411.2,297.6,410.2,297.6L410.2,297.6z M416.5,418.4h9.4v2.1h-9.4V418.4z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M298.2,227.3
		c0,0-7.3,0.1-8.8-3c0,0-1.4-1.7,0.4-7.4c0,0,11.8-35.2,17.7-48.8c0,0,2.3-6.3,8.5-8.9c0,0,4.3-2.3,13.9-2.6c0,0,51.2-1.4,90.9-1.2
		c0,0,56.5,0.1,88.9,1.1c0,0,8.7,0.3,11.7,1.1c0,0,8.1,1.2,11.7,7.2c0,0,1.5,1.8,5.6,12.8c0,0,11.8,31.4,14.3,40.4
		c0,0,1.5,5.1-0.3,7c0,0-1.4,2.3-8.9,2.3h-58.2h-64.7h-61.5h-61.4L298.2,227.3L298.2,227.3z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M283.7,236.4
		c0,0-1.8,25.9-1.7,49.9c0,0-0.1,18.7,0.3,32.5c0,0-0.1,0.8-0.1,1.6v0.8l0.7,13.6c0,0-0.1,2.2,0.5,3.6h137.8h137.8
		c0,0,0.7-1.5,0.6-3.2l0.7-14.3c0,0,0-1.1-0.1-1.9c0,0,0.4-28.5,0.4-45.9c0,0-0.7-22.1-1.8-36.9H421.4H283.7V236.4z"
              id="Liftgate and Tailgate"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Liftgate and Tailgate')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('liftgateTailgateColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M258.9,312.1
		c0,0,0.1,3,4.3,3.7c0,0,5.1,1.4,16.2,1.2c0,0,1,0.1,1-1.5l-0.4-53.6c0,0,0-1-0.8-1.1c0,0,0,0-0.1,0c-0.8,0-8.1,0.3-11,1
		c0,0-4.3,0.7-6.5,5.9c0,0-0.5,1.2-1.1,5.1c0,0-1.5,8.5-1.9,19.3C258.3,292,258.2,298.5,258.9,312.1z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M563.2,317
		c0,0,13.9,0.1,18.2-2.1c0,0,1.4-0.6,1.8-2.2c0,0,1-14.2,0.7-20.4c0,0-0.4-14-3-24.5c0,0-1.5-4.8-6.5-6.1c0,0-2.3-0.7-11.1-1
		c0,0-1,0-1,1.1l-0.4,53.6C561.9,315.5,561.8,317.2,563.2,317z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M261.8,338.5l61.9,0.1h59.4
		h38.1h67.5h66.9l24.6-0.1c0,0,2.3-0.1,3,1.1c0,0,0.6,0.4,0.3,2.3c-0.3,2.1-0.7,7.6-5.1,21.3c0,0-3.6,12.4-8,16.1
		c0,0-2.6,3.7-13.6,3.4l-13.5,0.6l-41.3,1.6l-5.6,0.3c0,0-2.1,0-2.8,0.7c0,0-0.8,1.1-8.4,1.1l-0.7-0.6l-1,0.4c0,0-44.7,0.5-47.3,0.1
		c0,0-2.8,0.1-3.3-1.2c0,0-1.1-1.6-2.1-1.8c0,0-0.6-0.1-1.1-0.1s-17.3,0-17.3,0s-0.8,0-1.5,0.6l-1.4,1.4c0,0-1.1,1.5-4.3,1.5
		l-46.5-0.3l-0.8-0.6c0,0-0.7,0.7-1.2,0.7h-5.4c0,0-1.6-0.1-2.3-1c0,0-1.1-0.6-4-0.7l-59.6-2.9c0,0-12.1,0.7-16.6-7.6
		c0,0-3.3-5.8-7.8-21.5c0,0-1.4-4.5-2.3-12C258.8,341.7,258.1,338.4,261.8,338.5z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup4DrRear);
