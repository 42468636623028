import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import ConsignorsNav from "../components/ConsignorsNav";

export default class Consignors extends Component {
  render() {
    return (
      <DocumentTitle title={`Consignors`}>
        <div>
          <ConsignorsNav />
          <div className="container-fluid" />
        </div>
      </DocumentTitle>
    );
  }
}
