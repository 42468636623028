import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-modal';
import { find } from 'lodash';
import * as momentTZ from 'moment-timezone';
import {
  loadAddWishlistItem,
  loadUpdateWishlistItem,
  loadWishlistItems,
} from '../../actions';
import {
  getConfig,
  getParamFromUrl,
  isJSON,
  removeParamFromQuery,
} from '../../utils/helpers';

const styles = {
  customStyles: {
    content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 200,
      maxHeight: 750,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {
    padding: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
};

class WishlistModal extends Component {
  state = {
    wishlistId: null,
    wishlistName: '',
    viewUpdated: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const query =
      window.location.search.substring(1, window.location.search.length) || '';
    const wishlistId = getParamFromUrl('wishlistId');
    const wishlistItem = wishlistId
      ? find(this.props.wishlistItems.items, {
          id: Number(wishlistId),
        })
      : null;

    if (
      wishlistItem &&
      !this.state.viewUpdated &&
      this.props.searchResultsCount !== undefined // have to wait for these results
    ) {
      // this runs ONCE when the search page first loads (i.e. not mounts)
      // pre-populate the name field from wishlistId in url
      this.setState(
        {
          wishlistId,
          wishlistName: wishlistItem.name || '',
          viewUpdated: true,
        },
        () => {
          //updates the wishlist item with latest timestamp and count
          this.updateLastViewed(wishlistItem);
        }
      );
    } else if (!query && prevState.wishlistId) {
      // resets state when url is cleared
      this.setState({
        wishlistId: null,
        wishlistName: '',
        viewUpdated: false,
      });
    }
  }

  onOpen = () => {
    this.inputRef.focus();
    this.inputRef.select();
  };

  onClose = () => {
    this.props.onClose();
  };

  changeInputName = e => {
    this.setState({ wishlistName: e.target.value });
  };

  updateLastViewed = wishlistItem => {
    const { searchResultsCount } = this.props;

    let originalValue = isJSON(wishlistItem.value)
      ? JSON.parse(wishlistItem.value)
      : { query: wishlistItem.value };
    let { query } = originalValue;
    query = decodeURIComponent(query);
    query = removeParamFromQuery('distance', query);
    query = removeParamFromQuery('wishlistId', query);
    query = encodeURIComponent(query);

    const updatedValue = JSON.stringify({
      query, // same query but with location and wishlistId stripped out
      postFilter: originalValue.postFilter, // keep same
      geo: originalValue.geo, // keep same
      lastCount: searchResultsCount, // updated
      lastViewed: momentTZ.tz().unix() * 1000, // updated
    });

    const mpId = getConfig('marketplaceId');
    this.props
      .loadUpdateWishlistItem(
        mpId,
        wishlistItem.id,
        wishlistItem.name,
        updatedValue
      )
      .then(({ response }) => {
        if (response && response.wsStatus === 'Success') {
          this.props.loadWishlistItems(mpId);
        }
      });
  };

  addWishlistItem = e => {
    // when uses clicks Create button
    e.preventDefault();
    const { wishlistName } = this.state;
    const {
      userSelectedLocation,
      userSelectedDistance,
      searchResultsCount,
      postFilter,
    } = this.props;

    let query =
      window.location.search.substring(1, window.location.search.length) || '';
    query = removeParamFromQuery('distance', query);
    query = removeParamFromQuery('wishlistId', query);
    query = encodeURIComponent(query);

    const value = JSON.stringify({
      query,
      postFilter,
      geo: { userSelectedLocation, userSelectedDistance },
      lastCount: searchResultsCount,
      lastViewed: momentTZ.tz().unix() * 1000,
    });

    if (wishlistName !== '') {
      const mpId = getConfig('marketplaceId');
      this.props
        .loadAddWishlistItem(mpId, wishlistName.trim(), value)
        .then(({ response }) => {
          if (response && response.wsStatus === 'Success') {
            toastr.success('Success', 'Search Saved');
            this.props.loadWishlistItems(mpId);
            this.onClose();
          } else {
            toastr.error('Error', 'Error updating saved search');
          }
        })
        .catch(err => console.error('loadAddWishlistItem error', err));
    }
  };

  updateWishlistItem = e => {
    // when uses clicks Update button
    // Update button only shows when name matches existing
    e.preventDefault();
    const { wishlistName } = this.state;
    const {
      userSelectedLocation,
      userSelectedDistance,
      searchResultsCount,
      wishlistItems,
      postFilter,
    } = this.props;

    const wishlistItem = find(wishlistItems.items, { name: wishlistName });

    if (wishlistItem) {
      let query =
        window.location.search.substring(1, window.location.search.length) ||
        '';
      query = removeParamFromQuery('distance', query);
      query = removeParamFromQuery('wishlistId', query);
      query = encodeURIComponent(query);

      const value = JSON.stringify({
        query,
        postFilter,
        geo: { userSelectedLocation, userSelectedDistance },
        lastCount: searchResultsCount,
        lastViewed: momentTZ.tz().unix() * 1000,
      });

      const mpId = getConfig('marketplaceId');
      this.props
        .loadUpdateWishlistItem(mpId, wishlistItem.id, wishlistName, value)
        .then(({ response }) => {
          if (response && response.wsStatus === 'Success') {
            toastr.success('Success', 'Saved Search Updated');
            this.props.loadWishlistItems(mpId);
            this.onClose();
          } else {
            toastr.error('Error', 'Error updating saved search');
          }
        })
        .catch(err => console.error('loadUpdateWishlistItem error', err));
    }
  };

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>Save Search</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderContent() {
    return (
      <div style={styles.body}>
        <label>Name</label> <br />
        <input
          ref={el => (this.inputRef = el)}
          autoFocus
          type="text"
          className="form-control"
          placeholder="Name"
          value={this.state.wishlistName}
          onChange={this.changeInputName}
          onFocus={e => e.target.select()}
        />
      </div>
    );
  }

  renderFooter() {
    const { wishlistItems } = this.props;
    const { wishlistName } = this.state;
    const wishlistNameExists = (wishlistItems.items || []).some(
      item => item.name === wishlistName
    );

    return (
      <div style={styles.footer}>
        <div />
        <div style={styles.buttons}>
          <button
            className="btn btn-primary button-cancel"
            style={{
              minWidth: 100,
              marginRight: 10,
            }}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>

          {!wishlistNameExists && (
            <button
              className="btn btn-primary button-action"
              style={{ minWidth: 100 }}
              type="button"
              onClick={this.addWishlistItem}
              disabled={!wishlistName}
            >
              Create
            </button>
          )}

          {wishlistNameExists && (
            <button
              style={{ backgroundColor: '#6D6D6D', minWidth: 100 }}
              type="button"
              onClick={this.updateWishlistItem}
              className="btn btn-primary"
              disabled={!wishlistName}
            >
              Update
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Wishlist Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div style={styles.container}>
          {this.renderHeader()}
          {this.renderDivider()}
          {this.renderContent()}
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

WishlistModal.propTypes = {
  loadAddWishlistItem: PropTypes.func.isRequired,
  loadUpdateWishlistItem: PropTypes.func.isRequired,
  userSelectedLocation: PropTypes.object.isRequired,
  wishlistItems: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { wishlistItems } = state.entities;
  return { wishlistItems };
};

export default connect(mapStateToProps, {
  loadAddWishlistItem,
  loadUpdateWishlistItem,
  loadWishlistItems,
})(WishlistModal);
