import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { Button } from 'semantic-ui-react';
import { loadSellerSold, resetSellerSold } from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';

class SellerSold extends Component {
  state = {
    title: 'sold',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerSold();
    this.props.loadSellerSold(getConfig('marketplaceId'));
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  render() {
    const loading = isEmpty(this.props.sellerSold);
    const data = this.props.sellerSold.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'sellFee',
      'seller',
      'buyer',
      'soldDate',
      'paymentStatus',
      'titleStatus',
      {
        Header: 'Actions',
        headerStyle: { background: 'none' },
        id: 'buttons',
        sortable: false,
        filterable: false,
        Cell: row => {
          return (
            <div style={styles.buttonGroup}>
              <Button
                style={styles.button}
                onClick={() =>
                  this.handleDetailsClick(row.original.itemId, data)
                }
              >
                View Details
              </Button>
              {row.original.sellerReceipt && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.sellerReceipt}
                >
                  Receipt
                </a>
              )}
            </div>
          );
        },
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Sold Vehicles</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sellerSold } = state.entities;
  return { sellerSold };
};

export default compose(
  withTable,
  connect(mapStateToProps, { loadSellerSold, resetSellerSold })
)(SellerSold);
