import { FETCH_API } from '../../middleware';

export const LOT_REQUEST = 'LOT_REQUEST';
export const LOT_SUCCESS = 'LOT_SUCCESS';
export const LOT_FAILURE = 'LOT_FAILURE';
export const RESET_LOT = 'RESET_LOT';

const fetchLots = (vin, saleId, accountNumber, keyword, apiKey) => ({
  [FETCH_API]: {
    types: [LOT_REQUEST, LOT_SUCCESS, LOT_FAILURE],
    endpoint:
      `get-lot-number?apiKey=${apiKey}` +
      `&searchType=VIN&searchText=${vin}&saleId=${saleId}` +
      `&accountNumber=${accountNumber}` +
      `${keyword ? `&keyword=${keyword}` : ''}`,
    core: true,
  },
});

export const loadLots = (vin, saleId, accountNumber, keyword, apiKey) => {
  return dispatch =>
    dispatch(fetchLots(vin, saleId, accountNumber, keyword, apiKey));
};

export const resetLot = () => ({
  type: RESET_LOT,
});
