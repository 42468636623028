import React, { Component } from 'react';
import { connect } from 'react-redux';

class Suv2DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M146.5,200c0-0.6-0.1-0.8-0.6-1l-0.6-0.1l-12-1.1v7.3l2.2-0.3l7-0.7l2.9-0.3c0.7,0,1-0.3,1-1L146.5,200
	L146.5,200z"
          />
          <path
            fill="#656565"
            d="M146.5,202.4h2.3v-2.1h-2.3 M135.6,204.9c1.2,0.6,2.3,2.1,3.6,4.3l5.9,11.3c0.8,1.4,1.2,4,1.5,7.8l2.9,34.8
	v-1.6h4.1l-2.8-33.8c-0.3-3.7-0.8-6.5-1.7-8.4l-6.6-15L135.6,204.9L135.6,204.9z"
          />
          <path
            fill="#656565"
            d="M93.1,221.2l4.5,108c0.6,3.9,2.9,6.3,6.9,7.4c4.8,0.7,10.5,0.8,17.1,0.4h0.6l0.1-4.3c1-4,3.2-6.1,6.6-6.1
	l18,0.8v-0.5l-4.5-108c-0.6-3.9-2.9-6.3-6.9-7.4c-4.8-0.7-10.5-0.8-17.1-0.4l-2.2,0.1c-6.6,0.1-12.1,0.8-16.9,1.9
	C95.5,214.7,93.4,217.3,93.1,221.2L93.1,221.2z M149.5,263.9V295h6.1c0.6,0,1-0.1,1.4-0.4s0.7-0.8,0.8-1.4l0.6-29.6
	c0-0.4-0.1-0.8-0.3-1.1c-0.3-0.7-1-1.1-1.8-1.1h-6.9v2.5L149.5,263.9L149.5,263.9z"
          />
          <path
            fill="#656565"
            d="M153.9,301.1V295h-3.4v4.7 M152.1,308.6c0.1,0.3,0.3,0.4,0.6,0.5h1.2V304h-0.8c-0.7,0-1,0.3-1,1L152.1,308.6
	L152.1,308.6z M151,318.7l2.5,1.4l0.4,0.1v-10.9h-0.8l-0.6-0.1l-1.5,1.1 M146.9,327.4l-18-0.8c-3.4,0-5.8,1.9-6.6,6.1l-0.1,4.3
	l-0.4,14.6c0,1.4,1,3.6,2.9,6.6l1.9,3.2l0.6,0.8c0.3,0.4,0.7,0.8,1.2,1.1c0.7,0.3,1.6,0.4,2.8,0.3l20.6-0.3c1.6-0.1,2.5-0.8,2.5-2.1
	l-20.9,0.5h-1.1h1.1c-1.8-0.1-3.2-0.7-4-1.6c-2.5-4.1-3.7-7.4-4-9.8v-14.6c-0.3-4.1,1.2-6.3,4.7-6.6h25c-0.1-0.5-0.4-1-1-1.2
	c-0.6-0.3-1.4-0.4-2.5-0.4L146.9,327.4L146.9,327.4L146.9,327.4z"
          />
          <path
            fill="#656565"
            d="M153.9,323v-2.8l-0.4-0.1h-2.6v2.3 M125.3,350.3c0.1,2.3,1.5,5.5,4,9.8c0.8,1.1,2.2,1.7,4,1.7l20.9-0.5
	l3.2-0.1l13.1-0.3c2.8,0,4.5-0.4,5.2-1.5l0.6-1.1l9.5-17.9c2.9-5.8,4.4-9.5,4.4-11.3H130c-3.4,0.3-4.9,2.6-4.7,6.6L125.3,350.3
	L125.3,350.3z M134.4,348.1c0,0.3-0.1,0.5-0.6,0.5h-7c-0.3,0-0.6-0.1-0.6-0.5v-14c0-0.3,0.1-0.6,0.6-0.6h7c0.3,0,0.6,0.1,0.6,0.5
	V348.1z"
          />
          <path
            fill="#FFFFFF"
            d="M314.6,153.6H210.5c-10.9,0.3-21.3,1.2-31.2,3l-0.6,4l-0.8,5.5l89.4,0.6h77.2v-11.4
	C336.1,154.2,326.2,153.5,314.6,153.6L314.6,153.6z"
          />
          <path
            fill="#FFFFFF"
            d="M345.8,165.9l105.5,2.1l3.2-4.5c0.3-1.4,0.1-2.5-0.3-3.6c-6.7-1.4-15.1-2.2-25.2-2.5l-84.7-1.8V167h1.5
	L345.8,165.9L345.8,165.9z"
          />
          <path
            fill="#FFFFFF"
            d="M344.3,166.8h-77.2l-89.4-0.6c-3.7,28.3-6.9,58.1-9.4,89.4l178.3,1.5l-1-90.4L344.3,166.8L344.3,166.8z
	 M331.5,180.3v49.1c-0.1,3.6-1.4,6.1-4,7.7c-2.3,1.5-5.9,2.2-10.6,1.9H204.1c-4.3-0.3-7.4-1-9.4-2.3c-2.2-1.4-3-3.4-2.6-5.9
	l4.4-48.1c0.4-5.6,3-8.5,7.8-8.8h117.8C328.4,173.8,331.5,175.9,331.5,180.3L331.5,180.3z"
          />
          <path
            fill="#656565"
            d="M322.2,174H204.4c-4.8,0.3-7.4,3.2-7.8,8.8l-4.4,48.1c-0.4,2.5,0.4,4.5,2.6,5.9c1.9,1.2,5.1,2.1,9.4,2.3
	h112.8c4.7,0.3,8.1-0.3,10.6-1.9c2.5-1.6,3.9-4.1,4-7.7v-49.2C331.5,175.9,328.4,173.8,322.2,174z M330.2,180.3v49.1
	c-0.1,3-1.2,5.1-3.3,6.5c-2.2,1.4-5.4,1.9-9.8,1.6H204.3c-4-0.3-6.9-1-8.7-2.1c-1.6-1.1-2.2-2.5-1.9-4.4l4.4-48.1
	c0.3-4.7,2.5-7.2,6.5-7.4h117.6C327.3,175.3,329.9,177,330.2,180.3L330.2,180.3z M172.9,263.4c-2.3,2.3-3.6,5.2-3.6,8.5
	c0,3.3,1.2,6.2,3.6,8.5c2.3,2.3,5.2,3.6,8.5,3.6c3.3,0,6.2-1.2,8.5-3.6c2.3-2.3,3.6-5.2,3.6-8.5c0-3.3-1.2-6.2-3.6-8.5
	c-2.3-2.3-5.2-3.6-8.5-3.6C178.1,259.8,175.4,261,172.9,263.4L172.9,263.4z M181.4,263.2c2.3,0,4.4,0.8,6.1,2.5
	c1.6,1.6,2.5,3.7,2.5,6.1c0,2.3-0.8,4.4-2.5,6.1c-1.6,1.6-3.7,2.5-6.1,2.5c-2.3,0-4.4-0.8-6.1-2.5s-2.5-3.7-2.5-6.1
	c0-2.3,0.8-4.4,2.5-6.1C177,264.1,179.1,263.2,181.4,263.2L181.4,263.2z"
          />
          <path
            fill="#656565"
            d="M175.4,265.9c-1.6,1.6-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1c1.6,1.6,3.7,2.5,6.1,2.5
	c2.3,0,4.4-0.8,6.1-2.5c1.6-1.7,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.1c-1.6-1.6-3.7-2.5-6.1-2.5C179.1,263.2,177,264.1,175.4,265.9
	L175.4,265.9z M181.4,265.9c1.6,0,3,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.3s-0.6,3-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8
	c-1.6,0-3-0.5-4.3-1.8c-1.2-1.2-1.8-2.6-1.8-4.3c0-1.6,0.6-3,1.8-4.3C178.4,266.4,179.8,265.9,181.4,265.9L181.4,265.9z"
          />
          <path
            fill="#656565"
            d="M177.2,267.6c-1.2,1.2-1.8,2.6-1.8,4.3c0,1.6,0.6,3,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8c1.6,0,3-0.5,4.3-1.8
	c1.2-1.2,1.8-2.6,1.8-4.3c0-1.6-0.6-3-1.8-4.3c-1.2-1.2-2.6-1.8-4.3-1.8C179.8,265.9,178.4,266.4,177.2,267.6L177.2,267.6z
	 M185.7,271.9c0,1.1-0.4,2.2-1.2,3c-0.8,0.8-1.8,1.2-3,1.2c-1.1,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.4-2.2,1.2-3
	c0.8-0.8,1.8-1.2,3-1.2c1.1,0,2.2,0.4,3,1.2C185.3,269.7,185.7,270.8,185.7,271.9L185.7,271.9z"
          />
          <path
            fill="#656565"
            d="M184.5,268.9c-0.8-0.8-1.8-1.2-3-1.2c-1.1,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.1,0.4,2.2,1.2,3
	c0.8,0.8,1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.8,1.2-3C185.7,270.8,185.3,269.7,184.5,268.9L184.5,268.9z M184.5,271.9
	c0,0.8-0.3,1.5-0.8,2.1s-1.2,0.8-2.1,0.8s-1.5-0.3-2.1-0.8s-0.8-1.2-0.8-2.1c0-0.8,0.3-1.5,0.8-2.1s1.2-0.8,2.1-0.8
	c0.8,0,1.5,0.3,2.1,0.8C184.2,270.4,184.5,271.1,184.5,271.9L184.5,271.9z"
          />
          <path
            fill="#656565"
            d="M183.6,269.9c-0.6-0.5-1.2-0.8-2.1-0.8s-1.5,0.3-2.1,0.8c-0.6,0.5-0.8,1.2-0.8,2.1c0,0.8,0.3,1.5,0.8,2.1
	s1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8s0.8-1.2,0.8-2.1C184.5,271.1,184.2,270.4,183.6,269.9L183.6,269.9z M163.1,171.9l-0.4,10.5
	l-0.1,1.9c-0.3,1.1,0,1.6,0.7,1.6l0.6-3.4v-0.1l1.9-12.4l0.3-1.2C164.2,169,163.3,170.1,163.1,171.9L163.1,171.9z"
          />
          <path
            fill="#656565"
            d="M160.8,182.1l-0.4,3l-0.8,5.1l-0.3,1.8l3,0.6l1-6.7c-0.7,0-0.8-0.6-0.7-1.6l0.1-1.9L160.8,182.1L160.8,182.1
	z"
          />
          <path
            fill="#656565"
            d="M160.3,185.1c-0.4,0-0.8,0.3-1.2,0.8c-0.3,0.6-0.6,1.1-0.6,1.9c0,0.7,0.1,1.4,0.6,1.9l0.6,0.7l0.8-5.1
	L160.3,185.1z M273.9,296c-21.9-1.9-41-2.2-57.2-0.7c-6.3,0.5-11.3,3.3-15,8.1c-1.4,1.8-2.6,4-3.6,6.3l-7.2,16l-1.9,0.1l-1.4,3.4
	h2.8c0,1.8-1.5,5.6-4.4,11.3l-9.5,17.9l19.8-0.8l2.2-6.2c1.9-4.7,4.4-9.1,7.6-13.2l0.3-0.3c1.4-1.8,2.9-3.6,4.5-5.4l1.5-1.5
	c12.4-12.4,27.4-18.6,45-18.6s32.6,6.2,45,18.6l1.5,1.5c1.6,1.8,3.2,3.6,4.5,5.4c1,1.2,1.8,2.5,2.8,3.9c2.2,3.3,4,6.9,5.5,10.6
	l8.3-0.4c0-0.7,0.3-1,1-1h3.6l-5.2-8.1c-7-10.6-14.2-20.4-21.3-29.4l-4.8-5.9l-4.5-4.8c-0.7-0.5-1.4-1.2-2.1-1.6
	c-0.4-0.3-0.8-0.5-1.1-0.8C285.9,297.8,280.4,296.4,273.9,296L273.9,296z"
          />
          <path
            fill="#FFFFFF"
            d="M290.1,300c0.4,0.3,0.8,0.5,1.1,0.8c0.8,0.5,1.5,1.1,2.1,1.6l4.5,4.8l4.8,5.9c7.2,9.1,14.3,18.8,21.3,29.4
	l5.2,8.1h15.7l-38.5-51.3c-5.5-7.3-12.8-11.6-21.6-12.8c-20.1-2.5-42.4-3.3-66.6-2.8c-10.5-0.3-18.2,2.2-23.1,7.6l-1.7,2.2l-5,7.6
	c-4.1,6.7-8.3,15-12.2,24.6c4.1-9.6,8.3-17.9,12.2-24.6l5-7.6l1.6-2.2c5-5.4,12.7-7.8,23.1-7.6c24.2-0.7,46.5,0.3,66.6,2.8
	c8.8,1.1,16.1,5.4,21.6,12.8l38.5,51.3h8.7c-10-14.6-19.8-28.3-29.7-41.1L311,293.2c-6.9-8.5-18-13.1-33.2-14
	c-21.7-2.1-44.4-2.5-68.1-1c-9.4,0.8-16.4,5-21,12.5c-6.6,10.2-12.9,22.1-19.1,35.6l19.1-0.7l1.9-0.1l7.2-16
	c1.1-2.5,2.2-4.5,3.6-6.3c3.7-4.8,8.7-7.6,15-8.1c16.2-1.5,35.4-1.2,57.2,0.7C280.4,296.4,285.9,297.8,290.1,300L290.1,300z"
          />
          <path
            fill="#656565"
            d="M158.5,263.5l-0.6,29.6c-0.1,0.5-0.4,1.1-0.8,1.4c1.6,0.1,2.6-0.8,2.6-2.8l0.6-27c-0.3-1.5-1-2.3-1.9-2.5
	C158.5,262.8,158.5,263.1,158.5,263.5L158.5,263.5z"
          />
          <path
            fill="#FFFFFF"
            d="M662.1,255.4l0.5,3.6l0.1,0.8l0.1,0.3h1.1c0.1-0.1,0.1-0.3,0.1-0.3l-0.1-1.9v-1h2.9l0.3,1l0.4,1.9l0.1,0.3
	l0.3,0.1h0.4l0.3-0.1h0.1c0.1-0.1,0.1-0.3,0.1-0.3l-0.1-0.8l-0.5-3.6l-0.1-0.3c-0.1-0.1-0.3-0.1-0.4-0.1h-5.2c-0.1,0-0.3,0-0.3,0.1
	C662.1,255.1,662.1,255.3,662.1,255.4L662.1,255.4z M669.9,260.8h-1.4l-0.1,0.1l-0.1,0.1v0.3l0.5,2.9v0.3c0,0.3,0.3,0.5,0.5,0.5h1.1
	l0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.4l-0.6-3.2C670.3,261,670.2,260.8,669.9,260.8L669.9,260.8z"
          />
          <path
            fill="#656565"
            d="M670.9,264.9l-0.3,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5v-0.3l-0.4,0.4l0.1,0.4c0,0.3,0.3,0.5,0.5,0.5h1.1
	c0.1,0,0.3,0,0.4-0.1L670.9,264.9L670.9,264.9z"
          />
          <path
            fill="#FFFFFF"
            d="M670.5,271.4h-2.3l0.1,1.8h-2.2v-1.8h-1.9c-0.3,0-0.5,0.1-0.5,0.5v2.5l7.4,0.8v-3.3
	C671,271.5,670.9,271.4,670.5,271.4L670.5,271.4z"
          />
          <path fill="#FFFFFF" d="M671.7,275.1v-1.6H671v1.6" />
          <path
            fill="#FFFFFF"
            d="M671,275.1l-7.4-0.8c-22.6-2.2-46.6-2.5-72.5-0.8c-11.7,1-21,6.3-28.1,15.7c-13.1,16.4-26.4,36-40,58.9h26
	c11.6-17.7,23.1-33.7,34.7-48.1c4.4-5.9,10.3-8.9,17.3-9.1h49.4c10.9,0.1,18.7,1.1,23.8,2.8c4.5,1.5,9.2,5.5,14.2,12
	c0.5,1,1.4,1.4,2.5,1.2h9.5l2.6-0.3l5.1-1.7c1.1-0.4,1.5-1,1.1-1.9c-2.3-6.2-4.7-11.3-7.2-15.3c-0.8-1.4-1.8-2.6-2.9-3.7
	c-1-1-2.2-1.9-3.4-2.8c-1.1-0.7-2.3-1.4-3.7-1.9c-0.8-0.3-1.5-0.7-2.5-1c-3.4-1.2-7.4-1.9-12-2.3l-5.9-0.8L671,275.1L671,275.1z
	 M694,292.4c0.8,0.8,1.2,1.9,1.2,3c0,1.2-0.4,2.2-1.2,3c-0.8,0.8-1.9,1.2-3,1.2c-1.2,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.9-1.2-3
	c0-1.2,0.4-2.2,1.2-3c0.8-0.8,1.9-1.2,3-1.2C692.1,291.2,693.2,291.6,694,292.4L694,292.4z M661.3,259.1l-0.1,0.4v0.3l0.1,0.3
	l0.3,0.1h1.5l-0.1-0.3l-0.1-0.8h-1.1C661.5,259,661.4,259,661.3,259.1L661.3,259.1z M662.5,260.9l-0.1,0.3v0.3l0.6,3.2
	c0,0.3,0.3,0.5,0.5,0.5h1.1l0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.4v-0.1l-0.5-3l-0.1-0.4c-0.1-0.1-0.3-0.1-0.4-0.1H662.5L662.5,260.9z"
          />
          <path
            fill="#FFFFFF"
            d="M662.8,260.8h1.1c0.1,0,0.3,0,0.4,0.1h4.3l0.1-0.1h0.5l-0.1-0.5l-0.3,0.1h-0.4l-0.3-0.1L668,260l-0.4-1.9
	h-3.2l0.1,1.9c0,0.1,0,0.3-0.1,0.3h-1.7L662.8,260.8L662.8,260.8z"
          />
          <path
            fill="#FFFFFF"
            d="M665,264.4l0.8,0.5v2.1l0.1,4.4l0.1,1.8h2.2l-0.1-1.8l-0.1-4.3V265l0.5-0.4l0.4-0.3l-0.5-3v-0.4h-4.3
	l0.3,0.4"
          />
          <path
            fill="#656565"
            d="M662,261.5v0.4l0.5,3.2c0,0.3,0.3,0.5,0.5,0.5h1.1c0.1,0,0.3,0,0.4-0.1l0.3-0.4l-0.3,0.1h-1.1
	c-0.3,0-0.5-0.1-0.5-0.5l-0.5-3.2v-0.3L662,261.5L662,261.5z M650.5,290.8h-49.4c-7.2,0.1-12.9,3.3-17.3,9.1
	c-11.6,14.4-23.1,30.4-34.7,48.1h-16l-2.1,2.8h14.7c0.7,0,0.8,0.3,0.4,1l-1.5,2.9l28.3-0.1c1.9-7.6,5.4-14.7,10.3-21l0.3-0.3
	c1.4-1.8,2.9-3.6,4.5-5.4l1.5-1.5c12.4-12.4,27.4-18.6,45-18.6c17.6,0,32.6,6.2,45,18.6l1.5,1.5c1.7,1.8,3.2,3.6,4.5,5.4
	c1,1.2,1.8,2.5,2.8,3.9c3.4,5.4,6.1,11.1,7.7,17.2h5.2L690.3,310l1.9-1h9.6l6.9-1.8h0.4l-1-2.3l-5.1,1.6l-2.6,0.3H691
	c-1.1,0.1-1.9-0.3-2.5-1.2c-5-6.5-9.6-10.3-14.2-12C669.2,291.9,661.4,291,650.5,290.8L650.5,290.8z"
          />
          <path
            fill="#FFFFFF"
            d="M489.9,148.9c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1l0.1,0.4h0.1v62
	l0.7,1.2v-63.3c0.1-0.1,0.1-0.3,0.1-0.4v-1.1C490.1,149.2,490,149.1,489.9,148.9L489.9,148.9z"
          />
          <path fill="#656565" d="M584.2,243.2l-0.8-4.3v-0.1l-5-0.6l-1.8,4.3" />
          <path
            fill="#656565"
            d="M585.1,239.6c0-0.3-0.1-0.6-0.5-0.6l-1.2-0.1l0.8,4.1l0.8,0.1v-3.6H585.1L585.1,239.6z"
          />
          <path
            fill="#FFFFFF"
            d="M485.6,223.8c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4
	c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1C484.9,223.6,485.2,223.8,485.6,223.8L485.6,223.8z M489.9,228.9
	l-0.4,0.3l-0.3,0.6v0.8l0.3,0.4l0.4,0.3l0.5,0.1c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.5-0.4-1-0.4L489.9,228.9L489.9,228.9z"
          />
          <path fill="#656565" d="M489.2,230.5v-1L489,230" />
          <path
            fill="#FFFFFF"
            d="M497.2,242.5c-0.3-0.3-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1
	c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1S497.4,242.8,497.2,242.5z M500.5,235.2l0.5,0.7
	c0.3,0.1,0.4,0.1,0.5,0.1c0.1-0.1,0.3-0.3,0.1-0.6c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.3-0.4-0.6-0.5-0.7l-0.5-0.1
	c-0.1,0.1-0.3,0.3-0.1,0.6C500.2,234.6,500.3,234.9,500.5,235.2L500.5,235.2z M498.5,250.9c-0.3-0.3-0.5-0.4-1-0.4s-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1C498.9,251.6,498.8,251.1,498.5,250.9
	L498.5,250.9z"
          />
          <path
            fill="#656565"
            d="M508,236.4l-0.1,0.4l-0.1,0.6l2.5,0.3l-0.3-0.7l-0.1-0.4"
          />
          <path
            fill="#FFFFFF"
            d="M531.1,350.7H325.8c-0.7,0-1,0.3-1,1v9.2c0,0.7,0.3,1,1,1h214.2c0.7,0,1.1-0.3,1.5-1l3.4-6.3l1.5-2.9
	c0.3-0.7,0.1-1-0.4-1H531.1L531.1,350.7z"
          />
          <path
            fill="#656565"
            d="M588.1,327.8c-1.7,1.8-3.2,3.6-4.5,5.4l-0.3,0.3c-4.8,6.5-8.3,13.5-10.3,21c-1.4,5.2-2.1,10.9-2.1,16.6
	c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3c1.8,2.3,3.9,4.7,6.1,6.9c2.1,2.1,4.3,4,6.5,5.8c11,8.7,23.9,12.9,38.5,12.9
	c14.6,0,27.5-4.3,38.5-12.9c2.2-1.8,4.4-3.6,6.5-5.8c2.2-2.2,4.3-4.5,6.1-6.9c1-1.2,1.8-2.5,2.8-3.9c6.6-10,9.8-21.5,9.8-34.3
	c0-5.9-0.7-11.6-2.2-16.9c-1.7-6.2-4.1-11.8-7.7-17.2c-0.8-1.2-1.8-2.6-2.8-3.9c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5
	c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6L588.1,327.8L588.1,327.8z M586,334.9c1.4-1.8,2.8-3.4,4.4-5.1l2.6-2.6
	c11.6-11,25.5-16.5,41.5-16.5s30,5.5,41.5,16.5l2.6,2.6c1.5,1.6,3,3.4,4.4,5.1c1.9,2.6,3.7,5.4,5.2,8.1c4.4,8.4,6.7,17.7,6.7,28.1
	s-2.2,19.7-6.7,28.1c-1.5,2.8-3.3,5.5-5.2,8.1c-1.8,2.3-3.7,4.4-5.8,6.6c-3,3-6.2,5.6-9.6,8c-9.8,6.5-20.8,9.8-33.2,9.8
	s-23.5-3.3-33.2-9.8c-3.3-2.2-6.6-5-9.6-8c-2.1-2.1-4-4.3-5.8-6.6c-1-1.2-1.9-2.6-2.8-4c-6.1-9.5-9.1-20.2-9.1-32.2
	c0-12.1,3-22.8,9.1-32.3C584.1,337.5,585.1,336.3,586,334.9L586,334.9z"
          />
          <path
            fill="#656565"
            d="M583.3,338.9c-6.1,9.5-9.1,20.2-9.1,32.3c0,12,3,22.7,9.1,32.2c0.8,1.4,1.8,2.6,2.8,4
	c1.8,2.3,3.7,4.4,5.8,6.6c3,3,6.2,5.6,9.6,8c9.6,6.5,20.8,9.8,33.2,9.8s23.5-3.3,33.2-9.8c3.3-2.2,6.6-5,9.6-8
	c2.1-2.1,4-4.3,5.8-6.6c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.8-3.3-5.5-5.2-8.1
	c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6c-1.5,1.6-3,3.3-4.4,5.1
	C585.1,336.3,584.1,337.5,583.3,338.9L583.3,338.9z M598,388.2c-2.5-5.2-3.6-10.9-3.6-17.1c0-11.1,4-20.6,11.8-28.5
	c7.8-7.8,17.3-11.8,28.5-11.8s20.6,4,28.5,11.8c7.8,7.8,11.8,17.3,11.8,28.5c0,7.2-1.7,13.5-4.8,19.3c-1.8,3.3-4.1,6.3-7,9.2
	c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6c-5.2,0-10.2-0.8-14.6-2.6c-5.1-1.9-9.6-5-13.8-9.1
	C602.7,396.1,599.9,392.3,598,388.2L598,388.2z"
          />
          <path
            fill="#656565"
            d="M610.8,347.3c-1.8,1.8-3.4,3.7-4.7,5.8l8.9,1.9l1.7-1.6c2.5-2.5,5.4-4.4,8.4-5.6l1-9.1
	C620.3,339.9,615.2,342.9,610.8,347.3L610.8,347.3z M630.7,367.4c-1.1,1-1.7,2.3-1.7,3.7c0,1.5,0.5,2.8,1.7,3.9c1,1,2.3,1.5,3.7,1.5
	c1.5,0,2.8-0.5,3.7-1.5c1-1.1,1.5-2.3,1.5-3.9s-0.5-2.8-1.5-3.7c-1.1-1.1-2.3-1.5-3.7-1.5C633.1,365.9,631.8,366.3,630.7,367.4z
	 M633.9,370.7l0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v0.3c-0.1,0.3-0.3,0.4-0.5,0.4h-0.3l-0.4-0.1l-0.4-0.1l-0.1-0.1h0.6l0.1,0.1h0.1
	c0.1,0,0.3-0.1,0.3-0.1v-0.1h-1.2v-0.3l0.1-0.3c0.1-0.3,0.3-0.3,0.5-0.4H633.9L633.9,370.7z M634.6,371c0.1-0.3,0.3-0.3,0.5-0.4h0.4
	l0.4,0.1c0.1,0.1,0.3,0.1,0.3,0.3v0.1l0.1,0.3l-0.1,0.4c-0.1,0.3-0.3,0.3-0.5,0.4h-0.3l-0.5-0.1c-0.1,0-0.3-0.1-0.3-0.1l-0.1-0.1
	h0.6l0.1,0.1h0.1l0.1-0.1v-0.3h-1.2v-0.3L634.6,371L634.6,371z M631.4,370.5l0.3,0.1l0.1,0.1v-0.3h0.5v2.1h-0.5v-0.5l-0.1,0.1H631
	l-0.1-0.1l-0.1-0.3v-0.5l0.1-0.3l0.3-0.3L631.4,370.5L631.4,370.5z M638.6,371.1v0.6l-0.4,0.4l-0.7,0.1h-0.3l-0.5-0.3l-0.1-0.3v-1.4
	h0.7v1.5l0.1,0.3h0.3l0.3-0.1v-0.4h0.7L638.6,371.1L638.6,371.1z"
          />
          <path
            fill="#656565"
            d="M633.6,371l-0.1-0.1h-0.1l-0.3,0.3h0.7 M635.3,370.8L635,371v0.1h0.5V371c0-0.1-0.1-0.1-0.1-0.1L635.3,370.8
	L635.3,370.8z M631.8,371.4l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1h-0.1l-0.1,0.1v0.4h0.1c0,0.1,0.1,0.1,0.3,0.1h0.1
	c0.1,0,0.1,0,0.1-0.1L631.8,371.4L631.8,371.4z M658.4,347.3c-4.5-4.5-9.6-7.4-15.4-8.9l1,9.1c3.2,1.2,5.9,3.2,8.5,5.6l1.4,1.5
	l8.9-1.9h0.1C661.7,351,660.2,349.1,658.4,347.3L658.4,347.3z M701.3,354.2l0.8,3.2h15.1l-7-30.7l5.4-1.9l-0.4-1.9l-4-15.7L703,310
	h-12.8"
          />
          <path
            fill="#656565"
            d="M699.5,357.3h2.6l-0.8-3.3h-2.6C698.7,356.5,699,357.6,699.5,357.3L699.5,357.3z M715.6,324.7h17.7
	c1.2,0,2.5,0.5,3.9,1.8c1.5,1.4,2.3,3.2,2.6,5.4v16.8c-0.1,2.5-0.8,5.4-2.2,8.4l-2.5,5.9h4.1l4.5-7.8c1.5-2.5,2.3-5,2.6-7.3V332
	c-0.4-1.9-1.2-3.4-2.5-4.5l-5.4-4.5h-23.2L715.6,324.7L715.6,324.7z"
          />
          <path
            fill="#656565"
            d="M710.2,326.7h8.8c2.8,0.3,4.8,1.4,6.2,3.6c0.8,0.8,1.1,4.7,0.8,11.8v0.1l-0.1,0.4c0,2.6-0.5,5.5-1.7,8.7
	l-1.9,5.4c-0.7,0.8-2.3,1.1-5.2,0.7l3.6,2.8l0.7,1.7c0.5,1,1.5,1.4,3,1.4h10.5l2.5-5.9c1.4-3,2.2-5.9,2.2-8.4V332
	c-0.1-2.2-1.1-4-2.6-5.4c-1.2-1.2-2.6-1.9-3.9-1.8h-17.7L710.2,326.7L710.2,326.7z M729.8,335.6l0.4-1.5c0.5-1.8,1.4-2.8,2.2-2.8
	c0.8,0,1.7,1,2.2,2.8c0.5,1.8,1,4,1,6.6c0,2.6-0.3,4.8-1,6.6c-0.5,1.8-1.4,2.8-2.2,2.8c-0.8,0-1.7-1-2.2-2.8l-0.4-1.4
	c-0.4-1.5-0.5-3.3-0.5-5.4C729.2,338.8,729.5,337.1,729.8,335.6L729.8,335.6z"
          />
          <path
            fill="#656565"
            d="M734.6,334.1c-0.5-1.8-1.4-2.8-2.2-2.8c-0.8,0-1.7,1-2.2,2.8l-0.4,1.5l0.4,0.8c0.5,1.2,0.8,2.6,0.8,4.4
	c0,1.6-0.3,3.2-0.8,4.4l-0.4,0.8l0.4,1.4c0.5,1.8,1.4,2.8,2.2,2.8c0.8,0,1.7-1,2.2-2.8c0.6-1.8,1-4,1-6.6
	C735.5,338.1,735.3,335.9,734.6,334.1L734.6,334.1z M710.2,326.7l7,30.7c2.8,0.4,4.5,0.1,5.2-0.7l1.9-5.4c1.1-3.2,1.7-6.1,1.7-8.7
	l0.1-0.4v-0.1c0.3-7.2,0-11-0.8-11.8c-1.5-2.2-3.6-3.3-6.2-3.6L710.2,326.7L710.2,326.7z M738.4,322.8l5.4,4.5
	c1.2,1.1,2.1,2.6,2.5,4.5V348c-0.3,2.3-1.1,4.7-2.6,7.3l-4.5,7.8h1.1c1.7,0,2.6-0.5,3.2-1.8l4.3-7c1.1-2.1,1.7-5,1.7-8.8v-13.9
	c-0.1-1.2-0.8-2.3-1.9-3.3l-4-3.7C742,323.2,740.2,322.7,738.4,322.8L738.4,322.8z M206.1,337.7l-0.3,0.3c-3.2,4.1-5.6,8.5-7.6,13.2
	l-2.2,6.2c-1.5,5-2.3,10.2-2.5,15.7v2.6c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1
	c12.2,11.7,26.8,17.6,43.9,17.6s31.8-5.9,43.9-17.6l1.1-1.1c2.2-2.2,4.3-4.5,6.1-6.9c1-1.2,1.8-2.5,2.8-3.9
	c6.6-10,9.8-21.5,9.8-34.3c0-8.5-1.5-16.4-4.4-23.7c-1.5-3.7-3.3-7.2-5.5-10.6c-0.8-1.2-1.8-2.6-2.8-3.9c-1.4-1.8-2.9-3.7-4.5-5.4
	l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6c-17.6,0-32.6,6.2-45,18.6l-1.5,1.5C209,334.1,207.4,335.9,206.1,337.7L206.1,337.7z
	 M215.6,331.9c11.6-11,25.4-16.5,41.5-16.5c16.1,0,30,5.5,41.5,16.5l2.6,2.6c1.5,1.6,3,3.4,4.4,5.1c1.9,2.6,3.7,5.4,5.2,8.1
	c4.4,8.4,6.7,17.7,6.7,28.1c0,10.3-2.2,19.7-6.7,28.1c-1.5,2.8-3.3,5.5-5.2,8.1c-1.8,2.3-3.7,4.4-5.8,6.6c-1.1,1.1-2.3,2.2-3.6,3.3
	c-11.1,9.6-24.2,14.4-39.2,14.4c-15.1,0-28.2-4.8-39.2-14.4l-3.6-3.3c-2.1-2.1-4-4.3-5.8-6.6l-2.8-4c-6.1-9.5-9.2-20.2-9.2-32.2
	c0-12.1,3-22.8,9.2-32.3c0.8-1.4,1.8-2.6,2.8-4c1.4-1.8,2.8-3.4,4.4-5.1L215.6,331.9L215.6,331.9z"
          />
          <path
            fill="#656565"
            d="M212.9,334.5c-1.5,1.6-3,3.3-4.4,5.1c-1,1.2-1.9,2.6-2.8,4c-6.1,9.5-9.2,20.2-9.2,32.3c0,12,3,22.7,9.2,32.2
	l2.8,4c1.8,2.3,3.7,4.4,5.8,6.6l3.6,3.3c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4c1.2-1.1,2.3-2.2,3.6-3.3
	c2.1-2.1,4-4.3,5.8-6.6c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.9-3.3-5.5-5.2-8.1
	c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5L212.9,334.5L212.9,334.5z M216.9,375.8
	c0-11.1,4-20.6,11.8-28.5c7.8-7.8,17.3-11.8,28.5-11.8c11.1,0,20.6,4,28.5,11.8c7.8,7.8,11.8,17.3,11.8,28.5
	c0,7.2-1.6,13.5-4.8,19.3c-1.8,3.3-4.1,6.3-7,9.2c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6s-10.2-0.8-14.6-2.6
	c-5.1-1.9-9.6-5-13.8-9.1c-3.4-3.4-6.2-7.3-8.1-11.4C218,387.6,216.9,382,216.9,375.8L216.9,375.8z"
          />
          <path
            fill="#656565"
            d="M233.3,352c-1.2,1.2-2.3,2.6-3.4,4l12.2-0.5c1.6-1.2,3.6-2.3,5.5-3.2l1-9.1
	C242.8,344.6,237.7,347.6,233.3,352L233.3,352z M253.3,372.1c-1.1,1-1.6,2.3-1.6,3.7c0,1.5,0.6,2.8,1.6,3.9c1.1,1,2.3,1.5,3.7,1.5
	c1.5,0,2.8-0.5,3.7-1.5c1.1-1.1,1.5-2.3,1.5-3.9c0-1.5-0.5-2.8-1.5-3.7c-1.1-1.1-2.3-1.5-3.7-1.5C255.6,370.4,254.4,371,253.3,372.1
	L253.3,372.1z M259.4,374.4v1.5l0.1,0.3h0.3l0.3-0.1v-0.4h0.5v0.5l-0.4,0.4l-0.7,0.1h-0.3c-0.3-0.1-0.4-0.1-0.5-0.3l-0.1-0.3v-1.4
	h0.8L259.4,374.4L259.4,374.4z M257.5,375.1h0.3l0.4,0.1l0.3,0.3v0.1l0.1,0.3l-0.1,0.4c-0.1,0.3-0.3,0.3-0.5,0.4h-0.3l-0.5-0.1
	l-0.3-0.1l-0.1-0.1h0.5l0.1,0.1h0.1l0.1-0.1V376h-1.2v-0.3l0.1-0.3c0.1-0.3,0.3-0.3,0.5-0.4L257.5,375.1L257.5,375.1z M255.9,375.1
	c0.1,0,0.3,0,0.4,0.1l0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.6v0.3c-0.1,0.3-0.3,0.4-0.5,0.4h-0.3l-0.4-0.1l-0.4-0.1l-0.1-0.1h0.6l0.1,0.1
	h0.1c0.1,0,0.3-0.1,0.3-0.1v-0.1h-1.2v-0.3l0.1-0.3c0.1-0.3,0.3-0.3,0.6-0.4L255.9,375.1L255.9,375.1z M253,375.4l0.3-0.3h0.6
	l0.3,0.1l0.1,0.1v-0.1h0.6v2.1h-0.6v-0.5l-0.1,0.1h-0.7l-0.1-0.1l-0.1-0.3v-0.5L253,375.4L253,375.4z"
          />
          <path
            fill="#656565"
            d="M257.7,375.4l-0.3,0.1v0.1h0.5v-0.1c0-0.1-0.1-0.1-0.1-0.1L257.7,375.4L257.7,375.4z M255.7,375.4l-0.3,0.3
	h0.5v-0.1l-0.1-0.1 M253.5,376.2l0.3,0.1h0.1l0.1-0.1v-0.3l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1h-0.1l-0.1,0.1v0.1L253.5,376.2
	L253.5,376.2z M265.5,343.2l1,9.1c1.4,0.5,2.6,1.2,3.9,1.9l12.1-0.6l-1.5-1.6C276.4,347.4,271.3,344.5,265.5,343.2L265.5,343.2z
	 M364.4,177.7l75.4,1.2c3.6,0.1,5.9,1.2,7,3.3l16.8,31h2.2l-17.3-31.9c-1.4-2.8-4.3-4.3-8.7-4.4l-75.2-1.2c-1.9-0.1-3.3,0.3-4.1,1.5
	c-0.8,1-1.1,2.5-0.8,4.7l1.4,46.4c-0.1,4.7,2.6,7.6,8.5,8.7c3.3,0.6,6.7,0.8,10.3,1l71.5,0.7v-1.9l-71.4-0.7c-3.4-0.1-6.9-0.4-10-1
	c-4.7-0.8-7-3.2-6.9-6.7l-1.4-46.5c-0.1-1.5,0-2.6,0.4-3.3C362.5,177.8,363.3,177.6,364.4,177.7L364.4,177.7z"
          />
          <path
            fill="#FFFFFF"
            d="M451.3,217.2v21.5c0.3,2.3,1.2,3.6,2.9,3.3h9.2c-0.8,0-1.5-0.4-1.8-1.1c-0.3-0.6-0.3-1.1-0.3-1.9v-22.8
	c0-0.7,0.1-1.2,0.4-1.6c0.4-0.7,1-1.1,1.8-1.2h-9.9C452,213.2,451.3,214.6,451.3,217.2L451.3,217.2z"
          />
          <path
            fill="#FFFFFF"
            d="M466.9,217.2c1.1,0.6,1.7,2.2,1.7,5v11c0.1,1.6-0.8,3.2-2.6,4.5l-4.3,3.3c0.3,0.7,1,1.1,1.8,1.1h3.6
	c1.2,0.1,3-0.8,5.5-2.9l1.8-1.5l0.8-0.7c1-1,1.5-2.5,1.4-4.3V221c-0.1-1.4-1-3-2.8-5.1c-1.9-2.1-4.1-3-6.3-2.9h-3.9
	c-0.8,0.1-1.4,0.6-1.8,1.2L466.9,217.2L466.9,217.2z"
          />
          <path
            fill="#FFFFFF"
            d="M461.9,214.4c-0.3,0.4-0.4,1-0.4,1.6v22.8c0,0.8,0.1,1.5,0.3,1.9l4.3-3.3c1.8-1.4,2.6-2.9,2.6-4.5v-10.9
	c0.1-2.6-0.6-4.3-1.6-4.9L461.9,214.4L461.9,214.4z M468.1,247.6h5.4c0.7,0,1-0.3,1-1v-9.1l-1.8,1.5c-2.5,2.1-4.3,3-5.5,2.9v4.7
	C467.2,247.3,467.4,247.6,468.1,247.6L468.1,247.6z"
          />
          <path
            fill="#FFFFFF"
            d="M468.7,248.5h13.2l-0.5,8.1c0,1-0.8,1.5-2.2,1.5h-7.6c-1.1,0-2.3-0.7-3.7-2.2c0.4,1.2,1.1,2.2,2.1,3.2
	c1.6,1.6,3.7,2.6,6.1,2.6s4.4-0.8,6.2-2.6c1.6-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.2c-1.7-1.6-3.7-2.5-6.2-2.5l-1.6,0.1v2.2
	c0,0.7-0.3,1-1,1h-4.1L468.7,248.5L468.7,248.5z"
          />
          <path
            fill="#FFFFFF"
            d="M467.4,248.5c-0.7,0-1,0.4-0.8,1.2l0.3,4.7c0,0.3,0.1,0.6,0.3,0.7l0.3,0.1l0.3,0.1h8.8c0.5,0,1-0.3,1-1
	l0.4-4.7c0.1-0.8-0.1-1.1-0.8-1.2H467.4L467.4,248.5z"
          />
          <path
            fill="#FFFFFF"
            d="M467.9,255.7c1,0.8,1.9,1.2,3.2,1.4h4.7c1.2-0.1,2.1-0.5,2.3-1.4l0.8-7.3h-1.6c0.7,0,1,0.4,0.8,1.2l-0.4,4.7
	c0,0.7-0.4,1-1,1h-8.8V255.7L467.9,255.7z"
          />
          <path
            fill="#FFFFFF"
            d="M468,255.8c1.4,1.5,2.6,2.2,3.7,2.2h7.6c1.4,0,2.2-0.4,2.2-1.5l0.5-8.1h-3.2l-0.8,7.3
	c-0.3,0.8-1.1,1.2-2.3,1.4H471c-1.1-0.1-2.2-0.5-3.2-1.4L468,255.8L468,255.8z"
          />
          <path
            fill="#656565"
            d="M354,260.1c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1h0.5c0.8-0.1,1.5-0.4,2.1-1.1
	c0.7-0.7,1.1-1.7,1.1-2.6c0-1-0.4-1.9-1.1-2.6c-0.5-0.5-1.1-0.8-1.8-1l-0.8-0.1c-1,0-1.9,0.4-2.6,1.1
	C354.2,258.2,353.9,259.1,354,260.1L354,260.1z M354.9,260.1c0-0.7,0.3-1.2,0.8-1.8s1.1-0.8,1.8-0.8s1.4,0.3,1.8,0.8
	c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.3,1.4-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.2-0.3-1.8-0.7S354.9,260.8,354.9,260.1
	L354.9,260.1z"
          />
          <path
            fill="#656565"
            d="M358.4,256.5c0.7,0.1,1.2,0.4,1.8,1c0.7,0.7,1.1,1.6,1.1,2.6c0,1-0.4,1.9-1.1,2.6c-0.5,0.5-1.2,1-2.1,1.1
	l3.3-0.3c1.1-0.3,1.9-0.5,2.3-1h21.7c0.7,0,1.2-0.3,1.8-0.7c0.4-0.4,0.7-1.1,0.7-1.6v-0.1c0-0.7-0.3-1.2-0.7-1.8
	c-0.5-0.4-1.1-0.7-1.8-0.7h-22.3c-0.5-0.3-1.1-0.5-1.8-0.7L358.4,256.5L358.4,256.5z M364.3,258h20.9c0.5,0,1,0.1,1.4,0.5
	c0.4,0.4,0.5,0.8,0.5,1.4s-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5h-20.9c-0.4,0-0.7-0.1-1-0.5c-0.3-0.4-0.4-0.8-0.4-1.4
	s0.1-1,0.4-1.4C363.6,258.2,363.9,258,364.3,258L364.3,258z"
          />
          <path
            fill="#656565"
            d="M363.3,258.6c-0.3,0.4-0.4,0.8-0.4,1.4s0.1,1,0.4,1.4c0.3,0.4,0.5,0.5,1,0.5h20.9c0.5,0,1-0.1,1.4-0.5
	c0.4-0.4,0.5-0.8,0.5-1.4s-0.1-1-0.5-1.4c-0.4-0.4-0.8-0.5-1.4-0.5h-20.9C363.9,258,363.6,258.2,363.3,258.6L363.3,258.6z"
          />
          <path
            fill="#656565"
            d="M383.4,256.5c-0.8,0-1.5,0.3-2.2,0.8h4.1c0.7,0,1.2,0.3,1.8,0.7c0.4,0.5,0.7,1.1,0.7,1.8v0.1
	c0,0.7-0.3,1.2-0.7,1.6c-0.5,0.5-1.1,0.7-1.8,0.7h-4c0.5,0.5,1.2,0.7,2.1,0.7h2.6c1,0,1.6-0.3,2.3-1s1-1.4,1-2.3c0-1-0.3-1.6-1-2.3
	s-1.5-1-2.3-1h-2.6V256.5L383.4,256.5z M354.8,273.8c0.5,0.5,1.2,0.8,2.2,0.8c0.8,0,1.5-0.3,2.2-0.8c0.5-0.5,0.8-1.2,0.8-2.2
	c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.2,0.8c-0.5,0.5-0.8,1.2-0.8,2.1C353.8,272.6,354.2,273.3,354.8,273.8
	L354.8,273.8z M356.2,271c0.3-0.1,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.7,0.3c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.7
	c-0.3,0.3-0.4,0.3-0.7,0.3s-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.4-0.3-0.7C355.9,271.4,355.9,271.2,356.2,271L356.2,271z"
          />
          <path
            fill="#656565"
            d="M355.9,271.8c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.7
	s-0.1-0.5-0.3-0.8c-0.3-0.1-0.4-0.3-0.7-0.3s-0.5,0.1-0.8,0.3C355.9,271.2,355.9,271.4,355.9,271.8L355.9,271.8z"
          />
          <path
            fill="#6B9BD2"
            d="M146,199l-0.4-0.1h-0.1 M149,263.9h0.6v-1H149 M149,293.1h0.6v-1H149"
          />
          <path
            fill="#FFFFFF"
            d="M151.2,306.2v2.5h1v-2.5 M126.6,361.3v13.5c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5v-11.6
	c-0.6-0.3-0.8-0.5-1.2-1.1L126.6,361.3L126.6,361.3z M142.2,371.5c-0.1,1.1,0,2.3,0.1,4c0.1,1.5,0.3,2.9,0.6,3.9
	c0.3,1,0.4,1.5,0.7,1.5l0.1-0.1c0.1-0.3,0.3-0.7,0.3-1.6c0.1-1.1,0-2.3-0.1-4c-0.1-1.5-0.3-2.8-0.6-3.9c-0.3-1.1-0.6-1.6-0.7-1.5
	C142.4,369.9,142.2,370.4,142.2,371.5L142.2,371.5z"
          />
          <path fill="#6B9BD2" d="M143.8,380.7l-0.3,0.1h0.1" />
          <path
            fill="#FFFFFF"
            d="M454.5,163.2l-3.2,4.5l29,53.2l10.5-5.4l-1-1.5l-0.7-1.2l-26.8-44.3C460.1,165.6,457.4,163.8,454.5,163.2
	L454.5,163.2z"
          />
          <path
            fill="#6B9BD2"
            d="M346.8,300.9c-0.3,1.1-0.3,2.3,0,3.7V300.9L346.8,300.9z"
          />
          <path
            fill="#FFFFFF"
            d="M346.8,300.9v-43.7l-178.3-1.5c-1.6,23.8-2.5,47.9-2.3,72.2v1.2h21.3l1.4-3.4l-19.1,0.7
	c6.2-13.5,12.5-25.4,19.1-35.6c4.7-7.6,11.8-11.7,21.2-12.5c23.5-1.5,46.2-1.1,68.1,1c15.1,0.8,26.3,5.5,33.2,14l12.8,16.4
	c9.8,12.8,19.7,26.5,29.7,41.1h37.3v-7.6c-4.4,0-8.1-0.4-11.6-1c-2.5-0.3-4.8-1-7.2-2.1l-2.2-1.1c-5.5-3.2-10.6-8.7-15.3-16.5
	c-4.7-7.6-7.4-13.5-8.1-17.9C346.5,303.3,346.5,302,346.8,300.9L346.8,300.9z M181.4,259.8c3.3,0,6.2,1.2,8.5,3.6
	c2.3,2.3,3.6,5.2,3.6,8.5c0,3.3-1.2,6.2-3.6,8.5c-2.3,2.3-5.2,3.6-8.5,3.6c-3.3,0-6.2-1.2-8.5-3.6c-2.3-2.3-3.6-5.2-3.6-8.5
	c0-3.3,1.2-6.2,3.6-8.5C175.4,261,178.1,259.8,181.4,259.8L181.4,259.8z"
          />
          <path
            fill="#FFFFFF"
            d="M166.2,168.7l-0.3,1.2l-1.9,12.4v0.1l-0.6,3.4l-1,6.7l-0.3,1.8c-2.8,19.3-5.1,39.8-7.3,61.4h13.6
	c2.5-31.4,5.6-61.2,9.4-89.4l0.8-5.5l-8.7,3.7C168.1,165.2,166.9,166.5,166.2,168.7L166.2,168.7z"
          />
          <path
            fill="#FFFFFF"
            d="M154.8,255.7l-0.6,5.6h2.2c1,0,1.5,0.3,1.8,1.1c1.1,0.1,1.6,1,1.9,2.5l-0.6,27c-0.1,1.9-1,2.8-2.6,2.8
	c-0.4,0.3-0.8,0.5-1.4,0.4h-1.8v33H166c-0.1-24.4,0.7-48.4,2.3-72.2h-13.6L154.8,255.7L154.8,255.7z M662.2,255.1
	c0.1-0.1,0.1-0.1,0.3-0.1h5.2c0.1,0,0.3,0,0.4,0.1l0.1,0.3l0.5,3.6h0.3c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.3v0.3
	c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.4l0.1,0.5h0.8c0.3,0,0.5,0.1,0.5,0.5l0.6,3.2c0,0.1,0,0.3-0.1,0.4l-0.3,0.4
	c-0.1,0.1-0.1,0.1-0.4,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5l0.3-0.1L668,265l0.1,2.1l19.9,1l-1.7-10.3c0-1.2-2.3-2.2-6.9-3l-18-2.2
	l-24.1-2.2l-103.2-10.2c1.4,5.8,2.1,12.7,2.2,20.5c-0.1-7.8-0.8-14.7-2.2-20.5l-2.1-0.1l-22-2.2l-2.5-0.3l-4-0.4
	c-0.7,3.2-0.1,10.6,1.7,22.3l160.4,7.7V265l-1-0.5v0.1c0,0.1,0,0.3-0.1,0.4l-0.3,0.4c-0.1,0.1-0.1,0.1-0.4,0.1h-1
	c-0.3,0-0.5-0.1-0.5-0.5l-0.5-3.2v-0.4l0.3-0.4l0.1-0.1l0.1-0.1h0.3l-0.1-0.5h-1l-0.3-0.1l-0.1-0.3v-0.3l0.1-0.3
	c0.1-0.1,0.3-0.1,0.3-0.1h1.1l-0.5-3.6C662.1,255.3,662.1,255.1,662.2,255.1L662.2,255.1z"
          />
          <path
            fill="#FFFFFF"
            d="M585.1,243.2h-0.8l-7.6-0.7l-42.2-3.4v1.1l103,10.2l24.1,2.2 M669.7,259.1c-0.1-0.1-0.3-0.1-0.3-0.1h-0.3
	l0.1,0.8c0,0.1,0,0.3-0.1,0.3H669h0.4c0.1,0,0.3,0,0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.3v-0.3C669.8,259.4,669.8,259.3,669.7,259.1
	L669.7,259.1z M686.4,257.7l1.5,10.3l1.5,10.3c0.8,0.3,1.7,0.5,2.5,1l-0.5-2.5c-1.5-6.1-2.3-10.5-2.6-12.9c-0.1-2.1,0-2.9,0.7-2.6
	c-0.4-0.7-0.8-1.4-1.4-1.9l-0.1-0.1C687.5,258.6,687,258,686.4,257.7L686.4,257.7z"
          />
          <path
            fill="#FFFFFF"
            d="M688.8,263.7c2.1,2.6,2.9,6.9,2.6,12.9l0.5,2.5c1.4,0.5,2.6,1.2,3.7,1.9l-5.5-18.7c-0.1-0.4-0.4-0.8-0.7-1.2
	C688.8,260.8,688.6,261.7,688.8,263.7L688.8,263.7z M671,271.8v1.6h0.7v1.6l5.9,0.8c4.5,0.4,8.5,1.2,12,2.3l-1.5-10.3l-19.9-1
	l0.1,4.3h2.3C670.9,271.4,671,271.5,671,271.8L671,271.8z"
          />
          <path
            fill="#FFFFFF"
            d="M690,262.4l5.5,18.7c1.2,0.8,2.5,1.8,3.4,2.8l-6.3-19.7c-1.4-2.6-2.9-4.4-4.7-5.1c0.5,0.5,1,1.2,1.4,1.9
	C689.6,261.6,689.9,262,690,262.4L690,262.4z"
          />
          <path
            fill="#D2D3D4"
            d="M688.8,263.7c0.3,2.5,1.1,6.9,2.6,12.9C691.7,270.5,690.8,266.3,688.8,263.7L688.8,263.7z"
          />
          <path
            fill="#FFFFFF"
            d="M667.3,257.9l-0.3-0.8h-2.9v0.8 M485.7,231.2c1.2,2.2,2.3,4.7,3.3,7.8l0.7,2.6c1.2,4.8,2.2,10.7,2.8,17.6
	l12.8,0.1c-1.8-11.7-2.5-19-1.6-22.3l-0.4-0.6l-12.5-20.8l-10.5,5.4L485.7,231.2L485.7,231.2z M484.6,223.4c-0.3-0.3-0.4-0.6-0.4-1
	c0-0.4,0.1-0.7,0.4-1s0.5-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4s0.4,0.6,0.4,1s-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4
	C485.2,223.8,484.9,223.6,484.6,223.4L484.6,223.4z M490.4,228.7c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1s-0.1,0.7-0.4,1
	c-0.3,0.3-0.5,0.4-1,0.4l-0.5-0.1l-0.4-0.3l-0.3-0.4l-0.1-0.4l0.1-0.4l0.3-0.6l0.4-0.3L490.4,228.7L490.4,228.7z M497.6,243.3
	c0,0.4-0.1,0.7-0.4,1s-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4
	c0.4,0,0.7,0.1,1,0.4C497.4,242.8,497.6,243,497.6,243.3L497.6,243.3z M500.2,234.4c0-0.3,0-0.4,0.1-0.6l0.5,0.1
	c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.3,0.3,0.6,0.3,0.8c0,0.3,0,0.6-0.1,0.6c-0.1,0.1-0.4,0-0.5-0.1l-0.6-0.7
	C500.3,234.9,500.2,234.6,500.2,234.4L500.2,234.4z M498.9,251.8c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4s-0.7-0.1-1-0.4
	c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4C498.8,251.1,498.9,251.6,498.9,251.8L498.9,251.8z"
          />
          <path fill="#6B9BD2" d="M507.7,237.4l0.1-0.6l-4.5-0.4l0.4,0.7" />
          <path fill="#FFFFFF" d="M532.2,239.9l0.1-1L510,237l0.3,0.7" />
          <path
            fill="#FFFFFF"
            d="M532.6,236.4l-0.3,2.3l-0.1,1.1l2.1,0.1l0.1-1.1l0.3-2.8C533.6,235.9,532.9,236,532.6,236.4L532.6,236.4z
	 M534.2,238.2c-0.1,0.1-0.3,0.3-0.5,0.3s-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.3,0.5-0.3
	s0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.6C534.4,237.8,534.3,237.9,534.2,238.2L534.2,238.2z"
          />
          <path
            fill="#FFFFFF"
            d="M534.4,237.7c0-0.3-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6
	s0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3C534.3,237.9,534.4,237.8,534.4,237.7z M473.6,316.4v6.6
	c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1l-11-2.2v-2.1h11.6v-3.6H476v-2.1l11-2.2h-1.1l-10.6,1.2C474.2,315,473.6,315.5,473.6,316.4
	L473.6,316.4z M485.6,319c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3C485.2,318.6,485.5,318.7,485.6,319L485.6,319z
	 M479.8,319.6c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3C479.8,319.1,479.8,319.4,479.8,319.6L479.8,319.6z"
          />
          <path
            fill="#FFFFFF"
            d="M484.9,318.5c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8
	C485.5,318.7,485.2,318.5,484.9,318.5L484.9,318.5z M479.5,319c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8
	C479.8,319.4,479.8,319.1,479.5,319L479.5,319z"
          />
          <path
            fill="#FFFFFF"
            d="M488.6,313.7h-0.3l-1.4-0.1l-11,2.2v2.1h11.6v3.6H476v2.1l11,2.2l1.4-0.1h0.3l1.6-0.5v-10.6
	C489.7,314.1,489.2,313.9,488.6,313.7L488.6,313.7z M701.7,309.1h-9.6l-1.9,1H703l8.3-2.9h-2.6"
          />
          <path
            fill="#6B9BD2"
            d="M729.2,340.7c0,1.9,0.1,3.7,0.5,5.4l0.4-0.8c0.5-1.2,0.8-2.6,0.8-4.4c0-1.6-0.3-3.2-0.8-4.4l-0.4-0.8
	C729.5,337.1,729.2,338.8,729.2,340.7L729.2,340.7z M717.3,357.3h-0.4H717.3z"
          />
          <path
            fill="#FFFFFF"
            d="M166.2,327.9h-12.2c0.6,0.3,1,0.7,1,1.2H166L166.2,327.9z"
          />
          <path
            fill="#6B9BD2"
            d="M372.4,340.1c2.3,1.1,4.7,1.6,7.2,2.1C376.8,341.7,374.4,341,372.4,340.1L372.4,340.1z"
          />
          <path fill="#FFFFFF" d="M465.7,350.7v-7.6H391v7.6" />
          <path
            fill="#FFFFFF"
            d="M345.8,166.8l1,90.4v47.5c0.7,4.4,3.3,10.3,8.1,17.9c4.5,7.8,9.6,13.2,15.3,16.5l2.2,1.1
	c2.1,0.8,4.4,1.5,7.2,2.1c3.3,0.5,7.2,1,11.6,1h75c10.6,0.4,17.6-1.1,20.9-4.7c3.2-1.9,5.1-5.9,5.8-12c-0.1,0.1-0.4,0.3-0.7,0.3
	h-0.7c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7V325l-1.6,0.5h-0.3l-1.4,0.1H486l-10.6-1.2c-1.1-0.1-1.6-0.7-1.6-1.5v-6.6
	c0-0.8,0.5-1.4,1.6-1.5l10.6-1.2h1.1l1.4,0.1h0.3c0.5,0.1,1.1,0.3,1.6,0.5v-0.1c0-0.3,0.1-0.6,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.7
	c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7v-52l-0.3-2.9c-0.5-6.9-1.5-12.8-2.8-17.6l-0.7-2.6c-1-3-2.1-5.6-3.3-7.8l-5.5-10
	l-29-53.2L346,165.9v1L345.8,166.8L345.8,166.8z M360.4,177.1c0.8-1.1,2.2-1.6,4.1-1.5l75.2,1.2c4.4,0.1,7.3,1.5,8.7,4.4l17.3,31.9
	h1.6c2.2-0.3,4.3,0.7,6.3,2.9c1.8,1.9,2.8,3.6,2.8,5.1v11.7c0,1.8-0.4,3.3-1.4,4.3l-0.8,0.7v6.9l1.6-0.1c2.3,0,4.4,0.8,6.2,2.5
	c1.6,1.6,2.5,3.7,2.5,6.2s-0.8,4.4-2.5,6.1c-1.6,1.6-3.7,2.6-6.2,2.6c-2.3,0-4.4-0.8-6.1-2.6c-1-1-1.6-2.1-2.1-3.2l-0.4-0.6
	l-0.3-0.1c-0.1-0.1-0.3-0.4-0.3-0.7l-0.3-4.7c-0.1-0.8,0.3-1.2,0.8-1.2h1.2l0.5-0.8H468c-0.7,0-1-0.3-1-1v-4.7h-12.7
	c-1.8,0.1-2.8-1-2.9-3.3l-71.5-0.7c-3.6-0.1-7-0.4-10.3-1c-5.9-1.1-8.7-4-8.5-8.7l-1.4-46.4C359.5,179.7,359.7,178.1,360.4,177.1
	L360.4,177.1z M377.8,254c1,1,1.6,2.1,1.9,3.3h1.6c0.5-0.5,1.4-0.8,2.2-0.8h2.6c1,0,1.6,0.3,2.3,1c0.7,0.7,1,1.5,1,2.3
	c0,1-0.3,1.6-1,2.3c-0.7,0.7-1.5,1-2.3,1h-2.6c-0.8,0-1.5-0.3-2.1-0.7h-2.1c-0.4,0.8-0.8,1.5-1.5,2.2c-1.5,1.5-3.3,2.2-5.4,2.2
	s-3.9-0.7-5.2-2.2c-0.7-0.7-1.2-1.4-1.5-2.2h-1.9c-0.4,0.4-1.2,0.7-2.3,1l-3.3,0.3h-0.5c-1,0-1.9-0.4-2.6-1.1
	c-0.7-0.7-1.1-1.7-1.1-2.6s0.4-1.9,1.1-2.6c0.7-0.7,1.7-1.1,2.6-1.1l0.8,0.1l3,0.1c0.7,0.1,1.2,0.4,1.8,0.7h2.1
	c0.3-1.2,1-2.3,1.9-3.3c1.5-1.5,3.2-2.2,5.2-2.2C374.6,252,376.2,252.7,377.8,254L377.8,254z M353.8,271.8c0-0.8,0.3-1.5,0.8-2.1
	s1.2-0.8,2.2-0.8c0.8,0,1.5,0.3,2.2,0.8c0.5,0.5,0.8,1.2,0.8,2.1c0,0.8-0.3,1.5-0.8,2.2c-0.5,0.5-1.2,0.8-2.2,0.8
	c-0.8,0-1.5-0.3-2.2-0.8C354.2,273.3,353.8,272.6,353.8,271.8L353.8,271.8z M475.3,266.4l10.6-1.2h1.1l1.4,0.1h0.3
	c0.5,0.1,1.1,0.3,1.6,0.5v-0.1c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.7c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7
	v11.7c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3h-0.7c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7v-0.8l-1.6,0.5h-0.3
	l-1.4,0.1h-1.1l-10.6-1.2c-1.1-0.1-1.6-0.7-1.6-1.5v-6.6C473.6,267.1,474.2,266.6,475.3,266.4L475.3,266.4z"
          />
          <path
            fill="#6B9BD2"
            d="M463.7,213.2h0.3h-0.4 M467.9,255.7v-0.3l-0.4-0.1h0.1"
          />
          <path fill="#6B9BD2" d="M467.9,255.7l-0.3-0.4l0.4,0.6" />
          <path
            fill="#FFFFFF"
            d="M372.4,252c-2.1,0-3.9,0.7-5.2,2.2c-1,1-1.6,2.1-1.9,3.3h14.4c-0.3-1.2-1-2.3-1.9-3.3
	C376.2,252.7,374.6,252,372.4,252L372.4,252z M355.7,261.9c0.5,0.5,1.1,0.7,1.8,0.7s1.4-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8
	s-0.3-1.2-0.7-1.8c-0.5-0.5-1.1-0.8-1.8-0.8c-0.7,0-1.2,0.3-1.8,0.8s-0.8,1.1-0.8,1.8C354.9,260.8,355.2,261.5,355.7,261.9
	L355.7,261.9z M367.2,264.8c1.5,1.5,3.2,2.2,5.2,2.2s3.9-0.7,5.4-2.2c0.7-0.7,1.2-1.4,1.5-2.2h-13.8
	C365.9,263.2,366.5,264.1,367.2,264.8L367.2,264.8z M473.6,267.9v6.6c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1l-11-2.2V273h11.6v-3.6H476
	v-2.1l11-2.2h-1.1l-10.6,1.2C474.2,266.5,473.6,267.1,473.6,267.9L473.6,267.9z M485.6,270.5c0.3,0.3,0.3,0.4,0.3,0.8
	c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8
	c0.3-0.3,0.4-0.3,0.8-0.3C485.2,270.3,485.5,270.3,485.6,270.5L485.6,270.5z M479.8,271.2c0,0.3-0.1,0.5-0.3,0.8
	c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3
	c0.3,0,0.5,0.1,0.8,0.3C479.8,270.7,479.8,271,479.8,271.2z"
          />
          <path
            fill="#FFFFFF"
            d="M484.9,270.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8
	C485.5,270.3,485.2,270.3,484.9,270.3L484.9,270.3z M479.5,270.5c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8
	C479.8,271,479.8,270.7,479.5,270.5L479.5,270.5z"
          />
          <path
            fill="#FFFFFF"
            d="M487,265.3l-11,2.2v2.1h11.6v3.6H476v2.1l11,2.2l1.4-0.1h0.3l1.6-0.5V266c-0.5-0.3-1-0.4-1.6-0.5h-0.3
	L487,265.3L487,265.3z"
          />
          <path
            fill="#FFFFFF"
            d="M490.1,266v5.1h2.8v-5.4c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3
	s-0.3,0.4-0.3,0.7L490.1,266L490.1,266z M490.6,278.1c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7
	v-6.3h-2.8v6.3C490.1,277.7,490.3,278,490.6,278.1L490.6,278.1z"
          />
          <path
            fill="#FFFFFF"
            d="M663.6,271.8c0-0.3,0.1-0.5,0.5-0.5h1.9l-0.1-4.4l-160.4-7.7l-12.8-0.1l0.3,2.9v63.7c0,0.3-0.1,0.5-0.3,0.7
	c-0.8,6.1-2.6,10-5.8,12c-3.3,3.4-10.2,5.1-20.9,4.7h-0.3v7.6h65.5l2.1-2.8h-10c13.6-22.8,27-42.5,40-58.9
	c6.9-9.5,16.2-14.7,28.1-15.7c25.7-1.6,49.9-1.4,72.5,0.8v-2.2L663.6,271.8L663.6,271.8z"
          />
          <path
            fill="#FFFFFF"
            d="M490.1,314.1v5.2h2.8v-5.2c0-0.3-0.1-0.4-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3
	C490.3,313.7,490.1,313.9,490.1,314.1L490.1,314.1z M490.6,326.5c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.4-0.1,0.7-0.3
	c0.1-0.1,0.3-0.4,0.3-0.7v-6.3h-2.8v6.3C490.1,326.1,490.3,326.4,490.6,326.5L490.6,326.5z"
          />
          <path
            fill="#EBEBEB"
            d="M144,375.2c0.1,1.5,0.1,2.9,0.1,4c-0.1,0.8-0.1,1.4-0.3,1.6l24.1-7.2l-1.2-0.4c-5.2-1.9-8.3-4.4-9.2-7.6
	l-14.7,4.1c0.3,0,0.4,0.6,0.7,1.5C143.6,372.5,143.9,373.7,144,375.2L144,375.2z M322.2,175.5H204.6c-4,0.3-6.1,2.8-6.5,7.4
	l-4.4,48.1c-0.3,1.9,0.4,3.4,1.9,4.4c1.8,1.1,4.7,1.8,8.7,2.1h112.8c4.3,0.3,7.6-0.3,9.8-1.6c2.1-1.4,3.2-3.4,3.3-6.5v-49.1
	C329.9,177,327.3,175.3,322.2,175.5L322.2,175.5z M606.1,399.6c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6
	c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3c0-11.1-4-20.6-11.8-28.5
	c-7.8-7.8-17.3-11.8-28.5-11.8s-20.6,4-28.5,11.8C598,350.5,594,360,594,371.1c0,6.2,1.2,11.8,3.6,17.1
	C599.9,392.3,602.7,396.1,606.1,399.6L606.1,399.6z M599.6,357.3c1.8-4.7,4.7-8.9,8.5-12.7c7.3-7.3,16.1-11,26.4-11l5.8,0.4
	c7.8,1.1,14.7,4.7,20.6,10.6c3.9,3.9,6.6,8.1,8.4,12.7l0.6,1.4c0.7,2.1,1.2,4.3,1.5,6.5v0.1c0.3,1.9,0.4,3.9,0.4,5.9
	c0,4.4-0.7,8.5-2.1,12.5c-1.8,5.1-4.8,9.8-8.9,14c-7.3,7.3-16.1,11-26.4,11c-10.3,0-19.1-3.6-26.4-10.9l-0.1-0.1
	c-5.2-5.2-8.7-11.3-10-18.2c-0.5-2.6-0.8-5.4-0.8-8.3s0.3-5.6,0.8-8.3C598.4,360.9,599,359.1,599.6,357.3L599.6,357.3z"
          />
          <path
            fill="#EBEBEB"
            d="M627.2,365.7c0.5,1,0.5,1.9,0.3,3c-0.3,1-1,1.8-1.9,2.3c-0.1,0-0.1,0.1-0.3,0.1h4c0-1.5,0.5-2.8,1.7-3.7
	c1-1.1,2.3-1.5,3.7-1.5c1.5,0,2.8,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7h4c-1-0.5-1.7-1.2-1.9-2.3c-0.3-1.1-0.3-2.1,0.3-3
	c0.4-0.8,1-1.4,1.7-1.8l1-0.3c1.7-0.6,3.3-0.7,4.7-0.3c1.5,0.3,2.3,1,2.6,2.1c0.3,1.1,0,2.2-1,3.3c-0.8,1.1-2.1,1.8-3.7,2.3h13.5
	l7.3-3c0.7-0.3,1.2-0.5,1.8-1c0.7-0.6,1.2-1.2,1.5-1.9c-0.3-2.2-0.8-4.4-1.5-6.5l-0.5-1.4c-1.8-4.7-4.7-8.9-8.4-12.7
	c-5.9-5.9-12.8-9.4-20.6-10.6c1.2,0.8,2.1,2.3,2.6,4.3c5.8,1.4,10.9,4.4,15.4,8.9c1.8,1.8,3.3,3.6,4.5,5.5c2.1-0.1,3.7,0.3,5,1.2
	c-1.2-0.7-2.8-0.8-4.4-0.4l-16.1,3.7l-1.7,0.1l-0.3-0.1c-0.5-0.1-1-0.5-1.4-1.1c-0.3-0.5-0.4-1.2-0.4-2.1l-1.5-16
	c-0.1-1.8-0.7-3.2-1.8-4.1l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11c-3.9,3.9-6.6,8.1-8.4,12.7c-0.7,1.8-1.2,3.6-1.7,5.5
	c-0.5,2.6-0.8,5.4-0.8,8.3h3.7v-1.9c-1.5-1-2.5-1.9-2.9-3.2l-0.3-0.7l0.3,0.5c0.7,1,1.7,1.8,3,2.3h0.1l6.6,2.8h14.3
	c-1.8-0.5-3-1.4-4-2.5c-1-1.1-1.2-2.2-1-3.3c0.3-1.1,1.2-1.8,2.6-2.1c1.5-0.3,3-0.3,4.7,0.3l1,0.3
	C626.2,364.4,626.7,364.9,627.2,365.7L627.2,365.7z M626.9,338.4l-1.5,16.1c0,0.8-0.1,1.5-0.4,1.9c-0.3,0.4-0.5,0.7-1,1l-0.7,0.3
	c-0.5,0.1-1.2,0-2.1-0.3l-15.7-3.6c-1.8-0.4-3.2-0.3-4.4,0.4c1.1-1,2.8-1.4,5-1.2c1.2-2.1,2.9-4,4.7-5.8c4.4-4.5,9.5-7.4,15.3-8.8
	c0.5-1.9,1.5-3.4,2.6-4.1c-1,1-1.7,2.3-1.8,4L626.9,338.4L626.9,338.4z M630.9,357.3c0.1-1.1,0.5-1.9,1-2.8c0.8-1.2,1.7-1.9,2.8-1.9
	c1.1,0,2.1,0.7,2.8,1.9c0.5,0.8,0.8,1.8,1,2.8c0.1,0.5,0.1,1.1,0.1,1.8v1c-0.1,0.8-0.5,1.5-1.1,2.2c-0.8,0.8-1.8,1.1-2.8,1.1
	c-1.1,0-2.1-0.4-2.8-1.1c-0.5-0.7-1-1.4-1.1-2.2L630.9,357.3L630.9,357.3z"
          />
          <path
            fill="#EBEBEB"
            d="M668.1,369c0,0.7,0.1,1.4,0.1,2.1h3.7c0-2.1-0.1-4-0.4-5.9c-0.3,0.8-0.8,1.7-1.5,2.5L668.1,369L668.1,369z"
          />
          <path
            fill="#EBEBEB"
            d="M639.8,371.1c0,1.5-0.5,2.8-1.5,3.9c-1.1,1-2.3,1.5-3.7,1.5c-1.5,0-2.8-0.5-3.7-1.5
	c-1.1-1.1-1.7-2.3-1.7-3.9h-4c-0.7,0.3-1.5,0.4-2.2,0.3l-1-0.3h-14.3l8.3,3.6c0.8,0.3,1.4,0.5,1.8,1c0.4,0.5,0.7,1.2,0.7,2.1
	c-0.1,0.5-0.4,1.2-0.8,1.9l-8.3,13.9c-1,1.5-1.2,2.9-1.1,4.3c7.3,7.2,16.1,10.9,26.4,10.9c10.3,0,19.1-3.7,26.4-11
	c4.1-4.1,7.2-8.8,8.9-14c1.4-3.9,2.1-8,2.1-12.5h-3.7c0,8.1-2.5,15.3-7.6,21.3c0.8,1.9,1,3.7,0.4,5.1c0.3-1.4-0.1-2.9-1-4.4
	l-8.3-13.8c-0.4-0.5-0.8-1.2-0.8-1.9c-0.1-0.7,0.1-1.4,0.7-2.1c0.4-0.4,1-0.8,1.8-1l7.6-3.3h-13.5c-0.1,0-0.3,0.1-0.3,0.1l-1,0.3
	c-0.8,0.1-1.7,0-2.5-0.4L639.8,371.1L639.8,371.1z M644.2,377.7l0.5,0.8c1.1,1.5,1.7,2.9,1.8,4.4c0.1,1.5-0.3,2.5-1.1,3.2
	s-1.9,0.7-3.3,0.1c-1.4-0.6-2.6-1.5-3.6-3l-0.5-0.8c-0.4-0.8-0.5-1.5-0.4-2.3c0.1-1.1,0.7-1.9,1.5-2.6s1.9-0.8,2.9-0.7
	C642.8,376.7,643.5,377.2,644.2,377.7L644.2,377.7z M637.3,390.1l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9
	c-3.9,1.5-8,2.3-12.5,2.3c-4.4,0-8.4-0.7-12.2-2.2c-1.7,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2
	c0.4-0.7,1-1.1,1.5-1.4c0.7-0.3,1.4-0.3,2.2,0C636.4,389,636.9,389.4,637.3,390.1L637.3,390.1z M631.7,379.9c0.1,0.8,0,1.6-0.4,2.5
	l-0.5,0.8c-1.1,1.5-2.2,2.5-3.6,3c-1.4,0.5-2.5,0.5-3.3-0.1c-1-0.7-1.2-1.6-1.1-3.2c0.1-1.5,0.7-3,1.8-4.4l0.7-0.8
	c0.5-0.5,1.4-1,2.2-1.1c1.1-0.1,2.1,0,2.9,0.7C631,377.8,631.6,378.8,631.7,379.9L631.7,379.9z"
          />
          <path
            fill="#EBEBEB"
            d="M597.2,371.1c0,2.9,0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1c-0.4-1.4-0.3-3,0.4-5
	c-5.1-6.1-7.7-13.2-7.7-21.5h-3.7L597.2,371.1L597.2,371.1z M626.9,338.2c0.1-1.7,0.7-3,1.8-4c-1.2,0.8-2.1,2.2-2.6,4.1l-1,9.1
	l-0.6,4.7c-0.1,1.5-0.7,2.6-1.7,3c-0.8,0.7-1.9,0.8-3.4,0.5l-4.4-1l-8.9-1.9c-2.2-0.1-3.7,0.3-5,1.2c1.2-0.7,2.6-0.8,4.4-0.4
	l15.7,3.6c0.7,0.3,1.4,0.3,2.1,0.3l0.7-0.3c0.4-0.3,0.8-0.5,1-1c0.3-0.5,0.4-1.2,0.4-1.9L626.9,338.2L626.9,338.2z"
          />
          <path
            fill="#EBEBEB"
            d="M616.7,353.2l-1.7,1.6l4.4,1c1.5,0.3,2.6,0.1,3.4-0.6c0.8-0.5,1.4-1.5,1.7-3l0.5-4.7
	C621.9,348.8,619.2,350.7,616.7,353.2L616.7,353.2z M617.1,365.5c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3
	c0.8,0.1,1.7,0,2.5-0.4c1-0.6,1.7-1.2,1.9-2.3c0.3-1.1,0.3-2.1-0.3-3c-0.4-0.8-1-1.4-1.8-1.8l-1-0.3c-1.7-0.5-3.3-0.7-4.7-0.3
	C618.3,363.7,617.4,364.4,617.1,365.5L617.1,365.5z M624.7,364.2c1,0.3,1.5,0.8,2.1,1.7c0.4,0.8,0.5,1.8,0.3,2.6
	c-0.3,1-0.8,1.5-1.8,1.9c-0.8,0.4-1.7,0.6-2.6,0.3s-1.7-0.8-2.1-1.8c-0.4-0.8-0.5-1.6-0.1-2.6c0.3-1,0.8-1.6,1.7-2.1
	C622.9,364.1,623.7,363.9,624.7,364.2L624.7,364.2z"
          />
          <path
            fill="#EBEBEB"
            d="M622.1,364.5c-0.8,0.4-1.4,1.1-1.7,2.1c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8
	c1,0.3,1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-2.1-1.6
	C623.7,363.9,622.9,364.1,622.1,364.5L622.1,364.5z M625,377.7l-0.7,0.8c-1.1,1.4-1.7,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2
	c0.8,0.7,1.9,0.7,3.3,0.1c1.4-0.6,2.6-1.5,3.6-3l0.5-0.8c0.4-0.8,0.5-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.7-2.6
	c-0.8-0.7-1.8-0.8-2.9-0.7C626.3,376.7,625.6,377,625,377.7L625,377.7z M625.8,383.3c-0.8-0.5-1.2-1.4-1.4-2.2c-0.1-1,0-1.8,0.5-2.6
	c0.5-0.8,1.4-1.2,2.2-1.4c1-0.1,1.8,0,2.6,0.5c0.8,0.5,1.2,1.2,1.4,2.2s0,1.8-0.5,2.6c-0.5,0.8-1.2,1.2-2.2,1.4
	C627.3,384.2,626.5,383.9,625.8,383.3L625.8,383.3z"
          />
          <path
            fill="#EBEBEB"
            d="M628.3,383.9c1-0.1,1.7-0.5,2.2-1.4s0.8-1.6,0.6-2.6c-0.1-1-0.5-1.6-1.4-2.2c-0.8-0.6-1.7-0.8-2.6-0.6
	c-1,0.1-1.7,0.5-2.2,1.4c-0.6,0.8-0.8,1.7-0.6,2.6c0.1,1,0.5,1.6,1.4,2.2C626.5,383.9,627.3,384.2,628.3,383.9L628.3,383.9z
	 M617.8,404.5c1.5,0.1,3-0.5,4.7-1.8l9.4-10.3c0.8-0.8,1.5-1.4,2.3-1.5l0.8-0.1h0.8c0.8,0.1,1.5,0.7,2.3,1.5l9.2,10.2
	c1.7,1.4,3.3,1.9,4.7,1.9c-1.4-0.1-2.8-1-3.9-2.3l-10.6-12.1c-0.4-0.7-1-1.1-1.5-1.4c-0.8-0.3-1.5-0.3-2.2,0
	c-0.6,0.3-1.1,0.7-1.5,1.4l-10.7,12.2C620.4,403.6,619.2,404.3,617.8,404.5L617.8,404.5z M630.9,357.3l-0.1,2.8
	c0.1,0.8,0.5,1.5,1.1,2.2c0.8,0.8,1.7,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2v-1c0-0.7,0-1.2-0.1-1.8
	c-0.1-1.1-0.5-1.9-1-2.8c-0.8-1.2-1.7-1.9-2.8-1.9c-1.1,0-2.1,0.7-2.8,1.9C631.4,355.4,631,356.4,630.9,357.3L630.9,357.3z
	 M632.2,357.1c0.7-0.7,1.5-1,2.3-1c1,0,1.8,0.3,2.5,1l0.3,0.3c0.5,0.5,0.8,1.4,0.8,2.2c0,1-0.4,1.8-1,2.3c-0.7,0.7-1.5,1.1-2.5,1.1
	s-1.8-0.3-2.3-1.1c-0.7-0.7-1-1.5-1-2.3s0.3-1.5,0.8-2.2L632.2,357.1L632.2,357.1L632.2,357.1z"
          />
          <path
            fill="#EBEBEB"
            d="M632,357.3c-0.5,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1
	c0.7-0.7,1-1.5,1-2.3s-0.3-1.5-0.8-2.2l-0.3-0.3c-0.7-0.7-1.5-1-2.5-1s-1.8,0.3-2.3,1L632,357.3L632,357.3L632,357.3z M643.8,364.1
	c-0.7,0.4-1.2,1-1.7,1.8c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3c1.7-0.5,3-1.4,4-2.5
	c1-1.1,1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1s-3-0.3-4.7,0.3L643.8,364.1L643.8,364.1z M647.2,364.5c0.8,0.4,1.4,1.1,1.7,2.1
	c0.3,0.8,0.3,1.8-0.3,2.6c-0.4,0.8-1.1,1.4-2.1,1.6c-0.8,0.3-1.8,0.3-2.6-0.1c-0.8-0.4-1.5-1.1-1.8-2.1c-0.3-1-0.3-1.8,0.3-2.6
	c0.4-0.8,1.1-1.5,1.9-1.8C645.4,363.9,646.4,364.1,647.2,364.5L647.2,364.5z"
          />
          <path
            fill="#EBEBEB"
            d="M644.6,364.4c-1,0.3-1.5,0.8-1.9,1.8c-0.4,0.8-0.5,1.7-0.3,2.6c0.3,1,0.8,1.5,1.8,2.1
	c0.8,0.4,1.8,0.5,2.6,0.1c1-0.3,1.5-0.8,2.1-1.7c0.4-0.8,0.5-1.8,0.3-2.6c-0.3-0.8-0.8-1.5-1.7-2.1
	C646.4,364.1,645.5,363.9,644.6,364.4L644.6,364.4z M633.6,370.5h-0.4c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.1
	c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1h-0.5l0.1,0.1l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5l-0.3-0.3
	L633.6,370.5L633.6,370.5L633.6,370.5z M633.8,371.1h-0.5V371l0.3-0.1h0.1l0.1,0.1L633.8,371.1L633.8,371.1z"
          />
          <path
            fill="#EBEBEB"
            d="M634.4,371.2v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1h-0.5l0.1,0.1c0.1,0.1,0.1,0.1,0.3,0.1l0.5,0.1h0.3
	c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3V371c-0.1-0.1-0.1-0.3-0.3-0.3l-0.7-0.1h-0.4c-0.3,0-0.4,0.1-0.5,0.4L634.4,371.2
	L634.4,371.2z M635.4,370.8c0.1,0,0.1,0,0.1,0.1v0.1H635V371l0.3-0.1L635.4,370.8L635.4,370.8z M630.9,370.5
	c-0.1,0.1-0.3,0.1-0.3,0.3l-0.1,0.3v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.5v-1.9h-0.5v0.1l-0.1-0.1L630.9,370.5L630.9,370.5z
	 M631.8,371.5v0.1c0,0.1-0.1,0.1-0.1,0.1h-0.3c-0.1,0-0.3,0-0.3-0.1H631v-0.4l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1L631.8,371.5
	L631.8,371.5z"
          />
          <path
            fill="#EBEBEB"
            d="M637.9,371.1v0.4l-0.3,0.1h-0.3l-0.1-0.3v-1.5h-0.7v1.4l0.1,0.3l0.5,0.3h0.3l0.7-0.1
	c0.3-0.1,0.4-0.3,0.4-0.4v-0.5h-0.7L637.9,371.1L637.9,371.1z M639,377.4c-0.8,0.7-1.4,1.5-1.5,2.6c-0.1,0.8,0,1.6,0.4,2.3l0.5,0.8
	c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1c0.8-0.7,1.2-1.6,1.1-3.2s-0.7-2.9-1.8-4.4l-0.5-0.8c-0.5-0.5-1.4-1-2.2-1.1
	C640.9,376.5,639.9,376.7,639,377.4L639,377.4z M639.4,377.7c0.8-0.5,1.7-0.8,2.6-0.5c1,0.1,1.7,0.5,2.2,1.4
	c0.5,0.8,0.8,1.7,0.5,2.6c-0.1,1-0.5,1.6-1.4,2.2c-0.8,0.5-1.7,0.8-2.6,0.7c-1-0.1-1.7-0.7-2.2-1.4c-0.5-0.8-0.8-1.6-0.5-2.6
	C638.2,379.1,638.6,378.3,639.4,377.7L639.4,377.7z"
          />
          <path
            fill="#EBEBEB"
            d="M638,380c-0.1,1,0,1.8,0.5,2.6c0.5,0.8,1.2,1.2,2.2,1.4c1,0.1,1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2
	c0.1-1,0-1.8-0.5-2.6c-0.5-0.8-1.2-1.2-2.2-1.4s-1.8,0-2.6,0.5S638.2,379.1,638,380L638,380z M601,369.3l6.7,3l5.8,2.6
	c0.8,0.4,1.5,0.8,1.9,1.4c0.3,0.3,0.5,0.7,0.5,1.1c0.4,1,0.3,2.1-0.5,3.4l-6.7,11.8c-0.8,1.9-1,3.6-0.4,5c-0.1-1.4,0.1-2.8,1.1-4.3
	l8.3-13.9c0.4-0.5,0.8-1.2,0.8-1.9c0-0.8-0.1-1.5-0.7-2.1c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3H601c-1.4-0.5-2.3-1.4-3-2.3l-0.3-0.5
	l0.3,0.7C598.5,367.2,599.5,368.3,601,369.3L601,369.3z"
          />
          <path
            fill="#EBEBEB"
            d="M613.5,375l-5.8-2.6c0,2.8,0.3,4.5,1.2,5.5l6.5-1.6C614.9,375.8,614.4,375.4,613.5,375L613.5,375z
	 M662.9,352.9l-8.9,1.9l-4.4,1c-1.5,0.3-2.6,0.1-3.4-0.5c-0.8-0.5-1.4-1.5-1.5-3l-0.5-4.5l-1-9.1c-0.5-2.1-1.4-3.4-2.6-4.3
	c1,1,1.7,2.3,1.8,4.1l1.5,16c0,0.8,0.1,1.5,0.4,2.1c0.4,0.5,0.8,1,1.4,1.1l0.3,0.1l1.7-0.1l16.1-3.7c1.7-0.4,3.2-0.3,4.4,0.4
	C666.6,353.2,665,352.8,662.9,352.9L662.9,352.9z"
          />
          <path
            fill="#EBEBEB"
            d="M644.5,352.1c0.1,1.5,0.7,2.5,1.5,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1l-1.4-1.5c-2.6-2.6-5.4-4.4-8.5-5.6
	L644.5,352.1L644.5,352.1z M671.6,365.2c-0.3,0.7-0.8,1.4-1.5,1.9c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1
	c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1l-6.7-12
	c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5l12.4-5.6h0.1l1.7-1.2C670.8,367,671.3,366.1,671.6,365.2L671.6,365.2z M220.5,392.8
	c1.9,4.1,4.7,8,8.1,11.4c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1
	c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8c-11.1,0-20.6,4-28.5,11.8
	c-7.8,7.8-11.8,17.3-11.8,28.5C216.9,382,218,387.6,220.5,392.8L220.5,392.8z M220.5,367.5c0.4-1.9,1-3.7,1.6-5.5
	c1.8-4.7,4.7-8.9,8.5-12.7c7.3-7.3,16.1-11,26.4-11l5.8,0.4c7.8,1.1,14.7,4.7,20.6,10.6c3.9,3.9,6.6,8.1,8.5,12.7l0.5,1.4
	c0.7,2.1,1.2,4.3,1.5,6.5v0.1c0.3,1.9,0.4,3.9,0.4,5.9c0,4.4-0.7,8.5-2.1,12.5c-1.8,5.1-4.8,9.8-8.9,14c-7.3,7.3-16.1,11-26.4,11
	s-19.1-3.6-26.4-10.9l-0.1-0.1c-5.2-5.2-8.7-11.3-10-18.2c-0.6-2.6-0.8-5.4-0.8-8.3C219.7,372.9,220,370.1,220.5,367.5L220.5,367.5z
	"
          />
          <path
            fill="#EBEBEB"
            d="M249.7,370.4c0.6,1,0.6,1.9,0.3,3c-0.3,1-1,1.8-1.9,2.3c-0.1,0-0.1,0.1-0.3,0.1h4c0-1.5,0.6-2.8,1.6-3.7
	c1.1-1.1,2.3-1.5,3.7-1.5c1.5,0,2.8,0.5,3.7,1.5s1.5,2.3,1.5,3.7h4c-1-0.5-1.7-1.2-1.9-2.3c-0.3-1.1-0.3-2.1,0.3-3
	c0.4-0.8,1-1.4,1.7-1.8l1-0.3c1.6-0.5,3.3-0.7,4.7-0.3c1.5,0.3,2.3,1,2.6,2.1c0.3,1.1,0,2.2-1,3.3c-0.8,1.1-2.1,1.8-3.7,2.3h13.5
	l7.3-3c0.7-0.3,1.2-0.5,1.8-1c0.7-0.5,1.2-1.2,1.5-1.9c-0.3-2.2-0.8-4.4-1.5-6.5l-0.5-1.4c-1.8-4.7-4.7-8.9-8.5-12.7
	c-5.9-5.9-12.8-9.4-20.6-10.6c1.2,0.8,2.1,2.3,2.6,4.3c5.8,1.4,10.9,4.4,15.4,8.9l1.5,1.7c1.1,1.2,2.2,2.6,3,4
	c2.1-0.1,3.7,0.3,5,1.2c-1.2-0.7-2.8-0.8-4.4-0.4l-16.1,3.7l-1.6,0.1l-0.3-0.1c-0.5-0.1-1.1-0.5-1.4-1.1c-0.3-0.5-0.4-1.2-0.4-2.1
	l-1.5-16c-0.1-1.8-0.7-3.2-1.8-4.1l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11c-3.9,3.9-6.6,8.1-8.5,12.7c-0.7,1.8-1.2,3.6-1.6,5.5
	c-0.6,2.6-0.8,5.4-0.8,8.3h3.7v-1.9c-1.5-1-2.5-1.9-2.9-3.2l-0.3-0.7l0.3,0.6c0.7,1,1.6,1.8,3,2.3h0.1l6.6,2.8h14.3
	c-1.8-0.5-3-1.4-4-2.5c-1-1.1-1.2-2.2-1-3.3s1.2-1.8,2.6-2.1c1.5-0.3,3-0.3,4.7,0.3l1,0.3C248.7,369,249.3,369.6,249.7,370.4
	L249.7,370.4z M249.4,343l-1.5,16.1c0,0.8-0.1,1.5-0.4,1.9c-0.3,0.4-0.6,0.7-1,1c-0.3,0.1-0.4,0.3-0.7,0.3c-0.6,0.1-1.2,0-2.1-0.3
	l-15.7-3.6c-1.8-0.4-3.2-0.3-4.4,0.4c1.1-1,2.8-1.4,4.9-1.2l1.2-1.8c1-1.4,2.2-2.6,3.4-4c4.4-4.5,9.5-7.4,15.3-8.8
	c0.6-1.9,1.5-3.4,2.6-4.1c-1,1-1.6,2.3-1.8,4L249.4,343L249.4,343z M253.4,362c0.1-1.1,0.6-1.9,1-2.8c0.8-1.2,1.6-1.9,2.8-1.9
	s2.1,0.7,2.9,1.9c0.5,0.8,0.8,1.8,1,2.8c0.1,0.5,0.1,1.1,0.1,1.8v1c-0.1,0.8-0.5,1.5-1.1,2.2c-0.8,0.8-1.8,1.1-2.9,1.1
	s-2.1-0.4-2.8-1.1c-0.6-0.7-1-1.4-1.1-2.2L253.4,362L253.4,362z"
          />
          <path
            fill="#EBEBEB"
            d="M290.7,373.7c0,0.7,0.1,1.4,0.1,2.1h3.7c0-2.1-0.1-4-0.4-5.9c-0.3,0.8-0.8,1.6-1.5,2.5
	C292,372.9,291.5,373.3,290.7,373.7L290.7,373.7z"
          />
          <path
            fill="#EBEBEB"
            d="M262.3,375.8c0,1.5-0.5,2.8-1.5,3.9c-1.1,1-2.3,1.5-3.7,1.5c-1.5,0-2.8-0.5-3.7-1.5
	c-1.1-1.1-1.6-2.3-1.6-3.9h-4c-0.7,0.3-1.5,0.4-2.2,0.3l-1-0.3h-14.3l8.3,3.6c0.8,0.3,1.4,0.5,1.8,1c0.4,0.5,0.7,1.2,0.7,2.1
	c-0.1,0.5-0.4,1.2-0.8,1.9l-8.3,13.9c-1,1.5-1.2,2.9-1.1,4.3c7.3,7.2,16.1,10.9,26.4,10.9s19.1-3.7,26.4-11c4.3-4.1,7.2-8.8,8.9-14
	c1.4-3.9,2.1-8,2.1-12.5h-3.7c0,8.1-2.5,15.3-7.6,21.3c0.8,1.9,1,3.7,0.4,5.1c0.3-1.4-0.1-2.9-1-4.4l-8.3-13.8
	c-0.4-0.5-0.8-1.2-0.8-1.9c-0.1-0.7,0.1-1.4,0.7-2.1c0.4-0.4,1-0.8,1.8-1l7.6-3.3h-13.5c-0.1,0-0.3,0.1-0.3,0.1l-1,0.3
	c-0.8,0.1-1.6,0-2.5-0.4L262.3,375.8L262.3,375.8z M266.7,382.4l0.5,0.8c1.1,1.5,1.6,2.9,1.8,4.4c0.1,1.5-0.3,2.5-1.1,3.2
	c-0.8,0.7-1.9,0.7-3.3,0.1c-1.4-0.5-2.6-1.5-3.6-3l-0.5-0.8c-0.4-0.8-0.5-1.5-0.4-2.3c0.1-1.1,0.7-1.9,1.5-2.6
	c0.8-0.7,1.9-0.8,2.9-0.7C265.4,381.4,266,381.8,266.7,382.4L266.7,382.4z M259.9,394.8l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3
	c-1.5,0.1-3-0.5-4.7-1.9c-3.9,1.5-8,2.3-12.5,2.3c-4.4,0-8.5-0.7-12.2-2.2c-1.6,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2
	l10.7-12.2c0.4-0.7,1-1.1,1.5-1.4c0.7-0.3,1.4-0.3,2.2,0C258.9,393.7,259.4,394.1,259.9,394.8L259.9,394.8z M254.2,384.6
	c0.1,0.8,0,1.7-0.4,2.5l-0.6,0.8c-1.1,1.5-2.2,2.5-3.6,3c-1.4,0.5-2.5,0.5-3.3-0.1c-1-0.7-1.2-1.6-1.1-3.2c0.1-1.5,0.7-3,1.8-4.4
	l0.7-0.8c0.6-0.5,1.4-1,2.2-1.1c1.1-0.1,2.1,0,2.9,0.7C253.5,382.5,254.1,383.5,254.2,384.6z"
          />
          <path
            fill="#EBEBEB"
            d="M219.7,375.8c0,2.9,0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1c-0.4-1.4-0.3-3,0.4-5
	c-5.1-6.1-7.7-13.2-7.7-21.5h-3.7V375.8L219.7,375.8z M228.1,358.6l15.7,3.6c0.7,0.3,1.4,0.3,2.1,0.3c0.3-0.1,0.6-0.1,0.7-0.3
	c0.4-0.3,0.8-0.5,1-1c0.3-0.5,0.4-1.2,0.4-1.9l1.5-16.1V343c0.1-1.6,0.7-3,1.8-4c-1.2,0.8-2.1,2.2-2.6,4.1l-1,9.1l-0.6,4.7
	c-0.1,1.5-0.7,2.6-1.6,3c-0.8,0.7-1.9,0.8-3.4,0.5l-4.4-1l-8.9-1.9c-2.2-0.1-3.7,0.3-5,1.2C224.9,358.3,226.3,358.2,228.1,358.6
	L228.1,358.6z"
          />
          <path
            fill="#EBEBEB"
            d="M228.6,357.8l8.9,1.9l1.6-1.6c1-1,1.9-1.8,2.9-2.5l-12.2,0.5L228.6,357.8L228.6,357.8z"
          />
          <path
            fill="#EBEBEB"
            d="M242,360.5c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.6,1.4-1.5,1.6-3l0.6-4.7c-1.9,0.8-3.7,1.8-5.5,3.2l-2.9,2.5
	l-1.6,1.7L242,360.5L242,360.5z M274.7,370.1c-0.3-1.1-1.2-1.8-2.6-2.1c-1.4-0.3-3-0.3-4.7,0.3l-1,0.3c-0.7,0.4-1.2,1-1.6,1.8
	c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3c1.6-0.5,3-1.4,4-2.5C274.7,372.3,275,371.2,274.7,370.1
	L274.7,370.1z M267.1,368.9c1-0.3,1.8-0.3,2.6,0.3c0.8,0.4,1.4,1.1,1.6,2.1c0.3,0.8,0.3,1.8-0.3,2.6c-0.4,0.8-1.1,1.4-2.1,1.6
	c-0.8,0.3-1.8,0.3-2.6-0.1c-0.8-0.4-1.5-1.1-1.8-2.1c-0.3-1-0.3-1.8,0.3-2.6C265.5,369.9,266.2,369.3,267.1,368.9L267.1,368.9z"
          />
          <path
            fill="#EBEBEB"
            d="M265.1,370.7c-0.4,0.8-0.5,1.6-0.3,2.6s0.8,1.5,1.8,2.1c0.8,0.4,1.8,0.5,2.6,0.1c1-0.3,1.5-0.8,2.1-1.6
	c0.4-0.8,0.5-1.8,0.3-2.6c-0.3-0.8-0.8-1.5-1.6-2.1c-0.8-0.4-1.8-0.5-2.6-0.3C266.2,369.3,265.5,369.9,265.1,370.7L265.1,370.7z
	 M247.9,368.6l-1-0.3c-1.6-0.5-3.3-0.7-4.7-0.3c-1.5,0.3-2.3,1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3
	c0.8,0.1,1.6,0,2.5-0.4c1-0.5,1.6-1.2,1.9-2.3c0.3-1.1,0.3-2.1-0.3-3C249.3,369.6,248.7,369,247.9,368.6L247.9,368.6z M244.6,369.2
	c0.8-0.4,1.8-0.5,2.6-0.1c1,0.3,1.5,0.8,2.1,1.6c0.4,0.8,0.6,1.8,0.3,2.6c-0.3,1-0.8,1.5-1.8,1.9c-0.8,0.4-1.6,0.5-2.6,0.3
	c-1-0.3-1.6-0.8-2.1-1.8c-0.4-0.8-0.6-1.6-0.1-2.6C243.1,370.3,243.6,369.6,244.6,369.2L244.6,369.2z"
          />
          <path
            fill="#EBEBEB"
            d="M242.8,371.1c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8s1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9
	c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-2.1-1.6c-0.8-0.3-1.8-0.3-2.6,0.1S243.1,370.3,242.8,371.1L242.8,371.1z M254.4,359.3
	c-0.6,0.8-0.8,1.8-1,2.8l-0.1,2.8c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.6,1.1,2.8,1.1s2.1-0.4,2.9-1.1c0.5-0.7,1-1.4,1.1-2.2v-1
	c0-0.7,0-1.2-0.1-1.8c-0.1-1.1-0.5-1.9-1-2.8c-0.8-1.2-1.6-1.9-2.9-1.9C256.1,357.3,255.2,358,254.4,359.3L254.4,359.3z M259.9,362
	c0.5,0.5,0.8,1.4,0.8,2.2c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.5,1.1-2.5,1.1s-1.8-0.3-2.3-1.1c-0.7-0.7-1.1-1.5-1.1-2.3
	c0-0.8,0.3-1.5,0.8-2.2l0.3-0.3c0.7-0.7,1.5-1,2.3-1c1,0,1.8,0.3,2.5,1L259.9,362L259.9,362z"
          />
          <path
            fill="#EBEBEB"
            d="M259.6,361.7c-0.7-0.7-1.5-1-2.5-1s-1.8,0.3-2.3,1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1c0.7-0.7,1-1.5,1-2.3c0-0.8-0.3-1.5-0.8-2.2L259.6,361.7L259.6,361.7z M258.8,374.4
	v1.4l0.1,0.3c0.1,0.1,0.3,0.3,0.5,0.3h0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.4-0.4v-0.5h-0.5v0.4l-0.3,0.1h-0.3l-0.1-0.3V374h-0.8
	L258.8,374.4L258.8,374.4z"
          />
          <path
            fill="#EBEBEB"
            d="M257.4,375.1c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1H257l0.1,0.1l0.3,0.1
	l0.5,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3v-0.1l-0.3-0.3l-0.4-0.1L257.4,375.1L257.4,375.1z M257.8,375.4
	c0.1,0,0.1,0,0.1,0.1v0.1h-0.5v-0.1l0.3-0.1L257.8,375.4L257.8,375.4z M255.5,375.1c-0.3,0-0.4,0.1-0.6,0.4l-0.1,0.3v0.3h1.2v0.1
	c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1h-0.6l0.1,0.1l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5l-0.3-0.3
	c-0.1-0.1-0.3-0.1-0.4-0.1L255.5,375.1L255.5,375.1z M255.9,375.4l0.1,0.1v0.1h-0.6v-0.1l0.3-0.1L255.9,375.4L255.9,375.4z
	 M252.8,375.6v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.6V375h-0.6v0.1l-0.1-0.1l-0.3-0.1H253c-0.1,0.1-0.3,0.1-0.3,0.3L252.8,375.6
	L252.8,375.6z M253.4,376.2v-0.5l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.3v0.3l-0.1,0.1h-0.1L253.4,376.2L253.4,376.2z M261,387.9
	c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1c0.8-0.7,1.2-1.6,1.1-3.2s-0.7-2.9-1.8-4.4l-0.5-0.8c-0.5-0.5-1.4-1-2.2-1.1
	c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.7-1.4,1.5-1.5,2.6c-0.1,0.8,0,1.6,0.4,2.3L261,387.9L261,387.9z M260.5,384.7c0.1-1,0.5-1.6,1.4-2.3
	c0.8-0.5,1.6-0.8,2.6-0.5c1,0.1,1.6,0.5,2.2,1.4s0.8,1.6,0.5,2.6c-0.1,1-0.5,1.6-1.4,2.2c-0.8,0.6-1.6,0.8-2.6,0.7
	c-1-0.1-1.6-0.7-2.2-1.4C260.5,386.5,260.4,385.7,260.5,384.7L260.5,384.7z"
          />
          <path
            fill="#EBEBEB"
            d="M261.1,387.2c0.5,0.8,1.2,1.2,2.2,1.4s1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2s0-1.8-0.5-2.6
	c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.6,0.6c-0.8,0.5-1.2,1.4-1.4,2.3C260.4,385.7,260.5,386.5,261.1,387.2L261.1,387.2z
	 M253.8,386.9c0.4-0.8,0.6-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.6-2.6c-0.8-0.7-1.8-0.8-2.9-0.7c-0.8,0.1-1.5,0.5-2.2,1.1l-0.7,0.8
	c-1.1,1.4-1.6,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2c0.8,0.7,1.9,0.7,3.3,0.1s2.6-1.5,3.6-3L253.8,386.9L253.8,386.9z M253.1,387.2
	c-0.6,0.8-1.2,1.2-2.2,1.4s-1.8,0-2.6-0.5c-0.8-0.6-1.2-1.4-1.4-2.2c-0.1-1,0-1.8,0.6-2.6s1.4-1.2,2.2-1.4c1-0.1,1.8,0,2.6,0.5
	c0.8,0.5,1.2,1.2,1.4,2.2C253.8,385.7,253.7,386.5,253.1,387.2z"
          />
          <path
            fill="#EBEBEB"
            d="M253.7,384.7c-0.1-1-0.6-1.6-1.4-2.2c-0.8-0.6-1.6-0.8-2.6-0.6c-1,0.1-1.6,0.5-2.2,1.4
	c-0.6,0.8-0.8,1.6-0.6,2.6c0.1,1,0.6,1.6,1.4,2.2c0.8,0.5,1.6,0.8,2.6,0.5c1-0.1,1.6-0.5,2.2-1.4S253.8,385.7,253.7,384.7
	L253.7,384.7z M258.1,395.6c0.8,0.1,1.5,0.7,2.3,1.5l9.2,10.2c1.6,1.4,3.3,1.9,4.7,1.9c-1.4-0.1-2.8-1-3.9-2.3l-10.6-12.1
	c-0.4-0.7-1-1.1-1.5-1.4c-0.8-0.3-1.5-0.3-2.2,0c-0.6,0.3-1.1,0.7-1.5,1.4L243.9,407c-1.1,1.2-2.3,1.9-3.7,2.2
	c1.5,0.1,3-0.5,4.7-1.8l9.4-10.3c0.8-0.8,1.5-1.4,2.3-1.5l0.8-0.1L258.1,395.6L258.1,395.6z M223.5,374l6.7,3l5.8,2.6
	c0.8,0.4,1.5,0.8,1.9,1.4c0.3,0.3,0.6,0.7,0.6,1.1c0.4,1,0.3,2.1-0.6,3.4l-6.7,11.8c-0.8,1.9-1,3.6-0.4,5c-0.1-1.4,0.1-2.8,1.1-4.3
	l8.3-13.9c0.4-0.5,0.8-1.2,0.8-1.9c0-0.8-0.1-1.5-0.7-2.1c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3h-0.1c-1.4-0.5-2.3-1.4-3-2.3l-0.3-0.5
	l0.3,0.7C221.1,371.9,222,373,223.5,374L223.5,374z"
          />
          <path
            fill="#EBEBEB"
            d="M231.5,382.5l6.5-1.6c-0.4-0.5-1.1-1-1.9-1.4l-5.8-2.6C230.1,379.8,230.6,381.6,231.5,382.5L231.5,382.5z
	 M264.5,342.9l1.5,16c0,0.8,0.1,1.5,0.4,2.1c0.4,0.5,0.8,1,1.4,1.1l0.3,0.1l1.6-0.1l16.1-3.7c1.6-0.4,3.2-0.3,4.4,0.4
	c-1.2-1-2.8-1.4-5-1.2h-0.1l-8.9,1.9l-4.4,1c-1.5,0.3-2.6,0.1-3.4-0.5c-0.8-0.5-1.4-1.5-1.5-3l-0.5-4.5l-1-9.1
	c-0.5-2.1-1.4-3.4-2.6-4.3C263.8,339.7,264.5,341.1,264.5,342.9L264.5,342.9z"
          />
          <path
            fill="#EBEBEB"
            d="M275,357.9c-1.5-1.5-3-2.8-4.7-3.7c-1.2-0.8-2.5-1.4-3.9-1.9l0.5,4.5c0.1,1.5,0.7,2.5,1.5,3
	c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1L275,357.9L275,357.9z"
          />
          <path
            fill="#EBEBEB"
            d="M276.4,359.4l8.9-1.9h0.1c-1-1.4-1.9-2.8-3-4l-12.1,0.5c1.6,1,3.2,2.2,4.7,3.7L276.4,359.4L276.4,359.4z
	 M290.8,373.7c0.7-0.4,1.2-0.8,1.6-1.2c0.8-0.8,1.2-1.6,1.5-2.5c-0.3,0.7-0.8,1.4-1.5,1.9c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3
	c-0.8,0.3-1.4,0.5-1.8,1c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1
	l-6.7-12c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5L290.8,373.7L290.8,373.7z M196,357.5l-19.8,0.8l-0.6,1.1
	c-0.7,1-2.3,1.5-5.2,1.5l-13.1,0.3c-0.4,1.6-0.4,3,0,4.4c1,3.2,4.1,5.6,9.2,7.6l1.2,0.4c7.3,1.9,15.1,2.1,23.5,0.1l2.2-0.5
	C193.7,367.7,194.5,362.4,196,357.5L196,357.5z M362.1,178.4c-0.4,0.7-0.5,1.8-0.4,3.3l1.4,46.5c0,3.6,2.2,5.8,6.9,6.7
	c3.2,0.6,6.5,0.8,10,1l71.4,0.7v-19.4c-0.1-2.6,0.7-4,2.3-4.1h9.9l-16.8-31c-1.1-2.1-3.4-3.2-7-3.3l-75.4-1.2
	C363.3,177.5,362.5,177.8,362.1,178.4L362.1,178.4z M147.9,261.3v0.7l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1.2l0.3,0.5l0.1,0.1v26.8
	l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1l0.3,0.5l0.1,0.1v1.2l0.1,0.3l0.3,0.1c0.1,0,0.1,0,0.3-0.1l0.1-0.3v-1.2l0.1-0.1
	c0.1-0.1,0.3-0.3,0.3-0.5v-1c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-26.8l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5v-1.2c0-0.1-0.1-0.4-0.3-0.5
	l-0.1-0.1v-0.7l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1L148,261L147.9,261.3L147.9,261.3z"
          />
          <path
            fill="#EBEBEB"
            d="M149,263.9c0,0.1-0.1,0.4-0.3,0.5l-0.1,0.1v26.8l0.1,0.1c0.1,0.1,0.3,0.3,0.3,0.5h0.6v-28.1H149L149,263.9
	L149,263.9z"
          />
          <path
            fill="#656565"
            d="M133.4,205.1v-7.7c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3v7.3l0.1,0.3l0.3,0.1l0.3,0.1
	L133.4,205.1L133.4,205.1z M134.4,334.6h-6.5c-0.3,0-0.6,0.1-0.6,0.5v13.5h6.5c0.3,0,0.6-0.1,0.6-0.5V334.6z"
          />
          <path
            fill="#656565"
            d="M134.4,334.2c0-0.3-0.1-0.5-0.6-0.5h-7c-0.3,0-0.6,0.1-0.6,0.5v14c0,0.3,0.1,0.6,0.6,0.6h0.6v-13.5
	c0-0.3,0.1-0.5,0.6-0.5h6.5V334.2z"
          />
          <path
            fill="#D2D3D4"
            d="M691,291.2c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.9,1.2,3,1.2
	c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.9,1.2-3c0-1.2-0.4-2.2-1.2-3C693.2,291.6,692.1,291.2,691,291.2L691,291.2z"
          />
          <path
            fill="none"
            d="M253,375.4l-0.1,0.3v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.6V375h-0.6v0.1l-0.1-0.1l-0.3-0.1H253
	C253.1,375.2,253,375.2,253,375.4L253,375.4z"
          />
          <path
            fill="none"
            d="M253.5,376.2l-0.1-0.4v-0.1l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.3v0.3l-0.1,0.1h-0.1L253.5,376.2
	L253.5,376.2z M255.9,375.1h-0.4c-0.3,0-0.4,0.1-0.6,0.4l-0.1,0.3v0.3h1.2v0.1c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1h-0.6l0.1,0.1
	l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5l-0.3-0.3L255.9,375.1L255.9,375.1z"
          />
          <path
            fill="none"
            d="M255.7,375.4h0.1l0.1,0.1v0.1h-0.6 M257.7,375.4h0.1c0.1,0,0.1,0,0.1,0.1v0.1h-0.5v-0.1L257.7,375.4
	L257.7,375.4z"
          />
          <path
            fill="none"
            d="M257.5,375.1h-0.1c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1H257l0.1,0.1l0.3,0.1
	l0.5,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3v-0.1l-0.3-0.3l-0.4-0.1L257.5,375.1L257.5,375.1z"
          />
          <path
            fill="none"
            d="M259.4,374.4h-0.7v1.4l0.1,0.3c0.1,0.1,0.3,0.3,0.5,0.3h0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.4-0.4v-0.5h-0.5v0.4
	l-0.3,0.1h-0.3l-0.1-0.3v-1.1H259.4L259.4,374.4z M635.3,370.8h0.1c0.1,0,0.1,0,0.1,0.1v0.1H635V371L635.3,370.8L635.3,370.8z
	 M638.6,371.1H638v0.4l-0.3,0.1h-0.3l-0.1-0.3v-1.5h-0.7v1.4l0.1,0.3l0.5,0.3h0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.4-0.4L638.6,371.1
	L638.6,371.1z M631.8,371.4v0.3c0,0.1-0.1,0.1-0.1,0.1h-0.3c-0.1,0-0.3,0-0.3-0.1H631v-0.4l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1
	L631.8,371.4z"
          />
          <path
            fill="none"
            d="M631.4,370.5h-0.5c-0.1,0.1-0.3,0.1-0.3,0.3l-0.1,0.3v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.5v-1.9h-0.5v0.1
	L631.4,370.5L631.4,370.5z M633.8,371.1h-0.7l0.3-0.3h0.1l0.1,0.1 M634.6,371l-0.1,0.3v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1h-0.5
	l0.1,0.1c0.1,0.1,0.1,0.1,0.3,0.1l0.5,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3V371c-0.1-0.1-0.1-0.3-0.3-0.3l-0.7-0.1
	h-0.4C634.9,370.5,634.7,370.7,634.6,371L634.6,371z"
          />
          <path
            fill="none"
            d="M633.9,370.7l-0.4-0.1h-0.4c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.1c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1
	h-0.5l0.1,0.1l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5L633.9,370.7L633.9,370.7z M149,292.1v1
	 M149,293.1h0.6 M149.5,292.1H149 M149,292.1c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-26.8l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5 M149,263.9v-1
	 M149.5,263.9H149 M149.5,263H149"
          />
          <path
            fill="none"
            d="M149,263v-0.3c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-0.7l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1l-0.3,0.1l-0.1,0.3v0.7
	l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1.2l0.3,0.5l0.1,0.1v26.8l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1l0.3,0.5l0.1,0.1v1.2l0.1,0.3
	l0.3,0.1c0.1,0,0.1,0,0.3-0.1l0.1-0.3v-1.2l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5 M146.5,200.4V200c0-0.6-0.1-0.8-0.6-1 M145.4,198.9h0.1
	l0.4,0.1 M146,199l-0.6-0.1 M142.7,204.2l2.9-0.3c0.7,0,1-0.3,1-1v-0.4 M146.5,202.4h2.3v-2.1h-2.3 M146.5,200.4v2.1 M133.4,205.1
	l2.2-0.1 M133.4,197.8v-0.4c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3v7.3l0.1,0.3l0.3,0.1l0.3,0.1
	c0.1-0.1,0.1-0.1,0.1-0.3 M133.4,197.8v7.3 M142.7,204.2l-7,0.7 M145.4,198.9l-12-1.1 M146.9,327.4v-0.5l-4.5-108
	c-0.6-3.9-2.9-6.3-6.9-7.4c-4.8-0.7-10.5-0.8-17.1-0.4l-2.2,0.1c-6.6,0.1-12.1,0.8-16.9,1.9c-4,1.5-6.1,4.1-6.2,8l4.5,108
	c0.6,3.9,2.9,6.3,6.9,7.4c4.8,0.7,10.5,0.8,17.1,0.4h0.6"
          />
          <path
            fill="none"
            d="M135.6,204.9c1.2,0.6,2.3,2.1,3.6,4.3l5.9,11.3c0.8,1.4,1.2,4,1.5,7.8l2.9,34.8 M153.7,261.3l-2.8-33.8
	c-0.3-3.7-0.8-6.5-1.6-8.4l-6.6-15"
          />
          <path
            fill="none"
            d="M149.5,263V295h1 M153.7,261.3h-4.1v1.6 M150.5,295v4.7l3.4,1.4 M152.1,308.6v-2.5 M153.9,304.1h-0.8
	c-0.7,0-1,0.3-1,1v1.1 M152.1,306.2h-1v2.5h1 M152.1,308.6c0.1,0.3,0.3,0.4,0.6,0.5 M152.6,309.3l-1.5,1v8.4l2.5,1.4 M153.9,327.9
	c-0.6-0.3-1.4-0.4-2.5-0.4l-4.4-0.1 M153.5,320.1h-2.6v2.3l3,0.5 M134.4,334.6v13.5c0,0.3-0.1,0.5-0.6,0.5h-6.5 M134.4,334.6v-0.5
	c0-0.3-0.1-0.5-0.6-0.5h-7c-0.3,0-0.6,0.1-0.6,0.5v14c0,0.3,0.1,0.5,0.6,0.5h0.6"
          />
          <path
            fill="none"
            d="M146.9,327.4l-18-0.8c-3.4,0-5.8,1.9-6.6,6.1l-0.1,4.3"
          />
          <path fill="none" d="M127.5,348.7v-13.5c0-0.3,0.1-0.5,0.5-0.5h6.5" />
          <path
            fill="none"
            d="M155,329.1h-25c-3.4,0.3-5,2.6-4.7,6.6v14.6c0.1,2.3,1.5,5.5,4,9.8c0.8,1.1,2.2,1.7,4,1.7 M122.2,336.8
	l-0.4,14.6c0,1.4,1,3.6,2.9,6.6l1.9,3.2 M128.3,363.3c0.7,0.3,1.6,0.4,2.8,0.3l20.6-0.3c1.6-0.1,2.5-0.8,2.5-2.1"
          />
          <path fill="none" d="M126.7,361.3l0.6,0.8c0.3,0.4,0.7,0.8,1.2,1.1" />
          <path
            fill="none"
            d="M126.7,361.3v13.5c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5v-11.6 M142.7,369.9c-0.3,0-0.4,0.6-0.4,1.6
	c-0.1,1.1,0,2.3,0.1,4c0.1,1.5,0.3,2.9,0.6,3.9c0.3,1,0.4,1.5,0.7,1.5 M143.8,380.7c0.1-0.3,0.3-0.7,0.3-1.6c0.1-1.1,0-2.3-0.1-4
	c-0.1-1.5-0.3-2.8-0.6-3.9c-0.3-1.1-0.6-1.6-0.7-1.5"
          />
          <path
            fill="none"
            d="M143.5,380.9h0.1l0.1-0.1 M143.5,380.9l0.3-0.1 M133.2,361.7l-1,0.1 M344.3,155.4c-8.3-1.2-18.2-1.9-29.7-1.8
	H210.5c-10.9,0.3-21.3,1.2-31.2,3l-0.6,4 M451.5,238.6l-71.5-0.7c-3.6-0.1-7-0.4-10.3-1c-5.9-1.1-8.7-4-8.5-8.7l-1.4-46.4
	c-0.3-2.2,0-3.7,0.8-4.7c0.8-1.1,2.2-1.6,4.1-1.5l75.2,1.2c4.4,0.1,7.3,1.5,8.7,4.4l17.3,31.9 M322.2,174c6.2-0.1,9.2,1.9,9.4,6.3
	v49.1c-0.1,3.6-1.4,6.1-4,7.7c-2.3,1.5-5.9,2.2-10.6,1.9H204.1c-4.3-0.3-7.4-1-9.4-2.3c-2.2-1.4-3-3.4-2.6-5.9l4.4-48.1
	c0.4-5.6,3-8.5,7.8-8.8h117.8L322.2,174L322.2,174z"
          />
          <path
            fill="none"
            d="M330.2,180.3c-0.1-3.3-2.8-5-7.8-5H204.7c-4,0.3-6.1,2.8-6.5,7.4l-4.4,48.1c-0.3,1.9,0.4,3.4,1.9,4.4
	c1.8,1.1,4.7,1.8,8.7,2.1h112.8c4.3,0.3,7.6-0.3,9.8-1.6c2.1-1.4,3.2-3.4,3.3-6.5v-49H330.2L330.2,180.3z M451.3,167.8l-105.5-1.9v1
	 M345.8,166.8h-1.5 M344.3,166.8v-11.4 M489.2,212.9l-26.8-44.3c-2.2-3-4.8-4.8-7.8-5.4 M454.5,163.2c0.3-1.4,0.1-2.5-0.3-3.6
	c-6.7-1.4-15.1-2.2-25.2-2.5l-84.7-1.8 M454.5,163.2l-3.2,4.5 M463.6,213.2h-9.9c-1.6,0-2.3,1.4-2.3,4.1v19.5"
          />
          <path
            fill="none"
            d="M461.8,240.8l4.3-3.3c1.8-1.4,2.6-2.9,2.6-4.5v-10.9c0.1-2.6-0.6-4.3-1.6-4.9l-5.1-2.8 M465.8,213.2H464
	 M464,213.2h-0.4 M463.6,213.2h0.1 M463.7,213.2c-0.8,0.1-1.4,0.6-1.8,1.2"
          />
          <path
            fill="none"
            d="M461.9,214.4c-0.3,0.4-0.4,1-0.4,1.6v22.8c0,0.8,0.1,1.5,0.3,1.9 M463.7,213.2h0.3 M465.8,213.2h1.6
	c2.2-0.3,4.3,0.7,6.3,2.9c1.8,1.9,2.8,3.6,2.8,5.1v11.7c0,1.8-0.4,3.3-1.4,4.3l-0.8,0.7 M451.3,236.7l0.1,1.9 M463.6,241.9h-9.2
	c-1.8,0.1-2.8-1-2.9-3.3 M461.8,240.8c0.3,0.7,1,1.1,1.8,1.1"
          />
          <path
            fill="none"
            d="M467.2,242.1l-0.1-0.1h-3.4 M467.2,242.1v4.7c0,0.7,0.3,1,1,1h1.2 M469.4,247.6l-0.7,1 M468.7,248.5h-1.2
	c-0.7,0-1,0.4-0.8,1.2l0.3,4.7c0,0.3,0.1,0.6,0.3,0.7l0.3,0.1 M467.4,255.3h0.1 M467.6,255.3l0.4,0.6 M467.9,255.4v0.3 M467.4,255.3
	l0.4,0.1 M467.9,255.7l-0.3-0.4 M467.9,255.7l0.1,0.1 M468,255.8c0.4,1.2,1.1,2.2,2.1,3.2c1.7,1.6,3.7,2.6,6.1,2.6
	c2.3,0,4.4-0.8,6.2-2.6c1.6-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.2c-1.6-1.6-3.7-2.5-6.2-2.5l-1.6,0.1 M379.7,257.3
	c-0.3-1.2-1-2.3-1.9-3.3c-1.5-1.5-3.3-2.2-5.4-2.2s-3.9,0.7-5.2,2.2c-1,1-1.6,2.1-1.9,3.3 M354.9,260.1c0,0.7,0.3,1.4,0.8,1.8
	c0.5,0.5,1.1,0.7,1.8,0.7s1.4-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8s-0.3-1.2-0.7-1.8c-0.5-0.5-1.1-0.8-1.8-0.8
	c-0.7,0-1.2,0.3-1.8,0.8C355.2,258.7,354.9,259.4,354.9,260.1L354.9,260.1z"
          />
          <path
            fill="none"
            d="M358.4,256.5l-0.8-0.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6
	c0.7,0.7,1.6,1.1,2.6,1.1h0.5 M365.2,257.3h-2.1c-0.5-0.3-1.1-0.5-1.8-0.7l-3-0.1"
          />
          <path
            fill="none"
            d="M358.1,263.8c0.8-0.1,1.5-0.4,2.1-1.1c0.7-0.7,1.1-1.7,1.1-2.6c0-1-0.4-1.9-1.1-2.6c-0.5-0.5-1.1-0.8-1.8-1"
          />
          <path
            fill="none"
            d="M358.1,263.8l3.3-0.3c1.1-0.3,1.9-0.5,2.3-1h1.9"
          />
          <path
            fill="none"
            d="M365.5,262.4c0.4,0.8,0.8,1.5,1.5,2.2c1.5,1.5,3.2,2.2,5.2,2.2s3.9-0.7,5.4-2.2c0.7-0.7,1.2-1.4,1.5-2.2
	 M363.3,258.6c0.3-0.4,0.5-0.5,1-0.5h20.9c0.5,0,1,0.1,1.4,0.5c0.4,0.4,0.5,0.8,0.5,1.4s-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5
	h-20.9c-0.4,0-0.7-0.1-1-0.5c-0.3-0.4-0.4-0.8-0.4-1.4S363,259,363.3,258.6L363.3,258.6z M356.2,271c-0.1,0.3-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.8
	c-0.3-0.1-0.4-0.3-0.7-0.3C356.6,270.7,356.3,270.8,356.2,271L356.2,271z"
          />
          <path
            fill="none"
            d="M353.8,271.8c0,0.8,0.3,1.5,0.8,2.2c0.5,0.7,1.2,0.8,2.2,0.8c0.8,0,1.5-0.3,2.2-0.8c0.5-0.5,0.8-1.2,0.8-2.2
	c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.2,0.8C354.2,270.1,353.8,271,353.8,271.8L353.8,271.8z
	 M381.3,257.3h-1.6 M379.3,262.4h2.1 M381.3,262.4c0.5,0.5,1.2,0.7,2.1,0.7h2.6c1,0,1.6-0.3,2.3-1c0.7-0.7,1-1.4,1-2.3
	c0-1-0.3-1.6-1-2.3c-0.7-0.7-1.5-1-2.3-1h-2.6c-0.8,0-1.5,0.3-2.2,0.8"
          />
          <path
            fill="none"
            d="M381.3,262.4h4c0.7,0,1.2-0.3,1.8-0.7c0.4-0.4,0.7-1.1,0.7-1.6v-0.1c0-0.7-0.3-1.2-0.7-1.8
	c-0.5-0.4-1.1-0.7-1.8-0.7h-4.1 M379.7,257.3h-14.4 M379.3,262.4h-13.8 M346.8,300.9v3.7 M346.8,304.7c-0.3-1.4-0.3-2.6,0-3.7
	 M346.8,300.9v-43.7 M346.8,257.2l-1-90.4 M178.6,160.6l-8.7,3.7c-1.8,0.8-3,2.2-3.7,4.4 M166.2,168.7c-1.9,0.4-2.9,1.4-3,3.2
	l-0.4,10.5 M162.7,182.2l-1.9-0.1l-0.4,3 M166.2,168.7l-0.3,1.2l-2.1,12.2v0.1l-0.4,3.4 M178.6,160.6l-0.7,5.6 M160.4,185.1h-0.1
	c-0.4,0-0.8,0.3-1.2,0.8c-0.3,0.6-0.6,1.1-0.6,1.9c0,0.7,0.1,1.4,0.6,1.9l0.6,0.7 M159.7,190.2l-0.3,1.9l2.9,0.4 M162.3,192.5
	l-0.3,1.8c-2.8,19.3-5.1,39.8-7.3,61.4 M159.7,190.2l0.7-5.1 M163.4,185.8c-0.7,0-0.8-0.6-0.7-1.6l0.1-1.9 M162.3,192.5l1.1-6.7
	 M188.9,325.7l1.9-0.1l7.2-16c1.1-2.5,2.2-4.5,3.6-6.3c3.7-4.8,8.7-7.6,15-8.1c16.2-1.5,35.4-1.2,57.2,0.7c6.6,0.4,12,1.6,16.2,4
	c0.4,0.3,0.8,0.5,1.1,0.8c0.8,0.5,1.5,1.1,2.1,1.7l4.5,4.8l4.8,5.9c7.2,9.1,14.3,18.8,21.3,29.4l5.2,8.1"
          />
          <path
            fill="none"
            d="M353.7,350.7c-10-14.6-19.8-28.3-29.7-41.1l-12.8-16.4c-6.9-8.5-18-13.1-33.2-14c-21.7-2.1-44.4-2.5-68.1-1
	c-9.4,0.8-16.4,5-21,12.5c-6.6,10.2-12.9,22.1-19.1,35.6l19.1-0.7"
          />
          <path
            fill="none"
            d="M345,350.7l-38.5-51.3c-5.5-7.3-12.8-11.6-21.6-12.8c-20.1-2.5-42.4-3.3-66.6-2.8c-10.5-0.3-18.2,2.2-23.1,7.6
	l-1.6,2.2l-5,7.6c-4.1,6.7-8.3,15-12.2,24.6 M168.5,255.7h-13.8 M154.2,261.3h-0.6 M157.1,294.6c0.4-0.3,0.7-0.8,0.8-1.4l0.6-29.6
	c0-0.4-0.1-0.8-0.3-1.1"
          />
          <path
            fill="none"
            d="M158.3,262.4c1.1,0.1,1.6,1,1.9,2.5l-0.6,27c-0.1,1.9-1,2.8-2.6,2.8 M158.3,262.4c-0.3-0.7-1-1.1-1.8-1.1h-2.2
	 M181.4,263.2c-2.3,0-4.4,0.8-6.1,2.5c-1.6,1.6-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1s3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5
	c1.6-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.1C185.8,264.1,183.9,263.2,181.4,263.2L181.4,263.2z"
          />
          <path
            fill="none"
            d="M181.4,265.9c-1.6,0-3,0.5-4.3,1.8c-1.2,1.2-1.8,2.6-1.8,4.3s0.6,3,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8
	c1.6,0,3-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3c0-1.6-0.6-3-1.8-4.3C184.6,266.4,183.1,265.9,181.4,265.9L181.4,265.9z"
          />
          <path
            fill="none"
            d="M181.4,259.8c-3.3,0-6.2,1.2-8.5,3.6c-2.3,2.3-3.6,5.2-3.6,8.5c0,3.3,1.2,6.2,3.6,8.5c2.3,2.3,5.2,3.6,8.5,3.6
	c3.3,0,6.2-1.2,8.5-3.6c2.3-2.3,3.6-5.2,3.6-8.5c0-3.3-1.2-6.2-3.6-8.5C187.6,261,184.7,259.8,181.4,259.8L181.4,259.8z"
          />
          <path
            fill="none"
            d="M184.5,271.9c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.1-0.8s-1.5,0.3-2.1,0.8c-0.6,0.5-0.8,1.2-0.8,2.1
	c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8S184.5,272.7,184.5,271.9L184.5,271.9z"
          />
          <path
            fill="none"
            d="M185.7,271.9c0-1.1-0.4-2.2-1.2-3c-0.8-0.8-1.8-1.2-3-1.2c-1.1,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3
	c0,1.1,0.4,2.2,1.2,3c0.8,0.8,1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2C185.3,274.1,185.7,273,185.7,271.9L185.7,271.9z M153.9,301.1v3
	 M157.1,294.6c-0.4,0.3-0.8,0.5-1.4,0.4h-1.8 M153.9,301.1V295 M154.8,255.7l-0.6,5.6 M177.9,166.3c-3.7,28.3-6.9,58.1-9.4,89.4
	 M177.9,166.3l89.3,0.6h77.2 M168.5,255.7l178.3,1.5 M662.8,259l-0.5-3.6c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.1-0.1,0.3-0.1h5.2
	c0.1,0,0.3,0,0.4,0.1l0.1,0.3l0.5,3.6 M661.5,252.5l-24.1-2.2l-103-10.2 M669,259h0.3c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.3
	v0.3c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.4 M669.1,260.8h0.8c0.3,0,0.5,0.1,0.5,0.5l0.5,3.2c0,0.1,0,0.3-0.1,0.4"
          />
          <path
            fill="none"
            d="M670.9,264.9l-0.3,0.4c-0.1,0.1-0.1,0.1-0.4,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5v-0.3"
          />
          <path
            fill="none"
            d="M670.9,264.9l-0.3,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5v-0.3 M688.1,259.1L688,259c-0.4-0.5-1-1-1.5-1.4
	 M686.4,257.7l1.5,10.2 M688.8,263.7c-0.1-2.1,0-2.9,0.7-2.6 M668.1,271.4h2.3c0.3,0,0.5,0.1,0.5,0.5v1.6 M671,273.4v1.6"
          />
          <path
            fill="none"
            d="M671,273.4h0.7v1.6 M671.7,275.1H671 M671.7,275.1l5.9,0.8c4.5,0.4,8.5,1.2,12,2.3 M691.9,279.2l-0.5-2.5
	 M689.5,261.2c0.3,0.4,0.4,0.8,0.7,1.2l5.5,18.7 M691.9,279.2c-0.8-0.3-1.5-0.7-2.5-1 M708,304.8c1.1-0.4,1.5-1,1.1-1.9
	c-2.3-6.2-4.7-11.3-7.2-15.3c-0.8-1.4-1.8-2.6-2.9-3.7 M694,292.4c-0.8-0.8-1.9-1.2-3-1.2c-1.2,0-2.2,0.4-3,1.2
	c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.9,1.2,3,1.2c1.2,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.9,1.2-3
	S694.8,293.4,694,292.4L694,292.4z M695.7,281.1c1.2,0.8,2.5,1.8,3.4,2.8"
          />
          <path
            fill="none"
            d="M699.1,283.9l-6.3-19.7c-1.4-2.6-2.9-4.4-4.7-5.1 M691.4,276.7c0.3-6.1-0.5-10.5-2.6-12.9 M695.7,281.1
	c-1.1-0.7-2.3-1.4-3.7-1.9 M689.6,278.2l-1.7-10.3 M688.8,263.7c0.3,2.5,1.1,6.9,2.6,12.9 M688.1,259.1c0.5,0.5,1,1.2,1.4,1.9
	 M662.8,259h-1.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3v0.3l0.1,0.3l0.3,0.1h1 M667.3,257.9l-0.3-0.8h-2.9v0.8 M662.8,260.8h-0.1l-0.1,0.1
	h-0.1l-0.1,0.1 M662.6,260.2l0.1,0.5"
          />
          <path
            fill="none"
            d="M663.2,260.2h-0.1l-0.1-0.4l-0.1-0.8 M663.2,260.2h-0.5"
          />
          <path
            fill="none"
            d="M664.2,257.9l0.1,1.9c0,0.1,0,0.3-0.1,0.3h-1.1 M665,264.4l-0.5-3l-0.3-0.4 M662.2,261l-0.3,0.4v0.4l0.5,3.2
	c0,0.3,0.3,0.5,0.5,0.5h1.1c0.1,0,0.3,0,0.4-0.1l0.3-0.4"
          />
          <path
            fill="none"
            d="M664.8,264.9l-0.3,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5l-0.6-3.2V261 M662.8,260.8h1.1c0.1,0,0.3,0,0.4,0.1
	 M669,260.2l-0.3,0.1h-0.4l-0.3-0.1h-0.1l-0.1-0.4l-0.4-1.9"
          />
          <path
            fill="none"
            d="M669,259l0.1,0.8c0,0.1,0,0.3-0.1,0.3h-0.1 M668.4,260.9h0.1l0.3-0.1h0.3 M669.1,260.8l-0.1-0.5 M669,264.4
	l-0.5-3v-0.4 M664.8,264.9c0.1-0.1,0.1-0.3,0.1-0.4v-0.1 M665,264.4l0.8,0.5v2.1 M668,267.1V265l0.5-0.4 M668.6,264.6l0.4-0.3
	 M664.2,260.9h4.3 M667.3,257.9h-3.2 M663.6,274.3v-2.5c0-0.3,0.1-0.5,0.5-0.5h1.9"
          />
          <path
            fill="none"
            d="M665.9,271.4l0.1,1.8h2.2l-0.1-1.8 M668.1,271.4l-0.1-4.3 M665.9,271.4l-0.1-4.4 M686.4,257.7
	c0-1.2-2.3-2.2-6.9-3l-18-2.2 M688,267.9l-19.9-0.8 M671,275.1l-7.4-0.8 M708,304.8l-5.1,1.7l-2.6,0.3h-9.5
	c-1.1,0.1-1.9-0.3-2.5-1.2c-5-6.5-9.6-10.3-14.2-12c-5.1-1.6-12.9-2.5-23.8-2.8h-49.2c-7.2,0.1-12.9,3.3-17.3,9.1
	c-11.6,14.4-23.1,30.4-34.7,48.1h-16 M489.9,214.1v-63.3c0.1-0.1,0.1-0.3,0.1-0.4v-1.1c0-0.1-0.1-0.3-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1l0.1,0.4h0.1v62 M583.4,238.9v-0.1l-5-0.6l-1.8,4.3"
          />
          <path
            fill="none"
            d="M585.1,243.2v-3.7c0-0.3-0.1-0.6-0.5-0.6l-1.2-0.1 M584.2,243.2l-0.8-4.3 M584.2,243.2h0.8 M576.7,242.5
	l7.6,0.7 M533.2,348.1h-10c13.6-22.8,27-42.5,40-58.9c6.9-9.5,16.2-14.7,28.1-15.7c25.7-1.6,49.9-1.4,72.5,0.8 M489.9,214.1
	l-0.7-1.2 M484.6,223.4c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4
	c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1S484.4,223.2,484.6,223.4L484.6,223.4z M489.2,230.5l0.3,0.4l0.4,0.3l0.5,0.1
	c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4l-0.5,0.1l-0.4,0.3l-0.3,0.6"
          />
          <path
            fill="none"
            d="M489.2,229.5L489,230l0.1,0.6 M489.2,230.5v-1 M480.2,221.2l5.5,10c1.2,2.2,2.3,4.7,3.3,7.8l0.7,2.6
	c1.2,4.8,2.2,10.7,2.8,17.6 M497.6,243.3c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1
	s0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4C497.4,244,497.6,243.7,497.6,243.3L497.6,243.3z M500.2,234.4
	c0,0.3,0.1,0.6,0.3,1l0.5,0.7c0.3,0.1,0.4,0.1,0.6,0.1c0.1-0.1,0.3-0.3,0.1-0.6c0-0.3-0.1-0.6-0.3-0.8c-0.1-0.3-0.4-0.6-0.5-0.7
	l-0.5-0.1C500.2,233.8,500,234.1,500.2,234.4L500.2,234.4z M503.8,237.1l-0.4-0.7 M510,237l-0.1-0.4l-1.8-0.1l-0.1,0.4 M507.7,237.4
	l0.1-0.6 M510,237l0.3,0.7 M507.7,237.4l2.5,0.3 M507.9,236.8l-4.5-0.4 M503.8,237.1l4,0.3 M498.9,251.8c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.5-0.4-1-0.4s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4
	C498.8,252.5,498.9,252.2,498.9,251.8L498.9,251.8z M474.5,237.5l-1.8,1.5c-2.5,2.1-4.3,3-5.5,2.9 M478.9,248.5h-1.7 M467.9,255.7
	c1,0.8,1.9,1.2,3.2,1.4h4.7c1.2-0.1,2.1-0.5,2.3-1.4l0.8-7.3"
          />
          <path
            fill="none"
            d="M477.2,248.5c0.7,0,1,0.4,0.8,1.2l-0.4,4.7c0,0.7-0.4,1-1,1h-8.8 M474.5,244.4v2.2c0,0.7-0.3,1-1,1h-4.1"
          />
          <path
            fill="none"
            d="M468,255.8c1.4,1.5,2.6,2.2,3.7,2.2h7.6c1.4,0,2.2-0.4,2.2-1.5l0.5-8.1h-3.2 M474.5,244.4v-6.9 M503.3,236.4
	l-12.5-20.8 M490.8,215.7l-1-1.5 M490.8,215.7l-10.6,5.5 M534.4,239l0.3-2.8c-1.1-0.4-1.8-0.3-2.1,0.1l-0.3,2.3"
          />
          <path
            fill="none"
            d="M534.2,238.2c0.1-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3
	c-0.1,0.1-0.3,0.3-0.3,0.6s0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3C533.9,238.3,534,238.3,534.2,238.2z M534.4,240.1V239
	 M532.2,239.9l2.2,0.3 M532.4,238.9l-0.1,1 M487,265.3h-1.1l-10.6,1.2c-1.1,0.1-1.6,0.7-1.6,1.5v6.6c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2
	h1.1"
          />
          <path
            fill="none"
            d="M490.1,266c-0.5-0.3-1-0.4-1.7-0.5h-0.3l-1.4-0.1 M492.9,271.1v-5.4c0-0.3-0.1-0.5-0.3-0.7
	c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v0.1 M485.6,270.5c-0.3-0.3-0.4-0.3-0.8-0.3
	c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3
	c0.3-0.3,0.3-0.4,0.3-0.8C486,271,485.9,270.7,485.6,270.5L485.6,270.5z"
          />
          <path
            fill="none"
            d="M487,265.3l-11,2.1v2.2h11.6v3.6H476v2.1l11,2.2 M490.1,276.6v0.8c0,0.3,0.1,0.5,0.3,0.7
	c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7v-6.3"
          />
          <path
            fill="none"
            d="M487,277.4l1.4-0.1l0.3-0.1l1.5-0.5 M490.1,276.6v-5.5 M479.8,271.2c0-0.3-0.1-0.5-0.3-0.8
	c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3C479.8,271.8,479.8,271.5,479.8,271.2z M490.1,271.1V266 M492.6,259.1l0.3,3v52 M505.4,259.3l-12.8-0.1
	 M490.1,271.1h2.8 M532.4,238.9l-22.4-1.9 M505.4,259.3c-1.8-11.7-2.5-19-1.7-22.3 M510.2,237.7l22,2.2 M534.4,240.1
	c1.4,5.8,2.1,12.7,2.2,20.5 M576.7,242.5l-42.2-3.4 M661.5,252.5l-76.5-9.4 M665.8,267l-160.4-7.7 M487,313.6h-1.1l-10.6,1.2
	c-1.1,0.1-1.6,0.7-1.6,1.5v6.6c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1"
          />
          <path
            fill="none"
            d="M492.9,314.1c0-0.3-0.1-0.4-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3
	c-0.1,0.1-0.3,0.4-0.3,0.7v0.1 M490.1,314.3c-0.5-0.3-1-0.4-1.7-0.5h-0.3l-1.4-0.1 M485.6,319c-0.3-0.3-0.4-0.3-0.8-0.3
	c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3
	c0.3-0.3,0.3-0.4,0.3-0.8C486,319.4,485.9,319.1,485.6,319L485.6,319z"
          />
          <path
            fill="none"
            d="M487,313.6l-11,2.2v2.1h11.6v3.6H476v2.1l11,2.2 M490.1,325v0.8c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3
	h0.7c0.3,0,0.4-0.1,0.7-0.3"
          />
          <path
            fill="none"
            d="M487,325.7l1.4-0.1h0.3l1.5-0.5 M490.1,325v-5.5 M479.8,319.6c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3
	c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3
	C479.8,320.2,479.8,319.9,479.8,319.6L479.8,319.6z M490.1,319.5v-5.2 M492.6,326.5c0.3-0.3,0.3-0.4,0.3-0.7v-6.3 M492.9,319.5v-5.4
	 M492.6,326.5c-0.8,6.1-2.6,10-5.8,12c-3.3,3.4-10.2,5.1-20.9,4.7h-0.3 M490.1,319.5h2.8 M531.1,350.7h14.7c0.7,0,0.8,0.3,0.4,1
	l-1.5,2.9 M533.2,348.1l-2.1,2.6"
          />
          <path
            fill="none"
            d="M324.6,351.7v9.2c0,0.7,0.3,1,1,1h214.2c0.7,0,1.1-0.3,1.5-1l3.4-6.3 M696.1,354.2c-1.7-6.2-4.1-11.8-7.7-17.2
	c-0.8-1.2-1.8-2.6-2.8-3.9c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6c-17.6,0-32.6,6.2-45,18.6l-1.5,1.5
	c-1.7,1.8-3.2,3.6-4.5,5.4l-0.3,0.3c-4.8,6.5-8.3,13.5-10.3,21"
          />
          <path
            fill="none"
            d="M586,334.9c-1,1.2-1.9,2.6-2.8,4c-6.1,9.5-9.1,20.2-9.1,32.3c0,12,3,22.7,9.1,32.2c0.8,1.4,1.8,2.6,2.8,4
	c1.8,2.3,3.7,4.4,5.8,6.6c3,3,6.2,5.6,9.6,8c9.6,6.5,20.8,9.8,33.2,9.8s23.5-3.3,33.2-9.8c3.3-2.2,6.6-5,9.6-8
	c2.1-2.1,4-4.3,5.8-6.6c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.8-3.3-5.5-5.2-8.1
	c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6
	C588.9,331.5,587.4,333.1,586,334.9L586,334.9z"
          />
          <path
            fill="none"
            d="M626.1,338.5c-5.8,1.4-10.7,4.4-15.3,8.8c-1.8,1.8-3.4,3.7-4.7,5.8"
          />
          <path
            fill="none"
            d="M606.1,353.1c-2.2-0.1-3.7,0.3-5,1.2c1.2-0.7,2.6-0.8,4.4-0.4l15.7,3.6c0.7,0.3,1.4,0.3,2.1,0.3l0.7-0.3
	c0.4-0.3,0.8-0.5,1-1c0.3-0.5,0.4-1.2,0.4-1.9l1.5-16.1v-0.1c0.1-1.6,0.7-3,1.8-4c-1.2,0.8-2.1,2.2-2.6,4.1"
          />
          <path
            fill="none"
            d="M625,347.6c-3,1.2-5.8,3-8.4,5.6l-1.7,1.6 M615,355l4.4,1c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.5,1.4-1.5,1.7-3
	l0.5-4.7 M626.1,338.5l-1.1,9.1 M615,355l-8.9-1.9"
          />
          <path
            fill="none"
            d="M640.4,334.1l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11c-3.9,3.9-6.6,8.1-8.4,12.7c-0.7,1.8-1.2,3.6-1.7,5.5
	c-0.5,2.6-0.8,5.4-0.8,8.3s0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1"
          />
          <path
            fill="none"
            d="M607.8,372.3l-6.7-3h-0.1 M600.9,369.3v1.9c0,8.3,2.6,15.4,7.7,21.5"
          />
          <path
            fill="none"
            d="M608.2,397.6c-0.1-1.4,0.1-2.8,1.1-4.3l8.3-13.9c0.4-0.5,0.8-1.2,0.8-1.9c0-0.8-0.1-1.5-0.7-2.1
	c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3h-0.1c-1.4-0.6-2.3-1.4-3-2.3l-0.3-0.5l0.3,0.7c0.6,1.1,1.5,2.2,2.9,3.2 M619.7,363.3
	c-1.5,0.3-2.3,1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3c0.8,0.1,1.7,0,2.5-0.4c1-0.5,1.7-1.2,1.9-2.3
	c0.3-1.1,0.3-2.1-0.3-3c-0.4-0.8-1-1.4-1.8-1.8l-1-0.3C622.8,363.1,621.2,363,619.7,363.3z"
          />
          <path
            fill="none"
            d="M624.7,364.2c-0.8-0.3-1.8-0.3-2.6,0.1c-0.8,0.4-1.4,1.1-1.7,2.1c-0.3,0.8-0.3,1.8,0.1,2.6
	c0.4,0.8,1.1,1.5,2.1,1.8s1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9c0.3-1,0.3-1.8-0.3-2.6S625.5,364.5,624.7,364.2L624.7,364.2z
	 M627.2,376.6c-0.8,0.1-1.5,0.6-2.2,1.1l-0.7,0.8c-1.1,1.4-1.7,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2c0.8,0.7,1.9,0.7,3.3,0.1
	c1.4-0.6,2.6-1.5,3.6-3l0.5-0.8c0.4-0.8,0.5-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.7-2.6C629.2,376.6,628.3,376.5,627.2,376.6L627.2,376.6
	z M608.6,392.7l6.7-11.8c0.7-1.4,1-2.5,0.5-3.4c-0.1-0.4-0.3-0.8-0.5-1.1 M615.3,376.3c-0.4-0.5-1-1-1.9-1.4l-5.8-2.6"
          />
          <path
            fill="none"
            d="M625.8,383.3c0.8,0.5,1.7,0.8,2.6,0.5c1-0.1,1.7-0.5,2.2-1.4c0.6-0.8,0.8-1.6,0.6-2.6c-0.1-1-0.5-1.6-1.4-2.2
	c-0.8-0.6-1.7-0.8-2.6-0.6c-1,0.1-1.7,0.5-2.2,1.4c-0.5,0.8-0.8,1.7-0.5,2.6C624.5,382,625,382.8,625.8,383.3L625.8,383.3z
	 M608.2,397.6c7.3,7.3,16.1,10.9,26.4,10.9s19.1-3.7,26.4-11c4.1-4.1,7.2-8.8,8.9-14c1.4-3.9,2.1-8,2.1-12.5c0-2.1-0.1-4-0.4-5.9
	 M608.6,392.7c-0.8,1.9-1,3.6-0.4,5.1"
          />
          <path
            fill="none"
            d="M598,388.2c1.9,4.1,4.7,8,8.1,11.4c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6
	c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8
	s-20.6,4-28.5,11.8C598,350.5,594,360,594,371.1C594.4,377.3,595.5,382.9,598,388.2L598,388.2z"
          />
          <path fill="none" d="M615.3,376.3l-6.5,1.7c-0.8-1-1.2-2.8-1.2-5.5" />
          <path
            fill="none"
            d="M573,354.5c-1.4,5.2-2.1,10.9-2.1,16.6c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3c1.8,2.3,3.9,4.7,6.1,6.9
	c2.1,2.1,4.3,4,6.5,5.8c11,8.7,23.9,12.9,38.5,12.9c14.6,0,27.5-4.3,38.5-12.9c2.2-1.8,4.4-3.6,6.5-5.8c2.2-2.2,4.3-4.5,6.1-6.9
	c1-1.2,1.8-2.5,2.8-3.9c6.6-10,9.8-21.5,9.8-34.3c0-5.9-0.7-11.6-2.2-16.9"
          />
          <path
            fill="none"
            d="M622.3,402.6c-1.7,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2c0.4-0.7,1-1.1,1.5-1.4
	c0.7-0.3,1.4-0.3,2.2,0c0.5,0.3,1.1,0.7,1.5,1.4l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9 M544.8,354.6l28.2-0.1
	 M662.9,352.9c-1.2-1.9-2.8-3.9-4.5-5.5c-4.5-4.5-9.6-7.4-15.4-8.9 M662.9,352.9l-9.1,1.8 M643,338.5c-0.5-2.1-1.4-3.4-2.6-4.3"
          />
          <path
            fill="none"
            d="M640.4,334.1c1,1,1.7,2.3,1.8,4.1l1.5,16c0,0.8,0.1,1.5,0.4,2.1c0.4,0.5,0.8,1,1.4,1.1l0.3,0.1l1.7-0.1
	l16.1-3.7c1.7-0.4,3.2-0.3,4.4,0.4c-1.2-1-2.8-1.4-5-1.2 M643.9,347.6l-1-9.1"
          />
          <path
            fill="none"
            d="M643.9,347.6l0.5,4.5c0.1,1.5,0.7,2.5,1.5,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1 M671.6,365.2
	c-0.3-2.2-0.8-4.4-1.5-6.5l-0.5-1.4c-1.8-4.7-4.7-8.9-8.5-12.7c-5.9-5.9-12.8-9.4-20.6-10.6"
          />
          <path
            fill="none"
            d="M653.8,354.7l-1.4-1.5c-2.6-2.6-5.4-4.4-8.5-5.6 M709,307.1h-0.4l-6.9,1.9h-9.6l-1.9,1"
          />
          <path
            fill="none"
            d="M690.2,310H703l8.3-2.9 M671.6,365.2c-0.3,0.7-0.8,1.4-1.5,1.9c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3
	c-0.8,0.3-1.4,0.5-1.8,1c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1"
          />
          <path
            fill="none"
            d="M668.1,369l1.8-1.4c0.8-0.8,1.2-1.6,1.5-2.5 M696.1,354.2h2.6 M698.7,354.2c0,2.3,0.3,3.4,0.8,3.2h2.6
	 M701.3,354.2h-2.6 M701.3,354.2l0.8,3.2 M631.8,354.6c-0.5,0.8-0.8,1.8-1,2.8l-0.1,2.8c0.1,0.8,0.5,1.5,1.1,2.2
	c0.8,0.8,1.7,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2v-1c0-0.7,0-1.2-0.1-1.8c-0.1-1.1-0.5-1.9-1-2.8
	c-0.8-1.2-1.7-1.9-2.8-1.9C633.6,352.7,632.7,353.4,631.8,354.6L631.8,354.6z"
          />
          <path
            fill="none"
            d="M632.2,357.1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1.1,2.3,1.1
	c1,0,1.8-0.3,2.5-1.1c0.7-0.7,1-1.5,1-2.3s-0.3-1.5-0.8-2.2l-0.3-0.3c-0.7-0.7-1.5-1-2.5-1C633.6,356.1,632.9,356.4,632.2,357.1z
	 M644.8,363.8l-1,0.3c-0.7,0.4-1.2,1-1.7,1.8c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3
	c1.7-0.5,3-1.4,4-2.5c1-1.1,1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1C648.1,363.1,646.5,363.1,644.8,363.8L644.8,363.8z"
          />
          <path
            fill="none"
            d="M647.2,364.5c-0.8-0.4-1.8-0.5-2.6-0.3c-1,0.3-1.5,0.8-1.9,1.8c-0.4,0.8-0.5,1.6-0.3,2.6c0.3,1,0.8,1.5,1.8,2.1
	c0.8,0.4,1.8,0.5,2.6,0.1c1-0.3,1.5-0.8,2.1-1.6c0.4-0.8,0.5-1.8,0.3-2.6C648.6,365.6,648.1,365,647.2,364.5L647.2,364.5z
	 M634.6,365.9c-1.5,0-2.8,0.5-3.7,1.5c-1.1,1-1.7,2.3-1.7,3.7c0,1.5,0.5,2.8,1.7,3.9c1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5
	c1-1.1,1.5-2.3,1.5-3.9s-0.5-2.8-1.5-3.7C637.2,366.3,636,365.9,634.6,365.9L634.6,365.9z M642,376.6c-1.1-0.1-2.1,0-2.9,0.7
	c-0.8,0.7-1.4,1.5-1.5,2.6c-0.1,0.8,0,1.6,0.4,2.3l0.5,0.8c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1s1.2-1.6,1.1-3.2
	c-0.1-1.5-0.7-2.9-1.8-4.4l-0.5-0.8C643.5,377.2,642.8,376.7,642,376.6L642,376.6z M660.7,392.4l-6.7-12c-0.7-1.4-1-2.5-0.5-3.4
	c0.3-1,1.1-1.8,2.5-2.5l12.4-5.6"
          />
          <path
            fill="none"
            d="M660.7,392.4c5.1-6.1,7.6-13.2,7.6-21.3c0-0.7,0-1.4-0.1-2.1 M639.4,377.7c-0.8,0.5-1.2,1.4-1.4,2.3
	c-0.1,1,0,1.8,0.5,2.6c0.5,0.8,1.2,1.2,2.2,1.4c1,0.1,1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2c0.1-1,0-1.8-0.5-2.6
	c-0.5-0.8-1.2-1.2-2.2-1.4S640.2,377.2,639.4,377.7L639.4,377.7z M647.1,402.6l-9.2-10.2c-0.8-0.8-1.5-1.4-2.3-1.5h-0.8l-0.8,0.1
	c-0.8,0.1-1.5,0.7-2.3,1.5l-9.4,10.3 M690.2,310l11.1,44.2 M715.2,322.8l-4-15.7 M715.2,322.8l0.4,1.9 M715.6,324.7l-5.4,1.9
	 M710.2,326.7h8.8c2.8,0.3,4.8,1.4,6.2,3.6c0.8,0.8,1.1,4.7,0.8,11.8v0.1l-0.1,0.4c0,2.6-0.5,5.5-1.7,8.7l-1.9,5.4
	c-0.7,0.8-2.3,1.1-5.2,0.7 M739.3,363.1h1.1c1.7,0,2.6-0.5,3.2-1.8l4.3-7c1.1-2.1,1.7-5,1.7-8.8v-13.9c-0.1-1.2-0.8-2.3-1.9-3.3
	l-4-3.7c-1.5-1.4-3.3-1.9-5.1-1.8"
          />
          <path
            fill="none"
            d="M738.4,322.8l5.4,4.5c1.2,1.1,2.1,2.6,2.5,4.5V348c-0.3,2.3-1.1,4.7-2.6,7.3l-4.5,7.8"
          />
          <path
            fill="none"
            d="M715.6,324.7h17.7c1.2,0,2.5,0.5,3.9,1.8c1.5,1.4,2.3,3.2,2.6,5.4v16.8c-0.1,2.5-0.8,5.4-2.2,8.4l-2.5,5.9"
          />
          <path
            fill="none"
            d="M729.8,346.1c-0.4-1.5-0.5-3.3-0.5-5.4c0-1.9,0.1-3.6,0.5-5.1"
          />
          <path
            fill="none"
            d="M729.8,335.6l0.4-1.5c0.5-1.8,1.4-2.8,2.2-2.8c0.8,0,1.7,1,2.2,2.8c0.5,1.8,1,4,1,6.6c0,2.6-0.3,4.8-1,6.6
	c-0.5,1.8-1.4,2.8-2.2,2.8c-0.8,0-1.7-1-2.2-2.8l-0.4-1.4"
          />
          <path
            fill="none"
            d="M729.8,346.1l0.4-0.8c0.5-1.2,0.8-2.6,0.8-4.4c0-1.6-0.3-3.2-0.8-4.4l-0.4-0.8 M738.4,322.8h-23.2 M716.8,357.3
	h0.4 M717.3,357.3l3.6,2.8l0.7,1.7c0.6,1,1.5,1.4,3,1.4H735 M717.3,357.3h-0.4 M735.1,363.1h4.1 M710.2,326.7l7,30.7 M622.3,402.6
	c3.7,1.5,7.8,2.2,12.2,2.2c4.5,0,8.7-0.8,12.5-2.3 M702.1,357.3h14.7 M711.2,307.1H709 M153.9,309.3h-0.8l-0.6-0.1 M166.2,329.1
	v-1.2 M153.9,323v5 M153.5,320.1l0.4,0.1 M153.9,320.2v2.8 M153.9,327.9c0.6,0.3,1,0.7,1,1.2 M153.9,309.3v10.9 M153.9,327.9h12.2
	 M155,329.1h11.1 M188.9,325.7l-1.5,3.4 M176.2,358.3l9.5-17.9c2.9-5.8,4.4-9.5,4.4-11.3h-2.8 M187.4,329.1h-21.2 M215.6,331.9
	l-2.6,2.6c-1.5,1.6-3,3.3-4.4,5.1c-1,1.2-1.9,2.6-2.8,4c-6.1,9.5-9.2,20.2-9.2,32.3c0,12,3,22.7,9.2,32.2l2.8,4
	c1.8,2.3,3.7,4.4,5.8,6.6l3.6,3.3c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4c1.2-1.1,2.3-2.2,3.6-3.3
	c2.1-2.1,4-4.3,5.8-6.6c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.8-3.3-5.5-5.2-8.1
	c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5C241,315.4,227.1,320.9,215.6,331.9L215.6,331.9z"
          />
          <path
            fill="none"
            d="M316.4,352.1c-1.5-3.7-3.3-7.2-5.5-10.6c-0.8-1.2-1.8-2.6-2.8-3.9c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5
	c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6l-1.5,1.5c-1.6,1.8-3.2,3.6-4.5,5.4l-0.3,0.3c-3.2,4.1-5.6,8.5-7.6,13.2l-2.2,6.2"
          />
          <path
            fill="none"
            d="M193.6,373.2v2.6c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1
	c12.2,11.7,26.8,17.6,43.9,17.6s31.8-5.9,43.9-17.6l1.1-1.1c2.2-2.2,4.3-4.5,6.1-6.9c1-1.2,1.8-2.5,2.8-3.9
	c6.6-10,9.8-21.5,9.8-34.3c0-8.5-1.5-16.4-4.4-23.7 M196,357.5c-1.5,5-2.3,10.2-2.5,15.7 M248.6,343.2c0.6-1.9,1.5-3.4,2.6-4.1
	c-1,1-1.6,2.3-1.8,4v0.1l-1.5,16.1c0,0.8-0.1,1.5-0.4,1.9s-0.6,0.7-1,1c-0.3,0.1-0.4,0.3-0.7,0.3c-0.6,0.1-1.2,0-2.1-0.3l-15.7-3.6
	c-1.8-0.4-3.2-0.3-4.4,0.4c1.1-1,2.8-1.4,4.9-1.2 M228.6,357.8l8.9,1.8 M229.9,356l-1.2,1.8"
          />
          <path fill="none" d="M223.4,374v1.9c0,8.3,2.6,15.4,7.7,21.5" />
          <path fill="none" d="M230.3,377l-6.7-3h-0.1" />
          <path
            fill="none"
            d="M223.4,374c-1.5-1-2.5-1.9-2.9-3.2l-0.3-0.7l0.3,0.5c0.7,1,1.6,1.8,3,2.3h0.1l14.9,6.3c0.8,0.3,1.4,0.5,1.8,1
	c0.4,0.5,0.7,1.2,0.7,2.1c-0.1,0.5-0.4,1.2-0.8,1.9l-8.3,13.9c-1,1.5-1.2,2.9-1.1,4.3"
          />
          <path
            fill="none"
            d="M262.9,338.8l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11c-3.9,3.9-6.6,8.1-8.5,12.7c-0.7,1.8-1.2,3.6-1.6,5.5
	c-0.6,2.6-0.8,5.4-0.8,8.3s0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1 M230.7,402.3c-0.4-1.4-0.3-3,0.4-5"
          />
          <path
            fill="none"
            d="M216.9,375.8c0,6.2,1.2,11.8,3.6,17.1c1.9,4.1,4.7,8,8.1,11.4c4.1,4.1,8.7,7.2,13.8,9.1
	c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3
	c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8c-11.1,0-20.6,4-28.5,11.8C220.8,355.3,216.9,364.6,216.9,375.8L216.9,375.8z
	"
          />
          <path
            fill="none"
            d="M237.8,381l-6.5,1.6c-0.8-1-1.2-2.8-1.2-5.5 M154.1,361.2h3.3 M157.4,361.2c-0.4,1.6-0.4,3,0,4.4 M176.2,358.3
	l-0.6,1.1c-0.7,1-2.3,1.5-5.2,1.5l-13.1,0.3 M157.4,365.6c1,3.2,4.1,5.6,9.2,7.6l1.2,0.4 M167.8,373.6c7.3,1.9,15.1,2.1,23.5,0.1
	l2.2-0.5 M196,357.5l-19.8,0.8 M285.4,357.6c2.1-0.1,3.7,0.3,5,1.2c-1.2-0.7-2.8-0.8-4.4-0.4l-16.1,3.7l-1.6,0.1l-0.3-0.1
	c-0.5-0.1-1.1-0.5-1.4-1.1c-0.3-0.5-0.4-1.2-0.4-2.1l-1.5-16c-0.1-1.8-0.7-3.2-1.8-4.1 M262.9,338.8c1.2,0.8,2.1,2.3,2.6,4.3"
          />
          <path
            fill="none"
            d="M265.5,343v0.1l1,9.1 M248.6,343.2c-5.8,1.4-10.7,4.4-15.3,8.8c-1.2,1.2-2.3,2.6-3.4,4 M247.5,352.3l1.1-9.1
	 M270.3,354.2c1.6,1,3.2,2.2,4.7,3.7l1.4,1.5 M276.4,359.4l9.1-1.8 M285.4,357.6c-1-1.4-1.9-2.8-3-4 M283.2,397.1
	c5.1-6.1,7.6-13.2,7.6-21.3c0-0.7,0-1.4-0.1-2.1 M290.7,373.7c0.8-0.4,1.4-1,1.8-1.4c0.8-0.8,1.2-1.6,1.5-2.5 M273.8,373.4
	c1-1.1,1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1c-1.4-0.3-3-0.3-4.7,0.3l-1,0.3c-0.7,0.4-1.2,1-1.6,1.8c-0.5,1-0.5,1.9-0.3,3
	c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3C271.6,375.4,272.8,374.5,273.8,373.4L273.8,373.4z"
          />
          <path
            fill="none"
            d="M230.7,402.3c7.3,7.3,16.1,10.9,26.4,10.9s19.1-3.7,26.4-11c4.3-4.1,7.2-8.8,8.9-14c1.4-3.9,2.1-8,2.1-12.5
	c0-2.1-0.1-4-0.4-5.9"
          />
          <path
            fill="none"
            d="M294.1,369.9c-0.3-2.2-0.8-4.4-1.5-6.5L292,362c-1.8-4.7-4.7-8.9-8.5-12.7c-5.9-5.9-12.8-9.4-20.6-10.6
	 M294.1,369.9c-0.3,0.7-0.8,1.4-1.5,1.9c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1c-0.5,0.7-0.7,1.4-0.7,2.1
	c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1"
          />
          <path
            fill="none"
            d="M283.2,397.1l-6.7-12c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5l12.4-5.6 M237.6,359.5l4.4,1
	c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.6,1.4-1.5,1.6-3l0.6-4.7 M242.1,355.4l-2.9,2.5l-1.6,1.7 M244.6,369.2c-0.8,0.4-1.4,1.1-1.6,2.1
	c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8s1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9c0.3-1,0.3-1.8-0.3-2.6
	c-0.4-0.8-1.1-1.4-2.1-1.6C246.2,368.6,245.4,368.8,244.6,369.2L244.6,369.2z"
          />
          <path
            fill="none"
            d="M249.7,370.4c-0.4-0.8-1-1.4-1.8-1.8l-1-0.3c-1.6-0.5-3.3-0.7-4.7-0.3c-1.5,0.3-2.3,1-2.6,2.1
	c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3c0.8,0.1,1.7,0,2.5-0.4c1-0.5,1.6-1.2,1.9-2.3C250.2,372.3,250.2,371.4,249.7,370.4
	z M257.2,357.3c-1.1,0-2.1,0.7-2.8,1.9c-0.6,0.8-0.8,1.8-1,2.8l-0.1,2.8c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.7,1.1,2.8,1.1
	s2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2v-1c0-0.7,0-1.2-0.1-1.8c-0.1-1.1-0.5-1.9-1-2.8C259.3,358,258.3,357.3,257.2,357.3
	L257.2,357.3z"
          />
          <path
            fill="none"
            d="M259.9,362l-0.3-0.3c-0.7-0.7-1.5-1-2.5-1s-1.8,0.3-2.3,1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1c0.7-0.7,1-1.5,1-2.3C260.7,363.4,260.4,362.7,259.9,362L259.9,362z M266.5,352.3
	l0.5,4.5c0.1,1.5,0.7,2.5,1.5,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1 M267.1,368.9c-1,0.3-1.5,0.8-1.9,1.8c-0.4,0.8-0.5,1.6-0.3,2.6
	c0.3,1,0.8,1.5,1.8,2.1c0.8,0.4,1.8,0.5,2.6,0.1c1-0.3,1.5-0.8,2.1-1.6c0.4-0.8,0.5-1.8,0.3-2.6c-0.3-0.8-0.8-1.5-1.6-2.1
	C268.9,368.8,268,368.6,267.1,368.9L267.1,368.9z M257.1,370.4c-1.5,0-2.8,0.5-3.7,1.5c-1.1,1-1.6,2.3-1.6,3.7
	c0,1.5,0.6,2.8,1.6,3.9c1.1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5c1-1.1,1.5-2.3,1.5-3.9s-0.5-2.8-1.5-3.7
	C259.7,371,258.5,370.4,257.1,370.4L257.1,370.4z M260.5,384.7c-0.1,1,0,1.8,0.5,2.6s1.2,1.2,2.2,1.4c1,0.1,1.8-0.1,2.6-0.7
	c0.8-0.5,1.2-1.2,1.4-2.2s0-1.8-0.5-2.6s-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.6,0.5C261.1,382.9,260.7,383.8,260.5,384.7z"
          />
          <path
            fill="none"
            d="M260.4,387.1l0.5,0.8c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1c0.8-0.7,1.2-1.6,1.1-3.2s-0.7-2.9-1.8-4.4
	l-0.5-0.8c-0.5-0.5-1.4-1-2.2-1.1c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.7-1.4,1.5-1.5,2.6C259.9,385.4,260,386.2,260.4,387.1L260.4,387.1z
	 M253.3,387.7l0.6-0.8c0.4-0.8,0.6-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.6-2.6c-0.8-0.7-1.8-0.8-2.9-0.7c-0.8,0.1-1.5,0.5-2.2,1.1
	l-0.7,0.8c-1.1,1.4-1.6,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2c0.8,0.7,1.9,0.7,3.3,0.1C250.9,390.2,252.2,389.3,253.3,387.7
	L253.3,387.7z"
          />
          <path
            fill="none"
            d="M253.1,387.2c0.6-0.8,0.8-1.7,0.6-2.6c-0.1-1-0.6-1.6-1.4-2.2c-0.8-0.5-1.6-0.8-2.6-0.5c-1,0.1-1.6,0.5-2.2,1.4
	s-0.8,1.6-0.6,2.6c0.1,1,0.6,1.6,1.4,2.2c0.8,0.6,1.6,0.8,2.6,0.6C251.7,388.4,252.6,388,253.1,387.2L253.1,387.2z M269.6,407.1
	l-9.2-10.2c-0.8-0.8-1.5-1.4-2.3-1.5h-0.8l-0.8,0.1c-0.8,0.1-1.5,0.7-2.3,1.5l-9.4,10.3"
          />
          <path
            fill="none"
            d="M244.9,407.3c-1.6,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2c0.4-0.7,1-1.1,1.5-1.4
	c0.7-0.3,1.4-0.3,2.1,0c0.5,0.3,1.1,0.7,1.5,1.4l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9 M231.1,397.4l6.7-11.8
	c0.7-1.4,1-2.5,0.6-3.4c-0.1-0.4-0.3-0.8-0.6-1.1 M237.8,381c-0.4-0.5-1.1-1-1.9-1.4l-5.8-2.6 M266.5,352.3c1.4,0.5,2.6,1.2,3.9,1.9
	 M282.4,353.6l-1.5-1.6c-4.5-4.5-9.6-7.4-15.4-8.9 M282.4,353.6l-12.1,0.5 M247.5,352.3c-1.9,0.8-3.7,1.8-5.5,3.2"
          />
          <path
            fill="none"
            d="M244.9,407.3c3.7,1.5,7.8,2.2,12.2,2.2c4.5,0,8.7-0.8,12.5-2.3 M242.1,355.4l-12.2,0.5 M316.4,352.1l8.3-0.4
	 M329.3,350.7h-3.6c-0.7,0-1,0.3-1,1 M329.3,350.7H345 M391,343.2c-4.4,0-8.1-0.4-11.6-1 M379.4,342.2c-2.6-0.5-5.1-1.1-7.2-2.1
	 M391,343.2v7.6 M372.4,340.1c2.3,1.1,4.7,1.6,7.2,2.1 M391,350.7h-37.3 M372.4,340.1l-2.2-1.1c-5.5-3.2-10.6-8.7-15.3-16.5
	c-4.7-7.6-7.4-13.5-8.1-17.9 M353.7,350.7H345 M465.7,350.7v-7.6 M391,350.7h74.7 M465.7,343.2H391 M709,307.1l-1-2.3 M477.2,248.5
	h-8.5 M166.2,327.9c-0.1-24.3,0.7-48.4,2.3-72.2 M451.3,167.8l28.9,53.4 M531.1,350.7h-65.5 M153.9,304.1v5.2 M157.4,365.6
	l-14.7,4.3 M143.8,380.7l24.1-7.2 M153.9,295h-3.4 M154.1,361.2l-20.9,0.5 M463.6,213.2l-16.8-31.1c-1.1-2.1-3.4-3.2-7-3.3
	l-75.4-1.2c-1.1-0.1-1.9,0.1-2.3,0.7c-0.4,0.7-0.5,1.8-0.4,3.3L363,228c0,3.6,2.2,5.8,6.9,6.7c3.2,0.6,6.5,0.8,10,1l71.4,0.7
	 M146.5,200c0-0.6-0.1-0.8-0.6-1l-0.6-0.1l-12-1.1v7.3l2.2-0.3l7-0.7l2.9-0.3c0.7,0,1-0.3,1-1L146.5,200L146.5,200z M146,199
	l-0.4-0.1h-0.1"
          />
          <path
            fill="none"
            d="M146.5,202.4h2.3v-2.1h-2.3 M133.4,205.1v-7.7c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3v7.3
	l0.1,0.3l0.3,0.1l0.3,0.1L133.4,205.1L133.4,205.1z M135.6,204.9c1.2,0.6,2.3,2.1,3.6,4.3l5.9,11.3c0.8,1.4,1.2,4,1.5,7.8l2.9,34.8
	v-1.6h4.1l-2.8-33.8c-0.3-3.7-0.8-6.5-1.7-8.4l-6.6-15L135.6,204.9L135.6,204.9z"
          />
          <path
            fill="none"
            d="M93.1,221.2l4.5,108c0.6,3.9,2.9,6.3,6.9,7.4c4.8,0.7,10.5,0.8,17.1,0.4h0.6l0.1-4.3c1-4,3.2-6.1,6.6-6.1
	l18,0.8v-0.5l-4.5-108c-0.6-3.9-2.9-6.3-6.9-7.4c-4.8-0.7-10.5-0.8-17.1-0.4l-2.2,0.1c-6.6,0.1-12.1,0.8-16.9,1.9
	C95.5,214.7,93.4,217.3,93.1,221.2L93.1,221.2z M147.9,261.3v0.7l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1.2l0.3,0.5l0.1,0.1v26.8
	l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1l0.3,0.5l0.1,0.1v1.2l0.1,0.3l0.3,0.1c0.1,0,0.1,0,0.3-0.1l0.1-0.3v-1.2l0.1-0.1
	c0.1-0.1,0.3-0.3,0.3-0.5v-1c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-26.8l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5v-1.2c0-0.1-0.1-0.4-0.3-0.5
	l-0.1-0.1v-0.7l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1L148,261L147.9,261.3L147.9,261.3z"
          />
          <path
            fill="none"
            d="M149,263.9h0.6v-1H149 M149,263.9c0,0.1-0.1,0.4-0.3,0.5l-0.1,0.1v26.8l0.1,0.1c0.1,0.1,0.3,0.3,0.3,0.5h0.6
	v-28.1H149L149,263.9L149,263.9z"
          />
          <path
            fill="none"
            d="M149.5,263.9V295h6.1c0.6,0,1-0.1,1.4-0.4s0.7-0.8,0.8-1.4l0.6-29.6c0-0.4-0.1-0.8-0.3-1.1
	c-0.3-0.7-1-1.1-1.8-1.1h-6.9v2.5L149.5,263.9L149.5,263.9z"
          />
          <path
            fill="none"
            d="M149,293.1h0.6v-1H149 M153.9,301.1V295h-3.4v4.7 M152.1,308.6c0.1,0.3,0.3,0.4,0.6,0.5h1.2V304h-0.8
	c-0.7,0-1,0.3-1,1L152.1,308.6L152.1,308.6z"
          />
          <path
            fill="none"
            d="M151.2,306.2v2.5h1v-2.5 M151,318.7l2.5,1.4l0.4,0.1v-10.9h-0.8l-0.6-0.1l-1.5,1.1 M146.9,327.4l-18-0.8
	c-3.4,0-5.8,1.9-6.6,6.1l-0.1,4.3l-0.4,14.6c0,1.4,1,3.6,2.9,6.6l1.9,3.2l0.6,0.8c0.3,0.4,0.7,0.8,1.2,1.1c0.7,0.3,1.6,0.4,2.8,0.3
	l20.6-0.3c1.6-0.1,2.5-0.8,2.5-2.1l-20.9,0.5h-1.1h1.1c-1.8-0.1-3.2-0.7-4-1.6c-2.5-4.1-3.7-7.4-4-9.8v-14.6
	c-0.3-4.1,1.2-6.3,4.7-6.6h25c-0.1-0.5-0.4-1-1-1.2c-0.6-0.3-1.4-0.4-2.5-0.4L146.9,327.4L146.9,327.4L146.9,327.4z"
          />
          <path
            fill="none"
            d="M153.9,323v-2.8l-0.4-0.1h-2.6v2.3 M125.3,350.3c0.1,2.3,1.5,5.5,4,9.8c0.8,1.1,2.2,1.7,4,1.7l20.9-0.5l3.2-0.1
	l13.1-0.3c2.8,0,4.5-0.4,5.2-1.5l0.6-1.1l9.5-17.9c2.9-5.8,4.4-9.5,4.4-11.3H130c-3.4,0.3-4.9,2.6-4.7,6.6L125.3,350.3L125.3,350.3z
	 M134.4,348.1c0,0.3-0.1,0.5-0.6,0.5h-7c-0.3,0-0.6-0.1-0.6-0.5v-14c0-0.3,0.1-0.6,0.6-0.6h7c0.3,0,0.6,0.1,0.6,0.5V348.1z"
          />
          <path
            fill="none"
            d="M134.4,334.6h-6.5c-0.3,0-0.6,0.1-0.6,0.5v13.5h6.5c0.3,0,0.6-0.1,0.6-0.5V334.6z"
          />
          <path
            fill="none"
            d="M134.4,334.2c0-0.3-0.1-0.5-0.6-0.5h-7c-0.3,0-0.6,0.1-0.6,0.5v14c0,0.3,0.1,0.6,0.6,0.6h0.6v-13.5
	c0-0.3,0.1-0.5,0.6-0.5h6.5V334.2z M126.6,361.3v13.5c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5v-11.6
	c-0.6-0.3-0.8-0.5-1.2-1.1L126.6,361.3L126.6,361.3z M142.2,371.5c-0.1,1.1,0,2.3,0.1,4c0.1,1.5,0.3,2.9,0.6,3.9
	c0.3,1,0.4,1.5,0.7,1.5l0.1-0.1c0.1-0.3,0.3-0.7,0.3-1.6c0.1-1.1,0-2.3-0.1-4c-0.1-1.5-0.3-2.8-0.6-3.9c-0.3-1.1-0.6-1.6-0.7-1.5
	C142.4,369.9,142.2,370.4,142.2,371.5L142.2,371.5z"
          />
          <path
            fill="none"
            d="M144,375.2c0.1,1.5,0.1,2.9,0.1,4c-0.1,0.8-0.1,1.4-0.3,1.6l24.1-7.2l-1.2-0.4c-5.2-1.9-8.3-4.4-9.2-7.6
	l-14.7,4.1c0.3,0,0.4,0.6,0.7,1.5C143.6,372.5,143.9,373.7,144,375.2L144,375.2z M143.8,380.7l-0.3,0.1h0.1 M314.6,153.6H210.5
	c-10.9,0.3-21.3,1.2-31.2,3l-0.6,4l-0.8,5.5l89.4,0.6h77.2v-11.4C336.1,154.2,326.2,153.5,314.6,153.6L314.6,153.6z"
          />
          <path
            fill="none"
            d="M345.8,165.9l105.5,2.1l3.2-4.5c0.3-1.4,0.1-2.5-0.3-3.6c-6.7-1.4-15.1-2.2-25.2-2.5l-84.7-1.8V167h1.5
	L345.8,165.9L345.8,165.9z"
          />
          <path
            fill="none"
            d="M344.3,166.8h-77.2l-89.4-0.6c-3.7,28.3-6.9,58.1-9.4,89.4l178.3,1.5l-1-90.4L344.3,166.8L344.3,166.8z
	 M331.5,180.3v49.1c-0.1,3.6-1.4,6.1-4,7.7c-2.3,1.5-5.9,2.2-10.6,1.9H204.1c-4.3-0.3-7.4-1-9.4-2.3c-2.2-1.4-3-3.4-2.6-5.9
	l4.4-48.1c0.4-5.6,3-8.5,7.8-8.8h117.8C328.4,173.8,331.5,175.9,331.5,180.3L331.5,180.3z"
          />
          <path
            fill="none"
            d="M322.2,174H204.4c-4.8,0.3-7.4,3.2-7.8,8.8l-4.4,48.1c-0.4,2.5,0.4,4.5,2.6,5.9c1.9,1.2,5.1,2.1,9.4,2.3h112.8
	c4.7,0.3,8.1-0.3,10.6-1.9c2.5-1.6,3.9-4.1,4-7.7v-49.2C331.5,175.9,328.4,173.8,322.2,174z M330.2,180.3v49.1
	c-0.1,3-1.2,5.1-3.3,6.5c-2.2,1.4-5.4,1.9-9.8,1.6H204.3c-4-0.3-6.9-1-8.7-2.1c-1.6-1.1-2.2-2.5-1.9-4.4l4.4-48.1
	c0.3-4.7,2.5-7.2,6.5-7.4h117.6C327.3,175.3,329.9,177,330.2,180.3L330.2,180.3z"
          />
          <path
            fill="none"
            d="M322.2,175.5H204.6c-4,0.3-6.1,2.8-6.5,7.4l-4.4,48.1c-0.3,1.9,0.4,3.4,1.9,4.4c1.8,1.1,4.7,1.8,8.7,2.1h112.8
	c4.3,0.3,7.6-0.3,9.8-1.6c2.1-1.4,3.2-3.4,3.3-6.5v-49.1C329.9,177,327.3,175.3,322.2,175.5L322.2,175.5z M454.5,163.2l-3.2,4.5
	l29,53.2l10.5-5.4l-1-1.5l-0.7-1.2l-26.8-44.3C460.1,165.6,457.4,163.8,454.5,163.2L454.5,163.2z M346.8,300.9
	c-0.3,1.1-0.3,2.3,0,3.7V300.9L346.8,300.9z"
          />
          <path
            fill="none"
            d="M346.8,300.9v-43.7l-178.3-1.5c-1.6,23.8-2.5,47.9-2.3,72.2v1.2h21.3l1.4-3.4l-19.1,0.7
	c6.2-13.5,12.5-25.4,19.1-35.6c4.7-7.6,11.8-11.7,21.2-12.5c23.5-1.5,46.2-1.1,68.1,1c15.1,0.8,26.3,5.5,33.2,14l12.8,16.4
	c9.8,12.8,19.7,26.5,29.7,41.1h37.3v-7.6c-4.4,0-8.1-0.4-11.6-1c-2.5-0.3-4.8-1-7.2-2.1l-2.2-1.1c-5.5-3.2-10.6-8.7-15.3-16.5
	c-4.7-7.6-7.4-13.5-8.1-17.9C346.5,303.3,346.5,302,346.8,300.9L346.8,300.9z M181.4,259.8c3.3,0,6.2,1.2,8.5,3.6
	c2.3,2.3,3.6,5.2,3.6,8.5c0,3.3-1.2,6.2-3.6,8.5c-2.3,2.3-5.2,3.6-8.5,3.6c-3.3,0-6.2-1.2-8.5-3.6c-2.3-2.3-3.6-5.2-3.6-8.5
	c0-3.3,1.2-6.2,3.6-8.5C175.4,261,178.1,259.8,181.4,259.8L181.4,259.8z"
          />
          <path
            fill="none"
            d="M172.9,263.4c-2.3,2.3-3.6,5.2-3.6,8.5c0,3.3,1.2,6.2,3.6,8.5c2.3,2.3,5.2,3.6,8.5,3.6c3.3,0,6.2-1.2,8.5-3.6
	c2.3-2.3,3.6-5.2,3.6-8.5c0-3.3-1.2-6.2-3.6-8.5c-2.3-2.3-5.2-3.6-8.5-3.6C178.1,259.8,175.4,261,172.9,263.4L172.9,263.4z
	 M181.4,263.2c2.3,0,4.4,0.8,6.1,2.5c1.6,1.6,2.5,3.7,2.5,6.1c0,2.3-0.8,4.4-2.5,6.1c-1.6,1.6-3.7,2.5-6.1,2.5
	c-2.3,0-4.4-0.8-6.1-2.5s-2.5-3.7-2.5-6.1c0-2.3,0.8-4.4,2.5-6.1C177,264.1,179.1,263.2,181.4,263.2L181.4,263.2z"
          />
          <path
            fill="none"
            d="M175.4,265.9c-1.6,1.6-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1c1.6,1.6,3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5
	c1.6-1.7,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.1c-1.6-1.6-3.7-2.5-6.1-2.5C179.1,263.2,177,264.1,175.4,265.9L175.4,265.9z
	 M181.4,265.9c1.6,0,3,0.5,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.3s-0.6,3-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8c-1.6,0-3-0.5-4.3-1.8
	c-1.2-1.2-1.8-2.6-1.8-4.3c0-1.6,0.6-3,1.8-4.3C178.4,266.4,179.8,265.9,181.4,265.9L181.4,265.9z"
          />
          <path
            fill="none"
            d="M177.2,267.6c-1.2,1.2-1.8,2.6-1.8,4.3c0,1.6,0.6,3,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8c1.6,0,3-0.5,4.3-1.8
	c1.2-1.2,1.8-2.6,1.8-4.3c0-1.6-0.6-3-1.8-4.3c-1.2-1.2-2.6-1.8-4.3-1.8C179.8,265.9,178.4,266.4,177.2,267.6L177.2,267.6z
	 M185.7,271.9c0,1.1-0.4,2.2-1.2,3c-0.8,0.8-1.8,1.2-3,1.2c-1.1,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.4-2.2,1.2-3
	c0.8-0.8,1.8-1.2,3-1.2c1.1,0,2.2,0.4,3,1.2C185.3,269.7,185.7,270.8,185.7,271.9L185.7,271.9z"
          />
          <path
            fill="none"
            d="M184.5,268.9c-0.8-0.8-1.8-1.2-3-1.2c-1.1,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.1,0.4,2.2,1.2,3
	c0.8,0.8,1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.8,1.2-3C185.7,270.8,185.3,269.7,184.5,268.9L184.5,268.9z M184.5,271.9
	c0,0.8-0.3,1.5-0.8,2.1s-1.2,0.8-2.1,0.8s-1.5-0.3-2.1-0.8s-0.8-1.2-0.8-2.1c0-0.8,0.3-1.5,0.8-2.1s1.2-0.8,2.1-0.8
	c0.8,0,1.5,0.3,2.1,0.8C184.2,270.4,184.5,271.1,184.5,271.9L184.5,271.9z"
          />
          <path
            fill="none"
            d="M183.6,269.9c-0.6-0.5-1.2-0.8-2.1-0.8s-1.5,0.3-2.1,0.8c-0.6,0.5-0.8,1.2-0.8,2.1c0,0.8,0.3,1.5,0.8,2.1
	s1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8s0.8-1.2,0.8-2.1C184.5,271.1,184.2,270.4,183.6,269.9L183.6,269.9z M166.2,168.7l-0.3,1.2
	l-1.9,12.4v0.1l-0.6,3.4l-1,6.7l-0.3,1.8c-2.8,19.3-5.1,39.8-7.3,61.4h13.6c2.5-31.4,5.6-61.2,9.4-89.4l0.8-5.5l-8.7,3.7
	C168.1,165.2,166.9,166.5,166.2,168.7L166.2,168.7z M163.1,171.9l-0.4,10.5l-0.1,1.9c-0.3,1.1,0,1.6,0.7,1.6l0.6-3.4v-0.1l1.9-12.4
	l0.3-1.2C164.2,169,163.3,170.1,163.1,171.9L163.1,171.9z"
          />
          <path
            fill="none"
            d="M160.8,182.1l-0.4,3l-0.8,5.1l-0.3,1.8l3,0.6l1-6.7c-0.7,0-0.8-0.6-0.7-1.6l0.1-1.9L160.8,182.1L160.8,182.1z"
          />
          <path
            fill="none"
            d="M160.3,185.1c-0.4,0-0.8,0.3-1.2,0.8c-0.3,0.6-0.6,1.1-0.6,1.9c0,0.7,0.1,1.4,0.6,1.9l0.6,0.7l0.8-5.1
	L160.3,185.1z M273.9,296c-21.9-1.9-41-2.2-57.2-0.7c-6.3,0.5-11.3,3.3-15,8.1c-1.4,1.8-2.6,4-3.6,6.3l-7.2,16l-1.9,0.1l-1.4,3.4
	h2.8c0,1.8-1.5,5.6-4.4,11.3l-9.5,17.9l19.8-0.8l2.2-6.2c1.9-4.7,4.4-9.1,7.6-13.2l0.3-0.3c1.4-1.8,2.9-3.6,4.5-5.4l1.5-1.5
	c12.4-12.4,27.4-18.6,45-18.6s32.6,6.2,45,18.6l1.5,1.5c1.6,1.8,3.2,3.6,4.5,5.4c1,1.2,1.8,2.5,2.8,3.9c2.2,3.3,4,6.9,5.5,10.6
	l8.3-0.4c0-0.7,0.3-1,1-1h3.6l-5.2-8.1c-7-10.6-14.2-20.4-21.3-29.4l-4.8-5.9l-4.5-4.8c-0.7-0.5-1.4-1.2-2.1-1.6
	c-0.4-0.3-0.8-0.5-1.1-0.8C285.9,297.8,280.4,296.4,273.9,296L273.9,296z"
          />
          <path
            fill="none"
            d="M290.1,300c0.4,0.3,0.8,0.5,1.1,0.8c0.8,0.5,1.5,1.1,2.1,1.6l4.5,4.8l4.8,5.9c7.2,9.1,14.3,18.8,21.3,29.4
	l5.2,8.1h15.7l-38.5-51.3c-5.5-7.3-12.8-11.6-21.6-12.8c-20.1-2.5-42.4-3.3-66.6-2.8c-10.5-0.3-18.2,2.2-23.1,7.6l-1.7,2.2l-5,7.6
	c-4.1,6.7-8.3,15-12.2,24.6c4.1-9.6,8.3-17.9,12.2-24.6l5-7.6l1.6-2.2c5-5.4,12.7-7.8,23.1-7.6c24.2-0.7,46.5,0.3,66.6,2.8
	c8.8,1.1,16.1,5.4,21.6,12.8l38.5,51.3h8.7c-10-14.6-19.8-28.3-29.7-41.1L311,293.2c-6.9-8.5-18-13.1-33.2-14
	c-21.7-2.1-44.4-2.5-68.1-1c-9.4,0.8-16.4,5-21,12.5c-6.6,10.2-12.9,22.1-19.1,35.6l19.1-0.7l1.9-0.1l7.2-16
	c1.1-2.5,2.2-4.5,3.6-6.3c3.7-4.8,8.7-7.6,15-8.1c16.2-1.5,35.4-1.2,57.2,0.7C280.4,296.4,285.9,297.8,290.1,300L290.1,300z
	 M154.8,255.7l-0.6,5.6h2.2c1,0,1.5,0.3,1.8,1.1c1.1,0.1,1.6,1,1.9,2.5l-0.6,27c-0.1,1.9-1,2.8-2.6,2.8c-0.4,0.3-0.8,0.5-1.4,0.4
	h-1.8v33H166c-0.1-24.4,0.7-48.4,2.3-72.2h-13.6L154.8,255.7L154.8,255.7z"
          />
          <path
            fill="none"
            d="M158.5,263.5l-0.6,29.6c-0.1,0.5-0.4,1.1-0.8,1.4c1.6,0.1,2.6-0.8,2.6-2.8l0.6-27c-0.3-1.5-1-2.3-1.9-2.5
	C158.5,262.8,158.5,263.1,158.5,263.5L158.5,263.5z M662.1,255.4l0.5,3.6l0.1,0.8l0.1,0.3h1.1c0.1-0.1,0.1-0.3,0.1-0.3l-0.1-1.9v-1
	h2.9l0.3,1l0.4,1.9l0.1,0.3l0.3,0.1h0.4l0.3-0.1h0.1c0.1-0.1,0.1-0.3,0.1-0.3l-0.1-0.8l-0.5-3.6l-0.1-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
	h-5.2c-0.1,0-0.3,0-0.3,0.1C662.1,255.1,662.1,255.3,662.1,255.4L662.1,255.4z"
          />
          <path
            fill="none"
            d="M662.2,255.1c0.1-0.1,0.1-0.1,0.3-0.1h5.2c0.1,0,0.3,0,0.4,0.1l0.1,0.3l0.5,3.6h0.3c0.1,0,0.3,0,0.3,0.1
	c0.1,0.1,0.1,0.3,0.1,0.3v0.3c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.4l0.1,0.5h0.8c0.3,0,0.5,0.1,0.5,0.5l0.6,3.2
	c0,0.1,0,0.3-0.1,0.4l-0.3,0.4c-0.1,0.1-0.1,0.1-0.4,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5l0.3-0.1L668,265l0.1,2.1l19.9,1l-1.7-10.3
	c0-1.2-2.3-2.2-6.9-3l-18-2.2l-24.1-2.2l-103.2-10.2c1.4,5.8,2.1,12.7,2.2,20.5c-0.1-7.8-0.8-14.7-2.2-20.5l-2.1-0.1l-22-2.2
	l-2.5-0.3l-4-0.4c-0.7,3.2-0.1,10.6,1.7,22.3l160.4,7.7V265l-1-0.5v0.1c0,0.1,0,0.3-0.1,0.4l-0.3,0.4c-0.1,0.1-0.1,0.1-0.4,0.1h-1
	c-0.3,0-0.5-0.1-0.5-0.5l-0.5-3.2v-0.4l0.3-0.4l0.1-0.1l0.1-0.1h0.3l-0.1-0.5h-1l-0.3-0.1l-0.1-0.3v-0.3l0.1-0.3
	c0.1-0.1,0.3-0.1,0.3-0.1h1.1l-0.5-3.6C662.1,255.3,662.1,255.1,662.2,255.1L662.2,255.1z"
          />
          <path
            fill="none"
            d="M585.1,243.2h-0.8l-7.6-0.7l-42.2-3.4v1.1l103,10.2l24.1,2.2 M669.7,259.1c-0.1-0.1-0.3-0.1-0.3-0.1h-0.3
	l0.1,0.8c0,0.1,0,0.3-0.1,0.3H669h0.4c0.1,0,0.3,0,0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.3v-0.3C669.8,259.4,669.8,259.3,669.7,259.1
	L669.7,259.1z M669.9,260.8h-1.4l-0.1,0.1l-0.1,0.1v0.3l0.5,2.9v0.3c0,0.3,0.3,0.5,0.5,0.5h1.1l0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.4
	l-0.6-3.2C670.3,261,670.2,260.8,669.9,260.8L669.9,260.8z"
          />
          <path
            fill="none"
            d="M670.9,264.9l-0.3,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5v-0.3l-0.4,0.4l0.1,0.4c0,0.3,0.3,0.5,0.5,0.5h1.1
	c0.1,0,0.3,0,0.4-0.1L670.9,264.9L670.9,264.9z M686.4,257.7l1.5,10.3l1.5,10.3c0.8,0.3,1.7,0.5,2.5,1l-0.5-2.5
	c-1.5-6.1-2.3-10.5-2.6-12.9c-0.1-2.1,0-2.9,0.7-2.6c-0.4-0.7-0.8-1.4-1.4-1.9l-0.1-0.1C687.5,258.6,687,258,686.4,257.7
	L686.4,257.7z"
          />
          <path
            fill="none"
            d="M688.8,263.7c2.1,2.6,2.9,6.9,2.6,12.9l0.5,2.5c1.4,0.5,2.6,1.2,3.7,1.9l-5.5-18.7c-0.1-0.4-0.4-0.8-0.7-1.2
	C688.8,260.8,688.6,261.7,688.8,263.7L688.8,263.7z M670.5,271.4h-2.3l0.1,1.8h-2.2v-1.8h-1.9c-0.3,0-0.5,0.1-0.5,0.5v2.5l7.4,0.8
	v-3.3C671,271.5,670.9,271.4,670.5,271.4L670.5,271.4z"
          />
          <path
            fill="none"
            d="M671,271.8v1.6h0.7v1.6l5.9,0.8c4.5,0.4,8.5,1.2,12,2.3l-1.5-10.3l-19.9-1l0.1,4.3h2.3
	C670.9,271.4,671,271.5,671,271.8L671,271.8z"
          />
          <path fill="none" d="M671.7,275.1v-1.6H671v1.6" />
          <path
            fill="none"
            d="M671,275.1l-7.4-0.8c-22.6-2.2-46.6-2.5-72.5-0.8c-11.7,1-21,6.3-28.1,15.7c-13.1,16.4-26.4,36-40,58.9h26
	c11.6-17.7,23.1-33.7,34.7-48.1c4.4-5.9,10.3-8.9,17.3-9.1h49.4c10.9,0.1,18.7,1.1,23.8,2.8c4.5,1.5,9.2,5.5,14.2,12
	c0.5,1,1.4,1.4,2.5,1.2h9.5l2.6-0.3l5.1-1.7c1.1-0.4,1.5-1,1.1-1.9c-2.3-6.2-4.7-11.3-7.2-15.3c-0.8-1.4-1.8-2.6-2.9-3.7
	c-1-1-2.2-1.9-3.4-2.8c-1.1-0.7-2.3-1.4-3.7-1.9c-0.8-0.3-1.5-0.7-2.5-1c-3.4-1.2-7.4-1.9-12-2.3l-5.9-0.8L671,275.1L671,275.1z
	 M694,292.4c0.8,0.8,1.2,1.9,1.2,3c0,1.2-0.4,2.2-1.2,3c-0.8,0.8-1.9,1.2-3,1.2c-1.2,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.9-1.2-3
	c0-1.2,0.4-2.2,1.2-3c0.8-0.8,1.9-1.2,3-1.2C692.1,291.2,693.2,291.6,694,292.4L694,292.4z"
          />
          <path
            fill="none"
            d="M691,291.2c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.9,1.2,3,1.2
	c1.1,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.9,1.2-3c0-1.2-0.4-2.2-1.2-3C693.2,291.6,692.1,291.2,691,291.2L691,291.2z M690,262.4l5.5,18.7
	c1.2,0.8,2.5,1.8,3.4,2.8l-6.3-19.7c-1.4-2.6-2.9-4.4-4.7-5.1c0.5,0.5,1,1.2,1.4,1.9C689.6,261.6,689.9,262,690,262.4L690,262.4z"
          />
          <path
            fill="none"
            d="M688.8,263.7c0.3,2.5,1.1,6.9,2.6,12.9C691.7,270.5,690.8,266.3,688.8,263.7L688.8,263.7z M661.3,259.1
	l-0.1,0.4v0.3l0.1,0.3l0.3,0.1h1.5l-0.1-0.3l-0.1-0.8h-1.1C661.5,259,661.4,259,661.3,259.1L661.3,259.1z M667.3,257.9l-0.3-0.8
	h-2.9v0.8 M662.5,260.9l-0.1,0.3v0.3l0.6,3.2c0,0.3,0.3,0.5,0.5,0.5h1.1l0.3-0.1c0.1-0.1,0.1-0.3,0.1-0.4v-0.1l-0.5-3l-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1H662.5L662.5,260.9z"
          />
          <path
            fill="none"
            d="M662.8,260.8h1.1c0.1,0,0.3,0,0.4,0.1h4.3l0.1-0.1h0.5l-0.1-0.5l-0.3,0.1h-0.4l-0.3-0.1L668,260l-0.4-1.9h-3.2
	l0.1,1.9c0,0.1,0,0.3-0.1,0.3h-1.7L662.8,260.8L662.8,260.8z"
          />
          <path
            fill="none"
            d="M665,264.4l0.8,0.5v2.1l0.1,4.4l0.1,1.8h2.2l-0.1-1.8l-0.1-4.3V265l0.5-0.4l0.4-0.3l-0.5-3v-0.4h-4.3l0.3,0.4"
          />
          <path
            fill="none"
            d="M662,261.5v0.4l0.5,3.2c0,0.3,0.3,0.5,0.5,0.5h1.1c0.1,0,0.3,0,0.4-0.1l0.3-0.4l-0.3,0.1h-1.1
	c-0.3,0-0.5-0.1-0.5-0.5l-0.5-3.2v-0.3L662,261.5L662,261.5z M650.5,290.8h-49.4c-7.2,0.1-12.9,3.3-17.3,9.1
	c-11.6,14.4-23.1,30.4-34.7,48.1h-16l-2.1,2.8h14.7c0.7,0,0.8,0.3,0.4,1l-1.5,2.9l28.3-0.1c1.9-7.6,5.4-14.7,10.3-21l0.3-0.3
	c1.4-1.8,2.9-3.6,4.5-5.4l1.5-1.5c12.4-12.4,27.4-18.6,45-18.6c17.6,0,32.6,6.2,45,18.6l1.5,1.5c1.7,1.8,3.2,3.6,4.5,5.4
	c1,1.2,1.8,2.5,2.8,3.9c3.4,5.4,6.1,11.1,7.7,17.2h5.2L690.3,310l1.9-1h9.6l6.9-1.8h0.4l-1-2.3l-5.1,1.6l-2.6,0.3H691
	c-1.1,0.1-1.9-0.3-2.5-1.2c-5-6.5-9.6-10.3-14.2-12C669.2,291.9,661.4,291,650.5,290.8L650.5,290.8z M489.9,148.9
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1l0.1,0.4h0.1v62l0.7,1.2v-63.3
	c0.1-0.1,0.1-0.3,0.1-0.4v-1.1C490.1,149.2,490,149.1,489.9,148.9L489.9,148.9z M584.2,243.2l-0.8-4.3v-0.1l-5-0.6l-1.8,4.3"
          />
          <path
            fill="none"
            d="M585.1,239.6c0-0.3-0.1-0.6-0.5-0.6l-1.2-0.1l0.8,4.1l0.8,0.1v-3.6H585.1L585.1,239.6z M485.7,231.2
	c1.2,2.2,2.3,4.7,3.3,7.8l0.7,2.6c1.2,4.8,2.2,10.7,2.8,17.6l12.8,0.1c-1.8-11.7-2.5-19-1.6-22.3l-0.4-0.6l-12.5-20.8l-10.5,5.4
	L485.7,231.2L485.7,231.2z M484.6,223.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1s0.5-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4
	s0.4,0.6,0.4,1s-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4C485.2,223.8,484.9,223.6,484.6,223.4L484.6,223.4z M490.4,228.7
	c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1s-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4l-0.5-0.1l-0.4-0.3l-0.3-0.4l-0.1-0.4l0.1-0.4
	l0.3-0.6l0.4-0.3L490.4,228.7L490.4,228.7z M497.6,243.3c0,0.4-0.1,0.7-0.4,1s-0.5,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4
	c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4C497.4,242.8,497.6,243,497.6,243.3L497.6,243.3z
	 M500.2,234.4c0-0.3,0-0.4,0.1-0.6l0.5,0.1c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.3,0.3,0.6,0.3,0.8c0,0.3,0,0.6-0.1,0.6
	c-0.1,0.1-0.4,0-0.5-0.1l-0.6-0.7C500.3,234.9,500.2,234.6,500.2,234.4L500.2,234.4z M498.9,251.8c0,0.4-0.1,0.7-0.4,1
	c-0.3,0.3-0.5,0.4-1,0.4s-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1s0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4
	C498.8,251.1,498.9,251.6,498.9,251.8L498.9,251.8z"
          />
          <path
            fill="none"
            d="M485.6,223.8c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4
	c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1C484.9,223.6,485.2,223.8,485.6,223.8L485.6,223.8z M489.9,228.9
	l-0.4,0.3l-0.3,0.6v0.8l0.3,0.4l0.4,0.3l0.5,0.1c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1
	c-0.3-0.3-0.5-0.4-1-0.4L489.9,228.9L489.9,228.9z M489.2,230.5v-1L489,230 M497.2,242.5c-0.3-0.3-0.5-0.4-1-0.4
	c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1
	S497.4,242.8,497.2,242.5z M500.5,235.2l0.5,0.7c0.3,0.1,0.4,0.1,0.5,0.1c0.1-0.1,0.3-0.3,0.1-0.6c0-0.3-0.1-0.6-0.3-0.8
	c-0.1-0.3-0.4-0.6-0.5-0.7l-0.5-0.1c-0.1,0.1-0.3,0.3-0.1,0.6C500.2,234.6,500.3,234.9,500.5,235.2L500.5,235.2z M498.5,250.9
	c-0.3-0.3-0.5-0.4-1-0.4s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1
	C498.9,251.6,498.8,251.1,498.5,250.9L498.5,250.9z M507.7,237.4l0.1-0.6l-4.5-0.4l0.4,0.7"
          />
          <path
            fill="none"
            d="M508,236.4l-0.1,0.4l-0.1,0.6l2.5,0.3l-0.3-0.7l-0.1-0.4"
          />
          <path fill="none" d="M532.2,239.9l0.1-1L510,237l0.3,0.7" />
          <path
            fill="none"
            d="M532.6,236.4l-0.3,2.3l-0.1,1.1l2.1,0.1l0.1-1.1l0.3-2.8C533.6,235.9,532.9,236,532.6,236.4L532.6,236.4z
	 M534.2,238.2c-0.1,0.1-0.3,0.3-0.5,0.3s-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.3,0.5-0.3
	s0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.6C534.4,237.8,534.3,237.9,534.2,238.2L534.2,238.2z"
          />
          <path
            fill="none"
            d="M534.4,237.7c0-0.3-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6
	s0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3C534.3,237.9,534.4,237.8,534.4,237.7z M473.6,316.4v6.6
	c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1l-11-2.2v-2.1h11.6v-3.6H476v-2.1l11-2.2h-1.1l-10.6,1.2C474.2,315,473.6,315.5,473.6,316.4
	L473.6,316.4z M485.6,319c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3C485.2,318.6,485.5,318.7,485.6,319L485.6,319z
	 M479.8,319.6c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3C479.8,319.1,479.8,319.4,479.8,319.6L479.8,319.6z"
          />
          <path
            fill="none"
            d="M484.9,318.5c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C485.5,318.7,485.2,318.5,484.9,318.5L484.9,318.5z
	 M479.5,319c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8C479.8,319.4,479.8,319.1,479.5,319L479.5,319z"
          />
          <path
            fill="none"
            d="M488.6,313.7h-0.3l-1.4-0.1l-11,2.2v2.1h11.6v3.6H476v2.1l11,2.2l1.4-0.1h0.3l1.6-0.5v-10.6
	C489.7,314.1,489.2,313.9,488.6,313.7L488.6,313.7z M531.1,350.7H325.8c-0.7,0-1,0.3-1,1v9.2c0,0.7,0.3,1,1,1h214.2
	c0.7,0,1.1-0.3,1.5-1l3.4-6.3l1.5-2.9c0.3-0.7,0.1-1-0.4-1H531.1L531.1,350.7z M588.1,327.8c-1.7,1.8-3.2,3.6-4.5,5.4l-0.3,0.3
	c-4.8,6.5-8.3,13.5-10.3,21c-1.4,5.2-2.1,10.9-2.1,16.6c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3c1.8,2.3,3.9,4.7,6.1,6.9
	c2.1,2.1,4.3,4,6.5,5.8c11,8.7,23.9,12.9,38.5,12.9c14.6,0,27.5-4.3,38.5-12.9c2.2-1.8,4.4-3.6,6.5-5.8c2.2-2.2,4.3-4.5,6.1-6.9
	c1-1.2,1.8-2.5,2.8-3.9c6.6-10,9.8-21.5,9.8-34.3c0-5.9-0.7-11.6-2.2-16.9c-1.7-6.2-4.1-11.8-7.7-17.2c-0.8-1.2-1.8-2.6-2.8-3.9
	c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6L588.1,327.8L588.1,327.8z M586,334.9
	c1.4-1.8,2.8-3.4,4.4-5.1l2.6-2.6c11.6-11,25.5-16.5,41.5-16.5s30,5.5,41.5,16.5l2.6,2.6c1.5,1.6,3,3.4,4.4,5.1
	c1.9,2.6,3.7,5.4,5.2,8.1c4.4,8.4,6.7,17.7,6.7,28.1s-2.2,19.7-6.7,28.1c-1.5,2.8-3.3,5.5-5.2,8.1c-1.8,2.3-3.7,4.4-5.8,6.6
	c-3,3-6.2,5.6-9.6,8c-9.8,6.5-20.8,9.8-33.2,9.8s-23.5-3.3-33.2-9.8c-3.3-2.2-6.6-5-9.6-8c-2.1-2.1-4-4.3-5.8-6.6
	c-1-1.2-1.9-2.6-2.8-4c-6.1-9.5-9.1-20.2-9.1-32.2c0-12.1,3-22.8,9.1-32.3C584.1,337.5,585.1,336.3,586,334.9L586,334.9z"
          />
          <path
            fill="none"
            d="M583.3,338.9c-6.1,9.5-9.1,20.2-9.1,32.3c0,12,3,22.7,9.1,32.2c0.8,1.4,1.8,2.6,2.8,4c1.8,2.3,3.7,4.4,5.8,6.6
	c3,3,6.2,5.6,9.6,8c9.6,6.5,20.8,9.8,33.2,9.8s23.5-3.3,33.2-9.8c3.3-2.2,6.6-5,9.6-8c2.1-2.1,4-4.3,5.8-6.6
	c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.8-3.3-5.5-5.2-8.1c-1.4-1.8-2.8-3.4-4.4-5.1
	l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6c-1.5,1.6-3,3.3-4.4,5.1
	C585.1,336.3,584.1,337.5,583.3,338.9L583.3,338.9z M598,388.2c-2.5-5.2-3.6-10.9-3.6-17.1c0-11.1,4-20.6,11.8-28.5
	c7.8-7.8,17.3-11.8,28.5-11.8s20.6,4,28.5,11.8c7.8,7.8,11.8,17.3,11.8,28.5c0,7.2-1.7,13.5-4.8,19.3c-1.8,3.3-4.1,6.3-7,9.2
	c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6c-5.2,0-10.2-0.8-14.6-2.6c-5.1-1.9-9.6-5-13.8-9.1
	C602.7,396.1,599.9,392.3,598,388.2L598,388.2z"
          />
          <path
            fill="none"
            d="M606.1,399.6c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6
	c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8
	s-20.6,4-28.5,11.8C598,350.5,594,360,594,371.1c0,6.2,1.2,11.8,3.6,17.1C599.9,392.3,602.7,396.1,606.1,399.6L606.1,399.6z
	 M599.6,357.3c1.8-4.7,4.7-8.9,8.5-12.7c7.3-7.3,16.1-11,26.4-11l5.8,0.4c7.8,1.1,14.7,4.7,20.6,10.6c3.9,3.9,6.6,8.1,8.4,12.7
	l0.6,1.4c0.7,2.1,1.2,4.3,1.5,6.5v0.1c0.3,1.9,0.4,3.9,0.4,5.9c0,4.4-0.7,8.5-2.1,12.5c-1.8,5.1-4.8,9.8-8.9,14
	c-7.3,7.3-16.1,11-26.4,11c-10.3,0-19.1-3.6-26.4-10.9l-0.1-0.1c-5.2-5.2-8.7-11.3-10-18.2c-0.5-2.6-0.8-5.4-0.8-8.3
	s0.3-5.6,0.8-8.3C598.4,360.9,599,359.1,599.6,357.3L599.6,357.3z"
          />
          <path
            fill="none"
            d="M627.2,365.7c0.5,1,0.5,1.9,0.3,3c-0.3,1-1,1.8-1.9,2.3c-0.1,0-0.1,0.1-0.3,0.1h4c0-1.5,0.5-2.8,1.7-3.7
	c1-1.1,2.3-1.5,3.7-1.5c1.5,0,2.8,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7h4c-1-0.5-1.7-1.2-1.9-2.3c-0.3-1.1-0.3-2.1,0.3-3
	c0.4-0.8,1-1.4,1.7-1.8l1-0.3c1.7-0.6,3.3-0.7,4.7-0.3c1.5,0.3,2.3,1,2.6,2.1c0.3,1.1,0,2.2-1,3.3c-0.8,1.1-2.1,1.8-3.7,2.3h13.5
	l7.3-3c0.7-0.3,1.2-0.5,1.8-1c0.7-0.6,1.2-1.2,1.5-1.9c-0.3-2.2-0.8-4.4-1.5-6.5l-0.5-1.4c-1.8-4.7-4.7-8.9-8.4-12.7
	c-5.9-5.9-12.8-9.4-20.6-10.6c1.2,0.8,2.1,2.3,2.6,4.3c5.8,1.4,10.9,4.4,15.4,8.9c1.8,1.8,3.3,3.6,4.5,5.5c2.1-0.1,3.7,0.3,5,1.2
	c-1.2-0.7-2.8-0.8-4.4-0.4l-16.1,3.7l-1.7,0.1l-0.3-0.1c-0.5-0.1-1-0.5-1.4-1.1c-0.3-0.5-0.4-1.2-0.4-2.1l-1.5-16
	c-0.1-1.8-0.7-3.2-1.8-4.1l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11c-3.9,3.9-6.6,8.1-8.4,12.7c-0.7,1.8-1.2,3.6-1.7,5.5
	c-0.5,2.6-0.8,5.4-0.8,8.3h3.7v-1.9c-1.5-1-2.5-1.9-2.9-3.2l-0.3-0.7l0.3,0.5c0.7,1,1.7,1.8,3,2.3h0.1l6.6,2.8h14.3
	c-1.8-0.5-3-1.4-4-2.5c-1-1.1-1.2-2.2-1-3.3c0.3-1.1,1.2-1.8,2.6-2.1c1.5-0.3,3-0.3,4.7,0.3l1,0.3
	C626.2,364.4,626.7,364.9,627.2,365.7L627.2,365.7z M626.9,338.4l-1.5,16.1c0,0.8-0.1,1.5-0.4,1.9c-0.3,0.4-0.5,0.7-1,1l-0.7,0.3
	c-0.5,0.1-1.2,0-2.1-0.3l-15.7-3.6c-1.8-0.4-3.2-0.3-4.4,0.4c1.1-1,2.8-1.4,5-1.2c1.2-2.1,2.9-4,4.7-5.8c4.4-4.5,9.5-7.4,15.3-8.8
	c0.5-1.9,1.5-3.4,2.6-4.1c-1,1-1.7,2.3-1.8,4L626.9,338.4L626.9,338.4z M630.9,357.3c0.1-1.1,0.5-1.9,1-2.8c0.8-1.2,1.7-1.9,2.8-1.9
	c1.1,0,2.1,0.7,2.8,1.9c0.5,0.8,0.8,1.8,1,2.8c0.1,0.5,0.1,1.1,0.1,1.8v1c-0.1,0.8-0.5,1.5-1.1,2.2c-0.8,0.8-1.8,1.1-2.8,1.1
	c-1.1,0-2.1-0.4-2.8-1.1c-0.5-0.7-1-1.4-1.1-2.2L630.9,357.3L630.9,357.3z"
          />
          <path
            fill="none"
            d="M668.1,369c0,0.7,0.1,1.4,0.1,2.1h3.7c0-2.1-0.1-4-0.4-5.9c-0.3,0.8-0.8,1.7-1.5,2.5L668.1,369L668.1,369z"
          />
          <path
            fill="none"
            d="M639.8,371.1c0,1.5-0.5,2.8-1.5,3.9c-1.1,1-2.3,1.5-3.7,1.5c-1.5,0-2.8-0.5-3.7-1.5c-1.1-1.1-1.7-2.3-1.7-3.9
	h-4c-0.7,0.3-1.5,0.4-2.2,0.3l-1-0.3h-14.3l8.3,3.6c0.8,0.3,1.4,0.5,1.8,1c0.4,0.5,0.7,1.2,0.7,2.1c-0.1,0.5-0.4,1.2-0.8,1.9
	l-8.3,13.9c-1,1.5-1.2,2.9-1.1,4.3c7.3,7.2,16.1,10.9,26.4,10.9c10.3,0,19.1-3.7,26.4-11c4.1-4.1,7.2-8.8,8.9-14
	c1.4-3.9,2.1-8,2.1-12.5h-3.7c0,8.1-2.5,15.3-7.6,21.3c0.8,1.9,1,3.7,0.4,5.1c0.3-1.4-0.1-2.9-1-4.4l-8.3-13.8
	c-0.4-0.5-0.8-1.2-0.8-1.9c-0.1-0.7,0.1-1.4,0.7-2.1c0.4-0.4,1-0.8,1.8-1l7.6-3.3h-13.5c-0.1,0-0.3,0.1-0.3,0.1l-1,0.3
	c-0.8,0.1-1.7,0-2.5-0.4L639.8,371.1L639.8,371.1z M644.2,377.7l0.5,0.8c1.1,1.5,1.7,2.9,1.8,4.4c0.1,1.5-0.3,2.5-1.1,3.2
	s-1.9,0.7-3.3,0.1c-1.4-0.6-2.6-1.5-3.6-3l-0.5-0.8c-0.4-0.8-0.5-1.5-0.4-2.3c0.1-1.1,0.7-1.9,1.5-2.6s1.9-0.8,2.9-0.7
	C642.8,376.7,643.5,377.2,644.2,377.7L644.2,377.7z M637.3,390.1l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9
	c-3.9,1.5-8,2.3-12.5,2.3c-4.4,0-8.4-0.7-12.2-2.2c-1.7,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2
	c0.4-0.7,1-1.1,1.5-1.4c0.7-0.3,1.4-0.3,2.2,0C636.4,389,636.9,389.4,637.3,390.1L637.3,390.1z M631.7,379.9c0.1,0.8,0,1.6-0.4,2.5
	l-0.5,0.8c-1.1,1.5-2.2,2.5-3.6,3c-1.4,0.5-2.5,0.5-3.3-0.1c-1-0.7-1.2-1.6-1.1-3.2c0.1-1.5,0.7-3,1.8-4.4l0.7-0.8
	c0.5-0.5,1.4-1,2.2-1.1c1.1-0.1,2.1,0,2.9,0.7C631,377.8,631.6,378.8,631.7,379.9L631.7,379.9z"
          />
          <path
            fill="none"
            d="M597.2,371.1c0,2.9,0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1c-0.4-1.4-0.3-3,0.4-5
	c-5.1-6.1-7.7-13.2-7.7-21.5h-3.7L597.2,371.1L597.2,371.1z M610.8,347.3c-1.8,1.8-3.4,3.7-4.7,5.8l8.9,1.9l1.7-1.6
	c2.5-2.5,5.4-4.4,8.4-5.6l1-9.1C620.3,339.9,615.2,342.9,610.8,347.3L610.8,347.3z"
          />
          <path
            fill="none"
            d="M626.9,338.2c0.1-1.7,0.7-3,1.8-4c-1.2,0.8-2.1,2.2-2.6,4.1l-1,9.1l-0.6,4.7c-0.1,1.5-0.7,2.6-1.7,3
	c-0.8,0.7-1.9,0.8-3.4,0.5l-4.4-1l-8.9-1.9c-2.2-0.1-3.7,0.3-5,1.2c1.2-0.7,2.6-0.8,4.4-0.4l15.7,3.6c0.7,0.3,1.4,0.3,2.1,0.3
	l0.7-0.3c0.4-0.3,0.8-0.5,1-1c0.3-0.5,0.4-1.2,0.4-1.9L626.9,338.2L626.9,338.2z"
          />
          <path
            fill="none"
            d="M616.7,353.2l-1.7,1.6l4.4,1c1.5,0.3,2.6,0.1,3.4-0.6c0.8-0.5,1.4-1.5,1.7-3l0.5-4.7
	C621.9,348.8,619.2,350.7,616.7,353.2L616.7,353.2z M617.1,365.5c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3
	c0.8,0.1,1.7,0,2.5-0.4c1-0.6,1.7-1.2,1.9-2.3c0.3-1.1,0.3-2.1-0.3-3c-0.4-0.8-1-1.4-1.8-1.8l-1-0.3c-1.7-0.5-3.3-0.7-4.7-0.3
	C618.3,363.7,617.4,364.4,617.1,365.5L617.1,365.5z M624.7,364.2c1,0.3,1.5,0.8,2.1,1.7c0.4,0.8,0.5,1.8,0.3,2.6
	c-0.3,1-0.8,1.5-1.8,1.9c-0.8,0.4-1.7,0.6-2.6,0.3s-1.7-0.8-2.1-1.8c-0.4-0.8-0.5-1.6-0.1-2.6c0.3-1,0.8-1.6,1.7-2.1
	C622.9,364.1,623.7,363.9,624.7,364.2L624.7,364.2z"
          />
          <path
            fill="none"
            d="M622.1,364.5c-0.8,0.4-1.4,1.1-1.7,2.1c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8
	c1,0.3,1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-2.1-1.6
	C623.7,363.9,622.9,364.1,622.1,364.5L622.1,364.5z M625,377.7l-0.7,0.8c-1.1,1.4-1.7,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2
	c0.8,0.7,1.9,0.7,3.3,0.1c1.4-0.6,2.6-1.5,3.6-3l0.5-0.8c0.4-0.8,0.5-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.7-2.6
	c-0.8-0.7-1.8-0.8-2.9-0.7C626.3,376.7,625.6,377,625,377.7L625,377.7z M625.8,383.3c-0.8-0.5-1.2-1.4-1.4-2.2c-0.1-1,0-1.8,0.5-2.6
	c0.5-0.8,1.4-1.2,2.2-1.4c1-0.1,1.8,0,2.6,0.5c0.8,0.5,1.2,1.2,1.4,2.2s0,1.8-0.5,2.6c-0.5,0.8-1.2,1.2-2.2,1.4
	C627.3,384.2,626.5,383.9,625.8,383.3L625.8,383.3z"
          />
          <path
            fill="none"
            d="M628.3,383.9c1-0.1,1.7-0.5,2.2-1.4s0.8-1.6,0.6-2.6c-0.1-1-0.5-1.6-1.4-2.2c-0.8-0.6-1.7-0.8-2.6-0.6
	c-1,0.1-1.7,0.5-2.2,1.4c-0.6,0.8-0.8,1.7-0.6,2.6c0.1,1,0.5,1.6,1.4,2.2C626.5,383.9,627.3,384.2,628.3,383.9L628.3,383.9z
	 M617.8,404.5c1.5,0.1,3-0.5,4.7-1.8l9.4-10.3c0.8-0.8,1.5-1.4,2.3-1.5l0.8-0.1h0.8c0.8,0.1,1.5,0.7,2.3,1.5l9.2,10.2
	c1.7,1.4,3.3,1.9,4.7,1.9c-1.4-0.1-2.8-1-3.9-2.3l-10.6-12.1c-0.4-0.7-1-1.1-1.5-1.4c-0.8-0.3-1.5-0.3-2.2,0
	c-0.6,0.3-1.1,0.7-1.5,1.4l-10.7,12.2C620.4,403.6,619.2,404.3,617.8,404.5L617.8,404.5z"
          />
          <path
            fill="none"
            d="M634.7,390.9l-0.8,0.1c-0.8,0.1-1.5,0.7-2.3,1.5l-9.4,10.3c3.7,1.5,7.8,2.2,12.2,2.2c4.5,0,8.7-0.8,12.5-2.3
	l-9.2-10.2c-0.8-0.8-1.5-1.4-2.3-1.5L634.7,390.9L634.7,390.9z M630.9,357.3l-0.1,2.8c0.1,0.8,0.5,1.5,1.1,2.2
	c0.8,0.8,1.7,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2v-1c0-0.7,0-1.2-0.1-1.8c-0.1-1.1-0.5-1.9-1-2.8
	c-0.8-1.2-1.7-1.9-2.8-1.9c-1.1,0-2.1,0.7-2.8,1.9C631.4,355.4,631,356.4,630.9,357.3L630.9,357.3z M632.2,357.1
	c0.7-0.7,1.5-1,2.3-1c1,0,1.8,0.3,2.5,1l0.3,0.3c0.5,0.5,0.8,1.4,0.8,2.2c0,1-0.4,1.8-1,2.3c-0.7,0.7-1.5,1.1-2.5,1.1
	s-1.8-0.3-2.3-1.1c-0.7-0.7-1-1.5-1-2.3s0.3-1.5,0.8-2.2L632.2,357.1L632.2,357.1L632.2,357.1z"
          />
          <path
            fill="none"
            d="M632,357.3c-0.5,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1
	c0.7-0.7,1-1.5,1-2.3s-0.3-1.5-0.8-2.2l-0.3-0.3c-0.7-0.7-1.5-1-2.5-1s-1.8,0.3-2.3,1L632,357.3L632,357.3L632,357.3z M643.8,364.1
	c-0.7,0.4-1.2,1-1.7,1.8c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3c1.7-0.5,3-1.4,4-2.5
	c1-1.1,1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1s-3-0.3-4.7,0.3L643.8,364.1L643.8,364.1z M647.2,364.5c0.8,0.4,1.4,1.1,1.7,2.1
	c0.3,0.8,0.3,1.8-0.3,2.6c-0.4,0.8-1.1,1.4-2.1,1.6c-0.8,0.3-1.8,0.3-2.6-0.1c-0.8-0.4-1.5-1.1-1.8-2.1c-0.3-1-0.3-1.8,0.3-2.6
	c0.4-0.8,1.1-1.5,1.9-1.8C645.4,363.9,646.4,364.1,647.2,364.5L647.2,364.5z"
          />
          <path
            fill="none"
            d="M644.6,364.4c-1,0.3-1.5,0.8-1.9,1.8c-0.4,0.8-0.5,1.7-0.3,2.6c0.3,1,0.8,1.5,1.8,2.1c0.8,0.4,1.8,0.5,2.6,0.1
	c1-0.3,1.5-0.8,2.1-1.7c0.4-0.8,0.5-1.8,0.3-2.6c-0.3-0.8-0.8-1.5-1.7-2.1C646.4,364.1,645.5,363.9,644.6,364.4L644.6,364.4z
	 M630.7,367.4c-1.1,1-1.7,2.3-1.7,3.7c0,1.5,0.5,2.8,1.7,3.9c1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5c1-1.1,1.5-2.3,1.5-3.9
	s-0.5-2.8-1.5-3.7c-1.1-1.1-2.3-1.5-3.7-1.5C633.1,365.9,631.8,366.3,630.7,367.4z M633.9,370.7l0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5
	v0.3c-0.1,0.3-0.3,0.4-0.5,0.4h-0.3l-0.4-0.1l-0.4-0.1l-0.1-0.1h0.6l0.1,0.1h0.1c0.1,0,0.3-0.1,0.3-0.1v-0.1h-1.2v-0.3l0.1-0.3
	c0.1-0.3,0.3-0.3,0.5-0.4H633.9L633.9,370.7z M634.6,371c0.1-0.3,0.3-0.3,0.5-0.4h0.4l0.4,0.1c0.1,0.1,0.3,0.1,0.3,0.3v0.1l0.1,0.3
	l-0.1,0.4c-0.1,0.3-0.3,0.3-0.5,0.4h-0.3l-0.5-0.1c-0.1,0-0.3-0.1-0.3-0.1l-0.1-0.1h0.6l0.1,0.1h0.1l0.1-0.1v-0.3h-1.2v-0.3
	L634.6,371L634.6,371z M631.4,370.5l0.3,0.1l0.1,0.1v-0.3h0.5v2.1h-0.5v-0.5l-0.1,0.1H631l-0.1-0.1l-0.1-0.3v-0.5l0.1-0.3l0.3-0.3
	L631.4,370.5L631.4,370.5z M638.6,371.1v0.6l-0.4,0.4l-0.7,0.1h-0.3l-0.5-0.3l-0.1-0.3v-1.4h0.7v1.5l0.1,0.3h0.3l0.3-0.1v-0.4h0.7
	L638.6,371.1L638.6,371.1z"
          />
          <path
            fill="none"
            d="M633.6,370.5h-0.4c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.1c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1h-0.5
	l0.1,0.1l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5l-0.3-0.3L633.6,370.5L633.6,370.5L633.6,370.5z
	 M633.8,371.1h-0.5V371l0.3-0.1h0.1l0.1,0.1L633.8,371.1L633.8,371.1z"
          />
          <path
            fill="none"
            d="M633.6,371l-0.1-0.1h-0.1l-0.3,0.3h0.7 M634.4,371.2v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1h-0.5l0.1,0.1
	c0.1,0.1,0.1,0.1,0.3,0.1l0.5,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3V371c-0.1-0.1-0.1-0.3-0.3-0.3l-0.7-0.1h-0.4
	c-0.3,0-0.4,0.1-0.5,0.4L634.4,371.2L634.4,371.2z M635.4,370.8c0.1,0,0.1,0,0.1,0.1v0.1H635V371l0.3-0.1L635.4,370.8L635.4,370.8z"
          />
          <path
            fill="none"
            d="M635.3,370.8L635,371v0.1h0.5V371c0-0.1-0.1-0.1-0.1-0.1L635.3,370.8L635.3,370.8z M630.9,370.5
	c-0.1,0.1-0.3,0.1-0.3,0.3l-0.1,0.3v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.5v-1.9h-0.5v0.1l-0.1-0.1L630.9,370.5L630.9,370.5z
	 M631.8,371.5v0.1c0,0.1-0.1,0.1-0.1,0.1h-0.3c-0.1,0-0.3,0-0.3-0.1H631v-0.4l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1L631.8,371.5
	L631.8,371.5z"
          />
          <path
            fill="none"
            d="M631.8,371.4l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1h-0.1l-0.1,0.1v0.4h0.1c0,0.1,0.1,0.1,0.3,0.1h0.1
	c0.1,0,0.1,0,0.1-0.1L631.8,371.4L631.8,371.4z M637.9,371.1v0.4l-0.3,0.1h-0.3l-0.1-0.3v-1.5h-0.7v1.4l0.1,0.3l0.5,0.3h0.3l0.7-0.1
	c0.3-0.1,0.4-0.3,0.4-0.4v-0.5h-0.7L637.9,371.1L637.9,371.1z M639,377.4c-0.8,0.7-1.4,1.5-1.5,2.6c-0.1,0.8,0,1.6,0.4,2.3l0.5,0.8
	c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1c0.8-0.7,1.2-1.6,1.1-3.2s-0.7-2.9-1.8-4.4l-0.5-0.8c-0.5-0.5-1.4-1-2.2-1.1
	C640.9,376.5,639.9,376.7,639,377.4L639,377.4z M639.4,377.7c0.8-0.5,1.7-0.8,2.6-0.5c1,0.1,1.7,0.5,2.2,1.4
	c0.5,0.8,0.8,1.7,0.5,2.6c-0.1,1-0.5,1.6-1.4,2.2c-0.8,0.5-1.7,0.8-2.6,0.7c-1-0.1-1.7-0.7-2.2-1.4c-0.5-0.8-0.8-1.6-0.5-2.6
	C638.2,379.1,638.6,378.3,639.4,377.7L639.4,377.7z"
          />
          <path
            fill="none"
            d="M638,380c-0.1,1,0,1.8,0.5,2.6c0.5,0.8,1.2,1.2,2.2,1.4c1,0.1,1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2
	c0.1-1,0-1.8-0.5-2.6c-0.5-0.8-1.2-1.2-2.2-1.4s-1.8,0-2.6,0.5S638.2,379.1,638,380L638,380z M601,369.3l6.7,3l5.8,2.6
	c0.8,0.4,1.5,0.8,1.9,1.4c0.3,0.3,0.5,0.7,0.5,1.1c0.4,1,0.3,2.1-0.5,3.4l-6.7,11.8c-0.8,1.9-1,3.6-0.4,5c-0.1-1.4,0.1-2.8,1.1-4.3
	l8.3-13.9c0.4-0.5,0.8-1.2,0.8-1.9c0-0.8-0.1-1.5-0.7-2.1c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3H601c-1.4-0.5-2.3-1.4-3-2.3l-0.3-0.5
	l0.3,0.7C598.5,367.2,599.5,368.3,601,369.3L601,369.3z"
          />
          <path
            fill="none"
            d="M600.9,369.3v1.9c0,8.3,2.6,15.4,7.7,21.5l6.7-11.8c0.7-1.4,1-2.5,0.6-3.4c-0.1-0.4-0.3-0.8-0.5-1.1l-6.5,1.7
	c-0.8-1-1.2-2.8-1.2-5.5L600.9,369.3L600.9,369.3z"
          />
          <path
            fill="none"
            d="M613.5,375l-5.8-2.6c0,2.8,0.3,4.5,1.2,5.5l6.5-1.6C614.9,375.8,614.4,375.4,613.5,375L613.5,375z M658.4,347.3
	c-4.5-4.5-9.6-7.4-15.4-8.9l1,9.1c3.2,1.2,5.9,3.2,8.5,5.6l1.4,1.5l8.9-1.9h0.1C661.7,351,660.2,349.1,658.4,347.3L658.4,347.3z"
          />
          <path
            fill="none"
            d="M662.9,352.9l-8.9,1.9l-4.4,1c-1.5,0.3-2.6,0.1-3.4-0.5c-0.8-0.5-1.4-1.5-1.5-3l-0.5-4.5l-1-9.1
	c-0.5-2.1-1.4-3.4-2.6-4.3c1,1,1.7,2.3,1.8,4.1l1.5,16c0,0.8,0.1,1.5,0.4,2.1c0.4,0.5,0.8,1,1.4,1.1l0.3,0.1l1.7-0.1l16.1-3.7
	c1.7-0.4,3.2-0.3,4.4,0.4C666.6,353.2,665,352.8,662.9,352.9L662.9,352.9z"
          />
          <path
            fill="none"
            d="M644.5,352.1c0.1,1.5,0.7,2.5,1.5,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1l-1.4-1.5c-2.6-2.6-5.4-4.4-8.5-5.6
	L644.5,352.1L644.5,352.1z M671.6,365.2c-0.3,0.7-0.8,1.4-1.5,1.9c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1
	c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1l-6.7-12
	c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5l12.4-5.6h0.1l1.7-1.2C670.8,367,671.3,366.1,671.6,365.2L671.6,365.2z"
          />
          <path
            fill="none"
            d="M655.8,374.7c-1.4,0.5-2.2,1.4-2.5,2.5c-0.4,1-0.3,2.1,0.5,3.4l6.7,12c5.1-6.1,7.6-13.2,7.6-21.3
	c0-0.7,0-1.4-0.1-2.1L655.8,374.7L655.8,374.7z M701.7,309.1h-9.6l-1.9,1H703l8.3-2.9h-2.6"
          />
          <path
            fill="none"
            d="M701.3,354.2l0.8,3.2h15.1l-7-30.7l5.4-1.9l-0.4-1.9l-4-15.7L703,310h-12.8"
          />
          <path
            fill="none"
            d="M699.5,357.3h2.6l-0.8-3.3h-2.6C698.7,356.5,699,357.6,699.5,357.3L699.5,357.3z M715.6,324.7h17.7
	c1.2,0,2.5,0.5,3.9,1.8c1.5,1.4,2.3,3.2,2.6,5.4v16.8c-0.1,2.5-0.8,5.4-2.2,8.4l-2.5,5.9h4.1l4.5-7.8c1.5-2.5,2.3-5,2.6-7.3V332
	c-0.4-1.9-1.2-3.4-2.5-4.5l-5.4-4.5h-23.2L715.6,324.7L715.6,324.7z"
          />
          <path
            fill="none"
            d="M710.2,326.7h8.8c2.8,0.3,4.8,1.4,6.2,3.6c0.8,0.8,1.1,4.7,0.8,11.8v0.1l-0.1,0.4c0,2.6-0.5,5.5-1.7,8.7
	l-1.9,5.4c-0.7,0.8-2.3,1.1-5.2,0.7l3.6,2.8l0.7,1.7c0.5,1,1.5,1.4,3,1.4h10.5l2.5-5.9c1.4-3,2.2-5.9,2.2-8.4V332
	c-0.1-2.2-1.1-4-2.6-5.4c-1.2-1.2-2.6-1.9-3.9-1.8h-17.7L710.2,326.7L710.2,326.7z M729.8,335.6l0.4-1.5c0.5-1.8,1.4-2.8,2.2-2.8
	c0.8,0,1.7,1,2.2,2.8c0.5,1.8,1,4,1,6.6c0,2.6-0.3,4.8-1,6.6c-0.5,1.8-1.4,2.8-2.2,2.8c-0.8,0-1.7-1-2.2-2.8l-0.4-1.4
	c-0.4-1.5-0.5-3.3-0.5-5.4C729.2,338.8,729.5,337.1,729.8,335.6L729.8,335.6z"
          />
          <path
            fill="none"
            d="M729.2,340.7c0,1.9,0.1,3.7,0.5,5.4l0.4-0.8c0.5-1.2,0.8-2.6,0.8-4.4c0-1.6-0.3-3.2-0.8-4.4l-0.4-0.8
	C729.5,337.1,729.2,338.8,729.2,340.7L729.2,340.7z"
          />
          <path
            fill="none"
            d="M734.6,334.1c-0.5-1.8-1.4-2.8-2.2-2.8c-0.8,0-1.7,1-2.2,2.8l-0.4,1.5l0.4,0.8c0.5,1.2,0.8,2.6,0.8,4.4
	c0,1.6-0.3,3.2-0.8,4.4l-0.4,0.8l0.4,1.4c0.5,1.8,1.4,2.8,2.2,2.8c0.8,0,1.7-1,2.2-2.8c0.6-1.8,1-4,1-6.6
	C735.5,338.1,735.3,335.9,734.6,334.1L734.6,334.1z M710.2,326.7l7,30.7c2.8,0.4,4.5,0.1,5.2-0.7l1.9-5.4c1.1-3.2,1.7-6.1,1.7-8.7
	l0.1-0.4v-0.1c0.3-7.2,0-11-0.8-11.8c-1.5-2.2-3.6-3.3-6.2-3.6L710.2,326.7L710.2,326.7z M738.4,322.8l5.4,4.5
	c1.2,1.1,2.1,2.6,2.5,4.5V348c-0.3,2.3-1.1,4.7-2.6,7.3l-4.5,7.8h1.1c1.7,0,2.6-0.5,3.2-1.8l4.3-7c1.1-2.1,1.7-5,1.7-8.8v-13.9
	c-0.1-1.2-0.8-2.3-1.9-3.3l-4-3.7C742,323.2,740.2,322.7,738.4,322.8L738.4,322.8z M717.3,357.3h-0.4H717.3z M166.2,327.9h-12.2
	c0.6,0.3,1,0.7,1,1.2H166L166.2,327.9z M206.1,337.7l-0.3,0.3c-3.2,4.1-5.6,8.5-7.6,13.2l-2.2,6.2c-1.5,5-2.3,10.2-2.5,15.7v2.6
	c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1c12.2,11.7,26.8,17.6,43.9,17.6s31.8-5.9,43.9-17.6l1.1-1.1
	c2.2-2.2,4.3-4.5,6.1-6.9c1-1.2,1.8-2.5,2.8-3.9c6.6-10,9.8-21.5,9.8-34.3c0-8.5-1.5-16.4-4.4-23.7c-1.5-3.7-3.3-7.2-5.5-10.6
	c-0.8-1.2-1.8-2.6-2.8-3.9c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6c-17.6,0-32.6,6.2-45,18.6l-1.5,1.5
	C209,334.1,207.4,335.9,206.1,337.7L206.1,337.7z M215.6,331.9c11.6-11,25.4-16.5,41.5-16.5c16.1,0,30,5.5,41.5,16.5l2.6,2.6
	c1.5,1.6,3,3.4,4.4,5.1c1.9,2.6,3.7,5.4,5.2,8.1c4.4,8.4,6.7,17.7,6.7,28.1c0,10.3-2.2,19.7-6.7,28.1c-1.5,2.8-3.3,5.5-5.2,8.1
	c-1.8,2.3-3.7,4.4-5.8,6.6c-1.1,1.1-2.3,2.2-3.6,3.3c-11.1,9.6-24.2,14.4-39.2,14.4c-15.1,0-28.2-4.8-39.2-14.4l-3.6-3.3
	c-2.1-2.1-4-4.3-5.8-6.6l-2.8-4c-6.1-9.5-9.2-20.2-9.2-32.2c0-12.1,3-22.8,9.2-32.3c0.8-1.4,1.8-2.6,2.8-4c1.4-1.8,2.8-3.4,4.4-5.1
	L215.6,331.9L215.6,331.9z"
          />
          <path
            fill="none"
            d="M212.9,334.5c-1.5,1.6-3,3.3-4.4,5.1c-1,1.2-1.9,2.6-2.8,4c-6.1,9.5-9.2,20.2-9.2,32.3c0,12,3,22.7,9.2,32.2
	l2.8,4c1.8,2.3,3.7,4.4,5.8,6.6l3.6,3.3c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4c1.2-1.1,2.3-2.2,3.6-3.3
	c2.1-2.1,4-4.3,5.8-6.6c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.9-3.3-5.5-5.2-8.1
	c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5c-16.1,0-30,5.5-41.5,16.5L212.9,334.5L212.9,334.5z M216.9,375.8
	c0-11.1,4-20.6,11.8-28.5c7.8-7.8,17.3-11.8,28.5-11.8c11.1,0,20.6,4,28.5,11.8c7.8,7.8,11.8,17.3,11.8,28.5
	c0,7.2-1.6,13.5-4.8,19.3c-1.8,3.3-4.1,6.3-7,9.2c-4.1,4.1-8.7,7.2-13.8,9.1c-4.5,1.8-9.4,2.6-14.6,2.6s-10.2-0.8-14.6-2.6
	c-5.1-1.9-9.6-5-13.8-9.1c-3.4-3.4-6.2-7.3-8.1-11.4C218,387.6,216.9,382,216.9,375.8L216.9,375.8z"
          />
          <path
            fill="none"
            d="M220.5,392.8c1.9,4.1,4.7,8,8.1,11.4c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6
	c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3c0-11.1-4-20.6-11.8-28.5
	c-7.8-7.8-17.3-11.8-28.5-11.8c-11.1,0-20.6,4-28.5,11.8c-7.8,7.8-11.8,17.3-11.8,28.5C216.9,382,218,387.6,220.5,392.8L220.5,392.8
	z M220.5,367.5c0.4-1.9,1-3.7,1.6-5.5c1.8-4.7,4.7-8.9,8.5-12.7c7.3-7.3,16.1-11,26.4-11l5.8,0.4c7.8,1.1,14.7,4.7,20.6,10.6
	c3.9,3.9,6.6,8.1,8.5,12.7l0.5,1.4c0.7,2.1,1.2,4.3,1.5,6.5v0.1c0.3,1.9,0.4,3.9,0.4,5.9c0,4.4-0.7,8.5-2.1,12.5
	c-1.8,5.1-4.8,9.8-8.9,14c-7.3,7.3-16.1,11-26.4,11s-19.1-3.6-26.4-10.9l-0.1-0.1c-5.2-5.2-8.7-11.3-10-18.2
	c-0.6-2.6-0.8-5.4-0.8-8.3C219.7,372.9,220,370.1,220.5,367.5L220.5,367.5z"
          />
          <path
            fill="none"
            d="M249.7,370.4c0.6,1,0.6,1.9,0.3,3c-0.3,1-1,1.8-1.9,2.3c-0.1,0-0.1,0.1-0.3,0.1h4c0-1.5,0.6-2.8,1.6-3.7
	c1.1-1.1,2.3-1.5,3.7-1.5c1.5,0,2.8,0.5,3.7,1.5s1.5,2.3,1.5,3.7h4c-1-0.5-1.7-1.2-1.9-2.3c-0.3-1.1-0.3-2.1,0.3-3
	c0.4-0.8,1-1.4,1.7-1.8l1-0.3c1.6-0.5,3.3-0.7,4.7-0.3c1.5,0.3,2.3,1,2.6,2.1c0.3,1.1,0,2.2-1,3.3c-0.8,1.1-2.1,1.8-3.7,2.3h13.5
	l7.3-3c0.7-0.3,1.2-0.5,1.8-1c0.7-0.5,1.2-1.2,1.5-1.9c-0.3-2.2-0.8-4.4-1.5-6.5l-0.5-1.4c-1.8-4.7-4.7-8.9-8.5-12.7
	c-5.9-5.9-12.8-9.4-20.6-10.6c1.2,0.8,2.1,2.3,2.6,4.3c5.8,1.4,10.9,4.4,15.4,8.9l1.5,1.7c1.1,1.2,2.2,2.6,3,4
	c2.1-0.1,3.7,0.3,5,1.2c-1.2-0.7-2.8-0.8-4.4-0.4l-16.1,3.7l-1.6,0.1l-0.3-0.1c-0.5-0.1-1.1-0.5-1.4-1.1c-0.3-0.5-0.4-1.2-0.4-2.1
	l-1.5-16c-0.1-1.8-0.7-3.2-1.8-4.1l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11c-3.9,3.9-6.6,8.1-8.5,12.7c-0.7,1.8-1.2,3.6-1.6,5.5
	c-0.6,2.6-0.8,5.4-0.8,8.3h3.7v-1.9c-1.5-1-2.5-1.9-2.9-3.2l-0.3-0.7l0.3,0.6c0.7,1,1.6,1.8,3,2.3h0.1l6.6,2.8h14.3
	c-1.8-0.5-3-1.4-4-2.5c-1-1.1-1.2-2.2-1-3.3s1.2-1.8,2.6-2.1c1.5-0.3,3-0.3,4.7,0.3l1,0.3C248.7,369,249.3,369.6,249.7,370.4
	L249.7,370.4z M249.4,343l-1.5,16.1c0,0.8-0.1,1.5-0.4,1.9c-0.3,0.4-0.6,0.7-1,1c-0.3,0.1-0.4,0.3-0.7,0.3c-0.6,0.1-1.2,0-2.1-0.3
	l-15.7-3.6c-1.8-0.4-3.2-0.3-4.4,0.4c1.1-1,2.8-1.4,4.9-1.2l1.2-1.8c1-1.4,2.2-2.6,3.4-4c4.4-4.5,9.5-7.4,15.3-8.8
	c0.6-1.9,1.5-3.4,2.6-4.1c-1,1-1.6,2.3-1.8,4L249.4,343L249.4,343z M253.4,362c0.1-1.1,0.6-1.9,1-2.8c0.8-1.2,1.6-1.9,2.8-1.9
	s2.1,0.7,2.9,1.9c0.5,0.8,0.8,1.8,1,2.8c0.1,0.5,0.1,1.1,0.1,1.8v1c-0.1,0.8-0.5,1.5-1.1,2.2c-0.8,0.8-1.8,1.1-2.9,1.1
	s-2.1-0.4-2.8-1.1c-0.6-0.7-1-1.4-1.1-2.2L253.4,362L253.4,362z"
          />
          <path
            fill="none"
            d="M290.7,373.7c0,0.7,0.1,1.4,0.1,2.1h3.7c0-2.1-0.1-4-0.4-5.9c-0.3,0.8-0.8,1.6-1.5,2.5
	C292,372.9,291.5,373.3,290.7,373.7L290.7,373.7z"
          />
          <path
            fill="none"
            d="M262.3,375.8c0,1.5-0.5,2.8-1.5,3.9c-1.1,1-2.3,1.5-3.7,1.5c-1.5,0-2.8-0.5-3.7-1.5c-1.1-1.1-1.6-2.3-1.6-3.9
	h-4c-0.7,0.3-1.5,0.4-2.2,0.3l-1-0.3h-14.3l8.3,3.6c0.8,0.3,1.4,0.5,1.8,1c0.4,0.5,0.7,1.2,0.7,2.1c-0.1,0.5-0.4,1.2-0.8,1.9
	l-8.3,13.9c-1,1.5-1.2,2.9-1.1,4.3c7.3,7.2,16.1,10.9,26.4,10.9s19.1-3.7,26.4-11c4.3-4.1,7.2-8.8,8.9-14c1.4-3.9,2.1-8,2.1-12.5
	h-3.7c0,8.1-2.5,15.3-7.6,21.3c0.8,1.9,1,3.7,0.4,5.1c0.3-1.4-0.1-2.9-1-4.4l-8.3-13.8c-0.4-0.5-0.8-1.2-0.8-1.9
	c-0.1-0.7,0.1-1.4,0.7-2.1c0.4-0.4,1-0.8,1.8-1l7.6-3.3h-13.5c-0.1,0-0.3,0.1-0.3,0.1l-1,0.3c-0.8,0.1-1.6,0-2.5-0.4L262.3,375.8
	L262.3,375.8z M266.7,382.4l0.5,0.8c1.1,1.5,1.6,2.9,1.8,4.4c0.1,1.5-0.3,2.5-1.1,3.2c-0.8,0.7-1.9,0.7-3.3,0.1
	c-1.4-0.5-2.6-1.5-3.6-3l-0.5-0.8c-0.4-0.8-0.5-1.5-0.4-2.3c0.1-1.1,0.7-1.9,1.5-2.6c0.8-0.7,1.9-0.8,2.9-0.7
	C265.4,381.4,266,381.8,266.7,382.4L266.7,382.4z M259.9,394.8l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9
	c-3.9,1.5-8,2.3-12.5,2.3c-4.4,0-8.5-0.7-12.2-2.2c-1.6,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2
	c0.4-0.7,1-1.1,1.5-1.4c0.7-0.3,1.4-0.3,2.2,0C258.9,393.7,259.4,394.1,259.9,394.8L259.9,394.8z M254.2,384.6
	c0.1,0.8,0,1.7-0.4,2.5l-0.6,0.8c-1.1,1.5-2.2,2.5-3.6,3c-1.4,0.5-2.5,0.5-3.3-0.1c-1-0.7-1.2-1.6-1.1-3.2c0.1-1.5,0.7-3,1.8-4.4
	l0.7-0.8c0.6-0.5,1.4-1,2.2-1.1c1.1-0.1,2.1,0,2.9,0.7C253.5,382.5,254.1,383.5,254.2,384.6z"
          />
          <path
            fill="none"
            d="M219.7,375.8c0,2.9,0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1c-0.4-1.4-0.3-3,0.4-5
	c-5.1-6.1-7.7-13.2-7.7-21.5h-3.7V375.8L219.7,375.8z M228.1,358.6l15.7,3.6c0.7,0.3,1.4,0.3,2.1,0.3c0.3-0.1,0.6-0.1,0.7-0.3
	c0.4-0.3,0.8-0.5,1-1c0.3-0.5,0.4-1.2,0.4-1.9l1.5-16.1V343c0.1-1.6,0.7-3,1.8-4c-1.2,0.8-2.1,2.2-2.6,4.1l-1,9.1l-0.6,4.7
	c-0.1,1.5-0.7,2.6-1.6,3c-0.8,0.7-1.9,0.8-3.4,0.5l-4.4-1l-8.9-1.9c-2.2-0.1-3.7,0.3-5,1.2C224.9,358.3,226.3,358.2,228.1,358.6
	L228.1,358.6z"
          />
          <path
            fill="none"
            d="M228.6,357.8l8.9,1.9l1.6-1.6c1-1,1.9-1.8,2.9-2.5l-12.2,0.5L228.6,357.8L228.6,357.8z M233.3,352
	c-1.2,1.2-2.3,2.6-3.4,4l12.2-0.5c1.6-1.2,3.6-2.3,5.5-3.2l1-9.1C242.8,344.6,237.7,347.6,233.3,352L233.3,352z"
          />
          <path
            fill="none"
            d="M242,360.5c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.6,1.4-1.5,1.6-3l0.6-4.7c-1.9,0.8-3.7,1.8-5.5,3.2l-2.9,2.5l-1.6,1.7
	L242,360.5L242,360.5z M274.7,370.1c-0.3-1.1-1.2-1.8-2.6-2.1c-1.4-0.3-3-0.3-4.7,0.3l-1,0.3c-0.7,0.4-1.2,1-1.6,1.8
	c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3c1.6-0.5,3-1.4,4-2.5C274.7,372.3,275,371.2,274.7,370.1
	L274.7,370.1z M267.1,368.9c1-0.3,1.8-0.3,2.6,0.3c0.8,0.4,1.4,1.1,1.6,2.1c0.3,0.8,0.3,1.8-0.3,2.6c-0.4,0.8-1.1,1.4-2.1,1.6
	c-0.8,0.3-1.8,0.3-2.6-0.1c-0.8-0.4-1.5-1.1-1.8-2.1c-0.3-1-0.3-1.8,0.3-2.6C265.5,369.9,266.2,369.3,267.1,368.9L267.1,368.9z"
          />
          <path
            fill="none"
            d="M265.1,370.7c-0.4,0.8-0.5,1.6-0.3,2.6s0.8,1.5,1.8,2.1c0.8,0.4,1.8,0.5,2.6,0.1c1-0.3,1.5-0.8,2.1-1.6
	c0.4-0.8,0.5-1.8,0.3-2.6c-0.3-0.8-0.8-1.5-1.6-2.1c-0.8-0.4-1.8-0.5-2.6-0.3C266.2,369.3,265.5,369.9,265.1,370.7L265.1,370.7z
	 M247.9,368.6l-1-0.3c-1.6-0.5-3.3-0.7-4.7-0.3c-1.5,0.3-2.3,1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3
	c0.8,0.1,1.6,0,2.5-0.4c1-0.5,1.6-1.2,1.9-2.3c0.3-1.1,0.3-2.1-0.3-3C249.3,369.6,248.7,369,247.9,368.6L247.9,368.6z M244.6,369.2
	c0.8-0.4,1.8-0.5,2.6-0.1c1,0.3,1.5,0.8,2.1,1.6c0.4,0.8,0.6,1.8,0.3,2.6c-0.3,1-0.8,1.5-1.8,1.9c-0.8,0.4-1.6,0.5-2.6,0.3
	c-1-0.3-1.6-0.8-2.1-1.8c-0.4-0.8-0.6-1.6-0.1-2.6C243.1,370.3,243.6,369.6,244.6,369.2L244.6,369.2z"
          />
          <path
            fill="none"
            d="M242.8,371.1c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8s1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9
	c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-2.1-1.6c-0.8-0.3-1.8-0.3-2.6,0.1S243.1,370.3,242.8,371.1L242.8,371.1z M254.4,359.3
	c-0.6,0.8-0.8,1.8-1,2.8l-0.1,2.8c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.6,1.1,2.8,1.1s2.1-0.4,2.9-1.1c0.5-0.7,1-1.4,1.1-2.2v-1
	c0-0.7,0-1.2-0.1-1.8c-0.1-1.1-0.5-1.9-1-2.8c-0.8-1.2-1.6-1.9-2.9-1.9C256.1,357.3,255.2,358,254.4,359.3L254.4,359.3z M259.9,362
	c0.5,0.5,0.8,1.4,0.8,2.2c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.5,1.1-2.5,1.1s-1.8-0.3-2.3-1.1c-0.7-0.7-1.1-1.5-1.1-2.3
	c0-0.8,0.3-1.5,0.8-2.2l0.3-0.3c0.7-0.7,1.5-1,2.3-1c1,0,1.8,0.3,2.5,1L259.9,362L259.9,362z"
          />
          <path
            fill="none"
            d="M259.6,361.7c-0.7-0.7-1.5-1-2.5-1s-1.8,0.3-2.3,1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1c0.7-0.7,1-1.5,1-2.3c0-0.8-0.3-1.5-0.8-2.2L259.6,361.7L259.6,361.7z M253.3,372.1
	c-1.1,1-1.6,2.3-1.6,3.7c0,1.5,0.6,2.8,1.6,3.9c1.1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5c1.1-1.1,1.5-2.3,1.5-3.9
	c0-1.5-0.5-2.8-1.5-3.7c-1.1-1.1-2.3-1.5-3.7-1.5C255.6,370.4,254.4,371,253.3,372.1L253.3,372.1z M259.4,374.4v1.5l0.1,0.3h0.3
	l0.3-0.1v-0.4h0.5v0.5l-0.4,0.4l-0.7,0.1h-0.3c-0.3-0.1-0.4-0.1-0.5-0.3l-0.1-0.3v-1.4h0.8L259.4,374.4L259.4,374.4z M257.5,375.1
	h0.3l0.4,0.1l0.3,0.3v0.1l0.1,0.3l-0.1,0.4c-0.1,0.3-0.3,0.3-0.5,0.4h-0.3l-0.5-0.1l-0.3-0.1l-0.1-0.1h0.5l0.1,0.1h0.1l0.1-0.1V376
	h-1.2v-0.3l0.1-0.3c0.1-0.3,0.3-0.3,0.5-0.4L257.5,375.1L257.5,375.1z M255.9,375.1c0.1,0,0.3,0,0.4,0.1l0.3,0.3
	c0.1,0.1,0.1,0.3,0.1,0.6v0.3c-0.1,0.3-0.3,0.4-0.5,0.4h-0.3l-0.4-0.1l-0.4-0.1l-0.1-0.1h0.6l0.1,0.1h0.1c0.1,0,0.3-0.1,0.3-0.1
	v-0.1h-1.2v-0.3l0.1-0.3c0.1-0.3,0.3-0.3,0.6-0.4L255.9,375.1L255.9,375.1z M253,375.4l0.3-0.3h0.6l0.3,0.1l0.1,0.1v-0.1h0.6v2.1
	h-0.6v-0.5l-0.1,0.1h-0.7l-0.1-0.1l-0.1-0.3v-0.5L253,375.4L253,375.4z"
          />
          <path
            fill="none"
            d="M258.8,374.4v1.4l0.1,0.3c0.1,0.1,0.3,0.3,0.5,0.3h0.3l0.7-0.1c0.3-0.1,0.4-0.3,0.4-0.4v-0.5h-0.5v0.4l-0.3,0.1
	h-0.3l-0.1-0.3V374h-0.8L258.8,374.4L258.8,374.4z"
          />
          <path
            fill="none"
            d="M257.4,375.1c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1H257l0.1,0.1l0.3,0.1l0.5,0.1
	h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3v-0.1l-0.3-0.3l-0.4-0.1L257.4,375.1L257.4,375.1z M257.8,375.4c0.1,0,0.1,0,0.1,0.1
	v0.1h-0.5v-0.1l0.3-0.1L257.8,375.4L257.8,375.4z"
          />
          <path
            fill="none"
            d="M257.7,375.4l-0.3,0.1v0.1h0.5v-0.1c0-0.1-0.1-0.1-0.1-0.1L257.7,375.4L257.7,375.4z M255.5,375.1
	c-0.3,0-0.4,0.1-0.6,0.4l-0.1,0.3v0.3h1.2v0.1c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1h-0.6l0.1,0.1l0.4,0.1l0.4,0.1h0.3
	c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5l-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1L255.5,375.1L255.5,375.1z M255.9,375.4
	l0.1,0.1v0.1h-0.6v-0.1l0.3-0.1L255.9,375.4L255.9,375.4z"
          />
          <path
            fill="none"
            d="M255.7,375.4l-0.3,0.3h0.5v-0.1l-0.1-0.1 M252.8,375.6v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.6V375h-0.6v0.1
	l-0.1-0.1l-0.3-0.1H253c-0.1,0.1-0.3,0.1-0.3,0.3L252.8,375.6L252.8,375.6z M253.4,376.2v-0.5l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1
	l0.1,0.3v0.3l-0.1,0.1h-0.1L253.4,376.2L253.4,376.2z"
          />
          <path
            fill="none"
            d="M253.5,376.2l0.3,0.1h0.1l0.1-0.1v-0.3l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1h-0.1l-0.1,0.1v0.1L253.5,376.2
	L253.5,376.2z M261,387.9c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1c0.8-0.7,1.2-1.6,1.1-3.2s-0.7-2.9-1.8-4.4l-0.5-0.8
	c-0.5-0.5-1.4-1-2.2-1.1c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.7-1.4,1.5-1.5,2.6c-0.1,0.8,0,1.6,0.4,2.3L261,387.9L261,387.9z
	 M260.5,384.7c0.1-1,0.5-1.6,1.4-2.3c0.8-0.5,1.6-0.8,2.6-0.5c1,0.1,1.6,0.5,2.2,1.4s0.8,1.6,0.5,2.6c-0.1,1-0.5,1.6-1.4,2.2
	c-0.8,0.6-1.6,0.8-2.6,0.7c-1-0.1-1.6-0.7-2.2-1.4C260.5,386.5,260.4,385.7,260.5,384.7L260.5,384.7z"
          />
          <path
            fill="none"
            d="M261.1,387.2c0.5,0.8,1.2,1.2,2.2,1.4s1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2s0-1.8-0.5-2.6
	c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.6,0.6c-0.8,0.5-1.2,1.4-1.4,2.3C260.4,385.7,260.5,386.5,261.1,387.2L261.1,387.2z
	 M253.8,386.9c0.4-0.8,0.6-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.6-2.6c-0.8-0.7-1.8-0.8-2.9-0.7c-0.8,0.1-1.5,0.5-2.2,1.1l-0.7,0.8
	c-1.1,1.4-1.6,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2c0.8,0.7,1.9,0.7,3.3,0.1s2.6-1.5,3.6-3L253.8,386.9L253.8,386.9z M253.1,387.2
	c-0.6,0.8-1.2,1.2-2.2,1.4s-1.8,0-2.6-0.5c-0.8-0.6-1.2-1.4-1.4-2.2c-0.1-1,0-1.8,0.6-2.6s1.4-1.2,2.2-1.4c1-0.1,1.8,0,2.6,0.5
	c0.8,0.5,1.2,1.2,1.4,2.2C253.8,385.7,253.7,386.5,253.1,387.2z"
          />
          <path
            fill="none"
            d="M253.7,384.7c-0.1-1-0.6-1.6-1.4-2.2c-0.8-0.6-1.6-0.8-2.6-0.6c-1,0.1-1.6,0.5-2.2,1.4
	c-0.6,0.8-0.8,1.6-0.6,2.6c0.1,1,0.6,1.6,1.4,2.2c0.8,0.5,1.6,0.8,2.6,0.5c1-0.1,1.6-0.5,2.2-1.4S253.8,385.7,253.7,384.7
	L253.7,384.7z M258.1,395.6c0.8,0.1,1.5,0.7,2.3,1.5l9.2,10.2c1.6,1.4,3.3,1.9,4.7,1.9c-1.4-0.1-2.8-1-3.9-2.3l-10.6-12.1
	c-0.4-0.7-1-1.1-1.5-1.4c-0.8-0.3-1.5-0.3-2.2,0c-0.6,0.3-1.1,0.7-1.5,1.4L243.9,407c-1.1,1.2-2.3,1.9-3.7,2.2
	c1.5,0.1,3-0.5,4.7-1.8l9.4-10.3c0.8-0.8,1.5-1.4,2.3-1.5l0.8-0.1L258.1,395.6L258.1,395.6z"
          />
          <path
            fill="none"
            d="M257.2,395.5l-0.8,0.1c-0.8,0.1-1.5,0.7-2.3,1.5l-9.4,10.3c3.7,1.5,7.8,2.2,12.2,2.2c4.5,0,8.7-0.8,12.5-2.3
	l-9.2-10.2c-0.8-0.8-1.5-1.4-2.3-1.5L257.2,395.5L257.2,395.5z M223.4,375.8c0,8.3,2.6,15.4,7.7,21.5l6.7-11.8
	c0.7-1.4,1-2.5,0.6-3.4c-0.1-0.4-0.3-0.8-0.6-1.1l-6.5,1.6c-0.8-1-1.2-2.8-1.2-5.5l-6.7-3h-0.1L223.4,375.8L223.4,375.8z"
          />
          <path
            fill="none"
            d="M223.5,374l6.7,3l5.8,2.6c0.8,0.4,1.5,0.8,1.9,1.4c0.3,0.3,0.6,0.7,0.6,1.1c0.4,1,0.3,2.1-0.6,3.4l-6.7,11.8
	c-0.8,1.9-1,3.6-0.4,5c-0.1-1.4,0.1-2.8,1.1-4.3l8.3-13.9c0.4-0.5,0.8-1.2,0.8-1.9c0-0.8-0.1-1.5-0.7-2.1c-0.4-0.4-1-0.8-1.8-1
	l-14.9-6.3h-0.1c-1.4-0.5-2.3-1.4-3-2.3l-0.3-0.5l0.3,0.7C221.1,371.9,222,373,223.5,374L223.5,374z"
          />
          <path
            fill="none"
            d="M231.5,382.5l6.5-1.6c-0.4-0.5-1.1-1-1.9-1.4l-5.8-2.6C230.1,379.8,230.6,381.6,231.5,382.5L231.5,382.5z
	 M264.5,342.9l1.5,16c0,0.8,0.1,1.5,0.4,2.1c0.4,0.5,0.8,1,1.4,1.1l0.3,0.1l1.6-0.1l16.1-3.7c1.6-0.4,3.2-0.3,4.4,0.4
	c-1.2-1-2.8-1.4-5-1.2h-0.1l-8.9,1.9l-4.4,1c-1.5,0.3-2.6,0.1-3.4-0.5c-0.8-0.5-1.4-1.5-1.5-3l-0.5-4.5l-1-9.1
	c-0.5-2.1-1.4-3.4-2.6-4.3C263.8,339.7,264.5,341.1,264.5,342.9L264.5,342.9z"
          />
          <path
            fill="none"
            d="M265.5,343.2l1,9.1c1.4,0.5,2.6,1.2,3.9,1.9l12.1-0.6l-1.5-1.6C276.4,347.4,271.3,344.5,265.5,343.2
	L265.5,343.2z"
          />
          <path
            fill="none"
            d="M275,357.9c-1.5-1.5-3-2.8-4.7-3.7c-1.2-0.8-2.5-1.4-3.9-1.9l0.5,4.5c0.1,1.5,0.7,2.5,1.5,3
	c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1L275,357.9L275,357.9z"
          />
          <path
            fill="none"
            d="M276.4,359.4l8.9-1.9h0.1c-1-1.4-1.9-2.8-3-4l-12.1,0.5c1.6,1,3.2,2.2,4.7,3.7L276.4,359.4L276.4,359.4z
	 M290.7,373.7l-12.4,5.6c-1.4,0.6-2.2,1.4-2.5,2.5c-0.4,1-0.3,2.1,0.5,3.4l6.7,12c5.1-6.1,7.6-13.2,7.6-21.3
	C290.8,375.1,290.8,374.4,290.7,373.7L290.7,373.7z"
          />
          <path
            fill="none"
            d="M290.8,373.7c0.7-0.4,1.2-0.8,1.6-1.2c0.8-0.8,1.2-1.6,1.5-2.5c-0.3,0.7-0.8,1.4-1.5,1.9
	c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8
	c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1l-6.7-12c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5L290.8,373.7L290.8,373.7z
	 M196,357.5l-19.8,0.8l-0.6,1.1c-0.7,1-2.3,1.5-5.2,1.5l-13.1,0.3c-0.4,1.6-0.4,3,0,4.4c1,3.2,4.1,5.6,9.2,7.6l1.2,0.4
	c7.3,1.9,15.1,2.1,23.5,0.1l2.2-0.5C193.7,367.7,194.5,362.4,196,357.5L196,357.5z M372.4,340.1c2.3,1.1,4.7,1.6,7.2,2.1
	C376.8,341.7,374.4,341,372.4,340.1L372.4,340.1z M465.7,350.7v-7.6H391v7.6"
          />
          <path
            fill="none"
            d="M345.8,166.8l1,90.4v47.5c0.7,4.4,3.3,10.3,8.1,17.9c4.5,7.8,9.6,13.2,15.3,16.5l2.2,1.1
	c2.1,0.8,4.4,1.5,7.2,2.1c3.3,0.5,7.2,1,11.6,1h75c10.6,0.4,17.6-1.1,20.9-4.7c3.2-1.9,5.1-5.9,5.8-12c-0.1,0.1-0.4,0.3-0.7,0.3
	h-0.7c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7V325l-1.6,0.5h-0.3l-1.4,0.1H486l-10.6-1.2c-1.1-0.1-1.6-0.7-1.6-1.5v-6.6
	c0-0.8,0.5-1.4,1.6-1.5l10.6-1.2h1.1l1.4,0.1h0.3c0.5,0.1,1.1,0.3,1.6,0.5v-0.1c0-0.3,0.1-0.6,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.7
	c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7v-52l-0.3-2.9c-0.5-6.9-1.5-12.8-2.8-17.6l-0.7-2.6c-1-3-2.1-5.6-3.3-7.8l-5.5-10
	l-29-53.2L346,165.9v1L345.8,166.8L345.8,166.8z M360.4,177.1c0.8-1.1,2.2-1.6,4.1-1.5l75.2,1.2c4.4,0.1,7.3,1.5,8.7,4.4l17.3,31.9
	h1.6c2.2-0.3,4.3,0.7,6.3,2.9c1.8,1.9,2.8,3.6,2.8,5.1v11.7c0,1.8-0.4,3.3-1.4,4.3l-0.8,0.7v6.9l1.6-0.1c2.3,0,4.4,0.8,6.2,2.5
	c1.6,1.6,2.5,3.7,2.5,6.2s-0.8,4.4-2.5,6.1c-1.6,1.6-3.7,2.6-6.2,2.6c-2.3,0-4.4-0.8-6.1-2.6c-1-1-1.6-2.1-2.1-3.2l-0.4-0.6
	l-0.3-0.1c-0.1-0.1-0.3-0.4-0.3-0.7l-0.3-4.7c-0.1-0.8,0.3-1.2,0.8-1.2h1.2l0.5-0.8H468c-0.7,0-1-0.3-1-1v-4.7h-12.7
	c-1.8,0.1-2.8-1-2.9-3.3l-71.5-0.7c-3.6-0.1-7-0.4-10.3-1c-5.9-1.1-8.7-4-8.5-8.7l-1.4-46.4C359.5,179.7,359.7,178.1,360.4,177.1
	L360.4,177.1z M377.8,254c1,1,1.6,2.1,1.9,3.3h1.6c0.5-0.5,1.4-0.8,2.2-0.8h2.6c1,0,1.6,0.3,2.3,1c0.7,0.7,1,1.5,1,2.3
	c0,1-0.3,1.6-1,2.3c-0.7,0.7-1.5,1-2.3,1h-2.6c-0.8,0-1.5-0.3-2.1-0.7h-2.1c-0.4,0.8-0.8,1.5-1.5,2.2c-1.5,1.5-3.3,2.2-5.4,2.2
	s-3.9-0.7-5.2-2.2c-0.7-0.7-1.2-1.4-1.5-2.2h-1.9c-0.4,0.4-1.2,0.7-2.3,1l-3.3,0.3h-0.5c-1,0-1.9-0.4-2.6-1.1
	c-0.7-0.7-1.1-1.7-1.1-2.6s0.4-1.9,1.1-2.6c0.7-0.7,1.7-1.1,2.6-1.1l0.8,0.1l3,0.1c0.7,0.1,1.2,0.4,1.8,0.7h2.1
	c0.3-1.2,1-2.3,1.9-3.3c1.5-1.5,3.2-2.2,5.2-2.2C374.6,252,376.2,252.7,377.8,254L377.8,254z M353.8,271.8c0-0.8,0.3-1.5,0.8-2.1
	s1.2-0.8,2.2-0.8c0.8,0,1.5,0.3,2.2,0.8c0.5,0.5,0.8,1.2,0.8,2.1c0,0.8-0.3,1.5-0.8,2.2c-0.5,0.5-1.2,0.8-2.2,0.8
	c-0.8,0-1.5-0.3-2.2-0.8C354.2,273.3,353.8,272.6,353.8,271.8L353.8,271.8z M475.3,266.4l10.6-1.2h1.1l1.4,0.1h0.3
	c0.5,0.1,1.1,0.3,1.6,0.5v-0.1c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.4-0.3,0.7-0.3h0.7c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.4,0.3,0.7
	v11.7c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.3-0.7,0.3h-0.7c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.3-0.4-0.3-0.7v-0.8l-1.6,0.5h-0.3
	l-1.4,0.1h-1.1l-10.6-1.2c-1.1-0.1-1.6-0.7-1.6-1.5v-6.6C473.6,267.1,474.2,266.6,475.3,266.4L475.3,266.4z"
          />
          <path
            fill="none"
            d="M364.4,177.7l75.4,1.2c3.6,0.1,5.9,1.2,7,3.3l16.8,31h2.2l-17.3-31.9c-1.4-2.8-4.3-4.3-8.7-4.4l-75.2-1.2
	c-1.9-0.1-3.3,0.3-4.1,1.5c-0.8,1-1.1,2.5-0.8,4.7l1.4,46.4c-0.1,4.7,2.6,7.6,8.5,8.7c3.3,0.6,6.7,0.8,10.3,1l71.5,0.7v-1.9
	l-71.4-0.7c-3.4-0.1-6.9-0.4-10-1c-4.7-0.8-7-3.2-6.9-6.7l-1.4-46.5c-0.1-1.5,0-2.6,0.4-3.3C362.5,177.8,363.3,177.6,364.4,177.7
	L364.4,177.7z"
          />
          <path
            fill="none"
            d="M362.1,178.4c-0.4,0.7-0.5,1.8-0.4,3.3l1.4,46.5c0,3.6,2.2,5.8,6.9,6.7c3.2,0.6,6.5,0.8,10,1l71.4,0.7v-19.4
	c-0.1-2.6,0.7-4,2.3-4.1h9.9l-16.8-31c-1.1-2.1-3.4-3.2-7-3.3l-75.4-1.2C363.3,177.5,362.5,177.8,362.1,178.4L362.1,178.4z"
          />
          <path
            fill="none"
            d="M451.3,217.2v21.5c0.3,2.3,1.2,3.6,2.9,3.3h9.2c-0.8,0-1.5-0.4-1.8-1.1c-0.3-0.6-0.3-1.1-0.3-1.9v-22.8
	c0-0.7,0.1-1.2,0.4-1.6c0.4-0.7,1-1.1,1.8-1.2h-9.9C452,213.2,451.3,214.6,451.3,217.2L451.3,217.2z"
          />
          <path
            fill="none"
            d="M466.9,217.2c1.1,0.6,1.7,2.2,1.7,5v11c0.1,1.6-0.8,3.2-2.6,4.5l-4.3,3.3c0.3,0.7,1,1.1,1.8,1.1h3.6
	c1.2,0.1,3-0.8,5.5-2.9l1.8-1.5l0.8-0.7c1-1,1.5-2.5,1.4-4.3V221c-0.1-1.4-1-3-2.8-5.1c-1.9-2.1-4.1-3-6.3-2.9h-3.9
	c-0.8,0.1-1.4,0.6-1.8,1.2L466.9,217.2L466.9,217.2z"
          />
          <path
            fill="none"
            d="M461.9,214.4c-0.3,0.4-0.4,1-0.4,1.6v22.8c0,0.8,0.1,1.5,0.3,1.9l4.3-3.3c1.8-1.4,2.6-2.9,2.6-4.5v-10.9
	c0.1-2.6-0.6-4.3-1.6-4.9L461.9,214.4L461.9,214.4z M463.7,213.2h0.3h-0.4 M468.1,247.6h5.4c0.7,0,1-0.3,1-1v-9.1l-1.8,1.5
	c-2.5,2.1-4.3,3-5.5,2.9v4.7C467.2,247.3,467.4,247.6,468.1,247.6L468.1,247.6z"
          />
          <path
            fill="none"
            d="M468.7,248.5h13.2l-0.5,8.1c0,1-0.8,1.5-2.2,1.5h-7.6c-1.1,0-2.3-0.7-3.7-2.2c0.4,1.2,1.1,2.2,2.1,3.2
	c1.6,1.6,3.7,2.6,6.1,2.6s4.4-0.8,6.2-2.6c1.6-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.2c-1.7-1.6-3.7-2.5-6.2-2.5l-1.6,0.1v2.2
	c0,0.7-0.3,1-1,1h-4.1L468.7,248.5L468.7,248.5z"
          />
          <path
            fill="none"
            d="M467.4,248.5c-0.7,0-1,0.4-0.8,1.2l0.3,4.7c0,0.3,0.1,0.6,0.3,0.7l0.3,0.1l0.3,0.1h8.8c0.5,0,1-0.3,1-1l0.4-4.7
	c0.1-0.8-0.1-1.1-0.8-1.2H467.4L467.4,248.5z"
          />
          <path fill="none" d="M467.9,255.7v-0.3l-0.4-0.1h0.1" />
          <path fill="none" d="M467.9,255.7l-0.3-0.4l0.4,0.6" />
          <path
            fill="none"
            d="M467.9,255.7c1,0.8,1.9,1.2,3.2,1.4h4.7c1.2-0.1,2.1-0.5,2.3-1.4l0.8-7.3h-1.6c0.7,0,1,0.4,0.8,1.2l-0.4,4.7
	c0,0.7-0.4,1-1,1h-8.8V255.7L467.9,255.7z"
          />
          <path
            fill="none"
            d="M468,255.8c1.4,1.5,2.6,2.2,3.7,2.2h7.6c1.4,0,2.2-0.4,2.2-1.5l0.5-8.1h-3.2l-0.8,7.3c-0.3,0.8-1.1,1.2-2.3,1.4
	H471c-1.1-0.1-2.2-0.5-3.2-1.4L468,255.8L468,255.8z M372.4,252c-2.1,0-3.9,0.7-5.2,2.2c-1,1-1.6,2.1-1.9,3.3h14.4
	c-0.3-1.2-1-2.3-1.9-3.3C376.2,252.7,374.6,252,372.4,252L372.4,252z M354,260.1c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1h0.5
	c0.8-0.1,1.5-0.4,2.1-1.1c0.7-0.7,1.1-1.7,1.1-2.6c0-1-0.4-1.9-1.1-2.6c-0.5-0.5-1.1-0.8-1.8-1l-0.8-0.1c-1,0-1.9,0.4-2.6,1.1
	C354.2,258.2,353.9,259.1,354,260.1L354,260.1z M354.9,260.1c0-0.7,0.3-1.2,0.8-1.8s1.1-0.8,1.8-0.8s1.4,0.3,1.8,0.8
	c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.3,1.4-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.2-0.3-1.8-0.7S354.9,260.8,354.9,260.1
	L354.9,260.1z"
          />
          <path
            fill="none"
            d="M355.7,261.9c0.5,0.5,1.1,0.7,1.8,0.7s1.4-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8s-0.3-1.2-0.7-1.8
	c-0.5-0.5-1.1-0.8-1.8-0.8c-0.7,0-1.2,0.3-1.8,0.8s-0.8,1.1-0.8,1.8C354.9,260.8,355.2,261.5,355.7,261.9L355.7,261.9z"
          />
          <path
            fill="none"
            d="M358.4,256.5c0.7,0.1,1.2,0.4,1.8,1c0.7,0.7,1.1,1.6,1.1,2.6c0,1-0.4,1.9-1.1,2.6c-0.5,0.5-1.2,1-2.1,1.1
	l3.3-0.3c1.1-0.3,1.9-0.5,2.3-1h21.7c0.7,0,1.2-0.3,1.8-0.7c0.4-0.4,0.7-1.1,0.7-1.6v-0.1c0-0.7-0.3-1.2-0.7-1.8
	c-0.5-0.4-1.1-0.7-1.8-0.7h-22.3c-0.5-0.3-1.1-0.5-1.8-0.7L358.4,256.5L358.4,256.5z M364.3,258h20.9c0.5,0,1,0.1,1.4,0.5
	c0.4,0.4,0.5,0.8,0.5,1.4s-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5h-20.9c-0.4,0-0.7-0.1-1-0.5c-0.3-0.4-0.4-0.8-0.4-1.4
	s0.1-1,0.4-1.4C363.6,258.2,363.9,258,364.3,258L364.3,258z"
          />
          <path
            fill="none"
            d="M363.3,258.6c-0.3,0.4-0.4,0.8-0.4,1.4s0.1,1,0.4,1.4c0.3,0.4,0.5,0.5,1,0.5h20.9c0.5,0,1-0.1,1.4-0.5
	c0.4-0.4,0.5-0.8,0.5-1.4s-0.1-1-0.5-1.4c-0.4-0.4-0.8-0.5-1.4-0.5h-20.9C363.9,258,363.6,258.2,363.3,258.6L363.3,258.6z
	 M367.2,264.8c1.5,1.5,3.2,2.2,5.2,2.2s3.9-0.7,5.4-2.2c0.7-0.7,1.2-1.4,1.5-2.2h-13.8C365.9,263.2,366.5,264.1,367.2,264.8
	L367.2,264.8z"
          />
          <path
            fill="none"
            d="M383.4,256.5c-0.8,0-1.5,0.3-2.2,0.8h4.1c0.7,0,1.2,0.3,1.8,0.7c0.4,0.5,0.7,1.1,0.7,1.8v0.1
	c0,0.7-0.3,1.2-0.7,1.6c-0.5,0.5-1.1,0.7-1.8,0.7h-4c0.5,0.5,1.2,0.7,2.1,0.7h2.6c1,0,1.6-0.3,2.3-1s1-1.4,1-2.3c0-1-0.3-1.6-1-2.3
	s-1.5-1-2.3-1h-2.6V256.5L383.4,256.5z M354.8,273.8c0.5,0.5,1.2,0.8,2.2,0.8c0.8,0,1.5-0.3,2.2-0.8c0.5-0.5,0.8-1.2,0.8-2.2
	c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.2,0.8c-0.5,0.5-0.8,1.2-0.8,2.1C353.8,272.6,354.2,273.3,354.8,273.8
	L354.8,273.8z M356.2,271c0.3-0.1,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.7,0.3c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.7
	c-0.3,0.3-0.4,0.3-0.7,0.3s-0.5-0.1-0.8-0.3c-0.1-0.3-0.3-0.4-0.3-0.7C355.9,271.4,355.9,271.2,356.2,271L356.2,271z"
          />
          <path
            fill="none"
            d="M355.9,271.8c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.3-0.4,0.3-0.7
	s-0.1-0.5-0.3-0.8c-0.3-0.1-0.4-0.3-0.7-0.3s-0.5,0.1-0.8,0.3C355.9,271.2,355.9,271.4,355.9,271.8L355.9,271.8z M473.6,267.9v6.6
	c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1l-11-2.2V273h11.6v-3.6H476v-2.1l11-2.2h-1.1l-10.6,1.2C474.2,266.5,473.6,267.1,473.6,267.9
	L473.6,267.9z M485.6,270.5c0.3,0.3,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3
	c-0.3-0.3-0.3-0.4-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3C485.2,270.3,485.5,270.3,485.6,270.5L485.6,270.5z
	 M479.8,271.2c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.3-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.3-0.3-0.4-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.8c0.3-0.3,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3C479.8,270.7,479.8,271,479.8,271.2z"
          />
          <path
            fill="none"
            d="M484.9,270.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8C485.5,270.3,485.2,270.3,484.9,270.3L484.9,270.3z
	 M479.5,270.5c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8C479.8,271,479.8,270.7,479.5,270.5L479.5,270.5z"
          />
          <path
            fill="none"
            d="M487,265.3l-11,2.2v2.1h11.6v3.6H476v2.1l11,2.2l1.4-0.1h0.3l1.6-0.5V266c-0.5-0.3-1-0.4-1.6-0.5h-0.3
	L487,265.3L487,265.3z"
          />
          <path
            fill="none"
            d="M490.1,266v5.1h2.8v-5.4c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3
	s-0.3,0.4-0.3,0.7L490.1,266L490.1,266z M490.6,278.1c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7
	v-6.3h-2.8v6.3C490.1,277.7,490.3,278,490.6,278.1L490.6,278.1z"
          />
          <path
            fill="none"
            d="M663.6,271.8c0-0.3,0.1-0.5,0.5-0.5h1.9l-0.1-4.4l-160.4-7.7l-12.8-0.1l0.3,2.9v63.7c0,0.3-0.1,0.5-0.3,0.7
	c-0.8,6.1-2.6,10-5.8,12c-3.3,3.4-10.2,5.1-20.9,4.7h-0.3v7.6h65.5l2.1-2.8h-10c13.6-22.8,27-42.5,40-58.9
	c6.9-9.5,16.2-14.7,28.1-15.7c25.7-1.6,49.9-1.4,72.5,0.8v-2.2L663.6,271.8L663.6,271.8z"
          />
          <path
            fill="none"
            d="M490.1,314.1v5.2h2.8v-5.2c0-0.3-0.1-0.4-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3
	C490.3,313.7,490.1,313.9,490.1,314.1L490.1,314.1z M490.6,326.5c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.4-0.1,0.7-0.3
	c0.1-0.1,0.3-0.4,0.3-0.7v-6.3h-2.8v6.3C490.1,326.1,490.3,326.4,490.6,326.5L490.6,326.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M253,375.4l-0.1,0.3v0.5l0.1,0.3l0.1,0.1h0.7l0.1-0.1v0.5h0.6V375h-0.6
	v0.1l-0.1-0.1l-0.3-0.1H253C253.1,375.2,253,375.2,253,375.4L253,375.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M253.5,376.2l-0.1-0.4v-0.1l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.3v0.3
	l-0.1,0.1h-0.1L253.5,376.2L253.5,376.2z M255.9,375.1h-0.4c-0.3,0-0.4,0.1-0.6,0.4l-0.1,0.3v0.3h1.2v0.1c0,0.1-0.1,0.1-0.3,0.1
	h-0.1l-0.1-0.1h-0.6l0.1,0.1l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5l-0.3-0.3L255.9,375.1
	L255.9,375.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M255.7,375.4h0.1l0.1,0.1v0.1h-0.6L255.7,375.4z M257.7,375.4h0.1
	c0.1,0,0.1,0,0.1,0.1v0.1h-0.5v-0.1L257.7,375.4L257.7,375.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M257.5,375.1h-0.1c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.3l-0.1,0.1
	h-0.1l-0.1-0.1H257l0.1,0.1l0.3,0.1l0.5,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4l-0.1-0.3v-0.1l-0.3-0.3l-0.4-0.1L257.5,375.1
	L257.5,375.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M259.4,374.4h-0.7v1.4l0.1,0.3c0.1,0.1,0.3,0.3,0.5,0.3h0.3l0.7-0.1
	c0.3-0.1,0.4-0.3,0.4-0.4v-0.5h-0.5v0.4l-0.3,0.1h-0.3l-0.1-0.3v-1.1H259.4L259.4,374.4z M635.3,370.8h0.1c0.1,0,0.1,0,0.1,0.1v0.1
	H635V371L635.3,370.8L635.3,370.8z M638.6,371.1H638v0.4l-0.3,0.1h-0.3l-0.1-0.3v-1.5h-0.7v1.4l0.1,0.3l0.5,0.3h0.3l0.7-0.1
	c0.3-0.1,0.4-0.3,0.4-0.4L638.6,371.1L638.6,371.1z M631.8,371.4v0.3c0,0.1-0.1,0.1-0.1,0.1h-0.3c-0.1,0-0.3,0-0.3-0.1H631v-0.4
	l0.1-0.1h0.1c0.1,0,0.3,0,0.3,0.1L631.8,371.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M631.4,370.5h-0.5c-0.1,0.1-0.3,0.1-0.3,0.3l-0.1,0.3v0.5l0.1,0.3l0.1,0.1
	h0.7l0.1-0.1v0.5h0.5v-1.9h-0.5v0.1L631.4,370.5L631.4,370.5z M633.8,371.1h-0.7l0.3-0.3h0.1l0.1,0.1L633.8,371.1z M634.6,371
	l-0.1,0.3v0.3h1.2v0.3l-0.1,0.1h-0.1l-0.1-0.1h-0.5l0.1,0.1c0.1,0.1,0.1,0.1,0.3,0.1l0.5,0.1h0.3c0.3-0.1,0.4-0.1,0.5-0.4l0.1-0.4
	l-0.1-0.3V371c-0.1-0.1-0.1-0.3-0.3-0.3l-0.7-0.1h-0.4C634.9,370.5,634.7,370.7,634.6,371L634.6,371z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M633.9,370.7l-0.4-0.1h-0.4c-0.3,0-0.4,0.1-0.5,0.4l-0.1,0.3v0.3h1.2v0.1
	c0,0.1-0.1,0.1-0.3,0.1h-0.1l-0.1-0.1h-0.5l0.1,0.1l0.4,0.1l0.4,0.1h0.3c0.3-0.1,0.4-0.3,0.5-0.4v-0.3c0-0.1,0-0.4-0.1-0.5
	L633.9,370.7L633.9,370.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M149,292.1v1 M149,293.1
	h0.6 M149.5,292.1H149 M149,292.1c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-26.8l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5 M149,263.9v-1
	 M149.5,263.9H149 M149.5,263H149"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M149,263v-0.3
	c0-0.1-0.1-0.4-0.3-0.5l-0.1-0.1v-0.7l-0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1l-0.3,0.1l-0.1,0.3v0.7l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5
	v1.2l0.3,0.5l0.1,0.1v26.8l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5v1l0.3,0.5l0.1,0.1v1.2l0.1,0.3l0.3,0.1c0.1,0,0.1,0,0.3-0.1l0.1-0.3
	v-1.2l0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M146.5,200.4V200c0-0.6-0.1-0.8-0.6-1 M145.4,198.9h0.1l0.4,0.1 M146,199
	l-0.6-0.1 M142.7,204.2l2.9-0.3c0.7,0,1-0.3,1-1v-0.4 M146.5,202.4h2.3v-2.1h-2.3 M146.5,200.4v2.1 M133.4,205.1l2.2-0.1
	 M133.4,197.8v-0.4c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.3v7.3l0.1,0.3l0.3,0.1l0.3,0.1c0.1-0.1,0.1-0.1,0.1-0.3
	 M133.4,197.8v7.3 M142.7,204.2l-7,0.7 M145.4,198.9l-12-1.1 M146.9,327.4v-0.5l-4.5-108c-0.6-3.9-2.9-6.3-6.9-7.4
	c-4.8-0.7-10.5-0.8-17.1-0.4l-2.2,0.1c-6.6,0.1-12.1,0.8-16.9,1.9c-4,1.5-6.1,4.1-6.2,8l4.5,108c0.6,3.9,2.9,6.3,6.9,7.4
	c4.8,0.7,10.5,0.8,17.1,0.4h0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M135.6,204.9c1.2,0.6,2.3,2.1,3.6,4.3l5.9,11.3c0.8,1.4,1.2,4,1.5,7.8
	l2.9,34.8 M153.7,261.3l-2.8-33.8c-0.3-3.7-0.8-6.5-1.6-8.4l-6.6-15"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M149.5,263V295h1 M153.7,261.3h-4.1v1.6 M150.5,295v4.7l3.4,1.4
	 M152.1,308.6v-2.5 M153.9,304.1h-0.8c-0.7,0-1,0.3-1,1v1.1 M152.1,306.2h-1v2.5h1 M152.1,308.6c0.1,0.3,0.3,0.4,0.6,0.5
	 M152.6,309.3l-1.5,1v8.4l2.5,1.4 M153.9,327.9c-0.6-0.3-1.4-0.4-2.5-0.4l-4.4-0.1 M153.5,320.1h-2.6v2.3l3,0.5 M134.4,334.6v13.5
	c0,0.3-0.1,0.5-0.6,0.5h-6.5 M134.4,334.6v-0.5c0-0.3-0.1-0.5-0.6-0.5h-7c-0.3,0-0.6,0.1-0.6,0.5v14c0,0.3,0.1,0.5,0.6,0.5h0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M146.9,327.4l-18-0.8c-3.4,0-5.8,1.9-6.6,6.1l-0.1,4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M127.5,348.7v-13.5c0-0.3,0.1-0.5,0.5-0.5h6.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M155,329.1h-25c-3.4,0.3-5,2.6-4.7,6.6v14.6c0.1,2.3,1.5,5.5,4,9.8
	c0.8,1.1,2.2,1.7,4,1.7 M122.2,336.8l-0.4,14.6c0,1.4,1,3.6,2.9,6.6l1.9,3.2 M128.3,363.3c0.7,0.3,1.6,0.4,2.8,0.3l20.6-0.3
	c1.6-0.1,2.5-0.8,2.5-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M126.7,361.3l0.6,0.8c0.3,0.4,0.7,0.8,1.2,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M126.7,361.3v13.5c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5v-11.6
	 M142.7,369.9c-0.3,0-0.4,0.6-0.4,1.6c-0.1,1.1,0,2.3,0.1,4c0.1,1.5,0.3,2.9,0.6,3.9c0.3,1,0.4,1.5,0.7,1.5 M143.8,380.7
	c0.1-0.3,0.3-0.7,0.3-1.6c0.1-1.1,0-2.3-0.1-4c-0.1-1.5-0.3-2.8-0.6-3.9c-0.3-1.1-0.6-1.6-0.7-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M143.5,380.9h0.1l0.1-0.1 M143.5,380.9l0.3-0.1 M133.2,361.7l-1,0.1
	 M344.3,155.4c-8.3-1.2-18.2-1.9-29.7-1.8H210.5c-10.9,0.3-21.3,1.2-31.2,3l-0.6,4 M451.5,238.6l-71.5-0.7c-3.6-0.1-7-0.4-10.3-1
	c-5.9-1.1-8.7-4-8.5-8.7l-1.4-46.4c-0.3-2.2,0-3.7,0.8-4.7c0.8-1.1,2.2-1.6,4.1-1.5l75.2,1.2c4.4,0.1,7.3,1.5,8.7,4.4l17.3,31.9
	 M322.2,174c6.2-0.1,9.2,1.9,9.4,6.3v49.1c-0.1,3.6-1.4,6.1-4,7.7c-2.3,1.5-5.9,2.2-10.6,1.9H204.1c-4.3-0.3-7.4-1-9.4-2.3
	c-2.2-1.4-3-3.4-2.6-5.9l4.4-48.1c0.4-5.6,3-8.5,7.8-8.8h117.8L322.2,174L322.2,174z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M330.2,180.3c-0.1-3.3-2.8-5-7.8-5H204.7c-4,0.3-6.1,2.8-6.5,7.4
	l-4.4,48.1c-0.3,1.9,0.4,3.4,1.9,4.4c1.8,1.1,4.7,1.8,8.7,2.1h112.8c4.3,0.3,7.6-0.3,9.8-1.6c2.1-1.4,3.2-3.4,3.3-6.5v-49H330.2
	L330.2,180.3z M451.3,167.8l-105.5-1.9v1 M345.8,166.8h-1.5 M344.3,166.8v-11.4 M489.2,212.9l-26.8-44.3c-2.2-3-4.8-4.8-7.8-5.4
	 M454.5,163.2c0.3-1.4,0.1-2.5-0.3-3.6c-6.7-1.4-15.1-2.2-25.2-2.5l-84.7-1.8 M454.5,163.2l-3.2,4.5 M463.6,213.2h-9.9
	c-1.6,0-2.3,1.4-2.3,4.1v19.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M461.8,240.8l4.3-3.3c1.8-1.4,2.6-2.9,2.6-4.5v-10.9
	c0.1-2.6-0.6-4.3-1.6-4.9l-5.1-2.8 M465.8,213.2H464 M464,213.2h-0.4 M463.6,213.2h0.1 M463.7,213.2c-0.8,0.1-1.4,0.6-1.8,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M461.9,214.4c-0.3,0.4-0.4,1-0.4,1.6v22.8c0,0.8,0.1,1.5,0.3,1.9
	 M463.7,213.2h0.3 M465.8,213.2h1.6c2.2-0.3,4.3,0.7,6.3,2.9c1.8,1.9,2.8,3.6,2.8,5.1v11.7c0,1.8-0.4,3.3-1.4,4.3l-0.8,0.7
	 M451.3,236.7l0.1,1.9 M463.6,241.9h-9.2c-1.8,0.1-2.8-1-2.9-3.3 M461.8,240.8c0.3,0.7,1,1.1,1.8,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M467.2,242.1l-0.1-0.1h-3.4 M467.2,242.1v4.7c0,0.7,0.3,1,1,1h1.2
	 M469.4,247.6l-0.7,1 M468.7,248.5h-1.2c-0.7,0-1,0.4-0.8,1.2l0.3,4.7c0,0.3,0.1,0.6,0.3,0.7l0.3,0.1 M467.4,255.3h0.1 M467.6,255.3
	l0.4,0.6 M467.9,255.4v0.3 M467.4,255.3l0.4,0.1 M467.9,255.7l-0.3-0.4 M467.9,255.7l0.1,0.1 M468,255.8c0.4,1.2,1.1,2.2,2.1,3.2
	c1.7,1.6,3.7,2.6,6.1,2.6c2.3,0,4.4-0.8,6.2-2.6c1.6-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.2c-1.6-1.6-3.7-2.5-6.2-2.5l-1.6,0.1
	 M379.7,257.3c-0.3-1.2-1-2.3-1.9-3.3c-1.5-1.5-3.3-2.2-5.4-2.2s-3.9,0.7-5.2,2.2c-1,1-1.6,2.1-1.9,3.3 M354.9,260.1
	c0,0.7,0.3,1.4,0.8,1.8c0.5,0.5,1.1,0.7,1.8,0.7s1.4-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8s-0.3-1.2-0.7-1.8
	c-0.5-0.5-1.1-0.8-1.8-0.8c-0.7,0-1.2,0.3-1.8,0.8C355.2,258.7,354.9,259.4,354.9,260.1L354.9,260.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M358.4,256.5l-0.8-0.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
	c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1h0.5 M365.2,257.3h-2.1c-0.5-0.3-1.1-0.5-1.8-0.7l-3-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M358.1,263.8c0.8-0.1,1.5-0.4,2.1-1.1c0.7-0.7,1.1-1.7,1.1-2.6
	c0-1-0.4-1.9-1.1-2.6c-0.5-0.5-1.1-0.8-1.8-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M358.1,263.8l3.3-0.3c1.1-0.3,1.9-0.5,2.3-1h1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M365.5,262.4c0.4,0.8,0.8,1.5,1.5,2.2c1.5,1.5,3.2,2.2,5.2,2.2
	s3.9-0.7,5.4-2.2c0.7-0.7,1.2-1.4,1.5-2.2 M363.3,258.6c0.3-0.4,0.5-0.5,1-0.5h20.9c0.5,0,1,0.1,1.4,0.5c0.4,0.4,0.5,0.8,0.5,1.4
	s-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5h-20.9c-0.4,0-0.7-0.1-1-0.5c-0.3-0.4-0.4-0.8-0.4-1.4S363,259,363.3,258.6L363.3,258.6z
	 M356.2,271c-0.1,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.7c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3
	c0.3-0.3,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.1-0.4-0.3-0.7-0.3C356.6,270.7,356.3,270.8,356.2,271L356.2,271z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.8,271.8c0,0.8,0.3,1.5,0.8,2.2c0.5,0.7,1.2,0.8,2.2,0.8
	c0.8,0,1.5-0.3,2.2-0.8c0.5-0.5,0.8-1.2,0.8-2.2c0-0.8-0.3-1.5-0.8-2.1c-0.5-0.5-1.2-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.2,0.8
	C354.2,270.1,353.8,271,353.8,271.8L353.8,271.8z M381.3,257.3h-1.6 M379.3,262.4h2.1 M381.3,262.4c0.5,0.5,1.2,0.7,2.1,0.7h2.6
	c1,0,1.6-0.3,2.3-1c0.7-0.7,1-1.4,1-2.3c0-1-0.3-1.6-1-2.3c-0.7-0.7-1.5-1-2.3-1h-2.6c-0.8,0-1.5,0.3-2.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M381.3,262.4h4c0.7,0,1.2-0.3,1.8-0.7c0.4-0.4,0.7-1.1,0.7-1.6v-0.1
	c0-0.7-0.3-1.2-0.7-1.8c-0.5-0.4-1.1-0.7-1.8-0.7h-4.1 M379.7,257.3h-14.4 M379.3,262.4h-13.8 M346.8,300.9v3.7 M346.8,304.7
	c-0.3-1.4-0.3-2.6,0-3.7 M346.8,300.9v-43.7 M346.8,257.2l-1-90.4 M178.6,160.6l-8.7,3.7c-1.8,0.8-3,2.2-3.7,4.4 M166.2,168.7
	c-1.9,0.4-2.9,1.4-3,3.2l-0.4,10.5 M162.7,182.2l-1.9-0.1l-0.4,3 M166.2,168.7l-0.3,1.2l-2.1,12.2v0.1l-0.4,3.4 M178.6,160.6
	l-0.7,5.6 M160.4,185.1h-0.1c-0.4,0-0.8,0.3-1.2,0.8c-0.3,0.6-0.6,1.1-0.6,1.9c0,0.7,0.1,1.4,0.6,1.9l0.6,0.7 M159.7,190.2l-0.3,1.9
	l2.9,0.4 M162.3,192.5l-0.3,1.8c-2.8,19.3-5.1,39.8-7.3,61.4 M159.7,190.2l0.7-5.1 M163.4,185.8c-0.7,0-0.8-0.6-0.7-1.6l0.1-1.9
	 M162.3,192.5l1.1-6.7 M188.9,325.7l1.9-0.1l7.2-16c1.1-2.5,2.2-4.5,3.6-6.3c3.7-4.8,8.7-7.6,15-8.1c16.2-1.5,35.4-1.2,57.2,0.7
	c6.6,0.4,12,1.6,16.2,4c0.4,0.3,0.8,0.5,1.1,0.8c0.8,0.5,1.5,1.1,2.1,1.7l4.5,4.8l4.8,5.9c7.2,9.1,14.3,18.8,21.3,29.4l5.2,8.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M353.7,350.7c-10-14.6-19.8-28.3-29.7-41.1l-12.8-16.4
	c-6.9-8.5-18-13.1-33.2-14c-21.7-2.1-44.4-2.5-68.1-1c-9.4,0.8-16.4,5-21,12.5c-6.6,10.2-12.9,22.1-19.1,35.6l19.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M345,350.7l-38.5-51.3c-5.5-7.3-12.8-11.6-21.6-12.8
	c-20.1-2.5-42.4-3.3-66.6-2.8c-10.5-0.3-18.2,2.2-23.1,7.6l-1.6,2.2l-5,7.6c-4.1,6.7-8.3,15-12.2,24.6 M168.5,255.7h-13.8
	 M154.2,261.3h-0.6 M157.1,294.6c0.4-0.3,0.7-0.8,0.8-1.4l0.6-29.6c0-0.4-0.1-0.8-0.3-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M158.3,262.4c1.1,0.1,1.6,1,1.9,2.5l-0.6,27c-0.1,1.9-1,2.8-2.6,2.8
	 M158.3,262.4c-0.3-0.7-1-1.1-1.8-1.1h-2.2 M181.4,263.2c-2.3,0-4.4,0.8-6.1,2.5c-1.6,1.6-2.5,3.7-2.5,6.1c0,2.3,0.8,4.4,2.5,6.1
	s3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5c1.6-1.6,2.5-3.7,2.5-6.1c0-2.3-0.8-4.4-2.5-6.1C185.8,264.1,183.9,263.2,181.4,263.2
	L181.4,263.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M181.4,265.9c-1.6,0-3,0.5-4.3,1.8c-1.2,1.2-1.8,2.6-1.8,4.3
	s0.6,3,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8c1.6,0,3-0.5,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3c0-1.6-0.6-3-1.8-4.3
	C184.6,266.4,183.1,265.9,181.4,265.9L181.4,265.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M181.4,259.8c-3.3,0-6.2,1.2-8.5,3.6c-2.3,2.3-3.6,5.2-3.6,8.5
	c0,3.3,1.2,6.2,3.6,8.5c2.3,2.3,5.2,3.6,8.5,3.6c3.3,0,6.2-1.2,8.5-3.6c2.3-2.3,3.6-5.2,3.6-8.5c0-3.3-1.2-6.2-3.6-8.5
	C187.6,261,184.7,259.8,181.4,259.8L181.4,259.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M184.5,271.9c0-0.8-0.3-1.5-0.8-2.1s-1.2-0.8-2.1-0.8s-1.5,0.3-2.1,0.8
	c-0.6,0.5-0.8,1.2-0.8,2.1c0,0.8,0.3,1.5,0.8,2.1s1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8S184.5,272.7,184.5,271.9L184.5,271.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M185.7,271.9c0-1.1-0.4-2.2-1.2-3c-0.8-0.8-1.8-1.2-3-1.2
	c-1.1,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.1,0.4,2.2,1.2,3c0.8,0.8,1.8,1.2,3,1.2c1.1,0,2.2-0.4,3-1.2
	C185.3,274.1,185.7,273,185.7,271.9L185.7,271.9z M153.9,301.1v3 M157.1,294.6c-0.4,0.3-0.8,0.5-1.4,0.4h-1.8 M153.9,301.1V295
	 M154.8,255.7l-0.6,5.6 M177.9,166.3c-3.7,28.3-6.9,58.1-9.4,89.4 M177.9,166.3l89.3,0.6h77.2 M168.5,255.7l178.3,1.5 M662.8,259
	l-0.5-3.6c0-0.1,0-0.3,0.1-0.3c0.1-0.1,0.1-0.1,0.3-0.1h5.2c0.1,0,0.3,0,0.4,0.1l0.1,0.3l0.5,3.6 M661.5,252.5l-24.1-2.2l-103-10.2
	 M669,259h0.3c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.3,0.1,0.3v0.3c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.4 M669.1,260.8
	h0.8c0.3,0,0.5,0.1,0.5,0.5l0.5,3.2c0,0.1,0,0.3-0.1,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M670.9,264.9l-0.3,0.4c-0.1,0.1-0.1,0.1-0.4,0.1h-1.1
	c-0.3,0-0.5-0.1-0.5-0.5v-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M670.9,264.9l-0.3,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5v-0.3 M688.1,259.1
	L688,259c-0.4-0.5-1-1-1.5-1.4 M686.4,257.7l1.5,10.2 M688.8,263.7c-0.1-2.1,0-2.9,0.7-2.6 M668.1,271.4h2.3c0.3,0,0.5,0.1,0.5,0.5
	v1.6 M671,273.4v1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M671,273.4h0.7v1.6 M671.7,275.1H671 M671.7,275.1l5.9,0.8
	c4.5,0.4,8.5,1.2,12,2.3 M691.9,279.2l-0.5-2.5 M689.5,261.2c0.3,0.4,0.4,0.8,0.7,1.2l5.5,18.7 M691.9,279.2c-0.8-0.3-1.5-0.7-2.5-1
	 M708,304.8c1.1-0.4,1.5-1,1.1-1.9c-2.3-6.2-4.7-11.3-7.2-15.3c-0.8-1.4-1.8-2.6-2.9-3.7 M694,292.4c-0.8-0.8-1.9-1.2-3-1.2
	c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.9-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.9,1.2,3,1.2c1.2,0,2.2-0.4,3-1.2
	c0.8-0.8,1.2-1.9,1.2-3S694.8,293.4,694,292.4L694,292.4z M695.7,281.1c1.2,0.8,2.5,1.8,3.4,2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M699.1,283.9l-6.3-19.7c-1.4-2.6-2.9-4.4-4.7-5.1 M691.4,276.7
	c0.3-6.1-0.5-10.5-2.6-12.9 M695.7,281.1c-1.1-0.7-2.3-1.4-3.7-1.9 M689.6,278.2l-1.7-10.3"
          />
          <path
            fill="#FFFFFF"
            stroke="#010101"
            strokeWidth="0.5"
            d="M688.8,263.7c0.3,2.5,1.1,6.9,2.6,12.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M688.1,259.1c0.5,0.5,1,1.2,1.4,1.9 M662.8,259h-1.1c-0.1,0-0.3,0-0.3,0.1
	l-0.1,0.3v0.3l0.1,0.3l0.3,0.1h1 M667.3,257.9l-0.3-0.8h-2.9v0.8 M662.8,260.8h-0.1l-0.1,0.1h-0.1l-0.1,0.1 M662.6,260.2l0.1,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M663.2,260.2h-0.1l-0.1-0.4l-0.1-0.8 M663.2,260.2h-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664.2,257.9l0.1,1.9c0,0.1,0,0.3-0.1,0.3h-1.1 M665,264.4l-0.5-3
	l-0.3-0.4 M662.2,261l-0.3,0.4v0.4l0.5,3.2c0,0.3,0.3,0.5,0.5,0.5h1.1c0.1,0,0.3,0,0.4-0.1l0.3-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664.8,264.9l-0.3,0.1h-1.1c-0.3,0-0.5-0.1-0.5-0.5l-0.6-3.2V261
	 M662.8,260.8h1.1c0.1,0,0.3,0,0.4,0.1 M669,260.2l-0.3,0.1h-0.4l-0.3-0.1h-0.1l-0.1-0.4l-0.4-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M669,259l0.1,0.8c0,0.1,0,0.3-0.1,0.3h-0.1 M668.4,260.9h0.1l0.3-0.1h0.3
	 M669.1,260.8l-0.1-0.5 M669,264.4l-0.5-3v-0.4 M664.8,264.9c0.1-0.1,0.1-0.3,0.1-0.4v-0.1 M665,264.4l0.8,0.5v2.1 M668,267.1V265
	l0.5-0.4 M668.6,264.6l0.4-0.3 M664.2,260.9h4.3 M667.3,257.9h-3.2 M663.6,274.3v-2.5c0-0.3,0.1-0.5,0.5-0.5h1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M665.9,271.4l0.1,1.8h2.2l-0.1-1.8 M668.1,271.4l-0.1-4.3 M665.9,271.4
	l-0.1-4.4 M686.4,257.7c0-1.2-2.3-2.2-6.9-3l-18-2.2 M688,267.9l-19.9-0.8 M671,275.1l-7.4-0.8 M708,304.8l-5.1,1.7l-2.6,0.3h-9.5
	c-1.1,0.1-1.9-0.3-2.5-1.2c-5-6.5-9.6-10.3-14.2-12c-5.1-1.6-12.9-2.5-23.8-2.8h-49.2c-7.2,0.1-12.9,3.3-17.3,9.1
	c-11.6,14.4-23.1,30.4-34.7,48.1h-16 M489.9,214.1v-63.3c0.1-0.1,0.1-0.3,0.1-0.4v-1.1c0-0.1-0.1-0.3-0.1-0.4
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4v1.1l0.1,0.4h0.1v62 M583.4,238.9v-0.1l-5-0.6l-1.8,4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M585.1,243.2v-3.7c0-0.3-0.1-0.6-0.5-0.6l-1.2-0.1 M584.2,243.2l-0.8-4.3
	 M584.2,243.2h0.8 M576.7,242.5l7.6,0.7 M533.2,348.1h-10c13.6-22.8,27-42.5,40-58.9c6.9-9.5,16.2-14.7,28.1-15.7
	c25.7-1.6,49.9-1.4,72.5,0.8 M489.9,214.1l-0.7-1.2 M484.6,223.4c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1
	s-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1S484.4,223.2,484.6,223.4L484.6,223.4z
	 M489.2,230.5l0.3,0.4l0.4,0.3l0.5,0.1c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4
	l-0.5,0.1l-0.4,0.3l-0.3,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M489.2,229.5L489,230l0.1,0.6 M489.2,230.5v-1 M480.2,221.2l5.5,10
	c1.2,2.2,2.3,4.7,3.3,7.8l0.7,2.6c1.2,4.8,2.2,10.7,2.8,17.6 M497.6,243.3c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4C497.4,244,497.6,243.7,497.6,243.3L497.6,243.3z
	 M500.2,234.4c0,0.3,0.1,0.6,0.3,1l0.5,0.7c0.3,0.1,0.4,0.1,0.6,0.1c0.1-0.1,0.3-0.3,0.1-0.6c0-0.3-0.1-0.6-0.3-0.8
	c-0.1-0.3-0.4-0.6-0.5-0.7l-0.5-0.1C500.2,233.8,500,234.1,500.2,234.4L500.2,234.4z M503.8,237.1l-0.4-0.7 M510,237l-0.1-0.4
	l-1.8-0.1l-0.1,0.4 M507.7,237.4l0.1-0.6 M510,237l0.3,0.7 M507.7,237.4l2.5,0.3 M507.9,236.8l-4.5-0.4 M503.8,237.1l4,0.3
	 M498.9,251.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-1-0.4s-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.7,0.4,1s0.5,0.4,1,0.4
	s0.7-0.1,1-0.4C498.8,252.5,498.9,252.2,498.9,251.8L498.9,251.8z M474.5,237.5l-1.8,1.5c-2.5,2.1-4.3,3-5.5,2.9 M478.9,248.5h-1.7
	 M474.5,244.4v2.2c0,0.7-0.3,1-1,1h-4.1 M468,255.8c1.4,1.5,2.6,2.2,3.7,2.2h7.6c1.4,0,2.2-0.4,2.2-1.5l0.5-8.1h-3.2 M474.5,244.4
	v-6.9 M503.3,236.4l-12.5-20.8 M490.8,215.7l-1-1.5 M490.8,215.7l-10.6,5.5 M534.4,239l0.3-2.8c-1.1-0.4-1.8-0.3-2.1,0.1l-0.3,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M534.2,238.2c0.1-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6
	c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6s0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3
	C533.9,238.3,534,238.3,534.2,238.2z M534.4,240.1V239 M532.2,239.9l2.2,0.3 M532.4,238.9l-0.1,1 M487,265.3h-1.1l-10.6,1.2
	c-1.1,0.1-1.6,0.7-1.6,1.5v6.6c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M490.1,266c-0.5-0.3-1-0.4-1.7-0.5h-0.3l-1.4-0.1 M492.9,271.1v-5.4
	c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7c-0.3,0-0.6,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v0.1 M485.6,270.5
	c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8C486,271,485.9,270.7,485.6,270.5L485.6,270.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M487,265.3l-11,2.1v2.2h11.6v3.6H476v2.1l11,2.2 M490.1,276.6v0.8
	c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7v-6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M487,277.4l1.4-0.1l0.3-0.1l1.5-0.5 M490.1,276.6v-5.5 M479.8,271.2
	c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3C479.8,271.8,479.8,271.5,479.8,271.2z M490.1,271.1V266 M492.6,259.1l0.3,3v52
	 M505.4,259.3l-12.8-0.1 M490.1,271.1h2.8 M532.4,238.9l-22.4-1.9 M505.4,259.3c-1.8-11.7-2.5-19-1.7-22.3 M510.2,237.7l22,2.2
	 M534.4,240.1c1.4,5.8,2.1,12.7,2.2,20.5 M576.7,242.5l-42.2-3.4 M661.5,252.5l-76.5-9.4 M665.8,267l-160.4-7.7 M487,313.6h-1.1
	l-10.6,1.2c-1.1,0.1-1.6,0.7-1.6,1.5v6.6c0,0.8,0.5,1.4,1.6,1.5l10.6,1.2h1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M492.9,314.1c0-0.3-0.1-0.4-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-0.7
	c-0.3,0-0.6,0.1-0.7,0.3c-0.1,0.1-0.3,0.4-0.3,0.7v0.1 M490.1,314.3c-0.5-0.3-1-0.4-1.7-0.5h-0.3l-1.4-0.1 M485.6,319
	c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.4,0.3,0.8,0.3
	c0.3,0,0.5-0.1,0.8-0.3c0.3-0.3,0.3-0.4,0.3-0.8C486,319.4,485.9,319.1,485.6,319L485.6,319z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M487,313.6l-11,2.2v2.1h11.6v3.6H476v2.1l11,2.2 M490.1,325v0.8
	c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.4,0.3,0.7,0.3h0.7c0.3,0,0.4-0.1,0.7-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M487,325.7l1.4-0.1h0.3l1.5-0.5 M490.1,325v-5.5 M479.8,319.6
	c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.3-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.3,0.4-0.3,0.8c0,0.3,0.1,0.5,0.3,0.8
	c0.3,0.3,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3C479.8,320.2,479.8,319.9,479.8,319.6L479.8,319.6z M490.1,319.5v-5.2 M492.6,326.5
	c0.3-0.3,0.3-0.4,0.3-0.7v-6.3 M492.9,319.5v-5.4 M492.6,326.5c-0.8,6.1-2.6,10-5.8,12c-3.3,3.4-10.2,5.1-20.9,4.7h-0.3
	 M490.1,319.5h2.8 M531.1,350.7h14.7c0.7,0,0.8,0.3,0.4,1l-1.5,2.9 M533.2,348.1l-2.1,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M324.6,351.7v9.2c0,0.7,0.3,1,1,1h214.2c0.7,0,1.1-0.3,1.5-1l3.4-6.3
	 M696.1,354.2c-1.7-6.2-4.1-11.8-7.7-17.2c-0.8-1.2-1.8-2.6-2.8-3.9c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5
	c-12.4-12.4-27.4-18.6-45-18.6c-17.6,0-32.6,6.2-45,18.6l-1.5,1.5c-1.7,1.8-3.2,3.6-4.5,5.4l-0.3,0.3c-4.8,6.5-8.3,13.5-10.3,21"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M586,334.9c-1,1.2-1.9,2.6-2.8,4c-6.1,9.5-9.1,20.2-9.1,32.3
	c0,12,3,22.7,9.1,32.2c0.8,1.4,1.8,2.6,2.8,4c1.8,2.3,3.7,4.4,5.8,6.6c3,3,6.2,5.6,9.6,8c9.6,6.5,20.8,9.8,33.2,9.8
	s23.5-3.3,33.2-9.8c3.3-2.2,6.6-5,9.6-8c2.1-2.1,4-4.3,5.8-6.6c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1
	c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.8-3.3-5.5-5.2-8.1c-1.4-1.8-2.8-3.4-4.4-5.1l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5
	c-16.1,0-30,5.5-41.5,16.5l-2.6,2.6C588.9,331.5,587.4,333.1,586,334.9L586,334.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626.1,338.5c-5.8,1.4-10.7,4.4-15.3,8.8c-1.8,1.8-3.4,3.7-4.7,5.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M606.1,353.1c-2.2-0.1-3.7,0.3-5,1.2c1.2-0.7,2.6-0.8,4.4-0.4l15.7,3.6
	c0.7,0.3,1.4,0.3,2.1,0.3l0.7-0.3c0.4-0.3,0.8-0.5,1-1c0.3-0.5,0.4-1.2,0.4-1.9l1.5-16.1v-0.1c0.1-1.6,0.7-3,1.8-4
	c-1.2,0.8-2.1,2.2-2.6,4.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M625,347.6c-3,1.2-5.8,3-8.4,5.6l-1.7,1.6 M615,355l4.4,1
	c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.5,1.4-1.5,1.7-3l0.5-4.7 M626.1,338.5l-1.1,9.1 M615,355l-8.9-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M640.4,334.1l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11
	c-3.9,3.9-6.6,8.1-8.4,12.7c-0.7,1.8-1.2,3.6-1.7,5.5c-0.5,2.6-0.8,5.4-0.8,8.3s0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M607.8,372.3l-6.7-3h-0.1 M600.9,369.3v1.9c0,8.3,2.6,15.4,7.7,21.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M608.2,397.6c-0.1-1.4,0.1-2.8,1.1-4.3l8.3-13.9c0.4-0.5,0.8-1.2,0.8-1.9
	c0-0.8-0.1-1.5-0.7-2.1c-0.4-0.4-1-0.8-1.8-1l-14.9-6.3h-0.1c-1.4-0.6-2.3-1.4-3-2.3l-0.3-0.5l0.3,0.7c0.6,1.1,1.5,2.2,2.9,3.2
	 M619.7,363.3c-1.5,0.3-2.3,1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3c0.8,0.1,1.7,0,2.5-0.4c1-0.5,1.7-1.2,1.9-2.3
	c0.3-1.1,0.3-2.1-0.3-3c-0.4-0.8-1-1.4-1.8-1.8l-1-0.3C622.8,363.1,621.2,363,619.7,363.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M624.7,364.2c-0.8-0.3-1.8-0.3-2.6,0.1c-0.8,0.4-1.4,1.1-1.7,2.1
	c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8s1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9c0.3-1,0.3-1.8-0.3-2.6
	S625.5,364.5,624.7,364.2L624.7,364.2z M627.2,376.6c-0.8,0.1-1.5,0.6-2.2,1.1l-0.7,0.8c-1.1,1.4-1.7,2.9-1.8,4.4
	c-0.1,1.5,0.3,2.5,1.1,3.2c0.8,0.7,1.9,0.7,3.3,0.1c1.4-0.6,2.6-1.5,3.6-3l0.5-0.8c0.4-0.8,0.5-1.5,0.4-2.5
	c-0.1-1.1-0.7-1.9-1.7-2.6C629.2,376.6,628.3,376.5,627.2,376.6L627.2,376.6z M608.6,392.7l6.7-11.8c0.7-1.4,1-2.5,0.5-3.4
	c-0.1-0.4-0.3-0.8-0.5-1.1 M615.3,376.3c-0.4-0.5-1-1-1.9-1.4l-5.8-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M625.8,383.3c0.8,0.5,1.7,0.8,2.6,0.5c1-0.1,1.7-0.5,2.2-1.4
	c0.6-0.8,0.8-1.6,0.6-2.6c-0.1-1-0.5-1.6-1.4-2.2c-0.8-0.6-1.7-0.8-2.6-0.6c-1,0.1-1.7,0.5-2.2,1.4c-0.5,0.8-0.8,1.7-0.5,2.6
	C624.5,382,625,382.8,625.8,383.3L625.8,383.3z M608.2,397.6c7.3,7.3,16.1,10.9,26.4,10.9s19.1-3.7,26.4-11c4.1-4.1,7.2-8.8,8.9-14
	c1.4-3.9,2.1-8,2.1-12.5c0-2.1-0.1-4-0.4-5.9 M608.6,392.7c-0.8,1.9-1,3.6-0.4,5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M598,388.2c1.9,4.1,4.7,8,8.1,11.4c4.1,4.1,8.7,7.2,13.8,9.1
	c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2c3.2-5.8,4.8-12.2,4.8-19.3
	c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8s-20.6,4-28.5,11.8C598,350.5,594,360,594,371.1
	C594.4,377.3,595.5,382.9,598,388.2L598,388.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M615.3,376.3l-6.5,1.7c-0.8-1-1.2-2.8-1.2-5.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573,354.5c-1.4,5.2-2.1,10.9-2.1,16.6c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3
	c1.8,2.3,3.9,4.7,6.1,6.9c2.1,2.1,4.3,4,6.5,5.8c11,8.7,23.9,12.9,38.5,12.9c14.6,0,27.5-4.3,38.5-12.9c2.2-1.8,4.4-3.6,6.5-5.8
	c2.2-2.2,4.3-4.5,6.1-6.9c1-1.2,1.8-2.5,2.8-3.9c6.6-10,9.8-21.5,9.8-34.3c0-5.9-0.7-11.6-2.2-16.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M622.3,402.6c-1.7,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2
	c0.4-0.7,1-1.1,1.5-1.4c0.7-0.3,1.4-0.3,2.2,0c0.5,0.3,1.1,0.7,1.5,1.4l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9
	 M544.8,354.6l28.2-0.1 M662.9,352.9c-1.2-1.9-2.8-3.9-4.5-5.5c-4.5-4.5-9.6-7.4-15.4-8.9 M662.9,352.9l-9.1,1.8 M643,338.5
	c-0.5-2.1-1.4-3.4-2.6-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M640.4,334.1c1,1,1.7,2.3,1.8,4.1l1.5,16c0,0.8,0.1,1.5,0.4,2.1
	c0.4,0.5,0.8,1,1.4,1.1l0.3,0.1l1.7-0.1l16.1-3.7c1.7-0.4,3.2-0.3,4.4,0.4c-1.2-1-2.8-1.4-5-1.2 M643.9,347.6l-1-9.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M643.9,347.6l0.5,4.5c0.1,1.5,0.7,2.5,1.5,3c0.8,0.7,1.9,0.8,3.4,0.5
	l4.4-1 M671.6,365.2c-0.3-2.2-0.8-4.4-1.5-6.5l-0.5-1.4c-1.8-4.7-4.7-8.9-8.5-12.7c-5.9-5.9-12.8-9.4-20.6-10.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M653.8,354.7l-1.4-1.5c-2.6-2.6-5.4-4.4-8.5-5.6 M709,307.1h-0.4l-6.9,1.9
	h-9.6l-1.9,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M690.2,310H703l8.3-2.9 M671.6,365.2c-0.3,0.7-0.8,1.4-1.5,1.9
	c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8
	c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M668.1,369l1.8-1.4c0.8-0.8,1.2-1.6,1.5-2.5 M696.1,354.2h2.6
	 M698.7,354.2c0,2.3,0.3,3.4,0.8,3.2h2.6 M701.3,354.2h-2.6 M701.3,354.2l0.8,3.2 M631.8,354.6c-0.5,0.8-0.8,1.8-1,2.8l-0.1,2.8
	c0.1,0.8,0.5,1.5,1.1,2.2c0.8,0.8,1.7,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2v-1c0-0.7,0-1.2-0.1-1.8
	c-0.1-1.1-0.5-1.9-1-2.8c-0.8-1.2-1.7-1.9-2.8-1.9C633.6,352.7,632.7,353.4,631.8,354.6L631.8,354.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.2,357.1l-0.3,0.3c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1c0.7-0.7,1-1.5,1-2.3s-0.3-1.5-0.8-2.2l-0.3-0.3c-0.7-0.7-1.5-1-2.5-1
	C633.6,356.1,632.9,356.4,632.2,357.1z M644.8,363.8l-1,0.3c-0.7,0.4-1.2,1-1.7,1.8c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3
	c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3c1.7-0.5,3-1.4,4-2.5c1-1.1,1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1
	C648.1,363.1,646.5,363.1,644.8,363.8L644.8,363.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M647.2,364.5c-0.8-0.4-1.8-0.5-2.6-0.3c-1,0.3-1.5,0.8-1.9,1.8
	c-0.4,0.8-0.5,1.6-0.3,2.6c0.3,1,0.8,1.5,1.8,2.1c0.8,0.4,1.8,0.5,2.6,0.1c1-0.3,1.5-0.8,2.1-1.6c0.4-0.8,0.5-1.8,0.3-2.6
	C648.6,365.6,648.1,365,647.2,364.5L647.2,364.5z M634.6,365.9c-1.5,0-2.8,0.5-3.7,1.5c-1.1,1-1.7,2.3-1.7,3.7
	c0,1.5,0.5,2.8,1.7,3.9c1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5c1-1.1,1.5-2.3,1.5-3.9s-0.5-2.8-1.5-3.7
	C637.2,366.3,636,365.9,634.6,365.9L634.6,365.9z M642,376.6c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.7-1.4,1.5-1.5,2.6
	c-0.1,0.8,0,1.6,0.4,2.3l0.5,0.8c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1s1.2-1.6,1.1-3.2c-0.1-1.5-0.7-2.9-1.8-4.4l-0.5-0.8
	C643.5,377.2,642.8,376.7,642,376.6L642,376.6z M660.7,392.4l-6.7-12c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5l12.4-5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M660.7,392.4c5.1-6.1,7.6-13.2,7.6-21.3c0-0.7,0-1.4-0.1-2.1 M639.4,377.7
	c-0.8,0.5-1.2,1.4-1.4,2.3c-0.1,1,0,1.8,0.5,2.6c0.5,0.8,1.2,1.2,2.2,1.4c1,0.1,1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2
	c0.1-1,0-1.8-0.5-2.6c-0.5-0.8-1.2-1.2-2.2-1.4S640.2,377.2,639.4,377.7L639.4,377.7z M647.1,402.6l-9.2-10.2
	c-0.8-0.8-1.5-1.4-2.3-1.5h-0.8l-0.8,0.1c-0.8,0.1-1.5,0.7-2.3,1.5l-9.4,10.3 M690.2,310l11.1,44.2 M715.2,322.8l-4-15.7
	 M715.2,322.8l0.4,1.9 M715.6,324.7l-5.4,1.9 M710.2,326.7h8.8c2.8,0.3,4.8,1.4,6.2,3.6c0.8,0.8,1.1,4.7,0.8,11.8v0.1l-0.1,0.4
	c0,2.6-0.5,5.5-1.7,8.7l-1.9,5.4c-0.7,0.8-2.3,1.1-5.2,0.7 M739.3,363.1h1.1c1.7,0,2.6-0.5,3.2-1.8l4.3-7c1.1-2.1,1.7-5,1.7-8.8
	v-13.9c-0.1-1.2-0.8-2.3-1.9-3.3l-4-3.7c-1.5-1.4-3.3-1.9-5.1-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M738.4,322.8l5.4,4.5c1.2,1.1,2.1,2.6,2.5,4.5V348
	c-0.3,2.3-1.1,4.7-2.6,7.3l-4.5,7.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M715.6,324.7h17.7c1.2,0,2.5,0.5,3.9,1.8c1.5,1.4,2.3,3.2,2.6,5.4v16.8
	c-0.1,2.5-0.8,5.4-2.2,8.4l-2.5,5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M729.8,346.1c-0.4-1.5-0.5-3.3-0.5-5.4c0-1.9,0.1-3.6,0.5-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M729.8,335.6l0.4-1.5c0.5-1.8,1.4-2.8,2.2-2.8c0.8,0,1.7,1,2.2,2.8
	c0.5,1.8,1,4,1,6.6c0,2.6-0.3,4.8-1,6.6c-0.5,1.8-1.4,2.8-2.2,2.8c-0.8,0-1.7-1-2.2-2.8l-0.4-1.4"
          />
          <path
            fill="#FFFFFF"
            stroke="#010101"
            strokeWidth="0.5"
            d="M729.8,346.1l0.4-0.8c0.5-1.2,0.8-2.6,0.8-4.4c0-1.6-0.3-3.2-0.8-4.4
	l-0.4-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M738.4,322.8h-23.2 M716.8,357.3h0.4 M717.3,357.3l3.6,2.8l0.7,1.7
	c0.6,1,1.5,1.4,3,1.4H735 M717.3,357.3h-0.4 M735.1,363.1h4.1 M710.2,326.7l7,30.7 M622.3,402.6c3.7,1.5,7.8,2.2,12.2,2.2
	c4.5,0,8.7-0.8,12.5-2.3 M702.1,357.3h14.7 M711.2,307.1H709 M153.9,309.3h-0.8l-0.6-0.1 M166.2,329.1v-1.2 M153.9,323v5
	 M153.5,320.1l0.4,0.1 M153.9,320.2v2.8 M153.9,327.9c0.6,0.3,1,0.7,1,1.2 M153.9,309.3v10.9 M153.9,327.9h12.2 M155,329.1h11.1
	 M188.9,325.7l-1.5,3.4 M176.2,358.3l9.5-17.9c2.9-5.8,4.4-9.5,4.4-11.3h-2.8 M187.4,329.1h-21.2 M215.6,331.9l-2.6,2.6
	c-1.5,1.6-3,3.3-4.4,5.1c-1,1.2-1.9,2.6-2.8,4c-6.1,9.5-9.2,20.2-9.2,32.3c0,12,3,22.7,9.2,32.2l2.8,4c1.8,2.3,3.7,4.4,5.8,6.6
	l3.6,3.3c11.1,9.6,24.2,14.4,39.2,14.4c15.1,0,28.2-4.8,39.2-14.4c1.2-1.1,2.3-2.2,3.6-3.3c2.1-2.1,4-4.3,5.8-6.6
	c1.9-2.6,3.7-5.4,5.2-8.1c4.4-8.4,6.7-17.7,6.7-28.1c0-10.3-2.2-19.7-6.7-28.1c-1.5-2.8-3.3-5.5-5.2-8.1c-1.4-1.8-2.8-3.4-4.4-5.1
	l-2.6-2.6c-11.6-11-25.5-16.5-41.5-16.5C241,315.4,227.1,320.9,215.6,331.9L215.6,331.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M316.4,352.1c-1.5-3.7-3.3-7.2-5.5-10.6c-0.8-1.2-1.8-2.6-2.8-3.9
	c-1.4-1.8-2.9-3.7-4.5-5.4l-1.5-1.5c-12.4-12.4-27.4-18.6-45-18.6s-32.6,6.2-45,18.6l-1.5,1.5c-1.6,1.8-3.2,3.6-4.5,5.4l-0.3,0.3
	c-3.2,4.1-5.6,8.5-7.6,13.2l-2.2,6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M193.6,373.2v2.6c0,14.3,4.1,26.8,12.2,37.7l0.3,0.3
	c1.8,2.3,3.9,4.7,6.1,6.9l1.1,1.1c12.2,11.7,26.8,17.6,43.9,17.6s31.8-5.9,43.9-17.6l1.1-1.1c2.2-2.2,4.3-4.5,6.1-6.9
	c1-1.2,1.8-2.5,2.8-3.9c6.6-10,9.8-21.5,9.8-34.3c0-8.5-1.5-16.4-4.4-23.7 M196,357.5c-1.5,5-2.3,10.2-2.5,15.7 M248.6,343.2
	c0.6-1.9,1.5-3.4,2.6-4.1c-1,1-1.6,2.3-1.8,4v0.1l-1.5,16.1c0,0.8-0.1,1.5-0.4,1.9s-0.6,0.7-1,1c-0.3,0.1-0.4,0.3-0.7,0.3
	c-0.6,0.1-1.2,0-2.1-0.3l-15.7-3.6c-1.8-0.4-3.2-0.3-4.4,0.4c1.1-1,2.8-1.4,4.9-1.2 M228.6,357.8l8.9,1.8 M229.9,356l-1.2,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M223.4,374v1.9c0,8.3,2.6,15.4,7.7,21.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.3,377l-6.7-3h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M223.4,374c-1.5-1-2.5-1.9-2.9-3.2l-0.3-0.7l0.3,0.5c0.7,1,1.6,1.8,3,2.3
	h0.1l14.9,6.3c0.8,0.3,1.4,0.5,1.8,1c0.4,0.5,0.7,1.2,0.7,2.1c-0.1,0.5-0.4,1.2-0.8,1.9l-8.3,13.9c-1,1.5-1.2,2.9-1.1,4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.9,338.8l-5.8-0.4c-10.3,0-19.1,3.7-26.4,11
	c-3.9,3.9-6.6,8.1-8.5,12.7c-0.7,1.8-1.2,3.6-1.6,5.5c-0.6,2.6-0.8,5.4-0.8,8.3s0.3,5.6,0.8,8.3c1.5,6.9,4.8,12.9,10,18.2l0.1,0.1
	 M230.7,402.3c-0.4-1.4-0.3-3,0.4-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M216.9,375.8c0,6.2,1.2,11.8,3.6,17.1c1.9,4.1,4.7,8,8.1,11.4
	c4.1,4.1,8.7,7.2,13.8,9.1c4.5,1.8,9.4,2.6,14.6,2.6c5.2,0,10.2-0.8,14.6-2.6c5.1-1.9,9.6-5,13.8-9.1c2.9-2.9,5.2-5.9,7-9.2
	c3.2-5.8,4.8-12.2,4.8-19.3c0-11.1-4-20.6-11.8-28.5c-7.8-7.8-17.3-11.8-28.5-11.8c-11.1,0-20.6,4-28.5,11.8
	C220.8,355.3,216.9,364.6,216.9,375.8L216.9,375.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M237.8,381l-6.5,1.6c-0.8-1-1.2-2.8-1.2-5.5 M154.1,361.2h3.3
	 M157.4,361.2c-0.4,1.6-0.4,3,0,4.4 M176.2,358.3l-0.6,1.1c-0.7,1-2.3,1.5-5.2,1.5l-13.1,0.3 M157.4,365.6c1,3.2,4.1,5.6,9.2,7.6
	l1.2,0.4 M167.8,373.6c7.3,1.9,15.1,2.1,23.5,0.1l2.2-0.5 M196,357.5l-19.8,0.8 M285.4,357.6c2.1-0.1,3.7,0.3,5,1.2
	c-1.2-0.7-2.8-0.8-4.4-0.4l-16.1,3.7l-1.6,0.1l-0.3-0.1c-0.5-0.1-1.1-0.5-1.4-1.1c-0.3-0.5-0.4-1.2-0.4-2.1l-1.5-16
	c-0.1-1.8-0.7-3.2-1.8-4.1 M262.9,338.8c1.2,0.8,2.1,2.3,2.6,4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.5,343v0.1l1,9.1 M248.6,343.2c-5.8,1.4-10.7,4.4-15.3,8.8
	c-1.2,1.2-2.3,2.6-3.4,4 M247.5,352.3l1.1-9.1 M270.3,354.2c1.6,1,3.2,2.2,4.7,3.7l1.4,1.5 M276.4,359.4l9.1-1.8 M285.4,357.6
	c-1-1.4-1.9-2.8-3-4 M283.2,397.1c5.1-6.1,7.6-13.2,7.6-21.3c0-0.7,0-1.4-0.1-2.1 M290.7,373.7c0.8-0.4,1.4-1,1.8-1.4
	c0.8-0.8,1.2-1.6,1.5-2.5 M273.8,373.4c1-1.1,1.2-2.2,1-3.3c-0.3-1.1-1.2-1.8-2.6-2.1c-1.4-0.3-3-0.3-4.7,0.3l-1,0.3
	c-0.7,0.4-1.2,1-1.6,1.8c-0.5,1-0.5,1.9-0.3,3c0.3,1.1,1,1.8,1.9,2.3c0.8,0.4,1.5,0.5,2.5,0.4l1-0.3
	C271.6,375.4,272.8,374.5,273.8,373.4L273.8,373.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M230.7,402.3c7.3,7.3,16.1,10.9,26.4,10.9s19.1-3.7,26.4-11
	c4.3-4.1,7.2-8.8,8.9-14c1.4-3.9,2.1-8,2.1-12.5c0-2.1-0.1-4-0.4-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M294.1,369.9c-0.3-2.2-0.8-4.4-1.5-6.5L292,362c-1.8-4.7-4.7-8.9-8.5-12.7
	c-5.9-5.9-12.8-9.4-20.6-10.6 M294.1,369.9c-0.3,0.7-0.8,1.4-1.5,1.9c-0.5,0.4-1.1,0.7-1.8,1l-14.9,6.3c-0.8,0.3-1.4,0.5-1.8,1
	c-0.5,0.7-0.7,1.4-0.7,2.1c0.1,0.5,0.4,1.2,0.8,1.9l8.3,13.8c1,1.5,1.2,2.9,1,4.4c0.5-1.4,0.4-3.2-0.4-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M283.2,397.1l-6.7-12c-0.7-1.4-1-2.5-0.5-3.4c0.3-1,1.1-1.8,2.5-2.5
	l12.4-5.6 M237.6,359.5l4.4,1c1.5,0.3,2.6,0.1,3.4-0.5c0.8-0.6,1.4-1.5,1.6-3l0.6-4.7 M242.1,355.4l-2.9,2.5l-1.6,1.7 M244.6,369.2
	c-0.8,0.4-1.4,1.1-1.6,2.1c-0.3,0.8-0.3,1.8,0.1,2.6c0.4,0.8,1.1,1.5,2.1,1.8s1.8,0.3,2.6-0.3c0.8-0.4,1.5-1.1,1.8-1.9
	c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-2.1-1.6C246.2,368.6,245.4,368.8,244.6,369.2L244.6,369.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M249.7,370.4c-0.4-0.8-1-1.4-1.8-1.8l-1-0.3c-1.6-0.5-3.3-0.7-4.7-0.3
	c-1.5,0.3-2.3,1-2.6,2.1c-0.3,1.1,0,2.2,1,3.3c1,1.1,2.2,1.9,4,2.5l1,0.3c0.8,0.1,1.7,0,2.5-0.4c1-0.5,1.6-1.2,1.9-2.3
	C250.2,372.3,250.2,371.4,249.7,370.4z M257.2,357.3c-1.1,0-2.1,0.7-2.8,1.9c-0.6,0.8-0.8,1.8-1,2.8l-0.1,2.8
	c0.1,0.8,0.6,1.5,1.1,2.2c0.8,0.8,1.7,1.1,2.8,1.1s2.1-0.4,2.8-1.1c0.5-0.7,1-1.4,1.1-2.2v-1c0-0.7,0-1.2-0.1-1.8
	c-0.1-1.1-0.5-1.9-1-2.8C259.3,358,258.3,357.3,257.2,357.3L257.2,357.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M259.9,362l-0.3-0.3c-0.7-0.7-1.5-1-2.5-1s-1.8,0.3-2.3,1l-0.3,0.3
	c-0.6,0.5-0.8,1.4-0.8,2.2c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1.1,2.3,1.1c1,0,1.8-0.3,2.5-1.1c0.7-0.7,1-1.5,1-2.3
	C260.7,363.4,260.4,362.7,259.9,362L259.9,362z M266.5,352.3l0.5,4.5c0.1,1.5,0.7,2.5,1.5,3c0.8,0.7,1.9,0.8,3.4,0.5l4.4-1
	 M267.1,368.9c-1,0.3-1.5,0.8-1.9,1.8c-0.4,0.8-0.5,1.6-0.3,2.6c0.3,1,0.8,1.5,1.8,2.1c0.8,0.4,1.8,0.5,2.6,0.1
	c1-0.3,1.5-0.8,2.1-1.6c0.4-0.8,0.5-1.8,0.3-2.6c-0.3-0.8-0.8-1.5-1.6-2.1C268.9,368.8,268,368.6,267.1,368.9L267.1,368.9z
	 M257.1,370.4c-1.5,0-2.8,0.5-3.7,1.5c-1.1,1-1.6,2.3-1.6,3.7c0,1.5,0.6,2.8,1.6,3.9c1.1,1,2.3,1.5,3.7,1.5c1.5,0,2.8-0.5,3.7-1.5
	c1-1.1,1.5-2.3,1.5-3.9s-0.5-2.8-1.5-3.7C259.7,371,258.5,370.4,257.1,370.4L257.1,370.4z M260.5,384.7c-0.1,1,0,1.8,0.5,2.6
	s1.2,1.2,2.2,1.4c1,0.1,1.8-0.1,2.6-0.7c0.8-0.5,1.2-1.2,1.4-2.2s0-1.8-0.5-2.6s-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.6,0.5
	C261.1,382.9,260.7,383.8,260.5,384.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M260.4,387.1l0.5,0.8c1.1,1.5,2.2,2.5,3.6,3c1.4,0.5,2.5,0.5,3.3-0.1
	c0.8-0.7,1.2-1.6,1.1-3.2s-0.7-2.9-1.8-4.4l-0.5-0.8c-0.5-0.5-1.4-1-2.2-1.1c-1.1-0.1-2.1,0-2.9,0.7c-0.8,0.7-1.4,1.5-1.5,2.6
	C259.9,385.4,260,386.2,260.4,387.1L260.4,387.1z M253.3,387.7l0.6-0.8c0.4-0.8,0.6-1.5,0.4-2.5c-0.1-1.1-0.7-1.9-1.6-2.6
	c-0.8-0.7-1.8-0.8-2.9-0.7c-0.8,0.1-1.5,0.5-2.2,1.1l-0.7,0.8c-1.1,1.4-1.6,2.9-1.8,4.4c-0.1,1.5,0.3,2.5,1.1,3.2
	c0.8,0.7,1.9,0.7,3.3,0.1C250.9,390.2,252.2,389.3,253.3,387.7L253.3,387.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M253.1,387.2c0.6-0.8,0.8-1.7,0.6-2.6c-0.1-1-0.6-1.6-1.4-2.2
	c-0.8-0.5-1.6-0.8-2.6-0.5c-1,0.1-1.6,0.5-2.2,1.4s-0.8,1.6-0.6,2.6c0.1,1,0.6,1.6,1.4,2.2c0.8,0.6,1.6,0.8,2.6,0.6
	C251.7,388.4,252.6,388,253.1,387.2L253.1,387.2z M269.6,407.1l-9.2-10.2c-0.8-0.8-1.5-1.4-2.3-1.5h-0.8l-0.8,0.1
	c-0.8,0.1-1.5,0.7-2.3,1.5l-9.4,10.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M244.9,407.3c-1.6,1.2-3.2,1.9-4.7,1.8c1.4-0.1,2.6-0.8,3.7-2.2l10.7-12.2
	c0.4-0.7,1-1.1,1.5-1.4c0.7-0.3,1.4-0.3,2.1,0c0.5,0.3,1.1,0.7,1.5,1.4l10.6,12.1c1.1,1.4,2.5,2.1,3.9,2.3c-1.5,0.1-3-0.5-4.7-1.9
	 M231.1,397.4l6.7-11.8c0.7-1.4,1-2.5,0.6-3.4c-0.1-0.4-0.3-0.8-0.6-1.1 M237.8,381c-0.4-0.5-1.1-1-1.9-1.4l-5.8-2.6 M266.5,352.3
	c1.4,0.5,2.6,1.2,3.9,1.9 M282.4,353.6l-1.5-1.6c-4.5-4.5-9.6-7.4-15.4-8.9 M282.4,353.6l-12.1,0.5 M247.5,352.3
	c-1.9,0.8-3.7,1.8-5.5,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M244.9,407.3c3.7,1.5,7.8,2.2,12.2,2.2c4.5,0,8.7-0.8,12.5-2.3
	 M242.1,355.4l-12.2,0.5 M316.4,352.1l8.3-0.4 M329.3,350.7h-3.6c-0.7,0-1,0.3-1,1 M329.3,350.7H345 M391,343.2
	c-4.4,0-8.1-0.4-11.6-1 M379.4,342.2c-2.6-0.5-5.1-1.1-7.2-2.1 M391,343.2v7.6 M372.4,340.1c2.3,1.1,4.7,1.6,7.2,2.1 M391,350.7
	h-37.3 M372.4,340.1l-2.2-1.1c-5.5-3.2-10.6-8.7-15.3-16.5c-4.7-7.6-7.4-13.5-8.1-17.9 M353.7,350.7H345 M465.7,350.7v-7.6
	 M391,350.7h74.7 M465.7,343.2H391 M709,307.1l-1-2.3 M477.2,248.5h-8.5 M166.2,327.9c-0.1-24.3,0.7-48.4,2.3-72.2 M451.3,167.8
	l28.9,53.4 M531.1,350.7h-65.5 M153.9,304.1v5.2 M157.4,365.6l-14.7,4.3 M143.8,380.7l24.1-7.2 M153.9,295h-3.4 M154.1,361.2
	l-20.9,0.5 M463.6,213.2l-16.8-31.1c-1.1-2.1-3.4-3.2-7-3.3l-75.4-1.2c-1.1-0.1-1.9,0.1-2.3,0.7c-0.4,0.7-0.5,1.8-0.4,3.3L363,228
	c0,3.6,2.2,5.8,6.9,6.7c3.2,0.6,6.5,0.8,10,1l71.4,0.7"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M689.6,278.2
		c0,0-5.1-1.8-12-2.3c0,0-12.9-1.8-27.5-2.8c0,0-17.2-1.2-32.6-0.8c0,0-9.1,0-17.7,0.5l-5.4,0.3c0,0-6.5-0.1-13.6,2.5
		c0,0-9.2,2.6-16.6,12.2c0,0-15.8,20.1-25.4,35.4c0,0-11.8,18.2-15.5,24.9h10l-2.1,2.8h-65.5v-7.6c0,0,15.5,1.4,21-4.7
		c0,0,4.8-2.5,5.8-12c0,0,0.3-0.1,0.3-0.7s0-11.7,0-11.7v-52l-0.3-2.9l12.8,0.1l182.5,8.7L689.6,278.2z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M474.5,238.6H489
		c0,0,2.9,7.7,3.9,23.5v63.7c0,0,0,0.5-0.3,0.7c0,0-0.4,8.8-5.8,12c0,0-4,5.5-21,4.7h-74.7c0,0-14.2,0.4-21.9-4.7
		c0,0-6.7-3.7-13.6-14.7c0,0-7.7-11.7-8.8-19v-47.7l-0.1-18.3l15.3-6.3c0,0,1.4,2.3,5,3.9c0,0,4.3,1.7,14.6,1.8l70.2,0.7
		c0,0,0.3,3.7,2.9,3.3h12.8v4.7c0,0,0,1,1,1h1.2l-0.6,0.8h-1.2c0,0-1,0-0.8,1l0.4,5c0,0,0,0.7,0.8,1l0,0c0.8,0.3,0.1,3,4.8,5.5
		c0,0,5.5,2.6,9.9-2.1c0,0,3-2.6,2.2-7.6c0,0-0.8-5-5.6-6.5c0,0-2.1-0.8-4.5-0.3L474.5,238.6z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M474.5,237.5v8.8
		c0,0,0.1,1.2-1.1,1.2h-5.2c0,0-1,0.1-1-1.2v-4.4h-12.8c0,0-2.5,0.7-2.9-3.7v-21c0,0-0.4-4.1,2.3-4.1h13.8c0,0,1.9-0.4,4.3,1.1
		c0,0,4.5,3.2,4.8,6.7v11.8C476.7,232.8,477.1,235.9,474.5,237.5z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M391,350.7
		h-37.3c0,0-10.3-16-36.5-49.8l-5.8-7.3c0,0-5-6.9-14.4-10.5c0,0-7.2-3.3-19-3.9c0,0-26.6-3-60.3-1.4l-7.3,0.4c0,0-7.3,0.3-13.3,4
		c0,0-4.7,3-6.9,6.5l-1.4,2.1c0,0-5,7.8-8.1,13.8c0,0-8.9,16.6-11,21.9l19.1-0.7l-1.4,3.4h-21.3c0,0-0.4-29.6,2.3-73.5l178.3,1.5
		v46.9c0,0-0.4,3.6,5.4,13.9l3.9,6.3c0,0,3.9,5.6,5.9,7.8c0,0,4,5.5,12.5,8.9c0,0,6.1,2.2,16.6,2.2v7.3L391,350.7L391,350.7z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M463.6,213.2
		l-16.8-31.1c0,0-1.4-3.3-7-3.3l-75.4-1.2c0,0-3.6-0.8-2.8,4l1.4,45.9c0,0-0.5,4.4,3.4,6.2c0,0,2.3,1.8,13.6,2.2l71.5,0.7v-20.1
		c0,0-0.1-3.4,2.3-3.4L463.6,213.2z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M330.2,229.4v-49.1
		c0,0,0-2.6-2.2-3.9c-2.2-1.1-3.2-1.1-5-1.1h-52h-42.5h-22.6c0,0-3.3-0.4-5.4,1.5c-2.2,1.9-2.3,5.2-2.3,5.2l-2.2,24.3l-2.3,24.5
		c0,0-0.4,2.3,1,3.6c0,0,1.5,2.5,9.6,2.9h34.4h58.5h19.9c0,0,5.2,0.6,8.8-1.1C325.9,236.4,330.2,235,330.2,229.4z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="634.4"
              cy="371.2"
              r="63.6"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="256.7"
              cy="375.6"
              r="63.6"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Suv2DrRight);
