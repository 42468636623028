import React, { Component } from 'react';

class DetailsIcon extends Component {
  render() {
    const { size = 24, color = '#98989E' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM4 19V5H20L20.002 19H4Z" />
        <path d="M6 7H18V9H6V7ZM6 11H18V13H6V11ZM6 15H12V17H6V15Z" />
      </svg>
    );
  }
}

export default DetailsIcon;
