import React, { Component } from 'react';
import { getConfig } from '../../utils/helpers';
import { toastr } from 'react-redux-toastr';
import { get } from 'lodash';
import $ from 'jquery';
import { connect } from 'react-redux';
import BuyerList from './BuyerList';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  loadBuyerInviteListRequest,
  loadPrivateLotSuccess,
  loadPrivateLotFailure,
  loadClearBuyerInvites,
  loadPrivateLotEvents,
} from '../../actions';
import moment from 'moment';
import PostApiWithHeader from '../common/PostApiWithHeader';
import jstz from 'jstz';
window.$ = window.jQuery = $;
const timezone = jstz.determine().name();

const countries = [
  { value: 'arizona', label: 'Arizona' },
  { value: 'california', label: 'California' },
  { value: 'canada', label: 'Canada' },
  { value: 'connecticut', label: 'Connectcut' },
  { value: 'delaware', label: 'Delaware' },
  { value: 'district of columbia', label: 'District of Columbia' },
];

class CreatePrivateLotModal extends Component {
  state = {
    privateLotName: '',
    countries: countries,
    multiSelectValue: [],
  };

  componentDidMount() {
    const { loadBuyerInviteListRequest } = this.props;
    loadBuyerInviteListRequest();
  }

  callEventApi = (api, eventId) => {
    const { items } = this.props;

    const vehiclesPostBody = items.map(item => {
      return {
        itemId: item.itemId,
        statusId: 1,
        eventId,
      };
    });

    PostApiWithHeader(api, vehiclesPostBody)
      .then(json => {
        if (json.wsStatus === 'Error') {
          toastr.error('Error', json.wsMessage);
        } else if (json.wsStatus === 'Success') {
          toastr.success('Success', json.wsMessage);
        }
      })
      .catch(error => {});
  };

  createPrivateLotRequest = postData => {
    const { loadClearBuyerInvites } = this.props;

    const url1 =
      `${getConfig('apiRoot')}` +
      `user-event-add` +
      `?apiKey=${this.props.cookies.get('apiKey')}`;

    let body1 = postData;

    const addVehiclesUrl =
      `${getConfig('apiRoot')}` +
      `batch-items-update` +
      `?apiKey=${this.props.cookies.get('apiKey')}`;

    PostApiWithHeader(url1, body1)
      .then(json => {
        if (json.wsStatus === 'Error') {
          toastr.error('Error', json.wsMessage);
        } else if (json.wsStatus === 'Success') {
          toastr.success('Success', json.wsMessage);
          this.callEventApi(addVehiclesUrl, json.eventId);
          loadClearBuyerInvites();
        }
      })
      .catch(error => {});
  };

  handleMultiSelectChange = multiSelectValue => {
    this.setState({ multiSelectValue });
  };

  handleApply = (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  changePrivateLotName(evt) {
    this.setState({
      privateLotName: evt.target.value,
    });
  }

  createPrivateLotAction = e => {
    e.preventDefault();
    const { buyerInvites } = this.props;

    const invitedList = get(buyerInvites, 'invitedList');

    if (invitedList && invitedList.length > 0) {
      const postData = this.generatePostData(invitedList);
      this.createPrivateLotRequest(postData);
    } else {
      toastr.error(
        'Error',
        'You have not invited any buyers. Please invite at least one buyer.'
      );
    }
  };

  generatePostData = data => {
    const { year, make, model } = this.props;

    const startTime = moment().format().tz(timezone);
    const endTime = moment(startTime).add(24, 'hours').tz(timezone);
    let postDataObj = {
      eventId: 0,
      accountId: 0,
      marketplaceId: getConfig('marketplaceId'),
      eventName: `${year} ${make} ${model}`,
      description: '',
      features: '',
      eventTypeId: 6,
      eventSubTypeId: 0,
      startTime: startTime,
      endTime: endTime,
      liveStartTime: startTime,
      automated: 0,
      privateEvent: true,
      statusId: 2,
      progressStatusId: 0,
      authorizedSellerGroupIds: null,
      authorizedBuyerGroupIds: null,
      authorizedSellers: null,
      authorizedBuyers: null,
      authorizedBuyerAccountIds: [],
    };
    let buyerAccountIds = data.map(buyer => buyer.accountId);
    postDataObj.authorizedBuyerAccountIds = buyerAccountIds;
    return postDataObj;
  };

  handleModalCancel = event => {
    this.props.loadClearBuyerInvites();
  };

  render() {
    const { items, createPrivateLotSuccess, createPrivateLotError } =
      this.props;

    let responseMessage = '';
    if (
      createPrivateLotSuccess &&
      createPrivateLotSuccess.wsStatus === 'Error'
    ) {
      responseMessage = `${createPrivateLotSuccess.wsStatus}: ${createPrivateLotSuccess.wsMessage}`;
    }
    if (createPrivateLotError && createPrivateLotError.wsStatus === 'Error') {
      responseMessage = `${createPrivateLotError.wsStatus}: ${createPrivateLotError.wsMessage}`;
    } else if (
      createPrivateLotError &&
      typeof createPrivateLotError === 'string'
    ) {
      responseMessage = `Error: ${createPrivateLotError}`;
    }

    return (
      <div
        className="modal fade createPrivateLotModal"
        id="createPrivateLotModal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title">Create Private Lot</h3>
              {responseMessage ? (
                <Alert className="alertMessage" bsStyle="error">
                  {responseMessage}
                </Alert>
              ) : (
                ''
              )}
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <p>{items ? items.length : 0} vehicle(s) selected.</p>
                </div>
              </div>
              <ul className="nav nav-tabs">
                <li className="active">
                  <Link to="#yourBuyers" data-toggle="tab">
                    Your Buyers
                  </Link>
                </li>
                <li>
                  <Link to="#searchBuyers" data-toggle="tab">
                    Search for New Buyers
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id="yourBuyers">
                  <BuyerList />
                </div>
                <div className="tab-pane" id="searchBuyers">
                  <BuyerList searchByPhone="true" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.handleModalCancel}
                className="btn btn-primary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={this.createPrivateLotAction}
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    buyerClearInvites,
    buyerInvites,
    createPrivateLotError,
    createPrivateLotSuccess,
    privateLot,
  } = state.entities;

  return {
    buyerClearInvites,
    buyerInvites,
    createPrivateLotError,
    createPrivateLotSuccess,
    privateLot,
  };
};

export default connect(mapStateToProps, {
  loadBuyerInviteListRequest,
  loadPrivateLotSuccess,
  loadPrivateLotFailure,
  loadClearBuyerInvites,
  loadPrivateLotEvents,
})(CreatePrivateLotModal);
