import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { titleCase } from '../../utils/helpers';
import { dataPlaceholder } from '../../utils/constants';

let styles = {};

class TableDropdownFilter extends Component {
  getDataForDropdown = id => {
    const labels = (this.props.data || [])
      .map((item, index) => {
        return {
          key: index,
          id,
          label:
            id === 'make'
              ? titleCase(item[id])
              : id === 'seller'
              ? item.sellerName || item.sName
              : id === 'locked' && item[id] === true
              ? 'Locked'
              : id === 'locked' && item[id] === false
              ? 'Unlocked'
              : item[id] === true
              ? 'true'
              : item[id] === false
              ? 'false'
              : item[id],
          value: item[id],
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .sort((a, b) => {
        if (a.label === 'Coming Soon') return 1;
        if (b.label === 'Coming Soon') return -1;
        return null;
      })
      .sort((a, b) => {
        if (a.label === 'true') return -1;
        if (b.label === 'false') return 1;
        return null;
      });

    return uniqBy(labels, 'label');
  };

  render() {
    const placeholderText = '';
    const blankOption = (
      <option key={'blank'} value={''}>
        {placeholderText}
      </option>
    );

    const uniqueOptions = this.getDataForDropdown(this.props.column.id).map(
      item => {
        return (
          <option key={item.key} style={styles.option} value={item.value}>
            {item.label || dataPlaceholder}
          </option>
        );
      }
    );

    return (
      <select
        onChange={event => this.props.onChange(event.target.value)}
        style={{
          ...styles.select,
          backgroundImage: this.props.filter ? 'none' : null,
        }}
        value={this.props.filter ? this.props.filter.value : ''}
      >
        {[blankOption, ...uniqueOptions]}
      </select>
    );
  }
}

styles = {
  select: {
    width: '100%',
    paddingTop: 'auto',
    paddingRight: 10,
    paddingBottom: 'auto',
    paddingLeft: 10,
    height: 28,
    borderRadius: 4,
  },
  option: {},
};

TableDropdownFilter.defaultProps = {
  updateCurrentData: () => false,
};

TableDropdownFilter.propTypes = {
  column: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  updateCurrentData: PropTypes.func,
};

export default TableDropdownFilter;
