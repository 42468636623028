import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateItem } from '../../../../../../../actions';
import Layout from '../../../../templates/forms/Layout';
import Icon from '../../../../ui/icons';
import { CYAN_01, GREY_07 } from '../../../../../constants/colors';
import FormFooter from '../../../../templates/forms/formFooter';
import { AXLE_TYPES } from '../../../../../constants/common';
import Axle from './Axle';

class SelectAxle extends Component {
  state = {
    selectedAxle: null,
  };

  componentDidMount() {
    this.buildAxles();
  }

  closeAxlePage = () => {
    this.setState({ selectedAxle: null });
  };

  buildAxles() {
    const { item } = this.props;
    const existing = item.axlesData || [];
    const axles = AXLE_TYPES.map((obj, index) => {
      obj.data = existing[index] || {};
      return obj;
    });
    this.props.updateItem({ axles });
  }

  render() {
    const { selectedAxle } = this.state;
    const { axles } = this.props.item;

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            {!selectedAxle ? (
              <div>
                <div>
                  <div className="form-header form-sub-section">
                    <div
                      onClick={() => {
                        this.props.navigate('/core/wheels');
                      }}
                    >
                      <Icon name="LeftArrow" size={20} color={CYAN_01} />
                      Wheels
                    </div>
                  </div>
                  <div className="form-body-container form-sub-section">
                    <div className="form-body form-sub-section">
                      <div className="form-sub-section-title">
                        <div>Select Axle</div>
                      </div>
                      {axles &&
                        axles.map((axle, index) => {
                          const hasInfo = Object.values(axle.data || {}).some(
                            val => val || val === '0'
                          );

                          return (
                            <div
                              key={index}
                              className="form-section-navigation-card"
                              onClick={() =>
                                this.setState({ selectedAxle: axle })
                              }
                            >
                              <span>{axle.title}</span>
                              {hasInfo ? (
                                <Icon name="Checkmark" size={17} />
                              ) : (
                                <Icon
                                  name="ChevronRight"
                                  color={GREY_07}
                                  size={20}
                                />
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Axle selectedAxle={selectedAxle} goBack={this.closeAxlePage} />
            )}
            <FormFooter currentSection="s10" />
          </div>
        </div>
      </Layout>
    );
  }
}

SelectAxle.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { item } = state.core;
  return { item };
};

export default connect(
  mapStateToProps,
  { updateItem }
)(SelectAxle);
