export const RESET_PHOTOS = 'RESET_PHOTOS';
export const resetPhotos = () => ({
  type: RESET_PHOTOS,
});

export const UPDATE_PHOTOS = 'UPDATE_PHOTOS';
export const updatePhotos = data => ({
  type: UPDATE_PHOTOS,
  response: data,
});

export const UPDATE_PHOTOS_ASYNC = 'UPDATE_PHOTOS_ASYNC';
export const updatePhotosAsync = data => {
  return dispatch => {
    return dispatch(dispatch => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: UPDATE_PHOTOS,
          response: data,
        });

        resolve(data);
      });
    });
  };
};
