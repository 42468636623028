import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import { cloneDeep, find } from 'lodash';
import { updateItem } from '../../../../../../../actions';
import Layout from '../../../../templates/forms/Layout';
import FormFooter from '../../../../templates/forms/formFooter';
import SelectMenu from '../../../../ui/selectMenu';
import Icon from '../../../../ui/icons';
import { GREY_07 } from '../../../../../constants/colors';
import {
  WHEEL_STATIC_ITEMS,
  WHEEL_MEDIA_TYPES,
} from '../../../../../constants/common';
import ImageUploader from '../../ImageUploader';
import { showRow } from '../../../../../utils/helpers';

class Wheels extends Component {
  state = {
    lightBoxOpen: false,
    lightBoxIndex: 0,
    lightBoxImages: [],
    source: {
      wheels: WHEEL_MEDIA_TYPES.map(wheel => wheel.description),
      wheelSizes: WHEEL_STATIC_ITEMS.size.map(size => ({
        value: size,
        label: size,
      })),
      manufacturers: WHEEL_STATIC_ITEMS.manufacturer.map(manufacturer => ({
        value: manufacturer,
        label: manufacturer,
      })),
      depths: WHEEL_STATIC_ITEMS.depth.map(depth => ({
        value: depth,
        label: depth,
      })),
    },
  };

  componentDidMount() {
    this.buildWheels();
    this.makeLightBoxImages();
  }

  findValue(wheelIndex, entity) {
    const { item } = this.props;
    const description =
      item.wheels && item.wheels[wheelIndex] && item.wheels[wheelIndex][entity];

    return description ? { value: description, label: description } : null;
  }

  buildWheels() {
    // existing vehicles have "wheels" but new vehicles don't.
    // so we need to use the existing ones or else create what isn't there.
    const wheels = this.state.source.wheels.map(wheelCategory => {
      const existingWheel = find(this.props.item.wheels || [], {
        category: wheelCategory,
      });
      const wheelObj = existingWheel
        ? { ...existingWheel }
        : wheelCategory === 'Spare'
        ? {
            category: wheelCategory,
            photo: null,
          }
        : {
            category: wheelCategory,
            size: null,
            manufacturer: null,
            depth: null,
            photo: null,
          };

      return wheelObj;
    });
    this.props.updateItem({ wheels });
  }

  makeLightBoxImages = () => {
    const lightBoxImages = this.state.source.wheels
      .map(wheelCategory => {
        const existingPhoto = this.findExistingPhoto({
          category: 'Wheels',
          description: wheelCategory,
        });
        return existingPhoto;
      })
      .map(photoObj =>
        photoObj && photoObj.name !== '(null)' ? photoObj.name : null
      )
      .filter(item => item);

    this.setState({ lightBoxImages });
  };

  handleValue = (wheelIndex, entity, value) => {
    // update all the wheels with the selected value if they are null otherwise leave them alone
    const updatedWheels = cloneDeep(this.props.item.wheels).map(
      (wheel, index) => {
        const updatedValue =
          index === wheelIndex || wheel[entity] === null
            ? value
            : wheel[entity];

        return { ...wheel, [entity]: updatedValue };
      }
    );

    this.props.updateItem({ wheels: updatedWheels });
  };

  findExistingPhoto = photoItem => {
    const descriptionKey = `${photoItem.category}-${photoItem.description}`;
    const existingPhoto = find(this.props.item.photos, { descriptionKey });
    return existingPhoto;
  };

  openLightbox = photoItem => {
    const existingPhoto = this.findExistingPhoto(photoItem);

    if (existingPhoto) {
      const lightBoxIndex = this.state.lightBoxImages.indexOf(
        existingPhoto.name
      );
      if (lightBoxIndex > -1) {
        this.setState({ lightBoxOpen: true, lightBoxIndex });
      }
    }
  };

  closeLightbox = () => {
    this.setState({ lightBoxOpen: false, lightBoxIndex: 0 });
  };

  moveNext = () => {
    this.setState({
      lightBoxIndex:
        (this.state.lightBoxIndex + 1) % this.state.lightBoxImages.length,
    });
  };

  movePrev = () => {
    this.setState({
      lightBoxIndex:
        (this.state.lightBoxIndex + this.state.lightBoxImages.length - 1) %
        this.state.lightBoxImages.length,
    });
  };

  renderLightBox() {
    const { lightBoxOpen, lightBoxImages, lightBoxIndex } = this.state;

    return (
      lightBoxOpen && (
        <Lightbox
          mainSrc={lightBoxImages[lightBoxIndex]}
          nextSrc={lightBoxImages[(lightBoxIndex + 1) % lightBoxImages.length]}
          prevSrc={
            lightBoxImages[
              (lightBoxIndex + lightBoxImages.length - 1) %
                lightBoxImages.length
            ]
          }
          onCloseRequest={this.closeLightbox}
          onMovePrevRequest={this.movePrev}
          onMoveNextRequest={this.moveNext}
        />
      )
    );
  }

  renderWheels() {
    const { source } = this.state;

    return source.wheels.slice(0, 4).map((wheelDescription, index) => {
      return (
        <div
          key={wheelDescription}
          className="form-body-sub-item"
          style={{ marginBottom: 20 }}
        >
          <span>{wheelDescription}</span>
          <div>
            <SelectMenu
              title="Size"
              options={source.wheelSizes}
              value={this.findValue(index, 'size')}
              onChangeHandler={e => this.handleValue(index, 'size', e.value)}
              hideIndicatorSeparator={true}
            />
            <SelectMenu
              title="Manufacturer"
              options={source.manufacturers}
              value={this.findValue(index, 'manufacturer')}
              onChangeHandler={e =>
                this.handleValue(index, 'manufacturer', e.value)
              }
              hideIndicatorSeparator={true}
            />
            <SelectMenu
              title="Depth"
              options={source.depths}
              value={this.findValue(index, 'depth')}
              onChangeHandler={e => this.handleValue(index, 'depth', e.value)}
              hideIndicatorSeparator={true}
            />
            <ImageUploader
              title="Photo"
              photoItem={{
                category: 'Wheels',
                description: `${wheelDescription} Wheel`, // note "Wheel" tacked on
              }}
              onImageClick={() =>
                this.openLightbox({
                  category: 'Wheels',
                  description: `${wheelDescription} Wheel`, // note "Wheel" tacked on
                })
              }
              onPhotosChange={this.makeLightBoxImages}
            />
          </div>
        </div>
      );
    });
  }

  renderSpare() {
    const { item } = this.props;

    return (
      <div className="form-body-sub-item" style={{ marginBottom: 20 }}>
        <span>Spare</span>
        <div>
          <div className="form-checkbox-container">
            <span>Select Spare</span>
            <div className="h-100">
              <label className="form-checkbox-label mr-4">
                Yes
                <input
                  type="radio"
                  name="spare"
                  defaultChecked={item && item['spareTire'] === true}
                  onChange={() =>
                    this.props.updateItem({
                      spareTire: true,
                    })
                  }
                />
                <span className="form-checkbox-checkmark" />
              </label>
              <label className="form-checkbox-label mr-4">
                No
                <input
                  type="radio"
                  name="spare"
                  defaultChecked={item && item['spareTire'] === false}
                  onChange={() =>
                    this.props.updateItem({
                      spareTire: false,
                    })
                  }
                />
                <span className="form-checkbox-checkmark" />
              </label>
              <label className="form-checkbox-label">
                Unknown
                <input
                  type="radio"
                  name="spare"
                  defaultChecked={
                    item &&
                    (item['spareTire'] !== false || item['spareTire'] !== true)
                  }
                  onChange={() => this.props.updateItem({ spareTire: '' })}
                />
                <span className="form-checkbox-checkmark" />
              </label>
            </div>
          </div>
          <ImageUploader
            title="Photo"
            photoItem={{
              category: 'Wheels',
              description: 'Spare Wheel', // note "Wheel" tacked on
            }}
            onImageClick={() =>
              this.openLightbox({
                category: 'Wheels',
                description: 'Spare Wheel', // note "Wheel" tacked on
              })
            }
            onPhotosChange={this.makeLightBoxImages}
          />
        </div>
      </div>
    );
  }

  render() {
    const { item, coreSections } = this.props;

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Wheels</div>
            </div>
            <div className="form-body-container">
              {this.renderLightBox()}
              {item && item.wheels ? (
                <div className="form-body form-body-sub">
                  {showRow('s10:r1', coreSections) && this.renderWheels()}
                  {showRow('s10:r2', coreSections) && this.renderSpare()}
                </div>
              ) : (
                <div>loading</div>
              )}

              {showRow('s10:r3', coreSections) && item.heavyTruckOption && (
                <div
                  className="form-section-navigation-card"
                  onClick={() => {
                    this.props.navigate('/core/wheels/axles');
                  }}
                >
                  <span>Heavy Truck Axles</span>
                  <Icon name="ChevronRight" size={20} color={GREY_07} />
                </div>
              )}
            </div>
            <FormFooter currentSection="s10" />
          </div>
        </div>
      </Layout>
    );
  }
}
Wheels.propTypes = {
  updateItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { item, coreSections } = state.core;
  return { item, coreSections };
};

export default connect(mapStateToProps, { updateItem })(Wheels);
