import React, { Component } from 'react';

class CircledExclamationmarkIcon extends Component {
  render() {
    const { size = 22, color = '#FC3F47' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.453 0C4.965 0 0.5 4.486 0.5 10C0.5 15.514 4.986 20 10.5 20C16.014 20 20.5 15.514 20.5 10C20.5 4.486 15.993 0 10.453 0ZM10.5 18C6.089 18 2.5 14.411 2.5 10C2.5 5.589 6.067 2 10.453 2C14.891 2 18.5 5.589 18.5 10C18.5 14.411 14.911 18 10.5 18Z"
          fill={color}
        />
        <path d="M9.5 5H11.5V12H9.5V5ZM9.5 13H11.5V15H9.5V13Z" fill={color} />
      </svg>
    );
  }
}

export default CircledExclamationmarkIcon;
