import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import {
  loadTransportationSaveOrderId,
  loadBuyerTransportation,
  resetBuyerTransportation,
} from '../../actions';
import { compose, getConfig } from '../../utils/helpers';
import EnableTrackingModal from '../common/EnableTrackingModal';
import styles from './styles';
import $ from 'jquery';
window.$ = window.jQuery = $;

/* 03/19/2024
TODO (housekeeping) - Marking as candidate for cleanup. 
  - this component doesn't look to be utilized and has no future value.
*/
class BuyerTransportation extends Component {
  state = {
    title: 'transportation',
    shippingStatusText: '',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetBuyerTransportation();
    this.props.loadBuyerTransportation(getConfig('marketplaceId'));
  };

  handleModalOpen = (e, row) => {
    e.preventDefault();
    $('#enableTrackingModal')
      .appendTo('body')
      .modal('show');
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  render() {
    const loading = isEmpty(this.props.buyerTransportation);
    const data = this.props.buyerTransportation.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'purchaseDate',
      'shippingStatusText',
      {
        Header: 'Actions',
        id: 'buttons',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        Cell: row => {
          let showOrderIdButton = row.original.shippingStatus < 2; // TODO: - verify

          return (
            <div style={styles.buttonGroup}>
              <Button
                style={styles.button}
                onClick={() =>
                  this.handleDetailsClick(row.original.itemId, data)
                }
              >
                View Details
              </Button>
              {showOrderIdButton && (
                <div style={{ width: '100%' }}>
                  <EnableTrackingModal
                    row={row.original}
                    loadData={this.loadData}
                    loadTransportationSaveOrderId={
                      this.props.loadTransportationSaveOrderId
                    }
                  />
                  <Button
                    style={{ ...styles.button, margin: 0, padding: 3 }}
                    onClick={e => this.handleModalOpen(e, row.original)}
                  >
                    Enable Tracking
                  </Button>
                </div>
              )}
            </div>
          );
        },
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Transportation Vehicles</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { buyerTransportation } = state.entities;
  return { buyerTransportation };
};

export default compose(
  withTable,
  connect(
    mapStateToProps,
    {
      loadBuyerTransportation,
      resetBuyerTransportation,
      loadTransportationSaveOrderId,
    }
  )
)(BuyerTransportation);
