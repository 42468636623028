export const LOAD_CORE_VALDIATION_ERROR = 'LOAD_CORE_VALDIATION_ERROR';

export const loadCoreValidationError = validationError => ({
  type: LOAD_CORE_VALDIATION_ERROR,
  response: validationError,
});

export const RESET_CORE_VALIDATION_ERROR = 'RESET_CORE_VALIDATION_ERROR';

export const resetCoreValidation = () => ({
  type: RESET_CORE_VALIDATION_ERROR,
});
