import React, { Component } from 'react';
import { connect } from 'react-redux';

class Convertible2DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }
    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M320.9,271v-25.7c0-3.7,2.6-3.9,6.7-3.9c4.3,0,8.1,10,8.1,17.6c0,5.1-0.8,10.3-1.4,13.2l-0.5,2.6l5.1,0.3
	c18.6,1.1,48.1,2.2,69.6,2.9c5.6,0.1,10.6,0.3,14.7,0.4h2.8c-0.5-0.7-0.8-1.2-0.8-1.2h-7.3c-6.1,0-6.2-8.1-3.2-12
	c1.1-1.5,2.5-2.6,4.1-3.3c-1.4-5-8-28.3-8.4-30.3c-0.5-2.2-0.3-3.4,0.8-5.5s8.3-10.7,9.2-11.7s4.4-1,7.6,0.8
	c3.3,1.8,37.4,21.6,51,30.1c3,1.9,6.6,4.3,10.6,6.7c13.3,8.5,29.7,19.3,31.5,20.5c1.8-0.5,3.4-1.1,6.2-2.2c0,0,6.2,1.9,9.9,1.9h3.3
	c8.9,0,31.6-0.1,40-0.1c10,0,26,1.8,36.3,3.9c8.8,1.8,22.4,5.6,26.4,6.7c0.1,0,0.5,0.1,0.8,0.3c7.7,1.6,16.2,3.6,24.3,6.3
	c1.2,0.4,2.6,0.8,3.9,1.4c11.4,4.1,25.3,10.6,35.1,17.5c11,7.6,10.3,7.8,13.3,10.7c1.4,1.4,2.3,2.9,2.9,4.5h7.4v27.8h-5.8v-4
	c-0.4,0.4-1,0.5-1.5,0.5h-6.5c-1.9,0-4.4,1.6-6.2,6.3s-2.1,9.9-1.7,12.8c0.5,2.9,5.4,5.1,5.4,7.4c0,2.5-2.8,2.9-7.3,5
	c-4.5,2.1-9.2,1.1-13.3,1.1h-0.7h0.1c-0.1,0.5-0.1,1-0.3,1.1c-0.4,1.2-1.2,3-3.4,3c-3,0-10.7-2.6-14.3-8.9c-0.1-0.1-0.3-0.7-0.5-1.2
	h-19.3c0,0,4.4-24.5-5.6-40c-4.4-6.9-9.9-12.9-16.6-17.6c-8.7-5.9-19.5-9.6-32.9-9.6c-18.8,0-32.3,7.7-41.8,18.6
	c-2.5,2.9-4.7,5.9-6.7,9.1c-9.8,15.5-8.3,36.7-8.3,46.4c0,0-7.4-0.3-18-0.5c-18-0.6-45.4-1.2-62.6-1.1c-27.5,0.3-113.5-0.7-125.3,0
	c-2.1,0.1-4.1,0.3-6.3,0.4c-10.7,0.7-22.7,1.4-25.7,1.4c0,0-4.3-6.3-4.8-13.6v-1.9c0.3-8,1.5-20.4-4.7-33
	c-4.4-9.2-14-20.8-29.7-26.4c-5.8-2.1-12.4-3.3-19.8-3.3c-27.9,0-42,13.6-48.1,24.5c-5.8,10.3-6.7,14.7-6.7,40.3v5.1
	c0,0-25.9-5.1-38.7-7.7c-3.2-0.7-5.5-1.2-6.5-1.5c-5.4-1.5-7.3-1.7-10.7-1.7H126c-2.8,0-8.9-0.3-13.9-8.5v-8.4c0-1,0.4-1.2,1.2-1.2
	v-5.1l-1.5-0.3v-13.8l12.1-7.2c0,0,0.1-19.9,0.1-24.1c0-4.3,2.2-9.8,2.8-12.9c0.6-3.3-0.6-8.1,6.2-7.8c6.6,0.3,23.5,0.7,33.6-0.7
	c10-1.2,37.1-5.6,56.1-6.7c14-0.8,20.1-0.7,20.1-0.7s0.3,0,0.8,0.1c8.5-1.7,39.5-5.2,39.5-5.2c4.7,5.1,16.1,8.8,22.1,9.6
	C309,269.7,315.8,270.5,320.9,271L320.9,271z"
          />
          <circle fill="#656565" cx="601.2" cy="362.3" r="48.8" />
          <circle fill="#EBEBEB" cx="601.2" cy="362.3" r="36.5" />
          <circle fill="#656565" cx="246.5" cy="362.3" r="48.8" />
          <circle fill="#EBEBEB" cx="246.5" cy="362.3" r="36.5" />
          <path
            fill="#656565"
            d="M153,365.5c12.8,2.8,38.7,7.7,38.7,7.7v-5.1c0.1-25.6,1.1-30.1,6.7-40.3c6.1-10.9,20.2-24.5,48.1-24.5
	c7.4,0,14,1.2,19.8,3.3c15.7,5.5,25.3,17.2,29.7,26.4c6.1,12.7,4.8,25,4.7,33v1.9c0.3,3.6,1.4,7,2.6,9.5l-10.5,0.4
	c1.6-4.8,2.5-10,2.5-15.5c0-27-21.9-48.8-48.8-48.8s-48.8,21.9-48.8,48.8c0,5.5,1,10.7,2.6,15.7h-26.1c-2.5,0-6.7-3-9.2-6.7h-12.1
	l-5.6,2.1H129v-10.9h6.7c3.4,0,5.4,0.1,10.7,1.6C147.5,364.4,149.8,364.8,153,365.5L153,365.5z M543.5,377
	c-0.1-10.7-0.1-28.5,8.3-42c2.1-3.2,4.3-6.3,6.7-9.1c9.6-10.9,23-18.6,41.8-18.6c13.3,0,24.2,3.7,32.9,9.6
	c6.7,4.7,12.2,10.7,16.6,17.6c8.1,12.7,6.7,31.2,5.9,37.7l-7.3,1.2c0.8-3.6,1.4-7.4,1.4-11.3c0-27-21.9-48.8-48.8-48.8
	s-48.8,21.9-48.8,48.8c0,5.1,0.8,10,2.2,14.6L543.5,377L543.5,377z"
          />
          <path
            fill="#EBEBEB"
            d="M145.3,288.4c-5.4,0.1-6.9-0.3-13.2,3.6c-0.6,0.3-3.2,2.3-4.4,4c-0.8,1.8-1.2,4-1.2,7.2
	c0,5.2,2.2,12.8,9.8,12.8c5.4,0,16.1-1.2,21.9-6.2c2.3-2.1,3.9-4.7,3.9-8.1C161.9,289.9,151,288.4,145.3,288.4L145.3,288.4z"
          />
          <path
            fill="#656565"
            d="M689.7,384.9c-3,0-10.7-2.6-14.3-8.9c-0.1-0.1-0.3-0.7-0.5-1.2h3.7c6.1,0,11,5.4,15,5.9
	c-0.1,0.5-0.1,1-0.3,1.1C692.9,383.1,691.9,384.9,689.7,384.9L689.7,384.9z"
          />
          <path
            fill="#EBEBEB"
            d="M702.5,323.8c3.4,0,5.6-1.9,5-6.1c-0.5-4.1-6.7-11.3-16.2-15.7c-8.3-3.7-14.4-5.5-18.4-4
	c-0.7,0.3-1.2,0.5-1.9,1c-3.9,2.9-5.4,7.3-4.4,12c0.4,1.8,1.1,3.9,2.2,5.6c1.8,3.2,4.8,5.8,9.5,6.2
	C685.9,323.4,702.5,323.8,702.5,323.8L702.5,323.8z M537.6,319.5c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9
	c0,2.2,1.8,3.9,3.9,3.9C535.8,323.5,537.6,321.7,537.6,319.5L537.6,319.5z M662.8,337.7c0,2.1,1.7,3.9,3.9,3.9h15.5
	c2.1,0,3.9-1.6,3.9-3.9c0-2.1-1.7-3.9-3.9-3.9h-15.5C664.4,334,662.8,335.6,662.8,337.7L662.8,337.7z"
          />
          <g>
            <path
              fill="#EBEBEB"
              d="M450.8,244c-8.5-6.9-21-16-22.1-16.9c-1.2-1.1-2.6-2.2-1.1-4.3c1.2-1.6,3.7-4.7,4.4-5.5
		c4.3,2.5,11.3,6.5,18.8,11c10.3,6.1,21.7,12.8,28.2,16.9c3,1.9,6.6,4.3,10.6,6.7c13.3,8.5,29.7,19.3,31.5,20.5
		c-2.3,0.7-5,1.4-11,2.9c-8.3,2.2-14.9,3-20.4,3.3c-0.3-0.1-0.4-0.3-0.4-0.3c-0.8-1.8-26.1-23.5-33-29.9
		C455.1,247.4,453,245.8,450.8,244L450.8,244z M419.3,232.6c2.5,1.1,20.2,13.5,31,22.1l0.5,0.4c9.2,7.6,20.4,19.8,23.2,23
		c0.1,0.1,0.4,0.4,0.7,0.8h-31c-0.5-2.2-1.8-5.9-1.8-5.9c-0.7-3.4-2.5-7.6-3.6-9.4c-1.2-1.6-4.4-3.4-10.3-3.4h-1.5
		c-1.9,0.1-3.7,0.3-5.4,0.7c-0.1-0.7-0.3-1.5-0.4-1.8c-1.4-5.9-6.7-24.6-7.8-27.2C411.9,229,417,231.5,419.3,232.6L419.3,232.6z"
            />
          </g>
          <path
            fill="#656565"
            d="M363.7,292.5c1.6,1,5.4,3,7.3,5.1c0.3,0.3,0.4,0.5,0.7,1c1,1.9-0.4,4.1-2.6,4.1h-19.4c-4,0-5-2.2-5.5-5
	c0-0.3-0.1-0.4-0.1-0.7c-0.5-3,0.3-5.9,4.4-5.9h11C361,291.2,362.1,291.6,363.7,292.5L363.7,292.5z M112.9,338.6
	c3,0.4,10.3,1.6,13.3,2.2c3.9,0.8,3.3,2.9,0.7,2.9h-13.9v-5.1L112.9,338.6L112.9,338.6z M330.3,274.5c-21-1.5-38.2-3.3-55.6-5.2
	c-15.4-1.6-28.2-4-31.4-4.5c8.5-1.6,39.5-5.2,39.5-5.2c4.7,5.1,16.1,8.8,22.1,9.6c4.1,0.5,11,1.4,16.1,1.8c2.2,0.1,4.1,0.4,5.1,0.4
	C328.6,271.5,330,273.8,330.3,274.5L330.3,274.5z"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="601.2"
            cy="362.3"
            r="48.8"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="601.2"
            cy="362.3"
            r="36.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="601.2"
            cy="362.3"
            r="34.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M597,334c-9.6,1.4-17.7,7.7-21.7,16.1 M627,349.9
	c-4-8.4-12.1-14.6-21.7-16 M621.1,382.9c5.4-5.2,8.7-12.5,8.7-20.6c0-1.4-0.1-2.9-0.3-4.3 M588.5,388c3.9,1.9,8.1,2.9,12.7,2.9
	c4.7,0,9.2-1.1,13.2-3.2 M572.8,358.2c-0.1,1.4-0.3,2.8-0.3,4.1c0,8.3,3.4,15.7,9.1,20.9 M597.3,341.1c-6.5,1.2-11.8,5.2-15,10.7
	 M620.4,352.5c-2.9-5.6-8.1-9.8-14.4-11.1 M616.8,377.2c3.7-3.9,5.9-9.1,5.9-14.9v-1.5 M592.5,382c2.6,1.2,5.6,1.8,8.8,1.8
	c3,0,5.9-0.7,8.5-1.8 M579.7,360.2c0,0.7-0.1,1.4-0.1,2.1c0,5.6,2.2,10.9,5.8,14.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="601.2"
            cy="362.3"
            r="10.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="601.2"
            cy="352.1"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="601.2"
            cy="372.2"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="591.1"
            cy="362.2"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="611.2"
            cy="362.2"
            r="3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601.8,328.2c-1.7,0-5.2,1.6-5.2,1.6s1.5,11,0.4,14.3
	c-1.1,3.3-4.3,8.3-8.8,8.4c-4.5,0.1-13.8-2.3-16.8-4.3c0,0-1.9,1.5-2.9,3.4 M634.4,352.5c-0.5-1.7-3.2-4.4-3.2-4.4s-10,4.8-13.5,4.8
	s-9.2-1.5-10.7-5.8c-1.5-4.3-2.1-13.8-1.1-17.2c0,0-1.9-1.4-4.1-1.8 M621.4,391.2c1.4-1,3.2-4.4,3.2-4.4s-7.7-8.1-8.7-11.4
	c-1.1-3.3-1.4-9.2,2.2-12c3.6-2.8,12.5-6.2,16.1-6.5c0,0,0.7-2.3,0.4-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M580.7,390.6c1.4,1,5.2,1.7,5.2,1.7s5.4-9.8,8.1-11.8
	c2.8-2.1,8.3-4.1,12-1.6c3.7,2.6,9.8,9.9,11,13.3c0,0,2.5,0,4.4-1.1 M568.5,351.7c-0.5,1.6,0,5.5,0,5.5s11,2.1,13.8,4.1
	c2.8,2.1,6.6,6.6,5.2,10.9c-1.2,4.3-6.5,12.4-9.2,14.6c0,0,0.8,2.3,2.3,3.9"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="246.5"
            cy="362.3"
            r="48.8"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="246.5"
            cy="362.3"
            r="36.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="246.5"
            cy="362.3"
            r="34.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242.2,334c-9.6,1.4-17.7,7.7-21.7,16.1 M272.4,349.9
	c-4-8.4-12.1-14.6-21.7-16 M233.9,388c3.9,1.9,8.1,2.9,12.7,2.9c4.7,0,9.2-1.1,13.2-3.2 M218.2,358.2c-0.1,1.4-0.3,2.8-0.3,4.1
	c0,8.3,3.4,15.7,9.1,20.9 M242.7,341.1c-6.5,1.2-11.8,5.2-15,10.7 M265.6,352.5c-2.9-5.6-8.1-9.8-14.4-11.1 M262.1,377.2
	c3.7-3.9,5.9-9.1,5.9-14.9v-1.5 M237.7,382c2.6,1.2,5.6,1.8,8.8,1.8c3,0,5.9-0.7,8.5-1.8 M225.1,360.2c-0.1,0.7-0.1,1.4-0.1,2.1
	c0,5.6,2.2,10.9,5.8,14.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="246.5"
            cy="362.3"
            r="10.5"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="246.5"
            cy="352.1"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="246.5"
            cy="372.2"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="236.5"
            cy="362.2"
            r="3.6"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="256.6"
            cy="362.2"
            r="3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.1,328.2c-1.6,0-5.2,1.6-5.2,1.6s1.5,11,0.4,14.3
	c-1.1,3.3-4.3,8.3-8.8,8.4c-4.5,0.1-13.8-2.3-16.8-4.3c0,0-1.9,1.5-2.9,3.4 M279.8,352.5c-0.5-1.7-3.2-4.4-3.2-4.4s-10,4.8-13.5,4.8
	s-9.2-1.5-10.7-5.8c-1.5-4.3-2.1-13.8-1.1-17.2c0,0-1.9-1.4-4.1-1.8 M266.7,391.2c1.4-1,3.2-4.4,3.2-4.4s-7.7-8.1-8.7-11.4
	c-1.1-3.3-1.4-9.2,2.2-12c3.6-2.8,12.5-6.2,16.1-6.5c0,0,0.7-2.3,0.4-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226,390.6c1.4,1,5.2,1.7,5.2,1.7s5.4-9.8,8.1-11.8
	c2.8-2.1,8.3-4.1,12-1.6c3.7,2.6,9.8,9.9,11,13.3c0,0,2.5,0,4.4-1.1 M213.9,351.7c-0.6,1.6,0,5.5,0,5.5s11,2.1,13.8,4.1
	c2.8,2.1,6.6,6.6,5.2,10.9c-1.2,4.3-6.5,12.4-9.2,14.6c0,0,0.8,2.3,2.3,3.9 M443.8,279.1c0,0,35.6-0.1,41.8-0.1
	c6.1,0,13.9-0.7,24.6-3.4c10.7-2.8,10.9-2.6,17.2-5.1c0,0,6.2,1.9,9.9,1.9s33.3-0.1,43.3-0.1c10,0,26,1.8,36.3,3.9
	c10.3,2.1,27.5,7.2,27.5,7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M549.6,272.6c0,0,29.3,1.5,42.4,2.8c13.1,1.2,31.4,3.9,41.4,5.8
	c10,1.9,23,4.4,34.9,8.4c12,4,28.1,11.1,39.1,18.8c11,7.6,10.3,7.8,13.3,10.7c2.9,2.9,3.6,6.3,4.4,10.7c0.8,4.4,1.5,11,1.5,13.6
	s-1,4.7-2.9,4.7h-6.5c0,0-4.4,1.6-6.2,6.3c-1.8,4.7-2.1,9.9-1.7,12.8c0.5,2.9,5.4,5,5.4,7.4s-2.8,2.9-7.3,5
	c-4.5,2.1-9.2,1.1-13.3,1.1c-4,0-9.2-6.1-15.5-6.1h-23c0,0,4.4-24.5-5.6-40c-9.9-15.5-25.7-27.2-49.5-27.2
	c-23.8,0-38.9,12.1-48.6,27.6c-9.8,15.5-8.3,36.7-8.3,46.4c0,0-53.2-1.9-80.6-1.6c-27.5,0.3-113.5-0.7-125.3,0
	c-12,0.7-28.5,1.6-32.1,1.6c0,0-5.1-7.6-4.8-15.5c0.3-8,1.5-20.4-4.7-33c-6.1-12.7-21.6-29.6-49.5-29.6c-27.9,0-42,13.6-48.1,24.5
	c-6.1,10.9-6.7,15.3-6.7,45.4c0,0-39.9-7.7-45.1-9.2c-5.4-1.5-7.3-1.7-10.7-1.7h-10.2c-2.8,0-8.9-0.3-13.9-8.5V345
	c0,0,0.6-1.2,1.5-1.2h13.8c0,0,3.3-2.1-0.7-2.9c-3.9-0.8-14.7-2.5-14.7-2.5v-13.8l12.1-7.2c0,0,0.1-19.9,0.1-24.1
	c0-4.3,2.2-9.8,2.8-12.9c0.6-3.3-0.6-8.1,6.2-7.8c6.6,0.3,23.5,0.7,33.6-0.7c10-1.2,37.1-5.6,56.1-6.7c14-0.8,20.1-0.7,20.1-0.7
	s14.3,2.8,32.2,4.7c18,1.9,35.8,3.7,57.9,5.4c22.1,1.6,72.2,3.3,92.9,3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M693.5,380.9c0,0-0.5,4-3.7,4c-3.3,0-11.6-2.9-14.9-10 M662.8,337.7
	c0,2.1,1.7,3.9,3.9,3.9h15.5c2.1,0,3.9-1.6,3.9-3.9c0-2.1-1.7-3.9-3.9-3.9h-15.5C664.4,334,662.8,335.6,662.8,337.7L662.8,337.7z
	 M655.1,347.2h20.8c0,0,25.6,5.5,25.6,7.2s-1.5,1.6-5.6,1.6h-39.2 M673,297.9c0,0-17.6-6.5-32.2-9.9s-36.9-7-56.8-8.4
	c-19.9-1.5-57.2-1.2-63.1-1.2s-11.3-0.3-14.4-1.8 M521.4,272.7c0,0-28.6-18.8-42.2-27.4c-13.6-8.5-47.7-28.3-51-30.1
	c-3.3-1.8-6.6-1.8-7.6-0.8c-1,1-8.1,9.5-9.2,11.7c-1.1,2.1-1.2,3.3-0.8,5.5c0.5,2.2,8.3,30.1,8.3,30.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M480.4,278.8c0,0-18.4-19.3-27.8-27.1c-9.4-7.7-27.2-20.9-30.3-22.6
	c-3-1.6-6.1-2.5-11.1-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M474.7,278.8c0,0-13.8-15.4-24.5-24.1c-10.7-8.8-28.5-21-31-22.1
	c-2.5-1.1-7.4-3.6-6.3-0.7c1.1,2.9,7.6,25.4,8.1,28.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M432,217.4c0,0-2.9,3.6-4.4,5.5c-1.5,1.9-0.1,3,1.1,4.3
	c1.2,1.1,20.8,15.3,27.6,21.6c7,6.3,32.2,28.1,33,29.9c0,0,7.4,3.6,9.9,5.8s4.3,4,4.4,8.3c0.1,4.3,2.1,29.3,2.1,36.5
	c0,7.2-0.3,7-1,11.7c-0.7,4.7-2.5,15.7-3.4,18.4c-1,2.8-2.1,4.5-5.6,4.5s-121.5-1.2-131.9-1.9c-10.6-0.7-20.1-3-26.8-14.9
	c-6.7-11.8-9.9-23.7-10.9-35.5c-1-11.8,1.8-27.8,5.4-36.7 M301.1,358.9c6.2,0,27.6-0.3,31.9-0.3s8.9-3,3.4-4.8
	c-5.5-1.8-12.7-2.2-17.6-3.3c-5-1-10.3-1.2-11.6-1.8c-1.1-0.5-2.9-0.8-3.6-5.2c-0.7-4.4-10.2-43.7-56.9-43.7
	c-46.8,0-55.6,34.4-56.4,37.8c-0.8,3.6-1,7.4-3.9,7.4h-25.7c-2.9,0-3.6,2.6,2.9,4c6.5,1.5,22,2.9,28.2,2.9 M191.8,368.2
	c0,0-47.7-8.8-55.2-10.7c-7.4-1.9-19.8-3.7-24.9-3.7 M112.9,338.9v4.8 M111.6,324.7h47.6c0,0,7.2-1.6,1.1-2.9s-24.5-3.6-36.6-4.3
	 M216.7,309.7h-58.9 M139.2,286.1c0,0-9.9-9.5-10.3-12.5 M128.8,294.3l-4.4-4.3 M200.3,378h-26.1c-3.3,0-9.4-5-10.7-10.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M164.8,371.1H153l-5.6,2.2H129v-10.7 M153,371.1v-5.6 M348.5,291.2
	c-4.1,0-4.8,2.9-4.4,5.9c0.4,3,1.2,5.6,5.6,5.6h19.4c2.6,0,3.9-3,1.9-5.1c-1.9-2.1-5.6-4.1-7.3-5.1s-2.8-1.4-4.3-1.4L348.5,291.2
	L348.5,291.2z M724.8,328.9c0,0-33.8-0.1-44.7-0.5c-10.9-0.5-31-1.8-31-1.8s-16.8-23.4-48.8-23.4s-48.4,22.8-52.5,30.5
	c-4.1,7.8-6.5,15-6.5,17.6c0,2.6-0.8,6.1-5.8,6.1c-5,0-91.2-0.1-107.2-0.4s-49.2-1.2-49.2-4.3c0-3,24.1-4.4,42.8-4.8
	c18.7-0.5,86.3-1.1,120.5-1.1"
          />
          <circle
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="533.6"
            cy="319.5"
            r="3.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M669.1,316.5h-35.9 M702.5,323.8c3.4,0,5.6-1.9,5-6.1
	c-0.5-4.1-6.7-11.3-16.2-15.7c-9.6-4.4-16.4-5.9-20.2-3c-3.9,2.9-5.4,7.3-4.4,12s4.1,11.3,11.7,12
	C685.9,323.4,702.5,323.8,702.5,323.8L702.5,323.8z M704,311.1h7.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M704.7,311.1c0,0,6.3,4.3,7.4,8.1c1.1,3.9,1.4,6.7-2.6,6.7h-60.7
	 M723.6,323.6h7.4v27.8h-5.8v-3.9 M330.3,274.7c0,0-1.2-3.2-4.4-3.3c-3.2-0.1-15.1-1.4-21.2-2.2c-5.9-0.8-17.5-4.5-22.1-9.6
	c0,0-31.6,3.7-39.8,5.2 M258.8,271.1c0,0-15.3-1.8-31.2-1.8s-33.3,1.8-51.7,5.4c-18.4,3.6-28.5,6.7-34.4,9.9
	c-5.8,3.2-9.5,6.2-11.7,8.4c-2.2,2.2-3.4,5-3.4,10.2s2.2,12.8,9.8,12.8c7.6,0,25.7-2.5,25.7-14.2c0-11.8-10.9-13.3-16.6-13.2
	c-5.8,0.1-7.2-0.4-14.9,4.7 M537.7,381.3V373H302 M442,273c-0.7-3.4-2.5-7.6-3.6-9.4c-1.2-1.6-4.4-3.4-10.3-3.4
	c-5.8,0-10.3,1.1-13.3,5c-3,3.9-2.9,12,3.2,12h7.3c0,0,3.4,4.7,4.4,6.1c0,0,7.2,0.5,10.6,0.1c3.4-0.4,4-1.8,3.9-2.9
	C443.9,279.3,442,273,442,273L442,273z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M426.6,260.2c0,0-5.8,2.2-8,4.4c-2.1,2.1-4,5.1-4,5.1 M334,274.5
	c0,0,1.8-8,1.8-15.5s-3.9-17.6-8.1-17.6c-4.3,0-6.7,0.1-6.7,3.9v26.1 M155.2,301.4c0-6.3-6.6-10.5-10.6-10.5c-4,0-4.7,2.6-4.7,3.6
	c0,0.8,1.2,2.9,1.2,6.7c0,4.1-2.9,7.7-2.9,9.1c0,1.4,1.1,2.2,4.3,2.2C145.7,312.5,155.2,309.2,155.2,301.4L155.2,301.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M135.2,291.9c-1.4,0-3.9,3.9-4.4,9.2s-0.6,8.5-0.4,10.2
	c0.1,1.6,1.9,2.2,2.8,2.2s4.7-3.9,5.9-10.5C140.6,296.5,138.1,292,135.2,291.9L135.2,291.9z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M429.6,283.2
		c0,0,11.6,1.1,13.8-0.8c0,0,1.1-0.5,0.7-2.2c-0.4-1.8-2.1-7.2-2.1-7.2s-1.9-7.8-4.3-10c0,0-2.3-3-12.2-2.6c0,0-9.6,0-12.1,7.6
		c0,0-2.3,8.4,4.1,9.2h7.7L429.6,283.2z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M443.8,278.9
		l31-0.1c0,0-13.3-14.6-19.1-19.5l-4.1-3.7c0,0-17.6-14.3-31.5-22.8c0,0-2.3-1.4-5.2-2.2c0,0-2.2-0.7-1.9,0.8s3.9,10.9,8.4,29.4
		c0,0,2.9-0.8,6.9-0.7c4,0.1,8.5,0.7,10.6,4c2.1,3.3,2.8,6.7,3.3,8.5C442.4,274.4,443.8,278.9,443.8,278.9z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M632.5,316.5h36.3
		c0,0-3.2-5.4-2.3-9.8c0.8-4.4,2.6-6.9,6.5-8.7l0,0c0,0-29.6-12.7-78-17.3c0,0-24.2-2.8-72.2-2.3c0,0-12.1,0.4-16.4-1.9
		c0,0-8.5,1.9-16.4,2.3c0,0,7.8,3.4,10.9,7c3,3.6,2.8,5,2.9,6.9c0.1,1.9,1.9,30.4,1.9,30.4s0.6,10.6-0.1,13.2
		c-0.7,2.6-1.2,11.3-3.6,21.2h42.5c0,0,0.8-26.1,28.1-43.1c0,0,7.6-4.8,18.2-6.3C601.4,306.7,617.2,306.9,632.5,316.5z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="601.4"
              cy="362.2"
              r="49"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="246.4"
              cy="362.6"
              r="49"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M489.9,278.8
		c0,0-7.7,0.5-46.2,0.3c0,0,0.8,2.5,0,3c-0.8,0.5-2.2,1.4-5.2,1.5c-3,0-6.1-0.1-8.8-0.3l-3.6-4.8c0,0-52.4-1.4-94.6-3.7
		c0,0-12.9,31,1.2,63.8c0,0,5.1,13.2,12.9,18c7.8,4.8,15.7,5.2,21.9,5.4l128.2,1.8c0,0,4.7,0.8,6.1-6.1c0,0,1.8-8.4,2.3-12.5
		c0,0,1-6.7,1.2-8.5c0,0,0.3-1.5,0.4-3.2c0-1.6-0.1-8.8-0.8-19.8l-1.2-20.5c0,0,0.3-4-2.3-6.6c0,0-1.8-2.5-6.6-5.2L489.9,278.8z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M301.1,358.9
		l47.5-0.7c0,0-7.3-3.3-11-10.3c-3.7-7-5.8-10.2-8.7-20.5c0,0-4-14.7-2.6-27.6c0,0,0.4-12.5,5.2-25c0,0-30.5-2.5-64.1-6.2l-8.5,2.8
		c0,0-29.4-3.3-48.7-1.1c-19.4,2.2-42.8,4-67.5,14.2c0,0-8.4,4.7-12.1,8.4l0.6,0.4c0,0,6.2-4.4,9.6-4.1c3.4,0.1,9.4-1.1,15.3,1.9
		c0,0,5.5,2.2,6.1,9.4c0,0,1.2,5.6-4,9.8h1.5h57.8c0,0,11.1-6.9,30.3-6.5c19.1,0.4,34,8.4,44.4,22.6
		C291.9,325.8,301.5,337,301.1,358.9z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Convertible2DrRight);
