import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { 
  loadBuyerPurchased, resetBuyerPurchased, 
} from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';
import { toastr } from 'react-redux-toastr';
import TransportationModal from '../transportation/TransportationModal';

class BuyerPurchased extends Component {
  
  constructor(props) {
    super(props);
    this.loadData();
    
    this.state = {
      title: 'purchased',
      isTransportationModalOpen: false,
      itemToBeShipped: {
        itemId: '',
        vehicleZip: '',
        vin: '',
      },
    }
  }

  /*  
    componentDidMount() {
    this.loadData();
  } */

  loadData = () => {
    this.props.resetBuyerPurchased();
    this.props.loadBuyerPurchased(getConfig('marketplaceId'));
  };

  handleCompleteTransportation = checkoutUrl => {
    window.open(checkoutUrl, '_blank');
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  onShippingClick = (e) => {
    e.preventDefault();
    const rowIndex = Number(e.target.dataset.vehicleIndex);
    const rowData = this.props.buyerPurchased.data[rowIndex];
    const {itemId, vehicleYear, make, model, amount, status, vin, vehicleZip, shippingStatusId } = rowData;
    const rowDataParsed = {itemId, vehicleYear, make, model, amount, status, vin, vehicleZip, shippingStatusId }
        
    this.handleShippingClick(rowDataParsed);
  }

  handleShippingClick = async (rowData) => {
    const vehicleZip = [null,undefined].includes(rowData.vehicleZip)
      ? ''
      : rowData.vehicleZip;
    const {zipcode} = this.props.userProfile?.user;
    if (!vehicleZip) {
      toastr.error(
        'Shipping quote error',
        'Origin ZIP code not found',
        {position: 'bottom-center'}
      );
    } else {
      this.setState({
        itemToBeShipped: {...rowData, vehicleZip, shippingDetails: { shippingStatusId: rowData.shippingStatusId} },
        isTransportationModalOpen: true
      });
    }
  }

  handleShippingSubmit = (acertusFormData={}) => {
    
  }

  closeShippingModal = e => {
    
  }
  // TODO - 3.2024 - Refactor:  patterned after VehicleDetails
  renderModals = (itemToBeShipped) => {
    const {isTransportationModalOpen} = this.state;
    const {user} = this.props.userProfile;
    return (
      <TransportationModal
          itemData={itemToBeShipped}
          mpId={getConfig('marketplaceId')}
          user={user}
          isOpen={isTransportationModalOpen}
          onClose={() => this.setState({ isTransportationModalOpen: false })}
        />
    )
  }

  render() {
    const loading = isEmpty(this.props.buyerPurchased);
    const data = this.props.buyerPurchased.data || [];
    let rowNumber = -1;
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      'lotNumber',
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'buyFee',
      'seller',
      'buyer',
      'purchaseDate',
      'paymentStatus',
      'titleStatus',
      {
        Header: 'Actions',
        id: 'buttons',
        headerStyle: { background: 'none' },
        sortable: false,
        filterable: false,
        Cell: row => {
          const features = (this.props.marketplaceFeatures.features || '').split(',');
          const showShippingButton = features.includes('600') || features.includes('601');
          const showCompletePurchaseButton = !!row.original.checkoutUrl;
          const isPaymentInProgress = row.original.paymentStatusId === 2 || row.original.paymentStatus === 'In Progress';
          const isPaymentAccepted = row.original.paymentStatusId === 3 || row.original.paymentStatus === 'Accepted';
          const showGatepassButton = 
            /* Payment Status is: 'Accepted'|'In Progress'  AND  Transaction Status is 'In Progress' or Complete */
            (isPaymentAccepted || isPaymentInProgress) && 
            ([2,4].includes(row.original.transactionStatusId))
          rowNumber++;
          const isShippingRequested = false;
          return (
            <div style={styles.buttonGroup}>
              {/* "View Details" */}
              <Button
                style={styles.button}
                onClick={() =>
                  this.handleDetailsClick(row.original.itemId, data)
                }
              >
                View Details
              </Button>

              {/* buyerReceipt - "Bill of Sale" */}
              {row.original.buyerReceipt && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.buyerReceipt}
                >
                  Bill of Sale
                </a>
              )}

              {/* gatePass - "Gatepass" link */}
              {(row.original.gatePass && showGatepassButton) && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.gatePass}
                >
                  Gatepass 
                </a>
              )}

              {/* "Shipping" */}
              {showShippingButton && 
                (!isShippingRequested ? 
                  <Button 
                    style={styles.button}
                    onClick={this.onShippingClick}
                    data-vehicle-index={rowNumber}
                    title="Request Transport Service"
                  > Shipping ... </Button> :

                  <Button
                    style={{
                      ...styles.button, 
                      backgroundColor: 'lightGray', 
                      cursor: 'default', 
                      color: 'darkSlateGrey'
                    }}
                    data-vehicle-index={rowNumber}
                    title="Transport has been requested"
                  > {"Requested \u{2714}"}</Button>)
              }

              {/* Receipt Report */}
              {row.original.buyerReceiptReport && (
                <a
                  style={styles.button}
                  href={getConfig('apiRoot') + row.original.buyerReceiptReport}
                >
                  Receipt Report
                </a>
              )}
              {/* TODO - 4.16.2024 -- Remove; Transport services not handled directly in MP */}
              {showCompletePurchaseButton && (
                <div style={{ width: '100%' }}>
                  <Button
                    style={{
                      ...styles.button,
                      margin: 0,
                      padding: 4,
                      height: 'auto',
                    }}
                    onClick={e =>
                      this.handleCompleteTransportation(
                        row.original.checkoutUrl
                      )
                    }
                  >
                    Complete Transportation
                  </Button>
                </div>
              )}
            </div>
          );
        },
      },
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        {this.renderModals(this.state.itemToBeShipped)}
        <h4>Purchased Vehicles</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { 
    buyerPurchased,
    marketplaceFeatures, 
    userProfile 
  } = state.entities;
  return { 
    buyerPurchased, 
    marketplaceFeatures,
    userProfile
  };
};

export default compose(
  withTable,
  connect(mapStateToProps, { 
    loadBuyerPurchased, resetBuyerPurchased
  })
)(BuyerPurchased);
