import React, { Component } from 'react';
import { connect } from 'react-redux';

class ExtendedCabPickup2DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M517,142.1c-1.2-0.3-2.3-0.4-3.7-0.6l-2.6,16.5c6.1,1.6,10.3,4.5,12.5,8.7v0.1c6.2,13.6,12.4,29.7,18.4,48.1
	l0.1,0.6c0.4,1.5,0.3,2.8-0.6,3.7c-0.3,0.4-0.8,0.7-1.4,1l-1.2,0.4h14.9l-19.7-50.5c-4.8-11.3-6.3-18-4.5-20.1l-0.1-0.1
	C526.3,145.8,522.2,143.2,517,142.1L517,142.1z"
          />
          <path
            fill="#FFFFFF"
            d="M513.2,141.6l-5.2-0.4l-2.5,15.8c1.8,0.3,3.6,0.7,5.1,1.1L513.2,141.6L513.2,141.6z"
          />
          <path
            fill="#FFFFFF"
            d="M508,141.2l-20.6-1.2l-62.3-2.1v2.2h-8.3v-2.2l-65.8,2.2l-17.1,1.1l2.5,15.8l4.7-0.6
	c17.9-1.8,36.2-2.9,55.2-3.4c7.7-0.3,15.5-0.3,23.4-0.3h1.5c8.3,0,16.4,0.1,24.3,0.3c19,0.6,37.3,1.6,55.2,3.4l4.7,0.6L508,141.2
	L508,141.2z M404,145.5c0.7-1,1.7-1.4,2.9-1.4h28.8c1.4-0.1,2.3,0.4,2.9,1.4l0.1,0.1l1.2,3.4c0.4,1.2-0.3,1.8-1.9,1.8h-33.4
	c-1.6,0.1-2.3-0.6-1.9-1.8L404,145.5L404,145.5z M530.9,152.2c-0.4-0.8-1-1.6-1.5-2.3c-1.8,2.1-0.3,8.8,4.5,20.1l19.7,50.5h4.8
	l-0.5-1.1l0.1-0.4l-0.4-1.2v-0.1c-2.3-8.1-4.8-15.8-7.4-23.1c-5.5-15.3-11.7-28.9-18.4-40.9L530.9,152.2L530.9,152.2z"
          />
          <path
            fill="#FFFFFF"
            d="M510.6,158.1c-1.5-0.4-3.3-0.8-5.1-1.1l-4.7-0.6c-17.9-1.8-36.2-2.9-55.2-3.4c-8-0.3-16.1-0.3-24.4-0.3h-1.5
	c-8,0-15.7,0.1-23.4,0.3c-19,0.6-37.3,1.7-55.2,3.4l-4.7,0.6c-1.8,0.3-3.6,0.7-5.1,1.1c-6.1,1.6-10.3,4.5-12.5,8.7v0.1
	c-6.2,13.6-12.4,29.7-18.4,48.1c-0.7,1.8-0.5,3.3,0.3,4.3c0.5,0.7,1.4,1.1,2.6,1.4l1.8,0.1h231.9l1.8-0.1l1.2-0.4
	c0.5-0.3,1-0.6,1.4-1c0.7-0.8,1-2.1,0.5-3.7l-0.1-0.6c-6.1-18.4-12.2-34.5-18.4-48.1v-0.1C521,162.6,516.8,159.8,510.6,158.1
	L510.6,158.1z M500.9,160.1c10.9,0.8,18,3.9,21.2,9.1c6.2,13.1,12.4,28.6,18.3,46.4c0.4,1.2,0.4,2.1,0,2.6c-0.5,0.6-1.7,1-3.3,1H305
	c-1.6-0.1-2.8-0.4-3.3-1c-0.4-0.6-0.5-1.5,0-2.6c6.1-17.7,12.1-33.3,18.3-46.4c3.2-5.4,10.3-8.4,21.2-9.1c16.2-1.5,33-2.6,50.3-3.2
	l3.6-0.1c8.1-0.3,16.4-0.4,24.6-0.4h1.5c8.7,0,17.2,0.1,25.6,0.4l3.6,0.1C467.7,157.5,484.5,158.6,500.9,160.1L500.9,160.1z"
          />
          <path
            fill="#FFFFFF"
            d="M395.2,156.9h-3.6v62.3h3.6v-60.7 M395.2,156.9v1.6h51.9v-1.6c-8.4-0.3-16.9-0.4-25.6-0.4H420
	C411.5,156.5,403.3,156.6,395.2,156.9L395.2,156.9z M395.2,216.2v3h51.7v-3"
          />
          <path
            fill="#FFFFFF"
            d="M450.5,156.9h-3.6v62.3h3.6v-57.5 M548.6,222.5v-2.1h-9.8l-1.8,0.3H305.1l-1.8-0.3h-9.8v2.1 M548.6,220.5
	v2.1h10.9l-1.1-2.1h-4.7"
          />
          <path
            fill="#FFFFFF"
            d="M558.4,220.5l1.1,2.1l3.3,6.1l9.9-1.4c-4.5,0-7.2-2.8-7.8-8.1l-7,0.3L558.4,220.5L558.4,220.5z"
          />
          <path
            fill="#FFFFFF"
            d="M563.5,206.7l1.4,12.4c0.7,5.5,3.3,8.3,7.8,8.1l2.6-0.4c5.9-1,11.6-2.1,16.9-3.3c4.7-1.2,8-3,10-5.4
	c0.6-0.6,0.8-1.2,1.1-1.8c0.3-0.7,0.4-1.5,0.4-2.2c0-0.8-0.3-1.6-0.7-2.5l-5.2-10.2c-2.3-2.3-6.9-3.7-13.6-4.3
	c-6.7-0.4-12.2,0.1-16.8,1.6C564.4,199.7,563,202.3,563.5,206.7L563.5,206.7z M601,211.7c0.4,0.8,0.7,1.5,0.7,2.2s0,1.2-0.3,1.9
	c-0.3,0.6-0.5,1.1-1,1.6c-1.8,2.2-5,3.9-9.1,5c-4.8,1.1-9.9,2.1-15.1,3l-2.3,0.4c-4,0-6.5-2.3-7-7.3l-1.4-11.1c-0.3-4,1-6.3,3.6-7.2
	c4-1.4,9.1-1.9,15.1-1.5c6.1,0.6,10.2,1.8,12.2,3.9L601,211.7L601,211.7z M559.5,222.5h-10.9v5c7.7,1,12.9,4,16,9.2l-1.8-8.1
	L559.5,222.5L559.5,222.5z"
          />
          <path fill="#FFFFFF" d="M548.6,233.4v-5.9h-255v5.9l127.1-0.8" />
          <path
            fill="#FFFFFF"
            d="M548.6,227.5v7.8c10.3-0.1,16.4,5.4,18.2,16.5c1.7,10.5,2.2,23.4,1.4,39.1c-0.7,0.4-1.2,1-1.8,1.4
	c-1.7,1.6-1.8,3.4-0.7,5.4c2.6,3.3,4,7.3,4,12c0,6.9-1.2,13.8-3.4,20.6c-1.8,4.5-5,7.6-9.2,9.1l-1.9,0.5l-4.8,1c1-0.1,1.8,0.3,2.2,1
	c1,1.4,0.8,4.3-0.1,8.7c3.6-0.3,7-1.9,10.6-5l4.1-3.7c4.4-4.3,6.7-7.6,7.3-9.9c0.8-44.7,0.4-68.5-1-71.1l-0.3-0.3
	c-0.7-2.3-1.4-4.4-2.1-6.2l-2.2-8.9l-1.5-4.1l-2.8-4.5C561.5,231.6,556.3,228.4,548.6,227.5L548.6,227.5z"
          />
          <path
            fill="#FFFFFF"
            d="M548.6,235.3v-1.9l-128.1-0.8l-127.1,0.8v58.7h27.2c4,0.1,7.4-1,10.3-3.3c7.2-4.8,15.5-7.2,25-7.3h129.9
	c9.5,0.1,17.9,2.6,25,7.3c3,2.3,6.5,3.4,10.3,3.3h27.2v-56.8L548.6,235.3L548.6,235.3z M398.4,242.9c0-2.2,1.1-3.3,3.3-3.3h38.7
	c2.2,0,3.3,1.1,3.3,3.3v9.5c0,2.2-1.1,3.3-3.3,3.3h-38.7c-2.2,0-3.3-1.1-3.3-3.3L398.4,242.9L398.4,242.9z M313.1,274.9h1.9l0.3,0.1
	l1.2,1.1v0.1l0.4,0.3l1.6-1.2c0.3-0.1,0.4-0.3,0.7-0.3h1.8v0.1l-3,2.3l2.8,2.5l0.1,0.1h-2.1l-0.3-0.1l-1.4-1.2l-0.4-0.3l-2.1,1.6
	l-0.1,0.1h-2.1l0.3-0.3l3-2.3l-2.8-2.5L313.1,274.9L313.1,274.9L313.1,274.9z M309.2,274h2.1v3.7h0.5v1.6h-0.6v0.6
	c0,0.1,0,0.3-0.1,0.3h-1.6c-0.1,0-0.1-0.1-0.1-0.3v-0.3l-0.1-0.3h-5.5v-1.5l0.4-0.4L309.2,274L309.2,274z M328.2,274v3.7h0.5v1.5
	h-0.5v0.6c0,0.1,0,0.3-0.1,0.3h-1.7c-0.1,0-0.1-0.1-0.1-0.3l-0.1-0.4l-0.4-0.1h-5.1v-1.4c0-0.1,0-0.3,0.1-0.3l5.2-3.6L328.2,274
	L328.2,274z"
          />
          <path
            fill="#656565"
            d="M401.7,239.6c-2.2,0-3.3,1.1-3.3,3.3v9.5c0,2.2,1.1,3.3,3.3,3.3h38.7c2.2,0,3.3-1.1,3.3-3.3v-9.5
	c0-2.2-1.1-3.3-3.3-3.3H401.7L401.7,239.6z M400.9,247c0.3-1.2,0.8-2.1,1.4-2.5c0.1-0.1,0.1-0.1,0.3-0.1l1.5-0.3h0.4l1.4,0.3
	l0.3,0.1c0.5,0.4,1.1,1.2,1.4,2.5v5.9c0,0.4-0.3,0.8-1,1.1c-0.7,0.3-1.4,0.4-2.3,0.4s-1.8-0.1-2.3-0.4c-0.7-0.3-1-0.7-1-1.1l0.1-0.3
	L400.9,247L400.9,247z M409.1,243.2l2.1-1.6H431l1.9,1.6v8.9l-2.2,1.9h-19.3l-2.2-1.9v-8.9L409.1,243.2L409.1,243.2z M437.3,249.6
	c0.4-0.4,0.8-0.6,1.4-0.6s1.1,0.3,1.4,0.6c0.4,0.4,0.5,0.8,0.5,1.4c0,0.6-0.1,1.1-0.5,1.4c-0.4,0.4-0.8,0.6-1.4,0.6
	s-1.1-0.1-1.4-0.6c-0.4-0.4-0.5-0.8-0.5-1.4S436.9,250,437.3,249.6L437.3,249.6z M435.4,247c0.1-0.1,0.1-0.1,0.3-0.1h6.3l0.3,0.1
	l0.1,0.3l-0.1,0.3l-0.3,0.1h-6.3l-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.3C435.3,247.2,435.3,247.2,435.4,247L435.4,247z"
          />
          <path
            fill="#656565"
            d="M402.2,244.5c-0.5,0.4-1.1,1.2-1.4,2.5v5.6c0.1-0.3,0.4-0.6,0.8-0.8l0.5-0.1c-0.1-1-0.1-1.8-0.3-2.6
	C402,247.4,402,245.9,402.2,244.5L402.2,244.5z"
          />
          <path
            fill="#656565"
            d="M402.5,244.3c-0.1,0-0.1,0.1-0.3,0.1c-0.3,1.4-0.3,3-0.3,4.7c0,0.8,0.1,1.6,0.3,2.6l0.5-0.1l1.1-0.1l1.4,0.1
	l0.4,0.1h0.3l0.3-2.6c0.1-1.8,0-3.3-0.3-4.7l-0.3-0.1l-1.4-0.3h-0.4L402.5,244.3L402.5,244.3z"
          />
          <path
            fill="#656565"
            d="M407.5,247c-0.3-1.2-0.8-2.1-1.4-2.5c0.3,1.4,0.3,3,0.3,4.7l-0.3,2.6l0.4,0.1c0.5,0.3,0.8,0.4,1,0.8
	L407.5,247L407.5,247z"
          />
          <path
            fill="#656565"
            d="M406.1,251.8h-0.3v1.1l0.1,0.3c0,0.3-0.1,0.4-0.4,0.6c-0.3,0.1-0.7,0.3-1.1,0.3l-1.1-0.3
	c-0.3-0.1-0.4-0.3-0.4-0.6l0.1-0.3v-1.2l-0.5,0.1L402,252c-0.4,0.3-0.7,0.4-0.8,0.8l-0.1,0.3c0,0.4,0.3,0.8,1,1.1
	c0.7,0.3,1.5,0.4,2.3,0.4c1,0,1.6-0.1,2.3-0.4c0.7-0.3,1-0.7,1-1.1v-0.3c-0.1-0.3-0.4-0.6-1-0.8L406.1,251.8L406.1,251.8z"
          />
          <path
            fill="#656565"
            d="M405.8,252.8v-1.1l-0.4-0.1l-1.4-0.1l-1.1,0.1v1.2c0.1-0.1,0.1-0.3,0.4-0.4l1.1-0.3l1.1,0.3
	C405.5,252.7,405.7,252.7,405.8,252.8z"
          />
          <path
            fill="#656565"
            d="M406,253.1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.3l-1.1-0.3l-1.1,0.3l-0.4,0.4l-0.1,0.3c0,0.3,0.1,0.4,0.4,0.6
	l1.1,0.3c0.4,0,0.8-0.1,1.1-0.3C405.7,253.5,406,253.3,406,253.1L406,253.1z M411.2,241.5l-2.1,1.6v4.4h23.8v-4.4l-1.9-1.6
	L411.2,241.5L411.2,241.5z M416.7,243c0.5,0,0.8,0.1,1.2,0.6c0.3,0.3,0.5,0.7,0.5,1.2c0,0.4-0.1,0.8-0.5,1.2
	c-0.3,0.3-0.7,0.6-1.2,0.6c-0.4,0-0.8-0.1-1.2-0.6c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.1-0.8,0.5-1.2S416.3,243,416.7,243z
	 M412.1,243.2h2.8v0.6h-1v2.5h-0.7v-2.5h-1L412.1,243.2L412.1,243.2z M425.3,243h2.8v0.6h-1v2.5h-0.7v-2.6h-1L425.3,243L425.3,243z
	 M422.2,243.6c0.3-0.3,0.7-0.6,1.1-0.6c0.5,0,1,0.1,1.2,0.4c0.3,0.3,0.4,0.7,0.6,1.1c0,0.6-0.1,1-0.4,1.4c-0.3,0.3-0.7,0.6-1.2,0.6
	c-0.4,0-0.8-0.1-1.2-0.6c-0.3-0.3-0.5-0.7-0.5-1.2C421.8,244.3,421.9,243.9,422.2,243.6L422.2,243.6z M420.8,243h0.8l-1.2,1.9v1.2
	h-0.7V245l-1.2-1.9h1l0.8,1.2L420.8,243L420.8,243z M428.6,246.3h-0.8l1.2-3l0.1-0.1h0.8l1.2,3.2h-0.7v-0.1l-0.1-0.6h-1.2
	L428.6,246.3L428.6,246.3z"
          />
          <path
            fill="#656565"
            d="M417.9,243.6c-0.3-0.3-0.7-0.6-1.2-0.6c-0.4,0-0.8,0.1-1.2,0.6c-0.3,0.3-0.5,0.7-0.5,1.2
	c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.6,1.2,0.6c0.4,0,0.8-0.1,1.2-0.6c0.3-0.3,0.5-0.7,0.5-1.2S418.3,243.9,417.9,243.6z
	 M416.7,243.6h0.3l0.3,0.1c0.3,0.1,0.3,0.3,0.4,0.4l0.1,0.3v0.4l-0.1,0.4l-0.4,0.3h-0.5l-0.3-0.1c-0.1-0.1-0.3-0.3-0.3-0.4v-0.8
	l0.1-0.3l0.3-0.3L416.7,243.6L416.7,243.6z"
          />
          <path
            fill="#656565"
            d="M416.8,243.6h-0.3l-0.3,0.1L416,244l-0.1,0.3v0.8c0,0.1,0.1,0.3,0.3,0.4l0.3,0.1h0.5l0.4-0.3l0.1-0.4v-0.4
	l-0.1-0.3c-0.1-0.1-0.1-0.3-0.4-0.4L416.8,243.6z M412.1,243v0.7h1v2.6h0.7v-2.6h1.1V243 M428.1,243h-2.8v0.7h1v2.6h0.7v-2.6h1.1
	v-0.6 M423.4,243c-0.4,0-0.8,0.3-1.1,0.6s-0.4,0.7-0.4,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.6,1.2,0.6c0.4,0,0.8-0.1,1.2-0.6
	c0.3-0.4,0.5-0.8,0.4-1.4c0-0.4-0.1-0.8-0.5-1.1C424.2,243.2,423.8,243,423.4,243L423.4,243z M422.7,244l0.3-0.3l0.5-0.1
	c0.1,0,0.3,0.1,0.4,0.1l0.3,0.3l0.1,0.4v0.6l-0.1,0.3l-0.4,0.3h-0.5c-0.1,0-0.3-0.1-0.4-0.1l-0.3-0.4l-0.1-0.4V244L422.7,244
	L422.7,244L422.7,244z"
          />
          <path
            fill="#656565"
            d="M423,243.7l-0.3,0.3l-0.1,0.3v0.6l0.1,0.4l0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1h0.5l0.4-0.3l0.1-0.3v-0.6
	l-0.1-0.4l-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1L423,243.7L423,243.7z M421.8,243h-1l-0.7,1.4l-0.8-1.2V243h-0.8l1.2,2.1v1.2h0.7v-1.2
	 M427.8,246.3h0.8l0.3-0.7h1.2l0.1,0.6v0.1h0.8l-1.2-3.2h-0.7l-0.1,0.1L427.8,246.3L427.8,246.3z M430,245h-0.8l0.4-1.2L430,245z"
          />
          <path
            fill="#656565"
            d="M429.2,245h0.8l-0.4-1.2 M409.1,247.6v2.3l2.6,2.8h18.7l2.5-2.8v-2.3"
          />
          <path
            fill="#656565"
            d="M430.7,254l2.2-1.9v-2.2l-2.5,2.8h-18.7l-2.6-2.8v2.2l2.3,1.9"
          />
          <path
            fill="#056DA5"
            d="M438.8,248.9c-0.5,0-1.1,0.3-1.4,0.6c-0.4,0.4-0.5,0.8-0.5,1.4s0.1,1.1,0.5,1.4c0.4,0.4,0.8,0.6,1.4,0.6
	c0.5,0,1.1-0.1,1.4-0.6c0.4-0.4,0.5-0.8,0.5-1.4s-0.1-1.1-0.5-1.4C439.8,249.2,439.4,248.9,438.8,248.9z M437.7,250
	c0.3-0.3,0.5-0.4,1-0.4s0.7,0.1,1,0.4c0.3,0.3,0.4,0.7,0.4,1c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.5,0.4-1,0.4s-0.7-0.1-1-0.4
	c-0.3-0.3-0.4-0.6-0.4-1C437.3,250.6,437.5,250.3,437.7,250L437.7,250z"
          />
          <path
            fill="#656565"
            d="M438.8,249.6c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4
	c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1C439.5,249.8,439.1,249.6,438.8,249.6L438.8,249.6z M435.7,246.9
	c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.1-0.1,0.3s0,0.3,0.1,0.3l0.3,0.1h6.3l0.3-0.1l0.1-0.3l-0.1-0.3l-0.3-0.1L435.7,246.9
	L435.7,246.9z"
          />
          <path
            fill="#FFFFFF"
            d="M314.9,274.9h-2.1v0.1l2.8,2.5l-3,2.3l-0.3,0.3h2.1l0.1-0.1l2.1-1.6l0.4,0.3l1.4,1.2l0.3,0.1h2.1l-0.1-0.1
	l-2.8-2.5l3-2.3v-0.1h-1.7c-0.3,0-0.4,0.1-0.7,0.3l-1.6,1.2l-0.4-0.3V276l-1.2-1.1L314.9,274.9L314.9,274.9z M311.3,274h-2.1
	l-4.8,3.4l-0.4,0.4v1.5h5.5l0.1,0.3v0.3c0,0.1,0,0.3,0.1,0.3h1.6c0.1,0,0.1-0.1,0.1-0.3v-0.6h0.5v-1.7h-0.5v-3.4L311.3,274
	L311.3,274z M306.9,277.7l2.5-1.9v1.9H306.9L306.9,277.7z"
          />
          <path
            fill="#FFFFFF"
            d="M306.9,277.7h2.6l-0.1-1.9 M328.4,274.1V274h-2.1l-5.2,3.6c-0.1,0-0.1,0.1-0.1,0.3v1.4h5.1l0.4,0.1l0.1,0.4
	v0.1c0,0.1,0,0.3,0.1,0.3h1.6c0.1,0,0.1-0.1,0.1-0.3v-0.6h0.6v-1.5h-0.5L328.4,274.1L328.4,274.1z M324,277.7l2.5-1.9v1.9H324z"
          />
          <path
            fill="#FFFFFF"
            d="M323.8,277.7h2.6v-1.9 M548.6,227.5v-5h-255v5 M571.4,248.4c-0.4-1.2-1.4-2.2-2.6-2.9l2.2,8.9
	c1.2,0.3,1.7-0.7,1.2-2.9L571.4,248.4L571.4,248.4z M418.1,137.9H417v2.2h0.3c0.8-0.1,1.5-0.8,2.1-2.3c0.3-0.8,0.5-1.9,0.8-3.3
	l-0.3,0.8c-0.4,1.1-1,1.9-1.6,2.5L418.1,137.9L418.1,137.9z"
          />
          <path
            fill="#056DA5"
            d="M418.3,137.8l-0.3,0.1h0.1 M420.5,131.9c0-0.7,0.3-1,0.5-1.1l-0.3,0.1c-0.3,0.3-0.4,0.8-0.4,1.6l0.1-0.3
	V131.9L420.5,131.9z"
          />
          <path
            fill="#FFFFFF"
            d="M421.1,130.8c-0.3,0.1-0.5,0.4-0.5,1.1v0.7l-0.3,1.9c-0.3,1.4-0.5,2.5-0.8,3.3c-0.5,1.4-1.2,2.2-2.1,2.3h7.7
	c-0.8-0.1-1.5-0.8-2.1-2.3c-0.3-0.8-0.5-1.9-0.8-3.3c-0.1-0.6-0.3-1.2-0.3-1.9V132l-0.1-0.8l-0.1-0.3
	C421.2,130.9,421.2,130.8,421.1,130.8L421.1,130.8z"
          />
          <path
            fill="#056DA5"
            d="M420.4,132.6v0.6l-0.3,1.4L420.4,132.6c0-0.1,0.1-0.1,0.1-0.3L420.4,132.6L420.4,132.6z M421.6,132v0.6
	c0.1,0.7,0.3,1.4,0.3,1.9L421.6,132z M424,137.9h0.1l-0.3-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M424.1,137.9H424l-0.1-0.1c-0.8-0.7-1.5-1.8-1.9-3.3c0.3,1.4,0.5,2.5,0.8,3.3c0.5,1.4,1.2,2.2,2.1,2.3h0.3
	v-2.2H424.1L424.1,137.9z"
          />
          <path fill="#056DA5" d="M420,135.3l0.1-0.8l0.3-1.4" />
          <path
            fill="#FFFFFF"
            d="M328.9,141.6l-3.7,0.6c-5.2,1.1-9.4,3.6-12.4,7.7c1.9,2.1,0.4,8.7-4.5,20.2l-19.7,50.5h14.7
	c-1.2-0.3-2.1-0.7-2.6-1.4c-0.8-1-1-2.3-0.3-4.3c6.1-18.4,12.2-34.5,18.4-48.1v-0.1c2.3-4.1,6.5-7,12.5-8.7L328.9,141.6L328.9,141.6
	z"
          />
          <path
            fill="#FFFFFF"
            d="M334.1,141.2l-5.2,0.4l2.6,16.5c1.5-0.4,3.3-0.8,5.1-1.1L334.1,141.2L334.1,141.2z"
          />
          <path
            fill="#656565"
            d="M409.8,349.2l1.9-1.4h18.8l1.9,1.4H454l1.9-1.4h18l1.6,1.4h10.2c3,0.3,5-0.5,5.9-2.3l2.2-5.2l1.1-2.6
	l3.3-7.8c0.8-1.7,1.8-2.9,2.8-4l1.5-1.4c1-0.7,2.1-1.2,3.3-1.4l37.6-1.8c1.8-0.1,3-0.5,4-1.4c1-0.8,1.5-2.2,1.5-3.9v-1.8h-62.5
	c-1.4-0.1-2.8,0.1-3.9,0.5c-1.6,0.7-3,2.1-4.1,4l-6.7,13.9c-2.2,3.9-6.5,5.9-12.9,6.2h-74.8c-6.5-0.3-10.7-2.3-12.9-6.2l-6.7-13.9
	c-1.1-1.9-2.5-3.3-4.1-4c-1.1-0.5-2.3-0.7-3.9-0.5h-62.5v1.8c0,1.6,0.5,2.9,1.5,3.9c1,0.8,2.3,1.2,4,1.4l37.6,1.8
	c1.2,0.3,2.3,0.7,3.3,1.4l1.5,1.4c1,1.1,1.9,2.3,2.8,4l3.3,7.8l1.1,2.6l2.2,5.2l0.4,0.5c1.1,1.4,2.9,2.1,5.5,1.8h10.2l1.6-1.4h18
	l1.9,1.4L409.8,349.2L409.8,349.2z M413.8,342.9h14.4c11.6,0.1,22.7,0,33.4-0.4c6.5-0.8,10.6-3.2,12.7-7l5.9-12.7
	c1.8-4,4-5.9,6.5-6.1h60.9h-60.9c-2.6,0.1-4.7,2.1-6.5,6.1l-5.9,12.7c-2.1,3.9-6.2,6.2-12.7,7c-10.7,0.4-21.9,0.5-33.4,0.4
	L413.8,342.9c-11.6,0.1-22.7,0-33.4-0.4c-6.5-0.8-10.6-3.2-12.7-7l-5.9-12.7c-1.8-4-4-5.9-6.5-6.1h-60.9h60.9
	c2.6,0.1,4.7,2.1,6.5,6.1l5.9,12.7c2.1,3.9,6.3,6.2,12.7,7C391.1,342.9,402.2,343,413.8,342.9L413.8,342.9z M355.7,327.6
	c0-0.5,0.3-1.1,0.5-1.5c0.4-0.4,1-0.5,1.5-0.5s1.1,0.3,1.5,0.5c0.4,0.4,0.5,1,0.5,1.5c0,0.5-0.1,1.1-0.5,1.5s-0.8,0.5-1.5,0.5
	c-0.5,0-1.1-0.3-1.5-0.5C356,328.7,355.7,328.2,355.7,327.6z M483.4,327.6c0-0.5,0.3-1.1,0.5-1.5c0.4-0.4,1-0.5,1.5-0.5
	s1.1,0.3,1.5,0.5c0.4,0.4,0.5,1,0.5,1.5c0,0.5-0.1,1.1-0.5,1.5c-0.4,0.4-1,0.5-1.5,0.5s-1.1-0.3-1.5-0.5
	C483.5,328.7,483.4,328.2,483.4,327.6L483.4,327.6z"
          />
          <path
            fill="#656565"
            d="M356.4,326.3c-0.4,0.4-0.5,1-0.5,1.5c0,0.5,0.3,1.1,0.5,1.5c0.4,0.4,1,0.5,1.5,0.5s1.1-0.3,1.5-0.5
	c0.4-0.4,0.5-0.8,0.5-1.5c0-0.5-0.1-1.1-0.5-1.5c-0.4-0.4-0.8-0.5-1.5-0.5C357.3,325.6,356.8,325.8,356.4,326.3L356.4,326.3z
	 M484,326.3c-0.4,0.4-0.6,1-0.6,1.5c0,0.5,0.3,1.1,0.5,1.5c0.4,0.4,1,0.5,1.5,0.5s1.1-0.3,1.5-0.5c0.4-0.4,0.5-0.8,0.5-1.5
	c0-0.5-0.1-1.1-0.5-1.5c-0.4-0.4-1-0.5-1.5-0.5C484.9,325.6,484.4,325.8,484,326.3L484,326.3z"
          />
          <path fill="#FFFFFF" d="M411.7,347.9l-1.9,1.4h22.6l-1.9-1.4" />
          <path
            fill="#FFFFFF"
            d="M432.4,349.2h-74v6.9c-0.1,1.8,0,3.4,0.7,5.1l1,1.8c1,1.5,2.5,2.5,4.1,2.9l1.9,0.3H476l1.9-0.3
	c1.8-0.4,3.2-1.4,4.1-2.9l0.1-0.3l0.7-1.5l0.3-1c0.4-1.4,0.5-2.8,0.4-4.3v-6.7L432.4,349.2L432.4,349.2z M441.9,313.3l18-1.9h22.4
	v-2.1h13.2v2.1h53.2v-4.9H293.6v4.9h53.1v-2.1h13.2v2.1h22.4l18,1.9L441.9,313.3L441.9,313.3z M424.2,310.8h-5.6
	c-0.3,0-0.4-0.1-0.4-0.4v-2.1c0-0.3,0.1-0.4,0.4-0.4h5.6c0.3,0,0.4,0.1,0.4,0.4v2.1C424.8,310.7,424.7,310.8,424.2,310.8
	L424.2,310.8z"
          />
          <path
            fill="#FFFFFF"
            d="M418.6,310.8h5.6c0.3,0,0.4-0.1,0.4-0.4v-2.1c0-0.3-0.1-0.4-0.4-0.4h-5.6c-0.3,0-0.4,0.1-0.4,0.4v2.1
	C418.2,310.7,418.3,310.8,418.6,310.8L418.6,310.8z M459.7,311.4l-17.9,1.9h-41.5l-18-1.9h-22.4v3h122.4v-3"
          />
          <path
            fill="#656565"
            d="M443.9,314.4c1.9,0.1,2.9,1.1,2.9,3v19.7c0,2.1-1,3.2-3,3.2h-45.3c-2.1,0-3-1.1-3-3.2v-19.7c0-1.9,1-3,2.9-3
	h-105v1.2h62.5c1.4-0.1,2.8,0.1,3.9,0.5c1.7,0.7,3,2.1,4.1,4l6.7,13.9c2.2,3.9,6.5,5.9,12.9,6.2h74.8c6.5-0.3,10.7-2.3,12.9-6.2
	l6.7-13.9c1.1-1.9,2.5-3.3,4.1-4c1.1-0.5,2.5-0.7,3.9-0.5h62.5v-1.2L443.9,314.4L443.9,314.4z M376.9,318.1h9.6
	c1.8,0,3.2,0.7,4.4,1.9c1.2,1.2,1.8,2.8,1.8,4.5s-0.5,3.3-1.8,4.5c-1.2,1.2-2.8,1.9-4.4,1.9h-9.6c-1.8,0-3.2-0.7-4.4-1.9
	s-1.8-2.8-1.8-4.5s0.5-3.3,1.8-4.5C373.6,318.8,375.1,318.1,376.9,318.1z"
          />
          <path
            fill="#FFFFFF"
            d="M386.6,318.1h-9.6c-1.8,0-3.2,0.7-4.4,1.9c-1.2,1.2-1.8,2.8-1.8,4.5s0.5,3.3,1.8,4.5
	c1.2,1.2,2.6,1.9,4.4,1.9h9.6c1.8,0,3.2-0.7,4.4-1.9s1.8-2.8,1.8-4.5s-0.5-3.3-1.8-4.5C389.7,318.8,388.2,318.1,386.6,318.1z
	 M376.9,319.1h9.6c1.4,0,2.8,0.5,3.7,1.6s1.5,2.3,1.5,3.9c0,1.5-0.5,2.9-1.5,4c-1.1,1.1-2.3,1.7-3.7,1.7h-9.6
	c-1.4,0-2.8-0.5-3.7-1.7c-1-1.1-1.5-2.3-1.5-4c0-1.5,0.5-2.9,1.5-3.9C374.2,319.7,375.4,319.1,376.9,319.1L376.9,319.1z"
          />
          <path
            fill="#656565"
            d="M386.6,319.1h-9.6c-1.4,0-2.8,0.5-3.7,1.6c-1,1.1-1.5,2.3-1.5,3.9c0,1.5,0.5,2.9,1.5,4
	c1,1.1,2.3,1.7,3.7,1.7h9.6c1.4,0,2.8-0.5,3.7-1.7c1-1.1,1.5-2.3,1.5-4c0-1.5-0.5-2.9-1.5-3.9C389.2,319.7,387.9,319.1,386.6,319.1
	L386.6,319.1z M446.8,317.6c0-1.9-1-3-2.9-3h-45.7c-1.9,0.1-2.9,1.1-2.9,3v19.7c0,2.1,1,3.2,3,3.2h45.3c2.1,0,3-1.1,3-3.2v-19.7
	L446.8,317.6L446.8,317.6z M443.8,316.3c1.1,0,1.6,0.5,1.6,1.6v19.4c0,1.1-0.5,1.6-1.6,1.6h-45.3c-1.1,0-1.6-0.5-1.6-1.6V318
	c0-1.1,0.5-1.6,1.6-1.6L443.8,316.3L443.8,316.3z"
          />
          <path
            fill="#656565"
            d="M445.4,318c0-1.1-0.5-1.6-1.6-1.6h-45.3c-1.1,0-1.6,0.5-1.6,1.6v19.4c0,1.1,0.5,1.6,1.6,1.6h45.3
	c1.1,0,1.6-0.5,1.6-1.6L445.4,318L445.4,318z"
          />
          <path
            fill="#FFFFFF"
            d="M455.7,347.9l-1.9,1.4h21.6l-1.6-1.4 M331.1,289c-3,2.3-6.5,3.4-10.3,3.3h-27.2v14.2h255v-14.2h-27.2
	c-4,0.1-7.4-1-10.3-3.3c-7.2-4.8-15.5-7.2-25-7.3H356.2C346.7,281.8,338.3,284.2,331.1,289L331.1,289z M451.1,288.7h3.7l5.9,3.9
	l5.9-3.9h-0.7h4.4v7.2h-4.3v-3.4l-5.2,3.4h-0.1l-5.2-3.4v3.4h-4.3v-7.2L451.1,288.7L451.1,288.7z M443.2,294.3l-3.2,1.6h-10.9
	l-3.2-1.6v-3.9l3.3-1.8h10.5l3.3,1.8L443.2,294.3L443.2,294.3z M477.5,296v-2.9l3.3-4.3H491l3.3,4.3v2.9h0.5h-5.1v-1.4h-7.6v1.4
	L477.5,296L477.5,296z M405.1,288.7h11.7l1.9,2.2h-12v2.9h12L417,296h-12l-3.2-1.6v-3.9L405.1,288.7L405.1,288.7z M364,291.3v4.5
	h-3.7v-4.5H354l-2.1-2.6h20.5l-2.3,2.6H364L364,291.3z M382.3,294.5v1.4h-4.5V293l3.3-4.3h10.2l3.3,4.3v2.9H390v-1.4L382.3,294.5
	L382.3,294.5z"
          />
          <path
            fill="#FFFFFF"
            d="M454.8,288.7h-3.7v7.3h4.3v-3.4l5.2,3.3l5.4-3.3v3.4h4.3v-7.3h-3.7l-5.9,3.9"
          />
          <path fill="#056DA5" d="M460.7,296l-0.1-0.1v0.1" />
          <path
            fill="#FFFFFF"
            d="M440.2,296l3.2-1.6v-3.9l-3.3-1.8h-10.5l-3.3,1.8v3.9l3.2,1.6L440.2,296L440.2,296z M431.1,293.8v-2.9h7.2
	v2.9H431.1L431.1,293.8z"
          />
          <path
            fill="#FFFFFF"
            d="M431.1,291v2.8h7.2V291 M477.5,293v2.9h4.5v-1.4h7.6v1.4h4.5V293l-3.3-4.3h-10.2L477.5,293L477.5,293z
	 M488.5,290.9l1.2,1.5h-7.8l1.2-1.5L488.5,290.9L488.5,290.9z"
          />
          <path
            fill="#FFFFFF"
            d="M489.7,292.4l-1.2-1.5h-5.2l-1.4,1.5 M416.8,288.7h-11.7l-3.3,1.8v3.9l3.2,1.6h12l1.8-2.2h-12V291h12
	 M364,296v-4.7h6.3l2.2-2.6H352l1.9,2.6h6.3v4.7 M382.3,296v-1.4h7.6v1.4h4.5v-2.9l-3.3-4.3h-10l-3.3,4.3v2.9L382.3,296L382.3,296z
	 M382.3,292.4l1.2-1.5h5.2l1.2,1.5H382.3z"
          />
          <path
            fill="#FFFFFF"
            d="M383.5,290.9l-1.2,1.5h7.7l-1.2-1.5 M339.8,326c-1-0.7-2.1-1.2-3.3-1.4l-37.6-1.8c-1.2,0.1-2.6,0-4-0.4
	l-1.4-0.6h-0.1l-1.2-1.1c-1.1-1.2-1.5-2.9-1.5-5.1v-18h-1.4v18.3c0,0.8,0,1.4,0.1,2.1c0.3,0.8,0.5,1.8,1.1,2.6l0.3,0.5l-0.3-0.5
	c-0.4-0.8-0.8-1.8-1.1-2.6c-0.1-0.7-0.1-1.4-0.1-2.1v-15.3h-8c-3.7,0.1-5.8,1.8-5.9,5.2c-0.7,5.5-0.1,11.7,1.9,18.4
	c1.8,4,4.7,7,8.5,8.8l2.2,0.8c1.2,0.4,2.5,0.8,3.7,1l22.3,2.2l31.4,1.9l-2.6-6.1c-0.8-1.6-1.8-2.9-2.8-4l-1.5-1.4
	c-1-0.7-2.1-1.2-3.3-1.4l-38-1.8c-2.6-0.1-4.7-1.1-6.2-2.9c1.6,1.8,3.7,2.8,6.2,2.9l38,1.8c1.2,0.3,2.3,0.7,3.3,1.4l1.5,1.4
	c1,1.1,1.9,2.3,2.8,4l2.6,6.1l1.9,0.1l-3.3-7.8c-0.8-1.6-1.8-2.9-2.8-4L339.8,326L339.8,326z M284.3,304.1c0.5,0,1,0.1,1.4,0.5
	s0.5,0.8,0.5,1.4c0,0.6-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5s-1-0.3-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4
	C283.4,304.2,283.8,304.1,284.3,304.1L284.3,304.1z"
          />
          <path
            fill="#FFFFFF"
            d="M285.7,304.7c-0.4-0.4-0.8-0.5-1.4-0.5s-1,0.1-1.4,0.5c-0.4,0.4-0.5,0.8-0.5,1.4c0,0.6,0.1,1,0.5,1.4
	c0.4,0.4,0.8,0.5,1.4,0.5s1-0.3,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4C286.3,305.5,286.1,305.1,285.7,304.7L285.7,304.7z M346.7,309.3
	v5.1h13.2v-5.1 M346.7,314.4v-3h-53.1v3 M314.1,342.4h1l11,0.7l6.9,0.4l13.5,0.8h1l0.8-2.3l-12.1-0.5l-22-1.5l-24.4-2.5
	c-2.9-0.5-4.4,0-4.7,1.6l1.9,0.5l4.8,1L314.1,342.4L314.1,342.4z"
          />
          <path
            fill="#FFFFFF"
            d="M347.3,339l-1.9-0.1l-31.4-1.9l-22.3-2.2c-1.2-0.1-2.5-0.4-3.7-1l-2.2-0.8c-3.9-1.8-6.6-4.8-8.5-8.8
	c-2.1-6.7-2.6-12.9-1.9-18.4c0.1-3.3,2.2-5.1,5.9-5.2h8v-2.9h-13.1c-2.6,3.3-4,7.3-4,12c0,6.9,1.1,13.8,3.4,20.6
	c1.8,4.5,5,7.6,9.2,9.1c0.3-1.6,1.8-2.2,4.7-1.6l24.4,2.5l22,1.5l12.1,0.5l0.1-0.5L347.3,339L347.3,339z M368.4,347.9l-1.6,1.4h21.5
	l-1.8-1.4 M364.1,365.9c-1.8-0.4-3.2-1.4-4.1-2.9v4.1h0.3L364.1,365.9L364.1,365.9z M341.4,364.6c-0.1-0.5-0.3-0.8-0.7-1.2v1.6
	c0,0.3-0.1,0.4-0.4,0.4h-7.7l-7.7,1c-1.2,0.1-2.5,0.4-3.6,0.7h19.4C341.4,366.4,341.7,365.6,341.4,364.6L341.4,364.6z"
          />
          <path fill="#056DA5" d="M354.8,378.4h-0.5l0.1,0.5" />
          <path fill="#FFFFFF" d="M324.9,366.4l7.7-1h-13.2" />
          <path
            fill="#FFFFFF"
            d="M319.4,365.5h-5.2v1.6h7.2c1.1-0.3,2.3-0.5,3.6-0.7L319.4,365.5z M582.2,275.8c-0.8-6.7-3.9-11.7-8.8-14.9
	c1.4,2.6,1.8,26.4,1,71.1c-0.5,2.3-2.9,5.8-7.3,9.9l-4.1,3.7c-3.6,3-7.2,4.7-10.6,5v0.4c0.8,0.4,1.4,1,1.4,1.6v1h0.3
	c3.7,0.1,7-0.8,9.9-2.9c3.7-3.3,6.6-6.2,8.8-8.9c1.9-2.5,3.3-4.7,4-6.6l2.5-20.4C581.3,300.3,582.3,287.2,582.2,275.8L582.2,275.8z
	 M552.6,292.3h-4v5.4h15.8l-1.4-3.4l1.1-1.6 M551.4,297.6h-2.8v12.1h2.8"
          />
          <path
            fill="#FFFFFF"
            d="M552.7,297.6v3h8c3.7,0.1,5.8,1.8,5.9,5.2c0.8,5.5,0.1,11.7-1.9,18.4c-1.8,4-4.7,7-8.5,8.8L554,334
	c-1.2,0.4-2.5,0.8-3.7,1l-22.3,2.2l-31.4,1.8l-1.9,0.1l-1.1,2.6l0.3,0.5l12.1-0.5l22-1.5l24.3-2.5c2.9-0.5,4.4,0,4.7,1.6
	c4.3-1.5,7.4-4.5,9.2-9.1c2.3-6.9,3.4-13.8,3.4-20.6c0-4.7-1.4-8.7-4-12L552.7,297.6L552.7,297.6L552.7,297.6z"
          />
          <path
            fill="#FFFFFF"
            d="M552.7,300.7v-3h-1.4v17.9c0,2.2-0.5,3.9-1.5,5.1l-1,0.8l-0.3,0.3h-0.1l-1.2,0.5c-1.4,0.5-2.8,0.7-4,0.4
	l-37.6,1.8c-1.2,0.3-2.3,0.7-3.3,1.4l-1.5,1.4c-1,1.1-1.9,2.3-2.8,4l-3.3,7.8l1.9-0.1l2.6-6.1c0.8-1.6,1.6-2.9,2.8-4
	c0.5-0.5,1-1,1.5-1.4c1-0.7,2.1-1.2,3.3-1.4l38-1.8c2.6-0.1,4.7-1.1,6.2-2.9c-1.7,1.8-3.7,2.8-6.2,2.9l-38,1.8
	c-1.2,0.3-2.3,0.7-3.3,1.4c-0.5,0.4-1,0.8-1.5,1.4c-1,1.1-1.9,2.3-2.8,4l-2.8,6.1L528,337l22.3-2.2c1.2-0.1,2.5-0.4,3.7-1l2.2-0.8
	c3.9-1.9,6.6-4.8,8.5-8.8c2.1-6.7,2.6-12.9,1.9-18.4c-0.1-3.4-2.2-5.1-5.9-5.2h-8v15.3l-0.1,2.1c-0.3,0.8-0.5,1.8-1.1,2.6l-0.3,0.5
	l0.3-0.5c0.4-0.8,0.8-1.8,1.1-2.6l0.1-2.1L552.7,300.7L552.7,300.7z M557.8,304.1c0.5,0,1,0.1,1.4,0.5c0.4,0.4,0.5,0.8,0.5,1.4
	c0,0.6-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5c-0.5,0-1-0.3-1.4-0.5c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4
	C556.7,304.2,557.3,304.1,557.8,304.1L557.8,304.1z"
          />
          <path
            fill="#FFFFFF"
            d="M559.2,304.7c-0.4-0.4-0.8-0.5-1.4-0.5c-0.5,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4c0,0.6,0.1,1,0.5,1.4
	c0.4,0.4,0.8,0.5,1.4,0.5c0.5,0,1-0.3,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4C559.7,305.5,559.5,305.1,559.2,304.7L559.2,304.7z
	 M565.8,297.6c-1.2-1.9-1-3.7,0.7-5.4h-1.9l-0.4,0.3l-1.2,1.6l1.4,3.6h1.5L565.8,297.6L565.8,297.6z"
          />
          <path fill="#056DA5" d="M564.1,292.5l0.4-0.3h-12" />
          <path
            fill="#FFFFFF"
            d="M495.5,311.4v-2.1h-13.2v5.1h13.2 M495.5,311.4v3h53.1v-3 M495.6,344.4v-0.1V344.4l13.3-0.8l7-0.4l11-0.7h1
	l22.3-1.4l4.8-1l1.9-0.5c-0.3-1.6-1.8-2.2-4.7-1.6l-24.3,2.5l-22,1.5l-12.1,0.5l0.8,2.3L495.6,344.4L495.6,344.4z M495.6,350.5
	l-11.8-0.4v6.1c0.1,1.4,0,2.9-0.4,4.3l-0.3,1l-0.7,1.5l13.2,0.3l5.6,0.1h2.6l22.8,0.3l1.4,0.4c0.8,0.4,1.4,0.8,1.5,1.2l0.5-15
	c-0.4,0.8-1.1,1.4-2.1,1.5h-1.4l-22.4-0.8L495.6,350.5L495.6,350.5z"
          />
          <path
            fill="#FFFFFF"
            d="M516,343.2c3.3,0.4,6.1,1.2,8,2.3c0.4,0.3,0.8,0.4,1.4,0.3l0.3-0.1c0.5-0.1,1.1-0.6,1.4-1
	c0.3-0.5,0.4-1.1,0.3-1.6l-0.3-0.5L516,343.2L516,343.2z M548.6,309.7v3.2h2.8v-3.2"
          />
          <path
            fill="#FFFFFF"
            d="M551.4,312.9h-2.8v4.5c0,1.6-0.5,2.9-1.5,3.9c-1,0.8-2.3,1.2-4,1.4c1.2,0.1,2.6,0,4-0.4l1.2-0.5h0.1l0.3-0.3
	l1-0.8c1-1.2,1.5-2.9,1.5-5.1L551.4,312.9L551.4,312.9z M528,342.4v9.2c1-0.1,1.7-0.7,2.1-1.5l22,0.8l0.3,0.1v-0.4
	c1-4.4,1.1-7.3,0.1-8.7c-0.6-0.8-1.2-1.1-2.2-1L528,342.4L528,342.4z"
          />
          <path
            fill="#FFFFFF"
            d="M553.8,352.7c-0.1-0.7-0.5-1.2-1.4-1.6l-0.3-0.1l-22-0.8l-0.5,15l22.7,1.1c0.7-0.3,1.1-1,1.2-2.1l0.3-10.5
	L553.8,352.7L553.8,352.7L553.8,352.7z M522.7,365.5h-13.2l7.7,1"
          />
          <path
            fill="#056DA5"
            d="M504.3,363.3l-0.1-0.1 M502,363h-0.7v0.4l0.3-0.1"
          />
          <path
            fill="#FFFFFF"
            d="M482,367.1h0.3V363c-1,1.5-2.5,2.5-4.1,2.9L482,367.1L482,367.1z M311.2,152.2l-0.7,1.4
	c-10.2,17.7-18.7,39.1-25.9,64l-0.4,1.4l0.1,0.4l-0.5,1.1h5l19.7-50.5c5-11.4,6.5-18.2,4.5-20.2
	C312.3,150.6,311.7,151.4,311.2,152.2L311.2,152.2z M238.8,216.2c0.3,0.6,0.7,1.2,1.1,1.8c2.1,2.3,5.4,4.1,10,5.4
	c5.4,1.2,11,2.3,16.9,3.3l2.6,0.4c4.5,0,7.2-2.8,7.8-8.1l1.4-12.4c0.3-4.4-1.1-7-4-7.8c-4.5-1.5-10-2.1-16.8-1.7
	c-6.7,0.6-11.3,1.9-13.6,4.3l-5.2,10.2c-0.6,0.8-0.7,1.6-0.8,2.5C238.4,214.8,238.5,215.5,238.8,216.2L238.8,216.2z M240.5,213.9
	c0-0.8,0.3-1.5,0.7-2.2l4.7-9.1c2.1-2.1,6.1-3.3,12.2-3.9c6.1-0.4,11,0.1,15,1.5c2.6,0.8,3.9,3.2,3.6,7.2l-1.2,11.1
	c-0.7,5-3,7.4-7,7.3l-2.3-0.4c-5.4-1-10.5-1.9-15.3-3c-4.1-1.1-7.2-2.8-9.1-5c-0.4-0.6-0.8-1.1-1-1.6
	C240.5,215.2,240.5,214.6,240.5,213.9L240.5,213.9z M293.6,222.5v-2.1h-9.9l-1.1,2.1"
          />
          <path
            fill="#FFFFFF"
            d="M283.7,220.5l0.5-1.1l-7-0.3c-0.7,5.5-3.3,8.3-7.8,8.1l9.9,1.4l3.3-6.1L283.7,220.5L283.7,220.5z
	 M273.3,245.5c-1.2,0.6-2.2,1.5-2.6,2.9l-0.8,3.2c-0.4,2.2,0,3,1.2,2.9L273.3,245.5L273.3,245.5z M279.4,228.7l-1.8,8.1
	c3-5.2,8.3-8.3,16-9.2v-5h-10.9L279.4,228.7L279.4,228.7z"
          />
          <path
            fill="#FFFFFF"
            d="M277.6,236.8l-2.8,4.5l-1.5,4.1l-2.2,8.9c-0.7,1.8-1.4,4-2.1,6.2l-0.3,0.3c-1,1.7-1.4,12-1.4,31
	c0,10.7,0.1,24.1,0.4,40.2c0.7,3,4.4,7.6,11.4,13.8c3.6,3,7,4.7,10.6,5c-1.5-6.7-0.8-10,2.2-9.8l-4.8-1l-1.9-0.5
	c-4.4-1.5-7.4-4.5-9.2-9.1c-2.3-6.9-3.4-13.8-3.4-20.6c0-4.7,1.2-8.7,4-12c1.2-1.9,1-3.7-0.5-5.4c-0.5-0.5-1.1-1-1.8-1.5
	c-0.7-15.5-0.3-28.6,1.4-39.1c1.8-11.1,7.8-16.6,18.2-16.5v-7.8C285.9,228.4,280.6,231.6,277.6,236.8L277.6,236.8z"
          />
          <path
            fill="#FFFFFF"
            d="M277.9,292.5l-2.2-0.1c1.5,1.6,1.6,3.4,0.5,5.4h1.4l1.4-3.6L277.9,292.5L277.9,292.5z"
          />
          <path
            fill="#FFFFFF"
            d="M279.1,294.2l-1.4,3.4h15.8v-5.4l-15.7,0.3 M293.6,297.6h-2.8v12.1h2.8v-3.3 M268.8,260.9
	c-5,3.2-7.8,8.1-8.8,14.9c-0.1,11.4,0.8,24.5,2.9,39.2l2.5,20.4c1.5,4.1,5.8,9.4,12.8,15.5c2.9,2.1,6.2,2.9,9.9,2.9
	c1.4-0.3,1.9-1.2,1.6-3c-3.6-0.3-7.2-1.9-10.6-5c-7-6.1-10.7-10.6-11.4-13.8c-0.3-16.1-0.4-29.4-0.4-40.2
	C267.4,272.9,267.8,262.6,268.8,260.9L268.8,260.9z M293.6,314.4v-1.5h-2.8v2.6c0,2.2,0.5,3.9,1.5,5.1l1.2,1.1h0.1l1.4,0.5
	c1.4,0.5,2.8,0.7,4,0.4c-1.8-0.1-3.2-0.5-4-1.4c-1-0.8-1.5-2.2-1.5-3.9L293.6,314.4L293.6,314.4z"
          />
          <path fill="#FFFFFF" d="M293.6,311.4v-1.6h-2.8v3.2h2.8" />
          <path
            fill="#656565"
            d="M316.8,345.8c0.5,0.1,1,0,1.4-0.3c1.9-1.1,4.5-1.9,8-2.3l-11-0.7c-0.1,0.1-0.1,0.4-0.3,0.5
	c-0.1,0.5-0.1,1.1,0.3,1.6c0.3,0.6,0.8,0.8,1.4,1L316.8,345.8L316.8,345.8z"
          />
          <path
            fill="#656565"
            d="M318.2,345.5c-0.4,0.3-0.8,0.4-1.4,0.3l9.8,2.5l3.4-0.3c4.4-0.5,7.6-1.5,9.2-3l-6.2-1.2l-6.9-0.4
	C322.7,343.6,320.1,344.4,318.2,345.5L318.2,345.5z"
          />
          <path
            fill="#656565"
            d="M315.8,351.3c-0.5,0.3-0.8,0.8-1.1,1.4c-0.1,0.5-0.1,1.1,0.3,1.6c0.3,0.5,0.8,0.8,1.4,1l0.7,0.1l1-0.3
	c2.2-1.2,5.2-2.1,9.2-2.5l1.4-0.1l1.8-0.1c7.8-1,11.4-2.9,10.7-5.6c-0.1-0.5-0.4-1.1-1-1.4c-0.3-0.3-0.5-0.3-1-0.4H339
	c-1.6,1.5-4.7,2.5-9.2,3l-3.4,0.3C321.9,348.8,318.5,349.8,315.8,351.3L315.8,351.3z"
          />
          <path
            fill="#656565"
            d="M328.6,352.5l-1.4,0.1c-4,0.4-7,1.2-9.2,2.5l-1,0.3l11.4,2.3l1.4-0.1c4.4-0.6,7.4-1.5,8.9-3L328.6,352.5
	L328.6,352.5z M330.6,352.4l-1.8,0.1l10.3,2.1l0.8,0.1l0.3,0.1l6.3-0.1v-10.3l-13.5-0.8l6.2,1.2h0.3c0.3,0,0.7,0.1,1,0.4
	c0.5,0.3,0.8,0.8,1,1.4C342,349.5,338.4,351.4,330.6,352.4L330.6,352.4z M348.3,342.2l-1,2.3l-0.8-0.1v3h4.5l-0.4-0.6l-2.2-5.2
	 M351.1,347.4h-4.5v19.7H360V363l-1-1.8c-0.7-1.6-0.8-3.4-0.7-5.1v-6.9h-1.6C354,349.5,352.2,348.8,351.1,347.4L351.1,347.4z
	 M392.2,366.3c-0.1,1.5,1.2,2.3,4,2.2h49.7c2.8,0.1,4.1-0.7,4-2.2H392.2L392.2,366.3z"
          />
          <path
            fill="#656565"
            d="M396.3,368.3c-2.8,0.1-4.1-0.7-4-2.2h-24.8v12.1c0.3,1.4,2.8,2.3,7.3,2.9l2.8,0.5c2.6,0.5,5.2,1.1,7.7,1.8
	c5.8,1.6,10.9,3.6,15,5.9c5.9,3.3,11.8,5.2,18.2,5.8l2.8,0.3h0.3l2.5-0.3c6.2-0.5,12.2-2.5,18.2-5.8c6.5-3.6,15-6.3,25.5-8.3
	c4.5-0.4,7-1.4,7.3-2.9v-12.1h-24.8c0.1,1.5-1.2,2.3-4,2.2h-4.8c0.4,1.7,0.7,3.4,0.7,5.2c0,5.5-1.9,10.3-5.9,14.3
	c-4,4-8.7,5.9-14.3,5.9c-5.5,0-10.3-1.9-14.3-5.9c-4-4-5.9-8.7-5.9-14.3c0-1.9,0.1-3.6,0.7-5.2L396.3,368.3L396.3,368.3z
	 M367.4,366.3h-1.4l-1.9-0.4l-4,1.2h-46.1v11.8h8.1l32.1-0.5h7l6.2-0.1"
          />
          <path
            fill="#656565"
            d="M367.4,378.3l-6.2,0.1c0.3,1.6,1.1,3.2,2.5,4.4c1.8,1.8,3.9,2.6,6.5,2.6c2.5,0,4.7-0.8,6.3-2.6l1.1-1.2
	l-2.8-0.5C370.2,380.6,367.8,379.6,367.4,378.3L367.4,378.3z"
          />
          <path
            fill="#656565"
            d="M360.4,388.4l9.9,2.9l8.8-2.3l6.1-5.5c-2.5-0.7-5-1.2-7.7-1.8l-1.1,1.2c-1.8,1.8-3.9,2.6-6.3,2.6
	s-4.7-0.8-6.5-2.6c-1.2-1.4-2.1-2.8-2.5-4.4h-6.6l-0.4,0.5l1.2,4L360.4,388.4L360.4,388.4z"
          />
          <path
            fill="#656565"
            d="M360.4,388.4l-4.8-5.6l-1.2-4c-1.5,1.9-4,3-7.7,3.3h-25.5h25.5c3.6-0.3,6.2-1.4,7.7-3.3l-0.1-0.5l-32.1,0.4
	c-1.4,3.4-3.7,5.2-6.9,5.4l8.3,1.8l6.2,1L360.4,388.4z M385.2,383.5l-6.1,5.5l21,0.3C396,387.1,391,385.1,385.2,383.5z M340.2,354.9
	l-0.3-0.1l-0.8-0.1c-1.5,1.5-4.5,2.5-8.9,3l-1.4,0.1l-1.8,0.1c-3.2,0.3-5.8,0.8-8,1.7h20.8c1.4-0.8,1.9-1.9,1.5-3.2
	c-0.1-0.5-0.4-1.1-1-1.4L340.2,354.9z"
          />
          <path
            fill="#656565"
            d="M340.3,359.5h-26.3v5.9h26.1c0.3,0,0.4-0.1,0.4-0.4V360C340.7,359.7,340.6,359.5,340.3,359.5L340.3,359.5z"
          />
          <path
            fill="#656565"
            d="M314.1,365.5v-23.1l-22.1-1.4c-2.9-0.3-3.7,2.9-2.2,9.8c0.3,1.8-0.3,2.9-1.6,3c-3.7,0.1-7-0.8-9.9-2.9
	c-7-6.2-11.3-11.4-12.8-15.5l-2.5-20.4c-1.4,1.9-1.9,5.6-1.8,11.1v96.6c0.3,10.5,3.6,16.1,9.9,17.1l16.5,0.5l16.6-0.5
	c6.3-1,9.6-6.6,9.9-17.1L314.1,365.5L314.1,365.5L314.1,365.5z M322.2,378.9l-8,0.1v5l1.1,0.3C318.5,384,320.8,382.2,322.2,378.9
	L322.2,378.9z M476.1,366.3h-1.4v12l12.7,0.1l32.6,0.5h8v-11.8H482l-4-1.2 M441.7,373.6c0-1.8-0.3-3.6-0.7-5.2h-3.2
	c0.5,1.7,0.8,3.4,0.8,5.2c0,4.7-1.6,8.8-5.1,12.1c-3.3,3.4-7.4,5.1-12.1,5.1c-4.1,0-7.7-1.2-10.7-3.7l-1.4-1.2
	c-3.4-3.3-5.1-7.4-5.1-12.1c0-1.8,0.3-3.6,0.8-5.2H402c-0.4,1.6-0.7,3.4-0.7,5.2c0,5.5,1.9,10.3,5.9,14.3c4,4,8.8,5.9,14.3,5.9
	c5.5,0,10.3-1.9,14.3-5.9C439.7,384,441.7,379.2,441.7,373.6L441.7,373.6z M467.3,381.1c-10.5,1.9-18.8,4.7-25.5,8.3l39.9-0.8
	l30.7-1.5l7.6-1.2l6.9-1.5c-3.2-0.1-5.5-1.9-6.9-5.4l-32.6-0.4c1.4,2.3,4.1,3.6,8,3.9h25.4h-25.5c-4-0.3-6.6-1.5-8-3.9l-12.8-0.1
	C474.3,379.6,471.8,380.6,467.3,381.1L467.3,381.1z"
          />
          <path
            fill="#656565"
            d="M438.7,373.6c0-1.8-0.3-3.6-0.8-5.2h-25v13.2c-0.1,3.6-0.8,5.5-2.1,5.5c3,2.5,6.6,3.7,10.7,3.7
	c4.7,0,8.8-1.6,12.1-5.1C436.9,382.4,438.7,378.4,438.7,373.6L438.7,373.6z"
          />
          <path
            fill="#656565"
            d="M412.8,368.3H405c-0.5,1.7-0.8,3.4-0.8,5.2c0,4.7,1.6,8.8,5.1,12.1l1.4,1.2c1.2,0,1.9-1.9,2.1-5.5
	L412.8,368.3L412.8,368.3z M580.9,326.1c0.1-5.5-0.4-9.2-1.8-11.1l-2.5,20.4c-0.7,1.9-2.1,4.1-4,6.6c-2.2,2.8-5.1,5.6-8.8,8.9
	c-2.9,2.1-6.2,2.9-9.9,2.9h-0.3l-0.3,10.5c-0.1,1.1-0.5,1.8-1.2,2.1l-22.7-1.1c-0.1-0.4-0.7-0.8-1.5-1.2v58.9
	c0.3,10.5,3.6,16.1,9.9,17.1c4.3,0.7,9.8,0.8,16.6,0.5l16.5-0.5c6.3-1,9.6-6.6,9.9-17.1L580.9,326.1L580.9,326.1z M485.5,349.2h-1.6
	v0.7l11.8,0.4v-5.9h-1l-0.8-2.3l-0.3-0.5l-2.2,5.2C490.6,348.7,488.5,349.5,485.5,349.2L485.5,349.2z M502.9,344.8h-0.3
	c-0.3,0-0.7,0.1-1,0.4c-0.5,0.3-0.8,0.8-1,1.4c-0.4,1.6,0.7,3,3.3,4l22.4,0.8l-0.4-0.3c-2.5-1.5-6.1-2.5-10.6-3l-3.4-0.3
	C507.6,347.3,504.6,346.3,502.9,344.8z"
          />
          <path
            fill="#656565"
            d="M524,345.5c-1.9-1.1-4.5-1.9-8-2.3l-7,0.4l-6.1,1.2c1.6,1.5,4.7,2.5,9.2,3l3.4,0.3l9.8-2.5
	C524.9,345.8,524.4,345.8,524,345.5L524,345.5z M528,365.5v-1.6l-1.4-0.4l-22.8-0.3c0.1,0,0.3,0.1,0.3,0.1v0.1
	c-0.1-0.1-0.1-0.1-0.3-0.1h-1.9l-0.4,0.1l-0.3,0.3v1.6c0,0.3,0.1,0.4,0.4,0.4H528L528,365.5L528,365.5z"
          />
          <path
            fill="#656565"
            d="M528,367.1v-1.6h-5.2l-5.5,1c1.4,0.1,2.5,0.4,3.6,0.7H528L528,367.1z M526.9,384.3l1.1-0.3v-5l-8-0.1
	C521.4,382.2,523.7,384,526.9,384.3L526.9,384.3z"
          />
          <path
            fill="#656565"
            d="M520.8,367.1c-1.1-0.3-2.3-0.5-3.6-0.7l-7.7-1h-7.7c-0.3,0-0.4-0.1-0.4-0.4v-1.6c-0.3,0.3-0.5,0.7-0.7,1.2
	c-0.3,1,0,1.8,0.7,2.5L520.8,367.1L520.8,367.1z M482.4,362.7l-0.3,0.4v4h13.5V363"
          />
          <path
            fill="#EBEBEB"
            d="M406.9,144.3c-1.4-0.1-2.3,0.4-2.9,1.4l-1.4,3.6c-0.4,1.2,0.3,1.8,1.9,1.8H438c1.6,0.1,2.3-0.6,1.9-1.8
	l-1.2-3.4l-0.1-0.1c-0.7-1-1.6-1.4-2.9-1.4L406.9,144.3L406.9,144.3z M427.8,144.9h0.3c1.2,0.1,1.9,0.8,2.3,1.9v1.9
	c-0.1,1.1-0.8,1.6-2.1,1.5h-14c-1.4,0.1-2.1-0.4-2.1-1.5v-1.9c0.3-1.2,1.1-1.9,2.6-1.9L427.8,144.9L427.8,144.9z"
          />
          <path
            fill="#EBEBEB"
            d="M428.1,144.9h-13.5c-1.4,0.1-2.3,0.8-2.6,1.9v1.9c0.1,1.1,0.8,1.6,2.1,1.5h14c1.4,0.1,2.1-0.4,2.1-1.5v-1.9
	C430.2,145.8,429.3,145.1,428.1,144.9L428.1,144.9z M427.3,147.1c0.1-0.1,0.4-0.3,0.5-0.3c0.3,0,0.4,0.1,0.5,0.3
	c0.1,0.1,0.3,0.4,0.3,0.6c0,0.3-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.3-0.5,0.3c-0.1,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.6
	C427.1,147.6,427.1,147.3,427.3,147.1L427.3,147.1z M425.1,146.9c0.1,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.4,0.3,0.6
	c0,0.3-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.3-0.5,0.3s-0.4-0.1-0.6-0.3c-0.1-0.1-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6
	C424.7,147,424.9,146.9,425.1,146.9L425.1,146.9z M422.6,146.9c0.3,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.4,0.3,0.6
	c0,0.3-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.3-0.5,0.3s-0.4-0.1-0.6-0.3c-0.1-0.1-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6
	C422.2,147,422.3,146.9,422.6,146.9L422.6,146.9z M420.1,146.9c0.3,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.4,0.3,0.6
	c0,0.3-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.3-0.5,0.3s-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6
	C419.7,147,419.8,146.9,420.1,146.9z M416.5,147.7c0-0.3,0.1-0.4,0.3-0.6s0.4-0.3,0.5-0.3c0.3,0,0.4,0.1,0.5,0.3s0.3,0.4,0.3,0.6
	c0,0.3-0.1,0.4-0.3,0.6s-0.3,0.3-0.5,0.3s-0.4-0.1-0.5-0.3S416.5,148,416.5,147.7L416.5,147.7z M415.2,147.1
	c0.1,0.1,0.3,0.4,0.3,0.6c0,0.3-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.3-0.5,0.3s-0.4-0.1-0.5-0.3s-0.3-0.3-0.3-0.6s0.1-0.4,0.3-0.6
	c0.1-0.1,0.4-0.3,0.5-0.3S415,147,415.2,147.1L415.2,147.1z"
          />
          <path
            fill="#EBEBEB"
            d="M428,146.9c-0.1,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.4,0.3,0.5,0.3
	c0.3,0,0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6C428.4,147,428.1,146.9,428,146.9z M425.6,147.1c-0.1-0.1-0.3-0.3-0.5-0.3
	s-0.4,0.1-0.6,0.3c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.4,0.3,0.6,0.3s0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6
	C425.9,147.6,425.9,147.3,425.6,147.1L425.6,147.1z M423.1,147.1c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.6,0.3
	c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3s0.4-0.1,0.6-0.3c0.1-0.1,0.3-0.3,0.3-0.6
	C423.4,147.6,423.4,147.3,423.1,147.1L423.1,147.1z M420.7,147.1c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3
	c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.4,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6
	C420.9,147.6,420.8,147.3,420.7,147.1L420.7,147.1z M416.8,147.1c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6s0.4,0.3,0.5,0.3
	c0.3,0,0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6s-0.3-0.3-0.5-0.3C417.2,146.9,417.1,147,416.8,147.1L416.8,147.1z
	 M415.4,147.7c0-0.3-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6
	c0.1,0.1,0.4,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3C415.3,148.1,415.4,148,415.4,147.7L415.4,147.7z M500.9,160.1
	c-16.4-1.5-33.2-2.6-50.4-3.2v4.8c16.8,0.3,33,1,48.7,2.1c11.6,1.8,19.1,6.3,22.7,13.6c5,8.9,9.5,19.7,13.5,32.2
	c1.9,4,0.8,6.2-3.6,6.6h-81.3v3H537c1.7-0.1,2.8-0.4,3.3-1c0.6-0.6,0.6-1.5,0-2.6c-6.1-17.7-12.1-33.3-18.3-46.4
	C518.8,163.9,511.7,160.9,500.9,160.1L500.9,160.1z"
          />
          <path
            fill="#EBEBEB"
            d="M521.9,177.4c-3.6-7.4-11.1-12-22.7-13.6c-15.7-1.1-31.9-1.8-48.7-2.1v54.5h81.3c4.3-0.4,5.5-2.6,3.6-6.6
	C531.4,197.1,526.9,186.4,521.9,177.4L521.9,177.4z M395.2,158.6v3.2c5.9-0.1,11.8-0.1,17.9,0h33.8v-3.2H395.2z M391.6,161.7v-4.8
	c-17.2,0.6-34,1.6-50.3,3.2c-10.9,0.8-18,3.9-21.2,9.1c-6.2,13.1-12.4,28.6-18.3,46.4c-0.4,1.2-0.4,2.1,0,2.6c0.5,0.6,1.6,1,3.3,1
	h86.5v-3h-81.3c-4.3-0.4-5.5-2.6-3.6-6.6c4-12.5,8.4-23.2,13.5-32.2c3.6-7.4,11.1-12,22.7-13.6C358.6,162.7,374.9,162,391.6,161.7
	L391.6,161.7z"
          />
          <path
            fill="#EBEBEB"
            d="M342.9,163.8c-11.6,1.8-19.1,6.3-22.7,13.6c-5,8.9-9.5,19.7-13.5,32.2c-1.9,4-0.7,6.2,3.6,6.6h81.3v-54.5
	C374.9,162,358.6,162.7,342.9,163.8L342.9,163.8z M395.2,161.7v54.5h51.9v-54.5c-5.9-0.1-11.8-0.1-17.9,0h-16
	C407,161.6,401.1,161.6,395.2,161.7z M601.7,213.9c0-0.8-0.3-1.5-0.7-2.2l-4.7-9.1c-2.1-2.1-6.2-3.3-12.2-3.9
	c-6.1-0.4-11,0.1-15.1,1.5c-2.6,0.8-3.9,3.2-3.6,7.2l1.4,11.1c0.7,5,3,7.4,7,7.3l2.3-0.4c5.4-1,10.5-1.9,15.1-3
	c4.1-1.1,7.2-2.8,9.1-5c0.4-0.6,0.8-1.1,1-1.6C601.7,215.2,601.7,214.6,601.7,213.9L601.7,213.9z M548.6,242.5v4.7h5.6l3.3,2.3
	l1,13.1l-1.9,2.3l-8,0.3v4.7h9.6c2.8,0.1,4.1-1,4.3-3.3c-0.1-7.6-0.8-13.8-2.1-18.4C559.2,244.4,555.2,242.6,548.6,242.5
	L548.6,242.5z"
          />
          <path
            fill="#EBEBEB"
            d="M548.6,237.7v4.7c6.6,0.1,10.6,2.1,11.8,5.6c1.2,4.7,1.9,10.9,2.1,18.4c-0.1,2.3-1.5,3.4-4.3,3.3h-9.6v3.6
	l14.6-0.3l2.9-2.8c-0.1-4.4-0.4-8.7-0.8-12.8C565,244.3,559.3,237.7,548.6,237.7L548.6,237.7z"
          />
          <path fill="#EBEBEB" d="M554.2,247.2h-5.6v18l8-0.3l1.9-2.3l-1-13.1" />
          <path
            fill="#EBEBEB"
            d="M548.6,235.3v2.3c10.7,0,16.4,6.6,16.8,19.8c0.4,4.1,0.7,8.4,0.8,12.8v5.9c-0.1,3.9-0.3,7.7-0.5,11.6
	l-1.4,1.6c2.1,0.7,2.2,1.7,0.4,2.9h1.9c0.4-0.5,1-1,1.8-1.4c0.8-15.5,0.3-28.6-1.4-39.1C565,240.7,559.1,235.2,548.6,235.3
	L548.6,235.3z"
          />
          <path fill="#EBEBEB" d="M566.2,270.4l-3,2.8l3,1.5" />
          <path
            fill="#EBEBEB"
            d="M566.2,274.7l-3-1.5l-14.6,0.3v3l17.6-0.3 M565.7,287.7c0.3-4,0.5-7.8,0.5-11.6l-17.6,0.3v12.8l15.5,0.3
	L565.7,287.7L565.7,287.7z M557.3,280.4c0.5,0,1.1,0.3,1.5,0.5c0.4,0.4,0.6,1,0.6,1.5s-0.3,1.1-0.5,1.5c-0.4,0.4-1,0.7-1.5,0.7
	c-0.5,0-1.1-0.3-1.5-0.7c-0.4-0.4-0.7-0.8-0.7-1.5c0-0.5,0.3-1.1,0.7-1.5C556.3,280.7,556.7,280.4,557.3,280.4L557.3,280.4z"
          />
          <path
            fill="#EBEBEB"
            d="M558.8,281.1c-0.4-0.4-1-0.5-1.5-0.5c-0.5,0-1.1,0.3-1.5,0.5c-0.4,0.4-0.7,1-0.7,1.5s0.3,1.1,0.7,1.5
	c0.4,0.4,0.8,0.7,1.5,0.7c0.5,0,1.1-0.3,1.5-0.7c0.4-0.4,0.5-0.8,0.5-1.5C559.5,282,559.2,281.5,558.8,281.1L558.8,281.1z
	 M548.6,292.3h16.1c1.8-1.2,1.7-2.2-0.4-2.9l-15.5-0.3L548.6,292.3L548.6,292.3z M241.1,211.7c-0.4,0.8-0.7,1.5-0.7,2.2
	s0.1,1.2,0.3,1.9c0.1,0.6,0.6,1.1,1,1.6c1.8,2.2,4.8,3.9,9.1,5c4.8,1.1,9.9,2.1,15.3,3l2.3,0.4c4.1,0,6.5-2.3,7-7.3l1.2-11.1
	c0.3-4-1-6.3-3.6-7.2c-4-1.4-9.1-1.9-15-1.5c-6.1,0.6-10.2,1.8-12.2,3.9L241.1,211.7L241.1,211.7z M287.9,247.2l-3.3,2.3l-1,13.1
	l1.8,2.3l8.1,0.3v-18"
          />
          <path
            fill="#EBEBEB"
            d="M284.6,249.5l3.3-2.3h5.6v-4.7c-6.6,0.1-10.6,2.1-11.8,5.6c-1.2,4.7-1.9,10.9-2.1,18.4
	c0.1,2.3,1.5,3.4,4.3,3.3h9.6v-4.7l-8-0.3l-1.9-2.3L284.6,249.5L284.6,249.5z"
          />
          <path
            fill="#EBEBEB"
            d="M293.6,237.7v-2.3c-10.3-0.1-16.4,5.4-18.2,16.5c-1.6,10.5-2.2,23.4-1.4,39.1c0.8,0.4,1.4,1,1.8,1.5l2.2,0.1
	c-2.1-1.4-2.1-2.3,0-3l-1.4-1.6c-0.3-4-0.5-7.8-0.5-11.6v-5.9c0.1-4.4,0.4-8.7,0.8-12.8C277.2,244.3,282.8,237.7,293.6,237.7
	L293.6,237.7z"
          />
          <path
            fill="#EBEBEB"
            d="M293.6,242.5v-4.7c-10.7,0-16.4,6.6-16.8,19.8c-0.4,4.1-0.7,8.4-0.8,12.8l2.9,2.8l14.6,0.3v-3.6h-9.6
	c-2.8,0.1-4.1-1-4.3-3.3c0.3-7.6,1-13.8,2.1-18.4C283,244.4,287,242.6,293.6,242.5L293.6,242.5z"
          />
          <path fill="#EBEBEB" d="M276,270.4v4.3l2.9-1.5" />
          <path fill="#EBEBEB" d="M278.8,273.2l-2.9,1.5v1.5l17.6,0.3v-3" />
          <path
            fill="#EBEBEB"
            d="M276,276.2c0.1,3.9,0.3,7.7,0.5,11.6l1.4,1.6l15.5-0.3v-12.8L276,276.2L276,276.2z M284.8,280.4
	c0.5,0,1.1,0.3,1.5,0.5c0.4,0.4,0.7,1,0.7,1.5s-0.3,1.1-0.7,1.5c-0.4,0.4-0.8,0.7-1.5,0.7c-0.5,0-1.1-0.3-1.5-0.7
	c-0.4-0.4-0.5-0.8-0.5-1.5c0-0.5,0.3-1.1,0.5-1.5C283.7,280.7,284.2,280.4,284.8,280.4L284.8,280.4z"
          />
          <path
            fill="#EBEBEB"
            d="M286.3,281.1c-0.4-0.4-0.8-0.5-1.5-0.5c-0.5,0-1.1,0.3-1.5,0.5c-0.4,0.4-0.5,1-0.5,1.5s0.3,1.1,0.5,1.5
	c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.5-0.7c0.4-0.4,0.7-0.8,0.7-1.5C287,282,286.7,281.5,286.3,281.1L286.3,281.1z M277.9,289.4
	c-2.1,0.7-2.1,1.8,0,3l15.5-0.3v-3L277.9,289.4L277.9,289.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M293.6,276.5l-17.6-0.3 M293.6,289.2l-15.7,0.1 M293.6,273.4l-14.7-0.3
	 M276,274.7v1.5 M306.9,277.7l2.5-1.9l0.1,1.9H306.9z M277.9,289.4c-2.1,0.7-2.1,1.8,0,3l1.2,1.6l-1.4,3.6 M276,276.2
	c0.1,3.9,0.3,7.7,0.5,11.6l1.4,1.6 M284.8,280.4c-0.5,0-1.1,0.3-1.5,0.5c-0.4,0.4-0.5,1-0.5,1.5s0.3,1.1,0.5,1.5
	c0.4,0.4,1,0.7,1.5,0.7s1.1-0.3,1.5-0.7c0.4-0.4,0.7-0.8,0.7-1.5c0-0.5-0.3-1.1-0.7-1.5C285.9,280.7,285.4,280.4,284.8,280.4
	L284.8,280.4z M276,270.4l2.9,2.8 M278.8,273.2l-3,1.5 M293.6,237.7c-10.7,0-16.4,6.6-16.8,19.8c-0.4,4.1-0.7,8.4-0.8,12.8
	 M276,270.4v4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M309.2,274l-4.8,3.4l-0.4,0.4v1.5h5.5l0.1,0.3v0.3c0,0.1,0,0.3,0.1,0.3
	h1.6c0.1,0,0.1-0.1,0.1-0.3v-0.6h0.5v-1.7h-0.5V274L309.2,274L309.2,274z M293.6,269.9h-9.6c-2.8,0.1-4.1-1-4.3-3.3
	c0.3-7.6,1-13.8,2.1-18.4c1.2-3.6,5.2-5.5,11.8-5.6 M293.6,265.2l-8-0.3l-1.9-2.3l1-13.1l3.3-2.3h5.6 M566.2,276.2v-1.6
	 M566.2,276.2l-17.6,0.3 M548.6,289.2l15.5,0.3 M564.1,289.4c2.1,0.7,2.2,1.6,0.4,2.9l-0.4,0.3l-11.7-0.3 M564.1,289.4l1.4-1.6
	c0.3-4,0.5-7.8,0.5-11.6 M558.8,281.1c0.4,0.4,0.5,1,0.5,1.5s-0.3,1.1-0.5,1.5c-0.4,0.4-1,0.7-1.5,0.7c-0.5,0-1.1-0.3-1.5-0.7
	c-0.4-0.4-0.7-0.8-0.7-1.5c0-0.5,0.3-1.1,0.7-1.5c0.4-0.4,0.8-0.5,1.5-0.5C558,280.4,558.4,280.7,558.8,281.1L558.8,281.1z
	 M548.6,273.4l14.6-0.3 M566.2,270.4c-0.1-4.4-0.4-8.7-0.8-12.8c-0.4-13.2-6.1-19.8-16.8-19.8 M563.2,273.2l3,1.5 M566.2,274.7v-4.3
	 M566.2,270.4l-2.9,2.8 M323.8,277.7l2.6-1.9v1.9H323.8L323.8,277.7z M328.2,274h-2.1l-5.2,3.6c-0.1,0-0.1,0.1-0.1,0.3v1.4h5.1
	l0.4,0.1l0.1,0.4v0.1c0,0.1,0,0.3,0.1,0.3h1.6c0.1,0,0.1-0.1,0.1-0.3v-0.6h0.6v-1.5h-0.5v-3.6L328.2,274L328.2,274z M313.1,274.9
	H313v0.1l2.8,2.5l-3,2.3l-0.3,0.3h2.1l0.1-0.1l2.1-1.6l0.4,0.3l1.4,1.2l0.3,0.1h2.1l-0.1-0.1l-2.8-2.5l3-2.3v-0.1h-1.8
	c-0.3,0-0.4,0.1-0.7,0.3l-1.6,1.2l-0.4-0.3V276l-1.2-1.1l-0.3-0.1L313.1,274.9L313.1,274.9z M395.2,216.2h51.7 M429.2,245l0.4-1.2
	l0.4,1.2H429.2L429.2,245z M428.6,246.3l0.3-0.7h1.2l0.1,0.6v0.1h0.8l-1.2-3.2h-0.7l-0.1,0.1l-1.2,3L428.6,246.3L428.6,246.3z
	 M423,243.7l0.5-0.1c0.1,0,0.3,0.1,0.4,0.1l0.3,0.3l0.1,0.4v0.6l-0.1,0.3l-0.4,0.3h-0.5c-0.1,0-0.3-0.1-0.4-0.1l-0.3-0.4l-0.1-0.4
	V244l0.1-0.3L423,243.7L423,243.7L423,243.7z M420.8,243l-0.8,1.4l-0.8-1.2h-1l1.2,1.9v1.2h0.7v-1.2l1.2-1.9h-0.5L420.8,243
	L420.8,243z M422.2,243.6c-0.3,0.3-0.4,0.7-0.4,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.6,1.2,0.6c0.4,0,0.8-0.1,1.2-0.6
	c0.3-0.4,0.5-0.8,0.4-1.4c0-0.4-0.1-0.8-0.5-1.1c-0.3-0.3-0.8-0.6-1.2-0.4C422.9,243,422.6,243.2,422.2,243.6L422.2,243.6z
	 M425.3,243v0.6h1v2.6h0.7v-2.5h1v-0.6h-2.6L425.3,243L425.3,243z M412.1,243.2v0.6h1v2.5h0.7v-2.5h1v-0.6L412.1,243.2L412.1,243.2z
	 M416.8,243.6l0.3,0.1c0.3,0.1,0.3,0.3,0.4,0.4l0.1,0.3v0.4l-0.1,0.4l-0.4,0.3h-0.5l-0.3-0.1c-0.1-0.1-0.3-0.3-0.3-0.4v-0.8l0.1-0.3
	l0.3-0.3l0.3-0.1h0.1V243.6L416.8,243.6z M416.7,243c-0.4,0-0.8,0.1-1.2,0.6c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,0.8,0.5,1.2
	c0.3,0.3,0.7,0.6,1.2,0.6c0.4,0,0.8-0.1,1.2-0.6c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.1-0.8-0.5-1.2C417.6,243.2,417.2,243,416.7,243
	L416.7,243z M446.9,161.7c-5.9-0.1-11.8-0.1-17.9,0h-33.8 M391.6,161.7c-16.8,0.3-33,1-48.7,2.1c-11.6,1.8-19.1,6.3-22.7,13.6
	c-5,8.9-9.5,19.7-13.5,32.2c-1.9,4-0.7,6.2,3.6,6.6h81.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.6,242.5c6.6,0.1,10.6,2.1,11.8,5.6c1.2,4.7,1.9,10.9,2.1,18.4
	c-0.1,2.3-1.5,3.4-4.3,3.3h-9.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.6,265.2l8-0.3l1.9-2.3l-1-13.1l-3.3-2.3h-5.6 M450.5,216.2h81.3
	c4.3-0.4,5.5-2.6,3.6-6.6c-4-12.5-8.4-23.2-13.5-32.2c-3.6-7.4-11.1-12-22.7-13.6c-15.7-1.1-31.9-1.8-48.7-2.1 M529.3,149.9
	c-3.2-4.1-7.3-6.7-12.4-7.8c-1.2-0.3-2.3-0.4-3.7-0.6 M513.2,141.6l-5.2-0.4 M508,141.2l-20.6-1.2l-62.2-2.1 M557.8,219.4l0.1-0.4
	l-0.4-1.2v-0.1c-2.3-8.1-4.8-15.8-7.4-23.1c-5.5-15.3-11.7-28.9-18.4-40.9l-0.7-1.4c-0.4-0.8-1-1.6-1.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M553.7,220.5L534,170c-4.8-11.3-6.3-18-4.5-20.1 M505.5,157.1
	c1.8,0.3,3.6,0.7,5.1,1.1 M510.6,158.1c6.1,1.6,10.3,4.5,12.5,8.7v0.1c6.2,13.6,12.4,29.7,18.4,48.1l0.1,0.6
	c0.4,1.5,0.3,2.8-0.6,3.7c-0.3,0.4-0.8,0.7-1.4,1l-1.2,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M450.5,219.2H537c1.7-0.1,2.8-0.4,3.3-1c0.6-0.6,0.6-1.5,0-2.6
	c-6.1-17.7-12.1-33.3-18.3-46.4c-3.2-5.4-10.3-8.4-21.2-9.1c-16.2-1.5-33-2.6-50.3-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M336.5,157.1l4.7-0.6c17.9-1.8,36.2-2.9,55.2-3.4
	c7.7-0.3,15.5-0.3,23.4-0.3h1.5c8.3,0,16.4,0.1,24.3,0.3c19,0.6,37.3,1.7,55.2,3.4l4.7,0.6 M548.6,220.5v2.1 M553.7,220.5h-5.1
	 M559.5,222.5l-1.1-2.1 M564.8,219.1l-7,0.3 M557.8,219.4l0.5,1.1 M558.4,220.5h-4.7 M572.7,227.3l2.6-0.4c5.9-1,11.6-2.1,16.9-3.3
	c4.7-1.2,8-3,10-5.4c0.6-0.6,0.8-1.2,1.1-1.8c0.3-0.7,0.4-1.5,0.4-2.2c0-0.8-0.3-1.6-0.7-2.5l-5.2-10.2c-2.3-2.3-6.9-3.7-13.6-4.3
	c-6.7-0.4-12.2,0.1-16.8,1.6c-2.9,0.8-4.3,3.4-4,7.8l1.4,12.4 M548.6,222.5h10.9 M538.8,220.5l-1.8,0.3H305.1l-1.8-0.3 M564.6,236.8
	l-1.8-8.1 M548.6,227.5v5.9 M548.6,233.4v1.9 M548.6,235.3v56.9 M564.6,236.8c-3-5.2-8.3-8.3-16-9.2 M538.8,220.5h9.8 M562.8,228.7
	l-3.3-6.2 M548.6,227.5v-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M601,211.7l-4.7-9.1c-2.1-2.1-6.2-3.3-12.2-3.9c-6.1-0.4-11,0.1-15.1,1.5
	c-2.6,0.8-3.9,3.2-3.6,7.2l1.4,11.1c0.7,5,3,7.4,7,7.3l2.3-0.4c5.4-1,10.5-1.9,15.1-3c4.1-1.1,7.2-2.8,9.1-5c0.4-0.6,0.8-1.1,1-1.6
	c0.3-0.7,0.4-1.2,0.3-1.9C601.7,213.2,601.4,212.5,601,211.7L601,211.7z M571,254.4c1.2,0.3,1.7-0.7,1.2-2.9l-0.8-3.2
	c-0.4-1.2-1.4-2.2-2.6-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M568.8,245.5l-1.5-4.3l-2.8-4.4 M562.8,228.7l9.9-1.4 M513.2,141.6
	l-2.6,16.5 M572.7,227.3c-4.5,0-7.2-2.8-7.8-8.1 M505.5,157.1l2.5-15.8 M418.1,137.9H417 M418.3,137.8l-0.3,0.1 M417,137.9v2.2h0.3
	 M418.1,137.9h0.1l0.1-0.1 M406.9,144.3h28.8c1.4-0.1,2.3,0.4,2.9,1.4l0.1,0.1l1.2,3.4c0.4,1.2-0.3,1.8-1.9,1.8h-33.4
	c-1.6,0.1-2.3-0.6-1.9-1.8l1.4-3.6C404.6,144.5,405.5,144.1,406.9,144.3L406.9,144.3z M421.1,130.8h-0.1 M420.9,130.8l-0.1,0.1
	 M421.1,130.8c-0.3,0.1-0.5,0.4-0.5,1.1v0.4 M420.8,130.9l0.3-0.1 M420.9,130.8h0.1 M420.4,132.6v0.6 M420.1,134.5l0.3-1.9v-0.3
	 M420.5,132.4l-0.1,0.1 M420.8,130.9c-0.3,0.3-0.4,0.8-0.4,1.6 M421.6,132l-0.1-0.8l-0.3-0.3 M421.2,130.9v-0.1 M421.1,130.8
	c0.1,0,0.1,0,0.1,0.1 M421.6,132v0.6c0.1,0.7,0.3,1.4,0.3,1.9 M421.1,130.8h0.1 M421.2,130.8h-0.1 M421.2,130.9
	c-0.1,0-0.1-0.1-0.1-0.1 M424.1,137.9H424l-0.1-0.1 M425.2,137.9h-1.1 M424.1,137.9l-0.3-0.1 M420.1,134.5l-0.1,0.8 M421.9,134.5
	c0.3,1.4,0.5,2.5,0.8,3.3c0.5,1.4,1.2,2.2,2.1,2.3 M417.2,140.1c0.8-0.1,1.5-0.8,2.1-2.3c0.3-0.8,0.5-1.9,0.8-3.3 M424.9,140.1h0.3
	v-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M428.1,144.9c1.2,0.1,1.9,0.8,2.3,1.9v1.9c-0.1,1.1-0.8,1.6-2.1,1.5h-14
	c-1.4,0.1-2.1-0.4-2.1-1.5v-1.9c0.3-1.2,1.1-1.9,2.6-1.9L428.1,144.9L428.1,144.9z M423.8,137.8c-0.8-0.7-1.5-1.8-1.9-3.3
	 M420.4,133.1l-0.4,2.2 M421.9,134.5l-0.3-2.5 M420.4,133.1l-0.3,1.4 M420,135.3c-0.4,1.1-1,1.9-1.6,2.5 M417.2,140.1h7.7
	 M328.9,141.6l-3.7,0.6c-5.2,1.1-9.4,3.6-12.4,7.7 M417,137.9l-65.8,2.2l-17.1,1.1 M334.1,141.2l-5.2,0.4 M395.2,156.9h-3.6
	 M395.2,219.2v-60.7 M395.2,158.6v-1.6 M391.6,219.2v-62.3 M391.6,156.9c-17.2,0.6-34,1.6-50.3,3.2c-10.9,0.8-18,3.9-21.2,9.1
	c-6.2,13.1-12.4,28.6-18.3,46.4c-0.4,1.2-0.4,2.1,0,2.6c0.5,0.6,1.6,1,3.3,1h86.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M303.3,220.5c-1.2-0.3-2.1-0.7-2.6-1.4c-0.8-1-1-2.3-0.3-4.3
	c6.1-18.4,12.2-34.5,18.4-48.1v-0.1c2.3-4.1,6.5-7,12.5-8.7 M331.4,158.1c1.5-0.4,3.3-0.8,5.1-1.1 M391.6,219.2h3.6 M450.5,156.9
	h-3.6 M450.5,219.2v-62.3 M446.9,158.6v60.7 M446.9,156.9v1.6 M427.3,147.1c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.4,0.3,0.6
	c0.1,0.1,0.4,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3
	C427.7,146.9,427.5,147,427.3,147.1L427.3,147.1z M425.1,146.9c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.4-0.3,0.6
	c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.4,0.3,0.6,0.3s0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6
	C425.5,147,425.3,146.9,425.1,146.9L425.1,146.9z M422.6,146.9c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.4-0.3,0.6
	c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.3,0.3,0.5,0.3s0.4-0.1,0.6-0.3c0.1-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6
	C423,147,422.9,146.9,422.6,146.9L422.6,146.9z M420.1,146.9c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.4-0.3,0.6
	c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.4,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6
	C420.5,147,420.4,146.9,420.1,146.9z M446.9,156.9c-8.4-0.3-16.9-0.4-25.6-0.4h-1.5c-8.4,0-16.5,0.1-24.6,0.4 M416.5,147.7
	c0,0.3,0.1,0.4,0.3,0.6s0.4,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3s0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6s-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3
	S416.5,147.6,416.5,147.7L416.5,147.7z M415.2,147.1c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.4-0.3,0.6
	c0,0.3,0.1,0.4,0.3,0.6c0.1,0.1,0.4,0.3,0.5,0.3c0.3,0,0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.3,0.3-0.6
	C415.4,147.6,415.3,147.3,415.2,147.1L415.2,147.1z M401.7,239.6h38.7c2.2,0,3.3,1.1,3.3,3.3v9.5c0,2.2-1.1,3.3-3.3,3.3h-38.7
	c-2.2,0-3.3-1.1-3.3-3.3v-9.5C398.4,240.7,399.5,239.6,401.7,239.6L401.7,239.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M402.2,244.5c-0.5,0.4-1.1,1.2-1.4,2.5v5.6 M406.1,244.5l-0.3-0.1
	l-1.4-0.3H404l-1.5,0.3c-0.1,0-0.1,0.1-0.3,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M402.2,251.8c-0.1-1-0.1-1.8-0.3-2.6c-0.1-1.8,0-3.3,0.3-4.7 M407.5,252.8
	v-5.6c-0.3-1.2-0.8-2.1-1.4-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M406.1,244.5c0.3,1.4,0.3,3,0.3,4.7l-0.3,2.6 M432.9,247.6v-4.4l-1.9-1.6
	h-19.8l-2.1,1.6v4.4 M432.9,249.9v-2.3 M437.7,250c-0.3,0.3-0.4,0.7-0.4,1c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.5,0.4,1,0.4s0.7-0.1,1-0.4
	c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-1-0.4C438.4,249.6,438.1,249.8,437.7,250L437.7,250z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M437.3,249.6c-0.4,0.4-0.5,0.8-0.5,1.4c0,0.6,0.1,1.1,0.5,1.4
	c0.4,0.4,0.8,0.6,1.4,0.6s1.1-0.1,1.4-0.6c0.4-0.4,0.5-0.8,0.5-1.4s-0.1-1.1-0.5-1.4c-0.4-0.4-0.8-0.6-1.4-0.6
	C438.3,248.9,437.7,249.2,437.3,249.6L437.3,249.6z M435.7,246.9h6.3l0.3,0.1l0.1,0.3l-0.1,0.3l-0.3,0.1h-6.3l-0.3-0.1
	c-0.1-0.1-0.1-0.1-0.1-0.3c0-0.1,0-0.3,0.1-0.3C435.4,247,435.5,246.9,435.7,246.9L435.7,246.9z M432.9,247.6h-23.8 M446.9,219.2
	h3.6 M395.2,219.2h51.7 M395.2,158.6h51.7 M336.5,157.1l-2.3-15.8 M331.4,158.1l-2.5-16.5 M454.8,288.7h-3.7 M439.9,288.7l3.3,1.8
	v3.9l-3.2,1.6 M460.6,296h0.1 M455.3,296v-3.4l5.2,3.4 M451.1,296h4.3 M466.5,288.7l-5.9,3.9l-5.9-3.9 M451.1,288.7v7.2
	 M465.8,288.7h4.4 M460.6,295.9l5.2-3.4v3.4 M477.5,293l3.3-4.3H491l3.3,4.3v2.9h-4.5v-1.4h-7.6v1.4h-4.5L477.5,293L477.5,293z
	 M470.2,296v-7.2 M465.9,296h4.3 M409.1,249.9v2.2l2.2,1.9h19.3l2.2-1.9v-2.2 M406.1,251.8h-0.3 M405.8,252.8v-1.1 M402.8,252.9
	l-0.1,0.3c0,0.3,0.1,0.4,0.4,0.6l1.1,0.3c0.4,0,0.8-0.1,1.1-0.3c0.3-0.1,0.4-0.3,0.4-0.6l-0.1-0.3 M407.5,252.8
	c-0.1-0.3-0.4-0.6-1-0.8l-0.4-0.1 M405.8,252.8c-0.1-0.1-0.3-0.3-0.4-0.3l-1.1-0.3l-1.1,0.3l-0.4,0.4 M400.9,252.7l-0.1,0.3
	c0,0.4,0.3,0.8,1,1.1c0.7,0.3,1.5,0.4,2.3,0.4c1,0,1.6-0.1,2.3-0.4c0.7-0.3,1-0.7,1-1.1v-0.3 M405.8,251.7l-0.4-0.1l-1.4-0.1
	l-1.1,0.1 M402.8,251.7v1.2 M402.2,251.8l-0.5,0.1c-0.4,0.3-0.7,0.4-0.8,0.8 M402.8,251.7l-0.5,0.1 M405.1,288.7h11.7 M405.1,296
	H405l-3.2-1.6v-3.9l3.3-1.8 M416.8,296h-11.7 M416.8,288.7l1.9,2.2h-12v2.9h12L417,296h-0.1 M429.3,296l-3.2-1.6v-3.9l3.3-1.8
	 M431.1,291h7.2v2.9h-7.2V291L431.1,291z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M432.9,249.9l-2.5,2.9h-18.8l-2.5-2.9 M418.6,310.8
	c-0.3,0-0.4-0.1-0.4-0.4v-2.1c0-0.3,0.1-0.4,0.4-0.4h5.6c0.3,0,0.4,0.1,0.4,0.4v2.1c0,0.3-0.1,0.4-0.4,0.4L418.6,310.8L418.6,310.8z
	 M432.4,349.2l-1.9-1.4h-18.8l-1.9,1.4 M547.8,316.8h-60.9c-2.6,0.1-4.7,2.1-6.5,6.1l-5.9,12.7c-2.1,3.9-6.2,6.2-12.7,7
	c-10.7,0.4-21.9,0.5-33.4,0.4h-14.4c-11.6,0.1-22.7,0-33.4-0.4c-6.5-0.8-10.6-3.2-12.7-7l-5.9-12.7c-1.8-4-4-5.9-6.5-6.1h-61.1
	 M409.8,349.2h22.6 M482.3,311.4h-22.4l-18,1.9h-41.5l-18-1.9h-22.4 M445.4,318v19.4c0,1.1-0.5,1.6-1.6,1.6h-45.3
	c-1.1,0-1.6-0.5-1.6-1.6V318c0-1.1,0.5-1.6,1.6-1.6h45.3C444.9,316.4,445.4,316.9,445.4,318L445.4,318z M398.2,314.4
	c-1.9,0.1-2.9,1.1-2.9,3v19.7c0,2.1,1,3.2,3,3.2h45.3c2.1,0,3-1.1,3-3.2v-19.7c0-1.9-1-3-2.9-3 M475.4,349.2l-1.6-1.4h-18l-1.9,1.4
	 M293.6,315.8H356c1.4-0.1,2.8,0.1,3.9,0.5c1.6,0.7,3,2.1,4.1,4l6.7,13.9c2.2,3.9,6.5,5.9,12.9,6.2h74.8c6.5-0.3,10.7-2.3,12.9-6.2
	l6.7-13.9c1.1-1.9,2.5-3.3,4.1-4c1.1-0.6,2.5-0.7,3.9-0.6h62.5 M453.8,349.2h21.5 M429.5,288.7h10.5 M440.2,296h-10.9 M453.8,349.2
	h-21.5 M398.2,314.4h45.7 M364,296h-3.7v-4.5H354l-2.1-2.6h20.5l-2.3,2.6h-6.2L364,296L364,296z M382.3,292.4h7.8l-1.2-1.5h-5.2
	L382.3,292.4z M382.3,296h-4.5v-2.9l3.3-4.3h10.2l3.3,4.3v2.9H390v-1.4h-7.6L382.3,296L382.3,296z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.6,292.3h-27.2c-4,0.1-7.4-1-10.3-3.3c-7.2-4.8-15.5-7.2-25-7.3H356.2
	c-9.5,0.1-17.9,2.6-25,7.3c-3,2.3-6.5,3.4-10.3,3.3h-27.2 M347.3,339l-3.3-7.8c-0.8-1.7-1.8-2.9-2.8-4l-1.5-1.4
	c-1-0.7-2.1-1.2-3.3-1.4l-37.6-1.8 M359.9,311.4v-2.1h-13.2v2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M345.6,338.9l-2.6-6.1c-0.8-1.6-1.8-2.9-2.8-4l-1.5-1.4
	c-1-0.7-2.1-1.2-3.3-1.4l-38-1.8c-2.6-0.1-4.7-1.1-6.2-2.9 M346.7,311.4v3 M326,343c-3.4,0.4-6.1,1.2-8,2.3
	c-0.4,0.3-0.8,0.4-1.4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M315,342.4c-0.1,0.1-0.1,0.4-0.3,0.5c-0.1,0.5-0.1,1.1,0.3,1.7
	c0.3,0.5,0.8,0.8,1.4,1l0.3,0.1 M315,342.4h-1 M326.4,348.3c-4.5,0.5-8.1,1.5-10.6,3c-0.5,0.3-0.8,0.8-1.1,1.4
	c-0.1,0.5-0.1,1.1,0.3,1.6c0.3,0.5,0.8,0.8,1.4,1l0.7,0.1 M328.6,352.5l-1.4,0.1c-4,0.4-7,1.2-9.2,2.5l-1,0.3 M332.9,343.6L326,343
	 M326.4,348.3l3.4-0.3c4.4-0.5,7.6-1.5,9.2-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M339.1,344.8h0.3c0.3,0,0.7,0.1,1,0.4c0.5,0.3,0.8,0.8,1,1.4
	c0.8,2.8-2.9,4.7-10.7,5.6l-1.8,0.1 M316.8,345.8l9.6,2.5 M326,343l-11-0.7 M347.3,339l-1.8-0.1 M348.3,342.2l-1,2.3l-1-0.1
	 M351.1,347.4l-0.4-0.6l-2.2-5.2 M351.1,347.4h-4.7 M346.5,347.4v-3 M348.5,341.7l-0.1,0.5 M348.5,341.7l-1.1-2.6 M285,339.5
	c0.3-1.7,1.8-2.2,4.7-1.7l24.3,2.5l22,1.5l12.1,0.5 M346.5,344.4l-13.6-0.8 M345.6,338.9l-31.4-1.9l-22.3-2.2
	c-1.2-0.1-2.5-0.4-3.7-1L286,333c-3.9-1.9-6.6-4.8-8.5-8.8c-2.1-6.7-2.6-12.9-1.9-18.4c0.1-3.4,2.2-5.1,5.9-5.2h8 M332.9,343.6
	l6.2,1.2 M386.6,318.1c1.8,0,3.2,0.7,4.4,1.9c1.2,1.2,1.8,2.8,1.8,4.5s-0.5,3.3-1.8,4.5s-2.8,1.9-4.4,1.9h-9.6
	c-1.8,0-3.2-0.7-4.4-1.9s-1.8-2.8-1.8-4.5s0.5-3.3,1.8-4.5c1.2-1.2,2.6-1.9,4.4-1.9H386.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.9,319.1c-1.4,0-2.8,0.5-3.7,1.6c-1,1.1-1.5,2.3-1.5,3.9
	c0,1.5,0.5,2.9,1.5,4c1,1.1,2.3,1.7,3.7,1.7h9.6c1.4,0,2.8-0.5,3.7-1.7c1-1.1,1.5-2.3,1.5-4c0-1.5-0.5-2.9-1.5-3.9
	c-1.1-1.1-2.3-1.6-3.7-1.6L376.9,319.1L376.9,319.1z M359.9,314.4v-3 M355.7,327.6c0,0.5,0.3,1.1,0.5,1.5c0.4,0.4,1,0.5,1.5,0.5
	s1.1-0.3,1.5-0.5c0.4-0.4,0.5-0.8,0.5-1.5c0-0.5-0.1-1.1-0.5-1.5c-0.4-0.4-0.8-0.5-1.5-0.5c-0.5,0-1.1,0.3-1.5,0.5
	C356,326.7,355.7,327.1,355.7,327.6z M366.7,349.2h-8.5 M388.2,349.2l-1.8-1.4h-18l-1.7,1.4 M358.2,349.2h-1.6
	c-2.6,0.3-4.5-0.3-5.5-1.8 M388.2,349.2h-21.5 M346.7,314.4h13.2 M401.8,368.3h-5.6c-2.8,0.1-4.1-0.7-4-2.2 M367.4,366.3h-1.4
	l-1.9-0.4 M359.9,363.1l-1-1.8c-0.7-1.6-0.8-3.4-0.7-5.1v-6.9 M364.1,365.9c-1.8-0.4-3.2-1.4-4.1-2.9 M364.1,365.9l-4,1.2h-0.3
	 M361.2,378.4l6.2-0.1 M367.4,378.3v-12 M359.9,367.1v-4 M360.4,388.4l9.9,2.9l8.8-2.3 M377.6,381.7l-1.1,1.2
	c-1.8,1.8-3.9,2.6-6.3,2.6c-2.5,0-4.7-0.8-6.5-2.6c-1.2-1.4-2.1-2.8-2.5-4.4 M354.4,378.9l1.1,4l5,5.5 M385.2,383.5
	c-2.5-0.7-5-1.2-7.7-1.8 M379.1,389l6.1-5.5 M392.2,366.3h-24.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M377.6,381.7l-2.8-0.5c-4.5-0.4-7-1.4-7.3-2.9 M339,354.6l0.8,0.1l0.4,0.1
	 M339.6,359.5c1.4-0.8,1.9-1.9,1.5-3.2c-0.1-0.5-0.4-1.1-1-1.4l-0.1-0.1 M340.7,363.4V360c0-0.3-0.1-0.4-0.4-0.4h-0.7 M340.7,367.1
	c0.7-0.7,1-1.5,0.7-2.5c-0.1-0.5-0.3-0.8-0.7-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.6,365.5h7.7c0.3,0,0.4-0.1,0.4-0.4v-1.6 M354.8,378.4h-0.5
	 M354.4,378.9l-0.1-0.5 M354.8,378.4l-0.4,0.5 M340.2,354.9l6.3-0.1 M346.5,354.7v12.4 M346.5,367.1h-5.8 M324.9,366.4l7.7-1
	 M328.6,357.8l1.4-0.1c4.4-0.5,7.4-1.5,8.9-3 M328.6,357.8l-1.8,0.1c-3.2,0.3-5.8,0.8-8,1.7 M314.1,359.5v5.9 M319.4,365.5h-5.4
	 M318.9,359.5h-4.8 M314.1,378.9v-11.8 M314.1,367.1h7.3 M322.2,378.9h-8.1 M317.2,355.4l11.4,2.3 M321.4,367.1
	c1.1-0.3,2.3-0.5,3.6-0.7 M332.6,365.5h-13.2 M324.9,366.4l-5.5-1 M314.1,365.5v1.6 M315.3,384.3l8.3,1.8l6.1,1l30.8,1.4
	 M314.1,383.9l1.2,0.4 M321.2,382.2h25.5c3.6-0.3,6.2-1.4,7.7-3.3 M321.4,367.1h19.4 M354.2,378.4l-32.1,0.5 M339.6,359.5h-20.8
	 M322.2,378.9c-1.4,3.4-3.7,5.2-6.9,5.4 M314.1,378.9v5 M262.9,315c-1.4,1.9-1.9,5.6-1.8,11.1v96.6c0.3,10.5,3.6,16.1,9.9,17.1
	l16.5,0.5l16.6-0.5c6.3-1,9.6-6.6,9.9-17.1V384 M361.2,378.4h-6.5 M359.9,367.1h-13.3 M478,365.9l-1.9,0.4h-1.4 M474.7,366.3v12
	 M449.8,366.3c0.1,1.5-1.2,2.3-4,2.2H441"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M441,368.3c0.4,1.7,0.7,3.4,0.7,5.2c0,5.5-1.9,10.3-5.9,14.3
	c-4,4-8.7,5.9-14.3,5.9c-5.5,0-10.3-1.9-14.3-5.9c-4-4-5.9-8.7-5.9-14.3c0-1.9,0.1-3.6,0.7-5.2 M474.7,366.3h-24.9 M474.7,378.3
	c-0.3,1.4-2.8,2.3-7.3,2.9c-10.5,1.9-18.8,4.7-25.5,8.3 M442,389.4l39.8-1l30.7-1.4l7.6-1.2l6.9-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M410.8,387.1c3,2.5,6.6,3.7,10.7,3.7c4.7,0,8.8-1.6,12.1-5.1
	c3.4-3.3,5.1-7.4,5.1-12.1c0-1.9-0.3-3.6-0.7-5.2 M405,368.3h-3.2 M412.8,368.3H405 M405,368.3c-0.5,1.7-0.8,3.4-0.8,5.2
	c0,4.7,1.6,8.8,5.1,12.1l1.4,1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M400.2,389.4c5.9,3.3,11.8,5.2,18.2,5.8l2.8,0.3h0.3l2.5-0.3
	c6.2-0.5,12.2-2.5,18.2-5.8 M412.8,368.3v13.2c-0.1,3.6-0.8,5.5-2.1,5.5 M437.9,368.3h-25 M441,368.3h-3.2 M379.1,389l21,0.4
	 M409.8,349.2h-21.6 M449.8,366.3h-57.6 M359.9,314.4h38.4 M314.1,359.5v-17.2 M400.2,389.4c-4.1-2.3-9.2-4.3-15-5.9 M339,354.6
	l-10.3-2.1 M346.5,347.4v7.3 M573.4,260.9l-0.3-0.3c-0.7-2.3-1.4-4.4-2.1-6.2 M579.1,315c2.1-14.7,3-27.8,2.9-39.2
	c-0.8-6.7-3.9-11.7-8.8-14.9 M566.5,292.3c0.4-0.5,1-1,1.8-1.4c0.8-15.5,0.3-28.6-1.4-39.1c-1.8-11.1-7.8-16.6-18.2-16.5 M528,383.9
	v38.8c0.3,10.5,3.6,16.1,9.9,17.1c4.3,0.7,9.8,0.8,16.6,0.5l16.6-0.5c6.3-1,9.6-6.6,9.9-17.1v-96.6c0.1-5.5-0.4-9.2-1.8-11.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.4,260.9c1.4,2.6,1.8,26.4,1,71.1c-0.5,2.3-2.9,5.8-7.3,9.9l-4.1,3.7
	c-3.6,3-7.2,4.7-10.6,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M553.8,353.6h0.3c3.7,0.1,7-0.8,9.9-2.9c3.7-3.3,6.6-6.2,8.8-8.9
	c1.9-2.5,3.3-4.7,4-6.6l2.5-20.4 M566.5,292.3h-17.9 M548.6,297.6v-5.4 M551.4,297.6h-2.8 M552.7,300.7v-3 M552.7,297.6h-1.4
	 M565.8,297.6c-1.2-1.9-1-3.7,0.7-5.4 M564.1,292.5l-1.1,1.6l1.4,3.4 M564.4,297.6h1.4 M564.4,297.6h-11.7 M496.6,338.9L528,337
	l22.3-2.2c1.2-0.1,2.5-0.4,3.7-1l2.2-0.8c3.9-1.9,6.6-4.8,8.5-8.8c2.1-6.7,2.6-12.9,1.9-18.4c-0.1-3.4-2.2-5.1-5.9-5.2h-8
	 M488.5,290.9h-5.2l-1.4,1.5h7.8L488.5,290.9z M494.8,296h-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M496.6,338.9l2.6-6.1c0.8-1.6,1.6-2.9,2.8-4c0.5-0.5,1-1,1.5-1.4
	c1-0.7,2.1-1.2,3.3-1.4l38-1.8c2.6-0.1,4.7-1.1,6.2-2.9 M495.5,311.4v-2.1h-13.2v2.1 M495.5,311.4v3 M482.3,311.4v3 M483.4,327.6
	c0,0.5,0.3,1.1,0.5,1.5c0.4,0.4,1,0.5,1.5,0.5s1.1-0.3,1.5-0.5c0.4-0.4,0.5-0.8,0.5-1.5c0-0.5-0.1-1.1-0.5-1.5
	c-0.4-0.4-1-0.5-1.5-0.5s-1.1,0.3-1.5,0.5C483.5,326.7,483.4,327.1,483.4,327.6L483.4,327.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.2,322.7l-37.6,1.8c-1.2,0.3-2.3,0.7-3.3,1.4l-1.5,1.4
	c-1,1.1-1.9,2.3-2.8,4l-3.3,7.8 M482.3,314.4h13.2 M494.7,339l1.9-0.1 M493.7,341.7l-2.2,5.2c-1,1.8-2.9,2.6-5.9,2.3h-1.6
	 M483.8,349.2v0.7 M495.6,350.5v-6.1 M493.9,342.2l-0.1-0.5 M495.6,344.3v0.1 M495.6,344.4l-1,0.1l-0.8-2.3 M502.9,344.8h-0.3
	c-0.3,0-0.7,0.1-1,0.4c-0.5,0.3-0.8,0.8-1,1.4c-0.4,1.6,0.7,3,3.3,4 M494.7,339l-1,2.6 M495.6,350.5l-11.8-0.5 M516,343.2
	c3.3,0.4,6.1,1.2,8,2.3c0.4,0.3,0.8,0.4,1.4,0.3 M509.1,343.6l6.9-0.4 M502.9,344.8c1.6,1.5,4.7,2.5,9.2,3l3.4,0.3 M509.1,343.6
	l-6.2,1.2 M495.6,344.4l13.5-0.8 M504.2,350.7l-8.5-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M557,339.5c-0.3-1.7-1.8-2.2-4.7-1.7l-24.3,2.5l-22,1.5l-12.1,0.5
	 M559.2,304.7c0.4,0.4,0.5,0.8,0.5,1.4c0,0.6-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.5-1.4,0.5c-0.5,0-1-0.3-1.4-0.5
	c-0.4-0.4-0.5-0.8-0.5-1.4c0-0.5,0.1-1,0.5-1.4s0.8-0.5,1.4-0.5C558.2,304.1,558.8,304.2,559.2,304.7L559.2,304.7z M548.6,306.4v3.3
	 M548.6,309.7v1.6 M548.6,312.9v-1.5 M551.4,312.9h-2.8 M548.6,314.4v-1.5 M548.6,309.7h2.8 M551.4,309.7v3.2 M551.2,321.2l0.3-0.5
	c0.4-0.8,0.8-1.8,1.1-2.6l0.1-2.1v-15.3 M548.6,315.8v1.8c0,1.6-0.5,2.9-1.5,3.9c-1,0.8-2.3,1.2-4,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M543.2,322.7c1.2,0.1,2.6,0,4-0.4l1.2-0.5h0.1l0.3-0.3l1-0.8
	c1-1.2,1.5-2.9,1.5-5.1v-2.6 M548.6,314.4v1.4 M527,342.5l1-0.1 M525.4,345.8l0.3-0.1c0.5-0.1,1.1-0.5,1.4-1
	c0.3-0.5,0.4-1.1,0.3-1.6l-0.3-0.5 M515.6,348.3c4.5,0.5,8.1,1.5,10.6,3l0.4,0.3 M528,351.6h-1.4 M528,342.4v9.2 M530.2,350.1
	c-0.4,0.8-1.1,1.4-2.1,1.5 M557,339.5c4.3-1.5,7.4-4.5,9.2-9.1c2.3-6.9,3.4-13.8,3.4-20.6c0-4.7-1.4-8.7-4-12 M553.8,353.6v-1
	c-0.1-0.7-0.5-1.2-1.4-1.6 M552.3,350.6v0.4 M552.3,351l-0.1-0.1l-22-0.8 M552.3,350.6c1-4.4,1.1-7.3,0.1-8.7
	c-0.6-0.8-1.2-1.1-2.2-1 M550.3,341l4.8-1l1.9-0.5 M550.3,341l-22.3,1.4 M548.6,311.4h-53.1 M495.5,314.4h53.1 M526.6,351.6
	l-22.4-0.8 M515.6,348.3l9.8-2.5 M551.4,309.7v-12.1 M527,342.5l-11,0.7 M548.6,297.6v8.8 M529.5,365l22.7,1.1
	c0.7-0.3,1.1-1,1.2-2.1l0.3-10.5 M528,363.8c0.8,0.4,1.4,0.8,1.5,1.2 M528,365.5v-1.6 M528,367.1v-1.7 M528,378.9v-11.8
	 M503.9,363.1l22.8,0.3l1.2,0.4 M526.9,384.3l1.1-0.4 M528,383.9v-5 M520.8,367.1c-1.1-0.3-2.3-0.5-3.6-0.7 M522.7,365.5h-13.2
	 M509.5,365.5l7.7,1 M504.3,363.3l-0.1-0.1L504.3,363.3z M504.3,363.3c-0.1-0.1-0.1-0.1-0.3-0.1 M517.2,366.4l5.5-1 M501.3,363h0.7
	 M495.6,363h5.6 M501.3,363v0.4 M501.3,363.4l0.3-0.1l0.4-0.3 M501.3,363.4v1.6c0,0.3,0.1,0.4,0.4,0.4h7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M501.4,367.1c-0.7-0.7-1-1.5-0.7-2.5c0.1-0.5,0.3-1,0.7-1.2 M483.8,349.9
	v6.1c0.1,1.4,0,2.9-0.4,4.3l-0.3,1l-0.7,1.5 M482.4,362.7l-0.3,0.4 M478,365.9l4,1.2h0.1 M501.4,367.1h-5.8 M495.6,367.1V363
	 M482.2,367.1h13.5 M482.2,363.1v4 M482.4,362.7l13.2,0.3 M520.8,367.1h-19.4 M502,363l1.9,0.1 M487.4,378.4l32.6,0.5 M521,382.2
	h-25.5c-4-0.3-6.6-1.5-8-3.9 M528,367.1h-7.2 M528,365.5h-5.2 M520,378.9c1.4,3.4,3.7,5.2,6.9,5.4 M528,378.9h-8 M529.5,365l0.7-15
	 M548.6,233.4l-127.9-0.8l-127.1,0.8 M483.8,349.2h-8.4 M482.2,363.1c-1,1.5-2.5,2.5-4.1,2.9 M443.9,314.4h38.4 M487.4,378.4
	l-12.7-0.1 M571,254.4l-2.2-8.9 M312.8,149.9c-0.5,0.7-1.1,1.5-1.6,2.3l-0.7,1.4c-10.2,17.7-18.7,39.1-25.9,64l-0.4,1.4l0.1,0.4
	 M277.3,219.1l1.4-12.4c0.3-4.4-1.1-7-4-7.8c-4.5-1.5-10-2.1-16.8-1.7c-6.7,0.6-11.3,1.9-13.6,4.3l-5.2,10.2
	c-0.6,0.8-0.7,1.6-0.8,2.5c0,0.7,0.1,1.4,0.4,2.2c0.3,0.6,0.7,1.2,1.1,1.8c2.1,2.3,5.4,4.1,10,5.4c5.4,1.2,11,2.3,16.9,3.3l2.6,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M241.1,211.7l4.7-9.1c2.1-2.1,6.1-3.3,12.2-3.9c6.1-0.4,11,0.1,15,1.5
	c2.6,0.8,3.9,3.2,3.6,7.2l-1.2,11.1c-0.7,5-3,7.4-7,7.3l-2.3-0.4c-5.4-1-10.5-1.9-15.3-3c-4.1-1.1-7.2-2.8-9.1-5
	c-0.4-0.6-0.8-1.1-1-1.6c-0.3-0.7-0.4-1.2-0.3-1.9C240.5,213.2,240.6,212.5,241.1,211.7L241.1,211.7z M293.6,222.5v-2.1
	 M293.6,220.5h9.8 M283.7,220.5l0.7-1.1 M282.6,222.5l1.1-2.1 M283.7,220.5h5 M284.3,219.4l-7-0.3 M273.3,245.5
	c-1.2,0.6-2.2,1.5-2.6,2.9l-0.8,3.2c-0.4,2.2,0,3,1.2,2.9 M279.4,228.7l-1.8,8.1 M277.6,236.8l-2.8,4.4l-1.5,4.3 M293.6,233.4v-5.9
	 M293.6,235.3v56.9 M293.6,235.3v-1.9 M293.6,220.5h-5 M293.6,222.5h-11 M293.6,227.5v-5 M277.6,236.8c3-5.2,8.3-8.3,16-9.2
	 M279.4,228.7l3.2-6.2 M269.5,227.3l9.9,1.4 M277.3,219.1c-0.7,5.5-3.3,8.3-7.8,8.1 M288.6,220.5l19.7-50.5
	c5-11.4,6.5-18.2,4.5-20.2 M293.6,292.3l-15.7,0.3l-2.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.8,292.4c-0.5-0.5-1.1-1-1.8-1.5c-0.7-15.5-0.3-28.6,1.4-39.1
	c1.8-11.1,7.8-16.6,18.2-16.5 M276.4,297.6c1.2-1.9,1-3.7-0.5-5.4 M289.4,297.6h-13.1 M290.8,297.6h-1.4 M289.4,300.7v-3
	 M293.6,297.6v-5.4 M293.6,297.6h-2.8 M268.8,260.9c-5,3.2-7.8,8.1-8.8,14.9c-0.1,11.4,0.8,24.5,2.9,39.2 M271.1,254.4
	c-0.7,1.9-1.4,4-2.1,6.2l-0.3,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M268.8,260.9c-1,1.7-1.4,12-1.4,31c0,10.7,0.1,24.1,0.4,40.2
	c0.7,3,4.4,7.6,11.4,13.8c3.6,3,7,4.7,10.6,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M289.7,350.6c0.3,1.8-0.3,2.9-1.6,3c-3.7,0.1-7-0.8-9.9-2.9
	c-7-6.2-11.3-11.4-12.8-15.5l-2.5-20.4 M293.6,314.4v-1.5 M293.6,309.7v-3.3 M293.6,311.4v-1.6 M293.6,312.9v-1.5 M290.8,312.9v2.6
	c0,2.2,0.5,3.9,1.5,5.1l1.2,1.1h0.1l1.4,0.5c1.4,0.5,2.8,0.7,4,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M298.9,322.7c-1.8-0.1-3.2-0.5-4-1.4c-1-0.8-1.5-2.2-1.5-3.9v-1.8
	 M293.6,315.8v-1.4 M284.3,304.1c-0.5,0-1,0.1-1.4,0.5c-0.4,0.4-0.5,0.8-0.5,1.4c0,0.6,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5
	s1-0.3,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4c0-0.5-0.1-1-0.5-1.4S284.9,304.1,284.3,304.1L284.3,304.1z M290.8,309.7v3.2 M289.4,300.7
	v15.3c0,0.8,0,1.4,0.1,2.1c0.3,0.8,0.5,1.8,1.1,2.6l0.3,0.5 M291.9,341l-5-1l-1.9-0.5 M276.4,297.6c-2.6,3.3-4,7.3-4,12
	c0,6.9,1.1,13.8,3.4,20.6c1.8,4.5,5,7.6,9.2,9.1 M291.9,341c-2.9-0.3-3.7,2.9-2.2,9.8 M290.8,312.9h2.8 M290.8,309.7h2.8
	 M293.6,306.4v-8.8 M290.8,297.6v12.1 M271.1,254.4l2.2-8.9 M548.6,222.5h-255 M293.6,227.5h255 M293.6,306.4h255 M346.7,311.4
	h-53.1 M293.6,314.4h53.1 M314.1,342.4l-22.1-1.4 M409.1,247.6v2.3"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M305.1,219.2
		c0,0-3-0.1-3.4-1.4c0,0-0.4-0.6,0.1-2.3c0,0,9.9-29.3,18.7-46.9c0,0,2.3-4.3,10.5-6.9c0,0,4.3-1.5,10.9-1.8c0,0,38.2-3.9,79.6-3.6
		c0,0,37.7-0.3,77.6,3.4c0,0,11.1,0.4,16.6,3.6c0,0,4.4,1.9,6.6,5.9c0,0,8.8,18.2,18.3,46.1c0,0,0.7,1.7,0,2.6
		c-0.7,0.8-3.4,1.1-3.4,1.1h-38.2h-48.3h-29.4h-29.7h-50.3h-36V219.2z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <polygon
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              points="
		293.6,220.5 303.5,220.5 305.3,220.7 320,220.7 346.8,220.7 379.3,220.7 409.4,220.7 435.9,220.7 461.3,220.7 491.9,220.7 
		523.4,220.7 537,220.7 539.1,220.5 548.6,220.5 548.6,227.5 548.6,242.5 548.6,265.2 548.6,276.5 548.6,292.3 548.6,311.4 
		495.5,311.4 495.5,309.3 482.3,309.3 482.3,311.4 459.7,311.4 442,313.3 421.1,313.3 400.3,313.3 382.3,311.4 359.9,311.4 
		359.9,309.3 346.7,309.3 346.7,311.4 310.5,311.4 293.6,311.4 293.6,297.6 293.6,283.5 293.6,267.4 293.6,251.8 293.6,234.4 	"
              id="Liftgate and Tailgate"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Liftgate and Tailgate')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('liftgateTailgateColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M293.6,292.3l-15.8,0.3
		l-1.9-0.1c0,0-0.7-0.8-1.8-1.5c0,0-0.8-15.5,0.1-27.8c0,0,0.4-9.1,2.3-16c0,0,2.9-12.4,16.9-11.7l0.1,23L293.6,292.3L293.6,292.3z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M548.6,292.3h17.9
		c0,0,0.8-1,1.8-1.4c0,0,0.4-5.9,0.3-18c0,0-0.1-11-2.1-23c0,0-1.2-8.7-8.4-12.5c0,0-2.9-1.9-9.5-1.9v29.7L548.6,292.3L548.6,292.3z
		"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M276.4,297.6h17.2v16.8v1.2
		H356c0,0,5.4-0.6,7.8,4.5l6.9,14c0,0,2.5,5.8,12.9,6.2h34.1h6.3h34.5c0,0,9.4-0.1,12.7-6.1l7.3-14.7c0,0,2.3-4.5,7.6-4h62.5v-18.2
		h17.2c0,0,3.3,3.7,3.9,9.6c0,0,0.8,11-3.3,23c0,0-1.8,4.5-5,6.9c0,0-2.3,1.9-6.2,2.9l-4.8,1l-22.3,1.4l-33.3,2.1l-1.1-2.9l-2.1,5
		c0,0-1,3-6.1,2.8h-10.2l-1.6-1.4h-18l-1.9,1.4h-21.5l-1.9-1.4h-18.8l-1.9,1.4h-21.5l-1.9-1.4h-18l-1.7,1.4h-10.2
		c0,0-4.3,0.8-5.9-2.3l-2.2-5.2l-1.1,2.9l-55.6-3.6l-4.3-0.8c0,0-8.5-1.2-11.8-9.8c0,0-3-8-3.4-18.4
		C272.4,311.9,271.4,303.1,276.4,297.6z"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(ExtendedCabPickup2DrRear);
