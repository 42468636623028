import React, { Component } from 'react';
import { CYAN_01, GREY_06 } from '../../../constants/colors';

class WheelIcon extends Component {
  render() {
    const { size = 24, isActive, isAccordian } = this.props;
    const color = isActive ? '#FFF' : isAccordian ? GREY_06 : CYAN_01;
    const reverseColor = isActive ? (isAccordian ? GREY_06 : CYAN_01) : '#FFF';

    return (
      <svg
        width={size}
        height={size}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        transform="translate(-2.5,0)"
      >
        <path
          fill={color} // tire filling
          d="M254,28.3C129.5,28.3,28.2,129.5,28.2,254S129.5,479.8,254,479.8S479.8,378.5,479.8,254 S378.5,28.3,254,28.3z"
        />
        <path
          fill={reverseColor} // wheel
          d="M254,120.8c-73.4,0-133.2,59.7-133.2,133.2S180.6,387.2,254,387.2S387.2,327.5,387.2,254 S327.4,120.8,254,120.8z"
        />
        <path
          fill={reverseColor} // center fill
          d="M254,229.1c-13.8,0-24.9,11.2-24.9,24.9c0,13.8,11.2,24.9,24.9,24.9c13.8,0,24.9-11.2,24.9-24.9 C278.9,240.3,267.8,229.1,254,229.1z"
        />

        <path
          fill={color} // inner tire
          d="M254,92.6C165,92.6,92.6,165,92.6,254S165,415.4,254,415.4S415.4,343,415.4,254S343,92.6,254,92.6z M254,387.2c-73.4,0-133.2-59.7-133.2-133.2S180.6,120.8,254,120.8S387.2,180.6,387.2,254S327.4,387.2,254,387.2z"
        />
        <path
          fill={color} // hub
          d="M254,200.8c-29.3,0-53.2,23.8-53.2,53.2s23.9,53.2,53.2,53.2s53.2-23.8,53.2-53.2 S283.3,200.8,254,200.8z M254,278.9c-13.8,0-24.9-11.2-24.9-24.9c0-13.8,11.2-24.9,24.9-24.9c13.8,0,24.9,11.2,24.9,24.9 C278.9,267.8,267.8,278.9,254,278.9z"
        />
        <circle fill={color} cx="254" cy="161.3" r="17.4" />
        <circle fill={color} cx="188.5" cy="319.5" r="17.4" />
        <circle fill={color} cx="319.5" cy="319.5" r="17.4" />
        <circle fill={color} cx="346.7" cy="254" r="17.4" />
        <circle fill={color} cx="161.3" cy="254" r="17.4" />
        <circle fill={color} cx="188.5" cy="188.5" r="17.4" />
        <circle fill={color} cx="319.5" cy="188.5" r="17.4" />
        <circle fill={color} cx="254" cy="346.7" r="17.4" />
      </svg>
    );
  }
}

export default WheelIcon;
