import React, { Component } from 'react';

class CheckmarkIcon extends Component {
  render() {
    const { size = 17, color = '#0EC541' } = this.props;
    return (
      <svg
        width={size}
        height="14"
        viewBox="0 0 17 14"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.1032 0.954901C15.2056 0.85045 15.3278 0.767472 15.4626 0.710825C15.5975 0.654178 15.7422 0.625 15.8885 0.625C16.0347 0.625 16.1795 0.654178 16.3144 0.710825C16.4492 0.767472 16.5714 0.85045 16.6737 0.954901C17.1027 1.3884 17.1087 2.0889 16.6887 2.5299L7.81924 13.0149C7.71854 13.1255 7.59633 13.2144 7.46011 13.2761C7.32388 13.3378 7.17648 13.3711 7.02695 13.3739C6.87741 13.3767 6.72888 13.349 6.59042 13.2924C6.45197 13.2358 6.32652 13.1516 6.22174 13.0449L0.824738 7.5759C0.616594 7.36363 0.5 7.07819 0.5 6.7809C0.5 6.48361 0.616594 6.19817 0.824738 5.9859C0.927107 5.88145 1.04929 5.79847 1.18412 5.74182C1.31896 5.68518 1.46374 5.656 1.60999 5.656C1.75624 5.656 1.90102 5.68518 2.03586 5.74182C2.17069 5.79847 2.29287 5.88145 2.39524 5.9859L6.97324 10.6254L15.0732 0.987901C15.0826 0.976309 15.0926 0.965289 15.1032 0.954901Z" />
      </svg>
    );
  }
}

export default CheckmarkIcon;
