const LOCAL_REQUSTED_KEY = 'transportRequests'

/** `getRequestedItems` - reads local storage and returns
 *  items requested for transportation service
 * 
 * @returns {string[]} `transportItems`
 */
function getRequestedItems () {
    try {
        const transportItemsStr = localStorage.getItem(LOCAL_REQUSTED_KEY);
        if (transportItemsStr === null) {
            return [];
        }
        const transportItems = JSON.parse(transportItemsStr);
        if (transportItems instanceof Array ) {
            return transportItems;
        } else {
            console.log(transportItemsStr); // debug
            return [transportItems];
        }
        
    } catch (err) {
        console.error('Error reading local storage, transportRequest tracking will not work properly.')
        return [];
    }
} 

/** 
 * 
 * @param {string} itemId unique itemId of vehicle requested for transport
 * @returns {void}
 */
function addRequstedItem (itemId) {
    if (itemId && typeof itemId === 'string') {
        const currentItems = getRequestedItems();
        currentItems.push(itemId);
        const requestedItemsSet = [...new Set(currentItems)];
        localStorage.setItem(LOCAL_REQUSTED_KEY, JSON.stringify([...requestedItemsSet.values()]));
        return;
    } else {
        console.error(`addRequestedItem:  Item ID was either: empty string, not a string, or undefined.`);
    }
}

/** `clearAllRequstedForTransport` - 
 * @returns {void}
 */
function clearAllRequstedForTransport () {
    localStorage.removeItem(LOCAL_REQUSTED_KEY);
}

export const transportRequests = {
    getRequestedItems,
    addRequstedItem,
    clearAllRequstedForTransport
}
