import React from 'react';
import PropTypes from 'prop-types';
// import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import moment from 'moment';
import { Button } from 'react-bootstrap';

class SingleDatePicker extends React.Component {
  render() {
    const { handleEvent, startDate, name, displayName } = this.props;

    if (!startDate) return null;

    const label = startDate.format('YYYY-MM-DD HH:mm A');

    let locale = {
      format: 'YYYY-MM-DD HH:mm',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };

    let buttonStyle = { width: '100%' };

    return (
      <div>
        <br />
        <label>{name}</label>
        <label>{displayName}</label>
        <div>
          {/* <DatetimeRangePicker
            singleDatePicker
            timePicker
            showDropdowns
            locale={locale}
            startDate={startDate}
            onEvent={handleEvent}
          >
            <Button className="selected-date-range-btn" style={buttonStyle}>
              <div className="pull-left">
                <i className="fa fa-calendar" />
                &nbsp;
                <span>{label}</span>
              </div>
              <div className="pull-right">
                <i className="fa fa-angle-down" />
              </div>
            </Button>
          </DatetimeRangePicker> */}
        </div>
        <br />
      </div>
    );
  }
}

SingleDatePicker.propTypes = {
  handleEvent: PropTypes.func,
  startDate: PropTypes.string,
  name: PropTypes.string,
};

export default SingleDatePicker;
