import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { cloneDeep } from 'lodash';

let styles = {};

export default class DataTablePrintable extends Component {
  state = {
    printableColumns: [],
  };

  componentDidMount() {
    this.setState({
      printableColumns: this.makePrintableColumns(),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.columns !== prevProps.columns) {
      this.setState({
        printableColumns: this.makePrintableColumns(),
      });
    }
  }

  makePrintableColumns() {
    const excludeFromPrint = ['itemId', 'checkbox', 'buttons', 'details'];

    return cloneDeep(this.props.columns)
      .filter(column => !excludeFromPrint.includes(column.id))
      .map(column => {
        // we clone so we don't change the width on the main component
        column.width = column.printWidth;
        return column;
      });
  }

  render() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.dataForButtonActions}
        columns={this.state.printableColumns}
        sortable={false}
        multiSort={false}
        filterable={false}
        resizable={false}
        defaultPageSize={this.props.dataForButtonActions.length}
        getTableProps={() => ({ style: styles.tableStyle })}
        getTheadThProps={() => ({ style: styles.headerCellStyle })}
        getTdProps={() => ({ style: styles.cellStyle })}
        showPagination={false}
      />
    );
  }
}

DataTablePrintable.propTypes = {
  data: PropTypes.array,
  dataForButtonActions: PropTypes.array,
};

styles = {
  tableStyle: {
    border: '1px solid gray',
  },
  headerCellStyle: {
    maxHeight: 80,
    minHeight: 50,
    fontWeight: 'bold',
    fontSize: `0.85em`,
    textAlign: 'left',
    padding: 5,
    outline: 'none',
    borderBottom: '1px solid gray',
    overflow: 'auto',
    overflowWrap: 'break-word',
    textOverflow: 'ellipses',
    whiteSpace: 'normal',
    wordBreak: 'normal',
  },
  cellStyle: {
    maxHeight: 80,
    textAlign: 'left',
    fontSize: `0.7em`,
    paddingLeft: 5,
    overflow: 'auto',
    overflowWrap: 'break-word',
    textOverflow: 'ellipses',
    whiteSpace: 'normal',
    wordBreak: 'normal',
  },
};
