import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe4DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M706.8,367.9c3.8-13.6,7.3-33.8,8-62.5c-0.2-8.3-0.7-15.9-1.3-22.7c-4.4-50.1-17.8-65.3-17.8-65.3
	c-5.9-10-26.9-15.3-26.9-15.3c-3.7-1.6-7.5-2.9-10.7-4c-5.8-1.9-10-2.7-10-2.7c-2.6-1.5-5.4-2.7-8-3.5c-8.4-2.4-16.1-2-16.1-2
	l-10.6-0.1l-66-0.4c-5.9,0.5-9.1,2.7-9.1,2.7H538c-26.1-1.2-52.3-1.9-77.5-2.1c-10.6-1-20.6-0.6-25.9-0.2h-8.2
	c-11.8,0-26,0.1-40.1,0.2H385c1.4-4.2,2.1-7.6,2.1-7.6s-1.2-0.7-5.1,0.2c-3,0.7-5.7,2.7-8.7,6.2c-0.2,0.4-0.6,0.8-0.8,1.3v0.1h-0.2
	l0.1-0.1h-4.1c-13.5,0.2-25.5,0.6-35.8,0.8c-23,0.7-36.8,1.3-36.8,1.3c-1.4-2.1-5.8-2.4-5.8-2.4c-37.7-2.1-81,0.5-81,0.5
	c-4,0.2-6.4,0.4-7.7,0.4c-1.6,0.1-1.9,0.1-1.9,0.1c-9.2,0.7-12.4,2.7-12.4,2.7l-12,4.8c0,0-9.3,2.3-16,13.6c0,0-9.8,20.1-16.4,31.5
	c0,0-7.6,18.4-10.7,32.9c0,0-0.9,2.2-2.1,6.2c-0.1-0.1-0.2-0.1-0.2-0.1H128c-0.5,0.1-0.3,0.5-0.3,0.5c-0.9,11.3-0.7,22.1-0.7,22.1
	s-0.2,11,0.7,22.1c0,0-0.1,0.4,0.3,0.5h1.4c0,0,0.1,0,0.2-0.1c1,4,2.1,6.2,2.1,6.2c3.1,14.3,10.7,32.9,10.7,32.9
	c6.6,11.4,16.4,31.5,16.4,31.5c6.6,11.4,16,13.6,16,13.6l12,4.8c0,0,3,2.1,12.4,2.7c0,0,0.2,0,1.9,0.1c1.4,0.1,3.7,0.2,7.7,0.4
	c0,0,43.4,2.6,81,0.5c0,0,4.4-0.4,5.8-2.5c0,0,15.5,0.7,40.8,1.4c9.8,0.2,21,0.6,33.3,0.7h2.7c0.2,0.5,0.6,0.9,0.8,1.4
	c2.9,3.6,5.6,5.6,8.7,6.2c4,0.9,5.1,0.2,5.1,0.2s-0.7-3.3-2.1-7.6h1.9c15.5,0.2,31.2,0.2,43.5,0.2h4.1c5.2,0.5,15.3,0.9,25.9-0.2
	c25.2-0.4,51.6-0.9,77.8-2.1c0,0,3.1,2.3,9.1,2.7l70.1-0.5h6.5c0,0,7.8,0.5,16.1-2c2.7-0.8,5.5-2,8-3.5c0,0,6.2-1.3,13.8-4
	c2.2-0.8,4.7-1.7,7-2.7c0,0,21-5.2,26.9-15.3C695.7,393.4,701.5,386.8,706.8,367.9L706.8,367.9z"
          />
          <path
            fill="#5D5D5D"
            d="M160.5,246.8c-7.5,3.1-14.5,6.4-20.6,10l-1.3,4.9c0,0-5.5,13.4-7.1,43.7c1.6,30.3,7.1,43.7,7.1,43.7l1.3,4.9
	c6.2,3.6,13.2,6.9,20.6,10c-0.7-1.5-1.3-3.4-1.6-5.4c0,0-5.5-26.2-5.1-53.3c-0.4-27,5.1-53.3,5.1-53.3
	C159.2,250.1,159.8,248.3,160.5,246.8L160.5,246.8z"
          />
          <path
            fill="#EAEBEB"
            d="M196.9,193.9c-5.1,0.2-9.4,0.8-10.8,2.6c-0.6,0.6-1.1,1.3-1.6,1.9c-0.8,0.9-1.5,1.9-2.2,2.8
	c-0.6,0.7-1.1,1.4-1.6,2.1c-12.9,17.5-18.5,34.2-19.1,36c-0.7,2.2-0.8,2.2,0.8,1.4c0.7-0.4,1.4-0.7,2.2-1.1c1.4-0.6,3.3-1.3,6.8-2.6
	c1.4-0.5,3-1,4.9-1.7c3.7-7,7.2-12.8,10.7-18c3.3-4.8,6.4-9,9.6-12.6c2.4-2.8,4.8-5.5,7.2-7.9l1.3-1.3c2-2,0.1-2-0.9-2
	C201.3,193.8,199.1,193.8,196.9,193.9L196.9,193.9z M196.9,417c-5.1-0.2-9.4-0.8-10.8-2.6c-0.6-0.6-1.1-1.3-1.6-1.9
	c-0.8-0.9-1.5-1.9-2.2-2.8c-0.6-0.7-1.1-1.4-1.6-2.1c-12.9-17.5-18.5-34.2-19.1-36c-0.7-2.2-0.8-2.2,0.8-1.4c0.7,0.4,1.4,0.7,2.2,1
	c1.4,0.6,3.3,1.3,6.8,2.6c1.4,0.5,3,1.1,4.9,1.8c3.7,7,7.2,12.8,10.7,18c3.3,4.8,6.4,9,9.6,12.6c2.4,2.8,4.8,5.5,7.2,7.9
	c0.5,0.5,0.8,0.8,1.3,1.3c2,2,0.1,2-0.9,2C201.3,417.1,199.1,417.1,196.9,417L196.9,417z M327.5,217.3c0,0-17.6,15-24.4,62.1
	l-0.4,2.7c-0.9,7.1-1.6,14.9-2,23.4c0.1,4,0.4,7.8,0.7,11.4l0.7,7.1c0.9,8.2,2.1,15.5,3.6,22l5.8,20c7.5,20.2,16,27.4,16,27.4
	s52.3-9.2,70.8-13.6c0,0,5.9-0.9,5.1-8.5c-0.1-0.6-0.1-1.2-0.4-1.7c0,0-6.2-26.6-6.2-64.2c0-37.7,6.2-64.2,6.2-64.2l0.4-1.8
	c0.8-7.6-5.1-8.5-5.1-8.5c-9.3-2.2-27.3-5.7-42.7-8.6C340.3,219.5,327.5,217.3,327.5,217.3L327.5,217.3z M576,371
	c0.2,12.5,3.6,18.8,5.8,21.6c0.3,0.5,1.2,1.3,1.7,1.7c11.8-2.7,37.2-9.7,50.6-23.1c0,0,14.7-12.7,15-51.6V291
	c-0.3-38.9-15-51.6-15-51.6c-13.4-13.4-38.9-20.4-50.6-23.1c-0.5,0.5-0.9,0.9-1,1c-2.2,2.4-6.2,8.7-6.4,22.3c0,0-0.7,24.6-0.3,65.6
	C575.3,346.4,576,371,576,371L576,371z M665.5,208.1l-4.3,1.4c0,0-3.3,0.6-0.5,2.7c0,0,5.4,2.1,11,5.8c1.6,1.1,3.3,2.3,4.8,3.7
	c0.8,0.8,1.6,1.5,2.4,2.4c0.8,0.9,1.5,1.8,2.1,2.8c0,0,3.7,4.8,5.5,12.9c0,0,3.4,5.1,7.5,9.8c0.6,0.7,1.2,1.4,1.9,2
	c0.3,0.4,1,0.9,1.5,1.4c-5.6-27.9-13.6-37.3-13.6-37.3C677.4,207.2,665.5,208.1,665.5,208.1L665.5,208.1z M665.5,402.7l-4.3-1.4
	c0,0-3.3-0.6-0.5-2.7c0,0,5.4-2.1,11-5.8c1.6-1,3.3-2.3,4.8-3.7c0.8-0.8,1.6-1.5,2.4-2.4c0.8-0.9,1.5-1.8,2.1-2.8
	c0,0,3.7-4.8,5.5-12.9c0,0,3.4-5.1,7.5-9.8c0.6-0.7,1.2-1.4,1.9-2c0.3-0.4,1-0.9,1.5-1.4c-5.6,27.9-13.6,37.3-13.6,37.3
	C677.4,403.4,665.5,402.7,665.5,402.7L665.5,402.7z"
          />
          <path
            fill="#5D5D5D"
            d="M361.1,208.4c-7.7,0.6-13.3,1.1-17.5,1.4c0.6,0.1,1.2,0.4,1.4,0.5c3.4,0.7,15.6,3.5,32.9,7.2l-4.1-8.2h-0.9
	l-0.8,2.4c0,0-2.7,1.5-7.8,1.2c-5.1-0.5-7.8-2-7.8-2s3.6,0,5.1-2.2c0.1-0.1,0.1-0.2,0.2-0.4L361.1,208.4L361.1,208.4z"
          />
          <path
            fill="#EAEBEB"
            d="M464.9,207.4c-40.8-0.2-84,1.6-90.9,2l4.1,8.2c9.4,2.1,20.4,4.4,32.2,6.9c0,0,14.7,2.4,35.2,3.7
	c4.8,0.4,29.4,0.7,29.4,0.7h1C472.1,219,465.4,208.2,464.9,207.4z M517.8,209.8c-10.8-1.2-24.5-1.9-39-2.2l0.2,0.5
	c0.9,1.9,5.6,11.4,9,20.5c21-0.6,42.2-1.5,50.1-1.9c1.3-0.4,2.6-0.7,2.8-0.7c3.3-0.9,0.6-3.8,0.6-3.8l-4.4-4.1
	C529.1,210.4,517.8,209.8,517.8,209.8L517.8,209.8z"
          />
          <path
            fill="#5D5D5D"
            d="M434.5,190c-2.3,0.2-3.7,0.4-3.7,0.4s-1,1.8,1.4,1.8l4.8,0.2c-1.2-0.4-1.6-0.7-1-0.9
	c1.9-0.7,4.3-0.4,8.9-0.4s6.9,0,8.6,0.4c0.6,0.1-0.1,0.6-1.4,1.1h9.7c0,0,2.1-1.9,0.7-2.1c-0.6-0.1-1.2-0.1-1.8-0.2h-0.2
	C449.6,189.1,439.7,189.5,434.5,190z M361.1,402.4c-7.7-0.6-13.3-1-17.5-1.4c0.6-0.1,1.2-0.4,1.4-0.5c3.4-0.7,15.6-3.5,32.9-7.2
	l-4.1,8.2h-0.9l-0.8-2.4c0,0-2.7-1.5-7.8-1.2c-5.1,0.5-7.8,2-7.8,2s3.6,0,5.1,2.2c0.1,0.1,0.1,0.2,0.2,0.4L361.1,402.4L361.1,402.4z
	"
          />
          <path
            fill="#EAEBEB"
            d="M464.9,403.4c-40.8,0.2-84-1.6-90.9-2l4.1-8.2c9.4-2.1,20.4-4.4,32.2-6.9c0,0,14.7-2.4,35.2-3.7
	c4.8-0.4,29.4-0.7,29.4-0.7h1C472.1,391.8,465.4,402.6,464.9,403.4L464.9,403.4z M517.8,401.1c-10.8,1.2-24.5,1.9-39,2.2l0.2-0.5
	c0.9-1.9,5.6-11.4,9-20.5c21,0.6,42.2,1.5,50.1,1.9c1.3,0.4,2.6,0.7,2.8,0.7c3.3,0.9,0.6,3.8,0.6,3.8l-4.4,4.1
	C529.1,400.4,517.8,401.1,517.8,401.1L517.8,401.1z"
          />
          <path
            fill="#5D5D5D"
            d="M434.5,420.8c-2.3-0.2-3.7-0.4-3.7-0.4s-1-1.8,1.4-1.8l4.8-0.2c-1.2,0.4-1.6,0.7-1,0.9
	c1.9,0.7,4.3,0.4,8.9,0.4s6.9,0,8.6-0.4c0.6-0.1-0.1-0.6-1.4-1h9.7c0,0,2.1,1.9,0.7,2.1c-0.6,0.1-1.2,0.1-1.8,0.2h-0.2
	C449.6,421.7,439.7,421.3,434.5,420.8L434.5,420.8z"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M610,405.5c0.2,3-5.1,5.9-12,6.5s-12.7-1.4-12.9-4.3
	c-0.2-3,5.1-6.6,12-7.2C604,399.8,609.8,402.5,610,405.5L610,405.5z M131.8,334.2c0,0-0.9-2.3-2.1-6.3 M131.8,276.7
	c0,0-0.9,2.3-2.1,6.3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M436.3,418.5l-4.3,0.4c-2.4,0-1.4,1.7-1.4,1.7s15.2,1.9,31.5,0
		c1.4-0.1-0.7-2.1-0.7-2.1h-9.2"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M453.2,419.4c1.2-0.2-3.7-2.2-8.3-2.2s-11,1.5-9.2,2.2
		c1.9,0.7,4.3,0.4,8.9,0.4C449.3,419.8,451.5,419.8,453.2,419.4z M453.6,418.5l2.3,2"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M436.3,192.4L432,192c-2.4,0-1.4-1.8-1.4-1.8s15.2-1.9,31.5,0
		c1.4,0.1-0.7,2.1-0.7,2.1h-9.2"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M453.2,191.4c1.2,0.2-3.7,2.2-8.3,2.2s-11-1.5-9.2-2.2
		c1.9-0.7,4.3-0.4,8.9-0.4C449.3,191,451.5,191,453.2,191.4L453.2,191.4z M453.6,192.4l2.3-2.1"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M131.5,305.4c1.6,30.4,7.1,43.8,7.1,43.8l3.1,12.4c0,0,1.6,3.6,0.7,5.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M371.6,420.3c-45.3-0.8-76-2.2-76-2.2c-1.4,2.1-5.8,2.4-5.8,2.4
	c-37.7,2.1-81-0.5-81-0.5c-8.6-0.4-9.7-0.5-9.7-0.5c-9.2-0.7-12.4-2.7-12.4-2.7l-12-4.8c0,0-9.3-2.3-16-13.6c0,0-9.8-20-16.4-31.5
	c0,0-7.6-18.4-10.7-32.9 M453,420.8c-3.4,0-36.3,0.2-67.8-0.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M452.9,420.8c27.5-0.2,56.5-0.9,85.2-2.2c0,0,3.1,2.3,9.1,2.7l76.6-0.5
	c0,0,13.6,0.9,24.1-5.5c0,0,10.3-2.1,20.8-6.6c0,0,21-5.2,26.9-15.3c0,0,17.4-19.6,19.1-87.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M319.3,418.9v-2.3c0,0,11.9-17.4,14.1-17.8l53.7-11.4
	c28.7-8.2,60.2-9.1,60.2-9.1c39.2-1.4,100.3,1.4,100.3,1.4c7.3,0.9,5.5,5.5,5.5,5.5l-5,15c-1.4,7.7-3.2,14.1-3.2,14.1
	c-1.4,3.6-6.9,4.1-6.9,4.1 M170.1,371.7c-11.8-4.8-15.7-7.8-16.7-8.2c-1.6-0.6-1.5,0.5-1.4,1c0.5,2.3,10.4,27.3,31.2,46.2
	c2.4,2.2,7.5,2.7,15.5,2.7c1.1,0,3,0,1.1-2.1C190.1,401.8,180.8,391.8,170.1,371.7L170.1,371.7z M317.9,392.2
	c-11.7,10.6-20.2,7.8-20.2,7.8c-21.9-1-76.2-12.6-76.2-12.6c-25.1-6.1-41.1-11.5-51.3-15.7 M192.5,413.9c0,0,0.7-0.5,55.4,0.5
	c0,0,31.1,0.7,47.9,3.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M284.3,416.5c0,0,0-1,5.1-1l29.8,1.3 M163.2,368.7
	c-1.5-2.1-3.5-5.5-4.4-10c0,0-5.5-26.2-5.1-53.3 M290.5,344.3c-80.7-13.6-84.8-38.9-84.8-38.9 M211.8,305.4c0,0,2.7,20.9,77.6,35.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M362,402.5c-28.3-2.1-29.7-3-29.7-3c-46.5-14-47.2-94-47.2-94
	 M351.7,401.5c0,0,3.7-3,24.9-5.8 M375.2,403.4c0,0,93.4,4.8,171.9,0.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M373,401.5c0,0,98.4,4.8,144.8-0.4c0,0,11.3-0.7,19.1-8.2l4.4-4.1
	c0,0,3.4-3.7-2.1-4.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M446,405.5c0,0,48.8,1,75.2-2.7c0,0,12-0.7,22.8-14.3c0,0,3.4-3.7-2.7-4.1
	c0,0-36.6-1.8-66.7-2.5c0,0-24.6,0.4-29.4,0.7c-20.5,1.4-35.2,3.7-35.2,3.7c-37.3,7.9-66,14.3-66,14.3 M548.6,403.4
	c0,0,77.3-4.4,127.9-14.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M519.2,402.8c0,0,16.1,0.4,58.2-7.2c0,0,38.9-6.5,56.8-24.2
	c0,0,14.7-12.7,15-51.6v-14.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M624.1,379.2c0,0,6.9,12.7,19.1,11.7l35.6-4.1 M668.3,402.8
	c-6.9,4.1-26.3,8.2-26.3,8.2c-5.8,6.9-19.1,9.6-19.1,9.6 M639.9,418.8c0,0,5.5-2.7,5.5-8.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M697.2,357.6c-5.5,5.1-10.8,13.3-10.8,13.3c-1.7,8.2-5.5,12.9-5.5,12.9
	c-6.2,9.2-20.2,14.7-20.2,14.7c-2.7,2.1,0.5,2.7,0.5,2.7l4.3,1.4c0,0,11.9,0.7,18.1-7.8c0,0,17.4-20.3,18.8-89.6 M693.8,361.5
	c0,0,5.2-19.1,6.2-56.1 M323.6,396c0,0,56.1-9.6,75.5-15c0,0,6.9-2.7,4.8-9.6c0,0-7.6-30.4-6.5-66"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M300.8,305.4c0.1,4,0.4,7.8,0.7,11.5l0.7,7.1c0.9,8.2,2.1,15.5,3.6,22
	l5.8,20.1c7.5,20.2,16,27.4,16,27.4s52.3-9.2,70.8-13.6c0,0,5.9-0.9,5.1-8.5c-0.1-0.6-0.1-1.2-0.4-1.7c0,0-6.2-26.6-6.2-64.1
	 M404,371.4c0,0,108.1-1.7,170-4.1c0,0,0.3,6.5,1.4,14c1.5,11.3,6.5,13.6,6.5,13.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M403,367.9c0,0,109.1-1.7,171-4.1c0,0-0.7-32.6-0.3-58.3 M470.4,420.2
	c0,0,1.7-0.4,6.5-14.3 M464.9,403.4c0,0,7.2-11.3,11-21.6 M476.4,403.1c0,0,9.6-19.8,10.6-24.6 M478.9,403.1c0,0,5.5-11,9.2-21.2
	 M583.2,394.1c-1.7-1.6-7-7.6-7.2-23.1c0,0-0.7-24.6-0.3-65.6 M191.5,413.5c0,0,8.2,5.2,9.8,6.4 M131.5,305.5
	c1.6-30.4,7.1-43.8,7.1-43.8l3.1-12.4c0,0,1.6-3.6,0.7-5.5"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M371.6,190.4c-45.3,0.8-76,2.2-76,2.2c-1.4-2.1-5.8-2.4-5.8-2.4
	c-37.7-2.1-81,0.5-81,0.5c-8.6,0.4-9.7,0.5-9.7,0.5c-9.2,0.7-12.4,2.7-12.4,2.7l-12,4.8c0,0-9.3,2.3-16,13.6c0,0-9.8,20.1-16.4,31.5
	c0,0-7.6,18.4-10.7,32.9 M453,190c-3.4,0-36.3-0.2-67.8,0.2 M452.9,190.1c27.5,0.2,56.5,0.9,85.2,2.2c0,0,3.1-2.3,9.1-2.7l76.6,0.5
	c0,0,13.6-0.9,24.1,5.5c0,0,10.3,2.1,20.8,6.6c0,0,21,5.2,26.9,15.3c0,0,17.4,19.6,19.1,87.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M319.3,191.9v2.3c0,0,11.9,17.4,14.1,17.8l53.7,11.4
	c28.7,8.2,60.2,9.1,60.2,9.1c39.2,1.4,100.3-1.4,100.3-1.4c7.3-0.9,5.5-5.5,5.5-5.5l-5-15c-1.4-7.7-3.2-14.1-3.2-14.1
	c-1.4-3.6-6.9-4.1-6.9-4.1 M192.5,196.9c0,0,0.7,0.5,55.4-0.5c0,0,31.1-0.7,47.9-3.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M284.3,194.3c0,0,0,1.1,5.1,1.1l29.8-1.3 M290.5,266.5
	c-80.7,13.6-84.8,38.9-84.8,38.9 M211.8,305.5c0,0,2.7-20.9,77.6-35.9"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M362,208.4c-28.3,2.1-29.7,3-29.7,3c-46.5,14-47.2,94-47.2,94
	 M351.7,209.5c0,0,3.7,3,24.9,5.8 M375.2,207.4c0,0,93.4-4.8,171.9-0.4"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M373,209.5c0,0,98.4-4.8,144.8,0.4c0,0,11.3,0.7,19.1,8.2l4.4,4.1
	c0,0,3.4,3.7-2.1,4.1"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M446,205.3c0,0,48.8-1.1,75.2,2.7c0,0,12,0.7,22.8,14.3
	c0,0,3.4,3.7-2.7,4.1c0,0-36.6,1.8-66.7,2.4c0,0-24.6-0.4-29.4-0.7c-20.5-1.4-35.2-3.7-35.2-3.7c-37.3-7.9-66-14.3-66-14.3
	 M548.6,207.4c0,0,77.3,4.4,127.9,14.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M519.2,208.1c0,0,16.1-0.4,58.2,7.2c0,0,38.9,6.5,56.8,24.2
	c0,0,14.7,12.7,15,51.6v14.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M624.1,231.6c0,0,6.9-12.7,19.1-11.7l35.6,4.1 M668.3,208.1
	c-6.9-4.1-26.3-8.2-26.3-8.2c-5.8-6.9-19.1-9.6-19.1-9.6 M639.9,192c0,0,5.5,2.7,5.5,8.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M697.2,253.2c-5.5-5.1-10.8-13.3-10.8-13.3c-1.7-8.2-5.5-12.9-5.5-12.9
	c-6.2-9.2-20.2-14.7-20.2-14.7c-2.7-2.1,0.5-2.7,0.5-2.7l4.3-1.4c0,0,11.9-0.7,18.1,7.8c0,0,17.4,20.3,18.8,89.6 M693.8,249.4
	c0,0,5.2,19.1,6.2,56.1 M323.6,214.8c0,0,56.1,9.6,75.5,15c0,0,6.9,2.7,4.8,9.6c0,0-7.6,30.4-6.5,66"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M300.8,305.5c0.4-8.5,1-16.3,2-23.4l0.4-2.7c6.8-47,24.4-62.1,24.4-62.1
	s52.3,9.2,70.8,13.6c0,0,5.9,0.9,5.1,8.5c-0.1,0.6-0.1,1.2-0.4,1.7c0,0-6.2,26.6-6.2,64.1 M404,239.5c0,0,108.1,1.8,170,4.1
	c0,0,0.3-6.5,1.4-14c1.5-11.3,6.5-13.6,6.5-13.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M403,242.9c0,0,109.1,1.7,171,4.1c0,0-0.7,32.6-0.3,58.3 M470.4,190.6
	c0,0,1.7,0.4,6.5,14.3 M464.9,207.4c0,0,7.2,11.3,11,21.6 M476.4,207.7c0,0,9.6,19.8,10.6,24.6 M478.9,207.7c0,0,5.5,11,9.2,21.2
	 M583.2,216.7c-1.7,1.6-7,7.6-7.2,23.1c0,0-0.7,24.6-0.3,65.6 M191.5,197.3c0,0,8.2-5.2,9.8-6.4 M127.1,305.4c0,0-0.2,11,0.7,22.1
	c0,0-0.1,0.4,0.3,0.5h1.4c0,0,0.4,0,0.2-0.5c0,0-0.8-14.7-0.8-22.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M127.1,305.4c0,0-0.2-11,0.7-22.1c0,0-0.1-0.4,0.3-0.5h1.4
	c0,0,0.4,0,0.2,0.5c0,0-0.8,14.7-0.8,22.3 M362.5,403.4c0,0-155.5-10.8-222.7-49.4c0,0,19,59.3,51.6,59.3"
          />
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M170.1,371.7c-11.8-4.8-15.7-7.8-16.7-8.2c-1.6-0.6-1.5,0.5-1.4,1c0.5,2.3,10.4,27.3,31.2,46.2
				c2.4,2.2,7.5,2.7,15.5,2.7c1.1,0,3,0,1.1-2.1C190.1,401.8,180.8,391.8,170.1,371.7L170.1,371.7z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_2_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M176,375.3c-9.7-3.3-11.5-4.1-13.9-5.2
			c-1.5-0.8-1.5-0.8-0.8,1.4c0.7,2.2,7.7,23.2,24.6,42.9c2.1,2.3,9.9,2.7,17.6,2.7c1.1,0,2.9,0,0.9-2
			C195.4,405.8,186.4,394.6,176,375.3L176,375.3z"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_3_"
                  d="M170.1,371.7c-11.8-4.8-15.7-7.8-16.7-8.2c-1.6-0.6-1.5,0.5-1.4,1c0.5,2.3,10.4,27.3,31.2,46.2
				c2.4,2.2,7.5,2.7,15.5,2.7c1.1,0,3,0,1.1-2.1C190.1,401.8,180.8,391.8,170.1,371.7L170.1,371.7z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_4_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M206.3,407.5c0,0-14.9-4.9-31.2,2"
              />
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_5_"
                  d="M170.1,371.7c-11.8-4.8-15.7-7.8-16.7-8.2c-1.6-0.6-1.5,0.5-1.4,1c0.5,2.3,10.4,27.3,31.2,46.2
				c2.4,2.2,7.5,2.7,15.5,2.7c1.1,0,3,0,1.1-2.1C190.1,401.8,180.8,391.8,170.1,371.7L170.1,371.7z"
                />
              </defs>
              <g clipPath="url(#SVGID_6_)">
                <path
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M178.1,400.6l2,1.9v-9.2l-2,1.9V400.6z M180.1,393.3H192 M180.1,402.5
				H192"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_7_"
                  d="M170.1,371.7c-11.8-4.8-15.7-7.8-16.7-8.2c-1.6-0.6-1.5,0.5-1.4,1c0.5,2.3,10.4,27.3,31.2,46.2
				c2.4,2.2,7.5,2.7,15.5,2.7c1.1,0,3,0,1.1-2.1C190.1,401.8,180.8,391.8,170.1,371.7L170.1,371.7z"
                />
              </defs>
              <path
                clipPath="url(#SVGID_8_)"
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M183.6,393.3h-7.3c0,0-10.5-12.7-11.9-22.1"
              />
            </g>
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M170.1,371.7c-11.8-4.8-15.7-7.8-16.7-8.2c-1.6-0.6-1.5,0.5-1.4,1
	c0.5,2.3,10.4,27.3,31.2,46.2c2.4,2.2,7.5,2.7,15.5,2.7c1.1,0,3,0,1.1-2.1C190.1,401.8,180.8,391.8,170.1,371.7L170.1,371.7z
	 M282.7,413.2c0,0-10.3-14.9-63.3-26.3 M170.1,239.1c-11.8,4.8-15.7,7.8-16.7,8.2c-1.6,0.6-1.5-0.5-1.4-1.1
	c0.5-2.3,10.4-27.2,31.2-46.2c2.4-2.2,7.5-2.7,15.5-2.7c1.1,0,3,0,1.1,2.1C190.1,209.1,180.8,219,170.1,239.1L170.1,239.1z
	 M317.9,218.7c-11.7-10.6-20.2-7.8-20.2-7.8c-21.9,1-76.2,12.7-76.2,12.7c-25.1,6.1-41.1,11.5-51.3,15.7"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M163.2,242.1c-1.5,2.1-3.5,5.5-4.4,10c0,0-5.5,26.2-5.1,53.3"
          />
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M362.5,207.4c0,0-155.5,10.8-222.7,49.4c0,0,19-59.3,51.6-59.3"
          />
          <g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_9_"
                    d="M170.1,239.1c-11.8,4.8-15.7,7.8-16.7,8.2c-1.6,0.6-1.5-0.5-1.4-1.1c0.5-2.3,10.4-27.2,31.2-46.2
					c2.4-2.2,7.5-2.7,15.5-2.7c1.1,0,3,0,1.1,2.1C190.1,209.1,180.8,219,170.1,239.1L170.1,239.1z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_10_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M176,235.6c-9.7,3.3-11.5,4.1-13.9,5.2
				c-1.5,0.8-1.5,0.8-0.8-1.4s7.7-23.2,24.6-42.9c2.1-2.3,9.9-2.7,17.6-2.7c1.1,0,2.9,0,0.9,2C195.4,205,186.4,216.2,176,235.6
				L176,235.6z"
                />
              </g>
            </g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_11_"
                    d="M170.1,239.1c-11.8,4.8-15.7,7.8-16.7,8.2c-1.6,0.6-1.5-0.5-1.4-1.1c0.5-2.3,10.4-27.2,31.2-46.2
					c2.4-2.2,7.5-2.7,15.5-2.7c1.1,0,3,0,1.1,2.1C190.1,209.1,180.8,219,170.1,239.1L170.1,239.1z"
                  />
                </defs>
                <path
                  clipPath="url(#SVGID_12_)"
                  fill="none"
                  stroke="#231F20"
                  strokeWidth="0.5"
                  d="M206.3,203.3c0,0-14.9,4.9-31.2-2"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <defs>
                    <path
                      id="SVGID_13_"
                      d="M170.1,239.1c-11.8,4.8-15.7,7.8-16.7,8.2c-1.6,0.6-1.5-0.5-1.4-1.1c0.5-2.3,10.4-27.2,31.2-46.2
						c2.4-2.2,7.5-2.7,15.5-2.7c1.1,0,3,0,1.1,2.1C190.1,209.1,180.8,219,170.1,239.1L170.1,239.1z"
                    />
                  </defs>
                  <g clipPath="url(#SVGID_14_)">
                    <path
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.5"
                      d="M178.1,210.2l2-1.9v9.3l-2-2V210.2z M180.1,217.6H192 M180.1,208.3
						H192"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <defs>
                    <path
                      id="SVGID_15_"
                      d="M170.1,239.1c-11.8,4.8-15.7,7.8-16.7,8.2c-1.6,0.6-1.5-0.5-1.4-1.1c0.5-2.3,10.4-27.2,31.2-46.2
						c2.4-2.2,7.5-2.7,15.5-2.7c1.1,0,3,0,1.1,2.1C190.1,209.1,180.8,219,170.1,239.1L170.1,239.1z"
                    />
                  </defs>
                  <path
                    clipPath="url(#SVGID_16_)"
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.5"
                    d="M183.6,217.6h-7.3
					c0,0-10.5,12.7-11.9,22.1"
                  />
                </g>
              </g>
            </g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                strokeWidth="0.5"
                d="M170.1,239.1c-11.8,4.8-15.7,7.8-16.7,8.2c-1.6,0.6-1.5-0.5-1.4-1.1
			c0.5-2.3,10.4-27.2,31.2-46.2c2.4-2.2,7.5-2.7,15.5-2.7c1.1,0,3,0,1.1,2.1C190.1,209.1,180.8,219,170.1,239.1L170.1,239.1z"
              />
            </g>
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M282.7,197.6c0,0-10.3,14.9-63.3,26.3"
          />
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M374,401.5l4.1-8.2 M356.6,399.8c0,0,3.6,0,5.1,2.2s4.4,9.8,8.7,15.9
		c4.2,6.1,7.6,9.1,11.5,10c4,0.9,5.1,0.2,5.1,0.2s-1.3-6.1-3.7-12.2c-2.5-6.2-3.7-10-5.4-11c-1.5-0.9-4.2-1.3-4.2-1.3l-1.5-4.7
		c0,0-2.7-1.5-7.8-1.2C359.3,398.3,356.6,399.8,356.6,399.8L356.6,399.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M374.9,403.9c0,0-1.3-0.5-4.2-0.7c-2.9-0.2-3.6-0.2-4.2,0.2
		c-0.7,0.5-0.6,2,0.5,4.2c1.8,3.8,4.7,12.5,7.3,15.2"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M372.6,400c0,0-5.6-0.9-7.8,2c-1.6,2.1-1.2,3.4-1.2,3.4 M376.8,404.2
		c0,0,3.1,6.5,5.1,12c2.7,7.3,4,12,4,12"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M374,209.3l4.1,8.2 M364.4,212.9c5.1,0.5,7.8-1.2,7.8-1.2l1.5-4.7
		c0,0,2.7-0.5,4.2-1.3c1.5-0.9,2.9-4.7,5.4-11c2.4-6.3,3.7-12.2,3.7-12.2s-1.2-0.7-5.1,0.2s-7.3,4-11.5,10
		c-4.2,6.1-7.1,13.6-8.7,15.9c-1.5,2.2-5.1,2.2-5.1,2.2S359.3,212.6,364.4,212.9L364.4,212.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M374.2,188c-2.7,2.7-5.6,11.3-7.3,15.2c-1,2.2-1.2,3.7-0.5,4.2
		s1.3,0.5,4.2,0.2c2.9-0.2,4.2-0.7,4.2-0.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeWidth="0.5"
              d="M363.6,205.4c0,0-0.5,1.3,1.2,3.4c2.2,2.9,7.8,2,7.8,2 M385.9,182.4
		c0,0-1.4,4.7-4,12c-2,5.5-5.1,12-5.1,12"
            />
          </g>
          <path
            fill="none"
            stroke="#231F20"
            strokeWidth="0.5"
            d="M538.1,418.5c19,0,35.4-5.4,58.5-5.4c23,0,33.8,3.1,45.3-2.2 M538.1,192.3
	c19,0,35.4,5.4,58.5,5.4c23,0,33.8-3.1,45.3,2.2 M683.6,395c0,0-7.3,4.8-11.5,5.7c-3.7,0.8-3.7,0-2.8-1.9c1.2-2.1,2.3-6.1,2.3-6.1
	 M683.6,215.9c0,0-7.3-4.8-11.5-5.7c-3.7-0.8-3.7,0-2.8,1.9c1.2,2.1,2.3,6.1,2.3,6.1 M139.9,354.1l11.5,9.2 M139.9,256.8l11.5-9.3"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M317.9,392.2c0,0-9.9,9.9-20.2,7.8
		c0,0-14.1-0.2-66.9-10.7c0,0-38-7.5-67.5-20.6c0,0-7.9-5.8-9.6-64.1c0,0,0.8-51.5,9.6-62.5c0,0,11-5.6,40.6-14.1
		c0,0,18.5-4.9,33.2-7.7c0,0,41.7-8.5,60.7-9.4c0,0,8.4-2.6,20.2,7.8c0,0-30.2,18.5-32.9,86.7C285,305.4,285.8,367.5,317.9,392.2z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M327.5,393.5
		c0,0-8.9-8.2-16-27.4l-5.8-20.1c0,0-4.2-18.1-4.9-40.6c0,0,0.4-60.5,26.7-88.2c0,0,55.3,9.8,70.8,13.6c0,0,6.8,0.7,4.8,10.3
		c0,0-6.3,29.7-6.2,64.2c0.1,34.6,4.9,57.8,6.3,64.9c0,0,2,8-4.9,9.6C398.2,379.9,354.6,389.2,327.5,393.5z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M403.2,367.9c0,0-6.8-26.5-5.7-62.5
		c0,0-1.5-29.7,5.7-62.6c0,0,89.4,1.3,170.8,4.1c0,0-1,67.7,0,116.8C574,363.8,526.2,365.9,403.2,367.9z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M583.6,394.3
		c0,0-7.2-5-7.6-22.7c0,0-1-65.7,0-131.7c0,0-0.1-16.4,7.5-23.3c0,0,35.1,7.5,50.2,22.6c0,0,14.6,12.5,15.5,50.4V319
		c0,0,0.7,24.6-7.9,42c0,0-3.6,10.1-17,18.3C624.1,379.1,609.3,388.9,583.6,394.3z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M678.8,386.6l-35.4,4.2
		c0,0-11.2,1.9-19.1-11.8c0,0,9.4-5.4,14.9-14.2s8.9-23.4,9.8-37.2l0.2-6.1v-31.4c0,0,0.1-16.3-4.7-31.2c0,0-2.6-8.9-7.8-16.4
		c0,0-2.4-3.8-12.5-10.8c0,0,6.9-12.4,18.8-11.7l35.8,4.2c0,0,5.8,5.7,7.6,15.7c0,0,3.5,5.4,7.6,10c0,0,5.6,24.7,5.9,55.6
		c0,0-0.6,32.4-6.2,56c0,0-3.4,3.5-7.3,9.6C686.4,371,684.9,380.3,678.8,386.6z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe4DrTop);
