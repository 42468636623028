module.exports = [
  {
    state: 'AL',
    statefp: '01',
    countyfp: '001',
    countyname: 'Autauga County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '003',
    countyname: 'Baldwin County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '005',
    countyname: 'Barbour County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '007',
    countyname: 'Bibb County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '009',
    countyname: 'Blount County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '011',
    countyname: 'Bullock County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '013',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '015',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '017',
    countyname: 'Chambers County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '019',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '021',
    countyname: 'Chilton County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '023',
    countyname: 'Choctaw County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '025',
    countyname: 'Clarke County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '027',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '029',
    countyname: 'Cleburne County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '031',
    countyname: 'Coffee County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '033',
    countyname: 'Colbert County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '035',
    countyname: 'Conecuh County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '037',
    countyname: 'Coosa County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '039',
    countyname: 'Covington County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '041',
    countyname: 'Crenshaw County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '043',
    countyname: 'Cullman County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '045',
    countyname: 'Dale County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '047',
    countyname: 'Dallas County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '049',
    countyname: 'DeKalb County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '051',
    countyname: 'Elmore County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '053',
    countyname: 'Escambia County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '055',
    countyname: 'Etowah County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '057',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '059',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '061',
    countyname: 'Geneva County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '063',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '065',
    countyname: 'Hale County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '067',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '069',
    countyname: 'Houston County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '071',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '073',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '075',
    countyname: 'Lamar County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '077',
    countyname: 'Lauderdale County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '079',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '081',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '083',
    countyname: 'Limestone County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '085',
    countyname: 'Lowndes County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '087',
    countyname: 'Macon County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '089',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '091',
    countyname: 'Marengo County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '093',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '095',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '097',
    countyname: 'Mobile County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '099',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '101',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '103',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '105',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '107',
    countyname: 'Pickens County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '109',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '111',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '113',
    countyname: 'Russell County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '115',
    countyname: 'St. Clair County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '117',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '119',
    countyname: 'Sumter County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '121',
    countyname: 'Talladega County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '123',
    countyname: 'Tallapoosa County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '125',
    countyname: 'Tuscaloosa County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '127',
    countyname: 'Walker County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '129',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '131',
    countyname: 'Wilcox County',
    classfp: 'H1',
  },
  {
    state: 'AL',
    statefp: '01',
    countyfp: '133',
    countyname: 'Winston County',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '013',
    countyname: 'Aleutians East Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '016',
    countyname: 'Aleutians West Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '020',
    countyname: 'Anchorage Municipality',
    classfp: 'H6',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '050',
    countyname: 'Bethel Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '060',
    countyname: 'Bristol Bay Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '068',
    countyname: 'Denali Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '070',
    countyname: 'Dillingham Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '090',
    countyname: 'Fairbanks North Star Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '100',
    countyname: 'Haines Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '105',
    countyname: 'Hoonah-Angoon Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '110',
    countyname: 'Juneau City and Borough',
    classfp: 'H6',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '122',
    countyname: 'Kenai Peninsula Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '130',
    countyname: 'Ketchikan Gateway Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '150',
    countyname: 'Kodiak Island Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '164',
    countyname: 'Lake and Peninsula Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '170',
    countyname: 'Matanuska-Susitna Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '180',
    countyname: 'Nome Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '185',
    countyname: 'North Slope Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '188',
    countyname: 'Northwest Arctic Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '195',
    countyname: 'Petersburg Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '198',
    countyname: 'Prince of Wales-Hyder Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '220',
    countyname: 'Sitka City and Borough',
    classfp: 'H6',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '230',
    countyname: 'Skagway Municipality',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '240',
    countyname: 'Southeast Fairbanks Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '261',
    countyname: 'Valdez-Cordova Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '270',
    countyname: 'Wade Hampton Census Area',
    classfp: 'H5',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '275',
    countyname: 'Wrangell City and Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '282',
    countyname: 'Yakutat City and Borough',
    classfp: 'H1',
  },
  {
    state: 'AK',
    statefp: '02',
    countyfp: '290',
    countyname: 'Yukon-Koyukuk Census Area',
    classfp: 'H5',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '001',
    countyname: 'Apache County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '003',
    countyname: 'Cochise County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '005',
    countyname: 'Coconino County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '007',
    countyname: 'Gila County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '009',
    countyname: 'Graham County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '011',
    countyname: 'Greenlee County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '012',
    countyname: 'La Paz County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '013',
    countyname: 'Maricopa County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '015',
    countyname: 'Mohave County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '017',
    countyname: 'Navajo County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '019',
    countyname: 'Pima County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '021',
    countyname: 'Pinal County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '023',
    countyname: 'Santa Cruz County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '025',
    countyname: 'Yavapai County',
    classfp: 'H1',
  },
  {
    state: 'AZ',
    statefp: '04',
    countyfp: '027',
    countyname: 'Yuma County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '001',
    countyname: 'Arkansas County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '003',
    countyname: 'Ashley County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '005',
    countyname: 'Baxter County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '007',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '009',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '011',
    countyname: 'Bradley County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '013',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '015',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '017',
    countyname: 'Chicot County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '019',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '021',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '023',
    countyname: 'Cleburne County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '025',
    countyname: 'Cleveland County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '027',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '029',
    countyname: 'Conway County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '031',
    countyname: 'Craighead County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '033',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '035',
    countyname: 'Crittenden County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '037',
    countyname: 'Cross County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '039',
    countyname: 'Dallas County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '041',
    countyname: 'Desha County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '043',
    countyname: 'Drew County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '045',
    countyname: 'Faulkner County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '047',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '049',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '051',
    countyname: 'Garland County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '053',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '055',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '057',
    countyname: 'Hempstead County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '059',
    countyname: 'Hot Spring County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '061',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '063',
    countyname: 'Independence County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '065',
    countyname: 'Izard County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '067',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '069',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '071',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '073',
    countyname: 'Lafayette County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '075',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '077',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '079',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '081',
    countyname: 'Little River County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '083',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '085',
    countyname: 'Lonoke County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '087',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '089',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '091',
    countyname: 'Miller County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '093',
    countyname: 'Mississippi County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '095',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '097',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '099',
    countyname: 'Nevada County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '101',
    countyname: 'Newton County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '103',
    countyname: 'Ouachita County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '105',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '107',
    countyname: 'Phillips County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '109',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '111',
    countyname: 'Poinsett County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '113',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '115',
    countyname: 'Pope County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '117',
    countyname: 'Prairie County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '119',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '121',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '123',
    countyname: 'St. Francis County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '125',
    countyname: 'Saline County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '127',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '129',
    countyname: 'Searcy County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '131',
    countyname: 'Sebastian County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '133',
    countyname: 'Sevier County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '135',
    countyname: 'Sharp County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '137',
    countyname: 'Stone County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '139',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '141',
    countyname: 'Van Buren County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '143',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '145',
    countyname: 'White County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '147',
    countyname: 'Woodruff County',
    classfp: 'H1',
  },
  {
    state: 'AR',
    statefp: '05',
    countyfp: '149',
    countyname: 'Yell County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '001',
    countyname: 'Alameda County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '003',
    countyname: 'Alpine County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '005',
    countyname: 'Amador County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '007',
    countyname: 'Butte County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '009',
    countyname: 'Calaveras County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '011',
    countyname: 'Colusa County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '013',
    countyname: 'Contra Costa County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '015',
    countyname: 'Del Norte County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '017',
    countyname: 'El Dorado County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '019',
    countyname: 'Fresno County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '021',
    countyname: 'Glenn County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '023',
    countyname: 'Humboldt County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '025',
    countyname: 'Imperial County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '027',
    countyname: 'Inyo County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '029',
    countyname: 'Kern County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '031',
    countyname: 'Kings County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '033',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '035',
    countyname: 'Lassen County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '037',
    countyname: 'Los Angeles County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '039',
    countyname: 'Madera County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '041',
    countyname: 'Marin County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '043',
    countyname: 'Mariposa County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '045',
    countyname: 'Mendocino County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '047',
    countyname: 'Merced County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '049',
    countyname: 'Modoc County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '051',
    countyname: 'Mono County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '053',
    countyname: 'Monterey County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '055',
    countyname: 'Napa County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '057',
    countyname: 'Nevada County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '059',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '061',
    countyname: 'Placer County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '063',
    countyname: 'Plumas County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '065',
    countyname: 'Riverside County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '067',
    countyname: 'Sacramento County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '069',
    countyname: 'San Benito County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '071',
    countyname: 'San Bernardino County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '073',
    countyname: 'San Diego County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '075',
    countyname: 'San Francisco County',
    classfp: 'H6',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '077',
    countyname: 'San Joaquin County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '079',
    countyname: 'San Luis Obispo County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '081',
    countyname: 'San Mateo County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '083',
    countyname: 'Santa Barbara County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '085',
    countyname: 'Santa Clara County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '087',
    countyname: 'Santa Cruz County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '089',
    countyname: 'Shasta County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '091',
    countyname: 'Sierra County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '093',
    countyname: 'Siskiyou County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '095',
    countyname: 'Solano County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '097',
    countyname: 'Sonoma County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '099',
    countyname: 'Stanislaus County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '101',
    countyname: 'Sutter County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '103',
    countyname: 'Tehama County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '105',
    countyname: 'Trinity County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '107',
    countyname: 'Tulare County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '109',
    countyname: 'Tuolumne County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '111',
    countyname: 'Ventura County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '113',
    countyname: 'Yolo County',
    classfp: 'H1',
  },
  {
    state: 'CA',
    statefp: '06',
    countyfp: '115',
    countyname: 'Yuba County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '003',
    countyname: 'Alamosa County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '005',
    countyname: 'Arapahoe County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '007',
    countyname: 'Archuleta County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '009',
    countyname: 'Baca County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '011',
    countyname: 'Bent County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '013',
    countyname: 'Boulder County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '014',
    countyname: 'Broomfield County',
    classfp: 'H6',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '015',
    countyname: 'Chaffee County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '017',
    countyname: 'Cheyenne County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '019',
    countyname: 'Clear Creek County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '021',
    countyname: 'Conejos County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '023',
    countyname: 'Costilla County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '025',
    countyname: 'Crowley County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '027',
    countyname: 'Custer County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '029',
    countyname: 'Delta County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '031',
    countyname: 'Denver County',
    classfp: 'H6',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '033',
    countyname: 'Dolores County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '035',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '037',
    countyname: 'Eagle County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '039',
    countyname: 'Elbert County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '041',
    countyname: 'El Paso County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '043',
    countyname: 'Fremont County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '045',
    countyname: 'Garfield County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '047',
    countyname: 'Gilpin County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '049',
    countyname: 'Grand County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '051',
    countyname: 'Gunnison County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '053',
    countyname: 'Hinsdale County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '055',
    countyname: 'Huerfano County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '057',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '059',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '061',
    countyname: 'Kiowa County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '063',
    countyname: 'Kit Carson County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '065',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '067',
    countyname: 'La Plata County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '069',
    countyname: 'Larimer County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '071',
    countyname: 'Las Animas County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '073',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '075',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '077',
    countyname: 'Mesa County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '079',
    countyname: 'Mineral County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '081',
    countyname: 'Moffat County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '083',
    countyname: 'Montezuma County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '085',
    countyname: 'Montrose County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '087',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '089',
    countyname: 'Otero County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '091',
    countyname: 'Ouray County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '093',
    countyname: 'Park County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '095',
    countyname: 'Phillips County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '097',
    countyname: 'Pitkin County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '099',
    countyname: 'Prowers County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '101',
    countyname: 'Pueblo County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '103',
    countyname: 'Rio Blanco County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '105',
    countyname: 'Rio Grande County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '107',
    countyname: 'Routt County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '109',
    countyname: 'Saguache County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '111',
    countyname: 'San Juan County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '113',
    countyname: 'San Miguel County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '115',
    countyname: 'Sedgwick County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '117',
    countyname: 'Summit County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '119',
    countyname: 'Teller County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '121',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '123',
    countyname: 'Weld County',
    classfp: 'H1',
  },
  {
    state: 'CO',
    statefp: '08',
    countyfp: '125',
    countyname: 'Yuma County',
    classfp: 'H1',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '001',
    countyname: 'Fairfield County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '003',
    countyname: 'Hartford County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '005',
    countyname: 'Litchfield County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '007',
    countyname: 'Middlesex County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '009',
    countyname: 'New Haven County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '011',
    countyname: 'New London County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '013',
    countyname: 'Tolland County',
    classfp: 'H4',
  },
  {
    state: 'CT',
    statefp: '09',
    countyfp: '015',
    countyname: 'Windham County',
    classfp: 'H4',
  },
  {
    state: 'DE',
    statefp: '10',
    countyfp: '001',
    countyname: 'Kent County',
    classfp: 'H1',
  },
  {
    state: 'DE',
    statefp: '10',
    countyfp: '003',
    countyname: 'New Castle County',
    classfp: 'H1',
  },
  {
    state: 'DE',
    statefp: '10',
    countyfp: '005',
    countyname: 'Sussex County',
    classfp: 'H1',
  },
  {
    state: 'DC',
    statefp: '11',
    countyfp: '001',
    countyname: 'District of Columbia',
    classfp: 'H6',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '001',
    countyname: 'Alachua County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '003',
    countyname: 'Baker County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '005',
    countyname: 'Bay County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '007',
    countyname: 'Bradford County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '009',
    countyname: 'Brevard County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '011',
    countyname: 'Broward County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '013',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '015',
    countyname: 'Charlotte County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '017',
    countyname: 'Citrus County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '019',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '021',
    countyname: 'Collier County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '023',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '027',
    countyname: 'DeSoto County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '029',
    countyname: 'Dixie County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '031',
    countyname: 'Duval County',
    classfp: 'H6',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '033',
    countyname: 'Escambia County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '035',
    countyname: 'Flagler County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '037',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '039',
    countyname: 'Gadsden County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '041',
    countyname: 'Gilchrist County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '043',
    countyname: 'Glades County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '045',
    countyname: 'Gulf County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '047',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '049',
    countyname: 'Hardee County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '051',
    countyname: 'Hendry County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '053',
    countyname: 'Hernando County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '055',
    countyname: 'Highlands County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '057',
    countyname: 'Hillsborough County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '059',
    countyname: 'Holmes County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '061',
    countyname: 'Indian River County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '063',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '065',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '067',
    countyname: 'Lafayette County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '069',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '071',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '073',
    countyname: 'Leon County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '075',
    countyname: 'Levy County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '077',
    countyname: 'Liberty County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '079',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '081',
    countyname: 'Manatee County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '083',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '085',
    countyname: 'Martin County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '086',
    countyname: 'Miami-Dade County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '087',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '089',
    countyname: 'Nassau County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '091',
    countyname: 'Okaloosa County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '093',
    countyname: 'Okeechobee County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '095',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '097',
    countyname: 'Osceola County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '099',
    countyname: 'Palm Beach County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '101',
    countyname: 'Pasco County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '103',
    countyname: 'Pinellas County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '105',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '107',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '109',
    countyname: 'St. Johns County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '111',
    countyname: 'St. Lucie County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '113',
    countyname: 'Santa Rosa County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '115',
    countyname: 'Sarasota County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '117',
    countyname: 'Seminole County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '119',
    countyname: 'Sumter County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '121',
    countyname: 'Suwannee County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '123',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '125',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '127',
    countyname: 'Volusia County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '129',
    countyname: 'Wakulla County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '131',
    countyname: 'Walton County',
    classfp: 'H1',
  },
  {
    state: 'FL',
    statefp: '12',
    countyfp: '133',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '001',
    countyname: 'Appling County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '003',
    countyname: 'Atkinson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '005',
    countyname: 'Bacon County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '007',
    countyname: 'Baker County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '009',
    countyname: 'Baldwin County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '011',
    countyname: 'Banks County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '013',
    countyname: 'Barrow County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '015',
    countyname: 'Bartow County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '017',
    countyname: 'Ben Hill County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '019',
    countyname: 'Berrien County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '021',
    countyname: 'Bibb County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '023',
    countyname: 'Bleckley County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '025',
    countyname: 'Brantley County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '027',
    countyname: 'Brooks County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '029',
    countyname: 'Bryan County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '031',
    countyname: 'Bulloch County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '033',
    countyname: 'Burke County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '035',
    countyname: 'Butts County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '037',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '039',
    countyname: 'Camden County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '043',
    countyname: 'Candler County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '045',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '047',
    countyname: 'Catoosa County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '049',
    countyname: 'Charlton County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '051',
    countyname: 'Chatham County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '053',
    countyname: 'Chattahoochee County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '055',
    countyname: 'Chattooga County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '057',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '059',
    countyname: 'Clarke County',
    classfp: 'H6',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '061',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '063',
    countyname: 'Clayton County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '065',
    countyname: 'Clinch County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '067',
    countyname: 'Cobb County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '069',
    countyname: 'Coffee County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '071',
    countyname: 'Colquitt County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '073',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '075',
    countyname: 'Cook County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '077',
    countyname: 'Coweta County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '079',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '081',
    countyname: 'Crisp County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '083',
    countyname: 'Dade County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '085',
    countyname: 'Dawson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '087',
    countyname: 'Decatur County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '089',
    countyname: 'DeKalb County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '091',
    countyname: 'Dodge County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '093',
    countyname: 'Dooly County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '095',
    countyname: 'Dougherty County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '097',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '099',
    countyname: 'Early County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '101',
    countyname: 'Echols County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '103',
    countyname: 'Effingham County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '105',
    countyname: 'Elbert County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '107',
    countyname: 'Emanuel County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '109',
    countyname: 'Evans County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '111',
    countyname: 'Fannin County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '113',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '115',
    countyname: 'Floyd County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '117',
    countyname: 'Forsyth County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '119',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '121',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '123',
    countyname: 'Gilmer County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '125',
    countyname: 'Glascock County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '127',
    countyname: 'Glynn County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '129',
    countyname: 'Gordon County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '131',
    countyname: 'Grady County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '133',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '135',
    countyname: 'Gwinnett County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '137',
    countyname: 'Habersham County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '139',
    countyname: 'Hall County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '141',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '143',
    countyname: 'Haralson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '145',
    countyname: 'Harris County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '147',
    countyname: 'Hart County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '149',
    countyname: 'Heard County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '151',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '153',
    countyname: 'Houston County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '155',
    countyname: 'Irwin County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '157',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '159',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '161',
    countyname: 'Jeff Davis County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '163',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '165',
    countyname: 'Jenkins County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '167',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '169',
    countyname: 'Jones County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '171',
    countyname: 'Lamar County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '173',
    countyname: 'Lanier County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '175',
    countyname: 'Laurens County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '177',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '179',
    countyname: 'Liberty County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '181',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '183',
    countyname: 'Long County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '185',
    countyname: 'Lowndes County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '187',
    countyname: 'Lumpkin County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '189',
    countyname: 'McDuffie County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '191',
    countyname: 'McIntosh County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '193',
    countyname: 'Macon County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '195',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '197',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '199',
    countyname: 'Meriwether County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '201',
    countyname: 'Miller County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '205',
    countyname: 'Mitchell County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '207',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '209',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '211',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '213',
    countyname: 'Murray County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '215',
    countyname: 'Muscogee County',
    classfp: 'H6',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '217',
    countyname: 'Newton County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '219',
    countyname: 'Oconee County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '221',
    countyname: 'Oglethorpe County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '223',
    countyname: 'Paulding County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '225',
    countyname: 'Peach County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '227',
    countyname: 'Pickens County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '229',
    countyname: 'Pierce County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '231',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '233',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '235',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '237',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '239',
    countyname: 'Quitman County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '241',
    countyname: 'Rabun County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '243',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '245',
    countyname: 'Richmond County',
    classfp: 'H6',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '247',
    countyname: 'Rockdale County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '249',
    countyname: 'Schley County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '251',
    countyname: 'Screven County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '253',
    countyname: 'Seminole County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '255',
    countyname: 'Spalding County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '257',
    countyname: 'Stephens County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '259',
    countyname: 'Stewart County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '261',
    countyname: 'Sumter County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '263',
    countyname: 'Talbot County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '265',
    countyname: 'Taliaferro County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '267',
    countyname: 'Tattnall County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '269',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '271',
    countyname: 'Telfair County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '273',
    countyname: 'Terrell County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '275',
    countyname: 'Thomas County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '277',
    countyname: 'Tift County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '279',
    countyname: 'Toombs County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '281',
    countyname: 'Towns County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '283',
    countyname: 'Treutlen County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '285',
    countyname: 'Troup County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '287',
    countyname: 'Turner County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '289',
    countyname: 'Twiggs County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '291',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '293',
    countyname: 'Upson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '295',
    countyname: 'Walker County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '297',
    countyname: 'Walton County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '299',
    countyname: 'Ware County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '301',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '303',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '305',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '307',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '309',
    countyname: 'Wheeler County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '311',
    countyname: 'White County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '313',
    countyname: 'Whitfield County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '315',
    countyname: 'Wilcox County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '317',
    countyname: 'Wilkes County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '319',
    countyname: 'Wilkinson County',
    classfp: 'H1',
  },
  {
    state: 'GA',
    statefp: '13',
    countyfp: '321',
    countyname: 'Worth County',
    classfp: 'H1',
  },
  {
    state: 'HI',
    statefp: '15',
    countyfp: '001',
    countyname: 'Hawaii County',
    classfp: 'H1',
  },
  {
    state: 'HI',
    statefp: '15',
    countyfp: '003',
    countyname: 'Honolulu County',
    classfp: 'H1',
  },
  {
    state: 'HI',
    statefp: '15',
    countyfp: '005',
    countyname: 'Kalawao County',
    classfp: 'H4',
  },
  {
    state: 'HI',
    statefp: '15',
    countyfp: '007',
    countyname: 'Kauai County',
    classfp: 'H1',
  },
  {
    state: 'HI',
    statefp: '15',
    countyfp: '009',
    countyname: 'Maui County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '001',
    countyname: 'Ada County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '003',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '005',
    countyname: 'Bannock County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '007',
    countyname: 'Bear Lake County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '009',
    countyname: 'Benewah County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '011',
    countyname: 'Bingham County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '013',
    countyname: 'Blaine County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '015',
    countyname: 'Boise County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '017',
    countyname: 'Bonner County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '019',
    countyname: 'Bonneville County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '021',
    countyname: 'Boundary County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '023',
    countyname: 'Butte County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '025',
    countyname: 'Camas County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '027',
    countyname: 'Canyon County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '029',
    countyname: 'Caribou County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '031',
    countyname: 'Cassia County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '033',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '035',
    countyname: 'Clearwater County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '037',
    countyname: 'Custer County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '039',
    countyname: 'Elmore County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '041',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '043',
    countyname: 'Fremont County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '045',
    countyname: 'Gem County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '047',
    countyname: 'Gooding County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '049',
    countyname: 'Idaho County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '051',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '053',
    countyname: 'Jerome County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '055',
    countyname: 'Kootenai County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '057',
    countyname: 'Latah County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '059',
    countyname: 'Lemhi County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '061',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '063',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '065',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '067',
    countyname: 'Minidoka County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '069',
    countyname: 'Nez Perce County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '071',
    countyname: 'Oneida County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '073',
    countyname: 'Owyhee County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '075',
    countyname: 'Payette County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '077',
    countyname: 'Power County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '079',
    countyname: 'Shoshone County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '081',
    countyname: 'Teton County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '083',
    countyname: 'Twin Falls County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '085',
    countyname: 'Valley County',
    classfp: 'H1',
  },
  {
    state: 'ID',
    statefp: '16',
    countyfp: '087',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '003',
    countyname: 'Alexander County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '005',
    countyname: 'Bond County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '007',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '009',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '011',
    countyname: 'Bureau County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '013',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '015',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '017',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '019',
    countyname: 'Champaign County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '021',
    countyname: 'Christian County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '023',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '025',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '027',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '029',
    countyname: 'Coles County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '031',
    countyname: 'Cook County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '033',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '035',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '037',
    countyname: 'DeKalb County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '039',
    countyname: 'De Witt County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '041',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '043',
    countyname: 'DuPage County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '045',
    countyname: 'Edgar County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '047',
    countyname: 'Edwards County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '049',
    countyname: 'Effingham County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '051',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '053',
    countyname: 'Ford County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '055',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '057',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '059',
    countyname: 'Gallatin County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '061',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '063',
    countyname: 'Grundy County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '065',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '067',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '069',
    countyname: 'Hardin County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '071',
    countyname: 'Henderson County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '073',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '075',
    countyname: 'Iroquois County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '077',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '079',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '081',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '083',
    countyname: 'Jersey County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '085',
    countyname: 'Jo Daviess County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '087',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '089',
    countyname: 'Kane County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '091',
    countyname: 'Kankakee County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '093',
    countyname: 'Kendall County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '095',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '097',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '099',
    countyname: 'LaSalle County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '101',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '103',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '105',
    countyname: 'Livingston County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '107',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '109',
    countyname: 'McDonough County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '111',
    countyname: 'McHenry County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '113',
    countyname: 'McLean County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '115',
    countyname: 'Macon County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '117',
    countyname: 'Macoupin County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '119',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '121',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '123',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '125',
    countyname: 'Mason County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '127',
    countyname: 'Massac County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '129',
    countyname: 'Menard County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '131',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '133',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '135',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '137',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '139',
    countyname: 'Moultrie County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '141',
    countyname: 'Ogle County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '143',
    countyname: 'Peoria County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '145',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '147',
    countyname: 'Piatt County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '149',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '151',
    countyname: 'Pope County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '153',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '155',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '157',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '159',
    countyname: 'Richland County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '161',
    countyname: 'Rock Island County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '163',
    countyname: 'St. Clair County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '165',
    countyname: 'Saline County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '167',
    countyname: 'Sangamon County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '169',
    countyname: 'Schuyler County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '171',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '173',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '175',
    countyname: 'Stark County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '177',
    countyname: 'Stephenson County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '179',
    countyname: 'Tazewell County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '181',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '183',
    countyname: 'Vermilion County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '185',
    countyname: 'Wabash County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '187',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '189',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '191',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '193',
    countyname: 'White County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '195',
    countyname: 'Whiteside County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '197',
    countyname: 'Will County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '199',
    countyname: 'Williamson County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '201',
    countyname: 'Winnebago County',
    classfp: 'H1',
  },
  {
    state: 'IL',
    statefp: '17',
    countyfp: '203',
    countyname: 'Woodford County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '003',
    countyname: 'Allen County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '005',
    countyname: 'Bartholomew County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '007',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '009',
    countyname: 'Blackford County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '011',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '013',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '015',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '017',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '019',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '021',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '023',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '025',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '027',
    countyname: 'Daviess County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '029',
    countyname: 'Dearborn County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '031',
    countyname: 'Decatur County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '033',
    countyname: 'DeKalb County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '035',
    countyname: 'Delaware County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '037',
    countyname: 'Dubois County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '039',
    countyname: 'Elkhart County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '041',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '043',
    countyname: 'Floyd County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '045',
    countyname: 'Fountain County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '047',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '049',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '051',
    countyname: 'Gibson County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '053',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '055',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '057',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '059',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '061',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '063',
    countyname: 'Hendricks County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '065',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '067',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '069',
    countyname: 'Huntington County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '071',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '073',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '075',
    countyname: 'Jay County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '077',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '079',
    countyname: 'Jennings County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '081',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '083',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '085',
    countyname: 'Kosciusko County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '087',
    countyname: 'LaGrange County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '089',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '091',
    countyname: 'LaPorte County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '093',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '095',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '097',
    countyname: 'Marion County',
    classfp: 'H6',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '099',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '101',
    countyname: 'Martin County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '103',
    countyname: 'Miami County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '105',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '107',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '109',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '111',
    countyname: 'Newton County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '113',
    countyname: 'Noble County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '115',
    countyname: 'Ohio County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '117',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '119',
    countyname: 'Owen County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '121',
    countyname: 'Parke County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '123',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '125',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '127',
    countyname: 'Porter County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '129',
    countyname: 'Posey County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '131',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '133',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '135',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '137',
    countyname: 'Ripley County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '139',
    countyname: 'Rush County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '141',
    countyname: 'St. Joseph County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '143',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '145',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '147',
    countyname: 'Spencer County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '149',
    countyname: 'Starke County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '151',
    countyname: 'Steuben County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '153',
    countyname: 'Sullivan County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '155',
    countyname: 'Switzerland County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '157',
    countyname: 'Tippecanoe County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '159',
    countyname: 'Tipton County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '161',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '163',
    countyname: 'Vanderburgh County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '165',
    countyname: 'Vermillion County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '167',
    countyname: 'Vigo County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '169',
    countyname: 'Wabash County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '171',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '173',
    countyname: 'Warrick County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '175',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '177',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '179',
    countyname: 'Wells County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '181',
    countyname: 'White County',
    classfp: 'H1',
  },
  {
    state: 'IN',
    statefp: '18',
    countyfp: '183',
    countyname: 'Whitley County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '001',
    countyname: 'Adair County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '003',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '005',
    countyname: 'Allamakee County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '007',
    countyname: 'Appanoose County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '009',
    countyname: 'Audubon County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '011',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '013',
    countyname: 'Black Hawk County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '015',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '017',
    countyname: 'Bremer County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '019',
    countyname: 'Buchanan County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '021',
    countyname: 'Buena Vista County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '023',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '025',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '027',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '029',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '031',
    countyname: 'Cedar County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '033',
    countyname: 'Cerro Gordo County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '035',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '037',
    countyname: 'Chickasaw County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '039',
    countyname: 'Clarke County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '041',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '043',
    countyname: 'Clayton County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '045',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '047',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '049',
    countyname: 'Dallas County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '051',
    countyname: 'Davis County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '053',
    countyname: 'Decatur County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '055',
    countyname: 'Delaware County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '057',
    countyname: 'Des Moines County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '059',
    countyname: 'Dickinson County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '061',
    countyname: 'Dubuque County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '063',
    countyname: 'Emmet County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '065',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '067',
    countyname: 'Floyd County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '069',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '071',
    countyname: 'Fremont County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '073',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '075',
    countyname: 'Grundy County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '077',
    countyname: 'Guthrie County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '079',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '081',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '083',
    countyname: 'Hardin County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '085',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '087',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '089',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '091',
    countyname: 'Humboldt County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '093',
    countyname: 'Ida County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '095',
    countyname: 'Iowa County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '097',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '099',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '101',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '103',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '105',
    countyname: 'Jones County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '107',
    countyname: 'Keokuk County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '109',
    countyname: 'Kossuth County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '111',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '113',
    countyname: 'Linn County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '115',
    countyname: 'Louisa County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '117',
    countyname: 'Lucas County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '119',
    countyname: 'Lyon County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '121',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '123',
    countyname: 'Mahaska County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '125',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '127',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '129',
    countyname: 'Mills County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '131',
    countyname: 'Mitchell County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '133',
    countyname: 'Monona County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '135',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '137',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '139',
    countyname: 'Muscatine County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '141',
    countyname: "O'Brien County",
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '143',
    countyname: 'Osceola County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '145',
    countyname: 'Page County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '147',
    countyname: 'Palo Alto County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '149',
    countyname: 'Plymouth County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '151',
    countyname: 'Pocahontas County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '153',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '155',
    countyname: 'Pottawattamie County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '157',
    countyname: 'Poweshiek County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '159',
    countyname: 'Ringgold County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '161',
    countyname: 'Sac County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '163',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '165',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '167',
    countyname: 'Sioux County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '169',
    countyname: 'Story County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '171',
    countyname: 'Tama County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '173',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '175',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '177',
    countyname: 'Van Buren County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '179',
    countyname: 'Wapello County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '181',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '183',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '185',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '187',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '189',
    countyname: 'Winnebago County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '191',
    countyname: 'Winneshiek County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '193',
    countyname: 'Woodbury County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '195',
    countyname: 'Worth County',
    classfp: 'H1',
  },
  {
    state: 'IA',
    statefp: '19',
    countyfp: '197',
    countyname: 'Wright County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '001',
    countyname: 'Allen County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '003',
    countyname: 'Anderson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '005',
    countyname: 'Atchison County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '007',
    countyname: 'Barber County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '009',
    countyname: 'Barton County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '011',
    countyname: 'Bourbon County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '013',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '015',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '017',
    countyname: 'Chase County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '019',
    countyname: 'Chautauqua County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '021',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '023',
    countyname: 'Cheyenne County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '025',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '027',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '029',
    countyname: 'Cloud County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '031',
    countyname: 'Coffey County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '033',
    countyname: 'Comanche County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '035',
    countyname: 'Cowley County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '037',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '039',
    countyname: 'Decatur County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '041',
    countyname: 'Dickinson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '043',
    countyname: 'Doniphan County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '045',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '047',
    countyname: 'Edwards County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '049',
    countyname: 'Elk County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '051',
    countyname: 'Ellis County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '053',
    countyname: 'Ellsworth County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '055',
    countyname: 'Finney County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '057',
    countyname: 'Ford County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '059',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '061',
    countyname: 'Geary County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '063',
    countyname: 'Gove County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '065',
    countyname: 'Graham County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '067',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '069',
    countyname: 'Gray County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '071',
    countyname: 'Greeley County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '073',
    countyname: 'Greenwood County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '075',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '077',
    countyname: 'Harper County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '079',
    countyname: 'Harvey County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '081',
    countyname: 'Haskell County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '083',
    countyname: 'Hodgeman County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '085',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '087',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '089',
    countyname: 'Jewell County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '091',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '093',
    countyname: 'Kearny County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '095',
    countyname: 'Kingman County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '097',
    countyname: 'Kiowa County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '099',
    countyname: 'Labette County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '101',
    countyname: 'Lane County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '103',
    countyname: 'Leavenworth County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '105',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '107',
    countyname: 'Linn County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '109',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '111',
    countyname: 'Lyon County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '113',
    countyname: 'McPherson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '115',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '117',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '119',
    countyname: 'Meade County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '121',
    countyname: 'Miami County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '123',
    countyname: 'Mitchell County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '125',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '127',
    countyname: 'Morris County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '129',
    countyname: 'Morton County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '131',
    countyname: 'Nemaha County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '133',
    countyname: 'Neosho County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '135',
    countyname: 'Ness County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '137',
    countyname: 'Norton County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '139',
    countyname: 'Osage County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '141',
    countyname: 'Osborne County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '143',
    countyname: 'Ottawa County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '145',
    countyname: 'Pawnee County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '147',
    countyname: 'Phillips County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '149',
    countyname: 'Pottawatomie County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '151',
    countyname: 'Pratt County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '153',
    countyname: 'Rawlins County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '155',
    countyname: 'Reno County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '157',
    countyname: 'Republic County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '159',
    countyname: 'Rice County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '161',
    countyname: 'Riley County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '163',
    countyname: 'Rooks County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '165',
    countyname: 'Rush County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '167',
    countyname: 'Russell County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '169',
    countyname: 'Saline County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '171',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '173',
    countyname: 'Sedgwick County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '175',
    countyname: 'Seward County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '177',
    countyname: 'Shawnee County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '179',
    countyname: 'Sheridan County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '181',
    countyname: 'Sherman County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '183',
    countyname: 'Smith County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '185',
    countyname: 'Stafford County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '187',
    countyname: 'Stanton County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '189',
    countyname: 'Stevens County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '191',
    countyname: 'Sumner County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '193',
    countyname: 'Thomas County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '195',
    countyname: 'Trego County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '197',
    countyname: 'Wabaunsee County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '199',
    countyname: 'Wallace County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '201',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '203',
    countyname: 'Wichita County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '205',
    countyname: 'Wilson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '207',
    countyname: 'Woodson County',
    classfp: 'H1',
  },
  {
    state: 'KS',
    statefp: '20',
    countyfp: '209',
    countyname: 'Wyandotte County',
    classfp: 'H6',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '001',
    countyname: 'Adair County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '003',
    countyname: 'Allen County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '005',
    countyname: 'Anderson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '007',
    countyname: 'Ballard County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '009',
    countyname: 'Barren County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '011',
    countyname: 'Bath County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '013',
    countyname: 'Bell County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '015',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '017',
    countyname: 'Bourbon County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '019',
    countyname: 'Boyd County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '021',
    countyname: 'Boyle County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '023',
    countyname: 'Bracken County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '025',
    countyname: 'Breathitt County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '027',
    countyname: 'Breckinridge County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '029',
    countyname: 'Bullitt County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '031',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '033',
    countyname: 'Caldwell County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '035',
    countyname: 'Calloway County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '037',
    countyname: 'Campbell County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '039',
    countyname: 'Carlisle County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '041',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '043',
    countyname: 'Carter County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '045',
    countyname: 'Casey County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '047',
    countyname: 'Christian County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '049',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '051',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '053',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '055',
    countyname: 'Crittenden County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '057',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '059',
    countyname: 'Daviess County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '061',
    countyname: 'Edmonson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '063',
    countyname: 'Elliott County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '065',
    countyname: 'Estill County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '067',
    countyname: 'Fayette County',
    classfp: 'H6',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '069',
    countyname: 'Fleming County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '071',
    countyname: 'Floyd County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '073',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '075',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '077',
    countyname: 'Gallatin County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '079',
    countyname: 'Garrard County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '081',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '083',
    countyname: 'Graves County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '085',
    countyname: 'Grayson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '087',
    countyname: 'Green County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '089',
    countyname: 'Greenup County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '091',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '093',
    countyname: 'Hardin County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '095',
    countyname: 'Harlan County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '097',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '099',
    countyname: 'Hart County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '101',
    countyname: 'Henderson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '103',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '105',
    countyname: 'Hickman County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '107',
    countyname: 'Hopkins County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '109',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '111',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '113',
    countyname: 'Jessamine County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '115',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '117',
    countyname: 'Kenton County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '119',
    countyname: 'Knott County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '121',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '123',
    countyname: 'Larue County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '125',
    countyname: 'Laurel County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '127',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '129',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '131',
    countyname: 'Leslie County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '133',
    countyname: 'Letcher County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '135',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '137',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '139',
    countyname: 'Livingston County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '141',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '143',
    countyname: 'Lyon County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '145',
    countyname: 'McCracken County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '147',
    countyname: 'McCreary County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '149',
    countyname: 'McLean County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '151',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '153',
    countyname: 'Magoffin County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '155',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '157',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '159',
    countyname: 'Martin County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '161',
    countyname: 'Mason County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '163',
    countyname: 'Meade County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '165',
    countyname: 'Menifee County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '167',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '169',
    countyname: 'Metcalfe County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '171',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '173',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '175',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '177',
    countyname: 'Muhlenberg County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '179',
    countyname: 'Nelson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '181',
    countyname: 'Nicholas County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '183',
    countyname: 'Ohio County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '185',
    countyname: 'Oldham County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '187',
    countyname: 'Owen County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '189',
    countyname: 'Owsley County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '191',
    countyname: 'Pendleton County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '193',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '195',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '197',
    countyname: 'Powell County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '199',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '201',
    countyname: 'Robertson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '203',
    countyname: 'Rockcastle County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '205',
    countyname: 'Rowan County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '207',
    countyname: 'Russell County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '209',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '211',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '213',
    countyname: 'Simpson County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '215',
    countyname: 'Spencer County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '217',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '219',
    countyname: 'Todd County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '221',
    countyname: 'Trigg County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '223',
    countyname: 'Trimble County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '225',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '227',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '229',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '231',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '233',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '235',
    countyname: 'Whitley County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '237',
    countyname: 'Wolfe County',
    classfp: 'H1',
  },
  {
    state: 'KY',
    statefp: '21',
    countyfp: '239',
    countyname: 'Woodford County',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '001',
    countyname: 'Acadia Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '003',
    countyname: 'Allen Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '005',
    countyname: 'Ascension Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '007',
    countyname: 'Assumption Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '009',
    countyname: 'Avoyelles Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '011',
    countyname: 'Beauregard Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '013',
    countyname: 'Bienville Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '015',
    countyname: 'Bossier Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '017',
    countyname: 'Caddo Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '019',
    countyname: 'Calcasieu Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '021',
    countyname: 'Caldwell Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '023',
    countyname: 'Cameron Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '025',
    countyname: 'Catahoula Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '027',
    countyname: 'Claiborne Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '029',
    countyname: 'Concordia Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '031',
    countyname: 'De Soto Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '033',
    countyname: 'East Baton Rouge Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '035',
    countyname: 'East Carroll Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '037',
    countyname: 'East Feliciana Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '039',
    countyname: 'Evangeline Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '041',
    countyname: 'Franklin Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '043',
    countyname: 'Grant Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '045',
    countyname: 'Iberia Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '047',
    countyname: 'Iberville Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '049',
    countyname: 'Jackson Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '051',
    countyname: 'Jefferson Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '053',
    countyname: 'Jefferson Davis Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '055',
    countyname: 'Lafayette Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '057',
    countyname: 'Lafourche Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '059',
    countyname: 'La Salle Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '061',
    countyname: 'Lincoln Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '063',
    countyname: 'Livingston Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '065',
    countyname: 'Madison Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '067',
    countyname: 'Morehouse Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '069',
    countyname: 'Natchitoches Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '071',
    countyname: 'Orleans Parish',
    classfp: 'H6',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '073',
    countyname: 'Ouachita Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '075',
    countyname: 'Plaquemines Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '077',
    countyname: 'Pointe Coupee Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '079',
    countyname: 'Rapides Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '081',
    countyname: 'Red River Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '083',
    countyname: 'Richland Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '085',
    countyname: 'Sabine Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '087',
    countyname: 'St. Bernard Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '089',
    countyname: 'St. Charles Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '091',
    countyname: 'St. Helena Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '093',
    countyname: 'St. James Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '095',
    countyname: 'St. John the Baptist Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '097',
    countyname: 'St. Landry Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '099',
    countyname: 'St. Martin Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '101',
    countyname: 'St. Mary Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '103',
    countyname: 'St. Tammany Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '105',
    countyname: 'Tangipahoa Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '107',
    countyname: 'Tensas Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '109',
    countyname: 'Terrebonne Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '111',
    countyname: 'Union Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '113',
    countyname: 'Vermilion Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '115',
    countyname: 'Vernon Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '117',
    countyname: 'Washington Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '119',
    countyname: 'Webster Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '121',
    countyname: 'West Baton Rouge Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '123',
    countyname: 'West Carroll Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '125',
    countyname: 'West Feliciana Parish',
    classfp: 'H1',
  },
  {
    state: 'LA',
    statefp: '22',
    countyfp: '127',
    countyname: 'Winn Parish',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '001',
    countyname: 'Androscoggin County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '003',
    countyname: 'Aroostook County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '005',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '007',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '009',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '011',
    countyname: 'Kennebec County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '013',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '015',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '017',
    countyname: 'Oxford County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '019',
    countyname: 'Penobscot County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '021',
    countyname: 'Piscataquis County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '023',
    countyname: 'Sagadahoc County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '025',
    countyname: 'Somerset County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '027',
    countyname: 'Waldo County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '029',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'ME',
    statefp: '23',
    countyfp: '031',
    countyname: 'York County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '001',
    countyname: 'Allegany County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '003',
    countyname: 'Anne Arundel County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '005',
    countyname: 'Baltimore County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '009',
    countyname: 'Calvert County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '011',
    countyname: 'Caroline County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '013',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '015',
    countyname: 'Cecil County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '017',
    countyname: 'Charles County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '019',
    countyname: 'Dorchester County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '021',
    countyname: 'Frederick County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '023',
    countyname: 'Garrett County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '025',
    countyname: 'Harford County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '027',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '029',
    countyname: 'Kent County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '031',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '033',
    countyname: "Prince George's County",
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '035',
    countyname: "Queen Anne's County",
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '037',
    countyname: "St. Mary's County",
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '039',
    countyname: 'Somerset County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '041',
    countyname: 'Talbot County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '043',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '045',
    countyname: 'Wicomico County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '047',
    countyname: 'Worcester County',
    classfp: 'H1',
  },
  {
    state: 'MD',
    statefp: '24',
    countyfp: '510',
    countyname: 'Baltimore city',
    classfp: 'C7',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '001',
    countyname: 'Barnstable County',
    classfp: 'H1',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '003',
    countyname: 'Berkshire County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '005',
    countyname: 'Bristol County',
    classfp: 'H1',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '007',
    countyname: 'Dukes County',
    classfp: 'H1',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '009',
    countyname: 'Essex County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '011',
    countyname: 'Franklin County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '013',
    countyname: 'Hampden County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '015',
    countyname: 'Hampshire County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '017',
    countyname: 'Middlesex County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '019',
    countyname: 'Nantucket County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '021',
    countyname: 'Norfolk County',
    classfp: 'H1',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '023',
    countyname: 'Plymouth County',
    classfp: 'H1',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '025',
    countyname: 'Suffolk County',
    classfp: 'H4',
  },
  {
    state: 'MA',
    statefp: '25',
    countyfp: '027',
    countyname: 'Worcester County',
    classfp: 'H4',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '001',
    countyname: 'Alcona County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '003',
    countyname: 'Alger County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '005',
    countyname: 'Allegan County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '007',
    countyname: 'Alpena County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '009',
    countyname: 'Antrim County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '011',
    countyname: 'Arenac County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '013',
    countyname: 'Baraga County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '015',
    countyname: 'Barry County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '017',
    countyname: 'Bay County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '019',
    countyname: 'Benzie County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '021',
    countyname: 'Berrien County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '023',
    countyname: 'Branch County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '025',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '027',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '029',
    countyname: 'Charlevoix County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '031',
    countyname: 'Cheboygan County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '033',
    countyname: 'Chippewa County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '035',
    countyname: 'Clare County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '037',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '039',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '041',
    countyname: 'Delta County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '043',
    countyname: 'Dickinson County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '045',
    countyname: 'Eaton County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '047',
    countyname: 'Emmet County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '049',
    countyname: 'Genesee County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '051',
    countyname: 'Gladwin County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '053',
    countyname: 'Gogebic County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '055',
    countyname: 'Grand Traverse County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '057',
    countyname: 'Gratiot County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '059',
    countyname: 'Hillsdale County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '061',
    countyname: 'Houghton County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '063',
    countyname: 'Huron County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '065',
    countyname: 'Ingham County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '067',
    countyname: 'Ionia County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '069',
    countyname: 'Iosco County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '071',
    countyname: 'Iron County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '073',
    countyname: 'Isabella County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '075',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '077',
    countyname: 'Kalamazoo County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '079',
    countyname: 'Kalkaska County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '081',
    countyname: 'Kent County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '083',
    countyname: 'Keweenaw County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '085',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '087',
    countyname: 'Lapeer County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '089',
    countyname: 'Leelanau County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '091',
    countyname: 'Lenawee County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '093',
    countyname: 'Livingston County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '095',
    countyname: 'Luce County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '097',
    countyname: 'Mackinac County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '099',
    countyname: 'Macomb County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '101',
    countyname: 'Manistee County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '103',
    countyname: 'Marquette County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '105',
    countyname: 'Mason County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '107',
    countyname: 'Mecosta County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '109',
    countyname: 'Menominee County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '111',
    countyname: 'Midland County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '113',
    countyname: 'Missaukee County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '115',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '117',
    countyname: 'Montcalm County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '119',
    countyname: 'Montmorency County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '121',
    countyname: 'Muskegon County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '123',
    countyname: 'Newaygo County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '125',
    countyname: 'Oakland County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '127',
    countyname: 'Oceana County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '129',
    countyname: 'Ogemaw County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '131',
    countyname: 'Ontonagon County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '133',
    countyname: 'Osceola County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '135',
    countyname: 'Oscoda County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '137',
    countyname: 'Otsego County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '139',
    countyname: 'Ottawa County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '141',
    countyname: 'Presque Isle County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '143',
    countyname: 'Roscommon County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '145',
    countyname: 'Saginaw County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '147',
    countyname: 'St. Clair County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '149',
    countyname: 'St. Joseph County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '151',
    countyname: 'Sanilac County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '153',
    countyname: 'Schoolcraft County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '155',
    countyname: 'Shiawassee County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '157',
    countyname: 'Tuscola County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '159',
    countyname: 'Van Buren County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '161',
    countyname: 'Washtenaw County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '163',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'MI',
    statefp: '26',
    countyfp: '165',
    countyname: 'Wexford County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '001',
    countyname: 'Aitkin County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '003',
    countyname: 'Anoka County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '005',
    countyname: 'Becker County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '007',
    countyname: 'Beltrami County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '009',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '011',
    countyname: 'Big Stone County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '013',
    countyname: 'Blue Earth County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '015',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '017',
    countyname: 'Carlton County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '019',
    countyname: 'Carver County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '021',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '023',
    countyname: 'Chippewa County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '025',
    countyname: 'Chisago County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '027',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '029',
    countyname: 'Clearwater County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '031',
    countyname: 'Cook County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '033',
    countyname: 'Cottonwood County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '035',
    countyname: 'Crow Wing County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '037',
    countyname: 'Dakota County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '039',
    countyname: 'Dodge County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '041',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '043',
    countyname: 'Faribault County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '045',
    countyname: 'Fillmore County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '047',
    countyname: 'Freeborn County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '049',
    countyname: 'Goodhue County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '051',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '053',
    countyname: 'Hennepin County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '055',
    countyname: 'Houston County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '057',
    countyname: 'Hubbard County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '059',
    countyname: 'Isanti County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '061',
    countyname: 'Itasca County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '063',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '065',
    countyname: 'Kanabec County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '067',
    countyname: 'Kandiyohi County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '069',
    countyname: 'Kittson County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '071',
    countyname: 'Koochiching County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '073',
    countyname: 'Lac qui Parle County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '075',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '077',
    countyname: 'Lake of the Woods County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '079',
    countyname: 'Le Sueur County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '081',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '083',
    countyname: 'Lyon County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '085',
    countyname: 'McLeod County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '087',
    countyname: 'Mahnomen County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '089',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '091',
    countyname: 'Martin County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '093',
    countyname: 'Meeker County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '095',
    countyname: 'Mille Lacs County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '097',
    countyname: 'Morrison County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '099',
    countyname: 'Mower County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '101',
    countyname: 'Murray County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '103',
    countyname: 'Nicollet County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '105',
    countyname: 'Nobles County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '107',
    countyname: 'Norman County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '109',
    countyname: 'Olmsted County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '111',
    countyname: 'Otter Tail County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '113',
    countyname: 'Pennington County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '115',
    countyname: 'Pine County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '117',
    countyname: 'Pipestone County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '119',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '121',
    countyname: 'Pope County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '123',
    countyname: 'Ramsey County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '125',
    countyname: 'Red Lake County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '127',
    countyname: 'Redwood County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '129',
    countyname: 'Renville County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '131',
    countyname: 'Rice County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '133',
    countyname: 'Rock County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '135',
    countyname: 'Roseau County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '137',
    countyname: 'St. Louis County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '139',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '141',
    countyname: 'Sherburne County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '143',
    countyname: 'Sibley County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '145',
    countyname: 'Stearns County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '147',
    countyname: 'Steele County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '149',
    countyname: 'Stevens County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '151',
    countyname: 'Swift County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '153',
    countyname: 'Todd County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '155',
    countyname: 'Traverse County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '157',
    countyname: 'Wabasha County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '159',
    countyname: 'Wadena County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '161',
    countyname: 'Waseca County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '163',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '165',
    countyname: 'Watonwan County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '167',
    countyname: 'Wilkin County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '169',
    countyname: 'Winona County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '171',
    countyname: 'Wright County',
    classfp: 'H1',
  },
  {
    state: 'MN',
    statefp: '27',
    countyfp: '173',
    countyname: 'Yellow Medicine County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '003',
    countyname: 'Alcorn County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '005',
    countyname: 'Amite County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '007',
    countyname: 'Attala County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '009',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '011',
    countyname: 'Bolivar County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '013',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '015',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '017',
    countyname: 'Chickasaw County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '019',
    countyname: 'Choctaw County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '021',
    countyname: 'Claiborne County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '023',
    countyname: 'Clarke County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '025',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '027',
    countyname: 'Coahoma County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '029',
    countyname: 'Copiah County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '031',
    countyname: 'Covington County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '033',
    countyname: 'DeSoto County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '035',
    countyname: 'Forrest County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '037',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '039',
    countyname: 'George County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '041',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '043',
    countyname: 'Grenada County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '045',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '047',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '049',
    countyname: 'Hinds County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '051',
    countyname: 'Holmes County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '053',
    countyname: 'Humphreys County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '055',
    countyname: 'Issaquena County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '057',
    countyname: 'Itawamba County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '059',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '061',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '063',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '065',
    countyname: 'Jefferson Davis County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '067',
    countyname: 'Jones County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '069',
    countyname: 'Kemper County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '071',
    countyname: 'Lafayette County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '073',
    countyname: 'Lamar County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '075',
    countyname: 'Lauderdale County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '077',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '079',
    countyname: 'Leake County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '081',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '083',
    countyname: 'Leflore County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '085',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '087',
    countyname: 'Lowndes County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '089',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '091',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '093',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '095',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '097',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '099',
    countyname: 'Neshoba County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '101',
    countyname: 'Newton County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '103',
    countyname: 'Noxubee County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '105',
    countyname: 'Oktibbeha County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '107',
    countyname: 'Panola County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '109',
    countyname: 'Pearl River County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '111',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '113',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '115',
    countyname: 'Pontotoc County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '117',
    countyname: 'Prentiss County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '119',
    countyname: 'Quitman County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '121',
    countyname: 'Rankin County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '123',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '125',
    countyname: 'Sharkey County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '127',
    countyname: 'Simpson County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '129',
    countyname: 'Smith County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '131',
    countyname: 'Stone County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '133',
    countyname: 'Sunflower County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '135',
    countyname: 'Tallahatchie County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '137',
    countyname: 'Tate County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '139',
    countyname: 'Tippah County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '141',
    countyname: 'Tishomingo County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '143',
    countyname: 'Tunica County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '145',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '147',
    countyname: 'Walthall County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '149',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '151',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '153',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '155',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '157',
    countyname: 'Wilkinson County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '159',
    countyname: 'Winston County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '161',
    countyname: 'Yalobusha County',
    classfp: 'H1',
  },
  {
    state: 'MS',
    statefp: '28',
    countyfp: '163',
    countyname: 'Yazoo County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '001',
    countyname: 'Adair County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '003',
    countyname: 'Andrew County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '005',
    countyname: 'Atchison County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '007',
    countyname: 'Audrain County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '009',
    countyname: 'Barry County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '011',
    countyname: 'Barton County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '013',
    countyname: 'Bates County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '015',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '017',
    countyname: 'Bollinger County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '019',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '021',
    countyname: 'Buchanan County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '023',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '025',
    countyname: 'Caldwell County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '027',
    countyname: 'Callaway County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '029',
    countyname: 'Camden County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '031',
    countyname: 'Cape Girardeau County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '033',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '035',
    countyname: 'Carter County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '037',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '039',
    countyname: 'Cedar County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '041',
    countyname: 'Chariton County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '043',
    countyname: 'Christian County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '045',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '047',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '049',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '051',
    countyname: 'Cole County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '053',
    countyname: 'Cooper County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '055',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '057',
    countyname: 'Dade County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '059',
    countyname: 'Dallas County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '061',
    countyname: 'Daviess County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '063',
    countyname: 'DeKalb County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '065',
    countyname: 'Dent County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '067',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '069',
    countyname: 'Dunklin County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '071',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '073',
    countyname: 'Gasconade County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '075',
    countyname: 'Gentry County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '077',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '079',
    countyname: 'Grundy County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '081',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '083',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '085',
    countyname: 'Hickory County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '087',
    countyname: 'Holt County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '089',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '091',
    countyname: 'Howell County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '093',
    countyname: 'Iron County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '095',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '097',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '099',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '101',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '103',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '105',
    countyname: 'Laclede County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '107',
    countyname: 'Lafayette County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '109',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '111',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '113',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '115',
    countyname: 'Linn County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '117',
    countyname: 'Livingston County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '119',
    countyname: 'McDonald County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '121',
    countyname: 'Macon County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '123',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '125',
    countyname: 'Maries County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '127',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '129',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '131',
    countyname: 'Miller County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '133',
    countyname: 'Mississippi County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '135',
    countyname: 'Moniteau County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '137',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '139',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '141',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '143',
    countyname: 'New Madrid County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '145',
    countyname: 'Newton County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '147',
    countyname: 'Nodaway County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '149',
    countyname: 'Oregon County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '151',
    countyname: 'Osage County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '153',
    countyname: 'Ozark County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '155',
    countyname: 'Pemiscot County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '157',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '159',
    countyname: 'Pettis County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '161',
    countyname: 'Phelps County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '163',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '165',
    countyname: 'Platte County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '167',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '169',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '171',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '173',
    countyname: 'Ralls County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '175',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '177',
    countyname: 'Ray County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '179',
    countyname: 'Reynolds County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '181',
    countyname: 'Ripley County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '183',
    countyname: 'St. Charles County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '185',
    countyname: 'St. Clair County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '186',
    countyname: 'Ste. Genevieve County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '187',
    countyname: 'St. Francois County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '189',
    countyname: 'St. Louis County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '195',
    countyname: 'Saline County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '197',
    countyname: 'Schuyler County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '199',
    countyname: 'Scotland County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '201',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '203',
    countyname: 'Shannon County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '205',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '207',
    countyname: 'Stoddard County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '209',
    countyname: 'Stone County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '211',
    countyname: 'Sullivan County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '213',
    countyname: 'Taney County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '215',
    countyname: 'Texas County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '217',
    countyname: 'Vernon County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '219',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '221',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '223',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '225',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '227',
    countyname: 'Worth County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '229',
    countyname: 'Wright County',
    classfp: 'H1',
  },
  {
    state: 'MO',
    statefp: '29',
    countyfp: '510',
    countyname: 'St. Louis city',
    classfp: 'C7',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '001',
    countyname: 'Beaverhead County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '003',
    countyname: 'Big Horn County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '005',
    countyname: 'Blaine County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '007',
    countyname: 'Broadwater County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '009',
    countyname: 'Carbon County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '011',
    countyname: 'Carter County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '013',
    countyname: 'Cascade County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '015',
    countyname: 'Chouteau County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '017',
    countyname: 'Custer County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '019',
    countyname: 'Daniels County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '021',
    countyname: 'Dawson County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '023',
    countyname: 'Deer Lodge County',
    classfp: 'H6',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '025',
    countyname: 'Fallon County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '027',
    countyname: 'Fergus County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '029',
    countyname: 'Flathead County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '031',
    countyname: 'Gallatin County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '033',
    countyname: 'Garfield County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '035',
    countyname: 'Glacier County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '037',
    countyname: 'Golden Valley County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '039',
    countyname: 'Granite County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '041',
    countyname: 'Hill County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '043',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '045',
    countyname: 'Judith Basin County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '047',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '049',
    countyname: 'Lewis and Clark County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '051',
    countyname: 'Liberty County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '053',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '055',
    countyname: 'McCone County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '057',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '059',
    countyname: 'Meagher County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '061',
    countyname: 'Mineral County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '063',
    countyname: 'Missoula County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '065',
    countyname: 'Musselshell County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '067',
    countyname: 'Park County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '069',
    countyname: 'Petroleum County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '071',
    countyname: 'Phillips County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '073',
    countyname: 'Pondera County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '075',
    countyname: 'Powder River County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '077',
    countyname: 'Powell County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '079',
    countyname: 'Prairie County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '081',
    countyname: 'Ravalli County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '083',
    countyname: 'Richland County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '085',
    countyname: 'Roosevelt County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '087',
    countyname: 'Rosebud County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '089',
    countyname: 'Sanders County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '091',
    countyname: 'Sheridan County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '093',
    countyname: 'Silver Bow County',
    classfp: 'H6',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '095',
    countyname: 'Stillwater County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '097',
    countyname: 'Sweet Grass County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '099',
    countyname: 'Teton County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '101',
    countyname: 'Toole County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '103',
    countyname: 'Treasure County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '105',
    countyname: 'Valley County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '107',
    countyname: 'Wheatland County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '109',
    countyname: 'Wibaux County',
    classfp: 'H1',
  },
  {
    state: 'MT',
    statefp: '30',
    countyfp: '111',
    countyname: 'Yellowstone County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '003',
    countyname: 'Antelope County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '005',
    countyname: 'Arthur County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '007',
    countyname: 'Banner County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '009',
    countyname: 'Blaine County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '011',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '013',
    countyname: 'Box Butte County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '015',
    countyname: 'Boyd County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '017',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '019',
    countyname: 'Buffalo County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '021',
    countyname: 'Burt County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '023',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '025',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '027',
    countyname: 'Cedar County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '029',
    countyname: 'Chase County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '031',
    countyname: 'Cherry County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '033',
    countyname: 'Cheyenne County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '035',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '037',
    countyname: 'Colfax County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '039',
    countyname: 'Cuming County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '041',
    countyname: 'Custer County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '043',
    countyname: 'Dakota County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '045',
    countyname: 'Dawes County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '047',
    countyname: 'Dawson County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '049',
    countyname: 'Deuel County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '051',
    countyname: 'Dixon County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '053',
    countyname: 'Dodge County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '055',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '057',
    countyname: 'Dundy County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '059',
    countyname: 'Fillmore County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '061',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '063',
    countyname: 'Frontier County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '065',
    countyname: 'Furnas County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '067',
    countyname: 'Gage County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '069',
    countyname: 'Garden County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '071',
    countyname: 'Garfield County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '073',
    countyname: 'Gosper County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '075',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '077',
    countyname: 'Greeley County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '079',
    countyname: 'Hall County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '081',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '083',
    countyname: 'Harlan County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '085',
    countyname: 'Hayes County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '087',
    countyname: 'Hitchcock County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '089',
    countyname: 'Holt County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '091',
    countyname: 'Hooker County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '093',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '095',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '097',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '099',
    countyname: 'Kearney County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '101',
    countyname: 'Keith County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '103',
    countyname: 'Keya Paha County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '105',
    countyname: 'Kimball County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '107',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '109',
    countyname: 'Lancaster County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '111',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '113',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '115',
    countyname: 'Loup County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '117',
    countyname: 'McPherson County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '119',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '121',
    countyname: 'Merrick County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '123',
    countyname: 'Morrill County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '125',
    countyname: 'Nance County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '127',
    countyname: 'Nemaha County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '129',
    countyname: 'Nuckolls County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '131',
    countyname: 'Otoe County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '133',
    countyname: 'Pawnee County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '135',
    countyname: 'Perkins County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '137',
    countyname: 'Phelps County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '139',
    countyname: 'Pierce County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '141',
    countyname: 'Platte County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '143',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '145',
    countyname: 'Red Willow County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '147',
    countyname: 'Richardson County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '149',
    countyname: 'Rock County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '151',
    countyname: 'Saline County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '153',
    countyname: 'Sarpy County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '155',
    countyname: 'Saunders County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '157',
    countyname: 'Scotts Bluff County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '159',
    countyname: 'Seward County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '161',
    countyname: 'Sheridan County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '163',
    countyname: 'Sherman County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '165',
    countyname: 'Sioux County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '167',
    countyname: 'Stanton County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '169',
    countyname: 'Thayer County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '171',
    countyname: 'Thomas County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '173',
    countyname: 'Thurston County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '175',
    countyname: 'Valley County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '177',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '179',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '181',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '183',
    countyname: 'Wheeler County',
    classfp: 'H1',
  },
  {
    state: 'NE',
    statefp: '31',
    countyfp: '185',
    countyname: 'York County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '001',
    countyname: 'Churchill County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '003',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '005',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '007',
    countyname: 'Elko County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '009',
    countyname: 'Esmeralda County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '011',
    countyname: 'Eureka County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '013',
    countyname: 'Humboldt County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '015',
    countyname: 'Lander County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '017',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '019',
    countyname: 'Lyon County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '021',
    countyname: 'Mineral County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '023',
    countyname: 'Nye County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '027',
    countyname: 'Pershing County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '029',
    countyname: 'Storey County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '031',
    countyname: 'Washoe County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '033',
    countyname: 'White Pine County',
    classfp: 'H1',
  },
  {
    state: 'NV',
    statefp: '32',
    countyfp: '510',
    countyname: 'Carson City',
    classfp: 'C7',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '001',
    countyname: 'Belknap County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '003',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '005',
    countyname: 'Cheshire County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '007',
    countyname: 'Coos County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '009',
    countyname: 'Grafton County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '011',
    countyname: 'Hillsborough County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '013',
    countyname: 'Merrimack County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '015',
    countyname: 'Rockingham County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '017',
    countyname: 'Strafford County',
    classfp: 'H1',
  },
  {
    state: 'NH',
    statefp: '33',
    countyfp: '019',
    countyname: 'Sullivan County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '001',
    countyname: 'Atlantic County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '003',
    countyname: 'Bergen County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '005',
    countyname: 'Burlington County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '007',
    countyname: 'Camden County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '009',
    countyname: 'Cape May County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '011',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '013',
    countyname: 'Essex County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '015',
    countyname: 'Gloucester County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '017',
    countyname: 'Hudson County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '019',
    countyname: 'Hunterdon County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '021',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '023',
    countyname: 'Middlesex County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '025',
    countyname: 'Monmouth County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '027',
    countyname: 'Morris County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '029',
    countyname: 'Ocean County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '031',
    countyname: 'Passaic County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '033',
    countyname: 'Salem County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '035',
    countyname: 'Somerset County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '037',
    countyname: 'Sussex County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '039',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'NJ',
    statefp: '34',
    countyfp: '041',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '001',
    countyname: 'Bernalillo County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '003',
    countyname: 'Catron County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '005',
    countyname: 'Chaves County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '006',
    countyname: 'Cibola County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '007',
    countyname: 'Colfax County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '009',
    countyname: 'Curry County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '011',
    countyname: 'De Baca County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '013',
    countyname: 'Dona Ana County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '015',
    countyname: 'Eddy County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '017',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '019',
    countyname: 'Guadalupe County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '021',
    countyname: 'Harding County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '023',
    countyname: 'Hidalgo County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '025',
    countyname: 'Lea County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '027',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '028',
    countyname: 'Los Alamos County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '029',
    countyname: 'Luna County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '031',
    countyname: 'McKinley County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '033',
    countyname: 'Mora County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '035',
    countyname: 'Otero County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '037',
    countyname: 'Quay County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '039',
    countyname: 'Rio Arriba County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '041',
    countyname: 'Roosevelt County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '043',
    countyname: 'Sandoval County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '045',
    countyname: 'San Juan County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '047',
    countyname: 'San Miguel County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '049',
    countyname: 'Santa Fe County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '051',
    countyname: 'Sierra County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '053',
    countyname: 'Socorro County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '055',
    countyname: 'Taos County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '057',
    countyname: 'Torrance County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '059',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'NM',
    statefp: '35',
    countyfp: '061',
    countyname: 'Valencia County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '001',
    countyname: 'Albany County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '003',
    countyname: 'Allegany County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '005',
    countyname: 'Bronx County',
    classfp: 'H6',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '007',
    countyname: 'Broome County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '009',
    countyname: 'Cattaraugus County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '011',
    countyname: 'Cayuga County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '013',
    countyname: 'Chautauqua County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '015',
    countyname: 'Chemung County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '017',
    countyname: 'Chenango County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '019',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '021',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '023',
    countyname: 'Cortland County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '025',
    countyname: 'Delaware County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '027',
    countyname: 'Dutchess County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '029',
    countyname: 'Erie County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '031',
    countyname: 'Essex County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '033',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '035',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '037',
    countyname: 'Genesee County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '039',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '041',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '043',
    countyname: 'Herkimer County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '045',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '047',
    countyname: 'Kings County',
    classfp: 'H6',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '049',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '051',
    countyname: 'Livingston County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '053',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '055',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '057',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '059',
    countyname: 'Nassau County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '061',
    countyname: 'New York County',
    classfp: 'H6',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '063',
    countyname: 'Niagara County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '065',
    countyname: 'Oneida County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '067',
    countyname: 'Onondaga County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '069',
    countyname: 'Ontario County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '071',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '073',
    countyname: 'Orleans County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '075',
    countyname: 'Oswego County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '077',
    countyname: 'Otsego County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '079',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '081',
    countyname: 'Queens County',
    classfp: 'H6',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '083',
    countyname: 'Rensselaer County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '085',
    countyname: 'Richmond County',
    classfp: 'H6',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '087',
    countyname: 'Rockland County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '089',
    countyname: 'St. Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '091',
    countyname: 'Saratoga County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '093',
    countyname: 'Schenectady County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '095',
    countyname: 'Schoharie County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '097',
    countyname: 'Schuyler County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '099',
    countyname: 'Seneca County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '101',
    countyname: 'Steuben County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '103',
    countyname: 'Suffolk County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '105',
    countyname: 'Sullivan County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '107',
    countyname: 'Tioga County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '109',
    countyname: 'Tompkins County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '111',
    countyname: 'Ulster County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '113',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '115',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '117',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '119',
    countyname: 'Westchester County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '121',
    countyname: 'Wyoming County',
    classfp: 'H1',
  },
  {
    state: 'NY',
    statefp: '36',
    countyfp: '123',
    countyname: 'Yates County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '001',
    countyname: 'Alamance County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '003',
    countyname: 'Alexander County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '005',
    countyname: 'Alleghany County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '007',
    countyname: 'Anson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '009',
    countyname: 'Ashe County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '011',
    countyname: 'Avery County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '013',
    countyname: 'Beaufort County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '015',
    countyname: 'Bertie County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '017',
    countyname: 'Bladen County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '019',
    countyname: 'Brunswick County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '021',
    countyname: 'Buncombe County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '023',
    countyname: 'Burke County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '025',
    countyname: 'Cabarrus County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '027',
    countyname: 'Caldwell County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '029',
    countyname: 'Camden County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '031',
    countyname: 'Carteret County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '033',
    countyname: 'Caswell County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '035',
    countyname: 'Catawba County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '037',
    countyname: 'Chatham County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '039',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '041',
    countyname: 'Chowan County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '043',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '045',
    countyname: 'Cleveland County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '047',
    countyname: 'Columbus County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '049',
    countyname: 'Craven County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '051',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '053',
    countyname: 'Currituck County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '055',
    countyname: 'Dare County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '057',
    countyname: 'Davidson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '059',
    countyname: 'Davie County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '061',
    countyname: 'Duplin County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '063',
    countyname: 'Durham County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '065',
    countyname: 'Edgecombe County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '067',
    countyname: 'Forsyth County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '069',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '071',
    countyname: 'Gaston County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '073',
    countyname: 'Gates County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '075',
    countyname: 'Graham County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '077',
    countyname: 'Granville County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '079',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '081',
    countyname: 'Guilford County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '083',
    countyname: 'Halifax County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '085',
    countyname: 'Harnett County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '087',
    countyname: 'Haywood County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '089',
    countyname: 'Henderson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '091',
    countyname: 'Hertford County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '093',
    countyname: 'Hoke County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '095',
    countyname: 'Hyde County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '097',
    countyname: 'Iredell County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '099',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '101',
    countyname: 'Johnston County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '103',
    countyname: 'Jones County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '105',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '107',
    countyname: 'Lenoir County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '109',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '111',
    countyname: 'McDowell County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '113',
    countyname: 'Macon County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '115',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '117',
    countyname: 'Martin County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '119',
    countyname: 'Mecklenburg County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '121',
    countyname: 'Mitchell County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '123',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '125',
    countyname: 'Moore County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '127',
    countyname: 'Nash County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '129',
    countyname: 'New Hanover County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '131',
    countyname: 'Northampton County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '133',
    countyname: 'Onslow County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '135',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '137',
    countyname: 'Pamlico County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '139',
    countyname: 'Pasquotank County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '141',
    countyname: 'Pender County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '143',
    countyname: 'Perquimans County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '145',
    countyname: 'Person County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '147',
    countyname: 'Pitt County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '149',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '151',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '153',
    countyname: 'Richmond County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '155',
    countyname: 'Robeson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '157',
    countyname: 'Rockingham County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '159',
    countyname: 'Rowan County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '161',
    countyname: 'Rutherford County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '163',
    countyname: 'Sampson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '165',
    countyname: 'Scotland County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '167',
    countyname: 'Stanly County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '169',
    countyname: 'Stokes County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '171',
    countyname: 'Surry County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '173',
    countyname: 'Swain County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '175',
    countyname: 'Transylvania County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '177',
    countyname: 'Tyrrell County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '179',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '181',
    countyname: 'Vance County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '183',
    countyname: 'Wake County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '185',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '187',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '189',
    countyname: 'Watauga County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '191',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '193',
    countyname: 'Wilkes County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '195',
    countyname: 'Wilson County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '197',
    countyname: 'Yadkin County',
    classfp: 'H1',
  },
  {
    state: 'NC',
    statefp: '37',
    countyfp: '199',
    countyname: 'Yancey County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '003',
    countyname: 'Barnes County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '005',
    countyname: 'Benson County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '007',
    countyname: 'Billings County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '009',
    countyname: 'Bottineau County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '011',
    countyname: 'Bowman County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '013',
    countyname: 'Burke County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '015',
    countyname: 'Burleigh County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '017',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '019',
    countyname: 'Cavalier County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '021',
    countyname: 'Dickey County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '023',
    countyname: 'Divide County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '025',
    countyname: 'Dunn County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '027',
    countyname: 'Eddy County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '029',
    countyname: 'Emmons County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '031',
    countyname: 'Foster County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '033',
    countyname: 'Golden Valley County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '035',
    countyname: 'Grand Forks County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '037',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '039',
    countyname: 'Griggs County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '041',
    countyname: 'Hettinger County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '043',
    countyname: 'Kidder County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '045',
    countyname: 'LaMoure County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '047',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '049',
    countyname: 'McHenry County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '051',
    countyname: 'McIntosh County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '053',
    countyname: 'McKenzie County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '055',
    countyname: 'McLean County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '057',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '059',
    countyname: 'Morton County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '061',
    countyname: 'Mountrail County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '063',
    countyname: 'Nelson County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '065',
    countyname: 'Oliver County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '067',
    countyname: 'Pembina County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '069',
    countyname: 'Pierce County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '071',
    countyname: 'Ramsey County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '073',
    countyname: 'Ransom County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '075',
    countyname: 'Renville County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '077',
    countyname: 'Richland County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '079',
    countyname: 'Rolette County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '081',
    countyname: 'Sargent County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '083',
    countyname: 'Sheridan County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '085',
    countyname: 'Sioux County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '087',
    countyname: 'Slope County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '089',
    countyname: 'Stark County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '091',
    countyname: 'Steele County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '093',
    countyname: 'Stutsman County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '095',
    countyname: 'Towner County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '097',
    countyname: 'Traill County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '099',
    countyname: 'Walsh County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '101',
    countyname: 'Ward County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '103',
    countyname: 'Wells County',
    classfp: 'H1',
  },
  {
    state: 'ND',
    statefp: '38',
    countyfp: '105',
    countyname: 'Williams County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '003',
    countyname: 'Allen County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '005',
    countyname: 'Ashland County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '007',
    countyname: 'Ashtabula County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '009',
    countyname: 'Athens County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '011',
    countyname: 'Auglaize County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '013',
    countyname: 'Belmont County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '015',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '017',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '019',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '021',
    countyname: 'Champaign County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '023',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '025',
    countyname: 'Clermont County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '027',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '029',
    countyname: 'Columbiana County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '031',
    countyname: 'Coshocton County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '033',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '035',
    countyname: 'Cuyahoga County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '037',
    countyname: 'Darke County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '039',
    countyname: 'Defiance County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '041',
    countyname: 'Delaware County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '043',
    countyname: 'Erie County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '045',
    countyname: 'Fairfield County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '047',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '049',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '051',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '053',
    countyname: 'Gallia County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '055',
    countyname: 'Geauga County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '057',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '059',
    countyname: 'Guernsey County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '061',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '063',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '065',
    countyname: 'Hardin County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '067',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '069',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '071',
    countyname: 'Highland County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '073',
    countyname: 'Hocking County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '075',
    countyname: 'Holmes County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '077',
    countyname: 'Huron County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '079',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '081',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '083',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '085',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '087',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '089',
    countyname: 'Licking County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '091',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '093',
    countyname: 'Lorain County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '095',
    countyname: 'Lucas County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '097',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '099',
    countyname: 'Mahoning County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '101',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '103',
    countyname: 'Medina County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '105',
    countyname: 'Meigs County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '107',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '109',
    countyname: 'Miami County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '111',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '113',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '115',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '117',
    countyname: 'Morrow County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '119',
    countyname: 'Muskingum County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '121',
    countyname: 'Noble County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '123',
    countyname: 'Ottawa County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '125',
    countyname: 'Paulding County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '127',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '129',
    countyname: 'Pickaway County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '131',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '133',
    countyname: 'Portage County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '135',
    countyname: 'Preble County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '137',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '139',
    countyname: 'Richland County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '141',
    countyname: 'Ross County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '143',
    countyname: 'Sandusky County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '145',
    countyname: 'Scioto County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '147',
    countyname: 'Seneca County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '149',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '151',
    countyname: 'Stark County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '153',
    countyname: 'Summit County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '155',
    countyname: 'Trumbull County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '157',
    countyname: 'Tuscarawas County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '159',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '161',
    countyname: 'Van Wert County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '163',
    countyname: 'Vinton County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '165',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '167',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '169',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '171',
    countyname: 'Williams County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '173',
    countyname: 'Wood County',
    classfp: 'H1',
  },
  {
    state: 'OH',
    statefp: '39',
    countyfp: '175',
    countyname: 'Wyandot County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '001',
    countyname: 'Adair County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '003',
    countyname: 'Alfalfa County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '005',
    countyname: 'Atoka County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '007',
    countyname: 'Beaver County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '009',
    countyname: 'Beckham County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '011',
    countyname: 'Blaine County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '013',
    countyname: 'Bryan County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '015',
    countyname: 'Caddo County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '017',
    countyname: 'Canadian County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '019',
    countyname: 'Carter County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '021',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '023',
    countyname: 'Choctaw County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '025',
    countyname: 'Cimarron County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '027',
    countyname: 'Cleveland County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '029',
    countyname: 'Coal County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '031',
    countyname: 'Comanche County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '033',
    countyname: 'Cotton County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '035',
    countyname: 'Craig County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '037',
    countyname: 'Creek County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '039',
    countyname: 'Custer County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '041',
    countyname: 'Delaware County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '043',
    countyname: 'Dewey County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '045',
    countyname: 'Ellis County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '047',
    countyname: 'Garfield County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '049',
    countyname: 'Garvin County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '051',
    countyname: 'Grady County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '053',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '055',
    countyname: 'Greer County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '057',
    countyname: 'Harmon County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '059',
    countyname: 'Harper County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '061',
    countyname: 'Haskell County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '063',
    countyname: 'Hughes County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '065',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '067',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '069',
    countyname: 'Johnston County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '071',
    countyname: 'Kay County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '073',
    countyname: 'Kingfisher County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '075',
    countyname: 'Kiowa County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '077',
    countyname: 'Latimer County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '079',
    countyname: 'Le Flore County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '081',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '083',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '085',
    countyname: 'Love County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '087',
    countyname: 'McClain County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '089',
    countyname: 'McCurtain County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '091',
    countyname: 'McIntosh County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '093',
    countyname: 'Major County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '095',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '097',
    countyname: 'Mayes County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '099',
    countyname: 'Murray County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '101',
    countyname: 'Muskogee County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '103',
    countyname: 'Noble County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '105',
    countyname: 'Nowata County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '107',
    countyname: 'Okfuskee County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '109',
    countyname: 'Oklahoma County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '111',
    countyname: 'Okmulgee County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '113',
    countyname: 'Osage County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '115',
    countyname: 'Ottawa County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '117',
    countyname: 'Pawnee County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '119',
    countyname: 'Payne County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '121',
    countyname: 'Pittsburg County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '123',
    countyname: 'Pontotoc County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '125',
    countyname: 'Pottawatomie County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '127',
    countyname: 'Pushmataha County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '129',
    countyname: 'Roger Mills County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '131',
    countyname: 'Rogers County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '133',
    countyname: 'Seminole County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '135',
    countyname: 'Sequoyah County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '137',
    countyname: 'Stephens County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '139',
    countyname: 'Texas County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '141',
    countyname: 'Tillman County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '143',
    countyname: 'Tulsa County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '145',
    countyname: 'Wagoner County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '147',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '149',
    countyname: 'Washita County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '151',
    countyname: 'Woods County',
    classfp: 'H1',
  },
  {
    state: 'OK',
    statefp: '40',
    countyfp: '153',
    countyname: 'Woodward County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '001',
    countyname: 'Baker County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '003',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '005',
    countyname: 'Clackamas County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '007',
    countyname: 'Clatsop County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '009',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '011',
    countyname: 'Coos County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '013',
    countyname: 'Crook County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '015',
    countyname: 'Curry County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '017',
    countyname: 'Deschutes County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '019',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '021',
    countyname: 'Gilliam County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '023',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '025',
    countyname: 'Harney County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '027',
    countyname: 'Hood River County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '029',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '031',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '033',
    countyname: 'Josephine County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '035',
    countyname: 'Klamath County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '037',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '039',
    countyname: 'Lane County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '041',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '043',
    countyname: 'Linn County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '045',
    countyname: 'Malheur County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '047',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '049',
    countyname: 'Morrow County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '051',
    countyname: 'Multnomah County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '053',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '055',
    countyname: 'Sherman County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '057',
    countyname: 'Tillamook County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '059',
    countyname: 'Umatilla County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '061',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '063',
    countyname: 'Wallowa County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '065',
    countyname: 'Wasco County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '067',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '069',
    countyname: 'Wheeler County',
    classfp: 'H1',
  },
  {
    state: 'OR',
    statefp: '41',
    countyfp: '071',
    countyname: 'Yamhill County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '003',
    countyname: 'Allegheny County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '005',
    countyname: 'Armstrong County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '007',
    countyname: 'Beaver County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '009',
    countyname: 'Bedford County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '011',
    countyname: 'Berks County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '013',
    countyname: 'Blair County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '015',
    countyname: 'Bradford County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '017',
    countyname: 'Bucks County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '019',
    countyname: 'Butler County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '021',
    countyname: 'Cambria County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '023',
    countyname: 'Cameron County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '025',
    countyname: 'Carbon County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '027',
    countyname: 'Centre County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '029',
    countyname: 'Chester County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '031',
    countyname: 'Clarion County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '033',
    countyname: 'Clearfield County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '035',
    countyname: 'Clinton County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '037',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '039',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '041',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '043',
    countyname: 'Dauphin County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '045',
    countyname: 'Delaware County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '047',
    countyname: 'Elk County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '049',
    countyname: 'Erie County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '051',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '053',
    countyname: 'Forest County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '055',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '057',
    countyname: 'Fulton County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '059',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '061',
    countyname: 'Huntingdon County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '063',
    countyname: 'Indiana County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '065',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '067',
    countyname: 'Juniata County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '069',
    countyname: 'Lackawanna County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '071',
    countyname: 'Lancaster County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '073',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '075',
    countyname: 'Lebanon County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '077',
    countyname: 'Lehigh County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '079',
    countyname: 'Luzerne County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '081',
    countyname: 'Lycoming County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '083',
    countyname: 'McKean County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '085',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '087',
    countyname: 'Mifflin County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '089',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '091',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '093',
    countyname: 'Montour County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '095',
    countyname: 'Northampton County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '097',
    countyname: 'Northumberland County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '099',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '101',
    countyname: 'Philadelphia County',
    classfp: 'H6',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '103',
    countyname: 'Pike County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '105',
    countyname: 'Potter County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '107',
    countyname: 'Schuylkill County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '109',
    countyname: 'Snyder County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '111',
    countyname: 'Somerset County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '113',
    countyname: 'Sullivan County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '115',
    countyname: 'Susquehanna County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '117',
    countyname: 'Tioga County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '119',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '121',
    countyname: 'Venango County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '123',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '125',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '127',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '129',
    countyname: 'Westmoreland County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '131',
    countyname: 'Wyoming County',
    classfp: 'H1',
  },
  {
    state: 'PA',
    statefp: '42',
    countyfp: '133',
    countyname: 'York County',
    classfp: 'H1',
  },
  {
    state: 'RI',
    statefp: '44',
    countyfp: '001',
    countyname: 'Bristol County',
    classfp: 'H4',
  },
  {
    state: 'RI',
    statefp: '44',
    countyfp: '003',
    countyname: 'Kent County',
    classfp: 'H4',
  },
  {
    state: 'RI',
    statefp: '44',
    countyfp: '005',
    countyname: 'Newport County',
    classfp: 'H4',
  },
  {
    state: 'RI',
    statefp: '44',
    countyfp: '007',
    countyname: 'Providence County',
    classfp: 'H4',
  },
  {
    state: 'RI',
    statefp: '44',
    countyfp: '009',
    countyname: 'Washington County',
    classfp: 'H4',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '001',
    countyname: 'Abbeville County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '003',
    countyname: 'Aiken County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '005',
    countyname: 'Allendale County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '007',
    countyname: 'Anderson County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '009',
    countyname: 'Bamberg County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '011',
    countyname: 'Barnwell County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '013',
    countyname: 'Beaufort County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '015',
    countyname: 'Berkeley County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '017',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '019',
    countyname: 'Charleston County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '021',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '023',
    countyname: 'Chester County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '025',
    countyname: 'Chesterfield County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '027',
    countyname: 'Clarendon County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '029',
    countyname: 'Colleton County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '031',
    countyname: 'Darlington County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '033',
    countyname: 'Dillon County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '035',
    countyname: 'Dorchester County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '037',
    countyname: 'Edgefield County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '039',
    countyname: 'Fairfield County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '041',
    countyname: 'Florence County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '043',
    countyname: 'Georgetown County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '045',
    countyname: 'Greenville County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '047',
    countyname: 'Greenwood County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '049',
    countyname: 'Hampton County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '051',
    countyname: 'Horry County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '053',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '055',
    countyname: 'Kershaw County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '057',
    countyname: 'Lancaster County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '059',
    countyname: 'Laurens County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '061',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '063',
    countyname: 'Lexington County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '065',
    countyname: 'McCormick County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '067',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '069',
    countyname: 'Marlboro County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '071',
    countyname: 'Newberry County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '073',
    countyname: 'Oconee County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '075',
    countyname: 'Orangeburg County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '077',
    countyname: 'Pickens County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '079',
    countyname: 'Richland County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '081',
    countyname: 'Saluda County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '083',
    countyname: 'Spartanburg County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '085',
    countyname: 'Sumter County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '087',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '089',
    countyname: 'Williamsburg County',
    classfp: 'H1',
  },
  {
    state: 'SC',
    statefp: '45',
    countyfp: '091',
    countyname: 'York County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '003',
    countyname: 'Aurora County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '005',
    countyname: 'Beadle County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '007',
    countyname: 'Bennett County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '009',
    countyname: 'Bon Homme County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '011',
    countyname: 'Brookings County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '013',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '015',
    countyname: 'Brule County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '017',
    countyname: 'Buffalo County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '019',
    countyname: 'Butte County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '021',
    countyname: 'Campbell County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '023',
    countyname: 'Charles Mix County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '025',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '027',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '029',
    countyname: 'Codington County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '031',
    countyname: 'Corson County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '033',
    countyname: 'Custer County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '035',
    countyname: 'Davison County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '037',
    countyname: 'Day County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '039',
    countyname: 'Deuel County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '041',
    countyname: 'Dewey County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '043',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '045',
    countyname: 'Edmunds County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '047',
    countyname: 'Fall River County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '049',
    countyname: 'Faulk County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '051',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '053',
    countyname: 'Gregory County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '055',
    countyname: 'Haakon County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '057',
    countyname: 'Hamlin County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '059',
    countyname: 'Hand County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '061',
    countyname: 'Hanson County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '063',
    countyname: 'Harding County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '065',
    countyname: 'Hughes County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '067',
    countyname: 'Hutchinson County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '069',
    countyname: 'Hyde County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '071',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '073',
    countyname: 'Jerauld County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '075',
    countyname: 'Jones County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '077',
    countyname: 'Kingsbury County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '079',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '081',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '083',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '085',
    countyname: 'Lyman County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '087',
    countyname: 'McCook County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '089',
    countyname: 'McPherson County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '091',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '093',
    countyname: 'Meade County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '095',
    countyname: 'Mellette County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '097',
    countyname: 'Miner County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '099',
    countyname: 'Minnehaha County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '101',
    countyname: 'Moody County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '103',
    countyname: 'Pennington County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '105',
    countyname: 'Perkins County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '107',
    countyname: 'Potter County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '109',
    countyname: 'Roberts County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '111',
    countyname: 'Sanborn County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '113',
    countyname: 'Shannon County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '115',
    countyname: 'Spink County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '117',
    countyname: 'Stanley County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '119',
    countyname: 'Sully County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '121',
    countyname: 'Todd County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '123',
    countyname: 'Tripp County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '125',
    countyname: 'Turner County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '127',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '129',
    countyname: 'Walworth County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '135',
    countyname: 'Yankton County',
    classfp: 'H1',
  },
  {
    state: 'SD',
    statefp: '46',
    countyfp: '137',
    countyname: 'Ziebach County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '001',
    countyname: 'Anderson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '003',
    countyname: 'Bedford County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '005',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '007',
    countyname: 'Bledsoe County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '009',
    countyname: 'Blount County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '011',
    countyname: 'Bradley County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '013',
    countyname: 'Campbell County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '015',
    countyname: 'Cannon County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '017',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '019',
    countyname: 'Carter County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '021',
    countyname: 'Cheatham County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '023',
    countyname: 'Chester County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '025',
    countyname: 'Claiborne County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '027',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '029',
    countyname: 'Cocke County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '031',
    countyname: 'Coffee County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '033',
    countyname: 'Crockett County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '035',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '037',
    countyname: 'Davidson County',
    classfp: 'H6',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '039',
    countyname: 'Decatur County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '041',
    countyname: 'DeKalb County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '043',
    countyname: 'Dickson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '045',
    countyname: 'Dyer County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '047',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '049',
    countyname: 'Fentress County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '051',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '053',
    countyname: 'Gibson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '055',
    countyname: 'Giles County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '057',
    countyname: 'Grainger County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '059',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '061',
    countyname: 'Grundy County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '063',
    countyname: 'Hamblen County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '065',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '067',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '069',
    countyname: 'Hardeman County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '071',
    countyname: 'Hardin County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '073',
    countyname: 'Hawkins County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '075',
    countyname: 'Haywood County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '077',
    countyname: 'Henderson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '079',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '081',
    countyname: 'Hickman County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '083',
    countyname: 'Houston County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '085',
    countyname: 'Humphreys County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '087',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '089',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '091',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '093',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '095',
    countyname: 'Lake County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '097',
    countyname: 'Lauderdale County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '099',
    countyname: 'Lawrence County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '101',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '103',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '105',
    countyname: 'Loudon County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '107',
    countyname: 'McMinn County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '109',
    countyname: 'McNairy County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '111',
    countyname: 'Macon County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '113',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '115',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '117',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '119',
    countyname: 'Maury County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '121',
    countyname: 'Meigs County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '123',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '125',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '127',
    countyname: 'Moore County',
    classfp: 'H6',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '129',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '131',
    countyname: 'Obion County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '133',
    countyname: 'Overton County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '135',
    countyname: 'Perry County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '137',
    countyname: 'Pickett County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '139',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '141',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '143',
    countyname: 'Rhea County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '145',
    countyname: 'Roane County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '147',
    countyname: 'Robertson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '149',
    countyname: 'Rutherford County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '151',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '153',
    countyname: 'Sequatchie County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '155',
    countyname: 'Sevier County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '157',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '159',
    countyname: 'Smith County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '161',
    countyname: 'Stewart County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '163',
    countyname: 'Sullivan County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '165',
    countyname: 'Sumner County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '167',
    countyname: 'Tipton County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '169',
    countyname: 'Trousdale County',
    classfp: 'H6',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '171',
    countyname: 'Unicoi County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '173',
    countyname: 'Union County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '175',
    countyname: 'Van Buren County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '177',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '179',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '181',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '183',
    countyname: 'Weakley County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '185',
    countyname: 'White County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '187',
    countyname: 'Williamson County',
    classfp: 'H1',
  },
  {
    state: 'TN',
    statefp: '47',
    countyfp: '189',
    countyname: 'Wilson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '001',
    countyname: 'Anderson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '003',
    countyname: 'Andrews County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '005',
    countyname: 'Angelina County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '007',
    countyname: 'Aransas County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '009',
    countyname: 'Archer County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '011',
    countyname: 'Armstrong County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '013',
    countyname: 'Atascosa County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '015',
    countyname: 'Austin County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '017',
    countyname: 'Bailey County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '019',
    countyname: 'Bandera County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '021',
    countyname: 'Bastrop County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '023',
    countyname: 'Baylor County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '025',
    countyname: 'Bee County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '027',
    countyname: 'Bell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '029',
    countyname: 'Bexar County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '031',
    countyname: 'Blanco County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '033',
    countyname: 'Borden County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '035',
    countyname: 'Bosque County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '037',
    countyname: 'Bowie County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '039',
    countyname: 'Brazoria County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '041',
    countyname: 'Brazos County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '043',
    countyname: 'Brewster County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '045',
    countyname: 'Briscoe County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '047',
    countyname: 'Brooks County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '049',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '051',
    countyname: 'Burleson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '053',
    countyname: 'Burnet County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '055',
    countyname: 'Caldwell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '057',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '059',
    countyname: 'Callahan County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '061',
    countyname: 'Cameron County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '063',
    countyname: 'Camp County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '065',
    countyname: 'Carson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '067',
    countyname: 'Cass County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '069',
    countyname: 'Castro County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '071',
    countyname: 'Chambers County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '073',
    countyname: 'Cherokee County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '075',
    countyname: 'Childress County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '077',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '079',
    countyname: 'Cochran County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '081',
    countyname: 'Coke County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '083',
    countyname: 'Coleman County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '085',
    countyname: 'Collin County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '087',
    countyname: 'Collingsworth County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '089',
    countyname: 'Colorado County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '091',
    countyname: 'Comal County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '093',
    countyname: 'Comanche County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '095',
    countyname: 'Concho County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '097',
    countyname: 'Cooke County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '099',
    countyname: 'Coryell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '101',
    countyname: 'Cottle County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '103',
    countyname: 'Crane County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '105',
    countyname: 'Crockett County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '107',
    countyname: 'Crosby County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '109',
    countyname: 'Culberson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '111',
    countyname: 'Dallam County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '113',
    countyname: 'Dallas County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '115',
    countyname: 'Dawson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '117',
    countyname: 'Deaf Smith County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '119',
    countyname: 'Delta County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '121',
    countyname: 'Denton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '123',
    countyname: 'DeWitt County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '125',
    countyname: 'Dickens County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '127',
    countyname: 'Dimmit County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '129',
    countyname: 'Donley County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '131',
    countyname: 'Duval County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '133',
    countyname: 'Eastland County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '135',
    countyname: 'Ector County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '137',
    countyname: 'Edwards County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '139',
    countyname: 'Ellis County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '141',
    countyname: 'El Paso County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '143',
    countyname: 'Erath County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '145',
    countyname: 'Falls County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '147',
    countyname: 'Fannin County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '149',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '151',
    countyname: 'Fisher County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '153',
    countyname: 'Floyd County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '155',
    countyname: 'Foard County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '157',
    countyname: 'Fort Bend County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '159',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '161',
    countyname: 'Freestone County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '163',
    countyname: 'Frio County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '165',
    countyname: 'Gaines County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '167',
    countyname: 'Galveston County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '169',
    countyname: 'Garza County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '171',
    countyname: 'Gillespie County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '173',
    countyname: 'Glasscock County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '175',
    countyname: 'Goliad County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '177',
    countyname: 'Gonzales County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '179',
    countyname: 'Gray County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '181',
    countyname: 'Grayson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '183',
    countyname: 'Gregg County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '185',
    countyname: 'Grimes County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '187',
    countyname: 'Guadalupe County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '189',
    countyname: 'Hale County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '191',
    countyname: 'Hall County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '193',
    countyname: 'Hamilton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '195',
    countyname: 'Hansford County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '197',
    countyname: 'Hardeman County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '199',
    countyname: 'Hardin County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '201',
    countyname: 'Harris County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '203',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '205',
    countyname: 'Hartley County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '207',
    countyname: 'Haskell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '209',
    countyname: 'Hays County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '211',
    countyname: 'Hemphill County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '213',
    countyname: 'Henderson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '215',
    countyname: 'Hidalgo County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '217',
    countyname: 'Hill County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '219',
    countyname: 'Hockley County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '221',
    countyname: 'Hood County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '223',
    countyname: 'Hopkins County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '225',
    countyname: 'Houston County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '227',
    countyname: 'Howard County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '229',
    countyname: 'Hudspeth County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '231',
    countyname: 'Hunt County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '233',
    countyname: 'Hutchinson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '235',
    countyname: 'Irion County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '237',
    countyname: 'Jack County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '239',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '241',
    countyname: 'Jasper County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '243',
    countyname: 'Jeff Davis County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '245',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '247',
    countyname: 'Jim Hogg County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '249',
    countyname: 'Jim Wells County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '251',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '253',
    countyname: 'Jones County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '255',
    countyname: 'Karnes County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '257',
    countyname: 'Kaufman County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '259',
    countyname: 'Kendall County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '261',
    countyname: 'Kenedy County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '263',
    countyname: 'Kent County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '265',
    countyname: 'Kerr County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '267',
    countyname: 'Kimble County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '269',
    countyname: 'King County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '271',
    countyname: 'Kinney County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '273',
    countyname: 'Kleberg County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '275',
    countyname: 'Knox County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '277',
    countyname: 'Lamar County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '279',
    countyname: 'Lamb County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '281',
    countyname: 'Lampasas County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '283',
    countyname: 'La Salle County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '285',
    countyname: 'Lavaca County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '287',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '289',
    countyname: 'Leon County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '291',
    countyname: 'Liberty County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '293',
    countyname: 'Limestone County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '295',
    countyname: 'Lipscomb County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '297',
    countyname: 'Live Oak County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '299',
    countyname: 'Llano County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '301',
    countyname: 'Loving County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '303',
    countyname: 'Lubbock County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '305',
    countyname: 'Lynn County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '307',
    countyname: 'McCulloch County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '309',
    countyname: 'McLennan County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '311',
    countyname: 'McMullen County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '313',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '315',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '317',
    countyname: 'Martin County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '319',
    countyname: 'Mason County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '321',
    countyname: 'Matagorda County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '323',
    countyname: 'Maverick County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '325',
    countyname: 'Medina County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '327',
    countyname: 'Menard County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '329',
    countyname: 'Midland County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '331',
    countyname: 'Milam County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '333',
    countyname: 'Mills County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '335',
    countyname: 'Mitchell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '337',
    countyname: 'Montague County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '339',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '341',
    countyname: 'Moore County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '343',
    countyname: 'Morris County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '345',
    countyname: 'Motley County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '347',
    countyname: 'Nacogdoches County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '349',
    countyname: 'Navarro County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '351',
    countyname: 'Newton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '353',
    countyname: 'Nolan County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '355',
    countyname: 'Nueces County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '357',
    countyname: 'Ochiltree County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '359',
    countyname: 'Oldham County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '361',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '363',
    countyname: 'Palo Pinto County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '365',
    countyname: 'Panola County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '367',
    countyname: 'Parker County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '369',
    countyname: 'Parmer County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '371',
    countyname: 'Pecos County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '373',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '375',
    countyname: 'Potter County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '377',
    countyname: 'Presidio County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '379',
    countyname: 'Rains County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '381',
    countyname: 'Randall County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '383',
    countyname: 'Reagan County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '385',
    countyname: 'Real County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '387',
    countyname: 'Red River County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '389',
    countyname: 'Reeves County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '391',
    countyname: 'Refugio County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '393',
    countyname: 'Roberts County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '395',
    countyname: 'Robertson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '397',
    countyname: 'Rockwall County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '399',
    countyname: 'Runnels County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '401',
    countyname: 'Rusk County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '403',
    countyname: 'Sabine County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '405',
    countyname: 'San Augustine County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '407',
    countyname: 'San Jacinto County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '409',
    countyname: 'San Patricio County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '411',
    countyname: 'San Saba County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '413',
    countyname: 'Schleicher County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '415',
    countyname: 'Scurry County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '417',
    countyname: 'Shackelford County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '419',
    countyname: 'Shelby County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '421',
    countyname: 'Sherman County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '423',
    countyname: 'Smith County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '425',
    countyname: 'Somervell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '427',
    countyname: 'Starr County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '429',
    countyname: 'Stephens County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '431',
    countyname: 'Sterling County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '433',
    countyname: 'Stonewall County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '435',
    countyname: 'Sutton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '437',
    countyname: 'Swisher County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '439',
    countyname: 'Tarrant County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '441',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '443',
    countyname: 'Terrell County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '445',
    countyname: 'Terry County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '447',
    countyname: 'Throckmorton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '449',
    countyname: 'Titus County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '451',
    countyname: 'Tom Green County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '453',
    countyname: 'Travis County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '455',
    countyname: 'Trinity County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '457',
    countyname: 'Tyler County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '459',
    countyname: 'Upshur County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '461',
    countyname: 'Upton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '463',
    countyname: 'Uvalde County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '465',
    countyname: 'Val Verde County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '467',
    countyname: 'Van Zandt County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '469',
    countyname: 'Victoria County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '471',
    countyname: 'Walker County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '473',
    countyname: 'Waller County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '475',
    countyname: 'Ward County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '477',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '479',
    countyname: 'Webb County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '481',
    countyname: 'Wharton County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '483',
    countyname: 'Wheeler County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '485',
    countyname: 'Wichita County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '487',
    countyname: 'Wilbarger County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '489',
    countyname: 'Willacy County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '491',
    countyname: 'Williamson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '493',
    countyname: 'Wilson County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '495',
    countyname: 'Winkler County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '497',
    countyname: 'Wise County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '499',
    countyname: 'Wood County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '501',
    countyname: 'Yoakum County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '503',
    countyname: 'Young County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '505',
    countyname: 'Zapata County',
    classfp: 'H1',
  },
  {
    state: 'TX',
    statefp: '48',
    countyfp: '507',
    countyname: 'Zavala County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '001',
    countyname: 'Beaver County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '003',
    countyname: 'Box Elder County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '005',
    countyname: 'Cache County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '007',
    countyname: 'Carbon County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '009',
    countyname: 'Daggett County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '011',
    countyname: 'Davis County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '013',
    countyname: 'Duchesne County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '015',
    countyname: 'Emery County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '017',
    countyname: 'Garfield County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '019',
    countyname: 'Grand County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '021',
    countyname: 'Iron County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '023',
    countyname: 'Juab County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '025',
    countyname: 'Kane County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '027',
    countyname: 'Millard County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '029',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '031',
    countyname: 'Piute County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '033',
    countyname: 'Rich County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '035',
    countyname: 'Salt Lake County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '037',
    countyname: 'San Juan County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '039',
    countyname: 'Sanpete County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '041',
    countyname: 'Sevier County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '043',
    countyname: 'Summit County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '045',
    countyname: 'Tooele County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '047',
    countyname: 'Uintah County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '049',
    countyname: 'Utah County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '051',
    countyname: 'Wasatch County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '053',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '055',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'UT',
    statefp: '49',
    countyfp: '057',
    countyname: 'Weber County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '001',
    countyname: 'Addison County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '003',
    countyname: 'Bennington County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '005',
    countyname: 'Caledonia County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '007',
    countyname: 'Chittenden County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '009',
    countyname: 'Essex County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '011',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '013',
    countyname: 'Grand Isle County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '015',
    countyname: 'Lamoille County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '017',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '019',
    countyname: 'Orleans County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '021',
    countyname: 'Rutland County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '023',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '025',
    countyname: 'Windham County',
    classfp: 'H1',
  },
  {
    state: 'VT',
    statefp: '50',
    countyfp: '027',
    countyname: 'Windsor County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '001',
    countyname: 'Accomack County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '003',
    countyname: 'Albemarle County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '005',
    countyname: 'Alleghany County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '007',
    countyname: 'Amelia County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '009',
    countyname: 'Amherst County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '011',
    countyname: 'Appomattox County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '013',
    countyname: 'Arlington County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '015',
    countyname: 'Augusta County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '017',
    countyname: 'Bath County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '019',
    countyname: 'Bedford County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '021',
    countyname: 'Bland County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '023',
    countyname: 'Botetourt County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '025',
    countyname: 'Brunswick County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '027',
    countyname: 'Buchanan County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '029',
    countyname: 'Buckingham County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '031',
    countyname: 'Campbell County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '033',
    countyname: 'Caroline County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '035',
    countyname: 'Carroll County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '036',
    countyname: 'Charles City County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '037',
    countyname: 'Charlotte County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '041',
    countyname: 'Chesterfield County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '043',
    countyname: 'Clarke County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '045',
    countyname: 'Craig County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '047',
    countyname: 'Culpeper County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '049',
    countyname: 'Cumberland County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '051',
    countyname: 'Dickenson County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '053',
    countyname: 'Dinwiddie County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '057',
    countyname: 'Essex County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '059',
    countyname: 'Fairfax County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '061',
    countyname: 'Fauquier County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '063',
    countyname: 'Floyd County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '065',
    countyname: 'Fluvanna County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '067',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '069',
    countyname: 'Frederick County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '071',
    countyname: 'Giles County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '073',
    countyname: 'Gloucester County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '075',
    countyname: 'Goochland County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '077',
    countyname: 'Grayson County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '079',
    countyname: 'Greene County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '081',
    countyname: 'Greensville County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '083',
    countyname: 'Halifax County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '085',
    countyname: 'Hanover County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '087',
    countyname: 'Henrico County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '089',
    countyname: 'Henry County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '091',
    countyname: 'Highland County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '093',
    countyname: 'Isle of Wight County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '095',
    countyname: 'James City County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '097',
    countyname: 'King and Queen County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '099',
    countyname: 'King George County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '101',
    countyname: 'King William County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '103',
    countyname: 'Lancaster County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '105',
    countyname: 'Lee County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '107',
    countyname: 'Loudoun County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '109',
    countyname: 'Louisa County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '111',
    countyname: 'Lunenburg County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '113',
    countyname: 'Madison County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '115',
    countyname: 'Mathews County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '117',
    countyname: 'Mecklenburg County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '119',
    countyname: 'Middlesex County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '121',
    countyname: 'Montgomery County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '125',
    countyname: 'Nelson County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '127',
    countyname: 'New Kent County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '131',
    countyname: 'Northampton County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '133',
    countyname: 'Northumberland County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '135',
    countyname: 'Nottoway County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '137',
    countyname: 'Orange County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '139',
    countyname: 'Page County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '141',
    countyname: 'Patrick County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '143',
    countyname: 'Pittsylvania County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '145',
    countyname: 'Powhatan County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '147',
    countyname: 'Prince Edward County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '149',
    countyname: 'Prince George County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '153',
    countyname: 'Prince William County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '155',
    countyname: 'Pulaski County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '157',
    countyname: 'Rappahannock County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '159',
    countyname: 'Richmond County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '161',
    countyname: 'Roanoke County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '163',
    countyname: 'Rockbridge County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '165',
    countyname: 'Rockingham County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '167',
    countyname: 'Russell County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '169',
    countyname: 'Scott County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '171',
    countyname: 'Shenandoah County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '173',
    countyname: 'Smyth County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '175',
    countyname: 'Southampton County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '177',
    countyname: 'Spotsylvania County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '179',
    countyname: 'Stafford County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '181',
    countyname: 'Surry County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '183',
    countyname: 'Sussex County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '185',
    countyname: 'Tazewell County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '187',
    countyname: 'Warren County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '191',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '193',
    countyname: 'Westmoreland County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '195',
    countyname: 'Wise County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '197',
    countyname: 'Wythe County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '199',
    countyname: 'York County',
    classfp: 'H1',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '510',
    countyname: 'Alexandria city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '515',
    countyname: 'Bedford city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '520',
    countyname: 'Bristol city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '530',
    countyname: 'Buena Vista city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '540',
    countyname: 'Charlottesville city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '550',
    countyname: 'Chesapeake city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '570',
    countyname: 'Colonial Heights city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '580',
    countyname: 'Covington city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '590',
    countyname: 'Danville city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '595',
    countyname: 'Emporia city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '600',
    countyname: 'Fairfax city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '610',
    countyname: 'Falls Church city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '620',
    countyname: 'Franklin city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '630',
    countyname: 'Fredericksburg city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '640',
    countyname: 'Galax city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '650',
    countyname: 'Hampton city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '660',
    countyname: 'Harrisonburg city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '670',
    countyname: 'Hopewell city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '678',
    countyname: 'Lexington city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '680',
    countyname: 'Lynchburg city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '683',
    countyname: 'Manassas city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '685',
    countyname: 'Manassas Park city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '690',
    countyname: 'Martinsville city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '700',
    countyname: 'Newport News city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '710',
    countyname: 'Norfolk city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '720',
    countyname: 'Norton city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '730',
    countyname: 'Petersburg city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '735',
    countyname: 'Poquoson city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '740',
    countyname: 'Portsmouth city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '750',
    countyname: 'Radford city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '760',
    countyname: 'Richmond city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '770',
    countyname: 'Roanoke city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '775',
    countyname: 'Salem city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '790',
    countyname: 'Staunton city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '800',
    countyname: 'Suffolk city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '810',
    countyname: 'Virginia Beach city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '820',
    countyname: 'Waynesboro city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '830',
    countyname: 'Williamsburg city',
    classfp: 'C7',
  },
  {
    state: 'VA',
    statefp: '51',
    countyfp: '840',
    countyname: 'Winchester city',
    classfp: 'C7',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '003',
    countyname: 'Asotin County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '005',
    countyname: 'Benton County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '007',
    countyname: 'Chelan County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '009',
    countyname: 'Clallam County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '011',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '013',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '015',
    countyname: 'Cowlitz County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '017',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '019',
    countyname: 'Ferry County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '021',
    countyname: 'Franklin County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '023',
    countyname: 'Garfield County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '025',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '027',
    countyname: 'Grays Harbor County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '029',
    countyname: 'Island County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '031',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '033',
    countyname: 'King County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '035',
    countyname: 'Kitsap County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '037',
    countyname: 'Kittitas County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '039',
    countyname: 'Klickitat County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '041',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '043',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '045',
    countyname: 'Mason County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '047',
    countyname: 'Okanogan County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '049',
    countyname: 'Pacific County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '051',
    countyname: 'Pend Oreille County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '053',
    countyname: 'Pierce County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '055',
    countyname: 'San Juan County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '057',
    countyname: 'Skagit County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '059',
    countyname: 'Skamania County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '061',
    countyname: 'Snohomish County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '063',
    countyname: 'Spokane County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '065',
    countyname: 'Stevens County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '067',
    countyname: 'Thurston County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '069',
    countyname: 'Wahkiakum County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '071',
    countyname: 'Walla Walla County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '073',
    countyname: 'Whatcom County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '075',
    countyname: 'Whitman County',
    classfp: 'H1',
  },
  {
    state: 'WA',
    statefp: '53',
    countyfp: '077',
    countyname: 'Yakima County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '001',
    countyname: 'Barbour County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '003',
    countyname: 'Berkeley County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '005',
    countyname: 'Boone County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '007',
    countyname: 'Braxton County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '009',
    countyname: 'Brooke County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '011',
    countyname: 'Cabell County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '013',
    countyname: 'Calhoun County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '015',
    countyname: 'Clay County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '017',
    countyname: 'Doddridge County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '019',
    countyname: 'Fayette County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '021',
    countyname: 'Gilmer County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '023',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '025',
    countyname: 'Greenbrier County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '027',
    countyname: 'Hampshire County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '029',
    countyname: 'Hancock County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '031',
    countyname: 'Hardy County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '033',
    countyname: 'Harrison County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '035',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '037',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '039',
    countyname: 'Kanawha County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '041',
    countyname: 'Lewis County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '043',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '045',
    countyname: 'Logan County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '047',
    countyname: 'McDowell County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '049',
    countyname: 'Marion County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '051',
    countyname: 'Marshall County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '053',
    countyname: 'Mason County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '055',
    countyname: 'Mercer County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '057',
    countyname: 'Mineral County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '059',
    countyname: 'Mingo County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '061',
    countyname: 'Monongalia County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '063',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '065',
    countyname: 'Morgan County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '067',
    countyname: 'Nicholas County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '069',
    countyname: 'Ohio County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '071',
    countyname: 'Pendleton County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '073',
    countyname: 'Pleasants County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '075',
    countyname: 'Pocahontas County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '077',
    countyname: 'Preston County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '079',
    countyname: 'Putnam County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '081',
    countyname: 'Raleigh County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '083',
    countyname: 'Randolph County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '085',
    countyname: 'Ritchie County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '087',
    countyname: 'Roane County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '089',
    countyname: 'Summers County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '091',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '093',
    countyname: 'Tucker County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '095',
    countyname: 'Tyler County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '097',
    countyname: 'Upshur County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '099',
    countyname: 'Wayne County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '101',
    countyname: 'Webster County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '103',
    countyname: 'Wetzel County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '105',
    countyname: 'Wirt County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '107',
    countyname: 'Wood County',
    classfp: 'H1',
  },
  {
    state: 'WV',
    statefp: '54',
    countyfp: '109',
    countyname: 'Wyoming County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '001',
    countyname: 'Adams County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '003',
    countyname: 'Ashland County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '005',
    countyname: 'Barron County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '007',
    countyname: 'Bayfield County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '009',
    countyname: 'Brown County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '011',
    countyname: 'Buffalo County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '013',
    countyname: 'Burnett County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '015',
    countyname: 'Calumet County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '017',
    countyname: 'Chippewa County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '019',
    countyname: 'Clark County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '021',
    countyname: 'Columbia County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '023',
    countyname: 'Crawford County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '025',
    countyname: 'Dane County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '027',
    countyname: 'Dodge County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '029',
    countyname: 'Door County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '031',
    countyname: 'Douglas County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '033',
    countyname: 'Dunn County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '035',
    countyname: 'Eau Claire County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '037',
    countyname: 'Florence County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '039',
    countyname: 'Fond du Lac County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '041',
    countyname: 'Forest County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '043',
    countyname: 'Grant County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '045',
    countyname: 'Green County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '047',
    countyname: 'Green Lake County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '049',
    countyname: 'Iowa County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '051',
    countyname: 'Iron County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '053',
    countyname: 'Jackson County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '055',
    countyname: 'Jefferson County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '057',
    countyname: 'Juneau County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '059',
    countyname: 'Kenosha County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '061',
    countyname: 'Kewaunee County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '063',
    countyname: 'La Crosse County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '065',
    countyname: 'Lafayette County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '067',
    countyname: 'Langlade County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '069',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '071',
    countyname: 'Manitowoc County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '073',
    countyname: 'Marathon County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '075',
    countyname: 'Marinette County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '077',
    countyname: 'Marquette County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '078',
    countyname: 'Menominee County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '079',
    countyname: 'Milwaukee County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '081',
    countyname: 'Monroe County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '083',
    countyname: 'Oconto County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '085',
    countyname: 'Oneida County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '087',
    countyname: 'Outagamie County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '089',
    countyname: 'Ozaukee County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '091',
    countyname: 'Pepin County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '093',
    countyname: 'Pierce County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '095',
    countyname: 'Polk County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '097',
    countyname: 'Portage County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '099',
    countyname: 'Price County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '101',
    countyname: 'Racine County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '103',
    countyname: 'Richland County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '105',
    countyname: 'Rock County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '107',
    countyname: 'Rusk County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '109',
    countyname: 'St. Croix County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '111',
    countyname: 'Sauk County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '113',
    countyname: 'Sawyer County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '115',
    countyname: 'Shawano County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '117',
    countyname: 'Sheboygan County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '119',
    countyname: 'Taylor County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '121',
    countyname: 'Trempealeau County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '123',
    countyname: 'Vernon County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '125',
    countyname: 'Vilas County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '127',
    countyname: 'Walworth County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '129',
    countyname: 'Washburn County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '131',
    countyname: 'Washington County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '133',
    countyname: 'Waukesha County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '135',
    countyname: 'Waupaca County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '137',
    countyname: 'Waushara County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '139',
    countyname: 'Winnebago County',
    classfp: 'H1',
  },
  {
    state: 'WI',
    statefp: '55',
    countyfp: '141',
    countyname: 'Wood County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '001',
    countyname: 'Albany County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '003',
    countyname: 'Big Horn County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '005',
    countyname: 'Campbell County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '007',
    countyname: 'Carbon County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '009',
    countyname: 'Converse County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '011',
    countyname: 'Crook County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '013',
    countyname: 'Fremont County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '015',
    countyname: 'Goshen County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '017',
    countyname: 'Hot Springs County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '019',
    countyname: 'Johnson County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '021',
    countyname: 'Laramie County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '023',
    countyname: 'Lincoln County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '025',
    countyname: 'Natrona County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '027',
    countyname: 'Niobrara County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '029',
    countyname: 'Park County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '031',
    countyname: 'Platte County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '033',
    countyname: 'Sheridan County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '035',
    countyname: 'Sublette County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '037',
    countyname: 'Sweetwater County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '039',
    countyname: 'Teton County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '041',
    countyname: 'Uinta County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '043',
    countyname: 'Washakie County',
    classfp: 'H1',
  },
  {
    state: 'WY',
    statefp: '56',
    countyfp: '045',
    countyname: 'Weston County',
    classfp: 'H1',
  },
  {
    state: 'AS',
    statefp: '60',
    countyfp: '010',
    countyname: 'Eastern District',
    classfp: 'H1',
  },
  {
    state: 'AS',
    statefp: '60',
    countyfp: '020',
    countyname: "Manu'a District",
    classfp: 'H1',
  },
  {
    state: 'AS',
    statefp: '60',
    countyfp: '030',
    countyname: 'Rose Island',
    classfp: 'H4',
  },
  {
    state: 'AS',
    statefp: '60',
    countyfp: '040',
    countyname: 'Swains Island',
    classfp: 'H4',
  },
  {
    state: 'AS',
    statefp: '60',
    countyfp: '050',
    countyname: 'Western District',
    classfp: 'H1',
  },
  {
    state: 'GU',
    statefp: '66',
    countyfp: '010',
    countyname: 'Guam',
    classfp: 'H4',
  },
  {
    state: 'MP',
    statefp: '69',
    countyfp: '085',
    countyname: 'Northern Islands Municipality',
    classfp: 'H1',
  },
  {
    state: 'MP',
    statefp: '69',
    countyfp: '100',
    countyname: 'Rota Municipality',
    classfp: 'H1',
  },
  {
    state: 'MP',
    statefp: '69',
    countyfp: '110',
    countyname: 'Saipan Municipality',
    classfp: 'H1',
  },
  {
    state: 'MP',
    statefp: '69',
    countyfp: '120',
    countyname: 'Tinian Municipality',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '001',
    countyname: 'Adjuntas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '003',
    countyname: 'Aguada Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '005',
    countyname: 'Aguadilla Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '007',
    countyname: 'Aguas Buenas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '009',
    countyname: 'Aibonito Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '011',
    countyname: 'Anasco Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '013',
    countyname: 'Arecibo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '015',
    countyname: 'Arroyo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '017',
    countyname: 'Barceloneta Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '019',
    countyname: 'Barranquitas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '021',
    countyname: 'Bayamon Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '023',
    countyname: 'Cabo Rojo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '025',
    countyname: 'Caguas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '027',
    countyname: 'Camuy Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '029',
    countyname: 'Canovanas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '031',
    countyname: 'Carolina Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '033',
    countyname: 'Catano Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '035',
    countyname: 'Cayey Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '037',
    countyname: 'Ceiba Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '039',
    countyname: 'Ciales Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '041',
    countyname: 'Cidra Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '043',
    countyname: 'Coamo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '045',
    countyname: 'Comerio Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '047',
    countyname: 'Corozal Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '049',
    countyname: 'Culebra Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '051',
    countyname: 'Dorado Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '053',
    countyname: 'Fajardo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '054',
    countyname: 'Florida Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '055',
    countyname: 'Guanica Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '057',
    countyname: 'Guayama Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '059',
    countyname: 'Guayanilla Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '061',
    countyname: 'Guaynabo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '063',
    countyname: 'Gurabo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '065',
    countyname: 'Hatillo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '067',
    countyname: 'Hormigueros Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '069',
    countyname: 'Humacao Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '071',
    countyname: 'Isabela Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '073',
    countyname: 'Jayuya Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '075',
    countyname: 'Juana Diaz Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '077',
    countyname: 'Juncos Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '079',
    countyname: 'Lajas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '081',
    countyname: 'Lares Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '083',
    countyname: 'Las Marias Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '085',
    countyname: 'Las Piedras Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '087',
    countyname: 'Loiza Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '089',
    countyname: 'Luquillo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '091',
    countyname: 'Manati Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '093',
    countyname: 'Maricao Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '095',
    countyname: 'Maunabo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '097',
    countyname: 'Mayaguez Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '099',
    countyname: 'Moca Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '101',
    countyname: 'Morovis Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '103',
    countyname: 'Naguabo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '105',
    countyname: 'Naranjito Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '107',
    countyname: 'Orocovis Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '109',
    countyname: 'Patillas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '111',
    countyname: 'Penuelas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '113',
    countyname: 'Ponce Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '115',
    countyname: 'Quebradillas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '117',
    countyname: 'Rincon Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '119',
    countyname: 'Rio Grande Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '121',
    countyname: 'Sabana Grande Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '123',
    countyname: 'Salinas Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '125',
    countyname: 'San German Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '127',
    countyname: 'San Juan Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '129',
    countyname: 'San Lorenzo Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '131',
    countyname: 'San Sebastian Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '133',
    countyname: 'Santa Isabel Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '135',
    countyname: 'Toa Alta Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '137',
    countyname: 'Toa Baja Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '139',
    countyname: 'Trujillo Alto Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '141',
    countyname: 'Utuado Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '143',
    countyname: 'Vega Alta Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '145',
    countyname: 'Vega Baja Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '147',
    countyname: 'Vieques Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '149',
    countyname: 'Villalba Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '151',
    countyname: 'Yabucoa Municipio',
    classfp: 'H1',
  },
  {
    state: 'PR',
    statefp: '72',
    countyfp: '153',
    countyname: 'Yauco Municipio',
    classfp: 'H1',
  },
  {
    state: 'UM',
    statefp: '74',
    countyfp: '300',
    countyname: 'Midway Islands',
    classfp: 'H4',
  },
  {
    state: 'VI',
    statefp: '78',
    countyfp: '010',
    countyname: 'St. Croix Island',
    classfp: 'H4',
  },
  {
    state: 'VI',
    statefp: '78',
    countyfp: '020',
    countyname: 'St. John Island',
    classfp: 'H4',
  },
  {
    state: 'VI',
    statefp: '78',
    countyfp: '030',
    countyname: 'St. Thomas Island',
    classfp: 'H4',
  },
];
