import React, { Component } from 'react';
import { connect } from 'react-redux';

class Sedan4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M43.9,312.9c0,0-3,0.4-3.6,0.4c-0.5,0-1.4,0.1-1.9,0.5c-0.4,0.3-1.9,1.4-3.6,2.3c-1,0.5-2.2,1-2.8,1.2
	c-0.8,0.4-1.1,0.8-1.5,2.2c-0.4,1.2-1.1,4.8-1.1,12.1c0,2.1,0.5,6.1,1.1,8.1c-1.2,2.3-1.1,5.5-1.1,5.5c0.1,0.3,0.8,0.4,0.8,0.4
	l0.8,0.1c0,0-0.3,0.1-0.1,0.7c0.1,0.5-1.4,2.8-1,4.4c0.3,1.2,2.9,4.3,4.4,6.9c0.5,1-0.7,2.3-1,3c-0.1,0.3,0.1,0.5,0.1,0.8
	c0.1,0.3,0.1,0.7,0.4,1c0.3,0.3,0.8,0.7,1.1,0.8c3.7,0.4,8.1,1.8,13.6,2.9c5.6,1.1,12.1,1.8,15.7,1.8c1.4,0,1.2-1.1,1.2-1.1l0.4,0.3
	c0.3,0.1,0.6,0.3,0.7,0.3c1.5,0.3,13.3,2.1,13.3,2.1c8.1,1,19,2.3,28.6,3.4l1.2,0.4c14.4,1.6,25.3,3,25.3,3c0.6,0.1,0.7-0.1,0.7-0.7
	c0-8-0.7-21.6,4-34.4c2.5-6.7,5.9-13.6,10.9-19.7c10.6-13.1,27-22.8,48.6-23c19-0.1,38.2,9.5,50.1,25.2
	c8.8,11.7,13.1,26.8,13.1,42.6c0,6.1-0.1,12.9-0.1,13.5c0,0.5,0.4,1.4,0.4,1.4s0.3,0.7,0.5,1.2c0.4,0.7,0.8,1.2,1.4,1.2
	c30.5-0.1,322.3,2.9,323.3,2.9c1,0,1.7-0.4,1.7-1v-5.4c0,0-0.1-2.5,0.1-12.7c0.4-11.4,1.5-28.8,12.4-44.2
	c9.6-13.6,27.1-25.7,50.8-25.7c34.9-0.1,59.3,26.7,62,54.6c1.1,10,1.2,28.5,1.2,29.3v1.7v-0.3v0.3h1.5c0.5,0,2.6-0.3,3.4-0.3
	c6.9-0.7,14.3-1.1,25.4-2.1c12-1.1,22.8-2.1,25.5-2.3c5.1-0.6,11.1-1,14-1.7s7.2-1.9,7.6-2.1c0.4-0.1,3-0.3,3.2-0.5
	c0.3-0.3,1.8-0.3,1.4-1c0.1-0.3-2.5-1-2.9-1.5c-0.1-0.3-1-1.5-2.2-1.8c-0.1,0,0.1-1.9,0.1-2.3c0-0.4,1.8-0.8,1.8-1.2
	c0.1-1.2,1-1.2,1-1.7c0-0.3-0.1-0.3-0.4-0.4l0.3-0.1c0,0,0.3,0,0.3-0.3c0-0.3,0.3-1.4,0.1-1.5c0-0.1-0.1-0.3-0.3-0.3
	c-0.1,0-2.1-1-2.2-1.6c0-0.5,0-2.8-0.1-4.1l0.3-0.4h0.1c0.8-0.1,1.8-0.1,2.2-0.3c0.7-0.1,0.4-1.2,0-1.4c-0.7-0.1-1.6-0.3-1.9-0.3
	c-0.1,0-0.3-0.1-0.3-0.3l-0.7-0.3c-0.1-1.6,0.1-1.8,0-4v-0.4c0,0,3-0.1,3.4-0.3c0.7-0.1,0.4-1.1,0-1.1l0.7-2.9l-0.4-16
	c0,0,0.3-0.1,0.3-0.4s-0.1-0.5-0.4-0.4h-1.1l0.3-0.3h0.1c0.8-0.1,0.5-1.4,0.3-1.5c-0.4,0-1.9-0.7-2.5-0.8c1-1.1,1.2-2.9,1.1-4.8
	c0-0.3,0.5-0.3,0.8-0.4c0.1-0.1-1.2-1.2-1.1-1.4c0.1,0-2.1-0.3-2.1-0.4c0.1-0.1,0-0.4,0.1-0.5c0.8-0.6,1.8-0.6,1.9-0.6
	c0.3-0.1,0.3-0.3,0.3-0.4l0.8-0.6c0,0,0.3-0.1,0.1-0.5c-0.1-0.3-1-2.2-1.5-5c-0.6-2.9-2.2-7.6-2.3-8c-0.1-0.4-1.2-0.8-1.2-0.8
	s0-0.1-0.3-0.3c-0.3-0.1-4.3-0.4-7.8-1h-0.3v-0.3c-0.1-0.7,0,0.1,0-0.5c0-0.3,0.1-0.4,0.1-0.4c0.1-0.1,0.7-0.3,1-0.5s0.3-0.6,0-0.8
	c-0.1-0.3-0.8-0.7-1-1c-0.3-0.3-1.1-0.1-1.1-0.1l-0.3,0.1c0.7-0.7,0.5-0.7,1-0.8c0.3-0.3,0.4-0.4,0-0.8
	c-7.3-7.2-25.2-18.2-57.8-25.3c-14-3.2-31.2-6.2-50.8-8.7c-23.7-3-45.8-4.4-68.4-6.1c-2.2-0.1-3.3-0.3-3.3-0.3s-7.8,0.7-18,1.8h-0.3
	c-19-11.7-64.4-40.2-109-57.6c-0.4-0.1-0.1-0.6-0.4-0.8c-19-6.1-46.2-12.5-106.2-14c-34.7-1-71.9,0.7-104.3,5.1h-0.4
	c0,0-0.4-0.3-0.5-0.8c-0.3-0.6-1.2-1.1-1.9-1.2c-0.8,0-1.6-0.1-2.6-0.3c-0.8,0-3.6-2.3-4.8-3.7c-1.2-1.4-2.3-1.2-3.2-1.4
	c-0.8-0.1-1.1,0.4-0.8,1c0.3,0.6,1.1,2.5,1.2,3.2c0.3,0.7,0.7,1.6,0.6,2.3l-0.6,2.8l-0.3,0.1c-10.9,1.9-19.5,4.1-27.2,6.3
	c-0.7,0.1-0.4,0.6-0.4,0.6v0.4h-0.1c-25.4,8.1-51.9,17.5-105.8,42.8c-1-0.3-1.7-0.4-1.8-0.4c-0.6-0.1-1.7-0.3-2.5-0.3
	c-9.5,1-41,4.5-45.9,5.1c-2.2,0.3-5.5,0.6-8.8,0.8c-3.9,0.3-7.7,0.3-8,0.3c-0.7,0-1,0.1-1.1,0.4c-0.1,0.3-0.7,0.8-1,1.2
	c-0.3,0.6-0.3,1.1-0.3,1.4c0,0.4,0.5,5-0.1,6.6c-0.7,1.6-2.1,4.3-4.7,9.1c-2.5,4.7-3.4,9.9-3.6,10.5c-0.1,0.5-0.1,0.8-0.1,1.2
	s-0.1,1.9-0.1,2.2c0,0.4-0.1,0.4,0.4,0.5c0.5,0.1,3.2,0.7,3.6,0.7h0.4c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0.3,0.3
	c0.1,0.1,1.9,1.1,4.7,2.6c0.1,0,0.3,0.1,0.4,0.1v0.1c-1.2,4.4-2.9,11.8-4.1,17.7c-1.1,5.9-2.1,8.4-2.5,8.8c-0.3,0.4-0.3,0.7,0,0.7
	s2.3,0.1,2.3,0.1L43.9,312.9L43.9,312.9z M264.7,384c-0.5,0-1-0.7-1.4-1.2c-0.4-0.7-0.5-1.2-0.5-1.2s-0.1-0.3-0.3-0.5l325.2,6.1
	C577.6,386.9,294.8,383.9,264.7,384L264.7,384z"
          />
          <path
            fill="#5F605F"
            d="M65.3,366.8l-1.5-0.5l-1.7-0.3l-2.2-0.8l-1.5-1.1l-2.6-2.5l-3.6-3.4l-1.9-1.5l-2.1-1.1l-2.3-0.7l-2.8-0.4
	l-2.3-0.4H40l-0.5,0.3l-0.1,0.4v0.3l0.5,1.5l0.8,2.3l0.8,2.3l0.8,1.8l0.8,1.2c0,0,0,0.7,0.4,0.8c1.5,0.4,3.2,0.7,5,1.1
	c5.6,1.1,12.1,1.8,15.7,1.8C65.6,367.9,65.5,366.8,65.3,366.8l0.6,0.3c0.3,0.1,0.6,0.3,0.7,0.3L65.3,366.8L65.3,366.8z M781.4,298.6
	l0.3,1.2l0.5,1v0.4l-1-0.1l-1.5-0.7l0.8,1l0.7,0.4l0.5,0.3l0.5,0.6l0.4,0.8l1.9,4.1l1.4,3.4l1.1,3l1.4,4.5l1.1,5.2l1,5.1l1,6.3
	l0.5,5.4l0.4,4.4l0.3,5.1l0.3,5.9l0.1,3.3l0.1,2.8l0.1,1.5l0.8,0.6l0.7,0.1l0.8,0.4h0.1c0,0,0.3,0,0.3-0.3c0-0.3,0.3-1.4,0.1-1.5
	c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-2.1-1-2.2-1.6c0-0.5,0-2.8-0.1-4.1l0.3-0.4h0.1c0.8-0.1,1.8-0.1,2.1-0.3c0.7-0.1,0.4-1.2,0-1.4
	c-0.7-0.1-1.6-0.3-1.9-0.3c-0.1,0-0.3-0.1-0.3-0.3l-0.7-0.3c-0.1-1.6,0.1-1.8,0-4v-0.4c0,0,3-0.1,3.4-0.3c0.7-0.1,0.4-1.1,0-1.1
	l0.7-2.9l-0.4-16c0,0,0.3-0.1,0.3-0.4c0-0.3-0.1-0.6-0.4-0.4h-1.1l0.3-0.3h0.1c0.8-0.1,0.5-1.4,0.3-1.5c-0.4,0-1.9-0.7-2.5-0.8
	c1-1.1,1.2-2.9,1.1-4.8c0-0.3,0.5-0.3,0.8-0.4c0.1-0.1-1.2-1.2-1.1-1.4c0.1,0-2.1-0.3-2.1-0.4c0.1-0.1,0-0.4,0.1-0.5
	c0.8-0.6,1.8-0.6,1.9-0.6c0.3-0.1,0.3-0.3,0.3-0.4l0.8-0.6c0,0,0.3-0.1,0.1-0.5c-0.1-0.3-1-2.2-1.5-5c-0.5-2.9-2.2-7.6-2.3-8
	c-0.1-0.4-1.2-0.8-1.2-0.8s0-0.1-0.3-0.3c-0.3-0.1-4.3-0.4-7.8-1h-0.3v-0.3c-0.1-0.7,0,0.1,0-0.5c0-0.3,0.1-0.4,0.1-0.4
	c0.1-0.1,0.7-0.3,1-0.5s0.3-0.5,0-0.8c-0.1-0.3-0.8-0.7-1-1c-0.3-0.3-1.1-0.1-1.1-0.1l-0.3,0.1L781.4,298.6L781.4,298.6L781.4,298.6
	z M146.6,358.2c0,8.1,1.9,15.8,5.2,22.6c8.5,17.3,26.3,29.2,46.9,29.2c20.2-0.1,37.7-11.7,46.2-28.6c3.6-7,5.5-15,5.5-23.5
	c-0.1-28.6-23.4-51.7-52.1-51.7C169.8,306.2,146.5,329.6,146.6,358.2z M601,358.2c0,10.3,3.2,20.1,8.4,28.1
	c9.4,14.2,25.5,23.7,43.7,23.5c18.2-0.1,34.1-9.5,43.3-23.7c5.4-8.3,8.4-18,8.4-28.5c-0.1-28.6-23.4-51.9-52.1-51.7
	C624,306.2,600.9,329.4,601,358.2L601,358.2z"
          />
          <path
            fill="#E9EAE9"
            d="M199.5,398.6c-22.8,0.1-41.4-18-41.5-40.4c-0.1-22.4,18.3-40.7,41.3-40.9c22.8-0.1,41.4,18,41.5,40.4
	C240.7,380.2,222.3,398.5,199.5,398.6L199.5,398.6z M653.2,398.5c-22.4,0.1-40.7-18-40.9-40.4c-0.1-22.4,18-40.7,40.4-40.9
	c22.4-0.1,40.7,18,40.9,40.4C693.6,380.2,675.6,398.5,653.2,398.5z"
          />
          <path
            fill="#5F605F"
            d="M44.2,264.9h-0.8c0,0-1,1.1-2.2,4c-1.4,2.9-1.5,5.2-1.5,5.2l0.3,0.8c0.4-1.6,1.1-3.4,1.9-5.1
	C42.8,267.8,43.6,266.1,44.2,264.9L44.2,264.9z"
          />
          <path
            fill="#E9EAE9"
            d="M713.1,292.4c-4.3,0.3-3.4,3-1.5,4.7c1.6,1.4,10.7,9.4,14,12.1c3.2,2.6,5.6,4.1,10.2,4.8
	c4,0.5,17.2,1.4,18.4,1.4c1.4,0,2.3-0.1,3.3-0.5s6.9-3,8-3.6c1.1-0.5,2.2-0.4,3.4-0.3c2.1,0.3,9.4,1,10.5,1c1.1,0,1.8-0.5,1.7-3
	c0-2.3-1.7-7.7-1.7-7.7c-0.1-0.5-0.1-0.8-0.4-1c-7.6-2.9-19.8-5.6-32.3-7.2C732.5,291.5,717.5,292.1,713.1,292.4L713.1,292.4z"
          />
          <path
            fill="#5F605F"
            d="M748.3,345c-0.7,0-3.3,0.1-3.6,2.3s-0.1,12.2,1.9,18.6c0,0.1,0.1,0.4,0.1,0.5c1.2,1.5,1.5,1.6,3.8,1.8
	c0.8,0,4,0.3,8.1,0.5c1.5,0.1,3.2,0.1,5,0.3c4.8,0.3,10.2,0.5,14.4,0.8l5,0.3l3.7,0.3l2.8,0.1l3-0.7l0.1-1.4l1-0.7l1.1-1l-6.6-1.4
	l-0.7-0.3l-0.8-0.7l-0.5-1.2h1.9l0.5-0.1l0.3-0.5l-0.3-0.6l-0.5-0.3l-1.2-0.3l-1.2-0.3l-1.2-4.8l3.6-0.1l0.5-0.3l0.1-0.8l-0.7-0.4
	l-1.6-0.3l-2.6-0.5l-1.7-5.6l0.5-0.5l0.1-0.8l-2.6-0.3c-4.7-0.4-9.8-0.8-13.1-1.1C761,345.5,748.9,345,748.3,345L748.3,345z"
          />
          <path
            fill="#E9EAE9"
            d="M763.3,351.7c-1.7-0.1-4.1,0.8-4.3,6.1c0,1.1,0,1.9,0.3,2.6c0.6,2.8,2.3,3.3,3,3.3h0.4
	c1.4-0.3,4.1-1.8,4.4-6.2c0.3-3.3-1.5-4.8-2.9-5.4C763.9,351.8,763.5,351.7,763.3,351.7L763.3,351.7z"
          />
          <path
            fill="#5F605F"
            d="M748.8,382.8c0,0.3,0.3,0.4,0.5,0.4l19.9-0.3c0.4,0,0.3-0.3,0.3-0.3v-3.4c-4.1,0.4-12.1,1.2-21,1.9
	C748.8,382,748.8,382.7,748.8,382.8L748.8,382.8z M602.1,323.8c-10.9,15.4-12.1,32.7-12.4,44.2c-0.3,10.2-0.1,10.5-0.1,12.7v5.4
	c0,0.4-0.4,0.8-1,1l21-0.1h0.1c-0.1-0.1-0.1-0.3-0.3-0.4c-5.2-8.1-8.4-17.7-8.4-28.1c-0.1-28.6,23-52,51.6-52.1
	c28.6-0.1,52,23,52.1,51.7c0,10.5-3,20.4-8.4,28.5c0,0,0,0.1-0.1,0.1h0.3l19.5-0.1v-2.9l0.1-0.1v-1.1c0-0.8-0.1-19.3-1.2-29.3
	c-2.9-27.9-27.2-54.8-62-54.6C629.4,298.2,611.9,310.3,602.1,323.8L602.1,323.8z"
          />
          <path
            fill="#E9EAE9"
            d="M521.9,254c0.3-0.1,0.8-0.7,1-0.8c0.1,0,0.1-0.3,0-0.3c-1.7-0.3-3.4-0.6-4.7-0.7c-3-0.3-9.2-0.8-20.6-0.4
	c0,0.6,0.1,1,0.1,1.5c11.3-0.3,17.6,0.3,20.5,0.4c0.8,0.1,1.9,0.3,3,0.4C521.6,254.2,521.6,254.3,521.9,254L521.9,254z"
          />
          <path
            fill="#5F605F"
            d="M430.2,278.4c0.1-0.7-0.1-1-1.2-1.2c-0.5-0.1-2.6-0.7-6.1-1.2c-2.8-0.4-6.3-0.7-10.3-0.8
	c-3.2-0.1-5.6-0.1-7.7-0.1h-2.1c-3,0.1-5.1,0.4-5.6,0.5c-1,0.3-1.4,0.8-1.2,1.5c0.1,0.7,0.1,1.9,0.3,2.5c0.1,0.5,1.1,1.1,1.8,1.4
	c2.2,0.5,5.5,0.7,5.9,0.8c0.4,0.1,0.7,0,0.8-0.3c0.4-0.7,1.5-0.8,2.6-1c2.5-0.3,9.8-0.1,12.2,0.3c2.1,0.3,2.5,0.8,2.6,1
	c0.1,0.1,0.3,0.1,0.6,0.1h0.7c2.8-0.1,5-0.3,5.2-0.4c0.5-0.1,1-0.5,1.1-1C430,280,430.2,279.1,430.2,278.4L430.2,278.4z M278.6,274
	c-0.5-0.1-2.6-0.7-6.1-1.2c-2.8-0.4-6.3-0.7-10.3-0.8c-3.2-0.1-5.6-0.1-7.7-0.1h-1.9c-3,0.1-5.1,0.4-5.6,0.5c-1,0.3-1.4,0.8-1.2,1.5
	c0.1,0.7,0.1,1.9,0.3,2.5s1.1,1.1,1.8,1.4c2.2,0.5,5.5,0.7,5.9,0.8c0.4,0.1,0.7,0,0.8-0.3c0.4-0.7,1.5-0.8,2.6-1
	c2.5-0.3,9.8-0.1,12.2,0.3c2.1,0.3,2.3,0.8,2.6,1c0.1,0.1,0.3,0.1,0.5,0.1h0.7c2.8-0.1,5-0.4,5.2-0.4c0.5-0.1,1-0.5,1.1-1
	c0.1-0.4,0.3-1.4,0.4-2.1C279.8,274.7,279.7,274.4,278.6,274L278.6,274z M524.4,259.8c7.4,0.3,14.4,0.7,20.4,1
	c-7.3-5.8-16.4-12.7-25-18.6c0.1,0.1,0.1,0.1,0.3,0.3c2.2,2.2,6.2,6.5,7.3,7.7c0.7,0.1,1.5,0.6,1.9,0.8c0.5,0.4,1.1,0.8,1.1,1.4
	s0,1.4-0.5,2.3c-0.3,0.6-1.5,2.1-2.3,3C526.6,258.6,525.5,259.5,524.4,259.8L524.4,259.8z M263.8,190.3c0,0-0.4-0.3-0.5-0.8
	c-0.3-0.6-1.2-1.1-2.1-1.2l-2.6-0.3c-0.8,0-3.6-2.3-4.8-3.7c-1.2-1.4-2.3-1.2-3.2-1.4c-0.8-0.1-1.1,0.4-0.8,1s1.1,2.5,1.2,3.2
	c0.3,0.7,0.7,1.6,0.6,2.3v0.3c-0.1,0.7-0.4,1.9-0.4,2.5c1.2-0.3,2.5-0.4,3.7-0.7c3-0.6,6.2-1,9.4-1.4L263.8,190.3L263.8,190.3z"
          />
          <path
            fill="#E9EAE9"
            d="M53.2,286.4c-1-0.1-2.1-0.4-2.1-0.4c-0.8-0.4-1.5-0.7-2.2-1h-0.1l-0.3-0.4c-2.8-1.4-4.5-2.5-4.7-2.6
	c-0.1,0-0.1-0.1-0.3-0.3c-0.1-0.3-0.1-0.4-0.1-0.5v-2.1c0-0.4,0.1-0.7,0.1-1.5c0.1-1.2,1.7-7.7,2.8-9.9c1.1-1.9,2.2-2.6,2.9-2.8
	c0.7-0.1,4-1.1,11.6-0.7c12,0.5,18.6,2.2,21.5,2.9c2.8,0.7,2.9,2.2,2.3,3.9c-0.4,1-2.8,8.8-4.8,17.1
	C69.1,287.9,59.6,287.6,53.2,286.4L53.2,286.4z"
          />
          <path
            fill="#5F605F"
            d="M150.8,321.7c-5,6.1-8.4,12.9-10.9,19.7c-4.7,12.8-4.1,26.4-4,34.4c0,0.5-0.1,0.8-0.7,0.7
	c0,0-10.9-1.4-25.3-3l-0.7-0.3l0.4,0.3l26.4,6.1l15.7,1c-3.2-6.7-5-14.2-5.1-22.1c-0.1-28.6,23-52,51.7-52.1
	c28.6-0.1,52,23,52.1,51.7c0,8.3-1.9,16.1-5.4,23.1l17.3-0.1c-0.1-0.3-0.1-0.5-0.1-0.7c0-0.5,0.1-7.4,0.1-13.5
	c0-15.8-4.3-31-13.1-42.6c-11.8-15.5-31.1-25.2-50.1-25.2C177.6,298.9,161.2,308.8,150.8,321.7L150.8,321.7z"
          />
          <path
            fill="#E9EAE9"
            d="M217.2,202.4c-0.6,0.3-1.8,0.6-3,1c-0.8,0.3-1.5-0.1-1.8-0.4c-22.1,7.3-47.5,16.9-92,37.8
	c3,0.6,9.5,1.5,11.1,1.8c2.2,0.4,3.7,0.1,5.4-0.6c2.2-1,20.5-9.1,42.6-18.3c25.3-10.5,41.7-16.1,44.3-16.9c2.6-0.8,1.4-1.5,0.7-1.8
	c-0.6-0.3-4.4-1.9-5.5-2.5C218,202.2,217.8,202.3,217.2,202.4L217.2,202.4z M247.1,219c0.6-1,0-1.4-1.1-1c-8,3.3-16.2,6.9-23.5,10.2
	c-16,7.3-28.5,13.8-31.1,15.7c-3.9,2.8-1.6,4.8,0.7,4.7c2.3-0.1,37.1,0,37.6,0c0.6,0,1-0.3,1.4-1.1
	C231.5,246.7,246.5,219.9,247.1,219L247.1,219z M336.8,203.4c-10.7,0.3-50.3,5.4-57.5,6.9c-7.2,1.4-10,3.4-13.2,8.7
	c-2.5,4.1-6.3,11.3-6.9,12.4c-0.4,1.1-0.7,2.3-0.5,3.3c0.1,1.1,2.3,15.7,2.8,17.9c33.3,0.3,63.6,0.8,85.8,1.2
	c4.7,0.1,9.8,0.1,15.4,0.3c-0.4-2.5-4.3-27.4-6.2-36.7C354.4,207.4,347.5,203.3,336.8,203.4L336.8,203.4z M503.1,238.2
	c0.5-0.3,1.2-0.6,1.8-0.7c-3-1.8-16.8-9.8-30.5-16.2c-10.9-5.1-21.3-8.7-29.6-10.7c-20.1-5.1-41.3-6.3-50.2-6.7
	c-3.6-0.1-7.7,1.2-9.4,4.7c-1.2,2.5-0.8,6.2,1.5,11.6c1.2,2.8,11.3,30.5,12.8,34.8c32.5,1,69.9,2.2,100.6,3.4
	c-0.8-0.7-1.2-1.5-1.5-2.9c-0.1-0.7-0.3-1.5-0.3-2.3c0-0.6-0.1-1-0.1-1.5c0-1-0.1-1.8-0.1-2.5C497.8,242.5,500.5,239.4,503.1,238.2
	L503.1,238.2z M564.4,259.4c0.4,0,0.7-0.1,1.1-0.1c0,0,1-0.3,1.4-0.3c5.6-0.5,11.4-1.2,16.5-1.6c-19.3-11.8-64.8-40.3-109.1-57.6
	c-0.1,0-0.1-0.1-0.3-0.3c-3.2,1-8,2.5-9.4,2.9c24.1,8.9,64.2,29.7,99.3,56.3C564.4,258.8,564.4,259.3,564.4,259.4L564.4,259.4z"
          />
          <path fill="#5F605F" d="M243.1,252.2h14.4l-3-21.7" />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M357,256.8l0.5-1 M39.8,354.3c0.3-0.1,0.7-0.3,1-0.1
	c0.8,0.1,2.1,0.3,5.4,0.8c3.3,0.5,5.2,2.2,6.3,3.2s4.4,4.3,6.1,5.9c1.7,1.6,4,1.9,5.2,2.2c1.4,0.3,2.3,1,3.6,1.2
	c1.4,0.3,9.6,1.6,12.5,1.9c5.4,0.7,12,1.4,18.6,2.3c10.9,1.6,36.6,4.7,36.6,4.7c0.6,0.1,0.7-0.1,0.7-0.7c0-8-0.7-21.6,4.1-34.3
	c2.5-6.7,5.9-13.6,10.9-19.7c10.6-13.1,27-22.8,48.6-23c19-0.1,38.2,9.5,50.1,25c8.8,11.7,13.1,26.8,13.1,42.6
	c0,6.1-0.1,12.9-0.1,13.5s0.4,1.4,0.4,1.4s0.3,0.5,0.5,1.2c0.4,0.7,0.8,1.2,1.4,1.2c30.5-0.1,322.3,2.9,323.3,2.9s1.7-0.4,1.7-1
	v-5.4c0-2.2-0.1-2.5,0.1-12.7c0.4-11.4,1.5-28.8,12.4-44.2c9.6-13.5,27.1-25.6,50.8-25.7c34.9-0.1,59.2,26.7,62,54.5
	c1.1,10,1.2,28.3,1.2,29.3v1.6h1.5c0.1,0,1.1-0.1,1.1-0.1c6.9-0.7,16.8-1.2,27.9-2.3c12-1.1,22.8-2.1,25.5-2.3
	c5.1-0.5,9.5-1,13.3-1.6c2.9-0.5,11.8-2.6,12.1-2.8c0.4-0.1,0.8-0.3,0.8-0.7c-0.1-0.4-2.2-1.2-3-1.5c0.4-0.8-1.4-1.7-2.2-1.9v-2.2
	c0,0,1.1-0.6,1.4-0.6c0.1-0.1,0.8-0.5,0.1-0.7c0.7,0.1,0.8-0.3,0.8-0.3s0.3-0.5,0.4-1c0.1-0.6-0.3-0.6-0.3-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M130.5,361.7c0.4-6.9,1.4-14.6,4.3-22.3c2.8-7.3,6.5-14.7,11.8-21.3
	c11.4-14.2,29.2-24.8,52.8-24.9c20.6-0.1,41.5,10.3,54.3,27.2c9.6,12.7,14.2,29.2,14.3,46.2c0,4,0,8.4-0.1,11.3 M740.4,358.3
	c-1.9,0-18.6-0.4-18.6-0.4c-1.4-0.1-1.8-1.4-1.8-2.9c-0.1-1-0.1-1.9-0.3-2.9c-3.2-30.1-29.3-59-67-58.9
	c-25.5,0.1-44.3,13.2-54.8,27.8c-8.1,11.4-11.1,23.8-12.5,34.4 M797.9,373.9c-22.3,0.1-70.6,1.2-81.8,1.2 M749.7,293.4
	c-1-0.1-1.7-0.5-2.1-0.7c-17.6-9.6-52.3-16.6-78.1-19.4c-25.7-2.8-53.9-5.1-73-6.3c-17.7-1.2-20.4-3.6-29-6.7c0,0-1-0.5-1.5-0.5
	c-3.6,0.4-7.2,1-11,1.4c-0.4,0-0.4,0.5-0.4,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M567,259.9c0,0,0-0.4,0.4-0.4c16.8-1.8,34.9-3.4,34.9-3.4s1.1,0.1,3.3,0.3
	c22.6,1.7,44.7,3,68.4,6.1c19.4,2.5,36.6,5.5,50.6,8.7c32.6,7.2,50.8,18,57.9,25.2c0.4,0.4,0.3,0.7-0.1,0.8c-0.3,0.3-0.3,0.3-1,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M568.4,259.8c43.1,1.8,87.1,5,126.8,10.5c39.8,5.4,62.7,14.6,72.9,20.5
	 M735,292c-33.7-14.3-121.6-18.7-174.4-21c-53.9-2.3-151-4.7-213.4-6.1c-52.5-1.1-137.6-1.8-219,0.3c-15,0.4-29.4,1.2-43.9,1.9
	 M117.2,242.5c53.9-25.3,80.3-34.7,105.8-42.8 M43.7,281.8c0,0-0.1,0.1-0.4,0.1c-0.4-0.1-3.2-0.5-3.6-0.7c-0.5-0.1-0.3-0.1-0.4-0.5
	c0-0.4,0.1-1.8,0.1-2.2s0-0.7,0.1-1.2c0.1-0.5,1.1-5.8,3.6-10.5c2.5-4.7,3.9-7.4,4.7-8.9c0.7-1.7,0.1-6.2,0.1-6.6
	c0-0.4-0.1-1,0.3-1.4c0.3-0.6,0.7-1.1,1-1.2c0.1-0.3,0.4-0.4,1.1-0.4c0.4,0,4.1,0,8-0.3c3.2-0.1,6.6-0.6,8.8-0.8
	c5-0.6,36.5-4.1,45.8-5.1c0.8-0.1,1.9,0.1,2.5,0.3c0.1,0,1,0.1,2.1,0.4 M117.2,242.5c4,0.8,12.2,2.8,12.2,2.8 M75.4,265.2
	c-8.4-5.4-22-12.5-27.2-14.9 M82.9,266.5C68.2,257.3,55.3,250,49.8,248 M47.5,312.2h-5.1c-0.3,0-0.3-0.3,0-0.7
	c0.3-0.4,1.4-2.9,2.5-8.8c1.1-5.9,2.9-13.3,4.1-17.7 M31.2,318.4c14.7,1.1,21.6,1.6,40.3,4c18.7,2.3,67.1,7.4,67.1,7.4 M135.8,362.4
	c0,0-4.1-0.4-5.9-0.7c-28.8-3-58.9-4.5-51.6-3.7s57.2,6.5,57.2,6.5 M258.3,341.1h4.8c0.7,0,1.2-0.1,2.2,0.1c1,0.1,5.9,0.8,5.9,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M79.9,288c-10.9-0.1-20.4-0.4-26.7-1.6c-1-0.1-2.1-0.4-2.1-0.4
	c-4.1-1.9-7-3.6-7.2-3.7c-0.3-0.3-0.5-0.8-0.5-1.2v-2.1c0-0.4,0.1-0.7,0.1-1.5c0.1-1.2,1.7-7.7,2.8-9.9c1-1.9,2.2-2.6,2.9-2.8
	c0.7-0.1,4-1.1,11.6-0.7c12,0.5,18.6,2.2,21.5,2.9c2.8,0.7,2.9,2.2,2.3,3.9C84.3,271.9,81.9,279.8,79.9,288
	c-0.3,0.8-0.4,1.8-0.6,2.6c-0.8,4-1.5,8.3-1.9,11.1c-1.1,9.8-0.4,11.4,13.1,13.2c13.6,1.7,52.3,5.6,52.3,5.6s1.7,0.3,2.9,0.4
	c0.7,0.1,5,0.8,5,0.8 M565,259.8c0,0,0-0.4-0.3-0.7c-35.6-27.1-76.5-48-100.4-56.7c-27-8.7-63.8-12.5-99.7-12.9
	c-27.8-0.3-55.3,1.4-79.1,4.5c-21.2,2.8-39.1,6.5-51.7,10.6c-43.1,14.2-67.7,25.7-88.5,34.7c0,0-8.4,3.9-14.2,5.6
	c-4.8,1.5-10.6,2.8-16.2,3.6c-15.8,2.2-46.5,6.6-49.4,7c-0.3,0-0.7,0.1-1,0.4c0,0-1.1,1.1-1.4,1.5c-0.3,0.4-0.3,0.7-0.1,1
	c0,0.3,0,2.2-0.7,3.3c-0.7,1.2-1,1.7-1.1,1.8c-0.1,0.1,0,0.5,0,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M562.4,260.1c0,0,0-0.5-0.5-0.8c-34.7-26.4-72.2-46.5-96.4-55.7
	c-1.1-0.4-1.5-0.7-1.5-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M584.1,257.7c-19-11.7-64.4-40-109-57.5c-0.4-0.1-0.1-0.6-0.4-0.8
	c-19-6.1-46.2-12.4-106.2-14c-38.1-1-79.2,1-113.6,6.6c-12.8,2.1-22.6,4.5-31.2,6.9c-0.7,0.1-0.4,0.6-0.4,0.6v0.1 M234,204.4
	c-7.7-3.6-11-4.8-11-4.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M389.7,367.9c0,0,0.1-0.4,0.3-1.1c0-0.5,2.2-18.2,2.3-19.5
	c0-1.5,0.5-11.8,0.5-28.9c-0.1-30.5-3.9-40.3-5.9-49.1c-0.3-1.2-0.7-3.3-0.5-3.6c-1.1-3.7-4-7.4-4.8-8.7c0,0-0.3-1.2-0.4-1.8
	s-0.3-0.8-0.3-0.8l-15.8-59.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M589.3,373c-147.6-2.1-246.9-4.1-278.2-5.6c-18.7-0.8-25.6-4.1-34.5-17.3
	c-3.4-5.1-10.9-17.2-18.8-30.3c-12.2-20.1-25.4-42.6-27.2-48c-1.1-3.3-1.6-5.4-1.6-7c0-1.2,0.6-2.1,1.2-3.4c1.5-3,2.8-4.3,4.3-6.3
	c0.6-0.8,2.1-2.8,2.1-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M589.3,371.4c-147.6-2.1-246.9-4.1-278.2-5.6c-18.7-0.8-27.1-5-36-18.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M556.2,263.7c3.8,4.5,4.1,5.8,4.8,7c0.4,0.8,0.7,2.6,0.8,3.4
	c1.4,5.9,4.1,22.7,4.1,44.6c0.1,21.9-0.8,33.2-1.1,34.9c0,0,0,1.2-0.3,2.2c-0.1,0.8-1.1,7.6-2.2,15.1 M464.6,202.4c0,0,4.8-1.4,10-3
	 M465.8,202.9c0,0,5.4-1.7,9.1-2.8 M403.9,281.8c0.4,0.1,0.7,0,0.8-0.3c0.4-0.7,1.5-0.8,2.6-1c2.5-0.3,9.8-0.1,12.2,0.3
	c2.1,0.3,2.5,0.8,2.6,1c0.1,0.1,0.4,0.1,1.2,0.1c2.8-0.1,5-0.4,5.2-0.4c0.5-0.1,1-0.5,1.1-1c0.1-0.4,0.3-1.4,0.4-2.1s-0.1-1-1.2-1.2
	c-0.5-0.3-2.6-0.7-6.1-1.2c-2.8-0.4-6.3-0.7-10.3-0.8c-4.1-0.1-7.3-0.1-9.8-0.1c-3,0.1-5.1,0.4-5.6,0.5c-1,0.3-1.4,0.8-1.2,1.5
	c0.1,0.7,0.1,1.9,0.3,2.5c0.1,0.5,1.1,1.1,1.8,1.4C400.3,281.7,403.5,281.8,403.9,281.8L403.9,281.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M404.4,281.8c0.5,1.5,2.3,3.3,5.5,3.4c3.2,0.1,5.2,0,7.8-0.1
	c3.7-0.3,4.3-1.9,5-3.3 M397.3,280.7c2.1-1.4,13.9-1,20.2-0.8s9.5,0.3,11.1,1 M404.2,276.9c-0.1,0-0.3,0.1-0.3,0.3v0.8
	c0,0.1,0.1,0.3,0.3,0.3h1.5c0.1,0,0.3-0.1,0.3-0.3v-0.8c0-0.3-0.1-0.3-0.3-0.3L404.2,276.9L404.2,276.9z M404.9,275.2
	c0.4-1.2,1.6-2.2,3.9-2.3c2.1-0.1,6.1-0.1,9.1,0c3,0.3,4.4,1.6,4.8,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M402.6,281.7c0,0-0.1-0.8-0.3-1.2c0-0.3-0.1-0.7-0.1-1.1V276
	c0-0.4,0.1-0.5,0.4-0.7"
          />
          <ellipse
            transform="matrix(0.1886 -0.9821 0.9821 0.1886 51.1948 617.6853)"
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="399.4"
            cy="277.9"
            rx="1.4"
            ry="1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M253.5,278.8c0.4,0.1,0.7,0,0.8-0.3c0.4-0.7,1.5-0.8,2.6-1
	c2.5-0.3,9.8-0.1,12.2,0.3c2.1,0.3,2.3,0.8,2.6,1c0.1,0.1,0.4,0.1,1.2,0.1c2.8-0.1,5-0.3,5.2-0.4c0.5-0.1,1-0.5,1.1-1
	c0.1-0.4,0.3-1.4,0.4-2.1c0.1-0.7-0.1-1-1.2-1.2c-0.5-0.1-2.6-0.7-6.1-1.2c-2.8-0.4-6.3-0.7-10.3-0.8c-4.1-0.1-7.3-0.1-9.8-0.1
	c-3,0.1-5.1,0.4-5.5,0.5c-1,0.3-1.4,0.8-1.2,1.5c0.1,0.7,0.1,1.9,0.4,2.5c0.1,0.5,1.1,1.1,1.8,1.4
	C249.8,278.5,253.1,278.7,253.5,278.8L253.5,278.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M253.9,278.8c0.6,1.5,2.3,3.3,5.5,3.4c3.2,0.1,5.2,0,7.8-0.1
	c3.7-0.3,4.3-1.9,5-3.3 M246.9,277.6c2.1-1.4,13.9-1,20.2-0.8s9.5,0.3,11.1,1 M253.7,273.7c-0.1,0-0.3,0.1-0.3,0.3v0.8
	c0,0.1,0.1,0.3,0.3,0.3h1.5c0.1,0,0.3-0.1,0.3-0.3V274c0-0.3-0.1-0.3-0.3-0.3C255.2,273.8,253.8,273.8,253.7,273.7L253.7,273.7z
	 M254.4,272.1c0.4-1.2,1.7-2.2,3.9-2.3c2.1-0.1,6.1-0.1,9.1,0c3,0.3,4.4,1.6,4.7,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M252.3,278.5c0,0-0.1-0.8-0.3-1.2c0-0.3-0.1-0.7-0.1-1.1v-3.3
	c0-0.4,0.1-0.5,0.4-0.7"
          />
          <ellipse
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            cx="763.1"
            cy="342.2"
            rx="0.8"
            ry="1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M262.3,380c76.3-0.3,280.9,0.8,327.3,0.6 M471.8,205.3
	c0,0,4.7-1.9,6.2-2.3c1.5-0.4,3.3,0.7,4.3,0.3 M559.5,255.1l17.7-1.4 M120.6,240.8c0,0,9.1,1.4,11.3,1.6c2.2,0.4,3.7,0.1,5.4-0.6
	c2.2-1,20.5-9.1,42.6-18.3c25.3-10.5,41.5-16.1,44.3-16.9c2.6-0.8,1.4-1.5,0.7-1.8c-0.6-0.3-4.4-1.9-5.5-2.5
	c-1.2-0.4-1.5-0.3-2.1-0.1c-0.6,0.3-1.8,0.6-3,1c-1.2,0.4-1.6-0.4-1.6-0.4 M38.7,313.6c3.7,0.4,4.7,0.4,6.3,0.1c0.4,0,4-0.6,5.5-7.4
	c1.5-6.9,4.4-19.4,4.4-19.4 M39.9,275.2l-0.1-0.8c0,0,0.1-2.3,1.4-5.2c1.2-2.9,2.2-4.1,2.2-4.1l0.8-0.1 M793.2,319.1v-0.5
	c0-0.1,0.1-0.3,0.3-0.3c0,0,1.4-0.3,1.5-0.4c0.3-0.1,0.3-0.3,0.1-0.5l0.8-0.6c0,0,0.3-0.1,0.1-0.5c-0.1-0.3-1.2-3.9-1.9-6.6
	c-0.7-2.8-1.7-5.8-1.8-6.2s-1.4-1-1.4-1s0-0.1-0.3-0.1s-4.5-0.7-8.1-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M795.3,317.3c0,0-0.5,0.3-2.8-7.4c-1.8-6.1-1.7-7.3-1.2-7.3 M793.5,349.9
	c0,0,3.3-0.5,3.7-0.7c0.4-0.1,0.1-0.7,0-0.8c-0.1-0.1,0-0.6,0-0.6s0.3-1.1,0.4-1.4c0.1-0.3,0.1-0.7,0.1-1c0-0.5-0.4-16-0.4-16
	s0.3-0.1,0.3-0.4c0-0.3-0.1-0.4-0.4-0.4h-1.1 M795.4,329.6h1.8 M198.4,317.3c22.4-0.1,40.7,18,40.9,40.4c0.1,22.4-18,40.7-40.4,40.9
	s-40.7-18-40.9-40.4C157.8,335.6,175.9,317.4,198.4,317.3L198.4,317.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M198.4,306c28.6-0.1,52,23,52.1,51.7c0.1,28.6-23,52-51.7,52.1
	c-28.6,0.1-52-23-52.1-51.7C146.5,329.6,169.8,306.2,198.4,306L198.4,306z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.9,361.6c2.2,1.5,5.2,1.1,6.7-1.1c1.5-2.2,1.1-5.2-1.1-6.7
	c-2.2-1.5-5.2-1.1-6.7,1.1C193.3,356.9,193.8,360,195.9,361.6L195.9,361.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M201.4,359c0.7,0,1.4-0.5,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
	c-0.8,0-1.4,0.7-1.4,1.4C200,358.4,200.7,359.1,201.4,359L201.4,359z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M201,357.8c0-0.7-0.6-1.4-1.4-1.4c-0.7,0-1.4,0.7-1.4,1.4
	c0,0.8,0.6,1.4,1.4,1.4C200.3,359.1,201,358.4,201,357.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M199.1,357.8c0-0.7-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.7-1.4,1.4
	c0,0.7,0.6,1.4,1.4,1.4C198.5,359.1,199.1,358.4,199.1,357.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.9,359.1c0.8,0,1.4-0.7,1.4-1.4s-0.6-1.4-1.4-1.4
	c-0.7,0-1.4,0.5-1.4,1.4C194.5,358.4,195.2,359.1,195.9,359.1L195.9,359.1z M203.7,353.1c0.7,0,1.2-0.3,1.6-1c0.7-1,0.6-2.3-0.6-3
	c-1-0.7-2.3-0.5-3,0.5c-0.4,0.5-0.6,1.2-0.4,1.8 M204.8,361.2c0.3,0.5,0.7,1.1,1.4,1.4c1.1,0.4,2.3-0.3,2.8-1.4
	c0.4-1.1-0.3-2.3-1.4-2.8c-0.7-0.1-1.4-0.1-1.8,0.3 M193.4,352.9c-0.7,0-1.2-0.3-1.6-1c-0.7-1-0.6-2.3,0.4-3c1-0.7,2.3-0.5,3,0.4
	c0.4,0.5,0.6,1.2,0.4,1.8 M197.3,364.8c-0.3,0.3-0.6,0.7-0.7,1.1c-0.4,1.1,0.3,2.3,1.4,2.8c1.1,0.4,2.3-0.3,2.8-1.4
	c0.3-1,0-1.8-0.7-2.3 M192.5,361.2c-0.1,0.4-0.4,0.8-0.8,1.1c-1,0.7-2.3,0.5-3-0.4s-0.6-2.3,0.4-3c0.8-0.5,1.8-0.5,2.5,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M205.5,349.8L203,354c0.8,1.1,1.4,2.5,1.2,3.9l4.5,2.1 M208.4,362l-5-1.1
	l-0.1,0.1c-0.8,1.1-1.9,1.8-3.2,2.2l-0.4,5.2 M197.7,368.3l-0.6-5.2c-0.6-0.1-1.2-0.4-1.8-0.8c-0.6-0.4-1-0.8-1.4-1.4l-5.1,1.1
	 M188.3,360.1l4.8-2.1c-0.1-1.2,0.3-2.6,1.1-3.7l0.1-0.1l-2.6-4.3 M203.9,348.7l-3.2,3.7c-1.4-0.5-2.8-0.4-4,0l-3.4-3.9
	 M696.6,386.2h19.5v-3 M587.9,386.9l21.6-0.3 M245.3,380.7h17.1 M109.6,373.4c6.5,0.8,26.4,6.1,26.4,6.1l15.7,1 M190,247.6
	c1-1.9,13.3-5.4,15.8-6.5s38-17.3,38-17.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231.1,247.6c0,0-1.6-1.1-7.6-2.2c-5.9-1.1-17.7-4.4-17.7-4.4
	 M263.8,190.5c0,0-0.4-0.3-0.5-0.8c-0.3-0.6-1.2-1.1-1.9-1.2c-0.8,0-1.6-0.1-2.6-0.1c-0.8-0.1-3.6-2.3-4.8-3.7s-2.3-1.2-3.2-1.4
	c-0.8-0.1-1.1,0.4-0.8,1c0.3,0.6,1.1,2.5,1.2,3.2c0.3,0.7,0.7,1.6,0.6,2.3l-0.6,2.8 M44.6,312.2c-0.8,0.8-1.5,0.7-1.5,0.7l-4.5,0.5
	c-2.1,2.1-5.5,3.3-6.5,3.7c-1,0.4-1.2,0.8-1.4,1.2c-0.3,1.1-1.4,5.4-1.4,10.5c0,5.2,0.7,9.5,0.8,10c0.1,0.5,0.3,1,0,1.6
	c-0.3,0.8-0.8,3.2-0.8,3.9c0,0.7,0.5,1.2,1.1,1.2c0.5,0.1,11.4,1.6,16.4,2.6c5,1,10.5,4.3,14.4,6.6c4,2.3,18,12,19,12.4
	c1,0.6,1.5,1.1,2.6,1.1c1.1,0.1,9.4,1.8,15.7,2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M31.1,346.1c-0.3,0.5-0.4,1.1-1,3.2c-0.5,2.2,0.1,2.8,0.7,3.9
	c0.7,1.1,2.3,3.3,3.2,4.4c1,1.1,0.1,2.3-0.4,2.9c-0.5,0.5-0.3,1.2-0.3,1.2l0.5,1.1c0.3,0.4,0.7,0.7,1.4,0.8
	c0.7,0.1,9.9,2.1,14.4,2.8c4.4,0.7,14.4,1.6,15,1.8c0.6,0.1,0.6-0.4,0.6-0.4v-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M63.8,366.3c-7.2,0-16.6-0.7-18.6-1c-1.4-0.3-1.9-0.8-2.2-1.5
	c-1.5-2.8-2.1-5.5-2.6-6.9c-0.5-1.2,1.1-1.2,1.1-1.2h7.2 M40.2,356.8c-0.3-0.5-0.4-1.1-0.5-1.4c-0.3-0.5-0.1-0.8,0.1-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M40.3,356.9c-0.3-0.7,0.4-0.7,1.5-0.5c1,0.1,5.9,1.1,6.9,1.4
	c1,0.3,1.8,1,2.1,1.2c0.3,0.3,3.4,4.4,3.9,5.1c0.3,0.4,0.3,0.7,0.1,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M54.8,364.9c-0.1,0.3-0.5,0.5-1.2,0.5c-1.4,0.1-6.5,0-8.5-0.3 M30.7,352.7
	c2.5,0.5,6.6,1.1,7.2,1.1c0.5,0.1,0.8,0.6,1,1c1,3,2.9,8.3,3.2,8.7c0.1,0.4,0.7,1.5,1,1.6 M30.4,339.9c4.5,0.5,9.5,1.2,18.2,3
	c9.1,1.8,17.6,6.5,29.4,12.4 M43.6,308.5c1.1,0.1,4.7,0.5,5.9,0.5 M203.2,343.3c2.1,0.8,3-0.6,3.6-1.2l8.8-12.1
	c0.3-0.4,0.3-1-0.3-1.4c-3.9-2.2-8.3-3.7-12.9-4.3c-0.4,0-1,0-1,0.8v15.1C201.4,340.8,201.3,342.6,203.2,343.3L203.2,343.3z
	 M210.9,348.8c1.2,1.8,2.8,1.4,3.7,1.1l14.2-4.5c0.6-0.1,0.8-0.5,0.6-1.2c-1.8-4.1-4.5-7.8-8-11c-0.3-0.3-0.8-0.5-1.2,0.1l-8.9,12.2
	C210.9,345.8,209.8,347,210.9,348.8L210.9,348.8z M213.9,357.8c-0.1,2.2,1.5,2.8,2.3,3l14.2,4.5c0.6,0.1,1,0,1.1-0.7
	c1-4.4,1-9.1,0-13.6c-0.1-0.4-0.3-0.8-1.1-0.7l-14.3,4.7C215.7,355.3,214,355.7,213.9,357.8z M211,366.8c-1.4,1.6-0.4,3.2,0.1,3.9
	l8.8,12.1c0.4,0.4,0.8,0.5,1.4,0.1c3.3-3,6.1-6.7,8.1-11c0.1-0.4,0.3-1-0.6-1.1l-14.3-4.7C213.9,365.9,212.4,365.2,211,366.8
	L211,366.8z M203.6,372.5c-2.1,0.6-2.2,2.2-2.2,3.2v14.9c0,0.5,0.3,1,1,1c4.5-0.5,8.9-1.9,13.1-4.3c0.4-0.3,0.7-0.6,0.3-1.2
	l-8.9-12.2C206.5,373.4,205.7,371.9,203.6,372.5z M194.2,372.6c-2.1-0.8-3,0.5-3.6,1.2l-8.8,12.1c-0.3,0.4-0.3,1,0.3,1.4
	c3.9,2.2,8.3,3.7,12.9,4.3c0.4,0,1,0,1-0.8v-15.1C196,375.1,196.2,373.4,194.2,372.6L194.2,372.6z M186.3,367.2
	c-1.2-1.8-2.8-1.4-3.7-1.1l-14.2,4.5c-0.6,0.1-0.8,0.5-0.6,1.2c1.9,4.1,4.5,7.8,8,11c0.3,0.3,0.8,0.6,1.2-0.1l8.9-12.2
	C186.3,370.3,187.5,369,186.3,367.2L186.3,367.2z M183.2,358.3c0.1-2.2-1.5-2.8-2.3-3l-14.2-4.5c-0.6-0.1-1,0-1.1,0.7
	c-1,4.4-1,9.1,0,13.6c0.1,0.4,0.3,0.8,1.1,0.7l14.3-4.7C181.4,360.8,183.1,360.5,183.2,358.3L183.2,358.3z M186.1,349.1
	c1.4-1.6,0.4-3.2-0.1-3.9l-8.8-12.1c-0.4-0.4-0.8-0.5-1.4-0.1c-3.3,3-6.1,6.7-8.1,11c-0.1,0.4-0.3,1,0.6,1.1l14.3,4.7
	C183.2,350.1,184.7,350.7,186.1,349.1L186.1,349.1z M193.7,343.5c2.1-0.6,2.2-2.2,2.2-3.2v-14.9c0-0.5-0.3-1-1-1
	c-4.5,0.5-8.9,1.9-13.1,4.3c-0.4,0.3-0.7,0.5-0.3,1.2l8.9,12.2C190.8,342.5,191.6,344,193.7,343.5L193.7,343.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M192.5,353.9l-27.1-8.8c-1-0.3-1-1.1-0.7-1.6c2.6-5.9,5.6-9.9,9.8-13.5
	c0.6-0.5,1.1-0.4,1.5,0.1l13.5,18.3c0.4,0.5,1,1.2,2.3,0.3c1.4-1,0.8-1.8,0.4-2.3L179.2,328c-0.6-0.8-0.1-1.5,0.4-1.8
	c5.6-3.2,10.3-4.7,15.8-5.1c0.8-0.1,1.2,0.3,1.2,1l0.1,28.6c0,0.4,0,1,1.8,1.1c1.8,0,1.8-0.5,1.8-1.1v-28.5c0-1,0.8-1.2,1.4-1.1
	c6.5,0.7,11.1,2.3,15.8,5.2c0.7,0.4,0.8,1,0.4,1.5l-13.3,18.4c-0.4,0.5-1,1.4,0.4,2.3c1.2,1,1.9,0.3,2.3-0.3l13.3-18
	c0.6-0.8,1.4-0.6,1.8-0.1c4.8,4.4,7.6,8.4,9.8,13.5c0.3,0.7,0.1,1.2-0.6,1.5l-27.1,8.9c-0.4,0.1-1,0.4-0.4,2.1
	c0.6,1.6,1.1,1.5,1.5,1.4l27.1-8.8c1-0.3,1.4,0.4,1.5,1c1.4,6.3,1.2,11.3,0,16.6c-0.1,0.8-0.7,1.1-1.4,0.8l-21.6-6.9
	c-0.7-0.1-1.5-0.4-2.1,1.1c-0.6,1.5,0.4,1.9,1,2.1l21.3,7.2c1,0.3,1,1.1,0.7,1.6c-2.6,5.9-5.6,9.9-9.8,13.5
	c-0.6,0.5-1.1,0.4-1.5-0.1l-16.9-23.1c-0.3-0.4-0.7-0.8-2.1,0.3c-1.4,1-1.1,1.5-0.8,1.9l16.9,23.2c0.6,0.8,0.1,1.5-0.4,1.8
	c-5.6,3.2-10.3,4.7-15.8,5.1c-0.8,0.1-1.2-0.3-1.2-1l-0.1-22.7c0-0.7,0-1.6-1.6-1.6s-1.7,1-1.7,1.6l-0.3,22.4c0,1-0.8,1.2-1.4,1.2
	c-6.5-0.7-11.1-2.3-15.8-5.2c-0.7-0.4-0.8-1-0.4-1.5l16.8-23.2c0.3-0.4,0.6-0.8-1-1.9c-1.5-1.1-1.9-0.7-2.2-0.3l-16.9,23.1
	c-0.6,0.8-1.4,0.5-1.8,0.1c-4.8-4.4-7.6-8.4-9.8-13.5c-0.3-0.7-0.1-1.2,0.6-1.5l21.6-7.2c0.7-0.3,1.5-0.5,1.1-2.1
	c-0.4-1.5-1.4-1.4-2.1-1.1l-21.5,6.7c-1,0.3-1.4-0.4-1.5-1c-1.4-6.3-1.2-11.3,0-16.6c0.1-0.8,0.7-1.1,1.4-0.8l27.2,8.7
	c0.4,0.1,1,0.3,1.6-1.5C193.4,354.3,192.9,354,192.5,353.9L192.5,353.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M160.3,347.4c-1,3.4-1.4,7-1.4,10.7c0,3.7,0.6,7.3,1.5,10.7
	c0.1,0.5,0.4,0.5,0.7,0.4l25.2-8.3l0.7,2.1l-25.2,8.3c-0.3,0.1-0.6,0.3-0.4,0.8c2.6,6.7,7,12.4,12.5,16.9c0.4,0.3,0.7,0.1,0.8-0.1
	l19.3-26.4l1.8,1.2l-19.3,26.4c-0.1,0.3-0.3,0.5,0.1,0.8c5.9,3.9,12.9,6.2,20.4,6.5c0.6,0,0.7-0.3,0.7-0.5v-26.5h2.2V397
	c0,0.3,0.1,0.5,0.7,0.5c7.3-0.4,14-2.9,19.8-6.6c0.4-0.3,0.4-0.5,0.3-0.8l-19.3-26.4l1.8-1.2l19.3,26.4c0.1,0.3,0.4,0.4,0.8,0.1
	c5.6-4.5,10-10.5,12.5-17.3c0.1-0.4-0.1-0.7-0.4-0.8l-25.2-8.3l0.7-2.1l25.2,8.3c0.3,0.1,0.7,0,0.8-0.4c1-3.4,1.4-6.9,1.4-10.6
	c0-3.6-0.6-7.2-1.4-10.5c-0.1-0.5-0.4-0.5-0.8-0.4l-31.1,10l-0.7-2.1l31.1-10c0.3-0.1,0.6-0.3,0.4-0.8c-2.6-6.9-6.9-12.8-12.5-17.2
	c-0.4-0.3-0.7-0.1-1,0.1l-15.5,21.5l-1.8-1.2l15.5-21.5c0.1-0.3,0.3-0.5-0.1-0.8c-5.8-3.9-12.7-6.2-20.1-6.5c-0.6,0-0.7,0.3-0.7,0.5
	v32.7h-2.2V319c0-0.3-0.1-0.5-0.7-0.5c-7.4,0.3-14.4,2.8-20.4,6.6c-0.4,0.3-0.4,0.7-0.1,1l15.5,21.5l-1.8,1.2l-15.3-21.6
	c-0.1-0.3-0.6-0.4-1-0.1c-5.5,4.4-9.9,10.3-12.4,17.2c-0.1,0.4,0,0.7,0.4,0.8l31.1,10l-0.7,2.1l-31.1-10
	C160.7,346.9,160.4,346.9,160.3,347.4 M652.7,317.2c22.4-0.1,40.7,18,40.9,40.4c0.1,22.4-18,40.7-40.4,40.9
	c-22.4,0.1-40.7-18-40.9-40.4C612.2,335.6,630.3,317.3,652.7,317.2L652.7,317.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M652.7,306c28.6-0.1,52,23,52.1,51.7c0.1,28.6-23,52-51.7,52.1
	c-28.6,0.1-52-23-52.1-51.7C600.9,329.4,624,306.2,652.7,306L652.7,306z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M650.1,361.5c2.2,1.5,5.2,1.1,6.7-1.1c1.5-2.2,1.1-5.2-1.1-6.7
	c-2.2-1.5-5.2-1.1-6.7,1.1C647.5,356.9,647.9,360,650.1,361.5L650.1,361.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M655.6,359c0.7,0,1.4-0.5,1.4-1.4c0-0.7-0.5-1.4-1.4-1.4s-1.4,0.7-1.4,1.4
	C654.3,358.4,654.8,359,655.6,359L655.6,359z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M655.1,357.6c0-0.7-0.5-1.4-1.4-1.4c-0.7,0-1.4,0.7-1.4,1.4
	c0,0.8,0.7,1.4,1.4,1.4C654.5,359,655.1,358.3,655.1,357.6L655.1,357.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M653.3,357.6c0-0.7-0.5-1.4-1.4-1.4c-0.7,0-1.4,0.7-1.4,1.4
	c0,0.7,0.5,1.4,1.4,1.4C652.7,359,653.3,358.4,653.3,357.6L653.3,357.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M650.1,359c0.8,0,1.4-0.5,1.4-1.4c0-0.7-0.5-1.4-1.4-1.4
	c-0.7,0-1.4,0.7-1.4,1.4C648.7,358.4,649.3,359,650.1,359L650.1,359z M658,352.9c0.7,0,1.2-0.3,1.7-1c0.7-1,0.5-2.3-0.5-3
	c-1-0.7-2.3-0.5-3,0.6c-0.4,0.5-0.5,1.2-0.4,1.8 M659.1,361.1c0.3,0.6,0.7,1.1,1.4,1.4c1.1,0.4,2.3-0.3,2.8-1.4
	c0.4-1.1-0.3-2.3-1.4-2.8c-0.7-0.3-1.4-0.1-1.8,0.3 M647.7,352.8c-0.7,0-1.2-0.3-1.7-1c-0.7-1-0.5-2.3,0.4-3c1-0.7,2.3-0.6,3,0.4
	c0.4,0.6,0.5,1.2,0.4,1.8 M651.5,364.6c-0.3,0.3-0.5,0.7-0.7,1.1c-0.4,1.1,0.3,2.3,1.4,2.8c1.1,0.4,2.3-0.3,2.8-1.4
	c0.3-1,0-1.8-0.7-2.3 M646.7,361.2c-0.1,0.4-0.4,0.8-0.8,1.1c-1,0.7-2.3,0.5-3-0.4c-0.7-1-0.5-2.3,0.4-3c0.8-0.5,1.8-0.5,2.5,0"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M659.8,349.8l-2.5,4.3c0.8,1.1,1.4,2.5,1.2,3.9l4.7,1.9 M662.6,361.9
	l-5-1.1l-0.1,0.1c-0.8,1.1-1.9,1.8-3.2,2.2l-0.4,5.2 M651.8,368.2l-0.5-5.2c-0.6-0.1-1.2-0.4-1.8-0.8c-0.6-0.4-1-0.8-1.4-1.4
	l-5.1,1.1 M642.6,360.1l4.8-2.1c-0.1-1.2,0.3-2.6,1.1-3.7l0.1-0.1l-2.6-4.3 M658,348.7l-3.2,3.7c-1.4-0.5-2.8-0.4-4,0l-3.4-3.9
	 M657.6,343.3c2.1,0.8,3-0.6,3.7-1.2l8.8-12.1c0.3-0.4,0.3-1-0.3-1.4c-3.9-2.2-8.3-3.7-12.9-4.3c-0.4,0-1,0-1,0.8v15.1
	C655.8,340.8,655.5,342.5,657.6,343.3L657.6,343.3z M665.3,348.7c1.2,1.8,2.8,1.4,3.7,1.1l14.2-4.5c0.5-0.1,0.8-0.5,0.5-1.2
	c-1.9-4.1-4.5-7.8-8-11c-0.3-0.3-0.8-0.5-1.2,0.1l-8.9,12.2C665.3,345.7,664,346.9,665.3,348.7L665.3,348.7z M668.1,357.8
	c-0.1,2.2,1.5,2.8,2.3,3l14.2,4.5c0.5,0.1,1,0,1.1-0.7c1-4.4,1-9.1,0-13.6c-0.1-0.4-0.3-0.8-1.1-0.7l-14.3,4.7
	C669.9,355.3,668.3,355.6,668.1,357.8L668.1,357.8z M665.4,366.7c-1.4,1.6-0.4,3,0.1,3.9l8.8,12.1c0.4,0.4,0.8,0.5,1.4,0.1
	c3.3-3,6.1-6.7,8-11c0.1-0.4,0.3-1-0.5-1.1l-14.3-4.7C668.3,365.7,666.8,365,665.4,366.7L665.4,366.7z M658,372.5
	c-2.1,0.6-2.2,2.2-2.2,3.2v14.9c0,0.5,0.3,1,1,1c4.5-0.5,8.9-1.9,13.1-4.3c0.4-0.3,0.7-0.6,0.3-1.2l-8.9-12.2
	C660.9,373.3,660,371.8,658,372.5L658,372.5z M648.5,372.5c-2.1-0.8-3,0.6-3.7,1.2l-8.8,12.1c-0.3,0.4-0.3,1,0.3,1.4
	c3.9,2.2,8.3,3.7,12.9,4.3c0.4,0,1,0,1-0.8v-15.1C650.3,375,650.5,373.3,648.5,372.5L648.5,372.5z M640.6,367.1
	c-1.2-1.8-2.8-1.4-3.7-1.1l-14.2,4.5c-0.5,0.1-0.8,0.5-0.5,1.2c1.9,4.1,4.5,7.8,8,11c0.3,0.3,0.8,0.5,1.2-0.1l8.9-12.2
	C640.6,370.1,641.7,368.9,640.6,367.1L640.6,367.1z M637.6,358.2c0.1-2.2-1.5-2.8-2.3-3l-14.2-4.5c-0.5-0.1-1,0-1.1,0.7
	c-1,4.4-1,9.1,0,13.6c0.1,0.4,0.3,0.8,1.1,0.7l14.3-4.7C635.8,360.6,637.5,360.4,637.6,358.2L637.6,358.2z M640.5,349
	c1.4-1.6,0.4-3.2-0.1-3.9l-8.8-12.1c-0.4-0.4-0.8-0.7-1.4-0.1c-3.3,3-6.1,6.7-8.1,11c-0.1,0.4-0.3,1,0.5,1.1l14.3,4.7
	C637.6,349.9,639.1,350.6,640.5,349L640.5,349z M648.1,343.3c2.1-0.6,2.2-2.2,2.2-3.2v-14.9c0-0.5-0.3-1-1-1
	c-4.5,0.5-8.9,1.9-13.1,4.3c-0.4,0.3-0.7,0.5-0.3,1.2l8.9,12.2C645.2,342.5,646,343.9,648.1,343.3L648.1,343.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.7,353.9l-27.1-8.8c-1-0.3-1-1.1-0.7-1.6c2.6-5.9,5.6-9.9,9.8-13.5
	c0.5-0.5,1.1-0.4,1.5,0.1l13.5,18.3c0.4,0.5,1,1.2,2.3,0.3c1.4-1,0.8-1.8,0.4-2.3L633.5,328c-0.5-0.8-0.1-1.5,0.4-1.8
	c5.6-3.2,10.3-4.7,15.8-5.1c0.8-0.1,1.2,0.3,1.2,1l0.1,28.6c0,0.4,0,1,1.8,1.1c1.8,0,1.8-0.5,1.8-1.1v-28.5c0-1,0.8-1.2,1.4-1.2
	c6.5,0.7,11.1,2.3,15.8,5.2c0.7,0.4,0.8,1,0.4,1.5l-13.3,18.4c-0.4,0.5-1,1.4,0.4,2.3c1.2,1,1.9,0.3,2.3-0.3l13.3-18
	c0.6-0.8,1.4-0.5,1.8-0.1c4.8,4.4,7.6,8.4,9.8,13.5c0.3,0.7,0.1,1.2-0.5,1.5l-27.1,8.9c-0.4,0.1-1,0.4-0.4,2.1
	c0.6,1.6,1.1,1.5,1.5,1.4l27.1-8.8c1-0.3,1.4,0.4,1.5,1c1.4,6.3,1.2,11.3,0,16.6c-0.1,0.8-0.7,1.1-1.4,0.8l-21.6-6.9
	c-0.7-0.1-1.5-0.4-2.1,1.1c-0.5,1.5,0.4,1.9,1,2.1l21.3,7.1c1,0.3,1,1.1,0.7,1.6c-2.6,5.9-5.6,9.9-9.8,13.5
	c-0.5,0.6-1.1,0.4-1.5-0.1l-16.9-23.1c-0.3-0.4-0.7-0.8-2.1,0.3c-1.4,1-1.1,1.5-0.8,1.9l16.9,23.1c0.5,0.8,0.1,1.5-0.4,1.8
	c-5.6,3.2-10.3,4.7-15.8,5.1c-0.8,0.1-1.2-0.3-1.2-1l-0.1-22.7c0-0.7,0-1.7-1.6-1.7c-1.7,0-1.7,1-1.7,1.7l-0.3,22.4
	c0,1-0.8,1.2-1.4,1.2c-6.5-0.7-11.1-2.3-15.8-5.2c-0.7-0.4-0.8-1-0.4-1.5l16.6-23.2c0.3-0.4,0.5-0.8-1-1.9c-1.5-1.1-1.9-0.7-2.2-0.3
	l-16.9,23.2c-0.6,0.8-1.4,0.6-1.8,0.1c-4.8-4.4-7.7-8.4-9.8-13.5c-0.3-0.7-0.1-1.2,0.5-1.5l21.6-7.2c0.7-0.3,1.5-0.5,1.1-2.1
	c-0.4-1.5-1.4-1.2-2.1-1.1l-21.5,6.7c-1,0.3-1.4-0.4-1.5-1c-1.4-6.3-1.2-11.3,0-16.6c0.1-0.8,0.7-1.1,1.4-0.8l27.2,8.7
	c0.4,0.1,1,0.3,1.6-1.5C647.6,354.2,647.2,354,646.7,353.9L646.7,353.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.6,347.3c-1,3.4-1.4,7-1.4,10.7s0.5,7.3,1.5,10.7
	c0.1,0.5,0.4,0.5,0.8,0.4l25.2-8.3l0.7,2.1l-25.2,8.3c-0.3,0.1-0.5,0.3-0.4,0.8c2.6,6.7,7,12.4,12.5,16.9c0.4,0.3,0.7,0.1,0.8-0.1
	l19.3-26.4l1.8,1.2L631,390.1c-0.1,0.3-0.3,0.5,0.1,0.8c5.9,3.9,12.9,6.2,20.4,6.5c0.5,0,0.7-0.3,0.7-0.5v-26.5h2.2v26.5
	c0,0.3,0.1,0.5,0.7,0.5c7.3-0.4,14-2.9,19.8-6.6c0.4-0.3,0.4-0.5,0.3-0.8l-19.3-26.4l1.8-1.2l19.3,26.4c0.1,0.3,0.4,0.4,0.8,0.1
	c5.6-4.5,10-10.5,12.5-17.3c0.1-0.4-0.1-0.7-0.4-0.8l-25.2-8.3l0.7-2.1l25.2,8.3c0.3,0.1,0.7,0,0.8-0.4c1-3.4,1.4-6.9,1.4-10.6
	c0-3.6-0.5-7.2-1.4-10.5c-0.1-0.5-0.4-0.5-0.8-0.4l-31.1,10l-0.7-2.1l31.1-10c0.3-0.1,0.5-0.3,0.4-0.8c-2.6-6.9-6.9-12.8-12.5-17.2
	c-0.4-0.3-0.7-0.1-1,0.1l-15.5,21.5l-1.8-1.2l15.5-21.5c0.1-0.3,0.3-0.5-0.1-0.8c-5.8-3.9-12.7-6.2-20.1-6.5c-0.5,0-0.7,0.3-0.7,0.5
	v32.7h-2.2v-32.7c0-0.3-0.1-0.5-0.7-0.5c-7.4,0.3-14.4,2.8-20.4,6.6c-0.4,0.3-0.4,0.7-0.1,1l15.5,21.5l-1.8,1.2l-15.5-21.5
	c-0.1-0.3-0.5-0.4-1-0.1c-5.5,4.4-9.9,10.3-12.4,17.2c-0.1,0.4,0,0.7,0.4,0.8l31.1,10l-0.7,2.1l-31.1-10
	C615,346.7,614.8,346.9,614.6,347.3 M748.6,381.3c0,0,0.1,1.1,0.1,1.4s0.3,0.4,0.5,0.4c0.3,0,19.7-0.3,19.9-0.3
	c0.4,0,0.3-0.3,0.3-0.3v-3.3 M781.6,297.9c0.7-0.5,1.2-0.1,1.2-0.1s0.5,0.5,1.1,1c0.4,0.4,0,0.8,0,0.8s-0.8,0.5-1.1,0.8
	c-0.4,0.3-0.1,1,0,1.2c0.3,0.5,2.6,6.2,3.4,8.5c1,2.3,2.8,6.9,4,13.6c1.2,6.7,2.6,14.7,3,24.5c0.3,7.3,0.5,11.3,0.5,13.1
	c0,0.5,0.7,0.8,1,1c0.8,0.3,1.5,0.3,1.5,0.3s0.5,0.1,0.3,0.5c-0.1,0.6-0.4,1-0.4,1s-0.3,0.4-0.6,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M795.5,364.6c0,0-0.5-0.1-1.5-0.3c-0.8-0.3-1-0.8-1-1.6
	c0-0.7-0.1-4.5-0.5-14.3c-0.4-9.8-1.8-17.7-3-24.5c-1.2-6.7-2.9-11.1-3.9-13.6c-1-2.3-3.2-7-3.4-7.7c-0.3-0.5-1-0.8-1.2-0.8
	c-0.3-0.1-0.6-0.4-0.6-0.6c-0.1-0.1-0.3-0.4-0.4-0.7 M716.6,337.8c27.4,0.4,57.4,2.6,75.5,4.4 M754.1,315.4
	c12.8,1.4,25.6,3.3,34.4,4.7 M785.5,307.7c1.1-0.1,3.6-0.5,4.3-0.7c0.8-0.1,0.1-1-0.3-1.4c-0.6-0.4-1.2-0.5-1.2-0.5l-4.1-0.5
	 M784.8,306l5.1,0.1 M785.2,307.1l3.7-0.1 M790.9,326.8c0.7,0.3,2.3,0.5,3.2,0.5l2.9,0.1 M788.1,315h0.7c1.1-0.1,3.6-0.7,4.4-0.8
	c0.8-0.1,0.1-1-0.3-1.4c-0.5-0.4-1.2-0.6-1.2-0.6l-4.7-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M787.4,312.8c0.8,0.3,2.8,0.5,3.6,0.5l2.3,0.1 M788,314.3h4.4
	 M789.5,319.8c0.8,0.3,3.4,0.5,4.3,0.5l2.3,0.1 M789.9,321.3h5.2 M795.9,328.6v-0.3 M794.2,326.3c1.4-1.9,1.2-4.3,1.2-5 M789.9,322
	h1.7c0.8-0.1,2.8-0.4,3.9-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M795.4,321.3c0.3,0,0.4-0.1,0.5-0.1c0.8-0.1,0.1-1-0.3-1.4
	c-0.5-0.4-1.2-0.5-1.2-0.5l-1.2-0.1 M793.3,319.1l-4.1-0.5 M791.5,325.8V322 M789.6,318.7c-0.1-1.2-0.7-3-0.8-3.7 M785.8,307.7
	c0.5,1,1.5,3.3,1.9,4.1 M791.1,328.5c1.8,0,3.6,0,4.8-0.1 M795.9,328.3c0.3,0,0.7,0,0.8-0.1c0.8-0.1,0.1-1-0.3-1.4
	c-0.5-0.4-1.2-0.5-1.2-0.5l-1.1-0.1 M794.2,326.3l-3.4-0.5 M792.2,335.3c1.1,0,2.1-0.1,2.5-0.1c0.8-0.1,0.1-1-0.3-1.4
	c-0.5-0.4-1.2-0.6-1.2-0.6l-1.4-0.1 M792.1,334.4h2.8 M792.9,342.4c0.8,0,1.5-0.1,1.9-0.1c0.8-0.1,0.1-1-0.3-1.4
	c-0.5-0.4-1.2-0.5-1.2-0.5l-0.7-0.1 M792.9,341.4h2.2 M793.3,348.3h3.7 M793.3,349.2l3.3,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M795.9,328.6h-0.5c0,0-0.3,0-0.3,0.3c0,0.1,0.4,16.5,0.4,16.5v0.7
	c0,0.3-0.5,1.9-0.5,1.9c-0.3-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.3-0.4-0.3-0.4v-4.7 M793.7,340.4v-5.1 M793.7,333.4v-3.7
	c0-0.6,0.1-1,0.3-1.4 M793.7,356.9c1.1-0.1,2.5-0.4,3-0.5c0.8-0.1,0.1-1-0.3-1.4c-0.5-0.4-1.2-0.5-1.2-0.5l-1.7-0.1 M793.6,355.4
	l3.3,0.1 M793.7,356.5h2.1 M790.7,346.8c-2.2-0.1-5.5-0.4-8.9-0.7c-5.1-0.4-10.7-1-14.4-1.1c-6.2-0.4-18.3-1-19-1
	c-2.1,0-4.3,0.8-4.7,3.3c-0.3,2.2-0.3,12.5,2.1,19.1c1.1,3.4,2.3,4,5.4,4.3c1.1,0.1,6.7,0.4,13.1,0.8c8.8,0.5,18.7,1.1,21.9,1.4
	l1.9,0.1c1.7,0.1,3.2,0.3,4.1,0.3l3.2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M793.1,370.4h-2.5c0,0-3.6-0.1-4.5-0.1c-3.2-0.3-13.5-0.8-22.3-1.4
	c-6.3-0.4-12-0.7-13.1-0.8c-2.3-0.1-2.6-0.3-3.9-1.8 M782.9,347.3c0.1,0.5,0,0.8-0.3,1c-0.3,0.1-0.7,0.3-0.8,0.3
	c-5.1-0.4-12.1-1-15.8-1.2c-6.3-0.4-18.3-1-18.8-1c-0.7,0-2.3,0.1-2.5,1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M795.3,371.9c0,0-1.9,0-3.2-0.1c-1.2,0-3.4-0.3-5.9-0.4
	c-5.5-0.4-32.2-1.9-34.9-2.2c-2.8-0.1-3.4-0.5-4.4-3.4c-2.2-6.3-2.3-16.4-1.9-18.6c0.3-2.2,2.9-2.3,3.6-2.3s12.7,0.7,18.8,1
	c6.2,0.4,18.2,1.5,23.5,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M788.8,362.3c0,0-9.2-0.7-10-0.7c-0.8,0-1.2,0-1.8,0.4
	c-0.5,0.3-4.7,2.5-6.7,3.4c-2.2,1-3,0.7-4.3,0.7c-1.1,0-9.2-0.4-10.7-0.5c-1.5-0.1-1.6-1.5-1.8-2.5c-0.1-1-1.1-11.4-1.1-12.5
	c0-1.1,0.8-1.1,1.7-1.1c4.5,0.3,9.5,0.8,10.5,1c1,0.1,2.1,0.4,3,1.1c1,0.7,4.1,4,4.1,4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M771.7,355.7l2.6,0.5c4.8,0.5,13.3,0.3,13.8,0.3c0.4,0,1-0.1,1-0.5
	c0-0.5-1-0.8-1-0.8s-7.2-1.4-7.4-1.5c-0.3-0.1-0.4-0.4-0.4-0.4l-1.2-4l3.4-0.4 M785.1,369.2l2.1-3.2 M787,366.3v-0.4
	c-1.9,0-3.4-0.5-3.4-0.5c-0.3-0.6-0.8-1.9-0.8-1.9 M779.3,368.9l4.3-3.6 M762.1,368.8c4-0.1,11.1,0,14.4,0.1c3.3,0.1,9.8,0.5,10,0.5
	s0.8,0.1,1.2,0.1c0.4,0.1,2.2,0.4,2.9,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M776,363.4c0,0,11.7,0.1,12.4,0.1c0.7,0,1-0.3,1-0.5c0-0.3-0.4-0.7-1-0.8
	c-0.3,0-1.2-0.3-2.3-0.5 M785.9,361.5c-1.4-0.3-3-0.7-3.3-0.7c-0.4-0.1-0.5-0.4-0.5-0.4l-1-3.7 M782.2,348.5c0.3,0.7,1,3.2,1.8,5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M784.7,356.8c0.5,1.8,1,3.4,1.2,4.7v0.1 M786.5,363.4
	c0.6,1.6,1.2,1.9,2.1,2.1c1.1,0.3,5.5,0.8,6.1,1 M767.3,347.6c1.2,0.3,5.6,1.4,5.6,1.4l5.9,0.4 M758.8,368.6c0,0,4.3-0.1,5.5-0.1
	c1.4,0,2.9-0.6,4.4-1.4s7.6-4.1,8.3-4.5c0.3-0.1,0.4-0.3,0.7-0.3 M777.6,362.3c0.4-0.1,0.8,0,0.8,0l10.2,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M766.8,347.3c6.3,3,10,6.5,10.6,7c0.1,0.1,0.3,0.3,0.5,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M777.8,354.6c0.4,0.1,1,0.3,1,0.3l9.9,0.7 M753.3,362.8
	c0.1,1.6,1.7,2.1,2.5,2.1c0.8,0,8,0.1,8.9,0.1c0.8,0,1.2-0.3,1.5-0.4c0.3-0.1,5.2-3.7,5.5-4c0.5-0.4,1-0.4,1-0.4l9.4,0.4
	 M772.1,360.4l-0.4-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M771.3,365.9c-1.8,1-3.9,1.1-4.4,1.1s-9.9-0.5-11.3-0.7
	c-1.5-0.1-2.5-0.7-2.6-2.8c-0.4-3.7-0.8-10.9-1-12.9c-0.1-2.1,1.4-2.1,2.1-2.1c0.7,0,11.8,0.5,13.1,0.7c1.2,0,2.8,0.6,3.4,1
	c2.8,1.5,5.9,4.1,6.6,4.7c0.6,0.5,1.2,0.7,1.2,0.7l10.5,0.8 M786.7,366.7c1.4,0.4,5.1,1,6.3,1.1 M787.6,367c1.5,0,2.6-0.8,2.6-1
	 M787.4,369.6l-0.1-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M765.4,364.6c0,0-5.2-0.3-5.8-0.4c-0.7-0.1-0.7-0.8-0.7-0.8
	s-0.7-10.9-0.7-11.6c0-0.7,0.5-1.1,1.1-1.1c0.7,0,6.3,0.4,6.3,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.3,352c1.4,0.7,3,2.2,2.9,5.4c-0.3,4.4-3.2,5.9-4.4,6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M762.8,363.7h-0.4c-1-0.1-3.4-0.8-3.3-6.1c0.1-5.2,2.6-6.2,4.3-6.1
	c0.3,0,0.7,0.1,1.1,0.3 M759.2,360.4c2.8-2.6,1.1-5.6,0.5-6.3 M790,367.4v2.5 M790,369.9h1.1 M791.3,367.5v2.3 M791.3,369.9h1
	 M792.4,367.7v2.2 M792.4,369.9h0.5 M781.6,297.9c-0.7,0.7,0,2.3,0.3,2.8c0.3,0.4,0.1,1-0.4,0.7c-7.2-3.3-20.9-6.6-34.9-8.3
	s-29-1-33.6-0.7c-4.3,0.3-3.4,3-1.5,4.7c1.7,1.4,10.7,9.4,14,12.1c3.2,2.6,5.6,4.1,10.2,4.8c4,0.5,17.2,1.4,18.4,1.4
	c1.4,0,2.3-0.1,3.3-0.5s6.9-3,8-3.6s2.2-0.4,3.4-0.3c2.1,0.3,9.4,1,10.5,1c1.1,0.1,1.8-0.5,1.7-3c0-2.3-1.7-7.7-1.7-7.7
	c-0.1-0.7-0.1-0.8-0.4-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M770.2,297.4c0,0.1,0.1,0.4,0.7,0.5c0.5,0.1,5.1,1.6,5.4,1.8
	c0.3,0.1,0.4,0.4,0.6,0.8c1.4,3.7,1.7,8,1.8,8.7c0,0.8-0.3,1.6-1.2,1.5c-1-0.1-8-0.7-10-1c-1.2-0.1-2.6-0.3-4.1,0.4
	c-1.1,0.5-7,3.2-8,3.6c-0.8,0.4-1.8,0.5-2.8,0.4c-1.9-0.1-13.2-1-16.9-1.4c-4.3-0.5-6.5-1.9-9.6-4.5c-1.9-1.5-5.8-5-8.9-7.7
	c-2.3-2.1-4.3-3.7-5-4.3c-0.8-0.7-1.2-1.2-1-1.9c0.1-0.4,0.5-0.7,1.9-0.8c4.3-0.3,19.3-1,33.3,0.5c11.4,1.4,23,3.9,30.5,6.5
	 M699.6,318.5c10.9,0.1,31.5,1.5,32.7,1.5s1.2-0.7,1-1.6c-0.3-0.8-2.5-5.6-2.5-5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M736,311c0,0,1.5-1.2,3-2.3c1.5-1.1,3.6-1.2,5.5-1.2
	c8.8,0.4,29.4,2.3,31.1,2.5c1.7,0.1,1.5-0.7,1.5-1.4c-0.4-3.6-1-5.9-1.1-6.6c-0.1-0.7-0.5-1.6-1.5-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M736.5,311c0,0,1.8-1.4,2.8-1.9c1-0.7,2.3-0.8,4.4-0.7
	c1.1,0,10.9,0.7,12.2,0.8c1.4,0.1,1.8,0,2.6-0.1c1-0.1,2.3-0.3,3.4-0.3c-0.4,0.1-0.8,0.3-1.2,0.6c-1.5,0.7-5.4,2.6-6.7,3
	c-1.4,0.5-2.2,0.5-4,0.5c-1.8,0-7.4-0.5-10-0.7c-2.6-0.3-7.7-0.4-9.4-1c-0.5-0.1-1.4-0.4-2.3-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M726.8,308.8c1,0.7,2.6,1.2,3.6,1.4c0.8,0.1,2.1,0.1,3,0.1
	c0,0,0.8,0.3,1.4,0.4c0.4,0.1,1,0.3,1,0.3c2.3,0.1,12.8,0.7,13.9,0.7c1.1,0,1.9-0.1,2.6-0.4c0.7-0.3,4.1-1.5,5.4-2.1 M711.1,294.9
	c5,0,28.2-0.3,41.4,1.6c13.2,1.9,19.5,3.9,23.5,5.1 M733.2,310.3c1-0.8,2.8-2.3,3.6-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M740.1,306.7c1.1,0,8.4,0.3,14.3,0.7c5.9,0.4,18.8,1.6,20.1,1.6
	c1.2,0.1,1.1-1,1-1.6c-0.3-2.5-0.8-5.1-0.8-5.5c-0.1-0.4-0.3-1-0.8-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M729.8,310c1-0.8,3.9-3.3,4.7-3.7c0.8-0.5,2.2-0.8,3.2-0.7
	c1.1,0,10.6,0.5,16.4,1c5.9,0.4,18,1.5,19.3,1.6c0.8,0,1-0.3,1-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M730.9,310.2c0,0,1.1-1,2.6-2.2c1.6-1.2,4-1.4,6.6-1.2 M711.2,295.2
	c2.1,0,20.8-0.5,37.7,1.5c10.5,1.2,18.7,3.6,19.3,3.7c0.4,0.1,1.1,0.1,1.1,0.1h3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M728.3,309.6c0,0,4.5-4.4,4.8-4.5c0.3-0.3,0-0.4-0.3-0.5
	c-2.1-1-15.8-8-18.3-9.4 M766,299.8v0.8c0,0.3,0.3,0.1,0.3,0.1h5.1c0,0,1.4,0.1,1.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M716.7,295.2c0,0,15.3,7.7,16.8,8.3s2.5,1,4.1,1
	c1.7,0.1,10.6,0.7,16.1,1.1c5.5,0.4,17.6,1.8,18.4,1.8c0.8,0,1.2-0.3,1.1-1.6c-0.1-1.4-0.4-3-0.8-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M771.6,301.1c0.3,1.1,0.7,3.4,0.7,4.4c0,0.4,0,0.7-0.7,0.7
	c-0.8,0-12-1.4-17.5-1.9c-6.2-0.5-14.4-1.2-16.1-1.4c-1.7-0.1-2.6-0.4-4.1-1c-1.5-0.5-13.9-6.7-13.9-6.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M765.8,305.5c0.5,0,0.5-0.4,0.5-1v-3.6 M744.9,311.4
	c0.3-0.1,4.5-1.4,5.4-1.6c0.8-0.3,1.4-0.7,1.4-1 M763.1,305.1v-3.2l1-1.5h1.9 M759.2,304.7l3.3-2.2h0.6 M726.5,298.3
	c0.8-1.2,1.9-2.5,2.5-3 M728.4,299.3c0.3-1.8,1.8-3.6,2.2-3.9 M728.7,295.6c0.7,0.3,1.1,0.5,1.1,0.5 M737.9,302.9
	c1.2-2.5,1.1-6.2,0.7-7 M739.8,303c3-3.4,1.8-6.5,1.2-7 M741.5,303.1c0.8-3.3,4-5.9,4.7-6.7 M744.6,303.3c0.8-3.3,3.2-5.9,4-6.6
	 M745.7,303.4c0.8-2.9,2.9-5.1,3.9-6.1c0.1-0.1,0.4-0.1,0.5-0.1c1.5,0.1,2.9,0.3,3.4,0.4c0.4,0,0.4,0.3,0.5,0.3v0.3
	c-1.1,1-3,3-4,5.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M750.4,303.8c1-2.5,3.2-4.4,4.1-5.4c0.1-0.1,0.4-0.1,0.5-0.1h2.1
	c0.4,0,0.4,0.3,0.5,0.3v0.3c-1.1,1-2.9,2.8-3.9,5.2 M748.5,297.8h0.7 M753.4,298.7h1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M758.8,304.7c0.4-1.4,1.1-1.9,1.4-2.2c0.1-0.3,1.1-1.5,1.4-1.9
	c0.3-0.3-0.1-0.4-0.1-0.4l-4.1-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755.2,304.2c1.1-1,2.2-1.2,3-1.5c1.2-0.4,1.4-0.7,1.4-0.7l1.5-1.6
	 M754.3,304.1c0.6-1,1.5-1.4,2.2-1.6c0.7-0.4,2.1-1,2.3-1.2c0.3-0.3,0-0.3,0-0.3l-2.6-0.5 M743.8,311.4c0-1.2-0.4-2.6-0.5-3
	 M56.8,268.5l0.7-1.4 M45.8,269.2c2.5-0.4,6.3-0.5,11-0.7 M56.8,268.3h0.7 M57.5,268.5c5,0,10.7,0,16.8,0.3c0-1.2-0.7-1.4-1.1-1.4
	c-5.9-0.3-10.9-0.4-15-0.4 M58.2,267.1h-0.7 M57.5,267.1c-4.8,0.1-8.4,0.4-11.1,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M47.3,267.6c1-1.5,1.9-1.8,2.2-1.9h0.1c3.3-0.8,8-0.8,11.1-0.7
	c11.3,0.5,17.7,2.1,20.8,2.8l0.4,0.1c1,0.3,1.7,0.5,1.9,1.1c0.1,0.1,0.1,0.4,0.1,0.7c0,0.3-0.1,0.7-0.3,1.1c-0.4,1-2.6,8.4-4.7,16.5
	c-9.6-0.1-19.5-0.4-25.9-1.6c0,0-1.8-0.4-2.1-0.4c-3.7-1.8-6.5-3.3-6.9-3.6c-0.1-0.1-0.3-0.4-0.3-0.5v-1.9v-0.7l0.1-0.7
	c0.1-1.1,1.2-6.1,2.2-8.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M48.8,269.3c0,0-0.4,2.8-0.4,3.2c0,0.4,0.5,0.7,1,0.8
	c0.4,0.1,1.1,0.1,1.1,0.1s13.8,0.3,15.3,0.3c1.2,0,2.2-0.8,2.6-1.1c0.4-0.4,3.3-3.4,3.3-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M49.1,273.2c3.3,0.1,12.9,0.3,14.2,0.3c1.2,0,2.2-0.8,2.6-1.1l3.2-3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M48.6,272.3c3.3,0.1,13.3,0.3,14.6,0.3s2.2-0.8,2.6-1.2l2.5-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M48.7,271h14.2c0.4,0,0.7-0.3,0.8-0.4c0.1-0.3,0.7-1,1-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M60.7,270.8c0.4,0,0.7-0.3,0.8-0.4c0.1-0.3,0.7-1,1-1.5 M56.3,270.8
	l1.9-3.9 M57.2,270.8l1.1-2.3 M46.5,269.6c2.5-0.4,6.2-0.5,10.7-0.7 M58.2,268.9c7.3-0.1,16.5,0.1,25.9,0.8 M57,273.3l0.4-0.7
	 M56.3,273.3l0.4-0.8 M55.8,271l-0.8,1.5 M54.9,270.8l-0.7,1.6 M53.9,271l-0.7,1.5 M52.8,271l-0.5,1.5 M51.9,271l-0.7,1.4 M50.9,271
	l-0.7,1.4 M49.8,271l-0.5,1.4 M57,271l-0.8,1.5 M58.3,270.8l-1,1.6 M59.2,270.8l-1,1.6 M60.5,270.8l-1.1,1.8 M61.9,270.8l-1.2,1.8
	 M61.6,270.8l-1.2,1.8 M62.7,270.8l-1.2,1.8 M66,268.9l-1.5,1.8 M65.2,268.9l-1.5,1.8 M64.4,270.8l-1.5,1.8 M63.6,270.8l-1.5,1.8
	 M66.9,268.9l-1.7,1.8 M65.1,270.8l-1.7,1.8 M63.6,270.7h0.1 M64,270.7h1.2 M65.3,270.7h1.1 M63.3,270.8h0.3 M63.8,270.8h1.2
	 M65.2,270.8h1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M46.9,269.4c-1,2.6-1.9,7.2-2.1,8.1l-0.1,0.7v2.5c0,0.1,0,0.1,0.1,0.3
	c0.5,0.4,3.3,1.9,6.9,3.6c0.3,0.1,1.9,0.4,1.9,0.4c6.2,1.2,16.2,1.4,25.3,1.5c1.9-8.1,4.3-15.3,4.5-16.2c0.1-0.3,0.1-0.5,0.1-0.7
	 M53.1,285l1.9-4.4 M54.2,285.1l1.8-4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M65.6,285.5c-0.1-0.8-0.4-2.1-0.6-2.9c-0.1-0.8-1-1.4-1.9-1.4
	c-4.7-0.3-13.1-1.1-17.5-1.9c-0.1,0-0.4,0-0.4,0.1v1.2c0,0,0,0.1,0.1,0.3c0.5,0.4,3,1.8,6.2,3.3c0.4,0.1,0.8,0.3,0.8,0.3
	c0.3,0,0.7,0.1,1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M46.9,269.4c-1.5,5.1-1.5,8.4-1.7,8.7c0,0.3,0.3,0.4,0.4,0.5
	c4.3,1,12.7,1.8,17.5,2.1c1,0,1.8,0.5,1.9,1.4c0.1,0.8,0.6,2.6,0.7,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M81.7,269.6c-0.3,0.8-2.9,8-4.8,16.2c-7.7-0.1-16.2-0.3-22.4-1.1l1.4-0.3
	c2.9,0.4,6.3,0.7,9.9,0.8 M56.1,280.7l-0.4,3.7 M46.2,279.3v0.7c0,0.1,0.1,0.1,0.1,0.3c0.5,0.4,3,1.8,6.2,3.3
	c0.4,0.1,0.8,0.3,0.8,0.3h0.3 M47.3,269.4c-0.4,1.8-1.1,5.6-1.1,6.1c0,0.4,0.4,0.4,0.8,0.5c4.4,0.4,15.8,0.5,17.5,0.5
	c1.7,0,2.3-1,2.9-1.5s5.5-5.9,5.5-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M48.1,269.3c-0.4,1.9-1,4.8-1,5.2s0.4,0.4,0.8,0.5
	c4.3,0.4,15,0.5,16.5,0.4c1.7,0,2.3-1,2.9-1.5c0.4-0.4,3.2-3.3,4.7-5 M45.4,277.3c0,0.4,0.3,0.4,0.5,0.5c6.3,1,19.8,1.5,20.8,1.5
	c1.1,0,1.8,0.1,2.3,0.3c0.6,0.1,1.2,0.3,2.1,0.3s5.5,0.1,7.2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M47.2,269.4c-0.8,3.4-1.2,5.8-1.2,6.9c0,0.4,0.3,0.4,0.5,0.5
	c6.5,0.8,19.4,1.2,20.4,1.2c0.7,0,1.5,0,2.3-0.1c0.7,0,1.7-0.1,2.2,0c0.4,0,6.6,0.3,7.7,0.3 M68.1,278.1l6.9-8.9 M70.2,278l6.5-8.7
	 M70.7,278l6.6-8.7 M68,285.5l-0.3-6.2 M69.6,285.7v-6.1 M70.2,285.7v-5.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M52,286.2c0,0-0.1-0.1-0.1-0.5c0-0.3-0.1-4.7-0.1-5s0-1.4,0.1-1.8
	c0.1-0.5,4.8-11.1,5.1-11.6c0.3-0.4,0.7-0.8,1.1-1.1c0.4-0.3,2.8-1.6,2.8-1.6 M167.4,248.9c15.4-9.8,39.5-23,67.1-34.1
	c15.3-6.2,31.2-11.3,47.7-14.3c11.4-2.1,25.3-4,40.3-5.1c13.1-1,27.2-1.4,41-1.4c34.5,0,69.2,3.9,90.9,10.3
	c17.9,5.2,35.9,14.4,51.7,23.8c18.8,11.3,34.5,22.8,47.6,33.3c0,0,0.3,0.1,0.1,0.1c0,0,0.4,0.1,0.7,0.4c0.3,0.3,0.5,0.7,1,1.1
	c0.4,0.4,0.3,0.7,0,0.5c-31.9-1.9-155.2-6.2-208.5-7.3c-42.2-0.8-112.7-2.2-179.7-1.9C161.6,254.3,164.3,250.6,167.4,248.9
	L167.4,248.9z M517.4,259.8c14.4,0.5,28.6,1.2,36.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.7,259.1c-50.1-2.1-117.6-4.3-153.7-5c-42-0.8-111-1.9-177.6-1.6
	c-1.1,0-1.8-0.7-1.6-1.4c0-0.8,0.7-1.5,1.4-1.9c15.8-9.4,38.8-22.6,65.2-33.3c15.3-6.2,31.2-11.3,47.7-14.3
	c11.6-2.1,25.6-4.1,40.6-5.2c13.1-1,27.4-1.4,41.1-1.5c34.5,0,68.9,4.1,90.7,10.6c17.9,5.2,35.6,14.6,51.4,23.9
	c17.9,10.6,33,21.9,45.7,31.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M243.2,252.1c8.1-16.4,23.1-42.8,23.1-42.8s0.7-0.6,1-0.7
	c5-1.2,10.2-2.5,15.3-3.4c7.3-1.4,15.4-2.8,24.6-3.9c18-2.1,38.9-3.2,58.6-3.2c34.5,0,67.1,4.7,88.9,11
	c17.9,5.2,35.4,14.9,51.2,24.2c2.3,1.4,4.7,3,6.9,4.4 M519.4,242.5c9.1,6.1,17.9,12.7,25.3,18.6 M238.3,252.1
	c0,0,6.5-12.5,13.1-24.6c6.6-12.1,12.1-21.5,12.1-21.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M499.6,258.4c-49.9-1.9-116.8-4-152.4-4.7c-27.9-0.6-68-1.2-111.3-1.5
	c0,0-54.2-0.3-57.1-0.3c-2.8,0-4-1.5-0.3-4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M255.3,212.5c-0.1,0.3-4.5,7.2-9.4,15.7c-6.1,10.9-12.8,23.9-12.8,23.9
	 M222.4,228.3c-16,7.3-28.5,13.8-31.1,15.5c-3.9,2.8-1.6,4.8,0.7,4.7h37.6c0.6,0,1-0.3,1.4-1.1c0.4-0.8,15.4-27.5,16-28.5
	c0.6-1,0-1.4-1.1-1C238.1,221.4,229.7,225,222.4,228.3L222.4,228.3z M507.5,237.5c0,0-0.5-1.5-0.7-2.3c-0.1-0.4,0.3-0.7,0.7-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M514.5,260.2c2.6-0.3,3.6-0.7,5.1-1.1c2.8-0.8,4.8-2.3,5.9-3.2
	c1.1-0.8,1.8-1.8,2.2-2.1c0.3-0.4,0.4-1.1,0.5-1.5c0.1-0.7-0.3-1.1-0.7-1.5c-0.4-0.4-5.1-5.6-7.6-8c-2.6-2.5-7-5-8.8-5.2
	c0,0-2.5-0.3-5.1,0c-2.5,0.3-8.4,2.2-8.3,11.7c0,1.4,0.1,4.3,0.5,6.2c0.7,4.4,5,4.4,9.8,4.8C509,260.5,512.6,260.5,514.5,260.2
	L514.5,260.2z M507.6,237.5c-2.5,0.3-7.8,2.2-8.1,10.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M499.2,253.2c0.1,0.8,0.1,1.6,0.3,2.3c0.7,3.9,4,4.4,8.1,4.8 M497.8,251.7
	c11.6-0.4,17.5,0.1,20.5,0.4s8.7,1.5,8.7,1.5 M497.8,249.4c11.6-0.4,17.5,0.1,20.5,0.4c3,0.3,8.7,1.5,8.7,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M523,252.9c0,0,0.1,0.1,0,0.3s-0.7,0.6-1,0.8c-0.3,0.1-0.4,0.1-0.7,0
	c-1.1-0.1-2.1-0.3-2.9-0.4c-3-0.3-8.8-0.8-20.4-0.4 M257.5,252.2l-3-21.6 M524.1,259.8c1.4-0.1,2.2-0.7,3-1.8c0.8-1,1.9-2.5,2.3-3
	c0.7-1.1,0.5-1.8,0.5-2.3s-0.4-1.1-1.1-1.4c-0.8-0.6-1.7-0.8-1.7-0.8 M395.5,254.7l-21-56 M366.9,254l-9.5-55.4 M384.6,210
	c0,0,2.3-3.7,9.5-3.3c7.3,0.4,35.6,2.1,57.8,8.7c7.7,2.3,15.7,5.2,22.7,8.4c12.7,5.6,22.8,11.6,28.2,14.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M504.7,237.8c0,0-15.1-8.9-30.7-16.2c-10.9-5.1-21.3-8.7-29.6-10.7
	c-20.1-5.1-41.1-6.3-50.1-6.7c-3.6-0.1-7.7,1.2-9.4,4.7c-1.2,2.5-0.8,6.2,1.5,11.6c1.4,3,12.8,34.5,12.8,34.5 M261.4,252
	c0,0-2.5-16.2-2.6-17.5c-0.1-1,0.1-2.2,0.5-3.3c0.4-1.1,4.3-8.3,6.7-12.4c3-5.1,5.9-7.2,13.1-8.5c7.2-1.4,46.8-6.6,57.5-6.7
	c10.7-0.3,17.6,3.9,19.7,13.8c2.1,9.9,6.2,36.3,6.2,36.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M258.9,234.9c0,0,7.6-13.5,9.4-15.8c1.8-2.5,5.6-5.2,11.6-6.5
	c3.2-0.7,16.4-2.5,29.3-3.9c11-1.2,21.9-2.2,27.6-2.6c11.7-0.6,18,4.7,19.5,10.7 M262.6,209.9c0,0,1.8-0.7,4.8-1.4 M261,210.3
	c0,0-0.5,0-1,0.1c-0.1,0.1-14.4,4.5-25.3,9.2c-23.5,10-33.7,15.8-43.7,21.2c-10.3,5.5-19.4,11.4-19.4,11.4c-1.6,0.1-3.3-0.1-3.2-1
	c9.2-5.8,21.3-12.5,33.6-19c10.5-5.4,20.5-9.8,27.8-13.1c16.1-7.2,30.4-11.3,31.5-11.6c1.1-0.3,1.4-0.1,1.4-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M239.6,252.2c0,0,7-14.2,12.2-23.8c6.7-12.4,12.1-21,12.2-21.3
	c0.3-0.4,0.5-0.4,0.8-0.6c0,0,19.8-5.2,41.5-7.7c21.7-2.5,45.5-2.9,58.1-3 M549.2,261c0,0-18.2-14.7-39.9-28.3
	c-14.6-9.1-31.9-17.5-40.4-21c-21.3-8.9-47.5-12.8-67.4-14.2c-19.9-1.4-28.9-1.5-35.6-1.5 M652.9,367.1v-9.2 M652.9,375v-4.7
	 M198.8,367.1v-9.2 M198.8,375v-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M66.2,367.1c0.4,0.1,0.8,0.3,1.4,0.4
	c1.4,0.3,9.6,1.6,12.5,1.9c5.4,0.7,12,1.4,18.6,2.2c2.8,0.4,6.5,1,10.6,1.5 M262.5,380.9c0.1,0.4,0.3,0.7,0.3,0.7s0.3,0.6,0.5,1.2
	c0.4,0.7,0.8,1.2,1.4,1.2c30.5-0.1,322.3,2.9,323.3,2.9c0.5,0,1-0.1,1.2-0.3 M716.2,383.8h1.5c0.1,0,1.1-0.1,1.1-0.1
	c6.9-0.7,16.8-1.2,27.9-2.3c0.5,0,1.1-0.1,1.8-0.1 M770.1,379.2c1-0.1,1.7-0.1,2.1-0.3c5.1-0.6,9.5-1,13.3-1.7
	c2.9-0.5,11.8-2.6,12.1-2.8c0.4-0.1,0.8-0.3,0.8-0.7c-0.1-0.4-2.2-1.2-3-1.5c0.4-0.8-1.4-1.6-2.2-1.9v-2.2c0,0,1.1-0.6,1.4-0.6
	c0.1-0.1,0.8-0.5,0.1-0.7c0.7,0.1,0.8-0.3,0.8-0.3s0.3-0.5,0.4-1c0.1-0.6-0.3-0.6-0.3-0.6 M584.2,257.7c10.2-1,18.2-1.8,18.2-1.8
	s1.1,0.1,3.3,0.3c22.6,1.6,44.7,3,68.4,6.1c19.4,2.5,36.6,5.5,50.8,8.7c32.6,7.2,50.8,18,57.9,25.2c0.4,0.4,0.3,0.7-0.1,0.8
	c-0.3,0.3-0.3,0.3-1,0.8 M117.2,242.5c53.9-25.3,80.3-34.7,105.8-42.8 M43.7,281.8c0,0-0.1,0.1-0.4,0.1c-0.4-0.1-3.2-0.5-3.6-0.7
	c-0.5-0.1-0.3-0.1-0.4-0.5c0-0.4,0.1-1.8,0.1-2.2s0-0.7,0.1-1.2c0-0.3,0.3-1.1,0.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M44.3,264.6c1.8-3.4,2.9-5.6,3.6-7
	c0.7-1.6,0.1-6.2,0.1-6.6s-0.1-1,0.3-1.4c0.3-0.6,0.7-1.1,1-1.2c0.1-0.3,0.4-0.4,1.1-0.4c0.4,0,4.1,0,8-0.3c3.2-0.1,6.5-0.6,8.8-0.8
	c5-0.6,36.5-4.1,45.8-5.1c0.8-0.1,1.9,0.1,2.5,0.3c0.1,0,1,0.1,2.1,0.4 M42.5,312.2c-0.3,0-0.3-0.3,0-0.7s1.4-2.9,2.5-8.8
	c1.1-5.9,2.9-13.3,4.1-17.7 M49,285c-3-1.5-5-2.6-5.1-2.8c-0.1-0.1-0.3-0.3-0.3-0.4 M584.1,257.7c-19-11.7-64.4-40-109-57.5
	c-0.4-0.1-0.1-0.6-0.5-0.8c-19-6.1-46.2-12.4-106.2-14c-34.7-1-71.9,0.7-104.3,5.1 M250.8,192.5c-10.9,1.9-19.5,4.1-27.2,6.3
	c-0.7,0.1-0.4,0.6-0.4,0.6v0.1 M39.9,275.2l-0.1-0.8c0,0,0.1-2.3,1.4-5.2c1.2-2.9,2.2-4.1,2.2-4.1l0.8-0.1 M793.2,319.1v-0.5
	c0-0.1,0.1-0.3,0.3-0.3c0,0,1.4-0.3,1.5-0.4c0.3-0.1,0.3-0.3,0.1-0.5l0.8-0.6c0,0,0.3-0.1,0.1-0.5c-0.1-0.3-1.2-3.9-1.9-6.6
	c-0.7-2.8-1.7-5.8-1.8-6.2s-1.4-1-1.4-1s0-0.1-0.3-0.1s-4.5-0.7-8.1-1 M793.5,349.9c0,0,3.3-0.5,3.7-0.7c0.4-0.1,0.1-0.7,0-0.8
	c-0.1-0.1,0-0.6,0-0.6s0.3-1.1,0.4-1.4c0.1-0.3,0.1-0.7,0.1-1c0-0.5-0.4-16-0.4-16s0.3-0.1,0.3-0.4c0-0.3-0.1-0.4-0.5-0.4h-1.1
	 M245.1,380.9c-8.4,17.1-26,28.9-46.4,29c-20.6,0.1-38.5-12-46.9-29.3 M696.6,386.2h19.5v-2.5 M587.9,386.9l21.6-0.3 M245.3,380.7
	h17.1 M109.6,373.4c6.5,0.8,26.4,6.1,26.4,6.1l15.7,1 M263.8,190.5c0,0-0.4-0.3-0.5-0.8c-0.3-0.6-1.2-1.1-1.9-1.1
	c-0.8-0.1-1.6-0.1-2.6-0.1c-0.8-0.1-3.6-2.3-4.8-3.7c-1.2-1.4-2.3-1.2-3.2-1.4c-0.8-0.1-1.1,0.4-0.8,1c0.3,0.6,1.1,2.5,1.2,3.2
	c0.3,0.7,0.7,1.6,0.6,2.3l-0.6,2.8 M43.2,313l-4.5,0.5c-2.1,2.1-5.5,3.3-6.5,3.7c-1,0.4-1.2,0.8-1.4,1.2c-0.3,1.1-1.4,5.4-1.4,10.5
	c0,5.2,0.7,9.5,0.8,10c0.1,0.6,0.3,1,0,1.6c-0.3,0.8-0.8,3.2-0.8,3.9c0,0.7,0.5,1.2,1.1,1.2 M31.1,346.1c-0.3,0.5-0.4,1.1-1,3.2
	c-0.5,2.2,0.1,2.8,0.7,3.9c0.7,1.1,2.3,3.3,3.2,4.4c1,1.1,0.1,2.3-0.4,2.9c-0.5,0.5-0.3,1.2-0.3,1.2l0.5,1.1
	c0.3,0.4,0.7,0.7,1.4,0.8c0.7,0.1,9.9,2.1,14.4,2.8c4.4,0.7,14.4,1.6,15,1.8c0.6,0.1,0.6-0.4,0.6-0.4v-1 M696.5,386.1
	c-9.2,14.2-25.2,23.5-43.3,23.7c-18.2,0.1-34.1-9.1-43.5-23.2 M748.6,381.3c0,0,0.1,1.1,0.1,1.4s0.3,0.4,0.5,0.4
	c0.3,0,19.7-0.3,19.9-0.3c0.4,0,0.3-0.3,0.3-0.3v-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M781.6,297.9c0.7-0.5,1.2-0.1,1.2-0.1
	s0.5,0.5,1.1,1c0.4,0.4,0,0.8,0,0.8s-0.8,0.5-1.1,1c-0.4,0.3-0.1,1,0,1.2 M793.3,349.9c0.1,1.8,0.1,3.3,0.1,4.5 M793.6,356.7
	c0.1,2.3,0.1,3.9,0.1,4.8c0,0.5,0.7,0.8,1,1c0.8,0.3,1.5,0.3,1.5,0.3s0.5,0.1,0.3,0.5c-0.1,0.5-0.4,1-0.4,1s-0.3,0.4-0.5,0.3
	 M794.2,326.3c1.4-1.9,1.2-4.3,1.2-5 M795.4,321.3c0.3,0,0.4-0.1,0.5-0.1c0.8-0.1,0.1-1-0.3-1.4c-0.5-0.4-1.2-0.5-1.2-0.5l-1.2-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="3.864"
            d="M795.9,328.3c0.3,0,0.7,0,0.8-0.1
	c0.8-0.1,0.1-1-0.3-1.4c-0.5-0.4-1.2-0.5-1.2-0.5l-1.1-0.1 M793.7,356.9c1.1-0.1,2.5-0.4,3-0.5c0.8-0.1,0.1-1-0.3-1.4
	c-0.5-0.4-1.2-0.5-1.2-0.5l-1.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M518.3,259.7l5.8,0.4c0,0,1.5-0.4,2.2-1.2c0.7-0.7,3.3-3.7,3.6-4.8
	c0.3-1.1,0.4-2.1-0.4-2.5c-0.8-0.6-2.6-1.4-2.6-1.4s-8.9-10.9-15.7-12.7c0,0-4.5-0.7-7.8,0.7c-3.2,1.4-5.5,5.1-5.6,8.3
	s0,7.8,0.7,10.3c0.7,2.5,4.7,3.6,9.2,3.7C507.7,260.5,513.1,260.8,518.3,259.7L518.3,259.7z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M499.6,258.4l-100-3.4l-13.3-35.6
		c0,0-4.3-8.5,0.4-12.8c0,0,2.2-2.2,6.3-2.6c0,0,29.3,0.6,56.4,8.1c0,0,20.4,5.1,55.4,25.6c0,0-5.2,0.7-6.7,7.7c0,0-0.3,2.2-0.1,6.6
		C497.8,252.1,498.1,257.5,499.6,258.4z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M362.6,254l-101.1-1.6l-2.6-16.5
		c0,0-0.5-2.8,0.7-5.4c1.4-2.5,7.3-13.6,9.4-15.4c2.1-1.8,4.7-4.1,13.9-5.4c0,0,39.2-5.5,52.8-6.1c0,0,16.8-2.2,20.4,12.4
		C356,215.9,359,230.9,362.6,254z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M231.4,247l15.7-27.9c0,0,0.8-1.7-1.1-1
		c-1.8,0.8-37.4,15.7-52.8,24.8c0,0-4.3,2.1-3.9,4.4c0,0,0.8,1.2,1.8,1.4l38.5,0.1C229.7,248.7,230.7,248.7,231.4,247z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M271.3,342.1l-7-1h-5.8
		c0,0-12.9-42.4-60.1-42.4c0,0-27.8-1.4-47.6,23l-6.1-1c0,0-56.4-5.6-60.5-7c-4.1-1.4-8-1.9-6.9-9.9c1.1-8.1,1.2-13.2,7.4-33
		c0,0,1.1-2.9-1.1-3.4l1.4-0.3c0,0,42.5-3.2,144-3.2c0,0-1.7,3.9,8.4,21.3c0,0,30.1,50.9,33.8,56.4l0.4,0.7L271.3,342.1z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M556.2,263.5c0,0-151.3-6.5-174.7-6.5
		c0,0,4.4,6.3,4.8,8.7c0,0,0.3,3.2,1.9,8.9c0,0,4.4,13.1,4.5,39.8c0,0,0.3,25.7-0.5,32.9l-2.5,20.6c0,0,95.7,2.1,172.8,3l2.3-16.1
		c0,0,4.7-45-3.4-83C561.4,271.9,560.7,268.8,556.2,263.5z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M381.5,257.1c0,0-114.9-2.8-147.1-2.2
		c0,0-4.5,5.6-5.4,9.1c0,0-1.2,2.8,3.2,11.3c0,0,15.7,28.6,39.5,67c0,0,7,13.5,16.6,18.6c0,0,6.5,4.4,22.8,5.1
		c0,0,56.7,1.6,78.5,2.1l2.5-20.6c0,0,3.3-52.5-4.8-75.2c0,0-1.2-5.2-1.2-6.3C386.1,265.7,386.4,264.4,381.5,257.1z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              cx="198.8"
              cy="357.9"
              r="52"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              cx="652.9"
              cy="358"
              r="52"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M699.6,318.5c0,0,16.1,0.4,32.5,1.5
		c0,0,2.1,0.3,1.4-1.6c-0.8-1.9-2.6-5.9-2.6-5.9s-2.6-1.2-4.7-3c-2.1-1.6-14.9-12.9-14.9-12.9s-1.9-1.8-1-3c0,0,0.7-1,2.6-1.1
		c1.9-0.1,8.9-0.7,22.3-0.4c0,0-34.9-16.6-174-21c0,0,8,27.4,3.9,81.3l-0.3,2.9l-2.2,15.7l27.2,0.6c0,0-1.2-28.9,12.4-47.5
		c13.6-18.7,34-25.9,50.8-25.7C669.5,298.3,684,302.7,699.6,318.5z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              d="M524.1,260.1l-6.5-0.4c0,0-1.9,0.8-8.1,0.9
		c0,0-6.9,0-9.6-2c0,0-1.8-0.7-2-5.3V247c0,0-0.1-4.3,3-7.1c0,0,2.5-3.2,10.2-2.4c0,0,3.9,0.9,8,4.9c0,0,3.5,2.7,7.7,7.8l2.5,1.3
		c0,0,0.9,0.2,0.7,1.9c0,0-0.1,0.9-0.7,1.8l-2.7,3.5c0,0-1.3,1.1-2.2,1.2L524.1,260.1z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Sedan4DrRight);
