import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { CookiesProvider, withCookies } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { ToastContainer } from 'react-toastify';
import config from '../config/index.js';
import configureStore from '../store';
import Router from '../router';
import { CYAN_01 } from '../components/core/constants/colors.js';
import Icon from '../components/core/components/ui/icons';

// TODO - choose one toast library:
// marketplace is using react-redux-toastr
// core is using react-toastify

const Root = props => {
  const [isHydrationComplete, setIsHydrationComplete] = useState(undefined);
  const store = configureStore();
  const history = syncHistoryWithStore(createBrowserHistory(), store);
  const persistor = persistStore(store, {}, () => {
    setIsHydrationComplete(true);
  });

  useEffect(() => {
    let conf;
    if (process.env.NODE_ENV === 'development') {
      // dev builds get the config directly from code
      conf = config.localhost || config.default;
      props.cookies.set('config', conf, { path: '/' });
    } else {
      // production builds get the config through server.js
      conf = props.cookies.get('config');
    }

    if (conf && conf.marketplaceId !== '0') {
      localStorage.setItem('config', JSON.stringify(conf));
      localStorage.setItem('marketplaceId', conf.marketplaceId);
    } else {
      window.history.replaceState(null, '', '/default');
    }
  }, [props.cookies]);

  if (!isHydrationComplete || !persistor) {
    return null;
  }

  return (
    <div>
      <ToastContainer
        limit={1}
        hideProgressBar
        draggable={false}
        position="top-center"
        autoClose={5000}
        closeOnClick
        newestOnTop
        style={{ width: 600 }}
        closeButton={
          <div
            style={{
              border: 'solid 1px #DFDFE6',
              borderRadius: '50%',
              height: 22,
              width: 22,
            }}
            className="flex items-center"
          >
            <Icon name="Cross" color={CYAN_01} />
          </div>
        }
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <BrowserRouter history={history} {...props}>
              <ReduxToastr
                timeOut={2000}
                newestOnTop={true}
                position="top-right"
              />
              <Router history={history} cookies={props.cookies} />
            </BrowserRouter>
          </CookiesProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

Root.propTypes = {
  allCookies: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
};

export default withCookies(Root);
