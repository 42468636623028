import React, { Component } from 'react';

class InformationIcon extends Component {
  render() {
    const { size = 20, color = '#98989E' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10Z" />
        <path d="M10 8C10.2652 8 10.5196 8.10536 10.7071 8.29289C10.8946 8.48043 11 8.73478 11 9V15C11 15.2652 10.8946 15.5196 10.7071 15.7071C10.5196 15.8946 10.2652 16 10 16C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V9C9 8.73478 9.10536 8.48043 9.29289 8.29289C9.48043 8.10536 9.73478 8 10 8ZM11.5 5.5C11.5 5.89782 11.342 6.27936 11.0607 6.56066C10.7794 6.84196 10.3978 7 10 7C9.60218 7 9.22064 6.84196 8.93934 6.56066C8.65804 6.27936 8.5 5.89782 8.5 5.5C8.5 5.10218 8.65804 4.72064 8.93934 4.43934C9.22064 4.15804 9.60218 4 10 4C10.3978 4 10.7794 4.15804 11.0607 4.43934C11.342 4.72064 11.5 5.10218 11.5 5.5Z" />
      </svg>
    );
  }
}

export default InformationIcon;
