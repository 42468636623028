const styles = {
  container: {
    minHeight: '100vh',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    textAlign: 'center',
    padding: 20,
    fontSize: 14,
  },
  buttonGroup: {
    // // height: 110, // only 1 button so no need
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // margin: 0,
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
  },
  button: {
    // backgroundColor: 'gray',
    // border: 'none',
    // borderRadius: 2,
    // boxShadow: 'none',
    // color: 'white',
    // margin: 2,
    // padding: 2,
    // width: '100%',
    // height: 24,
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: 'gray',
    border: 'none',
    borderRadius: 2,
    boxShadow: 'none',
    color: 'white',
    margin: 2,
    padding: 2,
    width: '100%',
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `sans-serif, 'Open Sans', helvetica`,
    fontSize: '1em',
    fontWeight: 'normal',
  },
};

export default styles;
