import React, { Component } from 'react';
import Button from '../../ui/buttons';
import Icon from '../../ui/icons';
import { CYAN_01 } from '../../../constants/colors';
import SaleInventoryModal from '../../modals/SaleInventoryModal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, findIndex } from 'lodash';
import {
  loadVin,
  updateVin,
  updateVariant,
  updateItem,
  loadExistingData,
  loadAccountList,
  loadEventList,
} from '../../../../../actions';
import VinVerificationModal from '../../modals/VinVerificationModal';

// NOT USING
class Vin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vin: '',
      gatepass: false,
      pickup: false,
      openSalesInventoryModal: false,
      openVerificationModal: false,
      update: false,
      showValidationError: false,
      isLoadingVin: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.vin.decodedItems !== prevProps.vin.decodedItems) {
      // adds "Unknown" option to the following keys
      const decodedItems =
        this.props.vin.decodedItems &&
        cloneDeep(this.props.vin.decodedItems).map(item => {
          item.bodyStyles && item.bodyStyles.push('Unknown');
          item.transmissions && item.transmissions.push('Unknown');
          item.engines && item.engines.push('Unknown');
          item.drives && item.drives.push('Unknown');
          item.exteriorColors && item.exteriorColors.push('Unknown');
          item.interiorColors && item.interiorColors.push('Unknown');
          item.interiorTypes && item.interiorTypes.push('Unknown');
          return item;
        });
      // adds a variant option "Unknown" based on the first decoded item
      const unknowTrim = decodedItems && cloneDeep(decodedItems[0]);
      if (unknowTrim && !this.state.update) {
        unknowTrim.trim = 'Unknown';
        decodedItems.push(unknowTrim);
        this.props.updateVin({ decodedItems });
        this.setState({ update: true });
      }
    }
  }
  handleSearch() {
    if (this.state.vin.length >= 4) {
      this.setState({ isLoadingVin: true });
      this.submitVin();
    } else {
      this.setState({ showValidationError: true });
    }
  }
  submitVin() {
    const { loadVin } = this.props;
    const { vin, gatepass, pickup } = this.state;
    this.props.loadEventList();
    this.props.loadAccountList();
    loadVin(vin, gatepass, pickup)
      .then(({ response }) => {
        if (response.amStatus === 'Success') {
          this.handleVINSuccess(response);
          this.setState({ isLoadingVin: false });
        } else {
          this.setState({ isLoadingVin: false });
          this.handleVINError(response);
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoadingVin: false });
      });
  }
  handleVINSuccess(vin) {
    const { decodedItems, items, printers } = vin;
    if (decodedItems.length > 0 && items.length === 0) {
      this.setState({ openSalesInventoryModal: true });
      this.props.loadExistingData(items[0], decodedItems[0]);
      this.props.updateItem({
        vin: this.state.vin,
        printers: [],
        photos: [],
      });
    } else if (decodedItems.length === 0) {
      this.setState({ openVerificationModal: true });
      this.props.updateItem({
        vin: this.state.vin,
      });
    } else if (decodedItems.length > 0 && items.length === 1) {
      this.props.loadExistingData(items[0], decodedItems[0]);
      const vehicle = items ? items[0] : null;
      const trim = vehicle && vehicle.info ? vehicle.info.trim : null;
      const selectedVariant = findIndex(decodedItems, { trim });

      if (selectedVariant > -1) {
        this.props.updateVariant(selectedVariant);
      }
      this.props.updateItem({
        vin: this.state.vin,
        printers: [],
      });
      this.props.navigate('/core/vehicleDetails');
    }
  }
  handleVINError(response) {
    console.error(response, 'response');
  }
  close = () => {
    this.setState({ openSalesInventoryModal: false });
  };
  closeVerificationModal = () => {
    this.setState({ openVerificationModal: false });
  };
  render() {
    const {
      vin,
      openSalesInventoryModal,
      openVerificationModal,
      gatepass,
      pickup,
      showValidationError,
    } = this.state;
    return (
      <div className="search-main-container">
        <div className="search-container">
          <div className="search-field-container">
            <input
              type="text"
              value={vin}
              onChange={e => {
                this.setState({ vin: e.target.value });
                e.target.value.length >= 4 &&
                  this.setState({ showValidationError: false });
              }}
              className="search-box"
              placeholder="Enter VIN"
            />
            <div
              className="flex justify-center items-center mr-4"
              style={{ visibility: vin !== '' ? 'visible' : 'hidden' }}
              onClick={() => {
                this.setState({ vin: '', showValidationError: false });
              }}
            >
              <Icon name="Cross" size={24} color={CYAN_01} />
            </div>
          </div>
          <div className="flex justify-end items-center">
            {/* <label>
              <input
                type="checkbox"
                onChange={() => this.setState({ gatepass: !gatepass })}
              />
              Gatepassed &amp; Redmeed
            </label>
            <label>
              <input
                type="checkbox"
                onChange={() => this.setState({ pickup: !pickup })}
              />
              Pickup Log
            </label> */}
            <Button
              btnText="Search"
              height="43px"
              borderRadius="10px"
              outlined={vin.length > 0 ? false : true}
              iconName="Search"
              marginRight="12px"
              iconSize={18}
              onClickHandler={() => vin.length > 0 && this.handleSearch()}
              loading={this.state.isLoadingVin}
            />
          </div>
        </div>
        {showValidationError && (
          <div className="validation-error-message">
            Please enter a VIN Search Term of at least 4 characters
          </div>
        )}
        <div className="search-toggles items-center">
          <label>
            <input
              type="checkbox"
              onChange={() => this.setState({ gatepass: !gatepass })}
            />
            Gatepassed &amp; Redmeed
          </label>
          <label>
            <input
              type="checkbox"
              onChange={() => this.setState({ pickup: !pickup })}
            />
            Pickup Log
          </label>
        </div>
        <div className="search-btn-mob">
          <Button
            btnText="Search"
            height="43px"
            borderRadius="10px"
            outlined={vin.length > 0 ? false : true}
            iconName="Search"
            marginRight="16px"
            iconSize={18}
            onClickHandler={() => vin.length > 0 && this.handleSearch()}
            loading={this.state.isLoadingVin}
          />
        </div>
        <SaleInventoryModal open={openSalesInventoryModal} close={this.close} />
        <VinVerificationModal
          open={openVerificationModal}
          close={this.closeVerificationModal}
          vin={vin}
        />
      </div>
    );
  }
}

Vin.propTypes = {
  loadVin: PropTypes.func.isRequired,
  updateVin: PropTypes.func.isRequired,
  updateVariant: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  loadExistingData: PropTypes.func.isRequired,
  loadAccountList: PropTypes.func.isRequired,
  loadEventList: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { vin } = state.core;
  return {
    vin,
  };
};

export default connect(
  mapStateToProps,
  {
    loadVin,
    updateVin,
    updateVariant,
    updateItem,
    loadExistingData,
    loadAccountList,
    loadEventList,
  }
)(Vin);
