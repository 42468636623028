import React, { Component } from 'react';

class CircledCheckmarkIcon extends Component {
  render() {
    const { size = 22, color = '#05A660' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C17 21 21.5 16.5 21.5 11C21.5 5.5 17 1 11.5 1C6 1 1.5 5.5 1.5 11C1.5 16.5 6 21 11.5 21Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 10.9999L10.08 13.8299L15.75 8.16992"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}

export default CircledCheckmarkIcon;
