import React from 'react';
import { bool, func } from 'prop-types';
import SVG from './SVG';

const Fullscreen = React.memo(({ isFullscreen, onClick, callback }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={() => {
        onClick();
        callback();
      }}
      aria-label="Open Fullscreen"
    >
      <SVG strokeWidth={2} icon={isFullscreen ? 'minimize' : 'maximize'} />
    </button>
  );
});

Fullscreen.displayName = 'Fullscreen';

Fullscreen.defaultProps = {
  callback: () => null,
};

Fullscreen.propTypes = {
  isFullscreen: bool.isRequired,
  onClick: func.isRequired,
};

export default Fullscreen;
