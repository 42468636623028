import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { getConfig, hasMPFeature } from '../utils/helpers';
const timezone = jstz.determine().name();

class LiveBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      liveFrameHeight: '500px',
      liveBarHeight: '45px',
      url:
        `${getConfig('hyperlaneUrl')}?ticket=${props.cookies.get('apiKey')}` +
        `&Relogin=1`,
    };
  }

  openWindow = (
    url,
    cache,
    center,
    scroll,
    left,
    top,
    width,
    height,
    advancedFeatures
  ) => {
    if (true) {
      if (cache === 0) {
        if (url.indexOf('?') === -1)
          url = `${url}?pageversion=${moment.tz(timezone).format('hh:mm')}`;
        else url = `${url}&pageversion=${moment.tz(timezone).format('hh:mm')}`;
      }
      if (center === 1) {
        left = (window.screen.availWidth - width) / 2;
        top = (window.screen.availHeight - height) / 2;
      }

      let features =
        `toolbar=0,scrollbars=${scroll},left=${left},` +
        `top=${top},height=${height},width=${width}`;
      if (advancedFeatures !== '') {
        features = `${features},${advancedFeatures}`;
      }
      window.open(url, '', features);
    }
  };

  renderFrame = () => {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="live-frame">
          <iframe
            id="liveFrame"
            title="liveFrame"
            width="100%"
            height={this.state.liveFrameHeight}
            scrolling="yes"
            frameBorder="0"
            src={this.state.url}
            allowFullScreen
          />
        </div>
      );
    }
  };

  handleWindowMinimize = () => {
    this.setState({
      loading: true,
      liveBarHeight: '45px',
    });
  };

  handleWindowRestore = () => {
    this.setState({
      loading: false,
      liveFrameHeight: '500px',
      liveBarHeight: '525px',
    });
  };

  handleWindowMaximize = () => {
    this.setState({
      loading: false,
      liveFrameHeight: `${window.innerHeight - 45}px`,
      liveBarHeight: `${window.innerHeight}px`,
    });
  };

  handleDetatch = () => {
    this.handleWindowMinimize();
    this.openWindow(this.state.url, 0, 1, 1, 0, 0, 1280, 600, '');
  };

  render() {
    const simulcastEnabled = hasMPFeature(
      this.props.marketplaceFeatures.features,
      410
    );

    // hyperlane is now enabled along with simulcast
    if (simulcastEnabled) {
      return (
        <div className="live-container">
          <div
            className="live-bar"
            id="liveBar"
            style={{ height: this.state.liveBarHeight }}
          >
            <h4
              style={{
                float: 'left',
                width: '100%',
                backgroundColor: '#35394C',
              }}
            >
              <span className="pull-left" style={{ marginRight: '10px' }}>
                Powered by SIA{' '}
                <span className="fa fa-info-circle" title="Patent Pending" />
              </span>
              <div className="live-actions">
                <span
                  className="fa fa-window-minimize ias-tooltip"
                  title="Minimize"
                  onClick={this.handleWindowMinimize}
                >
                  <span className="ias-tooltip-content">Minimize</span>
                </span>
                <span
                  className="fa fa-window-maximize ias-tooltip"
                  title="Half Screen"
                  onClick={this.handleWindowRestore}
                >
                  <span className="ias-tooltip-content">Half Screen</span>
                </span>
                <span
                  className="fa fa-arrows-alt ias-tooltip"
                  title="Full Screen"
                  onClick={this.handleWindowMaximize}
                >
                  <span className="ias-tooltip-content">Full Screen</span>
                </span>
                <span
                  className="fa fa-external-link ias-tooltip"
                  title="Launch in New Window"
                  onClick={this.handleDetatch}
                >
                  <span className="ias-tooltip-content">New Window</span>
                </span>
              </div>
              <span className="pull-right" style={{ marginRight: '10px' }}>
                <span className="fa fa-gavel" /> HyperLane
              </span>
            </h4>
            {this.renderFrame()}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  const { marketplaceFeatures } = state.entities;
  return { marketplaceFeatures };
};

export default connect(mapStateToProps, {})(LiveBar);
