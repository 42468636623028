import React, { Component } from 'react';
import { connect } from 'react-redux';

class SportsVanTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 140 810 340">
          <path
            fill="#FFFFFF"
            d="M60,163.5c-19.7,6.2-33.4,23-35.4,44c-2.9,30.4-4.3,60.3-4.3,90.1v1.7c0,30,1.5,62,4.3,92.4
	c1.9,20.9,15.7,37.8,35.4,44c10.6,3.3,24.2,5.6,36.5,7.8c0.1,0,0.3,0,0.4,0.1c6.9,1.2,13.5,1.7,20.5,1.7l94.4,1.8l71.1,1.4
	c44.2,1.4,88.3,2.2,132.6,2.8c61.6,0.7,123.3,0.8,185,0.4c57.6-0.4,115.1-1.1,172.8-2.2c2.3,0,4.4-0.1,6.5-0.4
	c1.7,0,3.4,0.1,5.1,0.1c17.6,0.1,34.1-2.8,35.1-29c1.4-40.6,2.1-81.7,2.2-120.6v-1.9c0-38.8-0.7-77.7-2.2-118.3
	c-1-26.3-17.5-29.2-35.1-29c-1.7,0-3.4,0-5.1,0.1c-2.1-0.3-4.1-0.3-6.5-0.4c-57.6-1-115.1-1.8-172.8-2.2
	c-61.6-0.4-123.3-0.4-185,0.4c-44.2,0.6-88.3,1.4-132.6,2.8l-71.1,1.4l-94.4,1.8c-7,0.1-13.6,0.4-20.5,1.6c-0.1,0-0.3,0-0.4,0.1
	C84.2,158,70.6,160.2,60,163.5L60,163.5z"
          />
          <path
            fill="#EAEBEB"
            d="M54.8,174.5l-0.1,0.1l-0.1,0.1l-0.1,0.1l-1.8,3l-0.1,0.1c-0.1,0.1-0.3,0.4-0.3,0.6
	c-8.7,14.6-12.2,34.8-13.2,50.9c0.7-0.6,1.4-1.2,2.2-1.8c1.7-1.2,3.3-2.5,4.5-3.6c0.1-0.4,0.1-0.8,0.3-1.2
	c1.8-15.7,6.3-32.9,16.1-44.8c5.6-6.9,13.1-12,22.7-14h0.1C74.1,163.9,63.1,163.1,54.8,174.5L54.8,174.5z"
          />
          <path
            fill="#5E5E5E"
            d="M45.5,224.3l-4.5,3.6l-2.2,1.6c-0.5,0.4-1.1,1-1.5,1.4c-0.7,0.6-1.4,1.2-1.9,1.9l-1.2,1.2
	c-2.3,2.6-4.1,5.5-4.4,8.5c-3.6,34-3.6,80.1,0,114c0.3,3,2.1,5.9,4.4,8.5l1.2,1.2c0.7,0.7,1.2,1.2,1.9,1.9c0.5,0.4,1.1,1,1.5,1.4
	c0.7,0.5,1.4,1.2,2.2,1.8c1.7,1.2,3.3,2.5,4.5,3.6c-0.4-1.5-1-3.3-1.2-4.5c-0.1-0.4-0.3-0.8-0.3-1.2c-3.8-42.8-3.8-96.2,0-138.9
	c0-0.4,0.1-0.8,0.3-1.2C44.6,227.6,45.3,225.8,45.5,224.3L45.5,224.3z"
          />
          <path
            fill="#EAEBEB"
            d="M220.7,167.2c-5.9,0.7-11.6,3.2-16,7c-3.6,3.2-6.5,7.2-8.3,11.8c-20.4,53.4-20.4,173.7,0,227.1
	c1.8,4.7,4.7,8.8,8.3,11.8c4.4,3.9,10,6.3,16,7c3.2,0.4,6.5,0.3,9.9-0.5l66.4-14.2c6.3-1.4,8.5-3.9,8.5-8.8c0-1.2-0.1-2.6-0.4-4.1
	c-10.3-60.7-10.3-148.6,0-209.2c0.3-1.5,0.4-2.9,0.4-4.1c0.1-5.1-2.1-7.4-8.5-8.8l-66.4-14.2C227.3,167,223.8,166.8,220.7,167.2
	L220.7,167.2z M401.3,154.7c-55.6-0.3-111.3-0.3-167.1-0.3h-5.2c31.1,9.5,90.8,22,122.8,22H400c1.1,0,1.9-0.8,1.9-1.9L401.3,154.7
	L401.3,154.7z M583.1,156.1c-51.7-0.7-103.7-1.1-155.7-1.4V174c0,1.5,1.2,2.6,2.6,2.6c49.1,0.6,97.9,0.3,146.6-0.7
	c1.1,0,2.2-0.7,2.5-1.8C581.1,168.1,582.3,162.1,583.1,156.1L583.1,156.1z M744.5,160.9c-46.6-0.6-93.3-1-139.9-1.1
	c-0.8,0-1.7,0.6-1.8,1.5c-1,4.4-2.1,8.7-3.7,12.9c-0.4,1.2,0.4,2.5,1.7,2.5c39.1,0,78.4-0.1,117.9-0.4
	C730.7,176,751.4,160.9,744.5,160.9L744.5,160.9z M758.4,206.8c2.8,61.1,2.8,124.4,0,185.4c-0.1,3,1.1,5.6,3.4,7.4
	c2.5,1.8,18.6,11.1,23.7,13.6c1.5,0.7,3.6,0.3,4.7-1c8.1-68.1,8.1-157.5,0-225.6c-1-1.5-2.9-1.5-4.7-0.8
	c-5.2,2.3-21.2,11.7-23.7,13.6C759.5,201.2,758.3,203.8,758.4,206.8L758.4,206.8z M779.6,150.3c-3.4,3.4-5.6,6.6-6.3,9.5
	c9.2,1.1,13.1,5,15,13.6c4.7,0,9.5-1,14.3-2.3C800.3,157.6,793.1,151.8,779.6,150.3z M87.2,435.5h-0.3c-9.6-2.1-17.1-7.2-22.7-14
	c-9.8-12-14.3-29.2-16.1-44.8c0-0.4-0.1-0.8-0.3-1.2c-1.4-1.1-2.9-2.2-4.5-3.6c-0.7-0.5-1.4-1.1-2.2-1.8c1,16.1,4.5,36.5,13.2,50.9
	c0.1,0.1,0.3,0.4,0.3,0.5l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1C63.1,436,74.1,435.2,87.2,435.5L87.2,435.5z M401.3,444.6
	c-55.6,0.3-111.3,0.3-167.1,0.3h-5.2c31.1-9.5,90.8-22,122.8-22H400c1.1,0,1.9,0.8,1.9,1.9L401.3,444.6L401.3,444.6z M583.1,443
	c-51.7,0.7-103.7,1.1-155.7,1.4v-19.3c0-1.5,1.2-2.6,2.6-2.6c49.1-0.5,97.9-0.3,146.6,0.7c1.1,0,2.2,0.7,2.5,1.8
	C581.1,431.1,582.3,437.1,583.1,443L583.1,443z M744.5,438.4c-46.6,0.5-93.3,1-139.9,1.1c-0.8,0-1.7-0.6-1.8-1.5
	c-1-4.4-2.1-8.7-3.7-12.9c-0.4-1.2,0.4-2.5,1.7-2.5c39.1,0,78.4,0.1,117.9,0.4C730.7,423.1,751.4,438.2,744.5,438.4L744.5,438.4z
	 M779.6,448.8c-3.4-3.4-5.6-6.6-6.3-9.5c9.2-1.1,13.1-5,15-13.6c4.7,0,9.5,1,14.3,2.3C800.3,441.7,793.1,447.3,779.6,448.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            d="
	M196.4,186.1c5.2-13.8,19.7-21.5,34.1-18.3l66.4,14.2c8,1.6,9.4,5.1,8.1,13.1c-10.3,60.7-10.3,148.6,0,209.2
	c1.4,8-0.1,11.3-8.1,13.1l-66.4,14.2c-14.4,3.2-28.8-4.5-34.1-18.3C176.1,359.8,176.1,239.5,196.4,186.1L196.4,186.1z M45.7,224.3
	c44.6-11.6,89.6-18.2,134.9-22.1c1.8-0.1,1.9,3.3,0.3,3.4c-40,2.5-84.9,9.9-133.9,21.2c-1.7,0.4-2.8,1.6-2.9,3.3
	c-3.9,42.8-3.9,96.2,0,138.9c0.1,1.6,1.2,3,2.9,3.3c48.8,11.4,93.8,18.8,133.8,21.2c1.8,0.1,1.5,3.6-0.3,3.4
	c-45.4-4-90.4-10.6-134.9-22.1 M44.4,228.9c0.3-1.6,1.2-4.1,1.4-5.8c2.8-24.8,12.5-53.4,38.8-58.9c37.8-7.8,110.1-9.6,149.5-9.8
	c179.4-0.3,357.9,0.8,534.3,5.1c14.7,0.4,18.8,5,20.8,19.1c9.5,71.7,9.5,170,0,241.7c-1.9,14.2-6.1,18.8-20.8,19.1
	c-176.4,4.3-354.9,5.4-534.3,5.1c-39.5-0.1-111.6-1.9-149.5-9.8c-26.3-5.5-36-34.1-38.8-58.9c-0.1-1.6-1.1-4.1-1.4-5.8 M51.9,290.8
	c36.6-2.2,74.8-2.2,113.4-1.9c5.6,0,5.6,21.3,0,21.5c-38.5,0.3-76.8,0.3-113.4-1.9C46.8,308.1,46.8,291,51.9,290.8L51.9,290.8z
	 M45.7,224.3c-5.5,4.3-15.1,10.9-15.8,18.3c-3.6,34-3.6,80.1,0,114c0.8,7.4,10.3,14,15.8,18.3 M41,227.8c-4.1,45.4-4.1,98.2,0,143.5
	 M37.3,230.9c-3.2,46.6-3.2,90.8,0,137.3 M34.3,234.1c-3.2,44.3-3.2,86.7,0,131.1 M87.2,163.7c-13.1,0.3-24.2-0.6-32.5,10.9
	c-10.6,14.6-14.7,37.3-16,55 M24.6,207.5c1.9-20.9,15.7-37.8,35.4-44c10.7-3.3,24.6-5.6,36.9-7.8c6.9-1.2,13.5-1.7,20.5-1.7l165.6-3
	c163.4-4.8,326.9-3.7,490.3-1c19.4,0.3,28.8,6.7,30,26.6c5.1,83.5,5.1,162.6,0,246.1c-1.2,19.8-10.6,26.3-30,26.6
	c-163.4,2.8-326.9,3.9-490.3-1l-165.6-3c-7-0.1-13.6-0.4-20.5-1.6c-12.2-2.3-26.1-4.5-36.9-7.8c-19.7-6.2-33.4-23-35.4-44
	C18.8,329.7,18.8,269.6,24.6,207.5L24.6,207.5z M96.4,155.8c-30.5,7-61.6,16.2-65.2,52.3c-6.6,64.1-6.6,118.9,0,183
	c3.7,36,34.7,45.3,65.2,52.4 M51.2,172.7c-10.2,16-16,40-15.8,60 M52.1,178.6c46.4-1.8,93.3-5.1,140.3-8.8c5-0.4,8.3,2.2,12.2,4.4
	 M182,170.5c-3.3,10.2-6.2,20.9-8.7,32.2 M172.6,206c-5.4,25.9-8.7,53.9-10.5,82.8 M773.2,159.8c0.8-3,2.9-6.2,6.3-9.5 M788.2,173.6
	c4.7,0,9.5-1,14.3-2.3 M779.6,150.3c19.4-0.6,39.2,0.1,40.2,29c2.9,82.7,2.9,157.9,0,240.6c-1,28.8-20.8,29.4-40.2,29 M784.7,150.3
	c15.8,1.4,21.2,1.6,23.5,19.8c9.9,76.5,9.9,182.7,0,259c-2.3,18.2-7.7,18.3-23.5,19.8 M305.4,190.8c134.3,4.1,283.4,4.4,425.9,6.6
	c19.3,0.3,40.2-14.7,57-23.9 M719.6,197.2c-2.9,66.9-2.9,137.8,0,204.7 M723.7,197.4c18.4,2.8,28.5,12.2,30.1,30.3
	c4.1,43.2,4.1,100.8,0,144c-1.8,18-11.7,27.5-30.1,30.3 M790.3,188c-0.3-2.8-2.8-3-4.8-2.1c-5.2,2.3-21.2,11.7-23.7,13.6
	c-2.3,1.8-3.6,4.4-3.4,7.4c2.8,61.1,2.8,124.4,0,185.4c-0.1,3,1.1,5.6,3.4,7.4c2.5,1.8,18.6,11.1,23.7,13.6c2.1,1,4.5,0.5,4.8-2.1
	 M752.2,192.8c1.1,14.4,1.8,28.9,2.3,43.2 M211.7,152.2c0.3,1,1.1,1.5,3.3,2.3c32.5,10.7,76.1,19.5,110.2,24.2
	c8.8,1.2,17.1,1.8,26.4,1.8c122.3,0.7,245.3,0.6,368.3-0.7c10.5-0.1,23.1-9.2,31.5-18c2.3-2.5,3.7-6.9-1-7
	c-177.6-1.9-359.7-3-537.9-1.5 M231.7,159.5c-5,2.5-8.7,5-11,7.7 M415.4,148.2c-0.1,7.8-1.4,24.8-0.4,31.5c0.5,4,4,7,8.1,7
	l164.9,0.1c2.2,0,4-1.2,4.7-3.3c5.5-14.2,9.2-27.4,7.7-35.8 M744.5,160.9c-46.6-0.6-93.3-1-139.9-1.1c-0.8,0-1.7,0.6-1.8,1.5
	c-1,4.4-2.1,8.7-3.7,12.9c-0.4,1.2,0.4,2.5,1.7,2.5c39.1,0,78.4-0.1,117.9-0.4C730.7,176,751.4,160.9,744.5,160.9L744.5,160.9z
	 M427.4,153.8V174c0,1.5,1.2,2.6,2.6,2.6c49.1,0.6,97.9,0.3,146.6-0.7c1.1,0,2.2-0.7,2.5-1.8c1.8-5.9,3-12,3.9-17.9 M228.8,154.4
	c31.1,9.5,90.8,22,122.8,22h48.3c1.1,0,1.9-0.8,1.9-1.9l-0.7-19.8 M87.2,435.5c-13.1-0.3-24.2,0.5-32.5-10.9
	c-10.6-14.6-14.7-37.3-16-55 M51.2,426.4c-10.2-16-16-40-15.8-60 M52.1,420.5c46.4,1.8,93.3,5.1,140.3,8.8c5,0.4,8.3-2.2,12.2-4.4
	 M182,428.6c-3.3-10.2-6.2-20.9-8.7-32.2 M172.6,393.1c-5.4-25.9-8.7-53.9-10.5-82.8 M773.2,439.3c0.8,3,2.9,6.2,6.3,9.5
	 M788.2,425.7c4.7,0,9.5,1,14.3,2.3 M305.4,408.4c134.3-4.1,283.4-4.4,425.9-6.6c19.3-0.3,40.2,14.7,57,23.9 M752.2,406.3
	c1.1-14.4,1.8-28.9,2.3-43.2 M211.7,446.9c0.3-1,1.1-1.5,3.3-2.3c32.5-10.7,76.1-19.5,110.2-24.2c8.8-1.2,17.1-1.8,26.4-1.8
	c122.3-0.7,245.3-0.5,368.3,0.7c10.5,0.1,23.1,9.2,31.5,18c2.3,2.5,3.7,6.9-1,7c-177.6,2.1-359.7,3.2-538,1.6 M231.7,439.6
	c-5-2.5-8.7-5-11-7.7 M415.4,451c-0.1-7.8-1.4-24.8-0.4-31.5c0.5-4,4-7,8.1-7l164.9-0.1c2.2,0,4,1.2,4.7,3.3
	c5.5,14.2,9.2,27.4,7.7,35.8 M744.5,438.4c-46.6,0.5-93.3,1-139.9,1.1c-0.8,0-1.7-0.5-1.8-1.4c-1-4.4-2.1-8.7-3.7-12.9
	c-0.4-1.2,0.4-2.5,1.7-2.5c39.1,0,78.4,0.1,117.9,0.4C730.7,423.1,751.4,438.2,744.5,438.4L744.5,438.4z M427.4,445.4v-20.2
	c0-1.5,1.2-2.6,2.6-2.6c49.1-0.5,97.9-0.3,146.6,0.7c1.1,0,2.2,0.7,2.5,1.8c1.8,5.9,3,12,3.9,17.9 M228.8,444.7
	c31.1-9.5,90.8-22,122.8-22h48.3c1.1,0,1.9,0.8,1.9,1.9l-0.7,19.8"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M204.7,425c0,0-6.2,4.1-9.1,4.3
		c0,0-1,0.4-3.6,0.1l-10.2-0.8l-51.6-3.7c0,0-9.2-0.6-39.1-2.3l-29.3-1.6c0,0-12.4-12.1-16.2-46.1l-1.5-5.2c0,0-1.1-10.2-2.3-37.3
		c0,0-0.5-14.4-0.5-32.7c0-18.3,0.7-33.8,0.7-33.8s0.4-12.2,2.2-35.6l1.5-5.8c0,0,2.8-30.5,16.2-46.1c0,0,31.6-1.2,65.6-3.7l62-4.5
		c0,0,4.4-0.6,5.9-0.1c1.7,0.4,3.4,0.7,9.2,4.3c0,0-6.2,4.3-9.6,15.7c0,0-7.2,17.5-11.4,57.9c0,0-2.5,22.3-2.5,51.9
		s1.9,46.2,1.9,46.2s1.4,17.9,4.7,35.1c0,0,3.4,20.1,9.6,34.5C197.4,415.4,199.3,420.3,204.7,425z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M228.8,431.8
		c0,0-8.3,1.5-16.1-1.9c0,0-9.6-3.4-14.9-13.6c0,0-7-15.3-11.3-42.1c0,0-3.4-20.5-4.8-45.7c0,0-0.7-14-0.7-28.9c0,0-0.3-15.8,1.4-38
		c0,0,1.8-26.6,5.9-46.1c0,0,3.2-17.5,8.3-30.3c0,0,3.2-9.5,13.6-15c0,0,8.9-5.2,20.6-2.6l65.3,14c0,0,6.7,1,8.4,4.5
		c0,0,1.2,1.7,0.8,6.5c0,0-2.8,15.1-5.2,41.3c0,0-0.8,7.2-1.7,23.4c0,0-1.1,20.5-1.1,42.2c0,21.7,1.2,43.5,1.4,46.8
		c0,0,0.8,15.3,3.3,36.6c0,0,1,8.5,3.2,21.9c0,0,1.4,6.9-1.8,9.5c0,0-1.7,1.8-5.8,2.6l-19.8,4.3L228.8,431.8z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M305.4,408.4c0,0,0-1.1-0.4-3.9
		c0,0-4.5-24.1-6.5-59c0,0-1.4-23.1-1.4-45.8c0-22.7,1.4-47,1.4-47s1.4-27.4,6.6-60c0,0,0.1-1.1,0.1-2.1l50.5,1.4l66.4,1.4l90.7,1.2
		l72.9,0.8l74.3,0.8l59.3,0.8c0,0,9.2-0.6,19.5,5c0,0,11.8,5.5,14.3,21.6c0,0,2.1,17.6,3,42.4c0,0,0.6,17.6,0.6,33.4
		c0,15.8-0.6,32.3-0.6,32.3s-1.1,30-3.2,44.4c0,0-1.5,13.6-14.2,20.6c0,0-6.2,3.7-16,4.8l-14.7,0.3l-41.4,0.6l-55,0.7l-51.7,0.6
		l-60.3,0.7l-57.8,0.8l-63.7,1.1L305.4,408.4z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M790.2,411.7
		c0,0-0.8,3.3-4.7,1.6c0,0-16-8.4-23.2-13.3c0,0-4.1-2.3-3.9-7.7c0,0,2.1-39.3,2.1-92.7s-2.1-92.2-2.1-92.2s-0.7-5.4,4-8.3
		c0,0,11.7-7.2,23.1-13.2c0,0,3.9-1.7,4.7,1.7c0,0,6.1,46.6,6.1,112.1C796.2,365.2,790.2,411.7,790.2,411.7z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M802.5,428
		c0,0-6.7-2.5-14.3-2.3c0,0,8-46.8,8-126c0-79.4-8-126.1-8-126.1s4.1,0.6,14.3-2.3c0,0,0.4,2.3,0.6,5c0,0,3.9,58.5,3.9,123.5
		c0,65.1-3.9,124.1-3.9,124.1S803,425.8,802.5,428z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(SportsVanTop);
