import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class BackButton extends Component {
  handleBack(e) {
    e.preventDefault();
    window.history.back();
  }

  render() {
    if (window.history.length === 1 || window.history.length === 0) {
      return null;
    } else {
      return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Link to="#" onClick={e => this.handleBack(e)}>
            Go back
          </Link>
        </div>
      );
    }
  }
}
