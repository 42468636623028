import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import api from '../middleware';
import rootReducer from '../reducers';
import { supressRouterWarning } from '../utils/helpers';

export default function _configureStore(preloadedState) {
  const isEnvDevelopment = process.env.NODE_ENV === 'development';
  const persistConfig = { key: 'root', storage, whitelist: ['core'] };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middleware = [
    thunk,
    api,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }),
  ];

  if (isEnvDevelopment) {
    const logger = createLogger({
      collapsed: true,
      timestamp: false,
      level: { action: 'info', nextState: false, prevState: false },
    });
    middleware.push(logger);
  }

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    devTools: isEnvDevelopment,
    middleware,
  });

  if (isEnvDevelopment && module.hot) {
    supressRouterWarning();
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers/index').default;
      store.replaceReducer(persistReducer(persistConfig, nextReducer));
    });
  }

  return store;
}
