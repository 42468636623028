import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { chain } from 'lodash';
import prodConfigs from '../config/index.prod.js';

const styles = {
  container: {
    margin: '0 auto',
    padding: 0,
    position: 'relative',
    backgroundColor: 'rgba(222,199,235,0.2)',
  },
  marketplacesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '30px 50px',
  },
  versionContainer: {},
  version: {
    textAlign: 'center',
    padding: 20,
  },
  title: {
    padding: '0.45em .75em',
    margin: 0,
    // background: `linear-gradient(90deg, #848dc3 0%, #239eb3 100%)`,
    backgroundColor: `ghostwhite`,
    // color: '#fff',
    color: 'black',
    textAlign: 'center',
    fontFamily: 'auto',
    verticalAlign: 'center',
  },
  link: {
    textAlign: 'center',
    marginRight: 10,
    marginBottom: 10,
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: 5,
    height: 180,
    width: 300,
    backgroundColor: '#fff',
  },
  logo: {
    objectFit: 'contain',
    width: '4rem',
    height: '2.5rem',
    margin: 'auto 0',
    // backgroundColor: 'ghostwhite',
    
  },
  image: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
};

class Default extends Component {

  renderHeader() {
    return (
      <h1 style={{...styles.title, boxShadow: '2px 2px 5px rgba(0,5,0,0.2)'}}>
        <img 
          style={styles.logo}
          alt={" "}
          src={'https://cdn.integratedauctionsolutions.com/yourmarketplace.siamarketplace.com/logo.png'} 
        />
        {'SIA Marketplaces'}
      </h1>

    )
  }

  renderMarketplaces() {
    const data = chain(Object.entries(prodConfigs))
      .map(([marketplace, config]) => ({
        marketplace,
        marketplaceId: config.marketplaceId,
        name: config.name,
        bucket: config.bucket,
      }))
      .filter(item => item.bucket)
      .uniqBy('bucket')
      .sort((a, b) => a.name - b.name)
      .value();

    return data.map((item, index) => {
      const link = `https://${item.marketplace}`;
      const src =
        `https://cdn.integratedauctionsolutions.com/` +
        `${item.bucket}/logo.png`;

      return (
        <Link key={item.marketplace + index} to={link} style={styles.link}>
          <img style={styles.image} src={src} alt={item.marketplace} />
          {false && <span>{link}</span>}
        </Link>
      );
    });
  }

  render() {
    return (
      <DocumentTitle title="SIA Marketplace">
        <div style={styles.container}>
          {this.renderHeader()}
          {/* <h1 style={styles.title}>
            SIA Marketplaces
          </h1> */}

          <div style={styles.marketplacesContainer}>
            {this.renderMarketplaces()}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default Default;
