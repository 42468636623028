import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { determineCountdownInfo, getTemplate } from '../utils/helpers';

let styles = {};

class CountdownTimerVLI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownInfo: determineCountdownInfo(props.vehicleData),
    };
  }

  componentDidMount() {
    if (!this.timer) {
      this.setTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setTimer = () => {
    this.timer = setInterval(() => {
      const countdownInfo = determineCountdownInfo(this.props.vehicleData);

      if (this.state.countdownInfo.eventStatus !== countdownInfo.eventStatus) {
        this.props.onCountdownChange();
      }

      if (
        this.state.countdownInfo.time !== countdownInfo.time ||
        this.state.countdownInfo.eventStatus !== countdownInfo.eventStatus ||
        this.state.countdownInfo.countdownType !== countdownInfo.countdownType
      ) {
        this.setState({ countdownInfo });
      }
    }, 1000);
  };

  render() {
    if (!this.props.vehicleData || !this.state.countdownInfo.countdownType) {
      return null;
    }

    const loud = this.state.countdownInfo.countdownType === 'loud';

    return (
      <div
        style={{
          ...styles.block,
          backgroundColor: loud
            ? getTemplate(this.props.template, 'countdownTimer.backgroundColor')
            : 'inherit',
          color: loud
            ? getTemplate(this.props.template, 'countdownTimer.color')
            : 'inherit',
          padding: loud ? 5 : 'inherit',
          justifyContent: loud ? 'center' : 'flex-start',
        }}
      >
        <div style={styles.label}>{this.state.countdownInfo.label}</div>
        <div style={styles.time}>{this.state.countdownInfo.time}</div>
      </div>
    );
  }
}

styles = {
  block: {
    display: 'flex',
    maxWidth: 250,
    borderRadius: '6px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  time: {},
};

CountdownTimerVLI.defaultProps = {
  onCountdownChange: () => null,
};

CountdownTimerVLI.propTypes = {
  vehicleData: PropTypes.object.isRequired,
  onCountdownChange: PropTypes.func,
};

const mapStateToProps = state => {
  const { template } = state.entities;
  return { template };
};

export default connect(
  mapStateToProps,
  {}
)(CountdownTimerVLI);
