import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import SettingsNav from "../components/SettingsNav";
import Profile from "../components/accountSettings/Profile";
import Accounts from "../components/accountSettings/Accounts";
import Alerts from "../components/accountSettings/Alerts";
import Password from "../components/accountSettings/Password";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: "profile"
    };

    this.handleSection = this.handleSection.bind(this);
  }

  componentDidMount() {}

  handleSection(section) {
    this.setState({
      section: section
    });
  }

  render() {
    let section = "";
    switch (this.state.section) {
      case "profile":
        section = <Profile />;
        break;
      case "accounts":
        section = <Accounts />;
        break;
      case "alerts":
        section = <Alerts />;
        break;
      case "password":
        section = <Password />;
        break;
      default:
        section = <Profile />;
    }
    return (
      <DocumentTitle title={`Account Settings`}>
        <div>
          <SettingsNav updateSection={this.handleSection} />
          <div className="container-fluid">{section}</div>
        </div>
      </DocumentTitle>
    );
  }
}
