import { FETCH_API } from '../../middleware';

export const ACCOUNT_LIST_REQUEST = 'ACCOUNT_LIST_REQUEST';
export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT_LIST_SUCCESS';
export const ACCOUNT_LIST_FAILURE = 'ACCOUNT_LIST_FAILURE';

const fetchAccountList = (keyword, apiKey) => ({
  [FETCH_API]: {
    types: [ACCOUNT_LIST_REQUEST, ACCOUNT_LIST_SUCCESS, ACCOUNT_LIST_FAILURE],
    endpoint: `get-account-list?apiKey=${apiKey}&keyword=${keyword}`,
    core: true,
  },
});

export const loadAccountList = (keyword, apiKey) => dispatch =>
  dispatch(fetchAccountList(keyword, apiKey));

export const RESET_ACCOUNT_LIST = 'RESET_ACCOUNT_LIST';

export const resetAccountList = () => ({
  type: RESET_ACCOUNT_LIST,
});
export const AUTO_ANNOUNCEMENTS_REQUEST = 'AUTO_ANNOUNCEMENTS_REQUEST';
export const AUTO_ANNOUNCEMENTS_SUCCESS = 'AUTO_ANNOUNCEMENTS_SUCCESS';
export const AUTO_ANNOUNCEMENTS_FAILURE = 'AUTO_ANNOUNCEMENTS_FAILURE';

const fetchAutoAnnouncements = (accountNumber, apiKey) => ({
  [FETCH_API]: {
    types: [
      AUTO_ANNOUNCEMENTS_REQUEST,
      AUTO_ANNOUNCEMENTS_SUCCESS,
      AUTO_ANNOUNCEMENTS_FAILURE,
    ],
    endpoint:
      `get-auto-announcements` +
      `?apiKey=${apiKey}` +
      `&accountNumber=${accountNumber}`,
    core: true,
  },
});

export const loadAutoAnnouncements = (accountNumber, apiKey) => dispatch =>
  dispatch(fetchAutoAnnouncements(accountNumber, apiKey));

export const RESET_AUTO_ANNOUNCEMENTS = 'RESET_AUTO_ANNOUNCEMENTS';

export const resetAutoAnnouncements = () => ({
  type: RESET_AUTO_ANNOUNCEMENTS,
});
