import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateItem } from '../../../../../../actions';
import Layout from '../../../templates/forms/Layout';
import {
  Aeropackages,
  Doortype,
  FifthWheelType,
  FuelTankTypes,
  HEAVY_TRUCK_PROPERTIES,
  INVENTORY_META_PROPERTIES,
  MirrorPowerTypes,
  PowerWindow,
  SuspOther,
  TrailerAxlOther,
  TrailerDoorType,
  TrailerMat,
  TrailerMatOther,
} from '../../../../constants/common';
import HeavyTruckForm from './HeavyTruckForm';
import FormFooter from '../../../templates/forms/formFooter';

class HeavyTruck extends Component {
  constructor(props) {
    super(props);
    const heavyTrucks = HEAVY_TRUCK_PROPERTIES.concat(
      INVENTORY_META_PROPERTIES
    ).reduce((a, property) => {
      return Object.assign(a, {
        [property]: props.item[property] || null,
      });
    }, {});
    this.state = {
      heavyTrucks,
      optionItems: {},
    };
  }

  componentDidMount() {
    this.buildHeavyTrucks();
    this.loadData();
  }

  buildHeavyTrucks() {
    const { item, updateItem } = this.props;

    const heavyTrucks = HEAVY_TRUCK_PROPERTIES.reduce((a, property) => {
      return Object.assign(a, { [property]: item[property] || null });
    }, {});

    const inventoryMeta = INVENTORY_META_PROPERTIES.reduce((a, property) => {
      return Object.assign(a, { [property]: item[property] || null });
    }, {});

    updateItem({ ...heavyTrucks, ...inventoryMeta });
  }

  loadData() {
    const { vin, variant } = this.props;
    const item = vin.decodedItems[variant && variant.index];

    let axles = [];

    if (
      item === undefined ||
      item.Axles === undefined ||
      item.Axles[0] == null
    ) {
      axles = ['0', '1', '2', '3', '4', '5', '6', '7'];
    } else {
      axles = item['Axles'];
    }
    let fifthWheelType = [];
    if (
      item === undefined ||
      item.Fifwhltype === undefined ||
      item.Fifwhltype[0] == null
    ) {
      fifthWheelType = FifthWheelType;
    } else {
      fifthWheelType = item['Fifwhltype'];
    }
    let EngineBrake = [];

    if (
      item === undefined ||
      item.Engbrake === undefined ||
      item.Engbrake[0] == null
    ) {
      EngineBrake = ['True', 'False'];
    } else {
      EngineBrake = item['Engbrake'];
    }
    let aeroPack = [];
    if (
      item === undefined ||
      item.Aeropack === undefined ||
      item.Aeropack[0] == null
    ) {
      aeroPack = Aeropackages;
    } else {
      aeroPack = item['Aeropack'];
    }
    let suspOther = [];
    if (
      item === undefined ||
      item.suspother === undefined ||
      item.suspother[0] == null
    ) {
      suspOther = SuspOther;
    } else {
      suspOther = item['suspother'];
    }
    let Trlrdoortp = [];
    if (
      item === undefined ||
      item.Trlrdoortp === undefined ||
      item.Trlrdoortp[0] == null
    ) {
      Trlrdoortp = TrailerDoorType;
    } else {
      Trlrdoortp = item['Trlrdoortp'];
    }
    let Fueltank = [];
    if (
      item === undefined ||
      item.Fueltank === undefined ||
      item.Fueltank[0] == null
    ) {
      Fueltank = FuelTankTypes;
    } else {
      Fueltank = item['Fueltank'];
    }
    let Heavytrk = [];
    if (
      item === undefined ||
      item.Heavytrk === undefined ||
      item.Heavytrk == null
    ) {
      Heavytrk = ['True', 'False'];
    } else {
      Heavytrk = item['Heavytrk'];
    }
    let mirrorpwr = [];
    if (
      item === undefined ||
      item.mirrorpwr === undefined ||
      item.mirrorpwr[0] == null
    ) {
      mirrorpwr = MirrorPowerTypes;
    } else {
      mirrorpwr = item['mirrorpwr'];
    }
    let mirrorheat = [];
    if (
      item === undefined ||
      item.mirrorheat === undefined ||
      item.mirrorheat[0] == null
    ) {
      mirrorheat = ['True', 'False'];
    } else {
      mirrorheat = item['mirrorheat'];
    }
    let windowpwr = [];
    if (
      item === undefined ||
      item.windowpwr === undefined ||
      item.windowpwr[0] == null
    ) {
      windowpwr = PowerWindow;
    } else {
      windowpwr = item['windowpwr'];
    }
    let lftdrtype = [];
    if (
      item === undefined ||
      item.lftdrtype === undefined ||
      item.lftdrtype[0] == null
    ) {
      lftdrtype = Doortype;
    } else {
      lftdrtype = item['lftdrtype'];
    }
    let liftgate = [];
    if (
      item === undefined ||
      item.liftgate === undefined ||
      item.liftgate[0] == null
    ) {
      liftgate = ['True', 'False'];
    } else {
      liftgate = item['liftgate'];
    }
    let reardrtype = [];
    if (
      item === undefined ||
      item.reardrtype === undefined ||
      item.reardrtype[0] == null
    ) {
      reardrtype = Doortype;
    } else {
      reardrtype = item['reardrtype'];
    }
    let rgtdrtype = [];
    if (
      item === undefined ||
      item.rgtdrtype === undefined ||
      item.rgtdrtype[0] == null
    ) {
      rgtdrtype = Doortype;
    } else {
      rgtdrtype = item['rgtdrtype'];
    }
    let heavytrl = [];
    if (
      item === undefined ||
      item.heavytrl === undefined ||
      item.heavytrl[0] == null
    ) {
      heavytrl = ['True', 'False'];
    } else {
      heavytrl = item['heavytrl'];
    }
    let heavyeqp = [];
    if (
      item === undefined ||
      item.heavyeqp === undefined ||
      item.heavyeqp[0] == null
    ) {
      heavyeqp = ['True', 'False'];
    } else {
      heavyeqp = item['heavyeqp'];
    }
    let trlmatoth = [];
    if (
      item === undefined ||
      item.trlmatoth === undefined ||
      item.trlmatoth[0] == null
    ) {
      trlmatoth = TrailerMatOther;
    } else {
      trlmatoth = item['trlmatoth'];
    }
    let trlmat = [];
    if (
      item === undefined ||
      item.trlmat === undefined ||
      item.trlmat[0] == null
    ) {
      trlmat = TrailerMat;
    } else {
      trlmat = item['trlmat'];
    }
    let trlaxloth = [];
    if (
      item === undefined ||
      item.trlaxloth === undefined ||
      item.trlaxloth[0] == null
    ) {
      trlaxloth = TrailerAxlOther;
    } else {
      trlaxloth = item['trlaxloth'];
    }
    this.setState(prevState => ({
      ...prevState,
      optionItems: {
        axles: axles.map(axle => ({
          value: axle,
          label: axle,
        })),
        fifthWheelType: fifthWheelType.map(fifthWheelType => ({
          value: fifthWheelType,
          label: fifthWheelType,
        })),
        EngineBrake: EngineBrake.map(status => ({
          value: status,
          label: status,
        })),
        Aeropack: aeroPack.map(pack => ({
          value: pack,
          label: pack,
        })),
        suspOther: suspOther.map(value => ({
          value: value,
          label: value,
        })),
        Trlrdoortp: Trlrdoortp.map(type => ({
          value: type,
          label: type,
        })),
        Fueltank: Fueltank.map(type => ({
          value: type,
          label: type,
        })),
        Heavytrk: Heavytrk.map(status => ({
          value: status,
          label: status,
        })),
        mirrorheat: mirrorheat.map(status => ({
          value: status,
          label: status,
        })),
        mirrorpwr: mirrorpwr.map(type => ({
          value: type,
          label: type,
        })),
        windowpwr: windowpwr.map(type => ({
          value: type,
          label: type,
        })),
        liftgate: liftgate.map(type => ({
          value: type,
          label: type,
        })),
        lftdrtype: lftdrtype.map(type => ({
          value: type,
          label: type,
        })),
        reardrtype: reardrtype.map(type => ({
          value: type,
          label: type,
        })),
        rgtdrtype: rgtdrtype.map(type => ({
          value: type,
          label: type,
        })),
        heavytrl: heavytrl.map(type => ({
          value: type,
          label: type,
        })),
        heavyeqp: heavyeqp.map(type => ({
          value: type,
          label: type,
        })),
        trlmatoth: trlmatoth.map(type => ({
          value: type,
          label: type,
        })),
        trlmat: trlmat.map(type => ({
          value: type,
          label: type,
        })),
        trlaxloth: trlaxloth.map(type => ({
          value: type,
          label: type,
        })),
      },
    }));
  }

  getDefaultValue(property) {
    const { item } = this.props;
    let existingValue = item && item[property];
    if (existingValue) {
      if (existingValue && existingValue === true) {
        return {
          value: 'True',
          label: 'True',
        };
      } else if (existingValue && existingValue === false) {
        return {
          value: 'False',
          label: 'False',
        };
      } else {
        return {
          value: existingValue,
          label: existingValue,
        };
      }
    } else {
      return null;
    }
  }

  setInputValue(property, value) {
    const { heavyTrucks } = this.state;
    this.props.updateItem({ [property]: value });
    this.setState({
      heavyTrucks: {
        ...heavyTrucks,
        [property]: value,
      },
    });
  }

  render() {
    const { heavyTrucks, optionItems } = this.state;
    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header">
              <div>Heavy Truck</div>
            </div>
            <div className="form-body-container">
              <HeavyTruckForm
                heavyTrucks={heavyTrucks}
                optionItems={optionItems}
                setInputValue={(property, value) =>
                  this.setInputValue(property, value)
                }
                getDefaultValue={property => this.getDefaultValue(property)}
              />
            </div>
            <FormFooter currentSection="s9" />
          </div>
        </div>
      </Layout>
    );
  }
}

HeavyTruck.propTypes = {
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { variant, vin, item, coreSections } = state.core;
  return { variant, vin, item, coreSections };
};

export default connect(
  mapStateToProps,
  { updateItem }
)(HeavyTruck);
