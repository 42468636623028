import React, { Component } from 'react';
import { connect } from 'react-redux';

class CrewCabPickup4DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M44.4,332.4c0,0.5-0.1,1.1-0.1,1.6l2.3,12.1c-1.2-0.1-1.6,0.5-1.4,1.9c0.1,0.3,0.2,0.8,0.3,1.3
	c1.2,4.5,2.7,7.1,4.5,7.8l6.7,4.3l1-1.9v-2.8l1.6,0.8c1.4-2,3-2.8,4.6-2.5h3.5c0.4,0.9,1.9,1.3,4.4,1.6l0.8-1l12.7,2l3.5,2.7
	c11.8,1.5,24.6,2.3,38.4,2.1l1.5,2.5h4.9v-2.5c-0.1-17.8,1.4-31.6,4.5-41.1c3.9-12.7,11.5-20.6,22.6-23.6c1.3-0.3,2.6-0.7,4-0.9
	c20.1-1.5,37.3-1.5,51.6,0c0.8,0.1,1.5,0.2,2.2,0.2c3,0.4,5.6,1,8,1.8c6.9,2.2,11.7,6,14.4,11.4c0.4,0.8,0.9,1.4,1.2,2.3
	c5.2,10.4,8.2,26.8,9,49.3c0.5,1.6,1.8,2.4,3.6,2.5h361.6c0.1-1,0.2-2.1,0.3-3.1c0.1-0.8,0.2-1.6,0.3-2.4
	c2.8-22.5,6.5-39.1,10.9-49.9c0.2-0.5,0.5-1.2,0.8-1.8c3.6-7.8,12.6-12,26.8-12.7h2.7c20-0.1,36.6,1,49.9,3.3
	c7,1.1,12.5,4.8,16.2,11.3c0.5,1,1.1,2.1,1.6,3.2c0.8,1.8,1.5,3.7,2.1,5.8c4.4,9.6,7.4,24.8,9.4,45.3c0.1,1.5,0.3,3.2,0.4,4.8
	c0.1,1.8,0.2,3.6,0.4,5.5c15.5,0.5,27.1,1.6,34.9,3.2c3.4,1.4,6.7,2.2,10,2c3.8-0.4,6.3-1.6,7.4-3.7c3.8-6.6,6.2-13.1,7.2-19.7
	l1.2-1.3c0.5-1.3,0-2.4-1.5-3.4l0.5-2.3c0.3-1.5-0.1-2.5-1.5-3.1l0.9-12.9c1.3,0.1,2.1-0.4,2.4-1.4c0-3.4-0.4-5.5-1.4-6.1
	c-1.4-0.9-4-1.9-7.8-2.7c-0.1-0.8-0.2-1.4-0.5-2c0.4-1.5,0.3-6.1-0.7-6.7l1.6-13.6h0.7c0.9-0.5,0.9-1.2,0.1-1.8v-0.2
	c0.1-3.5-0.1-7.1-0.7-10.7c0-0.2-0.1-0.9-0.3-2h-1.3l-0.9-7.5c1.3-0.7,1.3-1.3,0-2c-0.3-3-1.5-4.6-3.7-5v-0.1
	c-2.1-5.8-6-9.8-11.9-12c-4.5-1.5-11.3-3.2-20.7-4.8c-40.8-7.4-73.9-10.2-99.4-8.2c-5.1,0.3-9.7,1.1-14,2.2
	c-0.3,0.1-0.5,0.1-0.9,0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.4,0.4-0.7,0.9s-0.5,1.2-0.8,2.1c-1.8,0.9-3.6,1.2-5.4,1.1h-0.3
	c-13.1-0.2-23.1-3.3-30-9.2c-18.8-17.9-38-32.6-57.5-43.7l9.4-4.9l-1.3-0.7c-8.4-6.7-30.8-10.4-67.2-10.8l-30.4-0.5
	c-2.8-0.9-6.7-1.2-11.6-1.1H375c-15.4-0.3-26.2,1-32.3,3.8c-4.2,2.5-6.3,6.3-6.5,11.6c-5,15.7-8.2,34.8-9.4,57.2
	c-1.4-2.1-5-3.2-10.8-3.3l-248.9-1c-0.5-0.2-1.1-0.2-1.5,0c-2.2,0.3-3.6,2.3-4.2,6c-1.6,6.5-2.5,14.5-2.7,24.4
	c-0.1,6.7,0,14.1,0.4,22.4l3.8,24.9H49.3c-2.4,0.1-3.9,1.3-4.6,3.6C44.5,331.3,44.4,331.9,44.4,332.4L44.4,332.4z"
          />
          <path
            fill="#5E5E5E"
            d="M787,272.3c-0.7-0.8-1.4-1.3-2.4-1.5v-0.1h-7.5c-1.6,0-3,0.3-3.9,0.9c-1.7,1-2.5,2.7-2.2,5.1v0.5
	c1.5,4.8,2.6,9.7,3.3,14.8c0.1,0.7,0.2,1.4,0.2,2.1c0.7,6,0.5,12.1-0.1,18.4v2.4c6.2,0.9,10.6,2,13.2,3.3c0.3,0.1,0.5,0.2,0.8,0.4
	c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.7,0.9,0.9,1.3v-0.1c0.2-0.8,0.3-2.3,0.2-3.7c-0.1-1.4-0.3-2.6-0.9-3l1.6-13.6h0.7
	c0.9-0.5,0.9-1.2,0.1-1.8V298c0.2-4.4-0.2-8.4-1-12.7h-1.3l-0.9-7.5c1.3-0.7,1.3-1.3,0-2C788.1,274.4,787.7,273.1,787,272.3
	L787,272.3z M791.3,298.1C789.3,278.6,791.8,298.1,791.3,298.1z M787.1,272.5l0.1,0.1L787.1,272.5z"
          />
          <path
            fill="#EAEAEA"
            d="M63.2,314.5l18.5-0.3c4.3,0.1,6.3-2.3,6.2-7.2l-1-27.1c0.2-2.3-0.2-4.4-1.5-6.1c-1.8-2.4-5.1-4.2-9.9-5.4
	L61.9,266C61.4,276.8,61.9,292.9,63.2,314.5L63.2,314.5z M479,198c-6.2,0.3-9.4,3.6-9.7,9.7l-1,45v0.1c-0.1,1.3,0.3,2.4,1.3,3.1
	c1.3,0.9,3.3,1.2,5.9,1.3l85.2,1.8c-0.8-0.9-1.3-1.9-1.5-3.1l0.1-18.1v-1c0.1-2.1,1.3-3.3,3.4-3.6h4.2c0.9,0.1,1.6,0.2,2.4,0.3
	c-9.6-8.7-20-16.6-31.2-23.5c-6.6-4.2-15.1-7.2-25.7-9.1c-3.6-0.7-7.4-1.1-11.6-1.5L479,198L479,198z M359.5,206.2
	c-4,14.4-5.7,26.4-5.1,35.9c0.3,7.4,5,11.7,14,12.8l69,1.2h0.1c2.4,0.2,4.2-0.2,5.4-1.2c1.2-1.1,1.9-3,2-5.4l2.5-43.6
	c0-6.1-3.3-9.3-10.1-9.6H376C367.6,196.3,362.1,199.7,359.5,206.2L359.5,206.2z"
          />
          <path
            fill="#646464"
            d="M138,321.4c-3.1,9.5-4.6,23.3-4.5,41.1l10.8,0.2c0,1.1,0.1,2.2,0.2,3.2c0,0.5,0.1,1,0.2,1.5
	c1.4,10.5,6.1,19.7,13.9,27.4c9.6,9.6,21.1,14.3,34.7,14.3c12.1,0,22.6-3.8,31.5-11.5c1.1-0.9,2.2-1.9,3.2-3
	c5.2-5.2,9.1-11,11.4-17.4c1.2-3.2,2.1-6.6,2.5-10.1c0.2-1.8,0.4-3.6,0.4-5.5h8.6c-0.8-22.5-3.7-38.9-9-49.3
	c-0.4-0.8-0.8-1.5-1.2-2.3c-2.7-5.4-7.7-9.2-14.4-11.4c-2.4-0.8-5-1.4-8-1.8c-0.8-0.1-1.4-0.2-2.2-0.2c-14.2-1.5-31.4-1.5-51.6,0
	c-1.4,0.2-2.7,0.5-4,0.9C149.4,300.8,141.8,308.6,138,321.4L138,321.4z M213.8,335c0.9,0.7,1.6,1.4,2.5,2.2c6.3,6.3,9.5,14,9.5,22.9
	c0,2-0.1,3.8-0.4,5.7c-0.1,0.5-0.2,1-0.2,1.5c-1.3,5.9-4.2,11.2-8.7,15.6c-6.3,6.3-14,9.5-22.9,9.5c-7.7,0-14.4-2.3-20.1-7
	c-0.9-0.8-1.9-1.6-2.7-2.5c-4.6-4.6-7.4-9.7-8.7-15.6c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.9-0.4-3.7-0.4-5.7c0-9,3.2-16.6,9.5-22.9
	s14-9.5,22.9-9.5C201.1,327.8,208,330.2,213.8,335L213.8,335z"
          />
          <path
            fill="#EAEAEA"
            d="M182.2,357.7c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6v0.2h17.3V360c0-0.7,0.2-1.2,0.7-1.6
	c0.4-0.4,1-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7s0.7,1,0.7,1.6v0.2h10.9l1.8-0.9c1.2-0.7,2.1-0.3,2.4,0.9h4.3c0-9-3.2-16.6-9.5-22.9
	c-0.8-0.8-1.6-1.5-2.5-2.2c-5.8-4.8-12.7-7.2-20.4-7.2c-9,0-16.6,3.2-22.9,9.5c-6.3,6.3-9.5,14-9.5,22.9h4.2V360
	c0.4-1,1.1-1.1,2.3-0.5l1.8,0.9H180v-0.2c0-0.7,0.2-1.2,0.7-1.6C181,357.9,181.5,357.7,182.2,357.7L182.2,357.7z M173.1,338.8
	c1.5-1.4,3.1-2.6,4.7-3.7c4.6-2.8,9.7-4.3,15.4-4.3c2.5,0,5,0.3,7.3,0.9l-0.1,0.1h-0.1c-2.3-0.5-4.7-0.9-7.2-0.9s-4.8,0.2-7.1,0.9
	h-0.1c-2.8,0.7-5.6,1.7-8,3.3C176.2,336.1,174.6,337.3,173.1,338.8c-0.2,0.2-0.5,0.5-0.8,0.8l-0.7,0.7l-1.8,2
	c0.5-0.8,1.2-1.4,1.9-2.1l0.5-0.5l0.1-0.1L173.1,338.8L173.1,338.8z M173.4,340.3c2.2-2.2,4.5-3.8,6.9-5.2c1.2-0.7,2.5-1.2,3.8-1.6
	c1.8-0.2,2.6,0.3,2.5,1.6v0.4l-1.9,10.4c0,0-0.1,0.3-0.4,0.9c-0.2,0.5-1.1,1-2.4,1.2l-10.6-1.3c-1.4-0.1-2-0.9-1.4-2.2l0.1-0.1
	c0.9-1.3,2-2.5,3.2-3.8L173.4,340.3L173.4,340.3z M190.8,349c0-0.7,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7s1.2,0.2,1.6,0.7
	s0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7C191,350.2,190.8,349.7,190.8,349L190.8,349z M187,350.6
	c0.4,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6s1-0.7,1.6-0.7
	S186.5,350.1,187,350.6z M164.3,354.5c0.1-0.5,0.2-1.1,0.3-1.6l0.1-0.1c-0.1,0.3-0.2,0.8-0.3,1.1L164.3,354.5z M200,335
	c-0.1-1.2,0.5-1.7,2.1-1.7c1.4,0.4,2.8,1.1,4.3,1.7c2.5,1.3,4.8,3.1,7,5.2c1.2,1.2,2.3,2.5,3.3,3.8c0.7,1.5,0.2,2.4-1.3,2.5
	l-10.6,1.2c-1.1-0.2-2-0.9-2.6-2l-1.9-10.5C200.1,335.4,200.1,335.1,200,335L200,335z M202.5,350.6c0.4,0.4,0.7,1,0.7,1.6
	s-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7
	S202.1,350.1,202.5,350.6L202.5,350.6z"
          />
          <path
            fill="#EAEAEA"
            d="M205.7,361.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-0.9-0.7-1.4h-17.3c0,0.5-0.2,1-0.7,1.4
	c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-0.9-0.7-1.4h-10.7l7.9,4c0.4,0.4,0.8,1,0.9,1.6c0.1,0.3,0.1,0.7,0.1,1.1
	l-4.7,9.6c0,0.1-0.1,0.1-0.1,0.2c-0.5,1-1.2,1.2-2,0.8c-2.5-3.1-4.2-6.5-5.1-10.3c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.9-0.5-3.7-0.5-5.7
	h-4.2c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.3,1.5c1.3,5.9,4.2,11.2,8.7,15.6c0.9,0.9,1.8,1.6,2.7,2.5c5.8,4.7,12.5,7,20.1,7
	c9,0,16.5-3.2,22.9-9.5c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.3-1.5c0.3-1.9,0.4-3.7,0.4-5.7h-4.3c0,0.1,0,0.1,0.1,0.2v0.2
	c0,1.9-0.2,3.6-0.5,5.2c-0.1,0.5-0.2,1-0.3,1.5c-0.9,3.6-2.5,6.9-4.9,10c-1.1,0.9-1.9,0.7-2.5-0.7l-4.6-9.6
	c-0.1-0.3-0.1-0.8-0.1-1.1c0.1-0.7,0.4-1.2,1.1-1.9l7.7-3.9h-10.9C206.3,360.7,206.1,361.2,205.7,361.6L205.7,361.6z M199.2,366.1
	l0.1-0.1c0.4-0.3,0.9-0.5,1.4-0.5s1,0.2,1.4,0.5c0.1,0,0.1,0.1,0.2,0.2c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6
	c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6v-0.3C198.6,366.8,198.9,366.4,199.2,366.1L199.2,366.1z
	 M194.7,372.5c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7
	s1.2,0.2,1.6,0.7s0.7,1,0.7,1.6S195.2,372.1,194.7,372.5z M194.6,376.7l7.8,7.4c1.1,1.1,1,2.1-0.4,3c-2.7,0.9-5.6,1.3-8.6,1.3
	c-3.2,0-6.1-0.4-8.9-1.3c-1.4-0.9-1.5-1.9-0.4-2.8l7.8-7.4c0.7-0.3,1.2-0.5,1.6-0.4C193.9,376.4,194.3,376.5,194.6,376.7
	L194.6,376.7z M222.2,366C222.2,365.9,222.2,365.9,222.2,366l-0.1,0.8c0,0.2-0.1,0.5-0.2,0.8c0,0.1,0,0.2-0.1,0.3v-0.3
	C222,367,222.1,366.4,222.2,366L222.2,366z M183.9,366c0.4-0.3,0.9-0.5,1.4-0.5s1.1,0.2,1.4,0.5c0.1,0,0.1,0.1,0.2,0.1
	c0.3,0.3,0.5,0.8,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6v-0.3
	c0.1-0.5,0.3-1,0.7-1.3C183.8,366,183.8,366,183.9,366L183.9,366z"
          />
          <path
            fill="#646464"
            d="M201.9,345.9c0.7,1.1,1.5,1.8,2.6,2l10.6-1.2c1.5-0.2,2-1,1.3-2.5c-1-1.3-2-2.6-3.3-3.8
	c-2.2-2.2-4.5-3.8-7-5.2c-1.4-0.8-2.7-1.3-4.3-1.7c-1.4,0-2.2,0.5-2.1,1.7c0,0.1,0,0.2,0.1,0.4L201.9,345.9L201.9,345.9z M180.3,335
	c-2.5,1.3-4.8,3.1-6.9,5.2l-0.2,0.2c-1.2,1.2-2.2,2.5-3.2,3.8l-0.1,0.1c-0.4,1.3,0,2,1.4,2.2l10.6,1.3c1.3-0.2,2.1-0.7,2.4-1.2
	c0.2-0.5,0.4-0.9,0.4-0.9l1.9-10.4V335c0.1-1.3-0.8-1.9-2.5-1.6C182.8,333.8,181.6,334.4,180.3,335L180.3,335z M167.4,359.3
	c-1.1-0.5-1.9-0.4-2.3,0.5v0.3c0,2,0.2,3.9,0.5,5.7c0.1,0.5,0.2,1,0.3,1.5c1,3.7,2.6,7.1,5.1,10.3c0.9,0.4,1.5,0.1,2-0.8
	c0-0.1,0.1-0.1,0.1-0.2l4.7-9.6c0-0.4,0-0.8-0.1-1.1c-0.1-0.8-0.4-1.3-0.9-1.6L167.4,359.3L167.4,359.3z M184,384.1
	c-1.1,1.1-1,2,0.4,2.8c2.7,0.9,5.7,1.3,8.9,1.3c3.1,0,6-0.4,8.6-1.3c1.4-0.9,1.6-1.9,0.4-3l-7.8-7.4c-0.3-0.2-0.8-0.3-1.2-0.4
	s-1,0.1-1.6,0.4L184,384.1L184,384.1z M208.6,367l4.6,9.6c0.7,1.3,1.4,1.5,2.5,0.7c2.4-3.1,3.9-6.3,4.9-10c0.1-0.5,0.2-1,0.3-1.5
	c0.3-1.6,0.5-3.4,0.5-5.2v-0.2c-0.3-1.4-1.1-1.9-2.4-1.1l-9.4,4.8c-0.7,0.5-1,1.2-1.1,1.9C208.5,366.3,208.5,366.6,208.6,367
	L208.6,367z"
          />
          <path
            fill="#EAEAEA"
            d="M542.7,199.2c19.5,11.2,38.7,25.8,57.5,43.7c6.9,6,16.8,9.1,30,9.2h0.3c1.8,0.1,3.6-0.3,5.4-1.1
	c0.2-0.9,0.5-1.5,0.8-2.1c-35.9-25.9-64.1-44.2-84.6-54.7L542.7,199.2L542.7,199.2z"
          />
          <path
            fill="#646464"
            d="M657.3,294.7h-2.1c-14.2,0.7-23.2,4.9-26.8,12.7c-0.2,0.5-0.5,1.1-0.8,1.8c-4.5,10.7-8.1,27.3-10.9,49.9
	c-0.1,0.8-0.2,1.5-0.3,2.4l9.4,0.3c0,1.4,0.1,2.8,0.2,4.2c0,0.5,0.1,1,0.2,1.5c1.4,10.5,6.1,19.7,13.9,27.4
	c9.6,9.6,21.1,14.3,34.7,14.3c12.1,0,22.6-3.8,31.5-11.5c1.1-0.9,2.2-1.9,3.2-3c5.2-5.2,9.1-11,11.4-17.4c1.2-3.2,2.1-6.6,2.5-10.1
	V367l14,1.1c-0.1-1.6-0.2-3.3-0.4-4.8c-2-20.4-5-35.5-9.4-45.3c-0.5-2.1-1.3-4-2.1-5.8c-0.5-1.1-1.1-2.2-1.6-3.2
	c-3.7-6.3-9.2-10.2-16.2-11.3c-13.2-2.3-29.7-3.4-49.9-3.3L657.3,294.7L657.3,294.7z M695.4,335c0.9,0.7,1.6,1.4,2.5,2.2
	c6.3,6.3,9.5,14,9.5,22.9c0,2-0.1,3.8-0.4,5.7c-0.1,0.5-0.2,1-0.2,1.5c-1.3,5.9-4.2,11.2-8.7,15.6c-6.3,6.3-14,9.5-23,9.5
	c-7.7,0-14.4-2.3-20.1-7c-0.9-0.8-1.9-1.6-2.7-2.5c-4.6-4.6-7.4-9.7-8.7-15.6c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.9-0.4-3.7-0.4-5.7
	c0-9,3.2-16.6,9.5-22.9s14-9.5,22.9-9.5C682.8,327.8,689.6,330.2,695.4,335L695.4,335z"
          />
          <path
            fill="#EAEAEA"
            d="M664.7,357.8c0.2,0.1,0.5,0.2,0.8,0.5c0.4,0.4,0.7,1,0.7,1.6v0.2h17.3V360c0-0.7,0.2-1.2,0.7-1.6
	c0.2-0.2,0.4-0.3,0.8-0.5c0.2-0.1,0.5-0.2,0.9-0.2c0.7,0,1.2,0.2,1.6,0.7s0.7,1,0.7,1.6v0.2h10.9l1.7-0.9c1.2-0.7,2-0.3,2.4,0.9h4.3
	c0-9-3.2-16.6-9.5-23c-0.8-0.8-1.6-1.5-2.5-2.2c-5.8-4.8-12.7-7.2-20.4-7.2c-9,0-16.5,3.2-22.9,9.5s-9.5,14-9.5,23h4.2V360
	c0.4-1,1.1-1.1,2.3-0.5l1.8,0.9h10.7V360c0-0.7,0.2-1.2,0.7-1.6s1-0.7,1.6-0.7C664.1,357.7,664.4,357.7,664.7,357.8L664.7,357.8z
	 M654,339.5l-0.7,0.7l-1.8,2c0.5-0.8,1.2-1.4,1.9-2.1L654,339.5c0-0.1,0.1-0.1,0.1-0.1l0.7-0.7c1.5-1.4,3.1-2.6,4.7-3.7
	c4.6-2.8,9.7-4.3,15.4-4.3c2.5,0,5,0.3,7.3,0.9l-0.1,0.1H682c-2.3-0.5-4.7-0.9-7.2-0.9s-4.8,0.2-7.1,0.9h-0.1
	c-2.8,0.7-5.5,1.7-8,3.3c-1.6,1.1-3.3,2.3-4.8,3.7C654.5,339,654.3,339.3,654,339.5L654,339.5z M655,340.3c2.2-2.2,4.5-3.8,6.9-5.2
	c1.2-0.7,2.5-1.2,3.8-1.6c1.7-0.2,2.6,0.3,2.5,1.6v0.4l-1.9,10.4c0,0-0.1,0.3-0.4,0.9c-0.2,0.5-1.1,1-2.4,1.2l-10.6-1.3
	c-1.4-0.1-2-0.9-1.4-2.2l0.1-0.1c0.9-1.3,2-2.5,3.2-3.8L655,340.3L655,340.3z M666.1,350.1c0.2-0.1,0.5-0.2,0.9-0.2
	c0.7,0,1.2,0.2,1.6,0.7c0.2,0.2,0.4,0.4,0.5,0.8c0.1,0.2,0.2,0.5,0.2,0.9c0,0.7-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7
	c-0.3,0-0.7,0-0.9-0.2c-0.2-0.1-0.5-0.3-0.8-0.5c-0.4-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6C665.6,350.3,665.9,350.2,666.1,350.1z
	 M673.1,350.7c-0.2-0.2-0.4-0.4-0.5-0.8c-0.1-0.2-0.1-0.5-0.1-0.9s0-0.7,0.1-0.9s0.3-0.5,0.5-0.8c0.4-0.4,1-0.7,1.6-0.7
	s1.2,0.2,1.6,0.7s0.7,1,0.7,1.6c0,0.3,0,0.7-0.2,0.9c-0.1,0.3-0.2,0.5-0.5,0.8c-0.4,0.4-1,0.7-1.6,0.7
	C674.1,351.3,673.5,351.1,673.1,350.7L673.1,350.7z M646,354.5c0.1-0.5,0.2-1.1,0.3-1.6l0.1-0.1C646.3,353.3,646.2,353.8,646,354.5
	L646,354.5z M681.7,335c-0.1-1.2,0.5-1.7,2.1-1.7c1.4,0.4,2.8,1.1,4.3,1.7c2.5,1.3,4.8,3.1,7,5.2c1.2,1.2,2.3,2.5,3.3,3.8
	c0.7,1.5,0.2,2.4-1.3,2.5l-10.6,1.2c-1.1-0.2-2-0.9-2.6-2l-1.9-10.5C681.7,335.4,681.7,335.1,681.7,335L681.7,335z M682.5,349.9
	c0.3,0,0.5,0,0.9,0.2c0.2,0.1,0.5,0.2,0.8,0.5c0.4,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6c-0.2,0.2-0.4,0.4-0.8,0.5
	c-0.3,0.1-0.5,0.1-0.9,0.1c-0.7,0-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6c0-0.3,0-0.5,0.1-0.9c0.1-0.2,0.3-0.5,0.5-0.8
	C681.4,350.1,681.9,349.9,682.5,349.9L682.5,349.9z"
          />
          <path
            fill="#EAEAEA"
            d="M687.4,361.6c-0.4,0.4-1,0.7-1.6,0.7c-0.3,0-0.7,0-0.9-0.1c-0.3-0.1-0.5-0.3-0.8-0.5
	c-0.4-0.4-0.7-0.9-0.7-1.4h-17.3c0,0.5-0.2,1-0.7,1.4c-0.2,0.2-0.4,0.4-0.8,0.5s-0.5,0.1-0.9,0.1c-0.7,0-1.2-0.2-1.6-0.7
	s-0.7-0.9-0.7-1.4h-10.7l7.9,4c0.4,0.4,0.8,1,0.9,1.6c0.1,0.3,0.1,0.7,0.1,1.1l-4.7,9.6c0,0.1-0.1,0.1-0.1,0.2c-0.5,1-1.2,1.2-2,0.8
	c-2.5-3.1-4.2-6.5-5.1-10.3c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.9-0.5-3.7-0.5-5.7h-4.2c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.3,1.5
	c1.3,5.9,4.2,11.2,8.7,15.6c0.9,0.9,1.8,1.6,2.7,2.5c5.8,4.7,12.5,7,20.1,7c9,0,16.5-3.2,23-9.5c4.6-4.6,7.4-9.7,8.7-15.6
	c0.1-0.5,0.2-1,0.2-1.5c0.3-1.9,0.4-3.7,0.4-5.7h-4.3c0,0.1,0,0.1,0.1,0.2v0.2c0,1.9-0.2,3.6-0.5,5.2c-0.1,0.5-0.2,1-0.3,1.5
	c-0.9,3.6-2.5,6.9-4.9,10c-1.1,0.9-1.9,0.7-2.5-0.7l-4.6-9.6c-0.1-0.3-0.1-0.8-0.1-1.1c0.1-0.7,0.4-1.2,1.1-1.9l7.7-3.9H688
	C687.9,360.7,687.7,361.2,687.4,361.6L687.4,361.6z M680.8,366.1l0.1-0.1c0.4-0.3,0.9-0.5,1.4-0.5c0.2,0,0.5,0,0.8,0.1l0.7,0.3
	c0.1,0,0.1,0.1,0.2,0.2c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.7-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7
	c-0.2-0.2-0.4-0.4-0.5-0.8c-0.1-0.2-0.2-0.5-0.2-0.9v-0.3C680.3,366.8,680.5,366.4,680.8,366.1L680.8,366.1z M676.4,372.5
	c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.3,0-0.7,0.1-0.9s0.3-0.5,0.5-0.8c0.4-0.4,1-0.7,1.6-0.7
	s1.2,0.2,1.6,0.7c0.2,0.2,0.3,0.4,0.5,0.8c0.1,0.2,0.2,0.5,0.2,0.9C677.1,371.6,676.8,372.1,676.4,372.5L676.4,372.5z M676.4,376.7
	l7.8,7.4c1.1,1.1,1,2.1-0.4,3c-2.7,0.9-5.6,1.3-8.6,1.3c-3.2,0-6.1-0.4-8.9-1.3c-1.4-0.9-1.5-1.9-0.4-2.8l7.7-7.4
	c0.7-0.3,1.2-0.5,1.6-0.4C675.6,376.4,676,376.5,676.4,376.7L676.4,376.7z M703.8,366c0-0.2,0.1-0.4,0.1-0.8l-0.1,0.9
	c-0.1,0.4-0.2,0.9-0.3,1.4c0,0.1,0,0.2-0.1,0.3v-0.3C703.7,367,703.8,366.4,703.8,366L703.8,366z M696.3,380.1
	c0.3-0.1,0.7-0.3,1-0.5l-0.8,0.9L696.3,380.1L696.3,380.1z M665.5,366c0.2-0.2,0.4-0.3,0.7-0.3c0.2-0.1,0.5-0.1,0.8-0.1
	c0.5,0,1.1,0.2,1.4,0.5c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.5,0.8,0.7,1.3v0.3c0,0.3,0,0.7-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.8
	c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6v-0.3c0.1-0.5,0.3-1,0.7-1.3C665.4,366,665.5,366,665.5,366
	L665.5,366z"
          />
          <path
            fill="#646464"
            d="M681.8,335.5l1.9,10.5c0.7,1.1,1.5,1.8,2.6,2l10.6-1.2c1.5-0.2,2-1,1.3-2.5c-1-1.3-2-2.6-3.3-3.8
	c-2.2-2.2-4.5-3.8-7-5.2c-1.4-0.8-2.7-1.3-4.3-1.8c-1.4,0-2.2,0.5-2.1,1.8C681.7,335.1,681.7,335.4,681.8,335.5L681.8,335.5z
	 M662,335c-2.5,1.3-4.8,3.1-6.9,5.2l-0.2,0.2c-1.2,1.2-2.2,2.5-3.2,3.8l-0.1,0.1c-0.4,1.3,0,2,1.4,2.2l10.6,1.3
	c1.3-0.2,2.1-0.7,2.4-1.2c0.2-0.5,0.4-0.9,0.4-0.9l1.9-10.4V335c0.1-1.3-0.8-1.9-2.5-1.6C664.5,333.8,663.2,334.4,662,335L662,335z
	 M649,359.3c-1.1-0.5-1.9-0.4-2.3,0.5v0.3c0,2,0.2,3.9,0.5,5.7c0.1,0.5,0.2,1,0.3,1.5c1,3.7,2.6,7.1,5.1,10.3c0.9,0.4,1.5,0.1,2-0.8
	c0-0.1,0.1-0.1,0.1-0.2l4.7-9.6c0-0.4,0-0.8-0.1-1.1c-0.1-0.8-0.4-1.3-0.9-1.6L649,359.3L649,359.3z M673.4,376.7l-7.7,7.4
	c-1.1,1.1-1,2,0.4,2.8c2.7,0.9,5.7,1.3,8.9,1.3c3.1,0,6-0.4,8.6-1.3c1.4-0.9,1.6-1.9,0.4-3l-7.8-7.4c-0.3-0.2-0.8-0.3-1.2-0.4
	C674.6,376.1,674.1,376.4,673.4,376.7L673.4,376.7z M703.1,360.4c-0.3-1.4-1.1-1.9-2.4-1.1l-9.4,4.8c-0.7,0.5-1,1.2-1.1,1.9
	c0,0.3,0,0.7,0.1,1.1l4.6,9.6c0.7,1.3,1.4,1.5,2.5,0.7c2.4-3.1,3.9-6.3,4.9-10c0.1-0.5,0.2-1,0.3-1.5c0.3-1.6,0.5-3.4,0.5-5.2
	C703.1,360.6,703.1,360.5,703.1,360.4L703.1,360.4z"
          />
          <path
            fill="#FFFFFF"
            d="M177.9,335c2.5-1.5,5.1-2.6,8-3.3h0.1c2.3-0.5,4.7-0.9,7.1-0.9c2.5,0,4.9,0.2,7.2,0.9h0.1l0.1-0.1
	c-2.3-0.5-4.7-0.9-7.3-0.9C187.5,330.8,182.4,332.2,177.9,335c-1.7,1.1-3.3,2.3-4.8,3.7c-0.2,0.2-0.4,0.4-0.7,0.7l-0.1,0.1l-0.5,0.5
	c-0.7,0.7-1.2,1.3-1.9,2.1l1.8-2l0.7-0.7c0,0,0.5-0.5,0.8-0.8C174.6,337.3,176.3,336.1,177.9,335L177.9,335z M164.3,354.5l0.2-0.8
	c0.1-0.3,0.2-0.8,0.3-1.1l-0.1,0.1C164.5,353.4,164.4,353.9,164.3,354.5z M182.2,362.3c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6
	s-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6C181,362,181.5,362.3,182.2,362.3
	L182.2,362.3z M185.3,354.5c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7
	s-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6S184.7,354.5,185.3,354.5z M190.8,349c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7
	s0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7C191,347.8,190.8,348.4,190.8,349L190.8,349z M200.9,349.9
	c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6C202.1,350.1,201.5,349.9,200.9,349.9L200.9,349.9z M199.2,366.1c-0.3,0.3-0.7,0.9-0.7,1.3
	v0.3c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-0.3c0-0.5-0.3-1-0.7-1.3l-0.2-0.2
	c-0.4-0.3-0.9-0.5-1.4-0.5s-1.1,0.2-1.4,0.5C199.3,366,199.2,366,199.2,366.1L199.2,366.1z M187.6,367.4c-0.1-0.5-0.3-1-0.7-1.3
	c0,0-0.1-0.1-0.2-0.1c-0.4-0.3-0.9-0.5-1.4-0.5s-1.1,0.2-1.4,0.5l-0.2,0.2c-0.3,0.3-0.5,0.9-0.7,1.3v0.3c0,0.7,0.2,1.2,0.7,1.6
	s1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6L187.6,367.4L187.6,367.4z M191.4,369.3c-0.4,0.4-0.7,1-0.7,1.6
	s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7
	S191.9,368.8,191.4,369.3z M204,357.7c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6S204.7,357.7,204,357.7L204,357.7z M222.2,365.9L222.2,365.9z M222.1,366.6
	l0.1-0.7c-0.1,0.4-0.2,1-0.3,1.4v0.3c0-0.1,0-0.2,0.1-0.3C222,367.2,222.1,367,222.1,366.6L222.1,366.6z M659.6,335
	c2.5-1.5,5.1-2.6,8-3.3h0.1c2.3-0.5,4.7-0.9,7.1-0.9c2.5,0,4.9,0.2,7.2,0.9h0.1l0.1-0.1c-2.3-0.5-4.7-0.9-7.3-0.9
	C669.2,330.8,664.1,332.2,659.6,335c-1.8,1.1-3.3,2.3-4.8,3.7c-0.2,0.2-0.4,0.4-0.7,0.7l-0.1,0.1l-0.5,0.5c-0.7,0.7-1.3,1.4-1.9,2.1
	l1.8-2c0.2-0.2,0.4-0.4,0.7-0.7s0.5-0.5,0.8-0.8C656.3,337.3,657.9,336.1,659.6,335L659.6,335z M646.3,352.9
	c-0.1,0.5-0.2,1.1-0.3,1.6c0.1-0.7,0.2-1.2,0.4-1.9L646.3,352.9L646.3,352.9z M664.7,362.1c0.2-0.1,0.5-0.3,0.8-0.5
	c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.2-0.2-0.4-0.3-0.8-0.5c-0.3-0.1-0.5-0.2-0.9-0.2c-0.7,0-1.2,0.2-1.6,0.7s-0.7,1-0.7,1.6
	s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7C664.1,362.3,664.4,362.3,664.7,362.1L664.7,362.1z M672.4,349c0,0.3,0,0.7,0.1,0.9
	c0.1,0.3,0.3,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.2-0.2,0.3-0.4,0.5-0.8c0.1-0.2,0.2-0.5,0.2-0.9
	c0-0.7-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.2,0.2-0.4,0.4-0.5,0.8C672.5,348.4,672.4,348.7,672.4,349L672.4,349z
	 M667,354.5c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6c0-0.3,0-0.5-0.2-0.9c-0.1-0.2-0.2-0.5-0.5-0.8c-0.4-0.4-1-0.7-1.6-0.7
	c-0.3,0-0.7,0-0.9,0.2c-0.2,0.1-0.5,0.2-0.8,0.5c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.2,0.2,0.4,0.3,0.8,0.5L667,354.5
	L667,354.5z M682.5,349.9c-0.7,0-1.2,0.2-1.6,0.7c-0.2,0.2-0.4,0.4-0.5,0.8c-0.1,0.2-0.1,0.5-0.1,0.9c0,0.7,0.2,1.2,0.7,1.6
	s1,0.7,1.6,0.7c0.3,0,0.5,0,0.9-0.1c0.2-0.1,0.5-0.3,0.8-0.5c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.2-0.2-0.4-0.4-0.8-0.5
	C683.1,350,682.8,349.9,682.5,349.9L682.5,349.9z M684.8,357.8c-0.3,0.1-0.5,0.2-0.8,0.5c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6
	c0.2,0.2,0.4,0.4,0.8,0.5c0.2,0.1,0.5,0.1,0.9,0.1c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7
	C685.4,357.7,685.1,357.7,684.8,357.8L684.8,357.8z M680.8,366.1c-0.3,0.3-0.7,0.9-0.7,1.3v0.3c0,0.3,0,0.7,0.2,0.9
	c0.1,0.3,0.3,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6v-0.3c0-0.5-0.3-1-0.7-1.3l-0.2-0.2
	c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2-0.1-0.5-0.1-0.8-0.1C681.8,365.6,681.2,365.8,680.8,366.1C680.9,366,680.9,366,680.8,366.1
	L680.8,366.1z M669.4,367.4c-0.1-0.5-0.3-1-0.7-1.3c0,0-0.1-0.1-0.2-0.1c-0.4-0.3-0.9-0.5-1.4-0.5c-0.2,0-0.5,0-0.8,0.1
	s-0.4,0.2-0.7,0.3l-0.2,0.2c-0.3,0.3-0.5,0.9-0.7,1.3v0.3c0,0.7,0.2,1.2,0.7,1.6s1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7
	c0.2-0.2,0.4-0.4,0.5-0.8c0.1-0.2,0.2-0.5,0.2-0.9C669.4,367.6,669.4,367.5,669.4,367.4L669.4,367.4z M672.6,370
	c-0.1,0.2-0.1,0.5-0.1,0.9c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-0.3,0-0.7-0.2-0.9
	c-0.1-0.2-0.2-0.5-0.5-0.8c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7C672.9,369.5,672.7,369.8,672.6,370L672.6,370z M703.6,367.4
	c0.1-0.4,0.2-0.9,0.3-1.4l0.1-0.9c0,0.2-0.1,0.5-0.1,0.8C703.8,366.4,703.7,367,703.6,367.4c0,0.1,0,0.2-0.1,0.3
	C703.5,367.6,703.6,367.5,703.6,367.4L703.6,367.4z M696.3,380.1l0.1,0.3l0.8-0.9C696.9,379.8,696.6,380,696.3,380.1z"
          />
          <path
            fill="#EAEBEB"
            d="M764,276.2l-13.1-0.9l-7.5-0.5c-1-0.1-1.9-0.1-2.6,0c-1.6,0.3-2.5,1.5-2.8,3.5c-0.3,1.4-0.7,2.8-0.9,4.5
	c-0.3,2.4-0.7,4.9-0.9,7.7l-0.1,2.5l-0.1,1.2c-0.2,4.5-0.1,9.5,0.1,15.1l0.1,1.5l38.2,1.8c0.7-6.2,0.7-12.2,0.1-18.4
	c-0.1-0.8-0.2-1.4-0.2-2.1c-0.7-4.9-1.8-9.8-3.3-14.8v-0.5L764,276.2L764,276.2z"
          />
          <path
            fill="#5E5E5E"
            d="M790.4,323.2c0.2,0.8,2.7,1.3,7.7,1.6c-1.4-0.9-4-1.9-7.8-2.7C790.3,322.5,790.4,322.9,790.4,323.2z"
          />
          <path
            fill="#EAEAEA"
            d="M786.3,342.8c0,0.4,0,0.9-0.1,1.3C786.3,343.6,786.5,343.1,786.3,342.8L786.3,342.8z"
          />
          <path
            fill="#5E5E5E"
            d="M769.1,339.5c-2.4,0-3.7,1.8-4,5.2l-0.5,7.3c-0.2,3.1,0.8,4.6,3.1,4.7h13.2c2.3,0,3.7-1.5,4.2-4.7l1.2-7.4
	v-0.4c0.1-0.4,0.1-0.9,0.1-1.3c0-2.3-1-3.5-3.1-3.5L769.1,339.5L769.1,339.5z M778.4,351.3c-0.2,2.4-1,3.7-2.3,3.8h-7.7
	c-0.5,0-1-0.2-1.3-0.7c-0.2-0.4-0.4-1.1-0.4-1.9v-1.2l0.3-5.9c0.2-2.7,0.9-4.2,2.3-4.2l8.1-0.1h0.4c0.2,0,0.3,0.1,0.5,0.2
	c0.7,0.5,1,1.8,0.8,3.8L778.4,351.3L778.4,351.3z"
          />
          <path
            fill="#5E5E5E"
            d="M754.3,313.4c-4.5-0.1-9.5-0.3-15.3-0.5l-13.3-0.5c0.8,1.8,1.5,3.7,2.1,5.8c4.4,9.6,7.4,24.8,9.4,45.3
	c0.1,1.5,0.3,3.2,0.4,4.8c0.1,1.8,0.3,3.6,0.4,5.5c15.5,0.5,27.1,1.6,34.9,3.2c3.4,1.4,6.7,2.2,10,2c3.8-0.4,6.3-1.6,7.4-3.7
	c3.8-6.6,6.2-13.1,7.2-19.7l1.2-1.3c0.5-1.3,0-2.4-1.5-3.4l0.5-2.3c0.3-1.5-0.1-2.5-1.5-3.1l0.9-12.9c1.3,0.1,2.1-0.4,2.4-1.4
	c0-3.4-0.4-5.5-1.4-6.1c-4.9-0.3-7.4-0.9-7.7-1.6c0-0.3,0-0.8-0.1-1.1c-0.1-0.8-0.2-1.4-0.5-2c-0.2-0.5-0.5-1-0.9-1.3
	c-0.2-0.1-0.3-0.3-0.5-0.4s-0.5-0.3-0.8-0.4c-2.6-1.3-7.1-2.4-13.2-3.3c-1.2-0.2-2.4-0.3-3.6-0.4l-3.9-0.3c-1.4-0.1-2.8-0.2-4.3-0.3
	c-1.3,0-2.5-0.1-3.8-0.1L754.3,313.4L754.3,313.4z"
          />
          <path
            fill="#EAEAEA"
            d="M767,345.4l-0.3,5.9v1.2c0,0.9,0.2,1.4,0.4,1.9c0.3,0.4,0.8,0.7,1.3,0.7h7.7c1.2-0.1,2-1.4,2.3-3.8l0.7-6
	c0.2-2.1,0-3.3-0.8-3.8c-0.1-0.1-0.3-0.2-0.5-0.2h-0.4l-8.1,0.1C767.9,341.4,767.1,342.7,767,345.4L767,345.4z"
          />
          <path
            fill="#5E5E5E"
            d="M542.7,199.2l9.4-4.9c-0.4-0.2-0.9-0.4-1.3-0.7l-9.7,4.7L542.7,199.2L542.7,199.2z M539.4,208
	c-6.9-4.4-15.9-7.5-27-9.4c-3.6-0.7-7.3-1.1-11.4-1.4l-22-1.5h-0.1c-7.7,0.4-11.7,4.5-12.1,12.1l-1,45c-0.2,2.4,0.5,4.2,2.4,5.4
	c1.5,1.1,3.9,1.6,7.3,1.6l98.6,2c4.6-0.1,7.9-1,10-2.8h0.1l0.1-0.1l0.2-0.2c1-1.2,1.8-2.5,2.2-3.7c1.3-3.6,0.1-7.4-3.6-11.5
	C569.9,229.7,555.3,217.8,539.4,208L539.4,208z M582.5,257l-0.1,0.1c-1.8,1.4-4.5,2.2-8.3,2.2h-0.3c-1,0.8-2.3,1.4-3.9,1.9
	c-1.4,0.1-2.6,0.1-3.7,0c-0.9-0.1-1.8-0.2-2.6-0.4c-1.2-0.3-2.2-1-2.8-1.6l-85.2-1.8c-2.6,0-4.7-0.4-5.9-1.3c-1-0.8-1.4-1.8-1.3-3.1
	v-0.1l1-45c0.3-6.1,3.6-9.4,9.6-9.7l21.8,1.5c4.2,0.3,8,0.9,11.6,1.5c10.6,1.9,19.2,4.9,25.7,9.1c11,6.9,21.4,14.7,31.2,23.5
	c2,0.4,3.3,1.3,3.8,2.4c0.2,0.5,0.3,1,0.4,1.5c2.6,2.5,5.1,5,7.7,7.7c3,3.1,3.9,6.1,3.1,9C583.9,255.1,583.3,256.1,582.5,257
	L582.5,257z"
          />
          <path
            fill="#FFFFFF"
            d="M573,236c-0.5-1.2-1.9-2-3.8-2.4c-0.8-0.2-1.5-0.3-2.4-0.3h-4.2c-2.2,0.3-3.3,1.5-3.4,3.6v1l-0.1,18.1
	c0.2,1.3,0.7,2.3,1.5,3.1c0.8,0.8,1.6,1.3,2.8,1.6c0.8,0.2,1.6,0.3,2.6,0.4c1.1,0.1,2.4,0.1,3.7,0c1.6-0.4,3-1.1,3.9-1.9h0.3
	c3.8-0.1,6.6-0.8,8.3-2.2c0.1,0,0.1-0.1,0.1-0.1c0.8-0.9,1.3-1.9,1.6-2.8c0.9-2.8-0.1-5.8-3.1-9c-2.5-2.6-5-5.2-7.7-7.7
	C573.4,237.1,573.1,236.5,573,236L573,236z M473.8,274.6c-2.2,0.1-4.4,0.2-6.5,0.3c-0.7,0-1.3,0.2-1.8,0.8c-0.5,0.5-0.8,1.1-0.8,1.8
	v1.3c0,0.7,0.2,1.3,0.8,1.8c0.4,0.5,1.1,0.8,1.8,0.8c2.1,0.1,4.3,0.2,6.5,0.2h2.4c5.4,0.1,11.1-0.1,17.4-0.4c2-0.1,3.8-0.2,5.9-0.3
	c0.5,0,1-0.2,1.4-0.5c0.3-0.3,0.5-0.9,0.5-1.4v-1.1c0-0.5-0.2-1-0.5-1.4c-0.4-0.3-0.9-0.5-1.4-0.5c-7.4-0.9-15.1-1.2-22.7-1.1
	C475.7,274.6,474.7,274.6,473.8,274.6L473.8,274.6z M360,272.1h-3c-2.2,0.1-4.4,0.2-6.5,0.3c-0.7,0-1.3,0.2-1.8,0.8
	c-0.5,0.5-0.8,1.1-0.8,1.8v1.3c0,0.7,0.2,1.3,0.8,1.8c0.4,0.5,1.1,0.8,1.8,0.8c2.1,0.1,4.3,0.2,6.5,0.2h2.4
	c5.4,0.1,11.2-0.1,17.4-0.4c2-0.1,3.8-0.2,5.9-0.3c0.5,0,1-0.2,1.4-0.5c0.3-0.3,0.5-0.9,0.5-1.4v-1.1c0-0.5-0.2-1-0.5-1.4
	c-0.4-0.3-0.9-0.5-1.4-0.5C375.4,272.3,367.8,271.9,360,272.1L360,272.1z"
          />
          <path
            fill="#5E5E5E"
            d="M447.3,249.6l2.5-43.6c0.1-7.8-4-11.8-12.5-12.2h-61.2c-9.7,0-16,3.8-18.8,11.6v0.1
	c-4.2,14.8-5.9,27-5.2,36.7c0.3,8.9,5.7,13.9,16.3,15.2h0.1l69,1.2c3.2,0.3,5.6-0.3,7.2-1.9C446.2,255.3,447.2,252.9,447.3,249.6
	L447.3,249.6z M437.3,256.1l-69-1.2c-9-1.1-13.6-5.4-14-12.8c-0.5-9.4,1.1-21.4,5.1-35.9c2.5-6.6,8-9.9,16.5-9.9h61.2
	c6.7,0.3,10.1,3.6,10.1,9.6l-2.5,43.6c-0.1,2.4-0.8,4.3-2,5.4C441.5,256,439.9,256.3,437.3,256.1
	C437.5,256.1,437.3,256.1,437.3,256.1L437.3,256.1z M74.1,327.4H49.3c-2.4,0.1-3.9,1.3-4.6,3.6c-0.1,0.4-0.2,1-0.3,1.5
	c0,0.5-0.1,1.1-0.1,1.6l2.3,12.1c-1.2-0.1-1.6,0.5-1.4,1.9c0.1,0.3,0.2,0.8,0.3,1.3c1.2,4.5,2.7,7.1,4.5,7.8l6.7,4.3l1-1.9v-3
	l1.6,0.8c1.3-2,3-2.8,4.6-2.5h12.7c6,0.8,9.1-0.4,9-3.5v-16.6C85.1,329.9,81.2,327.4,74.1,327.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M171.8,340.1c-0.7,0.7-1.2,1.3-1.9,2.1 M170,342.1l1.8-2 M85.4,273.7
	c16.2-0.4,31.7-0.8,46.9-0.9c30.5-0.2,58.8,0.2,85,1.4l47.8,2.6c0.3,0,0.7,0.1,1,0.1c0.8,0.1,1.5,0.2,2.2,0.3c3,0.4,5.6,1,8,1.8
	c6.9,2.2,11.7,6,14.4,11.4c0.4,0.8,0.9,1.4,1.2,2.3c1,2.1,2,4.7,3,8c3.5,12.4,6.1,33.7,8.1,63.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M62,265.8c37.7-4,64.2-6,79.3-6.1c30.5-0.2,58.8,0.2,85,1.4l47.8,2.6
	c0.3,0,0.7,0.1,1,0.1c0.8,0.1,1.5,0.2,2.2,0.3c3,0.4,5.6,1,8,1.8c6.9,2.2,11.7,6,14.4,11.4c0.4,0.8,0.9,1.4,1.2,2.3c1,2.1,2,4.7,3,8
	c3.5,12.4,6.6,38,9.1,76.9 M76.6,354.9h-1.9 M67.5,354.9h7.3 M46.6,346.2l0.8,4 M46.6,346.2c-1.2-0.1-1.6,0.5-1.4,1.9
	c0.1,0.3,0.2,0.8,0.3,1.3c1.2,4.5,2.7,7.1,4.5,7.8l6.7,4.3l1-1.9v-2.8l1.6,0.8c1.3-2,3-2.8,4.6-2.5h3.5 M182.4,387.8
	c-3.3-1.3-6.3-3.3-9.2-5.9c-0.2-0.2-0.5-0.4-0.8-0.8c-0.7-0.7-1.2-1.3-1.8-2 M182.3,387.8c3.4,1.3,7,2,10.8,2c3.9,0,7.5-0.7,10.9-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M193.4,376.3c0.4,0.1,0.9,0.2,1.2,0.4l7.8,7.4c1.1,1.1,1,2.1-0.4,3
	c-2.7,0.9-5.6,1.3-8.6,1.3c-3.2,0-6.1-0.4-8.9-1.3c-1.4-0.9-1.5-1.9-0.4-2.8l7.8-7.4C192.4,376.4,193,376.1,193.4,376.3L193.4,376.3
	z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M204.1,387.7c1.1-0.7,1.6-1.4,1.5-2.1c-0.1-0.4-0.3-1-0.9-1.4l-9.7-9.3
	c-0.4-0.2-1-0.4-1.5-0.5s-1.3,0.1-2.1,0.5l-9.6,9.4c-0.5,0.4-0.9,1-0.9,1.4c-0.2,0.8,0.3,1.5,1.5,2.2 M195.4,370.9
	c0-0.7-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	s1.2-0.2,1.6-0.7C195.2,372.1,195.4,371.6,195.4,370.9L195.4,370.9z M186.7,366c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.5,0.9,0.7,1.3v0.3
	c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6v-0.3c0.1-0.5,0.3-1,0.7-1.3l0.2-0.2
	c0.4-0.3,0.9-0.5,1.4-0.5C185.9,365.4,186.4,365.5,186.7,366L186.7,366z M200.9,365.4c-0.5,0-1.1,0.2-1.4,0.5l-0.1,0.1
	c-0.3,0.3-0.7,0.9-0.7,1.3v0.3c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-0.3
	c0-0.5-0.2-1-0.7-1.3l-0.2-0.2C201.8,365.5,201.4,365.4,200.9,365.4L200.9,365.4z M196.7,353.7c-1.1-0.5-2.2-0.9-3.5-0.9
	s-2.5,0.3-3.5,0.9c-0.7,0.3-1.2,0.8-1.8,1.3c-1.4,1.4-2.2,3.2-2.2,5.2c0,2.1,0.8,3.8,2.2,5.2c0.2,0.2,0.3,0.3,0.5,0.5
	c0.9,0.8,2,1.3,3.1,1.5c0.5,0.1,1.1,0.1,1.5,0.1c0.5,0,1.1,0,1.5-0.1c1.1-0.2,2.2-0.8,3.1-1.5c0.2-0.1,0.3-0.3,0.5-0.5
	c1.4-1.4,2.2-3.2,2.2-5.2s-0.8-3.7-2.2-5.2C197.9,354.5,197.4,354.1,196.7,353.7L196.7,353.7z M198.6,352.2c0-0.7,0.2-1.2,0.7-1.6
	c0.4-0.4,1-0.7,1.6-0.7c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7
	C198.8,353.4,198.6,352.9,198.6,352.2L198.6,352.2z M194.7,347.4c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6
	s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7s0.7-1,0.7-1.6S195.2,347.8,194.7,347.4z M187,353.8c0.4-0.4,0.7-1,0.7-1.6
	s-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7s-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6s1,0.7,1.6,0.7
	C186,354.6,186.5,354.4,187,353.8L187,353.8z M164.7,352.9c-0.1,0.5-0.2,1.1-0.3,1.6 M164.3,354.5l0.1-0.8 M163.8,358.5v1.8
	c0,2,0.1,3.8,0.4,5.6c0.1,0.5,0.2,1,0.3,1.5c1,4.4,3.1,8.3,6,11.8 M163.8,358.5c0.1-1.4,0.2-2.7,0.5-4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M176.9,364.2c0.4,0.4,0.8,1,0.9,1.6c0.1,0.3,0.1,0.7,0.1,1.1l-4.7,9.6
	c0,0.1-0.1,0.1-0.1,0.2c-0.5,1-1.2,1.2-2,0.8c-2.5-3.1-4.2-6.5-5.1-10.3c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.9-0.5-3.7-0.5-5.7v-0.3
	c0.4-1,1.1-1.1,2.3-0.5L176.9,364.2L176.9,364.2z M180.5,358.3c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
	s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7S181,357.9,180.5,358.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M170.6,379.2c1,0.9,1.9,1.2,2.5,0.8c0,0,0.1,0,0.1-0.1
	c0.3-0.2,0.7-0.7,1-1.2l5.9-12v-0.8c0-1.2-0.4-2.1-1.2-2.7l-12-6.2c-0.5-0.3-1.1-0.4-1.5-0.4c-0.8,0.1-1.3,0.8-1.5,2 M164.8,352.6
	c0.7-2.6,1.8-5.1,3.2-7.4 M164.4,353.7c0.1-0.3,0.2-0.8,0.2-1.1 M164.8,352.6l-0.1,0.2 M164.7,352.9c-0.1,0.3-0.1,0.7-0.2,1
	 M170,342.1c-0.8,0.9-1.3,1.9-2,2.8c0,0.1-0.1,0.2-0.1,0.2 M133.4,362.5l10.8,0.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M144.2,362.7c0,1.1,0.1,2.2,0.2,3.2c0,0.5,0.1,1,0.2,1.5
	c1.4,10.5,6.1,19.7,13.9,27.4c9.6,9.6,21.1,14.3,34.7,14.3c12.1,0,22.6-3.8,31.5-11.5c1.1-0.9,2.2-1.9,3.2-3
	c5.2-5.2,9.1-11,11.4-17.4c1.2-3.2,2.1-6.6,2.5-10.1c0.2-1.8,0.4-3.6,0.4-5.5 M67.5,354.9c0.4,0.9,1.9,1.4,4.4,1.6l0.8-1l12.7,2
	l3.5,2.7c11.8,1.5,24.6,2.3,38.4,2.1l1.5,2.5h4.9v-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M251,361.9c-0.8-22.5-3.7-38.9-9-49.3c-0.4-0.8-0.8-1.5-1.2-2.3
	c-2.7-5.4-7.7-9.2-14.4-11.4c-2.4-0.8-5-1.4-8-1.8c-0.8-0.1-1.4-0.2-2.2-0.2c-14.2-1.5-31.4-1.5-51.6,0c-1.4,0.2-2.7,0.5-4,0.9
	c-11.3,3-18.8,10.8-22.6,23.6c-3.1,9.5-4.6,23.3-4.5,41.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242.3,361.9v-1.8c0-6.1-1-11.9-3-17.3c-1-2.7-2.3-5.4-3.8-7.9
	c-1.3-2.2-2.8-4.4-4.7-6.3c-0.9-1.1-1.9-2.2-3-3.2c-1.6-1.6-3.3-3.1-4.9-4.4c-8.5-6.7-18.5-10-29.7-10s-21.2,3.3-29.7,10
	c-1.8,1.3-3.4,2.7-5,4.4c-9.6,9.6-14.3,21.1-14.3,34.7v2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M193.3,327.8c-9,0-16.5,3.2-22.9,9.5c-6.3,6.3-9.5,14-9.5,23
	c0,2,0.1,3.8,0.4,5.7c0.1,0.5,0.2,1,0.3,1.5c1.3,5.9,4.2,11.1,8.7,15.6c0.9,0.9,1.8,1.6,2.7,2.5c5.8,4.7,12.5,7,20.1,7
	c9,0,16.6-3.2,22.9-9.5c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.2-1.5c0.3-1.9,0.4-3.7,0.4-5.7c0-9-3.2-16.6-9.5-23
	c-0.8-0.8-1.6-1.5-2.5-2.2C208,330.2,201.1,327.8,193.3,327.8L193.3,327.8z M171.8,340.2C171.8,340.1,171.8,340.1,171.8,340.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M186.6,335v0.4l-1.9,10.4c0,0-0.1,0.3-0.4,0.9c-0.2,0.5-1.1,1-2.4,1.2
	l-10.6-1.3c-1.4-0.1-2-0.9-1.4-2.2l0.1-0.1c0.9-1.3,2-2.5,3.2-3.8l0.2-0.2c2.2-2.2,4.5-3.8,6.9-5.2c1.2-0.7,2.5-1.2,3.8-1.6
	C185.9,333.3,186.7,333.8,186.6,335L186.6,335z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M167.8,345.2c-0.4,1.1-0.4,1.9,0.1,2.4c0.3,0.3,0.9,0.4,1.5,0.5l13.3,1.6
	c1.6-0.3,2.6-0.8,3-1.5c0.3-0.8,0.5-1.1,0.5-1.1l2.3-13c0.1-0.8,0.1-1.4-0.2-1.9s-1.2-0.9-2.4-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M186.1,331.8C186.1,331.8,186,331.8,186.1,331.8c-3,0.7-5.6,1.9-8.1,3.4
	c-1.6,1.1-3.3,2.3-4.8,3.7c-0.2,0.2-0.5,0.4-0.8,0.8l-0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M171.9,340.1l0.5-0.5l0.1-0.1l0.7-0.7c1.5-1.4,3.1-2.6,4.7-3.7
	c4.6-2.8,9.7-4.3,15.4-4.3c2.5,0,5,0.3,7.3,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M200.4,331.8c-2.3-0.5-4.7-0.9-7.2-0.9s-4.8,0.3-7.1,0.9 M200.6,331.6
	l-0.1,0.1 M200.4,331.8C200.4,331.8,200.5,331.8,200.4,331.8 M200.5,331.8C200.4,331.8,200.4,331.8,200.5,331.8 M200,335
	c0,0.1,0,0.2,0.1,0.4l1.9,10.5c0.7,1.1,1.5,1.8,2.6,2l10.6-1.2c1.5-0.2,2-1,1.3-2.5c-1-1.3-2-2.6-3.3-3.8c-2.2-2.2-4.5-3.8-7-5.2
	c-1.4-0.8-2.7-1.3-4.3-1.8C200.6,333.3,200,333.8,200,335z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M200.4,331.8c-1.1-0.1-1.9,0.1-2.2,0.7c-0.2,0.4-0.3,1-0.1,1.7l2.3,13
	c0.9,1.4,2,2.2,3.3,2.4L217,348c0.8-0.1,1.4-0.3,1.6-0.7c0.4-0.5,0.4-1.4,0-2.5 M62,265.8c-0.4,10.8-0.1,27,1.2,48.5 M63.2,314.5
	c0.2,4,0.5,8.2,0.9,12.6 M44.4,332.4c0,0.5-0.1,1.1-0.1,1.6l2.3,12.1 M44.6,330.9c-0.1,0.4-0.2,1-0.3,1.5 M62.8,327.4H49.3
	c-2.4,0.1-3.9,1.3-4.6,3.6 M76.6,354.9c6,0.8,9.1-0.4,9-3.5v-16.6c-0.5-4.9-4.4-7.4-11.5-7.4H62.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M44.6,330.9l25.8-0.9c2,0,3.4,0.2,4.3,1c1.1,0.7,1.6,1.8,1.5,3.3v0.3
	l0.3,20.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M74.8,354.9v-20.4v-0.2c0-0.9-0.2-1.6-0.9-2c-0.8-0.5-2-0.8-3.5-0.7
	l-26.1,0.9 M63.2,314.5l18.5-0.3c4.3,0.1,6.3-2.3,6.2-7.2l-1-27.1c0.3-2.3-0.2-4.4-1.5-6.1 M85.4,273.7c-1.8-2.4-5.1-4.2-9.9-5.4
	l-13.6-2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M65.4,249.6c-2.2,0.3-3.6,2.3-4.2,6c-2.7,10.8-3.5,26.4-2.3,46.8l3.8,24.9
	 M65.4,249.6c0.4-0.2,1-0.2,1.5,0 M66.9,249.6c-0.5-0.1-1.1-0.1-1.5,0 M65.4,249.6c-1.9,1.1-3.1,6.5-3.5,16.3 M501,197.1
	c4,0.3,7.8,0.9,11.4,1.4c11.2,2,20.2,5,27,9.4c16,9.8,30.5,21.8,43.6,35.5c3.7,3.9,4.9,7.8,3.6,11.5c-0.4,1.2-1.1,2.5-2.2,3.7
	c0,0.1-0.1,0.1-0.2,0.2l-0.1,0.1H584c-2.1,1.9-5.4,2.7-10,2.8l-98.6-2c-3.3,0-5.7-0.7-7.3-1.6c-1.9-1.2-2.6-3.1-2.4-5.4l1-45
	c0.4-7.7,4.5-11.7,12.1-12.1h0.1L501,197.1L501,197.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M569.2,233.6c-9.6-8.7-20-16.6-31.2-23.5c-6.6-4.2-15.1-7.2-25.7-9.1
	c-3.6-0.7-7.4-1.1-11.6-1.5l-21.8-1.5c-6.1,0.3-9.3,3.6-9.6,9.7l-1,45v0.1c-0.1,1.3,0.3,2.4,1.3,3.1c1.3,0.9,3.3,1.2,5.9,1.3
	l85.2,1.8 M449.8,206C449.8,206.1,449.8,206.1,449.8,206l-2.5,43.6c-0.1,3.3-1.1,5.6-2.7,7.1s-4,2.1-7.2,1.9l-69-1.2h-0.1
	c-10.6-1.2-16-6.3-16.3-15.2c-0.5-9.6,1.2-22,5.2-36.7v-0.1c2.8-7.7,9.1-11.5,18.8-11.6h61.2C445.8,194.2,449.9,198.4,449.8,206
	L449.8,206z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M550.9,193.8c-8.4-6.7-30.8-10.4-67.2-10.8l-30.4-0.5
	c-2.8-0.9-6.7-1.2-11.6-1.1h-66.9c-15.5-0.3-26.2,1-32.3,3.8c-4.2,2.5-6.3,6.3-6.5,11.6 M437.2,196.3H376c-8.5,0-14,3.3-16.5,9.9
	c-4,14.4-5.7,26.4-5.1,35.9c0.3,7.4,5,11.7,14,12.8l69,1.2h0.1c2.4,0.2,4.2-0.2,5.4-1.2c1.2-1.1,1.9-3,2-5.4l2.5-43.6
	C447.3,199.9,444,196.6,437.2,196.3L437.2,196.3z M352.6,185.6c-8.5,0.2-14.1,3.9-16.6,11 M460,186.9l-107.4-1.3 M573.5,237.5
	c-0.1-0.5-0.3-1.1-0.4-1.5c-0.5-1.2-1.9-2-3.8-2.4 M573.5,237.5C573.5,237.6,573.5,237.6,573.5,237.5c1,3.3,1.5,7,2,11 M562.6,233.2
	h4.2c0.9,0.1,1.6,0.2,2.4,0.3 M569.2,252.8c0-5.9-0.4-10.9-1.4-15.3c-0.1-0.5-0.2-1.1-0.4-1.6c-0.5-1.3-2.1-2.3-4.7-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M541.1,198.4c-0.4-0.2-1-0.5-1.4-0.9c-14.5-6.7-41.1-10.3-79.7-10.7
	 M542.7,199.2c-0.5-0.3-1.1-0.5-1.6-0.9 M562.6,233.2c-2.2,0.3-3.3,1.5-3.5,3.6v1L559,256c0.2,1.3,0.7,2.3,1.5,3.1 M552.1,194.3
	l-9.4,4.9 M552.1,194.3c-0.4-0.2-0.9-0.4-1.3-0.7 M550.9,193.8l-9.8,4.6 M600.3,243c-18.8-17.9-38-32.6-57.5-43.7 M560.6,259
	c0.8,0.8,1.6,1.3,2.8,1.6 M575.4,248.7h4.9c0.7,0,1,0.3,1,1v6.3c0,0.7-0.3,1-1,1h-4.8 M575.4,248.7c0.1,1.4,0.2,2.8,0.2,4.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M573.8,259.4h0.3c3.8-0.1,6.6-0.8,8.3-2.2c0.1,0,0.1-0.1,0.1-0.1
	c0.8-0.9,1.3-1.9,1.6-2.8c0.9-2.8-0.1-5.8-3.1-9c-2.5-2.6-5-5.2-7.7-7.7 M575.5,257c-0.3,0.9-1,1.6-1.8,2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M575.8,256c0,0.2-0.1,0.4-0.1,0.5s-0.1,0.2-0.1,0.4 M563.5,260.7
	c0.8,0.2,1.6,0.3,2.6,0.4c1.1,0.1,2.4,0.1,3.7,0c1.6-0.4,3-1.1,3.9-1.9 M563.5,260.7c1.3-0.3,2.5-0.9,3.3-1.5
	c1.1-0.8,1.7-1.8,2.2-2.8c0.1-0.2,0.1-0.4,0.2-0.7 M575.7,253c0,1,0.1,2,0.1,3 M569.1,255.7c0.2-0.9,0.2-1.9,0.1-3 M575.7,253
	c0.2,1.1,0.2,2.1,0.1,3 M569.2,252.8c0,1,0,2-0.1,3 M636.8,249.1c-0.3,0.5-0.5,1.2-0.8,2.1c-1.8,0.9-3.6,1.2-5.4,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M784.5,270.6c-2.1-5.8-6-9.8-11.9-12c-4.5-1.5-11.3-3.2-20.7-4.8
	c-40.8-7.4-73.9-10.2-99.4-8.2c-5.1,0.3-9.7,1.1-14,2.2c-0.3,0.1-0.5,0.1-0.9,0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.4,0.4-0.7,0.9
	 M630.7,252.3h-0.3c-13.1-0.2-23.1-3.3-30-9.2 M740.6,274.8c-34.4-3.6-61-5.6-79.9-6c-2.5-0.1-4.9-0.1-7.2-0.1h-2.7
	c-14.2,0.7-23.2,4.9-26.8,12.7c-0.2,0.5-0.5,1.1-0.8,1.8c-0.4,1-0.8,2-1.2,3c-2.8,7.7-7.1,24.6-13,50.8c1-15.3,2.3-28.1,4.2-38.4
	c0.3-2.1,0.8-4,1.1-5.8c0.1-0.7,0.3-1.2,0.4-1.9c1.2-4.7,2.4-8.9,3.7-12.5c0.3-1,0.8-2,1.2-3c0.2-0.5,0.5-1.2,0.8-1.8
	c3.6-7.8,12.6-12,26.8-12.7h2.7c2.3,0,4.7,0,7.2,0.1c18.9,0.3,45.5,3.2,79.9,8.4c5.8,1,10.5,2.8,13.8,5.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M725.7,312.3c-0.5-1.1-1.1-2.2-1.6-3.2c-3.7-6.3-9.2-10.2-16.2-11.3
	c-13.2-2.3-29.8-3.4-49.9-3.3h-2.7c-14.2,0.7-23.2,4.9-26.8,12.7c-0.2,0.5-0.5,1.1-0.8,1.8c-4.5,10.7-8.1,27.3-10.9,49.9 M682,331.8
	c-1.1-0.1-1.9,0.1-2.2,0.7c-0.2,0.4-0.3,1-0.1,1.7l2.3,13c0.5,0.9,1.2,1.5,2,2c0.4,0.2,0.9,0.3,1.3,0.4l13.3-1.5
	c0.8-0.1,1.4-0.3,1.6-0.7c0.4-0.5,0.4-1.4,0-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M683.7,333.3c-1.4,0-2.2,0.5-2.1,1.7c0,0.1,0,0.2,0.1,0.4l1.9,10.5
	c0.7,1.1,1.5,1.8,2.6,2l10.6-1.2c1.5-0.2,2-1,1.3-2.5c-1-1.3-2-2.6-3.3-3.8c-2.2-2.2-4.5-3.8-7-5.2
	C686.6,334.4,685.2,333.7,683.7,333.3L683.7,333.3z M682.2,331.8H682 M682.2,331.8H682 M682.3,331.6l-0.1,0.1 M682,331.8
	c-2.3-0.5-4.7-0.9-7.2-0.9s-4.8,0.3-7.1,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M653.5,340.1l0.5-0.5l0.1-0.1l0.7-0.7c1.5-1.4,3.1-2.6,4.7-3.7
	c4.6-2.8,9.7-4.3,15.4-4.3c2.5,0,5,0.3,7.3,0.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M667.7,331.8c-3,0.7-5.6,1.9-8.1,3.4c-1.6,1.1-3.3,2.3-4.8,3.7
	c-0.2,0.2-0.5,0.4-0.8,0.8l-0.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M649.6,345.2c-0.4,1.1-0.4,1.9,0.1,2.4c0.3,0.3,0.9,0.4,1.5,0.5l13.3,1.6
	c0.5-0.1,0.9-0.2,1.3-0.3c0.9-0.3,1.4-0.7,1.6-1.2c0.3-0.8,0.5-1.1,0.5-1.1l2.3-13c0.1-0.8,0.1-1.4-0.2-1.9s-1.2-0.9-2.4-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M668.3,335v0.4l-1.9,10.4c0,0-0.1,0.3-0.4,0.9c-0.2,0.5-1.1,1-2.4,1.2
	l-10.6-1.3c-1.4-0.1-2-0.9-1.4-2.2l0.1-0.1c0.9-1.3,2-2.5,3.2-3.8l0.2-0.2c2.2-2.2,4.5-3.8,6.9-5.2c1.2-0.7,2.5-1.2,3.8-1.6
	C667.6,333.3,668.4,333.8,668.3,335L668.3,335z M653.4,340.2l0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M674.9,327.8c-9,0-16.5,3.2-22.9,9.5s-9.5,14-9.5,23c0,2,0.1,3.8,0.4,5.7
	c0.1,0.5,0.2,1,0.3,1.5c1.3,5.9,4.2,11.1,8.7,15.6c0.9,0.9,1.8,1.6,2.7,2.5c5.8,4.7,12.5,7,20.1,7c9,0,16.5-3.2,22.9-9.5
	c4.6-4.6,7.4-9.7,8.7-15.6c0.1-0.5,0.2-1,0.3-1.5c0.3-1.9,0.4-3.7,0.4-5.7c0-9-3.2-16.6-9.5-23c-0.8-0.8-1.6-1.5-2.5-2.2
	C689.6,330.2,682.8,327.8,674.9,327.8L674.9,327.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M723.6,367.1c0.3-2.2,0.4-4.6,0.4-6.9c0-6.1-1-11.9-3-17.3
	c-1-2.7-2.3-5.4-3.8-7.9c-1.3-2.2-2.8-4.4-4.7-6.3c-0.9-1.1-1.9-2.2-3-3.2c-1.6-1.6-3.3-3.1-4.9-4.4c-8.5-6.7-18.5-10-29.7-10
	s-21.2,3.3-29.7,10c-1.8,1.3-3.4,2.7-4.9,4.4c-9.6,9.6-14.3,21.1-14.3,34.7v1.5 M333.7,364.4h282.4c0.1-1,0.2-2.1,0.3-3.1
	 M616.8,359.1c-0.1,0.8-0.2,1.5-0.3,2.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M626,361.7c0,1.4,0.1,2.8,0.2,4.2c0,0.5,0.1,1,0.2,1.5
	c1.4,10.5,6.1,19.7,13.9,27.4c9.6,9.6,21.1,14.3,34.7,14.3c12.1,0,22.6-3.8,31.5-11.5c1.1-0.9,2.2-1.9,3.2-3
	c5.2-5.2,9.1-11,11.4-17.4c1.2-3.2,2.1-6.6,2.5-10.1V367 M616.5,361.4l9.4,0.3 M646.4,352.6l-0.1,0.2 M651.6,342.1
	c-0.8,0.9-1.4,1.9-2,2.8c0,0.1-0.1,0.2-0.1,0.2 M646.4,352.6c0.7-2.6,1.8-5.1,3.2-7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M652.2,379.2c1,0.9,1.9,1.2,2.5,0.8c0,0,0.1,0,0.1-0.1
	c0.3-0.2,0.7-0.7,1-1.2l5.9-12v-0.8c0-1.2-0.4-2.1-1.2-2.7l-12-6.2c-0.5-0.3-1.1-0.4-1.5-0.4c-0.8,0.1-1.3,0.8-1.5,2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M658.6,364.2c0.4,0.4,0.8,1,0.9,1.6c0.1,0.3,0.1,0.7,0.1,1.1l-4.7,9.6
	c0,0.1-0.1,0.1-0.1,0.2c-0.5,1-1.2,1.2-2,0.8c-2.5-3.1-4.2-6.5-5.1-10.3c-0.1-0.5-0.2-1-0.3-1.5c-0.3-1.9-0.5-3.7-0.5-5.7v-0.3
	c0.4-1,1.1-1.1,2.3-0.5L658.6,364.2L658.6,364.2z M662.1,361.6c0.4,0.4,1,0.7,1.6,0.7c0.3,0,0.7,0,0.9-0.1s0.5-0.3,0.8-0.5
	c0.4-0.4,0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6c-0.2-0.2-0.4-0.3-0.8-0.5c-0.3-0.1-0.5-0.2-0.9-0.2c-0.7,0-1.2,0.2-1.6,0.7s-0.7,1-0.7,1.6
	C661.5,360.6,661.7,361.2,662.1,361.6L662.1,361.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M645.4,358.5v1.8c0,2,0.1,3.8,0.4,5.6c0.1,0.5,0.2,1,0.3,1.5
	c1,4.4,3.1,8.3,6,11.8 M645.4,358.5c0.1-2,0.4-3.9,0.9-5.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.3,352.9c-0.1,0.5-0.2,1.1-0.3,1.6c0.1-0.7,0.2-1.2,0.4-1.9
	 M672.4,349c0,0.3,0,0.7,0.1,0.9c0.1,0.3,0.3,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.2-0.2,0.3-0.4,0.5-0.8
	c0.1-0.2,0.2-0.5,0.2-0.9c0-0.7-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.2,0.2-0.4,0.4-0.5,0.8
	C672.5,348.4,672.4,348.7,672.4,349L672.4,349z M668.6,350.6c-0.4-0.4-1-0.7-1.6-0.7c-0.3,0-0.7,0-0.9,0.2c-0.2,0.1-0.5,0.2-0.8,0.5
	c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.2,0.2,0.4,0.3,0.8,0.5c0.2,0.1,0.5,0.2,0.9,0.2c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6
	c0-0.3,0-0.5-0.2-0.9C669,351.1,668.8,350.8,668.6,350.6L668.6,350.6z M682.5,349.9c0.3,0,0.5,0,0.9,0.2c0.2,0.1,0.5,0.2,0.8,0.5
	c0.4,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6c-0.2,0.2-0.4,0.4-0.8,0.5c-0.3,0.1-0.5,0.1-0.9,0.1c-0.7,0-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6
	c0-0.3,0-0.5,0.1-0.9c0.1-0.2,0.3-0.5,0.5-0.8C681.4,350.1,681.9,349.9,682.5,349.9L682.5,349.9z M678.4,353.7
	c-1.1-0.5-2.2-0.9-3.5-0.9h-0.1c-1.2,0-2.4,0.3-3.4,0.9c-0.7,0.3-1.2,0.8-1.8,1.3c-1.4,1.4-2.1,3.1-2.2,5.1v0.1c0,2,0.7,3.6,2,5
	l0.1,0.1c0.2,0.2,0.3,0.3,0.5,0.5c0.9,0.8,2,1.3,3.1,1.5c0.4,0.1,1,0.1,1.4,0.1h0.1c0.5,0,1.1,0,1.5-0.1c1.1-0.2,2.2-0.8,3.1-1.5
	c0.2-0.1,0.3-0.3,0.5-0.5c1.4-1.4,2.2-3.2,2.2-5.2V360c0-2-0.8-3.6-2.2-5c0,0-0.1-0.1-0.2-0.1C679.5,354.4,679,354.1,678.4,353.7
	L678.4,353.7z M684.1,358.3c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.2,0.2,0.4,0.4,0.8,0.5c0.2,0.1,0.5,0.1,0.9,0.1
	c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6s-1-0.7-1.6-0.7c-0.3,0-0.7,0-0.9,0.2C684.6,358,684.3,358.1,684.1,358.3
	L684.1,358.3z M683.9,366c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2-0.1-0.5-0.1-0.8-0.1c-0.5,0-1.1,0.2-1.4,0.5l-0.1,0.1
	c-0.3,0.3-0.7,0.9-0.7,1.3v0.3c0,0.3,0,0.7,0.2,0.9c0.1,0.2,0.3,0.5,0.5,0.8c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7
	s0.7-1,0.7-1.6v-0.3c0-0.5-0.3-1-0.7-1.3C684,366,684,366,683.9,366L683.9,366z M665.4,366.1l0.2-0.2c0.2-0.2,0.4-0.3,0.7-0.3
	c0.2-0.1,0.5-0.1,0.8-0.1c0.5,0,1.1,0.2,1.4,0.5c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.5,0.9,0.7,1.3v0.3c0,0.3,0,0.7-0.2,0.9
	c-0.1,0.2-0.3,0.5-0.5,0.8c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7s-0.7-1-0.7-1.6v-0.3C664.8,367,665,366.5,665.4,366.1
	L665.4,366.1z M672.6,370c-0.1,0.2-0.1,0.5-0.1,0.9c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6c0-0.3,0-0.7-0.2-0.9c-0.1-0.2-0.2-0.5-0.5-0.8c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7
	C672.9,369.5,672.7,369.8,672.6,370L672.6,370z M685.8,387.7c1.1-0.7,1.6-1.4,1.5-2.1c-0.1-0.4-0.3-1-0.9-1.4l-9.7-9.3
	c-0.4-0.2-1-0.4-1.5-0.5h-0.3c-0.5,0-1.1,0.2-1.8,0.5l-9.6,9.4c-0.5,0.4-0.9,1-0.9,1.4c-0.2,0.8,0.3,1.5,1.5,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M675.2,376.3c0.4,0.1,0.9,0.2,1.2,0.4l7.8,7.4c1.1,1.1,1,2.1-0.4,3
	c-2.7,0.9-5.6,1.3-8.6,1.3c-3.2,0-6.1-0.4-8.9-1.3c-1.4-0.9-1.5-1.9-0.4-2.8l7.8-7.4C674.1,376.4,674.6,376.1,675.2,376.3
	L675.2,376.3z M664,387.8c-3.3-1.3-6.3-3.3-9.2-5.9c-0.2-0.2-0.5-0.4-0.8-0.8c-0.7-0.7-1.2-1.3-1.8-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M664,387.8c3.4,1.3,7,2,10.8,2c3.9,0,7.5-0.7,10.9-2 M616.8,359.1h-16.5
	 M651.6,342.1l1.8-2 M653.5,340.1c-0.7,0.7-1.3,1.4-1.9,2.1 M764,276.2l-13.1-0.9 M770.8,276.8l-6.7-0.5H764 M740.6,274.8
	c-1.6,0.3-2.5,1.5-2.8,3.5c-0.3,1.4-0.7,2.8-0.9,4.5c-0.3,2.4-0.7,4.9-0.9,7.7l-0.1,2.5 M738,290.5c0.1-2.4,0.3-4.7,0.7-6.7
	c0.2-1.4,0.5-2.7,0.8-4c0.4-2.4,2-3.5,4.6-3.2c0.1,0,0.3,0,0.4,0.1l24.6,1.8c1.3,4.7,2.2,9.5,2.7,14.4c0,0.3,0.1,0.8,0.1,1.1
	c0.5,5.4,0.7,10.9,0.2,16.6c-11.7,0.1-23.2-0.5-34.3-1.8c-0.3-5.4-0.3-10.3-0.2-14.8v-0.4 M750.9,275.3l-7.5-0.5
	c-1-0.1-1.9-0.1-2.6,0 M764,276.2c-3.4-5.9-11.9-10.2-25.6-12.7c-21.8-4.6-57.7-8.3-107.9-11.3 M737.9,292.9v0.7 M737.9,293.6v-0.7
	 M736.1,292.8L736,294 M736.1,292.8c0,0.4-0.1,0.9-0.1,1.2 M735.9,294c-0.2,4.5-0.1,9.5,0.1,15.1c0,0.5,0,1,0.1,1.5 M736.2,310.7
	h-11 M774.2,314.8c-1.2-0.2-2.4-0.3-3.6-0.4c-1.3-0.1-2.6-0.2-3.9-0.3c-1.4-0.1-2.8-0.2-4.3-0.3c-1.3,0-2.5-0.1-3.8-0.1
	c-1.4,0-2.8-0.1-4.3-0.1c-4.5-0.1-9.5-0.3-15.3-0.5c-4.2-0.2-8.5-0.3-13.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M737.1,363.4c-2-20.4-5-35.5-9.4-45.3c-0.5-2.1-1.3-4-2.1-5.8
	 M700.2,344.9c-1.2-2-2.7-3.8-4.4-5.6c-1.6-1.6-3.5-3.2-5.4-4.4c-2.5-1.6-5.2-2.7-8.1-3.4 M738,290.5l-0.1,2.4 M789.2,285.4
	l-0.9-7.5 M790.8,287.4c0-0.2-0.1-0.9-0.3-2h-1.4 M790.8,287.4h-0.7 M790.2,287.4l0.4,4.6 M788.3,277.9c1.3-0.7,1.3-1.3,0-2
	 M770.8,277.2v-0.5 M770.8,277.2v-0.4 M784.5,270.6H777c-1.6,0-3,0.3-3.9,0.9c-1.7,1-2.5,2.7-2.2,5.1 M788.3,275.9
	c-0.3-3-1.5-4.6-3.7-5v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M789.2,285.4h-4.8c-0.7,0.1-1.2,0-1.5-0.2c-0.2-0.2-0.4-0.7-0.4-1.2
	l-0.4-5c0-0.3,0.1-0.5,0.3-0.8c0.3-0.2,0.9-0.3,1.6-0.3h4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M790.2,287.4h-5.6c-1.4,0.2-2.4,0-3.1-0.5c-0.8-0.5-1.2-1.4-1.2-2.7
	l-0.4-5c0-1.1,0.3-1.9,1.2-2.4c0.7-0.5,1.6-0.8,3-0.8h4.3 M737.9,292.9c10.2,0.9,20.4,1.3,31,1.2h0.2c0.2,0,0.5-0.2,0.8-0.3
	c0.2-0.2,0.3-0.5,0.3-0.9s-0.1-0.7-0.4-0.9c-0.2-0.2-0.5-0.3-1-0.3c-10.5,0.1-20.8-0.3-30.8-1.2 M774.1,292
	c-0.7-4.9-1.8-9.8-3.3-14.8 M790.6,292h-16.5 M791.3,298.1H786c-2.6,0.2-3.9,1.3-3.8,3.3v0.1l0.9,9.2c0.2,1.9,0.9,3.2,2,3.9
	c0.9,0.7,2,1.1,3.4,1c0.2,0,0.4,0,0.7,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M789.1,313.6c-1.4,0-2.3-0.2-2.8-0.5c-0.7-0.5-1.1-1.4-1.2-2.6l-0.9-9.1
	c0.1-0.8,0.8-1.1,1.9-1.2h4.6 M774.2,312.4v2.4 M774.2,312.4c0.7-6.2,0.7-12.2,0.1-18.4 M791.3,298.1h0.3 M791.5,298.3
	c-0.1-0.1-0.2-0.1-0.3-0.2 M790.8,294l0.4,4 M791.5,298.3v-0.2 M790.7,300h0.7c0.9-0.5,0.9-1.2,0.1-1.8 M788.9,315.7
	c1.4-0.7,1.4-1.4,0.2-2.1 M789.1,313.6l1.6-13.6 M790.3,322.1c0,0.3,0.1,0.7,0.1,1.1c0.2,0.8,2.7,1.3,7.8,1.6 M774.2,314.8
	c6.2,0.9,10.6,2,13.2,3.3c0.3,0.1,0.5,0.2,0.8,0.4c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.7,0.9,0.9,1.3 M790.3,322.1
	c-0.1-0.8-0.2-1.4-0.5-2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M797.3,350.6l0.5-2.3c0.3-1.5-0.1-2.5-1.5-3.1l0.9-12.9
	c1.3,0.1,2.1-0.4,2.4-1.4c0-3.4-0.4-5.5-1.4-6.1 M798,324.9c-1.4-0.9-4-1.9-7.8-2.7 M783.3,339.3l-14.2,0.2c-2.4,0-3.7,1.8-4,5.2
	l-0.5,7.3c-0.2,3.1,0.8,4.6,3.1,4.7h13.2c2.3,0,3.7-1.5,4.2-4.7l1.2-7.4v-0.4c0.1-0.4,0.1-0.9,0.1-1.3
	C786.3,340.5,785.4,339.3,783.3,339.3L783.3,339.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M777.4,341.2l-8.1,0.1c-1.4,0-2.1,1.4-2.3,4.2l-0.3,5.9v1.2
	c0,0.9,0.2,1.4,0.4,1.9c0.3,0.4,0.8,0.7,1.3,0.7h7.7c1.2-0.1,2-1.4,2.3-3.8l0.7-6c0.2-2.1,0-3.3-0.8-3.8c-0.1-0.1-0.3-0.2-0.5-0.2
	C777.7,341.3,777.5,341.2,777.4,341.2L777.4,341.2z M789.7,320.3c0.9-2.6,0.5-4.2-0.9-4.6 M774.3,294h16.5 M791.6,298.1
	c0.1-3.5-0.1-7.1-0.7-10.7 M774.3,294c-0.1-0.8-0.2-1.4-0.2-2.1 M790.6,292l0.2,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M797.3,350.6h-7.1l-3.8,10.5c-0.7,2.5-2.4,3.7-5,3.7h-41
	c-1.4,0.3-2.5-0.2-3.1-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M737.5,368.2c0.1,1.8,0.3,3.6,0.4,5.5c15.5,0.5,27.1,1.6,34.9,3.2
	c3.4,1.4,6.7,2.2,9.9,2c3.8-0.4,6.3-1.6,7.4-3.7c3.8-6.6,6.2-13.1,7.2-19.7l1.2-1.3c0.5-1.3,0-2.4-1.5-3.4 M703.9,365.2
	c0.1-1,0.2-2,0.3-3l0.1-1.5v-0.1v-0.4v-1.4 M704.3,358.8c-0.2-1.3-0.7-2-1.4-2.1c-0.4-0.1-1.1,0-1.8,0.3l-11.8,6
	c-0.1,0.1-0.2,0.1-0.2,0.2c-0.8,0.9-1.1,1.8-1.1,2.6c0,0.2,0,0.4,0.1,0.7l5.8,12.1c0.3,0.7,0.7,1.1,1.1,1.3c0.3,0.2,0.8,0.2,1.2,0
	c0.3-0.1,0.7-0.3,1-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M703.1,360.7v-0.2c-0.3-1.4-1.1-1.9-2.4-1.1l-9.4,4.8
	c-0.7,0.5-1,1.2-1.1,1.9c0,0.3,0,0.7,0.1,1.1l4.6,9.6c0.7,1.3,1.4,1.5,2.5,0.7c2.4-3.1,3.9-6.3,4.9-10c0.1-0.5,0.2-1,0.3-1.5
	C702.9,364.2,703.1,362.5,703.1,360.7L703.1,360.7z M703.8,366.1l0.1-0.9 M703.6,367.4c0.1-0.5,0.2-1,0.3-1.5c0-0.2,0.1-0.4,0.1-0.8
	 M700.2,344.9c2.5,4.2,3.9,8.7,4.2,13.9 M697.2,379.5l0.9-1.1c2.6-3.3,4.4-6.8,5.4-10.7 M703.5,367.7v-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M703.5,367.7c0-0.1,0-0.2,0.1-0.3c0.1-0.4,0.2-0.9,0.3-1.4 M697.2,379.5
	l-0.8,0.9c-0.2,0.2-0.4,0.5-0.8,0.8c-3,3-6.2,5.1-9.8,6.6 M703.6,367.4c0.1-0.4,0.2-0.9,0.3-1.4 M737.5,368.2
	c-0.1-1.6-0.2-3.3-0.4-4.8 M723.6,367.1l13.9,1.1 M736.2,310.7l38,1.7 M218.5,344.9c-1.2-2-2.7-3.8-4.4-5.6
	c-1.6-1.6-3.5-3.2-5.4-4.4c-2.5-1.6-5.2-2.7-8.1-3.4 M326.7,253.9c-0.2,3.3-0.3,6.8-0.4,10.2c0.1,34.8,1.3,66.5,3.7,95.1
	c-0.4,3.4,0.9,5.1,3.7,5.2 M357.2,279.1c-2.2-0.1-4.4-0.1-6.5-0.2c-0.7,0-1.3-0.2-1.8-0.8c-0.5-0.4-0.8-1.1-0.8-1.8V275
	c0-0.7,0.2-1.3,0.8-1.8c0.4-0.4,1.1-0.8,1.8-0.8c2.2-0.1,4.4-0.2,6.5-0.3 M359.6,279.1h-2.4 M357.2,272.1h3 M357.2,272.1v7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M352.6,185.6c-10.2,31.4-14.2,75.1-11.9,131.1v0.2c0,1.4,0,2.8,0.1,4.3
	c0.2,14.1,0.9,26.8,2,37.9H457 M326.7,253.9c-1.4-2.1-5-3.2-10.8-3.3l-248.9-1 M302.9,364.4h10 M312.8,364.4h20.9 M202.4,358.3
	c-0.4,0.4-0.7,1-0.7,1.6s0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7s0.7-1,0.7-1.6s-0.2-1.2-0.7-1.6
	s-1-0.7-1.6-0.7C203.4,357.7,202.8,357.9,202.4,358.3L202.4,358.3z M209.6,364.1c-0.7,0.5-1,1.2-1.1,1.9c0,0.3,0,0.7,0.1,1.1
	l4.6,9.6c0.7,1.3,1.4,1.5,2.5,0.7c2.4-3.1,3.9-6.3,4.9-10c0.1-0.5,0.2-1,0.3-1.5c0.3-1.6,0.5-3.4,0.5-5.2v-0.2
	c-0.3-1.4-1.1-1.9-2.4-1.1L209.6,364.1L209.6,364.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M222.7,358.8c-0.2-1.3-0.7-2-1.4-2.1c-0.4-0.1-1.1,0-1.8,0.3l-11.8,6
	c-1,0.9-1.4,1.9-1.3,2.8c0,0.2,0,0.4,0.1,0.7l5.8,12.1c0.3,0.7,0.7,1.1,1.1,1.3c0.7,0.3,1.3,0.1,2.2-0.5 M222.2,365.9l0.1-0.7
	c0.1-1,0.2-2,0.3-3l0.1-1.5v-0.1v-0.4v-1.4 M221.8,367.4c0.1-0.5,0.2-1,0.3-1.5 M222.1,366.6c-0.1,0.2-0.1,0.4-0.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M222.1,366.6c0,0.2-0.1,0.5-0.2,0.8c0,0.1,0,0.2-0.1,0.3 M221.8,367.7
	v-0.3 M215.5,379.5l-0.8,0.9c-0.2,0.2-0.4,0.5-0.8,0.8c-2.9,3-6.2,5.1-9.8,6.6 M215.5,379.5l0.9-1.1c2.6-3.3,4.4-6.8,5.4-10.7
	 M218.5,344.9c2.5,4.2,3.9,8.7,4.2,13.9 M222.2,365.9l-0.1,0.8 M251,361.9c0.5,1.6,1.8,2.4,3.6,2.5h48.2 M242.3,361.9h8.7
	 M360,272.1c7.8-0.1,15.3,0.2,22.7,1.1c0.5,0,1,0.2,1.4,0.5c0.3,0.4,0.5,0.9,0.5,1.4v1.1c0,0.5-0.2,1-0.5,1.4
	c-0.4,0.4-0.9,0.5-1.4,0.5c-2,0.1-3.9,0.2-5.9,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.9,272.3c-0.3-2.3-1.9-3.6-4.6-4c-2.5-0.3-4.9-0.3-7.2,0.1
	c-2.8,0.3-4.5,1.6-4.9,3.7 M377,278.6c-0.8,2.6-2.8,4.2-6.2,4.6h-4.3c-3.7-0.2-6-1.5-6.8-4.2 M473.8,281.6c-2.2-0.1-4.4-0.1-6.5-0.2
	c-0.7,0-1.3-0.2-1.8-0.8c-0.5-0.4-0.8-1.1-0.8-1.8v-1.4c0-0.7,0.2-1.3,0.8-1.8c0.4-0.4,1.1-0.8,1.8-0.8c2.2-0.1,4.4-0.2,6.5-0.3
	 M476.7,274.6h-3 M493.4,274.8c-0.3-2.3-1.9-3.6-4.6-4c-2.5-0.3-4.9-0.3-7.2,0.1c-2.8,0.3-4.5,1.6-4.9,3.7 M476.2,281.6h-2.4
	 M473.8,274.6v7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M476.7,274.6c7.8-0.1,15.3,0.2,22.7,1.1c0.5,0,1,0.2,1.4,0.5
	c0.3,0.4,0.5,0.9,0.5,1.4v1.1c0,0.5-0.2,1-0.5,1.4c-0.4,0.4-0.9,0.5-1.4,0.5l-5.9,0.3 M493.5,281.1c-0.8,2.6-2.8,4.2-6.2,4.6H483
	c-3.7-0.2-6-1.5-6.8-4.2 M493.5,281.1c-6.2,0.3-11.9,0.5-17.4,0.4 M377,278.6c-6.2,0.3-11.9,0.5-17.4,0.4 M636.8,249.1
	c-35.9-25.9-64.1-44.2-84.6-54.7 M336.1,196.7c-5,15.7-8.2,34.8-9.4,57.2 M600.3,359.1c10.5-47.8,10.5-86.5,0-116 M457,359.1h143.2
	 M457,359.1c-1.4-65.2-0.3-122.5,3-172.2"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M575.4,248.7h4.9
		c0,0,1-0.1,1,1s0,6.3,0,6.3s0,1-1,1s-4.8,0-4.8,0s-0.7,1.8-2.5,3c0,0-1.4,1-3.6,1.3c0,0-4,0.5-7.3-1.1c0,0-2.6-1.3-3-4.2l0.1-18.4
		c0,0-0.2-1.9,1.1-3.3c0,0,0.7-0.9,2.4-1.1h4.2c0,0,5.1,0.2,6.2,2.8l0.7,2.1C573.6,238.1,574.7,241,575.4,248.7z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M560.6,259
		l-21.6-0.4l-24.2-0.4l-23.8-0.4l-16.4-0.3c0,0-6.1,0.2-6.2-3.8l0.3-12.5l0.3-16.6l0.2-11.5l0.1-5.2c0,0-0.1-4.5,3.1-7.2
		c0,0,2.3-2.3,6.7-2.3l22.3,1.5l4.5,0.4c0,0,4.9,0.7,7.2,1.1c0,0,12.2,1.8,22.2,7.2c0,0,6.5,3.6,18.4,12.2c0,0,13,10.1,15.4,12.7
		l-2.1-0.2h-4.3c0,0-1.7,0.2-2.6,1.3c0,0-1,1-0.8,3l-0.1,18.7C559.1,256.1,559.6,258.1,560.6,259z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M437.3,256.1
		c0,0,4.2,0.7,6.1-1.9c0,0,1.2-1.1,1.3-3.8l2.5-44.4c0,0,0.3-4.7-2.8-7.3c0,0-2.2-2.2-7.5-2.4h-37.7h-24.1c0,0-5.7,0-9.6,2.4
		c0,0-4,2-6.1,7.4c0,0-3.6,12.4-4.8,23.9c0,0-1.1,10.5,0.1,15.2c0,0,1.1,8.2,13.4,9.5l31.6,0.5L437.3,256.1z"
              id="Right Rear Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M724.9,310.7h11.3
		c0,0-0.4-9.3-0.1-18.3c0,0-0.1-4.9,1.9-14.5c0,0,0.2-2,2.2-2.8c0,0,1.1-0.5,3.2-0.2l7.5,0.5l13.1,0.9c0,0-3.4-6.7-15.3-10.2
		c0,0-6.9-2.5-31.9-6.1c0,0-14.3-2.1-46.4-4.9c0,0-28.2-2.2-39.8-2.7c0,0-19.7,0.5-30.4-9.3c0,0,3.6,9.1,5.9,23.9
		c0,0,2.4,14.5,1.9,35.2c0,0-0.9,20.6-3.8,36.5c0,0-2.4,14.4-3.9,20.4h16.5c0,0,1.6-15,5.9-33.8c0,0,1.4-6.9,5.4-16.8
		c0,0,2-6.6,10.3-10.2c0,0,6.3-3.3,18.1-3.5c0,0,11.3-0.2,25.8,0.5c0,0,7.8,0.3,19.4,1.7c0,0,6.5,1,6.9,1.1s5.2,0.8,9.6,4.3
		C718.2,302.3,721.8,305,724.9,310.7z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M456.9,259.3l18.9,0.5
		l96.4,2l33.2,0.7c0,0,2.3,12,2.6,29v10.3c0,0-0.8,14.7-1.9,22.9c0,0-1.4,10.8-3,19.5c0,0-2.2,11.7-3.1,15h-58h-43.5H457l-0.4-28.2
		l-0.1-23.3l0.1-25L456.9,259.3z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M456.9,259.3l-18.8-0.7
		l-48.7-1l-20.4-0.3l-28-0.9c0,0-0.8,15-0.8,25.3c0,0,0,21,0.5,34.9c0,0,0.2,12.4,0.5,18.6c0.3,6.3,1,17.9,1.5,23.8H374h44.1H457
		l-0.3-22.3l-0.2-24.9l0.1-22.9l0.2-22.3L456.9,259.3z"
              id="Right Rear Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M333.7,364.4
		c0,0-4.5,0.2-3.7-5.2c0,0-2.7-37.6-3.2-57.8l-0.4-22.9l-0.1-14.5l0.4-10.2c0,0-1.6-2.6-8-3.1l-3.3-0.1l-47.5-0.2l-40.9-0.2
		l-48.3-0.2l-49.3-0.2l-44.6-0.2l-19.6-0.1c0,0-1.5,0.7-2.3,5.1c0,0-0.7,3-1,8.4l-0.1,2.7l12.9,2.3c0,0,4.5,0.8,8.3,3.4
		c0,0,4.6,2.8,3.7,8.2l1,27.1c0,0,0.3,3.8-1.8,5.8c0,0-1.3,1.4-4,1.4L63,314.2l0.9,12.9h10.8c0,0,4.4-0.1,7.5,1.9c0,0,3,1.6,3.3,5.6
		v17.2c0,0-0.1,2.7-4.3,3.2c0,0-1.8,0.3-4.7-0.1h-0.8l0.5,1.2l8.7,1.4l3.5,2.7c0,0,16.9,2.5,38.4,2.1l1.5,2.5h4.9
		c0,0-0.1-11.7,0.3-17.7c0,0,0.5-14.6,4.3-26.1c0,0,2.7-10.8,11.3-17.6c0,0,5.7-5.1,15.3-6.5c0,0,18-1.3,27.6-1.1
		c0,0,11.5-0.2,24.9,1.2c0,0,12.7,0.7,20,8.3c0,0,5,4,9.1,18.6c0,0,1.3,5.1,1.6,6.8c0,0,2,10.9,2.4,17.9l0.7,10.1l0.1,3.3
		c0,0,0.7,2.5,3.6,2.5H290h13h9.9L333.7,364.4L333.7,364.4z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="674.9"
              cy="360.2"
              r="49.1"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="193.3"
              cy="360.1"
              r="49.2"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(CrewCabPickup4DrRight);
