import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons';
import { GREEN_01, RED_02 } from '../../../constants/colors';

class ToastContent extends Component {
  render() {
    const { status, text } = this.props;

    return (
      <div className="toast-content">
        {status === 'success' && (
          <div className="flex items-center ml-3">
            <div style={{ backgroundColor: GREEN_01 }}>
              <Icon name="CircledCheckmark" />
            </div>
            <div className="toast-success mx-3">{text}</div>
          </div>
        )}

        {status === 'error' && (
          <div className="flex items-center ml-3">
            <div style={{ backgroundColor: RED_02 }}>
              <Icon name="CircledExclamationmark" />
            </div>
            <div className="toast-error mx-3">{text}</div>
          </div>
        )}
      </div>
    );
  }
}

ToastContent.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ToastContent;
