import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, find, get, findIndex } from 'lodash';
import {
  updateItem,
  updatePhotos,
  updateSelectedCondition,
} from '../../../../../../../actions';
import Icon from '../../../../ui/icons';
import Layout from '../../../../templates/forms/Layout';
import { areas } from '../../../../../constants/common';
import { CYAN_01 } from '../../../../../constants/colors';
import ConditionForm from './ConditionForm';
import ConditionFooter from '../../../../templates/forms/condition/splats/footer';
import '../../../../../styles/form/condition.css';

class ConditionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: !!props.location.state.edit,
      allowFinish: true,
      chargeableSelectedIndex: undefined,
      laborCode: '',
      laborHours: '',
      materialEstimate: '',
      notes: '',
      paintCode: '',
      paintHours: '',
      sublet: '',
      actionSuggest: {},
      partCost: null,
      conditionKey: null,
      prevPhoto: null,
      photoDeleted: false,
      needReviewIndex: undefined,
      repairCost: '',
      image: null,
    };
  }

  componentDidMount() {
    this.setConditionProperties();
    this.setPhotoInfo();
  }

  componentWillUnmount() {
    // this.state.allowFinish will tell you whether or not Finish was clicked vs backed out
    if (this.state.allowFinish) {
      this.unmountUnfinished();
    } else {
      this.unmountFinished();
    }
  }

  findPhotoIndex() {
    const photoIndex = findIndex(this.props.item.photos, {
      descriptionKey: this.state.conditionKey,
    });
    return photoIndex;
  }

  findPhoto(photoIndex) {
    if (!photoIndex && !photoIndex !== 0) {
      photoIndex = this.findPhotoIndex();
    }
    const photo = get(this.props.item.photos, `${photoIndex}`);
    return photo;
  }

  findPhotoUri() {
    const photo = this.findPhoto();
    let photoUri = '';

    if (photo) {
      if (photo.uri) {
        photoUri = photo.uri;
      } else if (photo.name === '(null)') {
        photo.uri = '';
      } else if (photo.name) {
        photoUri = (photo.name || '').includes('://')
          ? photo.name
          : `${this.props.serviceUrl.serviceUrl}/${photo.name}`;
      }
    }

    return photoUri;
  }

  unmountUnfinished() {
    const photos = cloneDeep(this.props.item.photos);
    const photoIndex = this.findPhotoIndex();
    const photo = this.findPhoto(photoIndex);

    if (!photo) {
      return;
    }

    if (this.state.prevPhoto) {
      // overwrite -- put the original photo which you saved to state back
      photos[photoIndex] = this.state.prevPhoto;
    } else {
      // remove
      photos.splice(photoIndex, 1);
    }

    this.props.updatePhotos(photos);
  }

  unmountFinished() {
    const photos = cloneDeep(this.props.item.photos);
    const photoIndex = this.findPhotoIndex();
    const photo = get(photos, `${photoIndex}`);

    if (!photo) {
      return;
    }

    if (this.state.photoDeleted === true) {
      // delete
      // The logic of using '(null)' vs. '' not done here
      photos.splice(photoIndex, 1);
    } else {
      // update with latest condition changes
      photo.finished = true;
      photos[photoIndex] = photo;
    }

    this.props.updatePhotos(photos);
  }

  setConditionProperties() {
    if (this.props.location.state.edit) {
      const index = this.props.location.state.edit.index;
      const {
        laborCode,
        laborHours,
        materialEstimate,
        needReview,
        notes,
        paintCode,
        paintHours,
        sublet,
        chargeableSelectedIndex,
      } = this.props.item.condition[index];
      this.setState({
        laborCode,
        laborHours,
        materialEstimate,
        needReviewIndex: needReview === '1' ? 0 : 1,
        notes,
        paintCode,
        paintHours,
        sublet,
        chargeableSelectedIndex,
      });
    } else {
      this.setState({ needReviewIndex: 1, chargeableSelectedIndex: 1 });
    }
  }

  setPhotoInfo() {
    const {
      item: { condition, photos },
      location,
    } = this.props;
    const existingConditionKey = get(
      condition,
      `${get(location, 'state.edit.index')}`,
      {}
    ).conditionKey;

    const highestConditionKey = (condition || []).reduce((highest, item) => {
      const val = Number(item.conditionKey.split('-')[1]);
      return Math.max(val, highest);
    }, -1);

    // used for new photos
    const nextConditionKey = `Condition-${highestConditionKey + 1}`;

    const conditionKey =
      existingConditionKey || existingConditionKey === 0
        ? existingConditionKey
        : nextConditionKey;

    const prevPhoto = find(photos, {
      descriptionKey: conditionKey,
    });

    this.setState({
      conditionKey,
      prevPhoto: prevPhoto || null,
    });
  }

  updateConditionColors(selectedItems, area) {
    let selectedValues = {};

    if (selectedItems) {
      if (Object.keys(selectedItems).length > 0) {
        selectedValues = selectedItems.area;
      }
    }

    if (area === areas.grille) {
      selectedValues.grilleColor = true;
    }
    if (area === areas.frontBumper) {
      selectedValues.frontBumperColor = true;
    }
    if (area === areas.leftFrontHeadlights) {
      selectedValues.leftFrontHeadlightsColor = true;
    }
    if (area === areas.rightFrontHeadlights) {
      selectedValues.rightFrontHeadlightsColor = true;
    }
    if (area === areas.windshield) {
      selectedValues.windshieldColor = true;
    }
    if (area === areas.leftFrontFender) {
      selectedValues.leftFrontFenderColor = true;
    }
    if (area === areas.leftFrontDoor) {
      selectedValues.leftFrontDoorColor = true;
    }
    if (area === areas.leftFrontDoorGlass) {
      selectedValues.leftFrontDoorGlassColor = true;
    }
    if (area === areas.leftQtrGlass) {
      selectedValues.leftQtrGlassColor = true;
    }
    if (area === areas.leftRearQuarterPanel) {
      selectedValues.leftRearQuarterPanelColor = true;
    }
    if (area === areas.leftRearDoor) {
      selectedValues.leftRearDoorColor = true;
    }
    if (area === areas.leftRearDoorGlass) {
      selectedValues.leftRearDoorGlassColor = true;
    }
    if (area === areas.leftSideMirror) {
      selectedValues.leftSideMirrorColor = true;
    }
    if (area === areas.wheelsAndTiresLeftFront) {
      selectedValues.tiresLeftFrontColor = true;
    }
    if (area === areas.wheelsAndTiresLeftRear) {
      selectedValues.tiresLeftRearColor = true;
    }
    if (area === areas.deckLid) {
      selectedValues.deckLidColor = true;
    }
    if (area === areas.liftgateAndTailgate) {
      selectedValues.liftgateTailgateColor = true;
    }
    if (area === areas.rearBumper) {
      selectedValues.rearBumperColor = true;
    }
    if (area === areas.rearGlass) {
      selectedValues.rearGlassColor = true;
    }
    if (area === areas.leftTailLight) {
      selectedValues.leftTailLightColor = true;
    }
    if (area === areas.rightTailLight) {
      selectedValues.rightTailLightColor = true;
    }
    if (area === areas.rightFrontFender) {
      selectedValues.rightFrontFenderColor = true;
    }
    if (area === areas.rightFrontDoor) {
      selectedValues.rightFrontDoorColor = true;
    }
    if (area === areas.rightFrontDoorGlass) {
      selectedValues.rightFrontDoorGlassColor = true;
    }
    if (area === areas.rightQtrGlass) {
      selectedValues.rightQtrGlassColor = true;
    }
    if (area === areas.rightRearQuarterPanel) {
      selectedValues.rightRearQuarterPanelColor = true;
    }
    if (area === areas.rightRearDoor) {
      selectedValues.rightRearDoorColor = true;
    }
    if (area === areas.rightRearDoorGlass) {
      selectedValues.rightRearDoorGlassColor = true;
    }
    if (area === areas.rightSideMirror) {
      selectedValues.rightSideMirrorColor = true;
    }
    if (area === areas.wheelsAndTiresRightFront) {
      selectedValues.tiresRightFrontColor = true;
    }
    if (area === areas.wheelsAndTiresRightRear) {
      selectedValues.tiresRightRearColor = true;
    }
    if (area === areas.hood) {
      selectedValues.hoodColor = true;
    }
    if (area === areas.roof) {
      selectedValues.roofColor = true;
    }
    if (area === areas.truckBed) {
      selectedValues.truckBedColor = true;
    }

    this.props.updateSelectedCondition({
      area: selectedValues,
    });
  }

  getValue(condition, index) {
    const {
      area,
      part,
      damage,
      severity,
    } = this.props.location.state.condition;

    // all this lodashness is for safe and direct access
    const areaCode = get(
      find(get(this.props.condition, 'areas.values', []), {
        description: area,
      }),
      'code',
      ''
    );
    const partCode = get(
      find(get(this.props.condition, 'parts.values', []), {
        description: part,
      }),
      'code',
      ''
    );
    const damageCode = get(
      find(get(this.props.condition, 'damages.values', []), {
        description: damage,
      }),
      'code',
      ''
    );
    const severityCode = get(
      find(get(this.props.condition, 'severity.values', []), {
        severityDescription: severity,
      }),
      'severityCode',
      ''
    );

    const value = {
      area,
      areaCode,
      part,
      partCode,
      damage,
      damageCode,
      severity,
      severityCode,
      actionDescription: this.state.actionSuggest.repairAction,
      actionCode: this.state.actionSuggest.actionCode,
      materialEstimate: this.state.materialEstimate,
      laborHours: this.state.laborHours,
      laborCode: this.state.laborCode,
      paintHours: this.state.paintHours,
      paintCode: this.state.paintCode,
      sublet: this.state.sublet,
      notes: this.state.notes,
      crType: this.props.location.state.selectedCrType,
      conditionKey: this.state.conditionKey,
      needReview: this.state.needReviewIndex === 0 ? '1' : '0',
      chargeableSelectedIndex: this.state.chargeableSelectedIndex,
    };
    if (condition && index) {
      value.hadPhoto = condition[index].hadPhoto;
    }
    return value;
  }

  addCondition() {
    // three things called "conditions" so don't get confused
    // 1 props item condition, 2 location condition, 3 props condition
    const condition = cloneDeep(this.props.item.condition) || [];
    const { area } = this.props.location.state.condition;
    const value = this.getValue();
    condition.push(value);
    this.props.updateItem({ condition });
    this.updateConditionColors(this.props.selectedCondition, area);
    this.props.navigate('/core/condition');
  }

  editCondition() {
    const condition = cloneDeep(this.props.item.condition);
    const index = get(this.props.location, 'state.edit.index');
    const value = this.getValue(condition, index);
    condition[index] = value;
    this.props.updateItem({ condition });
    this.props.navigate('/core/conditionReview');
  }

  _handleOnChangeTextPrice = (text, stateKey) => {
    const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (regexp.test(text)) {
      this.setState({
        [stateKey]: text.replace(/(\d{5})(?=(\d\d)+(?!\d))/g, '$1.'), //mask input as 11111.11
      });
    }
  };

  handleOnChangeTextPrice = (text, stateKey) => {
    const regexp = /[^0-9.]/g;
    this.setState({ [stateKey]: text.replace(regexp, '') });
  };

  handleOnChangeHours = (stateKey, hours) => {
    const regexp = /^\d*(\.\d{0,2})?$/;
    if (regexp.test(hours)) {
      this.setState({ [stateKey]: hours });
    }
  };

  handleStateChange = (stateKey, value, nestedStateKey) => {
    if (nestedStateKey) {
      this.setState({ [stateKey]: { [nestedStateKey]: value } });
    } else {
      this.setState({ [stateKey]: value });
    }
  };

  handleFinish = () => {
    if (!this.state.allowFinish) return;

    this.setState({ allowFinish: false }, () => {
      if (this.props.location.state.edit) {
        this.editCondition();
      } else {
        this.addCondition();
      }
    });
  };

  render() {
    const { isEdit } = this.state;
    const {
      area,
      part,
      damage,
      severity,
      selectedRow,
    } = this.props.location.state.condition;

    const actionItems = (this.props.condition.severity.values || []).filter(
      obj => {
        return (
          obj.repairAction !== '' &&
          this.props.location.state.condition.severity ===
            obj.severityDescription
        );
      }
    );
    const edit = this.props.location.state.edit;

    return (
      <Layout>
        <div className="flex justify-between h-100">
          <div className="form-container w-100">
            <div className="form-header form-sub-section">
              <div
                onClick={() => {
                  if (this.props.location.state.prevPage === 'severity') {
                    this.props.navigate(
                      isEdit
                        ? '/core/conditionReview/severity'
                        : '/core/condition/severity',
                      {
                        state: {
                          condition: {
                            area: area,
                            part: part,
                            damage: damage,
                            selectedRow: selectedRow,
                          },
                          edit,
                          selectedCrType: this.props.location.state
                            .selectedCrType,
                        },
                      }
                    );
                  } else {
                    this.props.navigate(
                      isEdit
                        ? '/core/conditionReview/damage'
                        : '/core/condition/damage',
                      {
                        state: {
                          condition: {
                            area: area,
                            part: part,
                            selectedRow: selectedRow,
                          },
                          edit,
                          selectedCrType: this.props.location.state
                            .selectedCrType,
                        },
                      }
                    );
                  }
                }}
              >
                <Icon name="LeftArrow" size={20} color={CYAN_01} />
                {this.props.location.state.prevPage === 'severity'
                  ? 'Severity'
                  : 'Damage'}
              </div>
            </div>
            <ConditionForm
              area={area}
              part={part}
              damage={damage}
              severity={severity}
              actionItems={actionItems}
              {...this.state}
              handleOnChangeTextPrice={this.handleOnChangeTextPrice}
              handleOnChangeHours={this.handleOnChangeHours}
              handleStateChange={this.handleStateChange}
            />
            <ConditionFooter handleFinish={this.handleFinish} isEdit={isEdit} />
          </div>
        </div>
      </Layout>
    );
  }
}
ConditionInfo.propTypes = {
  condition: PropTypes.object.isRequired,
  edit: PropTypes.object,
  item: PropTypes.object.isRequired,
  selectedCondition: PropTypes.object,
  updateItem: PropTypes.func.isRequired,
  updatePhotos: PropTypes.func.isRequired,
  updateSelectedCondition: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { condition, item, selectedCondition } = state.core;
  return { condition, item, selectedCondition };
};

export default connect(
  mapStateToProps,
  { updateItem, updateSelectedCondition, updatePhotos }
)(ConditionInfo);
