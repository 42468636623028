import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback3DrRight extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#656565"
            d="M138.4,196.4v0.3l2.1,4.3l0.1,0.1l17.9,36.7l0.1,0.1l0.4-0.3l-17.7-36.6v-0.3l-2.1-4.3l-0.1-0.1L138.4,196.4
	L138.4,196.4z M158.6,237.9l-0.4,0.6l2.2,4.8l1.5,3.4l1.9-0.6l-1.8-3.4l-2.6-5h-0.6L158.6,237.9L158.6,237.9z"
          />
          <path
            fill="#EBEBEB"
            d="M160.4,243.3l-9.8,4.1c2.5,0.6,5.5,0.6,8.9,0l2.5-0.7L160.4,243.3L160.4,243.3z M164.8,245.6
	c42.4-17.9,76.3-30.3,102.1-37.1l-1.9-2.9c-27.5,6.7-53,15.4-76.3,25.9L162,242.6l1.8,3.4L164.8,245.6L164.8,245.6z"
          />
          <path
            fill="#FFFFFF"
            d="M163.8,246.1l-1.9,0.6l-2.5,0.7c-2.8,0.7-5.8,1.4-9.1,1.9l-7.7,1.1l-31.1,2.9c-0.7,0-1.4,0.4-1.9,1.1
	l18.4,4.5h0.1c7.3,2.1,14.4,3.3,21.5,3.9h0.4l47.3,1.2l146.5,5.5l3.6-10.3l-2.6-0.1l-42.1-1.6c-5.2-0.4-9.8-1.4-13.5-2.9
	c-1.7-0.7-3.2-1.4-4.5-2.2c-8.1-4.4-17.6-11.4-28.6-21c-2.5-2.8-0.3-5.6,6.6-8.7l19.7-7c5.4-1.6,10.7-2.9,16.2-3.9
	c4.7-0.8,9.4-1.2,14-1.5c10.3-0.3,20.6-0.4,31.1-0.6h13.5c21.5-0.1,42.9,0.1,64.4,0.6l31.2,2.1c7.8,0.8,13.8,0.7,17.7-0.6l1-0.3
	c-18.3-5.8-54.9-9.2-110.1-10.2c-25.3-1.1-49.4-0.4-71.9,2.1c-6.6,1.1-14.3,2.9-22.7,5.2c-25.7,7-59.7,19.4-102.1,37.1L163.8,246.1
	L163.8,246.1z"
          />
          <path
            fill="#656565"
            d="M146.4,249.1l4.1,0.1c3.3-0.6,6.3-1.1,9.1-1.9c-3.4,0.6-6.5,0.6-8.9,0L146.4,249.1z"
          />
          <path
            fill="#FFFFFF"
            d="M139.8,247.2l-44.2,2.5c-1,0-1.7,0.3-2.2,0.7l16.4,4c0.6-0.8,1.2-1.1,1.9-1.1l31.1-2.9l7.7-1.1l-4.1-0.1
	C144.3,248.8,142.1,248.1,139.8,247.2L139.8,247.2z"
          />
          <path
            fill="#FFFFFF"
            d="M92.4,251.7c2.2-0.6,2.9,1.5,2.2,6.1L92,272.1l-4.8,24.8c-1,1.6-2.2,2.9-3.6,3.9l-0.6,8.4l2.5-0.3l1.8-6.2
	l4.9-1.2c1.1-7.3,2.6-11.7,4.4-13.3c3.2-2.1,5-4.3,5.6-6.7l4.1-16.4c1-5.5,2.1-9.1,3.4-10.7l-16.4-4C93,250.6,92.7,251.1,92.4,251.7
	L92.4,251.7z"
          />
          <path
            fill="#FFFFFF"
            d="M88.6,268.9c-0.1,1.6,1,2.8,3.4,3.2l2.6-14.3c0.7-4.5,0-6.6-2.2-6.1c-0.3,0.6-0.3,1.4-0.4,2.2L88.6,268.9
	L88.6,268.9z"
          />
          <path
            fill="#979797"
            d="M96.4,291.6c1.2-1.6,3.7-2.9,7.4-3.7c2.3-0.5,4.4-1.2,6.2-1.9c-1.2-0.3-2.2-0.8-2.8-1.6
	c-0.1-0.3-0.3-1-0.4-2.2c-0.1-1.5-0.1-3.2,0.1-5.2c0.1-2.1,0.4-3.9,0.7-5.2c0.3-1.4,0.6-2.1,0.8-2.1c0.8-1.2,1.8-2.1,3-2.3l-4-0.7
	c-1,6.3-2.3,11.7-4.1,16.1c-1.1,1.8-2.9,3.9-5.4,5.9C97.7,289.1,97,290.2,96.4,291.6L96.4,291.6z"
          />
          <path
            fill="#FFFFFF"
            d="M98.2,288.4c2.5-2.1,4.3-4.1,5.4-5.9c1.9-4.4,3.3-9.9,4.1-16.1l0.1-1.2l-1.7-0.1l-4.1,16.4
	c-0.6,2.5-2.5,4.8-5.6,6.7c-1.9,1.6-3.3,6.1-4.4,13.3l1.5-0.4c1-4,1.8-7.2,2.8-9.4C97,290.2,97.7,289.1,98.2,288.4L98.2,288.4z"
          />
          <path
            fill="#979797"
            d="M112.3,267.1l1.5,0.3c2.9,0.5,4.3,1.5,4.3,3l10.2-0.1c0.3-2.6-1.5-4-5.6-4.3l-14.7-1l-0.1,1.2l4,0.7
	L112.3,267.1L112.3,267.1z"
          />
          <path
            fill="#D2D3D4"
            d="M111.7,267.1c-1.2,0.4-2.2,1.1-3,2.3c0.3,0,0.4,0.8,0.6,2.2c0,1.5,0,3.3-0.1,5.4s-0.4,3.9-0.7,5.2
	c-0.3,1.4-0.6,2.2-1,2.1l-0.1-0.1c0.6,0.8,1.5,1.4,2.8,1.6h0.1c1-1.2,1.5-2.9,1.9-5.1l0.3-2.9c0.1-2.1,0.3-3.7,0.3-5.4
	c0-2.3-0.1-4.1-0.6-5.5L111.7,267.1L111.7,267.1z"
          />
          <path
            fill="#979797"
            d="M118,271.2c0-0.3,0.1-0.5,0.1-0.8c0-1.5-1.4-2.6-4.3-3l-1.5-0.3c0.4,1.4,0.6,3.2,0.6,5.5h4.8L118,271.2
	L118,271.2z"
          />
          <path
            fill="#BDBEC0"
            d="M117.6,272.6l-2.3,8.1l-0.7,2.5h8.3l5-11.7c0.1-0.4,0.3-1,0.3-1.4l-10.2,0.1c0,0.3,0,0.5-0.1,0.8
	L117.6,272.6L117.6,272.6z"
          />
          <path
            fill="#979797"
            d="M112.8,272.6c0,1.5-0.1,3.3-0.3,5.4l-0.3,2.9h3.2l2.3-8.1h-5L112.8,272.6L112.8,272.6z"
          />
          <path
            fill="#FFFFFF"
            d="M108,265.2l14.7,1c4.1,0.3,5.9,1.6,5.6,4.3c0,0.4-0.1,1-0.3,1.4l-5,11.7l-1.8,4.3l-0.3,0.7l-3.9,9.1l8.1-0.5
	l10-24.1c2.5-6.1,7.3-9.4,14.7-9.9c-7-0.4-14.2-1.8-21.5-3.9l-0.1-0.1l-18.7-4.7c-1.4,1.6-2.5,5.2-3.4,10.7L108,265.2L108,265.2z"
          />
          <path
            fill="#FFFFFF"
            d="M107.8,271.5c-0.4,1.5-0.6,3.2-0.7,5.2s-0.1,3.9-0.1,5.2c0,1.2,0.1,1.9,0.4,2.2l0.1,0.1c0.3,0,0.6-0.7,1-2.1
	c0.3-1.4,0.6-3.2,0.7-5.2c0.1-2.1,0.3-3.9,0.1-5.4c-0.1-1.5-0.4-2.2-0.7-2.2C108.4,269.4,108.1,270.1,107.8,271.5L107.8,271.5z"
          />
          <path
            fill="#EBEBEB"
            d="M121.1,287.6h-9.2c-3.9,0.7-8.4,2.2-13.6,4.7c-0.8,1.5-1.4,4.3-1.8,8.1c3.4-0.8,6.7-1.4,10.2-1.9l1.1-8.3
	h0.1c3.9,0,7.8-0.7,12.2-2.1h-7.3c-3.4,0.5-8.1,2.1-13.8,4.3c-0.1,0.7,2.6,0.7,8.1,0c-5.5,0.7-8.3,0.7-8.1,0
	c5.8-2.3,10.3-3.7,13.8-4.3h8L121.1,287.6L121.1,287.6z"
          />
          <path
            fill="#979797"
            d="M123,283.3h-8.3c-1.2,1-2.6,1.9-4.4,2.6h-0.1c-1.8,0.8-3.9,1.4-6.2,1.9c-3.7,0.8-6.2,2.1-7.4,3.7
	c-1,2.2-1.8,5.4-2.8,9.4l2.9-0.7c0.4-4,1-6.7,1.8-8.1c5.2-2.5,9.8-4,13.6-4.7h8.9L123,283.3L123,283.3z"
          />
          <path
            fill="#EBEBEB"
            d="M120.2,288.1c-4.4,1.4-8.5,2.1-12.2,2.1c2.1,0,2.8,2.6,1.9,7.7l7-0.8l3.8-9.1h-0.6L120.2,288.1L120.2,288.1z
	"
          />
          <path
            fill="#979797"
            d="M112.3,280.9c-0.4,2.2-1.1,3.9-1.9,5.1c1.8-0.8,3.2-1.6,4.4-2.6l0.7-2.5L112.3,280.9L112.3,280.9z"
          />
          <path
            fill="#FFFFFF"
            d="M109.9,298.1l-3.2,0.5c-3.3,0.5-6.7,1.1-10.2,1.9l-2.9,0.7l-1.5,0.4l-5,1.2l-1.8,6.2l-2.5,0.3l-3.3,0.4
	c-0.8,0.1-1.4,1-1.5,2.5l-1,12.5v0.1c0,1.9,1,3,2.8,3.2h5.5c-1-1.7-2.8-2.5-5.4-2.3c2.6,0,4.4,0.8,5.4,2.3c0.3,0.4,0.4,1,0.7,1.5
	l0.8,4.8l0.1,0.8l1.1,6.5l0.1,0.8l1.1,6.2l0.1,0.8v0.3l1.1,6.7c15.4,2.6,27.7,4,36.7,4.1c-8.9-1.5-15.5-3.7-20.1-6.6
	c-0.8,1-1.9,1.4-3.3,1.4h-2.8c3.3-1.1,5.6-2.6,7.2-4.3l0.1-1v-22.7c-2.6-5.5-4.5-6.3-5.6-2.3c0.3-3.4,1.1-5.1,2.8-4.8
	c2.1-0.7,3,1.8,2.9,7.2v22.7l-0.1,1c-0.1,1.2-0.6,2.2-1.1,2.9c4.5,2.9,11.3,5.1,20.1,6.6l12.2,1.2l0.7-9.8c0.4-3.3,1-6.5,1.6-9.5
	c2.3-8.9,6.9-16.9,13.3-24.2c1.2-1.4,2.6-2.8,4-4l0.1-0.1c1.9-1.8,3.9-3.4,5.9-5l1.5-1.1l-1-0.7c-5.5-4.3-10.9-7.4-16.4-9.4
	c-8-1-16.2-1.4-24.5-1l-8.1,0.5L109.9,298.1L109.9,298.1z"
          />
          <path
            fill="#EBEBEB"
            d="M107.8,290.4l-1.1,8.3l3.2-0.5C110.7,293,110.1,290.3,107.8,290.4L107.8,290.4z"
          />
          <path
            fill="#FFFFFF"
            d="M125,296.7c8.3-0.4,16.5,0,24.5,1c5.5,1.9,10.9,5.1,16.4,9.4l1,0.7c11.4-8.1,24.5-12.2,39.5-12.2
	c15.5,0,29.3,4.4,41,13.2c2.1,1.5,4,3.2,5.9,5c5.8,5.4,10.2,11.4,13.3,17.7c2.2,4.5,3.9,9.2,4.8,14.3c0.3,1.8,0.4,3.6,0.5,5.2
	c0,1.5,0.1,3,0.1,4.5l74.8,0.1c-3-10.6-5-22.8-5.9-36.6c-0.7-19.4,0.3-35.9,3-49.5v-0.3l-146.6-5.5l-47.3-1.2h-0.3
	c-7.4,0.5-12.4,3.9-14.7,9.9L125,296.7L125,296.7z M169.2,267.8c6.1-0.8,12.2-0.8,18.7,0c2.1,0,3.7,0.7,5.1,2.1
	c1.4,1.5,2.1,3.2,2.1,5.1v6.9c0,2.1-0.7,3.7-2.1,5.2c-1.5,1.4-3.2,2.1-5.1,2.1c-6.1,0.8-12.4,0.8-18.7,0c-2.1,0-3.7-0.7-5.2-2.1
	c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9c0-2.1,0.7-3.7,2.1-5.1C165.5,268.5,167.1,267.8,169.2,267.8z"
          />
          <path
            fill="#FFFFFF"
            d="M164,269.9c-1.4,1.5-2.1,3.2-2.1,5.1v6.9c0,2.1,0.7,3.7,2.1,5.2c1.4,1.4,3.2,2.1,5.2,2.1
	c6.3,0.8,12.7,0.8,18.7,0c2.1,0,3.7-0.7,5.1-2.1c1.4-1.5,2.1-3.2,2.1-5.2v-6.9c0-2.1-0.7-3.7-2.1-5.1c-1.5-1.4-3.2-2.1-5.1-2.1
	c-6.5-0.8-12.7-0.8-18.7,0C167.1,267.8,165.5,268.5,164,269.9z"
          />
          <path
            fill="#656565"
            d="M85.6,327.8h-5.5l-1.8,2.3v2.8h4.3c0.7,0.1,1.2,0.5,1.5,1.4h2.9l-0.8-4.8C86,328.7,85.7,328.2,85.6,327.8
	L85.6,327.8z"
          />
          <path
            fill="#656565"
            d="M84.1,334.9v-0.8c-0.3-0.8-0.8-1.2-1.5-1.4l0.1,2.1h1.4L84.1,334.9L84.1,334.9z"
          />
          <path
            fill="#656565"
            d="M82.7,334.9h-2.9c-1,0-1.4,0.4-1.5,1.4l0.3,3.7h5c0.3,0,0.6,0.1,0.7,0.3c0.4,0.3,0.7,0.5,1,1.1H88l-1.1-6.5
	L82.7,334.9L82.7,334.9z"
          />
          <path
            fill="#656565"
            d="M87.1,334.9l-0.1-0.8h-3l0.1,0.8 M85.2,341.4l0.3,0.7h2.9l-0.1-0.7 M82.9,340l0.1,2.1h1.4l-0.1-1.9
	c-0.3-0.1-0.4-0.1-0.7-0.3h-0.7L82.9,340L82.9,340z M84.5,342.1h-4.1c-0.8,0-1.2,0.5-1.4,1.4l0.6,3.6h5.8c0.7,0,1.2,0.5,1.7,1.4h2.5
	l-1.1-6.2h-3.9L84.5,342.1L84.5,342.1z"
          />
          <path
            fill="#656565"
            d="M80.1,340l0.3,2.1h1.8l-0.3-2.1 M79.8,334.9h1.8v-2.2h-1.8"
          />
          <path fill="#FFFFFF" d="M149.8,262.7h-0.1h0.4" />
          <path
            fill="#656565"
            d="M206.8,310c11.8,0,22.3,3.6,31.2,10.6c1.6,1.4,3.3,2.8,4.8,4.3c1.9,1.9,3.6,3.9,5.1,5.8
	c6.6,8.7,9.9,18.8,9.9,30.3c0,4.7-0.5,9.1-1.6,13.2l9.2,1v-10c0-3,0-5.9-0.3-8.9v-0.6l-0.4-4.5c-2.1-11.8-7.6-22.3-16.4-31.2
	c-4.5-4.5-9.4-8.3-14.6-11.1c-8.3-4.4-17.5-6.7-27.6-6.7c-10,0-19.3,2.2-27.5,6.7c-2.1,1.1-4.1,2.5-6.2,3.9c-1.4,1-2.8,2.1-4,3.2
	c-1.5,1.2-2.9,2.6-4.3,4l-3.6,4c-8,9.5-12.4,20.5-13.6,32.9l-0.4,5.5l9.1,1v-2.1c0-10.7,2.9-20.4,8.8-28.8c1.8-2.6,3.9-5,6.2-7.3
	l4.8-4.3C184.5,313.6,194.9,310,206.8,310L206.8,310z"
          />
          <path
            fill="#656565"
            d="M175.5,320.6l-4.8,4.3c-2.3,2.3-4.4,4.8-6.2,7.3c-5.9,8.4-8.8,18-8.8,28.8v2.1c0.3,9.9,3.3,18.8,8.8,26.7
	c1.8,2.5,3.9,5,6.2,7.3c8.5,8.5,18.4,13.3,30,14.7l7.4,0.3l5.2-0.4c11.3-1.4,21.2-6.2,29.6-14.6l3.2-3.4l1.9-2.5
	c4-5.2,6.7-10.9,8.3-17.1c1.1-4.1,1.6-8.5,1.6-13.2c0-11.4-3.3-21.5-9.9-30.3c-1.5-2.1-3.2-4-5.1-5.8c-1.5-1.5-3.2-3-4.8-4.3
	c-8.9-7.2-19.4-10.6-31.2-10.6C194.9,310,184.5,313.6,175.5,320.6L175.5,320.6z M201.3,322.5l5.6-0.4l5.4,0.3l1.9,0.3
	c7.7,1.4,14.4,5,20.2,10.7c2.6,2.6,4.8,5.5,6.5,8.5l1,1.8c1.6,3.3,2.8,6.7,3.3,10.5v0.1l0.3,1.8c0.1,1.6,0.3,3.2,0.3,5
	c0,10.2-3.4,19.1-10.3,26.4l-1.4,1.4h0.1h-0.3c-2.8,2.6-5.6,4.8-8.7,6.6l-1.8,1c-5.1,2.5-10.6,3.6-16.6,3.6
	c-5.9,0-11.4-1.1-16.4-3.4l-1.8-0.8c-3.2-1.6-6.1-3.9-8.8-6.5l-1.4-1.4c-7-7.4-10.6-16.4-10.6-26.7c0-1.6,0.1-3.2,0.3-4.7l0.3-1.9
	c0.6-3.7,1.6-7.1,3.3-10.5l1-1.8c1.8-3.2,4-6.1,6.7-8.8c5.8-5.8,12.4-9.4,19.9-10.7L201.3,322.5L201.3,322.5z"
          />
          <path
            fill="#EBEBEB"
            d="M199.3,322.8l-0.8,13.8c-0.1,1.1,0,2.1,0.1,3.2l0.3,1.4h2.3c-0.3-0.5-0.4-1.1-0.4-1.6
	c-0.1-1-0.3-1.9-0.1-2.8l0.6-13.5v-0.5L199.3,322.8L199.3,322.8z"
          />
          <path
            fill="#EBEBEB"
            d="M197.1,326.5l-0.6,10v3.6l0.1,0.7v0.1h2.1l-0.3-1.4c-0.1-1.1-0.1-2.1-0.1-3.2l0.8-13.6l-2.1,2.9L197.1,326.5
	L197.1,326.5z M190,345.1c-1.1-0.5-2.2-0.8-3.4-1.2l-9.8-2.5l-0.7-0.1l-3.4,1.1l13.2,3.4c1.1,0.3,2.1,0.7,2.9,1.1l0.3,0.1
	c0.8,0.4,1.5,0.8,2.2,1.4l6.7,5.1c0-0.8,0.4-1.7,1-2.2c0.7-0.7,1.5-1,2.3-1l0.8,0.1c0.7,0.1,1.1,0.4,1.6,0.8c0.7,0.7,1,1.5,1,2.5
	c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.3,1c-1,0-1.8-0.3-2.3-1c-0.7-0.7-1-1.4-1-2.3v-0.3l-6.7-5.1c-0.7-0.5-1.4-1-2.2-1.4l-0.3-0.1
	c-0.8-0.4-1.8-0.7-2.9-1.1l-13.2-3.4h-0.3l-1,1.8h0.1l13.5,3.9c1,0.1,1.8,0.5,2.5,1h0.1c1,0.5,1.8,1.1,2.5,1.9l6.1,5.4
	c0.3,0.5,0.6,1.1,0.4,1.6v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-1.8,0.1h8.4c0.3-0.7,0.7-1.4,1.2-2.1l0.4-0.3c1-0.8,1.9-1.2,3.2-1.2
	c1.4,0,2.5,0.5,3.6,1.5c0.6,0.5,1,1.2,1.2,2.1h11.3l-4.7-0.4c-0.7-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c0-0.5,0.1-1.1,0.4-1.5l6.1-5.4
	c0.8-0.8,1.6-1.5,2.5-1.9l0.1-0.1c0.8-0.4,1.6-0.8,2.6-1l12.8-3.6h0.1l0.6-0.1l-1-1.8l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3
	c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5l6.6-5c0.7-0.5,1.2-1,1.9-1.2l0.4-0.3c1-0.4,1.9-0.8,2.9-1.1l13.2-3.4l-3.4-1.1l-0.8,0.1l-9.6,2.5
	c-1.2,0.3-2.3,0.7-3.4,1.2l-0.8,0.4c-0.4,0.3-0.8,0.5-1.2,0.7l-5,3.7c-0.8,0.8-1.6,1-2.5,0.4c-0.6-0.3-0.6-1,0-1.9l2.1-5.8
	c0.1-0.4,0.3-1,0.3-1.4h-1.6c-0.1,0.4-0.3,1-0.4,1.4l-2.9,8c0.8-0.3,1.6-0.1,2.3,0.3c0.8,0.4,1.4,1.1,1.6,1.9l0.1,0.8
	c0.1,0.7,0,1.2-0.3,1.8c-0.4,0.8-1.1,1.4-1.9,1.6c-1,0.3-1.8,0.3-2.6-0.1c-0.8-0.4-1.4-1.1-1.6-1.9c-0.3-1-0.3-1.8,0.3-2.6
	c0.4-0.8,1.1-1.4,1.9-1.6h0.1l2.9-8c0.1-0.4,0.3-1,0.4-1.4h-2.3c-0.1,0.4-0.4,1-0.6,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.6
	c-0.6-0.1-1-0.4-1.4-1l-3.2-7.4c-0.3-0.4-0.4-1-0.6-1.4h-2.3l0.3,1.4l2.8,7.8l-2.8-7.8l-0.3-1.4h-2.1l0.3,1.4l2.1,5.8
	c0.6,1.1,0.4,1.9-0.4,2.5c-0.4,0.4-1,0.3-1.9-0.5l-5-3.7c-0.4-0.3-0.8-0.7-1.4-0.8L190,345.1L190,345.1z M168.4,354.5l-0.3,1.9
	l0.4,0.1l0.1,0.1l6.1,2.3l1.1,0.4h5.9c0,0-0.1-0.1-0.3-0.1l-6.6-2.5L168.4,354.5L168.4,354.5z"
          />
          <path
            fill="#EBEBEB"
            d="M168.7,356.7l-0.3-0.1l-0.1-0.1l2.2,2.9l0.4,0.3h5.1l-1.1-0.5 M214.2,322.8l-1.9-0.3l0.6,14
	c0.1,0.8,0.1,1.8-0.1,2.8l-0.4,1.6h2.3c0.1-0.3,0.1-0.7,0.3-1.1v-0.3c0.1-1,0.1-1.9,0.1-3.2l-0.8-13.2L214.2,322.8L214.2,322.8
	L214.2,322.8z"
          />
          <path
            fill="#EBEBEB"
            d="M214.2,323.2l0.8,13.2c0,1.1,0,2.2-0.1,3.2v0.3c0,0.3-0.1,0.7-0.3,1.1h2.1v-0.3l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6l-0.6-10v-0.7l-2.1-2.9L214.2,323.2L214.2,323.2z M244.7,354.3l-12.7,4.7c-0.3,0.1-0.7,0.3-1,0.4h5.9l8.4-3.3
	h0.1l-0.3-1.8H244.7L244.7,354.3z"
          />
          <path fill="#EBEBEB" d="M237,359.5h5.1l0.4-0.3l0.7-0.3l2.2-2.9" />
          <path
            fill="#EBEBEB"
            d="M211.7,361.1c0,0.4,0,0.8-0.1,1.2c-0.1,0.7-0.6,1.4-1,1.9l-0.3,0.4c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2
	c-1.1-0.1-2.1-0.6-2.9-1.4c-1-1-1.4-2.1-1.5-3.3v-0.1c0-0.5,0-1.1,0.3-1.5h-8.4l-6.2,0.5c-1.2,0.1-2.2,0.3-3.2,0
	c-1-0.1-1.8-0.3-2.5-0.7h-5.9l5,1.9c1,0.4,1.9,0.7,3,1l2.8,0.3l8.3-0.3l-8.3,0.3l-2.8-0.3c-1-0.3-2.1-0.5-3-1l-5-1.9h-5.2l0.3,0.1
	l3.6,1.4l5.6,2.2c1.1,0.4,2.2,0.8,3.4,1.1h8.7c1.2-0.3,1.8,0,1.9,0.5c0.6,1,0.3,1.6-0.8,2.2l-3.2,2.2l-3.2,2.3l-0.4,0.4
	c-0.8,0.8-1.5,1.8-2.2,2.9l-1.9,3h2.2l1.2-1.9c0.6-0.8,1.1-1.6,1.9-2.5l0.1-0.1c0.6-0.7,1.2-1.2,1.9-1.6l7-4.7
	c-0.8-0.4-1.4-1-1.8-1.6c-0.4-0.8-0.6-1.6-0.3-2.6l0.3-0.7c0.3-0.5,0.7-1,1.4-1.2c0.8-0.4,1.6-0.5,2.6-0.1c1,0.3,1.5,0.8,1.9,1.6
	c0.4,0.8,0.6,1.6,0.3,2.6c-0.3,1-0.8,1.5-1.6,1.9c-0.8,0.4-1.6,0.5-2.6,0.3H197l-7,4.7c-0.7,0.5-1.4,1.1-1.9,1.6l-0.1,0.1
	c-0.8,0.8-1.4,1.6-1.9,2.5l-1.2,1.9h2.5l0.4-0.5c0.4-0.8,1-1.5,1.6-2.2c0.7-0.7,1.7-1.2,2.6-1.8l6.9-4.1h1.6l0.3,0.1l0.3,0.3
	c0.4,0.4,0.6,1,0.6,1.5l-1.5,6.7h2.6l1.5-5.2l-1.5,5.2h2.1l0.6-2.1c0.1-1.2,0.6-1.8,1.1-1.7c1.1-0.3,1.6,0.3,1.8,1.4l0.7,2.2h2.1
	l-1.6-5.6c-0.6,0.7-1.2,1.1-2.1,1.2c-1,0.1-1.8-0.1-2.5-0.7l-0.6-0.5c-0.4-0.4-0.7-1-0.8-1.6c-0.1-1,0.1-1.8,0.7-2.5
	c0.6-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0.1,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2c0.1,1,0,1.8-0.6,2.5l-0.1,0.1l1.6,5.6h2.6l-1.5-6.9
	c0-0.7,0.3-1.2,0.6-1.5l0.3-0.3l0.3-0.1l1.7-0.1l6.9,4.1c1,0.4,1.8,1.1,2.6,1.8v0.1c0.7,0.7,1.4,1.4,1.8,2.2l0.6,0.8h2.5l-1.2-2.1
	c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7l6.9,4.7c0.7,0.5,1.2,1,1.8,1.5l0.3,0.3c0.7,0.7,1.4,1.5,1.9,2.5
	l1.2,2.1h2.3l-2.1-3.2c-0.7-1.1-1.4-2.1-2.2-2.9l-0.6-0.5l-0.6-0.6c-0.1-0.1-0.4-0.3-0.6-0.4l-5.1-3.6c-1.1-0.5-1.5-1.1-1.1-1.5
	c0-1.1,0.7-1.5,1.9-1.2h7.7l0.7-0.1c1.1-0.3,2.2-0.5,3.4-1.1l8.8-3.4h-5.2l-4.3,1.6c-1.1,0.4-2.1,0.7-3,0.8L229,362l-2.3,0.1
	l-8.4-0.3c0.4,0.7,0.7,1.5,0.4,2.3c-0.1,1-0.6,1.7-1.4,2.2l-0.7,0.4c-0.6,0.3-1.1,0.3-1.8,0.3c-1-0.1-1.6-0.7-2.2-1.4
	c-0.6-0.8-0.8-1.6-0.7-2.5c0.1-1,0.7-1.6,1.4-2.2c0.8-0.5,1.6-0.8,2.5-0.5c1,0.1,1.6,0.5,2.2,1.4l0.1,0.1l8.4,0.3l2.3-0.1l0.4-0.1
	c1-0.1,1.9-0.4,3-0.8l4.3-1.6h-5.9L229,360c-1,0.1-2.1,0.1-3.2,0l-3.3-0.3h-11.3C211.7,360,211.7,360.5,211.7,361.1L211.7,361.1z"
          />
          <path
            fill="#EBEBEB"
            d="M213.8,378h-2.6l0.8,2.5c0.3,0.8,0.6,1.5,1,2.3l0.1,0.3c0.4,0.8,1,1.6,1.8,2.6l8.4,10.2l0.3,0.3l0.1,0.1
	l1.8-1l-8.8-11c-0.6-0.7-1.1-1.4-1.5-2.2V382c-0.4-1-0.7-1.9-1-3L213.8,378L213.8,378z"
          />
          <path
            fill="#EBEBEB"
            d="M209.1,378l1.1,3.7l0.7,1.5l0.3,0.5c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.8l0.4,0.5l3.3,1.1l-0.3-0.3l-8.4-10.2
	c-0.7-0.8-1.2-1.8-1.8-2.6l-0.1-0.3c-0.4-0.8-0.7-1.5-1-2.3l-0.8-2.5L209.1,378L209.1,378L209.1,378z M235.4,387.5v-3.7l-0.4-0.5
	l-3.3-5.2h-2.3"
          />
          <path
            fill="#EBEBEB"
            d="M235.4,387.5l-6.1-9.5h-2.5l7.2,10.9 M182.3,378.4l-3.3,5.1l-0.4,0.5v3.6l0.1-0.1l6.1-9.5h-2.2 M202.8,378
	l-0.8,2.6c-0.3,0.8-0.6,1.5-1,2.2l-0.1,0.3c-0.6,1-1.1,1.8-1.8,2.6l-8.7,10.6l3.4-1.1l0.4-0.5l6.3-7.7l2.1-2.9l0.4-0.7
	c0.3-0.4,0.4-1,0.6-1.4l1.2-3.9h-2.1L202.8,378L202.8,378z"
          />
          <path
            fill="#EBEBEB"
            d="M200,379.1c-0.3,1.2-0.6,2.3-1,3c-0.4,0.8-1,1.6-1.5,2.3l-8.8,11l-0.1,0.1l1.8,0.8l0.1-0.1l8.7-10.6
	c0.7-0.8,1.2-1.6,1.8-2.6l0.1-0.3c0.4-0.7,0.7-1.5,1-2.2l0.8-2.6h-2.6L200,379.1L200,379.1z M178.4,387.7l1.5,1.4l7.3-11.1h-2.5
	l-6.1,9.5l-0.1,0.1 M203.3,357.5c-1,1-1.5,2.2-1.5,3.6v0.3c0.1,1.2,0.6,2.3,1.5,3.3c0.8,0.8,1.8,1.2,2.9,1.4h1.2
	c1.1-0.1,2.1-0.6,2.9-1.4l0.3-0.4c0.4-0.5,0.8-1.2,1-1.9c0.1-0.4,0.1-0.8,0.1-1.2c0-1.4-0.6-2.5-1.4-3.6c-1-1-2.2-1.5-3.6-1.5
	c-1.2,0-2.3,0.4-3.2,1.2L203.3,357.5L203.3,357.5z M203.3,359.1l0.6-0.3c0.8-0.4,1.8-0.7,2.9-0.5l0.8,0.1c0.7,0.1,1.4,0.4,1.8,0.7
	l0.6,0.4l0.4,0.5c0.1,0.1,0.3,0.4,0.3,0.7c0,0.3,0.1,0.5,0,0.8l-0.3,0.7l-0.4,0.5l-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.5l-1.6,0.1
	l-1.5-0.3l-1-1.1l-0.6-0.4l-0.4-0.5l-0.3-0.5v-0.8c0-0.3,0-0.4,0.1-0.7l0.4-0.5L203.3,359.1L203.3,359.1z"
          />
          <path
            fill="#EBEBEB"
            d="M202.9,359.5l-0.4,0.5c-0.1,0.3-0.1,0.4-0.1,0.7v0.8l0.3,0.5l0.4,0.6l0.6,0.4l1.2,0.5l1.5,0.3l1.6-0.1
	c0.4-0.1,0.8-0.3,1.2-0.5l1-0.8l0.4-0.5l0.3-0.7c0-0.3,0-0.5-0.1-0.8c0-0.3-0.1-0.4-0.3-0.7l-0.4-0.6l-0.6-0.4
	c-0.6-0.3-1.1-0.5-1.8-0.7l-0.8-0.1c-1.1-0.1-2.1,0.1-2.9,0.6l-0.6,0.4L202.9,359.5L202.9,359.5z M206.5,358.4v0.8
	c0,0,0,0.5-0.1,0.8v0.1c0,0.1-0.1,0.1-0.1,0.1h-3c0.1-0.7,0.6-1.1,1.2-1.5l1-0.4L206.5,358.4L206.5,358.4z M206.5,358.4
	c0.8,0,1.5,0.1,2.1,0.5c0.3,0.1,0.6,0.3,0.7,0.5l0.4,0.8h-2.3l-0.4-1.1C207,359,206.9,358.7,206.5,358.4L206.5,358.4z M206.6,360.5
	v0.7h-0.1v-0.7H206.6L206.6,360.5z M206.8,360.5h0.7l0.4-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0,0.1,0,0.1,0.1v0.5c0,0.1-0.1,0.1-0.1,0.1
	h-1.4v-0.1h1.2v-0.4h-0.3L206.8,360.5L206.8,360.5z M207,361.5h2.9c-0.3,0.5-0.7,1.1-1.2,1.5c-0.7,0.4-1.4,0.5-2.1,0.5v-1
	c0-0.3,0.1-0.5,0.3-0.8l0.1-0.1L207,361.5L207,361.5z M206.2,361.2c0,0-0.1,0.1-0.3,0.1h-1.2l-0.1-0.1v-0.4l0.1-0.1
	c0,0,0.1-0.1,0.3-0.1l0.4,0.1l0.3-0.1l0.3,0.1h0.3L206.2,361.2L206.2,361.2L206.2,361.2z M204.6,361.2h-0.1v-0.5h-1.1v0.5
	c-0.1,0-0.1,0-0.3-0.1v-0.5l0.1-0.1h1.4L204.6,361.2L204.6,361.2z M205.5,361.5l0.1,0.7l0.3,0.5l0.6,0.8l-1.2-0.1l-1.1-0.4
	c-0.7-0.4-1.1-0.8-1.2-1.5H205.5L205.5,361.5z M209.9,360.6v0.3h-1.2l0.1,0.1h1.2l-0.1,0.1h-1.4v-0.3h1.2v-0.1h-1.2v-0.1h0.7
	l0.3-0.1l0.3,0.1C209.9,360.5,209.9,360.6,209.9,360.6L209.9,360.6z"
          />
          <path
            fill="#EBEBEB"
            d="M205.2,358.6l-1,0.4c-0.7,0.4-1.1,0.8-1.2,1.5h3c0.1,0,0.1-0.1,0.1-0.1l0.1-0.3c0.1-0.1,0.1-0.4,0.1-0.8
	v-0.8L205.2,358.6L205.2,358.6z M206.6,358.4c0.3,0.3,0.4,0.5,0.6,0.8l0.4,1.1h2.3l-0.4-0.8c-0.1-0.3-0.4-0.4-0.7-0.5
	C208,358.6,207.3,358.4,206.6,358.4L206.6,358.4z M206.5,361.2h0.1v-0.7h-0.1"
          />
          <path
            fill="#EBEBEB"
            d="M206.8,360.6h1.2v0.4h-1.2v0.1h1.4c0.1,0,0.1,0,0.1-0.1v-0.5c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.3-0.1
	l-0.4,0.1L206.8,360.6L206.8,360.6z M206.9,361.6l-0.1,0.1c-0.1,0.3-0.1,0.4-0.3,0.8v1c0.8,0,1.5-0.1,2.1-0.5s1-1,1.2-1.5h-2.9
	L206.9,361.6L206.9,361.6z M206.2,360.6l-0.3-0.1h-1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.1v0.4l0.1,0.1h1.2c0.1,0,0.1-0.1,0.3-0.1
	L206.2,360.6L206.2,360.6z M205.5,360.6h0.6v0.4H205v-0.4L205.5,360.6L205.5,360.6z"
          />
          <path
            fill="#EBEBEB"
            d="M205,360.6v0.4h1.1v-0.1l-0.1-0.3h-0.7 M204.6,360.5h-1.4c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.3,0.1
	v-0.5h1.1v0.5h0.1L204.6,360.5L204.6,360.5z M203,361.5c0.1,0.7,0.6,1.2,1.2,1.5l1.1,0.4l1.2,0.1l-0.6-0.8l-0.3-0.6l-0.1-0.7
	L203,361.5L203,361.5z M209.8,360.5h-1.4v0.1h1.2v0.1h-1.2v0.3h1.4l0.1-0.1h-1.2l-0.1-0.1h1.2L209.8,360.5
	C209.9,360.6,209.9,360.5,209.8,360.5L209.8,360.5z M197.3,325.8l2.1-2.9v-0.1c-7.6,1.4-14.2,5-19.9,10.7c-2.8,2.8-5,5.6-6.7,8.8
	h0.3l3.4-1.1l0.7,0.1c1.4-1.9,2.9-3.9,4.7-5.6c4.5-4.5,9.8-7.7,15.5-9.2L197.3,325.8L197.3,325.8z M201.3,322.5v0.5 M201.3,323.1
	l-0.6,13.5c-0.1,0.8,0,1.8,0.1,2.8c0.1,1,0.6,1.9,1.1,3l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.6c0.6-0.1,1-0.4,1.4-1l3.2-7.4
	c0.6-1.1,1-2.1,1.1-3c0.1-1,0.3-1.9,0.1-2.8l-0.6-14l-1.5,2.2v0.8l0.4,12.2l-0.1,1.1c-0.1,0.5-0.4,1.1-0.7,1.6l-1.8,3.2
	c-0.3,0.7-1,1.1-1.6,1.1h-0.7c-0.7,0-1.2-0.3-1.7-1.1l-1.8-3.2c-0.3-0.6-0.6-1.1-0.7-1.6l-0.1-1.2l0.4-12.2v-0.8l-1.5-2.2
	L201.3,323.1L201.3,323.1z"
          />
          <path
            fill="#EBEBEB"
            d="M202.6,325.6l4.1-0.3l4,0.3v-0.8l1.4-2.3l-5.4-0.3l-5.5,0.4l1.5,2.2"
          />
          <path
            fill="#656565"
            d="M202.6,325.6l-0.4,12.2l0.1,1.1l4.4-0.4l4.3,0.4l0.1-1.1l-0.4-12.2l-4-0.3"
          />
          <path
            fill="#EBEBEB"
            d="M204.7,343.7c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.6-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6l-4.3-0.4
	l-4.4,0.4c0.1,0.5,0.3,1.1,0.7,1.6L204.7,343.7L204.7,343.7z"
          />
          <path
            fill="#656565"
            d="M196.6,336.6l0.6-10c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2
	l0.7,0.3l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3l-0.1-0.7C196.6,338.9,196.6,337.7,196.6,336.6L196.6,336.6z"
          />
          <path
            fill="#EBEBEB"
            d="M196.7,340.8c-2.1,1.1-4,2.5-5.9,4.3l-0.3,0.3c0.4,0.3,1,0.5,1.4,0.8l5,3.7c0.8,0.8,1.5,1.1,1.9,0.5
	c0.8-0.5,1-1.4,0.4-2.5l-2.1-5.8L196.7,340.8L196.7,340.8z M171.8,344l1.8,2.1l0.7,0.3l3.9,1.1l8.1,2.3c0.3,0.1,0.7,0.3,1,0.4
	c0.6,0.3,1.1,0.7,1.5,1.1l1.6,1.8l0.8,0.8c0.6,0.5,0.8,1.2,0.6,1.9l-0.3,0.7c-0.1,0.4-0.4,0.8-0.8,1l-0.7,0.1l-2.3,0.4l-1.2,0.3
	h-1.8l-0.8-0.1l-0.3-0.1l-8.3-3l-3.3-1.2l-0.7-0.3l-2.6,0.7l6.5,2.5l6.6,2.5c0.8,0.4,1.8,0.7,2.8,0.7c1,0.1,2.1,0.1,3.2,0l8-0.8
	c0.6-0.1,1.1-0.5,1.4-1l0.1-0.3v-0.3c0.1-0.5-0.1-1.1-0.4-1.6l-6.1-5.4c-0.8-0.8-1.6-1.5-2.5-1.9H188c-0.8-0.4-1.6-0.8-2.5-1
	L171.8,344z"
          />
          <path
            fill="#EBEBEB"
            d="M171.8,344c-1.8,3.3-2.8,6.7-3.4,10.5l2.6-0.7l0.7,0.3c0.6-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L171.8,344
	L171.8,344z M171.7,344h0.1"
          />
          <path
            fill="#656565"
            d="M171.7,354l3.3,1.2l0.1-1.4l0.3-1.8c0.4-1.1,1.4-1.9,2.8-2.6c1.1-0.4,1.7-0.3,1.7,0.5c0,1.5,0.8,2.5,2.3,3
	c0.8,0.1,1.5,1,2.2,2.3h0.6c0.3-1.2,0.7-2.3,1.2-3.4c-0.3-0.1-0.7-0.3-1.1-0.3c-1.1,0.1-1.6-0.3-1.9-1.2c-0.1-1.1-1.2-1.6-3.3-1.8
	h-1.9v-1.2l-3.9-1.1C173.1,348.8,172.2,351.3,171.7,354L171.7,354z"
          />
          <path
            fill="#EBEBEB"
            d="M168.4,356.5h0.1l-0.4-0.1 M168.5,356.5h-0.1l0.3,0.1"
          />
          <path
            fill="#EBEBEB"
            d="M168.1,356.4c-0.1,1.5-0.3,3-0.3,4.7c0,10.3,3.6,19.3,10.6,26.7l0.1-0.1V384l0.4-0.5
	c-4.4-5.4-7-11.7-7.7-18.6c-0.1-1.2-0.1-2.5-0.1-3.9v-1.4l-0.8-0.3L168.1,356.4l0.3,0.1L168.1,356.4L168.1,356.4z"
          />
          <path
            fill="#656565"
            d="M171.1,361.1c0,1.4,0,2.6,0.1,3.9l3.7,0.4c-0.1-1.4-0.1-2.8-0.1-4.3l-3.6-1.4L171.1,361.1L171.1,361.1z
	 M178,348.7h1.9c2.1,0.1,3.2,0.7,3.3,1.8c0.3,1,1,1.4,1.9,1.2c0.4,0,0.7,0.1,1.1,0.3c0.7,0.4,1.2,1.5,1.4,3.2v3l2.3-0.4l0.7-0.1
	c0-1.8-0.1-3.2-0.3-4.4l-1.7-1.8c-0.4-0.5-1-0.8-1.5-1.1c-0.3-0.3-0.6-0.4-1-0.4l-8.1-2.3v1.1H178L178,348.7z"
          />
          <path
            fill="#EBEBEB"
            d="M175.1,355.3l0.1-1.4L175.1,355.3L175.1,355.3z"
          />
          <path
            fill="#656565"
            d="M175.3,353.9l-0.1,1.4l8.3,3l0.3,0.1c0.1-0.8,0.6-1.4,1.1-1.8c0.7-0.4,1.5-0.5,2.8-0.4v-0.8h-3
	c-0.7-1.4-1.4-2.2-2.2-2.3c-1.7-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6L175.3,353.9L175.3,353.9z"
          />
          <path
            fill="#EBEBEB"
            d="M190.2,353.1c0.1,1.1,0.3,2.6,0.3,4.4c0.4-0.3,0.7-0.5,0.8-1l0.3-0.7c0.3-0.7,0-1.4-0.6-1.9L190.2,353.1
	L190.2,353.1z M185,355.3h2.5V355c-0.1-1.6-0.6-2.8-1.4-3.2C185.7,353.1,185.3,354.2,185,355.3L185,355.3z"
          />
          <path
            fill="#656565"
            d="M187.5,356.2c-1.2-0.1-2.2,0-2.8,0.4c-0.6,0.4-1,1-1.1,1.8l0.8,0.1h1.8l1.2-0.3L187.5,356.2L187.5,356.2z"
          />
          <path
            fill="#EBEBEB"
            d="M190.5,364.4c-0.1,2.1-0.3,3.7-0.4,5l3.2-2.2c1.1-0.5,1.4-1.2,0.8-2.2c0-0.5-0.7-0.8-1.9-0.5H190.5
	L190.5,364.4z M184.6,364.8l-0.1-0.4h-0.3"
          />
          <path
            fill="#656565"
            d="M187.5,366.3c-0.1,1.8-0.7,2.9-1.6,3.3l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9
	l1.2,5.9l3-0.3l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3c0.1-1.2,0.3-2.9,0.4-5l-3,0.1L187.5,366.3L187.5,366.3z"
          />
          <path
            fill="#EBEBEB"
            d="M187.5,366h-2.8v0.3c0.3,1.1,0.6,2.2,1,3.3c1-0.4,1.4-1.5,1.6-3.3L187.5,366L187.5,366z"
          />
          <path
            fill="#656565"
            d="M187.5,365.2c-1.4,0.1-2.3,0-2.9-0.4l-0.4-0.4l-0.3-0.1c-1.2-0.3-2.5-0.7-3.4-1.1l-5.6-2.2l0.3,4.3l0.3,2.2
	l0.3,1.8c0.4,1.1,1.4,1.9,2.8,2.6c1.1,0.4,1.6,0.3,1.6-0.4c0-1.5,0.8-2.5,2.3-3c0.8-0.1,1.4-1,2.1-2.2l0.1-0.3h3v-0.7L187.5,365.2
	L187.5,365.2z"
          />
          <path fill="#EBEBEB" d="M184.5,366l-0.1,0.3h0.6V366" />
          <path
            fill="#656565"
            d="M187.5,365.2v-0.7l-3-0.1v0.3C185.3,365.2,186.3,365.3,187.5,365.2z"
          />
          <path
            fill="#EBEBEB"
            d="M175,365.2l-0.3-4.3C174.7,362.4,174.8,363.9,175,365.2L175,365.2z M175.3,367.5l-0.3-2.3 M198.1,353.6
	c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1,2.3,1c1,0,1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3s-0.3-1.8-1-2.5c-0.4-0.4-1-0.7-1.6-0.8l-0.8-0.1
	c-1,0-1.8,0.3-2.3,1s-1,1.4-1,2.2v0.3H198.1z M200.3,352.4c0.3-0.3,0.7-0.5,1.2-0.5h0.4c0.3,0,0.6,0.3,0.8,0.4
	c0.4,0.3,0.6,0.7,0.6,1.2c0,0.4-0.1,0.8-0.6,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.6-0.7-0.6-1.2
	C199.7,353.2,199.9,352.8,200.3,352.4L200.3,352.4z"
          />
          <path
            fill="#EBEBEB"
            d="M199.7,353.6c0,0.4,0.1,0.8,0.6,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5c0.4-0.3,0.6-0.7,0.6-1.2
	s-0.1-1-0.6-1.2c-0.3-0.1-0.6-0.4-0.8-0.4h-0.4c-0.4,0-0.8,0.1-1.2,0.4C199.9,352.8,199.7,353.2,199.7,353.6L199.7,353.6z
	 M209,354.6c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.6,0.5,2.6,0.1c1-0.3,1.5-0.8,1.9-1.7c0.3-0.5,0.4-1.1,0.3-1.8l-0.1-0.8
	c-0.3-0.8-0.8-1.5-1.6-1.9c-0.8-0.4-1.7-0.5-2.3-0.3h-0.1c-1,0.3-1.5,0.8-1.9,1.6C208.8,352.8,208.7,353.6,209,354.6L209,354.6z
	 M210.6,354c-0.1-0.4-0.1-0.8,0.1-1.2c0.3-0.4,0.6-0.7,1-0.8l1.2,0.1c0.4,0.3,0.7,0.5,0.8,1v0.4c0.1,0.3,0,0.5-0.1,1
	c-0.3,0.4-0.6,0.7-1,0.8l-1.4-0.1C211,354.9,210.7,354.5,210.6,354L210.6,354z"
          />
          <path
            fill="#EBEBEB"
            d="M211.4,355l1.4,0.1c0.4-0.1,0.8-0.4,1-0.8c0.1-0.3,0.3-0.7,0.1-1v-0.4c-0.1-0.4-0.4-0.8-0.8-1l-1.2-0.1
	c-0.4,0.1-0.8,0.4-1,0.8c-0.1,0.4-0.3,0.8-0.1,1.2C210.7,354.5,211,354.9,211.4,355L211.4,355z M201.4,365c0.3-1,0.3-1.8-0.1-2.6
	c-0.4-0.8-1.1-1.4-1.9-1.6c-1-0.3-1.8-0.3-2.6,0.1c-0.6,0.3-1.1,0.7-1.4,1.2l-0.3,0.7c-0.3,1-0.3,1.8,0.3,2.6c0.4,0.8,1,1.4,1.8,1.6
	h0.1c1,0.3,1.8,0.3,2.6-0.3C200.6,366.6,201.1,365.9,201.4,365L201.4,365z M199.6,363.1c0.3,0.4,0.3,0.8,0.1,1.4
	c-0.1,0.4-0.4,0.7-0.8,1l-1.2,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.3-0.4-0.3-0.8-0.1-1.2l0.1-0.4c0.1-0.3,0.3-0.4,0.7-0.7l1.2-0.1
	C199.2,362.4,199.5,362.7,199.6,363.1L199.6,363.1z"
          />
          <path
            fill="#EBEBEB"
            d="M198.6,362.3l-1.2,0.1c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.4c-0.1,0.4-0.1,1,0.1,1.2c0.1,0.4,0.6,0.7,1,0.8h1.2
	c0.4-0.3,0.7-0.5,0.8-1c0.1-0.4,0.1-1-0.1-1.4C199.5,362.7,199.2,362.4,198.6,362.3L198.6,362.3z M201.1,369.7l-0.3-0.3l-0.3-0.1
	h-1.6l-6.9,4.1c-1.1,0.5-1.9,1.1-2.6,1.8c-0.7,0.7-1.2,1.4-1.6,2.2l-7.7,11.7l2.5-1l0.4-0.7l6.9-10.2l0.7-0.8c0.4-0.4,1-0.7,1.5-1.1
	l3.3-1.5l1.9,0.1l0.3,0.3l0.3,0.1c0.6,0.4,0.8,1.1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8l-0.6,1l-7.6,9.8l-0.4,0.5l-0.1,2.6
	l8.7-11c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.7-1.8,1-3l1.8-7.8C201.7,370.7,201.5,370.1,201.1,369.7L201.1,369.7z"
          />
          <path
            fill="#EBEBEB"
            d="M197.8,374.4l-0.3-0.1l-0.3-0.3l-1.9-0.1l-3.3,1.5c-0.6,0.3-1.1,0.7-1.5,1.1l0.4,0.4
	c2.1,2.1,4.1,3.6,6.6,4.7c0.3-0.5,0.4-1.1,0.6-1.8l0.4-3.6C198.6,375.4,198.4,374.8,197.8,374.4L197.8,374.4z M203.6,370.8
	c0.1,0.7,0.4,1.2,0.8,1.6l0.6,0.5c0.7,0.6,1.5,0.8,2.5,0.7c0.8-0.1,1.5-0.6,2.1-1.2l0.1-0.1c0.6-0.8,0.8-1.6,0.6-2.5
	c-0.1-1-0.6-1.6-1.4-2.2c-0.8-0.5-1.6-0.7-2.5-0.5c-1,0.1-1.6,0.5-2.2,1.4C203.7,369,203.5,369.9,203.6,370.8L203.6,370.8z
	 M206.6,368.6c0.6-0.1,1,0,1.2,0.3c0.4,0.3,0.6,0.7,0.7,1.1c0.1,0.4,0,0.8-0.3,1.2c-0.3,0.4-0.7,0.5-1.1,0.7c-0.4,0-0.8,0-1.2-0.3
	l-0.3-0.3c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0-1,0.3-1.2C205.8,368.9,206.2,368.6,206.6,368.6L206.6,368.6z"
          />
          <path
            fill="#EBEBEB"
            d="M205.5,369.2c-0.3,0.3-0.4,0.8-0.3,1.2c0,0.4,0.1,0.7,0.4,0.8l0.3,0.3c0.4,0.3,0.8,0.4,1.2,0.3
	c0.6-0.1,0.8-0.3,1.1-0.7c0.3-0.4,0.4-0.8,0.3-1.2c0-0.4-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.2-0.3
	C206.2,368.6,205.8,368.9,205.5,369.2L205.5,369.2z M205.5,375.9l-1.8,5.9c-0.1,0.5-0.3,1-0.6,1.4l3.6,0.3l4-0.3l-0.7-1.5l-1.8-5.9
	c-0.1-1.2-0.8-1.6-1.8-1.4C206.1,374.3,205.7,374.8,205.5,375.9L205.5,375.9z M179.9,389.1c2.8,2.6,5.6,4.8,8.8,6.5l0.1-0.1l0.1-2.6
	l0.4-0.5c-2.3-1.4-4.4-2.9-6.5-4.8l-0.4,0.7L179.9,389.1L179.9,389.1z M190.5,396.4c5,2.3,10.5,3.4,16.4,3.4
	c6.1,0,11.6-1.2,16.6-3.6l-0.1-0.1l-3.3-1.1l-0.4-0.5c-4,1.5-8.3,2.2-12.8,2.2c-4.4,0-8.5-0.7-12.4-2.1l-0.4,0.5l-3.4,1.1
	L190.5,396.4L190.5,396.4z M240.9,341.9c-1.6-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7l0.1,0.5v-0.4l2.1,2.9v0.7
	c5.9,1.5,11.1,4.5,15.8,9.2c1.8,1.7,3.2,3.4,4.5,5.4l0.8-0.1L240.9,341.9L240.9,341.9z M241.1,343.9l0.6-0.1h0.1"
          />
          <path
            fill="#EBEBEB"
            d="M241.8,343.7l-1.6,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C244.6,350.5,243.5,347,241.8,343.7
	L241.8,343.7z"
          />
          <path
            fill="#EBEBEB"
            d="M241.7,343.7l-0.6,0.1H241l-12.8,3.6c-1,0.3-1.8,0.5-2.6,1l-0.1,0.1c-0.8,0.4-1.6,1.1-2.5,1.9l-6.1,5.4
	c-0.4,0.5-0.6,1.1-0.4,1.5v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.3,3.2,0c1.1-0.1,1.9-0.4,2.8-0.8l12.7-4.7
	l0.4-0.1l-2.6-0.7l-0.7,0.3l-11.6,4.3l-1.1,0.3l-1.8-0.1l-3.6-0.7c-0.8-0.1-1.4-0.5-1.5-1.2l-0.3-0.7c-0.3-0.7,0-1.4,0.6-1.9
	l2.5-2.6c0.4-0.4,1-0.8,1.4-1.1c0.4-0.3,0.8-0.4,1.1-0.5l11.8-3.4l0.7-0.3L241.7,343.7L241.7,343.7z"
          />
          <path
            fill="#656565"
            d="M232.1,335.7c-4.7-4.7-9.9-7.7-15.8-9.2l0.6,10c0.1,1.2,0.1,2.3,0,3.6l-0.1,0.7c2.2,1.1,4.1,2.5,6.1,4.4
	l0.1,0.1l0.8-0.4c1.1-0.5,2.2-0.8,3.4-1.2l9.6-2.5C235.4,339.3,233.9,337.5,232.1,335.7L232.1,335.7z"
          />
          <path
            fill="#EBEBEB"
            d="M212.3,322.4h-0.1H212.3 M216.2,342.4l-2.1,5.8c-0.6,1.1-0.6,1.6,0,1.9c0.8,0.7,1.6,0.4,2.5-0.4l5-3.7
	c0.4-0.3,0.8-0.5,1.2-0.7l-0.1-0.1c-1.9-1.8-3.9-3.3-6.1-4.4C216.7,341.2,216.5,341.8,216.2,342.4L216.2,342.4z"
          />
          <path
            fill="#656565"
            d="M226.4,349.9c1.5,2.5,2.3,5.2,2.8,8.3l1.1-0.3l11.6-4.3c-0.6-2.8-1.4-5.2-2.5-7.7l-11.8,3.4
	C227.3,349.6,226.8,349.8,226.4,349.9L226.4,349.9z"
          />
          <path
            fill="#EBEBEB"
            d="M222,355.6l0.3,0.7c0.1,0.7,0.7,1.1,1.5,1.2l3.6,0.7l1.8,0.1c-0.3-3-1.2-5.6-2.8-8.3c-0.6,0.3-1,0.7-1.4,1.1
	l-2.5,2.6C222,354.2,221.9,354.9,222,355.6L222,355.6z"
          />
          <path
            fill="#656565"
            d="M229.2,364.1c-0.3,2.5-1,4.7-2.1,6.9l13.8,1.5c1.2-3.6,1.8-7.4,1.8-11.4v-1.6l-9.2,3.6
	c-1.2,0.5-2.3,0.8-3.4,1.1L229.2,364.1L229.2,364.1z"
          />
          <path
            fill="#EBEBEB"
            d="M221.5,364.1c-1.2-0.3-1.8,0.3-1.9,1.2c-0.3,0.6,0,1,1.1,1.5l5.1,3.6c0.3,0.1,0.4,0.3,0.6,0.4l0.8,0.1
	c1.1-2.2,1.8-4.4,2.1-6.9L221.5,364.1L221.5,364.1z M213.6,361.1c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5s1.2,1.2,2.2,1.4
	c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4c0.8-0.5,1.2-1.2,1.4-2.2c0.1-0.8,0-1.6-0.4-2.3l-0.1-0.1c-0.6-0.8-1.2-1.2-2.2-1.4
	C215.3,360.4,214.5,360.5,213.6,361.1L213.6,361.1z M217.4,364.1c-0.1,0.4-0.3,0.8-0.7,1.1l-0.4,0.1l-1,0.1c-0.4,0-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.4-0.8-0.3-1.2c0-0.5,0.3-0.8,0.7-1.1c0.4-0.3,0.8-0.4,1.2-0.3c0.4,0.1,0.8,0.3,1.1,0.7
	C217.2,363.3,217.4,363.7,217.4,364.1L217.4,364.1z"
          />
          <path
            fill="#EBEBEB"
            d="M217.1,362.8c-0.3-0.4-0.7-0.5-1.1-0.7c-0.4-0.1-0.8,0-1.2,0.3s-0.6,0.7-0.7,1.1c-0.1,0.4,0,0.8,0.3,1.2
	c0.3,0.4,0.7,0.7,1.1,0.7l1-0.1l0.4-0.1c0.4-0.3,0.6-0.7,0.7-1.1C217.4,363.7,217.2,363.3,217.1,362.8L217.1,362.8z M245.1,354.2
	l-0.4,0.1h0.4 M245.3,354.2h-0.1 M245.4,356.1l-2.2,2.9l-0.7,0.3v1.6c0,4.1-0.6,7.8-1.8,11.4c-1.2,3.9-3.2,7.4-5.9,10.7l0.4,0.7v3.6
	c6.9-7.4,10.3-16.2,10.3-26.4C245.7,359.4,245.7,357.8,245.4,356.1L245.4,356.1z M234,388.8h0.1 M234.1,388.8H234H234.1v0.1"
          />
          <path
            fill="#EBEBEB"
            d="M234,388.8l-7.7-11.7c-0.4-0.8-1.1-1.5-1.8-2.2v-0.1c-0.8-0.7-1.6-1.2-2.6-1.8l-6.9-4.1l-1.6,0.1l-0.3,0.1
	l-0.3,0.3c-0.3,0.4-0.6,0.8-0.6,1.5l1.8,7.8c0.3,1.1,0.6,2.2,1,3v0.1c0.4,0.8,1,1.6,1.5,2.2l8.7,11l-0.1-2.8l-0.4-0.5l-7.6-9.8
	l-0.6-1c-0.3-0.5-0.4-1.1-0.6-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.3l0.3-0.3h1.9l3.3,1.5c0.6,0.3,1.1,0.5,1.4,1l0.8,1
	l6.9,10.2l0.4,0.7L234,388.8L234,388.8z"
          />
          <path
            fill="#EBEBEB"
            d="M231.1,387.3c-2.1,1.9-4.3,3.4-6.5,4.8l0.4,0.5l0.1,2.8c3.2-1.7,6.1-3.9,8.8-6.6l-2.5-1L231.1,387.3
	L231.1,387.3z M215.6,376.1l0.4,3.6c0.1,0.7,0.3,1.2,0.6,1.8c2.2-1.1,4.4-2.6,6.2-4.4l0.7-0.7c-0.4-0.4-0.8-0.7-1.4-1l-3.3-1.5h-1.9
	l-0.3,0.3l-0.3,0.3C215.6,374.7,215.4,375.2,215.6,376.1L215.6,376.1z M227.1,371l-0.8-0.1l0.6,0.5 M223.1,395.9l0.3,0.3"
          />
          <path
            fill="#FFFFFF"
            d="M206.2,302c10.2,0,19.3,2.2,27.6,6.7c5.2,2.9,10,6.5,14.6,11.1c8.8,9.1,14.3,19.4,16.4,31.2
	c0.3,1.5,0.4,3,0.4,4.5h6.7c0-1.5-0.1-3-0.1-4.5c-0.1-1.8-0.3-3.6-0.5-5.2c-1-5-2.6-9.8-4.8-14.3c-3.2-6.5-7.7-12.4-13.3-17.7
	c-1.9-1.8-3.9-3.4-5.9-5c-11.7-8.8-25.3-13.2-41-13.2c-15,0-28.1,4.1-39.5,12.2l5.8,5c2.1-1.5,4-2.8,6.2-3.9
	C186.9,304.2,196.2,302,206.2,302L206.2,302z"
          />
          <path
            fill="#FFFFFF"
            d="M166.7,307.7l-1.5,1.1c-2.1,1.5-4,3.2-5.9,5l-0.1,0.1c-1.4,1.4-2.8,2.6-4,4c-6.6,7.3-11,15.4-13.3,24.2
	c-0.8,3-1.4,6.2-1.6,9.5l-0.7,9.8l-0.6,9.9l7.2,1l0.7-10l0.4-5.5c1.1-12.4,5.6-23.4,13.6-32.9l3.6-4c1.4-1.5,2.9-2.8,4.3-4
	c1.4-1.1,2.6-2.2,4-3.2L166.7,307.7L166.7,307.7z"
          />
          <path
            fill="#FFFFFF"
            d="M139.6,361.3l-12.2-1.2c-9.2-0.1-21.5-1.5-36.7-4.1c0.4,2.3,1.2,4.3,2.6,5.9l0.8,0.8c1.9,1.8,4.5,3,8,3.6
	l4.4,0.5l21.9,2.8h0.1l8.7,1.1l1.9,0.3L139.6,361.3L139.6,361.3z"
          />
          <path fill="#656565" d="M82.9,349.1l-0.3-2.1h-1.9l0.3,2.1" />
          <path
            fill="#656565"
            d="M79.8,350.6c0.4,1.6,0.1,3.2-1.1,4.7c-0.3,3,0.3,7,1.7,12.1c0.4,0.8,1.5,1.8,3.2,2.6c3-3,5.4-4.5,7-4.4
	l-3.3-6.7h2.2l-1.5-2.2c-2.2-3.9-3.2-4.1-2.9-0.7c0.3,2.3,1.8,4.8,4.3,7.7c-2.5-2.8-3.9-5.4-4.3-7.7c-0.3-3.4,0.7-3.3,2.9,0.7
	l1.5,2.2c1.2,1.5,2.3,2.5,3.7,3.2c-1.4-1.6-2.2-3.6-2.6-5.9l-1.1-6.7v-0.3h-8.3C80.2,349.2,79.8,349.6,79.8,350.6L79.8,350.6z"
          />
          <path
            fill="#656565"
            d="M85,347h-1.4l0.3,2.1h1.4 M86.8,348.4l0.3,0.7h2.3l-0.1-0.7 M93.1,362c-1.2-0.7-2.5-1.6-3.7-3.2h-2.2
	l3.3,6.7l2.8,5.6h13.1v-4.1l-4.4-0.5c-3.4-0.7-6.1-1.8-8-3.6L93.1,362L93.1,362z"
          />
          <path
            fill="#656565"
            d="M128.2,369.9l-21.9-2.8v4.1h19.1C127.5,371.1,128.3,370.7,128.2,369.9L128.2,369.9z"
          />
          <path
            fill="#FFFFFF"
            d="M476.4,208.8l-4.8,2.5l-1,0.3c-4,1.2-9.9,1.4-17.7,0.6c8.4,1.9,15.4,4.4,20.9,7.4
	c16.4,8.5,29.9,16.2,40.4,23.1l2.8,0.4c4.1,1.4,7.3,3,9.4,5c2.5,2.2,3.9,4.4,4.1,6.3c4.1,3.4,7.3,6.6,9.5,9.5
	c0.4,0.5,0.8,1.1,1.2,1.8c14-0.8,27.2-1.9,39.2-3.2l1.4-0.1l-2.9-1.6l-14.3,1.2c-6.7,0-13.5-2.3-20.1-7.3
	c-21.6-14.9-44-28.1-67.3-39.8l5.8-3.2L476.4,208.8L476.4,208.8z"
          />
          <path
            fill="#FFFFFF"
            d="M477.6,208.2c-11.3-4.7-34.2-7.7-68.8-9.1c-38.5-1.6-74.7-2.1-108.4-1c-4.3,0.3-8.3,0.8-11.8,1.5l0.3,0.7
	l0.3-0.1l-0.3,0.1l0.8,3c22.6-2.5,46.5-3.2,71.9-2.1c55.2,1,91.8,4.3,110.1,10.2l4.8-2.5L477.6,208.2L477.6,208.2z"
          />
          <path
            fill="#EBEBEB"
            d="M477.1,214.8c23.2,11.7,45.7,24.9,67.3,39.8c6.7,5,13.5,7.3,20.1,7.3l14.3-1.2c-32.5-18.6-64.5-34.9-95.9-49
	L477.1,214.8L477.1,214.8z"
          />
          <path
            fill="#FFFFFF"
            d="M508.3,249.9c-1,6.1-0.4,9.6,1.6,10.9c0.5,0.3,1.1,0.4,1.9,0.7l5.8,0.4l4.7,1.1c3.2,0.5,5.4,0.4,6.6-0.1
	c0.8-0.4,1.4-1.2,1.5-2.8c-2.1-1.1-7-1.9-15-2.6c-3,0-5-1.2-5.6-3.9l-0.1-0.7v-3.3c0.1-3.2,1-5.2,2.1-6.3c-0.4,0.1-1,0.4-1.2,0.7
	c-0.8,1-1.5,2.6-2.1,5.1L508.3,249.9L508.3,249.9z"
          />
          <path
            fill="#EBEBEB"
            d="M508.4,249.1c-0.7-2.2-1-3.7-1-4.7c-19.3-13.6-37.3-22.6-53.9-27.1c-14.9-2.1-35.2-3.3-60.9-3.4h-35.1
	l3.4,44l151.2,5.1l-0.4-1.6c-0.8-0.1-1.4-0.4-1.9-0.7c-2.1-1.1-2.5-4.7-1.6-10.9L508.4,249.1L508.4,249.1z"
          />
          <path
            fill="#EBEBEB"
            d="M509.5,252.8l0.1,0.7l13.9,0.7c1.5,0.4,1.9-0.3,1.2-2.2c-0.4-1.1-1.5-1.6-3.6-1.6l-11.6-0.8
	C509.5,250.6,509.5,251.7,509.5,252.8L509.5,252.8z"
          />
          <path
            fill="#FFFFFF"
            d="M508.4,249.1c0.5-2.5,1.2-4.1,2.1-5.1c0.4-0.3,0.8-0.6,1.2-0.7h-3.9c-0.3,0.1-0.4,0.6-0.4,1.1
	C507.5,245.4,507.7,246.9,508.4,249.1z"
          />
          <path
            fill="#FFFFFF"
            d="M507.9,243.3h3.9c0.7-0.3,1.5-0.6,2.5-0.6c-10.6-6.9-24.1-14.6-40.4-23.1c-5.5-2.9-12.5-5.4-20.9-7.4
	l-31.2-2.1c-21.5-0.6-42.9-0.7-64.4-0.6l0.3,4.3h35.1c25.7,0.1,46.1,1.2,60.9,3.4c16.6,4.5,34.7,13.5,53.9,27.1
	C507.5,243.7,507.6,243.3,507.9,243.3L507.9,243.3z"
          />
          <path
            fill="#656565"
            d="M288.7,200.2c-8.1,1.6-16,3.4-23.8,5.2l1.9,2.9c8.5-2.3,16.1-4,22.7-5.2L288.7,200.2z"
          />
          <path
            fill="#FFFFFF"
            d="M347.5,257.5l13.5,0.4l-3.4-44l-0.3-4.3h-10.9"
          />
          <path fill="#656565" d="M344.9,259l2.8,0.1l-0.1-1.6l-1.1-47.9h-2.6" />
          <path
            fill="#FFFFFF"
            d="M298.6,211.7c-5.5,1-11,2.2-16.2,3.9l-19.7,7c-6.9,3-9.1,5.9-6.6,8.7c11,9.6,20.6,16.6,28.6,21l4.5,2.2
	c3.7,1.5,8.3,2.5,13.5,2.9l42.1,1.6l-1.1-49.4c-10.3,0.1-20.8,0.3-31.1,0.6C308,210.4,303.3,211,298.6,211.7L298.6,211.7z
	 M334.4,220.7l1,28.6c-0.3,5.2-3.7,7.7-10.7,7.6c-16.8,0-29-1.6-36.6-4.8c-13.9-7-22.8-13.2-27-18.4c-2.1-3.4,3.6-7.2,16.9-11.1
	c12.8-4.4,28.8-6.9,47.6-7.6C331,214.7,333.9,216.6,334.4,220.7L334.4,220.7z"
          />
          <path
            fill="#EBEBEB"
            d="M325.6,214.8c-18.8,0.6-34.7,3-47.6,7.6c-13.3,4-19,7.7-17.1,11.1c4,5.2,12.9,11.4,27,18.4
	c7.6,3.3,19.8,4.8,36.6,4.8c6.9,0.1,10.5-2.3,10.7-7.6l-1-28.6C333.9,216.6,331,214.7,325.6,214.8L325.6,214.8z"
          />
          <path
            fill="#FFFFFF"
            d="M511.7,243.3c-1.2,1.1-1.9,3.2-2.1,6.3l11.6,0.8c1.9,0,3.2,0.6,3.6,1.6c0.7,1.9,0.3,2.6-1.2,2.2l-13.9-0.7
	c0.7,2.6,2.5,4,5.6,3.9c7.8,0.7,12.9,1.6,15,2.6c0.3-1.4,0.3-3,0-5.4v-0.4c-0.3-1.9-1.7-4.1-4.1-6.3c-2.1-1.9-5.2-3.6-9.4-5
	l-2.8-0.4C513.2,242.8,512.6,242.9,511.7,243.3L511.7,243.3z"
          />
          <path
            fill="#FFFFFF"
            d="M517.5,261.7l-5.8-0.4l0.4,1.6l27.7,1c-2.2-2.9-5.2-6.1-9.5-9.5v0.3c0.3,2.2,0.1,4,0,5.4
	c-0.3,1.4-0.8,2.3-1.5,2.8c-1.2,0.5-3.4,0.7-6.6,0.1L517.5,261.7L517.5,261.7z M539.8,263.9l-27.7-1L361,257.9l-13.5-0.4v1.6
	l193.4,6.5C540.6,265.2,540.2,264.5,539.8,263.9L539.8,263.9z M540.9,265.7l-193.4-6.5l-3.6,10.2l200.3,8
	C543.8,272.6,542.7,268.6,540.9,265.7L540.9,265.7z"
          />
          <path
            fill="#FFFFFF"
            d="M544.2,277.4l30.4,1.4c38.8,1.9,72.4,4.8,100.8,8.7l2.9,0.3l0.8,0.1l17.9,1.8c-4.7-3.9-11.7-6.9-21.3-9.1
	c-18.6-3.7-39.2-6.6-61.9-8.9c-15.1-1.6-26.3-4.5-33.7-8.9c-12.1,1.2-25.2,2.3-39.2,3.2C542.7,268.6,543.8,272.6,544.2,277.4
	L544.2,277.4z"
          />
          <path
            fill="#FFFFFF"
            d="M581.6,262.4l-1.4,0.1c7.4,4.3,18.7,7.3,33.7,8.9c22.7,2.3,43.3,5.2,61.9,8.9c9.6,2.2,16.6,5.2,21.3,9.1
	l4.3,1.9c4.1,1.8,8.5,3.2,12.9,4.3c4.4,1.4,8.5,2.5,12.4,3.3l7.6,1.2c4.3,0.5,8,0.5,11.3,0.3c-8.8-9.2-19.8-16.1-32.9-20.5
	c-25.6-8.7-61.1-15.7-106.5-20.9c-7.2,1.1-14.7,2.2-22.8,3c32.1,2.5,60.8,5.8,86.4,10c24.5,4.7,42,10.3,52.1,17.2
	c-10.3-6.9-27.7-12.7-52.1-17.2c-25.6-4.3-54.3-7.6-86.4-10L581.6,262.4L581.6,262.4z M576.9,316.8c10.6-9.2,23.1-13.8,37.6-13.8
	c16.4,0,30.3,5.9,42,17.9c8.8,8.9,14.2,19.4,16.4,31.2l0.1,1.9c0.8,5.6,1.2,12.5,1.4,20.5v6.2l49.7-2.2l13.9-2.2
	c8.4-0.1,16.1-0.7,23.1-1.6l3.3-0.4c0.8-0.1,1.4-0.4,1.7-0.8h-77.2c-3.6,0.1-5.9-1.6-7-5.2c-0.3-8.7-1.2-16-2.6-21.9
	c-2.5-11.8-8.4-22.4-18-31.5c-12.9-12.1-28.5-18.3-46.6-18.3s-33.7,6.1-46.5,18.3l-4,4.1c-8.8,9.8-13.8,21-15,33.7l-0.5,6.9h7.2
	v-1.7c1.1-12.4,5.6-23.4,13.5-32.9c1.1-1.4,2.3-2.8,3.6-4C574.1,319.4,575.6,318,576.9,316.8L576.9,316.8z"
          />
          <path
            fill="#656565"
            d="M572.7,320.8c-1.2,1.4-2.5,2.6-3.6,4c-7.8,9.5-12.4,20.5-13.5,32.9v19.8l9.4-0.3l-1-3.2
	c-1.1-4.1-1.7-8.5-1.7-13.2c0-11.4,3.3-21.5,9.9-30.3c1.5-2.1,3.2-4,5.1-5.8c1.5-1.5,3.2-3,4.8-4.3c8.9-7.2,19.4-10.6,31.2-10.6
	c12,0,22.3,3.6,31.4,10.6l4.8,4.3c2.3,2.3,4.4,4.8,6.2,7.3c5.9,8.4,8.8,18,8.8,28.8v2.1c-0.1,4.1-0.7,8-1.8,11.7l11.6-0.3
	c-0.1-8.1-0.5-15-1.4-20.5l-0.1-1.9c-2.2-11.8-7.6-22.3-16.4-31.2c-11.6-11.8-25.6-17.9-42-17.9c-14.4,0-27,4.5-37.6,13.8
	C575.6,318,574.1,319.4,572.7,320.8L572.7,320.8z"
          />
          <path
            fill="#656565"
            d="M572.3,330.8c-6.6,8.7-9.9,18.8-9.9,30.3c0,4.7,0.5,9.1,1.7,13.2l1,3.2c1.7,5,4.1,9.6,7.3,13.9l1.9,2.5
	l3.2,3.4c8.4,8.4,18.3,13.2,29.6,14.6l5.2,0.4l7.4-0.3c11.6-1.2,21.5-6.2,30-14.7c2.3-2.3,4.4-4.8,6.2-7.3c3.3-4.7,5.6-9.6,7-15
	c1-3.7,1.7-7.6,1.8-11.7v-2.1c0-10.7-2.9-20.4-8.8-28.8c-1.8-2.6-3.9-5-6.2-7.3l-4.8-4.3c-8.9-7.2-19.4-10.6-31.4-10.6
	c-11.8,0-22.3,3.6-31.2,10.6c-1.7,1.4-3.3,2.8-4.8,4.3C575.6,326.8,573.8,328.7,572.3,330.8L572.3,330.8z M578.5,343.7l1-1.8
	c1.8-3,3.9-5.8,6.5-8.5c5.8-5.8,12.5-9.4,20.2-10.7l1.9-0.3l5.4-0.3l5.6,0.4l1.9,0.3c7.6,1.4,14.2,5,19.9,10.7
	c2.8,2.8,5,5.6,6.7,8.8l1,1.8c1.7,3.3,2.8,6.7,3.3,10.5l0.3,1.9c0.1,1.5,0.3,3,0.3,4.7c0,10.3-3.6,19.3-10.6,26.7l-1.4,1.4
	c-2.8,2.6-5.6,4.8-8.8,6.5l-1.8,0.8c-5,2.3-10.5,3.4-16.4,3.4c-6.1,0-11.6-1.2-16.6-3.6l-1.8-1c-3.2-1.7-6.1-3.9-8.8-6.6h-0.1
	l0.1-0.1l-1.4-1.4c-6.9-7.4-10.3-16.2-10.3-26.4c0-1.6,0.1-3.3,0.3-5l0.3-1.8V354C575.7,350.5,576.8,347,578.5,343.7L578.5,343.7z"
          />
          <path
            fill="#EBEBEB"
            d="M606.2,322.8v0.4l-0.7,13.2c0,1.1,0,2.2,0.1,3.2v0.3c0,0.3,0.1,0.7,0.1,1.1h2.3c-0.1-0.5-0.4-1.1-0.4-1.6
	c-0.1-1-0.1-1.9-0.1-2.8l0.5-14L606.2,322.8L606.2,322.8z"
          />
          <path
            fill="#EBEBEB"
            d="M606.1,323.1v-0.3l-2.1,2.9v0.7l-0.5,10c-0.1,1.2-0.1,2.3,0,3.6l0.1,0.7v0.3h2.1c0-0.3-0.1-0.7-0.1-1.1v-0.3
	c-0.1-1-0.1-1.9-0.1-3.2l0.7-13.2V323.1z"
          />
          <path
            fill="#EBEBEB"
            d="M596.6,344.8c-1.1-0.5-2.2-0.8-3.4-1.2l-9.6-2.5l-0.7-0.1l-3.4,1.1l13.2,3.4c1,0.3,1.9,0.7,2.9,1.1l0.4,0.3
	c0.7,0.4,1.4,0.8,1.9,1.2l6.6,5l-6.6-5c-0.7-0.5-1.2-1-1.9-1.2l-0.3-0.4c-1-0.4-1.9-0.8-2.9-1.1l-13.2-3.4l-1,1.8l0.5,0.1h0.1
	l12.8,3.6c1,0.3,1.8,0.5,2.6,1l0.1,0.1c0.8,0.4,1.7,1.1,2.5,1.9l6.1,5.4c0.4,0.5,0.5,1.1,0.4,1.5v0.3l-0.1,0.3
	c-0.3,0.4-0.7,0.8-1.4,1l-4.7,0.4h11.3c0.3-0.7,0.7-1.4,1.2-2.1c1-1,2.2-1.5,3.6-1.5c1.2,0,2.3,0.4,3.2,1.2l0.3,0.3
	c0.5,0.5,1,1.2,1.2,2.1h8.4l-1.8-0.1c-0.7-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.7l6.1-5.4
	c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9h0.1l-1-1.8h-0.3l-13.2,3.4c-1.1,0.3-2.1,0.7-2.9,1.1l-0.3,0.1
	c-0.8,0.4-1.5,0.8-2.1,1.4l-6.7,5.1v0.3c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.4,1-2.3,1s-1.8-0.3-2.5-1c-0.7-0.7-1-1.4-1-2.3s0.3-1.8,1-2.5
	c0.5-0.4,1-0.7,1.7-0.8l0.8-0.1c1,0,1.7,0.3,2.3,1c0.5,0.7,1,1.4,1,2.2l6.7-5.1c0.7-0.5,1.4-1,2.1-1.4l0.3-0.1
	c0.8-0.4,1.8-0.7,2.9-1.1l13.2-3.4l-3.4-1.1l-0.7,0.1l-9.8,2.5c-1.2,0.3-2.3,0.7-3.4,1.2l-0.7,0.3c-0.4,0.3-1,0.5-1.4,0.8l-5,3.7
	c-0.8,0.8-1.5,1.1-1.9,0.5c-0.8-0.5-1-1.4-0.4-2.5l2.1-5.8l0.3-1.4H621c-0.1,0.4-0.3,1-0.4,1.4l-2.8,7.8l2.8-7.8
	c0.1-0.4,0.3-1,0.4-1.4H619c-0.1,0.4-0.4,1-0.5,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4
	c-0.3-0.4-0.4-1-0.5-1.4h-2.3c0.1,0.4,0.3,1,0.4,1.4l2.8,8h0.1c1,0.3,1.5,0.8,1.9,1.6c0.4,0.8,0.5,1.6,0.3,2.6s-0.8,1.5-1.7,1.9
	s-1.7,0.4-2.5,0.1c-1-0.3-1.5-0.8-2.1-1.6c-0.3-0.5-0.4-1.1-0.3-1.8l0.1-0.8c0.3-0.8,0.8-1.5,1.7-1.9s1.7-0.5,2.3-0.3l-2.8-8
	c-0.1-0.4-0.3-1-0.4-1.4h-2.1l0.4,1.4l2.1,5.8c0.5,1.1,0.5,1.6,0,1.9c-0.8,0.7-1.7,0.5-2.5-0.4l-5-3.7l-1.2-0.7L596.6,344.8
	L596.6,344.8z M575.2,354.3l-0.3,1.8l0.3,0.1l8.1,3.2h5.9c-0.4-0.1-0.7-0.3-1-0.4l-12.7-4.7H575.2L575.2,354.3z"
          />
          <path
            fill="#EBEBEB"
            d="M577.8,359.3l0.4,0.3h5.2l-8.3-3.3l-0.3-0.1l2.2,2.9 M619,323.1l0.5,13.5c0.1,0.8,0.1,1.8-0.1,2.8
	c-0.1,0.6-0.3,1.1-0.6,1.6h2.3c0-0.3,0.1-0.7,0.3-1v-0.4c0.1-1.1,0.1-2.1,0.1-3.2l-0.8-13.6v-0.1l-1.9-0.3v0.7L619,323.1L619,323.1z
	"
          />
          <path
            fill="#EBEBEB"
            d="M623,325.8L621,323l0.8,13.6c0,1.1,0,2.1-0.1,3.2v0.4c0,0.3-0.1,0.7-0.3,1h2.1V341l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6l-0.5-10L623,325.8L623,325.8z M651.6,356.7l-6.1,2.3h-0.1l-1.1,0.5h5.1l0.5-0.3l2.1-2.9l-0.1,0.1"
          />
          <path
            fill="#EBEBEB"
            d="M652.1,356.4l-0.1-1.9l-6.5,2.5l-6.6,2.5c-0.1,0-0.1,0.1-0.3,0.1h5.9l1.2-0.4l6.1-2.3l0.3-0.1L652.1,356.4
	L652.1,356.4L652.1,356.4z"
          />
          <path
            fill="#EBEBEB"
            d="M618.5,361.1v0.3c-0.1,1.2-0.5,2.3-1.5,3.3c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2c-1.1-0.1-2.1-0.6-2.9-1.4
	l-0.3-0.4c-0.5-0.5-0.8-1.2-1-1.9l-0.1-1.2c0-0.5,0-1.1,0.1-1.5h-11.3l-3.3,0.3c-1.2,0.1-2.2,0.3-3.2,0c-0.5,0-1.2-0.3-1.7-0.4h-5.9
	l4.3,1.6c1.1,0.4,2.1,0.7,3,0.8l0.4,0.1l2.3,0.1l8.4-0.3l0.1-0.1c0.5-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0,2.5,0.5
	c0.8,0.6,1.2,1.4,1.4,2.2c0.1,1-0.1,1.8-0.7,2.5s-1.2,1.2-2.2,1.4c-0.7,0.1-1.2,0-1.8-0.3l-0.7-0.4c-0.8-0.5-1.2-1.2-1.4-2.2
	c-0.1-0.8,0-1.6,0.5-2.3l-8.4,0.3l-2.3-0.1l-0.4-0.1c-1-0.1-1.9-0.4-3-0.8l-4.3-1.6h-5.2l8.8,3.4c1.2,0.5,2.3,0.8,3.4,1.1l0.7,0.1
	h7.7c1.2-0.3,1.8,0.3,1.9,1.2c0.3,0.5-0.1,1-1.1,1.5l-5.6,4l-0.5,0.5l-0.5,0.5c-0.7,0.8-1.4,1.8-2.2,2.9l-1.2,1.9l-0.8,1.2h2.3
	l1.2-2.1c0.5-1,1.2-1.8,1.9-2.5l0.3-0.3c0.5-0.5,1.1-1,1.8-1.5l6.9-4.7l-6.9,4.7c-0.7,0.4-1.2,1-1.8,1.5l-0.3,0.3
	c-0.7,0.7-1.4,1.5-1.9,2.5l-1.2,2.1h2.5l1-1.4c0.4-0.5,0.8-1.1,1.4-1.5v-0.1c0.8-0.7,1.7-1.2,2.6-1.8l6.9-4.1l1.7,0.1
	c0.1,0,0.1,0,0.3,0.1l0.3,0.3c0.4,0.4,0.5,0.8,0.5,1.5l-1.5,6.9h2.6l1.7-5.6l-0.1-0.1c-0.5-0.8-0.7-1.6-0.5-2.5
	c0.1-1,0.5-1.6,1.4-2.2c0.8-0.5,1.7-0.7,2.5-0.5c1,0.1,1.7,0.5,2.2,1.4c0.6,0.7,0.8,1.6,0.7,2.5c-0.1,0.7-0.4,1.2-0.8,1.6l-0.5,0.5
	c-0.7,0.5-1.5,0.8-2.5,0.7c-0.8-0.1-1.5-0.6-2.1-1.2l-1.7,5.6h2.1l0.7-2.2c0.1-1.2,0.7-1.7,1.8-1.4c0.5-0.1,1,0.4,1.1,1.7l0.7,2.1
	h2.1l-1.5-5.2l1.5,5.2h2.6l-1.5-6.7c0-0.7,0.1-1.1,0.6-1.5l0.3-0.3l0.3-0.1h1.7l6.9,4.1c1.1,0.5,1.9,1.1,2.6,1.8
	c0.7,0.7,1.2,1.4,1.7,2.2l0.4,0.6h2.5l-1.2-1.9c-0.5-0.8-1.1-1.6-1.9-2.5l-0.1-0.1c-0.5-0.7-1.2-1.2-1.9-1.6l-7-4.7h-0.1
	c-1,0.3-1.8,0.3-2.6-0.3c-0.8-0.4-1.4-1.1-1.7-1.9c-0.3-1-0.3-1.8,0.3-2.6c0.4-0.8,1.1-1.4,1.9-1.6c1-0.3,1.8-0.3,2.6,0.1
	c0.7,0.3,1.1,0.7,1.4,1.2l0.3,0.7c0.3,1,0.3,1.8-0.1,2.6c-0.4,0.8-1,1.4-1.8,1.6l7,4.7c0.7,0.5,1.4,1.1,1.9,1.6l0.1,0.1
	c0.8,0.8,1.4,1.6,1.9,2.5l1.2,1.9h2.2l-1.9-3c-0.7-1.1-1.4-2.1-2.2-2.9l-0.4-0.4l-3.2-2.3l-3.2-2.2c-1.1-0.5-1.4-1.2-0.8-2.2
	c0-0.5,0.7-0.8,1.8-0.5h1.8l3,0.1l3-0.1h0.4l0.4-0.1c1.2-0.3,2.3-0.7,3.4-1.1l5.6-2.2l3.6-1.4l0.3-0.1h-5.2l-5,1.9
	c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3l8.3,0.3l2.8-0.3c1-0.3,2.1-0.5,3-1l5-1.9h-5.9c-0.8,0.3-1.5,0.5-2.5,0.7
	c-1,0.1-2.1,0.1-3.2,0l-6.2-0.5h-8.4C618.3,360,618.5,360.5,618.5,361.1L618.5,361.1z"
          />
          <path
            fill="#EBEBEB"
            d="M620.1,378h-2.6l0.8,2.6c0.3,0.8,0.5,1.5,1,2.2l0.1,0.3c0.5,1,1.1,1.8,1.8,2.6l8.7,10.6l0.1,0.1l1.8-0.8
	l-8.8-11c-0.5-0.7-1.1-1.5-1.5-2.3c-0.4-0.8-0.7-1.8-1-3L620.1,378L620.1,378z"
          />
          <path
            fill="#EBEBEB"
            d="M615.5,378l1.7,5.2l0.4,0.7c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.7l0.4,0.5l3.3,1.1l-8.7-10.6
	c-0.7-0.8-1.2-1.6-1.8-2.6l-0.1-0.3c-0.4-0.7-0.7-1.5-1-2.2l-0.8-2.6h-1.8V378L615.5,378z M641.7,387.6V384l-0.4-0.5l-3.3-5.1
	l-0.1-0.4h-2.3"
          />
          <path
            fill="#EBEBEB"
            d="M635.5,378h-2.5l7.4,11.1l1.4-1.4l-0.1-0.1 M585.3,383.2l-0.4,0.5v3.7l6.1-9.5h-2.2 M609.1,378l-0.7,2.5
	c-0.3,0.8-0.5,1.5-1,2.3l-0.1,0.3c-0.4,0.8-1,1.6-1.8,2.6l-8.4,10.2l-0.3,0.3l3.3-1.1l0.4-0.5l6.3-7.8c0.8-1,1.5-1.9,2.1-2.9
	l0.3-0.5c0.3-0.6,0.4-1.1,0.7-1.5l1.1-3.7L609.1,378L609.1,378L609.1,378z"
          />
          <path
            fill="#EBEBEB"
            d="M606.2,378.9c-0.1,1.1-0.5,2.2-1,3v0.1c-0.4,0.8-1,1.6-1.5,2.2l-8.8,11l1.8,1l0.1-0.1l0.3-0.3l8.4-10.2
	c0.7-0.8,1.2-1.8,1.8-2.6l0.1-0.3c0.4-0.8,0.7-1.5,1-2.3l0.7-2.5h-2.6L606.2,378.9L606.2,378.9z M586.3,388.8l7.2-10.9H591l-6.1,9.5
	 M616.7,357.2c-1-0.8-1.9-1.2-3.2-1.2c-1.4,0-2.5,0.5-3.6,1.5c-1,1-1.4,2.2-1.4,3.6l0.1,1.2c0.1,0.7,0.5,1.4,1,1.9l0.3,0.4
	c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.5,2.9-1.4c1-1,1.4-2.1,1.5-3.3v-0.3c0-1.4-0.5-2.5-1.5-3.6L616.7,357.2L616.7,357.2z
	 M613.5,358.2c1.1-0.1,2.1,0.1,2.9,0.5l0.5,0.3l0.5,0.4l0.4,0.5c0.1,0.3,0.1,0.4,0.1,0.7v0.8c0,0-0.1,0.4-0.4,0.5l-0.4,0.5l-0.5,0.4
	c-0.4,0.3-0.7,0.4-1.2,0.5l-1.5,0.3l-1.7-0.1c-0.4-0.1-0.8-0.3-1.2-0.5l-1-0.8l-0.4-0.5l-0.1-0.7v-0.8l0.3-0.7l0.4-0.5l0.5-0.4
	c0.6-0.3,1.1-0.5,1.8-0.7L613.5,358.2L613.5,358.2z"
          />
          <path
            fill="#EBEBEB"
            d="M612.7,358.2c-0.7,0.1-1.4,0.4-1.8,0.7l-0.5,0.4l-0.4,0.6l-0.3,0.5c-0.1,0.3-0.1,0.5,0,0.8l0.1,0.7l0.4,0.5
	l1,0.8c0.4,0.3,0.8,0.4,1.2,0.5l1.7,0.1l1.5-0.3c0.4-0.1,0.8-0.4,1.2-0.5l0.5-0.4l0.4-0.6c0.1-0.3,0.3-0.4,0.4-0.5
	c0.1-0.3,0.1-0.6,0-0.8c0-0.3,0-0.4-0.1-0.7l-0.4-0.5l-0.5-0.4l-0.5-0.4c-0.8-0.4-1.8-0.7-2.9-0.6H612.7L612.7,358.2z M613.7,358.4
	c-0.3,0.3-0.4,0.5-0.7,1l-0.4,1.1h-2.3l0.6-0.8c0.1-0.3,0.4-0.4,0.7-0.5C612.3,358.6,613,358.4,613.7,358.4L613.7,358.4z M617,360.6
	h-1.1v0.6h-0.1v-0.7L617,360.6c0.1-0.1,0.1-0.1,0.1,0v0.5c0,0.1-0.1,0.1-0.1,0.1V360.6L617,360.6z M615.2,360.5c0.1,0,0.3,0,0.3,0.1
	l0.1,0.1v0.4l-0.1,0.1h-1.2l-0.1-0.1v-0.5h0.3c0,0,0.1-0.1,0.3-0.1l0.3,0.1L615.2,360.5L615.2,360.5z M613.8,359.3v-0.8l1.2,0.1
	l1,0.4c0.7,0.4,1.1,0.8,1.2,1.5h-3l-0.1-0.1l-0.1-0.3L613.8,359.3L613.8,359.3z M613.8,360.5v0.7h-0.1v-0.7L613.8,360.5L613.8,360.5
	z M612.6,360.6h-0.3v0.4h1.2v0.1h-1.4l-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1l0.3-0.1l0.4,0.1h0.7v0.1h-1L612.6,360.6L612.6,360.6z
	 M613.4,361.6l0.1,0.1c0.1,0.3,0.1,0.4,0.1,0.8v1c-0.8,0-1.5-0.1-2.1-0.5c-0.6-0.4-1.1-1-1.2-1.5h2.9L613.4,361.6L613.4,361.6z
	 M614.6,362c0.1-0.3,0.1-0.4,0.1-0.7h2.5c-0.1,0.7-0.5,1.2-1.2,1.5c-0.3,0.1-0.7,0.3-1,0.4l-1.2,0.1l0.5-0.8L614.6,362L614.6,362z
	 M611.9,360.6h-1.2v0.1L611.9,360.6v0.3l-0.1,0.1h-1.4l-0.1-0.1h1.2l0.1-0.1h-1.2v-0.3l0.1-0.1l0.3-0.1l0.3,0.1h0.7v0.3L611.9,360.6
	L611.9,360.6z"
          />
          <path
            fill="#EBEBEB"
            d="M613.8,358.4c-0.8,0-1.5,0.1-2.1,0.5c-0.3,0.1-0.5,0.3-0.7,0.6l-0.5,0.8h2.3l0.4-1.1
	C613.3,359,613.4,358.7,613.8,358.4L613.8,358.4z M617,361.2c0.1,0,0.1,0,0.3-0.1v-0.5l-0.1-0.1h-1.4v0.7h0.1v-0.5h1.1V361.2
	L617,361.2z M614.9,360.5l-0.3-0.1c-0.1,0-0.3,0-0.3,0.1h-0.3v0.5l0.1,0.1h1.2l0.1-0.1v-0.4l-0.1-0.1c0,0-0.1-0.1-0.3-0.1
	L614.9,360.5L614.9,360.5z M615,360.6h0.3v0.4h-1.1v-0.4L615,360.6L615,360.6z"
          />
          <path
            fill="#EBEBEB"
            d="M614.4,360.6l-0.1,0.3v0.1h1.1v-0.4h-0.4 M613.9,360.1l0.1,0.3l0.1,0.1h3c-0.1-0.7-0.5-1.1-1.2-1.5l-1-0.4
	l-1.2-0.1c-0.1,0.3-0.1,0.5-0.1,0.8L613.9,360.1L613.9,360.1z M613.7,360.9v0.3h0.1v-0.7h-0.1 M613.5,360.6l-0.7-0.1l-0.4-0.1
	l-0.3,0.1c-0.1,0-0.1,0-0.1,0.1v0.5l0.1,0.1h1.4v-0.1h-1.4v-0.4h1.4L613.5,360.6L613.5,360.6z M613.3,361.5h-2.9
	c0.3,0.5,0.7,1.1,1.2,1.5c0.7,0.4,1.4,0.5,2.1,0.5c0.1-0.3,0.1-0.5,0-1c0-0.3-0.1-0.5-0.1-0.8l-0.1-0.1L613.3,361.5L613.3,361.5z
	 M614.4,362.6l-0.6,0.8l1.2-0.1c0.4-0.1,0.7-0.3,1-0.4c0.7-0.4,1.1-0.8,1.2-1.5h-2.5c0,0.3,0,0.4-0.1,0.7L614.4,362.6L614.4,362.6z
	 M611.9,360.5h-1.2l-0.1,0.1v0.3h1.2l-0.1,0.1h-1.2l0.1,0.1h1.4l0.1-0.1v-0.1h-1.2v-0.1h1.2v-0.3L611.9,360.5L611.9,360.5z
	 M583.7,341.1c1.4-1.9,2.8-3.7,4.5-5.4c4.7-4.7,9.9-7.7,15.8-9.2v-0.7l2.1-2.9v0.3V323c-7.7,1.4-14.4,5-20.2,10.7
	c-2.6,2.6-4.8,5.5-6.5,8.5h0.1l3.4-1.1L583.7,341.1L583.7,341.1z M578.5,343.7h0.1l0.5,0.1"
          />
          <path
            fill="#EBEBEB"
            d="M578.6,343.7l1.7,2.1l0.7,0.3l11.8,3.4c0.4,0.1,0.7,0.3,1.1,0.5c0.5,0.3,1,0.7,1.4,1.1l2.5,2.6
	c0.5,0.5,0.7,1.2,0.5,1.9l-0.3,0.7c-0.1,0.7-0.7,1.1-1.5,1.2l-3.6,0.7l-1.8,0.1l-1.2-0.3l-11.6-4.3l-0.7-0.3l-2.6,0.7l0.4,0.1
	l12.7,4.7c0.8,0.4,1.7,0.7,2.8,0.8c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.3,1.1-0.5,1.4-1l0.1-0.3v-0.3c0.1-0.5,0-1.1-0.4-1.5l-6.1-5.4
	c-0.8-0.8-1.7-1.5-2.5-1.9l-0.1-0.1c-0.8-0.4-1.7-0.8-2.6-1l-12.8-3.6h-0.1L578.6,343.7L578.6,343.7z"
          />
          <path
            fill="#EBEBEB"
            d="M578.5,343.7c-1.7,3.3-2.8,6.7-3.4,10.5l2.6-0.7l0.7,0.3c0.5-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L578.5,343.7
	L578.5,343.7z M648.5,344l-1.8,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C651.4,350.7,650.3,347.3,648.5,344L648.5,344z"
          />
          <path
            fill="#EBEBEB"
            d="M648.5,344l-13.6,3.9c-1,0.1-1.8,0.5-2.5,1h-0.1c-1,0.4-1.8,1.1-2.5,1.9l-6.1,5.4c-0.3,0.5-0.5,1.1-0.4,1.6
	v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.3,3.2,0c1-0.1,1.9-0.4,2.8-0.7l6.6-2.5l6.5-2.5l-2.6-0.7l-0.7,0.3l-3.3,1.2
	H645l-8.3,3l-0.3,0.1l-0.8,0.1h-1.8l-1.2-0.3l-2.3-0.4l-0.7-0.3c-0.4-0.3-0.7-0.5-0.8-1l-0.3-0.7c-0.3-0.7,0-1.4,0.5-1.9l0.8-0.8
	l1.7-1.8c0.5-0.5,1-0.8,1.5-1.1c0.3-0.3,0.5-0.4,1-0.4l8.1-2.3l3.9-1.1l0.7-0.3L648.5,344L648.5,344z M648.5,344h0.1H648.5z
	 M647.7,342.2c-1.8-3.2-4-6.1-6.7-8.8c-5.8-5.8-12.4-9.4-19.9-10.7v0.1l2.1,2.9v0.8c5.8,1.6,11,4.7,15.5,9.2
	c1.8,1.8,3.4,3.7,4.7,5.6l0.7-0.1l3.4,1.1L647.7,342.2L647.7,342.2z"
          />
          <path
            fill="#656565"
            d="M623.2,326.5l0.5,10v3.6l-0.1,0.7c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5
	c-1.4-1.9-2.9-3.9-4.7-5.6C634.2,331.2,628.9,328.2,623.2,326.5L623.2,326.5z"
          />
          <path
            fill="#EBEBEB"
            d="M623.2,342.4l-2.1,5.8c-0.5,1.1-0.4,1.9,0.4,2.5c0.4,0.4,1,0.3,1.9-0.5l5-3.7c0.4-0.3,1-0.7,1.4-0.8
	l-0.3-0.3c-1.8-1.8-3.7-3.2-5.9-4.3L623.2,342.4L623.2,342.4z M608.2,322.4l-0.6,14c-0.1,0.8-0.1,1.8,0.1,2.8v0.1
	c0.1,1,0.5,1.9,1.1,3l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.7c0.5-0.1,1.1-0.4,1.4-1l3.2-7.4c0.5-1.1,1-2.1,1.1-3c0.1-1,0.1-1.9,0.1-2.8
	l-0.8-13.5v-0.5l-1.5,2.2v0.8l0.4,12.2l-0.1,1.2c-0.1,0.5-0.3,1.1-0.7,1.6l-1.8,3.2c-0.4,0.7-1,1.1-1.7,1.1H613
	c-0.7,0-1.2-0.4-1.7-1.1l-1.8-3.2c-0.3-0.5-0.5-1.1-0.7-1.6l-0.1-1.1l0.4-12.2v-0.8L608.2,322.4L608.2,322.4z"
          />
          <path
            fill="#EBEBEB"
            d="M609.5,325.6l4-0.3l4.1,0.3v-0.8l1.4-2.2l-5.5-0.4l-5.4,0.3l1.4,2.3 M619,323.1v-0.5"
          />
          <path
            fill="#656565"
            d="M609.5,325.6l-0.4,12.2l0.1,1.1l4.3-0.4l4.4,0.4l0.1-1.1l-0.4-12.2l-4.1-0.3"
          />
          <path
            fill="#EBEBEB"
            d="M617.2,340.6c0.3-0.5,0.5-1.1,0.7-1.6l-4.4-0.4l-4.3,0.4c0.1,0.5,0.4,1.1,0.7,1.6l1.8,3.2
	c0.4,0.7,1,1.1,1.7,1.1h0.7c0.7,0,1.2-0.3,1.7-1.1L617.2,340.6L617.2,340.6z"
          />
          <path
            fill="#656565"
            d="M603.5,340.1c-0.1-1.2-0.1-2.5,0-3.6l0.5-10c-5.9,1.5-11.1,4.5-15.8,9.2c-1.8,1.6-3.2,3.4-4.5,5.4l9.6,2.5
	c1.2,0.3,2.3,0.7,3.4,1.2l0.8,0.4l0.1-0.1c1.9-1.8,3.8-3.3,6.1-4.4L603.5,340.1L603.5,340.1z"
          />
          <path
            fill="#EBEBEB"
            d="M603.6,340.7c-2.2,1.1-4.1,2.5-6.1,4.4l-0.1,0.1l1.2,0.7l5,3.7c0.8,0.8,1.7,1,2.5,0.4c0.5-0.3,0.5-1,0-1.9
	l-2.1-5.8L603.6,340.7L603.6,340.7z M595.2,351c-0.4-0.4-1-0.8-1.4-1.1c-1.5,2.5-2.3,5.2-2.8,8.3l1.8-0.1l3.6-0.7
	c0.8-0.1,1.2-0.5,1.5-1.2l0.3-0.7c0.3-0.7,0-1.4-0.5-1.9L595.2,351L595.2,351z M601.3,364.4c0.1,1,0.6,1.6,1.4,2.2l0.7,0.4
	c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.6-0.8,0.8-1.7,0.7-2.5c-0.1-1-0.5-1.6-1.4-2.2s-1.7-0.8-2.5-0.6
	c-1,0.1-1.7,0.5-2.2,1.4l-0.1,0.1C601.3,362.7,601.2,363.5,601.3,364.4L601.3,364.4z M604.5,362.2c0.5-0.1,1,0,1.2,0.3
	c0.4,0.3,0.5,0.7,0.7,1.1c0,0.4,0,0.8-0.3,1.2s-0.7,0.7-1.1,0.7c-0.3,0-0.7,0-1-0.1l-0.4-0.1c-0.4-0.3-0.5-0.7-0.7-1.1
	c0-0.4,0-0.8,0.3-1.2C603.6,362.4,603.9,362.2,604.5,362.2L604.5,362.2z"
          />
          <path
            fill="#EBEBEB"
            d="M603.4,362.8c-0.3,0.4-0.4,0.8-0.3,1.2c0.1,0.4,0.3,0.8,0.7,1.1l0.4,0.1c0.3,0.1,0.5,0.3,1,0.1
	c0.4,0,0.8-0.3,1.1-0.7s0.4-0.8,0.3-1.2c0-0.5-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.2-0.3C603.9,362.2,603.6,362.4,603.4,362.8
	L603.4,362.8z M600.7,365.3c-0.1-1.1-0.7-1.5-1.9-1.2h-7.7c0.3,2.5,1,4.7,2.1,6.9l0.7-0.1l5.6-4
	C600.7,366.4,601.2,365.9,600.7,365.3L600.7,365.3z"
          />
          <path
            fill="#656565"
            d="M591.1,358.2c0.3-3,1.2-5.6,2.8-8.3c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4c-1.1,2.5-1.9,5-2.5,7.7l11.6,4.3
	L591.1,358.2L591.1,358.2z M590.6,363.9c-1.1-0.3-2.2-0.5-3.4-1.1l-9.2-3.6v1.6c0,4.1,0.5,7.8,1.8,11.4c0.5,1.5,1.1,3,1.8,4.4
	l8.3-0.1l1.2-1.9c0.7-1.1,1.5-2.1,2.2-2.9l0.5-0.5l-0.1-0.4c-1.1-2.2-1.8-4.4-2.1-6.9L590.6,363.9L590.6,363.9z"
          />
          <path
            fill="#EBEBEB"
            d="M609.1,350.3H609c-0.8-0.3-1.7-0.1-2.3,0.3c-0.8,0.4-1.4,1.1-1.7,1.9l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8
	c0.4,0.8,1.1,1.4,2.1,1.6c0.8,0.3,1.8,0.3,2.5-0.1c0.8-0.4,1.4-1.1,1.7-1.9c0.3-1,0.3-1.8-0.3-2.6
	C610.6,351.1,610.1,350.6,609.1,350.3L609.1,350.3z M608.9,355l-1.2,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.3-0.1-0.7-0.1-1v-0.4
	c0.1-0.4,0.4-0.8,0.8-1l1.2-0.1c0.4,0.1,0.8,0.4,1,0.8c0.3,0.4,0.3,0.8,0.1,1.2C609.5,354.5,609.3,354.9,608.9,355L608.9,355z"
          />
          <path
            fill="#EBEBEB"
            d="M609.7,354c0.1-0.4,0.1-0.8-0.1-1.2c-0.1-0.4-0.5-0.7-1-0.8l-1.2,0.1c-0.4,0.3-0.7,0.5-0.8,1v0.4
	c0,0.3,0,0.5,0.1,1c0.1,0.4,0.5,0.7,1,0.8l1.2-0.1C609.3,354.9,609.5,354.5,609.7,354L609.7,354z M615.6,353.6c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1,2.5,1s1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3v-0.3c0-0.8-0.4-1.6-1-2.2c-0.7-0.7-1.5-1-2.3-1l-0.8,0.1
	c-0.7,0.1-1.1,0.4-1.7,0.8C615.9,351.8,615.6,352.7,615.6,353.6L615.6,353.6z M618.5,352h0.4c0.4,0,0.8,0.1,1.2,0.5
	c0.3,0.3,0.4,0.7,0.4,1.2c0,0.4-0.1,0.8-0.4,1.2c-0.4,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2
	c0-0.4,0.1-1,0.5-1.2C617.9,352.3,618.2,352.1,618.5,352L618.5,352z"
          />
          <path
            fill="#EBEBEB"
            d="M617.7,352.4c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
	c0.4,0,0.8-0.1,1.2-0.5c0.3-0.3,0.4-0.7,0.4-1.2c0-0.4-0.1-1-0.4-1.2c-0.4-0.3-0.7-0.5-1.2-0.5h-0.4
	C618.2,352.1,617.9,352.3,617.7,352.4L617.7,352.4z M620.7,367c0.8,0.4,1.7,0.5,2.6,0.3h0.1c0.8-0.4,1.4-1,1.8-1.6
	c0.4-0.8,0.5-1.6,0.1-2.6l-0.3-0.7c-0.3-0.5-0.7-1-1.4-1.2c-0.8-0.4-1.7-0.5-2.6-0.1c-1,0.3-1.5,0.8-1.9,1.6
	c-0.4,0.8-0.5,1.6-0.3,2.6C619.3,365.9,619.9,366.6,620.7,367L620.7,367z M621.7,362.3l1.2,0.1c0.3,0.1,0.5,0.4,0.7,0.7l0.1,0.4
	c0.1,0.4,0.1,1-0.1,1.2c-0.3,0.4-0.5,0.7-1,0.8h-1.2c-0.4-0.3-0.7-0.5-0.8-1c-0.1-0.4-0.1-1,0.1-1.4
	C620.8,362.7,621.2,362.4,621.7,362.3L621.7,362.3z"
          />
          <path
            fill="#EBEBEB"
            d="M620.7,363.1c-0.3,0.4-0.3,0.8-0.1,1.4c0.1,0.4,0.4,0.7,0.8,1l1.2,0.1c0.4-0.1,0.8-0.4,1-0.8
	c0.1-0.4,0.3-0.8,0.1-1.2l-0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.7l-1.2-0.1C621.2,362.4,620.8,362.7,620.7,363.1L620.7,363.1z
	 M607.2,369.3c-0.1-0.1-0.1-0.1-0.3-0.1l-1.7-0.1l-6.9,4.1c-1,0.6-1.8,1.1-2.6,1.8v0.1c-0.5,0.4-1,1-1.4,1.5l-8.1,12.2l2.3-1
	l0.5-0.7l6.9-10.2l0.5-0.7l0.3-0.3c0.4-0.4,0.8-0.7,1.4-1l3.3-1.5h1.9l0.5,0.4c0.5,0.4,0.8,1,0.7,1.8l-0.4,3.6
	c-0.1,0.7-0.3,1.2-0.5,1.8l-0.5,1l-7.6,9.8l-0.4,0.5l-0.1,2.8l8.8-11c0.5-0.7,1.1-1.4,1.5-2.2v-0.1c0.4-1,0.7-1.9,1-3l1.8-7.8
	c0-0.7-0.1-1.2-0.6-1.5L607.2,369.3L607.2,369.3z M610.6,372.2l0.1,0.1c0.5,0.7,1.2,1.1,2.1,1.2c1,0.1,1.8-0.1,2.5-0.7l0.5-0.5
	c0.4-0.4,0.7-1,0.8-1.6c0.1-1-0.1-1.8-0.7-2.5c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2
	C610,370.5,610.1,371.4,610.6,372.2L610.6,372.2z M612.3,368.9c0.4-0.3,0.8-0.4,1.2-0.3c0.5,0,0.8,0.3,1.1,0.7
	c0.3,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.7-0.4,0.8l-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.3c-0.5-0.1-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.4-0.8-0.3-1.2C611.7,369.6,612,369.2,612.3,368.9L612.3,368.9z"
          />
          <path
            fill="#EBEBEB"
            d="M611.7,370c-0.1,0.4,0,0.8,0.3,1.2c0.3,0.4,0.7,0.5,1.1,0.7c0.4,0,0.8,0,1.2-0.3l0.3-0.3
	c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4,0-1-0.3-1.2c-0.3-0.4-0.7-0.5-1.1-0.7c-0.5-0.1-1,0-1.2,0.3C612,369.2,611.7,369.6,611.7,370
	L611.7,370z M603.5,373.9h-1.9l-3.3,1.5c-0.6,0.3-1.1,0.5-1.4,1l0.3,0.3l0.4,0.4c1.9,1.9,4,3.4,6.2,4.4c0.3-0.5,0.4-1.1,0.6-1.8
	l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8L603.5,373.9L603.5,373.9z M619.7,369.3l-0.3,0.1l-0.3,0.3c-0.4,0.4-0.5,1-0.5,1.5l1.8,7.8
	c0.1,1.2,0.5,2.3,1,3c0.4,0.8,1,1.6,1.5,2.3l8.8,11l-0.1-2.6l-0.4-0.5l-7.6-9.8l-0.5-1c-0.3-0.5-0.4-1.1-0.5-1.8l-0.4-3.6
	c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.1l0.3-0.3l1.9-0.1l3.3,1.5c0.5,0.3,1.1,0.7,1.5,1.1l0.7,0.8l6.9,10.2l0.4,0.7l2.5,1l-7.8-11.7
	c-0.4-0.8-1-1.5-1.7-2.2c-0.7-0.7-1.7-1.2-2.6-1.8l-6.9-4.1H619.7L619.7,369.3z M613.7,374.4c-1.1-0.3-1.7,0.1-1.8,1.4l-1.8,5.9
	c-0.1,0.6-0.4,1-0.7,1.5l4,0.3l3.6-0.3l-2.3-7.3C614.6,374.8,614.2,374.3,613.7,374.4L613.7,374.4z M594,370.8l-0.7,0.1l0.1,0.4"
          />
          <path
            fill="#EBEBEB"
            d="M597.2,376.5l-0.3-0.3l-0.3,0.3 M585.3,383.2c-1.7-2.1-3-4.1-4.1-6.3c-0.7-1.4-1.2-2.9-1.8-4.4
	c-1.2-3.6-1.8-7.4-1.8-11.4v-1.6l-0.7-0.3l-2.2-2.9l0.3,0.1l-0.3-0.1c-0.1,1.6-0.3,3.2-0.3,5c0,10.2,3.4,19.1,10.3,26.4V384
	L585.3,383.2L585.3,383.2z M586.2,389l0.1-0.1L586.2,389z M586.3,388.8c2.8,2.6,5.6,4.8,8.8,6.6l0.1-2.8l0.4-0.5
	c-2.3-1.4-4.4-3-6.5-4.8l-0.5,0.7L586.3,388.8L586.3,388.8z M629.2,353.9c-0.5,0.5-0.8,1.2-0.5,1.9l0.3,0.7c0.1,0.4,0.4,0.8,0.8,1
	c0.1-1.8,0.1-3.2,0.3-4.4L629.2,353.9L629.2,353.9z"
          />
          <path
            fill="#656565"
            d="M633.3,350.2c-0.5,0.3-1.1,0.7-1.5,1.1l-1.7,1.8c-0.1,1.1-0.3,2.6-0.3,4.4l0.7,0.1l2.3,0.4v-3
	c0.1-1.6,0.5-2.8,1.4-3.2c0.3-0.1,0.7-0.3,1.1-0.3c1.1,0.1,1.7-0.3,1.9-1.2c0.1-1.1,1.2-1.6,3.3-1.8h1.9v-1.2l-8.1,2.3
	C633.9,349.8,633.6,349.9,633.3,350.2L633.3,350.2z"
          />
          <path
            fill="#656565"
            d="M638,352.9c1.7-0.5,2.3-1.5,2.3-3c0-0.8,0.5-1,1.7-0.5c1.4,0.7,2.3,1.5,2.8,2.6l0.3,1.8l0.1,1.4l3.3-1.2
	c-0.5-2.8-1.4-5.2-2.5-7.7l-3.9,1.1v1.2h-1.9c-2.1,0.1-3.2,0.7-3.3,1.8c-0.3,1-1,1.4-1.9,1.2c-0.4,0-0.7,0.1-1.1,0.3
	c0.5,1.1,1,2.2,1.2,3.4h0.5C636.5,353.9,637.2,353.2,638,352.9L638,352.9z"
          />
          <path
            fill="#656565"
            d="M635.8,355.3h-3v0.8c1.2-0.1,2.2,0,2.8,0.4c0.5,0.3,1,1,1.1,1.8l0.3-0.1l8.3-3l-0.1-1.4l-0.3-1.8
	c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5c0,1.5-0.8,2.5-2.3,3C637.2,353.2,636.5,353.9,635.8,355.3L635.8,355.3z"
          />
          <path
            fill="#EBEBEB"
            d="M632.8,355v0.3h2.5c-0.3-1.2-0.7-2.3-1.2-3.4C633.3,352.4,632.9,353.5,632.8,355L632.8,355z"
          />
          <path
            fill="#656565"
            d="M634,358.4h1.8l0.8-0.1c-0.1-0.8-0.5-1.4-1.1-1.8c-0.7-0.4-1.5-0.5-2.8-0.4v1.9L634,358.4L634,358.4z"
          />
          <path fill="#EBEBEB" d="M635.7,364.8l0.4-0.4h-0.3" />
          <path
            fill="#656565"
            d="M635.7,364.8c-0.7,0.4-1.7,0.6-2.9,0.4v0.8h3l0.1,0.1c0.7,1.2,1.2,2.1,2.1,2.2c1.7,0.6,2.3,1.5,2.3,3
	c0,0.8,0.5,1,1.7,0.4c1.4-0.7,2.3-1.5,2.8-2.6l0.3-1.8l0.3-2.2l0.3-4.3l-5.6,2.2c-1.1,0.4-2.2,0.8-3.4,1.1l-0.4,0.1L635.7,364.8
	L635.7,364.8z"
          />
          <path
            fill="#656565"
            d="M635.8,364.4h-3v0.7c1.4,0.1,2.3,0,2.9-0.4L635.8,364.4L635.8,364.4z"
          />
          <path fill="#EBEBEB" d="M635.5,366l-0.1,0.3h0.5l-0.1-0.3" />
          <path
            fill="#EBEBEB"
            d="M632.8,366v0.3c0.1,1.8,0.7,2.9,1.7,3.3c0.4-1.1,0.8-2.2,1-3.3l0.1-0.3L632.8,366L632.8,366z"
          />
          <path
            fill="#656565"
            d="M632.8,365.2v-0.7l-3-0.1c0,2.1,0.3,3.7,0.4,5l3.2,2.3l0.4,0.4c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3l3,0.3
	l0.7-3.3l0.5-2.8h-1.9c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1c-1-0.4-1.4-1.5-1.7-3.3L632.8,365.2L632.8,365.2z"
          />
          <path
            fill="#656565"
            d="M635.4,366.3c-0.3,1.1-0.5,2.2-1,3.3l0.8,0.1c1.1-0.1,1.7,0.3,1.9,1.2c0.1,1.1,1.2,1.6,3.3,1.8h1.9l-0.5,2.8
	l4.5-0.1c1.4-3.3,2.3-6.7,2.8-10.5l-3.7,0.4c0,0.8-0.1,1.5-0.3,2.2l-0.3,1.8c-0.4,1.1-1.4,1.9-2.8,2.6c-1.1,0.5-1.7,0.3-1.7-0.4
	c0-1.5-0.8-2.5-2.3-3c-0.8-0.1-1.4-1-2.1-2.2L635.4,366.3L635.4,366.3z"
          />
          <path
            fill="#EBEBEB"
            d="M628.1,364.4c-1.2-0.3-1.8,0-1.8,0.5c-0.5,1-0.4,1.6,0.8,2.2l3.2,2.2c-0.1-1.2-0.3-2.9-0.4-5H628.1
	L628.1,364.4z M645,353.9l0.1,1.4h0.1 M651.9,356.5h-0.1l-0.1,0.1 M652.1,356.4l-0.3,0.1h0.1"
          />
          <path
            fill="#EBEBEB"
            d="M652.1,356.4l-0.3,0.1L652.1,356.4l-2.2,2.9l-0.7,0.3v1.4c0,1.4,0,2.6-0.1,3.9c-0.4,3.7-1.2,7.2-2.8,10.5
	c-1.2,2.9-2.9,5.6-5,8.1l0.3,0.5v3.7c7-7.4,10.6-16.4,10.6-26.7C652.5,359.4,652.3,357.9,652.1,356.4L652.1,356.4z"
          />
          <path
            fill="#656565"
            d="M649.2,359.5l-3.6,1.4c0,1.5-0.1,2.9-0.1,4.3l3.7-0.4c0.1-1.2,0.1-2.5,0.1-3.9L649.2,359.5z"
          />
          <path
            fill="#EBEBEB"
            d="M645.3,365.2c0.1-1.4,0.1-2.8,0.1-4.3L645.3,365.2L645.3,365.2z M645.3,365.2l-0.3,2.2
	C645.2,366.7,645.3,366,645.3,365.2z M640.4,389.1h0.1 M629.9,376.5c-0.4-0.4-1-0.7-1.5-1.1l-3.3-1.5l-1.9,0.1l-0.3,0.3l-0.3,0.1
	c-0.5,0.4-0.8,1.1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.5,1.8c2.3-1.1,4.5-2.6,6.6-4.7L629.9,376.5L629.9,376.5z M637.5,387.5
	c-2.1,1.9-4.1,3.4-6.5,4.8l0.4,0.5l0.1,2.6v0.1c3.2-1.6,6.1-3.9,8.8-6.5l-2.5-1L637.5,387.5L637.5,387.5z M629.8,396.3l-3.3-1.1
	l-0.5-0.5c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2l-0.4,0.5l-3.3,1.1l-0.1,0.1c5.1,2.5,10.6,3.6,16.6,3.6
	c5.9,0,11.4-1.1,16.4-3.4L629.8,396.3L629.8,396.3z M596.9,396.1l0.3-0.3 M575.6,354.3l-0.4-0.1v0.1"
          />
          <path
            fill="#FFFFFF"
            d="M567.9,314.7c12.8-12.1,28.3-18.3,46.5-18.3s33.7,6.1,46.6,18.3c9.6,9.1,15.5,19.7,18,31.5
	c1.5,5.9,2.3,13.2,2.6,21.9c1.1,3.6,3.4,5.4,7,5.2h77.2c0.4-0.6,0.4-1.4,0-2.3c-1.5-3.6-3.2-5.6-5-6.2c-2.9,0.3-5.1-0.1-6.6-1.2
	l-2.8-3.3l-1.1-1.5l-0.1-0.1l-1.1-1.8l-0.8-1.5l-0.8-1.8l-0.8-2.3l-0.3-2.2v-1.8l0.1-1.5l0.3-1.8l0.1-1.1l1-3.6l0.5-1.8l0.4-1.5
	l0.5-1.8l1.4-4.8l0.5-1.8l0.4-1.5l0.5-1.8l0.6-2.1c0.4-3.3,1.2-5,2.5-5.1h1.5l-12.8-8.3l-0.3-0.3l-13.3-2.1l-0.4-0.1
	c1.2,0.6,2.6,1.2,3.9,1.9c3.6,1.9,7,4.5,10.5,7.7c0.8,0.8-0.1,1.1-2.6,0.5l-8.7-1.4l-2.5-0.4l-27.4-4.5c-4.5-0.7-7.8-1.8-9.9-3
	c-4.3-2.9-8.3-5.9-12-9.1c-3.3-2.8-6.3-5.6-9.1-8.5c-0.4-0.5-0.4-1-0.4-1.4l0.5-0.5c0.5-0.3,1.5-0.5,2.9-0.5
	c-28.3-3.9-62-6.7-100.8-8.7l-30.4-1.4c1.4,24.5-0.4,51.9-5.1,82h9.2l0.5-6.9c1.2-12.7,6.2-23.9,15-33.7L567.9,314.7L567.9,314.7z
	 M702.8,322.8c0.1-1,0.7-1.4,1.7-1.4C703.6,321.4,703.1,321.8,702.8,322.8l3,1.8l0.4,0.5c0.4,0.5,0.8,1.5,1,2.6l3,15.8l6.1,1.7
	c1.2,0.1,2.5,1.1,3.9,3.2l0.8,1.6l0.4,0.8c0.1,0.4,0.3,0.8,0.4,1.4c0.1,0.5,0.3,1,0.3,1.6c0,0.5,0.1,1.2,0.1,1.8
	c0,0.5,0,1.2-0.1,1.8c0,0.8-0.1,1.5-0.4,2.3v0.4l-0.4,0.7c-1.4,2.2-4.1,2.9-8.4,2.2c-3.7-0.7-5.9-2.9-6.5-6.5v-0.3l-0.1-1.8
	l-0.4-3.7l-0.4-3.6l-2.6-21.9C702.7,324.3,702.7,323.4,702.8,322.8L702.8,322.8z"
          />
          <path
            fill="#656565"
            d="M703,325.3l2.6,21.9c4.3,0,8.3,0.1,11.8,0.7l2.9,0.5c-1.2-2.1-2.5-3.2-3.9-3.2l-6.1-1.7
	c-1,0.1-1.5-0.8-1.8-2.6l-2.2-15.8l-0.4-0.5l-3-1.8C702.7,323.4,702.7,324.3,703,325.3L703,325.3z"
          />
          <path
            fill="#EBEBEB"
            d="M708.5,341c0.3,1.8,1,2.6,1.8,2.6l-3-15.8c-0.3-1.2-0.5-2.1-1-2.6L708.5,341L708.5,341z"
          />
          <path
            fill="#656565"
            d="M705.8,350.9l0.4,3.7l4.5-0.1c3.2,0.1,6.2,0.3,9.1,0.8l2.3,0.4c0-0.7,0-1.2-0.1-1.8l-2.5-0.4
	c-2.8-0.4-5.8-0.7-8.8-0.7h-4.1h4.1c3.2,0,6.1,0.3,8.8,0.7c-0.1-0.6-0.3-1.1-0.4-1.5c-2.9-0.4-5.9-0.7-9.1-0.8L705.8,350.9
	L705.8,350.9z"
          />
          <path
            fill="#656565"
            d="M710.1,351c3.2,0.1,6.2,0.3,9.1,0.8l2.6,0.4c-0.1-0.4-0.3-1-0.4-1.4l-0.4-0.8l-2.8-0.5
	c-3.7-0.6-7.8-0.8-12.1-0.7c4.4,0,8.4,0.1,12.1,0.7c-0.3-0.6-0.7-1.1-1.1-1.6c-3.7-0.6-7.6-0.8-11.8-0.7l0.4,3.6L710.1,351
	L710.1,351z M706.2,354.5l0.1,1.8h4.3c3.2,0,6.2,0.3,9.1,0.8v-1.8c-2.9-0.4-5.9-0.7-9.1-0.8L706.2,354.5L706.2,354.5z"
          />
          <path
            fill="#656565"
            d="M706.5,356.2v0.3c0.5,3.6,2.8,5.8,6.5,6.5c4.3,0.7,7,0,8.4-2.2l-1.8-0.3l-8.3-0.7H708h3.3l8.3,0.7l0.1-1.2
	l-8.9-0.7h-3.3h3.3l8.9,0.7l2.1,0.4c0.1-0.8,0.3-1.6,0.4-2.3l-2.2-0.4c-2.9-0.4-5.9-0.7-9.1-0.8L706.5,356.2L706.5,356.2z"
          />
          <path
            fill="#C8C8C8"
            d="M718.4,349.5l2.8,0.6l-0.8-1.6l-2.9-0.5C717.7,348.4,718.1,349,718.4,349.5L718.4,349.5z M721.9,352.1
	l-2.6-0.4c0.1,0.5,0.3,1,0.4,1.5l2.5,0.4C722.1,353.2,722.1,352.7,721.9,352.1L721.9,352.1z M719.9,355.1v1.8l2.2,0.4
	c0.1-0.7,0.1-1.2,0.1-1.8L719.9,355.1L719.9,355.1z M719.7,359.4l-0.3,1.2l1.9,0.3l0.3-0.7l0.1-0.5"
          />
          <path
            fill="#FFFFFF"
            d="M539.1,359.4c4.8-30.1,6.5-57.5,5.1-82l-200.3-8v0.3c-2.6,13.5-3.7,30-3,49.5c1,13.8,2.9,25.9,5.9,36.6
	l1.9,6.2c1.5,3.3,5.5,5,12.1,4.8l167.8,1.2c3.4,0.4,6.1-0.5,7.8-2.9C537.7,363.7,538.6,361.7,539.1,359.4L539.1,359.4z"
          />
          <path
            fill="#FFFFFF"
            d="M536.6,365c8.7,3.4,10.5,5.1,5.6,4.8l-270.2-1.6v13.6h283.5v-22.4h-16.4C538.6,361.7,537.7,363.7,536.6,365
	L536.6,365z"
          />
          <path
            fill="#FFFFFF"
            d="M528.8,367.9L361,366.7c-6.6,0.1-10.6-1.5-12.1-4.8l-52-1.2c-10.6,0.5-18.8,3-24.6,7.6l270.2,1.6
	c4.8,0.3,2.9-1.4-5.6-4.8C534.8,367.4,532.1,368.3,528.8,367.9L528.8,367.9z"
          />
          <path
            fill="#EBEBEB"
            d="M678.3,287.6l-2.9-0.3c-1.4,0-2.3,0.3-2.9,0.5l31.4,5.8l-3.4-2.5l-21.3-3.6L678.3,287.6L678.3,287.6z"
          />
          <path
            fill="#EBEBEB"
            d="M672,288.4l26.8,5.2l1.8,0.3l3.6,0.7l2.3,0.4l20.4,4c-3.9-0.8-8-1.9-12.4-3.3c-4.5-1.1-8.8-2.5-12.9-4.3
	l-1-0.1l3.4,2.5l0.4,0.3l-0.4-0.3l-31.4-5.8L672,288.4L672,288.4z M672,288.4c0.8,1.5,2.2,2.5,4.1,2.9l0.7,0.1
	c7.2,1.8,14.4,2.5,21.9,2.2L672,288.4L672,288.4z"
          />
          <path
            fill="#EBEBEB"
            d="M672.4,289.8c2.9,2.9,5.9,5.8,9.1,8.5l19.7,4.1c-1.4-2.8-1.7-5.5-0.5-8.5l-1.8-0.3
	c-7.3,0.3-14.6-0.4-21.9-2.2l-0.7-0.1c-2.1-0.4-3.4-1.4-4.1-2.9C672,288.8,672,289.2,672.4,289.8L672.4,289.8z"
          />
          <path fill="#FFFFFF" d="M679.2,287.7l21.3,3.6l0.8,0.1l-4.3-1.9" />
          <path
            fill="#EBEBEB"
            d="M704,294.6c-0.8,2.1-1.1,5.4-1,10.2c0.8,0.7,1.7,1.4,2.5,1.9c-0.1-5.4,0.1-9.2,0.8-11.7L704,294.6L704,294.6
	z"
          />
          <path
            fill="#EBEBEB"
            d="M700.6,293.9c-1.1,3-0.8,5.8,0.6,8.5c0.5,0.8,1.2,1.6,1.9,2.3c0-4.8,0.3-8.1,1-10.2L700.6,293.9L700.6,293.9
	z M706.4,295.2c1,1.4,1.2,4.4,1.1,8.8l0.1,3.4l21.9-0.1c0-0.3,0.1-0.4,0.3-0.5h-1.1l-9.5-0.1l-5.6-0.1v-8.5l5.8,1.4l7.7,1.8l3.7,5.5
	l13.3,2.1l-8.5-7.4l-1.2-1.1l-7.6-1.2L706.4,295.2L706.4,295.2z"
          />
          <path
            fill="#EBEBEB"
            d="M701.2,302.5l-19.7-4.1c3.7,3.2,7.7,6.2,12,9.1c2.1,1.4,5.4,2.3,9.9,3l27.4,4.5l-18.6-5.5
	c-1.8-0.4-3.3-1-4.7-1.5c-0.7-0.3-1.4-0.7-2.1-1.1c-1-0.5-1.8-1.2-2.5-1.9C702.4,304.1,701.7,303.3,701.2,302.5L701.2,302.5z"
          />
          <path
            fill="#EBEBEB"
            d="M707.6,308v-0.5l-0.1-3.4c0.3-4.5-0.1-7.4-1.1-8.8c-0.7,2.5-1,6.3-0.8,11.7
	C706.3,307.3,706.9,307.5,707.6,308L707.6,308z"
          />
          <path
            fill="#EBEBEB"
            d="M712.3,309.5l18.6,5.5l2.5,0.4l-3-5.8c-0.7-1.1-1-1.8-0.8-2.3l-21.9,0.1v0.5
	C709,308.5,710.7,309.1,712.3,309.5L712.3,309.5z M713.5,305.3l5.2,0.5l-0.4-1c-0.3-0.8-0.4-1.6-0.3-2.5c0-0.7,0.1-1.2,0.4-1.8
	l0.4-0.8l-5.5,0.6v5L713.5,305.3L713.5,305.3z"
          />
          <path
            fill="#EBEBEB"
            d="M719.2,306.6l-0.4-0.7l-5.2-0.5v1.1 M713.5,298.1v2.3l5.5-0.6l0.3-0.4"
          />
          <path
            fill="#EBEBEB"
            d="M719,299.8l-0.4,0.8l4.3,1.1c1,2.2,2.9,3.9,5.6,5h1.1l0.7-0.1l0.4,0.1l-3.7-5.5l-7.7-1.8L719,299.8
	L719,299.8z"
          />
          <path
            fill="#EBEBEB"
            d="M718.2,302.5h2.3c0.3,0,0.5,0.1,0.7,0.3c0.1,0.3,0.3,0.4,0.3,0.7v0.7c0,0.3-0.1,0.4-0.3,0.7
	c-0.1,0.1-0.4,0.3-0.7,0.3h-2.1l0.4,1l0.3,0.7l9.5,0.1c-2.9-1.1-4.8-2.8-5.6-5l-4.3-1.1C718.4,301.4,718.2,301.9,718.2,302.5
	L718.2,302.5z"
          />
          <path
            fill="#EBEBEB"
            d="M721.5,303.4c0-0.3-0.1-0.4-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-2.3c-0.1,0.8,0,1.6,0.3,2.5h2.1
	c0.3,0,0.5-0.1,0.7-0.3c0.1-0.3,0.3-0.4,0.3-0.7L721.5,303.4L721.5,303.4z"
          />
          <path
            fill="#FFFFFF"
            d="M729.5,307.3c0,0.5,0.3,1.2,0.8,2.3l3,5.8l8.7,1.4c2.5,0.4,3.3,0.3,2.6-0.5c-3.4-3.2-7-5.6-10.5-7.7
	c-1.2-0.7-2.6-1.4-3.9-1.9l-0.7,0.1C729.6,306.9,729.5,307,729.5,307.3L729.5,307.3z M735.6,301.4l2.1,0.3l2.1,0.3l4.5,3.4l-0.4-2.9
	h-3h3v-0.3h1.9v-1.6c-3.3,0.4-7,0.3-11.3-0.3L735.6,301.4L735.6,301.4z"
          />
          <path
            fill="#FFFFFF"
            d="M744.1,308.8l0.3,0.3l12.8,8.3l-19.5-15.7l-2.1-0.3"
          />
          <path
            fill="#FFFFFF"
            d="M757.2,317.3h3.3l-8.3-8.3h-3.4l-4.5-3.7l-4.5-3.4l-2.1-0.3"
          />
          <path fill="#FFFFFF" d="M748.8,309.1h3.4l-3.6-6.9h-5v0.3l0.5,2.9" />
          <path
            fill="#656565"
            d="M757.7,320.5c1.5,0,2.2-0.5,2.2-1.8h-4c-1,0.1-1.5,0.4-1.9,1.1l-0.1,0.5h3.8L757.7,320.5L757.7,320.5z"
          />
          <path
            fill="#FFFFFF"
            d="M760.5,318.7v-1.4h-4.8c-1.2,0.1-2.1,1.9-2.5,5.1l0.7-2.1l0.1-0.7c0.4-0.7,1-1.1,1.9-1.1h4.5V318.7z"
          />
          <path fill="#C8C8C8" d="M754.5,324.5h1.1l-0.1-4l-1-0.1" />
          <path
            fill="#C8C8C8"
            d="M755.6,324.5h0.8l-0.1-4h-0.8 M753.3,322.4l-0.7,2.1h1.9v-4.1h-0.7"
          />
          <path fill="#C8C8C8" d="M756.5,324.5h1.1l-0.1-4h-1.1" />
          <path
            fill="#656565"
            d="M754.5,324.5h-1.9l-0.4,1.8h2.3v-0.1l0.7-0.8h4.8v-0.8h-4.4"
          />
          <path fill="#656565" d="M755.8,328.7h0.8l-0.1-3.4h-0.8" />
          <path
            fill="#C8C8C8"
            d="M754.5,326.1l0.1,1.8l0.5,0.8h0.6l-0.1-3.4h-0.4"
          />
          <path
            fill="#656565"
            d="M753,326.3l-0.1,1.5h1.7l0.1,0.1l-0.1-1.8v0.1"
          />
          <path fill="#FFFFFF" d="M751.8,327.8h0.4v-1.5" />
          <path fill="#C8C8C8" d="M752.2,327.8h0.7l0.1-1.5h-0.8" />
          <path fill="#656565" d="M758.7,325.3v1h1.2v-1" />
          <path
            fill="#C8C8C8"
            d="M756.7,325.3h-0.3l0.1,3.4h0.1v-1h1l-0.1-1.5h-0.8"
          />
          <path
            fill="#656565"
            d="M758,326.3v-1h-1.2v1h0.8 M756.7,327.8v1h1v-1"
          />
          <path fill="#FFFFFF" d="M758,328.7v-1h-0.3v1" />
          <path
            fill="#656565"
            d="M756.7,328.7h-1.5l-0.6-0.8l-0.1-0.1h-2.8l-0.5,1.6h8.8v-0.7h-2.3"
          />
          <path fill="#656565" d="M759.9,328.7v-1h-1.2v1" />
          <path fill="#C8C8C8" d="M752.1,334.4h0.7l0.1-5h-0.7" />
          <path fill="#656565" d="M755.9,334.4h0.8l-0.1-5h-0.8" />
          <path fill="#C8C8C8" d="M754.8,334.4h1.1l-0.1-5h-1.1" />
          <path fill="#656565" d="M752.7,334.4h2.1l-0.1-5h-1.8" />
          <path fill="#C8C8C8" d="M756.7,334.4h1.1l-0.1-5h-1.1" />
          <path
            fill="#656565"
            d="M749.7,334.4l-0.4,1.6h5.2l0.3-0.3l0.4-0.7h4.8v-0.7h-8"
          />
          <path
            fill="#C8C8C8"
            d="M754.8,337.9l0.4,0.7h0.8l-0.1-3.6h-0.7l-0.4,0.7"
          />
          <path fill="#656565" d="M754.8,335.7l-0.3,0.3h-1.8v1.5h1.8l0.3,0.4" />
          <path
            fill="#656565"
            d="M752.7,337.5h-4l-0.4,1.8H760v-0.7h-4.8l-0.4-0.7l-0.3-0.4"
          />
          <path fill="#C8C8C8" d="M751.9,336v1.5h0.8V336" />
          <path
            fill="#656565"
            d="M756.7,338.6v-1.1h0.1l-0.1-1.5v-1h-0.8l0.1,3.6"
          />
          <path
            fill="#656565"
            d="M756.7,336h1.2v-1h-1.2 M758.7,337.5v1.1h1.2v-1.1 M756.9,337.5v1.1h1.1v-1.1"
          />
          <path fill="#FFFFFF" d="M758,337.5v0.8 M756.7,338.6h0.1v-1.1h-0.1" />
          <path fill="#C8C8C8" d="M758,337.5l-0.1-1.5h-1.1l0.1,1.5" />
          <path
            fill="#656565"
            d="M759.9,336v-1h-1.2v1 M757,344h-9.9l-0.3,1.8h7.7l0.7-1.1h4.8V344h-1.9"
          />
          <path fill="#C8C8C8" d="M756.9,339.3l0.1,4.7h1.1l-0.1-4.7" />
          <path fill="#656565" d="M756,339.3l0.1,4.7h0.8l-0.1-4.7" />
          <path fill="#C8C8C8" d="M756.2,344l-0.1-4.7h-1.2l0.1,4.7" />
          <path fill="#656565" d="M752.6,339.3l-0.1,4.7h2.5l-0.1-4.7" />
          <path fill="#C8C8C8" d="M751.9,339.3l-0.1,4.7h0.7l0.1-4.7" />
          <path
            fill="#656565"
            d="M751.2,329.4l-1.5,5h2.3l0.1-5 M749.3,336l-0.5,1.5h3.2V336 M747.4,342.9l-0.3,1.1h4.7l0.1-4.7h-3.6
	 M746.7,349H760v-0.8h-4.8l-0.8-1V347h-7.6C746.7,347.7,746.7,348.4,746.7,349L746.7,349z"
          />
          <path fill="#C8C8C8" d="M752.3,347.2v-1.4h-1.7v1.4" />
          <path fill="#656565" d="M748.6,355.3h4.4l0.8-1h6.2v-0.7h-12.1" />
          <path fill="#C8C8C8" d="M752.3,353.6V349h-1.7v4.7" />
          <path
            fill="#656565"
            d="M750.7,358.6h9.4v-0.7h-6.2l-0.8-1.1h-3.6l1.1,1.8"
          />
          <path fill="#FFFFFF" d="M750.7,358.6h-0.1l0.1,0.1" />
          <path
            fill="#C8C8C8"
            d="M751.8,360.2l0.5,0.5v-2.2h-1.7v0.1 M752.3,356.8v-1.5h-1.7v1.5 M754.5,345.8v1.5l0.7,1h1.2l-0.1-3.6h-1.1
	 M754.5,353.6h2.1l-0.1-4.7h-1.9 M756.7,348.3v-1.1h1.2v1.1h0.3l-0.1-3.6H758v1.1h-1.2v-1.1h-0.4l0.1,3.6"
          />
          <path
            fill="#656565"
            d="M756.7,344.7v1.1h1.2v-1.1 M756.7,348.3h1.2v-1.1h-1.2 M759.9,345.8v-1.1h-1.2v1.1 M759.9,348.3v-1.1h-1.2
	v1.1 M758.7,354.3v1h1.2v-1"
          />
          <path
            fill="#C8C8C8"
            d="M756.6,354.3l0.1,3.6v-1.1h1.2v1.1h0.5l-0.1-3.6H758v1h-1.2v-1"
          />
          <path fill="#656565" d="M758,355.3v-1h-1.2v1" />
          <path
            fill="#C8C8C8"
            d="M754.7,357.9h2.1l-0.1-3.6h-2.1 M756.5,349l0.1,4.7h1.8l-0.1-4.7 M756.9,362.7h1.8l-0.1-4.1h-1.8"
          />
          <path
            fill="#656565"
            d="M758,357.9v-1.1h-1.2v1.1 M759.9,357.9v-1.1h-1.2v1.1"
          />
          <path
            fill="#FFFFFF"
            d="M758.7,362.7h-2.8c-0.4-0.1-0.8-0.1-1.4-0.4c-0.8-0.3-1.7-0.8-2.3-1.5l-0.6-0.5l2.8,3.3
	c1.5,1.1,3.7,1.5,6.6,1.2c1,0,1.2-0.3,1-1c-0.4-0.8-1.1-1.1-2.1-1.1L758.7,362.7L758.7,362.7z"
          />
          <path
            fill="#C8C8C8"
            d="M756,362.7h0.8l-0.1-4.1h-2.1v3.7C755.2,362.4,755.6,362.6,756,362.7L756,362.7z"
          />
          <path
            fill="#FFFFFF"
            d="M346.8,355.7l-74.8-0.1c0,1.5,0.1,3,0.1,4.7v8c5.9-4.4,14.2-6.9,24.6-7.6l52,1.2L346.8,355.7L346.8,355.7z"
          />
          <path
            fill="#FFFFFF"
            d="M265.5,365.2v17.1l6.6-0.4v-21.6c0-1.5,0-3.2-0.1-4.7h-6.7v0.5C265.5,359.1,265.5,362.2,265.5,365.2
	L265.5,365.2z M289.3,254.6l-4.5-2.2C286.3,253.1,287.8,253.9,289.3,254.6z"
          />
          <path
            fill="none"
            d="M613.8,358.4c-0.3,0.3-0.5,0.5-0.7,1l-0.4,1.1h-2.3l0.5-0.8c0.1-0.3,0.4-0.4,0.7-0.5
	C612.3,358.6,613,358.4,613.8,358.4L613.8,358.4z"
          />
          <path
            fill="none"
            d="M612.7,358.2l0.8-0.1c1.1,0,2.1,0.1,2.9,0.5l0.5,0.3l0.5,0.4l0.4,0.5c0.1,0.3,0.1,0.4,0.1,0.7v0.8
	c0,0-0.1,0.4-0.4,0.6l-0.4,0.5l-0.5,0.4c-0.4,0.3-0.7,0.4-1.2,0.5l-1.5,0.3l-1.7-0.1c-0.4-0.1-0.8-0.3-1.2-0.5l-1-0.8l-0.4-0.5
	l-0.1-0.7v-0.8l0.3-0.7l0.4-0.5l0.5-0.4C611.5,358.6,612,358.3,612.7,358.2L612.7,358.2z"
          />
          <path
            fill="none"
            d="M617,361.2v-0.5h-1.1v0.5h-0.1v-0.7h1.4c0.1,0,0.1,0,0.1,0.1v0.5L617,361.2L617,361.2z M615,360.6h-0.7
	l-0.1,0.3v0.1h1.1v-0.4"
          />
          <path
            fill="none"
            d="M614.9,360.5l0.4-0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.1v0.4l-0.1,0.1h-1.2l-0.1-0.1v-0.5h0.3c0,0,0.1-0.1,0.3-0.1
	L614.9,360.5L614.9,360.5z"
          />
          <path
            fill="none"
            d="M613.9,360.1l-0.1-0.8v-0.8l1.2,0.1l1,0.4c0.7,0.4,1.1,0.8,1.2,1.5h-3l-0.1-0.1L613.9,360.1L613.9,360.1z
	 M613.7,360.5h0.1v0.7h-0.1v-0.3 M613.5,360.6h-1.2v0.4h1.2v0.1h-1.4l-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1l0.3-0.1l0.4,0.1L613.5,360.6
	L613.5,360.6z M613.3,361.5l0.3,0.1l0.1,0.1c0.1,0.3,0.1,0.4,0.1,0.8v1c-0.8,0-1.5-0.1-2.1-0.5c-0.5-0.4-1.1-1-1.2-1.5L613.3,361.5
	L613.3,361.5z M614.4,362.6l0.3-0.5c0.1-0.3,0.1-0.4,0.1-0.7h2.5c-0.1,0.7-0.5,1.2-1.2,1.5c-0.3,0.1-0.7,0.3-1,0.4l-1.2,0.1h0.1
	L614.4,362.6L614.4,362.6z M611.9,360.5v0.1h-1.2v0.1h1.2v0.1l-0.1,0.1h-1.4l-0.1-0.1h1.2l0.1-0.1h-1.2v-0.3l0.1-0.1l0.3-0.1
	l0.3,0.1h0.8L611.9,360.5L611.9,360.5z M209.9,360.6c0-0.1,0-0.1-0.1-0.1h-1.2v0.1h1.2v0.1h-1.2v0.3h1.4l0.1-0.1h-1.2l-0.1-0.1h1.2
	L209.9,360.6L209.9,360.6L209.9,360.6z M205.5,361.5H203c0.1,0.7,0.6,1.2,1.2,1.5l1.1,0.4l1.2,0.1l-0.6-0.8l-0.3-0.6L205.5,361.5
	L205.5,361.5z M204.6,361.2v-0.7h-1.4c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.3,0.1v-0.5h1.1v0.5h0.1L204.6,361.2L204.6,361.2z"
          />
          <path
            fill="none"
            d="M203.3,359.1l-0.6,0.4l-0.4,0.5c-0.1,0.3-0.1,0.4-0.1,0.7v0.8l0.3,0.5l0.4,0.6l0.6,0.4l1.2,0.5l1.5,0.3l1.6-0.1
	c0.4-0.1,0.8-0.3,1.2-0.5l1-0.8l0.4-0.5l0.3-0.7c0-0.3,0-0.5-0.1-0.8c0-0.3-0.1-0.4-0.3-0.7l-0.4-0.6l-0.6-0.4
	c-0.6-0.3-1.1-0.5-1.8-0.7l-0.8-0.1c-1.1-0.1-2.1,0.1-2.9,0.6L203.3,359.1L203.3,359.1z"
          />
          <path fill="none" d="M205.2,360.6h0.7l0.1,0.3v0.1H205v-0.4" />
          <path
            fill="none"
            d="M206.2,361.2v-0.5H205c-0.1,0-0.3,0-0.3,0.1l-0.1,0.1v0.4l0.1,0.1h1.2L206.2,361.2L206.2,361.2z M207,361.5
	l-0.3,0.1l-0.1,0.1c-0.1,0.3-0.1,0.4-0.3,0.8v1c0.8,0,1.5-0.1,2.1-0.5s1-1,1.2-1.5L207,361.5L207,361.5z M206.8,360.5l1,0.1h0.3v0.4
	h-1.2v0.1h1.4c0.1,0,0.1,0,0.1-0.1v-0.5c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.3-0.1l-0.4,0.1h-0.7L206.8,360.5L206.8,360.5z
	 M206.6,360.5h-0.1v0.7h0.1"
          />
          <path
            fill="none"
            d="M206.5,358.4c0.3,0.3,0.6,0.5,0.7,1l0.4,1.1h2.3l-0.4-0.8c-0.1-0.3-0.4-0.4-0.7-0.5
	C208,358.6,207.3,358.4,206.5,358.4L206.5,358.4z"
          />
          <path
            fill="none"
            d="M206.5,358.4l-1.2,0.1l-1,0.4c-0.7,0.4-1.1,0.8-1.2,1.5h3c0.1,0,0.1-0.1,0.1-0.1l0.1-0.3
	c0.1-0.3,0.1-0.5,0.1-0.8C206.6,359,206.6,358.7,206.5,358.4L206.5,358.4z M96.4,291.6c0.6-1.4,1.2-2.5,1.8-3.2
	c2.5-2.1,4.3-4.1,5.4-5.9c1.9-4.4,3.3-9.9,4.1-16.1 M112.3,267.1h-0.6 M118.2,270.4c0,0.3,0,0.5-0.1,0.8l-0.4,1.4 M112.8,272.6h4.8
	 M112.8,272.6c0-2.3-0.1-4.1-0.6-5.5 M112.3,267.1l1.5,0.3c2.9,0.5,4.3,1.5,4.3,3 M108,265v0.1l-0.3,1.2 M108.7,269.4
	c-0.3,0-0.6,0.7-0.8,2.1c-0.4,1.5-0.6,3.2-0.7,5.2s-0.1,3.9-0.1,5.2c0,1.2,0.1,1.9,0.4,2.2 M107.4,284.3l0.1,0.1
	c0.3,0,0.6-0.7,1-2.1c0.3-1.4,0.6-3.2,0.7-5.2s0.3-3.9,0.1-5.4c-0.1-1.6-0.4-2.3-0.7-2.3 M111.7,267.1c-1.2,0.3-2.2,1.1-3,2.3
	 M107.7,266.4l4,0.7 M120.8,288.1h-0.6 M115.3,280.9h-3 M114.6,283.3c-1.2,1-2.6,1.9-4.4,2.6"
          />
          <path
            fill="none"
            d="M112.3,280.9l0.3-2.9c0.1-2.1,0.3-3.7,0.3-5.4 M114.6,283.3l0.7-2.5 M110.3,285.9h-0.1 M110.3,285.9
	c1-1.2,1.5-2.9,1.9-5.1 M108,290.4h-0.1l-1.1,8.1 M110.2,285.9c-1.8,0.8-3.9,1.4-6.2,1.9c-3.7,0.8-6.2,2.1-7.4,3.7 M107.4,284.3
	c0.6,0.8,1.5,1.4,2.8,1.6 M108,290.4c2.1,0,2.8,2.6,1.9,7.7 M117.6,272.6l-2.3,8.3"
          />
          <path
            fill="none"
            d="M120.2,288.1h-7.3c-3.4,0.5-8.1,2.1-13.8,4.3c-0.1,0.7,2.6,0.7,8.1,0 M123,283.3h-8.4 M120.2,288.1
	c-4.4,1.4-8.5,2.1-12.2,2.1"
          />
          <path
            fill="none"
            d="M96.6,300.4c0.4-4,1-6.7,1.8-8.1c5.2-2.5,9.8-4,13.6-4.7h9.1 M118.2,270.4l10-0.1 M752.3,358.6h-1.7
	 M752.3,355.3h-1.7 M752.3,345.8h-1.7 M752.3,347.2h-1.7 M750.7,356.8h1.7 M750.7,349h1.7 M750.7,353.6h1.7 M752.3,356.8v-1.5
	 M756.7,357.9l-0.1-3.6 M754.7,357.9l-0.1-3.6 M758.5,357.9l-0.1-3.6 M96.4,291.6c-1,2.2-1.8,5.4-2.8,9.4 M756.7,358.6h-2.1
	 M756.9,362.7l-0.1-4.1 M754.8,362.3c-0.8-0.3-1.7-0.8-2.3-1.5 M754.7,358.6l0.1,3.7 M754.7,357.9h2.1 M758.7,362.7l-0.1-4.1
	 M756.9,362.7h-0.8c-0.4-0.1-0.8-0.1-1.4-0.4 M758.7,362.7h-1.8 M761,364.8c1,0,1.2-0.3,1-1c-0.4-0.8-1.1-1.1-2.1-1.1h-1.2
	 M759.9,357.9h-1.2 M758.5,358.6h1.5v-0.7h-0.1 M756.7,357.9h1.2 M759.9,357.9v-1.1h-1.2v1.1 M758,357.9v-1.1h-1.2v1.1 M756.7,358.6
	h1.8 M758,357.9h0.5 M758.7,357.9h-0.1 M752.3,356.8h0.7l0.8,1.1h0.8 M752.3,358.6h2.3 M752.3,360.8v-2.2 M756.5,349h1.8
	 M756.7,354.3h-0.1 M754.5,349v4.7 M756.5,348.3h0.3 M756.5,349l0.1,4.7 M756.7,344.7h-0.4 M752.3,349v4.7 M756.6,353.6h1.8
	 M758.4,353.6l-0.1-4.7 M754.5,354.3h-0.7l-0.8,1h-0.7 M756.6,354.3h-2.1 M754.5,353.6h2.1 M752.3,353.6h2.2 M759.9,354.3h-1.2
	 M758,354.3h-1.2 M758.4,354.3h0.3 M758,354.3h0.4 M756.7,354.3v1h1.2v-1 M758.7,354.3v1h1.2v-1"
          />
          <path
            fill="none"
            d="M759.9,354.3h0.1v-0.7h-1.7 M759.9,344.7h-1.2 M759.9,348.3h-1.2 M759.9,348.3v-1.1h-1.2v1.1 M758.2,348.3
	l-0.1-3.6 M758,348.3h0.3 M756.7,348.3h1.2 M758.7,348.3h-0.4 M758.2,349h1.8v-0.7h-0.1 M758.1,344.7h0.5 M758.7,344.7v1.1h1.2v-1.1
	 M758,348.3v-1.1h-1.2v1.1 M758.1,344.7H758 M758,344.7h-1.2 M756.7,344.7v1.1h1.2v-1.1 M756.3,344.7l0.1,3.6 M752.3,349h2.2
	 M754.5,345.8h-2.2 M754.5,349h1.9 M754.5,347.3l0.7,1h1.2 M754.5,347.3v-0.1h-2.2 M754.5,345.8v1.5 M756.3,344.7h-1.1l-0.7,1.1
	 M752.3,345.8v1.4 M750.7,355.3v1.5 M750.7,358.6v0.1 M750.5,358.6h0.1 M750.7,356.8h-1.2 M751.8,360.2l0.5,0.5 M746.7,349h4
	 M750.7,349v4.7 M750.7,345.8h-3.9 M750.7,347.2h-3.9 M750.7,355.3h-2.1 M747.9,353.6h2.8 M750.7,345.8v1.4 M752.5,344h-0.7
	 M751.9,339.3h0.7 M752.1,334.4h-2.3 M751.2,329.4h1 M756.3,320.5l0.1,4 M755.5,320.5l0.1,4 M754.5,320.3v4.1 M752.2,327.8h-0.4
	 M752.7,337.5h-0.8 M752.7,336h-0.8 M757.6,324.5l-0.1-4 M751.8,344h-4.7 M751.9,339.3l-0.1,4.7 M748.3,339.3h3.6 M751.9,336h-2.6
	 M751.9,337.5h-3.2 M751.9,337.5V336 M756.6,329.4l0.1,5 M755.8,329.4l0.1,5 M754.7,329.4l0.1,5 M752.1,334.4l0.1-5 M752.9,329.4
	l-0.1,5 M757.8,334.4l-0.1-5 M757,344h-0.8 M756,339.3h0.8 M756.7,338.6H756 M756.7,335.1h-0.8 M756.7,334.4h-0.8 M752.6,339.3h2.2
	 M752.5,344l0.1-4.7 M755,344h-2.5 M756.2,344h-1.2 M756,339.3l0.1,4.7 M754.8,339.3h1.2 M755,344l-0.1-4.7 M756.9,339.3h1.1
	 M757,344l-0.1-4.7 M758.1,344H757 M758.1,344l-0.1-4.7 M759.9,344.7h0.1V344h-1.9 M759.9,338.6h-1.2 M759.9,335.1h-1.2
	 M758.7,335.1v1h1.2v-1 M756.9,338.6h1.1 M758,337.5h-1.1 M756.7,336h1.1 M758,337.5l-0.1-1.5 M756.7,336l0.1,1.5 M756.9,338.6h-0.1
	 M756.9,337.5v1.1 M756.9,337.5h-0.1v1.1 M758.7,338.6H758 M758,338.6v-0.3 M758,338.4v-0.8 M758,337.5v0.8 M757.8,336h0.1v-1
	 M756.7,335.1v1 M759.9,338.6v-1.1h-1.2v1.1 M758,339.3h2.1v-0.7h-0.1 M759.9,335.1h0.1v-0.7h-2.2 M758,335.1h0.7 M758,335.1h-1.2
	 M757.8,334.4h-1.1 M754.8,337.9v-2.2 M755.9,335.1l0.1,3.6 M754.8,334.4h-2.1 M754.8,335.7l-0.3,0.3h-1.8 M752.7,337.5V336
	 M756,338.6h-0.8l-0.4-0.7 M754.8,337.9l-0.3-0.4h-1.8 M755.9,334.4h-1.1 M755.9,335.1h-0.7l-0.4,0.7 M752.7,334.4h-0.7
	 M756.6,329.4h1.1 M754.7,327.9l-0.1-1.8 M752.2,327.8v-1.5 M753,326.3l-0.1,1.5 M756.7,328.7h-0.1 M755.8,328.7l-0.1-3.4
	 M756.5,325.3l0.1,3.4 M756.7,325.3h-0.3 M756.5,324.5h1.1 M757.7,327.8l-0.1-1.5 M752.9,329.4h1.8 M755.8,328.7h-0.5l-0.6-0.8
	 M754.7,329.4h1.1 M754.7,327.9l-0.1-0.1h-1.7 M756.6,328.7h-0.8 M755.8,329.4h0.8 M752.9,327.8h-0.7 M752.2,329.4h0.7 M759.9,328.7
	h-1.2 M759.9,328.7v-1h-1.2v1 M758.7,328.7H758 M758,328.7v-1h-0.3 M757.7,327.8h-1v1 M756.7,328.7h1.2 M759.9,325.3h-1.2
	 M758,325.3h0.7 M757.6,326.3h0.4v-1 M758,325.3h-1.2"
          />
          <path
            fill="none"
            d="M756.7,325.3v1h0.8 M759.9,325.3h0.1v-0.8h-2.5 M758.7,325.3v1h1.2v-1 M752.6,324.5h1.9 M753,326.3h-0.8
	 M754.5,326.1v0.1H753 M755.6,325.3h-0.4l-0.7,0.8 M756.5,325.3h-0.8 M755.6,324.5h0.8 M754.5,324.5h1.1 M757.4,320.5h-1.1
	 M754.5,320.3h-0.7 M756.3,320.5h-0.8 M755.5,320.5l-1-0.1 M757.4,320.5h0.3c1.5,0,2.2-0.5,2.2-1.8 M700.6,293.9
	c-1.1,3-0.8,5.8,0.6,8.5 M704,294.6c-0.8,2.1-1.1,5.4-1,10.2 M706.4,295.2c-0.7,2.5-1,6.3-0.8,11.7 M719.3,299.4l-5.8-1.4v2.3
	 M681.5,298.3l19.7,4.1 M672.5,287.9l31.4,5.8 M672,288.4l26.8,5.2 M718.8,305.9l-5.2-0.5 M713.5,300.4l5.5-0.5 M729.5,307.3
	l-22,0.1 M719.2,306.6l9.5,0.1 M730.7,306.7l-3.7-5.5l-7.7-1.8 M728.7,306.7h1.1 M718.6,300.7l4.3,1.1c1,2.2,2.9,3.9,5.6,5
	 M718.2,302.5c-0.1,0.8,0,1.6,0.3,2.5 M719.2,306.6l-0.4-0.7 M718.5,305.1l0.3,0.8"
          />
          <path
            fill="none"
            d="M718.5,305.1h2.1c0.3,0,0.5-0.1,0.7-0.3c0.1-0.3,0.3-0.4,0.3-0.7v-0.5c0-0.3-0.1-0.5-0.3-0.7
	c-0.1-0.1-0.4-0.3-0.7-0.3h-2.3 M718.6,300.7c-0.3,0.5-0.4,1.2-0.4,1.8 M719,299.8l-0.4,0.8 M719.3,299.4l-0.3,0.4 M707.5,307.4
	l0.1,0.5 M707.5,307.4l-0.1-3.4c0.3-4.5-0.1-7.4-1.1-8.8 M713.5,305.3v1.1l5.6,0.1 M713.5,300.4v5 M707.6,308c1.4,0.7,3,1.1,4.7,1.5
	l18.6,5.5 M705.6,306.9c0.7,0.4,1.4,0.7,2.1,1.1 M703.1,304.8c0.8,0.7,1.7,1.4,2.5,1.9 M701.2,302.5c0.5,0.8,1.2,1.6,1.9,2.3
	 M726.8,299l-20.4-3.9 M700.6,293.9l-1.8-0.3 M704,294.6l-3.4-0.7 M706.4,295.2l-2.3-0.5 M701.3,291.5l-0.8-0.1 M700.5,291.3
	l3.4,2.3 M703.9,293.6l0.4,0.4 M698.8,293.6c-7.3,0.3-14.6-0.4-21.9-2.2l-0.7-0.1c-2.1-0.4-3.4-1.4-4.1-2.9 M700.5,291.3l-21.3-3.6
	l-0.8-0.1 M159.4,247.4c-3.4,0.6-6.5,0.6-8.9,0 M266.9,208.5l-1.8-2.9 M334.4,220.7c-0.5-4-3.4-5.9-8.7-5.8
	c-18.8,0.6-34.7,3-47.6,7.6c-13.3,4-19,7.7-17.1,11.1c4,5.2,12.9,11.4,27,18.4c7.6,3.3,19.8,4.8,36.6,4.8c6.9,0.1,10.5-2.3,10.7-7.6
	L334.4,220.7L334.4,220.7z M357.5,213.9h35.1c25.7,0.1,46.1,1.2,60.9,3.4c16.6,4.5,34.7,13.5,53.9,27.1 M482.9,211.8l-5.8,3.2
	c23.2,11.7,45.7,24.9,67.3,39.8c6.7,5,13.5,7.3,20.1,7.3l14.3-1.2 M158.6,237.9l-17.9-36.7l-0.1-0.1l-2.1-4.3v-0.3l0.1-0.1h0.3
	l0.1,0.1l2.1,4.3v0.3l17.7,36.6 M757.7,329.4h2.3v-0.7h-0.1 M109.8,254.3l-16.4-4"
          />
          <path
            fill="none"
            d="M581.6,262.4l-2.9-1.6c-32.5-18.6-64.5-34.9-95.9-49l-6.5-2.9 M476.4,208.8l1.2-0.6 M511.7,261.3
	c-0.8-0.1-1.4-0.4-1.9-0.7c-2.1-1.1-2.5-4.7-1.6-10.9l0.1-0.8 M509.7,249.6c0,1-0.1,2.1,0,3.3l0.1,0.7 M511.7,243.3h-3.9
	c-0.3,0.1-0.4,0.6-0.4,1.1c0,1,0.3,2.5,1,4.7 M476.4,208.8l-4.8,2.5l-1,0.3c-4,1.2-9.9,1.4-17.7,0.6 M452.9,212.2
	c8.4,1.9,15.4,4.4,20.9,7.4c16.4,8.5,29.9,16.2,40.4,23.1"
          />
          <path
            fill="none"
            d="M477.6,208.2c-11.3-4.7-34.2-7.7-68.8-9.1c-38.5-1.6-74.7-2.1-108.4-1c-4.3,0.3-8.3,0.8-11.8,1.5l0.3,0.7l0.8,3
	 M471.6,211.4c-18.3-5.8-54.9-9.2-110-10.2c-25.3-1.1-49.4-0.4-71.9,2.1 M452.9,212.2l-31.2-2.1c-21.5-0.6-42.9-0.7-64.4-0.6h-10.9
	 M346.4,209.6h-2.6"
          />
          <path
            fill="none"
            d="M361,257.9l-3.4-44l-0.3-4.3 M343.8,209.6c-10.3,0.1-20.8,0.3-31.1,0.6c-4.7,0.3-9.4,0.8-14,1.5
	c-5.5,1-11,2.2-16.2,3.9l-19.7,7c-6.9,3-9.1,5.9-6.6,8.7c11,9.6,20.6,16.6,28.6,21 M289.7,203.3c-6.6,1.1-14.3,2.9-22.7,5.2
	c-25.7,7-59.7,19.4-102.1,37.1 M508.4,249.1c0.5-2.5,1.2-4.1,2.1-5.1c0.4-0.3,0.8-0.6,1.2-0.7 M511.7,243.3c0.8-0.3,1.5-0.6,2.5-0.6
	 M514.2,242.8l2.8,0.4c4.1,1.4,7.3,3,9.4,5c2.5,2.2,3.9,4.4,4.1,6.3 M509.7,253.5l13.9,0.7c1.5,0.4,1.9-0.3,1.2-2.2
	c-0.4-1.1-1.5-1.6-3.6-1.6l-11.6-0.8 M530.3,260.1c-0.3,1.4-0.8,2.3-1.5,2.8c-1.2,0.5-3.4,0.7-6.6,0.1l-4.7-1.1l-5.8-0.4
	 M511.7,261.3l0.4,1.6"
          />
          <path
            fill="none"
            d="M530.3,254.4v0.4c0.3,2.2,0.1,4,0,5.4 M540.9,265.7c-0.3-0.5-0.8-1.1-1.2-1.8 M539.8,263.9
	c-2.2-2.9-5.2-6.1-9.5-9.5 M509.7,253.5c0.7,2.6,2.5,4,5.6,3.9c7.8,0.7,12.9,1.6,15,2.6 M540.9,265.7c1.7,3,2.8,6.9,3.3,11.7
	 M512.2,263l27.7,1 M583.3,262.1l-1.7,0.3 M581.6,262.4l-1.4,0.1 M766,373.3c-0.3,0.4-0.8,0.7-1.7,0.8l-3.3,0.5
	c-7.2,1-14.9,1.5-23.1,1.6l-13.9,2.2l-49.7,2.2v-6.2c-0.1-8.1-0.5-15-1.4-20.5l-0.1-1.9c-2.2-11.8-7.6-22.3-16.4-31.2
	c-11.6-11.8-25.6-17.9-42-17.9c-14.4,0-27,4.5-37.6,13.8c-1.5,1.2-2.9,2.6-4.3,4c-1.2,1.4-2.5,2.6-3.6,4
	c-7.8,9.5-12.4,20.5-13.5,32.9v1.6"
          />
          <path
            fill="none"
            d="M579.6,342.1l3.4-1.1l0.7,0.1 M578.5,343.7l0.7,0.1 M579.1,343.9l-0.5-0.1h-0.1 M578.5,343.7l1-1.8
	 M579.4,341.9l0.1,0.1 M575.6,354.3l12.7,4.7c0.8,0.4,1.7,0.7,2.8,0.8c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.3,1.1-0.5,1.4-1l0.1-0.3
	v-0.3c0.1-0.5-0.1-1.1-0.4-1.5l-6.1-5.4c-0.8-0.8-1.7-1.5-2.5-1.9l-0.1-0.1c-0.8-0.4-1.7-0.8-2.6-1l-12.8-3.6H579"
          />
          <path
            fill="none"
            d="M572.3,330.8c1.5-2.1,3.2-4,5.1-5.8c1.5-1.5,3.2-3,4.8-4.3c8.9-7.2,19.4-10.6,31.2-10.6
	c12,0,22.3,3.6,31.4,10.6l4.8,4.3c2.3,2.3,4.4,4.8,6.2,7.3c5.9,8.4,8.8,18,8.8,28.8v2.1c-0.1,4.1-0.7,8-1.8,11.7
	c-1.5,5.4-3.9,10.3-7,15c-1.8,2.5-3.9,5-6.2,7.3c-8.5,8.5-18.4,13.3-30,14.7l-7.4,0.3l-5.2-0.4c-11.3-1.4-21.2-6.2-29.6-14.6
	l-3.2-3.4l-1.9-2.5c-3.3-4.3-5.8-8.9-7.3-13.9l-1-3.2c-1.1-4.1-1.7-8.5-1.7-13.2C562.4,349.6,565.7,339.5,572.3,330.8L572.3,330.8z"
          />
          <path
            fill="none"
            d="M548.5,359.4l0.5-6.9c1.2-12.7,6.2-23.9,15-33.7l4-4.1c12.8-12.1,28.3-18.3,46.5-18.3s33.7,6.1,46.6,18.3
	c9.6,9.1,15.5,19.7,18,31.5c1.5,5.9,2.3,13.2,2.6,21.9c1.1,3.6,3.4,5.4,7,5.2H766 M544.2,277.4l30.4,1.4
	c38.8,1.9,72.4,4.8,100.8,8.7 M580.2,262.6c-12.1,1.2-25.2,2.3-39.2,3.2"
          />
          <path
            fill="none"
            d="M646.1,346.3l0.7-0.3l1.7-2.1 M648.5,344h0.1 M648.6,344l-1-1.8 M647.7,342.2l-0.3,0.1 M648.6,344h-0.1
	 M647.4,342.4l-3.3-1.1l-0.7,0.1 M647.7,342.2c-1.8-3.2-4-6.1-6.7-8.8c-5.8-5.8-12.4-9.4-19.9-10.7"
          />
          <path
            fill="none"
            d="M643.4,341.4c-1.4-1.9-2.9-3.9-4.7-5.6c-4.5-4.5-9.8-7.7-15.5-9.2"
          />
          <path
            fill="none"
            d="M623.2,326.5l-0.1-0.7l-2.1-3 M629.6,345.4c-0.4,0.3-1,0.5-1.4,0.8l-5,3.7c-0.8,0.8-1.5,1.1-1.9,0.5
	c-0.8-0.5-1-1.4-0.4-2.5l2.1-5.8l0.4-1.5"
          />
          <path
            fill="none"
            d="M618.3,350.2l2.8-7.8c0.3-0.8,0.4-1.5,0.5-2.3v-0.4c0.1-1.1,0.1-2.1,0.1-3.2l-0.8-13.6 M623.6,340.8l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6l-0.6-10 M629.6,345.4l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5 M606.1,323.1l0.1-0.3 M604,326.5v-0.7l2.1-3
	v0.3 M606.1,323.1v0.1l-0.7,13.2c0,1.1,0,2.2,0.1,3.2v0.3c0.1,0.8,0.3,1.6,0.7,2.3l2.8,8 M608.2,322.4l1.4,2.3v0.8 M608.2,322.4
	l-1.9,0.4 M621,322.8l-1.9-0.3 M617.7,325.6v-0.8l1.4-2.2 M619,322.5v0.5 M619,323.1v-0.5"
          />
          <path
            fill="none"
            d="M619,322.5l-5.5-0.4l-5.4,0.3 M617.7,325.6l-4.1-0.3l-4,0.3"
          />
          <path
            fill="none"
            d="M619,323.1l0.5,13.5c0.1,0.8,0.1,1.8-0.1,2.8c-0.1,1-0.6,1.9-1.1,3l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7
	c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4c-0.5-1.1-1-2.1-1.1-3v-0.1c-0.1-1-0.1-1.9-0.1-2.8l0.6-14"
          />
          <path
            fill="none"
            d="M609.3,338.9c0.1,0.5,0.4,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.7,1.1h0.7c0.7,0,1.2-0.3,1.7-1.1l1.8-3.2
	c0.3-0.5,0.5-1.1,0.7-1.6 M617.9,338.9l0.1-1.1l-0.4-12.2"
          />
          <path
            fill="none"
            d="M609.3,338.9l4.3-0.4l4.4,0.4 M603.6,340.7l-0.1-0.7c-0.1-1.2-0.1-2.5,0-3.6l0.6-10 M597.4,345.2l1.2,0.7l5,3.7
	c0.8,0.8,1.7,1,2.5,0.4c0.5-0.3,0.5-1,0-1.9l-2.1-5.8l-0.4-1.5 M609.5,325.6l-0.4,12.2l0.1,1.1 M606.2,322.8
	c-7.7,1.4-14.4,5-20.2,10.7c-2.6,2.6-4.8,5.5-6.5,8.5"
          />
          <path
            fill="none"
            d="M583.7,341.1c1.4-1.9,2.8-3.7,4.5-5.4c4.7-4.7,9.9-7.7,15.8-9.2 M597.4,345.2c1.9-1.9,4-3.4,6.2-4.5
	 M604.6,353.1l-6.6-5c-0.7-0.6-1.2-1-1.9-1.2l-0.4-0.4c-1-0.4-1.9-0.8-2.9-1.1l-13.2-3.4"
          />
          <path
            fill="none"
            d="M583.7,341.1l9.6,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.8,0.4 M593.9,349.9c0.5,0.3,1,0.7,1.4,1.1l2.5,2.6
	c0.5,0.5,0.7,1.2,0.5,1.9l-0.3,0.7c-0.1,0.7-0.7,1.1-1.5,1.2l-3.6,0.7l-1.8,0.1 M601.8,362c-0.5,0.7-0.7,1.5-0.5,2.3
	c0.1,1,0.5,1.6,1.4,2.2l0.7,0.4c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.5-0.8,0.8-1.7,0.7-2.5c-0.1-1-0.5-1.6-1.4-2.2
	s-1.7-0.8-2.5-0.6c-1,0.1-1.7,0.5-2.2,1.4L601.8,362L601.8,362z M594,370.8l5.6-4c1.1-0.5,1.5-1.1,1.1-1.5c-0.1-1.1-0.7-1.5-1.9-1.2
	h-7.7 M591.1,358.2l-1.1-0.3l-11.6-4.1 M575.2,356.2l12.4,4.8c1.1,0.4,2.1,0.7,3,0.8l0.4,0.1l2.3,0.1l8.4-0.3"
          />
          <path
            fill="none"
            d="M591.2,364.1l-0.7-0.1c-1.1-0.3-2.2-0.5-3.4-1.1l-9.2-3.6 M591.1,358.2c0.3-3,1.2-5.6,2.8-8.3"
          />
          <path
            fill="none"
            d="M593.9,349.9c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4 M609,350.3c-0.8-0.3-1.7-0.1-2.3,0.3
	c-0.8,0.4-1.4,1.1-1.7,1.9l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,2.1,1.6c0.8,0.3,1.8,0.3,2.5-0.1
	c0.8-0.4,1.4-1.1,1.7-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6L609,350.3L609,350.3z"
          />
          <path
            fill="none"
            d="M609.7,354c-0.1,0.4-0.4,0.8-0.8,1l-1.2,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.3-0.1-0.7-0.1-1v-0.4
	c0.1-0.4,0.4-0.8,0.8-1l1.2-0.1c0.4,0.1,0.8,0.4,1,0.8C609.8,353.2,609.8,353.6,609.7,354L609.7,354z M617.7,352.4
	c0.3-0.3,0.5-0.4,0.8-0.4h0.4c0.4,0,0.8,0.1,1.2,0.5c0.3,0.3,0.4,0.7,0.4,1.2c0,0.4-0.1,0.8-0.4,1.2c-0.4,0.3-0.7,0.5-1.2,0.5
	c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2C617.2,353.2,617.4,352.8,617.7,352.4L617.7,352.4z"
          />
          <path
            fill="none"
            d="M622.2,353.5v0.3c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.4,1-2.3,1c-1,0-1.8-0.4-2.5-1c-0.7-0.7-1-1.4-1-2.3
	c0-1,0.3-1.8,1-2.5c0.5-0.4,1-0.7,1.7-0.8l0.8-0.1c1,0,1.7,0.3,2.3,1C621.9,351.8,622.2,352.5,622.2,353.5L622.2,353.5z
	 M616.7,357.2l0.4,0.3c1,1,1.5,2.2,1.5,3.6v0.3c-0.1,1.2-0.5,2.3-1.5,3.3c-0.8,0.8-1.8,1.2-2.9,1.4H613c-1.1-0.1-2.1-0.6-2.9-1.4
	l-0.3-0.4c-0.5-0.5-0.8-1.2-1-1.9l-0.1-1.2c0-1.4,0.6-2.5,1.4-3.6c1-1,2.2-1.5,3.6-1.5C614.6,356,615.7,356.4,616.7,357.2
	L616.7,357.2z M620.7,363.1c0.1-0.4,0.5-0.7,1-0.8l1.2,0.1c0.3,0.1,0.5,0.4,0.7,0.7l0.1,0.4c0.1,0.4,0.1,1-0.1,1.2
	c-0.3,0.4-0.5,0.7-1,0.8h-1.2c-0.4-0.3-0.7-0.5-0.8-1C620.4,364.1,620.4,363.5,620.7,363.1L620.7,363.1z"
          />
          <path
            fill="none"
            d="M623.4,367.1h-0.1c-1,0.3-1.8,0.3-2.6-0.3c-0.8-0.4-1.4-1.1-1.7-1.9c-0.3-1-0.1-1.8,0.3-2.6
	c0.4-0.8,1.1-1.4,1.9-1.6c1-0.3,1.8-0.3,2.6,0.1c0.7,0.3,1.1,0.7,1.4,1.2l0.3,0.7c0.3,1,0.3,1.8-0.1,2.6
	C624.8,366.1,624.3,366.7,623.4,367.1L623.4,367.1z M603.4,362.8c0.3-0.4,0.7-0.5,1.1-0.7c0.5-0.1,1,0,1.2,0.3
	c0.4,0.3,0.5,0.7,0.7,1.1c0,0.4,0,0.8-0.3,1.2s-0.7,0.7-1.1,0.7c-0.3,0.1-0.7,0-1-0.1l-0.4-0.1c-0.4-0.3-0.5-0.7-0.7-1.1
	C602.9,363.7,603.1,363.3,603.4,362.8L603.4,362.8z M595.1,395.5l8.8-11c0.5-0.7,1.1-1.4,1.5-2.2v-0.1c0.4-1,0.7-1.9,1-3l1.8-7.8
	c0-0.7-0.1-1.2-0.5-1.5l-0.3-0.3c-0.1-0.1-0.1-0.1-0.3-0.1l-1.7-0.1l-6.9,4.1c-1,0.4-1.8,1.1-2.6,1.8v0.1c-0.5,0.4-1,1-1.4,1.5
	l-8.1,12.2 M611.7,370c0-0.4,0.3-0.8,0.7-1.1s0.8-0.4,1.2-0.3c0.5,0,0.8,0.3,1.1,0.7c0.3,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.7-0.4,0.8
	l-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.3c-0.5-0.1-0.8-0.3-1.1-0.7C611.7,370.8,611.6,370.4,611.7,370L611.7,370z"
          />
          <path
            fill="none"
            d="M610.8,372.3l-0.1-0.1c-0.5-0.8-0.7-1.6-0.5-2.5c0.1-1,0.5-1.7,1.4-2.2c0.8-0.6,1.7-0.7,2.5-0.6
	c1,0.1,1.7,0.6,2.2,1.4c0.6,0.7,0.8,1.7,0.7,2.5c-0.1,0.7-0.4,1.2-0.8,1.6l-0.5,0.5c-0.7,0.6-1.5,0.8-2.5,0.7
	C612,373.4,611.3,373,610.8,372.3L610.8,372.3z M596.9,376.2c0.4-0.4,0.8-0.7,1.4-1l3.3-1.5h1.9l0.5,0.4c0.5,0.4,0.8,1,0.7,1.8
	l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8 M640.5,389.1l-7.8-11.7c-0.4-0.8-1-1.5-1.7-2.2c-0.7-0.7-1.7-1.2-2.6-1.8l-6.9-4.1h-1.7
	l-0.3,0.1l-0.3,0.3c-0.4,0.4-0.5,1-0.5,1.5l1.8,7.8c0.1,1.2,0.5,2.3,1,3c0.4,0.8,1,1.6,1.5,2.3l8.8,11 M609.4,383.2
	c0.3-0.5,0.4-1.1,0.7-1.5l1.8-5.9c0.1-1.2,0.7-1.6,1.8-1.4c0.5-0.1,1,0.4,1.1,1.7l2.3,7.3 M617.1,383.2l0.4,0.7
	c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.7 M629.8,396.3l-8.7-10.6c-0.7-0.8-1.2-1.6-1.8-2.6l-0.1-0.3c-0.4-0.7-0.7-1.5-1-2.2l-2.3-8"
          />
          <path
            fill="none"
            d="M617.1,383.2l-3.6,0.3l-4.1-0.3 M603.8,381.4l-0.5,1l-7.6,9.8 M597.2,395.9l8.4-10.2c0.7-0.8,1.2-1.8,1.8-2.6
	l0.1-0.3c0.4-0.8,0.7-1.5,1-2.3l2.3-8 M609.4,383.2l-0.3,0.5c-0.7,1.1-1.4,2.1-2.1,2.9l-6.3,7.8 M585.3,383.2l4.1-6.5l1.2-1.9
	c0.7-1.1,1.5-2.1,2.2-2.9l0.5-0.5 M593.5,371.4l-0.1-0.4 M603.1,367l-6.9,4.7c-0.7,0.5-1.2,1-1.8,1.5l-0.3,0.3
	c-0.7,0.7-1.4,1.5-1.9,2.5l-7.3,11.4 M589.2,387.3l6.9-10.2l0.5-0.7l0.3-0.3 M603.8,381.4c-2.2-1.1-4.4-2.6-6.2-4.4l-0.4-0.4
	l-0.3-0.3 M597.2,376.5h-0.5"
          />
          <path
            fill="none"
            d="M585.3,383.2l-0.4,0.7v3.6 M584.9,387.5l1.4,1.4 M586.3,388.8l2.3-1l0.5-0.7 M593.5,371.4l0.5-0.5 M593.3,371
	l0.7-0.1 M593.3,371c-1.1-2.2-1.8-4.4-2.1-6.9 M629.8,357.5c-0.4-0.3-0.7-0.5-0.8-1l-0.3-0.7c-0.3-0.7,0-1.4,0.5-1.9l0.8-0.8
	 M622.2,353.5l6.7-5.1c0.7-0.5,1.4-1,2.1-1.4l0.3-0.1c0.8-0.4,1.8-0.7,2.9-1.1l13.2-3.4 M623.6,340.8c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3
	 M651.9,354.5l-6.5,2.5l-6.6,2.5c-0.8,0.4-1.8,0.7-2.8,0.7c-1,0.1-2.1,0.1-3.2,0l-8-0.8c-0.7-0.1-1.1-0.5-1.4-1l-0.1-0.3v-0.3
	c-0.1-0.5,0.1-1.1,0.4-1.6l6.1-5.4c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9 M630,353.1l1.7-1.8
	c0.5-0.5,1-0.8,1.5-1.1c0.3-0.3,0.5-0.4,1-0.4l8.1-2.3 M645,353.9l-0.3-1.8c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5
	c0,1.5-0.8,2.5-2.3,3c-0.8,0.1-1.5,1-2.2,2.3h-0.6 M634.2,352c-0.7,0.4-1.2,1.5-1.4,3.2v0.3 M642.3,347.4v1.2h-1.9
	c-2.1,0.1-3.2,0.7-3.3,1.8c-0.3,1-1,1.4-1.9,1.2c-0.4,0-0.7,0.1-1.1,0.3 M632.8,355.3h2.5 M635.3,355.3c-0.3-1.2-0.7-2.3-1.2-3.4
	 M632.8,358.2l1.2,0.3h1.8l0.8-0.1 M632.8,356.2v1.9 M632.8,356.2c1.2-0.1,2.2,0,2.8,0.4c0.6,0.3,1,1,1.1,1.8 M636.6,358.3l0.3-0.1
	l8.3-3 M635.8,364.4h0.4 M636.1,364.4l-0.4,0.4 M635.7,364.8v-0.3 M636.1,364.4l0.4-0.1c1.2-0.3,2.3-0.7,3.4-1.1l5.6-2.2 M636,366.3
	l-0.1-0.1h-0.3 M635.5,366h-2.8 M632.8,366v-0.8 M634.4,369.6c-1-0.4-1.4-1.5-1.7-3.3V366 M635.5,366l-0.1,0.3 M632.8,365.2v-0.7
	 M651.6,356.6l-6.1,2.3l-6.2,2.3c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3 M632.8,365.2c1.4,0.1,2.3,0,2.9-0.4 M635.4,366.3l0.5-0.1
	 M635.8,364.4l-3,0.1 M629.8,357.5l0.7,0.3l2.3,0.4 M629.8,364.4H628c-1.2-0.3-1.8,0-1.8,0.5c-0.5,1-0.4,1.6,0.8,2.2l3.2,2.2
	 M630,353.1c-0.1,1.1-0.3,2.6-0.3,4.4 M632.8,355.3v0.8 M632.8,364.5l-3-0.1 M645.3,355.3l3.3-1.2 M648.6,354l0.7-0.3l2.6,0.7
	 M645,353.9l0.1,1.4 M645.3,355.3l-0.1-1.4 M642.3,347.4l3.9-1.1 M646.1,346.3c1.1,2.5,1.9,5,2.5,7.7 M651.8,356.5l-0.3,0.1
	 M651.9,356.5l-0.3,0.1 M641.9,387.7c7-7.4,10.6-16.4,10.6-26.7c0-1.6,0-3.2-0.3-4.7 M652.1,356.4l-0.3,0.1 M649.2,359.5l0.7-0.3
	l2.2-2.9l-0.1,0.1 M651.9,356.5l0.3-0.1 M649,364.8c0.1-1.2,0.1-2.5,0.1-3.9v-1.4 M649.2,359.5l-3.6,1.4 M649,364.8l-3.7,0.4
	 M645.6,361.1l-0.3,4.3 M645.6,361.1c0,1.5-0.1,2.9-0.1,4.3 M652.2,356.4l-0.3-1.9 M638,378.4l3,0.3l0.7-3.3l0.5-2.8h-1.9
	c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1 M641.7,375.4l4.7-0.1"
          />
          <path
            fill="none"
            d="M645,367.5l-0.3,1.8c-0.4,1.1-1.4,1.9-2.8,2.6c-1.1,0.5-1.7,0.3-1.7-0.4c0-1.5-0.8-2.5-2.3-3
	c-0.8-0.1-1.4-1-2.1-2.2 M641.3,383.5l0.3,0.5v3.6 M640.5,389.1l1.4-1.4 M649,364.8c-0.4,3.7-1.2,7.2-2.8,10.5
	c-1.2,2.9-2.9,5.6-5,8.1 M629.9,376.5l0.7,0.8l6.9,10.2 M622.9,381.6c2.3-1.1,4.5-2.6,6.6-4.7l0.4-0.4 M622.9,381.6
	c-0.3-0.5-0.4-1.1-0.5-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.1l0.3-0.3l1.9-0.1l3.3,1.5c0.5,0.3,1.1,0.7,1.5,1.1
	 M641.7,387.6l-7.4-11.4c-0.5-0.8-1.1-1.6-1.9-2.5l-0.1-0.1c-0.5-0.7-1.2-1.2-1.9-1.6l-7-4.7 M630.2,369.3l3.2,2.3l0.4,0.4
	c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3 M637.5,387.5l0.4,0.7l2.5,1 M631,392.3l-7.6-9.8l-0.6-1 M638,378.4l3.3,5.1 M645.3,365.2l-0.3,2.2
	 M635.4,366.3c-0.3,1.1-0.5,2.2-1,3.3 M645.3,365.2c0,0.8-0.1,1.5-0.3,2.2 M629.8,364.4c0,2.1,0.3,3.7,0.4,5 M629.8,396.4l-0.1-0.1
	 M625.9,394.6l0.4,0.6l3.3,1.1 M631.5,395.6l-0.1-2.8l-0.4-0.5 M629.8,396.4l1.8-0.8 M595.7,392.1l-0.4,0.5l-0.1,2.8 M600.7,394.5
	l-0.4,0.5l-3.3,1.1 M596.9,396.1l0.3-0.3 M597.2,395.9l-0.3,0.3 M596.9,396.1l-0.1,0.1 M595.1,395.4l1.8,1 M596.8,396.3
	c5.1,2.5,10.6,3.6,16.6,3.6c5.9,0,11.4-1.1,16.4-3.4 M625.9,394.6c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2 M586.3,388.8
	c2.8,2.6,5.6,4.8,8.8,6.6 M631.6,395.6c3.2-1.6,6.1-3.9,8.8-6.5 M595.7,392.1c-2.3-1.4-4.4-3-6.5-4.8 M637.5,387.5
	c-2.1,1.9-4.1,3.4-6.5,4.8 M539.1,359.4c-0.5,2.3-1.4,4.3-2.5,5.6 M536.6,365c-1.9,2.3-4.5,3.3-7.8,2.9L361,366.7
	c-6.6,0.1-10.6-1.5-12.1-4.8 M536.6,365c8.7,3.4,10.5,5.1,5.6,4.8l-270.2-1.6 M555.6,359.4h-7.2 M578.5,353.8l-0.7-0.3l-2.6,0.7
	 M578.5,353.8c0.6-2.8,1.4-5.2,2.5-7.7 M580.9,346.1l-0.7-0.3l-1.7-2.1 M577.8,359.3v1.6c0,4.1,0.5,7.8,1.8,11.4
	c0.5,1.5,1.1,3,1.8,4.4c1.1,2.2,2.5,4.3,4.1,6.3 M577.8,359.3l-0.7-0.3l-2.2-2.9l0.3,0.1 M574.9,356.1c-0.1,1.6-0.3,3.2-0.3,5
	c0,10.2,3.4,19.1,10.3,26.4 M575.2,354.3l-0.3,1.8 M575.2,354.3h0.4 M575.2,354.2l0.4,0.1 M574.9,356.1l0.3,0.1 M272.1,381.8h283.5
	v-22.4 M555.6,377.6l9.4-0.3 M548.5,359.4h-9.2 M745.6,300.5c-8.8-9.2-19.8-16.1-32.9-20.5c-25.6-8.7-61.1-15.7-106.5-20.9
	c-7.2,1.1-14.7,2.2-22.8,3 M544.2,277.4c1.4,24.5-0.4,51.9-5.1,82 M578.5,343.7c-1.7,3.3-2.8,6.7-3.4,10.5 M651.9,354.5
	c-0.5-3.7-1.7-7.2-3.3-10.5 M589.5,376.7l-8.1,0.1"
          />
          <path
            fill="none"
            d="M697,289.5c-4.7-3.9-11.7-6.9-21.3-9.1c-18.6-3.7-39.2-6.6-61.9-8.9c-15.1-1.6-26.3-4.5-33.7-8.9 M675.4,287.3
	c-1.4,0-2.3,0.3-2.9,0.5l-0.5,0.5c-0.1,0.3,0,0.8,0.4,1.4c2.9,2.9,5.9,5.8,9.1,8.5c3.7,3.2,7.7,6.2,12,9.1c2.1,1.4,5.4,2.3,9.9,3
	l27.4,4.5l2.5,0.4 M678.3,287.6l-2.9-0.3 M734.3,300.4l-7.6-1.2c-3.9-0.8-8-1.9-12.4-3.3c-4.5-1.1-8.8-2.5-12.9-4.3l-4.3-1.9"
          />
          <path
            fill="none"
            d="M730.3,306.6l-0.7,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.5,0.3,1.2,0.8,2.3l3,5.8 M730.3,306.6l0.4,0.1l13.3,2.1
	 M705.4,347.2l-2.6-21.9c-0.1-1.1-0.1-1.9,0-2.5 M702.8,322.8l3,1.8l0.4,0.5 M706.2,325.2l2.2,15.8c0.3,1.8,1,2.6,1.8,2.6"
          />
          <path
            fill="none"
            d="M706.2,325.2c0.4,0.5,0.8,1.5,1,2.6l3,15.8 M704.5,321.4c-1,0-1.5,0.4-1.7,1.4 M679.2,287.7l17.9,1.8
	 M721.9,289.4c-10.3-6.9-27.7-12.7-52.1-17.2c-25.6-4.3-54.3-7.6-86.4-10 M735.6,301.4l-1.2-1 M737.6,301.6l-2.1-0.3 M744.2,305.3
	l-4.5-3.4l-2.1-0.3 M743.7,302.5l0.5,2.9 M745.6,302.2h-1.9v0.3 M752.2,309.1h-3.4l-4.5-3.7 M752.2,309.1l-3.6-6.9h-3 M745.6,302.2
	v-1.6 M744.1,308.8l0.3,0.3l12.8,8.3 M740.6,302.5h3 M733.3,315.4l8.7,1.4c2.5,0.6,3.3,0.3,2.6-0.5c-3.4-3.2-7-5.6-10.5-7.7
	c-1.2-0.7-2.6-1.4-3.9-1.9 M734.3,300.4c4.3,0.5,8,0.5,11.3,0.3 M735.6,301.4l8.5,7.4 M753.3,322.4l0.7-2.1l0.1-0.7
	c0.4-0.7,1-1.1,1.9-1.1h4.5v-1.4 M760.5,317.3h-3.3"
          />
          <path
            fill="none"
            d="M757.2,317.3h-1.5c-1.2,0.1-2.1,1.9-2.5,5.1 M760.5,317.3l-8.3-8.3 M753.3,322.4l-0.5,2.1l-0.5,1.8l-0.4,1.5
	l-0.5,1.8l-1.4,4.8l-0.5,1.8l-0.4,1.5l-0.5,1.8l-1,3.6l-0.1,1.1l-0.3,1.8l-0.1,1.5v1.8l0.3,2.2l0.8,2.3l0.8,1.8l0.8,1.5l1.1,1.8
	l0.1,0.1l1.1,1.5 M139.6,361.3l-12.2-1.2 M171.7,344c-1.6,3.3-2.8,6.7-3.3,10.5 M89.3,348.4l-1.1-6.2 M137,371l1.9,0.3 M127.4,360.1
	c-9.2-0.1-21.5-1.5-36.7-4.1 M128.2,369.9l-21.9-2.8v4.1 M107.2,353.6c4.5,2.9,11.3,5.1,20.1,6.6 M128.3,369.9l8.7,1.1"
          />
          <path fill="none" d="M106.3,371.2h19.1c1.9,0,2.9-0.5,2.6-1.4" />
          <path
            fill="none"
            d="M113.6,371.2c0.6,1.1,1.5,2.1,2.6,3c2.5,2.2,5.5,3.3,9.1,3.3c3.6,0,6.6-1.1,9.1-3.3c1.1-1,2.1-2.1,2.6-3.3
	 M106.3,367.1l-4.4-0.5c-3.4-0.7-6.1-1.8-8-3.6 M108.4,350.6c-0.1,1.2-0.6,2.2-1.1,2.9 M108.4,350.6c-1.5,1.8-3.9,3.2-7.2,4.3h2.8
	c1.5,0,2.6-0.4,3.3-1.4 M108.5,326.9v22.7l-0.1,1 M82.9,349.1h-1.8 M80.7,347h1.8 M90.5,365.6l2.8,5.6h13.1 M93.1,362
	c-1.2-0.7-2.5-1.6-3.7-3.2 M93.1,362c-1.4-1.6-2.2-3.6-2.6-5.9 M90.7,356.1l-1.2-6.7"
          />
          <path
            fill="none"
            d="M89.6,358.9h-2.2l3.3,6.7 M89.4,363.7c-2.5-2.8-3.9-5.4-4.3-7.7c-0.3-3.4,0.7-3.3,2.9,0.7l1.5,2.2 M94,362.8
	l-0.8-0.8 M85.3,349.1h4.1 M86.8,348.4l0.3,0.7"
          />
          <path
            fill="none"
            d="M89.4,349.1l-0.1-0.8 M89.4,349.4v-0.3 M82.7,347h1 M82.9,349.1h1 M82.7,347l0.3,2.1 M83.6,347H85 M83.9,349.1
	l-0.3-2.1 M85,347h0.3c0.7,0,1.2,0.5,1.7,1.4h2.5 M85.3,349.1L85,347 M85.3,349.1h-1.4 M81,349.1c-0.8,0-1.2,0.5-1.2,1.4
	c0.4,1.6,0,3.2-1.1,4.7c-0.3,3,0.3,7,1.7,12.1c0.4,0.8,1.5,1.8,3.2,2.6c3-3,5.4-4.5,7-4.4 M80.7,347l0.3,2.1 M182.8,387.5
	c2.1,1.9,4.3,3.4,6.5,4.8 M175,365.2l-3.7-0.4 M174.7,361.1c0,1.5,0,2.9,0.1,4.3 M175,365.2l-0.3-4.3 M171.3,364.8
	c0.7,7,3.3,13.2,7.7,18.6 M178,347.4l-3.9-1.1 M171.7,354l3.3,1.2 M179.9,389.1c2.8,2.6,5.6,4.8,8.8,6.5 M190.5,396.4
	c4.9,2.3,10.5,3.4,16.4,3.4c6.1,0,11.6-1.2,16.6-3.6 M219.6,394.5c-4,1.5-8.3,2.2-12.8,2.2c-4.4,0-8.7-0.7-12.4-2.1 M194.4,394.6
	l-0.4,0.6l-3.4,1.1 M190.5,396.4l0.1-0.1 M188.7,395.4l0.1-2.6l0.4-0.5 M188.7,395.6l1.8,0.8 M190.1,369.3c0.1-1.2,0.3-2.9,0.4-5
	 M175,365.2l0.3,2.2 M184.9,366.3c0.3,1.1,0.6,2.2,1,3.3 M175.3,367.5l-0.3-2.2 M182.3,378.4l-3.3,5.1 M178.4,387.7l1.4,1.4
	 M182.8,387.5l-0.4,0.7l-2.5,1 M179,383.5l-0.4,0.5v3.6 M178.6,387.6l0.1-0.1l7.3-11.3c0.6-0.8,1.1-1.6,1.9-2.5l0.1-0.1
	c0.6-0.7,1.2-1.2,1.9-1.6l7-4.7 M190.5,376.5l-0.7,0.8l-6.9,10.2 M197.4,381.6c-2.3-1.1-4.5-2.6-6.6-4.7l-0.4-0.4 M182.3,378.4
	l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3 M184.3,366.3c-0.7,1.2-1.2,2.1-2.1,2.2c-1.6,0.6-2.3,1.5-2.3,3
	c0,0.8-0.6,1-1.6,0.4c-1.4-0.7-2.3-1.5-2.8-2.6l-0.3-1.8 M185.9,369.6l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8
	h-1.9l1.2,5.9l3-0.3 M223.1,395.9l-8.4-10.2c-0.7-0.8-1.2-1.8-1.8-2.6l-0.1-0.3c-0.4-0.8-0.7-1.5-1-2.3l-2.5-8 M190.7,396.3
	l8.7-10.6c0.7-0.8,1.2-1.6,1.8-2.6l0.1-0.3c0.4-0.7,0.7-1.5,1-2.2l2.3-8 M189.3,392.3l7.6-9.8l0.6-1 M210.9,383.2l0.3,0.6
	c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.8 M203.2,383.2l3.6,0.3l4-0.3 M203.2,383.2l-0.4,0.7c-0.7,1.1-1.4,2.1-2.1,2.9l-6.3,7.7
	 M210.9,383.2l-0.7-1.5l-1.8-5.9c-0.1-1.2-0.8-1.6-1.8-1.4c-0.6-0.1-1,0.4-1.1,1.7l-1.8,5.9c-0.1,0.5-0.3,1-0.6,1.4 M209.5,372.3
	l0.1-0.1c0.6-0.8,0.8-1.6,0.6-2.5c-0.1-1-0.6-1.7-1.4-2.2c-0.8-0.6-1.6-0.7-2.5-0.6s-1.6,0.6-2.2,1.4c-0.6,0.7-0.8,1.7-0.7,2.5
	c0.1,0.7,0.4,1.2,0.8,1.6l0.6,0.5c0.7,0.6,1.5,0.8,2.5,0.7C208.3,373.4,209,373,209.5,372.3L209.5,372.3z M206.6,368.6
	c-0.4,0.1-0.8,0.3-1.1,0.7c-0.3,0.4-0.4,0.8-0.3,1.2c0,0.3,0.1,0.7,0.4,0.8l0.3,0.3c0.4,0.3,0.8,0.4,1.2,0.3
	c0.6-0.1,0.8-0.3,1.1-0.7c0.3-0.4,0.4-0.8,0.3-1.2c0-0.4-0.3-0.8-0.7-1.1C207.6,368.6,207.2,368.5,206.6,368.6L206.6,368.6z
	 M197.4,381.6c0.3-0.5,0.4-1.1,0.6-1.8l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.1l-0.3-0.3l-1.9-0.1l-3.3,1.5
	c-0.6,0.3-1.1,0.7-1.5,1.1 M179.9,389.1l7.7-11.7c0.4-0.8,1-1.5,1.6-2.2c0.7-0.7,1.7-1.2,2.6-1.8l6.9-4.1h1.6l0.3,0.1l0.3,0.3
	c0.4,0.4,0.6,1,0.6,1.5l-1.8,7.8c-0.3,1.2-0.6,2.3-1,3c-0.4,0.8-1,1.6-1.5,2.3l-8.8,11 M196.9,367.1h0.1c1,0.3,1.8,0.3,2.6-0.3
	c0.8-0.4,1.4-1.1,1.6-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6c-1-0.3-1.8-0.3-2.6,0.1c-0.7,0.3-1.1,0.7-1.4,1.2
	l-0.4,0.7c-0.3,1-0.3,1.8,0.3,2.6C195.5,366.2,196.2,366.7,196.9,367.1L196.9,367.1z M199.6,363.1c-0.1-0.4-0.6-0.7-1-0.8l-1.2,0.1
	c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.4c-0.1,0.4-0.1,1,0.1,1.2c0.1,0.4,0.6,0.7,1,0.8h1.2c0.4-0.3,0.7-0.5,0.8-1
	C199.9,364.1,199.9,363.5,199.6,363.1L199.6,363.1z M203.6,357.2l-0.3,0.3c-1,1-1.5,2.2-1.5,3.6v0.3c0.1,1.2,0.6,2.3,1.5,3.3
	c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.6,2.9-1.4l0.3-0.4c0.4-0.5,0.8-1.2,1-1.9c0.1-0.4,0.1-0.8,0.1-1.2
	c0-1.4-0.6-2.5-1.4-3.6c-1-1-2.2-1.5-3.6-1.5C205.7,356,204.6,356.4,203.6,357.2L203.6,357.2z M210.6,354c0.1,0.4,0.4,0.8,0.8,1
	l1.4,0.1c0.4-0.1,0.8-0.4,1-0.8c0.1-0.3,0.3-0.7,0.1-1v-0.4c-0.1-0.4-0.4-0.8-0.8-1l-1.2-0.1c-0.4,0.1-0.8,0.4-1,0.8
	C210.5,353.2,210.5,353.6,210.6,354L210.6,354z M211.3,350.3h-0.1c-1,0.3-1.5,0.8-1.9,1.6c-0.4,0.8-0.6,1.6-0.3,2.6
	c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.6,0.4,2.6,0.1c1-0.3,1.5-0.8,1.9-1.7c0.3-0.5,0.4-1.1,0.3-1.8l-0.1-0.8c-0.3-0.8-0.8-1.5-1.6-1.9
	C212.9,350.2,212.1,350.1,211.3,350.3L211.3,350.3z M198.1,353.5v0.3c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1,2.3,1c1,0,1.8-0.4,2.3-1
	c0.7-0.7,1-1.4,1-2.3c0-1-0.3-1.8-1-2.5c-0.4-0.4-1-0.7-1.6-0.8l-0.8-0.1c-1,0-1.8,0.3-2.3,1C198.4,351.8,198.1,352.5,198.1,353.5
	L198.1,353.5z M200.3,352.4c-0.3,0.3-0.6,0.7-0.6,1.2c0,0.4,0.1,0.8,0.6,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5
	c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-1-0.6-1.2c-0.3-0.3-0.6-0.4-0.8-0.4h-0.4C201,352,200.6,352.1,200.3,352.4L200.3,352.4z
	 M190.2,353.1c0.1,1.1,0.3,2.6,0.3,4.4 M187.5,355.3v0.8 M757.1,317.3l-19.5-15.7 M184.2,364.4l-0.4-0.1c-1.2-0.3-2.5-0.7-3.4-1.1
	l-5.6-2.2 M175.1,355.3l8.3,3l0.3,0.1 M190.5,364.4l-3,0.1 M187.5,365.2v-0.7 M187.5,364.5l-3-0.1 M184.5,364.4v0.3 M184.2,364.4
	l0.4,0.4 M184.6,364.8c0.7,0.4,1.6,0.6,2.9,0.4 M187.5,366h-2.8 M184.9,366h-0.3l-0.1,0.1 M184.9,366.3l-0.6-0.1 M184.9,366v0.3
	 M187.5,365.2v0.8 M185.8,369.6c1-0.4,1.4-1.5,1.6-3.3V366 M184.5,364.4h-0.4 M190.1,369.3l3.2-2.2c1.1-0.5,1.4-1.2,0.8-2.2
	c0-0.5-0.7-0.8-1.9-0.5h-1.8 M187.5,358.2l2.3-0.4l0.7-0.1 M187.5,356.2c-1.2-0.1-2.2,0-2.8,0.4c-0.6,0.3-1,1-1.1,1.8 M183.6,358.3
	l0.8,0.1h1.8l1.2-0.3 M187.5,358.2v-1.9 M187.5,355.3H185 M186.1,352c0.7,0.4,1.2,1.5,1.4,3.2v0.3 M186.1,352
	c-0.6,1.1-1,2.2-1.2,3.4 M190.5,357.5c0.4-0.3,0.7-0.5,0.8-1l0.3-0.7c0.3-0.7,0-1.4-0.6-1.9l-0.8-0.8 M190.7,345.4l0.3-0.3
	c1.8-1.8,3.7-3.2,5.9-4.3 M198.1,353.5l-6.7-5.1c-0.7-0.5-1.4-1-2.2-1.4l-0.3-0.1c-0.8-0.4-1.8-0.7-2.9-1.1l-13.2-3.4 M190.2,353.1
	l-1.6-1.8c-0.4-0.5-1-0.8-1.5-1.1c-0.3-0.3-0.6-0.4-1-0.4l-8.1-2.3 M175.1,355.3l0.1-1.4 M185,355.3h-0.6c-0.7-1.4-1.4-2.2-2.2-2.3
	c-1.7-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6l-0.3,1.8 M175.3,353.9l-0.1,1.4 M178,347.4v1.2h1.9
	c2.1,0.1,3.2,0.7,3.3,1.8c0.3,1,1,1.4,1.9,1.2c0.4,0,0.7,0.1,1.1,0.3 M139.6,361.3l-0.7,9.9 M155.9,363.1c0.3,9.9,3.3,18.8,8.8,26.7
	c1.8,2.5,3.9,5,6.2,7.3c8.5,8.5,18.4,13.3,30,14.7l7.4,0.3l5.2-0.4c11.3-1.4,21.2-6.2,29.6-14.6l3.2-3.4l1.9-2.5
	c4-5.2,6.7-10.9,8.3-17.1 M146.1,372.1l0.7-10 M138.9,371.2l7.2,0.8"
          />
          <path
            fill="none"
            d="M168.4,354.5l-0.3,1.9 M195.1,362.3l-8.3,0.3l-2.8-0.3c-1-0.3-2.1-0.5-3-1l-6.2-2.3l-6.1-2.3 M174.7,361.1
	l-3.6-1.4 M171.1,359.5v1.4c0,1.4,0,2.6,0.1,3.9 M168.7,356.7l-0.1-0.1 M168.4,356.5l-0.1-0.1l2.2,2.9l0.8,0.3 M168.1,356.4
	c-0.1,1.5-0.3,3-0.3,4.7c0,10.3,3.6,19.3,10.6,26.7 M168.1,356.4l0.3,0.1 M168.7,356.7l-0.3-0.1 M168.5,356.5l-0.4-0.1 M174.2,346.3
	c-1.1,2.5-1.9,5-2.5,7.7 M168.4,354.5l2.6-0.7l0.7,0.3 M171.8,344l13.5,3.9c1,0.1,1.8,0.5,2.5,1h0.1c1,0.4,1.8,1.1,2.5,1.9l6.1,5.4
	c0.4,0.5,0.6,1.1,0.4,1.6v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-8,0.8c-1.2,0.1-2.2,0.3-3.2,0c-1-0.1-1.9-0.4-2.8-0.7l-6.6-2.5
	l-6.5-2.5 M146.6,362.2l9.1,1 M150.1,262.7l47.3,1.2l146.5,5.5 M174.2,346.3l-0.7-0.3l-1.8-2.1 M172.9,342.4h-0.3 M172.6,342.2
	l-1,1.8 M272.1,355.6c0-1.5-0.1-3-0.1-4.5c-0.1-1.8-0.3-3.6-0.5-5.2c-1-5-2.6-9.8-4.8-14.3c-3.2-6.5-7.7-12.4-13.3-17.7
	c-1.9-1.8-3.9-3.4-5.9-5c-11.7-8.8-25.3-13.2-41-13.2c-15,0-28.1,4.1-39.5,12.2l-1.5,1.1c-2.1,1.5-4,3.2-5.9,5l-0.1,0.1
	c-1.4,1.4-2.8,2.6-4,4c-6.6,7.3-11,15.4-13.3,24.2c-0.8,3-1.4,6.2-1.6,9.5l-0.7,9.8 M265.2,355.6l-0.4-4.5
	c-2.1-11.8-7.6-22.3-16.4-31.2c-4.5-4.5-9.4-8.3-14.6-11.1c-8.3-4.4-17.5-6.7-27.6-6.7c-10,0-19.3,2.2-27.5,6.7
	c-2.1,1.1-4.1,2.5-6.2,3.9c-1.4,1-2.8,2.1-4,3.2c-1.5,1.2-2.9,2.6-4.3,4l-3.6,4c-8,9.5-12.4,20.5-13.6,32.9l-0.4,5.5 M256.3,374.1
	c1.1-4.1,1.6-8.5,1.6-13.2c0-11.4-3.3-21.5-9.9-30.3c-1.5-2.1-3.2-4-5.1-5.8c-1.5-1.5-3.2-3-4.8-4.3c-8.9-7.2-19.4-10.6-31.2-10.6
	c-12,0-22.3,3.6-31.4,10.6l-4.8,4.3c-2.3,2.3-4.4,4.8-6.2,7.3c-5.9,8.4-8.8,18-8.8,28.8v2.1 M199.3,322.8c-7.6,1.4-14.2,5-19.9,10.7
	c-2.8,2.8-5,5.6-6.7,8.8 M176.9,341.4l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3 M176.9,341.4l-0.7-0.1l-3.4,1.1 M197.1,326.5
	c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6 M212.1,322.4l-5.4-0.4l-5.6,0.4 M190.7,345.4c0.4,0.3,1,0.5,1.4,0.8l4.9,3.7
	c0.8,0.8,1.5,1.1,1.9,0.5c0.8-0.5,1-1.4,0.4-2.5l-2.1-5.8l-0.4-1.5 M199.3,322.8l-0.8,13.6c-0.1,1.1,0,2.1,0.1,3.2l0.7,2.8l2.8,7.8
	 M202.6,325.6l-0.4,12.2l0.1,1.2 M212.3,322.4l0.6,14c0.1,0.8,0.1,1.8-0.1,2.8c-0.1,1-0.6,1.9-1.1,3l-3.2,7.4
	c-0.4,0.5-0.8,0.8-1.4,1h-0.6c-0.6-0.1-1-0.4-1.4-1l-3.2-7.4c-0.6-1.1-1-2.1-1.1-3c-0.1-1-0.3-1.9-0.1-2.8l0.6-13.5 M196.7,340.8
	l-0.1-0.7c-0.1-1.2-0.1-2.5,0-3.6l0.6-10 M211,338.9l-4.3-0.4l-4.4,0.4 M211,338.9l0.1-1.1l-0.4-12.2 M202.4,338.9
	c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.7-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6 M210.7,325.6
	v-0.8l1.5-2.2 M210.8,325.6l-3.9-0.3l-4.1,0.3 M201.3,322.5l-1.9,0.3 M751.8,360.2l2.8,3.3c1.5,1.1,3.7,1.5,6.6,1.2
	c1.9,0.5,3.6,2.6,5,6.2c0.4,1,0.4,1.8,0,2.3 M202.6,325.6v-0.8l-1.5-2.2 M201.3,322.5v0.5 M201.3,323.1v-0.6 M197.1,326.5l0.1-0.8
	l2.1-2.9 M164,269.8c1.4-1.4,3.2-2.1,5.2-2.1c6.1-0.8,12.2-0.8,18.7,0c2.1,0,3.7,0.7,5.1,2.1c1.4,1.5,2.1,3.2,2.1,5.1v6.9
	c0,2.1-0.7,3.7-2.1,5.2c-1.5,1.4-3.2,2.1-5.1,2.1c-6.1,0.8-12.4,0.8-18.7,0c-2.1,0-3.7-0.7-5.2-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9
	C161.9,273,162.6,271.4,164,269.8z M150.1,262.7h-0.4 M149.7,262.7h0.3 M82.1,342.1h-1.8 M80.1,340h1.8 M85.6,327.8h-5.5
	 M82.7,334.9h-1 M85.6,327.8c-1-1.6-2.8-2.5-5.4-2.3 M81.6,334.9l-0.1-2.1 M79.8,332.7l0.1,2.1 M80.1,340l0.1,2.1 M80.3,342.1
	c-0.8,0-1.2,0.5-1.4,1.4l0.4,3.6h1.4 M81.6,334.9h-1.8 M79.8,334.9c-1,0-1.5,0.4-1.5,1.4l0.3,3.7h1.5 M662.8,374.8l11.6-0.3
	 M705.8,350.9l4.3,0.1c3.2,0.1,6.2,0.3,9.1,0.8 M719.9,355.1c-2.9-0.4-5.9-0.7-9.1-0.8l-4.5,0.1 M719.6,353.4
	c-2.8-0.4-5.8-0.7-8.8-0.7h-4.1 M705.8,350.9l0.4,3.7 M719.9,356.9c-2.9-0.4-5.9-0.7-9.1-0.8l-4.3,0.1 M719.5,360.6l-8.3-0.7h-3.3
	 M719.7,359.4l-8.9-0.7h-3.3 M706.5,356.2v0.3c0.5,3.6,2.8,5.8,6.5,6.5c4.3,0.7,7,0,8.4-2.2 M706.3,354.5l0.1,1.8 M705.4,347.2
	l0.4,3.6 M718.4,349.5c-0.3-0.5-0.7-1.1-1.1-1.6 M721,349.9l-0.8-1.6 M722.2,355.6c0-0.7,0-1.2-0.1-1.8 M722.2,353.8
	c0-0.5-0.1-1.1-0.3-1.7 M721.9,352.1c-0.1-0.4-0.3-1-0.4-1.4l-0.4-0.8 M722.2,355.6l-2.3-0.4 M719.6,353.4l2.5,0.4 M721,349.9
	l-2.8-0.5 M719.2,351.7c0.1,0.5,0.3,1,0.4,1.5 M710.2,343.6l6.1,1.7c1.2,0,2.5,1.1,3.9,3.2 M720.2,348.4l-2.9-0.5 M721.9,352.1
	l-2.6-0.4 M721.8,359.7c0.1-0.8,0.3-1.6,0.4-2.3 M721.4,360.9l0.4-0.7v-0.4 M719.9,356.9l2.2,0.4 M721.4,360.9l-1.8-0.3
	 M719.5,360.6l0.1-1.2 M719.7,359.4l2.1,0.3 M722.1,357.3c0.1-0.7,0.1-1.2,0.1-1.8 M719.9,355.1v1.8 M717.3,347.9
	c-3.7-0.5-7.6-0.8-11.8-0.7 M718.4,349.5c-3.7-0.5-7.8-0.8-12.1-0.7 M80.1,327.8l-1.8,2.3v2.8h1.5 M77.3,324.6c0,1.9,1,3,2.8,3.2
	 M79.8,332.7h1.8 M82.5,332.9l0.1,2.1 M82.5,332.9c0.7,0.1,1.2,0.5,1.5,1.4 M87.1,334.9h-3 M83.9,334.1h2.9 M88.2,341.4l-1.1-6.5
	 M83,340l0.1,2.1 M82.1,342.1L82,340 M85.2,341.4l0.3,0.7 M84.3,340.1l0.1,1.9 M84.5,342.1h-1.4 M83.1,342.1h-1 M88.3,342.1h-3.7
	 M84.3,340.1c0.4,0.3,0.7,0.5,1,1.1h2.9 M82.9,340h0.7c0.3,0,0.6,0.1,0.7,0.3 M81.9,340h1 M88.3,342.1l-0.1-0.8 M87.1,334.9
	l-0.1-0.8 M83.9,334.1v0.8 M84.1,334.9h-1.4 M81.6,332.7h1 M86.9,334.1l-0.8-4.8c-0.1-0.5-0.4-1.1-0.7-1.5 M92.2,301.4l-4.9,1.2
	l-1.8,6.2 M85.4,308.9l-2.5,0.3l-3.3,0.4c-0.8,0.1-1.4,1-1.5,2.5l-1,12.5v0.1 M108.5,326.9c0-5.5-1-7.8-2.9-7.1
	c-1.5-0.3-2.5,1.4-2.8,4.8C104,320.6,105.8,321.4,108.5,326.9L108.5,326.9z"
          />
          <path
            fill="none"
            d="M172.6,312.6l-5.8-5l-1-0.7c-5.5-4.3-10.9-7.4-16.4-9.4c-8-1-16.2-1.4-24.5-1l-8.1,0.5 M149.7,262.7
	c-7.4,0.5-12.4,3.9-14.7,9.9l-10,24.1 M92.2,301.4l1.5-0.4l2.9-0.7c3.4-0.8,6.7-1.4,10.2-1.9l3.2-0.5l7-0.8"
          />
          <path
            fill="none"
            d="M109.8,254.3l18.4,4.5l0.1,0.1c7.3,2.1,14.4,3.3,21.5,3.9 M106.3,265c1-5.5,2.1-9.1,3.4-10.7"
          />
          <path
            fill="none"
            d="M92.2,301.4c1.1-7.3,2.6-11.7,4.4-13.3c3.2-2.1,4.9-4.3,5.6-6.7l4.1-16.4l1.7,0.1l14.7,1
	c4.1,0.3,5.9,1.6,5.6,4.3c0,0.4-0.1,1-0.3,1.4l-5,11.7l-1.8,4.3l-0.3,0.7l-3.9,9.1 M92,272.1l-4.8,24.8c-1,1.6-2.2,2.9-3.6,3.9
	l-0.6,8.4 M92,272.1l2.6-14.3c0.7-4.5,0-6.6-2.2-6.1 M92.4,251.7c-0.3,0.6-0.3,1.4-0.4,2.2l-3.4,15c-0.1,1.6,1,2.8,3.4,3.2
	 M240.9,341.9l-0.1,0.1 M241.8,343.7l-1-1.8 M241.8,343.7h-0.1l-0.6,0.1 M241.1,343.9l0.7-0.1 M244.7,354.3l-12.7,4.7
	c-0.8,0.4-1.6,0.7-2.8,0.8c-1,0.1-2.1,0.1-3.2,0l-8-0.8c-0.6-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.5l6.1-5.4
	c0.8-0.8,1.6-1.5,2.5-1.9l0.1-0.1c0.8-0.4,1.6-0.8,2.6-1l12.8-3.6h0.1 M240.7,342.1l-3.3-1.1l-0.8,0.1 M236.6,341.1
	c-1.4-1.9-2.8-3.7-4.5-5.4c-4.7-4.7-9.9-7.7-15.8-9.2"
          />
          <path
            fill="none"
            d="M240.9,341.9c-1.6-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7 M216.2,326.5v-0.7l-2.1-3v0.4 M214.2,322.8
	l-1.9-0.4 M212.3,322.4h-0.1 M212.1,322.4h0.1 M214.2,322.8v0.4 M214.2,323.2l0.8,13.2c0,1.1,0,2.2-0.1,3.2v0.3
	c-0.1,0.8-0.3,1.6-0.6,2.3l-2.9,8 M216.7,340.7l0.1-0.7c0.1-1.2,0.1-2.5,0-3.6l-0.6-10 M222.9,345.2c-0.4,0.3-0.8,0.5-1.2,0.7
	l-5,3.7c-0.8,0.8-1.6,1-2.5,0.4c-0.6-0.3-0.6-1,0-1.9l2.1-5.8c0.1-0.5,0.3-1.1,0.4-1.5"
          />
          <path
            fill="none"
            d="M236.6,341.1l-9.6,2.5c-1.2,0.3-2.3,0.7-3.4,1.2l-0.8,0.4 M289.3,254.6c3.7,1.5,8.3,2.5,13.5,2.9l42.1,1.6
	 M343.9,269.4v0.3c-2.6,13.5-3.7,30-3,49.5c1,13.8,2.9,25.9,5.9,36.6 M344.9,259l2.8,0.1 M347.6,259.1l-3.7,10.3 M347.6,259.1
	l-0.1-1.6 M347.5,257.5l13.5,0.4l151.2,5.1 M348.7,361.9l-1.9-6.2 M272.1,368.2c5.9-4.4,14.2-6.9,24.6-7.6l52,1.2 M222.9,345.2
	c-1.9-1.9-4-3.4-6.2-4.5 M240.7,342.1l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5 M239.4,346.1
	l-11.8,3.4c-0.4,0.1-0.7,0.3-1.1,0.5"
          />
          <path
            fill="none"
            d="M226.4,349.9c-0.6,0.3-1,0.7-1.4,1.1l-2.5,2.6c-0.6,0.5-0.8,1.2-0.6,1.9l0.3,0.7c0.3,0.7,0.7,1.1,1.5,1.2
	l3.6,0.7l1.8,0.1 M229.2,358.2l1.1-0.3l11.6-4.1 M245.4,356.1l-12.7,5c-1.1,0.4-2.1,0.7-3,0.8l-0.4,0.1l-2.3,0.1l-8.4-0.3
	 M229.2,364.1l0.7-0.1c1.1-0.3,2.2-0.5,3.4-1.1l9.2-3.6 M226.3,370.8c-0.1-0.1-0.4-0.3-0.6-0.4l-5.1-3.6c-1.1-0.5-1.5-1.1-1.1-1.5
	c0-1.1,0.7-1.5,1.9-1.2h7.7 M218.4,362l-0.1-0.1c-0.6-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2
	c-0.1,1,0.1,1.8,0.7,2.5s1.2,1.2,2.2,1.4c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4c0.8-0.5,1.2-1.2,1.4-2.2C219.1,363.5,219,362.7,218.4,362
	L218.4,362z"
          />
          <path
            fill="none"
            d="M217.4,364.1c0-0.4,0-0.8-0.3-1.2c-0.3-0.4-0.7-0.5-1.1-0.7c-0.4-0.1-0.8,0-1.2,0.3s-0.6,0.7-0.7,1.1
	c-0.1,0.4,0,0.8,0.3,1.2c0.3,0.4,0.7,0.7,1.1,0.7l1-0.1l0.4-0.1C217.1,364.9,217.2,364.5,217.4,364.1z M229.2,358.2
	c-0.4-3-1.2-5.6-2.8-8.3 M244.7,354.3h0.4 M245.1,354.2l-0.4,0.1 M245.1,354.3v-0.1 M245.1,354.2h0.1H245.1 M241.8,353.8l0.8-0.3
	l2.5,0.7 M235.4,387.5c6.9-7.4,10.3-16.2,10.3-26.4c0-1.6-0.1-3.3-0.3-5"
          />
          <path
            fill="none"
            d="M242.5,359.3v1.6c0,4.1-0.6,7.8-1.8,11.4 M242.5,359.3l0.7-0.3l2.2-2.9 M245.4,356.1l-0.3-1.8 M241.8,353.8
	c-0.6-2.8-1.4-5.2-2.5-7.7 M239.4,346.1l0.8-0.3l1.6-2.1 M234,388.8h0.1 M234.1,388.8v0.1V388.8H234 M234,388.8h0.1 M234,388.8
	l-2.5-0.8l-0.4-0.7 M235.4,387.5v-3.7l-0.4-0.5 M234,388.8l1.4-1.4 M235,383.2c2.6-3.3,4.5-6.9,5.9-10.7 M223.4,376.2
	c-0.4-0.4-0.8-0.7-1.4-1l-3.3-1.5h-1.9l-0.3,0.3l-0.3,0.3c-0.6,0.4-0.8,1-0.7,1.8l0.4,3.6c0.1,0.7,0.3,1.2,0.6,1.8"
          />
          <path
            fill="none"
            d="M225.2,395.5l-8.8-11c-0.6-0.7-1.1-1.4-1.5-2.2v-0.1c-0.4-1-0.7-1.9-1-3l-1.8-7.8c0-0.7,0.1-1.2,0.6-1.5
	l0.3-0.3l0.3-0.1l1.6-0.1l6.9,4.1c1,0.4,1.8,1.1,2.6,1.8v0.1c0.7,0.7,1.4,1.4,1.8,2.2l7.7,11.6 M226.8,371.4l-0.6-0.5 M227.1,371
	l-0.3,0.4 M227.1,371l-0.8-0.1 M226.8,371.4l0.6,0.5c0.7,0.8,1.4,1.8,2.2,2.9l5.4,8.4"
          />
          <path
            fill="none"
            d="M235.4,387.5l-7.4-11.4c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7 M216.5,381.4
	c2.2-1.1,4.4-2.6,6.2-4.4l0.7-0.7 M231.1,387.3l-6.9-10.2l-0.8-1 M216.5,381.4l0.6,1l7.6,9.8 M227.1,371c1.1-2.2,1.8-4.4,2.1-6.9
	 M240.7,372.5l-13.6-1.5 M265.5,375.2v-10c0-3-0.1-5.9-0.3-8.9v-0.5 M272.1,355.6c0,1.5,0.1,3,0.1,4.7v8 M272.1,368.2v13.6
	 M256.3,374.1l9.2,1.1 M265.5,375.2v7l6.6-0.4 M265.2,355.6h6.9 M223.4,396.1L220,395l-0.4-0.5 M225.2,395.5h0.1 M223.5,396.3
	l1.8-0.8 M223.5,396.3l-0.1-0.1 M223.4,396.1l-0.3-0.3 M224.6,392.1l0.4,0.5l0.1,2.8 M223.1,395.9l0.3,0.3 M225.3,395.5
	c3.2-1.7,6.1-3.9,8.8-6.6 M224.6,392.1c2.3-1.4,4.4-3,6.5-4.8 M346.8,355.7l-74.7-0.1 M245.1,354.2c-0.7-3.7-1.8-7.2-3.3-10.5
	 M511.7,243.3c-1.2,1.1-1.9,3.2-2.1,6.3 M284.9,252.2c1.4,0.8,2.9,1.6,4.5,2.2 M343.9,269.4l200.3,8 M540.9,265.7l-193.3-6.6
	 M284.9,252.2l4.4,2.3 M343.8,209.6l1.1,49.4 M346.4,209.6l1.1,47.9 M93.4,250.3c-0.4,0.3-0.7,0.8-1,1.5 M289.2,200.2h-0.3
	c-8.1,1.6-16,3.4-23.8,5.2c-27.5,6.7-53,15.4-76.3,25.9l-26.6,11.1 M150.5,249.2l-7.7,1.1l-31.1,2.9c-0.7,0-1.4,0.4-1.9,1.1
	 M146.4,249.1l4.1,0.1 M146.4,249.1c-2.1-0.3-4.3-1-6.6-1.9l-44.2,2.5c-1,0-1.7,0.3-2.2,0.7 M160.4,243.3l-9.9,4.1l-4.1,1.6
	 M163.8,246.1l-1.9,0.6 M161.9,246.6l-2.5,0.7c-2.8,0.7-5.8,1.4-9.1,1.9 M163.8,246.1l-1.8-3.4 M164.8,245.6l-1,0.4 M161.9,246.6
	l-1.5-3.3 M162.1,242.6l-2.6-5h-0.6l-0.4,0.3l-0.4,0.6l2.2,4.8"
          />
          <path
            fill="none"
            d="M138.4,196.4v0.3l2.1,4.3l0.1,0.1l17.9,36.7l0.1,0.1l0.4-0.3l-17.7-36.6v-0.3l-2.1-4.3l-0.1-0.1L138.4,196.4
	L138.4,196.4z M158.6,237.9l-0.4,0.6l2.2,4.8l1.5,3.4l1.9-0.6l-1.8-3.4l-2.6-5h-0.6L158.6,237.9L158.6,237.9z"
          />
          <path
            fill="none"
            d="M160.4,243.3l-9.8,4.1c2.5,0.6,5.5,0.6,8.9,0l2.5-0.7L160.4,243.3L160.4,243.3z M164.8,245.6
	c42.4-17.9,76.3-30.3,102.1-37.1l-1.9-2.9c-27.5,6.7-53,15.4-76.3,25.9L162,242.6l1.8,3.4L164.8,245.6L164.8,245.6z"
          />
          <path
            fill="none"
            d="M163.8,246.1l-1.9,0.6l-2.5,0.7c-2.8,0.7-5.8,1.4-9.1,1.9l-7.7,1.1l-31.1,2.9c-0.7,0-1.4,0.4-1.9,1.1l18.4,4.5
	h0.1c7.3,2.1,14.4,3.3,21.5,3.9h0.4l47.3,1.2l146.5,5.5l3.6-10.3l-2.6-0.1l-42.1-1.6c-5.2-0.4-9.8-1.4-13.5-2.9
	c-1.7-0.7-3.2-1.4-4.5-2.2c-8.1-4.4-17.6-11.4-28.6-21c-2.5-2.8-0.3-5.6,6.6-8.7l19.7-7c5.4-1.6,10.7-2.9,16.2-3.9
	c4.7-0.8,9.4-1.2,14-1.5c10.3-0.3,20.6-0.4,31.1-0.6h13.5c21.5-0.1,42.9,0.1,64.4,0.6l31.2,2.1c7.8,0.8,13.8,0.7,17.7-0.6l1-0.3
	c-18.3-5.8-54.9-9.2-110.1-10.2c-25.3-1.1-49.4-0.4-71.9,2.1c-6.6,1.1-14.3,2.9-22.7,5.2c-25.7,7-59.7,19.4-102.1,37.1L163.8,246.1
	L163.8,246.1z"
          />
          <path
            fill="none"
            d="M146.4,249.1l4.1,0.1c3.3-0.6,6.3-1.1,9.1-1.9c-3.4,0.6-6.5,0.6-8.9,0L146.4,249.1z"
          />
          <path
            fill="none"
            d="M139.8,247.2l-44.2,2.5c-1,0-1.7,0.3-2.2,0.7l16.4,4c0.6-0.8,1.2-1.1,1.9-1.1l31.1-2.9l7.7-1.1l-4.1-0.1
	C144.3,248.8,142.1,248.1,139.8,247.2L139.8,247.2z"
          />
          <path
            fill="none"
            d="M92.4,251.7c2.2-0.6,2.9,1.5,2.2,6.1L92,272.1l-4.8,24.8c-1,1.6-2.2,2.9-3.6,3.9l-0.6,8.4l2.5-0.3l1.8-6.2
	l4.9-1.2c1.1-7.3,2.6-11.7,4.4-13.3c3.2-2.1,5-4.3,5.6-6.7l4.1-16.4c1-5.5,2.1-9.1,3.4-10.7l-16.4-4C93,250.6,92.7,251.1,92.4,251.7
	L92.4,251.7z"
          />
          <path
            fill="none"
            d="M88.6,268.9c-0.1,1.6,1,2.8,3.4,3.2l2.6-14.3c0.7-4.5,0-6.6-2.2-6.1c-0.3,0.6-0.3,1.4-0.4,2.2L88.6,268.9
	L88.6,268.9z M96.4,291.6c1.2-1.6,3.7-2.9,7.4-3.7c2.3-0.5,4.4-1.2,6.2-1.9c-1.2-0.3-2.2-0.8-2.8-1.6c-0.1-0.3-0.3-1-0.4-2.2
	c-0.1-1.5-0.1-3.2,0.1-5.2c0.1-2.1,0.4-3.9,0.7-5.2c0.3-1.4,0.6-2.1,0.8-2.1c0.8-1.2,1.8-2.1,3-2.3l-4-0.7c-1,6.3-2.3,11.7-4.1,16.1
	c-1.1,1.8-2.9,3.9-5.4,5.9C97.7,289.1,97,290.2,96.4,291.6L96.4,291.6z"
          />
          <path
            fill="none"
            d="M98.2,288.4c2.5-2.1,4.3-4.1,5.4-5.9c1.9-4.4,3.3-9.9,4.1-16.1l0.1-1.2l-1.7-0.1l-4.1,16.4
	c-0.6,2.5-2.5,4.8-5.6,6.7c-1.9,1.6-3.3,6.1-4.4,13.3l1.5-0.4c1-4,1.8-7.2,2.8-9.4C97,290.2,97.7,289.1,98.2,288.4L98.2,288.4z"
          />
          <path
            fill="none"
            d="M112.3,267.1l1.5,0.3c2.9,0.5,4.3,1.5,4.3,3l10.2-0.1c0.3-2.6-1.5-4-5.6-4.3l-14.7-1l-0.1,1.2l4,0.7
	L112.3,267.1L112.3,267.1z"
          />
          <path
            fill="none"
            d="M111.7,267.1c-1.2,0.4-2.2,1.1-3,2.3c0.3,0,0.4,0.8,0.6,2.2c0,1.5,0,3.3-0.1,5.4s-0.4,3.9-0.7,5.2
	c-0.3,1.4-0.6,2.2-1,2.1l-0.1-0.1c0.6,0.8,1.5,1.4,2.8,1.6h0.1c1-1.2,1.5-2.9,1.9-5.1l0.3-2.9c0.1-2.1,0.3-3.7,0.3-5.4
	c0-2.3-0.1-4.1-0.6-5.5L111.7,267.1L111.7,267.1z M118,271.2c0-0.3,0.1-0.5,0.1-0.8c0-1.5-1.4-2.6-4.3-3l-1.5-0.3
	c0.4,1.4,0.6,3.2,0.6,5.5h4.8L118,271.2L118,271.2z"
          />
          <path
            fill="none"
            d="M117.6,272.6l-2.3,8.1l-0.7,2.5h8.3l5-11.7c0.1-0.4,0.3-1,0.3-1.4l-10.2,0.1c0,0.3,0,0.5-0.1,0.8L117.6,272.6
	L117.6,272.6z"
          />
          <path
            fill="none"
            d="M112.8,272.6c0,1.5-0.1,3.3-0.3,5.4l-0.3,2.9h3.2l2.3-8.1h-5L112.8,272.6L112.8,272.6z"
          />
          <path
            fill="none"
            d="M108,265.2l14.7,1c4.1,0.3,5.9,1.6,5.6,4.3c0,0.4-0.1,1-0.3,1.4l-5,11.7l-1.8,4.3l-0.3,0.7l-3.9,9.1l8.1-0.5
	l10-24.1c2.5-6.1,7.3-9.4,14.7-9.9c-7-0.4-14.2-1.8-21.5-3.9l-0.1-0.1l-18.7-4.7c-1.4,1.6-2.5,5.2-3.4,10.7L108,265.2L108,265.2z"
          />
          <path
            fill="none"
            d="M107.8,271.5c-0.4,1.5-0.6,3.2-0.7,5.2s-0.1,3.9-0.1,5.2c0,1.2,0.1,1.9,0.4,2.2l0.1,0.1c0.3,0,0.6-0.7,1-2.1
	c0.3-1.4,0.6-3.2,0.7-5.2c0.1-2.1,0.3-3.9,0.1-5.4c-0.1-1.5-0.4-2.2-0.7-2.2C108.4,269.4,108.1,270.1,107.8,271.5L107.8,271.5z
	 M121.1,287.6h-9.2c-3.9,0.7-8.4,2.2-13.6,4.7c-0.8,1.5-1.4,4.3-1.8,8.1c3.4-0.8,6.7-1.4,10.2-1.9l1.1-8.3h0.1
	c3.9,0,7.8-0.7,12.2-2.1h-7.3c-3.4,0.5-8.1,2.1-13.8,4.3c-0.1,0.7,2.6,0.7,8.1,0c-5.5,0.7-8.3,0.7-8.1,0c5.8-2.3,10.3-3.7,13.8-4.3
	h8L121.1,287.6L121.1,287.6z"
          />
          <path
            fill="none"
            d="M123,283.3h-8.3c-1.2,1-2.6,1.9-4.4,2.6h-0.1c-1.8,0.8-3.9,1.4-6.2,1.9c-3.7,0.8-6.2,2.1-7.4,3.7
	c-1,2.2-1.8,5.4-2.8,9.4l2.9-0.7c0.4-4,1-6.7,1.8-8.1c5.2-2.5,9.8-4,13.6-4.7h8.9L123,283.3L123,283.3z"
          />
          <path
            fill="none"
            d="M120.2,288.1c-4.4,1.4-8.5,2.1-12.2,2.1c2.1,0,2.8,2.6,1.9,7.7l7-0.8l3.8-9.1h-0.6L120.2,288.1L120.2,288.1z
	 M112.3,280.9c-0.4,2.2-1.1,3.9-1.9,5.1c1.8-0.8,3.2-1.6,4.4-2.6l0.7-2.5L112.3,280.9L112.3,280.9z M109.9,298.1l-3.2,0.5
	c-3.3,0.5-6.7,1.1-10.2,1.9l-2.9,0.7l-1.5,0.4l-5,1.2l-1.8,6.2l-2.5,0.3l-3.3,0.4c-0.8,0.1-1.4,1-1.5,2.5l-1,12.5v0.1
	c0,1.9,1,3,2.8,3.2h5.5c-1-1.7-2.8-2.5-5.4-2.3c2.6,0,4.4,0.8,5.4,2.3c0.3,0.4,0.4,1,0.7,1.5l0.8,4.8l0.1,0.8l1.1,6.5l0.1,0.8
	l1.1,6.2l0.1,0.8v0.3l1.1,6.7c15.4,2.6,27.7,4,36.7,4.1c-8.9-1.5-15.5-3.7-20.1-6.6c-0.8,1-1.9,1.4-3.3,1.4h-2.8
	c3.3-1.1,5.6-2.6,7.2-4.3l0.1-1v-22.7c-2.6-5.5-4.5-6.3-5.6-2.3c0.3-3.4,1.1-5.1,2.8-4.8c2.1-0.7,3,1.8,2.9,7.2v22.7l-0.1,1
	c-0.1,1.2-0.6,2.2-1.1,2.9c4.5,2.9,11.3,5.1,20.1,6.6l12.2,1.2l0.7-9.8c0.4-3.3,1-6.5,1.6-9.5c2.3-8.9,6.9-16.9,13.3-24.2
	c1.2-1.4,2.6-2.8,4-4l0.1-0.1c1.9-1.8,3.9-3.4,5.9-5l1.5-1.1l-1-0.7c-5.5-4.3-10.9-7.4-16.4-9.4c-8-1-16.2-1.4-24.5-1l-8.1,0.5
	L109.9,298.1L109.9,298.1z"
          />
          <path
            fill="none"
            d="M107.8,290.4l-1.1,8.3l3.2-0.5C110.7,293,110.1,290.3,107.8,290.4L107.8,290.4z M125,296.7
	c8.3-0.4,16.5,0,24.5,1c5.5,1.9,10.9,5.1,16.4,9.4l1,0.7c11.4-8.1,24.5-12.2,39.5-12.2c15.5,0,29.3,4.4,41,13.2c2.1,1.5,4,3.2,5.9,5
	c5.8,5.4,10.2,11.4,13.3,17.7c2.2,4.5,3.9,9.2,4.8,14.3c0.3,1.8,0.4,3.6,0.5,5.2c0,1.5,0.1,3,0.1,4.5l74.8,0.1
	c-3-10.6-5-22.8-5.9-36.6c-0.7-19.4,0.3-35.9,3-49.5v-0.3l-146.6-5.5l-47.3-1.2h-0.3c-7.4,0.5-12.4,3.9-14.7,9.9L125,296.7
	L125,296.7z M169.2,267.8c6.1-0.8,12.2-0.8,18.7,0c2.1,0,3.7,0.7,5.1,2.1c1.4,1.5,2.1,3.2,2.1,5.1v6.9c0,2.1-0.7,3.7-2.1,5.2
	c-1.5,1.4-3.2,2.1-5.1,2.1c-6.1,0.8-12.4,0.8-18.7,0c-2.1,0-3.7-0.7-5.2-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9c0-2.1,0.7-3.7,2.1-5.1
	C165.5,268.5,167.1,267.8,169.2,267.8z"
          />
          <path
            fill="none"
            d="M164,269.9c-1.4,1.5-2.1,3.2-2.1,5.1v6.9c0,2.1,0.7,3.7,2.1,5.2c1.4,1.4,3.2,2.1,5.2,2.1
	c6.3,0.8,12.7,0.8,18.7,0c2.1,0,3.7-0.7,5.1-2.1c1.4-1.5,2.1-3.2,2.1-5.2v-6.9c0-2.1-0.7-3.7-2.1-5.1c-1.5-1.4-3.2-2.1-5.1-2.1
	c-6.5-0.8-12.7-0.8-18.7,0C167.1,267.8,165.5,268.5,164,269.9z M102.9,324.6c1.1-4,3-3.2,5.6,2.3 M85.6,327.8h-5.5l-1.8,2.3v2.8h4.3
	c0.7,0.1,1.2,0.5,1.5,1.4h2.9l-0.8-4.8C86,328.7,85.7,328.2,85.6,327.8L85.6,327.8z"
          />
          <path
            fill="none"
            d="M84.1,334.9v-0.8c-0.3-0.8-0.8-1.2-1.5-1.4l0.1,2.1h1.4L84.1,334.9L84.1,334.9z"
          />
          <path
            fill="none"
            d="M82.7,334.9h-2.9c-1,0-1.4,0.4-1.5,1.4l0.3,3.7h5c0.3,0,0.6,0.1,0.7,0.3c0.4,0.3,0.7,0.5,1,1.1H88l-1.1-6.5
	L82.7,334.9L82.7,334.9z"
          />
          <path
            fill="none"
            d="M87.1,334.9l-0.1-0.8h-3l0.1,0.8 M85.2,341.4l0.3,0.7h2.9l-0.1-0.7 M82.9,340l0.1,2.1h1.4l-0.1-1.9
	c-0.3-0.1-0.4-0.1-0.7-0.3h-0.7L82.9,340L82.9,340z M84.5,342.1h-4.1c-0.8,0-1.2,0.5-1.4,1.4l0.6,3.6h5.8c0.7,0,1.2,0.5,1.7,1.4h2.5
	l-1.1-6.2h-3.9L84.5,342.1L84.5,342.1z"
          />
          <path
            fill="none"
            d="M80.1,340l0.3,2.1h1.8l-0.3-2.1 M79.8,334.9h1.8v-2.2h-1.8 M149.8,262.7h-0.1h0.4 M206.8,310
	c11.8,0,22.3,3.6,31.2,10.6c1.6,1.4,3.3,2.8,4.8,4.3c1.9,1.9,3.6,3.9,5.1,5.8c6.6,8.7,9.9,18.8,9.9,30.3c0,4.7-0.5,9.1-1.6,13.2
	l9.2,1v-10c0-3,0-5.9-0.3-8.9v-0.6l-0.4-4.5c-2.1-11.8-7.6-22.3-16.4-31.2c-4.5-4.5-9.4-8.3-14.6-11.1c-8.3-4.4-17.5-6.7-27.6-6.7
	c-10,0-19.3,2.2-27.5,6.7c-2.1,1.1-4.1,2.5-6.2,3.9c-1.4,1-2.8,2.1-4,3.2c-1.5,1.2-2.9,2.6-4.3,4l-3.6,4c-8,9.5-12.4,20.5-13.6,32.9
	l-0.4,5.5l9.1,1v-2.1c0-10.7,2.9-20.4,8.8-28.8c1.8-2.6,3.9-5,6.2-7.3l4.8-4.3C184.5,313.6,194.9,310,206.8,310L206.8,310z"
          />
          <path
            fill="none"
            d="M175.5,320.6l-4.8,4.3c-2.3,2.3-4.4,4.8-6.2,7.3c-5.9,8.4-8.8,18-8.8,28.8v2.1c0.3,9.9,3.3,18.8,8.8,26.7
	c1.8,2.5,3.9,5,6.2,7.3c8.5,8.5,18.4,13.3,30,14.7l7.4,0.3l5.2-0.4c11.3-1.4,21.2-6.2,29.6-14.6l3.2-3.4l1.9-2.5
	c4-5.2,6.7-10.9,8.3-17.1c1.1-4.1,1.6-8.5,1.6-13.2c0-11.4-3.3-21.5-9.9-30.3c-1.5-2.1-3.2-4-5.1-5.8c-1.5-1.5-3.2-3-4.8-4.3
	c-8.9-7.2-19.4-10.6-31.2-10.6C194.9,310,184.5,313.6,175.5,320.6L175.5,320.6z M201.3,322.5l5.6-0.4l5.4,0.3l1.9,0.3
	c7.7,1.4,14.4,5,20.2,10.7c2.6,2.6,4.8,5.5,6.5,8.5l1,1.8c1.6,3.3,2.8,6.7,3.3,10.5v0.1l0.3,1.8c0.1,1.6,0.3,3.2,0.3,5
	c0,10.2-3.4,19.1-10.3,26.4l-1.4,1.4h0.1h-0.3c-2.8,2.6-5.6,4.8-8.7,6.6l-1.8,1c-5.1,2.5-10.6,3.6-16.6,3.6
	c-5.9,0-11.4-1.1-16.4-3.4l-1.8-0.8c-3.2-1.6-6.1-3.9-8.8-6.5l-1.4-1.4c-7-7.4-10.6-16.4-10.6-26.7c0-1.6,0.1-3.2,0.3-4.7l0.3-1.9
	c0.6-3.7,1.6-7.1,3.3-10.5l1-1.8c1.8-3.2,4-6.1,6.7-8.8c5.8-5.8,12.4-9.4,19.9-10.7L201.3,322.5L201.3,322.5z"
          />
          <path
            fill="none"
            d="M199.3,322.8l-0.8,13.8c-0.1,1.1,0,2.1,0.1,3.2l0.3,1.4h2.3c-0.3-0.5-0.4-1.1-0.4-1.6c-0.1-1-0.3-1.9-0.1-2.8
	l0.6-13.5v-0.5L199.3,322.8L199.3,322.8z"
          />
          <path
            fill="none"
            d="M197.1,326.5l-0.6,10v3.6l0.1,0.7v0.1h2.1l-0.3-1.4c-0.1-1.1-0.1-2.1-0.1-3.2l0.8-13.6l-2.1,2.9L197.1,326.5
	L197.1,326.5z M190,345.1c-1.1-0.5-2.2-0.8-3.4-1.2l-9.8-2.5l-0.7-0.1l-3.4,1.1l13.2,3.4c1.1,0.3,2.1,0.7,2.9,1.1l0.3,0.1
	c0.8,0.4,1.5,0.8,2.2,1.4l6.7,5.1c0-0.8,0.4-1.7,1-2.2c0.7-0.7,1.5-1,2.3-1l0.8,0.1c0.7,0.1,1.1,0.4,1.6,0.8c0.7,0.7,1,1.5,1,2.5
	c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.5,1-2.3,1c-1,0-1.8-0.3-2.3-1c-0.7-0.7-1-1.4-1-2.3v-0.3l-6.7-5.1c-0.7-0.5-1.4-1-2.2-1.4l-0.3-0.1
	c-0.8-0.4-1.8-0.7-2.9-1.1l-13.2-3.4h-0.3l-1,1.8h0.1l13.5,3.9c1,0.1,1.8,0.5,2.5,1h0.1c1,0.5,1.8,1.1,2.5,1.9l6.1,5.4
	c0.3,0.5,0.6,1.1,0.4,1.6v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-1.8,0.1h8.4c0.3-0.7,0.7-1.4,1.2-2.1l0.4-0.3c1-0.8,1.9-1.2,3.2-1.2
	c1.4,0,2.5,0.5,3.6,1.5c0.6,0.5,1,1.2,1.2,2.1h11.3l-4.7-0.4c-0.7-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c0-0.5,0.1-1.1,0.4-1.5l6.1-5.4
	c0.8-0.8,1.6-1.5,2.5-1.9l0.1-0.1c0.8-0.4,1.6-0.8,2.6-1l12.8-3.6h0.1l0.6-0.1l-1-1.8l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3
	c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5l6.6-5c0.7-0.5,1.2-1,1.9-1.2l0.4-0.3c1-0.4,1.9-0.8,2.9-1.1l13.2-3.4l-3.4-1.1l-0.8,0.1l-9.6,2.5
	c-1.2,0.3-2.3,0.7-3.4,1.2l-0.8,0.4c-0.4,0.3-0.8,0.5-1.2,0.7l-5,3.7c-0.8,0.8-1.6,1-2.5,0.4c-0.6-0.3-0.6-1,0-1.9l2.1-5.8
	c0.1-0.4,0.3-1,0.3-1.4h-1.6c-0.1,0.4-0.3,1-0.4,1.4l-2.9,8c0.8-0.3,1.6-0.1,2.3,0.3c0.8,0.4,1.4,1.1,1.6,1.9l0.1,0.8
	c0.1,0.7,0,1.2-0.3,1.8c-0.4,0.8-1.1,1.4-1.9,1.6c-1,0.3-1.8,0.3-2.6-0.1c-0.8-0.4-1.4-1.1-1.6-1.9c-0.3-1-0.3-1.8,0.3-2.6
	c0.4-0.8,1.1-1.4,1.9-1.6h0.1l2.9-8c0.1-0.4,0.3-1,0.4-1.4h-2.3c-0.1,0.4-0.4,1-0.6,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.6
	c-0.6-0.1-1-0.4-1.4-1l-3.2-7.4c-0.3-0.4-0.4-1-0.6-1.4h-2.3l0.3,1.4l2.8,7.8l-2.8-7.8l-0.3-1.4h-2.1l0.3,1.4l2.1,5.8
	c0.6,1.1,0.4,1.9-0.4,2.5c-0.4,0.4-1,0.3-1.9-0.5l-5-3.7c-0.4-0.3-0.8-0.7-1.4-0.8L190,345.1L190,345.1z M168.4,354.5l-0.3,1.9
	l0.4,0.1l0.1,0.1l6.1,2.3l1.1,0.4h5.9c0,0-0.1-0.1-0.3-0.1l-6.6-2.5L168.4,354.5L168.4,354.5z"
          />
          <path
            fill="none"
            d="M168.7,356.7l-0.3-0.1l-0.1-0.1l2.2,2.9l0.4,0.3h5.1l-1.1-0.5 M214.2,322.8l-1.9-0.3l0.6,14
	c0.1,0.8,0.1,1.8-0.1,2.8l-0.4,1.6h2.3c0.1-0.3,0.1-0.7,0.3-1.1v-0.3c0.1-1,0.1-1.9,0.1-3.2l-0.8-13.2L214.2,322.8L214.2,322.8
	L214.2,322.8z"
          />
          <path
            fill="none"
            d="M214.2,323.2l0.8,13.2c0,1.1,0,2.2-0.1,3.2v0.3c0,0.3-0.1,0.7-0.3,1.1h2.1v-0.3l0.1-0.7c0.1-1.2,0.1-2.5,0-3.6
	l-0.6-10v-0.7l-2.1-2.9L214.2,323.2L214.2,323.2z M244.7,354.3l-12.7,4.7c-0.3,0.1-0.7,0.3-1,0.4h5.9l8.4-3.3h0.1l-0.3-1.8H244.7
	L244.7,354.3z"
          />
          <path fill="none" d="M237,359.5h5.1l0.4-0.3l0.7-0.3l2.2-2.9" />
          <path
            fill="none"
            d="M211.7,361.1c0,0.4,0,0.8-0.1,1.2c-0.1,0.7-0.6,1.4-1,1.9l-0.3,0.4c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2
	c-1.1-0.1-2.1-0.6-2.9-1.4c-1-1-1.4-2.1-1.5-3.3v-0.1c0-0.5,0-1.1,0.3-1.5h-8.4l-6.2,0.5c-1.2,0.1-2.2,0.3-3.2,0
	c-1-0.1-1.8-0.3-2.5-0.7h-5.9l5,1.9c1,0.4,1.9,0.7,3,1l2.8,0.3l8.3-0.3l-8.3,0.3l-2.8-0.3c-1-0.3-2.1-0.5-3-1l-5-1.9h-5.2l0.3,0.1
	l3.6,1.4l5.6,2.2c1.1,0.4,2.2,0.8,3.4,1.1h8.7c1.2-0.3,1.8,0,1.9,0.5c0.6,1,0.3,1.6-0.8,2.2l-3.2,2.2l-3.2,2.3l-0.4,0.4
	c-0.8,0.8-1.5,1.8-2.2,2.9l-1.9,3h2.2l1.2-1.9c0.6-0.8,1.1-1.6,1.9-2.5l0.1-0.1c0.6-0.7,1.2-1.2,1.9-1.6l7-4.7
	c-0.8-0.4-1.4-1-1.8-1.6c-0.4-0.8-0.6-1.6-0.3-2.6l0.3-0.7c0.3-0.5,0.7-1,1.4-1.2c0.8-0.4,1.6-0.5,2.6-0.1c1,0.3,1.5,0.8,1.9,1.6
	c0.4,0.8,0.6,1.6,0.3,2.6c-0.3,1-0.8,1.5-1.6,1.9c-0.8,0.4-1.6,0.5-2.6,0.3H197l-7,4.7c-0.7,0.5-1.4,1.1-1.9,1.6l-0.1,0.1
	c-0.8,0.8-1.4,1.6-1.9,2.5l-1.2,1.9h2.5l0.4-0.5c0.4-0.8,1-1.5,1.6-2.2c0.7-0.7,1.7-1.2,2.6-1.8l6.9-4.1h1.6l0.3,0.1l0.3,0.3
	c0.4,0.4,0.6,1,0.6,1.5l-1.5,6.7h2.6l1.5-5.2l-1.5,5.2h2.1l0.6-2.1c0.1-1.2,0.6-1.8,1.1-1.7c1.1-0.3,1.6,0.3,1.8,1.4l0.7,2.2h2.1
	l-1.6-5.6c-0.6,0.7-1.2,1.1-2.1,1.2c-1,0.1-1.8-0.1-2.5-0.7l-0.6-0.5c-0.4-0.4-0.7-1-0.8-1.6c-0.1-1,0.1-1.8,0.7-2.5
	c0.6-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0.1,2.5,0.5c0.8,0.5,1.2,1.4,1.4,2.2c0.1,1,0,1.8-0.6,2.5l-0.1,0.1l1.6,5.6h2.6l-1.5-6.9
	c0-0.7,0.3-1.2,0.6-1.5l0.3-0.3l0.3-0.1l1.7-0.1l6.9,4.1c1,0.4,1.8,1.1,2.6,1.8v0.1c0.7,0.7,1.4,1.4,1.8,2.2l0.6,0.8h2.5l-1.2-2.1
	c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7l6.9,4.7c0.7,0.5,1.2,1,1.8,1.5l0.3,0.3c0.7,0.7,1.4,1.5,1.9,2.5
	l1.2,2.1h2.3l-2.1-3.2c-0.7-1.1-1.4-2.1-2.2-2.9l-0.6-0.5l-0.6-0.6c-0.1-0.1-0.4-0.3-0.6-0.4l-5.1-3.6c-1.1-0.5-1.5-1.1-1.1-1.5
	c0-1.1,0.7-1.5,1.9-1.2h7.7l0.7-0.1c1.1-0.3,2.2-0.5,3.4-1.1l8.8-3.4h-5.2l-4.3,1.6c-1.1,0.4-2.1,0.7-3,0.8L229,362l-2.3,0.1
	l-8.4-0.3c0.4,0.7,0.7,1.5,0.4,2.3c-0.1,1-0.6,1.7-1.4,2.2l-0.7,0.4c-0.6,0.3-1.1,0.3-1.8,0.3c-1-0.1-1.6-0.7-2.2-1.4
	c-0.6-0.8-0.8-1.6-0.7-2.5c0.1-1,0.7-1.6,1.4-2.2c0.8-0.5,1.6-0.8,2.5-0.5c1,0.1,1.6,0.5,2.2,1.4l0.1,0.1l8.4,0.3l2.3-0.1l0.4-0.1
	c1-0.1,1.9-0.4,3-0.8l4.3-1.6h-5.9L229,360c-1,0.1-2.1,0.1-3.2,0l-3.3-0.3h-11.3C211.7,360,211.7,360.5,211.7,361.1L211.7,361.1z"
          />
          <path
            fill="none"
            d="M213.8,378h-2.6l0.8,2.5c0.3,0.8,0.6,1.5,1,2.3l0.1,0.3c0.4,0.8,1,1.6,1.8,2.6l8.4,10.2l0.3,0.3l0.1,0.1l1.8-1
	l-8.8-11c-0.6-0.7-1.1-1.4-1.5-2.2V382c-0.4-1-0.7-1.9-1-3L213.8,378L213.8,378z"
          />
          <path
            fill="none"
            d="M209.1,378l1.1,3.7l0.7,1.5l0.3,0.5c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.8l0.4,0.5l3.3,1.1l-0.3-0.3l-8.4-10.2
	c-0.7-0.8-1.2-1.8-1.8-2.6l-0.1-0.3c-0.4-0.8-0.7-1.5-1-2.3l-0.8-2.5L209.1,378L209.1,378L209.1,378z M235.4,387.5v-3.7l-0.4-0.5
	l-3.3-5.2h-2.3"
          />
          <path
            fill="none"
            d="M235.4,387.5l-6.1-9.5h-2.5l7.2,10.9 M182.3,378.4l-3.3,5.1l-0.4,0.5v3.6l0.1-0.1l6.1-9.5h-2.2 M202.8,378
	l-0.8,2.6c-0.3,0.8-0.6,1.5-1,2.2l-0.1,0.3c-0.6,1-1.1,1.8-1.8,2.6l-8.7,10.6l3.4-1.1l0.4-0.5l6.3-7.7l2.1-2.9l0.4-0.7
	c0.3-0.4,0.4-1,0.6-1.4l1.2-3.9h-2.1L202.8,378L202.8,378z"
          />
          <path
            fill="none"
            d="M200,379.1c-0.3,1.2-0.6,2.3-1,3c-0.4,0.8-1,1.6-1.5,2.3l-8.8,11l-0.1,0.1l1.8,0.8l0.1-0.1l8.7-10.6
	c0.7-0.8,1.2-1.6,1.8-2.6l0.1-0.3c0.4-0.7,0.7-1.5,1-2.2l0.8-2.6h-2.6L200,379.1L200,379.1z M178.4,387.7l1.5,1.4l7.3-11.1h-2.5
	l-6.1,9.5l-0.1,0.1 M203.3,357.5c-1,1-1.5,2.2-1.5,3.6v0.3c0.1,1.2,0.6,2.3,1.5,3.3c0.8,0.8,1.8,1.2,2.9,1.4h1.2
	c1.1-0.1,2.1-0.6,2.9-1.4l0.3-0.4c0.4-0.5,0.8-1.2,1-1.9c0.1-0.4,0.1-0.8,0.1-1.2c0-1.4-0.6-2.5-1.4-3.6c-1-1-2.2-1.5-3.6-1.5
	c-1.2,0-2.3,0.4-3.2,1.2L203.3,357.5L203.3,357.5z M203.3,359.1l0.6-0.3c0.8-0.4,1.8-0.7,2.9-0.5l0.8,0.1c0.7,0.1,1.4,0.4,1.8,0.7
	l0.6,0.4l0.4,0.5c0.1,0.1,0.3,0.4,0.3,0.7c0,0.3,0.1,0.5,0,0.8l-0.3,0.7l-0.4,0.5l-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.5l-1.6,0.1
	l-1.5-0.3l-1-1.1l-0.6-0.4l-0.4-0.5l-0.3-0.5v-0.8c0-0.3,0-0.4,0.1-0.7l0.4-0.5L203.3,359.1L203.3,359.1z"
          />
          <path
            fill="none"
            d="M202.9,359.5l-0.4,0.5c-0.1,0.3-0.1,0.4-0.1,0.7v0.8l0.3,0.5l0.4,0.6l0.6,0.4l1.2,0.5l1.5,0.3l1.6-0.1
	c0.4-0.1,0.8-0.3,1.2-0.5l1-0.8l0.4-0.5l0.3-0.7c0-0.3,0-0.5-0.1-0.8c0-0.3-0.1-0.4-0.3-0.7l-0.4-0.6l-0.6-0.4
	c-0.6-0.3-1.1-0.5-1.8-0.7l-0.8-0.1c-1.1-0.1-2.1,0.1-2.9,0.6l-0.6,0.4L202.9,359.5L202.9,359.5z M206.5,358.4v0.8
	c0,0,0,0.5-0.1,0.8v0.1c0,0.1-0.1,0.1-0.1,0.1h-3c0.1-0.7,0.6-1.1,1.2-1.5l1-0.4L206.5,358.4L206.5,358.4z M206.5,358.4
	c0.8,0,1.5,0.1,2.1,0.5c0.3,0.1,0.6,0.3,0.7,0.5l0.4,0.8h-2.3l-0.4-1.1C207,359,206.9,358.7,206.5,358.4L206.5,358.4z M206.6,360.5
	v0.7h-0.1v-0.7H206.6L206.6,360.5z M206.8,360.5h0.7l0.4-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0,0.1,0,0.1,0.1v0.5c0,0.1-0.1,0.1-0.1,0.1
	h-1.4v-0.1h1.2v-0.4h-0.3L206.8,360.5L206.8,360.5z M207,361.5h2.9c-0.3,0.5-0.7,1.1-1.2,1.5c-0.7,0.4-1.4,0.5-2.1,0.5v-1
	c0-0.3,0.1-0.5,0.3-0.8l0.1-0.1L207,361.5L207,361.5z M206.2,361.2c0,0-0.1,0.1-0.3,0.1h-1.2l-0.1-0.1v-0.4l0.1-0.1
	c0,0,0.1-0.1,0.3-0.1l0.4,0.1l0.3-0.1l0.3,0.1h0.3L206.2,361.2L206.2,361.2L206.2,361.2z M204.6,361.2h-0.1v-0.5h-1.1v0.5
	c-0.1,0-0.1,0-0.3-0.1v-0.5l0.1-0.1h1.4L204.6,361.2L204.6,361.2z M205.5,361.5l0.1,0.7l0.3,0.5l0.6,0.8l-1.2-0.1l-1.1-0.4
	c-0.7-0.4-1.1-0.8-1.2-1.5H205.5L205.5,361.5z M209.9,360.6v0.3h-1.2l0.1,0.1h1.2l-0.1,0.1h-1.4v-0.3h1.2v-0.1h-1.2v-0.1h0.7
	l0.3-0.1l0.3,0.1C209.9,360.5,209.9,360.6,209.9,360.6L209.9,360.6z"
          />
          <path
            fill="none"
            d="M205.2,358.6l-1,0.4c-0.7,0.4-1.1,0.8-1.2,1.5h3c0.1,0,0.1-0.1,0.1-0.1l0.1-0.3c0.1-0.1,0.1-0.4,0.1-0.8v-0.8
	L205.2,358.6L205.2,358.6z M206.6,358.4c0.3,0.3,0.4,0.5,0.6,0.8l0.4,1.1h2.3l-0.4-0.8c-0.1-0.3-0.4-0.4-0.7-0.5
	C208,358.6,207.3,358.4,206.6,358.4L206.6,358.4z M206.5,361.2h0.1v-0.7h-0.1"
          />
          <path
            fill="none"
            d="M206.8,360.6h1.2v0.4h-1.2v0.1h1.4c0.1,0,0.1,0,0.1-0.1v-0.5c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.3-0.1l-0.4,0.1
	L206.8,360.6L206.8,360.6z M206.9,361.6l-0.1,0.1c-0.1,0.3-0.1,0.4-0.3,0.8v1c0.8,0,1.5-0.1,2.1-0.5s1-1,1.2-1.5h-2.9L206.9,361.6
	L206.9,361.6z M206.2,360.6l-0.3-0.1h-1c-0.1,0-0.3,0-0.3,0.1l-0.1,0.1v0.4l0.1,0.1h1.2c0.1,0,0.1-0.1,0.3-0.1L206.2,360.6
	L206.2,360.6z M205.5,360.6h0.6v0.4H205v-0.4L205.5,360.6L205.5,360.6z"
          />
          <path
            fill="none"
            d="M205,360.6v0.4h1.1v-0.1l-0.1-0.3h-0.7 M204.6,360.5h-1.4c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.3,0.1v-0.5
	h1.1v0.5h0.1L204.6,360.5L204.6,360.5z M203,361.5c0.1,0.7,0.6,1.2,1.2,1.5l1.1,0.4l1.2,0.1l-0.6-0.8l-0.3-0.6l-0.1-0.7L203,361.5
	L203,361.5z M209.8,360.5h-1.4v0.1h1.2v0.1h-1.2v0.3h1.4l0.1-0.1h-1.2l-0.1-0.1h1.2L209.8,360.5
	C209.9,360.6,209.9,360.5,209.8,360.5L209.8,360.5z M197.3,325.8l2.1-2.9v-0.1c-7.6,1.4-14.2,5-19.9,10.7c-2.8,2.8-5,5.6-6.7,8.8
	h0.3l3.4-1.1l0.7,0.1c1.4-1.9,2.9-3.9,4.7-5.6c4.5-4.5,9.8-7.7,15.5-9.2L197.3,325.8L197.3,325.8z M201.3,322.5v0.5 M201.3,323.1
	l-0.6,13.5c-0.1,0.8,0,1.8,0.1,2.8c0.1,1,0.6,1.9,1.1,3l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.6c0.6-0.1,1-0.4,1.4-1l3.2-7.4
	c0.6-1.1,1-2.1,1.1-3c0.1-1,0.3-1.9,0.1-2.8l-0.6-14l-1.5,2.2v0.8l0.4,12.2l-0.1,1.1c-0.1,0.5-0.4,1.1-0.7,1.6l-1.8,3.2
	c-0.3,0.7-1,1.1-1.6,1.1h-0.7c-0.7,0-1.2-0.3-1.7-1.1l-1.8-3.2c-0.3-0.6-0.6-1.1-0.7-1.6l-0.1-1.2l0.4-12.2v-0.8l-1.5-2.2
	L201.3,323.1L201.3,323.1z"
          />
          <path
            fill="none"
            d="M202.6,325.6l4.1-0.3l4,0.3v-0.8l1.4-2.3l-5.4-0.3l-5.5,0.4l1.5,2.2"
          />
          <path
            fill="none"
            d="M202.6,325.6l-0.4,12.2l0.1,1.1l4.4-0.4l4.3,0.4l0.1-1.1l-0.4-12.2l-4-0.3"
          />
          <path
            fill="none"
            d="M204.7,343.7c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.6-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6l-4.3-0.4
	l-4.4,0.4c0.1,0.5,0.3,1.1,0.7,1.6L204.7,343.7L204.7,343.7z M196.6,336.6l0.6-10c-5.8,1.6-11,4.7-15.5,9.2
	c-1.8,1.8-3.4,3.7-4.7,5.6l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3l-0.1-0.7
	C196.6,338.9,196.6,337.7,196.6,336.6L196.6,336.6z"
          />
          <path
            fill="none"
            d="M196.7,340.8c-2.1,1.1-4,2.5-5.9,4.3l-0.3,0.3c0.4,0.3,1,0.5,1.4,0.8l5,3.7c0.8,0.8,1.5,1.1,1.9,0.5
	c0.8-0.5,1-1.4,0.4-2.5l-2.1-5.8L196.7,340.8L196.7,340.8z M171.8,344l1.8,2.1l0.7,0.3l3.9,1.1l8.1,2.3c0.3,0.1,0.7,0.3,1,0.4
	c0.6,0.3,1.1,0.7,1.5,1.1l1.6,1.8l0.8,0.8c0.6,0.5,0.8,1.2,0.6,1.9l-0.3,0.7c-0.1,0.4-0.4,0.8-0.8,1l-0.7,0.1l-2.3,0.4l-1.2,0.3
	h-1.8l-0.8-0.1l-0.3-0.1l-8.3-3l-3.3-1.2l-0.7-0.3l-2.6,0.7l6.5,2.5l6.6,2.5c0.8,0.4,1.8,0.7,2.8,0.7c1,0.1,2.1,0.1,3.2,0l8-0.8
	c0.6-0.1,1.1-0.5,1.4-1l0.1-0.3v-0.3c0.1-0.5-0.1-1.1-0.4-1.6l-6.1-5.4c-0.8-0.8-1.6-1.5-2.5-1.9H188c-0.8-0.4-1.6-0.8-2.5-1
	L171.8,344z"
          />
          <path
            fill="none"
            d="M171.8,344c-1.8,3.3-2.8,6.7-3.4,10.5l2.6-0.7l0.7,0.3c0.6-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L171.8,344L171.8,344z
	 M171.7,344h0.1"
          />
          <path
            fill="none"
            d="M171.7,354l3.3,1.2l0.1-1.4l0.3-1.8c0.4-1.1,1.4-1.9,2.8-2.6c1.1-0.4,1.7-0.3,1.7,0.5c0,1.5,0.8,2.5,2.3,3
	c0.8,0.1,1.5,1,2.2,2.3h0.6c0.3-1.2,0.7-2.3,1.2-3.4c-0.3-0.1-0.7-0.3-1.1-0.3c-1.1,0.1-1.6-0.3-1.9-1.2c-0.1-1.1-1.2-1.6-3.3-1.8
	h-1.9v-1.2l-3.9-1.1C173.1,348.8,172.2,351.3,171.7,354L171.7,354z M168.4,356.5h0.1l-0.4-0.1 M168.5,356.5h-0.1l0.3,0.1"
          />
          <path
            fill="none"
            d="M168.1,356.4c-0.1,1.5-0.3,3-0.3,4.7c0,10.3,3.6,19.3,10.6,26.7l0.1-0.1V384l0.4-0.5c-4.4-5.4-7-11.7-7.7-18.6
	c-0.1-1.2-0.1-2.5-0.1-3.9v-1.4l-0.8-0.3L168.1,356.4l0.3,0.1L168.1,356.4L168.1,356.4z"
          />
          <path
            fill="none"
            d="M171.1,361.1c0,1.4,0,2.6,0.1,3.9l3.7,0.4c-0.1-1.4-0.1-2.8-0.1-4.3l-3.6-1.4L171.1,361.1L171.1,361.1z
	 M178,348.7h1.9c2.1,0.1,3.2,0.7,3.3,1.8c0.3,1,1,1.4,1.9,1.2c0.4,0,0.7,0.1,1.1,0.3c0.7,0.4,1.2,1.5,1.4,3.2v3l2.3-0.4l0.7-0.1
	c0-1.8-0.1-3.2-0.3-4.4l-1.7-1.8c-0.4-0.5-1-0.8-1.5-1.1c-0.3-0.3-0.6-0.4-1-0.4l-8.1-2.3v1.1H178L178,348.7z M175.1,355.3l0.1-1.4
	L175.1,355.3L175.1,355.3z"
          />
          <path
            fill="none"
            d="M175.3,353.9l-0.1,1.4l8.3,3l0.3,0.1c0.1-0.8,0.6-1.4,1.1-1.8c0.7-0.4,1.5-0.5,2.8-0.4v-0.8h-3
	c-0.7-1.4-1.4-2.2-2.2-2.3c-1.7-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6L175.3,353.9L175.3,353.9z
	 M190.2,353.1c0.1,1.1,0.3,2.6,0.3,4.4c0.4-0.3,0.7-0.5,0.8-1l0.3-0.7c0.3-0.7,0-1.4-0.6-1.9L190.2,353.1L190.2,353.1z"
          />
          <path
            fill="none"
            d="M185,355.3h2.5V355c-0.1-1.6-0.6-2.8-1.4-3.2C185.7,353.1,185.3,354.2,185,355.3L185,355.3z M187.5,356.2
	c-1.2-0.1-2.2,0-2.8,0.4c-0.6,0.4-1,1-1.1,1.8l0.8,0.1h1.8l1.2-0.3L187.5,356.2L187.5,356.2z M190.5,364.4c-0.1,2.1-0.3,3.7-0.4,5
	l3.2-2.2c1.1-0.5,1.4-1.2,0.8-2.2c0-0.5-0.7-0.8-1.9-0.5H190.5L190.5,364.4z M184.6,364.8l-0.1-0.4h-0.3"
          />
          <path
            fill="none"
            d="M187.5,366.3c-0.1,1.8-0.7,2.9-1.6,3.3l-0.8,0.1c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9
	l1.2,5.9l3-0.3l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3c0.1-1.2,0.3-2.9,0.4-5l-3,0.1L187.5,366.3L187.5,366.3z"
          />
          <path
            fill="none"
            d="M187.5,366h-2.8v0.3c0.3,1.1,0.6,2.2,1,3.3c1-0.4,1.4-1.5,1.6-3.3L187.5,366L187.5,366z"
          />
          <path
            fill="none"
            d="M187.5,365.2c-1.4,0.1-2.3,0-2.9-0.4l-0.4-0.4l-0.3-0.1c-1.2-0.3-2.5-0.7-3.4-1.1l-5.6-2.2l0.3,4.3l0.3,2.2
	l0.3,1.8c0.4,1.1,1.4,1.9,2.8,2.6c1.1,0.4,1.6,0.3,1.6-0.4c0-1.5,0.8-2.5,2.3-3c0.8-0.1,1.4-1,2.1-2.2l0.1-0.3h3v-0.7L187.5,365.2
	L187.5,365.2z"
          />
          <path
            fill="none"
            d="M184.5,366l-0.1,0.3h0.6V366 M187.5,365.2v-0.7l-3-0.1v0.3C185.3,365.2,186.3,365.3,187.5,365.2z M175,365.2
	l-0.3-4.3C174.7,362.4,174.8,363.9,175,365.2L175,365.2z M175.3,367.5l-0.3-2.3 M198.1,353.6c0,1,0.3,1.8,1,2.3c0.7,0.7,1.5,1,2.3,1
	c1,0,1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3s-0.3-1.8-1-2.5c-0.4-0.4-1-0.7-1.6-0.8l-0.8-0.1c-1,0-1.8,0.3-2.3,1s-1,1.4-1,2.2v0.3H198.1
	z M200.3,352.4c0.3-0.3,0.7-0.5,1.2-0.5h0.4c0.3,0,0.6,0.3,0.8,0.4c0.4,0.3,0.6,0.7,0.6,1.2c0,0.4-0.1,0.8-0.6,1.2
	c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.6-0.7-0.6-1.2C199.7,353.2,199.9,352.8,200.3,352.4L200.3,352.4z"
          />
          <path
            fill="none"
            d="M199.7,353.6c0,0.4,0.1,0.8,0.6,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5c0.4-0.3,0.6-0.7,0.6-1.2
	s-0.1-1-0.6-1.2c-0.3-0.1-0.6-0.4-0.8-0.4h-0.4c-0.4,0-0.8,0.1-1.2,0.4C199.9,352.8,199.7,353.2,199.7,353.6L199.7,353.6z
	 M209,354.6c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.6,0.5,2.6,0.1c1-0.3,1.5-0.8,1.9-1.7c0.3-0.5,0.4-1.1,0.3-1.8l-0.1-0.8
	c-0.3-0.8-0.8-1.5-1.6-1.9c-0.8-0.4-1.7-0.5-2.3-0.3h-0.1c-1,0.3-1.5,0.8-1.9,1.6C208.8,352.8,208.7,353.6,209,354.6L209,354.6z
	 M210.6,354c-0.1-0.4-0.1-0.8,0.1-1.2c0.3-0.4,0.6-0.7,1-0.8l1.2,0.1c0.4,0.3,0.7,0.5,0.8,1v0.4c0.1,0.3,0,0.5-0.1,1
	c-0.3,0.4-0.6,0.7-1,0.8l-1.4-0.1C211,354.9,210.7,354.5,210.6,354L210.6,354z"
          />
          <path
            fill="none"
            d="M211.4,355l1.4,0.1c0.4-0.1,0.8-0.4,1-0.8c0.1-0.3,0.3-0.7,0.1-1v-0.4c-0.1-0.4-0.4-0.8-0.8-1l-1.2-0.1
	c-0.4,0.1-0.8,0.4-1,0.8c-0.1,0.4-0.3,0.8-0.1,1.2C210.7,354.5,211,354.9,211.4,355L211.4,355z M201.4,365c0.3-1,0.3-1.8-0.1-2.6
	c-0.4-0.8-1.1-1.4-1.9-1.6c-1-0.3-1.8-0.3-2.6,0.1c-0.6,0.3-1.1,0.7-1.4,1.2l-0.3,0.7c-0.3,1-0.3,1.8,0.3,2.6c0.4,0.8,1,1.4,1.8,1.6
	h0.1c1,0.3,1.8,0.3,2.6-0.3C200.6,366.6,201.1,365.9,201.4,365L201.4,365z M199.6,363.1c0.3,0.4,0.3,0.8,0.1,1.4
	c-0.1,0.4-0.4,0.7-0.8,1l-1.2,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.3-0.4-0.3-0.8-0.1-1.2l0.1-0.4c0.1-0.3,0.3-0.4,0.7-0.7l1.2-0.1
	C199.2,362.4,199.5,362.7,199.6,363.1L199.6,363.1z"
          />
          <path
            fill="none"
            d="M198.6,362.3l-1.2,0.1c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.4c-0.1,0.4-0.1,1,0.1,1.2c0.1,0.4,0.6,0.7,1,0.8h1.2
	c0.4-0.3,0.7-0.5,0.8-1c0.1-0.4,0.1-1-0.1-1.4C199.5,362.7,199.2,362.4,198.6,362.3L198.6,362.3z M201.1,369.7l-0.3-0.3l-0.3-0.1
	h-1.6l-6.9,4.1c-1.1,0.5-1.9,1.1-2.6,1.8c-0.7,0.7-1.2,1.4-1.6,2.2l-7.7,11.7l2.5-1l0.4-0.7l6.9-10.2l0.7-0.8c0.4-0.4,1-0.7,1.5-1.1
	l3.3-1.5l1.9,0.1l0.3,0.3l0.3,0.1c0.6,0.4,0.8,1.1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8l-0.6,1l-7.6,9.8l-0.4,0.5l-0.1,2.6
	l8.7-11c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.7-1.8,1-3l1.8-7.8C201.7,370.7,201.5,370.1,201.1,369.7L201.1,369.7z"
          />
          <path
            fill="none"
            d="M197.8,374.4l-0.3-0.1l-0.3-0.3l-1.9-0.1l-3.3,1.5c-0.6,0.3-1.1,0.7-1.5,1.1l0.4,0.4c2.1,2.1,4.1,3.6,6.6,4.7
	c0.3-0.5,0.4-1.1,0.6-1.8l0.4-3.6C198.6,375.4,198.4,374.8,197.8,374.4L197.8,374.4z M203.6,370.8c0.1,0.7,0.4,1.2,0.8,1.6l0.6,0.5
	c0.7,0.6,1.5,0.8,2.5,0.7c0.8-0.1,1.5-0.6,2.1-1.2l0.1-0.1c0.6-0.8,0.8-1.6,0.6-2.5c-0.1-1-0.6-1.6-1.4-2.2
	c-0.8-0.5-1.6-0.7-2.5-0.5c-1,0.1-1.6,0.5-2.2,1.4C203.7,369,203.5,369.9,203.6,370.8L203.6,370.8z M206.6,368.6
	c0.6-0.1,1,0,1.2,0.3c0.4,0.3,0.6,0.7,0.7,1.1c0.1,0.4,0,0.8-0.3,1.2c-0.3,0.4-0.7,0.5-1.1,0.7c-0.4,0-0.8,0-1.2-0.3l-0.3-0.3
	c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0-1,0.3-1.2C205.8,368.9,206.2,368.6,206.6,368.6L206.6,368.6z"
          />
          <path
            fill="none"
            d="M205.5,369.2c-0.3,0.3-0.4,0.8-0.3,1.2c0,0.4,0.1,0.7,0.4,0.8l0.3,0.3c0.4,0.3,0.8,0.4,1.2,0.3
	c0.6-0.1,0.8-0.3,1.1-0.7c0.3-0.4,0.4-0.8,0.3-1.2c0-0.4-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.2-0.3
	C206.2,368.6,205.8,368.9,205.5,369.2L205.5,369.2z M205.5,375.9l-1.8,5.9c-0.1,0.5-0.3,1-0.6,1.4l3.6,0.3l4-0.3l-0.7-1.5l-1.8-5.9
	c-0.1-1.2-0.8-1.6-1.8-1.4C206.1,374.3,205.7,374.8,205.5,375.9L205.5,375.9z M179.9,389.1c2.8,2.6,5.6,4.8,8.8,6.5l0.1-0.1l0.1-2.6
	l0.4-0.5c-2.3-1.4-4.4-2.9-6.5-4.8l-0.4,0.7L179.9,389.1L179.9,389.1z M190.5,396.4c5,2.3,10.5,3.4,16.4,3.4
	c6.1,0,11.6-1.2,16.6-3.6l-0.1-0.1l-3.3-1.1l-0.4-0.5c-4,1.5-8.3,2.2-12.8,2.2c-4.4,0-8.5-0.7-12.4-2.1l-0.4,0.5l-3.4,1.1
	L190.5,396.4L190.5,396.4z M240.9,341.9c-1.6-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7l0.1,0.5v-0.4l2.1,2.9v0.7
	c5.9,1.5,11.1,4.5,15.8,9.2c1.8,1.7,3.2,3.4,4.5,5.4l0.8-0.1L240.9,341.9L240.9,341.9z M241.1,343.9l0.6-0.1h0.1"
          />
          <path
            fill="none"
            d="M241.8,343.7l-1.6,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C244.6,350.5,243.5,347,241.8,343.7
	L241.8,343.7z"
          />
          <path
            fill="none"
            d="M241.7,343.7l-0.6,0.1H241l-12.8,3.6c-1,0.3-1.8,0.5-2.6,1l-0.1,0.1c-0.8,0.4-1.6,1.1-2.5,1.9l-6.1,5.4
	c-0.4,0.5-0.6,1.1-0.4,1.5v0.3l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.3,3.2,0c1.1-0.1,1.9-0.4,2.8-0.8l12.7-4.7
	l0.4-0.1l-2.6-0.7l-0.7,0.3l-11.6,4.3l-1.1,0.3l-1.8-0.1l-3.6-0.7c-0.8-0.1-1.4-0.5-1.5-1.2l-0.3-0.7c-0.3-0.7,0-1.4,0.6-1.9
	l2.5-2.6c0.4-0.4,1-0.8,1.4-1.1c0.4-0.3,0.8-0.4,1.1-0.5l11.8-3.4l0.7-0.3L241.7,343.7L241.7,343.7z M232.1,335.7
	c-4.7-4.7-9.9-7.7-15.8-9.2l0.6,10c0.1,1.2,0.1,2.3,0,3.6l-0.1,0.7c2.2,1.1,4.1,2.5,6.1,4.4l0.1,0.1l0.8-0.4
	c1.1-0.5,2.2-0.8,3.4-1.2l9.6-2.5C235.4,339.3,233.9,337.5,232.1,335.7L232.1,335.7z M212.3,322.4h-0.1H212.3"
          />
          <path
            fill="none"
            d="M216.2,342.4l-2.1,5.8c-0.6,1.1-0.6,1.6,0,1.9c0.8,0.7,1.6,0.4,2.5-0.4l5-3.7c0.4-0.3,0.8-0.5,1.2-0.7l-0.1-0.1
	c-1.9-1.8-3.9-3.3-6.1-4.4C216.7,341.2,216.5,341.8,216.2,342.4L216.2,342.4z M226.4,349.9c1.5,2.5,2.3,5.2,2.8,8.3l1.1-0.3
	l11.6-4.3c-0.6-2.8-1.4-5.2-2.5-7.7l-11.8,3.4C227.3,349.6,226.8,349.8,226.4,349.9L226.4,349.9z"
          />
          <path
            fill="none"
            d="M222,355.6l0.3,0.7c0.1,0.7,0.7,1.1,1.5,1.2l3.6,0.7l1.8,0.1c-0.3-3-1.2-5.6-2.8-8.3c-0.6,0.3-1,0.7-1.4,1.1
	l-2.5,2.6C222,354.2,221.9,354.9,222,355.6L222,355.6z M229.2,364.1c-0.3,2.5-1,4.7-2.1,6.9l13.8,1.5c1.2-3.6,1.8-7.4,1.8-11.4v-1.6
	l-9.2,3.6c-1.2,0.5-2.3,0.8-3.4,1.1L229.2,364.1L229.2,364.1z"
          />
          <path
            fill="none"
            d="M221.5,364.1c-1.2-0.3-1.8,0.3-1.9,1.2c-0.3,0.6,0,1,1.1,1.5l5.1,3.6c0.3,0.1,0.4,0.3,0.6,0.4l0.8,0.1
	c1.1-2.2,1.8-4.4,2.1-6.9L221.5,364.1L221.5,364.1z M213.6,361.1c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5s1.2,1.2,2.2,1.4
	c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4c0.8-0.5,1.2-1.2,1.4-2.2c0.1-0.8,0-1.6-0.4-2.3l-0.1-0.1c-0.6-0.8-1.2-1.2-2.2-1.4
	C215.3,360.4,214.5,360.5,213.6,361.1L213.6,361.1z M217.4,364.1c-0.1,0.4-0.3,0.8-0.7,1.1l-0.4,0.1l-1,0.1c-0.4,0-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.4-0.8-0.3-1.2c0-0.5,0.3-0.8,0.7-1.1c0.4-0.3,0.8-0.4,1.2-0.3c0.4,0.1,0.8,0.3,1.1,0.7
	C217.2,363.3,217.4,363.7,217.4,364.1L217.4,364.1z"
          />
          <path
            fill="none"
            d="M217.1,362.8c-0.3-0.4-0.7-0.5-1.1-0.7c-0.4-0.1-0.8,0-1.2,0.3s-0.6,0.7-0.7,1.1c-0.1,0.4,0,0.8,0.3,1.2
	c0.3,0.4,0.7,0.7,1.1,0.7l1-0.1l0.4-0.1c0.4-0.3,0.6-0.7,0.7-1.1C217.4,363.7,217.2,363.3,217.1,362.8L217.1,362.8z M245.1,354.2
	l-0.4,0.1h0.4 M245.3,354.2h-0.1 M245.4,356.1l-2.2,2.9l-0.7,0.3v1.6c0,4.1-0.6,7.8-1.8,11.4c-1.2,3.9-3.2,7.4-5.9,10.7l0.4,0.7v3.6
	c6.9-7.4,10.3-16.2,10.3-26.4C245.7,359.4,245.7,357.8,245.4,356.1L245.4,356.1z M234,388.8h0.1 M234.1,388.8H234H234.1v0.1"
          />
          <path
            fill="none"
            d="M234,388.8l-7.7-11.7c-0.4-0.8-1.1-1.5-1.8-2.2v-0.1c-0.8-0.7-1.6-1.2-2.6-1.8l-6.9-4.1l-1.6,0.1l-0.3,0.1
	l-0.3,0.3c-0.3,0.4-0.6,0.8-0.6,1.5l1.8,7.8c0.3,1.1,0.6,2.2,1,3v0.1c0.4,0.8,1,1.6,1.5,2.2l8.7,11l-0.1-2.8l-0.4-0.5l-7.6-9.8
	l-0.6-1c-0.3-0.5-0.4-1.1-0.6-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.3l0.3-0.3h1.9l3.3,1.5c0.6,0.3,1.1,0.5,1.4,1l0.8,1
	l6.9,10.2l0.4,0.7L234,388.8L234,388.8z"
          />
          <path
            fill="none"
            d="M231.1,387.3c-2.1,1.9-4.3,3.4-6.5,4.8l0.4,0.5l0.1,2.8c3.2-1.7,6.1-3.9,8.8-6.6l-2.5-1L231.1,387.3
	L231.1,387.3z M215.6,376.1l0.4,3.6c0.1,0.7,0.3,1.2,0.6,1.8c2.2-1.1,4.4-2.6,6.2-4.4l0.7-0.7c-0.4-0.4-0.8-0.7-1.4-1l-3.3-1.5h-1.9
	l-0.3,0.3l-0.3,0.3C215.6,374.7,215.4,375.2,215.6,376.1L215.6,376.1z M227.1,371l-0.8-0.1l0.6,0.5 M223.1,395.9l0.3,0.3 M206.2,302
	c10.2,0,19.3,2.2,27.6,6.7c5.2,2.9,10,6.5,14.6,11.1c8.8,9.1,14.3,19.4,16.4,31.2c0.3,1.5,0.4,3,0.4,4.5h6.7c0-1.5-0.1-3-0.1-4.5
	c-0.1-1.8-0.3-3.6-0.5-5.2c-1-5-2.6-9.8-4.8-14.3c-3.2-6.5-7.7-12.4-13.3-17.7c-1.9-1.8-3.9-3.4-5.9-5c-11.7-8.8-25.3-13.2-41-13.2
	c-15,0-28.1,4.1-39.5,12.2l5.8,5c2.1-1.5,4-2.8,6.2-3.9C186.9,304.2,196.2,302,206.2,302L206.2,302z"
          />
          <path
            fill="none"
            d="M166.7,307.7l-1.5,1.1c-2.1,1.5-4,3.2-5.9,5l-0.1,0.1c-1.4,1.4-2.8,2.6-4,4c-6.6,7.3-11,15.4-13.3,24.2
	c-0.8,3-1.4,6.2-1.6,9.5l-0.7,9.8l-0.6,9.9l7.2,1l0.7-10l0.4-5.5c1.1-12.4,5.6-23.4,13.6-32.9l3.6-4c1.4-1.5,2.9-2.8,4.3-4
	c1.4-1.1,2.6-2.2,4-3.2L166.7,307.7L166.7,307.7z"
          />
          <path
            fill="none"
            d="M139.6,361.3l-12.2-1.2c-9.2-0.1-21.5-1.5-36.7-4.1c0.4,2.3,1.2,4.3,2.6,5.9l0.8,0.8c1.9,1.8,4.5,3,8,3.6
	l4.4,0.5l21.9,2.8h0.1l8.7,1.1l1.9,0.3L139.6,361.3L139.6,361.3z M82.9,349.1l-0.3-2.1h-1.9l0.3,2.1"
          />
          <path
            fill="none"
            d="M79.8,350.6c0.4,1.6,0.1,3.2-1.1,4.7c-0.3,3,0.3,7,1.7,12.1c0.4,0.8,1.5,1.8,3.2,2.6c3-3,5.4-4.5,7-4.4
	l-3.3-6.7h2.2l-1.5-2.2c-2.2-3.9-3.2-4.1-2.9-0.7c0.3,2.3,1.8,4.8,4.3,7.7c-2.5-2.8-3.9-5.4-4.3-7.7c-0.3-3.4,0.7-3.3,2.9,0.7
	l1.5,2.2c1.2,1.5,2.3,2.5,3.7,3.2c-1.4-1.6-2.2-3.6-2.6-5.9l-1.1-6.7v-0.3h-8.3C80.2,349.2,79.8,349.6,79.8,350.6L79.8,350.6z"
          />
          <path
            fill="none"
            d="M85,347h-1.4l0.3,2.1h1.4 M86.8,348.4l0.3,0.7h2.3l-0.1-0.7 M93.1,362c-1.2-0.7-2.5-1.6-3.7-3.2h-2.2l3.3,6.7
	l2.8,5.6h13.1v-4.1l-4.4-0.5c-3.4-0.7-6.1-1.8-8-3.6L93.1,362L93.1,362z M134.4,374.3c1.1-1,2.1-2.1,2.6-3.3l-8.7-1.1h-0.1
	c0.1,0.8-0.7,1.4-2.6,1.4h-12c0.6,1.1,1.5,2.1,2.6,3c2.5,2.2,5.5,3.3,9.1,3.3C128.8,377.6,131.8,376.5,134.4,374.3L134.4,374.3z"
          />
          <path
            fill="none"
            d="M128.2,369.9l-21.9-2.8v4.1h19.1C127.5,371.1,128.3,370.7,128.2,369.9L128.2,369.9z M476.4,208.8l-4.8,2.5
	l-1,0.3c-4,1.2-9.9,1.4-17.7,0.6c8.4,1.9,15.4,4.4,20.9,7.4c16.4,8.5,29.9,16.2,40.4,23.1l2.8,0.4c4.1,1.4,7.3,3,9.4,5
	c2.5,2.2,3.9,4.4,4.1,6.3c4.1,3.4,7.3,6.6,9.5,9.5c0.4,0.5,0.8,1.1,1.2,1.8c14-0.8,27.2-1.9,39.2-3.2l1.4-0.1l-2.9-1.6l-14.3,1.2
	c-6.7,0-13.5-2.3-20.1-7.3c-21.6-14.9-44-28.1-67.3-39.8l5.8-3.2L476.4,208.8L476.4,208.8z"
          />
          <path
            fill="none"
            d="M477.6,208.2c-11.3-4.7-34.2-7.7-68.8-9.1c-38.5-1.6-74.7-2.1-108.4-1c-4.3,0.3-8.3,0.8-11.8,1.5l0.3,0.7
	l0.3-0.1l-0.3,0.1l0.8,3c22.6-2.5,46.5-3.2,71.9-2.1c55.2,1,91.8,4.3,110.1,10.2l4.8-2.5L477.6,208.2L477.6,208.2z M477.1,214.8
	c23.2,11.7,45.7,24.9,67.3,39.8c6.7,5,13.5,7.3,20.1,7.3l14.3-1.2c-32.5-18.6-64.5-34.9-95.9-49L477.1,214.8L477.1,214.8z
	 M508.3,249.9c-1,6.1-0.4,9.6,1.6,10.9c0.5,0.3,1.1,0.4,1.9,0.7l5.8,0.4l4.7,1.1c3.2,0.5,5.4,0.4,6.6-0.1c0.8-0.4,1.4-1.2,1.5-2.8
	c-2.1-1.1-7-1.9-15-2.6c-3,0-5-1.2-5.6-3.9l-0.1-0.7v-3.3c0.1-3.2,1-5.2,2.1-6.3c-0.4,0.1-1,0.4-1.2,0.7c-0.8,1-1.5,2.6-2.1,5.1
	L508.3,249.9L508.3,249.9z"
          />
          <path
            fill="none"
            d="M508.4,249.1c-0.7-2.2-1-3.7-1-4.7c-19.3-13.6-37.3-22.6-53.9-27.1c-14.9-2.1-35.2-3.3-60.9-3.4h-35.1l3.4,44
	l151.2,5.1l-0.4-1.6c-0.8-0.1-1.4-0.4-1.9-0.7c-2.1-1.1-2.5-4.7-1.6-10.9L508.4,249.1L508.4,249.1z"
          />
          <path
            fill="none"
            d="M509.5,252.8l0.1,0.7l13.9,0.7c1.5,0.4,1.9-0.3,1.2-2.2c-0.4-1.1-1.5-1.6-3.6-1.6l-11.6-0.8
	C509.5,250.6,509.5,251.7,509.5,252.8L509.5,252.8z M508.4,249.1c0.5-2.5,1.2-4.1,2.1-5.1c0.4-0.3,0.8-0.6,1.2-0.7h-3.9
	c-0.3,0.1-0.4,0.6-0.4,1.1C507.5,245.4,507.7,246.9,508.4,249.1z"
          />
          <path
            fill="none"
            d="M507.9,243.3h3.9c0.7-0.3,1.5-0.6,2.5-0.6c-10.6-6.9-24.1-14.6-40.4-23.1c-5.5-2.9-12.5-5.4-20.9-7.4l-31.2-2.1
	c-21.5-0.6-42.9-0.7-64.4-0.6l0.3,4.3h35.1c25.7,0.1,46.1,1.2,60.9,3.4c16.6,4.5,34.7,13.5,53.9,27.1
	C507.5,243.7,507.6,243.3,507.9,243.3L507.9,243.3z M288.7,200.2c-8.1,1.6-16,3.4-23.8,5.2l1.9,2.9c8.5-2.3,16.1-4,22.7-5.2
	L288.7,200.2z"
          />
          <path fill="none" d="M347.5,257.5l13.5,0.4l-3.4-44l-0.3-4.3h-10.9" />
          <path fill="none" d="M344.9,259l2.8,0.1l-0.1-1.6l-1.1-47.9h-2.6" />
          <path
            fill="none"
            d="M298.6,211.7c-5.5,1-11,2.2-16.2,3.9l-19.7,7c-6.9,3-9.1,5.9-6.6,8.7c11,9.6,20.6,16.6,28.6,21l4.5,2.2
	c3.7,1.5,8.3,2.5,13.5,2.9l42.1,1.6l-1.1-49.4c-10.3,0.1-20.8,0.3-31.1,0.6C308,210.4,303.3,211,298.6,211.7L298.6,211.7z
	 M334.4,220.7l1,28.6c-0.3,5.2-3.7,7.7-10.7,7.6c-16.8,0-29-1.6-36.6-4.8c-13.9-7-22.8-13.2-27-18.4c-2.1-3.4,3.6-7.2,16.9-11.1
	c12.8-4.4,28.8-6.9,47.6-7.6C331,214.7,333.9,216.6,334.4,220.7L334.4,220.7z"
          />
          <path
            fill="none"
            d="M325.6,214.8c-18.8,0.6-34.7,3-47.6,7.6c-13.3,4-19,7.7-17.1,11.1c4,5.2,12.9,11.4,27,18.4
	c7.6,3.3,19.8,4.8,36.6,4.8c6.9,0.1,10.5-2.3,10.7-7.6l-1-28.6C333.9,216.6,331,214.7,325.6,214.8L325.6,214.8z M511.7,243.3
	c-1.2,1.1-1.9,3.2-2.1,6.3l11.6,0.8c1.9,0,3.2,0.6,3.6,1.6c0.7,1.9,0.3,2.6-1.2,2.2l-13.9-0.7c0.7,2.6,2.5,4,5.6,3.9
	c7.8,0.7,12.9,1.6,15,2.6c0.3-1.4,0.3-3,0-5.4v-0.4c-0.3-1.9-1.7-4.1-4.1-6.3c-2.1-1.9-5.2-3.6-9.4-5l-2.8-0.4
	C513.2,242.8,512.6,242.9,511.7,243.3L511.7,243.3z"
          />
          <path
            fill="none"
            d="M517.5,261.7l-5.8-0.4l0.4,1.6l27.7,1c-2.2-2.9-5.2-6.1-9.5-9.5v0.3c0.3,2.2,0.1,4,0,5.4
	c-0.3,1.4-0.8,2.3-1.5,2.8c-1.2,0.5-3.4,0.7-6.6,0.1L517.5,261.7L517.5,261.7z M539.8,263.9l-27.7-1L361,257.9l-13.5-0.4v1.6
	l193.4,6.5C540.6,265.2,540.2,264.5,539.8,263.9L539.8,263.9z M540.9,265.7l-193.4-6.5l-3.6,10.2l200.3,8
	C543.8,272.6,542.7,268.6,540.9,265.7L540.9,265.7z"
          />
          <path
            fill="none"
            d="M544.2,277.4l30.4,1.4c38.8,1.9,72.4,4.8,100.8,8.7l2.9,0.3l0.8,0.1l17.9,1.8c-4.7-3.9-11.7-6.9-21.3-9.1
	c-18.6-3.7-39.2-6.6-61.9-8.9c-15.1-1.6-26.3-4.5-33.7-8.9c-12.1,1.2-25.2,2.3-39.2,3.2C542.7,268.6,543.8,272.6,544.2,277.4
	L544.2,277.4z"
          />
          <path
            fill="none"
            d="M581.6,262.4l-1.4,0.1c7.4,4.3,18.7,7.3,33.7,8.9c22.7,2.3,43.3,5.2,61.9,8.9c9.6,2.2,16.6,5.2,21.3,9.1
	l4.3,1.9c4.1,1.8,8.5,3.2,12.9,4.3c4.4,1.4,8.5,2.5,12.4,3.3l7.6,1.2c4.3,0.5,8,0.5,11.3,0.3c-8.8-9.2-19.8-16.1-32.9-20.5
	c-25.6-8.7-61.1-15.7-106.5-20.9c-7.2,1.1-14.7,2.2-22.8,3c32.1,2.5,60.8,5.8,86.4,10c24.5,4.7,42,10.3,52.1,17.2
	c-10.3-6.9-27.7-12.7-52.1-17.2c-25.6-4.3-54.3-7.6-86.4-10L581.6,262.4L581.6,262.4z M576.9,316.8c10.6-9.2,23.1-13.8,37.6-13.8
	c16.4,0,30.3,5.9,42,17.9c8.8,8.9,14.2,19.4,16.4,31.2l0.1,1.9c0.8,5.6,1.2,12.5,1.4,20.5v6.2l49.7-2.2l13.9-2.2
	c8.4-0.1,16.1-0.7,23.1-1.6l3.3-0.4c0.8-0.1,1.4-0.4,1.7-0.8h-77.2c-3.6,0.1-5.9-1.6-7-5.2c-0.3-8.7-1.2-16-2.6-21.9
	c-2.5-11.8-8.4-22.4-18-31.5c-12.9-12.1-28.5-18.3-46.6-18.3s-33.7,6.1-46.5,18.3l-4,4.1c-8.8,9.8-13.8,21-15,33.7l-0.5,6.9h7.2
	v-1.7c1.1-12.4,5.6-23.4,13.5-32.9c1.1-1.4,2.3-2.8,3.6-4C574.1,319.4,575.6,318,576.9,316.8L576.9,316.8z"
          />
          <path
            fill="none"
            d="M572.7,320.8c-1.2,1.4-2.5,2.6-3.6,4c-7.8,9.5-12.4,20.5-13.5,32.9v19.8l9.4-0.3l-1-3.2
	c-1.1-4.1-1.7-8.5-1.7-13.2c0-11.4,3.3-21.5,9.9-30.3c1.5-2.1,3.2-4,5.1-5.8c1.5-1.5,3.2-3,4.8-4.3c8.9-7.2,19.4-10.6,31.2-10.6
	c12,0,22.3,3.6,31.4,10.6l4.8,4.3c2.3,2.3,4.4,4.8,6.2,7.3c5.9,8.4,8.8,18,8.8,28.8v2.1c-0.1,4.1-0.7,8-1.8,11.7l11.6-0.3
	c-0.1-8.1-0.5-15-1.4-20.5l-0.1-1.9c-2.2-11.8-7.6-22.3-16.4-31.2c-11.6-11.8-25.6-17.9-42-17.9c-14.4,0-27,4.5-37.6,13.8
	C575.6,318,574.1,319.4,572.7,320.8L572.7,320.8z"
          />
          <path
            fill="none"
            d="M572.3,330.8c-6.6,8.7-9.9,18.8-9.9,30.3c0,4.7,0.5,9.1,1.7,13.2l1,3.2c1.7,5,4.1,9.6,7.3,13.9l1.9,2.5l3.2,3.4
	c8.4,8.4,18.3,13.2,29.6,14.6l5.2,0.4l7.4-0.3c11.6-1.2,21.5-6.2,30-14.7c2.3-2.3,4.4-4.8,6.2-7.3c3.3-4.7,5.6-9.6,7-15
	c1-3.7,1.7-7.6,1.8-11.7v-2.1c0-10.7-2.9-20.4-8.8-28.8c-1.8-2.6-3.9-5-6.2-7.3l-4.8-4.3c-8.9-7.2-19.4-10.6-31.4-10.6
	c-11.8,0-22.3,3.6-31.2,10.6c-1.7,1.4-3.3,2.8-4.8,4.3C575.6,326.8,573.8,328.7,572.3,330.8L572.3,330.8z M578.5,343.7l1-1.8
	c1.8-3,3.9-5.8,6.5-8.5c5.8-5.8,12.5-9.4,20.2-10.7l1.9-0.3l5.4-0.3l5.6,0.4l1.9,0.3c7.6,1.4,14.2,5,19.9,10.7
	c2.8,2.8,5,5.6,6.7,8.8l1,1.8c1.7,3.3,2.8,6.7,3.3,10.5l0.3,1.9c0.1,1.5,0.3,3,0.3,4.7c0,10.3-3.6,19.3-10.6,26.7l-1.4,1.4
	c-2.8,2.6-5.6,4.8-8.8,6.5l-1.8,0.8c-5,2.3-10.5,3.4-16.4,3.4c-6.1,0-11.6-1.2-16.6-3.6l-1.8-1c-3.2-1.7-6.1-3.9-8.8-6.6h-0.1
	l0.1-0.1l-1.4-1.4c-6.9-7.4-10.3-16.2-10.3-26.4c0-1.6,0.1-3.3,0.3-5l0.3-1.8V354C575.7,350.5,576.8,347,578.5,343.7L578.5,343.7z"
          />
          <path
            fill="none"
            d="M606.2,322.8v0.4l-0.7,13.2c0,1.1,0,2.2,0.1,3.2v0.3c0,0.3,0.1,0.7,0.1,1.1h2.3c-0.1-0.5-0.4-1.1-0.4-1.6
	c-0.1-1-0.1-1.9-0.1-2.8l0.5-14L606.2,322.8L606.2,322.8z"
          />
          <path
            fill="none"
            d="M606.1,323.1v-0.3l-2.1,2.9v0.7l-0.5,10c-0.1,1.2-0.1,2.3,0,3.6l0.1,0.7v0.3h2.1c0-0.3-0.1-0.7-0.1-1.1v-0.3
	c-0.1-1-0.1-1.9-0.1-3.2l0.7-13.2V323.1z"
          />
          <path
            fill="none"
            d="M596.6,344.8c-1.1-0.5-2.2-0.8-3.4-1.2l-9.6-2.5l-0.7-0.1l-3.4,1.1l13.2,3.4c1,0.3,1.9,0.7,2.9,1.1l0.4,0.3
	c0.7,0.4,1.4,0.8,1.9,1.2l6.6,5l-6.6-5c-0.7-0.5-1.2-1-1.9-1.2l-0.3-0.4c-1-0.4-1.9-0.8-2.9-1.1l-13.2-3.4l-1,1.8l0.5,0.1h0.1
	l12.8,3.6c1,0.3,1.8,0.5,2.6,1l0.1,0.1c0.8,0.4,1.7,1.1,2.5,1.9l6.1,5.4c0.4,0.5,0.5,1.1,0.4,1.5v0.3l-0.1,0.3
	c-0.3,0.4-0.7,0.8-1.4,1l-4.7,0.4h11.3c0.3-0.7,0.7-1.4,1.2-2.1c1-1,2.2-1.5,3.6-1.5c1.2,0,2.3,0.4,3.2,1.2l0.3,0.3
	c0.5,0.5,1,1.2,1.2,2.1h8.4l-1.8-0.1c-0.7-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.7l6.1-5.4
	c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1l13.5-3.9h0.1l-1-1.8h-0.3l-13.2,3.4c-1.1,0.3-2.1,0.7-2.9,1.1l-0.3,0.1
	c-0.8,0.4-1.5,0.8-2.1,1.4l-6.7,5.1v0.3c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.4,1-2.3,1s-1.8-0.3-2.5-1c-0.7-0.7-1-1.4-1-2.3s0.3-1.8,1-2.5
	c0.5-0.4,1-0.7,1.7-0.8l0.8-0.1c1,0,1.7,0.3,2.3,1c0.5,0.7,1,1.4,1,2.2l6.7-5.1c0.7-0.5,1.4-1,2.1-1.4l0.3-0.1
	c0.8-0.4,1.8-0.7,2.9-1.1l13.2-3.4l-3.4-1.1l-0.7,0.1l-9.8,2.5c-1.2,0.3-2.3,0.7-3.4,1.2l-0.7,0.3c-0.4,0.3-1,0.5-1.4,0.8l-5,3.7
	c-0.8,0.8-1.5,1.1-1.9,0.5c-0.8-0.5-1-1.4-0.4-2.5l2.1-5.8l0.3-1.4H621c-0.1,0.4-0.3,1-0.4,1.4l-2.8,7.8l2.8-7.8
	c0.1-0.4,0.3-1,0.4-1.4H619c-0.1,0.4-0.4,1-0.5,1.4l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4
	c-0.3-0.4-0.4-1-0.5-1.4h-2.3c0.1,0.4,0.3,1,0.4,1.4l2.8,8h0.1c1,0.3,1.5,0.8,1.9,1.6c0.4,0.8,0.5,1.6,0.3,2.6s-0.8,1.5-1.7,1.9
	s-1.7,0.4-2.5,0.1c-1-0.3-1.5-0.8-2.1-1.6c-0.3-0.5-0.4-1.1-0.3-1.8l0.1-0.8c0.3-0.8,0.8-1.5,1.7-1.9s1.7-0.5,2.3-0.3l-2.8-8
	c-0.1-0.4-0.3-1-0.4-1.4h-2.1l0.4,1.4l2.1,5.8c0.5,1.1,0.5,1.6,0,1.9c-0.8,0.7-1.7,0.5-2.5-0.4l-5-3.7l-1.2-0.7L596.6,344.8
	L596.6,344.8z M575.2,354.3l-0.3,1.8l0.3,0.1l8.1,3.2h5.9c-0.4-0.1-0.7-0.3-1-0.4l-12.7-4.7H575.2L575.2,354.3z"
          />
          <path
            fill="none"
            d="M577.8,359.3l0.4,0.3h5.2l-8.3-3.3l-0.3-0.1l2.2,2.9 M619,323.1l0.5,13.5c0.1,0.8,0.1,1.8-0.1,2.8
	c-0.1,0.6-0.3,1.1-0.6,1.6h2.3c0-0.3,0.1-0.7,0.3-1v-0.4c0.1-1.1,0.1-2.1,0.1-3.2l-0.8-13.6v-0.1l-1.9-0.3v0.7L619,323.1L619,323.1z
	"
          />
          <path
            fill="none"
            d="M623,325.8L621,323l0.8,13.6c0,1.1,0,2.1-0.1,3.2v0.4c0,0.3-0.1,0.7-0.3,1h2.1V341l0.1-0.7
	c0.1-1.2,0.1-2.5,0-3.6l-0.5-10L623,325.8L623,325.8z M651.6,356.7l-6.1,2.3h-0.1l-1.1,0.5h5.1l0.5-0.3l2.1-2.9l-0.1,0.1"
          />
          <path
            fill="none"
            d="M652.1,356.4l-0.1-1.9l-6.5,2.5l-6.6,2.5c-0.1,0-0.1,0.1-0.3,0.1h5.9l1.2-0.4l6.1-2.3l0.3-0.1L652.1,356.4
	L652.1,356.4L652.1,356.4z"
          />
          <path
            fill="none"
            d="M618.5,361.1v0.3c-0.1,1.2-0.5,2.3-1.5,3.3c-0.8,0.8-1.8,1.2-2.9,1.4h-1.2c-1.1-0.1-2.1-0.6-2.9-1.4l-0.3-0.4
	c-0.5-0.5-0.8-1.2-1-1.9l-0.1-1.2c0-0.5,0-1.1,0.1-1.5h-11.3l-3.3,0.3c-1.2,0.1-2.2,0.3-3.2,0c-0.5,0-1.2-0.3-1.7-0.4h-5.9l4.3,1.6
	c1.1,0.4,2.1,0.7,3,0.8l0.4,0.1l2.3,0.1l8.4-0.3l0.1-0.1c0.5-0.8,1.2-1.2,2.2-1.4c1-0.1,1.8,0,2.5,0.5c0.8,0.6,1.2,1.4,1.4,2.2
	c0.1,1-0.1,1.8-0.7,2.5s-1.2,1.2-2.2,1.4c-0.7,0.1-1.2,0-1.8-0.3l-0.7-0.4c-0.8-0.5-1.2-1.2-1.4-2.2c-0.1-0.8,0-1.6,0.5-2.3
	l-8.4,0.3l-2.3-0.1l-0.4-0.1c-1-0.1-1.9-0.4-3-0.8l-4.3-1.6h-5.2l8.8,3.4c1.2,0.5,2.3,0.8,3.4,1.1l0.7,0.1h7.7
	c1.2-0.3,1.8,0.3,1.9,1.2c0.3,0.5-0.1,1-1.1,1.5l-5.6,4l-0.5,0.5l-0.5,0.5c-0.7,0.8-1.4,1.8-2.2,2.9l-1.2,1.9l-0.8,1.2h2.3l1.2-2.1
	c0.5-1,1.2-1.8,1.9-2.5l0.3-0.3c0.5-0.5,1.1-1,1.8-1.5l6.9-4.7l-6.9,4.7c-0.7,0.4-1.2,1-1.8,1.5l-0.3,0.3c-0.7,0.7-1.4,1.5-1.9,2.5
	l-1.2,2.1h2.5l1-1.4c0.4-0.5,0.8-1.1,1.4-1.5v-0.1c0.8-0.7,1.7-1.2,2.6-1.8l6.9-4.1l1.7,0.1c0.1,0,0.1,0,0.3,0.1l0.3,0.3
	c0.4,0.4,0.5,0.8,0.5,1.5l-1.5,6.9h2.6l1.7-5.6l-0.1-0.1c-0.5-0.8-0.7-1.6-0.5-2.5c0.1-1,0.5-1.6,1.4-2.2c0.8-0.5,1.7-0.7,2.5-0.5
	c1,0.1,1.7,0.5,2.2,1.4c0.6,0.7,0.8,1.6,0.7,2.5c-0.1,0.7-0.4,1.2-0.8,1.6l-0.5,0.5c-0.7,0.5-1.5,0.8-2.5,0.7
	c-0.8-0.1-1.5-0.6-2.1-1.2l-1.7,5.6h2.1l0.7-2.2c0.1-1.2,0.7-1.7,1.8-1.4c0.5-0.1,1,0.4,1.1,1.7l0.7,2.1h2.1l-1.5-5.2l1.5,5.2h2.6
	l-1.5-6.7c0-0.7,0.1-1.1,0.6-1.5l0.3-0.3l0.3-0.1h1.7l6.9,4.1c1.1,0.5,1.9,1.1,2.6,1.8c0.7,0.7,1.2,1.4,1.7,2.2l0.4,0.6h2.5
	l-1.2-1.9c-0.5-0.8-1.1-1.6-1.9-2.5l-0.1-0.1c-0.5-0.7-1.2-1.2-1.9-1.6l-7-4.7h-0.1c-1,0.3-1.8,0.3-2.6-0.3
	c-0.8-0.4-1.4-1.1-1.7-1.9c-0.3-1-0.3-1.8,0.3-2.6c0.4-0.8,1.1-1.4,1.9-1.6c1-0.3,1.8-0.3,2.6,0.1c0.7,0.3,1.1,0.7,1.4,1.2l0.3,0.7
	c0.3,1,0.3,1.8-0.1,2.6c-0.4,0.8-1,1.4-1.8,1.6l7,4.7c0.7,0.5,1.4,1.1,1.9,1.6l0.1,0.1c0.8,0.8,1.4,1.6,1.9,2.5l1.2,1.9h2.2l-1.9-3
	c-0.7-1.1-1.4-2.1-2.2-2.9l-0.4-0.4l-3.2-2.3l-3.2-2.2c-1.1-0.5-1.4-1.2-0.8-2.2c0-0.5,0.7-0.8,1.8-0.5h1.8l3,0.1l3-0.1h0.4l0.4-0.1
	c1.2-0.3,2.3-0.7,3.4-1.1l5.6-2.2l3.6-1.4l0.3-0.1h-5.2l-5,1.9c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3l8.3,0.3l2.8-0.3
	c1-0.3,2.1-0.5,3-1l5-1.9h-5.9c-0.8,0.3-1.5,0.5-2.5,0.7c-1,0.1-2.1,0.1-3.2,0l-6.2-0.5h-8.4C618.3,360,618.5,360.5,618.5,361.1
	L618.5,361.1z"
          />
          <path
            fill="none"
            d="M620.1,378h-2.6l0.8,2.6c0.3,0.8,0.5,1.5,1,2.2l0.1,0.3c0.5,1,1.1,1.8,1.8,2.6l8.7,10.6l0.1,0.1l1.8-0.8
	l-8.8-11c-0.5-0.7-1.1-1.5-1.5-2.3c-0.4-0.8-0.7-1.8-1-3L620.1,378L620.1,378z"
          />
          <path
            fill="none"
            d="M615.5,378l1.7,5.2l0.4,0.7c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.7l0.4,0.5l3.3,1.1l-8.7-10.6
	c-0.7-0.8-1.2-1.6-1.8-2.6l-0.1-0.3c-0.4-0.7-0.7-1.5-1-2.2l-0.8-2.6h-1.8V378L615.5,378z M641.7,387.6V384l-0.4-0.5l-3.3-5.1
	l-0.1-0.4h-2.3"
          />
          <path
            fill="none"
            d="M635.5,378h-2.5l7.4,11.1l1.4-1.4l-0.1-0.1 M585.3,383.2l-0.4,0.5v3.7l6.1-9.5h-2.2 M609.1,378l-0.7,2.5
	c-0.3,0.8-0.5,1.5-1,2.3l-0.1,0.3c-0.4,0.8-1,1.6-1.8,2.6l-8.4,10.2l-0.3,0.3l3.3-1.1l0.4-0.5l6.3-7.8c0.8-1,1.5-1.9,2.1-2.9
	l0.3-0.5c0.3-0.6,0.4-1.1,0.7-1.5l1.1-3.7L609.1,378L609.1,378L609.1,378z"
          />
          <path
            fill="none"
            d="M606.2,378.9c-0.1,1.1-0.5,2.2-1,3v0.1c-0.4,0.8-1,1.6-1.5,2.2l-8.8,11l1.8,1l0.1-0.1l0.3-0.3l8.4-10.2
	c0.7-0.8,1.2-1.8,1.8-2.6l0.1-0.3c0.4-0.8,0.7-1.5,1-2.3l0.7-2.5h-2.6L606.2,378.9L606.2,378.9z M586.3,388.8l7.2-10.9H591l-6.1,9.5
	 M616.7,357.2c-1-0.8-1.9-1.2-3.2-1.2c-1.4,0-2.5,0.5-3.6,1.5c-1,1-1.4,2.2-1.4,3.6l0.1,1.2c0.1,0.7,0.5,1.4,1,1.9l0.3,0.4
	c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.5,2.9-1.4c1-1,1.4-2.1,1.5-3.3v-0.3c0-1.4-0.5-2.5-1.5-3.6L616.7,357.2L616.7,357.2z
	 M613.5,358.2c1.1-0.1,2.1,0.1,2.9,0.5l0.5,0.3l0.5,0.4l0.4,0.5c0.1,0.3,0.1,0.4,0.1,0.7v0.8c0,0-0.1,0.4-0.4,0.5l-0.4,0.5l-0.5,0.4
	c-0.4,0.3-0.7,0.4-1.2,0.5l-1.5,0.3l-1.7-0.1c-0.4-0.1-0.8-0.3-1.2-0.5l-1-0.8l-0.4-0.5l-0.1-0.7v-0.8l0.3-0.7l0.4-0.5l0.5-0.4
	c0.6-0.3,1.1-0.5,1.8-0.7L613.5,358.2L613.5,358.2z"
          />
          <path
            fill="none"
            d="M612.7,358.2c-0.7,0.1-1.4,0.4-1.8,0.7l-0.5,0.4l-0.4,0.6l-0.3,0.5c-0.1,0.3-0.1,0.5,0,0.8l0.1,0.7l0.4,0.5
	l1,0.8c0.4,0.3,0.8,0.4,1.2,0.5l1.7,0.1l1.5-0.3c0.4-0.1,0.8-0.4,1.2-0.5l0.5-0.4l0.4-0.6c0.1-0.3,0.3-0.4,0.4-0.5
	c0.1-0.3,0.1-0.6,0-0.8c0-0.3,0-0.4-0.1-0.7l-0.4-0.5l-0.5-0.4l-0.5-0.4c-0.8-0.4-1.8-0.7-2.9-0.6H612.7L612.7,358.2z M613.7,358.4
	c-0.3,0.3-0.4,0.5-0.7,1l-0.4,1.1h-2.3l0.6-0.8c0.1-0.3,0.4-0.4,0.7-0.5C612.3,358.6,613,358.4,613.7,358.4L613.7,358.4z M617,360.6
	h-1.1v0.6h-0.1v-0.7L617,360.6c0.1-0.1,0.1-0.1,0.1,0v0.5c0,0.1-0.1,0.1-0.1,0.1V360.6L617,360.6z M615.2,360.5c0.1,0,0.3,0,0.3,0.1
	l0.1,0.1v0.4l-0.1,0.1h-1.2l-0.1-0.1v-0.5h0.3c0,0,0.1-0.1,0.3-0.1l0.3,0.1L615.2,360.5L615.2,360.5z M613.8,359.3v-0.8l1.2,0.1
	l1,0.4c0.7,0.4,1.1,0.8,1.2,1.5h-3l-0.1-0.1l-0.1-0.3L613.8,359.3L613.8,359.3z M613.8,360.5v0.7h-0.1v-0.7L613.8,360.5L613.8,360.5
	z M612.6,360.6h-0.3v0.4h1.2v0.1h-1.4l-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1l0.3-0.1l0.4,0.1h0.7v0.1h-1L612.6,360.6L612.6,360.6z
	 M613.4,361.6l0.1,0.1c0.1,0.3,0.1,0.4,0.1,0.8v1c-0.8,0-1.5-0.1-2.1-0.5c-0.6-0.4-1.1-1-1.2-1.5h2.9L613.4,361.6L613.4,361.6z
	 M614.6,362c0.1-0.3,0.1-0.4,0.1-0.7h2.5c-0.1,0.7-0.5,1.2-1.2,1.5c-0.3,0.1-0.7,0.3-1,0.4l-1.2,0.1l0.5-0.8L614.6,362L614.6,362z
	 M611.9,360.6h-1.2v0.1L611.9,360.6v0.3l-0.1,0.1h-1.4l-0.1-0.1h1.2l0.1-0.1h-1.2v-0.3l0.1-0.1l0.3-0.1l0.3,0.1h0.7v0.3L611.9,360.6
	L611.9,360.6z"
          />
          <path
            fill="none"
            d="M613.8,358.4c-0.8,0-1.5,0.1-2.1,0.5c-0.3,0.1-0.5,0.3-0.7,0.6l-0.5,0.8h2.3l0.4-1.1
	C613.3,359,613.4,358.7,613.8,358.4L613.8,358.4z M617,361.2c0.1,0,0.1,0,0.3-0.1v-0.5l-0.1-0.1h-1.4v0.7h0.1v-0.5h1.1V361.2
	L617,361.2z M614.9,360.5l-0.3-0.1c-0.1,0-0.3,0-0.3,0.1h-0.3v0.5l0.1,0.1h1.2l0.1-0.1v-0.4l-0.1-0.1c0,0-0.1-0.1-0.3-0.1
	L614.9,360.5L614.9,360.5z M615,360.6h0.3v0.4h-1.1v-0.4L615,360.6L615,360.6z"
          />
          <path
            fill="none"
            d="M614.4,360.6l-0.1,0.3v0.1h1.1v-0.4h-0.4 M613.9,360.1l0.1,0.3l0.1,0.1h3c-0.1-0.7-0.5-1.1-1.2-1.5l-1-0.4
	l-1.2-0.1c-0.1,0.3-0.1,0.5-0.1,0.8L613.9,360.1L613.9,360.1z M613.7,360.9v0.3h0.1v-0.7h-0.1 M613.5,360.6l-0.7-0.1l-0.4-0.1
	l-0.3,0.1c-0.1,0-0.1,0-0.1,0.1v0.5l0.1,0.1h1.4v-0.1h-1.4v-0.4h1.4L613.5,360.6L613.5,360.6z M613.3,361.5h-2.9
	c0.3,0.5,0.7,1.1,1.2,1.5c0.7,0.4,1.4,0.5,2.1,0.5c0.1-0.3,0.1-0.5,0-1c0-0.3-0.1-0.5-0.1-0.8l-0.1-0.1L613.3,361.5L613.3,361.5z
	 M614.4,362.6l-0.6,0.8l1.2-0.1c0.4-0.1,0.7-0.3,1-0.4c0.7-0.4,1.1-0.8,1.2-1.5h-2.5c0,0.3,0,0.4-0.1,0.7L614.4,362.6L614.4,362.6z
	 M611.9,360.5h-1.2l-0.1,0.1v0.3h1.2l-0.1,0.1h-1.2l0.1,0.1h1.4l0.1-0.1v-0.1h-1.2v-0.1h1.2v-0.3L611.9,360.5L611.9,360.5z
	 M583.7,341.1c1.4-1.9,2.8-3.7,4.5-5.4c4.7-4.7,9.9-7.7,15.8-9.2v-0.7l2.1-2.9v0.3V323c-7.7,1.4-14.4,5-20.2,10.7
	c-2.6,2.6-4.8,5.5-6.5,8.5h0.1l3.4-1.1L583.7,341.1L583.7,341.1z M578.5,343.7h0.1l0.5,0.1"
          />
          <path
            fill="none"
            d="M578.6,343.7l1.7,2.1l0.7,0.3l11.8,3.4c0.4,0.1,0.7,0.3,1.1,0.5c0.5,0.3,1,0.7,1.4,1.1l2.5,2.6
	c0.5,0.5,0.7,1.2,0.5,1.9l-0.3,0.7c-0.1,0.7-0.7,1.1-1.5,1.2l-3.6,0.7l-1.8,0.1l-1.2-0.3l-11.6-4.3l-0.7-0.3l-2.6,0.7l0.4,0.1
	l12.7,4.7c0.8,0.4,1.7,0.7,2.8,0.8c1,0.1,2.1,0.1,3.2,0l8-0.8c0.7-0.3,1.1-0.5,1.4-1l0.1-0.3v-0.3c0.1-0.5,0-1.1-0.4-1.5l-6.1-5.4
	c-0.8-0.8-1.7-1.5-2.5-1.9l-0.1-0.1c-0.8-0.4-1.7-0.8-2.6-1l-12.8-3.6h-0.1L578.6,343.7L578.6,343.7z"
          />
          <path
            fill="none"
            d="M578.5,343.7c-1.7,3.3-2.8,6.7-3.4,10.5l2.6-0.7l0.7,0.3c0.5-2.8,1.4-5.2,2.5-7.7l-0.7-0.3L578.5,343.7
	L578.5,343.7z M648.5,344l-1.8,2.1l-0.7,0.3c1.1,2.5,1.9,5,2.5,7.7l0.7-0.3l2.6,0.7C651.4,350.7,650.3,347.3,648.5,344L648.5,344z"
          />
          <path
            fill="none"
            d="M648.5,344l-13.6,3.9c-1,0.1-1.8,0.5-2.5,1h-0.1c-1,0.4-1.8,1.1-2.5,1.9l-6.1,5.4c-0.3,0.5-0.5,1.1-0.4,1.6v0.3
	l0.1,0.3c0.3,0.4,0.7,0.8,1.4,1l8,0.8c1.2,0.1,2.2,0.3,3.2,0c1-0.1,1.9-0.4,2.8-0.7l6.6-2.5l6.5-2.5l-2.6-0.7l-0.7,0.3l-3.3,1.2H645
	l-8.3,3l-0.3,0.1l-0.8,0.1h-1.8l-1.2-0.3l-2.3-0.4l-0.7-0.3c-0.4-0.3-0.7-0.5-0.8-1l-0.3-0.7c-0.3-0.7,0-1.4,0.5-1.9l0.8-0.8
	l1.7-1.8c0.5-0.5,1-0.8,1.5-1.1c0.3-0.3,0.5-0.4,1-0.4l8.1-2.3l3.9-1.1l0.7-0.3L648.5,344L648.5,344z M648.5,344h0.1H648.5z
	 M647.7,342.2c-1.8-3.2-4-6.1-6.7-8.8c-5.8-5.8-12.4-9.4-19.9-10.7v0.1l2.1,2.9v0.8c5.8,1.6,11,4.7,15.5,9.2
	c1.8,1.8,3.4,3.7,4.7,5.6l0.7-0.1l3.4,1.1L647.7,342.2L647.7,342.2z"
          />
          <path
            fill="none"
            d="M623.2,326.5l0.5,10v3.6l-0.1,0.7c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3l0.7-0.3c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5
	c-1.4-1.9-2.9-3.9-4.7-5.6C634.2,331.2,628.9,328.2,623.2,326.5L623.2,326.5z"
          />
          <path
            fill="none"
            d="M623.2,342.4l-2.1,5.8c-0.5,1.1-0.4,1.9,0.4,2.5c0.4,0.4,1,0.3,1.9-0.5l5-3.7c0.4-0.3,1-0.7,1.4-0.8l-0.3-0.3
	c-1.8-1.8-3.7-3.2-5.9-4.3L623.2,342.4L623.2,342.4z M608.2,322.4l-0.6,14c-0.1,0.8-0.1,1.8,0.1,2.8v0.1c0.1,1,0.5,1.9,1.1,3
	l3.2,7.4c0.4,0.5,0.8,0.8,1.4,1h0.7c0.5-0.1,1.1-0.4,1.4-1l3.2-7.4c0.5-1.1,1-2.1,1.1-3c0.1-1,0.1-1.9,0.1-2.8l-0.8-13.5v-0.5
	l-1.5,2.2v0.8l0.4,12.2l-0.1,1.2c-0.1,0.5-0.3,1.1-0.7,1.6l-1.8,3.2c-0.4,0.7-1,1.1-1.7,1.1H613c-0.7,0-1.2-0.4-1.7-1.1l-1.8-3.2
	c-0.3-0.5-0.5-1.1-0.7-1.6l-0.1-1.1l0.4-12.2v-0.8L608.2,322.4L608.2,322.4z"
          />
          <path
            fill="none"
            d="M609.5,325.6l4-0.3l4.1,0.3v-0.8l1.4-2.2l-5.5-0.4l-5.4,0.3l1.4,2.3 M619,323.1v-0.5"
          />
          <path
            fill="none"
            d="M609.5,325.6l-0.4,12.2l0.1,1.1l4.3-0.4l4.4,0.4l0.1-1.1l-0.4-12.2l-4.1-0.3"
          />
          <path
            fill="none"
            d="M617.2,340.6c0.3-0.5,0.5-1.1,0.7-1.6l-4.4-0.4l-4.3,0.4c0.1,0.5,0.4,1.1,0.7,1.6l1.8,3.2
	c0.4,0.7,1,1.1,1.7,1.1h0.7c0.7,0,1.2-0.3,1.7-1.1L617.2,340.6L617.2,340.6z M603.5,340.1c-0.1-1.2-0.1-2.5,0-3.6l0.5-10
	c-5.9,1.5-11.1,4.5-15.8,9.2c-1.8,1.6-3.2,3.4-4.5,5.4l9.6,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.8,0.4l0.1-0.1c1.9-1.8,3.8-3.3,6.1-4.4
	L603.5,340.1L603.5,340.1z"
          />
          <path
            fill="none"
            d="M603.6,340.7c-2.2,1.1-4.1,2.5-6.1,4.4l-0.1,0.1l1.2,0.7l5,3.7c0.8,0.8,1.7,1,2.5,0.4c0.5-0.3,0.5-1,0-1.9
	l-2.1-5.8L603.6,340.7L603.6,340.7z M595.2,351c-0.4-0.4-1-0.8-1.4-1.1c-1.5,2.5-2.3,5.2-2.8,8.3l1.8-0.1l3.6-0.7
	c0.8-0.1,1.2-0.5,1.5-1.2l0.3-0.7c0.3-0.7,0-1.4-0.5-1.9L595.2,351L595.2,351z M601.3,364.4c0.1,1,0.6,1.6,1.4,2.2l0.7,0.4
	c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.6-0.8,0.8-1.7,0.7-2.5c-0.1-1-0.5-1.6-1.4-2.2s-1.7-0.8-2.5-0.6
	c-1,0.1-1.7,0.5-2.2,1.4l-0.1,0.1C601.3,362.7,601.2,363.5,601.3,364.4L601.3,364.4z M604.5,362.2c0.5-0.1,1,0,1.2,0.3
	c0.4,0.3,0.5,0.7,0.7,1.1c0,0.4,0,0.8-0.3,1.2s-0.7,0.7-1.1,0.7c-0.3,0-0.7,0-1-0.1l-0.4-0.1c-0.4-0.3-0.5-0.7-0.7-1.1
	c0-0.4,0-0.8,0.3-1.2C603.6,362.4,603.9,362.2,604.5,362.2L604.5,362.2z"
          />
          <path
            fill="none"
            d="M603.4,362.8c-0.3,0.4-0.4,0.8-0.3,1.2c0.1,0.4,0.3,0.8,0.7,1.1l0.4,0.1c0.3,0.1,0.5,0.3,1,0.1
	c0.4,0,0.8-0.3,1.1-0.7s0.4-0.8,0.3-1.2c0-0.5-0.3-0.8-0.7-1.1c-0.4-0.3-0.8-0.4-1.2-0.3C603.9,362.2,603.6,362.4,603.4,362.8
	L603.4,362.8z M600.7,365.3c-0.1-1.1-0.7-1.5-1.9-1.2h-7.7c0.3,2.5,1,4.7,2.1,6.9l0.7-0.1l5.6-4
	C600.7,366.4,601.2,365.9,600.7,365.3L600.7,365.3z M591.1,358.2c0.3-3,1.2-5.6,2.8-8.3c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4
	c-1.1,2.5-1.9,5-2.5,7.7l11.6,4.3L591.1,358.2L591.1,358.2z"
          />
          <path
            fill="none"
            d="M590.6,363.9c-1.1-0.3-2.2-0.5-3.4-1.1l-9.2-3.6v1.6c0,4.1,0.5,7.8,1.8,11.4c0.5,1.5,1.1,3,1.8,4.4l8.3-0.1
	l1.2-1.9c0.7-1.1,1.5-2.1,2.2-2.9l0.5-0.5l-0.1-0.4c-1.1-2.2-1.8-4.4-2.1-6.9L590.6,363.9L590.6,363.9z M609.1,350.3H609
	c-0.8-0.3-1.7-0.1-2.3,0.3c-0.8,0.4-1.4,1.1-1.7,1.9l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,2.1,1.6
	c0.8,0.3,1.8,0.3,2.5-0.1c0.8-0.4,1.4-1.1,1.7-1.9c0.3-1,0.3-1.8-0.3-2.6C610.6,351.1,610.1,350.6,609.1,350.3L609.1,350.3z
	 M608.9,355l-1.2,0.1c-0.4-0.1-0.8-0.4-1-0.8c-0.1-0.3-0.1-0.7-0.1-1v-0.4c0.1-0.4,0.4-0.8,0.8-1l1.2-0.1c0.4,0.1,0.8,0.4,1,0.8
	c0.3,0.4,0.3,0.8,0.1,1.2C609.5,354.5,609.3,354.9,608.9,355L608.9,355z"
          />
          <path
            fill="none"
            d="M609.7,354c0.1-0.4,0.1-0.8-0.1-1.2c-0.1-0.4-0.5-0.7-1-0.8l-1.2,0.1c-0.4,0.3-0.7,0.5-0.8,1v0.4
	c0,0.3,0,0.5,0.1,1c0.1,0.4,0.5,0.7,1,0.8l1.2-0.1C609.3,354.9,609.5,354.5,609.7,354L609.7,354z M615.6,353.6c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1,2.5,1s1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3v-0.3c0-0.8-0.4-1.6-1-2.2c-0.7-0.7-1.5-1-2.3-1l-0.8,0.1
	c-0.7,0.1-1.1,0.4-1.7,0.8C615.9,351.8,615.6,352.7,615.6,353.6L615.6,353.6z M618.5,352h0.4c0.4,0,0.8,0.1,1.2,0.5
	c0.3,0.3,0.4,0.7,0.4,1.2c0,0.4-0.1,0.8-0.4,1.2c-0.4,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2
	c0-0.4,0.1-1,0.5-1.2C617.9,352.3,618.2,352.1,618.5,352L618.5,352z"
          />
          <path
            fill="none"
            d="M617.7,352.4c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5
	c0.3-0.3,0.4-0.7,0.4-1.2c0-0.4-0.1-1-0.4-1.2c-0.4-0.3-0.7-0.5-1.2-0.5h-0.4C618.2,352.1,617.9,352.3,617.7,352.4L617.7,352.4z
	 M620.7,367c0.8,0.4,1.7,0.5,2.6,0.3h0.1c0.8-0.4,1.4-1,1.8-1.6c0.4-0.8,0.5-1.6,0.1-2.6l-0.3-0.7c-0.3-0.5-0.7-1-1.4-1.2
	c-0.8-0.4-1.7-0.5-2.6-0.1c-1,0.3-1.5,0.8-1.9,1.6c-0.4,0.8-0.5,1.6-0.3,2.6C619.3,365.9,619.9,366.6,620.7,367L620.7,367z
	 M621.7,362.3l1.2,0.1c0.3,0.1,0.5,0.4,0.7,0.7l0.1,0.4c0.1,0.4,0.1,1-0.1,1.2c-0.3,0.4-0.5,0.7-1,0.8h-1.2c-0.4-0.3-0.7-0.5-0.8-1
	c-0.1-0.4-0.1-1,0.1-1.4C620.8,362.7,621.2,362.4,621.7,362.3L621.7,362.3z"
          />
          <path
            fill="none"
            d="M620.7,363.1c-0.3,0.4-0.3,0.8-0.1,1.4c0.1,0.4,0.4,0.7,0.8,1l1.2,0.1c0.4-0.1,0.8-0.4,1-0.8
	c0.1-0.4,0.3-0.8,0.1-1.2l-0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.7l-1.2-0.1C621.2,362.4,620.8,362.7,620.7,363.1L620.7,363.1z
	 M607.2,369.3c-0.1-0.1-0.1-0.1-0.3-0.1l-1.7-0.1l-6.9,4.1c-1,0.6-1.8,1.1-2.6,1.8v0.1c-0.5,0.4-1,1-1.4,1.5l-8.1,12.2l2.3-1
	l0.5-0.7l6.9-10.2l0.5-0.7l0.3-0.3c0.4-0.4,0.8-0.7,1.4-1l3.3-1.5h1.9l0.5,0.4c0.5,0.4,0.8,1,0.7,1.8l-0.4,3.6
	c-0.1,0.7-0.3,1.2-0.5,1.8l-0.5,1l-7.6,9.8l-0.4,0.5l-0.1,2.8l8.8-11c0.5-0.7,1.1-1.4,1.5-2.2v-0.1c0.4-1,0.7-1.9,1-3l1.8-7.8
	c0-0.7-0.1-1.2-0.6-1.5L607.2,369.3L607.2,369.3z M610.6,372.2l0.1,0.1c0.5,0.7,1.2,1.1,2.1,1.2c1,0.1,1.8-0.1,2.5-0.7l0.5-0.5
	c0.4-0.4,0.7-1,0.8-1.6c0.1-1-0.1-1.8-0.7-2.5c-0.5-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.5,0.5c-0.8,0.5-1.2,1.4-1.4,2.2
	C610,370.5,610.1,371.4,610.6,372.2L610.6,372.2z M612.3,368.9c0.4-0.3,0.8-0.4,1.2-0.3c0.5,0,0.8,0.3,1.1,0.7
	c0.3,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.7-0.4,0.8l-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.3c-0.5-0.1-0.8-0.3-1.1-0.7
	c-0.3-0.4-0.4-0.8-0.3-1.2C611.7,369.6,612,369.2,612.3,368.9L612.3,368.9z"
          />
          <path
            fill="none"
            d="M611.7,370c-0.1,0.4,0,0.8,0.3,1.2c0.3,0.4,0.7,0.5,1.1,0.7c0.4,0,0.8,0,1.2-0.3l0.3-0.3
	c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4,0-1-0.3-1.2c-0.3-0.4-0.7-0.5-1.1-0.7c-0.5-0.1-1,0-1.2,0.3C612,369.2,611.7,369.6,611.7,370
	L611.7,370z M603.5,373.9h-1.9l-3.3,1.5c-0.6,0.3-1.1,0.5-1.4,1l0.3,0.3l0.4,0.4c1.9,1.9,4,3.4,6.2,4.4c0.3-0.5,0.4-1.1,0.6-1.8
	l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8L603.5,373.9L603.5,373.9z M619.7,369.3l-0.3,0.1l-0.3,0.3c-0.4,0.4-0.5,1-0.5,1.5l1.8,7.8
	c0.1,1.2,0.5,2.3,1,3c0.4,0.8,1,1.6,1.5,2.3l8.8,11l-0.1-2.6l-0.4-0.5l-7.6-9.8l-0.5-1c-0.3-0.5-0.4-1.1-0.5-1.8l-0.4-3.6
	c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.1l0.3-0.3l1.9-0.1l3.3,1.5c0.5,0.3,1.1,0.7,1.5,1.1l0.7,0.8l6.9,10.2l0.4,0.7l2.5,1l-7.8-11.7
	c-0.4-0.8-1-1.5-1.7-2.2c-0.7-0.7-1.7-1.2-2.6-1.8l-6.9-4.1H619.7L619.7,369.3z M613.7,374.4c-1.1-0.3-1.7,0.1-1.8,1.4l-1.8,5.9
	c-0.1,0.6-0.4,1-0.7,1.5l4,0.3l3.6-0.3l-2.3-7.3C614.6,374.8,614.2,374.3,613.7,374.4L613.7,374.4z M594,370.8l-0.7,0.1l0.1,0.4"
          />
          <path
            fill="none"
            d="M597.2,376.5l-0.3-0.3l-0.3,0.3 M585.3,383.2c-1.7-2.1-3-4.1-4.1-6.3c-0.7-1.4-1.2-2.9-1.8-4.4
	c-1.2-3.6-1.8-7.4-1.8-11.4v-1.6l-0.7-0.3l-2.2-2.9l0.3,0.1l-0.3-0.1c-0.1,1.6-0.3,3.2-0.3,5c0,10.2,3.4,19.1,10.3,26.4V384
	L585.3,383.2L585.3,383.2z M586.2,389l0.1-0.1L586.2,389z M586.3,388.8c2.8,2.6,5.6,4.8,8.8,6.6l0.1-2.8l0.4-0.5
	c-2.3-1.4-4.4-3-6.5-4.8l-0.5,0.7L586.3,388.8L586.3,388.8z M629.2,353.9c-0.5,0.5-0.8,1.2-0.5,1.9l0.3,0.7c0.1,0.4,0.4,0.8,0.8,1
	c0.1-1.8,0.1-3.2,0.3-4.4L629.2,353.9L629.2,353.9z M633.3,350.2c-0.5,0.3-1.1,0.7-1.5,1.1l-1.7,1.8c-0.1,1.1-0.3,2.6-0.3,4.4
	l0.7,0.1l2.3,0.4v-3c0.1-1.6,0.5-2.8,1.4-3.2c0.3-0.1,0.7-0.3,1.1-0.3c1.1,0.1,1.7-0.3,1.9-1.2c0.1-1.1,1.2-1.6,3.3-1.8h1.9v-1.2
	l-8.1,2.3C633.9,349.8,633.6,349.9,633.3,350.2L633.3,350.2z"
          />
          <path
            fill="none"
            d="M638,352.9c1.7-0.5,2.3-1.5,2.3-3c0-0.8,0.5-1,1.7-0.5c1.4,0.7,2.3,1.5,2.8,2.6l0.3,1.8l0.1,1.4l3.3-1.2
	c-0.5-2.8-1.4-5.2-2.5-7.7l-3.9,1.1v1.2h-1.9c-2.1,0.1-3.2,0.7-3.3,1.8c-0.3,1-1,1.4-1.9,1.2c-0.4,0-0.7,0.1-1.1,0.3
	c0.5,1.1,1,2.2,1.2,3.4h0.5C636.5,353.9,637.2,353.2,638,352.9L638,352.9z"
          />
          <path
            fill="none"
            d="M635.8,355.3h-3v0.8c1.2-0.1,2.2,0,2.8,0.4c0.5,0.3,1,1,1.1,1.8l0.3-0.1l8.3-3l-0.1-1.4l-0.3-1.8
	c-0.4-1.1-1.4-1.9-2.8-2.6c-1.1-0.4-1.7-0.3-1.7,0.5c0,1.5-0.8,2.5-2.3,3C637.2,353.2,636.5,353.9,635.8,355.3L635.8,355.3z"
          />
          <path
            fill="none"
            d="M632.8,355v0.3h2.5c-0.3-1.2-0.7-2.3-1.2-3.4C633.3,352.4,632.9,353.5,632.8,355L632.8,355z M634,358.4h1.8
	l0.8-0.1c-0.1-0.8-0.5-1.4-1.1-1.8c-0.7-0.4-1.5-0.5-2.8-0.4v1.9L634,358.4L634,358.4z M635.7,364.8l0.4-0.4h-0.3"
          />
          <path
            fill="none"
            d="M635.7,364.8c-0.7,0.4-1.7,0.6-2.9,0.4v0.8h3l0.1,0.1c0.7,1.2,1.2,2.1,2.1,2.2c1.7,0.6,2.3,1.5,2.3,3
	c0,0.8,0.5,1,1.7,0.4c1.4-0.7,2.3-1.5,2.8-2.6l0.3-1.8l0.3-2.2l0.3-4.3l-5.6,2.2c-1.1,0.4-2.2,0.8-3.4,1.1l-0.4,0.1L635.7,364.8
	L635.7,364.8z"
          />
          <path
            fill="none"
            d="M635.8,364.4h-3v0.7c1.4,0.1,2.3,0,2.9-0.4L635.8,364.4L635.8,364.4z M635.5,366l-0.1,0.3h0.5l-0.1-0.3"
          />
          <path
            fill="none"
            d="M632.8,366v0.3c0.1,1.8,0.7,2.9,1.7,3.3c0.4-1.1,0.8-2.2,1-3.3l0.1-0.3L632.8,366L632.8,366z"
          />
          <path
            fill="none"
            d="M632.8,365.2v-0.7l-3-0.1c0,2.1,0.3,3.7,0.4,5l3.2,2.3l0.4,0.4c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3l3,0.3l0.7-3.3
	l0.5-2.8h-1.9c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1c-1-0.4-1.4-1.5-1.7-3.3L632.8,365.2L632.8,365.2z"
          />
          <path
            fill="none"
            d="M635.4,366.3c-0.3,1.1-0.5,2.2-1,3.3l0.8,0.1c1.1-0.1,1.7,0.3,1.9,1.2c0.1,1.1,1.2,1.6,3.3,1.8h1.9l-0.5,2.8
	l4.5-0.1c1.4-3.3,2.3-6.7,2.8-10.5l-3.7,0.4c0,0.8-0.1,1.5-0.3,2.2l-0.3,1.8c-0.4,1.1-1.4,1.9-2.8,2.6c-1.1,0.5-1.7,0.3-1.7-0.4
	c0-1.5-0.8-2.5-2.3-3c-0.8-0.1-1.4-1-2.1-2.2L635.4,366.3L635.4,366.3z M628.1,364.4c-1.2-0.3-1.8,0-1.8,0.5c-0.5,1-0.4,1.6,0.8,2.2
	l3.2,2.2c-0.1-1.2-0.3-2.9-0.4-5H628.1L628.1,364.4z M645,353.9l0.1,1.4h0.1 M651.9,356.5h-0.1l-0.1,0.1 M652.1,356.4l-0.3,0.1h0.1"
          />
          <path
            fill="none"
            d="M652.1,356.4l-0.3,0.1L652.1,356.4l-2.2,2.9l-0.7,0.3v1.4c0,1.4,0,2.6-0.1,3.9c-0.4,3.7-1.2,7.2-2.8,10.5
	c-1.2,2.9-2.9,5.6-5,8.1l0.3,0.5v3.7c7-7.4,10.6-16.4,10.6-26.7C652.5,359.4,652.3,357.9,652.1,356.4L652.1,356.4z"
          />
          <path
            fill="none"
            d="M649.2,359.5l-3.6,1.4c0,1.5-0.1,2.9-0.1,4.3l3.7-0.4c0.1-1.2,0.1-2.5,0.1-3.9L649.2,359.5z M645.3,365.2
	c0.1-1.4,0.1-2.8,0.1-4.3L645.3,365.2L645.3,365.2z M645.3,365.2l-0.3,2.2C645.2,366.7,645.3,366,645.3,365.2z M640.4,389.1h0.1
	 M629.9,376.5c-0.4-0.4-1-0.7-1.5-1.1l-3.3-1.5l-1.9,0.1l-0.3,0.3l-0.3,0.1c-0.5,0.4-0.8,1.1-0.7,1.8l0.4,3.6
	c0.1,0.7,0.3,1.2,0.5,1.8c2.3-1.1,4.5-2.6,6.6-4.7L629.9,376.5L629.9,376.5z M637.5,387.5c-2.1,1.9-4.1,3.4-6.5,4.8l0.4,0.5l0.1,2.6
	v0.1c3.2-1.6,6.1-3.9,8.8-6.5l-2.5-1L637.5,387.5L637.5,387.5z M629.8,396.3l-3.3-1.1l-0.5-0.5c-3.9,1.4-8,2.1-12.4,2.1
	c-4.5,0-8.8-0.7-12.8-2.2l-0.4,0.5l-3.3,1.1l-0.1,0.1c5.1,2.5,10.6,3.6,16.6,3.6c5.9,0,11.4-1.1,16.4-3.4L629.8,396.3L629.8,396.3z
	 M596.9,396.1l0.3-0.3 M575.6,354.3l-0.4-0.1v0.1"
          />
          <path
            fill="none"
            d="M567.9,314.7c12.8-12.1,28.3-18.3,46.5-18.3s33.7,6.1,46.6,18.3c9.6,9.1,15.5,19.7,18,31.5
	c1.5,5.9,2.3,13.2,2.6,21.9c1.1,3.6,3.4,5.4,7,5.2h77.2c0.4-0.6,0.4-1.4,0-2.3c-1.5-3.6-3.2-5.6-5-6.2c-2.9,0.3-5.1-0.1-6.6-1.2
	l-2.8-3.3l-1.1-1.5l-0.1-0.1l-1.1-1.8l-0.8-1.5l-0.8-1.8l-0.8-2.3l-0.3-2.2v-1.8l0.1-1.5l0.3-1.8l0.1-1.1l1-3.6l0.5-1.8l0.4-1.5
	l0.5-1.8l1.4-4.8l0.5-1.8l0.4-1.5l0.5-1.8l0.6-2.1c0.4-3.3,1.2-5,2.5-5.1h1.5l-12.8-8.3l-0.3-0.3l-13.3-2.1l-0.4-0.1
	c1.2,0.6,2.6,1.2,3.9,1.9c3.6,1.9,7,4.5,10.5,7.7c0.8,0.8-0.1,1.1-2.6,0.5l-8.7-1.4l-2.5-0.4l-27.4-4.5c-4.5-0.7-7.8-1.8-9.9-3
	c-4.3-2.9-8.3-5.9-12-9.1c-3.3-2.8-6.3-5.6-9.1-8.5c-0.4-0.5-0.4-1-0.4-1.4l0.5-0.5c0.5-0.3,1.5-0.5,2.9-0.5
	c-28.3-3.9-62-6.7-100.8-8.7l-30.4-1.4c1.4,24.5-0.4,51.9-5.1,82h9.2l0.5-6.9c1.2-12.7,6.2-23.9,15-33.7L567.9,314.7L567.9,314.7z
	 M702.8,322.8c0.1-1,0.7-1.4,1.7-1.4C703.6,321.4,703.1,321.8,702.8,322.8l3,1.8l0.4,0.5c0.4,0.5,0.8,1.5,1,2.6l3,15.8l6.1,1.7
	c1.2,0.1,2.5,1.1,3.9,3.2l0.8,1.6l0.4,0.8c0.1,0.4,0.3,0.8,0.4,1.4c0.1,0.5,0.3,1,0.3,1.6c0,0.5,0.1,1.2,0.1,1.8
	c0,0.5,0,1.2-0.1,1.8c0,0.8-0.1,1.5-0.4,2.3v0.4l-0.4,0.7c-1.4,2.2-4.1,2.9-8.4,2.2c-3.7-0.7-5.9-2.9-6.5-6.5v-0.3l-0.1-1.8
	l-0.4-3.7l-0.4-3.6l-2.6-21.9C702.7,324.3,702.7,323.4,702.8,322.8L702.8,322.8z"
          />
          <path
            fill="none"
            d="M703,325.3l2.6,21.9c4.3,0,8.3,0.1,11.8,0.7l2.9,0.5c-1.2-2.1-2.5-3.2-3.9-3.2l-6.1-1.7c-1,0.1-1.5-0.8-1.8-2.6
	l-2.2-15.8l-0.4-0.5l-3-1.8C702.7,323.4,702.7,324.3,703,325.3L703,325.3z"
          />
          <path
            fill="none"
            d="M708.5,341c0.3,1.8,1,2.6,1.8,2.6l-3-15.8c-0.3-1.2-0.5-2.1-1-2.6L708.5,341L708.5,341z M705.8,350.9l0.4,3.7
	l4.5-0.1c3.2,0.1,6.2,0.3,9.1,0.8l2.3,0.4c0-0.7,0-1.2-0.1-1.8l-2.5-0.4c-2.8-0.4-5.8-0.7-8.8-0.7h-4.1h4.1c3.2,0,6.1,0.3,8.8,0.7
	c-0.1-0.6-0.3-1.1-0.4-1.5c-2.9-0.4-5.9-0.7-9.1-0.8L705.8,350.9L705.8,350.9z"
          />
          <path
            fill="none"
            d="M710.1,351c3.2,0.1,6.2,0.3,9.1,0.8l2.6,0.4c-0.1-0.4-0.3-1-0.4-1.4l-0.4-0.8l-2.8-0.5
	c-3.7-0.6-7.8-0.8-12.1-0.7c4.4,0,8.4,0.1,12.1,0.7c-0.3-0.6-0.7-1.1-1.1-1.6c-3.7-0.6-7.6-0.8-11.8-0.7l0.4,3.6L710.1,351
	L710.1,351z M706.2,354.5l0.1,1.8h4.3c3.2,0,6.2,0.3,9.1,0.8v-1.8c-2.9-0.4-5.9-0.7-9.1-0.8L706.2,354.5L706.2,354.5z"
          />
          <path
            fill="none"
            d="M706.5,356.2v0.3c0.5,3.6,2.8,5.8,6.5,6.5c4.3,0.7,7,0,8.4-2.2l-1.8-0.3l-8.3-0.7H708h3.3l8.3,0.7l0.1-1.2
	l-8.9-0.7h-3.3h3.3l8.9,0.7l2.1,0.4c0.1-0.8,0.3-1.6,0.4-2.3l-2.2-0.4c-2.9-0.4-5.9-0.7-9.1-0.8L706.5,356.2L706.5,356.2z
	 M718.4,349.5l2.8,0.6l-0.8-1.6l-2.9-0.5C717.7,348.4,718.1,349,718.4,349.5L718.4,349.5z M721.9,352.1l-2.6-0.4
	c0.1,0.5,0.3,1,0.4,1.5l2.5,0.4C722.1,353.2,722.1,352.7,721.9,352.1L721.9,352.1z"
          />
          <path
            fill="none"
            d="M719.9,355.1v1.8l2.2,0.4c0.1-0.7,0.1-1.2,0.1-1.8L719.9,355.1L719.9,355.1z M719.7,359.4l-0.3,1.2l1.9,0.3
	l0.3-0.7l0.1-0.5 M539.1,359.4c4.8-30.1,6.5-57.5,5.1-82l-200.3-8v0.3c-2.6,13.5-3.7,30-3,49.5c1,13.8,2.9,25.9,5.9,36.6l1.9,6.2
	c1.5,3.3,5.5,5,12.1,4.8l167.8,1.2c3.4,0.4,6.1-0.5,7.8-2.9C537.7,363.7,538.6,361.7,539.1,359.4L539.1,359.4z"
          />
          <path
            fill="none"
            d="M536.6,365c8.7,3.4,10.5,5.1,5.6,4.8l-270.2-1.6v13.6h283.5v-22.4h-16.4C538.6,361.7,537.7,363.7,536.6,365
	L536.6,365z"
          />
          <path
            fill="none"
            d="M528.8,367.9L361,366.7c-6.6,0.1-10.6-1.5-12.1-4.8l-52-1.2c-10.6,0.5-18.8,3-24.6,7.6l270.2,1.6
	c4.8,0.3,2.9-1.4-5.6-4.8C534.8,367.4,532.1,368.3,528.8,367.9L528.8,367.9z M678.3,287.6l-2.9-0.3c-1.4,0-2.3,0.3-2.9,0.5l31.4,5.8
	l-3.4-2.5l-21.3-3.6L678.3,287.6L678.3,287.6z"
          />
          <path
            fill="none"
            d="M672,288.4l26.8,5.2l1.8,0.3l3.6,0.7l2.3,0.4l20.4,4c-3.9-0.8-8-1.9-12.4-3.3c-4.5-1.1-8.8-2.5-12.9-4.3l-1-0.1
	l3.4,2.5l0.4,0.3l-0.4-0.3l-31.4-5.8L672,288.4L672,288.4z M672,288.4c0.8,1.5,2.2,2.5,4.1,2.9l0.7,0.1c7.2,1.8,14.4,2.5,21.9,2.2
	L672,288.4L672,288.4z"
          />
          <path
            fill="none"
            d="M672.4,289.8c2.9,2.9,5.9,5.8,9.1,8.5l19.7,4.1c-1.4-2.8-1.7-5.5-0.5-8.5l-1.8-0.3c-7.3,0.3-14.6-0.4-21.9-2.2
	l-0.7-0.1c-2.1-0.4-3.4-1.4-4.1-2.9C672,288.8,672,289.2,672.4,289.8L672.4,289.8z M679.2,287.7l21.3,3.6l0.8,0.1l-4.3-1.9
	 M704,294.6c-0.8,2.1-1.1,5.4-1,10.2c0.8,0.7,1.7,1.4,2.5,1.9c-0.1-5.4,0.1-9.2,0.8-11.7L704,294.6L704,294.6z"
          />
          <path
            fill="none"
            d="M700.6,293.9c-1.1,3-0.8,5.8,0.6,8.5c0.5,0.8,1.2,1.6,1.9,2.3c0-4.8,0.3-8.1,1-10.2L700.6,293.9L700.6,293.9z
	 M706.4,295.2c1,1.4,1.2,4.4,1.1,8.8l0.1,3.4l21.9-0.1c0-0.3,0.1-0.4,0.3-0.5h-1.1l-9.5-0.1l-5.6-0.1v-8.5l5.8,1.4l7.7,1.8l3.7,5.5
	l13.3,2.1l-8.5-7.4l-1.2-1.1l-7.6-1.2L706.4,295.2L706.4,295.2z"
          />
          <path
            fill="none"
            d="M701.2,302.5l-19.7-4.1c3.7,3.2,7.7,6.2,12,9.1c2.1,1.4,5.4,2.3,9.9,3l27.4,4.5l-18.6-5.5
	c-1.8-0.4-3.3-1-4.7-1.5c-0.7-0.3-1.4-0.7-2.1-1.1c-1-0.5-1.8-1.2-2.5-1.9C702.4,304.1,701.7,303.3,701.2,302.5L701.2,302.5z"
          />
          <path
            fill="none"
            d="M707.6,308v-0.5l-0.1-3.4c0.3-4.5-0.1-7.4-1.1-8.8c-0.7,2.5-1,6.3-0.8,11.7C706.3,307.3,706.9,307.5,707.6,308
	L707.6,308z"
          />
          <path
            fill="none"
            d="M712.3,309.5l18.6,5.5l2.5,0.4l-3-5.8c-0.7-1.1-1-1.8-0.8-2.3l-21.9,0.1v0.5C709,308.5,710.7,309.1,712.3,309.5
	L712.3,309.5z M713.5,305.3l5.2,0.5l-0.4-1c-0.3-0.8-0.4-1.6-0.3-2.5c0-0.7,0.1-1.2,0.4-1.8l0.4-0.8l-5.5,0.6v5L713.5,305.3
	L713.5,305.3z"
          />
          <path
            fill="none"
            d="M719.2,306.6l-0.4-0.7l-5.2-0.5v1.1 M713.5,298.1v2.3l5.5-0.6l0.3-0.4"
          />
          <path
            fill="none"
            d="M719,299.8l-0.4,0.8l4.3,1.1c1,2.2,2.9,3.9,5.6,5h1.1l0.7-0.1l0.4,0.1l-3.7-5.5l-7.7-1.8L719,299.8L719,299.8z"
          />
          <path
            fill="none"
            d="M718.2,302.5h2.3c0.3,0,0.5,0.1,0.7,0.3c0.1,0.3,0.3,0.4,0.3,0.7v0.7c0,0.3-0.1,0.4-0.3,0.7
	c-0.1,0.1-0.4,0.3-0.7,0.3h-2.1l0.4,1l0.3,0.7l9.5,0.1c-2.9-1.1-4.8-2.8-5.6-5l-4.3-1.1C718.4,301.4,718.2,301.9,718.2,302.5
	L718.2,302.5z"
          />
          <path
            fill="none"
            d="M721.5,303.4c0-0.3-0.1-0.4-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-2.3c-0.1,0.8,0,1.6,0.3,2.5h2.1
	c0.3,0,0.5-0.1,0.7-0.3c0.1-0.3,0.3-0.4,0.3-0.7L721.5,303.4L721.5,303.4z M729.5,307.3c0,0.5,0.3,1.2,0.8,2.3l3,5.8l8.7,1.4
	c2.5,0.4,3.3,0.3,2.6-0.5c-3.4-3.2-7-5.6-10.5-7.7c-1.2-0.7-2.6-1.4-3.9-1.9l-0.7,0.1C729.6,306.9,729.5,307,729.5,307.3
	L729.5,307.3z M735.6,301.4l2.1,0.3l2.1,0.3l4.5,3.4l-0.4-2.9h-3h3v-0.3h1.9v-1.6c-3.3,0.4-7,0.3-11.3-0.3L735.6,301.4L735.6,301.4z
	"
          />
          <path
            fill="none"
            d="M744.1,308.8l0.3,0.3l12.8,8.3l-19.5-15.7l-2.1-0.3"
          />
          <path
            fill="none"
            d="M757.2,317.3h3.3l-8.3-8.3h-3.4l-4.5-3.7l-4.5-3.4l-2.1-0.3"
          />
          <path
            fill="none"
            d="M748.8,309.1h3.4l-3.6-6.9h-5v0.3l0.5,2.9 M757.7,320.5c1.5,0,2.2-0.5,2.2-1.8h-4c-1,0.1-1.5,0.4-1.9,1.1
	l-0.1,0.5h3.8L757.7,320.5L757.7,320.5z"
          />
          <path
            fill="none"
            d="M760.5,318.7v-1.4h-4.8c-1.2,0.1-2.1,1.9-2.5,5.1l0.7-2.1l0.1-0.7c0.4-0.7,1-1.1,1.9-1.1h4.5V318.7z"
          />
          <path fill="none" d="M754.5,324.5h1.1l-0.1-4l-1-0.1" />
          <path
            fill="none"
            d="M755.6,324.5h0.8l-0.1-4h-0.8 M753.3,322.4l-0.7,2.1h1.9v-4.1h-0.7"
          />
          <path
            fill="none"
            d="M756.5,324.5h1.1l-0.1-4h-1.1 M754.5,324.5h-1.9l-0.4,1.8h2.3v-0.1l0.7-0.8h4.8v-0.8h-4.4"
          />
          <path
            fill="none"
            d="M755.8,328.7h0.8l-0.1-3.4h-0.8 M754.5,326.1l0.1,1.8l0.5,0.8h0.6l-0.1-3.4h-0.4"
          />
          <path
            fill="none"
            d="M753,326.3l-0.1,1.5h1.7l0.1,0.1l-0.1-1.8v0.1 M751.8,327.8h0.4v-1.5"
          />
          <path
            fill="none"
            d="M752.2,327.8h0.7l0.1-1.5h-0.8 M758.7,325.3v1h1.2v-1 M756.7,325.3h-0.3l0.1,3.4h0.1v-1h1l-0.1-1.5h-0.8"
          />
          <path
            fill="none"
            d="M758,326.3v-1h-1.2v1h0.8 M756.7,327.8v1h1v-1 M758,328.7v-1h-0.3v1"
          />
          <path
            fill="none"
            d="M756.7,328.7h-1.5l-0.6-0.8l-0.1-0.1h-2.8l-0.5,1.6h8.8v-0.7h-2.3"
          />
          <path
            fill="none"
            d="M759.9,328.7v-1h-1.2v1 M752.1,334.4h0.7l0.1-5h-0.7 M755.9,334.4h0.8l-0.1-5h-0.8 M754.8,334.4h1.1l-0.1-5
	h-1.1 M752.7,334.4h2.1l-0.1-5h-1.8"
          />
          <path fill="none" d="M756.7,334.4h1.1l-0.1-5h-1.1" />
          <path
            fill="none"
            d="M749.7,334.4l-0.4,1.6h5.2l0.3-0.3l0.4-0.7h4.8v-0.7h-8"
          />
          <path
            fill="none"
            d="M754.8,337.9l0.4,0.7h0.8l-0.1-3.6h-0.7l-0.4,0.7 M754.8,335.7l-0.3,0.3h-1.8v1.5h1.8l0.3,0.4"
          />
          <path
            fill="none"
            d="M752.7,337.5h-4l-0.4,1.8H760v-0.7h-4.8l-0.4-0.7l-0.3-0.4 M751.9,336v1.5h0.8V336"
          />
          <path
            fill="none"
            d="M756.7,338.6v-1.1h0.1l-0.1-1.5v-1h-0.8l0.1,3.6"
          />
          <path
            fill="none"
            d="M756.7,336h1.2v-1h-1.2 M758.7,337.5v1.1h1.2v-1.1 M756.9,337.5v1.1h1.1v-1.1 M758,337.5v0.8 M756.7,338.6h0.1
	v-1.1h-0.1"
          />
          <path
            fill="none"
            d="M758,337.5l-0.1-1.5h-1.1l0.1,1.5 M759.9,336v-1h-1.2v1 M757,344h-9.9l-0.3,1.8h7.7l0.7-1.1h4.8V344h-1.9
	 M756.9,339.3l0.1,4.7h1.1l-0.1-4.7"
          />
          <path fill="none" d="M756,339.3l0.1,4.7h0.8l-0.1-4.7" />
          <path fill="none" d="M756.2,344l-0.1-4.7h-1.2l0.1,4.7" />
          <path fill="none" d="M752.6,339.3l-0.1,4.7h2.5l-0.1-4.7" />
          <path
            fill="none"
            d="M751.9,339.3l-0.1,4.7h0.7l0.1-4.7 M751.2,329.4l-1.5,5h2.3l0.1-5 M749.3,336l-0.5,1.5h3.2V336"
          />
          <path
            fill="none"
            d="M747.4,342.9l-0.3,1.1h4.7l0.1-4.7h-3.6 M746.7,349H760v-0.8h-4.8l-0.8-1V347h-7.6
	C746.7,347.7,746.7,348.4,746.7,349L746.7,349z"
          />
          <path
            fill="none"
            d="M752.3,347.2v-1.4h-1.7v1.4 M748.6,355.3h4.4l0.8-1h6.2v-0.7h-12.1"
          />
          <path
            fill="none"
            d="M752.3,353.6V349h-1.7v4.7 M750.7,358.6h9.4v-0.7h-6.2l-0.8-1.1h-3.6l1.1,1.8 M750.7,358.6h-0.1l0.1,0.1
	 M751.8,360.2l0.5,0.5v-2.2h-1.7v0.1 M752.3,356.8v-1.5h-1.7v1.5 M754.5,345.8v1.5l0.7,1h1.2l-0.1-3.6h-1.1 M754.5,353.6h2.1
	l-0.1-4.7h-1.9 M756.7,348.3v-1.1h1.2v1.1h0.3l-0.1-3.6H758v1.1h-1.2v-1.1h-0.4l0.1,3.6"
          />
          <path
            fill="none"
            d="M756.7,344.7v1.1h1.2v-1.1 M756.7,348.3h1.2v-1.1h-1.2 M759.9,345.8v-1.1h-1.2v1.1 M759.9,348.3v-1.1h-1.2v1.1
	 M758.7,354.3v1h1.2v-1 M756.6,354.3l0.1,3.6v-1.1h1.2v1.1h0.5l-0.1-3.6H758v1h-1.2v-1"
          />
          <path
            fill="none"
            d="M758,355.3v-1h-1.2v1 M754.7,357.9h2.1l-0.1-3.6h-2.1 M756.5,349l0.1,4.7h1.8l-0.1-4.7 M756.9,362.7h1.8
	l-0.1-4.1h-1.8"
          />
          <path
            fill="none"
            d="M758,357.9v-1.1h-1.2v1.1 M759.9,357.9v-1.1h-1.2v1.1 M758.7,362.7h-2.8c-0.4-0.1-0.8-0.1-1.4-0.4
	c-0.8-0.3-1.7-0.8-2.3-1.5l-0.6-0.5l2.8,3.3c1.5,1.1,3.7,1.5,6.6,1.2c1,0,1.2-0.3,1-1c-0.4-0.8-1.1-1.1-2.1-1.1L758.7,362.7
	L758.7,362.7z"
          />
          <path
            fill="none"
            d="M756,362.7h0.8l-0.1-4.1h-2.1v3.7C755.2,362.4,755.6,362.6,756,362.7L756,362.7z M346.8,355.7l-74.8-0.1
	c0,1.5,0.1,3,0.1,4.7v8c5.9-4.4,14.2-6.9,24.6-7.6l52,1.2L346.8,355.7L346.8,355.7z"
          />
          <path
            fill="none"
            d="M265.5,365.2v17.1l6.6-0.4v-21.6c0-1.5,0-3.2-0.1-4.7h-6.7v0.5C265.5,359.1,265.5,362.2,265.5,365.2
	L265.5,365.2z M289.3,254.6l-4.5-2.2C286.3,253.1,287.8,253.9,289.3,254.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M613.8,358.4c-0.3,0.3-0.5,0.5-0.7,1l-0.4,1.1h-2.3l0.5-0.8
	c0.1-0.3,0.4-0.4,0.7-0.5C612.3,358.6,613,358.4,613.8,358.4L613.8,358.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M612.7,358.2l0.8-0.1c1.1,0,2.1,0.1,2.9,0.5l0.5,0.3l0.5,0.4l0.4,0.5
	c0.1,0.3,0.1,0.4,0.1,0.7v0.8c0,0-0.1,0.4-0.4,0.6l-0.4,0.5l-0.5,0.4c-0.4,0.3-0.7,0.4-1.2,0.5l-1.5,0.3l-1.7-0.1
	c-0.4-0.1-0.8-0.3-1.2-0.5l-1-0.8l-0.4-0.5l-0.1-0.7v-0.8l0.3-0.7l0.4-0.5l0.5-0.4C611.5,358.6,612,358.3,612.7,358.2L612.7,358.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617,361.2v-0.5h-1.1v0.5h-0.1v-0.7h1.4c0.1,0,0.1,0,0.1,0.1v0.5
	L617,361.2L617,361.2z M615,360.6h-0.7l-0.1,0.3v0.1h1.1v-0.4L615,360.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.9,360.5l0.4-0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.1v0.4l-0.1,0.1h-1.2
	l-0.1-0.1v-0.5h0.3c0,0,0.1-0.1,0.3-0.1L614.9,360.5L614.9,360.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M613.9,360.1l-0.1-0.8v-0.8l1.2,0.1l1,0.4c0.7,0.4,1.1,0.8,1.2,1.5h-3
	l-0.1-0.1L613.9,360.1L613.9,360.1z M613.7,360.5h0.1v0.7h-0.1v-0.3L613.7,360.5z M613.5,360.6h-1.2v0.4h1.2v0.1h-1.4l-0.1-0.1v-0.5
	c0,0,0-0.1,0.1-0.1l0.3-0.1l0.4,0.1L613.5,360.6L613.5,360.6z M613.3,361.5l0.3,0.1l0.1,0.1c0.1,0.3,0.1,0.4,0.1,0.8v1
	c-0.8,0-1.5-0.1-2.1-0.5c-0.5-0.4-1.1-1-1.2-1.5L613.3,361.5L613.3,361.5z M614.4,362.6l0.3-0.5c0.1-0.3,0.1-0.4,0.1-0.7h2.5
	c-0.1,0.7-0.5,1.2-1.2,1.5c-0.3,0.1-0.7,0.3-1,0.4l-1.2,0.1h0.1L614.4,362.6L614.4,362.6z M611.9,360.5v0.1h-1.2v0.1h1.2v0.1
	l-0.1,0.1h-1.4l-0.1-0.1h1.2l0.1-0.1h-1.2v-0.3l0.1-0.1l0.3-0.1l0.3,0.1h0.8L611.9,360.5L611.9,360.5z M209.9,360.6
	c0-0.1,0-0.1-0.1-0.1h-1.2v0.1h1.2v0.1h-1.2v0.3h1.4l0.1-0.1h-1.2l-0.1-0.1h1.2L209.9,360.6L209.9,360.6L209.9,360.6z M205.5,361.5
	H203c0.1,0.7,0.6,1.2,1.2,1.5l1.1,0.4l1.2,0.1l-0.6-0.8l-0.3-0.6L205.5,361.5L205.5,361.5z M204.6,361.2v-0.7h-1.4
	c-0.1,0-0.1,0-0.1,0.1v0.5c0,0.1,0.1,0.1,0.3,0.1v-0.5h1.1v0.5h0.1L204.6,361.2L204.6,361.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M203.3,359.1l-0.6,0.4l-0.4,0.5c-0.1,0.3-0.1,0.4-0.1,0.7v0.8l0.3,0.5
	l0.4,0.6l0.6,0.4l1.2,0.5l1.5,0.3l1.6-0.1c0.4-0.1,0.8-0.3,1.2-0.5l1-0.8l0.4-0.5l0.3-0.7c0-0.3,0-0.5-0.1-0.8
	c0-0.3-0.1-0.4-0.3-0.7l-0.4-0.6l-0.6-0.4c-0.6-0.3-1.1-0.5-1.8-0.7l-0.8-0.1c-1.1-0.1-2.1,0.1-2.9,0.6L203.3,359.1L203.3,359.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M205.2,360.6h0.7l0.1,0.3v0.1H205v-0.4L205.2,360.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.2,361.2v-0.5H205c-0.1,0-0.3,0-0.3,0.1l-0.1,0.1v0.4l0.1,0.1h1.2
	L206.2,361.2L206.2,361.2z M207,361.5l-0.3,0.1l-0.1,0.1c-0.1,0.3-0.1,0.4-0.3,0.8v1c0.8,0,1.5-0.1,2.1-0.5s1-1,1.2-1.5L207,361.5
	L207,361.5z M206.8,360.5l1,0.1h0.3v0.4h-1.2v0.1h1.4c0.1,0,0.1,0,0.1-0.1v-0.5c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.3-0.1l-0.4,0.1
	h-0.7L206.8,360.5L206.8,360.5z M206.6,360.5h-0.1v0.7h0.1L206.6,360.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.5,358.4c0.3,0.3,0.6,0.5,0.7,1l0.4,1.1h2.3l-0.4-0.8
	c-0.1-0.3-0.4-0.4-0.7-0.5C208,358.6,207.3,358.4,206.5,358.4L206.5,358.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M206.5,358.4l-1.2,0.1l-1,0.4c-0.7,0.4-1.1,0.8-1.2,1.5h3
	c0.1,0,0.1-0.1,0.1-0.1l0.1-0.3c0.1-0.3,0.1-0.5,0.1-0.8C206.6,359,206.6,358.7,206.5,358.4L206.5,358.4z M96.4,291.6
	c0.6-1.4,1.2-2.5,1.8-3.2c2.5-2.1,4.3-4.1,5.4-5.9c1.9-4.4,3.3-9.9,4.1-16.1 M112.3,267.1h-0.6 M118.2,270.4c0,0.3,0,0.5-0.1,0.8
	l-0.4,1.4 M112.8,272.6h4.8 M112.8,272.6c0-2.3-0.1-4.1-0.6-5.5 M112.3,267.1l1.5,0.3c2.9,0.5,4.3,1.5,4.3,3 M108,265v0.1l-0.3,1.2
	 M108.7,269.4c-0.3,0-0.6,0.7-0.8,2.1c-0.4,1.5-0.6,3.2-0.7,5.2s-0.1,3.9-0.1,5.2c0,1.2,0.1,1.9,0.4,2.2 M107.4,284.3l0.1,0.1
	c0.3,0,0.6-0.7,1-2.1c0.3-1.4,0.6-3.2,0.7-5.2s0.3-3.9,0.1-5.4c-0.1-1.6-0.4-2.3-0.7-2.3 M111.7,267.1c-1.2,0.3-2.2,1.1-3,2.3
	 M107.7,266.4l4,0.7 M120.8,288.1h-0.6 M115.3,280.9h-3 M114.6,283.3c-1.2,1-2.6,1.9-4.4,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M112.3,280.9l0.3-2.9c0.1-2.1,0.3-3.7,0.3-5.4 M114.6,283.3l0.7-2.5
	 M110.3,285.9h-0.1 M110.3,285.9c1-1.2,1.5-2.9,1.9-5.1 M108,290.4h-0.1l-1.1,8.1 M110.2,285.9c-1.8,0.8-3.9,1.4-6.2,1.9
	c-3.7,0.8-6.2,2.1-7.4,3.7 M107.4,284.3c0.6,0.8,1.5,1.4,2.8,1.6 M108,290.4c2.1,0,2.8,2.6,1.9,7.7 M117.6,272.6l-2.3,8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M120.2,288.1h-7.3c-3.4,0.5-8.1,2.1-13.8,4.3c-0.1,0.7,2.6,0.7,8.1,0
	 M123,283.3h-8.4 M120.2,288.1c-4.4,1.4-8.5,2.1-12.2,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M96.6,300.4c0.4-4,1-6.7,1.8-8.1c5.2-2.5,9.8-4,13.6-4.7h9.1 M118.2,270.4
	l10-0.1 M752.3,358.6h-1.7 M752.3,355.3h-1.7 M752.3,345.8h-1.7 M752.3,347.2h-1.7 M750.7,356.8h1.7 M750.7,349h1.7 M750.7,353.6
	h1.7 M752.3,356.8v-1.5 M756.7,357.9l-0.1-3.6 M754.7,357.9l-0.1-3.6 M758.5,357.9l-0.1-3.6 M96.4,291.6c-1,2.2-1.8,5.4-2.8,9.4
	 M756.7,358.6h-2.1 M756.9,362.7l-0.1-4.1 M754.8,362.3c-0.8-0.3-1.7-0.8-2.3-1.5 M754.7,358.6l0.1,3.7 M754.7,357.9h2.1
	 M758.7,362.7l-0.1-4.1 M756.9,362.7h-0.8c-0.4-0.1-0.8-0.1-1.4-0.4 M758.7,362.7h-1.8 M761,364.8c1,0,1.2-0.3,1-1
	c-0.4-0.8-1.1-1.1-2.1-1.1h-1.2 M759.9,357.9h-1.2 M758.5,358.6h1.5v-0.7h-0.1 M756.7,357.9h1.2 M759.9,357.9v-1.1h-1.2v1.1
	 M758,357.9v-1.1h-1.2v1.1 M756.7,358.6h1.8 M758,357.9h0.5 M758.7,357.9h-0.1 M752.3,356.8h0.7l0.8,1.1h0.8 M752.3,358.6h2.3
	 M752.3,360.8v-2.2 M756.5,349h1.8 M756.7,354.3h-0.1 M754.5,349v4.7 M756.5,348.3h0.3 M756.5,349l0.1,4.7 M756.7,344.7h-0.4
	 M752.3,349v4.7 M756.6,353.6h1.8 M758.4,353.6l-0.1-4.7 M754.5,354.3h-0.7l-0.8,1h-0.7 M756.6,354.3h-2.1 M754.5,353.6h2.1
	 M752.3,353.6h2.2 M759.9,354.3h-1.2 M758,354.3h-1.2 M758.4,354.3h0.3 M758,354.3h0.4 M756.7,354.3v1h1.2v-1 M758.7,354.3v1h1.2v-1
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M759.9,354.3h0.1v-0.7h-1.7 M759.9,344.7h-1.2 M759.9,348.3h-1.2
	 M759.9,348.3v-1.1h-1.2v1.1 M758.2,348.3l-0.1-3.6 M758,348.3h0.3 M756.7,348.3h1.2 M758.7,348.3h-0.4 M758.2,349h1.8v-0.7h-0.1
	 M758.1,344.7h0.5 M758.7,344.7v1.1h1.2v-1.1 M758,348.3v-1.1h-1.2v1.1 M758.1,344.7H758 M758,344.7h-1.2 M756.7,344.7v1.1h1.2v-1.1
	 M756.3,344.7l0.1,3.6 M752.3,349h2.2 M754.5,345.8h-2.2 M754.5,349h1.9 M754.5,347.3l0.7,1h1.2 M754.5,347.3v-0.1h-2.2
	 M754.5,345.8v1.5 M756.3,344.7h-1.1l-0.7,1.1 M752.3,345.8v1.4 M750.7,355.3v1.5 M750.7,358.6v0.1 M750.5,358.6h0.1 M750.7,356.8
	h-1.2 M751.8,360.2l0.5,0.5 M746.7,349h4 M750.7,349v4.7 M750.7,345.8h-3.9 M750.7,347.2h-3.9 M750.7,355.3h-2.1 M747.9,353.6h2.8
	 M750.7,345.8v1.4 M752.5,344h-0.7 M751.9,339.3h0.7 M752.1,334.4h-2.3 M751.2,329.4h1 M756.3,320.5l0.1,4 M755.5,320.5l0.1,4
	 M754.5,320.3v4.1 M752.2,327.8h-0.4 M752.7,337.5h-0.8 M752.7,336h-0.8 M757.6,324.5l-0.1-4 M751.8,344h-4.7 M751.9,339.3l-0.1,4.7
	 M748.3,339.3h3.6 M751.9,336h-2.6 M751.9,337.5h-3.2 M751.9,337.5V336 M756.6,329.4l0.1,5 M755.8,329.4l0.1,5 M754.7,329.4l0.1,5
	 M752.1,334.4l0.1-5 M752.9,329.4l-0.1,5 M757.8,334.4l-0.1-5 M757,344h-0.8 M756,339.3h0.8 M756.7,338.6H756 M756.7,335.1h-0.8
	 M756.7,334.4h-0.8 M752.6,339.3h2.2 M752.5,344l0.1-4.7 M755,344h-2.5 M756.2,344h-1.2 M756,339.3l0.1,4.7 M754.8,339.3h1.2
	 M755,344l-0.1-4.7 M756.9,339.3h1.1 M757,344l-0.1-4.7 M758.1,344H757 M758.1,344l-0.1-4.7 M759.9,344.7h0.1V344h-1.9 M759.9,338.6
	h-1.2 M759.9,335.1h-1.2 M758.7,335.1v1h1.2v-1 M756.9,338.6h1.1 M758,337.5h-1.1 M756.7,336h1.1 M758,337.5l-0.1-1.5 M756.7,336
	l0.1,1.5 M756.9,338.6h-0.1 M756.9,337.5v1.1 M756.9,337.5h-0.1v1.1 M758.7,338.6H758 M758,338.6v-0.3 M758,338.4v-0.8 M758,337.5
	v0.8 M757.8,336h0.1v-1 M756.7,335.1v1 M759.9,338.6v-1.1h-1.2v1.1 M758,339.3h2.1v-0.7h-0.1 M759.9,335.1h0.1v-0.7h-2.2 M758,335.1
	h0.7 M758,335.1h-1.2 M757.8,334.4h-1.1 M754.8,337.9v-2.2 M755.9,335.1l0.1,3.6 M754.8,334.4h-2.1 M754.8,335.7l-0.3,0.3h-1.8
	 M752.7,337.5V336 M756,338.6h-0.8l-0.4-0.7 M754.8,337.9l-0.3-0.4h-1.8 M755.9,334.4h-1.1 M755.9,335.1h-0.7l-0.4,0.7 M752.7,334.4
	h-0.7 M756.6,329.4h1.1 M754.7,327.9l-0.1-1.8 M752.2,327.8v-1.5 M753,326.3l-0.1,1.5 M756.7,328.7h-0.1 M755.8,328.7l-0.1-3.4
	 M756.5,325.3l0.1,3.4 M756.7,325.3h-0.3 M756.5,324.5h1.1 M757.7,327.8l-0.1-1.5 M752.9,329.4h1.8 M755.8,328.7h-0.5l-0.6-0.8
	 M754.7,329.4h1.1 M754.7,327.9l-0.1-0.1h-1.7 M756.6,328.7h-0.8 M755.8,329.4h0.8 M752.9,327.8h-0.7 M752.2,329.4h0.7 M759.9,328.7
	h-1.2 M759.9,328.7v-1h-1.2v1 M758.7,328.7H758 M758,328.7v-1h-0.3 M757.7,327.8h-1v1 M756.7,328.7h1.2 M759.9,325.3h-1.2
	 M758,325.3h0.7 M757.6,326.3h0.4v-1 M758,325.3h-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M756.7,325.3v1h0.8 M759.9,325.3h0.1v-0.8h-2.5 M758.7,325.3v1h1.2v-1
	 M752.6,324.5h1.9 M753,326.3h-0.8 M754.5,326.1v0.1H753 M755.6,325.3h-0.4l-0.7,0.8 M756.5,325.3h-0.8 M755.6,324.5h0.8
	 M754.5,324.5h1.1 M757.4,320.5h-1.1 M754.5,320.3h-0.7 M756.3,320.5h-0.8 M755.5,320.5l-1-0.1 M757.4,320.5h0.3
	c1.5,0,2.2-0.5,2.2-1.8 M700.6,293.9c-1.1,3-0.8,5.8,0.6,8.5 M704,294.6c-0.8,2.1-1.1,5.4-1,10.2 M706.4,295.2
	c-0.7,2.5-1,6.3-0.8,11.7 M719.3,299.4l-5.8-1.4v2.3 M681.5,298.3l19.7,4.1 M672.5,287.9l31.4,5.8 M672,288.4l26.8,5.2 M718.8,305.9
	l-5.2-0.5 M713.5,300.4l5.5-0.5 M729.5,307.3l-22,0.1 M719.2,306.6l9.5,0.1 M730.7,306.7l-3.7-5.5l-7.7-1.8 M728.7,306.7h1.1
	 M718.6,300.7l4.3,1.1c1,2.2,2.9,3.9,5.6,5 M718.2,302.5c-0.1,0.8,0,1.6,0.3,2.5 M719.2,306.6l-0.4-0.7 M718.5,305.1l0.3,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M718.5,305.1h2.1c0.3,0,0.5-0.1,0.7-0.3c0.1-0.3,0.3-0.4,0.3-0.7v-0.5
	c0-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.4-0.3-0.7-0.3h-2.3 M718.6,300.7c-0.3,0.5-0.4,1.2-0.4,1.8 M719,299.8l-0.4,0.8 M719.3,299.4
	l-0.3,0.4 M707.5,307.4l0.1,0.5 M707.5,307.4l-0.1-3.4c0.3-4.5-0.1-7.4-1.1-8.8 M713.5,305.3v1.1l5.6,0.1 M713.5,300.4v5 M707.6,308
	c1.4,0.7,3,1.1,4.7,1.5l18.6,5.5 M705.6,306.9c0.7,0.4,1.4,0.7,2.1,1.1 M703.1,304.8c0.8,0.7,1.7,1.4,2.5,1.9 M701.2,302.5
	c0.5,0.8,1.2,1.6,1.9,2.3 M726.8,299l-20.4-3.9 M700.6,293.9l-1.8-0.3 M704,294.6l-3.4-0.7 M706.4,295.2l-2.3-0.5 M701.3,291.5
	l-0.8-0.1 M700.5,291.3l3.4,2.3 M703.9,293.6l0.4,0.4 M698.8,293.6c-7.3,0.3-14.6-0.4-21.9-2.2l-0.7-0.1c-2.1-0.4-3.4-1.4-4.1-2.9
	 M700.5,291.3l-21.3-3.6l-0.8-0.1 M159.4,247.4c-3.4,0.6-6.5,0.6-8.9,0 M266.9,208.5l-1.8-2.9 M334.4,220.7c-0.5-4-3.4-5.9-8.7-5.8
	c-18.8,0.6-34.7,3-47.6,7.6c-13.3,4-19,7.7-17.1,11.1c4,5.2,12.9,11.4,27,18.4c7.6,3.3,19.8,4.8,36.6,4.8c6.9,0.1,10.5-2.3,10.7-7.6
	L334.4,220.7L334.4,220.7z M357.5,213.9h35.1c25.7,0.1,46.1,1.2,60.9,3.4c16.6,4.5,34.7,13.5,53.9,27.1 M482.9,211.8l-5.8,3.2
	c23.2,11.7,45.7,24.9,67.3,39.8c6.7,5,13.5,7.3,20.1,7.3l14.3-1.2 M158.6,237.9l-17.9-36.7l-0.1-0.1l-2.1-4.3v-0.3l0.1-0.1h0.3
	l0.1,0.1l2.1,4.3v0.3l17.7,36.6 M757.7,329.4h2.3v-0.7h-0.1 M109.8,254.3l-16.4-4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M581.6,262.4l-2.9-1.6c-32.5-18.6-64.5-34.9-95.9-49l-6.5-2.9
	 M476.4,208.8l1.2-0.6 M511.7,261.3c-0.8-0.1-1.4-0.4-1.9-0.7c-2.1-1.1-2.5-4.7-1.6-10.9l0.1-0.8 M509.7,249.6c0,1-0.1,2.1,0,3.3
	l0.1,0.7 M511.7,243.3h-3.9c-0.3,0.1-0.4,0.6-0.4,1.1c0,1,0.3,2.5,1,4.7 M476.4,208.8l-4.8,2.5l-1,0.3c-4,1.2-9.9,1.4-17.7,0.6
	 M452.9,212.2c8.4,1.9,15.4,4.4,20.9,7.4c16.4,8.5,29.9,16.2,40.4,23.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M477.6,208.2c-11.3-4.7-34.2-7.7-68.8-9.1c-38.5-1.6-74.7-2.1-108.4-1
	c-4.3,0.3-8.3,0.8-11.8,1.5l0.3,0.7l0.8,3 M471.6,211.4c-18.3-5.8-54.9-9.2-110-10.2c-25.3-1.1-49.4-0.4-71.9,2.1 M452.9,212.2
	l-31.2-2.1c-21.5-0.6-42.9-0.7-64.4-0.6h-10.9 M346.4,209.6h-2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M361,257.9l-3.4-44l-0.3-4.3 M343.8,209.6c-10.3,0.1-20.8,0.3-31.1,0.6
	c-4.7,0.3-9.4,0.8-14,1.5c-5.5,1-11,2.2-16.2,3.9l-19.7,7c-6.9,3-9.1,5.9-6.6,8.7c11,9.6,20.6,16.6,28.6,21 M289.7,203.3
	c-6.6,1.1-14.3,2.9-22.7,5.2c-25.7,7-59.7,19.4-102.1,37.1 M508.4,249.1c0.5-2.5,1.2-4.1,2.1-5.1c0.4-0.3,0.8-0.6,1.2-0.7
	 M511.7,243.3c0.8-0.3,1.5-0.6,2.5-0.6 M514.2,242.8l2.8,0.4c4.1,1.4,7.3,3,9.4,5c2.5,2.2,3.9,4.4,4.1,6.3 M509.7,253.5l13.9,0.7
	c1.5,0.4,1.9-0.3,1.2-2.2c-0.4-1.1-1.5-1.6-3.6-1.6l-11.6-0.8 M530.3,260.1c-0.3,1.4-0.8,2.3-1.5,2.8c-1.2,0.5-3.4,0.7-6.6,0.1
	l-4.7-1.1l-5.8-0.4 M511.7,261.3l0.4,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M530.3,254.4v0.4c0.3,2.2,0.1,4,0,5.4 M540.9,265.7
	c-0.3-0.5-0.8-1.1-1.2-1.8 M539.8,263.9c-2.2-2.9-5.2-6.1-9.5-9.5 M509.7,253.5c0.7,2.6,2.5,4,5.6,3.9c7.8,0.7,12.9,1.6,15,2.6
	 M540.9,265.7c1.7,3,2.8,6.9,3.3,11.7 M512.2,263l27.7,1 M583.3,262.1l-1.7,0.3 M581.6,262.4l-1.4,0.1 M766,373.3
	c-0.3,0.4-0.8,0.7-1.7,0.8l-3.3,0.5c-7.2,1-14.9,1.5-23.1,1.6l-13.9,2.2l-49.7,2.2v-6.2c-0.1-8.1-0.5-15-1.4-20.5l-0.1-1.9
	c-2.2-11.8-7.6-22.3-16.4-31.2c-11.6-11.8-25.6-17.9-42-17.9c-14.4,0-27,4.5-37.6,13.8c-1.5,1.2-2.9,2.6-4.3,4
	c-1.2,1.4-2.5,2.6-3.6,4c-7.8,9.5-12.4,20.5-13.5,32.9v1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M579.6,342.1l3.4-1.1l0.7,0.1 M578.5,343.7l0.7,0.1 M579.1,343.9l-0.5-0.1
	h-0.1 M578.5,343.7l1-1.8 M579.4,341.9l0.1,0.1 M575.6,354.3l12.7,4.7c0.8,0.4,1.7,0.7,2.8,0.8c1,0.1,2.1,0.1,3.2,0l8-0.8
	c0.7-0.3,1.1-0.5,1.4-1l0.1-0.3v-0.3c0.1-0.5-0.1-1.1-0.4-1.5l-6.1-5.4c-0.8-0.8-1.7-1.5-2.5-1.9l-0.1-0.1c-0.8-0.4-1.7-0.8-2.6-1
	l-12.8-3.6H579"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M572.3,330.8c1.5-2.1,3.2-4,5.1-5.8c1.5-1.5,3.2-3,4.8-4.3
	c8.9-7.2,19.4-10.6,31.2-10.6c12,0,22.3,3.6,31.4,10.6l4.8,4.3c2.3,2.3,4.4,4.8,6.2,7.3c5.9,8.4,8.8,18,8.8,28.8v2.1
	c-0.1,4.1-0.7,8-1.8,11.7c-1.5,5.4-3.9,10.3-7,15c-1.8,2.5-3.9,5-6.2,7.3c-8.5,8.5-18.4,13.3-30,14.7l-7.4,0.3l-5.2-0.4
	c-11.3-1.4-21.2-6.2-29.6-14.6l-3.2-3.4l-1.9-2.5c-3.3-4.3-5.8-8.9-7.3-13.9l-1-3.2c-1.1-4.1-1.7-8.5-1.7-13.2
	C562.4,349.6,565.7,339.5,572.3,330.8L572.3,330.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M548.5,359.4l0.5-6.9c1.2-12.7,6.2-23.9,15-33.7l4-4.1
	c12.8-12.1,28.3-18.3,46.5-18.3s33.7,6.1,46.6,18.3c9.6,9.1,15.5,19.7,18,31.5c1.5,5.9,2.3,13.2,2.6,21.9c1.1,3.6,3.4,5.4,7,5.2H766
	 M544.2,277.4l30.4,1.4c38.8,1.9,72.4,4.8,100.8,8.7 M580.2,262.6c-12.1,1.2-25.2,2.3-39.2,3.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M646.1,346.3l0.7-0.3l1.7-2.1 M648.5,344h0.1 M648.6,344l-1-1.8
	 M647.7,342.2l-0.3,0.1 M648.6,344h-0.1 M647.4,342.4l-3.3-1.1l-0.7,0.1 M647.7,342.2c-1.8-3.2-4-6.1-6.7-8.8
	c-5.8-5.8-12.4-9.4-19.9-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M643.4,341.4c-1.4-1.9-2.9-3.9-4.7-5.6c-4.5-4.5-9.8-7.7-15.5-9.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M623.2,326.5l-0.1-0.7l-2.1-3 M629.6,345.4c-0.4,0.3-1,0.5-1.4,0.8l-5,3.7
	c-0.8,0.8-1.5,1.1-1.9,0.5c-0.8-0.5-1-1.4-0.4-2.5l2.1-5.8l0.4-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M618.3,350.2l2.8-7.8c0.3-0.8,0.4-1.5,0.5-2.3v-0.4
	c0.1-1.1,0.1-2.1,0.1-3.2l-0.8-13.6 M623.6,340.8l0.1-0.7c0.1-1.2,0.1-2.5,0-3.6l-0.6-10 M629.6,345.4l0.7-0.3
	c1.1-0.5,2.2-0.8,3.4-1.2l9.8-2.5 M606.1,323.1l0.1-0.3 M604,326.5v-0.7l2.1-3v0.3 M606.1,323.1v0.1l-0.7,13.2c0,1.1,0,2.2,0.1,3.2
	v0.3c0.1,0.8,0.3,1.6,0.7,2.3l2.8,8 M608.2,322.4l1.4,2.3v0.8 M608.2,322.4l-1.9,0.4 M621,322.8l-1.9-0.3 M617.7,325.6v-0.8l1.4-2.2
	 M619,322.5v0.5 M619,323.1v-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M619,322.5l-5.5-0.4l-5.4,0.3 M617.7,325.6l-4.1-0.3l-4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M619,323.1l0.5,13.5c0.1,0.8,0.1,1.8-0.1,2.8c-0.1,1-0.6,1.9-1.1,3
	l-3.2,7.4c-0.4,0.5-0.8,0.8-1.4,1h-0.7c-0.5-0.1-1-0.4-1.4-1l-3.2-7.4c-0.5-1.1-1-2.1-1.1-3v-0.1c-0.1-1-0.1-1.9-0.1-2.8l0.6-14"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.3,338.9c0.1,0.5,0.4,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.7,1.1h0.7
	c0.7,0,1.2-0.3,1.7-1.1l1.8-3.2c0.3-0.5,0.5-1.1,0.7-1.6 M617.9,338.9l0.1-1.1l-0.4-12.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.3,338.9l4.3-0.4l4.4,0.4 M603.6,340.7l-0.1-0.7
	c-0.1-1.2-0.1-2.5,0-3.6l0.6-10 M597.4,345.2l1.2,0.7l5,3.7c0.8,0.8,1.7,1,2.5,0.4c0.5-0.3,0.5-1,0-1.9l-2.1-5.8l-0.4-1.5
	 M609.5,325.6l-0.4,12.2l0.1,1.1 M606.2,322.8c-7.7,1.4-14.4,5-20.2,10.7c-2.6,2.6-4.8,5.5-6.5,8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M583.7,341.1c1.4-1.9,2.8-3.7,4.5-5.4c4.7-4.7,9.9-7.7,15.8-9.2
	 M597.4,345.2c1.9-1.9,4-3.4,6.2-4.5 M604.6,353.1l-6.6-5c-0.7-0.6-1.2-1-1.9-1.2l-0.4-0.4c-1-0.4-1.9-0.8-2.9-1.1l-13.2-3.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M583.7,341.1l9.6,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.8,0.4 M593.9,349.9
	c0.5,0.3,1,0.7,1.4,1.1l2.5,2.6c0.5,0.5,0.7,1.2,0.5,1.9l-0.3,0.7c-0.1,0.7-0.7,1.1-1.5,1.2l-3.6,0.7l-1.8,0.1 M601.8,362
	c-0.5,0.7-0.7,1.5-0.5,2.3c0.1,1,0.5,1.6,1.4,2.2l0.7,0.4c0.5,0.3,1.2,0.3,1.8,0.3c1-0.1,1.7-0.7,2.2-1.4c0.5-0.8,0.8-1.7,0.7-2.5
	c-0.1-1-0.5-1.6-1.4-2.2s-1.7-0.8-2.5-0.6c-1,0.1-1.7,0.5-2.2,1.4L601.8,362L601.8,362z M594,370.8l5.6-4c1.1-0.5,1.5-1.1,1.1-1.5
	c-0.1-1.1-0.7-1.5-1.9-1.2h-7.7 M591.1,358.2l-1.1-0.3l-11.6-4.1 M575.2,356.2l12.4,4.8c1.1,0.4,2.1,0.7,3,0.8l0.4,0.1l2.3,0.1
	l8.4-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M591.2,364.1l-0.7-0.1c-1.1-0.3-2.2-0.5-3.4-1.1l-9.2-3.6 M591.1,358.2
	c0.3-3,1.2-5.6,2.8-8.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M593.9,349.9c-0.4-0.3-0.8-0.4-1.1-0.5l-11.8-3.4 M609,350.3
	c-0.8-0.3-1.7-0.1-2.3,0.3c-0.8,0.4-1.4,1.1-1.7,1.9l-0.1,0.8c-0.1,0.7,0,1.2,0.3,1.8c0.4,0.8,1.1,1.4,2.1,1.6
	c0.8,0.3,1.8,0.3,2.5-0.1c0.8-0.4,1.4-1.1,1.7-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6L609,350.3L609,350.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.7,354c-0.1,0.4-0.4,0.8-0.8,1l-1.2,0.1c-0.4-0.1-0.8-0.4-1-0.8
	c-0.1-0.3-0.1-0.7-0.1-1v-0.4c0.1-0.4,0.4-0.8,0.8-1l1.2-0.1c0.4,0.1,0.8,0.4,1,0.8C609.8,353.2,609.8,353.6,609.7,354L609.7,354z
	 M617.7,352.4c0.3-0.3,0.5-0.4,0.8-0.4h0.4c0.4,0,0.8,0.1,1.2,0.5c0.3,0.3,0.4,0.7,0.4,1.2c0,0.4-0.1,0.8-0.4,1.2
	c-0.4,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2C617.2,353.2,617.4,352.8,617.7,352.4L617.7,352.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M622.2,353.5v0.3c0,1-0.3,1.8-1,2.3c-0.7,0.7-1.4,1-2.3,1
	c-1,0-1.8-0.4-2.5-1c-0.7-0.7-1-1.4-1-2.3c0-1,0.3-1.8,1-2.5c0.5-0.4,1-0.7,1.7-0.8l0.8-0.1c1,0,1.7,0.3,2.3,1
	C621.9,351.8,622.2,352.5,622.2,353.5L622.2,353.5z M616.7,357.2l0.4,0.3c1,1,1.5,2.2,1.5,3.6v0.3c-0.1,1.2-0.5,2.3-1.5,3.3
	c-0.8,0.8-1.8,1.2-2.9,1.4H613c-1.1-0.1-2.1-0.6-2.9-1.4l-0.3-0.4c-0.5-0.5-0.8-1.2-1-1.9l-0.1-1.2c0-1.4,0.6-2.5,1.4-3.6
	c1-1,2.2-1.5,3.6-1.5C614.6,356,615.7,356.4,616.7,357.2L616.7,357.2z M620.7,363.1c0.1-0.4,0.5-0.7,1-0.8l1.2,0.1
	c0.3,0.1,0.5,0.4,0.7,0.7l0.1,0.4c0.1,0.4,0.1,1-0.1,1.2c-0.3,0.4-0.5,0.7-1,0.8h-1.2c-0.4-0.3-0.7-0.5-0.8-1
	C620.4,364.1,620.4,363.5,620.7,363.1L620.7,363.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M623.4,367.1h-0.1c-1,0.3-1.8,0.3-2.6-0.3c-0.8-0.4-1.4-1.1-1.7-1.9
	c-0.3-1-0.1-1.8,0.3-2.6c0.4-0.8,1.1-1.4,1.9-1.6c1-0.3,1.8-0.3,2.6,0.1c0.7,0.3,1.1,0.7,1.4,1.2l0.3,0.7c0.3,1,0.3,1.8-0.1,2.6
	C624.8,366.1,624.3,366.7,623.4,367.1L623.4,367.1z M603.4,362.8c0.3-0.4,0.7-0.5,1.1-0.7c0.5-0.1,1,0,1.2,0.3
	c0.4,0.3,0.5,0.7,0.7,1.1c0,0.4,0,0.8-0.3,1.2s-0.7,0.7-1.1,0.7c-0.3,0.1-0.7,0-1-0.1l-0.4-0.1c-0.4-0.3-0.5-0.7-0.7-1.1
	C602.9,363.7,603.1,363.3,603.4,362.8L603.4,362.8z M595.1,395.5l8.8-11c0.5-0.7,1.1-1.4,1.5-2.2v-0.1c0.4-1,0.7-1.9,1-3l1.8-7.8
	c0-0.7-0.1-1.2-0.5-1.5l-0.3-0.3c-0.1-0.1-0.1-0.1-0.3-0.1l-1.7-0.1l-6.9,4.1c-1,0.4-1.8,1.1-2.6,1.8v0.1c-0.5,0.4-1,1-1.4,1.5
	l-8.1,12.2 M611.7,370c0-0.4,0.3-0.8,0.7-1.1s0.8-0.4,1.2-0.3c0.5,0,0.8,0.3,1.1,0.7c0.3,0.4,0.4,0.8,0.3,1.2c0,0.3-0.1,0.7-0.4,0.8
	l-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.3c-0.5-0.1-0.8-0.3-1.1-0.7C611.7,370.8,611.6,370.4,611.7,370L611.7,370z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M610.8,372.3l-0.1-0.1c-0.5-0.8-0.7-1.6-0.5-2.5c0.1-1,0.5-1.7,1.4-2.2
	c0.8-0.6,1.7-0.7,2.5-0.6c1,0.1,1.7,0.6,2.2,1.4c0.6,0.7,0.8,1.7,0.7,2.5c-0.1,0.7-0.4,1.2-0.8,1.6l-0.5,0.5
	c-0.7,0.6-1.5,0.8-2.5,0.7C612,373.4,611.3,373,610.8,372.3L610.8,372.3z M596.9,376.2c0.4-0.4,0.8-0.7,1.4-1l3.3-1.5h1.9l0.5,0.4
	c0.5,0.4,0.8,1,0.7,1.8l-0.4,3.6c-0.1,0.7-0.3,1.2-0.6,1.8 M640.5,389.1l-7.8-11.7c-0.4-0.8-1-1.5-1.7-2.2c-0.7-0.7-1.7-1.2-2.6-1.8
	l-6.9-4.1h-1.7l-0.3,0.1l-0.3,0.3c-0.4,0.4-0.5,1-0.5,1.5l1.8,7.8c0.1,1.2,0.5,2.3,1,3c0.4,0.8,1,1.6,1.5,2.3l8.8,11 M609.4,383.2
	c0.3-0.5,0.4-1.1,0.7-1.5l1.8-5.9c0.1-1.2,0.7-1.6,1.8-1.4c0.5-0.1,1,0.4,1.1,1.7l2.3,7.3 M617.1,383.2l0.4,0.7
	c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.7 M629.8,396.3l-8.7-10.6c-0.7-0.8-1.2-1.6-1.8-2.6l-0.1-0.3c-0.4-0.7-0.7-1.5-1-2.2l-2.3-8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617.1,383.2l-3.6,0.3l-4.1-0.3 M603.8,381.4l-0.5,1l-7.6,9.8
	 M597.2,395.9l8.4-10.2c0.7-0.8,1.2-1.8,1.8-2.6l0.1-0.3c0.4-0.8,0.7-1.5,1-2.3l2.3-8 M609.4,383.2l-0.3,0.5
	c-0.7,1.1-1.4,2.1-2.1,2.9l-6.3,7.8 M585.3,383.2l4.1-6.5l1.2-1.9c0.7-1.1,1.5-2.1,2.2-2.9l0.5-0.5 M593.5,371.4l-0.1-0.4
	 M603.1,367l-6.9,4.7c-0.7,0.5-1.2,1-1.8,1.5l-0.3,0.3c-0.7,0.7-1.4,1.5-1.9,2.5l-7.3,11.4 M589.2,387.3l6.9-10.2l0.5-0.7l0.3-0.3
	 M603.8,381.4c-2.2-1.1-4.4-2.6-6.2-4.4l-0.4-0.4l-0.3-0.3 M597.2,376.5h-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M585.3,383.2l-0.4,0.7v3.6 M584.9,387.5l1.4,1.4 M586.3,388.8l2.3-1
	l0.5-0.7 M593.5,371.4l0.5-0.5 M593.3,371l0.7-0.1 M593.3,371c-1.1-2.2-1.8-4.4-2.1-6.9 M629.8,357.5c-0.4-0.3-0.7-0.5-0.8-1
	l-0.3-0.7c-0.3-0.7,0-1.4,0.5-1.9l0.8-0.8 M622.2,353.5l6.7-5.1c0.7-0.5,1.4-1,2.1-1.4l0.3-0.1c0.8-0.4,1.8-0.7,2.9-1.1l13.2-3.4
	 M623.6,340.8c2.1,1.1,4,2.5,5.9,4.3l0.3,0.3 M651.9,354.5l-6.5,2.5l-6.6,2.5c-0.8,0.4-1.8,0.7-2.8,0.7c-1,0.1-2.1,0.1-3.2,0l-8-0.8
	c-0.7-0.1-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.6l6.1-5.4c0.8-0.8,1.7-1.5,2.5-1.9h0.1c0.8-0.4,1.7-0.8,2.5-1
	l13.5-3.9 M630,353.1l1.7-1.8c0.5-0.5,1-0.8,1.5-1.1c0.3-0.3,0.5-0.4,1-0.4l8.1-2.3 M645,353.9l-0.3-1.8c-0.4-1.1-1.4-1.9-2.8-2.6
	c-1.1-0.4-1.7-0.3-1.7,0.5c0,1.5-0.8,2.5-2.3,3c-0.8,0.1-1.5,1-2.2,2.3h-0.6 M634.2,352c-0.7,0.4-1.2,1.5-1.4,3.2v0.3 M642.3,347.4
	v1.2h-1.9c-2.1,0.1-3.2,0.7-3.3,1.8c-0.3,1-1,1.4-1.9,1.2c-0.4,0-0.7,0.1-1.1,0.3 M632.8,355.3h2.5 M635.3,355.3
	c-0.3-1.2-0.7-2.3-1.2-3.4 M632.8,358.2l1.2,0.3h1.8l0.8-0.1 M632.8,356.2v1.9 M632.8,356.2c1.2-0.1,2.2,0,2.8,0.4
	c0.6,0.3,1,1,1.1,1.8 M636.6,358.3l0.3-0.1l8.3-3 M635.8,364.4h0.4 M636.1,364.4l-0.4,0.4 M635.7,364.8v-0.3 M636.1,364.4l0.4-0.1
	c1.2-0.3,2.3-0.7,3.4-1.1l5.6-2.2 M636,366.3l-0.1-0.1h-0.3 M635.5,366h-2.8 M632.8,366v-0.8 M634.4,369.6c-1-0.4-1.4-1.5-1.7-3.3
	V366 M635.5,366l-0.1,0.3 M632.8,365.2v-0.7 M651.6,356.6l-6.1,2.3l-6.2,2.3c-1,0.4-1.9,0.7-3,1l-2.8,0.3l-8.3-0.3 M632.8,365.2
	c1.4,0.1,2.3,0,2.9-0.4 M635.4,366.3l0.5-0.1 M635.8,364.4l-3,0.1 M629.8,357.5l0.7,0.3l2.3,0.4 M629.8,364.4H628
	c-1.2-0.3-1.8,0-1.8,0.5c-0.5,1-0.4,1.6,0.8,2.2l3.2,2.2 M630,353.1c-0.1,1.1-0.3,2.6-0.3,4.4 M632.8,355.3v0.8 M632.8,364.5l-3-0.1
	 M645.3,355.3l3.3-1.2 M648.6,354l0.7-0.3l2.6,0.7 M645,353.9l0.1,1.4 M645.3,355.3l-0.1-1.4 M642.3,347.4l3.9-1.1 M646.1,346.3
	c1.1,2.5,1.9,5,2.5,7.7 M651.8,356.5l-0.3,0.1 M651.9,356.5l-0.3,0.1 M641.9,387.7c7-7.4,10.6-16.4,10.6-26.7c0-1.6,0-3.2-0.3-4.7
	 M652.1,356.4l-0.3,0.1 M649.2,359.5l0.7-0.3l2.2-2.9l-0.1,0.1 M651.9,356.5l0.3-0.1 M649,364.8c0.1-1.2,0.1-2.5,0.1-3.9v-1.4
	 M649.2,359.5l-3.6,1.4 M649,364.8l-3.7,0.4 M645.6,361.1l-0.3,4.3 M645.6,361.1c0,1.5-0.1,2.9-0.1,4.3 M652.2,356.4l-0.3-1.9
	 M638,378.4l3,0.3l0.7-3.3l0.5-2.8h-1.9c-2.1-0.1-3.2-0.7-3.3-1.8c-0.3-1-1-1.4-1.9-1.2l-0.8-0.1 M641.7,375.4l4.7-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M645,367.5l-0.3,1.8c-0.4,1.1-1.4,1.9-2.8,2.6c-1.1,0.5-1.7,0.3-1.7-0.4
	c0-1.5-0.8-2.5-2.3-3c-0.8-0.1-1.4-1-2.1-2.2 M641.3,383.5l0.3,0.5v3.6 M640.5,389.1l1.4-1.4 M649,364.8c-0.4,3.7-1.2,7.2-2.8,10.5
	c-1.2,2.9-2.9,5.6-5,8.1 M629.9,376.5l0.7,0.8l6.9,10.2 M622.9,381.6c2.3-1.1,4.5-2.6,6.6-4.7l0.4-0.4 M622.9,381.6
	c-0.3-0.5-0.4-1.1-0.5-1.8l-0.4-3.6c-0.1-0.8,0.1-1.4,0.7-1.8l0.3-0.1l0.3-0.3l1.9-0.1l3.3,1.5c0.5,0.3,1.1,0.7,1.5,1.1
	 M641.7,387.6l-7.4-11.4c-0.5-0.8-1.1-1.6-1.9-2.5l-0.1-0.1c-0.5-0.7-1.2-1.2-1.9-1.6l-7-4.7 M630.2,369.3l3.2,2.3l0.4,0.4
	c0.8,0.8,1.5,1.8,2.2,2.9l2.2,3.3 M637.5,387.5l0.4,0.7l2.5,1 M631,392.3l-7.6-9.8l-0.6-1 M638,378.4l3.3,5.1 M645.3,365.2l-0.3,2.2
	 M635.4,366.3c-0.3,1.1-0.5,2.2-1,3.3 M645.3,365.2c0,0.8-0.1,1.5-0.3,2.2 M629.8,364.4c0,2.1,0.3,3.7,0.4,5 M629.8,396.4l-0.1-0.1
	 M625.9,394.6l0.4,0.6l3.3,1.1 M631.5,395.6l-0.1-2.8l-0.4-0.5 M629.8,396.4l1.8-0.8 M595.7,392.1l-0.4,0.5l-0.1,2.8 M600.7,394.5
	l-0.4,0.5l-3.3,1.1 M596.9,396.1l0.3-0.3 M597.2,395.9l-0.3,0.3 M596.9,396.1l-0.1,0.1 M595.1,395.4l1.8,1 M596.8,396.3
	c5.1,2.5,10.6,3.6,16.6,3.6c5.9,0,11.4-1.1,16.4-3.4 M625.9,394.6c-3.9,1.4-8,2.1-12.4,2.1c-4.5,0-8.8-0.7-12.8-2.2 M586.3,388.8
	c2.8,2.6,5.6,4.8,8.8,6.6 M631.6,395.6c3.2-1.6,6.1-3.9,8.8-6.5 M595.7,392.1c-2.3-1.4-4.4-3-6.5-4.8 M637.5,387.5
	c-2.1,1.9-4.1,3.4-6.5,4.8 M539.1,359.4c-0.5,2.3-1.4,4.3-2.5,5.6 M536.6,365c-1.9,2.3-4.5,3.3-7.8,2.9L361,366.7
	c-6.6,0.1-10.6-1.5-12.1-4.8 M536.6,365c8.7,3.4,10.5,5.1,5.6,4.8l-270.2-1.6 M555.6,359.4h-7.2 M578.5,353.8l-0.7-0.3l-2.6,0.7
	 M578.5,353.8c0.6-2.8,1.4-5.2,2.5-7.7 M580.9,346.1l-0.7-0.3l-1.7-2.1 M577.8,359.3v1.6c0,4.1,0.5,7.8,1.8,11.4
	c0.5,1.5,1.1,3,1.8,4.4c1.1,2.2,2.5,4.3,4.1,6.3 M577.8,359.3l-0.7-0.3l-2.2-2.9l0.3,0.1 M574.9,356.1c-0.1,1.6-0.3,3.2-0.3,5
	c0,10.2,3.4,19.1,10.3,26.4 M575.2,354.3l-0.3,1.8 M575.2,354.3h0.4 M575.2,354.2l0.4,0.1 M574.9,356.1l0.3,0.1 M272.1,381.8h283.5
	v-22.4 M555.6,377.6l9.4-0.3 M548.5,359.4h-9.2 M745.6,300.5c-8.8-9.2-19.8-16.1-32.9-20.5c-25.6-8.7-61.1-15.7-106.5-20.9
	c-7.2,1.1-14.7,2.2-22.8,3 M544.2,277.4c1.4,24.5-0.4,51.9-5.1,82 M578.5,343.7c-1.7,3.3-2.8,6.7-3.4,10.5 M651.9,354.5
	c-0.5-3.7-1.7-7.2-3.3-10.5 M589.5,376.7l-8.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M697,289.5c-4.7-3.9-11.7-6.9-21.3-9.1c-18.6-3.7-39.2-6.6-61.9-8.9
	c-15.1-1.6-26.3-4.5-33.7-8.9 M675.4,287.3c-1.4,0-2.3,0.3-2.9,0.5l-0.5,0.5c-0.1,0.3,0,0.8,0.4,1.4c2.9,2.9,5.9,5.8,9.1,8.5
	c3.7,3.2,7.7,6.2,12,9.1c2.1,1.4,5.4,2.3,9.9,3l27.4,4.5l2.5,0.4 M678.3,287.6l-2.9-0.3 M734.3,300.4l-7.6-1.2
	c-3.9-0.8-8-1.9-12.4-3.3c-4.5-1.1-8.8-2.5-12.9-4.3l-4.3-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M730.3,306.6l-0.7,0.1c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.5,0.3,1.2,0.8,2.3
	l3,5.8 M730.3,306.6l0.4,0.1l13.3,2.1 M705.4,347.2l-2.6-21.9c-0.1-1.1-0.1-1.9,0-2.5 M702.8,322.8l3,1.8l0.4,0.5 M706.2,325.2
	l2.2,15.8c0.3,1.8,1,2.6,1.8,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M706.2,325.2c0.4,0.5,0.8,1.5,1,2.6l3,15.8 M704.5,321.4
	c-1,0-1.5,0.4-1.7,1.4 M679.2,287.7l17.9,1.8 M721.9,289.4c-10.3-6.9-27.7-12.7-52.1-17.2c-25.6-4.3-54.3-7.6-86.4-10 M735.6,301.4
	l-1.2-1 M737.6,301.6l-2.1-0.3 M744.2,305.3l-4.5-3.4l-2.1-0.3 M743.7,302.5l0.5,2.9 M745.6,302.2h-1.9v0.3 M752.2,309.1h-3.4
	l-4.5-3.7 M752.2,309.1l-3.6-6.9h-3 M745.6,302.2v-1.6 M744.1,308.8l0.3,0.3l12.8,8.3 M740.6,302.5h3 M733.3,315.4l8.7,1.4
	c2.5,0.6,3.3,0.3,2.6-0.5c-3.4-3.2-7-5.6-10.5-7.7c-1.2-0.7-2.6-1.4-3.9-1.9 M734.3,300.4c4.3,0.5,8,0.5,11.3,0.3 M735.6,301.4
	l8.5,7.4 M753.3,322.4l0.7-2.1l0.1-0.7c0.4-0.7,1-1.1,1.9-1.1h4.5v-1.4 M760.5,317.3h-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M757.2,317.3h-1.5c-1.2,0.1-2.1,1.9-2.5,5.1 M760.5,317.3l-8.3-8.3
	 M753.3,322.4l-0.5,2.1l-0.5,1.8l-0.4,1.5l-0.5,1.8l-1.4,4.8l-0.5,1.8l-0.4,1.5l-0.5,1.8l-1,3.6l-0.1,1.1l-0.3,1.8l-0.1,1.5v1.8
	l0.3,2.2l0.8,2.3l0.8,1.8l0.8,1.5l1.1,1.8l0.1,0.1l1.1,1.5 M139.6,361.3l-12.2-1.2 M171.7,344c-1.6,3.3-2.8,6.7-3.3,10.5
	 M89.3,348.4l-1.1-6.2 M137,371l1.9,0.3 M127.4,360.1c-9.2-0.1-21.5-1.5-36.7-4.1 M128.2,369.9l-21.9-2.8v4.1 M128.3,369.9l8.7,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M106.3,371.2h19.1c1.9,0,2.9-0.5,2.6-1.4 M106.3,367.1l-4.4-0.5
	c-3.4-0.7-6.1-1.8-8-3.6 M82.9,349.1h-1.8 M80.7,347h1.8 M90.5,365.6l2.8,5.6h13.1 M93.1,362c-1.2-0.7-2.5-1.6-3.7-3.2 M93.1,362
	c-1.4-1.6-2.2-3.6-2.6-5.9 M90.7,356.1l-1.2-6.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M89.6,358.9h-2.2l3.3,6.7 M89.4,363.7c-2.5-2.8-3.9-5.4-4.3-7.7
	c-0.3-3.4,0.7-3.3,2.9,0.7l1.5,2.2 M94,362.8l-0.8-0.8 M85.3,349.1h4.1 M86.8,348.4l0.3,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M89.4,349.1l-0.1-0.8 M89.4,349.4v-0.3 M82.7,347h1 M82.9,349.1h1
	 M82.7,347l0.3,2.1 M83.6,347H85 M83.9,349.1l-0.3-2.1 M85,347h0.3c0.7,0,1.2,0.5,1.7,1.4h2.5 M85.3,349.1L85,347 M85.3,349.1h-1.4
	 M81,349.1c-0.8,0-1.2,0.5-1.2,1.4c0.4,1.6,0,3.2-1.1,4.7c-0.3,3,0.3,7,1.7,12.1c0.4,0.8,1.5,1.8,3.2,2.6c3-3,5.4-4.5,7-4.4
	 M80.7,347l0.3,2.1 M182.8,387.5c2.1,1.9,4.3,3.4,6.5,4.8 M175,365.2l-3.7-0.4 M174.7,361.1c0,1.5,0,2.9,0.1,4.3 M175,365.2
	l-0.3-4.3 M171.3,364.8c0.7,7,3.3,13.2,7.7,18.6 M178,347.4l-3.9-1.1 M171.7,354l3.3,1.2 M179.9,389.1c2.8,2.6,5.6,4.8,8.8,6.5
	 M190.5,396.4c4.9,2.3,10.5,3.4,16.4,3.4c6.1,0,11.6-1.2,16.6-3.6 M219.6,394.5c-4,1.5-8.3,2.2-12.8,2.2c-4.4,0-8.7-0.7-12.4-2.1
	 M194.4,394.6l-0.4,0.6l-3.4,1.1 M190.5,396.4l0.1-0.1 M188.7,395.4l0.1-2.6l0.4-0.5 M188.7,395.6l1.8,0.8 M190.1,369.3
	c0.1-1.2,0.3-2.9,0.4-5 M175,365.2l0.3,2.2 M184.9,366.3c0.3,1.1,0.6,2.2,1,3.3 M175.3,367.5l-0.3-2.2 M182.3,378.4l-3.3,5.1
	 M178.4,387.7l1.4,1.4 M182.8,387.5l-0.4,0.7l-2.5,1 M179,383.5l-0.4,0.5v3.6 M178.6,387.6l0.1-0.1l7.3-11.3
	c0.6-0.8,1.1-1.6,1.9-2.5l0.1-0.1c0.6-0.7,1.2-1.2,1.9-1.6l7-4.7 M190.5,376.5l-0.7,0.8l-6.9,10.2 M197.4,381.6
	c-2.3-1.1-4.5-2.6-6.6-4.7l-0.4-0.4 M182.3,378.4l2.2-3.3c0.7-1.1,1.4-2.1,2.2-2.9l0.4-0.4l3.2-2.3 M184.3,366.3
	c-0.7,1.2-1.2,2.1-2.1,2.2c-1.6,0.6-2.3,1.5-2.3,3c0,0.8-0.6,1-1.6,0.4c-1.4-0.7-2.3-1.5-2.8-2.6l-0.3-1.8 M185.9,369.6l-0.8,0.1
	c-1.1-0.1-1.6,0.3-1.9,1.2c-0.1,1.1-1.2,1.6-3.3,1.8h-1.9l1.2,5.9l3-0.3 M223.1,395.9l-8.4-10.2c-0.7-0.8-1.2-1.8-1.8-2.6l-0.1-0.3
	c-0.4-0.8-0.7-1.5-1-2.3l-2.5-8 M190.7,396.3l8.7-10.6c0.7-0.8,1.2-1.6,1.8-2.6l0.1-0.3c0.4-0.7,0.7-1.5,1-2.2l2.3-8 M189.3,392.3
	l7.6-9.8l0.6-1 M210.9,383.2l0.3,0.6c0.7,1.1,1.4,2.1,2.1,2.9l6.3,7.8 M203.2,383.2l3.6,0.3l4-0.3 M203.2,383.2l-0.4,0.7
	c-0.7,1.1-1.4,2.1-2.1,2.9l-6.3,7.7 M210.9,383.2l-0.7-1.5l-1.8-5.9c-0.1-1.2-0.8-1.6-1.8-1.4c-0.6-0.1-1,0.4-1.1,1.7l-1.8,5.9
	c-0.1,0.5-0.3,1-0.6,1.4 M209.5,372.3l0.1-0.1c0.6-0.8,0.8-1.6,0.6-2.5c-0.1-1-0.6-1.7-1.4-2.2c-0.8-0.6-1.6-0.7-2.5-0.6
	s-1.6,0.6-2.2,1.4c-0.6,0.7-0.8,1.7-0.7,2.5c0.1,0.7,0.4,1.2,0.8,1.6l0.6,0.5c0.7,0.6,1.5,0.8,2.5,0.7
	C208.3,373.4,209,373,209.5,372.3L209.5,372.3z M206.6,368.6c-0.4,0.1-0.8,0.3-1.1,0.7c-0.3,0.4-0.4,0.8-0.3,1.2
	c0,0.3,0.1,0.7,0.4,0.8l0.3,0.3c0.4,0.3,0.8,0.4,1.2,0.3c0.6-0.1,0.8-0.3,1.1-0.7c0.3-0.4,0.4-0.8,0.3-1.2c0-0.4-0.3-0.8-0.7-1.1
	C207.6,368.6,207.2,368.5,206.6,368.6L206.6,368.6z M197.4,381.6c0.3-0.5,0.4-1.1,0.6-1.8l0.4-3.6c0.1-0.8-0.1-1.4-0.7-1.8l-0.3-0.1
	l-0.3-0.3l-1.9-0.1l-3.3,1.5c-0.6,0.3-1.1,0.7-1.5,1.1 M179.9,389.1l7.7-11.7c0.4-0.8,1-1.5,1.6-2.2c0.7-0.7,1.7-1.2,2.6-1.8
	l6.9-4.1h1.6l0.3,0.1l0.3,0.3c0.4,0.4,0.6,1,0.6,1.5l-1.8,7.8c-0.3,1.2-0.6,2.3-1,3c-0.4,0.8-1,1.6-1.5,2.3l-8.8,11 M196.9,367.1
	h0.1c1,0.3,1.8,0.3,2.6-0.3c0.8-0.4,1.4-1.1,1.6-1.9c0.3-1,0.3-1.8-0.3-2.6c-0.4-0.8-1.1-1.4-1.9-1.6c-1-0.3-1.8-0.3-2.6,0.1
	c-0.7,0.3-1.1,0.7-1.4,1.2l-0.4,0.7c-0.3,1-0.3,1.8,0.3,2.6C195.5,366.2,196.2,366.7,196.9,367.1L196.9,367.1z M199.6,363.1
	c-0.1-0.4-0.6-0.7-1-0.8l-1.2,0.1c-0.3,0.1-0.6,0.4-0.7,0.7l-0.1,0.4c-0.1,0.4-0.1,1,0.1,1.2c0.1,0.4,0.6,0.7,1,0.8h1.2
	c0.4-0.3,0.7-0.5,0.8-1C199.9,364.1,199.9,363.5,199.6,363.1L199.6,363.1z M203.6,357.2l-0.3,0.3c-1,1-1.5,2.2-1.5,3.6v0.3
	c0.1,1.2,0.6,2.3,1.5,3.3c0.8,0.8,1.8,1.2,2.9,1.4h1.2c1.1-0.1,2.1-0.6,2.9-1.4l0.3-0.4c0.4-0.5,0.8-1.2,1-1.9
	c0.1-0.4,0.1-0.8,0.1-1.2c0-1.4-0.6-2.5-1.4-3.6c-1-1-2.2-1.5-3.6-1.5C205.7,356,204.6,356.4,203.6,357.2L203.6,357.2z M210.6,354
	c0.1,0.4,0.4,0.8,0.8,1l1.4,0.1c0.4-0.1,0.8-0.4,1-0.8c0.1-0.3,0.3-0.7,0.1-1v-0.4c-0.1-0.4-0.4-0.8-0.8-1l-1.2-0.1
	c-0.4,0.1-0.8,0.4-1,0.8C210.5,353.2,210.5,353.6,210.6,354L210.6,354z M211.3,350.3h-0.1c-1,0.3-1.5,0.8-1.9,1.6
	c-0.4,0.8-0.6,1.6-0.3,2.6c0.3,1,0.8,1.5,1.6,1.9c0.8,0.4,1.6,0.4,2.6,0.1c1-0.3,1.5-0.8,1.9-1.7c0.3-0.5,0.4-1.1,0.3-1.8l-0.1-0.8
	c-0.3-0.8-0.8-1.5-1.6-1.9C212.9,350.2,212.1,350.1,211.3,350.3L211.3,350.3z M198.1,353.5v0.3c0,1,0.3,1.8,1,2.3
	c0.7,0.7,1.5,1,2.3,1c1,0,1.8-0.4,2.3-1c0.7-0.7,1-1.4,1-2.3c0-1-0.3-1.8-1-2.5c-0.4-0.4-1-0.7-1.6-0.8l-0.8-0.1c-1,0-1.8,0.3-2.3,1
	C198.4,351.8,198.1,352.5,198.1,353.5L198.1,353.5z M200.3,352.4c-0.3,0.3-0.6,0.7-0.6,1.2c0,0.4,0.1,0.8,0.6,1.2
	c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.2-0.5c0.4-0.3,0.6-0.7,0.6-1.2c0-0.4-0.1-1-0.6-1.2c-0.3-0.3-0.6-0.4-0.8-0.4h-0.4
	C201,352,200.6,352.1,200.3,352.4L200.3,352.4z M190.2,353.1c0.1,1.1,0.3,2.6,0.3,4.4 M187.5,355.3v0.8 M757.1,317.3l-19.5-15.7
	 M184.2,364.4l-0.4-0.1c-1.2-0.3-2.5-0.7-3.4-1.1l-5.6-2.2 M175.1,355.3l8.3,3l0.3,0.1 M190.5,364.4l-3,0.1 M187.5,365.2v-0.7
	 M187.5,364.5l-3-0.1 M184.5,364.4v0.3 M184.2,364.4l0.4,0.4 M184.6,364.8c0.7,0.4,1.6,0.6,2.9,0.4 M187.5,366h-2.8 M184.9,366h-0.3
	l-0.1,0.1 M184.9,366.3l-0.6-0.1 M184.9,366v0.3 M187.5,365.2v0.8 M185.8,369.6c1-0.4,1.4-1.5,1.6-3.3V366 M184.5,364.4h-0.4
	 M190.1,369.3l3.2-2.2c1.1-0.5,1.4-1.2,0.8-2.2c0-0.5-0.7-0.8-1.9-0.5h-1.8 M187.5,358.2l2.3-0.4l0.7-0.1 M187.5,356.2
	c-1.2-0.1-2.2,0-2.8,0.4c-0.6,0.3-1,1-1.1,1.8 M183.6,358.3l0.8,0.1h1.8l1.2-0.3 M187.5,358.2v-1.9 M187.5,355.3H185 M186.1,352
	c0.7,0.4,1.2,1.5,1.4,3.2v0.3 M186.1,352c-0.6,1.1-1,2.2-1.2,3.4 M190.5,357.5c0.4-0.3,0.7-0.5,0.8-1l0.3-0.7c0.3-0.7,0-1.4-0.6-1.9
	l-0.8-0.8 M190.7,345.4l0.3-0.3c1.8-1.8,3.7-3.2,5.9-4.3 M198.1,353.5l-6.7-5.1c-0.7-0.5-1.4-1-2.2-1.4l-0.3-0.1
	c-0.8-0.4-1.8-0.7-2.9-1.1l-13.2-3.4 M190.2,353.1l-1.6-1.8c-0.4-0.5-1-0.8-1.5-1.1c-0.3-0.3-0.6-0.4-1-0.4l-8.1-2.3 M175.1,355.3
	l0.1-1.4 M185,355.3h-0.6c-0.7-1.4-1.4-2.2-2.2-2.3c-1.7-0.5-2.3-1.5-2.3-3c0-0.8-0.6-1-1.6-0.5c-1.4,0.7-2.3,1.5-2.8,2.6l-0.3,1.8
	 M175.3,353.9l-0.1,1.4 M178,347.4v1.2h1.9c2.1,0.1,3.2,0.7,3.3,1.8c0.3,1,1,1.4,1.9,1.2c0.4,0,0.7,0.1,1.1,0.3 M139.6,361.3
	l-0.7,9.9 M155.9,363.1c0.3,9.9,3.3,18.8,8.8,26.7c1.8,2.5,3.9,5,6.2,7.3c8.5,8.5,18.4,13.3,30,14.7l7.4,0.3l5.2-0.4
	c11.3-1.4,21.2-6.2,29.6-14.6l3.2-3.4l1.9-2.5c4-5.2,6.7-10.9,8.3-17.1 M146.1,372.1l0.7-10 M138.9,371.2l7.2,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M168.4,354.5l-0.3,1.9 M195.1,362.3l-8.3,0.3l-2.8-0.3c-1-0.3-2.1-0.5-3-1
	l-6.2-2.3l-6.1-2.3 M174.7,361.1l-3.6-1.4 M171.1,359.5v1.4c0,1.4,0,2.6,0.1,3.9 M168.7,356.7l-0.1-0.1 M168.4,356.5l-0.1-0.1
	l2.2,2.9l0.8,0.3 M168.1,356.4c-0.1,1.5-0.3,3-0.3,4.7c0,10.3,3.6,19.3,10.6,26.7 M168.1,356.4l0.3,0.1 M168.7,356.7l-0.3-0.1
	 M168.5,356.5l-0.4-0.1 M174.2,346.3c-1.1,2.5-1.9,5-2.5,7.7 M168.4,354.5l2.6-0.7l0.7,0.3 M171.8,344l13.5,3.9c1,0.1,1.8,0.5,2.5,1
	h0.1c1,0.4,1.8,1.1,2.5,1.9l6.1,5.4c0.4,0.5,0.6,1.1,0.4,1.6v0.3l-0.1,0.3c-0.3,0.4-0.7,0.8-1.4,1l-8,0.8c-1.2,0.1-2.2,0.3-3.2,0
	c-1-0.1-1.9-0.4-2.8-0.7l-6.6-2.5l-6.5-2.5 M146.6,362.2l9.1,1 M150.1,262.7l47.3,1.2l146.5,5.5 M174.2,346.3l-0.7-0.3l-1.8-2.1
	 M172.9,342.4h-0.3 M172.6,342.2l-1,1.8 M272.1,355.6c0-1.5-0.1-3-0.1-4.5c-0.1-1.8-0.3-3.6-0.5-5.2c-1-5-2.6-9.8-4.8-14.3
	c-3.2-6.5-7.7-12.4-13.3-17.7c-1.9-1.8-3.9-3.4-5.9-5c-11.7-8.8-25.3-13.2-41-13.2c-15,0-28.1,4.1-39.5,12.2l-1.5,1.1
	c-2.1,1.5-4,3.2-5.9,5l-0.1,0.1c-1.4,1.4-2.8,2.6-4,4c-6.6,7.3-11,15.4-13.3,24.2c-0.8,3-1.4,6.2-1.6,9.5l-0.7,9.8 M265.2,355.6
	l-0.4-4.5c-2.1-11.8-7.6-22.3-16.4-31.2c-4.5-4.5-9.4-8.3-14.6-11.1c-8.3-4.4-17.5-6.7-27.6-6.7c-10,0-19.3,2.2-27.5,6.7
	c-2.1,1.1-4.1,2.5-6.2,3.9c-1.4,1-2.8,2.1-4,3.2c-1.5,1.2-2.9,2.6-4.3,4l-3.6,4c-8,9.5-12.4,20.5-13.6,32.9l-0.4,5.5 M256.3,374.1
	c1.1-4.1,1.6-8.5,1.6-13.2c0-11.4-3.3-21.5-9.9-30.3c-1.5-2.1-3.2-4-5.1-5.8c-1.5-1.5-3.2-3-4.8-4.3c-8.9-7.2-19.4-10.6-31.2-10.6
	c-12,0-22.3,3.6-31.4,10.6l-4.8,4.3c-2.3,2.3-4.4,4.8-6.2,7.3c-5.9,8.4-8.8,18-8.8,28.8v2.1 M199.3,322.8c-7.6,1.4-14.2,5-19.9,10.7
	c-2.8,2.8-5,5.6-6.7,8.8 M176.9,341.4l9.8,2.5c1.2,0.3,2.3,0.7,3.4,1.2l0.7,0.3 M176.9,341.4l-0.7-0.1l-3.4,1.1 M197.1,326.5
	c-5.8,1.6-11,4.7-15.5,9.2c-1.8,1.8-3.4,3.7-4.7,5.6 M212.1,322.4l-5.4-0.4l-5.6,0.4 M190.7,345.4c0.4,0.3,1,0.5,1.4,0.8l4.9,3.7
	c0.8,0.8,1.5,1.1,1.9,0.5c0.8-0.5,1-1.4,0.4-2.5l-2.1-5.8l-0.4-1.5 M199.3,322.8l-0.8,13.6c-0.1,1.1,0,2.1,0.1,3.2l0.7,2.8l2.8,7.8
	 M202.6,325.6l-0.4,12.2l0.1,1.2 M212.3,322.4l0.6,14c0.1,0.8,0.1,1.8-0.1,2.8c-0.1,1-0.6,1.9-1.1,3l-3.2,7.4
	c-0.4,0.5-0.8,0.8-1.4,1h-0.6c-0.6-0.1-1-0.4-1.4-1l-3.2-7.4c-0.6-1.1-1-2.1-1.1-3c-0.1-1-0.3-1.9-0.1-2.8l0.6-13.5 M196.7,340.8
	l-0.1-0.7c-0.1-1.2-0.1-2.5,0-3.6l0.6-10 M211,338.9l-4.3-0.4l-4.4,0.4 M211,338.9l0.1-1.1l-0.4-12.2 M202.4,338.9
	c0.1,0.5,0.3,1.1,0.7,1.6l1.8,3.2c0.4,0.7,1,1.1,1.6,1.1h0.7c0.7,0,1.2-0.4,1.7-1.1l1.8-3.2c0.3-0.5,0.6-1.1,0.7-1.6 M210.7,325.6
	v-0.8l1.5-2.2 M210.8,325.6l-3.9-0.3l-4.1,0.3 M201.3,322.5l-1.9,0.3 M751.8,360.2l2.8,3.3c1.5,1.1,3.7,1.5,6.6,1.2
	c1.9,0.5,3.6,2.6,5,6.2c0.4,1,0.4,1.8,0,2.3 M202.6,325.6v-0.8l-1.5-2.2 M201.3,322.5v0.5 M201.3,323.1v-0.6 M197.1,326.5l0.1-0.8
	l2.1-2.9 M164,269.8c1.4-1.4,3.2-2.1,5.2-2.1c6.1-0.8,12.2-0.8,18.7,0c2.1,0,3.7,0.7,5.1,2.1c1.4,1.5,2.1,3.2,2.1,5.1v6.9
	c0,2.1-0.7,3.7-2.1,5.2c-1.5,1.4-3.2,2.1-5.1,2.1c-6.1,0.8-12.4,0.8-18.7,0c-2.1,0-3.7-0.7-5.2-2.1c-1.4-1.5-2.1-3.2-2.1-5.2v-6.9
	C161.9,273,162.6,271.4,164,269.8z M150.1,262.7h-0.4 M149.7,262.7h0.3 M82.1,342.1h-1.8 M80.1,340h1.8 M85.6,327.8h-5.5
	 M82.7,334.9h-1 M85.6,327.8c-1-1.6-2.8-2.5-5.4-2.3 M81.6,334.9l-0.1-2.1 M79.8,332.7l0.1,2.1 M80.1,340l0.1,2.1 M80.3,342.1
	c-0.8,0-1.2,0.5-1.4,1.4l0.4,3.6h1.4 M81.6,334.9h-1.8 M79.8,334.9c-1,0-1.5,0.4-1.5,1.4l0.3,3.7h1.5 M662.8,374.8l11.6-0.3
	 M705.8,350.9l4.3,0.1c3.2,0.1,6.2,0.3,9.1,0.8 M719.9,355.1c-2.9-0.4-5.9-0.7-9.1-0.8l-4.5,0.1 M719.6,353.4
	c-2.8-0.4-5.8-0.7-8.8-0.7h-4.1 M705.8,350.9l0.4,3.7 M719.9,356.9c-2.9-0.4-5.9-0.7-9.1-0.8l-4.3,0.1 M719.5,360.6l-8.3-0.7h-3.3
	 M719.7,359.4l-8.9-0.7h-3.3 M706.5,356.2v0.3c0.5,3.6,2.8,5.8,6.5,6.5c4.3,0.7,7,0,8.4-2.2 M706.3,354.5l0.1,1.8 M705.4,347.2
	l0.4,3.6 M718.4,349.5c-0.3-0.5-0.7-1.1-1.1-1.6 M721,349.9l-0.8-1.6 M722.2,355.6c0-0.7,0-1.2-0.1-1.8 M722.2,353.8
	c0-0.5-0.1-1.1-0.3-1.7 M721.9,352.1c-0.1-0.4-0.3-1-0.4-1.4l-0.4-0.8 M722.2,355.6l-2.3-0.4 M719.6,353.4l2.5,0.4 M721,349.9
	l-2.8-0.5 M719.2,351.7c0.1,0.5,0.3,1,0.4,1.5 M710.2,343.6l6.1,1.7c1.2,0,2.5,1.1,3.9,3.2 M720.2,348.4l-2.9-0.5 M721.9,352.1
	l-2.6-0.4 M721.8,359.7c0.1-0.8,0.3-1.6,0.4-2.3 M721.4,360.9l0.4-0.7v-0.4 M719.9,356.9l2.2,0.4 M721.4,360.9l-1.8-0.3
	 M719.5,360.6l0.1-1.2 M719.7,359.4l2.1,0.3 M722.1,357.3c0.1-0.7,0.1-1.2,0.1-1.8 M719.9,355.1v1.8 M717.3,347.9
	c-3.7-0.5-7.6-0.8-11.8-0.7 M718.4,349.5c-3.7-0.5-7.8-0.8-12.1-0.7 M80.1,327.8l-1.8,2.3v2.8h1.5 M77.3,324.6c0,1.9,1,3,2.8,3.2
	 M79.8,332.7h1.8 M82.5,332.9l0.1,2.1 M82.5,332.9c0.7,0.1,1.2,0.5,1.5,1.4 M87.1,334.9h-3 M83.9,334.1h2.9 M88.2,341.4l-1.1-6.5
	 M83,340l0.1,2.1 M82.1,342.1L82,340 M85.2,341.4l0.3,0.7 M84.3,340.1l0.1,1.9 M84.5,342.1h-1.4 M83.1,342.1h-1 M88.3,342.1h-3.7
	 M84.3,340.1c0.4,0.3,0.7,0.5,1,1.1h2.9 M82.9,340h0.7c0.3,0,0.6,0.1,0.7,0.3 M81.9,340h1 M88.3,342.1l-0.1-0.8 M87.1,334.9
	l-0.1-0.8 M83.9,334.1v0.8 M84.1,334.9h-1.4 M81.6,332.7h1 M86.9,334.1l-0.8-4.8c-0.1-0.5-0.4-1.1-0.7-1.5 M92.2,301.4l-4.9,1.2
	l-1.8,6.2 M85.4,308.9l-2.5,0.3l-3.3,0.4c-0.8,0.1-1.4,1-1.5,2.5l-1,12.5v0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M172.6,312.6l-5.8-5l-1-0.7c-5.5-4.3-10.9-7.4-16.4-9.4
	c-8-1-16.2-1.4-24.5-1l-8.1,0.5 M149.7,262.7c-7.4,0.5-12.4,3.9-14.7,9.9l-10,24.1 M92.2,301.4l1.5-0.4l2.9-0.7
	c3.4-0.8,6.7-1.4,10.2-1.9l3.2-0.5l7-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M109.8,254.3l18.4,4.5l0.1,0.1c7.3,2.1,14.4,3.3,21.5,3.9 M106.3,265
	c1-5.5,2.1-9.1,3.4-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M92.2,301.4c1.1-7.3,2.6-11.7,4.4-13.3c3.2-2.1,4.9-4.3,5.6-6.7l4.1-16.4
	l1.7,0.1l14.7,1c4.1,0.3,5.9,1.6,5.6,4.3c0,0.4-0.1,1-0.3,1.4l-5,11.7l-1.8,4.3l-0.3,0.7l-3.9,9.1 M92,272.1l-4.8,24.8
	c-1,1.6-2.2,2.9-3.6,3.9l-0.6,8.4 M92,272.1l2.6-14.3c0.7-4.5,0-6.6-2.2-6.1 M92.4,251.7c-0.3,0.6-0.3,1.4-0.4,2.2l-3.4,15
	c-0.1,1.6,1,2.8,3.4,3.2 M240.9,341.9l-0.1,0.1 M241.8,343.7l-1-1.8 M241.8,343.7h-0.1l-0.6,0.1 M241.1,343.9l0.7-0.1 M244.7,354.3
	l-12.7,4.7c-0.8,0.4-1.6,0.7-2.8,0.8c-1,0.1-2.1,0.1-3.2,0l-8-0.8c-0.6-0.3-1.1-0.5-1.4-1l-0.1-0.3v-0.3c-0.1-0.5,0.1-1.1,0.4-1.5
	l6.1-5.4c0.8-0.8,1.6-1.5,2.5-1.9l0.1-0.1c0.8-0.4,1.6-0.8,2.6-1l12.8-3.6h0.1 M240.7,342.1l-3.3-1.1l-0.8,0.1 M236.6,341.1
	c-1.4-1.9-2.8-3.7-4.5-5.4c-4.7-4.7-9.9-7.7-15.8-9.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M240.9,341.9c-1.6-3-3.9-5.8-6.5-8.5c-5.8-5.8-12.5-9.4-20.2-10.7
	 M216.2,326.5v-0.7l-2.1-3v0.4 M214.2,322.8l-1.9-0.4 M212.3,322.4h-0.1 M212.1,322.4h0.1 M214.2,322.8v0.4 M214.2,323.2l0.8,13.2
	c0,1.1,0,2.2-0.1,3.2v0.3c-0.1,0.8-0.3,1.6-0.6,2.3l-2.9,8 M216.7,340.7l0.1-0.7c0.1-1.2,0.1-2.5,0-3.6l-0.6-10 M222.9,345.2
	c-0.4,0.3-0.8,0.5-1.2,0.7l-5,3.7c-0.8,0.8-1.6,1-2.5,0.4c-0.6-0.3-0.6-1,0-1.9l2.1-5.8c0.1-0.5,0.3-1.1,0.4-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M236.6,341.1l-9.6,2.5c-1.2,0.3-2.3,0.7-3.4,1.2l-0.8,0.4 M289.3,254.6
	c3.7,1.5,8.3,2.5,13.5,2.9l42.1,1.6 M343.9,269.4v0.3c-2.6,13.5-3.7,30-3,49.5c1,13.8,2.9,25.9,5.9,36.6 M344.9,259l2.8,0.1
	 M347.6,259.1l-3.7,10.3 M347.6,259.1l-0.1-1.6 M347.5,257.5l13.5,0.4l151.2,5.1 M348.7,361.9l-1.9-6.2 M272.1,368.2
	c5.9-4.4,14.2-6.9,24.6-7.6l52,1.2 M222.9,345.2c-1.9-1.9-4-3.4-6.2-4.5 M240.7,342.1l-13.2,3.4c-1,0.3-1.9,0.7-2.9,1.1l-0.4,0.3
	c-0.7,0.3-1.4,0.8-1.9,1.2l-6.6,5 M239.4,346.1l-11.8,3.4c-0.4,0.1-0.7,0.3-1.1,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M226.4,349.9c-0.6,0.3-1,0.7-1.4,1.1l-2.5,2.6c-0.6,0.5-0.8,1.2-0.6,1.9
	l0.3,0.7c0.3,0.7,0.7,1.1,1.5,1.2l3.6,0.7l1.8,0.1 M229.2,358.2l1.1-0.3l11.6-4.1 M245.4,356.1l-12.7,5c-1.1,0.4-2.1,0.7-3,0.8
	l-0.4,0.1l-2.3,0.1l-8.4-0.3 M229.2,364.1l0.7-0.1c1.1-0.3,2.2-0.5,3.4-1.1l9.2-3.6 M226.3,370.8c-0.1-0.1-0.4-0.3-0.6-0.4l-5.1-3.6
	c-1.1-0.5-1.5-1.1-1.1-1.5c0-1.1,0.7-1.5,1.9-1.2h7.7 M218.4,362l-0.1-0.1c-0.6-0.8-1.2-1.2-2.2-1.4c-1-0.1-1.8,0-2.5,0.5
	c-0.8,0.5-1.2,1.4-1.4,2.2c-0.1,1,0.1,1.8,0.7,2.5s1.2,1.2,2.2,1.4c0.7,0.1,1.2,0,1.8-0.3l0.7-0.4c0.8-0.5,1.2-1.2,1.4-2.2
	C219.1,363.5,219,362.7,218.4,362L218.4,362z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M217.4,364.1c0-0.4,0-0.8-0.3-1.2c-0.3-0.4-0.7-0.5-1.1-0.7
	c-0.4-0.1-0.8,0-1.2,0.3s-0.6,0.7-0.7,1.1c-0.1,0.4,0,0.8,0.3,1.2c0.3,0.4,0.7,0.7,1.1,0.7l1-0.1l0.4-0.1
	C217.1,364.9,217.2,364.5,217.4,364.1z M229.2,358.2c-0.4-3-1.2-5.6-2.8-8.3 M244.7,354.3h0.4 M245.1,354.2l-0.4,0.1 M245.1,354.3
	v-0.1 M245.1,354.2h0.1H245.1 M241.8,353.8l0.8-0.3l2.5,0.7 M235.4,387.5c6.9-7.4,10.3-16.2,10.3-26.4c0-1.6-0.1-3.3-0.3-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242.5,359.3v1.6c0,4.1-0.6,7.8-1.8,11.4 M242.5,359.3l0.7-0.3l2.2-2.9
	 M245.4,356.1l-0.3-1.8 M241.8,353.8c-0.6-2.8-1.4-5.2-2.5-7.7 M239.4,346.1l0.8-0.3l1.6-2.1 M234,388.8h0.1 M234.1,388.8v0.1V388.8
	H234 M234,388.8h0.1 M234,388.8l-2.5-0.8l-0.4-0.7 M235.4,387.5v-3.7l-0.4-0.5 M234,388.8l1.4-1.4 M235,383.2
	c2.6-3.3,4.5-6.9,5.9-10.7 M223.4,376.2c-0.4-0.4-0.8-0.7-1.4-1l-3.3-1.5h-1.9l-0.3,0.3l-0.3,0.3c-0.6,0.4-0.8,1-0.7,1.8l0.4,3.6
	c0.1,0.7,0.3,1.2,0.6,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.2,395.5l-8.8-11c-0.6-0.7-1.1-1.4-1.5-2.2v-0.1c-0.4-1-0.7-1.9-1-3
	l-1.8-7.8c0-0.7,0.1-1.2,0.6-1.5l0.3-0.3l0.3-0.1l1.6-0.1l6.9,4.1c1,0.4,1.8,1.1,2.6,1.8v0.1c0.7,0.7,1.4,1.4,1.8,2.2l7.7,11.6
	 M226.8,371.4l-0.6-0.5 M227.1,371l-0.3,0.4 M227.1,371l-0.8-0.1 M226.8,371.4l0.6,0.5c0.7,0.8,1.4,1.8,2.2,2.9l5.4,8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235.4,387.5l-7.4-11.4c-0.6-1-1.2-1.8-1.9-2.5l-0.3-0.3
	c-0.6-0.5-1.1-1-1.8-1.5l-6.9-4.7 M216.5,381.4c2.2-1.1,4.4-2.6,6.2-4.4l0.7-0.7 M231.1,387.3l-6.9-10.2l-0.8-1 M216.5,381.4l0.6,1
	l7.6,9.8 M227.1,371c1.1-2.2,1.8-4.4,2.1-6.9 M240.7,372.5l-13.6-1.5 M265.5,375.2v-10c0-3-0.1-5.9-0.3-8.9v-0.5 M272.1,355.6
	c0,1.5,0.1,3,0.1,4.7v8 M272.1,368.2v13.6 M256.3,374.1l9.2,1.1 M265.5,375.2v7l6.6-0.4 M265.2,355.6h6.9 M223.4,396.1L220,395
	l-0.4-0.5 M225.2,395.5h0.1 M223.5,396.3l1.8-0.8 M223.5,396.3l-0.1-0.1 M223.4,396.1l-0.3-0.3 M224.6,392.1l0.4,0.5l0.1,2.8
	 M223.1,395.9l0.3,0.3 M225.3,395.5c3.2-1.7,6.1-3.9,8.8-6.6 M224.6,392.1c2.3-1.4,4.4-3,6.5-4.8 M346.8,355.7l-74.7-0.1
	 M245.1,354.2c-0.7-3.7-1.8-7.2-3.3-10.5 M511.7,243.3c-1.2,1.1-1.9,3.2-2.1,6.3 M284.9,252.2c1.4,0.8,2.9,1.6,4.5,2.2 M343.9,269.4
	l200.3,8 M540.9,265.7l-193.3-6.6 M284.9,252.2l4.4,2.3 M343.8,209.6l1.1,49.4 M346.4,209.6l1.1,47.9 M93.4,250.3
	c-0.4,0.3-0.7,0.8-1,1.5 M289.2,200.2h-0.3c-8.1,1.6-16,3.4-23.8,5.2c-27.5,6.7-53,15.4-76.3,25.9l-26.6,11.1 M150.5,249.2l-7.7,1.1
	l-31.1,2.9c-0.7,0-1.4,0.4-1.9,1.1 M146.4,249.1l4.1,0.1 M146.4,249.1c-2.1-0.3-4.3-1-6.6-1.9l-44.2,2.5c-1,0-1.7,0.3-2.2,0.7
	 M160.4,243.3l-9.9,4.1l-4.1,1.6 M163.8,246.1l-1.9,0.6 M161.9,246.6l-2.5,0.7c-2.8,0.7-5.8,1.4-9.1,1.9 M163.8,246.1l-1.8-3.4
	 M164.8,245.6l-1,0.4 M161.9,246.6l-1.5-3.3 M162.1,242.6l-2.6-5h-0.6l-0.4,0.3l-0.4,0.6l2.2,4.8"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M539.8,263.9l-27.7-1
		l-0.4-1.7c0,0-3.2-0.1-3.7-4.1c0,0-0.6-2.3,0.4-8.1c0,0,0.7-6.3,5.4-6.3l3,0.4c0,0,10.3,2.8,13.2,10.3l0.4,1.1
		C530.4,254.6,536.2,259.1,539.8,263.9z"
              id="Right Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightSideMirrorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M540.9,265.7
		c0,0,2.5,3.9,3.3,11.7c0,0,1.1,17.3-0.4,40.6c0,0-1.7,24.2-4.7,41.4c0,0-1.4,9.8-10.5,8.5l-166.6-1.2c0,0-5.8,0.3-9.6-1.4
		c0,0-2.6-1.1-3.7-3.6l-1.9-6.2c0,0-6.2-21-6.1-46.5c0,0-0.3-22.7,3.2-39.8l3.6-10.3L540.9,265.7z"
              id="Right Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M649.2,314.4
		l4.7-5.6l26.6-3.7h9.8c0,0-8.3-6.1-13.5-11.1c0,0-5-4.3-4.5-5.5c0,0,0.4-1,3-1.1l21.9,2.2c0,0-6.3-6.1-21.5-9.2
		c0,0-34.4-6.7-61.1-8.8c0,0-21.5-1.8-34-8.9l-39.5,3c0,0,2.5,3.2,3.3,11.7c0,0,1.2,15.3-0.4,40.6c0,0-1.7,24.1-4.7,41.4h16.5v-1.6
		c0,0,0.7-27.6,28.1-45.9C583.7,311.8,615.3,290.1,649.2,314.4z"
              id="Right Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontFenderColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="613.5"
              cy="361.5"
              r="51.2"
              id="Wheels And Tires - Right Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightFrontColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M346.8,355.7
		l-81.6-0.1c0,0-0.3-23.4-22.7-41.1c0,0-14.9-13.5-39.5-12.5c0,0-16.4,0.1-30.5,10.6c0,0-12.5-12-23.1-15c0,0-13.2-1.7-24.5-1
		l10-24.1c0,0,2.6-9.4,14.7-9.9l194.2,6.9c0,0-2.6,13.3-3,29.2c0,0-1.2,17.5,1.7,36.5C342.7,335.2,343.5,343,346.8,355.7z"
              id="Right Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M507.5,244.4
		c0,0-27.5-20.4-53.9-27.1c0,0-17.7-3-61.8-3.4h-34.3l3.4,44l151.2,5.1l-0.4-1.7c0,0-2.6-0.3-3.6-3c0,0-1-2.3,0.3-9.4
		C508.4,248.8,507.5,246.3,507.5,244.4z"
              id="Right Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M335.4,249.2
		c0,0,0,7.7-9.1,7.6c0,0-23.7,0.8-37.7-4.5c0,0-16.9-8.3-25.7-16.8c0,0-1.8-1.8-1.9-2.5c-0.1-0.6-0.8-1.4,1-3.4
		c1.8-2.1,5.9-4,16.2-7.3c0,0,6.2-2.3,12.9-3.6c0,0,7.6-1.7,17.9-2.9c0,0,12.4-1.2,18.6-1.1c0,0,6.1-0.3,7,5.8L335.4,249.2z"
              id="Right Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightQtrGlassColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="206.9"
              cy="360.6"
              r="51.2"
              id="Wheels And Tires - Right Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Right Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresRightRearColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback3DrRight);
