import React, { Component } from 'react';

class PictureIcon extends Component {
  render() {
    const { size = 24, color = '#fff' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.75 3.75V20.25H20.25V3.75H3.75ZM3 2.25H21C21.1989 2.25 21.3897 2.32902 21.5303 2.46967C21.671 2.61032 21.75 2.80109 21.75 3V21C21.75 21.1989 21.671 21.3897 21.5303 21.5303C21.3897 21.671 21.1989 21.75 21 21.75H3C2.80109 21.75 2.61032 21.671 2.46967 21.5303C2.32902 21.3897 2.25 21.1989 2.25 21V3C2.25 2.80109 2.32902 2.61032 2.46967 2.46967C2.61032 2.32902 2.80109 2.25 3 2.25Z" />
        <path d="M8.9998 6.75C9.9998 6.75 10.4998 7.25 10.4998 8.25C10.4998 9.25 9.9998 9.75 8.9998 9.75C7.9998 9.75 7.4998 9.25 7.4998 8.25C7.4998 7.25 7.9998 6.75 8.9998 6.75ZM4.3453 20.5545L3.1543 19.6425L8.2198 13.032C8.39944 12.7974 8.62353 12.6004 8.87929 12.4524C9.13504 12.3044 9.41745 12.2082 9.71037 12.1694C10.0033 12.1305 10.301 12.1497 10.5865 12.2259C10.872 12.3021 11.1397 12.4338 11.3743 12.6135L11.4178 12.648L14.2978 14.9715C14.4528 15.0962 14.6509 15.1543 14.8487 15.1329C15.0464 15.1115 15.2276 15.0125 15.3523 14.8575L20.4148 8.532L21.5848 9.468L16.5268 15.792L16.5208 15.7995C16.3353 16.0297 16.1063 16.221 15.8468 16.3626C15.5873 16.5042 15.3025 16.5933 15.0086 16.6248C14.7146 16.6563 14.4174 16.6296 14.1338 16.5461C13.8502 16.4627 13.5858 16.3242 13.3558 16.1385L10.4758 13.8165L10.4608 13.8045C10.303 13.684 10.1038 13.6311 9.90697 13.6572C9.71013 13.6834 9.53171 13.7865 9.4108 13.944L4.3453 20.5545Z" />
      </svg>
    );
  }
}

export default PictureIcon;
