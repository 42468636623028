import React, { Component } from 'react';
import { connect } from 'react-redux';

class Crossover4DrFront extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M517.2,192.3l-0.7-1.2c8,17.7,15.1,36.7,21.6,57.1c0.5,1.2,1.2,1.8,2.2,1.5l-0.7-1.6c-1-2.1-1.8-4.4-2.3-6.7
	C531,221.2,524.3,204.8,517.2,192.3L517.2,192.3z"
          />
          <path
            fill="#FFFFFF"
            d="M516.6,191h-2.5c9.2,18.8,16.6,40.4,22.1,65.1l2.8,0.1c1.7-1.8,2.1-4,1.4-6.6c-1,0.1-1.7-0.3-2.2-1.5
	C531.8,227.8,524.5,208.8,516.6,191L516.6,191z"
          />
          <path
            fill="#FFFFFF"
            d="M514.1,191h2.5c-4.8-8.7-10-13.8-15.8-14.9h-0.3c0.1,0.7,0.3,1.2,0.4,2.1v1.4c0,0.6-0.1,1-0.5,1.4
	s-0.8,0.6-1.4,0.6h-1.4c-0.6,0-1-0.1-1.4-0.6c-0.4-0.4-0.5-0.8-0.5-1.4l-0.3-1.4c-0.3-1.2-0.7-2.1-1.1-2.5c-0.8,0-1.4,0.1-1.9,0.3
	l-0.3,0.1c-0.7,0.3-1,0.7-1.1,1.2c-15.5-2.6-37-4-64.4-4.1h-11.1c-27.4,0.1-48.8,1.5-64.4,4.1c-0.1-0.6-0.5-1-1.1-1.2l-0.3-0.1
	c-0.5-0.1-1.1-0.3-1.9-0.3c-0.5,0.7-1,1.9-1.4,3.9c0,0.6-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.6-1.4,0.6h-1.4c-0.5,0-1-0.1-1.4-0.6
	c-0.4-0.4-0.5-0.8-0.5-1.4c0-1.2,0.1-2.3,0.4-3.4h-0.3c-5.8,1.2-11,6.2-15.8,14.9h2.5c0.8-1.1,1.9-1.4,3.3-1
	c2.5,0.1,3.3,0.6,2.3,1.2c18.8-0.7,36-1.1,51.6-1.2h70.6c15.8,0,33.3,0.4,52.5,1.2c-1-0.7-0.1-1.1,2.3-1.2
	C512.2,189.7,513.3,189.9,514.1,191L514.1,191z"
          />
          <path
            fill="#FFFFFF"
            d="M514.1,191c-0.8-1.1-1.9-1.4-3.3-1c-2.5,0.1-3.3,0.6-2.3,1.2c8.5,19.1,15.1,40.7,19.8,64.4l8.1,0.5
	C530.7,231.5,523.3,209.9,514.1,191L514.1,191z M495.4,178.1l0.3,1.4c0,0.6,0.1,1,0.5,1.4c0.4,0.4,0.8,0.6,1.4,0.6h1.4
	c0.5,0,1-0.1,1.4-0.6c0.4-0.4,0.5-0.8,0.5-1.4v-1.4c0-0.7-0.1-1.4-0.4-2.1c-0.3-0.8-0.5-1.5-1-2.2c-0.4-0.8-1.5-1.2-3.3-1.2
	c-1.9,0.1-3,0.6-3.4,1.1c-0.4,0.7-0.7,1.5-0.7,2.2h0.4c0.1-0.4,0.4-0.7,0.7-0.8c0.4-0.1,0.8,0,1.2,0.4
	C494.7,176,495.1,176.9,495.4,178.1L495.4,178.1z"
          />
          <path
            fill="#D3D3D3"
            d="M494.4,175.6c-0.4-0.4-0.8-0.7-1.2-0.4c-0.4,0.1-0.5,0.4-0.7,0.8C492.9,175.8,493.6,175.6,494.4,175.6
	L494.4,175.6z"
          />
          <path
            fill="#FFFFFF"
            d="M551.1,237.9c-4.5,2.2-6.6,5.5-6.5,9.8c2.6,0.7,4.7,1.5,6.2,2.5c3.4,2.2,4.4,5.1,2.8,8.9
	c8.4,0.5,14-0.7,17.1-3.7c2.9-2.8,3.6-6.1,2.3-9.9l-0.1-0.4c-1.4-3-4.3-5.5-9.1-7.2C558.6,236.3,554.4,236.3,551.1,237.9
	L551.1,237.9z M421.9,163.8c0-0.3-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6l-0.4,6.9
	h2.6L421.9,163.8L421.9,163.8z"
          />
          <path
            fill="#FFFFFF"
            d="M422.6,170.7H420c-1.4,0.3-2.2,1.2-2.6,2.6h7.4C424.9,171.5,424.1,170.7,422.6,170.7L422.6,170.7z
	 M429.8,265.7h-8.3H429.8c8.9-0.5,14.6-2.3,17.1-5.2l6.9-8.1L435,252h-27.8l-18.2,0.4l6.9,8.1c2.5,3,8.1,4.8,17.1,5.2h8.3H413
	c-8.9-0.5-14.6-2.3-17.1-5.2l-6.9-8.1l-55.4,2.2c8.5,3.4,15.8,7.8,21.6,13.3c4,3.4,5.4,7.2,4.4,10.9c-6.3,11.1-12,22.1-16.8,33.3
	l-48.3-2.2l7,34.3l1.4,6.9c1.1,4,5.1,5.9,11.7,5.9c1.8,6.7,8.1,10.5,19.3,11h0.1c2.2,0.1,4.5,0.1,7,0h8c3.2,3.3,9.9,4.8,19.9,4.8
	h104.3c10.2,0,16.8-1.5,19.9-4.8h8c2.5,0.1,5,0.1,7,0h0.1c11-0.7,17.5-4.3,19.3-11c6.7,0,10.6-1.9,11.7-5.9l1.4-6.9l7-34.3
	l-48.3,2.2c-4.8-11-10.5-22.1-16.8-33.3c-1.1-3.7,0.4-7.4,4.4-10.9c5.8-5.5,12.9-9.9,21.6-13.3l-54.9-2.2l-6.9,8.1
	C444.5,263.5,438.8,265.2,429.8,265.7L429.8,265.7z M424.7,274.5l0.8,0.8h8l-0.7,0.8l-0.7,0.8l-0.7,0.7l-0.8,0.7l-1,0.8l-1.2,0.8
	l-1.4,0.8h-2.2l-0.1,0.1c-1,1-2.2,1.4-3.6,1.4c-1.4,0-2.6-0.4-3.6-1.4l-0.1-0.1h-2.2l-1.2-0.8l-1.2-0.8l-1-0.8l-0.8-0.7l-0.7-0.7
	l-0.7-0.8l-0.7-0.8h8l0.8-0.8c1-1,2.2-1.4,3.6-1.4C422.5,273.2,423.7,273.6,424.7,274.5L424.7,274.5z M419.8,316.2H461
	c1.9,0.1,3.6,0.4,5,1c0.6,0.3,1.1,0.5,1.7,0.8c0.5,0.3,1.2,0.7,1.6,1.2l0.8,0.8l0.7,0.8l0.3,0.8v0.8c-0.3,0.8-1.5,1.2-3.6,1.2h-92.7
	c-2.1,0-3.3-0.4-3.6-1.2c-0.1-0.3-0.1-0.5,0-0.8c0-0.3,0.1-0.5,0.3-0.8l0.7-0.8l0.8-0.8l1.6-1.2l1.7-0.8c1.5-0.5,3.2-0.8,5-1H419.8
	L419.8,316.2z M472.3,315l-0.5-0.1l-2.5-0.3h-96.3l-2.5,0.3l-0.5,0.1l-0.3,0.1c-1,0.3-1.9,0.5-2.9,1.1l-3.3,1.5
	c-0.8,0.4-1.6,0.7-2.6,0.8l-3.4,0.3h-10.3c-3.3-1.1-4.7-3.4-3.7-7v-0.1c2.6-6.3,5-11.1,7-14.7c2.8-4.7,7.6-7.3,14.9-8
	c11.6-1.1,25.7-1.6,42.8-1.6h25.6c17.5,0,32.1,0.7,43.7,1.6c7.2,0.7,12.1,3.3,14.9,8c2.1,3.6,4.4,8.4,7,14.7v0.1
	c0.8,3.6-0.4,5.9-3.7,7h-10.3l-3.4-0.3c-0.8-0.3-1.8-0.5-2.6-0.8l-3.3-1.5c-1-0.4-1.9-0.8-2.9-1.1L472.3,315L472.3,315z
	 M380.2,328.7c0-1.4,0.7-1.9,1.9-1.9H460c1.4,0,1.9,0.7,1.9,1.9v14.2c0,1.4-0.7,1.9-1.9,1.9h-77.9c-1.4,0-1.9-0.7-1.9-1.9
	L380.2,328.7L380.2,328.7z M354.2,330.5c1.8,0,2.8,1,2.8,2.8v3.6c0,1.8-1,2.8-2.8,2.8h-3.6c-1.8,0-2.8-1-2.8-2.8v-3.6
	c0-1.8,1-2.8,2.8-2.8L354.2,330.5L354.2,330.5z M472.4,372.1H369.8c-10.6,0-17.3-1.8-20.4-5.2c-3.3-4.4-5.1-8.9-5.4-13.8
	c-0.4-4.5,2.2-6.7,8-6.6h137.8c5.8-0.1,8.5,2.1,8.1,6.6c-0.3,4.8-1.9,9.4-5.4,13.8C489.7,370.3,483,372.1,472.4,372.1L472.4,372.1z
	 M521.9,347.6c1.9,1.9,2.9,4.3,2.9,7s-1,5.1-2.9,7c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9s-2.9-4.3-2.9-7s1-5.1,2.9-7
	s4.3-2.9,7-2.9C517.7,344.7,520,345.6,521.9,347.6L521.9,347.6z M334.3,361.6c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9
	c-1.9-1.9-2.9-4.3-2.9-7s1-5.1,2.9-7c1.9-1.9,4.3-2.9,7-2.9c2.8,0,5.1,1,7,2.9s2.9,4.3,2.9,7S336.2,359.7,334.3,361.6L334.3,361.6z"
          />
          <path
            fill="#D3D3D3"
            d="M426.2,277.7h5.1l0.7-0.7H426 M431.3,277.7h-5.1l-0.1,0.8h4.4 M425.3,275.4c-0.3-0.3-0.4-0.5-0.8-0.8
	c-1-1-2.2-1.4-3.6-1.4s-2.6,0.4-3.6,1.4l-0.8,0.8l-0.4,0.8l-0.3,0.8v1.4l0.1,0.8l0.4,0.8l0.7,0.8l0.1,0.1c1,1,2.2,1.4,3.6,1.4
	c1.4,0,2.6-0.4,3.6-1.4l0.1-0.1l0.7-0.8l0.4-0.8l0.1-0.8v-0.7l-0.1-0.7l-0.3-0.8L425.3,275.4L425.3,275.4L425.3,275.4z M424.1,274.9
	c0.8,0.8,1.2,1.6,1.2,2.9c0,1.1-0.4,2.1-1.2,2.8c-0.8,0.8-1.9,1.1-3.2,1.1s-2.3-0.4-3.2-1.1c-0.8-0.8-1.2-1.8-1.2-2.8
	c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.9-1.2,3.2-1.2C422.2,273.8,423.3,274.1,424.1,274.9z"
          />
          <path
            fill="#D3D3D3"
            d="M425.5,277.8c0-1.1-0.4-2.1-1.2-2.9c-0.8-0.8-1.9-1.2-3.2-1.2s-2.3,0.4-3.2,1.2c-0.8,0.8-1.2,1.6-1.2,2.9
	c0,1.1,0.4,2.1,1.2,2.8c0.8,0.8,1.9,1.1,3.2,1.1s2.2-0.4,3.2-1.1C424.9,279.8,425.5,278.9,425.5,277.8L425.5,277.8z M423.8,275.2
	c0.8,0.7,1.2,1.5,1.2,2.6s-0.4,1.8-1.2,2.5s-1.8,1.1-2.9,1.1s-2.1-0.4-2.9-1.1c-0.8-0.7-1.2-1.5-1.2-2.5s0.4-1.9,1.2-2.6
	s1.8-1.1,2.9-1.1C422.2,274.1,423,274.5,423.8,275.2L423.8,275.2z"
          />
          <path
            fill="#D3D3D3"
            d="M425.1,277.8c0-1-0.4-1.9-1.2-2.6s-1.8-1.1-2.9-1.1s-2.1,0.4-2.9,1.1c-0.8,0.7-1.2,1.5-1.2,2.6
	c0,1,0.4,1.8,1.2,2.5c0.8,0.7,1.8,1.1,2.9,1.1c1.1,0,2.1-0.4,2.9-1.1S425.1,278.8,425.1,277.8L425.1,277.8z M423.8,276.7h0.6v1.9
	h-0.5L423.8,276.7L423.8,276.7z M417.5,276.7h0.5l0.7,1.2l0.7-1.2h0.5v1.9h-0.5v-1l-0.5,1.1h-0.1l-0.5-1.1v1.1h-0.5v-2.1
	L417.5,276.7L417.5,276.7z M420.4,276.7h0.5v1.9h-0.5V276.7z M421.5,276.7h0.4l1.1,1.2v-1.2h0.4v1.9H423l-1.1-1.2v1.2h-0.4V276.7z"
          />
          <path
            fill="#D3D3D3"
            d="M424.4,276.7h-0.5v2.1h0.5 M418.1,276.7h-0.5v2.1h0.5v-1.1l0.5,1.1h0.1l0.5-1.1v1.1h0.5v-2.1h-0.5l-0.5,1.2
	 M420.9,276.7h-0.5v2.1h0.5 M421.9,276.7h-0.4v2.1h0.4v-1.2l1.1,1.2h0.4v-2.1H423v1.2 M425.3,275.4l0.5,0.8h6.7l0.7-0.8
	 M425.9,276.2l0.1,0.8h5.9l0.7-0.8 M416.1,276.2l0.4-0.8h-7.8l0.7,0.8 M416,277l0.1-0.8h-6.7l0.7,0.8 M415.9,277.7l0.1-0.7h-5.9
	l0.7,0.7 M415.9,277.8v-0.1h-5.1l0.8,0.8h4.4 M416.1,279.2l-0.1-0.7h-4.4l1,0.7 M416.5,280l-0.4-0.8h-3.6l1.2,0.8 M417.2,280.9
	l-0.7-0.8h-2.8l1.2,0.8 M429.5,279.2l1-0.7H426l-0.1,0.7 M428.2,280l1.2-0.8h-3.6l-0.4,0.8 M425.5,280l-0.7,0.8h2.2l1.2-0.8
	 M422.3,316.2h-2.5v7.2h2.5v-6.2 M422.3,317.2v0.7H450v-0.7"
          />
          <path
            fill="#D3D3D3"
            d="M422.3,317.9v1.2H450v-1.2 M422.3,319.1v0.8H450v-0.8"
          />
          <path
            fill="#D3D3D3"
            d="M422.3,319.9v1.5H450v-1.5 M419.8,317.9v-0.7h-27.6v0.7 M419.8,319.1v-1.2h-27.6v1.2"
          />
          <path
            fill="#D3D3D3"
            d="M419.8,319.9v-0.8h-27.6v0.8 M419.8,321.4v-1.5h-27.6v1.5 M419.8,323.4v-1.1h-27.6v1.1 M422.3,322.3v1.1H450
	v-1.1"
          />
          <path
            fill="#D3D3D3"
            d="M419.8,322.3v-0.8h-27.6v0.8 M422.3,321.4v0.8H450v-0.8 M422.3,316.2v1H450v-1 M419.8,317.2v-1h-27.6v1
	 M451.8,316.2H450v7.2h1.8v-6.2 M451.8,317.2v0.8h16l-1.6-0.8L451.8,317.2L451.8,317.2z M451.8,319.9v1.5H471l-0.3-0.8l-0.5-0.7"
          />
          <path
            fill="#D3D3D3"
            d="M451.8,319.1v0.8h18.4l-0.8-0.8 M451.8,317.9v1.2h17.6l-1.6-1.2L451.8,317.9L451.8,317.9z M451.8,322.3v1.2
	h15.7c2.1,0,3.3-0.4,3.6-1.2L451.8,322.3L451.8,322.3z M451.8,321.4v0.8H471c0.1-0.3,0.1-0.5,0-0.8L451.8,321.4L451.8,321.4z
	 M451.8,316.2v1h14.2c-1.5-0.5-3.2-0.8-5-1H451.8L451.8,316.2z M371.3,320.6c-0.1,0.3-0.3,0.5-0.3,0.8h19.3v-1.5h-18.4L371.3,320.6
	L371.3,320.6z"
          />
          <path
            fill="#D3D3D3"
            d="M371.1,321.4v0.8h19.3v-0.8L371.1,321.4L371.1,321.4z M371.1,322.3c0.3,0.8,1.5,1.2,3.6,1.2h15.7v-1.2
	L371.1,322.3L371.1,322.3z M372.8,319.1l-0.8,0.8h18.4v-0.8 M374.4,317.9c-0.5,0.3-1.2,0.7-1.6,1.2h17.6v-1.2L374.4,317.9
	L374.4,317.9z M381.2,316.2c-1.9,0.1-3.6,0.4-5,1h14.3v-1H381.2L381.2,316.2z"
          />
          <path
            fill="#D3D3D3"
            d="M376.1,317.2l-1.7,0.8h16v-0.8L376.1,317.2L376.1,317.2z"
          />
          <path fill="#D3D3D3" d="M390.4,319.9v3.4h1.8v-7.2h-1.8v2.9" />
          <path
            fill="#D3D3D3"
            d="M472.3,315l0.3,0.1c1,0.3,1.9,0.5,2.9,1.1l3.3,1.5c0.8,0.4,1.6,0.7,2.6,0.8l3.4,0.3h10.3
	c3.3-1.1,4.7-3.4,3.7-7v-0.1c-2.6-6.3-5-11.1-7-14.7c-2.6-4.7-7.6-7.3-14.9-8c-11.8-1.1-26.4-1.6-43.7-1.6h-25.6
	c-16.9,0.1-31.2,0.7-42.8,1.6c-7.2,0.7-12.1,3.3-14.9,8c-2.1,3.6-4.4,8.4-7,14.7v0.1c-0.8,3.6,0.4,5.9,3.7,7H357l3.4-0.3
	c0.8-0.3,1.8-0.5,2.6-0.8l3.3-1.5c1-0.4,1.9-0.8,2.9-1.1l0.3-0.1l0.5-0.1l2.5-0.3h96.3l2.5,0.3L472.3,315L472.3,315z M476.9,290.6
	h0.5c2.3,0.3,4.3,0.7,6.1,1.5c2.9,1.2,5.2,3,6.6,5.5c2.1,3.4,4.3,8.3,6.9,14.4c0.4,1.7,0.1,2.9-1,3.7l-1.1,0.7
	c0,0.1-0.1,0.1-0.4,0.1h-10.9l-1.9-0.3c-0.8-0.3-1.8-0.5-2.6-1l-2.9-1.4l-1.1-0.4c-0.7-0.3-1.5-0.5-2.2-0.7h-0.3
	c-1.1-0.3-2.3-0.4-3.4-0.4h-96.3c-1.2,0-2.3,0.1-3.4,0.4h-0.3c-0.8,0.1-1.5,0.4-2.2,0.7l-1.1,0.4l-2.9,1.4c-0.8,0.4-1.6,0.7-2.6,1
	l-1.9,0.3h-11c-0.3,0-0.3,0-0.4-0.1c-0.4-0.1-0.7-0.4-1.1-0.7c-1-0.8-1.2-2.1-1-3.7c2.6-6.2,4.8-11,6.9-14.4
	c1.5-2.5,3.7-4.3,6.6-5.5c1.8-0.7,3.7-1.2,6.1-1.5h0.5c11.6-1.1,25.7-1.6,42.5-1.6h25.6C450.7,289,465.2,289.7,476.9,290.6
	L476.9,290.6z"
          />
          <path
            fill="#D3D3D3"
            d="M471.3,290.8h6.2h-0.5c-11.7-1.1-26.3-1.6-43.6-1.6h-25.6c-16.9,0.1-31.1,0.7-42.5,1.6h-0.5h6.2
	c0.7,0,1.2,0.4,1.5,1.5l0.3,1.2c0.1,0.7,0.1,1.1-0.1,1.4c-0.3,0.4-0.7,0.5-1.2,0.5l-14.7,0.1c-0.7,0-0.8-0.1-0.7-0.5
	c0-0.3,0.3-0.8,1.1-1.4l1.5-1.4c-2.1,0.8-3.9,2.1-5.1,3.6h135.4c-1.4-1.5-3-2.8-5.2-3.6l1.5,1.4c0.8,0.7,1.2,1.1,1.1,1.4
	c0.1,0.4-0.1,0.5-0.7,0.5l-14.7-0.1c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2C470.1,291.2,470.5,290.8,471.3,290.8
	L471.3,290.8z M468.1,291.3c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15C467.4,290.8,467.9,290.9,468.1,291.3L468.1,291.3z M430.7,291.2
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,290.6,430.6,290.9,430.7,291.2L430.7,291.2z M432,293.5l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	C431.8,294.5,431.8,294.1,432,293.5L432,293.5z M391.4,291.3c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15C390.8,290.8,391.2,290.9,391.4,291.3
	L391.4,291.3z M392.6,293.5l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5H408c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4
	c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5C392.5,294.5,392.5,294.1,392.6,293.5L392.6,293.5z"
          />
          <path
            fill="#D3D3D3"
            d="M429.5,301.6c0.7,0,1.1,0.1,1.4,0.5c0.1,0.1,0.1,0.1,0.1,0.3h1.6c0.3-0.5,0.8-0.7,1.5-0.7h13.3
	c0.7,0,1.1,0.3,1.5,0.7h1.6c0-0.1,0.1-0.1,0.1-0.3c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h2.1
	c0.3-0.4,0.8-0.7,1.5-0.7h16.5c1,0,1.7,0.3,2.3,0.7h2.2c-0.8-1.8-1.6-3.3-2.5-4.7c-0.4-0.7-1-1.4-1.5-2.1H353.7
	c-0.5,0.5-1.1,1.2-1.5,2.1c-0.8,1.4-1.6,2.9-2.5,4.7h2.2c0.5-0.4,1.4-0.7,2.3-0.7h16.5c0.7,0,1.2,0.3,1.5,0.7h2.1
	c0-0.1,0.1-0.1,0.1-0.3c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h1.6c0.3-0.5,0.8-0.8,1.5-0.7h13.3
	c0.7,0,1.2,0.3,1.5,0.7h1.8c0-0.1,0.1-0.3,0.1-0.3c0.3-0.4,0.7-0.5,1.2-0.5L429.5,301.6L429.5,301.6z M450.5,298.1
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5L450.5,298.1L450.5,298.1z M447.4,296.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2
	c0.3-1,0.8-1.5,1.8-1.5H447.4L447.4,296.3z M430.7,296.7c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9
	c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,296.1,430.6,296.4,430.7,296.7
	L430.7,296.7z M485.5,296.5c1.1,0,2.1,0.5,3.2,1.5l1.2,1.2c0.7,0.6,0.8,1,0.7,1.2c0,0.4-0.4,0.5-1.2,0.4H471c-0.7,0-1.1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.4-1.2c0.3-1,0.8-1.5,1.8-1.5L485.5,296.5L485.5,296.5z M373.9,298.1c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5L373.9,298.1L373.9,298.1z M370.5,296.4c1,0,1.5,0.4,1.8,1.5c0.1,0.4,0.1,0.8,0.4,1.2c0.1,0.7,0.1,1.1-0.1,1.4
	c-0.3,0.4-0.7,0.5-1.2,0.5H353c-0.8,0-1.2-0.1-1.2-0.4c-0.1-0.3,0-0.7,0.7-1.2l1.2-1.2c1.1-1,2.1-1.5,3.2-1.5L370.5,296.4
	L370.5,296.4z M408,296.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5H408L408,296.3z"
          />
          <path
            fill="#D3D3D3"
            d="M409.5,302.5c0.1,0.3,0.3,0.4,0.3,0.7l0.3,1.2c0.1,0.6,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.1-0.3,0.1-0.5,0.3-0.7h-1.6c0.1,0.3,0,0.7,0,1.1l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.4-0.1-0.8,0-1.1h-2.1c0.1,0.3,0.3,0.4,0.4,0.8l0.4,1.2
	c0.1,0.7,0.1,1.1-0.1,1.4c-0.3,0.4-0.7,0.5-1.2,0.5h-20.5c-0.8,0-1.4-0.1-1.4-0.5c-0.3-0.3,0-0.8,0.4-1.4l1-1.2
	c0.3-0.3,0.5-0.5,0.8-0.8h-2.1c-1,1.9-2.1,4.3-3,6.7h2.2l0.3-0.4c0.5-1,1.6-1.5,3.2-1.5h18.4c1,0,1.7,0.5,1.9,1.5l0.1,0.4h1.5v-0.1
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4v0.1h1.2l0.1-0.4
	c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.1,0.4h1.5v-0.1c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6
	c0.7,0,1.1,0.1,1.4,0.5c0.3,0.3,0.3,0.7,0.1,1.4v0.1h1.2l0.1-0.4c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.1,0.4h1.2v-0.1
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4v0.1h1.5l0.1-0.4
	c0.3-1,1-1.5,1.9-1.5h18.4c1.7,0,2.8,0.4,3.2,1.5l0.3,0.4h2.2c-1.1-2.5-2.1-4.8-3-6.7h-2.2c0.3,0.3,0.5,0.5,0.8,0.8l1,1.2
	c0.5,0.5,0.7,1.1,0.4,1.4c0,0.4-0.5,0.5-1.4,0.5h-20.5c-0.5,0-1.1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.4-1.2l0.4-0.8h-2.1
	c0.1,0.3,0,0.7,0,1.1l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.4-0.1-0.8,0-1.1h-1.7
	c0.1,0.3,0.3,0.4,0.3,0.7l0.3,1.2c0.1,0.6,0.1,1.1-0.1,1.4c-0.3,0.4-0.6,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0-0.3,0.1-0.5,0.3-0.7h-1.6c0.1,0.3,0,0.5,0,1l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9
	c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.4-0.1-0.7,0-1L409.5,302.5L409.5,302.5z"
          />
          <path
            fill="#D3D3D3"
            d="M392.6,310l0.3-0.8h-1.2l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2h-1.5l0.3,0.8
	c0.1,0.6,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-21.7c-1,0-1.5-0.1-1.6-0.5c-0.3-0.3-0.3-0.8,0-1.4l0.4-0.8h-2.2
	c-0.4,1-0.8,1.9-1.2,3c-0.4,1.6-0.1,2.9,1,3.7l0.8-1.5c0.5-1,1.6-1.5,3.2-1.5h18.4l0.8,0.1h0.3c1.1-0.3,2.3-0.4,3.4-0.4h96.3
	c1.2,0,2.3,0.1,3.4,0.4h0.3l0.8-0.1h18.4c1.6,0,2.6,0.4,3.2,1.5l0.8,1.5c1-0.8,1.2-2.1,1-3.7c-0.4-1.1-0.8-2.1-1.2-3h-2.2l0.4,0.8
	c0.3,0.6,0.4,1.1,0,1.4c-0.1,0.4-0.7,0.5-1.7,0.5h-21.7c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.8-0.1-1.4l0.3-0.8h-1.5l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2h-1.2l0.3,0.8c0.1,0.6,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.1-0.8H431l-0.4,1.1c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.3-1.1
	h-1.5l0.1,0.8c0.1,0.6,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5C392.5,311,392.5,310.6,392.6,310
	L392.6,310z M432.1,294.8c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5
	h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2C431.8,294.1,431.8,294.5,432.1,294.8L432.1,294.8z M432.8,293.6l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1
	C432.6,294.2,432.6,293.9,432.8,293.6z M432,299c-0.1,0.5-0.1,1.1,0.1,1.4c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5L432,299z M432.8,299.3l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1
	C432.6,299.8,432.6,299.6,432.8,299.3L432.8,299.3z M432.1,305.8c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2
	C431.8,305.1,431.8,305.5,432.1,305.8z M432.8,304.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1C432.6,305.2,432.6,304.9,432.8,304.7L432.8,304.7z M430.7,291.2
	c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6c-0.7,0-1.1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9
	c1.1,0,1.6-0.5,2.1-1.5l0.4-1.2C431.1,292,431,291.4,430.7,291.2L430.7,291.2z M430,291.7c0.1,0.1,0.1,0.4,0,0.7l-0.4,1.2
	c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h16.6
	C429.7,291.6,429.9,291.6,430,291.7L430,291.7z M430.7,296.7c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6c-0.7,0-1.1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9c1.1,0,1.6-0.5,2.1-1.5l0.4-1.2C431.1,297.5,431,297,430.7,296.7
	L430.7,296.7z M429.5,297.1c0.3,0,0.4,0,0.5,0.1s0.1,0.4,0,0.7l-0.4,1.2c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9c-0.5,0-1-0.3-1.1-0.8
	l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7s0.3-0.1,0.5-0.1L429.5,297.1L429.5,297.1z M430.7,302c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6
	c-0.7,0-1.1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9c1.1,0,1.6-0.6,2.1-1.5l0.4-1.2
	C431.1,302.9,431,302.5,430.7,302L430.7,302z M429.6,304.5c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2
	c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h16.6c0.3,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0,0.7L429.6,304.5L429.6,304.5z"
          />
          <path
            fill="#D3D3D3"
            d="M430.9,309.1c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6c-0.7,0-1.1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9c1.1,0,1.6-0.5,2.1-1.5L430.9,309.1L430.9,309.1z M429.6,310.2
	c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h16.6
	c0.3,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0,0.7L429.6,310.2L429.6,310.2z M432.2,308.6l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4
	c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3
	C433.1,307.3,432.5,307.7,432.2,308.6L432.2,308.6z M432.8,310.8v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8
	l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15C433,311,432.8,311,432.8,310.8L432.8,310.8z M467.9,293.9l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3C467,295.3,467.6,294.9,467.9,293.9L467.9,293.9z M467.4,291.7v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8
	h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15C467.2,291.6,467.3,291.6,467.4,291.7L467.4,291.7z
	 M468.1,296.8c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3
	c1,0,1.5-0.5,1.8-1.5l0.3-1.2C468.4,297.5,468.3,297.1,468.1,296.8L468.1,296.8z M466.9,297.1c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1L466.9,297.1L466.9,297.1z M469.8,297.8
	l-0.4,1.2c-0.1,0.7-0.1,1.1,0.1,1.4c0.3,0.4,0.7,0.5,1.2,0.5H489c0.8,0,1.2-0.1,1.2-0.4c0.1-0.3,0-0.7-0.7-1.2l-1.2-1.2
	c-1.1-1-2.1-1.5-3.2-1.5l-13.8-0.1C470.7,296.4,470.2,296.8,469.8,297.8L469.8,297.8z M470.3,300c-0.1-0.1-0.1-0.4,0-0.7l0.3-1.2
	c0.1-0.5,0.5-0.8,1-0.8l14.3,0.1c0.7,0,1.2,0.3,1.8,0.8l1.2,1.2c0.3,0.3,0.4,0.5,0.4,0.7c0,0.1-0.3,0.1-0.5,0.1l-17.9-0.1
	C470.6,300.1,470.5,300.1,470.3,300L470.3,300z M467.9,304.8l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15
	c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3C467,306.3,467.6,305.8,467.9,304.8L467.9,304.8
	z M467.4,303.3l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15
	c0.3,0,0.4,0,0.5,0.1C467.4,302.7,467.4,303,467.4,303.3L467.4,303.3z M450.7,307.7c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15
	C451.3,307.3,450.9,307.4,450.7,307.7z M451.3,308.9v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L451.3,308.9L451.3,308.9z M469.5,310c-0.1,0.6-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5h21.6c1,0,1.5-0.1,1.6-0.5c0.3-0.3,0.3-0.8,0-1.4l-0.7-1.2c-0.5-1-1.6-1.5-3.2-1.5h-18.4
	c-1,0-1.6,0.5-1.9,1.5L469.5,310L469.5,310z M470.7,311c-0.3,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.4,0-0.7l0.4-1.2
	c0.1-0.5,0.5-0.8,1.1-0.8h18.7c1,0,1.6,0.3,1.9,0.8l0.7,1.2c0.3,0.3,0.3,0.5,0.1,0.7c-0.1,0.1-0.3,0.1-0.8,0.1H470.7L470.7,311z
	 M375.3,290.8c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5L375.3,290.8L375.3,290.8z M374.7,292.4c-0.1-0.3-0.1-0.5,0-0.7
	c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L374.7,292.4
	L374.7,292.4z M375.3,296.3c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5
	l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5L375.3,296.3L375.3,296.3z M374.7,297.2c0.1-0.1,0.3-0.1,0.5-0.1h15
	c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2
	C374.7,297.6,374.7,297.4,374.7,297.2L374.7,297.2z M375.3,301.6c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5L375.3,301.6
	L375.3,301.6z M375,304.5l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3C375.6,305.3,375.1,305.1,375,304.5L375,304.5z M372.7,299.2l-0.4-1.2c-0.3-1-0.8-1.5-1.8-1.5
	l-13.8,0.1c-1.1,0-2.1,0.5-3.2,1.5l-1.2,1.2c-0.7,0.6-0.8,1-0.7,1.2c0,0.4,0.4,0.5,1.2,0.4h18.3c0.7,0,1.1-0.1,1.2-0.5
	C372.8,300.1,372.8,299.7,372.7,299.2L372.7,299.2z M371.4,298.1l0.3,1.2c0.1,0.3,0.1,0.6,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1l-17.9,0.1
	c-0.3,0-0.5,0-0.5-0.1c0-0.1,0.1-0.4,0.4-0.7l1.2-1.2c0.5-0.5,1.1-0.8,1.8-0.8l14.3-0.1C370.9,297.2,371.3,297.5,371.4,298.1
	L371.4,298.1z M372.7,304.5l-0.4-1.2c-0.3-1-1-1.5-1.9-1.5h-16.5c-1.4,0-2.3,0.5-3,1.5l-1,1.2c-0.5,0.5-0.7,1.1-0.4,1.4
	c0,0.4,0.5,0.5,1.4,0.5h20.5c0.5,0,1.1-0.1,1.2-0.5C372.8,305.6,372.8,305.1,372.7,304.5L372.7,304.5z M370.3,302.6
	c0.5,0,0.8,0.3,1.1,0.8l0.4,1.2v0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-20.1c-0.4,0-0.5,0-0.7-0.1c0-0.1,0-0.4,0.3-0.7l1-1.2
	c0.4-0.5,1-0.8,1.8-0.8H370.3L370.3,302.6z M374,307.7c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3
	c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5h-15C374.6,307.3,374.3,307.4,374,307.7L374,307.7z
	 M374.7,308.9c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8L374.7,308.9z M372.5,311.4c0.3-0.3,0.3-0.8,0.1-1.4l-0.4-1.2c-0.3-1-1-1.5-1.9-1.5h-18.4
	c-1.6,0-2.6,0.4-3.2,1.5L348,310c-0.3,0.6-0.4,1.1,0,1.4c0.1,0.4,0.7,0.5,1.6,0.5h21.7C372,311.9,372.4,311.7,372.5,311.4
	L372.5,311.4z M371.8,310.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-21.6c-0.4,0-0.7,0-0.8-0.1c-0.1-0.1-0.1-0.4,0.1-0.7
	l0.7-1.2c0.3-0.5,1-0.8,1.9-0.8h18.7c0.5,0,0.8,0.3,1.1,0.8L371.8,310.2L371.8,310.2z M409.8,292.1c-0.3-1-0.8-1.5-1.8-1.5h-13.3
	c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4
	L409.8,292.1L409.8,292.1z M408,291.6c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7s-0.3,0.1-0.5,0.1h-15
	c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8L408,291.6L408,291.6z M408.8,300.9c0.5,0,1-0.1,1.2-0.5
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5L408.8,300.9L408.8,300.9z M409.4,299.3c0.1,0.3,0.1,0.6,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15
	c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8H408c0.5,0,0.8,0.3,1,0.8L409.4,299.3L409.4,299.3z M408.8,306.3
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2
	c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5L408.8,306.3L408.8,306.3z M408,302.6c0.5,0,0.8,0.3,1,0.8l0.3,1.2
	c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8H408L408,302.6z
	 M394.7,307.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.6-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5L394.7,307.3L394.7,307.3z M393.7,308.9c0.1-0.5,0.4-0.8,1-0.8H408
	c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7L393.7,308.9
	L393.7,308.9z M477.5,290.8h-6.2c-0.7,0-1.2,0.4-1.5,1.5l-0.3,1.2c-0.1,0.7-0.1,1.1,0.1,1.4c0.3,0.4,0.7,0.5,1.2,0.5l14.7,0.1
	c0.7,0,0.8-0.1,0.7-0.5c0-0.3-0.3-0.8-1.1-1.4l-1.5-1.4C481.9,291.4,479.8,291,477.5,290.8L477.5,290.8z M470.7,294.5
	c-0.3,0-0.4,0-0.4-0.1c0-0.1-0.1-0.4,0-0.7l0.3-1.2c0.1-0.5,0.4-0.8,0.8-0.8h10.3c0.4,0,1,0.3,1.5,0.8l2.1,2.1
	c0,0.1-0.1,0.1-0.4,0.1L470.7,294.5L470.7,294.5z M473.8,312.9l-0.8,0.1c0.8,0.1,1.5,0.4,2.2,0.7h17.2c1,0,1.6,0.3,1.9,0.8l0.7,1.2
	c0.1,0.3,0.3,0.5,0.1,0.7l1.1-0.7l-0.8-1.5c-0.5-1-1.6-1.5-3.2-1.5L473.8,312.9z M372.5,294.9c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.5-1.5h-6.2c-2.3,0.3-4.3,0.7-6.1,1.5l-1.5,1.4c-0.8,0.7-1.2,1.1-1.1,1.4c-0.1,0.4,0.1,0.5,0.7,0.5l14.7-0.1
	C372,295.4,372.2,295.2,372.5,294.9L372.5,294.9z M370.7,291.6c0.4,0,0.7,0.3,0.8,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1l-14,0.1
	c-0.3,0-0.3,0-0.4-0.1c0-0.1,0.3-0.4,0.7-0.7l1.4-1.4c0.5-0.5,1.1-0.8,1.5-0.8L370.7,291.6L370.7,291.6z M369.2,313l-0.8-0.1H350
	c-1.6,0-2.8,0.4-3.2,1.5l-0.8,1.5c0.3,0.3,0.7,0.4,1.1,0.7c-0.1-0.1-0.1-0.4,0.1-0.7l0.7-1.2c0.3-0.6,1-0.8,1.9-0.8H367
	C367.7,313.5,368.4,313.2,369.2,313L369.2,313z M493.7,316.8h1.1 M348.5,316.8h-1.1h0.1"
          />
          <path
            fill="#FFFFFF"
            d="M382.2,326.8c-1.4,0-1.9,0.7-1.9,1.9v14.2c0,1.4,0.7,1.9,1.9,1.9H460c1.4,0,1.9-0.7,1.9-1.9v-14.2
	c0-1.4-0.7-1.9-1.9-1.9L382.2,326.8L382.2,326.8z M357,333.3c0-1.8-1-2.8-2.8-2.8h-3.6c-1.8,0-2.8,1-2.8,2.8v3.6
	c0,1.8,1,2.8,2.8,2.8h3.6c1.8,0,2.8-1,2.8-2.8L357,333.3L357,333.3z M471.6,350.3h19.7c1.4,0,1.9,0.7,1.9,1.9v2.8h4.7
	c0.1-0.7,0.1-1.2,0.1-1.9c0.4-4.5-2.3-6.7-8.1-6.6H352.2c-5.8-0.1-8.4,2.1-8.1,6.6c0,0.7,0.1,1.2,0.1,1.9h4.7v-2.8
	c0-1.4,0.7-1.9,1.9-1.9h19.7c1.4,0,1.9,0.7,1.9,1.9v2.8h3.6c0-0.1,0.1-0.3,0.1-0.3c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5
	c0,0.1,0.1,0.1,0.1,0.3h1.6c0.3-0.5,0.8-0.8,1.5-0.8h13.3c0.7,0,1.2,0.3,1.5,0.8h1.2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.4,0.5-0.5,1.1-0.5
	H428l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3v0.1h1.1c0.3-0.5,0.8-0.8,1.5-0.8h13.3c0.7,0,1.2,0.3,1.5,0.8h1.6
	c0-0.1,0.1-0.3,0.1-0.3c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h3.6v-2.8
	C469.6,350.9,470.2,350.3,471.6,350.3L471.6,350.3z M464.7,348.5c0.5,0,1,0.1,1.2,0.6c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5L464.7,348.5
	L464.7,348.5z M429.1,349.1l0.1,0.3c0.1,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5
	l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.1-0.5h13.9l0.7,0.1C428.9,348.8,429.1,349,429.1,349.1L429.1,349.1z
	 M430.2,349.6c0.3-0.7,0.8-1.1,1.6-1.1h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15
	c-0.4,0-0.8-0.1-1.1-0.3l-0.1-0.1c-0.1-0.1-0.1-0.4-0.1-0.5c0-0.3,0-0.4,0.1-0.7L430.2,349.6L430.2,349.6z M392.5,348.5
	c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.5c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5H392.5L392.5,348.5z M394.8,351.3l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	C394.7,352.4,394.7,352,394.8,351.3L394.8,351.3z"
          />
          <path
            fill="#FFFFFF"
            d="M448.3,354.9h-1.6c0.1,0.1,0.3,0.4,0.3,0.7l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15
	c-0.4,0-0.8-0.1-1.1-0.3l-0.1-0.1c-0.1-0.1-0.1-0.4-0.1-0.5c0-0.3,0-0.4,0.1-0.8l0.4-1.6c0-0.1,0.1-0.3,0.1-0.4h-1.1v1l-0.4,1.4
	c-0.3,1-0.8,1.4-1.6,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2c-0.1-0.4-0.1-0.7,0-1h-1.2c0.1,0.1,0.3,0.4,0.3,0.7l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2
	c0.1-0.3,0.1-0.5,0.3-0.7h-1.6c0.1,0.3,0,0.7,0,1.1l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-12.9c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.4-0.1-0.8,0-1.1h-3.6v8.5h4.3l-0.4-0.8l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.1,0.3-0.3,0.7-0.4,0.8h1.8c-0.1-0.3-0.1-0.7,0-1.2l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,0.8,0,1.2h1.4c-0.1-0.3-0.3-0.5-0.4-1l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.1-0.4,0.5-0.5,1.1-0.5h13.9l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,0.8v0.3l-0.4,1.4l-0.4,0.8h1.2
	c0-0.1-0.1-0.3-0.1-0.4c0-0.3,0-0.4,0.1-0.8l0.4-1.6c0.3-0.7,0.8-1.1,1.6-1.1h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,0.8,0,1.2h1.8l-0.4-0.8l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.1,0.3-0.1,0.7-0.4,0.8h4.3v-8.5h-3.6c0.1,0.3,0,0.7,0,1.1l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5
	h-13.3c-1,0-1.5-0.6-1.8-1.5l-0.3-1.2C448.3,355.6,448.3,355.1,448.3,354.9L448.3,354.9z M497.8,354.9h-4.7v0.5
	c-0.1,3.6-1.4,6.2-3.9,8h5.6C496.5,360.6,497.4,357.9,497.8,354.9L497.8,354.9z M348.9,354.9h-4.7c0.4,2.9,1.4,5.8,3,8.5h5.6
	c-2.5-1.8-3.7-4.4-3.9-8v-0.5H348.9L348.9,354.9z M394.8,363.5H393c-0.3,0.4-0.8,0.7-1.4,0.7h-13.3c-0.7,0-1.1-0.3-1.4-0.7h-4.3v0.7
	c0,1.4-0.7,1.9-1.9,1.9H363c-4.4-0.1-7.8-1-10.2-2.6h-5.6c0.7,1.1,1.4,2.2,2.2,3.3c3,3.6,9.8,5.4,20.4,5.2h102.6
	c10.6,0,17.3-1.8,20.4-5.2c0.8-1.1,1.5-2.2,2.2-3.3h-5.6c-2.3,1.6-5.6,2.5-10.2,2.6h-7.7c-1.4,0-1.9-0.7-1.9-1.9v-0.7h-4.3
	c-0.3,0.4-0.8,0.7-1.4,0.7h-13.3c-0.5,0-1.1-0.1-1.4-0.7h-1.8l-0.1,0.1c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.4,0-0.8-0.1-1.1-0.3
	l-0.1-0.1l-0.1-0.1h-1.2c-0.3,0.4-0.7,0.5-1.2,0.5h-12.2c-0.5,0-1-0.1-1.2-0.5h-1.4l-0.1,0.1c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5C394.8,363.7,394.8,363.5,394.8,363.5L394.8,363.5z M395.1,366.6c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4
	L395.1,366.6L395.1,366.6z M392.5,365c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.5
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5L392.5,365L392.5,365z M413.1,365.6
	c0.1-0.4,0.5-0.5,1.1-0.5H428l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4
	h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2C412.8,366.3,413,365.9,413.1,365.6L413.1,365.6z M464.7,365c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.3-0.4,0.5-0.5,1.2-0.5L464.7,365L464.7,365z M430.2,366.1c0.3-0.7,0.8-1.1,1.6-1.1h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.4,0-0.8-0.1-1.1-0.3l-0.1-0.1c-0.1-0.1-0.1-0.4-0.1-0.5
	c0-0.3,0-0.4,0.1-0.8L430.2,366.1L430.2,366.1z"
          />
          <path
            fill="#D3D3D3"
            d="M393.6,349.1c-0.3-0.4-0.5-0.6-1.2-0.6h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2C393.8,349.9,393.8,349.4,393.6,349.1L393.6,349.1z M392.9,349.5v0.7
	l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h15
	C392.7,349.4,392.9,349.5,392.9,349.5L392.9,349.5z M394.8,351.3c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5L394.8,351.3L394.8,351.3
	z M395.9,350.3c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15
	c-0.3,0-0.4,0-0.4-0.1v-0.7L395.9,350.3L395.9,350.3z M393.6,354.6c-0.3-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2C393.8,355.4,393.8,354.9,393.6,354.6
	L393.6,354.6z M392.5,354.9c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2
	c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1L392.5,354.9L392.5,354.9z M393.6,360c-0.3-0.4-0.5-0.5-1.2-0.5h-15
	c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	C393.8,360.8,393.8,360.2,393.6,360L393.6,360z M392.5,360.4c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1L392.5,360.4L392.5,360.4z M395.1,355.7l-0.3,1.2
	c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3C395.9,354.2,395.4,354.6,395.1,355.7L395.1,355.7z M395.6,357.8v-0.7l0.3-1.2c0.1-0.6,0.4-0.8,1-0.8
	h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7s-0.3,0.1-0.5,0.1h-15C395.8,357.9,395.6,357.9,395.6,357.8L395.6,357.8z
	 M395.1,361.1l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3C395.9,359.5,395.4,360.1,395.1,361.1L395.1,361.1z M395.6,363.3v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8
	h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15C395.8,363.4,395.6,363.3,395.6,363.3
	L395.6,363.3z M414.2,348.5c-0.5,0-1,0.1-1.1,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.7,1.5h12.2
	c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1L414.2,348.5L414.2,348.5z M413.8,350.2
	c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.4-0.1h14.2l0.1,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8H415c-0.5,0-0.8-0.3-1-0.8
	L413.8,350.2L413.8,350.2z M414.2,354c-0.5,0-1,0.1-1.1,0.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.7,1.5h12.2
	c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1L414.2,354L414.2,354z M413.8,355
	c0.1-0.1,0.3-0.1,0.4-0.1h13.9l0.3,0.1l0.1,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8H415c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2
	C413.7,355.4,413.7,355.1,413.8,355L413.8,355z M414.2,359.4c-0.5,0-1,0.1-1.1,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.7,1.5h12.2c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-0.8l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1L414.2,359.4
	L414.2,359.4z M413.8,361.1c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.4-0.1h14.2l0.1,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8H415
	c-0.5,0-0.8-0.3-1-0.8L413.8,361.1L413.8,361.1z M410.2,365h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2C411.9,365.6,411.2,365,410.2,365L410.2,365z
	 M411.6,368.1c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3
	c0.5,0,0.8,0.3,1,0.8L411.6,368.1L411.6,368.1z M372.5,352.3c0-1.4-0.7-1.9-1.9-1.9h-19.7c-1.4,0-1.9,0.7-1.9,1.9v3.3
	c0.1,6.9,4.8,10.5,14,10.6h7.7c1.4,0,1.9-0.7,1.9-1.9v-12L372.5,352.3L372.5,352.3z M369.6,351c1.2,0,1.8,0.5,1.8,1.8v10.7
	c0,1.2-0.5,1.8-1.8,1.8h-6.9c-8.3-0.3-12.5-3.4-12.7-9.6v-2.9c0-1.2,0.5-1.8,1.8-1.8L369.6,351L369.6,351z"
          />
          <path
            fill="#D3D3D3"
            d="M371.4,352.8c0-1.2-0.5-1.8-1.8-1.8H365c2.6,0.3,4,1.6,4.1,4.3v6.6c0.3,1.8-1.1,2.9-4.3,3.3h5
	c1.2,0,1.8-0.5,1.8-1.8L371.4,352.8L371.4,352.8z M376.5,368.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4L376.5,368.2L376.5,368.2z
	 M376.9,366c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8
	l-0.3-1.2C376.9,366.4,376.9,366.1,376.9,366L376.9,366z M413.1,365.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.6,1.5h12.2
	c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1h-13.8C413.7,365,413.4,365.2,413.1,365.6
	L413.1,365.6z M413.8,366.7c-0.1-0.3-0.1-0.5,0-0.7s0.3-0.1,0.4-0.1h13.9l0.3,0.1l0.1,0.1v0.7l-0.3,1.2c-0.1,0.6-0.4,0.8-1,0.8H415
	c-0.5,0-0.8-0.3-1-0.8L413.8,366.7z M449.7,348.5c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5
	h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5H449.7L449.7,348.5z M449.1,350.2v-0.7
	c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L449.1,350.2
	L449.1,350.2z M449.7,354c-0.5,0-1,0.1-1.2,0.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.6,1.8-1.5
	l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5L449.7,354L449.7,354z M449.1,355.1c0.1-0.1,0.3-0.1,0.4-0.1h15
	c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2
	C449.1,355.4,449.1,355.3,449.1,355.1L449.1,355.1z M447.2,352.7c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432
	c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.3,0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15
	C446.7,353.2,447.1,353.1,447.2,352.7L447.2,352.7z M431,352.4l-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3
	c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1L431,352.4L431,352.4z M447.2,358.3c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1
	c0.3,0.3,0.5,0.3,1.1,0.3h15C446.7,358.9,447.1,358.6,447.2,358.3L447.2,358.3z M430.6,357.9c-0.1-0.1-0.1-0.4,0-0.7l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15L430.6,357.9L430.6,357.9z M449.7,359.5
	c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5L449.7,359.5L449.7,359.5z M449.6,362.4l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1
	h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3C450,363.3,449.7,363,449.6,362.4L449.6,362.4z M447.2,363.7
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8
	s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15C446.7,364.2,447.1,364.1,447.2,363.7L447.2,363.7z M430.6,362.6l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15l-0.4-0.1
	C430.4,363.1,430.4,362.8,430.6,362.6L430.6,362.6z M471.6,350.3c-1.4,0-1.9,0.7-1.9,1.9v12c0,1.4,0.7,1.9,1.9,1.9h7.7
	c9.2-0.3,13.8-3.7,14-10.6v-3.3c0-1.4-0.7-1.9-1.9-1.9L471.6,350.3L471.6,350.3z M472.5,351h17.7c1.2,0,1.8,0.5,1.8,1.8v2.9
	c-0.1,6.2-4.4,9.4-12.7,9.6h-6.9c-1.2,0-1.8-0.5-1.8-1.8v-10.7C470.7,351.7,471.3,351,472.5,351L472.5,351z"
          />
          <path
            fill="#D3D3D3"
            d="M477.2,351h-4.7c-1.2,0-1.8,0.5-1.8,1.8v10.7c0,1.2,0.5,1.8,1.8,1.8h5c-3.2-0.4-4.5-1.5-4.3-3.3v-6.6
	C473.2,352.8,474.6,351.3,477.2,351L477.2,351z M464.7,365h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4C465.7,365.3,465.2,365,464.7,365L464.7,365z
	 M465.2,366v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2V366c0.1-0.1,0.3-0.1,0.4-0.1h15
	C465,365.9,465.1,366,465.2,366L465.2,366z M445.3,365H432c-0.8,0-1.4,0.4-1.7,1.1l-0.4,1.7c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5
	l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2C446.8,365.6,446.1,365,445.3,365
	L445.3,365z M446.5,368.8c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3
	c0.5,0,0.8,0.3,1,0.8l0.3,1.2C446.7,368.3,446.7,368.6,446.5,368.8L446.5,368.8z M524.8,354.6c0-2.8-1-5.1-2.9-7
	c-1.9-1.9-4.3-2.9-7-2.9c-2.8,0-5.1,1-7,2.9s-2.9,4.3-2.9,7s1,5.1,2.9,7s4.3,2.9,7,2.9s5.1-1,7-2.9
	C523.8,359.7,524.8,357.3,524.8,354.6L524.8,354.6z M521,348.5c1.7,1.6,2.5,3.7,2.5,6.1s-0.8,4.4-2.5,6.1c-1.7,1.6-3.7,2.5-6.1,2.5
	c-2.3,0-4.4-0.8-6.1-2.5c-1.6-1.6-2.5-3.7-2.5-6.1s0.8-4.4,2.5-6.1c1.7-1.6,3.7-2.5,6.1-2.5C517.2,345.9,519.3,346.9,521,348.5
	L521,348.5z M327.3,364.5c2.8,0,5.1-1,7-2.9s2.9-4.3,2.9-7s-1-5.1-2.9-7s-4.3-2.9-7-2.9c-2.8,0-5.1,1-7,2.9c-1.9,1.9-2.9,4.3-2.9,7
	s1,5.1,2.9,7C322.2,363.5,324.5,364.5,327.3,364.5L327.3,364.5z M333.3,348.5c1.6,1.6,2.5,3.7,2.5,6.1s-0.8,4.4-2.5,6.1
	s-3.7,2.5-6.1,2.5c-2.3,0-4.4-0.8-6.1-2.5c-1.6-1.6-2.5-3.7-2.5-6.1s0.8-4.4,2.5-6.1c1.7-1.6,3.7-2.5,6.1-2.5
	C329.6,346.1,331.7,346.9,333.3,348.5L333.3,348.5z"
          />
          <path
            fill="#FFFFFF"
            d="M359.5,278.8c1.1-3.7-0.4-7.4-4.4-10.9c-5.8-5.5-12.9-9.9-21.6-13.3l-19.7,1.1l-8.1,0.5
	c-1.5,1.4-2.9,2.8-4.3,4.1c-4.4,4.5-7.6,8.9-9.5,13.1v0.1c-0.7,1.4-1.2,2.8-1.6,4c-0.3,0.8-0.4,1.5-0.5,2.3l-0.1,0.7
	c-0.5,2.9-0.3,5.6,0.4,8.3l4.3,21l48.3,2.2C347.5,300.9,353.1,289.9,359.5,278.8L359.5,278.8z M333.9,272.5c5.2,3,7.7,7.4,7.2,13.1
	c-0.7,5.8-2.6,11-5.8,15.5l-0.1,0.1c-2.2,2.5-4.7,4.3-7.3,5.1c-1,0.3-2.1,0.5-3,0.7c-3.2,0.4-7,0.3-11.4-0.7
	c-6.5-1.4-10.9-4.3-13.2-8.8c-2.9-5.4-3.7-11-2.6-17.2c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.7,0.4-1.2,0.7-1.8v-0.1
	c2.9-7,8.3-10.7,16.5-11.1h0.1C321.5,266.4,327.8,268.5,333.9,272.5L333.9,272.5z"
          />
          <path
            fill="#FFFFFF"
            d="M333.7,272.5c-5.9-3.9-12.4-5.9-19-6.2h-0.1c-8.1,0.4-13.6,4.1-16.5,11.1v0.1c-0.3,0.5-0.4,1.2-0.7,1.8
	c-0.1,0.3-0.1,0.5,0,0.8c-1.1,6.2-0.3,12,2.6,17.2c2.3,4.5,6.7,7.6,13.2,8.8c4.4,1,8.1,1.1,11.4,0.7c1.1-0.1,2.1-0.3,3-0.7
	c2.6-0.8,5-2.6,7.3-5.1l0.1-0.1c3.2-4.5,5.1-9.8,5.8-15.5C341.4,279.9,339.1,275.5,333.7,272.5L333.7,272.5z M332.4,274.8
	c4.3,2.5,6.2,5.9,5.9,10.5c-0.5,5.4-2.3,10-5.2,14.2c-1.9,2.1-3.9,3.4-6.1,4.3c-0.8,0.3-1.6,0.4-2.5,0.5c-3,0.4-6.5,0.1-10.6-0.7
	c-5.5-1.1-9.4-3.6-11.4-7.4c-2.9-5.4-3.6-11.3-1.9-17.6c2.5-5.9,7-9.1,13.9-9.5h0.1C320.9,269.2,326.9,271.2,332.4,274.8
	L332.4,274.8z M473.2,372.9H368.9c-10.2,0-16.8-1.5-19.9-4.8h-8c-2.5,0.1-5,0.1-7,0h-0.1c-11-0.7-17.5-4.3-19.3-11
	c-6.7,0-10.6-1.9-11.7-5.9l-1.4-6.9l-9.6-1.2l5,27.8c1,3,3.3,5.5,7.3,7.6c5.5,2.9,14.2,4.7,25.7,5.4c19.1,0.7,29.6-0.1,31-2.8h120.9
	c1.5,2.6,11.8,3.4,31,2.8c11.6-0.7,20.1-2.5,25.7-5.4c3.9-2.1,6.3-4.5,7.2-7.6l5-27.8l-9.6,1.2l-1.4,6.9c-1.1,4-5.1,5.9-11.7,5.9
	c-1.8,6.7-8.1,10.5-19.3,11h-0.1c-2.2,0.1-4.5,0.1-7,0h-8C490,371.2,483.3,372.9,473.2,372.9L473.2,372.9z M543.7,256.8
	c0.8,0.1,1.7,0.3,2.3,0.4l5-7.2c-1.5-1-3.6-1.8-6.2-2.5c0.1,1.9,0.1,3.6,0.1,5C544.6,254.6,544.2,256,543.7,256.8L543.7,256.8z"
          />
          <path
            fill="#FFFFFF"
            d="M546,257.3c-0.7-0.3-1.5-0.4-2.3-0.4l-3,3.4c4.4,4.5,7.6,8.9,9.5,13.1c0.1-1.8,0.3-3.4,0.4-5
	c0.4-2.6,0.8-4.7,1.5-6.3C550.7,259.7,548.6,258,546,257.3L546,257.3z M543.7,256.8c0.7-0.8,1.1-2.2,1.2-4.3
	c-1.2-1.6-2.8-2.8-4.4-2.9c0.7,2.8,0.3,5-1.4,6.6l-2.8-0.1c1.5,1.4,2.9,2.8,4.3,4.1L543.7,256.8L543.7,256.8z"
          />
          <path
            fill="#FFFFFF"
            d="M552,262.1c0.4-1.2,1-2.2,1.7-3c1.7-3.7,0.7-6.7-2.8-8.9l-5,7.2C548.6,258,550.7,259.7,552,262.1L552,262.1z
	 M551.8,268.5c0.1,1.5,0.5,2.9,1.1,4.3l0.3,0.7h4c0.5,0,1-0.3,1.2-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-1c0-0.5-0.1-1-0.5-1.4
	s-0.8-0.5-1.4-0.5h-5.2L551.8,268.5L551.8,268.5z"
          />
          <path
            fill="#FFFFFF"
            d="M552.9,272.7c-0.6-1.4-0.8-2.8-1.1-4.3c-0.3-2.1-0.1-4.1,0.3-6.3c-0.7,1.6-1.2,3.9-1.5,6.3
	c-0.3,1.5-0.4,3.2-0.4,5v0.1c0.7,1.4,1.2,2.8,1.7,4l0.7,3c1.4,1.1,2.6,2.6,3.4,4.5c-0.1-2.5-0.7-5.1-1.4-7.7
	c-0.4-1.4-0.8-2.8-1.4-4.1L552.9,272.7L552.9,272.7z"
          />
          <path
            fill="#FFFFFF"
            d="M557.5,273.7l-0.4-0.3h-4c0.5,1.4,1,2.8,1.4,4.1h1.7c0.5,0,1-0.1,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-0.5
	C558.1,274.5,557.8,274.1,557.5,273.7L557.5,273.7z"
          />
          <path fill="#D3D3D3" d="M550.1,273.4" />
          <path
            fill="#FFFFFF"
            d="M550.1,273.4c-2.1-4.3-5.2-8.5-9.6-13.2c-1.4-1.4-2.8-2.8-4.3-4.1l-8-0.4l-19.7-1.1
	c-8.5,3.4-15.8,7.8-21.6,13.3c-4,3.4-5.4,7.2-4.4,10.9c6.3,11.1,12,22.1,16.8,33.3l48.3-2.2l4.3-21c0.8-2.6,1-5.4,0.4-8.3l-0.7-3
	C551.2,276.2,550.8,274.8,550.1,273.4L550.1,273.4z M543.9,277.4l0.7,1.9c0.1,0.3,0.1,0.5,0,0.8c1.1,6.2,0.3,12-2.6,17.2
	c-2.3,4.5-6.7,7.6-13.2,8.8c-4.4,1-8.1,1.1-11.4,0.7c-1.1-0.1-2.1-0.3-3-0.7c-2.6-0.8-5-2.6-7.3-5.1l-0.1-0.1
	c-3.2-4.5-5.1-9.8-5.8-15.5c-0.5-5.6,1.9-10,7.2-13.1c6.1-3.9,12.4-6.1,19.1-6.2h0.1C535.7,266.7,541.2,270.4,543.9,277.4
	L543.9,277.4z"
          />
          <path
            fill="#FFFFFF"
            d="M544.1,277.4c-2.9-7.2-8.4-10.9-16.5-11.3h-0.1c-6.7,0.3-13.1,2.3-19.1,6.2c-5.4,3-7.7,7.4-7.2,13.1
	c0.7,5.8,2.6,11,5.8,15.5l0.1,0.1c2.2,2.5,4.7,4.3,7.3,5.1c1,0.3,2.1,0.5,3,0.7c3.2,0.4,7,0.3,11.4-0.7c6.5-1.4,10.9-4.3,13.2-8.8
	c2.9-5.4,3.7-11,2.6-17.2c0.1-0.3,0.1-0.5,0-0.8L544.1,277.4L544.1,277.4z M527.4,268.9c7,0.4,11.7,3.4,14,9.5
	c1.7,6.3,1.1,12.2-1.9,17.6c-2.1,3.9-5.8,6.3-11.4,7.4c-4.1,0.8-7.6,1.1-10.6,0.7c-0.8,0-1.7-0.3-2.5-0.5c-2.1-0.7-4.1-2.2-6.1-4.3
	c-2.9-4.1-4.7-8.9-5.2-14.2c-0.4-4.5,1.5-8,5.8-10.5C515.3,271.2,521.2,269.2,527.4,268.9L527.4,268.9z M555.9,285.1
	c-0.8-1.9-2.1-3.4-3.4-4.5c0.5,2.9,0.3,5.6-0.4,8.3l-4.3,21l-7,34.3l9.6-1.2l5.5-31.1l1.9-10.5v-0.1
	C558.2,294.3,557.5,289,555.9,285.1L555.9,285.1z M269.2,245l-0.1,0.4c-1.4,3.9-0.5,7.2,2.3,9.9c3,3,8.8,4.3,17.1,3.7
	c-1.6-3.7-0.7-6.7,2.8-8.9c1.5-1,3.6-1.8,6.2-2.5c0.3-4.3-1.9-7.6-6.5-9.8c-3.3-1.6-7.6-1.6-12.8,0
	C273.5,239.6,270.5,241.9,269.2,245L269.2,245z M350,176c0-0.8-0.3-1.5-0.7-2.2c-0.4-0.6-1.5-1-3.4-1.1c-1.8,0-2.9,0.3-3.3,1.2
	c-0.4,0.7-0.7,1.4-1,2.2c-0.3,1.1-0.4,2.2-0.4,3.4c0,0.6,0.1,1,0.5,1.4c0.4,0.4,0.8,0.6,1.4,0.6h1.4c0.5,0,1-0.1,1.4-0.6
	c0.4-0.4,0.5-0.8,0.5-1.4c0.4-1.9,0.8-3.2,1.4-3.9c0.4-0.4,0.8-0.7,1.2-0.4c0.4,0.1,0.5,0.4,0.7,0.8L350,176L350,176z"
          />
          <path
            fill="#D3D3D3"
            d="M349.7,175.9c-0.1-0.4-0.4-0.7-0.7-0.8c-0.4-0.1-0.8,0-1.2,0.4C348.6,175.6,349.1,175.8,349.7,175.9
	L349.7,175.9z"
          />
          <path
            fill="#FFFFFF"
            d="M328,191h-2.5c-8,17.7-15.1,36.7-21.6,57.1c-0.5,1.2-1.2,1.8-2.2,1.5c-0.7,2.8-0.3,5,1.4,6.6l2.8-0.1
	C311.4,231.5,318.7,209.9,328,191L328,191z"
          />
          <path
            fill="#FFFFFF"
            d="M325.5,191l-0.7,1.2c-7,12.7-13.8,29-20.1,49c-0.5,2.3-1.2,4.5-2.3,6.7c-0.3,0.6-0.5,1.1-0.7,1.6
	c1,0.1,1.6-0.3,2.2-1.5C310.3,227.8,317.5,208.8,325.5,191L325.5,191z M333.7,191.2c1-0.7,0.1-1.1-2.3-1.2c-1.4-0.4-2.5-0.1-3.3,1
	c-9.2,18.8-16.6,40.4-22.1,65.1l8.1-0.6C318.6,231.9,325.2,210.4,333.7,191.2L333.7,191.2z"
          />
          <path
            fill="#FFFFFF"
            d="M301.8,249.6c-1.6,0.3-3.2,1.2-4.4,2.9c0.1,1.9,0.5,3.4,1.2,4.3l3,3.4c1.4-1.4,2.8-2.8,4.3-4.1l-2.8,0.1
	C301.5,254.6,301,252.4,301.8,249.6z"
          />
          <path
            fill="#FFFFFF"
            d="M298.5,256.8c-0.7-0.8-1.1-2.2-1.2-4.3c-0.1-1.4,0-3,0.1-5c-2.6,0.7-4.7,1.5-6.2,2.5l5,7.2
	C296.9,257.1,297.7,256.9,298.5,256.8L298.5,256.8z M296.2,257.3l-5-7.2c-3.4,2.2-4.4,5.1-2.8,8.9c0.7,0.8,1.2,1.8,1.6,3
	C291.5,259.7,293.6,258,296.2,257.3L296.2,257.3z"
          />
          <path
            fill="#FFFFFF"
            d="M298.5,256.8c-0.8,0.1-1.6,0.3-2.3,0.4c-2.6,0.8-4.7,2.3-6.1,4.8c0.7,1.6,1.2,3.9,1.5,6.3
	c0.3,1.5,0.4,3.2,0.4,5c2.1-4.1,5.2-8.5,9.5-13.1L298.5,256.8L298.5,256.8z"
          />
          <path
            fill="#FFFFFF"
            d="M289.3,272.7l-0.3,0.7c-0.5,1.4-1,2.8-1.4,4.1c-0.7,2.6-1.2,5.1-1.4,7.7c0.8-1.9,2.1-3.4,3.4-4.5l0.1-0.7
	c0.1-0.8,0.4-1.5,0.5-2.3c0.4-1.4,1-2.8,1.6-4v-0.1c-0.1-1.8-0.3-3.4-0.4-5c-0.4-2.6-0.8-4.7-1.5-6.3c0.4,2.2,0.5,4.3,0.3,6.3
	C290.1,270,289.7,271.4,289.3,272.7L289.3,272.7z"
          />
          <path
            fill="#FFFFFF"
            d="M289,273.4l0.3-0.7c0.5-1.4,0.8-2.8,1.1-4.3h-5.2c-0.5,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4v1
	c0,0.5,0.1,1,0.5,1.4s0.8,0.5,1.2,0.5h4L289,273.4L289,273.4z"
          />
          <path fill="#D3D3D3" d="M292,273.4" />
          <path
            fill="#FFFFFF"
            d="M294.4,309.9l-4.3-21c-0.8-2.6-1-5.4-0.4-8.3c-1.4,1.1-2.6,2.6-3.4,4.5c-1.8,3.9-2.3,9.2-1.9,16.1v0.1
	l1.9,10.5l5.5,31.1l9.6,1.2L294.4,309.9L294.4,309.9z M284.1,275.1v0.5c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5h1.6
	c0.4-1.4,0.8-2.8,1.4-4.1h-4l-0.4,0.3C284.2,274.1,284.1,274.5,284.1,275.1L284.1,275.1z"
          />
          <path
            fill="#656565"
            d="M433,292.4l-0.3,1.2c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3C433.6,291.6,433.2,291.9,433,292.4z M433,298.1l-0.3,1.2c-0.1,0.3-0.1,0.6,0,0.7
	c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3C433.6,297.2,433.2,297.5,433,298.1
	L433,298.1z M433,303.4l-0.3,1.2c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3C433.6,302.6,433.2,302.9,433,303.4z M430,292.4c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1
	h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9c0.5,0,1-0.3,1.1-0.8L430,292.4L430,292.4z M430,297.2
	c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9c0.5,0,1-0.3,1.1-0.8l0.4-1.2
	L430,297.2L430,297.2z M428.5,305.3c0.6,0,1-0.3,1.1-0.8l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6
	c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8L428.5,305.3L428.5,305.3z M428.5,311c0.6,0,1-0.3,1.1-0.8l0.4-1.2
	c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8H428.5L428.5,311z
	 M432.8,310.2c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3
	c-0.5,0-0.8,0.3-1,0.8L432.8,310.2z M467.4,292.4c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7
	l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8L467.4,292.4L467.4,292.4z M467.4,297.2c-0.1-0.1-0.3-0.1-0.5-0.1h-15
	c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2C467.4,297.6,467.4,297.4,467.4,297.2
	L467.4,297.2z M470.3,299.3c-0.1,0.3-0.1,0.6,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1l17.9,0.1c0.3,0,0.5,0,0.5-0.1c0-0.1-0.1-0.4-0.4-0.7
	l-1.2-1.2c-0.5-0.5-1.1-0.8-1.8-0.8l-14.3-0.1c-0.6,0-0.8,0.3-1,0.8L470.3,299.3L470.3,299.3z M470.3,304.7v0.7
	c0.1,0.1,0.3,0.1,0.5,0.1H491c0.4,0,0.5,0,0.7-0.1c0-0.1,0-0.4-0.3-0.7l-1-1.2c-0.4-0.5-1-0.8-1.8-0.8h-16.8c-0.5,0-0.8,0.3-1.1,0.8
	L470.3,304.7L470.3,304.7z M467,304.5l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7
	l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3C466.6,305.3,466.9,305.1,467,304.5L467,304.5z M451.3,308.2v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8
	h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15C451.6,308.1,451.5,308.1,451.3,308.2
	L451.3,308.2z M470.3,310.8c0.1,0.1,0.3,0.1,0.4,0.1h21.6c0.4,0,0.7,0,0.8-0.1c0.1-0.1,0.1-0.4-0.1-0.7l-0.7-1.2
	c-0.3-0.5-1-0.8-1.9-0.8h-18.7c-0.5,0-0.8,0.3-1.1,0.8l-0.4,1.2C470.2,310.6,470.2,310.7,470.3,310.8L470.3,310.8z M374.7,291.7v0.7
	l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15
	C375,291.6,374.9,291.6,374.7,291.7L374.7,291.7z M375.3,297.1c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1L375.3,297.1L375.3,297.1z M374.7,303.3l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8
	h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1C374.7,302.7,374.7,303,374.7,303.3
	L374.7,303.3z M371.7,299.3l-0.3-1.2c-0.1-0.5-0.5-0.8-1-0.8l-14.3,0.1c-0.7,0-1.2,0.3-1.8,0.8l-1.2,1.2c-0.3,0.3-0.4,0.5-0.4,0.7
	c0,0.1,0.3,0.1,0.5,0.1l17.9-0.1c0.3,0,0.4,0,0.5-0.1C371.8,299.8,371.8,299.6,371.7,299.3L371.7,299.3z M371.4,303.4
	c-0.1-0.5-0.5-0.8-1.1-0.8h-16.8c-0.8,0-1.4,0.3-1.8,0.8l-1,1.2c-0.3,0.3-0.4,0.5-0.3,0.7c0.1,0.1,0.3,0.1,0.7,0.1h20.1
	c0.3,0,0.4,0,0.5-0.1v-0.7L371.4,303.4z M374.7,308.2v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7
	c-0.1-0.1-0.3-0.1-0.4-0.1h-15C375,308.1,374.9,308.1,374.7,308.2L374.7,308.2z M371.8,310.8c0.1-0.1,0.1-0.4,0-0.7l-0.4-1.2
	c-0.1-0.5-0.5-0.8-1.1-0.8h-18.7c-1,0-1.6,0.3-1.9,0.8l-0.7,1.2c-0.3,0.3-0.3,0.5-0.1,0.7c0.1,0.1,0.3,0.1,0.8,0.1h21.7
	C371.6,311,371.7,311,371.8,310.8L371.8,310.8z M409.1,292.4c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7
	c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7L409.1,292.4L409.1,292.4z M409.4,300v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8
	h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15C409.1,300.1,409.3,300,409.4,300L409.4,300z M409.1,303.4
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7L409.1,303.4
	L409.1,303.4z M394.7,308.1c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8L394.7,308.1L394.7,308.1z M470.3,294.3c0.1,0.1,0.3,0.1,0.4,0.1l14,0.1c0.3,0,0.3,0,0.4-0.1l-2.1-2.1
	c-0.5-0.5-1.1-0.8-1.5-0.8h-10.3c-0.4,0-0.7,0.3-0.8,0.8l-0.3,1.2C470.2,294.1,470.2,294.2,470.3,294.3L470.3,294.3z M475.1,313.7
	l1.1,0.4l2.9,1.4c0.8,0.4,1.6,0.7,2.6,1l1.9,0.3h11c0.3,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.4-0.1-0.7l-0.7-1.2c-0.3-0.5-1-0.8-1.9-0.8
	L475.1,313.7L475.1,313.7z M371.6,292.4c-0.1-0.5-0.4-0.8-0.8-0.8h-10.3c-0.4,0-1,0.3-1.5,0.8l-1.4,1.4c-0.4,0.3-0.5,0.5-0.7,0.7
	c0,0.1,0.1,0.1,0.4,0.1l14-0.1c0.3,0,0.4,0,0.4-0.1c0-0.1,0.1-0.4,0-0.7L371.6,292.4L371.6,292.4z M360.3,316.5
	c0.8-0.3,1.8-0.5,2.6-1l2.9-1.4l1.1-0.4h-17.2c-1,0-1.6,0.3-1.9,0.8l-0.7,1.2c-0.1,0.3-0.3,0.5-0.1,0.7c0,0.1,0.1,0.1,0.4,0.1h11
	L360.3,316.5L360.3,316.5z M392.9,350.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8
	h13.3c0.5,0,0.8-0.3,1-0.8L392.9,350.2L392.9,350.2z M396.9,349.4c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8L396.9,349.4L396.9,349.4z M392.9,355.1c-0.1-0.1-0.3-0.1-0.4-0.1h-15
	c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2C393,355.4,393,355.3,392.9,355.1
	L392.9,355.1z M392.9,360.5c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2C393,360.8,393,360.6,392.9,360.5L392.9,360.5z M395.6,357.1v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8L395.6,357.1L395.6,357.1z M395.6,362.6v0.7
	c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8L395.6,362.6
	L395.6,362.6z M413.8,349.5v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7l-0.1-0.1h-14.2
	C413.9,349.4,413.8,349.4,413.8,349.5L413.8,349.5z M414.2,354.9c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2V355l-0.1-0.1l-0.3-0.1h-13.9L414.2,354.9L414.2,354.9z M413.8,360.4v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7l-0.1-0.1h-14.2C413.9,360.2,413.8,360.4,413.8,360.4L413.8,360.4z
	 M411.6,368.8v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	C411.3,368.9,411.5,368.8,411.6,368.8L411.6,368.8z M377.5,365.9c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366c-0.1-0.1-0.3-0.1-0.4-0.1H377.5L377.5,365.9z M413.8,366v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366l-0.1-0.1l-0.3-0.1h-13.9C413.9,365.9,413.8,365.9,413.8,366L413.8,366z M449.1,349.5v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15
	C449.4,349.4,449.3,349.5,449.1,349.5L449.1,349.5z M449.7,354.9c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1L449.7,354.9L449.7,354.9z M430.6,352.3l0.4,0.1h15
	c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2
	C430.4,351.8,430.4,352.1,430.6,352.3L430.6,352.3z M430.6,357.8l0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.6-0.4-0.8-1-0.8H432c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2C430.4,357.5,430.4,357.6,430.6,357.8L430.6,357.8z M449.1,361.2l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1
	C449.1,360.6,449.1,360.8,449.1,361.2L449.1,361.2z M430.9,361.2l-0.3,1.2c-0.1,0.3-0.1,0.5,0,0.7l0.4,0.1h15c0.3,0,0.4,0,0.4-0.1
	v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3C431.4,360.4,431,360.6,430.9,361.2L430.9,361.2z M465.2,366.7c0.1-0.3,0.1-0.5,0-0.7
	c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8L465.2,366.7
	L465.2,366.7z M446.1,368.9c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H432c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2
	c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.4,0.1L446.1,368.9L446.1,368.9z M359.6,383.8h8l1.9,1.8c0.4,0.4,0.8,0.5,1.4,0.5l2.6-0.1
	c0.4-0.1,1.1-0.8,2.1-2.2h19.8c2.9,2.2,9.6,3.4,20.2,3.9l7,0.1c12.9-0.1,21-1.5,24.4-4h19.8c1,1.4,1.6,2.1,2.1,2.2l2.6,0.1
	c0.5,0,1-0.1,1.4-0.5l1.9-1.8h8c1.1,3.3,3.2,5.6,5.9,7.2c4.3,2.2,11.1,3.2,20.4,2.9c5.4-0.5,9.9-1.1,13.6-1.6c5-0.8,8.3-1.8,9.9-2.9
	c2.6-0.8,4.7-4.5,5.9-11c-5.5,2.9-14.2,4.7-25.7,5.4c-19.1,0.7-29.6-0.1-31-2.8H360.8c-1.5,2.6-11.8,3.4-31,2.8
	c-11.6-0.7-20.1-2.5-25.7-5.4c1.2,6.6,3.3,10.3,5.9,11c1.8,1.1,5.1,2.1,9.9,2.9c3.7,0.5,8.1,1.2,13.6,1.6c9.4,0.3,16.1-0.7,20.4-2.9
	C356.6,389.4,358.5,387.1,359.6,383.8L359.6,383.8z"
          />
          <path
            fill="#656565"
            d="M522.5,392.1v10.7c0.1,5.4,1.7,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8h11.3c1.8,0.1,3.3,0.1,4.7,0
	c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-91.1l-5.5,31.1l-5,27.8c-1,3-3.3,5.5-7.3,7.6c-1.2,6.6-3.2,10.3-5.9,11
	C530.6,390.4,527.3,391.3,522.5,392.1L522.5,392.1z M303.9,378.2c-3.9-2.1-6.3-4.5-7.2-7.6l-5-27.8l-5.5-31.1v91.1
	c0.1,5.4,1.7,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8c1.4,0.1,2.9,0.1,4.7,0H311c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-10.7
	c-5-0.8-8.3-1.8-9.9-2.9C307,388.4,305.1,384.9,303.9,378.2L303.9,378.2z"
          />
          <path
            fill="#EBEBEB"
            d="M455.9,190.1h-70.6c-15.5,0-32.7,0.4-51.6,1.2c-8.5,19.1-15.1,40.7-19.8,64.4l19.7-1.1l55.4-2.2l18.2-0.4
	h27.9l18.7,0.4l54.9,2.2l19.5,1.1c-4.7-23.8-11.3-45.3-19.8-64.4C489.2,190.5,471.7,190.1,455.9,190.1L455.9,190.1z M493.3,195.7
	c7.6,0,12.8,2.8,15.7,8.3c4.4,9.4,8,20.5,10.6,33.3c1.7,7.8-1.2,12-8.7,12.1c-18.6-0.6-36.3-1-53.4-1h-71.8l-15.7,0.1
	c-12.7,0.1-25.6,0.4-39.1,0.8c-7.4-0.3-10.3-4.3-8.7-12.1c2.6-12.9,6.2-23.9,10.6-33.3c2.9-5.5,8.1-8.3,15.7-8.3l36.5-1.5l44.6,0.1
	l26-0.1C467.9,194.3,480.2,194.9,493.3,195.7L493.3,195.7z"
          />
          <path
            fill="#EBEBEB"
            d="M509,204c-2.9-5.5-8.1-8.3-15.7-8.3c-13.1-0.8-25.5-1.4-37.4-1.5l-26,0.1l-44.6-0.1l-36.5,1.5
	c-7.6,0-12.8,2.8-15.7,8.3c-4.4,9.4-8,20.5-10.6,33.3c-1.6,7.8,1.2,12,8.7,12.1c13.5-0.4,26.4-0.7,39.1-0.8l15.7-0.1h71.8
	c17.1,0.1,34.8,0.4,53.4,1c7.4-0.3,10.3-4.3,8.7-12.1C517,224.5,513.4,213.3,509,204L509,204z M469.8,303.3l-0.4,1.2
	c-0.1,0.7-0.1,1.1,0.1,1.4c0.3,0.4,0.7,0.5,1.2,0.5h20.5c0.8,0,1.4-0.1,1.4-0.5c0.3-0.3,0-0.8-0.4-1.4l-1-1.2c-0.7-1-1.8-1.5-3-1.5
	h-16.5C470.7,301.8,470.2,302.2,469.8,303.3L469.8,303.3z M470.3,305.3v-0.7l0.4-1.2c0.1-0.5,0.5-0.8,1.1-0.8h16.8
	c0.8,0,1.4,0.3,1.8,0.8l1,1.2c0.3,0.3,0.4,0.5,0.3,0.7c-0.1,0.1-0.3,0.1-0.7,0.1h-20.1C470.6,305.5,470.5,305.5,470.3,305.3
	L470.3,305.3z M368.9,362v-6.6c-0.1-2.6-1.5-4.1-4.1-4.3h-13.1c-1.2,0-1.8,0.6-1.8,1.8v2.9c0.1,6.2,4.4,9.4,12.7,9.6h1.9
	C367.8,364.9,369.2,363.8,368.9,362L368.9,362z M477.2,351c-2.6,0.3-4,1.6-4.1,4.3v6.6c-0.3,1.8,1.1,2.9,4.3,3.3h1.9
	c8.3-0.3,12.5-3.4,12.7-9.6v-2.9c0-1.2-0.5-1.8-1.8-1.8L477.2,351L477.2,351z M523.6,354.6c0-2.3-0.8-4.4-2.5-6.1s-3.7-2.5-6.1-2.5
	s-4.4,0.8-6.1,2.5s-2.5,3.7-2.5,6.1s0.8,4.4,2.5,6.1s3.7,2.5,6.1,2.5s4.4-0.8,6.1-2.5S523.6,357.1,523.6,354.6L523.6,354.6z
	 M335.9,354.6c0-2.3-0.8-4.4-2.5-6.1c-1.6-1.6-3.7-2.5-6.1-2.5c-2.3,0-4.4,0.8-6.1,2.5s-2.5,3.7-2.5,6.1s0.8,4.4,2.5,6.1
	s3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5C335,359,335.9,357.1,335.9,354.6L335.9,354.6z M332.4,274.8c-5.6-3.6-11.4-5.5-17.6-5.9
	h-0.1c-6.9,0.4-11.6,3.4-13.9,9.5c-1.6,6.3-1.1,12.2,1.9,17.6c2.1,3.9,5.8,6.3,11.4,7.4c4.1,0.8,7.6,1.1,10.6,0.7
	c0.8,0,1.6-0.3,2.5-0.5c2.1-0.7,4.1-2.2,6.1-4.3c2.9-4.1,4.7-8.9,5.2-14.2C338.5,280.7,336.6,277.3,332.4,274.8L332.4,274.8z
	 M527.4,268.9c-6.3,0.3-12.1,2.2-17.7,5.8c-4.3,2.5-6.2,5.9-5.8,10.5c0.5,5.4,2.3,10,5.2,14.2c1.9,2.1,3.9,3.4,6.1,4.3
	c0.8,0.3,1.7,0.4,2.5,0.5c3,0.4,6.5,0.1,10.6-0.7c5.5-1.1,9.4-3.6,11.4-7.4c2.9-5.4,3.6-11.3,1.9-17.6
	C539,272.5,534.3,269.3,527.4,268.9L527.4,268.9z"
          />
          <path
            fill="none"
            d="M410.1,277h5.9 M409.4,276.2h6.7 M410.8,277.7h5.1 M413.8,280h2.8 M411.6,278.5h4.4 M412.6,279.2h3.6 M426,277
	h5.9 M425.9,276.2h6.7 M430.4,278.5H426 M428.2,280h-2.8 M429.5,279.2h-3.6 M421.9,276.7l1.1,1.2v-1.2h0.4v2.1H423l-1.1-1.2v1.2
	h-0.4v-2.1 M420.4,276.7v2.1h0.5v-2.1 M417.5,276.7v2.1h0.5v-1.1l0.5,1.1h0.1l0.5-1.1v1.1h0.5v-2.1h-0.5l-0.5,1.2l-0.7-1.2
	 M423.8,276.7v2.1h0.5v-2.1"
          />
          <path
            fill="none"
            d="M424.1,274.9c-0.8-0.8-1.9-1.2-3.2-1.2s-2.3,0.4-3.2,1.2c-0.8,0.8-1.2,1.6-1.2,2.9c0,1.1,0.4,2.1,1.2,2.8
	c0.8,0.8,1.9,1.1,3.2,1.1s2.2-0.4,3.2-1.1c0.8-0.8,1.2-1.8,1.2-2.8C425.5,276.7,424.9,275.8,424.1,274.9z"
          />
          <path
            fill="none"
            d="M423.8,275.2c-0.8-0.7-1.8-1.1-2.9-1.1s-2.1,0.4-2.9,1.1c-0.8,0.7-1.2,1.5-1.2,2.6s0.4,1.8,1.2,2.5
	c0.8,0.7,1.8,1.1,2.9,1.1c1.1,0,2.1-0.4,2.9-1.1s1.2-1.5,1.2-2.5C425.1,276.7,424.7,275.9,423.8,275.2L423.8,275.2z M431.3,277.7
	h-5.1 M493.3,195.7c-13.1-0.8-25.5-1.4-37.4-1.5l-26,0.1l-44.6-0.1l-36.5,1.5c-7.6,0-12.8,2.8-15.7,8.3c-4.4,9.4-8,20.5-10.6,33.3
	c-1.6,7.8,1.2,12,8.7,12.1c13.5-0.4,26.4-0.7,39.1-0.8l15.7-0.1h71.8c17.1,0.1,34.8,0.4,53.4,1c7.4-0.3,10.3-4.3,8.7-12.1
	c-2.6-12.9-6.2-23.9-10.6-33.3C506.1,198.5,500.9,195.7,493.3,195.7L493.3,195.7z M516.6,191l0.7,1.2c7,12.7,13.8,29,20.1,49
	c0.5,2.3,1.2,4.5,2.3,6.7l0.7,1.6 M514.1,191h2.5 M514.1,191c-0.8-1.1-1.9-1.4-3.3-1c-2.5,0.1-3.3,0.6-2.3,1.2 M494.4,175.6
	c0.4,0.6,0.7,1.4,1.1,2.5l0.3,1.4c0,0.6,0.1,1,0.5,1.4s0.8,0.6,1.4,0.6h1.4c0.5,0,1-0.1,1.4-0.6s0.5-0.8,0.5-1.4v-1.4
	c0-0.7-0.1-1.4-0.4-2.1"
          />
          <path
            fill="none"
            d="M516.6,191c-4.8-8.7-10-13.8-15.8-14.9h-0.3 M500.6,176c-0.3-0.8-0.5-1.5-1-2.2c-0.4-0.8-1.5-1.2-3.3-1.2
	c-1.9,0.1-3,0.6-3.4,1.1c-0.4,0.7-0.7,1.5-0.7,2.2"
          />
          <path
            fill="none"
            d="M494.4,175.6c-0.4-0.4-0.8-0.7-1.2-0.4c-0.4,0.1-0.5,0.4-0.7,0.8 M492.1,176l0.4-0.1 M492.5,175.9
	c0.5-0.1,1.1-0.3,1.9-0.3 M492.1,176c-0.7,0.3-1,0.7-1.1,1.2c-15.5-2.6-37-4-64.4-4.1h-1.8 M516.6,191c8,17.7,15.1,36.7,21.6,57.1
	c0.5,1.2,1.2,1.8,2.2,1.5 M553.7,259.1c8.4,0.5,14-0.7,17.1-3.7c2.9-2.8,3.6-6.1,2.3-9.9l-0.1-0.4c-1.4-3-4.3-5.5-9.1-7.2
	c-5.2-1.6-9.5-1.6-12.8,0c-4.5,2.2-6.6,5.5-6.5,9.8 M422.6,170.7l-0.5-6.9c0-0.3-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3
	c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6l-0.4,6.9 M422.6,170.7H420 M417.5,173.1h-2.1c-27.4,0.1-48.8,1.5-64.4,4.1
	c-0.1-0.6-0.5-1-1.1-1.2 M417.5,173.1h7.4 M508.4,191.2c-19.3-0.8-36.7-1.1-52.5-1.2h-70.6c-15.5,0-32.7,0.4-51.6,1.2 M424.9,173.1
	c0-1.6-0.8-2.5-2.3-2.6 M420,170.7c-1.4,0.3-2.2,1.2-2.6,2.6 M421.5,265.7h8.3c8.9-0.5,14.6-2.3,17.1-5.2l6.9-8.1 M416.5,275.4
	l0.8-0.8c1-1,2.2-1.4,3.6-1.4c1.4,0,2.6,0.4,3.6,1.4l0.8,0.8 M425.3,275.4l0.4,0.8l0.3,0.8l0.1,0.7v0.7l-0.1,0.8l-0.4,0.8l-0.7,0.8"
          />
          <path
            fill="none"
            d="M417.2,280.9l-0.7-0.8l-0.4-0.8l-0.1-0.7l-0.1-0.7v-0.1l0.1-0.7l0.1-0.8l0.4-0.8 M424.8,280.9l-0.1,0.1
	c-1,1-2.2,1.4-3.6,1.4c-1.4,0-2.6-0.4-3.6-1.4l-0.1-0.1 M424.8,280.9h2.2l1.4-0.8l1.2-0.8l1-0.8l0.8-0.7l0.7-0.7l0.7-0.8l0.7-0.8h-8
	 M433,292.4c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1v-0.7
	L433,292.4z"
          />
          <path
            fill="none"
            d="M433.2,295.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3
	c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4C432.2,295.2,432.6,295.3,433.2,295.3L433.2,295.3z M433,298.1
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1v-0.7L433,298.1
	L433,298.1z"
          />
          <path
            fill="none"
            d="M432.1,300.4c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2C431.8,299.7,431.8,300.1,432.1,300.4z M433,303.4
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1v-0.7L433,303.4z"
          />
          <path
            fill="none"
            d="M433.2,306.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3
	c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4C432.2,306.2,432.6,306.3,433.2,306.3L433.2,306.3z M430.7,291.2
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,290.6,430.6,290.9,430.7,291.2z"
          />
          <path
            fill="none"
            d="M430,291.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9
	c0.5,0,1-0.3,1.1-0.8l0.4-1.2L430,291.7L430,291.7z M429.5,297.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8
	h14.9c0.5,0,1-0.3,1.1-0.8l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7C429.9,297.1,429.8,297.1,429.5,297.1L429.5,297.1z"
          />
          <path
            fill="none"
            d="M430.7,296.7c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,296.1,430.6,296.4,430.7,296.7z M430.7,302c0.3,0.3,0.3,0.7,0.1,1.4
	l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.6-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6
	C430.2,301.6,430.6,301.8,430.7,302L430.7,302z"
          />
          <path
            fill="none"
            d="M429.6,304.5l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.5,0.8,1.1,0.8h14.9C429.1,305.3,429.5,305.1,429.6,304.5L429.6,304.5z M430.9,309.1l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9
	c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6c0.7,0,1.1,0.1,1.4,0.5
	C431,308,431.1,308.5,430.9,309.1L430.9,309.1z"
          />
          <path
            fill="none"
            d="M429.6,310.2l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.5,0.8,1.1,0.8h14.9C429.1,311,429.5,310.7,429.6,310.2L429.6,310.2z M419.8,316.2h2.5 M422.3,317.2v0.7 M422.3,317.9v1.2
	 M422.3,319.1v0.8 M422.3,319.9v1.5 M419.8,317.9v-0.7 M419.8,319.1v-1.2 M419.8,319.9v-0.8 M419.8,321.4v-1.5 M419.8,323.4v-1.1
	 M422.3,323.4h-2.5 M422.3,322.3v1.1 M419.8,322.3v-0.8 M422.3,321.4v0.8 M422.3,316.2v1 M419.8,317.2v-1 M432.8,310.2l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1
	C432.6,310.7,432.6,310.4,432.8,310.2L432.8,310.2z"
          />
          <path
            fill="none"
            d="M432,310c-0.1,0.6-0.1,1.1,0.1,1.4c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4
	l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5L432,310L432,310z M477.5,290.8h-0.5c-11.7-1.1-26.3-1.6-43.6-1.6
	h-25.6c-16.9,0.1-31.1,0.7-42.5,1.6h-0.5"
          />
          <path
            fill="none"
            d="M483.5,292.1l1.5,1.4c0.8,0.7,1.2,1.1,1.1,1.4c0.1,0.4-0.1,0.5-0.7,0.5l-14.7-0.1c-0.5,0-1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.5-1.5h6.2 M467.4,292.4l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1C467.4,291.9,467.4,292.1,467.4,292.4
	L467.4,292.4z"
          />
          <path
            fill="none"
            d="M468.1,292.5c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.6,0-1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5L468.1,292.5L468.1,292.5z M470.7,294.5l14,0.1
	c0.3,0,0.3,0,0.4-0.1l-2.1-2.1c-0.5-0.5-1.1-0.8-1.5-0.8h-10.3c-0.4,0-0.7,0.3-0.8,0.8l-0.3,1.2v0.7
	C470.3,294.5,470.5,294.5,470.7,294.5L470.7,294.5z M466.9,296.3h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4C467.9,296.4,467.4,296.3,466.9,296.3L466.9,296.3
	z"
          />
          <path
            fill="none"
            d="M467.4,297.2v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7
	c0.1-0.1,0.3-0.1,0.4-0.1h15C467.2,297.1,467.3,297.1,467.4,297.2L467.4,297.2z M469.8,297.8c0.3-1,0.8-1.5,1.8-1.5l13.8,0.1
	c1.1,0,2.1,0.5,3.2,1.5l1.2,1.2c0.7,0.5,0.8,1,0.7,1.2c0,0.4-0.4,0.5-1.2,0.4h-18.3c-0.7,0-1.1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4L469.8,297.8L469.8,297.8z"
          />
          <path
            fill="none"
            d="M470.3,300c0.1,0.1,0.3,0.1,0.5,0.1l17.9,0.1c0.3,0,0.5,0,0.5-0.1c0-0.1-0.1-0.4-0.4-0.7l-1.2-1.2
	c-0.5-0.5-1.1-0.8-1.8-0.8l-14.3-0.1c-0.6,0-0.8,0.3-1,0.8l-0.3,1.2V300L470.3,300z M469.8,303.3c0.3-1,1-1.5,1.9-1.5h16.5
	c1.4,0,2.3,0.5,3,1.5l1,1.2c0.5,0.5,0.7,1.1,0.4,1.4c0,0.4-0.5,0.5-1.4,0.5h-20.5c-0.5,0-1.1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4
	L469.8,303.3L469.8,303.3z M467,304.5c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15
	c0.3,0,0.4,0,0.5,0.1v0.7L467,304.5L467,304.5z"
          />
          <path
            fill="none"
            d="M470.3,305.3c0.1,0.1,0.3,0.1,0.5,0.1H491c0.4,0,0.5,0,0.7-0.1c0-0.1,0-0.4-0.3-0.7l-1-1.2
	c-0.4-0.5-1-0.8-1.8-0.8h-16.8c-0.5,0-0.8,0.3-1.1,0.8l-0.4,1.2L470.3,305.3L470.3,305.3z M468.1,303.6c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3
	c1,0,1.5-0.5,1.8-1.5L468.1,303.6L468.1,303.6z M451.3,308.2c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2C451.3,308.6,451.3,308.4,451.3,308.2L451.3,308.2z"
          />
          <path
            fill="none"
            d="M450.5,309.1l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5C450.5,308.1,450.4,308.5,450.5,309.1L450.5,309.1z M451.8,316.2H450
	 M451.8,317.9v-0.7 M451.8,319.9v1.5 M451.8,319.1v0.8 M451.8,317.9v1.2 M450,321.4v-1.5 M450,319.9v-0.8 M450,317.9v-0.7
	 M450,319.1v-1.2 M450,323.4v-1.1 M451.8,322.3v1.1 M450,322.3v-0.8 M450,323.4h1.8 M451.8,321.4v0.8 M451.8,316.2v1 M450,317.2v-1
	 M465.9,317.2c-1.5-0.5-3.2-0.8-5-1h-9.2 M472.9,313l0.8-0.1h18.4c1.6,0,2.8,0.4,3.2,1.5l0.8,1.5 M369.2,313h0.3
	c1.1-0.3,2.3-0.4,3.4-0.4h96.3c1.2,0,2.3,0.1,3.4,0.4h0.3"
          />
          <path
            fill="none"
            d="M472.3,315l0.3,0.1c1,0.3,1.9,0.5,2.9,1.1l3.3,1.5c0.8,0.4,1.6,0.7,2.6,0.8l3.4,0.3h10.3c3.3-1.1,4.7-3.4,3.7-7
	v-0.1c-2.6-6.3-5-11.1-7-14.7c-2.6-4.7-7.6-7.3-14.9-8c-11.8-1.1-26.4-1.6-43.7-1.6h-25.6c-16.9,0.1-31.2,0.7-42.8,1.6
	c-7.2,0.7-12.1,3.3-14.9,8c-2.1,3.6-4.4,8.4-7,14.7v0.1c-0.8,3.6,0.4,5.9,3.7,7H357l3.4-0.3c0.8-0.3,1.8-0.5,2.6-0.8l3.3-1.5
	c1-0.4,1.9-0.8,2.9-1.1l0.3-0.1l0.5-0.1l2.5-0.3h96.3l2.5,0.3L472.3,315L472.3,315z"
          />
          <path
            fill="none"
            d="M475.1,313.7l1.1,0.4l2.9,1.4c0.8,0.4,1.6,0.7,2.6,1l1.9,0.3h9.9 M475.1,313.7c-0.7-0.3-1.5-0.5-2.2-0.7
	 M469.5,310l0.4-1.2c0.3-1,1-1.5,1.9-1.5h18.4c1.6,0,2.6,0.4,3.2,1.5l0.7,1.2c0.3,0.6,0.4,1.1,0,1.4c-0.1,0.4-0.7,0.5-1.6,0.5h-21.7
	c-0.5,0-1-0.1-1.2-0.5C469.4,311,469.4,310.6,469.5,310L469.5,310z"
          />
          <path
            fill="none"
            d="M470.7,311h21.6c0.4,0,0.7,0,0.8-0.1c0.1-0.1,0.1-0.4-0.1-0.7l-0.7-1.2c-0.3-0.5-1-0.8-1.9-0.8h-18.7
	c-0.5,0-0.8,0.3-1.1,0.8l-0.4,1.2c-0.1,0.3-0.1,0.5,0,0.7C470.3,311,470.5,311,470.7,311L470.7,311z M471,321.4l-0.3-0.8l-0.5-0.7
	 M470.2,319.9l-0.8-0.8 M469.4,319.1l-1.6-1.2 M467.7,317.9l-1.6-0.8 M471,322.3c0.1-0.3,0.1-0.5,0-0.8 M451.8,323.4h15.7
	c2.1,0,3.3-0.4,3.6-1.2 M469.4,319.1h-17.6 M451.8,322.3H471 M470.2,319.9h-18.4 M451.8,321.4H471 M467.7,317.9h-16 M451.8,317.2
	h14.2 M422.3,322.3H450 M422.3,321.4H450 M422.3,319.9H450 M422.3,319.1H450 M422.3,317.9H450 M450,317.2h-27.6 M453.7,252.4
	L435,252h-27.8l-18.2,0.4 M422.3,323.4H450 M450,316.2h-27.6 M421.2,265.7H413c-8.9-0.5-14.6-2.3-17.1-5.2l-6.9-8.1 M416.5,275.4
	h-7.8l0.7,0.8l0.7,0.8l0.7,0.7l0.8,0.8l1,0.7l1.2,0.8l1.2,0.8h2.2 M294.4,309.9l48.3,2.2c4.8-11,10.5-22.1,16.8-33.3
	c1.1-3.7-0.4-7.4-4.4-10.9c-5.8-5.5-12.9-9.9-21.6-13.3 M374.7,292.4l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8
	l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1C374.7,291.9,374.7,292.1,374.7,292.4L374.7,292.4z"
          />
          <path
            fill="none"
            d="M375.3,290.8h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4C374.3,290.9,374.6,290.8,375.3,290.8L375.3,290.8z M371.6,292.4l0.3,1.2
	v0.7c-0.1,0.1-0.3,0.1-0.4,0.1l-14,0.1c-0.3,0-0.3,0-0.4-0.1c0-0.1,0.3-0.4,0.7-0.7l1.4-1.4c0.5-0.5,1.1-0.8,1.5-0.8h10.3
	C371.1,291.6,371.4,291.9,371.6,292.4L371.6,292.4z"
          />
          <path
            fill="none"
            d="M358.5,292.1l-1.5,1.4c-0.8,0.7-1.2,1.1-1.1,1.4c-0.1,0.4,0.1,0.5,0.7,0.5l14.7-0.1c0.5,0,1-0.1,1.2-0.5
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.5-1.5h-6.2 M375.3,296.3h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4
	l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C374.3,296.4,374.6,296.3,375.3,296.3L375.3,296.3z"
          />
          <path
            fill="none"
            d="M374.7,297.2v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7
	c-0.1-0.1-0.3-0.1-0.4-0.1h-15C375,297.1,374.9,297.1,374.7,297.2L374.7,297.2z M375.3,301.6h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C374.3,301.8,374.6,301.6,375.3,301.6L375.3,301.6z M372.2,297.8c-0.3-1-0.8-1.5-1.8-1.5l-13.8,0.1c-1.1,0-2.1,0.5-3.2,1.5l-1.2,1.2
	c-0.7,0.5-0.8,1-0.7,1.2c0,0.4,0.4,0.5,1.2,0.4h18.3c0.7,0,1.1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4
	C372.5,298.7,372.4,298.3,372.2,297.8L372.2,297.8z"
          />
          <path
            fill="none"
            d="M371.7,299.3c0.1,0.3,0.1,0.6,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1l-17.9,0.1c-0.3,0-0.5,0-0.5-0.1
	c0-0.1,0.1-0.4,0.4-0.7l1.2-1.2c0.5-0.5,1.1-0.8,1.8-0.8l14.3-0.1c0.5,0,0.8,0.3,1,0.8L371.7,299.3L371.7,299.3z M371.4,303.4
	l0.4,1.2v0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-20.1c-0.4,0-0.5,0-0.7-0.1c0-0.1,0-0.4,0.3-0.7l1-1.2c0.4-0.5,1-0.8,1.8-0.8h16.8
	C370.9,302.6,371.3,302.9,371.4,303.4L371.4,303.4z"
          />
          <path
            fill="none"
            d="M372.2,303.3c-0.3-1-1-1.5-1.9-1.5h-16.5c-1.4,0-2.3,0.5-3,1.5l-1,1.2c-0.5,0.5-0.7,1.1-0.4,1.4
	c0,0.4,0.5,0.5,1.4,0.5h20.5c0.5,0,1.1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4L372.2,303.3L372.2,303.3z M375,304.5
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7L375,304.5
	L375,304.5z M364.5,290.8c-2.3,0.3-4.3,0.7-6.1,1.5 M348.5,316.8h9.9l1.9-0.3c0.8-0.3,1.8-0.5,2.6-1l2.9-1.4l1.1-0.4 M374,307.7
	c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2C373.8,308.5,373.8,308.1,374,307.7L374,307.7z"
          />
          <path
            fill="none"
            d="M374.7,308.9l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1
	h-15c-0.3,0-0.4,0-0.5,0.1C374.7,308.4,374.7,308.6,374.7,308.9L374.7,308.9z M371.8,310.8c-0.1,0.1-0.3,0.1-0.4,0.1h-21.6
	c-0.4,0-0.7,0-0.8-0.1c-0.1-0.1-0.1-0.4,0.1-0.7l0.7-1.2c0.3-0.5,1-0.8,1.9-0.8h18.7c0.5,0,0.8,0.3,1.1,0.8l0.4,1.2
	C372,310.6,372,310.7,371.8,310.8L371.8,310.8z"
          />
          <path
            fill="none"
            d="M372.7,310l-0.4-1.2c-0.3-1-1-1.5-1.9-1.5h-18.4c-1.6,0-2.6,0.4-3.2,1.5L348,310c-0.3,0.6-0.4,1.1,0,1.4
	c0.1,0.4,0.7,0.5,1.6,0.5h21.7c0.5,0,1-0.1,1.2-0.5C372.8,311,372.8,310.6,372.7,310L372.7,310z M369.2,313l-0.8-0.1H350
	c-1.6,0-2.8,0.4-3.2,1.5l-0.8,1.5 M371.1,321.4c0-0.3,0.1-0.6,0.3-0.8l0.7-0.8 M371.1,322.3c-0.1-0.3-0.1-0.5,0-0.8 M390.4,323.4
	h-15.7c-2.1,0-3.3-0.4-3.6-1.2 M372,319.9l0.8-0.8 M372.8,319.1l1.6-1.2 M376.1,317.2c1.5-0.5,3.2-0.8,5-1h9.2 M374.4,317.9l1.6-0.8
	 M366.9,313.7c0.7-0.3,1.5-0.5,2.2-0.7 M408,291.6h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2C409,291.9,408.6,291.6,408,291.6L408,291.6z M409.4,299.3l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8
	h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1C409.4,299.8,409.4,299.6,409.4,299.3
	L409.4,299.3z"
          />
          <path
            fill="none"
            d="M408.8,300.9h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4C409.9,300.8,409.5,300.9,408.8,300.9L408.8,300.9z M408.8,306.3h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4C409.9,306.2,409.5,306.3,408.8,306.3L408.8,306.3z"
          />
          <path
            fill="none"
            d="M408,302.6h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7
	l-0.3-1.2C409,302.9,408.6,302.6,408,302.6L408,302.6z M393.7,308.9l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1
	v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3C394.1,308.1,393.8,308.4,393.7,308.9L393.7,308.9z"
          />
          <path
            fill="none"
            d="M394.7,307.3H408c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.6,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2C393.2,307.7,393.7,307.1,394.7,307.3L394.7,307.3z M390.4,319.9v1.5
	 M390.4,317.2v0.7 M390.4,321.4v0.8 M390.4,322.3v1.1 M390.4,319.1v0.8 M390.4,317.9v1.2 M392.2,317.9v-0.7 M392.2,319.1v-1.2
	 M392.2,319.9v-0.8 M392.2,321.4v-1.5 M392.2,322.3v-0.8 M392.2,323.4v-1.1 M390.4,316.2h1.8 M392.2,323.4h-1.8 M392.2,317.2v-1
	 M390.4,316.2v1 M376.1,317.2h14.3 M371.1,321.4h19.3 M371.1,322.3h19.3 M390.4,319.9H372 M374.4,317.9h16 M390.4,319.1h-17.6
	 M395.9,350.3l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397
	C396.3,349.5,396,349.8,395.9,350.3L395.9,350.3z M392.9,349.5c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2C393,349.9,393,349.6,392.9,349.5L392.9,349.5z"
          />
          <path
            fill="none"
            d="M393.6,349.1c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.6,1.2-0.6h15C393,348.5,393.4,348.8,393.6,349.1L393.6,349.1z M394.8,351.3l0.3-1.2
	c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5C394.7,352.4,394.7,352,394.8,351.3L394.8,351.3z M392.5,354.9h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2V355C392.9,355,392.7,354.9,392.5,354.9L392.5,354.9z"
          />
          <path
            fill="none"
            d="M393.6,354.6c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15C393,354,393.4,354.3,393.6,354.6L393.6,354.6z M395.6,357.8
	c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.6-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2
	C395.5,357.5,395.5,357.6,395.6,357.8L395.6,357.8z M393.6,360c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15C393,359.5,393.4,359.7,393.6,360L393.6,360z"
          />
          <path
            fill="none"
            d="M395.1,355.7c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4
	c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4L395.1,355.7L395.1,355.7z M395.1,361.1
	c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4L395.1,361.1L395.1,361.1z M392.5,360.4h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7C392.9,360.4,392.7,360.4,392.5,360.4L392.5,360.4z"
          />
          <path
            fill="none"
            d="M395.6,363.3c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397
	c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2C395.5,362.8,395.5,363.1,395.6,363.3L395.6,363.3z M414.2,348.5H428l0.7,0.1l0.4,0.4l0.1,0.3
	c0.1,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C413.4,348.7,413.7,348.5,414.2,348.5L414.2,348.5z"
          />
          <path
            fill="none"
            d="M413.8,350.2l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7l-0.1-0.1h-14.2
	c-0.3,0-0.4,0-0.4,0.1C413.7,349.6,413.7,349.9,413.8,350.2L413.8,350.2z M414.2,354H428l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3
	c0,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C413.4,354.2,413.7,354,414.2,354L414.2,354z"
          />
          <path
            fill="none"
            d="M413.8,355v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2V355l-0.1-0.1l-0.3-0.1h-13.9
	C413.9,354.9,413.8,354.9,413.8,355L413.8,355z M414.2,359.4H428l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,0.8v0.3
	l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C413.4,359.5,413.7,359.4,414.2,359.4L414.2,359.4z"
          />
          <path
            fill="none"
            d="M413.8,361.1l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7l-0.1-0.1h-14.2
	c-0.3,0-0.4,0-0.4,0.1C413.7,360.5,413.7,360.8,413.8,361.1L413.8,361.1z M410.2,365c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2
	c0.3-1,0.8-1.5,1.8-1.5H410.2L410.2,365z M382.2,326.8H460c1.4,0,1.9,0.7,1.9,1.9v14.2c0,1.4-0.7,1.9-1.9,1.9h-77.9
	c-1.4,0-1.9-0.7-1.9-1.9v-14.2C380.2,327.4,380.8,326.8,382.2,326.8L382.2,326.8z M357,333.3v3.6c0,1.8-1,2.8-2.8,2.8h-3.6
	c-1.8,0-2.8-1-2.8-2.8v-3.6c0-1.8,1-2.8,2.8-2.8h3.6C356,330.5,357,331.3,357,333.3L357,333.3z M364.8,351h4.7
	c1.2,0,1.8,0.5,1.8,1.8v10.7c0,1.2-0.5,1.8-1.8,1.8h-5"
          />
          <path
            fill="none"
            d="M372.5,352.3v12c0,1.4-0.7,1.9-1.9,1.9h-7.7c-9.2-0.3-13.8-3.7-14-10.6v-3.3c0-1.4,0.7-1.9,1.9-1.9h19.7
	C371.8,350.3,372.5,350.9,372.5,352.3L372.5,352.3z"
          />
          <path
            fill="none"
            d="M364.7,365.3c3.2-0.4,4.5-1.5,4.3-3.3v-6.6c-0.1-2.6-1.5-4.1-4.1-4.3 M376.9,366v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366c-0.1-0.1-0.3-0.1-0.4-0.1h-15C377.2,365.9,377.1,366,376.9,366
	L376.9,366z"
          />
          <path
            fill="none"
            d="M376.5,368.2l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.6C377.3,369.7,376.6,369.2,376.5,368.2z M364.8,351h-13.1
	c-1.2,0-1.8,0.5-1.8,1.8v2.9c0.1,6.2,4.4,9.4,12.7,9.6h1.9 M319.7,392.1c3.7,0.5,8.1,1.2,13.6,1.6c9.4,0.3,16.1-0.7,20.4-2.9
	c2.9-1.5,4.8-3.9,5.9-7.1h8l1.9,1.8c0.4,0.4,0.8,0.5,1.4,0.5l2.6-0.1c0.4-0.1,1.1-0.8,2.1-2.2h19.8c2.9,2.2,9.6,3.4,20.2,3.9l7,0.1
	c12.9-0.1,21-1.5,24.4-4h19.8c1,1.4,1.6,2.1,2.1,2.2l2.6,0.1c0.5,0,1-0.1,1.4-0.5l1.9-1.8h8c1.1,3.3,3.2,5.6,5.9,7.2
	c4.3,2.2,11.1,3.2,20.4,2.9c5.4-0.5,9.9-1.1,13.6-1.6 M413.8,366.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2
	V366l-0.1-0.1l-0.3-0.1h-13.9c-0.3,0-0.4,0-0.4,0.1C413.7,366.1,413.7,366.4,413.8,366.7L413.8,366.7z"
          />
          <path
            fill="none"
            d="M413.1,365.6c0.1-0.4,0.5-0.5,1.1-0.5H428l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,1v0.3
	l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2C412.8,366.3,413,365.9,413.1,365.6L413.1,365.6z
	 M411.6,368.1l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1C411.6,368.6,411.6,368.3,411.6,368.1L411.6,368.1z M448.5,349.1c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.6-1.2-0.6h-15
	C449.1,348.5,448.7,348.8,448.5,349.1L448.5,349.1z"
          />
          <path
            fill="none"
            d="M449.1,349.5c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2C449.1,349.9,449.1,349.6,449.1,349.5z M449.7,354.9h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2V355C449.3,355,449.4,354.9,449.7,354.9L449.7,354.9z"
          />
          <path
            fill="none"
            d="M448.5,354.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15C449.1,354,448.7,354.3,448.5,354.6L448.5,354.6z M447.4,351.3l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.3,0,0.4,0.1,0.5l0.1,0.1
	c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5C447.5,352.4,447.5,352,447.4,351.3L447.4,351.3z M447.4,356.9l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1
	c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5C447.5,358,447.5,357.5,447.4,356.9L447.4,356.9z M448.5,360
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5h-15C449.1,359.5,448.7,359.7,448.5,360z M447.4,362.3l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432
	c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5
	C447.5,363.4,447.5,363,447.4,362.3L447.4,362.3z"
          />
          <path
            fill="none"
            d="M449.1,361.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8L449.1,361.2z M477.5,365.3h-5c-1.2,0-1.8-0.5-1.8-1.8v-10.7c0-1.2,0.5-1.8,1.8-1.8h4.7"
          />
          <path
            fill="none"
            d="M477.2,351c-2.6,0.3-4,1.6-4.1,4.3v6.6c-0.3,1.8,1.1,2.9,4.3,3.3"
          />
          <path
            fill="none"
            d="M469.6,352.3v12c0,1.4,0.7,1.9,1.9,1.9h7.7c9.2-0.3,13.8-3.7,14-10.6v-3.3c0-1.4-0.7-1.9-1.9-1.9h-19.7
	C470.2,350.3,469.6,350.9,469.6,352.3L469.6,352.3z M449.7,365c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5L449.7,365L449.7,365z
	 M430.6,352.3c-0.1-0.1-0.1-0.4,0-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.4,0.1h-15L430.6,352.3L430.6,352.3z M430.6,357.8v-0.7l0.3-1.2c0.1-0.6,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8
	l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15L430.6,357.8L430.6,357.8z M430.9,361.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8
	l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15l-0.4-0.1v-0.7L430.9,361.2L430.9,361.2z M431.8,365c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.7
	c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5L431.8,365L431.8,365z"
          />
          <path
            fill="none"
            d="M446.1,368.9h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2
	v0.7C446.4,368.8,446.3,368.9,446.1,368.9L446.1,368.9z M465.2,366.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8
	l-0.3-1.2V366c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1C465.2,366.1,465.2,366.4,465.2,366.7L465.2,366.7z"
          />
          <path
            fill="none"
            d="M472.4,372.1c10.6,0,17.3-1.8,20.4-5.2c3.3-4.4,5.1-8.9,5.4-13.8c0.4-4.5-2.3-6.7-8.1-6.6H352.2
	c-5.8-0.1-8.4,2.1-8.1,6.6c0.3,4.8,1.9,9.4,5.4,13.8c3,3.6,9.8,5.4,20.4,5.2H472.4L472.4,372.1z M392.2,316.2h27.6 M392.2,317.2
	h27.6 M392.2,317.9h27.6 M392.2,319.1h27.6 M392.2,319.9h27.6 M392.2,321.4h27.6 M419.8,323.4h-27.6 M392.2,322.3h27.6"
          />
          <path
            fill="none"
            d="M540.8,344.1l-1.4,6.9c-1.1,4-5.1,5.9-11.7,5.9c-1.8,6.7-8.1,10.5-19.3,11h-0.1c-2.2,0.1-4.5,0.1-7,0h-8
	c-3.2,3.3-9.9,4.8-19.9,4.8H369.1c-10.2,0-16.8-1.5-19.9-4.8h-8c-2.5,0.1-5,0.1-7,0H334c-11-0.7-17.5-4.3-19.3-11
	c-6.7,0-10.6-1.9-11.7-5.9l-1.4-6.9 M546,257.3c-0.7-0.3-1.5-0.4-2.3-0.4 M543.7,256.8c0.7-0.8,1.1-2.2,1.2-4.3 M552,262.1
	c0.4-1.2,1-2.2,1.7-3 M546,257.3c2.6,0.8,4.7,2.3,6.1,4.8 M553.7,259.1c1.7-3.7,0.7-6.7-2.8-8.9 M550.9,250.2l-5,7.2 M553.1,273.4
	l-0.3-0.7c-0.6-1.4-0.8-2.8-1.1-4.3 M554.5,277.6h1.7c0.5,0,1-0.1,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-0.5c0-0.5-0.1-1-0.5-1.4
	l-0.4-0.3 M554.5,277.6c-0.4-1.4-0.8-2.8-1.4-4.1 M557.1,273.4h-4"
          />
          <path
            fill="none"
            d="M557.1,273.4c0.5,0,1-0.3,1.2-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-1c0-0.5-0.1-1-0.5-1.4s-0.8-0.5-1.4-0.5h-5.2
	 M543.9,277.4c-2.9-7-8.4-10.7-16.5-11.1h-0.1c-6.7,0.3-13.1,2.3-19.1,6.2c-5.4,3-7.7,7.4-7.2,13.1c0.7,5.8,2.6,11,5.8,15.5l0.1,0.1
	c2.2,2.5,4.7,4.3,7.3,5.1c1,0.3,2.1,0.5,3,0.7c3.2,0.4,7,0.3,11.4-0.7c6.5-1.4,10.9-4.3,13.2-8.8c2.9-5.4,3.7-11,2.6-17.2
	c0.1-0.3,0.1-0.5,0-0.8L543.9,277.4L543.9,277.4z M555.9,285.1c-0.8-1.9-2.1-3.4-3.4-4.5 M550.1,273.4c0.7,1.4,1.2,2.8,1.7,4l0.7,3
	 M552,262.1c-0.7,1.6-1.2,3.9-1.5,6.3c-0.3,1.5-0.4,3.2-0.4,5 M555.9,285.1c-0.1-2.5-0.7-5.1-1.4-7.7 M551.8,268.5
	c-0.3-2.1-0.1-4.1,0.3-6.3 M555.9,311.8l1.9-10.5v-0.1c0.4-7-0.3-12.4-1.9-16.1"
          />
          <path
            fill="none"
            d="M552.5,280.6c0.5,2.9,0.3,5.6-0.4,8.3l-4.3,21 M540.3,249.6c0.7,2.8,0.3,5-1.4,6.7l-2.8-0.1 M540.5,260.4
	c-1.4-1.4-2.8-2.8-4.3-4.1 M536.2,256.1l-8-0.4 M527.4,268.9c6.9,0.4,11.6,3.4,13.9,9.5c1.7,6.3,1.1,12.2-1.9,17.6
	c-2.1,3.9-5.8,6.3-11.4,7.4c-4.1,0.8-7.6,1.1-10.6,0.7c-0.8,0-1.7-0.3-2.5-0.5c-2.1-0.7-4.1-2.2-6.1-4.3c-2.9-4.1-4.7-8.9-5.2-14.2
	c-0.4-4.5,1.5-8,5.8-10.5C515.3,271.2,521.2,269.2,527.4,268.9L527.4,268.9z"
          />
          <path
            fill="none"
            d="M508.6,254.6c-8.5,3.4-15.8,7.8-21.6,13.3c-4,3.4-5.4,7.2-4.4,10.9c6.3,11.1,12,22.1,16.8,33.3l48.3-2.2
	 M496.2,315.9c1-0.8,1.2-2.1,1-3.7c-2.6-6.2-4.8-11-6.9-14.4c-1.5-2.5-3.7-4.3-6.6-5.5 M495.1,316.5c0.1-0.1,0.1-0.4-0.1-0.7
	l-0.7-1.2c-0.3-0.5-1-0.8-1.9-0.8h-17.2 M493.7,316.8h1.1 M496.2,315.9l-1.1,0.5 M494.8,316.8c0.3,0,0.3,0,0.4-0.1 M494.8,316.8
	h-1.1 M528.2,255.7l-19.7-1.1 M523.6,354.6c0,2.3-0.8,4.4-2.5,6.1s-3.7,2.5-6.1,2.5s-4.4-0.8-6.1-2.5s-2.5-3.7-2.5-6.1
	s0.8-4.4,2.5-6.1s3.7-2.5,6.1-2.5s4.4,0.8,6.1,2.5S523.6,352.3,523.6,354.6L523.6,354.6z"
          />
          <path
            fill="none"
            d="M524.8,354.6c0,2.8-1,5.1-2.9,7c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9s-2.9-4.3-2.9-7s1-5.1,2.9-7
	s4.3-2.9,7-2.9s5.1,1,7,2.9C523.8,349.5,524.8,351.8,524.8,354.6L524.8,354.6z M477.5,365.3h1.9c8.3-0.3,12.5-3.4,12.7-9.6v-2.9
	c0-1.2-0.5-1.8-1.8-1.8h-13.1 M522.5,392.1v10.7c0.1,5.4,1.7,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8h11.3c1.8,0.1,3.3,0.1,4.7,0
	c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-91.1"
          />
          <path
            fill="none"
            d="M522.5,392.1c5-0.8,8.3-1.8,9.9-2.9c2.6-0.8,4.7-4.5,5.9-11 M538.3,378.2c-5.5,2.9-14.2,4.7-25.7,5.4
	c-19.1,0.7-29.6-0.1-31-2.8H360.7c-1.5,2.6-11.8,3.4-31,2.8c-11.6-0.7-20.1-2.5-25.7-5.4"
          />
          <path
            fill="none"
            d="M550.4,342.9l-5,27.8c-1,3-3.3,5.5-7.2,7.6 M550.4,342.9l5.5-31.1 M540.8,344.1l9.6-1.2 M550.1,273.4
	c-2.1-4.1-5.2-8.5-9.5-13.1 M540.5,260.4l3.2-3.6 M547.8,309.9l-7,34.3 M544.8,252.7c-1.2-1.6-2.8-2.8-4.4-2.9 M544.8,247.7
	c2.6,0.7,4.7,1.5,6.2,2.5 M508.4,191.2c8.5,19.1,15.1,40.7,19.8,64.4 M508.6,254.6l-54.9-2.2 M477.5,290.8c2.3,0.3,4.3,0.7,6.1,1.5
	 M544.8,247.7c0.1,1.9,0.1,3.6,0.1,5 M536.2,256.1c-5.5-24.6-12.9-46.4-22.1-65.1 M297.4,247.7c0.3-4.3-1.9-7.6-6.5-9.8
	c-3.3-1.6-7.6-1.6-12.8,0c-4.7,1.6-7.7,4-9.1,7.2l-0.1,0.4c-1.4,3.9-0.5,7.2,2.3,9.9c3,3,8.8,4.3,17.1,3.7 M350,176
	c0-0.8-0.3-1.5-0.7-2.2c-0.4-0.6-1.5-1-3.4-1.1c-1.8,0-2.9,0.3-3.3,1.2c-0.4,0.7-0.7,1.4-1,2.2"
          />
          <path
            fill="none"
            d="M349.7,175.9c-0.1-0.4-0.4-0.7-0.7-0.8c-0.4-0.1-0.8,0-1.2,0.4 M350,176l-0.3-0.1 M347.8,175.6
	c-0.5,0.7-1,1.9-1.4,3.9c0,0.6-0.1,1-0.5,1.4s-0.8,0.6-1.4,0.6h-1.4c-0.5,0-1-0.1-1.4-0.6c-0.4-0.4-0.5-0.8-0.5-1.4
	c0-1.2,0.1-2.3,0.4-3.4 M347.8,175.6c0.8,0,1.4,0.1,1.9,0.3 M341.6,176h-0.3c-5.8,1.2-11,6.2-15.8,14.9 M328,191h-2.5 M325.5,191
	l-0.7,1.2c-7,12.7-13.8,29-20.1,49c-0.5,2.3-1.2,4.5-2.3,6.7c-0.3,0.6-0.5,1.1-0.7,1.6 M333.7,191.2c1-0.7,0.1-1.1-2.3-1.2
	c-1.4-0.4-2.5-0.1-3.3,1"
          />
          <path
            fill="none"
            d="M325.5,191c-8,17.7-15.1,36.7-21.6,57.1c-0.5,1.2-1.2,1.8-2.2,1.5 M333.7,272.5c5.4,3,7.7,7.4,7.2,13.1
	c-0.7,5.8-2.6,11-5.8,15.5l-0.1,0.1c-2.2,2.5-4.7,4.3-7.3,5.1c-1,0.3-2.1,0.5-3,0.7c-3.2,0.4-7,0.3-11.4-0.7
	c-6.5-1.4-10.9-4.3-13.2-8.8c-2.9-5.4-3.7-11-2.6-17.2c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.7,0.4-1.2,0.7-1.8v-0.1
	c2.9-7,8.3-10.7,16.5-11.1h0.1C321.5,266.4,327.8,268.5,333.7,272.5L333.7,272.5z"
          />
          <path
            fill="none"
            d="M332.4,274.8c-5.5-3.6-11.4-5.5-17.6-5.8h-0.1c-6.9,0.4-11.6,3.4-13.9,9.5c-1.6,6.3-1.1,12.2,1.9,17.6
	c2.1,3.9,5.8,6.3,11.4,7.4c4.1,0.8,7.6,1.1,10.6,0.7c0.8,0,1.6-0.3,2.5-0.5c2.1-0.7,4.1-2.2,6.1-4.3c2.9-4.1,4.7-8.9,5.2-14.2
	C338.5,280.7,336.6,277.3,332.4,274.8L332.4,274.8z M313.9,255.7l-8.1,0.4 M301.8,249.6c-0.7,2.8-0.3,5,1.4,6.7l2.8-0.1
	 M298.5,256.8c-0.7-0.8-1.1-2.2-1.2-4.3 M301.8,249.6c-1.8,0.3-3.2,1.2-4.4,2.9 M291.2,250.2l5,7.2 M298.5,256.8
	c-0.8,0.1-1.6,0.3-2.3,0.4 M296.2,257.3c-2.6,0.8-4.7,2.3-6.1,4.8 M301.7,260.4l-3.2-3.6 M289,273.4l0.3-0.7
	c0.5-1.4,0.8-2.8,1.1-4.3 M289.7,280.6l0.1-0.7c0.1-0.8,0.4-1.5,0.5-2.3c0.4-1.4,1-2.8,1.6-4 M292,273.4c-0.1-1.8-0.3-3.4-0.4-5
	c-0.4-2.6-0.8-4.7-1.5-6.3 M301.7,260.4c-4.4,4.5-7.6,8.9-9.5,13.1 M305.8,256.1c-1.5,1.4-2.9,2.8-4.3,4.1 M290,262.1
	c0.4,2.2,0.5,4.3,0.3,6.3 M294.4,309.9l-4.3-21c-0.8-2.6-1-5.4-0.4-8.3 M366.9,313.7h-17.2c-1,0-1.6,0.3-1.9,0.8l-0.7,1.2
	c-0.1,0.3-0.3,0.5-0.1,0.7 M346,315.9c0.3,0.3,0.7,0.4,1.1,0.7 M358.5,292.1c-2.9,1.2-5.2,3-6.6,5.5c-2.1,3.4-4.3,8.3-6.9,14.4
	c-0.4,1.7-0.1,2.9,1,3.7 M347.3,316.8h1.1 M347.3,316.8h1.1 M346.9,316.5c0,0.1,0.1,0.1,0.4,0.1 M333.6,254.6l-19.7,1.1
	 M290.3,268.5H285c-0.5,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4v1c0,0.5,0.1,1,0.5,1.4s0.8,0.5,1.2,0.5 M285,273.4l-0.4,0.3
	c-0.4,0.4-0.5,0.8-0.5,1.4v0.5c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5h1.6 M287.6,277.6c-0.7,2.6-1.2,5.1-1.4,7.7
	 M286.3,285.1c-1.8,3.9-2.3,9.2-1.9,16.1v0.1l1.9,10.5 M333.3,348.5c-1.6-1.6-3.7-2.5-6.1-2.5c-2.3,0-4.4,0.8-6.1,2.5
	c-1.6,1.6-2.5,3.7-2.5,6.1s0.8,4.4,2.5,6.1c1.7,1.6,3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5s2.5-3.7,2.5-6.1
	C335.9,352.3,335,350.2,333.3,348.5L333.3,348.5z"
          />
          <path
            fill="none"
            d="M334.3,361.6c1.9-1.9,2.9-4.3,2.9-7s-1-5.1-2.9-7s-4.3-2.9-7-2.9c-2.8,0-5.1,1-7,2.9c-1.9,1.9-2.9,4.3-2.9,7
	s1,5.1,2.9,7c1.9,1.9,4.3,2.9,7,2.9C330,364.5,332.4,363.5,334.3,361.6L334.3,361.6z M291.8,342.9l9.6,1.2 M303.9,378.2
	c-3.9-2.1-6.3-4.5-7.2-7.6l-5-27.8 M319.7,392.1c-5-0.8-8.3-1.8-9.9-2.9c-2.6-0.8-4.7-4.5-5.9-11"
          />
          <path
            fill="none"
            d="M286.1,311.8v91.1c0.1,5.4,1.6,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8c1.4,0.1,2.9,0.1,4.7,0h11.3
	c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-10.7 M285,273.4h4 M286.3,285.1c0.8-1.9,2.1-3.4,3.4-4.5 M286.1,311.8l5.6,31.1
	 M287.6,277.6c0.4-1.4,0.8-2.8,1.4-4.1 M288.3,259.1c-1.6-3.7-0.7-6.7,2.8-8.9 M301.4,344.1l-7-34.3 M288.3,259.1
	c0.7,0.8,1.2,1.8,1.6,3 M297.4,247.7c-2.6,0.7-4.7,1.5-6.2,2.5 M305.8,256.1c5.5-24.6,12.9-46.4,22.1-65.1 M313.9,255.7
	c4.7-23.8,11.3-45.3,19.8-64.4 M297.3,252.7c-0.1-1.4,0-3,0.1-5 M389,252.4l-55.4,2.2 M409.8,292.1l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4
	c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	C409,290.8,409.7,291.2,409.8,292.1L409.8,292.1z M517.2,192.3l-0.7-1.2c8,17.7,15.1,36.7,21.6,57.1c0.5,1.2,1.2,1.8,2.2,1.5
	l-0.7-1.6c-1-2.1-1.8-4.4-2.3-6.7C531,221.2,524.3,204.8,517.2,192.3L517.2,192.3z"
          />
          <path
            fill="none"
            d="M516.6,191h-2.5c9.2,18.8,16.6,40.4,22.1,65.1l2.8,0.1c1.7-1.8,2.1-4,1.4-6.6c-1,0.1-1.7-0.3-2.2-1.5
	C531.8,227.8,524.5,208.8,516.6,191L516.6,191z"
          />
          <path
            fill="none"
            d="M514.1,191h2.5c-4.8-8.7-10-13.8-15.8-14.9h-0.3c0.1,0.7,0.3,1.2,0.4,2.1v1.4c0,0.6-0.1,1-0.5,1.4
	s-0.8,0.6-1.4,0.6h-1.4c-0.6,0-1-0.1-1.4-0.6c-0.4-0.4-0.5-0.8-0.5-1.4l-0.3-1.4c-0.3-1.2-0.7-2.1-1.1-2.5c-0.8,0-1.4,0.1-1.9,0.3
	l-0.3,0.1c-0.7,0.3-1,0.7-1.1,1.2c-15.5-2.6-37-4-64.4-4.1h-11.1c-27.4,0.1-48.8,1.5-64.4,4.1c-0.1-0.6-0.5-1-1.1-1.2l-0.3-0.1
	c-0.5-0.1-1.1-0.3-1.9-0.3c-0.5,0.7-1,1.9-1.4,3.9c0,0.6-0.1,1-0.5,1.4c-0.4,0.4-0.8,0.6-1.4,0.6h-1.4c-0.5,0-1-0.1-1.4-0.6
	c-0.4-0.4-0.5-0.8-0.5-1.4c0-1.2,0.1-2.3,0.4-3.4h-0.3c-5.8,1.2-11,6.2-15.8,14.9h2.5c0.8-1.1,1.9-1.4,3.3-1
	c2.5,0.1,3.3,0.6,2.3,1.2c18.8-0.7,36-1.1,51.6-1.2h70.6c15.8,0,33.3,0.4,52.5,1.2c-1-0.7-0.1-1.1,2.3-1.2
	C512.2,189.7,513.3,189.9,514.1,191L514.1,191z"
          />
          <path
            fill="none"
            d="M514.1,191c-0.8-1.1-1.9-1.4-3.3-1c-2.5,0.1-3.3,0.6-2.3,1.2c8.5,19.1,15.1,40.7,19.8,64.4l8.1,0.5
	C530.7,231.5,523.3,209.9,514.1,191L514.1,191z M495.4,178.1l0.3,1.4c0,0.6,0.1,1,0.5,1.4c0.4,0.4,0.8,0.6,1.4,0.6h1.4
	c0.5,0,1-0.1,1.4-0.6c0.4-0.4,0.5-0.8,0.5-1.4v-1.4c0-0.7-0.1-1.4-0.4-2.1c-0.3-0.8-0.5-1.5-1-2.2c-0.4-0.8-1.5-1.2-3.3-1.2
	c-1.9,0.1-3,0.6-3.4,1.1c-0.4,0.7-0.7,1.5-0.7,2.2h0.4c0.1-0.4,0.4-0.7,0.7-0.8c0.4-0.1,0.8,0,1.2,0.4
	C494.7,176,495.1,176.9,495.4,178.1L495.4,178.1z"
          />
          <path
            fill="none"
            d="M494.4,175.6c-0.4-0.4-0.8-0.7-1.2-0.4c-0.4,0.1-0.5,0.4-0.7,0.8C492.9,175.8,493.6,175.6,494.4,175.6
	L494.4,175.6z M551.1,237.9c-4.5,2.2-6.6,5.5-6.5,9.8c2.6,0.7,4.7,1.5,6.2,2.5c3.4,2.2,4.4,5.1,2.8,8.9c8.4,0.5,14-0.7,17.1-3.7
	c2.9-2.8,3.6-6.1,2.3-9.9l-0.1-0.4c-1.4-3-4.3-5.5-9.1-7.2C558.6,236.3,554.4,236.3,551.1,237.9L551.1,237.9z M421.9,163.8
	c0-0.3-0.1-0.4-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6l-0.4,6.9h2.6L421.9,163.8L421.9,163.8z
	"
          />
          <path
            fill="none"
            d="M422.6,170.7H420c-1.4,0.3-2.2,1.2-2.6,2.6h7.4C424.9,171.5,424.1,170.7,422.6,170.7L422.6,170.7z M455.9,190.1
	h-70.6c-15.5,0-32.7,0.4-51.6,1.2c-8.5,19.1-15.1,40.7-19.8,64.4l19.7-1.1l55.4-2.2l18.2-0.4h27.9l18.7,0.4l54.9,2.2l19.5,1.1
	c-4.7-23.8-11.3-45.3-19.8-64.4C489.2,190.5,471.7,190.1,455.9,190.1L455.9,190.1z M493.3,195.7c7.6,0,12.8,2.8,15.7,8.3
	c4.4,9.4,8,20.5,10.6,33.3c1.7,7.8-1.2,12-8.7,12.1c-18.6-0.6-36.3-1-53.4-1h-71.8l-15.7,0.1c-12.7,0.1-25.6,0.4-39.1,0.8
	c-7.4-0.3-10.3-4.3-8.7-12.1c2.6-12.9,6.2-23.9,10.6-33.3c2.9-5.5,8.1-8.3,15.7-8.3l36.5-1.5l44.6,0.1l26-0.1
	C467.9,194.3,480.2,194.9,493.3,195.7L493.3,195.7z"
          />
          <path
            fill="none"
            d="M509,204c-2.9-5.5-8.1-8.3-15.7-8.3c-13.1-0.8-25.5-1.4-37.4-1.5l-26,0.1l-44.6-0.1l-36.5,1.5
	c-7.6,0-12.8,2.8-15.7,8.3c-4.4,9.4-8,20.5-10.6,33.3c-1.6,7.8,1.2,12,8.7,12.1c13.5-0.4,26.4-0.7,39.1-0.8l15.7-0.1h71.8
	c17.1,0.1,34.8,0.4,53.4,1c7.4-0.3,10.3-4.3,8.7-12.1C517,224.5,513.4,213.3,509,204L509,204z M429.8,265.7h-8.3H429.8
	c8.9-0.5,14.6-2.3,17.1-5.2l6.9-8.1L435,252h-27.8l-18.2,0.4l6.9,8.1c2.5,3,8.1,4.8,17.1,5.2h8.3H413c-8.9-0.5-14.6-2.3-17.1-5.2
	l-6.9-8.1l-55.4,2.2c8.5,3.4,15.8,7.8,21.6,13.3c4,3.4,5.4,7.2,4.4,10.9c-6.3,11.1-12,22.1-16.8,33.3l-48.3-2.2l7,34.3l1.4,6.9
	c1.1,4,5.1,5.9,11.7,5.9c1.8,6.7,8.1,10.5,19.3,11h0.1c2.2,0.1,4.5,0.1,7,0h8c3.2,3.3,9.9,4.8,19.9,4.8h104.3
	c10.2,0,16.8-1.5,19.9-4.8h8c2.5,0.1,5,0.1,7,0h0.1c11-0.7,17.5-4.3,19.3-11c6.7,0,10.6-1.9,11.7-5.9l1.4-6.9l7-34.3l-48.3,2.2
	c-4.8-11-10.5-22.1-16.8-33.3c-1.1-3.7,0.4-7.4,4.4-10.9c5.8-5.5,12.9-9.9,21.6-13.3l-54.9-2.2l-6.9,8.1
	C444.5,263.5,438.8,265.2,429.8,265.7L429.8,265.7z M424.7,274.5l0.8,0.8h8l-0.7,0.8l-0.7,0.8l-0.7,0.7l-0.8,0.7l-1,0.8l-1.2,0.8
	l-1.4,0.8h-2.2l-0.1,0.1c-1,1-2.2,1.4-3.6,1.4c-1.4,0-2.6-0.4-3.6-1.4l-0.1-0.1h-2.2l-1.2-0.8l-1.2-0.8l-1-0.8l-0.8-0.7l-0.7-0.7
	l-0.7-0.8l-0.7-0.8h8l0.8-0.8c1-1,2.2-1.4,3.6-1.4C422.5,273.2,423.7,273.6,424.7,274.5L424.7,274.5z M419.8,316.2H461
	c1.9,0.1,3.6,0.4,5,1c0.6,0.3,1.1,0.5,1.7,0.8c0.5,0.3,1.2,0.7,1.6,1.2l0.8,0.8l0.7,0.8l0.3,0.8v0.8c-0.3,0.8-1.5,1.2-3.6,1.2h-92.7
	c-2.1,0-3.3-0.4-3.6-1.2c-0.1-0.3-0.1-0.5,0-0.8c0-0.3,0.1-0.5,0.3-0.8l0.7-0.8l0.8-0.8l1.6-1.2l1.7-0.8c1.5-0.5,3.2-0.8,5-1H419.8
	L419.8,316.2z M472.3,315l-0.5-0.1l-2.5-0.3h-96.3l-2.5,0.3l-0.5,0.1l-0.3,0.1c-1,0.3-1.9,0.5-2.9,1.1l-3.3,1.5
	c-0.8,0.4-1.6,0.7-2.6,0.8l-3.4,0.3h-10.3c-3.3-1.1-4.7-3.4-3.7-7v-0.1c2.6-6.3,5-11.1,7-14.7c2.8-4.7,7.6-7.3,14.9-8
	c11.6-1.1,25.7-1.6,42.8-1.6h25.6c17.5,0,32.1,0.7,43.7,1.6c7.2,0.7,12.1,3.3,14.9,8c2.1,3.6,4.4,8.4,7,14.7v0.1
	c0.8,3.6-0.4,5.9-3.7,7h-10.3l-3.4-0.3c-0.8-0.3-1.8-0.5-2.6-0.8l-3.3-1.5c-1-0.4-1.9-0.8-2.9-1.1L472.3,315L472.3,315z
	 M380.2,328.7c0-1.4,0.7-1.9,1.9-1.9H460c1.4,0,1.9,0.7,1.9,1.9v14.2c0,1.4-0.7,1.9-1.9,1.9h-77.9c-1.4,0-1.9-0.7-1.9-1.9
	L380.2,328.7L380.2,328.7z M354.2,330.5c1.8,0,2.8,1,2.8,2.8v3.6c0,1.8-1,2.8-2.8,2.8h-3.6c-1.8,0-2.8-1-2.8-2.8v-3.6
	c0-1.8,1-2.8,2.8-2.8L354.2,330.5L354.2,330.5z M472.4,372.1H369.8c-10.6,0-17.3-1.8-20.4-5.2c-3.3-4.4-5.1-8.9-5.4-13.8
	c-0.4-4.5,2.2-6.7,8-6.6h137.8c5.8-0.1,8.5,2.1,8.1,6.6c-0.3,4.8-1.9,9.4-5.4,13.8C489.7,370.3,483,372.1,472.4,372.1L472.4,372.1z
	 M521.9,347.6c1.9,1.9,2.9,4.3,2.9,7s-1,5.1-2.9,7c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9s-2.9-4.3-2.9-7s1-5.1,2.9-7
	s4.3-2.9,7-2.9C517.7,344.7,520,345.6,521.9,347.6L521.9,347.6z M334.3,361.6c-1.9,1.9-4.3,2.9-7,2.9c-2.8,0-5.1-1-7-2.9
	c-1.9-1.9-2.9-4.3-2.9-7s1-5.1,2.9-7c1.9-1.9,4.3-2.9,7-2.9c2.8,0,5.1,1,7,2.9s2.9,4.3,2.9,7S336.2,359.7,334.3,361.6L334.3,361.6z"
          />
          <path
            fill="none"
            d="M426.2,277.7h5.1l0.7-0.7H426 M431.3,277.7h-5.1l-0.1,0.8h4.4 M425.3,275.4c-0.3-0.3-0.4-0.5-0.8-0.8
	c-1-1-2.2-1.4-3.6-1.4s-2.6,0.4-3.6,1.4l-0.8,0.8l-0.4,0.8l-0.3,0.8v1.4l0.1,0.8l0.4,0.8l0.7,0.8l0.1,0.1c1,1,2.2,1.4,3.6,1.4
	c1.4,0,2.6-0.4,3.6-1.4l0.1-0.1l0.7-0.8l0.4-0.8l0.1-0.8v-0.7l-0.1-0.7l-0.3-0.8L425.3,275.4L425.3,275.4L425.3,275.4z M424.1,274.9
	c0.8,0.8,1.2,1.6,1.2,2.9c0,1.1-0.4,2.1-1.2,2.8c-0.8,0.8-1.9,1.1-3.2,1.1s-2.3-0.4-3.2-1.1c-0.8-0.8-1.2-1.8-1.2-2.8
	c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.9-1.2,3.2-1.2C422.2,273.8,423.3,274.1,424.1,274.9z"
          />
          <path
            fill="none"
            d="M425.5,277.8c0-1.1-0.4-2.1-1.2-2.9c-0.8-0.8-1.9-1.2-3.2-1.2s-2.3,0.4-3.2,1.2c-0.8,0.8-1.2,1.6-1.2,2.9
	c0,1.1,0.4,2.1,1.2,2.8c0.8,0.8,1.9,1.1,3.2,1.1s2.2-0.4,3.2-1.1C424.9,279.8,425.5,278.9,425.5,277.8L425.5,277.8z M423.8,275.2
	c0.8,0.7,1.2,1.5,1.2,2.6s-0.4,1.8-1.2,2.5s-1.8,1.1-2.9,1.1s-2.1-0.4-2.9-1.1c-0.8-0.7-1.2-1.5-1.2-2.5s0.4-1.9,1.2-2.6
	s1.8-1.1,2.9-1.1C422.2,274.1,423,274.5,423.8,275.2L423.8,275.2z"
          />
          <path
            fill="none"
            d="M425.1,277.8c0-1-0.4-1.9-1.2-2.6s-1.8-1.1-2.9-1.1s-2.1,0.4-2.9,1.1c-0.8,0.7-1.2,1.5-1.2,2.6
	c0,1,0.4,1.8,1.2,2.5c0.8,0.7,1.8,1.1,2.9,1.1c1.1,0,2.1-0.4,2.9-1.1S425.1,278.8,425.1,277.8L425.1,277.8z M423.8,276.7h0.6v1.9
	h-0.5L423.8,276.7L423.8,276.7z M417.5,276.7h0.5l0.7,1.2l0.7-1.2h0.5v1.9h-0.5v-1l-0.5,1.1h-0.1l-0.5-1.1v1.1h-0.5v-2.1
	L417.5,276.7L417.5,276.7z M420.4,276.7h0.5v1.9h-0.5V276.7z M421.5,276.7h0.4l1.1,1.2v-1.2h0.4v1.9H423l-1.1-1.2v1.2h-0.4V276.7z"
          />
          <path
            fill="none"
            d="M424.4,276.7h-0.5v2.1h0.5 M418.1,276.7h-0.5v2.1h0.5v-1.1l0.5,1.1h0.1l0.5-1.1v1.1h0.5v-2.1h-0.5l-0.5,1.2
	 M420.9,276.7h-0.5v2.1h0.5 M421.9,276.7h-0.4v2.1h0.4v-1.2l1.1,1.2h0.4v-2.1H423v1.2 M425.3,275.4l0.5,0.8h6.7l0.7-0.8
	 M425.9,276.2l0.1,0.8h5.9l0.7-0.8 M416.1,276.2l0.4-0.8h-7.8l0.7,0.8 M416,277l0.1-0.8h-6.7l0.7,0.8 M415.9,277.7l0.1-0.7h-5.9
	l0.7,0.7 M415.9,277.8v-0.1h-5.1l0.8,0.8h4.4 M416.1,279.2l-0.1-0.7h-4.4l1,0.7 M416.5,280l-0.4-0.8h-3.6l1.2,0.8 M417.2,280.9
	l-0.7-0.8h-2.8l1.2,0.8 M429.5,279.2l1-0.7H426l-0.1,0.7 M428.2,280l1.2-0.8h-3.6l-0.4,0.8 M425.5,280l-0.7,0.8h2.2l1.2-0.8
	 M422.3,316.2h-2.5v7.2h2.5v-6.2 M422.3,317.2v0.7H450v-0.7"
          />
          <path
            fill="none"
            d="M422.3,317.9v1.2H450v-1.2 M422.3,319.1v0.8H450v-0.8"
          />
          <path
            fill="none"
            d="M422.3,319.9v1.5H450v-1.5 M419.8,317.9v-0.7h-27.6v0.7 M419.8,319.1v-1.2h-27.6v1.2"
          />
          <path
            fill="none"
            d="M419.8,319.9v-0.8h-27.6v0.8 M419.8,321.4v-1.5h-27.6v1.5 M419.8,323.4v-1.1h-27.6v1.1 M422.3,322.3v1.1H450
	v-1.1"
          />
          <path
            fill="none"
            d="M419.8,322.3v-0.8h-27.6v0.8 M422.3,321.4v0.8H450v-0.8 M422.3,316.2v1H450v-1 M419.8,317.2v-1h-27.6v1
	 M451.8,316.2H450v7.2h1.8v-6.2 M451.8,317.2v0.8h16l-1.6-0.8L451.8,317.2L451.8,317.2z M451.8,319.9v1.5H471l-0.3-0.8l-0.5-0.7"
          />
          <path
            fill="none"
            d="M451.8,319.1v0.8h18.4l-0.8-0.8 M451.8,317.9v1.2h17.6l-1.6-1.2L451.8,317.9L451.8,317.9z M451.8,322.3v1.2
	h15.7c2.1,0,3.3-0.4,3.6-1.2L451.8,322.3L451.8,322.3z M451.8,321.4v0.8H471c0.1-0.3,0.1-0.5,0-0.8L451.8,321.4L451.8,321.4z
	 M451.8,316.2v1h14.2c-1.5-0.5-3.2-0.8-5-1H451.8L451.8,316.2z M371.3,320.6c-0.1,0.3-0.3,0.5-0.3,0.8h19.3v-1.5h-18.4L371.3,320.6
	L371.3,320.6z"
          />
          <path
            fill="none"
            d="M371.1,321.4v0.8h19.3v-0.8L371.1,321.4L371.1,321.4z M371.1,322.3c0.3,0.8,1.5,1.2,3.6,1.2h15.7v-1.2
	L371.1,322.3L371.1,322.3z M372.8,319.1l-0.8,0.8h18.4v-0.8 M374.4,317.9c-0.5,0.3-1.2,0.7-1.6,1.2h17.6v-1.2L374.4,317.9
	L374.4,317.9z M381.2,316.2c-1.9,0.1-3.6,0.4-5,1h14.3v-1H381.2L381.2,316.2z"
          />
          <path
            fill="none"
            d="M376.1,317.2l-1.7,0.8h16v-0.8L376.1,317.2L376.1,317.2z"
          />
          <path fill="none" d="M390.4,319.9v3.4h1.8v-7.2h-1.8v2.9" />
          <path
            fill="none"
            d="M472.3,315l0.3,0.1c1,0.3,1.9,0.5,2.9,1.1l3.3,1.5c0.8,0.4,1.6,0.7,2.6,0.8l3.4,0.3h10.3c3.3-1.1,4.7-3.4,3.7-7
	v-0.1c-2.6-6.3-5-11.1-7-14.7c-2.6-4.7-7.6-7.3-14.9-8c-11.8-1.1-26.4-1.6-43.7-1.6h-25.6c-16.9,0.1-31.2,0.7-42.8,1.6
	c-7.2,0.7-12.1,3.3-14.9,8c-2.1,3.6-4.4,8.4-7,14.7v0.1c-0.8,3.6,0.4,5.9,3.7,7H357l3.4-0.3c0.8-0.3,1.8-0.5,2.6-0.8l3.3-1.5
	c1-0.4,1.9-0.8,2.9-1.1l0.3-0.1l0.5-0.1l2.5-0.3h96.3l2.5,0.3L472.3,315L472.3,315z M476.9,290.6h0.5c2.3,0.3,4.3,0.7,6.1,1.5
	c2.9,1.2,5.2,3,6.6,5.5c2.1,3.4,4.3,8.3,6.9,14.4c0.4,1.7,0.1,2.9-1,3.7l-1.1,0.7c0,0.1-0.1,0.1-0.4,0.1h-10.9l-1.9-0.3
	c-0.8-0.3-1.8-0.5-2.6-1l-2.9-1.4l-1.1-0.4c-0.7-0.3-1.5-0.5-2.2-0.7h-0.3c-1.1-0.3-2.3-0.4-3.4-0.4h-96.3c-1.2,0-2.3,0.1-3.4,0.4
	h-0.3c-0.8,0.1-1.5,0.4-2.2,0.7l-1.1,0.4l-2.9,1.4c-0.8,0.4-1.6,0.7-2.6,1l-1.9,0.3h-11c-0.3,0-0.3,0-0.4-0.1
	c-0.4-0.1-0.7-0.4-1.1-0.7c-1-0.8-1.2-2.1-1-3.7c2.6-6.2,4.8-11,6.9-14.4c1.5-2.5,3.7-4.3,6.6-5.5c1.8-0.7,3.7-1.2,6.1-1.5h0.5
	c11.6-1.1,25.7-1.6,42.5-1.6h25.6C450.7,289,465.2,289.7,476.9,290.6L476.9,290.6z"
          />
          <path
            fill="none"
            d="M471.3,290.8h6.2h-0.5c-11.7-1.1-26.3-1.6-43.6-1.6h-25.6c-16.9,0.1-31.1,0.7-42.5,1.6h-0.5h6.2
	c0.7,0,1.2,0.4,1.5,1.5l0.3,1.2c0.1,0.7,0.1,1.1-0.1,1.4c-0.3,0.4-0.7,0.5-1.2,0.5l-14.7,0.1c-0.7,0-0.8-0.1-0.7-0.5
	c0-0.3,0.3-0.8,1.1-1.4l1.5-1.4c-2.1,0.8-3.9,2.1-5.1,3.6h135.4c-1.4-1.5-3-2.8-5.2-3.6l1.5,1.4c0.8,0.7,1.2,1.1,1.1,1.4
	c0.1,0.4-0.1,0.5-0.7,0.5l-14.7-0.1c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2C470.1,291.2,470.5,290.8,471.3,290.8
	L471.3,290.8z M468.1,291.3c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15C467.4,290.8,467.9,290.9,468.1,291.3L468.1,291.3z M430.7,291.2
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,290.6,430.6,290.9,430.7,291.2L430.7,291.2z M432,293.5l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	C431.8,294.5,431.8,294.1,432,293.5L432,293.5z M391.4,291.3c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15C390.8,290.8,391.2,290.9,391.4,291.3
	L391.4,291.3z M392.6,293.5l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5H408c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4
	c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5C392.5,294.5,392.5,294.1,392.6,293.5L392.6,293.5z"
          />
          <path
            fill="none"
            d="M429.5,301.6c0.7,0,1.1,0.1,1.4,0.5c0.1,0.1,0.1,0.1,0.1,0.3h1.6c0.3-0.5,0.8-0.7,1.5-0.7h13.3
	c0.7,0,1.1,0.3,1.5,0.7h1.6c0-0.1,0.1-0.1,0.1-0.3c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h2.1
	c0.3-0.4,0.8-0.7,1.5-0.7h16.5c1,0,1.7,0.3,2.3,0.7h2.2c-0.8-1.8-1.6-3.3-2.5-4.7c-0.4-0.7-1-1.4-1.5-2.1H353.7
	c-0.5,0.5-1.1,1.2-1.5,2.1c-0.8,1.4-1.6,2.9-2.5,4.7h2.2c0.5-0.4,1.4-0.7,2.3-0.7h16.5c0.7,0,1.2,0.3,1.5,0.7h2.1
	c0-0.1,0.1-0.1,0.1-0.3c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h1.6c0.3-0.5,0.8-0.8,1.5-0.7h13.3
	c0.7,0,1.2,0.3,1.5,0.7h1.8c0-0.1,0.1-0.3,0.1-0.3c0.3-0.4,0.7-0.5,1.2-0.5L429.5,301.6L429.5,301.6z M450.5,298.1
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5L450.5,298.1L450.5,298.1z M447.4,296.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2
	c0.3-1,0.8-1.5,1.8-1.5H447.4L447.4,296.3z M430.7,296.7c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9
	c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,296.1,430.6,296.4,430.7,296.7
	L430.7,296.7z M485.5,296.5c1.1,0,2.1,0.5,3.2,1.5l1.2,1.2c0.7,0.6,0.8,1,0.7,1.2c0,0.4-0.4,0.5-1.2,0.4H471c-0.7,0-1.1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.4-1.2c0.3-1,0.8-1.5,1.8-1.5L485.5,296.5L485.5,296.5z M373.9,298.1c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5L373.9,298.1L373.9,298.1z M370.5,296.4c1,0,1.5,0.4,1.8,1.5c0.1,0.4,0.1,0.8,0.4,1.2c0.1,0.7,0.1,1.1-0.1,1.4
	c-0.3,0.4-0.7,0.5-1.2,0.5H353c-0.8,0-1.2-0.1-1.2-0.4c-0.1-0.3,0-0.7,0.7-1.2l1.2-1.2c1.1-1,2.1-1.5,3.2-1.5L370.5,296.4
	L370.5,296.4z M408,296.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5H408L408,296.3z"
          />
          <path
            fill="none"
            d="M409.5,302.5c0.1,0.3,0.3,0.4,0.3,0.7l0.3,1.2c0.1,0.6,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.1-0.3,0.1-0.5,0.3-0.7h-1.6c0.1,0.3,0,0.7,0,1.1l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.4-0.1-0.8,0-1.1h-2.1c0.1,0.3,0.3,0.4,0.4,0.8l0.4,1.2
	c0.1,0.7,0.1,1.1-0.1,1.4c-0.3,0.4-0.7,0.5-1.2,0.5h-20.5c-0.8,0-1.4-0.1-1.4-0.5c-0.3-0.3,0-0.8,0.4-1.4l1-1.2
	c0.3-0.3,0.5-0.5,0.8-0.8h-2.1c-1,1.9-2.1,4.3-3,6.7h2.2l0.3-0.4c0.5-1,1.6-1.5,3.2-1.5h18.4c1,0,1.7,0.5,1.9,1.5l0.1,0.4h1.5v-0.1
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4v0.1h1.2l0.1-0.4
	c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.1,0.4h1.5v-0.1c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6
	c0.7,0,1.1,0.1,1.4,0.5c0.3,0.3,0.3,0.7,0.1,1.4v0.1h1.2l0.1-0.4c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.1,0.4h1.2v-0.1
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4v0.1h1.5l0.1-0.4
	c0.3-1,1-1.5,1.9-1.5h18.4c1.7,0,2.8,0.4,3.2,1.5l0.3,0.4h2.2c-1.1-2.5-2.1-4.8-3-6.7h-2.2c0.3,0.3,0.5,0.5,0.8,0.8l1,1.2
	c0.5,0.5,0.7,1.1,0.4,1.4c0,0.4-0.5,0.5-1.4,0.5h-20.5c-0.5,0-1.1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.4-1.2l0.4-0.8h-2.1
	c0.1,0.3,0,0.7,0,1.1l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.4-0.1-0.8,0-1.1h-1.7
	c0.1,0.3,0.3,0.4,0.3,0.7l0.3,1.2c0.1,0.6,0.1,1.1-0.1,1.4c-0.3,0.4-0.6,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0-0.3,0.1-0.5,0.3-0.7h-1.6c0.1,0.3,0,0.5,0,1l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9
	c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.4-0.1-0.7,0-1L409.5,302.5L409.5,302.5z"
          />
          <path
            fill="none"
            d="M392.6,310l0.3-0.8h-1.2l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2h-1.5l0.3,0.8
	c0.1,0.6,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-21.7c-1,0-1.5-0.1-1.6-0.5c-0.3-0.3-0.3-0.8,0-1.4l0.4-0.8h-2.2
	c-0.4,1-0.8,1.9-1.2,3c-0.4,1.6-0.1,2.9,1,3.7l0.8-1.5c0.5-1,1.6-1.5,3.2-1.5h18.4l0.8,0.1h0.3c1.1-0.3,2.3-0.4,3.4-0.4h96.3
	c1.2,0,2.3,0.1,3.4,0.4h0.3l0.8-0.1h18.4c1.6,0,2.6,0.4,3.2,1.5l0.8,1.5c1-0.8,1.2-2.1,1-3.7c-0.4-1.1-0.8-2.1-1.2-3h-2.2l0.4,0.8
	c0.3,0.6,0.4,1.1,0,1.4c-0.1,0.4-0.7,0.5-1.7,0.5h-21.7c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.8-0.1-1.4l0.3-0.8h-1.5l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2h-1.2l0.3,0.8c0.1,0.6,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.1-0.8H431l-0.4,1.1c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.3-1.1
	h-1.5l0.1,0.8c0.1,0.6,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5C392.5,311,392.5,310.6,392.6,310
	L392.6,310z M432.1,294.8c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5
	h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2C431.8,294.1,431.8,294.5,432.1,294.8L432.1,294.8z M432.8,293.6l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1
	C432.6,294.2,432.6,293.9,432.8,293.6z"
          />
          <path
            fill="none"
            d="M433,292.4l-0.3,1.2c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3C433.6,291.6,433.2,291.9,433,292.4z M432,299c-0.1,0.5-0.1,1.1,0.1,1.4c0.1,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5L432,299z M432.8,299.3
	l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1
	C432.6,299.8,432.6,299.6,432.8,299.3L432.8,299.3z"
          />
          <path
            fill="none"
            d="M433,298.1l-0.3,1.2c-0.1,0.3-0.1,0.6,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3C433.6,297.2,433.2,297.5,433,298.1L433,298.1z M432.1,305.8c0.1,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2
	C431.8,305.1,431.8,305.5,432.1,305.8z M432.8,304.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1C432.6,305.2,432.6,304.9,432.8,304.7L432.8,304.7z"
          />
          <path
            fill="none"
            d="M433,303.4l-0.3,1.2c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3C433.6,302.6,433.2,302.9,433,303.4z M430.7,291.2c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6
	c-0.7,0-1.1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9c1.1,0,1.6-0.5,2.1-1.5l0.4-1.2
	C431.1,292,431,291.4,430.7,291.2L430.7,291.2z M430,291.7c0.1,0.1,0.1,0.4,0,0.7l-0.4,1.2c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9
	c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h16.6C429.7,291.6,429.9,291.6,430,291.7L430,291.7z
	"
          />
          <path
            fill="none"
            d="M430,292.4c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.5,0.8,1.1,0.8h14.9c0.5,0,1-0.3,1.1-0.8L430,292.4L430,292.4z M430.7,296.7c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6
	c-0.7,0-1.1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9c1.1,0,1.6-0.5,2.1-1.5l0.4-1.2
	C431.1,297.5,431,297,430.7,296.7L430.7,296.7z M429.5,297.1c0.3,0,0.4,0,0.5,0.1s0.1,0.4,0,0.7l-0.4,1.2c-0.1,0.5-0.5,0.8-1.1,0.8
	h-14.9c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7s0.3-0.1,0.5-0.1L429.5,297.1L429.5,297.1z"
          />
          <path
            fill="none"
            d="M430,297.2c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9
	c0.5,0,1-0.3,1.1-0.8l0.4-1.2L430,297.2L430,297.2z M430.7,302c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6c-0.7,0-1.1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9c1.1,0,1.6-0.6,2.1-1.5l0.4-1.2C431.1,302.9,431,302.5,430.7,302
	L430.7,302z M429.6,304.5c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7
	c0.1-0.1,0.3-0.1,0.5-0.1h16.6c0.3,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0,0.7L429.6,304.5L429.6,304.5z"
          />
          <path
            fill="none"
            d="M428.5,305.3c0.6,0,1-0.3,1.1-0.8l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6
	c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8L428.5,305.3L428.5,305.3z M430.9,309.1c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.3-0.4-0.7-0.5-1.4-0.5h-16.6c-0.7,0-1.1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.4,1.2c0.3,1,1,1.5,2.1,1.5h14.9
	c1.1,0,1.6-0.5,2.1-1.5L430.9,309.1L430.9,309.1z M429.6,310.2c-0.1,0.5-0.5,0.8-1.1,0.8h-14.9c-0.5,0-1-0.3-1.1-0.8l-0.3-1.2
	c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h16.6c0.3,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.4,0,0.7L429.6,310.2L429.6,310.2z"
          />
          <path
            fill="none"
            d="M428.5,311c0.6,0,1-0.3,1.1-0.8l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6
	c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8H428.5L428.5,311z M432.2,308.6l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4
	c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3
	C433.1,307.3,432.5,307.7,432.2,308.6L432.2,308.6z M432.8,310.8v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8
	l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15C433,311,432.8,311,432.8,310.8L432.8,310.8z"
          />
          <path
            fill="none"
            d="M432.8,310.2c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8L432.8,310.2z M467.9,293.9l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3
	C467,295.3,467.6,294.9,467.9,293.9L467.9,293.9z M467.4,291.7v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8
	l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15C467.2,291.6,467.3,291.6,467.4,291.7L467.4,291.7z"
          />
          <path
            fill="none"
            d="M467.4,292.4c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8L467.4,292.4L467.4,292.4z M468.1,296.8c-0.1-0.4-0.5-0.5-1.2-0.5h-15
	c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	C468.4,297.5,468.3,297.1,468.1,296.8L468.1,296.8z M466.9,297.1c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1L466.9,297.1L466.9,297.1z"
          />
          <path
            fill="none"
            d="M467.4,297.2c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2C467.4,297.6,467.4,297.4,467.4,297.2L467.4,297.2z M469.8,297.8l-0.4,1.2c-0.1,0.7-0.1,1.1,0.1,1.4
	c0.3,0.4,0.7,0.5,1.2,0.5H489c0.8,0,1.2-0.1,1.2-0.4c0.1-0.3,0-0.7-0.7-1.2l-1.2-1.2c-1.1-1-2.1-1.5-3.2-1.5l-13.8-0.1
	C470.7,296.4,470.2,296.8,469.8,297.8L469.8,297.8z M470.3,300c-0.1-0.1-0.1-0.4,0-0.7l0.3-1.2c0.1-0.5,0.5-0.8,1-0.8l14.3,0.1
	c0.7,0,1.2,0.3,1.8,0.8l1.2,1.2c0.3,0.3,0.4,0.5,0.4,0.7c0,0.1-0.3,0.1-0.5,0.1l-17.9-0.1C470.6,300.1,470.5,300.1,470.3,300
	L470.3,300z"
          />
          <path
            fill="none"
            d="M470.3,299.3c-0.1,0.3-0.1,0.6,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1l17.9,0.1c0.3,0,0.5,0,0.5-0.1
	c0-0.1-0.1-0.4-0.4-0.7l-1.2-1.2c-0.5-0.5-1.1-0.8-1.8-0.8l-14.3-0.1c-0.6,0-0.8,0.3-1,0.8L470.3,299.3L470.3,299.3z M469.8,303.3
	l-0.4,1.2c-0.1,0.7-0.1,1.1,0.1,1.4c0.3,0.4,0.7,0.5,1.2,0.5h20.5c0.8,0,1.4-0.1,1.4-0.5c0.3-0.3,0-0.8-0.4-1.4l-1-1.2
	c-0.7-1-1.8-1.5-3-1.5h-16.5C470.7,301.8,470.2,302.2,469.8,303.3L469.8,303.3z M470.3,305.3v-0.7l0.4-1.2c0.1-0.5,0.5-0.8,1.1-0.8
	h16.8c0.8,0,1.4,0.3,1.8,0.8l1,1.2c0.3,0.3,0.4,0.5,0.3,0.7c-0.1,0.1-0.3,0.1-0.7,0.1h-20.1C470.6,305.5,470.5,305.5,470.3,305.3
	L470.3,305.3z"
          />
          <path
            fill="none"
            d="M470.3,304.7v0.7c0.1,0.1,0.3,0.1,0.5,0.1H491c0.4,0,0.5,0,0.7-0.1c0-0.1,0-0.4-0.3-0.7l-1-1.2
	c-0.4-0.5-1-0.8-1.8-0.8h-16.8c-0.5,0-0.8,0.3-1.1,0.8L470.3,304.7L470.3,304.7z M467.9,304.8l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3
	C467,306.3,467.6,305.8,467.9,304.8L467.9,304.8z M467.4,303.3l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8
	l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1C467.4,302.7,467.4,303,467.4,303.3L467.4,303.3z"
          />
          <path
            fill="none"
            d="M467,304.5l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3C466.6,305.3,466.9,305.1,467,304.5L467,304.5z M450.7,307.7c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15
	C451.3,307.3,450.9,307.4,450.7,307.7z M451.3,308.9v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L451.3,308.9L451.3,308.9z"
          />
          <path
            fill="none"
            d="M451.3,308.2v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7
	c-0.1-0.1-0.3-0.1-0.5-0.1h-15C451.6,308.1,451.5,308.1,451.3,308.2L451.3,308.2z M469.5,310c-0.1,0.6-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5h21.6c1,0,1.5-0.1,1.6-0.5c0.3-0.3,0.3-0.8,0-1.4l-0.7-1.2c-0.5-1-1.6-1.5-3.2-1.5h-18.4
	c-1,0-1.6,0.5-1.9,1.5L469.5,310L469.5,310z M470.7,311c-0.3,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.4,0-0.7l0.4-1.2
	c0.1-0.5,0.5-0.8,1.1-0.8h18.7c1,0,1.6,0.3,1.9,0.8l0.7,1.2c0.3,0.3,0.3,0.5,0.1,0.7c-0.1,0.1-0.3,0.1-0.8,0.1H470.7L470.7,311z"
          />
          <path
            fill="none"
            d="M470.3,310.8c0.1,0.1,0.3,0.1,0.4,0.1h21.6c0.4,0,0.7,0,0.8-0.1c0.1-0.1,0.1-0.4-0.1-0.7l-0.7-1.2
	c-0.3-0.5-1-0.8-1.9-0.8h-18.7c-0.5,0-0.8,0.3-1.1,0.8l-0.4,1.2C470.2,310.6,470.2,310.7,470.3,310.8L470.3,310.8z M375.3,290.8
	c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5L375.3,290.8L375.3,290.8z M374.7,292.4c-0.1-0.3-0.1-0.5,0-0.7
	c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L374.7,292.4
	L374.7,292.4z"
          />
          <path
            fill="none"
            d="M374.7,291.7v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7
	c-0.1-0.1-0.3-0.1-0.4-0.1h-15C375,291.6,374.9,291.6,374.7,291.7L374.7,291.7z M375.3,296.3c-0.5,0-1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.3-0.4-0.5-0.5-1.2-0.5L375.3,296.3L375.3,296.3z M374.7,297.2c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2C374.7,297.6,374.7,297.4,374.7,297.2L374.7,297.2z"
          />
          <path
            fill="none"
            d="M375.3,297.1c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7
	c-0.1-0.1-0.3-0.1-0.4-0.1L375.3,297.1L375.3,297.1z M375.3,301.6c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5L375.3,301.6
	L375.3,301.6z M375,304.5l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3C375.6,305.3,375.1,305.1,375,304.5L375,304.5z"
          />
          <path
            fill="none"
            d="M374.7,303.3l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1
	h-15c-0.3,0-0.4,0-0.5,0.1C374.7,302.7,374.7,303,374.7,303.3L374.7,303.3z M372.7,299.2l-0.4-1.2c-0.3-1-0.8-1.5-1.8-1.5l-13.8,0.1
	c-1.1,0-2.1,0.5-3.2,1.5l-1.2,1.2c-0.7,0.6-0.8,1-0.7,1.2c0,0.4,0.4,0.5,1.2,0.4h18.3c0.7,0,1.1-0.1,1.2-0.5
	C372.8,300.1,372.8,299.7,372.7,299.2L372.7,299.2z M371.4,298.1l0.3,1.2c0.1,0.3,0.1,0.6,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1l-17.9,0.1
	c-0.3,0-0.5,0-0.5-0.1c0-0.1,0.1-0.4,0.4-0.7l1.2-1.2c0.5-0.5,1.1-0.8,1.8-0.8l14.3-0.1C370.9,297.2,371.3,297.5,371.4,298.1
	L371.4,298.1z"
          />
          <path
            fill="none"
            d="M371.7,299.3l-0.3-1.2c-0.1-0.5-0.5-0.8-1-0.8l-14.3,0.1c-0.7,0-1.2,0.3-1.8,0.8l-1.2,1.2
	c-0.3,0.3-0.4,0.5-0.4,0.7c0,0.1,0.3,0.1,0.5,0.1l17.9-0.1c0.3,0,0.4,0,0.5-0.1C371.8,299.8,371.8,299.6,371.7,299.3L371.7,299.3z
	 M372.7,304.5l-0.4-1.2c-0.3-1-1-1.5-1.9-1.5h-16.5c-1.4,0-2.3,0.5-3,1.5l-1,1.2c-0.5,0.5-0.7,1.1-0.4,1.4c0,0.4,0.5,0.5,1.4,0.5
	h20.5c0.5,0,1.1-0.1,1.2-0.5C372.8,305.6,372.8,305.1,372.7,304.5L372.7,304.5z M370.3,302.6c0.5,0,0.8,0.3,1.1,0.8l0.4,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.5,0.1h-20.1c-0.4,0-0.5,0-0.7-0.1c0-0.1,0-0.4,0.3-0.7l1-1.2c0.4-0.5,1-0.8,1.8-0.8H370.3L370.3,302.6z"
          />
          <path
            fill="none"
            d="M371.4,303.4c-0.1-0.5-0.5-0.8-1.1-0.8h-16.8c-0.8,0-1.4,0.3-1.8,0.8l-1,1.2c-0.3,0.3-0.4,0.5-0.3,0.7
	c0.1,0.1,0.3,0.1,0.7,0.1h20.1c0.3,0,0.4,0,0.5-0.1v-0.7L371.4,303.4z M374,307.7c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5h-15
	C374.6,307.3,374.3,307.4,374,307.7L374,307.7z M374.7,308.9c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1h15
	c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L374.7,308.9z"
          />
          <path
            fill="none"
            d="M374.7,308.2v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7
	c-0.1-0.1-0.3-0.1-0.4-0.1h-15C375,308.1,374.9,308.1,374.7,308.2L374.7,308.2z M372.5,311.4c0.3-0.3,0.3-0.8,0.1-1.4l-0.4-1.2
	c-0.3-1-1-1.5-1.9-1.5h-18.4c-1.6,0-2.6,0.4-3.2,1.5L348,310c-0.3,0.6-0.4,1.1,0,1.4c0.1,0.4,0.7,0.5,1.6,0.5h21.7
	C372,311.9,372.4,311.7,372.5,311.4L372.5,311.4z M371.8,310.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-21.6
	c-0.4,0-0.7,0-0.8-0.1c-0.1-0.1-0.1-0.4,0.1-0.7l0.7-1.2c0.3-0.5,1-0.8,1.9-0.8h18.7c0.5,0,0.8,0.3,1.1,0.8L371.8,310.2L371.8,310.2
	z"
          />
          <path
            fill="none"
            d="M371.8,310.8c0.1-0.1,0.1-0.4,0-0.7l-0.4-1.2c-0.1-0.5-0.5-0.8-1.1-0.8h-18.7c-1,0-1.6,0.3-1.9,0.8l-0.7,1.2
	c-0.3,0.3-0.3,0.5-0.1,0.7c0.1,0.1,0.3,0.1,0.8,0.1h21.7C371.6,311,371.7,311,371.8,310.8L371.8,310.8z M409.8,292.1
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4L409.8,292.1L409.8,292.1z M408,291.6c0.5,0,0.8,0.3,1,0.8l0.3,1.2
	c0.1,0.3,0.1,0.5,0,0.7s-0.3,0.1-0.5,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8L408,291.6L408,291.6z"
          />
          <path
            fill="none"
            d="M409.1,292.4c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1v-0.7L409.1,292.4L409.1,292.4z M408.8,300.9c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5L408.8,300.9
	L408.8,300.9z M409.4,299.3c0.1,0.3,0.1,0.6,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8H408c0.5,0,0.8,0.3,1,0.8L409.4,299.3L409.4,299.3z"
          />
          <path
            fill="none"
            d="M409.4,300v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7
	c0.1,0.1,0.3,0.1,0.4,0.1h15C409.1,300.1,409.3,300,409.4,300L409.4,300z M408.8,306.3c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4
	l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5
	L408.8,306.3L408.8,306.3z M408,302.6c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15
	c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8H408L408,302.6z"
          />
          <path
            fill="none"
            d="M409.1,303.4c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1v-0.7L409.1,303.4L409.1,303.4z M394.7,307.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.6-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5L394.7,307.3L394.7,307.3
	z M393.7,308.9c0.1-0.5,0.4-0.8,1-0.8H408c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15
	c-0.3,0-0.4,0-0.4-0.1v-0.7L393.7,308.9L393.7,308.9z"
          />
          <path
            fill="none"
            d="M394.7,308.1c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8L394.7,308.1L394.7,308.1z M477.5,290.8h-6.2c-0.7,0-1.2,0.4-1.5,1.5l-0.3,1.2c-0.1,0.7-0.1,1.1,0.1,1.4
	c0.3,0.4,0.7,0.5,1.2,0.5l14.7,0.1c0.7,0,0.8-0.1,0.7-0.5c0-0.3-0.3-0.8-1.1-1.4l-1.5-1.4C481.9,291.4,479.8,291,477.5,290.8
	L477.5,290.8z M470.7,294.5c-0.3,0-0.4,0-0.4-0.1c0-0.1-0.1-0.4,0-0.7l0.3-1.2c0.1-0.5,0.4-0.8,0.8-0.8h10.3c0.4,0,1,0.3,1.5,0.8
	l2.1,2.1c0,0.1-0.1,0.1-0.4,0.1L470.7,294.5L470.7,294.5z"
          />
          <path
            fill="none"
            d="M470.3,294.3c0.1,0.1,0.3,0.1,0.4,0.1l14,0.1c0.3,0,0.3,0,0.4-0.1l-2.1-2.1c-0.5-0.5-1.1-0.8-1.5-0.8h-10.3
	c-0.4,0-0.7,0.3-0.8,0.8l-0.3,1.2C470.2,294.1,470.2,294.2,470.3,294.3L470.3,294.3z M473.8,312.9l-0.8,0.1c0.8,0.1,1.5,0.4,2.2,0.7
	h17.2c1,0,1.6,0.3,1.9,0.8l0.7,1.2c0.1,0.3,0.3,0.5,0.1,0.7l1.1-0.7l-0.8-1.5c-0.5-1-1.6-1.5-3.2-1.5L473.8,312.9z"
          />
          <path
            fill="none"
            d="M475.1,313.7l1.1,0.4l2.9,1.4c0.8,0.4,1.6,0.7,2.6,1l1.9,0.3h11c0.3,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.4-0.1-0.7
	l-0.7-1.2c-0.3-0.5-1-0.8-1.9-0.8L475.1,313.7L475.1,313.7z M372.5,294.9c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.5-1.5
	h-6.2c-2.3,0.3-4.3,0.7-6.1,1.5l-1.5,1.4c-0.8,0.7-1.2,1.1-1.1,1.4c-0.1,0.4,0.1,0.5,0.7,0.5l14.7-0.1
	C372,295.4,372.2,295.2,372.5,294.9L372.5,294.9z M370.7,291.6c0.4,0,0.7,0.3,0.8,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1l-14,0.1
	c-0.3,0-0.3,0-0.4-0.1c0-0.1,0.3-0.4,0.7-0.7l1.4-1.4c0.5-0.5,1.1-0.8,1.5-0.8L370.7,291.6L370.7,291.6z"
          />
          <path
            fill="none"
            d="M371.6,292.4c-0.1-0.5-0.4-0.8-0.8-0.8h-10.3c-0.4,0-1,0.3-1.5,0.8l-1.4,1.4c-0.4,0.3-0.5,0.5-0.7,0.7
	c0,0.1,0.1,0.1,0.4,0.1l14-0.1c0.3,0,0.4,0,0.4-0.1c0-0.1,0.1-0.4,0-0.7L371.6,292.4L371.6,292.4z M360.3,316.5
	c0.8-0.3,1.8-0.5,2.6-1l2.9-1.4l1.1-0.4h-17.2c-1,0-1.6,0.3-1.9,0.8l-0.7,1.2c-0.1,0.3-0.3,0.5-0.1,0.7c0,0.1,0.1,0.1,0.4,0.1h11
	L360.3,316.5L360.3,316.5z"
          />
          <path
            fill="none"
            d="M369.2,313l-0.8-0.1H350c-1.6,0-2.8,0.4-3.2,1.5l-0.8,1.5c0.3,0.3,0.7,0.4,1.1,0.7c-0.1-0.1-0.1-0.4,0.1-0.7
	l0.7-1.2c0.3-0.6,1-0.8,1.9-0.8H367C367.7,313.5,368.4,313.2,369.2,313L369.2,313z M493.7,316.8h1.1 M348.5,316.8h-1.1h0.1
	 M382.2,326.8c-1.4,0-1.9,0.7-1.9,1.9v14.2c0,1.4,0.7,1.9,1.9,1.9H460c1.4,0,1.9-0.7,1.9-1.9v-14.2c0-1.4-0.7-1.9-1.9-1.9
	L382.2,326.8L382.2,326.8z M357,333.3c0-1.8-1-2.8-2.8-2.8h-3.6c-1.8,0-2.8,1-2.8,2.8v3.6c0,1.8,1,2.8,2.8,2.8h3.6
	c1.8,0,2.8-1,2.8-2.8L357,333.3L357,333.3z M471.6,350.3h19.7c1.4,0,1.9,0.7,1.9,1.9v2.8h4.7c0.1-0.7,0.1-1.2,0.1-1.9
	c0.4-4.5-2.3-6.7-8.1-6.6H352.2c-5.8-0.1-8.4,2.1-8.1,6.6c0,0.7,0.1,1.2,0.1,1.9h4.7v-2.8c0-1.4,0.7-1.9,1.9-1.9h19.7
	c1.4,0,1.9,0.7,1.9,1.9v2.8h3.6c0-0.1,0.1-0.3,0.1-0.3c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h1.6
	c0.3-0.5,0.8-0.8,1.5-0.8h13.3c0.7,0,1.2,0.3,1.5,0.8h1.2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.4,0.5-0.5,1.1-0.5H428l0.7,0.1
	c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3v0.1h1.1c0.3-0.5,0.8-0.8,1.5-0.8h13.3c0.7,0,1.2,0.3,1.5,0.8h1.6c0-0.1,0.1-0.3,0.1-0.3
	c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0,0.1,0.1,0.1,0.1,0.3h3.6v-2.8C469.6,350.9,470.2,350.3,471.6,350.3L471.6,350.3z
	 M464.7,348.5c0.5,0,1,0.1,1.2,0.6c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5L464.7,348.5L464.7,348.5z M429.1,349.1l0.1,0.3c0.1,0.3,0.1,0.5,0,1v0.3
	l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.1-0.5h13.9
	l0.7,0.1C428.9,348.8,429.1,349,429.1,349.1L429.1,349.1z M430.2,349.6c0.3-0.7,0.8-1.1,1.6-1.1h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.4,0-0.8-0.1-1.1-0.3l-0.1-0.1c-0.1-0.1-0.1-0.4-0.1-0.5
	c0-0.3,0-0.4,0.1-0.7L430.2,349.6L430.2,349.6z M392.5,348.5c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.5c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5H392.5L392.5,348.5z
	 M394.8,351.3l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5
	h-15c-0.5,0-1-0.1-1.2-0.5C394.7,352.4,394.7,352,394.8,351.3L394.8,351.3z"
          />
          <path
            fill="none"
            d="M448.3,354.9h-1.6c0.1,0.1,0.3,0.4,0.3,0.7l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15
	c-0.4,0-0.8-0.1-1.1-0.3l-0.1-0.1c-0.1-0.1-0.1-0.4-0.1-0.5c0-0.3,0-0.4,0.1-0.8l0.4-1.6c0-0.1,0.1-0.3,0.1-0.4h-1.1v1l-0.4,1.4
	c-0.3,1-0.8,1.4-1.6,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2c-0.1-0.4-0.1-0.7,0-1h-1.2c0.1,0.1,0.3,0.4,0.3,0.7l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2
	c0.1-0.3,0.1-0.5,0.3-0.7h-1.6c0.1,0.3,0,0.7,0,1.1l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-12.9c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2
	c-0.1-0.4-0.1-0.8,0-1.1h-3.6v8.5h4.3l-0.4-0.8l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.1,0.3-0.3,0.7-0.4,0.8h1.8c-0.1-0.3-0.1-0.7,0-1.2l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,0.8,0,1.2h1.4c-0.1-0.3-0.3-0.5-0.4-1l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.1-0.4,0.5-0.5,1.1-0.5h13.9l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,0.8v0.3l-0.4,1.4l-0.4,0.8h1.2
	c0-0.1-0.1-0.3-0.1-0.4c0-0.3,0-0.4,0.1-0.8l0.4-1.6c0.3-0.7,0.8-1.1,1.6-1.1h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,0.8,0,1.2h1.8l-0.4-0.8l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.1,0.3-0.1,0.7-0.4,0.8h4.3v-8.5h-3.6c0.1,0.3,0,0.7,0,1.1l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5
	h-13.3c-1,0-1.5-0.6-1.8-1.5l-0.3-1.2C448.3,355.6,448.3,355.1,448.3,354.9L448.3,354.9z M497.8,354.9h-4.7v0.5
	c-0.1,3.6-1.4,6.2-3.9,8h5.6C496.5,360.6,497.4,357.9,497.8,354.9L497.8,354.9z M348.9,354.9h-4.7c0.4,2.9,1.4,5.8,3,8.5h5.6
	c-2.5-1.8-3.7-4.4-3.9-8v-0.5H348.9L348.9,354.9z M394.8,363.5H393c-0.3,0.4-0.8,0.7-1.4,0.7h-13.3c-0.7,0-1.1-0.3-1.4-0.7h-4.3v0.7
	c0,1.4-0.7,1.9-1.9,1.9H363c-4.4-0.1-7.8-1-10.2-2.6h-5.6c0.7,1.1,1.4,2.2,2.2,3.3c3,3.6,9.8,5.4,20.4,5.2h102.6
	c10.6,0,17.3-1.8,20.4-5.2c0.8-1.1,1.5-2.2,2.2-3.3h-5.6c-2.3,1.6-5.6,2.5-10.2,2.6h-7.7c-1.4,0-1.9-0.7-1.9-1.9v-0.7h-4.3
	c-0.3,0.4-0.8,0.7-1.4,0.7h-13.3c-0.5,0-1.1-0.1-1.4-0.7h-1.8l-0.1,0.1c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.4,0-0.8-0.1-1.1-0.3
	l-0.1-0.1l-0.1-0.1h-1.2c-0.3,0.4-0.7,0.5-1.2,0.5h-12.2c-0.5,0-1-0.1-1.2-0.5h-1.4l-0.1,0.1c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5C394.8,363.7,394.8,363.5,394.8,363.5L394.8,363.5z M395.1,366.6c0.3-1,0.8-1.5,1.8-1.5h13.3
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4
	L395.1,366.6L395.1,366.6z M392.5,365c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.5
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5L392.5,365L392.5,365z M413.1,365.6
	c0.1-0.4,0.5-0.5,1.1-0.5H428l0.7,0.1c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4
	h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2C412.8,366.3,413,365.9,413.1,365.6L413.1,365.6z M464.7,365c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.3-0.4,0.5-0.5,1.2-0.5L464.7,365L464.7,365z M430.2,366.1c0.3-0.7,0.8-1.1,1.6-1.1h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.3,0.4-0.5,0.5-1.2,0.5h-15c-0.4,0-0.8-0.1-1.1-0.3l-0.1-0.1c-0.1-0.1-0.1-0.4-0.1-0.5
	c0-0.3,0-0.4,0.1-0.8L430.2,366.1L430.2,366.1z M393.6,349.1c-0.3-0.4-0.5-0.6-1.2-0.6h-15c-0.5,0-1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2C393.8,349.9,393.8,349.4,393.6,349.1
	L393.6,349.1z M392.9,349.5v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7
	c0.1-0.1,0.3-0.1,0.5-0.1h15C392.7,349.4,392.9,349.5,392.9,349.5L392.9,349.5z"
          />
          <path
            fill="none"
            d="M392.9,350.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8L392.9,350.2L392.9,350.2z M394.8,351.3c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5L394.8,351.3L394.8,351.3
	z M395.9,350.3c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15
	c-0.3,0-0.4,0-0.4-0.1v-0.7L395.9,350.3L395.9,350.3z"
          />
          <path
            fill="none"
            d="M396.9,349.4c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8L396.9,349.4L396.9,349.4z M393.6,354.6c-0.3-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2C393.8,355.4,393.8,354.9,393.6,354.6
	L393.6,354.6z M392.5,354.9c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2
	c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1L392.5,354.9L392.5,354.9z"
          />
          <path
            fill="none"
            d="M392.9,355.1c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2C393,355.4,393,355.3,392.9,355.1L392.9,355.1z M393.6,360c-0.3-0.4-0.5-0.5-1.2-0.5h-15
	c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	C393.8,360.8,393.8,360.2,393.6,360L393.6,360z M392.5,360.4c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.5-0.1L392.5,360.4L392.5,360.4z"
          />
          <path
            fill="none"
            d="M392.9,360.5c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2C393,360.8,393,360.6,392.9,360.5L392.9,360.5z M395.1,355.7l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3
	C395.9,354.2,395.4,354.6,395.1,355.7L395.1,355.7z M395.6,357.8v-0.7l0.3-1.2c0.1-0.6,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8
	l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7s-0.3,0.1-0.5,0.1h-15C395.8,357.9,395.6,357.9,395.6,357.8L395.6,357.8z"
          />
          <path
            fill="none"
            d="M395.6,357.1v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397
	c-0.5,0-0.8,0.3-1,0.8L395.6,357.1L395.6,357.1z M395.1,361.1l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3C395.9,359.5,395.4,360.1,395.1,361.1
	L395.1,361.1z M395.6,363.3v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2c0.1,0.3,0.1,0.5,0,0.7
	c-0.1,0.1-0.3,0.1-0.5,0.1h-15C395.8,363.4,395.6,363.3,395.6,363.3L395.6,363.3z"
          />
          <path
            fill="none"
            d="M395.6,362.6v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397
	c-0.5,0-0.8,0.3-1,0.8L395.6,362.6L395.6,362.6z M414.2,348.5c-0.5,0-1,0.1-1.1,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.7,1.5h12.2c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1L414.2,348.5
	L414.2,348.5z M413.8,350.2c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.4-0.1h14.2l0.1,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8H415
	c-0.5,0-0.8-0.3-1-0.8L413.8,350.2L413.8,350.2z"
          />
          <path
            fill="none"
            d="M413.8,349.5v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7l-0.1-0.1h-14.2
	C413.9,349.4,413.8,349.4,413.8,349.5L413.8,349.5z M414.2,354c-0.5,0-1,0.1-1.1,0.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.7,1.5h12.2c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1L414.2,354
	L414.2,354z M413.8,355c0.1-0.1,0.3-0.1,0.4-0.1h13.9l0.3,0.1l0.1,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8H415
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2C413.7,355.4,413.7,355.1,413.8,355L413.8,355z"
          />
          <path
            fill="none"
            d="M414.2,354.9c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2V355
	l-0.1-0.1l-0.3-0.1h-13.9L414.2,354.9L414.2,354.9z M414.2,359.4c-0.5,0-1,0.1-1.1,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.7,1.5h12.2c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-0.8l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1L414.2,359.4
	L414.2,359.4z M413.8,361.1c-0.1-0.3-0.1-0.5,0-0.7c0.1-0.1,0.3-0.1,0.4-0.1h14.2l0.1,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8H415
	c-0.5,0-0.8-0.3-1-0.8L413.8,361.1L413.8,361.1z"
          />
          <path
            fill="none"
            d="M413.8,360.4v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7l-0.1-0.1h-14.2
	C413.9,360.2,413.8,360.4,413.8,360.4L413.8,360.4z M410.2,365h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4
	c0.3,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2C411.9,365.6,411.2,365,410.2,365L410.2,365z
	 M411.6,368.1c0.1,0.3,0.1,0.5,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3
	c0.5,0,0.8,0.3,1,0.8L411.6,368.1L411.6,368.1z"
          />
          <path
            fill="none"
            d="M411.6,368.8v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7
	c0.1,0.1,0.3,0.1,0.4,0.1h15C411.3,368.9,411.5,368.8,411.6,368.8L411.6,368.8z M372.5,352.3c0-1.4-0.7-1.9-1.9-1.9h-19.7
	c-1.4,0-1.9,0.7-1.9,1.9v3.3c0.1,6.9,4.8,10.5,14,10.6h7.7c1.4,0,1.9-0.7,1.9-1.9v-12L372.5,352.3L372.5,352.3z M369.6,351
	c1.2,0,1.8,0.5,1.8,1.8v10.7c0,1.2-0.5,1.8-1.8,1.8h-6.9c-8.3-0.3-12.5-3.4-12.7-9.6v-2.9c0-1.2,0.5-1.8,1.8-1.8L369.6,351
	L369.6,351z"
          />
          <path
            fill="none"
            d="M371.4,352.8c0-1.2-0.5-1.8-1.8-1.8H365c2.6,0.3,4,1.6,4.1,4.3v6.6c0.3,1.8-1.1,2.9-4.3,3.3h5
	c1.2,0,1.8-0.5,1.8-1.8L371.4,352.8L371.4,352.8z"
          />
          <path
            fill="none"
            d="M368.9,362v-6.6c-0.1-2.6-1.5-4.1-4.1-4.3h-13.1c-1.2,0-1.8,0.6-1.8,1.8v2.9c0.1,6.2,4.4,9.4,12.7,9.6h1.9
	C367.8,364.9,369.2,363.8,368.9,362L368.9,362z M376.5,368.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.3-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4L376.5,368.2L376.5,368.2z
	 M376.9,366c0.1-0.1,0.3-0.1,0.5-0.1h15c0.3,0,0.4,0,0.4,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8
	l-0.3-1.2C376.9,366.4,376.9,366.1,376.9,366L376.9,366z"
          />
          <path
            fill="none"
            d="M377.5,365.9c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366
	c-0.1-0.1-0.3-0.1-0.4-0.1H377.5L377.5,365.9z M413.1,365.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.6,1.5h12.2
	c0.8,0,1.4-0.4,1.7-1.4l0.4-1.4v-0.3v-1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.4-0.4l-0.7-0.1h-13.8C413.7,365,413.4,365.2,413.1,365.6
	L413.1,365.6z M413.8,366.7c-0.1-0.3-0.1-0.5,0-0.7s0.3-0.1,0.4-0.1h13.9l0.3,0.1l0.1,0.1v0.7l-0.3,1.2c-0.1,0.6-0.4,0.8-1,0.8H415
	c-0.5,0-0.8-0.3-1-0.8L413.8,366.7z"
          />
          <path
            fill="none"
            d="M413.8,366v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366l-0.1-0.1l-0.3-0.1h-13.9
	C413.9,365.9,413.8,365.9,413.8,366L413.8,366z M449.7,348.5c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5H449.7L449.7,348.5z
	 M449.1,350.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8L449.1,350.2L449.1,350.2z"
          />
          <path
            fill="none"
            d="M449.1,349.5v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7
	c-0.1-0.1-0.3-0.1-0.5-0.1h-15C449.4,349.4,449.3,349.5,449.1,349.5L449.1,349.5z M449.7,354c-0.5,0-1,0.1-1.2,0.6
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.6,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5L449.7,354L449.7,354z M449.1,355.1c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2C449.1,355.4,449.1,355.3,449.1,355.1L449.1,355.1z"
          />
          <path
            fill="none"
            d="M449.7,354.9c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2
	c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1L449.7,354.9L449.7,354.9z M447.2,352.7c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.3,0,0.4,0.1,0.5l0.1,0.1
	c0.3,0.3,0.5,0.3,1.1,0.3h15C446.7,353.2,447.1,353.1,447.2,352.7L447.2,352.7z M431,352.4l-0.4-0.1v-0.7l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1L431,352.4L431,352.4z"
          />
          <path
            fill="none"
            d="M430.6,352.3l0.4,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8
	l-0.3,1.2C430.4,351.8,430.4,352.1,430.6,352.3L430.6,352.3z M447.2,358.3c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5
	H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15
	C446.7,358.9,447.1,358.6,447.2,358.3L447.2,358.3z M430.6,357.9c-0.1-0.1-0.1-0.4,0-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3
	c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15L430.6,357.9L430.6,357.9z"
          />
          <path
            fill="none"
            d="M430.6,357.8l0.5,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.6-0.4-0.8-1-0.8H432c-0.5,0-0.8,0.3-1,0.8
	l-0.3,1.2C430.4,357.5,430.4,357.6,430.6,357.8L430.6,357.8z M449.7,359.5c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5L449.7,359.5
	L449.7,359.5z M449.6,362.4l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8
	h-13.3C450,363.3,449.7,363,449.6,362.4L449.6,362.4z"
          />
          <path
            fill="none"
            d="M449.1,361.2l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2c0.1-0.3,0.1-0.5,0-0.7
	c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1C449.1,360.6,449.1,360.8,449.1,361.2L449.1,361.2z M447.2,363.7
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8
	s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15C446.7,364.2,447.1,364.1,447.2,363.7L447.2,363.7z M430.6,362.6l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15l-0.4-0.1
	C430.4,363.1,430.4,362.8,430.6,362.6L430.6,362.6z"
          />
          <path
            fill="none"
            d="M430.9,361.2l-0.3,1.2c-0.1,0.3-0.1,0.5,0,0.7l0.4,0.1h15c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8h-13.3C431.4,360.4,431,360.6,430.9,361.2L430.9,361.2z M471.6,350.3c-1.4,0-1.9,0.7-1.9,1.9v12
	c0,1.4,0.7,1.9,1.9,1.9h7.7c9.2-0.3,13.8-3.7,14-10.6v-3.3c0-1.4-0.7-1.9-1.9-1.9L471.6,350.3L471.6,350.3z M472.5,351h17.7
	c1.2,0,1.8,0.5,1.8,1.8v2.9c-0.1,6.2-4.4,9.4-12.7,9.6h-6.9c-1.2,0-1.8-0.5-1.8-1.8v-10.7C470.7,351.7,471.3,351,472.5,351
	L472.5,351z"
          />
          <path
            fill="none"
            d="M477.2,351h-4.7c-1.2,0-1.8,0.5-1.8,1.8v10.7c0,1.2,0.5,1.8,1.8,1.8h5c-3.2-0.4-4.5-1.5-4.3-3.3v-6.6
	C473.2,352.8,474.6,351.3,477.2,351L477.2,351z"
          />
          <path
            fill="none"
            d="M477.2,351c-2.6,0.3-4,1.6-4.1,4.3v6.6c-0.3,1.8,1.1,2.9,4.3,3.3h1.9c8.3-0.3,12.5-3.4,12.7-9.6v-2.9
	c0-1.2-0.5-1.8-1.8-1.8L477.2,351L477.2,351z M464.7,365h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4C465.7,365.3,465.2,365,464.7,365L464.7,365z
	 M465.2,366v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2V366c0.1-0.1,0.3-0.1,0.4-0.1h15
	C465,365.9,465.1,366,465.2,366L465.2,366z"
          />
          <path
            fill="none"
            d="M465.2,366.7c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1h-15c-0.3,0-0.4,0-0.4,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8L465.2,366.7L465.2,366.7z M445.3,365H432c-0.8,0-1.4,0.4-1.7,1.1l-0.4,1.7
	c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	C446.8,365.6,446.1,365,445.3,365L445.3,365z M446.5,368.8c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2C446.7,368.3,446.7,368.6,446.5,368.8L446.5,368.8z"
          />
          <path
            fill="none"
            d="M446.1,368.9c0.3,0,0.4,0,0.4-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H432c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2
	c-0.1,0.3-0.1,0.5,0,0.7c0.1,0.1,0.3,0.1,0.4,0.1L446.1,368.9L446.1,368.9z M524.8,354.6c0-2.8-1-5.1-2.9-7c-1.9-1.9-4.3-2.9-7-2.9
	c-2.8,0-5.1,1-7,2.9s-2.9,4.3-2.9,7s1,5.1,2.9,7s4.3,2.9,7,2.9s5.1-1,7-2.9C523.8,359.7,524.8,357.3,524.8,354.6L524.8,354.6z
	 M521,348.5c1.7,1.6,2.5,3.7,2.5,6.1s-0.8,4.4-2.5,6.1c-1.7,1.6-3.7,2.5-6.1,2.5c-2.3,0-4.4-0.8-6.1-2.5c-1.6-1.6-2.5-3.7-2.5-6.1
	s0.8-4.4,2.5-6.1c1.7-1.6,3.7-2.5,6.1-2.5C517.2,345.9,519.3,346.9,521,348.5L521,348.5z"
          />
          <path
            fill="none"
            d="M523.6,354.6c0-2.3-0.8-4.4-2.5-6.1s-3.7-2.5-6.1-2.5s-4.4,0.8-6.1,2.5s-2.5,3.7-2.5,6.1s0.8,4.4,2.5,6.1
	s3.7,2.5,6.1,2.5s4.4-0.8,6.1-2.5S523.6,357.1,523.6,354.6L523.6,354.6z M327.3,364.5c2.8,0,5.1-1,7-2.9s2.9-4.3,2.9-7s-1-5.1-2.9-7
	s-4.3-2.9-7-2.9c-2.8,0-5.1,1-7,2.9c-1.9,1.9-2.9,4.3-2.9,7s1,5.1,2.9,7C322.2,363.5,324.5,364.5,327.3,364.5L327.3,364.5z
	 M333.3,348.5c1.6,1.6,2.5,3.7,2.5,6.1s-0.8,4.4-2.5,6.1s-3.7,2.5-6.1,2.5c-2.3,0-4.4-0.8-6.1-2.5c-1.6-1.6-2.5-3.7-2.5-6.1
	s0.8-4.4,2.5-6.1c1.7-1.6,3.7-2.5,6.1-2.5C329.6,346.1,331.7,346.9,333.3,348.5L333.3,348.5z"
          />
          <path
            fill="none"
            d="M335.9,354.6c0-2.3-0.8-4.4-2.5-6.1c-1.6-1.6-3.7-2.5-6.1-2.5c-2.3,0-4.4,0.8-6.1,2.5s-2.5,3.7-2.5,6.1
	s0.8,4.4,2.5,6.1s3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5C335,359,335.9,357.1,335.9,354.6L335.9,354.6z M359.5,278.8
	c1.1-3.7-0.4-7.4-4.4-10.9c-5.8-5.5-12.9-9.9-21.6-13.3l-19.7,1.1l-8.1,0.5c-1.5,1.4-2.9,2.8-4.3,4.1c-4.4,4.5-7.6,8.9-9.5,13.1v0.1
	c-0.7,1.4-1.2,2.8-1.6,4c-0.3,0.8-0.4,1.5-0.5,2.3l-0.1,0.7c-0.5,2.9-0.3,5.6,0.4,8.3l4.3,21l48.3,2.2
	C347.5,300.9,353.1,289.9,359.5,278.8L359.5,278.8z M333.9,272.5c5.2,3,7.7,7.4,7.2,13.1c-0.7,5.8-2.6,11-5.8,15.5l-0.1,0.1
	c-2.2,2.5-4.7,4.3-7.3,5.1c-1,0.3-2.1,0.5-3,0.7c-3.2,0.4-7,0.3-11.4-0.7c-6.5-1.4-10.9-4.3-13.2-8.8c-2.9-5.4-3.7-11-2.6-17.2
	c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.7,0.4-1.2,0.7-1.8v-0.1c2.9-7,8.3-10.7,16.5-11.1h0.1C321.5,266.4,327.8,268.5,333.9,272.5
	L333.9,272.5z"
          />
          <path
            fill="none"
            d="M333.7,272.5c-5.9-3.9-12.4-5.9-19-6.2h-0.1c-8.1,0.4-13.6,4.1-16.5,11.1v0.1c-0.3,0.5-0.4,1.2-0.7,1.8
	c-0.1,0.3-0.1,0.5,0,0.8c-1.1,6.2-0.3,12,2.6,17.2c2.3,4.5,6.7,7.6,13.2,8.8c4.4,1,8.1,1.1,11.4,0.7c1.1-0.1,2.1-0.3,3-0.7
	c2.6-0.8,5-2.6,7.3-5.1l0.1-0.1c3.2-4.5,5.1-9.8,5.8-15.5C341.4,279.9,339.1,275.5,333.7,272.5L333.7,272.5z M332.4,274.8
	c4.3,2.5,6.2,5.9,5.9,10.5c-0.5,5.4-2.3,10-5.2,14.2c-1.9,2.1-3.9,3.4-6.1,4.3c-0.8,0.3-1.6,0.4-2.5,0.5c-3,0.4-6.5,0.1-10.6-0.7
	c-5.5-1.1-9.4-3.6-11.4-7.4c-2.9-5.4-3.6-11.3-1.9-17.6c2.5-5.9,7-9.1,13.9-9.5h0.1C320.9,269.2,326.9,271.2,332.4,274.8
	L332.4,274.8z"
          />
          <path
            fill="none"
            d="M332.4,274.8c-5.6-3.6-11.4-5.5-17.6-5.9h-0.1c-6.9,0.4-11.6,3.4-13.9,9.5c-1.6,6.3-1.1,12.2,1.9,17.6
	c2.1,3.9,5.8,6.3,11.4,7.4c4.1,0.8,7.6,1.1,10.6,0.7c0.8,0,1.6-0.3,2.5-0.5c2.1-0.7,4.1-2.2,6.1-4.3c2.9-4.1,4.7-8.9,5.2-14.2
	C338.5,280.7,336.6,277.3,332.4,274.8L332.4,274.8z M359.6,383.8h8l1.9,1.8c0.4,0.4,0.8,0.5,1.4,0.5l2.6-0.1
	c0.4-0.1,1.1-0.8,2.1-2.2h19.8c2.9,2.2,9.6,3.4,20.2,3.9l7,0.1c12.9-0.1,21-1.5,24.4-4h19.8c1,1.4,1.6,2.1,2.1,2.2l2.6,0.1
	c0.5,0,1-0.1,1.4-0.5l1.9-1.8h8c1.1,3.3,3.2,5.6,5.9,7.2c4.3,2.2,11.1,3.2,20.4,2.9c5.4-0.5,9.9-1.1,13.6-1.6c5-0.8,8.3-1.8,9.9-2.9
	c2.6-0.8,4.7-4.5,5.9-11c-5.5,2.9-14.2,4.7-25.7,5.4c-19.1,0.7-29.6-0.1-31-2.8H360.8c-1.5,2.6-11.8,3.4-31,2.8
	c-11.6-0.7-20.1-2.5-25.7-5.4c1.2,6.6,3.3,10.3,5.9,11c1.8,1.1,5.1,2.1,9.9,2.9c3.7,0.5,8.1,1.2,13.6,1.6c9.4,0.3,16.1-0.7,20.4-2.9
	C356.6,389.4,358.5,387.1,359.6,383.8L359.6,383.8z"
          />
          <path
            fill="none"
            d="M473.2,372.9H368.9c-10.2,0-16.8-1.5-19.9-4.8h-8c-2.5,0.1-5,0.1-7,0h-0.1c-11-0.7-17.5-4.3-19.3-11
	c-6.7,0-10.6-1.9-11.7-5.9l-1.4-6.9l-9.6-1.2l5,27.8c1,3,3.3,5.5,7.3,7.6c5.5,2.9,14.2,4.7,25.7,5.4c19.1,0.7,29.6-0.1,31-2.8h120.9
	c1.5,2.6,11.8,3.4,31,2.8c11.6-0.7,20.1-2.5,25.7-5.4c3.9-2.1,6.3-4.5,7.2-7.6l5-27.8l-9.6,1.2l-1.4,6.9c-1.1,4-5.1,5.9-11.7,5.9
	c-1.8,6.7-8.1,10.5-19.3,11h-0.1c-2.2,0.1-4.5,0.1-7,0h-8C490,371.2,483.3,372.9,473.2,372.9L473.2,372.9z M543.7,256.8
	c0.8,0.1,1.7,0.3,2.3,0.4l5-7.2c-1.5-1-3.6-1.8-6.2-2.5c0.1,1.9,0.1,3.6,0.1,5C544.6,254.6,544.2,256,543.7,256.8L543.7,256.8z"
          />
          <path
            fill="none"
            d="M546,257.3c-0.7-0.3-1.5-0.4-2.3-0.4l-3,3.4c4.4,4.5,7.6,8.9,9.5,13.1c0.1-1.8,0.3-3.4,0.4-5
	c0.4-2.6,0.8-4.7,1.5-6.3C550.7,259.7,548.6,258,546,257.3L546,257.3z M543.7,256.8c0.7-0.8,1.1-2.2,1.2-4.3
	c-1.2-1.6-2.8-2.8-4.4-2.9c0.7,2.8,0.3,5-1.4,6.6l-2.8-0.1c1.5,1.4,2.9,2.8,4.3,4.1L543.7,256.8L543.7,256.8z"
          />
          <path
            fill="none"
            d="M552,262.1c0.4-1.2,1-2.2,1.7-3c1.7-3.7,0.7-6.7-2.8-8.9l-5,7.2C548.6,258,550.7,259.7,552,262.1L552,262.1z
	 M551.8,268.5c0.1,1.5,0.5,2.9,1.1,4.3l0.3,0.7h4c0.5,0,1-0.3,1.2-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-1c0-0.5-0.1-1-0.5-1.4
	s-0.8-0.5-1.4-0.5h-5.2L551.8,268.5L551.8,268.5z"
          />
          <path
            fill="none"
            d="M552.9,272.7c-0.6-1.4-0.8-2.8-1.1-4.3c-0.3-2.1-0.1-4.1,0.3-6.3c-0.7,1.6-1.2,3.9-1.5,6.3
	c-0.3,1.5-0.4,3.2-0.4,5v0.1c0.7,1.4,1.2,2.8,1.7,4l0.7,3c1.4,1.1,2.6,2.6,3.4,4.5c-0.1-2.5-0.7-5.1-1.4-7.7
	c-0.4-1.4-0.8-2.8-1.4-4.1L552.9,272.7L552.9,272.7z"
          />
          <path
            fill="none"
            d="M557.5,273.7l-0.4-0.3h-4c0.5,1.4,1,2.8,1.4,4.1h1.7c0.5,0,1-0.1,1.4-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-0.5
	C558.1,274.5,557.8,274.1,557.5,273.7L557.5,273.7z M550.1,273.4c-2.1-4.3-5.2-8.5-9.6-13.2c-1.4-1.4-2.8-2.8-4.3-4.1l-8-0.4
	l-19.7-1.1c-8.5,3.4-15.8,7.8-21.6,13.3c-4,3.4-5.4,7.2-4.4,10.9c6.3,11.1,12,22.1,16.8,33.3l48.3-2.2l4.3-21c0.8-2.6,1-5.4,0.4-8.3
	l-0.7-3C551.2,276.2,550.8,274.8,550.1,273.4L550.1,273.4z M543.9,277.4l0.7,1.9c0.1,0.3,0.1,0.5,0,0.8c1.1,6.2,0.3,12-2.6,17.2
	c-2.3,4.5-6.7,7.6-13.2,8.8c-4.4,1-8.1,1.1-11.4,0.7c-1.1-0.1-2.1-0.3-3-0.7c-2.6-0.8-5-2.6-7.3-5.1l-0.1-0.1
	c-3.2-4.5-5.1-9.8-5.8-15.5c-0.5-5.6,1.9-10,7.2-13.1c6.1-3.9,12.4-6.1,19.1-6.2h0.1C535.7,266.7,541.2,270.4,543.9,277.4
	L543.9,277.4z"
          />
          <path
            fill="none"
            d="M544.1,277.4c-2.9-7.2-8.4-10.9-16.5-11.3h-0.1c-6.7,0.3-13.1,2.3-19.1,6.2c-5.4,3-7.7,7.4-7.2,13.1
	c0.7,5.8,2.6,11,5.8,15.5l0.1,0.1c2.2,2.5,4.7,4.3,7.3,5.1c1,0.3,2.1,0.5,3,0.7c3.2,0.4,7,0.3,11.4-0.7c6.5-1.4,10.9-4.3,13.2-8.8
	c2.9-5.4,3.7-11,2.6-17.2c0.1-0.3,0.1-0.5,0-0.8L544.1,277.4L544.1,277.4z M527.4,268.9c7,0.4,11.7,3.4,14,9.5
	c1.7,6.3,1.1,12.2-1.9,17.6c-2.1,3.9-5.8,6.3-11.4,7.4c-4.1,0.8-7.6,1.1-10.6,0.7c-0.8,0-1.7-0.3-2.5-0.5c-2.1-0.7-4.1-2.2-6.1-4.3
	c-2.9-4.1-4.7-8.9-5.2-14.2c-0.4-4.5,1.5-8,5.8-10.5C515.3,271.2,521.2,269.2,527.4,268.9L527.4,268.9z"
          />
          <path
            fill="none"
            d="M527.4,268.9c-6.3,0.3-12.1,2.2-17.7,5.8c-4.3,2.5-6.2,5.9-5.8,10.5c0.5,5.4,2.3,10,5.2,14.2
	c1.9,2.1,3.9,3.4,6.1,4.3c0.8,0.3,1.7,0.4,2.5,0.5c3,0.4,6.5,0.1,10.6-0.7c5.5-1.1,9.4-3.6,11.4-7.4c2.9-5.4,3.6-11.3,1.9-17.6
	C539,272.5,534.3,269.3,527.4,268.9L527.4,268.9z M555.9,285.1c-0.8-1.9-2.1-3.4-3.4-4.5c0.5,2.9,0.3,5.6-0.4,8.3l-4.3,21l-7,34.3
	l9.6-1.2l5.5-31.1l1.9-10.5v-0.1C558.2,294.3,557.5,289,555.9,285.1L555.9,285.1z"
          />
          <path
            fill="none"
            d="M522.5,392.1v10.7c0.1,5.4,1.7,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8h11.3c1.8,0.1,3.3,0.1,4.7,0
	c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-91.1l-5.5,31.1l-5,27.8c-1,3-3.3,5.5-7.3,7.6c-1.2,6.6-3.2,10.3-5.9,11
	C530.6,390.4,527.3,391.3,522.5,392.1L522.5,392.1z M269.2,245l-0.1,0.4c-1.4,3.9-0.5,7.2,2.3,9.9c3,3,8.8,4.3,17.1,3.7
	c-1.6-3.7-0.7-6.7,2.8-8.9c1.5-1,3.6-1.8,6.2-2.5c0.3-4.3-1.9-7.6-6.5-9.8c-3.3-1.6-7.6-1.6-12.8,0
	C273.5,239.6,270.5,241.9,269.2,245L269.2,245z M350,176c0-0.8-0.3-1.5-0.7-2.2c-0.4-0.6-1.5-1-3.4-1.1c-1.8,0-2.9,0.3-3.3,1.2
	c-0.4,0.7-0.7,1.4-1,2.2c-0.3,1.1-0.4,2.2-0.4,3.4c0,0.6,0.1,1,0.5,1.4c0.4,0.4,0.8,0.6,1.4,0.6h1.4c0.5,0,1-0.1,1.4-0.6
	c0.4-0.4,0.5-0.8,0.5-1.4c0.4-1.9,0.8-3.2,1.4-3.9c0.4-0.4,0.8-0.7,1.2-0.4c0.4,0.1,0.5,0.4,0.7,0.8L350,176L350,176z"
          />
          <path
            fill="none"
            d="M349.7,175.9c-0.1-0.4-0.4-0.7-0.7-0.8c-0.4-0.1-0.8,0-1.2,0.4C348.6,175.6,349.1,175.8,349.7,175.9
	L349.7,175.9z M328,191h-2.5c-8,17.7-15.1,36.7-21.6,57.1c-0.5,1.2-1.2,1.8-2.2,1.5c-0.7,2.8-0.3,5,1.4,6.6l2.8-0.1
	C311.4,231.5,318.7,209.9,328,191L328,191z"
          />
          <path
            fill="none"
            d="M325.5,191l-0.7,1.2c-7,12.7-13.8,29-20.1,49c-0.5,2.3-1.2,4.5-2.3,6.7c-0.3,0.6-0.5,1.1-0.7,1.6
	c1,0.1,1.6-0.3,2.2-1.5C310.3,227.8,317.5,208.8,325.5,191L325.5,191z M333.7,191.2c1-0.7,0.1-1.1-2.3-1.2c-1.4-0.4-2.5-0.1-3.3,1
	c-9.2,18.8-16.6,40.4-22.1,65.1l8.1-0.6C318.6,231.9,325.2,210.4,333.7,191.2L333.7,191.2z"
          />
          <path
            fill="none"
            d="M301.8,249.6c-1.6,0.3-3.2,1.2-4.4,2.9c0.1,1.9,0.5,3.4,1.2,4.3l3,3.4c1.4-1.4,2.8-2.8,4.3-4.1l-2.8,0.1
	C301.5,254.6,301,252.4,301.8,249.6z"
          />
          <path
            fill="none"
            d="M298.5,256.8c-0.7-0.8-1.1-2.2-1.2-4.3c-0.1-1.4,0-3,0.1-5c-2.6,0.7-4.7,1.5-6.2,2.5l5,7.2
	C296.9,257.1,297.7,256.9,298.5,256.8L298.5,256.8z M296.2,257.3l-5-7.2c-3.4,2.2-4.4,5.1-2.8,8.9c0.7,0.8,1.2,1.8,1.6,3
	C291.5,259.7,293.6,258,296.2,257.3L296.2,257.3z"
          />
          <path
            fill="none"
            d="M298.5,256.8c-0.8,0.1-1.6,0.3-2.3,0.4c-2.6,0.8-4.7,2.3-6.1,4.8c0.7,1.6,1.2,3.9,1.5,6.3
	c0.3,1.5,0.4,3.2,0.4,5c2.1-4.1,5.2-8.5,9.5-13.1L298.5,256.8L298.5,256.8z"
          />
          <path
            fill="none"
            d="M289.3,272.7l-0.3,0.7c-0.5,1.4-1,2.8-1.4,4.1c-0.7,2.6-1.2,5.1-1.4,7.7c0.8-1.9,2.1-3.4,3.4-4.5l0.1-0.7
	c0.1-0.8,0.4-1.5,0.5-2.3c0.4-1.4,1-2.8,1.6-4v-0.1c-0.1-1.8-0.3-3.4-0.4-5c-0.4-2.6-0.8-4.7-1.5-6.3c0.4,2.2,0.5,4.3,0.3,6.3
	C290.1,270,289.7,271.4,289.3,272.7L289.3,272.7z"
          />
          <path
            fill="none"
            d="M289,273.4l0.3-0.7c0.5-1.4,0.8-2.8,1.1-4.3h-5.2c-0.5,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4v1c0,0.5,0.1,1,0.5,1.4
	s0.8,0.5,1.2,0.5h4L289,273.4L289,273.4z M294.4,309.9l-4.3-21c-0.8-2.6-1-5.4-0.4-8.3c-1.4,1.1-2.6,2.6-3.4,4.5
	c-1.8,3.9-2.3,9.2-1.9,16.1v0.1l1.9,10.5l5.5,31.1l9.6,1.2L294.4,309.9L294.4,309.9z"
          />
          <path
            fill="none"
            d="M284.1,275.1v0.5c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5h1.6c0.4-1.4,0.8-2.8,1.4-4.1h-4l-0.4,0.3
	C284.2,274.1,284.1,274.5,284.1,275.1L284.1,275.1z M303.9,378.2c-3.9-2.1-6.3-4.5-7.2-7.6l-5-27.8l-5.5-31.1v91.1
	c0.1,5.4,1.7,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8c1.4,0.1,2.9,0.1,4.7,0H311c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-10.7
	c-5-0.8-8.3-1.8-9.9-2.9C307,388.4,305.1,384.9,303.9,378.2L303.9,378.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M410.1,277h5.9 M409.4,276.2h6.7 M410.8,277.7h5.1 M413.8,280h2.8
	 M411.6,278.5h4.4 M412.6,279.2h3.6 M426,277h5.9 M425.9,276.2h6.7 M430.4,278.5H426 M428.2,280h-2.8 M429.5,279.2h-3.6
	 M421.9,276.7l1.1,1.2v-1.2h0.4v2.1H423l-1.1-1.2v1.2h-0.4v-2.1H421.9z M420.4,276.7v2.1h0.5v-2.1H420.4z M417.5,276.7v2.1h0.5v-1.1
	l0.5,1.1h0.1l0.5-1.1v1.1h0.5v-2.1h-0.5l-0.5,1.2l-0.7-1.2H417.5z M423.8,276.7v2.1h0.5v-2.1H423.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M424.1,274.9c-0.8-0.8-1.9-1.2-3.2-1.2s-2.3,0.4-3.2,1.2
	c-0.8,0.8-1.2,1.6-1.2,2.9c0,1.1,0.4,2.1,1.2,2.8c0.8,0.8,1.9,1.1,3.2,1.1s2.2-0.4,3.2-1.1c0.8-0.8,1.2-1.8,1.2-2.8
	C425.5,276.7,424.9,275.8,424.1,274.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M423.8,275.2c-0.8-0.7-1.8-1.1-2.9-1.1s-2.1,0.4-2.9,1.1
	c-0.8,0.7-1.2,1.5-1.2,2.6s0.4,1.8,1.2,2.5c0.8,0.7,1.8,1.1,2.9,1.1c1.1,0,2.1-0.4,2.9-1.1s1.2-1.5,1.2-2.5
	C425.1,276.7,424.7,275.9,423.8,275.2L423.8,275.2z M431.3,277.7h-5.1 M493.3,195.7c-13.1-0.8-25.5-1.4-37.4-1.5l-26,0.1l-44.6-0.1
	l-36.5,1.5c-7.6,0-12.8,2.8-15.7,8.3c-4.4,9.4-8,20.5-10.6,33.3c-1.6,7.8,1.2,12,8.7,12.1c13.5-0.4,26.4-0.7,39.1-0.8l15.7-0.1h71.8
	c17.1,0.1,34.8,0.4,53.4,1c7.4-0.3,10.3-4.3,8.7-12.1c-2.6-12.9-6.2-23.9-10.6-33.3C506.1,198.5,500.9,195.7,493.3,195.7
	L493.3,195.7z M516.6,191l0.7,1.2c7,12.7,13.8,29,20.1,49c0.5,2.3,1.2,4.5,2.3,6.7l0.7,1.6 M514.1,191h2.5 M514.1,191
	c-0.8-1.1-1.9-1.4-3.3-1c-2.5,0.1-3.3,0.6-2.3,1.2 M494.4,175.6c0.4,0.6,0.7,1.4,1.1,2.5l0.3,1.4c0,0.6,0.1,1,0.5,1.4
	s0.8,0.6,1.4,0.6h1.4c0.5,0,1-0.1,1.4-0.6s0.5-0.8,0.5-1.4v-1.4c0-0.7-0.1-1.4-0.4-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M516.6,191c-4.8-8.7-10-13.8-15.8-14.9h-0.3 M500.6,176
	c-0.3-0.8-0.5-1.5-1-2.2c-0.4-0.8-1.5-1.2-3.3-1.2c-1.9,0.1-3,0.6-3.4,1.1c-0.4,0.7-0.7,1.5-0.7,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M494.4,175.6c-0.4-0.4-0.8-0.7-1.2-0.4c-0.4,0.1-0.5,0.4-0.7,0.8
	 M492.1,176l0.4-0.1 M492.5,175.9c0.5-0.1,1.1-0.3,1.9-0.3 M492.1,176c-0.7,0.3-1,0.7-1.1,1.2c-15.5-2.6-37-4-64.4-4.1h-1.8
	 M516.6,191c8,17.7,15.1,36.7,21.6,57.1c0.5,1.2,1.2,1.8,2.2,1.5 M553.7,259.1c8.4,0.5,14-0.7,17.1-3.7c2.9-2.8,3.6-6.1,2.3-9.9
	l-0.1-0.4c-1.4-3-4.3-5.5-9.1-7.2c-5.2-1.6-9.5-1.6-12.8,0c-4.5,2.2-6.6,5.5-6.5,9.8 M422.6,170.7l-0.5-6.9c0-0.3-0.1-0.4-0.3-0.6
	c-0.1-0.1-0.3-0.3-0.5-0.3c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.6l-0.4,6.9 M422.6,170.7H420 M417.5,173.1h-2.1
	c-27.4,0.1-48.8,1.5-64.4,4.1c-0.1-0.6-0.5-1-1.1-1.2 M417.5,173.1h7.4 M508.4,191.2c-19.3-0.8-36.7-1.1-52.5-1.2h-70.6
	c-15.5,0-32.7,0.4-51.6,1.2 M424.9,173.1c0-1.6-0.8-2.5-2.3-2.6 M420,170.7c-1.4,0.3-2.2,1.2-2.6,2.6 M421.5,265.7h8.3
	c8.9-0.5,14.6-2.3,17.1-5.2l6.9-8.1 M416.5,275.4l0.8-0.8c1-1,2.2-1.4,3.6-1.4c1.4,0,2.6,0.4,3.6,1.4l0.8,0.8 M425.3,275.4l0.4,0.8
	l0.3,0.8l0.1,0.7v0.7l-0.1,0.8l-0.4,0.8l-0.7,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M417.2,280.9l-0.7-0.8l-0.4-0.8l-0.1-0.7l-0.1-0.7v-0.1l0.1-0.7l0.1-0.8
	l0.4-0.8 M424.8,280.9l-0.1,0.1c-1,1-2.2,1.4-3.6,1.4c-1.4,0-2.6-0.4-3.6-1.4l-0.1-0.1 M424.8,280.9h2.2l1.4-0.8l1.2-0.8l1-0.8
	l0.8-0.7l0.7-0.7l0.7-0.8l0.7-0.8h-8 M433,292.4c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1v-0.7L433,292.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M433.2,295.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4C432.2,295.2,432.6,295.3,433.2,295.3
	L433.2,295.3z M433,298.1c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15
	c-0.3,0-0.4,0-0.6-0.1v-0.7L433,298.1L433,298.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M432.1,300.4c0.1,0.4,0.5,0.5,1.2,0.5h15c0.5,0,1-0.1,1.2-0.5
	c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2
	C431.8,299.7,431.8,300.1,432.1,300.4z M433,303.4c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1v-0.7L433,303.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M433.2,306.3h15c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2
	c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5l-0.3,1.2c-0.1,0.5-0.1,1.1,0.1,1.4C432.2,306.2,432.6,306.3,433.2,306.3
	L433.2,306.3z M430.7,291.2c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,290.6,430.6,290.9,430.7,291.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M430,291.7c-0.1-0.1-0.3-0.1-0.5-0.1h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7
	l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9c0.5,0,1-0.3,1.1-0.8l0.4-1.2L430,291.7L430,291.7z M429.5,297.1h-16.6c-0.3,0-0.4,0-0.5,0.1
	v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9c0.5,0,1-0.3,1.1-0.8l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7C429.9,297.1,429.8,297.1,429.5,297.1
	L429.5,297.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M430.7,296.7c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5
	h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6
	C430.2,296.1,430.6,296.4,430.7,296.7z M430.7,302c0.3,0.3,0.3,0.7,0.1,1.4l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9
	c-1.1,0-1.8-0.6-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.3-0.4,0.7-0.5,1.2-0.5h16.6C430.2,301.6,430.6,301.8,430.7,302
	L430.7,302z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M429.6,304.5l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1
	h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9C429.1,305.3,429.5,305.1,429.6,304.5L429.6,304.5z
	 M430.9,309.1l-0.4,1.2c-0.3,1-1,1.5-1.9,1.5h-14.9c-1.1,0-1.8-0.5-2.1-1.5l-0.4-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	c0.3-0.4,0.7-0.5,1.2-0.5h16.6c0.7,0,1.1,0.1,1.4,0.5C431,308,431.1,308.5,430.9,309.1L430.9,309.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M429.6,310.2l0.4-1.2c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.1-0.5-0.1
	h-16.6c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.5,0.8,1.1,0.8h14.9C429.1,311,429.5,310.7,429.6,310.2L429.6,310.2z
	 M419.8,316.2h2.5 M422.3,317.2v0.7 M422.3,317.9v1.2 M422.3,319.1v0.8 M422.3,319.9v1.5 M419.8,317.9v-0.7 M419.8,319.1v-1.2
	 M419.8,319.9v-0.8 M419.8,321.4v-1.5 M419.8,323.4v-1.1 M422.3,323.4h-2.5 M422.3,322.3v1.1 M419.8,322.3v-0.8 M422.3,321.4v0.8
	 M422.3,316.2v1 M419.8,317.2v-1 M432.8,310.2l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7
	c-0.1,0.1-0.3,0.1-0.4,0.1h-15c-0.3,0-0.4,0-0.6-0.1C432.6,310.7,432.6,310.4,432.8,310.2L432.8,310.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M432,310c-0.1,0.6-0.1,1.1,0.1,1.4c0.1,0.4,0.5,0.5,1.2,0.5h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5h-13.3c-1,0-1.5,0.5-1.8,1.5L432,310L432,310z
	 M477.5,290.8h-0.5c-11.7-1.1-26.3-1.6-43.6-1.6h-25.6c-16.9,0.1-31.1,0.7-42.5,1.6h-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M483.5,292.1l1.5,1.4c0.8,0.7,1.2,1.1,1.1,1.4c0.1,0.4-0.1,0.5-0.7,0.5
	l-14.7-0.1c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.5-1.5h6.2 M467.4,292.4l-0.3,1.2
	c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1
	C467.4,291.9,467.4,292.1,467.4,292.4L467.4,292.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M468.1,292.5c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15
	c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5L468.1,292.5L468.1,292.5z
	 M470.7,294.5l14,0.1c0.3,0,0.3,0,0.4-0.1l-2.1-2.1c-0.5-0.5-1.1-0.8-1.5-0.8h-10.3c-0.4,0-0.7,0.3-0.8,0.8l-0.3,1.2v0.7
	C470.3,294.5,470.5,294.5,470.7,294.5L470.7,294.5z M466.9,296.3h-15c-0.5,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4C467.9,296.4,467.4,296.3,466.9,296.3L466.9,296.3
	z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M467.4,297.2v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3
	c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15C467.2,297.1,467.3,297.1,467.4,297.2L467.4,297.2z M469.8,297.8
	c0.3-1,0.8-1.5,1.8-1.5l13.8,0.1c1.1,0,2.1,0.5,3.2,1.5l1.2,1.2c0.7,0.5,0.8,1,0.7,1.2c0,0.4-0.4,0.5-1.2,0.4h-18.3
	c-0.7,0-1.1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4L469.8,297.8L469.8,297.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M470.3,300c0.1,0.1,0.3,0.1,0.5,0.1l17.9,0.1c0.3,0,0.5,0,0.5-0.1
	c0-0.1-0.1-0.4-0.4-0.7l-1.2-1.2c-0.5-0.5-1.1-0.8-1.8-0.8l-14.3-0.1c-0.6,0-0.8,0.3-1,0.8l-0.3,1.2V300L470.3,300z M469.8,303.3
	c0.3-1,1-1.5,1.9-1.5h16.5c1.4,0,2.3,0.5,3,1.5l1,1.2c0.5,0.5,0.7,1.1,0.4,1.4c0,0.4-0.5,0.5-1.4,0.5h-20.5c-0.5,0-1.1-0.1-1.2-0.5
	c-0.3-0.3-0.3-0.7-0.1-1.4L469.8,303.3L469.8,303.3z M467,304.5c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2v-0.7
	c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7L467,304.5L467,304.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M470.3,305.3c0.1,0.1,0.3,0.1,0.5,0.1H491c0.4,0,0.5,0,0.7-0.1
	c0-0.1,0-0.4-0.3-0.7l-1-1.2c-0.4-0.5-1-0.8-1.8-0.8h-16.8c-0.5,0-0.8,0.3-1.1,0.8l-0.4,1.2L470.3,305.3L470.3,305.3z M468.1,303.6
	c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.6,0-1,0.1-1.2,0.5c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2
	c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5L468.1,303.6L468.1,303.6z M451.3,308.2c0.1-0.1,0.3-0.1,0.4-0.1h15
	c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2
	C451.3,308.6,451.3,308.4,451.3,308.2L451.3,308.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M450.5,309.1l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5
	l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15c-0.5,0-1,0.1-1.2,0.5C450.5,308.1,450.4,308.5,450.5,309.1
	L450.5,309.1z M451.8,316.2H450 M451.8,317.9v-0.7 M451.8,319.9v1.5 M451.8,319.1v0.8 M451.8,317.9v1.2 M450,321.4v-1.5 M450,319.9
	v-0.8 M450,317.9v-0.7 M450,319.1v-1.2 M450,323.4v-1.1 M451.8,322.3v1.1 M450,322.3v-0.8 M450,323.4h1.8 M451.8,321.4v0.8
	 M451.8,316.2v1 M450,317.2v-1 M465.9,317.2c-1.5-0.5-3.2-0.8-5-1h-9.2 M472.9,313l0.8-0.1h18.4c1.6,0,2.8,0.4,3.2,1.5l0.8,1.5
	 M369.2,313h0.3c1.1-0.3,2.3-0.4,3.4-0.4h96.3c1.2,0,2.3,0.1,3.4,0.4h0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M472.3,315l0.3,0.1c1,0.3,1.9,0.5,2.9,1.1l3.3,1.5
	c0.8,0.4,1.6,0.7,2.6,0.8l3.4,0.3h10.3c3.3-1.1,4.7-3.4,3.7-7v-0.1c-2.6-6.3-5-11.1-7-14.7c-2.6-4.7-7.6-7.3-14.9-8
	c-11.8-1.1-26.4-1.6-43.7-1.6h-25.6c-16.9,0.1-31.2,0.7-42.8,1.6c-7.2,0.7-12.1,3.3-14.9,8c-2.1,3.6-4.4,8.4-7,14.7v0.1
	c-0.8,3.6,0.4,5.9,3.7,7H357l3.4-0.3c0.8-0.3,1.8-0.5,2.6-0.8l3.3-1.5c1-0.4,1.9-0.8,2.9-1.1l0.3-0.1l0.5-0.1l2.5-0.3h96.3l2.5,0.3
	L472.3,315L472.3,315z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M475.1,313.7l1.1,0.4l2.9,1.4c0.8,0.4,1.6,0.7,2.6,1l1.9,0.3h9.9
	 M475.1,313.7c-0.7-0.3-1.5-0.5-2.2-0.7 M469.5,310l0.4-1.2c0.3-1,1-1.5,1.9-1.5h18.4c1.6,0,2.6,0.4,3.2,1.5l0.7,1.2
	c0.3,0.6,0.4,1.1,0,1.4c-0.1,0.4-0.7,0.5-1.6,0.5h-21.7c-0.5,0-1-0.1-1.2-0.5C469.4,311,469.4,310.6,469.5,310L469.5,310z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M470.7,311h21.6c0.4,0,0.7,0,0.8-0.1c0.1-0.1,0.1-0.4-0.1-0.7l-0.7-1.2
	c-0.3-0.5-1-0.8-1.9-0.8h-18.7c-0.5,0-0.8,0.3-1.1,0.8l-0.4,1.2c-0.1,0.3-0.1,0.5,0,0.7C470.3,311,470.5,311,470.7,311L470.7,311z
	 M471,321.4l-0.3-0.8l-0.5-0.7 M470.2,319.9l-0.8-0.8 M469.4,319.1l-1.6-1.2 M467.7,317.9l-1.6-0.8 M471,322.3
	c0.1-0.3,0.1-0.5,0-0.8 M451.8,323.4h15.7c2.1,0,3.3-0.4,3.6-1.2 M469.4,319.1h-17.6 M451.8,322.3H471 M470.2,319.9h-18.4
	 M451.8,321.4H471 M467.7,317.9h-16 M451.8,317.2h14.2 M422.3,322.3H450 M422.3,321.4H450 M422.3,319.9H450 M422.3,319.1H450
	 M422.3,317.9H450 M450,317.2h-27.6 M453.7,252.4L435,252h-27.8l-18.2,0.4 M422.3,323.4H450 M450,316.2h-27.6 M421.2,265.7H413
	c-8.9-0.5-14.6-2.3-17.1-5.2l-6.9-8.1 M416.5,275.4h-7.8l0.7,0.8l0.7,0.8l0.7,0.7l0.8,0.8l1,0.7l1.2,0.8l1.2,0.8h2.2 M294.4,309.9
	l48.3,2.2c4.8-11,10.5-22.1,16.8-33.3c1.1-3.7-0.4-7.4-4.4-10.9c-5.8-5.5-12.9-9.9-21.6-13.3 M374.7,292.4l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1
	C374.7,291.9,374.7,292.1,374.7,292.4L374.7,292.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375.3,290.8h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4C374.3,290.9,374.6,290.8,375.3,290.8
	L375.3,290.8z M371.6,292.4l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1l-14,0.1c-0.3,0-0.3,0-0.4-0.1c0-0.1,0.3-0.4,0.7-0.7l1.4-1.4
	c0.5-0.5,1.1-0.8,1.5-0.8h10.3C371.1,291.6,371.4,291.9,371.6,292.4L371.6,292.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M358.5,292.1l-1.5,1.4c-0.8,0.7-1.2,1.1-1.1,1.4c-0.1,0.4,0.1,0.5,0.7,0.5
	l14.7-0.1c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.5-1.5h-6.2 M375.3,296.3h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C374.3,296.4,374.6,296.3,375.3,296.3L375.3,296.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M374.7,297.2v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8
	l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15C375,297.1,374.9,297.1,374.7,297.2L374.7,297.2z M375.3,301.6h15c0.5,0,1,0.1,1.2,0.5
	c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4
	C374.3,301.8,374.6,301.6,375.3,301.6L375.3,301.6z M372.2,297.8c-0.3-1-0.8-1.5-1.8-1.5l-13.8,0.1c-1.1,0-2.1,0.5-3.2,1.5l-1.2,1.2
	c-0.7,0.5-0.8,1-0.7,1.2c0,0.4,0.4,0.5,1.2,0.4h18.3c0.7,0,1.1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4
	C372.5,298.7,372.4,298.3,372.2,297.8L372.2,297.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M371.7,299.3c0.1,0.3,0.1,0.6,0,0.7c-0.1,0.1-0.3,0.1-0.5,0.1l-17.9,0.1
	c-0.3,0-0.5,0-0.5-0.1c0-0.1,0.1-0.4,0.4-0.7l1.2-1.2c0.5-0.5,1.1-0.8,1.8-0.8l14.3-0.1c0.5,0,0.8,0.3,1,0.8L371.7,299.3
	L371.7,299.3z M371.4,303.4l0.4,1.2v0.7c-0.1,0.1-0.3,0.1-0.5,0.1h-20.1c-0.4,0-0.5,0-0.7-0.1c0-0.1,0-0.4,0.3-0.7l1-1.2
	c0.4-0.5,1-0.8,1.8-0.8h16.8C370.9,302.6,371.3,302.9,371.4,303.4L371.4,303.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M372.2,303.3c-0.3-1-1-1.5-1.9-1.5h-16.5c-1.4,0-2.3,0.5-3,1.5l-1,1.2
	c-0.5,0.5-0.7,1.1-0.4,1.4c0,0.4,0.5,0.5,1.4,0.5h20.5c0.5,0,1.1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4L372.2,303.3L372.2,303.3z
	 M375,304.5c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1v0.7
	L375,304.5L375,304.5z M364.5,290.8c-2.3,0.3-4.3,0.7-6.1,1.5 M348.5,316.8h9.9l1.9-0.3c0.8-0.3,1.8-0.5,2.6-1l2.9-1.4l1.1-0.4
	 M374,307.7c0.1-0.4,0.5-0.5,1.2-0.5h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.3
	c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2C373.8,308.5,373.8,308.1,374,307.7L374,307.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M374.7,308.9l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8
	l0.3-1.2v-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-15c-0.3,0-0.4,0-0.5,0.1C374.7,308.4,374.7,308.6,374.7,308.9L374.7,308.9z M371.8,310.8
	c-0.1,0.1-0.3,0.1-0.4,0.1h-21.6c-0.4,0-0.7,0-0.8-0.1c-0.1-0.1-0.1-0.4,0.1-0.7l0.7-1.2c0.3-0.5,1-0.8,1.9-0.8h18.7
	c0.5,0,0.8,0.3,1.1,0.8l0.4,1.2C372,310.6,372,310.7,371.8,310.8L371.8,310.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M372.7,310l-0.4-1.2c-0.3-1-1-1.5-1.9-1.5h-18.4c-1.6,0-2.6,0.4-3.2,1.5
	L348,310c-0.3,0.6-0.4,1.1,0,1.4c0.1,0.4,0.7,0.5,1.6,0.5h21.7c0.5,0,1-0.1,1.2-0.5C372.8,311,372.8,310.6,372.7,310L372.7,310z
	 M369.2,313l-0.8-0.1H350c-1.6,0-2.8,0.4-3.2,1.5l-0.8,1.5 M371.1,321.4c0-0.3,0.1-0.6,0.3-0.8l0.7-0.8 M371.1,322.3
	c-0.1-0.3-0.1-0.5,0-0.8 M390.4,323.4h-15.7c-2.1,0-3.3-0.4-3.6-1.2 M372,319.9l0.8-0.8 M372.8,319.1l1.6-1.2 M376.1,317.2
	c1.5-0.5,3.2-0.8,5-1h9.2 M374.4,317.9l1.6-0.8 M366.9,313.7c0.7-0.3,1.5-0.5,2.2-0.7 M408,291.6h-13.3c-0.5,0-0.8,0.3-1,0.8
	l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2C409,291.9,408.6,291.6,408,291.6L408,291.6z
	 M409.4,299.3l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15
	c0.3,0,0.4,0,0.5-0.1C409.4,299.8,409.4,299.6,409.4,299.3L409.4,299.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M408.8,300.9h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2
	c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4C409.9,300.8,409.5,300.9,408.8,300.9L408.8,300.9
	z M408.8,306.3h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4C409.9,306.2,409.5,306.3,408.8,306.3L408.8,306.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M408,302.6h-13.3c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2v0.7
	c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2C409,302.9,408.6,302.6,408,302.6L408,302.6z M393.7,308.9l-0.3,1.2
	v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8h-13.3
	C394.1,308.1,393.8,308.4,393.7,308.9L393.7,308.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M394.7,307.3H408c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.6,0.1,1.1-0.1,1.4
	c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4l0.3-1.2C393.2,307.7,393.7,307.1,394.7,307.3
	L394.7,307.3z M390.4,319.9v1.5 M390.4,317.2v0.7 M390.4,321.4v0.8 M390.4,322.3v1.1 M390.4,319.1v0.8 M390.4,317.9v1.2
	 M392.2,317.9v-0.7 M392.2,319.1v-1.2 M392.2,319.9v-0.8 M392.2,321.4v-1.5 M392.2,322.3v-0.8 M392.2,323.4v-1.1 M390.4,316.2h1.8
	 M392.2,323.4h-1.8 M392.2,317.2v-1 M390.4,316.2v1 M376.1,317.2h14.3 M371.1,321.4h19.3 M371.1,322.3h19.3 M390.4,319.9H372
	 M374.4,317.9h16 M390.4,319.1h-17.6 M395.9,350.3l-0.3,1.2v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2
	c-0.1-0.5-0.4-0.8-1-0.8H397C396.3,349.5,396,349.8,395.9,350.3L395.9,350.3z M392.9,349.5c-0.1-0.1-0.3-0.1-0.4-0.1h-15
	c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2C393,349.9,393,349.6,392.9,349.5
	L392.9,349.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M393.6,349.1c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5
	h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.6,1.2-0.6h15C393,348.5,393.4,348.8,393.6,349.1
	L393.6,349.1z M394.8,351.3l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4
	c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5C394.7,352.4,394.7,352,394.8,351.3L394.8,351.3z M392.5,354.9h-15
	c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2V355C392.9,355,392.7,354.9,392.5,354.9
	L392.5,354.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M393.6,354.6c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5
	h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15C393,354,393.4,354.3,393.6,354.6
	L393.6,354.6z M395.6,357.8c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7l-0.3-1.2c-0.1-0.6-0.4-0.8-1-0.8H397
	c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2C395.5,357.5,395.5,357.6,395.6,357.8L395.6,357.8z M393.6,360c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2
	c-0.3,1-0.8,1.5-1.8,1.5h-13.3c-1,0-1.5-0.5-1.8-1.5l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5h15
	C393,359.5,393.4,359.7,393.6,360L393.6,360z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M395.1,355.7c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2
	c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4L395.1,355.7L395.1,355.7z
	 M395.1,361.1c0.3-1,0.8-1.5,1.8-1.5h13.3c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15
	c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4L395.1,361.1L395.1,361.1z M392.5,360.4h-15c-0.3,0-0.4,0-0.5,0.1v0.7l0.3,1.2
	c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2v-0.7C392.9,360.4,392.7,360.4,392.5,360.4L392.5,360.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M395.6,363.3c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1v-0.7
	l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2C395.5,362.8,395.5,363.1,395.6,363.3L395.6,363.3z
	 M414.2,348.5H428l0.7,0.1l0.4,0.4l0.1,0.3c0.1,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5
	l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4C413.4,348.7,413.7,348.5,414.2,348.5L414.2,348.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M413.8,350.2l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8
	l0.3-1.2v-0.7l-0.1-0.1h-14.2c-0.3,0-0.4,0-0.4,0.1C413.7,349.6,413.7,349.9,413.8,350.2L413.8,350.2z M414.2,354H428l0.7,0.1
	c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4C413.4,354.2,413.7,354,414.2,354L414.2,354z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M413.8,355v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8
	l0.3-1.2V355l-0.1-0.1l-0.3-0.1h-13.9C413.9,354.9,413.8,354.9,413.8,355L413.8,355z M414.2,359.4H428l0.7,0.1
	c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,0.8v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2
	c-0.1-0.5-0.1-1.1,0.1-1.4C413.4,359.5,413.7,359.4,414.2,359.4L414.2,359.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M413.8,361.1l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2c0.5,0,0.8-0.3,1-0.8
	l0.3-1.2v-0.7l-0.1-0.1h-14.2c-0.3,0-0.4,0-0.4,0.1C413.7,360.5,413.7,360.8,413.8,361.1L413.8,361.1z M410.2,365
	c1,0,1.5,0.5,1.8,1.5l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4
	l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5H410.2L410.2,365z M382.2,326.8H460c1.4,0,1.9,0.7,1.9,1.9v14.2c0,1.4-0.7,1.9-1.9,1.9h-77.9
	c-1.4,0-1.9-0.7-1.9-1.9v-14.2C380.2,327.4,380.8,326.8,382.2,326.8L382.2,326.8z M357,333.3v3.6c0,1.8-1,2.8-2.8,2.8h-3.6
	c-1.8,0-2.8-1-2.8-2.8v-3.6c0-1.8,1-2.8,2.8-2.8h3.6C356,330.5,357,331.3,357,333.3L357,333.3z M364.8,351h4.7
	c1.2,0,1.8,0.5,1.8,1.8v10.7c0,1.2-0.5,1.8-1.8,1.8h-5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M372.5,352.3v12c0,1.4-0.7,1.9-1.9,1.9h-7.7c-9.2-0.3-13.8-3.7-14-10.6
	v-3.3c0-1.4,0.7-1.9,1.9-1.9h19.7C371.8,350.3,372.5,350.9,372.5,352.3L372.5,352.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M364.7,365.3c3.2-0.4,4.5-1.5,4.3-3.3v-6.6c-0.1-2.6-1.5-4.1-4.1-4.3
	 M376.9,366v0.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h13.3c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366c-0.1-0.1-0.3-0.1-0.4-0.1h-15
	C377.2,365.9,377.1,366,376.9,366L376.9,366z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M376.5,368.2l-0.3-1.2c-0.1-0.5-0.1-1.1,0.1-1.4c0.1-0.4,0.5-0.5,1.2-0.5
	h15c0.5,0,1,0.1,1.2,0.5c0.3,0.3,0.3,0.7,0.1,1.4l-0.3,1.2c-0.3,1-0.8,1.5-1.8,1.5h-13.6C377.3,369.7,376.6,369.2,376.5,368.2z
	 M364.8,351h-13.1c-1.2,0-1.8,0.5-1.8,1.8v2.9c0.1,6.2,4.4,9.4,12.7,9.6h1.9 M319.7,392.1c3.7,0.5,8.1,1.2,13.6,1.6
	c9.4,0.3,16.1-0.7,20.4-2.9c2.9-1.5,4.8-3.9,5.9-7.1h8l1.9,1.8c0.4,0.4,0.8,0.5,1.4,0.5l2.6-0.1c0.4-0.1,1.1-0.8,2.1-2.2h19.8
	c2.9,2.2,9.6,3.4,20.2,3.9l7,0.1c12.9-0.1,21-1.5,24.4-4h19.8c1,1.4,1.6,2.1,2.1,2.2l2.6,0.1c0.5,0,1-0.1,1.4-0.5l1.9-1.8h8
	c1.1,3.3,3.2,5.6,5.9,7.2c4.3,2.2,11.1,3.2,20.4,2.9c5.4-0.5,9.9-1.1,13.6-1.6 M413.8,366.7l0.3,1.2c0.1,0.5,0.4,0.8,1,0.8h12.2
	c0.5,0,0.8-0.3,1-0.8l0.3-1.2V366l-0.1-0.1l-0.3-0.1h-13.9c-0.3,0-0.4,0-0.4,0.1C413.7,366.1,413.7,366.4,413.8,366.7L413.8,366.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M413.1,365.6c0.1-0.4,0.5-0.5,1.1-0.5H428l0.7,0.1
	c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.3c0,0.3,0.1,0.5,0,1v0.3l-0.4,1.4c-0.3,1-0.8,1.4-1.7,1.4h-12.2c-0.8,0-1.4-0.5-1.6-1.5l-0.3-1.2
	C412.8,366.3,413,365.9,413.1,365.6L413.1,365.6z M411.6,368.1l-0.3-1.2c-0.1-0.5-0.4-0.8-1-0.8H397c-0.5,0-0.8,0.3-1,0.8l-0.3,1.2
	v0.7c0.1,0.1,0.3,0.1,0.4,0.1h15c0.3,0,0.4,0,0.5-0.1C411.6,368.6,411.6,368.3,411.6,368.1L411.6,368.1z M448.5,349.1
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.6-1.2-0.6h-15C449.1,348.5,448.7,348.8,448.5,349.1L448.5,349.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M449.1,349.5c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7
	l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2C449.1,349.9,449.1,349.6,449.1,349.5z M449.7,354.9h15
	c0.3,0,0.4,0,0.5,0.1v0.7l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2V355
	C449.3,355,449.4,354.9,449.7,354.9L449.7,354.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M448.5,354.6c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5
	h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4c-0.1-0.4-0.5-0.5-1.2-0.5h-15C449.1,354,448.7,354.3,448.5,354.6
	L448.5,354.6z M447.4,351.3l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.7
	c0,0.3,0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5C447.5,352.4,447.5,352,447.4,351.3L447.4,351.3z
	 M447.4,356.9l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5
	l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5C447.5,358,447.5,357.5,447.4,356.9L447.4,356.9z M448.5,360
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5h-15C449.1,359.5,448.7,359.7,448.5,360z M447.4,362.3l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5H432
	c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.6c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15c0.5,0,1-0.1,1.2-0.5
	C447.5,363.4,447.5,363,447.4,362.3L447.4,362.3z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M449.1,361.2v-0.7c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1v0.7
	l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8L449.1,361.2z M477.5,365.3h-5c-1.2,0-1.8-0.5-1.8-1.8v-10.7
	c0-1.2,0.5-1.8,1.8-1.8h4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M477.2,351c-2.6,0.3-4,1.6-4.1,4.3v6.6c-0.3,1.8,1.1,2.9,4.3,3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M469.6,352.3v12c0,1.4,0.7,1.9,1.9,1.9h7.7c9.2-0.3,13.8-3.7,14-10.6v-3.3
	c0-1.4-0.7-1.9-1.9-1.9h-19.7C470.2,350.3,469.6,350.9,469.6,352.3L469.6,352.3z M449.7,365c-0.5,0-1,0.1-1.2,0.5
	c-0.3,0.3-0.3,0.7-0.1,1.4l0.3,1.2c0.3,1,0.8,1.5,1.8,1.5h13.3c1,0,1.5-0.5,1.8-1.5l0.3-1.2c0.1-0.5,0.1-1.1-0.1-1.4
	c-0.1-0.4-0.5-0.5-1.2-0.5L449.7,365L449.7,365z M430.6,352.3c-0.1-0.1-0.1-0.4,0-0.7l0.3-1.2c0.1-0.5,0.4-0.8,1-0.8h13.3
	c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15L430.6,352.3L430.6,352.3z M430.6,357.8v-0.7l0.3-1.2
	c0.1-0.6,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15L430.6,357.8L430.6,357.8z M430.9,361.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7c-0.1,0.1-0.3,0.1-0.4,0.1h-15l-0.4-0.1v-0.7L430.9,361.2L430.9,361.2z
	 M431.8,365c-0.8,0-1.4,0.4-1.6,1.1l-0.4,1.7c-0.1,0.3-0.1,0.5-0.1,0.8s0,0.4,0.1,0.5l0.1,0.1c0.3,0.3,0.5,0.3,1.1,0.3h15
	c0.5,0,1-0.1,1.2-0.5c0.3-0.3,0.3-0.7,0.1-1.4l-0.3-1.2c-0.3-1-0.8-1.5-1.8-1.5L431.8,365L431.8,365z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M446.1,368.9h-15c-0.3,0-0.4,0-0.4-0.1v-0.7l0.3-1.2
	c0.1-0.5,0.4-0.8,1-0.8h13.3c0.5,0,0.8,0.3,1,0.8l0.3,1.2v0.7C446.4,368.8,446.3,368.9,446.1,368.9L446.1,368.9z M465.2,366.7
	l-0.3,1.2c-0.1,0.5-0.4,0.8-1,0.8h-13.3c-0.5,0-0.8-0.3-1-0.8l-0.3-1.2V366c0.1-0.1,0.3-0.1,0.4-0.1h15c0.3,0,0.4,0,0.5,0.1
	C465.2,366.1,465.2,366.4,465.2,366.7L465.2,366.7z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M472.4,372.1c10.6,0,17.3-1.8,20.4-5.2c3.3-4.4,5.1-8.9,5.4-13.8
	c0.4-4.5-2.3-6.7-8.1-6.6H352.2c-5.8-0.1-8.4,2.1-8.1,6.6c0.3,4.8,1.9,9.4,5.4,13.8c3,3.6,9.8,5.4,20.4,5.2H472.4L472.4,372.1z
	 M392.2,316.2h27.6 M392.2,317.2h27.6 M392.2,317.9h27.6 M392.2,319.1h27.6 M392.2,319.9h27.6 M392.2,321.4h27.6 M419.8,323.4h-27.6
	 M392.2,322.3h27.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M540.8,344.1l-1.4,6.9c-1.1,4-5.1,5.9-11.7,5.9c-1.8,6.7-8.1,10.5-19.3,11
	h-0.1c-2.2,0.1-4.5,0.1-7,0h-8c-3.2,3.3-9.9,4.8-19.9,4.8H369.1c-10.2,0-16.8-1.5-19.9-4.8h-8c-2.5,0.1-5,0.1-7,0H334
	c-11-0.7-17.5-4.3-19.3-11c-6.7,0-10.6-1.9-11.7-5.9l-1.4-6.9 M546,257.3c-0.7-0.3-1.5-0.4-2.3-0.4 M543.7,256.8
	c0.7-0.8,1.1-2.2,1.2-4.3 M552,262.1c0.4-1.2,1-2.2,1.7-3 M546,257.3c2.6,0.8,4.7,2.3,6.1,4.8 M553.7,259.1c1.7-3.7,0.7-6.7-2.8-8.9
	 M550.9,250.2l-5,7.2 M553.1,273.4l-0.3-0.7c-0.6-1.4-0.8-2.8-1.1-4.3 M554.5,277.6h1.7c0.5,0,1-0.1,1.4-0.5
	c0.4-0.4,0.5-0.8,0.5-1.4v-0.5c0-0.5-0.1-1-0.5-1.4l-0.4-0.3 M554.5,277.6c-0.4-1.4-0.8-2.8-1.4-4.1 M557.1,273.4h-4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M557.1,273.4c0.5,0,1-0.3,1.2-0.5c0.4-0.4,0.5-0.8,0.5-1.4v-1
	c0-0.5-0.1-1-0.5-1.4s-0.8-0.5-1.4-0.5h-5.2 M543.9,277.4c-2.9-7-8.4-10.7-16.5-11.1h-0.1c-6.7,0.3-13.1,2.3-19.1,6.2
	c-5.4,3-7.7,7.4-7.2,13.1c0.7,5.8,2.6,11,5.8,15.5l0.1,0.1c2.2,2.5,4.7,4.3,7.3,5.1c1,0.3,2.1,0.5,3,0.7c3.2,0.4,7,0.3,11.4-0.7
	c6.5-1.4,10.9-4.3,13.2-8.8c2.9-5.4,3.7-11,2.6-17.2c0.1-0.3,0.1-0.5,0-0.8L543.9,277.4L543.9,277.4z M555.9,285.1
	c-0.8-1.9-2.1-3.4-3.4-4.5 M550.1,273.4c0.7,1.4,1.2,2.8,1.7,4l0.7,3 M552,262.1c-0.7,1.6-1.2,3.9-1.5,6.3c-0.3,1.5-0.4,3.2-0.4,5
	 M555.9,285.1c-0.1-2.5-0.7-5.1-1.4-7.7 M551.8,268.5c-0.3-2.1-0.1-4.1,0.3-6.3 M555.9,311.8l1.9-10.5v-0.1c0.4-7-0.3-12.4-1.9-16.1
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M552.5,280.6c0.5,2.9,0.3,5.6-0.4,8.3l-4.3,21 M540.3,249.6
	c0.7,2.8,0.3,5-1.4,6.7l-2.8-0.1 M540.5,260.4c-1.4-1.4-2.8-2.8-4.3-4.1 M536.2,256.1l-8-0.4 M527.4,268.9
	c6.9,0.4,11.6,3.4,13.9,9.5c1.7,6.3,1.1,12.2-1.9,17.6c-2.1,3.9-5.8,6.3-11.4,7.4c-4.1,0.8-7.6,1.1-10.6,0.7c-0.8,0-1.7-0.3-2.5-0.5
	c-2.1-0.7-4.1-2.2-6.1-4.3c-2.9-4.1-4.7-8.9-5.2-14.2c-0.4-4.5,1.5-8,5.8-10.5C515.3,271.2,521.2,269.2,527.4,268.9L527.4,268.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M508.6,254.6c-8.5,3.4-15.8,7.8-21.6,13.3c-4,3.4-5.4,7.2-4.4,10.9
	c6.3,11.1,12,22.1,16.8,33.3l48.3-2.2 M496.2,315.9c1-0.8,1.2-2.1,1-3.7c-2.6-6.2-4.8-11-6.9-14.4c-1.5-2.5-3.7-4.3-6.6-5.5
	 M495.1,316.5c0.1-0.1,0.1-0.4-0.1-0.7l-0.7-1.2c-0.3-0.5-1-0.8-1.9-0.8h-17.2 M493.7,316.8h1.1 M496.2,315.9l-1.1,0.5 M494.8,316.8
	c0.3,0,0.3,0,0.4-0.1 M494.8,316.8h-1.1 M528.2,255.7l-19.7-1.1 M523.6,354.6c0,2.3-0.8,4.4-2.5,6.1s-3.7,2.5-6.1,2.5
	s-4.4-0.8-6.1-2.5s-2.5-3.7-2.5-6.1s0.8-4.4,2.5-6.1s3.7-2.5,6.1-2.5s4.4,0.8,6.1,2.5S523.6,352.3,523.6,354.6L523.6,354.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M524.8,354.6c0,2.8-1,5.1-2.9,7c-1.9,1.9-4.3,2.9-7,2.9
	c-2.8,0-5.1-1-7-2.9s-2.9-4.3-2.9-7s1-5.1,2.9-7s4.3-2.9,7-2.9s5.1,1,7,2.9C523.8,349.5,524.8,351.8,524.8,354.6L524.8,354.6z
	 M477.5,365.3h1.9c8.3-0.3,12.5-3.4,12.7-9.6v-2.9c0-1.2-0.5-1.8-1.8-1.8h-13.1 M522.5,392.1v10.7c0.1,5.4,1.7,8.8,4.7,10.2
	c1,0.4,2.3,0.7,4.1,0.8h11.3c1.8,0.1,3.3,0.1,4.7,0c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-91.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M522.5,392.1c5-0.8,8.3-1.8,9.9-2.9c2.6-0.8,4.7-4.5,5.9-11 M538.3,378.2
	c-5.5,2.9-14.2,4.7-25.7,5.4c-19.1,0.7-29.6-0.1-31-2.8H360.7c-1.5,2.6-11.8,3.4-31,2.8c-11.6-0.7-20.1-2.5-25.7-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M550.4,342.9l-5,27.8c-1,3-3.3,5.5-7.2,7.6 M550.4,342.9l5.5-31.1
	 M540.8,344.1l9.6-1.2 M550.1,273.4c-2.1-4.1-5.2-8.5-9.5-13.1 M540.5,260.4l3.2-3.6 M547.8,309.9l-7,34.3 M544.8,252.7
	c-1.2-1.6-2.8-2.8-4.4-2.9 M544.8,247.7c2.6,0.7,4.7,1.5,6.2,2.5 M508.4,191.2c8.5,19.1,15.1,40.7,19.8,64.4 M508.6,254.6l-54.9-2.2
	 M477.5,290.8c2.3,0.3,4.3,0.7,6.1,1.5 M544.8,247.7c0.1,1.9,0.1,3.6,0.1,5 M536.2,256.1c-5.5-24.6-12.9-46.4-22.1-65.1
	 M297.4,247.7c0.3-4.3-1.9-7.6-6.5-9.8c-3.3-1.6-7.6-1.6-12.8,0c-4.7,1.6-7.7,4-9.1,7.2l-0.1,0.4c-1.4,3.9-0.5,7.2,2.3,9.9
	c3,3,8.8,4.3,17.1,3.7 M350,176c0-0.8-0.3-1.5-0.7-2.2c-0.4-0.6-1.5-1-3.4-1.1c-1.8,0-2.9,0.3-3.3,1.2c-0.4,0.7-0.7,1.4-1,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M349.7,175.9c-0.1-0.4-0.4-0.7-0.7-0.8c-0.4-0.1-0.8,0-1.2,0.4 M350,176
	l-0.3-0.1 M347.8,175.6c-0.5,0.7-1,1.9-1.4,3.9c0,0.6-0.1,1-0.5,1.4s-0.8,0.6-1.4,0.6h-1.4c-0.5,0-1-0.1-1.4-0.6
	c-0.4-0.4-0.5-0.8-0.5-1.4c0-1.2,0.1-2.3,0.4-3.4 M347.8,175.6c0.8,0,1.4,0.1,1.9,0.3 M341.6,176h-0.3c-5.8,1.2-11,6.2-15.8,14.9
	 M328,191h-2.5 M325.5,191l-0.7,1.2c-7,12.7-13.8,29-20.1,49c-0.5,2.3-1.2,4.5-2.3,6.7c-0.3,0.6-0.5,1.1-0.7,1.6 M333.7,191.2
	c1-0.7,0.1-1.1-2.3-1.2c-1.4-0.4-2.5-0.1-3.3,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M325.5,191c-8,17.7-15.1,36.7-21.6,57.1c-0.5,1.2-1.2,1.8-2.2,1.5
	 M333.7,272.5c5.4,3,7.7,7.4,7.2,13.1c-0.7,5.8-2.6,11-5.8,15.5l-0.1,0.1c-2.2,2.5-4.7,4.3-7.3,5.1c-1,0.3-2.1,0.5-3,0.7
	c-3.2,0.4-7,0.3-11.4-0.7c-6.5-1.4-10.9-4.3-13.2-8.8c-2.9-5.4-3.7-11-2.6-17.2c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.7,0.4-1.2,0.7-1.8
	v-0.1c2.9-7,8.3-10.7,16.5-11.1h0.1C321.5,266.4,327.8,268.5,333.7,272.5L333.7,272.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.4,274.8c-5.5-3.6-11.4-5.5-17.6-5.8h-0.1c-6.9,0.4-11.6,3.4-13.9,9.5
	c-1.6,6.3-1.1,12.2,1.9,17.6c2.1,3.9,5.8,6.3,11.4,7.4c4.1,0.8,7.6,1.1,10.6,0.7c0.8,0,1.6-0.3,2.5-0.5c2.1-0.7,4.1-2.2,6.1-4.3
	c2.9-4.1,4.7-8.9,5.2-14.2C338.5,280.7,336.6,277.3,332.4,274.8L332.4,274.8z M313.9,255.7l-8.1,0.4 M301.8,249.6
	c-0.7,2.8-0.3,5,1.4,6.7l2.8-0.1 M298.5,256.8c-0.7-0.8-1.1-2.2-1.2-4.3 M301.8,249.6c-1.8,0.3-3.2,1.2-4.4,2.9 M291.2,250.2l5,7.2
	 M298.5,256.8c-0.8,0.1-1.6,0.3-2.3,0.4 M296.2,257.3c-2.6,0.8-4.7,2.3-6.1,4.8 M301.7,260.4l-3.2-3.6 M289,273.4l0.3-0.7
	c0.5-1.4,0.8-2.8,1.1-4.3 M289.7,280.6l0.1-0.7c0.1-0.8,0.4-1.5,0.5-2.3c0.4-1.4,1-2.8,1.6-4 M292,273.4c-0.1-1.8-0.3-3.4-0.4-5
	c-0.4-2.6-0.8-4.7-1.5-6.3 M301.7,260.4c-4.4,4.5-7.6,8.9-9.5,13.1 M305.8,256.1c-1.5,1.4-2.9,2.8-4.3,4.1 M290,262.1
	c0.4,2.2,0.5,4.3,0.3,6.3 M294.4,309.9l-4.3-21c-0.8-2.6-1-5.4-0.4-8.3 M366.9,313.7h-17.2c-1,0-1.6,0.3-1.9,0.8l-0.7,1.2
	c-0.1,0.3-0.3,0.5-0.1,0.7 M346,315.9c0.3,0.3,0.7,0.4,1.1,0.7 M358.5,292.1c-2.9,1.2-5.2,3-6.6,5.5c-2.1,3.4-4.3,8.3-6.9,14.4
	c-0.4,1.7-0.1,2.9,1,3.7 M347.3,316.8h1.1 M347.3,316.8h1.1 M346.9,316.5c0,0.1,0.1,0.1,0.4,0.1 M333.6,254.6l-19.7,1.1
	 M290.3,268.5H285c-0.5,0-1,0.1-1.4,0.5s-0.5,0.8-0.5,1.4v1c0,0.5,0.1,1,0.5,1.4s0.8,0.5,1.2,0.5 M285,273.4l-0.4,0.3
	c-0.4,0.4-0.5,0.8-0.5,1.4v0.5c0,0.5,0.1,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5h1.6 M287.6,277.6c-0.7,2.6-1.2,5.1-1.4,7.7
	 M286.3,285.1c-1.8,3.9-2.3,9.2-1.9,16.1v0.1l1.9,10.5 M333.3,348.5c-1.6-1.6-3.7-2.5-6.1-2.5c-2.3,0-4.4,0.8-6.1,2.5
	c-1.6,1.6-2.5,3.7-2.5,6.1s0.8,4.4,2.5,6.1c1.7,1.6,3.7,2.5,6.1,2.5c2.3,0,4.4-0.8,6.1-2.5s2.5-3.7,2.5-6.1
	C335.9,352.3,335,350.2,333.3,348.5L333.3,348.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M334.3,361.6c1.9-1.9,2.9-4.3,2.9-7s-1-5.1-2.9-7s-4.3-2.9-7-2.9
	c-2.8,0-5.1,1-7,2.9c-1.9,1.9-2.9,4.3-2.9,7s1,5.1,2.9,7c1.9,1.9,4.3,2.9,7,2.9C330,364.5,332.4,363.5,334.3,361.6L334.3,361.6z
	 M291.8,342.9l9.6,1.2 M303.9,378.2c-3.9-2.1-6.3-4.5-7.2-7.6l-5-27.8 M319.7,392.1c-5-0.8-8.3-1.8-9.9-2.9c-2.6-0.8-4.7-4.5-5.9-11
	"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M286.1,311.8v91.1c0.1,5.4,1.6,8.8,4.7,10.2c1,0.4,2.3,0.7,4.1,0.8
	c1.4,0.1,2.9,0.1,4.7,0h11.3c1.8-0.1,3.2-0.4,4.1-0.8c3-1.5,4.5-4.8,4.7-10.2v-10.7 M285,273.4h4 M286.3,285.1
	c0.8-1.9,2.1-3.4,3.4-4.5 M286.1,311.8l5.6,31.1 M287.6,277.6c0.4-1.4,0.8-2.8,1.4-4.1 M288.3,259.1c-1.6-3.7-0.7-6.7,2.8-8.9
	 M301.4,344.1l-7-34.3 M288.3,259.1c0.7,0.8,1.2,1.8,1.6,3 M297.4,247.7c-2.6,0.7-4.7,1.5-6.2,2.5 M305.8,256.1
	c5.5-24.6,12.9-46.4,22.1-65.1 M313.9,255.7c4.7-23.8,11.3-45.3,19.8-64.4 M297.3,252.7c-0.1-1.4,0-3,0.1-5 M389,252.4l-55.4,2.2
	 M409.8,292.1l0.3,1.2c0.1,0.5,0.1,1.1-0.1,1.4c-0.1,0.4-0.5,0.5-1.2,0.5h-15c-0.5,0-1-0.1-1.2-0.5c-0.3-0.3-0.3-0.7-0.1-1.4
	l0.3-1.2c0.3-1,0.8-1.5,1.8-1.5h13.3C409,290.8,409.7,291.2,409.8,292.1L409.8,292.1z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M313.9,255.7
		c0,0,26.3-1.7,75.8-3.3c0,0,33-1.2,64,0c0,0,43.7,1.5,74.6,3.3c0,0-4.1-20.8-8.4-33.8c0,0-7.7-23.2-11.4-30.7
		c0,0-28.3-1.5-87.2-1.2c0,0-53-0.3-87.5,1.2c0,0-5.6,13.3-7.8,19.7C325.9,211,318.2,233.1,313.9,255.7z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M552,279.1
		c-2.3-11.3-15.8-23-15.8-23c-20.1-1.4-56.4-2.8-56.4-2.8c-36.7-1.7-58.7-1.7-58.7-1.7c-31.1,0.4-54.1,1.4-54.1,1.4
		c-35.1,1.1-61.1,3-61.1,3c-2.8,2.5-5.8,5.8-5.8,5.8c-7.7,8.7-9.6,15.5-9.6,15.5c-2.1,5.9-0.4,11-0.4,11l4.4,21.3l48.3,2.2l6.6-14.2
		l1.5-2.2c5.1-6.9,13.8-7,13.8-7l5.2-0.4c19.4-1.2,30.3-1.2,30.3-1.2l20.8-0.1h19.4c17.9,0,36.7,1.7,36.7,1.7
		c10.7,0.8,14.2,7,14.2,7c2.3,3.2,8.1,16.4,8.1,16.4l48.3-2.2l4.3-21C553.6,283.9,552,279.1,552,279.1z M339,294.1
		c0,0-1.2,3.6-3.7,7c0,0-3.3,4.1-7.6,5.4c0,0-4.4,1.4-10.7,0.4c0,0-6.2-0.7-10.5-3c0,0-3.2-1.8-5-4.3c0,0-2.6-3.3-3.9-8.9
		c0,0-1.1-4.1,0-10.3c0,0-0.1-0.3,0-0.8c0,0,1.7-6.1,6.7-9.8c0,0,4.3-3.3,10.7-3.3c0,0,7.6-0.1,16,4.5c0,0,3.6,1.9,5.2,3.3
		c0,0,4.5,3.3,4.8,8.5C341,282.5,341.7,286.8,339,294.1z M544.9,288.3c-0.6,5.4-3,9.4-3,9.4c-1.8,3.6-5.1,5.5-5.1,5.5
		c-4.8,3.4-13.5,3.9-13.5,3.9c-6.2,0.4-9.1-0.7-9.1-0.7c-4.3-1.5-7.3-5.1-7.3-5.1c-0.4-0.4-1.5-2.2-1.5-2.2
		c-3-5.2-3.9-10.3-3.9-10.3c-1.5-6.9,0.8-10.5,0.8-10.5c1.6-3.4,5.8-5.8,5.8-5.8c3.2-1.9,5.6-3,5.6-3c7.7-3.3,13.5-3.2,13.5-3.2
		c5.1,0.3,8,1.8,8,1.8c4.3,1.9,6.2,5,6.2,5c2.3,3.4,3.2,6.5,3.2,6.5c0.1,0.4-0.1,0.7-0.1,0.7C545.4,285.8,544.9,288.3,544.9,288.3z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M346.9,318.7c0,0-3-0.8-3.9-3.4
		c0,0-0.5-1,0-3.9c0,0,3.2-7.6,6.7-14.2c0,0,1.8-3.6,5.9-5.9c0,0,2.9-2.1,8.8-2.6c0,0,11.7-1.1,32.7-1.6l13.5-0.1h20.5
		c0,0,17.6,0.1,24.6,0.4c0,0,9.6,0.4,19.8,1.1c0,0,8.9,0.3,13.1,4.4c0,0,2.2,1.7,4.4,6.2c0,0,4.3,8.5,5.8,12.5c0,0,1,3.2-0.7,5
		c0,0-1.1,1.5-3,2.1h-10.3l-3.4-0.3c0,0-1.8-0.3-4.8-1.8c0,0-2.8-1.4-5-1.8l-2.3-0.3h-26h-22.3h-20.9h-27.2c0,0-2.2,0.1-3.4,0.4
		c-1.2,0.4-1.5,0.4-5.2,2.1c0,0-1.9,1.1-3.6,1.4l-3.4,0.3L346.9,318.7z"
              id="Grille"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Grille')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('grilleColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M294.4,309.9l48.6,2.2
		l0.1,0.4c0,0-0.5,2.5,0.8,4.1c0,0,1.1,1.5,3.2,2.1h10.3l3.3-0.3c0,0,2.2-0.4,4-1.5c0,0,4.5-2.3,7.2-2.2h1h20.1h22h16.2h22.6h15.5
		l2.3,0.3c0,0,1.9,0.1,5,1.8c0,0,2.6,1.5,4.8,1.8l3.4,0.3H495c0,0,2.5-0.7,3.6-2.8c0,0,1-1.4,0.4-3.9l48.7-2.1l-4.4,21.7l-2.6,12.5
		l9.6-1.2l-5,27.8c0,0-1,5.2-9.6,8.7c0,0-7.6,3.3-22.7,4.3c0,0-15.4,0.7-24.5-0.3c0,0-6.2-0.7-7-2.5h-38.4h-22h-25.6h-34.9
		c0,0-0.5,1.5-5,2.1c0,0-6.9,1.4-23.9,0.7c0,0-17.5-0.3-27.6-5.4c0,0-5.8-2.5-7.4-7.7l-5-27.8l9.6,1.2l-3.7-18L294.4,309.9z"
              id="Front Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Front Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('frontBumperColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M297.5,280.2
		c0,0-0.1-0.3,0-0.8c0,0,1.7-6.1,6.7-9.8c0,0,4.3-3.3,10.7-3.3c0,0,7.6-0.1,16,4.5c0,0,3.6,1.9,5.2,3.3c0,0,4.5,3.3,4.8,8.5
		c0,0,0.7,4.3-2.1,11.6c0,0-1.2,3.6-3.7,7c0,0-3.3,4.1-7.6,5.4c0,0-4.4,1.4-10.7,0.4c0,0-6.2-0.7-10.5-3c0,0-3.2-1.8-5-4.3
		c0,0-2.6-3.3-3.9-8.9C297.5,290.5,296.5,286.4,297.5,280.2z"
              id="Right Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightFrontHeadlightsColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M544.6,280.2
		c0,0,0.1-0.3,0.1-0.7c0,0-0.8-3-3.2-6.5c0,0-1.9-3-6.2-5c0,0-2.9-1.5-8-1.8c0,0-5.8-0.1-13.5,3.2c0,0-2.5,1.1-5.6,3
		c0,0-4.1,2.3-5.8,5.8c0,0-2.3,3.6-0.8,10.5c0,0,0.8,5.1,3.9,10.3c0,0,1.1,1.8,1.5,2.2c0,0,2.9,3.6,7.3,5.1c0,0,2.9,1.1,9.1,0.7
		c0,0,8.7-0.5,13.5-3.9c0,0,3.2-1.9,5.1-5.5c0,0,2.5-4,3-9.4C544.9,288.3,545.4,285.8,544.6,280.2z"
              id="Left Front - Headlights"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Headlights')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontHeadlightsColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Crossover4DrFront);
