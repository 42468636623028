export const SET_SIDEBAR_ENABLED_SUCCESS = 'SET_SIDEBAR_ENABLED_SUCCESS';

const setSideBarStatus = status => {
  return {
    type: SET_SIDEBAR_ENABLED_SUCCESS,
    status,
  };
};

export const handleSideBarStatus = status => dispatch => {
  dispatch(setSideBarStatus(status));
};
