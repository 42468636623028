import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe2DrTop extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M377.5,143.3c0.3-1-0.3-1.6-1.8-2.1l-13.2,27.6h0.7c1.4,0,2.5-0.7,3.3-1.8l3.2-6.7L377.5,143.3L377.5,143.3z
	"
          />
          <path
            fill="#FFFFFF"
            d="M375.8,141.2L370,141c-5.8,0.1-9.9,1.9-12.5,5.4l-10.6,13.8l-2.8,3.4c-0.7,2.8,0.7,4.1,4.5,4.3l9.5,0.7
	l4.4,0.4L375.8,141.2L375.8,141.2z"
          />
          <path
            fill="#FFFFFF"
            d="M363.3,168.9h-0.7l-4.4-0.4l-1.6,3.9v0.6c0,0.4-0.1,0.8-0.3,1.2c38.4-1.8,89.1-1.2,152,1.6l9.1,0.1l1-1.9
	c3-6.5,3-10.9,0.3-13.3l-12.4-0.1c1.9,0.7,3.6,1.5,5.1,2.6c-1.5,1.8-5.9,2.3-13.3,1.5l-15.5-0.3l-7-0.6c-3.3-0.6-4-1.2-2.1-2.1
	c0.7-0.4,1.5-0.8,2.5-1.1h-96.2l-10-0.1l-3.2,6.7C365.8,168.3,364.7,168.9,363.3,168.9L363.3,168.9z"
          />
          <path
            fill="#FFFFFF"
            d="M356.7,172.9v-0.4l1.5-3.9l-9.5-0.7c-3.7-0.1-5.2-1.5-4.5-4.3l-8,10.6c-0.1,0.6-0.1,1,0,1.2l0.1,0.4
	c0.4,0.8,1.4,1.1,3.2,1l13.2-0.6c1.5,0,2.5-0.4,3.2-1.2l0.5-1C356.6,173.7,356.7,173.3,356.7,172.9L356.7,172.9z"
          />
          <path
            fill="#FFFFFF"
            d="M344.3,163.5l2.8-3.4l-42-0.7c2.8,5.6,8.4,11.3,16.6,17.1l14.6-0.7l-0.1-0.4v-1.2L344.3,163.5L344.3,163.5z
	 M298.4,179.9c-0.7-0.8-1.4-1.5-2.1-2.2c-111.1,6.5-184.1,19.4-218.7,38.8c-0.7,2.1-1.2,4.3-1.8,6.6
	C111.4,200.8,185.6,186.4,298.4,179.9L298.4,179.9z"
          />
          <path
            fill="#FFFFFF"
            d="M302.2,185.9c-1.1-2.3-2.3-4.3-3.9-6.1c-112.8,6.5-186.9,20.8-222.6,43.2c-3.9,15.7-6.3,37.4-7.4,65.2
	c-0.4,4.4-0.6,8.9-0.6,13.6c0,4.8,0.1,9.4,0.6,13.8c1.1,27.6,3.6,49.4,7.4,65.2c35.6,22.4,109.9,36.9,222.6,43.2
	c1.5-1.8,2.8-3.7,3.9-6.1c-4.1-2.5-7.8-5.6-11-9.2c-6.2-6.9-11.4-15-15.8-24.2c-0.5-1.2-0.8-2.6-1-3.9
	c-79.1-6.6-140.9-17.6-185-32.9l-2.3-0.4l2.3,0.4c59.4,9.1,122.6,14.6,189.1,16.4c0.1-1.9,0-4-0.4-6.3c-2.9-16.8-4.3-35.4-4-55.9
	c-0.3-20.5,1.1-39.1,4-55.9c0.4-2.3,0.5-4.5,0.4-6.5c-66.7,1.8-129.7,7.3-189.1,16.4l-2.3,0.4l2.3-0.4
	c44.2-15.3,105.9-26.3,185-32.7c0.1-1.4,0.5-2.6,1-3.9c4.3-9.2,9.6-17.3,15.8-24.2C294.4,191.6,298.1,188.6,302.2,185.9L302.2,185.9
	z M172.8,201.8c5.5-1.1,7,0.7,4.7,5.1l-8.3,16.9c-1.2,2.6-4.3,4.3-8.8,5l-45.3,6.7c-4.3,0.6-5.8-0.7-4.7-4l3.9-8.9
	c2.2-4.5,7.2-8,14.9-10.3C141.6,208.4,156.1,204.9,172.8,201.8L172.8,201.8z M160.4,375.4c4.5,0.7,7.4,2.3,8.8,5l8.3,16.9
	c2.3,4.4,0.7,6.1-4.7,5.1c-16.6-3.2-31.2-6.6-43.7-10.3c-7.7-2.2-12.5-5.8-14.9-10.3l-3.9-8.9c-1.1-3.3,0.6-4.7,4.7-4L160.4,375.4
	L160.4,375.4z"
          />
          <path
            fill="#FFFFFF"
            d="M177.5,206.8c2.3-4.4,0.7-6.1-4.7-5.1c-16.6,3.2-31.2,6.6-43.7,10.3c-7.7,2.2-12.5,5.8-14.9,10.3l-3.9,8.9
	c-1.1,3.3,0.6,4.7,4.7,4l45.3-6.7c4.5-0.6,7.4-2.2,8.8-5L177.5,206.8L177.5,206.8z M148.2,209.6l-1.8-0.1l-9.9,19.3
	c0.6,0.3,1.4,0.4,2.2,0.6c-1-0.1-1.6-0.3-2.2-0.6c-1.6-0.7-2.1-1.7-1.1-3.2l6.1-11.4c1.6-2.8,3.3-4.3,5-4.5L148.2,209.6L148.2,209.6
	z"
          />
          <path
            fill="#FFFFFF"
            d="M146.4,209.5c-1.6,0.3-3.3,1.9-5,4.5l-6.1,11.4c-1,1.5-0.6,2.6,1.1,3.2L146.4,209.5L146.4,209.5z
	 M169.2,380.3c-1.2-2.6-4.3-4.3-8.8-5l-45.3-6.7c-4.3-0.5-5.8,0.8-4.7,4l3.9,8.9c2.2,4.5,7.2,8,14.9,10.3
	c12.5,3.7,27.1,7.3,43.7,10.3c5.5,1.1,7-0.7,4.7-5.1L169.2,380.3L169.2,380.3z M148.2,394.3l-1.8,0.1c-1.6-0.3-3.3-1.8-4.9-4.5
	l-6.1-11.4c-1-1.5-0.6-2.6,1.1-3.3c0.6-0.3,1.4-0.4,2.2-0.5c-1,0.1-1.6,0.3-2.2,0.5l9.9,19.3L148.2,394.3L148.2,394.3z"
          />
          <path
            fill="#FFFFFF"
            d="M141.4,389.9c1.6,2.8,3.3,4.3,5,4.5l-9.9-19.3c-1.6,0.7-2.1,1.8-1.1,3.3L141.4,389.9L141.4,389.9z
	 M146.2,165.4h0.3l11.4-6.9c-16.5,0.7-27.6,2.5-33.7,5.2l-2.5,1.4c-3.7,2.1-8,4-12.7,5.9c-4.5,1.2-8.9,3.7-13.5,7.2l-0.7,0.6
	c-8,6.3-15.3,14.7-22,25l-2.3,3.7c-0.3,2.5-1,5.4-1.9,8.5l-3.4,9.9l1.5-1.6c0.7-0.7,1.2-1.2,2.1-1.9c1.7-1.2,3.2-2.5,5-3.6
	c1.2-0.8,2.5-1.5,3.9-2.3c0.7-2.1,1.4-4.1,2.1-6.1l12.2-13.1c1.2-4.1,4.8-8.7,10.6-13.9C114.3,173,128.9,167,146.2,165.4
	L146.2,165.4z M276.6,233.8c-1.8-3.6-2.5-7.2-2.1-10.5c-79.2,6.6-141,17.5-185.2,32.7c59.4-9.1,122.6-14.6,189.1-16.4
	C278.4,237.5,277.7,235.5,276.6,233.8L276.6,233.8z M274.6,380.7c-0.4-3.3,0.3-6.9,2.1-10.5c1.1-1.6,1.8-3.7,2.1-5.9
	C212,362.6,149,357.1,89.6,348C133.6,363.1,195.3,374.1,274.6,380.7L274.6,380.7z M146.5,438.6h-0.3c-17.3-1.5-32.1-7.6-43.9-18.2
	c-5.8-5.2-9.4-9.8-10.6-13.9l-12.2-13.1c-0.7-1.8-1.4-3.9-2.1-5.9c-1.4-0.7-2.6-1.5-3.9-2.3l-4.8-3.7c-0.7-0.5-1.4-1.2-2.1-1.9
	l-1.5-1.6l1.5,4.1l1.9,5.8c1,3.2,1.7,6.1,1.9,8.5l2.3,3.7c6.7,10.3,14,18.7,22,25l0.7,0.6c4.5,3.4,8.9,5.9,13.5,7.2
	c4.7,1.9,8.9,4,12.7,5.9l2.5,1.4c6.1,2.8,17.2,4.5,33.7,5.2L146.5,438.6L146.5,438.6z M339.5,427.2c-1.6-0.1-2.8,0.1-3.2,1l-0.1,0.4
	v1.2l8,10.6c-0.7-2.8,0.7-4.1,4.5-4.3l9.5-0.7l-1.5-3.9v-0.5c0-0.4-0.1-0.8-0.3-1.2l-0.5-1c-0.7-0.7-1.6-1.1-3.2-1.2L339.5,427.2
	L339.5,427.2z"
          />
          <path
            fill="#FFFFFF"
            d="M336.3,428.6l0.1-0.4l-14.6-0.7c-8.4,5.6-13.9,11.3-16.6,17.1l42-0.7l-2.8-3.4l-8-10.6
	C336.2,429.4,336.2,429,336.3,428.6L336.3,428.6z M296.3,426.3l2.1-2.2c-112.8-6.5-186.9-20.8-222.6-43.2c0.6,2.3,1.1,4.5,1.8,6.6
	C112.1,406.9,185,419.8,296.3,426.3L296.3,426.3z"
          />
          <path
            fill="#FFFFFF"
            d="M348.9,436.2c-3.7,0.1-5.2,1.6-4.5,4.3l2.8,3.4l10.6,13.8c2.6,3.4,6.7,5.2,12.5,5.4l5.8-0.3l-13.2-27.6
	l-4.4,0.4L348.9,436.2L348.9,436.2z"
          />
          <path
            fill="#FFFFFF"
            d="M356.7,431.1v0.5l1.5,3.9l4.4-0.4h0.7c1.4,0.1,2.5,0.7,3.3,1.8l3.2,6.7l10-0.1H476c-1-0.3-1.6-0.7-2.5-1.1
	c-2.1-0.8-1.4-1.5,2.1-2.1l7-0.5l15.5-0.3c7.4-0.8,11.8-0.4,13.3,1.5c-1.5,1-3.2,1.8-5.1,2.6h6.3c1.9-0.1,4-0.1,6.1-0.1
	c2.9-2.3,2.8-6.9-0.3-13.3l-1-1.9c-3.7,0-6.7,0-9.1,0.1c-63,2.9-113.6,3.4-152,1.6C356.6,430.3,356.7,430.7,356.7,431.1L356.7,431.1
	z"
          />
          <path
            fill="#FFFFFF"
            d="M369.6,443.6l-3.2-6.7c-0.8-1.1-1.9-1.8-3.3-1.8h-0.7l13.2,27.6c1.5-0.4,2.1-1.1,1.8-2.1L369.6,443.6
	L369.6,443.6z M70.6,207.5l2.3-3.7l-16.2,18.7c-1.9,2.6-2.2,5.8-0.8,9.5c0.7,3.9,0.3,10.5-1.4,19.9l-1,5.2
	c-2.8,15.7-4.4,29.6-4.7,42v5.8c0.3,12.4,1.8,26.3,4.7,42l1,5.2c1.7,9.5,2.1,16.1,1.4,19.9c-1.4,3.7-1.1,6.9,0.8,9.5l16.2,18.7
	l-2.3-3.7c-2.8-3.9-4.8-8.1-6.1-12.7l-3.6-14.7c-1.4-6.1-2.8-12-3.9-17.6c-3.4-17.6-5.4-33.2-5.8-46.6v-5.8
	c0.3-13.6,2.2-29.2,5.8-46.6c1.1-5.6,2.5-11.6,3.9-17.6l3.6-14.6C65.8,215.8,67.7,211.5,70.6,207.5L70.6,207.5z"
          />
          <path
            fill="#FFFFFF"
            d="M64.5,220.3l-3.6,14.6c-1.4,6.1-2.8,12-3.8,17.6c-3.4,17.5-5.4,33.2-5.8,46.6v5.8
	c0.3,13.6,2.2,29.2,5.8,46.6c1.1,5.6,2.5,11.6,3.8,17.6l3.6,14.6c1.2,4.5,3.2,8.8,6.1,12.7c-0.3-2.5-1-5.4-1.9-8.5l-1.9-5.8
	l-1.5-4.1l-2.2-6.3l-0.4-1.4c-0.7-2.3-1.2-4.7-1.7-7c-2.6-14.3-4.3-34.7-5.1-61.2c0.8-26.5,2.5-46.9,5.1-61.2c0.4-2.3,1-4.7,1.7-7
	l0.4-1.4l2.2-6.3l3.4-9.9c1-3.2,1.7-6.1,1.9-8.5C67.7,211.5,65.8,215.8,64.5,220.3L64.5,220.3z"
          />
          <path
            fill="#FFFFFF"
            d="M65.2,226l-2.2,6.3c3.3-2.6,6.6-5.1,9.8-7.3l3-1.9c0.6-2.3,1.1-4.5,1.8-6.6c-1.4,0.7-2.6,1.5-3.9,2.3
	c-1.7,1.1-3.3,2.3-5,3.6c-0.7,0.6-1.4,1.2-2.1,1.9L65.2,226L65.2,226z"
          />
          <path
            fill="#FFFFFF"
            d="M63,232.3l-0.4,1.4c-0.7,2.3-1.2,4.7-1.7,7c-2.6,14.3-4.3,34.7-5.1,61.2c0.8,26.5,2.5,46.9,5.1,61.2
	c0.4,2.3,1,4.7,1.7,7l0.4,1.4c3.3,2.6,6.6,5.1,9.8,7.3l3,1.9c-3.9-15.7-6.3-37.4-7.4-65.2c-0.4-4.4-0.6-9.1-0.6-13.8
	c0-4.7,0.1-9.2,0.6-13.6c1.1-27.6,3.6-49.4,7.4-65.2l-3,1.9C69.5,227.2,66.3,229.7,63,232.3L63,232.3z"
          />
          <path
            fill="#FFFFFF"
            d="M77.6,387.5c-0.7-2.1-1.2-4.3-1.8-6.6l-3-1.9c-3.2-2.2-6.5-4.5-9.8-7.3l2.2,6.3l1.5,1.6
	c0.7,0.7,1.2,1.2,2.1,1.9l5,3.6C75,386,76.2,386.6,77.6,387.5L77.6,387.5z M746.8,184c4.4,1,8.5,2.6,12.5,4.7l2.6,1.5l-1-1.9
	c-2.8-5.4-7.2-8.1-13.1-8.5C745.3,180.2,744.9,181.5,746.8,184L746.8,184z"
          />
          <path
            fill="#FFFFFF"
            d="M752.5,193.8c-1.5-3.4-3-6.2-4.5-8.3l-1.1-1.4c-1.8-2.5-1.5-3.9,1.1-4.3c5.9,0.3,10.2,3.2,13.1,8.5l1,1.9
	c1.2,2.3,2.3,4.8,3.6,7.6c1.4,3.2,2.8,6.7,4,10.5l2.2,6.7c0.8,2.8,1.8,5.8,2.6,8.8l1.9,7.8l1.5,6.2c1.1,5.1,2.2,10.5,3.2,16.1
	c2.3,13.1,3.6,29.2,3.9,48.1c-0.1,19-1.5,35.1-3.9,48.3c-1.1,5.6-2.1,11-3.2,16.1l-1.5,6.2l-1.9,7.8l-2.6,8.8l-2.2,6.7
	c-1.2,3.7-2.6,7.2-4,10.5c-1.1,2.6-2.3,5.2-3.6,7.6l-1,1.9c-2.8,5.4-7.2,8.1-13.1,8.5c-2.6-0.4-3-1.8-1.1-4.3l1.1-1.4
	c1.5-2.1,3-4.8,4.5-8.4l1.9-4.7h-6.3l-0.8,1.8c-2.2,4.4-7.6,7.4-16.2,8.9c-40.3,9.2-93.7,13.6-160,13.2l-14.7-0.4l-12.2-0.8
	c-6.1-0.3-12.5-0.4-19.7-0.4l-5.9,0.1h-1l1,1.9c3,6.5,3,10.9,0.3,13.3c9.5,0,20.1,1.1,31.6,3.3c24.6,4.1,55,5.5,91.5,4
	c25.7,1,47.5-2.1,65.5-9.4c10.5-3.6,22.4-6.6,35.9-8.9c14.2-2.6,24.2-11.6,29.9-27c10.3-21.7,16.9-52.8,19.7-93.3
	c0.3-3.2,0.4-6.5,0.5-10c0-3.6-0.3-7-0.5-10.2c-2.9-40.4-9.4-71.5-19.7-93.3c-5.6-15.4-15.7-24.3-29.9-27
	c-13.5-2.5-25.3-5.5-35.9-8.9c-18-7.3-39.8-10.5-65.5-9.5c-36.5-1.6-66.9-0.3-91.5,3.9c-11.6,2.2-22.1,3.3-31.6,3.3
	c2.9,2.3,2.8,6.9-0.3,13.3l-1,1.9h1l5.9,0.1c7.2,0,13.8-0.1,19.7-0.4l12.2-0.8c5-0.4,9.8-0.6,14.7-0.4c66.4-0.3,119.8,4.1,160,13.2
	c8.7,1.6,14,4.5,16.2,8.9l0.8,1.7h6.3L752.5,193.8L752.5,193.8z"
          />
          <path
            fill="#FFFFFF"
            d="M754.4,198.5h-6.3c11.7,23.9,18,52,19.1,84.2l0.3,10.7l-0.1,22.4c-0.5,34.7-6.9,64.7-19.3,90.1h6.3l0.7-1.6
	c1.1-3.2,2.3-6.7,3.6-10.6l1.8-5.9c1.1-3.7,2.2-7.8,3.3-12.1l1.7-6.5l1.7-7.2c2.2-9.9,3.7-22.1,4.5-36.6c0.3-4.5,0.4-9.2,0.5-14.2
	v-16.6l-0.4-12.1c-0.7-16.1-2.3-29.4-4.7-40.2l-1.7-7.2l-1.7-6.5c-1.1-4.3-2.2-8.4-3.3-12.1l-1.8-5.9c-1.2-3.9-2.5-7.4-3.6-10.6
	L754.4,198.5L754.4,198.5z"
          />
          <path
            fill="#FFFFFF"
            d="M748.1,198.3l-22.6-0.1c-13.8-0.6-24.5,0.6-32.3,3.3c-8.1,4.3-13.9,10.3-17.5,18.3c5.9,2.5,11.3,8,16.1,16.2
	c5.8,12.7,9.4,27.9,10.9,45.9l0.3,4c0.3,3.9,0.4,7.7,0.4,11.7c0.1,6.1,0,12-0.3,18l-0.1,3.2c0,1.1-0.1,2.1-0.3,3.2
	c-1.5,18-5.1,33.4-10.9,45.9c-4.8,8.4-10.2,13.8-16.1,16.2c3.6,8,9.4,14,17.5,18.3c7.8,2.8,18.6,3.9,32.3,3.3l22.6-0.1
	c12.4-25.5,18.8-55.4,19.3-90.1l0.1-22.4l-0.3-10.6C766.2,250.3,759.8,222.3,748.1,198.3L748.1,198.3z M776.3,231.5
	c-0.7-0.4-1.4-0.7-2.2-1.1c-2.9-1.1-6.3-1.8-10.3-1.7l1.7,6.5c5.4,0.1,9.5,1,12.4,2.5L776.3,231.5L776.3,231.5z"
          />
          <path
            fill="#FFFFFF"
            d="M777.6,237.7c-2.9-1.5-7-2.3-12.4-2.5l1.7,7.2c2.3,10.7,4,24.1,4.7,40.2l0.4,11.7l0.1-0.3
	c0.8-1.9,1.9-2.9,3.2-2.9h0.3c1.1,0.1,2.1,1.1,2.9,2.8c0.8,1.9,1.4,4.1,1.4,6.7c0,2.6-0.4,5-1.4,6.9c-0.8,1.6-1.8,2.6-2.9,2.8h-0.3
	c-1.2,0-2.3-1-3.2-2.8v-0.1v3.4c-0.1,5-0.3,9.6-0.5,14.2c-0.8,14.4-2.3,26.7-4.5,36.6l-1.7,7.2c5.4-0.1,9.5-1,12.4-2.5
	c1.1-5.1,2.2-10.5,3.2-16.1c2.3-13.2,3.6-29.3,3.8-48.3c-0.1-19-1.5-35.1-3.8-48.1C779.8,248.1,778.9,242.8,777.6,237.7L777.6,237.7
	z"
          />
          <path
            fill="#FFFFFF"
            d="M775.6,291h-0.3c-1.2,0-2.3,1-3.2,2.9l-0.1,0.3v1.2l1-1.9c0.7-1.1,1.5-1.5,2.3-1.6c1.1,0,2.1,0.8,2.9,2.6
	c0.8,1.6,1.2,3.7,1.2,6.2c0,2.5-0.4,4.5-1.2,6.2c-0.8,1.6-1.8,2.6-2.9,2.6c-0.8,0-1.7-0.6-2.3-1.5c-0.1-0.3-0.4-0.5-0.5-1l-0.4-0.8
	v1.4c0.8,1.9,1.9,2.8,3.2,2.8h0.3c1.1-0.1,2.1-1.1,2.9-2.8c0.8-1.9,1.4-4.1,1.4-6.9c0-2.6-0.4-5-1.4-6.7
	C777.6,292.1,776.8,291.3,775.6,291L775.6,291z"
          />
          <path
            fill="#FFFFFF"
            d="M773,293.6l-1,1.9v10.6l0.4,0.8c0.1,0.4,0.4,0.7,0.5,1c0.7,1.1,1.5,1.7,2.3,1.5c1.1,0,2.1-0.8,2.9-2.6
	c0.8-1.8,1.2-3.9,1.2-6.2c0-2.5-0.4-4.5-1.2-6.2c-0.8-1.6-1.8-2.6-2.9-2.6C774.5,292,773.7,292.6,773,293.6L773,293.6z M774.5,294.2
	v2.9h1.4v1.1h-1.4v8.3h0.8v-4.3h-0.1v2.2H775v-2.6h0.4l0.1,0.1v4.8l-0.1,0.1h-1c0,0-0.1-0.1-0.1-0.3v-8.8h1.5v-0.3h-1.5v-3.3h-0.1
	v12.9c0,0.1,0.1,0.1,0.1,0.1h1l0.1-0.1l0.1-0.4v-4.5l-0.1-0.4l-0.1-0.1h-0.5c-0.1,0-0.1,0.1-0.1,0.4v2.6h-0.1v-2.9
	c0-0.3,0-0.4,0.1-0.4h0.5l0.3,0.3l0.1,0.5v5.1c0,0.3-0.1,0.5-0.1,0.7h-1.4c-0.1,0-0.3-0.3-0.3-0.5v-13.1h0.3L774.5,294.2
	L774.5,294.2z"
          />
          <path fill="#FFFFFF" d="M774.5,297.1v-2.9h-0.1v3.3h1.5v-0.4" />
          <path
            fill="#FFFFFF"
            d="M774.2,294.2h-0.1v13.1c0,0.3,0.1,0.5,0.3,0.5h1.4c0.1-0.1,0.1-0.3,0.1-0.7V302l-0.1-0.5l-0.3-0.3h-0.5
	c-0.1,0-0.1,0.1-0.1,0.4v2.9h0.1v-2.6c0-0.1,0-0.3,0.1-0.4h0.5l0.1,0.1l0.1,0.4v4.5l-0.1,0.4l-0.1,0.1h-1.1c0,0-0.1,0-0.1-0.1v-0.4
	L774.2,294.2L774.2,294.2z"
          />
          <path
            fill="#FFFFFF"
            d="M775.9,298.3v-0.4h-1.5v8.8c0,0.1,0,0.3,0.1,0.3h1l0.1-0.1V302l-0.1-0.1H775v2.6h0.1v-2.2h0.1v4.3h-0.8v-8.3
	L775.9,298.3L775.9,298.3z M763.8,375.4c4,0,7.4-0.5,10.3-1.6l2.2-1.1l1.5-6.2c-2.9,1.5-7,2.3-12.4,2.5L763.8,375.4L763.8,375.4z
	 M568.5,283.5c0.1,5,0.3,10,0.1,15.3v6.7c0,5.2,0,10.3-0.1,15.3c-0.3,8.8-0.7,17.1-1.5,25.2c4.7-3.3,7.3-10.5,7.7-21.5v-44.7
	c-0.4-11.1-3-18.3-7.7-21.5C567.9,266.3,568.3,274.7,568.5,283.5L568.5,283.5z M498.1,164.6c7.4,0.8,11.8,0.4,13.3-1.5
	c-1.5-1-3.2-1.8-5.1-2.6c-3.4-1.2-7.4-1.9-12.2-2.2c-7.7-0.1-13.8,0.6-18.2,2.2c-1,0.3-1.6,0.7-2.5,1.1c-2.1,0.8-1.4,1.5,2.1,2.1
	l7,0.6c0.4-2.1,1.5-3.3,3.3-3.7l2.1-0.1h5.4l1,0.1C496.9,161,498.1,162.4,498.1,164.6L498.1,164.6z M517.5,175.9h1 M514.3,182.2
	c4.7,5,8.9,10.9,12.8,17.9l17.3-0.8c-3.7-6.5-7.4-11.8-11-16L514.3,182.2L514.3,182.2z"
          />
          <path
            fill="#FFFFFF"
            d="M485.9,160.5c-1.8,0.4-2.9,1.6-3.3,3.7l15.5,0.3c0-2.2-1.2-3.6-3.9-4L485.9,160.5L485.9,160.5z"
          />
          <path
            fill="#FFFFFF"
            d="M488.1,160.4l-2.2,0.1h8.5l-1-0.1 M485.9,443.5h8.4c2.5-0.5,3.9-1.8,3.9-4l-15.5,0.3
	C483,441.8,484.1,443,485.9,443.5L485.9,443.5z M494.4,443.5h-8.5l7.6,0.1"
          />
          <path
            fill="#FFFFFF"
            d="M485.9,443.5c-1.8-0.4-2.9-1.7-3.3-3.7l-7,0.5c-3.3,0.5-4,1.2-2.1,2.1c0.7,0.4,1.5,0.8,2.5,1.1
	c4.4,1.6,10.5,2.3,18.2,2.2c4.8-0.3,8.9-1.1,12.2-2.2c1.9-0.7,3.6-1.5,5.1-2.6c-1.5-1.8-5.9-2.3-13.3-1.5c0,2.2-1.2,3.6-3.7,4
	l-1,0.1L485.9,443.5L485.9,443.5L485.9,443.5z M533.3,420.6c3.7-4.1,7.4-9.5,11-16l-17.3-0.8c-3.9,7-8.1,12.9-12.8,17.9L533.3,420.6
	L533.3,420.6z M517.5,428.2h1l-1-0.1 M305.1,159.4l-19.5-0.3c-6.6,0.1-13.2-0.3-19.7-1.2l-17.9-1.1c-27.8-0.6-56.1,0-85.3,1.6
	l-4.7,0.1l-11.4,6.9h-0.3c-11.3,2.8-20.4,5.9-27.4,9.5c-6.5,3-12.1,6.9-16.6,11.4c24.9-8.9,82.5-14.2,172.8-15.5
	c9.1,0,16.1,2.2,21.2,6.9l25.6-1.4C313.5,170.7,307.9,165,305.1,159.4L305.1,159.4z"
          />
          <path
            fill="#FFFFFF"
            d="M296.3,177.8c-5.1-4.7-12.2-7-21.2-6.9c-90.2,1.4-147.9,6.6-172.8,15.5c-0.6,0.6-1.1,1.1-1.7,1.8l-8.8,9.5
	l-12.2,13.1c-0.7,1.9-1.4,3.9-2.1,6.1C112.1,197.2,185,184.3,296.3,177.8L296.3,177.8z"
          />
          <path
            fill="#FFFFFF"
            d="M100.6,188.1c0.6-0.6,1.1-1.2,1.7-1.8c4.5-4.7,10.2-8.4,16.6-11.4c7-3.4,16.1-6.6,27.4-9.5
	c-17.3,1.5-31.9,7.6-43.9,18.2c-5.8,5.2-9.4,9.8-10.6,13.9L100.6,188.1L100.6,188.1z M158.1,445.5l4.7,0.1c29,1.7,57.5,2.2,85.3,1.7
	l17.9-1.1c6.6-1,13.2-1.4,19.7-1.2l19.5-0.3c2.8-5.6,8.4-11.3,16.6-17.1l-25.6-1.4c-5.1,4.7-12.2,7-21.2,6.9
	c-90.2-1.4-147.7-6.5-172.5-15.1c4.5,4.4,10,8.1,16.4,11.1c7,3.6,16.1,6.7,27.4,9.5h0.3L158.1,445.5L158.1,445.5z"
          />
          <path
            fill="#FFFFFF"
            d="M275,433.3c9.1,0,16.1-2.2,21.2-6.9C185,419.9,112.1,407,77.4,387.6c0.7,2.1,1.4,4.1,2.1,5.9l12.2,13.1
	l8.8,9.4l2.1,2.1C127.2,426.8,184.8,431.8,275,433.3L275,433.3z"
          />
          <path
            fill="#FFFFFF"
            d="M102.6,418c-0.7-0.7-1.4-1.4-2.1-2.1l-8.8-9.4c1.2,4,4.8,8.7,10.6,13.9c11.8,10.7,26.5,16.8,43.9,18.2
	c-11.3-2.8-20.4-5.9-27.4-9.5C112.5,426.3,107.2,422.5,102.6,418L102.6,418z M332.9,425.4l1.1,0.1c40.2,3.4,98.4,3.3,174.6-0.1
	l7.4-0.3l-1.7-3.3l-5.8,0.3c-70.4,3.3-125.5,3.6-165.1,1h-0.7L332.9,425.4L332.9,425.4z"
          />
          <path
            fill="#FFFFFF"
            d="M332.8,425.4h0.1l9.8-2.6l0.7-0.1c27.8-7,54.5-12.5,79.9-16.5c3-0.5,6.1-0.8,9.1-1.1
	c7.2-0.8,14.3-1.4,21.3-1.5c22.3-1,46.6-0.8,73.3,0.4l17.3,0.8l0.1-0.1l-0.1,0.1l50.3,2.3c3.7,0.5,4.3,1.2,1.8,2.3
	c-5.5,3.3-12.9,5.5-22.3,6.9c-13.5,1.9-27.1,3.3-41,4.4l-19.1,1.2l1.7,3.3c18.2-0.8,37.6-2.8,58.2-5.5c15.3-1.8,25.7-5.5,31.1-11.1
	c1.9-1.6,1.1-2.6-2.6-2.8l-61.6-2.9c-32.1-1.8-61.2-2.1-87.2-1.1l-9.5,0.4c-7,0.3-14,1-21,2.3c-27.1,4.1-55.7,10-85.6,17.9l-9.1,2.5
	c-0.1,0-1.2,0.3-3.6,0.8l12.2,0.5l-12.2-0.5l-3.3,2.2l14.6,0.7c0.4-0.8,1.4-1.1,3.2-1l13.2,0.5c1.5,0,2.5,0.4,3.2,1.2l0.5,1
	c38.4,1.8,89.1,1.2,152-1.6c2.3-0.1,5.4-0.1,9.1-0.1l-1.7-3l-7.4,0.3c-76.1,3.6-134.3,3.6-174.6,0.1L332.8,425.4L332.8,425.4z"
          />
          <path
            fill="#FFFFFF"
            d="M332.9,425.4h-0.1l1.2,0.1 M321.8,427.6l3.3-2.2l-26.7-1.4l-2.1,2.2"
          />
          <path
            fill="#FFFFFF"
            d="M303.3,418.8c-0.4-0.3-0.8-0.4-1.1-0.7c-1.1,2.3-2.3,4.3-3.9,6.1l26.8,1.4c-5-0.5-10-1.8-15.3-3.7
	c-1.6-0.5-3.2-1.2-4.8-2.1L303.3,418.8L303.3,418.8z M302.2,185.9c0.4-0.3,0.7-0.6,1.1-0.7l1.6-1c1.5-0.8,3.2-1.5,4.8-2.1
	c5-1.8,10-3,15.5-3.6h-0.3l-26.8,1.4C299.9,181.7,301.1,183.7,302.2,185.9z"
          />
          <path
            fill="#FFFFFF"
            d="M296.3,177.8c0.7,0.7,1.4,1.4,2.1,2.2l26.8-1.4l-3.3-2.2L296.3,177.8z M328.6,179.3l-3.2-0.7
	c-5.5,0.6-10.6,1.8-15.5,3.6l80.6,18.8c6.1,1.9,8.7,6.9,8.1,14.9c-2.9,20.5-4.8,42.1-5.6,64.8l-0.3,20.9l0.3,21.2
	c0.8,22.8,2.8,44.6,5.6,65.2c0.7,7.8-2.1,12.8-8.1,14.9l-80.6,18.8c5.2,1.9,10.2,3.2,15.3,3.7c2.2-0.5,3.4-0.7,3.6-0.8l9.1-2.5
	c29.9-7.7,58.3-13.8,85.6-17.9c7-1.2,14-2.1,21-2.3l9.5-0.4c26.1-1.1,55.2-0.7,87.2,1.1l61.6,2.9c3.6,0.1,4.4,1.1,2.6,2.8
	c-5.4,5.8-15.8,9.5-31.1,11.1c-20.6,2.8-40,4.7-58.2,5.5l1.7,3h1l5.9-0.1c7.2,0,13.8,0.1,19.7,0.4l12.2,0.8l14.7,0.4
	c66.4,0.3,119.8-4.1,160-13.2l-2.6,0.4l2.6-0.4c8.7-1.6,14-4.5,16.2-8.9l0.8-1.8l-22.6,0.1c-13.8,0.5-24.5-0.6-32.3-3.3
	c-8.1-4.3-13.9-10.3-17.5-18.3c-1.8,0.8-3.7,1.2-5.6,1.5c-36.3,2.5-71.7,4.1-106.3,4.8h-4.5c-65.3,0.5-119.1,2.6-161.4,6.2
	c42.4-3.7,96.2-5.8,161.4-6.2c3.6-13.3,6.2-28.3,7.7-44.8c0.7-8,1.2-16.4,1.5-25.2c0.1-5,0.3-10,0.1-15.3v-6.7
	c0-5.2,0-10.3-0.1-15.3c-0.3-8.8-0.7-17.1-1.5-25c-1.5-16.6-4.1-31.5-7.7-44.8c-65.3-0.6-119.1-2.6-161.4-6.3
	c42.4,3.7,96.2,5.8,161.4,6.3h4.5c34.5,0.8,70,2.5,106.3,4.8c1.9,0.1,3.9,0.7,5.6,1.5c3.6-8,9.4-14,17.5-18.3
	c7.8-2.8,18.6-3.9,32.3-3.3l22.6,0.1l-0.8-1.6c-2.2-4.4-7.6-7.4-16.2-8.9l-2.6-0.4l2.6,0.4c-40.3-9.2-93.7-13.6-160-13.2
	c-5-0.1-9.8,0-14.7,0.4l-12.2,0.8c-6.1,0.3-12.5,0.4-19.7,0.4l-5.9-0.1h-1l-1.7,3c18.2,0.8,37.6,2.8,58.2,5.5
	c15.3,1.6,25.7,5.5,31.1,11.3c1.9,1.6,1.1,2.6-2.6,2.8l-61.8,3c-32.1,1.6-61.2,2.1-87.2,1.1l-9.5-0.4c-7-0.3-14-1-21-2.3
	c-27.1-4.1-55.7-10-85.6-17.9L328.6,179.3L328.6,179.3z"
          />
          <path
            fill="#FFFFFF"
            d="M356.4,174.1l-0.5,1c-0.7,0.7-1.6,1.1-3.2,1.2l-13.2,0.6c-1.6,0.1-2.8-0.1-3.2-1l-14.6,0.7l3.3,2.2h1.1
	l6.7,0.1h-0.1l1.2-0.1c40.2-3.4,98.4-3.3,174.6,0.1l7.4,0.3l1.7-3l-9.1-0.1C445.6,172.9,394.8,172.3,356.4,174.1L356.4,174.1z"
          />
          <path
            fill="#FFFFFF"
            d="M343.4,181.4l-0.7-0.1l-9.8-2.6l-6.7-0.1c-0.3,0-0.5,0.1-0.8,0.1l3.2,0.7l9.1,2.5
	c29.9,7.7,58.3,13.8,85.6,17.9c7,1.2,14,2.1,21,2.3l9.5,0.4c26.1,1.1,55.2,0.7,87.2-1.1l61.6-2.9c3.6-0.1,4.4-1.1,2.6-2.8
	c-5.4-5.8-15.8-9.5-31.1-11.3c-20.6-2.8-40-4.7-58.2-5.5l-1.7,3.3l19.1,1.1c13.9,1.1,27.5,2.5,41,4.4c9.4,1.4,16.8,3.6,22.3,6.9
	c2.5,1.1,1.9,1.8-1.8,2.3l-50.3,2.3l0.1,0.1l-0.1-0.1l-17.3,0.8c-26.5,1.1-51,1.2-73.3,0.3c-7.1-0.1-14.3-0.7-21.3-1.5l-9.1-1.1
	C397.8,193.9,371.1,188.4,343.4,181.4L343.4,181.4z"
          />
          <path
            fill="#FFFFFF"
            d="M342.7,181.1h0.7c39.6-2.6,94.8-2.3,165.1,1l5.8,0.3l1.7-3.3l-7.4-0.3c-76.1-3.6-134.3-3.6-174.6-0.1
	l-1.1,0.1L342.7,181.1L342.7,181.1z M325.5,178.6c0.3,0,0.5,0,0.8-0.1h-1.1L325.5,178.6L325.5,178.6z M332.9,178.6l1.1-0.1l-1.2,0.1
	"
          />
          <path
            fill="#EBEBEB"
            d="M342.7,181.1l0.7,0.1c27.8,7,54.5,12.5,79.9,16.5l9.1,1.1c7.2,0.8,14.3,1.4,21.3,1.5
	c22.3,1,46.6,0.8,73.3-0.3c-3.9-7-8.1-12.9-12.8-17.9l-5.8-0.3c-70.4-3.3-125.5-3.6-165.1-1L342.7,181.1L342.7,181.1z M342.7,422.8
	h0.7c39.6,2.6,94.8,2.3,165.1-1l5.8-0.3c4.7-5,8.9-10.9,12.8-17.9c-26.5-1.1-51-1.2-73.3-0.4c-7.2,0.1-14.3,0.7-21.3,1.5
	c-3,0.3-6.1,0.7-9.1,1.1c-25.5,3.9-52,9.4-79.9,16.5L342.7,422.8L342.7,422.8z M759.2,188.7c-3.9-2.1-8.1-3.6-12.5-4.7l1.1,1.4
	c1.5,2.1,3,4.8,4.5,8.3c3.6,0,6.9,0.7,9.9,2.2L759.2,188.7L759.2,188.7z M755.1,200.1c1.1,3.2,2.3,6.6,3.6,10.6
	c3.6,0.4,7,1.4,10.3,2.9l-2.6-7.7C762.9,203.4,759.1,201.5,755.1,200.1L755.1,200.1z"
          />
          <path
            fill="#EBEBEB"
            d="M754.4,198.5l0.7,1.8c4,1.4,7.8,3.2,11.3,5.6c1.1,0.7,2.1,1.5,3,2.3c-1.2-3.7-2.6-7.2-4-10.5
	c-1-0.7-2.1-1.2-3-1.8c-3-1.5-6.3-2.2-9.9-2.2L754.4,198.5L754.4,198.5z"
          />
          <path
            fill="#EBEBEB"
            d="M766.4,205.7l2.6,7.7c1,0.4,1.8,0.8,2.6,1.4l-2.2-6.7C768.4,207.3,767.5,206.6,766.4,205.7L766.4,205.7z
	 M762.4,196c1.1,0.6,2.1,1.1,3,1.8c-1.1-2.6-2.3-5.2-3.6-7.6l-2.6-1.5L762.4,196L762.4,196z"
          />
          <path
            fill="#EBEBEB"
            d="M771.7,214.8c-0.8-0.6-1.8-1-2.6-1.4c-3.3-1.5-6.7-2.5-10.3-2.9l1.8,5.9c4.4,1.4,8.4,3.2,11.7,5.4l2.2,1.6
	C773.4,220.6,772.6,217.7,771.7,214.8z"
          />
          <path
            fill="#EBEBEB"
            d="M774.2,223.6L772,222l2.1,8.4c0.7,0.3,1.5,0.7,2.2,1.1L774.2,223.6L774.2,223.6z"
          />
          <path
            fill="#EBEBEB"
            d="M774.1,230.4L772,222c-3.3-2.2-7.2-4-11.7-5.4c1.1,3.7,2.2,7.8,3.3,12.1
	C767.7,228.7,771.2,229.3,774.1,230.4L774.1,230.4z M774.1,373.6c-2.9,1.1-6.3,1.6-10.3,1.6c-1.1,4.3-2.2,8.4-3.3,12.1
	c4.4-1.4,8.4-3.2,11.6-5.4L774.1,373.6L774.1,373.6z"
          />
          <path fill="#EBEBEB" d="M776.3,372.6l-2.2,1L772,382l2.2-1.6" />
          <path
            fill="#EBEBEB"
            d="M771.7,389.1l2.6-8.8l-2.2,1.6c-3.3,2.2-7.2,4-11.7,5.4l-1.8,5.9c3.6-0.4,7-1.4,10.3-2.8L771.7,389.1
	L771.7,389.1z M765.4,406.3c1.4-3.3,2.8-6.7,4-10.5c-1,0.8-2.1,1.6-3,2.3c-3.6,2.5-7.3,4.3-11.3,5.6l-0.7,1.6l-1.9,4.7
	c3.6,0,6.9-0.7,9.9-2.2C763.5,407.6,764.4,407,765.4,406.3L765.4,406.3z"
          />
          <path
            fill="#EBEBEB"
            d="M765.4,406.3c-1,0.7-2.1,1.2-3,1.8l-3.2,7.3l2.6-1.5C763.2,411.5,764.3,408.9,765.4,406.3L765.4,406.3z
	 M769,390.5l-2.6,7.7c1.1-0.7,2.1-1.5,3-2.3l2.2-6.7L769,390.5L769,390.5z"
          />
          <path
            fill="#EBEBEB"
            d="M766.4,398.2l2.6-7.7c-3.3,1.5-6.7,2.5-10.3,2.8c-1.2,3.9-2.5,7.4-3.6,10.6
	C759.1,402.6,762.9,400.7,766.4,398.2L766.4,398.2z M747.9,424.2c5.9-0.4,10.2-3.2,13.1-8.5l1-1.9l-2.6,1.5
	c-3.8,2.1-8.1,3.6-12.5,4.7C744.9,422.4,745.3,423.8,747.9,424.2L747.9,424.2z"
          />
          <path
            fill="#EBEBEB"
            d="M746.8,419.9c4.4-1,8.5-2.6,12.5-4.7l3.2-7.3c-3,1.5-6.3,2.2-9.9,2.2c-1.5,3.4-3,6.2-4.5,8.4L746.8,419.9
	L746.8,419.9z M594.8,197.1c3.7-0.6,4.3-1.2,1.8-2.3c-5.5-3.2-12.9-5.5-22.3-6.9c-13.5-1.9-27.1-3.4-41-4.4c3.7,4.1,7.4,9.5,11,16
	L594.8,197.1L594.8,197.1z M567.2,258.3c4.7,3.2,7.3,10.5,7.7,21.5v44.4c-0.4,11.1-3,18.3-7.7,21.5c-1.5,16.6-4.1,31.5-7.7,44.8h4.5
	c34.5-0.8,70-2.5,106.3-4.8c1.9-0.1,3.9-0.7,5.6-1.5c5.9-2.5,11.3-7.8,16.1-16.2c5.8-12.7,9.4-27.9,10.9-45.9
	c0.1-1.1,0.1-2.1,0.3-3.2l0.1-3.2c0.3-5.9,0.4-12,0.3-18c0-3.9-0.3-7.7-0.4-11.7l-0.3-4c-1.5-18-5.1-33.4-10.9-45.9
	c-4.8-8.4-10.2-13.8-16.1-16.2c-1.8-0.8-3.7-1.2-5.6-1.5c-36.3-2.5-71.7-4.1-106.3-4.8h-4.5C562.9,226.8,565.5,241.7,567.2,258.3
	L567.2,258.3z M575.6,235.9c-1.1-6.3,2.5-10.2,10.7-11.7c14.4-0.7,32.2-0.1,53.4,1.7l24.5,2.1c10.2,1.6,17.1,6.9,20.6,15.4
	c4.8,9.1,7.8,21.7,9.1,38l0.3,3.3l0.5,18.3c0,4.3-0.1,8.5-0.3,13.2L694,323c-1.4,16.2-4.4,28.9-9.1,38c-3.6,8.7-10.5,13.9-20.6,15.5
	l-24.5,2.1c-21.2,1.7-38.9,2.2-53.4,1.5c-8.3-1.5-11.8-5.4-10.7-11.7l1.7-16.2c0.3-3,1.1-5.5,2.3-7.4l0.1-0.1
	c2.6-4.1,4.1-10.7,4.5-19.9v-44.8c-0.3-9.2-1.8-15.8-4.5-19.9l-0.1-0.1c-1.2-1.9-2.1-4.4-2.3-7.4L575.6,235.9L575.6,235.9z"
          />
          <path
            fill="#EBEBEB"
            d="M586.3,224.2c-8.3,1.5-11.8,5.4-10.7,11.7l1.7,16.1c0.3,3,1.1,5.5,2.3,7.4l0.1,0.1
	c2.6,4.1,4.1,10.7,4.5,19.9v44.8c-0.3,9.2-1.8,15.8-4.5,19.9l-0.1,0.1c-1.2,1.9-2.1,4.4-2.3,7.4l-1.7,16.2
	c-1.1,6.3,2.5,10.2,10.7,11.7c14.4,0.7,32.2,0.1,53.4-1.5l24.5-2.1c10.2-1.6,17.1-6.9,20.6-15.5c4.8-9.1,7.8-21.7,9.1-38l0.4-6.9
	c0.1-4.5,0.3-8.9,0.3-13.2l-0.5-18.3l-0.3-3.3c-1.4-16.2-4.4-28.9-9.1-38c-3.6-8.7-10.5-13.9-20.6-15.4l-24.5-2.1
	C618.5,224,600.7,223.5,586.3,224.2L586.3,224.2z M544.5,404.7c-3.7,6.5-7.4,11.8-11,16c13.9-1.1,27.5-2.5,41-4.4
	c9.4-1.4,16.8-3.6,22.3-6.9c2.5-1.1,1.9-1.8-1.8-2.3L544.5,404.7L544.5,404.7z M303.3,185.2c-0.4,0.3-0.8,0.4-1.1,0.7
	c-4.1,2.5-7.8,5.6-11,9.4c-6.2,6.9-11.4,15-15.8,24.2c-0.5,1.2-0.8,2.6-1,3.9c-0.4,3.3,0.3,6.9,2.1,10.5c1.1,1.6,1.8,3.6,2.1,5.9
	c0.1,1.9,0,4.1-0.4,6.5c-2.9,16.8-4.3,35.4-4,55.9c-0.3,20.5,1.1,39.1,4,55.9c0.4,2.3,0.5,4.5,0.4,6.3c-0.3,2.3-1,4.3-2.1,5.9
	c-1.8,3.6-2.5,7.2-2.1,10.5c0.1,1.4,0.5,2.6,1,3.9c4.3,9.2,9.6,17.3,15.8,24.2c3.2,3.7,6.9,6.7,11,9.2c0.4,0.3,0.7,0.6,1.1,0.7
	l1.6,1c1.5,0.8,3.2,1.5,4.8,2.1l80.6-18.8c6.1-1.9,8.7-6.9,8.1-14.9c-2.9-20.5-4.8-42.2-5.6-65.2l-0.3-21.2l0.3-20.9
	c1-22.8,2.8-44.4,5.6-64.8c0.7-7.8-2.1-12.8-8.1-14.9l-80.6-18.8c-1.6,0.6-3.2,1.2-4.8,2.1L303.3,185.2L303.3,185.2z M308.4,204.5
	c0.1-0.4,0.3-0.8,0.5-1.1c3.6-7.4,10.5-10.6,20.8-9.6c16.9,1.8,33.8,5.2,50.6,10.3c7.3,2.2,10.5,7.7,9.5,16.6l-4.5,51.6
	c-0.7,4-2.5,6.9-5.4,8.5c-3.4,2.3-4.8,6.7-4.3,13.1v16.5c-0.5,6.3,0.8,10.7,4.3,13.1c2.9,1.6,4.8,4.5,5.4,8.5l4.5,51.6
	c1.1,8.9-2.1,14.4-9.5,16.6c-16.9,5.1-33.8,8.5-50.6,10.3c-10.3,1-17.3-2.3-20.8-9.6l-0.7-1.5c-9.6-21.9-14.9-49.5-15.7-82.8
	c-0.1-4.8-0.3-9.6-0.3-14.4s0.1-9.5,0.3-14.3c1-33.3,6.2-60.8,15.7-82.7L308.4,204.5L308.4,204.5z"
          />
          <path
            fill="#EBEBEB"
            d="M309,203.4c-0.1,0.4-0.4,0.7-0.5,1.1l-0.1,0.4c-9.6,21.9-14.9,49.4-15.7,82.7c-0.1,4.8-0.3,9.5-0.3,14.3
	c0,4.8,0.1,9.6,0.3,14.4c0.8,33.3,6.1,60.8,15.7,82.8l0.7,1.5c3.6,7.4,10.5,10.6,20.8,9.6c16.9-1.8,33.8-5.2,50.6-10.3
	c7.3-2.2,10.5-7.7,9.5-16.6l-4.5-51.6c-0.7-4-2.5-6.9-5.4-8.5c-3.4-2.3-4.8-6.7-4.3-13.1v-16.5c-0.5-6.3,0.8-10.7,4.3-13.1
	c2.9-1.6,4.8-4.5,5.4-8.5l4.5-51.6c1.1-8.9-2.1-14.4-9.5-16.6c-16.9-5.1-33.8-8.5-50.6-10.3C319.4,192.8,312.4,196,309,203.4
	L309,203.4z"
          />
          <path
            fill="none"
            d="M775.9,297.5h-1.5v-3.3 M775.9,297.8h-1.5v8.8c0,0.1,0,0.3,0.1,0.3h1l0.1-0.1v-4.8l-0.1-0.1H775v2.6h0.1v-2.2
	h0.1v4.3h-0.8v-8.3h1.4L775.9,297.8L775.9,297.8z M775.9,297.8v-0.3"
          />
          <path
            fill="none"
            d="M774.2,294.2v12.9c0,0.1,0.1,0.1,0.1,0.1h1l0.1-0.1l0.1-0.4v-4.5l-0.1-0.4l-0.1-0.1h-0.5
	c-0.1,0-0.1,0.1-0.1,0.4v2.6h-0.1v-2.9c0-0.3,0-0.4,0.1-0.4h0.5l0.3,0.3l0.1,0.5v5.1c0,0.3,0,0.5-0.1,0.7h-1.5
	c-0.1,0-0.3-0.3-0.3-0.5v-13.1h0.5L774.2,294.2L774.2,294.2z M774.3,294.2h-0.1"
          />
          <path
            fill="none"
            d="M775.9,297.5v-0.4h-1.4v-2.9h-0.1 M567.2,345.8c4.7-3.3,7.3-10.5,7.7-21.5v-44.6c-0.4-11.1-3-18.3-7.7-21.5"
          />
          <path
            fill="none"
            d="M772.1,306.2l0.4,0.8c0.1,0.4,0.4,0.7,0.5,1c0.7,1.1,1.5,1.7,2.3,1.5c1.1,0,2.1-0.8,2.9-2.6
	c0.8-1.8,1.2-3.9,1.2-6.2c0-2.5-0.4-4.5-1.2-6.2c-0.8-1.6-1.8-2.6-2.9-2.6c-0.8,0-1.7,0.5-2.3,1.6l-1,1.9 M308.4,204.5l-0.1,0.4
	c-9.6,21.9-14.9,49.4-15.7,82.7c-0.1,4.8-0.3,9.5-0.3,14.3c0,4.8,0.1,9.6,0.3,14.4c0.8,33.3,6.1,60.8,15.7,82.8l0.7,1.5
	c3.6,7.4,10.5,10.6,20.8,9.6c16.9-1.8,33.8-5.2,50.6-10.3c7.3-2.2,10.5-7.7,9.5-16.6l-4.5-51.6c-0.7-4-2.5-6.9-5.4-8.5
	c-3.4-2.3-4.8-6.7-4.3-13.1v-16.5c-0.5-6.3,0.8-10.7,4.3-13.1c2.9-1.6,4.8-4.5,5.4-8.5l4.5-51.6c1.1-8.9-2.1-14.4-9.5-16.6
	c-16.9-5.1-33.8-8.5-50.6-10.3c-10.3-1-17.3,2.3-20.8,9.6C308.7,203.8,308.6,204.1,308.4,204.5L308.4,204.5z M575.6,235.9l1.7,16.1
	c0.3,3,1.1,5.5,2.3,7.4l0.1,0.1c2.6,4.1,4.1,10.7,4.5,19.9v44.8c-0.3,9.2-1.8,15.8-4.5,19.9l-0.1,0.1c-1.2,1.9-2.1,4.4-2.3,7.4
	l-1.7,16.2c-1.1,6.3,2.5,10.2,10.7,11.7c14.4,0.7,32.2,0.1,53.4-1.5l24.5-2.1c10.2-1.6,17.1-6.9,20.6-15.5c4.8-9.1,7.8-21.7,9.1-38
	l0.4-6.9c0.1-4.5,0.3-8.9,0.3-13.2l-0.5-18.3l-0.3-3.3c-1.4-16.2-4.4-28.9-9.1-38c-3.6-8.7-10.5-13.8-20.6-15.4l-24.5-2.1
	c-21.2-1.6-38.9-2.2-53.4-1.6C578,225.7,574.5,229.5,575.6,235.9L575.6,235.9z M369.6,160.4l7.8-17.1c0.3-1-0.3-1.6-1.8-2.1"
          />
          <path
            fill="none"
            d="M375.8,141.2L370,141c-5.8,0.1-9.9,1.9-12.5,5.4l-10.6,13.8 M369.6,160.4l-3.2,6.7c-0.8,1.1-1.9,1.8-3.3,1.8
	h-0.7 M358.2,168.6l4.4,0.3"
          />
          <path
            fill="none"
            d="M476,160.5h-96.2l-10.2-0.1 M362.6,168.9l13.2-27.6 M356.4,174.1c0.1-0.4,0.1-0.8,0.3-1.2v-0.4l1.6-3.9
	 M336.3,175.8c0.4,0.8,1.4,1.1,3.2,1l13.2-0.6c1.5,0,2.5-0.4,3.2-1.2l0.5-1 M344.3,163.5l2.6-3.4 M358.2,168.6l-9.5-0.7
	c-3.7-0.1-5.2-1.5-4.5-4.3 M305.1,159.4l-19.5-0.3c-6.6,0.1-13.2-0.3-19.7-1.2l-17.9-1.1c-27.8-0.6-56.1,0-85.3,1.6l-4.7,0.1
	 M309.9,182.2c-1.6,0.6-3.2,1.2-4.8,2.1l-1.6,1c-0.4,0.3-0.8,0.4-1.1,0.7 M298.4,179.9c-0.7-0.8-1.4-1.5-2.1-2.2 M302.2,185.9
	c-1.1-2.3-2.3-4.3-3.9-6.1 M274.6,223.4c0.1-1.4,0.5-2.6,1-3.9c4.3-9.2,9.6-17.3,15.8-24.2c3.2-3.7,6.9-6.7,11-9.4 M342.7,181.1
	l0.7,0.1c27.8,7,54.5,12.5,79.9,16.5l9.1,1.1c7.2,0.8,14.3,1.4,21.3,1.5c22.3,1,46.6,0.8,73.3-0.3 M514.3,182.2l-5.8-0.3
	c-70.4-3.3-125.5-3.6-165.1-1h-0.7"
          />
          <path
            fill="none"
            d="M344.3,163.5l-8,10.6c-0.1,0.6-0.1,1,0,1.2l0.1,0.4 M332.9,178.6l-6.7-0.1 M326.2,178.5c-0.3,0-0.5,0.1-0.8,0.1
	 M516,178.9c18.2,0.8,37.6,2.8,58.2,5.5c15.3,1.6,25.7,5.5,31.1,11.1c1.9,1.6,1.1,2.6-2.6,2.8l-61.6,3c-32.1,1.6-61.2,2.1-87.2,1.1
	l-9.5-0.4c-7-0.3-14-1-21-2.3c-27.1-4.1-55.7-10-85.6-17.9l-9.1-2.5l-3.2-0.7 M332.9,178.6l1.1-0.1 M334,178.5l-1.2,0.1h0.1
	 M325.1,178.6l-3.3-2.2 M326.2,178.5h-1.1 M325.5,178.6h-0.3 M321.8,176.4l14.6-0.7 M332.9,178.6l9.8,2.6 M325.5,178.6
	c-5.5,0.6-10.6,1.8-15.5,3.6 M346.9,160.1l-42-0.7 M298.4,179.9l26.8-1.4 M296.3,177.8l25.6-1.4 M321.8,176.4
	c-8.4-5.6-13.9-11.4-16.6-17.1 M309.9,182.2l80.6,18.8c6.1,1.9,8.7,6.9,8.1,14.9c-2.9,20.5-4.8,42.1-5.6,64.8l-0.3,20.9l0.3,21.2
	c0.8,22.8,2.8,44.6,5.6,65.2c0.7,7.8-2.1,12.8-8.1,14.9l-80.6,18.8 M158.1,158.4l-11.4,6.9h-0.3 M146.4,209.5l1.8,0.1 M136.5,228.9
	c-1.6-0.7-2.1-1.6-1.1-3.2l6.1-11.4c1.6-2.8,3.3-4.3,5-4.5 M172.8,201.8c-16.6,3.2-31.2,6.6-43.7,10.3c-7.7,2.2-12.5,5.8-14.9,10.3
	l-3.9,8.9c-1.1,3.3,0.6,4.7,4.7,4l45.3-6.7c4.5-0.6,7.4-2.2,8.8-5l8.3-16.9C179.8,202.4,178.3,200.7,172.8,201.8L172.8,201.8z
	 M278.7,239.7c-0.3-2.3-1-4.3-2.1-5.9c-1.8-3.6-2.5-7.2-2.1-10.5 M278.7,364.2c0.1-1.9,0-4-0.4-6.3c-2.9-16.8-4.3-35.4-4-55.9
	c-0.3-20.5,1.1-39.1,4-55.9c0.4-2.3,0.5-4.5,0.4-6.5 M274.6,380.7c-0.4-3.3,0.3-6.9,2.1-10.5c1.1-1.6,1.8-3.7,2.1-5.9 M302.2,418
	c-4.1-2.5-7.8-5.6-11-9.2c-6.2-6.9-11.4-15-15.8-24.2c-0.5-1.2-0.8-2.6-1-3.9 M169.2,380.3l8.3,16.9c2.3,4.4,0.7,6.1-4.7,5.1
	c-16.6-3.2-31.2-6.6-43.7-10.3c-7.7-2.2-12.5-5.8-14.9-10.3l-3.9-8.9c-1.1-3.3,0.6-4.7,4.7-4l45.3,6.7
	C164.9,375.9,167.8,377.6,169.2,380.3L169.2,380.3z M146.4,394.5l1.8-0.1 M158.1,445.5l4.7,0.1c29,1.7,57.5,2.2,85.3,1.7l17.9-1.1
	c6.6-1,13.2-1.4,19.7-1.2l19.5-0.3 M158.1,445.5l-11.4-6.9h-0.3 M136.5,375.2c-1.6,0.7-2.1,1.8-1.1,3.3l6.1,11.4
	c1.6,2.8,3.3,4.3,5,4.5 M332.9,425.4h-0.1l1.2,0.1 M516,425c18.2-0.8,37.6-2.8,58.2-5.5c15.3-1.8,25.7-5.5,31.1-11.1
	c1.9-1.6,1.1-2.6-2.6-2.8l-61.6-2.9c-32.1-1.8-61.2-2.1-87.2-1.1l-9.5,0.4c-7,0.3-14,1-21,2.3c-27.1,4.1-55.7,10-85.6,17.9l-9.1,2.5
	c-0.1,0-1.2,0.3-3.6,0.8 M321.8,427.6l3.3-2.2 M334,425.6l-1.1-0.1 M356.4,430l-0.5-1c-0.7-0.7-1.6-1.1-3.2-1.2l-13.2-0.5
	c-1.6-0.1-2.8,0.1-3.2,1 M342.7,422.8h0.7c39.6,2.6,94.8,2.3,165.1-1l5.8-0.3 M342.7,422.8l0.7-0.1c27.8-7,54.5-12.5,79.9-16.5
	c3-0.5,6.1-0.8,9.1-1.1c7.2-0.8,14.3-1.4,21.3-1.5c22.3-1,46.6-0.8,73.3,0.4 M344.3,440.6l-8-10.6c-0.1-0.5-0.1-1,0-1.2l0.1-0.4
	 M325.1,425.4l12.2,0.6 M336.3,428.3l-14.6-0.7 M332.9,425.4l9.8-2.6 M296.3,426.3l2.1-2.2 M309.9,421.7c-1.6-0.5-3.2-1.2-4.8-2.1
	l-1.6-1c-0.4-0.3-0.8-0.4-1.1-0.7 M302.2,418c-1.1,2.3-2.3,4.3-3.9,6.1 M358.2,435.5l-9.5,0.7c-3.7,0.1-5.2,1.6-4.5,4.3
	 M344.3,440.6l2.8,3.4 M325.1,425.4c-5-0.6-10-1.8-15.3-3.7 M305.1,444.7l42-0.7 M298.4,424.1l26.8,1.4 M321.8,427.6l-25.6-1.4
	 M305.1,444.7c2.8-5.6,8.4-11.3,16.6-17.1 M356.4,430c0.1,0.4,0.1,0.8,0.3,1.2v0.5l1.6,3.9 M358.2,435.5l4.4-0.4 M362.6,435.1h0.7
	c1.4,0.1,2.5,0.7,3.3,1.8l3.2,6.7 M375.8,462.9c1.5-0.4,2.1-1.1,1.8-2.1l-7.8-17.1 M346.9,444l10.6,13.8c2.6,3.4,6.7,5.2,12.5,5.4
	l5.8-0.3 M369.6,443.6l10-0.1H476 M362.6,435.1l13.2,27.6 M296.3,426.3c-5.1,4.7-12.2,7-21.2,6.9c-90.2-1.4-147.7-6.5-172.5-15.1
	 M296.3,177.8c-5.1-4.7-12.2-7-21.2-6.9c-90.2,1.4-147.9,6.6-172.8,15.5 M158.1,158.4c-16.5,0.7-27.6,2.5-33.7,5.2l-2.5,1.4
	c-3.7,2.1-8,4-12.7,5.9c-4.5,1.2-8.9,3.7-13.5,7.2l-0.8,0.7c-8,6.3-15.3,14.7-22,25 M102.4,186.4c-0.6,0.6-1.1,1.1-1.7,1.8l-8.8,9.5
	 M70.6,207.5l2.3-3.7 M91.8,197.5l-12.2,13.1c-0.7,1.9-1.4,3.9-2.1,6.1 M146.2,165.4c-17.3,1.5-31.9,7.6-43.9,18.2
	c-5.8,5.2-9.4,9.8-10.6,13.9 M146.2,165.4c-11.3,2.8-20.4,5.9-27.4,9.5c-6.5,3-12.1,6.9-16.6,11.4 M72.9,203.8l-16.2,18.7
	c-1.9,2.6-2.2,5.8-0.8,9.5c0.7,3.9,0.3,10.5-1.4,19.9l-1,5.2c-2.8,15.7-4.4,29.6-4.7,42v5.8c0.3,12.4,1.8,26.3,4.7,42l1,5.2
	c1.7,9.5,2.1,16.1,1.4,19.9c-1.4,3.7-1.1,6.9,0.8,9.5l16.2,18.7 M70.6,396.4c-2.8-3.9-4.8-8.1-6.1-12.7l-3.6-14.6
	c-1.4-6.1-2.8-12-3.9-17.6c-3.4-17.6-5.4-33.2-5.8-46.6v-5.8c0.3-13.6,2.2-29.2,5.8-46.6c1.1-5.6,2.5-11.6,3.9-17.6l3.6-14.6
	c1.2-4.5,3.2-8.8,6.1-12.8 M65.2,226l-2.2,6.3 M63,371.6l-0.4-1.4c-0.7-2.3-1.2-4.7-1.7-7c-2.6-14.3-4.3-34.7-5.1-61.2
	c0.8-26.5,2.5-46.9,5.1-61.2c0.4-2.3,1-4.7,1.7-7l0.4-1.4 M77.6,216.6c-1.4,0.7-2.6,1.5-3.9,2.3c-1.7,1.1-3.3,2.3-5,3.6
	c-0.7,0.6-1.4,1.2-2.1,1.9l-1.5,1.6 M75.8,223.1c0.6-2.3,1.1-4.5,1.8-6.6 M63,232.3c3.3-2.6,6.6-5.1,9.8-7.3l3-1.9 M89.4,256.1
	l-2.3,0.4 M75.8,380.9c-3.9-15.7-6.3-37.4-7.4-65.2c-0.4-4.4-0.6-9.1-0.6-13.8c0-4.7,0.1-9.2,0.6-13.6c1.1-27.6,3.6-49.4,7.4-65.2
	 M65.2,226l3.4-9.9c1-3.2,1.7-6.1,1.9-8.5 M89.4,347.9l-2.3-0.4 M77.6,387.5c-0.7-2.1-1.2-4.3-1.8-6.6 M65.2,378l1.5,1.6
	c0.7,0.7,1.2,1.2,2.1,1.9l5,3.6c1.2,0.8,2.5,1.5,3.9,2.3 M75.8,380.9l-3-1.9c-3.2-2.2-6.5-4.5-9.8-7.3 M70.6,396.4
	c-0.3-2.5-1-5.4-1.9-8.5l-1.9-5.8l-1.5-4.1 M63,371.7l2.2,6.3 M72.9,400.3l-2.3-3.7 M102.6,418l-2.1-2.1l-8.8-9.4 M72.9,400.3
	c6.7,10.3,14,18.7,22,25l0.7,0.5c4.5,3.4,8.9,5.9,13.5,7.2c4.7,1.9,8.9,4,12.7,5.9l2.5,1.4c6.1,2.8,17.2,4.5,33.7,5.2 M91.8,406.6
	l-12.2-13.1c-0.7-1.8-1.4-3.9-2.1-5.9 M146.2,438.6c-17.3-1.5-32.1-7.6-43.9-18.2c-5.8-5.2-9.4-9.8-10.6-13.9 M146.2,438.6
	c-11.3-2.8-20.4-5.9-27.4-9.5c-6.3-2.9-11.8-6.6-16.4-11.1 M75.8,223.1c35.6-22.3,109.8-36.7,222.6-43.2 M298.4,424.1
	c-112.8-6.5-186.9-20.8-222.6-43.2 M136.5,228.9l9.9-19.3 M138.7,229.4c-1-0.1-1.6-0.3-2.2-0.6 M138.7,374.7c-1,0.1-1.6,0.3-2.2,0.5
	 M77.6,387.5c34.5,19.4,107.4,32.3,218.7,38.8 M77.6,216.6c34.5-19.4,107.4-32.3,218.7-38.8 M274.6,223.4
	c-79.2,6.6-141,17.5-185.2,32.7 M278.7,364.2c-66.7-1.8-129.7-7.3-189.1-16.4 M89.4,256.1c59.4-9.1,122.6-14.6,189.1-16.4
	 M274.6,380.7c-79.2-6.6-141-17.6-185.2-32.9 M136.5,375.2l9.9,19.3 M728.4,187.2l2.6,0.4 M746.8,184c4.4,1,8.5,2.6,12.5,4.7
	 M752.5,193.8c-1.5-3.4-3-6.2-4.5-8.3l-1.1-1.4 M746.8,184c-1.8-2.5-1.5-3.9,1.1-4.3c5.9,0.3,10.2,3.2,13.1,8.5l1,1.9 M731,187.6
	c8.7,1.6,14,4.5,16.2,8.9l0.8,1.6 M754.4,198.5h-6.3 M758.7,210.7c-1.2-3.9-2.5-7.4-3.6-10.6 M754.4,198.5l0.7,1.8 M766.4,205.7
	l2.6,7.7 M765.4,197.8c1.4,3.2,2.8,6.7,4,10.5 M762.4,196c1.1,0.6,2.1,1.1,3,1.8 M771.7,214.8c-0.8-0.6-1.8-1-2.6-1.4 M769.5,208.1
	l2.2,6.7 M766.4,205.7c1.1,0.7,2.1,1.5,3,2.3 M762.4,196c-3-1.5-6.3-2.2-9.9-2.2 M759.2,188.7l3.2,7.3 M762,190.2l-2.6-1.5
	 M752.5,193.8l1.9,4.7 M769,213.6c-3.3-1.5-6.7-2.5-10.3-2.9 M755.1,200.1c4,1.4,7.8,3.2,11.3,5.6 M765.4,197.8
	c-1.1-2.6-2.3-5.2-3.6-7.6 M518.8,443.3c9.5,0,20.1,1.1,31.6,3.3c24.6,4.1,55,5.5,91.5,4c25.7,1,47.5-2.1,65.5-9.4
	c10.5-3.6,22.4-6.6,35.9-8.9c14.2-2.6,24.2-11.6,29.8-27c10.3-21.7,16.9-52.8,19.7-93.3c0.3-3.2,0.4-6.5,0.5-10
	c0-3.6-0.3-7-0.5-10.2c-2.9-40.4-9.4-71.5-19.7-93.3c-5.6-15.4-15.7-24.4-29.8-27c-13.5-2.5-25.3-5.5-35.9-8.9
	c-18-7.3-39.8-10.5-65.5-9.5c-36.5-1.4-66.9,0-91.5,4.1c-11.6,2.2-22.1,3.3-31.6,3.3 M748.1,198.3l-22.6-0.1
	c-13.8-0.6-24.5,0.6-32.3,3.3c-8.1,4.3-13.9,10.3-17.5,18.3 M772,222l2.2,1.6 M774.1,230.4L772,222 M776.3,231.5
	c-0.7-0.4-1.4-0.7-2.2-1.1 M774.2,223.6l1.9,7.8 M763.8,228.7c4,0,7.4,0.6,10.3,1.6 M765.3,235.2l-1.7-6.5 M777.6,237.7
	c-2.9-1.5-7-2.3-12.4-2.5 M776.3,231.5l1.5,6.2 M760.5,216.6c4.4,1.4,8.4,3.2,11.7,5.4 M763.8,228.7c-1.1-4.3-2.2-8.4-3.3-12.1
	 M765.3,368.9l1.7-7.2c2.2-9.9,3.7-22.1,4.5-36.6c0.3-4.5,0.4-9.2,0.5-14.2v-16.5l-0.3-12c-0.7-16.1-2.3-29.4-4.7-40.2l-1.7-7.2"
          />
          <path
            fill="none"
            d="M772.1,307.4v0.1c0.8,1.9,1.9,2.8,3.2,2.8h0.3c1.1-0.1,2.1-1.1,2.9-2.8c0.8-1.9,1.4-4.1,1.4-6.9
	c0-2.6-0.4-5-1.4-6.7c-0.8-1.8-1.8-2.6-2.9-2.8h-0.3c-1.2,0-2.3,1-3.2,2.9l-0.1,0.3 M748.1,198.3c11.7,23.9,18,52,19.1,84.2
	l0.3,10.7l-0.1,22.4c-0.5,34.7-6.9,64.7-19.3,90.1"
          />
          <path
            fill="none"
            d="M777.6,237.7c1.1,5.1,2.2,10.5,3.2,16.1c2.3,13.1,3.6,29.2,3.9,48.1c-0.1,19-1.5,35.1-3.9,48.3
	c-1.1,5.6-2.1,11-3.2,16.1 M771.7,214.8c0.8,2.8,1.8,5.8,2.6,8.8 M760.5,216.6l-1.8-5.9 M774.1,373.6c-2.9,1.1-6.3,1.6-10.3,1.6
	 M776.3,372.6l-2.2,1 M774.1,373.6L772,382 M774.2,380.3l2.1-7.7 M771.7,389.1l2.5-8.8 M772,382l2.2-1.6 M765.3,368.9l-1.5,6.5
	 M777.6,366.3c-2.9,1.5-7,2.3-12.4,2.5 M777.6,366.3l-1.4,6.3 M772,382c-3.3,2.2-7.2,4-11.7,5.4 M763.8,375.4
	c-1.1,4.3-2.2,8.4-3.3,12.1 M765.4,406.3c1.4-3.3,2.8-6.7,4-10.5 M762.4,408.1c1.1-0.5,2.1-1.1,3-1.8 M766.4,398.2l2.6-7.7
	 M769.5,395.9c-1,0.8-2.1,1.6-3,2.3 M754.4,405.6l-1.9,4.7 M748.1,405.6h6.3 M731,416.4c8.7-1.6,14-4.5,16.2-8.9l0.8-1.8
	 M755.1,403.8l-0.7,1.8 M758.7,393.4c-1.2,3.9-2.5,7.4-3.6,10.6 M746.8,419.9c-1.8,2.5-1.5,3.9,1.1,4.3c5.9-0.4,10.2-3.2,13.1-8.5
	l1-1.9 M746.8,419.9c4.4-1,8.5-2.6,12.5-4.7"
          />
          <path
            fill="none"
            d="M752.5,410.3c-1.5,3.4-3,6.2-4.5,8.4l-1.1,1.4 M762,413.9c1.2-2.3,2.3-4.8,3.6-7.6 M759.2,415.4l3.2-7.3
	 M762,413.9l-2.8,1.5 M769,390.5c-3.3,1.5-6.7,2.5-10.3,2.8 M762.4,408.1c-3,1.5-6.3,2.2-9.9,2.2 M766.4,398.2
	c-3.6,2.5-7.3,4.3-11.3,5.6 M728.4,416.8l2.6-0.4 M748.1,405.6l-22.6,0.1c-13.8,0.5-24.5-0.5-32.3-3.3c-8.1-4.3-13.9-10.3-17.5-18.3
	 M769.5,395.9l2.2-6.7 M760.5,387.5l-1.8,5.9 M769,390.5l2.8-1.4 M544.5,199.4l50.3-2.3c3.7-0.6,4.3-1.2,1.8-2.3
	c-5.5-3.2-12.9-5.5-22.3-6.9c-13.5-1.9-27.1-3.4-41-4.4 M559.3,390.6c3.6-13.3,6.2-28.3,7.7-44.8c0.7-8,1.2-16.4,1.5-25.2
	c0.1-5,0.3-10,0.1-15.3v-6.7c0-5.2,0-10.3-0.1-15.3c-0.3-8.8-0.7-17.1-1.5-25c-1.5-16.6-4.1-31.5-7.7-44.8 M675.8,219.8
	c5.9,2.5,11.3,8,16.1,16.2c5.8,12.7,9.4,27.9,10.9,45.9l0.3,4c0.3,3.9,0.4,7.7,0.4,11.7c0.1,6.1,0,12-0.3,18l-0.1,3.2
	c0,1.1-0.1,2.1-0.3,3.2c-1.5,18-5.1,33.4-10.9,45.9c-4.8,8.4-10.2,13.8-16.1,16.2"
          />
          <path
            fill="none"
            d="M559.3,213.4h4.5c34.5,0.8,70,2.5,106.3,4.8c1.9,0.1,3.9,0.7,5.6,1.5 M506.4,160.5c1.9,0.7,3.6,1.5,5.1,2.6
	c-1.5,1.8-5.9,2.3-13.3,1.5 M518.8,160.6l-12.4-0.1 M518.8,160.6c2.9,2.3,2.8,6.9-0.3,13.3l-1,1.9 M514.3,182.2l1.7-3.3
	 M517.5,175.9l-1.5,3 M518.5,175.9h-1 M517.5,175.9h1 M731,187.6c-40.3-9.2-93.7-13.6-160-13.2c-5-0.1-9.8,0-14.7,0.4l-12.2,0.8
	c-6.1,0.3-12.5,0.4-19.7,0.4l-5.9-0.1 M517.5,175.9l-9.1-0.1c-63-2.9-113.6-3.4-152-1.6 M527,200.2c-3.9-7-8.1-12.9-12.8-17.9
	 M516,178.9l-7.4-0.3c-76.1-3.6-134.3-3.6-174.6-0.1 M544.6,199.6l-0.1-0.1 M533.3,183.5c3.7,4.1,7.4,9.5,11,16 M533.3,183.5
	l-19-1.2 M544.5,199.4l-17.5,0.8 M482.6,164.3l-7-0.6c-3.3-0.6-4-1.2-2.1-2.1c0.7-0.4,1.5-0.8,2.5-1.1 M485.9,160.5
	c-1.8,0.4-2.9,1.6-3.3,3.7 M494.4,160.5l-1-0.1h-5.4l-2.2,0.1 M494.4,160.5h-8.5"
          />
          <path
            fill="none"
            d="M506.4,160.5c-3.4-1.2-7.4-1.9-12.2-2.2c-7.7-0.1-13.8,0.6-18.2,2.2 M498.1,164.6c0-2.2-1.2-3.6-3.9-4
	 M498.1,164.6l-15.5-0.3 M494.4,443.5h-8.5 M485.9,443.5c-1.8-0.4-2.9-1.7-3.3-3.7 M494.4,443.5l-1,0.1l-7.6-0.1 M482.6,439.7
	l-7,0.5c-3.3,0.5-4,1.2-2.1,2.1c0.7,0.4,1.5,0.8,2.5,1.1"
          />
          <path
            fill="none"
            d="M476,443.5c4.4,1.6,10.5,2.3,18.2,2.2c4.8-0.3,8.9-1.1,12.2-2.2 M675.8,384.2c-1.8,0.8-3.7,1.2-5.6,1.5
	c-36.3,2.5-71.7,4.1-106.3,4.8h-4.5 M544.5,404.7l0.1-0.1 M518.5,428.2l5.9-0.1c7.2,0,13.8,0.1,19.7,0.4l12.2,0.8l14.7,0.4
	c66.4,0.3,119.8-4.1,160-13.2 M544.5,404.7c-3.7,6.5-7.4,11.8-11,16 M516,425l1.5,3 M514.3,421.7l1.7,3.3 M517.5,428.2h1
	 M518.8,443.3c2.9-2.3,2.8-6.9-0.3-13.3l-1-1.9 M517.5,428l1,0.1 M517.5,428.2V428 M517.5,428c-3.7,0-6.7,0-9.1,0.1
	c-63,2.9-113.6,3.4-152,1.6 M334,425.6c40.2,3.4,98.4,3.3,174.6-0.1l7.4-0.3 M527,403.8c-3.9,7-8.1,12.9-12.8,17.9"
          />
          <path
            fill="none"
            d="M506.4,443.5h6.3c1.9-0.1,4-0.1,6.1-0.1 M506.4,443.5c1.9-0.7,3.6-1.5,5.1-2.6c-1.5-1.8-5.9-2.3-13.3-1.5
	 M514.3,421.7l19-1.1 M527,403.8l17.5,0.8 M498.1,439.5c0,2.2-1.2,3.6-3.9,4 M482.6,439.7l15.5-0.3 M533.3,420.6
	c13.9-1.1,27.5-2.5,41-4.4c9.4-1.4,16.8-3.6,22.3-6.9c2.5-1.1,1.9-1.8-1.8-2.3l-50.3-2.3 M559.3,390.6
	c-65.3,0.5-119.1,2.6-161.4,6.2 M559.3,213.4c-65.3-0.6-119.1-2.6-161.4-6.3 M377.5,143.3c0.3-1-0.3-1.6-1.8-2.1l-13.2,27.6h0.7
	c1.4,0,2.5-0.7,3.3-1.8l3.2-6.7L377.5,143.3L377.5,143.3z"
          />
          <path
            fill="none"
            d="M375.8,141.2L370,141c-5.8,0.1-9.9,1.9-12.5,5.4l-10.6,13.8l-2.8,3.4c-0.7,2.8,0.7,4.1,4.5,4.3l9.5,0.7l4.4,0.4
	L375.8,141.2L375.8,141.2z"
          />
          <path
            fill="none"
            d="M363.3,168.9h-0.7l-4.4-0.4l-1.6,3.9v0.6c0,0.4-0.1,0.8-0.3,1.2c38.4-1.8,89.1-1.2,152,1.6l9.1,0.1l1-1.9
	c3-6.5,3-10.9,0.3-13.3l-12.4-0.1c1.9,0.7,3.6,1.5,5.1,2.6c-1.5,1.8-5.9,2.3-13.3,1.5l-15.5-0.3l-7-0.6c-3.3-0.6-4-1.2-2.1-2.1
	c0.7-0.4,1.5-0.8,2.5-1.1h-96.2l-10-0.1l-3.2,6.7C365.8,168.3,364.7,168.9,363.3,168.9L363.3,168.9z"
          />
          <path
            fill="none"
            d="M356.7,172.9v-0.4l1.5-3.9l-9.5-0.7c-3.7-0.1-5.2-1.5-4.5-4.3l-8,10.6c-0.1,0.6-0.1,1,0,1.2l0.1,0.4
	c0.4,0.8,1.4,1.1,3.2,1l13.2-0.6c1.5,0,2.5-0.4,3.2-1.2l0.5-1C356.6,173.7,356.7,173.3,356.7,172.9L356.7,172.9z"
          />
          <path
            fill="none"
            d="M344.3,163.5l2.8-3.4l-42-0.7c2.8,5.6,8.4,11.3,16.6,17.1l14.6-0.7l-0.1-0.4v-1.2L344.3,163.5L344.3,163.5z
	 M298.4,179.9c-0.7-0.8-1.4-1.5-2.1-2.2c-111.1,6.5-184.1,19.4-218.7,38.8c-0.7,2.1-1.2,4.3-1.8,6.6
	C111.4,200.8,185.6,186.4,298.4,179.9L298.4,179.9z"
          />
          <path
            fill="none"
            d="M302.2,185.9c-1.1-2.3-2.3-4.3-3.9-6.1c-112.8,6.5-186.9,20.8-222.6,43.2c-3.9,15.7-6.3,37.4-7.4,65.2
	c-0.4,4.4-0.6,8.9-0.6,13.6c0,4.8,0.1,9.4,0.6,13.8c1.1,27.6,3.6,49.4,7.4,65.2c35.6,22.4,109.9,36.9,222.6,43.2
	c1.5-1.8,2.8-3.7,3.9-6.1c-4.1-2.5-7.8-5.6-11-9.2c-6.2-6.9-11.4-15-15.8-24.2c-0.5-1.2-0.8-2.6-1-3.9
	c-79.1-6.6-140.9-17.6-185-32.9l-2.3-0.4l2.3,0.4c59.4,9.1,122.6,14.6,189.1,16.4c0.1-1.9,0-4-0.4-6.3c-2.9-16.8-4.3-35.4-4-55.9
	c-0.3-20.5,1.1-39.1,4-55.9c0.4-2.3,0.5-4.5,0.4-6.5c-66.7,1.8-129.7,7.3-189.1,16.4l-2.3,0.4l2.3-0.4
	c44.2-15.3,105.9-26.3,185-32.7c0.1-1.4,0.5-2.6,1-3.9c4.3-9.2,9.6-17.3,15.8-24.2C294.4,191.6,298.1,188.6,302.2,185.9L302.2,185.9
	z M172.8,201.8c5.5-1.1,7,0.7,4.7,5.1l-8.3,16.9c-1.2,2.6-4.3,4.3-8.8,5l-45.3,6.7c-4.3,0.6-5.8-0.7-4.7-4l3.9-8.9
	c2.2-4.5,7.2-8,14.9-10.3C141.6,208.4,156.1,204.9,172.8,201.8L172.8,201.8z M160.4,375.4c4.5,0.7,7.4,2.3,8.8,5l8.3,16.9
	c2.3,4.4,0.7,6.1-4.7,5.1c-16.6-3.2-31.2-6.6-43.7-10.3c-7.7-2.2-12.5-5.8-14.9-10.3l-3.9-8.9c-1.1-3.3,0.6-4.7,4.7-4L160.4,375.4
	L160.4,375.4z"
          />
          <path
            fill="none"
            d="M177.5,206.8c2.3-4.4,0.7-6.1-4.7-5.1c-16.6,3.2-31.2,6.6-43.7,10.3c-7.7,2.2-12.5,5.8-14.9,10.3l-3.9,8.9
	c-1.1,3.3,0.6,4.7,4.7,4l45.3-6.7c4.5-0.6,7.4-2.2,8.8-5L177.5,206.8L177.5,206.8z M148.2,209.6l-1.8-0.1l-9.9,19.3
	c0.6,0.3,1.4,0.4,2.2,0.6c-1-0.1-1.6-0.3-2.2-0.6c-1.6-0.7-2.1-1.7-1.1-3.2l6.1-11.4c1.6-2.8,3.3-4.3,5-4.5L148.2,209.6L148.2,209.6
	z"
          />
          <path
            fill="none"
            d="M146.4,209.5c-1.6,0.3-3.3,1.9-5,4.5l-6.1,11.4c-1,1.5-0.6,2.6,1.1,3.2L146.4,209.5L146.4,209.5z M169.2,380.3
	c-1.2-2.6-4.3-4.3-8.8-5l-45.3-6.7c-4.3-0.5-5.8,0.8-4.7,4l3.9,8.9c2.2,4.5,7.2,8,14.9,10.3c12.5,3.7,27.1,7.3,43.7,10.3
	c5.5,1.1,7-0.7,4.7-5.1L169.2,380.3L169.2,380.3z M148.2,394.3l-1.8,0.1c-1.6-0.3-3.3-1.8-4.9-4.5l-6.1-11.4c-1-1.5-0.6-2.6,1.1-3.3
	c0.6-0.3,1.4-0.4,2.2-0.5c-1,0.1-1.6,0.3-2.2,0.5l9.9,19.3L148.2,394.3L148.2,394.3z"
          />
          <path
            fill="none"
            d="M141.4,389.9c1.6,2.8,3.3,4.3,5,4.5l-9.9-19.3c-1.6,0.7-2.1,1.8-1.1,3.3L141.4,389.9L141.4,389.9z M342.7,181.1
	l0.7,0.1c27.8,7,54.5,12.5,79.9,16.5l9.1,1.1c7.2,0.8,14.3,1.4,21.3,1.5c22.3,1,46.6,0.8,73.3-0.3c-3.9-7-8.1-12.9-12.8-17.9
	l-5.8-0.3c-70.4-3.3-125.5-3.6-165.1-1L342.7,181.1L342.7,181.1z M146.2,165.4h0.3l11.4-6.9c-16.5,0.7-27.6,2.5-33.7,5.2l-2.5,1.4
	c-3.7,2.1-8,4-12.7,5.9c-4.5,1.2-8.9,3.7-13.5,7.2l-0.7,0.6c-8,6.3-15.3,14.7-22,25l-2.3,3.7c-0.3,2.5-1,5.4-1.9,8.5l-3.4,9.9
	l1.5-1.6c0.7-0.7,1.2-1.2,2.1-1.9c1.7-1.2,3.2-2.5,5-3.6c1.2-0.8,2.5-1.5,3.9-2.3c0.7-2.1,1.4-4.1,2.1-6.1l12.2-13.1
	c1.2-4.1,4.8-8.7,10.6-13.9C114.3,173,128.9,167,146.2,165.4L146.2,165.4z M276.6,233.8c-1.8-3.6-2.5-7.2-2.1-10.5
	c-79.2,6.6-141,17.5-185.2,32.7c59.4-9.1,122.6-14.6,189.1-16.4C278.4,237.5,277.7,235.5,276.6,233.8L276.6,233.8z M274.6,380.7
	c-0.4-3.3,0.3-6.9,2.1-10.5c1.1-1.6,1.8-3.7,2.1-5.9C212,362.6,149,357.1,89.6,348C133.6,363.1,195.3,374.1,274.6,380.7L274.6,380.7
	z M146.5,438.6h-0.3c-17.3-1.5-32.1-7.6-43.9-18.2c-5.8-5.2-9.4-9.8-10.6-13.9l-12.2-13.1c-0.7-1.8-1.4-3.9-2.1-5.9
	c-1.4-0.7-2.6-1.5-3.9-2.3l-4.8-3.7c-0.7-0.5-1.4-1.2-2.1-1.9l-1.5-1.6l1.5,4.1l1.9,5.8c1,3.2,1.7,6.1,1.9,8.5l2.3,3.7
	c6.7,10.3,14,18.7,22,25l0.7,0.6c4.5,3.4,8.9,5.9,13.5,7.2c4.7,1.9,8.9,4,12.7,5.9l2.5,1.4c6.1,2.8,17.2,4.5,33.7,5.2L146.5,438.6
	L146.5,438.6z M339.5,427.2c-1.6-0.1-2.8,0.1-3.2,1l-0.1,0.4v1.2l8,10.6c-0.7-2.8,0.7-4.1,4.5-4.3l9.5-0.7l-1.5-3.9v-0.5
	c0-0.4-0.1-0.8-0.3-1.2l-0.5-1c-0.7-0.7-1.6-1.1-3.2-1.2L339.5,427.2L339.5,427.2z M342.7,422.8h0.7c39.6,2.6,94.8,2.3,165.1-1
	l5.8-0.3c4.7-5,8.9-10.9,12.8-17.9c-26.5-1.1-51-1.2-73.3-0.4c-7.2,0.1-14.3,0.7-21.3,1.5c-3,0.3-6.1,0.7-9.1,1.1
	c-25.5,3.9-52,9.4-79.9,16.5L342.7,422.8L342.7,422.8z"
          />
          <path
            fill="none"
            d="M336.3,428.6l0.1-0.4l-14.6-0.7c-8.4,5.6-13.9,11.3-16.6,17.1l42-0.7l-2.8-3.4l-8-10.6
	C336.2,429.4,336.2,429,336.3,428.6L336.3,428.6z M296.3,426.3l2.1-2.2c-112.8-6.5-186.9-20.8-222.6-43.2c0.6,2.3,1.1,4.5,1.8,6.6
	C112.1,406.9,185,419.8,296.3,426.3L296.3,426.3z"
          />
          <path
            fill="none"
            d="M348.9,436.2c-3.7,0.1-5.2,1.6-4.5,4.3l2.8,3.4l10.6,13.8c2.6,3.4,6.7,5.2,12.5,5.4l5.8-0.3l-13.2-27.6
	l-4.4,0.4L348.9,436.2L348.9,436.2z"
          />
          <path
            fill="none"
            d="M356.7,431.1v0.5l1.5,3.9l4.4-0.4h0.7c1.4,0.1,2.5,0.7,3.3,1.8l3.2,6.7l10-0.1H476c-1-0.3-1.6-0.7-2.5-1.1
	c-2.1-0.8-1.4-1.5,2.1-2.1l7-0.5l15.5-0.3c7.4-0.8,11.8-0.4,13.3,1.5c-1.5,1-3.2,1.8-5.1,2.6h6.3c1.9-0.1,4-0.1,6.1-0.1
	c2.9-2.3,2.8-6.9-0.3-13.3l-1-1.9c-3.7,0-6.7,0-9.1,0.1c-63,2.9-113.6,3.4-152,1.6C356.6,430.3,356.7,430.7,356.7,431.1L356.7,431.1
	z"
          />
          <path
            fill="none"
            d="M369.6,443.6l-3.2-6.7c-0.8-1.1-1.9-1.8-3.3-1.8h-0.7l13.2,27.6c1.5-0.4,2.1-1.1,1.8-2.1L369.6,443.6
	L369.6,443.6z M70.6,207.5l2.3-3.7l-16.2,18.7c-1.9,2.6-2.2,5.8-0.8,9.5c0.7,3.9,0.3,10.5-1.4,19.9l-1,5.2
	c-2.8,15.7-4.4,29.6-4.7,42v5.8c0.3,12.4,1.8,26.3,4.7,42l1,5.2c1.7,9.5,2.1,16.1,1.4,19.9c-1.4,3.7-1.1,6.9,0.8,9.5l16.2,18.7
	l-2.3-3.7c-2.8-3.9-4.8-8.1-6.1-12.7l-3.6-14.7c-1.4-6.1-2.8-12-3.9-17.6c-3.4-17.6-5.4-33.2-5.8-46.6v-5.8
	c0.3-13.6,2.2-29.2,5.8-46.6c1.1-5.6,2.5-11.6,3.9-17.6l3.6-14.6C65.8,215.8,67.7,211.5,70.6,207.5L70.6,207.5z"
          />
          <path
            fill="none"
            d="M64.5,220.3l-3.6,14.6c-1.4,6.1-2.8,12-3.8,17.6c-3.4,17.5-5.4,33.2-5.8,46.6v5.8c0.3,13.6,2.2,29.2,5.8,46.6
	c1.1,5.6,2.5,11.6,3.8,17.6l3.6,14.6c1.2,4.5,3.2,8.8,6.1,12.7c-0.3-2.5-1-5.4-1.9-8.5l-1.9-5.8l-1.5-4.1l-2.2-6.3l-0.4-1.4
	c-0.7-2.3-1.2-4.7-1.7-7c-2.6-14.3-4.3-34.7-5.1-61.2c0.8-26.5,2.5-46.9,5.1-61.2c0.4-2.3,1-4.7,1.7-7l0.4-1.4l2.2-6.3l3.4-9.9
	c1-3.2,1.7-6.1,1.9-8.5C67.7,211.5,65.8,215.8,64.5,220.3L64.5,220.3z"
          />
          <path
            fill="none"
            d="M65.2,226l-2.2,6.3c3.3-2.6,6.6-5.1,9.8-7.3l3-1.9c0.6-2.3,1.1-4.5,1.8-6.6c-1.4,0.7-2.6,1.5-3.9,2.3
	c-1.7,1.1-3.3,2.3-5,3.6c-0.7,0.6-1.4,1.2-2.1,1.9L65.2,226L65.2,226z"
          />
          <path
            fill="none"
            d="M63,232.3l-0.4,1.4c-0.7,2.3-1.2,4.7-1.7,7c-2.6,14.3-4.3,34.7-5.1,61.2c0.8,26.5,2.5,46.9,5.1,61.2
	c0.4,2.3,1,4.7,1.7,7l0.4,1.4c3.3,2.6,6.6,5.1,9.8,7.3l3,1.9c-3.9-15.7-6.3-37.4-7.4-65.2c-0.4-4.4-0.6-9.1-0.6-13.8
	c0-4.7,0.1-9.2,0.6-13.6c1.1-27.6,3.6-49.4,7.4-65.2l-3,1.9C69.5,227.2,66.3,229.7,63,232.3L63,232.3z"
          />
          <path
            fill="none"
            d="M77.6,387.5c-0.7-2.1-1.2-4.3-1.8-6.6l-3-1.9c-3.2-2.2-6.5-4.5-9.8-7.3l2.2,6.3l1.5,1.6
	c0.7,0.7,1.2,1.2,2.1,1.9l5,3.6C75,386,76.2,386.6,77.6,387.5L77.6,387.5z M759.2,188.7c-3.9-2.1-8.1-3.6-12.5-4.7l1.1,1.4
	c1.5,2.1,3,4.8,4.5,8.3c3.6,0,6.9,0.7,9.9,2.2L759.2,188.7L759.2,188.7z"
          />
          <path
            fill="none"
            d="M746.8,184c4.4,1,8.5,2.6,12.5,4.7l2.6,1.5l-1-1.9c-2.8-5.4-7.2-8.1-13.1-8.5
	C745.3,180.2,744.9,181.5,746.8,184L746.8,184z"
          />
          <path
            fill="none"
            d="M752.5,193.8c-1.5-3.4-3-6.2-4.5-8.3l-1.1-1.4c-1.8-2.5-1.5-3.9,1.1-4.3c5.9,0.3,10.2,3.2,13.1,8.5l1,1.9
	c1.2,2.3,2.3,4.8,3.6,7.6c1.4,3.2,2.8,6.7,4,10.5l2.2,6.7c0.8,2.8,1.8,5.8,2.6,8.8l1.9,7.8l1.5,6.2c1.1,5.1,2.2,10.5,3.2,16.1
	c2.3,13.1,3.6,29.2,3.9,48.1c-0.1,19-1.5,35.1-3.9,48.3c-1.1,5.6-2.1,11-3.2,16.1l-1.5,6.2l-1.9,7.8l-2.6,8.8l-2.2,6.7
	c-1.2,3.7-2.6,7.2-4,10.5c-1.1,2.6-2.3,5.2-3.6,7.6l-1,1.9c-2.8,5.4-7.2,8.1-13.1,8.5c-2.6-0.4-3-1.8-1.1-4.3l1.1-1.4
	c1.5-2.1,3-4.8,4.5-8.4l1.9-4.7h-6.3l-0.8,1.8c-2.2,4.4-7.6,7.4-16.2,8.9c-40.3,9.2-93.7,13.6-160,13.2l-14.7-0.4l-12.2-0.8
	c-6.1-0.3-12.5-0.4-19.7-0.4l-5.9,0.1h-1l1,1.9c3,6.5,3,10.9,0.3,13.3c9.5,0,20.1,1.1,31.6,3.3c24.6,4.1,55,5.5,91.5,4
	c25.7,1,47.5-2.1,65.5-9.4c10.5-3.6,22.4-6.6,35.9-8.9c14.2-2.6,24.2-11.6,29.9-27c10.3-21.7,16.9-52.8,19.7-93.3
	c0.3-3.2,0.4-6.5,0.5-10c0-3.6-0.3-7-0.5-10.2c-2.9-40.4-9.4-71.5-19.7-93.3c-5.6-15.4-15.7-24.3-29.9-27
	c-13.5-2.5-25.3-5.5-35.9-8.9c-18-7.3-39.8-10.5-65.5-9.5c-36.5-1.6-66.9-0.3-91.5,3.9c-11.6,2.2-22.1,3.3-31.6,3.3
	c2.9,2.3,2.8,6.9-0.3,13.3l-1,1.9h1l5.9,0.1c7.2,0,13.8-0.1,19.7-0.4l12.2-0.8c5-0.4,9.8-0.6,14.7-0.4c66.4-0.3,119.8,4.1,160,13.2
	c8.7,1.6,14,4.5,16.2,8.9l0.8,1.7h6.3L752.5,193.8L752.5,193.8z"
          />
          <path
            fill="none"
            d="M754.4,198.5h-6.3c11.7,23.9,18,52,19.1,84.2l0.3,10.7l-0.1,22.4c-0.5,34.7-6.9,64.7-19.3,90.1h6.3l0.7-1.6
	c1.1-3.2,2.3-6.7,3.6-10.6l1.8-5.9c1.1-3.7,2.2-7.8,3.3-12.1l1.7-6.5l1.7-7.2c2.2-9.9,3.7-22.1,4.5-36.6c0.3-4.5,0.4-9.2,0.5-14.2
	v-16.6l-0.4-12.1c-0.7-16.1-2.3-29.4-4.7-40.2l-1.7-7.2l-1.7-6.5c-1.1-4.3-2.2-8.4-3.3-12.1l-1.8-5.9c-1.2-3.9-2.5-7.4-3.6-10.6
	L754.4,198.5L754.4,198.5z"
          />
          <path
            fill="none"
            d="M755.1,200.1c1.1,3.2,2.3,6.6,3.6,10.6c3.6,0.4,7,1.4,10.3,2.9l-2.6-7.7C762.9,203.4,759.1,201.5,755.1,200.1
	L755.1,200.1z"
          />
          <path
            fill="none"
            d="M754.4,198.5l0.7,1.8c4,1.4,7.8,3.2,11.3,5.6c1.1,0.7,2.1,1.5,3,2.3c-1.2-3.7-2.6-7.2-4-10.5
	c-1-0.7-2.1-1.2-3-1.8c-3-1.5-6.3-2.2-9.9-2.2L754.4,198.5L754.4,198.5z"
          />
          <path
            fill="none"
            d="M766.4,205.7l2.6,7.7c1,0.4,1.8,0.8,2.6,1.4l-2.2-6.7C768.4,207.3,767.5,206.6,766.4,205.7L766.4,205.7z
	 M762.4,196c1.1,0.6,2.1,1.1,3,1.8c-1.1-2.6-2.3-5.2-3.6-7.6l-2.6-1.5L762.4,196L762.4,196z"
          />
          <path
            fill="none"
            d="M771.7,214.8c-0.8-0.6-1.8-1-2.6-1.4c-3.3-1.5-6.7-2.5-10.3-2.9l1.8,5.9c4.4,1.4,8.4,3.2,11.7,5.4l2.2,1.6
	C773.4,220.6,772.6,217.7,771.7,214.8z M748.1,198.3l-22.6-0.1c-13.8-0.6-24.5,0.6-32.3,3.3c-8.1,4.3-13.9,10.3-17.5,18.3
	c5.9,2.5,11.3,8,16.1,16.2c5.8,12.7,9.4,27.9,10.9,45.9l0.3,4c0.3,3.9,0.4,7.7,0.4,11.7c0.1,6.1,0,12-0.3,18l-0.1,3.2
	c0,1.1-0.1,2.1-0.3,3.2c-1.5,18-5.1,33.4-10.9,45.9c-4.8,8.4-10.2,13.8-16.1,16.2c3.6,8,9.4,14,17.5,18.3c7.8,2.8,18.6,3.9,32.3,3.3
	l22.6-0.1c12.4-25.5,18.8-55.4,19.3-90.1l0.1-22.4l-0.3-10.6C766.2,250.3,759.8,222.3,748.1,198.3L748.1,198.3z"
          />
          <path
            fill="none"
            d="M774.2,223.6L772,222l2.1,8.4c0.7,0.3,1.5,0.7,2.2,1.1L774.2,223.6L774.2,223.6z"
          />
          <path
            fill="none"
            d="M774.1,230.4L772,222c-3.3-2.2-7.2-4-11.7-5.4c1.1,3.7,2.2,7.8,3.3,12.1C767.7,228.7,771.2,229.3,774.1,230.4
	L774.1,230.4z"
          />
          <path
            fill="none"
            d="M776.3,231.5c-0.7-0.4-1.4-0.7-2.2-1.1c-2.9-1.1-6.3-1.8-10.3-1.7l1.7,6.5c5.4,0.1,9.5,1,12.4,2.5L776.3,231.5
	L776.3,231.5z"
          />
          <path
            fill="none"
            d="M777.6,237.7c-2.9-1.5-7-2.3-12.4-2.5l1.7,7.2c2.3,10.7,4,24.1,4.7,40.2l0.4,11.7l0.1-0.3
	c0.8-1.9,1.9-2.9,3.2-2.9h0.3c1.1,0.1,2.1,1.1,2.9,2.8c0.8,1.9,1.4,4.1,1.4,6.7c0,2.6-0.4,5-1.4,6.9c-0.8,1.6-1.8,2.6-2.9,2.8h-0.3
	c-1.2,0-2.3-1-3.2-2.8v-0.1v3.4c-0.1,5-0.3,9.6-0.5,14.2c-0.8,14.4-2.3,26.7-4.5,36.6l-1.7,7.2c5.4-0.1,9.5-1,12.4-2.5
	c1.1-5.1,2.2-10.5,3.2-16.1c2.3-13.2,3.6-29.3,3.8-48.3c-0.1-19-1.5-35.1-3.8-48.1C779.8,248.1,778.9,242.8,777.6,237.7L777.6,237.7
	z"
          />
          <path
            fill="none"
            d="M775.6,291h-0.3c-1.2,0-2.3,1-3.2,2.9l-0.1,0.3v1.2l1-1.9c0.7-1.1,1.5-1.5,2.3-1.6c1.1,0,2.1,0.8,2.9,2.6
	c0.8,1.6,1.2,3.7,1.2,6.2c0,2.5-0.4,4.5-1.2,6.2c-0.8,1.6-1.8,2.6-2.9,2.6c-0.8,0-1.7-0.6-2.3-1.5c-0.1-0.3-0.4-0.5-0.5-1l-0.4-0.8
	v1.4c0.8,1.9,1.9,2.8,3.2,2.8h0.3c1.1-0.1,2.1-1.1,2.9-2.8c0.8-1.9,1.4-4.1,1.4-6.9c0-2.6-0.4-5-1.4-6.7
	C777.6,292.1,776.8,291.3,775.6,291L775.6,291z"
          />
          <path
            fill="none"
            d="M773,293.6l-1,1.9v10.6l0.4,0.8c0.1,0.4,0.4,0.7,0.5,1c0.7,1.1,1.5,1.7,2.3,1.5c1.1,0,2.1-0.8,2.9-2.6
	c0.8-1.8,1.2-3.9,1.2-6.2c0-2.5-0.4-4.5-1.2-6.2c-0.8-1.6-1.8-2.6-2.9-2.6C774.5,292,773.7,292.6,773,293.6L773,293.6z M774.5,294.2
	v2.9h1.4v1.1h-1.4v8.3h0.8v-4.3h-0.1v2.2H775v-2.6h0.4l0.1,0.1v4.8l-0.1,0.1h-1c0,0-0.1-0.1-0.1-0.3v-8.8h1.5v-0.3h-1.5v-3.3h-0.1
	v12.9c0,0.1,0.1,0.1,0.1,0.1h1l0.1-0.1l0.1-0.4v-4.5l-0.1-0.4l-0.1-0.1h-0.5c-0.1,0-0.1,0.1-0.1,0.4v2.6h-0.1v-2.9
	c0-0.3,0-0.4,0.1-0.4h0.5l0.3,0.3l0.1,0.5v5.1c0,0.3-0.1,0.5-0.1,0.7h-1.4c-0.1,0-0.3-0.3-0.3-0.5v-13.1h0.3L774.5,294.2
	L774.5,294.2z"
          />
          <path fill="none" d="M774.5,297.1v-2.9h-0.1v3.3h1.5v-0.4" />
          <path
            fill="none"
            d="M774.2,294.2h-0.1v13.1c0,0.3,0.1,0.5,0.3,0.5h1.4c0.1-0.1,0.1-0.3,0.1-0.7V302l-0.1-0.5l-0.3-0.3h-0.5
	c-0.1,0-0.1,0.1-0.1,0.4v2.9h0.1v-2.6c0-0.1,0-0.3,0.1-0.4h0.5l0.1,0.1l0.1,0.4v4.5l-0.1,0.4l-0.1,0.1h-1.1c0,0-0.1,0-0.1-0.1v-0.4
	L774.2,294.2L774.2,294.2z"
          />
          <path
            fill="none"
            d="M775.9,298.3v-0.4h-1.5v8.8c0,0.1,0,0.3,0.1,0.3h1l0.1-0.1V302l-0.1-0.1H775v2.6h0.1v-2.2h0.1v4.3h-0.8v-8.3
	L775.9,298.3L775.9,298.3z M763.8,375.4c4,0,7.4-0.5,10.3-1.6l2.2-1.1l1.5-6.2c-2.9,1.5-7,2.3-12.4,2.5L763.8,375.4L763.8,375.4z"
          />
          <path
            fill="none"
            d="M774.1,373.6c-2.9,1.1-6.3,1.6-10.3,1.6c-1.1,4.3-2.2,8.4-3.3,12.1c4.4-1.4,8.4-3.2,11.6-5.4L774.1,373.6
	L774.1,373.6z"
          />
          <path fill="none" d="M776.3,372.6l-2.2,1L772,382l2.2-1.6" />
          <path
            fill="none"
            d="M771.7,389.1l2.6-8.8l-2.2,1.6c-3.3,2.2-7.2,4-11.7,5.4l-1.8,5.9c3.6-0.4,7-1.4,10.3-2.8L771.7,389.1
	L771.7,389.1z M765.4,406.3c1.4-3.3,2.8-6.7,4-10.5c-1,0.8-2.1,1.6-3,2.3c-3.6,2.5-7.3,4.3-11.3,5.6l-0.7,1.6l-1.9,4.7
	c3.6,0,6.9-0.7,9.9-2.2C763.5,407.6,764.4,407,765.4,406.3L765.4,406.3z"
          />
          <path
            fill="none"
            d="M765.4,406.3c-1,0.7-2.1,1.2-3,1.8l-3.2,7.3l2.6-1.5C763.2,411.5,764.3,408.9,765.4,406.3L765.4,406.3z
	 M769,390.5l-2.6,7.7c1.1-0.7,2.1-1.5,3-2.3l2.2-6.7L769,390.5L769,390.5z"
          />
          <path
            fill="none"
            d="M766.4,398.2l2.6-7.7c-3.3,1.5-6.7,2.5-10.3,2.8c-1.2,3.9-2.5,7.4-3.6,10.6
	C759.1,402.6,762.9,400.7,766.4,398.2L766.4,398.2z M747.9,424.2c5.9-0.4,10.2-3.2,13.1-8.5l1-1.9l-2.6,1.5
	c-3.8,2.1-8.1,3.6-12.5,4.7C744.9,422.4,745.3,423.8,747.9,424.2L747.9,424.2z"
          />
          <path
            fill="none"
            d="M746.8,419.9c4.4-1,8.5-2.6,12.5-4.7l3.2-7.3c-3,1.5-6.3,2.2-9.9,2.2c-1.5,3.4-3,6.2-4.5,8.4L746.8,419.9
	L746.8,419.9z M594.8,197.1c3.7-0.6,4.3-1.2,1.8-2.3c-5.5-3.2-12.9-5.5-22.3-6.9c-13.5-1.9-27.1-3.4-41-4.4c3.7,4.1,7.4,9.5,11,16
	L594.8,197.1L594.8,197.1z M568.5,283.5c0.1,5,0.3,10,0.1,15.3v6.7c0,5.2,0,10.3-0.1,15.3c-0.3,8.8-0.7,17.1-1.5,25.2
	c4.7-3.3,7.3-10.5,7.7-21.5v-44.7c-0.4-11.1-3-18.3-7.7-21.5C567.9,266.3,568.3,274.7,568.5,283.5L568.5,283.5z"
          />
          <path
            fill="none"
            d="M567.2,258.3c4.7,3.2,7.3,10.5,7.7,21.5v44.4c-0.4,11.1-3,18.3-7.7,21.5c-1.5,16.6-4.1,31.5-7.7,44.8h4.5
	c34.5-0.8,70-2.5,106.3-4.8c1.9-0.1,3.9-0.7,5.6-1.5c5.9-2.5,11.3-7.8,16.1-16.2c5.8-12.7,9.4-27.9,10.9-45.9
	c0.1-1.1,0.1-2.1,0.3-3.2l0.1-3.2c0.3-5.9,0.4-12,0.3-18c0-3.9-0.3-7.7-0.4-11.7l-0.3-4c-1.5-18-5.1-33.4-10.9-45.9
	c-4.8-8.4-10.2-13.8-16.1-16.2c-1.8-0.8-3.7-1.2-5.6-1.5c-36.3-2.5-71.7-4.1-106.3-4.8h-4.5C562.9,226.8,565.5,241.7,567.2,258.3
	L567.2,258.3z M575.6,235.9c-1.1-6.3,2.5-10.2,10.7-11.7c14.4-0.7,32.2-0.1,53.4,1.7l24.5,2.1c10.2,1.6,17.1,6.9,20.6,15.4
	c4.8,9.1,7.8,21.7,9.1,38l0.3,3.3l0.5,18.3c0,4.3-0.1,8.5-0.3,13.2L694,323c-1.4,16.2-4.4,28.9-9.1,38c-3.6,8.7-10.5,13.9-20.6,15.5
	l-24.5,2.1c-21.2,1.7-38.9,2.2-53.4,1.5c-8.3-1.5-11.8-5.4-10.7-11.7l1.7-16.2c0.3-3,1.1-5.5,2.3-7.4l0.1-0.1
	c2.6-4.1,4.1-10.7,4.5-19.9v-44.8c-0.3-9.2-1.8-15.8-4.5-19.9l-0.1-0.1c-1.2-1.9-2.1-4.4-2.3-7.4L575.6,235.9L575.6,235.9z"
          />
          <path
            fill="none"
            d="M586.3,224.2c-8.3,1.5-11.8,5.4-10.7,11.7l1.7,16.1c0.3,3,1.1,5.5,2.3,7.4l0.1,0.1c2.6,4.1,4.1,10.7,4.5,19.9
	v44.8c-0.3,9.2-1.8,15.8-4.5,19.9l-0.1,0.1c-1.2,1.9-2.1,4.4-2.3,7.4l-1.7,16.2c-1.1,6.3,2.5,10.2,10.7,11.7
	c14.4,0.7,32.2,0.1,53.4-1.5l24.5-2.1c10.2-1.6,17.1-6.9,20.6-15.5c4.8-9.1,7.8-21.7,9.1-38l0.4-6.9c0.1-4.5,0.3-8.9,0.3-13.2
	l-0.5-18.3l-0.3-3.3c-1.4-16.2-4.4-28.9-9.1-38c-3.6-8.7-10.5-13.9-20.6-15.4l-24.5-2.1C618.5,224,600.7,223.5,586.3,224.2
	L586.3,224.2z M498.1,164.6c7.4,0.8,11.8,0.4,13.3-1.5c-1.5-1-3.2-1.8-5.1-2.6c-3.4-1.2-7.4-1.9-12.2-2.2
	c-7.7-0.1-13.8,0.6-18.2,2.2c-1,0.3-1.6,0.7-2.5,1.1c-2.1,0.8-1.4,1.5,2.1,2.1l7,0.6c0.4-2.1,1.5-3.3,3.3-3.7l2.1-0.1h5.4l1,0.1
	C496.9,161,498.1,162.4,498.1,164.6L498.1,164.6z M517.5,175.9h1 M514.3,182.2c4.7,5,8.9,10.9,12.8,17.9l17.3-0.8
	c-3.7-6.5-7.4-11.8-11-16L514.3,182.2L514.3,182.2z"
          />
          <path
            fill="none"
            d="M485.9,160.5c-1.8,0.4-2.9,1.6-3.3,3.7l15.5,0.3c0-2.2-1.2-3.6-3.9-4L485.9,160.5L485.9,160.5z"
          />
          <path
            fill="none"
            d="M488.1,160.4l-2.2,0.1h8.5l-1-0.1 M485.9,443.5h8.4c2.5-0.5,3.9-1.8,3.9-4l-15.5,0.3
	C483,441.8,484.1,443,485.9,443.5L485.9,443.5z M494.4,443.5h-8.5l7.6,0.1"
          />
          <path
            fill="none"
            d="M485.9,443.5c-1.8-0.4-2.9-1.7-3.3-3.7l-7,0.5c-3.3,0.5-4,1.2-2.1,2.1c0.7,0.4,1.5,0.8,2.5,1.1
	c4.4,1.6,10.5,2.3,18.2,2.2c4.8-0.3,8.9-1.1,12.2-2.2c1.9-0.7,3.6-1.5,5.1-2.6c-1.5-1.8-5.9-2.3-13.3-1.5c0,2.2-1.2,3.6-3.7,4
	l-1,0.1L485.9,443.5L485.9,443.5L485.9,443.5z M533.3,420.6c3.7-4.1,7.4-9.5,11-16l-17.3-0.8c-3.9,7-8.1,12.9-12.8,17.9L533.3,420.6
	L533.3,420.6z"
          />
          <path
            fill="none"
            d="M544.5,404.7c-3.7,6.5-7.4,11.8-11,16c13.9-1.1,27.5-2.5,41-4.4c9.4-1.4,16.8-3.6,22.3-6.9
	c2.5-1.1,1.9-1.8-1.8-2.3L544.5,404.7L544.5,404.7z M517.5,428.2h1l-1-0.1 M305.1,159.4l-19.5-0.3c-6.6,0.1-13.2-0.3-19.7-1.2
	l-17.9-1.1c-27.8-0.6-56.1,0-85.3,1.6l-4.7,0.1l-11.4,6.9h-0.3c-11.3,2.8-20.4,5.9-27.4,9.5c-6.5,3-12.1,6.9-16.6,11.4
	c24.9-8.9,82.5-14.2,172.8-15.5c9.1,0,16.1,2.2,21.2,6.9l25.6-1.4C313.5,170.7,307.9,165,305.1,159.4L305.1,159.4z"
          />
          <path
            fill="none"
            d="M296.3,177.8c-5.1-4.7-12.2-7-21.2-6.9c-90.2,1.4-147.9,6.6-172.8,15.5c-0.6,0.6-1.1,1.1-1.7,1.8l-8.8,9.5
	l-12.2,13.1c-0.7,1.9-1.4,3.9-2.1,6.1C112.1,197.2,185,184.3,296.3,177.8L296.3,177.8z"
          />
          <path
            fill="none"
            d="M100.6,188.1c0.6-0.6,1.1-1.2,1.7-1.8c4.5-4.7,10.2-8.4,16.6-11.4c7-3.4,16.1-6.6,27.4-9.5
	c-17.3,1.5-31.9,7.6-43.9,18.2c-5.8,5.2-9.4,9.8-10.6,13.9L100.6,188.1L100.6,188.1z M158.1,445.5l4.7,0.1c29,1.7,57.5,2.2,85.3,1.7
	l17.9-1.1c6.6-1,13.2-1.4,19.7-1.2l19.5-0.3c2.8-5.6,8.4-11.3,16.6-17.1l-25.6-1.4c-5.1,4.7-12.2,7-21.2,6.9
	c-90.2-1.4-147.7-6.5-172.5-15.1c4.5,4.4,10,8.1,16.4,11.1c7,3.6,16.1,6.7,27.4,9.5h0.3L158.1,445.5L158.1,445.5z"
          />
          <path
            fill="none"
            d="M275,433.3c9.1,0,16.1-2.2,21.2-6.9C185,419.9,112.1,407,77.4,387.6c0.7,2.1,1.4,4.1,2.1,5.9l12.2,13.1l8.8,9.4
	l2.1,2.1C127.2,426.8,184.8,431.8,275,433.3L275,433.3z"
          />
          <path
            fill="none"
            d="M102.6,418c-0.7-0.7-1.4-1.4-2.1-2.1l-8.8-9.4c1.2,4,4.8,8.7,10.6,13.9c11.8,10.7,26.5,16.8,43.9,18.2
	c-11.3-2.8-20.4-5.9-27.4-9.5C112.5,426.3,107.2,422.5,102.6,418L102.6,418z M332.9,425.4l1.1,0.1c40.2,3.4,98.4,3.3,174.6-0.1
	l7.4-0.3l-1.7-3.3l-5.8,0.3c-70.4,3.3-125.5,3.6-165.1,1h-0.7L332.9,425.4L332.9,425.4z"
          />
          <path
            fill="none"
            d="M332.8,425.4h0.1l9.8-2.6l0.7-0.1c27.8-7,54.5-12.5,79.9-16.5c3-0.5,6.1-0.8,9.1-1.1c7.2-0.8,14.3-1.4,21.3-1.5
	c22.3-1,46.6-0.8,73.3,0.4l17.3,0.8l0.1-0.1l-0.1,0.1l50.3,2.3c3.7,0.5,4.3,1.2,1.8,2.3c-5.5,3.3-12.9,5.5-22.3,6.9
	c-13.5,1.9-27.1,3.3-41,4.4l-19.1,1.2l1.7,3.3c18.2-0.8,37.6-2.8,58.2-5.5c15.3-1.8,25.7-5.5,31.1-11.1c1.9-1.6,1.1-2.6-2.6-2.8
	l-61.6-2.9c-32.1-1.8-61.2-2.1-87.2-1.1l-9.5,0.4c-7,0.3-14,1-21,2.3c-27.1,4.1-55.7,10-85.6,17.9l-9.1,2.5c-0.1,0-1.2,0.3-3.6,0.8
	l12.2,0.5l-12.2-0.5l-3.3,2.2l14.6,0.7c0.4-0.8,1.4-1.1,3.2-1l13.2,0.5c1.5,0,2.5,0.4,3.2,1.2l0.5,1c38.4,1.8,89.1,1.2,152-1.6
	c2.3-0.1,5.4-0.1,9.1-0.1l-1.7-3l-7.4,0.3c-76.1,3.6-134.3,3.6-174.6,0.1L332.8,425.4L332.8,425.4z"
          />
          <path
            fill="none"
            d="M332.9,425.4h-0.1l1.2,0.1 M321.8,427.6l3.3-2.2l-26.7-1.4l-2.1,2.2"
          />
          <path
            fill="none"
            d="M303.3,418.8c-0.4-0.3-0.8-0.4-1.1-0.7c-1.1,2.3-2.3,4.3-3.9,6.1l26.8,1.4c-5-0.5-10-1.8-15.3-3.7
	c-1.6-0.5-3.2-1.2-4.8-2.1L303.3,418.8L303.3,418.8z"
          />
          <path
            fill="none"
            d="M303.3,185.2c-0.4,0.3-0.8,0.4-1.1,0.7c-4.1,2.5-7.8,5.6-11,9.4c-6.2,6.9-11.4,15-15.8,24.2
	c-0.5,1.2-0.8,2.6-1,3.9c-0.4,3.3,0.3,6.9,2.1,10.5c1.1,1.6,1.8,3.6,2.1,5.9c0.1,1.9,0,4.1-0.4,6.5c-2.9,16.8-4.3,35.4-4,55.9
	c-0.3,20.5,1.1,39.1,4,55.9c0.4,2.3,0.5,4.5,0.4,6.3c-0.3,2.3-1,4.3-2.1,5.9c-1.8,3.6-2.5,7.2-2.1,10.5c0.1,1.4,0.5,2.6,1,3.9
	c4.3,9.2,9.6,17.3,15.8,24.2c3.2,3.7,6.9,6.7,11,9.2c0.4,0.3,0.7,0.6,1.1,0.7l1.6,1c1.5,0.8,3.2,1.5,4.8,2.1l80.6-18.8
	c6.1-1.9,8.7-6.9,8.1-14.9c-2.9-20.5-4.8-42.2-5.6-65.2l-0.3-21.2l0.3-20.9c1-22.8,2.8-44.4,5.6-64.8c0.7-7.8-2.1-12.8-8.1-14.9
	l-80.6-18.8c-1.6,0.6-3.2,1.2-4.8,2.1L303.3,185.2L303.3,185.2z M308.4,204.5c0.1-0.4,0.3-0.8,0.5-1.1c3.6-7.4,10.5-10.6,20.8-9.6
	c16.9,1.8,33.8,5.2,50.6,10.3c7.3,2.2,10.5,7.7,9.5,16.6l-4.5,51.6c-0.7,4-2.5,6.9-5.4,8.5c-3.4,2.3-4.8,6.7-4.3,13.1v16.5
	c-0.5,6.3,0.8,10.7,4.3,13.1c2.9,1.6,4.8,4.5,5.4,8.5l4.5,51.6c1.1,8.9-2.1,14.4-9.5,16.6c-16.9,5.1-33.8,8.5-50.6,10.3
	c-10.3,1-17.3-2.3-20.8-9.6l-0.7-1.5c-9.6-21.9-14.9-49.5-15.7-82.8c-0.1-4.8-0.3-9.6-0.3-14.4s0.1-9.5,0.3-14.3
	c1-33.3,6.2-60.8,15.7-82.7L308.4,204.5L308.4,204.5z"
          />
          <path
            fill="none"
            d="M309,203.4c-0.1,0.4-0.4,0.7-0.5,1.1l-0.1,0.4c-9.6,21.9-14.9,49.4-15.7,82.7c-0.1,4.8-0.3,9.5-0.3,14.3
	c0,4.8,0.1,9.6,0.3,14.4c0.8,33.3,6.1,60.8,15.7,82.8l0.7,1.5c3.6,7.4,10.5,10.6,20.8,9.6c16.9-1.8,33.8-5.2,50.6-10.3
	c7.3-2.2,10.5-7.7,9.5-16.6l-4.5-51.6c-0.7-4-2.5-6.9-5.4-8.5c-3.4-2.3-4.8-6.7-4.3-13.1v-16.5c-0.5-6.3,0.8-10.7,4.3-13.1
	c2.9-1.6,4.8-4.5,5.4-8.5l4.5-51.6c1.1-8.9-2.1-14.4-9.5-16.6c-16.9-5.1-33.8-8.5-50.6-10.3C319.4,192.8,312.4,196,309,203.4
	L309,203.4z M302.2,185.9c0.4-0.3,0.7-0.6,1.1-0.7l1.6-1c1.5-0.8,3.2-1.5,4.8-2.1c5-1.8,10-3,15.5-3.6h-0.3l-26.8,1.4
	C299.9,181.7,301.1,183.7,302.2,185.9z"
          />
          <path
            fill="none"
            d="M296.3,177.8c0.7,0.7,1.4,1.4,2.1,2.2l26.8-1.4l-3.3-2.2L296.3,177.8z M328.6,179.3l-3.2-0.7
	c-5.5,0.6-10.6,1.8-15.5,3.6l80.6,18.8c6.1,1.9,8.7,6.9,8.1,14.9c-2.9,20.5-4.8,42.1-5.6,64.8l-0.3,20.9l0.3,21.2
	c0.8,22.8,2.8,44.6,5.6,65.2c0.7,7.8-2.1,12.8-8.1,14.9l-80.6,18.8c5.2,1.9,10.2,3.2,15.3,3.7c2.2-0.5,3.4-0.7,3.6-0.8l9.1-2.5
	c29.9-7.7,58.3-13.8,85.6-17.9c7-1.2,14-2.1,21-2.3l9.5-0.4c26.1-1.1,55.2-0.7,87.2,1.1l61.6,2.9c3.6,0.1,4.4,1.1,2.6,2.8
	c-5.4,5.8-15.8,9.5-31.1,11.1c-20.6,2.8-40,4.7-58.2,5.5l1.7,3h1l5.9-0.1c7.2,0,13.8,0.1,19.7,0.4l12.2,0.8l14.7,0.4
	c66.4,0.3,119.8-4.1,160-13.2l-2.6,0.4l2.6-0.4c8.7-1.6,14-4.5,16.2-8.9l0.8-1.8l-22.6,0.1c-13.8,0.5-24.5-0.6-32.3-3.3
	c-8.1-4.3-13.9-10.3-17.5-18.3c-1.8,0.8-3.7,1.2-5.6,1.5c-36.3,2.5-71.7,4.1-106.3,4.8h-4.5c-65.3,0.5-119.1,2.6-161.4,6.2
	c42.4-3.7,96.2-5.8,161.4-6.2c3.6-13.3,6.2-28.3,7.7-44.8c0.7-8,1.2-16.4,1.5-25.2c0.1-5,0.3-10,0.1-15.3v-6.7
	c0-5.2,0-10.3-0.1-15.3c-0.3-8.8-0.7-17.1-1.5-25c-1.5-16.6-4.1-31.5-7.7-44.8c-65.3-0.6-119.1-2.6-161.4-6.3
	c42.4,3.7,96.2,5.8,161.4,6.3h4.5c34.5,0.8,70,2.5,106.3,4.8c1.9,0.1,3.9,0.7,5.6,1.5c3.6-8,9.4-14,17.5-18.3
	c7.8-2.8,18.6-3.9,32.3-3.3l22.6,0.1l-0.8-1.6c-2.2-4.4-7.6-7.4-16.2-8.9l-2.6-0.4l2.6,0.4c-40.3-9.2-93.7-13.6-160-13.2
	c-5-0.1-9.8,0-14.7,0.4l-12.2,0.8c-6.1,0.3-12.5,0.4-19.7,0.4l-5.9-0.1h-1l-1.7,3c18.2,0.8,37.6,2.8,58.2,5.5
	c15.3,1.6,25.7,5.5,31.1,11.3c1.9,1.6,1.1,2.6-2.6,2.8l-61.8,3c-32.1,1.6-61.2,2.1-87.2,1.1l-9.5-0.4c-7-0.3-14-1-21-2.3
	c-27.1-4.1-55.7-10-85.6-17.9L328.6,179.3L328.6,179.3z"
          />
          <path
            fill="none"
            d="M356.4,174.1l-0.5,1c-0.7,0.7-1.6,1.1-3.2,1.2l-13.2,0.6c-1.6,0.1-2.8-0.1-3.2-1l-14.6,0.7l3.3,2.2h1.1l6.7,0.1
	h-0.1l1.2-0.1c40.2-3.4,98.4-3.3,174.6,0.1l7.4,0.3l1.7-3l-9.1-0.1C445.6,172.9,394.8,172.3,356.4,174.1L356.4,174.1z"
          />
          <path
            fill="none"
            d="M343.4,181.4l-0.7-0.1l-9.8-2.6l-6.7-0.1c-0.3,0-0.5,0.1-0.8,0.1l3.2,0.7l9.1,2.5c29.9,7.7,58.3,13.8,85.6,17.9
	c7,1.2,14,2.1,21,2.3l9.5,0.4c26.1,1.1,55.2,0.7,87.2-1.1l61.6-2.9c3.6-0.1,4.4-1.1,2.6-2.8c-5.4-5.8-15.8-9.5-31.1-11.3
	c-20.6-2.8-40-4.7-58.2-5.5l-1.7,3.3l19.1,1.1c13.9,1.1,27.5,2.5,41,4.4c9.4,1.4,16.8,3.6,22.3,6.9c2.5,1.1,1.9,1.8-1.8,2.3
	l-50.3,2.3l0.1,0.1l-0.1-0.1l-17.3,0.8c-26.5,1.1-51,1.2-73.3,0.3c-7.1-0.1-14.3-0.7-21.3-1.5l-9.1-1.1
	C397.8,193.9,371.1,188.4,343.4,181.4L343.4,181.4z"
          />
          <path
            fill="none"
            d="M342.7,181.1h0.7c39.6-2.6,94.8-2.3,165.1,1l5.8,0.3l1.7-3.3l-7.4-0.3c-76.1-3.6-134.3-3.6-174.6-0.1l-1.1,0.1
	L342.7,181.1L342.7,181.1z M325.5,178.6c0.3,0,0.5,0,0.8-0.1h-1.1L325.5,178.6L325.5,178.6z M332.9,178.6l1.1-0.1l-1.2,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M775.9,297.5h-1.5v-3.3 M775.9,297.8h-1.5v8.8c0,0.1,0,0.3,0.1,0.3h1
	l0.1-0.1v-4.8l-0.1-0.1H775v2.6h0.1v-2.2h0.1v4.3h-0.8v-8.3h1.4L775.9,297.8L775.9,297.8z M775.9,297.8v-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M774.2,294.2v12.9c0,0.1,0.1,0.1,0.1,0.1h1l0.1-0.1l0.1-0.4v-4.5l-0.1-0.4
	l-0.1-0.1h-0.5c-0.1,0-0.1,0.1-0.1,0.4v2.6h-0.1v-2.9c0-0.3,0-0.4,0.1-0.4h0.5l0.3,0.3l0.1,0.5v5.1c0,0.3,0,0.5-0.1,0.7h-1.5
	c-0.1,0-0.3-0.3-0.3-0.5v-13.1h0.5L774.2,294.2L774.2,294.2z M774.3,294.2h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M775.9,297.5v-0.4h-1.4v-2.9h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M567.2,345.8c4.7-3.3,7.3-10.5,7.7-21.5v-44.6c-0.4-11.1-3-18.3-7.7-21.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M772.1,306.2l0.4,0.8
	c0.1,0.4,0.4,0.7,0.5,1c0.7,1.1,1.5,1.7,2.3,1.5c1.1,0,2.1-0.8,2.9-2.6c0.8-1.8,1.2-3.9,1.2-6.2c0-2.5-0.4-4.5-1.2-6.2
	c-0.8-1.6-1.8-2.6-2.9-2.6c-0.8,0-1.7,0.5-2.3,1.6l-1,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M308.4,204.5l-0.1,0.4c-9.6,21.9-14.9,49.4-15.7,82.7c-0.1,4.8-0.3,9.5-0.3,14.3c0,4.8,0.1,9.6,0.3,14.4
	c0.8,33.3,6.1,60.8,15.7,82.8l0.7,1.5c3.6,7.4,10.5,10.6,20.8,9.6c16.9-1.8,33.8-5.2,50.6-10.3c7.3-2.2,10.5-7.7,9.5-16.6l-4.5-51.6
	c-0.7-4-2.5-6.9-5.4-8.5c-3.4-2.3-4.8-6.7-4.3-13.1v-16.5c-0.5-6.3,0.8-10.7,4.3-13.1c2.9-1.6,4.8-4.5,5.4-8.5l4.5-51.6
	c1.1-8.9-2.1-14.4-9.5-16.6c-16.9-5.1-33.8-8.5-50.6-10.3c-10.3-1-17.3,2.3-20.8,9.6C308.7,203.8,308.6,204.1,308.4,204.5
	L308.4,204.5z M575.6,235.9l1.7,16.1c0.3,3,1.1,5.5,2.3,7.4l0.1,0.1c2.6,4.1,4.1,10.7,4.5,19.9v44.8c-0.3,9.2-1.8,15.8-4.5,19.9
	l-0.1,0.1c-1.2,1.9-2.1,4.4-2.3,7.4l-1.7,16.2c-1.1,6.3,2.5,10.2,10.7,11.7c14.4,0.7,32.2,0.1,53.4-1.5l24.5-2.1
	c10.2-1.6,17.1-6.9,20.6-15.5c4.8-9.1,7.8-21.7,9.1-38l0.4-6.9c0.1-4.5,0.3-8.9,0.3-13.2l-0.5-18.3l-0.3-3.3
	c-1.4-16.2-4.4-28.9-9.1-38c-3.6-8.7-10.5-13.8-20.6-15.4l-24.5-2.1c-21.2-1.6-38.9-2.2-53.4-1.6C578,225.7,574.5,229.5,575.6,235.9
	L575.6,235.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M369.6,160.4l7.8-17.1c0.3-1-0.3-1.6-1.8-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M375.8,141.2L370,141c-5.8,0.1-9.9,1.9-12.5,5.4l-10.6,13.8 M369.6,160.4
	l-3.2,6.7c-0.8,1.1-1.9,1.8-3.3,1.8h-0.7 M358.2,168.6l4.4,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M476,160.5h-96.2l-10.2-0.1 M362.6,168.9l13.2-27.6 M356.4,174.1
	c0.1-0.4,0.1-0.8,0.3-1.2v-0.4l1.6-3.9 M336.3,175.8c0.4,0.8,1.4,1.1,3.2,1l13.2-0.6c1.5,0,2.5-0.4,3.2-1.2l0.5-1 M344.3,163.5
	l2.6-3.4 M358.2,168.6l-9.5-0.7c-3.7-0.1-5.2-1.5-4.5-4.3 M305.1,159.4l-19.5-0.3c-6.6,0.1-13.2-0.3-19.7-1.2l-17.9-1.1
	c-27.8-0.6-56.1,0-85.3,1.6l-4.7,0.1 M309.9,182.2c-1.6,0.6-3.2,1.2-4.8,2.1l-1.6,1c-0.4,0.3-0.8,0.4-1.1,0.7 M298.4,179.9
	c-0.7-0.8-1.4-1.5-2.1-2.2 M302.2,185.9c-1.1-2.3-2.3-4.3-3.9-6.1 M274.6,223.4c0.1-1.4,0.5-2.6,1-3.9c4.3-9.2,9.6-17.3,15.8-24.2
	c3.2-3.7,6.9-6.7,11-9.4 M342.7,181.1l0.7,0.1c27.8,7,54.5,12.5,79.9,16.5l9.1,1.1c7.2,0.8,14.3,1.4,21.3,1.5
	c22.3,1,46.6,0.8,73.3-0.3 M514.3,182.2l-5.8-0.3c-70.4-3.3-125.5-3.6-165.1-1h-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M344.3,163.5l-8,10.6c-0.1,0.6-0.1,1,0,1.2l0.1,0.4 M332.9,178.6l-6.7-0.1
	 M326.2,178.5c-0.3,0-0.5,0.1-0.8,0.1 M516,178.9c18.2,0.8,37.6,2.8,58.2,5.5c15.3,1.6,25.7,5.5,31.1,11.1c1.9,1.6,1.1,2.6-2.6,2.8
	l-61.6,3c-32.1,1.6-61.2,2.1-87.2,1.1l-9.5-0.4c-7-0.3-14-1-21-2.3c-27.1-4.1-55.7-10-85.6-17.9l-9.1-2.5l-3.2-0.7 M332.9,178.6
	l1.1-0.1 M334,178.5l-1.2,0.1h0.1 M325.1,178.6l-3.3-2.2 M326.2,178.5h-1.1 M325.5,178.6h-0.3 M321.8,176.4l14.6-0.7 M332.9,178.6
	l9.8,2.6 M325.5,178.6c-5.5,0.6-10.6,1.8-15.5,3.6 M346.9,160.1l-42-0.7 M298.4,179.9l26.8-1.4 M296.3,177.8l25.6-1.4 M321.8,176.4
	c-8.4-5.6-13.9-11.4-16.6-17.1 M309.9,182.2l80.6,18.8c6.1,1.9,8.7,6.9,8.1,14.9c-2.9,20.5-4.8,42.1-5.6,64.8l-0.3,20.9l0.3,21.2
	c0.8,22.8,2.8,44.6,5.6,65.2c0.7,7.8-2.1,12.8-8.1,14.9l-80.6,18.8 M158.1,158.4l-11.4,6.9h-0.3 M146.4,209.5l1.8,0.1 M136.5,228.9
	c-1.6-0.7-2.1-1.6-1.1-3.2l6.1-11.4c1.6-2.8,3.3-4.3,5-4.5 M172.8,201.8c-16.6,3.2-31.2,6.6-43.7,10.3c-7.7,2.2-12.5,5.8-14.9,10.3
	l-3.9,8.9c-1.1,3.3,0.6,4.7,4.7,4l45.3-6.7c4.5-0.6,7.4-2.2,8.8-5l8.3-16.9C179.8,202.4,178.3,200.7,172.8,201.8L172.8,201.8z
	 M278.7,239.7c-0.3-2.3-1-4.3-2.1-5.9c-1.8-3.6-2.5-7.2-2.1-10.5 M278.7,364.2c0.1-1.9,0-4-0.4-6.3c-2.9-16.8-4.3-35.4-4-55.9
	c-0.3-20.5,1.1-39.1,4-55.9c0.4-2.3,0.5-4.5,0.4-6.5 M274.6,380.7c-0.4-3.3,0.3-6.9,2.1-10.5c1.1-1.6,1.8-3.7,2.1-5.9 M302.2,418
	c-4.1-2.5-7.8-5.6-11-9.2c-6.2-6.9-11.4-15-15.8-24.2c-0.5-1.2-0.8-2.6-1-3.9 M169.2,380.3l8.3,16.9c2.3,4.4,0.7,6.1-4.7,5.1
	c-16.6-3.2-31.2-6.6-43.7-10.3c-7.7-2.2-12.5-5.8-14.9-10.3l-3.9-8.9c-1.1-3.3,0.6-4.7,4.7-4l45.3,6.7
	C164.9,375.9,167.8,377.6,169.2,380.3L169.2,380.3z M146.4,394.5l1.8-0.1 M158.1,445.5l4.7,0.1c29,1.7,57.5,2.2,85.3,1.7l17.9-1.1
	c6.6-1,13.2-1.4,19.7-1.2l19.5-0.3 M158.1,445.5l-11.4-6.9h-0.3 M136.5,375.2c-1.6,0.7-2.1,1.8-1.1,3.3l6.1,11.4
	c1.6,2.8,3.3,4.3,5,4.5 M332.9,425.4h-0.1l1.2,0.1 M516,425c18.2-0.8,37.6-2.8,58.2-5.5c15.3-1.8,25.7-5.5,31.1-11.1
	c1.9-1.6,1.1-2.6-2.6-2.8l-61.6-2.9c-32.1-1.8-61.2-2.1-87.2-1.1l-9.5,0.4c-7,0.3-14,1-21,2.3c-27.1,4.1-55.7,10-85.6,17.9l-9.1,2.5
	c-0.1,0-1.2,0.3-3.6,0.8 M321.8,427.6l3.3-2.2 M334,425.6l-1.1-0.1 M356.4,430l-0.5-1c-0.7-0.7-1.6-1.1-3.2-1.2l-13.2-0.5
	c-1.6-0.1-2.8,0.1-3.2,1 M342.7,422.8h0.7c39.6,2.6,94.8,2.3,165.1-1l5.8-0.3 M342.7,422.8l0.7-0.1c27.8-7,54.5-12.5,79.9-16.5
	c3-0.5,6.1-0.8,9.1-1.1c7.2-0.8,14.3-1.4,21.3-1.5c22.3-1,46.6-0.8,73.3,0.4 M344.3,440.6l-8-10.6c-0.1-0.5-0.1-1,0-1.2l0.1-0.4
	 M325.1,425.4l12.2,0.6 M336.3,428.3l-14.6-0.7 M332.9,425.4l9.8-2.6 M296.3,426.3l2.1-2.2 M309.9,421.7c-1.6-0.5-3.2-1.2-4.8-2.1
	l-1.6-1c-0.4-0.3-0.8-0.4-1.1-0.7 M302.2,418c-1.1,2.3-2.3,4.3-3.9,6.1 M358.2,435.5l-9.5,0.7c-3.7,0.1-5.2,1.6-4.5,4.3
	 M344.3,440.6l2.8,3.4 M325.1,425.4c-5-0.6-10-1.8-15.3-3.7 M305.1,444.7l42-0.7 M298.4,424.1l26.8,1.4 M321.8,427.6l-25.6-1.4
	 M305.1,444.7c2.8-5.6,8.4-11.3,16.6-17.1 M356.4,430c0.1,0.4,0.1,0.8,0.3,1.2v0.5l1.6,3.9 M358.2,435.5l4.4-0.4 M362.6,435.1h0.7
	c1.4,0.1,2.5,0.7,3.3,1.8l3.2,6.7 M375.8,462.9c1.5-0.4,2.1-1.1,1.8-2.1l-7.8-17.1 M346.9,444l10.6,13.8c2.6,3.4,6.7,5.2,12.5,5.4
	l5.8-0.3 M369.6,443.6l10-0.1H476 M362.6,435.1l13.2,27.6 M296.3,426.3c-5.1,4.7-12.2,7-21.2,6.9c-90.2-1.4-147.7-6.5-172.5-15.1
	 M296.3,177.8c-5.1-4.7-12.2-7-21.2-6.9c-90.2,1.4-147.9,6.6-172.8,15.5 M158.1,158.4c-16.5,0.7-27.6,2.5-33.7,5.2l-2.5,1.4
	c-3.7,2.1-8,4-12.7,5.9c-4.5,1.2-8.9,3.7-13.5,7.2l-0.8,0.7c-8,6.3-15.3,14.7-22,25 M102.4,186.4c-0.6,0.6-1.1,1.1-1.7,1.8l-8.8,9.5
	 M70.6,207.5l2.3-3.7 M91.8,197.5l-12.2,13.1c-0.7,1.9-1.4,3.9-2.1,6.1 M146.2,165.4c-17.3,1.5-31.9,7.6-43.9,18.2
	c-5.8,5.2-9.4,9.8-10.6,13.9 M146.2,165.4c-11.3,2.8-20.4,5.9-27.4,9.5c-6.5,3-12.1,6.9-16.6,11.4 M72.9,203.8l-16.2,18.7
	c-1.9,2.6-2.2,5.8-0.8,9.5c0.7,3.9,0.3,10.5-1.4,19.9l-1,5.2c-2.8,15.7-4.4,29.6-4.7,42v5.8c0.3,12.4,1.8,26.3,4.7,42l1,5.2
	c1.7,9.5,2.1,16.1,1.4,19.9c-1.4,3.7-1.1,6.9,0.8,9.5l16.2,18.7 M70.6,396.4c-2.8-3.9-4.8-8.1-6.1-12.7l-3.6-14.6
	c-1.4-6.1-2.8-12-3.9-17.6c-3.4-17.6-5.4-33.2-5.8-46.6v-5.8c0.3-13.6,2.2-29.2,5.8-46.6c1.1-5.6,2.5-11.6,3.9-17.6l3.6-14.6
	c1.2-4.5,3.2-8.8,6.1-12.8 M65.2,226l-2.2,6.3 M63,371.6l-0.4-1.4c-0.7-2.3-1.2-4.7-1.7-7c-2.6-14.3-4.3-34.7-5.1-61.2
	c0.8-26.5,2.5-46.9,5.1-61.2c0.4-2.3,1-4.7,1.7-7l0.4-1.4 M77.6,216.6c-1.4,0.7-2.6,1.5-3.9,2.3c-1.7,1.1-3.3,2.3-5,3.6
	c-0.7,0.6-1.4,1.2-2.1,1.9l-1.5,1.6 M75.8,223.1c0.6-2.3,1.1-4.5,1.8-6.6 M63,232.3c3.3-2.6,6.6-5.1,9.8-7.3l3-1.9 M89.4,256.1
	l-2.3,0.4 M75.8,380.9c-3.9-15.7-6.3-37.4-7.4-65.2c-0.4-4.4-0.6-9.1-0.6-13.8c0-4.7,0.1-9.2,0.6-13.6c1.1-27.6,3.6-49.4,7.4-65.2
	 M65.2,226l3.4-9.9c1-3.2,1.7-6.1,1.9-8.5 M89.4,347.9l-2.3-0.4 M77.6,387.5c-0.7-2.1-1.2-4.3-1.8-6.6 M65.2,378l1.5,1.6
	c0.7,0.7,1.2,1.2,2.1,1.9l5,3.6c1.2,0.8,2.5,1.5,3.9,2.3 M75.8,380.9l-3-1.9c-3.2-2.2-6.5-4.5-9.8-7.3 M70.6,396.4
	c-0.3-2.5-1-5.4-1.9-8.5l-1.9-5.8l-1.5-4.1 M63,371.7l2.2,6.3 M72.9,400.3l-2.3-3.7 M102.6,418l-2.1-2.1l-8.8-9.4 M72.9,400.3
	c6.7,10.3,14,18.7,22,25l0.7,0.5c4.5,3.4,8.9,5.9,13.5,7.2c4.7,1.9,8.9,4,12.7,5.9l2.5,1.4c6.1,2.8,17.2,4.5,33.7,5.2 M91.8,406.6
	l-12.2-13.1c-0.7-1.8-1.4-3.9-2.1-5.9 M146.2,438.6c-17.3-1.5-32.1-7.6-43.9-18.2c-5.8-5.2-9.4-9.8-10.6-13.9 M146.2,438.6
	c-11.3-2.8-20.4-5.9-27.4-9.5c-6.3-2.9-11.8-6.6-16.4-11.1 M75.8,223.1c35.6-22.3,109.8-36.7,222.6-43.2 M298.4,424.1
	c-112.8-6.5-186.9-20.8-222.6-43.2 M136.5,228.9l9.9-19.3 M138.7,229.4c-1-0.1-1.6-0.3-2.2-0.6 M138.7,374.7c-1,0.1-1.6,0.3-2.2,0.5
	 M77.6,387.5c34.5,19.4,107.4,32.3,218.7,38.8 M77.6,216.6c34.5-19.4,107.4-32.3,218.7-38.8 M274.6,223.4
	c-79.2,6.6-141,17.5-185.2,32.7 M278.7,364.2c-66.7-1.8-129.7-7.3-189.1-16.4 M89.4,256.1c59.4-9.1,122.6-14.6,189.1-16.4
	 M274.6,380.7c-79.2-6.6-141-17.6-185.2-32.9 M136.5,375.2l9.9,19.3 M728.4,187.2l2.6,0.4 M746.8,184c4.4,1,8.5,2.6,12.5,4.7
	 M752.5,193.8c-1.5-3.4-3-6.2-4.5-8.3l-1.1-1.4 M746.8,184c-1.8-2.5-1.5-3.9,1.1-4.3c5.9,0.3,10.2,3.2,13.1,8.5l1,1.9 M731,187.6
	c8.7,1.6,14,4.5,16.2,8.9l0.8,1.6 M754.4,198.5h-6.3 M758.7,210.7c-1.2-3.9-2.5-7.4-3.6-10.6 M754.4,198.5l0.7,1.8 M766.4,205.7
	l2.6,7.7 M765.4,197.8c1.4,3.2,2.8,6.7,4,10.5 M762.4,196c1.1,0.6,2.1,1.1,3,1.8 M771.7,214.8c-0.8-0.6-1.8-1-2.6-1.4 M769.5,208.1
	l2.2,6.7 M766.4,205.7c1.1,0.7,2.1,1.5,3,2.3 M762.4,196c-3-1.5-6.3-2.2-9.9-2.2 M759.2,188.7l3.2,7.3 M762,190.2l-2.6-1.5
	 M752.5,193.8l1.9,4.7 M769,213.6c-3.3-1.5-6.7-2.5-10.3-2.9 M755.1,200.1c4,1.4,7.8,3.2,11.3,5.6 M765.4,197.8
	c-1.1-2.6-2.3-5.2-3.6-7.6 M518.8,443.3c9.5,0,20.1,1.1,31.6,3.3c24.6,4.1,55,5.5,91.5,4c25.7,1,47.5-2.1,65.5-9.4
	c10.5-3.6,22.4-6.6,35.9-8.9c14.2-2.6,24.2-11.6,29.8-27c10.3-21.7,16.9-52.8,19.7-93.3c0.3-3.2,0.4-6.5,0.5-10
	c0-3.6-0.3-7-0.5-10.2c-2.9-40.4-9.4-71.5-19.7-93.3c-5.6-15.4-15.7-24.4-29.8-27c-13.5-2.5-25.3-5.5-35.9-8.9
	c-18-7.3-39.8-10.5-65.5-9.5c-36.5-1.4-66.9,0-91.5,4.1c-11.6,2.2-22.1,3.3-31.6,3.3 M748.1,198.3l-22.6-0.1
	c-13.8-0.6-24.5,0.6-32.3,3.3c-8.1,4.3-13.9,10.3-17.5,18.3 M772,222l2.2,1.6 M774.1,230.4L772,222 M776.3,231.5
	c-0.7-0.4-1.4-0.7-2.2-1.1 M774.2,223.6l1.9,7.8 M763.8,228.7c4,0,7.4,0.6,10.3,1.6 M765.3,235.2l-1.7-6.5 M777.6,237.7
	c-2.9-1.5-7-2.3-12.4-2.5 M776.3,231.5l1.5,6.2 M760.5,216.6c4.4,1.4,8.4,3.2,11.7,5.4 M763.8,228.7c-1.1-4.3-2.2-8.4-3.3-12.1
	 M765.3,368.9l1.7-7.2c2.2-9.9,3.7-22.1,4.5-36.6c0.3-4.5,0.4-9.2,0.5-14.2v-16.5l-0.3-12c-0.7-16.1-2.3-29.4-4.7-40.2l-1.7-7.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M772.1,307.4v0.1c0.8,1.9,1.9,2.8,3.2,2.8h0.3c1.1-0.1,2.1-1.1,2.9-2.8
	c0.8-1.9,1.4-4.1,1.4-6.9c0-2.6-0.4-5-1.4-6.7c-0.8-1.8-1.8-2.6-2.9-2.8h-0.3c-1.2,0-2.3,1-3.2,2.9l-0.1,0.3 M748.1,198.3
	c11.7,23.9,18,52,19.1,84.2l0.3,10.7l-0.1,22.4c-0.5,34.7-6.9,64.7-19.3,90.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M777.6,237.7c1.1,5.1,2.2,10.5,3.2,16.1c2.3,13.1,3.6,29.2,3.9,48.1
	c-0.1,19-1.5,35.1-3.9,48.3c-1.1,5.6-2.1,11-3.2,16.1 M771.7,214.8c0.8,2.8,1.8,5.8,2.6,8.8 M760.5,216.6l-1.8-5.9 M774.1,373.6
	c-2.9,1.1-6.3,1.6-10.3,1.6 M776.3,372.6l-2.2,1 M774.1,373.6L772,382 M774.2,380.3l2.1-7.7 M771.7,389.1l2.5-8.8 M772,382l2.2-1.6
	 M765.3,368.9l-1.5,6.5 M777.6,366.3c-2.9,1.5-7,2.3-12.4,2.5 M777.6,366.3l-1.4,6.3 M772,382c-3.3,2.2-7.2,4-11.7,5.4 M763.8,375.4
	c-1.1,4.3-2.2,8.4-3.3,12.1 M765.4,406.3c1.4-3.3,2.8-6.7,4-10.5 M762.4,408.1c1.1-0.5,2.1-1.1,3-1.8 M766.4,398.2l2.6-7.7
	 M769.5,395.9c-1,0.8-2.1,1.6-3,2.3 M754.4,405.6l-1.9,4.7 M748.1,405.6h6.3 M731,416.4c8.7-1.6,14-4.5,16.2-8.9l0.8-1.8
	 M755.1,403.8l-0.7,1.8 M758.7,393.4c-1.2,3.9-2.5,7.4-3.6,10.6 M746.8,419.9c-1.8,2.5-1.5,3.9,1.1,4.3c5.9-0.4,10.2-3.2,13.1-8.5
	l1-1.9 M746.8,419.9c4.4-1,8.5-2.6,12.5-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M752.5,410.3c-1.5,3.4-3,6.2-4.5,8.4l-1.1,1.4 M762,413.9
	c1.2-2.3,2.3-4.8,3.6-7.6 M759.2,415.4l3.2-7.3 M762,413.9l-2.8,1.5 M769,390.5c-3.3,1.5-6.7,2.5-10.3,2.8 M762.4,408.1
	c-3,1.5-6.3,2.2-9.9,2.2 M766.4,398.2c-3.6,2.5-7.3,4.3-11.3,5.6 M728.4,416.8l2.6-0.4 M748.1,405.6l-22.6,0.1
	c-13.8,0.5-24.5-0.5-32.3-3.3c-8.1-4.3-13.9-10.3-17.5-18.3 M769.5,395.9l2.2-6.7 M760.5,387.5l-1.8,5.9 M769,390.5l2.8-1.4
	 M544.5,199.4l50.3-2.3c3.7-0.6,4.3-1.2,1.8-2.3c-5.5-3.2-12.9-5.5-22.3-6.9c-13.5-1.9-27.1-3.4-41-4.4 M559.3,390.6
	c3.6-13.3,6.2-28.3,7.7-44.8c0.7-8,1.2-16.4,1.5-25.2c0.1-5,0.3-10,0.1-15.3v-6.7c0-5.2,0-10.3-0.1-15.3c-0.3-8.8-0.7-17.1-1.5-25
	c-1.5-16.6-4.1-31.5-7.7-44.8 M675.8,219.8c5.9,2.5,11.3,8,16.1,16.2c5.8,12.7,9.4,27.9,10.9,45.9l0.3,4c0.3,3.9,0.4,7.7,0.4,11.7
	c0.1,6.1,0,12-0.3,18l-0.1,3.2c0,1.1-0.1,2.1-0.3,3.2c-1.5,18-5.1,33.4-10.9,45.9c-4.8,8.4-10.2,13.8-16.1,16.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M559.3,213.4h4.5c34.5,0.8,70,2.5,106.3,4.8c1.9,0.1,3.9,0.7,5.6,1.5
	 M506.4,160.5c1.9,0.7,3.6,1.5,5.1,2.6c-1.5,1.8-5.9,2.3-13.3,1.5 M518.8,160.6l-12.4-0.1 M518.8,160.6c2.9,2.3,2.8,6.9-0.3,13.3
	l-1,1.9 M514.3,182.2l1.7-3.3 M517.5,175.9l-1.5,3 M518.5,175.9h-1 M517.5,175.9h1 M731,187.6c-40.3-9.2-93.7-13.6-160-13.2
	c-5-0.1-9.8,0-14.7,0.4l-12.2,0.8c-6.1,0.3-12.5,0.4-19.7,0.4l-5.9-0.1 M517.5,175.9l-9.1-0.1c-63-2.9-113.6-3.4-152-1.6 M527,200.2
	c-3.9-7-8.1-12.9-12.8-17.9 M516,178.9l-7.4-0.3c-76.1-3.6-134.3-3.6-174.6-0.1 M544.6,199.6l-0.1-0.1 M533.3,183.5
	c3.7,4.1,7.4,9.5,11,16 M533.3,183.5l-19-1.2 M544.5,199.4l-17.5,0.8 M482.6,164.3l-7-0.6c-3.3-0.6-4-1.2-2.1-2.1
	c0.7-0.4,1.5-0.8,2.5-1.1 M485.9,160.5c-1.8,0.4-2.9,1.6-3.3,3.7 M494.4,160.5l-1-0.1h-5.4l-2.2,0.1 M494.4,160.5h-8.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M506.4,160.5c-3.4-1.2-7.4-1.9-12.2-2.2c-7.7-0.1-13.8,0.6-18.2,2.2
	 M498.1,164.6c0-2.2-1.2-3.6-3.9-4 M498.1,164.6l-15.5-0.3 M494.4,443.5h-8.5 M485.9,443.5c-1.8-0.4-2.9-1.7-3.3-3.7 M494.4,443.5
	l-1,0.1l-7.6-0.1 M482.6,439.7l-7,0.5c-3.3,0.5-4,1.2-2.1,2.1c0.7,0.4,1.5,0.8,2.5,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M476,443.5c4.4,1.6,10.5,2.3,18.2,2.2c4.8-0.3,8.9-1.1,12.2-2.2
	 M675.8,384.2c-1.8,0.8-3.7,1.2-5.6,1.5c-36.3,2.5-71.7,4.1-106.3,4.8h-4.5 M544.5,404.7l0.1-0.1 M518.5,428.2l5.9-0.1
	c7.2,0,13.8,0.1,19.7,0.4l12.2,0.8l14.7,0.4c66.4,0.3,119.8-4.1,160-13.2 M544.5,404.7c-3.7,6.5-7.4,11.8-11,16 M516,425l1.5,3
	 M514.3,421.7l1.7,3.3 M517.5,428.2h1 M518.8,443.3c2.9-2.3,2.8-6.9-0.3-13.3l-1-1.9 M517.5,428l1,0.1 M517.5,428.2V428 M517.5,428
	c-3.7,0-6.7,0-9.1,0.1c-63,2.9-113.6,3.4-152,1.6 M334,425.6c40.2,3.4,98.4,3.3,174.6-0.1l7.4-0.3 M527,403.8
	c-3.9,7-8.1,12.9-12.8,17.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M506.4,443.5h6.3c1.9-0.1,4-0.1,6.1-0.1 M506.4,443.5
	c1.9-0.7,3.6-1.5,5.1-2.6c-1.5-1.8-5.9-2.3-13.3-1.5 M514.3,421.7l19-1.1 M527,403.8l17.5,0.8 M498.1,439.5c0,2.2-1.2,3.6-3.9,4
	 M482.6,439.7l15.5-0.3 M533.3,420.6c13.9-1.1,27.5-2.5,41-4.4c9.4-1.4,16.8-3.6,22.3-6.9c2.5-1.1,1.9-1.8-1.8-2.3l-50.3-2.3
	 M559.3,390.6c-65.3,0.5-119.1,2.6-161.4,6.2 M559.3,213.4c-65.3-0.6-119.1-2.6-161.4-6.3"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M302.2,418c0,0-5,13.9-23.5,15.1
		h-3.9l-50.3-1.2c0,0-51.6-2.3-69.1-4.4c-17.5-2.1-40.4-4.8-52.8-9.5l-23-24.6c0,0-5.1-8.8-9.6-51c0,0-2.9-36.9-2.2-44
		c0,0,0.6-22.1,2.8-42.8c0,0,1.5-24.8,9.1-45l22.7-24.2c0,0,11-4.8,45.8-8.9c0,0,33-4,74.6-5.2l52.3-1.4c0,0,19.1-1.9,27.1,15.1
		c0,0-7.6,5.1-11.6,10c-4,5-12.2,16-15.5,24.8c0,0-2.5,5,1.2,12.5c0,0,2.3,3.9,2.3,6.5c0,2.6,0,3.7,0,3.7s-10.2,55.4-0.1,116.1
		c0,0,0.5,4.3-0.1,6.5s-1.8,4.3-1.8,4.3s-4.1,6.9-1.2,14c0,0,6.1,14.4,16.9,25.7C292.3,409.9,295.6,414,302.2,418z"
              id="Hood"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Hood')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('hoodColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M390.5,402.9l-80.6,18.8
		c0,0-12.7-3.2-25.5-21.6c0,0-8-11.8-9.8-18.4c0,0-1.5-4.1,1.8-11.1c0,0,3-4.1,2.2-9.4c0,0-4.5-20.8-4.5-62.9c0,0-0.3-25.3,4.4-53.9
		c0,0,1-4.7-1-9.1c0,0-4.1-6.6-2.9-12.1c0,0,2.8-13.2,20.5-31.5c0,0,8.1-7.3,14.9-9.4l79.2,18.4c0,0,6.2,1,8.5,7.2
		c0,0,1.4,3.2,0.8,7.8c0,0-5.9,39.5-5.9,82.4s3.7,73.7,4.8,81.7l1.1,8C398.7,388.2,400.6,399.7,390.5,402.9z"
              id="Windshield"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Windshield')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('windshieldColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M398.1,396.8
		c0,0,57.6-5.5,161.2-6.2c0,0,9.5-26.1,9.5-91.9c0,0,0.6-49.9-9.5-85.3c0,0-91.1,0-161.4-6.3h-0.4c0,0,1.8,3.9,1.1,8.8
		c0,0-5.9,41-5.9,82.3c0,41.3,3.7,75.5,5.5,86.9c0,0,1.6,7.3-0.7,11.8L398.1,396.8z"
              id="Roof"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Roof')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('roofColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M559.3,390.6
		c0,0,9.5-25.4,9.5-91.9c0,0,0.6-48.6-9.5-85.3c0,0,55.6,1.1,107.8,4.7l2.9,0.1c0,0,7.6,0.1,15.1,8.5c0,0,3.2,3.6,6.5,9.1
		c0,0,11.7,23.1,11.7,62.5c0,39.3-6.6,58.1-11.4,69.2c0,0-7.4,14.7-19,17.6l-2.3,0.4l-2.3,0.1C668.4,385.8,581.2,390.8,559.3,390.6z
		"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M754.4,405.6l-29.3,0.3
		c0,0-18.2,1.5-31.9-3.4c0,0-12-5.1-17.5-18.3c0,0,8.4-2.8,16.4-16.8c0,0,2.2-5.2,3.3-8.5c1.1-3.3,8.4-22.6,8.1-60.7
		c-0.3-38.1-9.9-58.2-9.9-58.2l-1.9-4.3c0,0-7.2-12.5-16-16.1c0,0,3.9-11.3,17.5-18.3c0,0,9.8-4.3,31.6-3.4l29.6,0.3l3.7,10.7
		c0,0,5.6,18.4,7.8,29.3c0,0,4.3,16.9,5.1,35.4c0,0,0.8,22.4,1,24.5c0,0,0.1,16.5-1.1,35.1c0,0-1.5,15.3-2.5,20.4
		C768.6,353.8,764.9,375.8,754.4,405.6z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe2DrTop);
