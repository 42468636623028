import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Dotdotdot from 'react-dotdotdot';
import LazyLoad from 'react-lazyload';
import { find, get } from 'lodash';
import {
  loadElasticSearchDistance,
  loadItem,
  loadTransportationTypes,
} from '../actions';
import {
  commafy,
  compose,
  findDistanceUnits,
  getConfig,
  makeAssetLink,
  stripHtml,
} from '../utils/helpers';
import { localization } from '../utils/localization';
import Commafy from './Commafy';
import withRouter from '../hocs/withRouter';
import $ from 'jquery';

let styles;

const DISABLE_DISTANCE_IN_DEV = false;

window.$ = window.jQuery = $;
require('bootstrap');

class VehicleDetailsVehicleCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      isOpen: false,

      devDistance: undefined,
      googleMapsDistance: undefined,
      transportationDistance: undefined,
      esGeoDistance: undefined,
      vehicleDataDistance: undefined,

      distance: undefined,
      distanceMethod: undefined,
      dynamicData:
        getConfig('enableDynamicAttributes') && props.vehicleData.condition,
    };
  }

  componentDidMount() {
    this.loadDistance();
  }

  componentDidUpdate(prevProps, prevState) {
    this.loadDistance(prevProps);
  }

  loadGoogleMapDistance(prevProps = {}) {
    // this method is the new default method for getting distance because
    // it is replicates how the transportation-estimate api calculates it's shipping distance
    // called individually per vehicle whenever the "userSelectedLocation" changes

    if (
      this.props.userSelectedLocation.address !==
        get(prevProps.userSelectedLocation, 'address') ||
      this.state.googleMapsDistance === undefined
    ) {
      this.setState(
        {
          googleMapsDistance: null,
          distance: null,
        },
        () => {
          const origins =
            this.props.vehicleData.itemPostalCode ||
            `${this.props.vehicleData.itemCity}+${this.props.vehicleData.itemState}`;

          const destinations =
            this.props.userSelectedLocation.zipcode ||
            (this.props.userSelectedLocation.address || '')
              .replace(/[*,]/g, '')
              .trim()
              .replace(/ /g, '+');

          if (!origins || !destinations) return null;

          const googleMapsDistanceService =
            new window.google.maps.DistanceMatrixService();
          googleMapsDistanceService.getDistanceMatrix(
            {
              origins: [origins],
              destinations: [destinations],
              travelMode: 'DRIVING',
              unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            },
            (response, status) => {
              if (response && status === 'OK') {
                const googleMapsDistance =
                  get(response, 'rows[0].elements[0].distance.value', '') *
                    0.00062137 || null;

                this.setState({
                  googleMapsDistance,
                  distance: googleMapsDistance,
                  distanceMethod: 'googleMapsDistance',
                });
              }
            }
          );
        }
      );
    }
  }

  loadTransportationDistance(prevProps = {}) {
    // this method gets the distance from the transportation-estimate api directly
    // we're not using this in lieu of the google maps distance
    if (
      this.props.userSelectedLocation.accountId !==
        get(prevProps.userSelectedLocation, 'accountId') ||
      this.state.transportationDistance === undefined
    ) {
      this.setState(
        {
          transportationDistance: null,
          distance: null,
        },
        () => {
          if (!this.props.userSelectedLocation.accountId) return null;

          this.props
            .loadTransportationTypes(
              getConfig('marketplaceId'),
              this.props.vehicleData.listingId,
              this.props.userSelectedLocation.accountId
            )
            .then(({ response }) => {
              if (response.wsStatus === 'Success') {
                const transportationDistance =
                  get(
                    find(response.items, item => item.distance),
                    'distance'
                  ) || null;

                this.setState({
                  transportationDistance,
                  distance: transportationDistance,
                  distanceMethod: 'transportationDistance',
                });
              }
            });
        }
      );
    }
  }

  loadESGeoDistance(prevProps = {}) {
    if (!this.props.userSelectedLocation.location) {
      return null;
    }

    if (
      this.props.userSelectedLocation.address !==
        get(prevProps.userSelectedLocation, 'address') ||
      this.state.esGeoDistance === undefined
    ) {
      this.setState(
        {
          esGeoDistance: null,
          distance: null,
        },
        () => {
          if (
            !this.props.vehicleData.listingId ||
            !this.props.userSelectedLocation.location.lat ||
            !this.props.userSelectedLocation.location.lng ||
            !findDistanceUnits(this.props.marketplaceFeatures)
          ) {
            return null;
          }

          this.props
            .loadElasticSearchDistance(
              this.props.vehicleData.listingId,
              this.props.userSelectedLocation.location.lat,
              this.props.userSelectedLocation.location.lng,
              findDistanceUnits(this.props.marketplaceFeatures)
            )
            .then(({ response }) => {
              const esGeoDistance =
                get(response, 'hits.hits[0].fields.distance[0]') || null;

              this.setState({
                esGeoDistance,
                distance: esGeoDistance,
                distanceMethod: 'esGeoDistance',
              });
            });
        }
      );
    }
  }

  loadVehicleDataDistance(prevProps = {}) {
    // this method uses the distance that is calculated by elasticsearch on the Search page
    // with Searchkit.  We use it when (1) when we are on the Search page and (2) when the user
    // selects a location that is NOT from the account list.  It's less expensive than the
    // google maps and we don't need it to exactly match shipping distance

    const vehicleDataDistance = get(this.props.vehicleData, 'distance', [])[0];
    const prevDistance = get(prevProps.vehicleData, 'distance', [])[0];

    if (
      vehicleDataDistance !== prevDistance ||
      this.state.vehicleDataDistance === undefined
    ) {
      this.setState({
        vehicleDataDistance: vehicleDataDistance || null,
        distance: vehicleDataDistance || null,
        distanceMethod: 'vehicleDataDistance',
      });
    }
  }

  loadDevDistance(prevProps) {
    if (this.state.devDistance) return;

    const devDistance = 999999;

    this.setState({
      devDistance,
      distance: devDistance,
      distanceMethod: 'devDistance',
    });
  }

  loadDistance(prevProps = {}) {
    if (DISABLE_DISTANCE_IN_DEV && process.env['MY_NODE'] === 'development') {
      // to avoid google charges we can limit calls during development
      this.loadDevDistance(prevProps);
    } else if (this.props.container !== 'Search') {
      this.loadESGeoDistance(prevProps);
    } else if (!this.props.userSelectedLocation.accountId) {
      this.loadVehicleDataDistance(prevProps);
    } else {
      this.loadGoogleMapDistance(prevProps);
    }
  }

  handleClick = e => {
    window.scrollTo(0, 0);
    const path = `/item/${this.props.vehicleData.listingId}`;
    this.props.navigate(path);
  };

  renderDate(date) {
    return (
      <div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <Dotdotdot clamp={1}>
              <span>Date Created: {date[0].dateCreated}</span>
            </Dotdotdot>
          </div>
        </div>
      </div>
    );
  }

  showListedPrice() {
    if (
      this.props.vehicleData.retailBookValue
      // && getConfig('localization') == 'en-uk'
    ) {
      return (
        <div>
          <strong>Listed Price: {'\u00A0'}</strong>
          {localization[getConfig('localization')].currency}
          <Commafy number={this.props.vehicleData.retailBookValue.toFixed(2)} />
        </div>
      );
    }
    return null;
  }

  renderLocation() {
    const { location } = this.props.vehicleData;

    if (!getConfig('enableShowLocation') || !location) {
      return null;
    }

    return (
      <div>
        <hr />
        <div className="row">
          <div className="col-sm-12 engine">
            <span>
              <Dotdotdot clamp={1}>
                <span>
                  <strong>Location: </strong>
                  {location}
                </span>
              </Dotdotdot>
            </span>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderDistance() {
    const { city, state, zipcode, address } = this.props.userSelectedLocation;
    const { latitude, longitude } = this.props.vehicleData;
    const { distance } = this.state;

    if (!distance) {
      return null;
    }

    const distanceUnits = findDistanceUnits(this.props.marketplaceFeatures);
    const distanceIsTooFar =
      (distanceUnits === 'mi' && distance > 3300) ||
      (distanceUnits === 'km' && distance > 5311);

    const distanceRounded =
      distance < 100 ? distance.toFixed(1) : Math.round(distance);

    let distanceText =
      latitude || longitude
        ? `${commafy(distanceRounded)} ${distanceUnits}`
        : 'N/A';

    let displayAddress = ``;
    if (city && state) {
      displayAddress = `${city}, ${state}`;
    } else if (zipcode) {
      displayAddress = zipcode;
    } else if (address) {
      displayAddress = address
        .replace(/, USA/g, '')
        .replace(/USA/g, '')
        .replace(/[\d*]/g, '');
    }

    if (displayAddress) distanceText += ` from ${displayAddress}`;
    if (DISABLE_DISTANCE_IN_DEV && process.env['MY_NODE'] === 'development') {
      distanceText = 'DEV MODE';
    }

    return (
      <div>
        <hr />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ fontWeight: 'bold', marginRight: 4 }}>Distance:</div>
          <div>{distanceText}</div>
        </div>
        <hr />
      </div>
    );
  }

  renderAnnouncements() {
    const { announcements, description } = this.props.vehicleData;

    if (description || announcements) {
      return (
        <div>
          <hr />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ fontWeight: 'bold', marginRight: 4 }}>
              Announcements:
            </div>
            <Dotdotdot clamp={2}>
              <div>{stripHtml(description)}</div>
              <div>{stripHtml(announcements)}</div>
            </Dotdotdot>
          </div>
        </div>
      );
    }
  }

  renderDynamicItem() {
    const { vehicleData } = this.props;

    const key = this.props.key;

    return (
      <div key={key} className="vehicle" onClick={this.handleClick}>
        <div
          className="vehicle-card"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 500,
            border:
              window.location.href.split('/').pop() ===
              vehicleData.listingId.toString()
                ? '2px solid green'
                : 'unset',
            cursor: 'pointer',
          }}
        >
          <div>
            <div
              className="vehicle-image"
              style={{
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#fff',
                objectFit: 'contain',
                height: 185,
                width: '100%',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottom: '1px solid #eee', // divider for white images
              }}
            >
              <img
                data-qa="poster"
                src={
                  vehicleData.imageUrl ||
                  'https://cdn.integratedauctionsolutions.com/holman.iasmarketplace.com/placeholder.png'
                }
                onError={e => {
                  e.onerror = null;
                  e.target.src = makeAssetLink('placeholder.png');
                }}
                role="presentation"
                alt="main"
                style={{
                  position: 'relative',
                  backgroundColor: '#fff',
                  objectFit: 'contain',
                  height: 186, // making just slightly larger than container helps w letter border effect
                  width: '100%',
                  overflow: 'hidden',
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              />
            </div>

            <div className="header" style={{ height: 'auto', padding: 8 }}>
              <div className="heading">
                <Dotdotdot clamp={1}>
                  {vehicleData.cardTitle || 'N/A'}
                  {/* {vehicleData.year} {vehicleData.make} {vehicleData.model} */}
                </Dotdotdot>
                <Dotdotdot clamp={1}>
                  <span className="sub-heading">{vehicleData.bodyStyle}</span>
                </Dotdotdot>
              </div>

              {
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      marginRight: 6,
                      color: '#777',
                    }}
                  >
                    <div>{`${
                      vehicleData.location ? `${vehicleData.location}` : ''
                    }`}</div>
                  </div>
                </div>
              }
            </div>

            <div className="vehicle-card-content">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginRight: 6,
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    Hours:
                  </div>
                  <div>{vehicleData.hours}</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginRight: 6,
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    Title:
                  </div>
                  <div>{vehicleData.title}</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginRight: 6,
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    Condition:
                  </div>
                  <div>{vehicleData.condition}</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginRight: 6,
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    Odometer:
                  </div>
                  <div>{vehicleData.mileage}</div>
                </div>
              </div>
              {this.renderAnnouncements()}
              {this.renderDistance()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStaticItem() {
    const { vehicleData } = this.props;

    if (!this.props.userProfile.user || !this.props.vehicleData) {
      return null;
    }

    const useVehicleCustom = getConfig('enableDynamicAttributes');
    return (
      <div className="vehicle" onClick={this.handleClick}>
        <div
          className="vehicle-card"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 500,
            border:
              window.location.href.split('/').pop() ===
              vehicleData.listingId.toString()
                ? '2px solid green'
                : 'unset',
            cursor: 'pointer',
          }}
        >
          <div>
            <div
              className="vehicle-image"
              style={{
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#fff',
                objectFit: 'contain',
                height: 185,
                width: '100%',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottom: '1px solid #eee', // divider for white images
              }}
            >
              <LazyLoad height={185}>
                <img
                  data-qa="poster"
                  src={
                    vehicleData.imageUrl ||
                    'https://cdn.integratedauctionsolutions.com/holman.iasmarketplace.com/placeholder.png'
                  }
                  onError={e => {
                    e.onerror = null;
                    e.target.src = makeAssetLink('placeholder.png');
                  }}
                  role="presentation"
                  alt="main"
                  style={{
                    position: 'relative',
                    backgroundColor: useVehicleCustom ? '#fff' : '#000',
                    objectFit: 'contain',
                    height: 186, // making just slightly larger than container helps w letter border effect
                    width: '100%',
                    overflow: 'hidden',
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                />
              </LazyLoad>
            </div>

            <div className="header" style={{ height: 'auto', padding: 8 }}>
              <div className="heading">
                <Dotdotdot clamp={1}>
                  {vehicleData.cardTitle || 'N/A'}
                  {/* {vehicleData.year} {vehicleData.make} {vehicleData.model} */}
                </Dotdotdot>
                <Dotdotdot clamp={1}>
                  <span className="sub-heading">
                    {vehicleData.bodyStyle || 'N/A'}
                  </span>
                </Dotdotdot>
              </div>
            </div>

            <div className="vehicle-card-content">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginRight: 6,
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    Odometer:
                  </div>
                  <div>
                    {vehicleData.odometer &&
                    vehicleData.odometer.toString().length > 0
                      ? `${commafy(vehicleData.odometer)}`
                      : 'N/A'}
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    overflow: 'hidden',
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    Color:
                  </div>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {vehicleData.color && vehicleData.color.length > 0
                      ? vehicleData.color
                      : 'N/A'}
                  </div>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-sm-12 engine">
                  <Dotdotdot clamp={1}>
                    <span>
                      <strong>Engine: </strong>
                      {vehicleData.engine && vehicleData.engine.length > 0
                        ? vehicleData.engine
                        : 'N/A'}
                    </span>
                  </Dotdotdot>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  <span>
                    <Dotdotdot clamp={1}>
                      <span>
                        <strong>Seller: </strong>
                        {vehicleData.seller && vehicleData.seller.length > 0
                          ? vehicleData.seller
                          : 'N/A'}
                      </span>
                    </Dotdotdot>
                  </span>
                </div>
              </div>
              {this.renderLocation()}
              {this.renderDistance()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.state.dynamicData
      ? this.renderDynamicItem()
      : this.renderStaticItem();
  }
}

VehicleDetailsVehicleCard.propTypes = {
  loadData: PropTypes.func,
  vehicleData: PropTypes.object.isRequired,
};

styles = {
  buttons: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 2,
  },
  button: {
    border: 'none',
    borderRadius: 3,
    outline: 'none',
    padding: '4px 5px',
    margin: 2,
    fontSize: 11,
    fontWeight: 500,
    cursor: 'pointer',
    minWidth: '18%',
    color: '#fff',
  },
};

const mapStateToProps = (state, ownProps) => {
  const {
    geocode,
    item,
    marketplaceFeatures,
    offer,
    resetOffer,
    sellingNow,
    serverTime,
    spinCars,
    template,
    userProfile,
    userSelectedLocation,
    watchlistUserItems,
  } = state.entities;

  return {
    geocode,
    item,
    marketplaceFeatures,
    offer,
    resetOffer,
    sellingNow,
    serverTime,
    spinCars,
    template,
    userProfile,
    userSelectedLocation,
    watchlistUserItems,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loadElasticSearchDistance,
    loadItem,
    loadTransportationTypes,
  })
)(VehicleDetailsVehicleCard);
