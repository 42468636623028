import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../../styles/form/condition.css';
import Icon from '../../../ui/icons';
import { GREY_07 } from '../../../../constants/colors';
import { filter, uniqBy } from 'lodash';
import { loadConditionParts } from '../../../../../../actions';
import Loader from '../../../ui/loader';
import ConfirmationModal from '../../../modals/ConfirmationModal';

class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      area: '',
      loaded: false,
      loadingDataSource: [{ description: 'Loading...' }],
      menuItems: ['Standard'],
      selectedMenuItem: 0,
      searchText: '',
      openErrorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.loaded && nextProps.condition.parts.amStatus === 'Success') {
      this.loadItems();
    }
  }

  loadItems() {
    const area = 'Frame';
    const menuItems = (this.props.crTypes.values || [])
      .map(value => value.name)
      .filter(name => name && String(name).toLowerCase() !== 'standard')
      .concat('Standard');

    this.setState(
      {
        area,
        menuItems,
        loaded: false,
      },
      () => {
        this.props
          .loadConditionParts(
            area,
            this.state.menuItems[this.state.selectedMenuItem]
          )
          .then(({ response }) => {
            if (response.amStatus === 'Success') {
              this.setState({ loaded: true });
            } else if (response.amStatus === 'Error') {
              this.setState({
                message: `Unable to load data. Error: ${response.amMessage}`,
                openErrorModal: true,
              });
            }
          })
          .catch(error => console.error(error));
      }
    );
  }

  handleClick = item => {
    this.props.navigate('/core/condition/damage', {
      state: {
        condition: {
          area: 'Frame',
          part: item.description,
          selectedRow: this.props.selectedRow,
        },
        selectedCrType: this.state.menuItems[this.state.selectedMenuItem],
      },
    });
  };

  filterList(searchText, list) {
    const text = searchText.toLowerCase();
    return filter(list, v => {
      const val = v.description.toLowerCase();
      return val.search(text) !== -1;
    });
  }

  handleMenuItemsClick(index) {
    this.setState(
      {
        selectedMenuItem: index,
        loaded: false,
      },
      this.loadItems()
    );
  }

  render() {
    const { menuItems, selectedMenuItem, searchText, loaded } = this.state;
    const { condition } = this.props;

    let parts = null;
    if (parts == null || !this.state.loaded) {
      parts = this.state.loadingDataSource;
    }
    if (condition && condition.parts) {
      parts = uniqBy(
        condition.parts && condition.parts.values,
        e => e.description
      );
    }

    if (searchText !== '') {
      parts = this.filterList(searchText, parts);
    }

    return (
      <div className="flex justify-between h-100">
        <div className="form-body-container form-sub-section w-100">
          <div className="form-body form-sub-section">
            <div className="search-container" style={{ borderRadius: '10px' }}>
              <input
                type="text"
                value={searchText}
                onChange={e => this.setState({ searchText: e.target.value })}
                className="search-box"
                placeholder="Search"
              />
            </div>
            {!loaded ? (
              <Loader className="conditions-loader flex justify-center pt-4" />
            ) : null}
            {menuItems.length > 1 &&
              (loaded && (
                <div>
                  <div className="mb-3">CR Types:</div>
                  <div className="splats">
                    {menuItems.map((item, index) => {
                      return (
                        <div
                          key={'crType-' + index}
                          className={`splat-type ${selectedMenuItem === index &&
                            'splat-type-selected'}`}
                          onClick={() => this.handleMenuItemsClick(index)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            {loaded &&
              parts.map((item, index) => {
                return (
                  <div
                    key={'parts-' + index}
                    className="form-section-navigation-card"
                    onClick={() => this.handleClick(item)}
                  >
                    <span>{item.description}</span>
                    <Icon name="ChevronRight" size={20} color={GREY_07} />
                  </div>
                );
              })}
          </div>
        </div>
        <ConfirmationModal
          open={this.state.openErrorModal}
          close={() => this.setState({ openErrorModal: false, area: '' })}
          action={() => this.loadItems()}
          title="Error"
          body={this.state.message}
          confirmBtnTxt="Try Again"
          iconName="CircledExclamationmark"
        />
      </div>
    );
  }
}

Frame.propTypes = {
  loadConditionParts: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { condition, crTypes } = state.core;
  const { loadingBar } = state;
  return { condition, crTypes, loadingBar };
};

export default connect(
  mapStateToProps,
  {
    loadConditionParts,
  }
)(Frame);
