import React, { Component } from 'react';
import { connect } from 'react-redux';

class Wagon4DrRear extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M393.7,350.3v6.1c0.7,0,1.4-0.6,2.2-0.6h17.4l2.2,0.6h9l4.3-0.6h17.4c0.7,0,1.4,0.6,2.2,0.6v-6.1v6.1h11.3
	c0.7,0,1.4-0.6,2.2-0.6c-0.8-2.2-1.3-3.5-1.5-4.3c0.4,0.8,0.8,2.1,1.5,4.3h17.4c0.7,0,1.4,0.6,2.2,0.6h6.5v-6.1v6.1
	c3.3,0,6.4-0.6,9.2-0.6c0.6,0.4,1,0.7,1.7,1c1.4,0.5,2.2,1.2,4.1,1.2h9.9c4.2,0,4.2-0.5,7.2,4.9c0.9,1.5,1.2,1.6,2.1,1.6h2.6
	c1,0,3.1,2.3,3.8,2.2c1.5-0.1,3-0.2,3.5-0.2s0.8-0.2,0.9-0.5c0.2-0.2,0.3-0.6,0.5-1.2c0.9-4,1.7-8,2.6-12.4c0.1-0.5-0.8-1.3-0.7-1.8
	c0.1-0.4,1.2-1.3,1.4-2l0.7-4.2c0.9-6.3,1.9-13.6,2.7-22.8c0.6-5.8,0.8-10.8,0.9-14.9c0.1-3.6,0.1-6.4,0.1-8c0-1.8-0.2-3.7-0.5-5.8
	c-0.3-2.3-0.9-4.8-1.6-6.9c-0.3-0.9-0.7-1.8-1-2.4c-0.9-1.9-1.9-3-1.9-3s-0.1-0.5-0.2-1.2c0-0.2-0.1-0.5-0.1-0.8
	c-0.2-1.3-0.6-3-0.9-4.8c-0.2-1-0.5-2.2-0.8-3.1c-0.2-0.9-0.5-1.6-0.8-2.3c0.3,0.4,1.2,0.5,3.1,0.5c2.2,0,9.6-0.2,14.2-0.8
	c4.7-0.6,5.4-4.5,5.4-9c0-4.3-1.4-8.9-2.8-10.5c-1.2-1.2-3.6-1.8-12-1.8c-3.8,0-8.6,0.2-9.9,1.4c-1.3,1.2-1.9,5.6-1.9,10.6
	c0,0.8-0.9,1-1.5,1h-0.6c-0.2,0-0.3,0.1-0.6,0.1c-0.5,0-0.8-0.1-0.8-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l-0.3-0.7c-0.2-0.5-0.6-2-1.3-4.3
	c-2.2-8-7.5-25.9-18.5-46.4l-1.4-2.4c-0.1-0.2-0.2-0.4-0.4-0.6c-2-3.5-6.4-6.2-8-6.5c-1.2-0.2-0.6,0.4-1.5,0.4
	c-0.1,0-0.6-0.2-1.7-0.2c-0.7,0-1.3,0.1-1.6,0.1c-0.5-0.1-0.8-0.4-1.4-0.5c-10.1-2.6-35.1-5.1-69.2-5.1h-0.5
	c-34.2,0-57.4,2.6-67.5,5.1c-0.5,0.1-0.9,0.5-1.4,0.5c-0.4,0-0.9-0.1-1.6-0.1c-1,0-1.6,0.2-1.8,0.2c-0.8,0-0.4-0.5-1.5-0.4
	c-1.6,0.4-6.2,3-8.2,6.5c-0.1,0.2-0.2,0.4-0.4,0.6l-1.4,2.4c-11.1,20.5-16.3,38.4-18.5,46.4c-0.6,2.3-1,3.7-1.2,4.3l-0.4,0.7
	c-0.1,0.1-0.1,0.2-0.2,0.4c0,0-0.4,0.1-0.8,0.1c-0.2,0-0.4-0.1-0.6-0.1h-0.6c-0.6,0-1.5-0.2-1.5-1c0-5-0.6-9.4-1.9-10.6
	c-1.3-1.2-5.9-1.4-9.8-1.4c-8.3,0-10.8,0.6-12,1.8c-1.5,1.6-2.8,6.2-2.8,10.5c0,4.4,0.7,8.4,5.4,9c4.7,0.6,11.9,0.8,14.1,0.8
	c2,0,2.9,0,3.1-0.5c-0.2,0.7-0.6,1.4-0.8,2.3c-0.2,0.9-0.6,2.1-0.8,3.1c-0.4,1.8-0.7,3.5-0.9,4.8c0,0.4-0.1,0.6-0.1,0.8
	c-0.1,0.7-0.2,1.2-0.2,1.2s-0.9,1.2-1.9,3c-0.4,0.7-0.7,1.5-1,2.5c-0.7,2-1.3,4.4-1.6,6.9c-0.4,2.1-0.5,4.1-0.5,5.8
	c0,1.6,0,4.4,0.1,8c0.1,4,0.5,9.1,0.9,14.9c0.8,9.2,1.8,16.6,2.7,22.8l0.7,4.2c0.1,0.7,1.3,1.6,1.4,2c0.1,0.5-0.8,1.3-0.7,1.8
	c0.8,4.4,1.6,8.4,2.6,12.4c0.1,0.5,0.2,0.8,0.5,1.2s0.5,0.5,0.9,0.5s2,0.1,3.5,0.2c0.7,0,2.7-2.2,3.8-2.2h2.6c1,0,1.3-0.1,2.1-1.6
	c3-5.4,3-4.9,7.2-4.9h9.9c1.9,0,2.7-0.8,4.1-1.2l1.7-1c2.9,0,5.9,0.6,9.2,0.6v-6.1v6.1h6.5c0.7,0,1.4-0.6,2.2-0.6h17.4
	c0.8-2.2,1.3-3.5,1.5-4.3c-0.4,0.8-0.8,2.1-1.5,4.3c0.7,0,1.4,0.6,2.2,0.6h11.3L393.7,350.3L393.7,350.3z"
          />
          <path
            fill="#EAEBEB"
            d="M420.9,201.7c-19.9,0-26.3,0.8-26.3,1.1v1.5c0,0.2,6.4,2.7,14.6,2.7h23.5c8.2,0,14.6-2.4,14.6-2.7v-1.5
	C447.3,202.5,440.8,201.7,420.9,201.7L420.9,201.7z M420.9,213.5c-34.7,0-55.7,0.8-65.3,3.1c-4.7,1.2-5.1,2.9-5.7,4.7
	c-1.9,5.4-4.3,15-4.7,16.6c-0.5,1.6-0.5,3.3,0.2,5.2c0.9,2.8,3.8,7.7,9.1,8.4c8.9,0.7,20.5,1.3,35.8,1.6c13.6,0.2,15.3-0.8,18.9-2.9
	c3.7-2.1,4.5-3,9.6-3.3c11.8,0,11.5,1.9,11.5,1.9c0.7,0.4,1.4,0.8,2.3,1.4c3.5,2,5.2,3,18.8,2.9c15.3-0.4,27-0.9,35.8-1.6
	c5.4-0.6,8.2-5.6,9.1-8.4c0.7-2,0.6-3.6,0.2-5.2c-0.5-1.5-2.8-11.2-4.7-16.6c-0.6-1.8-1-3.5-5.7-4.7
	C476.7,214.3,455.7,213.5,420.9,213.5L420.9,213.5z"
          />
          <path
            fill="#5D5D5D"
            d="M420.9,261.7c-5.1,0-9.3,2-9.3,4.3s4.2,4.3,9.3,4.3s9.3-2,9.3-4.3C430.3,263.6,426.1,261.7,420.9,261.7
	L420.9,261.7z"
          />
          <path
            fill="#EAEBEB"
            d="M312.4,273c0,4,0,9.9,3.3,11.3c3,1.2,17.6,3.1,20.4,3.5c-0.6-3.5-1-7.1-1.6-10.5h1.5
	c11.5,0.2,22.4,0.6,28,0.6c1.2,0,1.6-0.5,1.2-1.5c-0.2-0.4-1-1.9-2-3.5c0-0.1-0.1-0.1-0.1-0.2c-1.6-2.8-3.7-6.2-4.4-7.3
	c-1.2-1.9-2.3-2.2-4.3-2.8c-3.1-0.9-7.8-1.5-12.2-2.4c-1.2-0.2-2.4-0.6-3.6-0.8c-4.4-1-8.4-2.1-10.6-2.7c-0.8-0.2-1.5-0.4-1.8-0.4
	c-2.4,0-7.2,1.2-10.1,4.3C313.2,263.4,312.4,267.9,312.4,273L312.4,273z M323,238.7c-2.3,6.5-4,12-5.1,16.1c-0.5,1.8-0.8,3-1.2,4
	c0,0.1-0.1,0.2-0.1,0.2l-0.4,0.7c0.1-0.1,0.2-0.2,0.5-0.4c0.1-0.2,0.2-0.6,0.4-0.8c2.2-4.4,6.6-8.4,8.3-15.2
	c2.4-10,8.6-25.5,9.9-28.1c1.3-2.9,1.2-6.3,2.2-8.7v0.1c-0.4,0.6-0.7,1.3-1,1.9c-2.7,5-5,9.8-7,14.3c0,0.1-0.1,0.1-0.1,0.2
	c-2.6,5.6-4.5,10.8-6.3,15.5L323,238.7L323,238.7z M296.4,248.6c-2.2,0.1-4.8,0.1-6.1,1.8c-1.3,1.5-1.4,4.5-1.6,8.2
	c-0.1,2.9,0,7.2,1.9,7.7s13.3,1.3,15.9,0.8c1.9-0.4,3.8-4.3,3.8-8.9c0-4.2-0.2-7-1.4-8.3c-0.9-1.2-2.8-1.2-4.3-1.3
	C302,248.4,299.5,248.4,296.4,248.6L296.4,248.6z"
          />
          <path
            fill="#5D5D5D"
            d="M307.2,274c-1.2,0.5-1.8,1.5-1.9,2.6c0,1.2,0.6,1.9,0.9,2.2C306.5,277.5,306.8,275.7,307.2,274L307.2,274z
	 M306.1,279.6c-1.4,0.2-1.9,0.8-2.2,2.2c-0.2,1-0.4,1.5,0.2,1.9c0.9-1.9,1.9-3,1.9-3S306,280.3,306.1,279.6L306.1,279.6z
	 M319.3,320.5c0,2.9,0.1,5.9,0.2,7.9c0.4,6.6,1.2,8.7,5.1,8.7h7.6l-3.8-20.9h-6.8C319.9,316.3,319.3,317.5,319.3,320.5L319.3,320.5z
	 M304.6,344.6v55.7c0,4.3,3.6,7.9,7.9,7.9h14.7c4.3,0,7.9-3.6,7.9-7.9v-42.2h-5.8c-4.2,0-4.2-0.5-7.2,4.9l-0.1,0.1
	c-0.6,1-0.9,1.4-1.5,1.4c-0.5,0.1-1.3,0-2.4,0h-0.7c-1,0-3.1,2.3-3.8,2.2c-1.5-0.1-3-0.2-3.5-0.2s-0.8-0.2-0.9-0.5
	c-0.2-0.2-0.4-0.6-0.5-1.2c0-0.1,0-0.2-0.1-0.4c-0.6-2.1-1.3-5.8-1.9-8.6c-0.4-1.5-0.6-2.8-0.7-3.4c-0.1-0.5,0.8-1.3,0.7-1.8
	c-0.1-0.4-1.2-1.3-1.4-2v-0.1C305.1,347.4,304.8,346.1,304.6,344.6L304.6,344.6z"
          />
          <path
            fill="#EAEBEB"
            d="M529.5,273c0,4,0,9.9-3.3,11.3c-3,1.2-17.6,3.1-20.4,3.5c0.6-3.5,1-7.1,1.6-10.5h-1.5
	c-11.5,0.2-22.4,0.6-28,0.6c-1.2,0-1.6-0.5-1.2-1.5c0.2-0.4,1-1.9,2-3.5c0-0.1,0.1-0.1,0.1-0.2c1.6-2.8,3.7-6.2,4.4-7.3
	c1.2-1.9,2.3-2.2,4.3-2.8c3.1-0.9,7.8-1.5,12.2-2.4c1.2-0.2,2.4-0.6,3.6-0.8c4.4-1,8.4-2.1,10.6-2.7c0.8-0.2,1.5-0.4,1.7-0.4
	c2.4,0,7.2,1.2,10.1,4.3C528.7,263.4,529.5,267.9,529.5,273L529.5,273z M518.9,238.7c2.3,6.5,4,12,5.1,16.1c0.5,1.8,0.8,3,1.2,4
	c0,0.1,0.1,0.2,0.1,0.2l0.3,0.7c-0.1-0.1-0.2-0.2-0.5-0.4c-0.1-0.2-0.2-0.6-0.3-0.8c-2.2-4.4-6.6-8.4-8.3-15.2
	c-2.4-10-8.6-25.5-9.9-28.1c-1.3-2.9-1.2-6.3-2.2-8.7v0.1c0.4,0.6,0.7,1.3,1,1.9c2.7,5,5,9.8,7,14.3c0,0.1,0.1,0.1,0.1,0.2
	c2.6,5.6,4.5,10.8,6.3,15.5L518.9,238.7L518.9,238.7z M545.4,248.6c2.2,0.1,4.8,0.1,6.1,1.8c1.3,1.5,1.4,4.5,1.6,8.2
	c0.1,2.9,0,7.2-1.9,7.7s-13.3,1.3-15.9,0.8c-1.9-0.4-3.8-4.3-3.8-8.9c0-4.2,0.2-7,1.4-8.3c0.9-1.2,2.8-1.2,4.3-1.3
	C539.7,248.4,542.3,248.4,545.4,248.6L545.4,248.6z"
          />
          <path
            fill="#5D5D5D"
            d="M534.7,274c1.2,0.5,1.7,1.5,1.9,2.6c0,1.2-0.6,1.9-0.9,2.2C535.4,277.5,535.1,275.7,534.7,274L534.7,274z
	 M535.8,279.6c1.4,0.2,1.9,0.8,2.2,2.2c0.2,1,0.3,1.5-0.2,1.9c-0.9-1.9-1.9-3-1.9-3S535.9,280.3,535.8,279.6L535.8,279.6z
	 M522.6,320.5c0,2.9-0.1,5.9-0.2,7.9c-0.3,6.6-1.2,8.7-5.1,8.7h-7.6l3.8-20.9h6.8C521.9,316.3,522.6,317.5,522.6,320.5L522.6,320.5z
	 M537.2,344.6v55.7c0,4.3-3.6,7.9-7.9,7.9h-14.7c-4.3,0-7.9-3.6-7.9-7.9v-42.2h5.8c4.2,0,4.2-0.5,7.2,4.9l0.1,0.1
	c0.6,1,0.9,1.4,1.5,1.4c0.5,0.1,1.3,0,2.4,0h0.7c1,0,3.1,2.3,3.8,2.2c1.5-0.1,3-0.2,3.5-0.2c0.5,0,0.8-0.2,0.9-0.5
	c0.2-0.2,0.3-0.6,0.5-1.2c0-0.1,0-0.2,0.1-0.4c0.6-2.1,1.3-5.8,1.9-8.6c0.3-1.5,0.6-2.8,0.7-3.4c0.1-0.5-0.8-1.3-0.7-1.8
	c0.1-0.4,1.2-1.3,1.4-2v-0.1C536.8,347.4,537.1,346.1,537.2,344.6L537.2,344.6z"
          />
          <path
            fill="#5D5D5D"
            d="M498.7,356.9c1.3,0.5,2.1,1.2,3.8,1.2h4.3v25.6h-1.5c-1.2,0-3.6-3-5.2-3c-1.6,0-4.3,0.2-7.7,0.2
	s-33.6-6.1-37.9-6.1c-4.5,0-5-2-11-2c-2.3,0-5.8,0-5.8,0.4c0,0.9-1.4,3.3-5.6,3.3H412c-2.1,0-2,1.3-6.4,1.3h-16.7
	c-3.5,0-3.4-2.6-4.1-2.6c-10.6,0.9-33,5.7-35,5.7c-3.4,0-6.1-0.2-7.7-0.2s-4.1,3-5.2,3h-1.5V358h4c1.9,0,2.7-0.8,4.1-1.2
	c0.6-0.4,1.2-0.7,1.6-1c9.1-5.5,9.1-5.5,9.2-5.5h6.5c0.2,0,0.2,0,2.2,5.5h17.4c0.8-2.2,1.3-3.5,1.5-4.3c0.5-1.2,0.5-1.2,0.6-1.2
	h11.2c0.2,0,0.2,0,2.2,5.5h17.4c2-5.5,2-5.5,2.2-5.5h11.2c0.2,0,0.2,0,2.2,5.5h17.4c2-5.5,2-5.5,2.2-5.5h11.2c0.1,0,0.1,0,0.6,1.2
	c0.4,0.8,0.8,2.1,1.5,4.3h17.4c2-5.5,2-5.5,2.2-5.5h6.5c0.2,0,1.5,0.8,10.6,6.3h-0.9C497.8,356.6,498.2,356.7,498.7,356.9
	C498.6,356.9,498.7,356.9,498.7,356.9L498.7,356.9z M532.3,366.5l0.6-0.5c0.1-0.2,0.2-0.6,0.3-1c0,0.1-0.1,0.2-0.1,0.5
	c0-0.1,0.1-0.4,0.1-0.6c0.9-4,1.7-8,2.6-12.4c0-0.4-0.2-0.7-0.5-1v-0.1c0-0.1,0.1-0.2,0.2-0.4c-0.1,0.1-0.1,0.2-0.2,0.2
	c0.2-0.4,0.8-0.8,1-1.4c0,0.1-0.1,0.1-0.1,0.2c0.1-0.2,0.2-0.4,0.2-0.6c0.2-1.4,0.5-2.7,0.7-4.2c-0.2,1.4-0.5,2.7-0.6,4v0.1
	c-0.1,0.7-1.3,1.6-1.4,2c-0.1,0.5,0.8,1.3,0.7,1.7c-0.1,0.6-0.3,1.9-0.7,3.4c-0.6,2.8-1.3,6.5-1.9,8.6l-0.1,0.4
	c-0.1,0.5-0.2,0.8-0.5,1.2C532.7,366.3,532.5,366.4,532.3,366.5c-0.1,0-0.2,0.1-0.5,0.1c-0.5,0-2,0.1-3.5,0.2c1.5-0.1,3-0.2,3.5-0.2
	c0.2,0,0.5-0.1,0.6-0.1L532.3,366.5L532.3,366.5z M532.9,366.2l-0.5,0.4C532.5,366.4,532.7,366.3,532.9,366.2z M512.6,358h0.9H512.6
	z M521.4,364.6l3.6,0.1h3.1c-0.7,0-2.7-2.2-3.7-2.2h-2.6C521.8,364.7,521.7,364.7,521.4,364.6C521.5,364.6,521.5,364.6,521.4,364.6
	L521.4,364.6z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M335.1,383.8h1.5
	c1,0,3.6-3,5.2-3c1.6,0,4.3,0.2,7.7,0.2c2,0,24.5-4.8,35-5.7c0.7-0.1,0.6,2.6,4.1,2.6h16.7c4.5,0,4.3-1.3,6.4-1.3h20.2
	c4.2,0,5.6-2.3,5.6-3.3c0-0.4,3.5-0.4,5.8-0.4c6.1,0,6.4,2,11,2c4.3,0,34.5,6.1,37.9,6.1s6.1-0.2,7.7-0.2s4.1,3,5.2,3h1.5
	 M430.3,266c0,2.3-4.2,4.3-9.3,4.3c-5.1,0-9.3-2-9.3-4.3s4.2-4.3,9.3-4.3S430.3,263.6,430.3,266L430.3,266z M540.5,293
	c0,20.9-2.4,40.6-4.9,54.8c-0.1,1-1.3,1.4-1.4,2.3c-0.1,0.5,0.7,1.6,0.6,2.1c-0.9,4.9-1.8,9.1-2.6,12.1c-0.1,0.7-0.3,1.2-0.7,1.4
	 M534.6,274c1.3,0.4,1.9,1.5,2,2.6c0,1.2-0.6,1.9-0.9,2.2 M535.8,279.6c1.4,0.2,1.9,0.8,2.2,2.2c0.2,1,0.3,1.5-0.2,1.9 M505.5,208.3
	c-0.4,0.4,4.4,10,8.6,20.9c4.7,11.9,9.1,25.2,9.9,25.5 M533,249.8c0.9-1.2,2.8-1.2,4.3-1.3c2.6-0.1,5.1-0.1,8.2,0
	c2.2,0.1,4.8,0.1,6.1,1.8c1.3,1.5,1.4,4.5,1.6,8.2c0.1,2.9,0,7.2-1.9,7.7s-13.3,1.3-15.9,0.8c-1.9-0.4-3.8-4.3-3.8-8.9
	C531.6,254,531.8,251.2,533,249.8L533,249.8z M307.3,274c-1.3,0.4-1.9,1.5-2,2.6c0,1.2,0.6,1.9,0.9,2.2 M526.6,260.1v0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M504.1,205.9
	c1.4,2.4,1.2,6.2,2.6,9.3c1.2,2.7,7.5,18.1,9.9,28.1c1.7,7.1,6.6,11.2,8.6,16 M420.9,199.4c50,0,65.7,5.2,68,5.9
	c0.7,0.2,3.7-0.2,4.5,0c0.8,0.2,4.4,2,6.8,6.3c0.5,0.7,0.5,1.7,0.5,2.7s-0.5,1.6-0.8,1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,208.7
	c53.5,0,66.1,2.2,75.8,5.7c2.9,1.1,5.9,1.8,7.7,7.8c2.2,7.5,5.6,20.4,5.6,22.6v2.7c0,0.7,0.9,1.4,1.3,2.3c1.4,4.2,2.9,5.6,2.9,5.9
	c0,0.3,0.1,1.3-3.3,2c-1.5,0.4-1.7,0.6-1.7,2.3c0,6.9-5.1,39.5-7.3,49.1c-2.2,9.4-3.5,13.5-14.5,13.5h-66.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M509.2,259.3
	c-2,22.3-6.1,38.4-8.3,47.9c-2.2,9.4-1.9,13.4-12.8,13.4c-8,0-67.3,0.9-67.3,0.9 M510,246.3c0,1.3-0.4,2.1-1,2.3
	c-2.4,1.1-26.2,4.8-88,4.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,213.5
	c34.7,0,55.7,0.8,65.3,3.1c4.7,1.2,5.1,2.9,5.7,4.7c1.9,5.4,4.3,15,4.7,16.6c0.5,1.5,0.5,3.3-0.2,5.2c-0.9,2.8-4,8-9.6,8.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,209.7
	c53.2,0,65.7,2.2,75.3,5.7c2.9,1.1,5.9,1.8,7.7,7.8c2.2,7.5,5.6,20.4,5.6,22.6v2.4 M420.9,201.7c19.9,0,26.3,0.8,26.3,1.1v1.5
	c0,0.2-6.4,2.7-14.6,2.7h-11.8 M447.3,202.8c30.9,0,52.2,5.7,53.4,10 M447.3,204.3c30.9,0,52.3,5.7,53.4,10 M505.6,287.7
	c0,0,17.3-2.1,20.5-3.5s3.3-7.3,3.3-11.3c0-5.1-0.8-9.7-3.5-12.6c-2.9-3.1-7.7-4.3-10.1-4.3c-1.2,0-8.4,2.1-16,3.8
	c-4.4,1-9.1,1.5-12.2,2.4c-2,0.6-3.1,1-4.3,2.8c-1.2,1.9-6.1,10-6.6,11.1c-0.5,1,0,1.5,1.2,1.5c8.9,0,31.4-0.7,48.4-1
	c1.4,0,3.1,0,3,0.4 M540.9,306.9c-0.3-0.4-4.2-1-5.4-1.4c-1.1-0.4-2.9-1.7-4-3.1l-3.8-19.4 M494.2,199.1c0,0-0.6,2.6-0.9,6.1
	 M490.9,199c0,0-1,2.8-1.4,6.3 M420.9,257.5c70.9,0,82.5,1.5,82.5,1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,277.9
	c25.2,0,48.3,0,53.6-0.1c0.6,0,1.5-0.1,1.9-1 M420.9,272.8h57.7 M420.9,276.8h55.6 M467.9,277.9c-2.4,11.9-5.9,21.9-7.1,24.6
	c-1.2,2.7-2.8,6.3-10.8,6.5c-8,0.2-19.8,0.6-29,0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M466.9,277.9
	c0,0-7.5,0.7-8.4,1c-0.9,0.4-1.3,1.3-1.3,2.3c0,1-0.4,16.3-0.4,19.2c0,2.8-1.4,5.7-6.1,5.8c-4.5,0.1-16.1,0.5-29.8,0.5 M489.3,264
	c3.3-1,14.6-3.1,16.3-3.6c1.7-0.5,1.6,0.6,1.6,1.2c-0.1,1.8-0.4,4-0.7,6.4c0,0.5-0.1,1.4-1.9,1.4c-8.7,0.2-13.9,0.4-20,0.5
	c-1.6,0-1-0.9-0.8-1.4c0.4-0.7,0.7-1.3,0.9-1.5C486,265,487.2,264.5,489.3,264L489.3,264z M480.3,275c0.7-1.2,1.3-2.3,2-3.4
	c0.2-0.4,0.6-1,2.2-1c3.5-0.1,6.3-0.1,10.6-0.2c0.6,0,1.6,0,1.5,0.9c-0.2,1.5-0.4,2.4-0.6,3.7c-0.1,0.4-0.2,1.3-1.6,1.3
	c-6.9,0.1-9.7,0.2-13.3,0.2C479.5,276.5,480,275.6,480.3,275L480.3,275z M525.7,268.6c0.2,2.1,0.1,4.3,0,6.3c0,0.5-0.1,1.2-1.2,1.3
	c-5.1,0.1-8.3,0.1-13.6,0.2c-1,0-1-0.9-1-1.4c0.1-0.9,0.2-1.9,0.4-2.8c0.1-1,0.2-2.2,0.4-3.3c0-0.5,0.2-1.3,1.4-1.3
	c5-0.1,7.3-0.1,12.1-0.2C525.6,267.3,525.6,268.1,525.7,268.6L525.7,268.6z M524.7,282.7c-0.3,0.8-10.8,2.6-14.8,2.8
	c-0.8,0-1.4,0-1.4-1c0-0.1,0.2-1.7,0.7-5.4c0-0.5,0.2-1.3,1.3-1.3c4.9-0.1,9-0.1,13.5-0.2c0.5,0,1.3,0,1.3,0.9
	C525.2,281.3,524.8,282.5,524.7,282.7L524.7,282.7z M510.9,265.1c0.2-3,0.5-5.2,0.7-5.5c0.5-0.5,4.2-1.2,5.9-1.2
	c2.6,0,4.5,1.3,5.7,2.8c0.8,0.9,1.3,2.2,1.7,3.6c0.1,0.5,0.3,1.4-1,1.5c-4.7,0.1-6.9,0.1-11.8,0.2
	C510.8,266.6,510.8,265.6,510.9,265.1L510.9,265.1z M420.9,205.3h13.2c0.4,0,0.6-0.2,0.6-0.6v-0.2c0-0.4-0.2-0.6-0.6-0.6h-13.2
	 M420.9,350.3h5.6c0.2,0,0.2,0,2.2,5.5 M426.5,350.3v6.1 M420.9,350.3h-5.6c-0.2,0-0.2,0-2.2,5.5 M415.4,350.3v6.1 M453.8,350.3h5.6
	c0.2,0,0.2,0,2.2,5.5 M459.5,350.3v6.1 M453.8,350.3h-5.6c-0.2,0-0.2,0-2.2,5.5 M448.2,350.3v6.1 M487.6,350.3v6.1 M479,355.8
	c2-5.5,2-5.5,2.2-5.5h6.5c0.2,0,0.2,0,11,6.5 M481.2,350.3v6.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.7,356.4h5.9
	c0.7,0,1.4-0.6,2.1-0.6h17.4c0.7,0,1.4,0.6,2.2,0.6h11.3c0.7,0,1.4-0.6,2.2-0.6h17.4c0.7,0,1.4,0.6,2.2,0.6h6.5
	c3.3,0,6.4-0.6,9.2-0.6h18.5c6.5,0,7.2,0.4,9.4,2.8c1.7,2,5.6,5.9,6.9,7.3c0.3,0.4,1,0.1,1,0.1 M538.3,284.8c0,0-0.9,0.1-1.7,21
	 M420.9,324.6c0,0,59.2-0.8,67.6-0.8c8.4,0,11.2-1.5,12.9-8.5c1.7-7,2-9.1,2-9.2c0.1-0.2,0.4-6.4,0.4-6.4 M509.9,315.7
	c1.6,0,2.8,0.6,3.7,0.6h6.8c1.6,0,2.2,1.2,2.2,4.1c0,2.9-0.1,5.9-0.2,7.9c-0.3,6.6-1.2,8.7-5.1,8.7h-7.6c-0.7,0-3.3,1.4-4.5,1.4
	 M513.6,316.3l-3.8,20.9 M420.9,337.9h69.8c3.4,0,3.6,0.2,7.1,1.8c3.5,1.5,3.5,1.5,4.8,1.5c1.3,0,16.1,0.1,19.5,0.2
	c4.1,0.1,4,0,8.4,3.7s5.9,3.7,5.9,3.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,338.9h69.5
	c3.4,0,3.6,0.2,7.1,1.7c3.5,1.5,3.5,1.5,4.8,1.5c1.3,0,14.6,0.1,18,0.2c4.1,0.1,4,0,8.4,3.7s6.5,4.7,6.5,4.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,341h69.1
	c3.4,0,2.9,0,6.4,1.5c3.5,1.5,3.5,1.5,4.8,1.5s16.1,0.1,19.4,0.2c4.1,0.1,4,0,8.4,3.7s6.8,4.3,6.8,4.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M501.4,341
	c0.4,0.1,0.4,0.7,0.4,1.2s-1.2,1.8-1.4,1.8 M411.6,266c0,2.3,4.2,4.3,9.3,4.3s9.3-2,9.3-4.3s-4.2-4.3-9.3-4.3
	C415.7,261.7,411.6,263.6,411.6,266L411.6,266z M301.3,293c0,20.9,2.5,40.6,4.9,54.8c0.1,1,1.3,1.4,1.4,2.3c0.1,0.5-0.7,1.6-0.6,2.1
	c0.9,4.9,1.8,9.1,2.6,12.1c0.1,0.7,0.4,1.2,0.7,1.4 M306.1,279.6c-1.4,0.2-1.9,0.8-2.2,2.2c-0.2,1-0.4,1.5,0.2,1.9 M336.4,208.3
	c0.4,0.4-4.4,10-8.6,20.9c-4.7,11.9-9.1,25.2-9.9,25.5 M308.9,249.8c-0.9-1.2-2.8-1.2-4.3-1.3c-2.6-0.1-5.1-0.1-8.2,0
	c-2.2,0.1-4.8,0.1-6.1,1.8c-1.3,1.5-1.4,4.5-1.6,8.2c-0.1,2.9,0,7.2,1.9,7.7s13.3,1.3,15.9,0.8c1.9-0.4,3.8-4.3,3.8-8.9
	C310.2,254,310.1,251.2,308.9,249.8z M315.3,260.1v0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M337.8,205.9
	c-1.4,2.4-1.2,6.2-2.6,9.3c-1.2,2.7-7.5,18.1-9.9,28.1c-1.7,7.1-6.6,11.2-8.6,16 M420.9,199.4c-49.9,0-65.7,5.2-68,5.9
	c-0.7,0.2-3.7-0.2-4.5,0c-0.8,0.2-4.4,2-6.8,6.3c-0.5,0.7-0.5,1.7-0.5,2.7s0.5,1.6,0.8,1.5"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,208.7
	c-53.5,0-66.1,2.2-75.8,5.7c-2.9,1.1-5.9,1.8-7.7,7.8c-2.2,7.5-5.6,20.4-5.6,22.6v2.7c0,0.7-1,1.4-1.3,2.3c-1.4,4.2-2.9,5.6-2.9,5.9
	c0,0.3-0.1,1.3,3.3,2c1.5,0.4,1.8,0.6,1.8,2.3c0,6.9,5.1,39.5,7.3,49.1c2.2,9.4,3.5,13.5,14.5,13.5h66.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M332.7,259.3
	c2,22.3,6.1,38.4,8.3,47.9c2.2,9.4,1.9,13.4,12.8,13.4c8,0,67.3,0.9,67.3,0.9 M331.9,246.3c0,1.3,0.4,2.1,1,2.3
	c2.4,1.1,26.2,4.8,88,4.8"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,213.5
	c-34.7,0-55.7,0.8-65.3,3.1c-4.7,1.2-5.1,2.9-5.7,4.7c-1.9,5.4-4.3,15-4.7,16.6c-0.5,1.5-0.5,3.3,0.2,5.2c0.9,2.8,4,8,9.6,8.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,209.7
	c-53.2,0-65.7,2.2-75.3,5.7c-2.9,1.1-5.9,1.8-7.7,7.8c-2.2,7.5-5.6,20.4-5.6,22.6v2.4 M420.9,201.7c-19.9,0-26.3,0.8-26.3,1.1v1.5
	c0,0.2,6.4,2.7,14.6,2.7h11.8 M394.6,202.8c-30.9,0-52.2,5.7-53.4,10 M394.6,204.3c-30.9,0-52.3,5.7-53.4,10 M336.3,287.7
	c0,0-17.3-2.1-20.5-3.5c-3.3-1.4-3.3-7.3-3.3-11.3c0-5.1,0.8-9.7,3.5-12.6c2.9-3.1,7.7-4.3,10.1-4.3c1.2,0,8.4,2.1,16,3.8
	c4.4,1,9.1,1.5,12.2,2.4c2,0.6,3.1,1,4.3,2.8c1.2,1.9,6.1,10,6.6,11.1c0.5,1,0,1.5-1.2,1.5c-8.9,0-31.4-0.7-48.4-1
	c-1.4,0-3.1,0-3,0.4"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M301,306.9
	c0.4-0.4,4.2-1,5.4-1.4c1-0.4,2.9-1.7,4-3.1l3.8-19.4 M347.7,199.1c0,0,0.6,2.6,0.9,6.1 M351,199c0,0,1,2.8,1.4,6.3 M420.9,257.5
	c-70.9,0-82.5,1.5-82.5,1.5 M420.9,277.9c-25.2,0-48.3,0-53.6-0.1c-0.7,0-1.5-0.1-1.9-1 M420.9,272.8h-57.7 M420.9,276.8h-55.6
	 M374,277.9c2.4,11.9,5.9,21.9,7.1,24.6c1.2,2.7,2.8,6.3,10.8,6.5c8,0.2,19.8,0.6,29,0.6"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M375,277.9
	c0,0,7.5,0.7,8.4,1s1.3,1.3,1.3,2.3c0,1,0.4,16.3,0.4,19.2c0,2.8,1.4,5.7,6.1,5.8c4.5,0.1,16.1,0.5,29.8,0.5 M352.6,264
	c-3.3-1-14.6-3.1-16.3-3.6s-1.6,0.6-1.6,1.2c0.1,1.8,0.4,4,0.7,6.4c0,0.5,0.1,1.4,1.9,1.4c8.7,0.2,13.9,0.4,20,0.5
	c1.6,0,1-0.9,0.8-1.4c-0.4-0.7-0.7-1.3-0.9-1.5C355.9,265,354.7,264.5,352.6,264L352.6,264z M361.5,275c-0.7-1.2-1.3-2.3-2-3.4
	c-0.2-0.4-0.6-1-2.2-1c-3.4-0.1-6.3-0.1-10.6-0.2c-0.6,0-1.6,0-1.5,0.9c0.2,1.5,0.4,2.4,0.6,3.7c0.1,0.4,0.2,1.3,1.6,1.3
	c6.9,0.1,9.7,0.2,13.3,0.2C362.4,276.5,361.8,275.6,361.5,275L361.5,275z M316.1,268.6c-0.2,2.1-0.1,4.3,0,6.3
	c0,0.5,0.1,1.2,1.2,1.3c5.1,0.1,8.3,0.1,13.6,0.2c1,0,1-0.9,1-1.4l-0.4-2.8c-0.1-1-0.2-2.2-0.4-3.3c0-0.5-0.2-1.3-1.4-1.3
	c-5-0.1-7.3-0.1-12.1-0.2C316.3,267.3,316.1,268.1,316.1,268.6L316.1,268.6z M317.2,282.7c0.4,0.8,10.8,2.6,14.7,2.8
	c0.8,0,1.4,0,1.4-1c0-0.1-0.2-1.7-0.7-5.4c-0.1-0.5-0.2-1.3-1.3-1.3c-4.9-0.1-9-0.1-13.5-0.2c-0.5,0-1.3,0-1.3,0.9
	C316.7,281.3,317.1,282.5,317.2,282.7L317.2,282.7z M331,265.1c-0.2-3-0.5-5.2-0.7-5.5c-0.5-0.5-4.2-1.2-5.9-1.2
	c-2.6,0-4.5,1.3-5.7,2.8c-0.8,0.9-1.3,2.2-1.8,3.6c-0.1,0.5-0.4,1.4,1,1.5c4.7,0.1,6.9,0.1,11.8,0.2
	C331.1,266.6,331,265.6,331,265.1L331,265.1z M420.9,205.3h-13.2c-0.4,0-0.6-0.2-0.6-0.6v-0.2c0-0.4,0.2-0.6,0.6-0.6h13.2
	 M388,350.3h-5.6c-0.2,0-0.2,0-2.2,5.5 M382.4,350.3v6.1 M388,350.3h5.6c0.2,0,0.2,0,2.2,5.5 M393.7,350.3v6.1 M354.1,350.3v6.1
	 M362.9,355.8c-2-5.5-2-5.5-2.2-5.5h-6.5c-0.2,0-0.2,0-11,6.5 M360.7,350.3v6.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M421.2,356.4h-5.9
	c-0.7,0-1.4-0.6-2.1-0.6h-17.4c-0.7,0-1.4,0.6-2.2,0.6h-11.2c-0.7,0-1.4-0.6-2.2-0.6h-17.4c-0.7,0-1.4,0.6-2.2,0.6H354
	c-3.3,0-6.4-0.6-9.2-0.6h-18.4c-6.5,0-7.2,0.4-9.4,2.8c-1.8,2-5.6,5.9-6.9,7.3c-0.4,0.4-1,0.1-1,0.1 M303.6,284.8
	c0,0,0.9,0.1,1.8,21 M420.9,324.6c0,0-59.2-0.8-67.6-0.8s-11.2-1.5-12.9-8.5c-1.7-7-2-9.1-2-9.2c-0.1-0.2-0.4-6.4-0.4-6.4
	 M332,315.7c-1.6,0-2.8,0.6-3.7,0.6h-6.8c-1.6,0-2.2,1.2-2.2,4.1c0,2.9,0.1,5.9,0.2,7.9c0.4,6.6,1.2,8.7,5.1,8.7h7.6
	c0.7,0,3.3,1.4,4.5,1.4 M328.3,316.3l3.8,20.9 M420.9,337.9h-69.8c-3.4,0-3.6,0.2-7.1,1.8s-3.5,1.5-4.8,1.5
	c-1.3,0-16.1,0.1-19.5,0.2c-4.1,0.1-4,0-8.4,3.7s-5.9,3.7-5.9,3.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,338.9h-69.5
	c-3.4,0-3.6,0.2-7.1,1.7s-3.5,1.5-4.8,1.5c-1.3,0-14.6,0.1-18,0.2c-4.1,0.1-4,0-8.4,3.7s-6.5,4.7-6.5,4.7"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,341h-69.1
	c-3.4,0-2.9,0-6.4,1.5c-3.5,1.5-3.5,1.5-4.8,1.5s-16.1,0.1-19.5,0.2c-4.1,0.1-4,0-8.4,3.7s-6.8,4.3-6.8,4.3"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M340.5,341
	c-0.4,0.1-0.4,0.7-0.4,1.2s1.2,1.8,1.5,1.8 M531.6,273.7c-0.7-4.1-1.7-7.5-1.9-7.8c-0.2-0.8,0.5-1,0.8-0.6c0.2,0.2,1.5,4.4,2.2,8.6
	c0.8,4.4,1,8.9,1,9.3c0,0.9-0.6,2.1-0.8,1.2C533,284.1,532.4,278.5,531.6,273.7L531.6,273.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M420.9,358.3h2.6
	c0.6,0,1.3,0.8,2.1,0.8h13.9c0.4,0,0.7-0.4,1-0.4h0.9c0.4,0,0.6,0.4,0.9,0.4h10.8c1,0,2-0.8,3-0.8h4.5c1,0,1.6,0.8,2.7,0.8h4
	c1.4,0,1.9-0.4,3.1-0.4h12.1c3.1,0,4-2.2,6.4-2.2h7.3c3,0,3.6,1.5,6.2,1.5h9.9c4.2,0,4.2-0.5,7.2,4.9c0.9,1.5,1.2,1.6,2.1,1.6h2.6
	c1,0,3.1,2.3,3.8,2.2c1.5-0.1,3-0.2,3.5-0.2c0.8-0.1,1.2-0.6,1.4-1.6c0.9-4,1.7-8,2.6-12.4c0.1-0.5-0.8-1.3-0.7-1.7
	c0.1-0.4,1.3-1.3,1.4-2c1.2-7.2,2.3-15.7,3.4-27c1-11.1,1.2-19.5,1.2-23c0-3.6-0.8-8.9-2.2-12.7c-1.2-3.3-2.9-5.5-2.9-5.5
	s-0.9-5.7-2-9.8c-0.8-2.9-1.9-4.3-2.3-5.1c-2-3.6-5.5-4.3-6.4-6.6c-0.9-2.3-5.9-26-21.5-53.7c-2-3.5-6.4-6.2-8-6.5
	c-1-0.2-0.6,0.4-1.5,0.4c-0.1,0-0.6-0.2-1.7-0.2c-0.7,0-1.3,0.1-1.6,0.1c-0.5,0-0.8-0.4-1.4-0.5c-10.3-2.6-33.8-5.1-68.5-5.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M537.2,344.6v55.7
	c0,4.3-3.6,7.9-7.9,7.9h-14.7c-4.3,0-7.9-3.6-7.9-7.9v-43 M525.7,260c0,0,0.3,0.1,0.8,0.1c0.2,0,0.3-0.1,0.6-0.1h0.6
	c0.6,0,1.5-0.4,1.5-1c0-5,0.6-9.4,1.9-10.6c1.3-1.2,5.9-1.4,9.9-1.4c8.3,0,10.8,0.6,12,1.8c1.5,1.6,2.8,6.2,2.8,10.5
	c0,4.4-0.7,8.4-5.4,9c-4.7,0.6-11.9,0.8-14.2,0.8c-2.2,0-3,0-3.3-0.6 M420.9,358.3h-2.6c-0.6,0-1.3,0.8-2.1,0.8h-13.9
	c-0.4,0-0.7-0.4-1-0.4h-0.9c-0.4,0-0.6,0.4-0.9,0.4h-10.8c-1,0-2-0.8-3-0.8h-4.5c-1,0-1.6,0.8-2.7,0.8h-4.1c-1.4,0-1.9-0.4-3.1-0.4
	H359c-3.1,0-4-2.2-6.4-2.2h-7.3c-3,0-3.6,1.5-6.2,1.5h-9.9c-4.2,0-4.2-0.5-7.2,4.9c-0.9,1.5-1.2,1.6-2.1,1.6h-2.6
	c-1,0-3.1,2.3-3.8,2.2c-1.5-0.1-3-0.2-3.5-0.2c-0.8-0.1-1.2-0.6-1.4-1.6c-0.9-4-1.7-8-2.6-12.4c-0.1-0.5,0.8-1.3,0.7-1.7
	c-0.1-0.4-1.3-1.3-1.4-2c-1.2-7.2-2.3-15.7-3.4-27c-1-11.1-1.2-19.5-1.2-23c0-3.6,0.8-8.9,2.2-12.7c1.2-3.3,2.9-5.5,2.9-5.5
	s0.9-5.7,2-9.8c0.8-2.9,1.9-4.3,2.3-5.1c2-3.6,5.5-4.3,6.4-6.6c0.9-2.3,5.9-26,21.5-53.7c2-3.5,6.4-6.2,8.2-6.5
	c1-0.2,0.6,0.4,1.5,0.4c0.1,0,0.6-0.2,1.8-0.2c0.7,0,1.3,0.1,1.6,0.1c0.5-0.1,0.8-0.4,1.4-0.5c10.3-2.6,33.8-5.1,68.5-5.1"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M304.6,344.6v55.7
	c0,4.3,3.6,7.9,7.9,7.9h14.7c4.3,0,7.9-3.6,7.9-7.9v-43 M316,260c0,0-0.4,0.1-0.8,0.1c-0.2,0-0.4-0.1-0.6-0.1H314
	c-0.6,0-1.5-0.2-1.5-1c0-5-0.6-9.4-1.9-10.6c-1.3-1.2-5.9-1.4-9.8-1.4c-8.3,0-10.8,0.6-12,1.8c-1.5,1.6-2.8,6.2-2.8,10.5
	c0,4.4,0.7,8.4,5.4,9c4.7,0.6,11.9,0.8,14.2,0.8c2.2,0,3,0,3.3-0.6 M452.1,253.1c-14.2,0.2-15.9-0.8-19.4-2.8
	c-0.9-0.5-1.6-0.9-2.3-1.4c0,0,0.2-1.9-11.5-1.9c-5,0.2-5.9,1.2-9.6,3.3s-5.2,3.1-19.4,2.8"
          />
          <g>
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M332.5,248.1v-2.6l0.2-1.5
		c0,0,1.7-8.6,5.5-21.3c0,0,1.2-4.3,4.1-5.7c0,0,0.8-0.6,3.7-1.5c0,0,4.7-1.7,10.1-2.7c0,0,7.9-2,33.9-2.6c0,0,17.3-0.4,30.5-0.4
		c0,0,11.3-0.1,31.7,0.4c0,0,17.7,0.2,30.4,2.1c0,0,10.3,1.3,16.8,4.7c0,0,2.8,1.3,4,5.5c0,0,5.1,17.6,5.8,22.7l0.1,3.4
		c0,0,0.1,0.7-5.4,1.4c0,0-15.5,2.6-57.4,3.5l-25.9,0.2l-27.3-0.2c0,0-7.5,0.4-39.1-1.7c0,0-16.1-1.2-21.2-2.7
		C333.3,248.9,332.2,248.7,332.5,248.1z"
              id="Rear Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M332.1,247.3c0,0-0.5,1-1.2,1.9
		c0,0-0.5,0.5-0.6,1.4c0,0-0.7,2.6-2.4,4.9c0,0-0.8,1.3,0.9,1.9l1.7,0.6l1.3,0.4c0,0,0.9,0.1,0.8,1.5c0,0,1.5,21.6,7.9,46.2l1.2,5.4
		c0,0,1.2,6.1,3.3,7.5c0,0,2.2,2.2,8.4,2l23.9,0.2l43.4,0.7l49-0.7l18-0.2c0,0,5.6,0.1,8.3-1.4c0,0,2.6-1.2,3.6-5.6l1.6-7.2
		c0,0,2.1-8.7,4.4-20.6c0,0,2-8.9,3.6-26.2c0,0-0.1-1.3,0.9-1.5c0,0,3.1-0.6,3.8-1.6c0,0,0.2-0.5,0.1-0.7c-0.1-0.1-0.5-0.7-0.5-0.7
		s-1.6-2.4-2.4-5.1c0,0-0.2-0.5-0.5-0.9c0,0-0.7-0.9-0.8-1.6c0,0,0,1.4-1.6,1.6c0,0-3.3,1-19.1,2.4c0,0-21,1.4-42.4,1.9l-25.9,0.2
		l-26.7-0.4c0,0-14.2,0.4-44.5-2.1c0,0-11.1-0.9-15.7-2.1C334,249,331.9,248.9,332.1,247.3z"
              id="Deck Lid"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Deck Lid')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('deckLidColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M336.5,287.8
		c0,0-15.2-1.7-20.5-3.4c0,0-2.6-0.7-3.1-4.8c0,0-1.4-7.9,0.6-14.6c0,0,1-4.4,5.2-6.8c0,0,3.3-2,7.3-2.1l1.6,0.2c0,0,0.1,0.6,1.9,1
		s2.1,0.6,2.1,0.6s0.9,0.1,1,1.4c0,1.3,0.5,7.3,1.2,12.5C333.9,271.9,336,285.8,336.5,287.8z"
              id="Left Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M505.6,287.7
		c0,0,11.9-1.4,19.2-3c0,0,2-0.4,2.9-1.7c0,0,1.2-1.5,1.4-4.4c0,0,1.2-10.7-1.6-15.6c0,0-2.6-6.3-11.8-6.8l-1.7,0.4
		c0,0-0.3,0.6-2,1.2l-1.6,0.5c0,0-1.2,0-1.2,1.5c0,0-0.4,5.9-1.3,13.3L505.6,287.7z"
              id="Right Tail Light"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Right Tail Light')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rightTailLightColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M301,306.8
		c0,0,0.2-0.4,4.3-1c0,0,1.4-0.1,2.7-1.2c0,0,1.3-0.9,2.3-2.2l3.8-19.4c0,0,0.8,1,2.3,1.5c0,0,4,1,8.9,1.7l11,1.5l1.7,11.3l0.4,6.5
		c0,0,1,5.9,2.2,10.3c0,0,1,4.7,4.2,6.4c3.1,1.6,7.9,1.4,7.9,1.4l15.7,0.1l23.1,0.2l19.6,0.2l9.4,0.1l13.2-0.1l18.2-0.2l18.8-0.2
		l18.4-0.1c0,0,6.1,0,8.3-1.8c0,0,2-1,3.4-4.9c0,0,1.5-5.1,2.6-11.2l0.4-6.3l2.1-11.9c0,0,11.2-1.5,13.6-2c0,0,4.8-0.8,6.4-1.3
		c0,0,1.2-0.5,2-1.4l3.8,19.4c0,0,2.2,2.4,3.7,3l5,1.2l0.7,0.2c0,0-0.9,22.4-4.3,41.7c0,0,0,0.6-0.3,0.9l-0.7,0.9
		c0,0-0.5,0.4-0.1,0.9l0.5,0.7c0,0,0.2,0.2,0.1,0.9c0,0-2.1,10.6-2.6,12.1c0,0-0.3,1.4-1.5,1.5l-3,0.2c0,0-0.5,0.1-1.4-0.6
		c-0.9-0.7-2.1-1.6-2.4-1.6s-2.8,0-2.8,0s-1,0.1-1.7-0.9c-0.6-1.1-2.4-4.8-4.1-5.5c0,0-0.2-0.2-1.6-0.2h-11.5c0,0-1.4,0-2.6-0.5
		l-2.2-0.9l-10.6-6.3h-6.5l-2.2,5.5H462l-1.9-5c0,0-0.2-0.5-0.4-0.5s-11,0-11,0s-0.4,0-0.6,0.5c-0.2,0.5-1.9,5-1.9,5H429l-1.9-5
		c0,0-0.1-0.5-0.5-0.5c-0.4,0-11,0-11,0s-0.4,0-0.6,0.6c-0.2,0.6-1.7,4.9-1.7,4.9H396l-1.9-5c0,0-0.2-0.5-0.5-0.5s-11,0-11,0
		s-0.4,0.1-0.6,0.5c-0.1,0.4-1.9,5-1.9,5h-17.4l-1.9-5c0,0-0.2-0.5-0.6-0.5s-6.1,0-6.1,0s-0.2,0-0.7,0.2c-0.4,0.2-10.5,6.3-10.5,6.3
		l-2.3,0.9c0,0-0.5,0.2-2,0.2s-11.4,0-11.4,0s-2-0.2-3,1.2c0,0-1,1.3-2.6,4.1c0,0-0.7,1.4-1.6,1.4h-2.4c0,0-0.8-0.1-1.9,0.6
		c0,0-2.1,1.6-2.3,1.6l-3.5-0.2c0,0-1.2,0.1-1.4-1c0,0-1.7-7.3-2.7-12.7c0,0-0.1-0.4,0.1-0.8l0.4-0.6c0,0,0.4-0.4,0-0.9
		c-0.4-0.6-0.9-1-0.9-1s-0.2-0.2-0.4-1.4c0,0-1.9-12.6-2.7-20.9c0,0-0.8-8.4-1.6-20.3"
              id="Rear Bumper"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Rear Bumper')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('rearBumperColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Wagon4DrRear);
