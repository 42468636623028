import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import CreateHyperLotModal from './CreateHyperLotModal';
import AddHyperLotModal from './AddHyperLotModal';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadHyperLotEvents } from '../../actions';
import { getConfig } from '../../utils/helpers';
import $ from 'jquery';
window.$ = window.jQuery = $;

export class HyperLotButton extends Component {
  componentDidMount() {
    this.props.loadHyperLotEvents(getConfig('marketplaceId'));
  }

  handleHyperLot = e => {
    if (this.props.items.length) {
      e.preventDefault();
      const type = e.currentTarget.getAttribute('data-type');
      if (type === 'Create HyperLot') {
        $('#createHyperLotModal')
          .appendTo('body')
          .modal('show');
      }
      if (type === 'Add to HyperLot') {
        $('#addHyperLotModal')
          .appendTo('body')
          .modal('show');
      }
    } else {
      toastr.error('Error', 'Please select a vehicle to create a HyperLot.');
    }
  };

  render() {
    if (this.props.hyperLot.items) {
      return (
        <div className="btn-group" style={{ marginLeft: 10 }}>
          <button
            type="button"
            className="pull-right btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            HyperLot <span className="caret" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <Link
                to="#"
                data-type="Create HyperLot"
                onClick={this.handleHyperLot}
              >
                Create HyperLot
              </Link>
            </li>
            <li>
              <Link
                to="#"
                data-type="Add to HyperLot"
                onClick={this.handleHyperLot}
              >
                Add to HyperLot
              </Link>
            </li>
          </ul>
          <CreateHyperLotModal items={this.props.items} />
          <AddHyperLotModal hyperLot={this.props.hyperLot} />
        </div>
      );
    } else {
      return null;
    }
  }
}

HyperLotButton.propTypes = {
  loadHyperLotEvents: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { hyperLot },
  } = state;
  return { hyperLot };
};

export default connect(
  mapStateToProps,
  { loadHyperLotEvents }
)(HyperLotButton);
