import React, { Component } from 'react';

class FileUploadIcon extends Component {
  render() {
    const { size = 24, color = '#858C94' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 28"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.3333 2.68014C18.5986 2.68014 18.8529 2.57478 19.0404 2.38724C19.228 2.19971 19.3333 1.94535 19.3333 1.68014C19.3333 1.41492 19.228 1.16057 19.0404 0.973031C18.8529 0.785495 18.5986 0.680138 18.3333 0.680138L1 0.674805C0.734784 0.674805 0.480429 0.780161 0.292893 0.967698C0.105357 1.15523 0 1.40959 0 1.6748C0 1.94002 0.105357 2.19437 0.292893 2.38191C0.480429 2.56945 0.734784 2.6748 1 2.6748L18.3333 2.68014ZM9.53067 27.3308L9.66667 27.3401C9.90852 27.3401 10.1422 27.2524 10.3243 27.0933C10.5064 26.9342 10.6248 26.7145 10.6573 26.4748L10.6667 26.3401L10.6653 8.09347L15.628 13.0535C15.7973 13.2228 16.0218 13.3258 16.2606 13.3437C16.4994 13.3615 16.7367 13.2931 16.9293 13.1508L17.0427 13.0535C17.2122 12.8839 17.3151 12.6591 17.3327 12.42C17.3504 12.1809 17.2815 11.9434 17.1387 11.7508L17.0413 11.6388L10.3787 4.97747C10.2093 4.80811 9.98488 4.70514 9.74607 4.68728C9.50725 4.66942 9.26996 4.73785 9.07733 4.88014L8.964 4.97614L2.29333 11.6401C2.11395 11.8183 2.00873 12.0579 1.99887 12.3106C1.98901 12.5632 2.07523 12.8102 2.24018 13.0019C2.40512 13.1935 2.63655 13.3156 2.88787 13.3435C3.13918 13.3713 3.39173 13.303 3.59467 13.1521L3.70667 13.0548L8.66533 8.10414L8.66667 26.3401C8.66667 26.8468 9.04267 27.2641 9.53067 27.3308Z" />
      </svg>
    );
  }
}

export default FileUploadIcon;
