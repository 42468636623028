import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DocumentTitle from 'react-document-title';
import { loadEvents, resetEvents } from '../actions';
import { getConfig } from '../utils/helpers';
import EventsNav from './EventsNav';
import EventCard from './EventCard';

const styles = {
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 20,
    paddingBottom: 20,
  },
};

export class Events extends Component {
  state = {
    sortByEvent: '',
    filteredList: '',
    showLiveEvent: false,
  };

  componentDidMount() {
    this.props.loadEvents(getConfig('marketplaceId'));
  }

  getFilteredItems(filterValue, events) {
    if (events === '') return events;

    const mod = term => {
      return String(term).toLowerCase().includes(filterValue.toLowerCase());
    };

    return events.filter(item => {
      return (
        mod(item.eventName) ||
        mod(item.marketplace.city) ||
        mod(item.marketplace.marketplaceName) ||
        mod(item.marketplace.address1) ||
        mod(item.liveStartDate)
      );
    });
  }

  searchItemList = event => {
    this.setState({
      filteredList: this.getFilteredItems(
        event.target.value,
        this.props.events.eventList
      ),
    });
  };

  handleToggleSwitch = () => {
    var filterData = '';
    if (!this.state.showLiveEvent) {
      filterData = this.props.events.eventList.filter(item => {
        return String(item.eventTypeId).toLowerCase().includes('3');
      });
    }
    this.setState({
      showLiveEvent: !this.state.showLiveEvent,
      filteredList: filterData,
    });
  };

  changeSort = event => {
    this.setState({ sortByEvent: event.target.value });
  };

  getSortedValue(sorting, list) {
    if (sorting === 'A - Z') {
      return list.sort((a, b) => {
        let result =
          new Date(a.liveStartDate).getDate() -
          new Date(b.liveStartDate).getDate();

        if (result === 0) {
          result = a.eventName
            .toUpperCase()
            .localeCompare(b.eventName.toUpperCase());
        }

        return result;
      });
    }

    if (sorting === 'Start Time') {
      return list.sort((a, b) => {
        return moment.utc(a.eventStartTime).diff(moment.utc(b.eventStartTime));
      });
    }

    if (sorting === 'Lane') {
      return list.sort((a, b) => {
        if (a.eventShortName < b.eventShortName) return -1;
        else if (a.eventShortName > b.eventShortName) return 1;
        else {
          return moment
            .utc(a.eventStartTime)
            .diff(moment.utc(b.eventStartTime));
        }
      });
    }
  }

  createEventsList() {
    if (!this.props.events.eventList) {
      return [];
    }

    const sortByEvent =
      this.state.sortByEvent === ''
        ? getConfig('eventSort') === 'alphabet sort'
          ? 'A - Z'
          : 'Start Time'
        : this.state.sortByEvent;

    const eventList =
      this.state.filteredList ||
      this.getSortedValue(sortByEvent, this.props.events.eventList);

    return eventList.filter(event => {
      const { itemCountAtAuction, itemCountNoSale, eventId } = event;
      const hasItemCount = itemCountAtAuction > 0 || itemCountNoSale > 0;
      const isAllowed = !this.props.unauthorizedEventIds.includes(eventId);
      return hasItemCount && isAllowed;
    });
  }

  renderEventsList(eventList) {
    return (
      <div style={styles.listContainer}>
        {eventList.map(item => (
          <EventCard
            key={item.eventId}
            item={item}
            navigate={this.props.navigate}
          />
        ))}
      </div>
    );
  }

  render() {
    const title = getConfig('name')
      ? getConfig('name') + ' | Events'
      : 'Events';

    const eventList = this.createEventsList();

    return (
      <DocumentTitle title={title}>
        <div>
          <EventsNav
            sortByEvent={this.state.sortByEvent}
            showLiveEvent={this.state.showLiveEvent}
            handleToggleSwitch={this.handleToggleSwitch}
            searchHandler={this.searchItemList}
            sortHandler={this.changeSort}
          />
          <div className="container-fluid">
            {this.renderEventsList(eventList)}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Events.propTypes = {
  loadEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { events, unauthorizedEventIds } = state.entities;
  return { events, unauthorizedEventIds };
};

export default connect(mapStateToProps, { loadEvents, resetEvents })(Events);
