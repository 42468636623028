import React from 'react';
import { mapValues } from 'lodash';
import RunbuggyHtml from './runbuggy.html?raw'
import { getConfig } from '../../utils/helpers';

export default function RunBuggy({ itemData, user }) {

    var initVariables = {
        clientId: getConfig('runbuggyConfig').clientId,
        baseUrl: `${window.location.origin}/`,
        fromAddress: `${itemData.vehicleAddress}, ${itemData.vehicleCity} ${itemData.vehicleState}`,
        toAddress: `${user.address1}, ${user.city} ${user.state}`,
        carCarrierType: '',
        contactEmail: user.email,
        contactPhone: user.phone,
        pickupContactEmail: '',
        pickupContactName: '',
        pickupContactPhone: '',
        dropoffContactEmail: '',
        dropoffContactName: '',
        dropoffContactPhone: '',
        vin: itemData.vin,
        vehicleMake: itemData.make,
        vehicleModel: itemData.model,
        vehicleYear: itemData.year,
        vehicleType: '',
        vehicleOdometer: itemData.odometer,
        vehicleIsOperational: '',
        vehicleIsOverSized: ''
    };

    const html = String(RunbuggyHtml)
        .replace('{scriptSrc}', getConfig('runbuggyConfig').scriptSrc)
        .replace('{initVariables}', JSON.stringify(JSON.stringify(mapValues(initVariables, v => v === '' || v === null ? undefined : v))));

    return (
        <iframe title="Runbuggy Quote" srcDoc={html} style={{ border: 'none', height: '500px' }} />
    );

}