import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Checkbox } from 'semantic-ui-react';
import { cloneDeep, get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import {
  resetItem,
  resetPhotos,
  resetPostItem,
  resetVin,
  updateItem,
  loadPostItem,
} from '../../../../../actions';
import ToastContent from '../../ui/toastContent';
import Button from '../../ui/buttons';
import Loader from '../../ui/loader';
import { Item } from '../../../utils/Item';
import ConfirmationModal from '../../modals/ConfirmationModal';
import Layout from '../../templates/forms/Layout';
import { commafy } from '../../../../../utils/helpers';
import '../../../styles/form/review.css';

class Review extends Component {
  state = {
    lotEnabled: false,
    showPrintOptions: null,
    submitted: false,
    loader: false,
    isConfirmationModalOpen: false,
    confirmationTitle: '',
    confirmationMessage: '',
    confirmationAction: () => null,
    imageUploadStatus: {
      staged: [],
      uploading: [],
      success: [],
      failure: [],
      total: [],
    },
  };

  componentDidMount() {
    this.setState({
      imageUploadStatus: this.gatherImageUploadStatus(),
    });
  }

  gatherImageUploadStatus() {
    const photos = cloneDeep(this.props.item.photos) || [];
    const counts = {
      staged: [],
      uploading: [],
      success: [],
      failure: [],
      total: [],
    };

    photos.forEach(photo => {
      const hasImage = photo.name ? true : false;
      const notDeleted = photo.name !== '(null)';
      const notPosted = !photo.photo || photo.name;

      if (hasImage && notDeleted && notPosted) {
        if (photo.uploadStatus) counts.total.push(photo);
        if (photo.uploadStatus === 'Staged') counts.staged.push(photo);
        if (photo.uploadStatus === 'Success') counts.success.push(photo);
        if (photo.uploadStatus === 'Failure') counts.failure.push(photo);
        if (photo.uploadStatus === 'Uploading') counts.uploading.push(photo);
      }
    });

    return counts;
  }

  handleClose = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  handleCancelConfirmation = () => {
    this.setState({
      isConfirmationModalOpen: true,
      confirmationTitle: 'Alert',
      confirmationMessage: 'Are you sure you want to go back?',
      confirmationAction: this.handleItemCancel,
    });
  };

  handleItemCancel = () => {
    const { entryType, saleDate } = this.props.item;
    const path = `/inventory/${
      entryType === 'Inventory' || !saleDate ? 'unlisted' : 'listed'
    }`;
    this.props.navigate(path, { replace: true });
  };

  handleUnlockedConfirmation = () => {
    this.setState({
      isConfirmationModalOpen: true,
      confirmationTitle: 'Alert',
      confirmationMessage:
        'The vehicle is Unlocked. Updates from the inventory feed will replace existing data.',
      confirmationAction: () => this.handleSubmit({ overrideUnlocked: true }),
    });
  };

  handleSubmitCheck = () => {
    const imageUploadStatus = this.state.imageUploadStatus;
    const stagedCount = get(imageUploadStatus, 'staged', []).length;
    const uploadingCount = get(imageUploadStatus, 'uploading', []).length;
    const failureCount = get(imageUploadStatus, 'failure', []).length;

    let confirmationMessage = [
      uploadingCount
        ? `Uploads In Progress: ${uploadingCount + stagedCount}`
        : '',
      failureCount ? `Failed Uploads: ${failureCount}` : '',
    ]
      .filter(msg => msg)
      .join('\n');

    if (confirmationMessage) {
      this.setState({
        isConfirmationModalOpen: true,
        confirmationTitle: 'Must Complete:',
        confirmationMessage,
        confirmationAction: this.handleClose,
      });
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = (options = {}) => {
    const { overrideUnlocked } = options;

    if (!this.props.item.locked && !overrideUnlocked) {
      this.handleUnlockedConfirmation();
    } else {
      let printing = this.state.showPrintOptions;
      const vehicle = Item(this.props.item, this.props.lot, printing);
      this.setState({ submitted: true, loader: true }, () => {
        this.props
          .loadPostItem(vehicle)
          .then(({ response }) => {
            if (response) {
              if (response.amStatus === 'Error') {
                toast(
                  <ToastContent
                    status="error"
                    text="Oops. Something went wrong please try again"
                  />,
                  { className: 'review-toast' }
                );
                this.setState({ submitted: false, loader: false });
                this.props.resetPostItem();
              } else if (response.amStatus === 'Success') {
                toast(
                  <ToastContent
                    status="success"
                    text="Item successfully posted."
                  />,
                  { className: 'review-toast' }
                );
                this.setState({ submitted: true, loader: false });
                setTimeout(() => {
                  const {
                    dealerName,
                    accountNumber,
                    saleId,
                    saleDate,
                    eventName,
                    entryType,
                  } = this.props.item;

                  this.props.resetPostItem();
                  this.props.resetVin();
                  this.props.resetItem();
                  this.props.updateItem({
                    dealerName,
                    accountNumber,
                    saleId,
                    saleDate,
                    eventName,
                  });

                  const path = `/inventory/${
                    entryType === 'Inventory' || !saleDate
                      ? 'unlisted'
                      : 'listed'
                  }`;
                  this.props.navigate(path, { replace: true });
                }, 2000);
              }
            } else {
              toast(
                <ToastContent
                  status="error"
                  text="Oops. Something went wrong please try again"
                />,
                { className: 'review-toast' }
              );
              this.props.resetPostItem();
            }
          })
          .catch(error => console.error(error));
      });
    }
  };

  render() {
    const { item } = this.props;
    const {
      confirmationAction,
      confirmationMessage,
      confirmationTitle,
      imageUploadStatus,
      isConfirmationModalOpen,
      submitted,
      lotEnabled,
    } = this.state;

    const stagedCount = get(imageUploadStatus, 'staged', []).length;
    const uploadingCount = get(imageUploadStatus, 'uploading', []).length;
    const successCount = get(imageUploadStatus, 'success', []).length;
    const failureCount = get(imageUploadStatus, 'failure', []).length;
    const totalCount = get(imageUploadStatus, 'total', []).length;

    const odometerUnits =
      item.odometerType === 'Miles'
        ? 'mi'
        : item.odometerType === 'Kilometers'
        ? 'km'
        : '';

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <ReactTooltip
          id="locked"
          effect="solid"
          place="bottom"
          offset={{ bottom: 10, left: 0 }}
          backgroundColor="lightgray"
          textColor="black"
        />
        {this.state.loader && <Loader />}
        <Layout>
          <div className="flex justify-between h-100">
            <div className="form-container w-100">
              <div className="form-body-container">
                <div className="flex justify-center h-100">
                  <div className=" review-container review-container-width">
                    <div className="form-header">
                      <div>Vehicle Review</div>
                    </div>

                    <div className="review-card-title">Sales</div>
                    <div className="review-card-body">
                      <div className="review-card-row">
                        <div>
                          <div>Dealer Name</div>
                          <div>{item.dealerName}</div>
                        </div>
                        <div>
                          <div>Dealer Number</div>
                          <div>{item.accountNumber}</div>
                        </div>
                      </div>
                      <div className="review-card-row">
                        <div>
                          <div>Event</div>
                          <div>{item.eventName}</div>
                        </div>
                        <div>
                          <div>Sale Date</div>
                          <div>{item.saleDate}</div>
                        </div>
                      </div>
                      <div className="review-card-row">
                        <div>
                          <div>Stock Number</div>
                          <div>{item.stockNumber}</div>
                        </div>
                        {lotEnabled && (
                          <div>
                            <div>Lot Number</div>
                            <div>{item.lotNumber}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="review-card-title">Vehicle</div>
                    <div className="review-card-body">
                      <div className="review-card-row">
                        <div>
                          <div>YMM</div>
                          <div>
                            {item.year} {item.make} {item.model}
                          </div>
                        </div>
                        <div>
                          <div>Odometer</div>
                          <div>{`${commafy(
                            item.odometer
                          )} ${odometerUnits}`}</div>
                        </div>
                      </div>
                      <div className="review-card-row">
                        <div>
                          <div>Body</div>
                          <div>{item.bodyStyle}</div>
                        </div>
                        <div>
                          <div>Transmission</div>
                          <div>{item.transmission}</div>
                        </div>
                      </div>
                      <div className="review-card-row">
                        <div>
                          <div>Engine</div>
                          <div>{item.engine}</div>
                        </div>
                        <div>
                          <div>Driveline</div>
                          <div>{item.driveline}</div>
                        </div>
                      </div>
                      <div className="review-card-row">
                        <div>
                          <div>VIN</div>
                          <div>{item.vin}</div>
                        </div>

                        <div>
                          <div>{item.locked ? 'Locked' : 'Unlocked'}</div>
                          <Checkbox
                            data-for="locked"
                            data-tip="Locking prevents vehicle data from being updated by the inventory feed."
                            className="core-toggle"
                            checked={item.locked}
                            onClick={(evt, data) => {
                              this.props.updateItem({ locked: data.checked });
                            }}
                            toggle
                            fitted
                          />
                        </div>
                      </div>
                    </div>

                    <div className="review-card-title">Image Upload Status</div>
                    <div className="review-card-body">
                      <div className="review-card-row-full">
                        <div>
                          <div>Success</div>
                          <div className="image-upload-success">
                            {successCount} of {totalCount}
                          </div>
                        </div>
                      </div>

                      {uploadingCount + stagedCount > 0 && (
                        <div className="review-card-row-full">
                          <div>
                            <div>Uploading</div>
                            <div className="image-upload-success">
                              {uploadingCount + stagedCount}
                            </div>
                          </div>
                        </div>
                      )}

                      {failureCount > 0 && (
                        <div className="review-card-row-full">
                          <div>
                            <div>Failed</div>
                            <div className="image-upload-fail">
                              {failureCount}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="form-footer">
                      <ConfirmationModal
                        open={isConfirmationModalOpen}
                        close={this.handleClose}
                        action={confirmationAction}
                        title={confirmationTitle}
                        body={confirmationMessage}
                        iconName="CircledExclamationmark"
                        confirmBtnTxt="Ok"
                      />
                      <Button
                        btnText="Cancel"
                        height="42px"
                        outlined={true}
                        iconName="Cross"
                        iconSize={26}
                        onClickHandler={this.handleCancelConfirmation}
                        marginRight="10px"
                      />
                      <Button
                        btnText="Submit"
                        height="42px"
                        iconName="CompleteStatus"
                        iconSize={20}
                        iconOnRight
                        onClickHandler={this.handleSubmitCheck}
                        disabled={submitted}
                        marginLeft="16px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { item, lot } = state.core;
  return { item, lot };
};

export default connect(mapStateToProps, {
  loadPostItem,
  resetItem,
  resetPhotos,
  resetPostItem,
  resetVin,
  updateItem,
})(Review);
