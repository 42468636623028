import React, { Component } from 'react';
import { connect } from 'react-redux';

class Coupe2DrLeft extends Component {
  constructor(props) {
    super(props);
  }
  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="20 130 800 340">
          <path
            fill="#FFFFFF"
            d="M604,227.1c2.3,1.1,2.6,2.6,0.8,4.7c-2.9,3.4-5.6,6.2-8.4,8.3c-3.3,2.3-8.5,3.6-15.3,4
	c-22,2.3-44.2,4-66.4,5.1l1.9,2.9c5.5,8.7,7.7,17.3,6.5,26c-1.8,8.7-4.1,17.1-6.9,25.2c-3,8.9-6.5,17.5-10.5,25.9
	c9.8,1.5,18.7,3.7,26.6,7c4.1,2.1,0.7,3-10.6,3.2l-21.6,0.5l-3.4,5.9c-5.4,9.2-14.9,13.9-28.2,14l-147.7,2.1c-1.8,0.3-3.3,0.3-4.5,0
	h-0.8h0.8c-1.2-0.3-2.1-0.7-2.8-1.5l-3.4-11.1c-0.5-1.5-0.8-2.9-1.1-4.3l-19.4,0.5c-12.9,0.4-16.1-1-9.5-4
	c6.5-2.8,16.1-4.8,28.8-6.3v-3.2l-0.4-2.5c-1.8-9.6-3.2-18.4-3.9-26.4c-0.8-8.1-1.1-15.5-0.8-22.1c1-10.3,5.1-18.3,12.8-23.9
	L260,258c-36.5,0.3-70.8,2.2-103,6.2c-23.7,5.1-42,11.7-54.6,19.9l12.1-1.2c9.4-0.8,18.6-1.5,27.8-2.1c3,0.1,4.8,0.7,5.2,1.8
	l10.5,7.7c11-6.7,23.7-10,37.8-10c19.1,0,35.5,6.2,49,18.4c1.5,1.4,3,2.8,4.4,4.3l0.8,1c5.5,6.2,9.6,13.1,12.1,20.4
	c1,2.9,1.6,5.9,2.2,8.9c1.5,10.3,2.1,23.4,1.5,38.9L281,370l8.4-0.8l10.3-0.5L502,365c15.1-0.3,30.1,1.2,45.3,4.4h1.2
	c0.1-14.6,1.2-26.7,3-36.6c1.2-6.7,3.7-12.9,7.3-18.8c2.3-3.9,5.4-7.6,8.8-11.1c1-1,1.9-2.1,3-3c13.5-12.4,29.9-18.4,49-18.4
	s35.5,6.2,49,18.4l3,3l3,3.2l35.9-2.1c3.4,0.4,6.6,0,9.5-1.1c1.8-0.8,3.4-1.8,5.1-3.3l23-21.2c-3.2-2.9-4.3-5.9-3.2-8.9l1-1.8
	l0.8-1.8l1.1-2.2c0.8-1.5,2.2-2.6,4.1-3.2l18.8-4.7c1.8-0.6,1.9-2.3,0.3-5.5c-0.5-1.1-1.4-2.1-2.3-2.8l-0.8-0.6
	c-1.4-0.6-3.6-0.6-6.5,0l-12.4,3c-12.8,3.2-26.7,4-41.8,2.6c-53.9-3.9-106.5-2.8-157.8,3.6c51.3-6.3,104-7.4,157.8-3.6
	c15,1.4,28.9,0.6,41.8-2.6l12.4-3c-9.5,1-17.2,0.7-23.1-0.6c-8.4-1.2-15.3-3-20.8-5.4c-7.6-3.4-13.1-4.4-16.5-2.9
	c-8.3,1.1-16.5,0.7-24.9-1.4c-12.5-4-24.8-7.8-36.9-11.3c-26.5-7.7-52.3-14.4-77.2-19.9l-0.4-0.1l-1.4-0.3
	c-30-6.9-60.4-10.9-91.1-11.8c-28.1-0.1-51.9,3.3-71.5,10.2c-12.8,5.9-25.3,12.2-37.4,19c-1.8,1.1-3.6,2.1-5.4,3
	c-15.4,8.8-28.9,17.5-40.3,26.1l-31.2-1.1l-7.6-0.3l-77,2.8c-48.4,2.5-90.5,10.9-126.3,25.3c2.3,2.2,4.7,5.1,6.9,8.7
	c12-8.3,31.8-15.1,59.3-20.5l18.4-2.5c32.2-3.9,66.6-5.9,103-6.2l56.5-1.1h1.9l9.2-7l0.3-0.3c12.9-9.1,25.9-17.1,38.9-24.1
	c8.8-4.7,17.6-8.8,26.4-12.5c19.4-8,41.1-12,65.5-12.2c27.2,0.7,53.8,3.7,79.8,9.1c21,4,41.1,9.1,60.1,15.3L604,227.1L604,227.1z"
          />
          <path
            fill="#FFFFFF"
            d="M619.6,281.7c-19.1,0-35.5,6.2-49,18.4c-1.1,1-2.1,1.9-3,3c-3.4,3.6-6.3,7.3-8.8,11.1
	c-3.6,5.8-6.1,12.1-7.3,18.8c-1.8,9.9-2.9,22.1-3,36.6h8.1c0-11.6,0.7-21.9,2.2-30.8v-0.4c2.1-12,7.8-22.6,17.1-31.6
	c1.4-1.4,2.9-2.8,4.3-3.9c11.1-9.2,24.2-13.8,39.1-13.8c15,0,28.1,4.5,39.1,13.8c1.5,1.2,2.9,2.5,4.3,3.9l11.8-0.7l-3-3.2l-3-3
	C654.9,287.7,638.7,281.7,619.6,281.7L619.6,281.7z M538.2,212.5l-8.9-1.6l-15,36.5l20.6-1.1l12.1-31.9 M561.4,205.6l-1.2-0.3
	 M523.2,277.8c1.2-8.7-1-17.3-6.5-26L365.8,256l-47.2,0.8h-1.9c-7.6,5.5-11.8,13.5-12.8,23.9c-0.3,6.6,0,14,0.8,22.1
	c0.8,8,2.1,16.9,3.9,26.4l0.4,2.5c0.1,1.1,0.1,2.2,0,3.2c5.4-0.7,11.3-1.1,17.6-1.5l157-6.6c7.8,0.1,15.3,0.8,22.3,1.8
	c4-8.3,7.6-16.9,10.5-25.9C519.2,294.9,521.4,286.5,523.2,277.8L523.2,277.8z M494.8,278.1h-3.4l-7.8-0.4c-2.2-0.3-4.3-0.5-6.2-1.1
	c-1.5-0.4-2.8-0.8-3.7-1.2c-0.7-0.3-1.1-0.7-1.5-1c-0.4-0.4-0.6-0.8-0.6-1.2s0.1-0.8,0.5-1.2c0.3-0.4,0.8-0.7,1.5-1
	c1-0.5,2.2-1,3.7-1.4c1.9-0.5,4-0.8,6.2-1.1l7.7-0.4l3.6,0.1c4,0.1,7.4,0.5,10.5,1.4c1.5,0.4,2.8,0.8,3.7,1.2c0.8,0.4,1.5,1,1.8,1.4
	c0.1,0.3,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.5-1,1-1.8,1.4c-1,0.4-2.2,0.8-3.7,1.2C502.4,277.6,498.9,278,494.8,278.1
	L494.8,278.1z"
          />
          <path
            fill="#FFFFFF"
            d="M491.5,278.2h3.4c1.1-1,2.1-1.8,2.6-2.6h-16.1c0.4,0.8,1.1,1.5,2.1,2.3L491.5,278.2L491.5,278.2z"
          />
          <path
            fill="#FFFFFF"
            d="M477.5,276.7c1.9,0.5,3.9,0.8,6.2,1.1c-1-0.8-1.6-1.5-2.1-2.3h-7.8C474.6,275.9,475.8,276.3,477.5,276.7z
	 M483.7,268.5c-2.2,0.3-4.4,0.5-6.2,1.1c-1.6,0.4-2.9,0.8-3.7,1.4h7.8C482,270.1,482.7,269.3,483.7,268.5L483.7,268.5z"
          />
          <path
            fill="#FFFFFF"
            d="M491.5,268.1l-7.7,0.4c-1,0.8-1.7,1.5-2.2,2.3H498c-0.5-1-1.5-1.8-2.9-2.6L491.5,268.1L491.5,268.1z
	 M472.3,274.4c0.3,0.3,0.8,0.7,1.5,1h35.4c0.8-0.4,1.5-1,1.8-1.4c0.1-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.5-1-1-1.8-1.4
	h-35.5c-0.7,0.3-1.1,0.7-1.5,1c-0.4,0.4-0.5,0.8-0.5,1.2C471.7,273.6,471.8,274,472.3,274.4L472.3,274.4z M509.1,275.5h-11.6
	c-0.5,0.8-1.5,1.8-2.6,2.6c4-0.1,7.6-0.7,10.6-1.4C506.9,276.3,508.2,275.9,509.1,275.5L509.1,275.5z"
          />
          <path
            fill="#FFFFFF"
            d="M495.1,268.2c1.4,0.8,2.3,1.8,2.9,2.6h11.3c-1-0.4-2.2-1-3.7-1.2C502.4,268.9,498.9,268.3,495.1,268.2
	L495.1,268.2z M521,191c-25.7-3.7-52.4-5-80.2-3.9c-18.8,1.4-34.8,4.5-48,9.6l4.8,3.6c1.8,1.2,1.6,2.3-0.3,3.4
	c19.7-6.9,43.5-10.3,71.5-10.2c30.7,1.1,61.1,4.9,91.1,11.8l8-6.3C553.3,195.8,537.6,193.1,521,191L521,191z M496.7,345.5l3.4-5.9
	L309,345c0.3,1.4,0.7,2.8,1.1,4.3l3.4,11.1c0.7,0.7,1.6,1.2,2.8,1.5h4.5l147.7-2.1C482,359.4,491.4,354.7,496.7,345.5L496.7,345.5z
	 M505.8,328.9c-1.8,3.6-3.6,7.2-5.6,10.7l21.6-0.5c11.1,0,14.7-1.1,10.6-3.2C524.5,332.7,515.7,330.4,505.8,328.9L505.8,328.9z"
          />
          <path
            fill="#FFFFFF"
            d="M500.3,339.6c1.9-3.4,3.9-7,5.6-10.7c-6.9-1.1-14.3-1.6-22.3-1.8l-157,6.6c-6.5,0.4-12.4,1-17.6,1.7v0.4
	c-0.1,1.1-0.1,2.3-0.3,3.4c-0.1,1.9,0,3.9,0.4,5.8L500.3,339.6z M556.6,369.7h-9.4c-15.1-3.2-30.1-4.7-45.3-4.4l-202.2,3.6
	l-10.3,0.5l-8.4,0.8l-15.3,2.2h-8v1.2l298.8-1.5L556.6,369.7L556.6,369.7z"
          />
          <path
            fill="#FFFFFF"
            d="M556.4,377v-5l-298.8,1.5l-0.1,3.6l1.2,0.1H272l6.3,1.9l42-2.2h203.2 M678.1,331.6c0.8,2.8,1.5,5.6,1.9,8.5
	c1,7.3,1.5,16,1.5,25.9l8.1-0.5c0.1-12-0.4-22.1-1.8-30.7c-0.5-3-1.2-6.1-2.2-8.9c-2.3-7-6.1-13.5-11.3-19.4l-11.8,0.7
	C670.1,314.3,675.3,322.4,678.1,331.6L678.1,331.6z"
          />
          <path
            fill="#FFFFFF"
            d="M685.8,325.7c1,2.9,1.7,5.9,2.2,8.9c1.2,8.5,1.9,18.7,1.8,30.7l5.8-0.4c11.1-3.2,26.1-5.9,44.8-8.1
	c4.1-1.1,8.5-3.3,13.1-6.6c4-2.8,8.1-6.7,12.4-11.7c1.7-2.1,4.7-3.4,8.9-4H791c1.1,0,1.5-0.7,1.4-1.9l-0.5-4.1
	c-2.3-1.6-3.7-3.7-4.3-6.3l-3.3-11.7l3.2-10l1.4-0.4c1.8-0.7,2.3-1.5,1.9-2.8c-0.3-1.1-1-2.2-1.9-3.6c-0.7-1.1-1.7-2.2-2.8-3.3
	l-3.7-0.1l-17.6-0.4c-3,0-5.1-0.4-6.1-1.2h-0.1l-10.9-10L725,299.8c-1.5,1.4-3.2,2.5-5.1,3.3c-2.9,1.2-6.1,1.5-9.5,1.1l-35.9,2.1
	C679.7,312.2,683.4,318.7,685.8,325.7L685.8,325.7z M704.6,337.1c-1.1-1.2-0.4-1.9,1.9-2.1l27.7-1.7c1.9,0.1,1.9,1,0,2.5
	c-1.5,1.4-2.9,2.1-4.1,2.1l-20.6,1.4C707.1,339.2,705.6,338.5,704.6,337.1L704.6,337.1z"
          />
          <path
            fill="#FFFFFF"
            d="M706.5,334.9c-2.3,0.1-2.9,0.8-1.9,2.1c1,1.4,2.5,2.1,4.7,2.1l20.6-1.4c1.2,0,2.6-0.7,4.1-2.1
	c1.9-1.5,1.9-2.3,0-2.5L706.5,334.9L706.5,334.9z M769.8,258.3c1-1.1,1.2-1.9,0.8-2.5l-18.8,4.7c-1.9,0.5-3.3,1.6-4.1,3.2l-1.1,2.2
	c0.5-0.7,1.1-1.2,1.7-1.8c1.2-1,2.8-1.6,4.5-2.2L769.8,258.3L769.8,258.3z"
          />
          <path
            fill="#FFFFFF"
            d="M765.7,264.5l3-5.1l1.1-1l-17.1,3.7c-1.8,0.4-3.3,1.2-4.5,2.2l1.1,1.5l5.2-2.6l0.3,0.8l4.4-1.9l1.1,2.5
	l0.7,0.8L765.7,264.5L765.7,264.5z"
          />
          <path
            fill="#FFFFFF"
            d="M765.4,264.9l0.3-0.4l-4.5,1l15.1,19.7c1,1.2,0.7,2.2-0.8,2.6c-2.1,0.3-3.7-0.1-4.8-1.2l-0.5,0.1
	c-1.5,0.4-3.6,0-6.2-1.2c-0.8,1.5-2.8,1.5-5.9-0.3l-12.5-17.5l-1,1.8c-1.1,3.2,0,6.1,3.2,8.9l10.9,10h0.1c1,0.8,3,1.2,6.1,1.2
	l17.6,0.4c2.8-0.7,3.2-1.5,1.2-2.5l-5-3l-11.1-16.8L765.4,264.9L765.4,264.9z"
          />
          <path
            fill="#FFFFFF"
            d="M771.2,268.9c-1-1.4-2.1-1.5-3.4-0.5l11.1,16.8c1.9-1.1,2.5-2.1,1.4-3L771.2,268.9L771.2,268.9z M64.1,292.3
	l1.5,4.3l2.6,7.4c0.8,1.5,1,3.2,0.6,5c-0.1,0.8-0.6,1.7-1.1,2.6l-1.1,2.3l-0.8,2.1c-1.9,5.5-2.9,12.2-2.9,20.2
	c0,1.1,1.2,1.6,4.1,1.6l10.6-0.3c-1.7-0.3-2.3-1.5-1.9-3.7c0-2.2,1.4-3.6,3.9-3.9c-5.1,0.1-9.6,0.5-14,1.5c4.3-0.8,8.9-1.4,14-1.5
	h6.6c5,0,8.3,1.1,10,3.2l2.8,3.6l2.9,3.7l10.6,13.6c3.2,3.9,3.2,5.8,0,5.9l-47.2,1c1,1.2,2.8,1.8,5.2,1.6l36.5,0.8
	c2.8,0.1,5.6,0.4,8.4,0.8l9.6,1.2c0-12.7,0.8-23.9,2.6-33.6c1.2-6.7,3.7-12.9,7.3-18.8c2.1-3.4,4.7-6.7,7.6-9.9
	c1.4-1.5,2.8-2.9,4.3-4.3c3.6-3.2,7.3-6.1,11.1-8.4l-10.5-7.7l-14.4,11.3c-3.9,2.5-7.8,4-12.1,4.8l-27.1,3.6
	c-4.3,0.4-7.2-0.5-8.4-2.9L79,287.3c-2.2-3.6-4.5-6.5-6.9-8.7c-1.5-1.4-3-2.5-4.5-3.3c-3.9,1.9-7.6,4-11.1,6.1
	c-1.8,1.5-1.7,2.9,0.4,4.1C60.7,286.5,63,288.7,64.1,292.3L64.1,292.3z"
          />
          <path
            fill="#FFFFFF"
            d="M67.7,275.2c1.5,0.8,3,1.9,4.5,3.3c35.8-14.4,77.9-22.8,126.3-25.3l77-2.8l3-5.1
	c-26.3-0.6-54.9,0.1-85.7,2.2c-37,3.2-66.6,7.7-88.9,13.9C90.7,265.2,78.6,269.7,67.7,275.2L67.7,275.2z"
          />
          <path
            fill="#FFFFFF"
            d="M79.1,287.2c7.7-1.1,15.4-2.1,23.1-3c12.7-8.3,31-14.9,54.6-19.9l-18.4,2.5
	C110.9,272.1,91.1,278.8,79.1,287.2L79.1,287.2z M134.9,337.7v-0.4c2.1-12,7.8-22.6,17.1-31.6l2.8-2.6c3.2-2.9,6.6-5.4,10.3-7.4
	l-7.2-5.4c-3.9,2.3-7.6,5.2-11.1,8.4c-1.5,1.4-3,2.8-4.3,4.3c-2.9,3.2-5.4,6.5-7.6,9.9c-3.6,5.8-6.1,12.1-7.3,18.8
	c-1.8,9.6-2.6,20.9-2.6,33.6l7.7,0.4v-1.5C132.9,354.3,133.6,345.5,134.9,337.7L134.9,337.7z M68.8,308.9c0.4-1.8,0.3-3.3-0.6-5
	l-2.6-7.4l1.9,11.4L68.8,308.9L68.8,308.9z M79.7,330c-2.6,0.3-3.9,1.6-3.9,3.9c-0.4,2.2,0.1,3.4,1.9,3.7h1.4l6.1-0.3l3.3-0.1
	l10.9-0.4l-2.8-3.6c-1.8-2.1-5.2-3.2-10-3.2C84.1,329.8,81.7,329.8,79.7,330L79.7,330z"
          />
          <path
            fill="#FFFFFF"
            d="M78.8,337.5h-1.4l-10.6,0.3c-2.8,0-4.1-0.5-4.1-1.6c0-8,1-14.7,2.9-20.2l-5,0.8l-5.8,1
	c-3,0.7-4.3,1.8-4.1,3.2c0,4.4,0.5,9.5,1.7,15.4c0.1,1.2,1.4,2.1,3.7,2.6l9.6,1.8c0.6-0.5,1.2-0.8,2.1-1.1l10.5-1.1L78.8,337.5
	L78.8,337.5z M85,337.3l-6.1,0.3l-0.3,1c-1.1,3.9-1.7,7.7-1.7,11.6c0,3.2,1.4,4.7,4.3,4.7l31.5-0.5l-10.6-13.6l-4-0.1
	c0.6,0.7,1,1.5,1.4,2.5c1.4,3.9,1.1,6.7-1,8.7c-1.7,1.6-4.1,2.2-7.4,1.5h-4.5c-2.5-0.1-3.7-1.9-3.6-5.2
	C82.8,344,83.5,340.6,85,337.3L85,337.3z"
          />
          <path
            fill="#FFFFFF"
            d="M78.6,338.5l-10.5,1.1c-0.8,0.1-1.5,0.5-2.1,1.1c-1.1,1-1.7,2.3-1.9,4.3l-2.2,9.4c-0.1,1.4-1.2,2.2-3.3,2.5
	c-3.7,1.1-5.2,2.5-4.8,4.3l2.6-0.4c3.4-0.4,6.1-1.5,8-3.3c-0.1,1.5,0.1,2.8,0.8,3.6l47.5-0.8c3.2-0.1,3.2-2.1,0-5.9l-31.5,0.4
	c-2.9,0-4.3-1.5-4.3-4.7C76.9,346.2,77.4,342.3,78.6,338.5L78.6,338.5z M99.2,336.7l-10.9,0.4v0.3c4.5-0.8,7.7,0.1,9.8,2.9l4,0.1
	L99.2,336.7L99.2,336.7z"
          />
          <path
            fill="#FFFFFF"
            d="M98.1,340.3c-2.1-2.8-5.2-3.7-9.8-2.9l1.4,15.3l1.2,0.3c3.2,0.7,5.6,0.1,7.4-1.5c2.1-1.9,2.5-4.8,1-8.7
	C99,341.9,98.6,341.1,98.1,340.3L98.1,340.3z M132.6,365.7l-7.7-0.4l-9.6-1.2c-2.8-0.4-5.6-0.7-8.4-0.8l-36.5-0.8
	c-2.6,0.1-4.3-0.4-5.2-1.6c-0.7-0.8-1-2.1-0.8-3.6c-1.8,1.8-4.4,2.9-8,3.3l-2.6,0.4l-1.9,0.3h-0.3c-2.5,0.7-3,1.5-1.4,2.6
	c3.2,1.1,7.4,1.8,13.1,2.1l12.2,1l33.8,2.8l13.3,0.7l9.8,0.4L132.6,365.7L132.6,365.7z M66.7,313.9l-5.8,2.9l5-0.8 M283.2,250.6
	c3.3-1.8,6.5-3.6,9.6-5.4h-14l-3,5.1L283.2,250.6L283.2,250.6z M157.9,290.4l7.2,5.4c9.1-5.2,19.1-7.7,30.4-7.7
	c15.7,0,29.2,5.1,40.7,15.1l2.8,2.6c7.4,7.3,12.5,15.4,15.4,24.5c0.8,2.8,1.5,5.6,1.9,8.5c1.2,9.1,1.8,20.2,1.5,33.6h8
	c0.5-15.5,0-28.6-1.5-38.9c-0.5-3-1.2-6.1-2.2-8.9c-2.5-7.4-6.5-14.2-12.1-20.4l-0.8-1c-1.4-1.5-2.9-2.9-4.4-4.3
	c-13.5-12.4-29.9-18.4-49-18.4C181.6,280.3,168.9,283.6,157.9,290.4L157.9,290.4z M257.5,377.2v0.1h1.2 M309,335.6v-0.4
	c-12.7,1.5-22.3,3.6-28.8,6.3c-6.6,3-3.4,4.4,9.5,4l19.4-0.5c-0.4-2.1-0.5-4-0.4-5.8C308.8,337.9,308.8,336.8,309,335.6z
	 M316.5,361.9c1.2,0.3,2.8,0.3,4.5,0H316.5z M378.3,246.3l-0.8-5c-0.4-2.6-2.1-4.4-5-5.4c2.6,1,3.9,4.4,3.7,10.3L378.3,246.3
	L378.3,246.3z M516.7,252l-1.9-2.9c-17.6,0.8-35.5,1.4-53.4,1.5l-84.9,0.7c-0.1,0.1-0.4,0.3-0.5,0.3c-4,1.2-9.8,1.9-17.1,1.8l-3,2.7
	h-10.6c-2.5,0-4.7-0.1-6.5-0.7c-1-0.3-1.8-0.6-2.6-1c0.5-1.2,1.6-2.2,3.3-2.9l-10.9,0.1l-0.7-0.1c-0.1-0.1-0.3-0.3-0.4-0.6
	c-0.1-0.3-0.1-0.4,0-0.7l0.1-0.3l-9.2,7l47.2-0.8L516.7,252L516.7,252z"
          />
          <path
            fill="#FFFFFF"
            d="M338.8,255.4c1.8,0.6,4,0.7,6.5,0.7h10.6l3-2.8c7.3,0.1,13.1-0.6,17.1-1.8c0.3-0.1,0.4-0.1,0.5-0.3
	c0.4-0.3,0.7-0.6,1-0.8c-7,0.1-13.2,0.6-18.6,1.1C349.7,252.2,342.9,253.6,338.8,255.4L338.8,255.4z"
          />
          <path
            fill="#FFFFFF"
            d="M514.8,249.1l-0.5-1.8c-17.5,0.8-35.1,1.4-52.8,1.5l-83.5,0.7l-0.4,0.8c-0.3,0.4-0.5,0.7-1,0.8l84.9-0.7
	C479.3,250.5,497.2,249.9,514.8,249.1L514.8,249.1z"
          />
          <path
            fill="#FFFFFF"
            d="M339.5,251.6c-1.8,0.6-2.9,1.6-3.3,2.9c0.8,0.4,1.6,0.7,2.6,1c4.3-1.8,10.9-3.2,20.1-4
	c5.4-0.6,11.6-0.8,18.6-1.1c0.1-0.3,0.3-0.6,0.4-0.8c0-0.1,0.1-0.4,0.1-0.6h-12.4c-5.5,0-10.6,0.3-15.1,0.8
	c-1.6,0.1-3.2,0.4-4.5,0.6c-1.6,0.3-3.3,0.6-4.8,0.8C340.5,251.3,339.9,251.4,339.5,251.6L339.5,251.6z"
          />
          <path
            fill="#FFFFFF"
            d="M345.8,250.3c1.5-0.3,3-0.4,4.5-0.6h-5.1L345.8,250.3z M345.3,249.8h5.1c4.5-0.4,9.6-0.7,15.1-0.8h12.4
	c0.1-0.7,0.3-1.6,0.3-2.6h-2.1c-12.5-0.1-23.1,0.3-31.9,1.5C344.5,248.5,344.7,249.1,345.3,249.8L345.3,249.8z"
          />
          <path
            fill="#FFFFFF"
            d="M604.9,231.7c1.8-2.1,1.4-3.7-0.8-4.7l-5.4-1.9c-19-6.2-39.1-11.3-60.1-15.3c-26-5.4-52.5-8.4-79.8-9.1
	c-24.4,0.1-46.2,4.3-65.5,12.2c-8.8,3.7-17.6,7.8-26.4,12.5c-13.1,6.9-26,15-38.9,24.1l-0.3,0.3l-0.1,0.3c-0.1,0.3-0.1,0.4,0,0.7
	c0,0.3,0.1,0.4,0.4,0.6l0.7,0.1l10.9-0.1c0.4-0.1,1-0.3,1.5-0.4c1.5-0.3,3.2-0.6,4.8-0.8l-0.5-0.6l-13.2,0.1
	c7.7-5.4,15.3-10.2,23-14.7c5.8-3.4,11.6-6.6,17.3-9.5c7.2-3.7,14.4-7,21.6-10c19.1-7.8,40.6-11.8,64.7-12c23.9,0.6,47.3,3,70.3,7.3
	l9.1,1.8l8.8,1.6c15.4,3.2,30.1,6.9,44.4,11.4l5.8,1.8c2.3,1.1,2.6,2.5,0.7,4.5c-2.3,2.5-4.7,4.5-6.7,6.1c-2.9,2.2-6.2,3.7-9.9,4.3
	l-0.3,0.1c-15.3,1.5-30.7,2.9-46.1,3.9l-20.5,1.1l0.5,1.8c22.3-1.1,44.4-2.8,66.4-5.1c6.9-0.4,12-1.7,15.3-4
	C599.2,238.1,602,235.3,604.9,231.7L604.9,231.7z"
          />
          <path
            fill="#FFFFFF"
            d="M344.3,247.8c-0.1-1,0.3-1.9,1.1-2.9c2.2-2.3,5.4-4.9,9.6-7.7V235c-7.7,4.5-15.4,9.5-23,14.7l13.2-0.1
	C344.7,249.1,344.5,248.5,344.3,247.8L344.3,247.8z"
          />
          <path
            fill="#FFFFFF"
            d="M345.6,245.1c-1,1-1.2,1.9-1.1,2.9c8.7-1.2,19.3-1.8,31.9-1.5c0.1-5.9-1.1-9.4-3.7-10.3
	c-1.2-0.4-2.8-0.7-4.5-0.8c-4.1-0.6-8,0-11.8,1.5c-0.3,0.1-0.5,0.4-1,0.6C350.9,240.1,347.8,242.8,345.6,245.1L345.6,245.1z"
          />
          <path
            fill="#656565"
            d="M558.8,338.6v0.4c-1.5,8.9-2.2,19.1-2.2,30.8v2.5l-0.1,4.8h18.4c-2.9-4.3-5-8.7-6.5-13.5
	c-1.7-5.1-2.3-10.6-2.3-16.4c0-15,5.4-27.8,16-38.4c2.1-2.1,4.3-4,6.5-5.6c9.2-6.9,19.9-10.3,31.9-10.3c12.1,0,22.7,3.4,31.9,10.3
	c2.2,1.6,4.4,3.6,6.5,5.6c5.2,5.2,9.2,11,11.8,17.2c2.8,6.5,4.1,13.6,4.1,21.2c0,10.5-2.6,19.9-7.8,28.3l14.7-4.5v-4.8
	c0-9.9-0.5-18.6-1.5-25.9c-0.4-2.9-1.1-5.8-1.9-8.5c-2.9-9.1-8-17.3-15.3-24.5c-1.4-1.4-2.9-2.8-4.3-3.9
	c-11.1-9.2-24.2-13.8-39.1-13.8c-15,0-28.1,4.5-39.1,13.8c-1.5,1.2-2.9,2.5-4.3,3.9C566.6,316.1,561,326.5,558.8,338.6L558.8,338.6z
	"
          />
          <path
            fill="#656565"
            d="M523.4,377v5.2H563l0.1-5.2h-6.7 M658.7,308.6c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3
	c-12.1,0-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4
	c1.5,4.8,3.6,9.2,6.5,13.5c2.1,3,4.4,5.9,7.2,8.5c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c3.2-3.2,5.9-6.6,8.1-10.2
	c5.2-8.4,7.8-17.7,7.8-28.3c0-7.6-1.4-14.7-4.1-21.2C667.9,319.7,663.9,313.9,658.7,308.6L658.7,308.6z M660.2,347.2
	c0,11-3.8,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7c-11,0-20.4-3.9-28.2-11.7c-7.8-7.8-11.7-17.2-11.7-28.2s3.9-20.4,11.7-28.2
	s17.2-11.7,28.2-11.7s20.4,3.9,28.2,11.7C656.3,326.7,660.2,336.2,660.2,347.2L660.2,347.2z"
          />
          <path
            fill="#656565"
            d="M647,323.1l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4l-2.9,10.6c2.1,1.1,4,2.6,5.8,4.3L647,323.1L647,323.1z
	 M648.2,324.6l-8.8,6.3c4,4.5,5.9,10,5.9,16.2v1.9H656v-1.9C656.2,338.5,653.6,330.9,648.2,324.6L648.2,324.6z M641.7,360.4
	c-1,1.6-2.2,3.2-3.7,4.5c-3.4,3.4-7.4,5.6-11.8,6.7l3.3,10.3c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8L641.7,360.4
	L641.7,360.4z M645,351.4c-0.4,2.5-1.1,4.7-2.2,6.9l8.5,6.9c2.6-4.3,4.1-9.1,4.5-14.2L645,351.4z M633.3,313.6
	c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2l3.3,10.3c2.9-1.1,6.1-1.8,9.4-1.8c3.4,0,6.7,0.5,9.8,1.9L633.3,313.6L633.3,313.6
	z M602.8,329c1.8-1.8,3.7-3.2,5.9-4.3l-2.9-10.6c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2L602.8,329L602.8,329z M601.3,330.7l-8.8-6.3
	c-5.4,6.5-8.1,14-8.1,22.8v1.8h10.7v-1.8C595.1,340.8,597.2,335.3,601.3,330.7L601.3,330.7z M597.6,358.2c-1.1-2.2-1.8-4.5-2.2-7
	l-10.9-0.5c0.4,5.2,1.9,10,4.5,14.3L597.6,358.2z M598.8,360.2l-8.8,6.3c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4l3.4-10.3
	c-4.4-1.1-8.4-3.3-11.8-6.7C601,363.4,599.8,361.9,598.8,360.2L598.8,360.2z M616.6,372.1l-3.9,10.3c2.3,0.5,5,0.7,7.6,0.7
	s5.1-0.3,7.4-0.7l-3.9-10.3l-3.6,0.3L616.6,372.1z M689.7,365.3l-8.1,0.5v4.8l60.4-6.7c2.1-0.3,3.7-0.7,5.4-1.1
	c1.9-0.5,3.7-1.4,5-2.2c0.7-0.4,1.4-1,1.9-1.5l7.4-9.4l5.6-7.2l1.4-1.6c0.7-0.7,1.4-1.4,2.2-1.9c2.1-1.4,4.8-2.2,8.1-2.5l14.2-0.1
	c0.5-0.8-0.1-1.4-2.1-1.9h-16.2c-4.3,0.5-7.3,1.9-9.1,4c-4.3,5-8.4,8.9-12.4,11.7c-4.7,3.3-9.1,5.5-13.1,6.6
	c-18.7,2.2-33.6,5-44.8,8.1L689.7,365.3L689.7,365.3z"
          />
          <path
            fill="#656565"
            d="M780.3,342.8c1.1-1.1,2.6-1.9,4.5-2.3c1.5-0.4,3.4-0.5,5.6-0.5l-0.1-2.3l-12.4,0.8c1.2,0.3,1.2,1.1,0,2.2
	l-1.1,1.4c-0.5,0.4-1.2,0.7-2.2,0.5h-7.4l-5.6,7.2h0.1l0.3-0.3l2.2-2.3c1-1,1.7-1.4,2.2-1.5h0.5c0.4,0.3,0.1,1.2-0.7,3l-0.5,1.2h1.9
	c0.4,0.3,0.1,1.1-0.5,2.8c-0.7,1.5-1.8,3.3-3.3,5.2l-2.5,3L780.3,342.8L780.3,342.8z"
          />
          <path
            fill="#656565"
            d="M784.8,340.4c-1.9,0.4-3.4,1.2-4.5,2.3l-18.8,18l-1.5,1.5c-1,0.8-1.7,1.4-2.1,1.4h-0.1l-2.6,0.4l0.4,1.1
	c2.9-0.7,5.8-1.5,8.8-2.3l12.9-12l6.7-4c2.2-1.1,2.9-2.5,2.2-4.1L784.8,340.4L784.8,340.4z"
          />
          <path
            fill="#656565"
            d="M784.8,340.4l1.1,2.2c0.7,1.8,0,3.2-2.2,4.1l-6.7,4l-12.9,12c6.1-1.9,12.5-4.3,19-7
	c1.2-6.5,3.7-11.7,7.3-15.8C788.2,339.9,786.4,340,784.8,340.4L784.8,340.4z M792.2,337.5l1.1-1.1l-14.2,0.1
	c-3.3,0.3-5.9,1.1-8.1,2.5l7-0.5l12.4-0.8L792.2,337.5L792.2,337.5z M767.1,345.5h-0.4c-0.5,0.1-1.2,0.7-2.2,1.5l-2.2,2.3l-0.3,0.3
	h3.9l0.5-1.2C767.2,346.9,767.5,345.9,767.1,345.5L767.1,345.5z"
          />
          <path
            fill="#656565"
            d="M768.7,341l-1.4,1.6h7.4c1,0.1,1.7-0.1,2.2-0.5l1.1-1.4c1.2-1.1,1.2-1.9,0-2.2l-7,0.5
	C770.1,339.5,769.4,340.1,768.7,341L768.7,341z M767.7,349.8c-0.1-0.1-0.3-0.1-0.4,0c-0.4,0.1-1.1,0.5-2.1,1.4l-2.1,2.2
	c-0.7,0.7-1.2,1.5-1.9,2.5l-1.5,2.1c-0.7,1.1-1.4,2.2-1.8,3c-0.7,1.5-1,2.5-0.5,2.8h0.3c0.4,0,1.1-0.5,2.1-1.4l1.5-1.5l2.5-3
	c1.4-1.9,2.5-3.6,3.3-5.2C767.9,351,768.2,350.1,767.7,349.8L767.7,349.8z"
          />
          <path
            fill="#656565"
            d="M763.3,353.4l2.1-2.2c1-0.8,1.5-1.2,2.1-1.4H762l-7.4,9.4c-0.5,0.6-1.2,1.1-1.9,1.5c-1.7,1.5-2.2,2.8-1.7,4
	l4.3-0.5l2.6-0.4h-0.1c-0.4-0.3-0.1-1.1,0.5-2.8c0.4-1,1.1-1.9,1.8-3l1.5-2.1C762,354.9,762.7,354,763.3,353.4L763.3,353.4z"
          />
          <path
            fill="#656565"
            d="M750.7,364.6c-0.5-1.1-0.1-2.5,1.7-4c-1.4,0.8-3,1.6-5,2.2L750.7,364.6L750.7,364.6z M134.9,337.3v0.4
	c-1.4,7.8-2.1,16.6-2.2,26.5L144,366l-0.8-2.5c-1.6-5.1-2.3-10.6-2.3-16.4c0-15,5.4-27.8,16-38.4c2.1-2.1,4.3-4,6.5-5.6
	c9.2-6.9,19.9-10.3,31.9-10.3c12.1,0,22.7,3.4,31.9,10.3c2.2,1.6,4.4,3.6,6.5,5.6c5.2,5.2,9.2,11,11.8,17.2
	c2.8,6.5,4.1,13.6,4.1,21.2c0,10.3-2.5,19.7-7.6,27.9l10.6,1.4l5.1,0.7l0.1-3.4v-1.2c0.3-13.3-0.1-24.5-1.5-33.6
	c-0.4-2.9-1.1-5.8-1.9-8.5c-2.9-9.1-8-17.3-15.4-24.5l-2.8-2.6c-11.4-10-25-15.1-40.7-15.1c-11.3,0-21.3,2.6-30.4,7.7
	c-3.6,2.1-7,4.5-10.3,7.4l-2.8,2.6C142.8,314.7,137.2,325.3,134.9,337.3L134.9,337.3z M75.7,367v5.6c7.2,2.2,17.2,3.4,30.3,3.7h16.9
	v-5.9l-13.3-0.7L75.7,367z"
          />
          <path
            fill="#656565"
            d="M233.4,308.6c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3
	c-2.2,1.6-4.4,3.6-6.5,5.6c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4l0.8,2.5c2.6,7.2,6.9,13.8,12.7,19.5
	c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c3.3-3.3,6.1-6.9,8.4-10.6c5.1-8.3,7.6-17.5,7.6-27.9c0-7.6-1.4-14.7-4.1-21.2
	C242.7,319.6,238.7,313.9,233.4,308.6L233.4,308.6z M235,347.2c0,11-3.9,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7
	c-11,0-20.4-3.9-28.2-11.7c-7.8-7.8-11.7-17.2-11.7-28.2s3.9-20.4,11.7-28.2s17.2-11.7,28.2-11.7c11,0,20.4,3.9,28.2,11.7
	C231.1,326.7,235,336.2,235,347.2L235,347.2z"
          />
          <path
            fill="#656565"
            d="M221.9,323.1l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4l-2.9,10.6c2.1,1.1,4,2.6,5.8,4.3L221.9,323.1L221.9,323.1z
	 M223.1,324.6l-8.8,6.3c4,4.5,5.9,10,5.9,16.2v1.9H231v-1.9C231,338.5,228.4,330.9,223.1,324.6L223.1,324.6z M216.5,360.4
	c-1,1.6-2.2,3.2-3.7,4.5c-3.4,3.4-7.4,5.6-11.8,6.7l3.3,10.3c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8L216.5,360.4
	L216.5,360.4z M220,351.4c-0.4,2.5-1.1,4.7-2.2,6.9l8.5,6.9c2.6-4.3,4.1-9.1,4.5-14.2L220,351.4z M208.1,313.6
	c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2l3.3,10.3c2.9-1.1,6.1-1.8,9.4-1.8c3.4,0,6.7,0.5,9.8,1.9L208.1,313.6L208.1,313.6
	z M177.7,329c1.8-1.8,3.7-3.2,5.9-4.3l-2.9-10.6c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2L177.7,329L177.7,329z M170,348.8V347
	c0-6.3,2.1-11.8,6.2-16.5l-8.8-6.3c-5.4,6.5-8.1,14-8.1,22.8v1.8H170L170,348.8z M170.3,351.2l-10.9-0.5c0.4,5.2,1.9,10,4.5,14.3
	l8.5-6.9C171.4,356,170.6,353.6,170.3,351.2L170.3,351.2z M173.6,360.2l-8.8,6.3c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4
	l3.4-10.3c-4.4-1.1-8.4-3.3-11.8-6.7C175.8,363.4,174.6,361.9,173.6,360.2L173.6,360.2z M191.5,372.1l-3.9,10.3
	c2.3,0.5,5,0.7,7.6,0.7c2.6,0,5.1-0.3,7.4-0.7l-3.9-10.3l-3.6,0.3L191.5,372.1L191.5,372.1z M252.4,376.5l2.2,5.8h62.2l3.4-5.2
	l-42,2.2l-6.3-1.9h-14.4v-0.1"
          />
          <path
            fill="#EBEBEB"
            d="M607.2,209.1c-12.2-3.9-25.3-7.2-39.1-10l-8,6.3l1.2,0.3l0.4,0.1c24.9,5.5,50.6,12.1,77.2,19.9
	c12.1,3.6,24.3,7.3,36.9,11.3c8.3,2.1,16.5,2.5,24.9,1.4c-12.5-4.4-25-8.7-37.7-12.7C644.5,219.6,625.8,214.1,607.2,209.1
	L607.2,209.1z M597,227.5l-5.6-1.8c-14.3-4.4-29-8.3-44.4-11.4l-12.1,31.9c15.4-1,30.8-2.3,46.1-3.9l0.3-0.1c3.7-0.6,7-1.9,9.9-4.3
	c2.1-1.5,4.4-3.6,6.7-6.1C599.6,230,599.4,228.4,597,227.5L597,227.5z M648.5,375.4c7.8-7.8,11.7-17.2,11.7-28.2
	s-3.8-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7c-11,0-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2
	c7.8,7.8,17.2,11.7,28.2,11.7S640.6,383.1,648.5,375.4L648.5,375.4z M658,347.2c0,10.5-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11
	s-19.3-3.7-26.7-11c-7.3-7.4-11-16.2-11-26.7s3.7-19.3,11-26.7c7.4-7.4,16.2-11,26.7-11s19.3,3.7,26.7,11
	C654.3,327.8,658,336.7,658,347.2L658,347.2z"
          />
          <path
            fill="#EBEBEB"
            d="M647,373.7c7.3-7.4,11-16.2,11-26.7s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11
	c-7.3,7.4-11,16.2-11,26.7s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11C630.7,384.9,639.5,381.1,647,373.7z M645.7,321.7l1.4,1.4
	l1.2,1.4c5.4,6.5,8,13.9,8,22.6v1.9l-0.1,1.8c-0.6,5.2-2.1,9.9-4.5,14.2l-1,1.5c-1.4,2.1-3,4-4.8,5.8c-4.7,4.7-10,7.8-16.1,9.4
	L628,382c-2.3,0.5-5,0.7-7.4,0.7c-2.6,0-5.1-0.3-7.6-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4c-1.9-1.9-3.4-3.9-4.8-5.9l-1-1.5
	c-2.6-4.4-4.1-9.1-4.5-14.3c0-0.5-0.1-1.2-0.1-1.8v-1.8c0-8.7,2.8-16.4,8.1-22.8l1.2-1.4l1.2-1.2c3.3-3.3,7-5.8,10.9-7.6l1.7-0.7
	c4-1.5,8.3-2.2,12.8-2.2c4.7,0,9.1,0.8,13.1,2.3l1.7,0.7C638.8,315.9,642.4,318.4,645.7,321.7L645.7,321.7z"
          />
          <path
            fill="#EBEBEB"
            d="M639.5,330.9l8.8-6.3l-1.2-1.4l-9.1,6.1l-1.7,1.1c-1.1,0.7-2.1,0.8-3,0.6c-0.3-0.1-0.4-0.3-0.7-0.4l-0.5-0.5
	c-0.6-0.7-0.8-1.6-0.4-3l0.5-2.1l2.9-10.6l-1.7-0.7l-3.4,10.2l-3.6,11.1l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8
	l-0.3-1l-3.6-11.1l-3.3-10.3l-1.7,0.7l2.9,10.6l0.5,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.5,0.5l-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2
	l-9.2-6.1l-1.2,1.4l8.8,6.3l9.5,6.9l0.8,0.5c2.6,2.1,3.8,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4h-23.4c0,0.5,0,1.2,0.1,1.8l10.9,0.5
	l2.2,0.1c1.2,0.1,2.2,0.5,2.6,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5l-8.5,6.9l1,1.5l8.8-6.3l9.4-6.9l1-0.7
	c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1l-3.6,11.1l-3.4,10.3l1.8,0.4l3.9-10.3l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2
	h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1l3.9,10.3l1.8-0.4l-3.3-10.3l-3.6-11.1l-0.3-1c-1-3.2-0.5-5.8,1-7.7c2.5-1.4,5.4-1,8.3,1.1l0.8,0.5
	l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.7-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7c0.4-0.8,1.4-1.2,2.8-1.4l2.1-0.1
	l10.9-0.5l0.1-1.8h-23.8c-3.3,0-5.6-1.1-7-3.3c-0.4-2.8,0.7-5.4,3.6-7.6l0.7-0.5L639.5,330.9L639.5,330.9z M620.3,342.2
	c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4s-0.6,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4s-2.5-0.5-3.4-1.4c-1-1-1.4-2.1-1.4-3.4
	c0-1.4,0.5-2.5,1.4-3.4C617.8,342.8,618.9,342.2,620.3,342.2L620.3,342.2z"
          />
          <path
            fill="#EBEBEB"
            d="M623.7,343.7c-1-1-2.1-1.4-3.4-1.4s-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4
	c1,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4C625.1,345.8,624.7,344.7,623.7,343.7L623.7,343.7z M645.3,349.1
	v-1.9c0-6.2-1.9-11.7-5.9-16.2l-9.4,6.9c2.5,2.6,3.7,5.8,3.7,9.4l-0.1,1.9H645.3z M639.9,353.5c0,0.3-0.1,0.5-0.1,0.7
	c-0.1,1,0.4,1.8,1.4,2.6l1.7,1.4c1.1-2.2,1.8-4.4,2.2-6.9l-2.1,0.1c-1.4,0.1-2.2,0.5-2.8,1.4L639.9,353.5L639.9,353.5z M638,364.9
	c1.4-1.4,2.6-2.9,3.7-4.5l-9.4-6.9c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1C630.6,370.5,634.6,368.3,638,364.9
	L638,364.9z M630,323.8c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8l3.6,11.1c1.8-0.8,3.7-1.2,5.8-1.2c2.2,0,4.3,0.5,6.2,1.4
	L630,323.8L630,323.8z M607.6,330.7l0.7-0.4l0.5-0.5c0.6-0.7,0.8-1.6,0.4-3l-0.5-2.1c-2.1,1.1-4.1,2.5-5.9,4.3l1.8,1.2
	C605.7,330.9,606.8,331.1,607.6,330.7L607.6,330.7z M611.5,338.1l-0.8-0.5c-2.6,2.6-4,5.8-4,9.6l0.1,1.8h1.1c3.6,0,6.1-1.4,7.3-4
	C615.3,342.3,614.1,340.1,611.5,338.1L611.5,338.1z M613.3,342.8l1.2,1.6l-0.8,1.8l-1.9,0.3l-1.2-1.7l0.8-1.8L613.3,342.8
	L613.3,342.8z"
          />
          <path
            fill="#EBEBEB"
            d="M614.5,344.4l-1.2-1.6l-1.9,0.3l-0.8,1.8l1.2,1.7l1.9-0.3 M614.5,334.8l0.3,1c1.1,3.4,3.2,5.4,5.9,5.8
	c2.5-0.5,4.3-2.5,5.4-5.6l0.3-1c-1.9-1-3.9-1.4-6.2-1.4C618.1,333.5,616.1,334,614.5,334.8L614.5,334.8z M618.2,338.6l1.1-1.6
	l1.9,0.1l0.8,1.8l-1.1,1.6l-2.1-0.3L618.2,338.6L618.2,338.6z"
          />
          <path
            fill="#EBEBEB"
            d="M619.5,337l-1.2,1.6l0.8,1.8l2.1,0.3l1.1-1.6l-0.8-1.8 M633.2,330.8c0.8,0.4,1.9,0.3,3-0.5l1.7-1.1
	c-1.8-1.8-3.7-3.2-5.8-4.3l-0.5,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.5,0.5C632.8,330.5,633.1,330.7,633.2,330.8L633.2,330.8z M630,337.7
	l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6c1.4,2.2,3.7,3.3,7,3.3h1l0.1-1.9C633.9,343.4,632.7,340.3,630,337.7z M626.2,343.9l1.5-1.4
	l1.9,0.7l0.4,1.9l-1.5,1.4l-1.9-0.5L626.2,343.9L626.2,343.9z"
          />
          <path
            fill="#EBEBEB"
            d="M627.7,342.5l-1.5,1.4l0.4,1.9l1.9,0.5l1.5-1.4l-0.4-1.9 M600.6,353.4l-0.3-0.7c-0.5-0.8-1.4-1.2-2.8-1.4
	l-2.2-0.1c0.4,2.5,1.1,4.8,2.2,7l1.8-1.5c1-0.8,1.5-1.7,1.4-2.6L600.6,353.4L600.6,353.4z M595.1,347.2v1.8h11.6v-1.8
	c0-3.7,1.4-6.9,4-9.6l-9.5-6.9C597.2,335.3,595.1,340.8,595.1,347.2L595.1,347.2z M632.2,353.5l-0.8-0.5c-3-2.1-5.8-2.5-8.3-1.1
	c-1.7,1.9-2.1,4.5-1.1,7.7l0.3,1c2.8-0.4,5.2-1.6,7.3-3.9C630.9,355.7,631.7,354.6,632.2,353.5L632.2,353.5z M623.6,352.7l1.8-1
	l1.7,1v2.1l-1.7,1l-1.8-1V352.7z"
          />
          <path
            fill="#EBEBEB"
            d="M625.2,351.7l-1.7,1v1.9l1.7,1.1l1.8-1.1v-1.9 M609.1,352.7l-1,0.7c0.7,1.2,1.5,2.3,2.5,3.3
	c2.1,2.1,4.5,3.3,7.3,3.9l0.3-1c1.1-3.4,0.5-6.2-1.5-8.3C614.4,350.3,611.9,350.7,609.1,352.7L609.1,352.7z M615.2,355.7l-1.5-1.4
	l0.4-1.9l1.9-0.5l1.5,1.4l-0.4,1.9L615.2,355.7L615.2,355.7z"
          />
          <path
            fill="#EBEBEB"
            d="M613.7,354.5l1.5,1.2l1.9-0.5l0.4-1.9l-1.5-1.4l-1.9,0.5 M610.6,356.7c-1.1-1.1-1.9-2.2-2.5-3.3l-9.4,6.9
	c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1C615.2,360.1,612.7,358.9,610.6,356.7L610.6,356.7z M619.5,367.8
	c-1,0.3-1.7,1-2.1,2.2l-0.8,2.1l3.7,0.3l3.6-0.3L623,370c-0.5-1.2-1.2-1.9-2.1-2.2L619.5,367.8L619.5,367.8L619.5,367.8z M755,264.1
	l0.3,0.8l15,19.4c0.8,1.1,1,1.9,0.5,2.3c1.1,1.1,2.8,1.4,4.8,1.2c1.5-0.5,1.7-1.4,0.8-2.6l-15.1-19.7l-0.7-0.8l-1.1-2.5L755,264.1
	L755,264.1z"
          />
          <path
            fill="#EBEBEB"
            d="M755.4,264.9l-0.3-0.8l-0.3-0.8l-5.2,2.6l14.7,19.8c2.6,1.2,4.7,1.6,6.2,1.2l0.5-0.1
	c0.5-0.4,0.3-1.1-0.5-2.3L755.4,264.9L755.4,264.9z"
          />
          <path
            fill="#EBEBEB"
            d="M749.4,265.7l-1.1-1.5c-0.7,0.5-1.2,1.1-1.7,1.8l-0.8,1.8l12.5,17.5c3.2,1.6,5.1,1.8,5.9,0.3L749.4,265.7
	L749.4,265.7z M85.6,299.4c1.2,2.2,4,3.2,8.4,2.9l27.1-3.6c4.3-0.8,8.3-2.3,12.1-4.8l14.4-11.3c-0.4-1.1-2.2-1.6-5.2-1.8
	c-9.2,0.4-18.6,1.1-27.8,2.1l0.6,12.8l0.7-0.4c-0.1-0.1-0.1-0.4,0-0.5c0-0.3,0.1-0.4,0.3-0.5c3.3-3,4.8-5.9,4.4-9.1v-0.5l0.4-0.4
	c0.1-0.1,0.4-0.1,0.6-0.1l16.8-1.5l1.8,0.1c0.8,0.3,1.2,0.7,1.1,1.5c0.1,0.7-0.6,1.6-2.1,3l-8.1,5.9l-0.8,0.5
	c-3.2,2.3-6.2,3.6-9.1,3.9l-9.6,1.2l4-2.8l-0.6-12.8l-12.1,1.2c-7.7,0.8-15.4,1.8-23.1,3L85.6,299.4L85.6,299.4z M86.1,288
	c1.8-0.5,3.2,0,4.1,1.7l4.3,8.8c0.6,0.7-0.3,1.1-2.2,1.4L86.1,288L86.1,288z M101.4,297.8c0.6,0.7-0.3,1.1-2.2,1.4L93,287.5
	c1.8-0.5,3.2,0,4.1,1.7L101.4,297.8L101.4,297.8z M107.9,297.5c0.6,0.7-0.3,1.1-2.2,1.4l-6.2-11.7c1.8-0.5,3.2,0,4.1,1.6
	L107.9,297.5z"
          />
          <path
            fill="#EBEBEB"
            d="M90.2,289.7c-1-1.6-2.2-2.2-4.1-1.6l6.2,11.7c2.1-0.1,2.8-0.5,2.2-1.4L90.2,289.7z M99.2,299.2
	c2.1-0.1,2.8-0.5,2.2-1.4l-4.3-8.8c-1-1.6-2.2-2.2-4.1-1.6L99.2,299.2L99.2,299.2z M105.7,298.9c2.1-0.1,2.8-0.5,2.2-1.4l-4.3-8.8
	c-1-1.6-2.2-2.2-4.1-1.6L105.7,298.9L105.7,298.9z M120.4,284.4c0,0.1-0.1,0.4,0,0.5c0.4,3-1.1,6.1-4.4,9.1
	c-0.1,0.1-0.3,0.4-0.3,0.5v0.7c0.1,0.1,0.3,0.4,0.6,0.4c2.8,1.1,6.5,0.7,11-1.2l2.5-1.2l0.8-0.5l8.1-5.9c1.5-1.2,2.2-2.3,2.1-3
	c0-0.7-0.3-1.2-1.1-1.5l-1.8-0.1l-16.6,1.8c-0.1,0-0.4,0.1-0.6,0.1L120.4,284.4L120.4,284.4z M129.7,291.4l-3.2,1.6
	c-3,1.2-5.6,1.8-7.8,1.7c2.6-2.9,4-5.8,3.9-8.8l16-1.5h0.1l-1,0.8L129.7,291.4L129.7,291.4z"
          />
          <path
            fill="#EBEBEB"
            d="M126.7,293.2l3.2-1.6l8.3-6.2l1-0.8h-0.1l-16,1.5c0.1,3-1.1,5.9-3.8,8.8C120.9,295,123.5,294.5,126.7,293.2
	L126.7,293.2z"
          />
          <path
            fill="#EBEBEB"
            d="M116.2,295.9c-0.3-0.1-0.4-0.3-0.6-0.4v-0.1l-0.7,0.4l-4,2.8l9.6-1.2c2.9-0.3,5.9-1.5,9.1-3.9l-2.5,1.2
	C122.7,296.4,119,296.8,116.2,295.9L116.2,295.9z M88.5,337.5v-0.3l-3.3,0.1c-1.5,3.3-2.2,6.7-2.1,10.5c0,3.4,1.1,5.1,3.6,5.2h4.5
	l-1.2-0.3L88.5,337.5L88.5,337.5z M397.8,200.2l-4.8-3.6c-13.1,4-34.3,13.6-63.6,28.9c-11,5.8-23.2,12.4-36.6,19.7
	c-3.2,1.8-6.3,3.6-9.6,5.4l31.2,1.1c11.4-8.5,24.9-17.3,40.3-26.1c1.8-1,3.6-2.1,5.4-3c12.2-6.7,24.6-13.1,37.4-19
	C399.5,202.6,399.5,201.5,397.8,200.2L397.8,200.2z M223.3,375.4c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2
	c-7.8-7.8-17.2-11.7-28.2-11.7s-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7
	S215.6,383.1,223.3,375.4L223.3,375.4z M232.8,347.2c0,10.5-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11s-19.3-3.7-26.7-11
	c-7.4-7.4-11-16.2-11-26.7s3.7-19.3,11-26.7c7.4-7.4,16.2-11,26.7-11c10.5,0,19.3,3.7,26.7,11C229.2,327.8,232.8,336.7,232.8,347.2
	L232.8,347.2z"
          />
          <path
            fill="#EBEBEB"
            d="M221.8,373.7c7.3-7.4,11-16.2,11-26.7s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11
	c-7.4,7.4-11,16.2-11,26.7s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11C205.5,384.9,214.5,381.1,221.8,373.7z M220.5,321.7l1.4,1.4
	l1.2,1.4c5.4,6.5,8,13.9,8,22.6v1.9l-0.1,1.8c-0.6,5.2-2.1,9.9-4.5,14.2l-1,1.5c-1.4,2.1-3,4-4.8,5.8c-4.7,4.7-10,7.8-16.1,9.4
	l-1.8,0.4c-2.3,0.5-5,0.7-7.4,0.7c-2.6,0-5.1-0.3-7.6-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4c-1.9-1.9-3.4-3.9-4.8-5.9l-1-1.5
	c-2.6-4.4-4.1-9.1-4.5-14.3c0-0.5-0.1-1.2-0.1-1.8v-1.8c0-8.7,2.8-16.4,8.1-22.8l1.2-1.4l1.2-1.2c3.3-3.3,6.9-5.8,10.9-7.6l1.6-0.7
	c4-1.5,8.3-2.2,12.8-2.2c4.7,0,9.1,0.8,13.1,2.3l1.6,0.7C213.8,315.9,217.2,318.4,220.5,321.7L220.5,321.7z"
          />
          <path
            fill="#EBEBEB"
            d="M212.7,329.1l-1.6,1.1c-1.1,0.7-2.1,0.8-3,0.5l-0.7-0.4c-0.3-0.1-0.4-0.3-0.6-0.5c-0.6-0.7-0.8-1.6-0.4-3
	l0.6-2.1l2.9-10.6l-1.7-0.7l-3.3,10.3l-3.6,11.1l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8l-0.3-1l-3.7-11
	l-3.3-10.3l-1.7,0.7l2.9,10.6l0.6,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.6c-0.3,0.1-0.4,0.3-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2
	l-9.2-6.1l-1.2,1.4l8.8,6.3l9.5,6.9l0.8,0.5c2.6,2.1,3.9,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4h-23.5c0,0.5,0,1.2,0.1,1.8l10.9,0.5
	l2.2,0.1c1.2,0.1,2.2,0.5,2.8,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5l-8.5,6.9l1,1.5l8.8-6.3l9.4-6.9l1-0.7
	c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1l-3.6,11.1l-3.4,10.3l1.8,0.4l3.9-10.3l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2
	h1.5c1,0.3,1.6,1,2.1,2.2l0.8,2.1l3.9,10.3l1.8-0.4l-3.3-10.3l-3.6-11.1l-0.3-1c-1-3.2-0.6-5.8,1.1-7.7c2.5-1.4,5.4-1,8.3,1.1
	l0.8,0.6l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.6-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7c0.4-0.8,1.4-1.2,2.8-1.4
	l2.1-0.1l10.9-0.6l0.1-1.8H208c-3.3,0-5.6-1.1-7-3.3c-0.4-2.8,0.7-5.4,3.6-7.6l0.7-0.5l9.4-6.9l8.8-6.3l-1.2-1.4L212.7,329.1
	L212.7,329.1z M195.1,342.2c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4s-0.6,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4
	c-1.4,0-2.5-0.5-3.4-1.4c-1-1-1.4-2.1-1.4-3.4c0-1.4,0.6-2.5,1.4-3.4C192.6,342.8,193.7,342.2,195.1,342.2L195.1,342.2z"
          />
          <path
            fill="#EBEBEB"
            d="M198.5,343.7c-1-1-2.1-1.4-3.4-1.4c-1.4,0-2.5,0.5-3.4,1.4s-1.4,2.1-1.4,3.4c0,1.4,0.6,2.5,1.4,3.4
	c0.8,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4C199.9,345.8,199.5,344.7,198.5,343.7L198.5,343.7z M211,330.2
	l1.6-1.1c-1.8-1.8-3.7-3.2-5.8-4.3l-0.6,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5l0.7,0.4C209,331.2,209.9,331.1,211,330.2L211,330.2z
	 M220.1,349.1v-1.9c0-6.2-1.9-11.7-5.9-16.2l-9.4,6.9c2.5,2.6,3.7,5.8,3.7,9.4l-0.1,1.9H220.1L220.1,349.1z M215.1,352.8l-0.3,0.7
	c0,0.3-0.1,0.5-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6l1.7,1.4c1.1-2.2,1.8-4.4,2.2-6.9l-2.1,0.1C216.4,351.6,215.6,352,215.1,352.8
	L215.1,352.8z M212.8,364.9c1.4-1.4,2.6-2.9,3.7-4.5l-9.4-6.9c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	C205.5,370.5,209.4,368.3,212.8,364.9L212.8,364.9z M204.8,323.8c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8l3.6,11.1
	c1.8-0.8,3.7-1.2,5.8-1.2c2.2,0,4.3,0.5,6.2,1.4L204.8,323.8L204.8,323.8z M184.2,326.8l-0.6-2.1c-2.1,1.1-4.1,2.5-5.9,4.3l1.8,1.2
	c1.1,0.7,2.1,0.8,3,0.5c0.3-0.1,0.4-0.3,0.7-0.4l0.6-0.5C184.3,329,184.5,328,184.2,326.8L184.2,326.8z M186.4,338.1l-0.8-0.5
	c-2.6,2.6-4,5.8-4,9.6l0.1,1.8h1.1c3.6,0,6.1-1.4,7.3-4C190.2,342.3,189,340.1,186.4,338.1L186.4,338.1z M186.1,343l1.9-0.3l1.2,1.6
	l-0.8,1.8l-1.9,0.3l-1.2-1.7L186.1,343L186.1,343z"
          />
          <path
            fill="#EBEBEB"
            d="M188.2,342.8l-2.1,0.3l-0.8,1.8l1.2,1.7l1.9-0.3l0.8-1.8 M185.6,337.5l-9.5-6.9c-4.1,4.7-6.2,10-6.2,16.5
	v1.8h11.6l-0.1-1.8C181.4,343.3,182.8,340.1,185.6,337.5L185.6,337.5z M189.3,334.8l0.3,1c1.1,3.4,3.2,5.4,5.9,5.8
	c2.5-0.5,4.3-2.5,5.4-5.6l0.3-1c-1.9-1-3.9-1.4-6.2-1.4C193,333.5,191.1,334,189.3,334.8L189.3,334.8z M195.9,340.7l-2.1-0.3
	l-0.8-1.8l1.1-1.6l1.9,0.1l0.8,1.8L195.9,340.7L195.9,340.7z"
          />
          <path
            fill="#EBEBEB"
            d="M193.8,340.4l2.1,0.3l1.1-1.6l-0.8-1.8l-1.9-0.3l-1.1,1.6 M205,337.7l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6
	c1.4,2.2,3.7,3.3,7,3.3h1l0.1-1.9C208.7,343.4,207.4,340.3,205,337.7L205,337.7z M202.5,342.5l1.9,0.7l0.4,1.9l-1.5,1.4l-1.9-0.5
	L201,344L202.5,342.5z"
          />
          <path
            fill="#EBEBEB"
            d="M204.4,343l-1.9-0.5l-1.4,1.4l0.4,1.9l1.8,0.5l1.5-1.4 M172.5,351.3l-2.2-0.1c0.4,2.5,1.1,4.8,2.2,7l1.8-1.5
	c1-0.8,1.5-1.7,1.4-2.6l-0.1-0.7l-0.3-0.7C174.7,351.8,173.7,351.4,172.5,351.3L172.5,351.3z M207.2,353.5l-0.8-0.5
	c-3-2.1-5.8-2.5-8.3-1.1c-1.6,1.9-2.1,4.5-1.1,7.7l0.3,1c2.8-0.4,5.2-1.6,7.3-3.9C205.7,355.7,206.5,354.6,207.2,353.5L207.2,353.5z
	 M201.8,352.7v2.1l-1.6,1l-1.8-1v-2.1l1.8-1L201.8,352.7z"
          />
          <path
            fill="#EBEBEB"
            d="M201.8,354.6v-1.9l-1.6-1l-1.8,1v1.9l1.8,1.1 M192.7,360.5l0.3-1c1.1-3.4,0.6-6.2-1.5-8.3
	c-2.3-1-5-0.5-7.7,1.4l-1,0.7c0.7,1.2,1.5,2.3,2.5,3.3C187.6,358.9,190,360.1,192.7,360.5L192.7,360.5z M188.9,352.4l1.9-0.5
	l1.5,1.4l-0.4,1.9l-1.9,0.5l-1.5-1.4L188.9,352.4L188.9,352.4z"
          />
          <path
            fill="#EBEBEB"
            d="M190.8,351.8l-1.9,0.5l-0.4,2.1l1.5,1.2l1.9-0.5l0.4-1.9 M185.6,356.7c-1.1-1.1-1.9-2.2-2.5-3.3l-9.4,6.9
	c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1C190,360.1,187.6,358.9,185.6,356.7L185.6,356.7z M192.2,370l-0.8,2.1
	l3.7,0.3l3.6-0.3l-0.8-2.1c-0.6-1.2-1.2-1.9-2.1-2.2h-1.5C193.4,367.9,192.6,368.8,192.2,370L192.2,370z M458.8,203.5
	c-24.1,0.1-45.5,4.1-64.7,12c-7.3,3-14.4,6.3-21.6,10c-5.8,3-11.6,6.2-17.3,9.5v2.2c0.3-0.1,0.5-0.4,1-0.6c3.7-1.6,7.7-2.1,11.8-1.5
	c1.8,0.1,3.3,0.4,4.5,0.8c2.9,1,4.5,2.8,5,5.4l0.8,5c0,1,0,1.9-0.3,2.6c0,0.1-0.1,0.4-0.1,0.6l83.5-0.7c17.7-0.1,35.4-0.6,52.8-1.5
	l14.9-36.6C506.2,206.6,482.7,204.1,458.8,203.5L458.8,203.5z"
          />
          <path
            fill="none"
            d="M88.5,337.5l-0.1-0.4 M129.7,291.5l8.3-6.2l1-0.8h-0.1l-16,1.5c0.1,3-1.1,5.9-3.9,8.8c2.1,0.3,4.8-0.3,7.8-1.6
	L129.7,291.5L129.7,291.5z M115,295.7l-0.6-12.8"
          />
          <path
            fill="none"
            d="M115,295.7l-4,2.8l9.6-1.2c2.9-0.3,5.9-1.5,9.1-3.9 M115.6,295.3l-0.6,0.4"
          />
          <path
            fill="none"
            d="M129.9,293.2l-2.5,1.2c-4.5,1.9-8.3,2.3-11,1.2c-0.3-0.1-0.4-0.3-0.6-0.4v-0.1 M105.7,298.9l-6.2-11.7
	c1.8-0.5,3.2,0,4.1,1.6l4.3,8.8C108.3,298.2,107.6,298.6,105.7,298.9L105.7,298.9z M129.9,293.2l0.8-0.5l8.1-5.9
	c1.5-1.2,2.2-2.3,2.1-3c0-0.7-0.3-1.2-1.1-1.5l-1.8-0.1l-16.8,1.8c-0.1,0-0.4,0.1-0.6,0.1l-0.4,0.4c0,0.1-0.1,0.4,0,0.5
	c0.4,3-1.1,6.1-4.4,9.1c-0.1,0.1-0.3,0.4-0.3,0.5v0.5 M99.2,299.2L93,287.5c1.8-0.5,3.2,0,4.1,1.7l4.3,8.8
	C101.9,298.5,101.2,299,99.2,299.2L99.2,299.2z M86.1,288l6.2,11.7c2.1-0.1,2.8-0.5,2.2-1.4l-4.3-8.8C89.3,288,87.9,287.5,86.1,288
	L86.1,288z M700.8,238.2c-12.5-4.4-25-8.7-37.7-12.7c-18.7-5.9-37.3-11.6-56-16.5c-12.2-3.9-25.3-7.2-39.1-10 M514.8,249.1
	c22.3-1.1,44.4-2.8,66.4-5.1c6.9-0.4,12-1.6,15.3-4c2.6-2.1,5.5-4.8,8.4-8.3c1.8-2.1,1.4-3.7-0.8-4.7l-5.4-1.9
	c-19-6.2-39.1-11.3-60.1-15.3c-26-5.4-52.5-8.4-79.8-9.1c-24.4,0.1-46.2,4.3-65.5,12.2c-8.8,3.7-17.6,7.8-26.4,12.5
	c-13.1,6.9-26,15-38.9,24.1l-0.3,0.3"
          />
          <path
            fill="none"
            d="M534.8,246.2c15.4-1,30.8-2.3,46.1-3.9l0.3-0.1c3.7-0.6,7-1.9,9.9-4.3c2.1-1.5,4.4-3.6,6.7-6.1
	c1.9-1.9,1.7-3.4-0.7-4.5l-5.6-1.8c-14.3-4.4-29-8.3-44.4-11.4 M548.3,369.7c0.1-14.6,1.2-26.7,3-36.6c1.2-6.7,3.7-12.9,7.3-18.8
	c2.3-3.9,5.4-7.6,8.8-11.1c1-1,1.9-2.1,3-3c13.5-12.4,29.9-18.4,49-18.4s35.5,6.2,49,18.4l3,3l3,3.2 M700.8,238.2
	c-8.3,1.1-16.5,0.7-24.9-1.4c-12.5-4-24.8-7.8-36.9-11.3c-26.5-7.7-52.3-14.4-77.2-19.9l-0.4-0.1 M547,214.3l-8.8-1.8l-8.9-1.6
	 M561.4,205.6l-1.2-0.3 M561.4,205.6l-1.2-0.3 M529.2,210.8c-23-4.3-46.4-6.6-70.3-7.3c-23.9,0.1-45.5,4.1-64.7,12
	c-7.3,3-14.4,6.3-21.6,10c-5.8,3-11.6,6.2-17.3,9.5 M483.5,277.8l8,0.4l3.3-0.1 M473.8,275.5c1,0.4,2.2,1,3.7,1.2
	c1.9,0.5,3.9,0.8,6.2,1.1 M483.7,268.5c-2.2,0.3-4.4,0.5-6.2,1.1c-1.6,0.4-2.9,0.8-3.7,1.4 M495.1,268.2l-3.6-0.1l-7.8,0.4
	 M481.5,270.8c0.4-0.8,1.2-1.5,2.2-2.3 M483.5,277.8c-1-0.8-1.6-1.5-2.1-2.3 M473.6,271c-0.7,0.3-1.1,0.7-1.5,1
	c-0.4,0.4-0.5,0.8-0.5,1.2s0.1,0.8,0.5,1.2c0.3,0.3,0.8,0.7,1.5,1 M481.5,275.5h-7.7"
          />
          <path
            fill="none"
            d="M473.6,271l0.5-0.1h7.3 M516.7,252l-1.9-2.9 M514.8,249.1l-0.5-1.8 M509.1,275.5h-11.7 M509.1,275.5
	c0.8-0.4,1.5-1,1.8-1.4c0.1-0.3,0.3-0.5,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.5-1-1-1.8-1.4 M494.8,278.1c4-0.1,7.6-0.7,10.6-1.4
	c1.5-0.4,2.8-0.8,3.7-1.2 M495.1,268.2c1.4,0.8,2.3,1.8,2.9,2.6 M497.8,270.8l11.3,0.1"
          />
          <path
            fill="none"
            d="M509.1,271c-1-0.4-2.2-1-3.7-1.2c-3-0.8-6.5-1.2-10.5-1.4 M505.8,328.9c4-8.3,7.6-16.9,10.5-25.9
	c2.8-8.1,5-16.5,6.9-25.2c1.2-8.7-1-17.3-6.5-26 M514.2,247.3l20.6-1.1 M568.1,199c-14.9-3.2-30.5-5.8-47-8
	c-25.7-3.7-52.4-4.9-80.2-3.9c-18.8,1.4-34.8,4.5-48,9.6 M376.5,251.3l84.9-0.7c17.9-0.1,35.6-0.7,53.4-1.5 M534.8,246.2l12.1-31.9
	 M377.9,249.5l83.5-0.7c17.7-0.1,35.4-0.6,52.8-1.5 M514.2,247.3l15-36.5 M497.8,270.8h-16.4 M497.4,275.5c-0.5,0.8-1.5,1.8-2.6,2.6
	 M481.5,275.5h16"
          />
          <path
            fill="none"
            d="M560.2,205.3c-30-6.9-60.4-10.9-91.1-11.8c-28.1-0.1-51.9,3.3-71.5,10.2 M662.8,307c-1.4-1.4-2.9-2.8-4.3-3.9
	c-11.1-9.2-24.2-13.8-39.1-13.8c-15,0-28.1,4.5-39.1,13.8c-1.5,1.2-2.9,2.5-4.3,3.9c-9.2,9.1-14.9,19.7-17.1,31.6v0.4
	c-1.5,8.9-2.2,19.1-2.2,30.8 M320.9,361.7l147.7-2.1c13.3-0.1,22.7-4.8,28.1-14l3.4-5.9 M500.3,339.6c1.9-3.4,3.9-7,5.6-10.7"
          />
          <path
            fill="none"
            d="M500.3,339.6l21.6-0.5c11.1,0,14.7-1.1,10.6-3.2c-7.8-3.2-16.6-5.5-26.5-7 M523.4,377v5.2H563l0.1-5.2
	 M556.6,369.7h-8.3 M563.2,377h-6.7 M556.4,372.1l0.1-2.3 M556.4,377v-5 M265.6,372.3l15.3-2.2l8.4-0.8l10.3-0.5l202.2-3.6
	c15.1-0.3,30.1,1.2,45.3,4.4h1.2 M523.4,377h33 M505.8,328.9c-6.9-1.1-14.3-1.6-22.3-1.8l-157,6.6c-6.5,0.4-12.4,1-17.6,1.7
	 M681.6,366c0-9.9-0.5-18.6-1.5-25.9c-0.4-2.9-1.1-5.8-1.9-8.5c-2.9-9.1-8-17.3-15.3-24.5 M674.5,306.3c5.1,5.9,8.9,12.4,11.3,19.4
	c1,2.9,1.7,5.9,2.2,8.9c1.2,8.5,1.9,18.7,1.8,30.7 M704.6,337.1c1,1.4,2.5,2.1,4.7,2.1l20.6-1.4c1.2,0,2.6-0.7,4.1-2.1
	c1.9-1.5,1.9-2.3,0-2.5l-27.7,1.6C704.2,335.2,703.6,335.9,704.6,337.1L704.6,337.1z M647,323.1l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4
	 M648.2,324.6l-8.7,6.3 M648.2,324.6l-1.2-1.5 M637.9,329.1l9.1-6.1"
          />
          <path
            fill="none"
            d="M666.8,375.4c5.2-8.4,7.8-17.7,7.8-28.3c0-7.6-1.4-14.7-4.1-21.2c-2.6-6.3-6.6-12.1-11.8-17.2
	c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6
	c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4c1.5,4.8,3.6,9.2,6.5,13.5"
          />
          <path
            fill="none"
            d="M656.2,349.1v-1.9c0-8.7-2.6-16.1-8-22.6 M656,350.9l0.1-1.8 M645.3,349.1v-1.9c0-6.2-1.9-11.7-5.9-16.2
	 M642.8,358.3l-1.7-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7c0.4-0.8,1.4-1.2,2.6-1.4l2.1-0.1 M626.2,371.6
	c4.4-1.1,8.4-3.3,11.8-6.7c1.4-1.4,2.6-2.9,3.7-4.5 M650.4,366.7l1-1.5 M645,351.4c-0.4,2.5-1.1,4.7-2.2,6.9 M641.7,360.4l8.7,6.3
	 M651.4,365.2l-8.5-6.9 M574.7,377c2.1,3,4.4,5.9,7.2,8.5c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c3.2-3.2,5.9-6.6,8.1-10.2
	 M651.4,365.2c2.6-4.3,4.1-9.1,4.5-14.2"
          />
          <path
            fill="none"
            d="M629.5,382c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8 M645.3,349.1h10.9 M645,351.4l11-0.5"
          />
          <path
            fill="none"
            d="M658,347.2c0-10.5-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11c-7.3,7.4-11,16.2-11,26.7
	s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11s19.3-3.7,26.7-11C654.3,366.4,658,357.5,658,347.2z"
          />
          <path
            fill="none"
            d="M660.2,347.2c0-11-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7c-11,0-20.4,3.9-28.2,11.7
	s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7s20.4-3.9,28.2-11.7C656.3,367.5,660.2,358.2,660.2,347.2
	L660.2,347.2z M689.7,365.3l-8.1,0.7 M681.6,366v4.8 M791.1,334.5h-16.2c-4.3,0.5-7.3,1.9-8.9,4c-4.3,5-8.4,8.9-12.4,11.7
	c-4.7,3.3-9.1,5.5-13.1,6.6c-18.7,2.2-33.6,5-44.8,8.1l-5.8,0.4 M666.8,375.4l14.9-4.5 M674.5,306.3l-11.7,0.7"
          />
          <path
            fill="none"
            d="M633.3,313.6c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2 M635,314.3l-1.7-0.7 M630,323.8
	c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8 M633.3,313.6l-3.3,10.2 M607.5,313.5l-1.7,0.7 M610.9,323.8l-3.4-10.3 M608.7,324.7
	l0.5,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.5l-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2 M602.8,329c1.8-1.8,3.7-3.2,5.9-4.3
	 M618.2,338.6l0.8,1.8l2.1,0.3l1.1-1.6l-0.8-1.8l-1.9-0.3 M613.3,342.8l-1.9,0.3l-0.8,1.8l1.2,1.7l1.9-0.3l0.8-1.8"
          />
          <path
            fill="none"
            d="M610.6,337.5l0.8,0.5c2.6,2.1,3.9,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4h-1.1 M626.5,334.9l-0.3,1
	c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8l-0.3-1 M632.1,324.9l-0.5,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.5,0.5
	c0.3,0.1,0.4,0.3,0.7,0.4c0.8,0.4,1.9,0.3,3-0.5l1.7-1.1 M620.3,342.2c-1.4,0-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4s0.5,2.5,1.4,3.4
	c1,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4c0-1.4-0.6-2.5-1.4-3.4C622.8,342.8,621.6,342.2,620.3,342.2
	L620.3,342.2z M626.2,343.9l0.4,1.9l1.9,0.5l1.5-1.4l-0.4-1.9l-1.9-0.5"
          />
          <path
            fill="none"
            d="M630,337.7l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6c1.4,2.2,3.7,3.3,7,3.3h1 M614.5,334.8c1.8-0.8,3.7-1.2,5.8-1.2
	c2.2,0,4.3,0.5,6.2,1.4 M635,314.3l-2.9,10.6 M630,323.8l-3.6,11.1 M605.8,314.1l2.9,10.6 M614.5,334.8l-3.6-11 M605.8,314.1
	c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2 M592.5,324.3l8.8,6.3 M593.7,323l-1.2,1.4 M595.4,351.2l2.2,0.1c1.2,0.1,2.2,0.5,2.8,1.4l0.3,0.7
	l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5 M595.2,348.8V347c0-6.3,2.1-11.8,6.2-16.5 M584.4,348.8c0,0.5,0,1.2,0.1,1.8"
          />
          <path
            fill="none"
            d="M592.5,324.3c-5.4,6.5-8.1,14-8.1,22.8v1.8 M589,365l1,1.5 M597.6,358.2c-1.1-2.2-1.8-4.5-2.2-7 M584.5,350.7
	c0.4,5.2,1.9,10,4.5,14.3 M595.2,348.8h-10.9 M597.6,358.2L589,365 M590,366.6l8.8-6.3 M584.5,350.7l10.9,0.4 M590,366.6
	c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4 M622.6,360.5l-0.3-1c-1-3.2-0.5-5.8,1.1-7.7c2.5-1.4,5.2-1,8.3,1.1l0.8,0.6
	 M633.8,349.1l0.1-1.9c0-3.7-1.2-6.7-3.7-9.4"
          />
          <path
            fill="none"
            d="M632.2,353.5c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9 M623.6,352.7v1.9l1.7,1.1l1.8-1.1v-1.9l-1.8-1
	 M615.2,355.7l1.9-0.5l0.4-1.9l-1.5-1.4l-1.9,0.5l-0.4,2.1 M606.8,348.8l-0.1-1.6c0-3.7,1.4-6.9,4-9.6"
          />
          <path
            fill="none"
            d="M608.2,353.4l1-0.7c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1"
          />
          <path
            fill="none"
            d="M617.9,360.5c-2.8-0.4-5.2-1.6-7.3-3.9c-1.1-1.1-1.9-2.2-2.5-3.3 M610.9,381.8l1.8,0.4 M614.4,371.5l-3.4,10.3
	 M612.7,382.2l3.9-10.2 M616.6,372.1l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1 M627.7,382.2l1.8-0.3
	 M612.7,382.2c2.3,0.5,5,0.7,7.6,0.7s5.1-0.3,7.4-0.7 M629.5,382l-3.3-10.3 M623.8,372.1l3.9,10.2 M616.6,372.1l3.7,0.1l3.6-0.1
	 M598.8,360.2c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7 M626.2,371.6l-3.6-11.1 M617.9,360.5l-3.6,11 M601.3,330.7l9.4,6.9
	 M606.8,348.8h-11.6 M598.8,360.2l9.4-6.9 M602.8,329l-9.1-6.1 M633.8,349.1h11.6 M639.5,330.9l-9.5,6.7 M637.9,329.1
	c-1.8-1.8-3.7-3.2-5.8-4.3 M632.2,353.5l9.5,6.9 M563.2,377h11.6 M560.2,205.3l8-6.3 M761.1,247l-12.4,3c-12.8,3.2-26.7,4-41.8,2.6
	c-53.9-3.9-106.5-2.8-157.8,3.6 M770.6,255.8c1.8-0.6,1.9-2.3,0.3-5.5c-0.5-1.1-1.4-2.1-2.3-2.8l-0.8-0.6c-1.4-0.6-3.6-0.6-6.5,0
	 M769.8,258.3c1-1.1,1.2-1.9,0.8-2.5"
          />
          <path
            fill="none"
            d="M761.1,247c-9.5,1-17.2,0.7-23.1-0.6c-8.4-1.2-15.3-3-20.8-5.4c-7.6-3.4-13.1-4.4-16.5-2.9 M765.7,264.5l3-5.1
	l1.1-1.1 M767.7,268.3l-2.3-3.4l0.3-0.4 M778.9,285.1c1.9-1.1,2.5-2.1,1.4-3l-9.1-13.2c-1-1.4-2.1-1.5-3.4-0.5 M770.8,286.6
	c0.5-0.4,0.3-1.1-0.6-2.3l-15-19.4l-0.3-0.8 M761.1,265.5l-0.7-0.8l-1.1-2.5l-4.4,1.9"
          />
          <path
            fill="none"
            d="M755,264.1l-0.3-0.8l-5.2,2.5 M749.4,265.7l-1.1-1.5 M770.6,255.8l-18.8,4.7c-1.9,0.5-3.3,1.6-4.1,3.2l-1.1,2.2
	 M748.3,264.2c-0.7,0.5-1.2,1.1-1.7,1.8"
          />
          <path
            fill="none"
            d="M769.8,258.3l-17.1,3.7c-1.8,0.4-3.3,1.2-4.5,2.2 M745.7,267.8l-1,1.8c-1.1,3.2,0,6.1,3.2,8.9 M746.6,266
	l-0.8,1.8 M782.6,290.5l-17.6-0.4c-3,0-5.1-0.4-6.1-1.2h-0.1l-10.9-10"
          />
          <path
            fill="none"
            d="M761.1,265.5l15.1,19.7c1,1.2,0.7,2.2-0.8,2.6c-2.1,0.3-3.7-0.1-4.8-1.2"
          />
          <path
            fill="none"
            d="M764.2,285.5c2.6,1.2,4.7,1.6,6.2,1.2l0.6-0.1 M765.7,264.5l-4.5,1 M745.7,267.8l12.5,17.5
	c3.2,1.6,5.1,1.8,5.9,0.3 M778.9,285.1l-11.1-16.8 M764.2,285.5l-14.7-19.8 M747.9,278.7l-23,21.2c-1.5,1.4-3.2,2.5-5.1,3.3
	c-2.9,1.2-6.1,1.5-9.5,1.1l-35.9,2.1 M782.6,290.5c2.8-0.7,3.2-1.5,1.2-2.5l-5-3"
          />
          <path
            fill="none"
            d="M791.1,334.5c1.1,0,1.5-0.7,1.4-1.9l-0.5-4.1c-2.3-1.6-3.7-3.7-4.3-6.3l-3.3-11.7l3.2-10l1.4-0.4
	c1.8-0.7,2.3-1.5,1.9-2.8c-0.3-1.1-1-2.2-1.9-3.6c-0.7-1.1-1.7-2.2-2.8-3.3l-3.7-0.1 M784.8,340.4c-1.9,0.4-3.4,1.2-4.5,2.3
	l-18.8,18 M764.2,362.8l12.9-12l6.7-4c2.2-1.1,2.9-2.5,2.2-4.1l-1.1-2.2 M790.4,337.5h1.8l1-1.1 M790.4,339.9v-2.3 M784.8,340.4
	c1.5-0.4,3.4-0.5,5.6-0.5 M793.2,336.4c0.5-0.8-0.1-1.4-2.1-1.9"
          />
          <path
            fill="none"
            d="M764.2,362.8c6.1-1.9,12.5-4.3,19-7c1.2-6.5,3.7-11.7,7.4-15.8 M765.8,349.8l0.5-1.2c0.8-1.6,1.1-2.8,0.7-3
	h-0.5c-0.5,0.1-1.2,0.7-2.2,1.5l-2.2,2.3l-0.3,0.3 M771,338.9c-0.8,0.5-1.7,1.2-2.2,1.9l-1.4,1.6 M778.1,338.5l-7,0.4"
          />
          <path
            fill="none"
            d="M793.2,336.4l-14.2,0.1c-3.3,0.3-5.9,1.1-8.1,2.5 M767.3,342.6h7.4c1,0.1,1.7-0.1,2.2-0.5l1.1-1.4
	c1.2-1.1,1.2-1.9,0-2.2 M761.4,360.8l2.5-3c1.4-1.9,2.5-3.6,3.3-5.2c0.8-1.5,1-2.5,0.5-2.8h-0.4 M767.3,349.8
	c-0.4,0.1-1.1,0.5-2.1,1.4l-2.1,2.2c-0.7,0.7-1.2,1.5-1.9,2.5l-1.5,2.1c-0.7,1.1-1.4,2.2-1.8,3c-0.7,1.5-1,2.5-0.5,2.8h0.1
	 M767.3,349.8h-1.5 M761.8,349.8h-0.1 M750.7,364.6c-0.5-1.1-0.1-2.5,1.7-4 M752.3,360.8c-1.4,0.8-3,1.6-5,2.2 M747.2,362.8l3.4,1.8
	 M757.6,363.8h0.1c0.4,0,1.1-0.5,2.1-1.4l1.5-1.5"
          />
          <path
            fill="none"
            d="M755,364.1l0.4,1.1c2.9-0.7,5.8-1.5,8.8-2.3 M757.6,363.8l-2.6,0.3 M750.7,364.6l4.3-0.5 M761.7,349.8l-7.4,9.4
	c-0.5,0.6-1.2,1.1-1.9,1.5 M765.8,349.8h-4 M681.6,370.8l60.4-6.7c2.1-0.3,3.7-0.7,5.4-1.1 M761.7,349.8l5.6-7.2 M778.1,338.5
	l12.4-1 M67.7,275.2c-3.8,1.9-7.6,4-11.1,6.1c-1.8,1.5-1.7,2.9,0.4,4.1c3.6,1.1,5.9,3.4,7,6.9l1.5,4.3 M72.2,278.4
	c-1.5-1.4-3-2.5-4.5-3.3 M147.5,282.6L133,293.9c-3.9,2.5-7.8,4-12.1,4.8l-27.1,3.6c-4.3,0.4-7.2-0.5-8.4-2.9l-6.3-12.2 M79.1,287.2
	c7.7-1.1,15.4-2.1,23.1-3 M79.1,287.2c-2.2-3.6-4.5-6.5-6.9-8.7 M278.7,245.2c-26.3-0.6-54.9,0.1-85.7,2.2
	c-37,3.2-66.6,7.7-88.9,13.9c-13.5,3.9-25.6,8.4-36.5,13.8"
          />
          <path
            fill="none"
            d="M157,264.2l-18.4,2.5c-27.7,5.4-47.3,12.2-59.3,20.5 M102.3,284.2l12.1-1.2c9.4-0.8,18.6-1.5,27.8-2.1
	c3,0.1,4.8,0.7,5.2,1.8 M132.8,364.2c0.1-9.9,0.8-18.7,2.2-26.5v-0.4c2.1-12,7.8-22.6,17.1-31.6l2.8-2.6c3.2-2.9,6.6-5.4,10.3-7.4"
          />
          <path
            fill="none"
            d="M125,365.3c0-12.7,0.8-23.9,2.6-33.6c1.2-6.7,3.7-12.9,7.3-18.8c2.1-3.4,4.7-6.7,7.6-9.9
	c1.4-1.5,2.8-2.9,4.3-4.3c3.6-3.2,7.3-6.1,11.1-8.4 M68.8,308.9c-0.1,0.8-0.6,1.6-1.1,2.6l-1.1,2.3 M68.8,308.9
	c0.4-1.8,0.3-3.3-0.6-5l-2.6-7.4 M77.6,337.5c-1.7-0.3-2.3-1.5-1.9-3.7c0-2.2,1.4-3.6,3.9-3.9 M78.8,337.5h-1.2 M85,337.3l-6.2,0.3
	 M78.6,338.5l-10.5,1.1c-0.8,0.1-1.5,0.5-2.1,1.1 M78.8,337.5l-0.3,1 M99.2,336.7l-10.9,0.4l-3.3,0.1 M79.7,330h6.6
	c5,0,8.3,1.1,10,3.2l2.8,3.6"
          />
          <path
            fill="none"
            d="M98.1,340.3c-2.1-2.8-5.2-3.7-9.8-2.9l1.4,15.3l1.2,0.3 M102.1,340.4l-4-0.1 M99.2,336.7l2.9,3.7 M91.1,353.1
	c3.2,0.7,5.6,0.1,7.4-1.5c2.1-1.9,2.5-4.8,1-8.7c-0.4-1-0.8-1.8-1.4-2.5"
          />
          <path
            fill="none"
            d="M85,337.3c-1.5,3.3-2.2,6.7-2.1,10.5c0,3.4,1.1,5.1,3.6,5.2h4.5"
          />
          <path
            fill="none"
            d="M112.7,354l-31.5,0.6c-2.9,0-4.3-1.5-4.3-4.7c0-3.9,0.6-7.7,1.7-11.6 M75.7,367v5.6c7.2,2.2,17.2,3.4,30.3,3.7
	h16.9v-5.9 M132.6,365.7l0.1-1.5 M132.6,365.7l-7.6-0.4 M65.2,360.9c1,1.2,2.8,1.8,5.2,1.6l36.5,0.8c2.8,0.1,5.6,0.4,8.4,0.8
	l9.6,1.2 M65.2,360.9l47.5-0.8c3.2-0.1,3.2-2.1,0-5.9 M122.8,370.4l9.8,0.4v-5.1"
          />
          <path
            fill="none"
            d="M75.7,367l33.8,2.8l13.3,0.7 M102.1,340.4l10.6,13.6 M60.9,316.8l-5.8,1c-3,0.7-4.3,1.8-4.1,3.2
	c0,4.4,0.5,9.5,1.7,15.4c0.1,1.2,1.4,2.1,3.7,2.6l9.6,1.8 M66.7,313.9l-5.8,2.9 M65.9,315.9l-5,0.8 M66.7,313.9l-0.8,2.1 M66,340.7
	c-1.1,1-1.7,2.3-1.9,4.3l-2.2,9.4c-0.1,1.4-1.2,2.2-3.3,2.5c-3.7,1.1-5.2,2.5-4.8,4.3"
          />
          <path
            fill="none"
            d="M77.6,337.5L67,337.8c-2.8,0-4.1-0.5-4.1-1.6c0-8,1-14.7,2.9-20.2 M53.8,360.9l-1.9,0.3h-0.3
	c-2.5,0.7-3,1.5-1.4,2.6c3.2,1.1,7.4,1.8,13.1,2.1l12.2,1 M53.8,360.9l2.6-0.4c3.4-0.4,6.1-1.5,8-3.3c-0.1,1.5,0.1,2.8,0.8,3.6
	 M65.6,296.5l1.9,11.4l1.2,1 M79.7,330c-5.1,0.1-9.6,0.5-14,1.5 M397.6,203.7c1.9-1,1.9-2.2,0.3-3.4l-4.8-3.6 M327.7,249.9l-9.2,7
	 M327.7,249.9l-0.1,0.3c-0.1,0.3-0.1,0.4,0,0.7c0,0.3,0.1,0.4,0.4,0.6l0.7,0.1l10.9-0.1 M355.2,237.4c-4.3,2.8-7.4,5.4-9.6,7.7
	c-1,1-1.2,1.9-1.1,2.9 M339.5,251.6c-1.8,0.6-2.9,1.6-3.3,2.9c0.8,0.4,1.6,0.7,2.6,1 M345.8,250.3c-1.6,0.3-3.3,0.6-4.8,0.8
	c-0.5,0.1-1.1,0.3-1.5,0.4 M350.5,249.8c-1.6,0.1-3.2,0.4-4.5,0.6 M345.3,249.8l0.5,0.6 M345.3,249.8h5.2 M338.8,255.4
	c1.8,0.6,4,0.7,6.5,0.7h10.6l3-2.8c7.3,0.1,13.1-0.6,17.1-1.8c0.3-0.1,0.4-0.1,0.5-0.3 M344.3,247.8c0.1,0.7,0.4,1.2,1,1.9"
          />
          <path
            fill="none"
            d="M355.2,235.2c-7.7,4.5-15.4,9.5-23,14.7l13.2-0.1 M292.7,245.2c-3.2,1.8-6.3,3.6-9.6,5.4 M283.2,250.6l31.2,1.1
	c11.4-8.5,24.9-17.3,40.3-26.1c1.8-1,3.6-2.1,5.4-3c12.2-6.7,24.6-13.1,37.4-19 M392.9,196.7c-13.1,4-34.3,13.6-63.6,28.9
	c-11,5.8-23.2,12.4-36.6,19.7 M318.5,256.9l-1.9,0.1 M309,335.2V332l-0.4-2.5c-1.8-9.6-3.2-18.4-3.9-26.4
	c-0.8-8.1-1.1-15.5-0.8-22.1c1-10.3,5.1-18.3,12.8-23.9 M372.4,236c-1.2-0.4-2.8-0.7-4.5-0.8c-4.1-0.6-8,0-11.8,1.5
	c-0.3,0.1-0.5,0.4-1,0.6 M378.3,246.3l-0.8-5c-0.4-2.6-2.1-4.4-5-5.4 M378,248.9c0.1-0.7,0.3-1.6,0.3-2.6 M377.3,250.5l0.4-0.8
	 M377.9,249.5c0-0.1,0.1-0.4,0.1-0.6 M376.5,251.3c0.4-0.3,0.7-0.6,1-0.8 M378.3,246.3h-2.1 M355.2,237.4v-2.2 M377.3,250.5
	c-7,0.1-13.2,0.6-18.6,1.1c-9.1,0.8-15.8,2.2-20.1,4 M376.2,246.3c0.1-5.9-1.1-9.4-3.7-10.3 M378,248.9h-12.4
	c-5.5,0-10.6,0.3-15.1,0.8 M376.2,246.3c-12.5-0.1-23.1,0.3-31.9,1.5 M516.7,252l-150.9,4.1l-47.3,0.8 M157.9,290.4l7.2,5.4
	 M157.9,290.4l-10.5-7.7 M165.1,295.7c9.1-5.2,19.1-7.7,30.4-7.7c15.7,0,29.2,5.1,40.7,15.1l2.8,2.6c7.4,7.3,12.5,15.4,15.4,24.5
	c0.8,2.8,1.5,5.6,1.9,8.5c1.2,9.1,1.8,20.2,1.5,33.6"
          />
          <path
            fill="none"
            d="M265.6,372.3c0.5-15.5,0-28.6-1.5-38.9c-0.5-3-1.2-6.1-2.2-8.9c-2.5-7.4-6.5-14.2-12.1-20.4l-0.8-1
	c-1.4-1.5-2.9-2.9-4.4-4.3c-13.5-12.4-29.9-18.4-49-18.4c-14.2,0-26.7,3.3-37.8,10 M278.7,245.2l-3,5.1 M316.5,257.1l-56.5,1
	c-36.5,0.3-70.8,2.2-103,6.2"
          />
          <path
            fill="none"
            d="M275.7,250.3l-77,2.8c-48.4,2.5-90.5,10.9-126.3,25.3 M221.9,323.1l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4
	 M206.9,324.9l-0.6,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5l0.7,0.4c0.8,0.4,1.9,0.3,3-0.5l1.6-1.1 M223.1,324.6l-8.8,6.3 M223.1,324.6
	l-1.2-1.5 M212.7,329.1l9.2-6.1"
          />
          <path
            fill="none"
            d="M241.8,375c5.1-8.3,7.6-17.5,7.6-27.9c0-7.6-1.4-14.7-4.1-21.2c-2.6-6.3-6.6-12.1-11.8-17.2
	c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6
	c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4l0.8,2.5"
          />
          <path
            fill="none"
            d="M231,349.1v-1.9c0-8.7-2.6-16.1-8-22.6 M230.8,350.9l0.1-1.8 M220.1,349.1v-1.9c0-6.2-1.9-11.7-5.9-16.2
	 M217.6,358.3l-1.6-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7c0.4-0.8,1.4-1.2,2.8-1.4l2.1-0.1 M201,371.6
	c4.4-1.1,8.4-3.3,11.8-6.7c1.4-1.4,2.6-2.9,3.7-4.5 M225.3,366.7l1-1.5 M220,351.4c-0.4,2.5-1.1,4.7-2.2,6.9 M226.3,365.2l-8.7-6.9
	 M216.5,360.4l8.8,6.3"
          />
          <path
            fill="none"
            d="M144,366c2.6,7.1,6.9,13.8,12.7,19.5c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c3.3-3.3,6.1-6.9,8.4-10.6
	 M226.3,365.2c2.6-4.3,4.1-9.1,4.5-14.2"
          />
          <path
            fill="none"
            d="M204.4,382c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8 M220.1,349.1H231"
          />
          <path
            fill="none"
            d="M235,347.2c0-11-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7s-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2
	s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7c11,0,20.4-3.9,28.2-11.7C231.1,367.5,235,358.2,235,347.2L235,347.2z"
          />
          <path
            fill="none"
            d="M232.8,347.2c0-10.5-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11c-7.4,7.4-11,16.2-11,26.7
	s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11c10.5,0,19.3-3.7,26.7-11C229.2,366.4,232.8,357.5,232.8,347.2z M220,351.4l10.9-0.5
	 M252.4,376.5l2.2,5.8h62.2l3.4-5.2 M257.7,373.6v-1.2 M257.5,377.2l-5.1-0.7 M257.7,373.6l-0.1,3.6"
          />
          <path
            fill="none"
            d="M258.8,377.3h-1.2v-0.1 M257.5,377.2l1.2,0.1 M258.8,377.3H272l6.3,1.9l42-2.2 M257.7,372.3h8 M241.8,375
	l10.6,1.5 M208.1,313.6c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2 M209.9,314.3l-1.8-0.7 M204.8,323.8
	c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8 M208.1,313.6l-3.3,10.2 M182.4,313.5l-1.8,0.7 M185.7,323.8l-3.3-10.3 M183.5,324.7
	l0.6,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.5c-0.3,0.1-0.4,0.3-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2 M186.1,343l-0.8,1.8l1.2,1.7
	l1.9-0.3l0.8-1.8l-1.1-1.6"
          />
          <path
            fill="none"
            d="M185.6,337.5l0.8,0.5c2.6,2.1,3.9,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4h-1.2 M177.7,329c1.8-1.8,3.7-3.2,5.9-4.3
	 M176.1,330.7l9.5,6.9 M201.3,334.9l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8l-0.3-1"
          />
          <path
            fill="none"
            d="M195.9,340.7l1.1-1.6l-0.8-1.8l-1.9-0.3l-1.1,1.6l0.7,1.8 M195.1,342.2c-1.4,0-2.5,0.5-3.4,1.4
	c-1,1-1.4,2.1-1.4,3.4s0.6,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4c0-1.4-0.6-2.5-1.4-3.4
	C197.5,342.8,196.4,342.2,195.1,342.2L195.1,342.2z M205,337.7l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6c1.4,2.2,3.7,3.3,7,3.3h1"
          />
          <path
            fill="none"
            d="M202.5,342.5l-1.4,1.4l0.4,1.9l1.8,0.5l1.5-1.4l-0.4-1.9 M189.3,334.8c1.8-0.8,3.7-1.2,5.8-1.2
	c2.2,0,4.3,0.5,6.2,1.4 M209.9,314.3l-3,10.6 M204.8,323.8l-3.6,11.1 M189.3,334.8l-3.6-11 M180.6,314.1l2.9,10.6 M180.6,314.1
	c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2 M168.5,323l-1.2,1.4 M170,348.8V347c0-6.3,2.1-11.8,6.2-16.5 M170.3,351.2l2.2,0.1
	c1.2,0.1,2.2,0.5,2.6,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5 M159.2,348.8c0,0.5,0,1.2,0.1,1.8"
          />
          <path
            fill="none"
            d="M167.3,324.3c-5.4,6.5-8.1,14-8.1,22.8v1.8 M163.8,365l1,1.5 M159.3,350.7l11,0.4 M172.5,358.2
	c-1.1-2.2-1.8-4.5-2.2-7 M159.3,350.7c0.4,5.2,1.9,10,4.5,14.3 M172.5,358.2l-8.7,6.9 M170,348.8h-10.9 M164.8,366.6l8.8-6.3
	 M164.8,366.6c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4 M208.5,349.1l0.1-1.9c0-3.7-1.2-6.7-3.7-9.4 M197.4,360.5l-0.3-1
	c-1-3.2-0.6-5.8,1.1-7.7c2.5-1.4,5.4-1,8.3,1.1l0.8,0.6"
          />
          <path
            fill="none"
            d="M207.2,353.5c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9 M201.8,352.7l-1.6-1l-1.8,1v1.9l1.8,1.1
	l1.6-1.1 M183,353.4l1-0.7c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1"
          />
          <path
            fill="none"
            d="M188.9,352.4l-0.4,2.1l1.5,1.2l1.9-0.5l0.4-1.9l-1.5-1.4 M181.6,348.8l-0.1-1.8c0-3.7,1.4-6.9,4-9.6
	 M192.7,360.5c-2.8-0.4-5.2-1.6-7.3-3.9c-1.1-1.1-1.9-2.2-2.5-3.3 M185.7,381.8l1.9,0.4 M189.1,371.5l-3.4,10.3 M187.6,382.2
	l3.7-10.2 M191.5,372.1l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2h1.5c1,0.3,1.6,1,2.1,2.2l0.8,2.1 M198.8,372.1l3.9,10.2 M204.4,382
	l-3.4-10.3 M187.6,382.2c2.3,0.5,5,0.7,7.6,0.7c2.6,0,5.1-0.3,7.4-0.7 M202.6,382.2l1.8-0.3 M191.5,372.1l3.6,0.1l3.7-0.1
	 M173.6,360.2c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7 M192.7,360.5l-3.6,11 M201,371.6l-3.6-11.1 M181.6,348.8H170
	 M173.6,360.2l9.4-6.9 M167.3,324.3l8.8,6.3 M177.7,329l-9.2-6.1 M208.5,349.1h11.6 M214.3,330.9l-9.4,6.7 M206.9,324.9
	c2.1,1.1,4,2.6,5.8,4.3 M207.2,353.5l9.4,6.9 M309.1,345c-0.4-2.1-0.5-4-0.4-5.8c0-1.2,0.1-2.3,0.3-3.4v-0.4"
          />
          <path
            fill="none"
            d="M309,335.2c-12.7,1.5-22.3,3.6-28.8,6.3c-6.6,3-3.4,4.4,9.5,4l19.4-0.5 M315.7,361.9h0.8 M316.5,361.9
	c-1.2-0.3-2.1-0.7-2.8-1.5l-3.4-11.1c-0.5-1.5-0.8-2.9-1.1-4.3 M316.5,361.9c1.2,0.3,2.8,0.3,4.5,0 M320.9,361.7l-4.4,0.1
	 M292.7,245.2h-14 M275.7,250.3l7.6,0.3 M132.8,364.2L144,366 M157,264.2c-23.7,5.1-42,11.7-54.6,19.9 M320.2,377h203.2 M309.1,345
	l191.2-5.4 M556.4,372.1l-298.8,1.5 M607.2,209.1c-12.2-3.9-25.3-7.2-39.1-10l-8,6.3l1.2,0.3l0.4,0.1c24.9,5.5,50.6,12.1,77.2,19.9
	c12.1,3.6,24.3,7.3,36.9,11.3c8.3,2.1,16.5,2.5,24.9,1.4c-12.5-4.4-25-8.7-37.7-12.7C644.5,219.6,625.8,214.1,607.2,209.1
	L607.2,209.1z"
          />
          <path
            fill="none"
            d="M604,227.1c2.3,1.1,2.6,2.6,0.8,4.7c-2.9,3.4-5.6,6.2-8.4,8.3c-3.3,2.3-8.5,3.6-15.3,4c-22,2.3-44.2,4-66.4,5.1
	l1.9,2.9c5.5,8.7,7.7,17.3,6.5,26c-1.8,8.7-4.1,17.1-6.9,25.2c-3,8.9-6.5,17.5-10.5,25.9c9.8,1.5,18.7,3.7,26.6,7
	c4.1,2.1,0.7,3-10.6,3.2l-21.6,0.5l-3.4,5.9c-5.4,9.2-14.9,13.9-28.2,14l-147.7,2.1c-1.8,0.3-3.3,0.3-4.5,0h-0.8h0.8
	c-1.2-0.3-2.1-0.7-2.8-1.5l-3.4-11.1c-0.5-1.5-0.8-2.9-1.1-4.3l-19.4,0.5c-12.9,0.4-16.1-1-9.5-4c6.5-2.8,16.1-4.8,28.8-6.3v-3.2
	l-0.4-2.5c-1.8-9.6-3.2-18.4-3.9-26.4c-0.8-8.1-1.1-15.5-0.8-22.1c1-10.3,5.1-18.3,12.8-23.9L260,258c-36.5,0.3-70.8,2.2-103,6.2
	c-23.7,5.1-42,11.7-54.6,19.9l12.1-1.2c9.4-0.8,18.6-1.5,27.8-2.1c3,0.1,4.8,0.7,5.2,1.8l10.5,7.7c11-6.7,23.7-10,37.8-10
	c19.1,0,35.5,6.2,49,18.4c1.5,1.4,3,2.8,4.4,4.3l0.8,1c5.5,6.2,9.6,13.1,12.1,20.4c1,2.9,1.6,5.9,2.2,8.9
	c1.5,10.3,2.1,23.4,1.5,38.9L281,370l8.4-0.8l10.3-0.5L502,365c15.1-0.3,30.1,1.2,45.3,4.4h1.2c0.1-14.6,1.2-26.7,3-36.6
	c1.2-6.7,3.7-12.9,7.3-18.8c2.3-3.9,5.4-7.6,8.8-11.1c1-1,1.9-2.1,3-3c13.5-12.4,29.9-18.4,49-18.4s35.5,6.2,49,18.4l3,3l3,3.2
	l35.9-2.1c3.4,0.4,6.6,0,9.5-1.1c1.8-0.8,3.4-1.8,5.1-3.3l23-21.2c-3.2-2.9-4.3-5.9-3.2-8.9l1-1.8l0.8-1.8l1.1-2.2
	c0.8-1.5,2.2-2.6,4.1-3.2l18.8-4.7c1.8-0.6,1.9-2.3,0.3-5.5c-0.5-1.1-1.4-2.1-2.3-2.8l-0.8-0.6c-1.4-0.6-3.6-0.6-6.5,0l-12.4,3
	c-12.8,3.2-26.7,4-41.8,2.6c-53.9-3.9-106.5-2.8-157.8,3.6c51.3-6.3,104-7.4,157.8-3.6c15,1.4,28.9,0.6,41.8-2.6l12.4-3
	c-9.5,1-17.2,0.7-23.1-0.6c-8.4-1.2-15.3-3-20.8-5.4c-7.6-3.4-13.1-4.4-16.5-2.9c-8.3,1.1-16.5,0.7-24.9-1.4
	c-12.5-4-24.8-7.8-36.9-11.3c-26.5-7.7-52.3-14.4-77.2-19.9l-0.4-0.1l-1.4-0.3c-30-6.9-60.4-10.9-91.1-11.8
	c-28.1-0.1-51.9,3.3-71.5,10.2c-12.8,5.9-25.3,12.2-37.4,19c-1.8,1.1-3.6,2.1-5.4,3c-15.4,8.8-28.9,17.5-40.3,26.1l-31.2-1.1
	l-7.6-0.3l-77,2.8c-48.4,2.5-90.5,10.9-126.3,25.3c2.3,2.2,4.7,5.1,6.9,8.7c12-8.3,31.8-15.1,59.3-20.5l18.4-2.5
	c32.2-3.9,66.6-5.9,103-6.2l56.5-1.1h1.9l9.2-7l0.3-0.3c12.9-9.1,25.9-17.1,38.9-24.1c8.8-4.7,17.6-8.8,26.4-12.5
	c19.4-8,41.1-12,65.5-12.2c27.2,0.7,53.8,3.7,79.8,9.1c21,4,41.1,9.1,60.1,15.3L604,227.1L604,227.1z"
          />
          <path
            fill="none"
            d="M597,227.5l-5.6-1.8c-14.3-4.4-29-8.3-44.4-11.4l-12.1,31.9c15.4-1,30.8-2.3,46.1-3.9l0.3-0.1
	c3.7-0.6,7-1.9,9.9-4.3c2.1-1.5,4.4-3.6,6.7-6.1C599.6,230,599.4,228.4,597,227.5L597,227.5z M619.6,281.7c-19.1,0-35.5,6.2-49,18.4
	c-1.1,1-2.1,1.9-3,3c-3.4,3.6-6.3,7.3-8.8,11.1c-3.6,5.8-6.1,12.1-7.3,18.8c-1.8,9.9-2.9,22.1-3,36.6h8.1c0-11.6,0.7-21.9,2.2-30.8
	v-0.4c2.1-12,7.8-22.6,17.1-31.6c1.4-1.4,2.9-2.8,4.3-3.9c11.1-9.2,24.2-13.8,39.1-13.8c15,0,28.1,4.5,39.1,13.8
	c1.5,1.2,2.9,2.5,4.3,3.9l11.8-0.7l-3-3.2l-3-3C654.9,287.7,638.7,281.7,619.6,281.7L619.6,281.7z"
          />
          <path
            fill="none"
            d="M538.2,212.5l-8.9-1.6l-15,36.5l20.6-1.1l12.1-31.9 M561.4,205.6l-1.2-0.3 M523.2,277.8c1.2-8.7-1-17.3-6.5-26
	L365.8,256l-47.2,0.8h-1.9c-7.6,5.5-11.8,13.5-12.8,23.9c-0.3,6.6,0,14,0.8,22.1c0.8,8,2.1,16.9,3.9,26.4l0.4,2.5
	c0.1,1.1,0.1,2.2,0,3.2c5.4-0.7,11.3-1.1,17.6-1.5l157-6.6c7.8,0.1,15.3,0.8,22.3,1.8c4-8.3,7.6-16.9,10.5-25.9
	C519.2,294.9,521.4,286.5,523.2,277.8L523.2,277.8z M494.8,278.1h-3.4l-7.8-0.4c-2.2-0.3-4.3-0.5-6.2-1.1c-1.5-0.4-2.8-0.8-3.7-1.2
	c-0.7-0.3-1.1-0.7-1.5-1c-0.4-0.4-0.6-0.8-0.6-1.2s0.1-0.8,0.5-1.2c0.3-0.4,0.8-0.7,1.5-1c1-0.5,2.2-1,3.7-1.4
	c1.9-0.5,4-0.8,6.2-1.1l7.7-0.4l3.6,0.1c4,0.1,7.4,0.5,10.5,1.4c1.5,0.4,2.8,0.8,3.7,1.2c0.8,0.4,1.5,1,1.8,1.4
	c0.1,0.3,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8c-0.3,0.5-1,1-1.8,1.4c-1,0.4-2.2,0.8-3.7,1.2C502.4,277.6,498.9,278,494.8,278.1
	L494.8,278.1z"
          />
          <path
            fill="none"
            d="M491.5,278.2h3.4c1.1-1,2.1-1.8,2.6-2.6h-16.1c0.4,0.8,1.1,1.5,2.1,2.3L491.5,278.2L491.5,278.2z"
          />
          <path
            fill="none"
            d="M477.5,276.7c1.9,0.5,3.9,0.8,6.2,1.1c-1-0.8-1.6-1.5-2.1-2.3h-7.8C474.6,275.9,475.8,276.3,477.5,276.7z
	 M483.7,268.5c-2.2,0.3-4.4,0.5-6.2,1.1c-1.6,0.4-2.9,0.8-3.7,1.4h7.8C482,270.1,482.7,269.3,483.7,268.5L483.7,268.5z"
          />
          <path
            fill="none"
            d="M491.5,268.1l-7.7,0.4c-1,0.8-1.7,1.5-2.2,2.3H498c-0.5-1-1.5-1.8-2.9-2.6L491.5,268.1L491.5,268.1z
	 M472.3,274.4c0.3,0.3,0.8,0.7,1.5,1h35.4c0.8-0.4,1.5-1,1.8-1.4c0.1-0.3,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.3-0.5-1-1-1.8-1.4
	h-35.5c-0.7,0.3-1.1,0.7-1.5,1c-0.4,0.4-0.5,0.8-0.5,1.2C471.7,273.6,471.8,274,472.3,274.4L472.3,274.4z M509.1,275.5h-11.6
	c-0.5,0.8-1.5,1.8-2.6,2.6c4-0.1,7.6-0.7,10.6-1.4C506.9,276.3,508.2,275.9,509.1,275.5L509.1,275.5z"
          />
          <path
            fill="none"
            d="M495.1,268.2c1.4,0.8,2.3,1.8,2.9,2.6h11.3c-1-0.4-2.2-1-3.7-1.2C502.4,268.9,498.9,268.3,495.1,268.2
	L495.1,268.2z M521,191c-25.7-3.7-52.4-5-80.2-3.9c-18.8,1.4-34.8,4.5-48,9.6l4.8,3.6c1.8,1.2,1.6,2.3-0.3,3.4
	c19.7-6.9,43.5-10.3,71.5-10.2c30.7,1.1,61.1,4.9,91.1,11.8l8-6.3C553.3,195.8,537.6,193.1,521,191L521,191z M558.8,338.6v0.4
	c-1.5,8.9-2.2,19.1-2.2,30.8v2.5l-0.1,4.8h18.4c-2.9-4.3-5-8.7-6.5-13.5c-1.7-5.1-2.3-10.6-2.3-16.4c0-15,5.4-27.8,16-38.4
	c2.1-2.1,4.3-4,6.5-5.6c9.2-6.9,19.9-10.3,31.9-10.3c12.1,0,22.7,3.4,31.9,10.3c2.2,1.6,4.4,3.6,6.5,5.6c5.2,5.2,9.2,11,11.8,17.2
	c2.8,6.5,4.1,13.6,4.1,21.2c0,10.5-2.6,19.9-7.8,28.3l14.7-4.5v-4.8c0-9.9-0.5-18.6-1.5-25.9c-0.4-2.9-1.1-5.8-1.9-8.5
	c-2.9-9.1-8-17.3-15.3-24.5c-1.4-1.4-2.9-2.8-4.3-3.9c-11.1-9.2-24.2-13.8-39.1-13.8c-15,0-28.1,4.5-39.1,13.8
	c-1.5,1.2-2.9,2.5-4.3,3.9C566.6,316.1,561,326.5,558.8,338.6L558.8,338.6z M496.7,345.5l3.4-5.9L309,345c0.3,1.4,0.7,2.8,1.1,4.3
	l3.4,11.1c0.7,0.7,1.6,1.2,2.8,1.5h4.5l147.7-2.1C482,359.4,491.4,354.7,496.7,345.5L496.7,345.5z M505.8,328.9
	c-1.8,3.6-3.6,7.2-5.6,10.7l21.6-0.5c11.1,0,14.7-1.1,10.6-3.2C524.5,332.7,515.7,330.4,505.8,328.9L505.8,328.9z"
          />
          <path
            fill="none"
            d="M500.3,339.6c1.9-3.4,3.9-7,5.6-10.7c-6.9-1.1-14.3-1.6-22.3-1.8l-157,6.6c-6.5,0.4-12.4,1-17.6,1.7v0.4
	c-0.1,1.1-0.1,2.3-0.3,3.4c-0.1,1.9,0,3.9,0.4,5.8L500.3,339.6z M523.4,377v5.2H563l0.1-5.2h-6.7 M556.6,369.7h-9.4
	c-15.1-3.2-30.1-4.7-45.3-4.4l-202.2,3.6l-10.3,0.5l-8.4,0.8l-15.3,2.2h-8v1.2l298.8-1.5L556.6,369.7L556.6,369.7z"
          />
          <path
            fill="none"
            d="M556.4,377v-5l-298.8,1.5l-0.1,3.6l1.2,0.1H272l6.3,1.9l42-2.2h203.2 M678.1,331.6c0.8,2.8,1.5,5.6,1.9,8.5
	c1,7.3,1.5,16,1.5,25.9l8.1-0.5c0.1-12-0.4-22.1-1.8-30.7c-0.5-3-1.2-6.1-2.2-8.9c-2.3-7-6.1-13.5-11.3-19.4l-11.8,0.7
	C670.1,314.3,675.3,322.4,678.1,331.6L678.1,331.6z"
          />
          <path
            fill="none"
            d="M685.8,325.7c1,2.9,1.7,5.9,2.2,8.9c1.2,8.5,1.9,18.7,1.8,30.7l5.8-0.4c11.1-3.2,26.1-5.9,44.8-8.1
	c4.1-1.1,8.5-3.3,13.1-6.6c4-2.8,8.1-6.7,12.4-11.7c1.7-2.1,4.7-3.4,8.9-4H791c1.1,0,1.5-0.7,1.4-1.9l-0.5-4.1
	c-2.3-1.6-3.7-3.7-4.3-6.3l-3.3-11.7l3.2-10l1.4-0.4c1.8-0.7,2.3-1.5,1.9-2.8c-0.3-1.1-1-2.2-1.9-3.6c-0.7-1.1-1.7-2.2-2.8-3.3
	l-3.7-0.1l-17.6-0.4c-3,0-5.1-0.4-6.1-1.2h-0.1l-10.9-10L725,299.8c-1.5,1.4-3.2,2.5-5.1,3.3c-2.9,1.2-6.1,1.5-9.5,1.1l-35.9,2.1
	C679.7,312.2,683.4,318.7,685.8,325.7L685.8,325.7z M704.6,337.1c-1.1-1.2-0.4-1.9,1.9-2.1l27.7-1.7c1.9,0.1,1.9,1,0,2.5
	c-1.5,1.4-2.9,2.1-4.1,2.1l-20.6,1.4C707.1,339.2,705.6,338.5,704.6,337.1L704.6,337.1z"
          />
          <path
            fill="none"
            d="M706.5,334.9c-2.3,0.1-2.9,0.8-1.9,2.1c1,1.4,2.5,2.1,4.7,2.1l20.6-1.4c1.2,0,2.6-0.7,4.1-2.1
	c1.9-1.5,1.9-2.3,0-2.5L706.5,334.9L706.5,334.9z M658.7,308.6c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3
	c-12.1,0-22.7,3.4-31.9,10.3c-2.2,1.6-4.4,3.6-6.5,5.6c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4
	c1.5,4.8,3.6,9.2,6.5,13.5c2.1,3,4.4,5.9,7.2,8.5c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c3.2-3.2,5.9-6.6,8.1-10.2
	c5.2-8.4,7.8-17.7,7.8-28.3c0-7.6-1.4-14.7-4.1-21.2C667.9,319.7,663.9,313.9,658.7,308.6L658.7,308.6z M660.2,347.2
	c0,11-3.8,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7c-11,0-20.4-3.9-28.2-11.7c-7.8-7.8-11.7-17.2-11.7-28.2s3.9-20.4,11.7-28.2
	s17.2-11.7,28.2-11.7s20.4,3.9,28.2,11.7C656.3,326.7,660.2,336.2,660.2,347.2L660.2,347.2z"
          />
          <path
            fill="none"
            d="M648.5,375.4c7.8-7.8,11.7-17.2,11.7-28.2s-3.8-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7
	c-11,0-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7S640.6,383.1,648.5,375.4
	L648.5,375.4z M658,347.2c0,10.5-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11s-19.3-3.7-26.7-11c-7.3-7.4-11-16.2-11-26.7
	s3.7-19.3,11-26.7c7.4-7.4,16.2-11,26.7-11s19.3,3.7,26.7,11C654.3,327.8,658,336.7,658,347.2L658,347.2z"
          />
          <path
            fill="none"
            d="M647,373.7c7.3-7.4,11-16.2,11-26.7s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11
	c-7.3,7.4-11,16.2-11,26.7s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11C630.7,384.9,639.5,381.1,647,373.7z M645.7,321.7l1.4,1.4
	l1.2,1.4c5.4,6.5,8,13.9,8,22.6v1.9l-0.1,1.8c-0.6,5.2-2.1,9.9-4.5,14.2l-1,1.5c-1.4,2.1-3,4-4.8,5.8c-4.7,4.7-10,7.8-16.1,9.4
	L628,382c-2.3,0.5-5,0.7-7.4,0.7c-2.6,0-5.1-0.3-7.6-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4c-1.9-1.9-3.4-3.9-4.8-5.9l-1-1.5
	c-2.6-4.4-4.1-9.1-4.5-14.3c0-0.5-0.1-1.2-0.1-1.8v-1.8c0-8.7,2.8-16.4,8.1-22.8l1.2-1.4l1.2-1.2c3.3-3.3,7-5.8,10.9-7.6l1.7-0.7
	c4-1.5,8.3-2.2,12.8-2.2c4.7,0,9.1,0.8,13.1,2.3l1.7,0.7C638.8,315.9,642.4,318.4,645.7,321.7L645.7,321.7z"
          />
          <path
            fill="none"
            d="M647,323.1l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4l-2.9,10.6c2.1,1.1,4,2.6,5.8,4.3L647,323.1L647,323.1z"
          />
          <path
            fill="none"
            d="M639.5,330.9l8.8-6.3l-1.2-1.4l-9.1,6.1l-1.7,1.1c-1.1,0.7-2.1,0.8-3,0.6c-0.3-0.1-0.4-0.3-0.7-0.4l-0.5-0.5
	c-0.6-0.7-0.8-1.6-0.4-3l0.5-2.1l2.9-10.6l-1.7-0.7l-3.4,10.2l-3.6,11.1l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8
	l-0.3-1l-3.6-11.1l-3.3-10.3l-1.7,0.7l2.9,10.6l0.5,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.5,0.5l-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2
	l-9.2-6.1l-1.2,1.4l8.8,6.3l9.5,6.9l0.8,0.5c2.6,2.1,3.8,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4h-23.4c0,0.5,0,1.2,0.1,1.8l10.9,0.5
	l2.2,0.1c1.2,0.1,2.2,0.5,2.6,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5l-8.5,6.9l1,1.5l8.8-6.3l9.4-6.9l1-0.7
	c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1l-3.6,11.1l-3.4,10.3l1.8,0.4l3.9-10.3l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2
	h1.5c1,0.3,1.7,1,2.1,2.2l0.8,2.1l3.9,10.3l1.8-0.4l-3.3-10.3l-3.6-11.1l-0.3-1c-1-3.2-0.5-5.8,1-7.7c2.5-1.4,5.4-1,8.3,1.1l0.8,0.5
	l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.7-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7c0.4-0.8,1.4-1.2,2.8-1.4l2.1-0.1
	l10.9-0.5l0.1-1.8h-23.8c-3.3,0-5.6-1.1-7-3.3c-0.4-2.8,0.7-5.4,3.6-7.6l0.7-0.5L639.5,330.9L639.5,330.9z M620.3,342.2
	c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4s-0.6,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4s-2.5-0.5-3.4-1.4c-1-1-1.4-2.1-1.4-3.4
	c0-1.4,0.5-2.5,1.4-3.4C617.8,342.8,618.9,342.2,620.3,342.2L620.3,342.2z"
          />
          <path
            fill="none"
            d="M623.7,343.7c-1-1-2.1-1.4-3.4-1.4s-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4
	c1,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4C625.1,345.8,624.7,344.7,623.7,343.7L623.7,343.7z M648.2,324.6
	l-8.8,6.3c4,4.5,5.9,10,5.9,16.2v1.9H656v-1.9C656.2,338.5,653.6,330.9,648.2,324.6L648.2,324.6z"
          />
          <path
            fill="none"
            d="M645.3,349.1v-1.9c0-6.2-1.9-11.7-5.9-16.2l-9.4,6.9c2.5,2.6,3.7,5.8,3.7,9.4l-0.1,1.9H645.3z M639.9,353.5
	c0,0.3-0.1,0.5-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6l1.7,1.4c1.1-2.2,1.8-4.4,2.2-6.9l-2.1,0.1c-1.4,0.1-2.2,0.5-2.8,1.4L639.9,353.5
	L639.9,353.5z M641.7,360.4c-1,1.6-2.2,3.2-3.7,4.5c-3.4,3.4-7.4,5.6-11.8,6.7l3.3,10.3c6.1-1.5,11.4-4.7,16.1-9.4
	c1.8-1.8,3.4-3.7,4.8-5.8L641.7,360.4L641.7,360.4z"
          />
          <path
            fill="none"
            d="M638,364.9c1.4-1.4,2.6-2.9,3.7-4.5l-9.4-6.9c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	C630.6,370.5,634.6,368.3,638,364.9L638,364.9z M645,351.4c-0.4,2.5-1.1,4.7-2.2,6.9l8.5,6.9c2.6-4.3,4.1-9.1,4.5-14.2L645,351.4z
	 M633.3,313.6c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2l3.3,10.3c2.9-1.1,6.1-1.8,9.4-1.8c3.4,0,6.7,0.5,9.8,1.9
	L633.3,313.6L633.3,313.6z"
          />
          <path
            fill="none"
            d="M630,323.8c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8l3.6,11.1c1.8-0.8,3.7-1.2,5.8-1.2
	c2.2,0,4.3,0.5,6.2,1.4L630,323.8L630,323.8z M607.6,330.7l0.7-0.4l0.5-0.5c0.6-0.7,0.8-1.6,0.4-3l-0.5-2.1
	c-2.1,1.1-4.1,2.5-5.9,4.3l1.8,1.2C605.7,330.9,606.8,331.1,607.6,330.7L607.6,330.7z"
          />
          <path
            fill="none"
            d="M602.8,329c1.8-1.8,3.7-3.2,5.9-4.3l-2.9-10.6c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2L602.8,329L602.8,329z
	 M611.5,338.1l-0.8-0.5c-2.6,2.6-4,5.8-4,9.6l0.1,1.8h1.1c3.6,0,6.1-1.4,7.3-4C615.3,342.3,614.1,340.1,611.5,338.1L611.5,338.1z
	 M613.3,342.8l1.2,1.6l-0.8,1.8l-1.9,0.3l-1.2-1.7l0.8-1.8L613.3,342.8L613.3,342.8z"
          />
          <path
            fill="none"
            d="M614.5,344.4l-1.2-1.6l-1.9,0.3l-0.8,1.8l1.2,1.7l1.9-0.3 M614.5,334.8l0.3,1c1.1,3.4,3.2,5.4,5.9,5.8
	c2.5-0.5,4.3-2.5,5.4-5.6l0.3-1c-1.9-1-3.9-1.4-6.2-1.4C618.1,333.5,616.1,334,614.5,334.8L614.5,334.8z M618.2,338.6l1.1-1.6
	l1.9,0.1l0.8,1.8l-1.1,1.6l-2.1-0.3L618.2,338.6L618.2,338.6z"
          />
          <path
            fill="none"
            d="M619.5,337l-1.2,1.6l0.8,1.8l2.1,0.3l1.1-1.6l-0.8-1.8 M633.2,330.8c0.8,0.4,1.9,0.3,3-0.5l1.7-1.1
	c-1.8-1.8-3.7-3.2-5.8-4.3l-0.5,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.5,0.5C632.8,330.5,633.1,330.7,633.2,330.8L633.2,330.8z M630,337.7
	l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6c1.4,2.2,3.7,3.3,7,3.3h1l0.1-1.9C633.9,343.4,632.7,340.3,630,337.7z M626.2,343.9l1.5-1.4
	l1.9,0.7l0.4,1.9l-1.5,1.4l-1.9-0.5L626.2,343.9L626.2,343.9z"
          />
          <path
            fill="none"
            d="M627.7,342.5l-1.5,1.4l0.4,1.9l1.9,0.5l1.5-1.4l-0.4-1.9 M601.3,330.7l-8.8-6.3c-5.4,6.5-8.1,14-8.1,22.8v1.8
	h10.7v-1.8C595.1,340.8,597.2,335.3,601.3,330.7L601.3,330.7z M600.6,353.4l-0.3-0.7c-0.5-0.8-1.4-1.2-2.8-1.4l-2.2-0.1
	c0.4,2.5,1.1,4.8,2.2,7l1.8-1.5c1-0.8,1.5-1.7,1.4-2.6L600.6,353.4L600.6,353.4z"
          />
          <path
            fill="none"
            d="M595.1,347.2v1.8h11.6v-1.8c0-3.7,1.4-6.9,4-9.6l-9.5-6.9C597.2,335.3,595.1,340.8,595.1,347.2L595.1,347.2z
	 M597.6,358.2c-1.1-2.2-1.8-4.5-2.2-7l-10.9-0.5c0.4,5.2,1.9,10,4.5,14.3L597.6,358.2z M598.8,360.2l-8.8,6.3c1.4,2.1,3,4,4.8,5.9
	c4.7,4.7,10,7.8,16.1,9.4l3.4-10.3c-4.4-1.1-8.4-3.3-11.8-6.7C601,363.4,599.8,361.9,598.8,360.2L598.8,360.2z M632.2,353.5
	l-0.8-0.5c-3-2.1-5.8-2.5-8.3-1.1c-1.7,1.9-2.1,4.5-1.1,7.7l0.3,1c2.8-0.4,5.2-1.6,7.3-3.9C630.9,355.7,631.7,354.6,632.2,353.5
	L632.2,353.5z M623.6,352.7l1.8-1l1.7,1v2.1l-1.7,1l-1.8-1V352.7z"
          />
          <path
            fill="none"
            d="M625.2,351.7l-1.7,1v1.9l1.7,1.1l1.8-1.1v-1.9 M609.1,352.7l-1,0.7c0.7,1.2,1.5,2.3,2.5,3.3
	c2.1,2.1,4.5,3.3,7.3,3.9l0.3-1c1.1-3.4,0.5-6.2-1.5-8.3C614.4,350.3,611.9,350.7,609.1,352.7L609.1,352.7z M615.2,355.7l-1.5-1.4
	l0.4-1.9l1.9-0.5l1.5,1.4l-0.4,1.9L615.2,355.7L615.2,355.7z"
          />
          <path
            fill="none"
            d="M613.7,354.5l1.5,1.2l1.9-0.5l0.4-1.9l-1.5-1.4l-1.9,0.5 M610.6,356.7c-1.1-1.1-1.9-2.2-2.5-3.3l-9.4,6.9
	c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1C615.2,360.1,612.7,358.9,610.6,356.7L610.6,356.7z M616.6,372.1
	l-3.9,10.3c2.3,0.5,5,0.7,7.6,0.7s5.1-0.3,7.4-0.7l-3.9-10.3l-3.6,0.3L616.6,372.1z"
          />
          <path
            fill="none"
            d="M619.5,367.8c-1,0.3-1.7,1-2.1,2.2l-0.8,2.1l3.7,0.3l3.6-0.3L623,370c-0.5-1.2-1.2-1.9-2.1-2.2L619.5,367.8
	L619.5,367.8L619.5,367.8z M689.7,365.3l-8.1,0.5v4.8l60.4-6.7c2.1-0.3,3.7-0.7,5.4-1.1c1.9-0.5,3.7-1.4,5-2.2
	c0.7-0.4,1.4-1,1.9-1.5l7.4-9.4l5.6-7.2l1.4-1.6c0.7-0.7,1.4-1.4,2.2-1.9c2.1-1.4,4.8-2.2,8.1-2.5l14.2-0.1c0.5-0.8-0.1-1.4-2.1-1.9
	h-16.2c-4.3,0.5-7.3,1.9-9.1,4c-4.3,5-8.4,8.9-12.4,11.7c-4.7,3.3-9.1,5.5-13.1,6.6c-18.7,2.2-33.6,5-44.8,8.1L689.7,365.3
	L689.7,365.3z M769.8,258.3c1-1.1,1.2-1.9,0.8-2.5l-18.8,4.7c-1.9,0.5-3.3,1.6-4.1,3.2l-1.1,2.2c0.5-0.7,1.1-1.2,1.7-1.8
	c1.2-1,2.8-1.6,4.5-2.2L769.8,258.3L769.8,258.3z"
          />
          <path
            fill="none"
            d="M765.7,264.5l3-5.1l1.1-1l-17.1,3.7c-1.8,0.4-3.3,1.2-4.5,2.2l1.1,1.5l5.2-2.6l0.3,0.8l4.4-1.9l1.1,2.5l0.7,0.8
	L765.7,264.5L765.7,264.5z"
          />
          <path
            fill="none"
            d="M765.4,264.9l0.3-0.4l-4.5,1l15.1,19.7c1,1.2,0.7,2.2-0.8,2.6c-2.1,0.3-3.7-0.1-4.8-1.2l-0.5,0.1
	c-1.5,0.4-3.6,0-6.2-1.2c-0.8,1.5-2.8,1.5-5.9-0.3l-12.5-17.5l-1,1.8c-1.1,3.2,0,6.1,3.2,8.9l10.9,10h0.1c1,0.8,3,1.2,6.1,1.2
	l17.6,0.4c2.8-0.7,3.2-1.5,1.2-2.5l-5-3l-11.1-16.8L765.4,264.9L765.4,264.9z"
          />
          <path
            fill="none"
            d="M771.2,268.9c-1-1.4-2.1-1.5-3.4-0.5l11.1,16.8c1.9-1.1,2.5-2.1,1.4-3L771.2,268.9L771.2,268.9z M755,264.1
	l0.3,0.8l15,19.4c0.8,1.1,1,1.9,0.5,2.3c1.1,1.1,2.8,1.4,4.8,1.2c1.5-0.5,1.7-1.4,0.8-2.6l-15.1-19.7l-0.7-0.8l-1.1-2.5L755,264.1
	L755,264.1z"
          />
          <path
            fill="none"
            d="M755.4,264.9l-0.3-0.8l-0.3-0.8l-5.2,2.6l14.7,19.8c2.6,1.2,4.7,1.6,6.2,1.2l0.5-0.1c0.5-0.4,0.3-1.1-0.5-2.3
	L755.4,264.9L755.4,264.9z"
          />
          <path
            fill="none"
            d="M749.4,265.7l-1.1-1.5c-0.7,0.5-1.2,1.1-1.7,1.8l-0.8,1.8l12.5,17.5c3.2,1.6,5.1,1.8,5.9,0.3L749.4,265.7
	L749.4,265.7z M780.3,342.8c1.1-1.1,2.6-1.9,4.5-2.3c1.5-0.4,3.4-0.5,5.6-0.5l-0.1-2.3l-12.4,0.8c1.2,0.3,1.2,1.1,0,2.2l-1.1,1.4
	c-0.5,0.4-1.2,0.7-2.2,0.5h-7.4l-5.6,7.2h0.1l0.3-0.3l2.2-2.3c1-1,1.7-1.4,2.2-1.5h0.5c0.4,0.3,0.1,1.2-0.7,3l-0.5,1.2h1.9
	c0.4,0.3,0.1,1.1-0.5,2.8c-0.7,1.5-1.8,3.3-3.3,5.2l-2.5,3L780.3,342.8L780.3,342.8z"
          />
          <path
            fill="none"
            d="M784.8,340.4c-1.9,0.4-3.4,1.2-4.5,2.3l-18.8,18l-1.5,1.5c-1,0.8-1.7,1.4-2.1,1.4h-0.1l-2.6,0.4l0.4,1.1
	c2.9-0.7,5.8-1.5,8.8-2.3l12.9-12l6.7-4c2.2-1.1,2.9-2.5,2.2-4.1L784.8,340.4L784.8,340.4z"
          />
          <path
            fill="none"
            d="M784.8,340.4l1.1,2.2c0.7,1.8,0,3.2-2.2,4.1l-6.7,4l-12.9,12c6.1-1.9,12.5-4.3,19-7c1.2-6.5,3.7-11.7,7.3-15.8
	C788.2,339.9,786.4,340,784.8,340.4L784.8,340.4z M792.2,337.5l1.1-1.1l-14.2,0.1c-3.3,0.3-5.9,1.1-8.1,2.5l7-0.5l12.4-0.8
	L792.2,337.5L792.2,337.5z M767.1,345.5h-0.4c-0.5,0.1-1.2,0.7-2.2,1.5l-2.2,2.3l-0.3,0.3h3.9l0.5-1.2
	C767.2,346.9,767.5,345.9,767.1,345.5L767.1,345.5z"
          />
          <path
            fill="none"
            d="M768.7,341l-1.4,1.6h7.4c1,0.1,1.7-0.1,2.2-0.5l1.1-1.4c1.2-1.1,1.2-1.9,0-2.2l-7,0.5
	C770.1,339.5,769.4,340.1,768.7,341L768.7,341z M767.7,349.8c-0.1-0.1-0.3-0.1-0.4,0c-0.4,0.1-1.1,0.5-2.1,1.4l-2.1,2.2
	c-0.7,0.7-1.2,1.5-1.9,2.5l-1.5,2.1c-0.7,1.1-1.4,2.2-1.8,3c-0.7,1.5-1,2.5-0.5,2.8h0.3c0.4,0,1.1-0.5,2.1-1.4l1.5-1.5l2.5-3
	c1.4-1.9,2.5-3.6,3.3-5.2C767.9,351,768.2,350.1,767.7,349.8L767.7,349.8z"
          />
          <path
            fill="none"
            d="M763.3,353.4l2.1-2.2c1-0.8,1.5-1.2,2.1-1.4H762l-7.4,9.4c-0.5,0.6-1.2,1.1-1.9,1.5c-1.7,1.5-2.2,2.8-1.7,4
	l4.3-0.5l2.6-0.4h-0.1c-0.4-0.3-0.1-1.1,0.5-2.8c0.4-1,1.1-1.9,1.8-3l1.5-2.1C762,354.9,762.7,354,763.3,353.4L763.3,353.4z"
          />
          <path
            fill="none"
            d="M750.7,364.6c-0.5-1.1-0.1-2.5,1.7-4c-1.4,0.8-3,1.6-5,2.2L750.7,364.6L750.7,364.6z M64.1,292.3l1.5,4.3
	l2.6,7.4c0.8,1.5,1,3.2,0.6,5c-0.1,0.8-0.6,1.7-1.1,2.6l-1.1,2.3l-0.8,2.1c-1.9,5.5-2.9,12.2-2.9,20.2c0,1.1,1.2,1.6,4.1,1.6
	l10.6-0.3c-1.7-0.3-2.3-1.5-1.9-3.7c0-2.2,1.4-3.6,3.9-3.9c-5.1,0.1-9.6,0.5-14,1.5c4.3-0.8,8.9-1.4,14-1.5h6.6c5,0,8.3,1.1,10,3.2
	l2.8,3.6l2.9,3.7l10.6,13.6c3.2,3.9,3.2,5.8,0,5.9l-47.2,1c1,1.2,2.8,1.8,5.2,1.6l36.5,0.8c2.8,0.1,5.6,0.4,8.4,0.8l9.6,1.2
	c0-12.7,0.8-23.9,2.6-33.6c1.2-6.7,3.7-12.9,7.3-18.8c2.1-3.4,4.7-6.7,7.6-9.9c1.4-1.5,2.8-2.9,4.3-4.3c3.6-3.2,7.3-6.1,11.1-8.4
	l-10.5-7.7l-14.4,11.3c-3.9,2.5-7.8,4-12.1,4.8l-27.1,3.6c-4.3,0.4-7.2-0.5-8.4-2.9L79,287.3c-2.2-3.6-4.5-6.5-6.9-8.7
	c-1.5-1.4-3-2.5-4.5-3.3c-3.9,1.9-7.6,4-11.1,6.1c-1.8,1.5-1.7,2.9,0.4,4.1C60.7,286.5,63,288.7,64.1,292.3L64.1,292.3z"
          />
          <path
            fill="none"
            d="M67.7,275.2c1.5,0.8,3,1.9,4.5,3.3c35.8-14.4,77.9-22.8,126.3-25.3l77-2.8l3-5.1c-26.3-0.6-54.9,0.1-85.7,2.2
	c-37,3.2-66.6,7.7-88.9,13.9C90.7,265.2,78.6,269.7,67.7,275.2L67.7,275.2z M85.6,299.4c1.2,2.2,4,3.2,8.4,2.9l27.1-3.6
	c4.3-0.8,8.3-2.3,12.1-4.8l14.4-11.3c-0.4-1.1-2.2-1.6-5.2-1.8c-9.2,0.4-18.6,1.1-27.8,2.1l0.6,12.8l0.7-0.4c-0.1-0.1-0.1-0.4,0-0.5
	c0-0.3,0.1-0.4,0.3-0.5c3.3-3,4.8-5.9,4.4-9.1v-0.5l0.4-0.4c0.1-0.1,0.4-0.1,0.6-0.1l16.8-1.5l1.8,0.1c0.8,0.3,1.2,0.7,1.1,1.5
	c0.1,0.7-0.6,1.6-2.1,3l-8.1,5.9l-0.8,0.5c-3.2,2.3-6.2,3.6-9.1,3.9l-9.6,1.2l4-2.8l-0.6-12.8l-12.1,1.2c-7.7,0.8-15.4,1.8-23.1,3
	L85.6,299.4L85.6,299.4z M86.1,288c1.8-0.5,3.2,0,4.1,1.7l4.3,8.8c0.6,0.7-0.3,1.1-2.2,1.4L86.1,288L86.1,288z M101.4,297.8
	c0.6,0.7-0.3,1.1-2.2,1.4L93,287.5c1.8-0.5,3.2,0,4.1,1.7L101.4,297.8L101.4,297.8z M107.9,297.5c0.6,0.7-0.3,1.1-2.2,1.4l-6.2-11.7
	c1.8-0.5,3.2,0,4.1,1.6L107.9,297.5z"
          />
          <path
            fill="none"
            d="M90.2,289.7c-1-1.6-2.2-2.2-4.1-1.6l6.2,11.7c2.1-0.1,2.8-0.5,2.2-1.4L90.2,289.7z M99.2,299.2
	c2.1-0.1,2.8-0.5,2.2-1.4l-4.3-8.8c-1-1.6-2.2-2.2-4.1-1.6L99.2,299.2L99.2,299.2z M105.7,298.9c2.1-0.1,2.8-0.5,2.2-1.4l-4.3-8.8
	c-1-1.6-2.2-2.2-4.1-1.6L105.7,298.9L105.7,298.9z M79.1,287.2c7.7-1.1,15.4-2.1,23.1-3c12.7-8.3,31-14.9,54.6-19.9l-18.4,2.5
	C110.9,272.1,91.1,278.8,79.1,287.2L79.1,287.2z M120.4,284.4c0,0.1-0.1,0.4,0,0.5c0.4,3-1.1,6.1-4.4,9.1c-0.1,0.1-0.3,0.4-0.3,0.5
	v0.7c0.1,0.1,0.3,0.4,0.6,0.4c2.8,1.1,6.5,0.7,11-1.2l2.5-1.2l0.8-0.5l8.1-5.9c1.5-1.2,2.2-2.3,2.1-3c0-0.7-0.3-1.2-1.1-1.5
	l-1.8-0.1l-16.6,1.8c-0.1,0-0.4,0.1-0.6,0.1L120.4,284.4L120.4,284.4z M129.7,291.4l-3.2,1.6c-3,1.2-5.6,1.8-7.8,1.7
	c2.6-2.9,4-5.8,3.9-8.8l16-1.5h0.1l-1,0.8L129.7,291.4L129.7,291.4z"
          />
          <path
            fill="none"
            d="M126.7,293.2l3.2-1.6l8.3-6.2l1-0.8h-0.1l-16,1.5c0.1,3-1.1,5.9-3.8,8.8C120.9,295,123.5,294.5,126.7,293.2
	L126.7,293.2z"
          />
          <path
            fill="none"
            d="M116.2,295.9c-0.3-0.1-0.4-0.3-0.6-0.4v-0.1l-0.7,0.4l-4,2.8l9.6-1.2c2.9-0.3,5.9-1.5,9.1-3.9l-2.5,1.2
	C122.7,296.4,119,296.8,116.2,295.9L116.2,295.9z M134.9,337.3v0.4c-1.4,7.8-2.1,16.6-2.2,26.5L144,366l-0.8-2.5
	c-1.6-5.1-2.3-10.6-2.3-16.4c0-15,5.4-27.8,16-38.4c2.1-2.1,4.3-4,6.5-5.6c9.2-6.9,19.9-10.3,31.9-10.3c12.1,0,22.7,3.4,31.9,10.3
	c2.2,1.6,4.4,3.6,6.5,5.6c5.2,5.2,9.2,11,11.8,17.2c2.8,6.5,4.1,13.6,4.1,21.2c0,10.3-2.5,19.7-7.6,27.9l10.6,1.4l5.1,0.7l0.1-3.4
	v-1.2c0.3-13.3-0.1-24.5-1.5-33.6c-0.4-2.9-1.1-5.8-1.9-8.5c-2.9-9.1-8-17.3-15.4-24.5l-2.8-2.6c-11.4-10-25-15.1-40.7-15.1
	c-11.3,0-21.3,2.6-30.4,7.7c-3.6,2.1-7,4.5-10.3,7.4l-2.8,2.6C142.8,314.7,137.2,325.3,134.9,337.3L134.9,337.3z"
          />
          <path
            fill="none"
            d="M134.9,337.7v-0.4c2.1-12,7.8-22.6,17.1-31.6l2.8-2.6c3.2-2.9,6.6-5.4,10.3-7.4l-7.2-5.4
	c-3.9,2.3-7.6,5.2-11.1,8.4c-1.5,1.4-3,2.8-4.3,4.3c-2.9,3.2-5.4,6.5-7.6,9.9c-3.6,5.8-6.1,12.1-7.3,18.8
	c-1.8,9.6-2.6,20.9-2.6,33.6l7.7,0.4v-1.5C132.9,354.3,133.6,345.5,134.9,337.7L134.9,337.7z M68.8,308.9c0.4-1.8,0.3-3.3-0.6-5
	l-2.6-7.4l1.9,11.4L68.8,308.9L68.8,308.9z M79.7,330c-2.6,0.3-3.9,1.6-3.9,3.9c-0.4,2.2,0.1,3.4,1.9,3.7h1.4l6.1-0.3l3.3-0.1
	l10.9-0.4l-2.8-3.6c-1.8-2.1-5.2-3.2-10-3.2C84.1,329.8,81.7,329.8,79.7,330L79.7,330z"
          />
          <path
            fill="none"
            d="M78.8,337.5h-1.4l-10.6,0.3c-2.8,0-4.1-0.5-4.1-1.6c0-8,1-14.7,2.9-20.2l-5,0.8l-5.8,1c-3,0.7-4.3,1.8-4.1,3.2
	c0,4.4,0.5,9.5,1.7,15.4c0.1,1.2,1.4,2.1,3.7,2.6l9.6,1.8c0.6-0.5,1.2-0.8,2.1-1.1l10.5-1.1L78.8,337.5L78.8,337.5z M85,337.3
	l-6.1,0.3l-0.3,1c-1.1,3.9-1.7,7.7-1.7,11.6c0,3.2,1.4,4.7,4.3,4.7l31.5-0.5l-10.6-13.6l-4-0.1c0.6,0.7,1,1.5,1.4,2.5
	c1.4,3.9,1.1,6.7-1,8.7c-1.7,1.6-4.1,2.2-7.4,1.5h-4.5c-2.5-0.1-3.7-1.9-3.6-5.2C82.8,344,83.5,340.6,85,337.3L85,337.3z"
          />
          <path
            fill="none"
            d="M78.6,338.5l-10.5,1.1c-0.8,0.1-1.5,0.5-2.1,1.1c-1.1,1-1.7,2.3-1.9,4.3l-2.2,9.4c-0.1,1.4-1.2,2.2-3.3,2.5
	c-3.7,1.1-5.2,2.5-4.8,4.3l2.6-0.4c3.4-0.4,6.1-1.5,8-3.3c-0.1,1.5,0.1,2.8,0.8,3.6l47.5-0.8c3.2-0.1,3.2-2.1,0-5.9l-31.5,0.4
	c-2.9,0-4.3-1.5-4.3-4.7C76.9,346.2,77.4,342.3,78.6,338.5L78.6,338.5z M99.2,336.7l-10.9,0.4v0.3c4.5-0.8,7.7,0.1,9.8,2.9l4,0.1
	L99.2,336.7L99.2,336.7z"
          />
          <path
            fill="none"
            d="M88.5,337.5v-0.3l-3.3,0.1c-1.5,3.3-2.2,6.7-2.1,10.5c0,3.4,1.1,5.1,3.6,5.2h4.5l-1.2-0.3L88.5,337.5
	L88.5,337.5z"
          />
          <path
            fill="none"
            d="M98.1,340.3c-2.1-2.8-5.2-3.7-9.8-2.9l1.4,15.3l1.2,0.3c3.2,0.7,5.6,0.1,7.4-1.5c2.1-1.9,2.5-4.8,1-8.7
	C99,341.9,98.6,341.1,98.1,340.3L98.1,340.3z M75.7,367v5.6c7.2,2.2,17.2,3.4,30.3,3.7h16.9v-5.9l-13.3-0.7L75.7,367z"
          />
          <path
            fill="none"
            d="M132.6,365.7l-7.7-0.4l-9.6-1.2c-2.8-0.4-5.6-0.7-8.4-0.8l-36.5-0.8c-2.6,0.1-4.3-0.4-5.2-1.6
	c-0.7-0.8-1-2.1-0.8-3.6c-1.8,1.8-4.4,2.9-8,3.3l-2.6,0.4l-1.9,0.3h-0.3c-2.5,0.7-3,1.5-1.4,2.6c3.2,1.1,7.4,1.8,13.1,2.1l12.2,1
	l33.8,2.8l13.3,0.7l9.8,0.4L132.6,365.7L132.6,365.7z M66.7,313.9l-5.8,2.9l5-0.8 M397.8,200.2l-4.8-3.6
	c-13.1,4-34.3,13.6-63.6,28.9c-11,5.8-23.2,12.4-36.6,19.7c-3.2,1.8-6.3,3.6-9.6,5.4l31.2,1.1c11.4-8.5,24.9-17.3,40.3-26.1
	c1.8-1,3.6-2.1,5.4-3c12.2-6.7,24.6-13.1,37.4-19C399.5,202.6,399.5,201.5,397.8,200.2L397.8,200.2z"
          />
          <path
            fill="none"
            d="M283.2,250.6c3.3-1.8,6.5-3.6,9.6-5.4h-14l-3,5.1L283.2,250.6L283.2,250.6z M157.9,290.4l7.2,5.4
	c9.1-5.2,19.1-7.7,30.4-7.7c15.7,0,29.2,5.1,40.7,15.1l2.8,2.6c7.4,7.3,12.5,15.4,15.4,24.5c0.8,2.8,1.5,5.6,1.9,8.5
	c1.2,9.1,1.8,20.2,1.5,33.6h8c0.5-15.5,0-28.6-1.5-38.9c-0.5-3-1.2-6.1-2.2-8.9c-2.5-7.4-6.5-14.2-12.1-20.4l-0.8-1
	c-1.4-1.5-2.9-2.9-4.4-4.3c-13.5-12.4-29.9-18.4-49-18.4C181.6,280.3,168.9,283.6,157.9,290.4L157.9,290.4z"
          />
          <path
            fill="none"
            d="M233.4,308.6c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3
	c-2.2,1.6-4.4,3.6-6.5,5.6c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4l0.8,2.5c2.6,7.2,6.9,13.8,12.7,19.5
	c10.6,10.6,23.4,16,38.4,16s27.8-5.2,38.4-16c3.3-3.3,6.1-6.9,8.4-10.6c5.1-8.3,7.6-17.5,7.6-27.9c0-7.6-1.4-14.7-4.1-21.2
	C242.7,319.6,238.7,313.9,233.4,308.6L233.4,308.6z M235,347.2c0,11-3.9,20.4-11.7,28.2c-7.8,7.8-17.2,11.7-28.2,11.7
	c-11,0-20.4-3.9-28.2-11.7c-7.8-7.8-11.7-17.2-11.7-28.2s3.9-20.4,11.7-28.2s17.2-11.7,28.2-11.7c11,0,20.4,3.9,28.2,11.7
	C231.1,326.7,235,336.2,235,347.2L235,347.2z"
          />
          <path
            fill="none"
            d="M223.3,375.4c7.8-7.8,11.7-17.2,11.7-28.2s-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7
	s-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7S215.6,383.1,223.3,375.4L223.3,375.4z
	 M232.8,347.2c0,10.5-3.7,19.3-11,26.7c-7.4,7.4-16.2,11-26.7,11s-19.3-3.7-26.7-11c-7.4-7.4-11-16.2-11-26.7s3.7-19.3,11-26.7
	c7.4-7.4,16.2-11,26.7-11c10.5,0,19.3,3.7,26.7,11C229.2,327.8,232.8,336.7,232.8,347.2L232.8,347.2z"
          />
          <path
            fill="none"
            d="M221.8,373.7c7.3-7.4,11-16.2,11-26.7s-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11s-19.3,3.7-26.7,11
	c-7.4,7.4-11,16.2-11,26.7s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11C205.5,384.9,214.5,381.1,221.8,373.7z M220.5,321.7l1.4,1.4
	l1.2,1.4c5.4,6.5,8,13.9,8,22.6v1.9l-0.1,1.8c-0.6,5.2-2.1,9.9-4.5,14.2l-1,1.5c-1.4,2.1-3,4-4.8,5.8c-4.7,4.7-10,7.8-16.1,9.4
	l-1.8,0.4c-2.3,0.5-5,0.7-7.4,0.7c-2.6,0-5.1-0.3-7.6-0.7l-1.8-0.4c-6.1-1.5-11.4-4.7-16.1-9.4c-1.9-1.9-3.4-3.9-4.8-5.9l-1-1.5
	c-2.6-4.4-4.1-9.1-4.5-14.3c0-0.5-0.1-1.2-0.1-1.8v-1.8c0-8.7,2.8-16.4,8.1-22.8l1.2-1.4l1.2-1.2c3.3-3.3,6.9-5.8,10.9-7.6l1.6-0.7
	c4-1.5,8.3-2.2,12.8-2.2c4.7,0,9.1,0.8,13.1,2.3l1.6,0.7C213.8,315.9,217.2,318.4,220.5,321.7L220.5,321.7z"
          />
          <path
            fill="none"
            d="M221.9,323.1l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4l-2.9,10.6c2.1,1.1,4,2.6,5.8,4.3L221.9,323.1L221.9,323.1z"
          />
          <path
            fill="none"
            d="M212.7,329.1l-1.6,1.1c-1.1,0.7-2.1,0.8-3,0.5l-0.7-0.4c-0.3-0.1-0.4-0.3-0.6-0.5c-0.6-0.7-0.8-1.6-0.4-3
	l0.6-2.1l2.9-10.6l-1.7-0.7l-3.3,10.3l-3.6,11.1l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8l-0.3-1l-3.7-11
	l-3.3-10.3l-1.7,0.7l2.9,10.6l0.6,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.6c-0.3,0.1-0.4,0.3-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2
	l-9.2-6.1l-1.2,1.4l8.8,6.3l9.5,6.9l0.8,0.5c2.6,2.1,3.9,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4h-23.5c0,0.5,0,1.2,0.1,1.8l10.9,0.5
	l2.2,0.1c1.2,0.1,2.2,0.5,2.8,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5l-8.5,6.9l1,1.5l8.8-6.3l9.4-6.9l1-0.7
	c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1l-3.6,11.1l-3.4,10.3l1.8,0.4l3.9-10.3l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2
	h1.5c1,0.3,1.6,1,2.1,2.2l0.8,2.1l3.9,10.3l1.8-0.4l-3.3-10.3l-3.6-11.1l-0.3-1c-1-3.2-0.6-5.8,1.1-7.7c2.5-1.4,5.4-1,8.3,1.1
	l0.8,0.6l9.4,6.9l8.8,6.3l1-1.5l-8.5-6.9l-1.6-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7c0.4-0.8,1.4-1.2,2.8-1.4
	l2.1-0.1l10.9-0.6l0.1-1.8H208c-3.3,0-5.6-1.1-7-3.3c-0.4-2.8,0.7-5.4,3.6-7.6l0.7-0.5l9.4-6.9l8.8-6.3l-1.2-1.4L212.7,329.1
	L212.7,329.1z M195.1,342.2c1.4,0,2.5,0.5,3.4,1.4c1,1,1.4,2.1,1.4,3.4s-0.6,2.5-1.4,3.4c-1,1-2.1,1.4-3.4,1.4
	c-1.4,0-2.5-0.5-3.4-1.4c-1-1-1.4-2.1-1.4-3.4c0-1.4,0.6-2.5,1.4-3.4C192.6,342.8,193.7,342.2,195.1,342.2L195.1,342.2z"
          />
          <path
            fill="none"
            d="M198.5,343.7c-1-1-2.1-1.4-3.4-1.4c-1.4,0-2.5,0.5-3.4,1.4s-1.4,2.1-1.4,3.4c0,1.4,0.6,2.5,1.4,3.4
	c0.8,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4C199.9,345.8,199.5,344.7,198.5,343.7L198.5,343.7z M211,330.2
	l1.6-1.1c-1.8-1.8-3.7-3.2-5.8-4.3l-0.6,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5l0.7,0.4C209,331.2,209.9,331.1,211,330.2L211,330.2z
	 M223.1,324.6l-8.8,6.3c4,4.5,5.9,10,5.9,16.2v1.9H231v-1.9C231,338.5,228.4,330.9,223.1,324.6L223.1,324.6z"
          />
          <path
            fill="none"
            d="M220.1,349.1v-1.9c0-6.2-1.9-11.7-5.9-16.2l-9.4,6.9c2.5,2.6,3.7,5.8,3.7,9.4l-0.1,1.9H220.1L220.1,349.1z
	 M215.1,352.8l-0.3,0.7c0,0.3-0.1,0.5-0.1,0.7c-0.1,1,0.4,1.8,1.4,2.6l1.7,1.4c1.1-2.2,1.8-4.4,2.2-6.9l-2.1,0.1
	C216.4,351.6,215.6,352,215.1,352.8L215.1,352.8z M216.5,360.4c-1,1.6-2.2,3.2-3.7,4.5c-3.4,3.4-7.4,5.6-11.8,6.7l3.3,10.3
	c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8L216.5,360.4L216.5,360.4z"
          />
          <path
            fill="none"
            d="M212.8,364.9c1.4-1.4,2.6-2.9,3.7-4.5l-9.4-6.9c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9l3.6,11.1
	C205.5,370.5,209.4,368.3,212.8,364.9L212.8,364.9z M220,351.4c-0.4,2.5-1.1,4.7-2.2,6.9l8.5,6.9c2.6-4.3,4.1-9.1,4.5-14.2
	L220,351.4z M208.1,313.6c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2l3.3,10.3c2.9-1.1,6.1-1.8,9.4-1.8c3.4,0,6.7,0.5,9.8,1.9
	L208.1,313.6L208.1,313.6z"
          />
          <path
            fill="none"
            d="M204.8,323.8c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8l3.6,11.1c1.8-0.8,3.7-1.2,5.8-1.2
	c2.2,0,4.3,0.5,6.2,1.4L204.8,323.8L204.8,323.8z M184.2,326.8l-0.6-2.1c-2.1,1.1-4.1,2.5-5.9,4.3l1.8,1.2c1.1,0.7,2.1,0.8,3,0.5
	c0.3-0.1,0.4-0.3,0.7-0.4l0.6-0.5C184.3,329,184.5,328,184.2,326.8L184.2,326.8z M186.4,338.1l-0.8-0.5c-2.6,2.6-4,5.8-4,9.6
	l0.1,1.8h1.1c3.6,0,6.1-1.4,7.3-4C190.2,342.3,189,340.1,186.4,338.1L186.4,338.1z M186.1,343l1.9-0.3l1.2,1.6l-0.8,1.8l-1.9,0.3
	l-1.2-1.7L186.1,343L186.1,343z"
          />
          <path
            fill="none"
            d="M188.2,342.8l-2.1,0.3l-0.8,1.8l1.2,1.7l1.9-0.3l0.8-1.8 M177.7,329c1.8-1.8,3.7-3.2,5.9-4.3l-2.9-10.6
	c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2L177.7,329L177.7,329z M185.6,337.5l-9.5-6.9c-4.1,4.7-6.2,10-6.2,16.5v1.8h11.6l-0.1-1.8
	C181.4,343.3,182.8,340.1,185.6,337.5L185.6,337.5z M189.3,334.8l0.3,1c1.1,3.4,3.2,5.4,5.9,5.8c2.5-0.5,4.3-2.5,5.4-5.6l0.3-1
	c-1.9-1-3.9-1.4-6.2-1.4C193,333.5,191.1,334,189.3,334.8L189.3,334.8z M195.9,340.7l-2.1-0.3l-0.8-1.8l1.1-1.6l1.9,0.1l0.8,1.8
	L195.9,340.7L195.9,340.7z"
          />
          <path
            fill="none"
            d="M193.8,340.4l2.1,0.3l1.1-1.6l-0.8-1.8l-1.9-0.3l-1.1,1.6 M205,337.7l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6
	c1.4,2.2,3.7,3.3,7,3.3h1l0.1-1.9C208.7,343.4,207.4,340.3,205,337.7L205,337.7z M202.5,342.5l1.9,0.7l0.4,1.9l-1.5,1.4l-1.9-0.5
	L201,344L202.5,342.5z"
          />
          <path
            fill="none"
            d="M204.4,343l-1.9-0.5l-1.4,1.4l0.4,1.9l1.8,0.5l1.5-1.4 M170,348.8V347c0-6.3,2.1-11.8,6.2-16.5l-8.8-6.3
	c-5.4,6.5-8.1,14-8.1,22.8v1.8H170L170,348.8z M172.5,351.3l-2.2-0.1c0.4,2.5,1.1,4.8,2.2,7l1.8-1.5c1-0.8,1.5-1.7,1.4-2.6l-0.1-0.7
	l-0.3-0.7C174.7,351.8,173.7,351.4,172.5,351.3L172.5,351.3z"
          />
          <path
            fill="none"
            d="M170.3,351.2l-10.9-0.5c0.4,5.2,1.9,10,4.5,14.3l8.5-6.9C171.4,356,170.6,353.6,170.3,351.2L170.3,351.2z
	 M173.6,360.2l-8.8,6.3c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4l3.4-10.3c-4.4-1.1-8.4-3.3-11.8-6.7
	C175.8,363.4,174.6,361.9,173.6,360.2L173.6,360.2z M207.2,353.5l-0.8-0.5c-3-2.1-5.8-2.5-8.3-1.1c-1.6,1.9-2.1,4.5-1.1,7.7l0.3,1
	c2.8-0.4,5.2-1.6,7.3-3.9C205.7,355.7,206.5,354.6,207.2,353.5L207.2,353.5z M201.8,352.7v2.1l-1.6,1l-1.8-1v-2.1l1.8-1L201.8,352.7
	z"
          />
          <path
            fill="none"
            d="M201.8,354.6v-1.9l-1.6-1l-1.8,1v1.9l1.8,1.1 M192.7,360.5l0.3-1c1.1-3.4,0.6-6.2-1.5-8.3c-2.3-1-5-0.5-7.7,1.4
	l-1,0.7c0.7,1.2,1.5,2.3,2.5,3.3C187.6,358.9,190,360.1,192.7,360.5L192.7,360.5z M188.9,352.4l1.9-0.5l1.5,1.4l-0.4,1.9l-1.9,0.5
	l-1.5-1.4L188.9,352.4L188.9,352.4z"
          />
          <path
            fill="none"
            d="M190.8,351.8l-1.9,0.5l-0.4,2.1l1.5,1.2l1.9-0.5l0.4-1.9 M185.6,356.7c-1.1-1.1-1.9-2.2-2.5-3.3l-9.4,6.9
	c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7l3.6-11.1C190,360.1,187.6,358.9,185.6,356.7L185.6,356.7z M191.5,372.1
	l-3.9,10.3c2.3,0.5,5,0.7,7.6,0.7c2.6,0,5.1-0.3,7.4-0.7l-3.9-10.3l-3.6,0.3L191.5,372.1L191.5,372.1z"
          />
          <path
            fill="none"
            d="M192.2,370l-0.8,2.1l3.7,0.3l3.6-0.3l-0.8-2.1c-0.6-1.2-1.2-1.9-2.1-2.2h-1.5
	C193.4,367.9,192.6,368.8,192.2,370L192.2,370z M252.4,376.5l2.2,5.8h62.2l3.4-5.2l-42,2.2l-6.3-1.9h-14.4v-0.1"
          />
          <path
            fill="none"
            d="M257.5,377.2v0.1h1.2 M309,335.6v-0.4c-12.7,1.5-22.3,3.6-28.8,6.3c-6.6,3-3.4,4.4,9.5,4l19.4-0.5
	c-0.4-2.1-0.5-4-0.4-5.8C308.8,337.9,308.8,336.8,309,335.6z M316.5,361.9c1.2,0.3,2.8,0.3,4.5,0H316.5z M378.3,246.3l-0.8-5
	c-0.4-2.6-2.1-4.4-5-5.4c2.6,1,3.9,4.4,3.7,10.3L378.3,246.3L378.3,246.3z M516.7,252l-1.9-2.9c-17.6,0.8-35.5,1.4-53.4,1.5
	l-84.9,0.7c-0.1,0.1-0.4,0.3-0.5,0.3c-4,1.2-9.8,1.9-17.1,1.8l-3,2.7h-10.6c-2.5,0-4.7-0.1-6.5-0.7c-1-0.3-1.8-0.6-2.6-1
	c0.5-1.2,1.6-2.2,3.3-2.9l-10.9,0.1l-0.7-0.1c-0.1-0.1-0.3-0.3-0.4-0.6c-0.1-0.3-0.1-0.4,0-0.7l0.1-0.3l-9.2,7l47.2-0.8L516.7,252
	L516.7,252z"
          />
          <path
            fill="none"
            d="M338.8,255.4c1.8,0.6,4,0.7,6.5,0.7h10.6l3-2.8c7.3,0.1,13.1-0.6,17.1-1.8c0.3-0.1,0.4-0.1,0.5-0.3
	c0.4-0.3,0.7-0.6,1-0.8c-7,0.1-13.2,0.6-18.6,1.1C349.7,252.2,342.9,253.6,338.8,255.4L338.8,255.4z"
          />
          <path
            fill="none"
            d="M514.8,249.1l-0.5-1.8c-17.5,0.8-35.1,1.4-52.8,1.5l-83.5,0.7l-0.4,0.8c-0.3,0.4-0.5,0.7-1,0.8l84.9-0.7
	C479.3,250.5,497.2,249.9,514.8,249.1L514.8,249.1z"
          />
          <path
            fill="none"
            d="M339.5,251.6c-1.8,0.6-2.9,1.6-3.3,2.9c0.8,0.4,1.6,0.7,2.6,1c4.3-1.8,10.9-3.2,20.1-4
	c5.4-0.6,11.6-0.8,18.6-1.1c0.1-0.3,0.3-0.6,0.4-0.8c0-0.1,0.1-0.4,0.1-0.6h-12.4c-5.5,0-10.6,0.3-15.1,0.8
	c-1.6,0.1-3.2,0.4-4.5,0.6c-1.6,0.3-3.3,0.6-4.8,0.8C340.5,251.3,339.9,251.4,339.5,251.6L339.5,251.6z"
          />
          <path
            fill="none"
            d="M345.8,250.3c1.5-0.3,3-0.4,4.5-0.6h-5.1L345.8,250.3z M345.3,249.8h5.1c4.5-0.4,9.6-0.7,15.1-0.8h12.4
	c0.1-0.7,0.3-1.6,0.3-2.6h-2.1c-12.5-0.1-23.1,0.3-31.9,1.5C344.5,248.5,344.7,249.1,345.3,249.8L345.3,249.8z"
          />
          <path
            fill="none"
            d="M604.9,231.7c1.8-2.1,1.4-3.7-0.8-4.7l-5.4-1.9c-19-6.2-39.1-11.3-60.1-15.3c-26-5.4-52.5-8.4-79.8-9.1
	c-24.4,0.1-46.2,4.3-65.5,12.2c-8.8,3.7-17.6,7.8-26.4,12.5c-13.1,6.9-26,15-38.9,24.1l-0.3,0.3l-0.1,0.3c-0.1,0.3-0.1,0.4,0,0.7
	c0,0.3,0.1,0.4,0.4,0.6l0.7,0.1l10.9-0.1c0.4-0.1,1-0.3,1.5-0.4c1.5-0.3,3.2-0.6,4.8-0.8l-0.5-0.6l-13.2,0.1
	c7.7-5.4,15.3-10.2,23-14.7c5.8-3.4,11.6-6.6,17.3-9.5c7.2-3.7,14.4-7,21.6-10c19.1-7.8,40.6-11.8,64.7-12c23.9,0.6,47.3,3,70.3,7.3
	l9.1,1.8l8.8,1.6c15.4,3.2,30.1,6.9,44.4,11.4l5.8,1.8c2.3,1.1,2.6,2.5,0.7,4.5c-2.3,2.5-4.7,4.5-6.7,6.1c-2.9,2.2-6.2,3.7-9.9,4.3
	l-0.3,0.1c-15.3,1.5-30.7,2.9-46.1,3.9l-20.5,1.1l0.5,1.8c22.3-1.1,44.4-2.8,66.4-5.1c6.9-0.4,12-1.7,15.3-4
	C599.2,238.1,602,235.3,604.9,231.7L604.9,231.7z"
          />
          <path
            fill="none"
            d="M458.8,203.5c-24.1,0.1-45.5,4.1-64.7,12c-7.3,3-14.4,6.3-21.6,10c-5.8,3-11.6,6.2-17.3,9.5v2.2
	c0.3-0.1,0.5-0.4,1-0.6c3.7-1.6,7.7-2.1,11.8-1.5c1.8,0.1,3.3,0.4,4.5,0.8c2.9,1,4.5,2.8,5,5.4l0.8,5c0,1,0,1.9-0.3,2.6
	c0,0.1-0.1,0.4-0.1,0.6l83.5-0.7c17.7-0.1,35.4-0.6,52.8-1.5l14.9-36.6C506.2,206.6,482.7,204.1,458.8,203.5L458.8,203.5z
	 M344.3,247.8c-0.1-1,0.3-1.9,1.1-2.9c2.2-2.3,5.4-4.9,9.6-7.7V235c-7.7,4.5-15.4,9.5-23,14.7l13.2-0.1
	C344.7,249.1,344.5,248.5,344.3,247.8L344.3,247.8z"
          />
          <path
            fill="none"
            d="M345.6,245.1c-1,1-1.2,1.9-1.1,2.9c8.7-1.2,19.3-1.8,31.9-1.5c0.1-5.9-1.1-9.4-3.7-10.3
	c-1.2-0.4-2.8-0.7-4.5-0.8c-4.1-0.6-8,0-11.8,1.5c-0.3,0.1-0.5,0.4-1,0.6C350.9,240.1,347.8,242.8,345.6,245.1L345.6,245.1z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M88.5,337.5l-0.1-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M129.7,291.5l8.3-6.2l1-0.8h-0.1l-16,1.5c0.1,3-1.1,5.9-3.9,8.8c2.1,0.3,4.8-0.3,7.8-1.6L129.7,291.5L129.7,291.5z M115,295.7
	l-0.6-12.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M115,295.7l-4,2.8l9.6-1.2c2.9-0.3,5.9-1.5,9.1-3.9 M115.6,295.3l-0.6,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
	M129.9,293.2l-2.5,1.2c-4.5,1.9-8.3,2.3-11,1.2c-0.3-0.1-0.4-0.3-0.6-0.4v-0.1 M105.7,298.9l-6.2-11.7c1.8-0.5,3.2,0,4.1,1.6
	l4.3,8.8C108.3,298.2,107.6,298.6,105.7,298.9L105.7,298.9z M129.9,293.2l0.8-0.5l8.1-5.9c1.5-1.2,2.2-2.3,2.1-3
	c0-0.7-0.3-1.2-1.1-1.5l-1.8-0.1l-16.8,1.8c-0.1,0-0.4,0.1-0.6,0.1l-0.4,0.4c0,0.1-0.1,0.4,0,0.5c0.4,3-1.1,6.1-4.4,9.1
	c-0.1,0.1-0.3,0.4-0.3,0.5v0.5 M99.2,299.2L93,287.5c1.8-0.5,3.2,0,4.1,1.7l4.3,8.8C101.9,298.5,101.2,299,99.2,299.2L99.2,299.2z
	 M86.1,288l6.2,11.7c2.1-0.1,2.8-0.5,2.2-1.4l-4.3-8.8C89.3,288,87.9,287.5,86.1,288L86.1,288z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M700.8,238.2c-12.5-4.4-25-8.7-37.7-12.7c-18.7-5.9-37.3-11.6-56-16.5
	c-12.2-3.9-25.3-7.2-39.1-10 M514.8,249.1c22.3-1.1,44.4-2.8,66.4-5.1c6.9-0.4,12-1.6,15.3-4c2.6-2.1,5.5-4.8,8.4-8.3
	c1.8-2.1,1.4-3.7-0.8-4.7l-5.4-1.9c-19-6.2-39.1-11.3-60.1-15.3c-26-5.4-52.5-8.4-79.8-9.1c-24.4,0.1-46.2,4.3-65.5,12.2
	c-8.8,3.7-17.6,7.8-26.4,12.5c-13.1,6.9-26,15-38.9,24.1l-0.3,0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M534.8,246.2c15.4-1,30.8-2.3,46.1-3.9l0.3-0.1c3.7-0.6,7-1.9,9.9-4.3
	c2.1-1.5,4.4-3.6,6.7-6.1c1.9-1.9,1.7-3.4-0.7-4.5l-5.6-1.8c-14.3-4.4-29-8.3-44.4-11.4 M548.3,369.7c0.1-14.6,1.2-26.7,3-36.6
	c1.2-6.7,3.7-12.9,7.3-18.8c2.3-3.9,5.4-7.6,8.8-11.1c1-1,1.9-2.1,3-3c13.5-12.4,29.9-18.4,49-18.4s35.5,6.2,49,18.4l3,3l3,3.2
	 M700.8,238.2c-8.3,1.1-16.5,0.7-24.9-1.4c-12.5-4-24.8-7.8-36.9-11.3c-26.5-7.7-52.3-14.4-77.2-19.9l-0.4-0.1 M547,214.3l-8.8-1.8
	l-8.9-1.6 M561.4,205.6l-1.2-0.3 M561.4,205.6l-1.2-0.3 M529.2,210.8c-23-4.3-46.4-6.6-70.3-7.3c-23.9,0.1-45.5,4.1-64.7,12
	c-7.3,3-14.4,6.3-21.6,10c-5.8,3-11.6,6.2-17.3,9.5 M483.5,277.8l8,0.4l3.3-0.1 M473.8,275.5c1,0.4,2.2,1,3.7,1.2
	c1.9,0.5,3.9,0.8,6.2,1.1 M483.7,268.5c-2.2,0.3-4.4,0.5-6.2,1.1c-1.6,0.4-2.9,0.8-3.7,1.4 M495.1,268.2l-3.6-0.1l-7.8,0.4
	 M481.5,270.8c0.4-0.8,1.2-1.5,2.2-2.3 M483.5,277.8c-1-0.8-1.6-1.5-2.1-2.3 M473.6,271c-0.7,0.3-1.1,0.7-1.5,1
	c-0.4,0.4-0.5,0.8-0.5,1.2s0.1,0.8,0.5,1.2c0.3,0.3,0.8,0.7,1.5,1 M481.5,275.5h-7.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M473.6,271l0.5-0.1h7.3 M516.7,252l-1.9-2.9 M514.8,249.1l-0.5-1.8
	 M509.1,275.5h-11.7 M509.1,275.5c0.8-0.4,1.5-1,1.8-1.4c0.1-0.3,0.3-0.5,0.3-0.8c0-0.3-0.1-0.5-0.3-0.8c-0.3-0.5-1-1-1.8-1.4
	 M494.8,278.1c4-0.1,7.6-0.7,10.6-1.4c1.5-0.4,2.8-0.8,3.7-1.2 M495.1,268.2c1.4,0.8,2.3,1.8,2.9,2.6 M497.8,270.8l11.3,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M509.1,271c-1-0.4-2.2-1-3.7-1.2c-3-0.8-6.5-1.2-10.5-1.4 M505.8,328.9
	c4-8.3,7.6-16.9,10.5-25.9c2.8-8.1,5-16.5,6.9-25.2c1.2-8.7-1-17.3-6.5-26 M514.2,247.3l20.6-1.1 M568.1,199
	c-14.9-3.2-30.5-5.8-47-8c-25.7-3.7-52.4-4.9-80.2-3.9c-18.8,1.4-34.8,4.5-48,9.6 M376.5,251.3l84.9-0.7c17.9-0.1,35.6-0.7,53.4-1.5
	 M534.8,246.2l12.1-31.9 M377.9,249.5l83.5-0.7c17.7-0.1,35.4-0.6,52.8-1.5 M514.2,247.3l15-36.5 M497.8,270.8h-16.4 M497.4,275.5
	c-0.5,0.8-1.5,1.8-2.6,2.6 M481.5,275.5h16"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M560.2,205.3c-30-6.9-60.4-10.9-91.1-11.8c-28.1-0.1-51.9,3.3-71.5,10.2
	 M662.8,307c-1.4-1.4-2.9-2.8-4.3-3.9c-11.1-9.2-24.2-13.8-39.1-13.8c-15,0-28.1,4.5-39.1,13.8c-1.5,1.2-2.9,2.5-4.3,3.9
	c-9.2,9.1-14.9,19.7-17.1,31.6v0.4c-1.5,8.9-2.2,19.1-2.2,30.8 M320.9,361.7l147.7-2.1c13.3-0.1,22.7-4.8,28.1-14l3.4-5.9
	 M500.3,339.6c1.9-3.4,3.9-7,5.6-10.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M500.3,339.6l21.6-0.5c11.1,0,14.7-1.1,10.6-3.2c-7.8-3.2-16.6-5.5-26.5-7
	 M523.4,377v5.2H563l0.1-5.2 M556.6,369.7h-8.3 M563.2,377h-6.7 M556.4,372.1l0.1-2.3 M556.4,377v-5 M265.6,372.3l15.3-2.2l8.4-0.8
	l10.3-0.5l202.2-3.6c15.1-0.3,30.1,1.2,45.3,4.4h1.2 M523.4,377h33 M505.8,328.9c-6.9-1.1-14.3-1.6-22.3-1.8l-157,6.6
	c-6.5,0.4-12.4,1-17.6,1.7 M681.6,366c0-9.9-0.5-18.6-1.5-25.9c-0.4-2.9-1.1-5.8-1.9-8.5c-2.9-9.1-8-17.3-15.3-24.5 M674.5,306.3
	c5.1,5.9,8.9,12.4,11.3,19.4c1,2.9,1.7,5.9,2.2,8.9c1.2,8.5,1.9,18.7,1.8,30.7 M704.6,337.1c1,1.4,2.5,2.1,4.7,2.1l20.6-1.4
	c1.2,0,2.6-0.7,4.1-2.1c1.9-1.5,1.9-2.3,0-2.5l-27.7,1.6C704.2,335.2,703.6,335.9,704.6,337.1L704.6,337.1z M647,323.1l-1.4-1.4
	c-3.2-3.2-6.7-5.8-10.6-7.4 M648.2,324.6l-8.7,6.3 M648.2,324.6l-1.2-1.5 M637.9,329.1l9.1-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M666.8,375.4c5.2-8.4,7.8-17.7,7.8-28.3c0-7.6-1.4-14.7-4.1-21.2
	c-2.6-6.3-6.6-12.1-11.8-17.2c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3
	c-2.2,1.6-4.4,3.6-6.5,5.6c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4c1.5,4.8,3.6,9.2,6.5,13.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M656.2,349.1v-1.9c0-8.7-2.6-16.1-8-22.6 M656,350.9l0.1-1.8 M645.3,349.1
	v-1.9c0-6.2-1.9-11.7-5.9-16.2 M642.8,358.3l-1.7-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7
	c0.4-0.8,1.4-1.2,2.6-1.4l2.1-0.1 M626.2,371.6c4.4-1.1,8.4-3.3,11.8-6.7c1.4-1.4,2.6-2.9,3.7-4.5 M650.4,366.7l1-1.5 M645,351.4
	c-0.4,2.5-1.1,4.7-2.2,6.9 M641.7,360.4l8.7,6.3 M651.4,365.2l-8.5-6.9 M574.7,377c2.1,3,4.4,5.9,7.2,8.5c10.6,10.6,23.4,16,38.4,16
	s27.8-5.2,38.4-16c3.2-3.2,5.9-6.6,8.1-10.2 M651.4,365.2c2.6-4.3,4.1-9.1,4.5-14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M629.5,382c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8
	 M645.3,349.1h10.9 M645,351.4l11-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M658,347.2c0-10.5-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11
	s-19.3,3.7-26.7,11c-7.3,7.4-11,16.2-11,26.7s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11s19.3-3.7,26.7-11
	C654.3,366.4,658,357.5,658,347.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M660.2,347.2c0-11-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7
	c-11,0-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7s20.4-3.9,28.2-11.7
	C656.3,367.5,660.2,358.2,660.2,347.2L660.2,347.2z M689.7,365.3l-8.1,0.7 M681.6,366v4.8 M791.1,334.5h-16.2
	c-4.3,0.5-7.3,1.9-8.9,4c-4.3,5-8.4,8.9-12.4,11.7c-4.7,3.3-9.1,5.5-13.1,6.6c-18.7,2.2-33.6,5-44.8,8.1l-5.8,0.4 M666.8,375.4
	l14.9-4.5 M674.5,306.3l-11.7,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M633.3,313.6c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2 M635,314.3
	l-1.7-0.7 M630,323.8c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8 M633.3,313.6l-3.3,10.2 M607.5,313.5l-1.7,0.7 M610.9,323.8
	l-3.4-10.3 M608.7,324.7l0.5,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.5l-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2 M602.8,329
	c1.8-1.8,3.7-3.2,5.9-4.3 M618.2,338.6l0.8,1.8l2.1,0.3l1.1-1.6l-0.8-1.8l-1.9-0.3L618.2,338.6z M613.3,342.8l-1.9,0.3l-0.8,1.8
	l1.2,1.7l1.9-0.3l0.8-1.8L613.3,342.8z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M610.6,337.5l0.8,0.5c2.6,2.1,3.9,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4
	h-1.1 M626.5,334.9l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6c-2.8-0.4-4.8-2.3-5.9-5.8l-0.3-1 M632.1,324.9l-0.5,2.1c-0.3,1.2-0.1,2.2,0.4,3
	l0.5,0.5c0.3,0.1,0.4,0.3,0.7,0.4c0.8,0.4,1.9,0.3,3-0.5l1.7-1.1 M620.3,342.2c-1.4,0-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4
	s0.5,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4c1-1,1.4-2.1,1.4-3.4c0-1.4-0.6-2.5-1.4-3.4
	C622.8,342.8,621.6,342.2,620.3,342.2L620.3,342.2z M626.2,343.9l0.4,1.9l1.9,0.5l1.5-1.4l-0.4-1.9l-1.9-0.5L626.2,343.9z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M630,337.7l-0.7,0.6c-2.9,2.2-4.1,4.7-3.6,7.6c1.4,2.2,3.7,3.3,7,3.3h1
	 M614.5,334.8c1.8-0.8,3.7-1.2,5.8-1.2c2.2,0,4.3,0.5,6.2,1.4 M635,314.3l-2.9,10.6 M630,323.8l-3.6,11.1 M605.8,314.1l2.9,10.6
	 M614.5,334.8l-3.6-11 M605.8,314.1c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2 M592.5,324.3l8.8,6.3 M593.7,323l-1.2,1.4 M595.4,351.2
	l2.2,0.1c1.2,0.1,2.2,0.5,2.8,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5 M595.2,348.8V347c0-6.3,2.1-11.8,6.2-16.5
	 M584.4,348.8c0,0.5,0,1.2,0.1,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M592.5,324.3c-5.4,6.5-8.1,14-8.1,22.8v1.8 M589,365l1,1.5 M597.6,358.2
	c-1.1-2.2-1.8-4.5-2.2-7 M584.5,350.7c0.4,5.2,1.9,10,4.5,14.3 M595.2,348.8h-10.9 M597.6,358.2L589,365 M590,366.6l8.8-6.3
	 M584.5,350.7l10.9,0.4 M590,366.6c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4 M622.6,360.5l-0.3-1c-1-3.2-0.5-5.8,1.1-7.7
	c2.5-1.4,5.2-1,8.3,1.1l0.8,0.6 M633.8,349.1l0.1-1.9c0-3.7-1.2-6.7-3.7-9.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M632.2,353.5c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9
	 M623.6,352.7v1.9l1.7,1.1l1.8-1.1v-1.9l-1.8-1L623.6,352.7z M615.2,355.7l1.9-0.5l0.4-1.9l-1.5-1.4l-1.9,0.5l-0.4,2.1L615.2,355.7z
	 M606.8,348.8l-0.1-1.6c0-3.7,1.4-6.9,4-9.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M608.2,353.4l1-0.7c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3
	l-0.3,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617.9,360.5c-2.8-0.4-5.2-1.6-7.3-3.9c-1.1-1.1-1.9-2.2-2.5-3.3
	 M610.9,381.8l1.8,0.4 M614.4,371.5l-3.4,10.3 M612.7,382.2l3.9-10.2 M616.6,372.1l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2h1.5
	c1,0.3,1.7,1,2.1,2.2l0.8,2.1 M627.7,382.2l1.8-0.3 M612.7,382.2c2.3,0.5,5,0.7,7.6,0.7s5.1-0.3,7.4-0.7 M629.5,382l-3.3-10.3
	 M623.8,372.1l3.9,10.2 M616.6,372.1l3.7,0.1l3.6-0.1 M598.8,360.2c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7 M626.2,371.6
	l-3.6-11.1 M617.9,360.5l-3.6,11 M601.3,330.7l9.4,6.9 M606.8,348.8h-11.6 M598.8,360.2l9.4-6.9 M602.8,329l-9.1-6.1 M633.8,349.1
	h11.6 M639.5,330.9l-9.5,6.7 M637.9,329.1c-1.8-1.8-3.7-3.2-5.8-4.3 M632.2,353.5l9.5,6.9 M563.2,377h11.6 M560.2,205.3l8-6.3
	 M761.1,247l-12.4,3c-12.8,3.2-26.7,4-41.8,2.6c-53.9-3.9-106.5-2.8-157.8,3.6 M770.6,255.8c1.8-0.6,1.9-2.3,0.3-5.5
	c-0.5-1.1-1.4-2.1-2.3-2.8l-0.8-0.6c-1.4-0.6-3.6-0.6-6.5,0 M769.8,258.3c1-1.1,1.2-1.9,0.8-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M761.1,247c-9.5,1-17.2,0.7-23.1-0.6c-8.4-1.2-15.3-3-20.8-5.4
	c-7.6-3.4-13.1-4.4-16.5-2.9 M765.7,264.5l3-5.1l1.1-1.1 M767.7,268.3l-2.3-3.4l0.3-0.4 M778.9,285.1c1.9-1.1,2.5-2.1,1.4-3
	l-9.1-13.2c-1-1.4-2.1-1.5-3.4-0.5 M770.8,286.6c0.5-0.4,0.3-1.1-0.6-2.3l-15-19.4l-0.3-0.8 M761.1,265.5l-0.7-0.8l-1.1-2.5
	l-4.4,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755,264.1l-0.3-0.8l-5.2,2.5 M749.4,265.7l-1.1-1.5 M770.6,255.8
	l-18.8,4.7c-1.9,0.5-3.3,1.6-4.1,3.2l-1.1,2.2 M748.3,264.2c-0.7,0.5-1.2,1.1-1.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M769.8,258.3l-17.1,3.7c-1.8,0.4-3.3,1.2-4.5,2.2 M745.7,267.8l-1,1.8
	c-1.1,3.2,0,6.1,3.2,8.9 M746.6,266l-0.8,1.8 M782.6,290.5l-17.6-0.4c-3,0-5.1-0.4-6.1-1.2h-0.1l-10.9-10"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M761.1,265.5l15.1,19.7c1,1.2,0.7,2.2-0.8,2.6c-2.1,0.3-3.7-0.1-4.8-1.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.2,285.5c2.6,1.2,4.7,1.6,6.2,1.2l0.6-0.1 M765.7,264.5l-4.5,1
	 M745.7,267.8l12.5,17.5c3.2,1.6,5.1,1.8,5.9,0.3 M778.9,285.1l-11.1-16.8 M764.2,285.5l-14.7-19.8 M747.9,278.7l-23,21.2
	c-1.5,1.4-3.2,2.5-5.1,3.3c-2.9,1.2-6.1,1.5-9.5,1.1l-35.9,2.1 M782.6,290.5c2.8-0.7,3.2-1.5,1.2-2.5l-5-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M791.1,334.5c1.1,0,1.5-0.7,1.4-1.9l-0.5-4.1c-2.3-1.6-3.7-3.7-4.3-6.3
	l-3.3-11.7l3.2-10l1.4-0.4c1.8-0.7,2.3-1.5,1.9-2.8c-0.3-1.1-1-2.2-1.9-3.6c-0.7-1.1-1.7-2.2-2.8-3.3l-3.7-0.1 M784.8,340.4
	c-1.9,0.4-3.4,1.2-4.5,2.3l-18.8,18 M764.2,362.8l12.9-12l6.7-4c2.2-1.1,2.9-2.5,2.2-4.1l-1.1-2.2 M790.4,337.5h1.8l1-1.1
	 M790.4,339.9v-2.3 M784.8,340.4c1.5-0.4,3.4-0.5,5.6-0.5 M793.2,336.4c0.5-0.8-0.1-1.4-2.1-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M764.2,362.8c6.1-1.9,12.5-4.3,19-7c1.2-6.5,3.7-11.7,7.4-15.8
	 M765.8,349.8l0.5-1.2c0.8-1.6,1.1-2.8,0.7-3h-0.5c-0.5,0.1-1.2,0.7-2.2,1.5l-2.2,2.3l-0.3,0.3 M771,338.9c-0.8,0.5-1.7,1.2-2.2,1.9
	l-1.4,1.6 M778.1,338.5l-7,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M793.2,336.4l-14.2,0.1c-3.3,0.3-5.9,1.1-8.1,2.5 M767.3,342.6h7.4
	c1,0.1,1.7-0.1,2.2-0.5l1.1-1.4c1.2-1.1,1.2-1.9,0-2.2 M761.4,360.8l2.5-3c1.4-1.9,2.5-3.6,3.3-5.2c0.8-1.5,1-2.5,0.5-2.8h-0.4
	 M767.3,349.8c-0.4,0.1-1.1,0.5-2.1,1.4l-2.1,2.2c-0.7,0.7-1.2,1.5-1.9,2.5l-1.5,2.1c-0.7,1.1-1.4,2.2-1.8,3c-0.7,1.5-1,2.5-0.5,2.8
	h0.1 M767.3,349.8h-1.5 M761.8,349.8h-0.1 M750.7,364.6c-0.5-1.1-0.1-2.5,1.7-4 M752.3,360.8c-1.4,0.8-3,1.6-5,2.2 M747.2,362.8
	l3.4,1.8 M757.6,363.8h0.1c0.4,0,1.1-0.5,2.1-1.4l1.5-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M755,364.1l0.4,1.1c2.9-0.7,5.8-1.5,8.8-2.3 M757.6,363.8l-2.6,0.3
	 M750.7,364.6l4.3-0.5 M761.7,349.8l-7.4,9.4c-0.5,0.6-1.2,1.1-1.9,1.5 M765.8,349.8h-4 M681.6,370.8l60.4-6.7
	c2.1-0.3,3.7-0.7,5.4-1.1 M761.7,349.8l5.6-7.2 M778.1,338.5l12.4-1 M67.7,275.2c-3.8,1.9-7.6,4-11.1,6.1c-1.8,1.5-1.7,2.9,0.4,4.1
	c3.6,1.1,5.9,3.4,7,6.9l1.5,4.3 M72.2,278.4c-1.5-1.4-3-2.5-4.5-3.3 M147.5,282.6L133,293.9c-3.9,2.5-7.8,4-12.1,4.8l-27.1,3.6
	c-4.3,0.4-7.2-0.5-8.4-2.9l-6.3-12.2 M79.1,287.2c7.7-1.1,15.4-2.1,23.1-3 M79.1,287.2c-2.2-3.6-4.5-6.5-6.9-8.7 M278.7,245.2
	c-26.3-0.6-54.9,0.1-85.7,2.2c-37,3.2-66.6,7.7-88.9,13.9c-13.5,3.9-25.6,8.4-36.5,13.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M157,264.2l-18.4,2.5c-27.7,5.4-47.3,12.2-59.3,20.5 M102.3,284.2
	l12.1-1.2c9.4-0.8,18.6-1.5,27.8-2.1c3,0.1,4.8,0.7,5.2,1.8 M132.8,364.2c0.1-9.9,0.8-18.7,2.2-26.5v-0.4c2.1-12,7.8-22.6,17.1-31.6
	l2.8-2.6c3.2-2.9,6.6-5.4,10.3-7.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M125,365.3c0-12.7,0.8-23.9,2.6-33.6c1.2-6.7,3.7-12.9,7.3-18.8
	c2.1-3.4,4.7-6.7,7.6-9.9c1.4-1.5,2.8-2.9,4.3-4.3c3.6-3.2,7.3-6.1,11.1-8.4 M68.8,308.9c-0.1,0.8-0.6,1.6-1.1,2.6l-1.1,2.3
	 M68.8,308.9c0.4-1.8,0.3-3.3-0.6-5l-2.6-7.4 M77.6,337.5c-1.7-0.3-2.3-1.5-1.9-3.7c0-2.2,1.4-3.6,3.9-3.9 M78.8,337.5h-1.2
	 M85,337.3l-6.2,0.3 M78.6,338.5l-10.5,1.1c-0.8,0.1-1.5,0.5-2.1,1.1 M78.8,337.5l-0.3,1 M99.2,336.7l-10.9,0.4l-3.3,0.1 M79.7,330
	h6.6c5,0,8.3,1.1,10,3.2l2.8,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M98.1,340.3c-2.1-2.8-5.2-3.7-9.8-2.9l1.4,15.3l1.2,0.3 M102.1,340.4
	l-4-0.1 M99.2,336.7l2.9,3.7 M91.1,353.1c3.2,0.7,5.6,0.1,7.4-1.5c2.1-1.9,2.5-4.8,1-8.7c-0.4-1-0.8-1.8-1.4-2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M85,337.3c-1.5,3.3-2.2,6.7-2.1,10.5c0,3.4,1.1,5.1,3.6,5.2h4.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M112.7,354l-31.5,0.6c-2.9,0-4.3-1.5-4.3-4.7c0-3.9,0.6-7.7,1.7-11.6
	 M75.7,367v5.6c7.2,2.2,17.2,3.4,30.3,3.7h16.9v-5.9 M132.6,365.7l0.1-1.5 M132.6,365.7l-7.6-0.4 M65.2,360.9c1,1.2,2.8,1.8,5.2,1.6
	l36.5,0.8c2.8,0.1,5.6,0.4,8.4,0.8l9.6,1.2 M65.2,360.9l47.5-0.8c3.2-0.1,3.2-2.1,0-5.9 M122.8,370.4l9.8,0.4v-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M75.7,367l33.8,2.8l13.3,0.7 M102.1,340.4l10.6,13.6 M60.9,316.8l-5.8,1
	c-3,0.7-4.3,1.8-4.1,3.2c0,4.4,0.5,9.5,1.7,15.4c0.1,1.2,1.4,2.1,3.7,2.6l9.6,1.8 M66.7,313.9l-5.8,2.9 M65.9,315.9l-5,0.8
	 M66.7,313.9l-0.8,2.1 M66,340.7c-1.1,1-1.7,2.3-1.9,4.3l-2.2,9.4c-0.1,1.4-1.2,2.2-3.3,2.5c-3.7,1.1-5.2,2.5-4.8,4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M77.6,337.5L67,337.8c-2.8,0-4.1-0.5-4.1-1.6c0-8,1-14.7,2.9-20.2
	 M53.8,360.9l-1.9,0.3h-0.3c-2.5,0.7-3,1.5-1.4,2.6c3.2,1.1,7.4,1.8,13.1,2.1l12.2,1 M53.8,360.9l2.6-0.4c3.4-0.4,6.1-1.5,8-3.3
	c-0.1,1.5,0.1,2.8,0.8,3.6 M65.6,296.5l1.9,11.4l1.2,1 M79.7,330c-5.1,0.1-9.6,0.5-14,1.5 M397.6,203.7c1.9-1,1.9-2.2,0.3-3.4
	l-4.8-3.6 M327.7,249.9l-9.2,7 M327.7,249.9l-0.1,0.3c-0.1,0.3-0.1,0.4,0,0.7c0,0.3,0.1,0.4,0.4,0.6l0.7,0.1l10.9-0.1 M355.2,237.4
	c-4.3,2.8-7.4,5.4-9.6,7.7c-1,1-1.2,1.9-1.1,2.9 M339.5,251.6c-1.8,0.6-2.9,1.6-3.3,2.9c0.8,0.4,1.6,0.7,2.6,1 M345.8,250.3
	c-1.6,0.3-3.3,0.6-4.8,0.8c-0.5,0.1-1.1,0.3-1.5,0.4 M350.5,249.8c-1.6,0.1-3.2,0.4-4.5,0.6 M345.3,249.8l0.5,0.6 M345.3,249.8h5.2
	 M338.8,255.4c1.8,0.6,4,0.7,6.5,0.7h10.6l3-2.8c7.3,0.1,13.1-0.6,17.1-1.8c0.3-0.1,0.4-0.1,0.5-0.3 M344.3,247.8
	c0.1,0.7,0.4,1.2,1,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M355.2,235.2c-7.7,4.5-15.4,9.5-23,14.7l13.2-0.1 M292.7,245.2
	c-3.2,1.8-6.3,3.6-9.6,5.4 M283.2,250.6l31.2,1.1c11.4-8.5,24.9-17.3,40.3-26.1c1.8-1,3.6-2.1,5.4-3c12.2-6.7,24.6-13.1,37.4-19
	 M392.9,196.7c-13.1,4-34.3,13.6-63.6,28.9c-11,5.8-23.2,12.4-36.6,19.7 M318.5,256.9l-1.9,0.1 M309,335.2V332l-0.4-2.5
	c-1.8-9.6-3.2-18.4-3.9-26.4c-0.8-8.1-1.1-15.5-0.8-22.1c1-10.3,5.1-18.3,12.8-23.9 M372.4,236c-1.2-0.4-2.8-0.7-4.5-0.8
	c-4.1-0.6-8,0-11.8,1.5c-0.3,0.1-0.5,0.4-1,0.6 M378.3,246.3l-0.8-5c-0.4-2.6-2.1-4.4-5-5.4 M378,248.9c0.1-0.7,0.3-1.6,0.3-2.6
	 M377.3,250.5l0.4-0.8 M377.9,249.5c0-0.1,0.1-0.4,0.1-0.6 M376.5,251.3c0.4-0.3,0.7-0.6,1-0.8 M378.3,246.3h-2.1 M355.2,237.4v-2.2
	 M377.3,250.5c-7,0.1-13.2,0.6-18.6,1.1c-9.1,0.8-15.8,2.2-20.1,4 M376.2,246.3c0.1-5.9-1.1-9.4-3.7-10.3 M378,248.9h-12.4
	c-5.5,0-10.6,0.3-15.1,0.8 M376.2,246.3c-12.5-0.1-23.1,0.3-31.9,1.5 M516.7,252l-150.9,4.1l-47.3,0.8 M157.9,290.4l7.2,5.4
	 M157.9,290.4l-10.5-7.7 M165.1,295.7c9.1-5.2,19.1-7.7,30.4-7.7c15.7,0,29.2,5.1,40.7,15.1l2.8,2.6c7.4,7.3,12.5,15.4,15.4,24.5
	c0.8,2.8,1.5,5.6,1.9,8.5c1.2,9.1,1.8,20.2,1.5,33.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.6,372.3c0.5-15.5,0-28.6-1.5-38.9c-0.5-3-1.2-6.1-2.2-8.9
	c-2.5-7.4-6.5-14.2-12.1-20.4l-0.8-1c-1.4-1.5-2.9-2.9-4.4-4.3c-13.5-12.4-29.9-18.4-49-18.4c-14.2,0-26.7,3.3-37.8,10 M278.7,245.2
	l-3,5.1 M316.5,257.1l-56.5,1c-36.5,0.3-70.8,2.2-103,6.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.7,250.3l-77,2.8c-48.4,2.5-90.5,10.9-126.3,25.3 M221.9,323.1
	l-1.4-1.4c-3.2-3.2-6.7-5.8-10.6-7.4 M206.9,324.9l-0.6,2.1c-0.3,1.2-0.1,2.2,0.4,3l0.6,0.5l0.7,0.4c0.8,0.4,1.9,0.3,3-0.5l1.6-1.1
	 M223.1,324.6l-8.8,6.3 M223.1,324.6l-1.2-1.5 M212.7,329.1l9.2-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M241.8,375c5.1-8.3,7.6-17.5,7.6-27.9c0-7.6-1.4-14.7-4.1-21.2
	c-2.6-6.3-6.6-12.1-11.8-17.2c-2.1-2.1-4.3-4-6.5-5.6c-9.2-6.9-19.9-10.3-31.9-10.3c-12.1,0-22.7,3.4-31.9,10.3
	c-2.2,1.6-4.4,3.6-6.5,5.6c-10.6,10.6-16,23.4-16,38.4c0,5.8,0.8,11.3,2.3,16.4l0.8,2.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M231,349.1v-1.9c0-8.7-2.6-16.1-8-22.6 M230.8,350.9l0.1-1.8 M220.1,349.1
	v-1.9c0-6.2-1.9-11.7-5.9-16.2 M217.6,358.3l-1.6-1.4c-1-0.8-1.5-1.8-1.4-2.6c0-0.3,0.1-0.5,0.1-0.7l0.3-0.7
	c0.4-0.8,1.4-1.2,2.8-1.4l2.1-0.1 M201,371.6c4.4-1.1,8.4-3.3,11.8-6.7c1.4-1.4,2.6-2.9,3.7-4.5 M225.3,366.7l1-1.5 M220,351.4
	c-0.4,2.5-1.1,4.7-2.2,6.9 M226.3,365.2l-8.7-6.9 M216.5,360.4l8.8,6.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M144,366c2.6,7.1,6.9,13.8,12.7,19.5c10.6,10.6,23.4,16,38.4,16
	s27.8-5.2,38.4-16c3.3-3.3,6.1-6.9,8.4-10.6 M226.3,365.2c2.6-4.3,4.1-9.1,4.5-14.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M204.4,382c6.1-1.5,11.4-4.7,16.1-9.4c1.8-1.8,3.4-3.7,4.8-5.8
	 M220.1,349.1H231"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M235,347.2c0-11-3.9-20.4-11.7-28.2c-7.8-7.8-17.2-11.7-28.2-11.7
	s-20.4,3.9-28.2,11.7s-11.7,17.2-11.7,28.2s3.9,20.4,11.7,28.2c7.8,7.8,17.2,11.7,28.2,11.7c11,0,20.4-3.9,28.2-11.7
	C231.1,367.5,235,358.2,235,347.2L235,347.2z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M232.8,347.2c0-10.5-3.7-19.3-11-26.7c-7.4-7.4-16.2-11-26.7-11
	s-19.3,3.7-26.7,11c-7.4,7.4-11,16.2-11,26.7s3.7,19.3,11,26.7c7.4,7.4,16.2,11,26.7,11c10.5,0,19.3-3.7,26.7-11
	C229.2,366.4,232.8,357.5,232.8,347.2z M220,351.4l10.9-0.5 M252.4,376.5l2.2,5.8h62.2l3.4-5.2 M257.7,373.6v-1.2 M257.5,377.2
	l-5.1-0.7 M257.7,373.6l-0.1,3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M258.8,377.3h-1.2v-0.1 M257.5,377.2l1.2,0.1 M258.8,377.3H272l6.3,1.9
	l42-2.2 M257.7,372.3h8 M241.8,375l10.6,1.5 M208.1,313.6c-4-1.5-8.4-2.3-13.1-2.3c-4.5,0-8.8,0.7-12.8,2.2 M209.9,314.3l-1.8-0.7
	 M204.8,323.8c-3-1.2-6.2-1.9-9.8-1.9c-3.4,0-6.5,0.6-9.4,1.8 M208.1,313.6l-3.3,10.2 M182.4,313.5l-1.8,0.7 M185.7,323.8l-3.3-10.3
	 M183.5,324.7l0.6,2.1c0.3,1.2,0.1,2.2-0.4,3l-0.6,0.5c-0.3,0.1-0.4,0.3-0.7,0.4c-0.8,0.4-1.9,0.3-3-0.5l-1.8-1.2 M186.1,343
	l-0.8,1.8l1.2,1.7l1.9-0.3l0.8-1.8l-1.1-1.6L186.1,343z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M185.6,337.5l0.8,0.5c2.6,2.1,3.9,4.3,3.7,6.9c-1.2,2.6-3.7,3.9-7.3,4
	h-1.2 M177.7,329c1.8-1.8,3.7-3.2,5.9-4.3 M176.1,330.7l9.5,6.9 M201.3,334.9l-0.3,1c-1.1,3.2-2.9,5.1-5.4,5.6
	c-2.8-0.4-4.8-2.3-5.9-5.8l-0.3-1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M195.9,340.7l1.1-1.6l-0.8-1.8l-1.9-0.3l-1.1,1.6l0.7,1.8L195.9,340.7z
	 M195.1,342.2c-1.4,0-2.5,0.5-3.4,1.4c-1,1-1.4,2.1-1.4,3.4s0.6,2.5,1.4,3.4c1,1,2.1,1.4,3.4,1.4c1.4,0,2.5-0.5,3.4-1.4
	c1-1,1.4-2.1,1.4-3.4c0-1.4-0.6-2.5-1.4-3.4C197.5,342.8,196.4,342.2,195.1,342.2L195.1,342.2z M205,337.7l-0.7,0.6
	c-2.9,2.2-4.1,4.7-3.6,7.6c1.4,2.2,3.7,3.3,7,3.3h1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M202.5,342.5l-1.4,1.4l0.4,1.9l1.8,0.5l1.5-1.4l-0.4-1.9L202.5,342.5z
	 M189.3,334.8c1.8-0.8,3.7-1.2,5.8-1.2c2.2,0,4.3,0.5,6.2,1.4 M209.9,314.3l-3,10.6 M204.8,323.8l-3.6,11.1 M189.3,334.8l-3.6-11
	 M180.6,314.1l2.9,10.6 M180.6,314.1c-4,1.8-7.6,4.3-10.9,7.6l-1.2,1.2 M168.5,323l-1.2,1.4 M170,348.8V347c0-6.3,2.1-11.8,6.2-16.5
	 M170.3,351.2l2.2,0.1c1.2,0.1,2.2,0.5,2.6,1.4l0.3,0.7l0.1,0.7c0.1,1-0.4,1.8-1.4,2.6l-1.8,1.5 M159.2,348.8c0,0.5,0,1.2,0.1,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M167.3,324.3c-5.4,6.5-8.1,14-8.1,22.8v1.8 M163.8,365l1,1.5 M159.3,350.7
	l11,0.4 M172.5,358.2c-1.1-2.2-1.8-4.5-2.2-7 M159.3,350.7c0.4,5.2,1.9,10,4.5,14.3 M172.5,358.2l-8.7,6.9 M170,348.8h-10.9
	 M164.8,366.6l8.8-6.3 M164.8,366.6c1.4,2.1,3,4,4.8,5.9c4.7,4.7,10,7.8,16.1,9.4 M208.5,349.1l0.1-1.9c0-3.7-1.2-6.7-3.7-9.4
	 M197.4,360.5l-0.3-1c-1-3.2-0.6-5.8,1.1-7.7c2.5-1.4,5.4-1,8.3,1.1l0.8,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M207.2,353.5c-0.7,1.1-1.5,2.2-2.5,3.2c-2.1,2.1-4.5,3.3-7.3,3.9
	 M201.8,352.7l-1.6-1l-1.8,1v1.9l1.8,1.1l1.6-1.1V352.7z M183,353.4l1-0.7c2.8-1.9,5.4-2.3,7.7-1.4c2.1,2.1,2.5,4.7,1.5,8.3l-0.3,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M188.9,352.4l-0.4,2.1l1.5,1.2l1.9-0.5l0.4-1.9l-1.5-1.4L188.9,352.4z
	 M181.6,348.8l-0.1-1.8c0-3.7,1.4-6.9,4-9.6 M192.7,360.5c-2.8-0.4-5.2-1.6-7.3-3.9c-1.1-1.1-1.9-2.2-2.5-3.3 M185.7,381.8l1.9,0.4
	 M189.1,371.5l-3.4,10.3 M187.6,382.2l3.7-10.2 M191.5,372.1l0.8-2.1c0.4-1.2,1.1-1.9,2.1-2.2h1.5c1,0.3,1.6,1,2.1,2.2l0.8,2.1
	 M198.8,372.1l3.9,10.2 M204.4,382l-3.4-10.3 M187.6,382.2c2.3,0.5,5,0.7,7.6,0.7c2.6,0,5.1-0.3,7.4-0.7 M202.6,382.2l1.8-0.3
	 M191.5,372.1l3.6,0.1l3.7-0.1 M173.6,360.2c1.1,1.6,2.2,3.2,3.7,4.7c3.4,3.4,7.4,5.6,11.8,6.7 M192.7,360.5l-3.6,11 M201,371.6
	l-3.6-11.1 M181.6,348.8H170 M173.6,360.2l9.4-6.9 M167.3,324.3l8.8,6.3 M177.7,329l-9.2-6.1 M208.5,349.1h11.6 M214.3,330.9
	l-9.4,6.7 M206.9,324.9c2.1,1.1,4,2.6,5.8,4.3 M207.2,353.5l9.4,6.9 M309.1,345c-0.4-2.1-0.5-4-0.4-5.8c0-1.2,0.1-2.3,0.3-3.4v-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M309,335.2c-12.7,1.5-22.3,3.6-28.8,6.3c-6.6,3-3.4,4.4,9.5,4l19.4-0.5
	 M315.7,361.9h0.8 M316.5,361.9c-1.2-0.3-2.1-0.7-2.8-1.5l-3.4-11.1c-0.5-1.5-0.8-2.9-1.1-4.3 M316.5,361.9c1.2,0.3,2.8,0.3,4.5,0
	 M320.9,361.7l-4.4,0.1 M292.7,245.2h-14 M275.7,250.3l7.6,0.3 M132.8,364.2L144,366 M157,264.2c-23.7,5.1-42,11.7-54.6,19.9
	 M320.2,377h203.2 M309.1,345l191.2-5.4 M556.4,372.1l-298.8,1.5"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M355.2,237.4v-2.2
		c0,0,30.4-18.8,57.2-25.7c0,0,20.6-6.1,46.9-5.8c0,0,19-0.8,69.7,7.2l-14.9,36.6c0,0-30.5,1.2-55.3,1.5c-24.9,0.3-81,0.7-81,0.7
		s0.7-1.5,0.3-3.9l-0.7-4.3c0,0-0.1-3.6-4.4-5.2c-4.3-1.8-13.6-1.5-16.9,0.6L355.2,237.4z"
              id="Left Front Door Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M534.8,246.2l12.1-31.9
		c0,0,31.4,6.2,49.4,12.9c0,0,3.9,1.1,2.2,3.7c0,0-7,9.8-17.5,11.3l-0.3,0.1C580.9,242.3,541.5,246.1,534.8,246.2z"
              id="Left Qtr Glass"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M165.1,295.7
		l-7.2-5.4l-10.5-7.7c0,0-0.4-1.6-5.2-1.8c0,0-28.9,1.7-39.6,3.2c0,0,16.2-12.5,54.3-19.9c0,0,42.1-5.1,81.7-5.8l15.8-0.3l62-1.1
		c0,0-10.9,6.3-12.8,23.2c0,0-1.5,15,4.8,49.2l0.4,2.1c0,0,0.3,1.9,0.1,3.7s-0.4,3-0.3,5.2c0.1,2.2-0.1,3.4,1,7l4,12.9l-10,8.5
		c0,0-11.6-0.7-38.1,3.6h-8c0,0,0.4-31.5-2.9-40.4c0,0-1.9-6.3-3.6-9.2c-1.5-2.9-16-34.1-55.7-34.7C195.5,288,179.2,287,165.1,295.7
		z"
              id="Left Front - Fender"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M316.5,257.1
		c0,0-12,8-12.9,23.7c0,0-1,13.6,3.7,42.4l1.5,8.5c0,0,0.3,1.6,0.1,3.9c-0.3,2.2-0.8,4.4,0.1,9.9l4.5,15c0,0,1.4,1.5,3.9,1.5
		l151-2.2c0,0,11.8,0.6,20.8-5.9c0,0,4-2.9,7.2-7.7l3.7-6.5l5.6-10.7c0,0,12.4-25,17.3-51c0,0,2.8-12.2-6.5-26L316.5,257.1z"
              id="Left Front Door"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M662.8,307
		l47.7-2.8c0,0,8.5,1.5,14.9-4.7l22.6-20.9c0,0-5.1-4.3-3.2-9.1l2.9-5.8c0,0,1.2-2.5,4.1-3.2l18.4-4.7c0,0,2.3-0.3,1.7-2.9
		c0,0-1.2-4.8-4.7-6.1c0,0-2.1-0.8-6.7,0.3l-3.4,0.8c0,0-17.9,5.5-35.5,5.4c0,0-6.9,0.1-18.2-0.8c0,0-35.8-2.6-62.9-2.1
		c0,0-67.8,0.4-119.7,9.9c0,0,3.4,6.7,2.2,16.9l-0.6,3c0,0-2.2,11.1-8.5,29c0,0-4.5,12.5-8.3,19.5c0,0-6.3,12-7.7,14.3l32.5,23.7
		c0,0,6.2,0.6,16.5,2.8h9.4c0,0,0.1-20.2,2.8-33.4c2.6-13.3,10.5-21.9,14.2-26.8c3.9-5,21.3-20.6,45.4-20.1
		C618.9,289.2,643.8,287.6,662.8,307z"
              id="Left Rear - Quarter Panel"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="195.1"
              cy="347.2"
              r="54.3"
              id="Wheels And Tires - Left Front"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Front')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="620.3"
              cy="347.2"
              r="54.3"
              id="Wheels And Tires - Left Rear"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Wheels And Tires - Left Rear')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M356,256
		c0,0-15.4,1.2-19.8-1.5c0,0,0.7-3.6,9.5-4.1c0,0-2.6-2.8-0.3-5.1c2.3-2.3,6.7-6.2,10.7-8.5c0,0,5.9-3.3,15.7-1c0,0,4.4,0.8,5.6,5
		l0.8,6.1c0,0,0.4,3.7-2.3,5c0,0-7,2.3-17.1,1.8L356,256z"
              id="Left Side Mirror"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Coupe2DrLeft);
