import React from 'react';
import { makeTitle } from '../../utils/helpers';

const SelectedFilterCustom = props => {
  let labelKey = props.labelKey.replace(/\..*$/, '') + `: `; // removes any ".raw" from labelKey
  let labelValue = props.labelValue;

  // TODO: - use translations
  switch (props.filterId.toUpperCase()) {
    case 'LATLON': {
      labelKey = 'Location: ';
      labelValue = props.labelValue.split(',')[2];
      break;
    }
    case 'PRICE': {
      labelKey = 'Price: ';
      break;
    }
    case 'RETAILPRICE': {
      labelKey = 'Retail Price: ';
      break;
    }
    case 'YEAR': {
      labelKey = 'Year: ';
      break;
    }
    case 'HOURS': {
      labelKey = 'Hours: ';
      break;
    }
    case 'MILEAGE': {
      labelKey = 'Odometer: ';
      break;
    }
    case 'ODOMETER': {
      labelKey = 'Odometer: ';
      break;
    }
    case 'EXTERIORCOLOR': {
      labelKey = 'Color: ';
      break;
    }
    case 'EVENTNAME': {
      labelKey = 'Event: ';
      break;
    }
    case 'MARKETPLACENAME': {
      labelKey = 'Location: ';
      break;
    }
    case 'SELLERNAME': {
      labelKey = 'Seller: ';
      break;
    }
    case 'CONDITIONRATING': {
      labelKey = 'CR: ';
      break;
    }
    case 'HASPRICE':
    case 'HASRETAILPRICE':
    case 'HASIMAGE':
    case 'HASCR':
    case 'SHOWNOSALES':
    case 'ONLYSHOWNOSALES': {
      labelKey = '';
      break;
    }
    default: {
      break;
    }
  }

  if (props.filterId.includes('attribute_')) {
    const start = props.filterId.indexOf('_');
    const end = props.filterId.lastIndexOf('_');
    labelKey =
      start > -1 && end > -1 ? `${props.filterId.slice(start + 1, end)}: ` : '';
  }

  labelKey = makeTitle(labelKey);
  if (labelKey && !labelKey.includes(':')) labelKey += ': ';

  return (
    <div
      className={props.bemBlocks
        .option()
        .mix(props.bemBlocks.container('item'))
        .mix(`selected-filter--${props.filterId}`)}
    >
      <div className={props.bemBlocks.option('name')}>
        {`${labelKey}${labelValue}`}
      </div>
      <div
        className={props.bemBlocks.option('remove-action')}
        onClick={props.removeFilter}
      >
        x
      </div>
    </div>
  );
};

export default SelectedFilterCustom;
