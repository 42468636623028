import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dotdotdot from 'react-dotdotdot';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { commafy, getTemplate, makeAssetLink } from '../utils/helpers';
import '../assets/css/marketplace-card.css';

class ChildMarketplaceCard extends Component {
  render() {
    const data = this.props.marketplaceData;
    const href = `/search?marketplaceName[0]=${encodeURIComponent(
      data.marketplaceName
    )}`;
    const imageUri = data.logoUrl || makeAssetLink('logo.png');

    return (
      <Link to={href}>
        <div
          className="location-card"
          style={{
            display: 'block',
            float: 'left',
            width: 250,
            // minHeight: 100,
            minHeight: 320,
            borderBottom: '2px solid #ced6db',
            borderRadius: 6,
            backgroundColor: '#fff',
            margin: 8,
          }}
        >
          <div
            className="header"
            style={{
              backgroundColor: getTemplate(
                this.props.template,
                'marketplaceCardHeader.backgroundColor'
              ),
              color: getTemplate(
                this.props.template,
                'marketplaceCardHeader.color'
              ),
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
            }}
          >
            <div className="heading">
              <Dotdotdot clamp={2}>
                <span className="fa fa-map-marker" />
                {'  '}
                <span>{data.marketplaceName}</span>
              </Dotdotdot>
            </div>
          </div>
          <div className="location-image">
            <LazyLoad>
              <img
                src={imageUri}
                alt="marketplace logo"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = makeAssetLink('logo.png');
                }}
                role="presentation"
                style={{
                  objectFit: 'contain',
                }}
              />
            </LazyLoad>
          </div>
          <span
            className="marketplace-vehicles"
            style={{
              color: getTemplate(
                this.props.template,
                'marketplaceCardCount.color'
              ),
            }}
          >
            <span className="fa fa-car" />
            <span className="pad-left" />
            {`${commafy(data.itemCount)} Items`}
          </span>
          <div
            className="location-content"
            style={{
              color: getTemplate(
                this.props.template,
                'marketplaceCardText.color'
              ),
            }}
          >
            <div>
              <strong>Location:</strong>
            </div>
            <span>{data.address1}</span>
            <br />
            <span>
              {data.city}, {data.state} {data.zipCode}
            </span>
            <br />
          </div>
        </div>
      </Link>
    );
  }
}

ChildMarketplaceCard.propTypes = {
  template: PropTypes.object.isRequired,
  marketplaceData: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { template } = state.entities;
  return { template };
};

export default connect(mapStateToProps, {})(ChildMarketplaceCard);
