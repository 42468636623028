import React, { Component } from 'react';

class ErrorIcon extends Component {
  render() {
    const { size = 32, color = '#FFE6E7' } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill={color} />
        <path
          d="M15.953 6C10.465 6 6 10.486 6 16C6 21.514 10.486 26 16 26C21.514 26 26 21.514 26 16C26 10.486 21.493 6 15.953 6ZM16 24C11.589 24 8 20.411 8 16C8 11.589 11.567 8 15.953 8C20.391 8 24 11.589 24 16C24 20.411 20.411 24 16 24Z"
          fill="#FC3F47"
        />
        <path d="M15 11H17V18H15V11ZM15 19H17V21H15V19Z" fill="#FC3F47" />
      </svg>
    );
  }
}

export default ErrorIcon;
