import React from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

const withRouter = Component => props => (
  <Component
    {...props}
    location={useLocation()}
    navigate={useNavigate()}
    params={useParams()}
  />
);

export default withRouter;
